/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { updateLastActionInfo } from '../../app/actions';
import { getProjectLogStructure } from '../../apis';
import { getLoaderAction } from '../../utils';
import { ActionTypes } from '../actions';

const loadProjectLogStructureLogic = createLogic({
  type: ActionTypes.LOAD_PROJECT_LOGSTRUCTURE,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderAction(loader);

    dispatch(showLoader);
    dispatch(updateLastActionInfo());
    getProjectLogStructure(credentials, params)
      .then((data) => {
        console.log(data);
        dispatch({ type: ActionTypes.SET_PROJECT_LOGSTRUCTURE, payload: { params, data } });
      })
      .catch((err) => {
        // TODO: Add error handle.
        console.log(err);
      })
      .then(() => {
        dispatch(hideLoader);
        done();
      });
  },
});

export default loadProjectLogStructureLogic;
