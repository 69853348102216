import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, isNumber, round } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import {
  SearchOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  EditOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { Spin, Button, Pagination, Menu, message, Tag, Select } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Modal, AutoSizer, List, CellMeasurerCache, CellMeasurer, Dropdown, Popover } from '../../../lib/fui/react';
import { Defaults, EventRenderers, CellRenderers, buildUrl, GlobalParse, LogRenderers } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { BaseUrls } from '../../app/Constants';
import { ChangeEventIcon, GoodIcon, GoodOutlinedIcon } from '../../../lib/fui/icons';
import { EChart } from '../../share';

import { appMessages, appButtonsMessages, appFieldsMessages, appMenusMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';

import EventShortListModal from './EventShortListModal';
import TriageReportModal from './TriageReportModal';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import EventActionModal from '../../metric/components/EventActionModal';
import ProjectSelectorModal from './ProjectSelectorModal';
import ReportJiraModal from '../../metric/components/ReportJiraModal';
import ReportServiceNowModal from '../../metric/components/ReportServiceNowModal';

type Props = {
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  title: String,
  anomalyEvents: Array<Object>,
  isLoading: Boolean,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  timezoneOffset: Number,
  projects: Array<Object>,
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  currentProjectName: String,
  currentTheme: String,
};

class GlobalHealthAnomaliesModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      page: 1,
      pageSize: 20,
      // eslint-disable-next-line
      ignoreFilter: true,
      filterProject: null,
      eventList: [],
      optionTimelineRange: undefined,
      patternNameMap: {},

      activeEvent: null,
      showSystemEventModel: false,
      showTriageReportModal: false,
      showTakeLogActionModal: false,
      actionName: null,
      showEventActionModal: false,
      showReportJiraModal: false,
      showReportServiceNowModal: false,
      showProjectSelector: false,
      onConfirmProjectSelect: () => {},
    };
    this.anomalyEvents = [];
    this.projectOptions = [];
    this.instanceOptions = [];
    this.patternOptions = [];
    this.metricOptions = [];

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 46 });
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.anomalyEvents !== this.props.anomalyEvents) {
      this.parseData(nextProps);
    }
  }

  @autobind
  parseData(props) {
    const { intl, anomalyEvents: events, currentTheme } = props;
    const { sortBy, sortDirection } = this.state;

    this.setState({ isLoading: true });

    // get events
    let eventList = events || [];
    eventList = this.mergeAnomalyEvents(eventList);

    // build optons
    this.buildFilterOption(eventList);

    // build timeline chart option
    const { startTime, endTime } = props;
    const rangeStartTime = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const rangeEndTime = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
    const optionTimelineRange = GlobalParse.buildOptionTimelineRange({ rangeStartTime, rangeEndTime });
    eventList = R.map((item) => {
      const optionTimeline = GlobalParse.buildOptionTimeline({
        intl,
        rangeStartTime,
        rangeEndTime,
        item,
        currentTheme,
      });
      return { ...item, optionTimeline };
    }, eventList);

    // sort data
    let anomalyEvents = this.filterData(eventList);
    anomalyEvents = this.sortData(anomalyEvents, sortBy, sortDirection);

    this.anomalyEvents = anomalyEvents;
    this.setState({ isLoading: false, eventList, optionTimelineRange }, () => {
      if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  mergeAnomalyEvents(events) {
    let newEvents = [];

    // get durationTimePair info
    R.forEach((event) => {
      const { category, rootCausesDetailsList } = event;
      if (category === 'metric') {
        R.forEach((mm) => {
          const { timePairArr } = mm;
          let durationTimePair = [];
          if (timePairArr) {
            durationTimePair = R.map((tr) => {
              const { startTimestamp, endTimestamp, duration } = tr.map || {};
              return [startTimestamp, endTimestamp, duration];
            }, get(timePairArr, 'myArrayList', []));
          }
          newEvents.push({
            ...event,
            durationTimePair,
          });
        }, rootCausesDetailsList || []);
      } else {
        newEvents.push(event);
      }
    }, events);

    // merge with mergeKey
    const eventsMap = {};
    R.forEach((event) => {
      const {
        mergeKey,
        isNewAlert,
        isImportant,
        isIgnored,
        hasRootCause,
        hasTrailing,
        leadToIncident,
        causedByChange,
        avgAnomalyScore,
        anomalyColor,

        durationTimePair,
      } = event;
      if (!R.has(mergeKey, eventsMap)) {
        eventsMap[mergeKey] = {
          ...event,
          durationTimePair: durationTimePair || [],
          timelines: [event],
        };
      } else {
        eventsMap[mergeKey] = {
          ...eventsMap[mergeKey],
          isNewAlert: eventsMap[mergeKey].isNewAlert || isNewAlert,
          isImportant: eventsMap[mergeKey].isImportant || isImportant,
          isIgnored: eventsMap[mergeKey].isIgnored || isIgnored,
          hasRootCause: eventsMap[mergeKey].hasRootCause || hasRootCause,
          hasTrailing: eventsMap[mergeKey].hasTrailing || hasTrailing,
          leadToIncident: eventsMap[mergeKey].leadToIncident || leadToIncident,
          causedByChange: eventsMap[mergeKey].causedByChange || causedByChange,
          avgAnomalyScore:
            eventsMap[mergeKey].avgAnomalyScore > avgAnomalyScore
              ? eventsMap[mergeKey].avgAnomalyScore
              : avgAnomalyScore,
          anomalyColor:
            eventsMap[mergeKey].avgAnomalyScore > avgAnomalyScore ? eventsMap[mergeKey].anomalyColor : anomalyColor,

          durationTimePair: [...eventsMap[mergeKey].durationTimePair, ...(durationTimePair || [])],
          timelines: [...eventsMap[mergeKey].timelines, event],
        };
      }
    }, newEvents || []);
    newEvents = R.values(eventsMap);

    // merge timelines and get time/duration info
    newEvents = R.map((event) => {
      const { durationTimePair, timelines } = event;

      let duration = 0;
      let allDuration = 0;
      if (durationTimePair && durationTimePair.length > 0) {
        R.forEach((tr) => {
          const [st, et, dur] = tr;
          allDuration += dur || et - st;
        }, durationTimePair);
        duration = round(allDuration / durationTimePair.length);
      } else {
        duration = R.reduce(
          R.add,
          0,
          R.map((item) => item.endTimestamp - item.startTimestamp, timelines),
        );
      }

      let allStartTimestamp = Infinity;
      let allEndTimestamp = 0;
      R.forEach((event) => {
        allStartTimestamp = R.min(allStartTimestamp, event.startTimestamp);
        allEndTimestamp = R.max(allEndTimestamp, event.endTimestamp);
      }, timelines);

      return {
        ...event,

        timelines: R.sortWith([R.ascend(R.prop('startTimestamp'))], timelines),
        duration,
        allStartTimestamp,
        allEndTimestamp,
      };
    }, newEvents);

    return newEvents;
  }

  @autobind
  buildFilterOption(eventList) {
    let projectOptions = R.map(
      (item) => ({ projectName: item.projectName, projectDisplayName: item.projectDisplayName }),
      eventList,
    );
    projectOptions = R.uniq(projectOptions);

    const instanceProjectMap = {};
    R.forEach((item) => {
      const { onlyInstanceString, projectName } = item;
      if (!R.has(onlyInstanceString, instanceProjectMap)) {
        instanceProjectMap[onlyInstanceString] = {
          value: onlyInstanceString,
          projectNames: [],
        };
      }
      instanceProjectMap[onlyInstanceString].projectNames.push(projectName);
    }, eventList);
    let instanceOptions = R.values(instanceProjectMap);
    instanceOptions = R.uniqWith(R.eqBy(R.prop('value')), instanceOptions);

    const patternProjectMap = {};
    R.forEach((item) => {
      const { patternId, onlyInstanceString, projectName } = item;
      if (!R.has(patternId, patternProjectMap)) {
        patternProjectMap[patternId] = {
          value: patternId,
          projectNames: [],
          instances: [],
        };
      }
      patternProjectMap[patternId].projectNames.push(projectName);
      patternProjectMap[patternId].instances.push(onlyInstanceString);
    }, eventList);
    let patternOptions = R.values(patternProjectMap);
    patternOptions = R.uniqWith(R.eqBy(R.prop('value')), patternOptions);

    const metricProjectMap = {};
    R.forEach((item) => {
      const { category, metricNameString, onlyInstanceString, projectName } = item;
      if (category === 'metric') {
        if (!R.has(metricNameString, metricProjectMap)) {
          metricProjectMap[metricNameString] = {
            value: metricNameString,
            projectNames: [],
            instances: [],
          };
          metricProjectMap[metricNameString].projectNames.push(projectName);
          metricProjectMap[metricNameString].instances.push(onlyInstanceString);
        }
      }
    }, eventList);
    let metricOptions = R.values(metricProjectMap);
    metricOptions = R.map((item) => {
      return { ...item, projectNames: R.uniq(item.projectNames), instances: R.uniq(item.instances) };
    }, metricOptions);
    metricOptions = R.uniqWith(R.eqBy(R.prop('value')), metricOptions);
    metricOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], metricOptions);

    this.projectOptions = projectOptions;
    this.instanceOptions = instanceOptions;
    this.patternOptions = patternOptions;
    this.metricOptions = metricOptions;
  }

  @autobind
  filterData(eventList) {
    const { filterProject, filterInstance, filterPattern, filterMetric } = this.state;
    let filterList = eventList || [];
    if (filterProject) {
      filterList = R.filter((item) => item.projectName === filterProject, filterList);
    }
    if (filterInstance) {
      filterList = R.filter((item) => item.onlyInstanceString.includes(filterInstance), filterList);
    }
    if (!R.isNil(filterPattern)) {
      filterList = R.filter((item) => item.patternId === Number(filterPattern), filterList);
    }
    if (filterMetric) {
      filterList = R.filter(
        (item) => item.category === 'metric' && item.metricNameString.includes(filterMetric),
        filterList,
      );
    }
    return filterList;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.descend(R.prop('isImportant')), R.descend(R.prop('avgAnomalyScore'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  onChangeFilterField(fieldName, fieldValue) {
    const newState = { page: 1 };
    if (fieldName === 'filterProject') {
      newState.filterInstance = null;
      newState.filterPattern = null;
    } else if (fieldName === 'filterInstance') {
      newState.filterPattern = null;
    }
    // eslint-disable-next-line
    this.setState({ [fieldName]: fieldValue, ...newState }, () => {
      const { sortBy, sortDirection } = this.state;
      const eventList = this.state.eventList || [];

      // sort data
      let anomalyEvents = this.filterData(eventList);
      anomalyEvents = this.sortData(anomalyEvents, sortBy, sortDirection);

      this.anomalyEvents = anomalyEvents;
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  renderMetricType(rowData) {
    const metricType = rowData?.metricRootCauseJson?.metricType || '';
    return <span>{metricType.toLowerCase() === 'unknown' || metricType === '' ? '--' : metricType}</span>;
  }

  @autobind
  renderAnomalyListItem(events) {
    return ({ key, index: rowIndex, style, parent }) => {
      const item = events[rowIndex];
      if (!item) return null;

      const { componentNameString, onlyInstanceString, metricRootCauseJson } = item;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
            <div className="row-column" style={{ width: 80 }}>
              {this.scoreRender(item)}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {this.renderInstanceContent(item, componentNameString)}
            </div>
            <div className="row-column" style={{ width: 120 }}>
              {this.renderInstanceContent(item, onlyInstanceString || metricRootCauseJson?.instanceName)}
            </div>
            <div className="row-column" style={{ width: 100, flex: 1 }}>
              {this.renderLogContent(item)}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {this.renderDuration(item)}
            </div>
            <div className="row-column" style={{ width: 80 }}>
              {this.renderPattern(item)}
            </div>
            <div className="row-column" style={{ width: 80 }}>
              {this.renderCategory(item)}
            </div>
            <div
              className="row-column"
              style={{ width: 90, padding: '0 6px', display: 'inline-block', textAlign: 'left' }}
            >
              {this.renderMetricType(item)}
            </div>
            <div className="row-column" style={{ width: 100, flex: 1 }}>
              {item.optionTimeline && <EChart width="100%" height={40} option={item.optionTimeline} onEvents={{}} />}
            </div>
            <div className="row-column" style={{ width: 60, padding: 0 }}>
              {this.statusRender(item)}
            </div>
            <div className="row-column" style={{ width: 40 }}>
              {this.renderControl(item)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  statusRender(rowData) {
    const { intl, timezoneOffset } = this.props;
    const { isIgnored, isImportant, rootCauseResultInfo, reporterRecordMap } = rowData;
    const causedByChange = get(rootCauseResultInfo, 'causedByChangeEvent');

    return (
      <div className="flex-row flex-wrap full-width">
        <EventRenderers.RenderPatternReporterTooltip
          intl={intl}
          actionName={
            isImportant
              ? intl.formatMessage(eventMessages.markedAsImportant)
              : intl.formatMessage(eventMessages.markedAsUnimportant)
          }
          timezoneOffset={timezoneOffset}
          reporterRecordSet={get(reporterRecordMap, isImportant ? 'importance' : 'unImportance', [])}
        >
          {isImportant ? (
            <GoodIcon
              style={{ fontSize: 14, margin: '0 4px 2px 0', color: '#32C880' }}
              onClick={() => this.handleIgnoreClick({ event: rowData, category: 'important' })}
            />
          ) : (
            <GoodOutlinedIcon
              style={{ fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={() => this.handleIgnoreClick({ event: rowData, category: 'important' })}
            />
          )}
        </EventRenderers.RenderPatternReporterTooltip>
        <EventRenderers.RenderPatternReporterTooltip
          intl={intl}
          actionName={
            isIgnored
              ? intl.formatMessage(eventMessages.markedAsIgnored)
              : intl.formatMessage(eventMessages.markedAsUnignored)
          }
          timezoneOffset={timezoneOffset}
          reporterRecordSet={get(reporterRecordMap, isIgnored ? 'ignore' : 'unIgnore', [])}
        >
          {isIgnored ? (
            <EyeInvisibleOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          ) : (
            <EyeOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          )}
        </EventRenderers.RenderPatternReporterTooltip>
        {causedByChange && (
          <Popover
            content={
              <div>
                <div>{intl.formatMessage(DashboardMessages.causedByChangeAnomaly)}</div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="top"
          >
            <ChangeEventIcon
              style={{ color: 'orange', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAJumpClick(rowData);
              }}
            />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  scoreRender(rowData) {
    const { avgAnomalyScore, anomalyColor } = rowData;
    return (
      <Tag color={anomalyColor}>{numeral(avgAnomalyScore).format(avgAnomalyScore > 1 ? '0,0' : '0,0.[0000]')}</Tag>
    );
  }

  @autobind
  renderInstanceContent(rowData, cellData) {
    const { intl, userInfo, projects } = this.props;
    const { componentNameString, onlyInstanceString, containerName, projectOwner } = rowData;

    let { projectName } = rowData;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);

    return (
      <Popover
        title={null}
        content={
          <div style={{ maxWidth: 350, maxHeight: 300, overflowY: 'auto' }}>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </span>
              <span>{project ? project.projectDisplayName : projectName}</span>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </span>
              <span>{componentNameString}</span>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.instance)}:
              </span>
              <span>{onlyInstanceString}</span>
            </div>
            {containerName && (
              <div style={{ wordBreak: 'break-all' }}>
                <span className="light-label bold inline-block" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </span>
                <span>{containerName}</span>
              </div>
            )}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <span className="max-width inline-block hidden-line-with-ellipsis">{cellData}</span>
      </Popover>
    );
  }

  @autobind
  renderLogContent(rowData) {
    const { intl, currentTheme } = this.props;
    const {
      category,
      containerName,
      rawData,
      anomalyWords,
      outlierValue,
      rootCauseJson,
      allStartTimestamp,
      allEndTimestamp,
    } = rowData;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error);
    }
    const rootCauseDetailsArr = get(rootCauseJson, ['rootCauseDetailsArr'], []);

    return (
      <div className="full-width flex-col">
        {category === 'metric' && (
          <Popover
            title={null}
            content={
              <div style={{ maxWidth: 350, maxHeight: 300, overflowY: 'auto', wordBreak: 'break-all' }}>
                {containerName && (
                  <div style={{ wordBreak: 'break-all' }}>
                    <span className="light-label bold inline-block" style={{ width: 70 }}>
                      {intl.formatMessage(appFieldsMessages.container)}:
                    </span>
                    <span>{containerName}</span>
                  </div>
                )}
                <div style={{ wordBreak: 'break-all' }}>
                  <span className="light-label bold inline-block" style={{ width: 70 }}>
                    {intl.formatMessage(appFieldsMessages.startTime)}:
                  </span>
                  <span>{moment.utc(allStartTimestamp).format(Defaults.ShortTimeFormat)}</span>
                </div>
                <div style={{ wordBreak: 'break-all' }}>
                  <span className="light-label bold inline-block" style={{ width: 70 }}>
                    {intl.formatMessage(appFieldsMessages.endTime)}:
                  </span>
                  <span>{moment.utc(allEndTimestamp).format(Defaults.ShortTimeFormat)}</span>
                </div>
                {R.addIndex(R.map)(
                  (event, index) =>
                    EventRenderers.RenderMetricAnomalySummary({
                      intl,
                      // category: 'shortDisplay',
                      event,
                      index,
                      labelMinWidth: 70,
                    }),
                  rootCauseDetailsArr || [],
                )}
              </div>
            }
            mouseEnterDelay={0.3}
            placement="right"
          >
            {containerName && (
              <div className="hidden-line-with-ellipsis">
                <span className="bold light-label inline-block" style={{ minWidth: 70 }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </span>
                <span>{containerName}</span>
              </div>
            )}
            {EventRenderers.RenderMetricAnomalySummary({
              intl,
              // category: 'shortDisplay',
              event: rootCauseDetailsArr[0],
              withEllipsis: true,
              labelMinWidth: 70,
            })}
          </Popover>
        )}
        {category === 'log' && (
          <Popover
            title={null}
            content={
              <div className="flex-col overflow-y-auto" style={{ maxWidth: 480, maxHeight: 350, paddingRight: 8 }}>
                {containerName && (
                  <div style={{ wordBreak: 'break-all', marginBottom: 12 }}>
                    <span className="light-label bold inline-block" style={{ width: 70 }}>
                      {intl.formatMessage(appFieldsMessages.container)}:
                    </span>
                    <span>{containerName}</span>
                  </div>
                )}
                {anomalyWords && anomalyWords.length > 0 && (
                  <LogRenderers.RenderAnomalyWords
                    style={{ wordBreak: 'break-all', marginBottom: 12 }}
                    anomalyWordList={anomalyWords}
                  />
                )}
                {outlierValue && !R.isEmpty(outlierValue) && (
                  <LogRenderers.RenderOutlierValue
                    style={{ wordBreak: 'break-all', marginBottom: 12 }}
                    outlierValue={outlierValue}
                  />
                )}
                <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                  {!rawDataJson &&
                    R.join(
                      '\n',
                      R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                    )}
                  {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                </div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="right"
          >
            {containerName && (
              <div className="hidden-line-with-ellipsis">
                <span className="bold light-label inline-block" style={{ minWidth: 70 }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </span>
                <span>{containerName}</span>
              </div>
            )}
            <span className="max-width inline-block hidden-line-with-ellipsis">{rawData}</span>
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  renderDuration(rowData) {
    const { intl } = this.props;
    const { duration } = rowData;
    return (
      <div>
        {CellRenderers.humanizeDuration({
          period: duration,
          intl,
          showSeconds: true,
          showSecondsWithZeroMin: true,
        })}
      </div>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { intl, credentials, systemInfo } = this.props;
    const ownerUserName = get(systemInfo, 'ownerUserName');

    const { category, patternName, patternId, anomalyLogInstance, projectOwner, startTimestamp } = rowData;
    let { projectName } = rowData;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;

    const RendererPatternTitle = ({ rowData }) => {
      const { patternNameMap } = this.state;

      const [patternName, setPatternName] = useState(get(patternNameMap, [key, patternId]));
      const [loading, setLoading] = useState(false);
      useEffect(() => {
        if (patternName === undefined) {
          setLoading(true);
          this.props.updateLastActionInfo();
          fetchGet(getEndpoint('getpatternname', 1), {
            ...credentials,
            customerName: ownerUserName,
            projectName,
            instanceName: category === 'log' ? anomalyLogInstance : null,
            patternId,
            startTime: startTimestamp,
          })
            .then((data) => {
              const { patternName } = data;
              if (!patternNameMap[key]) patternNameMap[key] = {};
              patternNameMap[key][patternId] = patternName || '';

              this.setState({ patternNameMap });
              setLoading(false);
              setPatternName(patternName || '');
              this.forceUpdate();
            })
            .catch((err) => {
              message.error(intl.formatMessage(appMessages.apiFaild));
              console.log(err);
            });
        }
      }, []);
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName, patternId },
        { hasFullName: true, hasPrefix: true },
      );
      return (
        <div>
          {loading && <Spin size="small" />}
          {!loading && <div>{patternNameStr}</div>}
        </div>
      );
    };

    return (
      <div className="max-width flex-row flex-center-align">
        <Popover content={<RendererPatternTitle rowData={rowData} />} mouseEnterDelay={0.3} placement="top">
          <div className="hidden-line-with-ellipsis">
            {
              Defaults.PatternIdNameStr(
                { patternName: get(this.state.patternNameMap, [key, patternId]) || patternName, patternId },
                { hasFullName: true, hasPrefix: true },
              ).patternNameStr
            }
          </div>
        </Popover>
        <Popover content={intl.formatMessage(eventMessages.setPatternName)} mouseEnterDelay={0.3} placement="top">
          <EditOutlined
            className="primary-color"
            style={{ marginLeft: 2 }}
            onClick={() => this.handleActionClick(rowData, 'setPatternName')}
          />
        </Popover>
      </div>
    );
  }

  @autobind
  renderCategory(rowData) {
    const { intl } = this.props;
    return (
      <div className="full-width flex-col" style={{ alignItems: 'flex-start' }}>
        {CellRenderers.logTypeRenderer({ intl, rowData, isPrediction: rowData.isPrediction })}
      </div>
    );
  }

  @autobind
  renderControl(event) {
    const { intl } = this.props;
    const { category, typeList, neuronId } = event;
    return (
      <Dropdown
        icon={<SearchOutlined />}
        itemClick={({ key }) => {
          switch (key) {
            case 'setPatternName':
              this.handleActionClick(event, 'setPatternName');
              break;
            case 'takeAction':
              this.handleEventActionClick(event);
              break;
            case 'editTriageReport':
              this.handleTriageReportClick({ event });
              break;
            case 'rootCauseAnalysis':
              this.handleRCAJumpClick(event);
              break;
            case 'lineChart':
              this.handleDetailsClick(event);
              break;
            case 'logAnalysis':
              this.handleDetailsClick(event);
              break;
            case 'originalNormalPattern':
              this.handleOriginalNormalPatternClick(event);
              break;
            case 'important':
              this.handleIgnoreClick({ event, category: key });
              break;
            case 'ignore':
              this.handleIgnoreClick({ event, category: key });
              break;
            case 'reportJira':
              this.handleReportJiraClick(event);
              break;
            case 'reportServiceNow':
              this.handleReportServiceNowClick(event);
              break;
            default:
              break;
          }
        }}
      >
        <>
          {category === 'metric' && (
            <Menu.Item key="lineChart">{intl.formatMessage(DashboardMessages.lineChart)}</Menu.Item>
          )}
          {category !== 'metric' && (
            <Menu.Item key="logAnalysis">{intl.formatMessage(appMenusMessages.logAnalysis)}</Menu.Item>
          )}
          {category !== 'metric' && typeList.includes('rare') && isNumber(neuronId) && (
            <Menu.Item key="originalNormalPattern">
              {intl.formatMessage(DashboardMessages.originalNormalPattern)}
            </Menu.Item>
          )}
          <Menu.Item key="rootCauseAnalysis">{intl.formatMessage(appMenusMessages.globalSystemRootCause)}</Menu.Item>
          {/* <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item> */}
          {/* <Menu.Item key="takeAction">{intl.formatMessage(eventMessages.takeAction)}</Menu.Item> */}
          <Menu.Item key="editTriageReport">{intl.formatMessage(eventMessages.editTriageReport)}</Menu.Item>
          <Menu.Item key="reportJira">{intl.formatMessage(eventMessages.reportJira)}</Menu.Item>
          <Menu.Item key="reportServiceNow">{intl.formatMessage(eventMessages.reportServiceNow)}</Menu.Item>
        </>
      </Dropdown>
    );
  }

  @autobind
  handleActionClick(event, actionName) {
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const activeEvent = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    this.setState({ showTakeLogActionModal: true, actionName, activeEvent });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    const { systemInfo } = this.props;
    this.setState({ showTakeLogActionModal: false, actionName: null, activeEvent: null, patternNameMap: {} }, () => {
      this.props.onClose([systemInfo.id], true);
    });
  }

  @autobind
  handleEventActionClick(incident) {
    this.setState({ activeEvent: incident, showEventActionModal: true });
  }

  @autobind
  handleTriageReportClick({ event }) {
    this.setState({ showTriageReportModal: true, activeEvent: event });
  }

  @autobind
  getProjectNameList() {
    const { systemsMap, systemInfo: globalSystemInfo } = this.props;
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === globalSystemInfo.id, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  handleRCAJumpClick(event) {
    const { environmentId, customerName, systemInfo } = this.props;
    const { isIncident, isTrace, category, type, projectName, patternId, startTimestamp, instanceList, metricList } =
      event;
    const startTime = moment.utc(startTimestamp).format(Defaults.DateFormat);

    const query = {
      environmentId,
      customerName,
      systemId: systemInfo.id,
      startTime,
      endTime: startTime,

      eventCategory: isIncident ? 'incident' : category === 'metric' ? 'metric' : isTrace ? 'trace' : 'log',
      eventPatternType: isIncident ? 'incident' : category === 'metric' ? 'metric' : type,
      eventProjectName: projectName,
      eventPatternId: patternId,
      eventInstanceName: instanceList[0],
      eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
      eventTimestamp: startTimestamp,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  }

  @autobind
  handleDetailsClick(event) {
    const { userInfo, projects, environmentId, customerName, systemInfo } = this.props;
    const {
      projectOwner,
      category,
      startTimestamp,
      endTimestamp,
      anomalyLogInstance,
      instanceList,
      metricList,
      type,
      patternId,
    } = event;

    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const startTimeObj = moment.utc(startTimestamp).startOf('day');
    const endTimeObj = moment.utc(endTimestamp).endOf('day');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    if (category === 'metric') {
      const query = {
        startTime: startTimeObj.format(Defaults.DateFormat),
        endTime: endTimeObj.format(Defaults.DateFormat),
        customerName,
        environmentId,
        systemId: systemInfo.id,

        projectName,
        instanceGroup,
        modelType,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        justInstanceList: R.join(',', instanceList),
        justSelectMetric: R.join(',', metricList),
        // withBaseline: true,
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    } else {
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const query = {
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        activeTab: type === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: project?.owner || projectOwner,
        anomalyType: type.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleOriginalNormalPatternClick(event) {
    const { userInfo, projects } = this.props;
    const { projectOwner, startTimestamp, anomalyLogInstance, instanceList, neuronId, type } = event;

    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const query = {
      projectName,
      instanceName: anomalyLogInstance || instanceList[0],
      startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      activeTab: 'clusters',
      activePatternId: neuronId,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project?.owner || projectOwner,
      anomalyType: type.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  handleIgnoreClick({ event, category }) {
    const { intl } = this.props;
    const { isIgnored, isImportant, patternName, patternId } = event;
    const flag = (category === 'ignore' && isIgnored) || (category === 'important' && isImportant);
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: flag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          }),
      onOk: this.handleIgnoreSumbit(event, category),
    });
  }

  @autobind
  handleIgnoreSumbit(incident, category) {
    return () => {
      const { intl, credentials, userInfo, systemInfo } = this.props;
      const { isIgnored, isImportant } = incident;
      const { projectOwner, anomalyLogInstance, instanceName, patternId, type } = incident;
      let { projectName } = incident;
      projectName = projectOwner !== userInfo.userName ? `${projectName}@${projectOwner}` : projectName;

      if (this.ignoreModal) {
        this.ignoreModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      let content;
      if (category === 'ignore' && !isIgnored) {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, incident.rootCausesDetailsList || []);
        content = R.join('\n', summaryList);
      }

      let operation;
      switch (category) {
        case 'ignore':
          operation = isIgnored ? 'cancelIgnoreFlag' : 'setIgnoreFlag';
          break;
        case 'important':
          operation = isImportant ? 'cancelImportantFlag' : 'setImportantFlag';
          break;
        default:
          break;
      }

      this.props.updateLastActionInfo();
      return fetchPost(
        getEndpoint('events', 1),
        {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceName,
          operation,
          nid: patternId,
          type: type === 'Incident' ? 'incident' : undefined,
          content,
        },
        {},
        false,
      )
        .then((data) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.ignoreModal) this.ignoreModal.destroy();

          this.props.onClose([systemInfo.id], true);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.ignoreModal) {
            this.ignoreModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  handleReportJiraClick(incident) {
    this.setState({ activeEvent: incident, showReportJiraModal: true });
  }

  @autobind
  handleReportServiceNowClick(incident) {
    this.setState({ activeEvent: incident, showReportServiceNowModal: true });
  }

  @autobind
  handleOverallLineChartClick() {
    this.setState({ showProjectSelector: true, onConfirmProjectSelect: this.handleOverallLineChartJump });
  }

  @autobind
  handleOverallLineChartJump(projectName) {
    const { systemInfo, environmentId, customerName, startTime, endTime } = this.props;

    const startTimeObj = moment.utc(endTime, Defaults.DateFormat).startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    // get instanceList
    const shortProjectName = projectName.split('@')[0];
    let instanceList = [];
    R.forEach(
      (item) => {
        instanceList = [...instanceList, ...item.instanceList];
      },
      R.filter((event) => event.category === 'metric' && event.projectName === shortProjectName, this.anomalyEvents),
    );
    R.forEach(
      (item) => {
        instanceList.push(item.realInstanceName);
      },
      R.filter((event) => event.category !== 'metric' && event.projectName === shortProjectName, this.anomalyEvents),
    );
    instanceList = R.take(10, R.uniq(instanceList));

    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId: systemInfo.id,

      projectName,
      instanceGroup,
      modelType,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      justInstanceList: R.join(',', instanceList),
      withBaseline: false,
    };
    this.setState({
      showProjectSelector: false,
    });
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          this.anomalyEvents = this.sortData(this.anomalyEvents, name, sortDir);

          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const {
      intl,
      projects,
      userInfo,
      startTime,
      endTime,
      customerName,
      environmentId,
      systemHealth,
      systemInfo,
      title,
      isLoading: isLoadingData,
      currentProjectName,
    } = this.props;
    const {
      isLoading,
      filterProject,
      filterInstance,
      filterPattern,
      filterMetric,
      sortBy,
      sortDirection,
      page,
      pageSize,
      optionTimelineRange,
      patternNameMap,
    } = this.state;
    const { activeEvent, showTakeLogActionModal, actionName, showEventActionModal } = this.state;

    // build action params
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let incidentPatternName = null;
    let projectName;
    let project;
    if (activeEvent) {
      const { category, patternId, anomalyLogInstance, projectOwner } = activeEvent;
      projectName =
        userInfo.userName !== projectOwner ? `${activeEvent.projectName}@${projectOwner}` : activeEvent.projectName;
      project = R.find((project) => projectName === project.projectName, projects);
      const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
      incidentPatternName = get(patternNameMap, [key, patternId]);
    }

    let { instanceOptions, patternOptions, metricOptions } = this;
    if (filterProject) {
      instanceOptions = R.filter((item) => item.projectNames.includes(filterProject), instanceOptions);
      patternOptions = R.filter((item) => item.projectNames.includes(filterProject), patternOptions);
      metricOptions = R.filter((item) => item.projectNames.includes(filterProject), metricOptions);
    }
    if (filterInstance) {
      patternOptions = R.filter((item) => item.instances.includes(filterInstance), patternOptions);
      metricOptions = R.filter((item) => item.instances.includes(filterInstance), metricOptions);
    }
    const events = R.slice((page - 1) * pageSize, page * pageSize, this.anomalyEvents);
    return (
      <Modal
        title={title}
        width={1200}
        visible
        maskClosable={false}
        footer={null}
        onCancel={() => this.props.onClose()}
        bodyStyle={{ height: 560 }}
      >
        <Spin wrapperClassName="full-width full-height spin-full-height" spinning={isLoadingData || isLoading}>
          <div className="flex-row" style={{ padding: '0 8px' }}>
            <div className="flex-grow">
              <Select
                allowClear
                showArrow={false}
                showSearch
                size="small"
                placeholder={intl.formatMessage(eventMessages.projectName)}
                style={{ width: 120 }}
                value={filterProject}
                onChange={(filterProject) => this.onChangeFilterField('filterProject', filterProject)}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {this.projectOptions.map((item) => (
                  <Select.Option key={item.projectName} value={item.projectName}>
                    {item.projectDisplayName}
                  </Select.Option>
                ))}
              </Select>
              <Select
                allowClear
                showArrow={false}
                showSearch
                size="small"
                placeholder={intl.formatMessage(eventMessages.instanceName)}
                style={{ width: 120, marginLeft: 8 }}
                optionFilterProp="value"
                value={filterInstance}
                onChange={(filterInstance) => this.onChangeFilterField('filterInstance', filterInstance)}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {instanceOptions.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.value}
                  </Select.Option>
                ))}
              </Select>
              <Select
                allowClear
                showArrow={false}
                showSearch
                size="small"
                placeholder={intl.formatMessage(eventMessages.patternId)}
                style={{ width: 120, marginLeft: 8 }}
                optionFilterProp="value"
                value={filterPattern}
                onChange={(filterPattern) => this.onChangeFilterField('filterPattern', filterPattern)}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {patternOptions.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.value}
                  </Select.Option>
                ))}
              </Select>
              <Select
                allowClear
                showArrow={false}
                showSearch
                size="small"
                placeholder={intl.formatMessage(eventMessages.metricFilter)}
                style={{ width: 120, marginLeft: 8 }}
                optionFilterProp="value"
                value={filterMetric}
                onChange={(filterMetric) => this.onChangeFilterField('filterMetric', filterMetric)}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {metricOptions.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.value}
                  </Select.Option>
                ))}
              </Select>

              <Button size="small" style={{ marginLeft: 8 }} onClick={this.handleOverallLineChartClick}>
                {intl.formatMessage(eventMessages.overallLineChart)}
              </Button>
            </div>
            <Pagination
              size="small"
              current={page}
              total={this.anomalyEvents.length}
              pageSize={pageSize}
              onChange={(page) =>
                this.setState({ page }, () => {
                  this.cellMeasureCache.clearAll();
                  if (this.listNode) this.listNode.forceUpdateGrid();
                  this.forceUpdate();
                })
              }
              showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
              showSizeChanger
              pageSizeOptions={['20', '40', '60', '100', '500', '1000']}
              onShowSizeChange={(page, pageSize) =>
                this.setState({ page, pageSize }, () => {
                  this.cellMeasureCache.clearAll();
                  if (this.listNode) this.listNode.forceUpdateGrid();
                  this.forceUpdate();
                })
              }
            />
          </div>
          <div className="flex-grow flex-min-height" style={{ padding: '8px 8px 0 8px' }}>
            <AutoSizer>
              {({ width, height }) => (
                <div className="event-list">
                  <div
                    className="event-list-header"
                    style={{
                      height: this.listHeaderHeight,
                      width,
                      paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                    }}
                  >
                    <div className="header-column" style={{ width: 80 }} onClick={this.headerClick('avgAnomalyScore')}>
                      <span>{intl.formatMessage(eventMessages.anomalyScore)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'avgAnomalyScore')}
                    </div>
                    <div
                      className="header-column"
                      style={{ width: 100 }}
                      onClick={this.headerClick('componentNameString')}
                    >
                      <span>{intl.formatMessage(eventMessages.componentName)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'componentNameString')}
                    </div>
                    <div
                      className="header-column"
                      style={{ width: 120 }}
                      onClick={this.headerClick('onlyInstanceString')}
                    >
                      <span>{intl.formatMessage(eventMessages.instanceName)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'onlyInstanceString')}
                    </div>
                    <div className="header-column" style={{ width: 100, flex: 1 }}>
                      {intl.formatMessage(eventMessages.shortDescription)}
                    </div>
                    <div className="header-column" style={{ width: 100 }} onClick={this.headerClick('duration')}>
                      <span>{intl.formatMessage(eventMessages.avgDuration)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'duration')}
                    </div>
                    <div className="header-column" style={{ width: 80 }} onClick={this.headerClick('patternId')}>
                      <span>{intl.formatMessage(appFieldsMessages.patternIdName)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'patternId')}
                    </div>
                    <div className="header-column" style={{ width: 80 }}>
                      {intl.formatMessage(appFieldsMessages.type)}
                    </div>
                    <div className="header-column" style={{ minWidth: 90 }}>
                      {intl.formatMessage(eventMessages.metricType)}
                    </div>
                    <div className="header-column" style={{ width: 100, flex: 1 }}>
                      {optionTimelineRange && (
                        <EChart width="100%" height={40} option={optionTimelineRange} onEvents={{}} />
                      )}
                    </div>
                    <div className="header-column" style={{ width: 60 }} />
                    <div className="header-column" style={{ width: 40 }} />
                  </div>
                  <List
                    className="event-list-grid"
                    ref={(listNode) => {
                      this.listNode = listNode;
                    }}
                    width={width}
                    height={height - this.listHeaderHeight}
                    rowCount={events.length}
                    overscanRowCount={4}
                    deferredMeasurementCache={this.cellMeasureCache}
                    rowHeight={this.cellMeasureCache.rowHeight}
                    rowRenderer={this.renderAnomalyListItem(events)}
                    onScrollbarPresenceChange={({ horizontal, vertical }) => {
                      if (vertical) {
                        this.listNodeHeaderScrollbar = true;
                      } else {
                        this.listNodeHeaderScrollbar = false;
                      }
                      this.forceUpdate();
                    }}
                  />
                </div>
              )}
            </AutoSizer>
          </div>

          {this.state.showSystemEventModel && (
            <EventShortListModal
              customerName={customerName}
              environmentId={environmentId}
              systemId={get(systemHealth, 'systemId')}
              startTime={startTime}
              endTime={endTime}
              intervalInMinutes={get(systemHealth, 'intervalInMinutes', 1)}
              selectInstance={null}
              timelines={activeEvent.timelines}
              onClose={(systemIds) => {
                this.setState({ showSystemEventModel: false, activeEvent: null });
                if (systemIds) this.props.onClose(systemIds);
              }}
            />
          )}
          {this.state.showTriageReportModal && (
            <TriageReportModal
              environmentId={environmentId}
              systemId={get(systemHealth, 'systemId')}
              incident={activeEvent}
              onClose={() => this.setState({ showTriageReportModal: false, activeEvent: null })}
            />
          )}
          {showTakeLogActionModal && (
            <TakeEventTriageModal
              actionDetailsName={actionName}
              incident={activeEvent}
              incidentPatternName={incidentPatternName}
              project={project}
              projectName={activeEvent.fullProjectName}
              instanceGroup={instanceGroup}
              eventType={activeEvent.type}
              onClose={() => this.setState({ showTakeLogActionModal: false })}
              onNameChanged={this.handlePatternNameChanged}
            />
          )}
          {showEventActionModal && (
            <EventActionModal
              incident={activeEvent}
              project={project}
              projectName={projectName}
              projectNameList={this.getProjectNameList()}
              instanceGroup={instanceGroup}
              onClose={() => this.setState({ showEventActionModal: false })}
            />
          )}
          {this.state.showProjectSelector && (
            <ProjectSelectorModal
              system={systemInfo}
              events={events}
              onConfirm={this.state.onConfirmProjectSelect}
              onClose={() => this.setState({ showProjectSelector: false })}
            />
          )}
          {this.state.showReportJiraModal && (
            <ReportJiraModal
              incident={activeEvent}
              projectName={projectName}
              onClose={() => this.setState({ showReportJiraModal: false })}
            />
          )}
          {this.state.showReportServiceNowModal && (
            <ReportServiceNowModal
              incident={activeEvent}
              projectName={projectName}
              onClose={() => this.setState({ showReportServiceNowModal: false })}
            />
          )}
        </Spin>
      </Modal>
    );
  }
}

const GlobalHealthAnomaliesModal = injectIntl(GlobalHealthAnomaliesModalCore);
export default connect(
  (state) => {
    const { loadStatus, timezoneOffset, projects, systemsMap, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      loadStatus,
      timezoneOffset,
      projects,
      systemsMap,
      credentials,
      userInfo,
      currentTheme,
    };
  },
  { updateLastActionInfo },
)(GlobalHealthAnomaliesModal);
