import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { sortableHandle } from 'react-sortable-hoc';
import { Spin, Empty } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer, Popover } from '../../../lib/fui/react';
import { buildUrl, Defaults, parseJSON } from '../../../common/utils';
import { MoveIcon } from '../../../lib/fui/icons';

import { eventMessages } from '../../../common/metric/messages';

const DragHandle = sortableHandle(() => (
  <span className="hover-display-item" style={{ cursor: 'move', position: 'absolute', right: 8, top: 8 }}>
    <MoveIcon style={{ fontSize: 16 }} />
  </span>
));

const InsightsIncidentPatternStats = (props) => {
  // eslint-disable-next-line
  const { intl, name } = props;
  const {
    // eslint-disable-next-line
    push,
    // eslint-disable-next-line
    updateLastActionInfo,
    // eslint-disable-next-line
    credentials,
    // eslint-disable-next-line
    userInfo,

    // eslint-disable-next-line
    refresh,
    // eslint-disable-next-line
    refreshTime,
    // eslint-disable-next-line
    environmentId,
    // eslint-disable-next-line
    systemId,
    // eslint-disable-next-line
    customerName,
    // eslint-disable-next-line
    startTime,
    // eslint-disable-next-line
    globalInfo,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [eventList, setData] = useState([]);

  useEffect(() => {
    let cancel = false;

    // UNSAFE_componentWillReceiveProps
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      systemId &&
      systemInfo &&
      startTime
    ) {
      setLoading(true);
      updateLastActionInfo();
      fetchGet(getEndpoint('insights/RootCauseStats'), {
        ...credentials,
        environmentName: environmentId,
        systemIds: JSON.stringify([{ id: systemId }]),
        customerName: systemInfo.ownerUserName,
        startTime,
        categories: JSON.stringify(['instance/pattern']),
      })
        .then((data) => {
          if (cancel) return;
          const rootCauseMap = get(parseJSON(get(data, 'RootCauseMap')) || {}, systemId, {});
          let dataList = get(rootCauseMap, 'counterInstancePatternIdWithTimestampMap', []);
          dataList = R.map((item) => {
            const { instanceName, patternId } = item[0];
            const { count, eventTimestamp, projectName } = item[1];
            const patternIdMatch = R.match(/^Pattern\[(\d+)\]/, patternId);
            const onlyPatternId = patternIdMatch.length > 0 ? patternIdMatch[1] : patternId;
            return { projectName, instanceName, patternId: onlyPatternId, count, eventTimestamp };
          }, dataList);
          dataList = R.sortWith([R.descend(R.prop('count'))], dataList);

          setData(dataList);
          setLoading(false);
        })
        .catch((err) => {
          if (cancel) return;
          console.error(err);
          setData([]);
          setLoading(false);
        });
    }

    return () => (cancel = true);
  }, [refresh, refreshTime, environmentId, systemId, customerName]);

  const handlePatternClick = (item) => {
    const { patternId, instanceName, eventTimestamp, projectName } = item;
    const day = moment.utc(eventTimestamp).format(Defaults.DateFormat);

    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const project = R.find((project) => project.projectName === projectName, projectNameSet);
    const isDelpoyment = project && project.dataType === 'Deployment';

    const query = {
      environmentId,
      systemId,
      customerName,
      startTime: day,
      endTime: day,

      componentFilter: instanceName,
      patternIdFilter: patternId,
    };
    if (isDelpoyment) {
      query.defaultAlertPanel = 'change';
    }
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  };

  const cellMeasureCache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 28,
  });

  return (
    <div
      className="full-height full-width flex-col health-card hover-display"
      style={{ backgroundColor: 'white', borderRadius: '2em', padding: '8px 8px', position: 'relative' }}
    >
      <div
        className="flex-row flex-center-justify bold link"
        style={{ textAlign: 'center', margin: '0 14px 8px 14px', height: 34 }}
        onClick={() => {
          push(buildUrl(BaseUrls.GlobalSystemRootCause, {}, { startTime, customerName, environmentId, systemId }));
        }}
      >
        {name}
      </div>
      <DragHandle />

      <div className="flex-grow flex-col flex-min-height" style={{ padding: '0px 8px' }}>
        <Spin spinning={isLoading} wrapperClassName="spin-full-height full-width full-height">
          {eventList.length === 0 && (
            <div className="full-height flex-col flex-center-align flex-center-justify">
              <Empty imageStyle={{ height: 60 }} />
            </div>
          )}
          {eventList.length > 0 && (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowCount={eventList.length}
                  overscanRowCount={4}
                  deferredMeasurementCache={cellMeasureCache}
                  rowHeight={cellMeasureCache.rowHeight}
                  rowRenderer={({ key, index: rowIndex, style, parent }) => {
                    const item = eventList[rowIndex];
                    if (!item) return null;

                    let color = '#797D92';
                    switch (rowIndex) {
                      case 0:
                        color = '#FF5142';
                        break;
                      case 1:
                        color = '#FF8A61';
                        break;
                      case 2:
                        color = '#FEB207';
                        break;
                      default:
                        break;
                    }
                    return (
                      <CellMeasurer
                        key={key}
                        cache={cellMeasureCache}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={rowIndex}
                      >
                        <div
                          className="flex-row flex-min-width flex-center-align"
                          style={{ ...style, fontSize: 14, cursor: 'pointer' }}
                          onClick={() => handlePatternClick(item)}
                        >
                          <div className="circle" style={{ marginRight: 16, background: color }}>
                            <div className="circle-text">{rowIndex + 1}</div>
                          </div>
                          <div className="flex-grow flex-min-width">
                            <Popover
                              title={null}
                              content={
                                <div className="flex-col overflow-y-auto" style={{ maxWidth: 320, maxHeight: 180 }}>
                                  <div className="flex-row">
                                    <div style={{ fontWeight: 'bold', width: 120 }}>
                                      {intl.formatMessage(eventMessages.componentName)}:
                                    </div>
                                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                                      {item.instanceName}
                                    </div>
                                  </div>

                                  <div className="flex-row">
                                    <div style={{ fontWeight: 'bold', width: 120 }}>
                                      {intl.formatMessage(eventMessages.patternId)}:
                                    </div>
                                    <div className="flex-grow">{item.patternId}</div>
                                  </div>
                                </div>
                              }
                              placement="right"
                              mouseEnterDelay={0.3}
                            >
                              <div className="max-width flex-row">
                                <div className="flex-min-width hidden-line-with-ellipsis inline-block">
                                  {item.instanceName}
                                </div>
                                <div style={{ paddingLeft: 4 }}>[{item.patternId}]</div>
                              </div>
                            </Popover>
                          </div>
                          <div
                            className="flex-row flex-end-justify"
                            style={{ minWidth: 40, color, margin: '0 8px 0 4px' }}
                          >
                            {item.count}
                          </div>
                        </div>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default InsightsIncidentPatternStats;
