/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { autobind } from 'core-decorators';
import { RightOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Select, message } from 'antd';
import * as R from 'ramda';
import qs from 'qs';

import { projectWizardMessages, settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { CodeMirror } from '../../../../lib/fui/react';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  componentState: Object,
  credentials: Object,
};

class SnowSetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl } = props;
    const { componentState } = props;
    this.state = {
      dbName: '',
      dbAddress: '',
      dbPassword: '',
      warehouse: '',
      query: '',
      snowflakeUserName: '',

      dataType: 'Metric',
      samplingInterval: 5,
      samplingUnit: 1,

      loading: false,
      verified: true,

      ...(componentState || {}),
    };
    this.dataTypeOptions = [
      { label: intl.formatMessage(settingsMessages.Metric), value: 'Metric' },
      { label: intl.formatMessage(settingsMessages.Log), value: 'Log' },
      { label: intl.formatMessage(settingsMessages.Alert), value: 'Alert' },
      { label: intl.formatMessage(settingsMessages.Incident), value: 'Incident' },
    ];
    this.samplingUnitOptions = [
      // { label: 'second', value: 1 },
      { label: 'minute', value: 1 },
      { label: 'hour', value: 60 },
      { label: 'day', value: 1440 },
    ];
  }

  componentDidMount() {
    this.props.saveProjectInfo('snowflake', { dataType: this.state.dataType }, this.state);
  }

  @autobind
  handleDataTypeChange(dataType) {
    let samplingInterval = 5;
    if (dataType !== 'Metric') {
      samplingInterval = 10;
    }

    this.setState({ samplingInterval }, () => {
      this.props.saveProjectInfo('snowflake', { dataType }, this.state);
    });
  }

  @autobind
  handleVerify(data) {
    const { intl, credentials } = this.props;
    const { dataType } = this.state;
    const projectCreationType = dataType === 'Metric' ? 'Snowflake_Metric' : 'Snowflake_Log';
    return fetchPost(getEndpoint(`project-key-verify`), {
      ...credentials,
      projectCreationType,
      ...data,
    })
      .then((res) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ loading: false }, () => {
          this.props.saveProjectInfo('snowflake', { dataType }, { ...this.state });
        });
        return true;
      })
      .catch((e) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ loading: false }, () => {
          this.props.saveProjectInfo('snowflake', { dataType }, { ...this.state });
        });
        return false;
      });
  }

  @autobind
  async handleConfirmClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const {
      snowflakeUserName,
      dbName,
      dbAddress,
      dbPassword,
      dataType,
      samplingInterval,
      samplingUnit,
      warehouse,
      query,
    } = this.state;
    this.setState({ loading: true });
    const verifyState = await this.handleVerify({
      operation: 'verify',
      snowflakeUserName,
      database: dbName,
      dbAddress,
      password: dbPassword,
      warehouse,
      query,
      dataType,
    });
    if (!verifyState) return;
    this.props.createProject(
      'snowflake',
      {
        snowflakeUserName,
        database: dbName,
        dbAddress,
        password: dbPassword,
        warehouse,
        query,
        dataType,
        samplingInterval: Number(samplingInterval) * samplingUnit,
      },
      this.state,
    );
  }

  render() {
    const { intl, isLoading, currentTheme } = this.props;
    const {
      dbName,
      dbAddress,
      dbPassword,
      dataType,
      samplingInterval,
      samplingUnit,
      warehouse,
      query,
      snowflakeUserName,
      loading,
    } = this.state;

    const hasError =
      !dbName ||
      !dbAddress ||
      !dbPassword ||
      !dataType ||
      !samplingInterval ||
      !warehouse ||
      !query ||
      !snowflakeUserName ||
      this.props.hasError;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.snowIntro),
          }}
        />

        <Card>
          <Form layout="vertical">
            <Form.Item
              label="Snowflake Username"
              validateStatus={!snowflakeUserName ? 'error' : 'success'}
              help={!snowflakeUserName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={snowflakeUserName} onChange={(e) => this.setState({ snowflakeUserName: e.target.value })} />
            </Form.Item>

            <Form.Item
              label="Database Name"
              validateStatus={!dbName ? 'error' : 'success'}
              help={!dbName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={dbName} onChange={(e) => this.setState({ dbName: e.target.value })} />
            </Form.Item>

            <Form.Item
              label="Database Address"
              validateStatus={!dbAddress ? 'error' : 'success'}
              help={!dbAddress ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={dbAddress} onChange={(e) => this.setState({ dbAddress: e.target.value })} />
            </Form.Item>

            <Form.Item
              label="Database Password"
              validateStatus={!dbPassword ? 'error' : 'success'}
              help={!dbPassword ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={dbPassword}
                onChange={(e) => this.setState({ dbPassword: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              label="Warehouse"
              validateStatus={!warehouse ? 'error' : 'success'}
              help={!warehouse ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={warehouse} onChange={(e) => this.setState({ warehouse: e.target.value })} />
            </Form.Item>

            <Form.Item
              label="Query"
              validateStatus={!query ? 'error' : 'success'}
              help={!query ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <CodeMirror
                options={{ lineNumbers: true, mode: 'sql', theme: currentTheme === 'dark' ? 'material' : 'xq-light' }}
                onBeforeChange={(editor, data, value) => {
                  this.setState({ query: value });
                }}
                value={query || ''}
              />
            </Form.Item>

            <Form.Item
              label="Data type"
              validateStatus={!dataType ? 'error' : 'success'}
              help={!dataType ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                options={this.dataTypeOptions}
                value={dataType}
                onChange={(dataType) => {
                  this.setState({ dataType }, () => {
                    this.handleDataTypeChange(dataType);
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.samplingUnitOptions}
                  value={samplingUnit}
                  onChange={(samplingUnit) => this.setState({ samplingUnit })}
                />
              </div>
            </Form.Item>
          </Form>
        </Card>

        <div style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}>
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasError}
            onClick={this.handleConfirmClick}
            loading={isLoading || loading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

export default SnowSetting;
