/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { DeleteOutlined, FileAddOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Select, DatePicker, Divider, Checkbox, Pagination, Tabs, Alert, Input } from 'antd';

import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal, Container } from '../../../lib/fui/react';
import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  onOk: Function,
  onCancel: Function,
  isSubmitting: Boolean,
  // eslint-disable-next-line
  customerName: String,
  causalGroup: Object,
  view: String,
  incident: Object,
  // eslint-disable-next-line
  metaData: Object,

  intl: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class EditDependencyModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { view, incident } = props;

    let dependencyDates = [moment.utc(), moment.utc()];
    let relationList = [];
    let noRelationList = [];
    let intraMayNotImapctList = [];
    if (view === 'edit' && incident) {
      const { startTime, endTime } = incident;
      dependencyDates = [moment.utc(startTime), moment.utc(endTime)];
      relationList = this.parseData(incident.relationList || []);
      noRelationList = this.parseData(incident.noRelationList || []);
      intraMayNotImapctList = this.parseData(incident.intraMayNotImapctList || []);
    }

    this.state = {
      isLoading: false,
      dependencyDates,
      activeKey: 'relation',

      // relationList,
      relationPage: 1,
      relationPageSize: 20,

      // noRelationList,
      noRelationPage: 1,
      noRelationPageSize: 20,

      // intraMayNotImapct
      intraMayNotImapctPage: 1,
      intraMayNotImapctPageSize: 20,

      searchProject: '',
      searchComponet: '',
    };
    this.relationList = relationList;
    this.noRelationList = noRelationList;
    this.intraMayNotImapctList = intraMayNotImapctList;

    this.allComponents = [];
    this.allComponentOptions = [];
    this.allProjects = [];
    this.allProjectsOptions = [];
    this.allSelectOptions = [];
    this.instancePageSize = 60;

    this.searchProject = '';
    this.searchComponet = '';
    this.cmp = (x, y) => x.id === y.id;
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.causalGroup !== this.props.causalGroup) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { customerName, causalGroup, metaData, projects } = props;

    if (customerName && causalGroup) {
      this.setState({ isLoading: true });
      this.props.updateLastActionInfo();
      let allComponents = [];
      let allProjects = [];

      const projectMetaData = metaData?.projectMetaData || [];
      R.forEach((i) => {
        allProjects.push(JSON.parse(i?.m || '{}')?.projectName);
      }, projectMetaData);

      const instanceMetaData = JSON.parse(metaData?.instanceMetaData || '{}');
      R.forEachObjIndexed((val) => {
        const { parentName, componentName, causalInstanceName, projectName } = val;
        // only use component from instance
        if (!parentName || (parentName && causalInstanceName !== componentName) || true) {
          allComponents.push({ componentName, projectName });
        }
      }, instanceMetaData);

      allComponents = R.sort(
        (a, b) => a.componentName.toLowerCase().localeCompare(b.componentName.toLowerCase()),
        R.uniqBy((x) => x.componentName, allComponents),
      );
      allComponents = R.filter((item) => Boolean(item.componentName), allComponents);
      this.allComponents = R.map((item) => item.componentName, allComponents);
      this.allComponentOptions = R.map(
        (item) => ({
          id: item.componentName,
          label: item.componentName,
          projectName: item.projectName,
          type: 'componentLevel',
        }),
        allComponents,
      );

      allProjects = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(allProjects));
      allProjects = R.filter((item) => Boolean(item), allProjects);
      this.allProjects = allProjects;
      this.allProjectsOptions = R.map((item) => {
        const projectDisplayName =
          R.find((_item) => item === _item.projectShortName, projects || [])?.projectDisplayName || item;
        return { id: item, label: projectDisplayName, type: 'projectLevel' };
      }, allProjects);

      this.allSelectOptions = [...this.allProjectsOptions, ...this.allComponentOptions];

      this.forceUpdate();
      this.setState({ isLoading: false });
    }
  }

  @autobind
  parseData(eventList) {
    const preRelationList = [];

    const relationMap = {};
    R.forEach((relation) => {
      const { s, t } = relation;
      if (!R.has(`{ "id":"${s.id}", "type": "${s.type}" }`, relationMap)) {
        relationMap[`{ "id":"${s.id}", "type": "${s.type}" }`] = [{ id: t.id, type: t.type }];
      } else {
        relationMap[`{ "id":"${s.id}", "type": "${s.type}" }`].push({ id: t.id, type: t.type });
      }
    }, eventList || []);

    // build multiple replations map
    const multipleReplationMap = {};
    R.forEachObjIndexed((targets, source) => {
      const newTargets = R.sort((a, b) => a.id.toLowerCase().localeCompare(b.id.toLowerCase()), R.uniq(targets));
      const targetsKey = JSON.stringify(newTargets);
      if (!R.has(targetsKey, multipleReplationMap)) {
        multipleReplationMap[targetsKey] = { targets: newTargets, sources: [JSON.parse(source || '{}')] };
      } else {
        multipleReplationMap[targetsKey].sources.push(JSON.parse(source || '{}'));
      }
    }, relationMap);

    let index = 0;
    R.forEachObjIndexed((val, key) => {
      const { targets, sources } = val;
      preRelationList.push({
        id: index,
        sources,
        targets,
        sourcesRef: React.createRef(),
        targetsRef: React.createRef(),
        sourcesParentRef: React.createRef(),
        targetsParentRef: React.createRef(),
        // eslint-disable-next-line
        sources_onBlur_disable: false,
        // eslint-disable-next-line
        sources_dropdown_open: false,
        // eslint-disable-next-line
        targets_onBlur_disable: false,
        // eslint-disable-next-line
        targets_dropdown_open: false,
      });
      index += 1;
    }, multipleReplationMap);

    return preRelationList;
  }

  @autobind
  handleAddRelationClick(tabName) {
    this[`${tabName}List`] = [
      {
        id: this[`${tabName}List`].length + 1,
        sources: null,
        targets: null,
        sourcesRef: React.createRef(),
        targetsRef: React.createRef(),
        sourcesParentRef: React.createRef(),
        targetsParentRef: React.createRef(),
        // eslint-disable-next-line
        sources_onBlur_disable: false,
        // eslint-disable-next-line
        sources_dropdown_open: false,
        // eslint-disable-next-line
        targets_onBlur_disable: false,
        // eslint-disable-next-line
        targets_dropdown_open: false,
      },
      ...this[`${tabName}List`],
    ];
    this.forceUpdate();
  }

  @autobind
  handleRemoceDependencyClick(tabName, rowData) {
    const { id } = rowData;
    this[`${tabName}List`] = R.filter((x) => x.id !== id, this[`${tabName}List`]);
    this.forceUpdate();
  }

  @autobind
  renderRelation(tabName, eventList) {
    const { intl, view } = this.props;
    const relationPage = this.state[`${tabName}Page`];
    const relationPageSize = this.state[`${tabName}PageSize`];
    return (
      <div className="full-height flex-col">
        <div className="flex-row" style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={() => this.handleAddRelationClick(tabName)}>
            <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          <div className="flex-grow" />
          {view === 'edit' && (
            <Pagination
              current={relationPage}
              total={eventList.length}
              pageSize={relationPageSize}
              onChange={(page) => {
                this.setState({ [`${tabName}Page`]: page });
              }}
              showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
              showSizeChanger
              pageSizeOptions={['20', '40', '60', '100', '500', '1000']}
              onShowSizeChange={(page, pageSize) => {
                this.setState({ [`${tabName}Page`]: page, relationPageSize: pageSize });
              }}
            />
          )}
        </div>

        <div className="flex-grow flex-col flex-min-height">
          <div className="event-list flex-grow flex-col flex-min-height">
            <div className="event-list-header" style={{ height: 40, width: '100%' }}>
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(causalMessages.source)}
              </div>
              <div className="header-column  flex-center-justify" style={{ width: 100 }} />
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(causalMessages.target)}
              </div>
              <div className="header-column" style={{ width: 110 }} />
            </div>
            <div className="event-list-grid flex-grow overflow-y-auto">
              {R.addIndex(R.map)(
                (rowData, index) => this.renderDependencyRelation(tabName, rowData, index),
                view === 'edit'
                  ? R.slice((relationPage - 1) * relationPageSize, relationPage * relationPageSize, eventList)
                  : eventList,
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  renderDependencyRelation(tabName, rowData, index) {
    const { intl } = this.props;
    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 30, flex: 1, padding: '4px 6px' }} ref={rowData.sourcesParentRef}>
          {this.renderMultipleSelect(tabName, rowData, 'sources')}
        </div>
        <div className="row-column flex-center-justify" style={{ width: 100 }}>
          {`->`}
          {(tabName === 'noRelation' || tabName === 'intraMayNotImapct') && (
            <CloseCircleOutlined style={{ color: 'red', marginLeft: 4, fontSize: 14 }} />
          )}
        </div>
        <div className="row-column" style={{ width: 120, flex: 1, padding: '4px 6px' }} ref={rowData.targetsParentRef}>
          {this.renderMultipleSelect(tabName, rowData, 'targets')}
        </div>
        <div className="row-column" style={{ width: 110 }}>
          <Button size="small" onClick={() => this.handleRemoceDependencyClick(tabName, rowData)}>
            <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  getFilterValueRegex({ searchValue, options }) {
    let regex = null;
    try {
      regex = new RegExp(searchValue, 'gi');
    } catch (error) {
      // console.log(error)
    }
    if (searchValue && regex) {
      return R.filter((item) => regex.test(item), options);
    }
    return options;
  }

  @autobind
  getFilterLabelValueRegex({ searchValue, options, type = 'label' }) {
    let regex = null;
    try {
      regex = new RegExp(searchValue, 'gi');
    } catch (error) {
      // console.log(error)
    }
    if (searchValue && regex) {
      return R.filter((item) => regex.test(item[type]), options);
    }
    return options;
  }

  @autobind
  getFilterUtilValue({ searchValue, options }) {
    let regex = null;
    try {
      regex = new RegExp(searchValue, 'gi');
    } catch (error) {
      // console.log(error)
    }
    return R.filter((item) => {
      const newLabel = `${item.label}${item.projectName ? ` (${item.projectName})` : ''}`;
      if (searchValue && regex) {
        return regex.test(newLabel);
      }
      return true;
    }, options);
  }

  @autobind
  projectSearchRender({ rowData, field, fieldVal }) {
    const { intl } = this.props;
    const { searchProject } = this.state;

    const filterProjectIds = this.getFilterValueRegex({ searchValue: this.searchProject, options: this.allProjects });
    const selectAll = R.difference(filterProjectIds, fieldVal || []).length === 0;

    return (
      <div>
        <div style={{ fontSize: 14, fontWeight: 'bold', lineHeight: '30px' }}>Project lists</div>
        <div className="flex-row flex-center-align" style={{ margin: '4px 0' }}>
          <div className="flex-row flex-center-align">
            <Checkbox
              checked={selectAll}
              style={{ marginLeft: 12, marginRight: 4 }}
              onChange={(e) => {
                const { checked } = e.target;
                const filterValue = this.getFilterLabelValueRegex({
                  searchValue: this.searchProject,
                  options: this.allProjectsOptions,
                });
                const diff = R.differenceWith(this.cmp, rowData[field] || [], filterValue);
                rowData[field] = checked ? [...diff, ...filterValue] : diff;
                this.forceUpdate();
              }}
            />
            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
          </div>
          <Input.Search
            size="small"
            allowClear
            className="flex-grow"
            style={{ marginLeft: 12 }}
            value={searchProject}
            enterButton={<Button icon={<SearchOutlined />} />}
            onChange={(e) => {
              rowData[`${field}Ref`].current.blur();
              e.preventDefault();
              e.stopPropagation();
              this.setState({ searchProject: e.target.value });
            }}
            placeholder={intl.formatMessage(eventMessages.projectName)}
            onSearch={(value) => {
              rowData.instancePageIndex = 1;
              this.searchProject = value;
              this.forceUpdate();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 8) e.stopPropagation();
            }}
          />
        </div>
      </div>
    );
  }

  @autobind
  componentSearchRender({ rowData, field, fieldVal }) {
    const { intl } = this.props;
    const { searchComponet } = this.state;

    const filterComponentIds = R.map(
      (item) => item.id,
      this.getFilterUtilValue({
        searchValue: this.searchComponet,
        options: this.allComponentOptions,
      }),
    );
    const selectAll = R.difference(filterComponentIds, fieldVal || []).length === 0;

    return (
      <div style={{ borderTop: '4px dashed var(--virtualized-table-border-color)' }}>
        <div style={{ fontSize: 14, fontWeight: 'bold', lineHeight: '30px' }}>Component lists</div>
        <div className="flex-row flex-center-align" style={{ margin: '4px 0' }}>
          <div className="flex-row flex-center-align">
            <Checkbox
              checked={selectAll}
              style={{ marginLeft: 12, marginRight: 4 }}
              onChange={(e) => {
                const { checked } = e.target;
                const filterValue = this.getFilterUtilValue({
                  searchValue: this.searchComponet,
                  options: this.allComponentOptions,
                });
                const diff = R.differenceWith(this.cmp, rowData[field] || [], filterValue);
                rowData[field] = checked ? [...diff, ...filterValue] : diff;
                this.forceUpdate();
              }}
            />
            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
          </div>
          <Input.Search
            size="small"
            allowClear
            className="flex-grow"
            style={{ marginLeft: 12 }}
            value={searchComponet}
            enterButton={<Button icon={<SearchOutlined />} />}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ searchComponet: e.target.value });
            }}
            placeholder={intl.formatMessage(eventMessages.componentName)}
            onSearch={(value) => {
              rowData.instancePageIndex = 1;
              this.searchComponet = value;
              this.forceUpdate();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 8) e.stopPropagation();
            }}
          />
        </div>
      </div>
    );
  }

  @autobind
  getSelectVal(val, list, field) {
    const relationVal = [];
    R.forEach((_item) => {
      R.forEach((item) => {
        if (!R.find(R.propEq('id', item.id))(this.allSelectOptions)) {
          relationVal.push(item.id);
        }
      }, _item[field] || []);
    }, list || []);
    val = [...val, ...relationVal];
    val = R.uniq(val);

    let newVal = [];
    R.forEach((item) => {
      const findOption = R.find(R.propEq('id', item))(this.allSelectOptions);
      if (findOption) {
        newVal.push(findOption);
      } else {
        const fieldOption = [];
        R.forEach((_item) => {
          const findFieldOption = R.find(R.propEq('id', item))(_item[field] || {});
          if (findFieldOption) {
            if (!R.find((x) => x.id === findFieldOption.id, fieldOption)) {
              fieldOption.push(findFieldOption);
            }
          }
        }, list);
        newVal = [...newVal, ...fieldOption];
      }
    }, val);

    return newVal;
  }

  @autobind
  renderMultipleSelect(tabName, rowData, field) {
    const fieldVal = R.map((item) => item.id, get(rowData, field) || []);

    const { instancePageIndex = 1 } = rowData;

    const { allComponentOptions, allProjectsOptions, noRelationList, relationList, intraMayNotImapctList } = this;

    const isIntraMayNotImapct = tabName === 'intraMayNotImapct';

    let offset = [100];
    const sourcesParentDom = rowData.sourcesParentRef.current;
    const targetsParentDom = rowData.targetsParentRef.current;
    if (sourcesParentDom?.getBoundingClientRect || targetsParentDom?.getBoundingClientRect) {
      if (field === 'sources') {
        const width = sourcesParentDom?.getBoundingClientRect()?.width || 100;
        const height = sourcesParentDom?.getBoundingClientRect()?.height || 0;
        offset = [width - 10, -height + 10];
      } else if (field === 'targets') {
        const width = targetsParentDom?.getBoundingClientRect()?.width || -100;
        const height = targetsParentDom?.getBoundingClientRect()?.height || 0;
        offset = [-width + 10, -height + 10];
      }
    }

    const pageSizes = this.instancePageSize;
    const pageIndex = instancePageIndex - 1;

    const projectFilterOptions = this.getFilterLabelValueRegex({
      searchValue: this.searchProject,
      options: allProjectsOptions,
    });
    const projectOptions = R.slice(pageIndex * pageSizes, instancePageIndex * pageSizes, projectFilterOptions);
    const projectCounts =
      projectOptions.length === 0 ? projectFilterOptions.length : pageSizes * pageIndex + projectOptions.length;

    const componetFilterOptions = this.getFilterUtilValue({
      searchValue: this.searchComponet,
      options: allComponentOptions,
    });
    const componentStarts = pageIndex * pageSizes - projectCounts < 0 ? 0 : pageIndex * pageSizes - projectCounts;
    const componetOptions = R.slice(
      componentStarts,
      instancePageIndex * pageSizes - projectCounts,
      componetFilterOptions,
    );

    let totalList = [...allProjectsOptions, ...(isIntraMayNotImapct ? [] : allComponentOptions)];
    if (this.searchProject || this.searchComponet) {
      totalList = [...projectFilterOptions, ...(isIntraMayNotImapct ? [] : componetFilterOptions)];
    }

    return (
      <Select
        allowClear
        size="small"
        mode="multiple"
        listHeight={340}
        showSearch={false}
        filterOptio={false}
        value={fieldVal || []}
        optionLabelProp="title"
        dropdownAlign={{ offset }}
        optionFilterProp="children"
        ref={rowData[`${field}Ref`]}
        placeholder="Select instances"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ maxWidth: 650, minWidth: 400 }}
        className="full-width no-count-num"
        open={rowData[`${field}_dropdown_open`]}
        onInputKeyDown={(e) => {
          if (R.includes(e.keyCode, [8, 13]) && e.target.value.length === 0) e.stopPropagation();
        }}
        onChange={(val) => {
          const newVal = [];
          // if (tabName === 'relation') {
          //   newVal = this.getSelectVal(val, relationList, field);
          // } else if (tabName === 'noRelation') {
          //   newVal = this.getSelectVal(val, noRelationList, field);
          // } else if (tabName === 'intraMayNotImapct') {
          //   newVal = this.getSelectVal(val, intraMayNotImapctList, field);
          // }

          R.forEach((v) => {
            const opt = R.find((x) => x.id === v, this.allSelectOptions);
            if (opt) {
              newVal.push(opt);
            }
          }, val);

          rowData[field] = newVal;
          this.forceUpdate();
        }}
        onDropdownVisibleChange={(open) => {
          if (open) {
            rowData.instancePageIndex = 1;
            // eslint-disable-next-line
            rowData.sources_dropdown_open = false;
            // eslint-disable-next-line
            rowData.targets_dropdown_open = false;
          } else {
            this.searchProject = '';
            this.searchComponet = '';
            this.setState({ searchProject: '', searchComponet: '' });
          }
          if (!rowData[`${field}_onBlur_disable`]) {
            rowData[`${field}_dropdown_open`] = open;
          }
          this.forceUpdate();
        }}
        dropdownRender={(menu) => (
          <div onKeyDown={(e) => e.stopPropagation()}>
            <Divider style={{ margin: '2px 0' }} />
            {menu}
            <Divider style={{ margin: '2px 0' }} />
            <div
              style={{ padding: '4px 8px' }}
              onMouseDown={(event) => event.preventDefault()}
              onMouseEnter={() => {
                rowData[`${field}_onBlur_disable`] = true;
                this.forceUpdate();
              }}
              onMouseLeave={() => {
                rowData[`${field}_onBlur_disable`] = false;
                this.forceUpdate();
              }}
            >
              <Pagination
                size="small"
                total={totalList.length}
                current={instancePageIndex}
                onChange={(instancePageIndex) => {
                  rowData.instancePageIndex = instancePageIndex;
                  rowData[`${field}Ref`].current.focus();
                  rowData[`${field}Ref`].current.scrollTo(0);
                  this.forceUpdate();
                }}
                showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                pageSize={this.instancePageSize}
                showSizeChanger
                pageSizeOptions={['20', '40', '60', '80', '100', '500', '1000']}
                onShowSizeChange={(current, pageSize) => {
                  this.instancePageSize = pageSize;
                  rowData.instancePageIndex = current;
                  rowData[`${field}Ref`].current.focus();
                  this.forceUpdate();
                }}
              />
            </div>
          </div>
        )}
      >
        <Select.OptGroup label={this.projectSearchRender({ rowData, field, fieldVal })}>
          {projectOptions.length > 0 ? (
            R.map(
              (item) => (
                <Select.Option className="hide-icon" key={item.id} value={item.id} title={item.label}>
                  <Checkbox
                    checked={(fieldVal || []).includes(item.id)}
                    onChange={(e) => null}
                    style={{ marginRight: 8 }}
                  />
                  {item.label}
                </Select.Option>
              ),
              projectOptions,
            )
          ) : (
            <Select.Option className="hide-icon" disabled key="projectNull" value="projectNull">
              <div
                className="flex-row flex-center-align flex-center-justify"
                style={{ width: '100%', height: 24, fontSize: 16, color: 'gray' }}
              >
                {projectOptions.length > 0 ? 'See previous page' : 'No Data'}
              </div>
            </Select.Option>
          )}
        </Select.OptGroup>
        {tabName !== 'intraMayNotImapct' && (
          <Select.OptGroup label={this.componentSearchRender({ rowData, field, fieldVal })}>
            {componetOptions.length > 0 ? (
              R.map(
                (item) => (
                  <Select.Option className="hide-icon" key={item.id} value={item.id} title={item.label}>
                    <Checkbox
                      checked={(fieldVal || []).includes(item.id)}
                      onChange={(e) => null}
                      style={{ marginRight: 8 }}
                    />
                    {`${item.label}${item.projectName ? ` (${item.projectName})` : ''}`}
                  </Select.Option>
                ),
                componetOptions,
              )
            ) : (
              <Select.Option className="hide-icon" disabled key="componetNull" value="componetNull">
                <div
                  className="flex-row flex-center-align flex-center-justify"
                  style={{ width: '100%', height: 30, fontSize: 16, color: 'gray' }}
                >
                  No Data
                </div>
              </Select.Option>
            )}
          </Select.OptGroup>
        )}
      </Select>
    );
  }

  render() {
    const { intl, isSubmitting, onCancel, onOk, view, incident } = this.props;
    const { isLoading, dependencyDates, activeKey } = this.state;

    const hasErrorRelation = !R.reduce(
      R.and,
      true,
      R.map(
        (item) => item.sources && item.sources.length > 0 && item.targets && item.targets.length > 0,
        this.relationList,
      ),
    );

    const hasErrorNoRelation = !R.reduce(
      R.and,
      true,
      R.map(
        (item) => item.sources && item.sources.length > 0 && item.targets && item.targets.length > 0,
        this.noRelationList,
      ),
    );

    const hasErrorIntraMayNotImapct = !R.reduce(
      R.and,
      true,
      R.map(
        (item) => item.sources && item.sources.length > 0 && item.targets && item.targets.length > 0,
        this.intraMayNotImapctList,
      ),
    );

    const hasError =
      hasErrorRelation ||
      hasErrorNoRelation ||
      hasErrorIntraMayNotImapct ||
      (this.relationList.length === 0 && this.noRelationList.length === 0 && this.intraMayNotImapctList.length === 0);
    return (
      <Modal
        title={`${view === 'add' ? 'Create' : 'Edit'} Dependency Map`}
        width={960}
        visible
        onCancel={onCancel}
        footer={[
          <Button size="small" key="cancel" disabled={isSubmitting} onClick={onCancel}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>,
          <Button
            size="small"
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={hasError}
            onClick={() => {
              onOk({
                dependencyDates,
                incident,
                relationList: this.relationList,
                noRelationList: this.noRelationList,
                intraMayNotImapctList: this.intraMayNotImapctList,
              });
            }}
          >
            {view === 'add'
              ? intl.formatMessage(appButtonsMessages.submit)
              : intl.formatMessage(appButtonsMessages.update)}
          </Button>,
        ]}
        bodyStyle={{ padding: 8 }}
        maskClosable={false}
      >
        <Container className={`${isLoading ? 'loading ' : ''} flex-col flex-min-height`} style={{ height: 600 }}>
          <Tabs
            type="card"
            className="full-height ant-tabs-content-full-height flex-col"
            activeKey={activeKey}
            onChange={(activeKey) => this.setState({ activeKey })}
            tabBarExtraContent={
              <div className="full-height flex-row flex-center-align">
                <DatePicker.RangePicker
                  size="small"
                  allowClear={false}
                  style={{ marginLeft: 16, width: 220 }}
                  value={dependencyDates}
                  onChange={(dependencyDates) => {
                    this.setState({ dependencyDates });
                  }}
                />
              </div>
            }
          >
            <Tabs.TabPane tab={intl.formatMessage(causalMessages.hasRelation)} key="relation" style={{ paddingTop: 8 }}>
              {this.renderRelation('relation', this.relationList)}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={intl.formatMessage(causalMessages.noRelation)}
              key="noRelation"
              style={{ paddingTop: 8 }}
            >
              {this.renderRelation('noRelation', this.noRelationList)}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={intl.formatMessage(causalMessages.intraMayNotImapct)}
              key="intraMayNotImapct"
              style={{ paddingTop: 8 }}
            >
              {this.renderRelation('intraMayNotImapct', this.intraMayNotImapctList)}
            </Tabs.TabPane>
          </Tabs>
        </Container>

        {this.relationList.length === 0 && this.noRelationList.length === 0 && (
          <Alert
            style={{ marginTop: 8 }}
            message={null}
            description="Please use remove button to delete the whole graph."
            type="info"
            showIcon
          />
        )}
      </Modal>
    );
  }
}

const EditDependencyModal = injectIntl(EditDependencyModalCore);
export default connect(
  (state) => {
    const { loadStatus, projects } = state.app;
    const { credentials } = state.auth;
    return {
      loadStatus,
      projects,
      credentials,
    };
  },
  { updateLastActionInfo },
)(EditDependencyModal);
