/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { apiEpicErrorHandle } from '../../errors';
import { getLoaderEpicAction } from '../../utils';

import { getProjectMetricAllSettings } from '../../apis';
import { updateLastActionInfo } from '../../app/actions';
import { setProjectMetricSettings, setProjectCapacitySettings } from '../actions';

const loadProjectMetricSettingsEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('LOAD_PROJECT_METRIC_SETTINGS').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);
    const { viewCategory, instanceId } = params;

    const reduxSetSettings = viewCategory === 'metricSettings' ? setProjectMetricSettings : setProjectCapacitySettings;

    let apiAction$ = Observable.concat(Observable.of(reduxSetSettings({ instanceId, metricList: [] })), hideLoader);

    apiAction$ = Observable.from(getProjectMetricAllSettings(credentials, params))
      .concatMap((data) => {
        return Observable.concat(Observable.of(reduxSetSettings({ ...data, instanceId })), hideLoader);
      })
      .catch((err) => {
        return Observable.concat(apiEpicErrorHandle(err), hideLoader);
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default loadProjectMetricSettingsEpic;
