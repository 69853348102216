import React, { useReducer } from 'react';
import * as R from 'ramda';
import { get, isFinite } from 'lodash';
import { Alert, Checkbox, Input } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Popover } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

const reducer = (oldVal, newVal) => ({ ...oldVal, ...newVal });

const detectionTypeList = [
  { value: 'positive', label: 'Positive' },
  { value: 'negative', label: 'Negative' },
];
const getDetectionType = (detectionType) => {
  return R.toLower(detectionType) === 'both' ? ['positive', 'negative'] : [R.toLower(detectionType)];
};
const getAlertThresholdsData = (rowData, field1, field2) => {
  const lower = get(rowData, `${field1}ViewVal`, '');
  const upper = get(rowData, `${field2}ViewVal`, '');
  let color = 'currentcolor';
  if (isFinite(parseFloat(lower)) && !isFinite(parseFloat(upper))) {
    color = 'var(--red)';
  } else if (!isFinite(parseFloat(lower)) && isFinite(parseFloat(upper))) {
    color = 'var(--blue)';
  }
  return { lower, upper, color };
};
const getThresholdsData = (rowData, field1, field2) => {
  const lower = get(rowData, field1, '');
  const upper = get(rowData, field2, '');
  let color = 'currentcolor';
  if (isFinite(parseFloat(lower)) && !isFinite(parseFloat(upper))) {
    color = 'var(--red)';
  } else if (!isFinite(parseFloat(lower)) && isFinite(parseFloat(upper))) {
    color = 'var(--blue)';
  }
  return { lower, upper, color };
};

const handleInputBlue = ({ value }) => {
  let viewVal;
  if (!Number.isFinite(value * 1) || value === '' || Number.isFinite(value * 1) === 0) {
    viewVal = value;
  } else if (R.includes('e', value) || R.includes('E', value)) {
    if (R.includes('.', value)) {
      viewVal =
        value.match(/\.(\S*)(e|E)/)[1].length > 6
          ? R.replace('e', 'E', Number.parseFloat(value).toExponential(6))
          : R.replace('e', 'E', Number.parseFloat(value).toExponential());
    } else {
      viewVal =
        value.match(/(\S*)(e|E)/)[1].length > 6
          ? R.replace('e', 'E', Number.parseFloat(value).toExponential(6))
          : R.replace('e', 'E', Number.parseFloat(value).toExponential());
    }
  } else if (value > 999999) {
    viewVal = R.replace('e', 'E', Number.parseFloat(value).toExponential(6));
  } else {
    viewVal = value;
  }
  return viewVal;
};

const getRougeValue = (rowData) => {
  const l = get(rowData, ['rougeValue', 'l'], 'None');
  const s = get(rowData, ['rougeValue', 's'], 'None');
  return { l, s };
};

const alertLower = 'thresholdAlertLowerBound';
const alertUpper = 'thresholdAlertUpperBound';
const negativeLower = 'thresholdAlertLowerBoundNegative';
const negativeUpper = 'thresholdAlertUpperBoundNegative';
const noAlertLower = 'thresholdNoAlertLowerBound';
const noAlertUpper = 'thresholdNoAlertUpperBound';
const noAlertNegativeLower = 'thresholdNoAlertLowerBoundNegative';
const noAlertNegativeUpper = 'thresholdNoAlertUpperBoundNegative';

export default function AlertThresholdsModal({ intl, onClose, activeRowData, isBatch }: Object) {
  const [state, setState] = useReducer(reducer, {
    detectionType: getDetectionType(activeRowData?.rowData?.detectionType || 'positive'),
    alertMap: getAlertThresholdsData(activeRowData?.rowData, alertLower, alertUpper),
    negativeMap: getAlertThresholdsData(activeRowData?.rowData, negativeLower, negativeUpper),
    noAlertMap: getAlertThresholdsData(activeRowData?.rowData, noAlertLower, noAlertUpper),
    noAlertNegativeMap: getAlertThresholdsData(activeRowData?.rowData, noAlertNegativeLower, noAlertNegativeUpper),
    rougeValue: getRougeValue(activeRowData?.rowData),
  });
  const { detectionType, alertMap, negativeMap, noAlertMap, noAlertNegativeMap, rougeValue } = state;

  const handleUpdate = () => {
    const isPositive = R.includes('positive', detectionType);
    const isNegative = R.includes('negative', detectionType);
    const isBoth = isPositive && isNegative;
    const rowItem = {
      detectionType: isBoth ? 'both' : detectionType.join(),
      thresholdAlertLowerBound: alertMap.lower,
      thresholdAlertLowerBoundViewVal: alertMap.lower,
      thresholdAlertUpperBound: alertMap.upper,
      thresholdAlertUpperBoundViewVal: alertMap.upper,
      thresholdAlertLowerBoundNegative: negativeMap.lower,
      thresholdAlertLowerBoundNegativeViewVal: negativeMap.lower,
      thresholdAlertUpperBoundNegative: negativeMap.upper,
      thresholdAlertUpperBoundNegativeViewVal: negativeMap.upper,
      thresholdNoAlertLowerBound: noAlertMap.lower,
      thresholdNoAlertLowerBoundViewVal: noAlertMap.lower,
      thresholdNoAlertUpperBound: noAlertMap.upper,
      thresholdNoAlertUpperBoundViewVal: noAlertMap.upper,
      thresholdNoAlertLowerBoundNegative: noAlertNegativeMap.lower,
      thresholdNoAlertLowerBoundNegativeViewVal: noAlertNegativeMap.lower,
      thresholdNoAlertUpperBoundNegative: noAlertNegativeMap.upper,
      thresholdNoAlertUpperBoundNegativeViewVal: noAlertNegativeMap.upper,
      ...(isBatch ? {} : { rougeValue }),
    };
    onClose({ rowItem, rowIndex: activeRowData.rowIndex });
  };

  const changeDetectionTypeResetData = (detectionType) => {
    const hasPositive = R.includes('positive', detectionType);
    const hasNegative = R.includes('negative', detectionType);
    const hasBoth = hasPositive && hasNegative;
    const NoneData = { lower: 'None', upper: 'None' };

    if (hasBoth) {
      return false;
    } else if (hasPositive) {
      setState({
        negativeMap: getThresholdsData(NoneData, 'lower', 'upper'),
        noAlertNegativeMap: getThresholdsData(NoneData, 'lower', 'upper'),
      });
    } else if (hasNegative) {
      setState({
        alertMap: getThresholdsData(NoneData, 'lower', 'upper'),
        noAlertMap: getThresholdsData(NoneData, 'lower', 'upper'),
      });
    }
  };

  const detectionTypeChange = (detectionType) => {
    if (detectionType.length > 0) {
      changeDetectionTypeResetData(detectionType);
      setState({ detectionType });
    }
  };

  const inputChange = (stateKey, dataKey) => {
    return (e) => {
      const { value } = e.target;
      const newData = { ...state[stateKey], [dataKey]: value };
      setState({ [stateKey]: getThresholdsData(newData, 'lower', 'upper') });
    };
  };

  const inputBlur = (stateKey, dataKey) => {
    return (e) => {
      let { value } = e.target;
      if (Number.isNaN(value * 1) || value === null || value === '') {
        value = 'None';
      }
      const newData = { ...state[stateKey], [dataKey]: handleInputBlue({ value }) };
      setState({ [stateKey]: getThresholdsData(newData, 'lower', 'upper') });
    };
  };

  const changeValue = (stateKey, dataKey) => {
    return (e) => {
      const { value } = e.target;
      const newData = { ...state[stateKey], [dataKey]: value };
      setState({ [stateKey]: newData });
    };
  };

  const blurValue = (stateKey, dataKey) => {
    return (e) => {
      let { value } = e.target;
      if (Number.isNaN(value * 1) || value === null || value === '') {
        value = 'None';
      }
      const newData = { ...state[stateKey], [dataKey]: handleInputBlue({ value }) };
      setState({ [stateKey]: newData });
    };
  };

  const isPositive = R.includes('positive', detectionType);
  const isNegative = R.includes('negative', detectionType);
  const isBoth = isPositive && isNegative;

  let hasPositiveError = false;
  if (isPositive) {
    const { lower, upper } = noAlertMap;
    if (
      // (lower !== 'None' && Number(lower) >= Number(alertMap.lower)) ||
      (upper !== 'None' && Number(upper) >= Number(alertMap.lower)) ||
      Number(lower) >= Number(upper)
    ) {
      hasPositiveError = true;
    }
  }
  let hasNegativeError = false;
  if (isNegative) {
    const { lower, upper } = noAlertNegativeMap;
    if (
      // (lower !== 'None' && Number(lower) <= Number(negativeMap.upper)) ||
      (upper !== 'None' && Number(upper) <= Number(negativeMap.upper)) ||
      Number(lower) >= Number(upper)
    ) {
      hasNegativeError = true;
    }
  }
  let hasBothError = false;
  if (isBoth) {
    if (
      alertMap.lower !== 'None' &&
      negativeMap.upper !== 'None' &&
      Number(alertMap.lower) <= Number(negativeMap.upper)
    ) {
      hasBothError = true;
    }
  }

  let hasIgnoreError = false;
  if (rougeValue.l !== 'None' && rougeValue.s !== 'None' && Number(rougeValue.l) <= Number(rougeValue.s)) {
    hasIgnoreError = true;
  }

  const hasError = hasPositiveError || hasNegativeError || hasBothError || hasIgnoreError;

  return (
    <Modal
      visible
      width={650}
      maskClosable={false}
      onOk={handleUpdate}
      onCancel={() => onClose()}
      okButtonProps={{ disabled: hasError }}
      okText={intl.formatMessage(appButtonsMessages.update)}
      title={intl.formatMessage(appFieldsMessages.alertThresholdsAndDetectionType)}
    >
      <div>
        {hasError && (
          <Alert
            showIcon
            type="warning"
            message={intl.formatMessage(appFieldsMessages.Warning)}
            style={{ marginBottom: 18 }}
            description={
              <>
                {isPositive && hasPositiveError && (
                  <div>{intl.formatMessage(appFieldsMessages.positiveThresholdsAlert)}</div>
                )}
                {isNegative && hasNegativeError && (
                  <div>{intl.formatMessage(appFieldsMessages.negativeThresholdsAlert)}</div>
                )}
                {isBoth && hasBothError && <div>{intl.formatMessage(appFieldsMessages.bothThresholdsAlert)}</div>}
                {hasIgnoreError && <div>Ignore thresholds have conflicts</div>}
              </>
            }
          />
        )}
        <div className="flex-row flex-center-align">
          <div className="flex-row flex-center-align" style={{ width: 160 }}>
            <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
              {intl.formatMessage(settingsMessages.detectionType)}
            </div>
            <Popover
              content={intl.formatMessage(settingsMessages.detectionTypeMessage)}
              mouseEnterDelay={0.3}
              placement="top"
            >
              <QuestionCircleOutlined /> :
            </Popover>
          </div>
          <Checkbox.Group value={detectionType} options={detectionTypeList} onChange={detectionTypeChange} />
        </div>
        {isPositive && (
          <div style={{ marginTop: 16 }}>
            <div className="bold">{intl.formatMessage(appFieldsMessages.positiveAnomalyDetection)}:</div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <div className="flex-row flex-center-align" style={{ width: 160 }}>
                <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                  {`${intl.formatMessage(appFieldsMessages.alertThreshold)} (>)`}
                </div>
                <Popover
                  content={intl.formatMessage(settingsMessages.positiveAlertThresholdTypeMessage)}
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <QuestionCircleOutlined /> :
                </Popover>
              </div>
              <Input
                size="small"
                value={alertMap.lower}
                style={{ width: 120 }}
                onChange={inputChange('alertMap', 'lower')}
                onBlur={inputBlur('alertMap', 'lower')}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <div className="flex-row flex-center-align" style={{ width: 160 }}>
                <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                  {`${intl.formatMessage(appFieldsMessages.noAlertMin)}`}
                </div>
                <Popover
                  content={intl.formatMessage(settingsMessages.positiveNoAlertMinMessage)}
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <QuestionCircleOutlined /> :
                </Popover>
              </div>
              <Input
                size="small"
                value={noAlertMap.lower}
                style={{ width: 120, color: noAlertMap.color }}
                onChange={inputChange('noAlertMap', 'lower')}
                onBlur={inputBlur('noAlertMap', 'lower')}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <div className="flex-row flex-center-align" style={{ width: 160 }}>
                <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                  {`${intl.formatMessage(appFieldsMessages.noAlertMax)}`}
                </div>
                <Popover
                  content={intl.formatMessage(settingsMessages.positivenNoAlertMaxMessage)}
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <QuestionCircleOutlined /> :
                </Popover>
              </div>
              <Input
                size="small"
                value={noAlertMap.upper}
                style={{ width: 120, color: noAlertMap.color }}
                onChange={inputChange('noAlertMap', 'upper')}
                onBlur={inputBlur('noAlertMap', 'upper')}
              />
            </div>
          </div>
        )}
        {isNegative && (
          <div style={{ marginTop: 16 }}>
            <div className="bold">{intl.formatMessage(appFieldsMessages.negativeAnomalyDetection)}: </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <div className="flex-row flex-center-align" style={{ width: 160 }}>
                <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                  {`${intl.formatMessage(appFieldsMessages.alertThreshold)} (<)`}
                </div>
                <Popover
                  content={intl.formatMessage(settingsMessages.negativeAlertThresholdMessage)}
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <QuestionCircleOutlined /> :
                </Popover>
              </div>
              <Input
                size="small"
                value={negativeMap.upper}
                style={{ width: 120 }}
                onChange={inputChange('negativeMap', 'upper')}
                onBlur={inputBlur('negativeMap', 'upper')}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <div className="flex-row flex-center-align" style={{ width: 160 }}>
                <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                  {`${intl.formatMessage(appFieldsMessages.noAlertMin)}`}
                </div>
                <Popover
                  content={intl.formatMessage(settingsMessages.negativeNoAlertMinMessage)}
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <QuestionCircleOutlined /> :
                </Popover>
              </div>
              <Input
                size="small"
                value={noAlertNegativeMap.lower}
                style={{ width: 120, color: noAlertNegativeMap.color }}
                onChange={inputChange('noAlertNegativeMap', 'lower')}
                onBlur={inputBlur('noAlertNegativeMap', 'lower')}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <div className="flex-row flex-center-align" style={{ width: 160 }}>
                <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                  {`${intl.formatMessage(appFieldsMessages.noAlertMax)}`}
                </div>
                <Popover
                  content={intl.formatMessage(settingsMessages.negativeNoAlertMaxMessage)}
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <QuestionCircleOutlined /> :
                </Popover>
              </div>
              <Input
                size="small"
                value={noAlertNegativeMap.upper}
                style={{ width: 120, color: noAlertNegativeMap.color }}
                onChange={inputChange('noAlertNegativeMap', 'upper')}
                onBlur={inputBlur('noAlertNegativeMap', 'upper')}
              />
            </div>
          </div>
        )}
        {!isBatch && (
          <div style={{ marginTop: 16 }}>
            <div className="flex-row flex-center-align">
              <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                {intl.formatMessage(settingsMessages.ignoreThresholds)}
              </div>
              <Popover
                content={intl.formatMessage(settingsMessages.ignoreThresholdsMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined /> :
              </Popover>
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <span className="bold" style={{ width: 160, flexShrink: 0 }}>
                {`${intl.formatMessage(appFieldsMessages.invalidValueThreshold)} (>):`}
              </span>
              <Input
                size="small"
                value={rougeValue.l}
                style={{ width: 120 }}
                onChange={changeValue('rougeValue', 'l')}
                onBlur={blurValue('rougeValue', 'l')}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <span className="bold" style={{ width: 160, flexShrink: 0 }}>
                {`${intl.formatMessage(appFieldsMessages.invalidValueThreshold)} (<):`}
              </span>
              <Input
                size="small"
                value={rougeValue.s}
                style={{ width: 120 }}
                onChange={changeValue('rougeValue', 's')}
                onBlur={blurValue('rougeValue', 's')}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
