import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { createLogic } from 'redux-logic';

import { ActionTypes, createSetAction, updateLastActionInfo } from '../actions';
import { getLoadStatusActions, ifIn } from '../../utils';
import { getMetaDataNew } from '../../apis';

const loadInfoSystemLogic = createLogic({
  type: [ActionTypes.LOAD_INFO_SYSTEM],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { userName } = state.auth.userInfo;
    const { globalInfo, globalPatternActionMap, rawEnvironments, rawSystems } = state.app;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);
    const { environmentId, systemName: systemId, anomalyInstanceOnly } = params;

    dispatch(showLoading);
    dispatch(updateLastActionInfo());

    // Get the system ids for the environment
    const environment = R.find((item) => item.id === environmentId, globalInfo);
    const systemInfoMap = {};
    R.forEach((system) => {
      systemInfoMap[system.id] = system.systemInfo || {};
    }, get(environment, 'systemList', []));

    getMetaDataNew(credentials, { ...params, systemId, userName, systemInfoMap })
      .then((data) => {
        const newGlobalInfo = globalInfo;
        let environments = rawEnvironments;
        let systems = rawSystems;
        const debugStartTime = moment.utc().valueOf();
        // console.debug(`Meta data parse start: ${moment.utc(debugStartTime).format()}`);

        // get instances and components info
        const {
          components,
          instances,
          tabCounter,
          containerInstanceMap,
          allTabInfoDailyMap,
          globaInstaceMappingProject,
          instanceDisplayNameMap,
        } = data;
        const environmentComponentMap = {};
        const systemComponentMap = {};
        R.forEachObjIndexed((component) => {
          const { systemId, environmentName, id } = component;
          if (!R.has(environmentName, environmentComponentMap)) {
            environmentComponentMap[environmentName] = [];
          }
          if (!R.has(systemId, systemComponentMap)) {
            systemComponentMap[systemId] = [];
          }
          environmentComponentMap[environmentName].push(id);
          systemComponentMap[systemId].push(id);
        }, components);

        // set components to environments and systems
        environments = R.map(
          (item) => ({ ...item, componentIds: get(environmentComponentMap, item.id, []) }),
          environments,
        );
        systems = R.map((item) => ({ ...item, componentIds: get(systemComponentMap, item.id, []) }), systems);

        // normal global info parse
        R.forEachObjIndexed((environment) => {
          const eid = environment.id;
          const newEnvironment = R.find((e) => e.id === eid, newGlobalInfo || []);
          if (!newEnvironment) return;
          const newSystemList = newEnvironment.systemList;

          let systemIds = R.uniq(environment.systemIds || []);
          let allComponentList = [];
          let allInstanceList = [];

          // The environment contains component ids which will be used to filter component
          const environmentOwnedComponentIds = R.uniq(environment.componentIds || []);

          R.forEach((sid) => {
            const system = systems[sid];
            const newSystem = R.find((s) => s.id === sid, newSystemList || []);
            if (!system || !newSystem) {
              // console.warn(`[IF_API] system "${sid}" in "${eid}" not found in systems`);
            } else if (sid === systemId) {
              const systemComponentIds = R.uniq(system.componentIds || []);

              let systemInstances = [];
              let systemInstanceContainers = [];

              let componentList = [];
              R.forEach((cid) => {
                // Check whether the component is in the system component list
                if (ifIn(cid, environmentOwnedComponentIds)) {
                  const component = components[cid];
                  if (!component) {
                    // console.warn(`[IF_API] component "${cid}" in "${sid}" not found in components`);
                  } else {
                    const componentName = component.name;
                    const componentInstanceIds = R.uniq(component.instanceList || []);
                    let hasAnomalyComponent = 0;
                    let hasIncidentComponent = false;
                    let instanceList = [];

                    R.forEach((iid) => {
                      iid = containerInstanceMap[iid] || iid;
                      const instance = instances[iid];
                      let containerList = [];

                      // If there is no container, the instance list might not exists.
                      if (!instance) {
                        // console.warn(`[IF_API] instance "${iid}" in "${cid}" not found in instances`);
                      } else {
                        const lastMetricDataReceiveTimestamp = get(
                          instance.instanceProperty,
                          'lastMetricDataReceiveTimestamp',
                        );
                        let combineInstances = get(instance.instanceProperty, 'instanceInfoList', []);
                        combineInstances = R.map((item) => item.instanceName, combineInstances);
                        combineInstances = R.filter((instanceName) => instanceName !== iid, combineInstances);

                        const instanceContainers = instance.containerList;
                        let hasAnomalyContainer = 0;
                        R.forEachObjIndexed((item, key) => {
                          const { numberOfAnomalyExcludeIgnore, lastMetricDataReceiveTimestamp } = item || {};
                          const hasAnomaly = numberOfAnomalyExcludeIgnore;
                          hasAnomalyContainer = R.max(hasAnomalyContainer, hasAnomaly);
                          containerList.push({
                            level: 'container',
                            id: key,
                            parentLocalId: `${eid}#${sid}#${cid}#${iid}`,
                            localId: `${eid}#${sid}#${cid}#${iid}#${key}`,
                            name: key,
                            environmentId: eid,
                            systemId: sid,
                            componentId: cid,
                            componentName,
                            instanceId: iid,
                            combineInstances,
                            hasAnomaly,
                            lastMetricDataReceiveTimestamp,
                          });
                        }, instanceContainers);

                        containerList = R.sort((a, b) => a.name.localeCompare(b.name), containerList);
                        systemInstanceContainers = R.concat(systemInstanceContainers, containerList);

                        const hasAnomaly = instance.numberOfAnomalyExcludeIgnore;
                        const { hasIncident } = instance;
                        hasAnomalyComponent = R.max(hasAnomalyComponent, hasAnomaly);
                        hasIncidentComponent = hasIncidentComponent || hasIncident;

                        instanceList.push({
                          ...instance,
                          level: 'instance',
                          id: iid,
                          parentLocalId: `${eid}#${sid}#${cid}`,
                          localId: `${eid}#${sid}#${cid}#${iid}`,
                          instanceProperty: instance.instanceProperty,
                          name: instance.name,
                          environmentId: eid,
                          hasAnomaly,
                          hasAnomalyContainer,
                          hasIncident,
                          systemId: sid,
                          componentId: cid,
                          componentName,
                          combineInstances,
                          containerList,
                          containerIds: R.map((c) => c.id, containerList),
                          hasContainer: containerList.length > 0,
                          lastMetricDataReceiveTimestamp,
                        });
                      }
                    }, componentInstanceIds);

                    instanceList = R.sortWith(
                      [R.descend(R.prop('hasAnomaly')), R.descend(R.prop('hasIncident')), R.ascend(R.prop('name'))],
                      instanceList,
                    );
                    allInstanceList = R.concat(allInstanceList, instanceList);
                    systemInstances = R.concat(systemInstances, instanceList);

                    componentList.push({
                      ...component,
                      level: 'component',
                      id: cid,
                      parentLocalId: `${eid}#${sid}`,
                      localId: `${eid}#${sid}#${cid}`,
                      name: component.name,
                      environmentId: eid,
                      systemId: sid,
                      instanceList,
                      instanceIds: R.map((c) => c.id, instanceList),
                      hasAnomaly: hasAnomalyComponent,
                      hasIncident: hasIncidentComponent,
                    });
                  }
                }
              }, systemComponentIds);
              const debugSystemInfoStartTime = moment.utc().valueOf();
              // console.debug(`System Info parse start: ${moment.utc(debugSystemInfoStartTime).format()}`);

              componentList = R.sortWith(
                [R.descend(R.prop('hasAnomaly')), R.descend(R.prop('hasIncident')), R.ascend(R.prop('name'))],
                componentList,
              );
              allComponentList = R.concat(allComponentList, componentList);

              // build ComponentMap for instance and container
              const instanceComponentMap = {};
              R.forEach((item) => {
                instanceComponentMap[item.id] = item.componentId;

                // add combineInstances component map info
                if (item.combineInstances && item.combineInstances.length > 0) {
                  R.forEach((combineInstance) => {
                    instanceComponentMap[combineInstance] = item.componentId;
                  }, item.combineInstances);
                }
              }, systemInstances);
              R.forEach((item) => {
                instanceComponentMap[`${item.id}_${item.instanceId}`] = item.componentId;

                // add combineInstances component map info
                if (item.combineInstances && item.combineInstances.length > 0) {
                  R.forEach((combineInstance) => {
                    instanceComponentMap[`${item.id}_${combineInstance}`] = item.componentId;
                  }, item.combineInstances);
                }
              }, systemInstanceContainers);

              newSystem.hasAllInfo = true;
              newSystem.hasAllInstanceInfo = !anomalyInstanceOnly;
              newSystem.instanceList = systemInstances;
              newSystem.instanceIds = R.map((item) => item.id, systemInstances);
              newSystem.componentList = componentList;
              newSystem.instanceComponentMap = instanceComponentMap;
              newSystem.allTabInfoDailyMap = allTabInfoDailyMap;
              newSystem.globaInstaceMappingProject = globaInstaceMappingProject;
              newSystem.instanceDisplayNameMap = instanceDisplayNameMap;
              // console.debug(`System Info parse used: ${(moment.utc().valueOf() - debugSystemInfoStartTime) / 1000} s`);
            }
          }, systemIds);

          systemIds = R.sort((systemIdA, systemIdB) => systemIdA.localeCompare(systemIdB), systemIds);

          // The id might be duplicated in different system/region, to avoid conflicit
          // create a local id used to look up the entity.
          const localIdReducer = (acc, elem) => {
            acc[elem.localId] = elem;
            return acc;
          };
          const allComponentLocalMap = R.reduce(
            localIdReducer,
            newEnvironment.allComponentLocalMap || {},
            allComponentList,
          );
          const allInstanceLocalMap = R.reduce(
            localIdReducer,
            newEnvironment.allInstanceLocalMap || {},
            allInstanceList,
          );
          newEnvironment.systemList = newSystemList;
          newEnvironment.allComponentLocalMap = allComponentLocalMap;
          newEnvironment.allInstanceLocalMap = allInstanceLocalMap;
          newEnvironment.allTabCounterMap = {
            ...(newEnvironment.allTabCounterMap || {}),
            ...(R.isEmpty(tabCounter) ? {} : { [systemId]: tabCounter }),
          };
        }, environments);

        // console.debug(`Meta data parse used: ${(moment.utc().valueOf() - debugStartTime) / 1000} s`);
        data = { globalPatternActionMap, globalInfo: newGlobalInfo };
        dispatch(createSetAction(ActionTypes.SET_INFO_GLOBAL, params, data));
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed get global info, ignored', err);
        dispatch(createSetAction(ActionTypes.SET_INFO_GLOBAL, params, {}));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }
        dispatch(hideLoading);
        done();
      });
  },
});

export default loadInfoSystemLogic;
