/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import { get, round, isNumber, isEmpty, toInteger } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Radio, Spin } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Container, AutoSizer } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { loadCausalIncident, resetCausalIncidentData } from '../../../common/causal/actions';
import { Defaults, CausalParser } from '../../../common/utils';
import { D3Tree } from '../../share';
import { appFieldsMessages, appMenusMessages, appButtonsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

import EventRelationModal from './EventRelationModal';

type Props = {
  projectName: String,
  instanceName: String,
  iniInstanceName: String,
  needCausalProperty: Boolean,
  hasLogProject: Boolean,
  hasMetricProject: Boolean,
  relationTimeThreshold: String,
  relationProbability: String,
  relationCount: Number,
  filterModality: String,
  pattern: Number,
  incidentParams: Object,
  filterParams: Object,
  causalIncidentInfo: Object,
  fixedCountAndProb: Boolean,
  onInstanceChange: Function,
  onFilterChange: Function,

  intl: Object,
  match: Object,
  location: Object,
  push: Function,
  replace: Function,
  credentials: Object,
  createLoadAction: Function,
  updateLastActionInfo: Function,
  loadCausalIncident: Function,
  resetCausalIncidentData: Function,
  currentLoadingComponents: Object,

  causalIncidentProperty: Object,
  causalIncident: Object,
  causalInstanceIncident: Object,
};

class CausalRelationTreeCore extends D3Tree {
  props: Props;

  constructor(props) {
    super(props);

    // local data
    this.relationElemInfoMap = {};
    this.relationInfoMap = {};
    this.instanceMapping = {};
    this.intraInstanceList = [];
    this.allRootNodes = [];
    this.nodeRelationMap = {};
    this.nodeLogContent = {};

    this.incidentLoader = 'causal_incident_relation_loader';
    this.state = {
      hasRelation: false,
      relationList: [],

      rootnode: null,

      showLogModal: false,
      edgeRelation: null,
      leftContents: [],
      leftLabel: null,
      rightContents: [],
      rightLabel: null,
    };
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { onFilterChange, filterParams, iniInstanceName, instanceName, fixedCountAndProb } = nextProps;
    const nextIncidentParams = nextProps.incidentParams || {};
    const incidentParams = this.props.incidentParams || {};
    if (
      nextIncidentParams.causalKey !== incidentParams.causalKey ||
      nextIncidentParams.causalName !== incidentParams.causalName ||
      nextIncidentParams.customerName !== incidentParams.customerName ||
      nextIncidentParams.startTimestamp !== incidentParams.startTimestamp ||
      nextIncidentParams.endTimestamp !== incidentParams.endTimestamp ||
      nextIncidentParams.fileName !== incidentParams.fileName ||
      nextIncidentParams.joinDependency !== incidentParams.joinDependency ||
      nextProps.causalIncidentInfo !== this.props.causalIncidentInfo ||
      nextProps.relationTimeThreshold !== this.props.relationTimeThreshold ||
      nextProps.instanceName !== this.props.instanceName ||
      nextProps.needCausalProperty !== this.props.needCausalProperty
    ) {
      this.reloadData(nextProps, true);
    } else if (
      nextProps.causalIncident !== this.props.causalIncident ||
      nextProps.causalInstanceIncident !== this.props.causalInstanceIncident
    ) {
      const { filterNodes, hasRelation, metric } = this.parseInitNode(nextProps);
      let newMetric = metric;
      if (!newMetric) newMetric = 'all';
      let newFilterNodes = filterNodes;
      if (iniInstanceName && !instanceName) newFilterNodes = [iniInstanceName];
      if (filterParams && !fixedCountAndProb) {
        this.setState({ filterNodes: newFilterNodes, hasRelation, filterMetrics: newMetric }, () => {
          const parentState = this.getNodeChangeParams(nextProps, newFilterNodes);
          onFilterChange(parentState);
        });
        if (!newFilterNodes) {
          this.setState({ hasSelectMetricRelation: true });
          this.renderChart(nextProps);
        } else {
          this.setState({ hasSelectMetricRelation: true });
        }
      } else {
        this.renderChart(nextProps);
      }
    } else if (
      nextProps.relationCount !== this.props.relationCount ||
      nextProps.relationProbability !== this.props.relationProbability ||
      nextProps.filterModality !== this.props.filterModality
    ) {
      this.renderChart(nextProps);
    }
  }

  @autobind
  reloadData(props, force = false) {
    const { causalIncidentInfo } = props;
    const { loadCausalIncident, incidentParams, instanceName, needCausalProperty, relationTimeThreshold } = props;
    const { causalKey, relationKey, customerName, causalName, startTimestamp, endTimestamp, fileName, joinDependency } =
      incidentParams;
    const causalType = 'causal';
    let postFixStr = 'inter';
    if (instanceName) {
      postFixStr = `intra_${instanceName}`;
    }
    const postFix = `_${postFixStr}_${causalType}_${relationTimeThreshold || '2160.0'}`;
    if (causalIncidentInfo && relationTimeThreshold) {
      const timeThreshold = Number(relationTimeThreshold) * 60 * 1000;
      const params = {
        causalType,
        causalKey,
        relationKey,
        customerName,
        causalName,
        instanceName,
        needCausalProperty,
        startTimestamp,
        endTimestamp,
        fileName,
        postFix,
        joinDependency,

        // filter params
        timeThreshold,
      };
      loadCausalIncident(params, force, { [this.incidentLoader]: true });
    }
  }

  @autobind
  parseInitNode(props) {
    const { filterParams, instanceName, causalIncident, causalInstanceIncident } = props;
    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;
    const relationList = get(incident, ['relation'], []);
    const hasRelation = !isEmpty(relationList);

    let { filterNodes } = this.state;
    // get filterNodes
    if (filterParams && hasInstance) {
      const { metric, type, nid } = filterParams;
      if (metric) {
        R.forEach((relation) => {
          if (relation.elem1.indexOf(metric) >= 0) {
            filterNodes = [relation.elem1];
          } else if (relation.elem2.indexOf(metric) >= 0) {
            filterNodes = [relation.elem2];
          }
        }, relationList);
      } else if (type && nid) {
        const typeList = [];
        R.forEach((val) => {
          let onlyType = R.toLower(val);
          onlyType = onlyType.indexOf('(') > 0 ? onlyType.split('(')[0] : onlyType;
          typeList.push(onlyType);
        }, type.split('&'));

        R.forEach((relation) => {
          const { eventType: eventType1, postFix: elem1Nid } = relation.elem1Info;
          const { eventType: eventType2, postFix: elem2Nid } = relation.elem2Info;
          if (typeList.indexOf(R.toLower(eventType1)) >= 0 && elem1Nid === String(nid)) {
            filterNodes = [relation.elem1];
          } else if (typeList.indexOf(R.toLower(eventType2)) >= 0 && elem2Nid === String(nid)) {
            filterNodes = [relation.elem2];
          }
        }, relationList);
      }
    }

    // set some info
    let metric;
    let hasMetricRelation = true;
    if (filterParams) {
      const { type, nid } = filterParams;
      metric = filterParams.metric;
      if (type && nid) {
        hasMetricRelation = false;
      }
    }

    return { filterNodes, hasRelation, metric, hasMetricRelation };
  }

  @autobind
  getNodeChangeParams(props, filterNodes) {
    const { instanceName, causalIncident, causalInstanceIncident } = props;
    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;
    const causalNodeDegreeMap = get(incident, ['causalNodeDegreeMap'], {});

    let relationCount = null;
    let relationProbability = null;
    R.forEach((item) => {
      relationCount = isNumber(relationCount)
        ? R.min(relationCount, get(causalNodeDegreeMap, [item, 'maximumCount'], 1))
        : get(causalNodeDegreeMap, [item, 'maximumCount'], 1);
      relationProbability = isNumber(relationProbability)
        ? R.min(relationProbability, get(causalNodeDegreeMap, [item, 'maximumProbability'], 0))
        : get(causalNodeDegreeMap, [item, 'maximumProbability'], 0);
    }, filterNodes || []);

    const parentState = {};
    if (isNumber(relationCount)) parentState.relationCount = relationCount;
    if (isNumber(relationProbability))
      parentState.relationProbability = numeral(toInteger(relationProbability * 10) / 10).format('0.0');
    return parentState;
  }

  @autobind
  getChartData(props) {
    const { causalIncidentProperty, causalIncident, causalInstanceIncident } = props;
    const { instanceName, relationProbability, relationCount, filterModality } = props;
    let { rootnode } = this.state;

    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;

    let relationList = get(incident, ['relation'], []);
    const hasRelation = !isEmpty(relationList);

    // set all log node info
    this.relationElemInfoMap = get(incident, ['relationElemInfoMap'], {});

    // set instance map and intra instance list
    const { instancePropertyMap } = causalIncidentProperty || {};
    const relationInfoMap = {};
    const instanceMapping = {};
    const intraInstanceList = [];
    R.forEachObjIndexed((value, instance) => {
      const appName = get(value, ['projectInstanceMetadata', 'componentName']);
      if (appName && appName !== instance) {
        instanceMapping[instance] = appName;
      }
      if (value.possibleIntraCausal) {
        intraInstanceList.push(instance);
      }
    }, instancePropertyMap);

    // filter
    if (relationProbability) {
      relationList = R.filter((relation) => relation.probability >= parseFloat(relationProbability), relationList);
      relationList = R.map((relation) => {
        const { probability, count } = relation;
        const newCount = get(relation, ['probabilityCountMap', relationProbability], count);
        return {
          ...relation,
          count: newCount,
          realtionColor: CausalParser.getColorByProbability(probability),
          relationWidth: CausalParser.getWidthByCount(newCount),
        };
      }, relationList);
    }
    if (relationCount) {
      relationList = R.filter((relation) => relation.count >= Number(relationCount), relationList);
    }
    if (filterModality && filterModality !== 'all') {
      relationList = R.filter((relation) => {
        let timePairs = relation.contentTimeDifference || [];
        if (relationProbability) {
          timePairs = R.filter((pair) => pair.probability >= parseFloat(relationProbability), timePairs);
        }
        timePairs = R.filter((pair) => pair.modality === filterModality, timePairs);
        return timePairs.length > 0;
      }, relationList);
    }

    // create tree data
    if (relationList.length >= 100) {
      this.autoDisplayLevel = 2;
    } else this.autoDisplayLevel = this.defaultMaxLevel;

    let nodeRelationMap = {};
    let fromNodeNames = [];
    let toNodeNames = [];

    // create node map
    R.forEach((relation) => {
      const { elem1, elem2 } = relation;

      // set relationInfoMap
      const relationKey = `${elem1}-${elem2}`;
      if (!R.has(relationKey, relationInfoMap)) {
        relationInfoMap[relationKey] = relation;

        // reduce relation infos
        fromNodeNames = [...fromNodeNames, elem1];
        toNodeNames = [...toNodeNames, elem2];

        if (!R.has(elem1, nodeRelationMap))
          nodeRelationMap[elem1] = {
            nextNodes: [],
            previousNodes: [],
            nextNodeRelations: [],
            previousNodeRelations: [],
          };
        if (!R.has(elem2, nodeRelationMap))
          nodeRelationMap[elem2] = {
            nextNodes: [],
            previousNodes: [],
            nextNodeRelations: [],
            previousNodeRelations: [],
          };

        nodeRelationMap[elem1].nextNodes.push(elem2);
        nodeRelationMap[elem2].previousNodes.push(elem1);
        nodeRelationMap[elem1].nextNodeRelations.push(relation);
        nodeRelationMap[elem2].previousNodeRelations.push(relation);
      }
    }, relationList);
    nodeRelationMap = R.mapObjIndexed((val) => {
      const nextNodeRelations = R.sortWith([R.descend(R.prop('probability'))], val.nextNodeRelations);
      const previousNodeRelations = R.sortWith([R.descend(R.prop('probability'))], val.previousNodeRelations);
      return { ...val, nextNodeRelations, previousNodeRelations };
    }, nodeRelationMap);

    fromNodeNames = R.uniq(fromNodeNames);
    toNodeNames = R.uniq(toNodeNames);
    const allRootNodes = fromNodeNames;

    this.relationInfoMap = relationInfoMap;
    this.instanceMapping = instanceMapping;
    this.intraInstanceList = intraInstanceList;
    this.allRootNodes = allRootNodes;
    this.nodeRelationMap = nodeRelationMap;

    // traverse node to build tree
    if (!rootnode || (rootnode && allRootNodes.indexOf(rootnode) === -1)) {
      rootnode = allRootNodes[0];
    }
    const startTs = moment.utc().valueOf();
    const treeData = this.createTreeData({
      nodeRelationMap,
      parentAllNodeNameMap: {},
      nodeNames: rootnode ? [rootnode] : [],
      parentNode: null,
      parentPath: null,
      level: 1,
      hasInstance,
    });
    console.debug(`Create tree duration: ${(moment.utc().valueOf() - startTs) / 1000} sec`);

    this.setState({
      hasRelation,
      relationList,
      rootnode,
    });
    return {
      treeData: treeData.length > 0 ? treeData[0] : {},
    };
  }

  @autobind
  renderTip({ operation, target, d }) {
    const { intl } = this.props;
    if (operation === 'node') {
      const { node, hasInstance, nodeInfo, parentNode, children, childrenBackup } = d;
      const { isLogType, contentInfo } = nodeInfo || {};
      let isLoading = false;
      let nextNodeTotal = 0;
      let nextNodeRelations = [];
      let parentRelation;
      const content = get(contentInfo, 'content', node);
      const patternName = get(contentInfo, 'patternName', node);
      const projectName = get(contentInfo, 'projectName', '');
      let renderMetricContent = null;

      if (!hasInstance) {
        const nodeRelation = this.nodeRelationMap[node] || {};
        nextNodeRelations = children || childrenBackup ? nodeRelation.nextNodeRelations : [];
        nextNodeTotal = nextNodeRelations.length;
        nextNodeRelations = R.take(3, nextNodeRelations);
        const { previousNodeRelations } = nodeRelation;
        parentRelation = parentNode
          ? R.find((relation) => relation.elem1 === parentNode && relation.elem2 === node, previousNodeRelations)
          : null;
      } else if (isLogType && !R.has(node, this.nodeLogContent)) {
        isLoading = true;
        // call api to get log content
        this.getNodeLogContent(target, d);
      } else if (!isLogType) {
        let { avgValue } = contentInfo;
        if (R.isNil(avgValue) && isNumber(contentInfo.avgNormalValue)) avgValue = contentInfo.avgNormalValue;
        if (R.isNil(avgValue) && isNumber(contentInfo.avgAnomalyValue)) avgValue = contentInfo.avgAnomalyValue;
        const isHigher = ['positive', 'higher'].indexOf((contentInfo.metricDirection || '').toLowerCase()) >= 0;
        const isLower = ['negative'].indexOf((contentInfo.metricDirection || '').toLowerCase()) >= 0;
        renderMetricContent = (
          <div className="flex-row" style={{ marginTop: 8 }}>
            <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
              {intl.formatMessage(appFieldsMessages.value)}:
            </div>
            <div>{round(avgValue, 2)}</div>
            {isHigher && <i className="icon up arrow" />}
            {isLower && <i className="icon down arrow" />}
          </div>
        );
      }

      if (isLoading) {
        return ReactDOMServer.renderToStaticMarkup(
          <div className="flex-row flex-center-justify flex-center-align" style={{ minHeight: 60, minWidth: 160 }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
          </div>,
        );
      }
      return ReactDOMServer.renderToStaticMarkup(
        <div
          className="flex-col overflow-y-auto"
          style={{ fontSize: 12, paddingRight: 8, minHeight: 60, minWidth: 160, maxHeight: 300, maxWidth: 400 }}
        >
          <div className="flex-row" style={{ borderBottom: '1px solid #ccc', paddingBottom: 4 }}>
            <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
              {!hasInstance
                ? intl.formatMessage(appFieldsMessages.instance)
                : isLogType
                ? intl.formatMessage(appFieldsMessages.pattern)
                : intl.formatMessage(appFieldsMessages.metric)}
              :
            </div>
            <div>{patternName}</div>
          </div>

          {!hasInstance && (
            <div className="flex-row" style={{ borderBottom: '1px solid #ccc', paddingBottom: 4 }}>
              <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </div>
              <div>{projectName}</div>
            </div>
          )}

          {hasInstance && isLogType && (
            <div className="flex-row" style={{ marginTop: 4, wordBreak: 'break-word' }}>
              {this.nodeLogContent[node] || content}
            </div>
          )}
          {hasInstance && !isLogType && renderMetricContent}
          {!hasInstance && parentRelation && (
            <div className="flex-row" style={{ marginTop: 4 }}>
              <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                {intl.formatMessage(causalMessages.inboundNodeTop, { top: '' })}:
              </div>
              <div className="flex-col">
                <div
                  style={{
                    fontWeight: 500,
                    display: 'inline-block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >{`${parentNode}`}</div>
                <div>{`${intl.formatMessage(appFieldsMessages.probability)}: ${round(
                  parentRelation.probability * 100,
                  1,
                )}%, ${intl.formatMessage(appFieldsMessages.count)}: ${parentRelation.count}`}</div>
              </div>
            </div>
          )}
          {!hasInstance && nextNodeRelations.length > 0 && (
            <div className="flex-row" style={{ marginTop: 4 }}>
              <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                {intl.formatMessage(causalMessages.outboundNodeTop, { top: '(Top3)' })}:
              </div>
              <div className="flex-col">
                {R.addIndex(R.map)((relation, idx) => {
                  return (
                    <div key={idx}>
                      <div
                        style={{
                          fontWeight: 500,
                          display: 'inline-block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >{`${relation.elem2}`}</div>
                      <div>{`${intl.formatMessage(appFieldsMessages.probability)}: ${round(
                        relation.probability * 100,
                        1,
                      )}%, ${intl.formatMessage(appFieldsMessages.count)}: ${relation.count}`}</div>
                    </div>
                  );
                }, nextNodeRelations)}
                {nextNodeTotal > 3 && <div>......</div>}
              </div>
            </div>
          )}
        </div>,
      );
    }
    return '';
  }

  @autobind
  getNodeLogContent(target, d) {
    const { node, nodeInfo } = d;
    const { typeOnly, contentInfo } = nodeInfo || {};
    const { nid, timeStamp, type } = contentInfo || {};

    const { credentials, causalIncidentProperty, incidentParams, instanceName } = this.props;
    const customerName = get(incidentParams, 'customerName');
    const { instancePropertyMap } = causalIncidentProperty || {};
    const intraInstanceMap = get(instancePropertyMap, instanceName, {});
    const { logProjectName } = intraInstanceMap;
    const { logInstanceName } = intraInstanceMap;
    if (logProjectName && logInstanceName && nid && timeStamp && type) {
      const logEventQueryStr = [
        {
          id: `${logProjectName}-${logInstanceName}-${nid}-${typeOnly}`,
          logProjectName,
          logInstanceName,
          nid: String(nid),
          timestamp: timeStamp,
          type,
        },
      ];
      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('loadlogeventsforcausal'), {
        ...credentials,
        customerName,
        logEventQueryStr: JSON.stringify(logEventQueryStr),
      }).then((data) => {
        const logContents = get(data, [logInstanceName, typeOnly], []);
        const logContent = logContents.length > 0 ? logContents[0].logContent : '';
        this.nodeLogContent[node] = logContent;

        setTimeout(() => {
          // if displayed tip node is not change, then display the tip
          const { tipNode } = this;
          if (tipNode === d) {
            this.tip.show({ operation: 'node', target, d }, target);
          }
        }, 600);
      });
    }
  }

  @autobind
  nodeCircleClick(props) {
    return (d) => {
      const { isExpandNode, node, path, children, childrenBackup, hasChildren, newParentAllNodeNameMap, level } =
        d || {};

      if (isExpandNode) {
        this.nodeClick(props)(d);
      } else {
        // Toggle children on click.
        if (children) {
          d.childrenBackup = children;
          d.children = null;
        } else if (childrenBackup) {
          d.children = childrenBackup;
          d.childrenBackup = null;
        } else if (!childrenBackup && hasChildren) {
          // Dynamically create new node children
          // update tree root. Use current node parentAllNodeNameMap to build
          const { instanceName } = this.props;
          const hasInstance = Boolean(instanceName);
          // get next nodes
          const nodeRelation = get(this.nodeRelationMap, node);
          const childrenNodeNames = nodeRelation.nextNodes;

          // get the node path expand info
          if (!R.has(path, this.nodePathExpand)) {
            this.nodePathExpand[path] = { total: childrenNodeNames.length, display: 0 };
          } else {
            this.nodePathExpand[path].total = childrenNodeNames.length;
          }
          const nodeNames = R.slice(
            this.nodePathExpand[path].display,
            this.nodePathExpand[path].display + this.autoDisplayNode,
            childrenNodeNames,
          );
          const needNextNode =
            this.nodePathExpand[path].total > this.nodePathExpand[path].display + this.autoDisplayNode;

          // create new children
          const newChildren = this.createTreeData({
            nodeRelationMap: this.nodeRelationMap,
            parentAllNodeNameMap: newParentAllNodeNameMap,
            nodeNames,
            parentNode: node,
            parentPath: path,
            level: level + 1,
            hasInstance,
          });
          // update node status
          if (newChildren.length > 0) {
            d.children = newChildren;
          }
          d.hasChildren = false;

          // build expand control node
          if (needNextNode && d.children) {
            d.children.push(this.getExpandNode({ path, direction: 'next' }));
          }
        }
        this.updateTree(d, props);
      }
    };
  }

  @autobind
  nodeClick(props) {
    return async (d) => {
      const { root } = props;
      const { node, hasIntra, isExpandNode, path, direction } = d || {};

      if (isExpandNode) {
        const nodeData = this.findNodeByPath(root, path);

        // get next nodes
        const nodeRelation = get(this.nodeRelationMap, nodeData.node);
        const childrenNodeNames = nodeRelation.nextNodes || [];

        let nodeNames = [];
        let startIndex = null;
        if (direction === 'next') {
          startIndex = this.nodePathExpand[path].display + this.autoDisplayNode;
        } else {
          startIndex = R.max(0, this.nodePathExpand[path].display - this.autoDisplayNode);
        }
        this.nodePathExpand[path].display = startIndex;
        nodeNames = R.slice(startIndex, startIndex + this.autoDisplayNode, childrenNodeNames);
        const needNextNode = this.nodePathExpand[path].total > startIndex + this.autoDisplayNode;
        const needPreviousNode = startIndex > 0;

        // create new children
        const newChildren = this.createTreeData({
          nodeRelationMap: this.nodeRelationMap,
          parentAllNodeNameMap: nodeData.newParentAllNodeNameMap,
          nodeNames,
          parentNode: nodeData.node,
          parentPath: nodeData.path,
          level: nodeData.level + 1,
          hasInstance: nodeData.hasInstance,
        });

        // build new children
        nodeData.children = newChildren;
        if (needNextNode) {
          nodeData.children.push(this.getExpandNode({ path, direction: 'next' }));
        }
        if (needPreviousNode) {
          nodeData.children.push(this.getExpandNode({ path, direction: 'previous' }));
        }

        // update tree
        this.updateTree(nodeData, props);
      } else if (hasIntra) {
        this.handleInstanceClick(node);
      }
    };
  }

  @autobind
  pathClick(d) {
    const relation = this.relationInfoMap[`${d.source.node}-${d.target.node}`];
    if (relation) {
      const leftContents = R.filter((item) => item.type !== 'Metric', get(relation, 'fromContents', []));
      const rightContents = R.filter((item) => item.type !== 'Metric', get(relation, 'toContents', []));
      const leftLabel = relation.elem1;
      const rightLabel = relation.elem2;
      this.setState({ showLogModal: true, edgeRelation: relation, leftContents, rightContents, leftLabel, rightLabel });
    }
  }

  @autobind
  handleLogModalClose() {
    this.setState({ showLogModal: false, edgeRelation: null, leftContents: [], rightContents: [] });
  }

  @autobind
  handleInstanceIdClick(instanceName) {
    return () => {
      this.handleInstanceClick(instanceName);
    };
  }

  @autobind
  handleInstanceClick(instanceName) {
    this.setState(
      {
        filterNodes: null,
      },
      () => {
        this.props.onInstanceChange(instanceName);
      },
    );
  }

  @autobind
  handleBackToInter() {
    this.setState(
      {
        filterNodes: null,
      },
      () => {
        this.props.onInstanceChange(null);
      },
    );
  }

  render() {
    const { intl, currentLoadingComponents } = this.props;
    const {
      causalIncidentProperty,
      projectName,
      instanceName,
      incidentParams,
      relationTimeThreshold,
      relationProbability,
      filterModality,
    } = this.props;
    const { hasRelation } = this.state;
    const { showLogModal, edgeRelation, leftContents, rightContents, leftLabel, rightLabel } = this.state;

    const { instancePropertyMap } = causalIncidentProperty || {};
    const appName = this.instanceMapping[instanceName] || instanceName;
    const hasInstance = Boolean(instanceName);

    const isIncidentLoading = get(currentLoadingComponents, this.incidentLoader, false);
    return (
      <Container className={`flex-grow flex-col ${isIncidentLoading ? 'loading' : ''}`}>
        {!hasRelation && (
          <Container className="chart message">
            {!hasInstance && (
              <div
                className="ui mini warning message"
                style={{
                  maxHeight: 80,
                  overflowY: 'auto',
                  wordBreak: 'break-all',
                }}
              >
                {intl.formatMessage(causalMessages.noCasualAnomaliesFoundLongInfo)}:
                {this.intraInstanceList.length > 0 &&
                  R.map(
                    (instanceName) => (
                      <span className="link" key={instanceName} onClick={this.handleInstanceIdClick(instanceName)}>
                        {this.instanceMapping[instanceName] && this.instanceMapping[instanceName] !== instanceName
                          ? `${this.instanceMapping[instanceName]}(${instanceName})`
                          : instanceName}
                      </span>
                    ),
                    this.intraInstanceList,
                  )}
              </div>
            )}
            {hasInstance && (
              <div className="ui mini warning message" style={{ wordBreak: 'break-all' }}>
                {intl.formatMessage(causalMessages.noCasualAnomaliesFound)}&nbsp;
                <span className="instance">{appName}</span>
                ,&nbsp;
                <span className="link" onClick={this.handleBackToInter}>
                  {intl.formatMessage(causalMessages.backToInstances)}
                </span>
              </div>
            )}
          </Container>
        )}
        {hasRelation && !hasInstance && this.intraInstanceList.length > 0 && (
          <Container
            className="chart"
            style={{
              width: '100%',
              border: 'none',
            }}
          >
            <div
              className="ui mini warning message"
              style={{
                maxHeight: 80,
                overflowY: 'auto',
                wordBreak: 'break-all',
              }}
            >
              {intl.formatMessage(causalMessages.viewRelationForInstance)}:
              {this.intraInstanceList.length > 0 &&
                R.map(
                  (instanceName) => (
                    <span className="link" key={instanceName} onClick={this.handleInstanceIdClick(instanceName)}>
                      {this.instanceMapping[instanceName] && this.instanceMapping[instanceName] !== instanceName
                        ? `${this.instanceMapping[instanceName]}(${instanceName})`
                        : instanceName}
                    </span>
                  ),
                  this.intraInstanceList,
                )}
            </div>
          </Container>
        )}

        <div
          className={`${hasRelation ? '' : 'invisible'} flex-col flex-grow`}
          style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <div className="flex-row flex-center-justify" style={{ height: 30 }}>
            {!hasInstance && (
              <div className="title">
                <h4>{intl.formatMessage(causalMessages.instanceRelations)}</h4>
              </div>
            )}
            {hasInstance && (
              <div className="title">
                <Tooltip title={intl.formatMessage(causalMessages.backToInstances)}>
                  <i className="arrow left icon" style={{ cursor: 'pointer' }} onClick={this.handleBackToInter} />
                </Tooltip>
                {intl.formatMessage(causalMessages.eventsRelationsForInstance)}:
                <span className="instance">
                  <i className="circle icon" />
                  {appName}
                </span>
              </div>
            )}
          </div>
          <div className="flex-grow flex-row">
            <div className="flex-col" style={{ width: 200, padding: 8 }}>
              <h5>{intl.formatMessage(causalMessages.nodeList)}</h5>
              <div className="flex-grow overflow-y-auto">
                <Radio.Group
                  className="causal-rootnode-radio"
                  onChange={this.onChangeRootnode}
                  value={this.state.rootnode}
                >
                  {R.addIndex(R.map)((node, idx) => {
                    let label = node;
                    if (hasInstance) {
                      const nodeInfo = this.relationElemInfoMap[node];
                      label = nodeInfo
                        ? `${nodeInfo.postFix ? `[${nodeInfo.postFix}] ` : ''}${nodeInfo.eventType}`
                        : label;
                    } else {
                      label =
                        this.instanceMapping[node] && this.instanceMapping[node] !== node
                          ? `${this.instanceMapping[node]}(${node})`
                          : node;
                    }
                    return (
                      <Radio key={idx} className="rootnode-radio" value={node}>
                        <Tooltip title={node} mouseEnterDelay={0.3}>
                          {label}
                        </Tooltip>
                      </Radio>
                    );
                  }, this.allRootNodes)}
                </Radio.Group>
              </div>
            </div>
            <div className="flex-grow">
              <AutoSizer>
                {({ width, height }) => (
                  <div style={{ width, height }}>
                    <div
                      className="d3-tree-container"
                      ref={(container) => {
                        this.container = container;
                      }}
                    />
                  </div>
                )}
              </AutoSizer>

              <div className="flex-col" style={{ position: 'absolute', top: 8, right: 12 }}>
                <div className="flex-row">
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <i className="square icon" style={{ color: Defaults.Colorbrewer[0] }} />
                    <span>
                      {intl.formatMessage(appFieldsMessages.prob)}
                      {'>=90%'}
                    </span>
                  </div>
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <i className="square icon" style={{ color: Defaults.Colorbrewer[1] }} />
                    <span>
                      {intl.formatMessage(appFieldsMessages.prob)}
                      {'>=70%'}
                    </span>
                  </div>
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <i className="square icon" style={{ color: Defaults.Colorbrewer[2] }} />
                    <span>
                      {intl.formatMessage(appFieldsMessages.prob)}
                      {'>=50%'}
                    </span>
                  </div>
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <i className="square icon" style={{ color: Defaults.Colorbrewer[3] }} />
                    <span>
                      {intl.formatMessage(appFieldsMessages.prob)}
                      {'>=30%'}
                    </span>
                  </div>
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <i className="square icon" style={{ color: '#2cb37d' }} />
                    <span>
                      {intl.formatMessage(appFieldsMessages.prob)}
                      {'<30%'}
                    </span>
                  </div>
                </div>
                <div className="flex-row" style={{ marginTop: 4 }}>
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <hr
                      style={{ width: 14, border: 'none', borderTop: '4px solid gray', margin: 'auto 4px auto 2px' }}
                    />
                    <span>
                      {intl.formatMessage(appFieldsMessages.count)}
                      {'>=500'}
                    </span>
                  </div>
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <hr
                      style={{ width: 14, border: 'none', borderTop: '3.5px solid gray', margin: 'auto 4px auto 2px' }}
                    />
                    <span>
                      {intl.formatMessage(appFieldsMessages.count)}
                      {'>=100'}
                    </span>
                  </div>
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <hr
                      style={{ width: 14, border: 'none', borderTop: '3px solid gray', margin: 'auto 4px auto 2px' }}
                    />
                    <span>
                      {intl.formatMessage(appFieldsMessages.count)}
                      {'>=50'}
                    </span>
                  </div>
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <hr
                      style={{ width: 14, border: 'none', borderTop: '2.5px solid gray', margin: 'auto 4px auto 2px' }}
                    />
                    <span>
                      {intl.formatMessage(appFieldsMessages.count)}
                      {'>=10'}
                    </span>
                  </div>
                  <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                    <hr
                      style={{ width: 14, border: 'none', borderTop: '2px solid gray', margin: 'auto 4px auto 2px' }}
                    />
                    <span>
                      {intl.formatMessage(appFieldsMessages.count)}
                      {'<10'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showLogModal && (
          <EventRelationModal
            isIntra={hasInstance}
            leftContents={leftContents}
            edgeRelation={edgeRelation}
            leftLabel={leftLabel}
            rightLabel={rightLabel}
            rightContents={rightContents}
            relationTimeThreshold={relationTimeThreshold}
            instanceMapping={this.instanceMapping}
            incidentParams={incidentParams}
            operation={hasInstance ? 'intra' : 'inter'}
            instancePropertyMap={instancePropertyMap}
            projectName={projectName}
            intraInstanceName={instanceName || ''}
            relationType="casual"
            srcInstance={leftLabel}
            targetInstance={rightLabel}
            filterModality={filterModality}
            relationProbability={relationProbability}
            onClose={this.handleLogModalClose}
          />
        )}
      </Container>
    );
  }
}

const CausalRelationTree = injectIntl(CausalRelationTreeCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { location } = state.router;
    const { userName } = state.auth.userInfo;
    const { currentLoadingComponents, projects } = state.app;
    const { incidentData, instanceIncidentData, incidentCausalProperty } = state.causal;
    return {
      location,
      userName,
      credentials,
      currentLoadingComponents,
      projects,
      causalIncident: get(incidentData, 'data', null),
      causalInstanceIncident: get(instanceIncidentData, 'data', null),
      causalIncidentProperty: incidentCausalProperty || {},
    };
  },
  {
    push,
    replace,
    createLoadAction,
    updateLastActionInfo,
    loadCausalIncident,
    resetCausalIncidentData,
  },
)(CausalRelationTree);
