/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import * as R from 'ramda';
import { createLogic } from 'redux-logic';
import { get } from 'lodash';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getGlobalInstanceAnomalyScore } from '../../apis';
import { appMessages } from '../../app/messages';

const loadGlobalSystemInstanceHealthLogic = createLogic({
  type: [ActionTypes.LOAD_GLOBAL_SYSTEM_INSTANCE_HEALTH],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { globalInfo } = state.app;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { environmentId, systemIds } = params;

    dispatch(showLoading);
    dispatch(updateLastActionInfo());

    // Get the system ids for the environment
    const environment = R.find((item) => item.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemIds[0], systemList);

    Promise.all([getGlobalInstanceAnomalyScore(credentials, { ...params, systemInfo })])
      .then((results) => {
        const data = results[0];
        dispatch(createSetAction(ActionTypes.SET_GLOBAL_SYSTEM_INSTANCE_HEALTH, params, data));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get global system instance health scores and incident timelines', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }
        done();
      });
  },
});

export default loadGlobalSystemInstanceHealthLogic;
