import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { get } from 'lodash';
import * as R from 'ramda';
import moment from 'moment';
import tinygradient from 'tinygradient';
import { message, Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';
import { EChart } from '../../share';

import DashCellTitle from '../components/DashCellTitle';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { rootCauseOptionHtml } from '../components/OptionHtml';

import BuildCausesInfo from '../components/BuildCausesInfo';
import CausesCategoriesChartDetail from '../components/CausesCategoriesChartDetail';
import BuildCausesCategoriesInfo from '../components/BuildCausesCategoriesInfo';
import CauseschartDetail from '../components/CauseschartDetail';
import { getCacheData, setCacheData } from '../utils';

const pieChartStyle = {
  width: '40%',
  height: '90%',
};

export default function TopCausesChart(props: Object) {
  const {
    type = 'rootCauseCategories',
    systemId,
    startTime,
    endTime,
    forceRefreshTime,
    environmentId,
    credentials,
    intl,
    globalInfo,
    currentTheme,
    instanceDisplayNameMap,
  } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    option: {},
    barData: [],
    showHorizontalchartDetail: false,
    refresh: false,
  });
  const echartRef = useRef(null);
  const optionDetail = useRef({});
  const echartsAxiosDebugger = useRef(true);
  const { option, barData, showHorizontalchartDetail, refresh } = state;

  const getOptionDetail = (rowData, detailKey, callback) => {
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    fetchGet(getEndpoint('pie-chart-detail'), {
      ...credentials,
      customerName: systemInfo?.ownerUserName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).subtract(1, 'days').startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      instanceName: rowData.instanceName,
      dataType: rowData.dataType,
      patternId: rowData.patternId,
      metricName: rowData.dataType === 0 && rowData.supportInfo ? rowData.supportInfo : undefined,
    })
      .then((data) => {
        const { success, insightDetail } = data;
        if (success === undefined || success) {
          optionDetail.current[detailKey] = insightDetail;
          echartsAxiosDebugger.current = true;
        } else {
          echartsAxiosDebugger.current = true;
        }
        callback();
      })
      .catch((err) => {
        echartsAxiosDebugger.current = true;
        if (err?.code >= 500 || err?.code === 400) {
          console.error(intl.formatMessage(appMessages.apiFaild));
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
        }
      });
  };

  const getPieOption = (data, gradientColorsList) => {
    const gradient = tinygradient(gradientColorsList);
    const colorsRgb = gradient.rgb(data.length < 2 ? 2 : data.length);
    const colors = R.map((x) => {
      return x.toHexString();
    }, colorsRgb);
    const option = {
      backgroundColor: 'transparent',
      color: colors,
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'item',
        enterable: true,
        appendToBody: true,
        position: (pos, params, dom, rect, size) => {
          const boxWidth = size.contentSize[0]; // 弹框的width
          const boxHeight = size.contentSize[1]; // 弹框的height
          const pointX = pos[0] - boxWidth / 4;
          return [pointX, pos[1] - boxHeight - 5];
        },
        formatter: (params, ticket, callback) => {
          const { data } = params;
          const detailKey = `${type}-${data?.rootCause?.instanceName || ''}-${data?.rootCause?.patternId || ''}-${
            data.count
          }`;
          if (!R.has(detailKey, optionDetail.current)) {
            if (echartsAxiosDebugger.current) {
              echartsAxiosDebugger.current = false;
              getOptionDetail(data.rootCause, detailKey, () => {
                const newFormatter = rootCauseOptionHtml({
                  data,
                  detailKey,
                  optionDetail: optionDetail.current,
                  intl,
                  currentTheme,
                });
                callback(ticket, newFormatter);
                setState({ refresh: true });
              });
            }
          }
          if (optionDetail.current[detailKey]) {
            return rootCauseOptionHtml({ data, detailKey, optionDetail: optionDetail.current, intl, currentTheme });
          } else {
            return ReactDOMServer.renderToStaticMarkup(<Spin />);
          }
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      series: [
        {
          name: 'Top Root Causes',
          type: 'pie',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          itemStyle: {
            emphasis: {
              shadowBlur: 4,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            show: false,
            position: 'inner',
            formatter: (params) => {
              const { data, percent } = params;
              return percent > 5 ? data?.count : '';
            },
            color: 'white',
            fontWeight: 'bold',
            fontSize: 10,
          },
          emphasis: {
            disabled: false,
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          emptyCircleStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderColor: '#ccc',
          },
          data,
        },
      ],
    };
    return option;
  };

  const getCategoryPieOption = (data, gradientColorsList) => {
    const gradient = tinygradient(gradientColorsList);
    const colorsRgb = gradient.rgb(data.length < 2 ? 2 : data.length);
    const colors = R.map((x) => {
      return x.toHexString();
    }, colorsRgb);
    const option = {
      backgroundColor: 'transparent',
      color: colors,
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'item',
        enterable: true,
        appendToBody: true,
        position: (pos, params, dom, rect, size) => {
          const boxWidth = size.contentSize[0]; // 弹框的width
          const boxHeight = size.contentSize[1]; // 弹框的height
          const pointX = pos[0] - boxWidth / 4;
          return [pointX, pos[1] - boxHeight - 5];
        },
        formatter: (params, ticket, callback) => {
          const { data } = params;
          return ReactDOMServer.renderToStaticMarkup(
            <div className="formatter-wrap">
              <div>
                <span className="formatter-lable" style={{ width: 140 }}>
                  Cause categories name:
                </span>
                <span className="formatter-content">{data.categoryName}</span>
              </div>
              <div>
                <span className="formatter-lable" style={{ width: 140 }}>
                  {intl.formatMessage(appFieldsMessages.count)}:
                </span>
                <span className="formatter-content">{data.count}</span>
              </div>
            </div>,
          );
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      series: [
        {
          name: 'Top Root Cause Categories',
          type: 'pie',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          itemStyle: {
            emphasis: {
              shadowBlur: 4,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            show: false,
            position: 'inner',
            formatter: (params) => {
              const { data, percent } = params;
              return percent > 5 ? data?.count : '';
            },
            color: 'white',
            fontWeight: 'bold',
            fontSize: 10,
          },
          emphasis: {
            disabled: false,
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          emptyCircleStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderColor: '#ccc',
          },
          data,
        },
      ],
    };
    return option;
  };

  const categoryparseData = (causesCategory) => {
    let causesCategoryList = [];
    R.forEachObjIndexed((value, key) => {
      causesCategoryList.push({ ...value, categoryName: key });
    }, causesCategory);
    causesCategoryList = R.sortWith([R.descend(R.prop('count'))], causesCategoryList);
    causesCategoryList = R.map((item) => ({ ...item, value: item.count }), causesCategoryList);
    setState({ option: getCategoryPieOption(causesCategoryList, ['#043e7d', '#6abbf7']), barData: causesCategoryList });
  };

  const reloadData = (systemInfo) => {
    const params = {
      ...credentials,
      customerName: systemInfo?.ownerUserName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      cacheKey: 'top-root-causes',
    };
    const apiData = getCacheData(params);
    if (apiData) {
      categoryparseData(apiData || {});
      return;
    }
    if (!systemId) return;
    fetchGet(getEndpoint('top-root-causes'), R.clone(params))
      .then(async (data) => {
        const { success, message: msg, rootCauseStats } = data;
        if (success || success === undefined) {
          const { rootCauseByCategoryMap } = rootCauseStats;
          categoryparseData(rootCauseByCategoryMap || {});
          setCacheData(params, rootCauseByCategoryMap || {});
        } else {
          console.error(msg);
        }
      })
      .catch((e) => {
        console.error(e.message || String(e));
      });
  };

  useEffect(() => {
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo) {
      reloadData(systemInfo);
    } else {
      setState({ option: getPieOption([], ['#043e7d', '#6abbf7']) });
    }
  }, [systemId, forceRefreshTime]);

  useEffect(() => {
    if (refresh) {
      setTimeout(() => setState({ refresh: false }));
    }
  }, [refresh]);

  return (
    <div
      className="flex-col flex-space-between content-bg corner-10 overflow-x-hidden overflow-y-auto"
      style={{ flexShrink: 0, height: '100%' }}
    >
      <DashCellTitle
        title=""
        subTitle={type === 'rootCauses' ? 'Top Root Causes' : 'Top Root Cause Categories'}
        type={type}
        fillListFlag={barData.length > 0}
        setShowDetail={(showHorizontalchartDetail) => setState({ showHorizontalchartDetail })}
        setTypeName={(typeName) => {}}
      />
      <div className="flex-row flex-space-between" style={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>
        <EChart
          setRef={(chart) => {
            echartRef.current = chart;
          }}
          option={option}
          style={pieChartStyle}
        />
        {type === 'rootCauses' && (
          <BuildCausesInfo
            {...props}
            list={barData}
            type={type}
            echartRef={echartRef.current}
            optionAll={option}
            optionDetail={optionDetail.current}
            echartsAxiosDebugger={echartsAxiosDebugger.current}
            setEchartsAxiosDebugger={(flag) => {
              echartsAxiosDebugger.current = flag;
            }}
            getOptionDetail={getOptionDetail}
          />
        )}
        {type !== 'rootCauses' && (
          <BuildCausesCategoriesInfo
            {...props}
            list={barData}
            type={type}
            echartRef={echartRef.current}
            optionAll={option}
          />
        )}
      </div>

      {showHorizontalchartDetail && (
        <>
          {type === 'rootCauses' && (
            <CauseschartDetail
              {...props}
              barData={barData}
              open={showHorizontalchartDetail}
              onCancel={() => setState({ showHorizontalchartDetail: false })}
              typeName={type}
              titleDistinguish="Top Root Causes"
            />
          )}
          {type !== 'rootCauses' && (
            <CausesCategoriesChartDetail
              {...props}
              barData={barData}
              open={showHorizontalchartDetail}
              onCancel={() => setState({ showHorizontalchartDetail: false })}
              typeName={type}
              titleDistinguish="Top Root Cause Categories"
              instanceDisplayNameMap={instanceDisplayNameMap}
            />
          )}
        </>
      )}
    </div>
  );
}
