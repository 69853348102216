/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, isFinite } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseTimelines } from './parsers';

const getGlobalSystemLevelTimelinesV2 = (credentials: Credentials, params: Object) => {
  const { level, environmentId, startTime, endTime, systemIds, filterProjectList } = params;
  const { systemInfoMap, anomalyType, hasPrevDayNotInclude, loadPredicted } = params;
  const { projectDisplayNameMap } = params;

  // Split diff systems api call by owners
  const customerMap = {};
  R.forEach((systemId) => {
    if (systemInfoMap[systemId]) {
      const { owner } = systemInfoMap[systemId];
      if (!customerMap[owner]) customerMap[owner] = [];
      customerMap[owner].push(systemId);
    }
  }, systemIds);
  const requests = [];
  R.forEach((customerName) => {
    const systemIds = customerMap[customerName];

    // query prev day, but not include
    if (hasPrevDayNotInclude) {
      const prevStartTime = moment.utc(startTime).subtract(1, 'days').startOf('day').valueOf();
      const prevEndTime = moment.utc(startTime).subtract(1, 'days').endOf('day').valueOf();
      requests.push(
        fetchGet(getEndpoint('stats/global/globalviewsystemtimelines', 2), {
          ...credentials,
          environmentId,
          environmentName: environmentId,
          customerName,
          systemIds: JSON.stringify(R.map((systemId) => ({ id: systemId }), systemIds)),
          startTime: prevStartTime,
          endTime: prevEndTime,
          anomalyType,
          ...(filterProjectList ? { filterProjectList } : {}),
        })
          .then((systemTimelines) => {
            return { systemTimelines, notInclude: true };
          })
          .catch((e) => {
            console.warn('[IF_API] failed to get all system timelines', e);
            return { systemTimelines: [], notInclude: true };
          }),
      );
    }

    requests.push(
      fetchGet(getEndpoint('stats/global/globalviewsystemtimelines', 2), {
        ...credentials,
        environmentId,
        environmentName: environmentId,
        customerName,
        systemIds: JSON.stringify(R.map((systemId) => ({ id: systemId }), systemIds)),
        startTime,
        endTime,
        anomalyType,
        ...(filterProjectList ? { filterProjectList } : {}),
      })
        .then((systemTimelines) => {
          return { systemTimelines };
        })
        .catch((e) => {
          console.warn('[IF_API] failed to get all system timelines', e);
          return { systemTimelines: [] };
        }),
    );

    if (loadPredicted) {
      requests.push(
        fetchGet(getEndpoint('stats/global/globalviewsystemtimelines', 2), {
          ...credentials,
          environmentId,
          environmentName: environmentId,
          customerName,
          systemIds: JSON.stringify(R.map((systemId) => ({ id: systemId }), systemIds)),
          startTime,
          endTime,
          anomalyType: 'predictedAnomaly',
          ...(filterProjectList ? { filterProjectList } : {}),
        })
          .then((systemTimelines) => {
            systemTimelines = R.map((item) => {
              item.anomalyTimelines = R.map(
                (anomaly) => ({ ...anomaly, isPredictedAnomalies: true }),
                item.anomalyTimelines,
              );
              return item;
            }, systemTimelines);
            return { systemTimelines };
          })
          .catch((e) => {
            console.warn('[IF_API] failed to get all system timelines', e);
            return { systemTimelines: [] };
          }),
      );
    }
  }, R.keys(customerMap));

  return Promise.all(requests).then((results) => {
    const timelinesMap = {};

    R.addIndex(R.forEach)((result, idx) => {
      const { systemTimelines, notInclude } = result || {};

      R.forEach((timeline) => {
        const { projectNameSet, projectDisplayMap } = get(systemInfoMap, [timeline.id], {});
        try {
          parseTimelines({
            timeline,
            timelinesMap,
            projectNameSet,
            projectDisplayMap: R.isEmpty(projectDisplayNameMap) ? projectDisplayMap : projectDisplayNameMap,
            notInclude,
          });
        } catch (error) {
          console.log(error);
        }
      }, systemTimelines);
    }, results);

    // build timelines
    const systemTimelinesMap = {};
    R.forEachObjIndexed((valList, id) => {
      const newValList = R.filter((item) => !item.notInclude, valList);
      R.forEach((item) => {
        const { systemTimeline } = item;
        if (!R.has(id, systemTimelinesMap)) {
          systemTimelinesMap[id] = systemTimeline;
        } else {
          systemTimelinesMap[id] = {
            ...systemTimeline,
            anomalyTimelines: [...systemTimelinesMap[id].anomalyTimelines, ...systemTimeline.anomalyTimelines],
          };
        }
      }, newValList);
    }, timelinesMap);
    const timelines = R.map((timeline) => ({ ...timeline, level }), R.values(systemTimelinesMap));

    // build reanding
    let systemTrendingMap = {};
    if (hasPrevDayNotInclude) {
      R.addIndex(R.forEach)((result, idx) => {
        const { systemTimelines } = result || {};
        R.forEach((timeline) => {
          const { id, anomalyTimelines } = timeline;
          if (!R.has(id, systemTrendingMap)) {
            systemTrendingMap[id] = [];
          }
          if (anomalyType === 'detectedAnomaly') {
            let totalScore = 0;
            R.forEach((item) => {
              // const avgAnomalyScore = isFinite(item.averageAnomalyScore) ? item.averageAnomalyScore : item.anomalyRatio;
              const avgAnomalyScore = numeral(
                numeral(item.anomalyRatio).format(item.anomalyRatio > 1 ? '0,0' : '0,0.[0000]'),
              ).value();
              totalScore += avgAnomalyScore || 0;
            }, anomalyTimelines || []);
            systemTrendingMap[id].push(totalScore);
          } else {
            systemTrendingMap[id].push((anomalyTimelines || []).length);
          }
        }, systemTimelines);
      }, results);
      systemTrendingMap = R.mapObjIndexed((val) => {
        return {
          current: val[1] || 0,
          trending: (val[1] || 0) - (val[0] || 0),
        };
      }, systemTrendingMap);
    }

    return { timelines, systemTrendingMap };
  });
};

export default getGlobalSystemLevelTimelinesV2;
