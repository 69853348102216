/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get, isNumber, round } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'react-router-redux';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Button, Pagination } from 'antd';

import { Defaults, buildUrl, parseLocation, buildLocation } from '../../../common/utils';
import {
  Container,
  AutoSizer,
  List,
  CellMeasurerCache,
  CellMeasurer,
  Tooltip,
  Dropdown,
  Popover,
} from '../../../lib/fui/react';
import { BaseUrls } from '../../app/Constants';
import { appFieldsMessages } from '../../../common/app/messages';
import { queryFieldMessages } from '../../../common/query/messages';
import { eventMessages } from '../../../common/metric/messages';
import { CollapsibleLogContent } from '../../share';

import LogEntryListModal from '../../log/components/LogEntryListModal';
import TicketParamsModal from '../components/TicketParamsModal';

type Props = {
  intl: Object,
  location: Object,
  push: Function,
  loadStatus: Object,
  isAdmin: Boolean,

  width: Number,
  height: Number,
  project: Object,
  queryParams: Object,
  queryResult: Object,
};

class TicketEventCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = 32;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 58,
    });
    this.eventList = [];

    this.state = {
      total: 0,
      page: 1,
      pageSize: 5,

      activeIncident: null,
      operation: null,
      showLogContentModal: false,
      showQueryParamsModel: false,

      showPredictedIncidentsModal: false,
    };
  }

  componentDidMount() {
    this.convertData(this.props, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps, {});
    }
  }

  @autobind
  convertData(props, params = {}) {
    const { queryParams, queryResult, otherResult, project } = props;
    const { incidentId, keyword } = queryParams || {};
    const projectLinkInfo = get(project, 'projectLinkInfo', []);

    const { numOfResult, pageNumber, pageSize } = otherResult;

    let eventList = [];
    R.forEachObjIndexed((value, instanceName) => {
      R.forEachObjIndexed((patterns, timestamp) => {
        R.forEach((pattern) => {
          const { clusterInfo, events, featureKeywords, nid, patternName, eventTriage, impactScore, eventTimestamps } =
            pattern;
          const { count, topK } = clusterInfo || {};
          const featureKeywordsStr = R.join(
            ', ',
            R.map((featureKeyword) => {
              return `${featureKeyword.matchWord}(${featureKeyword.count})`;
            }, featureKeywords || []),
          );

          R.forEach((event) => {
            eventList.push({
              ...event,
              instanceName,
              incidentId: incidentId || keyword,
              patternName,
              eventTriage,
              impactScore: round(impactScore),
              nid,
              topK,
              topKeywords: R.split(',', topK || ''),
              featureKeywordsStr,
              featureKeywords: featureKeywords || [],
              isRecurrent: isNumber(count) && count > 1,
              eventTimestamps,
              count: eventTimestamps.length,
            });
          }, events);
        }, patterns);
      }, value);
    }, queryResult || {});

    eventList = R.map((e) => {
      return { ...e, projectLinkInfo };
    }, eventList);

    eventList = R.sortWith([R.descend(R.prop('impactScore')), R.ascend(R.prop('timestamp'))], eventList);
    this.eventList = eventList;
    this.setState({ page: pageNumber, pageSize, total: numOfResult });
  }

  @autobind
  handleContentChanged() {
    this.cellMeasureCache.clearAll();
    if (this.listNode) {
      this.listNode.forceUpdateGrid();
    }
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }) {
    const rowData = this.eventList[rowIndex];
    if (!rowData) return null;

    const rowProps = ({ dataKey, ...rest }) => {
      return { rowData, parent, rowIndex, dataKey, cellData: get(rowData, dataKey), ...rest };
    };

    const content = (
      <div
        className={`event-list-row${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
        style={{ ...style, alignItems: 'flex-start' }}
      >
        <div className="row-column" style={{ width: 120 }}>
          {this.investigateRenderer(rowData)}
        </div>
        <div className="row-column" style={{ width: 130 }}>
          {this.timeRenderer(rowData)}
        </div>
        <div className="row-column" style={{ width: 120 }}>
          {this.insightsRenderer(rowData)}
        </div>
        <div className="row-column" style={{ width: 900, flex: 1, alignItems: 'flex-start' }}>
          {this.contentRenderer(rowData)}
        </div>
      </div>
    );

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        {content}
      </CellMeasurer>
    );
  }

  @autobind
  textPopperRenderer(name) {
    return (
      <Tooltip
        title={<div style={{ maxWidth: 500 }}>{name}</div>}
        placement="top"
        className="flex-row flex-center-align"
      >
        <div
          style={{
            wordBreak: 'keep-all',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            width: 88,
          }}
        >
          {name}
        </div>
      </Tooltip>
    );
  }

  @autobind
  patternTypeRenderer(rowData) {
    const { isPrediction, isRecurrent } = rowData;

    const background = 'gray';
    let tags = isPrediction ? ['P'] : [];
    let tagDetails = isPrediction ? ['This is a predicted event.'] : [];

    if (isRecurrent) {
      tags = [...tags, 'R'];
      tagDetails = [...tagDetails, 'This is a recurrent event.'];
    }
    return (
      <div className="flex-row" style={{ width: '100%' }}>
        {R.addIndex(R.map)(
          (tag, idx) => (
            <Tooltip
              title={
                <div className="flex-col">
                  <span>{tagDetails[idx]}</span>
                </div>
              }
              placement="top"
              key={idx}
              style={{ margin: '0 1.5px' }}
            >
              <div
                style={{
                  textAlign: 'center',
                  background,
                  color: 'white',
                  padding: '0 2px',
                }}
              >
                {tag}
              </div>
            </Tooltip>
          ),
          tags,
        )}
      </div>
    );
  }

  @autobind
  insightsRenderer(rowData) {
    const { intl } = this.props;
    const { instanceName, eventTriage, impactScore } = rowData;
    return (
      <div className="flex-col">
        <div className="flex-col" style={{ padding: '4px 0' }}>
          <span style={{ fontWeight: 500, color: 'blue' }}>{intl.formatMessage(appFieldsMessages.instanceName)}:</span>
          <div>{instanceName}</div>
        </div>
        <div className="flex-col" style={{ padding: '4px 0' }}>
          <span style={{ fontWeight: 500, color: 'blue' }}>
            {intl.formatMessage(queryFieldMessages.predictedImpact)}:
          </span>
          <div style={{ wordBreak: 'break-all' }}>{impactScore}</div>
        </div>
        <div className="flex-col" style={{ padding: '4px 0' }}>
          <span style={{ fontWeight: 500, color: 'blue' }}>{intl.formatMessage(queryFieldMessages.triageReport)}:</span>
          <div style={{ wordBreak: 'break-all' }}>{eventTriage}</div>
        </div>
      </div>
    );
  }

  @autobind
  contentRenderer(rowData) {
    const { intl } = this.props;
    const { rawData } = rowData;
    const logOptions = {
      width: 900,
      highlightWord: null,
      hideExpanded: true,
      onChanged: this.handleContentChanged,
    };
    return (
      <div className="flex-grow flex-row">
        <div className="flex-col log-event-group" style={{ padding: '4px 0', fontSize: 12 }}>
          <CollapsibleLogContent ownerObject={rowData} message={rawData} {...logOptions} />
        </div>
      </div>
    );
  }

  @autobind
  investigateRenderer(rowData) {
    const { intl } = this.props;
    const { nid, count } = rowData;

    const handleMenuClick = ({ key }) => {
      if (key === 'content') {
        this.handleContentClick(rowData);
      } else if (key === 'trendAnalysis') {
        this.handleTrendAnalysisClick(rowData);
      } else if (key === 'causalAnalysis') {
        this.handleCausalAnalysisClick(rowData);
      } else if (key === 'rootCauseEvents') {
        this.handleTicketIncidentsClick(rowData, 'rootCauseEvents');
      } else if (key === 'predictedIncidents') {
        this.handleTicketIncidentsClick(rowData, 'predictedEvents');
      }
    };
    return (
      <div className="flex-col">
        <div className="flex-row" style={{ lineHeight: '24px', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 500, color: 'blue' }}>{intl.formatMessage(eventMessages.patternId)}:</span>
          <span>{nid}</span>
        </div>
        <div className="flex-row" style={{ lineHeight: '24px', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 500, color: 'blue' }}>{intl.formatMessage(eventMessages.count)}:</span>
          <span>{count}</span>
        </div>
        <Dropdown name={intl.formatMessage(appFieldsMessages.investigate)} itemClick={handleMenuClick}>
          <>
            <Menu.Item key="trendAnalysis">{intl.formatMessage(queryFieldMessages.trendAnalysis)}</Menu.Item>
            <Menu.Item key="causalAnalysis">{intl.formatMessage(queryFieldMessages.causalAnalysis)}</Menu.Item>
            <Menu.Item key="rootCauseEvents">{intl.formatMessage(queryFieldMessages.precedingEvents)}</Menu.Item>
            <Menu.Item key="predictedIncidents">{intl.formatMessage(queryFieldMessages.trailingEvents)}</Menu.Item>
          </>
        </Dropdown>
      </div>
    );
  }

  @autobind
  timeRenderer(rowData) {
    const { intl } = this.props;
    const { eventTimestamps } = rowData;
    const dateTimes = R.map(
      (timestamp) => moment.utc(timestamp).format(Defaults.DateTimeFormat),
      R.sort(R.subtract, R.uniq(eventTimestamps || [])),
    );
    return (
      <Popover
        content={
          <div className="flex-col" style={{ width: 160, maxHeight: 200, overflowY: 'auto' }}>
            {R.addIndex(R.map)((datetime, idx) => {
              return <span key={idx}>{datetime}</span>;
            }, R.slice(0, 7, dateTimes))}
            {dateTimes.length > 7 && <span>......</span>}
          </div>
        }
        placement="right"
      >
        {dateTimes.length === 1 && <div>{dateTimes[0]}</div>}
        {dateTimes.length >= 2 && (
          <div className="flex-col" style={{ lineHeight: '14px' }}>
            <div>{dateTimes[0]}</div>
            {dateTimes.length > 2 && <div>...</div>}
            <div>{dateTimes[dateTimes.length - 1]}</div>
          </div>
        )}
      </Popover>
    );
  }

  @autobind
  handleContentClick(rowData) {
    this.setState({
      activeIncident: rowData,
      showLogContentModal: true,
    });
  }

  @autobind
  handleTicketIncidentsClick(rowData, operation) {
    this.setState({
      activeIncident: rowData,
      operation,
      showPredictedIncidentsModal: true,
    });
  }

  @autobind
  onCloseQueryParams(props) {
    const { filterInstanceName, countFilter, probabilityFilter, delayFilter, eventTypeFilter } = props || {};
    if (countFilter) {
      this.setState({
        showQueryParamsModel: false,
        showPredictedIncidentsModal: true,
        filterInstanceName,
        countFilter,
        probabilityFilter,
        delayFilter,
        eventTypeFilter,
      });
    } else {
      this.setState({ showQueryParamsModel: false });
    }
  }

  @autobind
  handleCausalAnalysisClick(rowData) {
    const { queryParams, project } = this.props;
    const { projectName, instanceGroup } = queryParams;
    const { timestamp, instanceName } = rowData;
    const query = {
      customerName: project.owner,
      environmentId: instanceGroup,
      projectName: projectName.split('@')[0],
      instanceName,
      startTimestamp: timestamp,
      endTimestamp: moment(timestamp).endOf('day').valueOf(),
    };
    window.open(buildUrl(BaseUrls.CausalGroupAnalysis, {}, query), '_blank');
  }

  @autobind
  handleTrendAnalysisClick(rowData) {
    const { instanceName, nid, timestamp } = rowData;
    const { queryParams } = this.props;
    const { projectName } = queryParams;
    const endTimestamp = moment.utc(timestamp).startOf('day').valueOf();
    const query = {
      t: '953de6a33d8a4b96ac9c100bf69ba3fc',
      projectName,
      instanceName,
      startTime: endTimestamp - 7 * 24 * 60 * 60 * 1000,
      endTime: endTimestamp,
      pattern: nid,
    };
    window.open(buildUrl('/query', {}, query), '_blank');
  }

  @autobind
  handleTicketPageChange(page, pageSize) {
    const { push, location } = this.props;
    const params = parseLocation(location);
    this.setState({ page }, () => {
      push(buildLocation(location.pathname, {}, { ...params, pageNumber: page }));
    });
  }

  render() {
    const { intl, width, height, queryParams, project } = this.props;
    const { projectName } = queryParams;
    const { page, pageSize, total } = this.state;

    return (
      <Container className="flex-col" style={{ height, width }}>
        <Container className="toolbar flex-row">
          <div className="flex-grow flex-row flex-center-align">
            <h4>{intl.formatMessage(queryFieldMessages.ticketEvents)}</h4>
            <h4> (Count: {total})</h4>
          </div>
          <Pagination current={page} total={total} pageSize={pageSize} onChange={this.handleTicketPageChange} />
        </Container>
        <div className="flex-grow">
          <AutoSizer>
            {({ width, height }) => (
              <div className="event-list">
                <div className="event-list-header" style={{ height: this.listHeaderHeight, width }}>
                  <div className="header-column" style={{ width: 120 }}>
                    {intl.formatMessage(appFieldsMessages.pattern)}
                  </div>
                  <div className="header-column" style={{ width: 130 }}>
                    {intl.formatMessage(appFieldsMessages.time)}
                  </div>
                  <div className="header-column" style={{ width: 120 }}>
                    {intl.formatMessage(queryFieldMessages.ticketInsights)}
                  </div>
                  <div className="header-column" style={{ width: 580, flex: 1 }}>
                    {intl.formatMessage(queryFieldMessages.ticketContent)}
                  </div>
                </div>
                <List
                  className="event-list-grid"
                  ref={(listNode) => {
                    this.listNode = listNode;
                  }}
                  width={width}
                  height={height - this.listHeaderHeight}
                  rowCount={this.eventList.length}
                  overscanRowCount={10}
                  deferredMeasurementCache={this.cellMeasureCache}
                  rowHeight={this.cellMeasureCache.rowHeight}
                  rowRenderer={this.renderListItem}
                />
              </div>
            )}
          </AutoSizer>
        </div>

        {this.state.showLogContentModal && (
          <LogEntryListModal
            entryList={[this.state.activeIncident]}
            highlightWord={get(this.state.activeIncident, 'incidentId')}
            hasType={false}
            onClose={() => this.setState({ showLogContentModal: false })}
          />
        )}
        {this.state.showQueryParamsModel && <TicketParamsModal project={project} onClose={this.onCloseQueryParams} />}
      </Container>
    );
  }
}

const TicketEvent = injectIntl(TicketEventCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { isAdmin } = state.auth.userInfo;

    return {
      location,
      loadStatus,
      isAdmin,
    };
  },
  { push },
)(TicketEvent);
