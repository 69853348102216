import React from 'react';
// import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';

import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import RootCauseTimelineList from './RootCauseTimelineList';

type Props = {
  environmentId: String,
  systemId: String,
  incident: Object,
  activeChain: Object,
  onClose: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
};

class RootCausesModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  render() {
    const { onClose, environmentId, systemId, incident, activeChain } = this.props;

    return (
      <Modal
        visible
        title="Root causes"
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={1200}
        bodyStyle={{ height: 560 }}
      >
        <div className="full-width full-height overflow-y-auto">
          <RootCauseTimelineList
            incident={incident}
            selectChain={activeChain}
            environmentId={environmentId}
            systemId={systemId}
          />
        </div>
      </Modal>
    );
  }
}

const RootCausesModal = injectIntl(RootCausesModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, projectDisplayMap, systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      currentLocale,
      projectDisplayMap,
      systemsMap,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(RootCausesModal);
