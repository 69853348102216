/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';


const getFeatureKeyword = (credentials: Credentials, params: Object) => {
  const {
    projectName,
    instanceName,
    dayTimeMillis,
    nid,
  } = params;
  return fetchPost(getEndpoint('fetchfeaturekeyword'), {
    ...credentials,
    projectName,
    instanceName,
    dayTimeMillis,
    nid,
  }).then(d => {
    return d;
  });
};

export default getFeatureKeyword;
