/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { get, isArray } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { FileAddOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { message, Alert, Form, Input, Select, Button, Popconfirm, Typography, Spin, Popover, Image } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';

import ImgIFWebhookUrl from '../../../../images/external-sentry/if-webhook-url.png';
import ImgUserAuthToken from '../../../../images/external-sentry/sentry-user-auth-token.png';
import ImgWebhookToken from '../../../../images/external-sentry/sentry-webhook-token.png';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  activeIncident: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class AddSentryModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { userInfo, projects } = props;
    const activeIncident = props.activeIncident || {};
    const projectMapList = [];
    if (activeIncident && activeIncident.sentryProjectMapping) {
      R.forEachObjIndexed((val, key) => {
        projectMapList.push({
          sentryProjectName: key,
          projectName: val,
        });
      }, activeIncident.sentryProjectMapping);
    }

    this.state = {
      isLoading: false,
      verified: false,
      sentryProjectList: [],
      isSubmiting: false,
      errMsg: null,

      userName: activeIncident.userName || (!userInfo.isAdmin ? userInfo.userName : undefined),
      sentryUserToken: activeIncident.sentryUserToken || '',
      sentryWebhookToken: activeIncident.sentryWebhookToken || '',
      projectMapList,

      showIFWekhookModal: false,
      isProjectsLoading: false,
    };
    this.projects = projects || [];
  }

  componentDidMount() {
    this.reloadData();
    this.handlereloadProjects();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  reloadData() {
    const { intl, credentials } = this.props;
    const { userName, sentryUserToken } = this.state;

    if (userName && sentryUserToken) {
      this.setState({ isLoading: true });
      this.props.updateLastActionInfo();
      fetchGet(getEndpoint('sentryinfo', 2), {
        ...credentials,
        UserName: userName,
        sentryUserToken,
      })
        .then((data) => {
          if (isArray(data)) {
            const sentryProjectList = data || [];
            this.setState({ isLoading: false, errMsg: null, sentryProjectList, verified: true });
          } else {
            const { success, message: errMsg, sentryProjectList } = data || {};
            if (success) {
              this.setState({ isLoading: false, errMsg: null, sentryProjectList, verified: true });
            } else {
              this.setState({ isLoading: false, errMsg, sentryProjectList: [], verified: false });
            }
          }
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          this.setState({
            isLoading: false,
            errMsg: err.message || String(err),
            sentryProjectList: [],
            verified: false,
          });
        });
    } else {
      this.setState({ sentryProjectList: [], verified: false });
    }
  }

  @autobind
  handleSumbit() {
    const { intl, credentials, onClose } = this.props;
    const { userName, sentryUserToken, sentryWebhookToken, projectMapList } = this.state;

    this.setState({ isSubmiting: true });
    const sentryProjectMapping = {};
    R.forEach((item) => {
      if (item.sentryProjectName && item.projectName) {
        sentryProjectMapping[item.sentryProjectName] = item.projectName;
      }
    }, projectMapList);
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('updateusersetting', 2), {
      ...credentials,
      setting: JSON.stringify({
        userName,
        sentryUserToken,
        sentryWebhookToken,
        sentryProjectMapping,
      }),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmiting: false });
          onClose(true);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  @autobind
  handleIFWebhookClick(rowData) {
    this.setState({ showIFWekhookModal: true });
  }

  @autobind
  handleAddProjectMap() {
    const { projectMapList } = this.state;
    this.setState({
      projectMapList: [
        ...projectMapList,
        {
          sentryProjectName: undefined,
          projectName: undefined,
        },
      ],
    });
  }

  @autobind
  handleRemoveProjectMap(index) {
    const { projectMapList } = this.state;
    this.setState({ projectMapList: R.remove(index, 1, projectMapList) });
  }

  @autobind
  handlereloadProjects() {
    const { intl, credentials } = this.props;
    const { userName } = this.state;
    if (!userName) return;

    this.setState({ isProjectsLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('loadinitdata'), {
      ...credentials,
      customerName: userName,
    })
      .then((data) => {
        let projects = get(data, 'projectList', []);
        projects = R.map((project) => {
          const { projectName, customerName } = project;
          let newProjectName = projectName;
          if (customerName !== credentials.userName) {
            newProjectName = `${projectName}@${customerName}`;
          }
          return { ...project, projectName: newProjectName, projectShortName: projectName, owner: customerName };
        }, projects);
        projects = R.sort((a, b) => (a.projectName || '').localeCompare(b.projectName), projects);
        this.projects = projects;
        this.setState({ isProjectsLoading: false });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.projects = [];
        this.setState({ isProjectsLoading: false });
      });
  }

  render() {
    const { intl, activeIncident, onClose, credentials, userInfo, userList } = this.props;
    const {
      isLoading,
      verified,
      sentryProjectList,
      isSubmiting,
      errMsg,
      userName,
      sentryUserToken,
      sentryWebhookToken,
      projectMapList,
      isProjectsLoading,
    } = this.state;
    const hasError =
      !userName ||
      !sentryUserToken ||
      !sentryWebhookToken ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => item.sentryProjectName && item.projectName, projectMapList),
      );
    return (
      <Modal
        width={850}
        title={
          activeIncident ? intl.formatMessage(appButtonsMessages.edit) : intl.formatMessage(appButtonsMessages.add)
        }
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSumbit}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
      >
        <Spin spinning={isProjectsLoading}>
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            {errMsg && (
              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Alert message={errMsg} type="error" showIcon />
              </Form.Item>
            )}

            {userInfo.isAdmin && (
              <Form.Item
                label={intl.formatMessage(eventMessages.userName)}
                validateStatus={!userName ? 'error' : 'success'}
                help={!userName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <Select
                  disabled={Boolean(activeIncident)}
                  allowClear={false}
                  showSearch
                  filterOption
                  value={userName}
                  onChange={(userName) =>
                    this.setState({ userName, projectMapList: [] }, () => {
                      this.handlereloadProjects();
                    })
                  }
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label={
                <Popover title={null} content={<Image width={160} src={ImgIFWebhookUrl} />} placement="right">
                  InsightFinder sentry webhook
                  <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
                </Popover>
              }
            >
              <Button size="small" type="primary" disabled={!userName} onClick={() => this.handleIFWebhookClick()}>
                Generate
              </Button>
            </Form.Item>
            <Form.Item
              label={
                <Popover title={null} content={<Image width={160} src={ImgUserAuthToken} />} placement="right">
                  Sentry user auth token
                  <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
                </Popover>
              }
              validateStatus={!sentryUserToken ? 'error' : 'success'}
              help={!sentryUserToken ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-row flex-center-align">
                <Input.Password
                  autoComplete="new-password"
                  value={sentryUserToken}
                  onChange={(e) => this.setState({ sentryUserToken: e.target.value })}
                />
                <Button
                  size="small"
                  type="primary"
                  style={{ marginLeft: 16 }}
                  loading={isLoading}
                  disabled={!userName || !sentryUserToken}
                  onClick={this.reloadData}
                >
                  Verify
                </Button>
              </div>
            </Form.Item>
            <Form.Item
              label={
                <Popover title={null} content={<Image width={160} src={ImgWebhookToken} />} placement="right">
                  Sentry webhook token
                  <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
                </Popover>
              }
              validateStatus={!sentryWebhookToken ? 'error' : 'success'}
              help={!sentryWebhookToken ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={sentryWebhookToken}
                onChange={(e) => this.setState({ sentryWebhookToken: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="Sentry project mapping">
              <Button
                size="small"
                type="primary"
                style={{ marginBottom: 8 }}
                disabled={!verified}
                onClick={this.handleAddProjectMap}
              >
                <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
              </Button>
              <div className="event-list">
                <div className="event-list-header" style={{ height: 30, width: '100%' }}>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    Senrty project name
                  </div>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    InsightFinder project name
                  </div>
                  <div className="header-column" style={{ width: 110 }} />
                </div>
                <div className="event-list-grid" style={{ height: 120, overflowY: 'auto' }}>
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                        style={{ minHeight: 40 }}
                      >
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          <Select
                            size="small"
                            showSearch
                            filterOption
                            disabled={!verified}
                            value={item.sentryProjectName}
                            onChange={(sentryProjectName) => {
                              const { projectMapList } = this.state;
                              this.setState({
                                projectMapList: update(projectMapList, {
                                  [index]: { $set: { ...item, sentryProjectName } },
                                }),
                              });
                            }}
                          >
                            {R.map(
                              (item) => (
                                <Select.Option key={item}>{item}</Select.Option>
                              ),
                              sentryProjectList || [],
                            )}
                          </Select>
                        </div>
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          <Select
                            size="small"
                            showSearch
                            filterOption
                            disabled={!verified}
                            value={item.projectName}
                            onChange={(projectName) => {
                              const { projectMapList } = this.state;
                              this.setState({
                                projectMapList: update(projectMapList, {
                                  [index]: { $set: { ...item, projectName } },
                                }),
                              });
                            }}
                          >
                            {R.map(
                              (item) => (
                                <Select.Option key={item.projectShortName}>{item.projectShortName}</Select.Option>
                              ),
                              R.filter((p) => p.owner === userName, this.projects || []),
                            )}
                          </Select>
                        </div>
                        <div className="row-column flex-end-justify" style={{ width: 110 }}>
                          <Popconfirm
                            placement="topRight"
                            title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                            onConfirm={() => this.handleRemoveProjectMap(index)}
                            onCancel={(event) => event.stopPropagation()}
                          >
                            <Button
                              size="small"
                              className="button-color-grey"
                              disabled={!verified}
                              onClick={(event) => event.stopPropagation()}
                            >
                              <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                            </Button>
                          </Popconfirm>
                        </div>
                      </div>
                    );
                  }, projectMapList)}
                </div>
              </div>
            </Form.Item>
          </Form>
        </Spin>

        {this.state.showIFWekhookModal && (
          <IFWebhookModal
            intl={intl}
            credentials={credentials}
            userName={userName}
            onClose={() => this.setState({ showIFWekhookModal: false })}
          />
        )}
      </Modal>
    );
  }
}

type PropsIFWebhookModal = {
  intl: Object,
  credentials: Object,
  userName: String,
  onClose: Function,
};
const IFWebhookModal = ({ intl, credentials, userName, onClose }: PropsIFWebhookModal) => {
  const [webhook, setWebhook] = useState('');
  const [errMsg, setErrMsg] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    updateLastActionInfo();
    fetchGet(getEndpoint('sentryinfo', 2), {
      ...credentials,
      UserName: userName,
      operation: 'getWebhookUrl',
    })
      .then((data) => {
        const { success, message: errMsg, webhookUrl } = data || {};
        if (success === false) {
          setErrMsg(errMsg);
          setWebhook('');
        } else {
          setErrMsg(null);
          setWebhook(webhookUrl);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setWebhook('');
        setErrMsg(err.message || String(err));
        setLoading(false);
      });
  }, []);

  return (
    <Modal
      title="InsightFinder webhook"
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={() => onClose()}
      width={650}
      bodyStyle={{ height: 300, overflowY: 'auto' }}
    >
      <Spin spinning={isLoading} wrapperClassName="spin-full-height full-width full-height">
        {errMsg && (
          <div className="full-width full-height flex-row">
            <Alert message={errMsg} type="error" showIcon />
          </div>
        )}

        {!errMsg && (
          <div className="full-width full-height">
            <Typography.Paragraph
              copyable={{ text: webhook }}
              style={{ margin: 0 }}
              onClick={(event) => event.stopPropagation()}
            >
              {webhook}
            </Typography.Paragraph>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

const AddSentryModal = injectIntl(AddSentryModalCore);
export default connect(
  (state) => {
    const { userInfo, credentials } = state.auth;
    const { projects } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return { userInfo, credentials, userList, projects };
  },
  { updateLastActionInfo },
)(AddSentryModal);
