/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';

import { isUndefined, isArray } from 'lodash';
import colorMap from './colorMap';
import { highlightContent, chopString } from '../../common/utils';

type Props = {
  ownerObject: Object,
  content: String,
  width: Number,
  highlightWord: String,
  style: Object,
  onChanged: Function,
  hideExpanded: Boolean,
  defaultExpanded: Boolean,
  isCritical: Boolean,
};

class CollapsibleContent extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.expandedStateKey = '__expanded_state__';
    this.minLines = 1;

    const { hideExpanded } = props;
    let isExpanded = props.defaultExpanded;
    if (props.ownerObject && !isUndefined(props.ownerObject[this.expandedStateKey])) {
      isExpanded = Boolean(props.ownerObject[this.expandedStateKey]);
    }
    this.state = { hideExpanded, isExpanded };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.ownerObject !== this.props.ownerObject) {
      let isExpanded = nextProps.defaultExpanded;
      if (nextProps.ownerObject && !isUndefined(nextProps.ownerObject[this.expandedStateKey])) {
        isExpanded = Boolean(nextProps.ownerObject[this.expandedStateKey]);
      }
      this.setState({ isExpanded });
    }
  }

  @autobind
  handleEllipsisClicked() {
    this.setState({ isExpanded: !this.state.isExpanded }, () => {
      const { ownerObject, onChanged } = this.props;
      const { isExpanded } = this.state;
      if (ownerObject) {
        ownerObject[this.expandedStateKey] = isExpanded;
      }
      if (onChanged) {
        onChanged(isExpanded);
      }
    });
  }

  render() {
    const { content, style, width, highlightWord, isCritical, isKeywordQuery } = this.props;
    // const { content, style, width, highlightWord, isCritical, isLogOnly, isTwoLog, isShowActions } = this.props;
    const { hideExpanded, isExpanded } = this.state;
    const lines = R.filter((x) => Boolean((x || '').trim()), isArray(content) ? content : (content || '').split('\n'));
    if (lines.length > 0) {
      const htmls = R.map(
        (l) => highlightContent(l, highlightWord, isCritical),
        hideExpanded || isExpanded ? lines : R.take(this.minLines, lines),
      );
      return (
        <>
          {isKeywordQuery && (
            <div
              style={{
                width: '100%',
                padding: '6px 0px',
                zIndex: 999,
              }}
              className="flex-row flex-center-align"
            >
              <div className={`flex-grow white-pre ${isExpanded ? '' : 'hidden-line-with-ellipsis'}`}>
                <div
                  style={{ wordBreak: 'break-all' }}
                  className={`white-pre ${isExpanded ? '' : 'hidden-line-with-ellipsis'}`}
                  dangerouslySetInnerHTML={{ __html: htmls.join('\n') }}
                />
              </div>
              {!hideExpanded && (
                <i
                  className={`${isExpanded ? 'angle up' : 'angle down'} icon clickable`}
                  style={{ verticalAlign: 'top', color: 'var(--text-color)', fontSize: 14, right: 16 }}
                  onClick={this.handleEllipsisClicked}
                />
              )}
            </div>
          )}
          {!isKeywordQuery && (
            <div
              style={{
                ...style,
                width: '100%',
                padding: '6px 0px',
                zIndex: 999,
              }}
            >
              <div
                style={{
                  width: 'calc(100% - 10px)',
                  display: 'inline-block',
                }}
              >
                <div
                  style={{ width: '100%', wordBreak: 'break-all' }}
                  className={`white-pre ${isExpanded ? '' : 'hidden-line-with-ellipsis'}`}
                  dangerouslySetInnerHTML={{ __html: htmls.join('\n') }}
                />
              </div>
              {/* {!hideExpanded && lines.length > this.minLines && ( */}
              {!hideExpanded && (
                <i
                  className={`${isExpanded ? 'angle up' : 'angle down'} icon clickable`}
                  style={{ verticalAlign: 'top', color: 'var(--text-color)', fontSize: 14, right: 16 }}
                  onClick={this.handleEllipsisClicked}
                />
              )}
            </div>
          )}
        </>
      );
    }

    return null;
  }
}

export default CollapsibleContent;
