export const getCoverageTitle = (entity, isK8s, selectedTime) => {
  let { title } = entity;
  if (isK8s) {
    return title;
  }

  const coverageMap = entity?.k8CoverageMap?.coverageMap?.[title] || [];
  if (selectedTime) {
    const ts = selectedTime?.valueOf();
    let coverage = coverageMap.find((c) => c?.[1]?.s <= ts && c?.[1]?.e >= ts);
    if (!coverage && coverageMap && coverageMap.length > 0) {
      coverage = coverageMap[0];
    }

    if (coverage) {
      title = coverage[0].h;
    }
  }

  return title;
};
