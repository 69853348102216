/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { get } from 'lodash';

import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import type { Credentials } from '../types';
import { parseJSON } from '../utils';

const getGlobalSystemAlertStatistics = (credentials: Credentials, params: Object) => {
  const { includeIgnore } = params;
  return fetchGet(getEndpoint('insightsIncidentStatsAlert', 1), {
    ...credentials,
    ...params,
  }).then((data) => {
    const alertStatistics = parseJSON(get(data, ['daily', 'totalAlertStatistics'])) || {};
    const { alertCount, alertReductionRatio } = alertStatistics;

    const alertPatternStat = parseJSON(get(data, ['daily', 'alertPatternStatistics'])) || {};
    const alertPatternCount = includeIgnore
      ? alertPatternStat.patternCountWithIgnore
      : alertPatternStat.patternCountWithoutIgnore;
    const patternTypeCountMap = includeIgnore
      ? alertPatternStat.patternTypeCountMapWithIgnore
      : alertPatternStat.patternTypeCountMapWithoutIgnore;
    const alertTypeCountMap = {};
    R.forEachObjIndexed((val, key) => {
      const type = R.toLower(key);
      alertTypeCountMap[type] = val;
    }, patternTypeCountMap);

    return {
      globalSystemAlertStatistics: {
        alertCount: alertCount || 0,
        alertReductionRatio: alertReductionRatio || 0,
        alertPatternCount: alertPatternCount || 0,
        alertTypeCountMap: alertTypeCountMap || {},
      },
    };
  });
};

export default getGlobalSystemAlertStatistics;
