/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

// import * as R from 'ramda';
import { get } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getLogDayEntryList = (credentials: Credentials, params: Object) => {
  const requests = [];
  requests.push(
    fetchGet(getEndpoint('logfetchdecompressedevents'), {
      ...credentials,
      ...params,
    }),
  );

  return Promise.all(requests).then((results) => {
    const logEntryDayList = get(results[0], ['events'], []);
    const logEntryDaySampleMsg = get(results[0], ['sampleMsg'], '');
    const logEntryDayListTotal = get(results[0], ['totalSize'], 0);
    return {
      logEntryDayList,
      logEntryDaySampleMsg,
      logEntryDayListTotal,
    };
  });
};

export default getLogDayEntryList;
