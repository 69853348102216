import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { CellMeasurerCache } from '../../../lib/fui/react';
import { updateLastActionInfo, createLoadAction } from '../../../common/app/actions';

import GlobalDetectedAnomaliesTable from './GlobalDetectedAnomaliesTable';

type Props = {
  events: Array,
  // eslint-disable-next-line
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refreshTime: Number,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  zoomStartTime: Number,
  // eslint-disable-next-line
  zoomEndTime: Number,
  // eslint-disable-next-line
  selectAnomalyInstance: String,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoading: Boolean,
  // eslint-disable-next-line
  reloadSystem: Number,
  // eslint-disable-next-line
  onReloadSystemIncidentTimelines: Function,
  // eslint-disable-next-line
  updateState: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  projectName: String,
  width: Number,
  height: Number,
  isMetric: Boolean,
  summarySettingsMap: Object,
  title: String,
  typeString: String,
  activeLineIconKey: String,
  changeActiveLineIconKey: Function,
  onRefreshData: Function,
  globalLoading: Boolean,
};

class GlobalDetectedAnomaliesCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {};
  }

  componentDidMount() {
    // this.reloadAnomaliesData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    const {
      events,
      projectName,
      summarySettingsMap,
      environmentId,
      startTime,
      endTime,
      customerName,
      systemHealth,
      systemInfo,
      width,
      isMetric,
      height,
      title,
      typeString,
      activeLineIconKey,
      changeActiveLineIconKey,
      onRefreshData,
      globalLoading,
    } = this.props;

    return (
      <div style={{ width }}>
        <div style={{ padding: '8px 0px 4px 0' }}>
          <span className="font-14 bold">{title}</span> - {typeString}
        </div>
        <GlobalDetectedAnomaliesTable
          width={width - 2}
          height={height - 77}
          events={events}
          startTime={startTime}
          endTime={endTime}
          customerName={customerName}
          environmentId={environmentId}
          systemHealth={systemHealth}
          systemInfo={systemInfo}
          projectName={projectName}
          isMetric={isMetric}
          onRefreshData={onRefreshData}
          summarySettingsMap={summarySettingsMap}
          activeLineIconKey={activeLineIconKey}
          changeActiveLineIconKey={changeActiveLineIconKey}
          globalLoading={globalLoading}
        />
      </div>
    );
  }
}

const GlobalDetectedAnomalies = injectIntl(GlobalDetectedAnomaliesCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    const { globalSystemAnomalyTimelines, globalSystemAnomalyTrendingMap } = state.dashboard;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,

      globalSystemAnomalyTimelines,
      globalSystemAnomalyTrendingMap,
    };
  },
  { updateLastActionInfo, createLoadAction },
)(GlobalDetectedAnomalies);
