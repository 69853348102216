import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { Button, message, Spin } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';
import { getCacheData, setCacheData } from '../utils';
import { DashboardMessages } from '../../../common/dashboard/messages';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Modal,
  Popover,
  SortDirection,
  Table,
} from '../../../lib/fui/react';
import { appFieldsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

const handleIsNil = (value) => {
  return R.isNil(value) ? 'N/A' : `${value} %`;
};

export default function LogComoressionRatio(props: Object) {
  const { intl, systemId, startTime, endTime, credentials, systemInfo } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    logCompressionRatio: undefined,
    anomalyCompressionRatio: undefined,
    compressedSize: undefined,
    totalLogSize: undefined,
    totalAnomalySize: undefined,
    projectLevelList: [],
    projectLevelModal: false,
  });
  const { loading, logCompressionRatio, anomalyCompressionRatio, projectLevelList, projectLevelModal } = state;
  const { totalLogSize, compressedSize, totalAnomalySize } = state;
  const { ownerUserName } = systemInfo || {};

  const parseData = (data) => {
    const { logCompressionRatio, anomalyCompressionRatio, compressedSize, totalLogSize, totalAnomalySize } =
      data[systemInfo.name] || {};
    let projectLevel = [];
    R.forEachObjIndexed((value, key) => {
      if (key !== systemInfo.name) {
        projectLevel = [...projectLevel, { ...value, projectName: key }];
      }
    }, data || {});
    projectLevel = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('projectName')))], projectLevel);
    setState({
      logCompressionRatio,
      anomalyCompressionRatio,
      compressedSize,
      totalLogSize,
      totalAnomalySize,
      projectLevelList: projectLevel,
    });
  };

  const reloadData = () => {
    const startDate = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endDate = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();

    const params = {
      ...credentials,
      systemName: systemId,
      startTime: startDate,
      endTime: endDate,
      customerName: ownerUserName,
      cacheKey: 'logCompressionRatio',
    };

    const apiData = getCacheData(params);
    if (apiData) {
      parseData(apiData);
      return;
    }

    setState({ loading: true });
    fetchGet(getEndpoint('logcompressioninfodashboard', 2), R.clone(params))
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          setState({ loading: false });
          parseData(data);
          setCacheData(params, data);
        } else {
          setState({ loading: false });
          console.error(msg);
        }
      })
      .catch((e) => {
        setState({ loading: false });
        if (e?.code >= 500) {
          console.error(e.message || String(e));
        } else {
          message.error(e.message || String(e));
        }
      });
  };

  useEffect(() => {
    reloadData();
  }, [systemId, ownerUserName]);

  return (
    <Spin spinning={loading} wrapperClassName="spin-base overflow-x-hidden overflow-y-auto full-width full-height">
      <div className="full-height flex-col">
        <div className="font-14 bold flex-row flex-space-between flex-center-align">
          <span>{intl.formatMessage(DashboardMessages.logCompressionRatio)}</span>
          {projectLevelList.length > 0 && (
            <Popover
              content={intl.formatMessage(DashboardMessages.projectDataCompressionRatio)}
              mouseEnterDelay={0.3}
              placement="top"
            >
              <Button size="small" onClick={() => setState({ projectLevelModal: true })}>
                {intl.formatMessage(appFieldsMessages.details)}
              </Button>
            </Popover>
          )}
        </div>
        <div className="flex-row flex-center-align full-width flex-center-justify flex-grow" style={{ marginTop: 8 }}>
          <div style={{ marginRight: 24, width: 140 }}>
            <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
              {intl.formatMessage(DashboardMessages.originalLogSize)}
            </div>
            <div style={{ fontSize: 18 }}>{numeral(totalLogSize || 0).format('0.0ib')}</div>
          </div>
          <div style={{ marginRight: 24, width: 160 }}>
            <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
              {intl.formatMessage(DashboardMessages.compressedLogSize)}
            </div>
            <div style={{ fontSize: 18 }}>{numeral(compressedSize || 0).format('0.0ib')}</div>
          </div>
          <div style={{ width: 160 }}>
            <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
              {intl.formatMessage(DashboardMessages.dataCompressionRatio)}
            </div>
            <div style={{ fontSize: 18 }}>{handleIsNil(logCompressionRatio)}</div>
          </div>
        </div>
        <div className="flex-row flex-center-align full-width flex-center-justify flex-grow" style={{ marginTop: 8 }}>
          <div style={{ marginRight: 24, width: 140 }}>
            <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
              {intl.formatMessage(DashboardMessages.anomalousLogSize)}
            </div>
            <div style={{ fontSize: 18 }}>{numeral(totalAnomalySize || 0).format('0.0ib')}</div>
          </div>
          <div style={{ marginRight: 24, width: 160 }}>
            <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
              {intl.formatMessage(DashboardMessages.anomalyCompressionRatio)}
            </div>
            <div style={{ fontSize: 18 }}>{handleIsNil(anomalyCompressionRatio)}</div>
          </div>
          <div style={{ width: 160 }} />
        </div>
      </div>

      {projectLevelModal && (
        <ProjectLevelModal
          {...props}
          projectLevelList={projectLevelList}
          onCancel={() => setState({ projectLevelModal: false })}
        />
      )}
    </Spin>
  );
}

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

const ProjectLevelModal = (props: Object) => {
  const { projectLevelList = [], onCancel, intl } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    sortBy: null,
    sortDirection: null,
    eventList: projectLevelList,
  });
  const dataTable = useRef(null);
  const { eventList, sortBy, sortDirection } = state;

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const sortData = (eventList, sortBy, sortDirection) => {
    let sortList = eventList || [];
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        sortList = R.sortWith([R.descend(R.prop(sortBy))])(sortList);
      } else {
        sortList = R.sortWith([R.ascend(R.prop(sortBy))])(sortList);
      }
    }
    return sortList;
  };

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  useEffect(() => {
    setState({ eventList: sortData(eventList, sortBy, sortDirection) });
    cellMeasureCache.clearAll();
    if (dataTable.current) dataTable.current.forceUpdateGrid();
  }, [sortBy, sortDirection]);

  return (
    <Modal
      title={intl.formatMessage(DashboardMessages.projectDataCompressionRatio)}
      width={1100}
      visible
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      bodyStyle={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}
    >
      <div className="full-width full-height">
        <AutoSizer>
          {({ width, height }) => (
            <Table
              className="with-border"
              width={width}
              height={height}
              deferredMeasurementCache={cellMeasureCache}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={cellMeasureCache.rowHeight}
              rowCount={eventList.length}
              rowGetter={({ index }) => eventList[index]}
              sort={sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              ref={(table) => {
                dataTable.current = table;
              }}
            >
              <Column
                label={intl.formatMessage(eventMessages.projectName)}
                flexGrow={1}
                width={200}
                dataKey="projectName"
                headerRenderer={headerRenderer}
                cellRenderer={({ dataKey, parent, rowIndex, cellData }) => {
                  return (
                    <CellMeasurer
                      cache={cellMeasureCache}
                      columnIndex={0}
                      key={dataKey}
                      parent={parent}
                      rowIndex={rowIndex}
                    >
                      <Popover content={cellData} mouseEnterDelay={0.3} placement="right">
                        <div className="hidden-line-with-ellipsis">{cellData}</div>
                      </Popover>
                    </CellMeasurer>
                  );
                }}
              />
              <Column
                width={140}
                flexShrink={0}
                label={intl.formatMessage(DashboardMessages.originalLogSize)}
                dataKey="totalLogSize"
                headerRenderer={headerRenderer}
                cellRenderer={({ cellData }) => {
                  return <div>{numeral(cellData || 0).format('0.0ib')}</div>;
                }}
              />
              <Column
                width={160}
                flexShrink={0}
                label={intl.formatMessage(DashboardMessages.compressedLogSize)}
                dataKey="compressedSize"
                headerRenderer={headerRenderer}
                cellRenderer={({ cellData }) => {
                  return <div>{numeral(cellData || 0).format('0.0ib')}</div>;
                }}
              />
              <Column
                width={180}
                flexShrink={0}
                label={intl.formatMessage(DashboardMessages.dataCompressionRatio)}
                dataKey="logCompressionRatio"
                headerRenderer={headerRenderer}
                cellRenderer={({ cellData }) => {
                  return <div>{handleIsNil(cellData)}</div>;
                }}
              />
              <Column
                width={160}
                flexShrink={0}
                label={intl.formatMessage(DashboardMessages.anomalousLogSize)}
                dataKey="totalAnomalySize"
                headerRenderer={headerRenderer}
                cellRenderer={({ cellData }) => {
                  return <div>{numeral(cellData || 0).format('0.0ib')}</div>;
                }}
              />
              <Column
                width={190}
                flexShrink={0}
                label={intl.formatMessage(DashboardMessages.anomalyCompressionRatio)}
                dataKey="anomalyCompressionRatio"
                headerRenderer={headerRenderer}
                cellRenderer={({ cellData }) => {
                  return <div>{handleIsNil(cellData)}</div>;
                }}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    </Modal>
  );
};
