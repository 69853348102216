/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import { get } from 'lodash';
import moment from 'moment';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';
import { Defaults } from '../app';
import type { Credentials } from '../types';

const getGlobalSystemInstances = (credentials: Credentials, params: Object) => {
  const { startTime, instanceIds, customerName, environmentName } = params;
  const startTimestamp = moment
    .utc(startTime, Defaults.DateFormat)
    .startOf('day')
    .valueOf();
  const endTimestamp = moment
    .utc(startTime, Defaults.DateFormat)
    .endOf('day')
    .valueOf();
  const dayKey = moment.utc(startTime, Defaults.DateFormat).format(Defaults.DateShortFormat);
  return fetchPost(getEndpoint('stats/global/health', 2), {
    ...credentials,
    ...params,
    startTime: startTimestamp,
    endTime: endTimestamp,
    environmentName,
    customerName,
    instanceIds: JSON.stringify(instanceIds),
  }).then((d) => {
    const data = get(d, [dayKey], []);
    return data;
  });
};

export default getGlobalSystemInstances;
