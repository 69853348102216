/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import moment from 'moment';
import { Defaults } from '../app';

const getProjectFiltersSetting = (credentials: Credentials, params: Object) => {
  return fetchGet(getEndpoint('projectfilters'), {
    ...credentials,
    ...params,
  })
    .then((d) => {
      const { filters } = d;
      return filters;
    })
    .catch((e) => {
      console.error('[IF_API]', e);
      return [];
    });
};

export default getProjectFiltersSetting;
