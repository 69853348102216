/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
// import * as R from 'ramda';
import { get } from 'lodash';
import * as R from 'ramda';

import type { Credentials } from '../types';
import fetchGet from './fetchGet';
import fetchPost from './fetchPost';
import getEndpoint from './getEndpoint';
import { parseJSON } from '../utils';
import parseMetricSettings from './parsers/parseMetricSettings';

const getProjectMetricAllSettings = (credentials: Credentials, params: Object) => {
  const { viewCategory, projectName, instanceId, owner, start, limit, metricFilter, onlyFilter } = params;
  const { timeRangeStart, timeRangeEnd } = params;

  const request = [
    fetchGet(getEndpoint(viewCategory === 'metricSettings' ? 'projects/componentmetricupdate' : 'projects/cpupdate'), {
      ...credentials,
      ...onlyFilter,
      projectName: projectName.indexOf('@') >= 0 ? projectName : `${projectName}@${owner}`,
      appName: instanceId,
      start,
      limit,
      metricFilter,
    }),
  ];

  return Promise.all(request)
    .then(async (results) => {
      const data = results[0];
      const metricListCount = get(data, 'metricSettingArrCount', 0);
      const possibleMetricType = get(data, 'possibleMetricType', []);
      const metricSettings = get(data, 'metricSetting', []);
      const metricUnitMapping = JSON.parse(get(data, 'metricUnitMapping', '[]'));
      const patternIdGenerationRule = get(data, 'patternIdGenerationRule', 0);
      const patternNameRegex = get(data, 'patternNameRegex', '');

      const metricList = parseMetricSettings(metricSettings, metricUnitMapping);

      // get metric statics
      const metricStats = await fetchPost(getEndpoint('projects/metricstats'), {
        ...credentials,
        projectName,
        appName: instanceId,
        metricList: JSON.stringify(R.map((m) => m.smetric, metricList || [])),
        timeRangeStart,
        timeRangeEnd,
      });
      const metricStatsMap = parseJSON(get(metricStats, ['Metric statistics map'], {}));

      return {
        metricList,
        metricListCount,
        possibleMetricType,
        metricStatsMap,
        patternIdGenerationRule,
        patternNameRegex,
      };
    })
    .catch((e) => {
      console.error('[IF_API]', e);
      return {
        metricList: [],
        metricListCount: 0,
        possibleMetricType: [],
        metricStatsMap: {},
        patternIdGenerationRule: 0,
        patternNameRegex: '',
      };
    });
};

export default getProjectMetricAllSettings;
