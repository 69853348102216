/* @flow */

import { defineMessages } from 'react-intl';

const settingsButtonMessages = defineMessages({
  addWebHook: {
    defaultMessage: 'Add WebHook',
    id: 'settings.button.addWebHook',
  },
  addIdentityProvider: {
    defaultMessage: 'Add Global External Service Provider Setting',
    id: 'settings.button.addIdentityProvider',
  },
});

export default settingsButtonMessages;
