/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import moment from 'moment';
import { get, isObject, isNumber, isInteger, round, isString, isArray, isEqual, transform } from 'lodash';
import { schemeRdYlBu, schemePaired } from 'd3-scale-chromatic';

import html2canvas from 'html2canvas';
import { toCanvas } from 'html-to-image';
import { jsPDF } from 'jspdf';
import { autobind } from 'core-decorators';

export const pickNotNil = R.pickBy((a) => !R.isNil(a));
export const pickNotEmpty = R.pickBy((a) => !R.isEmpty(a));
export const ifIn = (i, items) => items.indexOf(i) !== -1;
export const sortByString = R.sort((a, b) => (a || '').localeCompare(b));
export const toIntArray = R.pipe(
  R.filter((f) => Boolean(f)),
  R.map((n) => parseInt(n.trim(), 10)),
);

export const utcObjToLocalDate = (utcObj) => {
  if (!utcObj) return null;

  const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const ts = utcObj.valueOf();
  return new Date(ts + localOffset);
};

export const localDateToUtcObj = (localDate) => {
  if (!localDate) return null;

  const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const ts = localDate.valueOf();
  return moment.utc(ts - localOffset);
};

export const truncateToDecimals = (num, dec = 2) => {
  const calcDec = 10 ** dec;
  return (Math.trunc(num * calcDec) / calcDec).toFixed(dec);
};
export const chopString = (str, n) => {
  if (str) {
    if (str.length <= n + 2) {
      return str;
    }
    return `${str.slice(0, n)}..`;
  }
  return null;
};

export const CamelCase = (name) => {
  if (name && name.length > 1) {
    return `${R.toUpper(R.slice(0, 1, name))}${R.slice(1, Infinity, name)}`;
  }
  return name;
};

export const numberStringNull = (n) => {
  return isNumber(n) ? (isInteger(n) ? n : n.toFixed(2)) : '';
};

export const numberString = (n) => {
  return isNumber(n) ? (isInteger(n) ? n : n.toFixed(2)) : 'NA';
};

export const numberHumanize = (val) => {
  let valStr = '';
  const num = Number(val || 0);
  if (num >= 1000 * 1000 * 1000) {
    valStr = `${round(num / (1000 * 1000 * 1000), 1)}G`;
  } else if (num >= 1000 * 1000) {
    valStr = `${round(num / (1000 * 1000), 1)}M`;
  } else if (num >= 1000) {
    valStr = `${round(num / 1000, 1)}K`;
  } else {
    valStr = num;
  }
  return valStr;
};

export const parseJSON = (str) => {
  let ret = str;
  if (isString(str)) {
    try {
      ret = JSON.parse(str);
    } catch (ex) {
      console.error(ex);
      ret = str;
    }
  }
  return ret;
};

export const filterJsonByKeyValue = (theObject, filterKey, filterValue) => {
  let result = false;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i += 1) {
      result = filterJsonByKeyValue(theObject[i], filterKey, filterValue);
      if (result) {
        break;
      }
    }
  } else {
    const keys = R.keys(theObject);
    for (let i = 0; i < keys.length; i += 1) {
      const prop = keys[i];
      if (prop === filterKey) {
        if (R.trim(String(theObject[prop])) === filterValue) {
          return true;
        } else if (
          theObject[prop] instanceof Array &&
          R.map((item) => R.trim(String(item)), theObject[prop]).indexOf(filterValue) >= 0
        ) {
          return true;
        }
      } else if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
        result = filterJsonByKeyValue(theObject[prop], filterKey, filterValue);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
};

export const compareJSON = (origObj, newObj, opration) => {
  function compare(value, other) {
    if (opration === 'same') {
      if (isObject(value) && isObject(other)) {
        return true;
      }
      return isEqual(value, other);
    }
    return !isEqual(value, other);
  }
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return transform(newObj, (result, value, key) => {
      if (compare(value, origObj[key])) {
        let resultKey;
        if (isArray(origObj)) {
          resultKey = arrayIndexCounter;
          arrayIndexCounter += 1;
        } else {
          resultKey = key;
        }
        result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : true;
      }
    });
  }
  return changes(newObj, origObj);
};

const escapeRegExp = (str) => {
  return (str || '').trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const highlightContent = (rawData, word, isCritical) => {
  let newData = rawData;
  let color = 'var(--virtualized-table-row-finish-bg)';
  if (isCritical) {
    color = '#D4322E';
  }

  // highlight special regex
  if (isString(newData)) {
    newData = R.replace(/\u2002/g, `<span style="background:${color};padding: 0 2px;">`, newData);
    newData = R.replace(/\u2003/g, '</span>', newData);
  }

  if (isString(word) && word && isString(newData)) {
    const regex = new RegExp(`(${escapeRegExp(word)})`, 'mgi');
    return newData.replace(regex, `<span style="background:${color};padding: 0 2px;">$1</span>`);
  }

  if (isArray(word) && isString(newData)) {
    R.forEach(
      (item) => {
        const value = isObject(item) ? item.keyword : item;
        if (value) {
          const regex = new RegExp(`(${escapeRegExp(value)})`, 'mgi');
          newData = newData.replace(regex, `\u2002$1\u2003`);
        }
      },
      R.sort(
        (a, b) => a.length - b.length,
        R.filter((k) => Boolean(k), word),
      ),
    );
    newData = R.replace(/\u2002/g, `<span style="background:${color};padding: 0 2px;">`, newData);
    newData = R.replace(/\u2003/g, '</span>', newData);
  }

  return newData || '';
};

export const Defaults = {
  YearFormat: 'YYYY',
  MonthFormat: 'YYYY-MM',
  MonthOnlyFormat: 'MM',
  MonthShortFormat: 'MMM',
  DateFormat: 'YYYY-MM-DD',
  DateFormat1: 'yyyy-MM-dd',
  DateFormat2: 'MM/DD/YYYY',
  DateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  DateTimeFormat1: 'yyyy-MM-dd HH:mm',
  ShortDateTimeFormat: 'MM-DD HH:mm:ss',
  SmartDateFormat: 'YYYYMMDD',
  ShortDayFormat: 'MM-DD',
  ShortDayEnFormat: 'MMMM Do',
  TimeFormat: 'YYYY-MM-DD HH:mm',
  TimeOnlyFormat: 'HH:mm',
  DatePickerTime: 'HH:mm',
  ShortTimeOnlyFormat: 'HH:mm:ss',
  ShortTimeFormat: 'MM-DD HH:mm',
  SmartTimeFormat: 'YYYYMMDDHHmm',
  PagingSize: 50,
  NumberOfDays: 14,
  LargeNumberOfDays: 365 * 2,
  InstanceGroup: 'All',
  AllInstanceName: '_all',
  LogInstanceName: 'unknownApplication',
  PredictionWindow: 4, // hours
  PatternNamePrefix: 'Pattern ',
  StackTraceNames: ['stacktrace', 'stack-trace'],
  ColorNames: [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'teal',
    'blue',
    'violet',
    'purple',
    'pink',
    'brown',
    'grey',
    'black',
  ],
  KpiPredictionProbability: '0.75',
  DisplayedMenus: [1, 2, 3, 4, 5, 6, 8],
  InsightWidgets: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  demoDay: () => moment.utc().subtract(1, 'days').format(Defaults.DateFormat),
  PatternIdNameStr: ({ patternName, patternId, type, isLog }, { hasFullId, hasFullName, hasPrefix }) => {
    let newPatternName = patternName;
    let patternIdStr = String(patternId);
    const neuronId = parseInt(patternId, 10);

    if (neuronId === -1) {
      newPatternName = newPatternName || 'Threshold Violation';
    } else if (neuronId === -2) {
      patternIdStr = hasFullId ? 'Miscellaneous' : 'Misc';
    } else if (neuronId === -3) {
      newPatternName = newPatternName || 'Service Hang';
    } else if (neuronId === -4) {
      newPatternName = newPatternName || 'System outage';
    } else if (neuronId === undefined && type === 'removedInstance') {
      newPatternName = newPatternName || 'Instance Down';
    }

    if (!newPatternName) {
      newPatternName = `${hasPrefix ? 'Pattern ' : ''}${patternIdStr}`;
    } else if (hasFullName && patternIdStr) {
      newPatternName = `[${patternIdStr}] ${newPatternName}`;
    }
    return { patternIdStr, patternNameStr: newPatternName };
  },
  CausalCronInterval: 7,

  // http://colorbrewer2.org/#type=sequential&scheme=RdYlBu&n=11
  Colorbrewer: schemeRdYlBu[11],
  Colorbrewer5: schemeRdYlBu[5],
  ColorbrewerMore: schemePaired,
  ColorStatusFont: { success: '#52c41a', warning: '#faad14', info: '#1890ff', error: '#f5222d' },
  ColorStatusBackground: { success: '#f6ffed', warning: '#fffbe6', info: '#e6f7ff', error: '#fff1f0' },
};

export const Constants = {
  PapaData: ['data'],
  PapaFields: ['meta', 'fields'],
  Timestamp: 'timestamp',
};

export const Params = {
  ProjectName: 'projectName',
  MetricProjectName: 'metricProjectName',
  LogProjectName: 'logProjectName',
  ProjectList: 'projectList',
  InstanceGroup: 'instanceGroup',
  StartTime: 'startTime',
  EndTime: 'endTime',
  StartTimestamp: 'startTimestamp',
  EndTimestamp: 'endTimestamp',
  InstanceName: 'instanceName',
  LogEventType: 'logEventType',
  Time: 'time',
  Interval: 'interval',
  Duration: 'duration',
  TrainingWindow: 'trainingWindow',
  ForecastInterval: 'forecastInterval',
  ModelType: 'modelType',
  AppName: 'appName',
  Keyword: 'keyword',
  ExcludingKeyword: 'excludingKeyword',
  SimilarityLevel: 'similarityLevel',
  NumOfCluster: 'numOfCluster',
  IncidentId: 'incidentId',
  Pattern: 'pattern',
};
export const FilterQueryList = [
  'b81364228d574502b7f6c3e454b9a21b',
  '953de6a33d8a4b96ac9c100bf69ba3fc',
  'search_all_log_entries',
  'live_tail_log_entries',
];

export const Options = {
  KpiPredictionProbability: [
    { label: '50%', value: '0.5' },
    { label: '75%', value: '0.75' },
    { label: '80%', value: '0.8' },
    { label: '90%', value: '0.9' },
    { label: '100%', value: '1.0' },
  ],
  WeekOptions: [
    { label: 'Sunday', value: '1' },
    { label: 'Monday', value: '2' },
    { label: 'Tuesday', value: '3' },
    { label: 'Wednesday', value: '4' },
    { label: 'Thursday', value: '5' },
    { label: 'Friday', value: '6' },
    { label: 'Saturday', value: '7' },
  ],
  HourOptions: R.map((i) => ({ label: String(i), value: String(i) }), R.range(0, 25)),
  MinuteOptions: R.map((i) => ({ label: i < 10 ? `0${i}` : String(i), value: String(i) }), R.range(0, 60)),
};

export const getUIMenus = (intl, appMenusMessages) => {
  return [
    { id: 1, name: intl.formatMessage(appMenusMessages.globalHealthView) },
    { id: 2, name: intl.formatMessage(appMenusMessages.globalSystemInsights), allowHide: true },
    { id: 3, name: intl.formatMessage(appMenusMessages.globalSystemRootCause), allowHide: true },
    { id: 4, name: intl.formatMessage(appMenusMessages.globalSystemPrediction), allowHide: true },
    // { id: 5, name: intl.formatMessage(appMenusMessages.globalSystemAlertCause), allowHide: true },
    { id: 6, name: intl.formatMessage(appMenusMessages.globalSystemKnowledgeBase), allowHide: true },
    // { id: 7, name: intl.formatMessage(appMenusMessages.globalCapacityView), allowHide: true },
    // { id: 7, name: intl.formatMessage(appMenusMessages.insightQuery), allowHide: true },
    { id: 8, name: intl.formatMessage(appMenusMessages.analysis), allowHide: true },
  ];
};

export const getHttpStatusInfo = (response) => {
  let info = 'error';
  if (response && [200, 204].indexOf(response.status) >= 0) {
    info = 'success';
  }
  return info;
};

export const getExternalSystemId = (event, cloudType) => {
  if (cloudType === 'ServiceNow') {
    return get(parseJSON(event.rawData) || {}, 'number');
  }
  return null;
};

export const getReaderStream = (reader) => {
  return new ReadableStream({
    start(controller) {
      const pump = () => {
        return reader.read().then(({ done, value }) => {
          // When no more data needs to be consumed, close the stream
          if (done) {
            controller.close();
            return undefined;
          }
          // Enqueue the next data chunk into our target stream
          controller.enqueue(value);
          return pump();
        });
      };
      return pump();
    },
  });
};

export const downloadFile = (dataString, fname, type: 'text/csv') => {
  const data = new Blob([dataString], { type });
  const url = URL.createObjectURL(data);
  const aTag = document.createElement('a');
  document.body.appendChild(aTag);
  aTag.innerHTML = 'Click here';
  aTag.href = url;
  aTag.target = '_blank';
  aTag.method = 'POST';
  aTag.download = fname || 'null';
  aTag.click();
  document.body.removeChild(aTag);
};

export const downloadPdf = async (pdfFilename, pdfContents, exportCallback, progressCallBack) => {
  // EXPORT PDF
  // build pdf object and set params
  const pdf = new jsPDF('p', 'mm', 'a4');
  // A4: 210mm x 297mm, add padding: 10mm
  const a4w = 190;
  const a4h = 277;

  // add divOfPdf to print
  const pdfDom = document.createElement('div');
  pdfDom.setAttribute('id', 'divOfPdf');
  pdfDom.setAttribute('style', 'position:absolute;top:0px;height:0px;overflow:hidden;');
  document.body.appendChild(pdfDom);
  let persent = 0;
  if (progressCallBack) {
    progressCallBack(persent);
  }
  // build multi canvas by content list
  const canvasList = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const pdfContent of pdfContents) {
    pdfDom.innerHTML = pdfContent;
    const targetElm = document.getElementById('divToPrint');
    const targetElmScrollHeight = parseInt(targetElm.scrollHeight || 0, 10);
    const targetElmClientHeight = parseInt(targetElm.clientHeight || 0, 10);
    const targetElmClienWidth = parseInt(targetElm.clientWidth || 0, 10);
    const pageHeight = (Math.floor((a4h * targetElmClienWidth) / a4w) || targetElmClientHeight) * 10;
    const maxHight = pageHeight;
    let totalHeight = targetElmScrollHeight;
    const canvasHeight = Math.min(maxHight, totalHeight);
    targetElm.style.height = `${canvasHeight}px`;
    const curPersent = 0.7 / pdfContents.length;
    const peerPersent = curPersent / Math.ceil(targetElmScrollHeight / canvasHeight);
    while (totalHeight > 0) {
      // eslint-disable-next-line no-await-in-loop
      const canvas = await html2canvas(targetElm, {
        background: '#FFFFFF',
        scale: 1,
        imageTimeout: 0,
        height: Math.min(maxHight, totalHeight),
        // eslint-disable-next-line no-loop-func
      }).then((canvas) => {
        totalHeight -= Math.min(maxHight, totalHeight);
        if (totalHeight < canvasHeight) {
          targetElm.style.height = `${totalHeight}px`;
        }
        targetElm.scrollTo(0, targetElmScrollHeight - totalHeight);
        persent = Math.min(0.5, persent + peerPersent);
        if (progressCallBack) {
          progressCallBack(persent);
        }
        return canvas;
      });
      canvasList.push(canvas);
    }
  }
  persent = 0.7;
  if (progressCallBack) {
    progressCallBack(persent);
  }
  await sleep(500);
  // 50%
  // COMBINE ALL CANVAS TO THE PDF
  let canvasIdx = 1;
  const canvasToImagePeerPersent = 0.3 / canvasList.length;
  R.forEach((canvas) => {
    const ctx = canvas.getContext('2d');
    // covert A4 mm to px
    const mmRatio = canvas.width / a4w;
    const imgHeight = Math.floor((a4h * canvas.width) / a4w);
    let renderedHeight = 0;
    while (renderedHeight < canvas.height) {
      // add bottom padding for image if is not last page
      const bottomAppending = renderedHeight + imgHeight < canvas.height ? 3 : 0;

      // build new canvas by single pdf page
      const page = document.createElement('canvas');
      page.width = canvas.width;
      // if img height < A4 height, use the img height
      // get more image size: 4mm
      const fullImageHeight = Math.min(imgHeight, canvas.height - renderedHeight) + bottomAppending * mmRatio;
      page.height = fullImageHeight;
      // getImageData and put to canvas
      page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, fullImageHeight), 0, 0);
      // add image to pdf, and add padding: 10mm
      pdf.addImage(
        page.toDataURL('image/jpeg', 0.9),
        'JPEG',
        10,
        10,
        a4w,
        Math.min(a4h, (page.height * a4w) / page.width) + bottomAppending,
      );
      renderedHeight += imgHeight;

      // add new page to pdf if has data in canvas
      if (renderedHeight < canvas.height) pdf.addPage();
      page.remove();
    }

    // add new page for next canvas
    if (canvasIdx < canvasList.length) pdf.addPage();
    canvasIdx += 1;
    persent = Math.min(0.99, persent + canvasToImagePeerPersent);
    if (progressCallBack) {
      progressCallBack(persent);
    }
  }, canvasList);

  if (progressCallBack) {
    progressCallBack(0.99);
  }
  // SAVE PDF FILE
  await pdf.save(pdfFilename, { returnPromise: true });
  // remove print dom
  if (pdfDom) pdfDom.remove();
  if (progressCallBack) {
    progressCallBack(1);
  }
  await sleep(500);
  // after download
  exportCallback();
};

export const downloadPdfV2 = (pdfFilename, pdfContents, exportCallback) => {
  // EXPORT PDF
  // build pdf object and set params
  const pdf = new jsPDF('p', 'mm', 'a4');
  // A4: 210mm x 297mm, add padding: 10mm
  const a4w = 190;
  const a4h = 277;

  // add divOfPdf to print
  const pdfDom = document.createElement('div');
  pdfDom.setAttribute('id', 'divOfPdf');
  pdfDom.setAttribute('style', 'position:absolute;top:0px;height:0px;overflow:hidden;');
  document.body.appendChild(pdfDom);

  // build multi canvas by content list
  const canvasRequests = R.map((pdfContent) => {
    // add pdfContent to divOfPdf
    pdfDom.innerHTML = pdfContent;
    // get input dom
    const targetElm = document.getElementById('divToPrint');
    return toCanvas(targetElm, {
      scale: 1,
      background: '#FFFFFF',
    });
  }, pdfContents);

  // covert doms to canvas list
  return Promise.all(canvasRequests).then((canvasList) => {
    // COMBINE ALL CANVAS TO THE PDF
    let canvasIdx = 1;
    R.forEach((canvas) => {
      const ctx = canvas.getContext('2d');
      // covert A4 mm to px
      const mmRatio = canvas.width / a4w;
      const imgHeight = Math.floor((a4h * canvas.width) / a4w);
      let renderedHeight = 0;
      while (renderedHeight < canvas.height) {
        // add bottom padding for image if is not last page
        const bottomAppending = renderedHeight + imgHeight < canvas.height ? 3 : 0;

        // build new canvas by single pdf page
        const page = document.createElement('canvas');
        page.width = canvas.width;
        // if img height < A4 height, use the img height
        // get more image size: 4mm
        const fullImageHeight = Math.min(imgHeight, canvas.height - renderedHeight) + bottomAppending * mmRatio;
        page.height = fullImageHeight;
        // getImageData and put to canvas
        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, fullImageHeight), 0, 0);
        // add image to pdf, and add padding: 10mm
        pdf.addImage(
          page.toDataURL('image/jpeg', 0.9),
          'JPEG',
          10,
          10,
          a4w,
          Math.min(a4h, (page.height * a4w) / page.width) + bottomAppending,
        );
        renderedHeight += imgHeight;

        // add new page to pdf if has data in canvas
        if (renderedHeight < canvas.height) pdf.addPage();
        page.remove();
      }

      // add new page for next canvas
      if (canvasIdx < canvasList.length) pdf.addPage();
      canvasIdx += 1;
    }, canvasList);

    // SAVE PDF FILE
    pdf.save(pdfFilename, { returnPromise: true }).then(() => {
      // remove print dom
      if (pdfDom) pdfDom.remove();

      // after download
      exportCallback();
    });
  });
};

export const downloadImage = (filename, content, exportCallback) => {
  // add divOfImage to print
  const imageDom = document.createElement('div');
  imageDom.setAttribute('id', 'divOfImage');
  imageDom.setAttribute('style', 'position:absolute;top:0px;height:0px;overflow:hidden;');
  document.body.appendChild(imageDom);

  // add content to divOfImage
  imageDom.innerHTML = content;
  // get input dom
  const targetElm = document.getElementById('divToPrint');
  return html2canvas(targetElm, {
    scale: 1,
  }).then((canvas) => {
    const aTag = document.createElement('a');
    document.body.appendChild(aTag);
    aTag.href = canvas.toDataURL('image/png');
    aTag.download = filename;
    aTag.target = '_blank';
    aTag.click();
    document.body.removeChild(aTag);

    // remove print dom
    if (imageDom) imageDom.remove();

    // after download
    exportCallback();
  });
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const LogTypes = [
  'normal',
  'rare',
  'hot',
  'cold',
  'critical',
  'newpattern',
  'whitelist',
  'incident',
  'deployment',
];

export const monthFormat = 'YYYY-MM';
export const dateFormat = 'YYYY-MM-DD';
export const defaultNumberOfDays = 14;
export const defaultLargeNumberOfDays = 365 * 2;

export const defaultInstanceGroup = 'All';

export function timeScopeControl(startTimeObj, endTimeObj, timeObj, operation, day = 30 * 1000000000) {
  let statusTime;
  switch (operation) {
    case 'add':
      statusTime = endTimeObj;
      break;
    case 'subtract':
      statusTime = startTimeObj;
      break;
  }
  const timeDayGTE0 = R.gte(endTimeObj.diff(startTimeObj, 'days'))(0); // endTimeObj and startTimeObj diff Is it greater than or less than 0 (Greater than 0 means endTimeObj is greater than startTimeObj)
  const timeDayGTDay = R.gt(endTimeObj.diff(startTimeObj, 'days'))(day); // endTimeObj and startTimeObj diff Is it greater than or less than ‘day’
  const timeUTCOperationDay = moment.utc(timeObj.valueOf())[operation](30 || day, 'days'); // current time ‘operation’ ‘day’
  const timeUTCAdd1 = moment.utc().add(1, 'days').endOf('day'); // The maximum end validity period (can not be more than)
  let refreshTimeObj;
  if (timeDayGTE0) {
    refreshTimeObj = timeDayGTDay ? timeUTCOperationDay : statusTime;
  } else {
    switch (operation) {
      case 'add':
        refreshTimeObj = R.gte(timeUTCOperationDay)(timeUTCAdd1) ? timeUTCAdd1 : timeUTCOperationDay;
        break;
      case 'subtract':
        refreshTimeObj = timeUTCOperationDay;
        break;
    }
  }
  return refreshTimeObj;
}

export const getRegExp = (regexValue) => {
  let regex = null;
  try {
    regex = new RegExp(regexValue, 'i');
  } catch (error) {
    // console.log(error)
  }
  return regex;
};

export { default as buildLocation } from './buildLocation';
export { default as buildMatchLocation } from './buildMatchLocation';
export { default as buildUrl } from './buildUrl';
export { default as getLoaderEpicAction } from './getLoaderEpicAction';
export { default as getStartEndTimeRange } from './getStartEndTimeRange';
export { default as calcColorOfAnomalyScore } from './calcColorOfAnomalyScore';
export { default as parseQueryString } from './parseQueryString';
export { default as parseLocation } from './parseLocation';
export { default as parseUrl } from './parseUrl';
export { default as urlJoin } from './urlJoin';
export { CellRenderers, anomalySeverityRendererItem } from './CellRenderers';
export { default as Regex } from './Regex';
export { default as getLocalTimeDiff } from './getLocalZoneTime';

export { default as getLoaderAction } from './getLoaderAction';
// v2
export { default as getLoadStatusActions } from './getLoadStatusActions';
export { default as getLoadStatus } from './getLoadStatus';
export { default as calcAnomalyLevel } from './calcAnomalyLevel';
export { calcColorOfAnomalyLevel, calcColorOfHealthScore } from './calcColorOfAnomalyLevel';
export { default as CalcColorOfAnomalyScore } from './calcColorOfMaxScore';
export { default as withTooltip } from './withTooltip';
export { default as MenuBarRender } from './MenuBarRender';
export { ExportModal } from './UtilsModal';
export { BackgroundCall } from './BackgroundCall';
export { default as LoginRenderers } from './LoginRenderers';
export { GlobalRenderers } from './GlobalRenderers';
export { EventRenderers } from './EventRenderers';
export { LogRenderers } from './LogRenderers';
export { MetricRenderers } from './MetricRenderers';
export { CausalRenderers } from './CausalRenderers';
export { SettingRenderers } from './SettingRenderers';

export { default as ScriptUtil } from './ScriptUtil';
export { default as CookieUtil } from './CookieUtil';
export { default as GlobalParse } from './GlobalParse';
export { default as EndPointParser } from './EndPointParser';
export { default as CausalParser } from './CausalParser';
export { default as MetricParser } from './MetricParser';
export { default as LogParser } from './LogParser';

export { default as EventActionHandle } from './EventActionHandle';
export { getPatternNameIcon, getPatternNameIconPath } from './getPatternNameIcon';
