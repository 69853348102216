/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { RightOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Alert,
  Form,
  InputNumber,
  Input,
  Select,
  Upload,
  message,
  Checkbox,
  Divider,
  TreeSelect,
  Card,
} from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { MetricParser } from '../../../../common/utils';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { projectWizardMessages } from '../../../../common/settings/messages';

type Props = {
  intl: Object,
  credentials: Object,
  saveProjectInfo: Function,
  componentState: Object,
};

class KubernetesSetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;
    this.state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      clusterType: 'k8sCustom',
      clusterName: '',
      enableAutoscale: false,
      isLoadingFile: false,
      configFileList: [],
      fileContent: undefined,

      projectId: '',
      privateKeyId: '',
      privateKey: '',
      clientEmail: '',
      clientId: '',

      instanceTypeMetricsMap: {},
      instanceTypeList: [],
      preSelectedMetrics: [],
      instanceTypes: [],
      treeData: [],
      treeDataKeys: [],
      selectedMetrics: [],

      samplingInterval: 10,
      samplingUnit: 60,

      ...(componentState || {}),
    };
    this.clusterTypeOptions = [
      { value: 'k8sCustom', label: 'Custom' },
      { value: 'k8sGke', label: 'Google Kubernetes Engine (GKE)' },
    ];
    this.samplingUnitOption = [
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
  }

  @autobind
  beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('File must smaller than 2MB!');
      return false;
    }

    this.setState({ isLoadingFile: true, configFileList: [file] });
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const fileContent = reader.result;
        this.setState({ isLoadingFile: false, fileContent });
      };
    });
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { credentials } = this.props;
    const {
      clusterType,
      clusterName,
      enableAutoscale,
      fileContent,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
    } = this.state;
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: 'Kubernet',
      clusterType,
      clusterName,
      enableAutoscale,
      fileConfig: fileContent,
      ...(clusterType === 'k8sGke' ? { projectId, privateKeyId, privateKey, clientEmail, clientId } : {}),
    })
      .then((data) => {
        const { success, message: errMsg, metricsList, preSelected } = data;
        if (success) {
          let newState = {
            isVerifying: false,
            verified: true,
            verifiedMessage: '',
          };
          if (clusterType === 'k8sGke') {
            // build instanceType Metrics Map
            let instanceTypeMetricsMap = {};
            R.forEach((val) => {
              R.forEach((item) => {
                const { instanceType, metrics } = item;
                if (!R.has(instanceType, instanceTypeMetricsMap)) instanceTypeMetricsMap[instanceType] = [];

                instanceTypeMetricsMap[instanceType] = [...instanceTypeMetricsMap[instanceType], ...(metrics || [])];
              }, val || []);
            }, metricsList);
            // filter kubernetes metrics
            instanceTypeMetricsMap = R.mapObjIndexed((val, key) => {
              if (key === 'Others') {
                return R.filter((item) => !item.startsWith('kubernetes.io/'), val);
              } else {
                return val;
              }
            }, instanceTypeMetricsMap);
            const instanceTypeList = R.keys(instanceTypeMetricsMap);
            newState = { ...newState, instanceTypeMetricsMap, instanceTypeList, preSelectedMetrics: preSelected };
          }

          this.setState(newState, () => {
            this.props.saveProjectInfo('Kubernetes', {}, this.state);
          });
        } else {
          this.setState(
            {
              instanceTypeMetricsMap: {},
              instanceTypeList: [],
              preSelectedMetrics: [],
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('Kubernetes', {}, this.state);
            },
          );
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState(
          {
            instanceTypeMetricsMap: {},
            instanceTypeList: [],
            preSelectedMetrics: [],
            isVerifying: false,
            verified: false,
            verifiedMessage: errMsg || 'No metric data is found under the selected instance type.',
          },
          () => {
            this.props.saveProjectInfo('Kubernetes', {}, this.state);
          },
        );
      });
  }

  @autobind
  handleInstanceTypeChange(instanceTypes) {
    this.setState({ instanceTypes }, () => {
      const { instanceTypeMetricsMap, preSelectedMetrics, selectedMetrics } = this.state;
      // build tree data
      let allMetrics = [];
      R.forEachObjIndexed((metrics) => {
        allMetrics = [...allMetrics, ...metrics];
      }, R.pick(instanceTypes, instanceTypeMetricsMap));
      const { treeData, treeDataKeys } = MetricParser.getMetricTreeStructure(allMetrics);

      // set pre selected metrics
      let newMetrics = selectedMetrics;
      R.forEach((val) => {
        const { metrics } = val;
        newMetrics = [...newMetrics, ...(metrics || [])];
      }, preSelectedMetrics);
      newMetrics = R.intersection(newMetrics, treeDataKeys);

      this.setState({ treeData, treeDataKeys, selectedMetrics: newMetrics });
    });
  }

  @autobind
  handleConfirmClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const {
      clusterType,
      clusterName,
      enableAutoscale,
      fileContent,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      selectedMetrics,
      samplingInterval,
      samplingUnit,
    } = this.state;
    this.props.createProject(
      'Kubernetes',
      {
        clusterType,
        clusterName,
        enableAutoscale,
        fileConfig: fileContent,
        ...(clusterType === 'k8sGke'
          ? { projectId, privateKeyId, privateKey, clientEmail, clientId, metrics: JSON.stringify(selectedMetrics) }
          : {}),
        samplingInterval: Number(samplingInterval) * samplingUnit,
      },
      this.state,
    );
  }

  render() {
    const { intl, isLoading } = this.props;
    const {
      clusterType,
      clusterName,
      enableAutoscale,
      isLoadingFile,
      configFileList,
      fileContent,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
    } = this.state;
    const {
      isVerifying,
      verified,
      verifiedMessage,
      instanceTypeList,
      instanceTypes,
      treeData,
      // eslint-disable-next-line no-unused-vars
      treeDataKeys,
      selectedMetrics,
      samplingInterval,
      samplingUnit,
    } = this.state;

    let hasVerifyError = !clusterType;
    if (enableAutoscale) {
      hasVerifyError = hasVerifyError || R.isNil(fileContent);
    }
    if (clusterType === 'k8sGke') {
      hasVerifyError =
        hasVerifyError || !clusterName || !projectId || !privateKeyId || !privateKey || !clientEmail || !clientId;
    }
    let hasError = hasVerifyError || !samplingInterval || !samplingUnit || this.props.hasError;
    if (clusterType === 'k8sGke') {
      hasError = hasError || !selectedMetrics || selectedMetrics.length === 0;
    }
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: `<span>${intl.formatMessage(projectWizardMessages.KubernetesIntro)}</span>
            <span> For details, please visit
              <a
                href="https://github.com/insightfinder/InsightAgent/tree/master/kubernetes-agent"
                target="_blank"
                rel="noreferrer"
              >
                https://github.com/insightfinder/InsightAgent/tree/master/kubernetes-agent
              </a>
            </span>`,
          }}
        />

        {false && verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        {false && (
          <Card>
            <Form layout="vertical">
              <Form.Item
                label="Cluster Type"
                required
                validateStatus={!clusterType ? 'error' : 'success'}
                help={!clusterType ? 'This input is required.' : undefined}
              >
                <Select
                  options={this.clusterTypeOptions}
                  value={clusterType}
                  onChange={(clusterType) => this.setState({ clusterType })}
                />
              </Form.Item>
              <Form.Item
                label="Cluster Name"
                required={clusterType === 'k8sGke'}
                validateStatus={clusterType === 'k8sGke' && !clusterName ? 'error' : 'success'}
                help={clusterType === 'k8sGke' && !clusterName ? 'This input is required.' : undefined}
              >
                <Input value={clusterName} onChange={(e) => this.setState({ clusterName: e.target.value })} />
              </Form.Item>
              <div className="flex-row" style={{ marginBottom: 24 }}>
                <div className="bold" style={{ marginRight: 16 }}>
                  Enable Autoscale
                </div>
                <Checkbox
                  size="small"
                  checked={enableAutoscale}
                  onChange={(e) => {
                    const newVal = e.target.checked;
                    const state = { enableAutoscale: newVal };
                    if (!newVal) {
                      state.configFileList = [];
                      state.fileContent = undefined;
                    }
                    this.setState(state);
                  }}
                />
              </div>
              {enableAutoscale && (
                <Form.Item label="Config File" required>
                  <Upload
                    onRemove={(file) => this.setState({ configFileList: [], fileContent: undefined })}
                    beforeUpload={this.beforeUpload}
                    fileList={configFileList}
                  >
                    <Button>{isLoadingFile ? <LoadingOutlined /> : <UploadOutlined />} Select File</Button>
                  </Upload>
                </Form.Item>
              )}

              {clusterType === 'k8sGke' && (
                <>
                  <Form.Item
                    label="Project Id"
                    required
                    validateStatus={!projectId ? 'error' : 'success'}
                    help={!projectId ? 'This input is required.' : undefined}
                  >
                    <Input value={projectId} onChange={(e) => this.setState({ projectId: e.target.value })} />
                  </Form.Item>
                  <Form.Item
                    label="Private key Id"
                    required
                    validateStatus={!privateKeyId ? 'error' : 'success'}
                    help={!privateKeyId ? 'This input is required.' : undefined}
                  >
                    <Input.Password
                      value={privateKeyId}
                      onChange={(e) => this.setState({ privateKeyId: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Private key"
                    required
                    validateStatus={!privateKey ? 'error' : 'success'}
                    help={!privateKey ? 'This input is required.' : undefined}
                  >
                    <Input.Password
                      value={privateKey}
                      onChange={(e) => this.setState({ privateKey: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Client email"
                    required
                    validateStatus={!clientEmail ? 'error' : 'success'}
                    help={!clientEmail ? 'This input is required.' : undefined}
                  >
                    <Input value={clientEmail} onChange={(e) => this.setState({ clientEmail: e.target.value })} />
                  </Form.Item>
                  <Form.Item
                    label="Client Id"
                    required
                    validateStatus={!clientId ? 'error' : 'success'}
                    help={!clientId ? 'This input is required.' : undefined}
                  >
                    <Input.Password value={clientId} onChange={(e) => this.setState({ clientId: e.target.value })} />
                  </Form.Item>
                </>
              )}
            </Form>
            <div className="inline field text-right">
              <Button
                type="primary"
                style={{ width: 120, marginTop: 16 }}
                loading={isVerifying}
                disabled={hasVerifyError}
                onClick={this.handleVerifyClick}
              >
                Verify
              </Button>
            </div>
          </Card>
        )}
        {false && (
          <Card className={`${verified ? 'block' : 'display-none'}`}>
            <Form layout="vertical">
              {clusterType === 'k8sGke' && (
                <>
                  <Form.Item
                    label="Instance Type"
                    validateStatus={R.isEmpty(instanceTypes) ? 'error' : 'success'}
                    help={R.isEmpty(instanceTypes) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                    required
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      autoClearSearchValue={false}
                      maxTagCount={1}
                      value={instanceTypes}
                      onChange={this.handleInstanceTypeChange}
                      showSearch
                      optionFilterProp="key"
                      optionLabelProp="key"
                      dropdownRender={(menu) => {
                        const selectAll = R.difference(instanceTypeList, instanceTypes).length === 0;
                        return (
                          <div>
                            <div
                              className="flex-row"
                              style={{ padding: '5px 12px' }}
                              onMouseDown={(event) => event.preventDefault()}
                            >
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selectAll}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  if (checked) {
                                    this.handleInstanceTypeChange(instanceTypeList);
                                  } else {
                                    this.handleInstanceTypeChange([]);
                                  }
                                }}
                              />
                              <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                            </div>
                            <Divider style={{ margin: '4px 0' }} />
                            {menu}
                          </div>
                        );
                      }}
                    >
                      {R.map((item) => {
                        return (
                          <Select.Option className="hide-icon" key={item}>
                            <Checkbox
                              checked={instanceTypes.includes(item)}
                              onChange={(e) => null}
                              style={{ marginRight: 8 }}
                            />
                            {item}
                          </Select.Option>
                        );
                      }, instanceTypeList)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Metrics"
                    required
                    validateStatus={selectedMetrics.length === 0 ? 'error' : 'success'}
                    help={selectedMetrics.length === 0 ? 'This input is required.' : undefined}
                  >
                    <TreeSelect
                      style={{ width: '100%' }}
                      allowClear
                      maxTagCount={1}
                      showSearch
                      autoClearSearchValue={false}
                      treeDefaultExpandAll={false}
                      treeCheckable
                      treeNodeLabelProp="title"
                      treeData={treeData}
                      value={selectedMetrics}
                      onChange={(selectedMetrics) => this.setState({ selectedMetrics })}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                label="Sampling Interval"
                validateStatus={!samplingInterval ? 'error' : 'success'}
                help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <div className="flex-row flex-center-align">
                  <Input
                    style={{ width: 200 }}
                    value={samplingInterval}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (/^\d+$/.test(val) || R.isEmpty(val)) {
                        this.setState({ samplingInterval: Number(val) });
                      }
                    }}
                  />
                  <Select
                    style={{ width: 120, marginLeft: 4 }}
                    options={this.samplingUnitOption}
                    value={samplingUnit}
                    onChange={(samplingUnit) => this.setState({ samplingUnit })}
                  />
                </div>
              </Form.Item>
            </Form>
          </Card>
        )}

        {false && (
          <div
            className={`${verified ? 'block' : 'display-none'}`}
            style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
          >
            <Button
              type="primary"
              style={{ width: 120 }}
              disabled={hasError}
              onClick={this.handleConfirmClick}
              loading={isLoading}
            >
              {intl.formatMessage(appButtonsMessages.finished)}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default KubernetesSetting;
