/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import { get } from 'lodash';
import { createLogic } from 'redux-logic';

import { BaseUrls } from '../../../web/app/Constants';
import { idpFetchLogin, getInitData, getDisplayedMenus, getUserMissingInfo } from '../../apis';
import {
  ActionTypes as AppActionTypes,
  appError,
  showAppModal,
  setInitData,
  updateLastActionInfo,
  createSetAction,
} from '../../app/actions';
import { ActionTypes, loginSuccess, loginMessage } from '../actions';
import { appMessages } from '../../app/messages';
import { loginErrorHandler } from '../../errors';
import { buildUrl, getLoaderAction, Defaults } from '../../utils';
import { shouldRunAwesomeTours } from './utils';

const idpUserLoginLogic = createLogic({
  type: [ActionTypes.IDP_USER_LOGIN],
  cancelType: AppActionTypes.APP_STOP,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const { showLoader, hideLoader } = getLoaderAction();
    const { idpState } = action.payload;
    idpFetchLogin(idpState)
      .then(async (data) => {
        const { credentials, userInfo } = data;
        dispatch(showLoader);
        const possibleMenu = await getDisplayedMenus(credentials);
        const displayedMenus = get(possibleMenu, [credentials.userName], Defaults.DisplayedMenus);

        // get user missing info
        const userMissingInfo = await getUserMissingInfo(credentials);
        const { needsInput } = userMissingInfo || {};

        if (needsInput) {
          dispatch(
            createSetAction(
              AppActionTypes.SET_USER_MISSING_INFO,
              {},
              { needInputUserMissingInfo: needsInput, userMissingInfo },
            ),
          );
        } else if (userInfo.isFirstLogin) {
          dispatch(
            createSetAction(
              AppActionTypes.SET_AWESOME_TOURS,
              {},
              {
                runAwesomeTours: shouldRunAwesomeTours(userInfo.isFirstLogin),
                toursState: {
                  stepIndex: 0,
                  url: buildUrl(
                    BaseUrls.GlobalHealth,
                    {},
                    { startTime: Defaults.demoDay(), endTime: Defaults.demoDay(), customerName: 'demoUser' },
                  ),
                },
              },
            ),
          );
        }
        dispatch(updateLastActionInfo());

        // Load the init
        getInitData(credentials, userInfo)
          .then((data) => {
            dispatch(setInitData(data));
            if (data.licenseValid === false) {
              dispatch(showAppModal('warning', { message: appMessages.licenseValidFaild }));
            }
          })
          .catch((err) => {
            dispatch(appError(appMessages.errorsServer, err));
          })
          .then(() => {
            dispatch(loginSuccess(credentials, { ...userInfo, displayedMenus }));
            dispatch(hideLoader);
            done();
          });
      })
      .catch((err) => {
        dispatch(loginMessage(err?.message));
        loginErrorHandler(err, dispatch, done, null);
      });
  },
});

export default idpUserLoginLogic;
