/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { NavLink } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Alert, Steps, Form, AutoComplete, Input, Select, Checkbox, Popover, Card, message } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { buildUrl, parseJSON, parseLocation, Regex } from '../../../common/utils';
import { Container, Box, AutoSizer, Table, Column, Modal } from '../../../lib/fui/react';
import { hideAppLoader } from '../../../common/app/actions';
import { createProject, setProjectCreationStatus } from '../../../common/settings/actions';
import { State } from '../../../common/types';

import { IntegrationsMetadata } from './dataSource';

import { appButtonsMessages, appFieldsMessages, appMenusMessages } from '../../../common/app/messages';
import { settingsMenusMessages, projectWizardMessages, settingsMessages } from '../../../common/settings/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';

type Props = {
  intl: Object,
  push: Function,
  location: Object,
  projects: Array<Object>,
  systemsMap: Object,
  credentials: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  projectCreationStatus: String,
  // eslint-disable-next-line
  projectCreationResponse: Object,
  hideAppLoader: Function,
  createProject: Function,
  setProjectCreationStatus: Function,
  defaultTimezone: String,
  userList: Array<Object>,
  userInfo: Object,
  currentTheme: String,
};

class ProjectWizardCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { intl, credentials, location, systemsMap, isAdmin, isLocalAdmin, isReadUser } = props;
    const params = parseLocation(location);
    const { dataSource, category } = params;
    this.noPermission = isReadUser;

    this.state = {
      currentDataSourceName: dataSource,
      currentCategory: category,

      // step 2
      projectType: '',
      projectInfo: {},
      componentState: {},

      // step 3
      systemName: undefined,
      projectName: '',
      projectOwner: undefined,

      // finished
      dataType: '',

      projectPrefix: '',
      isBatchLoading: false,
    };

    let systemOptions = R.values(systemsMap);
    systemOptions = R.filter((item) => item.owner === credentials.userName, systemOptions);
    systemOptions = R.uniqWith(R.eqBy(R.prop('systemId')), systemOptions);
    systemOptions = R.sortWith(
      [R.ascend(R.compose(R.toLower, R.prop('value')))],
      R.map((item) => ({ value: item.systemName, sid: item.systemId }), systemOptions),
    );
    this.systemOptions = systemOptions;

    // labels
    this.typeList = [
      { label: intl.formatMessage(settingsMessages.fieldName), value: 'fieldName' },
      { label: intl.formatMessage(settingsMessages.content), value: 'content' },
    ];
    this.localLabelsWhitelist = [];
    this.localLabelsIncident = [];
    this.regexPlaceHolderForWhiteList = [
      intl.formatMessage(logMessages.wordSentenceRegex),
      intl.formatMessage(logMessages.regexPlaceHolderForWhiteList),
    ];
    this.regexPlaceHolderForIncident = [
      intl.formatMessage(logMessages.wordSentenceRegex),
      intl.formatMessage(logMessages.regexPlaceHolderForIncident),
    ];
  }

  componentDidMount() {
    const { hideAppLoader, location, isAdmin, isLocalAdmin } = this.props;
    const { dataSource, customerName } = parseLocation(location);
    if (dataSource === 'Dynatrace' && (isAdmin || isLocalAdmin)) {
      this.handleProjectOwnerChange(customerName);
    }
    hideAppLoader();

    this.props.setProjectCreationStatus('', {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { projectCreationStatus } = nextProps;
    if (this.props.projectCreationStatus !== projectCreationStatus) {
      if (projectCreationStatus === 'success') {
        this.handleCreationSuccess(nextProps);
      }
    }
  }

  @autobind
  handleSaveProject(projectType, projectInfo, componentState) {
    this.setState({ projectType, projectInfo, componentState: componentState || {} });
  }

  @autobind
  handleCreate(projectType, projectInfo, componentState) {
    const { createProject, location, push, isAdmin, isLocalAdmin } = this.props;
    const { projectName, systemName, projectOwner } = this.state;
    const params = parseLocation(location);
    const { dataSource } = params;
    let { batch } = params;
    if (projectType === 'Dynatrace') {
      batch = projectInfo.isBatch;
    }

    // get systemKey or create new system with new project
    let systemKey;
    let systemDisplayName;
    if (systemName) {
      const system = R.find((item) => item.value === systemName, this.systemOptions);
      if (system) {
        systemKey = system.sid;
      } else {
        systemDisplayName = systemName;
      }
    }

    // get log labels
    let labelsWhitelist = this.localLabelsWhitelist || [];
    labelsWhitelist = R.filter((item) => item.keyword, labelsWhitelist);
    let labelsIncident = this.localLabelsIncident || [];
    labelsIncident = R.filter((item) => item.keyword, labelsIncident);

    const isOnlyJump = R.includes(projectType, ['Kubernetes']);

    if (batch) {
      switch (dataSource) {
        case 'DataDog':
          this.batchPostApi({ dataSource, labelsWhitelist, labelsIncident, projectInfo });
          break;
        case 'Dynatrace':
          this.batchPostApi({
            projectName,
            projectType,
            projectInfo,
            dataSource,
            systemKey,
            systemDisplayName,
            projectOwner,
            labelsWhitelist,
            labelsIncident,
          });
          break;

        default:
          break;
      }
    } else if (isOnlyJump) {
      const customerName = isAdmin || isLocalAdmin ? projectInfo?.systemOwner || undefined : undefined;
      const systemId = projectInfo?.systemId || undefined;
      setTimeout(() => {
        push(buildUrl(BaseUrls.SystemSetting, {}, { customerName, systemSearch: systemId }));
        window.location.reload();
      }, 200);
    } else {
      createProject(projectName, projectType, {
        ...projectInfo,
        dataSource,
        systemKey,
        systemDisplayName,
        projectOwner,
        labelsWhitelist,
        labelsIncident,
      });
    }
  }

  @autobind
  batchPostApi({ dataSource, labelsWhitelist, labelsIncident, projectInfo }) {
    const { credentials, userInfo, push } = this.props;
    const { projectOwner, projectPrefix } = this.state;
    this.setState({ isBatchLoading: true });

    let request = [];
    if (dataSource === 'DataDog') {
      request = [
        fetchPost(getEndpoint('add-datadog-batch-projects'), {
          ...credentials,
          ...projectInfo,
          dataSource,
          projectOwner: projectOwner || userInfo.userName,
          prefix: projectPrefix,
          labelsWhitelist,
          labelsIncident,
        }),
      ];
    }
    if (dataSource === 'Dynatrace') {
      request = [
        fetchPost(getEndpoint('add-dynatrace-batch-projects'), {
          ...credentials,
          ...projectInfo,
          dataSource,
          projectOwner: projectOwner || userInfo.userName,
        }),
      ];
    }
    Promise.all(request)
      .then((res) => {
        this.setState({ isBatchLoading: false }, () => {
          push(buildUrl(BaseUrls.SystemSetting, {}, {}));
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log(String(err));
        this.setState({ isBatchLoading: false });
      });
  }

  @autobind
  handleProjectOwnerChange(projectOwner) {
    const { credentials } = this.props;
    this.systemOptions = [];
    this.setState({ systemName: undefined }, async () => {
      const result = await fetchGet(getEndpoint('systemframework', 2), {
        ...credentials,
        customerName: projectOwner,
      });
      const systems = R.map((s) => parseJSON(s) || {}, result?.ownSystemArr || []);
      let systemOptions = R.map(
        (item) => ({ value: item.systemDisplayName, sid: item?.systemKey?.systemName }),
        systems,
      );
      systemOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], systemOptions);
      this.systemOptions = systemOptions;
      this.setState({ projectOwner, systemName: undefined });
    });
  }

  @autobind
  handleCreationSuccess(props) {
    // when complate create project, reset projectCreationStatus
    this.props.setProjectCreationStatus('', {});
    const { isAdmin, isLocalAdmin, userInfo } = props;
    const { systemName, projectOwner } = this.state;
    let { projectName } = this.state;
    let systemSearch;

    if (systemName) {
      const system = R.find((item) => item.value === systemName, this.systemOptions);
      systemSearch = system?.sid;
    }
    if (isAdmin || isLocalAdmin) {
      projectName = `${projectName}@${projectOwner}`;
    }

    props.push(
      buildUrl(
        BaseUrls.SettingsProject,
        { projectName },
        {
          systemSearch,
          // projectSearch: projectName,
          projectOwner: projectOwner || userInfo.userName,
          customerName: projectOwner,
        },
      ),
    );
    window.location.reload();
  }

  renderProjectCreation() {
    const {
      intl,
      projects,
      projectCreationStatus,
      isAdmin,
      isLocalAdmin,
      userList,
      location,
      defaultTimezone,
      credentials,
      currentTheme,
      systemsMap,
      userInfo,
    } = this.props;
    const {
      systemName,
      projectName,
      projectOwner,
      projectPrefix,
      isBatchLoading,
      currentDataSourceName,
      currentCategory,
      componentState,
    } = this.state;
    const { dataSource, batch } = parseLocation(location);
    const { verified = false, isHost } = componentState || {};
    const hasErrorProject =
      !projectName ||
      R.find((p) => p.projectName.toLowerCase(0) === projectName.toLowerCase(), projects) ||
      !Regex.projectName.test(projectName);
    let hasError = hasErrorProject || !systemName || (!projectOwner && (isAdmin || isLocalAdmin));
    let showCard = true;
    if (batch) {
      if (dataSource === 'DataDog') {
        hasError = !projectPrefix || (!projectOwner && (isAdmin || isLocalAdmin));
      }
    }
    if (dataSource === 'Dynatrace' && !isHost) {
      if (isAdmin || isLocalAdmin) {
        hasError = !projectOwner && (isAdmin || isLocalAdmin);
        showCard = false;
      } else if (!isAdmin && !isLocalAdmin) {
        hasError = false;
        showCard = false;
      }
    }
    if (dataSource === 'Kubernetes') {
      showCard = false;
    }
    const isSubmitting = projectCreationStatus === 'creating';

    const noShowOwner = dataSource === 'Dynatrace' && (isAdmin || isLocalAdmin);

    let DocumentPage = null;
    let isAgent = false;
    let currentDataSourceComponent = null;
    let configureAllowOptions = {};
    let configureDefaultVal = {};
    let configureDefaultValByCategory = {};
    if (currentDataSourceName) {
      const dataSourceItem = R.find((item) => item.name === currentDataSourceName, IntegrationsMetadata || []);
      if (dataSourceItem) {
        currentDataSourceComponent = dataSourceItem.ConfigurePage;
        if (batch) {
          switch (dataSource) {
            case 'DataDog':
              currentDataSourceComponent = dataSourceItem.BatchConfigurePage;
              break;
            default:
              currentDataSourceComponent = dataSourceItem.ConfigurePage;
              break;
          }
        }
        configureAllowOptions = dataSourceItem.configureAllowOptions || {};
        configureDefaultVal = dataSourceItem.configureDefaultVal || {};
        configureDefaultValByCategory = dataSourceItem.configureDefaultValByCategory || {};
        if (currentCategory) {
          configureDefaultValByCategory = get(configureDefaultValByCategory, currentCategory, {});
          configureDefaultVal = { ...configureDefaultVal, ...configureDefaultValByCategory };
        }
        DocumentPage = dataSourceItem?.DocumentPage;
        isAgent = dataSourceItem?.type === 'Agent';
      }
    }

    return (
      <div className="flex-col full-height full-width overflow-y-auto" style={{ paddingBottom: 100 }}>
        {DocumentPage && isAgent && <DocumentPage intl={intl} />}
        {currentDataSourceComponent &&
          React.createElement(currentDataSourceComponent, {
            intl,
            credentials,
            saveProjectInfo: this.handleSaveProject,
            createProject: this.handleCreate,
            configureDefaultVal,
            configureAllowOptions,
            componentState,
            defaultTimezone,
            hasError,
            isLoading: isSubmitting || isBatchLoading,
            currentTheme,
            isBatch: batch,
            isAdmin,
            isLocalAdmin,
            userList,
            systemsMap,
            userInfo,
          })}

        {showCard && (
          <Card style={{ margin: '16px 16px 0 0' }} className={`${verified ? 'block' : 'display-none'}`}>
            <div className="flex-grow flex-min-height">
              {!batch && (
                <div
                  className="text"
                  style={{ marginBottom: '1em' }}
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(projectWizardMessages.step1Introduction),
                  }}
                />
              )}

              <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left">
                {projectCreationStatus && ['success', 'creating'].indexOf(projectCreationStatus) === -1 && (
                  <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                    <Alert type="error" showIcon message={null} description={projectCreationStatus} />
                  </Form.Item>
                )}

                {!batch && (
                  <Form.Item
                    label={intl.formatMessage(eventMessages.projectName)}
                    validateStatus={hasErrorProject ? 'error' : 'success'}
                    help={
                      !projectName
                        ? intl.formatMessage(appFieldsMessages.inputRequired)
                        : !Regex.projectName.test(projectName)
                        ? 'Project name can only contain characters: 0-9 a-z A-Z -'
                        : R.find((p) => p.projectName.toLowerCase(0) === projectName.toLowerCase(), projects)
                        ? 'Project name already exists, please use another one'
                        : undefined
                    }
                    required
                  >
                    <Input value={projectName} onChange={(e) => this.setState({ projectName: e.target.value })} />
                  </Form.Item>
                )}
                {batch && dataSource === 'DataDog' && (
                  <Form.Item
                    label="Project prefix"
                    validateStatus={!projectPrefix ? 'error' : 'success'}
                    help={!projectPrefix ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                    required
                  >
                    <Input value={projectPrefix} onChange={(e) => this.setState({ projectPrefix: e.target.value })} />
                  </Form.Item>
                )}

                {(isAdmin || isLocalAdmin) && !noShowOwner && (
                  <Form.Item
                    label={intl.formatMessage(appFieldsMessages.owner)}
                    validateStatus={!projectOwner ? 'error' : 'success'}
                    help={
                      (isAdmin || isLocalAdmin) && !projectOwner
                        ? intl.formatMessage(appFieldsMessages.inputRequired)
                        : undefined
                    }
                    required
                  >
                    <Select
                      showSearch
                      size="small"
                      value={projectOwner}
                      style={{ width: 280 }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={this.handleProjectOwnerChange}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.map(
                        (item) => (
                          <Select.Option key={item.userName} value={item.userName}>
                            {item.userName}
                          </Select.Option>
                        ),
                        userList || [],
                      )}
                    </Select>
                  </Form.Item>
                )}
                {!batch && (
                  <Form.Item
                    label={intl.formatMessage(appFieldsMessages.systemName)}
                    validateStatus={!systemName ? 'error' : 'success'}
                    help={!systemName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                    required
                  >
                    <AutoComplete
                      allowClear
                      placeholder="Please type in your system name or select from the existing system list"
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      options={this.systemOptions}
                      value={systemName}
                      onChange={(systemName) => this.setState({ systemName })}
                    />
                  </Form.Item>
                )}
              </Form>
            </div>
          </Card>
        )}
      </div>
    );
  }

  render() {
    const { intl, location, push } = this.props;
    const params = parseLocation(location);
    const { dataSource } = params;
    const dataSourceItem = R.find((item) => item.name === dataSource, IntegrationsMetadata || []);
    const { display: dataSourceDisplay } = dataSourceItem || {};

    return (
      <Container fullHeight withGutter className="flex-col settings">
        <Container className="flex-row flex-center-align" breadcrumb>
          <div className="section">
            <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
            <span className="divider">/</span>
            <NavLink to={BaseUrls.SystemSetting}>
              <span className="label">{intl.formatMessage(settingsMenusMessages.systemSetting)}</span>
            </NavLink>
            <span className="divider">/</span>
            <a
              onClick={(e) => {
                e.preventDefault();
                push(buildUrl(BaseUrls.SettingsIntegrations, {}, params));
              }}
            >
              <span className="label">{intl.formatMessage(appFieldsMessages.integrations)}</span>
            </a>
            <span className="divider">/</span>
            <span className="label">{dataSourceDisplay}</span>
          </div>
        </Container>

        <Container
          className="flex-grow flex-min-height flex-col content-bg corner-10"
          style={{
            margin: '0 16px 8px 16px',
            padding: '16px 0 16px 16px',
          }}
        >
          {this.noPermission && (
            <Alert
              message={null}
              description={intl.formatMessage(projectWizardMessages.errorNotAllowAdmin)}
              type="error"
              showIcon
            />
          )}

          {!this.noPermission && (
            <Container className="flex-grow flex-min-height wizard">{this.renderProjectCreation()}</Container>
          )}
        </Container>
      </Container>
    );
  }
}

const ProjectWizard = injectIntl(ProjectWizardCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { projects, systemsMap, defaultTimezone, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isLocalAdmin, isReadUser } = state.auth.userInfo;
    const { projectCreationStatus, projectCreationResponse } = state.settings;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return {
      location,
      projects,
      systemsMap,
      credentials,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      userList,
      projectCreationStatus,
      projectCreationResponse,
      defaultTimezone,
      userInfo,
      currentTheme,
    };
  },
  { push, hideAppLoader, createProject, setProjectCreationStatus },
)(ProjectWizard);
