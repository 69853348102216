/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import * as R from 'ramda';
import { get } from 'lodash';

import { Container } from '../../../lib/fui/react';
import EventGroup from '../../../../components/log/loganalysis/event-group';
import type { State } from '../../../common/types';
import { getLogContextEventList } from '../../../common/apis';
import { getDurationMs } from '../../../common/utils/DurationUtils';

const PropNames = ['projectName', 'startTimestampObj', 'endTimestampObj', 'keyword', 'duration'];

type Props = {
  width: Number,
  height: Number,
  queryResult: Object,
  queryParams: Object,
  metadata: Object,
  credentials: Object,
  projects: Array<Object>,
  isAdmin: Boolean,
};

const PAGE_SIZE_ALL = 6000;
const MAX_TOTAL_COUNT = Number.MAX_SAFE_INTEGER;

class LogAllEntries extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      events: [],
      clusterSampleMsgs: {},
    };
  }

  @autobind
  async loadMoreRows(startTime, endTime, instanceName) {
    const { credentials } = this.props;
    const params = R.pick(PropNames, this.props?.queryParams || {});

    let events = [];
    const clusterSampleMsgs = {};
    let succeed = false;
    try {
      const d = await getLogContextEventList(credentials, {
        ...params,
        instanceName,
        startTimestamp: startTime,
        endTimestamp: endTime,
        pageNo: 1,
        pageSize: PAGE_SIZE_ALL,
      });

      const { data, clusterInfoList } = d;
      const totalCount = data?.totalCount;
      events = data?.events || [];
      if (totalCount > events.length) {
        console.warn('[IF] page size is too small to retrieve all log entries in time range');
      }

      R.forEach((c) => {
        const sampleMsgs = get(c, ['sampleMsg'], []);
        R.addIndex(R.forEach)((s, idx) => {
          clusterSampleMsgs[`${c.nid}[${idx}]`] = s;
        }, sampleMsgs);
      }, clusterInfoList || []);
      succeed = true;
    } catch (err) {
      console.error('[IF_API] Failed get event list', err);
    }

    return { succeed, events, clusterSampleMsgs };
  }

  render() {
    const { width, height, queryParams } = this.props;
    const { isLoading, events, clusterSampleMsgs } = this.state;
    const { startTimestampObj, endTimestampObj, duration } = queryParams || {};
    const { projectName } = queryParams;
    let { instanceName } = queryParams;
    instanceName = JSON.parse(instanceName || '[]');
    const hasMultiple = instanceName && instanceName.length > 1;
    return (
      <Container style={{ width, height }}>
        <Container
          className={`flex-row ${isLoading ? ' loading' : ''}`}
          style={{ width, height, overflowY: 'auto', overflowX: 'auto' }}
        >
          <EventGroup
            name=""
            projectName={projectName}
            eventDataset={events}
            totalCount={MAX_TOTAL_COUNT}
            pageSize={PAGE_SIZE_ALL}
            pageNo={1}
            loadMoreRows={this.loadMoreRows}
            isContext={false}
            showFE={false}
            hasType={false}
            hasCount={false}
            hasNid
            hasDecompress
            clusterSampleMsgs={clusterSampleMsgs}
            isInfiniteLoad
            startTime={startTimestampObj.valueOf()}
            endTime={endTimestampObj.valueOf()}
            initExpandDuration={duration}
            instanceList={instanceName}
            isManualLoad
          />
          {hasMultiple && (
            <EventGroup
              name=""
              projectName={projectName}
              eventDataset={events}
              totalCount={MAX_TOTAL_COUNT}
              pageSize={PAGE_SIZE_ALL}
              pageNo={1}
              loadMoreRows={this.loadMoreRows}
              isContext={false}
              showFE={false}
              hasType={false}
              hasCount={false}
              hasNid
              hasDecompress
              clusterSampleMsgs={clusterSampleMsgs}
              isInfiniteLoad
              startTime={startTimestampObj.valueOf()}
              endTime={endTimestampObj.valueOf()}
              initExpandDuration={duration}
              instanceList={instanceName}
              extra
              isManualLoad
            />
          )}
        </Container>
      </Container>
    );
  }
}

export default connect((state: State) => {
  const { credentials } = state.auth;
  const { projects } = state.app;
  const { isAdmin } = state.auth.userInfo;
  return { credentials, isAdmin, projects };
}, {})(LogAllEntries);
