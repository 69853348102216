/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import { get } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import fetchPost from './fetchPost';

import { parseJSON } from '../utils';

const getProjectInfo = (credentials: Credentials, params: Object) => {
  const { projectList, includeInstance, startTimestamp, endTimestamp, withStats } = params;
  const projectListStr = JSON.stringify(projectList);
  const requests = [];

  requests.push(
    fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
      ...credentials,
      projectList: projectListStr,
      startTime: startTimestamp,
      endTime: endTimestamp,
      includeInstance,
    }),
  );

  if (withStats) {
    requests.push(
      fetchPost(getEndpoint('getprojectstatus'), {
        ...credentials,
        projectList: projectListStr,
      }).catch((err) => {
        console.error('[IF] Failed to get project stats', err);
        // Ignore the stats error, and return an empty mapping.
        return {};
      }),
    );
  }

  return Promise.all(requests).then((results) => {
    let idx = 0;
    const d = results[idx];
    idx = withStats ? idx + 1 : idx;
    const projectStats = withStats ? get(results[idx], 'data', {}) : {};

    // Normalize the project information
    const { userName } = credentials;
    const projectList = R.map((p) => {
      const { projectKey, projectName, customerName } = p;
      const {
        cloudType,
        dataType,
        insightAgentType,
        projectCreationTime: creationTime,
        samplingInterval,
        isHolistic,
        isContainer,
        projectDisplayName,
      } = p;

      // The project full name is used for the API calls. It includes the customerName if the current
      // user is not the owner of the project
      let newProjectName = projectName;
      if (customerName !== userName) {
        newProjectName = `${projectName}@${customerName}`;
      }

      const isStationary = [
        'Historical',
        'MetricFile',
        'containerReplay',
        'LogFile',
        'ContainerHistorical',
        'SplunkReplay',
      ].includes(insightAgentType);

      const groupList = R.filter(
        (group) => Boolean(group),
        R.sort((a, b) => (a || '').localeCompare(b), get(p, 'grouping', [])),
      );
      if (groupList.length === 0) {
        // add default grouping by `All`
        groupList.push('All');
      }
      const instanceStructureSet = get(p, 'instanceStructureSet', []);
      let instanceList = [];
      R.forEach((inc) => {
        const { i, c } = inc || {};
        if (i) {
          instanceList = [...instanceList, i];
          R.forEach((item) => {
            instanceList = [...instanceList, `${item}_${i}`];
          }, c || []);
        }
      }, instanceStructureSet || []);

      // Get project status from stats api result
      const projectStatus = get(projectStats, [projectKey, 'projectStatus']);
      const {
        e: earliestProcessTimestamp,
        lp: latestProcessTimestamp,
        lc: latestCollectTimestamp,
        lm: lastMissingDate,
        pr: processRate,
        ps: status,
      } = parseJSON(projectStatus) || {};

      return {
        projectDisplayName,
        // project meta info
        hasAllInfo: true,
        hasAllInstanceInfo: includeInstance,
        projectKey,
        projectName: newProjectName,
        cloudType,
        dataType,
        insightAgentType,
        owner: customerName,
        // project status
        status,
        earliestProcessTimestamp,
        latestProcessTimestamp,
        latestCollectTimestamp,
        lastMissingDate,
        processRate,
        isHolistic,

        // additional info
        isStationary,
        groupList,
        instanceList,
        creationTime,
        samplingInterval,
        samplingIntervalInSecond: samplingInterval * 60,
        predictionWindow: samplingInterval * 60 === 10 ? 5 / 60 : 4,
        isContainer,
        instanceStructureSet,
      };
    }, d.data || []);
    return projectList;
  });
};

export default getProjectInfo;
