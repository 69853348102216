/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Tabs } from 'antd';

import { Container } from '../../../../lib/fui/react';

import EndpointSettingNew from './EndpointSettingNew';
import EndpointSettingOld from './EndpointSettingOld';
import EndpointSettingPagerDuty from './EndpointSettingPagerDuty';
import EndpointSettingAWSCost from './EndpointSettingAWSCost';
import EndpointSettingCloudWatchMetric from './EndpointSettingCloudWatchMetric';
import EndpointSettingGoogleCloud from './EndpointSettingGoogleCloud';
import EndpointSettingKubernetes from './EndpointSettingKubernetes';
import EndpointSettingAzure from './EndpointSettingAzure';
import EndpointSettingNewRelic from './EndpointSettingNewRelic';
import EndpointSettingAzureCost from './EndpointSettingAzureCost';
import EndpointSettingDyntrace from './EndpointSettingDynatrace';
import EndpointSettingNagios from './EndpointSettingNagios';

type Props = {
  instanceType: String,
};

class EndpointSettingAllCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const cloudType = get(props, ['currentProject', 'cloudType']);
    const isMetric = get(props, ['currentProject', 'isMetric']);

    let activeKey = 'splunkExport';
    switch (cloudType) {
      case 'ServiceNow':
        activeKey = 'serviceNow';
        break;
      case 'DataDog':
        activeKey = 'dataDog';
        break;
      case 'PagerDuty':
        activeKey = 'pagerDuty';
        break;
      case 'AWSCost':
        activeKey = 'AWSCost';
        break;
      case 'CloudWatch':
        activeKey = isMetric ? 'cloudWatchMetric' : 'cloudWatchLog';
        break;
      case 'NewRelic':
        activeKey = 'newRelic';
        break;
      case 'GoogleCloud':
        activeKey = 'googleCloud';
        break;
      case 'GoogleBigQuery':
        activeKey = isMetric ? 'googleBigQueryMetric' : 'googleBigQueryLog';
        break;
      case 'GoogleCloudCost':
        activeKey = 'GoogleCloudCost';
        break;
      case 'GooglePubsub':
        activeKey = 'googlePubSub';
        break;
      case 'Kubernetes':
        activeKey = 'kubernetes';
        break;
      case 'AZURE':
        activeKey = 'azure';
        break;
      case 'AzureCost':
        activeKey = 'AzureCost';
        break;
      case 'Dynatrace':
        activeKey = 'Dynatrace';
        break;
      case 'Nagios':
        activeKey = 'Nagios';
        break;
      default:
        break;
    }

    this.state = {
      activeKey,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    const { instanceType, tabPosition } = this.props;
    const { activeKey } = this.state;
    const cloudType = get(this.props, ['currentProject', 'cloudType']);
    const isMetric = get(this.props, ['currentProject', 'isMetric']);

    return (
      <Container className="full-height full-width">
        <Tabs
          className="full-height ant-tabs-content-full-height tabs-content-padding-top"
          tabBarStyle={{ width: tabPosition === 'top' ? undefined : 250 }}
          tabPosition={tabPosition || 'left'}
          activeKey={activeKey}
          onChange={(activeKey) => this.setState({ activeKey })}
        >
          {cloudType === 'AzureCost' && (
            <Tabs.TabPane tab="AzureCost settings" key="AzureCost">
              <EndpointSettingAzureCost {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'ServiceNow' && (
            <Tabs.TabPane tab="ServiceNow settings" key="serviceNow">
              <EndpointSettingNew {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'DataDog' && (
            <Tabs.TabPane tab="DataDog settings" key="dataDog">
              <EndpointSettingNew {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'PagerDuty' && (
            <Tabs.TabPane tab="PagerDuty settings" key="pagerDuty">
              <EndpointSettingPagerDuty {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'AWSCost' && (
            <Tabs.TabPane tab="AWS Cost settings" key="AWSCost">
              <EndpointSettingAWSCost {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'CloudWatch' && isMetric && (
            <Tabs.TabPane tab="CloudWatch settings" key="cloudWatchMetric">
              <EndpointSettingCloudWatchMetric {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'CloudWatch' && !isMetric && (
            <Tabs.TabPane tab="CloudWatch settings" key="cloudWatchLog">
              <EndpointSettingNew {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'NewRelic' && (
            <Tabs.TabPane tab="NewRelic settings" key="newRelic">
              <EndpointSettingNewRelic {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'GoogleCloud' && (
            <Tabs.TabPane tab="GoogleCloud settings" key="googleCloud">
              <EndpointSettingGoogleCloud {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'GoogleBigQuery' && isMetric && (
            <Tabs.TabPane tab="GoogleBigQuery settings" key="googleBigQueryMetric">
              <EndpointSettingNew {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'GoogleBigQuery' && !isMetric && (
            <Tabs.TabPane tab="GoogleBigQuery settings" key="googleBigQueryLog">
              <EndpointSettingNew {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'GoogleCloudCost' && (
            <Tabs.TabPane tab="GoogleCloudCost settings" key="GoogleCloudCost">
              <EndpointSettingNew {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'GooglePubsub' && (
            <Tabs.TabPane tab="GooglePubSub settings" key="googlePubSub">
              <EndpointSettingNew {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'Kubernetes' && (
            <Tabs.TabPane tab="Kubernetes settings" key="kubernetes">
              <EndpointSettingKubernetes {...this.props} />
            </Tabs.TabPane>
          )}
          {cloudType === 'AZURE' && (
            <Tabs.TabPane tab="Azure settings" key="azure">
              <EndpointSettingAzure {...this.props} />
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Splunk export settings" key="splunkExport">
            <EndpointSettingOld {...this.props} tabKey="splunkExport" />
          </Tabs.TabPane>

          {cloudType === 'SnowFlake' && (
            <Tabs.TabPane tab="JDBC data source settings" key="SnowFlake">
              <EndpointSettingNew {...this.props} tabKey="SnowFlake" />
            </Tabs.TabPane>
          )}

          {instanceType === 'ElasticSearch' && (
            <Tabs.TabPane tab="ElasticSearch settings" key="ElasticSearch">
              <EndpointSettingOld {...this.props} tabKey="ElasticSearch" />
            </Tabs.TabPane>
          )}

          {instanceType === 'HBase' && (
            <Tabs.TabPane tab="HBase settings" key="HBase">
              <EndpointSettingOld {...this.props} tabKey="HBase" />
            </Tabs.TabPane>
          )}

          {instanceType === 'MapReduce' && (
            <Tabs.TabPane tab="MapReduce settings" key="MapReduce">
              <EndpointSettingOld {...this.props} tabKey="MapReduce" />
            </Tabs.TabPane>
          )}

          {cloudType === 'JDBC' && (
            <Tabs.TabPane tab="JDBC data source settings" key="JDBCDataSource">
              <EndpointSettingOld {...this.props} tabKey="JDBCDataSource" />
            </Tabs.TabPane>
          )}

          {cloudType === 'Dynatrace' && (
            <Tabs.TabPane tab="Dynatrace settings" key="Dynatrace">
              <EndpointSettingDyntrace {...this.props} />
            </Tabs.TabPane>
          )}

          {cloudType === 'Nagios' && (
            <Tabs.TabPane tab="Nagios" key="Nagios">
              <EndpointSettingNagios {...this.props} tabKey="Nagios" />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Container>
    );
  }
}

const EndpointSettingAll = injectIntl(EndpointSettingAllCore);
export default connect((state) => {
  const { instanceType } = state.settings.projectSettings;
  return { instanceType };
}, {})(EndpointSettingAll);
