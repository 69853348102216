/* @flow */
import qs from 'qs';
import momenttz from 'moment-timezone';
import fetchHandler from './fetchHandler';

/**
 * Use fetch to send put method.
 * The content type of the sending data is application/x-www-from-urlencode.
 * The response json format is:
 * {
 *   success: true|false
 *   data: object,  // Data object if success is true.
 *   error: string // Error messages if success is false.
 * }
 *
 * @param {string} url The url of the api
 * @param {object} data The data to put.
 * @param {boolean} isJsonResponse whether response is in json format.
 */
const fetchPutForm = (url: string, formData: Object, extraHeaders: Object = {}, isJsonResponse: Boolean = true) => {
  // parse time zone
  const zone = momenttz.tz(momenttz.tz.guess());
  const tzOffset = zone.utcOffset() * 60 * 1000;

  // Get X-CSRF-Token in localStorage
  const token = window.localStorage.getItem('X-CSRF-Token');

  // remove userName
  if (formData.get('userName')) {
    formData.delete('userName');
  }
  // set userName by UserName if it is necessary
  if (formData.has('UserName')) {
    formData.set('userName', formData.get('UserName'));
    formData.delete('UserName');
  }

  url = url.indexOf('?') >= 0 ? `${url}&tzOffset=${tzOffset}` : `${url}?tzOffset=${tzOffset}`;

  const option = {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      Environment: process.env.NODE_ENV,
      Accept: 'application/json',
      'X-CSRF-Token': token,
      ...extraHeaders,
    },
    body: formData,
  };

  return fetchHandler(fetch(url, option), isJsonResponse, option);
};

export default fetchPutForm;
