/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPostForm from './fetchPostForm';

const deleteProjectKeywordsSetting = (credentials: Credentials, params: Object) => {
  const { keywords, ...rest } = params;
  const formData = new FormData();
  formData.append('operation', 'delete');
  formData.append('keywords', JSON.stringify(keywords));
  R.forEachObjIndexed((value, key) => {
    if (key === 'type' && value === 'instanceNameLabels') {
      formData.append(key, 'instanceName');
    } else {
      formData.append(key, value);
    }
  }, rest || {});
  return fetchPostForm(getEndpoint('projectkeywords'), formData).then((d) => {
    return d;
  });
};

export default deleteProjectKeywordsSetting;
