/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2018
 * *****************************************************************************
 **/
import React from 'react';
import { Tooltip } from '../../lib/fui/react';

const withTooltip = (component, tooltip, maxWidth = 200) => {
  return (
    <Tooltip title={<div>{tooltip || component}</div>} placement="top">
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: 'inline-block',
          verticalAlign: 'middle',
          maxWidth,
        }}
      >
        {component}
      </div>
    </Tooltip>
  );
};

export default withTooltip;
