import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  AutoComplete,
  Button,
  Checkbox,
  Divider,
  Input,
  InputNumber,
  message,
  Popover,
  Select,
  Spin,
  Switch,
} from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { State } from '../../../../common/types';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Container, List, Tooltip } from '../../../../lib/fui/react';
import { updateLastActionInfo } from '../../../../common/app/actions';

import { settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../../common/metric/messages';
import { Regex } from '../../../../common/utils';

const cmp = (x, y) => x.label === y.label;

const cellMemoryMinimalMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
const cellMemoryMaximalMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
const cellProjectToNameSpacesCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

const memoryInitData = { pods: [], valueNum: 0 };
const mappingInitData = { keys: [], value: '' };

function KubernetesSettingCore({ activeSystemTab, systemId, ...props }: Object) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isLoading: false,
    isBtnLoading: false,
    enableHorizontalAutoscaling: false,
    enableVerticalAutoscaling: false,
    actionTriggerDampeningPeriod: 0,
    metricPredictionTimePeriod: 0,
    recommendDampeningPeriod: 0,
    memoryScalingUpGap: 0,
    memoryScalingDownGap: 0,
    enableEmailNotification: false,
    emailAddresses: '',
    ignoredDeployments: [],
    memoryDeployments: [],
    deployments: [],
    localDeployments: [],
    searchValue: '',
    requestMemoryMinimalValues: [],
    limitMemoryMaximalValues: [],
    projectNameOption: [],
    namespacesOption: [],
    projectToNamespaces: [],
  });
  const emailRef = useRef();
  const memoryMinimalRef = useRef();
  const memoryMaximalRef = useRef();
  const projectToNameSpacesRef = useRef();
  const { credentials, systemsMap, intl, updateLastActionInfo, userInfo, projects } = props;
  const { isLoading, isBtnLoading, enableVerticalAutoscaling, enableHorizontalAutoscaling } = state;
  const { metricPredictionTimePeriod, memoryScalingUpGap, memoryScalingDownGap, localDeployments } = state;
  const { actionTriggerDampeningPeriod, recommendDampeningPeriod, enableEmailNotification, emailAddresses } = state;
  const { ignoredDeployments, memoryDeployments, deployments, searchValue, requestMemoryMinimalValues } = state;
  const { limitMemoryMaximalValues, projectNameOption, namespacesOption, projectToNamespaces } = state;

  const getMemoryGroupValues = (memoryMinimalValues) => {
    const memoryValues = [];
    R.forEachObjIndexed((value, key) => memoryValues.push({ key, value }), JSON.parse(memoryMinimalValues || '{}'));
    const groupMemoryValue = R.groupBy((item) => item.value, memoryValues);
    const memoryValueList = [];
    R.forEachObjIndexed((values, key) => {
      const podList = [];
      R.forEach((item) => podList.push(item.key), values || []);
      memoryValueList.push({ pods: podList, valueNum: key });
    }, groupMemoryValue || {});
    return { memoryValueList, memoryValues };
  };

  const getProjectNameOption = (projectList) => {
    const projectOption = [];
    R.forEach((p) => {
      const findProject = R.find((project) => project.projectShortName === p, projects || []);
      projectOption.push({
        label: `${findProject?.projectDisplayName || p}${findProject?.owner ? `@${findProject?.owner}` : ''}`,
        value: p,
      });
    }, projectList || []);
    return projectOption;
  };

  const getNamespacesOption = (namespacesList) => {
    const namespaceOption = [];
    R.forEach((space) => {
      namespaceOption.push({ label: space, value: space });
    }, namespacesList || []);
    return namespaceOption;
  };

  const getProjectToNamespaces = (projectToNamespace) => {
    const mapping = [];
    R.forEachObjIndexed((value, key) => mapping.push({ key, value }), JSON.parse(projectToNamespace || '{}'));
    const groupMappingValue = R.groupBy((item) => item.value, mapping);
    const mapingValueList = [];
    R.forEachObjIndexed((values, key) => {
      const projectList = [];
      R.forEach((item) => projectList.push(item.key), values || []);
      mapingValueList.push({ keys: projectList, value: key });
    }, groupMappingValue || {});
    return mapingValueList;
  };

  const reloadData = () => {
    if (activeSystemTab !== 'autoScalingSetting' || !systemId) return;

    const systemInfo = systemsMap[systemId] || {};

    setState({ isLoading: true });
    updateLastActionInfo();
    const request = [
      fetchGet(getEndpoint('k8s-scaling-setting'), {
        ...credentials,
        customerName: systemInfo.owner,
        systemName: systemId,
        environmentName: systemInfo.environmentName,
      }),
    ];

    Promise.all(request)
      .then((res) => {
        const [data1] = res || [];
        const success = data1.success || data1.success === undefined;
        const msg = data1.message;

        if (success) {
          const localDeployments = R.map(
            (item) => ({ label: item, value: item }),
            R.sort((a, b) => a - b, R.uniq(data1.deployments || [])),
          );
          const { memoryValueList: minVals = [], memoryValues: minKeys = [] } = getMemoryGroupValues(
            data1.requestMemoryMinimalValues,
          );
          const { memoryValueList: maxVals = [], memoryValues: maxKeys = [] } = getMemoryGroupValues(
            data1.limitMemoryMaximalValues,
          );
          let newAddDeployments = R.map(
            (item) => ({ label: item.key, value: item.key }),
            R.uniqBy((item) => item.key, [...minKeys, ...maxKeys]),
          );
          newAddDeployments = R.differenceWith(cmp, newAddDeployments, localDeployments);
          newAddDeployments = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('label')))])(newAddDeployments);

          const ignoredDeployments = data1.ignoredDeployments ? R.split(',', data1.ignoredDeployments) : [];
          let newDeployments = R.map((item) => ({ label: item, value: item }), R.uniq(ignoredDeployments));
          newDeployments = R.differenceWith(cmp, newDeployments, localDeployments);
          newDeployments = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('label')))])(newDeployments);

          const newState1 = {
            enableHorizontalAutoscaling: data1.enableHorizontalAutoscaling,
            enableVerticalAutoscaling: data1.enableVerticalAutoscaling,
            actionTriggerDampeningPeriod: data1.actionTriggerDampeningPeriod,
            metricPredictionTimePeriod: data1.metricPredictionTimePeriod,
            recommendDampeningPeriod: data1.recommendDampeningPeriod,
            memoryScalingUpGap: data1.memoryScalingUpGap,
            memoryScalingDownGap: data1.memoryScalingDownGap,
            enableEmailNotification: data1.enableEmailNotification,
            emailAddresses: data1.emailAddresses,
            ignoredDeployments,
            localDeployments,
            memoryDeployments: [...newAddDeployments, ...localDeployments],
            deployments: [...newDeployments, ...localDeployments],
            requestMemoryMinimalValues: minVals,
            limitMemoryMaximalValues: maxVals,
            projectNameOption: getProjectNameOption(data1.projectNames),
            namespacesOption: getNamespacesOption(data1.namespaces),
            projectToNamespaces: getProjectToNamespaces(data1.projectNameToNamespace),
          };
          setState({ isLoading: false, ...newState1 });
          cellMemoryMinimalMeasureCache.clearAll();
          if (memoryMinimalRef.current) memoryMinimalRef.current.forceUpdateGrid();
          cellMemoryMaximalMeasureCache.clearAll();
          if (memoryMaximalRef.current) memoryMaximalRef.current.forceUpdateGrid();
          cellProjectToNameSpacesCache.clearAll();
          if (projectToNameSpacesRef.current) projectToNameSpacesRef.current.forceUpdateGrid();
        } else {
          message.error(msg);
          setState({ isLoading: false });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        setState({ isLoading: false });
      });
  };

  useEffect(() => {
    reloadData();
  }, [systemId]);

  const handleSave = () => {
    const systemInfo = systemsMap[systemId] || {};

    const memoryMinimalMap = {};
    R.forEach((item) => {
      const { pods, valueNum } = item || {};
      R.forEach((pod) => {
        memoryMinimalMap[pod] = valueNum;
      }, pods || []);
    }, requestMemoryMinimalValues || []);

    const memoryMaximalMap = {};
    R.forEach((item) => {
      const { pods, valueNum } = item || {};
      R.forEach((pod) => {
        memoryMaximalMap[pod] = valueNum;
      }, pods || []);
    }, limitMemoryMaximalValues || []);

    const projectNameToNamespace = {};
    R.forEach((item) => {
      const { keys, value } = item || {};
      R.forEach((p) => {
        projectNameToNamespace[p] = value;
      }, keys || []);
    }, projectToNamespaces || []);

    const settings = {
      envName: systemInfo.environmentName,
      userName: systemInfo.owner,
      systemName: systemId,
      enableHorizontalAutoscaling,
      enableVerticalAutoscaling,
      metricPredictionTimePeriod,
      actionTriggerDampeningPeriod,
      recommendDampeningPeriod,
      memoryScalingUpGap,
      memoryScalingDownGap,
      enableEmailNotification,
      emailAddresses,
      ignoredDeployments: ignoredDeployments.join(),
      requestMemoryMinimalValues: JSON.stringify(memoryMinimalMap),
      limitMemoryMaximalValues: JSON.stringify(memoryMaximalMap),
      projectNameToNamespace: JSON.stringify(projectNameToNamespace),
    };

    setState({ isBtnLoading: true });
    updateLastActionInfo();
    const request = [
      fetchPost(getEndpoint('k8s-scaling-setting'), {
        ...credentials,
        customerName: systemInfo.owner,
        settings: JSON.stringify(settings),
      }),
    ];

    Promise.all(request)
      .then((res) => {
        const [data1] = res || [];
        const success = data1.success || data1.success === undefined;
        const msg = data1.message;
        if (success) {
          setState({ isBtnLoading: false });
          reloadData();
        } else {
          message.error(msg);
          setState({ isBtnLoading: false });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        setState({ isBtnLoading: false });
      });
  };

  const deliveryEmail = (value) => {
    setState({ emailAddresses: `${value}${emailAddresses ? `,${emailAddresses}` : ''}` });
  };

  const removeChilrenEmail = (value) => {
    setState({ emailAddresses: value });
  };

  const getListOptionValues = (option) => {
    return R.map((item) => item.value, option);
  };

  const getFilterValue = ({ searchValue, options, type = 'label' }) => {
    return R.filter((item) => item[type].toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1, options);
  };

  const isCheckedAll = ({ searchValue, options, valueList }) => {
    const filterValue = getListOptionValues(getFilterValue({ searchValue, options }));
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  };

  const handleAdd = (stateKey, cellCache, listRef, initData) => {
    setState({ [stateKey]: [R.clone(initData), ...state[stateKey]] });
    cellCache.clearAll();
    if (listRef.current) listRef.current.forceUpdateGrid();
    if (listRef.current) listRef.current.scrollToRow(0);
    forceUpdate();
  };

  const handleRemove = (rowIndex, stateKey, cellCache, listRef) => {
    const newValues = R.addIndex(R.filter)((item, idx) => idx !== rowIndex, state[stateKey]);
    setState({ [stateKey]: newValues });
    setTimeout(() => {
      cellCache.clearAll();
      if (listRef.current) listRef.current.forceUpdateGrid();
      if (listRef.current) listRef.current.scrollToRow(rowIndex);
      forceUpdate();
    }, 10);
  };

  const renderMemoryItem = ({ key, index: rowIndex, style, parent }, stateKey, cellCache, listRef, minAndMaxErrors) => {
    const rowData = state[stateKey][rowIndex];
    if (!rowData) return null;
    const { pods, valueNum } = rowData;

    let valueError = false;
    R.forEach((pod) => {
      if (!valueError) valueError = R.includes(pod, minAndMaxErrors || []);
    }, pods);

    let newOption = R.uniqBy(
      (item) => item.label,
      [...R.map((pod) => ({ label: pod, value: pod }), pods), ...memoryDeployments],
    );
    newOption = R.differenceWith(cmp, [...newOption, ...memoryDeployments], localDeployments);
    newOption = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('label')))])(newOption);
    newOption = [...newOption, ...localDeployments];
    const allOptionContents = R.map((item) => item.value, newOption || []);
    const showSearchOption = searchValue && !R.includes(searchValue, allOptionContents);
    if (showSearchOption) newOption = [{ label: searchValue, value: searchValue }, ...newOption];

    return (
      <CellMeasurer key={key} cache={cellCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div
          className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          style={{ ...style, paddingTop: 4, paddingBottom: 4 }}
        >
          <div className="row-column" style={{ flex: 1 }}>
            <Select
              allowClear
              size="small"
              mode="multiple"
              style={{ width: '100%' }}
              autoClearSearchValue={false}
              optionFilterProp="children"
              optionLabelProp="title"
              className={`no-count-num ${pods.length === 0 ? 'jsonKeyNoneError' : ''}`}
              value={pods}
              onChange={(pods) => {
                rowData.pods = pods;
                cellCache.clearAll();
                if (listRef.current) listRef.current.forceUpdateGrid();
                forceUpdate();
              }}
              onDropdownVisibleChange={(open) => {
                let newAddOption = R.uniqBy(
                  (item) => item.label,
                  [...R.map((pod) => ({ label: pod, value: pod }), pods), ...memoryDeployments],
                );
                newAddOption = R.differenceWith(cmp, [...newAddOption, ...memoryDeployments], localDeployments);
                newAddOption = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('label')))])(newAddOption);
                newAddOption = [...newAddOption, ...localDeployments];
                setState({ memoryDeployments: newAddOption });
                if (!open) setState({ searchValue: '' });
              }}
              onSearch={(searchValue) => setState({ searchValue })}
              dropdownRender={(menu) => {
                return (
                  <div>
                    <div
                      className="flex-row"
                      style={{ padding: '5px 12px' }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={isCheckedAll({ searchValue, options: newOption, valueList: pods })}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const filterValue = getListOptionValues(getFilterValue({ searchValue, options: newOption }));
                          const diff = R.difference(pods, filterValue);
                          rowData.pods = checked ? [...diff, ...filterValue] : diff;
                          cellCache.clearAll();
                          if (listRef.current) listRef.current.forceUpdateGrid();
                          forceUpdate();
                        }}
                      />
                      <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                    </div>
                    <Divider style={{ margin: '4px 0' }} />
                    {menu}
                  </div>
                );
              }}
            >
              {R.map((item) => {
                return (
                  <Select.Option className="hide-icon" key={item.value} value={item.value} title={item.label}>
                    <Checkbox
                      checked={(pods || []).includes(item.value)}
                      onChange={(e) => null}
                      style={{ marginRight: 8 }}
                    />
                    {item.label}
                  </Select.Option>
                );
              }, newOption)}
            </Select>
          </div>
          <div className="row-column" style={{ width: 200 }}>
            <InputNumber
              min={0}
              precision={0}
              style={{ width: 200 }}
              value={valueNum}
              className={`${valueError ? 'inputIsNil' : ''}`}
              onChange={(value) => {
                rowData.valueNum = value || 0;
                cellCache.clearAll();
                if (listRef.current) listRef.current.forceUpdateGrid();
                forceUpdate();
              }}
            />
          </div>
          <div className="row-column" style={{ width: 80 }}>
            <Button
              size="small"
              className="button-color-grey"
              onClick={() => handleRemove(rowIndex, stateKey, cellCache, listRef)}
            >
              {intl.formatMessage(eventActionMessages.remove)}
            </Button>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  const memoryRender = (stateKey, cellCache, listRef, minAndMaxErrors) => {
    let valueNumTitle = settingsMessages.minimumRequestMemory;
    if (stateKey === 'limitMemoryMaximalValues') {
      valueNumTitle = settingsMessages.maximumLimitMemory;
    }
    return (
      <div className="flex-grow flex-min-height" style={{ marginTop: 10, height: 300 }}>
        <AutoSizer>
          {({ width, height }) => (
            <div className="event-list">
              <div className="event-list-header" style={{ height: 30, width }}>
                <div className="header-column" style={{ flex: 1 }}>
                  {intl.formatMessage(settingsMessages.pod)}
                </div>
                <div className="header-column" style={{ width: 200 }}>
                  {intl.formatMessage(valueNumTitle)}
                </div>
                <div className="header-column" style={{ width: 80 }} />
              </div>
              <List
                className="event-list-grid"
                ref={listRef}
                width={width}
                height={260}
                rowCount={state[stateKey].length}
                overscanRowCount={4}
                rowHeight={cellCache.rowHeight}
                rowRenderer={(props) => renderMemoryItem(props, stateKey, cellCache, listRef, minAndMaxErrors)}
              />
            </div>
          )}
        </AutoSizer>
      </div>
    );
  };

  const renderMappingItem = ({ key, index: rowIndex, style, parent }, stateKey, cellCache, listRef) => {
    const rowData = state[stateKey][rowIndex];
    if (!rowData) return null;
    const { keys, value } = rowData;

    return (
      <CellMeasurer key={key} cache={cellCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div
          className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          style={{ ...style, paddingTop: 4, paddingBottom: 4 }}
        >
          <div className="row-column" style={{ flex: 1 }}>
            <Select
              allowClear
              showSearch
              mode="multiple"
              className={`no-count-num ${keys.length === 0 ? 'jsonKeyNoneError' : ''}`}
              style={{ width: '100%' }}
              value={keys}
              optionFilterProp="children"
              optionLabelProp="title"
              autoClearSearchValue={false}
              onChange={(keys) => {
                rowData.keys = keys;
                cellCache.clearAll();
                if (listRef.current) listRef.current.forceUpdateGrid();
                forceUpdate();
              }}
              onDropdownVisibleChange={(open) => {
                if (!open) setState({ searchValue: '' });
              }}
              onSearch={(searchValue) => setState({ searchValue })}
              dropdownRender={(menu) => {
                return (
                  <div>
                    <div
                      className="flex-row"
                      style={{ padding: '5px 12px' }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={isCheckedAll({ searchValue, options: projectNameOption, valueList: keys })}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const filterValue = getListOptionValues(
                            getFilterValue({ searchValue, options: projectNameOption }),
                          );
                          const diff = R.difference(keys, filterValue);
                          rowData.keys = checked ? [...diff, ...filterValue] : diff;
                          cellCache.clearAll();
                          if (listRef.current) listRef.current.forceUpdateGrid();
                          forceUpdate();
                        }}
                      />
                      <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                    </div>
                    <Divider style={{ margin: '4px 0' }} />
                    {menu}
                  </div>
                );
              }}
            >
              {R.map((item) => {
                return (
                  <Select.Option className="hide-icon" key={item.value} value={item.value} title={item.label}>
                    <Checkbox
                      checked={(keys || []).includes(item.value)}
                      onChange={(e) => null}
                      style={{ marginRight: 8 }}
                    />
                    {item.label}
                  </Select.Option>
                );
              }, projectNameOption)}
            </Select>
          </div>
          <div className="row-column" style={{ width: 200 }}>
            <Select
              allowClear
              showSearch
              className={`no-count-num ${!value ? 'jsonKeyNoneError' : ''}`}
              style={{ width: '100%' }}
              value={value}
              optionFilterProp="children"
              autoClearSearchValue={false}
              onChange={(value) => {
                rowData.value = value;
                cellCache.clearAll();
                if (listRef.current) listRef.current.forceUpdateGrid();
                forceUpdate();
              }}
            >
              {R.map((item) => {
                return (
                  <Select.Option className="hide-icon" key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              }, namespacesOption)}
            </Select>
          </div>
          <div className="row-column" style={{ width: 80 }}>
            <Button
              size="small"
              className="button-color-grey"
              onClick={() => handleRemove(rowIndex, stateKey, cellCache, listRef)}
            >
              {intl.formatMessage(eventActionMessages.remove)}
            </Button>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  const mappingRender = (stateKey, cellCache, listRef) => {
    return (
      <div className="flex-grow flex-min-height" style={{ marginTop: 10, height: 300 }}>
        <AutoSizer>
          {({ width, height }) => (
            <div className="event-list">
              <div className="event-list-header" style={{ height: 30, width }}>
                <div className="header-column" style={{ flex: 1 }}>
                  {intl.formatMessage(eventMessages.projectName)}
                </div>
                <div className="header-column" style={{ width: 200 }}>
                  {intl.formatMessage(settingsMessages.namespaces)}
                </div>
                <div className="header-column" style={{ width: 80 }} />
              </div>
              <List
                className="event-list-grid"
                ref={listRef}
                width={width}
                height={260}
                rowCount={state[stateKey].length}
                overscanRowCount={4}
                rowHeight={cellCache.rowHeight}
                rowRenderer={(props) => renderMappingItem(props, stateKey, cellCache, listRef)}
              />
            </div>
          )}
        </AutoSizer>
      </div>
    );
  };

  const ignoredDeploymentsRender = () => {
    let newOption = R.uniqBy(
      (item) => item.label,
      [...R.map((deployment) => ({ label: deployment, value: deployment }), ignoredDeployments), ...deployments],
    );
    newOption = R.differenceWith(cmp, [...newOption, ...deployments], localDeployments);
    newOption = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('label')))])(newOption);
    newOption = [...newOption, ...localDeployments];
    const allOptionContents = R.map((item) => item.value, newOption || []);
    const showSearchOption = searchValue && !R.includes(searchValue, allOptionContents);
    if (showSearchOption) newOption = [{ label: searchValue, value: searchValue }, ...newOption];

    return (
      <Select
        allowClear
        showSearch
        mode="multiple"
        className="no-count-num"
        style={{ width: '80%' }}
        value={ignoredDeployments}
        optionFilterProp="children"
        optionLabelProp="title"
        autoClearSearchValue={false}
        onChange={(ignoredDeployments) => setState({ ignoredDeployments })}
        onDropdownVisibleChange={(open) => {
          let newAddOption = R.uniqBy(
            (item) => item.label,
            [...R.map((deployment) => ({ label: deployment, value: deployment }), ignoredDeployments), ...deployments],
          );
          newAddOption = R.differenceWith(cmp, [...newAddOption, ...deployments], localDeployments);
          newAddOption = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('label')))])(newAddOption);
          newAddOption = [...newAddOption, ...localDeployments];
          setState({ deployments: newAddOption });
          if (!open) setState({ searchValue: '' });
        }}
        onSearch={(searchValue) => setState({ searchValue })}
        dropdownRender={(menu) => {
          return (
            <div>
              <div className="flex-row" style={{ padding: '5px 12px' }} onMouseDown={(event) => event.preventDefault()}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={isCheckedAll({ searchValue, options: newOption, valueList: ignoredDeployments })}
                  onChange={(e) => {
                    const { checked } = e.target;
                    const filterValue = getListOptionValues(getFilterValue({ searchValue, options: newOption }));
                    const diff = R.difference(ignoredDeployments, filterValue);
                    setState({ ignoredDeployments: checked ? [...diff, ...filterValue] : diff });
                  }}
                />
                <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
              </div>
              <Divider style={{ margin: '4px 0' }} />
              {menu}
            </div>
          );
        }}
      >
        {R.map((item) => {
          return (
            <Select.Option className="hide-icon" key={item.value} value={item.value} title={item.label}>
              <Checkbox
                checked={ignoredDeployments.includes(item.value)}
                onChange={(e) => null}
                style={{ marginRight: 8 }}
              />
              {item.label}
            </Select.Option>
          );
        }, newOption)}
      </Select>
    );
  };

  const minAndMaxErrors = [];
  R.forEach((item) => {
    const { pods, valueNum } = item || {};
    if (pods.length > 0) {
      R.forEach((pod) => {
        const fintError = R.find(
          (maxItem) => R.includes(pod, maxItem.pods || []) && Number(maxItem.valueNum) <= Number(valueNum),
          limitMemoryMaximalValues || [],
        );
        if (fintError) minAndMaxErrors.push(pod);
      }, pods || []);
    }
  }, requestMemoryMinimalValues || []);

  const minPodsError = !!R.find((item) => item.pods.length === 0, requestMemoryMinimalValues || []);
  const maxPodsError = !!R.find((item) => item.pods.length === 0, limitMemoryMaximalValues || []);
  const mappingError = !!R.find((item) => item.keys.length === 0 || !item.value, projectToNamespaces || []);
  const isRequired = enableEmailNotification;

  const hasError =
    minPodsError ||
    maxPodsError ||
    mappingError ||
    minAndMaxErrors.length > 0 ||
    (isRequired &&
      (!emailAddresses ||
        !R.reduce(
          R.and,
          true,
          R.map((e) => Regex.email.test(R.trim(e)), emailAddresses.split(',')),
        )));

  return (
    <Container className={`full-height flex-min-width flex-min-height flex-col `}>
      <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height spin-overflow-y">
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="light-label font-14">{intl.formatMessage(settingsMessages.enableVerticalScaling)}:</div>
          <Switch
            size="small"
            checked={enableVerticalAutoscaling}
            onChange={(checked) => setState({ enableVerticalAutoscaling: checked })}
          />
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="light-label font-14">{intl.formatMessage(settingsMessages.enableHorizontalScaling)}:</div>
          <Switch
            size="small"
            checked={enableHorizontalAutoscaling}
            onChange={(checked) => setState({ enableHorizontalAutoscaling: checked })}
          />
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="light-label font-14">{intl.formatMessage(settingsMessages.predictionLookSheadWindow)}:</div>
          <InputNumber
            min={0}
            precision={0}
            style={{ width: 200 }}
            value={metricPredictionTimePeriod}
            onChange={(value) => setState({ metricPredictionTimePeriod: value || 0 })}
          />
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="light-label font-14">{intl.formatMessage(settingsMessages.scaleUpThreshold)}:</div>
          <InputNumber
            min={0}
            precision={0}
            style={{ width: 200 }}
            value={memoryScalingUpGap}
            onChange={(value) => setState({ memoryScalingUpGap: value || 0 })}
          />
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="light-label font-14">{intl.formatMessage(settingsMessages.scaleDownThreshold)}:</div>
          <InputNumber
            min={0}
            precision={0}
            style={{ width: 200 }}
            value={memoryScalingDownGap}
            onChange={(value) => setState({ memoryScalingDownGap: value || 0 })}
          />
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="light-label font-14">
            {intl.formatMessage(settingsMessages.actionTriggeringDampeningPeriod)}:
          </div>
          <InputNumber
            min={0}
            precision={0}
            style={{ width: 200 }}
            value={actionTriggerDampeningPeriod}
            onChange={(value) => setState({ actionTriggerDampeningPeriod: value || 0 })}
          />
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="light-label font-14">{intl.formatMessage(settingsMessages.notificationDampeningPeriod)}:</div>
          <InputNumber
            min={0}
            precision={0}
            style={{ width: 200 }}
            value={recommendDampeningPeriod}
            onChange={(value) => setState({ recommendDampeningPeriod: value || 0 })}
          />
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="light-label font-14" style={{ marginRight: 8 }}>
            {intl.formatMessage(settingsMessages.ignoredDeployments)}:
          </div>
          {ignoredDeploymentsRender()}
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24, width: 800 }}>
          <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
            <div className="light-label font-14" style={{ marginRight: 8 }}>
              {intl.formatMessage(settingsMessages.enableEmailNotification)}:
            </div>
            <Checkbox
              checked={enableEmailNotification}
              onChange={(e) => setState({ enableEmailNotification: e.target.checked })}
            />
          </div>
          <RenderEmailComponent
            ref={emailRef}
            intl={intl}
            credentials={credentials}
            alertEmail={emailAddresses}
            systemsMap={systemsMap}
            systemId={systemId}
            deliveryEmail={deliveryEmail}
            removeChilrenEmail={removeChilrenEmail}
          />
          {isRequired && !emailAddresses && (
            <div style={{ color: 'red' }}>{intl.formatMessage(appFieldsMessages.inputRequired)}</div>
          )}
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24, width: 800 }}>
          <div className="flex-row flex-center-align">
            <div className="light-label font-14">
              {`${intl.formatMessage(settingsMessages.minimumRequestMemory)}(MB)`}:
            </div>
            <Button
              size="small"
              style={{ marginLeft: 10 }}
              type="primary"
              onClick={() =>
                handleAdd('requestMemoryMinimalValues', cellMemoryMinimalMeasureCache, memoryMinimalRef, memoryInitData)
              }
            >
              {intl.formatMessage(appButtonsMessages.add)}
            </Button>
          </div>
          {memoryRender('requestMemoryMinimalValues', cellMemoryMinimalMeasureCache, memoryMinimalRef, minAndMaxErrors)}
          {minPodsError && <div style={{ color: 'red' }}>{intl.formatMessage(appFieldsMessages.inputRequired)}</div>}
          {minAndMaxErrors.length > 0 && <div style={{ color: 'red' }}>Minimum and Maximum conflict</div>}
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24, width: 800 }}>
          <div className="flex-row flex-center-align">
            <div className="light-label font-14">
              {`${intl.formatMessage(settingsMessages.maximumLimitMemory)}(MB)`}:
            </div>
            <Button
              size="small"
              style={{ marginLeft: 10 }}
              type="primary"
              onClick={() =>
                handleAdd('limitMemoryMaximalValues', cellMemoryMaximalMeasureCache, memoryMaximalRef, memoryInitData)
              }
            >
              {intl.formatMessage(appButtonsMessages.add)}
            </Button>
          </div>
          {memoryRender('limitMemoryMaximalValues', cellMemoryMaximalMeasureCache, memoryMaximalRef, minAndMaxErrors)}
          {maxPodsError && <div style={{ color: 'red' }}>{intl.formatMessage(appFieldsMessages.inputRequired)}</div>}
          {minAndMaxErrors.length > 0 && <div style={{ color: 'red' }}>Minimum and Maximum conflict</div>}
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24, width: 800 }}>
          <div className="flex-row flex-center-align">
            <div className="light-label font-14">
              {intl.formatMessage(settingsMessages.projectNameToNamespaceMapping)}:
            </div>
            <Button
              size="small"
              style={{ marginLeft: 10 }}
              type="primary"
              onClick={() =>
                handleAdd('projectToNamespaces', cellProjectToNameSpacesCache, projectToNameSpacesRef, mappingInitData)
              }
            >
              {intl.formatMessage(appButtonsMessages.add)}
            </Button>
          </div>
          {mappingRender('projectToNamespaces', cellProjectToNameSpacesCache, projectToNameSpacesRef)}
          {mappingError && <div style={{ color: 'red' }}>{intl.formatMessage(appFieldsMessages.inputRequired)}</div>}
        </div>

        <div style={{ position: 'fixed', bottom: 50, right: 50 }}>
          <Popover
            content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
            mouseEnterDelay={0.3}
            placement="left"
          >
            <Button
              size="small"
              type="primary"
              style={{ width: 120 }}
              loading={isBtnLoading}
              disabled={hasError || userInfo.isReadUser}
              onClick={handleSave}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Popover>
        </div>
      </Spin>
    </Container>
  );
}

const RenderEmailComponent = forwardRef(
  ({ intl, credentials, alertEmail, deliveryEmail, removeChilrenEmail, systemsMap, systemId }: Object, ref) => {
    const cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

    const [emailList, setEmailList] = useState([]);
    const [emailHeader, setEmailHeader] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const [emailSelectOpen, setEmailSelectOpen] = useState(false);
    const [hasError, setHasError] = useState(true);
    const emailListRef = useRef();

    useImperativeHandle(ref, () => ({
      setEmailHeader,
      setEmailContent,
    }));
    useEffect(() => {
      const systemInfo = systemsMap[systemId] || {};
      const localShareUserList = R.concat(
        [{ userName: systemInfo.owner }],
        R.map((user) => {
          return { userName: user };
        }, get(systemInfo, 'shareUserSet', [])),
      );
      setEmailHeader('');
      setEmailContent('');
      fetchGet(getEndpoint('customer', 2), {
        ...credentials,
      })
        .then((data) => {
          let setLocalShareUserList = [];
          const testObj = {};
          R.addIndex(R.map)((item, index) => {
            if (typeof item.userName === 'string' && item.userName) {
              setLocalShareUserList.push({ value: item.userName, content: '' });
              R.forEach((userItem) => {
                if (item.userName === userItem.userName) {
                  setLocalShareUserList[index].value = Regex.email.test(userItem.email)
                    ? userItem.email.split('@')[0]
                    : Regex.email.test(item.userName)
                    ? item.userName.split('@')[0]
                    : Regex.email.test(`${item.userName}@qq.com`)
                    ? item.userName
                    : '';
                  setLocalShareUserList[index].content = Regex.email.test(userItem.email)
                    ? userItem.email.match(/(?<=\@)[\S\s]*/)[0]
                    : Regex.email.test(item.userName)
                    ? `${item.userName.match(/@(\S*)\./)[1]}.com`
                    : '';
                }
              }, R.sortWith([R.ascend(R.prop('userName'))], data || []));
            }
          }, localShareUserList);
          setLocalShareUserList = setLocalShareUserList.reduce((preVal, curVal) => {
            // eslint-disable-next-line no-unused-expressions
            testObj[curVal.value] ? '' : (testObj[curVal.value] = curVal.value && preVal.push(curVal));
            return preVal;
          }, []);
          setEmailList(setLocalShareUserList);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    }, [systemId]);

    const onEmailHeaderSelect = (data) => {
      const emailContent = emailList.filter((item) => item.value === data)[0]?.content;
      setEmailHeader(data);
      setEmailContent(emailContent);
      setHasError(
        emailContent ? !(Regex.email.test(`${data}@${emailContent}`) && /.\../.test(R.trim(emailContent))) : true,
      );
    };

    const renderEmailListItem = ({ key, index: rowIndex, style, parent }) => {
      const removeEmail = (rowIndex) => {
        const newAlertEmail = alertEmail.split(',');
        newAlertEmail.splice(rowIndex, 1);
        removeChilrenEmail(newAlertEmail.join(','));
      };

      return (
        <CellMeasurer key={key} cache={cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
            <div className="row-column" style={{ flex: 1 }}>
              {alertEmail.split(',')[rowIndex]}
            </div>
            <div className="row-column" style={{ width: 80 }}>
              <Button size="small" className="button-color-grey" onClick={() => removeEmail(rowIndex)}>
                {intl.formatMessage(eventActionMessages.remove)}
              </Button>
            </div>
          </div>
        </CellMeasurer>
      );
    };

    const addEmail = () => {
      if (
        emailHeader &&
        emailContent &&
        Regex.email.test(`${emailHeader}@${emailContent}`) &&
        !alertEmail?.includes(`${emailHeader}@${emailContent}`)
      ) {
        deliveryEmail(`${emailHeader}@${emailContent}`);
        emailListRef.current.scrollToRow(0);
        setEmailHeader('');
        setEmailContent('');
        setHasError(true);
      }
    };

    const handleEmailSuffixChange = (e) => {
      const content = e.target.value;
      setEmailContent(content);
      setHasError(content ? !(Regex.email.test(`${emailHeader}@${content}`) && /.\../.test(R.trim(content))) : true);
    };

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Please enter the name set by the user of the mailbox" placement="top" trigger="focus">
            <AutoComplete
              value={emailHeader}
              style={{ width: 200 }}
              options={emailList}
              open={emailSelectOpen}
              onBlur={() => setEmailSelectOpen(false)}
              onFocus={() => setEmailSelectOpen(true)}
              onSelect={onEmailHeaderSelect}
              onChange={onEmailHeaderSelect}
              filterOption={(inputValue, option) => option.value.indexOf(emailHeader) !== -1}
            />
          </Tooltip>
          <span style={{ margin: '0 10px' }}>@</span>
          <Tooltip title="Please enter the email domain name" placement="top" trigger="focus">
            <Input value={emailContent} style={{ width: 150 }} onChange={handleEmailSuffixChange} />
          </Tooltip>
          <Button style={{ marginLeft: 10 }} type="primary" onClick={addEmail} disabled={hasError}>
            {intl.formatMessage(eventActionMessages.addEmail)}
          </Button>
        </div>

        <div className="flex-grow flex-min-height" style={{ marginTop: 10, height: 200 }}>
          <AutoSizer>
            {({ width, height }) => (
              <div className="event-list">
                <div className="event-list-header" style={{ height: 30, width }}>
                  <div className="header-column" style={{ flex: 1 }}>
                    {intl.formatMessage(eventActionMessages.email)}
                  </div>
                  <div className="header-column" style={{ width: 60 }} />
                </div>
                <List
                  className="event-list-grid"
                  ref={emailListRef}
                  width={width}
                  height={160}
                  rowCount={alertEmail ? alertEmail.split(',').length : 0}
                  overscanRowCount={4}
                  rowHeight={cellMeasureCache.rowHeight}
                  rowRenderer={(props) => renderEmailListItem(props)}
                />
              </div>
            )}
          </AutoSizer>
        </div>
      </>
    );
  },
);

const KubernetesSetting = injectIntl(KubernetesSettingCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, systemsMap, projects } = state.app;
    const { credentials, userInfo } = state.auth;
    return { location, loadStatus, systemsMap, credentials, userInfo, projects };
  },
  { updateLastActionInfo },
)(KubernetesSetting);
