import React, { useState, useEffect, useCallback } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { Form, Select, Spin, message, Alert, InputNumber } from 'antd';

import fetchGet from '../apis/fetchGet';
import fetchPost from '../apis/fetchPost';
import getEndpoint from '../apis/getEndpoint';
import { BackgroundCall } from '.';
import { Modal } from '../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../app/messages';
import { settingsMessages } from '../settings/messages';

// Setting component renderers
const SettingRenderers = {
  RenderRestoreLogRawDataModal: ({
    intl,
    credentials,
    projects,
    projectInstanceComponentMap,
    updateLastActionInfo,
    createSetAction,
    projectName,
    onClose,
  }: Object) => {
    const [isLoading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [projectOptions, setProjectOptions] = useState([]);
    const [componentOptions, setComponentOptions] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);

    const [componentName, setComponentName] = useState(null);
    const [targetProjectName, setTargetProjectName] = useState(null);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);

    const project = R.find((project) => project.projectName === projectName, projects || []) || {};
    const { owner } = project;

    useEffect(() => {
      let cancel = false;

      async function fetchData() {
        setLoading(true);
        updateLastActionInfo();
        let instanceNameList = [];
        if (project.hasAllInstanceInfo) {
          instanceNameList = get(project, ['instanceStructureSet'], []);
        } else {
          const result = await fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
            ...credentials,
            projectList: JSON.stringify([{ projectName: project.projectShortName, customerName: project.owner }]),
            startTime: moment.utc().startOf('day').subtract(1, 'days').valueOf(),
            endTime: moment.utc().endOf('day').valueOf(),
            includeInstance: true,
          });
          const projectMetaData = get(result, 'data', [])[0];
          instanceNameList = get(projectMetaData, ['instanceStructureSet'], []);
        }
        return instanceNameList;
      }
      fetchData()
        .then(async (instanceStructureSet) => {
          let instanceNameList = [];
          R.forEach((inc) => {
            const { i, c } = inc || {};
            if (i) {
              instanceNameList = [...instanceNameList, i];
              R.forEach((item) => {
                instanceNameList = [...instanceNameList, `${item}_${i}`];
              }, c || []);
            }
          }, instanceStructureSet || []);
          // get all components from instances
          const prevInstanceComponentMap = get(projectInstanceComponentMap, projectName, {});
          const { hasError, allInstanceComponentMap } = await BackgroundCall.GetSetComponent({
            updateLastActionInfo,
            createSetAction,
            credentials,
            prevInstanceComponentMap,
            projectName,
            instanceNameList,
          });

          const projectOptions = R.map(
            (item) => ({ value: item.projectShortName, label: item.projectShortName }),
            R.filter((item) => !item.isMetric && item.owner === owner, projects || []),
          );
          setProjectOptions(projectOptions);

          // build appNameList
          if (hasError) {
            throw new Error('Get components faild.');
          }
          const appNameList = !R.isEmpty(allInstanceComponentMap)
            ? { ...prevInstanceComponentMap, ...allInstanceComponentMap }
            : prevInstanceComponentMap;

          let components = R.values(appNameList);
          components = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(components));
          const componentOptions = R.map((item) => ({ value: item, label: item }), components);
          if (!cancel) {
            setComponentOptions(componentOptions);
            setLoading(false);
            setErrMsg();
          }
        })
        .catch((err) => {
          if (!cancel) {
            setLoading(false);
            setErrMsg(`${err.message || String(err)}`);
          }
        });

      return () => {
        cancel = true;
      };
    }, [projectName]);

    const handleSubmit = useCallback(
      ({ projectName, componentName, targetProjectName, targetUserName, startIndex, endIndex }) => {
        setSubmitting(true);
        updateLastActionInfo();
        fetchPost(
          getEndpoint('lograwdatafetchfromstorage'),
          {
            ...credentials,
            projectName,
            componentName,
            targetProjectName,
            targetUserName,
            startIndex,
            endIndex,
          },
          {},
          false,
        )
          .then((data) => {
            const { success, message: errMsg } = data || {};
            if (success === undefined || success) {
              message.success(intl.formatMessage(appMessages.apiSuccess));
              setSubmitting(false);
              onClose();
            } else {
              setErrMsg(errMsg);
              setSubmitting(false);
            }
          })
          .catch((err) => {
            setErrMsg(err.message || String(err));
            setSubmitting(false);
          });
      },
      [],
    );

    const hasError = !componentName || !targetProjectName || startIndex > endIndex;
    return (
      <Modal
        title={intl.formatMessage(settingsMessages.restoreLogRawData)}
        width={650}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={() =>
          handleSubmit({ projectName, componentName, targetProjectName, targetUserName: owner, startIndex, endIndex })
        }
        okButtonProps={{ disabled: hasError, loading: isSubmitting }}
      >
        <Spin spinning={isLoading}>
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign="left">
            {errMsg && (
              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Alert message={errMsg} type="error" showIcon />
              </Form.Item>
            )}

            <Form.Item
              label="Source Component Name"
              validateStatus={!componentName ? 'error' : 'success'}
              help={!componentName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                options={componentOptions}
                value={componentName}
                onChange={(value) => setComponentName(value)}
              />
            </Form.Item>
            <Form.Item
              label="Target Project Name"
              validateStatus={!targetProjectName ? 'error' : 'success'}
              help={!targetProjectName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                options={projectOptions}
                value={targetProjectName}
                onChange={(targetProjectName) => setTargetProjectName(targetProjectName)}
              />
            </Form.Item>
            <Form.Item label="Start Index" required>
              <InputNumber value={startIndex} onChange={(startIndex) => setStartIndex(startIndex)} />
            </Form.Item>
            <Form.Item label="End Index" required>
              <InputNumber value={endIndex} onChange={(endIndex) => setEndIndex(endIndex)} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  },
};

export { SettingRenderers };
