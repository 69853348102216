/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 ** */

export const ActionTypes = {
  LOAD_GLOBAL_HEALTH: 'LOAD_GLOBAL_HEALTH',
  SET_GLOBAL_HEALTH: 'SET_GLOBAL_HEALTH',
  LOAD_GLOBAL_CAPACITY_PLANNING: 'LOAD_GLOBAL_CAPACITY_PLANNING',
  SET_GLOBAL_CAPACITY_PLANNING: 'SET_GLOBAL_CAPACITY_PLANNING',
  LOAD_GLOBAL_SYSTEM_HEALTH: 'LOAD_GLOBAL_SYSTEM_HEALTH',
  SET_GLOBAL_SYSTEM_HEALTH: 'SET_GLOBAL_SYSTEM_HEALTH',
  LOAD_GLOBAL_SYSTEM_INSTANCE_HEALTH: 'LOAD_GLOBAL_SYSTEM_INSTANCE_HEALTH',
  SET_GLOBAL_SYSTEM_INSTANCE_HEALTH: 'SET_GLOBAL_SYSTEM_INSTANCE_HEALTH',
  LOAD_GLOBAL_SYSTEM_HEALTH_CHART: 'LOAD_GLOBAL_SYSTEM_HEALTH_CHART',
  SET_GLOBAL_SYSTEM_HEALTH_CHART: 'SET_GLOBAL_SYSTEM_HEALTH_CHART',
  LOAD_GLOBAL_SYSTEM_INSTANCES: 'LOAD_GLOBAL_SYSTEM_INSTANCES',
  SET_GLOBAL_SYSTEM_INSTANCES: 'SET_GLOBAL_SYSTEM_INSTANCES',
  LOAD_GLOBAL_TOP_ANOMALY_KEYWORDS: 'LOAD_GLOBAL_TOP_ANOMALY_KEYWORDS',
  SET_GLOBAL_TOP_ANOMALY_KEYWORDS: 'SET_GLOBAL_TOP_ANOMALY_KEYWORDS',
  LOAD_GLOBAL_SYSTEM_LEVEL_TIMELINES: 'LOAD_GLOBAL_SYSTEM_LEVEL_TIMELINES',
  SET_GLOBAL_SYSTEM_LEVEL_TIMELINES: 'SET_GLOBAL_SYSTEM_LEVEL_TIMELINES',
  LOAD_GLOBAL_SYSTEM_INCIDENT_TIMELINES: 'LOAD_GLOBAL_SYSTEM_INCIDENT_TIMELINES',
  SET_GLOBAL_SYSTEM_INCIDENT_TIMELINES: 'SET_GLOBAL_SYSTEM_INCIDENT_TIMELINES',
  LOAD_GLOBAL_SYSTEM_ANOMALY_TIMELINES: 'LOAD_GLOBAL_SYSTEM_ANOMALY_TIMELINES',
  LOAD_GLOBAL_PREDICTED_ANOMALY_TIMELINES: 'LOAD_GLOBAL_PREDICTED_ANOMALY_TIMELINES',
  SET_GLOBAL_SYSTEM_ANOMALY_TIMELINES: 'SET_GLOBAL_SYSTEM_ANOMALY_TIMELINES',
  SET_GLOBAL_PREDICTED_ANOMALY_TIMELINES: 'SET_GLOBAL_PREDICTED_ANOMALY_TIMELINES',
  LOAD_GLOBAL_SYSTEM_ALERT_TIMELINES: 'LOAD_GLOBAL_SYSTEM_ALERT_TIMELINES',
  SET_GLOBAL_SYSTEM_ALERT_TIMELINES: 'SET_GLOBAL_SYSTEM_ALERT_TIMELINES',
  LOAD_GLOBAL_SYSTEM_DETECT_INCIDENT_TIMELINES: 'LOAD_GLOBAL_SYSTEM_DETECT_INCIDENT_TIMELINES',
  SET_GLOBAL_SYSTEM_DETECT_INCIDENT_TIMELINES: 'SET_GLOBAL_SYSTEM_DETECT_INCIDENT_TIMELINES',
  SET_GLOBAL_SYSTEM_DETECT_INCIDENT_ALL_TIMELINES: 'SET_GLOBAL_SYSTEM_DETECT_INCIDENT_ALL_TIMELINES',
  LOAD_GLOBAL_SYSTEM_DEPLOY_TIMELINES: 'LOAD_GLOBAL_SYSTEM_DEPLOY_TIMELINES',
  SET_GLOBAL_SYSTEM_DEPLOY_TIMELINES: 'SET_GLOBAL_SYSTEM_DEPLOY_TIMELINES',
  LOAD_GLOBAL_SYSTEM_TRACE_TIMELINES: 'LOAD_GLOBAL_SYSTEM_TRACE_TIMELINES',
  SET_GLOBAL_SYSTEM_TRACE_TIMELINES: 'SET_GLOBAL_SYSTEM_TRACE_TIMELINES',

  LOAD_GLOBAL_SYSTEM_ZOOM_TIMELINES: 'LOAD_GLOBAL_SYSTEM_ZOOM_TIMELINES',
  SET_GLOBAL_SYSTEM_ZOOM_TIMELINES: 'SET_GLOBAL_SYSTEM_ZOOM_TIMELINES',
  LOAD_INCIDENT_PREDICTION_TIMELINES: 'LOAD_INCIDENT_PREDICTION_TIMELINES',
  LOAD_ROOT_CAUSE_TIMELINES: 'LOAD_ROOT_CAUSE_TIMELINES',
  SET_GLOBAL_TIMELINE_INFO: 'SET_GLOBAL_TIMELINE_INFO',
  LOAD_ALERT_CAUSE_TIMELINES: 'LOAD_ALERT_CAUSE_TIMELINES',
  SET_GLOBAL_SYSTEM_INFO: 'SET_GLOBAL_SYSTEM_INFO',
  LOAD_GLOBAL_KEYWORDS_EVENTS: 'LOAD_GLOBAL_KEYWORDS_EVENTS',
  SET_GLOBAL_KEYWORDS_EVENTS: 'SET_GLOBAL_KEYWORDS_EVENTS',
  LOAD_GLOBAL_SYSTEM_CAUSAL_GROUP: 'LOAD_GLOBAL_SYSTEM_CAUSAL_GROUP',
  SET_GLOBAL_SYSTEM_CAUSAL_GROUP: 'SET_GLOBAL_SYSTEM_CAUSAL_GROUP',
  LOAD_GLOBAL_SYSTEM_ALERT_STATISTICS: 'LOAD_GLOBAL_SYSTEM_ALERT_STATISTICS',
  SET_GLOBAL_SYSTEM_ALERT_STATISTICS: 'SET_GLOBAL_SYSTEM_ALERT_STATISTICS',
  LOAD_PATTERN_NAME_MAP: 'LOAD_PATTERN_NAME_MAP',
  SET_PATTERN_NAME_MAP: 'SET_PATTERN_NAME_MAP',
  LOAD_INSIGHTS_WIDGETS_CONFIG: 'LOAD_INSIGHTS_WIDGETS_CONFIG',
  SET_INSIGHTS_WIDGETS_CONFIG: 'SET_INSIGHTS_WIDGETS_CONFIG',
  LOAD_MATCHED_INCIDENT_PREDICTIONS: 'LOAD_MATCHED_INCIDENT_PREDICTIONS',
  SET_MATCHED_INCIDENT_PREDICTIONS: 'SET_MATCHED_INCIDENT_PREDICTIONS',
};
