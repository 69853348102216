/* @flow */
import React, { Element } from 'react';
import cx from 'classnames';

type Props = {
  children: Element<any>,
  className: string,
  responsive: boolean,
  noGutter: boolean,
  withGutter: boolean,
  toolbar: boolean,
  breadcrumb: boolean,
  fullHeight: boolean,
  screenCenter: boolean,
  exactCenter: boolean,
};

const Container = ({
  className,
  screenCenter,
  withGutter,
  responsive,
  noGutter,
  toolbar,
  breadcrumb,
  fullHeight,
  children,
  ...rest
}: Props) => {
  if (screenCenter) {
    const classes = cx(
      'fui screen-center',
      {
        'full-height': fullHeight,
      },
      'container',
      className,
    );

    return (
      <div className={classes}>
        <div {...rest}>{children}</div>
      </div>
    );
  }

  const classes = cx(
    'fui',
    {
      responsive,
      toolbar,
      breadcrumb,
      'with-gutter': withGutter,
      'no-gutter': noGutter,
      'full-height': fullHeight,
    },
    'container',
    className,
  );
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default Container;
