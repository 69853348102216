import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Form, Input, Select, message, Checkbox, Empty } from 'antd';
import * as CryptoJS from 'crypto-js';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { Modal } from '../../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  projects: Array<Object>,
  credentials: Object,
  userInfo: Object,
  updateLastActionInfo: Function,
};

class NewSystemModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { userList } = props;

    this.state = {
      isSubmitting: false,
      customerName: userList[0]?.userName || 'user',
      systemName: '',
      selectProjects: [],
    };

    this.userSystemNames = [];
    this.projectsListOptions = [];
  }

  componentDidMount() {
    const { userList } = this.props;
    this.parseData(this.props, { customerName: userList[0]?.userName || 'user' });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  parseData(props, { customerName }) {
    const { systemsMap, projects, userInfo } = props;
    const owner = userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : userInfo.userName;

    this.userSystemNames = R.map(
      (item) => item.systemName,
      R.filter((item) => item.owner === owner, R.values(systemsMap)),
    );
    this.projectsListOptions = R.filter((project) => !project.systemId && project.owner === owner, projects);
    this.forceUpdate();
  }

  @autobind
  handleSubmit() {
    const { intl, onClose, userInfo, credentials, projects } = this.props;
    const { systemName, selectProjects, customerName } = this.state;
    const sessionToken = userInfo?.sessionToken;

    this.setState({ isSubmitting: true });
    const owner = userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : userInfo.userName;
    const systemInfo = { customerName: owner, systemDisplayName: systemName };
    if (selectProjects && selectProjects.length > 0) {
      const localProjects = R.filter(
        (item) => selectProjects.includes(item.projectShortName) && item.owner === owner,
        projects,
      );
      systemInfo.projectNameSet = R.map(
        (item) => ({ userName: item.owner, projectName: item.projectShortName, dataType: item.dataType }),
        localProjects,
      );
    }
    this.props.updateLastActionInfo();
    const systemInfoStr = JSON.stringify([systemInfo]);
    const systemInfoHashed = CryptoJS.HmacMD5(systemInfoStr, sessionToken).toString();
    fetchPost(
      getEndpoint('systemframework', 2),
      {
        ...credentials,
        operation: 'addProjectToSystem',
        systemInfo: systemInfoStr,
        'systemInfo-hashed': systemInfoHashed,
      },
      {},
      false,
    )
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmitting: false });
        onClose(true);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  handleCustomerNameChange(customerName) {
    this.setState({ customerName, selectProjects: [] }, () => {
      this.parseData(this.props, { customerName });
    });
  }

  render() {
    const { intl, onClose, userList, userInfo } = this.props;
    const { isSubmitting, customerName, systemName, selectProjects } = this.state;

    const hasErrCustomerName = userInfo.isAdmin || userInfo.isLocalAdmin ? !customerName : !userInfo.userName;
    const hasErrSystemName = !systemName || this.userSystemNames.includes(systemName);
    return (
      <Modal
        title={intl.formatMessage(settingsMessages.addNewSystem)}
        width={650}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: hasErrCustomerName || hasErrSystemName, loading: isSubmitting }}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
            <Form.Item label={intl.formatMessage(appFieldsMessages.userName)} required>
              <Select
                showSearch
                optionFilterProp="value"
                filterOption
                value={customerName}
                onChange={this.handleCustomerNameChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.systemName)}
            validateStatus={hasErrSystemName ? 'error' : 'success'}
            help={
              this.userSystemNames.includes(systemName)
                ? 'The system name is already in use. Please pick a different system name.'
                : undefined
            }
            required
          >
            <Input value={systemName} onChange={(e) => this.setState({ systemName: e.target.value })} />
          </Form.Item>
          <Form.Item label={intl.formatMessage(settingsMessages.projectsNoSystem)}>
            <div className="flex-col overflow-y-auto" style={{ maxHeight: 130, margin: '12px 0' }}>
              {this.projectsListOptions.length === 0 && <Empty />}
              {this.projectsListOptions.length > 0 && (
                <Checkbox.Group
                  className="flex-col"
                  value={selectProjects}
                  onChange={(selectProjects) => this.setState({ selectProjects })}
                >
                  {R.addIndex(R.map)((item, idx) => {
                    return (
                      <Checkbox key={idx} style={{ marginLeft: 0 }} value={item.projectShortName}>
                        {item.projectName}
                      </Checkbox>
                    );
                  }, this.projectsListOptions)}
                </Checkbox.Group>
              )}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const NewSystemModal = injectIntl(NewSystemModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, systemsMap, projects } = state.app;
    const { credentials, userInfo } = state.auth;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return { location, loadStatus, systemsMap, userList, projects, credentials, userInfo };
  },
  {
    updateLastActionInfo,
  },
)(NewSystemModal);
