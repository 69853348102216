/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
import { createLogic } from 'redux-logic';
import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getMetricCapacityScore } from '../../apis';
import { appMessages } from '../../app/messages';

const loadMetricCapacityScoreRealTimeLogic = createLogic({
  type: [ActionTypes.LOAD_METRIC_REAL_CAPACITY],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,

  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getMetricCapacityScore(credentials, { ...params })
      .then((data) => {
        dispatch(createSetAction(ActionTypes.SET_METRIC_REAL_CAPACITY, params, data));
        dispatch(hideLoading);
      })
      .catch((err) => {
        // Not alert: console.error('[IF_API] Failed get capacity score', err);
        dispatch(
          errorLoading(appMessages.errorsAPIMessage, {
            message: err.message,
            error: err,
          }),
        );
      })
      .then(() => {
        done();
      });
  },
});

export default loadMetricCapacityScoreRealTimeLogic;
