/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 **/

const Options = {
  RelationTimeThresholdOptions: [
    { label: '5 minutes', value: '5.0' },
    { label: '15 minutes', value: '15.0' },
    { label: '30 minutes', value: '30.0' },
    { label: '1 hour', value: '60.0' },
    { label: '4 hour', value: '240.0' },
    { label: '6 hours', value: '360.0' },
    { label: '12 hours', value: '720.0' },
    { label: '24 hours', value: '1440.0' },
    { label: '36 hours', value: '2160.0' },
    { label: '2 days', value: '2880.0' },
    { label: '3 days', value: '4320.0' },
    { label: '4 days', value: '5760.0' },
    { label: '5 days', value: '7200.0' },
    { label: '6 days', value: '8640.0' },
    { label: '7 days', value: '10080.0' },
  ],
  RelationProbability: [
    { label: '0%', value: '0.0' },
    { label: '10%', value: '0.1' },
    { label: '20%', value: '0.2' },
    { label: '30%', value: '0.3' },
    { label: '40%', value: '0.4' },
    { label: '50%', value: '0.5' },
    { label: '60%', value: '0.6' },
    { label: '70%', value: '0.7' },
    { label: '80%', value: '0.8' },
    { label: '90%', value: '0.9' },
    { label: '100%', value: '1.0' },
  ],
  KpiPredictionProbability: [
    { label: '50%', value: '0.5' },
    { label: '75%', value: '0.75' },
    { label: '80%', value: '0.8' },
    { label: '90%', value: '0.9' },
    { label: '100%', value: '1.0' },
  ],
};

export default Options;
