/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import * as R from 'ramda';
import moment from 'moment';

import { State } from '../../../common/types';
import { Modal } from '../../../../artui/react';
import { AutoSizer, Table, Column, CellMeasurer, CellMeasurerCache } from '../../../lib/fui/react';

type Props = {
  incident: Object,
  onClose: Function,
};

class SyscallModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.timeRender = ({ cellData }) => (cellData ? moment.utc(cellData).format('YYYY-MM-DD HH:mm') : '');
    this.percentRender = ({ cellData }) => `${(cellData * 100).toFixed(1)}%`;

    this.contentRender = ({ key, parent, rowIndex, cellData, style }) => {
      const contents = R.map((p) => `${p.name}: ${p.TIDs.join(', ')}`, cellData || []).join('\n');
      return (
        <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={key} parent={parent} rowIndex={rowIndex}>
          <div className="white-pre" style={{ ...style }}>
            {contents}
          </div>
        </CellMeasurer>
      );
    };
  }
  @autobind
  handleSubmit() {
    this.props.onClose();
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  render() {
    const { incident } = this.props;
    const systemCallList = get(incident, 'systemCallList', []);
    const hasSyscall = systemCallList.length > 0;

    return (
      <Modal closable onClose={this.handleClose}>
        <div className="content" style={{ paddingBottom: 8, height: 680, width: '100%' }}>
          {!hasSyscall && (
            <div className="ui message info">
              To know the affected processes or threads of this event, install <b>syscall</b> agent on the instances.
              <br />
              For details, see: &nbsp;
              <a href="https://github.com/insightfinder/InsightAgent/tree/master/syscall">
                https://github.com/insightfinder/InsightAgent/tree/master/syscall
              </a>
            </div>
          )}
          {hasSyscall && (
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  deferredMeasurementCache={this.cellMeasureCache}
                  headerHeight={60}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={this.cellMeasureCache.rowHeight}
                  rowCount={systemCallList.length}
                  rowGetter={({ index }) => systemCallList[index]}
                >
                  <Column width={110} label="DateTime" dataKey="datetime" cellRenderer={this.timeRender} />
                  <Column
                    width={90}
                    label="Number of all threads"
                    dataKey="totalNumThread"
                    headerClassName="no-wrap text-right"
                    className="text-right"
                  />
                  <Column
                    width={100}
                    label="Percentage of affected threads"
                    dataKey="impactFactor"
                    headerClassName="no-wrap text-right"
                    className="text-right"
                    cellRenderer={this.percentRender}
                  />
                  <Column
                    width={378}
                    flexGrow={1}
                    label="Affected processes (TIDs)"
                    dataKey="procNames"
                    cellRenderer={this.contentRender}
                  />
                </Table>
              )}
            </AutoSizer>
          )}
        </div>
      </Modal>
    );
  }
}

const SyscallModal = injectIntl(SyscallModalCore);
export default connect((state: State) => {
  const { currentLoadingComponents } = state.app;
  return {
    currentLoadingComponents,
  };
}, {})(SyscallModal);
