/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { isNumber } from 'util';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';

const getLogHotColdEntryList = (credentials: Credentials, params: Object) => {
  const {
    projectName,
    instanceName,
    day,
    pageNo,
    pageSize,
    patternIds,
    startTimestamp,
    endTimestamp,
    isFetchAll,
    ...rest
  } = params;
  const dayObj = moment.utc(day, Defaults.DateFormat).startOf('day');
  const requests = [];
  requests.push(
    fetchGet(getEndpoint('logstreamingevent'), {
      ...credentials,
      ...rest,
      projectName,
      instanceName,
      isFetchAll: Boolean(isFetchAll),
      dayTimeMillis: dayObj.valueOf(),
      startTimestamp,
      endTimestamp,
      patternIds: JSON.stringify(patternIds),
      pageNo,
      pageSize,
    }),
  );

  return Promise.all(requests).then((results) => {
    const logEntryList = R.filter((e) => e.timestamp, get(results[0], ['eventArray'], []));
    const logEntryListTotal = get(results[0], ['totalMatched'], 0);
    const compressedSize = get(results[0], ['compressedSize']);
    const originSize = get(results[0], ['originSize']);
    let decreaseRatio;
    if (isNumber(compressedSize) && isNumber(originSize)) {
      decreaseRatio = (originSize - compressedSize) / originSize;
    }
    return { logEntryList, logEntryListTotal, originSize, compressedSize, decreaseRatio };
  });
};

export default getLogHotColdEntryList;
