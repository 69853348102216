/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import d3 from 'd3';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Pagination, Button } from 'antd';
import ReactFauxDOM from 'react-faux-dom';

import getEndpoint from '../../../common/apis/getEndpoint';
import fetchGet from '../../../common/apis/fetchGet';
import getActiveInstance from '../../../common/apis/getActiveInstance';
import { createLoadAction, updateLastActionInfo, ActionTypes as AppActionTypes } from '../../../common/app/actions';
import { chopString, parseLocation, buildUrl, buildLocation, parseQueryString } from '../../../common/utils';
import { Container } from '../../../lib/fui/react';
import { BaseUrls } from '../../app/Constants';

type Props = {
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  userName: String,
  credentials: Object,
  activeMap: Object,

  width: Number,
  height: Number,
  project: Object,
  queryParams: Object,
  queryResult: Object,
};

class MetricTreemapCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.objMap = {};
    this.isAnomalyMap = {};
    this.topKAnomalyMap = {};
    this.instanceColorMap = {};
    this.containerColorMap = {};
    this.instanceMetricColorMap = {};
    const { location, queryResult } = props;
    this.treemapData = {};
    const params = parseLocation(location);
    const { start, limit, parent } = params;
    this.activeMapLoader = 'query_active_map_loader';
    this.state = {
      queryResult,
      reload: false,
      parent,
      pageNo: Number(start) / Number(limit) + 1,
      pageSize: Number(limit),
      prevPageSize: 0,
      totalSize: 0,
      isLoading: false,
      selectedData: null,
    };

    this.resultLoader = 'query_result_loader';
    this.scaleRatio = 1.3;
    this.colorMap = {
      red: 'red',
      green: '#21BA47',
      gray: '#A1A1A1',
      blue: 'blue',
      orange: 'orange',
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.queryResult !== nextProps.queryResult ||
      this.props.height !== nextProps.height ||
      this.props.project !== nextProps.project ||
      this.props.activeMap !== nextProps.activeMap
    ) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { queryResult } = this.state;
    const { activeMap, location } = props;
    const params = parseLocation(location);
    const { projectName } = params;
    const newProjectName = projectName.split('@')[0];

    const totalSize = get(queryResult, 'totalSize', 0);
    this.setState({ totalSize, prevPageSize: totalSize });
    const treeMapNodeInfoMap = JSON.parse(get(queryResult, 'treeMapNodeInfoMap', '{}'));
    const objMap = {};
    const topKAnomalyMap = {};
    const instanceColorMap = this.instanceColorMap;
    const isAnomalyMap = {};

    R.forEachObjIndexed((val, key) => {
      objMap[val.id] = {};
      objMap[val.id].metricList = val.metricModelList;
      objMap[val.id].componentName = val.componentName;
      objMap[val.id].hasContainer = val.hasContainer;
      objMap[val.id].hasInfo = true;
      if (val.isAnomaly) {
        if (!instanceColorMap[val.id]) instanceColorMap[val.id] = this.colorMap.red;
        topKAnomalyMap[val.id] = val.metricModelList;
        isAnomalyMap[val.id] = true;
      } else {
        isAnomalyMap[val.id] = false;
      }
      if (val.metricModelList.length === 0 && !val.hasContainer) {
        objMap[val.id].hasInfo = false;
      }
    }, treeMapNodeInfoMap);
    this.objMap = objMap;
    this.topKAnomalyMap = topKAnomalyMap;
    this.instanceColorMap = instanceColorMap;
    this.isAnomalyMap = isAnomalyMap;

    this.setColorMap();

    if (activeMap && activeMap[newProjectName]) {
      this.convertData(props, newProjectName);
    }
  }

  @autobind
  setColorMap() {
    const instanceColorMap = this.instanceColorMap;
    const containerColorMap = this.containerColorMap;
    const instanceMetricColorMap = this.instanceMetricColorMap;
    const { selectedData } = this.state;
    const isAnomalyMap = this.isAnomalyMap;
    R.forEachObjIndexed((items, objId) => {
      const instanceId = selectedData ? selectedData.instanceName : objId;
      let containerId = null;
      const containerFlag = selectedData || this.objMap[instanceId].hasContainer;
      if (containerFlag) {
        containerId = objId;
      }

      if (!R.has(instanceId, instanceMetricColorMap)) {
        instanceMetricColorMap[instanceId] = {};
      }
      if (containerFlag) {
        if (!R.has(containerId, instanceMetricColorMap[instanceId])) {
          instanceMetricColorMap[instanceId][containerId] = {};
        }
      }

      if (items.length > 0) {
        let instanceColor = this.colorMap.green;
        let containerColor = this.colorMap.green;
        R.forEach((item) => {
          const { metricName, metricDirection, isAnomaly } = item;
          if (isAnomaly) {
            instanceColor = this.colorMap.red;
            containerColor = this.colorMap.red;
            if (metricDirection === 'lower') {
              instanceColor = this.colorMap.blue;
              containerColor = this.colorMap.blue;
            }
          }
          if (containerFlag) {
            if (isAnomaly) {
              // If Metrics and containers are at the same level
              instanceMetricColorMap[instanceId][metricName] = this.colorMap.red;
              if (metricDirection === 'lower') {
                instanceMetricColorMap[instanceId][metricName] = this.colorMap.blue;
              }
              instanceMetricColorMap[instanceId][containerId][metricName] = this.colorMap.red;
              if (metricDirection === 'lower') {
                instanceMetricColorMap[instanceId][containerId][metricName] = this.colorMap.blue;
              }
            }
          } else if (isAnomaly) {
            instanceMetricColorMap[instanceId][metricName] = this.colorMap.red;
            if (metricDirection === 'lower') {
              instanceMetricColorMap[instanceId][metricName] = this.colorMap.blue;
            }
          }
        }, items);
        instanceColorMap[instanceId] = instanceColor;
        if (containerFlag) {
          if (!R.has(instanceId, containerColorMap)) {
            containerColorMap[instanceId] = {};
          }
          containerColorMap[instanceId][containerId] = containerColor;
        }
      }
      if (isAnomalyMap[containerId]) {
        if (!containerColorMap[instanceId]) containerColorMap[instanceId] = {};
        containerColorMap[instanceId][containerId] = this.colorMap.red;
      }
    }, this.topKAnomalyMap);
    this.instanceColorMap = instanceColorMap;
    this.containerColorMap = containerColorMap;
    this.instanceMetricColorMap = instanceMetricColorMap;
  }

  @autobind
  fetchContainerInfo(data, changePage) {
    const { credentials, location, project, createLoadAction } = this.props;
    const { projectType, projectName } = project;
    const params = parseQueryString(location.search);
    const { t: templateId, instanceGroup, startTime, limit } = params;
    const endTime = moment
      .utc(Number(startTime))
      .clone()
      .endOf('day')
      .valueOf();
    const { instanceName } = data;
    const { pageNo, pageSize } = this.state;
    let hasMetric = false;
    const hasInfo = this.objMap[instanceName] ? this.objMap[instanceName].hasInfo : false;
    R.forEach((child) => {
      if (child.metricName) hasMetric = true;
    }, data.nodeChildren);
    if ((instanceName && !hasMetric && hasInfo) || changePage) {
      this.setState({ isLoading: true });
      this.props.updateLastActionInfo();
      fetchGet(getEndpoint(`query/${templateId}`), {
        ...credentials,
        projectName,
        instanceGroup,
        metricProjectName: projectName,
        start: changePage ? pageSize * (pageNo - 1) : 0,
        limit,
        projectType,
        parent: instanceName,
        startTime,
        endTime,
        topKFlag: true,
        isLog: false,
      }).then((data) => {
        const queryResult = data.data[0].result;
        const idList = [];
        const treeMapNodeInfoMap = JSON.parse(get(queryResult, 'treeMapNodeInfoMap', '{}'));
        R.forEachObjIndexed((val, key) => {
          idList.push(val.idInProjectMetadata);
        }, treeMapNodeInfoMap);
        const customerName = project.owner;
        return getActiveInstance(credentials, {
          customerName,
          startTime,
          endTime,
          projectNameList: JSON.stringify([
            {
              projectName: project.projectShortName,
              projectType: project.dataType,
              idList,
            },
          ]),
        }).then((data) => {
          if (!changePage) this.setState({ pageNo: 1 });
          createLoadAction(AppActionTypes.SET_ACTIVE_INSTANCE, data, this.activeMapLoader);
          this.setState({ queryResult }, () => {
            this.reloadData(this.props);
          });
        });
      });
    } else {
      this.setState({ totalSize: 0 });
    }
  }

  @autobind
  handleTileClick(data) {
    if (data) {
      const { type } = data || {};
      if (type !== 'metric') {
        this.fetchContainerInfo(data);
        this.renderTreemap(data);
        this.setState({ selectedData: data, parent: data.name });
      } else {
        const { metricName, instanceName, containerName } = data || {};
        const { location } = this.props;
        const params = parseLocation(location);
        const { projectName, instanceGroup, startTime } = params;
        const endTime = moment
          .utc(Number(startTime))
          .clone()
          .endOf('day')
          .valueOf();
        let modelType = 'Holistic';
        if (instanceGroup !== 'All') modelType = 'splitByEnv';
        const query = {
          projectName,
          instanceGroup,
          modelType,
          startTimestamp:
            startTime ||
            moment
              .utc()
              .startOf('day')
              .valueOf(),
          endTimestamp:
            endTime ||
            moment
              .utc()
              .endOf('day')
              .valueOf(),
          predictedFlag: false,
          justSelectMetric: metricName,
          justInstanceList: containerName ? `${containerName}_${instanceName}` : instanceName,
          withBaseline: true,
        };
        window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
      }
    }
  }

  @autobind
  renderTreemap(data) {
    data.x = 0;
    data.y = 0;
    data.dx = this.treemapWidth;
    data.dy = this.treemapHeight;
    data.depth = 0;

    const layout = (d) => {
      if (d.nodeChildren) {
        this.treemap.nodes({ nodeChildren: d.nodeChildren });
        d.nodeChildren.forEach((c) => {
          c.x = d.x + c.x * d.dx;
          c.y = d.y + c.y * d.dy;
          c.dx *= d.dx;
          c.dy *= d.dy;
          c.parent = d;
          layout(c);
        });
      }
    };
    // Set up the new layout for the data
    layout(data);

    const rect = (r) => {
      r
        .attr('x', (d) => this.scaleX(d.x))
        .attr('y', (d) => this.scaleY(d.y))
        .attr('width', (d) => this.scaleX(d.x + d.dx) - this.scaleX(d.x))
        .attr('height', (d) => this.scaleY(d.y + d.dy) - this.scaleY(d.y));
    };

    const faux = ReactFauxDOM.createElement('svg');
    const svg = d3
      .select(faux)
      .attr({ width: this.treemapWidth, height: this.treemapHeight })
      .append('g');
    const g1 = svg.datum(data).attr('class', 'depth');
    const g = g1
      .selectAll('g')
      .data(data.nodeChildren)
      .enter()
      .append('g');
    g
      // Remove filter and make metric clickable //.filter((d) => !isEmpty(d.nodeChildren))
      .classed('children', true)
      .on('click', this.handleTileClick);
    g
      .selectAll('.child')
      .data((d) => d.nodeChildren || [d])
      .enter()
      .append('rect')
      .attr('class', 'child')
      .call(rect);
    g
      .append('rect')
      .attr('class', (d) => `parent ${d.type}`)
      .call(rect)
      .append('title')
      .text((d) => `${d.name}\n${d.title || ''}`);
    g.selectAll('.parent').attr('fill', (d) => d.color || this.colorMap.green); // #00cd00
    g
      .append('text')
      .attr('dy', '.75em')
      .text((d) => (d.dx > 0 && d.dy > 0 ? chopString(d.name, 10) : ''))
      .call((t) => {
        t.attr('x', (d) => this.scaleX(d.x) + 6).attr('y', (d) => this.scaleY(d.y) + 6);
      });
    this.setState({ faux: faux.toReact() });
  }

  @autobind
  handleNavbarClick(data) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { prevPageSize } = this.state;
      const { location, push } = this.props;
      const params = parseQueryString(location.search);
      if (typeof data === 'string') {
        push(buildLocation(location.pathname, {}, { ...params, start: 0, parent: null }));
      } else if (data) {
        this.renderTreemap(data);
        this.setState({ selectedData: data, showMetricChart: false, totalSize: prevPageSize });
      }
    };
  }

  @autobind
  convertData(props, newProjectName) {
    const { parent } = this.state;
    let { selectedData } = this.state;
    const { activeMap, location } = props;
    const { startTime } = parseLocation(location);
    const endTime = moment
      .utc(Number(startTime))
      .clone()
      .endOf('day')
      .valueOf();
    const projectActiveInfo = activeMap[newProjectName];
    const { inactiveInstanceList, inactiveContainerList, activeContainerList, activeInstanceList } = projectActiveInfo;

    const instanceColorMap = this.instanceColorMap;
    const containerColorMap = this.containerColorMap;
    const instanceMetricColorMap = this.instanceMetricColorMap;

    this.treemapWidth = props.width;
    this.treemapHeight = props.height - 66 < 0 ? 100 : props.height - 66;

    // Set D3 treemap layout settings.
    this.treemap = d3.layout
      .treemap()
      .children((d, depth) => (depth ? null : d.nodeChildren))
      .sort((a, b) => b.name.localeCompare(a.name))
      .ratio(0.3 * (1 + Math.sqrt(5)))
      .round(false);

    this.scaleX = d3.scale
      .linear()
      .domain([0, this.treemapWidth])
      .range([0, this.treemapWidth]);
    this.scaleY = d3.scale
      .linear()
      .domain([0, this.treemapHeight])
      .range([0, this.treemapHeight]);
    const objMap = this.objMap;
    const treemapData = [];

    // Set inactive objects as orange
    R.forEach((objId) => {
      let instanceId = objId;
      if (selectedData) instanceId = selectedData.instanceName;
      if (!R.has(instanceId, instanceMetricColorMap)) instanceMetricColorMap[instanceId] = {};
      if (inactiveInstanceList && inactiveInstanceList.length > 0 && R.contains(instanceId, inactiveInstanceList)) {
        instanceColorMap[instanceId] = this.colorMap.gray;
        const metricList = selectedData ? objMap[objId].metricList : objMap[instanceId].metricList;
        R.forEach((metric) => {
          instanceMetricColorMap[instanceId][metric.metricName] = this.colorMap.gray;
        }, metricList);
      }
      if (inactiveContainerList && !R.isEmpty(inactiveContainerList)) {
        const inactiveContainerMap = inactiveContainerList[instanceId] || [];
        R.forEach((containerId) => {
          if (!R.has(instanceId, containerColorMap)) containerColorMap[instanceId] = {};
          containerColorMap[instanceId][containerId] = this.colorMap.gray;
          const metricList = objMap[containerId] ? objMap[containerId].metricList : [];
          R.forEach((metric) => {
            if (!R.has(containerId, instanceMetricColorMap[instanceId])) {
              instanceMetricColorMap[instanceId][containerId] = {};
            }
            instanceMetricColorMap[instanceId][containerId][metric.metricName] = this.colorMap.gray;
          }, metricList || []);
        }, inactiveContainerMap);
      }
    }, R.keys(objMap) || []);

    // Set new instances as orange
    if (activeInstanceList && activeInstanceList.length > 0) {
      R.forEach((activeInstance) => {
        const { firstSeenTimestamp, id } = activeInstance;
        const firstSeenDayTime = moment
          .utc(firstSeenTimestamp)
          .startOf('day')
          .valueOf();
        if (firstSeenDayTime >= Number(startTime) && firstSeenDayTime <= Number(endTime))
          instanceColorMap[id] = this.colorMap.orange;
      }, activeInstanceList);
    }
    if (activeContainerList && !R.isEmpty(activeContainerList)) {
      R.forEachObjIndexed((containerList, instanceId) => {
        R.forEach((activeContainer) => {
          const { firstSeenTimestamp, id: containerId } = activeContainer;
          const firstSeenDayTime = moment
            .utc(firstSeenTimestamp)
            .startOf('day')
            .valueOf();
          if (!R.has(instanceId, containerColorMap)) containerColorMap[instanceId] = {};
          if (firstSeenDayTime >= Number(startTime) && firstSeenDayTime <= Number(endTime))
            containerColorMap[instanceId][containerId] = this.colorMap.orange;
        }, containerList);
      }, activeContainerList);
    }

    this.instanceColorMap = instanceColorMap;
    this.containerColorMap = containerColorMap;
    this.instanceMetricColorMap = instanceMetricColorMap;

    R.forEach((name) => {
      let iname = name;
      if (selectedData) iname = selectedData.instanceName;
      const hasComponentName =
        (selectedData && selectedData.componentName) || (objMap[iname] && objMap[iname].componentName) || null;
      const instance = {
        name: selectedData ? selectedData.instanceName : objMap[iname].componentName || iname,
        type: 'instance',
        instanceName: iname,
        score: 0,
        value: 1,
        title: hasComponentName ? `Instance: ${iname}` : '',
        children: [],
        color: get(this.instanceColorMap, iname),
      };
      const containerFlag = selectedData || objMap[iname].hasContainer;
      // If has contiainer
      if (containerFlag) {
        // Metrics and containers are at the same level
        if (
          selectedData &&
          selectedData.children &&
          selectedData.children.length > 0 &&
          selectedData.children[0].nodeChildren &&
          selectedData.children[0].nodeChildren.length > 0
        ) {
          const hasMetric =
            selectedData.name === selectedData.children[0].name && selectedData.children[0].nodeChildren[0].metricName;
          if (hasMetric) {
            const metricList = [];
            R.forEach((node) => {
              metricList.push(node.name);
            }, selectedData.children[0].nodeChildren);
            const hostInstance = {
              name: iname,
              type: 'container',
              instanceName: iname,
              containerName: null,
              score: 0,
              value: 1,
              title: '',
              color: get(this.instanceColorMap, [iname]),
              children: R.map(
                (metric) => ({
                  name: metric,
                  instanceName: iname,
                  metricName: metric,
                  type: 'metric',
                  score: 0,
                  value: 1,
                  title: 'Click to linechart vew',
                  color: get(this.instanceMetricColorMap, [iname, metric]),
                }),
                metricList,
              ),
            };
            hostInstance.nodeChildren = hostInstance.children;
            instance.children.push(hostInstance);
          }
        }

        const containerList = selectedData ? R.keys(objMap) : [];
        R.forEach((cname) => {
          const metricList = objMap[cname].metricList;
          const container = {
            name: cname,
            type: 'container',
            instanceName: iname,
            containerName: cname,
            score: 0,
            value: 1,
            title: '',
            color: get(this.containerColorMap, [iname, cname]),
            children: R.map(
              (metric) => ({
                name: metric.metricName,
                instanceName: iname,
                containerName: cname,
                metricName: metric.metricName,
                type: 'metric',
                score: 0,
                value: 1,
                title: 'Click to linechart vew',
                color: get(this.instanceMetricColorMap, [iname, cname, metric.metricName]),
              }),
              metricList,
            ),
          };
          container.nodeChildren = container.children;
          instance.children.push(container);
        }, containerList);
      } else {
        const metricList = objMap[iname].metricList;
        if (metricList && metricList.length > 0) {
          instance.children = R.map(
            (metric) => ({
              name: metric.metricName,
              metricName: metric.metricName,
              instanceName: iname,
              type: 'metric',
              score: 0,
              value: 1,
              title: 'Click to linechart vew',
              color: get(this.instanceMetricColorMap, [iname, metric.metricName]),
            }),
            metricList,
          );
        }
      }
      instance.nodeChildren = instance.children;
      if ((selectedData && treemapData.length === 0) || !selectedData) treemapData.push(instance);
    }, R.keys(objMap));

    this.treemapData = { children: treemapData, nodeChildren: treemapData };
    if (selectedData) {
      selectedData = treemapData[0];
      this.renderTreemap(selectedData);
      this.setState({ isLoading: false });
    } else if (parent) {
      R.forEachObjIndexed((obj) => {
        if (obj.name === parent) selectedData = obj;
      }, treemapData);
      if (selectedData) {
        this.setState({ isLoading: true });
        this.setState({ selectedData });
        this.fetchContainerInfo(selectedData);
      } else {
        this.renderTreemap(this.treemapData);
      }
    } else {
      this.renderTreemap(this.treemapData);
      this.setState({ isLoading: false });
    }
  }

  @autobind
  handlePaginationChange(pageNo, pageSize) {
    const { location, push } = this.props;
    const { selectedData } = this.state;
    const parent = selectedData ? selectedData.instanceName : null;
    const params = parseQueryString(location.search);
    if (parent) {
      this.setState({ pageNo, pageSize }, () => {
        this.fetchContainerInfo(selectedData, true);
      });
    } else {
      push(
        buildLocation(
          location.pathname,
          {},
          { ...params, start: pageSize * (pageNo - 1), instanceStart: pageSize * (pageNo - 1) },
        ),
      );
    }
  }

  @autobind
  handlePageSizeChange(current, pageSize) {
    const { location, push } = this.props;
    const params = parseQueryString(location.search);
    push(buildLocation(location.pathname, {}, { ...params, start: 0, limit: pageSize }));
  }

  @autobind
  dateChange(type) {
    return (event) => {
      const { location, push } = this.props;
      const params = parseQueryString(location.search);
      const { startTime, instanceStart } = params;
      const { selectedData, pageSize, pageNo } = this.state;
      const parent = selectedData ? selectedData.instanceName : null;
      if (type === 'previous') {
        const newStartTime = moment
          .utc(Number(startTime))
          .subtract(1, 'days')
          .valueOf();
        push(
          buildLocation(
            location.pathname,
            {},
            { ...params, startTime: newStartTime, start: instanceStart || pageSize * (pageNo - 1), parent },
          ),
        );
      } else {
        const newStartTime = moment
          .utc(Number(startTime))
          .add(1, 'days')
          .valueOf();
        push(
          buildLocation(
            location.pathname,
            {},
            { ...params, startTime: newStartTime, start: instanceStart || pageSize * (pageNo - 1), parent },
          ),
        );
      }
    };
  }

  render() {
    const { width, height, location } = this.props;
    const { isLoading, selectedData, faux, pageNo, pageSize, totalSize } = this.state;
    const params = parseLocation(location);
    const { projectName, startTime } = params;

    const treemapData = selectedData || this.treemapData || {};
    const treemapDataType = treemapData.type;
    let instanceData;
    let containerData;
    let metricData;

    if (treemapDataType === 'instance') {
      instanceData = treemapData;
    } else if (treemapDataType === 'container') {
      instanceData = treemapData.parent;
      containerData = treemapData;
    } else if (treemapDataType === 'metric') {
      if (treemapData.parent.type === 'container') {
        containerData = treemapData.parent;
        instanceData = treemapData.parent.parent;
      } else {
        instanceData = treemapData.parent;
      }
      metricData = treemapData;
    }

    const noNextDay =
      startTime >=
      moment
        .utc()
        .startOf('day')
        .valueOf();

    return (
      <Container
        className={`content flex-col ${isLoading ? ' loading' : ''}`}
        style={{ paddingBottom: 8, paddingTop: 0, height, width }}
      >
        <div className="flex-row flex-center-align flex-center-justify">
          <Button.Group>
            <Button type="primary" onClick={this.dateChange('previous')}>
              <LeftOutlined />
              Previous Day
            </Button>
            <Button type="primary" onClick={this.dateChange('next')} disabled={noNextDay}>
              Next Day
              <RightOutlined />
            </Button>
          </Button.Group>
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
          <div className="flex-grow">
            <a href={null} onClick={this.handleNavbarClick(projectName)}>
              {projectName}
            </a>
            {Boolean(instanceData) && <span className="divider">/</span>}
            {Boolean(instanceData) &&
              (Boolean(containerData) || Boolean(metricData)) && (
                <a href={null} onClick={this.handleNavbarClick(instanceData)} title={instanceData.name}>
                  {instanceData.name}
                </a>
              )}
            {Boolean(instanceData) &&
              !containerData &&
              !metricData && <span title={instanceData.name}>{instanceData.name}</span>}
            {Boolean(containerData) && <span className="divider">/</span>}
            {Boolean(containerData) &&
              Boolean(metricData) && (
                <a href={null} onClick={this.handleNavbarClick(containerData)} title={containerData.name}>
                  {containerData.name}
                </a>
              )}
            {Boolean(containerData) && !metricData && <span title={containerData.name}>{containerData.name}</span>}
            {Boolean(metricData) && <span className="divider">/</span>}
            {Boolean(metricData) && <span>{metricData.name}</span>}
          </div>

          <div className="flex-row flex-center-align">
            <div style={{ marginLeft: 8 }}>
              <i className="square icon" style={{ color: this.colorMap.orange }} />
              <span>New Instance</span>
            </div>
            <div style={{ marginLeft: 8 }}>
              <i className="square icon" style={{ color: this.colorMap.gray }} />
              <span>Missing Data</span>
            </div>
            <div style={{ marginLeft: 8 }}>
              <i className="square icon" style={{ color: this.colorMap.red }} />
              <span>Hot Anomaly</span>
            </div>
            <div style={{ marginLeft: 8 }}>
              <i className="square icon" style={{ color: this.colorMap.blue }} />
              <span>Cold Anomaly</span>
            </div>
            <div style={{ marginLeft: 8 }}>
              <i className="square icon" style={{ color: this.colorMap.green }} />
              <span>Normal</span>
            </div>
          </div>

          <div className="flex-row flex-center-align">
            <div
              style={{
                marginLeft: 20,
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Page:
            </div>
            <Pagination
              size="small"
              current={pageNo}
              total={totalSize}
              pageSize={pageSize}
              onChange={this.handlePaginationChange}
              showSizeChanger
              onShowSizeChange={this.handlePageSizeChange}
            />
          </div>
        </div>
        <div className="flex-grow flex-min-height treemap">{faux}</div>
      </Container>
    );
  }
}

const MetricTreemap = injectIntl(MetricTreemapCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { location } = state.router;
    const { loadStatus, activeMap } = state.app;
    const { userName } = state.auth.userInfo;
    return {
      activeMap,
      location,
      loadStatus,
      credentials,
      userName,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(MetricTreemap);
