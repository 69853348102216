/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { get, isNumber } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';

import { parseLocation } from '../../../common/utils';
import { Container, Select, AutoSizer, Table, Column } from '../../../lib/fui/react';
import { DataGroupCharts } from '../../../../components/share/charts';
import appForecastMetricDataParser from '../../../common/apis/magicParsers/appForecastMetricDataParser';

import '../../metric/metric.scss';

type Props = {
  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,

  width: Number,
  height: Number,
  project: Object,
  queryParams: Object,
  queryResult: Object,
};

class MetricAppForecastCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.pickNotNil = R.pickBy((a) => !R.isNil(a));
    this.ifIn = (i, items) => items.indexOf(i) !== -1;

    this.dateFormat = 'YYYY-MM-DD';
    this.defaultInstanceGroup = 'All';
    this.defaultInterval = '24';
    this.defaultAppName = 'Aggregation';

    this.intervalOptions = [
      { label: '3 hours', value: '3' },
      { label: '12 hours', value: '12' },
      { label: '1 day', value: '24' },
      { label: '1 week', value: '168' },
    ];

    this.groupListLoaderName = 'metric_appforecast_grouplist_loader';

    this.dataApiParamNames = ['projectName', 'instanceGroup', 'startTime', 'interval'];

    this.percentRender = ({ cellData }) => (isNumber(cellData) ? `${cellData.toFixed(1)}%` : 'N/A');

    // handle data
    this.convertData(this.props, {});

    const appForecastData = this.appForecastData;
    const appInfoList = get(appForecastData, ['data', 'appInfoList'], []);

    const { location } = this.props;
    const params = parseLocation(location);
    const { metricName } = params || {};

    this.state = {
      chartDateWindow: undefined,
      selectedMetricList: metricName ? [{ label: metricName, value: metricName }] : [],
      appName: appInfoList ? appInfoList[0].name : undefined,
    };
  }

  componentDidMount() {
    this.convertData(this.props, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps, {});
    }
  }

  @autobind
  convertData(props, params = {}) {
    const { queryResult } = props;
    const rawData = queryResult.data;

    const appNames = rawData.appNames || [];
    const stats = get(rawData, ['appObj', 'stats'], {});
    const instanceInfo = rawData.instanceInfo || {};
    const defaultAppName = 'Aggregation';

    const convertor = (name) => {
      const instanceList = instanceInfo[name] || [];
      return {
        name,
        instanceList,
        instanceCount: instanceList.length,
        cpuMax: get(stats, [name, 'max']),
        cpuAvg: get(stats, [name, 'avg']),
      };
    };

    // Put special Aggregation at the top of the list and sort others by cpu avg
    let appInfoList = R.map((a) => convertor(a), R.filter((a) => a !== defaultAppName, appNames));
    appInfoList = R.sort((a, b) => b.cpuAvg - a.cpuAvg, appInfoList);

    const defaultAppInfo = convertor(defaultAppName);
    defaultAppInfo.instanceCount = R.reduce((acc, a) => acc + a.instanceCount, 0, appInfoList);
    appInfoList = [defaultAppInfo, ...appInfoList];

    const appForecastData = get(rawData, ['appObj', 'appForecastData'], {});

    const appMetricMap = {};
    R.forEachObjIndexed((val, key) => {
      appMetricMap[key] = appForecastMetricDataParser(val);
    }, appForecastData);

    this.appForecastData = {
      params: {},
      data: {
        appInfoList,
        appMetricMap,
        periodMap: get(rawData, ['periodMap'], {}),
        startTimestamp: get(rawData, ['appObj', 'startTimestamp']),
        endTimestamp: get(rawData, ['appObj', 'endTimestamp']),
        endDataTimestamp: get(rawData, ['appObj', 'endDataTimestamp']),
      },
    };
  }

  @autobind
  handleMetricListChanged(newValue) {
    this.setState({ selectedMetricList: newValue });
  }

  convertToChartData(appMetricMap, selectedMetricList) {
    const names = R.map((m) => m.value, selectedMetricList);
    const metricList = names.length === 0 ? R.omit(['timestamp'], appMetricMap) : R.pick(names, appMetricMap);

    return R.sort((a, b) => a.metrics.localeCompare(b.metrics), R.values(metricList));
  }

  @autobind
  handleDateWindowSync(dateWindow) {
    this.setState({ chartDateWindow: dateWindow });
  }

  render() {
    const { width, height, queryParams, queryResult, project } = this.props;

    const appForecastData = this.appForecastData;
    let { chartDateWindow } = this.state;
    const { selectedMetricList, appName } = this.state;

    const appInfoList = get(appForecastData, ['data', 'appInfoList'], []);
    const appMetricMap = get(appForecastData, ['data', 'appMetricMap', appName], {});
    const periodMap = get(appForecastData, ['data', 'periodMap'], {});

    const metricOptions = R.map(
      (m) => ({ label: m, value: m }),
      R.sort((a, b) => a.localeCompare(b), R.filter((n) => n !== 'timestamp', R.keys(appMetricMap))),
    );

    const chartsData = this.convertToChartData(appMetricMap, selectedMetricList);
    if (!chartDateWindow && chartsData.length > 0) {
      const sdata = chartsData[0].sdata;
      if (sdata && sdata[0]) {
        chartDateWindow = [sdata[0][0].valueOf(), sdata[sdata.length - 1][0].valueOf()];
      }
    }

    return (
      <Container fullHeight className="causal flex-col" style={{ height }}>
        <Container fullHeight className="white-bg flex-row">
          <Container className="overflow-y-auto" style={{ width: 300, padding: '8px 16px 8px 0px' }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowHeight={40}
                  rowCount={appInfoList.length}
                  rowGetter={({ index }) => appInfoList[index]}
                  onRowClick={({ rowData }) => {
                    this.setState({
                      appName: rowData.name,
                    });
                  }}
                  rowClassName={({ index }) => {
                    let className = index >= 0 && index % 2 === 1 ? 'odd-row' : '';
                    // Ignore header row.
                    if (index >= 0) {
                      const item = appInfoList[index];
                      if (item.name === appName) {
                        className += ' active';
                      }
                    }
                    return className;
                  }}
                >
                  <Column width={140} label="Component name" dataKey="name" flexGrow={1} />
                  <Column
                    width={80}
                    label="Inst count"
                    dataKey="instanceCount"
                    className="text-right"
                    headerClassName="text-right"
                  />
                </Table>
              )}
            </AutoSizer>
          </Container>
          <Container className="flex-grow flex-col" style={{ padding: '8px 0px' }}>
            <Container className="toolbar" style={{ zIndex: 'initial' }}>
              <div className="section" style={{ fontSize: 14 }}>
                <span className="label">Metric Filters:</span>
                <Select
                  name="appname"
                  multi
                  autosize
                  options={metricOptions}
                  style={{ minWidth: 200 }}
                  value={selectedMetricList}
                  onChange={this.handleMetricListChanged}
                />
              </div>
            </Container>
            {chartsData &&
              chartsData.length > 0 && (
                <DataGroupCharts
                  orderByMetric={false}
                  periodMap={periodMap}
                  chartType="line"
                  groups={chartsData}
                  view="list"
                  alertMissingData={false}
                  onDateWindowChange={this.handleDateWindowSync}
                  dateWindow={chartDateWindow}
                />
              )}
          </Container>
        </Container>
      </Container>
    );
  }
}

const MetricAppForecast = injectIntl(MetricAppForecastCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { projects } = state.app;

    return { location, loadStatus, projects };
  },
  { push, replace },
)(MetricAppForecast);
