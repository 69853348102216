import * as R from 'ramda';
import { get } from 'lodash';

import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults, parseJSON } from '../utils';
import type { Credentials } from '../types';

const getInsightsWidgetsConfig = (credentials: Credentials, params: Object) => {
  return fetchGet(getEndpoint('insightWidgets', 1), {
    ...credentials,
    ...params,
  }).then((data) => {
    let insightWidgets = parseJSON(get(data, 'insightWidgets')) || [];

    // filter widgets not in InsightWidgets
    insightWidgets = R.filter((key) => Defaults.InsightWidgets.includes(key), insightWidgets);

    // append other widgets in the end
    // if (insightWidgets.length === 0) insightWidgets = Defaults.InsightWidgets;
    const appendWidgets = R.difference(Defaults.InsightWidgets, insightWidgets);
    insightWidgets = [...insightWidgets, ...appendWidgets];

    return { insightWidgets };
  });
};
export default getInsightsWidgetsConfig;
