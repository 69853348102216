import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import ReactDOMServer from 'react-dom/server';
import { get } from 'lodash';
import { Checkbox, Col, Divider, Input, notification, Row, Spin, Tag } from 'antd';
import {
  BellOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DoubleLeftOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { appFieldsMessages, appMenusMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { Defaults, EventRenderers, getPatternNameIcon, LogRenderers, sleep } from '../../../common/utils';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import {
  ActionIcon,
  FixedIcon,
  FutureEventsIcon,
  InvalidPredictionsIcon,
  MatchedIncidentIcon,
  RestoreSvgPath,
  UnmatchedIncidentIcon,
} from '../../../lib/fui/icons';
import { AutoSizer, List, Popover, Tooltip } from '../../../lib/fui/react';
import { DashboardMessages } from '../../../common/dashboard/messages';

const chartBarColor = '#ff7875';
const chartBarActiveColor = '#d9363e';
const listHeaderHeight = 50;
const rowMinHeight = 60;
const dateHeight = 60;
const childrenBorder = { height: '100%' };

const generateTimeIntervals = (startTime, endTime) =>
  R.unfold((t) => (t >= endTime ? false : [t, t + 600000]), startTime);

const predictionStatusOption = [
  { label: 'Created', value: 'created', color: 'currentColor' },
  { label: 'Active', value: 'in progress', color: 'orange' },
  { label: 'Closed', value: 'closed', color: 'green' },
  { label: 'Auto resolved', value: 'Auto resolved', color: 'green' },
];

const handleMergeCategoryParentClick = ({ self, dateIdx, rootEventIdx, needHanderDateRoot, rowData }) => {
  const { groupEventList } = self.state;
  const { isExpand } = rowData;

  const newEventList = [...(groupEventList || [])];
  if (needHanderDateRoot) {
    newEventList[dateIdx].isExpand = !isExpand;
  }
  if (newEventList[dateIdx]?.events[rootEventIdx]) {
    newEventList[dateIdx].events[rootEventIdx].isExpand = !isExpand;
  }
  if (newEventList[dateIdx]?.events[rootEventIdx]?.isExpand) {
    self.fatherEvent = rowData;
  }

  self.setState({ groupEventList: newEventList }, () => {
    self.cellMeasureCache.clearAll();
    self.listNode.forceUpdateGrid();
    self.forceUpdate();
  });
};

const getChartTimeMap = (chartTimeMap, startTime, endTime) => {
  if (!chartTimeMap[`${startTime}-${endTime}`]) {
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    const allDayAnomalyList = generateTimeIntervals(startTimestamp, endTimestamp);
    const filterTimestamps = R.reverse(allDayAnomalyList);
    chartTimeMap[`${startTime}-${endTime}`] = { allDayAnomalyList, filterTimestamps };
  }
};

const anomalyTypeOptions = (intl) => {
  return [
    { value: 'all', label: intl.formatMessage(appFieldsMessages.all), count: 0 },
    {
      value: 'matchedIncident',
      label: intl.formatMessage(logMessages.matchedPredictions),
      count: 0,
      color: '#ff5142',
    },
    {
      value: 'unmatchedIncident',
      label: intl.formatMessage(logMessages.unmatchedPredictions),
      count: 0,
      color: 'gray',
    },
    { value: 'futurePredictions', label: intl.formatMessage(logMessages.futurePredictions), count: 0, color: 'gray' },
    { value: 'validPrediction', label: intl.formatMessage(logMessages.validPredictions), count: 0 },
    { value: 'invalidPrediction', label: intl.formatMessage(logMessages.invalidPrediction), count: 0, color: 'gray' },
    { value: 'fixedPrediction', label: intl.formatMessage(logMessages.fixedPrediction), count: 0, color: '#ff5142' },
    { value: 'actionTriggered', label: intl.formatMessage(logMessages.actionTriggered), count: 0, color: '#037AEF' },
    { value: 'notifications', label: intl.formatMessage(eventMessages.notificationsSent), count: 0, color: 'gray' },
  ];
};

const filterData = (events, self) => {
  const { componentFilter, typeFilter, sourceFilter, patternIdFilter, predictionStatusFilter } = self.state || {};
  const { instanceFilter, logicPodIDFilter, containerFilter } = self.state || {};
  let eventList = events || [];

  // anomaly events filter
  if (componentFilter && !R.includes('all', R.split(',', componentFilter))) {
    eventList = R.filter((event) => {
      return (
        R.difference(R.split(',', componentFilter), event.componentList).length !== R.split(',', componentFilter).length
      );
    }, eventList);
  }
  if (typeFilter && !R.includes('all', R.split(',', typeFilter))) {
    eventList = R.filter((event) => {
      return R.difference(R.split(',', typeFilter), event.filterTypeList).length !== R.split(',', typeFilter).length;
    }, eventList);
  }

  if (sourceFilter && !R.includes('_all', R.split(',', sourceFilter))) {
    eventList = R.filter((event) => {
      let found = false;
      R.forEach((p) => {
        const { type, metricType } = p?.sourceDetail || {};
        if (R.includes('_log', R.split(',', sourceFilter))) {
          if ((type?.toLowerCase() || '').startsWith('log')) {
            found = true;
          }
        }
        if (R.includes(metricType, R.split(',', sourceFilter))) {
          found = true;
        }
      }, event?.predictionSourceInfoList || []);
      return found;
    }, eventList);
  }

  if (patternIdFilter) {
    eventList = R.filter((event) => {
      return R.includes(`${String(event.patternId)}/${event.patternName}`, R.split(',', patternIdFilter));
    }, eventList);
  }
  if (predictionStatusFilter) {
    eventList = R.filter((event) => {
      return R.includes(event.status, R.split(',', predictionStatusFilter));
    }, eventList);
  }
  if (instanceFilter && !R.includes('all', R.split(',', instanceFilter))) {
    eventList = R.filter((event) => {
      const isContainer = R.includes('_', event.instanceName);
      if (isContainer) {
        const [, i] = R.split('_', event.instanceName);
        return R.includes(i, R.split(',', instanceFilter));
      } else {
        return R.includes(event.instanceName, R.split(',', instanceFilter));
      }
    }, eventList);
  }
  if (logicPodIDFilter && !R.includes('all', R.split(',', logicPodIDFilter))) {
    eventList = R.filter((event) => {
      const isContainer = R.includes('_', event.instanceName);
      if (isContainer) {
        const [, i] = R.split('_', event.instanceName);
        return R.includes(i, R.split(',', logicPodIDFilter));
      } else {
        return false;
      }
    }, eventList);
  }
  if (containerFilter && !R.includes('all', R.split(',', containerFilter))) {
    eventList = R.filter((event) => {
      const isContainer = R.includes('_', event.instanceName);
      if (isContainer) {
        const [c] = R.split('_', event.instanceName);
        return R.includes(c, R.split(',', containerFilter));
      } else {
        return false;
      }
    }, eventList);
  }

  return eventList;
};

const getJumpFlag = ({ event, query, isJWT, props }) => {
  let eventInstanceName;
  let eventPatternType;
  let eventRootCauseMetric;
  let eventPredictionTime;
  let eventTimestamp;
  let eventPatternId;
  let eventProjectName;

  if (isJWT) {
    eventInstanceName = props?.eventInstanceName;
    eventPatternType = props?.eventPatternType;
    eventRootCauseMetric = props?.eventRootCauseMetric;
    eventPredictionTime = props?.eventPredictionTime;
    eventTimestamp = props?.eventTimestamp;
    eventPatternId = props?.eventPatternId;
    eventProjectName = props?.eventProjectName;
  } else {
    eventInstanceName = query?.eventInstanceName;
    eventPatternType = query?.eventPatternType;
    eventRootCauseMetric = query?.eventRootCauseMetric;
    eventPredictionTime = query?.eventPredictionTime;
    eventTimestamp = query?.eventTimestamp;
    eventPatternId = query?.eventPatternId;
    eventProjectName = query?.eventProjectName;
  }

  return (
    event.patternId === Number(eventPatternId) &&
    event.projectName === eventProjectName &&
    (!eventInstanceName || (eventInstanceName && event.instanceList.includes(eventInstanceName))) &&
    (!eventPatternType || (eventPatternType && event.typeList.includes(eventPatternType))) &&
    (!eventRootCauseMetric || (eventRootCauseMetric && event.metricList.includes(eventRootCauseMetric))) &&
    (!eventPredictionTime || (eventPredictionTime && event.predictionTime === Number(eventPredictionTime))) &&
    (!eventTimestamp ||
      (event.startTimestamp <= Number(eventTimestamp) && event.endTimestamp >= Number(eventTimestamp)))
  );
};

const findJumpEvent = ({ groupEventList, query, isJWT, props }) => {
  let eventPredictionTime;
  let eventTimestamp;
  let eventPatternId;
  let eventProjectName;
  if (isJWT) {
    eventPredictionTime = props?.eventPredictionTime;
    eventTimestamp = props?.eventTimestamp;
    eventPatternId = props?.eventPatternId;
    eventProjectName = props?.eventProjectName;
  } else {
    eventPredictionTime = query?.eventPredictionTime;
    eventTimestamp = query?.eventTimestamp;
    eventPatternId = query?.eventPatternId;
    eventProjectName = query?.eventProjectName;
  }

  let jumpFatherIncident;
  let jumpIncident;
  let isShowPredictionSourceInfo;
  if (eventProjectName && eventPatternId && (eventTimestamp || eventPredictionTime)) {
    R.find((dataEvent) => {
      const fatherEvent = R.find((event) => {
        let childEvent;
        if (!childEvent) {
          childEvent = R.find((item) => getJumpFlag({ event: item, query, isJWT, props }), event?.events || []);
          if (childEvent) jumpIncident = childEvent;
        }
        return childEvent;
      }, dataEvent?.events || []);

      if (fatherEvent) jumpFatherIncident = fatherEvent;
      return fatherEvent;
    }, groupEventList || []);

    if (!jumpFatherIncident && !jumpIncident) {
      notification.warn({
        message: 'Notification',
        description: `The selected predicted incident ${eventPatternId} is no longer valid.`,
        duration: 0,
      });
    }

    if (jumpFatherIncident && jumpIncident) {
      isShowPredictionSourceInfo = true;
    }
  }

  return { jumpFatherIncident, jumpIncident, isShowPredictionSourceInfo };
};

const positionEvent = ({ self }) => {
  const { groupEventList = [], allExpand, dateAllExpand } = self.state;
  const { fatherEvent, childEvent, rowMinHeight, dateHeight } = self;

  if (!fatherEvent) {
    self.cellMeasureCache.clearAll();
    if (self.listNode) {
      self.listNode.forceUpdateGrid();
      self.listNode.scrollToPosition(0);
    }
    self.forceUpdate();
    return;
  }

  const dateEventIdx = R.findIndex(
    (event) => event.id === moment.utc(fatherEvent?.startTimestamp).format(Defaults.DateFormat),
    groupEventList,
  );

  if (dateEventIdx === -1) {
    self.cellMeasureCache.clearAll();
    if (self.listNode) {
      self.listNode.forceUpdateGrid();
      self.listNode.scrollToPosition(0);
    }
    self.forceUpdate();
    return;
  }

  const findFatherEventIdx = R.findIndex(
    (event) => event.expandKey === fatherEvent?.expandKey,
    groupEventList[dateEventIdx]?.events || [],
  );

  if (findFatherEventIdx === -1) {
    self.cellMeasureCache.clearAll();
    if (self.listNode) {
      self.listNode.forceUpdateGrid();
      self.listNode.scrollToPosition(0);
    }
    self.forceUpdate();
    return;
  }

  let positionTopNum = 0;
  const findChildEventIdx = R.findIndex(
    (event) => event.id === childEvent?.id,
    groupEventList[dateEventIdx]?.events[findFatherEventIdx]?.events || [],
  );

  if (allExpand) {
    const dateEvents = R.take(dateEventIdx, groupEventList);
    const mergerEvents = [];
    R.forEach((event) => {
      if (event.events && event.events.length > 0) {
        mergerEvents.push(...event.events);
        R.forEach((item) => {
          if (item.events && item.events.length > 0) mergerEvents.push(...item.events);
        }, event.events);
      }
    }, dateEvents || []);

    const fatherEvents = R.take(findFatherEventIdx, groupEventList[dateEventIdx]?.events || []);
    const childEvents = [];
    R.forEach((event) => {
      if (event.events && event.events.length > 0) childEvents.push(...event.events);
    }, fatherEvents || []);

    positionTopNum =
      (dateEventIdx + 1) * dateHeight +
      mergerEvents.length * rowMinHeight +
      findFatherEventIdx * rowMinHeight +
      childEvents.length * rowMinHeight;
  } else if (!allExpand && dateAllExpand) {
    const dateEvents = R.take(dateEventIdx, groupEventList);
    const mergerEvents = [];
    R.forEach((event) => {
      if (event.events && event.events.length > 0) mergerEvents.push(...event.events);
    }, dateEvents || []);
    handleMergeCategoryParentClick({
      self,
      dateIdx: dateEventIdx,
      rootEventIdx: findFatherEventIdx,
      rowData: groupEventList[dateEventIdx]?.events[findFatherEventIdx] || {},
    });
    positionTopNum =
      (dateEventIdx + 1) * dateHeight + mergerEvents.length * rowMinHeight + findFatherEventIdx * rowMinHeight;
  } else {
    handleMergeCategoryParentClick({
      self,
      dateIdx: dateEventIdx,
      rootEventIdx: findFatherEventIdx,
      rowData: groupEventList[dateEventIdx]?.events[findFatherEventIdx] || {},
      needHanderDateRoot: true,
    });
    positionTopNum = (dateEventIdx + 1) * dateHeight + findFatherEventIdx * rowMinHeight;
  }

  if (findChildEventIdx !== -1) positionTopNum += findChildEventIdx * rowMinHeight + rowMinHeight;

  self.setState({ incident: childEvent }, async () => {
    if (self.listNode) await self.listNode.scrollToPosition(positionTopNum + 5);
    if (self.listNode) self.listNode.scrollToPosition(positionTopNum - 5);
  });
};

const sortData = (eventList, sortBy, sortDirection) => {
  let sortList = eventList || [];

  // sort by
  let sortFunctions = [R.ascend(R.prop('predictionTime'))];
  if (sortBy && sortDirection && sortDirection !== 'NA') {
    sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
  }

  if (R.includes(sortBy, ['status'])) {
    sortList = R.map((event) => {
      return {
        ...event,
        events: R.map((item) => {
          return { ...item, events: R.sortWith(sortFunctions)(item?.events) };
        }, event?.events || []),
      };
    }, sortList || []);
    return sortList;
  }

  if (R.includes(sortBy, ['startTimestamp'])) {
    sortList = R.sortWith(sortFunctions)(
      R.map((event) => {
        return {
          ...event,
          events: R.sortWith(sortFunctions)(
            R.map((item) => {
              return { ...item, events: R.sortWith(sortFunctions)(item?.events) };
            }, event?.events || []),
          ),
        };
      }, sortList || []),
    );
    return sortList;
  }

  sortList = R.map((event) => {
    return {
      ...event,
      events: R.sortWith(sortFunctions)(
        R.map((item) => {
          return { ...item, events: R.sortWith(sortFunctions)(item?.events) };
        }, event?.events || []),
      ),
    };
  }, sortList || []);
  return sortList;
};

const getEventsByOccurrence = (self, events) => {
  const { sortBy, sortDirection, dateAllExpand, allExpand } = self.state;

  // sort events by pattern, predictionTime
  let sortEvents = events;
  sortEvents = R.sortWith([R.ascend(R.prop('predictionTime'))], sortEvents);

  const dateMap = {};
  R.forEach((event) => {
    const { startTimestamp } = event || {};
    const dateFormat = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
    if (!R.has(dateFormat, dateMap)) {
      dateMap[dateFormat] = {
        currentEvent: {
          id: moment.utc(startTimestamp).format(Defaults.DateFormat),
          dateFormat,
          isTimeRoot: true,
          isExpand: dateAllExpand,
          startTimestamp,
          dateEnFormat: moment.utc(startTimestamp).format(Defaults.ShortDayEnFormat),
        },
        timelines: [],
      };
    }
    dateMap[dateFormat].timelines.push(event);
  }, sortEvents);

  let dateMergerList = [];
  R.forEachObjIndexed((dateEvent) => {
    let count = 0;

    const categoryMap = {};
    R.forEach((event) => {
      count += 1;
      const { category, projectName, patternId, componentName, type, metricNameString } = event;
      const mergeKey =
        category === 'metric'
          ? `${projectName}-${componentName}-${patternId}-${type}-${metricNameString}`
          : `${projectName}-${componentName}-${patternId}-${type}`;
      if (!R.has(mergeKey, categoryMap)) categoryMap[mergeKey] = [];
      categoryMap[mergeKey].push({ ...event, mergeKey });
    }, dateEvent.timelines);

    const slpitCategoryList = [];
    R.forEachObjIndexed((val) => {
      let datas = val;
      datas = R.sortWith([R.ascend(R.prop('predictionTime'))], datas);

      let mergeTimestamp = 0;
      let occurrenceList = [];
      R.forEach((event) => {
        const { predictionTime } = event;
        if (!mergeTimestamp) mergeTimestamp = predictionTime;

        // srot with ascend
        if (predictionTime) {
          occurrenceList.push(event);
        } else {
          slpitCategoryList.push(occurrenceList);
          mergeTimestamp = predictionTime;
          occurrenceList = [event];
        }
      }, datas);
      if (occurrenceList.length > 0) {
        slpitCategoryList.push(occurrenceList);
      }
    }, categoryMap);

    // get event list by category
    let eventOccurrenceList = [];
    R.addIndex(R.forEach)((events, index) => {
      if (events.length > 0) {
        const occurrenceRangeTimes = [events[0].predictionTime, events[events.length - 1].predictionTime];
        const hasFuture = Boolean(R.find((item) => item.isFuture, events));
        const hasNotValidSrc = Boolean(R.find((item) => item.notValidSrc, events));
        const hasGenerateFromRule = Boolean(R.find((item) => item.generateFromRule, events));
        const hasNotified = Boolean(R.find((item) => item.isNotified, events));
        const hasIgnored = Boolean(R.find((item) => item.isIgnored, events));
        const hasConfirmed = Boolean(R.find((item) => item.isConfirmed, events));
        const hasImportant = Boolean(R.find((item) => item.isImportant, events));
        const hasTrigger = Boolean(R.find((item) => item.triggeredActionList.length > 0, events));
        const isMatchedIncident = Boolean(R.find((item) => item.isMatchedIncident, events));
        const eventsIds = R.map((event) => event.id, events);
        const hasIsFixedIncident = Boolean(R.find((item) => item.isFixedIncident, events));
        const isPrediction = Boolean(R.find((item) => item.isPrediction, events));

        let newEvents = R.sortWith(
          [
            R.ascend(R.prop('predictionTime')),
            R.descend(R.prop('rootCauseProbability')),
            R.ascend(R.prop('rootCauseCount')),
          ],
          events,
        );
        const { instanceName, patternId, incidentTimestamp, startTimestamp, patternName } = events[0];
        const expandKey =
          dateEvent.currentEvent.id + instanceName + patternId + incidentTimestamp + startTimestamp + index;

        // active = 0, resolved = 1. 按照字母给大小
        let status = 0;
        if (hasIsFixedIncident) {
          status = 1;
        }

        newEvents = R.map((item) => ({ ...item, expandKey }), newEvents);

        eventOccurrenceList.push({
          hasFuture,
          hasNotValidSrc,
          hasNotified,
          hasGenerateFromRule,
          hasIgnored,
          hasConfirmed,
          hasImportant,
          hasTrigger,
          isMatchedIncident,
          occurrenceRangeTimes,
          predictionTime: events[0].predictionTime,
          patternId: events[0].patternId,
          appName: events[0].appName,
          instanceAppNames: events[0].instanceAppNames,
          realInstanceName: events[0].realInstanceName,
          eventsIds,

          events: newEvents,
          checked: false,
          index,
          componentName: events[0].componentName,

          isParent: true,
          expandKey,
          projectName: events[0].projectName,
          projectOwner: events[0].projectOwner,
          instanceName,
          patternName,
          status,
          isPrediction,
          isExpand: allExpand,

          count: events.length,
          startTimestamp: events[0].startTimestamp,
        });
      }
    }, slpitCategoryList);

    // sort eventOccurrenceList
    eventOccurrenceList = R.sortWith([R.ascend(R.prop('patternId'))], eventOccurrenceList);

    const newCurrentEvent = { ...dateEvent.currentEvent, count };
    dateMergerList.push({ ...newCurrentEvent, events: eventOccurrenceList });
  }, dateMap);

  dateMergerList = sortData(dateMergerList, sortBy, sortDirection);

  return dateMergerList;
};

const setChartRef = (systemChartRef, systemId, chart) => {
  systemChartRef[systemId] = chart;
};

const getDataChartZoom = (self) => {
  const { zoomStartTime, zoomEndTime, events: eventList } = self.state;
  const events = R.filter((item) => {
    if (zoomStartTime && zoomEndTime) {
      return zoomStartTime <= item.predictionTime && item.predictionTime <= zoomEndTime;
    } else {
      return true;
    }
  }, eventList || []);
  const filterList = filterData(events, self);
  const groupEventList = getEventsByOccurrence(self, filterList);

  self.setState(
    {
      filterList,
      groupEventList,
      allExpand: true,
      dateAllExpand: true,
      incident: null,
      filterLoading: false,
    },
    () => {
      if (self.listNode) {
        self.cellMeasureCache.clearAll();
        self.listNode.forceUpdateGrid();
        self.listNode.scrollToPosition(0);
        self.forceUpdate();
      }
    },
  );
};

const onSystemChartClick = (self, systemId) => {
  return async (item) => {
    self.setState({ filterLoading: true });
    await sleep(300);
    const { chartOption } = self.state;
    const chartRef = self.systemChartRef[systemId];
    const { data, dataIndex } = item || {};
    const { timestamp } = data || {};
    let { predictionIncidentList = [] } = data || {};
    predictionIncidentList = R.sortWith([R.ascend(R.prop('predictionTime'))], predictionIncidentList || []);

    const newChartOption = { ...chartOption };
    newChartOption.series[0].itemStyle = {
      ...newChartOption.series[0].itemStyle,
      color: (params) => {
        return params.dataIndex === dataIndex ? chartBarActiveColor : chartBarColor;
      },
    };
    chartRef.getEchartsInstance().setOption(newChartOption);

    const groupEventList = getEventsByOccurrence(self, predictionIncidentList);

    self.setState(
      {
        filterList: predictionIncidentList,
        groupEventList,
        allExpand: true,
        dateAllExpand: true,
        incident: null,
        zoomStartTime: timestamp,
        zoomEndTime: timestamp + 10 * 60 * 1000 * 2,
        filterLoading: false,
      },
      () => {
        if (self.listNode) {
          self.cellMeasureCache.clearAll();
          self.listNode.forceUpdateGrid();
          self.listNode.scrollToPosition(0);
          self.forceUpdate();
        }
      },
    );
  };
};

const handleChartZoom = (self, systemId) => {
  return async (evt) => {
    const { chartOption } = self.state;
    let zoomStartTime;
    let zoomEndTime;

    const chartRef = self.systemChartRef[systemId];
    if (chartRef) {
      self.setState({ filterLoading: true });
      await sleep(300);
      const myChart = chartRef.getEchartsInstance();
      const { batch } = evt || {};
      if (batch && batch.length > 0) {
        const { startValue, endValue } = batch[0] || {};
        if (!R.isNil(startValue) && !R.isNil(endValue)) {
          const axis = myChart.getModel().option.xAxis[0];
          const rangeData = [];
          R.addIndex(R.forEach)((time, index) => {
            if (index >= startValue && index <= endValue) {
              rangeData.push(time);
            }
          }, axis.data);
          if (rangeData.length >= 2) {
            zoomStartTime = rangeData[0];
            zoomEndTime = rangeData[rangeData.length - 1] + 10 * 60 * 1000;
          } else {
            zoomStartTime = rangeData[0];
            zoomEndTime = zoomStartTime + 10 * 60 * 1000;
          }

          const newChartOption = { ...chartOption };
          newChartOption.series[0].itemStyle = {
            ...newChartOption.series[0].itemStyle,
            color: (params) => chartBarColor,
          };
          chartRef.getEchartsInstance().setOption(newChartOption);

          self.setState({ zoomStartTime, zoomEndTime }, () => {
            getDataChartZoom(self);
          });
        }
      } else {
        self.setState({ filterLoading: false });
      }
    }
  };
};

const handleChartRestore = (self, systemId) => {
  return () => {
    self.setState({ filterLoading: true }, async () => {
      await sleep(300);
      const { chartOption } = self.state;
      const chartRef = self.systemChartRef[systemId];
      if (chartRef) {
        const myChart = chartRef.getEchartsInstance();

        // active the dataZoom
        myChart.dispatchAction({
          type: 'takeGlobalCursor',
          key: 'dataZoomSelect',
          dataZoomSelectActive: true,
        });
        self.setState(
          {
            chartOption,
            isResetDateZoom: true,
            zoomStartTime: undefined,
            zoomEndTime: undefined,
            sortBy: null,
            sortDirection: null,
          },
          () => {
            getDataChartZoom(self);
          },
        );
      } else {
        self.setState({ filterLoading: false });
      }
    });
  };
};

const handleChartFinish = (self, systemId) => {
  return () => {
    const { isResetDateZoom } = self.state;
    if (isResetDateZoom) {
      self.setState({ isResetDateZoom: false }, () => {
        try {
          const chartRef = self.systemChartRef[systemId];
          if (chartRef) {
            const myChart = chartRef.getEchartsInstance();

            // active the dataZoom
            myChart.dispatchAction({
              type: 'takeGlobalCursor',
              key: 'dataZoomSelect',
              dataZoomSelectActive: true,
            });
          }
        } catch (err) {
          // console.error(err);
        }
      });
    }
  };
};

const customizeRestore = async (self, systemId) => {
  const { chartOption } = self.state;
  const chartRef = self.systemChartRef[systemId];
  if (chartRef) {
    self.setState({ filterLoading: true });
    await sleep(300);
    const myChart = chartRef.getEchartsInstance();

    const newChartOption = { ...chartOption };
    newChartOption.series[0].itemStyle = {
      ...newChartOption.series[0].itemStyle,
      color: (params) => chartBarColor,
    };
    chartRef.getEchartsInstance().setOption(newChartOption, true);

    // active the dataZoom
    myChart.dispatchAction({
      type: 'takeGlobalCursor',
      key: 'dataZoomSelect',
      dataZoomSelectActive: true,
    });

    self.setState(
      { isResetDateZoom: true, zoomStartTime: undefined, zoomEndTime: undefined, sortBy: null, sortDirection: null },
      () => {
        getDataChartZoom(self);
      },
    );
  }
};

const chartPatternRender = (rowData) => {
  const { patternName, patternId } = rowData;

  const isPrediction = rowData.isPrediction || rowData.timeLineType === 'future';

  const patternIdAndName = Defaults.PatternIdNameStr(
    {
      patternName,
      patternId,
    },
    {},
  ).patternNameStr;
  const patternStyle = { color: '#ffad66', fontSize: 20, marginRight: 4 };
  const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });
  return (
    <>
      {isPrediction && patternIcon}
      <div className="flex-grow hidden-line-with-ellipsis">
        <div className="hidden-line-with-ellipsis">{`[${patternId}]`}</div>
        <div className="hidden-line-with-ellipsis">{patternIdAndName}</div>
      </div>
    </>
  );
};

const chartImpactedRender = (rowData, instanceDisplayNameMap) => {
  const { instanceName, containerInfo, projectName, projectOwner } = rowData;

  const instanceListStr = containerInfo ? containerInfo.instanceName : instanceName;

  const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, instanceListStr, {
    pn: projectName,
    owner: projectOwner,
  });

  return (
    <>
      <div className="hidden-line-with-ellipsis">{containerInfo ? containerInfo.containerName : ''}</div>
      <div className="hidden-line-with-ellipsis">{instanceDisplayName || instanceListStr}</div>
    </>
  );
};

const chartListHeader = (self, peersEventList = []) => {
  const { intl } = self.props;
  return (
    <div
      className="event-list-header"
      style={{ height: 40, flexShrink: 0, paddingRight: peersEventList.length > 6 ? 8 : 0 }}
    >
      <div className="header-column" style={{ width: 150 }}>
        {intl.formatMessage(appFieldsMessages.patternIdName)}
      </div>
      <div className="header-column" style={{ width: 100, flex: 1 }}>
        {intl.formatMessage(eventMessages.impacted)}
      </div>
      <div className="header-column" style={{ width: 106 }}>
        {intl.formatMessage(eventMessages.predictionTime)}
      </div>
      <div className="header-column" style={{ width: 106, whiteSpace: 'normal' }}>
        {intl.formatMessage(eventMessages.predictedOccurrenceTime)}
      </div>
      <div className="header-column flex-center-justify" style={{ width: 80 }}>
        {`${intl.formatMessage(eventMessages.count)}`}
      </div>
    </div>
  );
};

const chartListItem = (rowData, idx, instanceDisplayNameMap) => {
  if (!rowData) return null;
  const { id, isParent, componentName, predictionTime, startTimestamp, count } = rowData;

  return (
    <div key={`${id}-${idx}`} style={{ height: 50 }} className="event-list">
      {isParent && (
        <div
          className="event-list-category-row flex-row flex-center-align"
          style={{ height: 50 - 1, backgroundColor: 'var(--item-active-bg2)' }}
        >
          <div className="row-column flex-row flex-center-align" style={{ padding: '0 6px', width: 150 }}>
            {chartPatternRender(rowData)}
          </div>
          <div className="row-column hidden-line-with-ellipsis" style={{ padding: '0 6px', width: 100, flex: 1 }}>
            {componentName}
          </div>
          <div className="row-column flex-row flex-center-align" style={{ padding: '0 6px', width: 106 }} />
          <div className="row-column flex-row flex-center-align" style={{ padding: '0 6px', width: 106 }} />
          <div
            className="row-column flex-row flex-center-align flex-center-justify"
            style={{ padding: '0 6px', width: 80 }}
          >
            {count}
          </div>
        </div>
      )}
      {!isParent && (
        <div
          className="incident-event-list-row flex-row flex-center-align"
          style={{ height: 50 - 1, borderBottom: '1px solid var(--virtualized-table-border-color)' }}
        >
          <div className="row-column" style={{ padding: '0 6px', width: 150 }} />
          <div className="row-column overflow-hidden" style={{ padding: '0 6px', width: 100, flex: 1 }}>
            {chartImpactedRender(rowData, instanceDisplayNameMap)}
          </div>
          <div className="row-column" style={{ padding: '0 6px', width: 106, whiteSpace: 'normal' }}>
            {moment.utc(predictionTime).format(Defaults.ShortDateTimeFormat)}
          </div>
          <div className="row-column" style={{ padding: '0 6px', width: 106, whiteSpace: 'normal' }}>
            {moment.utc(startTimestamp).format(Defaults.ShortDateTimeFormat)}
          </div>
          <div className="row-column flex-center-justify" style={{ padding: '0 6px', width: 80 }} />
        </div>
      )}
    </div>
  );
};

const getChartOption = (self, eventList, allDayAnomalyList, filterTimestamps, instanceDisplayNameMap) => {
  const { intl } = self.props;
  const anomalyScoresMap = {};
  R.forEach((timestamp) => {
    anomalyScoresMap[timestamp] = { timestamp, predictionIncidentList: [], value: 0 };
  }, allDayAnomalyList || []);

  R.forEach((item) => {
    const { predictionTime } = item;
    const incidentTimestamp = R.find((time) => time <= predictionTime, filterTimestamps);
    const anomalyScoresItem = anomalyScoresMap[incidentTimestamp];
    if (!anomalyScoresItem) return;
    anomalyScoresItem.predictionIncidentList.push({ ...item });
    anomalyScoresItem.value += 1;
  }, eventList || []);

  return {
    backgroundColor: 'transparent',
    animation: false,
    grid: { top: 10, left: 60, right: 30, bottom: 40 },
    tooltip: {
      backgroundColor: 'var(--component-background)',
      borderColor: 'transparent',
      trigger: 'axis',
      enterable: true,
      appendToBody: true,
      position: (pos, params, dom, rect, size) => {
        const isRight = size.viewSize[0] / 2 < pos[0];
        const boxWidth = size.contentSize[0];
        const pointX = pos[0] - (isRight ? boxWidth : 0);
        return [pointX, size.viewSize[1] - 20];
      },
      formatter: (params, ticket, callback) => {
        const { data } = params[0];
        const { timestamp, value, predictionIncidentList } = data || {};
        const eventList = R.sortWith([R.ascend(R.prop('predictionTime'))], predictionIncidentList || []);
        const mergedList = getEventsByOccurrence(self, eventList);
        let peersEventList = [];
        R.forEach((item) => {
          peersEventList = [...peersEventList, item, ...item.events];
        }, mergedList[0]?.events || []);
        return ReactDOMServer.renderToStaticMarkup(
          <div className="flex-col" style={{ width: 700, minHeight: 200, maxHeight: 400 }}>
            <div>{moment.utc(timestamp).format(Defaults.ShortTimeFormat)}</div>
            <div>{`${intl.formatMessage(appFieldsMessages.count)}: ${numeral(value).format('0,0')}`}</div>
            <div className="flex-grow flex-col event-list overflow-hidden">
              {chartListHeader(self, peersEventList)}
              <div
                className="flex-grow overflow-y-auto"
                style={{ border: '1px solid var(--virtualized-table-border-color)', borderTop: 'none' }}
              >
                {R.addIndex(R.map)((item, idx) => chartListItem(item, idx, instanceDisplayNameMap), peersEventList)}
              </div>
            </div>
          </div>,
        );
      },
      textStyle: {
        color: 'var(--text-color)',
      },
    },
    toolbox: {
      show: true,
      showTitle: true,
      itemGap: 10,
      left: 0,
      top: 30,
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
          icon: null,
          title: { zoom: 'Zoom', back: 'Back' },
          brushStyle: { color: 'gray', opacity: 0.4 },
        },
        restore: {
          title: '',
          icon: `path://${RestoreSvgPath}`,
          emphasis: {
            iconStyle: {
              textPosition: 'top',
              textAlign: 'left',
              textPadding: [0, 0, 10, 0],
            },
          },
        },
      },
    },
    xAxis: {
      type: 'category',
      data: allDayAnomalyList,
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        formatter: (value) =>
          `${moment.utc(Number(value)).format(Defaults.ShortDayFormat)}\n${moment
            .utc(Number(value))
            .format(Defaults.TimeOnlyFormat)}`,
        textStyle: { color: 'gray' },
      },
    },
    yAxis: {
      type: 'value',
      axisLine: { show: true },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: { textStyle: { color: 'gray' } },
    },
    series: [
      {
        name: 'Prediction',
        type: 'bar',
        data: R.values(anomalyScoresMap),
        itemStyle: {
          color: chartBarColor,
        },
      },
    ],
  };
};

const tidyEventList = (isJWT, self, anomalyEventList, instanceComponentMap, hasKubernetes) => {
  let eventList = R.sortWith([R.descend(R.prop('startTimestamp'))], anomalyEventList);
  const hasTableData = eventList.length > 0;

  // parse events for appName、filter instances、filter metrics
  let eventPatternIds = [];
  const eventPatternIdsNumMap = {};
  const typeCountMap = {};
  let allCount = 0;
  let eventComponents = [];
  const componentAnomalies = {};
  const instanceAnomalies = {};
  const podAnomalies = {};
  const containerAnomalies = {};
  const instanceNameList = [];
  const predictionSourceCategory = {};
  const predictionStatusCountMap = {};

  eventList = R.addIndex(R.map)((event, idx) => {
    const patternKey = `${event.patternId}/${event.patternName}`;
    eventPatternIds.push({
      patternId: String(event.patternId),
      instanceName: event.instanceName,
      patternName: event.patternName,
    });
    if (!eventPatternIdsNumMap[patternKey]) {
      eventPatternIdsNumMap[patternKey] = 1;
    } else {
      eventPatternIdsNumMap[patternKey] += 1;
    }

    const count = 1;
    allCount += count;

    let instanceAppNames = [];
    let componentList = [];
    let rootCauseDetailsArr = get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []);

    if (event.category === 'metric') {
      rootCauseDetailsArr = R.map((pair) => {
        const realInstance = pair.instanceId || pair.instanceName;
        let instanceName = realInstance;
        if (pair.containerName && !realInstance.includes('_')) {
          instanceName = `${pair.containerName}_${realInstance}`;
        }
        const componentName =
          get(instanceComponentMap, realInstance) ||
          get(instanceComponentMap, instanceName) ||
          event.componentName ||
          instanceName;
        componentList.push(componentName);
        const appName =
          componentName && componentName !== instanceName && componentName !== realInstance
            ? `${instanceName} (${componentName})`
            : instanceName;
        instanceAppNames.push(appName);

        return { ...pair, appName };
      }, rootCauseDetailsArr);

      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
    } else {
      const { realInstanceName } = event;
      const componentName =
        get(instanceComponentMap, realInstanceName) ||
        get(instanceComponentMap, event.instanceName) ||
        event.componentName ||
        event.instanceName;
      componentList.push(componentName);
      const appName =
        componentName && componentName !== event.instanceName && componentName !== event.realInstanceName
          ? `${event.instanceName} (${componentName})`
          : event.instanceName;
      instanceAppNames.push(appName);
    }

    componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
    instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
    const onlyInstanceString = get(event, ['containerInfo', 'instanceName'], event.realInstanceString);

    eventComponents = R.concat(eventComponents, componentList);
    instanceNameList.push({
      projectName: event.projectName,
      projectOwner: event.projectOwner,
      instanceName: event.instanceName,
    });

    R.forEach((componentName) => {
      if (!R.has(componentName, componentAnomalies)) componentAnomalies[componentName] = 0;
      componentAnomalies[componentName] += count;
    }, componentList);

    if (!R.has(event.instanceName, instanceAnomalies)) instanceAnomalies[event.instanceName] = 0;
    instanceAnomalies[event.instanceName] += count;

    const isContainer = R.includes('_', event.instanceName);
    if (isContainer) {
      const [c, p] = R.split('_', event.instanceName);
      if (!R.has(p, podAnomalies)) podAnomalies[p] = 0;
      podAnomalies[p] += count;
      if (!R.has(c, containerAnomalies)) containerAnomalies[c] = 0;
      containerAnomalies[c] += count;
    }

    const predictionSourceInfoList = event.predictionSourceInfoList || [];
    const predicitonSourcePatterns = R.map((source) => {
      const nid = get(source, ['sourceDetail', 'nid']);
      return nid;
    }, predictionSourceInfoList);
    let rootCauseProbability = R.reduce(
      (a, b) => a * b,
      1,
      R.map((item) => item.probability, predictionSourceInfoList),
    );
    let rootCauseCount = R.reduce(
      R.min,
      Infinity,
      R.map((item) => item.count, predictionSourceInfoList),
    );
    if (predictionSourceInfoList.length === 0) {
      rootCauseProbability = 1;
      rootCauseCount = 1;
    }

    const filterTypeList = [];
    if (event.notValidSrc) {
      filterTypeList.push('invalidPrediction');
      if (!R.has('invalidPrediction', typeCountMap)) typeCountMap.invalidPrediction = 0;
      typeCountMap.invalidPrediction += count;
    } else if (event.isMatchedIncident) {
      filterTypeList.push('matchedIncident');
      if (!R.has('matchedIncident', typeCountMap)) typeCountMap.matchedIncident = 0;
      typeCountMap.matchedIncident += count;
    } else if (event.isFuture) {
      filterTypeList.push('futurePredictions');
      if (!R.has('futurePredictions', typeCountMap)) typeCountMap.futurePredictions = 0;
      typeCountMap.futurePredictions += count;
    } else {
      filterTypeList.push('unmatchedIncident');
      if (!R.has('unmatchedIncident', typeCountMap)) typeCountMap.unmatchedIncident = 0;
      typeCountMap.unmatchedIncident += count;
    }
    if (event.isFixedIncident) {
      filterTypeList.push('fixedPrediction');
      if (!R.has('fixedPrediction', typeCountMap)) typeCountMap.fixedPrediction = 0;
      typeCountMap.fixedPrediction += count;
    }
    if ((event?.triggeredActionList || []).length > 0) {
      filterTypeList.push('actionTriggered');
      if (!R.has('actionTriggered', typeCountMap)) typeCountMap.actionTriggered = 0;
      typeCountMap.actionTriggered += count;
    }
    if (event.isNotified) {
      filterTypeList.push('notifications');
      if (!R.has('notifications', typeCountMap)) typeCountMap.notifications = 0;
      typeCountMap.notifications += count;
    }
    if (!event.notValidSrc) {
      filterTypeList.push('validPrediction');
      if (!R.has('validPrediction', typeCountMap)) typeCountMap.validPrediction = 0;
      typeCountMap.validPrediction += count;
    }

    R.forEach((p) => {
      const { type, metricType, patternName, nid } = p?.sourceDetail || {};
      // 赋值 patternName
      p.patternName = patternName || nid;

      if (type) {
        let label = metricType;
        let value = metricType;

        if (type.toLowerCase().startsWith('log')) {
          value = '_log';
          label = 'Log';
        }

        if (value && label) {
          if (!predictionSourceCategory[value]) {
            predictionSourceCategory[value] = { label, count: 0, value };
          }
          predictionSourceCategory[value].count += 1;
        }
      }
    }, event?.predictionSourceInfoList || []);

    let status = 'created';
    if (event.isFixedIncident) {
      status = 'Auto resolved';
    } else if (event.incidentStatus === 'in progress') {
      status = 'in progress';
    } else if (event.incidentStatus === 'closed') {
      status = 'closed';
    }
    if (!predictionStatusCountMap[status]) {
      predictionStatusCountMap[status] = 1;
    } else {
      predictionStatusCountMap[status] += 1;
    }

    return {
      ...event,
      rootCauseJson: {
        rootCauseDetailsArr,
      },
      predicitonSourcePatterns,
      rootCauseProbability,
      rootCauseCount,
      appName: R.join(', ', instanceAppNames),
      instanceAppNames,
      componentListStr: R.join(', ', componentList),
      componentList,
      instanceListStr: event.instanceNameString,
      onlyInstanceString,
      filterTypeList,
      status,
      leadTime: event.startTimestamp - event.predictionTime,
    };
  }, eventList);

  // modify type filter options
  const anomalyTypeOptions = R.map((item) => {
    const count = get(typeCountMap, item.value, 0);
    return { ...item, count };
  }, self.anomalyTypeOptions);
  const option = R.find((item) => item.value === 'all', anomalyTypeOptions);
  if (option) option.count = allCount;

  let instanceOption = [];
  let podOptions = [];
  let containerOption = [];
  R.forEach(
    (ins) => {
      const { instanceName: insKey, ...rest } = ins || {};
      const isContainer = R.includes('_', insKey);
      if (isContainer) {
        const [c, i] = R.split('_', insKey);
        instanceOption.push({ ...rest, value: i, label: i });
        podOptions.push({ ...rest, value: i, label: i });
        containerOption.push({ value: c, label: c });
      } else {
        instanceOption.push({ ...rest, value: insKey, label: insKey });
      }
    },
    R.uniqBy((item) => item.instanceName, instanceNameList),
  );
  instanceOption = R.uniqBy((item) => item.value, instanceOption);
  podOptions = R.uniqBy((item) => item.value, podOptions);
  containerOption = R.uniqBy((item) => item.value, containerOption);
  if (hasKubernetes) {
    instanceOption = R.differenceWith((x, y) => x.value === y.value, instanceOption, podOptions);
  }
  const instanceListOptions = R.sortWith(
    [R.descend(R.prop('count'))],
    R.map((item) => ({ ...item, count: instanceAnomalies[item.value] || 0 }), instanceOption),
  );
  const podListOptions = R.sortWith(
    [R.descend(R.prop('count'))],
    R.map((item) => ({ ...item, count: podAnomalies[item.value] || 0 }), podOptions),
  );
  const containerListOption = R.sortWith(
    [R.descend(R.prop('count'))],
    R.map((item) => ({ ...item, count: containerAnomalies[item.value] || 0 }), containerOption),
  );

  const predictionSourceOptions = [
    // { value: '_all', label: 'All', count: -1 },
    ...R.sort(R.descend(R.prop('count')), R.values(predictionSourceCategory)),
  ];

  eventComponents = R.filter((item) => Boolean(item), R.uniq(eventComponents));
  let componentListOptions = R.map((component) => {
    return { value: component, label: component, count: componentAnomalies[component] || 0 };
  }, eventComponents);
  componentListOptions = [...R.sortWith([R.descend(R.prop('count'))], componentListOptions)];

  eventPatternIds = R.uniqBy((item) => `${item.patternId}/${item.patternName}`, eventPatternIds);
  let patternIdFilterOptions = R.map((item) => {
    const patternKey = `${item.patternId}/${item.patternName}`;
    return {
      value: patternKey,
      label: `${item.patternId} / ${item.patternName}`,
      count: eventPatternIdsNumMap[patternKey] || 1,
      instanceName: item.instanceName,
      patternId: item.patternId,
      patternName: item.patternName,
    };
  }, eventPatternIds);
  patternIdFilterOptions = R.sortWith([R.ascend(R.prop('label'))], patternIdFilterOptions);
  patternIdFilterOptions = R.sortWith([R.descend(R.prop('count'))], patternIdFilterOptions);

  return {
    eventList,
    hasTableData,
    anomalyTypeOptions,
    instanceListOptions,
    podListOptions,
    containerListOption,
    predictionSourceOptions,
    componentListOptions,
    patternIdFilterOptions,
    predictionStatusCountMap,
  };
};

const closeRender = (self, key) => {
  return (
    <div className="clickable" onClick={() => self.setState((pre) => ({ [key]: !pre[key] }))}>
      {self.state[key] ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
    </div>
  );
};

const renderTopBarRender = ({ self, isLoadingParserData, instanceDisplayNameMap }) => {
  const { intl } = self.props;
  const { predictionStatusFilter, typeFilter, componentFilter, instanceFilter, logicPodIDFilter } = self.state;
  const { containerFilter, patternIdFilter, sourceFilter } = self.state;
  const { hasKubernetes, patternIdsFilter, componentsFilter, instancesFilter, podsFilter, containersFilter } =
    self.state;
  const { predictionStatusClose, predictionCategoryClose, impactedComponentsClose, impactedInstancesClose } =
    self.state;
  const { impactedPodsClose, impactedContainersClose, patternIdNameClose, sourceCategoryClose } = self.state;

  const newTypeFilter = typeFilter ? R.split(',', typeFilter) : [];
  const newSourceFilter = sourceFilter ? R.split(',', sourceFilter) : [];
  const newComponentFilter = componentFilter ? R.split(',', componentFilter) : [];
  const newInstanceFilter = instanceFilter ? R.split(',', instanceFilter) : [];
  const newLogicPodIDFilter = logicPodIDFilter ? R.split(',', logicPodIDFilter) : [];
  const newContainerFilter = containerFilter ? R.split(',', containerFilter) : [];
  const newPatternIdFilter = patternIdFilter ? R.split(',', patternIdFilter) : [];
  const newPredictionStatusFilter = predictionStatusFilter ? R.split(',', predictionStatusFilter) : [];

  let newComponentListOptions = self.componentListOptions || [];
  if (componentsFilter) {
    newComponentListOptions = R.filter(
      (event) => R.toLower(event?.label || '').includes(R.toLower(componentsFilter || '')),
      newComponentListOptions,
    );
  }

  let newInstanceListOptions = self.instanceListOptions || [];
  if (instancesFilter) {
    newInstanceListOptions = R.filter((event) => {
      const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, event?.label, {
        pn: event?.projectName,
        owner: event?.projectOwner,
      });
      return (
        R.toLower(event?.label || '').includes(R.toLower(instancesFilter || '')) ||
        R.toLower(instanceDisplayName || '').includes(R.toLower(instancesFilter || ''))
      );
    }, newInstanceListOptions);
  }

  let newPodOptions = self.podOptions || [];
  if (podsFilter) {
    newPodOptions = R.filter((event) => {
      const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, event?.label, {
        pn: event?.projectName,
        owner: event?.projectOwner,
      });
      return (
        R.toLower(event?.label || '').includes(R.toLower(podsFilter || '')) ||
        R.toLower(instanceDisplayName || '').includes(R.toLower(podsFilter || ''))
      );
    }, newPodOptions);
  }

  let newContainerOption = self.containerOption || [];
  if (containersFilter) {
    newContainerOption = R.filter(
      (event) => R.toLower(event?.label || '').includes(R.toLower(containersFilter || '')),
      newContainerOption,
    );
  }

  let newPatternIdFilterOptions = self.patternIdFilterOptions || [];
  if (patternIdsFilter) {
    newPatternIdFilterOptions = R.filter(
      (event) => R.toLower(event?.label || '').includes(R.toLower(patternIdsFilter || '')),
      newPatternIdFilterOptions,
    );
  }

  {
    let count = 0;
    let headCount = 0;
    headCount += 1;
    if (!predictionStatusClose) {
      count += predictionStatusOption.length;
    }
    headCount += 1;
    if (!predictionCategoryClose) {
      count += self.anomalyTypeOptions.length;
    }
    if (self.componentListOptions.length > 0) {
      headCount += 1;
      if (!impactedComponentsClose) {
        count += newComponentListOptions.length;
      }
    }

    if (self.instanceListOptions.length > 0) {
      headCount += 1;
      if (!impactedInstancesClose) {
        count += newInstanceListOptions.length;
      }
    }
    if (self.podOptions.length > 0 && hasKubernetes) {
      headCount += 1;
      if (!impactedPodsClose) {
        count += newPodOptions.length;
      }
    }
    if (self.containerOption.length > 0 && logicPodIDFilter) {
      headCount += 1;
      if (!impactedContainersClose) {
        count += newContainerOption.length;
      }
    }

    if (self.patternIdFilterOptions.length > 0) {
      headCount += 1;
      if (!patternIdNameClose) {
        count += newPatternIdFilterOptions.length;
      }
    }
    if (self.predictionSourceOptions.length > 0) {
      headCount += 1;
      if (!sourceCategoryClose) {
        count += self.predictionSourceOptions.length;
      }
    }
    self.searchBarCountObj = {
      headCount,
      childCount: count,
    };
  }

  return (
    <div className="block flex-grow flex-col flex-min-height flex-min-width content-bg full-height">
      <Spin spinning={isLoadingParserData} wrapperClassName="full-width full-height spin-full-height">
        <div className="flex-col full-height">
          <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
            <span className="label" style={{ fontSize: 14 }}>
              {intl.formatMessage(eventMessages.predictionStatus)}
            </span>
            <div className="flex-grow" />
            {closeRender(self, 'predictionStatusClose')}
          </div>
          {!predictionStatusClose && (
            <Checkbox.Group
              value={newPredictionStatusFilter}
              onChange={(value) => self.onChangeFilter(value, 'predictionStatusFilter')}
            >
              <Row>
                {R.addIndex(R.map)((item, index) => {
                  return (
                    <Col
                      span={24}
                      key={item.value}
                      className="flex-row flex-center-align"
                      style={{ ...(index === 0 ? {} : { marginTop: 4 }) }}
                    >
                      <Checkbox value={item.value} />
                      <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                        <span className="hidden-line-with-ellipsis flex-grow">{item.label}</span>
                        <Tag
                          size="small"
                          style={{
                            borderRadius: 100,
                            marginLeft: 8,
                            fontWeight: 'bold',
                            cursor: 'default',
                            ...(item.color ? { color: item.color } : {}),
                          }}
                        >
                          {self.predictionStatusCountMap[item.value] || 0}
                        </Tag>
                      </span>
                    </Col>
                  );
                }, predictionStatusOption || [])}
              </Row>
            </Checkbox.Group>
          )}

          <Divider style={{ margin: '16px 0 8px 0' }} />

          <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
            <span className="label" style={{ fontSize: 14 }}>
              {intl.formatMessage(eventMessages.predictionCategory)}
            </span>
            <div className="flex-grow" />
            {closeRender(self, 'predictionCategoryClose')}
          </div>
          {!predictionCategoryClose && (
            <Checkbox.Group value={newTypeFilter} onChange={(value) => self.onChangeFilter(value, 'typeFilter')}>
              <Row>
                {R.addIndex(R.map)((item, index) => {
                  return (
                    <Col
                      span={24}
                      key={item.value}
                      className="flex-row flex-center-align"
                      style={{ ...(index === 0 ? {} : { marginTop: 4 }) }}
                    >
                      <Checkbox value={item.value} />
                      <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                        <span className="hidden-line-with-ellipsis flex-grow" style={{ textTransform: 'capitalize' }}>
                          {item.label}
                        </span>
                        <Tag
                          size="small"
                          style={{
                            borderRadius: 100,
                            marginLeft: 8,
                            fontWeight: 'bold',
                            cursor: 'default',
                            ...(item.color ? { color: item.color } : {}),
                          }}
                        >
                          {item.count}
                        </Tag>
                      </span>
                    </Col>
                  );
                }, self.anomalyTypeOptions || [])}
              </Row>
            </Checkbox.Group>
          )}

          <Divider style={{ margin: '16px 0 8px 0' }} />

          {self.componentListOptions.length > 0 && (
            <>
              <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
                <span className="label" style={{ fontSize: 14 }}>
                  {intl.formatMessage(appFieldsMessages.impactedComponents)}
                </span>
                <div className="flex-grow" />
                {closeRender(self, 'impactedComponentsClose')}
              </div>
              {!impactedComponentsClose && (
                <>
                  <Input.Search
                    allowClear
                    style={{ width: '100%', marginBottom: 4 }}
                    size="small"
                    placeholder={intl.formatMessage(appFieldsMessages.impactedComponents)}
                    value={componentsFilter}
                    onSearch={(componentsFilter) => self.setState({ componentsFilter })}
                    onChange={(e) => {
                      self.setState({ componentsFilter: e.target.value });
                    }}
                  />
                  <Checkbox.Group
                    value={newComponentFilter}
                    onChange={(value) => self.onChangeFilter(value, 'componentFilter')}
                  >
                    <Row>
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <Col
                            span={24}
                            key={item.value}
                            className="flex-row flex-center-align"
                            style={{ marginTop: 4 }}
                          >
                            <Checkbox value={item.value} />
                            <span
                              className="flex-grow flex-row flex-center-align overflow-hidden"
                              style={{ marginLeft: 8 }}
                            >
                              <Popover content={item.label} mouseEnterDelay={0.3} placement="top">
                                <span className="hidden-line-with-ellipsis flex-grow">{item.label}</span>
                              </Popover>
                              <Tag
                                size="small"
                                style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                              >
                                {item.count}
                              </Tag>
                            </span>
                          </Col>
                        );
                      }, newComponentListOptions)}
                    </Row>
                  </Checkbox.Group>
                </>
              )}

              <Divider style={{ margin: '16px 0 8px 0' }} />
            </>
          )}

          {self.instanceListOptions.length > 0 && (
            <>
              <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
                <span className="label" style={{ fontSize: 14 }}>
                  {intl.formatMessage(appFieldsMessages.impactedInstances)}
                </span>
                <div className="flex-grow" />
                {closeRender(self, 'impactedInstancesClose')}
              </div>
              {!impactedInstancesClose && (
                <>
                  <Input.Search
                    allowClear
                    style={{ width: '100%', marginBottom: 4 }}
                    size="small"
                    placeholder={intl.formatMessage(appFieldsMessages.impactedInstances)}
                    value={instancesFilter}
                    onSearch={(instancesFilter) => self.setState({ instancesFilter })}
                    onChange={(e) => {
                      self.setState({ instancesFilter: e.target.value });
                    }}
                  />
                  <Checkbox.Group
                    value={newInstanceFilter}
                    onChange={(value) => self.onChangeFilter(value, 'instanceFilter')}
                  >
                    <Row>
                      {R.addIndex(R.map)((item, index) => {
                        const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, item?.label, {
                          pn: item?.projectName,
                          owner: item?.projectOwner,
                        });
                        const width = instanceDisplayName ? 140 : 100;
                        return (
                          <Col
                            span={24}
                            key={item.value}
                            className="flex-row flex-center-align"
                            style={{ marginTop: 4 }}
                          >
                            <Checkbox value={item.value} />
                            <span
                              className="flex-grow flex-row flex-center-align overflow-hidden"
                              style={{ marginLeft: 8 }}
                            >
                              <Popover
                                content={
                                  <div
                                    className="flex-col"
                                    style={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}
                                  >
                                    <div className="flex-row">
                                      <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                                        {intl.formatMessage(eventMessages.instanceName)}
                                        :&nbsp;
                                      </div>
                                      {item.label}
                                    </div>
                                    {instanceDisplayName && (
                                      <div className="flex-row">
                                        <div className="light-label bold" style={{ width: 140, flexShrink: 0 }}>
                                          {intl.formatMessage(appFieldsMessages.instanceDisplayName)}
                                          :&nbsp;
                                        </div>
                                        {instanceDisplayName}
                                      </div>
                                    )}
                                  </div>
                                }
                                mouseEnterDelay={0.3}
                                placement="top"
                              >
                                <span className="hidden-line-with-ellipsis flex-grow">
                                  {instanceDisplayName || item.label}
                                </span>
                              </Popover>
                              <Tag
                                size="small"
                                style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                              >
                                {item.count}
                              </Tag>
                            </span>
                          </Col>
                        );
                      }, newInstanceListOptions)}
                    </Row>
                  </Checkbox.Group>
                </>
              )}

              <Divider style={{ margin: '16px 0 8px 0' }} />
            </>
          )}

          {self.podOptions.length > 0 && hasKubernetes && (
            <>
              <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
                <span className="label" style={{ fontSize: 14 }}>
                  {intl.formatMessage(appFieldsMessages.impactedPods)}
                </span>
                <div className="flex-grow" />
                {closeRender(self, 'impactedPodsClose')}
              </div>
              {!impactedPodsClose && (
                <>
                  <Input.Search
                    allowClear
                    style={{ width: '100%', marginBottom: 4 }}
                    size="small"
                    placeholder={intl.formatMessage(appFieldsMessages.impactedPods)}
                    value={podsFilter}
                    onSearch={(podsFilter) => self.setState({ podsFilter })}
                    onChange={(e) => {
                      self.setState({ podsFilter: e.target.value });
                    }}
                  />
                  <Checkbox.Group
                    value={newLogicPodIDFilter}
                    onChange={(value) => self.onChangeFilter(value, 'logicPodIDFilter')}
                  >
                    <Row>
                      {R.addIndex(R.map)((item, index) => {
                        const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, item?.label, {
                          pn: item?.projectName,
                          owner: item?.projectOwner,
                        });
                        const width = instanceDisplayName ? 140 : 100;
                        return (
                          <Col
                            span={24}
                            key={item.value}
                            className="flex-row flex-center-align"
                            style={{ marginTop: 4 }}
                          >
                            <Checkbox value={item.value} />
                            <span
                              className="flex-grow flex-row flex-center-align overflow-hidden"
                              style={{ marginLeft: 8 }}
                            >
                              <Popover
                                content={
                                  <div
                                    className="flex-col"
                                    style={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}
                                  >
                                    <div className="flex-row">
                                      <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                                        {intl.formatMessage(appFieldsMessages.logicPodID)}
                                        :&nbsp;
                                      </div>
                                      {item.label}
                                    </div>
                                    {instanceDisplayName && (
                                      <div className="flex-row">
                                        <div className="light-label bold" style={{ width: 140, flexShrink: 0 }}>
                                          {intl.formatMessage(appFieldsMessages.instanceDisplayName)}
                                          :&nbsp;
                                        </div>
                                        {instanceDisplayName}
                                      </div>
                                    )}
                                  </div>
                                }
                                mouseEnterDelay={0.3}
                                placement="top"
                              >
                                <span className="hidden-line-with-ellipsis flex-grow">
                                  {instanceDisplayName || item.label}
                                </span>
                              </Popover>
                              <Tag
                                size="small"
                                style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                              >
                                {item.count}
                              </Tag>
                            </span>
                          </Col>
                        );
                      }, newPodOptions)}
                    </Row>
                  </Checkbox.Group>
                </>
              )}

              <Divider style={{ margin: '16px 0 8px 0' }} />
            </>
          )}

          {self.containerOption.length > 0 && logicPodIDFilter && (
            <>
              <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
                <span className="label" style={{ fontSize: 14 }}>
                  {intl.formatMessage(appFieldsMessages.impactedContainers)}
                </span>
                <div className="flex-grow" />
                {closeRender(self, 'impactedContainersClose')}
              </div>
              {!impactedContainersClose && (
                <>
                  <Input.Search
                    allowClear
                    style={{ width: '100%', marginBottom: 4 }}
                    size="small"
                    placeholder={intl.formatMessage(appFieldsMessages.impactedContainers)}
                    value={containersFilter}
                    onSearch={(containersFilter) => self.setState({ containersFilter })}
                    onChange={(e) => {
                      self.setState({ containersFilter: e.target.value });
                    }}
                  />
                  <Checkbox.Group
                    value={newContainerFilter}
                    onChange={(value) => self.onChangeFilter(value, 'containerFilter')}
                    disabled={hasKubernetes ? !logicPodIDFilter : false}
                  >
                    <Row>
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <Col
                            span={24}
                            key={item.value}
                            className="flex-row flex-center-align"
                            style={{ marginTop: 4 }}
                          >
                            <Checkbox value={item.value} />
                            <span
                              className="flex-grow flex-row flex-center-align overflow-hidden"
                              style={{ marginLeft: 8 }}
                            >
                              <Popover content={item.label} mouseEnterDelay={0.3} placement="top">
                                <span className="hidden-line-with-ellipsis flex-grow">{item.label}</span>
                              </Popover>
                            </span>
                          </Col>
                        );
                      }, newContainerOption)}
                    </Row>
                  </Checkbox.Group>
                </>
              )}

              <Divider style={{ margin: '16px 0 8px 0' }} />
            </>
          )}

          {self.patternIdFilterOptions.length > 0 && (
            <>
              <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
                <span className="label" style={{ fontSize: 14 }}>
                  {intl.formatMessage(appFieldsMessages.patternIdName)}
                </span>
                <div className="flex-grow" />
                {closeRender(self, 'patternIdNameClose')}
              </div>
              {!patternIdNameClose && (
                <>
                  <Input.Search
                    allowClear
                    style={{ width: '100%', marginBottom: 4 }}
                    size="small"
                    placeholder={intl.formatMessage(appFieldsMessages.patternIdName)}
                    value={patternIdsFilter}
                    onSearch={(patternIdsFilter) => self.setState({ patternIdsFilter })}
                    onChange={(e) => {
                      self.setState({ patternIdsFilter: e.target.value });
                    }}
                  />
                  <Checkbox.Group
                    value={newPatternIdFilter}
                    onChange={(value) => self.onChangeFilter(value, 'patternIdFilter')}
                  >
                    <Row>
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <Col
                            span={24}
                            key={item.value}
                            className="flex-row flex-center-align"
                            style={{ marginTop: 4 }}
                          >
                            <Checkbox value={item.value} />
                            <span
                              className="flex-grow flex-row flex-center-align overflow-hidden"
                              style={{ marginLeft: 8 }}
                            >
                              <Popover content={item.label} mouseEnterDelay={0.3} placement="top">
                                <span className="hidden-line-with-ellipsis flex-grow">{item.label}</span>
                              </Popover>
                              <Tag
                                size="small"
                                style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                              >
                                {item.count}
                              </Tag>
                            </span>
                          </Col>
                        );
                      }, newPatternIdFilterOptions)}
                    </Row>
                  </Checkbox.Group>
                </>
              )}

              <Divider style={{ margin: '16px 0 8px 0' }} />
            </>
          )}

          {self.predictionSourceOptions.length > 0 && (
            <>
              <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
                <span className="label" style={{ fontSize: 14 }}>
                  {intl.formatMessage(eventMessages.predictionSourceCategory)}
                </span>
                <div className="flex-grow" />
                {closeRender(self, 'sourceCategoryClose')}
              </div>
              {!sourceCategoryClose && (
                <Checkbox.Group
                  value={newSourceFilter}
                  onChange={(value) => self.onChangeFilter(value, 'sourceFilter')}
                >
                  <Row>
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Col
                          span={24}
                          key={item.value}
                          className="flex-row flex-center-align"
                          style={{ ...(index === 0 ? {} : { marginTop: 4 }) }}
                        >
                          <Checkbox value={item.value} disabled={item.count === 0} />
                          <span
                            className="flex-grow flex-row flex-center-align overflow-hidden"
                            style={{
                              marginLeft: 8,
                              ...(item.count === 0 ? { color: 'var(--disabled-color)', cursor: 'not-allowed' } : {}),
                            }}
                          >
                            <Popover content={item.label} mouseEnterDelay={0.3} placement="top">
                              <span className="hidden-line-with-ellipsis flex-grow">{item.label}</span>
                            </Popover>
                            <Tag
                              size="small"
                              style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                            >
                              {item.count}
                            </Tag>
                          </span>
                        </Col>
                      );
                    }, self.predictionSourceOptions || [])}
                  </Row>
                </Checkbox.Group>
              )}
            </>
          )}

          <div style={{ padding: 8 }} />
        </div>
      </Spin>
    </div>
  );
};

const reducerHandleEvents = (groupEventList) => {
  let eventOccurrenceList = [];
  let predictionCount = 0;
  R.forEach((event) => {
    if (event.isTimeRoot) predictionCount += event.count;
    if (event.isExpand && event.events && event.events.length > 0) {
      let dateChildren = [];
      R.forEach((item) => {
        item.lastChildren = R.last(item?.events || []) || {};
        if (item.isExpand && item.events && item.events.length > 0) {
          dateChildren = [...dateChildren, item, ...item.events];
        } else {
          dateChildren = [...dateChildren, item];
        }
      }, event.events || []);
      eventOccurrenceList = [...eventOccurrenceList, event, ...dateChildren];
    } else {
      eventOccurrenceList = [...eventOccurrenceList, event];
    }
  }, groupEventList || []);
  return { eventOccurrenceList, predictionCount };
};

const handlerCheck = (e, self) => {
  const { checked } = e.target;
  const { groupEventList } = self.state;

  R.forEach((item) => {
    R.forEach((_item) => {
      _item.checked = checked;
      R.forEach((__item) => {
        __item.checked = checked;
      }, _item.events);
    }, item.events || []);
  }, groupEventList || []);

  self.setState({ allChecked: checked });
  self.cellMeasureCache.clearAll();
  if (self.listNode) self.listNode.forceUpdateGrid();
  self.forceUpdate();
};

const headerClick = (self, name) => {
  return (e) => {
    e.stopPropagation();
    const { sortBy, sortDirection } = self.state;
    let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
    if (name !== sortBy) {
      sortDir = 'ASC';
    }

    if (name) {
      self.setState({ sortBy: name, sortDirection: sortDir }, () => {
        const { filterList } = self.state;
        const groupEventList = getEventsByOccurrence(self, filterList);
        self.setState({ groupEventList }, () => {
          self.cellMeasureCache.clearAll();
          if (self.listNode) self.listNode.forceUpdateGrid();
          self.forceUpdate();
        });
      });
    }
  };
};

const sortIcon = (sortBy, sortDirection, name) => {
  if (sortBy !== name || sortDirection === 'NA') {
    return null;
  }
  if (sortDirection === 'ASC') {
    return <CaretUpOutlined />;
  }
  return <CaretDownOutlined />;
};

const expandAll = (self) => {
  const { allExpand, groupEventList } = self.state;
  R.forEach((item) => {
    if (item.events && item.events.length > 0) {
      item.isExpand = true;
      R.forEach((_item) => {
        if (_item.events && _item.events.length > 0) {
          _item.isExpand = !allExpand;
        }
      }, item.events);
    }
  }, groupEventList);
  self.setState({ allExpand: !allExpand, dateAllExpand: true }, () => {
    self.cellMeasureCache.clearAll();
    if (self.refList) self.refList.forceUpdateGrid();
    self.forceUpdate();
  });
};

const handleMergeCategoryDateClick = (self, rowData) => {
  const { groupEventList } = self.state;
  const { isExpand, id } = rowData;
  const rowIndex = R.findIndex(R.propEq('id', id))(groupEventList);
  const newEventList = [...(groupEventList || [])];
  newEventList[rowIndex].isExpand = !isExpand;

  self.setState({ groupEventList: newEventList }, () => {
    self.cellMeasureCache.clearAll();
    if (self.listNode) self.listNode.forceUpdateGrid();
    self.forceUpdate();
  });
};

const rendererExpand = (rowIndex, rowData) => {
  const { isParent, isExpand, isTimeRoot } = rowData;

  return (
    (isParent || isTimeRoot) && (
      <div className="full-width flex-row flex-center-align flex-center-justify" style={{ height: 40 }}>
        {isExpand ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
      </div>
    )
  );
};

const checkboxParentRender = (self, rowData, rootEventIdx, dateIdx) => {
  const { groupEventList } = self.state;
  const { checked } = rowData;
  return (
    <Checkbox
      size="small"
      checked={checked}
      onChange={(e) => {
        const newGroupEventList = R.addIndex(R.map)((timeEvent, dateIndex) => {
          if (dateIndex === dateIdx) {
            const newDateEvents = R.addIndex(R.map)((groupEvent, eventIndex) => {
              if (eventIndex === rootEventIdx) {
                const newEvents = R.map((event) => ({ ...event, checked: e.target.checked }), groupEvent.events);
                return { ...groupEvent, events: newEvents, checked: e.target.checked };
              } else {
                return { ...groupEvent };
              }
            }, timeEvent.events);
            return { ...timeEvent, events: newDateEvents };
          } else {
            return { ...timeEvent };
          }
        }, groupEventList);

        const allChecked = R.all(R.equals(true))(
          R.map((event) => {
            return R.all(R.equals(-1))(
              R.map((item) => R.findIndex((citem) => !citem.checked, item.events || []), event.events || []),
            );
          }, newGroupEventList || []),
        );

        self.setState({ groupEventList: newGroupEventList, allChecked }, () => {
          if (self.listNode) self.listNode.forceUpdateGrid();
        });
      }}
    />
  );
};

const patternRenderer = (self, isJWT, rowData, active) => {
  const { intl, isReadUser } = self.props;

  const { patternName, patternId } = rowData;

  const isPrediction = rowData.isPrediction || rowData.timeLineType === 'future';

  const patternIdAndName = Defaults.PatternIdNameStr(
    {
      patternName,
      patternId,
    },
    {},
  ).patternNameStr;

  const patternStyle = { color: '#ffad66', fontSize: 20, marginRight: 4, ...(active ? { cursor: 'default' } : {}) };
  const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });

  return (
    <div className="max-width flex-row flex-center-align">
      {isPrediction && (
        <Popover title={null} content={intl.formatMessage(DashboardMessages.predictedIncident)} mouseEnterDelay={0.3}>
          {patternIcon}
        </Popover>
      )}
      <Popover
        content={<div>{`${intl.formatMessage(logMessages.patternId)}: ${patternIdAndName}`}</div>}
        mouseEnterDelay={0.3}
        placement="top"
      >
        <div className="flex-grow hidden-line-with-ellipsis" style={{ ...(active ? { cursor: 'default' } : {}) }}>
          <div className="hidden-line-with-ellipsis">{`[${patternId}]`}</div>
          <div className="hidden-line-with-ellipsis">{patternIdAndName}</div>
        </div>
      </Popover>
      {!isReadUser && !isJWT && (
        <Popover content={intl.formatMessage(eventMessages.setPatternName)} mouseEnterDelay={0.3} placement="top">
          <EditOutlined
            className="primary-color"
            style={{ marginLeft: 2 }}
            onClick={(e) => {
              e.stopPropagation();
              self.fatherEvent = rowData;
              self.childEvent = undefined;
              self.handleChangePatternNameClick({ event: rowData?.events[0] || {} });
            }}
          />
        </Popover>
      )}
    </div>
  );
};

const impactedRender = ({ intl, rowData, isChild, instanceDisplayNameMap }) => {
  const { componentName, instanceName, containerInfo, projectName, projectOwner } = rowData;
  const instanceListStr = containerInfo ? containerInfo.instanceName : instanceName;
  const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, instanceListStr, {
    pn: projectName,
    owner: projectOwner,
  });
  const width = instanceDisplayName ? 140 : 110;
  return (
    <div className="max-width flex-row">
      <Popover
        content={
          <div style={{ maxWidth: 400, wordBreak: 'break-all' }}>
            <div className="flex-row">
              <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                {intl.formatMessage(eventMessages.componentName)}:&nbsp;
              </div>
              {componentName}
            </div>
            {isChild && (
              <>
                {containerInfo && (
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                      {intl.formatMessage(eventMessages.containerName)}:&nbsp;
                    </div>
                    {containerInfo.containerName}
                  </div>
                )}
                <div className="flex-row">
                  <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                    {intl.formatMessage(containerInfo ? appFieldsMessages.logicPodID : eventMessages.instanceName)}
                    :&nbsp;
                  </div>
                  {instanceListStr}
                </div>
                {instanceDisplayName && (
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width: 140, flexShrink: 0 }}>
                      {intl.formatMessage(appFieldsMessages.instanceDisplayName)}
                      :&nbsp;
                    </div>
                    {instanceDisplayName}
                  </div>
                )}
              </>
            )}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <div className="overflow-hidden">
          {!isChild && <div className="hidden-line-with-ellipsis">{componentName}</div>}
          {isChild && (
            <>
              <div className="hidden-line-with-ellipsis">{containerInfo ? containerInfo.containerName : ''}</div>
              <div className="hidden-line-with-ellipsis">{instanceDisplayName || instanceListStr}</div>
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};

const timeRenderer = (rowData) => {
  const { predictionTime, isParent } = rowData;

  const parentEvents = isParent ? rowData?.events || [] : [];

  return (
    <div className="flex-col full-width">
      {!predictionTime && 'N/A'}
      {predictionTime && (
        <div>
          {isParent ? (
            <div>
              <div className="flex-row flex-center-align">
                {moment.utc(parentEvents[0]?.predictionTime).format(Defaults.ShortTimeFormat)}
              </div>
              <div className="flex-row flex-center-align">
                {moment.utc(parentEvents[parentEvents.length - 1]?.predictionTime).format(Defaults.ShortTimeFormat)}
              </div>
            </div>
          ) : (
            <div className="flex-row flex-center-align">
              {moment.utc(predictionTime).format(Defaults.ShortTimeOnlyFormat)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const occurrenceTime = (rowData) => {
  const { startTimestamp } = rowData;
  return <div>{moment.utc(startTimestamp).format(Defaults.ShortTimeOnlyFormat)}</div>;
};

const shortDescriptionInfo = (rowData, self) => {
  const { intl, currentTheme, credentials, userInfo } = self.props;
  const { category, rawData, frequencyStr, anomalyFeatureStr, projectOwner } = rowData;
  let { projectName } = rowData;
  projectName = projectOwner !== (credentials || userInfo).userName ? `${projectName}@${projectOwner}` : projectName;
  const summarySettings = (self?.summarySettingsMap || {})[projectName] || [];

  const rootCauseDetailsArr = get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []);
  let rawDataJson;
  try {
    rawDataJson = JSON.parse(rawData);
  } catch (error) {
    // console.debug(error)
  }

  return (
    <Popover
      placement="right"
      trigger={['click', 'hover']}
      content={
        <div className="overflow-y-auto overflow-x-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
          {category === 'metric' && (
            <div>
              {R.addIndex(R.map)(
                (event, index) => EventRenderers.RenderMetricAnomalySummary({ intl, event, index }),
                rootCauseDetailsArr,
              )}
            </div>
          )}
          {category === 'log' && (
            <>
              <div style={{ wordWrap: 'break-word' }}>
                {!rawDataJson && !R.isEmpty(frequencyStr) && <div style={{ color: 'var(--blue)' }}>{frequencyStr}</div>}
                {!rawDataJson && anomalyFeatureStr && (
                  <div className="max-width flex-col" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
                    {R.addIndex(R.map)((item, index) => {
                      const [label, values] = item;
                      const content = R.addIndex(R.map)(
                        ({ value }, i) => (
                          <span style={{ margin: '0 4px' }} key={`featureStr${index}${i}`}>
                            {value}
                            {values.length - 1 === i ? '' : ','}
                          </span>
                        ),
                        values,
                      );
                      return (
                        <div className="flex-row" key={`${index}+${label}`}>
                          <div style={{ whiteSpace: 'nowrap' }}>{label} :</div>
                          <div className="flex-row flex-wrap">{content}</div>
                        </div>
                      );
                    }, anomalyFeatureStr)}
                  </div>
                )}
                {!rawDataJson && (
                  <div style={{ whiteSpace: 'break-spaces' }}>
                    {R.join(
                      '\n',
                      R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                    )}
                  </div>
                )}
                {rawDataJson && (
                  <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                    <LogRenderers.RenderLogContent
                      intl={intl}
                      rawData={rawData}
                      rawDataJson={rawDataJson}
                      owner={rowData}
                      summarySettings={summarySettings}
                      enableExpansion={false}
                      currentTheme={currentTheme}
                      frequencyStr={frequencyStr}
                      anomalyFeatureStr={anomalyFeatureStr}
                      clearStyle
                      noExpand
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      }
    >
      <div className="flex-row flex-center-align" style={{ minHeight: 20 }}>
        <InfoCircleOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </div>
    </Popover>
  );
};

const rendererContent = (rowData, self) => {
  const { intl, currentTheme, credentials, userInfo } = self.props;
  const { category, rawData, projectOwner } = rowData;
  let { projectName } = rowData;
  projectName = projectOwner !== (credentials || userInfo).userName ? `${projectName}@${projectOwner}` : projectName;
  const summarySettings = (self?.summarySettingsMap || {})[projectName] || [];

  const rootCauseDetailsArr = get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []);
  let content;
  let rawDataJson;
  if (category === 'metric') {
    const summaryList = R.map((event) => {
      return EventRenderers.BuildMetricAnomalySummary({ event });
    }, rootCauseDetailsArr);
    content = R.join('\n', summaryList);
  } else {
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error)
    }
  }

  return (
    <div className="max-width flex-row hover-display flex-center-align" style={{ height: '100%' }}>
      <div className="flex-col flex-center-justify hidden-line-with-ellipsis-multiline">
        {category === 'metric' ? (
          <div className="hidden-line-with-ellipsis">{content}</div>
        ) : (
          <>
            {!rawDataJson && <div className="hidden-line-with-ellipsis">{rawData}</div>}
            {rawDataJson && (
              <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                <LogRenderers.RenderLogContent
                  intl={intl}
                  rawData={rawData}
                  rawDataJson={rawDataJson}
                  owner={rowData}
                  summarySettings={summarySettings || []}
                  enableExpansion={false}
                  currentTheme={currentTheme}
                  clearStyle
                  infoRender={shortDescriptionInfo(rowData, self)}
                  takeNum={2}
                  inLine
                />
              </div>
            )}
          </>
        )}
      </div>
      {(!rawDataJson || (summarySettings.length === 0 && rawDataJson)) && shortDescriptionInfo(rowData, self)}
    </div>
  );
};

const renderActionTriggered = (rowData, index) => {
  const { actionName, timestamp } = rowData;
  return (
    <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
      <div className="row-column" style={{ width: 140 }}>
        {moment.utc(timestamp).format(Defaults.DateTimeFormat)}
      </div>
      <div className="row-column" style={{ width: 120, flex: 1 }}>
        {actionName}
      </div>
    </div>
  );
};

const statusChainRenderer = (rowData, self) => {
  const { intl } = self.props;
  const item = rowData;
  return (
    <>
      {item.isMatchedIncident && (
        <Popover
          title={null}
          content={
            item.isMatchedIncident
              ? intl.formatMessage(logMessages.matchedIncident)
              : intl.formatMessage(logMessages.unmatchedIncident)
          }
          mouseEnterDelay={0.3}
        >
          <MatchedIncidentIcon style={{ fontSize: 16, color: 'transparent' }} />
        </Popover>
      )}
      {!item.isMatchedIncident && item.isFuture && (
        <Popover placement="top" title={null} content={intl.formatMessage(eventMessages.futureEvents)} trigger="hover">
          <FutureEventsIcon style={{ fontSize: 16, color: 'transparent' }} />
        </Popover>
      )}
      {!item.isMatchedIncident && !item.isFuture && !item.notValidSrc && !item.triggeredActionList.length > 0 && (
        <Popover
          title={null}
          content={
            item.isMatchedIncident
              ? intl.formatMessage(logMessages.matchedIncident)
              : intl.formatMessage(logMessages.unmatchedIncident)
          }
          mouseEnterDelay={0.3}
        >
          <UnmatchedIncidentIcon style={{ fontSize: 16, color: 'transparent' }} />
        </Popover>
      )}
      {item.notValidSrc && (
        <Popover
          placement="right"
          title={null}
          content={intl.formatMessage(logMessages.invalidPrediction)}
          trigger="hover"
        >
          <InvalidPredictionsIcon style={{ fontSize: 16, color: 'transparent' }} />
        </Popover>
      )}
      {item.isNotified && (
        <Tooltip title="Real time notification was sent" mouseEnterDelay={0.3} placement="top">
          <BellOutlined size="small" style={{ fontSize: 14, color: '#FF7A00' }} />
        </Tooltip>
      )}
      {item.triggeredActionList.length > 0 && (
        <Popover
          title="Action triggered"
          content={
            <div className="event-list flex-grow flex-col flex-min-height" style={{ width: 500, maxHeight: 240 }}>
              <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                <div className="header-column" style={{ width: 140 }}>
                  {intl.formatMessage(eventActionMessages.triggeredTime)}
                </div>
                <div className="header-column" style={{ width: 120, flex: 1 }}>
                  {intl.formatMessage(eventActionMessages.actionName)}
                </div>
              </div>
              <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                {R.addIndex(R.map)((item, index) => renderActionTriggered(item, index), item.triggeredActionList)}
              </div>
            </div>
          }
          mouseEnterDelay={0.3}
          placement="left"
        >
          <ActionIcon style={{ color: '#037AEF', fontSize: 14 }} />
        </Popover>
      )}
      {item.isFixedIncident && (
        <Tooltip title="Is fixed incident" mouseEnterDelay={0.3}>
          <FixedIcon style={{ color: '#ff5142', fontSize: 14 }} />
        </Tooltip>
      )}
    </>
  );
};

const tableViewList = (isJWT, self, intl, eventOccurrenceList, predictionCount, renderListItem) => {
  const { allChecked, sortBy, sortDirection, allExpand } = self.state;

  return (
    <div className="flex-col flex-min-height content-bg" style={{ marginLeft: 8, flex: 1 }}>
      <AutoSizer>
        {({ width, height }) => (
          <div className="event-list">
            <div
              className="event-list-header"
              style={{
                height: 40,
                width,
                paddingRight: self.listNodeHeaderScrollbar ? 9 : 0,
              }}
            >
              <div className="header-column flex-center-justify" style={{ width: 40 }}>
                {!isJWT && <Checkbox size="small" checked={allChecked} onChange={(e) => handlerCheck(e, self)} />}
              </div>
              <div
                className="header-column"
                style={{ width: 90, minWidth: 90 }}
                onClick={headerClick(self, 'predictionTime')}
              >
                <span>{intl.formatMessage(eventMessages.predictionTime)}</span>
                {sortIcon(sortBy, sortDirection, 'predictionTime')}
              </div>
              <div
                className="header-column"
                style={{ width: 120, minWidth: 120 }}
                onClick={headerClick(self, 'startTimestamp')}
              >
                <span>{intl.formatMessage(eventMessages.predictedOccurrenceTime)}</span>
                {sortIcon(sortBy, sortDirection, 'startTimestamp')}
              </div>
              <div
                className="header-column"
                style={{ width: 90, minWidth: 90 }}
                onClick={headerClick(self, 'leadTime')}
              >
                <span>{intl.formatMessage(eventMessages.leadTime)}</span>
                {sortIcon(sortBy, sortDirection, 'leadTime')}
              </div>
              <div
                className="header-column"
                style={{ width: 160, minWidth: 160 }}
                onClick={headerClick(self, 'patternId')}
              >
                <span>{intl.formatMessage(appFieldsMessages.patternIdName)}</span>
                {sortIcon(sortBy, sortDirection, 'patternId')}
              </div>
              <div
                className="header-column"
                style={{ width: 190, minWidth: 190 }}
                onClick={headerClick(self, 'componentName')}
              >
                <span>{intl.formatMessage(eventMessages.impacted)}</span>
                {sortIcon(sortBy, sortDirection, 'componentName')}
              </div>
              <div className="header-column" style={{ flex: 1, minWidth: 140 }}>
                {intl.formatMessage(eventMessages.shortDescription)}
              </div>
              <div
                className="header-column flex-center-justify text-center"
                style={{ width: 130, minWidth: 130, color: 'var(--primary-color)', paddingRight: 16 }}
              >
                {intl.formatMessage(appMenusMessages.insights)}
              </div>
              <div className="header-column" style={{ width: 100 }}>
                {intl.formatMessage(appMenusMessages.flags)}
              </div>
              <div className="header-column" style={{ width: 95 }} onClick={headerClick(self, 'status')}>
                <span>{intl.formatMessage(logMessages.status)}</span>
                {sortIcon(sortBy, sortDirection, 'status')}
              </div>
              <div
                className="header-column flex-center-justify break-word"
                style={{ width: 110 }}
                onClick={headerClick(self, 'count')}
              >
                <span>{`${intl.formatMessage(eventMessages.count)}(${predictionCount})`}</span>
                {sortIcon(sortBy, sortDirection, 'count')}
              </div>
              <div
                className="header-column"
                style={{ width: 70, padding: 0, justifyContent: 'center' }}
                onClick={() => expandAll(self)}
              >
                <DoubleLeftOutlined rotate={allExpand ? 90 : -90} />
              </div>
            </div>
            <List
              className="event-list-grid"
              ref={(listNode) => {
                self.listNode = listNode;
              }}
              width={width}
              height={height - self.listHeaderHeight}
              rowCount={eventOccurrenceList.length}
              overscanRowCount={10}
              deferredMeasurementCache={self.cellMeasureCache}
              rowHeight={self.cellMeasureCache.rowHeight}
              onScrollbarPresenceChange={({ horizontal, vertical }) => {
                if (vertical) {
                  self.listNodeHeaderScrollbar = true;
                } else {
                  self.listNodeHeaderScrollbar = false;
                }
                self.forceUpdate();
              }}
              rowRenderer={renderListItem(eventOccurrenceList)}
            />
          </div>
        )}
      </AutoSizer>
    </div>
  );
};

export {
  listHeaderHeight,
  rowMinHeight,
  dateHeight,
  childrenBorder,
  getChartTimeMap,
  tidyEventList,
  anomalyTypeOptions,
  filterData,
  findJumpEvent,
  positionEvent,
  getChartOption,
  getEventsByOccurrence,
  renderTopBarRender,
  setChartRef,
  onSystemChartClick,
  handleChartZoom,
  handleChartRestore,
  handleChartFinish,
  customizeRestore,
  reducerHandleEvents,
  tableViewList,
  handleMergeCategoryDateClick,
  handleMergeCategoryParentClick,
  rendererExpand,
  checkboxParentRender,
  patternRenderer,
  impactedRender,
  timeRenderer,
  occurrenceTime,
  rendererContent,
  statusChainRenderer,
};
