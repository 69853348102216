/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Button, Popconfirm, message } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import fetchGet from '../../../common/apis/fetchGet';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { hideAppLoader, updateLastActionInfo } from '../../../common/app/actions';
import { Container, AutoSizer, Table, Column, Popover } from '../../../lib/fui/react';
import { State } from '../../../common/types';
import { CellRenderers, Defaults, BackgroundCall } from '../../../common/utils';
import { SunnyIcon, RainingIcon } from '../../../lib/fui/icons';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

type Props = {
  intl: Object,
  refresh: Number,
  credentials: Object,
  hideAppLoader: Function,
  updateLastActionInfo: Function,
  appLoaderVisible: Boolean,
};

class CronstatusCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.state = { isLoading: false, cronStatus: [] };
    this.gridOffsetY = 40;
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.refresh !== this.props.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const self = this;
    const { credentials } = props;

    self.setState({ isLoading: true });
    props.updateLastActionInfo();
    fetchGet(getEndpoint('cronstatus'), {
      ...credentials,
    }).then((data) => {
      let cronStatus = [];
      const dropCommand = 'http://localhost:8080';
      R.forEachObjIndexed((value, key) => {
        let { command } = value;
        const { operation, updateTimestamp, count, frequency, ...rest } = value;
        if (command.indexOf(dropCommand) === 0) {
          command = command.substring(dropCommand.length, command.length);
        }
        cronStatus.push({
          ...rest,
          operation,
          command,
          updateTimestamp,
          count,
          frequency: frequency * 1000,
        });
      }, JSON.parse(data.cronList || ''));

      cronStatus = this.sortData(cronStatus, this.state);

      self.setState({ cronStatus, isLoading: false });
    });
  }

  @autobind
  sortData(datas, state) {
    const { sortBy, sortDirection } = state;

    // sort by
    let sortList = datas;
    let sortFunctions = [R.ascend(R.compose(R.toLower, R.prop('operation')))];
    if (sortBy && sortDirection) {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);

    return sortList;
  }

  @autobind
  statusRenderer(props) {
    const { cellData } = props;
    return (
      <Popover content={cellData ? 'Normal' : 'Abnormal'} mouseEnterDelay={0.3} placement="right">
        {cellData && <SunnyIcon style={{ color: '#ffc800', fontSize: 16 }} />}
        {!cellData && <RainingIcon style={{ color: Defaults.Colorbrewer[0], fontSize: 16 }} />}
      </Popover>
    );
  }

  @autobind
  updateTimestampRenderer(props) {
    const { cellData } = props;
    return <div>{moment.utc(Number(cellData)).format(Defaults.TimeFormat)}</div>;
  }

  @autobind
  controlRenderer({ rowData }) {
    const { intl } = this.props;
    return (
      <div className="flex-row">
        <Popconfirm
          placement="topRight"
          title={
            <div>
              {intl.formatMessage(eventMessages.permanentlyDeleteThisCronJob, { operation: rowData.operation })}
              <br />
              {intl.formatMessage(appMessages.continueConfirm)}
            </div>
          }
          onConfirm={this.handleDelete(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button size="small" style={{ marginRight: 8 }} onClick={(event) => event.stopPropagation()}>
            {intl.formatMessage(appButtonsMessages.delete)}
          </Button>
        </Popconfirm>
        <Popconfirm
          placement="topRight"
          title={<div>{intl.formatMessage(eventMessages.triggerCron, { operation: rowData.operation })}</div>}
          onConfirm={this.handleExecute(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button size="small" type="primary" onClick={(event) => event.stopPropagation()}>
            {intl.formatMessage(eventMessages.execute)}
          </Button>
        </Popconfirm>
      </div>
    );
  }

  @autobind
  handleDelete(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      const { intl, credentials } = this.props;
      this.props.updateLastActionInfo();
      fetchDelete(getEndpoint('cronstatus'), {
        ...credentials,
        operation: rowData.operation,
      })
        .then((d) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.reloadData(this.props);
        })
        .catch((err) => {
          console.warn('[IF_API] api call failed, ignored', err);
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    };
  }

  @autobind
  handleExecute(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      const { intl } = this.props;
      this.props.updateLastActionInfo();
      BackgroundCall.CallUrlJob({
        intl,
        method: 'GET',
        url: `${window.BASE_URL || ''}${rowData.command}`,
        params: {},
        isJsonResponse: false,
      });
    };
  }

  @autobind
  sort({ sortBy, sortDirection }) {
    const { cronStatus, sortDirection: prevSortDirection } = this.state;
    this.setState({
      sortBy,
      sortDirection: !prevSortDirection ? 'ASC' : prevSortDirection === 'DESC' ? undefined : sortDirection,
      cronStatus: this.sortData(cronStatus, {
        ...this.state,
        sortBy,
        sortDirection: !prevSortDirection ? 'ASC' : prevSortDirection === 'DESC' ? undefined : sortDirection,
      }),
    });
  }

  @autobind
  headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey || !sortDirection) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { isLoading, cronStatus } = this.state;
    const { sortBy, sortDirection } = this.state;
    return (
      <Container className="flex-col" fullHeight>
        <Container className={`flex-grow ${isLoading ? ' loading' : ''}`}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <Table
                className="with-border"
                width={1048}
                height={height}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={50}
                rowCount={cronStatus.length}
                rowGetter={({ index }) => cronStatus[index]}
                ref={(table) => (this.table = table)}
                sort={this.sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
              >
                <Column width={40} label="" dataKey="isNormal" cellRenderer={this.statusRenderer} disableSort />
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.operation)}
                  dataKey="operation"
                  flexGrow={1}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.command)}
                  dataKey="command"
                  flexGrow={1}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={180}
                  label={intl.formatMessage(eventMessages.lastUpdatedGMT)}
                  dataKey="updateTimestamp"
                  cellRenderer={this.updateTimestampRenderer}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={100}
                  label={intl.formatMessage(logMessages.frequency)}
                  dataKey="frequency"
                  cellRenderer={({ cellData }) => CellRenderers.humanizeDuration({ period: cellData, intl })}
                  headerRenderer={this.headerRenderer}
                />
                <Column width={180} label="" cellRenderer={this.controlRenderer} dataKey="queueName" disableSort />
              </Table>
            )}
          </AutoSizer>
        </Container>
      </Container>
    );
  }
}

const Cronstatus = injectIntl(CronstatusCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { appLoaderVisible } = state.app;
    return { credentials, appLoaderVisible };
  },
  { hideAppLoader, updateLastActionInfo },
)(Cronstatus);
