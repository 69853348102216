/* @flow */

import { defineMessages } from 'react-intl';

const capacityPlanningMessages = defineMessages({
  currentDeployment: {
    defaultMessage: 'Current change',
    id: 'event.capacity.currentDeployment',
  },
  predictionsForRequired: {
    defaultMessage: 'Predictions for required instances to keep below thresholds',
    id: 'event.capacity.predictionsForRequired',
  },
  average: {
    defaultMessage: 'Average {metric}',
    id: 'event.capacity.average',
  },
  maximum: {
    defaultMessage: 'Maximum {metric}',
    id: 'event.capacity.maximum',
  },
  avgUsagePast: {
    defaultMessage: 'Avg usage (past 30 days data)',
    id: 'event.capacity.avgUsagePast',
  },
  avgUsageReal: {
    defaultMessage: 'Avg usage (past {lines} lines data)',
    id: 'event.capacity.avgUsageReal',
  },
  predictedAvgUsageCurrent: {
    defaultMessage: 'Predicted Avg usage (current day)',
    id: 'event.capacity.predictedAvgUsageCurrent',
  },
  predictionDeviationCurrent: {
    defaultMessage: 'Prediction deviation (current day)',
    id: 'event.capacity.predictionDeviationCurrent',
  },
  full: {
    defaultMessage: 'Full',
    id: 'event.capacity.full',
  },
  noChange: {
    defaultMessage: 'No change',
    id: 'event.capacity.noChange',
  },
  addInstances: {
    defaultMessage: 'Add {count} {name}',
    id: 'event.capacity.addInstances',
  },
  removeInstances: {
    defaultMessage: 'Remove {count} {name}',
    id: 'event.capacity.removeInstances',
  },
});

export default capacityPlanningMessages;
