/* @flow */

import { defineMessages } from 'react-intl';

const HelpMessages = defineMessages({
  welcome: {
    defaultMessage: 'Welcome',
    id: 'help.welcome',
  },
  welcomeInfo: {
    defaultMessage:
      'Thanks for choosing InsightFinder to monitor and manage your applications and systems. We provide a general use guide here to help answer some of the questions you may have. We will be glad to receive your contributions and suggestions both with respect to the content we outline below and other areas that you feel may need better or more in-depth explanation. If you need to get in touch with us, please send an email to info@insightfinder.com.',
    id: 'help.welcomeInfo',
  },
  helpStart: {
    defaultMessage: 'How to get started?',
    id: 'help.helpStart',
  },
  helpStart1: {
    defaultMessage: 'Step 1: Register your project according to data source types.',
    id: 'help.helpStart1',
  },
  helpStart2: {
    defaultMessage: 'Step 2: View anomaly detection and diagnosis results in Dashboard.',
    id: 'help.helpStart2',
  },
  helpStart3: {
    defaultMessage: 'Step 3: Configure your projects in settings.',
    id: 'help.helpStart3',
  },

  helpAgent: {
    defaultMessage: 'How to install Insight Agent?',
    id: 'help.helpAgent',
  },
  helpAgent1: {
    defaultMessage: 'Step 1: Retrieve your license key here if you forget.',
    id: 'help.helpAgent1',
  },
  helpAgent2: {
    defaultMessage: 'Step 2: Select the agent(s) you want to install.',
    id: 'help.helpAgent2',
  },
  helpAgent3: {
    defaultMessage: 'Step 3: Deploy one or multiple agents using our deployment code.',
    id: 'help.helpAgent3',
  },

  helpDashboard: {
    defaultMessage: 'Dashboard',
    id: 'help.helpDashboard',
  },
  helpDashboard1: {
    defaultMessage: 'View health score and anomaly info of your systems.',
    id: 'help.helpDashboard1',
  },
  helpDashboard2: {
    defaultMessage: 'View incident prediction of your systems.',
    id: 'help.helpDashboard2',
  },
  helpDashboard3: {
    defaultMessage: 'View detection anomalous and root cause analysis of your systems.',
    id: 'help.helpDashboard3',
  },
  helpDashboard4: {
    defaultMessage: 'View alert summary and root cause analysis of your systems.',
    id: 'help.helpDashboard4',
  },

  helpLog: {
    defaultMessage: 'Log',
    id: 'help.helpLog',
  },
  helpLog1: {
    defaultMessage: 'View log analysis for patterns and anomalies in log files.',
    id: 'help.helpLog1',
  },

  helpSettings: {
    defaultMessage: 'Settings',
    id: 'help.helpSettings',
  },
  helpSettings1: {
    defaultMessage: 'See a list of existing projects, and register/remove project.',
    id: 'help.helpSettings1',
  },
  helpSettings2: {
    defaultMessage: 'Anomaly detection and metric threshold settings.',
    id: 'help.helpSettings2',
  },
  helpSettings3: {
    defaultMessage: 'Project sharing with collaborators.',
    id: 'help.helpSettings3',
  },
  helpSettings4: {
    defaultMessage: 'Third party incident reporting service.',
    id: 'help.helpSettings4',
  },
});

export default HelpMessages;
