import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Button, Dropdown, Menu } from 'antd';

import {
  BarChartOutlined,
  LineChartOutlined,
  AlignLeftOutlined,
  EyeInvisibleOutlined,
  ZoomInOutlined,
  PauseOutlined,
  FormOutlined,
  SecurityScanOutlined,
} from '@ant-design/icons';

import { BaseUrls } from '../../app/Constants';
import { Defaults, buildUrl } from '../../../common/utils';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { QeuryIcon } from '../../../lib/fui/icons';

import { appMenusMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import InsightQueryBoxModal from './InsightQueryBoxModal';

type Props = {
  style: Object,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  onSetQueryParams: Function,
  projectDisplayMap: Object,
};

class GlobalPanelLogQueryCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    let { systemInfo, projectDisplayMap } = props;

    if (projectDisplayMap && systemInfo) {
      const newSystemInfo = R.clone(systemInfo);
      newSystemInfo.projectNameSet = R.map(
        (item) => ({
          ...item,
          ...(projectDisplayMap[item.projectName] ? { projectDisplayName: projectDisplayMap[item.projectName] } : {}),
        }),
        newSystemInfo?.projectNameSet || [],
      );
      systemInfo = newSystemInfo;
    }

    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const logProjects = R.filter(
      (project) => project.dataType !== 'Metric' && project.ps !== 'Deleting',
      projectNameSet,
    );

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {
      eventList: logProjects || [],

      showInsightQueryBox: false,
      queryParams: {},
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.systemInfo !== this.props.systemInfo) {
      this.parseData(nextProps);
    }
  }

  @autobind
  async parseData(props) {
    let { systemInfo, projectDisplayMap } = props;

    if (!systemInfo || R.isEmpty(systemInfo)) {
      return;
    }

    if (projectDisplayMap) {
      const newSystemInfo = R.clone(systemInfo);
      newSystemInfo.projectNameSet = R.map(
        (item) => ({
          ...item,
          ...(projectDisplayMap[item.projectName] ? { projectDisplayName: projectDisplayMap[item.projectName] } : {}),
        }),
        newSystemInfo?.projectNameSet || [],
      );
      systemInfo = newSystemInfo;
    }

    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const logProjects = R.filter(
      (project) => project.dataType !== 'Metric' && project.ps !== 'Deleting',
      projectNameSet,
    );

    this.setState({ eventList: logProjects || [] });
  }

  @autobind
  handleLogQueryClick({ item, domEvent }) {
    domEvent.stopPropagation();

    const { startTime, endTime, onSetQueryParams, systemInfo } = this.props;
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const logProjects = R.filter((project) => project.dataType !== 'Metric', projectNameSet);
    const { projectNameReal } = item?.props?.data || {};
    this.setState({
      showInsightQueryBox: true,
      queryParams: {
        projectName: projectNameReal,
        startTimeObj: moment.utc(startTime, Defaults.DateFormat).startOf('day'),
        endTimeObj: moment.utc(endTime, Defaults.DateFormat).endOf('day'),
      },
    });

    if (onSetQueryParams) {
      onSetQueryParams({
        showInsightQueryBox: true,
        queryParams: {
          projectName: projectNameReal,
          startTimeObj: moment.utc(startTime, Defaults.DateFormat).startOf('day'),
          endTimeObj: moment.utc(endTime, Defaults.DateFormat).endOf('day'),
        },
        logProjects,
      });
    }
  }

  @autobind
  handleInsightQueryConfirm(params) {
    const {
      templateId,
      projectName,
      instanceName,
      startTimeObj,
      endTimeObj,
      keyword,
      numOfCluster,
      pattern,
      duration,
    } = params;
    const query = {
      t: templateId,
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      keyword,
      numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
      pattern,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      duration,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    this.setState({ showInsightQueryBox: false });
  }

  render() {
    const { intl, onSetQueryParams, style } = this.props;
    const { eventList } = this.state;

    return (
      <>
        {eventList.length > 0 && (
          <>
            <Dropdown
              overlay={
                <Menu onClick={this.handleLogQueryClick}>
                  {R.addIndex(R.map)(
                    (item, index) => (
                      <Menu.Item key={`linechartList-${index}`} title={item.projectDisplayName} data={item}>
                        {item.projectDisplayName}
                      </Menu.Item>
                    ),
                    eventList || [],
                  )}
                </Menu>
              }
              placement="bottomLeft"
              arrow
              size="small"
            >
              <Button size="small" style={style}>
                <AlignLeftOutlined />
                {intl.formatMessage(appMenusMessages.logQuery)}
              </Button>
            </Dropdown>

            {!onSetQueryParams && this.state.showInsightQueryBox && (
              <InsightQueryBoxModal
                queryParams={this.state.queryParams}
                projects={eventList}
                onConfirm={this.handleInsightQueryConfirm}
                onClose={() => this.setState({ showInsightQueryBox: false })}
              />
            )}
          </>
        )}
      </>
    );
  }
}

const GlobalPanelLogQuery = injectIntl(GlobalPanelLogQueryCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,
    };
  },
  { updateLastActionInfo },
)(GlobalPanelLogQuery);
