/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import React from 'react';
import cx from 'classnames';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { Button, Tooltip } from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Box, Tile, Heatmap } from '../../../../lib/fui/react';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { buildUrl, Defaults } from '../../../../common/utils';

import { appFieldsMessages, appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  intl: Object,
  className: string,
  style: Object,
  projectName: string,
  credentials: Object,
  userInfo: Object,
  instanceGroup: string,
  model: Object,
  big: boolean,
  onModelSelect: Function,
  onModelPick: Function,
  onModelRemove: Function,
  onModelExport: Function,
  isSelect: Boolean,
  showDetailsBut: Boolean,
  handleModalDetails: Function,
};

class ModelTile extends React.PureComponent {
  props: Props;

  @autobind
  handleModelSelect() {
    const { model } = this.props;
    this.props.onModelSelect(model.modelKey);
  }

  @autobind
  handleModelExport(e) {
    e.preventDefault();
    e.stopPropagation();

    const { model } = this.props;
    this.props.onModelExport(model.modelKey);
  }

  @autobind
  handleModelRemove(e) {
    e.preventDefault();
    e.stopPropagation();

    const { model } = this.props;
    const { startTimestamp } = model;
    const startTime = moment.utc(startTimestamp).format(Defaults.ShortTimeFormat);
    if (window.confirm(`Are you sure to remove model ${startTime}`)) {
      this.props.onModelRemove(model.modelKey);
    }
  }

  @autobind
  handleModelPick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { model } = this.props;
    this.props.onModelPick(model.modelKey);
  }

  render() {
    const { intl, credentials, userInfo, model, big, projectName, instanceGroup, isSelect, className, style } =
      this.props;
    const { showDetailsBut, handleModalDetails } = this.props;
    const count = 32;
    const size = count * (big ? 4 : 2);
    const {
      startTimestamp,
      endTimestamp,
      dataStartTimestamp,
      dataEndTimestamp,
      picked,
      sampleCount,
      modelKey,
      metrics,
      heatmap,
    } = model || {};
    if (!startTimestamp || !endTimestamp) {
      return null;
    }
    let { pickable } = model;

    let fileUrl = null;
    if (!big) {
      const modelKeyObj = { startTimestamp, endTimestamp, modelKey };
      const { userName } = credentials;
      const params = {
        projectName,
        instanceGroup,
        userName,
        modelKeyObj: JSON.stringify(modelKeyObj),
        operation: 'download',
      };
      fileUrl = buildUrl(getEndpoint('modelPicking'), {}, params);
    } else {
      pickable = false;
    }

    const startTimeObj = moment.utc(dataStartTimestamp || startTimestamp);

    return (
      <Tile className={cx('model-tile', { big, picked }, className)} style={{ ...style }}>
        <Box
          style={isSelect ? { border: '2px solid orange' } : {}}
          isLink={!big}
          onClick={this.handleModelSelect}
          className="corner-8"
        >
          {!big && (dataStartTimestamp || startTimestamp) && (
            <div
              style={{
                fontSize: 12,
                background:
                  startTimeObj.format('d') === '0' || startTimeObj.format('d') === '6' ? 'darkgrey' : 'darkblue',
                display: 'inline-block',
                color: 'white',
                padding: '0px 2px',
                position: 'absolute',
                top: 1,
                left: 1,
              }}
            >
              {startTimeObj.format('ddd')}
            </div>
          )}
          <Heatmap dataset={heatmap} countPerRow={count} style={{ width: size, height: size, margin: '4px auto' }} />
          <div className="meta">
            <div>{`${intl.formatMessage(appFieldsMessages.start)}: ${moment
              .utc(dataStartTimestamp || startTimestamp)
              .format(Defaults.ShortTimeFormat)}`}</div>
            <div>{`${intl.formatMessage(appFieldsMessages.end)}: ${moment
              .utc(dataEndTimestamp || endTimestamp)
              .format(Defaults.ShortTimeFormat)}`}</div>
            <div>{`${intl.formatMessage(appFieldsMessages.metric)}: ${metrics.length}`}</div>
            <div>{`${intl.formatMessage(settingsMessages.samples)}: ${sampleCount}`}</div>
            {userInfo.isAdmin && <CloseOutlined className="antd_remove icon" onClick={this.handleModelRemove} />}
            <Tooltip placement="top" mouseEnterDelay={0.3} title={intl.formatMessage(appButtonsMessages.pick)}>
              <CheckOutlined className="antd_check icon" />
            </Tooltip>

            {showDetailsBut && (
              <Button
                size="small"
                style={{ marginTop: 8 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleModalDetails(modelKey);
                }}
              >
                {intl.formatMessage(appFieldsMessages.details)}
              </Button>
            )}

            {false && (
              <Tooltip placement="top" mouseEnterDelay={0.3} title={intl.formatMessage(appButtonsMessages.export)}>
                <i className="external share icon" onClick={this.handleModelExport} />
              </Tooltip>
            )}
            {false && (
              <Tooltip placement="top" mouseEnterDelay={0.3} title={intl.formatMessage(appButtonsMessages.download)}>
                <a className="download" href={fileUrl} target="_blank" rel="noopener noreferrer">
                  <i className="download icon" />
                </a>
              </Tooltip>
            )}
          </div>
        </Box>
      </Tile>
    );
  }
}

export default ModelTile;
