/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */
/* eslint-disable no-console */

import type { Action, Credentials, Message } from '../types';
import { authMessages } from './messages';

export const ActionTypes = {
  LOGIN: 'LOGIN',
  SANDBOX_LOGIN: 'SANDBOX_LOGIN',
  LOGOFF: 'LOGOFF',
  CLEAR_CREDENTIALS: 'CLEAR_CREDENTIALS',
  IDP_USER_LOGIN: 'IDP_USER_LOGIN',
  NEW_IDP_USER: 'NEW_IDP_USER',
  USER_TYPE_CHECK: 'USER_TYPE_CHECK',
  SINGLESIGNINLOGIN: 'SINGLESIGNINLOGIN',
  PASSPORTLOGIN: 'PASSPORTLOGIN',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  RESET_NEW_PASSWORD_BY_TEMP: 'RESET_NEW_PASSWORD_BY_TEMP',
  SET_NEW_IDP_USER_STATUS: 'SET_NEW_IDP_USER_STATUS',
  UPDATE_USER_AUTH_INFO: 'UPDATE_USER_AUTH_INFO',
  SET_USER_AUTH_INFO: 'SET_USER_AUTH_INFO',
};

export const idpUserLogin = (idpState: String, params: Object): Action => ({
  type: ActionTypes.IDP_USER_LOGIN,
  payload: { idpState, params },
});

export const userTypeCheck = (userName: String, params: Object, callback: ?Function): Action => ({
  type: ActionTypes.USER_TYPE_CHECK,
  payload: { userName, params, callback },
});

export const userFound = (redirectLoginURL: String, redirectURL: String): Action => ({
  type: 'USER_FOUND',
  payload: { redirectLoginURL, redirectURL },
});

export const userNotFound = (idpSettingMap: Object): Action => ({
  type: 'USER_NOT_FOUND',
  payload: { idpSettingMap },
});

export const login = (
  userName: String,
  password: String,
  isIdpUser: Boolean,
  idpType: String,
  params: Object,
): Action => ({
  type: ActionTypes.LOGIN,
  payload: { userName, password, isIdpUser, idpType, params },
});

export const sandboxLogin = (params: Object): Action => ({
  type: ActionTypes.SANDBOX_LOGIN,
  payload: params,
});

export const loginSuccess = (credentials: Credentials, userInfo: ?Object = null): Action => ({
  type: 'LOGIN_SUCCESS',
  payload: { credentials, userInfo },
});

export const loginFailure = (
  message: ?Message = null,
  error: ?Error = null,
  idpAvailable: ?Boolean = null,
): Action => ({
  type: 'LOGIN_FAILURE',
  payload: { message, error, idpAvailable },
});

export const loginMessage = (message: ?Message = null): Action => ({
  type: 'LOGIN_MESSAGE',
  payload: { message },
});

export const loginPending = (redirectURL: String, error: ?any): Action => ({
  type: 'LOGIN_PENDING',
  payload: { redirectURL, error },
});

export const sessionInvalid = (error: ?Error = null): Action => ({
  type: 'LOGIN_FAILURE',
  payload: {
    message: authMessages.errorsTokenInvalid,
    error,
  },
});

export const setResetPasswordByTempStatus = (succeed: Boolean, error: ?any): Action => ({
  type: 'AUTH_SET_RESET_PASSWORD_BY_TEMP_STATUS',
  payload: { succeed, error },
});
export const setResetNewPasswordStatus = (succeed: Boolean, error: ?any): Action => ({
  type: 'SET_RESET_NEW_PASSWORD_STATUS',
  payload: { succeed, error },
});

export const logoff = (credentials: Object, loginRedirectURL: String): Action => ({
  type: ActionTypes.LOGOFF,
  payload: { credentials, loginRedirectURL },
});

export const clearCredentials = (): Action => ({
  type: ActionTypes.CLEAR_CREDENTIALS,
  payload: {},
});
