import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Form, Input, message } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
// import { Defaults } from '../../../../common/utils';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { Modal } from '../../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  incident: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  systemsMap: Object,
  credentials: Object,
  userInfo: Object,
  updateLastActionInfo: Function,
};

class SystemEditModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { systemsMap, userInfo, incident } = props;
    const { systemName } = incident;

    this.state = { isSubmitting: false, systemName };
    this.userSystemNames = R.map(
      (item) => item.systemName,
      R.filter((item) => item.owner === userInfo.userName, R.values(systemsMap)),
    );
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleSubmit() {
    const { intl, credentials, incident, onClose } = this.props;
    const { environmentName, systemId, owner } = incident;
    const { systemName } = this.state;

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(
      getEndpoint('systemframework', 2),
      {
        ...credentials,
        operation: 'changeSystemDisplayName',
        systemKey: JSON.stringify({
          userName: owner,
          systemName: systemId,
          environmentName,
        }),
        systemDisplayName: systemName,
      },
      {},
      false,
    )
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmitting: false });
        onClose(true);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
      });
  }

  render() {
    const { intl, onClose, incident } = this.props;
    const { isSubmitting, systemName } = this.state;

    const disabled = !systemName || this.userSystemNames.includes(systemName);
    return (
      <Modal
        title={intl.formatMessage(settingsMessages.editSystem)}
        width={650}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled, loading: isSubmitting }}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          <Form.Item label={intl.formatMessage(appFieldsMessages.systemId)}>
            <Input disabled value={incident.systemId} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.systemName)}
            validateStatus={disabled ? 'error' : 'success'}
            help={
              this.userSystemNames.includes(systemName)
                ? 'The system name is already in use. Please pick a different system name.'
                : undefined
            }
            required
          >
            <Input value={systemName} onChange={(e) => this.setState({ systemName: e.target.value })} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const SystemEditModal = injectIntl(SystemEditModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    return { location, loadStatus, systemsMap, credentials, userInfo };
  },
  {
    updateLastActionInfo,
  },
)(SystemEditModal);
