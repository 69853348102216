import React, { memo, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import { get } from 'lodash';
import tinygradient from 'tinygradient';
import moment from 'moment';
import { Button, message, Spin, Tabs } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { AutoSizer, Column, Container, Modal, Popover, SortDirection, Table } from '../../../lib/fui/react';
import { buildUrl, Defaults, getPatternNameIcon, parseLocation } from '../../../common/utils';
import { EChart } from '../../share';
import { OptionHtml, PopverTableHtml } from './OptionHtml';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import BaseUrls from '../../app/BaseUrls';
import { getInstanceDisplayNameFn } from '../utils';

const DashCellDetail = memo(
  ({
    onCancel,
    list,
    title,
    typeName,
    credentials,
    location,
    intl,
    globalInfo,
    currentTheme,
    customerName,
    systemId,
    startTime,
    endTime,
    environmentId,
    systemInfo,
    instanceDisplayNameMap,
  }: Object) => {
    const query = parseLocation(location);
    const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
      newList: R.map(
        (item) => ({ ...item, value: item.count, loading: false }),
        R.sort((a, b) => b.count - a.count, list || []),
      ),
      sortBy: null,
      sortDirection: null,
      tabsKey: null,
      refresh: false,
    });
    const { newList, sortBy, sortDirection, tabsKey, refresh } = state;
    let echartsAxiosDebugger = true;
    let dataTableNode = null;
    let echartRef = null;
    let logEchartRef = null;
    const optionDetail = useRef({});

    useLayoutEffect(() => {
      const hasMetric = R.find((item) => item.dataType === 0, newList);
      if (hasMetric) {
        setState({ tabsKey: 'metric' });
      } else {
        setState({ tabsKey: 'log' });
      }
    }, []);

    useEffect(() => {
      return () => {
        optionDetail.current = {};
      };
    }, []);

    useEffect(() => {
      if (refresh) {
        setTimeout(() => setState({ refresh: false }));
      }
    }, [refresh]);

    const getOptionDetail = (rowData, detailKey, callback) => {
      fetchGet(getEndpoint('pie-chart-detail'), {
        ...credentials,
        customerName: systemInfo?.customerName,
        systemName: systemId,
        startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
        endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
        instanceName: rowData.instanceName,
        dataType: rowData.dataType,
        patternId: rowData.patternId,
        metricName: rowData.dataType === 0 && rowData.supportInfo ? rowData.supportInfo : undefined,
      })
        .then((data) => {
          const { success, insightDetail } = data;
          if (success === undefined || success) {
            optionDetail.current[detailKey] = insightDetail;
          } else {
            optionDetail.current[detailKey] = {};
          }
          echartsAxiosDebugger = true;
          callback();
        })
        .catch((err) => {
          echartsAxiosDebugger = true;
          if (err?.code >= 500) {
            console.error(intl.formatMessage(appMessages.apiFaild));
          } else {
            message.error(intl.formatMessage(appMessages.apiFaild));
          }
        });
    };

    const getPieOption = (data, gradientColorsList, { intl }) => {
      const gradient = tinygradient(['#043e7d', '#6abbf7']);
      const colorsRgb = gradient.rgb(data.length < 2 ? 2 : data.length);
      const colors = R.map((x) => {
        return x.toHexString();
      }, colorsRgb);
      data = R.sort((a, b) => b.value - a.value, data || []);
      const option = {
        color: colors,
        tooltip: {
          backgroundColor: 'var(--component-background)',
          borderColor: 'transparent',
          trigger: 'item',
          enterable: true,
          appendToBody: true,
          position: (pos, params, dom, rect, size) => {
            const boxWidth = size.contentSize[0]; // 弹框的width
            return [pos[0] - boxWidth - 5, pos[1] + 5];
          },
          formatter: (params, ticket, callback) => {
            const { data } = params;
            const detailKey = `${data.instanceName}-${data.patternId}-${data.count}`;
            if (!R.has(detailKey, optionDetail.current)) {
              if (echartsAxiosDebugger) {
                echartsAxiosDebugger = false;
                getOptionDetail(data, detailKey, () => {
                  const newFormatter = OptionHtml({
                    data,
                    detailKey,
                    optionDetail: optionDetail.current,
                    intl,
                    currentTheme,
                    showIcon: typeName === 'incidents',
                    instanceDisplayNameMap,
                  });
                  callback(ticket, newFormatter);
                  if (dataTableNode) {
                    dataTableNode.forceUpdate();
                  }
                  setState({ refresh: true });
                });
              }
            }
            if (optionDetail.current[detailKey]) {
              return OptionHtml({
                data,
                detailKey,
                optionDetail: optionDetail.current,
                intl,
                currentTheme,
                showIcon: typeName === 'incidents',
                instanceDisplayNameMap,
              });
            } else {
              return ReactDOMServer.renderToStaticMarkup(<Spin />);
            }
          },
          textStyle: {
            color: 'var(--text-color)',
          },
        },
        series: [
          {
            name: title,
            type: 'pie',
            radius: ['50%', '90%'],
            avoidLabelOverlap: false,
            itemStyle: {
              emphasis: {
                shadowBlur: 4,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            label: {
              show: true,
              position: 'inner',
              formatter: (params) => {
                const { data, percent } = params;
                return percent > 5 ? data?.value : '';
              },
              color: 'white',
              fontWeight: 'bold',
              fontSize: 14,
            },
            emphasis: {
              disabled: false,
              label: {
                show: true,
                fontSize: '12',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            emptyCircleStyle: {
              color: 'transparent',
              borderWidth: 1,
              borderColor: '#ccc',
            },
            data,
          },
        ],
      };
      return option;
    };

    // poeOption
    const [pieOption] = useState(() =>
      getPieOption(
        R.filter((item) => item.dataType === 0, newList || []),
        ['#5d4157', '#a8caba'],
        { intl },
      ),
    );
    const [logPieOption] = useState(() =>
      getPieOption(
        R.filter((item) => item.dataType === 1, newList || []),
        ['#5d4157', '#a8caba'],
        { intl },
      ),
    );

    const sort = ({ sortBy, sortDirection }) => {
      setState({ sortBy, sortDirection });
    };

    const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
      const sortIcon = () => {
        if (sortBy !== dataKey) {
          return null;
        }
        if (sortDirection === 'ASC') {
          return <CaretUpOutlined />;
        }
        return <CaretDownOutlined />;
      };
      return (
        <div>
          {label}
          {!disableSort && sortIcon()}
        </div>
      );
    };

    const instanceRender = ({ rowData, cellData }) => {
      const { instanceName, componentName } = rowData;
      const { instanceDisplayName, viewContent, onlyInstanceName } = getInstanceDisplayNameFn({
        instanceDisplayNameMap,
        instanceName,
        intl,
      });
      return (
        <Popover
          title={null}
          placement="rightTop"
          content={
            <div className={`formatter-wrap ${instanceDisplayName ? 'formatter-wrap-w' : ''}`}>
              <div>
                <span className="formatter-lable">{intl.formatMessage(eventActionMessages.componentName)}:</span>
                <span>{componentName}</span>
              </div>
              {viewContent}
            </div>
          }
        >
          <div className="overflow-hidden" style={{ wordWrap: 'break-word', width: 'fit-content', maxWidth: 200 }}>
            <div className="hidden-line-with-ellipsis">{componentName}</div>
            <div className="hidden-line-with-ellipsis">{instanceDisplayName || onlyInstanceName}</div>
          </div>
        </Popover>
      );
    };

    const patternIdRender = ({ rowData, rowIndex }) => {
      const { instanceName, patternId, count, patternName } = rowData;
      const detailKey = `${instanceName}-${patternId}-${count}`;

      const patternIdAndName = R.toString(patternName || patternId);
      const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
      const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });

      return (
        <Popover
          title={null}
          content={
            <Spin spinning={rowData.loading}>
              {PopverTableHtml({
                data: rowData,
                detailKey,
                optionDetail: optionDetail.current,
                intl,
                currentTheme,
                showIcon: typeName === 'incidents',
              })}
            </Spin>
          }
          placement="rightTop"
          onVisibleChange={(visible) => {
            if (visible) {
              if (!R.has(detailKey, optionDetail.current)) {
                rowData.loading = true;
                if (dataTableNode) {
                  dataTableNode.forceUpdate();
                  setState({ refresh: true });
                }
                getOptionDetail(rowData, detailKey, () => {
                  rowData.loading = false;
                  if (dataTableNode) {
                    dataTableNode.forceUpdate();
                    setState({ refresh: true });
                  }
                });
              } else {
                setState({ refresh: true });
              }
            }
          }}
        >
          <div
            className="flex-row flex-center-align"
            style={{ wordWrap: 'break-word', width: 'fit-content', maxWidth: 270 }}
          >
            {typeName === 'incidents' && patternIcon}
            <div className="overflow-hidden">
              <div className="hidden-line-with-ellipsis">{`[${patternId}]`}</div>
              <div className="hidden-line-with-ellipsis">{patternName || patternId}</div>
            </div>
          </div>
        </Popover>
      );
    };

    const mouseOverTableRow = ({ event, index, rowData }, type) => {
      if (echartRef) {
        const detailKey = `${rowData.instanceName}-${rowData.patternId}-${rowData.count}`;
        const { data } = pieOption?.series[0] || [];
        const dataIndex = R.findIndex(
          (item) => `${item.instanceName}-${item.patternId}-${item.count}` === detailKey,
          data,
        );
        const echartsInstance = echartRef.getEchartsInstance();
        echartsInstance.dispatchAction({ type: type === 'over' ? 'highlight' : 'downplay', seriesIndex: 0, dataIndex });
      }
      if (logEchartRef) {
        const detailKey = `${rowData.instanceName}-${rowData.patternId}-${rowData.count}`;
        const { data } = logPieOption?.series[0] || [];
        const dataIndex = R.findIndex(
          (item) => `${item.instanceName}-${item.patternId}-${item.count}` === detailKey,
          data,
        );
        const echartsInstance = logEchartRef.getEchartsInstance();
        echartsInstance.dispatchAction({ type: type === 'over' ? 'highlight' : 'downplay', seriesIndex: 0, dataIndex });
      }
    };

    const jumpToInvestigation = (rowData) => {
      if (query?.saveFlag === 'true') {
        message.warning(intl.formatMessage(eventMessages.notSaveDashboardMessage));
        return;
      }

      const { dataType, componentName, instanceName, patternId } = rowData;
      const jumpParams = {
        // set redirect flag
        redirect: true,

        environmentId,
        startTime,
        endTime,
        customerName,
        systemId,

        eventCategory: typeName === 'incidents' ? 'incident' : dataType ? 'loganomaly' : 'metric',
        jumpComponentName: componentName,
        jumpInstanceName: instanceName,
        jumpPatternId: patternId,
      };

      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, jumpParams), '_blank');
    };

    const detailsRender = ({ rowData }) => {
      return (
        <Button size="small" onClick={() => jumpToInvestigation(rowData)}>
          {intl.formatMessage(appFieldsMessages.details)}
        </Button>
      );
    };

    const TableRender = useMemo(
      () => () => {
        let filterList = R.filter(
          (item) => (tabsKey === 'metric' ? item.dataType === 0 : item.dataType === 1),
          newList || [],
        );
        if (sortBy) {
          if (sortDirection === SortDirection.DESC) {
            filterList = R.sortWith([R.descend(R.prop(sortBy))])(filterList);
          } else {
            filterList = R.sortWith([R.ascend(R.prop(sortBy))])(filterList);
          }
        }
        return (
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={440}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={50}
                rowCount={filterList.length}
                rowGetter={({ index }) => filterList[index]}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                ref={(dataTable) => {
                  dataTableNode = dataTable;
                }}
                onRowMouseOver={({ event, index, rowData }) => {
                  mouseOverTableRow({ event, index, rowData }, 'over');
                }}
                onRowMouseOut={({ event, index, rowData }) => {
                  mouseOverTableRow({ event, index, rowData }, 'out');
                }}
              >
                <Column
                  width={150}
                  flexGrow={1}
                  label={intl.formatMessage(appFieldsMessages.patternIdName)}
                  dataKey="patternId"
                  headerRenderer={headerRenderer}
                  cellRenderer={patternIdRender}
                  flexShrink={0}
                />
                <Column
                  width={220}
                  label={`${intl.formatMessage(eventActionMessages.componentName)}/${intl.formatMessage(
                    appFieldsMessages.instanceName,
                  )}`}
                  dataKey="componentName"
                  headerRenderer={headerRenderer}
                  cellRenderer={instanceRender}
                />
                <Column
                  width={120}
                  label={tabsKey === 'metric' ? 'Duration (min)' : 'Log entry count'}
                  dataKey="count"
                  headerRenderer={headerRenderer}
                  flexShrink={0}
                />
                <Column width={80} label="" dataKey="button" cellRenderer={detailsRender} />
              </Table>
            )}
          </AutoSizer>
        );
      },
      [tabsKey, sortBy, sortDirection],
    );

    return (
      <Modal
        title={title}
        width={980}
        visible
        onCancel={onCancel}
        maskClosable={false}
        footer={null}
        className="dash-cell-detail-modal"
      >
        <Container className="full-width full-height flex-row">
          <div className="cell-detail-wrapper" style={{ height: 500 }}>
            <div style={{ textAlign: 'center' }}>{tabsKey === 'metric' ? 'Metric' : 'Log'}</div>
            {tabsKey === 'metric' && (
              <EChart
                setRef={(chart) => {
                  echartRef = chart;
                }}
                option={pieOption}
                className="chart-pie"
                style={{
                  width: '230px',
                  height: '230px',
                  top: 0,
                  right: 0,
                  left: 0,
                }}
              />
            )}
            {tabsKey === 'log' && (
              <EChart
                setRef={(chart) => {
                  logEchartRef = chart;
                }}
                option={logPieOption}
                className="chart-pie"
                style={{
                  width: '230px',
                  height: '230px',
                  top: 0,
                  right: 0,
                  left: 0,
                }}
              />
            )}
          </div>
          <Tabs type="card" activeKey={tabsKey} onChange={(tabsKey) => setState({ tabsKey })} className="flex-grow">
            <Tabs.TabPane tab="Metric" key="metric">
              {TableRender()}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Log" key="log">
              {TableRender()}
            </Tabs.TabPane>
          </Tabs>
        </Container>
      </Modal>
    );
  },
);

export default DashCellDetail;
