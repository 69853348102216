import React from 'react';
import * as R from 'ramda';
import momenttz from 'moment-timezone';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { replace } from 'react-router-redux';
import { message, Form, Input, Select, Alert } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../Constants';
import { createSetAction, updateLastActionInfo, setDefaultTimezone, ActionTypes } from '../../../common/app/actions';
import { loginSuccess } from '../../../common/auth/actions';
import { Modal } from '../../../lib/fui/react';
import { Defaults, Regex, buildUrl } from '../../../common/utils';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { authMessages } from '../../../common/auth/messages';
import { shouldRunAwesomeTours } from '../../../common/auth/logic/utils';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createSetAction: Function,
  updateLastActionInfo: Function,
  loginSuccess: Function,
  setDefaultTimezone: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,

  // eslint-disable-next-line
  needInputUserMissingInfo: Boolean,
  // eslint-disable-next-line
  userMissingInfo: Object,
};

class UserMissingInfoCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { userMissingInfo } = props;

    this.state = {
      ...(userMissingInfo || {}),

      isLoading: false,
      errMsg: null,
    };
    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleSubmit() {
    const { intl, credentials, userInfo, createSetAction, loginSuccess, setDefaultTimezone } = this.props;
    const { firstName, lastName, email, companyName, phone, timezone } = this.state;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('submit-missing-info'), {
      ...credentials,
      firstName,
      lastName,
      email,
      companyName,
      phone,
      timezone,
    })
      .then((result) => {
        const { success, message: errMsg } = result;
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          loginSuccess(credentials, {
            ...userInfo,
            fullName: `${firstName} ${lastName}`,
            firstName,
            lastName,
            email,
            companyName,
            phone,
          });
          setDefaultTimezone(timezone);
          this.setState({ isLoading: false, errMsg: null });

          createSetAction(ActionTypes.SET_USER_MISSING_INFO, {}, { needInputUserMissingInfo: false });
          if (userInfo.isFirstLogin) {
            createSetAction(
              ActionTypes.SET_AWESOME_TOURS,
              {},
              {
                runAwesomeTours: shouldRunAwesomeTours(userInfo.isFirstLogin),
                toursState: {
                  stepIndex: 0,
                  url: buildUrl(
                    BaseUrls.GlobalHealth,
                    {},
                    { startTime: Defaults.demoDay(), endTime: Defaults.demoDay(), customerName: 'demoUser' },
                  ),
                },
              },
            );
          }
        } else {
          this.setState({ isLoading: false, errMsg });
        }
      })
      .catch((err) => {
        // message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, errMsg: err.message || String(err) });
      });
  }

  render() {
    const { intl } = this.props;
    const { isLoading, errMsg } = this.state;
    const { username, firstName, lastName, emailEditable, email, companyName, phone, timezone } = this.state;

    const hasErrEmail = !email || !Regex.email.test(email);
    const hasErrPhone = !phone || !Regex.phone.test(phone);
    const hasError = !firstName || !lastName || hasErrEmail || !companyName || hasErrPhone || !timezone;
    return (
      <Modal
        title={intl.formatMessage(appMessages.updateUserInfo, { userName: username })}
        width={750}
        visible
        keyboard={false}
        closable={false}
        maskClosable={false}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: hasError, loading: isLoading }}
        cancelButtonProps={{ disabled: true }}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.fname)}
            style={{ marginBottom: 12 }}
            validateStatus={!firstName ? 'error' : 'success'}
            help={!firstName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input value={firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.lname)}
            style={{ marginBottom: 12 }}
            validateStatus={!lastName ? 'error' : 'success'}
            help={!lastName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input value={lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.email)}
            style={{ marginBottom: 12 }}
            validateStatus={hasErrEmail ? 'error' : 'success'}
            help={hasErrEmail ? intl.formatMessage(authMessages.errorsEmailIncorrect) : undefined}
            required
          >
            <Input value={email} onChange={(e) => this.setState({ email: e.target.value })} disabled={!emailEditable} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.companyName)}
            style={{ marginBottom: 12 }}
            validateStatus={!companyName ? 'error' : 'success'}
            help={!companyName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input value={companyName} onChange={(e) => this.setState({ companyName: e.target.value })} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.phoneNumber)}
            style={{ marginBottom: 12 }}
            validateStatus={hasErrPhone ? 'error' : 'success'}
            help={hasErrPhone ? intl.formatMessage(authMessages.errorsPhoneIncorrect) : undefined}
            required
          >
            <Input value={phone} onChange={(e) => this.setState({ phone: e.target.value })} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.defaultTimezone)}
            style={{ marginBottom: 12 }}
            validateStatus={!timezone ? 'error' : 'success'}
            help={!timezone ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Select
              showSearch
              style={{ width: 200 }}
              filterOption
              options={this.timezoneOptions}
              value={timezone}
              onChange={(timezone) => this.setState({ timezone })}
            />
          </Form.Item>
          {errMsg && (
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 },
              }}
              style={{ margin: 0 }}
            >
              <Alert message={errMsg} type="error" showIcon />
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

const UserMissingInfo = injectIntl(UserMissingInfoCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { needInputUserMissingInfo, userMissingInfo } = state.app;
    return {
      location,
      userInfo,
      credentials,
      needInputUserMissingInfo,
      userMissingInfo,
    };
  },
  { replace, createSetAction, updateLastActionInfo, loginSuccess, setDefaultTimezone },
)(UserMissingInfo);
