import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseTimelines } from './parsers';

const getIncidentPredictionTimelines = (credentials: Credentials, params: Object) => {
  const {
    level,
    customerName,
    environmentId,
    startTimestamps,
    startTime,
    endTime,
    fromJobAnalysis,
    ignoreFirstDayData,
    systemId,
    systemInfoMap,

    timezoneOffset,
  } = params;
  const nowCustomerTimestamp = moment.utc().valueOf() + timezoneOffset * 60000;

  const requests = [];
  R.forEach((startTimestamp) => {
    requests.push(
      fetchGet(getEndpoint('incidentpredictiontimelines', 2), {
        ...credentials,
        environmentName: environmentId,
        systemIds: JSON.stringify([{ id: systemId }]),
        endTime,
        customerName,
        startTime: fromJobAnalysis ? startTime : startTimestamp,
      }).catch((e) => {
        console.warn('[IF_API] failed to get all system timelines', e);
        return [];
      }),
    );
  }, startTimestamps);

  const timelinesMap = {};

  return Promise.all(requests).then((results) => {
    R.addIndex(R.forEach)((result, idx) => {
      const timeline = result[0];
      const { projectNameSet, projectDisplayMap } = get(systemInfoMap, [timeline.id], {});
      parseTimelines({
        timeline,
        timelinesMap,
        projectNameSet,
        projectDisplayMap,
        notInclude: ignoreFirstDayData && idx === 0,
      });
    }, R.slice(0, startTimestamps.length, results));

    // build timelines
    const systemTimelinesMap = {};
    R.forEachObjIndexed((valList, id) => {
      const newValList = R.filter((item) => !item.notInclude, valList);
      R.forEach((item) => {
        const { systemTimeline } = item;
        if (!R.has(id, systemTimelinesMap)) {
          systemTimelinesMap[id] = systemTimeline;
        } else {
          systemTimelinesMap[id] = {
            ...systemTimeline,
            anomalyTimelines: [...systemTimelinesMap[id].anomalyTimelines, ...systemTimeline.anomalyTimelines],
          };
        }
      }, newValList);
    }, timelinesMap);
    const timelines = R.map((timeline) => ({ ...timeline, level }), R.values(systemTimelinesMap));

    // add triggeredAction info
    const globalSystemInfo = timelines[0];
    let { anomalyTimelines } = globalSystemInfo || {};
    anomalyTimelines = R.map((event) => {
      const predictionTime = event.predictionTimeStamp;
      const consolidatedTimePair = event.consolidatedTimePair || [];
      const isFuture = R.reduce(
        R.or,
        false,
        R.map((item) => item.predictionOccurrence >= nowCustomerTimestamp, consolidatedTimePair),
      );
      if (consolidatedTimePair.length === 0) {
        consolidatedTimePair.push({
          predictionTime,
          predictionOccurrence: event.startTimestamp,
        });
      }
      return {
        ...event,
        predictionTime,
        consolidatedTimePair,
      };
    }, anomalyTimelines || []);

    return {
      globalSystemInfo: {
        ...globalSystemInfo,
        anomalyTimelines,
      },
    };
  });
};

export default getIncidentPredictionTimelines;
