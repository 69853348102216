import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { message } from 'antd';
import TrendsChart from '../components/TrendsChart';
import { Defaults } from '../../../common/utils';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { getCacheData, setCacheData } from '../utils';

export default function SystemIncidents(props: Object) {
  const { startTime, endTime, systemInfo, credentials, systemId, systemIdsWithShare } = props;
  const [systemScoreList, setSystemScoreList] = useState([]);

  const reloadData = async () => {
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    const newStartTimestamp = moment.utc(startTimestamp).subtract(7, 'days').valueOf();
    const newEndTimestamp = moment.utc(endTimestamp).add(7, 'days').valueOf();
    const filterSystem = R.filter((item) => item.id === systemId, systemIdsWithShare || []);
    const params = {
      ...credentials,
      systemIdsWithShare: JSON.stringify(filterSystem),
      startTime: newStartTimestamp,
      endTime: newEndTimestamp,
      cacheKey: 'system-daily-info',
    };
    const apiData = getCacheData(params);
    if (apiData) {
      setSystemScoreList(apiData);
      return;
    }

    fetchGet(getEndpoint('system-daily-info'), R.clone(params))
      .then((data) => {
        const { result, success, message: msg } = data;
        if (success || success === undefined) {
          const systemScoreList = R.filter((item) => item.systemKey === systemId, result || []);
          setCacheData(params, systemScoreList);
          setSystemScoreList(systemScoreList);
        } else {
          setCacheData(params, []);
          setSystemScoreList([]);
        }
      })
      .catch((e) => {
        console.error(e.message || String(e));
      });
  };

  useEffect(() => {
    reloadData();
  }, [systemInfo, systemId]);
  return (
    <TrendsChart
      {...props}
      systemScoreList={systemScoreList}
      cacheKey="systemIncidents"
      title="Incidents"
      subTitle="system"
    />
  );
}
