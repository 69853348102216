/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { showAppAlert, updateLastActionInfo } from '../../app/actions';
import { deleteBugIssue } from '../../apis';
import { loadBugRepository } from '../actions';
import { usecaseMessages } from '../messages';
import { apiEpicErrorHandle } from '../../errors';

const removeBugIssueEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('REMOVE_BUG_ISSUE').concatMap((action) => {
    const state = getState();

    const { credentials } = state.auth;
    const params = action.payload;

    return Observable.concat(
      Observable.of(updateLastActionInfo()),
      Observable.from(deleteBugIssue(credentials, params))
        .concatMap(() => {
          return Observable.concat(Observable.of(showAppAlert('info', usecaseMessages.infoBugIssueRemoved)));
        })
        .catch((err) => {
          return apiEpicErrorHandle(err);
        }),
      Observable.of(loadBugRepository()),
    );
  });

export default removeBugIssueEpic;
