import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Checkbox, Divider, message, Select, Spin } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { State } from '../../../../common/types';
import { Container, Popover } from '../../../../lib/fui/react';
import { sleep } from '../../../../common/utils';

import { eventMessages } from '../../../../common/metric/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

function LogToMetricBaseValueCore({ ...props }: Object) {
  const { intl, projectName, credentials, baseValueSetting, projects, userInfo, saveProjectSettings } = props;
  const { currentProject, settingsLoader } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    fieldNameLoading: false,
    baseValueLoading: false,

    fieldNameOptions: [],
    isSourceProject: false,
    mappingKeys: [],
    baseValueKeys: [],
    targetProject: '',
    metricProjects: [],
    additionalMetricNames: [],

    metricProjectOptions: [],
    logProjectOptions: [],

    searchValue: '',
  });
  const { fieldNameLoading, baseValueLoading, isSourceProject, targetProject, metricProjects } = state;
  const { metricProjectOptions, logProjectOptions, mappingKeys, baseValueKeys, searchValue } = state;
  const { additionalMetricNames } = state;

  const getLogJsonType = () => {
    setState({ fieldNameLoading: true });
    updateLastActionInfo();
    return fetchGet(getEndpoint('logjsontype'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          const fieldNameOptions = R.map((item) => ({ value: item.jsonKey, label: item.jsonKey }), data || []);
          setState({ fieldNameOptions, fieldNameLoading: false });
        } else {
          setState({ fieldNameLoading: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        setState({ fieldNameLoading: false });
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  };

  useEffect(() => {
    getLogJsonType();
  }, []);

  const reloadData = async () => {
    setState({ baseValueLoading: true });

    await sleep(500);
    const logProject = R.find((item) => item.projectName === projectName, projects || []);
    const { owner } = logProject || {};

    let metricProjects = R.filter((item) => item.isMetric, projects || []);
    let logProjects = R.filter((item) => !item.isMetric, projects || []);
    if (owner) {
      metricProjects = R.filter((item) => item.owner === owner, metricProjects || []);
      logProjects = R.filter((item) => item.owner === owner, logProjects || []);
    }
    const metricProjectOptions = R.map(
      (item) => ({
        value: item.projectShortName,
        label: `${item.projectDisplayName}${
          userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName ? `@${item.owner}` : ''
        }`,
      }),
      metricProjects,
    );
    const logProjectOptions = R.map(
      (item) => ({
        value: item.projectShortName,
        label: `${item.projectDisplayName}${
          userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName ? `@${item.owner}` : ''
        }`,
      }),
      logProjects,
    );

    setState({ ...baseValueSetting, metricProjectOptions, logProjectOptions, baseValueLoading: false });
  };

  useEffect(() => {
    reloadData();
  }, [baseValueSetting, projects, userInfo]);

  const getListOptionValues = (option) => {
    return R.map((item) => item.value, option);
  };

  const getFilterValue = ({ searchValue, options, type = 'label' }) => {
    return R.filter(
      (item) => (item[type] || '').toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1,
      options,
    );
  };

  const isCheckedAll = ({ searchValue, options, valueList }) => {
    const filterValue = getListOptionValues(getFilterValue({ searchValue, options }));
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  };

  const tagsSelect = (selectKey, optionKey) => {
    let newOption = [];
    R.forEach((item) => {
      const option = R.find((_item) => _item.value === item, state[optionKey]);
      if (!option) newOption.push({ label: item, value: item });
    }, state[selectKey]);
    newOption = [...newOption, ...state[optionKey]];

    const allOptionContents = R.map((item) => item.value, newOption || []);
    const showSearchOption = searchValue && !R.includes(searchValue, allOptionContents);
    if (showSearchOption) newOption = [{ label: searchValue, value: searchValue }, ...newOption];
    return (
      <Select
        allowClear
        size="small"
        mode="multiple"
        style={{ width: 500 }}
        autoClearSearchValue={false}
        optionFilterProp="children"
        optionLabelProp="value"
        className="full-width no-count-num-row"
        value={state[selectKey]}
        onChange={(value) => setState({ [selectKey]: value })}
        onDropdownVisibleChange={(open) => {
          if (!open) setState({ searchValue: '' });
        }}
        onSearch={(searchValue) => setState({ searchValue })}
        dropdownRender={(menu) => {
          return (
            <div>
              <div
                className="flex-row flex-center-align"
                style={{ padding: '5px 12px' }}
                onMouseDown={(event) => event.preventDefault()}
              >
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={isCheckedAll({ searchValue, options: newOption, valueList: state[selectKey] })}
                  onChange={(e) => {
                    const { checked } = e.target;
                    const filterValue = getListOptionValues(getFilterValue({ searchValue, options: newOption }));
                    const diff = R.difference(state[selectKey], filterValue);
                    setState({ [selectKey]: checked ? [...diff, ...filterValue] : diff });
                  }}
                />
                <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
              </div>
              <Divider style={{ margin: '4px 0' }} />
              {menu}
            </div>
          );
        }}
      >
        {R.map((item) => {
          return (
            <Select.Option className="hide-icon" key={item.value} value={item.value}>
              <Checkbox
                checked={state[selectKey].includes(item.value)}
                onChange={(e) => null}
                style={{ marginRight: 8 }}
              />
              {item.label}
            </Select.Option>
          );
        }, newOption)}
      </Select>
    );
  };

  const handleSave = () => {
    const { projectShortName, owner } = currentProject;

    const settings = {
      baseValueSetting: {
        isSourceProject,
        mappingKeys,
        baseValueKeys,
        targetProject,
        metricProjects,
        additionalMetricNames,
      },
    };

    saveProjectSettings(
      projectName.indexOf('@') >= 0 ? projectName : `${projectShortName}@${owner}`,
      { ...settings, notPostHasSetData: true },
      {
        [settingsLoader]: true,
      },
    );
  };

  return (
    <Container className={`full-height flex-min-width flex-min-height flex-col `}>
      <Spin spinning={fieldNameLoading || baseValueLoading}>
        <div className="flex-row flex-center-align" style={{ marginBottom: 24 }}>
          <div className="font-14">{intl.formatMessage(settingsMessages.isSourceProject)}:</div>
          <Checkbox
            checked={isSourceProject}
            style={{ marginLeft: 8 }}
            onChange={({ target: { checked: isSourceProject } }) => setState({ isSourceProject })}
          />
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="font-14">{intl.formatMessage(settingsMessages.mappingKeys)}:</div>
          {tagsSelect('mappingKeys', 'fieldNameOptions')}
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="font-14">{intl.formatMessage(settingsMessages.baseValueKeys)}:</div>
          {tagsSelect('baseValueKeys', 'fieldNameOptions')}
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="font-14">{intl.formatMessage(settingsMessages.additionalMetricName)}:</div>
          {tagsSelect('additionalMetricNames', 'fieldNameOptions')}
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="font-14">{intl.formatMessage(settingsMessages.targetProject)}:</div>
          <Select
            showSearch
            size="small"
            style={{ width: 500 }}
            className="full-width"
            value={targetProject}
            options={logProjectOptions}
            onChange={(targetProject) => setState({ targetProject })}
          />
        </div>
        <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
          <div className="font-14">{intl.formatMessage(settingsMessages.metricProjects)}:</div>
          <Select
            allowClear
            size="small"
            mode="multiple"
            style={{ width: 500 }}
            autoClearSearchValue={false}
            optionFilterProp="children"
            optionLabelProp="value"
            className="full-width no-count-num-row"
            value={metricProjects}
            onChange={(metricProjects) => setState({ metricProjects })}
            onDropdownVisibleChange={(open) => {
              if (!open) setState({ searchValue: '' });
            }}
            onSearch={(searchValue) => setState({ searchValue })}
            dropdownRender={(menu) => {
              return (
                <div>
                  <div
                    className="flex-row"
                    style={{ padding: '5px 12px' }}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={isCheckedAll({ searchValue, options: metricProjectOptions, valueList: metricProjects })}
                      onChange={(e) => {
                        const { checked } = e.target;
                        const filterValue = getListOptionValues(
                          getFilterValue({ searchValue, options: metricProjectOptions }),
                        );
                        const diff = R.difference(metricProjects, filterValue);
                        setState({ metricProjects: checked ? [...diff, ...filterValue] : diff });
                      }}
                    />
                    <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                  </div>
                  <Divider style={{ margin: '4px 0' }} />
                  {menu}
                </div>
              );
            }}
          >
            {R.map((item) => {
              return (
                <Select.Option className="hide-icon" key={item.value} value={item.value}>
                  <Checkbox
                    checked={metricProjects.includes(item.value)}
                    onChange={(e) => null}
                    style={{ marginRight: 8 }}
                  />
                  {item.label}
                </Select.Option>
              );
            }, metricProjectOptions)}
          </Select>
        </div>
        <div>
          <Popover
            content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
            mouseEnterDelay={0.3}
            placement="left"
          >
            <Button size="small" type="primary" disabled={userInfo.isReadUser} onClick={handleSave}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Popover>
        </div>
      </Spin>
    </Container>
  );
}

const LogToMetricBaseValue = injectIntl(LogToMetricBaseValueCore);
export default connect(
  (state: State) => {
    const { projects } = state.app;
    const { credentials, userInfo } = state.auth;
    const { projectSettings } = state.settings;
    const { baseValueSetting } = projectSettings;

    return { credentials, userInfo, projects, baseValueSetting };
  },
  {
    updateLastActionInfo,
  },
)(LogToMetricBaseValue);
