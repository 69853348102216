/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import Papa from 'papaparse';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import VLink from 'valuelink';

import { Button } from 'antd';
import { Modal, Select, Input } from '../../../../lib/fui/react';
import { Constants, downloadFile } from '../../../../common/utils';

import { appButtonsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  onClose: Function,
};

class GroupFileModal extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      fileType: '',
      csvFileData: null,
      instanceGroupList: null,
      csvInstanceName: null,
      csvMetricInstanceName: null,
      csvAppName: null,
      csvIpAddress: null,
      csvZone: null,
      csvGroupList: [],
      groupSeperator: '-',
      csvGroupName: '',
      filename: null,
    };
  }

  @autobind
  handleSumbit() {
    this.setState(
      { csvFileData: null, filename: null, csvGroupName: null, csvGroupList: [], errorMessage: null },
      () => {
        this.props.onClose(true, this.state.instanceGroupList, true);
      },
    );
  }

  @autobind
  saveFileUploadNode(node) {
    this.fileUploadNode = node;
  }

  @autobind
  handleFileChanged(e) {
    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const { type, name: filename } = file;
      if (type === 'application/json' || filename.indexOf('.json') >= 0) {
        const reader = new FileReader();
        reader.onload = (env) => {
          const instanceGroupList = [];
          const json = JSON.parse(env.target.result || '{}');
          R.forEachObjIndexed((val, instanceGroup) => {
            R.forEach((instanceObj) => {
              instanceGroupList.push({
                instanceGroup,
                instanceName: instanceObj.instanceName,
                metricInstanceName: instanceObj.metricInstanceName,
                appName: instanceObj.componentName,
                ipAddress: instanceObj.ipAddress,
                zone: instanceObj.zone,
              });
            }, val || []);
          }, json);

          this.setState({ filename, fileType: 'json', instanceGroupList });
        };
        reader.readAsText(file);
      } else if (type === 'text/csv' || filename.indexOf('.csv') >= 0) {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            this.setState({
              filename,
              fileType: 'csv',
              csvFileData: result,
              instanceGroupList: null,
              csvInstanceName: null,
              csvMetricInstanceName: null,
              csvAppName: null,
              csvIpAddress: null,
              csvZone: null,
              csvGroupList: [],
              csvGroupName: '',
              groupSeperator: '-',
            });
          },
        });
      } else {
        this.setState({ errorMessage: 'The file format is incorrect.', instanceGroupList: null, fileType: null });
      }
    }
  }

  @autobind
  handleCsvGroupListChange(groupList) {
    const { csvInstanceName, csvMetricInstanceName, csvAppName, csvIpAddress, csvZone, groupSeperator, csvFileData } =
      this.state;
    const csvGroupList = groupList || [];

    const data = get(csvFileData, Constants.PapaData, []);
    let instanceGroupList = null;
    if (csvInstanceName && (csvGroupList || []).length > 0) {
      instanceGroupList = [];
      R.forEach((d) => {
        const instanceGroup = R.props(csvGroupList, d).join(groupSeperator);
        const instanceName = d[csvInstanceName];
        const metricInstanceName = d[csvMetricInstanceName];
        const appName = d[csvAppName];
        const ipAddress = d[csvIpAddress];
        const zone = d[csvZone];
        if (instanceName && instanceGroup) {
          instanceGroupList.push({ instanceGroup, instanceName, metricInstanceName, appName, ipAddress, zone });
        }
      }, data);
    }

    this.setState({
      instanceGroupList,
      csvGroupName: (csvGroupList || []).join(groupSeperator),
      csvGroupList,
    });
  }

  @autobind
  handleCsvGroupSeperatorChange(seperator) {
    const { csvInstanceName, csvMetricInstanceName, csvAppName, csvIpAddress, csvZone, csvGroupList, csvFileData } =
      this.state;
    const groupSeperator = seperator || '';

    const data = get(csvFileData, Constants.PapaData, []);
    let instanceGroupList = null;
    if (csvInstanceName && (csvGroupList || []).length > 0) {
      instanceGroupList = [];
      R.forEach((d) => {
        const instanceGroup = R.props(csvGroupList, d).join(groupSeperator);
        const instanceName = d[csvInstanceName];
        const metricInstanceName = d[csvMetricInstanceName];
        const appName = d[csvAppName];
        const ipAddress = d[csvIpAddress];
        const zone = d[csvZone];
        if (instanceName && instanceGroup) {
          instanceGroupList.push({ instanceGroup, instanceName, metricInstanceName, appName, ipAddress, zone });
        }
      }, data);
    }

    this.setState({
      instanceGroupList,
      groupSeperator,
      csvGroupName: (csvGroupList || []).join(seperator),
    });
  }

  @autobind
  handleSampleFile() {
    const sampleData = JSON.stringify({
      All: [
        {
          metricInstanceName: '',
          zone: '',
          instanceName: '',
          componentName: '',
        },
      ],
    });
    downloadFile(sampleData, 'sampleFile.json', 'text/json');
  }

  render() {
    const { intl, onClose } = this.props;
    const { errorMessage, filename, instanceGroupList } = this.state;
    const { fileType, csvFileData } = this.state;
    const hasJsonFile = fileType === 'json';
    const hasCsvFile = fileType === 'csv';
    const hasGroupSettings = Boolean(instanceGroupList) && instanceGroupList.length > 0;
    const hasError = Boolean(errorMessage) || !hasGroupSettings;

    let csvColumnOptions = [];
    if (hasCsvFile) {
      csvColumnOptions = R.map(
        (n) => ({ label: n, value: n }),
        R.uniq(R.filter((n) => Boolean((n || '').trim()), get(csvFileData, Constants.PapaFields, []))),
      );
    }
    const csvInstanceNameLink = VLink.state(this, 'csvInstanceName').check(
      (x) => Boolean(x),
      'Instance column is required',
    );
    const csvZoneLink = VLink.state(this, 'csvZone');
    const csvAppNameLink = VLink.state(this, 'csvAppName');
    const csvIpAddressLink = VLink.state(this, 'csvIpAddress');
    const csvMetricInstanceNameLink = VLink.state(this, 'csvMetricInstanceName');
    const csvGroupListLink = VLink.state(this, 'csvGroupList')
      .check((x) => Boolean(x) && x.length > 0, 'Select at least one group column')
      .onChange(this.handleCsvGroupListChange);
    const groupSeperatorLink = VLink.state(this, 'groupSeperator')
      .check((x) => Boolean(x), 'seperator is required')
      .onChange(this.handleCsvGroupSeperatorChange);
    const csvGroupNameLink = VLink.state(this, 'csvGroupName');

    return (
      <Modal
        title="Upload instance component name file"
        width={850}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSumbit}
        okButtonProps={{ disabled: hasError }}
      >
        <div className="content overflow-y-auto overflow-x-hidden" style={{ maxHeight: 500 }}>
          {errorMessage && <div className="ui error message">{errorMessage}</div>}

          <div className="ui info message" style={{ padding: '4px 8px', marginTop: 0 }}>
            <i className="info icon" />
            Click <b>Select file</b> to choose a JSON or CSV file contains the instance grouping settings.
          </div>
          <div className="flex-row">
            <Button size="small" style={{ background: '#767676', color: '#fff', marginRight: 10 }}>
              <div className="ui fileinput-button" style={{ position: 'relative' }}>
                <input
                  ref={this.saveFileUploadNode}
                  type="file"
                  name="file"
                  accept=".csv,.json"
                  onChange={this.handleFileChanged}
                />
                {intl.formatMessage(appButtonsMessages.selectFile)}
              </div>
            </Button>

            <Button size="small" onClick={this.handleSampleFile}>
              {intl.formatMessage(appButtonsMessages.sampleFile)}
            </Button>
            <span className="text-blue flex-grow" style={{ margin: 'auto 0.5em', wordBreak: 'break-all' }}>
              {filename}
            </span>
          </div>

          {hasCsvFile && csvColumnOptions.length > 0 && (
            <div style={{ margin: '8px 0' }}>
              <div
                style={{ margin: '8px 0' }}
                dangerouslySetInnerHTML={{ __html: 'Select CSV columns as the instance and group name' }}
              />
              <div className="flex-row">
                <label style={{ width: 150, fontWeight: 'bold', margin: 'auto 0' }}>
                  <span style={{ color: 'red' }}>*</span> Instance Column:
                </label>
                <Select
                  name="instance"
                  options={csvColumnOptions}
                  valueLink={csvInstanceNameLink}
                  style={{ width: 258 }}
                />
                <label style={{ width: 150, fontWeight: 'bold', margin: 'auto 0 auto 16px' }}>Group Seperator:</label>
                <Input name="group" valueLink={groupSeperatorLink} style={{ width: 100 }} />
              </div>
              <div className="flex-row" style={{ marginTop: 20 }}>
                <label style={{ width: 150, fontWeight: 'bold', margin: 'auto 0' }}>
                  <span style={{ color: 'red' }}>*</span> MetricInstanceName Column:
                </label>
                <Select
                  multi
                  name="zone"
                  options={csvColumnOptions}
                  valueLink={csvMetricInstanceNameLink}
                  style={{ width: 486 }}
                />
              </div>
              <div className="flex-row" style={{ marginTop: 20 }}>
                <label style={{ width: 150, fontWeight: 'bold', margin: 'auto 0' }}>
                  <span style={{ color: 'red' }}>*</span> AppName Column:
                </label>
                <Select
                  multi
                  name="zone"
                  options={csvColumnOptions}
                  valueLink={csvAppNameLink}
                  style={{ width: 486 }}
                />
              </div>
              <div className="flex-row" style={{ marginTop: 20 }}>
                <label style={{ width: 150, fontWeight: 'bold', margin: 'auto 0' }}>
                  <span style={{ color: 'red' }}>*</span> Group Columns:
                </label>
                <Select
                  multi
                  name="group"
                  options={csvColumnOptions}
                  valueLink={csvGroupListLink}
                  style={{ width: 486 }}
                />
              </div>
              <div className="flex-row" style={{ marginTop: 20 }}>
                <label style={{ width: 150, fontWeight: 'bold', margin: 'auto 0' }}>Group Name:</label>
                <Input name="groupName" disabled valueLink={csvGroupNameLink} style={{ width: 486 }} />
              </div>

              <div className="flex-row" style={{ marginTop: 20 }}>
                <label style={{ width: 150, fontWeight: 'bold', margin: 'auto 0' }}>IP Address Column:</label>
                <Select
                  multi
                  name="zone"
                  options={csvColumnOptions}
                  valueLink={csvIpAddressLink}
                  style={{ width: 486 }}
                />
              </div>
              <div className="flex-row" style={{ marginTop: 20 }}>
                <label style={{ width: 150, fontWeight: 'bold', margin: 'auto 0' }}>Zone Column:</label>
                <Select multi name="zone" options={csvColumnOptions} valueLink={csvZoneLink} style={{ width: 486 }} />
              </div>
            </div>
          )}
          {hasGroupSettings && instanceGroupList.length > 0 && (
            <div
              style={{ margin: '8px 0' }}
              dangerouslySetInnerHTML={{
                __html: `
The file contains <b>${instanceGroupList.length}</b> instance grouping settings, <b>Replace</b> with the current settings.`,
              }}
            />
          )}
          {!hasGroupSettings && hasJsonFile && (
            <div
              style={{ margin: '8px 0' }}
              dangerouslySetInnerHTML={{
                __html:
                  'The JSON file doesn\'t contains instance grouping settings, the format is like { groupA: "instanceA, instanceB"}.',
              }}
            />
          )}
          {hasCsvFile && csvColumnOptions.length === 0 && (
            <div
              style={{ margin: '8px 0' }}
              dangerouslySetInnerHTML={{ __html: "The CSV file doesn't contains any headers" }}
            />
          )}
          {hasCsvFile && !hasGroupSettings && (
            <div
              style={{ margin: '8px 0' }}
              dangerouslySetInnerHTML={{
                __html:
                  "The CSV file doesn't contains instance grouping settings, change the columns or check the CSV file.",
              }}
            />
          )}
        </div>
      </Modal>
    );
  }
}

export default GroupFileModal;
