/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { get, isNumber, isEqual, isFunction, isArray, isNull } from 'lodash';
import { OrderedListOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Checkbox, Tooltip } from 'antd';

import Dygraph from '../../../dygraph';
import { buildUrl, parseLocation } from '../../../../common/utils';
import BaseUrls from '../../../../web/app/BaseUrls';

import { eventActionMessages, eventMessages } from '../../../../common/metric/messages';
import { State } from '../../../../common/types';
import getInstanceDisplayName from '../../../../common/utils/getInstanceDisplayName';

type Props = {
  className: string,
  style: Object,

  // eslint-disable-next-line
  width: any,
  // eslint-disable-next-line
  height: any,
  title: String,
  viewTitle: String,
  titlePostfix: String,
  data: any,
  labels: Object,
  predictionlabelMap: Object,
  highlightMap: Object,
  intl: Object,
  currentTimestamp: Number,
  // eslint-disable-next-line
  labelMapping: Function,
  // eslint-disable-next-line
  annotations: Array<Object>,
  // eslint-disable-next-line
  onCreateAnnotation: Function,

  labelsVisibility: Array,
  underlayCallback: Function,
  dateWindow: any,
  valueRange: any,
  onDateWindowChange: Function,
  selection: any,
  onSelectionChange: Function,
  highlightCallback: Function,
  drawCallback: Function,
  unhighlightCallback: Function,
  samplingInterval: Number,
  isCustomData: Boolean,
  showSeparateInstance: Boolean,
  onClickSeparateInstance: Function,
  onCheckMetric: Function,
  isDark: Boolean,
  projectName: String,
  owner: String,
  systemId: String,
  location: Object,
  userInfo: Object,
  instanceDisplayNameMap: Object,
};

const MaxDataPoints = 2016;

const financial = (x) => Number(Number.parseFloat(Number.parseFloat(x).toFixed(20)).toFixed(5));

class LineChartCore extends React.PureComponent {
  props: Props;

  static defaultProps = {
    // eslint-disable-next-line
    width: '100%',
    // eslint-disable-next-line
    height: 120 + 44 + 36 + 12,
    // eslint-disable-next-line
    labelMapping: (x) => x,
    // eslint-disable-next-line
    annotations: [],
    // eslint-disable-next-line
    onCreateAnnotation: () => null,
  };

  constructor(props) {
    super(props);

    this.currentDateWindow = [];
    this.currentSelectIdx = null;
    this.titleBarHeight = 48;
    this.annotationBarHeight = 12;
    this.legendBarHeight = 36;

    this.chartNode = null;
    this.legendNode = null;
    this.dygraph = null;
    this.rangeFillStyle = 'rgba(103, 141, 255, 0.3)';
    this.predicatedRangeFillStyle = 'rgba(28, 190, 203, 0.3)';
    this.timeFormat = 'YYYY-MM-DD HH:mm';

    this.dygraphOptions = {
      connectSeparatedPoints: false,
      includeZero: true,
      labelsUTC: true,
      axisLineColor: 'gray',
      axisLabelFontSize: 12,
      fillAlpha: 0.3,
      gridLineColor: 'gray',
      strokeWidth: 1.5,
      highlightSeriesOpts: { strokeWidth: 2 },
      highlightSeriesBackgroundAlpha: props.isDark ? 0.9 : 0.3,
      colors: ['#6abbf7'], // The color of the default line, overridden if any other color has a value

      // labels
      legend: 'follow',

      legendFormatter: this.legendFormatter,
      // hideOverlayOnMouseOut: false,
      // underlayCallback: this.handleUnderlayCallback,
      plotter: [Dygraph.Plotters.fillPlotter, Dygraph.Plotters.errorPlotter, this.handlePlotter],
      drawCallback: this.handleDrawCallback,
      highlightCallback: this.handleHighlightCallback,
      unhighlightCallback: this.handleUnhighlightCallback,
    };
    this.sdata = [];

    this.state = {
      checked: false,
    };
  }

  @autobind
  hostIDAndPodIDRender({ instanceId, component, hostID, podID }) {
    const { instanceDisplayNameMap } = this.props;
    const { instanceStr: viewInstance } = getInstanceDisplayName(instanceDisplayNameMap, instanceId);
    if (hostID && podID) {
      return (
        <>
          <div className="light-label inline-block" style={{ marginRight: 4 }}>
            Host ID:
          </div>
          <div className="label inline-block">{hostID}</div>
          <div className="light-label inline-block" style={{ marginRight: 4 }}>
            Pod ID:
          </div>
          <div className="label inline-block">{podID}</div>
        </>
      );
    }
    return (
      <span className="label">
        {component && component !== instanceId ? `${viewInstance} (${component})` : viewInstance}
      </span>
    );
  }

  @autobind
  legendNotHighlightFormatter({ serie, labelMapping, isHighlight, isCustomData, pointData, x }) {
    const { label } = serie;
    let { y } = serie;
    y = financial(y);
    const { instanceId, component, hostID, podID } = labelMapping(label, x);
    if (isCustomData) {
      const upper = financial(pointData?.[4] || pointData?.[2]);
      const lower = financial(pointData?.[3] || pointData?.[0]);

      return (
        <div key={label} className={isHighlight ? 'highlight' : ''}>
          {this.hostIDAndPodIDRender({ instanceId, component, hostID, podID })}
          <span className="value">
            {isNumber(y) ? numeral(y).format(y > 1 ? '0,0.[00]' : '0.[000000]') : 'NA'}
            <span className="light-label" style={{ display: 'inline', margin: '0 4px 0 8px' }}>
              UpperBound:
            </span>
            {isNumber(upper) ? numeral(upper).format(upper > 1 ? '0,0.[00]' : '0.[000000]') : 'NA'}
            <span className="light-label" style={{ display: 'inline', margin: '0 4px 0 8px' }}>
              LowerBound:
            </span>
            {isNumber(lower) ? numeral(lower).format(lower > 1 ? '0,0.[00]' : '0.[000000]') : 'NA'}
          </span>
        </div>
      );
    }
    return (
      <div key={label} className={isHighlight ? 'highlight' : ''}>
        {this.hostIDAndPodIDRender({ instanceId, component, hostID, podID })}
        <span className="value">{isNumber(y) ? numeral(y).format(y > 1 ? '0,0.[00]' : '0.[000000]') : 'NA'}</span>
      </div>
    );
  }

  @autobind
  legendFormatter(data) {
    const { x } = data;
    if (!x) return '';

    const { labelMapping, selection, isCustomData } = this.props;
    const highlightSerieIndex = R.findIndex((serie) => {
      const hname = selection && selection[1] ? selection[1] : null;
      return serie.label === hname || serie.isHighlighted;
    }, data.series || []);

    const labels = [];
    if (highlightSerieIndex >= 0) {
      const highlightSerie = data.series[highlightSerieIndex];

      const selectData = R.find((point) => point[0].valueOf() === x, get(data.dygraph, 'file_', []));
      const pointData = selectData[highlightSerieIndex + 1];
      labels.push(
        this.legendNotHighlightFormatter({
          serie: highlightSerie,
          labelMapping,
          isCustomData,
          pointData,
          isHighlight: true,
          x,
        }),
      );

      R.addIndex(R.forEach)(
        (serie, index) => {
          const pointDataOther = selectData[index + 1];
          labels.push(
            this.legendNotHighlightFormatter({ serie, labelMapping, isCustomData, pointData: pointDataOther, x }),
          );
        },
        R.filter((a) => a.isVisible && a.label !== highlightSerie.label, data.series || []),
      );
    } else {
      R.forEach(
        (serie) => {
          labels.push(this.legendNotHighlightFormatter({ serie, labelMapping, x }));
        },
        R.filter((a) => a.isVisible, data.series || []),
      );
    }

    return ReactDOMServer.renderToStaticMarkup(
      <>
        <div className="time">{moment.utc(x).format(this.timeFormat)}</div>
        <div className="labels">{labels}</div>
      </>,
    );
  }

  @autobind
  handleHighlightCallback(e, x, points, row, seriesName) {
    const { onSelectionChange, highlightCallback } = this.props;
    if (onSelectionChange && this.dygraph) {
      const idx = this.dygraph.getRowForX(x);
      if (idx !== null && idx !== this.currentSelectIdx) {
        this.currentSelectIdx = idx;
        onSelectionChange(idx, seriesName);
      }
    }

    if (highlightCallback) {
      highlightCallback(e, x, points, row, seriesName);
    }
  }

  @autobind
  handleUnhighlightCallback(e) {
    const { onSelectionChange, unhighlightCallback } = this.props;
    if (onSelectionChange && this.dygraph) {
      this.currentSelectIdx = null;
      onSelectionChange(null, null);
    }

    if (unhighlightCallback) {
      unhighlightCallback(e);
    }
  }

  @autobind
  handleDrawCallback(g, initial) {
    const { onDateWindowChange, drawCallback } = this.props;
    if (onDateWindowChange && !initial) {
      const dw = g.xAxisRange();
      if (!isEqual(dw, this.currentDateWindow)) {
        onDateWindowChange(g.xAxisRange());
        this.currentDateWindow = dw;
      }
    }

    if (drawCallback) {
      drawCallback(g, initial);
    }
  }

  @autobind
  handlePlotter(event) {
    // Drop the highlight on the lines
    const { highlightMap, samplingInterval, data, dateWindow } = this.props;
    const { setName, points } = event;
    const highlights = get(highlightMap, setName, []);
    const rawData = data || [];

    const step = dateWindow
      ? Math.ceil((dateWindow[1] - dateWindow[0]) / samplingInterval / MaxDataPoints)
      : Math.ceil(rawData.length / MaxDataPoints);

    const padding = isNumber(samplingInterval) ? samplingInterval * 1 : 0;

    Dygraph.Plotters.linePlotter(event);
    R.forEach((highlight) => {
      const { startTimestamp, endTimestamp, color, needHighlightPading } = highlight;
      const timeRangeList = highlight.timeRangeList || [{ startTimestamp, endTimestamp }];

      const hPadding = step > 1 ? padding * step : needHighlightPading ? padding : 0;
      R.forEach((timeRange) => {
        const { startTimestamp, endTimestamp } = timeRange;

        let hpoints = R.filter((p) => {
          // Adjust precision to second level
          const xvalSec = p.xval ? p.xval : null;
          return xvalSec && xvalSec >= startTimestamp - hPadding && xvalSec <= endTimestamp + hPadding;
        }, points);
        if (hpoints.length === 0) {
          const sp = R.findLast((p) => p.xval && p.xval <= startTimestamp - hPadding, points);
          const ep = R.find((p) => p.xval && p.xval >= endTimestamp + hPadding, points);
          hpoints = [sp, ep];
        }

        hpoints = R.filter((p) => Boolean(p), hpoints);
        if (hpoints.length > 1) {
          // make a copy of the points
          hpoints = R.map((p) => ({ ...p }), hpoints);
          const last = hpoints[hpoints.length - 1];
          const next = hpoints[hpoints.length - 2];
          last.canvasx = next.canvasx + (last.canvasx - next.canvasx) / 2;
          last.canvasy = next.canvasy + (last.canvasy - next.canvasy) / 2;
        }

        if (hpoints.length > 0) {
          const hightlightEvent = { ...event, points: hpoints, strokeWidth: 3, color };
          Dygraph.Plotters.linePlotter(hightlightEvent);
        }
      }, timeRangeList);
    }, highlights);
  }

  @autobind
  handleUnderlayCallback(canvas, area, g) {
    const { data, underlayCallback, currentTimestamp } = this.props;
    // Draw background for predicted time range
    const nowTs = currentTimestamp || moment.utc().valueOf();
    if (data.length > 0) {
      const begin = data[0][0].valueOf();
      const last = data[data.length - 1][0].valueOf();
      if (nowTs < last) {
        const start = Math.max(nowTs, begin);
        const x = g.toDomXCoord(start);
        const { y } = area;
        const w = area.w + area.x - x;
        const { h } = area;
        if (w > 0) {
          canvas.fillStyle = this.predicatedRangeFillStyle;
          canvas.fillRect(x, y, w, h);
        }
      }
    }
    if (underlayCallback) {
      underlayCallback(canvas, area, g);
    }
  }

  @autobind
  handleClickJump() {
    const { projectName, owner, systemId, title, location } = this.props;
    const { startTimestamp, endTimestamp } = parseLocation(location);
    const url = buildUrl(
      BaseUrls.SettingsProject,
      { projectName },
      {
        systemSearch: systemId,
        projectSearch: '',
        projectOwner: owner,
        setting: 'threshold',
        metricFilter: title,
        startTimestamp,
        endTimestamp,
        customerName: owner,
      },
    );
    window.open(url, '_blank');
  }

  @autobind
  paddingData(data, padding, nextProps) {
    const { dateWindow, samplingInterval } = nextProps || this.props;
    const compact = true;

    const rawData = data || [];

    let sdata = [];

    if (!compact) {
      sdata = rawData;
    } else {
      const step = dateWindow
        ? Math.ceil((dateWindow[1] - dateWindow[0]) / samplingInterval / MaxDataPoints)
        : Math.ceil(rawData.length / MaxDataPoints);
      padding *= step;
      for (let i = 0; i < rawData.length; i += step) {
        const bucket = rawData.slice(i, i + step);
        const first = [bucket[0][0]];
        const itemSize = bucket[0].length;

        for (let j = 0; j < bucket.length; j += 1) {
          for (let k = 1; k < itemSize; k += 1) {
            const val = bucket[j][k];
            if (isArray(val)) {
              if (!first[k]) {
                first[k] = [];
              }
              val.forEach((v, idx) => {
                first[k][idx] = isNull(v) || Number.isNaN(v) ? null : R.max(first?.[k]?.[idx] || 0, Number(v));
              });
            } else {
              first[k] = isNull(val) || Number.isNaN(val) ? null : R.max(first?.[k] || 0, Number(val));
            }
          }
        }

        // for (let k = 1; k < itemSize; k += 1) {
        //   if (isArray(first[k])) {
        //     first[k] = first[k].map((v) => (isNull(v) || Number.isNaN(v) ? null : v / bucket.length));
        //   } else {
        //     first[k] = isNull(first[k]) || Number.isNaN(first[k]) ? null : first[k] / bucket.length;
        //   }
        // }

        sdata.push(first);
      }
    }

    if (padding) {
      // append nan to empty data
      const sd = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < sdata.length; i++) {
        sd.push(sdata[i]);
        if (i < sdata.length - 1) {
          const s1 = sdata[i];
          const s2 = sdata[i + 1];
          if (s1[0].valueOf() + padding < s2[0].valueOf()) {
            const d = [new Date(s1[0].valueOf() + padding)];
            // eslint-disable-next-line no-plusplus
            for (let j = 1; j < s1.length; j++) {
              if (isArray(s1[j])) {
                d.push([]);
              } else {
                d.push(NaN);
              }
            }
            sd.push(d);
          }
        }
      }
      sdata = sd;
    }

    return sdata;
  }

  componentDidMount() {
    if (this.chartNode) {
      const {
        data,
        labels,
        predictionlabelMap,
        dateWindow,
        valueRange,
        labelsVisibility,
        isCustomData,
        samplingInterval,
      } = this.props;
      const padding = isNumber(samplingInterval) ? samplingInterval * 1 : 0;
      const sdata = this.paddingData(data, padding);
      let options = { ...this.dygraphOptions, labels, labelsDiv: this.legendNode, customBars: Boolean(isCustomData) };
      if (dateWindow) {
        options = { ...options, dateWindow };
      }
      if (valueRange) {
        options = { ...options, valueRange };
      }
      if (predictionlabelMap) {
        const series = {};
        R.forEach((label) => {
          series[label] = {
            strokePattern: [4, 4],
            color: '#1cbecb',
          };
        }, R.values(predictionlabelMap));
        options = { ...options, series };
      }

      this.sdata = sdata;
      this.dygraph = new Dygraph(this.chartNode, sdata, options);
      // this.dygraph = new Dygraph(this.chartNode, [], options);
      if (labelsVisibility && labelsVisibility.length > 0) {
        this.dygraph.setVisibility(labelsVisibility);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { width, height } = nextProps;

    if (width && height && this.dygraph && (width !== this.props.width || height !== this.props.height)) {
      this.dygraph.resize(width, height - this.titleBarHeight - this.legendBarHeight - this.annotationBarHeight);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.dygraph) {
      const {
        data,
        labels,
        predictionlabelMap,
        dateWindow,
        valueRange,
        selection,
        labelsVisibility,
        isCustomData,
        samplingInterval,
      } = nextProps;
      let { sdata } = this;
      if (data !== this.props.data || dateWindow !== this.props.dateWindow) {
        const padding = isNumber(samplingInterval) ? samplingInterval * 1 : 0;
        sdata = this.paddingData(data, padding, nextProps);
        this.sdata = sdata;
      }

      if (
        data !== this.props.data ||
        labels !== this.props.labels ||
        dateWindow !== this.props.dateWindow ||
        valueRange !== this.props.valueRange ||
        isCustomData !== this.props.isCustomData
      ) {
        let options = { file: sdata, labels, customBars: Boolean(isCustomData) };
        if (dateWindow) {
          options = { ...options, dateWindow };
        }
        if (valueRange) {
          options = { ...options, valueRange };
        }
        if (predictionlabelMap) {
          const series = {};
          R.forEach((label) => {
            series[label] = {
              strokePattern: [4, 4],
              color: '#1cbecb',
            };
          }, R.values(predictionlabelMap));
          options = { ...options, series };
        }

        this.dygraph.updateOptions(options);
      }

      if (selection !== this.props.selection) {
        if (selection && isNumber(selection[0]) && selection[1]) {
          this.dygraph.setSelection(selection[0], selection[1]);
        } else {
          this.dygraph.clearSelection();
        }
      }
      if (labelsVisibility !== this.props.labelsVisibility) {
        if (labelsVisibility && labelsVisibility.length > 0) {
          this.dygraph.setVisibility(labelsVisibility);
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.dygraph) {
      this.dygraph.destroy();
      this.dygraph = null;
    }
  }

  @autobind
  unzoomGraph() {
    if (this.dygraph) {
      this.dygraph.updateOptions({
        dateWindow: null,
        valueRange: null,
      });
    }
  }

  render() {
    const { intl, title, titlePostfix, className, style, width, height, annotations, onCreateAnnotation } = this.props;
    const { showSeparateInstance, onClickSeparateInstance, onCheckMetric, userInfo, viewTitle } = this.props;
    const items = [];
    R.forEach(
      (item) => {
        const { timestamp, ...rest } = item;
        const x = this.dygraph.toDomXCoord(timestamp);
        const annoElem = onCreateAnnotation({ x, timestamp, ...rest });
        if (annoElem) {
          items.push(annoElem);
        }
      },
      this.dygraph ? annotations : [],
    );
    return (
      <div className={`fui linechart ${className || ''}`}>
        <div className="max-width flex-row flex-center-align" style={{ marginBottom: 24 }}>
          <div
            className={`flex-grow flex-min-width flex-row flex-center-align ${
              isFunction(onCheckMetric) ? 'clickable' : ''
            }`}
            onClick={
              isFunction(onCheckMetric)
                ? () => {
                    const { checked } = this.state;
                    this.setState({ checked: !checked }, () => {
                      onCheckMetric(!checked);
                    });
                  }
                : () => {}
            }
          >
            {isFunction(onCheckMetric) && (
              <Checkbox style={{ marginRight: 8 }} checked={this.state.checked} onChange={(e) => null} />
            )}
            <Tooltip title={title} mouseEnterDelay={0.3} placement="top">
              <span className="hidden-line-with-ellipsis inline-block font-14 bold">{viewTitle || title}</span>
            </Tooltip>
            {titlePostfix && (
              <div className="light-label font-14 bold" style={{ marginLeft: 8 }}>
                ({titlePostfix})
              </div>
            )}
          </div>
          {showSeparateInstance && isFunction(onClickSeparateInstance) && (
            <Button
              size="small"
              type="primary"
              icon={<OrderedListOutlined />}
              onClick={onClickSeparateInstance}
              style={{ marginLeft: 14 }}
              ghost
            >
              {intl.formatMessage(eventMessages.perInstanceChart)}
            </Button>
          )}
          {showSeparateInstance && !userInfo.isReadUser && (
            <Button size="small" type="primary" onClick={this.handleClickJump} style={{ marginLeft: 10 }} ghost>
              Config
            </Button>
          )}
        </div>
        <div className="annotation-bar">{items}</div>
        <div
          style={{
            position: 'absolute',
            color: '#2ec7c9',
            fontSize: 16,
            left: 4,
            paddingTop: 8,
            cursor: 'pointer',
            zIndex: 100,
          }}
        >
          <RollbackOutlined onClick={this.unzoomGraph} />
        </div>
        <div
          style={{
            ...style,
            width,
            height: height - this.titleBarHeight - this.legendBarHeight - this.annotationBarHeight,
          }}
          ref={(c) => {
            this.chartNode = c;
          }}
        />
        <div
          className="legend-bar"
          ref={(c) => {
            this.legendNode = c;
          }}
        />
      </div>
    );
  }
}

export default connect((state: State) => {
  const { currentTheme } = state.app;
  const { location } = state.router;
  const { userInfo } = state.auth;
  const isDark = currentTheme === 'dark';

  return { isDark, location, userInfo };
})(LineChartCore);
