/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
// import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Spin, Alert } from 'antd';

import fetchGet from '../../../src/common/apis/fetchGet';
import getEndpoint from '../../../src/common/apis/getEndpoint';
import { buildUrl } from '../../../src/common/utils';
import { updateLastActionInfo } from '../../../src/common/app/actions';
import { Modal } from '../../../src/lib/fui/react';

type Props = {
  onClose: Function,
  incident: Object,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class ExternalSystemJumpModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: '',
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.incident !== this.props.incident) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { credentials, incident, onClose } = props;
    const { projectName, externalSystemId } = incident;

    if (incident) {
      this.setState({ isLoading: true });
      this.props.updateLastActionInfo();
      fetchGet(getEndpoint('getredirectingurl'), {
        ...credentials,
        projectName,
        id: externalSystemId,
      })
        .then((data) => {
          const { msg, url } = data || {};
          if (msg === 'Success') {
            this.setState({ isLoading: false });
            window.open(buildUrl(url, {}, {}), '_blank');
            onClose();
          } else {
            this.setState({ isLoading: false, errMsg: msg });
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  }

  render() {
    const { onClose } = this.props;
    const { isLoading, errMsg } = this.state;
    return (
      <Modal title={null} visible onCancel={onClose} footer={null} bodyStyle={{ padding: 8 }}>
        <div className="flex-row flex-min-height flex-center-align flex-center-justify" style={{ height: 100 }}>
          <Spin spinning={isLoading}>
            {errMsg && <Alert message="Error" description={errMsg} type="error" showIcon />}
          </Spin>
        </div>
      </Modal>
    );
  }
}

const ExternalSystemJumpModal = injectIntl(ExternalSystemJumpModalCore);
export default connect(
  (state) => {
    const { loadStatus } = state.app;
    const { credentials } = state.auth;
    return {
      loadStatus,
      credentials,
    };
  },
  { updateLastActionInfo },
)(ExternalSystemJumpModal);
