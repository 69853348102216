/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const newCausalGroup = (credentials: Credentials, params: Object) => {
  return fetchPost(getEndpoint('causalgroup'), {
    ...credentials,
    ...params,
  }).then((d) => {
    return d;
  });
};

export default newCausalGroup;
