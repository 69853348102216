import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { Redirect } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Spin } from 'antd';

import type { State, Message } from '../../common/types';
import { setCurrentLocale, hideAppLoader, createLoadAction } from '../../common/app/actions';
import { ActionTypes, clearCredentials } from '../../common/auth/actions';
import { parseLocation, sleep } from '../../common/utils';

import { authMessages } from '../../common/auth/messages';

import { CenterPage, LocaleSelector } from '../app/components';

type Props = {
  intl: Object,
  push: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  replace: Function,
  location: Object,
  setCurrentLocale: Function,
  hideAppLoader: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  createLoadAction: Function,
  clearCredentials: Function,

  currentLocale: String,
  appLoaderVisible: Boolean,
  loginReason: Message,
  errorMessage: String,
  isLoggedIn: Boolean,
  credentials: Object,
};

class PassportLoginCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location, setCurrentLocale, currentLocale } = props;
    const params = parseLocation(location);
    const { locale } = params;
    if (locale && locale !== currentLocale) {
      setCurrentLocale(locale);
    }

    this.state = {
      isLogging: false,
      errMsg: undefined,
    };
    this.allowTypeParams = {
      cmcc: ['encodedString'],
    };
  }

  componentDidMount() {
    if (!this.props.isLoggedIn && this.props.credentials) {
      this.props.clearCredentials();
    }
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    if (!this.props.isLoggedIn) {
      this.loginPassport(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  async loginPassport(props) {
    const { intl, location, createLoadAction } = props;
    const params = parseLocation(location);
    const { idpType } = params;

    if (idpType && R.has(idpType.toLocaleLowerCase(), this.allowTypeParams)) {
      const requiredParams = this.allowTypeParams[idpType.toLocaleLowerCase()] || [];
      const missParams = R.difference(requiredParams, R.keys(params));
      if (missParams.length !== 0) {
        this.setState({
          errMsg: intl.formatMessage(authMessages.wrongParameters, { params: R.join(', ', missParams) }),
        });
      } else {
        this.setState({ isLogging: true, errMsg: undefined });
        await sleep(300);

        createLoadAction(ActionTypes.PASSPORTLOGIN, params, false, false, this.callbackHandleLoading);
      }
    } else {
      this.setState({
        errMsg: intl.formatMessage(authMessages.wrongParameters, { params: 'idpType' }),
      });
    }
  }

  @autobind
  callbackHandleLoading() {
    // reset reloadSystem
    this.setState({ isLogging: false });
  }

  render() {
    const { intl, push, location, isLoggedIn, loginReason, errorMessage, currentTheme } = this.props;
    const { isLogging, errMsg } = this.state;
    const hasError = errMsg || loginReason;
    const loginError = !isLoggedIn && loginReason;

    if (isLoggedIn) {
      const from = get(location, 'state.from', '/');
      return <Redirect to={from} />;
    }
    return (
      <CenterPage intl={intl} push={push} className="auth" currentTheme={currentTheme}>
        <form className={`ui ${hasError ? 'error' : ''} form`} style={{ width: 320 }}>
          <LocaleSelector style={{ marginBottom: 16 }} disabled />

          <div className="flex-row full-width" style={{ height: 150 }}>
            <Spin spinning={isLogging} wrapperClassName="full-width full-height">
              {errMsg && <div className="ui error message">{errMsg}</div>}
              {loginError && <div className="ui error message">{errorMessage || intl.formatMessage(loginReason)}</div>}
            </Spin>
          </div>
        </form>
      </CenterPage>
    );
  }
}

const PassportLogin = injectIntl(PassportLoginCore);
export default connect(
  (state: State) => ({
    currentLocale: state.app.currentLocale,
    appLoaderVisible: state.app.appLoaderVisible,
    location: state.router.location,
    loginReason: state.auth.loginReason,
    errorMessage: state.auth.errorMessage,
    currentTheme: state.app.currentTheme,
    isLoggedIn: state.auth.loggedIn,
    credentials: state.auth.credentials,
  }),
  { replace, push, setCurrentLocale, hideAppLoader, clearCredentials, createLoadAction },
)(PassportLogin);
