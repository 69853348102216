/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { EditOutlined } from '@ant-design/icons';
import { Button, Spin, message, Alert, Popconfirm } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Container, Table, Column, AutoSizer, Tooltip } from '../../../lib/fui/react';
// import { Defaults } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appButtonsMessages, appMessages } from '../../../common/app/messages';

import AddMSTeamsNameModal from './AddMSTeamsNameModal';
import { parseJSON } from '../../../common/utils';

type Props = {
  // eslint-disable-next-line
  onClose: Function,
  refresh: Number,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  replace: Function,
  updateLastActionInfo: Function,
};

class ServiceTeamsWorkspaceModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      eventList: [],

      showAddTeamNameModal: false,
      activeIncident: null,
      isConnect: true,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refresh !== nextProps.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials, userInfo } = props;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('authenticate-teams'), {
      ...credentials,
      list: true,
    })
      .then(async (data) => {
        const systemTeamsMap = {};
        R.forEach((item) => {
          const { systemKey } = item;
          const { systemName } = systemKey || {};
          systemTeamsMap[systemName] = item;
        }, data || []);

        let { systemsMap } = props;
        if (userInfo.isAdmin || userInfo.isLocalAdmin) {
          let userNameList = R.values(systemTeamsMap);
          userNameList = R.map((item) => item.systemKey.userName, userNameList);
          userNameList = R.uniq(userNameList);
          systemsMap = await this.getUserSystemMap(userNameList);
        }

        // get all system list
        let systemList = R.filter((system) => !system.isShared, R.values(systemsMap));
        systemList = R.sortWith(
          [R.ascend(R.compose(R.toLower, R.prop('owner'))), R.ascend(R.compose(R.toLower, R.prop('systemName')))],
          systemList,
        );

        // merge info
        const eventList = [];
        R.forEach((item) => {
          const systemTeamsInfo = get(systemTeamsMap, item.systemId);
          if (systemTeamsInfo) {
            eventList.push({ ...systemTeamsInfo, ...item });
          } else {
            eventList.push(item);
          }
        }, systemList);

        this.setState({ isLoading: false, errMsg: null, eventList });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, errMsg: err.message || String(err), eventList: [] });
      });
  }

  @autobind
  getUserSystemMap(userNameList) {
    const { credentials } = this.props;
    const requests = [];
    R.forEach((customerName) => {
      requests.push(
        fetchGet(getEndpoint('systemframework', 2), {
          ...credentials,
          customerName,
        }),
      );
    }, userNameList);
    return Promise.all(requests)
      .then((data) => {
        let allSystemMap = {};
        R.forEach((item) => {
          let { ownSystemArr } = item;
          ownSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system };
          }, ownSystemArr || []);
          const systemsMap = {};
          R.forEach(
            (system) => {
              const { systemDisplayName, systemKey, projectDetailsList, isShared, ...rest } = system;
              const { environmentName, systemName: systemId, userName } = systemKey || {};
              const newProjects = parseJSON(projectDetailsList) || [];
              systemsMap[systemId] = {
                ...rest,
                isShared,
                environmentName,
                systemId,
                systemName: systemDisplayName || systemId,
                owner: userName,
                projectDetailsList: newProjects,
              };
            },
            [...ownSystemArr],
          );
          allSystemMap = { ...allSystemMap, ...systemsMap };
        }, data || []);
        return allSystemMap;
      })
      .catch((e) => {
        console.log(String(e));
      });
  }

  @autobind
  channelNameRenderer({ cellData }) {
    return (
      <Tooltip title={cellData} mouseEnterDelay={0.3}>
        <div className="hidden-line-with-ellipsis clickable">{cellData || '--'}</div>
      </Tooltip>
    );
  }

  @autobind
  handleConfirmClick(rowData) {
    const { credentials, intl } = this.props;
    const { systemId, owner, environmentName } = rowData;
    fetchPost(getEndpoint('teams-remove'), {
      ...credentials,
      customerName: owner,
      systemName: systemId,
      environmentName,
    })
      .then((data) => {
        const { message: messageText, success } = data;
        if (success === undefined || success) {
          message.success(messageText);
          this.reloadData(this.props);
        } else {
          message.error(messageText);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  controlRenderer({ rowData, rowIndex }) {
    const { intl } = this.props;
    const { isLoading, microsoftTeamName } = rowData;
    return (
      <div className="flex-row flex-end-justify">
        {microsoftTeamName ? (
          <Popconfirm
            title="Are you sure you want to delete relevant information?"
            onConfirm={() => this.handleConfirmClick(rowData)}
            okText={intl.formatMessage(appButtonsMessages.yes)}
            cancelText={intl.formatMessage(appButtonsMessages.no)}
          >
            <Button size="small" type="primary" style={{ marginLeft: 8, width: 76 }}>
              Disconnect
            </Button>
          </Popconfirm>
        ) : (
          <Button
            size="small"
            type="primary"
            style={{ marginLeft: 8, width: 76 }}
            loading={isLoading}
            onClick={() => this.setState({ showAddTeamNameModal: true, activeIncident: rowData, isConnect: true })}
          >
            Connect
          </Button>
        )}

        <Tooltip title={microsoftTeamName ? null : 'No Team name, cannot be clicked'}>
          <Button
            size="small"
            type="primary"
            style={{ marginLeft: 8 }}
            loading={isLoading}
            disabled={!microsoftTeamName}
            onClick={() => this.setState({ showAddTeamNameModal: true, activeIncident: rowData, isConnect: false })}
          >
            Update
          </Button>
        </Tooltip>
      </div>
    );
  }

  @autobind
  channelNameHeaderRender(type, width) {
    return ({ dataKey }) => {
      return <div style={{ width }}>{type}</div>;
    };
  }

  render() {
    const { intl } = this.props;
    const { isLoading, errMsg, eventList, isConnect } = this.state;
    return (
      <Container className="full-width full-height flex-col">
        {errMsg && <Alert message={errMsg} type="error" showIcon style={{ marginBottom: 8 }} />}
        <div className="flex-row flex-end-justify" style={{ marginBottom: 8 }}>
          <Button size="small" onClick={() => this.reloadData(this.props)}>
            {intl.formatMessage(appButtonsMessages.refresh)}
          </Button>
        </div>
        <div className="flex-grow flex-min-height">
          <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  rowCount={eventList.length}
                  rowGetter={({ index }) => eventList[index]}
                >
                  <Column
                    width={100}
                    maxWidth={100}
                    dataKey="owner"
                    flexShrink={0}
                    headerRenderer={this.channelNameHeaderRender('User name', 100)}
                    cellRenderer={this.channelNameRenderer}
                  />
                  <Column
                    width={100}
                    maxWidth={100}
                    dataKey="systemName"
                    flexShrink={0}
                    headerRenderer={this.channelNameHeaderRender('System name', 100)}
                    cellRenderer={this.channelNameRenderer}
                  />
                  <Column
                    width={100}
                    maxWidth={100}
                    dataKey="microsoftTeamName"
                    flexShrink={0}
                    headerRenderer={this.channelNameHeaderRender('Teams name', 100)}
                    cellRenderer={this.channelNameRenderer}
                  />
                  <Column
                    width={100}
                    maxWidth={100}
                    dataKey="email"
                    flexShrink={0}
                    headerRenderer={this.channelNameHeaderRender('Email', 100)}
                    cellRenderer={this.channelNameRenderer}
                  />
                  <Column
                    width={120}
                    maxWidth={120}
                    dataKey="predicted"
                    flexShrink={0}
                    headerRenderer={this.channelNameHeaderRender('Predicted Incident Channel Name', 120)}
                    cellRenderer={this.channelNameRenderer}
                  />
                  <Column
                    width={120}
                    maxWidth={120}
                    dataKey="detected"
                    flexShrink={0}
                    headerRenderer={this.channelNameHeaderRender('Detected Incident Channel Name', 120)}
                    cellRenderer={this.channelNameRenderer}
                  />
                  <Column
                    width={120}
                    maxWidth={120}
                    dataKey="newAlert"
                    flexShrink={0}
                    headerRenderer={this.channelNameHeaderRender('New Pattern Alert Channel Name', 120)}
                    cellRenderer={this.channelNameRenderer}
                  />
                  <Column
                    width={200}
                    label=""
                    dataKey="systemName"
                    flexShrink={0}
                    cellRenderer={this.controlRenderer}
                  />
                </Table>
              )}
            </AutoSizer>
          </Spin>
        </div>

        {this.state.showAddTeamNameModal && (
          <AddMSTeamsNameModal
            activeIncident={this.state.activeIncident}
            isConnect={isConnect}
            onClose={(reload, close) =>
              this.setState({ showAddTeamNameModal: false, activeIncident: null }, () => {
                if (reload) {
                  this.reloadData(this.props);
                } else if (close) {
                  this.props.onClose();
                }
              })
            }
          />
        )}
      </Container>
    );
  }
}

const ServiceTeamsWorkspaceModal = injectIntl(ServiceTeamsWorkspaceModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return { location, systemsMap, userInfo, credentials };
  },
  { replace, updateLastActionInfo },
)(ServiceTeamsWorkspaceModal);
