/*  @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */
/* eslint-disable no-console */

import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchDelete from './fetchDelete';
import fetchGet from './fetchGet';

const deleteLogSequenceScope = (credentials: Credentials, params: Object) => {
  const { projectName, entries } = params;
  return fetchDelete(
    getEndpoint('logsequencescope'),
    {
      ...credentials,
      projectName,
      entries: JSON.stringify(entries),
    },
    {},
    false,
  ).then(() => {
    return {};
  });
};

export default deleteLogSequenceScope;
