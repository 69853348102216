import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { AutoComplete, Button, Checkbox, Input, Select, Spin } from 'antd';
import { PauseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  defaultTableRowRenderer,
  Modal,
  Popover,
  Table,
  Tooltip,
} from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { logMessages } from '../../../../common/log/messages';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});
const SortableTable = SortableContainer(Table, { withRef: true });
const SortableTableRowRenderer = SortableElement(defaultTableRowRenderer);
const DragHandle = SortableHandle(({ intl }) => (
  <Tooltip title={intl.formatMessage(appFieldsMessages.dragAndDrop)} placement="top" mouseEnterDelay={0.3}>
    <span
      className="hover-display-item"
      style={{ cursor: 'move', pointerEvents: 'auto', marginLeft: 8 }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <PauseOutlined style={{ transform: 'rotate(90deg)', fontSize: 18, cursor: 'move' }} />
    </span>
  </Tooltip>
));

const cmpPatternNameLabel = (x, y) =>
  x.keyword === y.keyword &&
  x.type === y.type &&
  x.patternName === y.patternName &&
  x.order === y.order &&
  x.patternNameKey === y.patternNameKey;

export default function PatternNameRegexModal({
  intl,
  activeEvent,
  onClose,
  fieldNameOptions,
  incidentListData,
  regexPlaceHolderForIncident,
}: Object) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    typeList: [
      { label: intl.formatMessage(settingsMessages.jsonFieldName), value: 'fieldName' },
      { label: intl.formatMessage(settingsMessages.stringContent), value: 'content' },
    ],
    isLoading: false,
    rootEvent: {},
    localEvents: [],
    eventIndex: 0,
    eventType: null,
    allChecked: false,
  });
  const { typeList, isLoading, rootEvent, localEvents, eventIndex, eventType, allChecked } = state;
  const tableRef = useRef(null);
  const events = useRef([]);

  const onToggleCollapse = () => {
    cellMeasureCache.clearAll();
    if (tableRef.current) {
      tableRef.current.forceUpdateGrid();
      forceUpdate();
    }
  };

  useEffect(() => {
    const { data, index, type } = activeEvent || {};
    const { patternNameLabels, ...rest } = data || {};
    const newEvents = R.addIndex(R.map)((item, idx) => ({ ...item, order: idx + 1 }), patternNameLabels || []);
    events.current = newEvents;
    setState({ rootEvent: rest, localEvents: R.clone(newEvents), eventIndex: index, eventType: type });
    onToggleCollapse();
  }, [activeEvent]);

  const allCheckedRender = () => {
    return (
      <Checkbox
        checked={allChecked}
        onChange={(e) => {
          const { checked } = e.target;
          const newEvents = R.map((item) => ({ ...item, checked }), events.current || []);
          events.current = newEvents;
          setState({ allChecked: checked });
          onToggleCollapse();
        }}
      />
    );
  };

  const checkedRender = ({ rowData, cellData, dataKey }) => {
    return (
      <Checkbox
        checked={cellData}
        onChange={(e) => {
          rowData[dataKey] = e.target.checked;
          const newAllChecked = !R.find((item) => !item.checked, events.current || []);
          setState({ allChecked: newAllChecked });
          onToggleCollapse();
        }}
      />
    );
  };

  const orderRender = ({ label }) => {
    return (
      <span className="clickable flex-row flex-center-align" style={{ marginBottom: 4 }}>
        <span style={{ marginRight: 4 }}>{label}</span>
        <Popover title={null} content={intl.formatMessage(settingsMessages.patternNameLabelsOrder)} placement="right">
          <QuestionCircleOutlined />
        </Popover>
      </span>
    );
  };

  const getKeywordList = (rowData) => {
    const { keyword, type, isNew } = rowData;
    const keywordList = [];
    if (R.isNil(keyword)) {
      return keywordList;
    }
    R.forEach((_item) => {
      if (type === 'fieldName' && (_item || '').includes('=')) {
        const jsonKey = R.slice(0, R.indexOf('=', _item || ''), _item || '');
        const keyword = R.slice(R.indexOf('=', _item || '') + 1, Infinity, _item || '');
        keywordList.push({ jsonKey, keyword });
      } else if (type === 'fieldName' && !(_item || '').includes('=') && isNew) {
        const jsonKey = '';
        const keyword = _item || '';
        keywordList.push({ jsonKey, keyword });
      } else if (type === 'fieldName' && !(_item || '').includes('=')) {
        const jsonKey = _item || '';
        const keyword = '';
        keywordList.push({ jsonKey, keyword });
      } else if (type === 'content' || !type) {
        keywordList.push({ keyword: _item });
      }
    }, (keyword ?? '').split(' AND '));
    return keywordList;
  };

  const typeChangeList = (rowData, dataKey, node) => {
    return (newVal) => {
      const keywordList = getKeywordList(rowData);

      let newData = R.map(
        (item) => (`${item.jsonKey || ''}=${item.keyword}` === '=' ? '' : `${item.jsonKey || ''}=${item.keyword}`),
        keywordList || [],
      );
      if (newVal === 'content' || !newVal) {
        newData = R.map((item) => item.keyword, keywordList || []);
      }
      newData = newData.length === 0 ? null : newData.join(' AND ');

      // Save the data and force update.
      rowData.patternNameKey = undefined;
      rowData.keyword = newData;
      rowData[dataKey] = newVal;
      onToggleCollapse();
    };
  };

  const renderTypeSelectList = ({ dataKey, rowData, cellData }) => {
    return (
      <Select
        className="full-width"
        size="small"
        value={cellData}
        onChange={typeChangeList(rowData, dataKey)}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {R.map(
          (item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ),
          typeList,
        )}
      </Select>
    );
  };

  const handleRegexRender = ({ label }) => {
    return (
      <span className="clickable flex-row flex-center-align" style={{ marginBottom: 4 }}>
        <span style={{ marginRight: 4 }}>{label}</span>
        <Popover title={null} content={intl.formatMessage(settingsMessages.regexIsCaseSensitive)} placement="right">
          <QuestionCircleOutlined />
        </Popover>
      </span>
    );
  };

  const handleTypeChangeList = (keywordList, index, dataKey, key, rowData) => {
    return (newVal) => {
      keywordList = update(keywordList, {
        [index]: {
          $set: {
            ...keywordList[index],
            ...{ [key]: newVal },
          },
        },
      });
      let newData = R.map(
        (item) => (`${item.jsonKey || ''}=${item.keyword}` === '=' ? '' : `${item.jsonKey || ''}=${item.keyword}`),
        keywordList || [],
      );
      newData = newData.join(' AND ');
      // Save the data and force update.
      rowData[dataKey] = newData;
      onToggleCollapse();
    };
  };

  const handleKeywordsChangedList = (keywordList, index, dataKey, key, rowData) => {
    return (e) => {
      const newVal = e.target.value || '';
      const { type } = rowData;
      keywordList = update(keywordList, {
        [index]: {
          $set: {
            ...keywordList[index],
            ...{ [key]: newVal },
          },
        },
      });
      let newData = R.map(
        (item) => (`${item.jsonKey || ''}=${item.keyword}` === '=' ? '' : `${item.jsonKey || ''}=${item.keyword}`),
        keywordList || [],
      );
      if (type === 'content' || !type) {
        newData = R.map((item) => item.keyword, keywordList || []);
      }
      newData = newData.join(' AND ');
      // Save the data and force update.
      rowData[dataKey] = newData;
      onToggleCollapse();
    };
  };

  const handleAddAnd = (keywordList, dataKey, rowData) => {
    const { type } = rowData;
    let newData = [];
    if (type === 'fieldName') {
      newData = [...keywordList, { jsonKey: '', keyword: '' }];
      newData = R.map((item) => `${item.jsonKey}=${item.keyword}`, newData || []);
    } else if (type === 'content' || !type) {
      newData = [...keywordList, { keyword: '' }];
      newData = R.map((item) => item.keyword, newData || []);
    }
    newData = newData.join(' AND ');
    // Save the data and force update.
    rowData[dataKey] = newData;
    onToggleCollapse();
    forceUpdate();
  };

  const handleDeleteAnd = (keywordList, index, dataKey, rowData) => {
    if (keywordList.length > 0) {
      const { type } = rowData;
      const newKeywordList = R.remove(index, 1, keywordList);
      let newData = R.map(
        (item) => (`${item.jsonKey || ''}=${item.keyword}` === '=' ? '' : `${item.jsonKey || ''}=${item.keyword}`),
        newKeywordList || [],
      );
      if (type === 'content' || !type) {
        newData = R.map((item) => item.keyword, newKeywordList || []);
      }
      newData = newKeywordList.length === 0 ? null : newData.join(' AND ');
      // Save the data and force update.
      rowData[dataKey] = newData;
      onToggleCollapse();
      forceUpdate();
    }
  };

  const renderKeywordsInput = ({ dataKey, parent, rowIndex, rowData }) => {
    const { type } = rowData;
    const rootKeywordList = getKeywordList(rootEvent);
    const keywordList = getKeywordList(rowData);

    return (
      <CellMeasurer cache={cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ padding: '4px 0 8px 0' }}>
          {R.addIndex(R.map)(
            (item, idx) => (
              <div
                key={`${item.jsonKey}${item.keyword}-${idx}-w`}
                className="flex-row flex-center-align"
                style={{ marginTop: 4 }}
              >
                {rootEvent?.type === 'fieldName' && (
                  <>
                    <AutoComplete disabled size="small" value={item.jsonKey} style={{ minWidth: 180, maxWidth: 180 }} />
                    <span style={{ margin: '0 10px' }}>=</span>
                  </>
                )}
                <Input size="small" style={{ minWidth: 180, maxWidth: 180 }} value={item.keyword} disabled />
                {rootKeywordList.length === idx + 1 && (
                  <Button
                    size="small"
                    style={{ marginLeft: 8 }}
                    onClick={() => handleAddAnd(keywordList, dataKey, rowData)}
                  >
                    AND
                  </Button>
                )}
              </div>
            ),
            rootKeywordList || [],
          )}
          {R.addIndex(R.map)((item, idx) => {
            return (
              <div key={idx} className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                {type === 'fieldName' && (
                  <>
                    <AutoComplete
                      size="small"
                      allowClear
                      options={fieldNameOptions}
                      value={item.jsonKey}
                      placeholder={intl.formatMessage(logMessages.regexPlaceHolderForWhiteListJsonKey)}
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf((item.jsonKey || '').toUpperCase()) !== -1
                      }
                      onChange={handleTypeChangeList(keywordList, idx, dataKey, 'jsonKey', rowData)}
                      style={{ minWidth: 180, maxWidth: 180 }}
                      className={`${item.jsonKey ? '' : 'jsonKeyNoneError'}`}
                    />
                    <span style={{ margin: '0 10px' }}>=</span>
                  </>
                )}
                <Input
                  size="small"
                  placeholder={
                    type === 'content'
                      ? regexPlaceHolderForIncident[0]
                      : type === 'fieldName'
                      ? regexPlaceHolderForIncident[1]
                      : ''
                  }
                  style={{ minWidth: 180, maxWidth: 180 }}
                  value={item.keyword}
                  onChange={handleKeywordsChangedList(keywordList, idx, dataKey, 'keyword', rowData)}
                />
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={() => handleAddAnd(keywordList, dataKey, rowData)}
                >
                  AND
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={() => handleDeleteAnd(keywordList, idx, dataKey, rowData)}
                >
                  {intl.formatMessage(appButtonsMessages.delete)}
                </Button>
              </div>
            );
          }, keywordList || [])}
        </div>
      </CellMeasurer>
    );
  };

  const systemCellRender = ({ cellData }) => {
    return (
      <Popover title={null} content={cellData} placement="right">
        <span className="hidden-line-with-ellipsis inline-block clickable">{cellData}</span>
      </Popover>
    );
  };

  const handleThresholdChange = ({ value, rowData, dataKey }) => {
    const newVal = value || '';
    // Save the data and force update.
    rowData[dataKey] = newVal;
    onToggleCollapse();
  };

  const renderInput = ({ dataKey, cellData, rowData }) => {
    const { patternNameKey } = rowData;
    return (
      <div className="flex-row flex-center-align">
        <AutoComplete
          allowClear
          size="small"
          value={cellData}
          options={incidentListData}
          style={{ width: 200 }}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ maxWidth: 650 }}
          onChange={(incident) => handleThresholdChange({ value: incident, rowData, dataKey })}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
          }
          className={`${cellData || patternNameKey ? '' : 'jsonKeyNoneError'}`}
        />
      </div>
    );
  };

  const patternNameKeyInput = ({ dataKey, cellData, rowData }) => {
    const { type, patternName } = rowData;
    const isJSON = type === 'fieldName';
    return (
      <div className="flex-row flex-center-align">
        <AutoComplete
          allowClear
          size="small"
          value={cellData}
          options={isJSON ? fieldNameOptions : []}
          style={{ width: 200 }}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ maxWidth: 650 }}
          onChange={(incident) => handleThresholdChange({ value: incident, rowData, dataKey })}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
          }
          className={`${cellData || patternName ? '' : 'jsonKeyNoneError'}`}
        />
      </div>
    );
  };

  const removeRenderer = () => {
    return <DragHandle intl={intl} />;
  };

  const onPatternNameLabelsSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }
    let newEvents = R.move(oldIndex, newIndex, R.clone(events.current));
    newEvents = R.addIndex(R.map)((item, idx) => ({ ...item, order: idx + 1 }), newEvents);
    events.current = newEvents;
    onToggleCollapse();
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    let newEvents = [{ isNew: true, type: rootEvent?.type }, ...events.current];
    newEvents = R.addIndex(R.map)((item, idx) => ({ ...item, order: idx + 1 }), newEvents);
    events.current = newEvents;
    onToggleCollapse();
  };

  const handleRemoved = (event) => {
    event.preventDefault();
    let newEvents = R.filter((item) => !item.checked, events.current || []);
    newEvents = R.addIndex(R.map)((item, idx) => ({ ...item, order: idx + 1 }), newEvents);
    events.current = newEvents;
    onToggleCollapse();
  };

  const handleSave = () => {
    const data = {
      ...rootEvent,
      patternNameLabels: R.map(
        (item) => ({
          ...(item.keyword ? { keyword: item.keyword } : {}),
          patternName: item.patternName,
          type: item.type,
          order: item.order,
          patternNameKey: item.patternNameKey,
        }),
        events.current || [],
      ),
    };
    onClose({ event: data, index: eventIndex, type: eventType });
  };

  const fieldIsNullList = (list) => {
    const hasData = R.find((n) => {
      if (n.type === 'fieldName') {
        const keywordList = getKeywordList(n);
        return R.find((item) => R.isEmpty(item.jsonKey) || R.isNil(item.keyword), keywordList);
      } else {
        const keywordList = getKeywordList(n);
        return R.find((item) => R.isEmpty(item.keyword), keywordList);
      }
    }, list);
    return !!hasData;
  };

  const removeDisabled = R.find((item) => item.checked, events.current || []);
  const diffPatternNameLabels = R.differenceWith(cmpPatternNameLabel, events.current, localEvents || []);
  const diffPatternNameLabelsHasNull = fieldIsNullList(diffPatternNameLabels);
  const hasErrorPatternNameLabels =
    diffPatternNameLabels.length === 0 ||
    diffPatternNameLabelsHasNull ||
    R.filter((item) => !item.patternName && !item.patternNameKey, events.current).length > 0;
  return (
    <Modal
      visible
      title={intl.formatMessage(appFieldsMessages.patternNameRegex)}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      width={1300}
      bodyStyle={{ height: 600 }}
      onChange={(e) => console.log(e)}
      footer={
        <div>
          <Button size="small" onClick={() => onClose()}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>
          <Button size="small" type="primary" disabled={!removeDisabled} onClick={handleRemoved}>
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={handleSave}
            disabled={events.current.length && hasErrorPatternNameLabels}
          >
            {intl.formatMessage(appButtonsMessages.save)}
          </Button>
        </div>
      }
    >
      <Container className="full-height flex-col">
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={handleAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
          <AutoSizer>
            {({ width, height }) => (
              <SortableTable
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={cellMeasureCache.rowHeight}
                rowCount={events.current.length}
                rowGetter={({ index }) => events.current[index]}
                ref={(table) => {
                  tableRef.current = table && table.getWrappedInstance();
                }}
                key={events.current.length}
                lockAxis="y"
                useDragHandle
                onSortEnd={onPatternNameLabelsSortEnd}
                rowRenderer={(params) => <SortableTableRowRenderer {...params} />}
                rowStyle={{ zIndex: 1001 }}
              >
                <Column
                  width={40}
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={allCheckedRender}
                  cellRenderer={checkedRender}
                />
                <Column
                  width={65}
                  label={intl.formatMessage(appFieldsMessages.order)}
                  dataKey="order"
                  className="white-pre"
                  headerRenderer={orderRender}
                />
                <Column
                  width={140}
                  label={intl.formatMessage(appFieldsMessages.type)}
                  dataKey="type"
                  className="white-pre"
                  cellRenderer={renderTypeSelectList}
                />
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.patternNameRegex)}
                  dataKey="keyword"
                  flexGrow={1}
                  cellRenderer={renderKeywordsInput}
                  headerRenderer={handleRegexRender}
                />
                <Column
                  width={120}
                  label={intl.formatMessage(appFieldsMessages.system)}
                  dataKey="systemTag"
                  cellRenderer={systemCellRender}
                />
                <Column
                  width={150}
                  label={intl.formatMessage(logMessages.patternName)}
                  dataKey="patternName"
                  cellRenderer={renderInput}
                />
                <Column
                  width={150}
                  label={intl.formatMessage(logMessages.patternNameKey)}
                  dataKey="patternNameKey"
                  cellRenderer={patternNameKeyInput}
                />
                <Column width={50} label="" className="text-right" cellRenderer={removeRenderer} dataKey="userName" />
              </SortableTable>
            )}
          </AutoSizer>
        </Spin>
      </Container>
    </Modal>
  );
}
