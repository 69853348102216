/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { State } from '../../../common/types';
import { EventRenderers } from '../../../common/utils';
import { updateMetricPatternFlag } from '../../../common/metric/actions';
import { updateLogPatternFlag } from '../../../common/log/actions';
import { Modal } from '../../../lib/fui/react';

import { appButtonsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  intl: Object,
  projectName: String,
  incident: Object,
  errorMessage: ?Object,
  currentLoadingComponents: Object,
  updateMetricPatternFlag: Function,
  updateLogPatternFlag: Function,
  onClose: Function,
};

class IgnoreModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.submitLoader = 'metric_ignore_submit';
    this.sumbitting = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLoadingComponents, errorMessage } = nextProps;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      if (!errorMessage) {
        this.props.onClose(true);
      }
    }
  }

  @autobind
  handleOnClose() {
    this.props.onClose(false);
  }

  @autobind
  handleSumbit() {
    const { incident, projectName, updateMetricPatternFlag, updateLogPatternFlag } = this.props;
    const { isLog } = incident;
    this.sumbitting = true;
    const { ignoreFlag, isIgnored, instanceName, eventType } = incident;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    const ignored = ignoreFlag === true || isIgnored === true;
    if (isLog) {
      updateLogPatternFlag(
        { projectName, instanceName, nid, ignoreFlag: !ignored, type: eventType },
        { [this.submitLoader]: true },
      );
    } else {
      const summaryList = R.map((event) => {
        return EventRenderers.BuildMetricAnomalySummary({ event });
      }, get(incident, ['rootCauseJson', 'rootCauseDetailsArr'], []));
      const content = R.join('\n', summaryList);
      updateMetricPatternFlag(
        { projectName, instanceName, nid, ignoreFlag: !ignored, content },
        { [this.submitLoader]: true },
      );
    }
  }

  render() {
    const { intl, errorMessage, incident, currentLoadingComponents } = this.props;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    const hasError = false;
    const { patternName, patternId, ignoreFlag, isIgnored } = incident || {};
    const ignored = ignoreFlag === true || isIgnored === true;

    return (
      <Modal
        width={650}
        title={intl.formatMessage(eventMessages.ignore)}
        visible
        footer={null}
        maskClosable={false}
        onCancel={() => this.handleOnClose()}
      >
        <div className="content" style={{ paddingBottom: 16 }}>
          <div className="ui info message" style={{ wordBreak: 'break-all' }}>
            {ignored && (
              <span>
                {intl.formatMessage(eventMessages.resetIgnoreStatus, {
                  pattern: patternName || patternId,
                  status: 'Ignore',
                })}
              </span>
            )}
            {!ignored && (
              <span>
                {intl.formatMessage(eventMessages.markIgnoreStatus, {
                  pattern: patternName || patternId,
                  status: 'Ignore',
                })}
              </span>
            )}
          </div>
        </div>
        {Boolean(errorMessage) && (
          <div className="error content">
            <div className="ui error mini message">
              <i className="icon warning sign" />
              <span>{intl.formatMessage(errorMessage.message, errorMessage.params)}</span>
            </div>
          </div>
        )}
        <div className="flex-row flex-end-justify actions">
          <Button size="small" style={{ backgroundColor: '#e0e1e2' }} onClick={this.handleOnClose}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>
          <Button
            type="primary"
            size="small"
            icon={<SaveOutlined />}
            style={{ marginLeft: 8 }}
            disabled={hasError}
            loading={isLoading}
            onClick={this.handleSumbit}
          >
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </div>
      </Modal>
    );
  }
}

const IgnoreModal = injectIntl(IgnoreModalCore);
export default connect(
  (state: State) => {
    const { currentLoadingComponents } = state.app;
    const { modalErrorMessage } = state.metric;
    return {
      currentLoadingComponents,
      errorMessage: modalErrorMessage,
    };
  },
  { updateMetricPatternFlag, updateLogPatternFlag },
)(IgnoreModal);
