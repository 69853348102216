import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { message, Spin, Tabs, Alert } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, GlobalParse } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import { eventMessages } from '../../../common/metric/messages';

import LikelyRootCausesList from './LikelyRootCausesList';

type Props = {
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  // eslint-disable-next-line
  compareIncidents: Array<Object>,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
};

class CompareIncidentsModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      activeKey: 'common',

      incidentsMap: {},
      sameKeysRootCauses: [],
      incidentsDiffRootCauseMap: {},
      hasCommonRC: false,
      hasDifferentRC: false,
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData() {
    const { intl, compareIncidents, credentials, projectDisplayMap } = this.props;

    this.setState({ isLoading: true });

    const request = [];
    R.forEach((incident) => {
      const {
        category,
        projectOwner,
        anomalyLogInstance,
        instanceList,
        startTimestamp,
        endTimestamp,
        patternId,
        type,
        rootCauseTableKey,
      } = incident;

      // parse params to get root cause
      let { projectName } = incident;
      projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
      const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
      const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
      const event = {
        nid: patternId,
        eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
      };

      request.push(
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceList[0],
          startTime,
          endTime,
          operation: 'rootCauseEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
      );
    }, compareIncidents || []);
    this.props.updateLastActionInfo();
    Promise.all(request)
      .then((results) => {
        const incidentsMap = {};
        R.forEach((incident) => {
          incidentsMap[incident.id] = incident;
        }, compareIncidents);

        const incidentsRootCauseMap = {};
        const incidentsRootCauseKeyMap = {};
        R.addIndex(R.forEach)((rootCausesData, idx) => {
          // RC
          const logRootCauseEvents = get(rootCausesData, ['logRootCauseEvents'], []);
          const metricRootCauseEvents = get(rootCausesData, ['metricRootCauseEvents'], []);
          const { relatedEventList } = GlobalParse.parseRootCauseInfo({
            operation: 'rootCauseEvents',
            chains: [...logRootCauseEvents, ...metricRootCauseEvents],
            credentials,
            projectDisplayMap,
          });

          const incident = compareIncidents[idx] || {};
          incidentsRootCauseMap[incident.id] = relatedEventList;

          const rootCauseKeyList = R.map((rootCause) => {
            const { actionRootCauseKey } = rootCause;
            return actionRootCauseKey;
          }, relatedEventList);
          incidentsRootCauseKeyMap[incident.id] = rootCauseKeyList;
        }, results);

        // compare root causes
        let sameKeys = [];
        R.addIndex(R.forEach)((keys, idx) => {
          if (idx === 0) {
            sameKeys = keys;
          } else {
            sameKeys = R.intersection(sameKeys, keys);
          }
        }, R.values(incidentsRootCauseKeyMap));
        const sameKeysRootCauseMap = {};
        R.forEachObjIndexed((rootCauses, incidentId) => {
          R.forEach((rootCause) => {
            const { actionRootCauseKey } = rootCause;
            if (sameKeys.includes(actionRootCauseKey)) {
              if (!R.has(actionRootCauseKey, sameKeysRootCauseMap)) {
                sameKeysRootCauseMap[actionRootCauseKey] = rootCause;
              }
            }
          }, rootCauses);
        }, incidentsRootCauseMap);
        const sameKeysRootCauses = R.values(sameKeysRootCauseMap);
        const hasCommonRC = sameKeys.length !== 0;

        // get diff info
        const incidentsDiffRootCauseKeyMap = R.mapObjIndexed((keys) => {
          return R.difference(keys, sameKeys);
        }, incidentsRootCauseKeyMap);
        const incidentsDiffRootCauseMap = R.mapObjIndexed((rootCauses, incidentId) => {
          return R.filter((rootCause) => {
            const { actionRootCauseKey } = rootCause;
            return (incidentsDiffRootCauseKeyMap[incidentId] || []).includes(actionRootCauseKey);
          }, rootCauses);
        }, incidentsRootCauseMap);
        const hasDifferentRC = R.reduce(R.concat, [], R.values(incidentsDiffRootCauseMap)).length !== 0;

        this.setState({
          isLoading: false,
          incidentsMap,
          sameKeysRootCauses,
          incidentsDiffRootCauseMap,
          hasCommonRC,
          hasDifferentRC,
        });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({
          isLoading: false,

          incidentsMap: {},
          sameKeysRootCauses: [],
          incidentsDiffRootCauseMap: {},
          hasCommonRC: false,
          hasDifferentRC: false,
        });
      });
  }

  @autobind
  renderCommonRootCauses() {
    const { environmentId, systemId, compareIncidents } = this.props;
    const { hasCommonRC, sameKeysRootCauses } = this.state;
    const incident = compareIncidents[0];
    return (
      <div className="full-height flex-col" style={{ paddingTop: 8 }}>
        {!hasCommonRC && (
          <div className="flex-grow flex-min-height">
            <Alert style={{ marginBottom: 8 }} message="No common root causes found." type="info" showIcon />
          </div>
        )}
        {hasCommonRC && (
          <div className="flex-grow flex-min-height overflow-y-auto">
            <LikelyRootCausesList
              isAutoHeight
              environmentId={environmentId}
              systemId={systemId}
              rootCauseList={sameKeysRootCauses}
              incident={incident}
              onReload={this.reloadData}
            />
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderDifferentRootCauses() {
    const { environmentId, systemId } = this.props;
    const { hasDifferentRC, incidentsMap, incidentsDiffRootCauseMap } = this.state;
    return (
      <div className="full-height flex-col" style={{ paddingTop: 8 }}>
        {!hasDifferentRC && (
          <div className="flex-grow flex-min-height">
            <Alert style={{ marginBottom: 8 }} message="No different root causes found." type="info" showIcon />
          </div>
        )}
        {hasDifferentRC && (
          <div className="flex-grow flex-min-height overflow-y-auto">
            {R.map(([incidentId, rootCauses]) => {
              const incident = incidentsMap[incidentId] || {};
              const { patternId, startTimestamp } = incident;
              return (
                <div key={incidentId} className="flex-col" style={{ marginBottom: 16 }}>
                  <div className="flex-row flex-center-align">
                    <span className="light-lable bold" style={{ marginRight: 16 }}>
                      Incident:
                    </span>
                    <span>{`${patternId} (${moment.utc(startTimestamp).format(Defaults.ShortDateTimeFormat)})`}</span>
                  </div>
                  <LikelyRootCausesList
                    isAutoHeight
                    environmentId={environmentId}
                    systemId={systemId}
                    rootCauseList={rootCauses}
                    incident={incident}
                    onReload={this.reloadData}
                  />
                </div>
              );
            }, R.toPairs(incidentsDiffRootCauseMap))}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { intl, onClose } = this.props;
    const { isLoading, activeKey } = this.state;

    return (
      <Modal
        visible
        title={intl.formatMessage(logMessages.relatedAnomalies)}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={1200}
        bodyStyle={{ height: 560 }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <Tabs
            type="card"
            className="full-width full-height ant-tabs-content-full-height"
            activeKey={activeKey}
            onChange={(activeKey) => {
              this.setState({ activeKey });
            }}
          >
            <Tabs.TabPane tab={intl.formatMessage(eventMessages.commonRootCauses)} key="common">
              {this.renderCommonRootCauses()}
            </Tabs.TabPane>
            <Tabs.TabPane tab={intl.formatMessage(eventMessages.differentRootCauses)} key="different">
              {this.renderDifferentRootCauses()}
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </Modal>
    );
  }
}

const CompareIncidentsModal = injectIntl(CompareIncidentsModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, projectDisplayMap, systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      currentLocale,
      projectDisplayMap,
      systemsMap,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(CompareIncidentsModal);
