import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const newIdpRegister = (params: Object) => {
  const { userName, idpInfo, ...rest } = params;
  return fetchPost(getEndpoint('newidpregistration'), {
    ...rest,
    UserName: userName,
    idpInfo: JSON.stringify(idpInfo),
  }).then((data) => ({
    redirectURL: data.url,
  }));
};

export default newIdpRegister;
