import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Input, Popover, Badge } from 'antd';

const SystemInsightWidgets = ({ widgets, onTakeItem, onDrop }) => {
  const [keyword, setKeyword] = useState('');
  const [costWidgets, setCostWidgets] = useState([]);
  const [kubernetesWidgets, setKubernetesWidgets] = useState([]);
  const [notCostWidgets, setNotCostWidgets] = useState([]);

  useEffect(() => {
    const costWidgets = [];
    const kubernetesWidgets = [];
    const notCostWidgets = [];
    R.forEach((item) => {
      if (item.isCloudCost) {
        costWidgets.push(item);
      } else if (item.isKubernetes) {
        kubernetesWidgets.push(item);
      } else {
        notCostWidgets.push(item);
      }
    }, widgets);
    setCostWidgets(costWidgets);
    setKubernetesWidgets(kubernetesWidgets);
    setNotCostWidgets(notCostWidgets);
  }, [widgets]);

  const renderWidgets = (widgets) => {
    return R.map((widget) => {
      const { id, title, disable, isCloudCost, hasCPSetting, isKubernetes } = widget;
      if (keyword && title.toLowerCase().indexOf(keyword.toLowerCase()) < 0) {
        return null;
      }

      return (
        <Popover
          key={id}
          title={
            disable && (
              <>
                {isCloudCost && (
                  <div className="font-12 text-info" style={{ color: 'var(--info-color)' }}>
                    You need to create a cost project in order to use the cost analysis widget
                  </div>
                )}
                {hasCPSetting && (
                  <div className="font-12 text-info" style={{ color: 'var(--info-color)' }}>
                    Please configure the capacity planning setting in the setting page to use the capacity planning
                    widget
                  </div>
                )}
                {isKubernetes && (
                  <div className="font-12 text-info" style={{ color: 'var(--info-color)' }}>
                    You need to create a Kubernetes project in order to use the cost analysis widget
                  </div>
                )}
              </>
            )
          }
          content={<div>{!!widget.desc && <div className="font-12 break-word ">{widget.desc}</div>}</div>}
          placement="top"
          overlayInnerStyle={{ maxWidth: 200 }}
        >
          <Badge size="small" count={widget.leftCount} overflowCount={3}>
            <div
              className="content-bg droppable-element corner-10"
              style={{
                padding: 4,
                width: 105,
                height: 90,
                cursor: !disable ? 'pointer' : 'not-allowed',
              }}
              draggable={!disable}
              unselectable="on"
              onDragStart={(e) => {
                e.dataTransfer.setData('text/plain', id);
                onTakeItem(id);
              }}
              onClick={(e) => {
                if (disable) return;
                onTakeItem(id);
                onDrop([], widget, 'click');
              }}
            >
              <div className="flex-col flex-center-align flex-center-justify text-center">
                <div
                  className="light-label corner-10"
                  style={{
                    fontSize: 30,
                    width: '100%',
                    border: '1px solid var(--border-color-base)',
                    marginBottom: 4,
                    background: disable ? 'var(--disabled-bg)' : '',
                  }}
                >
                  {widget.icon}
                </div>
                <div
                  className="font-12 bold"
                  style={{
                    color: disable ? 'var(--disabled-color)' : 'var(--text-color)',
                  }}
                >
                  {widget.title}
                </div>
              </div>
            </div>
          </Badge>
        </Popover>
      );
    }, widgets || []);
  };

  return (
    <div className="flex-col" style={{ padding: 8, position: 'relative' }}>
      <Input
        size="small"
        style={{ marginBottom: 8 }}
        value={keyword}
        placeholder="Search widgets"
        allowClear
        enterButton
        onChange={(e) => setKeyword(e.target.value)}
      />
      {notCostWidgets.length > 0 && (
        <>
          <div className="bold text-secondary">Graphs</div>
          <div className="flex-row flex-wrap flex-center-align" style={{ gap: 10 }}>
            {renderWidgets(notCostWidgets)}
          </div>
        </>
      )}

      {costWidgets.length > 0 && (
        <>
          <div className="bold text-secondary" style={{ marginTop: 20 }}>
            Cost project graphs
          </div>
          <div className="flex-row flex-wrap flex-center-align" style={{ gap: 10 }}>
            {renderWidgets(costWidgets)}
          </div>
        </>
      )}

      {kubernetesWidgets.length > 0 && (
        <>
          <div className="bold text-secondary" style={{ marginTop: 20 }}>
            Kubernetes
          </div>
          <div className="flex-row flex-wrap flex-center-align" style={{ gap: 10 }}>
            {renderWidgets(kubernetesWidgets)}
          </div>
        </>
      )}
    </div>
  );
};

export default SystemInsightWidgets;
