/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { NavLink } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Input } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { Container, AutoSizer, List } from '../../../lib/fui/react';
import { hideAppLoader } from '../../../common/app/actions';
import { buildLocation, buildUrl, parseLocation } from '../../../common/utils';

import { IntegrationsMetadata } from './dataSource';

import { appFieldsMessages, appMenusMessages } from '../../../common/app/messages';
import { settingsMenusMessages } from '../../../common/settings/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  appLoaderVisible: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,

  // eslint-disable-next-line
  push: Function,
  replace: Function,
  // eslint-disable-next-line
  hideAppLoader: Function,
  location: Object,
};

class ProjectIntegrationsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      category: 'Featured',
      dataSourceFilter: '',
      showMultiple: false,
      integrationsList: [],
    };
    this.categoryOptions = [
      { value: 'Featured', label: 'Featured integrations' },
      { value: 'All', label: 'All Categories' },
      { value: 'APM', label: 'APM' },
      { value: 'CI/CD', label: 'CI/CD' },
      { value: 'Databases', label: 'Databases' },
      { value: 'Incident Management', label: 'Incident Management' },
      { value: 'Log Monitoring', label: 'Log Monitoring' },
      { value: 'Event Monitoring', label: 'Event Monitoring' },
      { value: 'Metric Monitoring', label: 'Metric Monitoring' },
      { value: 'Microservices', label: 'Microservices' },
      { value: 'Federated Learning', label: 'Federated Learning' },
      { value: 'Custom', label: 'Custom' },
      { value: 'Cost', label: 'Cost' },
    ];
    this.categoryOptionsMap = R.fromPairs(R.map((item) => [item.value, item.label], this.categoryOptions));
    this.needInvert = [
      'AWS CloudWatch',
      'DataDog',
      'AWS Cost',
      'AWS CloudWatch',
      'Apache Kafka Metric',
      'Apache Kafka Log',
      'Zendesk',
      'VMWare VCenter',
      'Sentry',
      'Snowflake performance monitoring',
      'Nagios',
      'MariaDB',
      'Microsoft SQL Server',
      'Collectd',
      'CA Technology',
      'VMWare VCenter',
      'Dynatrace',
    ];
    this.multipleItem = ['DataDog'];
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }

    this.parseData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  parseData() {
    const { location } = this.props;
    const query = parseLocation(location);
    const { category } = query;
    this.setState({ category: category || 'Featured' }, () => {
      let integrationsList = IntegrationsMetadata || [];
      integrationsList = this.filterData(integrationsList);
      integrationsList = this.sortData(integrationsList);
      this.setState({ integrationsList });
      if (this.listNode) {
        this.listNode.forceUpdateGrid();
      }
    });
  }

  @autobind
  filterData(eventList) {
    const { category, dataSourceFilter } = this.state;

    let integrationsList = eventList;
    if (category && category !== 'All') {
      integrationsList = R.filter((item) => item.category.includes(category), integrationsList);
    }
    if (dataSourceFilter) {
      integrationsList = R.filter(
        (item) => R.toLower(item.display).indexOf(R.toLower(dataSourceFilter)) !== -1,
        integrationsList,
      );
    }
    return integrationsList;
  }

  @autobind
  sortData(eventList) {
    let integrationsList = eventList;
    integrationsList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('display')))], integrationsList);
    return integrationsList;
  }

  @autobind
  handleCategoryChange(category) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    this.setState({ category, dataSourceFilter: '' }, () => {
      let integrationsList = IntegrationsMetadata || [];
      integrationsList = this.filterData(integrationsList);
      integrationsList = this.sortData(integrationsList);
      this.setState({ integrationsList });
      replace(buildLocation(location.pathname, {}, { ...query, category }));
    });
  }

  @autobind
  handleSearchDataSource(e) {
    const { category } = this.state;
    const dataSourceFilter = e.target.value;
    const isAllCategory = category === 'All';
    const newState = { dataSourceFilter };
    if (!isAllCategory && dataSourceFilter) {
      newState.category = 'All';
    }
    this.setState(newState, () => {
      let integrationsList = IntegrationsMetadata || [];
      integrationsList = this.filterData(integrationsList);
      integrationsList = this.sortData(integrationsList);
      this.setState({ integrationsList });
    });
  }

  @autobind
  renderListItemCategory({ key, index: rowIndex, style, parent }) {
    const rowData = this.categoryOptions[rowIndex];
    if (!rowData) return null;

    const { value, label } = rowData;
    const active = value === this.state.category;
    return (
      <div
        key={key}
        className={`event-list-row clickable ${active ? ' active' : ''}`}
        style={{ ...style }}
        onClick={() => this.handleCategoryChange(value)}
      >
        <div className="row-column flex-row flex-center-align" style={{ width: 100, flex: 1, padding: '0 16px' }}>
          <div className="round-tag" style={{ display: 'inline-flex' }}>
            {label}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  handleDataSourceClick(rowData) {
    const { push } = this.props;
    const { category } = this.state;
    const { name, isBatch } = rowData;

    push(buildUrl(BaseUrls.SettingsProjectWizard, {}, { dataSource: name, category, batch: isBatch }));
  }

  render() {
    const { intl, currentTheme } = this.props;
    const { dataSourceFilter, integrationsList, showMultiple } = this.state;
    const isDark = currentTheme === 'dark';

    return (
      <Container fullHeight withGutter className="flex-col settings">
        <Container className="flex-row flex-center-align" breadcrumb>
          <div className="section">
            <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
            <span className="divider">/</span>
            <NavLink to={BaseUrls.SystemSetting}>
              <span className="label">{intl.formatMessage(settingsMenusMessages.systemSetting)}</span>
            </NavLink>
            <span className="divider">/</span>
            <NavLink to={BaseUrls.SettingsIntegrations}>
              <span className="label tour-integrations">{intl.formatMessage(appFieldsMessages.integrations)}</span>
            </NavLink>
          </div>
        </Container>

        <Container
          className="flex-grow flex-min-height flex-col content-bg corner-10"
          style={{
            margin: '0 16px 8px 16px',
            borderRadius: 4,
            padding: '8px 16px',
          }}
        >
          <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
            <div className="flex-row flex-grow flex-min-width font-22 bold tour-integrations-amazon">
              {intl.formatMessage(appFieldsMessages.integrations)}
              <Input
                size="large"
                style={{ width: 200, marginLeft: 16 }}
                placeholder="Filter Integrations..."
                allowClear
                prefix={<SearchOutlined style={{ fontSize: 16 }} />}
                value={dataSourceFilter}
                onChange={this.handleSearchDataSource}
              />
            </div>
          </div>
          <div className="flex-grow flex-min-height flex-row">
            <div className="" style={{ flex: 1, paddingRight: 16 }}>
              <AutoSizer>
                {({ width, height }) => (
                  <div className="event-list">
                    <List
                      ref={(r) => (this.ListNode = r)}
                      className="event-list-grid"
                      style={{ borderTopWidth: 1 }}
                      width={width}
                      height={height}
                      rowCount={this.categoryOptions.length}
                      rowHeight={60}
                      rowRenderer={this.renderListItemCategory}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
            <div className="" style={{ flex: 3 }}>
              <AutoSizer>
                {({ width, height }) => (
                  <div
                    style={{
                      width,
                      height,
                      border: '1px solid var(--border-color-base)',
                      padding: 8,
                      overflow: 'hidden auto',
                    }}
                  >
                    <div className="flex-row flex-wrap">
                      {R.map((item) => {
                        const { icon, display, type } = item;
                        const isMultipleItem = this.multipleItem.includes(display);
                        return (
                          <div
                            key={display}
                            style={{ width: 180, margin: 8, position: 'relative' }}
                            onMouseEnter={(e) => {
                              if (isMultipleItem) {
                                this.setState({ showMultiple: true });
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (isMultipleItem) {
                                this.setState({ showMultiple: false });
                              }
                            }}
                          >
                            {isMultipleItem && (
                              <div
                                className={`intergration-mask full-width full-height ${
                                  showMultiple ? 'mask-opacity' : ''
                                }`}
                                style={{
                                  position: 'absolute',
                                  left: 0,
                                  top: 0,
                                  zIndex: 1,
                                }}
                              >
                                <Button
                                  type="primary"
                                  className="mask-button"
                                  size="small"
                                  onClick={() => this.handleDataSourceClick(item)}
                                >
                                  Create Project
                                </Button>
                                <Button
                                  type="primary"
                                  className="mask-button"
                                  size="small"
                                  onClick={() => this.handleDataSourceClick({ ...item, isBatch: true })}
                                >
                                  Create Projects In Batch
                                </Button>
                              </div>
                            )}

                            <Card
                              key={display + icon}
                              hoverable
                              style={{ width: '100%' }}
                              cover={
                                <img
                                  alt="SentryImg"
                                  src={icon}
                                  style={{
                                    padding: 10,
                                    height: 170,
                                    objectFit: 'contain',
                                    filter: isDark && this.needInvert.includes(display) ? 'invert(1)' : 'none',
                                  }}
                                />
                              }
                              onClick={() => this.handleDataSourceClick(item)}
                            >
                              <Card.Meta
                                style={{ wordBreak: 'break-all' }}
                                title={display}
                                description={
                                  <div className="flex-row flex-center-align">
                                    <div className="radio" />
                                    <div style={{ padding: '0 4px' }}>{type}</div>
                                  </div>
                                }
                              />
                            </Card>
                          </div>
                        );
                      }, integrationsList)}
                    </div>
                  </div>
                )}
              </AutoSizer>
            </div>
          </div>
        </Container>
      </Container>
    );
  }
}

const ProjectIntegrations = injectIntl(ProjectIntegrationsCore);
export default connect(
  (state) => {
    const { appLoaderVisible, projects, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    const { location } = state.router;

    return {
      location,
      appLoaderVisible,
      projects,
      credentials,
      userInfo,
      currentTheme,
    };
  },
  { push, hideAppLoader, replace },
)(ProjectIntegrations);
