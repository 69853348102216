/* @flow */

import { defineMessages } from 'react-intl';

const logMessages = defineMessages({
  errorNoIncident: {
    defaultMessage: 'The log incident cannot find, please refresh and try again.',
    id: 'log.error.errorNoIncident',
  },
  infoRerunTriggerred: {
    defaultMessage: 'Rerun detection for this log incident has been triggerred.',
    id: 'log.error.infoRerunTriggerred',
  },
  totalEvents: {
    defaultMessage: 'Total Events',
    id: 'log.totalEvents',
  },
  totalLog: {
    defaultMessage: 'Total Log',
    id: 'log.totalLog',
  },
  totalLogs: {
    defaultMessage: 'Total Logs',
    id: 'log.totalLogs',
  },
  totalAlert: {
    defaultMessage: 'Total Alert',
    id: 'log.totalAlert',
  },
  totalAlerts: {
    defaultMessage: 'Total Alerts',
    id: 'log.totalAlerts',
  },
  allEvents: {
    defaultMessage: 'All events (including ignored events)',
    id: 'log.allEvents',
  },
  importantLog: {
    defaultMessage: 'Anomalous log entries',
    id: 'log.importantLog',
  },
  allLogEntries: {
    defaultMessage: 'All log entries',
    id: 'log.allLogEntries',
  },
  allAnomalousLogs: {
    defaultMessage: 'All anomalous logs',
    id: 'log.allAnomalousLogs',
  },
  totalLogEntries: {
    defaultMessage: 'Total log entries',
    id: 'log.totalLogEntries',
  },
  totalAnomalousLogEntries: {
    defaultMessage: 'Total anomalous log entries',
    id: 'log.totalAnomalousLogEntries',
  },
  currentTotal: {
    defaultMessage: 'Current total',
    id: 'log.currentTotal',
  },
  importantAlert: {
    defaultMessage: 'Anomalous alert entries',
    id: 'log.importantAlert',
  },
  keywordSearch: {
    defaultMessage: 'Keyword Search',
    id: 'log.keywordSearch',
  },
  allAlertEntries: {
    defaultMessage: 'All alert entries',
    id: 'log.allAlertEntries',
  },
  logTypeFilter: {
    defaultMessage: 'Log Type Filter',
    id: 'log.logTypeFilter',
  },
  rerunHotEventDetection: {
    defaultMessage: 'Rerun Hot Event Detection',
    id: 'log.rerunHotEventDetection',
  },
  rerunKeywordDetection: {
    defaultMessage: 'Rerun Keyword Detection',
    id: 'log.rerunKeywordDetection',
  },
  hideIgnoredEvents: {
    defaultMessage: 'Hide Ignored Events',
    id: 'log.hideIgnoredEvents',
  },
  showEnableRCA: {
    defaultMessage: 'Show Enable RCA',
    id: 'log.showEnableRCA',
  },
  hideNoResultsEvents: {
    defaultMessage: 'Hide Events with no Root Causes',
    id: 'log.hideNoResultsEvents',
  },
  logPush: {
    defaultMessage: 'Push Log',
    id: 'log.logPush',
  },
  clusters: {
    defaultMessage: 'Total Patterns',
    id: 'log.clusters',
  },
  newClusters: {
    defaultMessage: 'New Patterns',
    id: 'log.newClusters',
  },
  rareEvents: {
    defaultMessage: 'Rare Events',
    id: 'log.rareEvents',
  },
  hotEventsPeriods: {
    defaultMessage: 'Hot Events',
    id: 'log.hotEventsPeriods',
  },
  coldEventsPeriods: {
    defaultMessage: 'Cold Events',
    id: 'log.coldEventsPeriods',
  },
  numOfCriticalEvent: {
    defaultMessage: 'Critical Events',
    id: 'log.numOfCriticalEvent',
  },
  numOfWhitelistEvent: {
    defaultMessage: 'Whitelist Events',
    id: 'log.numOfWhitelistEvent',
  },
  keywordAlerts: {
    defaultMessage: 'Keyword Alerts',
    id: 'log.keywordAlerts',
  },
  numOfIncidentEvent: {
    defaultMessage: 'Incident Events',
    id: 'log.numOfIncidentEvent',
  },
  monthly: {
    defaultMessage: 'Back To Calendar',
    id: 'log.monthly',
  },
  logSequences: {
    defaultMessage: 'Sequences',
    id: 'log.logSequences',
  },
  commonLogSequences: {
    defaultMessage: 'Common Log Sequences',
    id: 'log.commonLogSequences',
  },
  anomalyLogSequences: {
    defaultMessage: 'Anomaly Log Sequences',
    id: 'log.anomalyLogSequences',
  },
  keywords: {
    defaultMessage: 'Keywords',
    id: 'log.keywords',
  },
  topKeywords: {
    defaultMessage: 'Top Keywords',
    id: 'log.topKeywords',
  },
  total: {
    defaultMessage: 'Total',
    id: 'log.total',
  },
  currentCluster: {
    defaultMessage: 'Current Pattern',
    id: 'log.currentCluster',
  },
  currentKeyword: {
    defaultMessage: 'Current Keyword',
    id: 'log.currentKeyword',
  },
  top10Patterns: {
    defaultMessage: 'Top 10 Patterns',
    id: 'log.top10Patterns',
  },
  top10Keywords: {
    defaultMessage: 'Top 10 Keywords',
    id: 'log.top10Keywords',
  },
  commonSequence: {
    defaultMessage: 'Common Sequence',
    id: 'log.commonSequence',
  },
  anomalySequence: {
    defaultMessage: 'Anomaly Sequence',
    id: 'log.anomalySequence',
  },
  anomalyScore: {
    defaultMessage: 'Anomaly score',
    id: 'log.anomalyScore',
  },
  fixedIncident: {
    defaultMessage: 'Fixed Incident',
    id: 'log.fixedIncident',
  },
  changeEvent: {
    defaultMessage: 'Change Event',
    id: 'log.changeEvent',
  },
  selectFeature: {
    defaultMessage: 'Select Feature',
    id: 'log.selectFeature',
  },
  metricanomaly: {
    defaultMessage: 'Metric',
    id: 'log.metricanomaly',
  },
  ublanomaly: {
    defaultMessage: 'Metric',
    id: 'log.ublanomaly',
  },
  normal: {
    defaultMessage: 'Normal',
    id: 'log.normal',
  },
  rare: {
    defaultMessage: 'Rare',
    id: 'log.rare',
  },
  hot: {
    defaultMessage: 'Hot',
    id: 'log.hot',
  },
  cold: {
    defaultMessage: 'Cold',
    id: 'log.cold',
  },
  critical: {
    defaultMessage: 'Critical',
    id: 'log.critical',
  },
  newpattern: {
    defaultMessage: 'New',
    id: 'log.newpattern',
  },
  whitelist: {
    defaultMessage: 'WhiteList',
    id: 'log.whitelist',
  },
  incident: {
    defaultMessage: 'Incident',
    id: 'log.incident',
  },
  deployment: {
    defaultMessage: 'Change',
    id: 'log.deployment',
  },
  alert: {
    defaultMessage: 'Alert',
    id: 'log.alert',
  },
  hotExplain: {
    defaultMessage: 'those anomalous logs have abnormally high frequency',
    id: 'log.hotExplain',
  },
  newpatternExplain: {
    defaultMessage:
      'those anomalous logs have a new pattern that never appeared in the past user-defined period (e.g.,7 days)',
    id: 'log.newpatternExplain',
  },
  coldExplain: {
    defaultMessage: 'those anomalous logs have abnormally low frequency',
    id: 'log.coldExplain',
  },
  whitelistExplain: {
    defaultMessage: 'those anomalous logs have matched whitelist keyword labels',
    id: 'log.whitelistExplain',
  },
  rareExplain: {
    defaultMessage: 'those anomalous logs have unusual patterns that are rarely seen',
    id: 'log.rareExplain',
  },
  clickForSample: {
    defaultMessage: 'Click for Sample log entry',
    id: 'log.clickForSample',
  },
  clusterSampleLog: {
    defaultMessage: 'Latest Sample Log Records',
    id: 'log.clusterSampleLog',
  },
  fieldKeywords: {
    defaultMessage: 'Field Keywords',
    id: 'log.fieldKeywords',
  },
  featureKeyword: {
    defaultMessage: 'Feature Keyword',
    id: 'log.featureKeyword',
  },
  featureKeywordDistribution: {
    defaultMessage: 'Feature Keyword Distribution (Top 10)',
    id: 'log.featureKeywordDistribution',
  },
  featureKeywordDistributionAll: {
    defaultMessage: 'Feature Keyword Distribution',
    id: 'log.featureKeywordDistributionAll',
  },
  featureKeywordMapping: {
    defaultMessage: 'Feature Keyword Mapping (Top 10)',
    id: 'log.featureKeywordMapping',
  },
  featureKeywordMappingAll: {
    defaultMessage: 'Feature Keyword Mapping',
    id: 'log.featureKeywordMappingAll',
  },
  sampleLog: {
    defaultMessage: 'Sample Log',
    id: 'log.sampleLog',
  },
  sampleLogEntry: {
    defaultMessage: 'Sample Log Records',
    id: 'log.sampleLogEntry',
  },
  patternSampleLog: {
    defaultMessage: '[Pattern {patternName}] Sample Log [{labelIdx}]',
    id: 'log.patternSampleLog',
  },
  rankNumber: {
    defaultMessage: 'Rank',
    id: 'log.rankNumber',
  },
  timeRange: {
    defaultMessage: 'Time Range',
    id: 'log.timeRange',
  },
  dateTime: {
    defaultMessage: 'DateTime',
    id: 'log.dateTime',
  },
  dateTimes: {
    defaultMessage: 'DateTimes',
    id: 'log.dateTimes',
  },
  labels: {
    defaultMessage: 'Labels',
    id: 'log.labels',
  },
  type: {
    defaultMessage: 'Pattern type',
    id: 'log.type',
  },
  count: {
    defaultMessage: 'Count',
    id: 'log.count',
  },
  ignored: {
    defaultMessage: 'Ignored',
    id: 'log.ignored',
  },
  probability: {
    defaultMessage: 'Probability',
    id: 'log.probability',
  },
  accumulatedProbability: {
    defaultMessage: 'Accumulated probability',
    id: 'log.accumulatedProbability',
  },
  minDelay: {
    defaultMessage: 'Min Delay',
    id: 'log.minDelay',
  },
  maxDelay: {
    defaultMessage: 'Max delay',
    id: 'log.maxDelay',
  },
  estimatedLeadTime: {
    defaultMessage: 'Estimated Lead Time',
    id: 'log.estimatedLeadTime',
  },
  estimatedDelay: {
    defaultMessage: 'Estimated delay',
    id: 'log.estimatedDelay',
  },
  estimatedMinDelay: {
    defaultMessage: 'Estimated Min Delay',
    id: 'log.estimatedMinDelay',
  },
  estimatedMaxDelay: {
    defaultMessage: 'Estimated Max Delay',
    id: 'log.estimatedMaxDelay',
  },
  occurrenceTime: {
    defaultMessage: 'Estimated Occurrence Time',
    id: 'log.occurrenceTime',
  },
  tag: {
    defaultMessage: 'Tag',
    id: 'log.tag',
  },
  patternAndTrace: {
    defaultMessage: 'Pattern / Trace',
    id: 'log.patternAndTrace',
  },
  pattern: {
    defaultMessage: 'Pattern',
    id: 'log.pattern',
  },
  patternName: {
    defaultMessage: 'Pattern Name',
    id: 'log.patternName',
  },
  patternNameKey: {
    defaultMessage: 'Pattern name key',
    id: 'log.patternNameKey',
  },
  patternId: {
    defaultMessage: 'Pattern ID',
    id: 'log.patternId',
  },
  category: {
    defaultMessage: 'Category',
    id: 'log.category',
  },
  logEntry: {
    defaultMessage: 'Records',
    id: 'log.logEntry',
  },
  investigate: {
    defaultMessage: 'Investigate',
    id: 'log.investigate',
  },
  context: {
    defaultMessage: 'Context',
    id: 'log.context',
  },
  linkedEvents: {
    defaultMessage: 'Linked Events',
    id: 'log.linkedEvents',
  },
  metricEvents: {
    defaultMessage: 'Metric Events',
    id: 'log.metricEvents',
  },
  logEvents: {
    defaultMessage: 'Log Events',
    id: 'log.logEvents',
  },
  status: {
    defaultMessage: 'Status',
    id: 'log.status',
  },
  anomalousLogSize: {
    defaultMessage: 'Anomalous Log Size',
    id: 'log.anomalousLogSize',
  },
  originalSize: {
    defaultMessage: 'Original Size',
    id: 'log.originalSize',
  },
  decreaseRatio: {
    defaultMessage: 'Decrease Ratio',
    id: 'log.decreaseRatio',
  },
  federatedLearning: {
    defaultMessage: 'Federated Learning Reduction Ratio',
    id: 'log.federatedLearning',
  },
  alertReductionRatio: {
    defaultMessage: 'Alert Reduction Ratio',
    id: 'log.alertReductionRatio',
  },
  extractedSize: {
    defaultMessage: 'Extracted Size',
    id: 'log.extractedSize',
  },
  extractedRatio: {
    defaultMessage: 'Extracted Ratio',
    id: 'log.extractedRatio',
  },
  compressedSize: {
    defaultMessage: 'Compressed Size',
    id: 'log.compressedSize',
  },
  decompressed: {
    defaultMessage: 'Decompressed',
    id: 'log.decompressed',
  },
  compressed: {
    defaultMessage: 'Compressed',
    id: 'log.compressed',
  },
  highlightOption: {
    defaultMessage: 'Highlight Option',
    id: 'log.highlightOption',
  },
  difference: {
    defaultMessage: 'Difference',
    id: 'log.difference',
  },
  common: {
    defaultMessage: 'Common',
    id: 'log.common',
  },
  none: {
    defaultMessage: 'None',
    id: 'log.none',
  },
  frequency: {
    defaultMessage: 'Frequency',
    id: 'log.frequency',
  },
  deviation: {
    defaultMessage: 'Deviation',
    id: 'log.deviation',
  },
  clusterFrequencyLower: {
    defaultMessage: 'Frequency(count {count}) of this pattern is {percent}% lower than normal.',
    id: 'log.clusterFrequencyLower',
  },
  clusterFrequencyHigher: {
    defaultMessage: 'Frequency(count {count}) of this pattern is {percent}% higher than normal.',
    id: 'log.clusterFrequencyHigher',
  },
  noLogEntryFound: {
    defaultMessage: 'No log entries for this time period could be found.',
    id: 'log.noLogEntryFound',
  },
  instance: {
    defaultMessage: 'Instance',
    id: 'log.instance',
  },
  showPie: {
    defaultMessage: 'View Feature Statistics',
    id: 'log.showPie',
  },
  showSampleLog: {
    defaultMessage: 'View Sample Logs',
    id: 'log.showSampleLog',
  },
  predictedEvents: {
    defaultMessage: 'Predicted Events',
    id: 'log.predictedEvents',
  },
  rootCauseChain: {
    defaultMessage: 'Likely root cause chains',
    id: 'log.rootCauseChain',
  },
  rootCauseList: {
    defaultMessage: 'Likely root cause list',
    id: 'log.rootCauseList',
  },
  relatedAnomalies: {
    defaultMessage: 'Related Alerts/Anomalies',
    id: 'log.relatedAnomalies',
  },
  precedingAnomalies: {
    defaultMessage: 'Log source root causes',
    id: 'log.precedingAnomalies',
  },
  trailingAnomalies: {
    defaultMessage: 'Prediction chain rank list',
    id: 'log.trailingAnomalies',
  },
  major: {
    defaultMessage: 'Major',
    id: 'log.major',
  },
  minor: {
    defaultMessage: 'Minor',
    id: 'log.minor',
  },
  noRootCauseFound: {
    defaultMessage: 'No likely root causes',
    id: 'log.noRootCauseFound',
  },
  noTriageReport: {
    defaultMessage: 'No summary notes',
    id: 'log.noTriageReport',
  },
  noServiceNowNotes: {
    defaultMessage: 'No ServiceNow Notes',
    id: 'log.noServiceNowNotes',
  },
  noRelatedAnomaly: {
    defaultMessage: 'No Related Alert/Anomaly is found.',
    id: 'log.noRelatedAnomaly',
  },
  recommendations: {
    defaultMessage: 'Recommendations',
    id: 'log.recommendations',
  },
  LLMRecommendation: {
    defaultMessage: 'LLM recommendation',
    id: 'log.LLMRecommendation',
  },
  incidentSummary: {
    defaultMessage: 'Incident summary',
    id: 'log.incidentSummary',
  },
  alertSummary: {
    defaultMessage: 'Alert summary',
    id: 'log.alertSummary',
  },
  metricAnomalieSummary: {
    defaultMessage: 'Metric anomaly summary',
    id: 'log.metricAnomalieSummary',
  },
  logAnomalieSummary: {
    defaultMessage: 'Log anomaly summary',
    id: 'log.logAnomalieSummary',
  },
  traceAnomalieSummary: {
    defaultMessage: 'Trace anomaly summary',
    id: 'log.traceAnomalieSummary',
  },
  changeSummary: {
    defaultMessage: 'Change summary',
    id: 'log.changeSummary',
  },
  predictionSummary: {
    defaultMessage: 'Prediction summary',
    id: 'log.predictionSummary',
  },
  rootCauseSummary: {
    defaultMessage: 'Root cause summary',
    id: 'log.rootCauseSummary',
  },
  systemHealthSummary: {
    defaultMessage: 'System health summary',
    id: 'log.systemHealthSummary',
  },
  recommendedSteps: {
    defaultMessage: 'Recommended action steps',
    id: 'log.recommendedSteps',
  },
  noRecommendationsFound: {
    defaultMessage: 'No recommendations for this anomaly event.',
    id: 'log.noRecommendationsFound',
  },
  validationTime: {
    defaultMessage: 'Validation Time',
    id: 'log.validationTime',
  },
  triggeredAction: {
    defaultMessage: 'Triggered Action',
    id: 'log.triggeredAction',
  },
  triggeredActions: {
    defaultMessage: 'Triggered actions',
    id: 'log.triggeredActions',
  },
  severity: {
    defaultMessage: 'Severity',
    id: 'log.severity',
  },
  noTriggeredActionsFound: {
    defaultMessage: 'No triggered actions for this anomaly event.',
    id: 'log.noTriggeredActionsFound',
  },
  historicalDetectedIncident: {
    defaultMessage: 'Historical Detected Incident',
    id: 'log.historicalDetectedIncident',
  },
  correspondingDetectedIncident: {
    defaultMessage: 'Corresponding Detected Incident',
    id: 'log.correspondingDetectedIncident',
  },
  leadTimeSummary: {
    defaultMessage: 'Lead Time Summary',
    id: 'log.leadTimeSummary',
  },
  noHistoricalIncidentFound: {
    defaultMessage: 'No historical detected incident found.',
    id: 'log.noHistoricalIncidentFound',
  },
  noCorrespondingIncidentFound: {
    defaultMessage: 'No corresponding detected incident found.',
    id: 'log.noCorrespondingIncidentFound',
  },
  matchedIncident: {
    defaultMessage: 'Matched Incident',
    id: 'log.matchedIncident',
  },
  matchedPredictions: {
    defaultMessage: 'Matched Predictions',
    id: 'log.matchedPredictions',
  },
  unmatchedIncident: {
    defaultMessage: 'Unmatched Incident',
    id: 'log.unmatchedIncident',
  },
  unmatchedPredictions: {
    defaultMessage: 'Unmatched Predictions',
    id: 'log.unmatchedPredictions',
  },
  predictionCount: {
    defaultMessage: 'Prediction Count',
    id: 'log.predictionCount',
  },
  fixedPrediction: {
    defaultMessage: 'Fixed Predictions',
    id: 'log.fixedPrediction',
  },
  actionTriggered: {
    defaultMessage: 'Actions Triggered',
    id: 'log.actionTriggered',
  },
  futurePredictions: {
    defaultMessage: 'Future Predictions',
    id: 'log.futurePredictions',
  },
  invalidPrediction: {
    defaultMessage: 'Invalid Predictions',
    id: 'log.invalidPrediction',
  },
  validIncidents: {
    defaultMessage: 'Valid incidents',
    id: 'log.validIncidents',
  },
  invalidIncidents: {
    defaultMessage: 'Invalid incidents',
    id: 'log.invalidIncidents',
  },
  ignoredIncidents: {
    defaultMessage: 'Ignored incidents',
    id: 'log.ignoredIncidents',
  },
  validPredictions: {
    defaultMessage: 'Valid Predictions',
    id: 'log.validPredictions',
  },
  historicalPredictions: {
    defaultMessage: 'Historical Predictions',
    id: 'log.historicalPredictions',
  },
  totalMatch: {
    defaultMessage: 'Total Match',
    id: 'log.totalMatch',
  },
  avgLeadTime: {
    defaultMessage: 'Avg Lead Time',
    id: 'log.avgLeadTime',
  },
  predictionAccuracy: {
    defaultMessage: 'Prediction Accuracy',
    id: 'log.predictionAccuracy',
  },
  predictionLabels: {
    defaultMessage: 'Prediction labels',
    id: 'log.predictionLabels',
  },
  precision: {
    defaultMessage: 'Precision',
    id: 'log.precision',
  },
  recall: {
    defaultMessage: 'Recall',
    id: 'log.recall',
  },
  wordSentenceRegex: {
    defaultMessage: 'word, sentence or regex',
    id: 'log.wordSentenceRegex',
  },
  regexPlaceHolderForIncident: {
    defaultMessage:
      '(support nested json): key=value(value can be a word, sentence or regex)' +
      '\ne.g key1->key2=value (means if the given key’s value contains the value, then matched)' +
      '\nkey1=.* (means any values of this key will be matched)',
    id: 'log.regexPlaceHolderForIncident',
  },
  regexPlaceHolderForWhiteList: {
    defaultMessage: 'values can be word, sentence or regex',
    id: 'log.regexPlaceHolderForWhiteList',
  },
  regexPlaceHolderForWhiteListJsonKey: {
    defaultMessage: 'support nested json, key1->key2',
    id: 'log.regexPlaceHolderForWhiteListJsonKey',
  },
  regexPlaceHolderForOther: {
    defaultMessage: '(support nested json): key e.g key1->key2 or key1',
    id: 'log.regexPlaceHolderForOther',
  },
  monthlyLogAnalysis: {
    defaultMessage: 'Monthly log analysis',
    id: 'log.monthlyLogAnalysis',
  },
  dailyLogAnalysis: {
    defaultMessage: 'Daily log analysis',
    id: 'log.dailyLogAnalysis',
  },
  Monthly: {
    defaultMessage: 'Monthly',
    id: 'log.Monthly',
  },
  Daily: {
    defaultMessage: 'Daily',
    id: 'log.Daily',
  },
  Analysis: {
    defaultMessage: 'Analysis',
    id: 'log.Analysis',
  },
  compareIncidents: {
    defaultMessage: 'Compare incidents',
    id: 'log.compareIncidents',
  },
  compareCommonLogs: {
    defaultMessage: 'Compare logs',
    id: 'log.compareCommonLogs',
  },
  deleteIncidents: {
    defaultMessage: 'Delete Incidents',
    id: 'log.deleteIncidents',
  },
  lookBackTimeWindow: {
    defaultMessage: 'Look back time window',
    id: 'log.lookBackTimeWindow',
  },
  extendTimeBy: {
    defaultMessage: 'Extend time by',
    id: 'log.extendTimeBy',
  },
  duration5Minutes: {
    defaultMessage: '5 minutes',
    id: 'log.duration5Minutes',
  },
  duration10Minutes: {
    defaultMessage: '10 minutes',
    id: 'log.duration10Minutes',
  },
  duration30Minutes: {
    defaultMessage: '30 minutes',
    id: 'log.duration30Minutes',
  },
  duration1Hour: {
    defaultMessage: '1 hour',
    id: 'log.duration1Hour',
  },
  duration4Hours: {
    defaultMessage: '4 hours',
    id: 'log.duration4Hours',
  },
  duration12Hours: {
    defaultMessage: '12 hours',
    id: 'log.duration12Hours',
  },
  duration24Hours: {
    defaultMessage: '24 hours',
    id: 'log.duration24Hours',
  },
  active: {
    defaultMessage: 'Active',
    id: 'log.active',
  },
  resolved: {
    defaultMessage: 'Auto resolved',
    id: 'log.resolved',
  },
});

export default logMessages;
