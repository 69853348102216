import React from 'react';
import TrendsChart from '../components/TrendsChart';

export default function TopCostService(props: Object) {
  return (
    <TrendsChart
      {...props}
      showMaxNum
      title="Service"
      subTitle="Trend top cost"
      type="anomalousInstances"
      cacheKey="anomalousInstances"
    />
  );
}
