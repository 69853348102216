/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* @flow */
import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';
import app from './app/reducer';
import auth from './auth/reducer';
import dashboard from './dashboard/reducer';
import query from './query/reducer';
import metric from './metric/reducer';
import job from './job/reducer';
import log from './log/reducer';
import causal from './causal/reducer';
import usecase from './usecase/reducer';
import settings from './settings/reducer';
import report from './report/reducer';

const configureReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    dashboard,
    query,
    metric,
    job,
    log,
    causal,
    usecase,
    settings,
    report,
  });

export default configureReducer;
