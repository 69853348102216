import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { EntityFilter } from './EntityFilter';
import { appFieldsMessages } from '../../common/app/messages';
import { EntityFilterEx } from './EntityFilterEx';

const applyMetricList = (allMetricList, noDataList, anomalyEntityMap) => {
  const allEntityTreeMap = { children: {} };
  const noDataMap = noDataList.reduce((acc, e) => ({ ...acc, [e]: true }), noDataList);
  const allRawEntityList = [];
  allMetricList.forEach((metric) => {
    const m = {
      ...metric,
      title: metric.name,
      inactive: !!noDataMap[metric.id],
      hasAnomaly: !!anomalyEntityMap[metric.id],
    };
    allEntityTreeMap.children[metric.id] = m;
    allRawEntityList.push(m);
  });

  return { allRawEntityList, allEntityTreeMap };
};

export const MetricFilter = ({
  intl,
  allMetricList = [],
  anomalyList = [],
  noDataList = [],
  selectionMap = {},
  onChange,
  onRefresh,
  loading,
  getEntityTitle = () => {},
}) => {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    allEntityTreeMap: {},
    allRawEntityList: [],
    anomalyEntityMap: {},
  });

  useEffect(() => {
    const anomalyEntityMap = R.reduce((acc, i) => ({ ...acc, [i]: true }), {}, anomalyList);
    setState({ anomalyEntityMap });
  }, [anomalyList]);

  const { anomalyEntityMap } = state;

  useEffect(() => {
    const { allRawEntityList, allEntityTreeMap } = applyMetricList(allMetricList, noDataList, anomalyEntityMap);
    setState({ allRawEntityList, allEntityTreeMap });
  }, [allMetricList, noDataList, anomalyEntityMap]);

  const { allRawEntityList, allEntityTreeMap } = state;

  return (
    <EntityFilterEx
      entityType={intl.formatMessage(appFieldsMessages.metric)}
      allRawEntityList={allRawEntityList || []}
      allEntityTreeMap={allEntityTreeMap || {}}
      selectionMap={selectionMap || {}}
      onChange={(selectionMap) => {
        onChange(selectionMap);
      }}
      loading={loading}
      onRefresh={onRefresh}
      getEntityTitle={getEntityTitle}
    />
  );
};
