import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef, memo } from 'react';
import '../style/index.less';
import L from 'lodash';
import * as R from 'ramda';
import { Button, Checkbox, Space } from 'antd';
import { DownOutlined, UpOutlined, ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { settingsMessages } from '../../../../common/settings/messages';
import { appFieldsMessages } from '../../../../common/app/messages';

type TressListProps = {
  intl: Object,
  targetKeys: Array,
  isTatget: Boolean,
  selectedNum: Number,
  setSelectedNum: Function,
  selectedNumTarget: Function,
  setSelectedNumTarget: Function,
  dataSource: Array,
  setDataSource: Function,
  setTargetSource: Function,
};
const TreeList = forwardRef((props: TressListProps, ref) => {
  const {
    intl,
    targetKeys = [],
    isTatget = false,
    selectedNum,
    setSelectedNum,
    selectedNumTarget,
    setSelectedNumTarget,
    dataSource = [],
    setDataSource,
    setTargetSource,
    cloudType,
    agentType,
    projectFilter,
    allCheckDisabled,
    allProjects,
  } = props;
  const [allCheckAll, setAllCheckAll] = useState(false);

  useImperativeHandle(ref, () => ({
    onAdd,
    onRemove,
    setAllCheckAll,
    isTatget,
  }));

  const handleClickTitle = (e, systemKey, isCheckbox) => {
    e.preventDefault();
    e.stopPropagation();
    let num = isTatget ? selectedNumTarget : selectedNum;
    const newTreeData = L.map(dataSource, (item) => {
      if (item.systemId === systemKey) {
        if (isCheckbox) {
          item.checked = !item.checked;
          item.children.forEach((p) => {
            if (!targetKeys.includes(p.key)) {
              if (p.checked !== item.checked && (isTatget || p.show)) {
                num = item.checked ? num + 1 : num - 1;
              }
              p.checked = isTatget || p.show ? item.checked : p.checked;
            }
          });
        } else {
          item.expand = !item.expand;
        }
      }
      return item;
    });
    if (isTatget && isCheckbox) {
      setSelectedNumTarget(num);
    } else if (isCheckbox) {
      setSelectedNum(num);
    }

    if (!isTatget) {
      setDataSource(newTreeData);
    } else {
      setTargetSource(newTreeData);
    }

    const selectSystem = L.filter(newTreeData, (n) => n.checked);
    const showSystem = L.filter(newTreeData, (n) => isTatget || n.show);
    const newAllCheckAll = R.difference(showSystem, selectSystem).length === 0;
    setAllCheckAll(newAllCheckAll);
  };

  const handleClickProject = (e, projectKey, hasProject) => {
    e.preventDefault();
    e.stopPropagation();

    if (hasProject) return;
    const diff = L.clone(dataSource);
    // eslint-disable-next-line
    for (const key in diff) {
      const system = diff[key];
      const { children } = system;
      // eslint-disable-next-line
      for (const p in children) {
        const project = children[p];
        if (project.key === projectKey) {
          project.checked = !project.checked;

          if (isTatget) {
            setSelectedNumTarget((prevValue) => {
              return project.checked ? prevValue + 1 : prevValue - 1;
            });
          } else {
            setSelectedNum((prevValue) => {
              return project.checked ? prevValue + 1 : prevValue - 1;
            });
          }

          const selectProject = L.filter(children, (n) => n.checked);
          const showChildrens = L.filter(children, (n) => isTatget || n.show);
          system.checked = R.difference(showChildrens, selectProject).length === 0;
          break;
        }
      }
    }
    if (!isTatget) {
      setDataSource(diff);
    } else {
      setTargetSource(diff);
    }

    const selectSystem = L.filter(diff, (n) => n.checked);
    const showSystem = L.filter(diff, (n) => isTatget || n.show);
    const newAllCheckAll = R.difference(showSystem, selectSystem).length === 0;
    setAllCheckAll(newAllCheckAll);
  };

  const handleChangeRoot = (e) => {
    const notFilter = !cloudType && !agentType && !projectFilter;
    let num = notFilter ? 0 : isTatget ? selectedNumTarget : selectedNum;
    const { checked } = e.target;
    const checkAllSystem = L.map(dataSource, (s) => {
      if (!targetKeys.includes(s.key) && (isTatget || s.show)) {
        s.checked = checked;
      }
      L.forEach(s.children, (p) => {
        if (!targetKeys.includes(p.key) && (isTatget || p.show)) {
          p.checked = checked;
          num = p.checked ? num + 1 : num - 1;
        }
      });
      return { ...s };
    });

    if (isTatget) {
      setSelectedNumTarget(checked ? num : notFilter ? 0 : num);
    } else {
      setSelectedNum(checked ? num : notFilter ? 0 : num);
    }

    if (!isTatget) {
      setDataSource(checkAllSystem);
    } else {
      setTargetSource(checkAllSystem);
    }
    setAllCheckAll(checked);
  };

  const onGetProjectTotal = () => {
    let total = 0;
    // eslint-disable-next-line
    for (const system in dataSource) {
      const s = dataSource[system];
      // eslint-disable-next-line
      for (const p in s.children) {
        total += 1;
      }
    }
    return total;
  };

  const onAdd = (callback) => {
    const showActives = [];
    const showNotActives = [];
    const targetKeys = [];
    const targetSource = L.reduce(
      dataSource,
      (prev, cur) => {
        const children = L.filter(cur.children, (p) => {
          if (p.checked) {
            targetKeys.push(p.key);
            if (p.show) showActives.push(p.key);
            return true;
          } else if (p.show) showNotActives.push(p.key);
        });
        if (L.isEmpty(children)) {
          return prev;
        } else {
          const selectProject = L.filter(cur.children, (n) => n.checked);
          const showChildrens = L.filter(cur.children, (n) => isTatget || n.show);
          const diffFlag = R.difference(showChildrens, selectProject).length === 0;
          if (diffFlag && showChildrens.length > 0) {
            targetKeys.push(cur.key);
            showActives.push(cur.key);
          }
          return [...prev, { ...cur, children }];
        }
      },
      [],
    );

    const disabledFlag = R.difference(showActives, targetKeys).length === 0 && showNotActives.length === 0;

    if (typeof callback === 'function') {
      callback({ targetSource, targetKeys, disabledFlag });
    }

    setSelectedNum(0);
    setSelectedNumTarget(0);
  };

  const onRemove = (callback) => {
    let removeItemsNum = 0;
    const showActives = [];
    const showNotActives = [];
    const targetKeys = [];
    const targetSource = L.reduce(
      L.cloneDeep(dataSource),
      (prev, cur) => {
        const children = L.filter(cur.children, (p) => {
          if (!p.checked) {
            targetKeys.push(p.key);
            if (p.show) showNotActives.push(p.key);
            return true;
          } else if (p.show) showActives.push(p.key);
          removeItemsNum += 1;
        });
        if (L.isEmpty(children)) {
          return prev;
        } else {
          const selectProject = L.filter(cur.children, (n) => !n.checked);
          const showChildrens = L.filter(cur.children, (n) => isTatget || n.show);
          const diffFlag = R.difference(showChildrens, selectProject).length === 0;
          if (diffFlag && showChildrens.length > 0) {
            targetKeys.push(cur.key);
          } else {
            showActives.push(cur.key);
          }
          return [...prev, { ...cur, children }];
        }
      },
      [],
    );

    const disabledFlag = R.difference(showActives, targetKeys).length === 0 && showNotActives.length === 0;

    if (typeof callback === 'function') {
      callback({ targetSource, targetKeys, disabledFlag });
    }

    // eslint-disable-next-line no-return-assign
    setSelectedNum((prevValue) => (removeItemsNum += prevValue));
    setSelectedNumTarget(0);
  };

  return (
    <>
      <div className="batch-transfer-container">
        <h4 className="transfer-select-items">{isTatget ? onGetProjectTotal() : onGetProjectTotal()} Items</h4>
        <div className="transfer-header corner-8-8-0-0">
          <div className="transfer-header-item header-checkbox">
            <Checkbox
              checked={allCheckAll}
              disabled={isTatget ? false : allCheckDisabled}
              onChange={handleChangeRoot}
            />
            {intl.formatMessage(appFieldsMessages.systemName)}
            {/* <DownOutlined /> */}
          </div>
          <div className="transfer-header-item">{intl.formatMessage(settingsMessages.cloudType)}</div>
          <div className="transfer-header-item">{intl.formatMessage(settingsMessages.agentType)}</div>
        </div>
        <div className="transfer-content">
          {L.map(
            dataSource,
            ({ key: systemKey, title: systemName, checked, expand, children, show: showSystem }, index) => {
              const hasSystem = !isTatget && targetKeys.includes(systemKey);

              return (
                <div
                  className="transfer-group"
                  key={systemKey}
                  style={{ display: isTatget ? 'block' : showSystem ? 'block' : 'none' }}
                >
                  <div className="transfer-title" onClick={(e) => handleClickTitle(e, systemKey, false)}>
                    <Checkbox
                      checked={checked}
                      value={systemKey}
                      disabled={hasSystem}
                      onClick={(e) => handleClickTitle(e, e.target.value, true)}
                    >
                      {systemName}
                    </Checkbox>

                    {expand ? <DownOutlined /> : <UpOutlined />}
                  </div>
                  <div className="transfer-list" style={{ height: expand ? 'auto' : 0 }}>
                    {L.map(
                      children,
                      (
                        {
                          key: projectKey,
                          title: projectTitle,
                          customerName,
                          cloudType,
                          insightAgentType,
                          checked: projectValue,
                          show = true,
                          systemName,
                        },
                        i,
                      ) => {
                        const hasProject = !isTatget && targetKeys.includes(projectKey);
                        const findProject = R.find(
                          (p) => p.systemName === systemName && p.projectShortName === projectTitle,
                          allProjects || [],
                        );
                        return (
                          <div
                            className={`transfer-list-row ${hasProject ? 'disable-text' : ''}`}
                            key={projectKey}
                            onClick={(e) => handleClickProject(e, projectKey, hasProject)}
                            style={{ display: isTatget ? 'flex' : show ? 'flex' : 'none' }}
                          >
                            <div className="transfer-list-item list-checkbox">
                              <Checkbox
                                checked={projectValue}
                                value={projectKey}
                                disabled={hasProject}
                                onClick={(e) => handleClickProject(e, e.target.value, hasProject)}
                              >
                                {findProject?.projectDisplayName || projectTitle}
                              </Checkbox>
                            </div>
                            <div className="transfer-list-item">{cloudType}</div>
                            <div className="transfer-list-item">{insightAgentType}</div>
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
    </>
  );
});

const TreeMemo = memo(TreeList);

const TransferTree = (props) => {
  const {
    intl,
    style,
    data = [],
    targetData = [],
    keys = [],
    onChange,
    reload,
    cloudType,
    agentType,
    projectFilter,
    allProjects,
  } = props;
  const originRef = useRef({});
  const targetRef = useRef({});

  const [selectedNum, setSelectedNum] = useState(0);
  const [selectedNumTarget, setSelectedNumTarget] = useState(0);

  const [dataSource, setDataSource] = useState(data);
  const [targetSource, setTargetSource] = useState(targetData);
  const [targetKeys, setTargetKeys] = useState(keys);

  const [allCheckDisabled, setAllCheckDisabled] = useState(false);

  const handleAdd = () => {
    const { onAdd } = originRef.current;
    onAdd(({ targetSource, targetKeys, disabledFlag }) => {
      const deep = L.cloneDeep(targetSource);
      const targetCheckToNot = L.map(deep, (system) => {
        const children = L.map(system.children, (project) => ({ ...project, checked: false }));
        return { ...system, checked: false, expand: true, children };
      });
      setTargetKeys(targetKeys);
      setTargetSource(targetCheckToNot);
      setSelectedNum(0);
      setAllCheckDisabled(disabledFlag);
      if (typeof onChange === 'function') {
        onChange({
          targetSource: targetCheckToNot,
          targetKeys,
        });
      }
    });
  };
  const handleRemove = () => {
    const { onRemove, setAllCheckAll, isTatget } = targetRef.current;

    const selectSystem = L.filter(targetSource, (n) => n.checked);
    const showSystem = L.filter(targetSource, (n) => isTatget || n.show);
    const newAllCheckAll = R.difference(showSystem, selectSystem).length === 0;
    setAllCheckAll(newAllCheckAll);

    onRemove(({ targetSource, targetKeys, disabledFlag }) => {
      const targetNotCheck = L.map(targetSource, (system) => {
        const children = L.map(system.children, (project) => ({ ...project, checked: false }));
        return { ...system, checked: false, children };
      });
      setTargetKeys(targetKeys);
      setTargetSource(targetNotCheck);
      setAllCheckDisabled(disabledFlag);

      if (targetSource.length === 0) {
        setAllCheckAll(false);
      }

      if (typeof onChange === 'function') {
        onChange({
          targetSource: targetNotCheck,
          targetKeys,
        });
      }
    });
  };

  const Operation = () => {
    return (
      <Space direction="vertical" className="transfer-operation-buttons">
        <div>{selectedNum} Selected</div>
        <Button type="primary" size="small" onClick={handleAdd} disabled={selectedNum === 0}>
          Add To List
          <ArrowRightOutlined />
        </Button>
        <Button type="primary" size="small" onClick={handleRemove} disabled={selectedNumTarget === 0}>
          <ArrowLeftOutlined />
          Remove From List
        </Button>
      </Space>
    );
  };

  useEffect(() => {
    setDataSource(data);
    setTargetSource(targetData);
    setTargetKeys(keys);
  }, [reload]);

  useEffect(() => {
    const { setAllCheckAll } = originRef.current;
    const systems = L.map(
      dataSource,
      (cur) => {
        cur.expand = true;
        const filterArr = [cloudType, agentType];
        L.forEach(cur.children, (project) => {
          project.show = true;

          if (projectFilter) {
            project.show = R.toLower(project?.projectDisplayName || '').includes(R.toLower(projectFilter || ''));
          }

          if (cloudType) {
            project.show = project.show && filterArr.includes(project.cloudType);
          }

          if (agentType) {
            project.show = project.show && filterArr.includes(project.insightAgentType);
          }
        });
        const showSystem = L.filter(cur.children, (p) => !p.show);
        cur.show = showSystem.length !== cur.children.length;
        const selectProject = L.filter(cur.children, (n) => n.checked);
        const showChildrens = L.filter(cur.children, (n) => n.show);
        cur.checked = R.difference(showChildrens, selectProject).length === 0;
        return { ...cur, children: cur.children };
      },
      [],
    );

    let newTargetKeys = [];
    const prvTargetKeys = [];
    const showNotActives = [];
    if (systems.length > 0) {
      const selectSystem = L.filter(systems, (n) => n.checked);
      const showSystem = L.filter(systems, (n) => n.show);
      const newAllCheckAll = R.difference(showSystem, selectSystem).length === 0;
      setAllCheckAll(newAllCheckAll);

      R.forEach((cur) => {
        R.forEach((p) => {
          prvTargetKeys.push(p.key);
        }, cur.children || []);
        const showChildrens = L.filter(cur.children, (n) => n);
        if (showChildrens.length > 0) {
          prvTargetKeys.push(cur.key);
        }
      }, targetSource || []);

      R.forEach((cur) => {
        R.forEach((p) => {
          if (p.checked) {
            if (p.show) newTargetKeys.push(p.key);
          } else if (p.show) showNotActives.push(p.key);
        }, cur.children || []);

        const selectProject = L.filter(cur.children, (n) => n.checked);
        const showChildrens = L.filter(cur.children, (n) => n.show);
        const diff = R.difference(showChildrens, selectProject);
        const prvIncludesKey = R.filter(
          (item) => !R.includes(item.key, prvTargetKeys),
          L.filter(cur.children, (n) => n.checked && n.show) || [],
        );

        if (diff.length === 0 && showChildrens.length > 0 && prvIncludesKey.length === 0) {
          newTargetKeys.push(cur.key);
        }
      }, systems || []);
    }

    const diffFlag =
      R.difference(newTargetKeys, prvTargetKeys).length === 0 &&
      showNotActives.length === 0 &&
      prvTargetKeys.length > 0;
    newTargetKeys = R.filter((item) => R.includes(item, prvTargetKeys), newTargetKeys);

    setDataSource(systems);
    setTargetSource(targetSource);
    setTargetKeys(newTargetKeys);
    setAllCheckDisabled(diffFlag);
  }, [cloudType, agentType, projectFilter]);

  return (
    <div className="batch-transfer-tree" style={style}>
      <TreeMemo
        intl={intl}
        ref={originRef}
        dataSource={dataSource}
        setDataSource={setDataSource}
        targetKeys={targetKeys}
        selectedNum={selectedNum}
        setSelectedNum={setSelectedNum}
        selectedNumTarget={selectedNumTarget}
        setSelectedNumTarget={setSelectedNumTarget}
        cloudType={cloudType}
        agentType={agentType}
        projectFilter={projectFilter}
        allCheckDisabled={allCheckDisabled}
        allProjects={allProjects}
      />
      <Operation />
      <TreeMemo
        intl={intl}
        ref={targetRef}
        isTatget
        dataSource={targetSource}
        setTargetSource={setTargetSource}
        selectedNum={selectedNum}
        setSelectedNum={setSelectedNum}
        selectedNumTarget={selectedNumTarget}
        setSelectedNumTarget={setSelectedNumTarget}
        cloudType={cloudType}
        agentType={agentType}
        projectFilter={projectFilter}
        allProjects={allProjects}
      />
    </div>
  );
};

export default TransferTree;
