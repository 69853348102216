/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
// import * as R from 'ramda';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Tabs } from 'antd';

import { Container, Modal } from '../../../lib/fui/react';
// import { Defaults } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import ServiceTeamsWorkspaceModal from './ServiceTeamsWorkspaceModal';
import ServiceTeamsWebhookModal from './ServiceTeamsWebhookModal';

type Props = {
  // eslint-disable-next-line
  onClose: Function,
  refresh: Number,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  replace: Function,
};

class ServiceSlackModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      activeKey: 'workspace',
    };
  }

  componentDidMount() {}

  render() {
    const { onClose } = this.props;
    const { activeKey } = this.state;
    return (
      <Modal width={1024} title="Microsoft Teams" visible maskClosable={false} onCancel={() => onClose()} footer={null}>
        <Container className="flex-col" style={{ height: 500 }}>
          <Tabs
            size="small"
            type="card"
            className="full-height ant-tabs-content-full-height"
            activeKey={activeKey}
            onChange={(activeKey) => this.setState({ activeKey })}
          >
            <Tabs.TabPane tab="Workspace" key="workspace">
              <div className="full-height flex-row" style={{ paddingTop: 8 }}>
                <ServiceTeamsWorkspaceModal refresh={this.props.refresh} onClose={onClose} />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Webhook" key="webhook">
              <div className="full-height flex-row" style={{ paddingTop: 8 }}>
                <ServiceTeamsWebhookModal refresh={this.props.refresh} onClose={onClose} />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Container>
      </Modal>
    );
  }
}

const ServiceSlackModal = injectIntl(ServiceSlackModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return { location, systemsMap, userInfo, credentials };
  },
  { replace, updateLastActionInfo },
)(ServiceSlackModal);
