/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { round, get } from 'lodash';
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getMetricLineChartsBaseLine } from '../../apis';
import { appMessages } from '../../app/messages';
import calculationBaseLineLogic from './newMetricLineChartsBaselineLogic';

const loadMetricEventLineChartsBaselineLogic = createLogic({
  type: [ActionTypes.LOAD_METRIC_EVENT_LINECHARTS_BASELINE],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,

  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials, userInfo } = state.auth;
    const { projects, userList } = state.app;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { projectName, endTimeObj, metricList } = params;

    dispatch(showLoading);
    dispatch(updateLastActionInfo());

    let projectOwner;
    if (projectName && projectName.indexOf('@') >= 0) {
      projectOwner = projectName.split('@')[1];
    }
    const proj = R.find((p) => p.projectName === projectName, projects);
    if (!projectOwner && proj) {
      projectOwner = projectOwner || proj.owner;
    }

    // use user/customer time zone to set isCurrentDay
    let { timezoneOffset } = state.app;
    if (userInfo.isAdmin && projectOwner) {
      const customerUserInfo = R.find((user) => user.userName === projectOwner, userList || []);
      timezoneOffset = customerUserInfo ? customerUserInfo.userZoneOffset : timezoneOffset;
    }
    // should use project timezone
    {
      const projectTimeZone = get(proj, 'timezone');
      if (projectTimeZone) {
        const zone = momenttz.tz(projectTimeZone);
        timezoneOffset = zone.utcOffset();
      }
    }

    const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;

    let progressCount = 0;
    const allProgress = (proms, progressCB) => {
      if (progressCount === 0) progressCB(0);
      R.forEach((request) => {
        request.then(() => {
          progressCount += 1;
          progressCB((progressCount * 100) / proms.length);
        });
      }, proms);
      return Promise.all(proms);
    };

    const requests = [];
    requests.push(
      getMetricLineChartsBaseLine(credentials, {
        ...params,
      }),
    );
    if (endTimeObj.valueOf() >= nowTimestamp) {
      requests.push(
        getMetricLineChartsBaseLine(credentials, {
          ...params,
          withOnlyPredict: true,
        }),
      );
    }
    await allProgress(requests, (progress) => {
      const baseLineLoadingPercentage = round(progress, 2);
      dispatch(hideLoading);
      dispatch(
        createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_BASELINE_PROGRESS, params, {
          baseLineLoadingPercentage,
        }),
      );
    })
      .then((dataList) => {
        const { datasetInstanceBaselineList, eventLineChartBaselineData } = calculationBaseLineLogic(
          dataList,
          metricList,
        );
        dispatch(
          createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_BASELINE, params, {
            datasetInstanceBaselineList,
            eventLineChartBaselineData,
          }),
        );
      })
      .catch((err) => {
        console.error('[IF_API] Failed get linechart baseline', err);
        dispatch(
          errorLoading(appMessages.errorsAPIMessage, {
            message: err.message,
            error: err,
          }),
        );
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }
        dispatch(hideLoading);
        done();
      });
  },
});

export default loadMetricEventLineChartsBaselineLogic;
