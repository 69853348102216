/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { postProjectInstanceGroup } from '../../apis';
import { apiEpicErrorHandle } from '../../errors';
import { getLoaderEpicAction } from '../../utils';
import { showAppAlert, updateLastActionInfo } from '../../app/actions';

import { settingsMessages } from '../messages';

const saveProjectInstanceGroupEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('SAVE_PROJECT_INSTANCE_GROUP').concatMap((action) => {
    const { params, loader, callback } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);
    const { componentChange } = params;

    const apiAction$ = Observable.from(postProjectInstanceGroup(credentials, params))
      .concatMap(() => {
        if (R.type(callback) === 'Function') {
          callback();
        }

        return Observable.concat(
          Observable.of(showAppAlert('info', settingsMessages.infoProjectSettingSaved)),
          componentChange
            ? Observable.of(showAppAlert('info', settingsMessages.infoComponentChange, {}, { duration: 0 }))
            : Observable.empty(),
          hideLoader,
        );
      })
      .catch((err) => {
        return Observable.concat(apiEpicErrorHandle(err), hideLoader);
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default saveProjectInstanceGroupEpic;
