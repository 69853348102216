import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Button, message, Spin } from 'antd';

import fetchPost from '../../../../src/common/apis/fetchPost';
import getEndpoint from '../../../../src/common/apis/getEndpoint';
import { Defaults } from '../../../../src/common/app';
import { buildUrl, Defaults as DefaultsUtils } from '../../../../src/common/utils';
import BaseUrls from '../../../../src/web/app/BaseUrls';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Modal,
  Popover,
  Table,
} from '../../../../src/lib/fui/react';

import { eventMessages } from '../../../../src/common/metric/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../src/common/app/messages';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

export default function StatusDetailsModel({ intl, userInfo, credentials, activeEvent, onClose }: Object) {
  const dataTableNode = useRef(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [detailsData, setDetailsData] = useState([]);

  const getTitle = (activeEvent) => {
    const { keyType } = activeEvent || {};
    let title = intl.formatMessage(eventMessages.trainingStatus);
    if (keyType === 'detection') {
      title = intl.formatMessage(eventMessages.detectionStatus);
    } else if (keyType === 'baseline') {
      title = intl.formatMessage(eventMessages.baselineStatus);
    }
    return title;
  };

  const getData = (activeEvent) => {
    const { replayTrainingTimelines, replayBaselineTimelines, replayDetectionTimelines, keyType } = activeEvent || {};
    let data = replayTrainingTimelines || [];
    if (keyType === 'baseline') {
      data = replayBaselineTimelines || [];
    } else if (keyType === 'detection') {
      data = replayDetectionTimelines || [];
    }
    return data;
  };

  useEffect(() => {
    let cancel = false;
    if (cancel) return;
    const title = getTitle(activeEvent);
    const data = getData(activeEvent);
    setTitle(title);
    setDetailsData(data);
    setLoading(false);
    return () => {
      cancel = true;
    };
  }, [activeEvent]);

  const contentRender = ({ dataKey, parent, rowIndex, cellData, columnIndex, rowData }) => {
    const { startTime, endTime } = rowData;
    const isTime = ['startTime', 'endTime'].includes(dataKey);
    const timeContent = (
      <div style={{ padding: '8px 0' }}>
        <div className="hidden-line-with-ellipsis">
          <span style={{ width: 64, display: 'inline-block' }}>{intl.formatMessage(appFieldsMessages.startTime)}:</span>
          <span>{moment.utc(startTime).format(Defaults.TimeFormat)}</span>
        </div>
        <div className="hidden-line-with-ellipsis">
          <span style={{ width: 64, display: 'inline-block' }}>{intl.formatMessage(appFieldsMessages.endTime)}:</span>
          <span>{moment.utc(endTime).format(Defaults.TimeFormat)}</span>
        </div>
      </div>
    );
    const content = isTime ? timeContent : cellData;
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Popover title={null} content={content} placement="top" mouseEnterDelay={0.3}>
          <span className="hidden-line-with-ellipsis">{content}</span>
        </Popover>
      </CellMeasurer>
    );
  };

  const handleStatusRerun = (rowData) => {
    const { projectName, userName, timestamp, startTime, endTime } = rowData;
    const { keyType } = activeEvent;
    setLoading(true);
    fetchPost(getEndpoint('metricanalysismanage'), {
      ...credentials,
      projectName,
      customerName: userName,
      timestamp,
      rerunTaskList: JSON.stringify([{ s: startTime, e: endTime, type: keyType }]),
    })
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          message.success(msg);
          onClose(true);
        } else {
          setLoading(false);
          console.error(msg);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(String(err));
      });
  };

  const statusRenderer = ({ rowData, columnIndex, dataKey, parent, rowIndex }) => {
    const { status = [] } = rowData;
    const { isFinished } = status[0] || {};
    let color = DefaultsUtils.ColorStatusFont.success;
    let content = intl.formatMessage(eventMessages.success);
    if (!isFinished) {
      color = DefaultsUtils.ColorStatusFont.info;
      content = intl.formatMessage(eventMessages.inProgress);
    }
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div className="flex-row flex-center-align" style={{ textTransform: 'capitalize', padding: '4px 0' }}>
          <div className="hidden-line-with-ellipsis clickable" style={{ color, marginRight: 8 }}>
            {content}
          </div>
          <Button size="small" onClick={() => handleStatusRerun(rowData)}>
            {intl.formatMessage(eventMessages.rerun)}
          </Button>
        </div>
      </CellMeasurer>
    );
  };

  const handleJumpLineChart = (rowData) => {
    const { userName } = rowData;
    let { startTime, endTime } = rowData;
    let { projectName } = rowData;
    projectName =
      userInfo.isAdmin || userInfo.isLocalAdmin || userName !== userInfo.userName
        ? `${projectName}@${userName}`
        : projectName;
    startTime = moment.utc(startTime).startOf('day').valueOf();
    endTime = moment.utc(endTime).endOf('day').valueOf();

    const params = {
      projectName,
      modelType: 'Holistic',
      startTimestamp: startTime,
      endTimestamp: endTime,
      withBaseline: true,
      customerName: userName,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, params), '_blank');
  };

  const expandRender = ({ dataKey, parent, rowIndex, rowData }) => {
    return (
      <Button size="small" onClick={() => handleJumpLineChart(rowData)}>
        {intl.formatMessage(eventMessages.data)}
      </Button>
    );
  };

  return (
    <Modal
      visible
      title={title}
      onCancel={() => onClose()}
      width={900}
      bodyStyle={{ height: 600 }}
      footer={
        <div>
          <Button size="small" onClick={() => onClose()}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>
        </div>
      }
    >
      <Container className="full-height">
        <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-width">
          <div className="flex-col full-width">
            <div
              className="text-right"
              style={{ marginBottom: 8 }}
              onClick={() => {
                onClose(true);
                setLoading(true);
              }}
            >
              <Button size="small" type="primary">
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </div>
            <div className="flex-grow">
              <AutoSizer>
                {({ width, height }) => (
                  <Table
                    className="with-border"
                    width={width}
                    height={height}
                    deferredMeasurementCache={cellMeasureCache}
                    headerHeight={40}
                    rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                    rowHeight={cellMeasureCache.rowHeight}
                    rowCount={detailsData.length}
                    rowGetter={({ index }) => detailsData[index]}
                    headerClassName="flex-row flex-center-align"
                    ref={(c) => {
                      dataTableNode.current = c;
                    }}
                  >
                    <Column
                      width={100}
                      label={intl.formatMessage(eventMessages.projectName)}
                      dataKey="projectName"
                      cellRenderer={contentRender}
                      disableSort
                    />
                    <Column
                      width={130}
                      flexGrow={1}
                      label={intl.formatMessage(eventMessages.fileName)}
                      dataKey="fileName"
                      cellRenderer={contentRender}
                    />
                    <Column
                      width={194}
                      flexShrink={0}
                      label={intl.formatMessage(appFieldsMessages.time)}
                      dataKey="startTime"
                      cellRenderer={contentRender}
                    />
                    <Column
                      width={195}
                      label={title}
                      dataKey="status"
                      disableSort
                      flexShrink={0}
                      cellRenderer={statusRenderer}
                    />
                    <Column
                      width={65}
                      label={null}
                      dataKey="isExpand"
                      flexShrink={0}
                      cellRenderer={expandRender}
                      disableSort
                    />
                  </Table>
                )}
              </AutoSizer>
            </div>
          </div>
        </Spin>
      </Container>
    </Modal>
  );
}
