/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { postProjectMetricAllSettings } from '../../apis';
import { loadProjectMetricSettings, setProjectMetricSettingExcludedMetrics } from '../actions';
import { apiEpicErrorHandle } from '../../errors';
import { getLoaderEpicAction } from '../../utils';
import { updateLastActionInfo } from '../../app/actions';

const saveProjectMetricSettingsEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('SAVE_PROJECT_METRIC_SETTINGS').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(postProjectMetricAllSettings(credentials, params))
      .concatMap((data) => {
        const appAlert = Observable.of(setProjectMetricSettingExcludedMetrics(data));
        return Observable.concat(appAlert, hideLoader);
      })
      .catch((err) => {
        return Observable.concat(apiEpicErrorHandle(err), hideLoader);
      });

    return Observable.concat(
      showLoader,
      Observable.of(updateLastActionInfo()),
      apiAction$,
      Observable.of(loadProjectMetricSettings(params)),
    );
  });

export default saveProjectMetricSettingsEpic;
