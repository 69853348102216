import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import update from 'immutability-helper';
import { get, isNumber } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { LineChartOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import { Spin, message, Tag, Button, Select, Tabs, Alert, Checkbox } from 'antd';

// import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { Defaults, GlobalParse, buildUrl, GlobalRenderers, parseLocation } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal, AutoSizer, List, CellMeasurerCache, CellMeasurer, Popover } from '../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

import RootCauseChainSelectModal from './RootCauseChainSelectModal';
import EventActionModal from '../../metric/components/EventActionModal';
import RootCausesModal from './RootCausesModal';
import RootCauseTimelineList from './RootCauseTimelineList';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  environmentId: String,
  systemId: String,
  isLoading: Boolean,
  incident: Object,
  rootCausesData: Object,
  predictedData: Object,
  onReload: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  timezoneOffset: Number,
  // eslint-disable-next-line
  currentLocale: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
  // eslint-disable-next-line
  globalInfo: Object,
};

class LikelyRootCausesCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this[`cellMeasureCacheRC-logRC`] = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this[`cellMeasureCacheRC-metricRC`] = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this[`cellMeasureCachePT-logPT`] = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this[`cellMeasureCachePT-metricPT`] = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.state = {
      isLoading: false,
      activeKey: 'logRC',

      // filter
      rootCauseFilterInstanceOptionsMap: {},
      rootCauseFilterInstanceMap: {},
      rootCauseFilterMetricOptionsMap: {},
      rootCauseFilterMetricMap: {},
      predictedFilterInstanceOptionsMap: {},
      predictedFilterInstanceMap: {},
      predictedFilterMetricOptionsMap: {},
      predictedFilterMetricMap: {},

      allRootCauseListMap: {},
      allPredictedListMap: {},
      activeChain: null,

      // take action
      rootCauseIncident: null,
      operation: null,
      showRootCauseChainSelect: false,
      actionRootCauseKey: null,
      showActionModal: false,

      // details
      showRootCausesModal: false,
    };

    this.relevanceOptions = [
      { label: 'Unspecified', value: 'none', color: 'currentColor' },
      { label: 'High', value: 'high', color: 'red' },
      { label: 'Medium', value: 'medium', color: 'orange' },
      { label: 'Low', value: 'low', color: 'gray' },
    ];
    this.relevanceColorMap = R.fromPairs(R.map((item) => [item.value, item.color], this.relevanceOptions));
    this.summarySettingsMap = {};
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.rootCausesData !== this.props.rootCausesData ||
      nextProps.predictedData !== this.props.predictedData
    ) {
      this.parseData(nextProps);
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  parseData(props) {
    const { credentials, projectDisplayMap, rootCausesData, predictedData, location, globalInfo } = props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    this.setState({ isLoading: true });

    // RC
    const logRootCauseEvents = get(rootCausesData, ['logRootCauseEvents'], []);
    const metricRootCauseEvents = get(rootCausesData, ['metricRootCauseEvents'], []);
    const {
      relatedEventList: allRootCauseListLog,
      allInstances: allInstancesRootCauseLog,
      allMetrics: allMetricsRootCauseLog,
    } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: logRootCauseEvents,
      credentials,
      projectDisplayMap,
    });
    const {
      relatedEventList: allRootCauseListMetric,
      allInstances: allInstancesRootCauseMetric,
      allMetrics: allMetricsRootCauseMetric,
    } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: metricRootCauseEvents,
      credentials,
      projectDisplayMap,
    });
    const allRootCauseListMap = { logRC: allRootCauseListLog, metricRC: allRootCauseListMetric };

    // PT
    const logPredictedEvents = get(predictedData, ['logPredictedEvents'], []);
    const metricPredictedEvents = get(predictedData, ['metricPredictedEvents'], []);
    const {
      relatedEventList: allPredictedListLog,
      allInstances: allInstancesPredictedLog,
      allMetrics: allMetricsPredictedLog,
    } = GlobalParse.parseRootCauseInfo({
      operation: 'predictedEvents',
      chains: logPredictedEvents,
      credentials,
      projectDisplayMap,
    });
    const {
      relatedEventList: allPredictedListMetric,
      allInstances: allInstancesPredictedMetric,
      allMetrics: allMetricsPredictedMetric,
    } = GlobalParse.parseRootCauseInfo({
      operation: 'predictedEvents',
      chains: metricPredictedEvents,
      credentials,
      projectDisplayMap,
    });
    const allPredictedListMap = { logPT: allPredictedListLog, metricPT: allPredictedListMetric };

    // build filter options
    const rootCauseFilterInstanceOptionsMap = {
      logRC: R.map((value) => {
        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, value);
        return { value, label: instanceStr };
      }, allInstancesRootCauseLog),
      metricRC: R.map((value) => {
        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, value);
        return { value, label: instanceStr };
      }, allInstancesRootCauseMetric),
    };
    const rootCauseFilterMetricOptionsMap = {
      logRC: R.map((value) => ({ value, label: value }), allMetricsRootCauseLog),
      metricRC: R.map((value) => ({ value, label: value }), allMetricsRootCauseMetric),
    };

    const predictedFilterInstanceOptionsMap = {
      logPT: R.map((value) => {
        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, value);
        return { value, label: instanceStr };
      }, allInstancesPredictedLog),
      metricPT: R.map((value) => {
        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, value);
        return { value, label: instanceStr };
      }, allInstancesPredictedMetric),
    };
    const predictedFilterMetricOptionsMap = {
      logPT: R.map((value) => ({ value, label: value }), allMetricsPredictedLog),
      metricPT: R.map((value) => ({ value, label: value }), allMetricsPredictedMetric),
    };

    let { activeKey } = this.state;
    if (allRootCauseListLog.length !== 0) {
      activeKey = 'logRC';
    } else if (allRootCauseListMetric.length !== 0) {
      activeKey = 'metricRC';
    } else if (allPredictedListLog.length !== 0) {
      activeKey = 'logPT';
    } else if (allPredictedListMetric.length !== 0) {
      activeKey = 'metricPT';
    }

    this[`cellMeasureCacheRC-logRC`].clearAll();
    this[`cellMeasureCacheRC-metricRC`].clearAll();
    this[`cellMeasureCachePT-logPT`].clearAll();
    this[`cellMeasureCachePT-metricPT`].clearAll();
    this.setState({
      isLoading: false,
      activeKey,

      rootCauseFilterInstanceOptionsMap,
      rootCauseFilterMetricOptionsMap,
      predictedFilterInstanceOptionsMap,
      predictedFilterMetricOptionsMap,

      allRootCauseListMap,
      allPredictedListMap,
    });
  }

  @autobind
  handleOverallLineChartClick(e) {
    const { intl, credentials, incident } = this.props;
    const { allRootCauseListMap } = this.state;

    // build project metric/instance info map
    const projectMap = {};
    R.forEach((chainList) => {
      R.forEach((chain) => {
        const { sourceInfoList } = chain;
        R.forEach((sourceInfo) => {
          const { sourceDetail, instanceName, owner, displayTimestamp } = sourceInfo;
          const { isMetric, content } = sourceDetail || {};
          if (isMetric) {
            let { projectName } = sourceInfo;
            projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;

            if (!R.has(projectName, projectMap)) {
              projectMap[projectName] = {
                projectName,
                times: [],
                instances: [],
                metrics: [],
                metricsMap: {},
              };
            }

            projectMap[projectName].times.push(displayTimestamp);
            projectMap[projectName].instances.push(instanceName);
            projectMap[projectName].metrics.push(content);
            if (!R.has(content, projectMap[projectName].metricsMap)) {
              projectMap[projectName].metricsMap[content] = [];
            }
            projectMap[projectName].metricsMap[content].push(instanceName);
          }
        }, sourceInfoList || []);
      }, chainList);
    }, R.values(allRootCauseListMap));

    const projectNames = R.keys(projectMap);
    this.selectProject = projectNames.length > 0 ? projectNames[0] : undefined;
    Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      bodyStyle: { marginLeft: 0 },
      style: { minWidth: 500 },
      content: (
        <div className="flex-row flex-center-align">
          <div style={{ marginRight: 8 }}>Metric Project:</div>
          <div className="flex-grow">
            <Select
              size="small"
              style={{ width: 250 }}
              filterOption
              options={R.map((item) => ({ value: item, label: item }), projectNames)}
              defaultValue={this.selectProject}
              onChange={(project) => {
                this.selectProject = project;
              }}
            />
          </div>
        </div>
      ),
      onOk: (close) => this.handleSelectProjectConfirm(close, incident, get(projectMap, this.selectProject, {})),
    });
  }

  @autobind
  handleSelectProjectConfirm(close, incident, params) {
    const { projectName, times, instances, metrics, metricsMap } = params;
    const { startTimestamp: incidentStart, endTimestamp: incidentEnd, patternId, isIncident, isDeployment } = incident;
    const rootCauseStart = R.reduce(R.min, incidentStart, times);
    const rootCauseEnd = R.reduce(R.max, incidentStart, times);
    const startTimestamp = moment.utc(rootCauseStart).startOf('day').valueOf();
    const endTimestamp = moment.utc(rootCauseEnd).endOf('day').valueOf();

    const incidentInfo = {
      startTimestamp: incidentStart,
      endTimestamp: incidentEnd,
      patternId,
      type: isIncident ? 'incident' : isDeployment ? 'deployment' : 'normal',
    };
    const metricAnomalyMap = R.mapObjIndexed((val) => {
      return R.uniq(val);
    }, metricsMap);

    const query = {
      projectName,
      instanceGroup: 'All',
      startTimestamp,
      endTimestamp,
      justSelectMetric: R.join(',', R.uniq(metrics)),
      justInstanceList: R.join(',', R.uniq(instances)),
      // withBaseline: true,
      incidentInfo: JSON.stringify(incidentInfo),
      metricAnomalyMap: JSON.stringify(metricAnomalyMap),
      // startTimeWindow: rootCauseStart - 600000,
      // endTimeWindow: rootCauseEnd + 600000,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');

    close();
  }

  @autobind
  onChangeFilterInstance(filterInstance, operation, tabName) {
    if (operation === 'rootCauseEvents') {
      this.setState((prevState) => ({
        rootCauseFilterInstanceMap: { ...prevState.rootCauseFilterInstanceMap, [tabName]: filterInstance },
      }));
    } else {
      this.setState((prevState) => ({
        predictedFilterInstanceMap: { ...prevState.predictedFilterInstanceMap, [tabName]: filterInstance },
      }));
    }
  }

  @autobind
  onChangeFilterMetric(filterMetric, operation, tabName) {
    if (operation === 'rootCauseEvents') {
      this.setState((prevState) => ({
        rootCauseFilterMetricMap: { ...prevState.rootCauseFilterMetricMap, [tabName]: filterMetric },
      }));
    } else {
      this.setState((prevState) => ({
        predictedFilterMetricMap: { ...prevState.predictedFilterMetricMap, [tabName]: filterMetric },
      }));
    }
  }

  @autobind
  renderListItemRootCauseList(operation, events, incident, tabName) {
    return ({ key, index: rowIndex, style, parent }) => {
      const item = events[rowIndex];
      if (!item) return null;

      const { environmentId, systemId } = this.props;
      const { isExpand } = item;
      const cellMeasureCache =
        operation === 'rootCauseEvents' ? this[`cellMeasureCacheRC-${tabName}`] : this[`cellMeasureCachePT-${tabName}`];
      return (
        <CellMeasurer key={key} cache={cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div style={{ ...style }}>
            <div
              className={`event-list-row clickable ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
              onClick={() => this.handleEventClick(tabName, cellMeasureCache, rowIndex, item)}
            >
              <div
                className="row-column flex-center-justify"
                style={{ width: 30 }}
                onClick={(e) => e.stopPropagation()}
              >
                {this.rendererChecked(tabName, rowIndex, item)}
              </div>
              <div className="row-column" style={{ width: 30, padding: 0 }}>
                {this.rendererExpand(rowIndex, item)}
              </div>
              <div className="row-column" style={{ width: 40 }}>
                <span className="circle" style={{ background: item.rankColor }}>
                  <span className="circle-text">{item.rank}</span>
                </span>
              </div>
              <div className="row-column" style={{ width: 80 }}>
                {numeral(item.probability).format('0.0%')}
              </div>
              <div className="row-column" style={{ width: 60 }}>
                {item.count}
              </div>
              <div className="row-column flex-col break-all" style={{ width: 120, alignItems: 'flex-start' }}>
                {R.addIndex(R.map)((sourceInfo, idx) => {
                  const { sourceDetail } = sourceInfo;
                  const { isMetric, slope } = sourceDetail || {};
                  if (!isMetric || !isNumber(slope)) return null;
                  return <span key={idx}>{numeral(slope).format(Math.abs(slope) >= 1 ? '0,0' : '0,0.[000000]')}</span>;
                }, item.sourceInfoList || [])}
              </div>
              <div className="row-column flex-row flex-center-align break-all" style={{ width: 100, flex: 1 }}>
                {this.contentRenderer(tabName, operation, item)}
              </div>
              <div className="row-column" style={{ width: 100 }}>
                {item.hasAction ? 'Action created' : '--'}
              </div>
              <div className="row-column flex-row flex-center-align break-all" style={{ width: 120 }}>
                {this.relevanceRenderer(tabName, item, rowIndex)}
              </div>
            </div>
            {isExpand && (
              <div className="full-width" style={{ padding: '8px 8px 8px 38px' }}>
                <RootCauseTimelineList
                  incident={incident}
                  selectChain={item}
                  environmentId={environmentId}
                  systemId={systemId}
                  summarySettingsMap={this.summarySettingsMap}
                  onToggleCollapse={() => {
                    this.onToggleCollapse(tabName, cellMeasureCache, rowIndex, item);
                  }}
                />
              </div>
            )}
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleEventClick(tabName, cellMeasureCache, rowIndex, item) {
    const { operation, isExpand } = item;
    const dataField = operation === 'rootCauseEvents' ? 'allRootCauseListMap' : 'allPredictedListMap';
    const { [dataField]: dataFieldMap } = this.state;
    rowIndex = R.findIndex((_item) => _item.rootCauseKey === item.rootCauseKey, dataFieldMap[tabName]);
    this.setState(
      {
        [dataField]: {
          ...dataFieldMap,
          [tabName]: update(dataFieldMap[tabName], {
            [rowIndex]: {
              $set: { ...(dataFieldMap[tabName][rowIndex] || {}), isExpand: !isExpand },
            },
          }),
        },
      },
      () => {
        cellMeasureCache.clearAll();
        const refList = operation === 'rootCauseEvents' ? `refList-RC-${tabName}` : `refList-PT-${tabName}`;
        if (this[refList]) this[refList].forceUpdateGrid();
      },
    );
  }

  @autobind
  onToggleCollapse(tabName, cellMeasureCache, rowIndex, item) {
    const { operation } = item;
    cellMeasureCache.clearAll();
    const refList = operation === 'rootCauseEvents' ? `refList-RC-${tabName}` : `refList-PT-${tabName}`;
    if (this[refList]) this[refList].forceUpdateGrid();
  }

  @autobind
  rendererChecked(tabName, rowIndex, rowData) {
    const { operation, isChecked } = rowData;
    const dataField = operation === 'rootCauseEvents' ? 'allRootCauseListMap' : 'allPredictedListMap';
    return (
      <Checkbox
        checked={isChecked}
        onChange={(e) => {
          this.setState(
            (prevState) => ({
              [dataField]: {
                ...prevState[dataField],
                [tabName]: update(prevState[dataField][tabName], {
                  [rowIndex]: {
                    $set: {
                      ...(prevState[dataField][tabName][rowIndex] || {}),
                      isChecked: e.target.checked,
                    },
                  },
                }),
              },
            }),
            () => {
              const refList = operation === 'rootCauseEvents' ? `refList-RC-${tabName}` : `refList-PT-${tabName}`;
              if (this[refList]) this[refList].forceUpdateGrid();
            },
          );
        }}
      />
    );
  }

  @autobind
  rendererExpand(rowIndex, rowData) {
    const { isExpand } = rowData;

    return (
      <div className="full-width flex-row flex-center-align flex-center-justify" style={{ height: 40 }}>
        {isExpand ? <DownOutlined style={{ fontSize: 14 }} /> : <RightOutlined style={{ fontSize: 14 }} />}
      </div>
    );
  }

  @autobind
  contentRenderer(tabName, operation, item) {
    const { intl } = this.props;
    const { sourceInfoList } = item;
    return (
      <div className="full-width">
        {R.addIndex(R.map)((sourceInfo, idx) => {
          const { isMetric, isDeployment, sourceDetail, componentName } = sourceInfo;
          const { content } = sourceDetail;
          return (
            <div key={idx} className="flex-row flex-center-align" style={{ margin: idx !== 0 ? '2px 0' : '2px 0 0 0' }}>
              <span style={{ minWidth: 20 }}>{idx + 1}.</span>
              <span style={{ minWidth: 80 }}>
                {isMetric ? (
                  <Tag className="inline-block" color="red" style={{ lineHeight: '18px' }}>
                    {intl.formatMessage(appFieldsMessages.metric)}
                  </Tag>
                ) : isDeployment ? (
                  <Tag className="inline-block" color="orange" style={{ lineHeight: '18px' }}>
                    {intl.formatMessage(logMessages.deployment)}
                  </Tag>
                ) : (
                  <Tag className="inline-block" style={{ lineHeight: '18px' }}>
                    {intl.formatMessage(appFieldsMessages.log)}
                  </Tag>
                )}
              </span>
              <span className="light-label bold" style={{ minWidth: 80 }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </span>
              <span className="hidden-line-with-ellipsis inline-block" style={{ marginRight: 8 }}>
                {componentName}
              </span>
              {isMetric && (
                <>
                  <span className="light-label bold" style={{ minWidth: 50 }}>
                    {intl.formatMessage(appFieldsMessages.metric)}:
                  </span>
                  <span className="hidden-line-with-ellipsis inline-block">{content}</span>
                </>
              )}
            </div>
          );
        }, sourceInfoList)}
      </div>
    );
  }

  @autobind
  relevanceRenderer(tabName, item, rowIndex) {
    const { intl, timezoneOffset, userInfo } = this.props;
    const { isLoading, relevance, reporterRecordSet } = item;
    return (
      <Popover
        title={intl.formatMessage(eventActionMessages.reporter)}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 350, maxHeight: 100 }}>
            {R.addIndex(R.map)((item, idx) => {
              const { timestamp, reporterName, action } = item;
              const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
              return (
                <div key={idx} className="flex-row flex-center-align">
                  <div style={{ width: 80 }}>{moment.utc(customerTimestamp).format(Defaults.ShortTimeFormat)}</div>
                  <div className="bold light-label" style={{ marginRight: 4 }}>
                    {intl.formatMessage(eventActionMessages.reporter)}:
                  </div>
                  <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                  <span style={{ marginLeft: 2 }}>{reporterName}</span>
                  <div className="bold light-label" style={{ marginLeft: 8, marginRight: 4 }}>
                    {intl.formatMessage(eventActionMessages.action)}:
                  </div>
                  <span style={{ margin: '0 4px', color: this.relevanceColorMap[action] }}>{action}</span>
                </div>
              );
            }, reporterRecordSet || [])}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="top"
      >
        <Spin size="small" spinning={!!isLoading} wrapperClassName="spin-full-width full-width">
          <Select
            showArrow={false}
            size="small"
            style={{ width: '100%' }}
            value={relevance}
            disabled={userInfo.isReadUser}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(relevance) => this.onChangeRelevance({ tabName, rowData: item, rowIndex, relevance })}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          >
            {R.map((item) => {
              return (
                <Select.Option key={item.value}>
                  <span style={{ color: item.color }}>{item.label}</span>
                </Select.Option>
              );
            }, this.relevanceOptions)}
          </Select>
        </Spin>
      </Popover>
    );
  }

  @autobind
  onChangeRelevance({ tabName, rowData, rowIndex, relevance }) {
    const { intl, credentials, incident } = this.props;
    const { rootCauseKey, operation } = rowData;
    const {
      category,
      projectOwner,
      anomalyLogInstance,
      instanceList,
      startTimestamp,
      endTimestamp,
      patternId,
      type,
      rootCauseTableKey,
    } = incident;
    let { projectName } = incident;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
    const event = {
      nid: patternId,
      eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
    };
    const oldValue = get(rowData, 'relevance');
    const dataField = operation === 'rootCauseEvents' ? 'allRootCauseListMap' : 'allPredictedListMap';

    this.setState(
      (prevState) => ({
        [dataField]: {
          ...prevState[dataField],
          [tabName]: update(prevState[dataField][tabName], {
            [rowIndex]: {
              $set: { ...(prevState[dataField][tabName][rowIndex] || {}), isLoading: true, relevance },
            },
          }),
        },
      }),
      () => {
        const refList = operation === 'rootCauseEvents' ? `refList-RC-${tabName}` : `refList-PT-${tabName}`;
        if (this[refList]) this[refList].forceUpdateGrid();
      },
    );

    this.props.updateLastActionInfo();
    fetchPost(
      getEndpoint('updateRelevance', 1),
      {
        ...credentials,
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime,
        endTime,
        operation,
        rootCauseTableKey: JSON.stringify(rootCauseTableKey),
        event: JSON.stringify(event),
        rootCauseKey,
        relevance,
      },
      {},
      false,
    )
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.props.onReload();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        // rollback
        this.setState(
          (prevState) => ({
            [dataField]: {
              ...prevState[dataField],
              [tabName]: update(prevState[dataField][tabName], {
                [rowIndex]: {
                  $set: { ...(prevState[dataField][tabName][rowIndex] || {}), isLoading: false, relevance: oldValue },
                },
              }),
            },
          }),
          () => {
            const refList = operation === 'rootCauseEvents' ? `refList-RC-${tabName}` : `refList-PT-${tabName}`;
            if (this[refList]) this[refList].forceUpdateGrid();
          },
        );
      });
  }

  @autobind
  handleGroupSuggestedActionsClick({ tabName, operation }) {
    const { credentials, incident } = this.props;
    const dataField = operation === 'rootCauseEvents' ? 'allRootCauseListMap' : 'allPredictedListMap';
    const { [dataField]: dataFieldMap } = this.state;

    const { projectOwner } = incident;
    let { projectName } = incident;
    // parse params to get root cause
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const rootCauseIncident = {
      ...incident,
      projectName,
    };

    const checkedList = R.filter((item) => item.isChecked, dataFieldMap[tabName] || []);
    const actionRootCauseKey = JSON.stringify(R.map((item) => item.actionRootCauseKey, checkedList));
    this.setState({ rootCauseIncident, showActionModal: true, actionRootCauseKey });
  }

  @autobind
  handleActionSelectConfirm(actionRootCauseKey) {
    this.setState({ showRootCauseChainSelect: false, showActionModal: true, actionRootCauseKey });
  }

  @autobind
  getProjectNameList() {
    const { systemsMap, systemId } = this.props;
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  handleCheckAllChange(tabName, operation, e) {
    const isCheckAll = e.target.checked;
    const dataField = operation === 'rootCauseEvents' ? 'allRootCauseListMap' : 'allPredictedListMap';
    this.setState(
      (prevState) => ({
        [dataField]: {
          ...prevState[dataField],
          [tabName]: R.map((item) => ({ ...item, isChecked: isCheckAll }), prevState[dataField][tabName]),
        },
      }),
      () => {
        const refList = operation === 'rootCauseEvents' ? `refList-RC-${tabName}` : `refList-PT-${tabName}`;
        if (this[refList]) this[refList].forceUpdateGrid();
      },
    );
  }

  @autobind
  renderDetailsRC({ tabName, rootCausesData }) {
    const { intl, incident } = this.props;
    const {
      rootCauseFilterInstanceOptionsMap,
      rootCauseFilterInstanceMap,
      rootCauseFilterMetricOptionsMap,
      rootCauseFilterMetricMap,
    } = this.state;
    const { allRootCauseListMap } = this.state;
    let rootCauseList = allRootCauseListMap[tabName] || [];
    const hasRootCause = rootCauseList.length > 0;

    // filter data
    const rootCauseFilterInstance = rootCauseFilterInstanceMap[tabName];
    const rootCauseFilterMetric = rootCauseFilterMetricMap[tabName];
    if (rootCauseFilterInstance) {
      rootCauseList = R.filter((item) => (item.instanceList || []).includes(rootCauseFilterInstance), rootCauseList);
    }
    if (rootCauseFilterMetric) {
      rootCauseList = R.filter((item) => (item.metricList || []).includes(rootCauseFilterMetric), rootCauseList);
    }

    const isCheckAllRC = R.findIndex((item) => !item.isChecked, rootCauseList) === -1;
    return (
      <div className="full-height flex-col" style={{ paddingTop: 8 }}>
        {hasRootCause && (
          <div className="flex-grow flex-min-height flex-col">
            <div className="flex-row flex-center-align" style={{ marginBottom: 4, lineHeight: '28px' }}>
              <div className="light-label font-14" style={{ marginRight: 16 }}>
                {intl.formatMessage(logMessages.precedingAnomalies)}
              </div>

              <Select
                size="small"
                style={{ width: 160, marginRight: 8 }}
                allowClear
                showSearch
                placeholder={intl.formatMessage(eventMessages.instanceFilter)}
                options={rootCauseFilterInstanceOptionsMap[tabName] || []}
                value={rootCauseFilterInstance}
                onChange={(filterInstance) => this.onChangeFilterInstance(filterInstance, 'rootCauseEvents', tabName)}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              />
              {tabName === 'metricRC' && (
                <Select
                  size="small"
                  style={{ width: 160, marginRight: 8 }}
                  allowClear
                  showSearch
                  placeholder={intl.formatMessage(eventMessages.metricFilter)}
                  options={rootCauseFilterMetricOptionsMap[tabName] || []}
                  value={rootCauseFilterMetric}
                  onChange={(filterMetric) => this.onChangeFilterMetric(filterMetric, 'rootCauseEvents', tabName)}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                />
              )}
              <div className="flex-grow" />
            </div>

            <div className="flex-grow flex-min-height">
              <AutoSizer>
                {({ width, height }) => (
                  <div className="event-list">
                    <div
                      className="event-list-header"
                      style={{ height: 30, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                    >
                      <div className="header-column flex-center-justify" style={{ width: 30 }}>
                        <Checkbox
                          checked={isCheckAllRC}
                          onChange={(e) => this.handleCheckAllChange(tabName, 'rootCauseEvents', e)}
                        />
                      </div>
                      <div className="header-column" style={{ width: 30 }} />
                      <div className="header-column" style={{ width: 40 }}>
                        {intl.formatMessage(logMessages.rankNumber)}
                      </div>
                      <div className="header-column" style={{ width: 80 }}>
                        {intl.formatMessage(appFieldsMessages.probability)}
                      </div>
                      <div className="header-column" style={{ width: 60 }}>
                        {intl.formatMessage(appFieldsMessages.count)}
                      </div>
                      <div className="header-column" style={{ width: 120 }}>
                        {intl.formatMessage(eventMessages.slope)}
                      </div>
                      <div className="header-column" style={{ width: 100, flex: 1 }}>
                        {intl.formatMessage(appMenusMessages.patterns)}
                      </div>
                      <div className="header-column" style={{ width: 100 }}>
                        {intl.formatMessage(appFieldsMessages.actionStatus)}
                      </div>
                      <div className="header-column" style={{ width: 120 }}>
                        {intl.formatMessage(appFieldsMessages.relevance)}
                      </div>
                    </div>
                    <List
                      ref={(ref) => {
                        this[`refList-RC-${tabName}`] = ref;
                      }}
                      className="event-list-grid"
                      width={width}
                      height={height - 30}
                      rowCount={rootCauseList.length}
                      rowHeight={this[`cellMeasureCacheRC-${tabName}`].rowHeight}
                      overscanRowCount={4}
                      deferredMeasurementCache={this[`cellMeasureCacheRC-${tabName}`]}
                      rowRenderer={this.renderListItemRootCauseList(
                        'rootCauseEvents',
                        rootCauseList,
                        incident,
                        tabName,
                      )}
                      onScrollbarPresenceChange={({ horizontal, vertical }) => {
                        if (vertical) {
                          this.listNodeHeaderScrollbar = true;
                        } else {
                          this.listNodeHeaderScrollbar = false;
                        }
                        this.forceUpdate();
                      }}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          </div>
        )}
        {rootCausesData && !hasRootCause && (
          <div className="flex-grow flex-min-height">
            <Alert style={{ marginBottom: 8 }} message="No source root causes found" type="info" showIcon />
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderDetailsPT({ tabName, predictedData }) {
    const { intl, incident } = this.props;
    const {
      predictedFilterInstanceOptionsMap,
      predictedFilterInstanceMap,
      predictedFilterMetricOptionsMap,
      predictedFilterMetricMap,
    } = this.state;
    const { allPredictedListMap } = this.state;
    let predictedList = allPredictedListMap[tabName] || [];
    const hasPredicted = predictedList.length > 0;

    // filter data
    const predictedFilterInstance = predictedFilterInstanceMap[tabName];
    const predictedFilterMetric = predictedFilterMetricMap[tabName];
    if (predictedFilterInstance) {
      predictedList = R.filter((item) => (item.instanceList || []).includes(predictedFilterInstance), predictedList);
    }
    if (predictedFilterMetric) {
      predictedList = R.filter((item) => (item.metricList || []).includes(predictedFilterMetric), predictedList);
    }

    const isCheckAllPT = R.findIndex((item) => !item.isChecked, predictedList) === -1;
    return (
      <div className="full-height flex-col" style={{ paddingTop: 8 }}>
        {hasPredicted && (
          <div className="flex-grow flex-min-height flex-col">
            <div className="flex-row flex-center-align" style={{ marginBottom: 4, lineHeight: '28px' }}>
              <div className="light-label font-14" style={{ marginRight: 16 }}>
                {intl.formatMessage(logMessages.trailingAnomalies)}
              </div>

              <Select
                size="small"
                style={{ width: 160, marginRight: 8 }}
                allowClear
                showSearch
                placeholder={intl.formatMessage(eventMessages.instanceFilter)}
                options={predictedFilterInstanceOptionsMap[tabName] || []}
                value={predictedFilterInstance}
                onChange={(filterInstance) => this.onChangeFilterInstance(filterInstance, 'predictedEvents', tabName)}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              />
              <Select
                size="small"
                style={{ width: 160, marginRight: 8 }}
                allowClear
                showSearch
                placeholder={intl.formatMessage(eventMessages.metricFilter)}
                options={predictedFilterMetricOptionsMap[tabName] || []}
                value={predictedFilterMetric}
                onChange={(filterMetric) => this.onChangeFilterMetric(filterMetric, 'predictedEvents', tabName)}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              />
              <div className="flex-grow" />
              <Button
                type="primary"
                size="small"
                disabled={R.findIndex((item) => item.isChecked, predictedList) === -1}
                onClick={() => this.handleGroupSuggestedActionsClick({ tabName, operation: 'predictedEvents' })}
              >
                {intl.formatMessage(eventMessages.groupTakeAction)}
              </Button>
            </div>

            <div className="flex-grow flex-min-height">
              <AutoSizer>
                {({ width, height }) => (
                  <div className="event-list">
                    <div
                      className="event-list-header"
                      style={{ height: 30, width, paddingRight: this.listNodeHeaderScrollbar2 ? 17 : 0 }}
                    >
                      <div className="header-column flex-center-justify" style={{ width: 30 }}>
                        <Checkbox
                          checked={isCheckAllPT}
                          onChange={(e) => this.handleCheckAllChange(tabName, 'predictedEvents', e)}
                        />
                      </div>
                      <div className="header-column" style={{ width: 30 }} />
                      <div className="header-column" style={{ width: 40 }}>
                        {intl.formatMessage(logMessages.rankNumber)}
                      </div>
                      <div className="header-column" style={{ width: 80 }}>
                        {intl.formatMessage(appFieldsMessages.probability)}
                      </div>
                      <div className="header-column" style={{ width: 60 }}>
                        {intl.formatMessage(appFieldsMessages.count)}
                      </div>
                      <div className="header-column" style={{ width: 120 }}>
                        {intl.formatMessage(eventMessages.slope)}
                      </div>
                      <div className="header-column" style={{ width: 100, flex: 1 }}>
                        {intl.formatMessage(appMenusMessages.patterns)}
                      </div>
                      <div className="header-column" style={{ width: 100 }}>
                        {intl.formatMessage(appFieldsMessages.actionStatus)}
                      </div>
                      <div className="header-column" style={{ width: 120 }}>
                        {intl.formatMessage(appFieldsMessages.relevance)}
                      </div>
                      <div className="header-column" style={{ width: 120 }} />
                    </div>
                    <List
                      ref={(ref) => {
                        this[`refList-PT-${tabName}`] = ref;
                      }}
                      className="event-list-grid"
                      width={width}
                      height={height - 20}
                      rowCount={predictedList.length}
                      rowHeight={this[`cellMeasureCachePT-${tabName}`].rowHeight}
                      overscanRowCount={4}
                      deferredMeasurementCache={this[`cellMeasureCachePT-${tabName}`]}
                      rowRenderer={this.renderListItemRootCauseList(
                        'predictedEvents',
                        predictedList,
                        incident,
                        tabName,
                      )}
                      onScrollbarPresenceChange={({ horizontal, vertical }) => {
                        if (vertical) {
                          this.listNodeHeaderScrollbar2 = true;
                        } else {
                          this.listNodeHeaderScrollbar2 = false;
                        }
                        this.forceUpdate();
                      }}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          </div>
        )}
        {predictedData && !hasPredicted && (
          <div className="flex-grow flex-min-height">
            <Alert style={{ marginBottom: 8 }} message="No predictions found" type="info" showIcon />
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      intl,
      incident,
      isLoading: isLoadingData,
      rootCausesData,
      predictedData,
      environmentId,
      systemId,
    } = this.props;
    const { isLoading, activeKey } = this.state;
    const { allRootCauseListMap, allPredictedListMap, activeChain } = this.state;
    const { rootCauseIncident, operation } = this.state;

    return (
      <Spin spinning={isLoadingData || isLoading} wrapperClassName="full-height spin-full-height">
        <Tabs
          type="card"
          className="full-width full-height ant-tabs-content-full-height"
          activeKey={activeKey}
          onChange={(activeKey) => {
            this.setState({ activeKey });
          }}
          tabBarExtraContent={
            <div className="full-height flex-row flex-center-align">
              {rootCausesData && (
                <Button size="small" icon={<LineChartOutlined />} onClick={this.handleOverallLineChartClick}>
                  {intl.formatMessage(eventMessages.anomalyLineChart)}
                </Button>
              )}
            </div>
          }
        >
          {rootCausesData && (
            <>
              <Tabs.TabPane tab={intl.formatMessage(eventMessages.logSourceRootCauses)} key="logRC">
                {this.renderDetailsRC({ tabName: 'logRC', rootCausesData })}
              </Tabs.TabPane>
              <Tabs.TabPane tab={intl.formatMessage(eventMessages.metricSourceRootCauses)} key="metricRC">
                {this.renderDetailsRC({ tabName: 'metricRC', rootCausesData })}
              </Tabs.TabPane>
            </>
          )}
          {predictedData && (
            <>
              <Tabs.TabPane tab={intl.formatMessage(eventMessages.logSourceTrailingEvents)} key="logPT">
                {this.renderDetailsPT({ tabName: 'logPT', predictedData })}
              </Tabs.TabPane>
              <Tabs.TabPane tab={intl.formatMessage(eventMessages.metricSourceTrailingEvents)} key="metricPT">
                {this.renderDetailsPT({ tabName: 'metricPT', predictedData })}
              </Tabs.TabPane>
            </>
          )}
        </Tabs>

        {this.state.showRootCauseChainSelect && (
          <RootCauseChainSelectModal
            allRootCauseAndPredictList={
              operation === 'rootCauseEvents'
                ? get(allRootCauseListMap, activeKey, [])
                : get(allPredictedListMap, activeKey, [])
            }
            rootCauseKey={get(activeChain, 'rootCauseKey')}
            incident={rootCauseIncident}
            isLoadingRootCause={isLoadingData || isLoading}
            reloadRootCause={() => this.props.onReload()}
            onConfirm={this.handleActionSelectConfirm}
            onClose={() => this.setState({ showRootCauseChainSelect: false })}
          />
        )}
        {this.state.showActionModal && (
          <EventActionModal
            suggestActions={get(activeChain, 'suggestActions', [])}
            incident={rootCauseIncident}
            projectName={rootCauseIncident.projectName}
            projectNameList={this.getProjectNameList()}
            actionRootCauseKey={this.state.actionRootCauseKey}
            onClose={(needReload) => {
              this.setState({ showActionModal: false }, () => {
                if (needReload) this.props.onReload();
              });
            }}
          />
        )}
        {this.state.showRootCausesModal && (
          <RootCausesModal
            environmentId={environmentId}
            systemId={systemId}
            incident={incident}
            activeChain={activeChain}
            onClose={(reload) =>
              this.setState({ showRootCausesModal: false, activeChain: null }, () => {
                if (reload) this.props.onReload();
              })
            }
          />
        )}
      </Spin>
    );
  }
}

const LikelyRootCauses = injectIntl(LikelyRootCausesCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset, currentLocale, projectDisplayMap, systemsMap, globalInfo } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      timezoneOffset,
      currentLocale,
      projectDisplayMap,
      systemsMap,
      userInfo,
      credentials,
      globalInfo,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(LikelyRootCauses);
