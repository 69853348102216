/* @flow */

import { defineMessages } from 'react-intl';

const queryMessages = defineMessages({
  Root_Cause_Analysis: {
    defaultMessage: 'Root Cause Analysis',
    id: 'query.Root_Cause_Analysis',
  },
  Metric_Insights: {
    defaultMessage: 'Metric Insights',
    id: 'query.Metric_Insights',
  },
  Log_Insights: {
    defaultMessage: 'Log Insights',
    id: 'query.Log_Insights',
  },
  Application_Performance_Insights: {
    defaultMessage: 'Application Performance Insights',
    id: 'query.Application_Performance_Insights',
  },
  Predictive_Alert: {
    defaultMessage: 'Predictive Alert',
    id: 'query.Predictive_Alert',
  },
  Key_Performance_Indicator_KPI_Prediction: {
    defaultMessage: 'Key Performance Indicator (KPI) Prediction',
    id: 'query.Key_Performance_Indicator_KPI_Prediction',
  },
  '8d49a00c0dcb4c45b65d792b37990e2e': {
    defaultMessage: '{displayName}',
    id: 'query.8d49a00c0dcb4c45b65d792b37990e2e',
  },
  f3afb75038244cb9bff7d738489884b9: {
    defaultMessage: '{displayName}',
    id: 'query.f3afb75038244cb9bff7d738489884b9',
  },
  '38d06cc6d65e43e98c083946367c80a6': {
    defaultMessage: '{displayName}',
    id: 'query.38d06cc6d65e43e98c083946367c80a6',
  },
  de593074c127470b9758fe0182e6cb4b: {
    defaultMessage: '{displayName}',
    id: 'query.de593074c127470b9758fe0182e6cb4b',
  },
  '78e1072bae6b48b783214a0658425e14': {
    defaultMessage: '{displayName}',
    id: 'query.78e1072bae6b48b783214a0658425e14',
  },
  f14e65eca3844ce29745c3d47fe90b00: {
    defaultMessage: '{displayName}',
    id: 'query.f14e65eca3844ce29745c3d47fe90b00',
  },
  d3554c441470434a958e0fc26cd7fb5a: {
    defaultMessage: '{displayName}',
    id: 'query.d3554c441470434a958e0fc26cd7fb5a',
  },
  e3c3c5e8172f4a1396988747e6dc09be: {
    defaultMessage: '{displayName}',
    id: 'query.e3c3c5e8172f4a1396988747e6dc09be',
  },
  c550706ee50d4b77a076b6127f1ca2ce: {
    defaultMessage: '{displayName}',
    id: 'query.c550706ee50d4b77a076b6127f1ca2ce',
  },
  '44295d4bd7c0486693af4e6a2ae5e943': {
    defaultMessage: '{displayName}',
    id: 'query.44295d4bd7c0486693af4e6a2ae5e943',
  },
  '680e10ddf5ac44b8867690c0ff472c67': {
    defaultMessage: '{displayName}',
    id: 'query.680e10ddf5ac44b8867690c0ff472c67',
  },
  '6f0627d11427480b95e8d012f83c83f0': {
    defaultMessage: '{displayName}',
    id: 'query.6f0627d11427480b95e8d012f83c83f0',
  },
  b81364228d574502b7f6c3e454b9a21b: {
    defaultMessage: '{displayName}',
    id: 'query.b81364228d574502b7f6c3e454b9a21b',
  },
  '0377137ccb9f4415b14908cb7e41a7fa': {
    defaultMessage: '{displayName}',
    id: 'query.0377137ccb9f4415b14908cb7e41a7fa',
  },
  '18a34799e749452d96eb20cd9f44f6c0': {
    defaultMessage: '{displayName}',
    id: 'query.18a34799e749452d96eb20cd9f44f6c0',
  },
  '009be3dae7314c168cee40257404ac06': {
    defaultMessage: '{displayName}',
    id: 'query.009be3dae7314c168cee40257404ac06',
  },
  '953de6a33d8a4b96ac9c100bf69ba3fc': {
    defaultMessage: '{displayName}',
    id: 'query.953de6a33d8a4b96ac9c100bf69ba3fc',
  },
  '0339074566234192966c641e7fb7d317': {
    defaultMessage: '{displayName}',
    id: 'query.0339074566234192966c641e7fb7d317',
  },
  '5046cdfc80824588aadae3018abefbe3': {
    defaultMessage: '{displayName}',
    id: 'query.5046cdfc80824588aadae3018abefbe3',
  },
  b919ba042c4b4d69a455262e18174dd0: {
    defaultMessage: '{displayName}',
    id: 'query.b919ba042c4b4d69a455262e18174dd0',
  },
  d2361b74c70e4ef2a41c58accb785cd4: {
    defaultMessage: '{displayName}',
    id: 'query.d2361b74c70e4ef2a41c58accb785cd4',
  },
  '4e2438c80bb0472f84f0aa6a3a03469c': {
    defaultMessage: '{displayName}',
    id: 'query.4e2438c80bb0472f84f0aa6a3a03469c',
  },
  '6fff86729e6749be963227242f39d176': {
    defaultMessage: '{displayName}',
    id: 'query.6fff86729e6749be963227242f39d176',
  },
  '668119e595b3420dad08dd78b4299be6': {
    defaultMessage: '{displayName}',
    id: 'query.668119e595b3420dad08dd78b4299be6',
  },
  c8dcea3a7a9fc4060b3a554b16c4a84d3: {
    defaultMessage: '{displayName}',
    id: 'query.c8dcea3a7a9fc4060b3a554b16c4a84d3',
  },
  a73d0c1ac08146eba9bfbf7bd09c5f20: {
    defaultMessage: '{displayName}',
    id: 'query.a73d0c1ac08146eba9bfbf7bd09c5f20',
  },
  f1f82460f99711e9aaef0800200c9a66: {
    defaultMessage: '{displayName}',
    id: 'query.f1f82460f99711e9aaef0800200c9a66',
  },
  eadc0e347ad24cae84bb32aaefe72418: {
    defaultMessage: '{displayName}',
    id: 'query.eadc0e347ad24cae84bb32aaefe72418',
  },
  '9076db738dc24cf38e4f9db53ba1deb3': {
    defaultMessage: '{displayName}',
    id: 'query.9076db738dc24cf38e4f9db53ba1deb3',
  },
});

export default queryMessages;
