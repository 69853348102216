/* @flow */

import { defineMessages } from 'react-intl';

const eventActionMessages = defineMessages({
  patternName: {
    defaultMessage: 'Name',
    id: 'event.action.patternName',
  },
  setPatternName: {
    defaultMessage: 'Set name',
    id: 'event.action.setPatternName',
  },
  action: {
    defaultMessage: 'Action',
    id: 'event.action.action',
  },
  actionName: {
    defaultMessage: 'Action name',
    id: 'event.action.actionName',
  },
  actionType: {
    defaultMessage: 'Action type',
    id: 'event.action.actionType',
  },
  actionData: {
    defaultMessage: 'Action data',
    id: 'event.action.actionData',
  },
  actionTarget: {
    defaultMessage: 'Target',
    id: 'event.action.actionTarget',
  },
  actionParameter: {
    defaultMessage: 'Action parameter',
    id: 'event.action.actionParameter',
  },
  actionServer: {
    defaultMessage: 'Action server',
    id: 'event.action.actionServer',
  },
  triggeredTime: {
    defaultMessage: 'Triggered time',
    id: 'event.action.triggeredTime',
  },
  savedAction: {
    defaultMessage: 'Saved actions',
    id: 'event.action.savedAction',
  },
  addSavedAction: {
    defaultMessage: 'Add Saved actions',
    id: 'event.action.addSavedAction',
  },
  takeActionOnEvent: {
    defaultMessage: 'Take action on this event',
    id: 'event.action.takeActionOnEvent',
  },
  addAction: {
    defaultMessage: 'Add action',
    id: 'event.action.addAction',
  },
  takeAction: {
    defaultMessage: 'Take action',
    id: 'event.action.takeAction',
  },
  saveAction: {
    defaultMessage: 'Save action',
    id: 'event.action.saveAction',
  },
  noSavedAction: {
    defaultMessage: 'No saved action',
    id: 'event.action.noSavedAction',
  },
  suggestedAction: {
    defaultMessage: 'Suggested action',
    id: 'event.action.suggestedAction',
  },
  noSuggestedAction: {
    defaultMessage: 'No suggested action',
    id: 'event.action.noSuggestedAction',
  },
  componentName: {
    defaultMessage: 'Component name',
    id: 'event.action.componentName',
  },
  instanceName: {
    defaultMessage: 'Instance name',
    id: 'event.action.instanceName',
  },
  insightFinderNotes: {
    defaultMessage: 'InsightFinder Notes',
    id: 'event.action.insightFinderNotes',
  },
  microsoftTeamsNotes: {
    defaultMessage: 'Microsoft Teams Notes',
    id: 'event.action.microsoftTeamsNotes',
  },
  microsoftTeams: {
    defaultMessage: 'Microsoft Teams',
    id: 'event.action.microsoftTeams',
  },
  viewOperatorNotes: {
    defaultMessage: 'View operator notes',
    id: 'event.action.viewOperatorNotes',
  },
  slackNotes: {
    defaultMessage: 'Slack Notes',
    id: 'event.action.slackNotes',
  },
  triageReport: {
    defaultMessage: 'Operator notes',
    id: 'event.action.triageReport',
  },
  summaryNotes: {
    defaultMessage: 'Summary Notes',
    id: 'event.action.summaryNotes',
  },
  serviceNowNotes: {
    defaultMessage: 'ServiceNow Notes',
    id: 'event.action.serviceNowNotes',
  },
  reporter: {
    defaultMessage: 'Reporter',
    id: 'event.action.reporter',
  },
  lastReporter: {
    defaultMessage: 'Last Reporter',
    id: 'event.action.lastReporter',
  },
  reporterName: {
    defaultMessage: 'Reporter name',
    id: 'event.action.reporterName',
  },
  reportIncidentOccurrenceTime: {
    defaultMessage: 'Incident occurrence time',
    id: 'event.action.reportIncidentOccurrenceTime',
  },
  occurrenceTime: {
    defaultMessage: 'occurrence time',
    id: 'event.action.occurrenceTime',
  },
  saveTriage: {
    defaultMessage: 'Save triage',
    id: 'event.action.saveTriage',
  },

  email: {
    defaultMessage: 'Email',
    id: 'event.action.email',
  },
  addEmail: {
    defaultMessage: 'Add Email',
    id: 'event.action.addEmail',
  },
  savedEmailAlert: {
    defaultMessage: 'Saved email alert',
    id: 'event.action.savedEmailAlert',
  },
  noSavedEmailAlerts: {
    defaultMessage: 'No saved email alerts',
    id: 'event.action.noSavedEmailAlerts',
  },
  createEmailAlert: {
    defaultMessage: 'Create email alert',
    id: 'event.action.createEmailAlert',
  },
  saveAlert: {
    defaultMessage: 'Save alert',
    id: 'event.action.saveAlert',
  },
  emailAlertType: {
    defaultMessage: 'Alert type',
    id: 'event.action.emailAlertType',
  },
  emailRecipients: {
    defaultMessage: 'Recipients',
    id: 'event.action.emailRecipients',
  },
  remove: {
    defaultMessage: 'Remove',
    id: 'event.action.remove',
  },
  replies: {
    defaultMessage: 'Replies',
    id: 'event.action.replies',
  },
});

export default eventActionMessages;
