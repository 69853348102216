import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getActiveInstance = (credentials: Credentials, params: Object) => {
  const { customerName, startTime, endTime, projectNameList } = params;
  return fetchGet(getEndpoint('activeinstance'), {
    ...credentials,
    daily: false,
    customerName,
    startTime,
    endTime,
    projectNameList,
  }).then((data) => {
    return data;
  });
};

export default getActiveInstance;
