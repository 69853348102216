import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { Button } from 'antd';

import { parseLocation, getLoadStatus, buildLocation, pickNotNil, Defaults } from '../../common/utils';
import { createLoadAction, showAppLoader } from '../../common/app/actions';
import { ActionTypes } from '../../common/job/actions';
import { State } from '../../common/types';
import { Container, Select, AutoSizer, DatePicker } from '../../lib/fui/react';
import { appButtonsMessages } from '../../common/app/messages';
import JobAnalysisTable from './components/JobAnalysisTable';
import NewJobModal from './components/NewJobModal';

type Props = {
  intl: Object,
  match: Object,
  location: Object,
  loadStatus: Object,
  push: Function,
  replace: Function,
  createLoadAction: Function,
  showAppLoader: Function,
  isAdmin: Boolean,
  userListOption: Array<Object>,

  currentLoadingComponents: Object,
  projects: Array<Object>,
  jobAnalysis: Array<Object>,
};

class JobAnalysisCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.viewLoader = 'job_analysis_view';
    this.useViewLoader = false;
    this.defaultCloudType = 'MapReduce';

    this.ETLOptions = [
      { label: 'MapReduce', value: 'MapReduce' },
      { label: 'Spark', value: 'Spark' },
      { label: 'Jenkins', value: 'Jenkins' },
    ];

    this.state = {
      showNewJobModal: false,
    };
  }

  componentDidMount() {
    if (!this.applyParamsAndRedirect(this.props)) {
      this.reloadData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextParams = parseLocation(nextProps.location);
    const params = parseLocation(this.props.location);
    if (nextParams.customerName !== params.customerName || nextParams.cloudType !== params.cloudType) {
      if (!this.applyParamsAndRedirect(nextProps)) {
        this.reloadData(nextProps, this.props);
      }
    }
  }

  applyParamsAndRedirect(props) {
    const { location, replace, userListOption } = props;
    const params = parseLocation(location);
    let { customerName, startTime, endTime, cloudType } = params;
    let redirect = false;

    // The customerName name might be emtpy, which will be handled by epic.
    if (!customerName && userListOption.length > 0) {
      customerName = userListOption[0].value;
    }
    if (!startTime || !endTime) {
      startTime = moment.utc().format(Defaults.DateFormat);
      endTime = moment.utc().format(Defaults.DateFormat);
    }
    if (!cloudType) {
      cloudType = this.defaultCloudType;
    }

    const newParams = pickNotNil({ customerName, startTime, endTime, cloudType });
    if (!R.equals(newParams, params)) {
      redirect = true;
      replace(buildLocation(location.pathname, {}, newParams));
    }

    return redirect;
  }

  reloadData(props) {
    const { createLoadAction } = props;
    const params = parseLocation(location);
    const { customerName, startTime, endTime, cloudType } = params;

    createLoadAction(
      ActionTypes.LOAD_JOB_ANALYSIS,
      {
        customerName,
        startTime,
        endTime,
        cloudType,
        day: startTime,
      },
      this.useViewLoader ? this.viewLoader : undefined,
    );
  }

  @autobind
  handleCustomerNameChange(newValue) {
    const customerName = newValue ? newValue.value : undefined;
    const { location, showAppLoader } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    this.useViewLoader = false;
    setTimeout(() => {
      const query = parseLocation(location);
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { ...query, customerName, environmentId: undefined, systemId: undefined },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  handleETLChange(newValue) {
    const cloudType = newValue ? newValue.value : null;
    const { location, push } = this.props;
    this.useViewLoader = false;

    const params = parseLocation(location);
    push(buildLocation(location.pathname, {}, { ...params, cloudType }));
  }

  @autobind
  handleDateChange(dateObj) {
    if (dateObj) {
      const startTime = dateObj.format('YYYY-MM-DD');
      const { push, location } = this.props;
      const params = parseLocation(location);
      params.startTime = startTime;

      const { endTime } = params;
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).startOf('day').valueOf();
      if (startTimestamp > endTimestamp) {
        params.endTime = startTime;
      }

      push(buildLocation(location.pathname, {}, params));
    }
  }

  @autobind
  handleEndDateChange(dateObj) {
    if (dateObj) {
      const endTime = dateObj.format('YYYY-MM-DD');
      const { push, location } = this.props;
      const params = parseLocation(location);
      params.endTime = endTime;

      const { startTime } = params;
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).startOf('day').valueOf();
      if (endTimestamp < startTimestamp) {
        params.startTime = endTime;
      }

      push(buildLocation(location.pathname, {}, params));
    }
  }

  @autobind
  handleRefreshClick() {
    this.useViewLoader = true;
    this.reloadData(this.props, true);
  }

  @autobind
  createNewJobClick() {
    this.setState({ showNewJobModal: true });
  }

  render() {
    const { isAdmin, userListOption } = this.props;
    const { intl, loadStatus, currentLoadingComponents } = this.props;
    const { jobAnalysis } = this.props;

    const params = parseLocation(location);
    const { customerName, startTime, endTime, cloudType } = params;

    const nowObj = moment.utc();
    const startTimeObj = moment.utc(startTime, Defaults.DateFormat);
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat);

    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.viewLoader), intl);

    return (
      <Container fullHeight withGutter className="flex-col metric-analysis">
        <Container breadcrumb>
          <div className="section">
            <span className="label">Job</span>
            <span className="divider">/</span>
            <span>Job Anomaly</span>
          </div>
          <div className="section float-right" style={{ fontSize: 12 }}>
            <span className="label">Start Date</span>
            <DatePicker
              utcOffset={0}
              className="day"
              todayButton="Today"
              dateFormat="YYYY-MM-DD"
              maxDate={nowObj}
              showYearDropdown
              showMonthDropdown
              selected={startTimeObj}
              onChange={this.handleDateChange}
            />
            <span className="label">End Date</span>
            <DatePicker
              utcOffset={0}
              className="day"
              todayButton="Today"
              dateFormat="YYYY-MM-DD"
              maxDate={nowObj}
              showYearDropdown
              showMonthDropdown
              selected={endTimeObj}
              onChange={this.handleEndDateChange}
            />
            <span className="label">Platform</span>
            <Select
              name="etl"
              style={{ width: 140 }}
              options={this.ETLOptions}
              value={cloudType}
              onChange={this.handleETLChange}
            />
            {isAdmin && <span style={{ fontWeight: 700, padding: '0 1em' }}>User</span>}
            {isAdmin && (
              <Select
                style={{ width: 100 }}
                value={customerName}
                options={userListOption}
                onChange={this.handleCustomerNameChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              />
            )}
            <Button size="small" onClick={this.handleRefreshClick}>
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </div>
        </Container>
        <Container className="flex-grow flex-col" style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Container className="boxed flex-grow flex-col" style={{ margin: 0, minHeight: 400, position: 'relative' }}>
            <Container className={`overflow-y-auto flex-grow ${isLoading ? ' loading' : ''}`}>
              <AutoSizer>
                {({ width, height }) => (
                  <JobAnalysisTable width={width} height={height} jobAnalysis={jobAnalysis} etlName={cloudType} />
                )}
              </AutoSizer>
            </Container>
          </Container>
        </Container>

        {this.state.showNewJobModal && (
          <NewJobModal
            intl={intl}
            loadStatus={loadStatus}
            currentLoadingComponents={currentLoadingComponents}
            customerName={customerName}
            onClose={() => this.setState({ showNewJobModal: false })}
          />
        )}
      </Container>
    );
  }
}

const JobAnalysis = injectIntl(JobAnalysisCore);

export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { projects, currentLoadingComponents } = state.app;
    const { isAdmin } = state.auth.userInfo;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const userListOption = R.map((u) => ({ label: u.userName, value: u.userName }), userList);

    const { jobAnalysis } = state.job;
    return {
      currentLoadingComponents,
      location,
      loadStatus,
      isAdmin,
      userListOption,
      projects,
      jobAnalysis,
    };
  },
  {
    push,
    replace,
    showAppLoader,
    createLoadAction,
  },
)(JobAnalysis);
