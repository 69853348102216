/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import Helmet from 'react-helmet';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ConfigProvider, message } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';

import type { State } from '../../common/types';
import { appStart, appStop, createLoadAction } from '../../common/app/actions';
import { ActionTypes } from '../../common/auth/actions';
import { Container } from '../../lib/fui/react';
import { AppError, AppLoader, AppAlert, AppModal, UserMissingInfo, AwesomeTours } from './components';

import Routing, { EmbedRouting } from './Routing';

import './commons.less';
import './app.scss';
import './style-override.less';

type Props = {
  history: Object,

  location: Object,
  messages: Object,
  currentTheme: String,
  currentLocale: String,
  appStarted: Boolean,
  appLoaderVisible: Boolean,
  needInputUserMissingInfo: Boolean,
  runAwesomeTours: Boolean,
  loggedIn: Boolean,

  appStart: Function,
  appStop: Function,
  createLoadAction: Function,
  isEmbedMode: Boolean,
};

const EmbedModeBaseUrls = ['/global/embed/'];

export class AppCore extends React.PureComponent {
  props: Props;

  componentDidMount() {
    const { isEmbedMode } = this.props;
    window.handleCopyClick = this.handleCopyClick;
    if (!isEmbedMode) {
      if (!this.props.appStarted) {
        this.props.appStart();
      }

      this.timer = setInterval(() => {
        this.refreshToken();
      }, 60 * 1000);
    }
  }

  componentWillUnmount() {
    const { isEmbedMode } = this.props;
    window.handleCopyClick = undefined;
    if (!isEmbedMode) {
      clearInterval(this.timer);
      if (this.props.appStarted) {
        this.props.appStop();
      }
    }
  }

  @autobind
  handleCopyClick(content, convertFlag) {
    if (convertFlag) {
      content = R.replace(/-QvQ-/g, '\n', content || '');
    }
    if (!navigator.clipboard) {
      try {
        const input = document.createElement('input');
        input.setAttribute('value', content);
        document.body.appendChild(input);
        input.select();
        document.execCommand('Copy');
        document.body.removeChild(input);
        message.success('Copy success!');
      } catch (error) {
        message.success('Copy error!');
      }
    } else {
      navigator.clipboard.writeText(content).then(
        () => message.success('Copy success!'),
        () => message.success('Copy error!'),
      );
    }
  }

  @autobind
  refreshToken() {
    const { location, createLoadAction, appStarted, loggedIn } = this.props;
    const ignoreInitData = ['/report/companystatus'].includes(location.pathname);
    if (appStarted && loggedIn && !ignoreInitData) {
      createLoadAction(ActionTypes.UPDATE_USER_AUTH_INFO, {});
    }
  }

  render() {
    const {
      isEmbedMode,
      history,
      currentTheme,
      appStarted,
      messages,
      appLoaderVisible,
      needInputUserMissingInfo,
      runAwesomeTours,
      currentLocale,
      loggedIn,
    } = this.props;

    return (
      <IntlProvider key={currentLocale} locale={currentLocale} messages={messages[currentLocale] || messages.en}>
        <ConfigProvider csp={{ nonce: window.cspNonce }} locale={currentLocale === 'zh' ? zhCN : enUS}>
          <Container className="full-height flex-col flex-min-height">
            {isEmbedMode ? (
              <>
                <Helmet
                  htmlAttributes={{
                    lang: currentLocale,
                    class: currentTheme ? `${currentTheme} theme` : '',
                  }}
                  title=""
                />
                <EmbedRouting history={history} />
              </>
            ) : (
              <>
                <Helmet
                  htmlAttributes={{
                    lang: currentLocale,
                    class: currentTheme ? `${currentTheme} theme` : '',
                  }}
                />
                <AppError />
                <AppAlert />
                <AppModal />
                <AppLoader visible={appLoaderVisible} currentTheme={currentTheme} />
                {appStarted && loggedIn && needInputUserMissingInfo && <UserMissingInfo />}
                {appStarted && loggedIn && runAwesomeTours && <AwesomeTours />}
                {appStarted && <Routing history={history} />}
              </>
            )}
          </Container>
        </ConfigProvider>
      </IntlProvider>
    );
  }
}

export default connect(
  (state: State) => {
    const isEmbedMode = EmbedModeBaseUrls.some((url) => state.router.location.pathname.startsWith(url));

    return {
      isEmbedMode,
      location: state.router.location,
      messages: state.app.messages,
      currentTheme: state.app.currentTheme,
      currentLocale: state.app.currentLocale,
      appStarted: state.app.started,
      appLoaderVisible: state.app.appLoaderVisible,
      needInputUserMissingInfo: state.app.needInputUserMissingInfo,
      runAwesomeTours: state.app.runAwesomeTours,
      loggedIn: state.auth.loggedIn,
    };
  },
  { appStart, appStop, createLoadAction },
)(AppCore);
