/*  @flow */
import { get } from 'lodash';
import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const loadBugRepository = (credentials: Credentials) => {
  return fetchPost(getEndpoint('dashboard-benchmark'), {
    ...credentials,
    operation: 'display',
  }).then((d) => {
    let infos = get(d, ['data', 'publishedDataAllInfo'], []);

    // Convert metaData from string to object
    infos = R.map(
      (i) => ({
        ...i,
        metaData: JSON.parse(i.metaData || '{}'),
      }),
      infos,
    );

    return infos;
  });
};

export default loadBugRepository;
