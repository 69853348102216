import React from 'react';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import * as R from 'ramda';
import moment from 'moment';

import { Table, Column, CellMeasurer, CellMeasurerCache } from '../../../lib/fui/react';
import { CellRenderers } from '../../../common/utils';
import { CollapsibleLogContent } from '../../../web/share';

type Props = {
  intl: Object,
  location: Object,
  project: Object,
  width: Number,
  height: Number,
  appNameMapping: Object,
};

class LineChartLogEvents extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { width } = props;

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.timeRender = ({ cellData }) => (cellData ? moment.utc(parseInt(cellData, 10)).format('YYYY-MM-DD HH:mm') : '');
    this.contentWidth = width - 210;
    this.preprocessData(props);
  }

  @autobind
  preprocessData(props) {
    const logEntryList = props.logEntryList || [];
    this.logEntryList = R.sortWith([R.ascend(R.prop('timestamp'))], logEntryList);
  }

  componentDidMount() {
    this.cellMeasureCache.clearAll();

    if (this.dataTable) {
      this.dataTable.forceUpdate();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.width !== this.props.width) {
      this.contentWidth = nextProps.width - 210;
    }

    if (nextProps.logEntryList !== this.props.logEntryList) {
      this.preprocessData(nextProps);
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdate();
      }
    }
  }

  @autobind
  setDataTableNode(c) {
    this.dataTable = c;
  }

  @autobind
  handleContentChanged() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdate();
    }
  }

  @autobind
  contentRender(props) {
    const width = this.contentWidth;
    const { dataKey, parent, rowIndex, style, rowData } = props;
    const { type } = rowData;
    const { rawData, percentageStr, count } = rowData;
    const logOptions = {
      width: width - 20,
      highlightWord: null,
      onChanged: this.handleContentChanged,
      inLine: true,
    };
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div className="log-event-group" style={{ ...style, width, fontSize: 12 }}>
          <div>
            {type !== 'rare' && (
              <span style={{ color: 'blue' }}>{`Frequency is ${percentageStr} Count (${count})`}</span>
            )}
          </div>
          <CollapsibleLogContent ownerObject={rowData} message={rawData} {...logOptions} />
        </div>
      </CellMeasurer>
    );
  }

  render() {
    const { width, height } = this.props;
    const logEntryList = this.logEntryList;

    return (
      <Table
        ref={this.setDataTableNode}
        className="with-border"
        width={width}
        height={height - 40}
        deferredMeasurementCache={this.cellMeasureCache}
        headerHeight={30}
        rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
        rowHeight={this.cellMeasureCache.rowHeight}
        rowCount={logEntryList.length}
        rowGetter={({ index }) => logEntryList[index]}
      >
        <Column width={130} label="DateTime" dataKey="timestamp" cellRenderer={this.timeRender} />
        <Column width={80} label="Type" dataKey="type" cellRenderer={CellRenderers.logTypeRenderer} />
        <Column
          width={this.contentWidth}
          flexGrow={1}
          label="Event summary"
          dataKey="eventSummary"
          cellRenderer={this.contentRender}
          headerClassName="text-center"
          className="raw-data"
        />
      </Table>
    );
  }
}

export default LineChartLogEvents;
