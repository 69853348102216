/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import moment from 'moment';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';
import { formatNormalEventList, formatWithPrediction } from './newFormatMetricEventList';
// set prediction query time padding
const getMetricEventList = (credentials: Credentials, params: Object) => {
  const {
    projectName,
    instanceGroup,
    startTimeObj,
    endTimeObj,
    eventType,
    withPredict,
    filterPredict,
    onlyLastDayPrediction,
    nowTimestamp,
    instanceList,
  } = params;
  const modelType = instanceGroup && instanceGroup.toLowerCase() !== 'all' ? 'splitByEnv' : 'Holistic';

  const promiseQuery = [];
  if (withPredict) {
    promiseQuery.push(
      fetchPost(getEndpoint('getevents'), {
        ...credentials,
        projectName,
        instanceGroup,
        startTime: startTimeObj.valueOf(),
        endTime: endTimeObj.valueOf(),
        modelType,
        eventType: 'detected',
        instanceList: instanceList ? JSON.stringify(instanceList) : undefined,
      }),
    );
    promiseQuery.push(
      fetchPost(getEndpoint('getevents'), {
        ...credentials,
        projectName,
        instanceGroup,
        // only last day
        startTime: onlyLastDayPrediction ? moment.utc(nowTimestamp).startOf('day').valueOf() : startTimeObj.valueOf(),
        endTime: onlyLastDayPrediction ? moment.utc(nowTimestamp).endOf('day').valueOf() : endTimeObj.valueOf(),
        modelType,
        eventType: 'predicted',
        instanceList: instanceList ? JSON.stringify(instanceList) : undefined,
      }),
    );
  } else {
    promiseQuery.push(
      fetchPost(getEndpoint('getevents'), {
        ...credentials,
        projectName,
        instanceGroup,
        startTime: startTimeObj.valueOf(),
        endTime: endTimeObj.valueOf(),
        modelType,
        eventType,
        instanceList: instanceList ? JSON.stringify(instanceList) : undefined,
      }),
    );
  }

  if (withPredict) {
    return Promise.all(promiseQuery).then((results) => {
      const eventListMap = formatWithPrediction(results, instanceGroup, eventType, filterPredict, nowTimestamp);
      return { eventListMap };
    });
  }
  return Promise.all(promiseQuery).then(async (results) => {
    const eventListMap = formatNormalEventList(results, instanceGroup, eventType, filterPredict, nowTimestamp);
    return { eventListMap };
  });
};

export default getMetricEventList;
