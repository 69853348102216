import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';

import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import RecommendationsReport from './RecommendationsReport';

import { logMessages } from '../../../common/log/messages';

type Props = {
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  // eslint-disable-next-line
  incident: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  timezoneOffset: Number,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  projectName: String,
};

class RecommendationIncidendModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  render() {
    const { intl, incident, onClose, projectName } = this.props;

    return (
      <Modal
        visible
        title={intl.formatMessage(logMessages.recommendations)}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={1300}
        bodyStyle={{ height: 500 }}
      >
        <RecommendationsReport incident={incident} projectName={projectName} />
      </Modal>
    );
  }
}

const RecommendationIncidendModal = injectIntl(RecommendationIncidendModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      timezoneOffset,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(RecommendationIncidendModal);
