/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import { Loader } from '../../../lib/fui/react';

type Props = {
  visible: boolean,
};

const PageLoader = ({ visible }: Props) => (visible ? <Loader size={48} visible /> : null);

export default PageLoader;
