import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isArray } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { message, Spin, Alert, Button, Select, Checkbox, Row, Col } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import BaseUrls from '../../app/BaseUrls';
import { Defaults, GlobalParse, buildUrl, parseLocation } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal, Popover } from '../../../lib/fui/react';

import { appButtonsMessages, appMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import { eventMessages } from '../../../common/metric/messages';

import LikelyRootCausesVisualizer from './LikelyRootCausesVisualizer';

type Props = {
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  // eslint-disable-next-line
  compareIncidents: Array<Object>,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
  currentTheme: String,
  summarySettingsMap: Object,
};

class CompareIncidentsNewModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      activeKey: 'common',

      incidentsMap: {},
      sameKeysRootCauseMap: {},
      incidentsDiffRootCauseMap: {},
      hasCommonRC: false,
      hasDifferentRC: false,

      incidentsRootCauseMap: {},
      commonCausals: [],
      diffCausals: [],
    };

    this.initDataFlag = false;
    this.summarySettingsMap = {};
    this.incidentsSameRootCauses = [];
    this.incidentsDiffRootCauses = [];
    this.sameKeysLength = 0;
  }

  componentDidMount() {
    this.reloadData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData() {
    const { intl, compareIncidents, credentials, projectDisplayMap } = this.props;

    this.setState({ isLoading: true });

    const request = [];
    R.forEach((incident) => {
      const {
        category,
        projectOwner,
        anomalyLogInstance,
        instanceList,
        startTimestamp,
        endTimestamp,
        patternId,
        type,
        rootCauseTableKey,
      } = incident;

      // parse params to get root cause
      let { projectName } = incident;
      projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
      const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
      const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
      const event = {
        nid: patternId,
        eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
      };

      request.push(
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceList[0],
          startTime,
          endTime,
          operation: 'rootCauseEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
      );
    }, compareIncidents || []);
    this.props.updateLastActionInfo();
    Promise.all(request)
      .then(async (results) => {
        const incidentsMap = {};
        R.forEach((incident) => {
          incidentsMap[incident.id] = incident;
        }, compareIncidents);

        let causeChainInfoList = [];
        let eventProjectNameAll = [];
        let incidentsRootCauseMap = {};
        const incidentsRootCauseKeyMap = {};
        R.addIndex(R.forEach)((rootCausesData, idx) => {
          // RC
          const logRootCauseEvents = get(rootCausesData, ['logRootCauseEvents'], []);
          const metricRootCauseEvents = get(rootCausesData, ['metricRootCauseEvents'], []);
          const { relatedEventList } = GlobalParse.parseRootCauseInfo({
            operation: 'rootCauseEvents',
            chains: [...logRootCauseEvents, ...metricRootCauseEvents],
            credentials,
            projectDisplayMap,
            isCompareIncidents: true,
          });

          const incident = compareIncidents[idx] || {};
          incidentsRootCauseMap[incident.id] = relatedEventList;

          const rootCauseKeyList = R.map((rootCause) => {
            const { actionRootCauseKey, sourceInfoList } = rootCause;

            R.forEach((_item) => {
              const { projectName, owner, type, projectOwner, anomalyLogInstance, sourceInstanceName } = _item;
              const { sourceDetail, sourceProjectOwner } = _item;
              const { nid, instanceName } = sourceDetail || {};

              eventProjectNameAll.push({
                projectName: owner !== credentials.userName ? `${projectName}@${owner}` : projectName,
                category: type,
              });

              const userName = owner || projectOwner || sourceProjectOwner;
              const instanceNameNew = instanceName || anomalyLogInstance || sourceInstanceName;
              causeChainInfoList.push({
                projectName,
                userName,
                instanceName: instanceNameNew,
                patternId: nid,
                key: `${projectName}-${userName}-${instanceNameNew}-${nid}`,
              });
            }, sourceInfoList || []);

            return actionRootCauseKey;
          }, relatedEventList);
          incidentsRootCauseKeyMap[incident.id] = rootCauseKeyList;
        }, results);

        causeChainInfoList = R.uniqBy((item) => item.key, causeChainInfoList || []);
        eventProjectNameAll = R.uniqBy((item) => `${item.projectName}-${item.category}`, eventProjectNameAll || []);
        const groupSourceInfo = R.groupBy((item) => `${item.projectName}@${item.userName}`, causeChainInfoList || []);
        const patternNameList = await this.getPatternNameData(groupSourceInfo);
        const summarySettingsMap = await this.getLogsummarysettings(eventProjectNameAll);

        incidentsRootCauseMap = R.mapObjIndexed((val, key) => {
          const newVal = R.map((item) => {
            const { sourceInfoList } = item || {};
            R.forEach((_item) => {
              const { projectName, owner, projectOwner, anomalyLogInstance, sourceInstanceName } = _item;
              const { sourceDetail, sourceProjectOwner } = _item;
              const { nid, instanceName } = sourceDetail || {};
              const userName = owner || projectOwner || sourceProjectOwner;
              const instanceNameNew = instanceName || anomalyLogInstance || sourceInstanceName;
              const key = `${projectName}-${userName}-${instanceNameNew}-${nid}`;
              const findValue = R.find((pt) => {
                const findKey = `${pt.projectName}-${pt.userName}-${pt.instanceName}-${pt.patternId}`;
                return key === findKey;
              }, patternNameList || []);
              if (findValue) {
                _item.patternName = findValue.patternName;
              }
            }, sourceInfoList || []);
            return item;
          }, val || []);
          return newVal;
        }, incidentsRootCauseMap);

        // compare root causes
        let sameKeys = [];
        R.addIndex(R.forEach)((keys, idx) => {
          if (idx === 0) {
            sameKeys = keys;
          } else {
            sameKeys = R.intersection(sameKeys, keys);
          }
        }, R.values(incidentsRootCauseKeyMap));
        this.sameKeysLength = sameKeys.length;

        const hasCommonRC = sameKeys.length !== 0;
        const sameKeysRootCauseMap = R.mapObjIndexed((rootCauses, incidentId) => {
          return R.filter((rootCause) => {
            const { actionRootCauseKey } = rootCause;
            return sameKeys.includes(actionRootCauseKey);
          }, rootCauses);
        }, incidentsRootCauseMap);

        this.incidentsSameRootCauses = [];
        R.forEachObjIndexed((val, key) => {
          if (val.length > 0) this.incidentsSameRootCauses = [...this.incidentsSameRootCauses, key];
        }, sameKeysRootCauseMap || {});

        // get diff info
        const incidentsDiffRootCauseKeyMap = R.mapObjIndexed((keys) => {
          return R.difference(keys, sameKeys);
        }, incidentsRootCauseKeyMap);
        const incidentsDiffRootCauseMap = R.mapObjIndexed((rootCauses, incidentId) => {
          return R.filter((rootCause) => {
            const { actionRootCauseKey } = rootCause;
            return (incidentsDiffRootCauseKeyMap[incidentId] || []).includes(actionRootCauseKey);
          }, rootCauses);
        }, incidentsRootCauseMap);
        const hasDifferentRC = R.reduce(R.concat, [], R.values(incidentsDiffRootCauseMap)).length !== 0;

        this.incidentsDiffRootCauses = [];
        R.forEachObjIndexed((val, key) => {
          if (val.length > 0) this.incidentsDiffRootCauses = [...this.incidentsDiffRootCauses, key];
        }, incidentsDiffRootCauseMap || {});

        this.initDataFlag = true;
        this.summarySettingsMap = { ...summarySettingsMap, ...this.props.summarySettingsMap };
        this.setState({
          isLoading: false,
          incidentsMap,
          sameKeysRootCauseMap,
          incidentsDiffRootCauseMap,
          hasCommonRC,
          hasDifferentRC,

          incidentsRootCauseMap,
        });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({
          isLoading: false,

          incidentsMap: {},
          sameKeysRootCauseMap: {},
          incidentsDiffRootCauseMap: {},
          hasCommonRC: false,
          hasDifferentRC: false,
        });
      });
  }

  @autobind
  getPatternNameData(groupSourceInfo) {
    const { credentials } = this.props;
    const getPatternNameParams = [];
    R.forEachObjIndexed((sourceInfos, infoKey) => {
      const [p, u] = R.split('@', infoKey);
      const groupInstanceName = R.groupBy((item) => item.instanceName, sourceInfos || []);
      const patternNameByInstance = {};
      R.forEachObjIndexed((items, key) => {
        patternNameByInstance[key] = [];
        R.forEach((item) => {
          patternNameByInstance[key].push({ patternId: item.patternId });
        }, items || []);
      }, groupInstanceName || {});
      getPatternNameParams.push({ projectName: p, userName: u, patternNameByInstance });
    }, groupSourceInfo || {});

    const patternNameList = [];
    if (getPatternNameParams.length === 0) {
      return patternNameList;
    }

    return fetchPost(getEndpoint('getpatternname', 1), {
      ...credentials,
      queryString: JSON.stringify(getPatternNameParams),
    })
      .then((data) => {
        const { success, message } = data || {};
        if ((success || success === undefined) && isArray(data)) {
          R.forEach((item) => {
            const { projectName, userName, patternNameByInstance } = item || {};
            R.forEachObjIndexed((patterns, instance) => {
              R.forEach((patternInfo) => {
                const { patternId, patternName } = patternInfo || {};
                patternNameList.push({
                  projectName,
                  userName,
                  instanceName: instance,
                  patternId,
                  patternName,
                });
              }, patterns || []);
            }, patternNameByInstance);
          }, data || []);
        } else {
          console.error(message);
        }
        return patternNameList;
      })
      .catch((err) => {
        console.error(err.message || String(err));
        return patternNameList;
      });
  }

  @autobind
  getLogsummarysettings(eventProjectNameAll) {
    const { credentials } = this.props;
    const projects = [];
    R.forEach((p) => {
      const { projectName, category } = p;
      if (!R.includes(category, ['metric'])) projects.push(projectName);
    }, eventProjectNameAll || []);

    const request = [];
    R.forEach((projectName) => {
      request.push(
        fetchGet(getEndpoint('logsummarysettings'), {
          ...credentials,
          projectName,
        }),
      );
    }, projects || []);

    return Promise.all(request)
      .then((data) => {
        const summarySettingsMap = {};
        R.addIndex(R.forEach)((projectName, idx) => {
          summarySettingsMap[projectName] = data[idx];
        }, projects || []);
        return summarySettingsMap;
      })
      .catch((err) => {
        console.err(err.message || String(err));
        return {};
      });
  }

  @autobind
  anomalyLineChartRedner(rootCauses, incident) {
    const { intl } = this.props;
    return (
      <div className="flex-row flex-center-align flex-end-justify">
        {rootCauses.length > 0 && (
          <div style={{ marginBottom: 8 }}>
            <Button
              size="small"
              type="primary"
              icon={<LineChartOutlined />}
              onClick={() => this.handleOverallLineChartClick(incident)}
              disabled={rootCauses.length === 0}
            >
              {intl.formatMessage(eventMessages.anomalyLineChart)}
            </Button>
          </div>
        )}
      </div>
    );
  }

  @autobind
  handleOverallLineChartClick(currentIncident) {
    const { intl, credentials, compareIncidents } = this.props;
    const { incidentsRootCauseMap } = this.state;

    const incident = currentIncident || compareIncidents[0];

    // build project metric/instance info map
    const projectMap = {};
    R.forEach((chainList) => {
      R.forEach((chain) => {
        const { sourceInfoList } = chain;
        R.forEach((sourceInfo) => {
          const { sourceDetail, instanceName, owner, displayTimestamp } = sourceInfo;
          const { isMetric, content } = sourceDetail || {};
          if (isMetric) {
            let { projectName } = sourceInfo;
            projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;

            if (!R.has(projectName, projectMap)) {
              projectMap[projectName] = {
                projectName,
                times: [],
                instances: [],
                metrics: [],
                metricsMap: {},
              };
            }

            projectMap[projectName].times.push(displayTimestamp);
            projectMap[projectName].instances.push(instanceName);
            projectMap[projectName].metrics.push(content);
            if (!R.has(content, projectMap[projectName].metricsMap)) {
              projectMap[projectName].metricsMap[content] = [];
            }
            projectMap[projectName].metricsMap[content].push(instanceName);
          }
        }, sourceInfoList || []);
      }, chainList);
    }, R.values(incidentsRootCauseMap));

    const projectNames = R.keys(projectMap);
    this.selectProject = projectNames.length > 0 ? projectNames[0] : undefined;
    Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      bodyStyle: { marginLeft: 0 },
      style: { minWidth: 500 },
      content: (
        <div className="flex-row flex-center-align">
          <div style={{ marginRight: 8 }}>Metric Project:</div>
          <div className="flex-grow">
            <Select
              size="small"
              style={{ width: 250 }}
              filterOption
              options={R.map((item) => ({ value: item, label: item }), projectNames)}
              defaultValue={this.selectProject}
              onChange={(project) => {
                this.selectProject = project;
              }}
            />
          </div>
        </div>
      ),
      onOk: (close) => this.handleSelectProjectConfirm(close, incident, get(projectMap, this.selectProject, {})),
    });
  }

  @autobind
  handleSelectProjectConfirm(close, incident, params) {
    const { projectName, times, instances, metrics, metricsMap } = params;
    const { startTimestamp: incidentStart, endTimestamp: incidentEnd, patternId, isIncident, isDeployment } = incident;
    const rootCauseStart = R.reduce(R.min, incidentStart, times);
    const rootCauseEnd = R.reduce(R.max, incidentStart, times);
    const startTimestamp = moment.utc(rootCauseStart).startOf('day').valueOf();
    const endTimestamp = moment.utc(rootCauseEnd).endOf('day').valueOf();
    const { location } = this.props;
    const { customerName } = parseLocation(location);

    const incidentInfo = {
      startTimestamp: incidentStart,
      endTimestamp: incidentEnd,
      patternId,
      type: isIncident ? 'incident' : isDeployment ? 'deployment' : 'normal',
    };
    const metricAnomalyMap = R.mapObjIndexed((val) => {
      return R.uniq(val);
    }, metricsMap);

    const query = {
      customerName,
      projectName,
      instanceGroup: 'All',
      startTimestamp,
      endTimestamp,
      justSelectMetric: R.join(',', R.uniq(metrics)),
      justInstanceList: R.join(',', R.uniq(instances)),
      // withBaseline: true,
      incidentInfo: JSON.stringify(incidentInfo),
      metricAnomalyMap: JSON.stringify(metricAnomalyMap),
      // startTimeWindow: rootCauseStart - 600000,
      // endTimeWindow: rootCauseEnd + 600000,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');

    close();
  }

  @autobind
  renderCommonRootCauses() {
    const { environmentId, systemId, currentTheme } = this.props;
    const { commonCausals, sameKeysRootCauseMap, incidentsMap } = this.state;

    let newCommonCausals = [];
    R.forEach((key) => {
      if (incidentsMap[key]) newCommonCausals.push(incidentsMap[key]);
    }, commonCausals || []);
    newCommonCausals = R.sortWith([R.ascend(R.prop('startTimestamp'))])(newCommonCausals);

    return (
      <div className="full-height flex-col" style={{ paddingTop: 8 }}>
        {commonCausals.length === 0 && (
          <div className="flex-grow flex-min-height">
            <Alert style={{ marginBottom: 8 }} message="No common root causes found." type="info" showIcon />
          </div>
        )}
        {commonCausals.length > 0 && (
          <div className="flex-grow flex-min-height overflow-y-auto" style={{ paddingRight: 12 }}>
            {R.map((incident) => {
              const { patternName, patternId, startTimestamp, id: incidentId } = incident;
              const rootCauses = sameKeysRootCauseMap[incidentId];

              const patternIdAndName = Defaults.PatternIdNameStr(
                { patternName, patternId },
                { hasFullName: true },
              ).patternNameStr;

              return (
                <div key={`common-${incidentId}`} className="flex-col" style={{ marginBottom: 16 }}>
                  <div className="flex-row flex-center-align" style={{ marginBottom: 4 }}>
                    <span className="light-lable bold" style={{ marginRight: 16 }}>
                      Incident:
                    </span>
                    <span>{`(${moment
                      .utc(startTimestamp)
                      .format(Defaults.ShortDateTimeFormat)}) ${patternIdAndName}`}</span>
                  </div>
                  <div className="overflow-hidden flex-col" style={{ minHeight: 524, maxHeight: 524 }}>
                    <LikelyRootCausesVisualizer
                      key="RC"
                      currentTheme={currentTheme}
                      incident={incident}
                      systemId={systemId}
                      data={rootCauses}
                      environmentId={environmentId}
                      onReload={this.reloadData}
                      summarySettingsMap={this.summarySettingsMap || {}}
                      initDataFlag={this.initDataFlag}
                      handleChangeInitDataFlag={(flag) => {
                        this.initDataFlag = flag;
                      }}
                      ownRank
                      maxZoom={0.8}
                      anomalyLineChartRedner={
                        rootCauses ? () => this.anomalyLineChartRedner(rootCauses, incident) : undefined
                      }
                      useScroll
                    />
                  </div>
                </div>
              );
            }, newCommonCausals || [])}
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderDifferentRootCauses() {
    const { environmentId, systemId, currentTheme } = this.props;
    const { incidentsMap, incidentsDiffRootCauseMap, diffCausals } = this.state;

    let newDiffCausals = [];
    R.forEach((key) => {
      if (incidentsMap[key]) newDiffCausals.push(incidentsMap[key]);
    }, diffCausals || []);
    newDiffCausals = R.sortWith([R.ascend(R.prop('startTimestamp'))])(newDiffCausals);

    return (
      <div className="full-height flex-col" style={{ paddingTop: 8 }}>
        {diffCausals.length === 0 && (
          <div className="flex-grow flex-min-height">
            <Alert style={{ marginBottom: 8 }} message="No different root causes found." type="info" showIcon />
          </div>
        )}
        {diffCausals.length > 0 && (
          <div className="flex-grow flex-min-height overflow-y-auto" style={{ paddingRight: 12 }}>
            {R.map((incident) => {
              const { patternName, patternId, startTimestamp, id: incidentId } = incident;
              const rootCauses = incidentsDiffRootCauseMap[incidentId];

              const patternIdAndName = Defaults.PatternIdNameStr(
                { patternName, patternId },
                { hasFullName: true },
              ).patternNameStr;

              return (
                <div key={`diff-${incidentId}`} className="flex-col" style={{ marginBottom: 16 }}>
                  <div className="flex-row flex-center-align" style={{ marginBottom: 4 }}>
                    <span className="light-lable bold" style={{ marginRight: 16 }}>
                      Incident:
                    </span>
                    <span>{`(${moment
                      .utc(startTimestamp)
                      .format(Defaults.ShortDateTimeFormat)}) ${patternIdAndName}`}</span>
                  </div>
                  <div className="overflow-hidden flex-col" style={{ minHeight: 524, maxHeight: 524 }}>
                    <LikelyRootCausesVisualizer
                      key="RC"
                      currentTheme={currentTheme}
                      incident={incident}
                      systemId={systemId}
                      data={rootCauses}
                      environmentId={environmentId}
                      onReload={this.reloadData}
                      summarySettingsMap={this.summarySettingsMap || {}}
                      initDataFlag={this.initDataFlag}
                      handleChangeInitDataFlag={(flag) => {
                        this.initDataFlag = flag;
                      }}
                      ownRank
                      maxZoom={0.8}
                      anomalyLineChartRedner={
                        rootCauses ? () => this.anomalyLineChartRedner(rootCauses, incident) : undefined
                      }
                      useScroll
                    />
                  </div>
                </div>
              );
            }, newDiffCausals || [])}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { intl, onClose } = this.props;
    const { isLoading, activeKey, hasCommonRC, hasDifferentRC, incidentsMap, diffCausals } = this.state;
    const { incidentsDiffRootCauseMap, commonCausals } = this.state;
    const isCommon = activeKey === 'common';

    const lengths = R.map(R.length, R.values(incidentsDiffRootCauseMap || {}));
    const totalLength = R.sum(lengths);

    return (
      <Modal
        visible
        title={intl.formatMessage(logMessages.relatedAnomalies)}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={1400}
        bodyStyle={{ height: 620 }}
        footer={null}
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
          <div
            className="flex-col"
            style={{
              width: 310,
              flexShrink: 0,
              marginRight: 16,
              borderRight: '1px solid var(--virtualized-table-border-color)',
            }}
          >
            <div className="flex-col" style={{ maxHeight: 300, lineHeight: '34px', marginBottom: 8 }}>
              <div>{`Common causal chains (Total: ${this.sameKeysLength})`}</div>
              {hasCommonRC && (
                <div className="flex-grow flex-min-height overflow-y-auto overflow-x-hidden" style={{ paddingLeft: 8 }}>
                  <Checkbox.Group
                    className="full-width"
                    value={commonCausals}
                    onChange={(value) => {
                      this.setState({ activeKey: 'common', commonCausals: value, diffCausals: [] });
                    }}
                  >
                    <Row>
                      {R.map((incidentId) => {
                        const incident = incidentsMap[incidentId] || {};
                        const { patternName, patternId, startTimestamp } = incident;

                        const patternIdAndName = Defaults.PatternIdNameStr(
                          { patternName, patternId },
                          { hasFullName: true },
                        ).patternNameStr;

                        const label = `(${moment
                          .utc(startTimestamp)
                          .format(Defaults.ShortDateTimeFormat)}) ${patternIdAndName}`;

                        return (
                          <Col
                            span={24}
                            key={incidentId}
                            className="flex-row flex-center-align"
                            style={{ marginTop: 4 }}
                          >
                            <Checkbox value={incidentId} />
                            <span
                              className="flex-grow flex-row flex-center-align overflow-hidden"
                              style={{ marginLeft: 8 }}
                            >
                              <Popover content={label} mouseEnterDelay={0.3} placement="top">
                                <span className="hidden-line-with-ellipsis flex-grow">{label}</span>
                              </Popover>
                            </span>
                          </Col>
                        );
                      }, this.incidentsSameRootCauses || [])}
                    </Row>
                  </Checkbox.Group>
                </div>
              )}
            </div>
            <div className="flex-grow flex-col" style={{ lineHeight: '34px' }}>
              <div>{`Different causal chains: (Total: ${totalLength})`}</div>
              {hasDifferentRC && (
                <div className="flex-grow flex-min-height overflow-y-auto overflow-x-hidden" style={{ paddingLeft: 8 }}>
                  <Checkbox.Group
                    className="full-width"
                    value={diffCausals}
                    onChange={(value) => {
                      this.setState({ activeKey: 'different', diffCausals: value, commonCausals: [] });
                    }}
                  >
                    <Row>
                      {R.map((incidentId) => {
                        const incident = incidentsMap[incidentId] || {};
                        const { patternName, patternId, startTimestamp } = incident;

                        const patternIdAndName = Defaults.PatternIdNameStr(
                          { patternName, patternId },
                          { hasFullName: true },
                        ).patternNameStr;

                        const label = `(${moment
                          .utc(startTimestamp)
                          .format(Defaults.ShortDateTimeFormat)}) ${patternIdAndName}`;

                        return (
                          <Col
                            span={24}
                            key={incidentId}
                            className="flex-row flex-center-align"
                            style={{ marginTop: 4 }}
                          >
                            <Checkbox value={incidentId} />
                            <span
                              className="flex-grow flex-row flex-center-align overflow-hidden"
                              style={{ marginLeft: 8 }}
                            >
                              <Popover content={label} mouseEnterDelay={0.3} placement="top">
                                <span className="hidden-line-with-ellipsis flex-grow">{label}</span>
                              </Popover>
                            </span>
                          </Col>
                        );
                      }, this.incidentsDiffRootCauses || [])}
                    </Row>
                  </Checkbox.Group>
                </div>
              )}
            </div>
          </div>
          <div className="flex-grow flex-min-width">
            {isCommon ? this.renderCommonRootCauses() : this.renderDifferentRootCauses()}
          </div>
        </Spin>
      </Modal>
    );
  }
}

const CompareIncidentsNewModal = injectIntl(CompareIncidentsNewModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, projectDisplayMap, systemsMap, currentTheme } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      currentLocale,
      projectDisplayMap,
      systemsMap,
      userInfo,
      credentials,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(CompareIncidentsNewModal);
