/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import VLink from 'valuelink';
import { isInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { RightOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';

import { Input, Select } from '../../../../lib/fui/react';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { projectWizardMessages } from '../../../../common/settings/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  componentState: Object,
};

class GoogleAdsSetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;
    this.state = {
      refreshToken: '',
      developerToken: '',
      clientId: '',
      clientSecret: '',
      loginCustomerId: '',
      customerId: '',
      samplingInterval: 1,
      samplingUnit: 3600,
      verified: true,

      ...(componentState || {}),
    };
    this.samplingUnit = [
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
  }

  componentDidMount() {
    this.props.saveProjectInfo('GoogleAds', {}, this.state);
  }

  @autobind
  handleConfirmClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const {
      refreshToken,
      developerToken,
      clientId,
      clientSecret,
      loginCustomerId,
      customerId,
      samplingInterval,
      samplingUnit,
    } = this.state;
    this.props.createProject(
      'GoogleAds',
      {
        refreshToken,
        developerToken,
        clientId,
        clientSecret,
        loginCustomerId,
        customerId,
        samplingInterval: Number(samplingInterval) * samplingUnit,
      },
      this.state,
    );
  }

  render() {
    const { intl, isLoading } = this.props;

    const refreshTokenLink = VLink.state(this, 'refreshToken').check(
      (word) => Boolean(word),
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const developerTokenLink = VLink.state(this, 'developerToken').check(
      (word) => Boolean(word),
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const clientIdLink = VLink.state(this, 'clientId').check(
      (word) => Boolean(word),
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const clientSecretLink = VLink.state(this, 'clientSecret').check(
      (word) => Boolean(word),
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const loginCustomerIdLink = VLink.state(this, 'loginCustomerId').check(
      (word) => Boolean(word),
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const customerIdLink = VLink.state(this, 'customerId').check(
      (word) => Boolean(word),
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const samplingIntervalLink = VLink.state(this, 'samplingInterval').check((word) => {
      return Number(word) > 0 && isInteger(Number(word));
    }, 'The sampling interal is required and it has to be an integer');
    const samplingUnitLink = VLink.state(this, 'samplingUnit').check(
      (word) => word,
      intl.formatMessage(appFieldsMessages.inputRequired),
    );

    const hasError =
      refreshTokenLink.error ||
      developerTokenLink.error ||
      clientIdLink.error ||
      clientSecretLink.error ||
      loginCustomerIdLink.error ||
      customerIdLink.error ||
      samplingIntervalLink.error ||
      samplingUnitLink.error ||
      this.props.hasError;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.GoogleAdsIntro),
          }}
        />

        <Card className="ui form">
          <div className="field required">
            <label>Refresh token</label>
            <Input type="password" autoComplete="new-password" valueLink={refreshTokenLink} />
          </div>
          <div className="field required">
            <label>Developer token</label>
            <Input type="password" valueLink={developerTokenLink} />
          </div>
          <div className="field required">
            <label>Client Id</label>
            <Input type="password" valueLink={clientIdLink} />
          </div>
          <div className="field required">
            <label>Client secret</label>
            <Input type="password" valueLink={clientSecretLink} />
          </div>
          <div className="field required">
            <label>Login customer Id</label>
            <Input type="password" valueLink={loginCustomerIdLink} />
          </div>
          <div className="field required">
            <label>Customer Id</label>
            <Input type="password" valueLink={customerIdLink} />
          </div>
          <div className="field">
            <label>Sampling Interval</label>
            <div className="flex-row">
              <Input type="number" valueLink={samplingIntervalLink} style={{ width: 200 }} disabled />
              <Select
                valueLink={samplingUnitLink}
                style={{ width: 130, padding: '2px 0' }}
                options={this.samplingUnit}
              />
            </div>
          </div>
        </Card>

        <div style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}>
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasError}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

export default GoogleAdsSetting;
