/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { FilterOutlined } from '@ant-design/icons';
import { Switch, Tabs, Select, InputNumber, Drawer, Button, Input, DatePicker, message } from 'antd';

import { State } from '../../../common/types';
import { getLoadStatus, CausalParser, buildLocation, parseLocation } from '../../../common/utils';
import { Container } from '../../../lib/fui/react';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/causal/actions';
import { Options } from '../../../common/app';
import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

import CausalRelationTree2 from './CausalRelationTree2';
import CausalCorrelationTree2 from './CausalCorrelationTree2';
import CausalDependencyTree2 from '../../../../_legacy/src/web/causal/components/CausalDependencyTree2';
import { eventMessages } from '../../../common/metric/messages';
// import CausalDependencyGraph2 from './CausalDependencyGraph2';
// import ComponentCorrelation2 from './ComponentCorrelation2';

type Props = {
  className: String,
  isLoadingTasks: Boolean,
  // eslint-disable-next-line
  causalGroup: Object,
  causalIncidentInfo: Object,
  // eslint-disable-next-line
  causalIncidentList: Array<Object>,
  incidentParams: Object,
  view: String,
  relationTimeThreshold: String,
  relationProbability: String,
  relationCount: Number,
  joinDependency: Boolean,
  // eslint-disable-next-line
  kpiPredictionProbability: String,
  onViewChange: Function,
  onJoinDependencyChanged: Function,

  hideCausalRelation: Boolean,
  hideCorrelation: Boolean,
  hideDependency: Boolean,
  // eslint-disable-next-line
  hideKpiPrediction: Boolean,
  fixedCountAndProb: Boolean,

  intl: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  createLoadAction: Function,

  incidentMetaData: Object,
  location: Object,
  replace: Function,
};

class CausalIncidentTaskCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.incidentLoader = 'causal_incident_loader';

    const { intl, relationTimeThreshold, relationCount, relationProbability, location } = props;
    const { resultStartstamp, resultEndstamp } = parseLocation(location);

    this.state = {
      refresh: null,
      dynamicChange: null,
      incidentMetaData: null,
      isLoadingGraph: false,

      relationDurationOptions: Options.RelationTimeThresholdOptions,
      relationProbabilityOptions: Options.RelationProbability,

      visibleFilterDrawer: false,
      graphView: 'source',
      relationTimeThreshold: relationTimeThreshold || Options.RelationTimeThresholdOptions[0].value,
      relationProbability: relationProbability || Options.RelationProbability[0].value,
      relationCount,
      filterModality: 'all',
      filterPattern: '',

      instanceName: null,

      resultStartDate: moment.utc(Number(resultStartstamp)),
      resultEndDate: moment.utc(Number(resultEndstamp)),
    };
    this.modalityFilterOptions = [
      { label: 'All', value: 'all' },
      { label: intl.formatMessage(causalMessages.metricToMetric, { flag: '<->' }), value: 'metric-metric' },
      { label: intl.formatMessage(causalMessages.logToLog, { flag: '<->' }), value: 'log-log' },
      { label: intl.formatMessage(causalMessages.metricToLog, { flag: '<->' }), value: 'log-metric' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalMetric)} -> ${intl.formatMessage(
          causalMessages.causalLog,
        )}`,
        value: 'metric->log',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalLog)} -> ${intl.formatMessage(
          causalMessages.causalMetric,
        )}`,
        value: 'log->metric',
      },
      { label: intl.formatMessage(causalMessages.metricToIncident, { flag: '<->' }), value: 'incident-metric' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalIncident)} -> ${intl.formatMessage(
          causalMessages.causalMetric,
        )}`,
        value: 'incident->metric',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalMetric)} -> ${intl.formatMessage(
          causalMessages.causalIncident,
        )}`,
        value: 'metric->incident',
      },
      { label: intl.formatMessage(causalMessages.logToIncident, { flag: '<->' }), value: 'incident-log' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalIncident)} -> ${intl.formatMessage(
          causalMessages.causalLog,
        )}`,
        value: 'incident->log',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalLog)} -> ${intl.formatMessage(
          causalMessages.causalIncident,
        )}`,
        value: 'log->incident',
      },
      { label: intl.formatMessage(causalMessages.metricToAlert, { flag: '<->' }), value: 'alert-metric' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalAlert)} -> ${intl.formatMessage(
          causalMessages.causalMetric,
        )}`,
        value: 'alert->metric',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalMetric)} -> ${intl.formatMessage(
          causalMessages.causalAlert,
        )}`,
        value: 'metric->alert',
      },
      { label: intl.formatMessage(causalMessages.logToAlert, { flag: '<->' }), value: 'alert-log' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalAlert)} -> ${intl.formatMessage(causalMessages.causalLog)}`,
        value: 'alert->log',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalLog)} -> ${intl.formatMessage(causalMessages.causalAlert)}`,
        value: 'log->alert',
      },
      { label: intl.formatMessage(causalMessages.alertToIncident, { flag: '<->' }), value: 'alert-incident' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalIncident)} -> ${intl.formatMessage(
          causalMessages.causalAlert,
        )}`,
        value: 'incident->alert',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalAlert)} -> ${intl.formatMessage(
          causalMessages.causalIncident,
        )}`,
        value: 'alert->incident',
      },
    ];
  }

  componentDidMount() {
    this.reloadMetaData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.causalIncidentInfo !== this.props.causalIncidentInfo) {
      this.reloadMetaData(nextProps);
    } else if (nextProps.incidentMetaData !== this.props.incidentMetaData) {
      this.parseData(nextProps);
    }
  }

  @autobind
  reloadMetaData(props) {
    const { createLoadAction, incidentParams, causalIncidentInfo, causalGroup } = props;
    const { causalKey, customerName, startTimestamp, endTimestamp } = incidentParams;
    if (causalIncidentInfo && customerName && causalKey && startTimestamp && endTimestamp) {
      createLoadAction(
        ActionTypes.LOAD_CAUSAL_INCIDENT_META_DATA,
        {
          causalKey,
          customerName,
          startTime: startTimestamp,
          endTime: endTimestamp,
          projectList: causalGroup?.projectList,
        },
        this.incidentLoader,
      );
    }
  }

  @autobind
  parseData(props) {
    const { incidentMetaData } = props;
    this.setState({ incidentMetaData });
  }

  @autobind
  handleViewClick(view) {
    this.setState({ instanceName: null, graphView: 'source' }, () => {
      this.props.onViewChange(view);
    });
  }

  @autobind
  handleFilterChange(state, callBackFunction) {
    this.setState(state, callBackFunction);
  }

  @autobind
  handleChangeGraphView(event) {
    const graphView = event.target.value;
    this.setState({ graphView });
  }

  @autobind
  onChangeDuration(newValue) {
    this.setState({ relationTimeThreshold: newValue });
  }

  @autobind
  onChangeProbability(newValue) {
    const newState = {
      relationProbability: newValue,
    };
    this.setState(newState);
  }

  @autobind
  onChangeCount(newValue) {
    this.setState({ relationCount: newValue });
  }

  @autobind
  toggleJoinDependencyGraph(e) {
    const { joinDependency } = this.props;
    this.props.onJoinDependencyChanged(!joinDependency);
  }

  @autobind
  onChangeModality(newValue) {
    this.setState({ filterModality: newValue });
  }

  @autobind
  onChangePattern(e) {
    this.setState({ filterPattern: e.target.value });
  }

  @autobind
  renderIncidentState() {
    const { intl, view, location } = this.props;
    const { resultStartDate, resultEndDate } = this.state;
    const { resultStartstamp, resultEndstamp, startTimestamp, endTimestamp } = parseLocation(location);
    const showTime = resultStartstamp && resultEndstamp && ['relation', 'correlation'].indexOf(view) !== -1;
    let changeTimeFlag = false;
    if (showTime)
      changeTimeFlag =
        resultStartDate.valueOf() !== Number(resultStartstamp) || resultEndDate.valueOf() !== Number(resultEndstamp);

    return (
      <div className="full-height flex-row flex-center-align" style={{ height: 44 }}>
        <Button
          size="small"
          icon={<FilterOutlined />}
          style={{ marginLeft: 8 }}
          onClick={() => this.setState({ visibleFilterDrawer: true })}
        >
          {intl.formatMessage(causalMessages.filterOptions)}
        </Button>

        {showTime && (
          <>
            <div className="flex-row flex-center-align" style={{ marginLeft: 16 }}>
              <span style={{ width: 106 }}>{intl.formatMessage(appFieldsMessages.resultStartDate)}:</span>
              <DatePicker
                size="small"
                allowClear={false}
                value={resultStartDate}
                format="MM-DD HH:mm"
                onChange={(date) => {
                  this.setState({ resultStartDate: date });
                }}
                disabledDate={(current) => {
                  return (
                    current &&
                    (current < moment.utc(Number(startTimestamp)).startOf('day') ||
                      current > moment.utc(Number(endTimestamp)).endOf('day'))
                  );
                }}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginLeft: 16, marginRight: 8 }}>
              <span style={{ width: 106 }}>{intl.formatMessage(appFieldsMessages.resultEndDate)}:</span>
              <DatePicker
                size="small"
                allowClear={false}
                value={resultEndDate}
                format="MM-DD HH:mm"
                onChange={(date) => {
                  this.setState({ resultEndDate: date });
                }}
                disabledDate={(current) => {
                  return (
                    current &&
                    (current < moment.utc(Number(startTimestamp)).startOf('day') ||
                      current > moment.utc(Number(endTimestamp)).endOf('day'))
                  );
                }}
              />
            </div>
          </>
        )}

        <Button
          size="small"
          style={{ marginLeft: 8 }}
          onClick={this.refresh}
          type={showTime ? (changeTimeFlag ? 'primary' : 'default') : 'primary'}
        >
          {intl.formatMessage(appButtonsMessages.refresh)}
        </Button>
      </div>
    );
  }

  @autobind
  renderFilterOptions() {
    const { intl, view } = this.props;
    const { incidentMetaData, isLoadingGraph } = this.state;
    const { dependencyStatus } = incidentMetaData || {};

    const joinDependency = Boolean(this.props.joinDependency);
    const { relationDurationOptions, relationProbabilityOptions } = this.state;
    const { relationTimeThreshold, relationProbability, relationCount, filterModality, filterPattern } = this.state;

    return (
      <div className="full-height flex-col flex-min-height">
        {false && ['relation', 'correlation'].indexOf(view) !== -1 && (
          <h5 style={{ fontWeight: 500 }}>{intl.formatMessage(causalMessages.joinDependencyGraph)}:</h5>
        )}
        {false && ['relation', 'correlation'].indexOf(view) !== -1 && (
          <Switch
            disabled={!dependencyStatus || isLoadingGraph}
            style={{ width: 30 }}
            size="small"
            checked={joinDependency}
            onChange={this.toggleJoinDependencyGraph}
          />
        )}

        {view === 'relation' && <h5 style={{ fontWeight: 500 }}>{intl.formatMessage(causalMessages.duration)}:</h5>}
        {view === 'relation' && (
          <Select disabled={isLoadingGraph} value={relationTimeThreshold} onChange={this.onChangeDuration}>
            {relationDurationOptions.map((item) => (
              <Select.Option key={item.value} title={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        )}

        {['relation', 'correlation'].indexOf(view) !== -1 && (
          <h5 style={{ fontWeight: 500, marginTop: 8 }}>{intl.formatMessage(causalMessages.probability)}(≥):</h5>
        )}
        {['relation', 'correlation'].indexOf(view) !== -1 && (
          <Select disabled={isLoadingGraph} value={relationProbability} onChange={this.onChangeProbability}>
            {relationProbabilityOptions.map((item) => (
              <Select.Option key={item.value} title={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        )}

        {['relation', 'correlation'].indexOf(view) !== -1 && (
          <h5 style={{ fontWeight: 500, marginTop: 8 }}>{intl.formatMessage(appFieldsMessages.count)}(≥):</h5>
        )}
        {['relation', 'correlation'].indexOf(view) !== -1 && (
          <InputNumber
            disabled={isLoadingGraph}
            style={{ width: '100%' }}
            min={0}
            max={1000}
            value={relationCount}
            onChange={this.onChangeCount}
          />
        )}

        {['relation', 'correlation'].indexOf(view) !== -1 && (
          <h5 style={{ fontWeight: 500, marginTop: 8 }}>{intl.formatMessage(causalMessages.modality)}:</h5>
        )}
        {['relation', 'correlation'].indexOf(view) !== -1 && (
          <Select disabled={isLoadingGraph} value={filterModality} onChange={this.onChangeModality}>
            {this.modalityFilterOptions.map((item) => (
              <Select.Option key={item.value} title={item.value}>
                <span style={{ whiteSpace: 'pre' }}> {item.label} </span>
              </Select.Option>
            ))}
          </Select>
        )}

        {['relation', 'correlation'].indexOf(view) !== -1 && (
          <h5 style={{ fontWeight: 500, marginTop: 8 }}>{intl.formatMessage(eventMessages.patternId)}:</h5>
        )}
        {['relation', 'correlation'].indexOf(view) !== -1 && (
          <Input
            disabled={isLoadingGraph}
            style={{ width: '100%' }}
            allowClear
            value={filterPattern}
            onChange={this.onChangePattern}
          />
        )}
      </div>
    );
  }

  @autobind
  refresh() {
    const { location, replace, view, intl } = this.props;
    const { resultStartDate, resultEndDate } = this.state;
    const query = parseLocation(location);
    const { resultStartstamp, resultEndstamp } = query;
    if (resultStartstamp && resultEndstamp && ['relation', 'correlation'].indexOf(view) !== -1) {
      if (resultStartDate > resultEndDate) {
        message.error(intl.formatMessage(appMessages.apiFaild));
        return;
      }
      replace(
        buildLocation(
          location.pathname,
          {},
          { ...query, resultStartstamp: resultStartDate.valueOf(), resultEndstamp: resultEndDate.valueOf() },
        ),
      );
    }
    this.setState({ refresh: moment.utc().valueOf() });
  }

  @autobind
  updateState(newState) {
    // disable options filter when reload data
    const { isLoadingGraph } = newState;
    this.setState({ isLoadingGraph });
  }

  @autobind
  handleInstanceChange(instanceName) {
    if (instanceName) {
      // zoom in intra
      const {
        graphView,
        relationDurationOptions,
        relationTimeThreshold,
        relationProbability,
        relationCount,
        filterModality,
        filterPattern,
      } = this.state;
      this.backupRelationFilterGraphView = graphView;
      this.backupRelationDurationOptions = relationDurationOptions;
      this.backupRelationTimeThreshold = relationTimeThreshold;
      this.backupRelationProbability = relationProbability;
      this.backupRelationCount = relationCount;
      this.backupRelationFilterModality = filterModality;
      this.backupRelationFilterPattern = filterPattern;

      // get node duration options and default value
      const { incidentMetaData } = this.state;
      const instanceCausalInfo = incidentMetaData.instanceCausalInfo || {};
      const instanceInfo = get(instanceCausalInfo, instanceName, {});
      const { minIntraCausalDuration, maxIntraCausalDuration } = instanceInfo.intraDuration;
      const { relationDurationOptions: intraDurationOptions } = CausalParser.getRootnodeDuration(
        instanceName,
        minIntraCausalDuration,
        maxIntraCausalDuration,
        false,
        true,
      );
      const relationTimeThresholdFromProps = intraDurationOptions[0].value;

      this.setState({
        instanceName,
        relationTimeThreshold: relationTimeThresholdFromProps,
        relationDurationOptions:
          intraDurationOptions.length > 0 ? intraDurationOptions : Options.RelationTimeThresholdOptions,
      });
    } else {
      // back to inter
      const graphView = this.backupRelationFilterGraphView;
      const relationDurationOptions = this.backupRelationDurationOptions;
      const relationTimeThreshold = this.backupRelationTimeThreshold;
      const relationProbability = this.backupRelationProbability;
      const relationCount = this.backupRelationCount;
      const filterModality = this.backupRelationFilterModality;
      const filterPattern = this.backupRelationFilterPattern;

      this.setState({
        instanceName,
        graphView,
        relationDurationOptions,
        relationTimeThreshold,
        relationProbability,
        relationCount,
        filterModality,
        filterPattern,
      });
    }
  }

  render() {
    const { intl, loadStatus, className, isLoadingTasks, view, incidentParams, causalIncidentInfo } = this.props;
    const { hideCausalRelation, hideCorrelation, hideDependency, fixedCountAndProb } = this.props;

    const {
      refresh,
      dynamicChange,
      incidentMetaData,
      instanceName,
      graphView,
      relationTimeThreshold,
      relationProbability,
      relationCount,
      filterModality,
      filterPattern,
    } = this.state;

    const { isLoading } = getLoadStatus(get(loadStatus, this.incidentLoader), intl);
    return (
      <Container
        className={`${className} flex-col flex-min-height ${isLoadingTasks || isLoading ? 'loading' : ''} content-bg`}
        style={{ padding: '0px 8px 8px 8px' }}
      >
        <Tabs
          className="full-height ant-tabs-content-full-height flex-col"
          activeKey={view}
          onChange={this.handleViewClick}
          tabBarExtraContent={this.renderIncidentState()}
        >
          {!hideCausalRelation && (
            <Tabs.TabPane tab={intl.formatMessage(causalMessages.relatedAnomalies)} key="relation">
              <CausalRelationTree2
                view={view}
                refresh={refresh}
                dynamicChange={dynamicChange}
                causalIncidentInfo={causalIncidentInfo}
                incidentMetaData={incidentMetaData}
                instanceName={instanceName}
                joinDependency
                graphView={graphView}
                relationTimeThreshold={relationTimeThreshold}
                relationProbability={relationProbability}
                relationCount={relationCount}
                filterModality={filterModality}
                filterPattern={filterPattern}
                incidentParams={incidentParams}
                onChangeGraphView={this.handleChangeGraphView}
                onInstanceChange={this.handleInstanceChange}
                onFilterChange={this.handleFilterChange}
                fixedCountAndProb={fixedCountAndProb}
                updateState={this.updateState}
                onChangeModality={this.onChangeModality}
              />
            </Tabs.TabPane>
          )}
          {!hideCorrelation && (
            <Tabs.TabPane tab={intl.formatMessage(causalMessages.concurrentAnomalies)} key="correlation">
              <CausalCorrelationTree2
                view={view}
                refresh={refresh}
                dynamicChange={dynamicChange}
                causalIncidentInfo={causalIncidentInfo}
                incidentMetaData={incidentMetaData}
                instanceName={instanceName}
                joinDependency
                relationProbability={relationProbability}
                relationCount={relationCount}
                filterModality={filterModality}
                filterPatter={filterPattern}
                incidentParams={incidentParams}
                onInstanceChange={this.handleInstanceChange}
                onFilterChange={this.handleFilterChange}
                fixedCountAndProb={fixedCountAndProb}
                updateState={this.updateState}
                onChangeModality={this.onChangeModality}
              />
            </Tabs.TabPane>
          )}
          {!hideDependency && (
            <Tabs.TabPane tab={intl.formatMessage(causalMessages.dependencyGraph)} key="dependency">
              <CausalDependencyTree2
                view={view}
                refresh={refresh}
                dynamicChange={dynamicChange}
                causalIncidentInfo={causalIncidentInfo}
                incidentMetaData={incidentMetaData}
                incidentParams={incidentParams}
                graphView={graphView}
                onChangeGraphView={this.handleChangeGraphView}
              />
            </Tabs.TabPane>
          )}
        </Tabs>

        <Drawer
          title={intl.formatMessage(causalMessages.filterOptions)}
          placement="right"
          closable={false}
          onClose={() => this.setState({ visibleFilterDrawer: false })}
          visible={this.state.visibleFilterDrawer}
        >
          {this.renderFilterOptions()}
        </Drawer>
      </Container>
    );
  }
}

const CausalIncidentTask = injectIntl(CausalIncidentTaskCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    const { incidentMetaData } = state.causal;
    return {
      loadStatus,
      projects,
      incidentMetaData: get(incidentMetaData, 'data', null),
      location,
    };
  },
  { createLoadAction, replace },
)(CausalIncidentTask);
