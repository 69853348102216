/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 **/
import { getHttpStatusInfo } from '../utils';
import { isBoolean, isString, isObject } from 'lodash';

/**
 * Get actions for loading status, the return object contains show, hide, and error load status actions
 * The default will use the app loader or page loader.
 * If loader is null or false, will not get the loader, which is used to disable show the loading spinning.
 */
const getLoadStatus = (status, intl) => {
  let isLoading = false;
  let isLoaded = false;
  let errorMessage = null;
  let errorInfo = null;

  if (isBoolean(status)) {
    isLoading = status;
    isLoaded = !isLoading;
  } else if (isString(status)) {
    isLoading = status === 'loading';
    isLoaded = status === 'loaded';
  } else if (isObject(status)) {
    isLoading = true;
    const { message, params } = status;
    errorInfo = params.error ? getHttpStatusInfo(params.error.response) : 'error';
    // Use intl to get the message content
    if (isObject(message)) {
      errorMessage = intl.formatMessage(message, params);
    } else {
      errorMessage = message;
    }
  }
  return { isLoading, isLoaded, errorMessage, errorInfo };
};

export default getLoadStatus;
