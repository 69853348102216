import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { Spin, message, Empty } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, parseJSON, CausalParser, LogRenderers, CellRenderers, EventRenderers } from '../../../common/utils';

import { eventMessages } from '../../../common/metric/messages';
import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { settingsMessages } from '../../../common/settings/messages';
import { logMessages } from '../../../common/log/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type PropsLeadToIncident = {
  intl: Object,
  credentials: Object,
  projectDisplayMap: Object,
  projects: Array<Object>,
  incident: Object,
  incidentKnowledgeRule: Object,
  setIncidentKnowledgeRule: Function,
  updateLastActionInfo: Function,
  currentTheme: String,
};

const RenderIncidentKnowledgeRule = ({
  intl,
  credentials,
  projectDisplayMap,
  projects,
  incident,
  incidentKnowledgeRule,
  setIncidentKnowledgeRule,
  updateLastActionInfo,
  currentTheme,
  instanceDisplayNameMap,
}: PropsLeadToIncident) => {
  const [isLoading, setLoading] = useState(false);
  const [rule, setRule] = useState(incidentKnowledgeRule);
  const { predictionRuleInfo, realInstanceName } = incident || {};

  const statusOptions = {
    '-1': intl.formatMessage(eventMessages.ignored),
    0: intl.formatMessage(eventMessages.notInUse),
    1: intl.formatMessage(settingsMessages.confirmed),
    2: intl.formatMessage(eventMessages.inUse),
    10: intl.formatMessage(eventMessages.confirmedByOperator),
    3: 'To be Validated',
  };

  useEffect(() => {
    let cancel = false;

    if (!rule && predictionRuleInfo) {
      setLoading(true);
      updateLastActionInfo();
      fetchGet(getEndpoint('knowledge-base'), {
        ...credentials,
        predictionRuleInfo,
      })
        .then((data) => {
          let ruleSouceList;
          if (data && !R.isEmpty(data)) {
            const { matchedCount, unmatchedCount, incidentRule, rootCauseInfo = [], statusFlag } = data || {};
            const { rulePrimaryKey } = incidentRule || {};
            const projectName = rulePrimaryKey?.rulePartitionKey?.projectName || '';

            ruleSouceList = {
              matchedCount,
              unmatchedCount,
              isGlobalKnowledgeBase: R.includes('-1880685610', projectName),
              isDistributed: R.filter((item) => item.p && projectName !== item.p, rootCauseInfo || []).length > 0,
              userName: rulePrimaryKey?.rulePartitionKey?.userName,
              sourceDetailSet: incidentRule.sourceDetailSet,
              statusFlag,
            };
          }

          if (cancel) return;
          setRule(ruleSouceList);
          setIncidentKnowledgeRule(ruleSouceList);
          setLoading(false);
        })
        .catch((err) => {
          if (cancel) return;
          setLoading(false);
        });
    }

    return () => {
      cancel = true;
    };
  }, [predictionRuleInfo]);

  return (
    <div
      className="flex-col overflow-y-auto"
      style={{ minWidth: 200, maxWidth: 480, minHeight: 100, maxHeight: 350, paddingRight: 8 }}
    >
      <Spin spinning={isLoading} wrapperClassName="spin-full-height full-width full-height">
        {!rule && (
          <div className="full-width full-height flex-row flex-center-align flex-center-justify">
            <Empty />
          </div>
        )}

        {rule && (
          <>
            <div>{`This prediction is generated by ${
              rule.isGlobalKnowledgeBase ? 'global ' : rule.isDistributed ? 'distributed ' : ''
            }knowledge base`}</div>
            <div style={{ marginBottom: 16 }}>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120 }}>
                  {intl.formatMessage(logMessages.status)}:
                </div>
                <div>{statusOptions[R.toString(rule.statusFlag)]}</div>
              </div>
              {!rule.isGlobalKnowledgeBase && (
                <>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width: 120 }}>
                      {intl.formatMessage(settingsMessages.matchedCount)}:
                    </div>
                    <div>{rule.matchedCount}</div>
                  </div>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width: 120 }}>
                      {intl.formatMessage(settingsMessages.unmatchedCount)}:
                    </div>
                    <div>{rule.unmatchedCount}</div>
                  </div>
                </>
              )}

              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120 }}>
                  {intl.formatMessage(eventMessages.likelyRootCauses)}:
                </div>
                <div className="flex-grow flex-min-width">
                  {R.addIndex(R.map)((item, index) => {
                    const { projectName, instanceName, nid, componentName, content, metricDirection } = item;
                    let { type } = item;
                    const project = R.find((project) => project.projectShortName === projectName, projects || []);
                    let { isLogType, eventType } = CausalParser.getRelationLogType(type);
                    if (project && project.isDeployment) {
                      type = 'deployment';
                      isLogType = true;
                      eventType = 'deployment';
                    }
                    const sourceDetail = { ...item, isLogType, eventType, type };
                    const projectNameReal =
                      rule.userName !== credentials.userName ? `${projectName}@${rule.userName}` : projectName;
                    let rawDataJson;
                    try {
                      rawDataJson = JSON.parse(content);
                    } catch (error) {
                      // console.debug(error)
                    }

                    const isGlobalKnowledgeBase = R.includes('-1880685610', projectName || '');

                    const { instanceStr } = getInstanceDisplayName(
                      instanceDisplayNameMap,
                      instanceName || realInstanceName,
                      {
                        pn: projectName,
                        owner: rule?.userName,
                      },
                    );
                    return (
                      <div
                        key={index}
                        className="flex-col"
                        style={{ marginBottom: 16, paddingBottom: 16, borderBottom: '1px dashed #ccc' }}
                      >
                        <div className="flex-row">
                          <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                            {intl.formatMessage(eventMessages.rootCauseHop)}:
                          </div>
                          <div style={{ wordBreak: 'break-all' }}>{index + 1}</div>
                        </div>
                        {!isGlobalKnowledgeBase && (
                          <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                            <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                              {intl.formatMessage(appFieldsMessages.project)}:
                            </div>
                            <div style={{ wordBreak: 'break-all' }}>
                              {get(projectDisplayMap, projectNameReal, projectNameReal)}
                            </div>
                          </div>
                        )}

                        {componentName && (
                          <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                            <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                              {intl.formatMessage(appFieldsMessages.component)}:
                            </div>
                            <div style={{ wordBreak: 'break-all' }}>{componentName}</div>
                          </div>
                        )}
                        {!isGlobalKnowledgeBase && (
                          <>
                            <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                                {intl.formatMessage(appFieldsMessages.instance)}:
                              </div>
                              <div style={{ wordBreak: 'break-all' }}>{instanceStr}</div>
                            </div>
                            <div className="flex-row">
                              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                                {intl.formatMessage(appFieldsMessages.pattern)}:
                              </div>
                              <div style={{ wordBreak: 'break-all' }}>{nid}</div>
                            </div>
                          </>
                        )}

                        <div className="flex-row">
                          <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                            {intl.formatMessage(appFieldsMessages.type)}:
                          </div>
                          <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                            {isLogType
                              ? CellRenderers.logTypeRenderer({
                                  intl,
                                  rowData: { type: eventType },
                                  isHorizontal: true,
                                })
                              : intl.formatMessage(appFieldsMessages.metric)}
                          </div>
                        </div>
                        <div className="full-width flex-row">
                          <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                            {intl.formatMessage(eventMessages.shortDescription)}:
                          </div>
                          {metricDirection && (
                            <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                              {EventRenderers.RenderMetricAnomalySummary({ intl, event: sourceDetail })}
                            </div>
                          )}
                        </div>
                        {!metricDirection && (
                          <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                            {!rawDataJson &&
                              R.join(
                                '\n',
                                R.filter((x) => Boolean(x), (content || '').split('\n')),
                              )}
                            {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                          </div>
                        )}
                      </div>
                    );
                  }, rule?.sourceDetailSet || [])}
                </div>
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
};

export default RenderIncidentKnowledgeRule;
