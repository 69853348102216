/* @flow */
/* eslint-disable no-console */
import type { JobState, Action } from '../types';

const initialState: JobState = {
  errorMessage: null,
};

const reducer = (state: JobState = initialState, action: Action): JobState => {
  if (action.type === 'SET_JOB_ANALYSIS') {
    const { jobAnalysis } = action.payload.data || {};
    return {
      ...state,
      jobAnalysis,
    };
  }
  return { ...initialState, ...state };
};

export default reducer;
