import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';

import { State } from '../../../common/types';
import { Modal } from '../../../../artui/react';
import { CellRenderers } from '../../../common/utils';
import { Container, Table, Column, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';

type Props = {
  intl: Object,
  stages: Array<Object>,
  onClose: Function,
};

class JobStagesModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.convertData(props, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.stages !== nextProps.stages) {
      this.convertData(nextProps, {});
    }
  }

  @autobind
  convertData(props, params = {}) {
    const { stages } = props;
    this.stagesList = [];
    R.forEach((s) => {
      const { name, ...rest } = s;
      this.stagesList.push({
        name,
        ...rest,
      });
    }, stages);
    this.stagesList = R.sortBy(R.prop('stageId'), this.stagesList);
  }

  @autobind
  contentRenderer(props) {
    const { dataKey, parent, rowIndex } = props;
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        test
      </CellMeasurer>
    );
  }
  @autobind
  handleClose() {
    this.props.onClose();
  }

  render() {
    const { intl } = this.props;
    const width = 1100;
    return (
      <Modal closable size="huge" onClose={this.handleClose}>
        <div style={{ padding: '0 10px' }}>
          <Container className="toolbar" style={{ width: 200 }}>
            <Container className="section">
              <h4>Stages</h4>
            </Container>
            <Container className="section float-right" />
          </Container>
          <div className="flex-row" style={{ marginTop: 10, paddingBottom: 8, height: 600, width: '100%' }}>
            <Table
              className="with-border"
              width={width}
              height={560}
              headerHeight={50}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={40}
              rowCount={this.stagesList.length}
              rowGetter={({ index }) => this.stagesList[index]}
              ref={(c) => {
                this.dataTable = c;
              }}
            >
              <Column width={100} label="Stage id" dataKey="stageId" />
              <Column
                width={150}
                label="ExecutorRunTime"
                dataKey="executorRunTime"
                cellRenderer={({ cellData }) => CellRenderers.humanizeDuration({ period: cellData, intl })}
              />
              <Column width={100} label="NumTasks" dataKey="numTasks" />
              <Column width={150} label="NumCompleteTasks" dataKey="numCompleteTasks" />
              <Column width={150} label="NumFailedTasks" dataKey="numFailedTasks" />
              <Column width={150} label="NumActiveTasks" dataKey="numActiveTasks" />
              <Column width={100} label="InputBytes" dataKey="inputBytes" />
              <Column width={100} label="OutputBytes" dataKey="outputBytes" />
            </Table>
          </div>
        </div>
      </Modal>
    );
  }
}

const JobStagesModal = injectIntl(JobStagesModalCore);
export default connect((state: State) => ({}), {})(JobStagesModal);
