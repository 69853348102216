import React from 'react';
import * as R from 'ramda';
import { Button, InputNumber, message, Select, Checkbox, Spin, Divider } from 'antd';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Popover,
  Table,
} from '../../../../lib/fui/react';
import { appMessages, appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  width: Number,
  height: Number,

  intl: Object,
  projectName: String,
  credentials: Object,
  refreshTime: Number,
  // eslint-disable-next-line
  instanceDisplayNameMap: Object,
  // eslint-disable-next-line
  currentProject: Object,
  isDark: Boolean,
};
class SystemCapacitySetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {
      metricNameList: [],
      loading: false,
      provisionData: [],
      instanceListOption: [],
      searchValue: '',
    };
    this.ProvisionMetricOptions = [
      { label: 'CPU', value: 'CPU' },
      { label: 'MEM', value: 'MEM' },
      { label: 'Storage', value: 'Storage' },
    ];
    this.modeList = [
      { label: 'Usage', value: 0 },
      { label: 'Availability', value: 1 },
    ];
    this.dataTableNode = null;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 70,
    });
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials, projectName: projectNameSet } = props;
    const [projectName, customerName] = projectNameSet.split('@');
    this.setState({
      loading: true,
    });
    Promise.all([
      fetchGet(getEndpoint('metricprovisionsetting', 1), {
        ...credentials,
        projectName,
        customerName,
      })
        .then((res) => res)
        .catch((e) => []),
      fetchGet(getEndpoint('metricmetadata', 1), { ...credentials, projectName, customerName })
        .then((res) => res)
        .catch((e) => ({})),
    ])
      .then((res) => {
        const metricProvisionSettingList = res[0] || [];
        let { possibleMetricList = [], instanceNameList = [] } = res[1] || {};
        possibleMetricList = R.map((item) => ({ label: item, value: item }), possibleMetricList);

        const instanceListOption = R.map(
          (item) => ({ label: item, value: item }),
          R.sort((a, b) => R.toString(a).localeCompare(R.toString(b)), R.uniq(instanceNameList)),
        );

        const provisionData = [];
        R.addIndex(R.forEach)((item, index) => {
          const prevSetting = R.find((n) => n.p === item.value, metricProvisionSettingList);

          const { p, t, u, c, ic, e, m } = prevSetting || {
            p: item.value,
            t: possibleMetricList[0].value,
            u: false,
            c: 0,
            ic: {},
            e: false,
            m: 0,
          };

          provisionData.push({
            p,
            t,
            u,
            c: u ? c : '',
            e,
            m,
            isRoot: true,
          });

          const levelCapKeysGroup = R.pipe(
            R.toPairs,
            R.reduceBy(
              (acc, [key]) => R.append(key, acc || []),
              [],
              ([_, value]) => value,
            ),
          )(ic || {});
          R.forEachObjIndexed((values, key) => {
            provisionData.push({
              isRoot: false,
              instanceNameList: values,
              levelCapCalue: Number(key),
              level: item.value,
            });
          }, levelCapKeysGroup || {});
        }, this.ProvisionMetricOptions);

        this.setState({
          metricNameList: possibleMetricList,
          loading: false,
          instanceListOption,
          provisionData,
        });
        this.cellMeasureCache.clearAll();
        if (this.dataTableNode) this.dataTableNode.forceUpdateGrid();
        this.forceUpdate();
      })
      .catch((e) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          loading: false,
        });
      });
  }

  @autobind
  async handleSaveClick() {
    const { intl, projectName: projectNameSet, credentials } = this.props;
    const { provisionData } = this.state;

    let isRootList = [];
    let otherList = [];
    R.forEach((item) => {
      if (item.isRoot) {
        isRootList = [...isRootList, item];
      } else if (item.instanceNameList.length > 0) {
        otherList = [...otherList, item];
      }
    }, provisionData || []);

    const otherGroup = R.groupBy((item) => item.level, otherList || []);

    isRootList = R.map((value) => {
      const { p, t, u, c, e, m } = value || {};
      if (!u) {
        return { p, t, u, e, m };
      } else {
        const icMap = {};
        const filterVal = R.filter((_item) => _item.levelCapCalue !== c, otherGroup[p] || []);
        R.forEach((item) => {
          R.forEach((i) => {
            icMap[i] = item.levelCapCalue;
          }, item.instanceNameList || []);
        }, filterVal || []);
        return { p, t, u, c, ic: icMap, e, m };
      }
    }, isRootList || []);

    const [projectName, customerName] = projectNameSet.split('@');

    this.setState({ loading: true });

    fetchPost(getEndpoint('metricprovisionsetting', 1), {
      ...credentials,
      projectName,
      customerName,
      metricProvisionSettingSet: JSON.stringify(isRootList),
    })
      .then((res) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData(this.props);
      })
      .catch((e) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          loading: false,
        });
      });
  }

  @autobind
  cellRowRender({ cellData, rowData }) {
    const { isRoot } = rowData;
    if (!isRoot) {
      return <div />;
    }
    return (
      <Popover title={null} content={cellData} mouseEnterDelay={0.3}>
        <span className="hidden-line-with-ellipsis clickable" style={{ wordWrap: 'break-word' }}>
          {cellData}
        </span>
      </Popover>
    );
  }

  @autobind
  cellSelectRender(options, { cellData, dataKey, rowData }) {
    const { isRoot } = rowData;
    if (!isRoot) {
      return <div />;
    }
    return (
      <Select
        style={{ width: '100%' }}
        size="small"
        showSearch
        filterOption
        options={options}
        onChange={(value) => {
          rowData[dataKey] = value;
          this.cellMeasureCache.clearAll();
          if (this.dataTableNode) this.dataTableNode.forceUpdate();
          this.forceUpdate();
        }}
        value={cellData}
        dropdownMatchSelectWidth={false}
      />
    );
  }

  @autobind
  cellCheckboxRender({ cellData, rowData, dataKey }) {
    const { isRoot } = rowData;
    if (!isRoot) {
      return <div />;
    }
    return (
      <Checkbox
        checked={cellData}
        onChange={(e) => {
          rowData[dataKey] = e?.target?.checked;
          this.cellMeasureCache.clearAll();
          if (this.dataTableNode) this.dataTableNode.forceUpdate();
          this.forceUpdate();
        }}
      />
    );
  }

  @autobind
  cellInputNumberRender({ cellData, rowData, dataKey, rowIndex }) {
    const { provisionData } = this.state;
    const { u, isRoot } = rowData;
    if (dataKey === 'levelCapCalue' && isRoot) {
      return <div />;
    }

    if (dataKey !== 'levelCapCalue' && !isRoot) {
      return <div />;
    }

    let disabled = !u;
    if (dataKey === 'levelCapCalue') {
      let parentIndex = rowIndex - 1;
      for (let i = rowIndex - 1; i >= 0; i -= 1) {
        if (provisionData[i].isRoot) {
          parentIndex = i;
          break;
        }
      }
      disabled = !provisionData[parentIndex]?.u;
    }

    return (
      <InputNumber
        size="small"
        onChange={(e) => {
          rowData[dataKey] = e;
          this.cellMeasureCache.clearAll();
          if (this.dataTableNode) this.dataTableNode.forceUpdate();
          this.forceUpdate();
        }}
        value={cellData}
        disabled={disabled}
        min={1}
        style={{ width: '100%' }}
        className={!disabled && dataKey !== 'levelCapCalue' && !cellData ? 'inputIsNil' : ''}
      />
    );
  }

  @autobind
  cellConfigRender({ rowData }) {
    const { intl } = this.props;
    const { isRoot } = rowData;
    if (!isRoot) {
      return <div />;
    }
    return (
      <div className="flex-row flex-center-align flex-end-justify">
        <Button size="small">{intl.formatMessage(appButtonsMessages.update)}</Button>
      </div>
    );
  }

  @autobind
  handleClickAdd({ rowIndex, rowData }) {
    const { provisionData } = this.state;
    const newRowData = {
      isRoot: false,
      instanceNameList: [],
      levelCapCalue: undefined,
      level: rowData.p,
    };
    const [before, after] = R.splitAt(rowIndex + 1, provisionData);
    const newProvisionData = R.concat(before, [newRowData, ...after]);
    this.setState({ provisionData: newProvisionData });
    this.cellMeasureCache.clearAll();
    if (this.dataTableNode) this.dataTableNode.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleClickDelete({ rowIndex }) {
    const { provisionData } = this.state;
    const newProvisionData = R.remove(rowIndex, 1, provisionData);
    this.setState({ provisionData: newProvisionData });
    this.forceUpdate();
    setTimeout(() => {
      this.cellMeasureCache.clearAll();
      if (this.table) this.table.forceUpdateGrid();
    }, 0);
  }

  @autobind
  getListOptionValues(option) {
    return R.map((item) => item.value, option);
  }

  @autobind
  getFilterValue({ searchValue, options, type = 'label' }) {
    return R.filter(
      (item) => (item[type] || '').toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1,
      options,
    );
  }

  @autobind
  isCheckedAll({ searchValue, options, valueList }) {
    const filterValue = this.getListOptionValues(this.getFilterValue({ searchValue, options }));
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  }

  @autobind
  cellInstanceNameRender({ rowData, dataKey, parent, rowIndex, cellData = [] }) {
    const { intl } = this.props;
    const { instanceListOption, searchValue } = this.state;
    const { isRoot } = rowData;
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div className="flex-row flex-center-align" style={{ padding: '8px 0px 8px 8px' }}>
          {isRoot && (
            <>
              <span>Global</span>
              <Button size="small" style={{ marginLeft: 6 }} onClick={() => this.handleClickAdd({ rowIndex, rowData })}>
                Add
              </Button>
            </>
          )}
          {!isRoot && (
            <div className="flex-row flex-center-align full-width">
              <Select
                className="no-count-num"
                style={{ flex: 1, minWidth: 0 }}
                allowClear
                showSearch
                mode="multiple"
                value={cellData}
                autoClearSearchValue={false}
                optionFilterProp="children"
                dropdownMatchSelectWidth={450}
                onChange={(value) => {
                  rowData[dataKey] = value;
                  this.cellMeasureCache.clearAll();
                  if (this.dataTableNode) this.dataTableNode.forceUpdate();
                  this.forceUpdate();
                }}
                onDropdownVisibleChange={(open) => {
                  if (!open) {
                    this.setState({ searchValue: '' });
                  }
                }}
                dropdownRender={(menu) => {
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={this.isCheckedAll({ searchValue, options: instanceListOption, valueList: cellData })}
                          onChange={(e) => {
                            const { checked } = e.target;
                            const filterValue = this.getListOptionValues(
                              this.getFilterValue({ searchValue, options: instanceListOption }),
                            );
                            const diff = R.difference(cellData, filterValue);
                            rowData[dataKey] = checked ? [...diff, ...filterValue] : diff;
                            this.cellMeasureCache.clearAll();
                            if (this.dataTableNode) this.dataTableNode.forceUpdate();
                            this.forceUpdate();
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
                onSearch={(searchValue) => this.setState({ searchValue })}
              >
                {R.map((item) => {
                  return (
                    <Select.Option className="hide-icon" key={item.value} value={item.value}>
                      <Checkbox
                        checked={cellData.includes(item.value)}
                        onChange={(e) => null}
                        style={{ marginRight: 8 }}
                      />
                      {item.label}
                    </Select.Option>
                  );
                }, instanceListOption)}
              </Select>
              <Button
                size="small"
                style={{ marginLeft: 8, flexShrink: 0 }}
                onClick={() => this.handleClickDelete({ rowIndex })}
              >
                {intl.formatMessage(appButtonsMessages.delete)}
              </Button>
            </div>
          )}
        </div>
      </CellMeasurer>
    );
  }

  render() {
    const { intl, width, height, isDark } = this.props;
    const { loading, metricNameList, provisionData } = this.state;
    const isRootList = R.filter((item) => item.isRoot, provisionData || []);
    const capValueError = !!R.find((item) => item.u && !item.c, isRootList || []);
    const hasError = capValueError;
    return (
      <Container style={{ width, height, padding: '8px 0' }} className="flex-col  overflow-x-auto" fullHeight>
        <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-height">
          <Container className="flex-grow field$" style={{ padding: '8px 0' }}>
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  ref={(c) => {
                    this.dataTableNode = c;
                  }}
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={60}
                  rowCount={provisionData.length}
                  rowGetter={({ index }) => provisionData[index]}
                  deferredMeasurementCache={this.cellMeasureCache}
                  rowHeight={this.cellMeasureCache.rowHeight}
                  rowStyle={({ index }) => {
                    if (provisionData[index]?.isRoot) {
                      return { backgroundColor: isDark ? '#353535' : '#e7e7e7' };
                    }
                    return {};
                  }}
                >
                  <Column
                    width={100}
                    flexShrink={0}
                    label="Provision metric"
                    dataKey="p"
                    cellRenderer={this.cellRowRender}
                  />
                  <Column
                    width={170}
                    flexShrink={0}
                    label="Metric name"
                    dataKey="t"
                    cellRenderer={(props) => this.cellSelectRender(metricNameList, props)}
                  />
                  <Column
                    width={100}
                    flexGrow={1}
                    label="Instance name"
                    dataKey="instanceNameList"
                    cellRenderer={this.cellInstanceNameRender}
                  />
                  <Column
                    width={150}
                    flexShrink={0}
                    label="Instance level cap value"
                    dataKey="levelCapCalue"
                    cellRenderer={this.cellInputNumberRender}
                  />
                  <Column
                    width={100}
                    flexShrink={0}
                    label="Use cap"
                    className="text-center"
                    headerClassName="text-center"
                    dataKey="u"
                    cellRenderer={this.cellCheckboxRender}
                  />
                  <Column
                    width={100}
                    flexShrink={0}
                    label="Cap value"
                    dataKey="c"
                    cellRenderer={this.cellInputNumberRender}
                  />
                  <Column
                    width={100}
                    flexShrink={0}
                    label="Enable"
                    className="text-center"
                    headerClassName="text-center"
                    dataKey="e"
                    cellRenderer={this.cellCheckboxRender}
                  />
                  <Column
                    width={130}
                    flexShrink={0}
                    label="Mode"
                    dataKey="m"
                    cellRenderer={(props) => this.cellSelectRender(this.modeList, props)}
                  />
                  {/* <Column width={100} label={null} dataKey="config" cellRenderer={this.cellConfigRender} /> */}
                </Table>
              )}
            </AutoSizer>
          </Container>
          <div className="flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button type="primary" disabled={hasError} loading={loading} onClick={this.handleSaveClick}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </div>
        </Spin>
      </Container>
    );
  }
}

export default connect((state: Object) => {
  const { credentials } = state.auth;
  const { projects, currentTheme } = state.app;
  const { isAdmin } = state.auth.userInfo;
  const isDark = currentTheme === 'dark';
  return { credentials, isAdmin, projects, isDark };
}, {})(SystemCapacitySetting);
