import React, { useState, useEffect, useCallback } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import { isNumber, isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import {
  LoadingOutlined,
  SmileOutlined,
  ArrowRightOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { Button, Form, Select, Spin, Alert, Tabs, Result, Popover } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, LogRenderers, sleep } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo, loadProjectInfo } from '../../../common/app/actions';
import { Modal, AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

type Props = {
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  // eslint-disable-next-line
  compareIncidents: Array<Object>,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  loadProjectInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  projects: Array<Object>,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  currentTheme: String,
};

class CompareCommonLogsModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl, userInfo, systemId, projects, compareIncidents } = props;
    let systemProjectList = R.filter((project) => project.systemId === systemId, projects || []);
    systemProjectList = R.filter((p) => !p.isMetric, systemProjectList);

    // set default info from incidents
    let projectName = null;
    let logInstances = [];
    let metricInstances = [];
    const logIncidents = R.filter((item) => item.category === 'log', compareIncidents || []);
    const metricIncidents = R.filter((item) => item.category === 'metric', compareIncidents || []);
    if (logIncidents.length > 0) {
      const { projectName: incidentProject, projectOwner } = logIncidents[0];
      projectName = projectOwner !== userInfo.userName ? `${incidentProject}@${projectOwner}` : incidentProject;
      logInstances = R.map((item) => item.anomalyLogInstance, logIncidents);
      logInstances = R.uniq(logInstances);
    }
    if (metricIncidents.length > 0) {
      metricInstances = R.map((item) => item.realInstanceName, metricIncidents);
      metricInstances = R.uniq(metricInstances);
    }

    this.state = {
      current: 0,

      // query
      projectName,
      instanceList: logInstances,
      logInstances,
      metricInstances,
      timeWindow: 30000,
      isLoadingInstance: false,

      // task status
      isSubmiting: false,
      errMsg: null,
      taskId: null,
      query: [],
      taskStatus: null,
      taskFinished: false,

      // show common logs
      isLoading: false,
      eventListMap: {},
      eventListDifferentMap: {},
      activeKey: 'common',
      activeKeyCommon: null,
      activeKeyDifferent: null,
    };
    this.projectListOptions = R.map(
      (item) => ({ value: item.projectName, label: item.projectDisplayName }),
      systemProjectList,
    );
    this.instanceListOptions = [];
    this.timeListOptions = [
      { value: 30000, label: `30 ${intl.formatMessage(appFieldsMessages.seconds)}` },
      { value: 60000, label: `1 ${intl.formatMessage(appFieldsMessages.minute)}` },
      { value: 5 * 60000, label: `5 ${intl.formatMessage(appFieldsMessages.minutes)}` },
      { value: 10 * 60000, label: `10 ${intl.formatMessage(appFieldsMessages.minutes)}` },
    ];
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projects !== this.props.projects) {
      this.parseData(nextProps);
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};

    if (this.timerCheckTaskStatus) {
      clearInterval(this.timerCheckTaskStatus);
    }
  }

  @autobind
  reloadInstance() {
    const { loadProjectInfo } = this.props;
    const { projectName } = this.state;

    if (projectName) {
      this.setState({ isLoadingInstance: true });
      loadProjectInfo(
        {
          projectName,
          includeInstance: true,
        },
        false,
      );
    }
  }

  @autobind
  async parseData(props) {
    const { credentials, projects } = props;
    const { projectName, logInstances, metricInstances } = this.state;

    // reset instance
    const currentProject = R.find((project) => project.projectName === projectName, projects || []);
    const allInstanceList = currentProject ? currentProject.instanceList || [] : [];

    let instanceList = logInstances;
    // find log instances from metric instancs
    if (metricInstances.length > 0) {
      const log2metricMap = await fetchGet(getEndpoint('metric2loginstancemap'), {
        ...credentials,
        projectName,
        metricInstances: JSON.stringify(metricInstances),
      });
      instanceList = [...instanceList, ...R.values(log2metricMap || {})];
    }
    // only pick instances in allInstanceList
    instanceList = R.intersection(instanceList, allInstanceList);

    this.instanceListOptions = R.map((i) => ({ value: i, label: i }), allInstanceList || []);
    this.setState({ isLoadingInstance: false, instanceList });
  }

  @autobind
  async handleQueryClick() {
    const { credentials, compareIncidents } = this.props;
    const { projectName, instanceList, timeWindow } = this.state;
    this.setState({ isSubmiting: true });

    await sleep(300);

    const query = [];
    R.forEach((incident) => {
      const { incidentTimestamp } = incident;
      R.forEach((instanceName) => {
        query.push({
          instanceName,
          timeWindow: { s: incidentTimestamp - timeWindow, e: incidentTimestamp },
        });
      }, instanceList);
    }, compareIncidents || []);

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('logcommonevent'), {
      ...credentials,
      projectName,
      query: JSON.stringify(query),
    })
      .then((data) => {
        const { success, message: errMsg, taskId } = data || {};
        if (success === undefined || success) {
          this.setState({ isSubmiting: false, errMsg: null, taskId, query, current: 1 }, () => {
            this.timerCheckTaskStatus = setInterval(() => {
              this.handleCheckTaskStatus();
            }, 2 * 1000);
          });
        } else {
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        this.setState({ isSubmiting: false, errMsg: err.message || String(err) });
      });
  }

  @autobind
  handleShowCommonLogsClick() {
    this.setState({ current: 2 }, () => {
      this.handleGetCommonLogs();
    });
  }

  @autobind
  handleCheckTaskStatus() {
    const { credentials } = this.props;
    const { projectName, taskId } = this.state;
    fetchGet(getEndpoint('logcommonevent'), {
      ...credentials,
      projectName,
      operation: 'status',
      taskId,
    })
      .then((data) => {
        const { status } = data[0] || {};
        const taskFinished = status === 'finished';
        this.setState({ taskStatus: status, taskFinished }, () => {
          if (taskFinished && this.timerCheckTaskStatus) {
            clearInterval(this.timerCheckTaskStatus);
            this.handleShowCommonLogsClick();
          }
        });
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  }

  @autobind
  handleGetCommonLogs() {
    const { credentials } = this.props;
    const { projectName, taskId, query } = this.state;
    this.setState({ isLoading: true });

    const requests = R.map((item) => {
      return fetchGet(getEndpoint('logcommonevent'), {
        ...credentials,
        projectName,
        operation: 'detail',
        taskId,
        query: JSON.stringify([item]),
      });
    }, query || []);
    this.props.updateLastActionInfo();
    Promise.all(requests)
      .then((results) => {
        const eventListMap = {};
        const eventListDifferentMap = {};

        R.addIndex(R.forEach)((result, idx) => {
          const quesyItem = (query || [])[idx];
          const { instanceName, timeWindow } = quesyItem || {};
          const { s, e } = timeWindow || {};
          const key = `${instanceName}-${s}-${e}`;

          R.forEach((item) => {
            const { isCommon, events } = item;
            if (isCommon) {
              R.forEach((log) => {
                if (!R.has(key, eventListMap)) {
                  eventListMap[key] = { instanceName, s, e, eventList: [] };
                }
                eventListMap[key].eventList.push(log);
              }, events);
            } else {
              R.forEach((log) => {
                if (!R.has(key, eventListDifferentMap)) {
                  eventListDifferentMap[key] = { instanceName, s, e, eventList: [] };
                }
                eventListDifferentMap[key].eventList.push(log);
              }, events);
            }
          }, result || []);
        }, results);

        // set default active key
        let { activeKey } = this.state;
        if (R.isEmpty(eventListMap) && !R.isEmpty(eventListDifferentMap)) {
          activeKey = 'different';
        }
        const activeKeyCommon = R.keys(eventListMap)[0];
        const activeKeyDifferent = R.keys(eventListDifferentMap)[0];

        this.setState({
          isLoading: false,
          eventListMap,
          eventListDifferentMap,
          activeKey,
          activeKeyCommon,
          activeKeyDifferent,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          eventListMap: {},
          eventListDifferentMap: {},
          activeKeyCommon: null,
          activeKeyDifferent: null,
        });
      });
  }

  @autobind
  renderQueryConfiguration() {
    const { intl } = this.props;
    const { projectName, instanceList, timeWindow, isLoadingInstance, isSubmiting, errMsg } = this.state;
    return (
      <div className="full-width full-height overflow-y-auto">
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left">
          {errMsg && <Alert message={errMsg} type="error" showIcon style={{ marginBottom: 16 }} />}

          <Form.Item
            label={intl.formatMessage(appFieldsMessages.project)}
            validateStatus={!projectName ? 'error' : 'success'}
            help={!projectName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Select
              showSearch
              filterOption
              options={this.projectListOptions}
              value={projectName}
              onChange={(projectName) =>
                this.setState({ projectName, instanceList: [] }, () => {
                  this.reloadInstance();
                })
              }
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
              disabled={isSubmiting}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(DashboardMessages.instances)}
            validateStatus={instanceList.length === 0 ? 'error' : 'success'}
            help={instanceList.length === 0 ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Spin spinning={isLoadingInstance}>
              <Select
                showSearch
                mode="tags"
                filterOption
                options={this.instanceListOptions}
                value={instanceList}
                onChange={(instanceList) => this.setState({ instanceList: instanceList || [] })}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
                disabled={isSubmiting}
              />
            </Spin>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(logMessages.lookBackTimeWindow)}
            validateStatus={!timeWindow ? 'error' : 'success'}
            help={!timeWindow ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Select
              allowClear={false}
              showSearch
              filterOption
              options={this.timeListOptions}
              value={timeWindow}
              onChange={(timeWindow) => this.setState({ timeWindow })}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
              disabled={isSubmiting}
            />
          </Form.Item>
        </Form>
      </div>
    );
  }

  @autobind
  renderTaskStatus() {
    const { intl } = this.props;
    const { errMsg, taskFinished, taskStatus } = this.state;
    return (
      <div className="full-width full-height flex-col">
        {errMsg && <Alert message={errMsg} type="error" showIcon style={{ marginBottom: 16 }} />}

        <div className="flex-grow flex-min-height flex-row flex-center-align flex-center-justify">
          <Result
            icon={taskFinished ? <SmileOutlined /> : <LoadingOutlined />}
            title={taskFinished ? 'Great, we have done all the results!' : taskStatus || 'Loading...'}
            extra={
              <Button size="small" style={{ width: 80 }} onClick={this.handleCheckTaskStatus}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            }
          />
        </div>
      </div>
    );
  }

  @autobind
  renderShowCommonLogs() {
    const { intl, currentTheme } = this.props;
    const { timeWindow, isSubmiting, errMsg } = this.state;
    // eslint-disable-next-line no-unused-vars
    const { isLoading, activeKey, eventListMap, eventListDifferentMap, activeKeyCommon, activeKeyDifferent } =
      this.state;

    return (
      <div className="full-width full-height flex-col">
        {errMsg && <Alert message={errMsg} type="error" showIcon style={{ marginBottom: 16 }} />}

        <div className="flex-grow flex-min-height flex-row flex-center-align flex-center-justify">
          <Spin spinning={isLoading} wrapperClassName="spin-full-height full-width full-height">
            <Tabs
              size="small"
              type="card"
              className="full-height ant-tabs-content-full-height flex-col"
              activeKey={activeKey}
              onChange={(activeKey) => this.setState({ activeKey })}
              tabBarExtraContent={
                <div className="full-height flex-row flex-center-align flex-end-justify">
                  <div className="bold" style={{ marginRight: 8 }}>
                    {intl.formatMessage(logMessages.lookBackTimeWindow)}
                  </div>
                  <Select
                    size="small"
                    allowClear={false}
                    showSearch
                    filterOption
                    options={this.timeListOptions}
                    value={timeWindow}
                    onChange={(timeWindow) =>
                      this.setState(
                        { timeWindow, taskId: null, query: [], taskStatus: null, taskFinished: false },
                        () => {
                          this.handleQueryClick();
                        },
                      )
                    }
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                    loading={isSubmiting}
                  />
                  <Button size="small" style={{ marginLeft: 16, width: 80 }} onClick={this.handleGetCommonLogs}>
                    {intl.formatMessage(appButtonsMessages.refresh)}
                  </Button>
                </div>
              }
            >
              <Tabs.TabPane tab="Common logs" key="common" style={{ paddingTop: 8 }}>
                {!R.isEmpty(eventListMap) && (
                  <Tabs
                    className="full-height ant-tabs-content-full-height ant-tabs-bar-full-width"
                    activeKey={activeKeyCommon}
                    onChange={(activeKeyCommon) => this.setState({ activeKeyCommon })}
                    tabPosition="left"
                    tabBarStyle={{ width: 200 }}
                  >
                    {R.map(([key, data]) => {
                      const { instanceName, s, e, eventList } = data || {};
                      return (
                        <Tabs.TabPane
                          key={key}
                          tab={
                            <Popover placement="right" mouseEnterDelay={0.3} content={instanceName}>
                              <div>
                                <div className="hidden-line-with-ellipsis inline-block max-width">{instanceName}</div>
                                <div className="light-label">{`${moment
                                  .utc(s)
                                  .format(Defaults.ShortTimeOnlyFormat)} - ${moment
                                  .utc(e)
                                  .format(Defaults.ShortTimeOnlyFormat)}`}</div>
                              </div>
                            </Popover>
                          }
                        >
                          <CommonLogsRenderer intl={intl} eventList={eventList} currentTheme={currentTheme} />
                        </Tabs.TabPane>
                      );
                    }, R.toPairs(eventListMap))}
                  </Tabs>
                )}

                {R.isEmpty(eventListMap) && <Alert message="No common logs." type="info" showIcon />}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Different logs" key="different" style={{ paddingTop: 8 }}>
                {!R.isEmpty(eventListDifferentMap) && (
                  <Tabs
                    className="full-height ant-tabs-content-full-height ant-tabs-bar-full-width"
                    activeKey={activeKeyDifferent}
                    onChange={(activeKeyDifferent) => this.setState({ activeKeyDifferent })}
                    tabPosition="left"
                    tabBarStyle={{ width: 200 }}
                  >
                    {R.map(([key, data]) => {
                      const { instanceName, s, e, eventList } = data || {};
                      return (
                        <Tabs.TabPane
                          key={key}
                          tab={
                            <Popover placement="right" mouseEnterDelay={0.3} content={instanceName}>
                              <div>
                                <div className="hidden-line-with-ellipsis inline-block max-width">{instanceName}</div>
                                <div className="light-label">{`${moment
                                  .utc(s)
                                  .format(Defaults.ShortTimeOnlyFormat)} - ${moment
                                  .utc(e)
                                  .format(Defaults.ShortTimeOnlyFormat)}`}</div>
                              </div>
                            </Popover>
                          }
                        >
                          <CommonLogsRenderer intl={intl} eventList={eventList} currentTheme={currentTheme} />
                        </Tabs.TabPane>
                      );
                    }, R.toPairs(eventListDifferentMap))}
                  </Tabs>
                )}

                {R.isEmpty(eventListDifferentMap) && <Alert message="No different logs." type="info" showIcon />}
              </Tabs.TabPane>
            </Tabs>
          </Spin>
        </div>
      </div>
    );
  }

  render() {
    const { intl, onClose } = this.props;
    const { current } = this.state;
    const { projectName, instanceList, isSubmiting } = this.state;
    const { taskFinished } = this.state;

    const hasErrQuery = current !== 0 || !projectName || instanceList.length === 0;
    const hasErrShowCommonLogs = current !== 1 || !taskFinished;
    return (
      <Modal
        visible
        title={intl.formatMessage(logMessages.compareCommonLogs)}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={1024}
        bodyStyle={{ height: 560 }}
        footer={null}
      >
        <div className="full-width full-height flex-col">
          <div className="flex-grow flex-min-height">
            {current === 0 && this.renderQueryConfiguration()}
            {current === 1 && this.renderTaskStatus()}
            {current === 2 && this.renderShowCommonLogs()}
          </div>

          <div className="flex-row flex-end-justify" style={{ marginTop: 16 }}>
            {current === 0 && (
              <Button
                size="small"
                type="primary"
                disabled={hasErrQuery || isSubmiting}
                loading={isSubmiting}
                onClick={this.handleQueryClick}
                ghost
              >
                {intl.formatMessage(appButtonsMessages.next)} <ArrowRightOutlined />
              </Button>
            )}
            {current === 1 && (
              <Button
                size="small"
                type="primary"
                disabled={hasErrShowCommonLogs}
                onClick={this.handleShowCommonLogsClick}
                ghost
              >
                {intl.formatMessage(appButtonsMessages.next)} <ArrowRightOutlined />
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const CommonLogsRenderer = ({ intl, eventList, showInstance = false, currentTheme }: Object) => {
  const [refList, setRef] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [cellMeasureCache] = useState(new CellMeasurerCache({ fixedWidth: true, minHeight: 40 }));
  const [listNodeHeaderScrollbar, setScrollbar] = useState(false);
  const [localEventList, setLocalEventList] = useState(eventList || []);
  const [localUpdateRowIndex, setLocalUpdateRowIndex] = useState(null);
  const [localUpdateRowIndexRefresh, setLocalUpdateRowIndexRefresh] = useState(null);

  const sortData = useCallback((eventList, sortBy, sortDirection) => {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('ts'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);

    return sortList;
  }, []);

  useEffect(() => {
    let localEventList = eventList;
    localEventList = sortData(localEventList, sortBy, sortDirection);
    setLocalEventList(localEventList);
  }, [eventList]);

  useEffect(() => {
    if (isNumber(localUpdateRowIndex)) cellMeasureCache.clear(localUpdateRowIndex);
    if (refList) refList.forceUpdateGrid();
  }, [localUpdateRowIndexRefresh]);

  const handleExpandRow = useCallback(
    (rowIndex) => {
      return (isExpand) => {
        setLocalEventList(
          update(localEventList, {
            [rowIndex]: { $set: { ...(localEventList[rowIndex] || {}), isExpand } },
          }),
        );
        setLocalUpdateRowIndex(rowIndex);
        setLocalUpdateRowIndexRefresh(moment.utc().valueOf());
      };
    },
    [localEventList],
  );

  const headerClick = useCallback(
    (name) => {
      return (e) => {
        e.stopPropagation();
        let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
        if (name !== sortBy) {
          sortDir = 'ASC';
        }
        if (name) {
          setSortBy(name);
          setSortDirection(sortDir);
          setLocalEventList(sortData(localEventList, name, sortDir));
          setLocalUpdateRowIndex(null);
          setLocalUpdateRowIndexRefresh(moment.utc().valueOf());
        }
      };
    },
    [sortDirection, localEventList],
  );

  const sortIcon = useCallback((sortBy, sortDirection, name) => {
    if (sortBy !== name || sortDirection === 'NA') {
      return <i className="sort icon" style={{ fontSize: 14, color: '#ffffff' }} />;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }, []);

  const renderListItem = (localEventList) => {
    return ({ key, index: rowIndex, style, parent }: Object) => {
      const rowData = localEventList[rowIndex];
      if (!rowData) return null;

      const { ts, i, pid, d: rawData, isExpand } = rowData;
      let rawDataJson;
      try {
        rawDataJson = JSON.parse(rawData);
        if (!isObject(rawDataJson)) rawDataJson = undefined;
      } catch (error) {
        // console.debug(error);
      }

      return (
        <CellMeasurer key={key} cache={cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
            <div className="row-column" style={{ width: 140 }}>
              {moment.utc(ts).format(Defaults.DateTimeFormat)}
            </div>
            <div className="row-column" style={{ width: 80 }}>
              {pid}
            </div>
            {showInstance && (
              <div className="row-column" style={{ width: 140 }}>
                {i}
              </div>
            )}
            <div className="row-column " style={{ width: 120, flex: 1 }}>
              {rawDataJson && (
                <LogRenderers.ExpandLogJSONContent
                  intl={intl}
                  rawData={rawData}
                  rawDataJson={rawDataJson}
                  currentTheme={currentTheme}
                />
              )}
              {!rawDataJson && (
                <LogRenderers.ExpandLogContent
                  intl={intl}
                  rawData={rawData}
                  rawDataJson={rawDataJson}
                  isExpand={isExpand}
                  onExpand={handleExpandRow(rowIndex)}
                />
              )}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  };

  return (
    <div className="full-width full-height">
      <AutoSizer>
        {({ width, height }) => (
          <div className="event-list">
            <div
              className="event-list-header"
              style={{ height: 30, width, paddingRight: listNodeHeaderScrollbar ? 17 : 0 }}
            >
              <div className="header-column" style={{ width: 140 }} onClick={headerClick('ts')}>
                {intl.formatMessage(appFieldsMessages.time)}
                {sortIcon(sortBy, sortDirection, 'ts')}
              </div>
              <div className="header-column" style={{ width: 80 }} onClick={headerClick('pid')}>
                {intl.formatMessage(appFieldsMessages.pattern)}
                {sortIcon(sortBy, sortDirection, 'pid')}
              </div>
              {showInstance && (
                <div className="header-column" style={{ width: 140 }} onClick={headerClick('i')}>
                  {intl.formatMessage(appFieldsMessages.instance)}
                  {sortIcon(sortBy, sortDirection, 'i')}
                </div>
              )}
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(eventMessages.shortDescription)}
              </div>
            </div>
            <List
              ref={(ref) => setRef(ref)}
              className="event-list-grid"
              width={width}
              height={height - 30}
              rowCount={localEventList.length}
              overscanRowCount={4}
              deferredMeasurementCache={cellMeasureCache}
              rowHeight={cellMeasureCache.rowHeight}
              rowRenderer={renderListItem(localEventList)}
              onScrollbarPresenceChange={({ horizontal, vertical }) => {
                if (vertical) {
                  setScrollbar(true);
                } else {
                  setScrollbar(false);
                }
              }}
            />
          </div>
        )}
      </AutoSizer>
    </div>
  );
};

const CompareCommonLogsModal = injectIntl(CompareCommonLogsModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, projectDisplayMap, systemsMap, projects, currentTheme } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      currentLocale,
      projectDisplayMap,
      systemsMap,
      projects,
      userInfo,
      credentials,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo, loadProjectInfo },
)(CompareCommonLogsModal);
