/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import { isNumber, isString } from 'lodash';

export { autobind } from 'core-decorators';
export const noop = () => {};
export const isDefined = (d) => d !== null && typeof d !== 'undefined';

export const mousePosition = (e, defaultRect) => {
  const container = e.currentTarget;
  const rect = defaultRect || container.getBoundingClientRect();
  const x = e.clientX - rect.left - container.clientLeft;
  const y = e.clientY - rect.top - container.clientTop;
  return [Math.round(x), Math.round(y)];
};

export const defaultComparer = (a, b) => {
  if (isNumber(a) && isNumber(b)) {
    return a - b;
  }

  if (isString(a) && isString(b)) {
    return a.localeCompare(b);
  }

  if (isNumber(a) || isNumber(b)) {
    a = isNumber(a) ? a : parseFloat(a);
    a = isNumber(a) ? a : -Infinity;
    b = isNumber(b) ? b : parseFloat(b);
    b = isNumber(b) ? b : -Infinity;
    return a - b;
  }

  // String
  return (a || '').localeCompare(b || '');
};

export const AscendingComparer = defaultComparer;
