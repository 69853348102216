import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Select } from 'antd';

import type { State } from '../../../common/types';
import { setCurrentLocale } from '../../../common/app/actions';

const LocaleSelector = ({ currentLocale, setCurrentLocale, localeOptions, disabled, ...rest }: Object) => (
  <div className="locale-selector" {...rest}>
    <i className="translate icon" />
    <Select
      showSearch
      style={{ width: 100 }}
      optionFilterProp="value"
      filterOption
      options={localeOptions}
      value={currentLocale}
      onChange={(currentLocale) => setCurrentLocale(currentLocale)}
      disabled={Boolean(disabled)}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ maxWidth: 650 }}
    />
  </div>
);

export default connect(
  (state: State) => ({
    currentLocale: state.app.currentLocale,
    localeOptions: R.map(
      (localeData) => ({ value: localeData[0], label: localeData[1] }),
      R.toPairs(state.app.locales),
    ),
  }),
  { setCurrentLocale },
)(LocaleSelector);
