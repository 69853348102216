/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';
import { parseJSON } from '../utils';

const MAX_FATCH_NUM = 1000;

const postProjectMetricAllSettings = (credentials: Credentials, params: Object) => {
  const { viewCategory, projectName, instanceId, owner, metricList, patternIdGenerationRule } = params;
  if (viewCategory !== 'metricSettings') {
    return fetchPost(getEndpoint('projects/cpupdate'), {
      ...credentials,
      projectName: projectName.indexOf('@') >= 0 ? projectName : `${projectName}@${owner}`,
      appName: instanceId,
      projectSettings: metricList ? JSON.stringify(metricList) : null,
    }).then((d) => {
      const { success, excludedMetrics } = d || {};
      return { success, excludedMetrics: parseJSON(excludedMetrics) || [] };
    });
  } else if (metricList.length <= MAX_FATCH_NUM) {
    return fetchPost(getEndpoint('projects/componentmetricupdate'), {
      ...credentials,
      projectName: projectName.indexOf('@') >= 0 ? projectName : `${projectName}@${owner}`,
      appName: instanceId,
      projectSettings: metricList ? JSON.stringify(metricList) : null,
      patternIdGenerationRule,
    }).then((d) => {
      const { success, excludedMetrics } = d || {};
      return { success, excludedMetrics: parseJSON(excludedMetrics) || [] };
    });
  } else {
    const splitEveryTakes = R.splitEvery(MAX_FATCH_NUM, metricList);
    const requests = [];
    R.forEach((groupM) => {
      if (groupM.length > 0) {
        requests.push(
          fetchPost(getEndpoint('projects/componentmetricupdate'), {
            ...credentials,
            projectName: projectName.indexOf('@') >= 0 ? projectName : `${projectName}@${owner}`,
            appName: instanceId,
            projectSettings: groupM ? JSON.stringify(groupM) : null,
            patternIdGenerationRule,
          }),
        );
      }
    }, splitEveryTakes || []);
    return Promise.all(requests).then((d) => {
      let newExcludedMetrics = [];
      R.forEach((item) => {
        const { success, excludedMetrics } = item || {};
        if (success) {
          newExcludedMetrics = [...newExcludedMetrics, ...(excludedMetrics ? parseJSON(excludedMetrics) : [])];
        }
      }, d || []);
      return { success: true, excludedMetrics: newExcludedMetrics };
    });
  }
};

export default postProjectMetricAllSettings;
