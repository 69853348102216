import React from 'react';
import { Breadcrumb, Button, Select, message } from 'antd';
import * as R from 'ramda';
import momenttz from 'moment-timezone';
import { HomeOutlined, PlusOutlined } from '@ant-design/icons';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import moment from 'moment';
import { connect } from 'react-redux';

import './style/index.less';
import { Container, Popover } from '../../lib/fui/react';
import { Defaults, buildLocation, buildUrl, parseJSON, parseLocation } from '../../common/utils';
import { appMenusMessages, appMessages } from '../../common/app/messages';
import { hideAppLoader } from '../../common/app/actions';
import BaseUrls from '../app/BaseUrls';
import LayoutConfigures from './components/LayoutConfigures';
import DashboardCreation from './components/DashboardCreation';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import { eventMessages } from '../../common/metric/messages';

type Props = {
  intl: Object,
  hideAppLoader: Function,
  location: Object,
  push: Function,
  globalInfo: Array,
  userInfo: Object,
  customerName: String,
  userList: Array,
  systemsMap: Object,
  credentials: Object,
  timezoneOffset: number,
  isReadUser: Boolean,
};

const Active = {
  color: 'var(--yellow)',
  borderColor: 'var(--yellow)',
  fontWeight: 'bold',
};
const DefaultButton = {
  color: 'var(--text-color)',
  borderColor: 'var(--border-color)',
};

class DashboardCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      showCreateDashboardModal: false,
      adminOrganization: {},
      organizationName: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.hideAppLoader();
    this.reloadOrganization(this.props);
  }

  @autobind
  reloadOrganization(props) {
    const { intl, credentials, userInfo, location, replace, userList } = props;
    const query = parseLocation(location);
    const { customerName } = query;
    const { isAdmin, isLocalAdmin, companyName, userName } = userInfo;
    const { company: currentUserConpany } =
      R.find((item) => item.userName === (customerName || userName), userList) || {};

    const currentCompanyName = query?.organizationName || currentUserConpany || companyName;

    this.setState({ isLoading: true });
    if (isAdmin || isLocalAdmin) {
      fetchGet(getEndpoint('getallorganizations'), {
        ...credentials,
      })
        .then((res = {}) => {
          if (isLocalAdmin) {
            if (!res[companyName]) {
              res[companyName] = [userName];
            }
          }

          this.setState({
            adminOrganization: res,
            organizationName: currentCompanyName,
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    } else {
      this.setState({ organizationName: currentCompanyName, isLoading: false });
    }
    if (currentCompanyName !== query?.organizationName) {
      replace(buildLocation(location.pathname, {}, { ...query, organizationName: currentCompanyName }));
    }
  }

  // TODO Optimization is needed here @YueYong
  @autobind
  async onShowLayoutDetail(data, isCreate) {
    const { location, push, credentials } = this.props;
    const { organizationName } = this.state;
    const query = parseLocation(location);

    let timezoneOffset = 0;
    if (data?.systemFramework?.timezone) {
      const zone = momenttz.tz(data?.systemFramework?.timezone);
      timezoneOffset = zone.utcOffset();
    }

    const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
    const startTime = moment.utc(nowTimestamp).subtract(6, 'days').format(Defaults.DateFormat);
    const endTime = moment.utc(nowTimestamp).format(Defaults.DateFormat);

    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

    const { systemId, customerName, dashBoardName, systemFramework } = data;

    this.setState({ isLoading: true });
    const basicInfo = await fetchGet(getEndpoint('system-basic-info', 1), {
      ...credentials,
      startTime: startTimestamp,
      endTime: endTimestamp,
      systemKeyList: JSON.stringify([
        { id: systemFramework.systemKey.systemName, customerName: systemFramework.systemKey.userName },
      ]),
      customerName,
    })
      .then((data) => {
        this.setState({ isLoading: false });
        return data || {};
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        return {};
      });

    const systemInfo = {
      ...systemFramework,
      environmentName: systemFramework.systemKey.environmentName,
      id: systemFramework.systemKey.systemName,
      systemId: systemFramework.systemKey.systemName,
      ownerUserName: systemFramework.systemKey.userName,
      owner: systemFramework.systemKey.userName,
      name: systemFramework.systemDisplayName,
      projectDetailsList: JSON.parse(systemFramework.projectDetailsList || '[]'),
    };

    R.forEachObjIndexed((val, key) => {
      const systemKey = parseJSON(key) || {};
      const { id } = systemKey || {};
      let hasData = false;
      let hasCPSetting = false;
      let hasCost = false;
      R.forEach((b) => {
        const j = parseJSON(b?.basicInfo) || {};
        if (!hasData && j.hasData) {
          hasData = true;
        }
        hasCPSetting = hasCPSetting || j.hasCPSetting;
        hasCost = hasCost || j.hasCost;
      }, val);

      if (id === systemInfo.id) {
        systemInfo.systemInfo = { showInGV: true, hasData, hasCPSetting, hasCost };
      }
    }, basicInfo);
    sessionStorage.setItem('dashboardSystemFramework', JSON.stringify(systemInfo));

    if (query.customerName && query.customerName !== 'undefined' && query.customerName !== undefined) {
      sessionStorage.setItem('dashboardCustomerName', query.customerName);
    }
    this.setState({ isLoading: false });
    push(
      buildUrl(
        BaseUrls.GlobalSystemInsights,
        {},
        { ...query, systemId, isCreate, customerName, dashBoardName, organizationName, startTime, endTime },
      ),
    );
  }

  @autobind
  handleOrganizationChange(organizationName) {
    this.setState({
      organizationName,
    });
    const { location } = this.props;
    const query = parseLocation(location);

    replace(buildLocation(location.pathname, {}, { ...query, organizationName }));
  }

  @autobind
  handleNewDashboard() {
    this.setState({
      showCreateDashboardModal: true,
    });
  }

  @autobind
  onClose() {
    this.setState({
      showCreateDashboardModal: false,
    });
  }

  render() {
    const { intl, location, globalInfo, userInfo, isReadUser } = this.props;
    const { showCreateDashboardModal, adminOrganization, organizationName, isLoading } = this.state;
    const query = parseLocation(location);
    const { environmentId, systemId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);

    const systemInfo = R.find((system) => system.id === systemId, systemList);

    const props = {
      ...this.props,
      systemInfo,
      ...query,
    };
    const { isAdmin, isLocalAdmin } = userInfo;
    const userListOfOrganization = isAdmin || isLocalAdmin ? adminOrganization[organizationName] : [];

    return (
      <Container fullHeight withGutter className="flex-col flex-min-height">
        <Container breadcrumb className="flex-row">
          <div className="flex-grow flex-row flex-center-align">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, {}))}>
                  <HomeOutlined />
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(appMenusMessages.globalSystemInsights)}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="flex-row flex-center-align" style={{ marginLeft: 15 }}>
            {(isAdmin || isLocalAdmin) && (
              <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.accountGroup)}:</div>
            )}
            {(isAdmin || isLocalAdmin) && (
              <div>
                <Select
                  showSearch
                  size="small"
                  value={organizationName}
                  style={{ width: 200, marginRight: 10 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.handleOrganizationChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                  loading={isLoading}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ),
                    R.sort(
                      (organizationA, organizationB) => organizationA.localeCompare(organizationB),
                      R.keys(adminOrganization) || [],
                    ),
                  )}
                </Select>
              </div>
            )}

            <Popover
              content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
            >
              <Button disabled={isReadUser} onClick={this.handleNewDashboard} type="primary" size="small">
                <div className="flex-row flex-center-align">
                  <PlusOutlined style={{ fontSize: 16 }} />
                  New dashboard
                </div>
              </Button>
            </Popover>
          </div>
        </Container>
        <Container
          className="flex-grow flex-col flex-min-width flex-min-height content-bg corner-10"
          style={{ margin: '0 16px 8px 16px', padding: 8 }}
        >
          <LayoutConfigures
            {...props}
            isLoading={isLoading}
            onShowLayoutDetail={this.onShowLayoutDetail}
            organizationName={organizationName}
          />
        </Container>

        {showCreateDashboardModal && (
          <DashboardCreation
            {...props}
            isLoading={isLoading}
            onClose={this.onClose}
            onShowLayoutDetail={this.onShowLayoutDetail}
            organizationName={organizationName}
            userListOfOrganization={userListOfOrganization}
          />
        )}
      </Container>
    );
  }
}

const Dashboard = injectIntl(DashboardCore);
export default connect(
  (state: Object) => {
    const { location } = state.router;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isReadUser, userName } = state.auth.userInfo;
    const {
      loadStatus,
      globalInfo,
      allSystemConfigs,
      allProjects,
      currentTheme,
      systemsMap,
      favorites,
      timezoneOffset,
    } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { globalHealthSummary, globalHealthByInstance, globalSystemIncidentTimelines } = state.dashboard;
    return {
      userInfo,
      loadStatus,
      credentials,
      globalInfo,
      isAdmin,
      isReadUser,
      userName,
      location,
      userList,
      globalHealthSummary,
      globalHealthByInstance,
      globalSystemIncidentTimelines,
      allSystemConfigs,
      allProjects,
      currentTheme,
      systemsMap,
      favorites,
      timezoneOffset,
    };
  },
  { push, replace, hideAppLoader },
)(Dashboard);
