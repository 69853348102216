import React, { useEffect, useReducer, useRef, useState } from 'react';
import * as R from 'ramda';
import { get, isEqual } from 'lodash';

import { ConnectionMode, MarkerType, ReactFlow, useEdgesState, useNodesState } from '../../../lib/reactflow/core';
import { Controls } from '../../../lib/reactflow/controls';
import { EventRenderers } from '../../../common/utils';

import PredictionFlowCustomNode from './PredictionFlowCustomNode';

const nodeWidth = 200;
const nodeHeight = 105;
const ranksep = 200;
const nodesep = 40;
const gapTime = 5 * 60 * 1000;

const nodeTypes = {
  custom: PredictionFlowCustomNode,
};

export default function PredictionFlow({
  intl,
  currentTheme,
  event,
  projects,
  detectedIncident,
  historicalIncident,
  predictionSourceInfoList,
  environmentId,
  globalInfo,
  systemId,
  summarySettings,
  incidentPatternName,
  allIncidentList,
  onLineChartClick,
  onLineChartJump,
  onPredictionJumpLogClick,
  onPredictionSourceLineChartClick,
  onPredictionSourceJumpClick,
  onPredictionSourceTrendPatternsClick,
  onPredictionSourceLogQueryClick,
  renderDetectedIncident,
  isJWT,
}: Object) {
  const [forceUpdateData, forceUpdate] = useReducer((x) => x + 1, 0);
  const [prevEvent, setPrevEvent] = useState(event);
  const [prevSourceInfoList, setPrevSourceInfoList] = useState(predictionSourceInfoList);
  const [prevAllIncidentList, setPrevAllIncidentList] = useState(allIncidentList);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), { activeNode: null });
  const { activeNode } = state;

  const environment = R.find((e) => e.id === environmentId, globalInfo || []);
  const systemList = get(environment, 'systemList', []);
  const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

  const flowRef = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onNodeClick = (event, node) => {
    if (node) {
      const { isGroup } = node;
      if (!isGroup) {
        setState({ activeNode: node });

        const nodeId = node.id;
        const { edgesMap = {} } = state;

        const allTargets = { [nodeId]: true };

        const findTargets = (s) => {
          R.forEach((t) => {
            if (!allTargets[t]) {
              allTargets[t] = true;
              findTargets(t);
            }
          }, R.keys(edgesMap[s] || {}));
        };

        findTargets(nodeId);

        setTimeout(() => {
          setNodes(
            R.map((n) => {
              if (n.id === node.id) {
                return { ...n, selected: true };
              } else {
                return { ...n, selected: false };
              }
            }, nodes),
          );
          setEdges(
            R.map((e) => {
              const { source } = e?.data || {};
              const sourceNodes = source?.nodes ? source.nodes : [source];
              const sourceNode = R.find((n) => allTargets[n.id], sourceNodes);
              const darkColor = sourceNode ? '#ccc' : '#555';
              const lightColor = sourceNode ? '#f1d9d6' : '#b1b1b7';
              return {
                ...e,
                selected: sourceNode,
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                  color: currentTheme === 'dark' ? darkColor : lightColor,
                },
              };
            }, edges),
          );
        }, 0);
      }
    }
  };

  const processEvents = (event, predictionSourceInfoList) => {
    event = { ...event, isRoot: true };
    const incidentNode = {
      id: event.id,
      type: 'custom',
      isIncident: true,
      data: {
        label: event.instanceName,
        own: event,
        environmentId,
        summarySettings,
        event,
        incidentPatternName,
        predictionSourceInfoList,
        onLineChartClick,
        onLineChartJump,
        onPredictionJumpLogClick,
        onPredictionSourceLineChartClick,
        onPredictionSourceJumpClick,
        onPredictionSourceTrendPatternsClick,
        onPredictionSourceLogQueryClick,
        instanceDisplayNameMap,
        isJWT,
      },
      position: {},
    };
    predictionSourceInfoList = R.sortWith([R.ascend(R.prop('predictionTime'))], predictionSourceInfoList);

    const nodesMap = {};
    // First, merge the events with the same instanceName and eventTimestamp into one node,
    // Meanwhile, build the edges between the nodes
    R.forEach((item) => {
      const sourceInfo = item || {};
      const { sourceInstanceName, predictionTime } = sourceInfo || {};
      const instName = sourceInstanceName;
      const key = `${instName}__${predictionTime}`;
      item.key = key;

      if (!nodesMap[key]) {
        nodesMap[key] = {
          id: key,
          type: 'custom',
          time: predictionTime,
          instanceName: instName,
          position: { x: 0, y: 0 },
          data: {
            label: key,
            event,
            own: sourceInfo,
            ownList: [sourceInfo],
            environmentId,
            summarySettings,
            incidentPatternName,
            predictionSourceInfoList,
            onLineChartClick,
            onLineChartJump,
            onPredictionJumpLogClick,
            onPredictionSourceLineChartClick,
            onPredictionSourceJumpClick,
            onPredictionSourceTrendPatternsClick,
            onPredictionSourceLogQueryClick,
            instanceDisplayNameMap,
            isJWT,
          },
        };
      } else {
        nodesMap[key].data.ownList.push(sourceInfo);
      }
    }, predictionSourceInfoList || []);

    // Next, split the nodes into groups based on the time gap and instance name
    const allEventsNodes = R.sortWith([R.ascend(R.prop('time'))], R.values(nodesMap));

    const allNodeRows = [];
    const instanceGroups = {};
    if (allEventsNodes.length > 0) {
      let startTs = null;
      let ridx = 0;

      R.forEach((n) => {
        const { instanceName, time: eventTimestamp } = n;
        if (!startTs) {
          startTs = eventTimestamp;
        }

        if (eventTimestamp - startTs > gapTime) {
          startTs = eventTimestamp;
          ridx += 1;
        }

        const row = allNodeRows[ridx];
        const gid = `${instanceName}_${ridx}`;
        let newGroup = null;

        if (row) {
          const group = R.find((g) => g.instanceName === instanceName, row?.groups || []);
          if (group) {
            group.endTime = eventTimestamp;
            group.nodes.push(n);
          } else {
            newGroup = { id: gid, startTime: eventTimestamp, endTime: eventTimestamp, nodes: [n], instanceName };
            row.groups.push(newGroup);
          }
          row.totalCount += 1;
        } else {
          newGroup = { id: gid, startTime: eventTimestamp, endTime: eventTimestamp, nodes: [n], instanceName };
          allNodeRows.push({
            totalCount: 1,
            groups: [newGroup],
          });
        }

        if (newGroup) {
          if (instanceGroups[instanceName]) {
            instanceGroups[instanceName].push(newGroup);
          } else {
            instanceGroups[instanceName] = [newGroup];
          }
        }
      }, allEventsNodes);

      // For groups in row by end time
      R.forEach((row) => {
        const { groups = [] } = row;
        row.groups = R.sortWith([R.ascend(R.prop('endTime'))], groups);
      }, allNodeRows);
    }

    // Mark the first node in first row as active
    let firstNode = null;
    if (allNodeRows.length > 0) {
      const { nodes: groupNodes = [] } = allNodeRows[0]?.groups?.[0] || {};
      if (groupNodes.length > 0) {
        firstNode = groupNodes[0];
        firstNode.selected = true;
      }
    }

    // Append the incident node to the first row if it's trailing events, otherwise, append it to the last row
    const incidentRow = {
      totalCount: 1,
      groups: [{ id: incidentNode?.id, nodes: [incidentNode] }],
    };
    allNodeRows.push(incidentRow);
    const maxNodeCount = R.reduce(
      R.max,
      -Infinity,
      R.map((r) => r.totalCount, allNodeRows),
    );

    // Adjust position and style for the nodes
    const nodes = [];
    const maxWidth = maxNodeCount * (nodeWidth + nodesep) - nodesep;

    if (allNodeRows.length > 0) {
      let ridx = 0;
      R.forEach((row) => {
        let rowridx = 0;
        R.forEach((group) => {
          const y = (ridx + rowridx) * ranksep;
          const { id: groupId, nodes: groupNodes = [] } = group;
          const count = groupNodes.length;
          const offset = (maxNodeCount - count) / 2;
          const padding = 16;

          if (groupNodes.length === 1) {
            // groupNodes[0].position.x = offset * (nodeWidth + nodesep) - maxWidth / 2;
            groupNodes[0].position.x = 0;
            groupNodes[0].position.y = y;
            nodes.push(groupNodes[0]);
          } else if (groupNodes.length > 1) {
            nodes.push({
              id: groupId,
              data: { label: null },
              isGroup: true,
              // position: { x: offset * (nodeWidth + nodesep) - maxWidth / 2, y },
              position: { x: 0, y },
              style: {
                // width: count * (nodeWidth + nodesep) - nodesep,
                width: nodeWidth + nodesep - nodesep,
                height: nodeHeight + padding,
                borderRadius: 8,
                backgroundColor: 'transparent',
                // border: groupNodes.length > 1 ? '2px dashed var(--virtualized-table-border-color)' : '2px dashed transparent',
                border: '2px dashed transparent',
              },
            });

            let cidx = 0;
            R.addIndex(R.forEach)((n, i) => {
              // n.position.x = cidx * (nodeWidth + nodesep);
              n.position.x = 0;
              n.position.y = 0 + padding / 2;
              n.parentNode = groupId;
              n.zIndex = groupNodes.length - i;
              n.data.groups = row?.groups || [];
              nodes.push(n);
              cidx += 1;
            }, groupNodes);
          }
          rowridx += 1;
        }, row?.groups || []);
        ridx += rowridx;
      }, allNodeRows);
    }

    // edges
    const edges = [];
    let source = null;
    let sourceKey = null;
    R.forEach((row) => {
      R.forEach((group) => {
        const { nodes: groupNodes = [], id } = group;
        if (!sourceKey) {
          sourceKey = id;
          source = group;
          if (groupNodes.length === 1) {
            sourceKey = groupNodes[0]?.id;
            source = groupNodes[0];
          }
        } else {
          let target = group;
          let targetKey = id;
          if (groupNodes.length === 1) {
            target = groupNodes[0];
            targetKey = groupNodes[0]?.id;
          }
          const edgeId = `${sourceKey}->${targetKey}`;
          edges.push({
            id: edgeId,
            source: sourceKey,
            target: targetKey,
            data: { source, target },
            style: { strokeWidth: 2.4 },
            markerEnd: { type: MarkerType.ArrowClosed },
          });
          source = target;
          sourceKey = targetKey;
        }
      }, row?.groups || []);
    }, allNodeRows || []);

    return { nodes, edges, firstNode };
  };

  if (!isEqual(prevEvent, event) && !isEqual(prevSourceInfoList, predictionSourceInfoList)) {
    setPrevEvent(event);
    setPrevSourceInfoList(predictionSourceInfoList);
  }

  if (!isEqual(prevAllIncidentList, allIncidentList)) {
    setPrevAllIncidentList(allIncidentList);
  }

  useEffect(() => {
    if (!prevEvent || prevSourceInfoList.length < 1) return;

    const { nodes, edges, firstNode } = processEvents(prevEvent, prevSourceInfoList);
    setNodes(nodes);

    const edgesMap = {};
    R.forEach((e) => {
      const { source, target } = e?.data || {};

      const sourceNodes = source?.nodes ? [...source.nodes, source] : [source];
      const targetNodes = target?.nodes ? [...target.nodes, target] : [target];

      R.forEach((s) => {
        const skey = s.id;
        R.forEach((t) => {
          const tkey = t.id;
          if (!edgesMap[skey]) {
            edgesMap[skey] = {};
          }
          edgesMap[skey][tkey] = true;
        }, targetNodes);
      }, sourceNodes);
    }, edges);

    const nodeId = firstNode?.id;
    const allTargets = { [nodeId]: true };

    const findTargets = (s) => {
      R.forEach((t) => {
        if (!allTargets[t]) {
          allTargets[t] = true;
          findTargets(t);
        }
      }, R.keys(edgesMap[s] || {}));
    };

    findTargets(nodeId);

    setEdges(
      R.map((e) => {
        const { source } = e?.data || {};
        const sourceNodes = source?.nodes ? source.nodes : [source];
        const sourceNode = R.find((n) => allTargets[n.id], sourceNodes);
        const darkColor = sourceNode ? '#ccc' : '#555';
        const lightColor = sourceNode ? '#f1d9d6' : '#b1b1b7';

        return {
          ...e,
          selected: sourceNode,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: currentTheme === 'dark' ? darkColor : lightColor,
          },
        };
      }, edges),
    );

    setState({ activeNode: firstNode, edgesMap });
    forceUpdate();
  }, [prevEvent, prevSourceInfoList, prevAllIncidentList, currentTheme]);

  useEffect(() => {
    if (flowRef.current) {
      setTimeout(() => {
        flowRef.current.fitView();
      }, 200);
    }
  }, [forceUpdateData]);

  const onInit = (reactFlowInstance) => {
    flowRef.current = reactFlowInstance;
  };

  return (
    <div className="flex-grow overflow-hidden flex-row">
      <div
        className="corner-8"
        style={{
          flex: 3,
          marginRight: 12,
          padding: '12px 4px',
          border: '1px solid var(--virtualized-table-border-color)',
        }}
      >
        <div className="full-width full-height rca-flow-container">
          <ReactFlow
            fitView
            maxZoom={0.9}
            minZoom={0.2}
            nodes={nodes}
            edges={edges}
            onInit={onInit}
            nodeTypes={nodeTypes}
            selectionKeyCode={false}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            proOptions={{ hideAttribution: true }}
            connectionMode={ConnectionMode.Loose}
            defaultMarkerColor="var(--react-flow-edge-path-color)"
            onNodeClick={onNodeClick}
            onPaneClick={() => onNodeClick(undefined, activeNode)}
            defaultEdgeOptions={{
              style: { strokeWidth: 2.4 },
              type: 'smoothstep',
              markerEnd: { type: MarkerType.ArrowClosed, color: currentTheme === 'dark' ? '#555' : '#b1b1b7' },
            }}
          >
            <Controls showInteractive={false} position="top-right" />
          </ReactFlow>
        </div>
      </div>
      <div
        className="corner-8"
        style={{
          flex: 5,
          padding: '12px 4px',
          border: '1px solid var(--virtualized-table-border-color)',
        }}
      >
        <EventRenderers.RenderPredictionTimelines
          currentTheme={currentTheme}
          intl={intl}
          projects={projects || []}
          metricUnitMap={{}}
          predictionSourceInfoList={predictionSourceInfoList}
          event={event}
          incidentPatternName={incidentPatternName}
          summarySettings={summarySettings}
          detectedIncident={detectedIncident}
          historicalIncident={historicalIncident}
          showDetected
          onLineChartClick={onLineChartClick}
          onLineChartJump={onLineChartJump}
          onPredictionSourceLineChartClick={onPredictionSourceLineChartClick}
          onPredictionSourceJumpClick={onPredictionSourceJumpClick}
          onPredictionSourceTrendPatternsClick={onPredictionSourceTrendPatternsClick}
          onPredictionSourceLogQueryClick={onPredictionSourceLogQueryClick}
          onPredictionJumpLogClick={onPredictionJumpLogClick}
          renderDetectedIncident={renderDetectedIncident}
          scroll="hidden auto"
          activeNode={activeNode}
          hideDetectedIncident
          instanceDisplayNameMap={instanceDisplayNameMap}
          isJWT={isJWT}
        />
      </div>
    </div>
  );
}
