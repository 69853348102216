import { autobind } from 'core-decorators';
import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Card } from 'antd';

import { State } from '../../../src/common/types';
import { Modal } from '../../../src/lib/fui/react';
import DetectionChart from './DetectionChart';

type Props = {
  title: String,
  onClose: Function,
  data: Array,
  highlight: Object,
  anomalyList: Array,
  onCreateAnnotation: Function,
  dateWindow: Array,
};
class SeparateInstanceModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { dateWindow } = props;
    this.state = { instanceMap: null, dateWindow };
    this.chartHeight = 280;
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  @autobind
  parseData(props) {
    const { data, anomalyList, labels, highlight } = props;

    const anomalyClusterMap = R.groupBy((item) => `${item.m}[${item.i}]`)(anomalyList);

    const newInstanceMap = {};
    R.forEach((item) => {
      R.forEach((label) => {
        if (label === 'timestamp') return;
        if (!newInstanceMap[label]) {
          newInstanceMap[label] = {
            data: [],
            labels: ['timestamp'],
            instance: label,
            highlight: { [label]: highlight[label] },
            anomalyList: anomalyClusterMap[label],
          };
        }

        newInstanceMap[label].data.push({ timestamp: item.timestamp, [label]: item[label] });
        if (!newInstanceMap[label].labels.includes(label)) {
          newInstanceMap[label].labels.push(label);
        }
      }, labels);
    }, data);

    this.setState({ instanceMap: newInstanceMap });
  }

  @autobind
  onDateWindowChange(dateWindow) {
    this.setState({ dateWindow });
  }

  render() {
    const { title, onClose, onCreateAnnotation } = this.props;
    const { instanceMap, dateWindow } = this.state;

    return (
      <Modal
        title={title}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        footer={null}
        width={1200}
        bodyStyle={{ height: 700, overflowY: 'auto', overflowX: 'hidden', background: 'var(--body-background)' }}
      >
        {instanceMap &&
          R.map((item) => {
            const { labels, data, instance, highlight, anomalyList } = item;
            return (
              <Card
                key={instance}
                style={{
                  height: this.chartHeight,
                  width: '100%',
                  overflow: 'hidden',
                  marginBottom: 20,
                }}
                bordered={false}
                hoverable
                bodyStyle={{ padding: 10 }}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                <div style={{ marginBottom: 20 }}>{instance}</div>
                <DetectionChart
                  data={data}
                  anomalyList={anomalyList}
                  labels={labels}
                  highlight={highlight}
                  width="100%"
                  height={this.chartHeight - 40}
                  onCreateAnnotation={onCreateAnnotation}
                  dateWindow={dateWindow}
                  onDateWindowChange={this.onDateWindowChange}
                />
              </Card>
            );
          }, R.values(instanceMap))}
      </Modal>
    );
  }
}

export default connect((state: State) => {
  const { currentTheme } = state.app;
  const { location } = state.router;
  const isDark = currentTheme === 'dark';

  return { isDark, location };
})(SeparateInstanceModalCore);
