import React from 'react';

import * as R from 'ramda';
import moment from 'moment';
import { get, isFinite } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import {
  BellOutlined,
  SearchOutlined,
  RightSquareOutlined,
  SettingOutlined,
  CloseCircleOutlined,
  MailOutlined,
  ExportOutlined,
  FormOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Tooltip,
  Empty,
  Select,
  Input,
  Spin,
  Alert,
  Button,
  notification,
  message,
  Switch,
  Tag,
  DatePicker,
  Popconfirm,
  Tabs,
  Checkbox,
  Card,
  Menu,
  Progress,
} from 'antd';

import {
  Defaults,
  buildLocation,
  parseLocation,
  EventRenderers,
  GlobalRenderers,
  buildUrl,
  parseJSON,
  CellRenderers,
  Regex,
  sleep,
  downloadFile,
  CausalParser,
  ExportModal,
  timeScopeControl,
  downloadPdf,
} from '../../../common/utils';
import { State } from '../../../common/types';
import {
  Modal,
  Container,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer,
  Popover,
  Dropdown,
} from '../../../lib/fui/react';
import { KnowledgeBaseIcon, GoodIcon, GoodOutlinedIcon, RobotIcon, PDFIcon } from '../../../lib/fui/icons';
import { createLoadAction, updateLastActionInfo, ActionTypes as AppActionTypes } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/dashboard/actions';
import { BaseUrls } from '../../app/Constants';
import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { EChart } from '../../share';

import { appFieldsMessages, appButtonsMessages, appMessages, appMenusMessages } from '../../../common/app/messages';
import { eventMessages, eventActionMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

import RenderIncidentKnowledgeRule from './RenderIncidentKnowledgeRule';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import PublicBuildCizizen from './PublicBuildCizizen';
import fetchPut from '../../../common/apis/fetchPut';
import PatternConfigModal from './PatternConfigModal';
import PredictionRootCauseRCA from './PredictionRootCauseRCA';
import RecommendationsGPT from './RecommendationsGPT';
import RecommendationPredictionModal from './RecommendationPredictionModal';
import ConfigNotesPredictionModal from './ConfigNotesPredictionModal';
import {
  anomalyTypeOptions,
  checkboxParentRender,
  childrenBorder,
  customizeRestore,
  dateHeight,
  filterData,
  findJumpEvent,
  getChartOption,
  getChartTimeMap,
  getEventsByOccurrence,
  handleChartFinish,
  handleChartRestore,
  handleChartZoom,
  handleMergeCategoryDateClick,
  handleMergeCategoryParentClick,
  impactedRender,
  listHeaderHeight,
  occurrenceTime,
  onSystemChartClick,
  patternRenderer,
  positionEvent,
  reducerHandleEvents,
  rendererContent,
  rendererExpand,
  renderTopBarRender,
  rowMinHeight,
  setChartRef,
  statusChainRenderer,
  tableViewList,
  tidyEventList,
  timeRenderer,
} from '../utils/predictionTimeLine';

type Props = {
  refresh: Number,
  handleRefresh: Function,
  handleCustomerNameChange: Function,
  handleSystemIdChange: Function,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  push: Function,
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  userList: Array<Object>,
  userInfo: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  userName: String,
  credentials: Object,
  systemsMap: Object,
  projects: Array<Object>,
  projectDisplayMap: Object,

  globalInfo: Object,
  globalSystemInfo: Object,
  currentTheme: String,
  timezoneOffset: Number,
};

class GlobalSystemIncidentPredictionViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    const { location, systemsMap } = props;
    const query = parseLocation(location);
    const { startTime, endTime, systemId } = query;

    const hasKubernetes = !!R.find(
      (item) => R.includes('Kubernetes', item?.cloudType || ''),
      systemsMap[systemId]?.projectDetailsList || [],
    );

    this.notificationKey = 'notification-new-predicted-incident';
    this.dataLoader = 'dashboard_global_system_incident_prediction';
    this.statsLoader = 'dashboard_global_system_incident_prediction_stats';
    this.listHeaderHeight = listHeaderHeight;
    this.rowMinHeight = rowMinHeight;
    this.dateHeight = dateHeight;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: this.dateHeight,
    });
    this.breakAutoLoad = false;

    this.state = {
      startTimeObj: moment.utc(startTime, Defaults.DateFormat),
      endTimeObj: moment.utc(endTime, Defaults.DateFormat),
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      events: [],
      filterList: [],
      groupEventList: [],

      incidentKnowledgeRuleMap: {},
      incident: null,
      patternNameMap: {},
      activeEvent: undefined,

      activeKey: 'incident',
      showPatternConfigModel: false,

      isAutoReload: false,
      isLoaded: false,
      isLoadingParserData: false,

      showTakeLogActionModal: false,
      actionName: null,
      actionIncident: null,

      showSoftwareUpdateContextModal: false,

      summaryObj: {},

      allExpand: true,
      dateAllExpand: true,
      isShowPredictionSourceInfo: false,

      hasKubernetes,
      showFilterPanel: true,

      patternIdsFilter: undefined,
      componentsFilter: undefined,
      instancesFilter: undefined,
      podsFilter: undefined,
      containersFilter: undefined,
      chartOption: {},
      isResetDateZoom: true,
      zoomStartTime: undefined,
      zoomEndTime: undefined,

      predictionStatusClose: true,
      predictionCategoryClose: true,
      impactedComponentsClose: true,
      impactedInstancesClose: true,
      impactedPodsClose: true,
      impactedContainersClose: true,
      patternIdNameClose: true,
      sourceCategoryClose: true,

      showRecommendationsGPT: false,
      activeRecommendation: null,
      showRecommendationModal: false,
      allChecked: false,
      configNotesModal: false,

      hasTableData: false,

      filterLoading: false,
      predictionStatusFilter: undefined,
      typeFilter: undefined,
      componentFilter: undefined,
      instanceFilter: undefined,
      logicPodIDFilter: undefined,
      containerFilter: undefined,
      patternIdFilter: undefined,
      sourceFilter: undefined,
    };

    this.componentListOptions = [];
    this.instanceListOptions = [];
    this.podOptions = [];
    this.containerOption = [];
    this.anomalyTypeOptions = anomalyTypeOptions(intl);
    this.predictionSourceOptions = [];
    this.anomalyMetricOptions = [];
    this.aggregationIntervalOptions = [
      { value: '4', label: '4 hour' },
      { value: '8', label: '8 hour' },
      { value: '12', label: '12 hour' },
    ];
    this.patternIdFilterOptions = [];
    this.listNodeHeaderScrollbar = false;
    this.patternNameMap = {};

    this.systemChartRef = {};
    this.predictionStatusCountMap = {};

    this.statusOptions = [
      { value: 'created', label: 'Created', color: 'currentColor' },
      { value: 'in progress', label: 'Active', color: 'orange' },
      { value: 'closed', label: 'Closed', color: 'green' },
    ];
    this.statusColorMap = R.fromPairs(R.map((item) => [item.value, item.color], this.statusOptions));
    this.channelNameMap = {
      Teams: 'Teams channel',
      Slack: 'Slack channel',
    };
    this.summarySettingsMap = {};

    this.chartTimeMap = {};

    this.fatherEvent = undefined;
    this.childEvent = undefined;
  }

  componentDidMount() {
    // get predictedOccurrenceTime mapping before start
    this.setState({ isLoaded: true }, async () => {
      await this.handlePredictedOccurrenceTimeMapFromJump();
      await this.setQueryFilterParams(this.props);
      await this.getSummaryObj(this.props);
      this.reloadData(this.props, { needResetFilter: true });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (nextQuery.startTime !== query.startTime || nextQuery.endTime !== query.endTime) {
      this.setState({
        predictionStatusFilter: undefined,
        typeFilter: undefined,
        componentFilter: undefined,
        instanceFilter: undefined,
        logicPodIDFilter: undefined,
        containerFilter: undefined,
        patternIdFilter: undefined,
        sourceFilter: undefined,
      });
    }
    if (
      nextProps.refresh !== this.props.refresh ||
      nextQuery.refreshTime !== query.refreshTime ||
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.systemId !== query.systemId
    ) {
      if (nextQuery.systemId !== query.systemId) {
        const { systemsMap = {} } = nextProps;
        const { systemId } = nextQuery;
        this.setState({
          incident: null,
          hasKubernetes: !!R.find(
            (item) => R.includes('Kubernetes', item?.cloudType || ''),
            systemsMap[systemId]?.projectDetailsList || [],
          ),

          predictionStatusFilter: undefined,
          typeFilter: undefined,
          componentFilter: undefined,
          instanceFilter: undefined,
          logicPodIDFilter: undefined,
          containerFilter: undefined,
          patternIdFilter: undefined,
          sourceFilter: undefined,
        });
      }
      this.fatherEvent = null;
      this.childEvent = null;
      const isAutoReload = nextQuery.refreshTime !== query.refreshTime;
      if (!isAutoReload || !this.breakAutoLoad) {
        const needResetFilter = !isAutoReload;
        this.reloadData(nextProps, { isAutoReload, needResetFilter });
      }
    } else if (
      nextProps.globalSystemInfo !== this.props.globalSystemInfo ||
      nextQuery.reloadSystem !== query.reloadSystem
    ) {
      this.parseData(nextProps);
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  setQueryFilterParams(props) {
    const { location } = props;
    const { hasKubernetes } = this.state;
    const query = parseLocation(location);
    const { eventInstanceName } = query;
    if (eventInstanceName) {
      const isContainer = R.includes('_', eventInstanceName);
      let instanceFilter;
      let logicPodIDFilter;
      let containerFilter;
      if (hasKubernetes) {
        if (isContainer) {
          const [c, i] = R.split('_', eventInstanceName);
          logicPodIDFilter = i;
          containerFilter = c;
        } else {
          logicPodIDFilter = eventInstanceName;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (isContainer) {
          const [c, i] = R.split('_', eventInstanceName);
          instanceFilter = i;
          containerFilter = c;
        } else {
          instanceFilter = eventInstanceName;
        }
      }

      this.setState({ instanceFilter, logicPodIDFilter, containerFilter });
    }
  }

  @autobind
  loadSystemData() {
    const { location, isAdmin, userName, createLoadAction } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime, customerName, systemId, environmentId } = query;
    if (((isAdmin && customerName) || !isAdmin) && startTime && endTime && systemId && environmentId) {
      this.breakAutoLoad = true;
      createLoadAction(
        AppActionTypes.LOAD_INFO_SYSTEM,
        {
          startTime,
          endTime,
          customerName: isAdmin ? customerName : userName,
          systemName: systemId,
          environmentId,
          anomalyInstanceOnly: false,
        },
        false,
        true,
        this.callbackHandleUpdateReloadSystemTime,
      );
    }
  }

  @autobind
  callbackHandleUpdateReloadSystemTime() {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, reloadSystem: moment.utc().valueOf() }));
    this.breakAutoLoad = false;
  }

  @autobind
  reloadData(props, params) {
    const { createLoadAction, location, isAdmin, globalInfo } = props;
    const query = parseLocation(location);
    const { environmentId, startTime, endTime, customerName, systemId } = query;
    const { isAutoReload, needResetFilter } = params;

    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId) {
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo) {
        const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
        const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
        const startTimestamps = [];
        R.forEach((ts) => {
          const timestamp = ts * 86400000;
          startTimestamps.push(timestamp);
        }, R.range(startTimestamp / 86400000, endTimestamp / 86400000));

        const filterState = needResetFilter
          ? {
              componentFilter: undefined,
              typeFilter: undefined,
              sourceFilter: undefined,
              patternIdFilter: undefined,
              predictionStatusFilter: undefined,
              instanceFilter: undefined,
              logicPodIDFilter: undefined,
              containerFilter: undefined,

              incident: null,
            }
          : {};
        this.breakAutoLoad = true;
        this.setState({ isLoaded: true, isAutoReload, ...filterState }, () => {
          createLoadAction(
            ActionTypes.LOAD_INCIDENT_PREDICTION_TIMELINES,
            {
              isAutoReload,
              level: 'system',
              environmentId,
              customerName: systemInfo.ownerUserName,
              systemId,
              startTimestamps,
            },
            isAutoReload ? false : this.dataLoader,
            false,
            this.callbackSetState,
          );
        });
      }
    } else {
      this.setState({ isLoaded: false });
    }
  }

  @autobind
  callbackSetState() {
    this.breakAutoLoad = false;
    this.setState({ isLoaded: false });
  }

  @autobind
  async handlePredictedOccurrenceTimeMapFromJump() {
    const { intl, replace, location, credentials } = this.props;
    let { typeFilter } = this.state;
    const query = parseLocation(location);
    const {
      customerName,
      eventProjectName,
      eventInstanceName,
      eventPatternId,
      eventPredictionTime,
      eventTimestamp,
      eventOwner,
    } = query;
    let { startTime } = query;

    if (eventProjectName && eventInstanceName && eventPatternId && (eventPredictionTime || eventTimestamp)) {
      const projectName =
        customerName !== credentials.userName ? `${eventProjectName}@${customerName}` : eventProjectName;
      const data = await fetchGet(getEndpoint('kpiPredictionOccurrenceTimeMapping'), {
        ...credentials,
        projectName,
        instanceName: eventInstanceName,
        patternId: eventPatternId,
        predictionTime: eventPredictionTime || eventTimestamp,
        predictedTime: eventTimestamp,
        customerName: eventOwner || customerName,
      }).catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        return {};
      });
      const { success, predictedOccurrenceTime } = data || {};
      if (success && isFinite(predictedOccurrenceTime)) {
        startTime = moment.utc(predictedOccurrenceTime).format(Defaults.DateFormat);

        // set typeFilter if not current day
        if (!typeFilter && moment.utc().startOf('day') > moment.utc(startTime, Defaults.DateFormat)) {
          typeFilter = 'matchedIncident';
        }

        this.setState({ typeFilter });

        replace(
          buildLocation(
            location.pathname,
            {},
            { ...query, eventTimestamp: predictedOccurrenceTime, startTime, endTime: startTime },
          ),
        );
      }
    }

    // set typeFilter if not current day
    if (!typeFilter && moment.utc().startOf('day') > moment.utc(startTime, Defaults.DateFormat)) {
      this.setState({ typeFilter: 'matchedIncident' });
      replace(buildLocation(location.pathname, {}, { ...query }));
    }
  }

  @autobind
  async parseData(props) {
    const { replace, location, globalInfo, globalSystemInfo } = props;
    const { isAutoReload, filterList: stateEventList, hasKubernetes } = this.state;

    const query = parseLocation(location);
    const { environmentId, systemId, eventPatternId, eventProjectName } = query;
    const { eventPredictionTime, eventTimestamp } = query;
    const { startTime, endTime } = query;

    getChartTimeMap(this.chartTimeMap, startTime, endTime);

    // add wait time befor parse data
    if (!isAutoReload) {
      this.setState({ isLoadingParserData: true });
      await sleep(300);
    }

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    let anomalyEventList = get(globalSystemInfo, ['anomalyTimelines'], []);

    //  get prediction incident
    anomalyEventList = R.filter(
      (event) => R.toLower(event.type) === 'incident' && event.timeLineType === 'future',
      anomalyEventList,
    );

    // if eventList and system has no details, then reload system details
    if (anomalyEventList.length > 0 && !systemInfo.hasAllInstanceInfo) {
      this.setState({ isLoadingParserData: true });
      return this.loadSystemData();
    }

    const {
      eventList: events,
      hasTableData,
      anomalyTypeOptions,
      instanceListOptions,
      podListOptions,
      containerListOption,
      predictionSourceOptions,
      componentListOptions,
      patternIdFilterOptions,
      predictionStatusCountMap,
    } = tidyEventList(false, this, anomalyEventList, instanceComponentMap, hasKubernetes);

    this.anomalyTypeOptions = anomalyTypeOptions;

    this.instanceListOptions = instanceListOptions;
    this.podOptions = podListOptions;
    this.containerOption = containerListOption;

    this.predictionSourceOptions = predictionSourceOptions;

    this.componentListOptions = componentListOptions;

    this.patternIdFilterOptions = patternIdFilterOptions;

    this.predictionStatusCountMap = predictionStatusCountMap;

    this.summarySettingsMap = await this.getLogsummarysettings(events);

    // remind user to refresh if the data from auto reload
    if (isAutoReload) {
      // pop up window to confirm update incident
      this.setState({ isAutoReload: false }, () => {
        const newRelationCount = events.length - stateEventList.length;
        if (newRelationCount > 0) {
          const btn = (
            <Button size="small" onClick={() => this.handleNotificationConfirm({ props, events, query })}>
              Refresh
            </Button>
          );
          notification.info({
            message: 'Notification',
            description: `${newRelationCount} new anomaly ${
              newRelationCount > 1 ? 'events have' : 'event has'
            } been predicted.`,
            btn,
            key: this.notificationKey,
            duration: 0,
          });
        }
      });
    } else {
      const filterList = filterData(events, this);
      const chartOption = this.handleChartOption(filterList);
      // get events occurrence list
      const groupEventList = getEventsByOccurrence(this, filterList);

      const { jumpFatherIncident, jumpIncident, isShowPredictionSourceInfo } = findJumpEvent({
        groupEventList,
        query,
        isJWT: false,
        props,
      });

      this.setState(
        {
          events,
          filterList,
          groupEventList,
          incident: jumpIncident,
          isLoadingParserData: false,
          isShowPredictionSourceInfo,
          chartOption,
          isResetDateZoom: true,
          zoomStartTime: undefined,
          zoomEndTime: undefined,
          allChecked: false,
          hasTableData,
        },
        async () => {
          // reset jump params
          if (eventProjectName && eventPatternId && (eventTimestamp || eventPredictionTime)) {
            replace(
              buildLocation(
                location.pathname,
                {},
                {
                  ...query,
                  redirect: undefined,
                  eventProjectName: undefined,
                  eventInstanceName: undefined,
                  eventPatternId: undefined,
                  eventPatternType: undefined,
                  eventRootCauseMetric: undefined,
                  eventPredictionTime: undefined,
                  eventTimestamp: undefined,
                },
              ),
            );
          }

          if (jumpFatherIncident && jumpIncident) {
            this.fatherEvent = jumpFatherIncident;
            this.childEvent = jumpIncident;
            positionEvent({ self: this });
          } else {
            positionEvent({ self: this });
          }
        },
      );
    }
    return true;
  }

  @autobind
  getLogsummarysettings(eventList) {
    const { credentials } = this.props;
    const projectMap = {};
    R.forEach((item) => {
      const { category, projectName, projectOwner } = item || {};
      const key = `${projectName}-${projectOwner}-${category}`;
      if (!projectMap[key])
        projectMap[key] = {
          projectName: projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName,
          category,
        };
    }, eventList || []);

    const projects = [];
    R.forEach((item) => {
      const { projectName, category } = item || {};
      if (!R.includes(category, ['metric', 'Metric'])) projects.push(projectName);
    }, R.values(projectMap));

    const request = [];
    R.forEach((projectName) => {
      request.push(
        fetchGet(getEndpoint('logsummarysettings'), {
          ...credentials,
          projectName,
        }),
      );
    }, projects);

    return Promise.all(request)
      .then((res) => {
        const summarySettingsMap = {};
        R.addIndex(R.forEach)((projectName, idx) => {
          summarySettingsMap[projectName] = res[idx];
        }, projects || []);
        return summarySettingsMap;
      })
      .catch((err) => {
        message.error(err.message || String(err));
        return {};
      });
  }

  @autobind
  async handleNotificationConfirm({ props, events, query }) {
    notification.close(this.notificationKey);
    this.summarySettingsMap = await this.getLogsummarysettings(events);

    const filterList = filterData(events, this);
    const chartOption = this.handleChartOption(filterList);
    // get events occurrence list
    const groupEventList = getEventsByOccurrence(this, filterList);
    const { jumpFatherIncident, jumpIncident } = findJumpEvent({ groupEventList, query, isJWT: false, props });

    this.setState(
      {
        events,
        filterList,
        groupEventList,
        incident: jumpIncident,
        isLoadingParserData: false,
        chartOption,
        isResetDateZoom: true,
        zoomStartTime: undefined,
        zoomEndTime: undefined,
        allChecked: false,
      },
      () => {
        if (jumpFatherIncident && jumpIncident) {
          this.fatherEvent = jumpFatherIncident;
          this.childEvent = jumpIncident;
          positionEvent({ self: this });
        } else {
          positionEvent({ self: this });
        }
      },
    );
  }

  @autobind
  refresh() {
    this.reloadData(this.props, {});
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        startTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        endTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'add'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        endTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  onChangeFilter(value, type) {
    const { hasKubernetes } = this.state;
    let newValue;
    if (value.length > 0) newValue = R.join(',', value);

    let otherQuery = {};
    if (R.includes(type, ['instanceFilter'])) {
      if (hasKubernetes) {
        otherQuery = { logicPodIDFilter: undefined, containerFilter: undefined };
      }
    }
    if (R.includes(type, ['logicPodIDFilter'])) {
      if (hasKubernetes) {
        otherQuery = { instanceFilter: undefined };
        if (!newValue) {
          otherQuery = { ...otherQuery, containerFilter: undefined };
        }
      }
    }

    this.setState({ [type]: newValue, filterLoading: true, ...otherQuery }, async () => {
      await sleep(100);
      const { events } = this.state;
      const filterList = filterData(events, this);
      const chartOption = this.handleChartOption(filterList);
      const groupEventList = getEventsByOccurrence(this, filterList);
      this.setState(
        {
          filterList,
          groupEventList,
          chartOption,
          filterLoading: false,
          isResetDateZoom: true,
          zoomStartTime: undefined,
          zoomEndTime: undefined,
        },
        async () => {
          if (this.listNode) await this.listNode.scrollToPosition(1);
          if (this.listNode) this.listNode.scrollToPosition(1);
        },
      );
    });
  }

  @autobind
  renderTopBar({ isLoadingParserData }) {
    const { location, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    return renderTopBarRender({ self: this, isLoadingParserData, instanceDisplayNameMap });
  }

  @autobind
  handlePredictionDelete(eventList) {
    const { credentials, intl, location, globalInfo } = this.props;
    const { systemId, environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    const inputrecordMap = {};
    R.forEach((item) => {
      let { events } = item;
      if (!events) events = [item];
      R.forEach((n) => {
        const { instanceName, incidentTimestamp, projectName, startTimestamp, patternId, projectOwner } = n;
        const key = `${instanceName}-${projectName}-${
          incidentTimestamp || startTimestamp
        }-${patternId}-${projectOwner}`;
        inputrecordMap[key] = {
          instanceName,
          projectName,
          timestamp: incidentTimestamp || startTimestamp,
          patternId,
          customerName: projectOwner,
        };
      }, events || []);
    }, eventList || []);
    const inputrecords = R.values(inputrecordMap);

    fetchPost(getEndpoint('incident'), {
      ...credentials,
      inputrecords: JSON.stringify(inputrecords),
      isPrediction: true,
      systemName: systemId,
      customerName: systemInfo.ownerUserName,
    })
      .then((data) => {
        const description = [];
        R.forEachObjIndexed((val, key) => {
          key = JSON.parse(key);
          val = JSON.parse(val);
          const { projectName, timestamp } = key;
          const { msg, success } = val;
          if (!success) {
            description.push({ projectName, time: moment.utc(timestamp).format(Defaults.DateTimeFormat), msg });
          }
        }, data || {});
        if (R.isEmpty(description)) {
          this.reloadData(this.props, { needResetFilter: true });
          message.success(intl.formatMessage(appMessages.apiSuccess));
        } else {
          notification.open({
            message: 'Failed to delete',
            description: (
              <div className="flex-col flex-center-justify">
                {R.map(
                  (item) => (
                    <div key={item.projectName + item.time}>
                      {item.time}
                      <span className="bold" style={{ marginLeft: 4 }}>
                        {item.projectName}
                      </span>
                      :{item.msg}
                    </div>
                  ),
                  description,
                )}
              </div>
            ),
          });
          this.reloadData(this.props, { needResetFilter: true });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  rootCauseChainRenderer(rowData, rowIndex, active) {
    const { intl } = this.props;
    const incident = rowData;
    const { predictionSourceInfoList } = incident;
    const patternNames = R.map((item) => item.patternName, predictionSourceInfoList || []);
    const nameChains = R.join('->', patternNames);
    const completeChains = `${nameChains}->${incident.patternName}`;
    return (
      <div className="flex-row flex-center-align full-width flex-center-justify">
        <Popover
          placement="top"
          title={intl.formatMessage(logMessages.rootCauseChain)}
          content={
            <div className="flex-col flex-center-jusutify" style={{ maxWidth: 400 }}>
              <div className="flex-grow flex-grow flex-wrap" style={{ wordWrap: 'anywhere', textAlign: 'left' }}>
                {completeChains}
              </div>
            </div>
          }
        >
          <Button
            size="small"
            icon={<SearchOutlined style={{ fontSize: 16, color: 'var(--primary-color)' }} />}
            style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}
            onClick={(e) => {
              e.stopPropagation();
              const incident = R.clone(rowData);
              this.setState({ incident, activeKey: 'incident', isShowPredictionSourceInfo: true });
            }}
          />
        </Popover>
        <Popover mouseEnterDelay={0.3} placement="left" content={intl.formatMessage(logMessages.recommendations)}>
          <FormOutlined
            className="clickable"
            style={{ fontSize: 14, color: 'var(--primary-color)', marginLeft: 4 }}
            onClick={() => this.setState({ showRecommendationModal: true, activeEvent: rowData })}
          />
        </Popover>
        <Popover mouseEnterDelay={0.3} placement="left" content={intl.formatMessage(logMessages.predictionSummary)}>
          <RobotIcon
            className="clickable"
            style={{ fontSize: 20, color: 'var(--primary-color)', marginLeft: 8 }}
            onClick={() => this.setState({ showRecommendationsGPT: true, activeRecommendation: rowData })}
          />
        </Popover>
      </div>
    );
  }

  @autobind
  confirmButtonRender(rowData, rowIndex, rootEvent) {
    const { intl, timezoneOffset, isReadUser } = this.props;
    const { isImportant, ruleReporter } = rowData;
    const { reporterMap } = ruleReporter || {};
    const { like: confirm, unlike: unconfirm } = reporterMap || {};
    let confirmReporterDetails = [];

    R.forEachObjIndexed((val, key) => {
      const { reportTimestampSet, reporterName } = val || {};
      R.forEach((day) => {
        confirmReporterDetails.push({
          time: day,
          owner: reporterName,
          isConfirm: true,
          action: 'Like',
        });
      }, reportTimestampSet || []);
    }, confirm?.reporterDetailMap || {});

    R.forEachObjIndexed((val, key) => {
      const { reportTimestampSet, reporterName } = val || {};
      R.forEach((day) => {
        confirmReporterDetails.push({
          time: day,
          owner: reporterName,
          isConfirm: false,
          action: 'Unlike',
        });
      }, reportTimestampSet || []);
    }, unconfirm?.reporterDetailMap || {});

    confirmReporterDetails = R.sortWith([R.descend(R.prop('time'))], confirmReporterDetails);

    return (
      <Popover
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 400, maxHeight: 150 }}>
            {isReadUser
              ? intl.formatMessage(eventMessages.isReadUserDisable)
              : confirmReporterDetails.length > 0
              ? R.addIndex(R.map)((item, idx) => {
                  const { time, owner, action, isConfirm } = item;
                  const customerTimestamp = moment.utc(time).valueOf() + (timezoneOffset || 0) * 60000;
                  return (
                    <div key={idx} className="flex-row flex-center-align">
                      <div style={{ width: 80, flexShrink: 0 }}>
                        {moment.utc(customerTimestamp).format(Defaults.ShortTimeFormat)}
                      </div>
                      <div className="bold light-label" style={{ marginRight: 4, flexShrink: 0 }}>
                        {intl.formatMessage(eventActionMessages.reporter)}:
                      </div>
                      <GlobalRenderers.RenderReporterAvatar userName={owner} />
                      <span className="hidden-line-with-ellipsis" style={{ marginLeft: 2 }}>
                        {owner}
                      </span>
                      <div className="bold light-label" style={{ marginLeft: 8, marginRight: 4, flexShrink: 0 }}>
                        {intl.formatMessage(eventActionMessages.action)}:
                      </div>
                      <span style={{ margin: '0 4px', color: isConfirm ? 'var(--green)' : '', flexShrink: 0 }}>
                        {action}
                      </span>
                    </div>
                  );
                }, confirmReporterDetails || [])
              : isImportant
              ? intl.formatMessage(eventMessages.removeLikeFlag)
              : intl.formatMessage(eventMessages.markAsLike)}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="left"
      >
        <Button
          size="small"
          style={{ marginRight: 4 }}
          disabled={isReadUser}
          onClick={(e) => {
            e.stopPropagation();
            this.fatherEvent = rootEvent;
            this.childEvent = rowData;
            this.setState({ incident: rowData }, () => {
              this.handleIgnoreClick({ event: rowData, category: 'confirm' });
            });
          }}
        >
          {isImportant ? <GoodIcon style={{ color: 'var(--green)' }} /> : <GoodOutlinedIcon />}
        </Button>
      </Popover>
    );
  }

  @autobind
  ignoreButtonRender(rowData, rowIndex, rootEvent) {
    const { intl, timezoneOffset, isReadUser } = this.props;
    const { isIgnored, ruleReporter } = rowData;
    const { reporterMap } = ruleReporter || {};
    const { dislike: ignore, undislike: unignore, systemIgnore } = reporterMap || {};
    let ignoreReporterDetails = [];

    R.forEachObjIndexed((val, key) => {
      const { reportTimestampSet, reporterName } = val || {};
      R.forEach((day) => {
        ignoreReporterDetails.push({
          time: day,
          owner: reporterName,
          isIgnore: true,
          action: 'Dislike',
        });
      }, reportTimestampSet || []);
    }, ignore?.reporterDetailMap || {});

    R.forEachObjIndexed((val, key) => {
      const { reportTimestampSet, reporterName } = val || {};
      R.forEach((day) => {
        ignoreReporterDetails.push({
          time: day,
          owner: reporterName,
          isIgnore: false,
          action: 'Undislike',
        });
      }, reportTimestampSet || []);
    }, unignore?.reporterDetailMap || {});

    R.forEachObjIndexed((val, key) => {
      const { reportTimestampSet } = val || {};
      R.forEach((day) => {
        ignoreReporterDetails.push({
          time: day,
          owner: 'system',
          isIgnore: true,
          action: intl.formatMessage(eventMessages.ignored),
        });
      }, reportTimestampSet || []);
    }, systemIgnore?.reporterDetailMap || {});
    ignoreReporterDetails = R.sortWith([R.descend(R.prop('time'))], ignoreReporterDetails);

    return (
      <Popover
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 400, maxHeight: 150 }}>
            {isReadUser
              ? intl.formatMessage(eventMessages.isReadUserDisable)
              : ignoreReporterDetails.length > 0
              ? R.addIndex(R.map)((item, idx) => {
                  const { time, owner, action, isIgnore } = item;
                  const customerTimestamp = moment.utc(time).valueOf() + (timezoneOffset || 0) * 60000;
                  return (
                    <div key={idx} className="flex-row flex-center-align">
                      <div style={{ width: 80, flexShrink: 0 }}>
                        {moment.utc(customerTimestamp).format(Defaults.ShortTimeFormat)}
                      </div>
                      <div className="bold light-label" style={{ marginRight: 4, flexShrink: 0 }}>
                        {intl.formatMessage(eventActionMessages.reporter)}:
                      </div>
                      <GlobalRenderers.RenderReporterAvatar userName={owner} />
                      <span className="hidden-line-with-ellipsis" style={{ marginLeft: 2 }}>
                        {owner}
                      </span>
                      <div className="bold light-label" style={{ marginLeft: 8, marginRight: 4, flexShrink: 0 }}>
                        {intl.formatMessage(eventActionMessages.action)}:
                      </div>
                      <span style={{ margin: '0 4px', color: isIgnore ? 'var(--yellow)' : '', flexShrink: 0 }}>
                        {action}
                      </span>
                    </div>
                  );
                }, ignoreReporterDetails || [])
              : isIgnored
              ? 'Remove dislike flag'
              : 'Dislike'}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="left"
      >
        <Button
          size="small"
          disabled={isReadUser}
          onClick={(e) => {
            e.stopPropagation();
            this.fatherEvent = rootEvent;
            this.childEvent = rowData;
            this.setState({ incident: rowData }, () => {
              this.handleIgnoreClick({ event: rowData, category: 'ignore' });
            });
          }}
        >
          {isIgnored && <GoodIcon style={{ color: 'var(--yellow)', transform: 'rotateX(180deg)' }} />}
          {!isIgnored && <GoodOutlinedIcon style={{ transform: 'rotateX(180deg)' }} />}
        </Button>
      </Popover>
    );
  }

  @autobind
  onChangeIncidentStatus({ rowData, rowIndex, field, value }) {
    const { intl, credentials } = this.props;
    const { projectOwner, anomalyLogInstance, instanceName, incidentTimestamp } = rowData;
    let { projectName } = rowData;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const oldValue = get(rowData, field);

    rowData[`isLoading-${field}`] = true;
    rowData[field] = value;
    if (this.listNode) this.listNode.forceUpdateGrid();

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('incidentInvestigation'), {
      ...credentials,
      projectName,
      instanceName: anomalyLogInstance || instanceName,
      timestamp: incidentTimestamp,
      [field === 'incidentStatus' ? 'status' : 'severity']: value,
    })
      .then((data) => {
        // delete teams channel if exist
        if (field === 'incidentStatus' && value === 'closed') {
          this.handleTeamsCheckAndDetele(rowData, rowIndex);
        } else {
          this.refresh();
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        rowData[`isLoading-${field}`] = false;
        rowData[field] = oldValue;
        if (this.listNode) this.listNode.forceUpdateGrid();
      });
  }

  @autobind
  async handleTeamsCheckAndDetele(rowData, rowIndex) {
    const { credentials } = this.props;
    const { projectName, projectOwner, instanceName, incidentTimestamp } = rowData;
    this.props.updateLastActionInfo();
    const channels = await fetchGet(getEndpoint('incident-channel-info'), {
      ...credentials,
      customerName: projectOwner,
      projectName,
      instanceName,
      incidentTimestamp,
    })
      .then((data) => {
        return data || [];
      })
      .catch((error) => {
        return [];
      });

    if (channels.length > 0) {
      // delete teams channel
      this.handleTeamsDetele(rowData, channels, rowIndex);
    } else {
      this.refresh();
    }
  }

  @autobind
  handleTeamsDetele(rowData, channels, rowIndex) {
    const { intl } = this.props;
    this.deleteIncidentCheckedMap = {};
    this.deleteTeamsChannelModal = Modal.confirm({
      title: intl.formatMessage(eventMessages.deleteTeamsChannelConfirm),
      content: (
        <div>
          {R.map((channel) => {
            const { channelType, channelName } = channel || {};
            return (
              <div key={channelType} className="flex-row">
                <div className="flex-grow flex-min-width">
                  <span className="light-label bold" style={{ marginRight: 4 }}>
                    {this.channelNameMap[channelType] || channelType}:
                  </span>{' '}
                  {channelName}
                </div>
                <Switch
                  size="small"
                  style={{ width: 50 }}
                  checkedChildren={intl.formatMessage(appButtonsMessages.yes)}
                  unCheckedChildren={intl.formatMessage(appButtonsMessages.no)}
                  defaultChecked={this.deleteIncidentCheckedMap[channelType] || false}
                  onChange={(checked) => {
                    this.deleteIncidentCheckedMap[channelType] = checked;
                    if (this.deleteTeamsChannelModal) {
                      const hasChecked = R.reduce(R.or, false, R.values(this.deleteIncidentCheckedMap));
                      this.deleteTeamsChannelModal.update({
                        okButtonProps: { disabled: !hasChecked },
                      });
                    }
                  }}
                />
              </div>
            );
          }, channels)}
        </div>
      ),
      okButtonProps: { disabled: true },
      onCancel: () => {
        if (this.deleteTeamsChannelModal) this.deleteTeamsChannelModal.destroy();
        this.refresh();
      },
      onOk: (close) => {
        const hasChecked = R.reduce(R.or, false, R.values(this.deleteIncidentCheckedMap));
        if (hasChecked) {
          this.handleTeamsDeteleConfirm(rowData, close, channels, rowIndex);
        } else {
          this.refresh();
          close();
        }
      },
    });
  }

  @autobind
  handleTeamsDeteleConfirm(rowData, close, channels, rowIndex) {
    const { intl, credentials, location } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const { projectName, projectOwner, instanceName, incidentTimestamp } = rowData;

    if (this.deleteTeamsChannelModal) {
      this.deleteTeamsChannelModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }

    const requests = [];
    R.forEachObjIndexed((val, channelType) => {
      if (val) {
        if (channelType === 'Teams') {
          requests.push(
            fetchPost(getEndpoint('delete-teams'), {
              ...credentials,
              environmentName: environmentId,
              systemName: systemId,
              customerName: projectOwner,
              projectName,
              instanceName,
              incidentTimestamp,
            }),
          );
        } else if (channelType === 'Slack') {
          requests.push(
            fetchPost(getEndpoint('delete-slack'), {
              ...credentials,
              environmentName: environmentId,
              systemName: systemId,
              customerName: projectOwner,
              projectName,
              instanceName,
              incidentTimestamp,
            }),
          );
        }
      }
    }, this.deleteIncidentCheckedMap);

    this.props.updateLastActionInfo();
    Promise.all(requests)
      .then((results) => {
        const success = R.reduce(
          R.and,
          true,
          R.map((item) => item.success, results),
        );
        const errMsg = R.reduce(
          R.or,
          '',
          R.map((item) => item.message, results),
        );
        if (success) {
          this.refresh();
          close();
        } else if (this.deleteTeamsChannelModal) {
          this.deleteTeamsChannelModal.update({
            content: (
              <div>
                <div>
                  <div>
                    {R.map((channel) => {
                      const { channelType, channelName } = channel || {};
                      return (
                        <div key={channelType} className="flex-row">
                          <div className="flex-grow flex-min-width">
                            <span className="light-label bold" style={{ marginRight: 4 }}>
                              {this.channelNameMap[channelType] || channelType}:
                            </span>{' '}
                            {channelName}
                          </div>
                          <Switch
                            size="small"
                            style={{ width: 50 }}
                            checkedChildren={intl.formatMessage(appButtonsMessages.yes)}
                            unCheckedChildren={intl.formatMessage(appButtonsMessages.no)}
                            defaultChecked={this.deleteIncidentCheckedMap[channelType] || false}
                            onChange={(checked) => {
                              this.deleteIncidentCheckedMap[channelType] = checked;
                              if (this.deleteTeamsChannelModal) {
                                const hasChecked = R.reduce(R.or, false, R.values(this.deleteIncidentCheckedMap));
                                this.deleteTeamsChannelModal.update({
                                  okButtonProps: { disabled: !hasChecked },
                                });
                              }
                            }}
                          />
                        </div>
                      );
                    }, channels)}
                  </div>
                </div>
                <Alert message={errMsg} type="error" style={{ marginTop: 8 }} />
              </div>
            ),
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      })
      .catch((error) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        if (this.deleteTeamsChannelModal) {
          this.deleteTeamsChannelModal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      });
  }

  @autobind
  statusContentRenderer(rowData, rowIndex) {
    const { isReadUser, intl, credentials, timezoneOffset } = this.props;
    const { status, isFixedIncident, incidentStatus, 'isLoading-incidentStatus': isLoading } = rowData;
    const { investigationReporterRecordStatus } = rowData;

    if (isFixedIncident || status === 'Auto resolved') {
      return <span>{status}</span>;
    }

    return (
      <Popover
        title={null}
        content={
          isReadUser ? (
            intl.formatMessage(eventMessages.isReadUserDisable)
          ) : (
            <EventRenderers.RenderIncidentStatusTooltipContent
              intl={intl}
              credentials={credentials}
              timezoneOffset={timezoneOffset}
              reporterRecordSet={investigationReporterRecordStatus}
              statusColorMap={this.statusColorMap}
              incident={rowData}
              updateLastActionInfo={this.props.updateLastActionInfo}
            />
          )
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <Spin size="small" spinning={!!isLoading} wrapperClassName="spin-full-width full-width">
          <Select
            showArrow
            size="small"
            style={{ width: '100%' }}
            value={incidentStatus}
            onChange={(value) =>
              this.onChangeIncidentStatus({
                rowData,
                rowIndex,
                field: 'incidentStatus',
                value,
              })
            }
            disabled={isReadUser}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
            className={
              incidentStatus === 'in progress'
                ? 'status-select-progress'
                : incidentStatus === 'closed'
                ? 'status-select-closed'
                : ''
            }
          >
            {R.map((item) => {
              return (
                <Select.Option key={item.value}>
                  <span>{item.label}</span>
                </Select.Option>
              );
            }, this.statusOptions)}
          </Select>
        </Spin>
      </Popover>
    );
  }

  @autobind
  handlePatternConfigClick({ event }) {
    this.setState({ showPatternConfigModel: true, activeEvent: event });
  }

  @autobind
  handleConfigNotesClick({ event }) {
    this.setState({ configNotesModal: true, activeEvent: event });
  }

  @autobind
  controlRenderer(rowData, rowIndex) {
    const { intl, isReadUser } = this.props;
    return (
      <Dropdown
        name={intl.formatMessage(eventMessages.actions)}
        butStyle={{ width: 90 }}
        align={{ offset: [70, 0] }}
        itemClick={({ key }) => {
          switch (key) {
            case 'patternConfig':
              this.handlePatternConfigClick({ event: rowData });
              break;
            case 'email':
              this.handleMailAlertClick({ event: rowData });
              break;
            case 'export':
              this.handleExportAlertClick({ event: rowData });
              break;
            case 'configNotes':
              this.handleConfigNotesClick({ event: rowData });
              break;
            default:
              break;
          }
        }}
      >
        <Menu.Item key="configNotes" icon={<FormOutlined />} disabled={isReadUser}>
          <Popover
            content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
            mouseEnterDelay={0.3}
            placement="left"
            zIndex={10001}
          >
            {intl.formatMessage(appFieldsMessages.Notes)}
          </Popover>
        </Menu.Item>
        <Menu.Item key="patternConfig" icon={<SettingOutlined />} disabled={isReadUser}>
          <Popover
            content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
            mouseEnterDelay={0.3}
            placement="left"
            zIndex={10001}
          >
            {intl.formatMessage(eventMessages.investigationPatternConfig)}
          </Popover>
        </Menu.Item>
        <Menu.Item key="email" icon={<MailOutlined />} disabled={isReadUser}>
          <Popover
            content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
            mouseEnterDelay={0.3}
            placement="left"
            zIndex={10001}
          >
            {intl.formatMessage(eventActionMessages.email)}
          </Popover>
        </Menu.Item>
        <Menu.Item key="export" icon={<ExportOutlined />} disabled={isReadUser}>
          <Popover
            content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
            mouseEnterDelay={0.3}
            placement="left"
            zIndex={10001}
          >
            {intl.formatMessage(eventMessages.export)}
          </Popover>
        </Menu.Item>
      </Dropdown>
    );
  }

  @autobind
  renderListItem(events) {
    return ({ key, index: rowIndex, style, parent }) => {
      const { currentTheme, location, globalInfo, intl } = this.props;
      const { incident, groupEventList } = this.state;
      const rowData = events[rowIndex];

      if (!rowData) return null;

      const { environmentId, systemId } = parseLocation(location);
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

      const active = rowData.id === incident?.id;
      const { isParent, isTimeRoot, dateEnFormat, startTimestamp, expandKey } = rowData;

      const dateData = R.find(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(groupEventList);
      const dateIdx = R.findIndex(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(
        groupEventList,
      );
      const rootEvent = R.find(R.propEq('expandKey', expandKey))(dateData?.events || []);
      const rootEventIdx = R.findIndex(R.propEq('expandKey', expandKey))(dateData?.events || []);

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div
            className="event-list-category-row flex-row"
            style={{ ...style, borderBottom: 'none', borderColor: 'transparent' }}
          >
            {isTimeRoot && (
              <div
                className="flex-row full-width"
                style={{
                  backgroundColor: currentTheme === 'dark' ? '#4b4a4a' : '#d0d0d0',
                  height: this.dateHeight - 1,
                  borderBottom: '1px solid transparent',
                  borderColor: 'var(--virtualized-table-border-color)',
                }}
                onClick={() => handleMergeCategoryDateClick(this, rowData)}
              >
                <div className="row-column flex-center-justify" style={{ width: 40 }} />
                <div
                  className="row-column font-14 bold"
                  style={{ width: 90 + 120 + 90 + 160 + 190, minWidth: 90 + 120 + 90 + 160 + 190, paddingRight: 16 }}
                >
                  {dateEnFormat}
                </div>
                <div className="row-column flex-grow" />
                <div className="row-column flex-center-justify break-word" style={{ width: 110 }}>
                  {rowData.count}
                </div>
                <div className="row-column" style={{ width: 70, padding: 0 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
              </div>
            )}
            {!isTimeRoot && isParent && (
              <div
                className="flex-row full-width"
                style={{
                  backgroundColor: 'var(--item-active-bg2)',
                  height: this.rowMinHeight - 1,
                  borderBottom: '1px solid transparent',
                  borderColor: 'var(--virtualized-table-border-color)',
                }}
                onClick={() => handleMergeCategoryParentClick({ self: this, dateIdx, rootEventIdx, rowData })}
              >
                <div
                  className="row-column flex-center-justify"
                  style={{ width: 40 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {checkboxParentRender(this, rowData, rootEventIdx, dateIdx)}
                </div>
                <div className="row-column" style={{ width: 90, minWidth: 90 }} />
                <div className="row-column" style={{ width: 120, minWidth: 120, paddingRight: 16 }} />
                <div className="row-column" style={{ width: 90, minWidth: 90 }} />
                <div className="row-column" style={{ width: 160, paddingRight: 16, minWidth: 160 }}>
                  {patternRenderer(this, false, rowData, isParent)}
                </div>
                <div className="row-column" style={{ width: 190, paddingRight: 16, minWidth: 190 }}>
                  {impactedRender({ intl, rowData, isChild: false, instanceDisplayNameMap })}
                </div>
                <div className="row-column" style={{ flex: 1, minWidth: 140 }} />
                <div
                  className="row-column"
                  style={{ width: 130, minWidth: 130, ...childrenBorder, paddingRight: 16 }}
                />
                <div className="row-column" style={{ width: 100 }} />
                <div className="row-column" style={{ minWidth: 95 }} />
                <div className="row-column flex-center-justify break-word" style={{ width: 110 }}>
                  {rowData.count}
                </div>
                <div className="row-column" style={{ width: 70, padding: 0 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
              </div>
            )}

            {!isTimeRoot && !isParent && (
              <div
                className={`event-list-row full-width ${active ? ' active' : ''}`}
                style={{
                  height: '100%',
                  borderBottom: '1px solid var(--virtualized-table-border-color)',
                  ...(active ? {} : { cursor: 'pointer' }),
                }}
                onClick={() => this.handleMergeCategoryClick(rowIndex, rowData, rootEvent)}
              >
                <div
                  className="row-column flex-center-justify"
                  style={{ width: 40, ...childrenBorder }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {this.checkboxRender(rowData, rootEvent, rootEventIdx, dateIdx)}
                </div>
                <div className="row-column" style={{ width: 90, minWidth: 90, paddingRight: 16 }}>
                  {timeRenderer(rowData)}
                </div>
                <div className="row-column" style={{ width: 120, minWidth: 120 }}>
                  {occurrenceTime(rowData)}
                </div>
                <div className="row-column" style={{ width: 90, minWidth: 90, paddingRight: 16 }}>
                  {CellRenderers.humanizeDuration({
                    period: rowData.leadTime,
                    intl: this.props.intl,
                    showSeconds: true,
                    showZero: true,
                    shortDisplay: true,
                    showZeroToOne: true,
                  })}
                </div>
                <div className="row-column" style={{ width: 160, paddingRight: 16, minWidth: 160 }} />
                <div className="row-column" style={{ width: 190, paddingRight: 16, minWidth: 190 }}>
                  {impactedRender({ intl, rowData, isChild: true, instanceDisplayNameMap })}
                </div>
                <div className="row-column" style={{ flex: 1, paddingRight: 10, minWidth: 140 }}>
                  {rendererContent(rowData, this)}
                </div>
                <div className="row-column" style={{ width: 130, minWidth: 130, ...childrenBorder, paddingRight: 16 }}>
                  {this.rootCauseChainRenderer(rowData, rowIndex, active)}
                </div>
                <div className="row-column flex-wrap" style={{ width: 100, gap: 4 }}>
                  {statusChainRenderer(rowData, this)}
                </div>
                <div className="row-column" style={{ minWidth: 95 }}>
                  {this.statusContentRenderer(rowData, rowIndex)}
                </div>
                <div className="row-column flex-center-justify break-word" style={{ width: 110 }}>
                  {this.controlRenderer(rowData, rowIndex)}
                </div>
                <div className="row-column" style={{ width: 70, padding: 0 }}>
                  {this.confirmButtonRender(rowData, rowIndex, rootEvent)}
                  {this.ignoreButtonRender(rowData, rowIndex, rootEvent)}
                </div>
              </div>
            )}
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  componentRender(rowData, active) {
    const { componentName } = rowData;
    return (
      <Popover content={componentName} mouseEnterDelay={0.3} placement="right">
        <div style={{ ...(active ? { cursor: 'default' } : {}) }} className="hidden-line-with-ellipsis">
          {componentName}
        </div>
      </Popover>
    );
  }

  @autobind
  instanceRender(rowData, active) {
    const { instanceName } = rowData;
    return (
      <Popover content={instanceName} mouseEnterDelay={0.3} placement="right">
        <div style={{ ...(active ? { cursor: 'default' } : {}) }} className="hidden-line-with-ellipsis">
          {instanceName}
        </div>
      </Popover>
    );
  }

  @autobind
  checkboxRender(rowData, rootEvent, rootEventIdx, dateIdx) {
    const { groupEventList } = this.state;
    const { checked } = rowData;
    const rowIndex = R.findIndex(R.propEq('id', rowData.id))(rootEvent.events || []);
    return (
      <Checkbox
        size="small"
        checked={checked}
        onChange={(e) => {
          const newGroupEventList = R.addIndex(R.map)((timeEvent, dateIndex) => {
            if (dateIndex === dateIdx) {
              const newDateEvents = R.addIndex(R.map)((groupEvent, eventIndex) => {
                if (eventIndex === rootEventIdx) {
                  const newEvents = R.addIndex(R.map)((event, index) => {
                    if (index === rowIndex) {
                      return { ...event, checked: e.target.checked };
                    } else {
                      return { ...event };
                    }
                  }, groupEvent.events);

                  const allParentChecked = !R.find((item) => !item.checked, newEvents);
                  return { ...groupEvent, events: newEvents, checked: allParentChecked };
                } else {
                  return { ...groupEvent };
                }
              }, timeEvent.events);
              return { ...timeEvent, events: newDateEvents };
            } else {
              return { ...timeEvent };
            }
          }, groupEventList);

          const allChecked = R.all(R.equals(true))(
            R.map((event) => {
              return R.all(R.equals(-1))(
                R.map((item) => R.findIndex((citem) => !citem.checked, item.events || []), event.events || []),
              );
            }, newGroupEventList || []),
          );

          this.setState({ groupEventList: newGroupEventList, allChecked }, () => {
            if (this.listNode) this.listNode.forceUpdateGrid();
          });
        }}
      />
    );
  }

  @autobind
  matchedRenderer(rowData) {
    const { intl } = this.props;
    const { isMatchedIncident, hasTrigger, hasFuture, hasNotValidSrc } = rowData;
    return (
      <>
        {isMatchedIncident && (
          <Popover
            title={null}
            content={
              isMatchedIncident
                ? intl.formatMessage(logMessages.matchedIncident)
                : intl.formatMessage(logMessages.unmatchedIncident)
            }
            mouseEnterDelay={0.3}
          >
            <div
              style={{
                textAlign: 'center',
                background: '#ff5142',
                color: 'white',
                padding: '0 2px',
                borderRadius: '50%',
                width: 16,
                height: 16,
                lineHeight: '16px',
                marginRight: 2,
                cursor: 'default',
              }}
            >
              M
            </div>
          </Popover>
        )}

        {!isMatchedIncident && !hasTrigger && !hasFuture && !hasNotValidSrc && (
          <Popover
            title={null}
            content={
              isMatchedIncident
                ? intl.formatMessage(logMessages.matchedIncident)
                : intl.formatMessage(logMessages.unmatchedIncident)
            }
            mouseEnterDelay={0.3}
          >
            <div
              style={{
                textAlign: 'center',
                background: 'gray',
                color: 'white',
                padding: '0 2px',
                borderRadius: '50%',
                width: 16,
                height: 16,
                marginRight: 2,
                cursor: 'default',
              }}
            >
              U
            </div>
          </Popover>
        )}
      </>
    );
  }

  @autobind
  instanceRenderer(rowData) {
    const { appName, instanceAppNames } = rowData;

    return (
      <Popover
        title={null}
        content={
          <div
            className="log-event-group flex-col"
            style={{
              width: 250,
              maxHeight: 300,
              overflowX: 'hidden',
              overflowY: 'auto',
              fontSize: 12,
              padding: '0 0',
            }}
          >
            <div>
              {R.addIndex(R.map)(
                (item, idx) => (
                  <div key={idx}>{item}</div>
                ),
                instanceAppNames,
              )}
            </div>
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <div className="hidden-line-with-ellipsis max-width">{appName}</div>
      </Popover>
    );
  }

  @autobind
  handleMergeCategoryClick(rowIndex, rowData, rootEvent) {
    const incident = rowData;
    this.fatherEvent = rootEvent;
    this.childEvent = rowData;
    this.setState({ incident, activeKey: 'incident' });
  }

  @autobind
  handleIgnoreClick({ event, category }) {
    const { intl } = this.props;
    const { isIgnored, isImportant, patternName, patternId } = event;
    const flag =
      (category === 'ignore' && isIgnored) ||
      (category === 'important' && isImportant) ||
      (category === 'confirm' && isImportant);
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: flag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Undislike' : category === 'confirm' ? 'Unlike' : 'Normal',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Dislike' : category === 'confirm' ? 'Like' : 'Confirmed ',
          }),
      okText: intl.formatMessage(appButtonsMessages.submit),
      cancelText: intl.formatMessage(appButtonsMessages.cancel),
      onOk: async () => {
        if (category === 'ignore') {
          await this.ignoreSumbit(event);
        } else if (category === 'confirm') {
          await this.handleMarkImportantSumbit(event);
        } else {
          await this.handleIgnoreSumbit(event, category);
        }
      },
    });
  }

  @autobind
  ignoreSumbit(incident) {
    const { intl, credentials } = this.props;
    const { isIgnored } = incident;
    let { predictionRuleInfo } = incident;

    try {
      predictionRuleInfo = JSON.parse(predictionRuleInfo || '{}');
    } catch (error) {
      // error
    }

    if (this.ignoreModal) {
      this.ignoreModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }

    const RULE_DISLIKE = 5;
    let rules = JSON.stringify([[predictionRuleInfo, RULE_DISLIKE]]);
    if (isIgnored) {
      rules = JSON.stringify([predictionRuleInfo]);
    }

    this.props.updateLastActionInfo();
    return fetchPut(getEndpoint('knowledge-base', 1), {
      ...credentials,
      operation: isIgnored ? 'undo' : 'update',
      type: isIgnored ? 'undislike' : undefined,
      rules,
      // ...(isIgnored ? { type: 'unignore' } : {}),
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        if (this.ignoreModal) this.ignoreModal.destroy();
        this.refresh();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        if (this.ignoreModal) {
          this.ignoreModal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      });
  }

  @autobind
  handleIgnoreSumbit(incident, category) {
    const { intl, credentials } = this.props;
    const {
      isIgnored,
      isConfirmed,
      predictionSourceInfoList,
      startTimestamp,
      projectName,
      projectOwner,
      patternId,
      anomalyLogInstance,
      instanceName,
    } = incident;

    let { predictionRuleInfo } = incident;
    try {
      predictionRuleInfo = JSON.parse(predictionRuleInfo || '{}');
    } catch (error) {
      // error
    }

    const { sourceProjectName, sourceProjectOwner, sourceInstanceName } = predictionSourceInfoList[0] || {};
    let rules = [
      {
        projectName: `${sourceProjectName}@${sourceProjectOwner}`,
        instanceName: sourceInstanceName,
        timestamp: startTimestamp,
        incidentProjectName: `${projectName}@${projectOwner}`,
        incidentInstanceName: anomalyLogInstance || instanceName,
        incidentPatternId: patternId,
        statusFlag: category === 'confirm' ? (isConfirmed ? 0 : 1) : isIgnored ? 2 : -1,
      },
    ];

    if (category === 'confirm' && !R.isEmpty(predictionRuleInfo)) {
      rules = [
        { ...predictionRuleInfo, statusFlag: category === 'confirm' ? (isConfirmed ? 0 : 1) : isIgnored ? 2 : -1 },
      ];
    }

    if (this.ignoreModal) {
      this.ignoreModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }

    this.props.updateLastActionInfo();
    return fetchPost(
      getEndpoint('predictionrule', 1),
      {
        ...credentials,
        operation: category === 'confirm' ? 'setLike' : 'setIgnore',
        rules: JSON.stringify(rules),
      },
      {},
      false,
    )
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        if (this.ignoreModal) this.ignoreModal.destroy();
        this.refresh();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        if (this.ignoreModal) {
          this.ignoreModal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      });
  }

  @autobind
  handleMarkImportantSumbit(incident) {
    const { intl, credentials } = this.props;
    const { isImportant } = incident;
    let { predictionRuleInfo } = incident;

    if (this.ignoreModal) {
      this.ignoreModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }

    try {
      predictionRuleInfo = JSON.parse(predictionRuleInfo || '{}');
    } catch (error) {
      // error
    }

    const RULE_LIKE = 4;
    let rules = JSON.stringify([[predictionRuleInfo, RULE_LIKE]]);
    if (isImportant) {
      rules = JSON.stringify([predictionRuleInfo]);
    }

    this.props.updateLastActionInfo();
    return fetchPut(getEndpoint('knowledge-base', 1), {
      ...credentials,
      operation: isImportant ? 'undo' : 'update',
      rules,
      ...(isImportant ? { type: 'unlike' } : {}),
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        if (this.ignoreModal) this.ignoreModal.destroy();
        this.refresh();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        if (this.ignoreModal) {
          this.ignoreModal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      });
  }

  @autobind
  async handleMailAlertClick({ event }) {
    const { intl, location, credentials, isAdmin, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const ownerUserName = get(systemInfo, 'ownerUserName');

    this.emailAlertModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <div className="flex-row flex-center-align flex-center-justify">
          <Spin spinning />
        </div>
      ),
      okButtonProps: { disabled: true },
      onOk: () => {
        if (this.emailAlertModal) this.emailAlertModal.destroy();
      },
    });

    const emailAddress = await fetchGet(getEndpoint('healthviewsetting', 2), {
      ...credentials,
      customerName: ownerUserName,
      environmentName: environmentId,
      shareUserFlag: ownerUserName !== credentials.userName && !isAdmin,
    })
      .then((data) => {
        const systemConfigs = [];
        R.forEachObjIndexed((system, systemId) => {
          const { environmentName } = system.key || {};
          systemConfigs.push({ ...system, systemId, id: systemId, environmentId: environmentName });
        }, data);
        const systemConfigInfo = get(data, systemId);
        return systemConfigInfo ? systemConfigInfo.predictionEmail : '';
      })
      .catch((error) => {
        console.error('[IF_API] ', error);
        return '';
      });

    this.handleMailAlertModal(event, emailAddress);
  }

  @autobind
  handleMailAlertModal(event, emailAddress) {
    const { intl } = this.props;
    this.emailAddressList = R.map((email) => R.trim(email), R.split(',', emailAddress || ''));

    // handle content change
    const onChangeMailList = (e) => {
      const value = e.target.value || '';
      const hasErrorEmailAddress = !R.reduce(
        (a, b) => a && b,
        true,
        R.map((email) => Regex.email.test(R.trim(email)), value.split(',')),
      );
      this.emailAddressList = R.map((email) => R.trim(email), R.split(',', value));
      if (this.emailAlertModal) {
        this.emailAlertModal.update({ okButtonProps: { disabled: hasErrorEmailAddress } });
      }
    };

    this.emailAlertModal.update({
      content: (
        <div>
          <div>{`${intl.formatMessage(eventMessages.sendEmailToAddress)}:`}</div>
          <Input.TextArea rows={3} defaultValue={emailAddress} onChange={onChangeMailList} />
        </div>
      ),
      okButtonProps: { disabled: false },
      onOk: this.handleMailAlertSumbit(event),
    });
  }

  @autobind
  handleMailAlertSumbit(incident) {
    return () => {
      const { intl, location, credentials, userName } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const { projectName, projectOwner, startTimestamp, endTimestamp, type, instanceName, patternId } = incident;

      if (this.emailAlertModal) {
        this.emailAlertModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      this.props.updateLastActionInfo();
      return Promise.all([
        fetchPost(getEndpoint('globalalertemail', 2), {
          ...credentials,
          environmentName: environmentId,
          systemName: systemId,
          projectName,
          customerName: projectOwner || userName,
          startTime: startTimestamp,
          endTime: endTimestamp,
          type,
          instanceName,
          patternId,
          emailAddressList: JSON.stringify(this.emailAddressList),
          operation: 'Email',
        }),
      ])
        .then((results) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.emailAlertModal) this.emailAlertModal.destroy();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.emailAlertModal) {
            this.emailAlertModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  handleExportAlertClick({ event }) {
    const { intl } = this.props;
    this.exportAlertModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <div className="flex-row">
          <div className="flex-grow">External Service:</div>
          <Select
            size="small"
            style={{ width: 160 }}
            optionFilterProp="value"
            filterOption
            defaultValue="ServiceNow"
            onChange={(operation) => {
              this.exportAlertOperation = operation;
            }}
          >
            <Select.Option key="ServiceNow">ServiceNow</Select.Option>
          </Select>
        </div>
      ),
      okButtonProps: { disabled: false },
      onOk: this.handleExportAlertSumbit(event),
    });
  }

  @autobind
  handleExportAlertSumbit(incident) {
    return () => {
      const { intl, location, credentials, userName } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const { projectName, projectOwner, startTimestamp, endTimestamp, type, instanceName, patternId } = incident;

      if (this.exportAlertModal) {
        this.exportAlertModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      this.props.updateLastActionInfo();
      return fetchPost(getEndpoint('globalalertemail', 2), {
        ...credentials,
        environmentName: environmentId,
        systemName: systemId,
        projectName,
        customerName: projectOwner || userName,
        startTime: startTimestamp,
        endTime: endTimestamp,
        type,
        instanceName,
        patternId,
        operation: this.exportAlertOperation || 'ServiceNow',
      })
        .then((result) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.exportAlertModal) this.exportAlertModal.destroy();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.exportAlertModal) {
            this.exportAlertModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  handleChangePatternNameClick({ event }) {
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const actionIncident = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    this.setState({ showTakeLogActionModal: true, actionName: 'setPatternName', actionIncident });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    const { actionName } = this.state;
    let newState = {};
    if (actionName === 'setPatternName') {
      newState = { patternIdsFilter: undefined, patternIdFilter: undefined };
    }
    this.setState({ showTakeLogActionModal: false, actionName: null, actionIncident: null, ...newState }, () => {
      this.refresh();
    });
  }

  @autobind
  handleSoftwareUpdateContextClick({ event }) {
    this.setState({ showSoftwareUpdateContextModal: true });
  }

  @autobind
  goToKnowledgeBase(rowData, isGlobalKnowledgeBase) {
    return () => {
      const { location, userInfo } = this.props;
      const { customerName, systemId, startTime, endTime } = parseLocation(location);
      const item = rowData;

      const { projectOwner } = item || {};
      let { predictionRuleInfo } = item || {};
      try {
        predictionRuleInfo = JSON.parse(predictionRuleInfo);
      } catch (err) {
        // console.debug(err)
      }

      const { projectName, instanceName, patternId, uniqueKey, ruleType, userName } = predictionRuleInfo;
      let jumpMergeKey = `${instanceName}-${patternId}`;
      if (ruleType === 'COMPONENT') {
        jumpMergeKey = `${R.split(`${projectName}_`, instanceName || '')[1] || instanceName}-${patternId}`;
      }
      const jumpKey = `${projectName}-${instanceName}-${patternId}-${userName}-${ruleType}-${uniqueKey}`;

      let params = {
        environmentId: 'All',
        systemId,
        customerName,
        startTime,
        endTime,
        jumpProject:
          userInfo.isAdmin || userInfo.isLocalAdmin || projectOwner !== userInfo.userName
            ? `${projectName}@${projectOwner}`
            : projectName,
        jumpCauseLevel: ruleType === 'COMPONENT' ? 'component' : 'instance',
        jumpMergeKey,
        jumpKey,
      };

      if (isGlobalKnowledgeBase) {
        if (userInfo.isAdmin) {
          params = {
            ...params,
            systemId: 'globalKnowledgeBase',
            jumpProject: undefined,
            jumpCauseLevel: undefined,
            jumpMergeKey: `${uniqueKey}`,
          };
          window.open(buildUrl(BaseUrls.GlobalSystemKnowledgeBase, {}, params), '_blank');
        }
      } else {
        window.open(buildUrl(BaseUrls.GlobalSystemKnowledgeBase, {}, params), '_blank');
      }
    };
  }

  @autobind
  statusRenderer(rowData) {
    const { intl } = this.props;
    const { hasFuture, hasNotified, hasNotValidSrc, isMatchedIncident } = rowData;

    return (
      <div className="flex-row full-width">
        {!isMatchedIncident && hasFuture && (
          <Popover
            placement="right"
            title={null}
            content={intl.formatMessage(eventMessages.futureEvents)}
            trigger="hover"
          >
            <div
              style={{
                textAlign: 'center',
                background: 'gray',
                color: 'white',
                padding: '0 2px',
              }}
            >
              F
            </div>
          </Popover>
        )}
        {hasNotValidSrc && (
          <Popover
            placement="right"
            title={null}
            content={intl.formatMessage(logMessages.invalidPrediction)}
            trigger="hover"
          >
            <div
              style={{
                textAlign: 'center',
                background: 'gray',
                color: 'white',
                padding: '0 4px',
              }}
            >
              I
            </div>
          </Popover>
        )}
        {hasNotified && false && (
          <Popover content="Real time notification was sent" mouseEnterDelay={0.3} placement="top">
            <BellOutlined size="small" style={{ fontSize: 16 }} />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  renderTriggeredActions(rowData, index) {
    const { actionName, timestamp } = rowData;
    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {moment.utc(timestamp).format(Defaults.DateTimeFormat)}
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {actionName}
        </div>
      </div>
    );
  }

  @autobind
  getProjectNameList() {
    const { location, systemsMap } = this.props;
    const { systemId } = parseLocation(location);
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  handleExport() {
    this.setState({ showExportModal: true });
  }

  @autobind
  handleExportOk({ setLoading, exportCategory, email }) {
    if (exportCategory === 'CSV') {
      return this.handleExportCSV(setLoading);
    } else {
      return this.handleExportEmail(setLoading, email);
    }
  }

  @autobind
  async handleExportCSV(setLoading) {
    // start download data
    setLoading(true);
    await sleep(300);

    const { intl, location, userInfo, globalInfo, credentials } = this.props;
    const { patternNameMap, events } = this.state;
    const { startTime, endTime, systemId, environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const ownerUserName = get(systemInfo, 'ownerUserName');
    const systemName = systemInfo ? systemInfo.name : systemId;

    const fname = `${systemName} ${startTime}~${endTime} predicted incidents.csv`;
    let csvString = `${R.join(',', [
      'Matched incident',
      'Anomaly score',
      'Prediction time details',
      'Pattern ID/Name',
      'Project',
      'Component',
      'Instance',
      'Short description',
      'Likely root causes',
      'Lead Time Summary',
      'Corresponding Detected Incident',
    ])}\r\n`;

    const csvData = [];
    const eventListExport = R.sortWith([R.descend(R.prop('predictionTime'))], events);

    const incidents = R.filter((item) => item.isMatchedIncident, eventListExport);
    const incidentsCorrespondingMap = {};
    await Promise.all(
      R.map((item) => {
        // get incident pattern name
        const { patternId, instanceName, consolidatedTimePair, predictionSourceInfoList } = item;
        const { projectName } = item;
        const { sourceProjectName, sourceProjectOwner } = predictionSourceInfoList[0] || {};

        return fetchGet(getEndpoint('incidentrelation', 1), {
          ...credentials,
          customerName: ownerUserName,
          patternId,
          projectName,
          systemName: systemId,
          instanceName,
          consolidatedTimePair: JSON.stringify(consolidatedTimePair || []),
          sourceProjectName,
          sourceProjectOwner,
        });
      }, incidents),
    ).then((results) => {
      R.addIndex(R.forEach)((result, idx) => {
        let incidentRelationList = get(result, 'incidentRelationList', []);
        incidentRelationList = parseJSON(incidentRelationList);
        incidentsCorrespondingMap[incidents[idx].id] = incidentRelationList;
      }, results);
    });

    R.addIndex(R.forEach)((item, idx) => {
      // get incident pattern name
      const {
        id,
        patternId,
        patternName,
        anomalyLogInstance,
        category,
        projectOwner,
        consolidatedTimePair,
        rawData,
        rootCausesDetailsList,
        predictionSourceInfoList,
        isFixedIncident,
        isMatchedIncident,
      } = item;
      let { projectName } = item;
      projectName =
        userInfo.isAdmin || userInfo.isLocalAdmin || projectOwner !== userInfo.userName
          ? `${projectName}@${projectOwner}`
          : projectName;
      const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
      const incidentPatternName = get(patternNameMap, [key, patternId]);
      const activePatternName = incidentPatternName !== undefined ? incidentPatternName : patternName;
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName: activePatternName, patternId },
        { hasPrefix: true, hasFullName: true },
      );

      let shortDescription = '';
      if (category === 'log') {
        try {
          const rawDataJson = JSON.parse(rawData);
          shortDescription = JSON.stringify(rawDataJson, null, 4);
        } catch (error) {
          shortDescription = R.join(
            '\n',
            R.filter((x) => Boolean(x), (rawData || '').split('\n')),
          );
        }
      } else {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, rootCausesDetailsList);
        shortDescription = R.join('\n', summaryList);
      }

      const timeInfo = R.map((ct) => {
        return {
          predictionTime: ct.predictionTime ? moment.utc(ct.predictionTime).format(Defaults.DateTimeFormat) : 'N/A',
          predictionOccurrence: ct.predictionOccurrence
            ? moment.utc(ct.predictionOccurrence).format(Defaults.DateTimeFormat)
            : 'N/A',
        };
      }, consolidatedTimePair || []);

      const rootCauseList = [];
      R.forEach((sourceInfo) => {
        const {
          sourceDetail,
          probability,
          count,
          predictionTime,
          sourceProjectName,
          componentName,
          sourceInstanceName,
        } = sourceInfo;
        const { type, nid, content, avgValue, percentage, metricDirection } = sourceDetail || {};
        let data = '';
        if (type === 'Metric') {
          data = EventRenderers.BuildMetricAnomalySummary({
            event: { rootCauseMetric: content, metricValue: avgValue, pct: percentage, direction: metricDirection },
          });
        } else {
          data = content;
        }
        const { eventType } = CausalParser.getRelationLogType(type);
        rootCauseList.push({
          'Prediction time': moment.utc(predictionTime).format(Defaults.DateTimeFormat),
          'Pattern type': eventType,
          'Pattern ID': nid,
          'Source project': sourceProjectName,
          'Source component': componentName,
          'Source instance': sourceInstanceName,
          'Accumulated probability': probability,
          Count: count,
          'Short description': data,
        });
      }, predictionSourceInfoList || []);

      // get Corresponding incident info
      let leadTimeInfo = {};
      let detectedIncidentInfo = {};
      if (isMatchedIncident) {
        const incidentRelationList = incidentsCorrespondingMap[id] || [];
        const detectedIncident = R.find((item) => item.relationStatus === 'corresponding', incidentRelationList);
        if (detectedIncident) {
          const { predictionTime, relatedIncident } = detectedIncident;
          const { incidentKey, leadTime, rootCause, patternId, incidentData: content } = relatedIncident || {};
          const { timestamp } = incidentKey || {};
          const isMetric = Boolean(rootCause) && !content;
          let data = '';
          if (isMetric) {
            const { metricName, anomalyValue, pct, sign } = parseJSON(rootCause) || {};
            data = EventRenderers.BuildMetricAnomalySummary({
              event: { metricName, anomalyValue, pct, sign },
            });
          } else {
            data = content;
          }

          leadTimeInfo = {
            'Prediction time': predictionTime ? moment.utc(predictionTime).format(Defaults.DateTimeFormat) : '-',
            'Detection time':
              timestamp && !isFixedIncident ? moment.utc(timestamp).format(Defaults.DateTimeFormat) : '-',
            'Lead time': isFixedIncident
              ? 'Fixed'
              : leadTime
              ? CellRenderers.humanizeDuration({ period: leadTime, intl })
              : '-',
          };
          detectedIncidentInfo = {
            'Pattern ID': patternId,
            'Short description': data,
          };
        }
      }

      csvData.push(
        R.join(',', [
          item.isMatchedIncident ? 'yes' : 'no',
          item.anomalyRatio,
          `"${R.replace(/"/g, '""', JSON.stringify(timeInfo, null, 4))}"`,
          patternNameStr,
          item.projectName,
          item.componentListStr,
          item.instanceListStr,
          `"${R.replace(/"/g, '""', shortDescription)}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(rootCauseList, null, 4))}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(leadTimeInfo, null, 4))}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(detectedIncidentInfo, null, 4))}"`,
        ]),
      );
    }, eventListExport);
    csvString += R.join('\r\n', csvData);

    // download csv file
    downloadFile(csvString, fname);

    // close modal after
    setLoading(false);
    this.setState({ showExportModal: false });
  }

  @autobind
  handleExportEmail(setLoading, email) {
    const { intl, location, credentials, globalInfo } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime, systemId, environmentId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();

    setLoading(true);
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('incidentpredictiontimelines', 2), {
      ...credentials,
      environmentName: environmentId,
      customerName: systemInfo.ownerUserName,
      systemIds: JSON.stringify([{ id: systemId }]),
      startTime: startTimestamp,
      endTime: endTimestamp,
      operation: 'export',
      email: JSON.stringify(R.map((e) => R.trim(e), R.split(',', email || ''))),
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ showExportModal: false });
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${msg}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        setLoading(false);
      });
  }

  @autobind
  getSummaryObj(props) {
    const { credentials, location, globalInfo } = props;
    const { environmentId, startTime, endTime } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemIdsWithShare = R.map((item) => ({ id: item.id, customerName: item.ownerUserName }), systemList || []);
    if (startTime && endTime) {
      fetchPost(getEndpoint('dashboard-summary'), {
        ...credentials,
        systemIdsWithShare: JSON.stringify(systemIdsWithShare),
        startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
        endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      })
        .then((data) => {
          this.setState({ summaryObj: data || {} });
        })
        .catch((e) => {
          console.log(String(e));
        });
    }
  }

  @autobind
  handleChartOption(eventList) {
    const { location, globalInfo } = this.props;
    const query = parseLocation(location);
    const { environmentId, systemId, startTime, endTime } = query;

    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    const { allDayAnomalyList = [], filterTimestamps = [] } = this.chartTimeMap[`${startTime}-${endTime}`] || {};

    return getChartOption(this, eventList, allDayAnomalyList, filterTimestamps, instanceDisplayNameMap);
  }

  @autobind
  async exportPDF(systemInfo) {
    try {
      if (this.exportPDFing) {
        return;
      }
      if (this.tableList?.length > 2000) {
        message.info('the number of list is over 2000, please reduce the time range or filter conditions');
        return;
      }
      this.exportPDFing = true;
      this.setState({ exportPDFing: true, exportPDFPersent: 0 });
      const { location } = this.props;
      const { startTime, endTime } = parseLocation(location);
      const pdfFilename = `${systemInfo.name}-${startTime}-${endTime} Incident Prediction.pdf`;
      const pdfContents = document.createElement('div');
      pdfContents.setAttribute('id', 'divToPrint');
      document.body.appendChild(pdfContents);
      const height = this.tableList.length * 60 + 280;
      const searchBarHeight =
        (this.searchBarCountObj?.childCount || 0) * 32 + (this.searchBarCountObj?.headCount || 0) * 64;
      pdfContents.setAttribute(
        'style',
        `min-width: 210mm; 
        width: 100%; 
        height: 100%; 
        overflow: auto; 
        margin-left: auto; 
        margin-right: auto; 
        background-color: white;
        position: absolute;
        top: -100000px;
      `,
      );
      const clientContent = document.createElement('div');
      clientContent.setAttribute(
        'style',
        `width: 100%; 
        min-height: 100%; 
        height: ${Math.max(height, searchBarHeight) + 84}px; 
        margin-left: auto; 
        margin-right: auto; 
        background-color: white;
      `,
      );
      clientContent.setAttribute('class', 'flex-col');
      const exportContainer = document.getElementById('export-container');
      clientContent.appendChild(exportContainer);
      await pdfContents.appendChild(clientContent);
      await sleep(600);
      await downloadPdf(
        pdfFilename,
        [pdfContents],
        () => {
          this.setState({ exportPDFing: false, exportPDFPersent: 0 }, () => {
            document.getElementById('export-container-parent').appendChild(exportContainer);
            document.body.removeChild(pdfContents);
          });
          this.exportPDFing = false;
        },
        (percent) => {
          this.setState({ exportPDFPersent: percent });
        },
      );
    } catch (e) {
      console.log(e);
      this.setState({ exportPDFing: false, exportPDFPersent: 0 });
      this.exportPDFing = false;
    }
  }

  @autobind
  renderProgressBar(percent, successPercent) {
    const { intl } = this.props;
    if (percent >= 100) {
      return (
        <span style={{ fontSize: 14 }}>
          {intl.formatMessage(appFieldsMessages.waiting)}
          <SyncOutlined style={{ marginLeft: 2 }} size="small" spin />
        </span>
      );
    }
    return `${percent.toFixed(2)}%`;
  }

  render() {
    const { intl, location, userList, projects, isAdmin, isReadUser, userName, globalInfo } = this.props;
    const { isLocalAdmin, currentTheme, favorites, credentials, projectDisplayMap } = this.props;
    const { customerName, environmentId, systemId } = parseLocation(location);
    const { startTimeObj, endTimeObj, timeChange, disableRefresh, tooltipVisibleReload } = this.state;
    const { tooltipVisibleReloadMouseOver, summaryObj, events } = this.state;
    const { filterList, incident, activeKey, patternNameMap, activeEvent, actionIncident } = this.state;
    const { showFilterPanel, chartOption, zoomStartTime, zoomEndTime, hasTableData } = this.state;
    const { isLoaded, isLoadingParserData, isShowPredictionSourceInfo, incidentKnowledgeRuleMap } = this.state;
    const { groupEventList, filterLoading, exportPDFing } = this.state;

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    let incidentPatternName = null;
    let triggeredActionList = [];
    let validationTimeList = [];
    let isGlobalKnowledgeBase = false;
    if (incident) {
      const { patternId, anomalyLogInstance, category, projectOwner, predictionRuleInfo } = incident;
      let { projectName } = incident;
      projectName =
        isAdmin || isLocalAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
      const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
      incidentPatternName = get(patternNameMap, [key, patternId]);
      triggeredActionList = incident.triggeredActionList || [];
      validationTimeList = incident.validationTimeList || [];
      const globalKnowledgeBaseInfo = JSON.parse(predictionRuleInfo || '{}');
      isGlobalKnowledgeBase = R.includes('-1880685610', globalKnowledgeBaseInfo?.projectName || '');
    }

    const hasResult = filterList.length > 0;

    const { eventOccurrenceList, predictionCount } = reducerHandleEvents(groupEventList);
    this.tableList = eventOccurrenceList;

    const checkedEvent = R.filter((item) => item.checked, eventOccurrenceList || []);
    const hasErr = checkedEvent.length === 0;

    return (
      <Container
        className={`flex-grow flex-col flex-min-height flex-min-width ${isLoaded ? 'loading ' : ''}`}
        id="export-container-parent"
      >
        <div
          className={`full-height ${isShowPredictionSourceInfo ? 'display-none' : 'flex-col'}`}
          id="export-container"
        >
          <Container breadcrumb className="flex-row">
            <div className="flex-row" style={{ width: '100%' }}>
              <Container
                className="flex-row"
                style={{ width: '100%', justifyContent: 'space-between', alignItems: 'self-end' }}
              >
                <div className="flex-grow flex-row flex-center-align" style={{ alignItems: 'self-end' }}>
                  <PublicBuildCizizen
                    summaryObj={summaryObj}
                    systemList={systemList}
                    systemId={systemId}
                    handleSystemIdChange={this.props.handleSystemIdChange}
                    favorites={favorites}
                  />
                </div>
                <div className="flex-row flex-center-align">
                  <span style={{ fontWeight: 700, padding: '0 1em' }}>
                    {intl.formatMessage(appFieldsMessages.startDate)}
                  </span>
                  <DatePicker
                    size="small"
                    allowClear={false}
                    showToday={false}
                    value={startTimeObj}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                    onChange={this.handleStartTimeChange}
                  />
                  <span style={{ fontWeight: 700, padding: '0 1em' }}>
                    {intl.formatMessage(appFieldsMessages.endDate)}
                  </span>
                  <DatePicker
                    size="small"
                    allowClear={false}
                    showToday={false}
                    value={endTimeObj}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                    onChange={this.handleEndTimeChange}
                  />
                  {(isAdmin || isLocalAdmin) && (
                    <span style={{ fontWeight: 700, padding: '0 1em' }}>
                      {intl.formatMessage(appFieldsMessages.user)}
                    </span>
                  )}
                  {(isAdmin || isLocalAdmin) && (
                    <Select
                      showSearch
                      size="small"
                      value={customerName}
                      style={{ width: 100 }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={this.props.handleCustomerNameChange}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.map(
                        (item) => (
                          <Select.Option key={item.userName} value={item.userName}>
                            {item.userName}
                          </Select.Option>
                        ),
                        userList || [],
                      )}
                    </Select>
                  )}
                  <div
                    style={{ marginLeft: 8 }}
                    onMouseEnter={() => {
                      if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                        this.setState({ tooltipVisibleReloadMouseOver: true });
                    }}
                    onMouseLeave={() => {
                      if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
                    }}
                  >
                    <Tooltip
                      mouseEnterDelay={0.3}
                      placement="bottomRight"
                      visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                      title={
                        disableRefresh
                          ? 'Range of days <= 31'
                          : timeChange
                          ? intl.formatMessage(appMessages.clickToReload)
                          : null
                      }
                    >
                      <Button
                        size="small"
                        disabled={disableRefresh}
                        onClick={() => {
                          // set typeFilter if not current day
                          let typeFilter;
                          if (moment.utc().startOf('day') > endTimeObj) {
                            typeFilter = 'matchedIncident';
                          }
                          this.setState({ typeFilter }, () => {
                            this.props.handleRefresh({ startTimeObj, endTimeObj });
                          });
                        }}
                      >
                        {intl.formatMessage(appButtonsMessages.refresh)}
                      </Button>
                    </Tooltip>
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    <Button.Group style={{ marginRight: 8 }}>
                      <Tooltip title="Export PDF" placement="topRight">
                        <Button
                          size="small"
                          icon={<PDFIcon style={{ color: 'white', fontSize: 18 }} />}
                          onClick={() => this.exportPDF(R.find((system) => system.id === systemId, systemList))}
                          Tooltip="Export PDF"
                        />
                      </Tooltip>
                    </Button.Group>
                  </div>
                </div>
              </Container>
            </div>
          </Container>

          <Container
            className="global-view flex-grow flex-row flex-min-height content-bg flex-min-width overflow-hidden corner-10"
            style={{ margin: '0 8px' }}
          >
            <div
              className="flex-col full-height"
              style={{
                width: showFilterPanel ? 270 : 0,
                padding: '8px 0 8px 8px',
                position: 'relative',
                flexShrink: 0,
              }}
            >
              <Tooltip title={showFilterPanel ? 'Close filters' : 'Show filters'} mouseEnterDelay={0.3} placement="top">
                <div
                  className="clickable"
                  style={{ position: 'absolute', right: -17, top: 5, fontSize: 18, color: 'gray', zIndex: 99 }}
                  onClick={() => {
                    this.setState({ showFilterPanel: !showFilterPanel });
                  }}
                >
                  <RightSquareOutlined rotate={showFilterPanel ? 180 : 0} />
                </div>
              </Tooltip>
              <Card
                style={{ width: '100%', height: '100%', display: showFilterPanel ? 'unset' : 'none' }}
                bodyStyle={{ height: '100%', padding: 8, overflowY: 'auto' }}
              >
                <Container className="flex-col flex-min-height flex-min-width full-height">
                  {this.renderTopBar({ isLoadingParserData })}
                </Container>
              </Card>
            </div>

            <Container className="flex-grow block flex-row flex-min-height" style={{ padding: '8px 8px 0 0px' }}>
              <Spin
                spinning={isLoadingParserData || filterLoading}
                wrapperClassName="full-width full-height spin-full-width"
              >
                {!isLoadingParserData && !hasTableData && (
                  <div className="flex-grow flex-row flex-center-align flex-center-justify content-bg">
                    <Empty
                      imageStyle={{ height: 200 }}
                      description={
                        <span className="flex-col">
                          <span style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 8 }}>No results found</span>
                          <span style={{ fontSize: 14 }}>Try adjusting the date range</span>
                        </span>
                      }
                    />
                  </div>
                )}
                {!isLoadingParserData && !isLoaded && hasTableData && (
                  <div className="flex-col full-width flex-grow">
                    <div style={{ height: 130 }} className="log-entries-echart">
                      <AutoSizer>
                        {({ width, height }) => (
                          <EChart
                            setRef={(chart) => setChartRef(this.systemChartRef, systemId, chart)}
                            width={width}
                            height={height}
                            option={chartOption}
                            renderer="svg"
                            onEvents={{
                              click: onSystemChartClick(this, systemId),
                              datazoom: handleChartZoom(this, systemId),
                              restore: handleChartRestore(this, systemId),
                              rendered: handleChartFinish(this, systemId),
                            }}
                          />
                        )}
                      </AutoSizer>
                    </div>
                    <div className="flex-row flex-center-align" style={{ margin: '0 0 8px 8px' }}>
                      {zoomStartTime && zoomEndTime && (
                        <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                          <div style={{ marginRight: 8 }}>{`${moment
                            .utc(zoomStartTime)
                            .format(Defaults.ShortDateTimeFormat)} ~ ${moment
                            .utc(zoomEndTime - 10 * 60 * 1000)
                            .format(Defaults.ShortDateTimeFormat)}`}</div>
                          <div className="font-14 clickable" onClick={() => customizeRestore(this, systemId)}>
                            <CloseCircleOutlined />
                          </div>
                        </div>
                      )}
                      <div className="flex-grow" />

                      <Button
                        size="small"
                        onClick={this.handleExport}
                        style={{ marginRight: 8 }}
                        disabled={events.length === 0}
                      >
                        {intl.formatMessage(appButtonsMessages.export)}
                      </Button>
                      {isReadUser ? (
                        <Popover
                          content={intl.formatMessage(eventMessages.isReadUserDisable)}
                          mouseEnterDelay={0.3}
                          placement="left"
                        >
                          <Button size="small" disabled type="primary">
                            {intl.formatMessage(appButtonsMessages.delete)}
                          </Button>
                        </Popover>
                      ) : (
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to delete this prediction?"
                          onConfirm={(e) => this.handlePredictionDelete(checkedEvent)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button size="small" disabled={hasErr} type="primary">
                            {intl.formatMessage(appButtonsMessages.delete)}
                          </Button>
                        </Popconfirm>
                      )}
                    </div>
                    {tableViewList(false, this, intl, eventOccurrenceList, predictionCount, this.renderListItem)}
                  </div>
                )}
              </Spin>
            </Container>
          </Container>
        </div>
        {isShowPredictionSourceInfo && !isLoaded && hasResult && (
          <div className="flex-col full-height">
            <Container breadcrumb className="flex-row" style={{ marginBottom: 0 }}>
              <div className="flex-row flex-center-align" style={{ width: 'fit-content' }}>
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => this.setState({ isShowPredictionSourceInfo: false })}
                >
                  {intl.formatMessage(appMenusMessages.globalSystemPrediction)}
                </Button>
                <div style={{ marginLeft: 4 }}>{`/ ${intl.formatMessage(appFieldsMessages.details)}`}</div>
              </div>
            </Container>
            <div
              className="flex-grow flex-min-height flex-col content-bg flex-min-width corner-10"
              style={{ margin: '0 16px', padding: 8 }}
            >
              {incident && (
                <Spin spinning={false} wrapperClassName="full-height spin-full-height">
                  <Tabs
                    type="card"
                    size="small"
                    className="full-height ant-tabs-content-full-height flex-col tabs-extra-full"
                    activeKey={activeKey}
                    onChange={(activeKey) => this.setState({ activeKey })}
                    tabBarExtraContent={
                      <div className="full-height flex-row flex-center-align pl-1">
                        {incident.generateFromRule && (
                          <Popover
                            content={
                              <RenderIncidentKnowledgeRule
                                intl={intl}
                                credentials={credentials}
                                projectDisplayMap={projectDisplayMap}
                                projects={projects}
                                incident={incident}
                                incidentKnowledgeRule={incidentKnowledgeRuleMap[incident?.id]}
                                setIncidentKnowledgeRule={(setIncidentKnowledgeRule) => {
                                  this.setState({
                                    incidentKnowledgeRuleMap: {
                                      ...incidentKnowledgeRuleMap,
                                      [incident?.id]: setIncidentKnowledgeRule,
                                    },
                                  });
                                }}
                                updateLastActionInfo={updateLastActionInfo}
                                currentTheme={currentTheme}
                                instanceDisplayNameMap={instanceDisplayNameMap}
                              />
                            }
                            mouseEnterDelay={0.3}
                            placement="bottom"
                            destroyTooltipOnHide
                          >
                            <Button
                              size="small"
                              icon={<KnowledgeBaseIcon />}
                              style={{ marginLeft: 8 }}
                              onClick={this.goToKnowledgeBase(incident, isGlobalKnowledgeBase)}
                            >
                              Knowledge base details
                            </Button>
                          </Popover>
                        )}
                      </div>
                    }
                  >
                    <Tabs.TabPane
                      tab={intl.formatMessage(appFieldsMessages.incident)}
                      key="incident"
                      style={{ paddingTop: 8, border: '1px solid var(--border-color-split)', borderTop: 0 }}
                    >
                      <div className="flex-col full-height">
                        <div className="flex-col flex-grow overflow-hidden" style={{ paddingBottom: 8 }}>
                          <PredictionRootCauseRCA incident={incident} />
                        </div>
                      </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={
                        <div>
                          {intl.formatMessage(logMessages.triggeredActions)}
                          <Tag style={{ borderRadius: 100, marginLeft: 4, fontWeight: 'bold' }}>
                            {triggeredActionList.length}
                          </Tag>
                        </div>
                      }
                      className="overflow-y-auto"
                      key="triggeredActions"
                      style={{ paddingTop: 8, border: '1px solid var(--border-color-split)', borderTop: 0 }}
                    >
                      {triggeredActionList.length === 0 && (
                        <div style={{ marginBottom: 16 }}>
                          <Alert
                            message=""
                            description={intl.formatMessage(logMessages.noTriggeredActionsFound)}
                            type="info"
                            showIcon
                          />
                        </div>
                      )}
                      {triggeredActionList.length > 0 && (
                        <div className="flex-row" style={{ marginBottom: 4, lineHeight: '24px' }}>
                          <span style={{ fontSize: 14, fontWeight: 700 }}>
                            {intl.formatMessage(logMessages.triggeredActions)}:
                          </span>
                        </div>
                      )}
                      {triggeredActionList.length > 0 && (
                        <div className="event-list flex-grow flex-col flex-min-height" style={{ marginBottom: 16 }}>
                          <div className="event-list-header" style={{ height: this.listHeaderHeight, width: '100%' }}>
                            <div className="header-column" style={{ width: 120, flex: 1 }}>
                              {intl.formatMessage(eventActionMessages.triggeredTime)}
                            </div>
                            <div className="header-column" style={{ width: 120, flex: 1 }}>
                              {intl.formatMessage(eventActionMessages.actionName)}
                            </div>
                          </div>
                          <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                            {R.addIndex(R.map)(
                              (rowData, index) => this.renderTriggeredActions(rowData, index),
                              triggeredActionList,
                            )}
                          </div>
                        </div>
                      )}
                      {validationTimeList.length > 0 && (
                        <div className="flex-row" style={{ marginBottom: 4, lineHeight: '24px' }}>
                          <span style={{ fontSize: 14, fontWeight: 700 }}>
                            {intl.formatMessage(logMessages.validationTime)}:
                          </span>
                        </div>
                      )}
                      {validationTimeList.length > 0 && (
                        <div className="event-list flex-grow flex-min-height flex-col" style={{ marginBottom: 16 }}>
                          <div className="event-list-header" style={{ height: this.listHeaderHeight, width: '100%' }}>
                            <div className="header-column" style={{ width: 120, flex: 1 }}>
                              {intl.formatMessage(appFieldsMessages.startTime)}
                            </div>
                            <div className="header-column" style={{ width: 120, flex: 1 }}>
                              {intl.formatMessage(appFieldsMessages.endTime)}
                            </div>
                          </div>
                          <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                            {R.addIndex(R.map)((rowData, index) => {
                              const { startTime, endTime } = rowData;
                              return (
                                <div
                                  key={index}
                                  className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                                  style={{ minHeight: 40 }}
                                >
                                  <div className="row-column" style={{ width: 120, flex: 1 }}>
                                    {moment.utc(startTime).format(Defaults.DateTimeFormat)}
                                  </div>
                                  <div className="row-column" style={{ width: 120, flex: 1 }}>
                                    {moment.utc(endTime).format(Defaults.DateTimeFormat)}
                                  </div>
                                </div>
                              );
                            }, validationTimeList)}
                          </div>
                        </div>
                      )}
                    </Tabs.TabPane>
                  </Tabs>
                </Spin>
              )}
            </div>
          </div>
        )}

        {this.state.showExportModal && (
          <ExportModal
            intl={intl}
            onOk={this.handleExportOk}
            onClose={() => this.setState({ showExportModal: false })}
          />
        )}
        {this.state.showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={this.state.actionName}
            incident={actionIncident}
            incidentPatternName={incidentPatternName}
            projectName={actionIncident.fullProjectName}
            instanceGroup={environmentId}
            eventType={actionIncident.type}
            onClose={() => this.setState({ showTakeLogActionModal: false, actionName: null, actionIncident: null })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {this.state.showSoftwareUpdateContextModal && (
          <GlobalRenderers.SoftwareUpdateContextModal
            intl={intl}
            incident={incident}
            onCancel={() => this.setState({ showSoftwareUpdateContextModal: false })}
          />
        )}

        {this.state.showPatternConfigModel && (
          <PatternConfigModal
            title={intl.formatMessage(eventMessages.investigationPatternConfig)}
            intl={intl}
            incident={activeEvent}
            instanceName={this.state.selectInstance}
            activeKey="incident"
            projectName={`${activeEvent?.projectName}@${activeEvent?.projectOwner}`}
            onClose={() => this.setState({ showPatternConfigModel: false, activeEvent: null })}
          />
        )}

        {this.state.showRecommendationsGPT && (
          <RecommendationsGPT
            incident={this.state.activeRecommendation}
            incidentType="Prediction"
            title={intl.formatMessage(logMessages.predictionSummary)}
            onCancel={() => this.setState({ showRecommendationsGPT: false, activeRecommendation: null })}
          />
        )}

        {this.state.showRecommendationModal && (
          <RecommendationPredictionModal
            incident={activeEvent}
            onClose={() => this.setState({ showRecommendationModal: false, activeEvent: null })}
          />
        )}
        {this.state.configNotesModal && (
          <ConfigNotesPredictionModal
            incident={activeEvent}
            instanceDisplayNameMap={instanceDisplayNameMap}
            onClose={() => this.setState({ configNotesModal: false, activeEvent: null })}
          />
        )}
        {!!exportPDFing && (
          <Container
            fullHeight
            className="flex-grow flex-col flex-center-align flex-center-justify"
            style={{ padding: 0 }}
          >
            <Progress
              type="circle"
              percent={(this.state.exportPDFPersent || 0) * 100}
              format={this.renderProgressBar}
            />
          </Container>
        )}
      </Container>
    );
  }
}

const GlobalSystemIncidentPredictionView = injectIntl(GlobalSystemIncidentPredictionViewCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { systemsMap, projects, projectDisplayMap, globalInfo, favorites, timezoneOffset } = state.app;
    let { userList } = state.app;
    const { currentTheme } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isLocalAdmin, isReadUser, userName } = state.auth.userInfo;
    const { globalSystemInfo } = state.dashboard;

    return {
      credentials,
      userList,
      userInfo,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      userName,
      location,
      projects,
      projectDisplayMap,
      systemsMap,
      globalInfo,
      globalSystemInfo,
      currentTheme,
      favorites,
      timezoneOffset,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(GlobalSystemIncidentPredictionView);
