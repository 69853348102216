import React from 'react';
import * as R from 'ramda';
import { DeleteOutlined, FolderViewOutlined } from '@ant-design/icons';
import { eventMessages } from '../../../../src/common/metric/messages';
import { Popover } from '../../../../src/lib/fui/react';

const textColor = {
  wait: '#d48806', // 等待
  analysis: '#1677ff', // 文件解析中
  progress: '#1677ff', // 进行中
  success: '#52c41a', // 成功
  error: 'var(--red)', // 失败
};

export default function FileListDisplay({ fileList, resetFileState, viewFileContent, removeRile, intl }: Object) {
  return (
    <div className="flex-col" style={{ overflowY: 'auto' }}>
      <div style={{ padding: '8px 0', fontWeight: 'bold' }}>List of files that failed to upload:</div>
      <div
        className="flex-grow"
        style={{
          width: '100%',
          border: '1px solid var(--virtualized-table-border-color)',
          overflowY: 'auto',
          background: 'var(--virtualized-table-bg)',
          cursor: 'pointer',
        }}
      >
        {R.addIndex(R.map)((item, index) => {
          const { state, name, uid, errMessage } = item;
          return (
            <div key={uid || index}>
              {state === 'error' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px',
                    color: textColor[state],
                    borderBottom: '1px solid var(--virtualized-table-border-color)',
                  }}
                >
                  <div style={{ flex: 1, fontSize: 14, minWidth: 0 }}>
                    <div className="flex-row">
                      <div className="flex-grow hidden-line-with-ellipsis bold">{name}</div>
                      <Popover
                        title={null}
                        content={intl.formatMessage(eventMessages.viewFileContent)}
                        mouseEnterDelay={0.3}
                        placement="left"
                      >
                        <div style={{ marginRight: 8, fontSize: 14 }} onClick={() => viewFileContent(item)}>
                          <FolderViewOutlined style={{ color: textColor[state] }} />
                        </div>
                      </Popover>
                      <Popover
                        title={null}
                        content={intl.formatMessage(eventMessages.deleteFile)}
                        mouseEnterDelay={0.3}
                        placement="right"
                      >
                        <div style={{ fontSize: 14 }} onClick={() => removeRile(uid)}>
                          <DeleteOutlined style={{ color: textColor[state] }} />
                        </div>
                      </Popover>
                    </div>
                    {errMessage && (
                      <div className="flex-row" style={{ fontSize: 12 }}>
                        <Popover
                          title={null}
                          content={<div style={{ maxWidth: 300, wordBreak: 'break-word' }}>{errMessage}</div>}
                          mouseEnterDelay={0.3}
                          placement="top"
                        >
                          <span className="hidden-line-with-ellipsis">{`${intl.formatMessage(
                            eventMessages.failReason,
                          )}: ${errMessage}`}</span>
                        </Popover>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        }, fileList)}
      </div>
    </div>
  );
}
