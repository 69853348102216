/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Spin, Form, Input, Select, Alert, message, Button } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { buildUrl } from '../../../common/utils';
import { BaseUrls } from '../../app/Constants';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  incident: Object,
  // eslint-disable-next-line
  projectName: String,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class ReportServiceNowModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { rawData, rootCausesDetailsList } = props.incident || {};
    this.state = {
      isLoading: false,
      isSubmiting: false,
      errMessage: undefined,

      assignedID: undefined,
      callerID: undefined,
      urgency: undefined,
      impact: undefined,
      shortDescription: '',
      rawData: rawData || this.parseRootCauseList(rootCausesDetailsList),
    };
    this.usersOptions = [];
    this.urgencyOptions = [
      { value: 1, label: '1 - High' },
      { value: 2, label: '2 - Medium' },
      { value: 3, label: '3 - Low' },
    ];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.incident !== nextProps.incident) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    this.setState({ isLoading: true });
    const { intl, credentials, incident } = this.props;
    const { projectOwner } = incident;

    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('servicenowusers'), {
      ...credentials,
      customerName: projectOwner,
    })
      .then((d) => {
        const { success, message, serviceNowUsers } = d || {};
        let errMessage;
        let usersOptions = [];
        if (success) {
          usersOptions = serviceNowUsers || [];
          usersOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))], usersOptions);
        } else {
          errMessage = message;
        }
        this.usersOptions = usersOptions;
        this.setState({ isLoading: false, errMessage });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, errMessage: JSON.stringify(err) });
      });
  }

  @autobind
  parseRootCauseList(rootCausesDetailsList) {
    const contentList = [];
    R.forEach((item) => {
      const { instanceDown, processCrash } = item;
      const { appName, componentName, rootCauseSource } = item;
      let { instanceId, rootCauseMetric, metricValue, pct, direction } = item;
      const { instanceName, metricName, anomalyValue, percentage, sign } = item;

      if (!instanceId && instanceName) instanceId = instanceName;
      if (!rootCauseMetric && metricName) rootCauseMetric = metricName;
      if (!metricValue && anomalyValue) metricValue = anomalyValue;
      if (!direction && sign) direction = sign;
      if (!pct && percentage) pct = percentage;

      if (pct && Number(pct) < 0) direction = 'lower';
      if (direction === 'positive') {
        direction = 'higher';
      } else if (direction === 'negative') {
        direction = 'lower';
      }
      const name =
        appName ||
        (componentName && componentName.indexOf(instanceId) === -1 ? `${instanceId} (${componentName})` : instanceId);

      if (instanceDown) {
        contentList.push(`Instance: ${name} Status: Missing Data`);
      } else {
        let content = `${rootCauseMetric}(${numeral(metricValue).format(
          metricValue > 1 ? '0,0' : '0,0.0[00000]',
        )}) is ${numeral(Math.abs(Number(pct) / 100)).format('0.0%')} ${direction} than ${
          rootCauseSource || 'normal'
        } at ${name}`;
        if (processCrash) {
          content += ` and process crashed`;
        }
        contentList.push(content);
      }
    }, rootCausesDetailsList);
    return R.join('\n', contentList);
  }

  @autobind
  handleSubmit() {
    this.setState({ isSubmiting: true });
    const { intl, credentials, onClose, incident } = this.props;
    const { assignedID, callerID, urgency, impact, shortDescription, rawData } = this.state;
    const { projectName, patternId, anomalyScore, anomalyRatio, projectOwner } = incident;

    this.props.updateLastActionInfo();
    fetchPost(
      getEndpoint('servicenowticket'),
      {
        ...credentials,
        assignedID,
        callerID,
        urgency,
        impact,
        shortDescription,

        // info
        projectName,
        patternId,
        anomalyScore: anomalyScore || anomalyRatio,
        rawData,
        customerName: projectOwner,
      },
      {},
      false,
    )
      .then((d) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmiting: false, errMessage: undefined });
        onClose();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMessage: JSON.stringify(err) });
      });
  }

  render() {
    const { intl, onClose } = this.props;
    const { isLoading, isSubmiting, errMessage } = this.state;
    const { assignedID, callerID, urgency, impact, shortDescription, rawData } = this.state;

    const hasError = !callerID || !shortDescription || !rawData;
    return (
      <Modal
        title={intl.formatMessage(eventMessages.reportServiceNow)}
        width={750}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            {errMessage && (
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Alert
                  message={errMessage}
                  description={
                    <span>
                      Please go to{' '}
                      <Button
                        type="link"
                        style={{ margin: 0, padding: 0 }}
                        onClick={() => window.open(buildUrl(BaseUrls.ExternalServiceSetting, {}, {}), '_blank')}
                      >
                        external service
                      </Button>{' '}
                      page and add ServiceNow credentials.
                    </span>
                  }
                  type="error"
                  showIcon
                />
              </Form.Item>
            )}

            <Form.Item label="Assigned to">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={assignedID}
                onChange={(assignedID) => this.setState({ assignedID })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.sys_id}>{item.name}</Select.Option>
                  ),
                  this.usersOptions || [],
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Caller"
              validateStatus={!callerID ? 'error' : 'success'}
              help={!callerID ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={callerID}
                onChange={(callerID) => this.setState({ callerID })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.sys_id}>{item.name}</Select.Option>
                  ),
                  this.usersOptions || [],
                )}
              </Select>
            </Form.Item>
            <Form.Item label="Urgency">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={urgency}
                onChange={(urgency) => this.setState({ urgency })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ),
                  this.urgencyOptions || [],
                )}
              </Select>
            </Form.Item>
            <Form.Item label="Impact">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={impact}
                onChange={(impact) => this.setState({ impact })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ),
                  this.urgencyOptions || [],
                )}
              </Select>
            </Form.Item>

            <Form.Item
              label="Short description"
              validateStatus={!shortDescription ? 'error' : 'success'}
              help={!shortDescription ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={shortDescription} onChange={(e) => this.setState({ shortDescription: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Description"
              validateStatus={!rawData ? 'error' : 'success'}
              help={!rawData ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
                value={rawData}
                onChange={(e) => this.setState({ rawData: e.target.value })}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const ReportServiceNowModal = injectIntl(ReportServiceNowModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials } = state.auth;
    return {
      location,
      loadStatus,
      credentials,
    };
  },
  { updateLastActionInfo },
)(ReportServiceNowModal);
