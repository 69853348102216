/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2021
 * *****************************************************************************
 * */

import React, { useState, useRef, useCallback } from 'react';
import { Modal as ModalCore } from 'antd';
import Draggable from 'react-draggable';

// eslint-disable-next-line react/prop-types
const Modal = ({ title, ...rest }) => {
  const draggleRef = useRef();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  const onStart = useCallback((event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      // bottom: clientHeight - (targetRect?.bottom - uiData?.y) + (targetRect.height - 55), // deep in to bottom
    });
  }, []);

  return (
    <ModalCore
      {...rest}
      keyboard={false}
      maskClosable={false}
      title={
        <div
          style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          // fix eslintjsx-a11y/mouse-events-have-key-events
          // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
          onFocus={() => {}}
          onBlur={() => {}}
          // end
        >
          {title}
        </div>
      }
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    />
  );
};

Modal.info = ModalCore.info;
Modal.success = ModalCore.success;
Modal.error = ModalCore.error;
Modal.warning = ModalCore.warning;
Modal.confirm = ModalCore.confirm;

export default Modal;
