import React from 'react';
import KubernetesAverageCount from '../components/KubernetesAverageCount';

export default function AverageInstanceCount(props: Object) {
  return (
    <KubernetesAverageCount
      {...props}
      title="Instances"
      subTitle="Instances"
      isInstance
      cacheKey="kubernetesInstance"
    />
  );
}
