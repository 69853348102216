import React from 'react';
import { Alert, Button } from 'antd';

import { BaseUrls } from '../../../app/Constants';
import { buildUrl } from '../../../../common/utils';

import { projectWizardMessages } from '../../../../common/settings/messages';

// Configure component
import InsightAgentSetting from './InsightAgentSetting';
import KubernetesSetting from './KubernetesSetting';
import KubernetesSettingNew from './KubernetesSettingNew';
import AWSCloudSetting from './AWSCloudSetting';
import AWSCloudWatchSetting from './AWSCloudWatchSetting';
import DataDogSetting from './DataDogSetting';
import DataDogBatchSetting from './DataDogBatchSetting';
import PagerDutySetting from './PagerDutySetting';
import ServiceNowSetting from './ServiceNowSetting';
import ZendeskSetting from './ZendeskSetting';
import SumoLogicSetting from './SumoLogicSetting';
import NewRelicSetting from './NewRelicSetting';
import GoogleAdsSetting from './GoogleAdsSetting';
import GoogleCloudSetting from './GoogleCloudSetting';
import GoogleBigQuerySetting from './GoogleBigQuerySetting';
import GoogleCloudCostSetting from './GoogleCloudCostSetting';
import GoogleCloudPubSubSetting from './GoogleCloudPubSubSetting';
import MicrosoftAzureSetting from './MicrosoftAzureSetting';
import JDBCSetting from './JDBCSetting';
import SnowSetting from './SnowSetting';
import ShadowProjectSetting from './ShadowProjectSetting';
import AzureCostSetting from './AzureCostSetting';
import DynatraceSetting from './DynatraceSetting';
import NagiosSetting from './NagiosSetting';

// Agent
import DockerSvg from '../../../../../images/services/docker-icon.svg';
import CAdvisorImg from '../../../../../images/services/cadvisor.png';
import SysdigSvg from '../../../../../images/services/sysdig-icon.svg';
import CassandraSvg from '../../../../../images/services/apache-cassandra-icon.svg';
import SQLServerSvg from '../../../../../images/services/microsoft-sql-server-icon.svg';
import MySQLSvg from '../../../../../images/services/mysql-icon.svg';
import MariaDBSvg from '../../../../../images/services/mariadb-icon.svg';
import OpenTSDBImg from '../../../../../images/services/OpenTSDB.png';
import CloudTrailSvg from '../../../../../images/services/aws-cloudtrail.svg';
import ElasticSvg from '../../../../../images/services/elasticsearch.svg';
import FluentdSvg from '../../../../../images/services/fluentd-icon.svg';
import SplunkSvg from '../../../../../images/services/splunk-icon.svg';
import SentrySvg from '../../../../../images/services/sentry-icon.svg';
import OpenTelemetrySvg from '../../../../../images/services/OpenTelemetry-icon.svg';
import JenkinsSvg from '../../../../../images/services/jenkins-icon.svg';
import GitHubActionsSvg from '../../../../../images/services/github.svg';
// import AWSSvg from '../../../../../images/services/amazon-web-services-icon.svg';
import CollectdSvg from '../../../../../images/services/collectd.svg';
import JolokiaImg from '../../../../../images/services/jolokia-logo.png';
import PrometheusSvg from '../../../../../images/services/prometheus-icon.svg';
import StatsDImg from '../../../../../images/services/statsd.png';
import ZabbixSvg from '../../../../../images/services/zabbix-icon.svg';
import ExtrahopSvg from '../../../../../images/services/extrahop.svg';
import OsmosysImg from '../../../../../images/services/osmosys-icon.png';
import NagiosSvg from '../../../../../images/services/nagios-icon.svg';
import VMWareVCenterSvg from '../../../../../images/services/vmware.svg';
import ELKMonitoringSvg from '../../../../../images/services/elastic-search-icon.svg';
import KafkaSvg from '../../../../../images/services/apache-kafka-icon.svg';

// Service
import KubernetesSvg from '../../../../../images/services/kubernetes-icon.svg';
import CloudWatchSvg from '../../../../../images/services/aws-icon.svg';
import DataDogSvg from '../../../../../images/services/datadog-icon.svg';
import PagerDutySvg from '../../../../../images/services/pagerduty-icon.svg';
import ServiceNowSvg from '../../../../../images/services/servicenow-icon.svg';
import ZendeskSvg from '../../../../../images/services/zendesk-icon.svg';
import SumoLogicSvg from '../../../../../images/services/sumo-logic-icon.svg';
import NewRelicSvg from '../../../../../images/services/new-relic-icon.svg';
import GoogleAdsSvg from '../../../../../images/services/google-ads-official.svg';
import GoogleCloudSvg from '../../../../../images/services/google-cloud-icon.svg';
import GoogleBigQuerySvg from '../../../../../images/services/bigquery-icon.svg';
import GoogleCloudPubSubSvg from '../../../../../images/services/google-cloud-pub-sub-logo.svg';
import MicrosoftAzureSvg from '../../../../../images/services/microsoft-azure-icon.svg';
import SnowFlakeSvg from '../../../../../images/services/snowflake.svg';
import DynatraceSvg from '../../../../../images/services/dynatrace-icon.svg';
import JDBCSvg from '../../../../../images/services/java-icon.svg';
import LogoSvg from '../../../../../images/favicon.svg';

// not used for now
// import AppDynamicsSvg from '../../../../../images/services/appdynamics-icon.svg';
// import AWSLambdaSvg from '../../../../../images/services/lambda-function.svg';
// import ExasolSvg from '../../../../../images/services/exasol.svg';
// import OracleDatabaseSvg from '../../../../../images/services/oracle-icon.svg';

type PropsDocumentPage = {
  intl: Object,
};

const DocumentPageInsightAgent = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent" target="_blank" rel="noreferrer">
            {str}
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageMetricFileReplay = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/metricFileReplay"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/metricFileReplay
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageLogFileReplay = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/logFileReplay"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/logFileReplay
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageDocker = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/docker_remote_api"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/docker_remote_api
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageCAdvisor = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/cadvisor" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/cadvisor
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageSysdig = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/sysdig" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/sysdig
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageCassandra = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/jolokia-cassandra-stream"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/jolokia-cassandra-stream
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageSQLServer = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/mssql" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/mssql
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageMySQL = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/mysql" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/mysql
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageMariaDB = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/mariadb" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/mariadb
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageOpenTSDB = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/opentsdb" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/opentsdb
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageCloudTrail = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/aws-cloudtrail"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/aws-cloudtrail
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageElastic = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/elasticsearch_collector"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/elasticsearch_collector
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageFluentd = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/td-agent" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/td-agent
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageSplunk = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      For details, please visit{' '}
      <a href="https://splunkbase.splunk.com/app/3281" target="_blank" rel="noreferrer">
        https://splunkbase.splunk.com/app/3281
      </a>
      .
    </div>
  );
};
const DocumentPageSentry = ({ intl }: PropsDocumentPage) => {
  const handleProjectMappingClick = () => {
    window.open(buildUrl(BaseUrls.ExternalServiceSetting, {}, { defaultActiveModal: 'sentry' }), '_blank');
  };
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      <p>
        For details, please visit{' '}
        <a href="https://insightfinder.com/insightfinder-sentry-integration" target="_blank" rel="noreferrer">
          https://insightfinder.com/insightfinder-sentry-integration
        </a>
        .
      </p>
      <Alert
        message="Informational Notes"
        description={
          <div>
            To make the data ingestion work, please set{' '}
            <Button type="link" style={{ padding: 0 }} onClick={handleProjectMappingClick}>
              project mapping
            </Button>{' '}
            info after create sentry project.
          </div>
        }
        type="info"
        showIcon
      />
    </div>
  );
};
const DocumentPageOpenTelemetry = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      For details, please visit{' '}
      <a href="https://opentelemetry.io" target="_blank" rel="noreferrer">
        https://opentelemetry.io
      </a>
      .
    </div>
  );
};
const DocumentPageJenkins = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/jenkins" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/jenkins
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageGitHubActions = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      <p>{intl.formatMessage(projectWizardMessages.GitActionsIntro)}</p>
      <p>
        For details, please visit{' '}
        <a href="https://docs.github.com/en/actions" target="_blank" rel="noreferrer">
          https://docs.github.com/en/actions
        </a>
        .
      </p>
    </div>
  );
};

// const DocumentPageAWS = ({ intl }: PropsDocumentPage) => {
//   return (
//     <div style={{ marginTop: 16,marginBottom:10 }}>
//       {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
//         b: (str) => <b>{str}</b>,
//         a: (str) => (
//           <a
//             href="https://github.com/insightfinder/InsightAgent/tree/master/ec2monitoring"
//             target="_blank"
//             rel="noreferrer"
//           >
//             https://github.com/insightfinder/InsightAgent/tree/master/ec2monitoring
//           </a>
//         ),
//       })}
//     </div>
//   );
// };
const DocumentPageCollectd = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/collectd" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/collectd
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageJolokia = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/jolokia" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/jolokia
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPagePrometheus = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/prometheus-new"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/prometheus-new
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageStatsD = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/insightfinder-statsd-backend"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/insightfinder-statsd-backend
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageZabbix = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/zabbix" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/zabbix
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageExtrahop = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/extrahop" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/extrahop
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageOsmosys = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/osmosys" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/osmosys
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageNagios = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/nagios" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/nagios
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageVMWareVCenter = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a href="https://github.com/insightfinder/InsightAgent/tree/master/vCenter" target="_blank" rel="noreferrer">
            https://github.com/insightfinder/InsightAgent/tree/master/vCenter
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageELKMonitoring = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/elkmetrics"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/elkmetrics
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageKafkaLog = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/kafka_log"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/kafka_log
          </a>
        ),
      })}
    </div>
  );
};
const DocumentPageKafkaMetric = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.InsightAgentIntro, {
        b: (str) => <b>{str}</b>,
        a: (str) => (
          <a
            href="https://github.com/insightfinder/InsightAgent/tree/master/kafka_metric"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/insightfinder/InsightAgent/tree/master/kafka_metric
          </a>
        ),
      })}
    </div>
  );
};

const DocumentPageKubernetes = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.KubernetesIntro)}</div>
  );
};
const DocumentPageAWSCloudWatch = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.AWSCloudWatchIntro)}
    </div>
  );
};
const DocumentPageAWSCloud = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.AWSCloudIntro)}</div>
  );
};
const DocumentPageDataDog = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      <p>{intl.formatMessage(projectWizardMessages.PublicDataDogIntro)}</p>
      <p>
        For details, please visit{' '}
        <a href="https://insightfinder.com/datadog-integration" target="_blank" rel="noreferrer">
          https://insightfinder.com/datadog-integration
        </a>
        .
      </p>
    </div>
  );
};
const DocumentPagePagerDuty = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      <p>{intl.formatMessage(projectWizardMessages.PagerDutyIntro)}</p>
      <p>
        For details, please visit{' '}
        <a href="https://www.pagerduty.com" target="_blank" rel="noreferrer">
          https://www.pagerduty.com
        </a>
        .
      </p>
    </div>
  );
};
const DocumentPageServiceNow = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      <p>{intl.formatMessage(projectWizardMessages.ServiceNowIntro)}</p>
      <p>
        For details, please visit{' '}
        <a href="https://insightfinder.com/servicenow-integration" target="_blank" rel="noreferrer">
          https://insightfinder.com/servicenow-integration
        </a>
        .
      </p>
    </div>
  );
};
const DocumentPageZendesk = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.ZendeskIntro)}</div>
  );
};
const DocumentPageSumoLogic = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.SumoLogicIntro)}</div>
  );
};
const DocumentPageNewRelic = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.PublicNewRelicIntro)}
    </div>
  );
};
const DocumentPageCATechnology = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.PublicCATechnologyIntro)}
    </div>
  );
};
const DocumentGoogleAds = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.GoogleAdsIntro)}</div>
  );
};
const DocumentGoogleCloud = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.GoogleCloudIntro)}</div>
  );
};
const DocumentGoogleBigQuery = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.GoogleBigQueryIntro)}
    </div>
  );
};

const DocumentGoogleCloudCost = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.GoogleCloudCost)}</div>
  );
};
const DocumentPageGoogleCloudPubSub = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.GoogleCloudPubSubIntro)}
    </div>
  );
};
const DocumentPageMicrosoftAzure = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.MicrosoftAzureIntro)}
    </div>
  );
};
const DocumentPageJDBC = ({ intl }: PropsDocumentPage) => {
  return <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.JDBCIntro)}</div>;
};
const DocumentPageShadowProject = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.shadowProject)}</div>
  );
};
const DocumentPageSnow = ({ intl }: PropsDocumentPage) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 10 }}>
      {intl.formatMessage(projectWizardMessages.snowflakePerformanceMonitoring)}
    </div>
  );
};
const DocumentAzureCost = ({ intl }: PropsDocumentPage) => {
  return <div style={{ marginTop: 16, marginBottom: 10 }}>{intl.formatMessage(projectWizardMessages.AzureCost)}</div>;
};

const IntegrationsMetadata = [
  // Agent
  {
    name: 'Metric File Replay',
    display: 'Metric File Replay',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: LogoSvg,
    DocumentPage: DocumentPageMetricFileReplay,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'MetricFile' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Metric File Replay APIs to monitor and report metrics.',
  },
  {
    name: 'Log File Replay',
    display: 'Log File Replay',
    category: ['Log Monitoring'],
    type: 'Agent',
    icon: LogoSvg,
    DocumentPage: DocumentPageLogFileReplay,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      dataType: 'Log',
      insightAgentType: 'LogFile',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Log File Replay APIs to monitor and report logs.',
  },
  {
    name: 'Docker',
    display: 'Docker',
    category: ['Microservices'],
    type: 'Agent',
    icon: DockerSvg,
    DocumentPage: DocumentPageDocker,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'containerStreaming' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Docker APIs to monitor and report logs/metrics.',
  },
  {
    name: 'cAdvisor',
    display: 'cAdvisor',
    category: ['Microservices'],
    type: 'Agent',
    icon: CAdvisorImg,
    DocumentPage: DocumentPageCAdvisor,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses cAdvisor APIs to monitor and report logs/metrics.',
  },
  {
    name: 'Sysdig',
    display: 'Sysdig',
    category: ['Microservices'],
    type: 'Agent',
    icon: SysdigSvg,
    DocumentPage: DocumentPageSysdig,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Sysdig APIs to monitor and report logs/metrics.',
  },
  {
    name: 'Apache Cassandra',
    display: 'Apache Cassandra',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: CassandraSvg,
    DocumentPage: DocumentPageCassandra,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Apache Cassandra APIs to monitor and report metrics.',
  },
  // {
  //   name: 'Exasol',
  //   display: 'Exasol',
  //   category: ['Databases'],
  //   type: 'Agent',
  //   icon: ExasolSvg,
  //   DocumentPage: DocumentPageExasol,
  //   ConfigurePage: InsightAgentSetting,
  // },
  {
    name: 'Microsoft SQL Server',
    display: 'Microsoft SQL Server',
    category: ['Databases'],
    type: 'Agent',
    icon: SQLServerSvg,
    DocumentPage: DocumentPageSQLServer,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Microsoft SQL Server APIs to monitor and report logs/metrics.',
  },
  {
    name: 'MySQL',
    display: 'MySQL',
    category: ['Databases'],
    type: 'Agent',
    icon: MySQLSvg,
    DocumentPage: DocumentPageMySQL,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses MySQL APIs to monitor and report logs/metrics.',
  },
  {
    name: 'MariaDB',
    display: 'MariaDB',
    category: ['Databases'],
    type: 'Agent',
    icon: MariaDBSvg,
    DocumentPage: DocumentPageMariaDB,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses MariaDB APIs to monitor and report logs/metrics.',
  },
  {
    name: 'OpenTSDB',
    display: 'OpenTSDB',
    category: ['Databases'],
    type: 'Agent',
    icon: OpenTSDBImg,
    DocumentPage: DocumentPageOpenTSDB,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses OpenTSDB APIs to monitor and report logs/metrics.',
  },
  {
    name: 'Amazon CloudTrail',
    display: 'Amazon CloudTrail',
    category: ['Log Monitoring'],
    type: 'Agent',
    icon: CloudTrailSvg,
    DocumentPage: DocumentPageCloudTrail,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      dataType: 'Log',
      insightAgentType: 'Custom',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Amazon CloudTrail APIs to monitor and report logs.',
  },
  {
    name: 'Elastic Log',
    display: 'Elastic Log',
    category: ['Log Monitoring'],
    type: 'Agent',
    icon: ElasticSvg,
    DocumentPage: DocumentPageElastic,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      projectCloudType: 'Elastic',
      dataType: 'Log',
      insightAgentType: 'Custom',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Elastic Log APIs to monitor and report logs.',
  },
  {
    name: 'Fluentd',
    display: 'Fluentd',
    category: ['Log Monitoring'],
    type: 'Agent',
    icon: FluentdSvg,
    DocumentPage: DocumentPageFluentd,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      dataType: 'Log',
      insightAgentType: 'Custom',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Fluentd APIs to monitor and report logs.',
  },
  {
    name: 'Splunk',
    display: 'Splunk',
    category: ['Log Monitoring'],
    type: 'Agent',
    icon: SplunkSvg,
    DocumentPage: DocumentPageSplunk,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      dataType: 'Log',
      insightAgentType: 'Custom',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Splunk APIs to monitor and report logs.',
  },
  {
    name: 'Sentry',
    display: 'Sentry',
    category: ['Log Monitoring'],
    type: 'Service',
    icon: SentrySvg,
    DocumentPage: DocumentPageSentry,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      dataType: 'Alert',
      insightAgentType: 'Custom',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Sentry APIs to monitor and report logs.',
  },
  {
    name: 'OpenTelemetry',
    display: 'OpenTelemetry',
    category: ['APM'],
    type: 'Agent',
    icon: OpenTelemetrySvg,
    DocumentPage: DocumentPageOpenTelemetry,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      dataType: 'Trace',
      insightAgentType: 'Custom',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses OpenTelemetry APIs to monitor and report logs/metrics.',
  },
  {
    name: 'Jenkins',
    display: 'Jenkins',
    category: ['CI/CD'],
    type: 'Agent',
    icon: JenkinsSvg,
    DocumentPage: DocumentPageJenkins,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      dataType: 'Deployment',
      insightAgentType: 'Custom',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Jenkins APIs to monitor and report logs/metrics.',
  },
  {
    name: 'GitHub Actions',
    display: 'GitHub Actions',
    category: ['CI/CD'],
    type: 'Agent',
    icon: GitHubActionsSvg,
    DocumentPage: DocumentPageGitHubActions,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      dataType: 'Deployment',
      insightAgentType: 'Custom',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses GitHub Actions to monitor and report logs/metrics.',
  },
  {
    name: 'Collectd',
    display: 'Collectd',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: CollectdSvg,
    DocumentPage: DocumentPageCollectd,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Collectd APIs to monitor and report metrics.',
  },
  {
    name: 'Jolokia',
    display: 'Jolokia',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: JolokiaImg,
    DocumentPage: DocumentPageJolokia,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Jolokia APIs to monitor and report metrics.',
  },
  {
    name: 'Prometheus',
    display: 'Prometheus',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: PrometheusSvg,
    DocumentPage: DocumentPagePrometheus,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'Prometheus', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['Prometheus'] },
    shortDetails: 'Uses Prometheus APIs to monitor and report metrics.',
  },
  {
    name: 'StatsD',
    display: 'StatsD',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: StatsDImg,
    DocumentPage: DocumentPageStatsD,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses StatsD APIs to monitor and report metrics.',
  },
  {
    name: 'Zabbix',
    display: 'Zabbix',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: ZabbixSvg,
    DocumentPage: DocumentPageZabbix,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Zabbix APIs to monitor and report metrics.',
  },
  {
    name: 'Extrahop',
    display: 'Extrahop',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: ExtrahopSvg,
    DocumentPage: DocumentPageExtrahop,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Extrahop APIs to monitor and report metrics.',
  },
  {
    name: 'osmosys',
    display: 'Osmosys',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: OsmosysImg,
    DocumentPage: DocumentPageOsmosys,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Osmosys APIs to monitor and report metrics.',
  },
  {
    name: 'Nagios',
    display: 'Nagios',
    category: ['Event Monitoring'],
    type: 'Service',
    icon: NagiosSvg,
    DocumentPage: null,
    ConfigurePage: NagiosSetting,
    configureDefaultVal: { dataType: 'nagiosXIAlert' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses NewRelic APIs to monitor and report logs.',
  },
  {
    name: 'VMWare VCenter',
    display: 'VMWare VCenter',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: VMWareVCenterSvg,
    DocumentPage: DocumentPageVMWareVCenter,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'containerStreaming' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses VMWare VCenter APIs to monitor and report metrics.',
  },
  {
    name: 'ELK Monitoring',
    display: 'ELK Monitoring',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: ELKMonitoringSvg,
    DocumentPage: DocumentPageELKMonitoring,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: { instanceType: 'PrivateCloud', dataType: 'Metric', insightAgentType: 'Custom' },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses ELK Monitoring APIs to monitor and report metrics.',
  },
  {
    name: 'Apache Kafka Log',
    display: 'Apache Kafka Log',
    category: ['Log Monitoring'],
    type: 'Agent',
    icon: KafkaSvg,
    DocumentPage: DocumentPageKafkaLog,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      projectCloudType: 'Kafka',
      dataType: 'Log',
      insightAgentType: 'Custom',
      samplingInterval: 10,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Apache Kafka Log APIs to monitor and report logs.',
  },
  {
    name: 'Apache Kafka Metric',
    display: 'Apache Kafka Metric',
    category: ['Metric Monitoring'],
    type: 'Agent',
    icon: KafkaSvg,
    DocumentPage: DocumentPageKafkaMetric,
    ConfigurePage: InsightAgentSetting,
    configureDefaultVal: {
      instanceType: 'PrivateCloud',
      projectCloudType: 'Kafka',
      dataType: 'Metric',
      insightAgentType: 'Custom',
      samplingInterval: 5,
    },
    configureAllowOptions: { instanceTypeOptions: ['PrivateCloud'] },
    shortDetails: 'Uses Apache Kafka Metric APIs to monitor and report metrics.',
  },

  // Service
  {
    name: 'Kubernetes',
    display: 'Kubernetes',
    category: ['Microservices', 'Featured'],
    type: 'Service',
    icon: KubernetesSvg,
    DocumentPage: DocumentPageKubernetes,
    ConfigurePage: KubernetesSettingNew,
    shortDetails: 'Uses Kubernetes APIs to monitor and report logs/metrics.',
  },
  {
    name: 'AWSCost',
    display: 'AWS Cost',
    category: ['Cost'],
    type: 'Service',
    icon: CloudWatchSvg,
    DocumentPage: DocumentPageAWSCloud,
    ConfigurePage: AWSCloudSetting,
    shortDetails: 'Uses the AWS cloud cost API to monitor.',
  },
  {
    name: 'AWS CloudWatch',
    display: 'AWS CloudWatch',
    category: ['Log Monitoring', 'Metric Monitoring', 'Featured'],
    type: 'Service',
    icon: CloudWatchSvg,
    DocumentPage: DocumentPageAWSCloudWatch,
    ConfigurePage: AWSCloudWatchSetting,
    configureDefaultValByCategory: {
      'Metric Monitoring': { dataType: 'Metric' },
      'Log Monitoring': { dataType: 'Log' },
    },
    shortDetails:
      'AWS instance monitoring data (i.e., metrics, logs, traces) can be easily ingested into InsightFinder via Cloudwatch APIs.',
  },
  {
    name: 'DataDog',
    display: 'DataDog',
    category: ['Event Monitoring', 'Metric Monitoring', 'Incident Management', 'Featured'],
    type: 'Service',
    icon: DataDogSvg,
    DocumentPage: DocumentPageDataDog,
    ConfigurePage: DataDogSetting,
    BatchConfigurePage: DataDogBatchSetting,
    configureDefaultValByCategory: {
      'Metric Monitoring': { dataType: 'Metric' },
      'Event Monitoring': { dataType: 'Alert' },
    },
    shortDetails:
      'Uses DataDog APIs to ingest data (i.e., metrics, logs, traces) view predictions within the DataDog console.',
  },
  {
    name: 'PagerDuty',
    display: 'PagerDuty',
    category: ['Event Monitoring', 'Incident Management', 'Featured'],
    type: 'Service',
    icon: PagerDutySvg,
    DocumentPage: DocumentPagePagerDuty,
    ConfigurePage: PagerDutySetting,
    shortDetails:
      'Uses PagerDuty APIs to ingest alert data from PagerDuty and view prediction insights within the PagerDuty platform.',
  },
  {
    name: 'ServiceNow',
    display: 'ServiceNow',
    category: ['Incident Management', 'Featured'],
    type: 'Service',
    icon: ServiceNowSvg,
    DocumentPage: DocumentPageServiceNow,
    ConfigurePage: ServiceNowSetting,
    shortDetails: 'Uses ServiceNow APIs to import incident ticket data into InsightFinder for incident data analysis.',
  },
  {
    name: 'Zendesk',
    display: 'Zendesk',
    category: ['Incident Management'],
    type: 'Service',
    icon: ZendeskSvg,
    DocumentPage: DocumentPageZendesk,
    ConfigurePage: ZendeskSetting,
    shortDetails: 'Uses Zendesk APIs to monitor and report logs.',
  },
  {
    name: 'Sumo Logic',
    display: 'Sumo Logic',
    category: ['Log Monitoring'],
    type: 'Service',
    icon: SumoLogicSvg,
    DocumentPage: DocumentPageSumoLogic,
    ConfigurePage: SumoLogicSetting,
    shortDetails: 'Uses Sumo Logic APIs to monitor and report logs.',
  },
  {
    name: 'New Relic',
    display: 'New Relic',
    category: ['APM', 'Log Monitoring', 'Metric Monitoring'],
    type: 'Service',
    icon: NewRelicSvg,
    DocumentPage: DocumentPageNewRelic,
    ConfigurePage: NewRelicSetting,
    shortDetails: 'Uses New Relic APIs to monitor and report logs/metrics.',
  },
  {
    name: 'GoogleAds',
    display: 'Google Ads',
    category: ['Metric Monitoring'],
    type: 'Service',
    icon: GoogleAdsSvg,
    DocumentPage: DocumentGoogleAds,
    ConfigurePage: GoogleAdsSetting,
    shortDetails: 'Uses Google Ads APIs to monitor and report metrics for Microservices and Container Based Apps.',
  },
  {
    name: 'GoogleCloud',
    display: 'Google Cloud Metric Monitoring',
    category: ['Metric Monitoring'],
    type: 'Service',
    icon: GoogleCloudSvg,
    DocumentPage: DocumentGoogleCloud,
    ConfigurePage: GoogleCloudSetting,
    shortDetails: 'Uses Google Cloud APIs to monitor and report metrics.',
  },
  {
    name: 'GoogleBigQuery',
    display: 'Google Big Query',
    category: ['Log Monitoring', 'Metric Monitoring'],
    type: 'Service',
    icon: GoogleBigQuerySvg,
    DocumentPage: DocumentGoogleBigQuery,
    ConfigurePage: GoogleBigQuerySetting,
    configureDefaultValByCategory: {
      'Metric Monitoring': { dataType: 'Metric' },
      'Log Monitoring': { dataType: 'Log' },
    },
    shortDetails: 'Uses Google Big Query APIs to monitor and report logs/metrics.',
  },
  {
    name: 'GoogleCloudCost',
    display: 'Google Cloud Cost',
    category: ['Cost'],
    type: 'Service',
    icon: GoogleCloudSvg,
    DocumentPage: DocumentGoogleCloudCost,
    ConfigurePage: GoogleCloudCostSetting,
    shortDetails: 'Uses Google Cloud Cost APIs to monitor.',
  },
  {
    name: 'GoogleCloudPubSub',
    display: 'Google Cloud Pub/Sub',
    category: ['Log Monitoring'],
    type: 'Service',
    icon: GoogleCloudPubSubSvg,
    DocumentPage: DocumentPageGoogleCloudPubSub,
    ConfigurePage: GoogleCloudPubSubSetting,
    shortDetails: 'Uses Google Cloud Pub/Sub APIs to monitor and report logs.',
  },
  {
    name: 'Azure Monitoring',
    display: 'Azure Monitoring',
    category: ['Log Monitoring', 'Metric Monitoring'],
    type: 'Service',
    icon: MicrosoftAzureSvg,
    DocumentPage: DocumentPageMicrosoftAzure,
    ConfigurePage: MicrosoftAzureSetting,
    configureDefaultValByCategory: {
      'Metric Monitoring': { dataType: 'Metric' },
      'Log Monitoring': { dataType: 'Log' },
    },
    shortDetails: 'Uses Azure Monitoring APIs to monitor and report logs/metrics.',
  },
  {
    name: 'JDBC',
    display: 'JDBC (MySQL/Oracle Database)',
    category: ['Databases'],
    type: 'Service',
    icon: JDBCSvg,
    DocumentPage: DocumentPageJDBC,
    ConfigurePage: JDBCSetting,
    shortDetails: 'Uses JDBC to monitor and report metrics for databases.',
  },
  {
    name: 'SnowflakePerformanceMonitoring',
    display: 'Snowflake performance monitoring',
    category: ['Databases'],
    type: 'Service',
    icon: SnowFlakeSvg,
    DocumentPage: DocumentPageSnow,
    ConfigurePage: SnowSetting,
    shortDetails: 'Uses Snowflake to monitor and report metrics for databases.',
  },
  // Federated Learning
  {
    name: 'Shadow Project',
    display: 'Shadow Project',
    category: ['Federated Learning'],
    type: 'Service',
    icon: LogoSvg,
    DocumentPage: DocumentPageShadowProject,
    ConfigurePage: ShadowProjectSetting,
    shortDetails: 'Uses InsightAgent to import anomalies into InsightFinder.',
  },

  // Custom
  {
    name: 'Custom',
    display: 'Custom',
    category: ['Custom'],
    type: 'Agent',
    icon: LogoSvg,
    DocumentPage: DocumentPageInsightAgent,
    ConfigurePage: InsightAgentSetting,
    shortDetails: 'Uses InsightAgent to monitor and report system logs/metrics for your instances.',
  },

  // Cost
  {
    name: 'Azure Cost',
    display: 'Azure Cost',
    category: ['Cost'],
    type: 'Service',
    icon: MicrosoftAzureSvg,
    DocumentPage: DocumentAzureCost,
    ConfigurePage: AzureCostSetting,
    shortDetails: 'Uses Azure Cost APIs to monitor.',
  },

  // dynatrace
  {
    name: 'Dynatrace',
    display: 'Dynatrace',
    category: ['APM', 'Featured'],
    type: 'Service',
    icon: DynatraceSvg,
    DocumentPage: DocumentAzureCost,
    ConfigurePage: DynatraceSetting,
    shortDetails: 'Uses Dynatrace APIs to monitor and report logs/metrics.',
  },
];

export default IntegrationsMetadata;
