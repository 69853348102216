import './style/index.less';
import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Spin, Breadcrumb, Button } from 'antd';
import moment from 'moment';

import { appButtonsMessages } from '../../../common/app/messages';
import { settingsMenusMessages } from '../../../common/settings/messages';
// eslint-disable-next-line import/no-cycle
import { BatchContext } from './pageContext';
// eslint-disable-next-line import/no-cycle
import Step1 from './component/Step1';
// eslint-disable-next-line import/no-cycle
import Step2 from './component/Step2';
// eslint-disable-next-line import/no-cycle
import Step3 from './component/Step3';
// eslint-disable-next-line import/no-cycle
import Step4 from './component/Step4';
import { Modal } from '../../../lib/fui/react';

const stepContents = [Step2, Step3, Step4];

const BatchPage = () => {
  const [stepIndex, setStepIndex] = useState(0);

  const [pageData, setPageData] = useState({
    loading: false,
    showModal: true,
  });

  const { showModal } = pageData;

  const [refresh, setRefresh] = useState(moment.utc().valueOf());

  const { intl } = useContext(BatchContext);

  useEffect(() => {
    setStepIndex(0);
  }, [showModal]);

  const onSetPageData = (data, clear = false) => {
    setPageData((prev) => {
      if (clear) {
        return {
          loading: false,
          ...data,
        };
      }
      return {
        ...prev,
        ...data,
      };
    });
  };

  const handleNext = () => {
    setStepIndex((value) => {
      // eslint-disable-next-line no-plusplus
      const nextIndex = ++value;
      const hasNext = _.get(stepContents, nextIndex, false);
      const step = hasNext ? nextIndex : stepContents.length;
      return step;
    });
    onSetPageData({
      showModal: true,
    });
  };

  const handlePrev = () => {
    setStepIndex((value) => {
      // eslint-disable-next-line no-plusplus
      const prevIndex = --value;
      const hasNext = _.get(stepContents, prevIndex, false);
      const step = hasNext ? prevIndex : 0;
      return step;
    });
    onSetPageData({
      showModal: true,
    });
  };

  const handleCancel = () => {
    onSetPageData({
      showModal: false,
    });
  };

  const onShowLoading = () => {
    onSetPageData({ loading: true });
  };
  const onHideLoading = () => {
    onSetPageData({ loading: false });
  };

  const handleRefresh = () => {
    setRefresh(moment.utc().valueOf());
  };

  const StepContent = stepContents[stepIndex];
  const stepTitle = [
    'Step 1 of 3 General properties',
    'Step 2 of 3 - Alert cluster 1, Alerts - Select servers to configure',
    'Step 3 of 3 Alert cluster 1, Alerts Configuration to apply',
  ];

  const { loading } = pageData;

  return (
    <div className="if-batch-configuration">
      <div className="batch-header">
        <Breadcrumb>
          <Breadcrumb.Item>{intl.formatMessage(settingsMenusMessages.systemSetting)}</Breadcrumb.Item>
          <Breadcrumb.Item>{intl.formatMessage(settingsMenusMessages.systemBatch)}</Breadcrumb.Item>
        </Breadcrumb>
        <Button size="small" onClick={handleRefresh}>
          {intl.formatMessage(appButtonsMessages.refresh)}
        </Button>
      </div>
      <Step1
        refresh={refresh}
        onPrev={handlePrev}
        onNext={handleNext}
        onCancel={handleCancel}
        pageData={pageData}
        onSetPageData={onSetPageData}
        onShowLoading={onShowLoading}
        onHideLoading={onHideLoading}
      />
      {showModal && (
        <Modal
          title={stepTitle[stepIndex]}
          width="95%"
          visible
          footer={null}
          maskClosable={false}
          onCancel={handleCancel}
          style={{ minWidth: 1550 }}
          bodyStyle={{ height: 760 }}
          className="batch-modal-content"
        >
          <Spin spinning={loading}>
            <StepContent
              onPrev={handlePrev}
              onNext={handleNext}
              onCancel={handleCancel}
              pageData={pageData}
              onSetPageData={onSetPageData}
              onShowLoading={onShowLoading}
              onHideLoading={onHideLoading}
              setRefresh={setRefresh}
            />
          </Spin>
        </Modal>
      )}
    </div>
  );
};

export default BatchPage;
