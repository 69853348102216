import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
// import JSONTree from 'react-json-tree';
import { get, isObject, round } from 'lodash';
import { DownOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import { Popover, Tag, Typography, Button } from 'antd';

import { downloadFile, highlightContent } from '.';
import { DownloadPath } from '../../lib/fui/icons';
import { Modal } from '../../lib/fui/react';
import ReactJson from '../../lib/react-json-view/js/index';

import { logMessages, logDescMessages } from '../log/messages';
import { appButtonsMessages, appFieldsMessages } from '../app/messages';
import CopyTextContent from '../../web/app/components/CopyContent';

// Log component renderers
const LogRenderers = {
  CompressTitle: ({ originSize, compressedSize, decreaseRatio }, intl) => {
    return (
      <div>
        <span className="light-label bold" style={{ marginRight: 8 }}>{`${intl.formatMessage(
          logMessages.originalSize,
        )}:`}</span>
        <span style={{ color: '#668CFF' }}>{numeral(originSize).format('0.0ib')}</span>
        <span className="light-label bold" style={{ marginRight: 8, marginLeft: 16 }}>{`${intl.formatMessage(
          logMessages.compressedSize,
        )}:`}</span>
        <span style={{ color: '#668CFF' }}>{numeral(compressedSize).format('0.0ib')}</span>
        <span className="light-label bold" style={{ marginRight: 8, marginLeft: 16 }}>{`${intl.formatMessage(
          logMessages.decreaseRatio,
        )}:`}</span>
        <span style={{ color: '#32C67F' }}>{numeral(decreaseRatio).format('0.0%')}</span>
      </div>
    );
  },
  RenderSimpleMsgs: ({ sampleMsg, onToggleCollapse, currentTheme }, intl) => {
    return (
      <div className="full-height full-width flex-col overflow-y-auto">
        {R.addIndex(R.map)((rawData, index) => {
          let rawDataJson;
          try {
            rawDataJson = JSON.parse(rawData);
          } catch (error) {
            // console.debug(error);
          }
          return (
            <div key={index} style={{ marginBottom: 16, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              {!rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                )}
              {rawDataJson && (
                <LogRenderers.JsonTree
                  data={rawDataJson}
                  onToggleCollapse={onToggleCollapse}
                  currentTheme={currentTheme}
                />
              )}
            </div>
          );
        }, sampleMsg || [])}
      </div>
    );
  },

  /* eslint react/prop-types: 0 */
  GetPieOption: (key, value) => {
    return {
      title: {
        show: false,
        text: `Keyword: ${key}`,
        x: 'center',
        textStyle: { fontSize: 12 },
      },
      toolbox: {
        bottom: 0,
        feature: {
          mySaveCsv: {
            show: true,
            title: 'Save',
            icon: `path://${DownloadPath}`,
            onclick: () => {
              const fname = `Keyword: ${key}.csv`;
              let csvString = 'Value,Count\r\n';
              R.forEach((item) => {
                csvString += `${item.featureValue},${item.count}\r\n`;
              }, R.sortWith([R.descend(R.prop('count'))], value));
              downloadFile(csvString, fname);
            },
          },
        },
      },
      tooltip: {
        trigger: 'item',
        confine: false,
        borderWidth: 0,
        extraCssText: 'box-shadow: 0 0 3px rgba(197, 197, 197, 0.8);',
        appendToBody: true,
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        textStyle: {
          color: 'var(--text-color)',
        },
        formatter: (params) => {
          const { data } = params;
          const { featureValue } = data || {};
          const parts = R.splitEvery(36, featureValue || '');

          const content = R.map((p) => `<div class="pie-tooltip-content">${p}</div>`, parts).join('');

          let html = `${params.percent.toFixed(1)}% Count: ${params.value}<br/>`;
          html += `Value: ${content}`;
          return `<div style="font-size: 12px">${html}</div>`;
        },
      },
      series: [
        {
          animation: false,
          minAngle: 1,
          name: 'Keyword',
          type: 'pie',
          radius: ['40%', '90%'],
          // selectedMode: 'single',
          hoverAnimation: false,
          data: R.map((item) => {
            const { isJson, featureLabel, featureValue, count, hide } = item;
            const itemStyle = {};
            if (hide) {
              itemStyle.opacity = 0.1;
              itemStyle.color = 'gray';
            }
            return { isJson, featureLabel, featureValue, value: count, itemStyle };
          }, value),
          itemStyle: {
            emphasis: {
              shadowBlur: 4,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
            normal: {
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
            },
          },
        },
      ],
    };
  },
  GetNumericChartOption: (key, value) => {
    const { isJson, isNumeric, featureLabel, featureValue, numericInfo } = value;
    const { distribution, interval } = numericInfo;
    let datas = [];
    R.forEachObjIndexed((val, key) => {
      datas.push({
        name: key,
        value: val === 0 ? null : val,
        realValue: val,
        start: interval === 0 ? Number(key) : Number(key) * interval,
        end: interval === 0 ? Number(key) : (Number(key) + 1) * interval,
        isJson,
        isNumeric,
        interval,

        featureLabel,
        featureValue,
      });
    }, distribution || {});
    datas = R.sortWith([R.ascend(R.prop('start'))], datas);
    return {
      title: {
        show: false,
        text: `Keyword: ${key}`,
        x: 'center',
        textStyle: { fontSize: 12 },
      },
      toolbox: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        textStyle: {
          color: 'var(--text-color)',
        },
        bottom: 0,
        feature: {
          mySaveCsv: {
            show: true,
            title: 'Save',
            icon: `path://${DownloadPath}`,
            onclick: () => {
              const fname = `Keyword: ${key}.csv`;
              let csvString = 'Start value,End value,Count\r\n';
              R.forEach((item) => {
                csvString += `${item.start},${item.end},${item.realValue}\r\n`;
              }, datas);
              downloadFile(csvString, fname);
            },
          },
        },
      },
      tooltip: {
        trigger: 'axis',
        confine: false,
        borderWidth: 0,
        extraCssText: 'box-shadow: 0 0 3px rgba(197, 197, 197, 0.8);',
        appendToBody: true,
        formatter: (params) => {
          const { data } = params[0];
          const { start, end, realValue } = data || {};
          let html = `Start value: ${numeral(start).format(start > 1 ? '0,0' : '0.[000000]')}<br/>`;
          html += `End value: ${numeral(end).format(end > 1 ? '0,0' : '0.[000000]')}<br/>`;
          html += `Count: ${realValue}`;
          return `<div style="font-size: 12px">${html}</div>`;
        },
      },
      grid: { left: 10, right: 10, top: 10, bottom: 40 },
      xAxis: {
        type: 'category',
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: false,
        },
      },
      series: [
        {
          type: 'bar',
          data: datas,
          showBackground: true,
          barMaxWidth: 10,
          barMinHeight: 10,
          backgroundStyle: { color: 'rgba(180, 180, 180, 0.2)' },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
            },
          },
        },
      ],
    };
  },
  GetEffectScatterOption: ({ key, data, maxData, minData }) => {
    return {
      tooltip: {
        confine: false,
        borderWidth: 0,
        extraCssText: 'box-shadow: 0 0 3px rgba(197, 197, 197, 0.8);',
        appendToBody: true,
        formatter: (params) => {
          const { value } = params;
          return `<div style="font-size: 12px">Value: ${
            value[0] > 10000 ? numeral(value[0]).format('0.00e+0') : numeral(value[0]).format('0.00')
          }<br/>Count: ${value[1]}</div>`;
        },
      },
      grid: { left: 48, right: 10, top: 6, bottom: 45 },
      xAxis: {
        splitNumber: 1,
        scale: true,
        nameGap: 30,
        nameLocation: 'end',
        nameTextStyle: {
          padding: [75, 0, 0, -40],
        },
        axisLabel: {
          show: true,
          formatter: (value) => {
            if (value > 10000) value = numeral(value).format('0.0e+0');
            return value;
          },
        },
      },
      yAxis: {
        scale: true,
        axisLabel: {
          show: true,
          formatter: (value) => {
            if (value > 1000) value = numeral(value).format('0.0e+0');
            return value;
          },
        },
      },
      toolbox: {
        bottom: 0,
        emphasis: {
          iconStyle: {
            textPosition: 'left',
          },
        },
        feature: {
          mySaveCsv: {
            show: true,
            title: 'Save',
            icon: `path://${DownloadPath}`,
            onclick: () => {
              const fname = `Keyword ${key}.csv`;
              let csvString = 'Value,Count\r\n';
              R.forEach((item) => {
                csvString += `${item.name},${item.count}\r\n`;
              }, R.sortWith([R.descend(R.prop('count'))], data));
              downloadFile(csvString, fname);
            },
          },
        },
      },
      series: [
        {
          type: 'effectScatter',
          symbolSize: 20,
          data: [
            {
              value: [maxData.nameNumber, maxData.count],
              itemStyle: {},
              name: maxData.name,
              key,
              type: maxData.type,
            },
            {
              value: [minData.nameNumber, minData.count],
              itemStyle: {},
              name: minData.name,
              key,
              type: minData.type,
            },
          ],
        },
        {
          type: 'scatter',
          data: R.map((item) => {
            const { name, nameNumber, count, hide, type } = item;
            const itemStyle = {};
            if (hide) {
              itemStyle.opacity = 0.1;
              itemStyle.color = 'gray';
            }
            return {
              value: [nameNumber, count],
              itemStyle,
              name,
              key,
              type,
            };
          }, data),
        },
      ],
    };
  },

  GetAllPatternsInfo: (clusterInfoList) => {
    const allPatternInfo = {
      nid: 'All',
      patternName: 'All Patterns',
      name: 'All Patterns',
      msgName: 'All Patterns',
      sampleMsgContent: '',
      eventsCount: 0,
      sampleMsg: [],
      JSONFieldKeywords: [],
      keywords: [],
      keywordsStr: '',
      featureKeywords: [],
      featureK: '',
      countRatio: 1,
    };
    R.forEach((clusterInfo) => {
      allPatternInfo.eventsCount += clusterInfo.eventsCount;
      allPatternInfo.sampleMsg = R.concat(allPatternInfo.sampleMsg, clusterInfo.sampleMsg || []);
      allPatternInfo.JSONFieldKeywords = R.concat(
        allPatternInfo.JSONFieldKeywords,
        clusterInfo.JSONFieldKeywords || [],
      );
    }, clusterInfoList || []);
    return allPatternInfo;
  },
  GetLogEntryStatistics: ({ events }) => {
    const logPatternNamesMap = {};
    const logTypesMap = {};
    const logCategoriesMap = {};
    const logCountMap = {};
    R.forEach((log) => {
      const patternId = String(log.nid);
      if (!R.has(patternId, logPatternNamesMap)) {
        logPatternNamesMap[patternId] = { name: patternId, count: 0 };
      }
      logPatternNamesMap[patternId] = {
        name: patternId,
        count: logPatternNamesMap[patternId].count + (log.count || 1),
      };

      R.forEach((item) => {
        const eventType = item.type;
        if (!R.has(eventType, logTypesMap)) {
          logTypesMap[eventType] = { name: eventType, count: 0 };
        }
        logTypesMap[eventType] = {
          name: eventType,
          count: logTypesMap[eventType].count + (log.count || 1),
        };
      }, log.typeAndColor || []);

      R.forEach(
        (category) => {
          if (!R.has(category, logCategoriesMap)) {
            logCategoriesMap[category] = { name: category, count: 0 };
          }
          logCategoriesMap[category] = {
            name: category,
            count: logCategoriesMap[category].count + (log.count || 1),
          };
        },
        R.filter((category) => Boolean(category), R.split('&', log.category || '')),
      );

      const count = String(log.count || '');
      if (count) {
        if (!R.has(count, logCountMap)) {
          logCountMap[count] = { name: count, count: 0 };
        }
        logCountMap[count] = {
          name: count,
          count: logCountMap[count].count + 1,
        };
      }
    }, events || []);
    return { logPatternNamesMap, logTypesMap, logCategoriesMap, logCountMap };
  },
  LogTypeItemRenderer: ({ intl, idx, tc }) => {
    const { type, color } = tc;
    return (
      <Popover
        key={idx}
        title={null}
        content={
          logDescMessages[type]
            ? intl.formatMessage(logDescMessages[type])
            : logMessages[type]
            ? intl.formatMessage(logMessages[type])
            : type
        }
        mouseEnterDelay={0.3}
      >
        <Tag className="hidden-line-with-ellipsis inline-block max-width" style={{ marginBottom: 8 }} color={color}>
          {logMessages[type] ? intl.formatMessage(logMessages[type]) : type}
        </Tag>
      </Popover>
    );
  },
  JsonTree: ({ data, collapsed = 2, onToggleCollapse, currentTheme, highlightWord }) => {
    return (
      <div onClick={(event) => event.stopPropagation()} style={{ padding: 4 }}>
        <ReactJson
          name={null}
          src={data}
          theme={currentTheme === 'light' ? 'rjv-default' : 'ashes'}
          collapsed={collapsed}
          indentWidth={2}
          enableClipboard={false}
          displayDataTypes={false}
          onToggleCollapse={onToggleCollapse}
          style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
          highlight={highlightWord}
        />
      </div>
    );
  },
  RenderAnomalyWords: ({ className, style, anomalyWordList }) => {
    return (
      <div className={className || ''} style={{ ...(style || {}) }}>
        <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
          Attributed anomalies:
        </span>
        {R.map((val) => {
          const { anomalyWord, direction, ratio, numericFlag } = val || {};
          return (
            <span key={anomalyWord} style={{ marginLeft: 8 }}>
              <span style={{ color: 'var(--blue)' }}>{anomalyWord}</span>
              <span>{' is'}</span>
              <span style={{ color: 'var(--red)' }}>{` ${numeral(Math.abs(Number(ratio) / 100)).format('0.0%')}`}</span>

              {!numericFlag && <span>{` more frequent than normal;`}</span>}
              {numericFlag && direction && (
                <>
                  <span style={{ color: 'var(--blue)' }}>{` ${direction === 'positive' ? 'higher' : 'lower'}`}</span>
                  <span>{` than normal;`}</span>
                </>
              )}
            </span>
          );
        }, R.sortWith([R.descend(R.prop('ratio'))], anomalyWordList || []))}
      </div>
    );
  },
  RenderOutlierValue: ({ className, style, outlierValue, isTrace }) => {
    return (
      <div className={className || ''} style={{ ...(style || {}) }}>
        <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
          Outlier features:
        </span>
        {R.map(([word, value]) => {
          let valueStr = value;
          if (isTrace && word === 'duration') {
            const duration = Number(value);
            if (duration >= 1000000) {
              valueStr = `${Number.parseFloat(duration / 1000 / 1000).toFixed(3)}s`;
            } else if (duration >= 1000) {
              valueStr = `${Number.parseFloat(duration / 1000).toFixed(3)}ms`;
            } else {
              valueStr = `${Number.parseFloat(duration).toFixed(3)}μs`;
            }
          }
          return (
            <span key={word} style={{ marginLeft: 8 }}>
              <span style={{ color: 'var(--blue)' }}>{`${word}`}</span>
              <span>({valueStr})</span>
              <span>{' is higher than normal;'}</span>
            </span>
          );
        }, R.toPairs(outlierValue || {}))}
      </div>
    );
  },
  ExpandLogContent: ({
    intl,
    style,
    rawData,
    rawDataJson,
    frequencyStr,
    isSmallCluster,
    anomalyWordList,
    outlierValue,
    isExpand,
    onExpand,
    anomalyFeatureStr,
    rows = 2,
    inLine,
  }) => {
    return (
      <div className="flex-col full-width">
        <div className="flex-col max-width" style={{ ...(style || {}) }}>
          {frequencyStr && (
            <div className="max-width" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
              {frequencyStr}
            </div>
          )}
          {!R.isEmpty(anomalyFeatureStr) && anomalyFeatureStr && (
            <div className="max-width flex-col" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
              {R.addIndex(R.map)((item, index) => {
                const [label, values] = item;
                const content = R.addIndex(R.map)(
                  ({ value }, i) => (
                    <span style={{ margin: '0 4px' }} key={`featureStr${index}${i}`}>
                      {value}
                      {values.length - 1 === i ? '' : ','}
                    </span>
                  ),
                  values,
                );
                return (
                  <div className="flex-row" key={`${index}+${label}`}>
                    <div style={{ whiteSpace: 'nowrap' }}>{label} :</div>
                    <div className={`${inLine ? 'hidden-line-with-ellipsis' : 'flex-row flex-wrap'}`}>{content}</div>
                  </div>
                );
              }, anomalyFeatureStr)}
            </div>
          )}
          {isSmallCluster && (
            <div
              style={{ wordBreak: 'break-all', marginBottom: 12, color: 'var(--blue)' }}
              className="hidden-line-with-ellipsis"
            >
              This rare event is from small cluster.
            </div>
          )}
          {anomalyWordList && anomalyWordList.length > 0 && (
            <LogRenderers.RenderAnomalyWords
              className="hidden-line-with-ellipsis"
              style={{ wordBreak: 'break-all', marginBottom: 12 }}
              anomalyWordList={anomalyWordList}
            />
          )}
          {outlierValue && !R.isEmpty(outlierValue) && (
            <LogRenderers.RenderOutlierValue
              className="hidden-line-with-ellipsis"
              style={{ wordBreak: 'break-all', marginBottom: 12 }}
              outlierValue={outlierValue}
            />
          )}

          {isExpand && (
            <div className="flex-row flex-center-align">
              <div style={{ flex: 1, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{rawData}</div>
              <span
                style={{ border: 0, padding: 0, marginLeft: 4, height: 'auto', textAlign: 'right', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onExpand(false);
                }}
              >
                <UpOutlined />
              </span>
            </div>
          )}
          {!isExpand && (
            <div className="flex-row flex-center-align">
              <div className="flex-grow hidden-line-with-ellipsis">{rawData}</div>
              <span
                style={{ border: 0, padding: 0, marginLeft: 4, height: 'auto', textAlign: 'right', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onExpand(true);
                }}
              >
                <DownOutlined />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  },
  ExpandLogJSONContent: ({
    intl,
    style,
    rawData,
    rawDataJson,
    onToggleCollapse,
    frequencyStr,
    isSmallCluster,
    anomalyWordList,
    outlierValue,
    currentTheme,
  }) => {
    const onExpandJSON = useCallback(() => {
      Modal.info({
        width: 980,
        icon: null,
        closable: true,
        title: null,
        content: (
          <div
            className="overflow-y-auto"
            style={{
              height: 500,
              padding: '0 8px 0 0',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            {rawDataJson && isObject(rawDataJson) ? (
              <LogRenderers.JsonTree
                data={rawDataJson}
                onToggleCollapse={onToggleCollapse}
                currentTheme={currentTheme}
              />
            ) : (
              rawData
            )}
          </div>
        ),
        okText: intl.formatMessage(appButtonsMessages.close),
      });
    }, [rawData, rawDataJson]);

    return (
      <div className="flex-col max-width" style={{ ...(style || {}) }}>
        {frequencyStr && (
          <div className="max-width" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
            {frequencyStr}
          </div>
        )}
        {isSmallCluster && (
          <div style={{ wordBreak: 'break-all', marginBottom: 12, color: 'var(--blue)' }}>
            This rare event is from small cluster.
          </div>
        )}
        {anomalyWordList && anomalyWordList.length > 0 && (
          <LogRenderers.RenderAnomalyWords
            style={{ wordBreak: 'break-all', marginBottom: 12 }}
            anomalyWordList={anomalyWordList}
          />
        )}
        {outlierValue && !R.isEmpty(outlierValue) && (
          <LogRenderers.RenderOutlierValue
            style={{ wordBreak: 'break-all', marginBottom: 12 }}
            outlierValue={outlierValue}
          />
        )}

        <div className="max-width flex-row flex-center-align">
          <div className="hidden-line-with-ellipsis">{rawData}</div>
          <Button
            type="link"
            style={{ border: 0, padding: 0, height: 'auto' }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onExpandJSON();
            }}
          >
            {intl.formatMessage(appFieldsMessages.expand)}
          </Button>
        </div>
      </div>
    );
  },
  RenderLogContent: ({
    intl,
    style,
    rawData,
    rawDataJson,
    onToggleCollapse,
    frequencyStr,
    isSmallCluster,
    anomalyWordList,
    outlierValue,
    owner,
    summarySettings,
    enableExpansion = true,
    autoExpand = false,
    currentTheme,
    anomalyFeatureStr,
    clearStyle,
    highlightWord,
    inLine,
    summarySettingsOnlyRow = false,
    noExpand,
    multilineEllipsis,
    notViewJsonString,
    infoRender,
    takeNum,
  }) => {
    const expandState = '__content_expand_state';

    let settings = [];
    R.forEach((s) => {
      const v = get(rawDataJson, s.replaceAll('->', '.'));
      if (v) settings.push(s);
    }, summarySettings || []);
    settings = takeNum ? R.take(takeNum, settings) : settings;

    const [expand, setExpand] = useState(owner[expandState]);
    const handleExpand = (e) => {
      if (!enableExpansion) return;
      e.stopPropagation();
      const c = !expand;
      setExpand(c);
      if (owner) {
        owner[expandState] = c;
      }
      if (onToggleCollapse) {
        onToggleCollapse();
      }
    };

    useEffect(() => {
      setExpand(owner[expandState]);
    }, [owner]);

    return (
      <div className="flex-col max-width" style={{ ...(style || {}) }}>
        {frequencyStr && (
          <div className="max-width hidden-line-with-ellipsis word-break-all color-blue">{frequencyStr}</div>
        )}
        {!R.isEmpty(anomalyFeatureStr) && anomalyFeatureStr && (
          <div className="max-width flex-col word-break-all color-blue">
            {R.addIndex(R.map)((item, index) => {
              const [label, values] = item;
              const content = R.addIndex(R.map)(
                ({ value }, i) => (
                  <span className="m-x-4" key={`featureStr${index}${i}`}>
                    {value}
                    {values.length - 1 === i ? '' : ','}
                  </span>
                ),
                values,
              );
              return (
                <div className="flex-row" key={`${index}+${label}`}>
                  <div className="white-space-no-wrap">{label} :</div>
                  <div className={`${inLine ? 'hidden-line-with-ellipsis' : 'flex-row flex-wrap'}`}>{content}</div>
                </div>
              );
            }, anomalyFeatureStr)}
          </div>
        )}
        {isSmallCluster && (
          <div className="hidden-line-with-ellipsis word-break-all m-b-12 color-blue">
            This rare event is from small cluster.
          </div>
        )}
        {anomalyWordList && anomalyWordList.length > 0 && (
          <LogRenderers.RenderAnomalyWords
            className="hidden-line-with-ellipsis word-break-all m-b-12"
            anomalyWordList={anomalyWordList}
          />
        )}
        {outlierValue && !R.isEmpty(outlierValue) && (
          <LogRenderers.RenderOutlierValue
            className="hidden-line-with-ellipsis word-break-all m-b-12"
            outlierValue={outlierValue}
          />
        )}
        <div>
          {(!rawDataJson || !isObject(rawDataJson)) && (
            <div className="white-space-pre-wrap word-break-all">
              <CopyTextContent
                content={R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                )}
              />
              {R.join(
                '\n',
                R.filter((x) => Boolean(x), (rawData || '').split('\n')),
              )}
            </div>
          )}
          {rawDataJson && isObject(rawDataJson) && (
            <div
              className={`flex-row flex-grow flex-center-align ${clearStyle ? 'min-height-0' : 'min-height-40'}`}
              onClick={handleExpand}
            >
              {enableExpansion && (
                <div className="clickable p-r-4 font-line-40">{expand ? <DownOutlined /> : <RightOutlined />}</div>
              )}

              {settings.length === 0 && !notViewJsonString && (
                <div
                  className={`${
                    !autoExpand
                      ? multilineEllipsis
                        ? 'hidden-line-with-ellipsis-multiline'
                        : 'hidden-line-with-ellipsis '
                      : ''
                  } clickable text-primary`}
                >
                  {highlightContent(rawData, highlightWord, false)}
                </div>
              )}
              {settings.length > 0 && (
                <div
                  style={{ overflow: 'hidden' }}
                  className={`overflow-hi ${!autoExpand ? '' : ''} clickable ${
                    multilineEllipsis ? ' hidden-line-with-ellipsis-multiline' : ' flex-row flex-wrap'
                  }`}
                >
                  {R.addIndex(R.map)(
                    (s, idx) => {
                      const v = get(rawDataJson, s.replaceAll('->', '.'));
                      if (!v) return null;
                      const hv = highlightContent(v, highlightWord, false);
                      const showInfo = summarySettingsOnlyRow ? idx === 0 : settings.length - 1 === idx;
                      return (
                        <div
                          key={s}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            flex: 'initial',
                            marginBottom: 4,
                            width: '100%',
                          }}
                        >
                          <div style={{ display: 'inline-flex', overflow: 'hidden', alignItems: 'center' }}>
                            <Tag color="processing" style={{ marginRight: 2, padding: '0 2px' }}>
                              {s}
                            </Tag>
                            <div
                              className="hidden-line-with-ellipsis"
                              style={{
                                paddingRight: 8,
                                display: 'inline',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              dangerouslySetInnerHTML={{ __html: hv }}
                            />
                          </div>
                          {showInfo && infoRender}
                        </div>
                      );
                    },
                    summarySettingsOnlyRow ? R.slice(0, 1, settings) : settings,
                  )}
                </div>
              )}
            </div>
          )}
          {rawDataJson && isObject(rawDataJson) && (noExpand || expand) && (
            <LogRenderers.JsonTree
              highlightWord={highlightWord}
              data={rawDataJson}
              onToggleCollapse={onToggleCollapse}
              currentTheme={currentTheme}
            />
          )}
        </div>
      </div>
    );
  },
};

export { LogRenderers };
