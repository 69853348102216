/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
// import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { NavLink } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { FileAddOutlined } from '@ant-design/icons';
import { Empty, Tabs, Button } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { Container } from '../../../lib/fui/react';
import { hideAppLoader } from '../../../common/app/actions';
import { buildUrl, parseLocation } from '../../../common/utils';

import { IntegrationsMetadata } from './dataSource';

import { appFieldsMessages, appMenusMessages } from '../../../common/app/messages';
import { settingsMenusMessages } from '../../../common/settings/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  match: Object,
  // eslint-disable-next-line
  appLoaderVisible: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  hideAppLoader: Function,
};

class ProjectIntegrationsDataSourceCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      dataSourceItem: null,

      activeKey: 'overview',
    };
    this.categoryOptions = [
      { value: 'All', label: 'All Categories' },
      { value: 'APM', label: 'APM' },
      { value: 'CI/CD', label: 'CI/CD' },
      { value: 'Databases', label: 'Databases' },
      { value: 'Incident Management', label: 'Incident Management' },
      { value: 'Log Monitoring', label: 'Log Monitoring' },
      { value: 'Event Monitoring', label: 'Event Monitoring' },
      { value: 'Metric Monitoring', label: 'Metric Monitoring' },
      { value: 'Microservices', label: 'Microservices' },
      { value: 'Federated Learning', label: 'Federated Learning' },
      { value: 'Custom', label: 'Custom' },
    ];
    this.categoryOptionsMap = R.fromPairs(R.map((item) => [item.value, item.label], this.categoryOptions));
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }

    this.parseData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  parseData() {
    const { match } = this.props;
    const { dataSource } = match.params || {};
    if (dataSource) {
      const dataSourceItem = R.find((item) => item.name === dataSource, IntegrationsMetadata || []);
      this.setState({ dataSourceItem });
    }
  }

  @autobind
  handleCreateProjectClick() {
    const { push, location } = this.props;
    const { dataSourceItem } = this.state;
    const params = parseLocation(location);
    const { category } = params;

    const { name } = dataSourceItem;
    push(buildUrl(BaseUrls.SettingsProjectWizard, {}, { dataSource: name, category }));
  }

  @autobind
  handleCreateBatchProjectClick() {
    const { push, location } = this.props;
    const { dataSourceItem } = this.state;
    const params = parseLocation(location);
    const { category } = params;

    const { name } = dataSourceItem;
    push(buildUrl(BaseUrls.SettingsProjectWizard, {}, { dataSource: name, category, batch: true }));
  }

  render() {
    const { intl, location, push } = this.props;
    const { dataSourceItem } = this.state;
    const params = parseLocation(location);
    const { category } = params;
    const { display: dataSourceDisplay, name: dataSource } = dataSourceItem || {};

    return (
      <Container fullHeight withGutter className="flex-col settings">
        <Container className="flex-row flex-center-align" breadcrumb>
          <div className="section">
            <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
            <span className="divider">/</span>
            <NavLink to={BaseUrls.SystemSetting}>
              <span className="label">{intl.formatMessage(settingsMenusMessages.systemSetting)}</span>
            </NavLink>
            <span className="divider">/</span>
            <a
              onClick={(e) => {
                e.preventDefault();
                push(buildUrl(BaseUrls.SettingsIntegrations, {}, params));
              }}
            >
              <span className="label">{intl.formatMessage(appFieldsMessages.integrations)}</span>
            </a>
            <span className="divider">/</span>
            <NavLink to={buildUrl(BaseUrls.SettingsIntegrationsDataSource, { dataSource }, { category })}>
              <span className="label">{dataSourceDisplay}</span>
            </NavLink>
          </div>
        </Container>

        <Container
          className="flex-grow flex-min-height flex-col content-bg corner-10"
          style={{
            margin: '0 16px 8px 16px',
            borderRadius: 4,
            padding: 30,
          }}
        >
          {!dataSourceItem && (
            <div className="full-height full-width flex-row flex-center-align flex-center-justify">
              <Empty />
            </div>
          )}
          {dataSourceItem && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
              <div>
                <img style={{ width: 50, height: 50 }} alt={dataSourceItem.name} src={dataSourceItem.icon} />
              </div>
              <div className="flex-grow flex-min-width" style={{ padding: '0px 16px' }}>
                <div className="font-22 bold">{dataSourceItem.display}</div>
                <div className="flex-row">
                  {R.map(
                    (c) => (
                      <div key={c} className="round-tag" style={{ display: 'inline-flex' }}>
                        {this.categoryOptionsMap[c] || c}
                      </div>
                    ),
                    dataSourceItem.category,
                  )}
                </div>
              </div>
              <div>
                <Button size="small" icon={<FileAddOutlined />} onClick={this.handleCreateProjectClick}>
                  Create Project
                </Button>
                {dataSourceItem.name === 'DataDog' && (
                  <Button
                    size="small"
                    icon={<FileAddOutlined />}
                    onClick={this.handleCreateBatchProjectClick}
                    style={{ marginLeft: 20 }}
                  >
                    Create Projects In Batch
                  </Button>
                )}
              </div>
            </div>
          )}
          {dataSourceItem && (
            <div className="flex-grow flex-min-height">
              <Tabs
                className="full-height ant-tabs-content-full-height"
                activeKey={this.state.activeKey}
                onChange={(activeKey) => this.setState({ activeKey })}
              >
                <Tabs.TabPane tab="Overview" key="overview" className="overflow-y-auto">
                  <dataSourceItem.DocumentPage intl={intl} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          )}
        </Container>
      </Container>
    );
  }
}

const ProjectIntegrationsDataSource = injectIntl(ProjectIntegrationsDataSourceCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { appLoaderVisible, projects } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      location,
      appLoaderVisible,
      projects,
      credentials,
      userInfo,
    };
  },
  { push, hideAppLoader },
)(ProjectIntegrationsDataSource);
