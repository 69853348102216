/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { get } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getEventTriage = (credentials: Credentials, params: Object) => {
  const { projectName, neuronId, operation } = params;
  const promiseQuery = [
    fetchGet(getEndpoint('userAction'), {
      ...credentials,
      projectName,
      neuronId,
      operation,
    })
      .then((d) => {
        const triage = get(d, ['data', 'Triage'], {});
        const { triageAction } = triage;
        return triageAction || '';
      })
      .catch((err) => {
        console.log('[IF_API', err);
        return '';
      }),
  ];

  return Promise.all(promiseQuery).then((results) => {
    const d0 = results[0];
    return {
      userAction: d0,
    };
  });
};

export default getEventTriage;
