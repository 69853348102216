import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import { get, round, isNumber } from 'lodash';
import moment from 'moment';
import { sortableHandle } from 'react-sortable-hoc';
import { Empty, Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';

import { BaseUrls } from '../../app/Constants';
import { Defaults, buildUrl } from '../../../common/utils';
import { MoveIcon } from '../../../lib/fui/icons';
import { EChart } from '../../share';
import { DashboardMessages } from '../../../common/dashboard/messages';

const DragHandle = sortableHandle(() => (
  <span className="hover-display-item" style={{ cursor: 'move', position: 'absolute', right: 8, top: 8 }}>
    <MoveIcon style={{ fontSize: 16 }} />
  </span>
));

const InsightsHealthScoreTrend = (props: Object) => {
  // eslint-disable-next-line
  const { intl, name } = props;
  const {
    // eslint-disable-next-line
    push,
    // eslint-disable-next-line
    updateLastActionInfo,
    // eslint-disable-next-line
    credentials,
    // eslint-disable-next-line
    userInfo,

    // eslint-disable-next-line
    refresh,
    // eslint-disable-next-line
    refreshTime,
    // eslint-disable-next-line
    environmentId,
    // eslint-disable-next-line
    systemId,
    // eslint-disable-next-line
    customerName,
    // eslint-disable-next-line
    startTime,
    // eslint-disable-next-line
    globalInfo,
    // eslint-disable-next-line
    updateState,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [option, setOption] = useState(undefined);

  const buildChartOption = (dataList) => {
    const firstIndex = R.findIndex((item) => Boolean(item.value), dataList);
    const lastIndex = R.findLastIndex((item) => Boolean(item.value), dataList);
    return {
      tooltip: {
        trigger: 'axis',
        confine: true,
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          const { name, seriesName, color, data } = params[0];
          const { realValue } = data;
          return ReactDOMServer.renderToStaticMarkup(
            <div>
              {name}
              <br />
              <span
                style={{
                  display: 'inline-block',
                  marginRight: 5,
                  borderRadius: 10,
                  width: 9,
                  height: 9,
                  backgroundColor: color,
                }}
              />
              {`${seriesName}: ${realValue}`}
            </div>,
          );
        },
      },
      grid: {
        left: 50,
        right: 30,
        top: 40,
        bottom: 40,
      },
      xAxis: {
        type: 'category',
        data: R.map((item) => item.name, dataList),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: intl.formatMessage(DashboardMessages.healthScore),
          type: 'bar',
          data: R.map((item) => {
            return {
              value: R.max(0, item.value),
              realValue: item.value,
            };
          }, dataList),
          markPoint:
            dataList.length > 0
              ? {
                  data: [
                    firstIndex >= 0 && firstIndex !== lastIndex
                      ? {
                          coord: [firstIndex, dataList[firstIndex].value],
                          value: dataList[firstIndex].value,
                        }
                      : {},
                    lastIndex >= 0
                      ? {
                          coord: [lastIndex, dataList[lastIndex].value],
                          value: dataList[lastIndex].value,
                        }
                      : {},
                  ],
                }
              : {},
        },
      ],
    };
  };

  useEffect(() => {
    let cancel = false;

    // UNSAFE_componentWillReceiveProps
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      systemId &&
      systemInfo &&
      startTime
    ) {
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').subtract(30, 'days').valueOf();
      const endTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();

      setLoading(true);
      updateState({ isLoadingHealthScore: true });
      updateLastActionInfo();
      fetchGet(getEndpoint('stats/global/systemanomalyscore', 2), {
        ...credentials,
        envName: environmentId,
        startTime: startTimestamp,
        endTime: endTimestamp,
        customerName: systemInfo.ownerUserName,
        systemIds: JSON.stringify([systemId]),
        shareUserFlag: systemInfo.ownerUserName !== credentials.userName && !userInfo.isAdmin,
        isHistorical: true,
      })
        .then((data) => {
          if (cancel) return;

          const systemScoreInfo = R.find((item) => item.systemName === systemId, data || []);
          const healthScoreMap = get(systemScoreInfo, 'healthScoreMap', {});

          let dataList = [];
          R.forEach((ts) => {
            const timestamp = ts * 86400000;
            const value = get(healthScoreMap, String(timestamp));
            dataList.push({
              timestamp,
              value: isNumber(value) ? round(value) : null,
            });
          }, R.range(startTimestamp / 86400000, endTimestamp / 86400000 + 1));

          // current day health score
          const avgHealthScore = get(healthScoreMap, String(endTimestamp));

          dataList = R.sortWith([R.ascend(R.prop('timestamp'))], dataList);
          dataList = R.map((item) => {
            const name = moment.utc(item.timestamp).format(Defaults.ShortDayFormat);
            return { ...item, name };
          }, dataList);

          const option = buildChartOption(dataList);
          setOption(option);
          updateState({
            isLoadingHealthScore: false,
            avgHealthScore,
          });
          setHasData(!R.isEmpty(healthScoreMap));
          setLoading(false);
        })
        .catch((err) => {
          if (cancel) return;
          console.error(err);
          updateState({
            isLoadingHealthScore: false,
            avgHealthScore: null,
          });
          setHasData(false);
          setLoading(false);
        });
    }

    return () => {
      cancel = true;
    };
  }, [refresh, refreshTime, environmentId, systemId, customerName]);

  return (
    <div
      className="full-height full-width flex-col health-card hover-display"
      style={{ backgroundColor: 'white', borderRadius: '2em', padding: '8px 8px', position: 'relative' }}
    >
      <div
        className="flex-row flex-center-justify bold link"
        style={{ textAlign: 'center', margin: '0 14px 8px 14px', height: 34 }}
        onClick={() => {
          push(
            buildUrl(
              BaseUrls.GlobalHealthSystem,
              {},
              { startTime, endTime: startTime, customerName, environmentId, systemId },
            ),
          );
        }}
      >
        {name}
      </div>
      <DragHandle />

      <Spin
        spinning={isLoading}
        wrapperClassName="spin-full-height flex-grow flex-center-align flex-center-justify overflow-hidden"
      >
        {!hasData && (
          <div className="full-height flex-row flex-center-align flex-center-justify">
            <Empty imageStyle={{ height: 60 }} />
          </div>
        )}
        {hasData && <EChart width="100%" height="100%" option={option} />}
      </Spin>
    </div>
  );
};

export default InsightsHealthScoreTrend;
