/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import { get, isNumber, isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { DownOutlined, RightOutlined, DoubleLeftOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Popover, Spin, Alert, Button, Skeleton } from 'antd';
import { InfiniteLoader } from 'react-virtualized';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { Container, AutoSizer, List, CellMeasurer, CellMeasurerCache } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Defaults, LogParser, LogRenderers, parseJSON, CellRenderers, parseLocation } from '../../../common/utils';

import { appFieldsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  instanceName: String,
  // eslint-disable-next-line
  clusterInfo: Object,
  // eslint-disable-next-line
  clustersSampleMsgMap: Object,
  // eslint-disable-next-line
  allPatternNidMap: Object,
  // eslint-disable-next-line
  clusterActiveTimeChange: Number,
  // eslint-disable-next-line
  startTimestamp: Number,
  // eslint-disable-next-line
  endTimestamp: Number,
  // eslint-disable-next-line
  featureToNidMapInfo: Object,
  // eslint-disable-next-line
  featureInfo: Object,
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  currentTheme: String,
  setLoading: Function,
  // eslint-disable-next-line
  hostAndPodIdList: Array,
  instanceDisplayNameMap: Object,
};

class LogEntriesList extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.state = {
      isLoading: false,

      page: 1,
      pageSize: 100,

      // showHighlightCategory: 'none',
      eventList: [],
      eventListTotal: 0,
      compressedSize: 0,
      originSize: 0,
      decreaseRatio: 0,

      activeIncident: null,
      selectInstance: undefined,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
      contextKeywordFilter: '',
      showContextModal: false,

      summarySettings: [],

      allExpand: false,
    };
    this.interval = 10 * 60 * 1000;
    this.LOADING_LAST_ROW = {
      eventType: 'normal',
      frequencyStr: '',
      hostId: undefined,
      index: 999999999999,
      instanceName: '',
      isWhitelistEvent: false,
      logToMetricValue: 0,
      patternId: null,
      patternName: null,
      podId: undefined,
      rawData: '',
      typeList: [],
      isLastRow: true,
    };
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.clusterActiveTimeChange !== nextProps.clusterActiveTimeChange) {
      if (this.state.allExpand || this.state.sortBy || this.state.sortDirection) {
        this.setState({ allExpand: false, sortBy: null, sortDirection: null }, () => {
          this.reloadData(nextProps, true);
        });
      } else {
        this.reloadData(nextProps, true);
      }
    }
  }

  @autobind
  async reloadData(props, force) {
    const {
      credentials,
      projects,
      projectName,
      instanceName,
      clusterInfo,
      allPatternNidMap,
      startTimestamp,
      endTimestamp,
      featureToNidMapInfo,
      featureInfo,
      isInfinityLoad,
      hostAndPodIdList,
    } = props;
    const { pageSize, allExpand, sortBy, sortDirection } = this.state;
    let { page, eventList: prevEventList } = this.state;
    page = force ? 1 : isInfinityLoad ? page + 1 : page;

    if (projectName && instanceName && clusterInfo && startTimestamp && endTimestamp) {
      this.setState({ isLoading: true, page: force ? 1 : page });
      const { featureIsJson, featureLabel, featureValue, featureIsNumber, featureInterval, featurePosition } =
        featureInfo;
      // get pattern ids
      const { isAllPattern, patternId } = clusterInfo || {};
      let patternIds = [patternId];
      if (isAllPattern) {
        if (featureLabel && featureValue) {
          patternIds = get(
            featureToNidMapInfo,
            [featureLabel, featureValue, startTimestamp],
            R.uniq(R.flatten(R.values(featureToNidMapInfo[featureLabel][featureValue]))),
          );
        } else {
          patternIds = get(
            allPatternNidMap,
            String(startTimestamp),
            R.uniq(R.flatten(R.values(allPatternNidMap || {}))),
          );
        }
      }
      patternIds = R.map((item) => Number(item), patternIds);
      const dayTimeMillis = moment.utc(startTimestamp).startOf('day').valueOf();
      const query = {
        ...credentials,
        projectName,
        instanceName,
        isFetchAll: isAllPattern,
        dayTimeMillis,
        startTimestamp,
        endTimestamp,
        patternIds: JSON.stringify(patternIds),
        pageNo: force ? 1 : page,
        pageSize,
      };
      if (featureIsJson) {
        query.jsonPath = featureLabel;
        query.featureWord = featureValue;
      }
      if (featureIsNumber) {
        query.featureInterval = featureInterval;
        query.featurePosition = featurePosition;
      } else {
        query.featureWord = featureValue;
        query.featureLabel = featureLabel;
      }
      if (!query.featureWord) query.featureWord = '';
      await fetchGet(getEndpoint('logsummarysettings'), {
        ...credentials,
        projectName,
      })
        .then((summarySettings) => {
          this.setState({ summarySettings });
        })
        .catch((err) => console.log(err));

      const requests = [fetchGet(getEndpoint('logstreamingevent'), query)];

      props.updateLastActionInfo();
      await Promise.all(requests)
        .then((results) => {
          const project = R.find(
            (project) => project.projectName === projectName || project.projectShortName === projectName,
            projects,
          );
          const projectLinkInfo = get(project, 'projectLinkInfo', []);

          // get log entries
          let eventList = R.filter((e) => e.timestamp || e.startTime, get(results[0], ['eventArray'], []));

          eventList = R.map((event) => {
            const { patternId, eventType, count, frequency, rawData, timestamp, startTime } = event;
            const typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', eventType).split('&'));
            const typeAndColor = LogParser.CalculateLogType({ eventType });

            let frequencyStr = '';
            if (frequency) {
              const percent = `${Math.abs(frequency).toFixed(2)}%`;
              const cmp = frequency > 0 ? 'higher' : 'lower';
              frequencyStr = `${isNumber(count) ? `Count: ${count}. ` : ''}Frequency is ${percent} ${cmp} than normal.`;
            }

            let rawDataJson;
            try {
              rawDataJson = JSON.parse(rawData);
              if (!isObject(rawDataJson)) rawDataJson = undefined;
            } catch (error) {
              // console.debug(error);
            }

            const timesTampNum = timestamp || startTime;
            const findHostAndPodInfo =
              R.find((item) => item[1]?.s <= timesTampNum && timesTampNum <= item[1]?.e, hostAndPodIdList || []) || [];

            return {
              ...event,
              isExpand: allExpand,
              // eslint-disable-next-line camelcase
              __content_expand_state: allExpand,
              timestamp: timesTampNum,
              rawDataJson,
              typeList,
              typeAndColor,
              patternId,
              frequencyStr,
              projectLinkInfo,
              hostId: findHostAndPodInfo[0]?.h,
              podId: findHostAndPodInfo[0]?.p,
            };
          }, eventList);

          const eventListTotal = get(results[0], ['totalMatched']);
          const compressedSize = get(results[0], ['compressedSize']);
          const originSize = get(results[0], ['originSize']);
          let decreaseRatio;
          if (isNumber(compressedSize) && isNumber(originSize)) {
            decreaseRatio = (originSize - compressedSize) / originSize;
          }

          eventList = this.sortData(eventList, sortBy, sortDirection);
          prevEventList = this.sortData(prevEventList, sortBy, sortDirection);

          const newEventList = force ? eventList : [...prevEventList, ...eventList];

          if (this.listNode && !force) {
            this.cellMeasureCache.clearAll();
            this.listNode.forceUpdateGrid();
            setTimeout(() => {
              this.listNode.scrollToRow(prevEventList.length - 1);
              this.listNode.forceUpdateGrid();
            }, 0);
          }

          this.setState(
            {
              isLoading: false,
              errorMessage: null,
              eventList: newEventList,
              eventListTotal,
              compressedSize,
              originSize,
              decreaseRatio,
            },
            () => {
              this.cellMeasureCache.clearAll();
              if (this.listNode && force) this.listNode.forceUpdateGrid();
              if (this.listNode && !force) this.listNode.scrollToRow(prevEventList.length - 1);
              this.props.setLoading(false);
            },
          );
        })
        .catch((err) => {
          this.cellMeasureCache.clearAll();
          this.setState({
            isLoading: false,
            errorMessage: err,
            eventList: force ? [] : prevEventList || [],
            eventListTotal: 0,
            compressedSize: 0,
            originSize: 0,
            decreaseRatio: 0,
          });
          this.props.setLoading(false);
        });
    }
  }

  @autobind
  getNextDay(props) {
    const { location, startTimestamp } = props;
    const params = parseLocation(location);
    const { endTime } = params;
    const currentDate = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
    const endDate = moment.utc(endTime).format(Defaults.ShortDayFormat);
    let nextDay = null;

    if (currentDate !== endDate) {
      nextDay = moment.utc(currentDate).add(1, 'days').startOf('day').valueOf();
    }
    return nextDay;
  }

  @autobind
  getDecompressData({ clustersSampleMsgMap, dayTimeMillis, logDiffOperation, rawData }) {
    let newRawData = '';

    // get diff cluster info
    if (logDiffOperation) {
      const json = parseJSON(rawData);
      const { Cluster, Diff } = json || {};
      // get cluster simple msg
      const diffSampleMsg = get(clustersSampleMsgMap, [Cluster || '', dayTimeMillis], '');

      const diffList = parseJSON(Diff) || [];
      // get compress diff info
      const { operationMap } = logDiffOperation || {};
      const diff = get(operationMap, ['diff'], []);

      let diffListIndex = 0;
      let sampleMsgIndex = 0;
      R.forEach((regStr) => {
        const [tp, idx] = R.split(',', regStr);
        if (tp === 'E') {
          newRawData = `${newRawData}${R.slice(sampleMsgIndex, sampleMsgIndex + Number(idx), diffSampleMsg)}`;
          sampleMsgIndex += Number(idx);
        } else if (tp === 'D') {
          sampleMsgIndex += Number(idx);
        } else if (tp === 'I') {
          newRawData = `${newRawData}${diffList[diffListIndex]}`;
          diffListIndex += 1;
        }
      }, diff);
    } else {
      newRawData = rawData;
    }

    return newRawData;
  }

  @autobind
  renderHostAndPod(rowData, cellKey) {
    const { instanceDisplayNameMap, projects, projectName } = this.props;
    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects,
    );
    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, rowData[cellKey], {
      pn: project?.projectShortName,
      owner: project?.owner,
    });
    return (
      <Popover content={<div>{instanceStr}</div>}>
        <div className="hidden-line-with-ellipsis clickable">{instanceStr}</div>
      </Popover>
    );
  }

  @autobind
  loadingRendererWrapper(rowData, rowIndex) {
    const { isLoading, eventListTotal } = this.state;
    const { isLastRow } = rowData;
    const isDataLoad = eventListTotal <= rowIndex;

    if (isLoading) {
      return (
        <div style={{ lineHeight: '40px', alignItems: 'center' }}>
          <Skeleton.Button size="small" active />
        </div>
      );
    } else if (isLastRow) {
      if (isDataLoad) {
        return <div />;
      }
      return (
        <a
          style={{ lineHeight: '40px', minWidth: 130 }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState({ sortBy: null, sortDirection: null }, async () => {
              await this.reloadData({ ...this.props, isInfinityLoad: true });
            });
          }}
        >
          Load more: +1 page
        </a>
      );
    }
    return this.renderTime(rowData);
  }

  @autobind
  renderListItem(events, hasHostIdAndPodId) {
    return ({ key, index: rowIndex, style, parent }) => {
      const rowData = events[rowIndex];
      if (!rowData) return null;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style, minHeight: 40 }}>
            <div className="row-column" style={{ width: 110 }}>
              {this.loadingRendererWrapper(rowData, rowIndex)}
            </div>
            <div className="row-column" style={{ width: 80 }}>
              {rowData?.patternId}
            </div>
            {hasHostIdAndPodId && (
              <>
                <div className="row-column" style={{ width: 140 }}>
                  {this.renderHostAndPod(rowData, 'hostId')}
                </div>
                <div className="row-column" style={{ width: 140 }}>
                  {this.renderHostAndPod(rowData, 'podId')}
                </div>
              </>
            )}
            <div className="row-column" style={{ width: 100, flex: 1 }}>
              {this.renderLogEntry(rowIndex, rowData)}
            </div>
            <div className="row-column" style={{ width: 40 }}>
              {this.renderType(rowData)}
            </div>
            <div className="row-column" style={{ width: 70 }}>
              {this.renderControl(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderTime(rowData) {
    const { timestamp } = rowData;
    return <div>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</div>;
  }

  @autobind
  renderType(rowData) {
    const { intl } = this.props;
    const { eventType, isLastRow } = rowData;

    if (isLastRow) {
      return <div />;
    }

    return (
      <div className="flex-row flex-wrap full-width">
        {CellRenderers.logShortTypeRenderer({ intl, type: eventType })}
      </div>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { patternName, patternId } = rowData;
    const { patternNameStr } = Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true });
    return (
      <Popover mouseEnterDelay={0.3} title={null} content={patternNameStr}>
        <div className="hidden-line-with-ellipsis inline-block max-width">{patternNameStr}</div>
      </Popover>
    );
  }

  @autobind
  onToggleCollapse() {
    this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
  }

  @autobind
  renderLogEntry(rowIndex, rowData) {
    const { intl, currentTheme } = this.props;
    const { rawData, rawDataJson, frequencyStr, isSmallCluster, anomalyWords, outlierValue, isExpand, isLastRow } =
      rowData;
    const { summarySettings } = this.state;
    if (isLastRow) {
      return <div />;
    }

    if (rawDataJson) {
      return (
        <LogRenderers.RenderLogContent
          intl={intl}
          rawData={rawData}
          rawDataJson={rawDataJson}
          frequencyStr={frequencyStr}
          isSmallCluster={isSmallCluster}
          anomalyWordList={anomalyWords}
          outlierValue={outlierValue}
          owner={rowData}
          summarySettings={summarySettings}
          onToggleCollapse={this.onToggleCollapse}
          currentTheme={currentTheme}
        />
      );
    }

    return (
      <LogRenderers.ExpandLogContent
        intl={intl}
        rawData={rawData}
        rawDataJson={rawDataJson}
        frequencyStr={frequencyStr}
        isSmallCluster={isSmallCluster}
        anomalyWordList={anomalyWords}
        outlierValue={outlierValue}
        isExpand={isExpand}
        onExpand={this.handleExpandRow(rowIndex)}
        rows={1}
      />
    );
  }

  @autobind
  handleExpandRow(rowIndex) {
    return (isExpand) => {
      const { eventList } = this.state;
      this.setState(
        {
          eventList: update(eventList, {
            [rowIndex]: { $set: { ...(eventList[rowIndex] || {}), isExpand } },
          }),
        },
        () => {
          this.cellMeasureCache.clear(rowIndex);
          if (this.listNode) this.listNode.forceUpdateGrid();
        },
      );
    };
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;
    const { eventType } = rowData;

    return (
      <>
        {eventType !== 'normal' && (
          <Button size="small" onClick={() => this.handleLogContextOneMinClick(rowData)}>
            {intl.formatMessage(eventMessages.context)}
          </Button>
        )}
      </>
    );
  }

  @autobind
  handleLogContextOneMinClick(rowData) {
    const { projectName, instanceName } = this.props || {};
    const { timestamp, startTime } = rowData;
    const selectStartTimestamp = timestamp ? timestamp - 9 * 60 * 1000 : startTime - 9 * 60 * 1000;
    const selectEndTimestamp = timestamp + 60 * 1000 || startTime + 60 * 1000;
    this.setState(
      {
        activeIncident: rowData,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp,
        selectEndTimestamp,
        contextKeywordFilter: '',
      },
      () => {
        this.setState({
          showContextModal: true,
        });
      },
    );
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('timestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);
    return sortList;
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const { eventList } = this.state;
          const sortList = this.sortData(eventList, name, sortDir);
          this.cellMeasureCache.clearAll();
          this.setState({ eventList: sortList });
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  isRowLoaded({ index }) {
    const { eventList } = this.state;
    return eventList && eventList.length > index;
  }

  @autobind
  handleAllExpand() {
    const { allExpand, eventList } = this.state;
    this.setState(
      {
        allExpand: !allExpand,
        // eslint-disable-next-line camelcase
        eventList: R.map((item) => ({ ...item, isExpand: !allExpand, __content_expand_state: !allExpand }), eventList),
      },
      () => {
        this.cellMeasureCache.clearAll();
        if (this.listNode) {
          this.listNode.forceUpdateGrid();
        }
      },
    );
  }

  render() {
    const { intl } = this.props;
    const { isLoading, errorMessage } = this.state;
    const { eventListTotal, sortBy, sortDirection, originSize, compressedSize, decreaseRatio, allExpand } = this.state;
    let { eventList } = this.state;
    const needAddloading = eventListTotal !== 0 && eventList.length !== 0 && eventList.length < eventListTotal;

    if (needAddloading) eventList = [...eventList, this.LOADING_LAST_ROW];
    const hasHostIdAndPodId = R.find((item) => item?.hostId || item?.podId, eventList || []);

    return (
      <Container className="full-width full-height flex-col">
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          {errorMessage && (
            <div className="full-width full-height" style={{ padding: 16 }}>
              <Alert type="error" message={String(errorMessage)} showIcon />
            </div>
          )}

          {!errorMessage && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 8, padding: '0 10px' }}>
              <div className="flex-grow flex-row flex-center-align">
                {LogRenderers.CompressTitle({ originSize, compressedSize, decreaseRatio }, intl)}
              </div>
            </div>
          )}

          {!errorMessage && (
            <div className="full-width full-height flex-col">
              <div className="flex-grow flex-min-height">
                <InfiniteLoader
                  isRowLoaded={this.isRowLoaded}
                  rowCount={eventListTotal}
                  loadMoreRows={() => {}}
                  threshold={3}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer
                      onResize={() => {
                        this.cellMeasureCache.clearAll();
                        if (this.listNode) this.listNode.forceUpdateGrid();
                        this.forceUpdate();
                      }}
                    >
                      {({ width, height }) => {
                        return (
                          <div className="event-list">
                            <div
                              className="event-list-header"
                              style={{
                                height: this.listHeaderHeight,
                                width,
                                paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                              }}
                            >
                              <div
                                className="header-column"
                                style={{ width: 110 }}
                                onClick={this.headerClick('timestamp')}
                              >
                                <span>{intl.formatMessage(appFieldsMessages.time)}</span>
                                {this.sortIcon(sortBy, sortDirection, 'timestamp')}
                              </div>
                              <div
                                className="header-column"
                                style={{ width: 80 }}
                                onClick={this.headerClick('patternId')}
                              >
                                <span>{intl.formatMessage(appFieldsMessages.pattern)}</span>
                                {this.sortIcon(sortBy, sortDirection, 'patternId')}
                              </div>
                              {hasHostIdAndPodId && (
                                <>
                                  <div
                                    className="header-column"
                                    style={{ width: 140 }}
                                    onClick={this.headerClick('hostId')}
                                  >
                                    <span>Host ID</span>
                                    {this.sortIcon(sortBy, sortDirection, 'hostId')}
                                  </div>
                                  <div
                                    className="header-column"
                                    style={{ width: 140 }}
                                    onClick={this.headerClick('podId')}
                                  >
                                    <span>Pod ID</span>
                                    {this.sortIcon(sortBy, sortDirection, 'podId')}
                                  </div>
                                </>
                              )}
                              <div className="header-column" style={{ width: 100, flex: 1 }}>
                                {intl.formatMessage(eventMessages.shortDescription)}
                                <span onClick={this.handleAllExpand}>
                                  <DoubleLeftOutlined rotate={allExpand ? 90 : -90} style={{ marginLeft: 4 }} />
                                </span>
                              </div>
                              <div
                                className="header-column"
                                style={{ width: 40 }}
                                onClick={this.headerClick('eventType')}
                              >
                                <span>{intl.formatMessage(appFieldsMessages.type)}</span>
                                {this.sortIcon(sortBy, sortDirection, 'eventType')}
                              </div>
                              <div className="header-column" style={{ width: 70 }} />
                            </div>
                            <List
                              className="event-list-grid corner-0-0-8-8"
                              ref={(listNode) => {
                                this.listNode = listNode;
                                registerChild(listNode);
                              }}
                              onRowsRendered={onRowsRendered}
                              width={width}
                              height={height - this.listHeaderHeight}
                              rowCount={eventList.length}
                              overscanRowCount={4}
                              deferredMeasurementCache={this.cellMeasureCache}
                              rowHeight={this.cellMeasureCache.rowHeight}
                              rowRenderer={this.renderListItem(eventList, hasHostIdAndPodId)}
                              onScrollbarPresenceChange={({ horizontal, vertical }) => {
                                if (vertical) {
                                  this.listNodeHeaderScrollbar = true;
                                  this.cellMeasureCache.clearAll();
                                  if (this.listNode) this.listNode.forceUpdateGrid();
                                } else {
                                  this.listNodeHeaderScrollbar = false;
                                }
                                this.forceUpdate();
                              }}
                            />
                          </div>
                        );
                      }}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              </div>
            </div>
          )}
        </Spin>

        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeIncident}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            keywordFilter={this.state.contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
            useTimeRange
          />
        )}
      </Container>
    );
  }
}

const ClusterLogEntries = injectIntl(LogEntriesList);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects, currentTheme } = state.app;
    const { credentials } = state.auth;

    return {
      location,
      loadStatus,
      projects,
      credentials,
      currentTheme,
    };
  },
  { updateLastActionInfo },
)(ClusterLogEntries);
