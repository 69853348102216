/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import * as R from 'ramda';
import { toInteger } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseJSON } from '../utils';

const getCausalIncidentList = (credentials: Credentials, params: Object) => {
  const { causalKey, customerName } = params;

  return fetchGet(getEndpoint('eventsrelationlist'), {
    ...credentials,
    causalKey,
    customerName,
  }).then((data) => {
    const incidentList = R.sort(
      (incidentA, incidentB) => incidentB.startTimestamp - incidentA.startTimestamp,
      R.map((incidentResult) => {
        const casualStartPoint = parseJSON(incidentResult.casualStartPoint) || [];
        return {
          ...incidentResult,
          id: incidentResult.relationKey,
          causalKey: incidentResult.causalGroupKey,
          fileName: incidentResult.fileName,
          relationKey: incidentResult.relationKey,
          customerName: incidentResult.userName,
          status: incidentResult.status,
          requestTime: parseInt(incidentResult.requestTime, 10),
          finishTime: parseInt(incidentResult.finishTime, 10),
          startTimestamp: parseInt(incidentResult.relationStartTime, 10),
          endTimestamp: parseInt(incidentResult.relationEndTime, 10),
          postFix: incidentResult.postFix,
          casualStartPoint,
        };
      }, data.data),
    );

    return {
      incidentList,
    };
  });
};

export default getCausalIncidentList;
