/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseJSON } from '../utils';

const getCausalDependencyGraph = (credentials: Credentials, params: Object) => {
  const { causalKey, customerName, startTimestamp, endTimestamp } = params;

  const requests = [];

  // Use the time as the incident id.
  requests.push(
    fetchGet(getEndpoint('dependencyrelation', 2), {
      ...credentials,
      causalKey,
      customerName,
      startTime: startTimestamp,
      endTime: endTimestamp,
      format: 'json',
      merge: true,
    }).then((data) => {
      const { dependencyGraph, metaData } = data;

      // parse data
      const dependencyList = [];
      R.forEach((item) => {
        const { s: elem1, t: elem2 } = item;
        dependencyList.push({
          elem1,
          elem2,
        });
      }, parseJSON(dependencyGraph) || []);

      // II-13193 comment out useless code first, remove it if no isssues.
      // const instanceMapping = {};
      // R.forEachObjIndexed((val, instance) => {
      //   const instanceInfoList = val.instanceInfoList || [];
      //   const hasComponentList = R.filter(
      //     (item) => item.componentName && item.componentName.indexOf(item.instanceName) === -1,
      //     instanceInfoList,
      //   );
      //   const componentName = hasComponentList.length > 0 ? hasComponentList[0].componentName : null;
      //   instanceMapping[instance] = componentName;
      // }, metaData || {});

      return {
        // metaData,
        // instanceMapping,
        relations: dependencyList,
      };
    }),
  );

  return Promise.all(requests).then((results) => {
    return {
      dependencyGroupData: results[0],
    };
  });
};

export default getCausalDependencyGraph;
