import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link, Redirect } from 'react-router-dom';
import VLink from 'valuelink';
import cx from 'classnames';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';

import type { State, Message } from '../../common/types';
import { hideAppLoader } from '../../common/app/actions';
import { userTypeCheck } from '../../common/auth/actions';
import { buildLocation, parseQueryString } from '../../common/utils';
import { appFieldsMessages, appMessages } from '../../common/app/messages';
import { authMessages } from '../../common/auth/messages';
import { CenterPage, LocaleSelector } from '../app/components';

type Props = {
  appLoaderVisible: boolean,
  redirectLoginURL: String,
  idpSettingMap: Object,
  intl: Object,
  push: Function,
  location: Object,
  hideAppLoader: Function,
  userTypeCheck: Function,
  isExisted: Boolean,
  location: Object,
};

class IDPOptionsCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const params = parseQueryString(location.search);
    const { userName } = params;

    this.state = {
      hasError: false,
      userName,
    };
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
  }

  @autobind
  handleClickIcon(idp) {
    const { push } = this.props;
    const { userName } = this.state;
    return (e) => {
      e.preventDefault();
      push(buildLocation('/auth/idpregister', {}, { idp, userName }));
    };
  }

  render() {
    const { intl, push, currentTheme } = this.props;
    const { hasError } = this.state;

    return (
      <CenterPage intl={intl} push={push} className="auth" currentTheme={currentTheme}>
        <form className={`ui ${hasError ? 'error' : ''} form`}>
          <LocaleSelector style={{ marginBottom: 10 }} />
          <h3 style={{ justifyContent: 'center', textAlign: 'center' }}>
            {intl.formatMessage(authMessages.chooseAnotherLoginMethod)}
          </h3>
          <div className="flex-grow" style={{ margin: 20, justifyContent: 'center', textAlign: 'center' }}>
            <Button type="primary" onClick={this.handleClickIcon('MS')}>
              Microsoft AD
            </Button>
            <Button type="primary" style={{ marginLeft: 8 }} onClick={this.handleClickIcon('LDAPS')}>
              LDAPS
            </Button>
            <Button type="primary" style={{ marginLeft: 8 }} onClick={this.handleClickIcon('Azure')}>
              Azure AD
            </Button>
            <Button type="primary" style={{ marginLeft: 8 }} onClick={this.handleClickIcon('Google')}>
              Google
            </Button>
          </div>

          <div className="field" style={{}}>
            <span style={{ marginRight: 8 }}>{intl.formatMessage(authMessages.hintsForgot)}</span>
            <Link tabIndex={-1} to="/auth/forgotPassword">
              {intl.formatMessage(authMessages.hintsPassword)}
            </Link>
            <span style={{ margin: '0 4px' }}>{intl.formatMessage(authMessages.hintsOr)}</span>
            <Link tabIndex={-1} to="/auth/forgotUsername">
              {intl.formatMessage(authMessages.hintsUserName)}
            </Link>
          </div>

          <div className="field" style={{}}>
            <span style={{ marginRight: 8 }}>{intl.formatMessage(authMessages.hintsQuestions)}</span>
            <Button type="link" href="https://insightfinder.com/contact" style={{ padding: 0 }}>
              {intl.formatMessage(authMessages.contactSupport)}
            </Button>
          </div>
        </form>
      </CenterPage>
    );
  }
}

const IDPOptions = injectIntl(IDPOptionsCore);
export default connect(
  (state: State) => ({
    appLoaderVisible: state.app.appLoaderVisible,
    location: state.router.location,
    currentTheme: state.app.currentTheme,
    idpSettingMap: state.auth.idpSettingMap,
  }),
  { push, userTypeCheck, hideAppLoader },
)(IDPOptions);
