import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import tinygradient from 'tinygradient';
import { debounce, get } from 'lodash';

import { Spin, message } from 'antd';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';

import DashCellTitle from './DashCellTitle';
import HorizontalchartDetail from './HorizontalchartDetail';
import { anomalousOptionHtml } from './OptionHtml';
import { EChart } from '../../share';
import { getCacheData, setCacheData } from '../utils';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

const pieChartStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
};

const topTitleDistinguish = {
  anomalousProjects: 'Projects',
  anomalousComponents: 'Accounts',
  anomalousInstances: 'Services',
};

const typeDistinguish = {
  anomalousProjects: 'project',
  anomalousComponents: 'component',
  anomalousInstances: 'instance',
};

const titleDistinguish = {
  anomalousProjects: 'Projects(Top cost)',
  anomalousComponents: 'Accounts(Top cost)',
  anomalousInstances: 'Services(Top cost)',
};

const nameDistinguish = {
  anomalousProjects: 'projectName',
  anomalousComponents: 'componentName',
  anomalousInstances: 'instanceName',
};

const NoDataTitleDistinguish = {
  anomalousProjects: 'projects cost',
  anomalousComponents: 'components cost',
  anomalousInstances: 'incidents cost',
};

const getViewData = (view) => (view || view === 0 ? view : 0);
const floatFixed = (num) => (num ? Number(Number.parseFloat(num).toFixed(3)) : num);

export default function TopCostCahrt({
  type,
  credentials,
  location,
  intl,
  globalInfo,
  currentTheme,
  systemId,
  startTime,
  endTime,
  environmentId,
  cacheKey,
  systemInfo,
  projects,
  instanceDisplayNameMap,
  widgetInfo,
  getPopUpPosition,
}: Object) {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    option: {},
    barData: [],
    showHorizontalchartDetail: false,
  });
  const echartBoxRef = useRef(null);
  const echartRef = useRef(null);
  const { barData, option, showHorizontalchartDetail } = state;

  const getPieOption = (data, category, gradientColorsList) => {
    const gradient = tinygradient(gradientColorsList);
    const colorsRgb = gradient.rgb(data.length < 2 ? 2 : data.length);
    const colors = R.map((x) => {
      return x.toHexString();
    }, colorsRgb);
    const option = {
      backgroundColor: 'transparent',
      position: (pos, params, dom, rect, size) => {
        return getPopUpPosition(widgetInfo, pos, size);
      },
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'item',
        enterable: true,
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          const { data } = params;
          return anomalousOptionHtml({
            data,
            intl,
            typeName: nameDistinguish[type],
            isCost: true,
            instanceDisplayNameMap,
          });
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      xAxis: {
        type: 'value',
        axisLine: { show: true },
        splitArea: {
          show: false,
        },
        axisTick: {
          show: false,
          inside: true,
        },
        axisLabel: {
          show: false,
        },
        splitLine: { show: false },
      },
      yAxis: {
        type: 'category',
        inverse: true,
        data: category,
        axisTick: {
          show: false,
          inside: true,
        },
        axisLabel: {
          fontSize: 11,
          width: '100',
          overflow: 'truncate',
          formatter: (value) => {
            const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, value);
            return instanceStr;
          },
        },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [
        {
          name: titleDistinguish[type],
          type: 'bar',
          data,
          barMaxWidth: '36px',
          itemStyle: {
            color: (item) => {
              const { dataIndex } = item;
              return colors[dataIndex];
            },
          },
          emphasis: {
            itemStyle: {
              color: '#097fd7',
              borderWidth: 0,
            },
          },
        },
      ],
      grid:
        data.length > 0
          ? { top: 30, left: '43%', right: '23%', bottom: 10 }
          : { top: 30, left: 10, right: 10, bottom: 10 },
      label: {
        show: true,
        position: 'right',
        formatter: (params) => {
          const { data } = params;
          let scoreVla = data.value;
          scoreVla = `${Number(Number.parseFloat(scoreVla).toFixed(2))}`;
          return `$ ${scoreVla}`;
        },
      },
      itemStyle: {
        borderRadius: [0, 5, 5, 0],
      },
    };
    return option;
  };

  const parseData = (data) => {
    const newData = R.map(
      (item) => ({
        ...item,
        value: getViewData(floatFixed(item.cost)),
        projectDisplayName: R.find((_item) => _item.projectShortName === item.projectName, projects || [])
          ?.projectDisplayName,
      }),
      data || [],
    );
    const takeData = R.take(3)(newData);
    const categoryList = R.map(
      (item) =>
        nameDistinguish[type] === 'projectName'
          ? item.projectDisplayName || item[nameDistinguish[type]]
          : item[nameDistinguish[type]],
      takeData || [],
    );
    setState({ option: getPieOption(takeData, categoryList, ['#043e7d', '#6abbf7']), barData: newData || [] });
  };

  const reloadData = (systemInfo) => {
    const params = {
      ...credentials,
      customerName: systemInfo?.customerName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      limit: 20,
      granularity: typeDistinguish[type],
    };
    const apiData = getCacheData({ cacheKey, url: 'topCosts', ...params });
    if (apiData) {
      parseData(apiData);
      return;
    }
    if (!systemId) return;
    setIsLoading(true);
    fetchGet(getEndpoint('top-costs'), R.clone(params))
      .then(async (data) => {
        const { success, message: msg, topCosts } = data;
        if (success || success === undefined) {
          parseData(topCosts);
          setCacheData({ cacheKey, url: 'topCosts', ...params }, topCosts);
        } else {
          console.error(msg);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e.message || String(e));
      });
  };

  useEffect(() => {
    if (systemInfo) {
      reloadData(systemInfo);
    } else {
      setState({ option: getPieOption([], [], ['#043e7d', '#6abbf7']) });
    }
    return () => {
      setIsLoading(false);
    };
  }, [systemId]);

  useEffect(() => {
    if (echartRef.current) {
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.setOption(option);
    }
  }, [currentTheme]);

  const hasHealthOption = ((option?.series || [])[0]?.data || []).length < 1;
  return (
    <Spin spinning={isLoading} wrapperClassName="spin-base overflow-x-hidden overflow-y-auto">
      <DashCellTitle
        title={topTitleDistinguish[type]}
        subTitle="Top cost"
        type={type}
        fillListFlag={barData.length > 0}
        setShowDetail={(showHorizontalchartDetail) => setState({ showHorizontalchartDetail })}
        setTypeName={(typeName) => {}}
      />

      <div className="flex-row flex-center-align" style={{ height: 'calc(100% - 48px)' }} ref={echartBoxRef}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          {!hasHealthOption && (
            <div style={{ position: 'absolute', left: '50%', transform: 'translate(-15%, 0)', fontWeight: 'bold' }}>
              Cost
            </div>
          )}
          <EChart
            setRef={(chart) => {
              echartRef.current = chart;
            }}
            option={option}
            style={pieChartStyle}
            className="chart-pie"
            theme={currentTheme}
          />
          {hasHealthOption && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-55%, -30%)',
                color: 'var(--text-color-secondary)',
                width: 160,
              }}
            >
              No data for this time period
            </div>
          )}
        </div>
      </div>

      {showHorizontalchartDetail && (
        <HorizontalchartDetail
          startTime={startTime}
          endTime={endTime}
          intl={intl}
          barData={barData}
          open={showHorizontalchartDetail}
          onCancel={() => setState({ showHorizontalchartDetail: false })}
          typeName={type}
          titleDistinguish={titleDistinguish}
          nameDistinguish={nameDistinguish}
          isCost
          currentTheme={currentTheme}
          systemId={systemId}
          instanceDisplayNameMap={instanceDisplayNameMap}
        />
      )}
    </Spin>
  );
}
