/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
// import { get } from 'lodash';
import { autobind } from 'core-decorators';

import { Container, AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';

type Props = {
  className: ?String,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  credentials: Object,
  userInfo: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  activeCausalName: String,
  nameFilter: ?String,
  causalGroupList: Array<Object>,
  onSelected: Function,
  projects: Array<Object>,
};

class CausalGroupList extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 80,
    });

    const groupList = this.convertData(props);
    this.state = { groupList };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.causalGroupList !== nextProps.causalGroupList ||
      this.props.nameFilter !== nextProps.nameFilter ||
      this.props.activeCausalName !== nextProps.activeCausalName
    ) {
      const groupList = this.convertData(nextProps);
      this.setState({ groupList }, () => {
        this.cellMeasureCache.clearAll();
        if (this.listNode) this.listNode.forceUpdateGrid();
        this.forceUpdate();
      });
    }
  }

  @autobind
  convertData(props) {
    const { causalGroupList } = props;
    const nameFilter = props.nameFilter.toLowerCase();
    const groupFilter = R.filter((g) => g.causalName.toLowerCase().indexOf(nameFilter) >= 0);
    const groupList = groupFilter(causalGroupList);
    return groupList;
  }

  @autobind
  handleGroupClick(causalGroup) {
    return (e) => {
      e.preventDefault();

      const { onSelected } = this.props;
      if (onSelected) {
        onSelected(causalGroup);
      }
    };
  }

  @autobind
  renderListItem(groupList) {
    return ({ key, index: rowIndex, style, parent }) => {
      const rowData = groupList[rowIndex];
      if (!rowData) return null;

      const { userInfo, activeCausalName, projects } = this.props;
      const { causalName, projectList, owner } = rowData;

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div
            className={`event-list-row clickable${causalName === activeCausalName ? ' active' : ''}`}
            style={{ ...style }}
            onClick={this.handleGroupClick(rowData)}
          >
            <div className="row-column" style={{ width: 80, flex: 1, padding: '8px 12px' }}>
              <div className="flex-col full-width" style={{}}>
                <div style={{ fontWeight: 'bold', marginBottom: 4 }}>
                  {causalName}
                  {!userInfo.isAdmin && userInfo.userName !== owner && (
                    <span className="light-label" style={{ marginLeft: 4 }}>
                      [shared]
                    </span>
                  )}
                </div>
                {R.map((project) => {
                  const projectDisplayName =
                    R.find((item) => project.projectName === item.projectShortName, projects || [])
                      ?.projectDisplayName || project.projectName;
                  return (
                    <div
                      key={`${project.projectName}@${project.customerName || owner}`}
                      className="full-width"
                      style={{ color: 'gray', wordBreak: 'break-all' }}
                    >
                      {`${projectDisplayName}@${project.customerName || owner}`}
                    </div>
                  );
                }, projectList)}
              </div>
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  render() {
    const { className } = this.props;
    const { groupList } = this.state;
    return (
      <Container className={`flex-grow flex-col flex-min-height ${className || ''}`}>
        <div className="flex-grow event-list">
          <AutoSizer>
            {({ width, height }) => (
              <List
                className="event-list-grid"
                style={{ border: 'none' }}
                ref={(listNode) => {
                  this.listNode = listNode;
                }}
                width={width}
                height={height}
                rowCount={groupList.length}
                deferredMeasurementCache={this.cellMeasureCache}
                rowHeight={this.cellMeasureCache.rowHeight}
                rowRenderer={this.renderListItem(groupList)}
              />
            )}
          </AutoSizer>
        </div>
      </Container>
    );
  }
}

export default CausalGroupList;
