import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Button } from 'antd';

import { State } from '../../common/types';
import { Container } from '../../lib/fui/react';
import { setShowDetailsFrame } from '../../common/app/actions';

const DetailsFrameHeader = ({ header, onCancel, setShowDetailsFrame }: Object) => {
  return (
    <Container breadcrumb className="flex-row" style={{ marginBottom: 0 }}>
      <div className="flex-row flex-center-align" style={{ width: 'fit-content', fontSize: 14 }}>
        {R.map((item) => {
          const { action } = item;
          if (action === 'back') {
            return (
              <Button
                key={item.title}
                type="link"
                style={{ padding: 0, fontSize: 14 }}
                onClick={() => {
                  setShowDetailsFrame(false);
                  onCancel();
                }}
              >
                {item.title}
              </Button>
            );
          } else {
            return (
              <div key={item.title} style={{ marginLeft: 4 }}>
                / {item.title}
              </div>
            );
          }
        }, header || [])}
      </div>
    </Container>
  );
};

const DetailsFrameContent = ({ children }: Object) => {
  return (
    <div
      className="flex-grow flex-min-height flex-col content-bg flex-min-width corner-10"
      style={{ margin: '0 16px 16px 16px', padding: 8 }}
    >
      {children}
    </div>
  );
};

const CreatePortalDetailsFrame = ({ setShowDetailsFrame, domKey, onCancel, header, children }: Object) => {
  useEffect(() => {
    return () => {
      setShowDetailsFrame(false);
    };
  }, []);
  return createPortal(
    <div className="flex-col full-height">
      <DetailsFrameHeader header={header} onCancel={onCancel} setShowDetailsFrame={setShowDetailsFrame} />
      <DetailsFrameContent>{children}</DetailsFrameContent>
    </div>,
    document.getElementById('DETAILS_FRAME'),
    domKey || `${+new Date()}`,
  );
};

export default connect(
  (state: State) => {
    const { credentials, userInfo } = state.auth;
    return { credentials, userInfo };
  },
  { setShowDetailsFrame },
)(CreatePortalDetailsFrame);
