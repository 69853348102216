import React, { useCallback, useEffect, useRef, useState } from 'react';

import fetchGet from '../apis/fetchGet';
import getEndpoint from '../apis/getEndpoint';
import { useExternalScript } from './ScriptUtil'; // global renderers

// global renderers
const LoginRenderers = {
  GoogleLogin: ({ width = 200, onGoogleSignIn = () => {} }: Object) => {
    const googleSignInButton = useRef(null);
    const [clientId, setClientId] = useState();
    const externalScript = 'https://accounts.google.com/gsi/client';
    const scriptStatus = useExternalScript(externalScript);

    const onCallBackFunction = useCallback(
      (clientId) => {
        if (clientId) {
          try {
            if (!window.google) {
              console.error('[IF] google auth not initialize');
              return;
            }

            window.google.accounts.id.initialize({
              // eslint-disable-next-line camelcase
              client_id: clientId,
              callback: onGoogleSignIn,
            });
            window.google.accounts.id.renderButton(googleSignInButton.current, {
              theme: 'outline',
              size: 'medium',
              text: 'signin_with',
              width,
              locale: 'en',
            });
          } catch (e) {
            console.error('[IF] google auth initialize error', e);
          }
        }
      },
      [googleSignInButton],
    );

    useEffect(() => {
      fetchGet(getEndpoint('idpClientID'), {
        idpType: 'Google',
      })
        .then((data) => {
          const { success, clientId } = data || {};
          if (success) {
            setClientId(clientId);
          }
          setTimeout(() => {
            onCallBackFunction(clientId);
          }, 1000);
        })
        .catch((err) => {
          // hard code
          // setClientId('30118187957-vl22ph8t30hbvlat0lvq22ncn2ugg2im.apps.googleusercontent.com');
        });

      return () => {};
    }, []);

    useEffect(() => {
      if (scriptStatus === 'ready') {
        onCallBackFunction(clientId);
      }
    }, [scriptStatus]);

    // ScriptUtil.useScript('https://accounts.google.com/gsi/client', onCallBackFunction, clientId);

    return <div className="google-login" ref={googleSignInButton} />;
  },
};

export default LoginRenderers;
