/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import moment from 'moment';
// import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { FileAddOutlined, DeleteOutlined, RightOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import {
  Form,
  Button,
  Alert,
  Input,
  InputNumber,
  Select,
  Popconfirm,
  DatePicker,
  Checkbox,
  Upload,
  message,
  Card,
} from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';

import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  credentials: Object,
  componentState: Object,
};

class GoogleBigQuerySettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;

    const state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      type: 'service_account',
      projectId: '',
      privateKeyId: '',
      privateKey: '',
      clientEmail: '',
      clientId: '',
      authUri: 'https://accounts.google.com/o/oauth2/auth',
      tokenUri: 'https://oauth2.googleapis.com/token',
      authProviderCertUrl: 'https://www.googleapis.com/oauth2/v1/certs',
      clientCertUrl: '',

      dataType: 'Metric',
      queries: [],
      samplingInterval: 5,
      samplingUnit: 60,
      historicalDates: [null, null],
      projectModelFlag: false,
      processPauseFlag: false,

      configFileList: [],
      isLoadingFile: false,
    };

    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);

    this.state = {
      ...state,

      ...(componentState || {}),
    };
    this.dataTypeOption = [
      { label: 'Metric', value: 'Metric' },
      { label: 'Log', value: 'Log' },
    ];
    this.samplingUnitOption = [
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { intl, credentials } = this.props;
    const {
      type,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      authUri,
      tokenUri,
      authProviderCertUrl,
      clientCertUrl,
    } = this.state;
    const { dataType } = this.state;
    const isMetric = dataType === 'Metric';

    const content = {
      [`type`]: type,
      [`project_id`]: projectId,
      [`private_key_id`]: privateKeyId,
      [`private_key`]: (privateKey || '').replaceAll('\\n', '\n'),
      [`client_email`]: clientEmail,
      [`client_id`]: clientId,
      [`auth_uri`]: authUri,
      [`token_uri`]: tokenUri,
      [`auth_provider_x509_cert_url`]: authProviderCertUrl,
      [`client_x509_cert_url`]: clientCertUrl,
    };
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: isMetric ? 'GoogleBigQuery_Metric' : 'GoogleBigQuery_Log',
      content: btoa(JSON.stringify(content)),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
              samplingInterval: dataType !== 'Metric' ? 10 : 5,
            },
            () => {
              this.props.saveProjectInfo('BigQuery', { dataType }, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('BigQuery', { dataType }, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
          },
          () => {
            this.props.saveProjectInfo('BigQuery', { dataType }, this.state);
          },
        );
      });
  }

  @autobind
  beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('File must smaller than 2MB!');
      return false;
    }

    this.setState({ isLoadingFile: true, configFileList: [file] });
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const fileContent = reader.result;
        let state = { isLoadingFile: false };

        // parse fileContent info
        let ret;
        try {
          ret = JSON.parse(fileContent);
        } catch (e) {
          console.debug(fileContent);
        }
        if (ret) {
          const {
            private_key_id: privateKeyId,
            private_key: privateKey,
            client_id: clientId,
            client_email: clientEmail,
            project_id: projectId,
            client_x509_cert_url: clientCertUrl,
          } = ret;
          state = { ...state, privateKeyId, privateKey, clientId, clientEmail, projectId, clientCertUrl };
        }
        this.setState(state);
      };
    });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      type,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      authUri,
      tokenUri,
      authProviderCertUrl,
      clientCertUrl,
    } = this.state;
    const { dataType, queries, samplingInterval, samplingUnit, historicalDates, projectModelFlag, processPauseFlag } =
      this.state;

    const content = {
      [`type`]: type,
      [`project_id`]: projectId,
      [`private_key_id`]: privateKeyId,
      [`private_key`]: (privateKey || '').replaceAll('\\n', '\n'),
      [`client_email`]: clientEmail,
      [`client_id`]: clientId,
      [`auth_uri`]: authUri,
      [`token_uri`]: tokenUri,
      [`auth_provider_x509_cert_url`]: authProviderCertUrl,
      [`client_x509_cert_url`]: clientCertUrl,
    };

    this.props.createProject(
      'BigQuery',
      {
        operation: 'register',
        content: btoa(JSON.stringify(content)),

        dataType,
        queries: JSON.stringify(
          R.filter(
            (query) => Boolean(query),
            R.map((item) => item.query, queries || []),
          ),
        ),
        samplingInterval: Number(samplingInterval) * samplingUnit,
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
        projectModelFlag,
        processPauseFlag,
      },
      this.state,
    );
  }

  @autobind
  handleDataTypeChange(dataType) {
    this.setState(
      {
        dataType,
        verified: false,
      },
      () => {
        this.props.saveProjectInfo('BigQuery', { dataType }, this.state);
      },
    );
  }

  render() {
    const { intl, isLoading, hasError } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,

      type,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      authUri,
      tokenUri,
      authProviderCertUrl,
      clientCertUrl,

      dataType,
      queries,
      samplingInterval,
      samplingUnit,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
      configFileList,
      isLoadingFile,
    } = this.state;
    const isMetric = dataType === 'Metric';

    const hasVerifyError =
      !type ||
      !projectId ||
      !privateKeyId ||
      !privateKey ||
      !clientEmail ||
      !clientId ||
      !authUri ||
      !tokenUri ||
      !authProviderCertUrl ||
      !clientCertUrl ||
      !dataType;
    const hasErrorRegister = hasVerifyError || !samplingInterval || !samplingUnit || hasError;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.GoogleBigQueryIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item label="JSON Key File">
              <Upload
                onRemove={(file) => this.setState({ configFileList: [] })}
                beforeUpload={this.beforeUpload}
                fileList={configFileList}
              >
                <Button>{isLoadingFile ? <LoadingOutlined /> : <UploadOutlined />} Select File</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="Type"
              validateStatus={!type ? 'error' : 'success'}
              help={!type ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={type} onChange={(e) => this.setState({ type: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Project Id"
              validateStatus={!projectId ? 'error' : 'success'}
              help={!projectId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={projectId} onChange={(e) => this.setState({ projectId: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Private Key Id"
              validateStatus={!privateKeyId ? 'error' : 'success'}
              help={!privateKeyId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={privateKeyId}
                onChange={(e) => this.setState({ privateKeyId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Private Key"
              validateStatus={!privateKey ? 'error' : 'success'}
              help={!privateKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={privateKey}
                onChange={(e) => this.setState({ privateKey: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client Email"
              validateStatus={!clientEmail ? 'error' : 'success'}
              help={!clientEmail ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={clientEmail} onChange={(e) => this.setState({ clientEmail: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Client Id"
              validateStatus={!clientId ? 'error' : 'success'}
              help={!clientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={clientId}
                onChange={(e) => this.setState({ clientId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Auth Uri"
              validateStatus={!authUri ? 'error' : 'success'}
              help={!authUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={authUri} onChange={(e) => this.setState({ authUri: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Token Uri"
              validateStatus={!tokenUri ? 'error' : 'success'}
              help={!tokenUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={tokenUri} onChange={(e) => this.setState({ tokenUri: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Auth Provider Cert Url"
              validateStatus={!authProviderCertUrl ? 'error' : 'success'}
              help={!authProviderCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input
                value={authProviderCertUrl}
                onChange={(e) => this.setState({ authProviderCertUrl: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client Cert Url"
              validateStatus={!clientCertUrl ? 'error' : 'success'}
              help={!clientCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={clientCertUrl} onChange={(e) => this.setState({ clientCertUrl: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Data type"
              validateStatus={!dataType ? 'error' : 'success'}
              help={!dataType ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select showSearch options={this.dataTypeOption} value={dataType} onChange={this.handleDataTypeChange} />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying || isLoading}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>

        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            <Form.Item label="Filter Query List">
              <Button
                size="small"
                type="primary"
                style={{ marginBottom: 8 }}
                onClick={(e) => {
                  const { queries } = this.state;
                  this.setState({
                    queries: [...queries, { query: '' }],
                  });
                }}
              >
                <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
              </Button>
              <div className="event-list">
                <div className="event-list-header" style={{ height: 30, width: '100%' }}>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    Query
                  </div>
                  <div className="header-column" style={{ width: 110 }} />
                </div>
                <div className="event-list-grid" style={{ height: 120, overflowY: 'auto' }}>
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                        style={{ minHeight: 40 }}
                      >
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          <Input
                            size="small"
                            value={item.query}
                            onChange={(e) => {
                              const { queries } = this.state;
                              this.setState({
                                queries: update(queries, {
                                  [index]: { $set: { ...item, query: e.target.value } },
                                }),
                              });
                            }}
                          />
                        </div>
                        <div className="row-column flex-end-justify" style={{ width: 110 }}>
                          <Popconfirm
                            placement="topRight"
                            title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                            onConfirm={() => {
                              const { queries } = this.state;
                              this.setState({ queries: R.remove(index, 1, queries) });
                            }}
                            onCancel={(event) => event.stopPropagation()}
                          >
                            <Button
                              size="small"
                              className="button-color-grey"
                              onClick={(event) => event.stopPropagation()}
                            >
                              <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                            </Button>
                          </Popconfirm>
                        </div>
                      </div>
                    );
                  }, queries || [])}
                </div>
              </div>
            </Form.Item>

            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.samplingUnitOption}
                  value={samplingUnit}
                  onChange={(samplingUnit) => this.setState({ samplingUnit })}
                />
              </div>
            </Form.Item>

            <Form.Item label="Historical Date Range">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={historicalDates}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(historicalDates) => this.setState({ historicalDates })}
              />
              {!isMetric && (
                <div className="flex-row" style={{ marginTop: 16 }}>
                  <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                    Enable holistic model
                  </div>
                  <Checkbox
                    checked={projectModelFlag}
                    onChange={(e) => {
                      this.setState({ projectModelFlag: e.target.checked });
                    }}
                  />
                </div>
              )}
              {!isMetric && (
                <div className="flex-row" style={{ marginTop: 16 }}>
                  <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                    Enable initial processing pause
                  </div>
                  <Checkbox
                    checked={processPauseFlag}
                    onChange={(e) => {
                      this.setState({ processPauseFlag: e.target.checked });
                    }}
                  />
                </div>
              )}
            </Form.Item>
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasErrorRegister}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const GoogleBigQuerySetting = injectIntl(GoogleBigQuerySettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(GoogleBigQuerySetting);
