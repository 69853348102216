/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 */

/* eslint-disable no-console */
import * as R from 'ramda';
import { get } from 'lodash';

import type { DashboardState, Action } from '../types';
import { ActionTypes } from './actions';
import { ActionTypes as AppActionTypes } from '../app/actions';

const initialState: DashboardState = {
  globalHealthSummary: [],
  globalHealthByInstance: [],
  globalCapacitySummary: [],
  globalSystemHealth: [],
  globalTopAnomalyKeywords: [],
  globalSystemInstances: [],
  globalSystemInfo: {},
  globalSystemTimelineInfo: {},
  globalSystemLevelTimelines: [],
  globalSystemIncidentTimelines: [],
  globalSystemAnomalyTimelines: [],
  globalSystemAlertTimelines: [],
  globalSystemDetectIncidentTimelines: [],
  globalSystemAnomalyTrendingMap: {},
  globalPredictedAnomalyTimelines: [],
  globalPredictedAnomalyTrendingMap: {},
  globalSystemAlertStatistics: {},
  globalSystemComponentAnomalyCount: {},
  globalSystemInstanceAnomalyCount: {},
  globalSystemMatchedPredictions: [],
  insightWidgets: [],
  globalPredictedIncidentList: [],
  globalSystemDeployTimelines: [],
  globalSystemTraceTimelines: [],
};

const reducer = (state: DashboardState = initialState, action: Action): DashboardState => {
  if (action.type === AppActionTypes.RESET_INFO_GLOBAL) {
    // Reset all if global info changed.
    return {
      ...state,
      globalHealthSummary: [],
      globalHealthByInstance: [],
      globalCapacitySummary: [],
      globalSystemHealth: [],
      globalTopAnomalyKeywords: [],
      globalSystemInstances: [],
      globalSystemLevelTimelines: [],
      globalSystemIncidentTimelines: [],
      globalSystemAnomalyTimelines: [],
      globalSystemAlertTimelines: [],
      globalSystemDetectIncidentTimelines: [],
      globalSystemAnomalyTrendingMap: {},
      globalPredictedAnomalyTimelines: [],
      globalPredictedAnomalyTrendingMap: {},
      globalSystemInfo: {},
      globalSystemTimelineInfo: {},
      globalSystemAlertStatistics: {},
      globalSystemComponentAnomalyCount: {},
      globalSystemInstanceAnomalyCount: {},
      globalSystemMatchedPredictions: [],
      insightWidgets: [],
      globalPredictedIncidentList: [],
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_HEALTH) {
    let { globalHealthSummary } = state;
    let { globalHealthSummary: newGlobalHealthSummary } = action.payload.data || {};
    newGlobalHealthSummary = newGlobalHealthSummary || [];

    R.forEach((systemHealth) => {
      const index = R.findIndex((t) => t.id === systemHealth.id, globalHealthSummary);
      if (index >= 0) {
        globalHealthSummary = R.update(index, systemHealth, globalHealthSummary);
      } else {
        globalHealthSummary = [...globalHealthSummary, systemHealth];
      }
    }, newGlobalHealthSummary);

    return {
      ...state,
      globalHealthSummary,
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_INSTANCE_HEALTH) {
    // parse globalHealthByInstance
    let { globalHealthByInstance } = state;
    let { globalHealthByInstance: newGlobalHealthByInstance } = action.payload.data || {};
    newGlobalHealthByInstance = newGlobalHealthByInstance || [];
    R.forEach((systemHealth) => {
      const index = R.findIndex((t) => t.id === systemHealth.id, globalHealthByInstance);
      if (index >= 0) {
        globalHealthByInstance = R.update(index, systemHealth, globalHealthByInstance);
      } else {
        globalHealthByInstance = [...globalHealthByInstance, systemHealth];
      }
    }, newGlobalHealthByInstance);

    return {
      ...state,
      globalHealthByInstance,
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_CAPACITY_PLANNING) {
    let { globalCapacitySummary } = action.payload.data || {};
    globalCapacitySummary = globalCapacitySummary || [];
    return {
      ...state,
      globalCapacitySummary,
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_HEALTH) {
    const globalSystemHealth = action.payload.data || [];
    return {
      ...state,
      globalSystemHealth,
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_HEALTH_CHART) {
    let { globalSystemHealth } = action.payload.data || {};
    globalSystemHealth = globalSystemHealth || [];
    return {
      ...state,
      globalSystemHealth,
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_INSTANCES) {
    const globalSystemInstances = action.payload.data || [];
    return {
      ...state,
      globalSystemInstances,
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_TOP_ANOMALY_KEYWORDS) {
    const globalTopAnomalyKeywords = action.payload.data || [];
    return {
      ...state,
      globalTopAnomalyKeywords,
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_INFO) {
    const { globalSystemInfo, globalSystemComponentAnomalyCount, globalSystemInstanceAnomalyCount } =
      action.payload.data || {};
    return { ...state, globalSystemInfo, globalSystemComponentAnomalyCount, globalSystemInstanceAnomalyCount };
  } else if (action.type === ActionTypes.SET_PATTERN_NAME_MAP) {
    const { patternNameMap } = action.payload.data || {};
    return { ...state, patternNameMap };
  } else if (action.type === ActionTypes.SET_INSIGHTS_WIDGETS_CONFIG) {
    let { insightWidgets } = action.payload.data || {};
    insightWidgets = insightWidgets || [];
    return { ...state, insightWidgets };
  } else if (action.type === ActionTypes.SET_GLOBAL_TIMELINE_INFO) {
    const { operation } = action.payload.params;
    const { globalSystemInfo, globalSystemComponentAnomalyCount, globalSystemInstanceAnomalyCount } =
      action.payload.data || {};
    if (operation) {
      const { globalSystemTimelineInfo } = state;
      return {
        ...state,
        globalSystemTimelineInfo: { ...globalSystemTimelineInfo, [`RCA-${operation}`]: globalSystemInfo },
        globalSystemComponentAnomalyCount,
        globalSystemInstanceAnomalyCount,
      };
    }
    return { ...state, globalSystemInfo, globalSystemComponentAnomalyCount, globalSystemInstanceAnomalyCount };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_LEVEL_TIMELINES) {
    // parse globalSystemLevelTimelines
    let { globalSystemLevelTimelines } = state;
    const { level } = action.payload.params;
    let { timelines } = action.payload.data || {};
    timelines = timelines || [];
    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemLevelTimelines);
      if (index >= 0) {
        globalSystemLevelTimelines = R.update(index, timeline, globalSystemLevelTimelines);
      } else {
        globalSystemLevelTimelines = [...globalSystemLevelTimelines, timeline];
      }
    }, timelines);

    return {
      ...state,
      globalSystemLevelTimelines,
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_INCIDENT_TIMELINES) {
    let { globalSystemIncidentTimelines } = state;
    const { level } = action.payload.params;
    let { timelines } = action.payload.data || {};
    timelines = timelines || [];

    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemIncidentTimelines);
      if (index >= 0) {
        globalSystemIncidentTimelines = R.update(index, timeline, globalSystemIncidentTimelines);
      } else {
        globalSystemIncidentTimelines = [...globalSystemIncidentTimelines, timeline];
      }
    }, timelines);

    const globalPredictedIncidentList = [];
    R.forEach((item) => {
      const { anomalyTimelines } = item;
      R.forEach((incident) => globalPredictedIncidentList.push(incident), anomalyTimelines);
    }, timelines);

    return { ...state, globalSystemIncidentTimelines, globalPredictedIncidentList };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_ANOMALY_TIMELINES) {
    let { globalSystemAnomalyTimelines, globalSystemAnomalyTrendingMap } = state;
    const { level } = action.payload.params;
    let { timelines, systemTrendingMap } = action.payload.data || {};
    timelines = timelines || [];
    systemTrendingMap = systemTrendingMap || {};

    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemAnomalyTimelines);
      if (index >= 0) {
        globalSystemAnomalyTimelines = R.update(index, timeline, globalSystemAnomalyTimelines);
      } else {
        globalSystemAnomalyTimelines = [...globalSystemAnomalyTimelines, timeline];
      }
    }, timelines);
    globalSystemAnomalyTrendingMap = { ...globalSystemAnomalyTrendingMap, ...systemTrendingMap };

    return { ...state, globalSystemAnomalyTimelines, globalSystemAnomalyTrendingMap };
  } else if (action.type === ActionTypes.SET_GLOBAL_PREDICTED_ANOMALY_TIMELINES) {
    let { globalPredictedAnomalyTimelines, globalPredictedAnomalyTrendingMap } = state;
    const { level } = action.payload.params;
    let { timelines, systemTrendingMap } = action.payload.data || {};
    timelines = timelines || [];
    systemTrendingMap = systemTrendingMap || {};

    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalPredictedAnomalyTimelines);
      if (index >= 0) {
        globalPredictedAnomalyTimelines = R.update(index, timeline, globalPredictedAnomalyTimelines);
      } else {
        globalPredictedAnomalyTimelines = [...globalPredictedAnomalyTimelines, timeline];
      }
    }, timelines);
    globalPredictedAnomalyTrendingMap = { ...globalPredictedAnomalyTrendingMap, ...systemTrendingMap };

    return { ...state, globalPredictedAnomalyTimelines, globalPredictedAnomalyTrendingMap };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_ALERT_TIMELINES) {
    let { globalSystemAlertTimelines } = state;
    const { level } = action.payload.params;
    let { timelines } = action.payload.data || {};
    timelines = timelines || [];

    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemAlertTimelines);
      if (index >= 0) {
        globalSystemAlertTimelines = R.update(index, timeline, globalSystemAlertTimelines);
      } else {
        globalSystemAlertTimelines = [...globalSystemAlertTimelines, timeline];
      }
    }, timelines);

    return { ...state, globalSystemAlertTimelines };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_DEPLOY_TIMELINES) {
    let { globalSystemDeployTimelines } = state;
    const { level } = action.payload.params;
    let { timelines } = action.payload.data || {};
    timelines = timelines || [];

    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemDeployTimelines);
      if (index >= 0) {
        globalSystemDeployTimelines = R.update(index, timeline, globalSystemDeployTimelines);
      } else {
        globalSystemDeployTimelines = [...globalSystemDeployTimelines, timeline];
      }
    }, timelines);
    return { ...state, globalSystemDeployTimelines };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_TRACE_TIMELINES) {
    let { globalSystemTraceTimelines } = state;
    const { level } = action.payload.params;
    let { timelines } = action.payload.data || {};
    timelines = timelines || [];

    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemTraceTimelines);
      if (index >= 0) {
        globalSystemTraceTimelines = R.update(index, timeline, globalSystemTraceTimelines);
      } else {
        globalSystemTraceTimelines = [...globalSystemTraceTimelines, timeline];
      }
    }, timelines);
    return { ...state, globalSystemTraceTimelines };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_DETECT_INCIDENT_TIMELINES) {
    let { globalSystemDetectIncidentTimelines } = state;
    const { level } = action.payload.params;
    let { timelines } = action.payload.data || {};
    timelines = timelines || [];

    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemDetectIncidentTimelines);
      if (index >= 0) {
        globalSystemDetectIncidentTimelines = R.update(index, timeline, globalSystemDetectIncidentTimelines);
      } else {
        globalSystemDetectIncidentTimelines = [...globalSystemDetectIncidentTimelines, timeline];
      }
    }, timelines);

    return { ...state, globalSystemDetectIncidentTimelines };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_ZOOM_TIMELINES) {
    const { level } = action.payload.params;
    const { timelinesPredictIncident, timelinesAnomaly, systemTrendingMap, timelinesAlert, timelinesDetectIncident } =
      action.payload.data || {};

    // incident
    let { globalSystemIncidentTimelines } = state;
    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemIncidentTimelines);
      if (index >= 0) {
        globalSystemIncidentTimelines = R.update(index, timeline, globalSystemIncidentTimelines);
      } else {
        globalSystemIncidentTimelines = [...globalSystemIncidentTimelines, timeline];
      }
    }, timelinesPredictIncident);

    // anomaly
    let { globalSystemAnomalyTimelines, globalSystemAnomalyTrendingMap } = state;
    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemAnomalyTimelines);
      if (index >= 0) {
        globalSystemAnomalyTimelines = R.update(index, timeline, globalSystemAnomalyTimelines);
      } else {
        globalSystemAnomalyTimelines = [...globalSystemAnomalyTimelines, timeline];
      }
    }, timelinesAnomaly || []);
    globalSystemAnomalyTrendingMap = { ...globalSystemAnomalyTrendingMap, ...systemTrendingMap };

    // alert
    let { globalSystemAlertTimelines } = state;
    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemAlertTimelines);
      if (index >= 0) {
        globalSystemAlertTimelines = R.update(index, timeline, globalSystemAlertTimelines);
      } else {
        globalSystemAlertTimelines = [...globalSystemAlertTimelines, timeline];
      }
    }, timelinesAlert || []);

    // incident
    let { globalSystemDetectIncidentTimelines } = state;
    R.forEach((timeline) => {
      const index = R.findIndex((t) => t.id === timeline.id && t.level === level, globalSystemDetectIncidentTimelines);
      if (index >= 0) {
        globalSystemDetectIncidentTimelines = R.update(index, timeline, globalSystemDetectIncidentTimelines);
      } else {
        globalSystemDetectIncidentTimelines = [...globalSystemDetectIncidentTimelines, timeline];
      }
    }, timelinesDetectIncident || []);

    return {
      ...state,
      globalSystemIncidentTimelines,
      globalSystemAnomalyTimelines,
      globalSystemAnomalyTrendingMap,
      globalSystemAlertTimelines,
      globalSystemDetectIncidentTimelines,
    };
  } else if (action.type === ActionTypes.SET_GLOBAL_KEYWORDS_EVENTS) {
    const { data } = action.payload;
    return { ...state, globalKeywordsEvents: data };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_CAUSAL_GROUP) {
    const { data } = action.payload;
    const { globalSystemCausalGroup } = data || {};
    return { ...state, globalSystemCausalGroup };
  } else if (action.type === ActionTypes.SET_GLOBAL_SYSTEM_ALERT_STATISTICS) {
    const { data } = action.payload;
    const { globalSystemAlertStatistics } = data || {};
    return { ...state, globalSystemAlertStatistics };
  } else if (action.type === ActionTypes.SET_MATCHED_INCIDENT_PREDICTIONS) {
    const { data } = action.payload;
    const { globalSystemMatchedPredictions } = data || {};
    return { ...state, globalSystemMatchedPredictions };
  }

  return { ...initialState, ...state };
};

export default reducer;
