import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import VLink from 'valuelink';
import { get } from 'lodash';
import { push } from 'react-router-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Button, Popover } from 'antd';

import type { State } from '../../common/types';
import { hideAppLoader, createLoadAction } from '../../common/app/actions';
import { ActionTypes } from '../../common/auth/actions';
import { Input } from '../../lib/fui/react';
import { CenterPage } from '../app/components';
import { Regex, getLoadStatus, parseQueryString } from '../../common/utils';

import { appFieldsMessages, appButtonsMessages } from '../../common/app/messages';
import { authMessages } from '../../common/auth/messages';

type Props = {
  intl: Object,
  push: Function,
  loadStatus: Object,
  currentLoadingComponents: Object,
  appLoaderVisible: boolean,
  hideAppLoader: Function,
  createLoadAction: Function,
  resetPasswordSucceed: Boolean,
  resetPasswordError: Object,
  location: Object,
};

class ResetPasswordCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { search } = props.location;
    const params = parseQueryString(search);
    const { resetcode } = params;

    this.submitLoader = 'auth_resetpassword_submit';
    this.state = {
      userName: '',
      email: '',
      tempPassword: resetcode || '',
      password: '',
      confirmPassword: '',

      passwordFocus: false,
    };
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
  }

  @autobind
  handleSubmit() {
    const { userName, email, tempPassword, password, confirmPassword } = this.state;
    const { createLoadAction } = this.props;
    createLoadAction(
      ActionTypes.RESET_NEW_PASSWORD_BY_TEMP,
      { userName, email, tempPassword, password, confirmPassword },
      this.submitLoader,
    );
  }

  render() {
    const { intl, push, loadStatus, currentTheme } = this.props;
    const { resetPasswordSucceed, resetPasswordError } = this.props;
    const { userName } = this.state;
    const { isLoading: isSubmitting } = getLoadStatus(get(loadStatus, this.submitLoader), intl);

    if (resetPasswordSucceed) {
      // eslint-disable-next-line
      alert('Your password has been reset, you can sign in using your new password now');
      return <Redirect to="/" />;
    }

    const userNameLink = VLink.state(this, 'userName').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsUserNameRequired),
    );
    const emailLink = VLink.state(this, 'email')
      .check((x) => x, intl.formatMessage(authMessages.errorsEmailRequired))
      .check((x) => Regex.email.test(x), intl.formatMessage(authMessages.errorsEmailIncorrect));

    // const tempPasswordLink = VLink.state(this, 'tempPassword').check(
    //   (x) => x,
    //   intl.formatMessage(authMessages.errorsPasswordRequired),
    // );
    const passwordLink = VLink.state(this, 'password')
      .check((value) => value, intl.formatMessage(authMessages.errorsPasswordRequired))
      .check((value) => value.length >= 8, intl.formatMessage(authMessages.errorsPasswordLength))
      .check((value) => value.match(/[0-9]/g), intl.formatMessage(authMessages.errorsPasswordNumber))
      .check((value) => value.match(/[A-Z]/g), intl.formatMessage(authMessages.errorsPasswordUppercase))
      .check((value) => value.match(/[a-z]/g), intl.formatMessage(authMessages.errorsPasswordLowercase))
      .check(
        (value) => value.match(/[~!@#$%^&*_+?:]/g),
        intl.formatMessage(authMessages.errorsPasswordSpecialCharacters),
      )
      .check(
        (value) => !userName || (Boolean(userName) && value.toLowerCase().indexOf(userName.toLowerCase()) === -1),
        intl.formatMessage(authMessages.errorsPasswordContain),
      );
    const confirmPasswordLink = VLink.state(this, 'confirmPassword')
      .check((x) => x, intl.formatMessage(authMessages.errorsPasswordRequired))
      .check((x) => x === this.state.password, intl.formatMessage(authMessages.errorsPasswordNotMatch));
    const disabled =
      userNameLink.error ||
      emailLink.error ||
      // tempPasswordLink.error ||
      passwordLink.error ||
      confirmPasswordLink.error ||
      isSubmitting;
    const hasError = Boolean(resetPasswordError);

    return (
      <CenterPage intl={intl} push={push} className="auth" currentTheme={currentTheme}>
        <div className="header">Reset your password</div>
        <form className={`ui ${hasError ? 'error' : ''} form`} autoComplete="off">
          {hasError && <div className="ui error message">{resetPasswordError}</div>}
          <div className="input field required">
            <label>{intl.formatMessage(appFieldsMessages.userName)}</label>
            <Input valueLink={userNameLink} icon="user icon" autoComplete="off" />
          </div>
          <div className="input field required">
            <label>{intl.formatMessage(appFieldsMessages.email)}</label>
            <Input type="text" valueLink={emailLink} icon="mail icon" autoComplete="off" />
          </div>
          {/* <div className="input field required">
            <label>{intl.formatMessage(appFieldsMessages.tempPassword)}</label>
            <Input type="password" valueLink={tempPasswordLink} icon="lock icon" autoComplete="new-password" />
          </div> */}
          <div className="input field required">
            <label>{intl.formatMessage(appFieldsMessages.password)}</label>
            <Popover
              visible={this.state.passwordFocus}
              placement="right"
              title="Password requirements"
              content={
                <div className="flex-col" style={{ width: 360 }}>
                  <div>{'Length >=8'}</div>
                  <div>Contains at least one lower case and one upper case</div>
                  <div>Contains at least one number and one special symbol (Examples: ~!@#$%^&*_+?:)</div>
                </div>
              }
            >
              <Input
                type="password"
                valueLink={passwordLink}
                icon="lock icon"
                autoComplete="new-password"
                onFocus={() => this.setState({ passwordFocus: true })}
                onBlur={() => this.setState({ passwordFocus: false })}
              />
            </Popover>
          </div>
          <div className="input field required">
            <label>{intl.formatMessage(appFieldsMessages.confirmPassword)}</label>
            <Input type="password" valueLink={confirmPasswordLink} icon="lock icon" autoComplete="new-password" />
          </div>
          <div className="field">
            <Button
              type="primary"
              style={{ width: '100%' }}
              loading={isSubmitting}
              disabled={disabled}
              onClick={this.handleSubmit}
            >
              {intl.formatMessage(appButtonsMessages.confirm)}
            </Button>
          </div>
        </form>
      </CenterPage>
    );
  }
}

const ResetPassword = injectIntl(ResetPasswordCore);
export default connect(
  (state: State) => ({
    loadStatus: state.app.loadStatus,
    appLoaderVisible: state.app.appLoaderVisible,
    currentLoadingComponents: state.app.currentLoadingComponents,
    resetPasswordSucceed: state.auth.resetPasswordSucceed,
    resetPasswordError: state.auth.resetPasswordError,
    currentTheme: state.app.currentTheme,
    location: state.router.location,
  }),
  { push, hideAppLoader, createLoadAction },
)(ResetPassword);
