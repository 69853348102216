import React from 'react';
import { Button } from 'antd';
import { Modal } from '../../../lib/fui/react';

import ClusterFeatureChart from './ClusterFeatureChart';
import { appButtonsMessages } from '../../../common/app/messages';

type Props = { onClose: Function, intl: Object };
class AllInstanceFeatureChartModal extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onClose, intl } = this.props;
    return (
      <Modal
        title="All instance features"
        width={1024}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        bodyStyle={{ height: 600, background: 'var(--content-background)' }}
        footer={[
          <Button size="small" key="cancel" onClick={onClose}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>,
        ]}
      >
        <ClusterFeatureChart {...this.props} isAllInstance />
      </Modal>
    );
  }
}
export default AllInstanceFeatureChartModal;
