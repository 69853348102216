import moment from 'moment';
import { get } from 'lodash';

import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const idpFetchLogin = (idpState: String) => {
  return fetchGet(getEndpoint('idpfetchlogin'), {
    state: idpState,
  }).then((data) => {
    // Set tokenExpiredTime in localStorage
    const tokenExpiredTime = get(data, ['tokenExpiredTime']);
    window.localStorage.setItem('tokenExpiredTime', tokenExpiredTime);
    console.debug(`Token Expired Time: ${moment.utc(tokenExpiredTime).format('HH:mm:ss')}`);

    // Set X-CSRF-Token in localStorage
    const token = get(data, ['token']);
    window.localStorage.setItem('X-CSRF-Token', token);

    const isFirstLogin = get(data, ['data', 'isFirstLogin'], false);
    const avatarId = get(data, ['avatarId']);
    return {
      credentials: {
        userName: get(data, ['data', 'userName']),
      },
      userInfo: { ...data.data, isIDPUser: true, isFirstLogin, avatarId },
    };
  });
};

export default idpFetchLogin;
