/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, isNumber } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Alert, Spin, Empty, Select, Tooltip } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { RestoreSvgPath } from '../../../lib/fui/icons';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Defaults, parseLocation, parseJSON } from '../../../common/utils';

import { appFieldsMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';

import { EChart } from '../../share';
import LogEntriesList from './LogEntriesList';
import LogEntriesImportantList from './LogEntriesImportantList';
import LogEntriesFeatureChart from './LogEntriesFeatureChart';
import AllInstanceFeatureChartModal from './AllInstanceFeatureChartModal';
import { AutoSizer } from '../../../lib/fui/react';

type Props = {
  // eslint-disable-next-line
  clusterInfo: Object,
  // eslint-disable-next-line
  clusterActivePatternChange: Number,
  // eslint-disable-next-line
  clustersSampleMsgMap: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  isReadUser: Boolean,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  renderFilterContent: Function,
  anomalyType: String,
  onChangeAnomalyType: Function,
  activePatternId: String,
  setLoading: Function,
  hostAndPodIdList: Array,
  instanceDisplayNameMap: Object,
};

class LogEntriesDetail extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const interval = 10 * 60 * 1000;

    const { location } = props;
    const { activeTimestamp, startTime, endTime } = parseLocation(location);

    this.state = {
      isLoading: true,
      errorMessage: null,
      results: [],

      interval,
      activeTimestamp: moment
        .utc(activeTimestamp || null)
        .startOf('day')
        .valueOf(),
      activeEndTimestamp: null,
      chartOption: null,
      clusterActiveTimeChange: null,
      allPatternNidMap: {},

      activeKey: 'featureKeyword',

      // feature keyword info
      featureToNidMapInfo: {},
      featureInfo: {},

      isResetDateZoom: true,
      showAllInstanceFeatureChart: false,

      importantStartTime: moment.utc(startTime).startOf('day').valueOf(),
      importantEndTime: moment.utc(endTime).endOf('day').valueOf(),
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { startTime, endTime } = parseLocation(nextProps.location);
    if (this.props.clusterActivePatternChange !== nextProps.clusterActivePatternChange) {
      this.setState(
        {
          importantStartTime: moment.utc(startTime).startOf('day').valueOf(),
          importantEndTime: moment.utc(endTime).endOf('day').valueOf(),
        },
        () => {
          this.reloadData(nextProps);
        },
      );
    }
  }

  @autobind
  reloadData(props) {
    const { location, credentials, projects, clusterInfo } = props;
    const params = parseLocation(location);
    const { projectName, startTime, endTime, instanceName } = params;
    let { activeTimestamp } = this.state;
    let activeEndTimestamp;

    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects,
    );
    if (projectName && project?.hasAllInfo && startTime && endTime && instanceName && clusterInfo) {
      const { isAllPattern, patternId } = clusterInfo;
      let { activeKey } = this.state;
      activeKey = isAllPattern ? 'featureKeyword' : activeKey;
      this.setState({
        isLoading: true,
        isResetDateZoom: true,
        activeKey,
        featureInfo: {},
      });

      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
      const startTimestampList = R.map(
        (i) => startTimestamp + i * 86400000,
        R.range(0, (endTimestamp + 1 - startTimestamp) / 86400000),
      );

      const requests = [];
      R.forEach((dayTimeMillis) => {
        requests.push(
          fetchGet(getEndpoint('logfrequencyanomaly'), {
            ...credentials,
            projectName,
            instanceName,
            dayTimeMillis,
            nid: patternId,
          }),
        );
      }, startTimestampList);

      props.updateLastActionInfo();
      this.props.setLoading(true);
      Promise.all(requests)
        .then(async (results) => {
          const { chartOption, allPatternNidMap } = this.parseData(props, results);
          activeTimestamp = moment.utc(startTime).startOf('day').valueOf();
          activeEndTimestamp = moment.utc(startTime).endOf('day').valueOf();

          this.setState({
            isLoading: false,
            errorMessage: null,
            results,
            activeTimestamp,
            activeEndTimestamp,
            chartOption,
            clusterActiveTimeChange: moment.utc().valueOf(),
            allPatternNidMap,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            errorMessage: err,
            results: [],
            activeTimestamp: null,
            activeEndTimestamp: null,
            chartOption: {},
            allPatternNidMap: {},
          });
        });
    }
  }

  @autobind
  parseData(props, results) {
    const { clusterInfo } = props;
    const { isAllPattern } = clusterInfo || {};

    let result = {};
    if (isAllPattern) {
      result = this.buildAllPatternChart(props, results);
    } else {
      result = this.buildPatternChart(props, results);
    }
    const { allTimes, chartOption, allPatternNidMap } = result;
    return { allTimes, chartOption, allPatternNidMap };
  }

  @autobind
  buildAllPatternChart(props, results) {
    const { intl, location, clusterInfo } = props;
    const params = parseLocation(location);
    const { startTime, endTime } = params;
    const { isAllPattern } = clusterInfo || {};

    let datas = [];
    let allPatternNidMap = {};
    let derivedAnomalyMap = {};
    R.forEach((data) => {
      const globalFreqVector = get(data, ['data', 'globalFreqVector'], {});
      const dataList = [];
      R.forEachObjIndexed((count, timestamp) => {
        dataList.push({ timestamp: Number(timestamp), count, isAllPattern });
      }, globalFreqVector);
      datas = [...datas, ...dataList];

      const globalFreqVectorNidMap = get(data, ['data', 'globalFreqVectorNidMap'], {});
      allPatternNidMap = { ...allPatternNidMap, ...globalFreqVectorNidMap };

      const derivedAnomalyString = get(data, ['data', 'derivedAnomalyString'], {});
      derivedAnomalyMap = { ...derivedAnomalyMap, ...derivedAnomalyString };
    }, results);

    // add hot/cold anomaly flag
    R.forEachObjIndexed((frequency, timestamp) => {
      let isCold;
      let isHot;
      if (frequency > 0) {
        isHot = true;
      } else {
        isCold = true;
      }

      const itemInfo = R.find((item) => item.timestamp === Number(timestamp), datas);
      if (itemInfo) {
        itemInfo.frequency = frequency;
        itemInfo.isHot = isHot;
        itemInfo.isCold = isCold;
      } else {
        datas.push({
          timestamp: Number(timestamp),
          count: 0,
          frequency,
          isHot,
          isCold,
        });
      }
    }, derivedAnomalyMap);

    const xDatas = [];
    let maxVal = 0;
    datas = R.sortWith([R.ascend(R.prop('timestamp'))], datas);
    datas = R.map((item) => {
      const { timestamp, count } = item;
      xDatas.push(timestamp);
      maxVal = R.max(maxVal, count);
      return { ...item, value: [timestamp, count] };
    }, datas);
    const allTimes = R.map((item) => item.timestamp, datas);

    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

    if (!xDatas.includes(startTimestamp)) {
      datas = [{ value: [startTimestamp, null], timestamp: startTimestamp, count: null }, ...datas];
    }
    if (!xDatas.includes(endTimestamp)) {
      datas = [...datas, { value: [endTimestamp, null], timestamp: endTimestamp, count: null }];
    }

    const chartOption = {
      useUTC: true,
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        axisPointer: {
          type: 'none',
        },
        formatter: (params, ticket, callback) => {
          const { data, color } = params[0] || {};
          const { timestamp, count, frequency } = data || {};

          let frequencyStr = '';
          if (frequency) {
            const percent = `${Math.abs(frequency).toFixed(2)}%`;
            const cmp = frequency > 0 ? 'higher' : 'lower';
            frequencyStr = `${
              isNumber(count) ? `${intl.formatMessage(appFieldsMessages.count)}: ${numeral(count).format('0,0')}. ` : ''
            }Frequency is ${percent} ${cmp} than normal.`;
          }

          return ReactDOMServer.renderToStaticMarkup(
            <div>
              {moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color }} />
              {!frequencyStr && `${intl.formatMessage(appFieldsMessages.count)}: ${numeral(count).format('0,0')}`}
              {frequencyStr && frequencyStr}
            </div>,
          );
        },
        textStyle: { color: 'var(--text-color-secondary)' },
        backgroundColor: 'var(--content-background)',
        borderColor: 'var(--border-color-base)',
      },
      grid: {
        left: 30,
        right: 30,
        top: 10,
        bottom: 10,
        containLabel: true,
      },
      toolbox: {
        show: true,
        showTitle: true,
        itemGap: 10,
        left: 0,
        top: 0,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
            icon: null,
            title: { zoom: 'Zoom', back: 'Back' },
            brushStyle: { color: 'gray', opacity: 0.4 },
          },
          restore: {
            title: '',
            icon: `path://${RestoreSvgPath}`,
            emphasis: {
              iconStyle: {
                textPosition: 'top',
                textAlign: 'left',
                textPadding: [0, 0, 10, 0],
              },
            },
          },
        },
      },
      xAxis: {
        type: 'time',
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: {
          formatter: '{MM}-{dd}\n{HH}:{mm}',
          textStyle: {
            color: 'gray',
          },
        },
        data: R.map((a) => a.timestamp, datas),
      },
      yAxis: {
        type: 'value',
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: {
          textStyle: {
            color: 'gray',
          },
        },
      },
      series: [
        {
          type: 'bar',
          data: R.map((item) => {
            const itemStyle = {};
            const { isHot, isCold } = item;
            if (isHot || isCold) {
              itemStyle.color = isHot ? 'orange' : '#2185D0';
            }
            return { ...item, itemStyle, showLine: false };
          }, datas),
          barMaxWidth: 10,
          barMinHeight: 10,
          zlevel: 20,
          itemStyle: { color: '#6abbf7' },
        },
      ],
    };
    return { allTimes, chartOption, allPatternNidMap };
  }

  @autobind
  buildPatternChart(props, results) {
    const { intl, location, clusterInfo } = props;
    const params = parseLocation(location);
    const { startTime, endTime } = params;
    const { patternId, newPatternFlag } = clusterInfo || {};

    const defaultBarColor = newPatternFlag ? 'red' : '#6abbf7';

    let datas = [];
    let derivedAnomalyMap = {};
    R.forEach((data) => {
      const freqVector = get(data, ['data', 'freqVector'], {});
      const patternFreqVectorStr = get(freqVector, patternId);
      if (patternFreqVectorStr) {
        const dataList = parseJSON(patternFreqVectorStr) || [];
        datas = [...datas, ...dataList];
      }

      const derivedAnomalyString = get(data, ['data', 'derivedAnomalyString'], {});
      derivedAnomalyMap = { ...derivedAnomalyMap, ...derivedAnomalyString };
    }, results);

    // add hot/cold anomaly flag
    R.forEachObjIndexed((frequency, timestamp) => {
      let isCold;
      let isHot;
      if (frequency > 0) {
        isHot = true;
      } else {
        isCold = true;
      }

      const itemInfo = R.find((item) => item.timestamp === Number(timestamp), datas);
      if (itemInfo) {
        itemInfo.frequency = frequency;
        itemInfo.isHot = isHot;
        itemInfo.isCold = isCold;
      } else {
        datas.push({
          timestamp: Number(timestamp),
          count: 0,
          frequency,
          isHot,
          isCold,
        });
      }
    }, derivedAnomalyMap);

    const xDatas = [];
    let maxVal = 0;
    datas = R.sortWith([R.ascend(R.prop('timestamp'))], datas);
    datas = R.map((item) => {
      const { timestamp, count } = item;
      xDatas.push(timestamp);
      maxVal = R.max(maxVal, count);
      return { ...item, value: [timestamp, count] };
    }, datas);
    const allTimes = R.map((item) => item.timestamp, datas);

    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

    if (!xDatas.includes(startTimestamp)) {
      datas = [{ value: [startTimestamp, null], timestamp: startTimestamp, count: null }, ...datas];
    }
    if (!xDatas.includes(endTimestamp)) {
      datas = [...datas, { value: [endTimestamp, null], timestamp: endTimestamp, count: null }];
    }

    const chartOption = {
      useUTC: true,
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        axisPointer: {
          type: 'none',
        },
        formatter: (params, ticket, callback) => {
          const { data, color } = params[0] || {};
          const { timestamp, count, frequency } = data || {};

          let frequencyStr = '';
          if (frequency) {
            const percent = `${Math.abs(frequency).toFixed(2)}%`;
            const cmp = frequency > 0 ? 'higher' : 'lower';
            frequencyStr = `${
              isNumber(count) ? `${intl.formatMessage(appFieldsMessages.count)}: ${numeral(count).format('0,0')}. ` : ''
            }Frequency is ${percent} ${cmp} than normal.`;
          }
          return ReactDOMServer.renderToStaticMarkup(
            <div>
              {moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color }} />
              {!frequencyStr && `${intl.formatMessage(appFieldsMessages.count)}: ${numeral(count).format('0,0')}`}
              {frequencyStr && frequencyStr}
            </div>,
          );
        },
        textStyle: { color: 'var(--text-color-secondary)' },
        backgroundColor: 'var(--content-background)',
        borderColor: 'var(--border-color-base)',
      },
      grid: {
        left: 30,
        right: 30,
        top: 10,
        bottom: 10,
        containLabel: true,
      },
      toolbox: {
        show: true,
        showTitle: true,
        itemGap: 10,
        left: 0,
        top: 0,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
            icon: null,
            title: { zoom: 'Zoom', back: 'Back' },
            brushStyle: { color: 'gray', opacity: 0.4 },
          },
          restore: {
            title: '',
            icon: `path://${RestoreSvgPath}`,
            emphasis: {
              iconStyle: {
                textPosition: 'top',
                textAlign: 'left',
                textPadding: [0, 0, 10, 0],
              },
            },
          },
        },
      },
      xAxis: {
        type: 'time',
        splitLine: { show: false },
        axisLabel: {
          formatter: '{MM}-{dd}\n{HH}:{mm}',
          textStyle: {
            color: 'gray',
          },
        },
        data: R.map((a) => a.timestamp, datas),
      },
      yAxis: {
        type: 'value',
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: {
          textStyle: {
            color: 'gray',
          },
        },
      },
      series: [
        {
          type: 'bar',
          cursor: 'pointer',
          data: R.map((item) => {
            const itemStyle = {};
            const { isHot, isCold } = item;
            if (isHot || isCold) {
              itemStyle.color = isHot ? 'orange' : '#2185D0';
            }
            return { ...item, itemStyle, showLine: false };
          }, datas),
          barMaxWidth: 10,
          barMinHeight: 10,
          zlevel: 20,
          itemStyle: { color: defaultBarColor },
        },
      ],
    };
    return { allTimes, chartOption };
  }

  @autobind
  handleFeatureKeywordChange({ featureFreqVector, featureToNidMapInfo, featureInfo }) {
    const { onChangeAnomalyType } = this.props;
    this.setState(
      {
        isLoading: true,
        featureToNidMapInfo,
        featureInfo,
      },
      () => {
        if (!featureFreqVector) {
          const { results } = this.state;
          const { chartOption, allPatternNidMap } = this.parseData(this.props, results);
          this.setState({
            isLoading: false,
            errorMessage: null,
            chartOption,
            clusterActiveTimeChange: moment.utc().valueOf(),
            allPatternNidMap,
          });
        } else {
          const { chartOption } = this.buildFeatureChart(featureFreqVector);
          this.setState({
            isLoading: false,
            errorMessage: null,
            chartOption,
            isResetDateZoom: true,
            clusterActiveTimeChange: moment.utc().valueOf(),
          });
        }
      },
    );
    if (onChangeAnomalyType) {
      onChangeAnomalyType();
    }
  }

  @autobind
  clearFeatureWord() {
    this.handleFeatureKeywordChange({
      featureFreqVector: null,
      featureToNidMapInfo: {},
      featureInfo: {},
    });
  }

  @autobind
  buildFeatureChart(featureFreqVector) {
    const { intl, location } = this.props;
    const params = parseLocation(location);
    const { startTime, endTime } = params;

    let datas = [];
    R.forEachObjIndexed((count, timestamp) => {
      datas.push({
        timestamp: Number(timestamp),
        count,
      });
    }, featureFreqVector);

    const xDatas = [];
    let maxVal = 0;
    datas = R.sortWith([R.ascend(R.prop('timestamp'))], datas);
    datas = R.map((item) => {
      const { timestamp, count } = item;
      xDatas.push(timestamp);
      maxVal = R.max(maxVal, count);
      return { ...item, value: [timestamp, count] };
    }, datas);
    const allTimes = R.map((item) => item.timestamp, datas);

    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

    if (!xDatas.includes(startTimestamp)) {
      datas = [{ value: [startTimestamp, null], timestamp: startTimestamp, count: null }, ...datas];
    }
    if (!xDatas.includes(endTimestamp)) {
      datas = [...datas, { value: [endTimestamp, null], timestamp: endTimestamp, count: null }];
    }

    const chartOption = {
      useUTC: true,
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        axisPointer: {
          type: 'none',
        },
        formatter: (params, ticket, callback) => {
          const { data, color } = params[0] || {};
          const { timestamp, count } = data || {};
          return ReactDOMServer.renderToStaticMarkup(
            <div>
              {moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color }} />
              {`${intl.formatMessage(appFieldsMessages.count)}: ${numeral(count).format('0,0')}`}
            </div>,
          );
        },
        textStyle: { color: 'var(--text-color-secondary)' },
        backgroundColor: 'var(--content-background)',
        borderColor: 'var(--border-color-base)',
      },
      grid: {
        left: 30,
        right: 30,
        top: 10,
        bottom: 10,
        containLabel: true,
      },
      toolbox: {
        show: true,
        showTitle: true,
        itemGap: 10,
        left: 0,
        top: 0,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
            icon: null,
            title: { zoom: 'Zoom', back: 'Back' },
            brushStyle: { color: 'gray', opacity: 0.4 },
          },
          restore: {
            title: '',
            icon: `path://${RestoreSvgPath}`,
            emphasis: {
              iconStyle: {
                textPosition: 'top',
                textAlign: 'left',
                textPadding: [0, 0, 10, 0],
              },
            },
          },
        },
      },
      xAxis: {
        type: 'time',
        splitLine: { show: false },
        axisLabel: {
          formatter: '{MM}-{dd}\n{HH}:{mm}',
          textStyle: {
            color: 'gray',
          },
        },
        data: R.map((a) => a.timestamp, datas),
      },
      yAxis: {
        type: 'value',
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: {
          textStyle: {
            color: 'gray',
          },
        },
      },
      series: [
        {
          type: 'bar',
          data: R.map((item) => {
            const itemStyle = {};
            const { isHot, isCold } = item;
            if (isHot || isCold) {
              itemStyle.color = isHot ? 'orange' : '#2185D0';
            }
            return { ...item, itemStyle };
          }, datas),
          barMaxWidth: 10,
          barMinHeight: 10,
          zlevel: 20,
          itemStyle: { color: 'orange' },
        },
      ],
    };
    return { allTimes, chartOption };
  }

  @autobind
  handleChartClick(item) {
    const { timestamp } = item.data || {};
    this.setState({
      activeTimestamp: timestamp,
      activeEndTimestamp: timestamp + this.state.interval,
      importantStartTime: timestamp,
      importantEndTime: timestamp + this.state.interval,
      clusterActiveTimeChange: moment.utc().valueOf(),
    });
  }

  @autobind
  renderKeywordFilter({ featureInfo, intl, isAllPattern }) {
    return (
      <>
        {featureInfo.featureLabel && featureInfo.featureValue && (
          <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
            <div className="flex-row flex-center-align flex-grow">
              <div className="bold" style={{ minWidth: 110 }}>
                {intl.formatMessage(logMessages.featureKeyword)}:
              </div>
              <Tooltip title={`${featureInfo.featureLabel}: ${featureInfo.featureValue}`}>
                <div className="hidden-line-with-ellipsis inline-block flex-grow" style={{ width: 100 }}>
                  {`${featureInfo.featureLabel}: ${featureInfo.featureValue}`}
                </div>
              </Tooltip>
              <CloseCircleOutlined onClick={this.clearFeatureWord} />
            </div>
          </div>
        )}
      </>
    );
  }

  @autobind
  handleChartFinish() {
    const { chartRef } = this;
    const { isResetDateZoom } = this.state;
    if (isResetDateZoom) {
      if (chartRef) {
        this.setState(
          {
            isResetDateZoom: false,
          },
          () => {
            const myChart = chartRef.getEchartsInstance();
            // active the dataZoom
            myChart.dispatchAction({
              type: 'takeGlobalCursor',
              key: 'dataZoomSelect',
              dataZoomSelectActive: true,
            });
          },
        );
      }
    }
  }

  @autobind
  handleChartRestore() {
    const { chartRef } = this;
    const { chartOption } = this.state;
    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    if (chartRef) {
      this.setState({
        chartOption,
        isResetDateZoom: true,
        activeTimestamp: moment.utc(startTime).startOf('day').valueOf(),
        activeEndTimestamp: moment.utc(startTime).endOf('day').valueOf(),
        importantStartTime: moment.utc(startTime).startOf('day').valueOf(),
        importantEndTime: moment.utc(endTime).endOf('day').valueOf(),
        clusterActiveTimeChange: moment.utc().valueOf(),
      });
    }
  }

  @autobind
  handleChartZoom(evt) {
    const { chartRef } = this;
    const { interval } = this.state;

    let activeTimestamp;
    let activeEndTimestamp;

    if (chartRef) {
      const myChart = chartRef.getEchartsInstance();
      const { batch } = evt || {};
      if (batch && batch.length > 0) {
        const { startValue, endValue } = batch[0] || {};
        if (!R.isNil(startValue) && !R.isNil(endValue)) {
          const axis = myChart.getModel().option.xAxis[0];
          const rangeData = [];
          R.forEach((time) => {
            if (time >= startValue && time <= endValue) {
              rangeData.push(time);
            }
          }, axis.data);
          if (rangeData.length >= 2) {
            activeTimestamp = rangeData[0];
            activeEndTimestamp = rangeData[rangeData.length - 1] + interval;
          } else {
            activeTimestamp = rangeData[0];
            activeEndTimestamp = activeTimestamp + interval;
          }

          this.setState({
            activeTimestamp,
            activeEndTimestamp,
            importantStartTime: activeTimestamp,
            importantEndTime: activeEndTimestamp,
            clusterActiveTimeChange: moment.utc().valueOf(),
          });
        }
      }
    }
  }

  render() {
    const {
      intl,
      location,
      clusterInfo,
      clustersSampleMsgMap,
      clusterActivePatternChange,
      renderFilterContent,
      anomalyType,
      activePatternId,
      hostAndPodIdList,
      instanceDisplayNameMap,
    } = this.props;
    const {
      isLoading,
      errorMessage,
      interval,
      chartOption,
      activeTimestamp,
      activeEndTimestamp,
      clusterActiveTimeChange,
      allPatternNidMap,
      featureToNidMapInfo,
      featureInfo,
      importantStartTime,
      importantEndTime,
    } = this.state;
    const params = parseLocation(location);
    const { projectName, instanceName, startTime, endTime } = params;
    const { isAllPattern } = clusterInfo || {};

    return (
      <>
        <div
          className="flex-col flex-grow flex-min-width corner-8"
          style={{ border: '1px solid var(--border-color-base)', borderBottom: 'none', overflow: 'hidden' }}
        >
          <div style={{ height: 180 }}>
            <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
              {errorMessage && (
                <div className="full-width full-height" style={{ padding: 16 }}>
                  <Alert type="error" message={String(errorMessage)} showIcon />
                </div>
              )}
              {!errorMessage && (
                <div className="full-width full-height flex-row flex-center-align flex-center-justify">
                  {!chartOption && <Empty />}
                  {chartOption && (
                    <div style={{ width: '100%', height: '100%' }} className="log-entries-echart">
                      <AutoSizer>
                        {({ width, height }) => {
                          return (
                            <EChart
                              setRef={(chart) => (this.chartRef = chart)}
                              style={{ cursor: 'default !important' }}
                              width={width}
                              height={height}
                              option={chartOption}
                              renderer="svg"
                              onEvents={{
                                click: this.handleChartClick,
                                datazoom: this.handleChartZoom,
                                restore: this.handleChartRestore,
                                rendered: this.handleChartFinish,
                              }}
                            />
                          );
                        }}
                      </AutoSizer>
                    </div>
                  )}
                </div>
              )}
            </Spin>
          </div>
          {renderFilterContent()}

          {!anomalyType && (
            <LogEntriesList
              projectName={projectName}
              instanceName={instanceName}
              clusterInfo={clusterInfo}
              clustersSampleMsgMap={clustersSampleMsgMap}
              allPatternNidMap={allPatternNidMap}
              clusterActiveTimeChange={clusterActiveTimeChange}
              startTimestamp={activeTimestamp}
              endTimestamp={activeEndTimestamp || (activeTimestamp ? activeTimestamp + interval : null)}
              featureToNidMapInfo={featureToNidMapInfo}
              featureInfo={featureInfo}
              setLoading={this.props.setLoading}
              hostAndPodIdList={hostAndPodIdList}
              instanceDisplayNameMap={instanceDisplayNameMap}
            />
          )}
          {anomalyType && (
            <LogEntriesImportantList
              projectName={projectName}
              instanceName={instanceName}
              anomalyType={anomalyType}
              startTime={importantStartTime}
              endTime={importantEndTime}
              activePatternId={activePatternId}
              clusterInfo={clusterInfo}
              clusterActiveTimeChange={clusterActiveTimeChange}
              setLoading={this.props.setLoading}
              hostAndPodIdList={hostAndPodIdList}
              instanceDisplayNameMap={instanceDisplayNameMap}
            />
          )}
        </div>
        <LogEntriesFeatureChart
          activeKey="featureKeyword"
          projectName={projectName}
          instanceName={instanceName}
          startTimestamp={moment.utc(startTime, Defaults.DateFormat).valueOf()}
          endTimestamp={moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf()}
          clusterInfo={clusterInfo}
          clusterActivePatternChange={clusterActivePatternChange}
          handleFeatureKeywordChange={this.handleFeatureKeywordChange}
          onShowAllInstance={() => this.setState({ showAllInstanceFeatureChart: true })}
          renderKeywordFilter={() => this.renderKeywordFilter({ featureInfo, intl, isAllPattern })}
          setLoading={this.props.setLoading}
        />
        {this.state.showAllInstanceFeatureChart && (
          <AllInstanceFeatureChartModal
            intl={intl}
            activeKey="featureKeyword"
            projectName={projectName}
            instanceName={instanceName}
            startTimestamp={moment.utc(startTime, Defaults.DateFormat).valueOf()}
            endTimestamp={moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf()}
            clusterInfo={clusterInfo}
            clusterActivePatternChange={clusterActivePatternChange}
            onClose={() => this.setState({ showAllInstanceFeatureChart: false })}
          />
        )}
      </>
    );
  }
}

const LogAnalysisClusterDetails = injectIntl(LogEntriesDetail);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { isReadUser, credentials } = state.auth.userInfo;
    const { loadStatus, projects } = state.app;

    return {
      location,
      loadStatus,
      isReadUser,
      credentials,
      projects: R.filter((project) => !project.isMetric, projects),
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(LogAnalysisClusterDetails);
