import React, { memo, useReducer } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { get } from 'lodash';
import { LaptopOutlined, LineChartOutlined, UnorderedListOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { Popover } from '../../../lib/fui/react';
import { Handle, Position } from '../../../lib/reactflow/core';
import { State } from '../../../common/types';
import BaseUrls from '../../app/BaseUrls';
import { QeuryIcon, TrendIcon } from '../../../lib/fui/icons';
import {
  buildUrl,
  CellRenderers,
  Defaults,
  EventRenderers,
  GlobalParse,
  LogRenderers,
  parseLocation,
} from '../../../common/utils';

import { appFieldsMessages, appMenusMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';

import InsightQueryBoxModal from './InsightQueryBoxModal';
import { logMessages } from '../../../common/log/messages';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import LogToMetricContextRCA from './LogToMetricContextRCA';
import LogToMetricContext from './LogToMetricContext';

const LikelyRootCausesCustomNodeCore = memo(
  ({ data, intl, currentTheme, credentials, projects, projectDisplayMap, location, globalInfo, userInfo }: Object) => {
    const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
      showInsightQueryBox: false,
      rootCauseEvent: null,
      popoverIsOpen: false,

      activeIncident: null,
      selectProject: null,
      selectInstance: undefined,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
      showContextModal: false,
    });

    const { showInsightQueryBox, rootCauseEvent } = state;
    const { own: oldOwn, environmentId, incident, summarySettingsMap, groups, isJWT } = data || {};

    let allOwnList = [];
    const allOwn = [];
    R.forEach((g) => {
      R.forEach((n) => {
        const ownList = n?.data?.ownList || [];
        const own = n?.data?.own;
        allOwnList = [...allOwnList, ...ownList];
        if (own) {
          allOwn.push(own);
        }
      }, g.nodes || []);
    }, groups || []);

    let eventList = R.map((item) => {
      const { metricInstanceName, instanceName, eventTimestamp, sourceDetail } = item;
      const { nid, isMetric, content } = sourceDetail || {};
      const mergerKey = `${metricInstanceName || instanceName}-${eventTimestamp}-${nid}${
        isMetric ? `-${content}` : ''
      }`;
      return { ...item, mergerKey };
    }, allOwnList || []);
    const own = allOwnList[0] || oldOwn;
    const lastOwn = allOwnList[allOwnList?.length - 1] || oldOwn;
    eventList = R.uniqBy((item) => item.mergerKey, eventList);

    const { metricInstanceName, instanceName, isRoot } = own || {};
    const allInstances = R.uniq(R.map((o) => o.metricInstanceName || o.instanceName, allOwn));
    const eventTimestamp = own?.displayTimestamp || own?.eventTimestamp;
    const eventEndTimestamp = lastOwn?.displayTimestamp || own?.eventTimestamp;

    if (isRoot) {
      eventList = [own];
    }
    const logProjects = [];
    if (rootCauseEvent) {
      const { projectName, owner } = rootCauseEvent;
      const projectNameReal = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
      const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
      logProjects.push({
        projectNameReal,
        projectDisplayName,
      });
    }

    const { systemId, customerName } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};
    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, metricInstanceName || instanceName, {
      pn: own?.projectName,
      owner: own?.projectOwner,
    });

    const handleIncidentJumpClick = (rowData) => {
      const {
        projectOwner,
        startTimestamp,
        endTimestamp,
        anomalyLogInstance,
        instanceName,
        patternId,
        type: eventType,
      } = rowData;
      let { projectName } = rowData;
      if (projectOwner !== credentials.userName) {
        projectName = `${projectName}@${projectOwner}`;
      }
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const startTime = moment.utc(startTimestamp).format(Defaults.DateFormat);
      const endTime = moment.utc(endTimestamp).format(Defaults.DateFormat);

      const query = {
        projectName,
        instanceName: anomalyLogInstance || instanceName,
        startTime,
        endTime,
        activeTab: eventType.toLowerCase() === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: customerName || credentials.userName || project?.owner || projectOwner,
        anomalyType: eventType.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    };

    const handleIncidentChartClick = (rowData) => {
      const { projectOwner, startTimestamp, endTimestamp } = rowData;
      let { projectName } = rowData;
      if (projectOwner !== credentials.userName) {
        projectName = `${projectName}@${projectOwner}`;
      }
      const startTs = moment.utc(startTimestamp).startOf('day').valueOf();
      const endTs = moment.utc(endTimestamp).endOf('day').valueOf();
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';

      let metricList = [];
      let instanceList = [];
      R.forEach((item) => {
        metricList.push(item.rootCauseMetric || item.metricName);
        instanceList.push(item.instanceId || item.instanceName);
      }, get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []));
      metricList = R.filter((item) => item, R.uniq(metricList));
      instanceList = R.filter((item) => item, R.uniq(instanceList));

      const query = {
        projectName,
        instanceGroup,
        modelType,
        startTimestamp: startTs,
        endTimestamp: endTs,
        justSelectMetric: R.join(',', metricList),
        justInstanceList: R.join(',', instanceList),
        withBaseline: true,
        customerName: customerName || credentials.userName || projectOwner,
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    };

    const patternsJumpButtonIsRoot = (rowData) => {
      const { projectOwner } = rowData;
      let { projectName } = rowData;
      if (projectOwner !== credentials.userName) {
        projectName = `${projectName}@${projectOwner}`;
      }
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      return (
        <Popover
          overlayClassName="small-popover"
          placement="top"
          title={null}
          mouseEnterDelay={0.3}
          content={
            isAlert || isIncident
              ? intl.formatMessage(appMenusMessages.alertAnalysis)
              : intl.formatMessage(appMenusMessages.logAnalysis)
          }
        >
          <Button size="small" style={{ height: 22, marginRight: 8 }} onClick={() => handleIncidentJumpClick(rowData)}>
            <UnorderedListOutlined />
          </Button>
        </Popover>
      );
    };

    const lineChartButtonIsRoot = (rowData) => {
      return (
        <Popover
          overlayClassName="small-popover"
          placement="top"
          title={null}
          mouseEnterDelay={0.3}
          content={intl.formatMessage(DashboardMessages.lineChart)}
        >
          <Button
            size="small"
            icon={<LineChartOutlined />}
            style={{ height: 22, marginRight: 8 }}
            onClick={() => handleIncidentChartClick(rowData)}
          />
        </Popover>
      );
    };

    const handleJumpClick = (rowData) => {
      const { sourceDetail, instanceName, owner, patternId, displayTimestamp } = rowData;
      const { isMetric, eventType } = sourceDetail;
      let { projectName } = rowData;
      projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const startTime = moment.utc(displayTimestamp).format(Defaults.DateFormat);
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      if (isMetric) {
        const query = {
          projectName,
          instanceGroup,
          startTime,
          endTime: startTime,
          customerName: customerName || credentials.userName || project?.owner || owner,
          patternSearch: patternId,
        };
        window.open(buildUrl(BaseUrls.MetricEvents, {}, query), '_blank');
      } else {
        const query = {
          projectName,
          instanceName,
          startTime,
          endTime: startTime,
          activeTab: eventType.toLowerCase() === 'rare' ? 'important' : 'clusters',
          activePatternId: patternId,
          ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
          customerName: customerName || credentials.userName || project?.owner || owner,
          anomalyType: eventType.toLowerCase(),
          isJump: true,
        };
        window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
      }
    };

    const handleTrendPatternsClick = (rowData) => {
      const { instanceName, owner, patternId, displayTimestamp } = rowData;
      let { projectName } = rowData;
      projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;

      const startTimeObj = moment.utc(displayTimestamp).subtract(7, 'days').startOf('day');
      const endTimeObj = moment.utc(displayTimestamp).endOf('day');

      const query = {
        t: '953de6a33d8a4b96ac9c100bf69ba3fc',
        projectName,
        instanceName,
        startTime: startTimeObj.valueOf(),
        endTime: endTimeObj.valueOf(),
        pattern: patternId,
        customerName: customerName || credentials.userName || owner,
      };
      window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    };

    const handleRootCauseChartClick = (rowData) => {
      const { sourceDetail, instanceName, owner, displayTimestamp } = rowData;
      const {
        startTimestamp: incidentStart,
        endTimestamp: incidentEnd,
        patternId,
        isIncident,
        isDeployment,
      } = incident;
      const { content } = sourceDetail;
      let { projectName } = rowData;
      projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
      const startTimestamp = moment.utc(displayTimestamp).subtract(1, 'days').startOf('day').valueOf();
      const endTimestamp = moment.utc(displayTimestamp).endOf('day').valueOf();

      const incidentInfo = {
        startTimestamp: incidentStart,
        endTimestamp: incidentEnd,
        patternId,
        type: isIncident ? 'incident' : isDeployment ? 'deployment' : 'normal',
      };
      const metricAnomalyMap = { [content]: [instanceName] };

      const query = {
        projectName,
        instanceGroup: 'All',
        startTimestamp,
        endTimestamp,
        justSelectMetric: R.join(',', [content]),
        justInstanceList: R.join(',', [instanceName]),
        // withBaseline: true,
        incidentInfo: JSON.stringify(incidentInfo),
        metricAnomalyMap: JSON.stringify(metricAnomalyMap),
        customerName: customerName || credentials.userName || owner,
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    };

    const patternsJumpButton = (rowData) => {
      const { owner } = rowData;
      let { projectName } = rowData;
      projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      return (
        <Popover
          overlayClassName="small-popover"
          placement="top"
          title={null}
          mouseEnterDelay={0.3}
          content={
            isAlert || isIncident
              ? intl.formatMessage(appMenusMessages.alertAnalysis)
              : intl.formatMessage(appMenusMessages.logAnalysis)
          }
        >
          <Button
            size="small"
            icon={<UnorderedListOutlined />}
            style={{ height: 22, marginRight: 8 }}
            onClick={() => handleJumpClick(rowData)}
          />
        </Popover>
      );
    };

    const handleLogContextOneMinClick = (rowData) => {
      const { owner, instanceName, displayTimestamp, sourceDetail } = rowData || {};
      const { content } = sourceDetail || {};
      let { projectName } = rowData || {};
      projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
      const selectStartTimestamp = displayTimestamp - 9 * 60 * 1000;
      const selectEndTimestamp = displayTimestamp + 60 * 1000;
      const activeIncident = { ...rowData, timestamp: displayTimestamp, rawData: content };
      return (
        <Button
          size="small"
          style={{ height: 22, marginRight: 8 }}
          onClick={() => {
            setState({
              activeIncident,
              selectProject: projectName,
              selectInstance: instanceName,
              selectStartTimestamp,
              selectEndTimestamp,
              showContextModal: true,
            });
          }}
        >
          {intl.formatMessage(logMessages.context)}
        </Button>
      );
    };

    const trendPatternsButton = (rowData) => {
      return (
        <Popover
          overlayClassName="small-popover"
          placement="top"
          title={null}
          mouseEnterDelay={0.3}
          content={intl.formatMessage(eventMessages.patternTrend)}
        >
          <Button size="small" style={{ height: 22, marginRight: 8 }} onClick={() => handleTrendPatternsClick(rowData)}>
            <TrendIcon />
          </Button>
        </Popover>
      );
    };

    const logQueryButton = (rowData) => {
      return (
        <Popover
          overlayClassName="small-popover"
          placement="top"
          title={null}
          mouseEnterDelay={0.3}
          content={intl.formatMessage(appMenusMessages.logQuery)}
        >
          <Button
            type="primary"
            size="small"
            style={{ height: 22, marginRight: 8 }}
            onClick={() => setState({ showInsightQueryBox: true, rootCauseEvent: rowData })}
          >
            <QeuryIcon />
          </Button>
        </Popover>
      );
    };

    const lineChartButton = (rowData) => {
      return (
        <Popover
          overlayClassName="small-popover"
          placement="top"
          title={null}
          mouseEnterDelay={0.3}
          content={intl.formatMessage(DashboardMessages.lineChart)}
        >
          <Button
            size="small"
            icon={<LineChartOutlined />}
            style={{ height: 22, marginRight: 8 }}
            onClick={() => handleRootCauseChartClick(rowData)}
          />
        </Popover>
      );
    };

    const onConfirmInsightQueryProjectSelect = (params) => {
      const {
        templateId,
        projectName,
        instanceName,
        startTimeObj,
        endTimeObj,
        keyword,
        numOfCluster,
        pattern,
        duration,
      } = params;
      const query = {
        t: templateId,
        projectName,
        instanceName,
        startTime: startTimeObj.valueOf(),
        endTime: endTimeObj.valueOf(),
        keyword,
        numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
        pattern,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        duration,
        customerName: customerName || credentials.userName,
      };
      window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
      setState({ showInsightQueryBox: false });
    };

    const logToMetricButton = (rowData) => {
      return (
        <Popover
          content={intl.formatMessage(eventMessages.logToMetricContext)}
          mouseEnterDelay={0.3}
          placement="top"
          zIndex={2000}
        >
          <Button
            size="small"
            icon={<UnorderedListOutlined />}
            style={{ height: 22, marginRight: 8 }}
            onClick={() => {
              setState({ showLogToMetricModal: true, activeIncident: rowData });
            }}
          />
        </Popover>
      );
    };

    return (
      <>
        <div className="hover-display">
          <Handle type="target" position={Position.Top} id="a" style={{ visibility: 'hidden' }} />
          <Popover
            mouseEnterDelay={0.1}
            zIndex={1999}
            content={
              <div style={{ minWidth: 200, maxWidth: 400, maxHeight: 400, overflowY: 'auto', paddingRight: 6 }}>
                {R.addIndex(R.map)((own, idx) => {
                  const {
                    instanceName,
                    componentName,
                    patternId,
                    isRoot,
                    sourceDetail,
                    isDeployment,
                    displayTimestamp,
                    logToMetricEvents,
                    projectCouldType,
                  } = own || {};
                  const { type, category, projectName, owner, projectOwner, patternName } = own || {};
                  const { isMetric, eventType, content } = sourceDetail || {};
                  const rowDataType = isRoot ? type : eventType;

                  let projectNameStr = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
                  if (isRoot) {
                    projectNameStr =
                      projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
                  }

                  const summarySettings = summarySettingsMap[projectNameStr] || [];
                  let rawDataJson;
                  try {
                    rawDataJson = JSON.parse(content);
                  } catch (error) {
                    // console.debug(error);
                  }

                  const {
                    instanceDisplayName,
                    instanceName: iName,
                    containerName,
                  } = getInstanceDisplayName(instanceDisplayNameMap, instanceName, {
                    pn: own?.projectName,
                    owner: own?.projectOwner,
                  });
                  const width = instanceDisplayName ? 140 : 85;
                  return (
                    <div
                      key={idx}
                      style={{
                        ...(idx > 0
                          ? {
                              marginTop: 14,
                              paddingTop: 14,
                              borderTop: '2px dashed var(--virtualized-table-border-color)',
                            }
                          : {}),
                      }}
                    >
                      <div className="flex-row flex-center-align">
                        <span className="bold" style={{ width: 120 }}>
                          {intl.formatMessage(appFieldsMessages.time)}:
                        </span>
                        <span className="flex-grow hidden-line-with-ellipsis" style={{ marginRight: 8 }}>
                          <div>{moment.utc(displayTimestamp).format(Defaults.ShortDateTimeFormat)}</div>
                        </span>
                      </div>
                      <div className="flex-row flex-center-align">
                        <span className="bold" style={{ width: 120 }}>
                          {intl.formatMessage(appFieldsMessages.type)}:
                        </span>
                        <span className="flex-grow hidden-line-with-ellipsis" style={{ marginRight: 8 }}>
                          {CellRenderers.logTypeRenderer({ intl, rowData: { type: rowDataType } })}
                        </span>
                      </div>
                      <div className="flex-row flex-center-align">
                        <span className="bold" style={{ width: 120, flexShrink: 0 }}>
                          {intl.formatMessage(appFieldsMessages.patternIdName)}:
                        </span>
                        <div className="flex-row flex-center-align flex-wrap flex-grow overflow-hidden">
                          <span className="hidden-line-with-ellipsis" style={{ marginRight: 8 }}>
                            {
                              Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true })
                                .patternNameStr
                            }
                          </span>
                          {isRoot ? (
                            <>
                              {category !== 'metric' && !isJWT && patternsJumpButtonIsRoot(own)}
                              {category === 'metric' && !isJWT && lineChartButtonIsRoot(own)}
                              {projectCouldType === 'LogToMetric' && !isJWT && logToMetricButton(own)}
                            </>
                          ) : (
                            <>
                              {isDeployment && R.includes(type, ['Log']) && (
                                <>
                                  {!isMetric && false && trendPatternsButton(own)}
                                  {!isMetric && false && logQueryButton(own)}
                                </>
                              )}
                              {!isDeployment && R.includes(type, ['Log']) && !isJWT && handleLogContextOneMinClick(own)}
                              {!isMetric && !isJWT && patternsJumpButton(own)}
                              {isMetric && !isJWT && lineChartButton(own)}
                              {logToMetricEvents && logToMetricEvents.length > 0 && !isJWT && logToMetricButton(own)}
                            </>
                          )}
                        </div>
                      </div>
                      {containerName && (
                        <div className="flex-row flex-center-align">
                          <span className="bold" style={{ width: 120 }}>
                            {intl.formatMessage(appFieldsMessages.container)}:
                          </span>
                          <span className="flex-grow hidden-line-with-ellipsis">{containerName}</span>
                        </div>
                      )}
                      <div className="flex-row flex-center-align">
                        <span className="bold" style={{ width: 120 }}>
                          {intl.formatMessage(appFieldsMessages.instance)}:
                        </span>
                        <span className="flex-grow hidden-line-with-ellipsis">
                          <Popover
                            content={
                              <div style={{ maxWidth: 400, maxHeight: 400, overflowY: 'auto' }}>
                                <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                                  <div className="bold" style={{ minWidth: width }}>
                                    {intl.formatMessage(appFieldsMessages.project)}:
                                  </div>
                                  {get(projectDisplayMap, projectNameStr, projectNameStr)}
                                </div>
                                <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                                  <div className="bold" style={{ minWidth: width }}>
                                    {intl.formatMessage(appFieldsMessages.component)}:
                                  </div>
                                  {componentName}
                                </div>
                                <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                                  <div className="bold" style={{ minWidth: width }}>
                                    {intl.formatMessage(appFieldsMessages.instance)}:
                                  </div>
                                  {iName}
                                </div>
                                {instanceDisplayName && (
                                  <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                                    <div className="bold" style={{ minWidth: width }}>
                                      {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                                    </div>
                                    {instanceDisplayName}
                                  </div>
                                )}
                              </div>
                            }
                            mouseEnterDelay={0.1}
                            zIndex={2000}
                          >
                            <span className="hidden-line-with-ellipsis inline-block max-width">
                              {instanceDisplayName || iName}
                            </span>
                          </Popover>
                        </span>
                      </div>
                      <div className="flex-row" style={{ marginTop: 8 }}>
                        <span className="bold" style={{ width: 120 }}>
                          {intl.formatMessage(eventMessages.fieldDetails)}:
                        </span>
                        <span className="flex-grow hidden-line-with-ellipsis">
                          {isRoot ? (
                            <EventRenderers.RenderShortDescription
                              intl={intl}
                              event={own}
                              shouldExpandLimit={80}
                              summarySettings={summarySettings}
                              currentTheme={currentTheme}
                            />
                          ) : (
                            <>
                              {!isMetric && (
                                <LogRenderers.RenderLogContent
                                  intl={intl}
                                  rawData={content}
                                  rawDataJson={rawDataJson}
                                  owner={own}
                                  summarySettings={summarySettings}
                                  currentTheme={currentTheme}
                                />
                              )}
                              {isMetric && (
                                <div
                                  className="flex-grow"
                                  style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
                                >
                                  {EventRenderers.RenderMetricAnomalySummary({ intl, event: sourceDetail })}
                                </div>
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  );
                }, eventList || [])}
              </div>
            }
          >
            <div className="hover-visible-item" style={{ position: 'absolute', right: 48, top: 14, fontSize: 20 }}>
              <InfoCircleOutlined />
            </div>
          </Popover>
          <div className="corner-8 nodrag" style={{ padding: 8, maxWidth: 200, minWidth: 200, fontSize: 16 }}>
            <div className="flex-row flex-center-align flex-center-justify" style={{ margin: '6px 0', fontSize: 28 }}>
              {isRoot ? (
                <LaptopOutlined style={{ color: 'var(--primary-color)' }} />
              ) : (
                <LaptopOutlined style={{ color: 'var(--success-color)' }} />
              )}
            </div>
            <div className="flex-row flex-center-align">
              <span className="flex-grow hidden-line-with-ellipsis text-center">
                {`${moment.utc(eventTimestamp).format(Defaults.ShortTimeFormat)}-${moment
                  .utc(eventEndTimestamp)
                  .format(Defaults.TimeOnlyFormat)}`}
              </span>
            </div>
            <div className="flex-row flex-center-align">
              <span className="flex-grow hidden-line-with-ellipsis text-center">
                {`${allInstances.length > 1 ? '...' : ''}${instanceStr}`}
              </span>
            </div>
          </div>
          <Handle type="source" position={Position.Bottom} id="c" style={{ visibility: 'hidden' }} />
        </div>
        {showInsightQueryBox && (
          <InsightQueryBoxModal
            projects={logProjects}
            onConfirm={onConfirmInsightQueryProjectSelect}
            onClose={() => setState({ showInsightQueryBox: false })}
          />
        )}
        {state.showContextModal && (
          <EventContextModal
            incident={state.activeIncident}
            projectName={state.selectProject}
            instanceName={state.selectInstance}
            startTimestamp={state.selectStartTimestamp}
            endTimestamp={state.selectEndTimestamp}
            keywordFilter=""
            onClose={() => setState({ showContextModal: false })}
            useTimeRange
          />
        )}
        {state.showLogToMetricModal && !state.activeIncident?.isRoot && (
          <LogToMetricContextRCA
            intl={intl}
            activeIncident={state.activeIncident}
            credentials={credentials}
            currentTheme={currentTheme}
            summarySettingsMap={summarySettingsMap}
            projects={projects}
            location={location}
            globalInfo={globalInfo}
            onClose={() => setState({ showLogToMetricModal: false, activeIncident: null })}
          />
        )}
        {state.showLogToMetricModal && state.activeIncident?.isRoot && (
          <LogToMetricContext
            intl={intl}
            rowData={state.activeIncident}
            credentials={credentials}
            isAdmin={userInfo?.isAdmin}
            userName={userInfo?.userName}
            currentTheme={currentTheme}
            summarySettingsMap={summarySettingsMap}
            projects={projects}
            location={location}
            globalInfo={globalInfo}
            onClose={() => setState({ showLogToMetricModal: false, activeIncident: null })}
          />
        )}
      </>
    );
  },
);

const LikelyRootCausesCustomNode = injectIntl(LikelyRootCausesCustomNodeCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { projects, projectDisplayMap, globalInfo } = state.app;
  const { userInfo } = state.auth;

  const { dark, jwtToken, customerName } = parseLocation(location);
  let credentials = {};
  if (jwtToken) {
    if (dark) {
      state.app.currentTheme = 'dark';
    } else {
      state.app.currentTheme = 'light';
    }
    credentials = { userName: customerName };
  } else {
    credentials = state.auth?.credentials;
  }
  const { currentTheme } = state.app;

  return { currentTheme, credentials, projects, projectDisplayMap, location, globalInfo, userInfo };
})(LikelyRootCausesCustomNode);
