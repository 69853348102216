import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Button, Skeleton, Spin, message } from 'antd';

import { CaretDownOutlined, CaretUpOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Container, List, Tooltip } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { CellRenderers, Defaults, LogParser, parseLocation } from '../../../common/utils';

import { logMessages } from '../../../common/log/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';

type Props = {
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  project: Object,
  // eslint-disable-next-line
  selectProjectName: String,
  // eslint-disable-next-line
  selectInstanceName: String,
  // eslint-disable-next-line
  selectStartTimestamp: Number,
  // eslint-disable-next-line
  selectEndTimestamp: Number,
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  credentials: Object,
  currentTheme: String,
  changeTotal: Function,
};

const expandKey = '__content_expand_state';
const expandKey1 = '__expanded_state__';

class MetricToLoaAllEntriesCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 60,
    });

    this.state = {
      eventList: [],
      eventListTotal: 0,

      isLoading: false,
      pageNo: 1,
      pageSize: 100,
      allExpand: true,
      sortBy: null,
      sortDirection: null,

      activeIncident: null,
      activeStartTimestamp: null,
      activeEndTimestamp: null,
      contextKeywordFilter: '',
      showContextModal: false,
    };

    this.LOADING_LAST_ROW = {
      eventType: 'normal',
      index: 999999999999,
      patternId: null,
      rawData: '',
      isLastRow: true,
    };
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.refresh !== nextProps.refresh ||
      this.props.selectProjectName !== nextProps.selectProjectName ||
      this.props.selectInstanceName !== nextProps.selectInstanceName ||
      this.props.selectStartTimestamp !== nextProps.selectStartTimestamp ||
      this.props.selectEndTimestamp !== nextProps.selectEndTimestamp
    ) {
      this.setState({ eventList: [], eventListTotal: 0 }, () => {
        this.reloadData(nextProps, true);
      });
    }
  }

  @autobind
  reloadData(props, force) {
    const { updateLastActionInfo, credentials, location, project } = props;
    const { selectProjectName, selectInstanceName, selectStartTimestamp, selectEndTimestamp } = props;
    const { pageSize, allExpand, sortBy, sortDirection, eventList } = this.state;
    let { pageNo } = this.state;
    pageNo = force ? 1 : pageNo + 1;

    const params = parseLocation(location);
    let { patternId } = params;
    patternId = parseInt(patternId, 10);

    if (selectProjectName && selectInstanceName && selectStartTimestamp && selectEndTimestamp) {
      this.setState({ isLoading: true, pageNo });

      const requests = [
        fetchPost(getEndpoint('logfetchallevents'), {
          ...credentials,
          projectName: selectProjectName,
          instanceName: selectInstanceName,
          startTime: selectStartTimestamp,
          endTime: selectEndTimestamp,
          consolidateFlag: true,
          nidToHighlight: patternId,
          pageNo,
          pageSize,
          nidList: JSON.stringify([patternId]),
        }),
      ];

      updateLastActionInfo();
      Promise.all(requests)
        .then((results) => {
          const { allEvents, eventsTotal } = this.logfetchalleventsParseData(results[0]);
          let newAllEvents = R.map(
            (event) => ({
              ...event,
              isExpand: allExpand,
              [expandKey]: allExpand,
              [expandKey1]: allExpand,
              projectName: selectProjectName,
              instanceName: event.instanceName || selectInstanceName,
              user: project.owner,
              typeAndColor: LogParser.CalculateLogType(event),
            }),
            allEvents || [],
          );

          newAllEvents = this.sortData(newAllEvents, sortBy, sortDirection);
          let newEventList = this.sortData(eventList, sortBy, sortDirection);
          newEventList = force ? newAllEvents : [...newEventList, ...newAllEvents];

          this.cellMeasureCache.clearAll();
          if (this.listNode && !force) {
            this.listNode.forceUpdateGrid();
            setTimeout(() => {
              this.listNode.scrollToRow(eventList.length - 1);
              this.listNode.forceUpdateGrid();
            }, 0);
          }

          this.props.changeTotal(eventsTotal);

          this.setState({ isLoading: false, eventList: newEventList, eventListTotal: eventsTotal }, () => {
            this.cellMeasureCache.clearAll();
            if (this.listNode && force) this.listNode.forceUpdateGrid();
            if (this.listNode && !force) this.listNode.scrollToRow(eventList.length - 1);
          });
        })
        .catch((err) => {
          this.cellMeasureCache.clearAll();
          this.setState({ isLoading: false });
          message.error(err.message || String(err));
        });
    }
  }

  @autobind
  logfetchalleventsParseData(data, isMetric) {
    let eventsAll = [];
    let clusterInfoList = [];
    let totalCount = 0;
    let highlightPages = [];
    const { clusterInfo, events, totalMatched, highlightPageSet } = data;
    clusterInfoList = clusterInfo || [];
    eventsAll = events || [];
    totalCount = totalMatched || 0;
    highlightPages = highlightPageSet || [];

    eventsAll = R.map((event) => {
      const { timestamp, ...rest } = event;
      return {
        datetime: timestamp,
        timestamp,
        ...rest,
      };
    }, eventsAll);
    return {
      clusterInfoList,
      allEvents: eventsAll,
      eventsTotal: totalCount,
      highlightPages,
    };
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('timestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);
    return sortList;
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const { eventList } = this.state;
          const sortList = this.sortData(eventList, name, sortDir);
          this.setState({ eventList: sortList });
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  handleAllExpand() {
    const { allExpand, eventList } = this.state;
    this.setState(
      {
        allExpand: !allExpand,
        eventList: R.map(
          (item) => ({ ...item, isExpand: !allExpand, [expandKey]: !allExpand, [expandKey1]: !allExpand }),
          eventList,
        ),
      },
      () => {
        window.setTimeout(() => {
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        }, 50);
      },
    );
  }

  @autobind
  renderTime(rowData) {
    const { timestamp } = rowData;
    return <div>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</div>;
  }

  @autobind
  loadingRendererWrapper(rowData, rowIndex) {
    const { isLoading } = this.state;
    const { isLastRow } = rowData;

    if (isLoading) {
      return (
        <div style={{ lineHeight: '40px', alignItems: 'center' }}>
          <Skeleton.Button size="small" active />
        </div>
      );
    } else if (isLastRow) {
      return (
        <a
          style={{ lineHeight: '40px', minWidth: 130 }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState({ sortBy: null, sortDirection: null }, () => {
              this.reloadData(this.props);
            });
          }}
        >
          Load more: +1 page
        </a>
      );
    }
    return this.renderTime(rowData);
  }

  @autobind
  renderType(rowData) {
    const { intl } = this.props;
    const { eventType, isLastRow } = rowData;

    if (isLastRow) {
      return <div />;
    }

    return (
      <div className="flex-row flex-wrap full-width">
        {CellRenderers.logShortTypeRenderer({ intl, type: eventType })}
      </div>
    );
  }

  @autobind
  logPatternIdRenderer(rowData) {
    const { typeAndColor, patternId, isLastRow } = rowData || {};

    if (isLastRow) {
      return <div />;
    }

    const pid = patternId;
    const { patternIdStr: msg } = Defaults.PatternIdNameStr({ patternId: pid }, {});
    const { color } = (typeAndColor && typeAndColor.length) > 0 ? typeAndColor[0] : 'gray';
    return (
      <div className="flex-row" style={{ height: '40px', alignItems: 'center' }}>
        <div className="flex-grow flex-min-width flex-row">
          <Tooltip placement="top" mouseEnterDelay={0.3} title={msg}>
            <span
              style={{
                color,
                lineHeight: '14px',
                height: 14,
                minWidth: 10,
                textAlign: 'center',
                border: `1px solid ${color}`,
                borderRadius: '20%',
                padding: '0 2px',
                cursor: 'default',
              }}
            >
              {pid}
            </span>
          </Tooltip>
        </div>
      </div>
    );
  }

  @autobind
  onToggleCollapse() {
    this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
  }

  @autobind
  renderLogEntry(props) {
    const { intl, currentTheme } = this.props;
    const { eventList } = this.state;
    const { rowData } = props;
    props.cellData = rowData.rawData;
    props.style = {};

    if (rowData?.isLastRow) {
      return <div />;
    }

    return (
      <div style={{ width: '100%' }}>
        {CellRenderers.logContent(props, this.cellMeasureCache, {
          width: '90%',
          highlightWord: this.keywords,
          isCritical: false,
          onChanged: this.onToggleCollapse,
          showRawDataFrequency: false,
          frequencyStr: '',
          allParsedEventList: eventList,
          intl,
          summarySettings: [],
          enableJsonSummary: true,
          currentTheme,
          isCacheColumn: true,
          isKeywordQuery: true,
        })}
      </div>
    );
  }

  @autobind
  handleLogContextOneMinClick(rowData) {
    const { timestamp, startTime } = rowData;
    const activeStartTimestamp = timestamp ? timestamp - 9 * 60 * 1000 : startTime - 9 * 60 * 1000;
    const activeEndTimestamp = timestamp + 60 * 1000 || startTime + 60 * 1000;
    this.setState(
      {
        activeIncident: rowData,
        activeStartTimestamp,
        activeEndTimestamp,
        contextKeywordFilter: '',
      },
      () => {
        this.setState({ showContextModal: true });
      },
    );
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;
    const { eventType, isLastRow } = rowData;

    if (isLastRow) {
      return <div />;
    }

    return (
      <>
        {eventType !== 'normal' && (
          <Button size="small" onClick={() => this.handleLogContextOneMinClick(rowData)}>
            {intl.formatMessage(eventMessages.context)}
          </Button>
        )}
      </>
    );
  }

  @autobind
  renderListItem(events) {
    return (props: Object) => {
      const { key, index: rowIndex, style, parent } = props;
      const rowData = events[rowIndex];
      if (!rowData) return null;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style, minHeight: 40 }}>
            <div className="row-column" style={{ width: 110 }}>
              {this.loadingRendererWrapper(rowData, rowIndex)}
            </div>
            <div className="row-column" style={{ width: 60 }}>
              {this.renderType(rowData)}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {this.logPatternIdRenderer(rowData)}
            </div>
            <div className="row-column" style={{ width: 100, flex: 1 }}>
              {this.renderLogEntry({ ...props, rowData, dataKey: key, rowIndex })}
            </div>
            <div className="row-column" style={{ width: 70 }}>
              {this.renderControl(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  render() {
    const { intl } = this.props;
    const { isLoading, sortBy, sortDirection, allExpand, eventListTotal } = this.state;
    let { eventList } = this.state;
    const needAddloading = eventListTotal !== 0 && eventList.length !== 0 && eventList.length < eventListTotal;

    if (needAddloading) eventList = [...eventList, this.LOADING_LAST_ROW];
    return (
      <Container className="full-width full-height flex-col">
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <div className="flex-grow flex-min-height flex-col ">
            <div className="flex-grow">
              <AutoSizer
                onResize={() => {
                  this.cellMeasureCache.clearAll();
                  if (this.listNode) this.listNode.forceUpdateGrid();
                  this.forceUpdate();
                }}
              >
                {({ width, height }) => (
                  <div className="event-list">
                    <div
                      className="event-list-header"
                      style={{
                        height: this.listHeaderHeight,
                        width,
                        paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                      }}
                    >
                      <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('timestamp')}>
                        <span>{intl.formatMessage(logMessages.dateTime)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'timestamp')}
                      </div>
                      <div className="header-column" style={{ width: 60 }} onClick={this.headerClick('eventType')}>
                        <span>{intl.formatMessage(appFieldsMessages.type)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'eventType')}
                      </div>
                      <div className="header-column" style={{ width: 100 }} onClick={this.headerClick('patternId')}>
                        <span>{intl.formatMessage(logMessages.patternId)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'patternId')}
                      </div>
                      <div className="header-column" style={{ width: 100, flex: 1 }}>
                        {intl.formatMessage(eventMessages.shortDescription)}
                        <div onClick={this.handleAllExpand}>
                          <DoubleLeftOutlined rotate={allExpand ? 90 : -90} style={{ marginLeft: 4 }} />
                        </div>
                        <div className="flex-grow" />
                        <div style={{ marginRight: 30 }}>{`(${eventList.length - (needAddloading ? 1 : 0)})`}</div>
                      </div>
                    </div>
                    <List
                      className="event-list-grid corner-0-0-8-8"
                      ref={(listNode) => {
                        this.listNode = listNode;
                      }}
                      width={width}
                      height={height - this.listHeaderHeight}
                      rowCount={eventList.length}
                      overscanRowCount={4}
                      deferredMeasurementCache={this.cellMeasureCache}
                      rowHeight={this.cellMeasureCache.rowHeight}
                      rowRenderer={this.renderListItem(eventList)}
                      onScrollbarPresenceChange={({ horizontal, vertical }) => {
                        if (vertical) {
                          this.listNodeHeaderScrollbar = true;
                          this.cellMeasureCache.clearAll();
                          if (this.listNode) this.listNode.forceUpdateGrid();
                        } else {
                          this.listNodeHeaderScrollbar = false;
                        }
                        this.forceUpdate();
                      }}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          </div>
        </Spin>
        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeIncident}
            projectName={this.props.selectProjectName}
            instanceName={this.props.selectInstanceName}
            startTimestamp={this.state.activeStartTimestamp}
            endTimestamp={this.state.activeEndTimestamp}
            keywordFilter={this.state.contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
            useTimeRange
          />
        )}
      </Container>
    );
  }
}

const MetricToLoaAllEntries = injectIntl(MetricToLoaAllEntriesCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { currentTheme } = state.app;

    return { location, credentials, currentTheme };
  },
  { updateLastActionInfo },
)(MetricToLoaAllEntries);
