/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import Papa from 'papaparse';
import numeral from 'numeral';
import { get, round } from 'lodash';
import { autobind } from 'core-decorators';
import { Button, Spin, Alert } from 'antd';

import { Container } from '../../../lib/fui/react';
import { Constants, parseJSON } from '../../../common/utils';
import { queryFieldMessages } from '../../../common/query/messages';
import { logMessages } from '../../../common/log/messages';

import { EChart } from '../../share';

type Props = {
  intl: Object,
  width: Number,
  height: Number,
  queryResult: Object,
  queryParams: Object,
};

class KPIPredictionChart extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.rowCount = 1;

    this.state = {
      instances: [],
      metrics: [],
      kpiPredictedMetrics: [],
      metricInfosMap: {},
      metricInstanceStats: {},

      kpiMetricInfos: [],
      chartFinishedStatusKpi: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    return null;
  }

  componentDidMount() {
    this.convertData(this.props.queryResult);
  }

  // In between before real DOM updates (pre-commit)
  // has access of 'this'
  // return object will be captured in componentDidUpdate
  getSnapshotBeforeUpdate(prevProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Typical usage (don't forget to compare props):
    if (this.props.queryResult !== prevProps.queryResult) {
      this.convertData(this.props.queryResult);
    }
  }

  @autobind
  convertData(queryResult) {
    const { kpiRealData, kpiPredictedData } = queryResult;
    let { accuracyMap } = queryResult;
    // init data
    let metrics = [];
    let instances = [];
    let kpiPredictedMetrics = [];
    const kpiPredictedInstances = [];
    let metricInfosMap = {};

    // parse stats
    const metricInstanceStats = {};
    accuracyMap = parseJSON(accuracyMap) || {};
    R.forEachObjIndexed((value, key) => {
      const metric = key.split('[')[0];
      const instance = key.split('[')[1].split(']')[0];
      if (!R.has(metric, metricInstanceStats)) metricInstanceStats[metric] = {};
      metricInstanceStats[metric][instance] = value;
    }, accuracyMap);

    // parse predicted data
    const papaResultPredicted = kpiPredictedData
      ? Papa.parse(kpiPredictedData, { header: true, dynamicTyping: true, skipEmptyLines: true })
      : {};
    const dataPredicted = get(papaResultPredicted, Constants.PapaData, []);
    let timestamps = [];
    R.forEach((item) => {
      const { timestamp, ...metricInstanceVals } = item;
      timestamps.push(timestamp);
      R.forEachObjIndexed((value, key) => {
        const metric = key.split('[')[0];
        const instance = key.split('[')[1].split(']')[0];
        const instanceKey = `${instance}(Predicted)`;

        if (R.has(instance, metricInstanceStats[metric])) {
          kpiPredictedMetrics.push(metric);
          kpiPredictedInstances.push(instance);
        }

        if (!R.has(metric, metricInfosMap)) metricInfosMap[metric] = {};

        if (!R.has(instanceKey, metricInfosMap[metric]))
          metricInfosMap[metric][instanceKey] = { instanceKey, instance, isPredicted: true, data: [] };

        metricInfosMap[metric][instanceKey].data.push({ timestamp, value: round(value, 2) });

        instances.push(instance);
      }, metricInstanceVals);
    }, dataPredicted);

    // parse real data
    const papaResult = kpiRealData
      ? Papa.parse(kpiRealData, { header: true, dynamicTyping: true, skipEmptyLines: true })
      : {};
    const data = get(papaResult, Constants.PapaData, []);
    R.forEach((item) => {
      const { timestamp, ...metricInstanceVals } = item;
      R.forEachObjIndexed((value, key) => {
        const metric = key.split('[')[0];
        const instance = key.split('[')[1].split(']')[0];
        const instanceKey = instance;

        if (!R.has(metric, metricInfosMap)) metricInfosMap[metric] = {};

        if (!R.has(instanceKey, metricInfosMap[metric]))
          metricInfosMap[metric][instanceKey] = { instanceKey, instance, isPredicted: false, data: [] };

        metricInfosMap[metric][instanceKey].data.push({ timestamp, value: round(value, 2) });

        instances.push(instance);
      }, metricInstanceVals);
    }, data);

    // build metric,instance map data
    metricInfosMap = R.mapObjIndexed((val, metric) => {
      let instanceMap = {};
      R.forEachObjIndexed((instanceInfo, instanceKey) => {
        const { instance } = instanceInfo;
        let { data } = instanceInfo;
        data = R.sortWith([R.ascend(R.prop('timestamp'))], data);
        if (!R.has(instance, instanceMap)) instanceMap[instance] = [];
        instanceMap[instance].push({
          ...instanceInfo,
          data,
        });
      }, val);
      instanceMap = R.mapObjIndexed((val) => R.sortWith([R.descend(R.prop('isPredicted'))], val), instanceMap);
      return instanceMap;
    }, metricInfosMap);
    instances = R.sort(R.subtract, R.uniq(instances));
    metrics = R.sort(R.subtract, R.keys(metricInfosMap));
    kpiPredictedMetrics = R.sort(R.subtract, R.uniq(kpiPredictedMetrics));
    metrics = [
      ...R.filter((metric) => kpiPredictedMetrics.indexOf(metric) !== -1, metrics),
      ...R.filter((metric) => kpiPredictedMetrics.indexOf(metric) === -1, metrics),
    ];
    timestamps = R.sort(R.subtract, timestamps);

    // create chart by filter metrics and instances
    // build kpi chart
    const kpiMetricInfos = [];
    R.forEach(
      (metric) => {
        const metricInfo = metricInfosMap[metric];
        R.forEach((instance) => {
          if (R.contains(instance, kpiPredictedInstances) && R.has(instance, metricInstanceStats[metric])) {
            const instanceInfoList = metricInfo[instance];
            if (instanceInfoList) {
              const option = this.createChart({ isKpiMetric: true, timestamps, dataList: instanceInfoList });
              kpiMetricInfos.push({
                metric,
                instance,
                option,
              });
            }
          }
        }, instances);
      },
      R.filter((metric) => kpiPredictedMetrics.indexOf(metric) !== -1, metrics),
    );

    this.setState({
      instances,
      metrics,
      kpiPredictedMetrics,
      metricInfosMap,
      metricInstanceStats,
      kpiMetricInfos,
    });
  }

  @autobind
  createChart({ isKpiMetric, timestamps, dataList }) {
    return {
      useUTC: true,
      tooltip: { trigger: 'axis' },
      toolbox: {
        orient: 'vertical',
        itemGap: 18,
        top: 0,
        left: 0,
        feature: {
          dataZoom: { title: { zoom: 'Zoom', back: 'Back' } },
          restore: { show: false, title: 'Restore' },
        },
      },
      grid: { left: 60, right: 20, top: 6, bottom: 6, containLabel: true },
      xAxis: { show: true, type: 'time', boundaryGap: false },
      yAxis: {
        boundaryGap: false,
        type: 'value',
      },
      series: R.map((item) => {
        const { instanceKey, data } = item;
        return {
          name: instanceKey,
          type: 'line',
          connectNulls: true,
          symbol: 'emptyCircle',
          showSymbol: false,
          data: R.map((val) => [val.timestamp, val.value], data),
        };
      }, dataList),
    };
  }

  @autobind
  renderMetricCard({ metricInfo, metricInstanceStats, chartFinishedStatusKpi }) {
    const { intl } = this.props;
    const { metric, instance, option } = metricInfo;
    const statusKey = `${metric}-${instance}`;

    let predictionAccuracy = metricInstanceStats[metric][instance];
    predictionAccuracy = predictionAccuracy ? numeral(predictionAccuracy).format('0.0%') : 'N/A';

    return (
      <div className="health-panel even-shadow flex-grow flex-col flex-min-height">
        <div className="flex-row" style={{ height: 32, fontWeight: 'bold', fontSize: 14 }}>
          <div className="flex-grow flex-row flex-center-align" style={{ padding: '0 8px' }}>
            {`${metric}${instance ? `(${instance})` : ''}`}
          </div>
          <div className="flex-row flex-center-align flex-end-justify" style={{ padding: '0 8px' }}>
            <div>
              {intl.formatMessage(logMessages.predictionAccuracy)}: {predictionAccuracy}
            </div>
          </div>
        </div>

        {/* <Spin spinning={!chartFinishedStatusKpi[statusKey]}> */}
        <EChart
          width={'100%'}
          height={200}
          option={option}
          // onFinished={() => this.onFinishedChart(statusKey)}
        />
        {/* </Spin> */}
      </div>
    );
  }

  @autobind
  onFinishedChart(statusKey) {
    const { chartFinishedStatusKpi } = this.state;
    if (!chartFinishedStatusKpi[statusKey]) {
      chartFinishedStatusKpi[statusKey] = true;
      this.setState({ chartFinishedStatusKpi });
    }
  }

  render() {
    const { intl, width, height } = this.props;
    const { kpiMetricInfos, metricInstanceStats, chartFinishedStatusKpi } = this.state;
    return (
      <Container className="content flex-col" style={{ height, width }}>
        <Container className={`flex-grow flex-col flex-min-height overflow-y-auto`} style={{ padding: '8px 16px' }}>
          {R.isEmpty(kpiMetricInfos) && (
            <Alert message={intl.formatMessage(queryFieldMessages.noResult)} type="info" showIcon />
          )}
          {kpiMetricInfos.length > 0 &&
            R.addIndex(R.map)(
              (cardList, index) => {
                return (
                  <div key={index} className="flex-row">
                    {this.renderMetricCard({
                      metricInfo: cardList[0],
                      metricInstanceStats,
                      chartFinishedStatusKpi,
                    })}
                    {cardList.length === 2
                      ? this.renderMetricCard({
                          metricInfo: cardList[1],
                          metricInstanceStats,
                          chartFinishedStatusKpi,
                        })
                      : this.rowCount === 2 && <div className="flex-grow" style={{ margin: '0 8px' }} />}
                  </div>
                );
              },
              R.map((index) => {
                return R.slice(index * this.rowCount, index * this.rowCount + this.rowCount, kpiMetricInfos);
              }, R.range(0, round(kpiMetricInfos.length / this.rowCount))),
            )}
        </Container>
      </Container>
    );
  }
}

export default KPIPredictionChart;
