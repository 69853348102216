/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, setInitData, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getCausalRelationTaskList, getCausalIncidentList, getInitData } from '../../apis';
import { appMessages } from '../../app/messages';

const loadCausalIncidentListLogic = createLogic({
  type: [ActionTypes.LOAD_CAUSAL_INCIDENTLIST],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials, userInfo } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { isAutoReload, causalGraphVersion, customerName } = params;

    dispatch(showLoading);
    if (!isAutoReload) dispatch(updateLastActionInfo());
    const apiAction = causalGraphVersion === 'oldCausal' ? getCausalIncidentList : getCausalRelationTaskList;
    apiAction(credentials, params)
      .then(async (data) => {
        if ((userInfo.isAdmin || userInfo.isLocalAdmin) && causalGraphVersion !== 'oldCausal') {
          const initData = await getInitData(credentials, userInfo, { customerName });
          dispatch(setInitData(initData));
        }
        dispatch(createSetAction(ActionTypes.SET_CAUSAL_INCIDENTLIST_DATA, params, data));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get causal incident list', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadCausalIncidentListLogic;
