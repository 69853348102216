import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Spin, message, Popconfirm, Button, Alert, Popover } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, parseJSON, GlobalRenderers, parseLocation } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

import NewTriageReportModal from './NewTriageReportModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  incident: Object,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  timezoneOffset: Number,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
  tabName: String,
  handleUpdate: Function,
  globalInfo: Object,
  instanceDisplayNameMap: Object,
};

class TriageReportCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      triageReportList: [],
      activeEvent: undefined,

      showAddTriageReportModal: false,
    };

    this.localServiceNowList = [];
  }

  componentDidMount() {
    this.reloadData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData() {
    const { intl, incident, credentials } = this.props;
    const { anomalyLogInstance, instanceList, patternId } = incident;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('logpatterntriage'), {
      ...credentials,
      customerName: incident.projectOwner,
      projectName: incident.projectName,
      instanceName: anomalyLogInstance || instanceList[0],
      patternId,
    })
      .then((data) => {
        // get triager report
        let triageReportList = [];
        triageReportList = parseJSON(get(data, 'triageReportList')) || [];
        this.localServiceNowList = R.filter(
          (item) => item?.triageReport?.reportSource === 'ServiceNow',
          triageReportList,
        );
        triageReportList = R.filter((item) => item?.triageReport?.reportSource !== 'ServiceNow', triageReportList);
        triageReportList = R.map((item) => {
          const { instanceName, triageReport } = item;
          const { timestamp, fieldName, report, triageReporterManagement, reportSource } = triageReport;
          const key = `${instanceName}-${timestamp}-${fieldName}-${report}`;

          let reporterRecordSet = [];
          const { reporterMap } = triageReporterManagement || {};
          const { create, edit } = reporterMap || {};
          R.forEachObjIndexed((val, owner) => {
            const { reporterName, reportTimestampSet } = val || {};
            R.forEach((timestamp) => {
              reporterRecordSet.push({ reporterName, timestamp, action: 'create' });
            }, reportTimestampSet || []);
            reporterRecordSet.push();
          }, create?.reporterDetailMap || {});
          R.forEachObjIndexed((val, owner) => {
            const { reporterName, reportTimestampSet } = val || {};
            R.forEach((timestamp) => {
              reporterRecordSet.push({ reporterName, timestamp, action: 'edit' });
            }, reportTimestampSet || []);
            reporterRecordSet.push();
          }, edit?.reporterDetailMap || {});
          reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))], reporterRecordSet);

          return {
            instanceName,

            key,
            timestamp,
            fieldName,
            report,
            triageReporterManagement,
            reporterRecordSet,
            reportSource,
          };
        }, triageReportList);
        triageReportList = R.sortWith([R.descend(R.prop('timestamp'))], triageReportList);

        this.setState({ isLoading: false, triageReportList });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, triageReportList: [] });
      });
  }

  @autobind
  handleTriageReportClick() {
    this.setState({ showAddTriageReportModal: true, activeEvent: undefined });
  }

  @autobind
  handleTriageReportClose(reload) {
    this.setState({ showAddTriageReportModal: false }, () => {
      if (reload) {
        this.reloadData();
      }
    });
  }

  @autobind
  handleTriageReportEdit(activeEvent) {
    this.setState({ showAddTriageReportModal: true, activeEvent });
  }

  @autobind
  handleTriageReportRemove(rowData) {
    const { intl, credentials, incident } = this.props;
    let { triageReportList } = this.state;
    const { projectName, projectOwner, patternId } = incident;
    const { key, instanceName } = rowData;

    // only update same instance's data
    triageReportList = R.filter((item) => item.instanceName === instanceName, triageReportList);
    const rowIndex = R.findIndex((item) => item.key === key)(triageReportList);
    let newReportList = R.remove(
      rowIndex,
      1,
      R.map((item) => {
        const { timestamp, fieldName, report, triageReporterManagement } = item;
        return { timestamp, fieldName, report, triageReporterManagement };
      }, triageReportList),
    );
    newReportList = JSON.stringify([...newReportList, ...this.localServiceNowList]);

    this.props.updateLastActionInfo();
    fetchPost(
      getEndpoint('logpatterntriage', 1),
      {
        ...credentials,
        customerName: projectOwner,
        projectName,
        patternId,
        instanceName,
        triageReportList: newReportList,
      },
      {},
      false,
    )
      .then((d) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.handleReader();
        this.reloadData();
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  handleReader() {
    if (this.props.handleUpdate) this.props.handleUpdate(true);
  }

  render() {
    const {
      intl,
      incident,
      timezoneOffset,
      tabName,
      userInfo,
      location,
      globalInfo,
      instanceDisplayNameMap: propsInstanceDisplayNameMap,
    } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    const { isLoading, triageReportList, activeEvent } = this.state;

    return (
      <>
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <div className="flex-row flex-center-align" style={{ margin: '8px 0' }}>
            <Popover
              content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
            >
              <Button
                size="small"
                type="primary"
                disabled={userInfo.isReadUser}
                style={{ width: 120 }}
                onClick={this.handleTriageReportClick}
              >
                {intl.formatMessage(appButtonsMessages.add)}
              </Button>
            </Popover>
          </div>
          {triageReportList.length === 0 && (
            <Alert message="" description={intl.formatMessage(logMessages.noTriageReport)} type="info" showIcon />
          )}
          {triageReportList.length > 0 && (
            <div className="event-list flex-grow flex-col flex-min-height">
              <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                <div className="header-column" style={{ width: 160 }}>
                  {intl.formatMessage(eventActionMessages.reportIncidentOccurrenceTime)}
                </div>
                <div className="header-column" style={{ width: 170 }}>
                  {intl.formatMessage(appFieldsMessages.instanceName)}
                </div>
                <div className="header-column" style={{ width: 120 }}>
                  {intl.formatMessage(eventActionMessages.reporterName)}
                </div>
                <div className="header-column" style={{ width: 120 }}>
                  {intl.formatMessage(appFieldsMessages.title)}
                </div>
                <div className="header-column" style={{ width: 150, flex: 1 }}>
                  {intl.formatMessage(eventActionMessages.triageReport)}
                </div>
                <div className="header-column" style={{ width: 180 }} />
              </div>
              <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                {R.addIndex(R.map)((rowData, index) => {
                  const { instanceName, timestamp, fieldName, report, reporterRecordSet } = rowData;
                  const { instanceStr } = getInstanceDisplayName(
                    instanceDisplayNameMap || propsInstanceDisplayNameMap,
                    instanceName,
                  );
                  return (
                    <div
                      key={index}
                      className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                      style={{ minHeight: 50 }}
                    >
                      <div className="row-column" style={{ width: 160 }}>
                        {moment.utc(Number(timestamp)).format(Defaults.DateTimeFormat)}
                      </div>
                      <div
                        className="row-column hidden-line-with-ellipsis-multiline"
                        style={{ width: 170, wordBreak: 'break-word', paddingRight: 8 }}
                      >
                        <Popover
                          content={
                            <div
                              style={{
                                maxWidth: 400,
                                wordBreak: 'break-word',
                                maxHeight: 300,
                                overflowY: 'auto',
                              }}
                            >
                              {instanceStr}
                            </div>
                          }
                          mouseEnterDelay={0.3}
                          placement="top"
                        >
                          <span className="clickable">{instanceStr}</span>
                        </Popover>
                      </div>
                      <div className="row-column flex-wrap" style={{ width: 120, maxHeight: 42, overflowY: 'auto' }}>
                        <GlobalRenderers.RenderReporter
                          intl={intl}
                          timezoneOffset={timezoneOffset}
                          reporterRecordSet={reporterRecordSet}
                        />
                      </div>
                      <div
                        className="row-column hidden-line-with-ellipsis-multiline"
                        style={{ width: 120, wordBreak: 'break-word' }}
                      >
                        <Popover
                          content={
                            <div
                              style={{
                                maxWidth: 400,
                                wordBreak: 'break-word',
                                maxHeight: 300,
                                overflowY: 'auto',
                              }}
                            >
                              {fieldName}
                            </div>
                          }
                          mouseEnterDelay={0.3}
                          placement="top"
                        >
                          <span className="clickable">{fieldName}</span>
                        </Popover>
                      </div>
                      <div
                        className="row-column hidden-line-with-ellipsis-multiline"
                        style={{ width: 150, flex: 1, wordBreak: 'break-word' }}
                      >
                        <Popover
                          content={
                            <div
                              style={{
                                maxWidth: 400,
                                wordBreak: 'break-word',
                                maxHeight: 300,
                                overflowY: 'auto',
                              }}
                            >
                              {report}
                            </div>
                          }
                          mouseEnterDelay={0.3}
                          placement="top"
                        >
                          <span className="clickable">{report}</span>
                        </Popover>
                      </div>
                      <div className="row-column" style={{ width: 180 }}>
                        <Popover
                          content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                          mouseEnterDelay={0.3}
                          placement="left"
                        >
                          <Button
                            size="small"
                            type="primary"
                            disabled={userInfo.isReadUser}
                            onClick={() => this.handleTriageReportEdit(rowData)}
                          >
                            <EditOutlined /> {intl.formatMessage(appButtonsMessages.edit)}
                          </Button>
                        </Popover>

                        <Popover
                          content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                          mouseEnterDelay={0.3}
                          placement="left"
                        >
                          <Popconfirm
                            placement="topRight"
                            title={intl.formatMessage(appMessages.continueConfirm)}
                            onConfirm={() => this.handleTriageReportRemove(rowData)}
                            onCancel={(event) => event.stopPropagation()}
                            disabled={userInfo.isReadUser}
                          >
                            <Button
                              size="small"
                              className="button-color-grey"
                              style={{ marginLeft: 8 }}
                              disabled={userInfo.isReadUser}
                              onClick={(event) => event.stopPropagation()}
                            >
                              <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                            </Button>
                          </Popconfirm>
                        </Popover>
                      </div>
                    </div>
                  );
                }, triageReportList)}
              </div>
            </div>
          )}
        </Spin>

        {this.state.showAddTriageReportModal && (
          <NewTriageReportModal
            tabName={tabName}
            triageReportIncident={incident}
            triageReportList={triageReportList}
            activeEvent={activeEvent}
            localServiceNowList={this.localServiceNowList}
            onClose={this.handleTriageReportClose}
            handleReader={this.handleReader}
          />
        )}
      </>
    );
  }
}

const TriageReport = injectIntl(TriageReportCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset, globalInfo } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      timezoneOffset,
      userInfo,
      credentials,
      globalInfo,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(TriageReport);
