/* @flow */
import type { Action } from '../types';


export const ActionTypes = {
  LOAD_BUG_DETAILS: 'LOAD_BUG_DETAILS',
  SET_BUG_DETAILS: 'SET_BUG_DETAILS',
};


export const loadBugRepository = (params: Object): Action => ({
  type: 'LOAD_BUG_REPOSITORY',
  payload: params,
});

export const removeBugIssue = (params: Object): Action => ({
  type: 'REMOVE_BUG_ISSUE',
  payload: params,
});

export const setBugRepository = (bugRepository: Object): Action => ({
  type: 'SET_BUG_REPOSITORY',
  payload: {
    bugRepository,
  },
});

export const saveBugRepository = (params: Object, loader: ?any): Action => ({
  type: 'SAVE_BUG_REPOSITORY',
  payload: { params, loader },
});

export const updateBugRepository = (params: Object): Action => ({
  type: 'UPDATE_BUG_REPOSITORY',
  payload: { params },
});
