/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Spin, message, Alert, Popconfirm } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Container, Table, Column, AutoSizer, Modal } from '../../../lib/fui/react';
// import { Defaults } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appButtonsMessages, appMessages } from '../../../common/app/messages';

import AddSentryModal from './AddSentryModal';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  // eslint-disable-next-line
  onClose: Function,
  refresh: Number,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  replace: Function,
  updateLastActionInfo: Function,
};

class ServiceSentryModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isDeletingMap: {},
      eventList: [],

      showAddSentryModal: false,
      activeIncident: null,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refresh !== nextProps.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials } = props;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('updateusersetting', 2), {
      ...credentials,
    })
      .then((data) => {
        const eventList = data || [];
        this.setState({ isLoading: false, errMsg: null, eventList });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, errMsg: err.message || String(err), eventList: [] });
      });
  }

  @autobind
  handleEventRemove(rowData) {
    const { intl, credentials } = this.props;
    const { isDeletingMap } = this.state;
    const { userName, sentryUserToken } = rowData;

    this.setState({ isDeletingMap: { ...isDeletingMap, [sentryUserToken]: true } });
    this.props.updateLastActionInfo();
    fetchDelete(getEndpoint('updateusersetting', 2), {
      ...credentials,
      UserName: userName,
      sentryUserToken,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(`${intl.formatMessage(appMessages.apiSuccess)}. ${errMsg}`);
          this.setState({ isDeletingMap: { ...isDeletingMap, [sentryUserToken]: false } });
          this.reloadData(this.props);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isDeletingMap: { ...isDeletingMap, [sentryUserToken]: false }, errMsg });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isDeletingMap: { ...isDeletingMap, [sentryUserToken]: false }, errMsg: String(err) });
      });
  }

  @autobind
  handleAddNewClick() {
    this.setState({ showAddSentryModal: true });
  }

  @autobind
  handleEventClick({ rowData }) {
    this.setState({ showAddSentryModal: true, activeIncident: rowData });
  }

  @autobind
  rendererWebhookToken({ cellData }) {
    if (!cellData) return 'N/A';

    return (
      <div className="hidden-line-with-ellipsis max-width">
        {R.take(6, cellData)}******{R.takeLast(6, cellData)}
      </div>
    );
  }

  @autobind
  removeRendererProjectMapping({ cellData }) {
    return <div className="hidden-line-with-ellipsis max-width">{JSON.stringify(cellData)}</div>;
  }

  @autobind
  controlRenderer({ rowData }) {
    const { intl } = this.props;
    const { isDeletingMap } = this.state;
    const { sentryUserToken } = rowData;

    return (
      <div className="flex-row flex-end-justify">
        <Button
          size="small"
          type="primary"
          style={{ marginLeft: 8 }}
          onClick={() => this.handleEventClick({ rowData })}
        >
          <EditOutlined /> {intl.formatMessage(appButtonsMessages.edit)}
        </Button>
        <Popconfirm
          placement="topRight"
          title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
          onConfirm={() => this.handleEventRemove(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button
            size="small"
            type="primary"
            // loading={get(isDeletingMap, sentryUserToken, false)}
            style={{ marginLeft: 8, background: '#474D6A', borderColor: '#474D6A' }}
            onClick={(event) => event.stopPropagation()}
          >
            <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.delete)}
          </Button>
        </Popconfirm>
      </div>
    );
  }

  render() {
    const { onClose, userInfo, intl } = this.props;
    const { isLoading, errMsg, eventList } = this.state;
    return (
      <Modal width={1024} title="Sentry" visible maskClosable={false} onCancel={() => onClose()} footer={null}>
        <Container className="flex-col" style={{ height: 500 }}>
          <div style={{ marginBottom: 12 }}>
            <Button
              size="small"
              type="primary"
              disabled={!userInfo.isAdmin && eventList.length >= 1}
              onClick={this.handleAddNewClick}
            >
              Add New
            </Button>
          </div>
          <div className="flex-grow flex-min-height">
            <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
              {errMsg && <Alert message={errMsg} type="error" showIcon />}
              {!errMsg && (
                <AutoSizer>
                  {({ height, width }) => (
                    <Table
                      className="with-border"
                      width={width}
                      height={height}
                      headerHeight={40}
                      rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                      rowHeight={40}
                      rowCount={eventList.length}
                      rowGetter={({ index }) => eventList[index]}
                    >
                      {userInfo.isAdmin && (
                        <Column width={100} label={intl.formatMessage(eventMessages.userName)} dataKey="userName" />
                      )}
                      <Column
                        width={180}
                        label="Sentry user auth token"
                        dataKey="sentryUserToken"
                        cellRenderer={this.rendererWebhookToken}
                      />
                      <Column
                        width={180}
                        label="Sentry webhook token"
                        dataKey="sentryWebhookToken"
                        cellRenderer={this.rendererWebhookToken}
                      />
                      <Column
                        width={150}
                        label="Sentry project mapping"
                        dataKey="sentryProjectMapping"
                        flexGrow={1}
                        cellRenderer={this.removeRendererProjectMapping}
                      />
                      <Column width={190} label="" dataKey="sentryUserToken" cellRenderer={this.controlRenderer} />
                    </Table>
                  )}
                </AutoSizer>
              )}
            </Spin>
          </div>

          {this.state.showAddSentryModal && (
            <AddSentryModal
              activeIncident={this.state.activeIncident}
              onClose={(reload) =>
                this.setState({ showAddSentryModal: false, activeIncident: null }, () => {
                  if (reload) {
                    this.reloadData(this.props);
                  }
                })
              }
            />
          )}
        </Container>
      </Modal>
    );
  }
}

const ServiceSentryModal = injectIntl(ServiceSentryModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return { location, systemsMap, userInfo, credentials };
  },
  { replace, updateLastActionInfo },
)(ServiceSentryModal);
