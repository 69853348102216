/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
**/

import loadQueryResultLogic from './loadQueryResultLogic';
import loadQueryTemplateListLogic from './loadQueryTemplateListLogic';

export default [loadQueryResultLogic, loadQueryTemplateListLogic];
