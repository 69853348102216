import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';
import type { Credentials } from '../types';

const getPatternNameMap = (credentials: Credentials, params: Object) => {
  const { projectInfoList } = params;
  return fetchPost(getEndpoint('getpatternnamemap', 1), {
    ...credentials,
    projectInfoList: JSON.stringify(projectInfoList),
  }).then((data) => {
    return { patternNameMap: data };
  });
};

export default getPatternNameMap;
