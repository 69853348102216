/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
**/
import loadCausalDependencyGraphLogic from './loadCausalDependencyGraphLogic';
import loadCausalLogEventsLogic from './loadCausalLogEventsLogic';
import loadCausalGroupAnalysisLogic from './loadCausalGroupAnalysisLogic';
import loadCausalGroupListLogic from './loadCausalGroupListLogic';
import loadCausalIncidentListLogic from './loadCausalIncidentListLogic';
import loadCausalIncidentMetaDataLogic from './loadCausalIncidentMetaDataLogic';

export default [
  loadCausalDependencyGraphLogic,
  loadCausalLogEventsLogic,
  loadCausalGroupAnalysisLogic,
  loadCausalGroupListLogic,
  loadCausalIncidentListLogic,
  loadCausalIncidentMetaDataLogic,
];
