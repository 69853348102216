/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import { get } from 'lodash';

import { ActionTypes } from '../actions';
import { ActionTypes as AppActionTypes, createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getLogCausalEventList, getAnomalyLogEvents } from '../../apis';

const loadMetricLineChartsDetailsLogic = createLogic({
  type: [ActionTypes.LOAD_METRIC_LINECHARTS_DETAILS],
  cancelType: AppActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const {
      customerName,
      causalFetchedInfoFileName,
      projectName,
      instanceNameList,
      startTimestamp,
      endTimestamp,
    } = params;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());

    let logEntryCausalList = [];
    let eventLogListMap = {};

    if (customerName && causalFetchedInfoFileName) {
      try {
        logEntryCausalList = get(
          await getLogCausalEventList(credentials, { customerName, causalFetchedInfoFileName, operation: 'log' }),
          ['logEntryCausalList'],
          [],
        );
      } catch (err) {
        console.error('[IF_API]', err);
      }
    }

    if (projectName && startTimestamp && endTimestamp && instanceNameList.length > 0) {
      try {
        eventLogListMap = get(
          await getAnomalyLogEvents(credentials, { projectName, instanceNameList, startTimestamp, endTimestamp }),
          ['data'],
          {},
        );
      } catch (err) {
        console.error('[IF_API]', err);
      }
    }
    dispatch(
      createSetAction(ActionTypes.SET_METRIC_LINECHARTS_DETAILS, params, {
        lineChartsMetricDetails: { logEntryCausalList, eventLogListMap },
      }),
    );
    dispatch(hideLoading);
    done();
  },
});

export default loadMetricLineChartsDetailsLogic;
