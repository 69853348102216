/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import moment from 'moment';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../utils';

const getLogImportantList = (credentials: Credentials, params: Object) => {
  const { day, startTime, endTime, startTimestamp: st, endTimestamp: et, ...rest } = params;

  let startTimestamp = moment.utc(day, Defaults.DateFormat).valueOf();
  let endTimestamp = moment.utc(day, Defaults.DateFormat).endOf('day').valueOf();
  if (startTime && endTime) {
    startTimestamp = moment.utc(startTime, Defaults.DateFormat).valueOf();
    endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
  }
  const startTimestampList = R.map(
    (i) => startTimestamp + i * 86400000,
    R.range(0, (endTimestamp + 1 - startTimestamp) / 86400000),
  );

  const requests = [];
  R.forEach((dayTimeMillis) => {
    requests.push(
      fetchGet(getEndpoint('fetchimportantlog'), {
        ...credentials,
        ...rest,
        ...(st && et ? { startTime: st, endTime: et } : {}),
        day: moment.utc(dayTimeMillis).format(Defaults.DateFormat),
      }),
    );
  }, startTimestampList);

  return Promise.all(requests).then((results) => {
    const logImportantEntryTotal = R.reduce(
      R.add,
      0,
      R.map((item) => item.totalMatched || 0, results || []),
    );
    const logImportantEntryList = R.reduce(
      R.concat,
      [],
      R.map((item) => item.eventArray || [], results || []),
    );
    return { logImportantEntryList, logImportantEntryTotal };
  });
};

export default getLogImportantList;
