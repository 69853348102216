/* @flow */

import { defineMessages } from 'react-intl';

const settingsMenusMessages = defineMessages({
  systemSetting: {
    defaultMessage: 'System settings',
    id: 'settings.menus.systemSetting',
  },
  systemBatch: {
    defaultMessage: 'System batch configuration',
    id: 'settings.menus.systemBatch',
  },
  ifSetting: {
    defaultMessage: 'InsightFinder platform settings',
    id: 'settings.menus.ifSetting',
  },
  externalService: {
    defaultMessage: 'External service settings',
    id: 'settings.menus.externalService',
  },
  identityProvider: {
    defaultMessage: 'Global system settings',
    id: 'settings.menus.identityProvider',
  },
  statusPage: {
    defaultMessage: 'Status page settings',
    id: 'settings.menus.statusPage',
  },
  deleteProject: {
    defaultMessage: 'Delete project',
    id: 'settings.menus.deleteProject',
  },
  serviceMapMsg: {
    defaultMessage: 'Service Map',
    id: 'settings.menus.serviceMapMsg',
  },
  actionMsg: {
    defaultMessage: 'Action',
    id: 'settings.menus.actionMsg',
  },
});

export default settingsMenusMessages;
