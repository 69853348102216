/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import * as R from 'ramda';
import moment from 'moment';

import fetchGet from './fetchGet';
import getEndpoint from './getEndpoint';
import type { Credentials } from '../types';
import { Defaults } from '../utils';

const getLogInstanceList = (credentials: Credentials, params: Object) => {
  const { projectName, day, startTime, endTime, pageNo, pageSize } = params;

  let startTimestamp = moment.utc(day, Defaults.DateFormat).valueOf();
  let endTimestamp = moment.utc(day, Defaults.DateFormat).endOf('day').valueOf();
  if (startTime && endTime) {
    startTimestamp = moment.utc(startTime, Defaults.DateFormat).valueOf();
    endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
  }

  const startTimestampList = R.map(
    (i) => startTimestamp + i * 86400000,
    R.range(0, (endTimestamp + 1 - startTimestamp) / 86400000),
  );

  const requests = [];
  R.forEach((dayTimeMillis) => {
    requests.push(
      fetchGet(getEndpoint('fetchloginstanceinfo'), {
        ...credentials,
        projectName,
        dayTimeMillis,
        pageNo,
        pageSize,
      }),
    );
  }, startTimestampList);

  requests.push(
    fetchGet(getEndpoint('fetchloginstancecountinfo'), {
      dayTimeMillis: startTimestamp,
      projectName,
    }),
  );

  return Promise.all(requests).then((results) => {
    const dataList = R.slice(0, startTimestampList.length, results);
    const { totalSize: instanceInfoTotal = 0 } = results[startTimestampList.length];
    console.log('totalSize', instanceInfoTotal);

    let instanceInfoList = [];
    R.forEach((item) => {
      instanceInfoList = [...instanceInfoList, ...item];
    }, dataList || []);
    instanceInfoList = R.map((item) => {
      const { appName, instanceName: inst, metricInstance, anomalyCountInfo } = item;
      const label = appName && appName !== inst ? `${inst} (${appName})` : inst;

      return {
        id: inst,
        instanceName: inst,
        metricInstance,
        appName,
        anomalyMap: anomalyCountInfo || {
          coldEventCount: 0,
          entryCount: 0,
          hotEventCount: 0,
          incidentEventCount: 0,
          newPatternCount: 0,
          rareEventCount: 0,
          whitelistEventCount: 0,
        },
        value: inst,
        label,
      };
    }, instanceInfoList);
    return {
      instanceInfoList,
      instanceInfoTotal,
    };
  });
};

export default getLogInstanceList;
