/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getProjectFiltersSetting } from '../../apis';
import { appMessages } from '../../app/messages';

const loadProjectFiltersSettingLogic = createLogic({
  type: [ActionTypes.LOAD_PROJECT_FILTERS_SETTING],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getProjectFiltersSetting(credentials, params)
      .then((d) => {
        dispatch(createSetAction(ActionTypes.SET_PROJECT_FILTERS_SETTING, params, d));
        dispatch(hideLoading);
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed get filters, ignored', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadProjectFiltersSettingLogic;
