/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import * as R from 'ramda';
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getProjectModel } from '../../apis';
import { appMessages } from '../../app/messages';

const loadProjectModelsSettingLogic = createLogic({
  type: [ActionTypes.LOAD_PROJECT_MODELS],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    const { setting } = params;

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getProjectModel(credentials, params)
      .then((d) => {
        dispatch(
          createSetAction(ActionTypes.SET_PROJECT_MODELS, params, {
            [setting === 'model' ? 'models' : 'holidaymodels']: d.data.models,
          }),
        );
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed get project models, ignored', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }

        dispatch(hideLoading);
        done();
      });
  },
});

export default loadProjectModelsSettingLogic;
