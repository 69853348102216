/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import { get, round } from 'lodash';
import Helmet from 'react-helmet';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import {
  parseLocation,
  getLoadStatus,
  ifIn,
  Defaults,
  anomalySeverityRendererItem,
  CellRenderers,
} from '../../../common/utils';
import { Defaults as AppDefaults } from '../../../common/app';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/report/actions';
import { Container } from '../../../lib/fui/react';

type Props = {
  // Common props
  intl: Object,
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  allEventList: Array<Object>,
  metricUnitMap: Object,
};

class MetricReportCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.pageWidth = 700;
    this.dataLoader = 'report_metric';
  }

  componentDidMount() {
    const { intl } = this.props;
    moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);

    this.reloadData(this.props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = nextProps;
    if (intl.locale !== this.props.intl.locale) {
      moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    }
  }

  @autobind
  reloadData(props) {
    const { location, createLoadAction } = props;
    const params = parseLocation(location);
    const { projectName, startTimestamp, endTimestamp } = params;

    createLoadAction(ActionTypes.LOAD_REPORT_METRIC, { projectName, startTimestamp, endTimestamp });
  }

  @autobind
  renderMetricEntry(idx, ev) {
    const { metricUnitMap, intl } = this.props;
    const topestRank = 100;
    const blueColor = '#2185d0';
    const rootCauseDetailsArr = get(ev, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    return (
      <div key={idx} style={{ marginTop: 8, position: 'relative' }}>
        <div style={{ fontWeight: 'bold', fontSize: 13 }}>{`${moment.utc(ev.timestamp).toString()}`}</div>
        <div className="flex-row">
          <div className="flex-grow flex-row" style={{}}>
            <span style={{}}>Pattern Name:</span>
            <span style={{ paddingLeft: 20 }}>{get(ev, 'patternName', '')}</span>
          </div>
          <div className="flex-grow flex-row" style={{}}>
            <span style={{}}>Duration:</span>
            <span style={{ paddingLeft: 20 }}>{CellRenderers.anomalyEventDurationRenderer({ rowData: ev })}</span>
          </div>
          <div className="flex-grow flex-row" style={{}}>
            <span style={{}}>Anomaly Score:</span>
            <span style={{ paddingLeft: 20 }}>{round(get(ev, 'anomalyRatio', 0), 2)}</span>
          </div>
        </div>
        <div style={{}}>Event Summary:</div>
        <div style={{ wordBreak: 'break-all' }}>
          {R.addIndex(R.map)((e, index) => {
            const { appName, rootCauseMetric, instanceId, metricValue, pct, direction } = e;
            const { legend } = anomalySeverityRendererItem(e, index, blueColor, topestRank);
            const name = appName && appName !== instanceId ? `${appName} (${instanceId})` : instanceId;
            const unit = get(metricUnitMap, rootCauseMetric);
            return (
              <div className="flex-row detail-row" key={index}>
                <div className="detail-item" style={{ width: 250, color: 'green' }}>
                  {name}
                </div>
                <div className="detail-item" style={{ width: 60 }}>
                  {legend}
                </div>
                <div className="detail-item" style={{ width: 90, color: 'blue' }}>
                  {rootCauseMetric}
                </div>
                {metricValue && (
                  <div className="detail-item" style={{ width: 80, textAlign: 'right' }}>
                    {R.compose(R.replace('(', ''), R.replace(')', ''))(metricValue)}
                    {unit ? `(${unit})` : ''}
                  </div>
                )}
                {pct && (
                  <div className="detail-item" style={{ width: 220, flex: 1 }}>
                    &nbsp;is <span style={{ color: 'red' }}>{numeral(Math.abs(Number(pct) / 100)).format('0.0%')}</span>{' '}
                    {direction}
                  </div>
                )}
              </div>
            );
          }, rootCauseDetailsArr)}
        </div>
      </div>
    );
  }

  render() {
    const { intl, loadStatus, allEventList } = this.props;
    const { isLoading } = getLoadStatus(get(loadStatus), intl);
    const { location } = this.props;
    const params = parseLocation(location);
    const { projectName, startTimestamp, endTimestamp } = params;
    const startTimeObj = moment.utc(Number(startTimestamp));
    const endTimeObj = moment.utc(Number(endTimestamp));
    const startTime = startTimeObj.toString();
    const endTime = endTimeObj.toString();

    return (
      <Container className={`report ${isLoading ? 'loading' : ''}`} style={{ width: '100%', minHeight: 200 }}>
        <Helmet title={`report-${projectName}-${startTime}-${endTime}`} htmlAttributes={{ class: 'report' }} />
        <div style={{ fontSize: 12, width: this.pageWidth, margin: '0 auto', padding: '16px 0' }}>
          <div style={{ fontSize: 14, fontWeight: 'bold' }}>Event list (All Instance)</div>
          <div style={{ fontWeight: 'bold' }}>{`Time Range: ${startTime} ~ ${endTime}`}</div>
          {R.addIndex(R.map)((ev, idx) => {
            return this.renderMetricEntry(idx, ev);
          }, allEventList || [])}
        </div>
      </Container>
    );
  }
}

const MetricReport = injectIntl(MetricReportCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { metricReportData } = state.report;
    const { allEventList, metricUnitMap } = metricReportData || {};
    return { location, loadStatus, allEventList, metricUnitMap };
  },
  { createLoadAction },
)(MetricReport);
