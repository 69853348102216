/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import { defineMessages } from 'react-intl';

const causalMessages = defineMessages({
  infoCausalIncidentRemoved: {
    defaultMessage: 'This item is removed',
    id: 'usecase.info.infoCausalIncidentRemoved',
  },
  infoCausalGroupRemoved: {
    defaultMessage: 'This causal group is removed',
    id: 'usecase.info.infoCausalGroupRemoved',
  },
  causalGroups: {
    defaultMessage: 'Causal Groups',
    id: 'causal.causalGroups',
  },
  causalKey: {
    defaultMessage: 'Causal Key',
    id: 'causal.causalKey',
  },
  scope: {
    defaultMessage: 'Scope',
    id: 'causal.scope',
  },
  intervalLimit: {
    defaultMessage: 'Causal interval limit',
    id: 'causal.intervalLimit',
  },
  considerAnomalyAfterIncident: {
    defaultMessage: 'Consider post-incident anomalies',
    id: 'causal.considerAnomalyAfterIncident',
  },
  sourceCandidateAnomalyTypes: {
    defaultMessage: 'Source candidate anomaly types',
    id: 'causal.sourceCandidateAnomalyTypes',
  },
  disableMetricToIncidentDurationLimitation: {
    defaultMessage: 'Disable Metric To Incident Duration Limitation',
    id: 'causal.disableMetricToIncidentDurationLimitation',
  },
  enableAnomalyToIncidentGapCheck: {
    defaultMessage: 'Enable anomaly to incident gap check',
    id: 'causal.enableAnomalyToIncidentGapCheck',
  },
  enableLatestComponentLevelCausalValidations: {
    defaultMessage: 'Enable latest component level causal validations',
    id: 'causal.enableLatestComponentLevelCausalValidations',
  },
  enableDependencyFiltering: {
    defaultMessage: 'Enable dependency map filtering',
    id: 'causal.enableDependencyFiltering',
  },
  enableInterRelation: {
    defaultMessage: 'Enable inter relation',
    id: 'causal.enableInterRelation',
  },
  enableIncidentOnly: {
    defaultMessage: 'Enable incident only',
    id: 'causal.enableIncidentOnly',
  },
  enableOrphanIncidentParing: {
    defaultMessage: 'Enable orphan incident pairing',
    id: 'causal.enableOrphanIncidentParing',
  },
  eventsRelationLookBackTime: {
    defaultMessage: 'Causal Graph Search Range (days)',
    id: 'causal.eventsRelationLookBackTime',
  },
  duration: {
    defaultMessage: 'Duration',
    id: 'causal.duration',
  },
  probability: {
    defaultMessage: 'Probability',
    id: 'causal.probability',
  },
  days: {
    defaultMessage: 'days',
    id: 'causal.days',
  },
  createCausalGroup: {
    defaultMessage: 'Create Causal Group',
    id: 'causal.createCausalGroup',
  },
  createCausalAnalysis: {
    defaultMessage: 'Create Causal Analysis',
    id: 'causal.createCausalAnalysis',
  },
  shareCausalGroup: {
    defaultMessage: 'Share Causal Group',
    id: 'causal.shareCausalGroup',
  },
  removeCausalGroup: {
    defaultMessage: 'Remove Causal Group',
    id: 'causal.removeCausalGroup',
  },
  causalAnalysisInfo: {
    defaultMessage:
      'Causal Analysis is used to analyze the relationship between metrics, log entries, and KPIs. It provides a cross-project view of data by analyzing different projects and instance groups within a Causal Group.',
    id: 'causal.causalAnalysisInfo',
  },
  causalGroupInfo: {
    defaultMessage:
      'To view an existing Causal Group, select from the left side or click "Create Causal Group" below. After creating the Causal Group, you can create Causal Analyses for different time windows.',
    id: 'causal.causalGroupInfo',
  },
  relationStartTime: {
    defaultMessage: 'Relation start time',
    id: 'causal.relationStartTime',
  },
  relationEndTime: {
    defaultMessage: 'Relation end time',
    id: 'causal.relationEndTime',
  },
  status: {
    defaultMessage: 'Status',
    id: 'causal.status',
  },
  viewCausalGraph: {
    defaultMessage: 'View Causal Graph',
    id: 'causal.viewCausalGraph',
  },
  showSubTasks: {
    defaultMessage: 'Show Sub Tasks',
    id: 'causal.showSubTasks',
  },
  requestTime: {
    defaultMessage: 'Request time',
    id: 'causal.requestTime',
  },
  finishTime: {
    defaultMessage: 'Finish time',
    id: 'causal.finishTime',
  },
  createTimeRangeInfo: {
    defaultMessage: 'Select the start time and end time to create the causal analysis',
    id: 'causal.createTimeRangeInfo',
  },
  causalTaskDuration: {
    defaultMessage: 'Causal task duration',
    id: 'causal.causalTaskDuration',
  },
  createTimeRangeError: {
    defaultMessage: 'only {maxNumberOfDays} days is allowed for this causal group',
    id: 'causal.createTimeRangeError',
  },
  finish: {
    defaultMessage: 'finish',
    id: 'causal.finish',
  },
  causalName: {
    defaultMessage: 'Causal Name',
    id: 'causal.causalName',
  },
  group: {
    defaultMessage: 'Group',
    id: 'causal.group',
  },
  groupInterval: {
    defaultMessage: 'Causal Graph Calculation Time Span (days)',
    id: 'causal.groupInterval',
  },
  retentionTime: {
    defaultMessage: 'Retention (days)',
    id: 'causal.retentionTime',
  },
  correlationInterval: {
    defaultMessage: 'Correlation Interval',
    id: 'causal.correlationInterval',
  },
  createCausalGroupInfo: {
    defaultMessage: 'Add Project/Instance Group',
    id: 'causal.createCausalGroupInfo',
  },
  createCausalGroupTip: {
    defaultMessage: 'Create a Causal Group for one or multiple project/instance group.',
    id: 'causal.createCausalGroupTip',
  },
  editCausalGroup: {
    defaultMessage: 'Edit causal group',
    id: 'causal.editCausalGroup',
  },
  relatedAnomalies: {
    defaultMessage: 'Related Anomalies',
    id: 'causal.relatedAnomalies',
  },
  concurrentAnomalies: {
    defaultMessage: 'Concurrent Anomalies',
    id: 'causal.concurrentAnomalies',
  },
  dependencyGraph: {
    defaultMessage: 'Dependency Map',
    id: 'causal.dependencyGraph',
  },
  kpiPredictionGraph: {
    defaultMessage: 'KPI Prediction Graph',
    id: 'causal.kpiPredictionGraph',
  },
  filterOptions: {
    defaultMessage: 'Filter Options',
    id: 'causal.filterOptions',
  },
  graphView: {
    defaultMessage: 'Graph View',
    id: 'causal.graphView',
  },
  modality: {
    defaultMessage: 'Relation',
    id: 'causal.modality',
  },
  filterMetric: {
    defaultMessage: 'Metric',
    id: 'causal.filterMetric',
  },
  joinDependencyGraph: {
    defaultMessage: 'Join Dependency Map',
    id: 'causal.joinDependencyGraph',
  },
  nodeList: {
    defaultMessage: 'Node List',
    id: 'causal.nodeList',
  },
  rootTreeNode: {
    defaultMessage: 'Root Node',
    id: 'causal.rootTreeNode',
  },
  nonRootTreeNode: {
    defaultMessage: 'Non-root Node',
    id: 'causal.nonRootTreeNode',
  },
  hasRelation: {
    defaultMessage: 'Inter May Impact',
    id: 'causal.hasRelation',
  },
  noRelation: {
    defaultMessage: 'Inter May Not Imapct',
    id: 'causal.noRelation',
  },
  intraMayNotImapct: {
    defaultMessage: 'Intra May Not Imapct',
    id: 'causal.intraMayNotImapct',
  },
  instanceRelations: {
    defaultMessage: 'Instance Relations',
    id: 'causal.instanceRelations',
  },
  backToInstances: {
    defaultMessage: 'Back to instances relation',
    id: 'causal.backToInstances',
  },
  eventsRelationsForInstance: {
    defaultMessage: 'Events relations for instance',
    id: 'causal.eventsRelationsForInstance',
  },
  viewRelationForInstance: {
    defaultMessage: 'View the relation for instance',
    id: 'causal.viewRelationForInstance',
  },
  noCasualRelationFuond: {
    defaultMessage: 'No root cause relation is found',
    id: 'causal.noCasualRelationFuond',
  },
  noConcurrentCorrelationFuond: {
    defaultMessage: 'No concurrent correlation is found',
    id: 'causal.noConcurrentCorrelationFuond',
  },
  noDependencyRelationFuond: {
    defaultMessage: 'No dependency relation is found',
    id: 'causal.noDependencyRelationFuond',
  },
  noCasualAnomaliesFound: {
    defaultMessage: 'No Casual Anomalies found for',
    id: 'causal.noCasualAnomaliesFound',
  },
  noConcurrentAnomaliesFound: {
    defaultMessage: 'No Concurrent Anomalies found for',
    id: 'causal.noConcurrentAnomaliesFound',
  },
  noCasualAnomaliesFoundLongInfo: {
    defaultMessage:
      'No Casual Anomalies found for this time period, view "Concurrent Anomalies". Or view the relation for instance',
    id: 'causal.noCasualAnomaliesFoundLongInfo',
  },
  noConcurrentAnomaliesFoundLongInfo: {
    defaultMessage:
      'No Concurrent Anomalies found for this time period, view "Causal Anomalies". Or view the relation for instance',
    id: 'causal.noConcurrentAnomaliesFoundLongInfo',
  },
  patternFilter: {
    defaultMessage: 'Pattern Filter',
    id: 'causal.patternFilter',
  },
  nodeFilter: {
    defaultMessage: 'Node Filter',
    id: 'causal.nodeFilter',
  },
  instanceFilter: {
    defaultMessage: 'Instance Filter',
    id: 'causal.instanceFilter',
  },
  concurrentAnomaliesInfo: {
    defaultMessage: 'Concurrent Anomalies Info',
    id: 'causal.concurrentAnomaliesInfo',
  },
  inboundNodeTop: {
    defaultMessage: 'Inbound Node{top}',
    id: 'causal.inboundNodeTop',
  },
  outboundNodeTop: {
    defaultMessage: 'Outbound Node{top}',
    id: 'causal.outboundNodeTop',
  },
  modalityFilter: {
    defaultMessage: 'Modality Filter',
    id: 'causal.modalityFilter',
  },
  keywordFilter: {
    defaultMessage: 'Keyword Filter',
    id: 'causal.keywordFilter',
  },
  mayImpact: {
    defaultMessage: 'may impact',
    id: 'causal.mayImpact',
  },
  source: {
    defaultMessage: 'Source',
    id: 'causal.source',
  },
  sourceShortDescription: {
    defaultMessage: 'Source short description',
    id: 'causal.sourceShortDescription',
  },
  sourceList: {
    defaultMessage: 'Source List',
    id: 'causal.sourceList',
  },
  target: {
    defaultMessage: 'Target',
    id: 'causal.target',
  },
  targetShortDescription: {
    defaultMessage: 'Target short description',
    id: 'causal.targetShortDescription',
  },
  targets: {
    defaultMessage: 'Targets',
    id: 'causal.targets',
  },
  clickHightlight: {
    defaultMessage: 'Click for hightlight',
    id: 'causal.clickHightlight',
  },
  relationInfoStep1: {
    defaultMessage: 'anomalies on',
    id: 'causal.relationInfoStep1',
  },
  relationInfoStep2: {
    defaultMessage: 'were followed within {relationTimeThreshold} mins by anomalies on',
    id: 'causal.relationInfoStep2',
  },
  relationInfoStep3: {
    defaultMessage: 'happened concurrently with anomalies on',
    id: 'causal.relationInfoStep3',
  },
  relationInfoStep4: {
    defaultMessage: 'with probability',
    id: 'causal.relationInfoStep4',
  },
  rerunCausal: {
    defaultMessage: 'Rerun Causal',
    id: 'causal.rerunCausal',
  },
  rerunTheWholeCausalGraph: {
    defaultMessage: 'Rerun the whole causal graph',
    id: 'causal.rerunTheWholeCausalGraph',
  },
  rerunCausalTasks: {
    defaultMessage: 'Rerun causal tasks',
    id: 'causal.rerunCausalTasks',
  },
  rerunRelation: {
    defaultMessage: 'This relation will be rerun.',
    id: 'causal.rerunRelation',
  },
  removeRelation: {
    defaultMessage: 'This relation will be removed.',
    id: 'causal.removeRelation',
  },
  lockRelation: {
    defaultMessage: 'This relation will set to lock status.',
    id: 'causal.lockRelation',
  },
  unlockRelation: {
    defaultMessage: 'This relation will remove lock status.',
    id: 'causal.unlockRelation',
  },
  causalMetric: {
    defaultMessage: 'Metric',
    id: 'causal.causalMetric',
  },
  causalLog: {
    defaultMessage: 'Log',
    id: 'causal.causalLog',
  },
  causalIncident: {
    defaultMessage: 'Incident',
    id: 'causal.causalIncident',
  },
  causalAlert: {
    defaultMessage: 'Alert',
    id: 'causal.causalAlert',
  },
  metricToMetric: {
    defaultMessage: 'Metric {flag} Metric',
    id: 'causal.metricToMetric',
  },
  metricToIncident: {
    defaultMessage: 'Metric {flag} Incident',
    id: 'causal.metricToIncident',
  },
  incidentToMetric: {
    defaultMessage: 'Incident {flag} Metric',
    id: 'causal.incidentToMetric',
  },
  metricToAlert: {
    defaultMessage: 'Metric {flag} Alert',
    id: 'causal.metricToAlert',
  },
  metricToLog: {
    defaultMessage: 'Metric {flag} Log',
    id: 'causal.metricToLog',
  },
  logToLog: {
    defaultMessage: 'Log {flag} Log',
    id: 'causal.logToLog',
  },
  logToIncident: {
    defaultMessage: 'Log {flag} Incident',
    id: 'causal.logToIncident',
  },
  alertToIncident: {
    defaultMessage: 'Alert {flag} Incident',
    id: 'causal.alertToIncident',
  },
  logToAlert: {
    defaultMessage: 'Log {flag} Alert',
    id: 'causal.logToAlert',
  },
  sharedUsers: {
    defaultMessage: 'Shared users',
    id: 'causal.sharedUsers',
  },
  shareUser: {
    defaultMessage: 'Share user',
    id: 'causal.shareUser',
  },
  pairingDuration: {
    defaultMessage: 'Pairing duration',
    id: 'causal.pairingDuration',
  },
  editPairingDuration: {
    defaultMessage: 'Edit pairing duration',
    id: 'causal.editPairingDuration',
  },
});

export default causalMessages;
