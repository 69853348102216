/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';

import type { SettingsState, Action } from '../types';
import { ActionTypes } from './actions';

const initialState: SettingsState = {
  projectSettings: {},
  projectSettingsParams: {},
  projectGroups: ['All'],
  currentApisParams: {},
  currentErrorMessage: null,
  projectCreationStatus: '',
  projectCreationResponse: {},
  externalServiceList: [],
  holidayList: [],
  userList: [],
  rabbitMQStatusList: [],
  internal: {},
  currentInstanceId: null,
  instanceSettings: { metricList: [] },
  capacitySettings: { metricList: [] },
  componentVersions: {},
};

const reducer = (state: SettingsState = initialState, action: Action): SettingsState => {
  if (action.type === 'SET_SETTINGS_INFO') {
    return {
      ...state,
      ...action.payload,
    };
  } else if (action.type === ActionTypes.SET_SYSTEM_GENERAL_INFO) {
    // Merge the versions from payload with versions in state.
    const { componentVersions } = action.payload.data;
    return {
      ...state,
      componentVersions: { ...state.componentVersions, ...componentVersions },
    };
  } else if (action.type === 'SET_PROJECT_SETTINGS') {
    return {
      ...state,
      ...action.payload,
    };
  } else if (action.type === 'SET_PROJECT_METRIC_SETTINGS') {
    const {
      instanceId,
      metricList,
      metricListCount,
      possibleMetricType,
      metricStatsMap,
      patternIdGenerationRule,
      patternNameRegex,
    } = action.payload;
    const { instanceSettings } = state;
    return {
      ...state,
      currentInstanceId: instanceId,
      instanceSettings: {
        ...instanceSettings,
        metricList,
        metricListCount,
        possibleMetricType,
        metricStatsMap,
        patternIdGenerationRule,
        patternNameRegex,
      },
    };
  } else if (action.type === 'SET_PROJECT_CAPACITY_SETTINGS') {
    const { instanceId, metricList, metricListCount, possibleMetricType, metricStatsMap, patternIdGenerationRule } =
      action.payload;
    const { capacitySettings } = state;
    return {
      ...state,
      currentInstanceId: instanceId,
      capacitySettings: {
        ...capacitySettings,
        metricList,
        metricListCount,
        possibleMetricType,
        metricStatsMap,
        patternIdGenerationRule,
      },
    };
  } else if (action.type === 'SET_PROJECT_METRIC_SETTING_EXCLUDED_METRICS') {
    const { excludedMetrics } = action.payload;
    return {
      ...state,
      excludedMetrics,
    };
  } else if (action.type === 'CREATE_PROJECT') {
    return {
      ...state,
      projectCreationStatus: 'creating',
    };
  } else if (action.type === 'SET_PROJECT_CREATION_STATUS') {
    const { status, info } = action.payload || {};
    return {
      ...state,
      projectCreationStatus: status,
      projectCreationResponse: info || {},
    };
  } else if (action.type === 'UPDATE_PROJECT_MODEL_STATUS') {
    // This action doesn't work.
    console.error("[IF] BUG: update model doesn't work", action.payload);
    const { projectSettings } = state;
    const { modelKey, status } = action.payload;
    let models = projectSettings.models || [];

    const idx = R.findIndex((m) => m.modelKey === modelKey, models);
    let changed = false;
    if (idx >= 0) {
      if (status === 'removed') {
        models = R.slice(idx, 1, models);
        changed = true;
      } else if (status === 'picked') {
        let model = models[idx];
        model = {
          ...model,
          picked: true,
        };
        models = [...R.slice(0, idx, models), model, ...R.slice(idx, models)];
        changed = true;
      }
    }

    if (changed) {
      return { ...state, projectSettings: { ...projectSettings, models } };
    }
    return state;
  } else if (action.type === 'SET_SETTINGS_API_PARAMS') {
    const { name, params } = action.payload;
    return {
      ...state,
      currentApisParams: {
        ...state.currentApisParams,
        [name]: params,
      },
    };
  } else if (action.type === ActionTypes.SET_PROJECT_PATTERN_SETTING) {
    const patternSettings = action.payload.data || [];
    return {
      ...state,
      patternSettings,
    };
  } else if (action.type === ActionTypes.SET_PROJECT_KEYWORDS_SETTING) {
    const keywordsList = action.payload.data || [];

    // not process whitelist to support AND

    // R.forEach((item) => {
    //   item.systemTag = item.systemTag || '';
    //   if (item.type === 'fieldName' && (item.keyword || '').includes('=')) {
    //     item.jsonKey = R.slice(0, R.indexOf('=', item.keyword || ''), item.keyword || '');
    //     item.keyword = R.slice(R.indexOf('=', item.keyword || '') + 1, Infinity, item.keyword || '');
    //   } else if (item.type === 'fieldName' && !(item.keyword || '').includes('=')) {
    //     item.jsonKey = item.keyword || '';
    //     item.keyword = '';
    //   }
    // }, keywordsList.whitelist || []);
    keywordsList.whitelist = R.addIndex(R.map)((item, idx) => ({ ...item, order: idx + 1 }), keywordsList.whitelist);
    keywordsList.whitelist = R.sortWith([R.ascend(R.prop('order'))])(keywordsList.whitelist);

    R.forEach((item) => {
      if (item.type === 'fieldName' && (item.keyword || '').includes('=')) {
        item.jsonKey = R.slice(0, R.indexOf('=', item.keyword || ''), item.keyword || '');
        item.keyword = R.slice(R.indexOf('=', item.keyword || '') + 1, Infinity, item.keyword || '');
      } else if (item.type === 'fieldName' && !(item.keyword || '').includes('=')) {
        item.jsonKey = item.keyword || '';
        item.keyword = '';
      }
    }, keywordsList.trainingWhitelist || []);
    R.forEach((item) => {
      if (item.type === 'fieldName' && (item.keyword || '').includes('=')) {
        item.jsonKey = R.slice(0, R.indexOf('=', item.keyword || ''), item.keyword || '');
        item.keyword = R.slice(R.indexOf('=', item.keyword || '') + 1, Infinity, item.keyword || '');
      } else if (item.type === 'fieldName' && !(item.keyword || '').includes('=')) {
        item.jsonKey = item.keyword || '';
        item.keyword = '';
      }
    }, keywordsList.instanceNameLabels || []);
    R.forEach((item) => {
      if (item.type === 'fieldName' && (item.keyword || '').includes('=')) {
        item.jsonKey = R.slice(0, R.indexOf('=', item.keyword || ''), item.keyword || '');
        item.keyword = R.slice(R.indexOf('=', item.keyword || '') + 1, Infinity, item.keyword || '');
      } else if (item.type === 'fieldName' && !(item.keyword || '').includes('=')) {
        item.jsonKey = item.keyword || '';
        item.keyword = '';
      }
    }, keywordsList.trainingBlacklistLabels || []);
    keywordsList.trainingBlacklist = keywordsList.trainingBlacklistLabels || [];
    R.forEach((item) => {
      if (item.type === 'fieldName' && (item.keyword || '').includes('=')) {
        item.jsonKey = R.slice(0, R.indexOf('=', item.keyword || ''), item.keyword || '');
        item.keyword = R.slice(R.indexOf('=', item.keyword || '') + 1, Infinity, item.keyword || '');
      } else if (item.type === 'fieldName' && !(item.keyword || '').includes('=')) {
        item.jsonKey = item.keyword || '';
        item.keyword = '';
      }
    }, keywordsList.extractionBlacklist || []);
    R.forEach((item) => {
      if (item.type === 'fieldName' && (item.keyword || '').includes('=')) {
        item.jsonKey = R.slice(0, R.indexOf('=', item.keyword || ''), item.keyword || '');
        item.keyword = R.slice(R.indexOf('=', item.keyword || '') + 1, Infinity, item.keyword || '');
      } else if (item.type === 'fieldName' && !(item.keyword || '').includes('=')) {
        item.jsonKey = item.keyword || '';
        item.keyword = '';
      }
    }, keywordsList.triagelist || []);
    R.forEach((item) => {
      if (item.type === 'fieldName' && (item.keyword || '').includes('=')) {
        item.jsonKey = R.slice(0, R.indexOf('=', item.keyword || ''), item.keyword || '');
        item.keyword = R.slice(R.indexOf('=', item.keyword || '') + 1, Infinity, item.keyword || '');
      } else if (item.type === 'fieldName' && !(item.keyword || '').includes('=')) {
        item.jsonKey = item.keyword || '';
        item.keyword = '';
      }
    }, keywordsList.featurelist || []);
    R.forEach((item) => {
      if (item.type === 'fieldName' && (item.keyword || '').includes('=')) {
        item.jsonKey = R.slice(0, R.indexOf('=', item.keyword || ''), item.keyword || '');
        item.keyword = R.slice(R.indexOf('=', item.keyword || '') + 1, Infinity, item.keyword || '');
      } else if (item.type === 'fieldName' && !(item.keyword || '').includes('=')) {
        item.jsonKey = item.keyword || '';
        item.keyword = '';
      }
    }, keywordsList.anomalyFeatureLabels || []);
    keywordsList.anomalyFeature = keywordsList.anomalyFeatureLabels || [];
    keywordsList.dataFilterLabels = R.sortWith([R.ascend(R.prop('keyword'))])(keywordsList.dataFilterLabels);
    R.forEach((item) => {
      if (item.type === 'fieldName' && (item.keyword || '').includes('=')) {
        item.jsonKey = R.slice(0, R.indexOf('=', item.keyword || ''), item.keyword || '');
        item.keyword = R.slice(R.indexOf('=', item.keyword || '') + 1, Infinity, item.keyword || '');
      } else if (item.type === 'fieldName' && !(item.keyword || '').includes('=')) {
        item.jsonKey = item.keyword || '';
        item.keyword = '';
      }
    }, keywordsList.dataFilterLabels || []);
    keywordsList.incidentlist = R.sortWith([R.ascend(R.prop('order'))])(keywordsList.incidentlist);

    return {
      ...state,
      keywordsList,
    };
  } else if (action.type === ActionTypes.SET_PROJECT_FILTERS_SETTING) {
    const filtersList = action.payload.data || [];
    return {
      ...state,
      filtersList,
    };
  } else if (action.type === ActionTypes.SET_PROJECT_DETECTION_STATUS) {
    const { detectionStatus, projectDetectionStatus, pageInfo } = action.payload.data || {};
    return {
      ...state,
      pageInfo,
      detectionStatus,
      projectDetectionStatus,
    };
  } else if (action.type === ActionTypes.SET_PROJECT_ACTIONS) {
    const actionsList = action.payload.data || [];
    return {
      ...state,
      actionsList,
    };
  } else if (action.type === ActionTypes.SET_PROJECT_MODELS) {
    const { models, holidaymodels } = action.payload.data || {};
    const newState = state;
    if (models) {
      newState.models = models;
    }
    if (holidaymodels) {
      newState.holidaymodels = holidaymodels;
    }
    return newState;
  } else if (action.type === ActionTypes.SET_ACTIVE_INSTANCE_ACTIONS) {
    const activeInstanceMap = action.payload || [];
    return {
      ...state,
      activeInstanceMap,
    };
  }
  return { ...initialState, ...state };
};

export default reducer;
