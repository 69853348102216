/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
// import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { RightOutlined } from '@ant-design/icons';
import { Form, Button, Alert, Input, Select as AntSelect, DatePicker, Checkbox, Card } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';

import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  credentials: Object,
  componentState: Object,
};

class SumoLogicSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;
    this.state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      deployment: undefined,
      accessId: '',
      accessKey: '',
      query: '',

      // default fields
      historicalDates: [null, null],
      projectModelFlag: false,
      processPauseFlag: false,

      ...(componentState || {}),
    };
    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
    this.additonalFieldsOptions = [];
  }

  componentDidMount() {
    this.props.saveProjectInfo('SumoLogic', {}, this.state);
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { intl, credentials } = this.props;
    const { deployment, accessId, accessKey, query } = this.state;
    const projectCreationType = 'SumoLogic';
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType,
      deployment,
      accessId,
      accessKey,
      query,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
            },
            () => {
              this.props.saveProjectInfo('SumoLogic', {}, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('SumoLogic', {}, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
          },
          () => {
            this.props.saveProjectInfo('SumoLogic', {}, this.state);
          },
        );
      });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { deployment, accessId, accessKey, query, historicalDates, projectModelFlag, processPauseFlag } = this.state;
    this.props.createProject(
      'SumoLogic',
      {
        operation: 'register',
        deployment,
        accessId,
        accessKey,
        query,
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
        projectModelFlag,
        processPauseFlag,
      },
      this.state,
    );
  }

  render() {
    const { intl, isLoading, hasError } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,

      deployment,
      accessId,
      accessKey,
      query,

      historicalDates,
      projectModelFlag,
      processPauseFlag,
    } = this.state;

    const hasVerifyError = !deployment || !accessId || !accessKey || !query;
    const hasErrorRegister = hasVerifyError || hasError;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.SumoLogicIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item
              label="Deployment"
              validateStatus={!deployment ? 'error' : 'success'}
              help={!deployment ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <AntSelect
                showSearch
                options={R.map(
                  (item) => ({ value: item, label: item }),
                  ['us1', 'us2', 'eu', 'jp', 'de', 'in', 'ca', 'au', 'fed'],
                )}
                value={deployment}
                onChange={(deployment) => this.setState({ deployment })}
              />
            </Form.Item>
            <Form.Item
              label="Access Id"
              validateStatus={!accessId ? 'error' : 'success'}
              help={!accessId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={accessId}
                onChange={(e) => this.setState({ accessId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Access key"
              validateStatus={!accessKey ? 'error' : 'success'}
              help={!accessKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={accessKey}
                onChange={(e) => this.setState({ accessKey: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Filter query"
              validateStatus={!query ? 'error' : 'success'}
              help={!query ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input
                placeholder="_sourceCategory=windows/events"
                value={query}
                onChange={(e) => this.setState({ query: e.target.value })}
              />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>
        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            <Form.Item label="Historical Date Range">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={historicalDates}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(historicalDates) => this.setState({ historicalDates })}
              />
              <div className="flex-row" style={{ marginTop: 16 }}>
                <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                  Enable holistic model
                </div>
                <Checkbox
                  checked={projectModelFlag}
                  onChange={(e) => {
                    this.setState({ projectModelFlag: e.target.checked });
                  }}
                />
              </div>
              <div className="flex-row" style={{ marginTop: 16 }}>
                <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                  Enable initial processing pause
                </div>
                <Checkbox
                  checked={processPauseFlag}
                  onChange={(e) => {
                    this.setState({ processPauseFlag: e.target.checked });
                  }}
                />
              </div>
            </Form.Item>
          </Form>
        </Card>
        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasErrorRegister}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const SumoLogicSetting = injectIntl(SumoLogicSettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(SumoLogicSetting);
