import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { Spin, message } from 'antd';

import getEndpoint from '../../../common/apis/getEndpoint';
import fetchPost from '../../../common/apis/fetchPost';

import { eventMessages } from '../../../common/metric/messages';

const getPercentage = (value) => {
  if (value === -1) {
    return value;
  } else {
    return `${(value * 100).toFixed(1)} %`;
  }
};

const NotificationCoverage = (props: Object) => {
  const { systemId, startTime, endTime, systemInfo, credentials, intl, systemIdsWithShare } = props;

  const [loading, setLoading] = useState(false);
  const [notificationCoverage, setNotificationCoverage] = useState(null);

  const fetchData = () => {
    const startTimestamp = moment.utc(startTime).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime).endOf('day').valueOf();
    const filterSystem = R.filter((item) => item.id === systemId, systemIdsWithShare || []);
    setLoading(true);
    fetchPost(getEndpoint('dashboard-summary'), {
      ...credentials,
      systemIdsWithShare: JSON.stringify(filterSystem),
      startTime: startTimestamp,
      endTime: endTimestamp,
      cacheKey: 'NotificationCoverage',
    })
      .then((res) => {
        const { sussess, message: msg } = res || {};
        if (sussess || sussess === undefined) {
          const system = R.find((item) => item.systemKey === systemId, res || []);
          setNotificationCoverage(!system ? null : getPercentage(system.notificationCoverage));
          setLoading(false);
        } else {
          setLoading(false);
          message.error(msg);
        }
      })
      .catch((err) => {
        message.error(err?.message || err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (systemInfo) {
      fetchData(systemInfo);
    }
  }, [systemId]);

  return (
    <Spin spinning={loading} wrapperClassName="overflow-x-hidden overflow-y-auto full-width full-height">
      <div className="font-14 bold flex-row flex-space-between flex-center-align">
        <span className="hidden-line-with-ellipsis">{intl.formatMessage(eventMessages.alertNotificationCoverage)}</span>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginTop: 8,
          gap: 24,
        }}
      >
        <div className="flex-col">
          {R.isNil(notificationCoverage) ? (
            <div style={{ color: 'var(--text-color-secondary)' }}>
              {intl.formatMessage(eventMessages.noDataForThisTimePeriod)}
            </div>
          ) : (
            <div style={{ fontSize: 18 }}>{notificationCoverage === -1 ? 'N/A' : notificationCoverage}</div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default NotificationCoverage;
