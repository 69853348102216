/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

export const ActionTypes = {
  SET_REPORT_LOG: 'SET_REPORT_LOG',
  LOAD_REPORT_METRIC: 'LOAD_REPORT_METRIC',
  SET_REPORT_METRIC: 'SET_REPORT_METRIC',
};
