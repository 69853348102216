import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { AutoSizer, Column, Container, Popover, SortDirection, Table } from '../../../../lib/fui/react';
import { appFieldsMessages } from '../../../../common/app/messages';

export default function PreviewInstance({ intl, event, localInstanceGroupList = [] }: Object) {
  const table = useRef(null);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    sortBy: null,
    sortDirection: null,
    instanceGroupList: R.filter((item) => {
      let regex = null;
      try {
        regex = new RegExp(event.instanceRegex, 'gi');
      } catch (error) {
        // console.log(error)
      }
      if (event && regex && event.instanceRegex) {
        return event && new RegExp(event.instanceRegex, 'gi').test(item.instanceName);
      }
      return false;
    }, localInstanceGroupList),
  });
  const { sortBy, sortDirection, instanceGroupList } = state;

  useEffect(() => {
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        setState({ instanceGroupList: R.sortWith([R.descend(R.prop(sortBy))])(instanceGroupList) });
      } else {
        setState({ instanceGroupList: R.sortWith([R.ascend(R.prop(sortBy))])(instanceGroupList) });
      }
      if (table.current) {
        table.current.forceUpdate();
      }
    }
  }, [sortBy, sortDirection]);

  const sortTable = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div style={{ padding: '0 6px' }}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const highlightRegexMatches = (text, regex) => {
    if (text && regex) {
      return text.replace(
        regex,
        (match) => `<span style="background: var(--virtualized-table-row-finish-bg);">${match}</span>`,
      );
    }
    return text;
  };

  const renderInstanceName = ({ rowData }) => {
    const { instanceRegex } = event;
    const { instanceName } = rowData;
    let regex = null;
    try {
      regex = new RegExp(instanceRegex, 'gi');
    } catch (error) {
      // console.log(error)
    }
    return (
      <Popover
        mouseEnterDelay={0.3}
        placement="right"
        title={null}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxHeight: 300, maxWidth: 350 }}>
            <div style={{ wordBreak: 'break-all' }}>{instanceName}</div>
          </div>
        }
      >
        <div
          className="hidden-line-with-ellipsis inline-block"
          style={{ maxWidth: 'calc(100% - 17px)' }}
          dangerouslySetInnerHTML={{ __html: highlightRegexMatches(instanceName, regex) }}
        />
      </Popover>
    );
  };

  return (
    <Container className="flex-col flex-min-height flex-grow">
      <AutoSizer>
        {({ width, height }) => (
          <Table
            className="with-border"
            width={width}
            height={height}
            headerHeight={40}
            rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
            rowHeight={40}
            rowCount={instanceGroupList.length}
            rowGetter={({ index }) => instanceGroupList[index]}
            ref={(c) => {
              table.current = c;
            }}
            sort={sortTable}
            sortBy={sortBy}
            sortDirection={sortDirection}
          >
            <Column
              width={160}
              flexGrow={1}
              label={intl.formatMessage(appFieldsMessages.instance)}
              dataKey="instanceName"
              className="white-pre"
              headerStyle={{ padding: 0 }}
              headerRenderer={headerRenderer}
              cellRenderer={renderInstanceName}
            />
          </Table>
        )}
      </AutoSizer>
    </Container>
  );
}
