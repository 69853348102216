/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { ActionTypes as AppActionTypes } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';

const loadLogAnalysisLogic = createLogic({
  type: [ActionTypes.LOAD_LOG_ANALYSIS],
  cancelType: AppActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(hideLoading);
    done();
  },
});

export default loadLogAnalysisLogic;
