/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import moment from 'moment';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getJiraProjectsAssigners = (credentials: Credentials, params: Object) => {
  // Post the jira iussue.
  const promiseQuery = [
    fetchGet(getEndpoint('jiraPostEvent', 1), {
      ...credentials,
      ...params,
    }).then((d) => {
      return d;
    }),
  ];

  return Promise.all(promiseQuery).then((results) => {
    const jiraProjectAssigners = results[0] || [];
    return {
      jiraProjectAssigners,
    };
  });
};
export default getJiraProjectsAssigners;
