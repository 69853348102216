import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { UnorderedListOutlined, AppstoreOutlined, OrderedListOutlined } from '@ant-design/icons';
import { message, Layout, Card, Popover, Select, Col, Row, Radio, Tooltip, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import moment from 'moment';
import Papa from 'papaparse';
import { get } from 'lodash';

import { hideAppLoader } from '../../../src/common/app/actions';
import DetectionChart from './DetectionChart';
import { Defaults } from '../../../src/common/app';
import { eventMessages } from '../../../src/common/metric/messages';
import SeparateInstanceModal from './SeparateInstanceModal';

const { Content } = Layout;
const RED_COLOR = '#F2495C';

type Props = {
  intl: Object,
  hideAppLoader: Function,
};

const FileDetailsCore = class extends React.Component {
  props: Props;

  chartHeight = 280;

  constructor(props) {
    super(props);
    this.defaultColor = 'rgba(103, 141, 253, 0.6)';
    this.dangerColor = 'rgba(255,0,0,1)';

    this.state = {
      chartList: [],
      systemOptions: [],

      systemValues: [],
      anomalyGroupMap: {},
      grid: 12,

      activeObject: {},
      showSperateInstanceModal: false,

      dateWindow: [],
    };
  }

  componentDidMount() {
    this.parseData();
  }

  componentWillUnmount() {}

  parseData() {
    const chartData = sessionStorage.getItem('chartData') || '[]';
    const anomalyData = sessionStorage.getItem('anomalyData') || '[]';
    const metricUnits = sessionStorage.getItem('metricUnitMapping') || '[]';
    // 传递 detection
    const dataMap = this.parseCsv(JSON.parse(chartData));
    const anomalyGroupMap = this.groupByMetric(JSON.parse(anomalyData));
    const metricUnitMapping = JSON.parse(metricUnits);
    if (R.isEmpty(chartData)) {
      message.error('data is null');
      return;
    }

    const systemOptions = R.map((item) => ({ label: item, value: item }), R.keys(dataMap));

    const chartList = R.map((item) => {
      const [key, value] = item;

      const anomalyList = get(anomalyGroupMap, [key], []);
      const highlight = this.timeOffset({
        chartData: value,
        anomalyList,
      });
      return { data: value.data, anomalyList, labels: value.labels, title: value.name, highlight };
    }, R.toPairs(dataMap));
    this.setState({ chartList, metricUnitMapping, systemOptions });
    this.props.hideAppLoader();
  }

  parseCsv(csvData) {
    const data = {};
    let labels = null;
    R.forEachObjIndexed((csvData, key) => {
      const paJson = Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        transform: (value) => {
          return Math.round(Number(value));
        },
        transformHeader: (value) => {
          return R.equals(value, 'timestamp') ? value : value.split(':')[0];
        },
      });
      labels = paJson.meta.fields;
      data[key] = {
        data: paJson.data,
        labels,
        name: key,
      };
    }, csvData);
    return data;
  }

  groupByMetric(data) {
    const anomalyObject = [];
    R.forEach((ano) => {
      const { hintSet, ...rest } = ano;
      R.forEach((x) => {
        anomalyObject.push({ ...x, ...rest });
      }, hintSet);
    }, data);
    const byMetric = R.groupBy((item) => item.m);
    const anomalyMap = byMetric(anomalyObject);
    return anomalyMap;
  }

  timeOffset({ chartData, anomalyList: detectionData }) {
    const { data } = chartData;
    const dataLength = data.length;
    const offsetInt = 4;
    const instanceMap = {};

    R.addIndex(R.forEach)((chart, chartIndex) => {
      R.forEach((ano) => {
        if (R.equals(chart.timestamp, ano.timeStamp)) {
          const midNum = parseInt(offsetInt / 2, 10);
          const { m, i, c, p } = ano;
          const instance = `${m}[${i}]`;
          const minOffset = chartIndex - midNum <= 0;
          const maxOffset = chartIndex + midNum >= dataLength;
          const startIndex = minOffset ? 0 : chartIndex - midNum;
          const endIndex = minOffset ? chartIndex + midNum * 2 : maxOffset ? dataLength - 1 : chartIndex + midNum;
          const color = R.equals(c, 'positive') ? (p >= 200 ? RED_COLOR : p > 0 ? 'orange' : '#2185d0') : '#2185d0';

          if (get(instanceMap, [instance, 'timeRange'], false)) {
            instanceMap[instance].color = color;
            instanceMap[instance].timeRange.push({
              startTime: data[startIndex].timestamp,
              endTime: data[endIndex].timestamp,
              color,
            });
          } else {
            instanceMap[instance] = {
              timeRange: [
                {
                  startTime: data[startIndex].timestamp,
                  endTime: data[endIndex].timestamp,
                },
                color,
              ],
            };
          }
        }
      }, detectionData || []);
    }, data);

    return instanceMap;
  }

  @autobind
  onCreateAnnotation(params) {
    const { x, timeStamp, m, i, v, p, c, sm } = params;
    const { metricUnitMapping } = this.state;

    const unit = R.find((x) => {
      const { shortMetric } = x;
      return shortMetric === sm;
    }, metricUnitMapping || []);

    const color = R.equals(c, 'positive') ? (p >= 200 ? RED_COLOR : p > 0 ? 'orange' : '#2185d0') : '#2185d0';

    return (
      <Popover
        placement="right"
        key={m + i + v + timeStamp}
        overlayClassName="dark"
        title={null}
        trigger="hover"
        content={
          <>
            <div style={{ color: 'white' }}>{moment.utc(timeStamp).format(Defaults.TimeFormat)}</div>
            <div style={{ marginBottom: 10 }}>
              <span style={{ color: '#9fa3a5', marginRight: 8 }}>Percent:</span>
              <span style={{ color: c === 'positive' ? '#FF5142' : '#2285d0' }}>{(+p).toFixed(2)}%</span>
            </div>
            <span style={{ color: 'white', marginRight: 8 }}>
              {m}[{i}]:
            </span>
            <span style={{ color: c === 'positive' ? '#FF5142' : '#2285d0' }}>
              {v} {unit && <span>({unit.unit})</span>}
            </span>
          </>
        }
      >
        <div
          style={{
            position: 'absolute',
            left: x,
            top: 0,
            border: `2px solid ${color}`,
            width: 8,
            height: 12,
            transform: 'translateX(-50%)',
          }}
        />
      </Popover>
    );
  }

  @autobind
  handleSeparateInstance(activeObject) {
    this.setState({
      showSperateInstanceModal: true,
      activeObject,
    });
  }

  @autobind
  onClose() {
    this.setState({
      showSperateInstanceModal: false,
    });
  }

  @autobind
  onDateWindowChange(dateWindow) {
    this.setState({ dateWindow });
  }

  @autobind
  renderListItem({ list, grid }) {
    const { intl } = this.props;
    const { dateWindow } = this.state;
    const cardGutter = 15;
    return (
      <Row
        gutter={cardGutter}
        style={{ padding: cardGutter }}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
      >
        {!R.isEmpty(list) &&
          R.map((item) => {
            const { data, anomalyList, labels, highlight, title } = item;

            return (
              <Col
                span={grid}
                key={title}
                style={{ margin: `0 0 ${15}px 0` }}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                <Card
                  style={{
                    height: this.chartHeight,
                    width: '100%',
                    overflow: 'hidden',
                  }}
                  bordered={false}
                  hoverable
                  bodyStyle={{ padding: 10 }}
                >
                  <div
                    className="flex-row full-width flex-center-align flex-space-between"
                    style={{ marginBottom: 20 }}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                  >
                    {title}
                    <Button
                      size="small"
                      type="primary"
                      icon={<OrderedListOutlined />}
                      onClick={() =>
                        this.handleSeparateInstance({
                          data,
                          anomalyList,
                          labels,
                          highlight,
                          title,
                          dateWindow,
                          onClose: this.onClose,
                          onCreateAnnotation: this.onCreateAnnotation,
                          onDateWindowChange: this.onDateWindowChange,
                        })
                      }
                      style={{ marginLeft: 16 }}
                      ghost
                    >
                      {intl.formatMessage(eventMessages.perInstanceChart)}
                    </Button>
                  </div>
                  <DetectionChart
                    data={data}
                    anomalyList={anomalyList}
                    labels={labels}
                    highlight={highlight}
                    width="100%"
                    height={this.chartHeight - 40}
                    onCreateAnnotation={this.onCreateAnnotation}
                    onDateWindowChange={this.onDateWindowChange}
                    dateWindow={dateWindow}
                  />
                </Card>
              </Col>
            );
          }, list)}
      </Row>
    );
  }

  @autobind
  onResize() {
    setTimeout(function () {
      if (document.createEvent) {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, true);
        window.dispatchEvent(event);
      } else if (document.createEventObject) {
        window.fireEvent('onresize');
      }
    }, 0);
  }

  render() {
    const { chartList, systemValues, grid, showSperateInstanceModal, activeObject, systemOptions } = this.state;

    return (
      <Layout style={{ height: '100%' }}>
        <div
          className=" flex-row flex-grow flex-center-align flex-space-between full-width"
          style={{ padding: '0 24px 0 15px' }}
        >
          <div className="flex-row flex-center-align">
            <span>Filter: </span>
            <Select
              style={{ width: 180, marginLeft: 5 }}
              allowClear
              mode="multiple"
              options={systemOptions}
              maxTagCount={1}
              size="small"
              placeholder="Only View"
              value={systemValues}
              onChange={(value) => this.setState({ systemValues: value })}
            />
          </div>
          <div>
            <Radio.Group
              value={grid}
              size="small"
              style={{ marginTop: 20 }}
              onChange={(event) => {
                this.setState({ grid: event.target.value }, () => {
                  this.onResize();
                });
              }}
            >
              <Tooltip title="List View" placement="top">
                <Radio.Button value={24}>
                  <UnorderedListOutlined />
                </Radio.Button>
              </Tooltip>
              <Tooltip title="Grid View" placement="top">
                <Radio.Button value={12}>
                  <AppstoreOutlined />
                </Radio.Button>
              </Tooltip>
            </Radio.Group>
          </div>
        </div>
        <Content className="overflow-x-hidden overflow-y-auto full-height full-width">
          {this.renderListItem({ list: chartList, grid })}
        </Content>

        {showSperateInstanceModal && <SeparateInstanceModal {...activeObject} />}
      </Layout>
    );
  }
};
const FileDetails = injectIntl(FileDetailsCore);

export default connect(
  (state) => {
    const { credentials } = state.auth;
    const { location } = state.router;

    return { credentials, location };
  },
  { hideAppLoader },
)(FileDetails);
