/* @flow */
import { defineMessages } from 'react-intl';

const authMessages = defineMessages({
  privacy: {
    defaultMessage: 'Privacy',
    id: 'auth.privacy',
  },
  terms: {
    defaultMessage: 'Terms',
    id: 'auth.terms',
  },
  privacyPolicy: {
    defaultMessage: 'privacy policy',
    id: 'auth.privacyPolicy',
  },
  termsUse: {
    defaultMessage: 'terms of use',
    id: 'auth.termsUse',
  },
  hintsOr: {
    defaultMessage: 'or',
    id: 'auth.hint.or',
  },
  hintsUserName: {
    defaultMessage: 'User name?',
    id: 'auth.hint.userName',
  },
  hintsNewUser: {
    defaultMessage: 'New user?',
    id: 'auth.hint.newuser',
  },
  hintsForgot: {
    defaultMessage: 'Forgot',
    id: 'auth.hint.hintsForgot',
  },
  hintsPassword: {
    defaultMessage: 'password?',
    id: 'auth.hint.hintsPassword',
  },
  hintsQuestions: {
    defaultMessage: 'Questions?',
    id: 'auth.hint.hintsQuestions',
  },
  contactSupport: {
    defaultMessage: 'Contact support',
    id: 'auth.hint.contactSupport',
  },
  haveAnAccount: {
    defaultMessage: 'Have an account',
    id: 'auth.hint.haveAnAccount',
  },
  readyToGetStartedWithInsightFinder: {
    defaultMessage: 'Ready to get started with InsightFinder',
    id: 'auth.hint.readyToGetStartedWithInsightFinder',
  },
  signUpHere: {
    defaultMessage: 'Sign up here',
    id: 'auth.hint.signUpHere',
  },
  hintsResetPassword: {
    defaultMessage: 'Reset Password',
    id: 'auth.hint.resetPassword',
  },
  hintsGetPassword: {
    defaultMessage: 'Get a temporary reset link',
    id: 'auth.hint.hintsGetPassword',
  },
  hintsGetUsername: {
    defaultMessage: 'Retrieve user name by email',
    id: 'auth.hint.hintsGetUsername',
  },
  hintsGetSignupCode: {
    defaultMessage: 'Get a registration code now',
    id: 'auth.hint.hintsGetSignupCode',
  },
  getStartedForFree: {
    defaultMessage: 'Get started for free',
    id: 'auth.hint.getStartedForFree',
  },
  getSandboxStartedForFree: {
    defaultMessage: 'Play with InsightFinder Sandbox for free now!',
    id: 'auth.hint.getSandboxStartedForFree',
  },
  startedInsightFinderSandbox: {
    defaultMessage: 'Get started with the InsightFinder Sandbox!',
    id: 'auth.hint.startedInsightFinderSandbox',
  },
  startedInsightFinderSandboxToday: {
    defaultMessage: 'Get Started with the InsightFinder Sandbox Today!',
    id: 'auth.hint.startedInsightFinderSandboxToday',
  },
  experienceInsightFinderFirsthand: {
    defaultMessage:
      'Experience InsightFinder firsthand through our comprehensive guided demo. Discover the power to predict incidents and reduce system downtime.',
    id: 'auth.hint.experienceInsightFinderFirsthand',
  },
  ourCloudBasedDemoSandbox: {
    defaultMessage:
      'Our cloud-based demo sandbox lets you explore key features including data integration, incident prediction, root cause analysis, proactive automated remediation, and more!',
    id: 'auth.hint.ourCloudBasedDemoSandbox',
  },
  successEmailTip: {
    defaultMessage: 'Please check your email to verify your account',
    id: 'auth.hint.successEmailTip',
  },
  hintsHaveSignupCode: {
    defaultMessage: 'Already have a registration code?',
    id: 'auth.hint.hintsHaveSignupCode',
  },
  hintsWaitReviewRequest: {
    defaultMessage: 'We will review your request, and will email you the registration code after confirmation.',
    id: 'auth.hint.hintsWaitReviewRequest',
  },
  hintsAgree: {
    defaultMessage: 'I agree to',
    id: 'auth.hint.hintsAgree',
  },
  buttonsNext: {
    defaultMessage: 'Next',
    id: 'auth.buttons.next',
  },
  login: {
    defaultMessage: 'Log in',
    id: 'auth.login',
  },
  register: {
    defaultMessage: 'Register',
    id: 'auth.register',
  },
  buttonsSignIn: {
    defaultMessage: 'Sign In',
    id: 'auth.buttons.signin',
  },
  butOtherSignIn: {
    defaultMessage: 'Sign in with {name}',
    id: 'auth.buttons.butOtherSignIn',
  },
  buttonsSignUp: {
    defaultMessage: 'Sign Up',
    id: 'auth.buttons.signup',
  },
  chooseAnotherLoginMethod: {
    defaultMessage: 'Choose another log-in method',
    id: 'auth.buttons.chooseAnotherLoginMethod',
  },
  errorsUserNameRequired: {
    defaultMessage: 'User name is required',
    id: 'auth.errors.userNameRequired',
  },
  errorsUserNameContain: {
    defaultMessage: 'User name should only contain letters and numbers',
    id: 'auth.errors.errorsUserNameContain',
  },
  errorsIDPNameRequired: {
    defaultMessage: 'IDP name is required',
    id: 'auth.errors.errorsIDPNameRequired',
  },
  errorsClientIdRequired: {
    defaultMessage: 'Client ID is required',
    id: 'auth.errors.errorsClientIdRequired',
  },
  errorsClientSecretRequired: {
    defaultMessage: 'Client secret is required',
    id: 'auth.errors.errorsClientSecretRequired',
  },
  errorsTenantRequired: {
    defaultMessage: 'Tenant is required',
    id: 'auth.errors.errorsTenantRequired',
  },
  errorsDomainNameRequired: {
    defaultMessage: 'Domain name is required',
    id: 'auth.errors.errorsDomainNameRequired',
  },
  errorsServerAddressRequired: {
    defaultMessage: 'Server Address is required',
    id: 'auth.errors.errorsServerAddressRequired',
  },
  errorsEmailRequired: {
    defaultMessage: 'Email is required',
    id: 'auth.errors.errorsEmailRequired',
  },
  errorsEmailIncorrect: {
    defaultMessage: 'Email is incorrect',
    id: 'auth.errors.errorsEmailIncorrect',
  },
  errorsEmailIncorrectPerson: {
    defaultMessage: 'Please do not use your personal mailbox',
    id: 'auth.errors.errorsEmailIncorrectPerson',
  },
  errorsPhoneIncorrect: {
    defaultMessage: 'Phone number is incorrect',
    id: 'auth.errors.errorsPhoneIncorrect',
  },
  errorsPasswordRequired: {
    defaultMessage: 'Password is required',
    id: 'auth.errors.errorsPasswordRequired',
  },
  errorsPasswordContain: {
    defaultMessage: 'Password must not contain user name',
    id: 'auth.errors.errorsPasswordContain',
  },
  errorsPasswordLength: {
    defaultMessage: 'Password must be 8 characters or longer',
    id: 'auth.errors.errorsPasswordLength',
  },
  errorsPasswordNumber: {
    defaultMessage: 'Password must contain at least 1 numeric character',
    id: 'auth.errors.errorsPasswordNumber',
  },
  errorsPasswordLowercase: {
    defaultMessage: 'Password must contain at least 1 lowercase alphabetical character',
    id: 'auth.errors.errorsPasswordLowercase',
  },
  errorsPasswordUppercase: {
    defaultMessage: 'Password must contain at least 1 uppercase alphabetical character',
    id: 'auth.errors.errorsPasswordUppercase',
  },
  errorsPasswordSpecialCharacters: {
    defaultMessage: 'Password must contain at least one special character',
    id: 'auth.errors.errorsPasswordSpecialCharacters',
  },
  errorsTempPasswordRequired: {
    defaultMessage: 'Temp Password is required',
    id: 'auth.errors.tempPasswordRequired',
  },
  errorsPasswordNotMatch: {
    defaultMessage: 'Password not match',
    id: 'auth.errors.passwordNotMatch',
  },
  errorsExpired: {
    defaultMessage:
      'Your trial account has expired. Please contact our sales team (sales@insightfinder.com) to upgrade your account.',
    id: 'auth.errors.errorsExpired',
  },
  errorsLoginFailure: {
    defaultMessage: 'Cannot login, please check the network and try again.',
    id: 'auth.errors.loginFailure',
  },
  errorsWrongCredential: {
    defaultMessage:
      'Sorry, the information entered does not match our records or this account is suspended. Please contact InsightFinder and try again.',
    id: 'auth.errors.wrongCredential',
  },
  errorsUserNotExist: {
    defaultMessage: 'Sorry, this user is not exist. Please contact InsightFinder and try again.',
    id: 'auth.errors.errorsUserNotExist',
  },
  errorsUserIdpAvailable: {
    defaultMessage: 'The chosen IDP is not available.',
    id: 'auth.errors.errorsUserIdpAvailable',
  },
  errorsLockUser: {
    defaultMessage: 'User locked by maximum login times.',
    id: 'auth.errors.lockUser',
  },
  errorsLockAdmin: {
    defaultMessage: 'User locked by maximum login times. Please contact InsightFinder to unlock your admin account.',
    id: 'auth.errors.lockAdmin',
  },
  errorsTokenInvalid: {
    defaultMessage: 'Your session has expired please login again',
    id: 'auth.errors.tokenInvalid',
  },
  signupSuccess: {
    defaultMessage: 'Signup success',
    id: 'auth.signupSuccess',
  },
  signupFail: {
    defaultMessage: 'Signup failed',
    id: 'auth.signupFail',
  },
  backLogin: {
    defaultMessage: 'Back login page',
    id: 'auth.backLogin',
  },
  pleaseLogin: {
    defaultMessage: 'Please login',
    id: 'auth.pleaseLogin',
  },
  errorsNoPrivilege: {
    defaultMessage:
      'Current logged in user doesn’t have the privilege, need project owner or admin account to  finish the action.',
    id: 'auth.errors.noPrivilege',
  },
  expirationDate: {
    defaultMessage: 'Expiration date',
    id: 'auth.expirationDate',
  },
  wrongParameters: {
    defaultMessage: 'Wrong parameters: {params}!',
    id: 'auth.wrongParameters',
  },
});

export default authMessages;
