/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get, round } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { State } from '../../../common/types';
import { parseLocation, getLoadStatus, CellRenderers } from '../../../common/utils';
import { Container } from '../../../lib/fui/react';
import { createLoadAction } from '../../../common/app/actions';
import { EventTimelineBar } from '../../share';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  width: Number,
  height: Number,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  project: Object,
  eventListMap: Object,
  specialEvents: Boolean,
  timeLineEvents: Array<Object>,
  appNameMapping: Object,
  statusFilter: String,
  statusFilterAll: String,
  onlyShowPredicted: Boolean,
  scoreFilter: String,
  logTypeFilter: String,
  onItemSelected: Function,
  instanceFilter: String,
  metricFilter: String,
  showNumberTooltip: Boolean,
  expandEvents: Boolean,
  startTime: Number,
  endTime: Number,
  showHasPredictionSourceInfo: Boolean,
};

class EventTimeLineContentCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl } = props;
    this.dataLoader = 'events_loader';
    this.appNameMapping = {};

    this.timeLinesLabelMap = {
      log: intl.formatMessage(eventMessages.logAnomaly),
      metric: intl.formatMessage(eventMessages.metricAnomaly),
    };
    this.timeLinesMap = {
      eventTimelines: [],
      startTimestamp: null,
      currentTimestamp: null,
      endTimestamp: null,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { specialEvents } = nextProps;
    if (!specialEvents) {
      if (
        this.props.location !== nextProps.location ||
        this.props.eventListMap !== nextProps.eventListMap ||
        this.props.onlyShowPredicted !== nextProps.onlyShowPredicted ||
        this.props.statusFilter !== nextProps.statusFilter ||
        this.props.statusFilterAll !== nextProps.statusFilterAll ||
        this.props.scoreFilter !== nextProps.scoreFilter ||
        this.props.logTypeFilter !== nextProps.logTypeFilter ||
        this.props.instanceFilter !== nextProps.instanceFilter ||
        this.props.metricFilter !== nextProps.metricFilter
      ) {
        this.reloadData(nextProps);
      }
    }
  }

  @autobind
  handleEventFilter(props, elist) {
    const {
      onlyShowPredicted,
      scoreFilter,
      statusFilter,
      statusFilterAll,
      logTypeFilter,
      instanceFilter,
      metricFilter,
      project,
    } = props;
    const isLog = get(project, 'isLog', false);
    const allRatios = R.sort(
      (a, b) => b - a,
      R.map((e) => round(e.anomalyRatio, 2), elist),
    );
    let top30Ratio = allRatios[Math.floor(allRatios.length * 0.3)];
    const top70Ratio = allRatios[Math.floor(allRatios.length * 0.7)];
    if (top30Ratio === top70Ratio) {
      top30Ratio = allRatios[0];
    }

    let eventList = elist;
    // filter detect or predict
    if (onlyShowPredicted) {
      eventList = R.filter((event) => event.isPrediction, eventList);
    }

    // filter by scoreFilter
    eventList = R.filter((event) => {
      if (scoreFilter === 'high') {
        return event.isEmailSent || round(event.anomalyRatio, 2) >= top30Ratio;
      } else if (scoreFilter === 'middle') {
        return event.isEmailSent || round(event.anomalyRatio, 2) >= top70Ratio;
      }
      return true;
    }, eventList);

    // filter by statusFilter
    eventList = R.filter((event) => {
      if (statusFilter === 'noIgnore') {
        return event.ignoreFlag !== true;
      }
      return true;
    }, eventList);
    // filter by statusFilterAll
    eventList = R.filter((event) => {
      if (statusFilterAll === 'historicalPredictions') {
        return event.isPrediction === true && event.fromStorage;
      } else if (statusFilterAll === 'onlyPredicted') {
        return event.isPrediction === true && !event.fromStorage;
      } else if (statusFilterAll === 'onlyDetected') {
        return event.isPrediction !== true;
      }
      return !event.fromStorage;
    }, eventList);

    // filter by log type
    if (isLog && logTypeFilter && logTypeFilter !== 'all') {
      eventList = R.filter((event) => event.type === logTypeFilter, eventList);
    }

    // filter by metricFilter
    if (metricFilter) {
      eventList = R.filter((event) => {
        const mList = R.map(
          (data) => get(data, 'rootCauseMetric'),
          get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []),
        );
        return mList.indexOf(metricFilter) >= 0;
      }, eventList);
    }

    // filter by instanceFilter
    if (instanceFilter) {
      if (isLog) {
        eventList = R.filter((event) => {
          const instanceName = this.getLogInstance(event, project);
          return instanceName === instanceFilter;
        }, eventList);
      } else {
        eventList = R.filter((event) => {
          const iList = R.map(
            (data) => get(data, 'instanceId'),
            get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []),
          );
          return iList.indexOf(instanceFilter) >= 0;
        }, eventList);
      }
    }

    return eventList;
  }

  @autobind
  reloadData(props) {
    const { location, eventListMap, specialEvents, timeLineEvents, appNameMapping, expandEvents, startTime, endTime } =
      props;
    const params = parseLocation(location);
    const { view, selectTime, interval } = params;

    let allEventList = [];
    let eventList = [];
    if (specialEvents) {
      eventList = timeLineEvents;
      this.appNameMapping = appNameMapping;
    } else {
      eventList = get(eventListMap, [view, 'data', 'eventList'], []);
      this.appNameMapping = get(eventListMap, [view, 'data', 'appNameMapping'], {});
    }

    // use all events
    if (expandEvents) {
      R.forEach((e) => {
        const { timeLineEvents } = e;
        R.forEach((te) => {
          allEventList.push(te);
        }, timeLineEvents || []);
      }, eventList);
    } else {
      allEventList = eventList;
    }

    allEventList = this.handleEventFilter(props, allEventList);

    const currentTimestamp = moment.utc().valueOf();
    let startTimestamp = null;
    let endTimestamp = null;
    if (selectTime && interval) {
      startTimestamp = Number(selectTime) - Number(interval);
      endTimestamp = Number(selectTime) + Number(interval) - 1;
    } else if (startTime && endTime) {
      startTimestamp = startTime;
      endTimestamp = endTime;
    }

    const eventTimelines = R.map((event) => {
      const { startTimestamp, type, duration, anomalyScoreList, neuronId, isPrediction, predictionSourceInfo } = event;
      const durationTime = (duration || 0) * 60 * 1000;
      return {
        startTimestamp,
        endTimestamp: startTimestamp + durationTime,
        duration: durationTime,
        score: event.anomalyRatio,
        type,
        anomalyScoreList,
        tooltipItem: event.patternId,
        neuronId,
        isPrediction,
        predictionSourceInfo,
      };
    }, allEventList);

    this.timeLinesMap = {
      eventTimelines,
      startTimestamp,
      currentTimestamp,
      endTimestamp,
    };
  }

  @autobind
  getLogInstance(event, project) {
    let instanceName = get(event, ['instanceName'], '');
    const instanceInfoList = get(project, ['instanceInfoList'], []);
    const info = R.find((instanceInfo) => instanceInfo.id === instanceName, instanceInfoList);
    if (info) {
      instanceName = info.label;
    }
    return instanceName;
  }

  @autobind
  onBarItemClick(ownEvents) {
    if (ownEvents && ownEvents.length > 0) {
      this.props.onItemSelected(ownEvents);
    }
  }

  @autobind
  onBarItemTooltipRender(item) {
    const { intl } = this.props;
    const { startTimestamp, ownEvents, metricList } = item;
    return (
      <div className="flex-col">
        <div className="flex-row">
          <div style={{ paddingRight: 8 }}>{`${intl.formatMessage(eventMessages.startTime)}:`}</div>
          <div>{CellRenderers.time({ cellData: startTimestamp })}</div>
        </div>
        {ownEvents && ownEvents.length > 0 && (
          <div className="flex-row">
            <div style={{ paddingRight: 8 }}>{`${intl.formatMessage(eventMessages.patternId)}:`}</div>
            <div>
              {R.join(
                ',',
                R.map(
                  (e) => e.neuronId,
                  R.sort((a, b) => a.neuronId - b.neuronId, ownEvents),
                ),
              )}
            </div>
          </div>
        )}
        {metricList && metricList.length > 0 && (
          <div className="flex-row">
            <div style={{ paddingRight: 8 }}>{`${intl.formatMessage(eventMessages.metric)}:`}</div>
            <div>{R.join(',', metricList)}</div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { width, height } = this.props;
    const { intl, loadStatus, project, location, showNumberTooltip, showHasPredictionSourceInfo } = this.props;
    const isLog = get(project, 'isLog', false);
    const lineType = isLog ? 'log' : 'metric';
    const params = parseLocation(location);
    const { interval } = params;

    const { eventTimelines, startTimestamp, currentTimestamp, endTimestamp } = this.timeLinesMap;
    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);

    return (
      <Container style={{ width, height }} className={`flex-col ${isLoading && !errorMessage ? ' loading' : ''}`}>
        <div
          className="flex-row"
          style={{ fontSize: 12, fontWeight: 'bold', height: 20, justifyContent: 'space-between' }}
        >
          <div>{get(this.timeLinesLabelMap, lineType, 'Anomaly')}</div>
          <div>{`${intl.formatMessage(eventMessages.totalLabel)}: ${eventTimelines.length}`}</div>
        </div>
        <div
          className="flex-grow flex-row"
          style={{
            flex: 1,
            textAlign: 'left',
            alignItems: 'center',
          }}
        >
          <EventTimelineBar
            width={width}
            height={height - 20}
            project={project}
            interval={Number(interval)}
            eventTimelines={eventTimelines}
            startTimestamp={startTimestamp}
            currentTimestamp={currentTimestamp}
            endTimestamp={endTimestamp}
            onBarItemClick={this.onBarItemClick}
            onBarItemTooltipRender={this.onBarItemTooltipRender}
            showNumberTooltip={showNumberTooltip}
            showHasPredictionSourceInfo={showHasPredictionSourceInfo}
          />
        </div>
      </Container>
    );
  }
}

const EventTimeLineContent = injectIntl(EventTimeLineContentCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { eventListMap } = state.metric;

    return { location, loadStatus, eventListMap };
  },
  { createLoadAction },
)(EventTimeLineContent);
