import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { get, isNumber, isString } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, DatePicker, Spin, Tabs, message } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchDelete from '../../../../common/apis/fetchDelete';
import getEndpoint from '../../../../common/apis/getEndpoint';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Popover,
  SortDirection,
  Table,
} from '../../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../../common/app/actions';
import { Defaults, parseJSON, parseLocation } from '../../../../common/utils';

import ModelTileList from './ModelTileList';
import { ModelDeleteRange, ModelDetailsModal } from './MetricModelSetting';

import { settingsMessages } from '../../../../common/settings/messages';
import { eventMessages } from '../../../../common/metric/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

const defaultLogInstanceGroup = 'rawLog';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,
  projectName: String,
  credentials: Object,
  userInfo: Object,
  data: Object,
  models: Array<Object>,
  holidaymodels: Array<Object>,
  refreshTime: Number,
  currentProject: Object,
  projects: Array<Object>,
  updateLastActionInfo: Function,
};

class LogModelSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { currentProject } = props;

    const defaultModelDays = 2;

    let timezoneOffset = 0;
    if (currentProject?.timezone) {
      const zone = momenttz.tz(currentProject?.timezone);
      timezoneOffset = zone.utcOffset();
    }
    const now = moment.utc(moment.utc().valueOf() + (timezoneOffset || 0) * 60000).endOf('day');
    const startTime = now.clone().subtract(defaultModelDays, 'days').startOf('day');
    const year = now.clone().startOf('year');

    this.defaultModelDays = defaultModelDays;

    this.state = {
      isLoading: false,
      activeTab: 'regularModelList',
      instanceList: [],
      activeRegularInstance: undefined,
      activeHolidayInstance: undefined,
      startTime,
      endTime: now,
      year,
      refreshRegularTime: +new Date(),
      refreshHolidayTime: +new Date(),
      showModelDeleteModal: false,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const params = parseLocation(this.props.location);
    const nextParams = parseLocation(nextProps.location);
    if (
      this.props.projectName !== nextProps.projectName ||
      this.props.refreshTime !== nextProps.refreshTime ||
      params.reloadProject !== nextParams.reloadProject
    ) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  async reloadData(props) {
    this.setState({ isLoading: true });

    const { instanceList } = await this.getSomeDataList(props);

    this.setState({
      isLoading: false,
      instanceList,
      activeRegularInstance: instanceList[0]?.instanceName,
      activeHolidayInstance: instanceList[0]?.instanceName,
    });
  }

  @autobind
  getSomeDataList(props) {
    const { credentials, projectName, updateLastActionInfo } = props;

    const request = [
      fetchGet(getEndpoint('projects/component', 1), {
        ...credentials,
        projectName,
      }),
    ];

    updateLastActionInfo();

    let instanceList = [];
    return Promise.all(request)
      .then((data) => {
        const [res1] = data || [];

        instanceList = res1;
        instanceList = R.uniqBy((item) => item.instanceName, instanceList || []);
        instanceList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('instanceName')))], instanceList);

        return { instanceList };
      })
      .catch((err) => {
        message.error(err.message || String(err));
        return { instanceList };
      });
  }

  @autobind
  changeActiveInstance(instanceName, isHoliday) {
    if (isHoliday) {
      this.setState({ activeHolidayInstance: instanceName });
    } else {
      this.setState({ activeRegularInstance: instanceName });
    }
  }

  @autobind
  handleDateRangeChange(dates) {
    const [startTime, endTime] = dates;
    this.setState({ startTime: startTime.startOf('day'), endTime: endTime.endOf('day') });
  }

  @autobind
  handleYearChange(year) {
    this.setState({ year: year.startOf('year') });
  }

  @autobind
  showDeleteConfirm() {
    this.setState({ showModelDeleteModal: true });
  }

  render() {
    const { intl, userInfo, updateLastActionInfo, credentials, projectName } = this.props;
    const { activeTab, isLoading, instanceList, activeRegularInstance, activeHolidayInstance } = this.state;
    const { startTime, endTime, year, refreshRegularTime, refreshHolidayTime, showModelDeleteModal } = this.state;

    return (
      <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
        <Tabs
          type="card"
          className="full-height ant-tabs-content-full-height flex-col"
          activeKey={activeTab}
          onChange={(activeTab) => this.setState({ activeTab })}
        >
          <Tabs.TabPane
            tab={intl.formatMessage(settingsMessages.regularModelList)}
            key="regularModelList"
            className="full-height"
          >
            {instanceList.length === 0 && (
              <Container fullHeight style={{ width: '100%', fontSize: 12 }}>
                <div
                  className="ui error message"
                  dangerouslySetInnerHTML={{ __html: `There is no model found for ${projectName}` }}
                />
              </Container>
            )}
            {instanceList.length > 0 && (
              <div className="full-height flex-row" style={{ paddingTop: 8 }}>
                <InstanceListRender
                  {...this.props}
                  instanceList={instanceList}
                  activeInstance={activeRegularInstance}
                  changeActiveInstance={this.changeActiveInstance}
                />
                <div className="flex-grow flex-col" style={{ marginLeft: 8 }}>
                  <div className="flex-row flex-center-align">
                    <span className="label" style={{ marginRight: 8 }}>
                      {intl.formatMessage(appFieldsMessages.dateRange)}:
                    </span>
                    <DatePicker.RangePicker
                      allowClear={false}
                      size="small"
                      disabledDate={(current) => {
                        return current && current > moment.utc().add(1, 'days').endOf('day');
                      }}
                      value={[startTime, endTime]}
                      onChange={this.handleDateRangeChange}
                    />
                    <Button
                      size="small"
                      type="primary"
                      style={{ marginLeft: 8 }}
                      onClick={() => this.setState({ refreshRegularTime: +new Date() })}
                    >
                      {intl.formatMessage(appButtonsMessages.refresh)}
                    </Button>
                    {userInfo.isAdmin && (
                      <Button type="danger" style={{ marginLeft: 8 }} size="small" onClick={this.showDeleteConfirm}>
                        {intl.formatMessage(appButtonsMessages.delete)}
                      </Button>
                    )}
                  </div>
                  {!isLoading && (
                    <SplitModal
                      {...this.props}
                      refreshModalTime={refreshRegularTime}
                      startTime={startTime}
                      endTime={endTime}
                      activeInstance={activeRegularInstance}
                    />
                  )}
                </div>
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={intl.formatMessage(settingsMessages.holidayModelList)}
            key="holidayModelList"
            className="full-height"
          >
            {instanceList.length === 0 && (
              <Container fullHeight style={{ width: '100%', fontSize: 12 }}>
                <div
                  className="ui error message"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(settingsMessages.errorNoProjectHolidayModel),
                  }}
                />
              </Container>
            )}
            {instanceList.length > 0 && (
              <div className="full-height flex-row" style={{ marginTop: 8 }}>
                <InstanceListRender
                  {...this.props}
                  instanceList={instanceList}
                  activeInstance={activeHolidayInstance}
                  changeActiveInstance={this.changeActiveInstance}
                  isHoliday
                />
                <div className="flex-grow flex-col" style={{ marginLeft: 8 }}>
                  <div className="flex-row flex-center-align">
                    <span className="label" style={{ marginRight: 8, textTransform: 'capitalize' }}>
                      {intl.formatMessage(appFieldsMessages.year)}:
                    </span>
                    <DatePicker
                      allowClear={false}
                      size="small"
                      value={year}
                      picker="year"
                      disabledDate={(current) => {
                        return current && current > moment().endOf('year');
                      }}
                      onChange={this.handleYearChange}
                    />
                    <Button
                      size="small"
                      type="primary"
                      style={{ marginLeft: 8 }}
                      onClick={() => this.setState({ refreshHolidayTime: +new Date() })}
                    >
                      {intl.formatMessage(appButtonsMessages.refresh)}
                    </Button>
                    {userInfo.isAdmin && (
                      <Button type="danger" style={{ marginLeft: 8 }} size="small" onClick={this.showDeleteConfirm}>
                        {intl.formatMessage(appButtonsMessages.delete)}
                      </Button>
                    )}
                  </div>
                  {!isLoading && (
                    <SplitModal
                      {...this.props}
                      refreshModalTime={refreshHolidayTime}
                      year={year}
                      activeInstance={activeHolidayInstance}
                      isHoliday
                    />
                  )}
                </div>
              </div>
            )}
          </Tabs.TabPane>
        </Tabs>

        {showModelDeleteModal && (
          <ModelDeleteRange
            intl={intl}
            updateLastActionInfo={updateLastActionInfo}
            credentials={credentials}
            projectName={projectName}
            instanceGroup={defaultLogInstanceGroup}
            instanceName={activeTab === 'regularModelList' ? activeRegularInstance : activeHolidayInstance}
            onClose={(reload) => {
              let newState = {};
              if (reload) {
                if (activeTab === 'regularModelList') {
                  newState = { refreshRegularTime: +new Date() };
                } else {
                  newState = { refreshHolidayTime: +new Date() };
                }
              }
              this.setState({ showModelDeleteModal: false, ...newState });
            }}
          />
        )}
      </Spin>
    );
  }
}

const LogModelSetting = injectIntl(LogModelSettingCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { models, holidaymodels } = state.settings;
    const { userInfo } = state.auth;

    return { location, loadStatus, userInfo, models, holidaymodels };
  },
  { createLoadAction, updateLastActionInfo },
)(LogModelSetting);

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

const InstanceListRender = (props: Object) => {
  const { intl, instanceList: localInstanceList, activeInstance, changeActiveInstance, isHoliday } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    sortBy: null,
    sortDirection: null,
    instanceList: [],
  });
  const { sortBy, sortDirection, instanceList } = state;
  const dataTable = useRef(null);

  useEffect(() => {
    setState({ instanceList: localInstanceList });
  }, [localInstanceList]);

  useEffect(() => {
    if (sortBy) {
      let newInstanceList = R.sortWith([R.ascend(R.prop(sortBy))])(localInstanceList);
      if (sortDirection === SortDirection.DESC) {
        newInstanceList = R.sortWith([R.descend(R.prop(sortBy))])(newInstanceList);
      }
      setState({ instanceList: newInstanceList });
    }
  }, [sortBy, sortDirection]);

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const contentRender = ({ dataKey, parent, rowIndex, cellData }) => {
    return (
      <CellMeasurer cache={cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <Popover content={cellData} placement="right" mouseEnterDelay={0.3}>
          <div className="hidden-line-with-ellipsis inline-block max-width" style={{ whiteSpace: 'nowrap' }}>
            {cellData}
          </div>
        </Popover>
      </CellMeasurer>
    );
  };

  const handleInstanceClick = (rowData) => {
    changeActiveInstance(rowData?.instanceName, isHoliday);
  };

  return (
    <Container className="full-height" style={{ width: 300 }}>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            className="with-border"
            width={width}
            height={height}
            deferredMeasurementCache={cellMeasureCache}
            headerHeight={40}
            rowHeight={cellMeasureCache.rowHeight}
            rowCount={instanceList.length}
            rowGetter={({ index }) => instanceList[index]}
            ref={(table) => {
              dataTable.current = table;
            }}
            rowClassName={({ index }) => {
              let className = 'clickable';
              className += index >= 0 && index % 2 === 1 ? ' odd-row' : '';
              if (index >= 0) {
                if (instanceList[index].instanceName === activeInstance) {
                  className += ' active';
                }
              }
              return className;
            }}
            onRowClick={({ rowData }) => {
              handleInstanceClick(rowData);
            }}
            sort={sort}
            sortBy={sortBy}
            sortDirection={sortDirection}
          >
            <Column
              width={260}
              className="raw-data"
              flexGrow={1}
              label={intl.formatMessage(eventMessages.instanceName)}
              dataKey="instanceName"
              headerRenderer={headerRenderer}
              cellRenderer={contentRender}
            />
          </Table>
        )}
      </AutoSizer>
    </Container>
  );
};

const SplitModal = (props: Object) => {
  const { updateLastActionInfo, credentials, projectName, intl, userInfo, projects } = props || {};
  const { refreshModalTime, startTime, endTime, activeInstance, year, isHoliday } = props || {};
  const [updateEffect, forceUpdateEffect] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    models: [],
    isLoading: false,
    selectedModelKey: '',
    showModelDetailsModal: false,
  });
  const { models, isLoading, selectedModelKey, showModelDetailsModal } = state;

  useEffect(() => {
    let flag = false;

    const reloadData = () => {
      if (!activeInstance) return;
      setState({ isLoading: true });

      updateLastActionInfo();
      fetchGet(getEndpoint('modelPicking'), {
        ...credentials,
        projectName,
        operation: 'list',
        instanceName: activeInstance,
        instanceGroup: defaultLogInstanceGroup,
        ...(isHoliday
          ? { year: year.format(Defaults.YearFormat) }
          : { modelStartTime: startTime.valueOf(), modelEndTime: endTime.valueOf() }),
      })
        .then((d) => {
          if (flag) return;
          const rawData = d.data;
          const rawModels = get(rawData, 'modelKeys', []);

          let models = R.map((model) => {
            const {
              mapData,
              pickableFlag,
              maxValues,
              metricNameList,
              minValues,
              pickedFlag,
              metricModelStatsOrganizer,
              ...rest
            } = model;

            // build metrics
            const names = (metricNameList || '[]').slice(1, -1).split(',');
            const maxs = JSON.parse((maxValues || '[]').replace(/\bNaN\b/g, 'null'));
            const mins = JSON.parse((minValues || '[]').replace(/\bNaN\b/g, 'null'));
            let metrics = [];
            if (metricModelStatsOrganizer) {
              const metricOrganizer = JSON.parse(metricModelStatsOrganizer || '{}');
              const { ms, mm, im } = metricOrganizer;
              R.forEach((item) => {
                const { m, i, ma, mi, g } = item;
                metrics.push({ name: mm[m], instanceName: im[i], max: ma, min: mi, group: g });
              }, ms || []);
            } else {
              metrics = R.addIndex(R.map)((val, idx) => {
                return {
                  name: val,
                  max: isNumber(maxs[idx]) ? maxs[idx].toFixed(2) : NaN,
                  min: isNumber(mins[idx]) ? mins[idx].toFixed(2) : NaN,
                };
              }, names);
            }

            // build heat map
            const heatmap = isString(mapData)
              ? parseJSON(mapData) || []
              : get(mapData[0], 'NASValues', []).map((item) => parseFloat(item.split(',')[1]));

            return {
              ...rest,
              heatmap,
              metrics,
              pickable: Boolean(pickableFlag),
              picked: Boolean(pickedFlag),
              hasMetricOrganizer: Boolean(metricModelStatsOrganizer),
            };
          }, rawModels);
          models = R.sortWith([R.descend(R.prop('endTimestamp'))], models);

          setState({ isLoading: false, models });
        })
        .catch((err) => {
          message.error(err.message || String(err));
          setState({ isLoading: false });
        });
    };

    reloadData();

    return () => {
      flag = true;
    };
  }, [refreshModalTime, activeInstance, updateEffect]);

  const handleModelSelect = (key) => {
    setState({ selectedModelKey: key });
  };

  const handleModelRemove = (modelKey) => {
    const model = R.find((m) => m.modelKey === modelKey, models);
    if (model) {
      const { startTimestamp, endTimestamp } = model;
      const modelKeyObj = { startTimestamp, endTimestamp, modelKey };

      setState({ isLoading: true });
      updateLastActionInfo();
      fetchDelete(
        getEndpoint('modelPicking'),
        {
          ...credentials,
          operation: 'single',
          projectName,
          instanceGroup: defaultLogInstanceGroup,
          instanceName: activeInstance,

          modelKeyObj: JSON.stringify(modelKeyObj),
          startTime: startTimestamp,
          endTime: endTimestamp,
        },
        {},
        false,
      )
        .then((resp) => {
          message.success('Model deletion successful.');
          forceUpdateEffect();
        })
        .catch((err) => {
          message.error('Model deletion failed.');
          setState({ isLoading: false });
        });
    }
  };

  const handleModalDetails = (modelKey) => {
    setState({ selectedModelKey: modelKey, showModelDetailsModal: true });
  };

  const isEmpty = models.length === 0;
  const hasError = isEmpty;

  return (
    <div className="flex-grow" style={{ marginTop: 8 }}>
      <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height model-settings">
        {hasError && (
          <Container fullHeight style={{ width: '100%', fontSize: 12 }}>
            <div
              className="ui error message"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  isHoliday ? settingsMessages.errorNoProjectHolidayModel : settingsMessages.errorNoProjectModel,
                  { projectName: activeInstance },
                ),
              }}
            />
          </Container>
        )}
        {!hasError && (
          <Container className="flex-grow flex-min-width flex-col">
            <div className="flex-row" style={{ marginBottom: 8 }}>
              <div style={{ fontSize: 12 }}>
                <i className="icon circle info" />
                <span>{intl.formatMessage(settingsMessages.modelDesc)}</span>
              </div>
            </div>
            <div className="flex-grow flex-min-height">
              <AutoSizer>
                {({ width, height }) => (
                  <ModelTileList
                    intl={intl}
                    width={width}
                    height={height}
                    modelList={models}
                    projects={projects}
                    credentials={credentials}
                    userInfo={userInfo}
                    projectName={projectName}
                    instanceGroup={defaultLogInstanceGroup}
                    onModelSelect={handleModelSelect}
                    onModelRemove={handleModelRemove}
                    handleModalDetails={handleModalDetails}
                    selectedModelKey={selectedModelKey}
                    showDetailsBut
                  />
                )}
              </AutoSizer>
            </div>
          </Container>
        )}
      </Spin>

      {showModelDetailsModal && (
        <ModelDetailsModal
          {...props}
          models={models}
          selectedModelKey={selectedModelKey}
          onClose={() => setState({ showModelDetailsModal: false })}
        />
      )}
    </div>
  );
};
