import React from 'react';
import KubernetesAverageCount from '../components/KubernetesAverageCount';

export default function AverageContainerCount(props: Object) {
  return (
    <KubernetesAverageCount
      {...props}
      title="Containers"
      subTitle="Containers"
      isContainer
      cacheKey="kubernetesContainer"
    />
  );
}
