/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import { get } from 'lodash';
import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import { buildUrl } from '../utils';
import fetchPost from './fetchPost';

const postProjectAgentList = (credentials: Credentials, params: Object) => {
  const { projectName, agentList } = params;
  const enabledInstances = R.map(
    (a) => ({
      instanceName: a.instanceName,
      instanceIP: a.instanceIP,
      enabled: a.enabled,
    }),
    agentList,
  );

  return fetchPost(buildUrl(getEndpoint('projects/syscall/settings'), {}, { ...credentials, projectName }), {
    enabledInstances: JSON.stringify(enabledInstances),
  }).then((d) => {
    return d;
  });
};

export default postProjectAgentList;
