/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { DeleteOutlined, DownloadOutlined, EditOutlined, FileAddOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, message, Popconfirm, Popover } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import fetchPut from '../../../common/apis/fetchPut';
import fetchPostForm from '../../../common/apis/fetchPostForm';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, parseJSON, downloadFile } from '../../../common/utils';
import { Container } from '../../../lib/fui/react';
import { createLoadAction } from '../../../common/app/actions';

import { appButtonsMessages, appMessages, appFieldsMessages } from '../../../common/app/messages';

import UploadDependencyModal from './UploadDependencyModal';
import ViewDependencyModal from './ViewDependencyModal';
import EditDependencyModal from './EditDependencyModal';

type Props = {
  updateLastActionInfo: Function,
  onReload: Function,
  customerName: String,
  causalGroup: Object,
  refreshTaskTime: String,

  intl: Object,
  credentials: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
};

class CausalDependencyListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      showUploadDependencyModal: false,
      isSubmittingUploadDependency: false,
      errUploadDependency: null,
      showViewDependencyModal: false,
      showEditDependencyModal: false,
      isSubmittingEditDependency: false,
      view: null,
      incident: null,
      metaData: {},

      allChecked: false,
      btnLoading: false,
    };
    this.dependencyList = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.causalGroup !== this.props.causalGroup || nextProps.refreshTaskTime !== this.props.refreshTaskTime) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials, customerName, causalGroup } = props;
    const { incident } = this.state;

    if (customerName && causalGroup) {
      const { causalKey } = causalGroup;
      this.setState({ isLoading: true });
      this.props.updateLastActionInfo();
      fetchGet(getEndpoint('dependencyrelation', 2), {
        ...credentials,
        customerName,
        causalKey,
        // startTime: tasksStartTime,
        // endTime: tasksEndTime,
        format: 'json',
      })
        .then((data) => {
          const { dependencyMap, metaData } = data || {};

          // parse data
          let dependencyList = [];
          R.forEach((item) => {
            const { relationDependencyKey, dependencyData, noRelationDependencyData } = item;
            const relationInfo = parseJSON(relationDependencyKey) || {};
            const relationList = parseJSON(dependencyData) || [];
            const noRelationList = R.filter(
              (_item) => !_item?.s?.intra && !_item?.t?.intra,
              parseJSON(noRelationDependencyData) || [],
            );
            const intraMayNotImapctList = R.filter(
              (_item) => _item?.s?.intra && _item?.t?.intra,
              parseJSON(noRelationDependencyData) || [],
            );
            const { endTime, startTime } = relationInfo;
            const key = `${startTime}-${endTime}`;
            dependencyList.push({
              key,
              startTime,
              endTime,
              relationList,
              noRelationList,
              intraMayNotImapctList,
            });
          }, dependencyMap || []);
          dependencyList = R.sortWith([R.descend(R.prop('startTime'))], dependencyList);

          // update incident info when reload data
          const newIncident = incident ? R.find((item) => item.key === incident.key, dependencyList) : null;

          this.dependencyList = dependencyList;
          this.forceUpdate();
          this.setState({ isLoading: false, incident: newIncident || incident, metaData });
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isLoading: false });
        });
    }
  }

  @autobind
  handleUploadDependencyClick() {
    this.setState({ showUploadDependencyModal: true });
  }

  @autobind
  handleUploadDependencySubmit(dates, relationFileList) {
    const { intl, credentials, customerName, causalGroup, onReload } = this.props;
    const { causalKey } = causalGroup;
    this.setState({ isSubmittingUploadDependency: true });
    const params = {
      ...credentials,
      customerName,
      causalKey,
      startTime: dates[0].startOf('day').valueOf(),
      endTime: dates[1].endOf('day').valueOf(),
      startDate: dates[0].format(Defaults.SmartDateFormat),
      endDate: dates[1].format(Defaults.SmartDateFormat),
      dependencyData: relationFileList[0] || undefined,
    };
    const formData = new FormData();
    R.forEachObjIndexed((data, key) => {
      formData.append(key, data);
    }, params);
    const extraHeader = { userName: credentials.userName };

    this.props.updateLastActionInfo();
    fetchPostForm(getEndpoint('customdependency'), formData, extraHeader)
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({
          isSubmittingUploadDependency: false,
          showUploadDependencyModal: false,
          errUploadDependency: null,
        });
        onReload();
      })
      .catch((err) => {
        // message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmittingUploadDependency: false,
          errUploadDependency:
            'The file format of the uploaded dependency graph is incorrect. ' +
            'The correct format should be “source, target1, target2\n, …”',
        });
      });
  }

  @autobind
  handleAddDependencyClick() {
    this.setState({ showEditDependencyModal: true, view: 'add', incident: null });
  }

  @autobind
  handleAddDependencySubmit({ dependencyDates, relationList, noRelationList, intraMayNotImapctList }) {
    const { intl, credentials, customerName, causalGroup, onReload } = this.props;
    const { causalKey } = causalGroup;
    this.setState({ isSubmittingEditDependency: true });

    let relationSet = [];
    R.forEach((relation) => {
      const { sources, targets } = relation;
      R.forEach((s) => {
        R.forEach((t) => {
          if (s.id && t.id)
            relationSet.push({
              key: `${s.id}-${t.id}`,
              s: { id: s.id, type: s.type },
              t: { id: t.id, type: t.type },
            });
        }, targets);
      }, sources);
    }, relationList);
    relationSet = R.uniqWith(R.eqBy(R.prop('key')), relationSet);
    relationSet = R.map((item) => R.dissoc('key', item), relationSet);

    let noRelationSet = [];
    R.forEach((relation) => {
      const { sources, targets } = relation;
      R.forEach((s) => {
        R.forEach((t) => {
          if (s.id && t.id)
            noRelationSet.push({
              key: `${s.id}-${t.id}`,
              s: { id: s.id, type: s.type },
              t: { id: t.id, type: t.type },
            });
        }, targets);
      }, sources);
    }, noRelationList);
    noRelationSet = R.uniqWith(R.eqBy(R.prop('key')), noRelationSet);
    noRelationSet = R.map((item) => R.dissoc('key', item), noRelationSet);

    let intraMayNotImapctSet = [];
    R.forEach((relation) => {
      const { sources, targets } = relation;
      R.forEach((s) => {
        R.forEach((t) => {
          if (s.id && t.id)
            intraMayNotImapctSet.push({
              key: `${s.id}-${t.id}`,
              s: { id: s.id, type: s.type, intra: true },
              t: { id: t.id, type: t.type, intra: true },
            });
        }, targets);
      }, sources);
    }, intraMayNotImapctList);
    intraMayNotImapctSet = R.uniqWith(R.eqBy(R.prop('key')), intraMayNotImapctSet);
    intraMayNotImapctSet = R.map((item) => R.dissoc('key', item), intraMayNotImapctSet);
    noRelationSet = [...noRelationSet, ...intraMayNotImapctSet];

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('dependencyrelation', 2), {
      ...credentials,
      customerName,
      causalKey,
      startTime: dependencyDates[0].startOf('day').valueOf(),
      endTime: dependencyDates[1].endOf('day').valueOf(),
      relationSet: JSON.stringify(relationSet),
      noRelationSet: JSON.stringify(noRelationSet),
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmittingEditDependency: false, showEditDependencyModal: false });
        onReload();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmittingEditDependency: false });
      });
  }

  @autobind
  handleDownloadDependencyClick(rowData) {
    const { startTime, endTime, relationList } = rowData;
    const fname = `dependency_relations_${moment.utc(startTime).format(Defaults.TimeFormat)}-${moment
      .utc(endTime)
      .format(Defaults.TimeFormat)}.csv`;
    let csvString = 'Source,Target\r\n';
    R.forEach((item) => {
      csvString += `${item.s},${item.t}\r\n`;
    }, R.sortWith([R.ascend(R.prop('s')), R.ascend(R.prop('t'))], relationList));
    downloadFile(csvString, fname);
  }

  @autobind
  handleViewDependencyClick(rowData) {
    this.setState({ showViewDependencyModal: true, incident: rowData });
  }

  @autobind
  handleEditDependencyClick(rowData) {
    this.setState({ showEditDependencyModal: true, view: 'edit', incident: rowData });
  }

  @autobind
  handleEditDependencySubmit({ dependencyDates, incident, relationList, noRelationList, intraMayNotImapctList }) {
    const { intl, credentials, customerName, causalGroup, onReload } = this.props;
    const { causalKey } = causalGroup;
    this.setState({ isSubmittingEditDependency: true });

    const { startTime, endTime, relationList: preRelationList, noRelationList: preNoRelationList } = incident;
    const { intraMayNotImapctList: preIntraMayNotImapctList } = incident;

    // relation
    const relationMap = {};
    R.forEach((relation) => {
      const { sources, targets } = relation;
      R.forEach((s) => {
        R.forEach((t) => {
          if (s.id && t.id)
            relationMap[`${s.id}-${t.id}`] = { s: { id: s.id, type: s.type }, t: { id: t.id, type: t.type } };
        }, targets);
      }, sources);
    }, relationList);

    const preRelationMap = {};
    R.forEach((relation) => {
      const { s, t } = relation;
      preRelationMap[`${s.id}-${t.id}`] = { s: { id: s.id, type: s.type }, t: { id: t.id, type: t.type } };
    }, preRelationList);

    const addRelationSet = [];
    R.forEach((key) => {
      if (!preRelationMap[key]) {
        addRelationSet.push(relationMap[key]);
      }
    }, R.keys(relationMap));

    const removeRelationSet = [];
    R.forEach((key) => {
      if (!relationMap[key]) {
        removeRelationSet.push(preRelationMap[key]);
      }
    }, R.keys(preRelationMap));

    // no realtion
    const noRelationMap = {};
    R.forEach((relation) => {
      const { sources, targets } = relation;
      R.forEach((s) => {
        R.forEach((t) => {
          if (s.id && t.id)
            noRelationMap[`${s.id}-${t.id}`] = { s: { id: s.id, type: s.type }, t: { id: t.id, type: t.type } };
        }, targets);
      }, sources);
    }, noRelationList);

    const preNoRelationMap = {};
    R.forEach((relation) => {
      const { s, t } = relation;
      preNoRelationMap[`${s.id}-${t.id}`] = { s: { id: s.id, type: s.type }, t: { id: t.id, type: t.type } };
    }, preNoRelationList);

    let addNoRelationSet = [];
    R.forEach((key) => {
      if (!preNoRelationMap[key]) {
        addNoRelationSet.push(noRelationMap[key]);
      }
    }, R.keys(noRelationMap));

    let removeNoRelationSet = [];
    R.forEach((key) => {
      if (!noRelationMap[key]) {
        removeNoRelationSet.push(preNoRelationMap[key]);
      }
    }, R.keys(preNoRelationMap));

    // intraMayNotImapct
    const intraMayNotImapctMap = {};
    R.forEach((relation) => {
      const { sources, targets } = relation;
      R.forEach((s) => {
        R.forEach((t) => {
          if (s.id && t.id)
            intraMayNotImapctMap[`${s.id}-${t.id}`] = {
              s: { id: s.id, type: s.type, intra: true },
              t: { id: t.id, type: t.type, intra: true },
            };
        }, targets);
      }, sources);
    }, intraMayNotImapctList);

    const preIntraMayNotImapctMap = {};
    R.forEach((relation) => {
      const { s, t } = relation;
      preIntraMayNotImapctMap[`${s.id}-${t.id}`] = {
        s: { id: s.id, type: s.type, intra: true },
        t: { id: t.id, type: t.type, intra: true },
      };
    }, preIntraMayNotImapctList);

    const addIntraMayNotImapctSet = [];
    R.forEach((key) => {
      if (!preIntraMayNotImapctMap[key]) {
        addIntraMayNotImapctSet.push(intraMayNotImapctMap[key]);
      }
    }, R.keys(intraMayNotImapctMap));

    const removeIntraMayNotImapctSet = [];
    R.forEach((key) => {
      if (!intraMayNotImapctMap[key]) {
        removeIntraMayNotImapctSet.push(preIntraMayNotImapctMap[key]);
      }
    }, R.keys(preIntraMayNotImapctMap));
    addNoRelationSet = [...addNoRelationSet, ...addIntraMayNotImapctSet];
    removeNoRelationSet = [...removeNoRelationSet, ...removeIntraMayNotImapctSet];

    this.props.updateLastActionInfo();
    fetchPut(
      getEndpoint('dependencyrelation', 2),
      {
        ...credentials,
        customerName,
        causalKey,
        startTime,
        endTime,
        changeStartTime: dependencyDates[0].startOf('day').valueOf(),
        changeEndTime: dependencyDates[1].endOf('day').valueOf(),
        addRelationSet: JSON.stringify(addRelationSet),
        removeRelationSet: JSON.stringify(removeRelationSet),
        addNoRelationSet: JSON.stringify(addNoRelationSet),
        removeNoRelationSet: JSON.stringify(removeNoRelationSet),
      },
      {},
      false,
    )
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmittingEditDependency: false });
        onReload();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmittingEditDependency: false });
      });
  }

  @autobind
  handleRemoceDependencyClick(rowData) {
    const { intl, credentials, customerName, causalGroup, onReload } = this.props;
    const { causalKey } = causalGroup;
    const { startTime, endTime } = rowData;

    this.props.updateLastActionInfo();
    fetchDelete(
      getEndpoint('dependencyrelation', 2),
      {
        ...credentials,
        customerName,
        causalKey,
        startTime,
        endTime,
      },
      {},
      false,
    )
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        onReload();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  }

  @autobind
  handleBatchDeleteClick() {
    const { intl, credentials, customerName, causalGroup, onReload } = this.props;
    const { causalKey } = causalGroup;

    const events = R.filter((item) => item.checked, this.dependencyList);
    this.setState({ btnLoading: true });
    this.props.updateLastActionInfo();
    const request = [];
    R.forEach((item) => {
      const { startTime, endTime } = item;
      request.push(
        fetchDelete(
          getEndpoint('dependencyrelation', 2),
          { ...credentials, customerName, causalKey, startTime, endTime },
          {},
          false,
        ),
      );
    }, events);

    Promise.all(request)
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ btnLoading: false });
        onReload();
      })
      .catch((err) => {
        this.setState({ btnLoading: false });
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  }

  @autobind
  renderDependencyRelation(rowData, index) {
    const { intl } = this.props;
    const { allChecked } = this.state;

    const { startTime, endTime, checked } = rowData;
    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 40 }}>
          <Checkbox
            checked={checked}
            onChange={(e) => {
              rowData.checked = e.target.checked;
              const newAllChecked = !R.find((item) => !item.checked, this.dependencyList);
              if (newAllChecked !== allChecked) {
                this.setState({ allChecked: newAllChecked });
              } else {
                this.forceUpdate();
              }
            }}
          />
        </div>
        <div className="row-column" style={{ width: 150 }}>
          {moment.utc(startTime).format(Defaults.TimeFormat)}
        </div>
        <div className="row-column" style={{ width: 150 }}>
          {moment.utc(endTime).format(Defaults.TimeFormat)}
        </div>
        <div className="row-column flex-row flex-end-justify" style={{ width: 200, flex: 1 }}>
          <Button size="small" onClick={() => this.handleViewDependencyClick(rowData)} style={{ marginRight: 8 }}>
            {intl.formatMessage(appButtonsMessages.view)}
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => this.handleEditDependencyClick(rowData)}
            style={{ marginRight: 8 }}
          >
            <EditOutlined /> {intl.formatMessage(appButtonsMessages.edit)}
          </Button>
          <Button size="small" onClick={() => this.handleDownloadDependencyClick(rowData)} style={{ marginRight: 8 }}>
            <DownloadOutlined /> {intl.formatMessage(appButtonsMessages.download)}
          </Button>
          <Popconfirm
            placement="topRight"
            title={
              <div>
                This action will permanently remove this dependency map.
                <br />
                Are you sure you would like to continue?
              </div>
            }
            okText={intl.formatMessage(appButtonsMessages.yes)}
            cancelText={intl.formatMessage(appButtonsMessages.no)}
            onConfirm={() => this.handleRemoceDependencyClick(rowData)}
            onCancel={(event) => event.stopPropagation()}
          >
            <Button size="small" style={{ marginRight: 8 }} onClick={(event) => event.stopPropagation()}>
              <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  }

  render() {
    const { intl, customerName, causalGroup } = this.props;
    const { isLoading, allChecked, btnLoading } = this.state;
    const {
      showUploadDependencyModal,
      isSubmittingUploadDependency,
      errUploadDependency,
      showViewDependencyModal,
      showEditDependencyModal,
      isSubmittingEditDependency,
      view,
      incident,
      metaData,
    } = this.state;

    const hasDelete = !!R.find((item) => item.checked, this.dependencyList);
    return (
      <Container
        className={`${isLoading ? 'loading ' : ''} flex-grow flex-col flex-min-height full-height`}
        style={{ padding: 8 }}
      >
        <div className="flex-row" style={{ marginBottom: 8 }}>
          <Button size="small" type="primary tour-dependency-add" onClick={this.handleAddDependencyClick}>
            <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          <Popover
            title={null}
            content={
              <div className="flex-col">
                <span>The dependency map can be uploaded as a CSV file with a format of</span>
                <code
                  className="flex-col"
                  style={{
                    background: 'aliceblue',
                    padding: 4,
                  }}
                >
                  <span>source1, target11, target12</span>
                  <span>source2, target21, target22</span>
                </code>
                <span>
                  You can also download one of the existing dependency map and edit the downloaded CSV file directly.
                </span>
              </div>
            }
            placement="top"
            mouseEnterDelay={0.3}
          >
            <Button size="small" type="primary" style={{ marginLeft: 8 }} onClick={this.handleUploadDependencyClick}>
              <UploadOutlined /> {intl.formatMessage(appButtonsMessages.upload)}
            </Button>
          </Popover>
          <Button
            size="small"
            type="primary tour-dependency-add"
            disabled={!hasDelete}
            loading={btnLoading}
            onClick={this.handleBatchDeleteClick}
            style={{ marginLeft: 8 }}
          >
            {intl.formatMessage(appButtonsMessages.delete)}
          </Button>
        </div>
        <div className="flex-grow flex-col flex-min-height">
          <div className="event-list flex-grow flex-col flex-min-height">
            <div className="event-list-header" style={{ height: 40, width: '100%' }}>
              <div className="header-column" style={{ width: 40 }}>
                <Checkbox
                  checked={allChecked}
                  onChange={(e) => {
                    R.forEach((item) => {
                      item.checked = e.target.checked;
                    }, this.dependencyList || []);
                    this.setState({ allChecked: e.target.checked });
                  }}
                />
              </div>
              <div className="header-column" style={{ width: 150 }}>
                {intl.formatMessage(appFieldsMessages.startTime)}
              </div>
              <div className="header-column" style={{ width: 150 }}>
                {intl.formatMessage(appFieldsMessages.endTime)}
              </div>
              <div className="header-column" style={{ width: 200, flex: 1 }} />
            </div>
            <div className="event-list-grid flex-grow overflow-y-auto">
              {R.addIndex(R.map)(
                (rowData, index) => this.renderDependencyRelation(rowData, index),
                this.dependencyList,
              )}
            </div>
          </div>
        </div>

        {showUploadDependencyModal && (
          <UploadDependencyModal
            intl={intl}
            onOk={this.handleUploadDependencySubmit}
            onCancel={() => this.setState({ showUploadDependencyModal: false })}
            isSubmitting={isSubmittingUploadDependency}
            errMsg={errUploadDependency}
          />
        )}
        {showViewDependencyModal && (
          <ViewDependencyModal
            intl={intl}
            onCancel={() => this.setState({ showViewDependencyModal: false })}
            customerName={customerName}
            causalGroup={causalGroup}
            incident={incident}
            metaData={metaData}
          />
        )}
        {showEditDependencyModal && (
          <EditDependencyModal
            intl={intl}
            onOk={view === 'add' ? this.handleAddDependencySubmit : this.handleEditDependencySubmit}
            onCancel={() => this.setState({ showEditDependencyModal: false })}
            isSubmitting={isSubmittingEditDependency}
            customerName={customerName}
            causalGroup={causalGroup}
            view={view}
            incident={incident}
            metaData={metaData}
          />
        )}
      </Container>
    );
  }
}

const CausalDependencyList = injectIntl(CausalDependencyListCore);
export default connect(
  (state) => {
    const { loadStatus, projects } = state.app;
    const { credentials } = state.auth;
    return {
      loadStatus,
      projects,
      credentials,
    };
  },
  { createLoadAction },
)(CausalDependencyList);
