/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
// import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import {
  Form,
  Button,
  Alert,
  Input,
  InputNumber,
  Select,
  TreeSelect,
  DatePicker,
  Checkbox,
  Divider,
  Spin,
  Popover,
  Card,
} from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { parseJSON, sleep } from '../../../../common/utils';
import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  credentials: Object,
  componentState: Object,
};

class NewRelicSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;

    const state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      apikey: '',
      dataType: 'Metric',

      // log
      accountOptions: [],
      fieldsMap: {},
      fields: [],
      account: null,
      instanceField: null,

      // metric
      hostMap: {},
      allHostKeys: [],
      allMetricMap: {},
      treeDataMetric: [],
      treeDataKeys: [],
      isLoadingMetric: false,
      selectedInstances: [],
      selectedMetrics: [],

      samplingInterval: 5,
      samplingUnit: 60,
      historicalDates: [null, null],
      projectModelFlag: false,
      processPauseFlag: false,
    };

    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);

    this.state = {
      ...state,

      ...(componentState || {}),
    };
    this.dataTypeOption = [
      { label: 'Metric', value: 'Metric' },
      { label: 'Log', value: 'Log' },
    ];
    this.samplingUnitOption = [
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
  }

  componentDidMount() {
    this.props.saveProjectInfo('NewRelic', { dataType: this.state.dataType }, this.state);
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { intl, credentials } = this.props;
    const { apikey, dataType } = this.state;
    const isMetric = dataType === 'Metric';

    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: isMetric ? 'NewRelic_Metric' : 'NewRelic_Log',
      apikey,
      dataType,
    })
      .then((d) => {
        const { success, message: errMsg, accounts, data } = d || {};
        if (success === undefined || success) {
          if (dataType === 'Metric') {
            // parse metric
            this.parseDataMetric(data);
          } else {
            // parselog
            this.parseDataLog(accounts, data);
          }

          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
              samplingInterval: dataType !== 'Metric' ? 10 : 5,
            },
            () => {
              this.props.saveProjectInfo('NewRelic', { dataType }, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('NewRelic', { dataType }, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
          },
          () => {
            this.props.saveProjectInfo('NewRelic', { dataType }, this.state);
          },
        );
      });
  }

  @autobind
  parseDataMetric(data) {
    const hostMap = {};
    const allHostKeys = [];
    const allMetricMap = {};
    R.forEach((item) => {
      const { hostName, hostId, metrics, accountId } = item;

      allHostKeys.push(hostId);

      const allMetricList = [];
      R.forEach((item) => {
        const { name: metricNameNewRelic, values } = item;
        R.forEach((value) => {
          const key = `${metricNameNewRelic}-${value}-${hostId}`;
          allMetricMap[key] = { metricNameNewRelic, valueToFetch: value, accountId, hostId, hostName };
          allMetricList.push({ metricNameNewRelic, valueToFetch: value, key, accountId, hostId, hostName });
        }, values);
      }, parseJSON(metrics) || []);

      // build host map
      hostMap[hostId] = {
        hostName,
        hostId,
        metrics: allMetricList,
      };
    }, data);
    this.setState({
      hostMap,
      allHostKeys,
      allMetricMap,
    });
  }

  @autobind
  parseDataLog(accounts, data) {
    const accountOptions = R.map((item) => ({ value: item.id, label: item.name }), accounts || []);

    const fieldsMap = {};
    R.addIndex(R.forEach)((item, idx) => {
      const dataObj = (data || [])[idx] || data[0] || {};
      fieldsMap[item.id] = R.keys(dataObj);
    }, accounts || []);

    this.setState({ accountOptions, fieldsMap, fields: [] });
  }

  @autobind
  handleInstanceChange(selectedInstances) {
    this.setState({ selectedInstances, isLoadingMetric: true }, async () => {
      await sleep(300);

      const metricValueMap = {};
      R.forEach((hostId) => {
        const { metrics } = this.state.hostMap[hostId] || {};
        R.forEach((metric) => {
          if (!R.has(metric.metricNameNewRelic, metricValueMap)) {
            metricValueMap[metric.metricNameNewRelic] = {};
          }
          metricValueMap[metric.metricNameNewRelic][metric.key] = metric.valueToFetch;
        }, metrics || []);
      }, selectedInstances || []);

      // build tree data
      let treeData = [];
      const treeDataKeys = [];
      R.forEachObjIndexed((val, metricNameNewRelic) => {
        const children = [];
        R.forEachObjIndexed((value, key) => {
          treeDataKeys.push(key);
          const parseKey = key.split('-');
          const hostId = parseKey[parseKey.length - 1];
          children.push({
            title: `${value}(${this.state.hostMap[hostId].hostName})`,
            value: key,
            key,
          });
        }, val);
        treeData.push({
          title: metricNameNewRelic,
          value: metricNameNewRelic,
          key: metricNameNewRelic,
          children,
        });
      }, metricValueMap);
      treeData = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], treeData);

      // reset selectedMetrics
      const { selectedMetrics } = this.state;
      const newSelectedMetrics = R.intersection(selectedMetrics, treeDataKeys);

      this.setState({
        isLoadingMetric: false,
        treeDataMetric: treeData,
        treeDataKeys,
        selectedMetrics: newSelectedMetrics,
      });
    });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { apikey, dataType } = this.state;
    const { allMetricMap, selectedInstances, selectedMetrics } = this.state;
    const { account, instanceField } = this.state;
    const { samplingInterval, samplingUnit, historicalDates, projectModelFlag, processPauseFlag } = this.state;

    // build metrics params
    const hosts = R.map((item) => item, selectedInstances);
    let metrics = R.map((key) => {
      return allMetricMap[key] ? allMetricMap[key] : null;
    }, selectedMetrics);
    metrics = R.filter((item) => Boolean(item), metrics);

    this.props.createProject(
      'NewRelic',
      {
        apikey,
        operation: 'register',
        dataType,
        ...(dataType === 'Metric'
          ? { hosts: JSON.stringify(hosts), metrics: JSON.stringify(metrics) }
          : { account, instanceField }),

        samplingInterval: Number(samplingInterval) * samplingUnit,
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
        projectModelFlag,
        processPauseFlag,
      },
      this.state,
    );
  }

  @autobind
  handleDataTypeChange(dataType) {
    this.setState(
      {
        dataType,
        verified: false,
      },
      () => {
        this.props.saveProjectInfo('NewRelic', { dataType }, this.state);
      },
    );
  }

  render() {
    const { intl, hasError, isLoading } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,
      apikey,
      dataType,

      accountOptions,
      fields,
      account,
      instanceField,

      hostMap,
      allHostKeys,
      treeDataMetric,
      treeDataKeys,
      isLoadingMetric,
      selectedInstances,
      selectedMetrics,

      samplingInterval,
      samplingUnit,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
    } = this.state;
    const isMetric = dataType === 'Metric';
    const hasVerifyError = !apikey || !dataType;
    let hasErrorRegister = hasVerifyError || !samplingInterval || !samplingUnit || hasError;
    if (isMetric) {
      hasErrorRegister = hasErrorRegister || selectedInstances.length === 0 || selectedMetrics.length === 0;
    } else {
      hasErrorRegister = hasErrorRegister || !account || !instanceField;
    }

    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.PublicNewRelicIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item
              label="Api Key"
              validateStatus={!apikey ? 'error' : 'success'}
              help={!apikey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password value={apikey} onChange={(e) => this.setState({ apikey: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Data type"
              validateStatus={!dataType ? 'error' : 'success'}
              help={!dataType ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select showSearch options={this.dataTypeOption} value={dataType} onChange={this.handleDataTypeChange} />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>
        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            {isMetric && (
              <>
                <Form.Item
                  label="Instances"
                  validateStatus={R.isEmpty(selectedInstances) ? 'error' : 'success'}
                  help={R.isEmpty(selectedInstances) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    style={{ width: '100%' }}
                    allowClear
                    mode="multiple"
                    autoClearSearchValue={false}
                    maxTagCount={1}
                    value={selectedInstances}
                    onChange={this.handleInstanceChange}
                    showSearch
                    optionLabelProp="label"
                    dropdownRender={(menu) => {
                      const selectAll = R.difference(allHostKeys, selectedInstances).length === 0;
                      return (
                        <div>
                          <div
                            className="flex-row"
                            style={{ padding: '5px 12px', background: 'transparent' }}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selectAll}
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (checked) {
                                  this.handleInstanceChange(allHostKeys);
                                } else {
                                  this.handleInstanceChange([]);
                                }
                              }}
                            />
                            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                          </div>
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                  >
                    {R.map((item) => {
                      return (
                        <Select.Option
                          className="hide-icon"
                          key={item.hostId}
                          label={item.hostName}
                          style={{ background: 'transparent' }}
                        >
                          <Checkbox
                            checked={selectedInstances.indexOf(item.hostId) !== -1}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {item.hostName}
                        </Select.Option>
                      );
                    }, R.values(hostMap))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Metrics"
                  validateStatus={R.isEmpty(selectedMetrics) ? 'error' : 'success'}
                  help={R.isEmpty(selectedMetrics) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Spin spinning={isLoadingMetric} wrapperClassName="full-width">
                    <TreeSelect
                      style={{ width: '100%' }}
                      allowClear
                      maxTagCount={1}
                      showSearch
                      autoClearSearchValue={false}
                      treeDefaultExpandAll
                      treeCheckable
                      treeNodeLabelProp="title"
                      treeData={treeDataMetric || []}
                      value={selectedMetrics}
                      onChange={(selectedMetrics) => this.setState({ selectedMetrics })}
                      dropdownRender={(menu) => {
                        const selectAll = R.difference(treeDataKeys, selectedMetrics).length === 0;
                        return (
                          <div>
                            <div
                              className="flex-row"
                              style={{ padding: '5px 24px' }}
                              onMouseDown={(event) => event.preventDefault()}
                            >
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selectAll}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  if (checked) {
                                    this.setState({ selectedMetrics: treeDataKeys });
                                  } else {
                                    this.setState({ selectedMetrics: [] });
                                  }
                                }}
                              />
                              <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                            </div>
                            <Divider style={{ margin: '4px 0' }} />
                            {menu}
                          </div>
                        );
                      }}
                    />
                  </Spin>
                </Form.Item>
              </>
            )}

            {!isMetric && (
              <>
                <Form.Item
                  label="Account"
                  validateStatus={!account ? 'error' : 'success'}
                  help={!account ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    filterOption
                    options={accountOptions}
                    value={account}
                    onChange={(account) => {
                      this.setState((prevState) => ({ account, fields: prevState.fieldsMap[account] || [] }));
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Popover
                      title={null}
                      content={
                        <div style={{ width: 320 }}>
                          This instance in InsightFinder represents the ticket grouping granularity for pattern
                          learning.
                        </div>
                      }
                      placement="right"
                    >
                      <span>
                        InsightFinder Analysis Instance in NewRelic
                        <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
                      </span>
                    </Popover>
                  }
                  validateStatus={!instanceField ? 'error' : 'success'}
                  help={!instanceField ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    filterOption
                    value={instanceField}
                    onChange={(instanceField) => {
                      this.setState({ instanceField });
                    }}
                  >
                    {R.map((field) => {
                      return <Select.Option key={field}>{field}</Select.Option>;
                    }, fields)}
                  </Select>
                </Form.Item>
              </>
            )}

            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.samplingUnitOption}
                  value={samplingUnit}
                  onChange={(samplingUnit) => this.setState({ samplingUnit })}
                />
              </div>
            </Form.Item>
            <Form.Item label="Historical Date Range">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={historicalDates}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(historicalDates) => this.setState({ historicalDates })}
              />
              {!isMetric && (
                <div className="flex-row" style={{ marginTop: 16 }}>
                  <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                    Enable holistic model
                  </div>
                  <Checkbox
                    checked={projectModelFlag}
                    onChange={(e) => {
                      this.setState({ projectModelFlag: e.target.checked });
                    }}
                  />
                </div>
              )}
              {!isMetric && (
                <div className="flex-row" style={{ marginTop: 16 }}>
                  <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                    Enable initial processing pause
                  </div>
                  <Checkbox
                    checked={processPauseFlag}
                    onChange={(e) => {
                      this.setState({ processPauseFlag: e.target.checked });
                    }}
                  />
                </div>
              )}
            </Form.Item>
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasErrorRegister}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const NewRelicSetting = injectIntl(NewRelicSettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(NewRelicSetting);
