import React from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Spin, Popover, Button } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';

import { Defaults, sleep } from '../../../common/utils';
import { AutoSizer, Table, Column } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { appMenusMessages } from '../../../common/app/messages';

type Props = {
  style: Object,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoading: Boolean,
  // eslint-disable-next-line
  isAutoReload: Boolean,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class GlobalPanelHistoricalMaxScoresCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      eventList: [],
    };
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.systemHealth !== this.props.systemHealth) {
      this.parseData(nextProps);
    }
  }

  @autobind
  async parseData(props) {
    const { systemHealth, isAutoReload } = props;

    this.setState({ isLoading: !isAutoReload });
    await sleep(300);

    const { historicalMaxStats } = systemHealth || {};
    this.setState({ isLoading: false, eventList: historicalMaxStats || [] });
  }

  @autobind
  dayRender({ cellData }) {
    if (!cellData) return '';

    return moment.utc(cellData, Defaults.SmartDateFormat).format(Defaults.DateFormat);
  }

  @autobind
  scoreRender({ cellData }) {
    if (!cellData) return '';

    return numeral(cellData).format('0,0.00');
  }

  render() {
    const { isLoading: isLoadingTimelines, intl, style } = this.props;
    const { isLoading, eventList } = this.state;

    return (
      <Popover
        title={null}
        content={
          <div style={{ width: 600, height: 240 }}>
            <Spin wrapperClassName="full-width full-height spin-full-height" spinning={isLoading || isLoadingTimelines}>
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    className="with-border"
                    width={width}
                    height={height}
                    headerHeight={30}
                    rowHeight={40}
                    rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                    rowCount={eventList.length}
                    rowGetter={({ index }) => eventList[index]}
                  >
                    <Column width={120} label="Day" dataKey="day" cellRenderer={this.dayRender} flexGrow={1} />
                    <Column
                      width={100}
                      label="Max metric score"
                      dataKey="metricTotalNinetyNinePercentileVal"
                      cellRenderer={this.scoreRender}
                      flexGrow={1}
                    />
                    <Column
                      width={100}
                      label="Max log score"
                      dataKey="logTotalNinetyNinePercentileVal"
                      cellRenderer={this.scoreRender}
                      flexGrow={1}
                    />
                  </Table>
                )}
              </AutoSizer>
            </Spin>
          </div>
        }
        placement="bottomRight"
        trigger="hover"
      >
        <Button size="small" style={style}>
          <BarChartOutlined />
          {intl.formatMessage(appMenusMessages.historicalMaxScores)}
        </Button>
      </Popover>
    );
  }
}

const GlobalPanelHistoricalMaxScores = injectIntl(GlobalPanelHistoricalMaxScoresCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,
    };
  },
  { updateLastActionInfo },
)(GlobalPanelHistoricalMaxScores);
