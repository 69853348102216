import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { Link, Redirect } from 'react-router-dom';
import VLink from 'valuelink';
import { get, isString } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import { Button, Form, Input as AntdInput, message } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import type { State, Message } from '../../common/types';
import {
  buildLocation,
  parseQueryString,
  buildUrl,
  LoginRenderers,
  sleep,
  parseLocation,
  Regex,
} from '../../common/utils';
import { hideAppLoader, createLoadAction } from '../../common/app/actions';
import {
  userTypeCheck,
  login,
  idpUserLogin,
  loginFailure,
  clearCredentials,
  ActionTypes,
} from '../../common/auth/actions';

import AzureImg from '../../../images/identity-providers/microsoft-azure-icon.svg';
import MSImg from '../../../images/identity-providers/microsoft-icon.svg';
import LDAPSImg from '../../../images/identity-providers/ldaps.svg';

import { appFieldsMessages } from '../../common/app/messages';
import { authMessages } from '../../common/auth/messages';
import { Input, Modal } from '../../lib/fui/react';
import { CenterPage, LocaleSelector } from '../app/components';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import fetchPost from '../../common/apis/fetchPost';

type Props = {
  appLoaderVisible: boolean,
  redirectLoginURL: String,
  redirectURL: String,
  isLoggedIn: boolean,
  isLoggingIn: boolean,
  isChecked: boolean,
  currentTheme: String,
  intl: Object,
  hideAppLoader: Function,
  loginFailure: Function,
  login: Function,
  push: Function,
  replace: Function,
  loginReason: Message,
  redirectURL: String,
  location: Object,
  idpUserLogin: Function,

  clearCredentials: Function,
  credentials: Object,
  createLoadAction: Function,
  loginMessage: String,
};

class LoginCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const loginRedirectURL = window.sessionStorage.getItem('loginRedirectURL');
    window.sessionStorage.removeItem('loginRedirectURL');
    this.state = {
      userName: '',
      password: '',
      isChecking: false,
      loginRedirectURL,
      idpType: 'Insightfinder',
      activeItem: {},
      activeFlag: false,
    };
    this.otherBtnObj = [
      { imgSrc: AzureImg, altStr: 'AzureImg', textName: 'Azure AD', handleKey: 'Azure' },
      { imgSrc: MSImg, altStr: 'MSImg', textName: 'MS AD', handleKey: 'MS' },
      { imgSrc: LDAPSImg, altStr: 'LDAPSImg', textName: 'OpenLDAP', handleKey: 'LDAPS' },
    ];
  }

  componentDidMount() {
    if (!this.props.isLoggedIn || this.props.credentials) {
      this.props.clearCredentials();
      const params = parseLocation(window.location);
      const { state, error } = params;
      if (!error) {
        if (state) {
          this.props.idpUserLogin(state);
        } else {
          const { redirectUrl } = params;
          const { pathname } = window.location;
          const isVirtualLoginPage = (pathname || '').startsWith('/auth/virtual_login');
          this.props.replace(
            buildLocation(
              this.getSandboxEnvironment()
                ? isVirtualLoginPage
                  ? '/auth/virtual_login'
                  : '/auth/sandbox-signup'
                : isVirtualLoginPage
                ? '/auth/virtual_login'
                : '/auth/login2',
              {},
              { redirectUrl },
            ),
          );
        }
      }
    }

    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.redirectURL !== nextProps.redirectURL) {
      if (nextProps.redirectURL.indexOf('api/v1/idpUserVerify?state=') >= 0) {
        const IdpUrl = nextProps.redirectURL.split('?');
        const location = IdpUrl[1];
        const params = parseQueryString(location);
        const { state } = params;
        fetchGet(getEndpoint('idpUserVerify', 1), { state })
          .then((res) => {
            this.props.idpUserLogin(state);
          })
          .catch((e) => {
            this.props.loginFailure(authMessages.errorsUserIdpAvailable, e.message);
          });
        return;
      }
      window.location.href = nextProps.redirectURL;
    } else if (nextProps.redirectLoginURL !== this.props.redirectLoginURL) {
      const { userName } = this.state;
      const params = parseLocation(window.location);
      const { redirectUrl } = params;
      const { pathname } = window.location;
      const isVirtualLoginPage = (pathname || '').startsWith('/auth/virtual_login');
      this.props.replace(
        buildLocation(
          this.getSandboxEnvironment()
            ? isVirtualLoginPage
              ? '/auth/virtual_login'
              : '/auth/sandbox-signup'
            : isVirtualLoginPage
            ? '/auth/virtual_login'
            : '/auth/login2',
          {},
          { userName, redirectUrl },
        ),
      );
      this.setState({ isChecking: false }, this.login);
    } else if (nextProps.isChecked !== this.props.isChecked) {
      if (this.state.userName && this.state.password) this.login();
    }
  }

  @autobind
  getSandboxEnvironment() {
    const urlRex = 'https://sandbox.insightfinder.com';
    const url = window.BASE_URL || window.location.origin || '';
    return url === urlRex;
  }

  @autobind
  idpTypeCallback(idpType) {
    this.setState({ idpType });
  }

  @autobind
  handleSignIn(event) {
    event.preventDefault();
    this.login();
  }

  @autobind
  handleEnterSubmit(event) {
    const { userName, password } = this.state;
    if (event.key === 'Enter' && userName && password) {
      event.preventDefault();
      this.login();
    }
  }

  @autobind
  login() {
    const { userName, password, idpType } = this.state;
    const isIdpUser = idpType !== 'Insightfinder';
    const params = parseLocation(window.location);
    const { redirectUrl } = params;
    let customerName;
    if (redirectUrl) {
      const search = decodeURIComponent(redirectUrl).split('?')[1];
      if (search) {
        const { customerName: cname } = parseQueryString(search);
        if (cname) {
          customerName = cname;
        }
      }
    }
    const { pathname } = window.location;
    const isVirtualLoginPage = (pathname || '').startsWith('/auth/virtual_login');
    this.props.replace(
      buildLocation(
        isVirtualLoginPage ? '/auth/virtual_login' : '/auth/login2',
        {},
        { userName, customerName, redirectUrl },
      ),
    );
    this.props.login(userName, password, isIdpUser, idpType, {});
  }

  @autobind
  async responseGoogle(response) {
    const { clientId, credential } = response || {};
    if (clientId && credential) {
      this.setState({ isLogging: true });
      await sleep(300);

      const { createLoadAction } = this.props;
      const params = {
        idpType: 'Google',
        // eslint-disable-next-line camelcase
        idpInfo: JSON.stringify({ clientId, id_token: credential }),
      };
      createLoadAction(ActionTypes.SINGLESIGNINLOGIN, params, false, false, this.callbackHandleLoading);
    }
  }

  @autobind
  callbackHandleLoading() {
    // reset reloadSystem
    this.setState({ isLogging: false });
  }

  render() {
    const { intl, push, isLoggingIn, isLoggedIn, location, loginReason, currentTheme, login, loginMessage } =
      this.props;
    const { isChecking, loginRedirectURL, isLogging, activeItem, activeFlag } = this.state;
    const params = parseLocation(location);
    const { error, redirectUrl } = params;
    const browserWidth =
      (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 375 ? 320 : 296;

    const userNameLink = VLink.state(this, 'userName').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsUserNameRequired),
    );
    const passwordLink = VLink.state(this, 'password').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsPasswordRequired),
    );
    const disabled = userNameLink.error || passwordLink.error || isLoggingIn || isChecking;
    const from = get(location, 'state.from', '/');
    const hasError = !isLoggedIn && (loginReason || error);

    if (isLoggedIn) {
      if (from && from.pathname && from.pathname === '/account-info') {
        return <Redirect to="/" />;
      }
      if (redirectUrl) {
        const url = decodeURIComponent(redirectUrl);
        const path = url.split('?')[0];
        const query = url.split('?')[1];
        return <Redirect to={{ pathname: path, search: query }} />;
      }

      if (loginRedirectURL) {
        return <Redirect to={loginRedirectURL} />;
      }

      return <Redirect to={from} />;
    }

    const loginMessageStr = isString(loginMessage) ? loginMessage : JSON.stringify(loginMessage || '');
    const loginErrorMessage =
      R.includes('tokenExpiredTime', loginMessageStr) &&
      R.includes('avatarId', loginMessageStr) &&
      R.includes('data', loginMessageStr)
        ? intl.formatMessage(authMessages.errorsWrongCredential)
        : loginMessage;
    return (
      <CenterPage
        intl={intl}
        push={push}
        className="auth"
        currentTheme={currentTheme}
        idpTypeCallback={this.idpTypeCallback}
      >
        <form className={`ui ${hasError ? 'error' : ''} form login_class`} style={{ width: 320 }}>
          <div style={{ marginBottom: 10, textAlign: 'center' }}>
            <UserAddOutlined style={{ fontSize: 20 }} />
            <span style={{ margin: '0 8px' }}>{intl.formatMessage(authMessages.hintsNewUser)}</span>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                push(buildUrl('/auth/signup', {}, {}));
              }}
            >
              {intl.formatMessage(authMessages.register)}
            </Button>
          </div>

          {hasError && !activeFlag && (
            <div className="ui error message">
              {error ? intl.formatMessage(authMessages.errorsWrongCredential) : loginErrorMessage}
            </div>
          )}
          <div className="input field required">
            <label>{intl.formatMessage(appFieldsMessages.userName)}</label>
            <Input valueLink={userNameLink} icon="user icon" disabled={isLogging} />
          </div>
          <div className="input field required">
            <label>{intl.formatMessage(appFieldsMessages.password)}</label>
            <Input
              type="password"
              valueLink={passwordLink}
              icon="lock icon"
              onKeyPress={this.handleEnterSubmit}
              disabled={isLogging}
            />
          </div>
          <div className="field">
            <Button
              type="primary"
              style={{ width: '100%' }}
              loading={isLoggingIn || isChecking}
              disabled={disabled}
              onClick={this.handleSignIn}
            >
              {intl.formatMessage(authMessages.buttonsSignIn)}
            </Button>
          </div>

          <div className="field" style={{}}>
            <span style={{ marginRight: 8 }}>{intl.formatMessage(authMessages.hintsForgot)}</span>
            <Link tabIndex={-1} to="/auth/forgotPassword">
              {intl.formatMessage(authMessages.hintsPassword)}
            </Link>
            <span style={{ margin: '0 4px' }}>{intl.formatMessage(authMessages.hintsOr)}</span>
            <Link tabIndex={-1} to="/auth/forgotUsername">
              {intl.formatMessage(authMessages.hintsUserName)}
            </Link>
          </div>

          <div className="field flex-row flex-space-between" style={{ marginTop: 10 }}>
            <div>
              <span style={{ marginRight: 8 }}>{intl.formatMessage(authMessages.hintsQuestions)}</span>
              <Button type="link" href="https://insightfinder.com/contact" style={{ padding: 0 }}>
                {intl.formatMessage(authMessages.contactSupport)}
              </Button>
            </div>
            <LocaleSelector style={{ marginBottom: 10 }} />
          </div>
        </form>
        <div className="flex-col flex-center-align">
          <LoginRenderers.GoogleLogin width={browserWidth} onGoogleSignIn={this.responseGoogle} />

          <div
            className="flex-row flex-center-align clickable login-other-btn full-width"
            style={{
              maxWidth: browserWidth,
              height: 32,
              border: '1px solid #dadce0',
              borderRadius: 4,
              marginTop: 10,
              padding: '0 10px',
            }}
            onClick={() => {
              this.props.loginFailure();
              this.setState({ activeFlag: true, activeItem: { textName: 'Saml', handleKey: 'Saml' } });
            }}
          >
            <div style={{ height: 14 }}>
              <div
                className="saml-color"
                style={{
                  backgroundImage:
                    currentTheme === 'light'
                      ? 'linear-gradient(to right, #390bff, #a44859)'
                      : 'linear-gradient(to right, #39d199, #a49a48)',
                }}
              >
                Saml
              </div>
            </div>
            <div className="flex-grow" style={{ fontSize: 14, textAlign: 'center' }}>
              {intl.formatMessage(authMessages.butOtherSignIn, { name: 'Saml' })}
            </div>
          </div>

          {R.map((item) => {
            const { imgSrc, altStr, textName, handleKey } = item;
            return (
              <RenderOtherButton
                key={handleKey.toString()}
                intl={intl}
                imgSrc={imgSrc}
                altStr={altStr}
                textName={textName}
                browserWidth={browserWidth}
                onClick={() => {
                  this.props.loginFailure();
                  this.setState({ activeFlag: true, activeItem: { textName, handleKey } });
                }}
              />
            );
          }, this.otherBtnObj)}
        </div>

        {activeFlag && (
          <RenderOtherModal
            intl={intl}
            activeItem={activeItem}
            isLoggingIn={isLoggingIn}
            isChecking={isChecking}
            onClose={() => {
              this.props.loginFailure();
              this.setState({ activeFlag: false, activeItem: {} });
            }}
            login={login}
            hasError={hasError}
            error={error}
            loginReason={loginReason}
            credentials={this.props.credentials}
          />
        )}
      </CenterPage>
    );
  }
}

const RenderOtherButton = ({ intl, imgSrc, altStr, textName, onClick, browserWidth }: Object) => {
  return (
    <div
      className="flex-row flex-center-align clickable login-other-btn full-width"
      style={{
        maxWidth: browserWidth,
        height: 32,
        border: '1px solid #dadce0',
        borderRadius: 4,
        marginTop: 10,
        padding: '0 10px',
      }}
      onClick={onClick}
    >
      <div style={{ width: 30, height: 14, lineHeight: 1 }}>
        <img alt={altStr} src={imgSrc} style={{ height: '100%', width: '100%' }} />
      </div>
      <div className="flex-grow" style={{ fontSize: 14, textAlign: 'center' }}>
        {intl.formatMessage(authMessages.butOtherSignIn, { name: textName })}
      </div>
    </div>
  );
};

const RenderOtherModal = ({
  intl,
  activeItem,
  onClose,
  isLoggingIn,
  isChecking,
  login,
  hasError,
  error,
  loginReason,
  credentials,
}: Object) => {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loginError: '',
    loginLoading: false,
  });
  const { loginError, loginLoading } = state;
  const { handleKey: idpType, textName } = activeItem;

  const isShowEmail = R.includes(idpType, ['Azure', 'Saml']);
  const isSaml = idpType === 'Saml';

  const samlLogin = (email) => {
    setState({ loginLoading: true });
    fetchPost(getEndpoint('saml-email-check'), { ...credentials, email })
      .then((data) => {
        const { success, message: msg, url } = data || {};
        if (success || success === undefined) {
          message.success(msg);
          if (url) window.location.href = url;
          setState({ loginLoading: false });
        } else {
          setState({ loginError: msg, loginLoading: false });
        }
      })
      .catch((err) => {
        setState({ loginError: err.message || String(err), loginLoading: false });
      });
  };

  const handleFinish = (values) => {
    const { userName, password } = values;
    if (!isSaml) {
      login(userName, password, true, idpType, {});
    } else {
      samlLogin(userName);
    }
  };
  return (
    <Modal
      title={intl.formatMessage(authMessages.butOtherSignIn, { name: textName || 'Azure AD' })}
      width={500}
      visible
      maskClosable={false}
      footer={null}
      onCancel={() => onClose()}
    >
      <Form labelCol={{ span: 4 }} onFinish={handleFinish}>
        {loginError && <div className="ui error message">{loginError}</div>}
        {hasError && (
          <div className="ui error message">
            {error ? intl.formatMessage(authMessages.errorsWrongCredential) : intl.formatMessage(loginReason)}
          </div>
        )}
        <Form.Item
          required
          label={intl.formatMessage(isShowEmail ? appFieldsMessages.email : appFieldsMessages.userName)}
          name="userName"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (isShowEmail && Regex.email.test(value)) {
                  return Promise.resolve();
                } else if (!isShowEmail && value) {
                  return Promise.resolve();
                } else if (isShowEmail && !Regex.email.test(value) && value) {
                  return Promise.reject(new Error(intl.formatMessage(authMessages.errorsEmailIncorrect)));
                }
                return Promise.reject(new Error(intl.formatMessage(appFieldsMessages.inputRequired)));
              },
            }),
          ]}
        >
          <AntdInput />
        </Form.Item>
        {!isShowEmail && (
          <Form.Item
            required
            label={intl.formatMessage(appFieldsMessages.password)}
            name="password"
            rules={[{ required: true, message: intl.formatMessage(appFieldsMessages.inputRequired) }]}
          >
            <AntdInput type="password" />
          </Form.Item>
        )}
        <Form.Item style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={isLoggingIn || isChecking || loginLoading}>
            {intl.formatMessage(authMessages.buttonsSignIn)}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Login = injectIntl(LoginCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    return {
      location,
      appLoaderVisible: state.app.appLoaderVisible,
      loginReason: state.auth.loginReason,
      loginMessage: state.auth.loginMessage,
      isLoggedIn: state.auth.loggedIn,
      isLoggingIn: state.auth.loggingIn,
      redirectURL: state.auth.redirectURL,
      redirectLoginURL: state.auth.redirectLoginURL,
      isChecked: state.auth.checked,
      credentials: state.auth.credentials,
      currentTheme: state.app.currentTheme,
    };
  },
  {
    push,
    replace,
    login,
    hideAppLoader,
    userTypeCheck,
    idpUserLogin,
    loginFailure,
    clearCredentials,
    createLoadAction,
  },
)(Login);
