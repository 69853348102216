import { PermissionError, NotFoundError, NoAuthError } from '.';
import { loginFailure } from '../auth/actions';
import { authMessages } from '../auth/messages';

const loginErrorHandler = (err, dispatch, done, userName, idpAvailable = false) => {
  console.error('[IF] Failed to auth', err);

  if (idpAvailable) {
    dispatch(loginFailure(authMessages.errorsUserIdpAvailable, err));
    done();
  }
  if (err instanceof NotFoundError) {
    dispatch(loginFailure(authMessages.errorsWrongCredential, err));
    done();
  } else if (err instanceof NoAuthError || err instanceof PermissionError) {
    const jsonObj = err.responseJson;
    if (jsonObj && jsonObj.message.indexOf('locked') > 0) {
      if (jsonObj.role === 'Admin' || userName === 'admin') {
        dispatch(loginFailure(authMessages.errorsWrongCredential, jsonObj));
      } else {
        dispatch(loginFailure(authMessages.errorsWrongCredential, jsonObj));
      }
    } else {
      dispatch(loginFailure(authMessages.errorsWrongCredential, jsonObj));
    }
    done();
  } else {
    dispatch(loginFailure(authMessages.errorsWrongCredential, err));
    done();
  }
};

export default loginErrorHandler;
