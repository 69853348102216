import React from 'react';
import {
  ClusterOutlined,
  DashboardOutlined,
  LineChartOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  AlertIncidentAnalysisIcon,
  AnomalyDetectionIcon,
  CausalAnalysisIcon,
  ExternalServiceSettingIcon,
  FileAnalysisIcon,
  GlobalHealthIcon,
  IncidentPredictIcon,
  KnowledgeBaseNewIcon,
  LogTraceAnalysisIcon,
  MetricAnalysisIcon,
  RootCauseIcon,
  SystemBatchConfigurationIcon,
} from '../../../lib/fui/icons';
import BaseUrls from '../BaseUrls';

type Props = {
  children: Element<any>,
};

export const AppCategories = [
  { name: 'apps', label: 'Apps' },
  { name: 'settings', label: 'Settings' },
];

export const AppManifests = [
  {
    name: 'incidentInvestigation',
    label: 'Incident Investigation',
    category: 'apps',
    icon: <RootCauseIcon style={{ color: 'var(--primary-color)' }} />,
    link: BaseUrls.GlobalSystemRootCause,
    keywords: ['incident', 'investigation', 'root', 'cause'],
    shortDetails:
      'Enable your responders with root cause analysis for faster time to resolution to minimize business impacts.',
  },
  {
    name: 'incidentPrediction',
    label: 'Incident Prediction',
    category: 'apps',
    icon: <IncidentPredictIcon style={{ color: 'var(--primary-color)' }} />,
    link: BaseUrls.GlobalSystemPrediction,
    keywords: ['incident', 'prediction'],
    shortDetails: 'Receive early warning signals to business impacting incidents and achieve zero-downtime.',
  },
  {
    name: 'serviceMap',
    label: 'Service Map',
    category: 'apps',
    icon: <ClusterOutlined style={{ color: 'var(--primary-color)' }} />,
    link: BaseUrls.GlobalServiceMap,
    keywords: ['service', 'map'],
    shortDetails: 'Graphical view of components and instances and their dependencies along with the health status.',
  },
  {
    name: 'fileAnalysis',
    label: 'Anomaly Detection',
    category: 'apps',
    icon: <AnomalyDetectionIcon style={{ color: 'var(--primary-color)' }} />,
    link: BaseUrls.FileAnalysis,
    roleChecker: ({ isReadUser, allowFileUpload }) => !isReadUser && allowFileUpload,
    keywords: ['file', 'analysis'],
    shortDetails: 'Upload historical data (csv) to analyze and identify anomalous trends.',
  },
  {
    name: 'uhv',
    label: 'Unified Health View',
    category: 'apps',
    icon: <GlobalHealthIcon style={{ color: 'var(--primary-color)' }} />,
    link: BaseUrls.GlobalHealth,
    keywords: ['unified', 'health', 'view'],
    shortDetails:
      'Comprehensive overview of your system’s health with real-time monitoring data and insights from all data sources to drive informed decision-making and proactive management.',
  },
  {
    name: 'insightDashboard',
    label: 'Insight Dashboard',
    category: 'apps',
    icon: <DashboardOutlined style={{ color: 'var(--primary-color)' }} />,
    link: BaseUrls.GlobalDashboard,
    keywords: ['insight', 'dashboard'],
    shortDetails: 'Display an overall look of your systems based on an overall statistics calculation.',
  },
  {
    name: 'metricLineChart',
    label: 'Metric line chart',
    category: 'apps',
    icon: <LineChartOutlined style={{ color: 'var(--primary-color)' }} />,
    link: BaseUrls.MetricLineCharts,
    keywords: ['metric', 'line', 'chart'],
    shortDetails: 'Access and analyze your metric data.',
  },
  {
    name: 'metricAnalysis',
    label: 'Metric Analysis',
    category: 'apps',
    icon: <MetricAnalysisIcon style={{ color: 'var(--primary-color)' }} />,
    link: BaseUrls.MetricEvents,
    keywords: ['metric', 'analysis'],
    shortDetails: 'Visibility into components/instances displaying specific anomalous behaviors in metric time series.',
  },
  {
    name: 'logAnalysis',
    label: 'Log/Trace Analysis',
    category: 'apps',
    icon: <LogTraceAnalysisIcon style={{ color: 'var(--primary-color)' }} />,
    link: `${BaseUrls.LogCalendar}?hasLog=true`,
    keywords: ['log', 'trace', 'analysis'],
    shortDetails:
      'Extract abnormal log patterns such as rare events, hot/cold events, new pattern events in real time log analysis. It also compresses logs using extracted patterns to reduce your storage cost by over 95%.',
  },
  {
    name: 'incidentAnalysis',
    label: 'Alert/Incident Analysis',
    category: 'apps',
    icon: <AlertIncidentAnalysisIcon style={{ color: 'var(--primary-color)' }} />,
    link: `${BaseUrls.LogCalendar}?hasAlert=true`,
    keywords: ['incident', 'alert', 'analysis'],
    shortDetails: 'Visibility into the number of alerts raised, total patterns, new patterns, events, incidents, etc.',
  },
  {
    name: 'causalAnalysis',
    label: 'Causal Analysis',
    category: 'apps',
    icon: <CausalAnalysisIcon style={{ color: 'var(--primary-color)' }} />,
    link: `${BaseUrls.CausalAnalysis}`,
    keywords: ['causal', 'analysis'],
    shortDetails:
      'Analyze the relationship between metrics, log entries, KPIs providing cross-project view by analyzing different projects and instance groups within a causal group.',
  },
  {
    name: 'knowledgeBase',
    label: 'Knowledge Base',
    category: 'apps',
    icon: <KnowledgeBaseNewIcon style={{ color: 'var(--primary-color)' }} />,
    link: `${BaseUrls.GlobalSystemKnowledgeBase}`,
    keywords: ['knowledge', 'base'],
    shortDetails:
      'Automatically extracted predictive causal chains with prediction accuracy statistics (e.g., true positive predictions, false positive predictions).',
  },
  {
    name: 'systemSetting',
    label: 'System Setting',
    category: 'settings',
    icon: <SettingOutlined style={{ color: 'var(--primary-color)' }} />,
    link: `${BaseUrls.InsightfinderSetting}`,
    roleChecker: ({ isAdmin, isLocalAdmin }) => isAdmin || isLocalAdmin,
    keywords: ['system', 'setting'],
    shortDetails: 'Tune settings towards each project for a more tailored result.',
  },
  {
    name: 'externalServiceSetting',
    label: 'External Service Setting',
    category: 'settings',
    icon: <ExternalServiceSettingIcon style={{ color: 'var(--primary-color)' }} />,
    link: `${BaseUrls.ExternalServiceSetting}`,
    roleChecker: ({ isReadUser }) => !isReadUser,
    keywords: ['external', 'service', 'setting'],
    shortDetails:
      'Push InsightFinder prediction and detection insights with RCA into your native alerting or monitoring environment.',
  },
  {
    name: 'systemBatchConfiguration',
    label: 'System Batch Configuration',
    category: 'settings',
    icon: <SystemBatchConfigurationIcon style={{ color: 'var(--primary-color)' }} />,
    link: `${BaseUrls.SystemBatch}`,
    roleChecker: ({ isReadUser }) => !isReadUser,
    keywords: ['system', 'batch', 'setting'],
    shortDetails: 'Transpose existing system configurations to other systems for faster configurations. ',
  },
];
