import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { CellMeasurerCache } from '../../../lib/fui/react';
import { updateLastActionInfo, createLoadAction } from '../../../common/app/actions';

import { DashboardMessages } from '../../../common/dashboard/messages';

// new
import GlobalDetectedIncidentTable from './GlobalDetectedIncidentTable';

type Props = {
  events: Array,
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refreshTime: Number,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  zoomStartTime: Number,
  // eslint-disable-next-line
  zoomEndTime: Number,
  // eslint-disable-next-line
  selectAnomalyInstance: String,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoadingDetectedIncident: Boolean,
  // eslint-disable-next-line
  reloadSystem: Number,
  // eslint-disable-next-line
  onReloadSystemIncidentTimelines: Function,
  // eslint-disable-next-line
  updateState: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createLoadAction: Function,

  // eslint-disable-next-line
  globalSystemDetectIncidentTimelines: Array<Object>,
  width: Number,
  height: Number,
  summarySettingsMap: Object,
  onRefreshData: Function,
  rankMap: Object,
  activeLineIconKey: String,
  title: String,
  typeString: String,
  changeActiveLineIconKey: Function,
  changeLikelyRootCausesRCA: Function,
};

class GlobalPanelIncidentsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {};
    this.patternCountMap = {};
    this.patternCountProjectMap = {};
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    const { intl, events = [], onRefreshData, activeLineIconKey, title, typeString } = this.props;
    const { isLoadingDetectedIncident, systemInfo, width, summarySettingsMap, rankMap, height } = this.props;
    const { changeActiveLineIconKey, changeLikelyRootCausesRCA } = this.props;
    return (
      <>
        <div style={{ padding: '8px 0' }}>
          <span className="font-14 bold">{title}</span> - {typeString}
        </div>
        <GlobalDetectedIncidentTable
          title={intl.formatMessage(DashboardMessages.detectedIncidents)}
          tabName="detectedIncidents"
          systemInfo={systemInfo}
          events={events}
          activeEvent={events}
          width={width - 2}
          height={height - 36}
          rankMap={rankMap}
          isLoading={isLoadingDetectedIncident}
          summarySettingsMap={summarySettingsMap}
          onRefreshData={onRefreshData}
          activeLineIconKey={activeLineIconKey}
          changeActiveLineIconKey={changeActiveLineIconKey}
          changeLikelyRootCausesRCA={changeLikelyRootCausesRCA}
        />
      </>
    );
  }
}

const GlobalPanelIncidents = injectIntl(GlobalPanelIncidentsCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    const { globalSystemDetectIncidentTimelines } = state.dashboard;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,

      globalSystemDetectIncidentTimelines,
    };
  },
  { updateLastActionInfo, createLoadAction },
)(GlobalPanelIncidents);
