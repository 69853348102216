import * as R from 'ramda';
import moment from 'moment';
import { parseTimelinesCategory } from '../magicParsers';

const parseTimelines = ({
  timeline,
  timelinesMap,
  projectNameSet,
  projectDisplayMap,
  notInclude,
  hasSetNewFlag,
  componentLevelNewFlag,
  systemId,
}) => {
  const systemTimeline = parseTimelinesCategory({ timeline, projectNameSet, projectDisplayMap, systemId });
  const { id, currentStartTime, anomalyTimelines } = systemTimeline;
  const dayStartTimestamp = moment.utc(currentStartTime).startOf('day').valueOf();
  if (!R.has(id, timelinesMap)) {
    timelinesMap[id] = [{ systemTimeline, notInclude }];
  } else {
    // get recurrent info only for incident
    // const startTs = moment.utc().valueOf();
    const newAnomalyTimelines = R.map((item) => {
      const newItem = { ...item, dayStartTimestamp };
      return newItem;
    }, anomalyTimelines);
    // console.debug(`Parser recurrent duration: ${(moment.utc().valueOf() - startTs) / 1000} sec`);

    timelinesMap[id].push({ systemTimeline: { ...systemTimeline, anomalyTimelines: newAnomalyTimelines }, notInclude });
  }
};

export default parseTimelines;
