/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';

import { Select } from '../../../lib/fui/react';

import { DashboardMessages } from '../../../common/dashboard/messages';

type Props = {};

export const AnomalyThresholdSensitivity = ({ intl, ...rest }: Props) => (
  <Select
    options={[
      { label: intl.formatMessage(DashboardMessages.low), value: '0.99' },
      { label: intl.formatMessage(DashboardMessages.mediumLow), value: '0.95' },
      { label: intl.formatMessage(DashboardMessages.medium), value: '0.9' },
      { label: intl.formatMessage(DashboardMessages.mediumHigh), value: '0.75' },
      { label: intl.formatMessage(DashboardMessages.high), value: '0.5' },
    ]}
    {...rest}
  />
);

export const FeatureOutlierSensitivity = ({ intl, ...rest }: Props) => (
  <Select
    options={[
      { label: intl.formatMessage(DashboardMessages.low), value: 'low' },
      { label: intl.formatMessage(DashboardMessages.medium), value: 'medium' },
      { label: intl.formatMessage(DashboardMessages.high), value: 'high' },
    ]}
    {...rest}
  />
);

export const SimilaritySensitivity = ({ intl, ...rest }: Props) => (
  <Select
    options={[
      { label: intl.formatMessage(DashboardMessages.low), value: 'low' },
      { label: intl.formatMessage(DashboardMessages.mediumLow), value: 'mediumLow' },
      { label: intl.formatMessage(DashboardMessages.medium), value: 'medium' },
      { label: intl.formatMessage(DashboardMessages.mediumHigh), value: 'mediumHigh' },
      { label: intl.formatMessage(DashboardMessages.high), value: 'high' },
    ]}
    {...rest}
  />
);

const PredictionWindowHourOptions = [
  { label: '4', value: '4' },
  { label: '12', value: '12' },
  { label: '24', value: '24' },
  { label: '48', value: '48' },
];
export const PredictionWindowHour = ({ ...rest }: Props) => <Select options={PredictionWindowHourOptions} {...rest} />;

const DurationThresholdOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '15', value: '15' },
  { label: '20', value: '20' },
  { label: '25', value: '25' },
  { label: '30', value: '30' },
];
export const DurationThreshold = ({ ...rest }: Props) => <Select options={DurationThresholdOptions} {...rest} />;

export default {
  AnomalyThresholdSensitivity,
  PredictionWindowHour,
  DurationThreshold,
};
