import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Select } from 'antd';

import { State } from '../../../common/types';
import { Container } from '../../../lib/fui/react';

import { logMessages } from '../../../common/log/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import MetricToLoaAnomalous from './MetricToLoaAnomalous';
import MetricToLoaAllEntries from './MetricToLoaAllEntries';

type Props = {
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  project: Object,
  // eslint-disable-next-line
  selectProjectName: String,
  // eslint-disable-next-line
  selectInstanceName: String,
  // eslint-disable-next-line
  selectStartTimestamp: Number,
  // eslint-disable-next-line
  selectEndTimestamp: Number,
  // eslint-disable-next-line
  intl: Object,
};

class MetricLineChartToLogPageCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl } = props;

    this.state = {
      eventListTotal: 0,
      filterType: 'entrie',
    };

    this.filterTypeOption = [
      { value: 'entrie', label: intl.formatMessage(logMessages.allLogEntries) },
      { value: 'anomaly', label: intl.formatMessage(logMessages.allAnomalousLogs) },
    ];
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleTypeChange(filterType) {
    this.setState({ filterType, eventListTotal: 0 });
  }

  @autobind
  changeTotal(total) {
    this.setState({ eventListTotal: total });
  }

  render() {
    const { intl, refresh, selectProjectName, selectInstanceName, selectStartTimestamp, selectEndTimestamp, project } =
      this.props;
    const { eventListTotal, filterType } = this.state;
    const isAnomalyLog = filterType === 'anomaly';

    return (
      <Container className="full-width full-height flex-col">
        <div className="flex-grow flex-min-height flex-col ">
          <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.type)}:</div>
              <Select
                size="small"
                value={filterType}
                style={{ width: 150 }}
                onChange={this.handleTypeChange}
                options={this.filterTypeOption}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginLeft: 16 }}>
              <div style={{ marginRight: 8 }}>
                {intl.formatMessage(isAnomalyLog ? logMessages.totalAnomalousLogEntries : logMessages.totalLogEntries)}:
              </div>
              <div className="font-14">{eventListTotal}</div>
            </div>
          </div>
          <div className="flex-grow">
            {isAnomalyLog && (
              <MetricToLoaAnomalous
                refresh={refresh}
                project={project}
                selectProjectName={selectProjectName}
                selectInstanceName={selectInstanceName}
                selectStartTimestamp={selectStartTimestamp}
                selectEndTimestamp={selectEndTimestamp}
                changeTotal={this.changeTotal}
              />
            )}
            {!isAnomalyLog && (
              <MetricToLoaAllEntries
                refresh={refresh}
                project={project}
                selectProjectName={selectProjectName}
                selectInstanceName={selectInstanceName}
                selectStartTimestamp={selectStartTimestamp}
                selectEndTimestamp={selectEndTimestamp}
                changeTotal={this.changeTotal}
              />
            )}
          </div>
        </div>
      </Container>
    );
  }
}

const MetricLineChartToLogPage = injectIntl(MetricLineChartToLogPageCore);
export default connect((state: State) => {
  return {};
}, {})(MetricLineChartToLogPage);
