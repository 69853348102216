/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import moment from 'moment';

const getProjectKeywordsSetting = (credentials: Credentials, params: Object) => {
  const requests = [];
  const { onlyFetchProjectKeyWord } = params;
  requests.push(
    fetchGet(getEndpoint('projectkeywords'), {
      ...credentials,
      ...params,
    })
      .then((data) => {
        const { keywords } = data;
        return keywords;
      })
      .catch((error) => {
        console.error('[IF_API]', error);
        return {};
      }),
  );
  if (!onlyFetchProjectKeyWord) {
    requests.push(
      fetchGet(`${window.BASE_URL || ''}/logCategorySetting`, {
        ...credentials,
        ...params,
      })
        .then((data) => {
          const { categoryMap } = data;
          return categoryMap;
        })
        .catch((error) => {
          console.error('[IF_API]', error);
          return {};
        }),
    );
  }

  return Promise.all(requests).then((results) => {
    const keywords = results[0] || {};
    const categoryList = [];
    if (!onlyFetchProjectKeyWord) {
      const categoryMap = results[1] || {};
      R.forEachObjIndexed((val, key) => {
        categoryList.push({
          category: key,
          keyword: R.join(',', val || []),
        });
      }, categoryMap);
      keywords.categorylist = categoryList;
    }
    return keywords;
  });
};

export default getProjectKeywordsSetting;
