import React from 'react';
import * as R from 'ramda';
import { get, round } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';

import { State } from '../../../common/types';
import { Container, Table, Column, CellMeasurer, CellMeasurerCache, Tooltip, Scrollbars } from '../../../lib/fui/react';
import { Defaults } from '../../../common/app';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/log/actions';
import { getLoadStatus, CellRenderers } from '../../../common/utils';
import { CollapsibleLogContent } from '../../share';

type Props = {
  intl: Object,
  loadStatus: Object,
  incident: Object,
  onClose: Function,
  width: Number,
  height: Number,
  shortTable: Boolean,

  appNameMapping: Object,
  logEntryCausalList: Array<Object>,
  incidentCausalProperty: Object,
  causalLogEvents: Object,
};

class CausalPredictionCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { width } = props;

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.dataLoader = 'log_data_causal';
    this.contentWidth = (width - 200) / 2;

    this.events = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
    this.cellMeasureCache.clearAll();

    if (this.dataTable) {
      this.dataTable.forceUpdate();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.incident !== nextProps.incident) {
      this.reloadData(nextProps);
    } else if (
      nextProps.logEntryCausalList !== this.props.logEntryCausalList ||
      nextProps.incidentCausalProperty !== this.props.incidentCausalProperty
    ) {
      this.preprocessData(nextProps);
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdate();
      }
    } else if (nextProps.causalLogEvents !== this.props.causalLogEvents) {
      this.preprocessData(nextProps);
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdate();
      }
    }
  }

  @autobind
  reloadData(props) {
    const { incident, createLoadAction } = props;
    const { causalFetchedInfoFileName, userName, user, startTimestamp, endTimestamp } = incident;

    if (causalFetchedInfoFileName && (userName || user)) {
      const causalFetchedInfoList = causalFetchedInfoFileName.split('_');
      const causalKey = causalFetchedInfoList[1];
      const fileName = R.join('_', R.slice(0, 5, causalFetchedInfoList));
      createLoadAction(
        ActionTypes.LOAD_LOG_ENTRY_CAUSAL_LIST,
        {
          customerName: userName || user,
          operation: 'log',
          causalFetchedInfoFileName,
          causalKey,
          startTimestamp,
          endTimestamp,
          fileName,
        },
        this.dataLoader,
      );
    }
  }

  @autobind
  reloadLogEventData(props) {
    const { incident, createLoadAction } = props;
    const { userName, user } = incident;
    const logEventQueryStr = [];
    createLoadAction(
      ActionTypes.LOAD_CAUSAL_LOG_EVENTS,
      {
        customerName: userName || user,
        logEventQueryStr: JSON.stringify(logEventQueryStr),
      },
      this.dataLoader,
    );
  }

  @autobind
  preprocessData(props) {
    const { logEntryCausalList, appNameMapping } = props;
    this.events = R.map((causal) => {
      const { timestamp, srcDetails, targetDetails, ...rest } = causal;
      let timePair = causal.timePair || [];
      timePair = R.map((item) => item.split(','), timePair);
      timePair = R.map((item) => [Number(item[0]) * 1000, Number(item[1]) * 1000], timePair);
      const delay =
        timePair.length > 0
          ? R.sum(R.map((item) => Number(item[1]) - Number(item[0]), timePair)) / timePair.length
          : causal.delay || 0;
      const count = timePair.length;
      const srcInstanceId = get(srcDetails, 'instanceId');
      const targetInstanceId = get(targetDetails, 'instanceId');
      const srcInstanceName = get(appNameMapping, srcInstanceId, srcInstanceId);
      const targetInstanceName = get(appNameMapping, targetInstanceId, targetInstanceId);
      return {
        ...rest,
        delay,
        count,
        timePair,
        timestamp: Number(timestamp),
        srcDetails: { ...srcDetails, instanceName: srcInstanceName },
        targetDetails: { ...targetDetails, instanceName: targetInstanceName },
      };
    }, logEntryCausalList || []);
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  @autobind
  getType(type) {
    type = type || 'Metric';
    if (type.indexOf('LogRareEvent') === 0) {
      type = 'LogRareEvent';
    } else if (type.indexOf('LogHotEvent') === 0) {
      type = 'LogHotEvent';
    } else if (type.indexOf('LogColdEvent') === 0) {
      type = 'LogColdEvent';
    }
    return type;
  }

  @autobind
  getLogTypeLegend(type) {
    type = this.getType(type);
    return (
      <Tooltip
        title={<div style={{ maxWidth: 200, wordBreak: 'break-all', display: 'inline-block', width: 40 }}>{type}</div>}
        placement="top"
      >
        <div className={`legend-item ${(type || '').toLowerCase()}`}>
          <i className="square icon" />
        </div>
      </Tooltip>
    );
  }

  @autobind
  getMetricLegend(metric) {
    const m = (metric || '').toLowerCase();
    let label = m ? m[0].toUpperCase() : 'M';
    if (m.indexOf('network') === 0) {
      label = 'NET';
    } else if (m.indexOf('disk') === 0) {
      label = 'DISK';
    } else if (m.indexOf('cpu') === 0) {
      label = 'CPU';
    } else if (m.indexOf('mem') === 0) {
      label = 'MEM';
    } else if (m.indexOf('new') === 0) {
      label = 'NEW';
    }

    return (
      <Tooltip
        title={<div style={{ maxWidth: 200, wordBreak: 'break-all', display: 'inline-block' }}>{metric}</div>}
        placement="top"
      >
        <span
          className="anomaly-event-legend"
          style={{ border: '1px solid #767676', background: '#767676', color: 'white' }}
        >
          {label}
        </span>
      </Tooltip>
    );
  }

  @autobind
  handleContentChanged() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind
  eventContentAutoHeightRenderer(props) {
    const { dataKey, parent, rowIndex } = props;

    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        {this.eventContentRenderer(props)}
      </CellMeasurer>
    );
  }

  @autobind
  eventContentScrollingRenderer(props) {
    return (
      <Scrollbars style={{ height: '100%', width: this.contentWidth }}>{this.eventContentRenderer(props)}</Scrollbars>
    );
  }

  @autobind
  eventContentRenderer(props) {
    const { style, cellData } = props;
    const { type, content, instanceName, avgValue, metricDirection } = cellData;
    const isHigher = (metricDirection || '').toLowerCase() === 'positive';
    const isLower = (metricDirection || '').toLowerCase() === 'negative';
    const dtype = this.getType(type);

    // Table can only support one cellmeasuer, so use source as the main one.
    const logOptions = {
      width: this.contentWidth - 40, // Space for icon
      highlightWord: null,
      inLine: true,
      ignoreFields: ['projectName'],
      onChanged: this.handleContentChanged,
    };

    if (dtype === 'Metric') {
      return (
        <div className="flex-row flex-center-align" style={{ ...style, width: this.contentWidth }}>
          <div style={{ width: 40 }}>{this.getMetricLegend(content)}</div>
          <div className="flex-grew white-pre" style={{ padding: '4px 0' }}>
            <div className="flex-col">
              <div className="flex-row" style={{ padding: '4px 0' }}>
                <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 16 }}>Instance:</div>
                <div style={{ color: 'green' }}>{instanceName}</div>
              </div>
              <div className="flex-row" style={{ padding: '4px 0' }}>
                <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 16 }}>Metric:</div>
                <div style={{ color: 'blue' }}>{content}</div>
              </div>
              <div className="flex-row" style={{ padding: '4px 0' }}>
                <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 16 }}>Value:</div>
                <div>{round(avgValue, 2)}</div>
                {isHigher && <i className="icon up arrow" />}
                {isLower && <i className="icon down arrow" />}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ ...style, width: this.contentWidth }}>
        <div className="white-pre" style={{ padding: '4px 0px' }}>
          <div className="flex-col">
            <div className="flex-row" style={{ paddingLeft: 40 }}>
              <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 16 }}>Instance:</div>
              <div style={{ color: 'green' }}>{instanceName}</div>
            </div>
            <Container className="flex-row" style={{ marginBottom: 4, alignItems: 'center' }}>
              {this.getLogTypeLegend(type)}
              <CollapsibleLogContent ownerObject={cellData} message={content} {...logOptions} />
            </Container>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { intl, loadStatus, width, height } = this.props;
    const events = this.events;

    const { isLoading } = getLoadStatus(get(loadStatus, this.dataLoader), intl);

    return (
      <Container className={`${isLoading ? 'loading ' : ''}flex-grow`}>
        <div className="flex-row causal-log" style={{ overflow: 'auto', height }}>
          {events.length <= 0 && (
            <div className="ui info message" style={{ width, height: 50 }}>
              {Defaults.NoLogEntryFound}
            </div>
          )}
          {events.length > 0 && (
            <Table
              className="with-border"
              width={width}
              height={height}
              deferredMeasurementCache={this.cellMeasureCache}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={this.cellMeasureCache.rowHeight}
              rowCount={events.length}
              rowGetter={({ index }) => events[index]}
              ref={(c) => {
                this.dataTable = c;
              }}
            >
              <Column width={130} label="DateTimes" dataKey="timePair" cellRenderer={CellRenderers.timePair} />
              <Column
                width={this.contentWidth}
                label={'Detected events'}
                style={{ height: '100%' }}
                flexGrow={1}
                dataKey="srcDetails"
                cellRenderer={this.eventContentScrollingRenderer}
              />
              <Column
                width={this.contentWidth}
                label={'Predicted events'}
                flexGrow={1}
                dataKey="targetDetails"
                cellRenderer={this.eventContentAutoHeightRenderer}
              />
              <Column width={100} label="Probability" dataKey="probability" cellRenderer={CellRenderers.probability} />
              <Column
                width={100}
                label="Avg delay"
                dataKey="delay"
                cellRenderer={({ cellData }) => CellRenderers.humanizeDuration({ period: cellData, intl })}
              />
            </Table>
          )}
        </div>
      </Container>
    );
  }
}

const CausalPrediction = injectIntl(CausalPredictionCore);
export default connect(
  (state: State) => {
    const { loadStatus } = state.app;
    const logEntryCausalList = get(state.log, 'logEntryCausalList', []);
    const incidentCausalProperty = get(state.log, 'incidentCausalProperty', {});
    const { causalLogEvents } = state.causal;
    return { loadStatus, logEntryCausalList, incidentCausalProperty, causalLogEvents };
  },
  { createLoadAction },
)(CausalPrediction);
