/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import * as R from 'ramda';

import { State } from '../../../common/types';
import { Modal, Dropdown } from '../../../../artui/react';
import { parseLocation, getLoadStatus } from '../../../common/utils';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/metric/actions';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,
  onClose: Function,
  createLoadAction: Function,

  incident: Object,
  eventsNeurons: Array,
  instanceList: Array,
  metricList: Array,
};

class EventPublishModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'event_publish_loader';
    this.systemNames = [
      'Security',
      'Cassandra',
      'Hadoop',
      'Apache',
      'Tomcat',
      'MySQL',
      'HDFS',
      'Spark',
      'Lighttpd',
      'Memcached',
    ];
    this.state = {
      name: '',
      description: '',
      system: undefined,
      ownerOnly: false,
      sharedUsernames: '',

      other: 'Unknown',
      showOther: false,
    };
  }

  @autobind
  handleSubmit() {
    const { name, description, system, ownerOnly, sharedUsernames, other } = this.state;
    const { createLoadAction, location } = this.props;

    const { eventsNeurons, instanceList, metricList } = this.props;
    const { incident } = this.props;

    let params = {
      projectName: null,
      instanceGroup: null,
      ownerOnly,
      sharedUsernames,
      startTime: null,
      endTime: null,
      metaData: null,
      instanceList: JSON.stringify(instanceList || []),
      metricList: JSON.stringify(metricList || []),
    };
    let startTimestamp = null;
    let endTimestamp = null;
    let projectName = null;
    let instanceGroup = 'All';
    if (incident) {
      startTimestamp = get(incident, 'startTimestamp');
      endTimestamp = get(incident, 'endTimestamp');
      projectName = get(incident, 'projectName');
      const userName = get(incident, 'userName');
      projectName = projectName.indexOf('@') >= 0 ? projectName : `${projectName}@${userName}`;
      instanceGroup = get(incident, 'instanceGroup') || instanceGroup;
      // const { neuronId } = incident;
      // eventsNeurons = [neuronId];
      params = { ...params, projectName, instanceGroup, startTime: startTimestamp, endTime: endTimestamp };
    } else {
      const locationObj = parseLocation(location);
      startTimestamp = get(locationObj, 'startTimestamp');
      endTimestamp = get(locationObj, 'endTimestamp');
      projectName = get(locationObj, 'projectName');
      instanceGroup = get(locationObj, 'instanceGroup') || instanceGroup;
      params = { ...params, projectName, instanceGroup, startTime: startTimestamp, endTime: endTimestamp };
    }

    params = {
      ...params,
      metaData: JSON.stringify({
        name,
        desc: description,
        system: system === 'Others' ? other : system,
        eventsNeurons: JSON.stringify(eventsNeurons || []),
      }),
    };

    if (projectName && startTimestamp && endTimestamp) {
      createLoadAction(ActionTypes.SAVE_EVENT_PUBLISH, params, this.dataLoader);
    }
  }

  @autobind
  handleCancel() {
    this.props.onClose();
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  @autobind
  handleSystemChange(v) {
    this.setState({ system: v, showOther: v === 'Others' });
  }

  render() {
    const { intl, loadStatus } = this.props;
    const { showOther } = this.state;
    const disabled = !this.state.name || !this.state.description || !this.state.system;

    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);

    return (
      <Modal size="mini" closable={false} onClose={this.handleClose}>
        <div className="header">Please provide info on data</div>
        <div className="content">
          <form className="ui form">
            <div className="field">
              <label>Incident name/bug ID</label>
              <input
                type="text"
                name="name"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="field">
              <label>Incident description</label>
              <textarea
                type="text"
                rows="4"
                name="name"
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </div>
            <div className="field">
              <label>Types</label>
              <Dropdown mode="select" value={this.state.system} onChange={this.handleSystemChange}>
                <i className="dropdown icon" />
                <div className="menu">
                  {R.map(
                    (s) => (
                      <div className="item" data-value={s} key={s}>
                        {s}
                      </div>
                    ),
                    this.systemNames,
                  )}
                  <div className="item" data-value="Others">
                    Others
                  </div>
                </div>
              </Dropdown>
              {showOther && (
                <div className="item">
                  <input
                    type="text"
                    name="other"
                    value={this.state.other}
                    onChange={(e) => this.setState({ other: e.target.value })}
                  />
                </div>
              )}
            </div>
            <div className="field">
              <input
                type="checkbox"
                value={this.state.ownerOnly}
                onChange={(e) => this.setState({ ownerOnly: e.target.checked })}
              />
              &nbsp;&nbsp;Visible to owner only
            </div>
            <div className="field">
              <label>Share group(Comma separated list)</label>
              <input
                type="text"
                name="name"
                placeholder="Shared usernames"
                value={this.state.sharedUsernames}
                onChange={(e) => this.setState({ sharedUsernames: e.target.value })}
              />
            </div>
          </form>
        </div>
        <div className="actions">
          <div className="ui button deny" onClick={this.handleCancel}>
            Cancel
          </div>
          <div className={`ui button approve labeled${disabled ? ' disabled' : ''}`}>
            <div className={`ui button orange submit${disabled ? ' disabled' : ''}`} onClick={this.handleSubmit}>
              Share data
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const EventPublishModal = injectIntl(EventPublishModalCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    return {
      location,
      loadStatus,
      projects,
    };
  },
  {
    createLoadAction,
  },
)(EventPublishModal);
