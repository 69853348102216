/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import { autobind } from 'core-decorators';
import moment from 'moment';
import * as R from 'ramda';
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import fetchPut from '../../../common/apis/fetchPut';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, parseLocation } from '../../../common/utils';
import { Container, AutoSizer, Table, Column, SortDirection, Popover } from '../../../lib/fui/react';
import { appButtonsMessages, appMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';
import { eventMessages } from '../../../common/metric/messages';
import ViewCausalSelectTimeModal from './ViewCausalSelectTimeModal';

type Props = {
  intl: Object,
  updateLastActionInfo: Function,
  isLoading: Boolean,
  credentials: Object,
  // eslint-disable-next-line
  causalGroup: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  errorMessage: ?Object,
  causalIncidentList: Object,
  onSelected: Function,
  onReload: Function,
  location: Object,
  userInfo: Object,
};

class CausalRelationTaskManualList extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      sortBy: null,
      sortDirection: null,
      causalIncidentList: props.causalIncidentList,

      showTimeModal: false,
      activeEvent: null,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { causalIncidentList, location } = this.props;
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(location);
    if (nextQuery.causalGraphVersion !== query.causalGraphVersion) {
      this.setState({ sortBy: null, sortDirection: null });
    }
    if (nextProps.causalIncidentList !== causalIncidentList) {
      this.setState({ causalIncidentList: nextProps.causalIncidentList });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
    let { causalIncidentList } = this.state;
    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        causalIncidentList = R.sortWith([R.ascend(R.prop(sortBy))])(causalIncidentList);
        if (sortDirection === SortDirection.DESC) {
          causalIncidentList = R.sortWith([R.descend(R.prop(sortBy))])(causalIncidentList);
        }
        this.setState({ causalIncidentList });
      }
    }
  }

  @autobind
  handleDateSelect({ rowData }) {
    this.setState({ showTimeModal: true, activeEvent: rowData });
  }

  @autobind
  handleIncidentClick(rowData, state) {
    const { intl, onSelected } = this.props;
    const { rangeStartDate, rangeEndDate, resultStartDate, resultEndDate } = state || {};

    if (rangeStartDate >= rangeEndDate || resultStartDate >= resultEndDate) {
      message.error(intl.formatMessage(appMessages.apiFaild));
    } else {
      const newData = { ...rowData };
      if (rangeStartDate) {
        newData.startTimestamp = rangeStartDate.valueOf();
      }
      if (rangeEndDate) {
        newData.endTimestamp = rangeEndDate.valueOf();
      }
      if (resultStartDate) {
        newData.resultStartstamp = resultStartDate.valueOf();
      }
      if (resultEndDate) {
        newData.resultEndstamp = resultEndDate.valueOf();
      }
      this.setState({ showTimeModal: false, activeEvent: null }, () => {
        onSelected(newData);
      });
    }
  }

  @autobind
  utcTimeRenderer({ cellData }) {
    const time = cellData ? moment.utc(cellData).format(Defaults.TimeFormat) : '';
    return <div>{time}</div>;
  }

  @autobind
  statusDetailsRenderer({ rowData }) {
    const { intl, userInfo } = this.props;
    return (
      <div className="flex-row">
        <Button
          type="primary"
          size="small"
          style={{ marginRight: 8 }}
          onClick={() => {
            this.handleDateSelect({ rowData });
          }}
        >
          {intl.formatMessage(causalMessages.viewCausalGraph)}
        </Button>

        <Popover
          content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
          mouseEnterDelay={0.3}
          placement="left"
        >
          <Popconfirm
            placement="topRight"
            title={
              <div>
                {intl.formatMessage(causalMessages.rerunRelation)}
                <br />
                {intl.formatMessage(appMessages.continueConfirm)}
              </div>
            }
            okText={intl.formatMessage(appButtonsMessages.yes)}
            cancelText={intl.formatMessage(appButtonsMessages.no)}
            onConfirm={this.handleIncidentRerunClick(rowData)}
            onCancel={(event) => event.stopPropagation()}
            disabled={userInfo.isReadUser}
          >
            <Button
              size="small"
              type="primary"
              disabled={userInfo.isReadUser}
              onClick={(event) => event.stopPropagation()}
            >
              {intl.formatMessage(causalMessages.rerunCausal)}
            </Button>
          </Popconfirm>
        </Popover>
      </div>
    );
  }

  @autobind
  controlRenderer({ rowData }) {
    const { intl } = this.props;
    const { lock } = rowData;
    return (
      <div className="flex-row" style={{ justifyContent: 'flex-end' }}>
        <Popconfirm
          placement="topRight"
          title={
            lock ? (
              <div>
                {intl.formatMessage(causalMessages.unlockRelation)}
                <br />
                {intl.formatMessage(appMessages.continueConfirm)}
              </div>
            ) : (
              <div>
                {intl.formatMessage(causalMessages.lockRelation)}
                <br />
                {intl.formatMessage(appMessages.continueConfirm)}
              </div>
            )
          }
          okText={intl.formatMessage(appButtonsMessages.yes)}
          cancelText={intl.formatMessage(appButtonsMessages.no)}
          onConfirm={this.handleIncidentLockClick(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          {lock && (
            <LockOutlined
              style={{ fontSize: 16, margin: '0 4px', color: lock ? 'orange' : 'currentColor' }}
              onClick={(event) => event.stopPropagation()}
            />
          )}
          {!lock && (
            <UnlockOutlined
              style={{ fontSize: 16, margin: '0 4px', color: lock ? 'orange' : 'currentColor' }}
              onClick={(event) => event.stopPropagation()}
            />
          )}
        </Popconfirm>

        <Popconfirm
          placement="topRight"
          title={
            <div>
              {intl.formatMessage(causalMessages.removeRelation)}
              <br />
              {intl.formatMessage(appMessages.continueConfirm)}
            </div>
          }
          okText={intl.formatMessage(appButtonsMessages.yes)}
          cancelText={intl.formatMessage(appButtonsMessages.no)}
          onConfirm={this.handleIncidentRemoveClick(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <DeleteOutlined style={{ fontSize: 16, margin: '0 4px' }} onClick={(event) => event.stopPropagation()} />
        </Popconfirm>
      </div>
    );
  }

  @autobind
  handleIncidentRerunClick(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();

      const { intl, credentials, onReload } = this.props;
      const { causalKey, startTimestamp, endTimestamp, customerName } = rowData;
      this.props.updateLastActionInfo();
      Promise.all([
        fetchPost(
          `${window.BASE_URL || ''}/localcron/relationpreprocess`,
          {
            ...credentials,
            customerName,
            causalKey,
            startTime: startTimestamp,
            endTime: endTimestamp,
            rerunCron: false,
          },
          {},
          false,
        ),
      ])
        .then(() => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          onReload();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    };
  }

  @autobind
  handleIncidentRemoveClick(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();

      const { intl, credentials, onReload } = this.props;
      const { causalKey, customerName, startTimestamp, endTimestamp } = rowData;
      this.props.updateLastActionInfo();
      fetchDelete(
        getEndpoint('relationtask', 2),
        {
          ...credentials,
          causalKey,
          customerName,
          startTime: startTimestamp,
          endTime: endTimestamp,
        },
        {},
        false,
      )
        .then(() => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          onReload();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    };
  }

  @autobind
  handleIncidentLockClick(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();

      const { intl, credentials, onReload } = this.props;
      const { lock, causalKey, customerName, startTimestamp, endTimestamp } = rowData;
      this.props.updateLastActionInfo();
      fetchPut(
        getEndpoint('relationtask', 2),
        {
          ...credentials,
          causalKey,
          customerName,
          startTime: startTimestamp,
          endTime: endTimestamp,
          operation: lock ? 'unlock' : 'lock',
        },
        {},
        false,
      )
        .then(() => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          onReload();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    };
  }

  @autobind
  sort({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  render() {
    const { intl, userInfo } = this.props;
    const { isLoading } = this.props;
    const { sortBy, sortDirection, causalIncidentList } = this.state;

    return (
      <Container className={`full-height ${isLoading ? 'loading ' : ''}`}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              width={width}
              height={height}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={40}
              rowCount={causalIncidentList.length}
              rowGetter={({ index }) => causalIncidentList[index]}
              // onRowClick={this.handleIncidentClick}
              sort={this.sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            >
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.relationStartTime)}
                dataKey="startTimestamp"
                cellRenderer={this.utcTimeRenderer}
                headerRenderer={this.headerRenderer}
              />
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.relationEndTime)}
                dataKey="endTimestamp"
                cellRenderer={this.utcTimeRenderer}
                headerRenderer={this.headerRenderer}
              />
              <Column
                width={120}
                label={intl.formatMessage(causalMessages.status)}
                dataKey="status"
                headerRenderer={this.headerRenderer}
              />
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.requestTime)}
                dataKey="requestTime"
                cellRenderer={this.utcTimeRenderer}
                headerRenderer={this.headerRenderer}
              />
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.finishTime)}
                dataKey="finishTime"
                cellRenderer={this.utcTimeRenderer}
                headerRenderer={this.headerRenderer}
              />
              <Column
                width={240}
                flexGrow={1}
                label={null}
                dataKey="butn"
                cellRenderer={this.statusDetailsRenderer}
                disableSort
              />
              {!userInfo.isReadUser && (
                <Column width={90} label="" cellRenderer={this.controlRenderer} dataKey="id" disableSort />
              )}
            </Table>
          )}
        </AutoSizer>

        {this.state.showTimeModal && (
          <ViewCausalSelectTimeModal
            isOnDemandCausal
            activeEvent={this.state.activeEvent}
            onClose={() => this.setState({ showTimeModal: false, activeEvent: null })}
            onSumbit={(activeEvent, state) => this.handleIncidentClick(activeEvent, state)}
          />
        )}
      </Container>
    );
  }
}

export default CausalRelationTaskManualList;
