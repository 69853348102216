import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { Checkbox, Col, Divider, Input, Row, Tag } from 'antd';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { Popover, Tooltip } from '../../../lib/fui/react';
import { parseLocation } from '../../../common/utils';

import { eventMessages } from '../../../common/metric/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import { setTooltipText, statusOptions } from '../utils/rootCauseTimeLine';

export default function RootCauseRenderSearchBar(props: Object) {
  const {
    intl,
    activeKey,
    filterEventsTotal,
    importantCount,
    likelyRootCauseCount,
    noRootCauseCount,
    onChange,
    showChangeCb,
  } = props || {};
  const { fixedIncidentCount, triggeredCount, patternIdFilterOptions, ignoredPatternIdFilterOptions, globalInfo } =
    props || {};
  const { projectListOptions, anomalyMetricOptions, location, resetPatternFilter, notificationCount } = props || {};
  const { patternIdFilter: propsPatternIdFilter, tabName, incidentStatusCountMap = {} } = props || {};
  const { notValidSrcCount, validSrcCount, ignoredSrcCount, compositeAnomalyCount } = props || {};
  const { componentListOptions = [], instanceListOptions = [], podListOptions = [], hasKubernetes } = props || {};
  const { instanceFilter: propsInstanceFilter, logicPodID: propsLogicPodID, containerListOptions = [] } = props || {};
  const { filterContainer: propsFilterContainer, componentFilter: propsComponentFilter } = props || {};
  const { recurrentIncidentCount } = props || {};
  const { environmentId, systemId, customerName, startTime, endTime } = parseLocation(location);
  const environment = R.find((e) => e.id === environmentId, globalInfo || []);
  const systemList = get(environment, 'systemList', []);
  const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    keywordFilter: undefined,
    rootCauseFilter: [],
    patternIdsFilter: undefined,
    patternIdFilter: [],
    typeFilter: [],
    projectsFilter: undefined,
    projectFilter: [],
    componentsFilter: undefined,
    componentFilter: [],
    instancesFilter: undefined,
    instanceFilter: [],
    podsFilter: undefined,
    podFilter: [],
    containersFilter: undefined,
    containerFilter: [],
    metricsFilter: undefined,
    metricFilter: [],
    incidentStatus: [],

    incidentStatusClose: true,
    rootCauseClose: true,
    componentClose: true,
    instanceClose: true,
    podClose: true,
    containerClose: true,
    typeClose: true,
    patternIdClose: true,
    projectClose: true,
    metricClose: true,
  });

  const typeList = [
    { label: 'Hot', value: 'hot' },
    { label: 'New', value: 'newpattern' },
    { label: 'Cold', value: 'cold' },
    { label: intl.formatMessage(logMessages.keywordAlerts), value: 'whitelist' },
    { label: 'Rare', value: 'rare' },
  ];

  const { keywordFilter, rootCauseFilter, patternIdsFilter, patternIdFilter, typeFilter, projectsFilter } = state;
  const { projectFilter, metricsFilter, metricFilter, incidentStatus, componentsFilter, componentFilter } = state;
  const { instancesFilter, instanceFilter, podsFilter, podFilter, containersFilter, containerFilter } = state;
  const { incidentStatusClose, rootCauseClose, componentClose, instanceClose, podClose, containerClose } = state;
  const { typeClose, patternIdClose, projectClose, metricClose } = state;
  const isIgnoreFilter = R.includes('ignoredSrc', rootCauseFilter);

  useEffect(() => {
    setState({ patternIdsFilter: undefined, patternIdFilter: [] });
  }, [resetPatternFilter]);

  useEffect(() => {
    setState({
      keywordFilter: undefined,
      rootCauseFilter: [],
      patternIdsFilter: undefined,
      patternIdFilter: [],
      typeFilter: [],
      projectsFilter: undefined,
      projectFilter: [],
      componentsFilter: undefined,
      componentFilter: [],
      instancesFilter: undefined,
      instanceFilter: [],
      podsFilter: undefined,
      podFilter: [],
      containersFilter: undefined,
      containerFilter: [],
      metricsFilter: undefined,
      metricFilter: [],
      incidentStatus: [],

      incidentStatusClose: true,
      rootCauseClose: true,
      componentClose: true,
      instanceClose: true,
      podClose: true,
      containerClose: true,
      typeClose: true,
      patternIdClose: true,
      projectClose: true,
      metricClose: true,
    });
  }, [environmentId, systemId, customerName, startTime, endTime]);

  useEffect(() => {
    setState({ componentFilter: propsComponentFilter ? R.split(',', propsComponentFilter) : [] });
  }, [propsComponentFilter]);

  useEffect(() => {
    setState({ patternIdFilter: propsPatternIdFilter ? R.split(',', propsPatternIdFilter) : [] });
  }, [propsPatternIdFilter]);

  useEffect(() => {
    setState({ instanceFilter: propsInstanceFilter ? R.split(',', propsInstanceFilter) : [] });
  }, [propsInstanceFilter]);

  useEffect(() => {
    setState({ podFilter: propsLogicPodID ? R.split(',', propsLogicPodID) : [] });
  }, [propsLogicPodID]);

  useEffect(() => {
    setState({ containerFilter: propsFilterContainer ? R.split(',', propsFilterContainer) : [] });
  }, [propsFilterContainer]);

  let tabTitle = eventMessages.incidentCategory;
  if (activeKey === 'logAlert') {
    tabTitle = eventMessages.alertCategory;
  } else if (activeKey === 'metric') {
    tabTitle = eventMessages.metricCategory;
  } else if (activeKey === 'logAnomaly') {
    tabTitle = eventMessages.logCategory;
  } else if (activeKey === 'trace') {
    tabTitle = eventMessages.traceCategory;
  } else if (activeKey === 'deployment') {
    tabTitle = eventMessages.changeCategory;
  }

  const patternIdOptions = isIgnoreFilter ? ignoredPatternIdFilterOptions : patternIdFilterOptions || [];
  let newPatternIdFilterOptions = patternIdOptions;
  if (patternIdsFilter) {
    newPatternIdFilterOptions = R.filter(
      (event) => R.toLower(event?.label || '').includes(R.toLower(patternIdsFilter || '')),
      newPatternIdFilterOptions,
    );
  }

  let newProjectListOptions = projectListOptions || [];
  if (projectsFilter) {
    newProjectListOptions = R.filter(
      (event) => R.toLower(event?.label || '').includes(R.toLower(projectsFilter || '')),
      newProjectListOptions,
    );
  }

  let newComponentListOptions = componentListOptions || [];
  if (componentsFilter) {
    newComponentListOptions = R.filter(
      (event) => R.toLower(event?.label || '').includes(R.toLower(componentsFilter || '')),
      newComponentListOptions,
    );
  }

  let newInstanceListOptions = instanceListOptions || [];
  if (instancesFilter) {
    newInstanceListOptions = R.filter((event) => {
      const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, event?.label, {
        pn: event?.projectName,
        owner: event?.projectOwner,
      });
      return (
        R.toLower(event?.label || '').includes(R.toLower(instancesFilter || '')) ||
        R.toLower(instanceDisplayName || '').includes(R.toLower(instancesFilter || ''))
      );
    }, newInstanceListOptions);
  }

  let newPodListOptions = podListOptions || [];
  if (podsFilter) {
    newPodListOptions = R.filter((event) => {
      const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, event?.label, {
        pn: event?.projectName,
        owner: event?.projectOwner,
      });
      return (
        R.toLower(event?.label || '').includes(R.toLower(podsFilter || '')) ||
        R.toLower(instanceDisplayName || '').includes(R.toLower(podsFilter || ''))
      );
    }, newPodListOptions);
  }

  let newContainerListOptions = containerListOptions || [];
  if (containersFilter) {
    newContainerListOptions = R.filter(
      (event) => R.toLower(event?.label || '').includes(R.toLower(containersFilter || '')),
      newContainerListOptions,
    );
  }

  let newAnomalyMetricOptions = anomalyMetricOptions || [];
  if (metricsFilter) {
    newAnomalyMetricOptions = R.filter(
      (event) => R.toLower(event?.label || '').includes(R.toLower(metricsFilter || '')),
      newAnomalyMetricOptions,
    );
  }

  const getGroupValuespecial = (value, flag) => {
    let newValue;
    if (value.length === 1) {
      newValue = value[0];
    } else if (value.length > 1) {
      newValue = value[value.length - 1];
    }
    if (flag) {
      return newValue ? [newValue] : [];
    } else {
      return newValue;
    }
  };

  const getGroupValue = (value, flag) => {
    if (flag) {
      return value;
    } else {
      return value.length > 0 ? R.join(',', value) : undefined;
    }
  };

  const closeRender = (key) => {
    return (
      <div className="clickable" onClick={() => setState({ [key]: !state[key] })}>
        {state[key] ? <DownOutlined style={{ fontSize: 14 }} /> : <UpOutlined style={{ fontSize: 14 }} />}
      </div>
    );
  };
  if (showChangeCb) {
    let count = 0;
    let headCount = 0;
    if (projectListOptions.length > 0) {
      headCount += 1;
      if (!projectClose) {
        count += newProjectListOptions.length;
      }
    }
    if (tabName === 'incident') {
      headCount += 1;
      if (!incidentStatusClose) {
        count += statusOptions.length;
      }
    }
    headCount += 1;
    if (rootCauseFilter.length > 0) {
      count += 6;
      if (activeKey === 'incident') {
        count += 6;
      }
    }
    if (componentListOptions.length > 0) {
      headCount += 1;
      if (!componentClose) {
        count += newComponentListOptions.length;
      }
    }
    if (instanceListOptions.length > 0) {
      headCount += 1;
      if (!instanceClose) {
        count += newInstanceListOptions.length;
      }
    }
    if (podListOptions.length > 0 && hasKubernetes) {
      headCount += 1;
      if (!podClose) {
        count += newPodListOptions.length;
      }
    }
    if (containerListOptions.length > 0 && podFilter.length > 0) {
      headCount += 1;
      if (!containerClose) {
        count += newContainerListOptions.length;
      }
    }
    if (R.includes(activeKey, ['logAnomaly', 'logAlert'])) {
      headCount += 1;
      if (!typeClose) {
        count += typeList.length;
      }
    }
    if (patternIdOptions.length > 0) {
      headCount += 1;
      if (!patternIdClose) {
        count += newPatternIdFilterOptions.length;
      }
    }
    if (activeKey !== 'logAnomaly' && anomalyMetricOptions.length > 0) {
      headCount += 1;
      if (!metricClose) {
        count += newAnomalyMetricOptions.length;
      }
    }
    showChangeCb({ childCount: count, headCount });
  }

  return (
    <div className="block flex-grow flex-col flex-min-height flex-min-width content-bg full-height">
      <div className="flex-col full-height">
        <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
          <span className="label" style={{ fontSize: 14 }}>
            {intl.formatMessage(eventMessages.shortDescriptionKeywordSearch)}
          </span>
          <div className="flex-grow" />
        </div>
        <Input.Search
          size="small"
          placeholder={intl.formatMessage(eventMessages.shortDescriptionKeywordSearch)}
          value={keywordFilter}
          allowClear
          enterButton
          onSearch={(value) => onChange({ type: 'keywordFilter', value })}
          onChange={(e) => setState({ keywordFilter: e.target.value })}
        />
        <Divider style={{ margin: '16px 0 8px 0' }} />

        {projectListOptions.length > 0 && (
          <>
            <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
              <span className="label" style={{ fontSize: 14 }}>
                {intl.formatMessage(appFieldsMessages.project)}
              </span>
              <div className="flex-grow" />
              {closeRender('projectClose')}
            </div>
            {!projectClose && (
              <>
                <Input.Search
                  allowClear
                  style={{ width: '100%', marginBottom: 4 }}
                  size="small"
                  placeholder={intl.formatMessage(appFieldsMessages.project)}
                  value={projectsFilter}
                  onSearch={(projectsFilter) => setState({ projectsFilter })}
                  onChange={(e) => {
                    setState({ projectsFilter: e.target.value });
                  }}
                />
                <Checkbox.Group
                  value={projectFilter}
                  onChange={(value) => {
                    setState({ projectFilter: getGroupValue(value, true) });
                    onChange({ type: 'projectFilter', value: getGroupValue(value) });
                  }}
                >
                  <Row>
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Col span={24} key={item.value} className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                          <Checkbox value={item.value} />
                          <span
                            className="flex-grow flex-row flex-center-align overflow-hidden"
                            style={{ marginLeft: 8 }}
                          >
                            <Popover content={item.projectDisplayName} mouseEnterDelay={0.3} placement="top">
                              <span className="hidden-line-with-ellipsis flex-grow">{item.projectDisplayName}</span>
                            </Popover>
                            <Tag
                              size="small"
                              style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                            >
                              {item.count}
                            </Tag>
                          </span>
                        </Col>
                      );
                    }, newProjectListOptions)}
                  </Row>
                </Checkbox.Group>
              </>
            )}

            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        )}

        {tabName === 'incident' && (
          <>
            <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
              <span className="label" style={{ fontSize: 14 }}>
                {intl.formatMessage(eventMessages.incidentStatus)}
              </span>
              <div className="flex-grow" />
              {closeRender('incidentStatusClose')}
            </div>
            {!incidentStatusClose && (
              <Checkbox.Group
                value={incidentStatus}
                onChange={(value) => {
                  setState({ incidentStatus: getGroupValue(value, true) });
                  onChange({ type: 'incidentStatus', value: getGroupValue(value) });
                }}
              >
                <Row>
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Col span={24} key={item.value} className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                        <Checkbox value={item.value} />
                        <span
                          className="flex-grow flex-row flex-center-align overflow-hidden"
                          style={{ marginLeft: 8 }}
                        >
                          <span className="hidden-line-with-ellipsis flex-grow">{item.label}</span>
                          <Tag
                            size="small"
                            style={{
                              borderRadius: 100,
                              marginLeft: 8,
                              fontWeight: 'bold',
                              cursor: 'default',
                              color: item.color,
                            }}
                          >
                            {incidentStatusCountMap[item.value] || 0}
                          </Tag>
                        </span>
                      </Col>
                    );
                  }, statusOptions || [])}
                </Row>
              </Checkbox.Group>
            )}

            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        )}

        <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
          <span className="label" style={{ fontSize: 14 }}>
            {intl.formatMessage(tabTitle)}
          </span>
          <div className="flex-grow" />
          {closeRender('rootCauseClose')}
        </div>
        {!rootCauseClose && (
          <Checkbox.Group
            value={rootCauseFilter}
            onChange={(value) => {
              setState({ rootCauseFilter: getGroupValue(value, true) });
              onChange({ type: 'rootCauseFilter', value: getGroupValue(value) });
            }}
          >
            <Row>
              <Col span={24} key="all" className="flex-row flex-center-align">
                <Checkbox value="all" />
                <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                  <span className="hidden-line-with-ellipsis flex-grow">{intl.formatMessage(eventMessages.all)}</span>
                  <Tag size="small" style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}>
                    {filterEventsTotal}
                  </Tag>
                </span>
              </Col>
              <Col span={24} key="important" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                <Checkbox value="important" />
                <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                  <span className="hidden-line-with-ellipsis flex-grow">
                    {intl.formatMessage(eventMessages.important)}
                  </span>
                  <Tag
                    size="small"
                    style={{
                      borderRadius: 100,
                      marginLeft: 8,
                      fontWeight: 'bold',
                      cursor: 'default',
                      color: '#ff7c6b',
                    }}
                  >
                    {importantCount}
                  </Tag>
                </span>
              </Col>

              <Col span={24} key="hasRootCause" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                <Checkbox value="hasRootCause" />
                <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                  <span className="hidden-line-with-ellipsis flex-grow">
                    {intl.formatMessage(eventMessages.hasRootCauses)}
                  </span>
                  <Tag size="small" style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}>
                    {likelyRootCauseCount}
                  </Tag>
                </span>
              </Col>
              <Col span={24} key="noRootCause" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                <Checkbox value="noRootCause" />
                <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                  <span className="hidden-line-with-ellipsis flex-grow">
                    {intl.formatMessage(eventMessages.noRootCauses)}
                  </span>
                  <Tag size="small" style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}>
                    {noRootCauseCount}
                  </Tag>
                </span>
              </Col>
              {activeKey === 'incident' && (
                <Col span={24} key="fixedIncident" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                  <Checkbox value="fixedIncident" />
                  <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                    <span className="hidden-line-with-ellipsis flex-grow">
                      {intl.formatMessage(eventMessages.fixedIncidents)}
                    </span>
                    <Tag
                      size="small"
                      style={{
                        borderRadius: 100,
                        marginLeft: 8,
                        fontWeight: 'bold',
                        cursor: 'default',
                        color: '#ff5142',
                      }}
                    >
                      {fixedIncidentCount}
                    </Tag>
                  </span>
                </Col>
              )}
              <Col span={24} key="triggered" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                <Checkbox value="triggered" />
                <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                  <span className="hidden-line-with-ellipsis flex-grow">
                    {intl.formatMessage(eventMessages.actionsTriggered)}
                  </span>
                  <Tag
                    size="small"
                    style={{
                      borderRadius: 100,
                      marginLeft: 8,
                      fontWeight: 'bold',
                      cursor: 'default',
                      color: '#037AEF',
                    }}
                  >
                    {triggeredCount}
                  </Tag>
                </span>
              </Col>
              <Col span={24} key="notification" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                <Checkbox value="notification" />
                <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                  <span className="hidden-line-with-ellipsis flex-grow">
                    {intl.formatMessage(eventMessages.notificationsSent)}
                  </span>
                  <Tag size="small" style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}>
                    {notificationCount}
                  </Tag>
                </span>
              </Col>
              {activeKey === 'incident' && (
                <Col span={24} key="validSrc" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                  <Checkbox value="validSrc" />
                  <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                    <span className="hidden-line-with-ellipsis flex-grow">
                      {intl.formatMessage(logMessages.validIncidents)}
                    </span>
                    <Tag
                      size="small"
                      style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                    >
                      {validSrcCount}
                    </Tag>
                  </span>
                </Col>
              )}
              {activeKey === 'incident' && (
                <Col span={24} key="notValidSrc" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                  <Checkbox value="notValidSrc" />
                  <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                    <span className="hidden-line-with-ellipsis flex-grow">
                      {intl.formatMessage(logMessages.invalidIncidents)}
                    </span>
                    <Tag
                      size="small"
                      style={{
                        borderRadius: 100,
                        marginLeft: 8,
                        fontWeight: 'bold',
                        cursor: 'default',
                        color: 'gray',
                      }}
                    >
                      {notValidSrcCount}
                    </Tag>
                  </span>
                </Col>
              )}
              {activeKey === 'incident' && (
                <Col span={24} key="ignoredSrc" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                  <Checkbox value="ignoredSrc" />
                  <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                    <span className="hidden-line-with-ellipsis flex-grow">
                      {intl.formatMessage(logMessages.ignoredIncidents)}
                    </span>
                    <Tag
                      size="small"
                      style={{
                        borderRadius: 100,
                        marginLeft: 8,
                        fontWeight: 'bold',
                        cursor: 'default',
                        color: 'gray',
                      }}
                    >
                      {ignoredSrcCount}
                    </Tag>
                  </span>
                </Col>
              )}
              {activeKey === 'incident' && (
                <Col span={24} key="compositeAnomaly" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                  <Checkbox value="compositeAnomaly" />
                  <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                    <span className="hidden-line-with-ellipsis flex-grow">
                      {intl.formatMessage(eventMessages.compositeIncidents)}
                    </span>
                    <Tag
                      size="small"
                      style={{
                        borderRadius: 100,
                        marginLeft: 8,
                        fontWeight: 'bold',
                        cursor: 'default',
                        color: '#FF5142',
                      }}
                    >
                      {compositeAnomalyCount}
                    </Tag>
                  </span>
                </Col>
              )}
              {activeKey === 'incident' && (
                <Col span={24} key="recurrentIncident" className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                  <Checkbox value="recurrentIncident" />
                  <span className="flex-grow flex-row flex-center-align overflow-hidden" style={{ marginLeft: 8 }}>
                    <span className="hidden-line-with-ellipsis flex-grow">
                      {intl.formatMessage(eventMessages.recurrentIncidents)}
                    </span>
                    <Tag
                      size="small"
                      style={{
                        borderRadius: 100,
                        marginLeft: 8,
                        fontWeight: 'bold',
                        cursor: 'default',
                        color: '#FF5142',
                      }}
                    >
                      {recurrentIncidentCount}
                    </Tag>
                  </span>
                </Col>
              )}
            </Row>
          </Checkbox.Group>
        )}

        <Divider style={{ margin: '16px 0 8px 0' }} />

        {componentListOptions.length > 0 && (
          <>
            <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
              <span className="label" style={{ fontSize: 14 }}>
                {intl.formatMessage(appFieldsMessages.impactedComponents)}
              </span>
              <div className="flex-grow" />
              {closeRender('componentClose')}
            </div>
            {!componentClose && (
              <>
                <Input.Search
                  allowClear
                  style={{ width: '100%', marginBottom: 4 }}
                  size="small"
                  placeholder={intl.formatMessage(appFieldsMessages.impactedComponents)}
                  value={componentsFilter}
                  onSearch={(componentsFilter) => setState({ componentsFilter })}
                  onChange={(e) => {
                    setState({ componentsFilter: e.target.value });
                  }}
                />
                <Checkbox.Group
                  value={componentFilter}
                  onChange={(value) => {
                    setState({ componentFilter: getGroupValue(value, true) });
                    onChange({ type: 'componentFilter', value: getGroupValue(value) });
                  }}
                >
                  <Row>
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Col span={24} key={item.value} className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                          <Checkbox value={item.value} />
                          <span
                            className="flex-grow flex-row flex-center-align overflow-hidden"
                            style={{ marginLeft: 8 }}
                          >
                            <Popover content={item.label} mouseEnterDelay={0.3} placement="top">
                              <span className="hidden-line-with-ellipsis flex-grow">{item.label}</span>
                            </Popover>
                            <Tag
                              size="small"
                              style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                            >
                              {item.count}
                            </Tag>
                          </span>
                        </Col>
                      );
                    }, newComponentListOptions)}
                  </Row>
                </Checkbox.Group>
              </>
            )}

            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        )}

        {instanceListOptions.length > 0 && (
          <>
            <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
              <span className="label" style={{ fontSize: 14 }}>
                {intl.formatMessage(appFieldsMessages.impactedInstances)}
              </span>
              <div className="flex-grow" />
              {closeRender('instanceClose')}
            </div>
            {!instanceClose && (
              <>
                <Input.Search
                  allowClear
                  style={{ width: '100%', marginBottom: 4 }}
                  size="small"
                  placeholder={intl.formatMessage(appFieldsMessages.impactedInstances)}
                  value={instancesFilter}
                  onSearch={(instancesFilter) => setState({ instancesFilter })}
                  onChange={(e) => {
                    setState({ instancesFilter: e.target.value });
                  }}
                />
                <Checkbox.Group
                  value={instanceFilter}
                  onChange={(value) => {
                    setState({ instanceFilter: getGroupValue(value, true) });
                    onChange({ type: 'instanceFilter', value: getGroupValue(value) });
                  }}
                >
                  <Row>
                    {R.addIndex(R.map)((item, index) => {
                      const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, item?.label, {
                        pn: item?.projectName,
                        owner: item?.projectOwner,
                      });
                      const width = instanceDisplayName ? 140 : 100;
                      return (
                        <Col span={24} key={item.value} className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                          <Checkbox value={item.value} />
                          <span
                            className="flex-grow flex-row flex-center-align overflow-hidden"
                            style={{ marginLeft: 8 }}
                          >
                            <Popover
                              content={
                                <div
                                  className="flex-col"
                                  style={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}
                                >
                                  <div className="flex-row">
                                    <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                                      {intl.formatMessage(eventMessages.instanceName)}
                                      :&nbsp;
                                    </div>
                                    {item.label}
                                  </div>
                                  {instanceDisplayName && (
                                    <div className="flex-row">
                                      <div className="light-label bold" style={{ width: 140, flexShrink: 0 }}>
                                        {intl.formatMessage(appFieldsMessages.instanceDisplayName)}
                                        :&nbsp;
                                      </div>
                                      {instanceDisplayName}
                                    </div>
                                  )}
                                </div>
                              }
                              mouseEnterDelay={0.3}
                              placement="top"
                            >
                              <span className="hidden-line-with-ellipsis flex-grow">
                                {instanceDisplayName || item.label}
                              </span>
                            </Popover>
                            <Tag
                              size="small"
                              style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                            >
                              {item.count}
                            </Tag>
                          </span>
                        </Col>
                      );
                    }, newInstanceListOptions)}
                  </Row>
                </Checkbox.Group>
              </>
            )}
            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        )}

        {podListOptions.length > 0 && hasKubernetes && (
          <>
            <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
              <span className="label" style={{ fontSize: 14 }}>
                {intl.formatMessage(appFieldsMessages.impactedPods)}
              </span>
              <div className="flex-grow" />
              {closeRender('podClose')}
            </div>
            {!podClose && (
              <>
                <Input.Search
                  allowClear
                  style={{ width: '100%', marginBottom: 4 }}
                  size="small"
                  placeholder={intl.formatMessage(appFieldsMessages.impactedPods)}
                  value={podsFilter}
                  onSearch={(podsFilter) => setState({ podsFilter })}
                  onChange={(e) => {
                    setState({ podsFilter: e.target.value });
                  }}
                />
                <Checkbox.Group
                  value={podFilter}
                  onChange={(value) => {
                    setState({ podFilter: getGroupValue(value, true) });
                    onChange({ type: 'podFilter', value: getGroupValue(value) });
                  }}
                >
                  <Row>
                    {R.addIndex(R.map)((item, index) => {
                      const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, item?.label, {
                        pn: item?.projectName,
                        owner: item?.projectOwner,
                      });

                      const width = instanceDisplayName ? 140 : 100;
                      return (
                        <Col span={24} key={item.value} className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                          <Checkbox value={item.value} />
                          <span
                            className="flex-grow flex-row flex-center-align overflow-hidden"
                            style={{ marginLeft: 8 }}
                          >
                            <Popover
                              content={
                                <div
                                  className="flex-col"
                                  style={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}
                                >
                                  <div className="flex-row">
                                    <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                                      {intl.formatMessage(appFieldsMessages.logicPodID)}
                                      :&nbsp;
                                    </div>
                                    {item.label}
                                  </div>
                                  {instanceDisplayName && (
                                    <div className="flex-row">
                                      <div className="light-label bold" style={{ width: 140, flexShrink: 0 }}>
                                        {intl.formatMessage(appFieldsMessages.instanceDisplayName)}
                                        :&nbsp;
                                      </div>
                                      {instanceDisplayName}
                                    </div>
                                  )}
                                </div>
                              }
                              mouseEnterDelay={0.3}
                              placement="top"
                            >
                              <span className="hidden-line-with-ellipsis flex-grow">
                                {instanceDisplayName || item.label}
                              </span>
                            </Popover>
                            <Tag
                              size="small"
                              style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                            >
                              {item.count}
                            </Tag>
                          </span>
                        </Col>
                      );
                    }, newPodListOptions)}
                  </Row>
                </Checkbox.Group>
              </>
            )}
            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        )}

        {containerListOptions.length > 0 && podFilter.length > 0 && (
          <>
            <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
              <span className="label" style={{ fontSize: 14 }}>
                {intl.formatMessage(appFieldsMessages.impactedContainers)}
              </span>
              <div className="flex-grow" />
              {closeRender('containerClose')}
            </div>
            {!containerClose && (
              <>
                <Input.Search
                  allowClear
                  style={{ width: '100%', marginBottom: 4 }}
                  size="small"
                  placeholder={intl.formatMessage(appFieldsMessages.impactedContainers)}
                  value={containersFilter}
                  onSearch={(containersFilter) => setState({ containersFilter })}
                  onChange={(e) => {
                    setState({ containersFilter: e.target.value });
                  }}
                />
                <Checkbox.Group
                  value={containerFilter}
                  onChange={(value) => {
                    setState({ containerFilter: getGroupValue(value, true) });
                    onChange({ type: 'containerFilter', value: getGroupValue(value) });
                  }}
                  disabled={hasKubernetes ? podFilter.length === 0 : false}
                >
                  <Row>
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Col span={24} key={item.value} className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                          <Checkbox value={item.value} />
                          <span
                            className="flex-grow flex-row flex-center-align overflow-hidden"
                            style={{ marginLeft: 8 }}
                          >
                            <Popover content={item.label} mouseEnterDelay={0.3} placement="top">
                              <span className="hidden-line-with-ellipsis flex-grow">{item.label}</span>
                            </Popover>
                          </span>
                        </Col>
                      );
                    }, newContainerListOptions)}
                  </Row>
                </Checkbox.Group>
              </>
            )}

            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        )}

        {R.includes(activeKey, ['logAnomaly', 'logAlert']) && (
          <>
            <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
              <span className="label" style={{ fontSize: 14 }}>
                {intl.formatMessage(appFieldsMessages.type)}
              </span>
              <div className="flex-grow" />
              {closeRender('typeClose')}
            </div>
            {!typeClose && (
              <Checkbox.Group
                value={typeFilter}
                onChange={(value) => {
                  setState({ typeFilter: getGroupValue(value, true) });
                  onChange({ type: 'typeFilter', value: getGroupValue(value) });
                }}
              >
                <Row>
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Col
                        span={24}
                        key={item.value}
                        className="flex-row flex-center-align"
                        style={{ ...(index === 0 ? {} : { marginTop: 4 }) }}
                      >
                        <Checkbox value={item.value} />
                        <Tooltip title={setTooltipText(intl, item.value)} placement="right">
                          <span style={{ marginLeft: 8 }}>{item.label}</span>
                        </Tooltip>
                      </Col>
                    );
                  }, typeList)}
                </Row>
              </Checkbox.Group>
            )}

            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        )}

        {patternIdOptions.length > 0 && (
          <>
            <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
              <span className="label" style={{ fontSize: 14 }}>
                {intl.formatMessage(appFieldsMessages.patternIdName)}
              </span>
              <div className="flex-grow" />
              {closeRender('patternIdClose')}
            </div>
            {!patternIdClose && (
              <>
                <Input.Search
                  allowClear
                  style={{ width: '100%', marginBottom: 4 }}
                  size="small"
                  placeholder={intl.formatMessage(appFieldsMessages.patternIdName)}
                  value={patternIdsFilter}
                  onSearch={(patternIdsFilter) => setState({ patternIdsFilter })}
                  onChange={(e) => {
                    setState({ patternIdsFilter: e.target.value });
                  }}
                />
                <Checkbox.Group
                  value={patternIdFilter}
                  onChange={(value) => {
                    // setState({ patternIdFilter: getGroupValue(value, true) });
                    onChange({ type: 'patternIdFilter', value: getGroupValue(value) });
                  }}
                >
                  <Row>
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Col span={24} key={item.value} className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                          <Checkbox value={item.value} />
                          <span
                            className="flex-grow flex-row flex-center-align overflow-hidden"
                            style={{ marginLeft: 8 }}
                          >
                            <Popover content={item.label} mouseEnterDelay={0.3} placement="top">
                              <span className="hidden-line-with-ellipsis flex-grow">{item.label}</span>
                            </Popover>
                            <Tag
                              size="small"
                              style={{ borderRadius: 100, marginLeft: 8, fontWeight: 'bold', cursor: 'default' }}
                            >
                              {item.count}
                            </Tag>
                          </span>
                        </Col>
                      );
                    }, newPatternIdFilterOptions)}
                  </Row>
                </Checkbox.Group>
              </>
            )}

            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        )}

        {activeKey !== 'logAnomaly' && anomalyMetricOptions.length > 0 && (
          <>
            <div className="flex-row flex-center-alignt" style={{ marginBottom: 4, alignItems: 'center' }}>
              <span className="label" style={{ fontSize: 14 }}>
                {intl.formatMessage(appFieldsMessages.metric)}
              </span>
              <div className="flex-grow" />
              {closeRender('metricClose')}
            </div>
            {!metricClose && (
              <>
                <Input.Search
                  allowClear
                  style={{ width: '100%', marginBottom: 4 }}
                  size="small"
                  placeholder={intl.formatMessage(appFieldsMessages.metric)}
                  value={metricsFilter}
                  onSearch={(metricsFilter) => setState({ metricsFilter })}
                  onChange={(e) => {
                    setState({ metricsFilter: e.target.value });
                  }}
                />
                <Checkbox.Group
                  value={metricFilter}
                  onChange={(value) => {
                    setState({ metricFilter: getGroupValue(value, true) });
                    onChange({ type: 'metricFilter', value: getGroupValue(value) });
                  }}
                >
                  <Row>
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Col span={24} key={item.value} className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                          <Checkbox value={item.value} />
                          <span className="flex-grow hidden-line-with-ellipsis" style={{ marginLeft: 8 }}>
                            <Popover content={item.label} mouseEnterDelay={0.3} placement="top">
                              {item.label}
                            </Popover>
                          </span>
                        </Col>
                      );
                    }, newAnomalyMetricOptions)}
                  </Row>
                </Checkbox.Group>
              </>
            )}

            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        )}

        <div style={{ padding: 8 }} />
      </div>
    </div>
  );
}
