/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

// test precommit

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import * as CryptoJS from 'crypto-js';
import { get, isInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Modal,
  Button,
  Select,
  Checkbox,
  message,
  Input as AntInput,
  Form,
  InputNumber,
  DatePicker,
  Row,
  Col,
  Spin,
} from 'antd';

import { CaretDownOutlined, CaretUpOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import fetchPostForm from '../../../../common/apis/fetchPostForm';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { State } from '../../../../common/types';
import { Options, Defaults } from '../../../../common/utils';
import { Container, AutoSizer, Table, Column, Popover, SortDirection } from '../../../../lib/fui/react';
import { loadProjectHoliday, saveProjectHoliday } from '../../../../common/settings/actions';
import { showAppAlert, updateLastActionInfo } from '../../../../common/app/actions';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../../common/app/messages';

import HolidayModal from './HolidayModal';
import MaintenanceDayModal from './MaintenanceDayModal';
import { settingsMessages } from '../../../../common/settings/messages';
import { eventActionMessages } from '../../../../common/metric/messages';
import fetchDelete from '../../../../common/apis/fetchDelete';

const { Item } = Form;

const GroupBox = {
  border: '1px dashed var(--border-color-split)',
  padding: 20,
  borderRadius: 12,
  marginBottom: 40,
};

type Props = {
  intl: Object,
  currentLoadingComponents: Object,
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  data: Object,
  refreshTime: Number,

  credentials: Object,
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  holidayList: Array<Object>,
  projectSettings: Object,

  // eslint-disable-next-line
  showAppAlert: Function,
  updateLastActionInfo: Function,
  saveProjectSettings: Function,
  loadProjectHoliday: Function,
  saveProjectHoliday: Function,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
};

class GeneralSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl, systemsMap, projects, currentProject } = props;

    this.retentionTimeLoader = 'settings_retention_time_submit';
    this.metricModelSpanLoader = 'settings_metricModelSpanLoader_submit';
    this.holidayLoader = 'settings_holiday_submit';
    const minValidModelSpan = get(props, ['projectSettings', 'minValidModelSpan']);

    const [samplingInterval, samplingUnit] = this.handleUnit(get(props, ['projectSettings', 'samplingInterval'], 0));
    this.state = {
      activeKey: '0',

      systemId: currentProject.systemId,
      isLoading: false,

      fillNaNZero: get(props, ['projectSettings', 'fillNaNZero'], false),
      trainingFilter: get(props, ['projectSettings', 'trainingFilter'], false),
      minValidModelSpan: get(props, ['projectSettings', 'minValidModelSpan'], ''),
      modelSpan: get(props, ['projectSettings', 'modelSpan']),
      linkedLogProjects: get(props, ['projectSettings', 'linkedLogProjects'], []),
      shouldConfigSamplingInterval: get(props, ['projectSettings', 'shouldConfigSamplingInterval']),
      samplingInterval,
      samplingUnit,
      samplingUnitGoogleCost: 3600,
      trainingStartTime: get(props, ['projectSettings', 'modelSpanRangeStr', 'trainingStartTime']) || null,
      trainingEndTime: get(props, ['projectSettings', 'modelSpanRangeStr', 'trainingEndTime']) || null,
      prettyJsonConvertorFlag: get(props, ['projectSettings', 'prettyJsonConvertorFlag'], false),
      multiLineFlag: get(props, ['projectSettings', 'multiLineFlag'], false),
      oldValMap: {
        trainingFilter: get(props, ['projectSettings', 'trainingFilter']),
        modelSpan: get(props, ['projectSettings', 'modelSpan']),
        linkedLogProjects: get(props, ['projectSettings', 'linkedLogProjects'], []),
        samplingInterval: get(props, ['projectSettings', 'samplingInterval']),
        samplingUnitGoogleCost: 3600,
        minValidModelSpan: minValidModelSpan ? Number(minValidModelSpan) * 60000 : '',
        prettyJsonConvertorFlag: get(props, ['projectSettings', 'prettyJsonConvertorFlag'], false),
        multiLineFlag: get(props, ['projectSettings', 'multiLineFlag'], false),
        modelSpanRangeStr: JSON.stringify(get(props, ['projectSettings', 'modelSpanRangeStr'], '{}')),
      },
      showHolidayModal: false,
      defaultTimezone: get(currentProject, 'timezone', 'default'),

      isMaintenanceDayLoading: false,
      maintenanceDays: [],
      showMaintenanceDayModal: false,

      isRemoveCheckedAll: false,
      sortBy: 'key',
      sortDirection: 'ASC',

      isHolidaysCheckedAll: false,
      holidaysLoading: false,
      activeHoliday: null,
    };
    this.localSystemId = currentProject.systemId;
    const systemOptions = R.uniqBy(
      (n) => n.systemId,
      R.sortWith(
        [R.ascend(R.compose(R.toLower, R.prop('systemName')))],
        R.filter((item) => item.owner && item.systemId && item.systemName, R.values(systemsMap)),
      ),
    );
    this.systemOptions = [
      { systemId: 'emptySystem', systemName: intl.formatMessage(settingsMessages.noSystem) },
      ...systemOptions,
    ];
    this.projectOptions = R.map(
      (item) => ({ value: item.projectShortName, label: item.projectShortName }),
      R.filter((item) => item.systemId === currentProject.systemId && !item.isMetric, projects || []),
    );
    this.settingFieldList = [
      'fillNaNZero',
      'trainingFilter',
      'minValidModelSpan',
      'modelSpan',
      'linkedLogProjects',
      'shouldConfigSamplingInterval',
      'samplingInterval',
      'samplingUnit',
      'prettyJsonConvertorFlag',
      'multiLineFlag',
    ];
    this.samplingUnitOption = [
      { label: intl.formatMessage(appFieldsMessages.minute), value: 60 },
      { label: intl.formatMessage(appFieldsMessages.hour), value: 3600 },
      { label: intl.formatMessage(appFieldsMessages.day), value: 86400 },
    ];
    this.maintenanceDataMap = {};

    this.holidayDayRenderer = ({ cellData }) => (cellData ? cellData.format('MMMM DD') : '');
  }

  componentDidMount() {
    const { projectName } = this.props;
    this.props.loadProjectHoliday({ projectName }, { [this.holidayLoader]: true });
    // this.reloadDataMaintenanceDay(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If the users prop is changed, set the local state.
    if (nextProps.projectSettings !== this.props.projectSettings) {
      let newState = {};
      let changed = false;

      const projectSettings = nextProps.projectSettings || {};
      const nextPropsFields = this.getSettingFields(projectSettings, this.settingFieldList);
      const statesFields = this.getSettingFields(this.state, this.settingFieldList);
      R.forEachObjIndexed((value, key) => {
        if (!R.identical(value, get(statesFields, key, ''))) {
          newState[key] = value;
          changed = true;
        }
      }, nextPropsFields);

      const { trainingStartTime: startTimestamp, trainingEndTime: endTimestamp } = get(
        nextProps,
        ['projectSettings', 'modelSpanRangeStr'],
        {},
      );
      if (startTimestamp !== this.state.trainingStartTime || endTimestamp !== this.state.trainingEndTime) {
        changed = true;
        newState = {
          ...newState,
          trainingStartTime: startTimestamp || null,
          trainingEndTime: endTimestamp || null,
        };
      }

      const [samplingInterval, samplingUnit] = this.handleUnit(projectSettings.samplingInterval);
      newState = {
        ...newState,
        samplingInterval,
        samplingUnit,
      };

      if (changed) {
        const minValidModelSpan = get(projectSettings, ['minValidModelSpan']);
        const oldValMap = {
          trainingFilter: get(projectSettings, ['trainingFilter']),
          modelSpan: get(projectSettings, ['modelSpan']),
          linkedLogProjects: get(projectSettings, ['linkedLogProjects'], []),
          samplingInterval: get(projectSettings, ['samplingInterval']),
          samplingUnitGoogleCost: 3600,
          minValidModelSpan: minValidModelSpan ? Number(minValidModelSpan) * 60000 : '',
          modelSpanRangeStr: JSON.stringify(get(projectSettings, ['modelSpanRangeStr'], '{}')),
          prettyJsonConvertorFlag: get(projectSettings, ['prettyJsonConvertorFlag'], false),
          multiLineFlag: get(projectSettings, ['multiLineFlag'], false),
        };
        newState.oldValMap = oldValMap;
        this.setState(newState);
      }
    } else if (this.props.projectName !== nextProps.projectName || this.props.refreshTime !== nextProps.refreshTime) {
      // If project changed, reload the holiday
      this.props.loadProjectHoliday({ projectName: nextProps.projectName }, { [this.holidayLoader]: true });
      // this.reloadDataMaintenanceDay(nextProps);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection, maintenanceDays } = nextState;

      if (sortBy) {
        let newMaintenanceDays = R.sortWith([R.ascend(R.prop(sortBy))])(maintenanceDays);
        if (sortDirection === SortDirection.DESC) {
          newMaintenanceDays = R.sortWith([R.descend(R.prop(sortBy))])(newMaintenanceDays);
        }
        this.setState({ maintenanceDays: newMaintenanceDays });
      }
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  getSettingFields(props, fieldList) {
    const settingVals = {};
    R.forEach((f) => {
      settingVals[f] = get(props, f, '');
    }, fieldList);
    return settingVals;
  }

  @autobind
  reloadDataMaintenanceDay(props) {
    const self = this;
    const { credentials, projectName } = props;
    if (projectName) {
      const { sortBy, sortDirection } = self.state;
      self.setState({ isMaintenanceDayLoading: true });
      props.updateLastActionInfo();
      fetchGet(getEndpoint('specialday'), {
        ...credentials,
        projectName,
      }).then((d) => {
        const { specialList, instanceLevelSpecialList } = d || {};

        let maintenanceDays = [];
        const maintenanceDaysMap = {};
        try {
          maintenanceDays = JSON.parse(specialList || '[]');
          R.forEach((item) => {
            const { key, dateKey, startTime, endTime, uuid } = item;
            const itemKey = `${key}_${dateKey}_${startTime?.hour}_${startTime?.minute}_${endTime?.hour}_${endTime?.minute}`;
            if (maintenanceDaysMap[itemKey]) {
              maintenanceDaysMap[itemKey].uuids = [...maintenanceDaysMap[itemKey].uuids, uuid];
            } else {
              maintenanceDaysMap[itemKey] = { ...item, isProjectLevel: true, uuid: undefined, uuids: [uuid] };
            }
          }, maintenanceDays);
        } catch (e) {
          console.log(e);
        }
        maintenanceDays = R.values(maintenanceDaysMap);

        let maintenanceDays2 = [];
        try {
          maintenanceDays2 = JSON.parse(instanceLevelSpecialList || '[]');
        } catch (e) {
          console.log(e);
        }
        const maintenanceDays2Map = {};
        let maintenanceDaysIns = [];
        R.forEach((item) => {
          const { componentName, instanceName, specialDayList } = item;
          R.forEach((item2) => {
            const { key, dateKey, startTime, endTime, uuid } = item2;
            const itemKey = `${key}_${dateKey}_${startTime?.hour}_${startTime?.minute}_${endTime?.hour}_${endTime?.minute}`;

            if (maintenanceDays2Map[itemKey]) {
              maintenanceDays2Map[itemKey].instances = [
                ...maintenanceDays2Map[itemKey].instances,
                { instanceName, componentName, uuid },
              ];
            } else {
              maintenanceDays2Map[itemKey] = {
                ...item2,
                instances: [{ instanceName, componentName, uuid }],
              };
            }
          }, specialDayList || []);
        }, maintenanceDays2);
        maintenanceDaysIns = R.values(maintenanceDays2Map);

        let allMaintenanceDays = [...maintenanceDays, ...maintenanceDaysIns];
        allMaintenanceDays = this.sortData(allMaintenanceDays, sortBy, sortDirection);

        self.setState({
          maintenanceDays: allMaintenanceDays,
          isMaintenanceDayLoading: false,
          isRemoveCheckedAll: false,
        });
      });
    }
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    let sortFunctions = [R.ascend(R.prop('key'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);
    return sortList;
  }

  @autobind
  sort({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  handleUnit(samplingInterval) {
    let unit = 1;
    let value = samplingInterval;
    if (isInteger(samplingInterval / 86400)) {
      unit = 86400;
      value /= unit;
      return [value, unit];
    }

    if (isInteger(samplingInterval / 3600)) {
      unit = 3600;
      value /= unit;
      return [value, unit];
    }

    if (isInteger(samplingInterval / 60)) {
      unit = 60;
      value /= unit;
      return [value, unit];
    }

    return [value, unit];
  }

  @autobind
  async handleSaveClick() {
    const { credentials, projectName, currentProject, systemsMap, saveProjectSettings, settingsLoader, userInfo } =
      this.props;
    const {
      trainingFilter,
      modelSpan,
      linkedLogProjects,
      samplingInterval,
      samplingUnit,
      trainingStartTime,
      trainingEndTime,
      samplingUnitGoogleCost,
      minValidModelSpan,
      prettyJsonConvertorFlag,
      multiLineFlag,
      systemId,
      oldValMap,
    } = this.state;
    const { isMetric, projectShortName, owner } = currentProject;
    const isGoogleCloudCost = get(currentProject, 'cloudType', '') === 'GoogleCloudCost';

    let settings = {
      trainingFilter,
      modelSpan,
      linkedLogProjects,
      samplingInterval: isGoogleCloudCost ? samplingUnitGoogleCost * samplingInterval : samplingInterval * samplingUnit,
      samplingUnitGoogleCost,

      minValidModelSpan: minValidModelSpan ? Number(minValidModelSpan) * 60000 : '',
      modelSpanRangeStr: JSON.stringify({ trainingStartTime, trainingEndTime }),
      prettyJsonConvertorFlag,
      multiLineFlag,
    };

    if (!isMetric) {
      settings = R.pick(
        ['minValidModelSpan', 'modelSpanRangeStr', 'prettyJsonConvertorFlag', 'multiLineFlag', 'samplingInterval'],
        settings,
      );
    }

    const systemInfo = systemsMap[systemId === 'emptySystem' ? currentProject.systemId : systemId];
    const hasSetData = { oldValMap, newValMap: settings };
    const requests = [
      saveProjectSettings(
        projectName.indexOf('@') >= 0 ? projectName : `${projectShortName}@${owner}`,
        { ...settings, hasSetData: JSON.stringify(hasSetData) },
        {
          [settingsLoader]: true,
        },
      ),
    ];
    if (systemInfo && this.localSystemId !== systemId) {
      const { systemName, owner } = systemInfo;
      const sessionToken = userInfo?.sessionToken;
      const systemInfoStr = JSON.stringify([
        {
          customerName: owner,
          systemName: systemId === 'emptySystem' ? currentProject.systemId : systemId,
          systemDisplayName: systemName,
          projectNameSet: [
            {
              projectName: projectName.split('@')[0],
              userName: projectName.split('@')[1] || credentials.userName,
            },
          ],
        },
      ]);
      const systemInfoHashed = CryptoJS.HmacMD5(systemInfoStr, sessionToken).toString();
      requests.push(
        fetchPost(
          getEndpoint('systemframework', 2),
          {
            ...credentials,
            customerName: credentials.userName,
            operation: systemId === 'emptySystem' ? 'removeProjectFromSystem' : 'addProjectToSystem',
            systemInfo: systemInfoStr,
            'systemInfo-hashed': systemInfoHashed,
          },
          {},
          false,
        ).catch((err) => {
          Modal.error({ content: 'Unfortunately, an error has occurred. Please try again!' });
        }),
      );
    }

    this.setState({ isLoading: true });
    Promise.all(requests)
      .then((results) => {
        this.setState({ isLoading: false });
        if (this.localSystemId !== systemId) {
          window.location.reload();
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        Modal.error({ content: 'Unfortunately, an error has occurred. Please try again!' });
      });
  }

  @autobind
  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  @autobind
  handleAddHolidayClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showHolidayModal: true });
  }

  @autobind
  handleAddMaintenanceDayClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showMaintenanceDayModal: true });
  }

  @autobind
  handleHolidayRemove() {
    const { holidayList, credentials, projectName } = this.props;
    this.setState({ holidaysLoading: true });

    fetchDelete(
      getEndpoint('holiday'),
      {
        ...credentials,
        projectName,
        holidayNameList: JSON.stringify(
          R.map(
            (item) => item.name,
            R.filter((_item) => _item.checked, holidayList || []),
          ),
        ),
      },
      {},
      false,
    )
      .then((data) => {
        message.success('successfully deleted!');
        this.setState({ isHolidaysCheckedAll: false, holidaysLoading: false });
        this.props.loadProjectHoliday({ projectName }, { [this.holidayLoader]: true });
      })
      .catch((err) => {
        this.setState({ holidaysLoading: false });
        message.error(err.message || String(err));
      });
  }

  @autobind
  handleHolidayModalClose(created) {
    const { projectName } = this.props;
    this.setState({ showHolidayModal: false, activeHoliday: null, isHolidaysCheckedAll: false }, () => {
      if (created) {
        this.props.loadProjectHoliday({ projectName }, { [this.holidayLoader]: true });
      }
    });
  }

  @autobind
  handleMaintenanceDayModalClose(created) {
    this.setState({ showMaintenanceDayModal: false }, () => {
      if (created) {
        this.reloadDataMaintenanceDay(this.props);
      }
    });
  }

  @autobind
  dateKeyRender({ cellData, rowData }) {
    const { key, dateKey, startTime, endTime } = rowData;
    let content = cellData;
    if (key === 'week') {
      const option = R.find((sp) => sp.value === dateKey, Options.WeekOptions);
      content = option ? option.label : content;
    }
    if (key === 'range') {
      const [startTime, endTime] = dateKey.split(',');
      const startTimeStr = moment.utc(Number(startTime)).format(Defaults.TimeFormat);
      const endTimeStr = moment.utc(Number(endTime)).format(Defaults.TimeFormat);
      content = `From:${startTimeStr} To:${endTimeStr}`;
    } else if (startTime && endTime) {
      content = `${content ? `Day: ${content}, ` : ''}From Time ${startTime.hour}:${
        startTime.minute < 10 ? `0${startTime.minute}` : startTime.minute
      } To Time ${endTime.hour}:${endTime.minute < 10 ? `0${endTime.minute}` : endTime.minute}`;
    }
    return <div className="flex-row">{content}</div>;
  }

  @autobind
  renderMaintenanceInstance({ rowData, dataKey, cellData }) {
    const { isProjectLevel, instances = [] } = rowData;

    if (isProjectLevel) {
      return dataKey === 'instanceNameList' ? 'All instances' : 'All components';
    }

    const content =
      dataKey === 'instanceNameList'
        ? R.join(', ', R.uniq(R.map(R.prop('instanceName'), instances)))
        : R.join(', ', R.uniq(R.map(R.prop('componentName'), instances)));

    return (
      <Popover
        title={null}
        content={
          <div style={{ maxWidth: 300, maxHeight: 150, overflowY: 'auto', wordBreak: 'break-all' }}>{content}</div>
        }
        placement="top"
        mouseEnterDelay={0.3}
      >
        <div className="hidden-line-with-ellipsis inline-block max-width">{content}</div>
      </Popover>
    );
  }

  @autobind
  removeCheckBoxRender({ rowData, rowIndex }) {
    const { isChecked } = rowData;
    const { maintenanceDays } = this.state;

    return (
      <Checkbox
        checked={isChecked}
        onChange={(e) => {
          this.setState(
            {
              maintenanceDays: update(maintenanceDays, {
                [rowIndex]: { $set: { ...rowData, isChecked: e.target.checked } },
              }),
            },
            () => {
              const { maintenanceDays } = this.state;
              this.setState({
                isRemoveCheckedAll: !(
                  R.find(R.propEq('isChecked', false), maintenanceDays) ||
                  R.find(R.propEq('isChecked', undefined), maintenanceDays)
                ),
              });
            },
          );
        }}
      />
    );
  }

  @autobind
  handleMaintenanceDayRemove(deleteMaintenanceList) {
    return () => {
      const { intl, projectName } = this.props;
      this.setState({ isMaintenanceDayLoading: true });

      const requests = [];
      let uuidList = [];
      const instancesMap = {};
      R.forEach((item) => {
        const { instances = [], uuids = [] } = item;
        uuidList = [...uuidList, ...uuids];

        R.forEach((inst) => {
          const { uuid } = inst;
          if (instancesMap[uuid]) {
            instancesMap[uuid].items = [...instancesMap[uuid].items, inst];
          } else {
            instancesMap[uuid] = { items: [inst] };
          }
        }, instances);
      }, deleteMaintenanceList || []);

      if (uuidList.length > 0) {
        const formData = new FormData();
        formData.append('projectName', projectName);
        formData.append('operation', 'delete');
        if (uuidList.length > 0) {
          formData.append('uuidList', JSON.stringify(uuidList));
        }
        requests.push(fetchPostForm(getEndpoint('specialday'), formData));
      }

      if (R.keys(instancesMap).length > 0) {
        R.forEach((uuid) => {
          const { items } = instancesMap[uuid];

          const formData = new FormData();
          formData.append('projectName', projectName);
          formData.append('operation', 'delete');
          formData.append('uuidList', JSON.stringify([uuid]));
          const instanceNameList = R.map(R.prop('instanceName'), items);
          formData.append('instanceNameList', JSON.stringify(instanceNameList));
          requests.push(fetchPostForm(getEndpoint('specialday'), formData));
        }, R.keys(instancesMap));
      }

      this.props.updateLastActionInfo();

      Promise.all(requests)
        .then((results) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.reloadDataMaintenanceDay(this.props);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    };
  }

  @autobind
  handleTrainingStartTimeChange(dateObj) {
    if (dateObj) {
      let { trainingEndTime } = this.state;
      const selectTime = dateObj.valueOf();
      if (selectTime > trainingEndTime) {
        trainingEndTime = selectTime;
      }
      this.setState({ trainingStartTime: selectTime, trainingEndTime });
    } else {
      this.setState({ trainingStartTime: null });
    }
  }

  @autobind
  handleTrainingEndTimeChange(dateObj) {
    if (dateObj) {
      let { trainingStartTime } = this.state;
      const selectTime = dateObj.valueOf();
      if (trainingStartTime > selectTime) {
        trainingStartTime = selectTime;
      }
      this.setState({ trainingEndTime: selectTime, trainingStartTime });
    } else {
      this.setState({ trainingEndTime: null });
    }
  }

  @autobind
  removeCheckAllHeaderRender(fieldName) {
    return () => {
      return (
        <>
          {fieldName === 'isRemoveCheckedAll' && (
            <Checkbox checked={this.state[fieldName]} onChange={this.handleIsAllChecked(fieldName)} />
          )}
        </>
      );
    };
  }

  @autobind
  handleIsAllChecked(fieldName) {
    return ({ target: { checked } }) => {
      const { maintenanceDays } = this.state;
      R.forEach((item) => {
        if (fieldName === 'isRemoveCheckedAll') {
          item.isChecked = checked;
        }
      }, maintenanceDays || []);
      this.setState({ [fieldName]: checked });
    };
  }

  @autobind
  headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  checkedRenderer({ cellData, dataKey, rowData }) {
    const { holidayList } = this.props;
    return (
      <Checkbox
        size="small"
        checked={cellData}
        onChange={({ target: { checked } }) => {
          rowData[dataKey] = checked;
          const findChecked = R.find((item) => !item.checked, holidayList || []);
          if (this.holidayTable) this.holidayTable.forceUpdate();
          this.setState({ isHolidaysCheckedAll: !findChecked });
          this.forceUpdate();
        }}
      />
    );
  }

  render() {
    const {
      intl,
      credentials,
      projectName,
      projects,
      currentProject,
      currentLoadingComponents,
      holidayList,
      isAdmin,
      isLocalAdmin,
    } = this.props;
    const { saveProjectHoliday } = this.props;

    const {
      systemId,
      isLoading,

      trainingFilter,
      modelSpan,
      linkedLogProjects,
      samplingInterval,
      samplingUnit,
      trainingStartTime,
      trainingEndTime,
      samplingUnitGoogleCost,
      minValidModelSpan,
      prettyJsonConvertorFlag,
      multiLineFlag,
      maintenanceDays,
      isHolidaysCheckedAll,
      holidaysLoading,
      activeHoliday,
    } = this.state;

    const { showHolidayModal, showMaintenanceDayModal, isMaintenanceDayLoading } = this.state;

    const deleteMaintenanceList = R.filter((item) => item.isChecked, maintenanceDays);

    const startTimeObjTraining = trainingStartTime ? moment.utc(trainingStartTime) : null;
    const endTimeObjTraining = trainingEndTime ? moment.utc(trainingEndTime) : null;

    const isGoogleCloudCost = get(currentProject, 'cloudType', '') === 'GoogleCloudCost';
    const isHolidayLoading = get(currentLoadingComponents, this.holidayLoader, false);

    const verifyMinValidModelSpan = Number(minValidModelSpan) >= 1 && isInteger(Number(minValidModelSpan));
    const verifySamplingInterval = isAdmin || isLocalAdmin ? !samplingInterval : false;

    const hasErrorUpdateTrainingRange =
      (trainingStartTime && !trainingEndTime) || (!trainingStartTime && trainingEndTime);
    const hasError = !verifyMinValidModelSpan || verifySamplingInterval;
    const isSubmitting = get(this.props.currentLoadingComponents, this.props.settingsLoader, false);
    const { sortBy, sortDirection } = this.state;

    const disabled = !R.find((item) => item.checked, holidayList || []);

    return (
      <Spin spinning={isLoading} wrapperClassName="full-width full-height pr-10 overflow-y-auto">
        <h3 className="project-setting-title" id="project-setting-anchor-general">
          {intl.formatMessage(settingsMessages.general)}
        </h3>
        <Form layout="vertical" wrapperCol={{ span: 12 }}>
          <Row wrap>
            {false && (
              <Col span={12}>
                <Item
                  label={intl.formatMessage(appFieldsMessages.systemName)}
                  required
                  help={!systemId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  validateStatus={systemId ? 'success' : 'error'}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption
                    value={systemId || 'emptySystem'}
                    onChange={(systemId) => this.setState({ systemId })}
                    dropdownMatchSelectWidth={250}
                  >
                    {R.map(
                      (item) => (
                        <Select.Option
                          key={item.systemId}
                          value={item.systemId}
                          title={`${item.systemName}@${item.owner}`}
                        >
                          {item.systemId === 'emptySystem' ? item.systemName : `${item.systemName}@${item.owner}`}
                        </Select.Option>
                      ),
                      this.systemOptions || [],
                    )}
                  </Select>
                </Item>
              </Col>
            )}

            <Col span={12}>
              <Item
                label={intl.formatMessage(settingsMessages.minValidModelSpan)}
                required
                help={verifyMinValidModelSpan ? undefined : 'Please input a number greater than 1'}
                validateStatus={verifyMinValidModelSpan ? 'success' : 'error'}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={1}
                  value={minValidModelSpan}
                  onChange={(minValidModelSpan) => this.setState({ minValidModelSpan })}
                  precision={0}
                />
              </Item>
            </Col>

            {!currentProject.isMetric && (
              <Col span={12}>
                <Item
                  label={intl.formatMessage(settingsMessages.trainingModelSpan)}
                  validateStatus={hasErrorUpdateTrainingRange ? 'error' : 'success'}
                  help={hasErrorUpdateTrainingRange ? 'Please select the correct time frame' : undefined}
                >
                  <Row gutter={10}>
                    <Col span={12}>
                      <DatePicker
                        style={{ width: '100%' }}
                        allowClear
                        onChange={this.handleTrainingStartTimeChange}
                        value={startTimeObjTraining}
                      />
                    </Col>
                    <Col span={12}>
                      <DatePicker
                        style={{ width: '100%' }}
                        allowClear
                        onChange={this.handleTrainingEndTimeChange}
                        value={endTimeObjTraining}
                      />
                    </Col>
                  </Row>
                </Item>
              </Col>
            )}

            {currentProject.isMetric && (
              <>
                <Col span={12}>
                  <Item label={intl.formatMessage(settingsMessages.enableAnomalyDataFiltering)}>
                    <Checkbox
                      checked={trainingFilter}
                      onChange={({ target: { checked: trainingFilter } }) => this.setState({ trainingFilter })}
                    />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label={intl.formatMessage(settingsMessages.metricModelSpan)} required>
                    <Select
                      showSearch
                      filterOption
                      value={modelSpan}
                      onChange={(modelSpan) => this.setState({ modelSpan })}
                    >
                      <Select.Option value="0">Daily</Select.Option>
                      <Select.Option value="1">Monthly</Select.Option>
                    </Select>
                  </Item>
                </Col>
              </>
            )}

            <Col span={12}>
              <Item
                label={intl.formatMessage(settingsMessages.samplingInterval)}
                required
                help={verifySamplingInterval ? 'Please input a number greater than 0' : undefined}
                validateStatus={verifySamplingInterval ? 'error' : 'success'}
              >
                <div className="flex-row flex-center-align">
                  <AntInput
                    style={{ flex: 1 }}
                    value={samplingInterval}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (/^\d+$/.test(val) || R.isEmpty(val)) {
                        this.setState({ samplingInterval: Number(val) });
                      }
                    }}
                  />
                  {!isGoogleCloudCost && (
                    <Select
                      style={{ width: 80, marginLeft: 10 }}
                      options={this.samplingUnitOption}
                      value={samplingUnit}
                      dropdownMatchSelectWidth={false}
                      onChange={(samplingUnit) => this.setState({ samplingUnit })}
                    />
                  )}

                  {isGoogleCloudCost && (
                    <Select
                      style={{ width: 80, marginLeft: 10 }}
                      options={[{ label: intl.formatMessage(appFieldsMessages.hour), value: 3600 }]}
                      value={samplingUnitGoogleCost}
                      onChange={(samplingUnitGoogleCost) => this.setState({ samplingUnitGoogleCost })}
                    />
                  )}
                </div>
              </Item>
            </Col>

            {!currentProject.isMetric && (
              <Col span={12}>
                <Item
                  label={
                    <div className="flex-row flex-center-align">
                      <div style={{ marginRight: 8 }}>{intl.formatMessage(settingsMessages.enableJSONPreprocess)}</div>
                      <Popover
                        title={null}
                        content={intl.formatMessage(settingsMessages.enableJSONPreprocessToolTip)}
                        placement="top"
                        mouseEnterDelay={0.3}
                      >
                        <QuestionCircleOutlined className="clickable" />
                      </Popover>
                    </div>
                  }
                >
                  <Checkbox
                    checked={prettyJsonConvertorFlag}
                    onChange={({ target: { checked: prettyJsonConvertorFlag } }) =>
                      this.setState({ prettyJsonConvertorFlag })
                    }
                  />
                </Item>
              </Col>
            )}
            {!currentProject.isMetric && (
              <Col span={12}>
                <Item label={intl.formatMessage(settingsMessages.enableRegexMultiline)}>
                  <Checkbox
                    checked={multiLineFlag}
                    onChange={({ target: { checked: multiLineFlag } }) => this.setState({ multiLineFlag })}
                  />
                </Item>
              </Col>
            )}
          </Row>
          {currentProject.isMetric && (
            <Row>
              <>
                <Col span={24}>
                  <Item label={intl.formatMessage(settingsMessages.linkingLogProjects)}>
                    <Select
                      allowClear
                      mode="multiple"
                      value={linkedLogProjects}
                      options={this.projectOptions}
                      onChange={(linkedLogProjects) => this.setState({ linkedLogProjects })}
                    />
                  </Item>
                </Col>
              </>
            </Row>
          )}
        </Form>
        <div className="flex-row flex-end-justify" style={{ marginBottom: 20 }}>
          <Button onClick={this.handleSaveClick} size="small" disabled={hasError} type="primary" loading={isSubmitting}>
            Update
          </Button>
        </div>

        <h3 className="project-setting-title">{intl.formatMessage(settingsMessages.holidays)}</h3>
        <div style={GroupBox}>
          <div className="full-height flex-col">
            <div className="flex-row field" style={{ marginBottom: 10 }}>
              <Button type="primary" onClick={this.handleAddHolidayClick} size="small">
                {intl.formatMessage(appButtonsMessages.add)}
              </Button>
              <div className="flex-grow" />
              <Button type="primary" disabled={disabled} onClick={this.handleHolidayRemove} size="small">
                {intl.formatMessage(appButtonsMessages.delete)}
              </Button>
              {showHolidayModal && (
                <HolidayModal
                  intl={intl}
                  projectName={projectName}
                  currentLoadingComponents={currentLoadingComponents}
                  holidayList={holidayList}
                  activeHoliday={activeHoliday}
                  saveProjectHoliday={saveProjectHoliday}
                  onClose={this.handleHolidayModalClose}
                />
              )}
            </div>
            <Container className={`flex-grow ${isHolidayLoading || holidaysLoading ? ' loading' : ''}`}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Table
                    className="with-border"
                    width={width}
                    height={400}
                    headerHeight={40}
                    rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                    rowHeight={40}
                    rowCount={holidayList.length}
                    rowGetter={({ index }) => holidayList[index]}
                    ref={(c) => {
                      this.holidayTable = c;
                    }}
                  >
                    <Column
                      width={40}
                      disableSort
                      dataKey="checked"
                      className="white-pre"
                      cellRenderer={this.checkedRenderer}
                      label={
                        <Checkbox
                          size="small"
                          checked={isHolidaysCheckedAll}
                          onChange={({ target: { checked } }) => {
                            R.forEach((item) => {
                              item.checked = checked;
                            }, holidayList || []);
                            if (this.holidayTable) this.holidayTable.forceUpdate();
                            this.setState({ isHolidaysCheckedAll: checked });
                          }}
                        />
                      }
                    />
                    <Column
                      width={280}
                      label={intl.formatMessage(appFieldsMessages.name)}
                      dataKey="name"
                      className="white-pre"
                      flexGrow={1}
                    />
                    <Column
                      width={120}
                      label={intl.formatMessage(appFieldsMessages.startDate)}
                      dataKey="startTime"
                      cellRenderer={this.holidayDayRenderer}
                      flexGrow={1}
                    />
                    <Column
                      width={120}
                      label={intl.formatMessage(appFieldsMessages.endDate)}
                      dataKey="endTime"
                      cellRenderer={this.holidayDayRenderer}
                      flexGrow={1}
                    />
                    <Column
                      width={70}
                      label={null}
                      dataKey="edit"
                      cellRenderer={({ rowData }) => {
                        return (
                          <Button
                            onClick={() => this.setState({ activeHoliday: rowData, showHolidayModal: true })}
                            size="small"
                          >
                            {intl.formatMessage(appButtonsMessages.edit)}
                          </Button>
                        );
                      }}
                    />
                  </Table>
                )}
              </AutoSizer>
            </Container>
          </div>
        </div>

        {false && (
          <>
            <h3 className="project-setting-title">{intl.formatMessage(settingsMessages.maintenanceDay)}</h3>
            <div style={GroupBox}>
              <div className="full-height flex-col">
                <div className="flex-row" style={{ marginBottom: 12 }}>
                  <Button
                    type="primary"
                    onClick={this.handleAddMaintenanceDayClick}
                    style={{ marginBottom: 10 }}
                    size="small"
                  >
                    {intl.formatMessage(appButtonsMessages.add)}
                  </Button>
                  <div className="flex-grow" />
                  <Button
                    type="primary"
                    disabled={deleteMaintenanceList.length === 0}
                    onClick={this.handleMaintenanceDayRemove(deleteMaintenanceList)}
                  >
                    {intl.formatMessage(appButtonsMessages.delete)}
                  </Button>
                  {showMaintenanceDayModal && (
                    <MaintenanceDayModal
                      intl={intl}
                      credentials={credentials}
                      projectName={projectName}
                      projects={projects}
                      maintenanceDataMap={this.maintenanceDataMap}
                      changeMaintenanceDataMap={(data) => {
                        this.maintenanceDataMap = data;
                      }}
                      onClose={this.handleMaintenanceDayModalClose}
                    />
                  )}
                </div>
                <Container className={`flex-grow flex-col ${isMaintenanceDayLoading ? ' loading' : ''}`}>
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <Table
                        className="with-border"
                        width={width}
                        height={400}
                        headerHeight={40}
                        rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                        rowHeight={40}
                        rowCount={maintenanceDays.length}
                        rowGetter={({ index }) => maintenanceDays[index]}
                        sort={this.sort}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                      >
                        <Column
                          width={150}
                          label={intl.formatMessage(settingsMessages.timeWindowType)}
                          dataKey="key"
                          headerRenderer={this.headerRenderer}
                        />
                        <Column
                          width={280}
                          label={<span>{intl.formatMessage(settingsMessages.maintenanceDay)} (UTC)</span>}
                          dataKey="dateKey"
                          cellRenderer={this.dateKeyRender}
                          headerRenderer={this.headerRenderer}
                          flexGrow={1}
                        />
                        <Column
                          width={320}
                          disableSort
                          label={intl.formatMessage(appFieldsMessages.component)}
                          dataKey="componentNameList"
                          cellRenderer={this.renderMaintenanceInstance}
                        />
                        <Column
                          width={320}
                          disableSort
                          label={intl.formatMessage(appFieldsMessages.instance)}
                          dataKey="instanceNameList"
                          cellRenderer={this.renderMaintenanceInstance}
                        />
                        <Column
                          width={40}
                          label=""
                          dataKey="uuid"
                          disableSort
                          headerRenderer={this.removeCheckAllHeaderRender('isRemoveCheckedAll')}
                          cellRenderer={this.removeCheckBoxRender}
                        />
                      </Table>
                    )}
                  </AutoSizer>
                </Container>
              </div>
            </div>
          </>
        )}
      </Spin>
    );
  }
}

const GeneralSetting = injectIntl(GeneralSettingCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { systemsMap } = state.app;
    const { userInfo } = state.auth;
    const { holidayList, projectSettings } = state.settings;
    const { isAdmin, isLocalAdmin } = userInfo || {};

    return {
      credentials,
      systemsMap,
      userInfo,

      holidayList,
      projectSettings,
      isAdmin,
      isLocalAdmin,
    };
  },
  {
    showAppAlert,
    saveProjectHoliday,
    loadProjectHoliday,
    updateLastActionInfo,
  },
)(GeneralSetting);
