/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { getLoaderEpicAction } from '../../utils';
import { apiEpicErrorHandle } from '../../errors';
import { getMetricEventCausalResult } from '../../apis';
import { updateLastActionInfo } from '../../app/actions';
import { setMetricEventCausalResult } from '../actions';

const loadMetricEventCausalResultEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('LOAD_METRIC_EVENT_CAUSALRESULT').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(getMetricEventCausalResult(credentials, params))
      .concatMap((d) => {
        return Observable.concat(Observable.of(setMetricEventCausalResult(params, d.data)), hideLoader);
      })
      .catch((err) => {
        return Observable.concat(apiEpicErrorHandle(err), hideLoader);
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default loadMetricEventCausalResultEpic;
