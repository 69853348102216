/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import parseEventList from './parsers/parseEventList';

const getAnomalyLogEvents = (credentials: Credentials, params: Object) => {
  const { projectName, instanceNameList, startTimestamp, endTimestamp } = params;

  return fetchGet(getEndpoint('fetcheventsfromtable'), {
    ...credentials,
    projectName,
    instanceNameList: JSON.stringify(instanceNameList),
    startTimestamp,
    endTimestamp,
  }).then((d) => {
    return {
      data: R.mapObjIndexed((val, key, obj) => {
        return parseEventList({ All: val }, { instanceGroup: 'All' });
      }, d || {}),
    };
  });
};

export default getAnomalyLogEvents;
