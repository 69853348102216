/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import * as R from 'ramda';
import { get, isString } from 'lodash';

import parseEvents from './parseEvents';

const parseJSON = (str) => {
  let ret = str;
  if (isString(str)) {
    try {
      ret = JSON.parse(str);
    } catch (ex) {
      ret = str;
    }
  }
  return ret;
};

const parseEventList = (rawData, params) => {
  const { instanceGroup, isPrediction, isDetectedAnomaly } = params;
  let events = [];

  if (instanceGroup) {
    const gname = instanceGroup.split(':')[1] || instanceGroup;
    events = get(rawData, instanceGroup, []);
    // use ENV:xxx to get events by default, if no result, then use xxx.
    events = events.length === 0 ? get(rawData, gname, []) : events;
  } else {
    events = R.reduce(
      R.concat,
      [],
      R.filter((rt) => R.type(rt) === 'Array', R.values(rawData)),
    );
  }

  // Order events by startTimestamp in desc order.
  events = R.map((e) => {
    const patternId = !R.isNil(e.patternId) ? e.patternId : e.neuronId;
    const appNameMetricMapJson = parseJSON(e.appNameMetricMapJson);
    const instanceMetricJson = parseJSON(e.instanceMetricJson);
    const rootCauseByInstanceJson = parseJSON(e.rootCauseByInstanceJson);

    let rootCauseJson = parseJSON(e.rootCauseJson);
    // let rootCauseDetailsArr = get(rootCauseJson, ['rootCauseDetailsArr'], []);
    let rootCauseDetailsArr = rootCauseJson || [];
    // rootCauseDetailsArr = R.map((rc) => ({ ...rc, pct: parseFloat(rc.pct) }), rootCauseDetailsArr);
    rootCauseDetailsArr = R.map(
      (rc) => ({
        ...rc,
        appName: rc.componentName,
        direction: rc.sign,
        instanceId: rc.instanceName,
        metricValue: rc.anomalyValue,
        pct: parseFloat(rc.percentage),
        rootCauseMetric: rc.metricName,
        // rootCauseSource: 333,
        timePairArr: R.map(
          (val) => ({ startTimestamp: val.s, endTimestamp: val.e, duration: val.e - val.s }),
          rc.timePairList,
        ),
        isDetectedAnomaly,
      }),
      rootCauseDetailsArr,
    );
    // sort by pct
    rootCauseDetailsArr = R.sort((a, b) => {
      return Math.abs(b.pct) - Math.abs(a.pct);
    }, rootCauseDetailsArr);
    rootCauseJson = { ...rootCauseJson, rootCauseDetailsArr };

    let predictionSourceInfo = parseJSON(e.predictionSourceInfo);
    if (predictionSourceInfo) {
      const { sourceDetail, ...rest } = predictionSourceInfo;
      predictionSourceInfo = {
        sourceDetail: parseJSON(sourceDetail),
        ...rest,
      };
    }

    const keyfields = get(e, 'key', {});
    const startTimestamp = keyfields.startTimestamp || keyfields.timestamp || e.timestamp;
    const anomalyScoreJson = parseJSON(e.anomalyScoreJson) || {};
    const { anomalyRatioMax } = e;
    let { anomalyRatio } = e;
    anomalyRatio = anomalyRatioMax ? R.max(anomalyRatioMax, anomalyRatio) : anomalyRatio;
    // if (R.keys(anomalyScoreJson).length > 0) {
    //   anomalyRatio = 0;
    // }
    let anomalyScoreList = [];
    R.forEachObjIndexed((val, timestamp) => {
      const { anomalyScore, metricSet } = val;
      timestamp = parseInt(timestamp, 10);
      if (timestamp) {
        anomalyRatio = R.max(anomalyRatio, anomalyScore);
        anomalyScoreList.push({ timestamp, anomalyScore, metricSet });
      }
    }, anomalyScoreJson);
    anomalyScoreList = R.sort((a, b) => a.timestamp - b.timestamp, anomalyScoreList);

    const suggestedActions = get(rootCauseJson, ['suggestedActions'], '');
    const suggestActionList = suggestedActions.split('\n');

    return {
      ...e,
      // Hack code due to bad api design
      projectNameListName: isString(e) ? e : undefined,
      patternId,
      isPrediction: e.predictionFlag || !!isPrediction,
      predictionSourceInfo,
      keyfields,
      anomalyRatio,
      creationTime: e.creationTime || e.detectionCreationTime,
      appNameMetricMapJson,
      instanceMetricJson,
      rootCauseByInstanceJson,
      rootCauseJson,
      anomalyScoreList,
      suggestActionList,
      ...keyfields,
      startTimestamp,
    };
  }, events);

  events = R.sort((a, b) => b.startTimestamp - a.startTimestamp, events);
  const eventList = parseEvents(events, params);

  return eventList;
};

export default parseEventList;
