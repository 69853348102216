/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { CaretDownOutlined, CaretUpOutlined, DownOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, Button, Tooltip, Pagination } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { BackgroundCall, getLoadStatus, CellRenderers, Defaults, SettingRenderers } from '../../../../common/utils';
import { createLoadAction, createSetAction, showAppAlert, updateLastActionInfo } from '../../../../common/app/actions';
import { ActionTypes } from '../../../../common/settings/actions';
import { State } from '../../../../common/types';
import { Container, AutoSizer, Table, Column, Dropdown, Popover, SortDirection } from '../../../../lib/fui/react';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  location: Object,
  loadStatus: Object,
  projects: Array<Object>,
  projectInstanceComponentMap: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  push: Function,
  createLoadAction: Function,
  createSetAction: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  showAppAlert: Function,
  updateLastActionInfo: Function,
  refreshTime: Number,
  credentials: Object,
  userInfo: Object,
  pageInfo: Object,
  projectName: String,
  detectionStatus: Array<Object>,
  projectDetectionStatus: String,
};

class DetectionStatusCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { detectionStatus, pageInfo } = props;

    this.dataLoader = 'setting_detection_status_loading';
    this.detectionStatus = R.clone(detectionStatus || []);
    this.pageInfo = R.clone(pageInfo || {});

    this.state = {
      isLoadingUpdate: false,
      pageNo: 1,
      pageSize: 100,

      showRestoreLogRawDataModal: false,

      sortBy: null,
      sortDirection: null,
    };
  }

  componentDidMount() {
    const { createLoadAction, projectName } = this.props;
    const { pageNo, pageSize } = this.state;

    createLoadAction(
      ActionTypes.LOAD_PROJECT_DETECTION_STATUS,
      {
        projectName,
        pageNo,
        pageSize,
      },
      this.dataLoader,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { detectionStatus, pageInfo } = nextProps;
    // If project changed, reload the holiday
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    } else if (detectionStatus !== this.props.detectionStatus || pageInfo !== this.props.pageInfo) {
      this.detectionStatus = R.clone(detectionStatus || []);
      this.pageInfo = R.clone(pageInfo || {});
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        this.detectionStatus = R.sortWith([R.ascend(R.prop(sortBy))])(this.detectionStatus);
        if (sortDirection === SortDirection.DESC) {
          this.detectionStatus = R.sortWith([R.descend(R.prop(sortBy))])(this.detectionStatus);
        }
        this.table.forceUpdateGrid();
        this.forceUpdate();
      }
    }
  }

  @autobind
  reloadData(props) {
    const { projectName, createLoadAction } = props;
    const { pageNo, pageSize } = this.state;
    createLoadAction(
      ActionTypes.LOAD_PROJECT_DETECTION_STATUS,
      {
        projectName,
        pageNo,
        pageSize,
      },
      this.dataLoader,
    );
  }

  @autobind
  renderDetectionStatus({ rowData, cellData }) {
    let color;
    switch (cellData) {
      case 'done':
        color = 'currentColor';
        break;
      case 'in progress':
        color = 'green';
        break;
      case 'error':
        color = 'red';
        break;
      default:
        break;
    }
    return <div style={{ color }}>{cellData}</div>;
  }

  @autobind
  handleDetectionIndexChanged(rowData, dataKey) {
    return (e) => {
      const { target } = e;
      const newVal = target.value || 0;
      // Save the data and force update.
      rowData[dataKey] = Number(newVal);
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  renderDetectionIndexInput({ dataKey, rowData, cellData }) {
    const { intl } = this.props;
    const progressInfo = rowData.progressInfo || [];
    const content = Number(cellData) ? cellData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : cellData;
    return (
      <Popover
        content={
          <div className="flex-col overflow-y-auto" style={{ maxHeight: 300, fontSize: 12 }}>
            <div className="flex-row">
              <span style={{ width: 120 }}>{intl.formatMessage(settingsMessages.detectionIndex)}:</span>
              <span>{content}</span>
            </div>
            {progressInfo.length > 0 && (
              <div style={{ marginTop: 4, fontWeight: 500, fontSize: 13 }}>
                {intl.formatMessage(settingsMessages.progressDetectionInfo)}:
              </div>
            )}
            {R.addIndex(R.map)((val, index) => {
              const { timestamp, detectionIndex } = val;
              return (
                <div className="flex-row" key={index}>
                  <span style={{ width: 80, fontWeight: 500 }}>{intl.formatMessage(appFieldsMessages.startTime)}:</span>
                  <span style={{ marginRight: 8 }}>{moment.utc(timestamp).format(Defaults.TimeFormat)}</span>
                  <span style={{ width: 120, fontWeight: 500 }}>
                    {intl.formatMessage(settingsMessages.detectionIndex)}:
                  </span>
                  <span>{detectionIndex}</span>
                </div>
              );
            }, progressInfo)}
          </div>
        }
        placement="right"
      >
        <span>{content}</span>
      </Popover>
    );
  }

  @autobind
  renderDetectionTrainingLock({ cellData }) {
    return <span style={{ color: cellData ? 'red' : 'green' }}>{cellData ? 'On' : 'Off'}</span>;
  }

  @autobind
  renderDetectionStuckIndex({ cellData }) {
    const stuckIndexs = cellData || [];
    const msg = R.join(',', stuckIndexs);
    return <span>{msg}</span>;
  }

  @autobind
  actionsRenderer({ rowData }) {
    const { intl } = this.props;
    const { isLoadingUpdate } = this.state;

    const handleMenuClick = ({ key }) => {
      switch (key) {
        case 'clearLock':
          this.handleClearLock({ rowData });
          break;
        case 'dispatchDetection':
          this.handleDispatchDetection({ rowData });
          break;
        default:
          break;
      }
    };
    return (
      <Dropdown
        name={intl.formatMessage(appFieldsMessages.investigate)}
        itemClick={handleMenuClick}
        disabled={isLoadingUpdate}
      >
        <>
          <Menu.Item key="dispatchDetection">{intl.formatMessage(settingsMessages.dispatchDetection)}</Menu.Item>
          <Menu.Item key="clearLock">{intl.formatMessage(settingsMessages.clearLock)}</Menu.Item>
        </>
      </Dropdown>
    );
  }

  @autobind
  handleSaveClick() {
    const { createLoadAction, detectionStatus } = this.props;
    let { projectName } = this.props;

    if (projectName && projectName.indexOf('@') >= 0) {
      projectName = projectName.split('@')[0] || projectName;
    }
    const diff = R.difference(this.detectionStatus, detectionStatus || []);
    const changeData = diff[0];

    createLoadAction(
      ActionTypes.SAVE_PROJECT_DETECTION_STATUS,
      {
        projectName,
        tag: changeData.instanceName,
        index: changeData.detectionIndex,
      },
      this.dataLoader,
    );
  }

  @autobind
  handleClearLock({ rowData }) {
    const self = this;
    const { credentials, projectName } = this.props;
    const { instanceName } = rowData;
    if (window.confirm(`Clear this instance's training locker: ${instanceName}?`)) {
      self.setState({ isLoadingUpdate: true });
      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('logProjectProcessingStatus'), {
        ...credentials,
        projectName,
        operation: 'clearTrainingLocker',
        instanceName,
      })
        .then((data) => {
          self.reloadData(self.props);
          self.setState({ isLoadingUpdate: false });
        })
        .catch((err) => {
          self.setState({ isLoadingUpdate: false });
        });
    }
  }

  @autobind
  handleDispatchDetection({ rowData }) {
    const self = this;
    const { credentials, projectName } = this.props;
    const { instanceName } = rowData;
    if (window.confirm(`Dispatch this instance's detection: ${instanceName}?`)) {
      self.setState({ isLoadingUpdate: true });
      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('detectionprogress'), {
        ...credentials,
        projectName,
        instanceName,
      })
        .then((data) => {
          self.reloadData(self.props);
          self.setState({ isLoadingUpdate: false });
        })
        .catch((err) => {
          self.setState({ isLoadingUpdate: false });
        });
    }
  }

  @autobind
  handleChangeDetectStatus() {
    const { credentials, projectName, projectDetectionStatus } = this.props;
    const flag = projectDetectionStatus !== 'processing';
    const self = this;
    if (window.confirm(`Are you sure to change project detection status?`)) {
      self.setState({ isLoadingUpdate: true });
      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('logProjectProcessingStatus'), {
        ...credentials,
        projectName,
        operation: 'changeDetectionFlag',
        flag,
      })
        .then((data) => {
          self.relaodData(self.props);
          self.setState({ isLoadingUpdate: false });
        })
        .catch((err) => {
          self.setState({ isLoadingUpdate: false });
        });
    }
  }

  @autobind
  handleSettingClick({ key, domEvent }) {
    domEvent.stopPropagation();

    const { intl, credentials, projectName } = this.props;

    switch (key) {
      case 'resetDetectionIndex':
        if (window.confirm(intl.formatMessage(appMessages.continueConfirm))) {
          const url = `${window.BASE_URL || ''}/updateDetection`;
          const cronParams = {
            ...credentials,
            projectName,
            recover: true,
          };
          this.props.updateLastActionInfo();
          BackgroundCall.CallUrlJob({ intl, method: 'GET', url, params: cronParams, isJsonResponse: false });
        }
        break;
      case 'restoreLogRawData':
        this.handleRestoreLogRawData();
        break;
      default:
        break;
    }
  }

  @autobind
  handleRestoreLogRawData() {
    this.setState({ showRestoreLogRawDataModal: true });
  }

  @autobind
  handlePaginationChange(pageNo, pageSize) {
    this.setState({ pageNo }, () => {
      this.reloadData(this.props, { ...this.state, pageNo });
    });
  }

  @autobind
  renderSplitNumber({ cellData }) {
    const content = Number(cellData) ? cellData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : cellData;
    return content;
  }

  @autobind
  sort({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  render() {
    const {
      intl,
      loadStatus,
      credentials,
      userInfo,
      projects,
      projectInstanceComponentMap,
      updateLastActionInfo,
      createSetAction,
      projectName,
    } = this.props;
    const { detectionStatus, projectDetectionStatus } = this.props;
    const { isLoadingUpdate, pageNo, pageSize, sortBy, sortDirection } = this.state;
    const totalNumber = this.pageInfo.totalNumber || 0;

    const diff = R.difference(this.detectionStatus, detectionStatus || []);
    const hasError = diff.length === 0;

    // eslint-disable-next-line no-unused-vars
    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    return (
      <Container style={{ height: '100%' }}>
        <form
          className={`ui ${hasError ? 'error' : ''} form full-height flex-col`}
          style={{ fontSize: 12, height: '100%' }}
        >
          <Container className="field flex-row flex-center-align" style={{ fontSize: 14 }}>
            <div className="flex-grow" />
            <div className="flex-row" style={{ paddingRight: 16 }}>
              <span style={{ paddingRight: 8 }}>{intl.formatMessage(settingsMessages.projectDetectionStatus)}:</span>
              <span style={{ color: projectDetectionStatus === 'processing' ? 'green' : 'red' }}>
                {projectDetectionStatus}
              </span>
            </div>
            {userInfo.isAdmin && (
              <Button
                size="small"
                type="primary"
                disabled={Boolean(isLoadingUpdate)}
                onClick={this.handleChangeDetectStatus}
              >
                {`${projectDetectionStatus === 'processing' ? 'Stop' : 'Start'} Project Detection`}
              </Button>
            )}
            {userInfo.isAdmin && (
              <div className="flex-row flex-center-align" style={{ marginLeft: 8 }}>
                <Tooltip title="Advanced Action" placement="topRight">
                  <Dropdown icon={<SettingOutlined />} itemClick={this.handleSettingClick}>
                    <>
                      <Menu.Item key="resetDetectionIndex">
                        {intl.formatMessage(settingsMessages.resetDetectionIndex)}
                      </Menu.Item>
                      <Menu.Item key="restoreLogRawData">
                        {intl.formatMessage(settingsMessages.restoreLogRawData)}
                      </Menu.Item>
                    </>
                  </Dropdown>
                </Tooltip>
              </div>
            )}
          </Container>
          <Container className="flex-row" style={{ padding: '0 0 5px 0' }}>
            <div className="flex-row flex-center-align">
              <Pagination
                size="small"
                current={pageNo}
                total={totalNumber}
                pageSize={pageSize}
                onChange={this.handlePaginationChange}
                showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                showSizeChanger
                pageSizeOptions={['10', '20', '50', '100', '200', '500', '1000']}
                onShowSizeChange={(current, pageSize) => {
                  this.setState({ pageSize }, () => {
                    this.handlePaginationChange(current, pageSize);
                  });
                }}
              />
            </div>
          </Container>
          <Container className={`flex-grow field${isLoading ? ' loading' : ''}`}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  rowCount={this.detectionStatus.length}
                  rowGetter={({ index }) => this.detectionStatus[index]}
                  ref={(table) => {
                    this.table = table;
                  }}
                  sort={this.sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  <Column
                    width={100}
                    label={intl.formatMessage(appFieldsMessages.status)}
                    dataKey="status"
                    cellRenderer={this.renderDetectionStatus}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={200}
                    label={intl.formatMessage(appFieldsMessages.component)}
                    dataKey="instanceName"
                    className="white-pre"
                    flexGrow={1}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={150}
                    label={intl.formatMessage(settingsMessages.totalChunk)}
                    dataKey="totalChunk"
                    className="white-pre"
                    cellRenderer={this.renderSplitNumber}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={150}
                    label={intl.formatMessage(settingsMessages.totalEntry)}
                    dataKey="totalEntry"
                    className="white-pre"
                    cellRenderer={this.renderSplitNumber}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={150}
                    label={intl.formatMessage(settingsMessages.detectionIndex)}
                    dataKey="detectionIndex"
                    className="white-pre"
                    cellRenderer={this.renderDetectionIndexInput}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={100}
                    label={intl.formatMessage(settingsMessages.trainingLock)}
                    dataKey="trainingLocker"
                    className="white-pre"
                    cellRenderer={this.renderDetectionTrainingLock}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={120}
                    label={intl.formatMessage(settingsMessages.lockDuration)}
                    dataKey="lockDuration"
                    className="white-pre"
                    cellRenderer={({ cellData }) => CellRenderers.humanizeDuration({ period: cellData, intl })}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={200}
                    label={intl.formatMessage(settingsMessages.blockedIndex)}
                    dataKey="stuckIndex"
                    className="white-pre"
                    flexGrow={1}
                    cellRenderer={this.renderDetectionStuckIndex}
                    headerRenderer={this.headerRenderer}
                  />
                  {userInfo.isAdmin && (
                    <Column
                      width={130}
                      label=""
                      className="text-right"
                      disableSort
                      cellRenderer={this.actionsRenderer}
                      dataKey="instanceName"
                    />
                  )}
                </Table>
              )}
            </AutoSizer>
          </Container>
          <Container className="field flex-row">
            <div className="flex-grow" />
            <Button size="small" type="primary" loading={isLoading} disabled={hasError} onClick={this.handleSaveClick}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        </form>

        {this.state.showRestoreLogRawDataModal && (
          <SettingRenderers.RenderRestoreLogRawDataModal
            intl={intl}
            credentials={credentials}
            projects={projects}
            projectInstanceComponentMap={projectInstanceComponentMap}
            updateLastActionInfo={updateLastActionInfo}
            createSetAction={createSetAction}
            projectName={projectName}
            onClose={() => {
              this.setState({ showRestoreLogRawDataModal: false });
            }}
          />
        )}
      </Container>
    );
  }
}

const DetectionStatus = injectIntl(DetectionStatusCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects, projectInstanceComponentMap } = state.app;
    const { credentials, userInfo } = state.auth;

    const { detectionStatus, projectDetectionStatus, pageInfo } = state.settings;
    return {
      location,
      loadStatus,
      projects,
      projectInstanceComponentMap,
      credentials,
      userInfo,
      detectionStatus,
      projectDetectionStatus,
      pageInfo,
    };
  },
  {
    push,
    createLoadAction,
    createSetAction,
    showAppAlert,
    updateLastActionInfo,
  },
)(DetectionStatus);
