import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Checkbox, Divider, Select } from 'antd';

const DefaultProps = {
  options: [],
  value: [],
  onChange: () => {
    console.log('Select Multiple Component missing onChange.');
  },
};

const SelectMultiple = (props) => {
  const { options, value, onChange, ...restProps } = { ...DefaultProps, ...props };
  const [filterOptions, setFilterOptions] = useState(options);
  const [searchValue, setSearchValue] = useState('');

  const getFilterValue = (searchValue, filterOptions) => {
    return R.filter((item) => item.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1, filterOptions);
  };

  const isCheckedAll = () => {
    const filterValue = R.map((item) => item.value, getFilterValue(searchValue, filterOptions));
    const diff = R.difference(filterValue, value);
    return diff.length === 0;
  };

  useEffect(() => {
    setFilterOptions(options);
  }, [options]);

  return (
    <div>
      <Select
        maxTagCount={1}
        style={{ width: '100%' }}
        {...restProps}
        showSearch
        mode="multiple"
        value={value}
        autoClearSearchValue={false}
        optionFilterProp="children"
        dropdownMatchSelectWidth={false}
        onChange={(values) => onChange(values)}
        onDropdownVisibleChange={(open) => {
          if (!open) {
            setSearchValue('');
            setFilterOptions(options);
          }
        }}
        dropdownRender={(menu) => {
          return (
            <div>
              <div className="flex-row" style={{ padding: '5px 12px' }} onMouseDown={(event) => event.preventDefault()}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={isCheckedAll()}
                  onChange={(e) => {
                    const { checked } = e.target;
                    const filterValue = R.map((item) => item.value, getFilterValue(searchValue, filterOptions));
                    const diff = R.difference(value, filterValue);
                    onChange(checked ? [...diff, ...filterValue] : diff);
                  }}
                />
                <span>Select all</span>
              </div>
              <Divider style={{ margin: '4px 0' }} />
              {menu}
            </div>
          );
        }}
        onSearch={(searchValue) => {
          const newOptions = searchValue
            ? R.filter((item) => item.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1, options)
            : options;
          setSearchValue(searchValue);
          setFilterOptions(newOptions);
        }}
      >
        {R.map((item) => {
          return (
            <Select.Option className="hide-icon" key={item.value} value={item.value}>
              <Checkbox checked={value.includes(item.value)} onChange={(e) => null} style={{ marginRight: 8 }} />
              {item.label}
            </Select.Option>
          );
        }, options)}
      </Select>
    </div>
  );
};

export default SelectMultiple;
