/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
/* eslint-disable no-console */

/**
 * Convert log anomaly string derivedAnomalyString to array
 * The string contains: timestamp, anomaly ratio, pattern id and hints
 * "1388541600000, anomaly ratio: 2.3393375001524683, neuron Id: 865,
 * Fault hints: 1.neuron 165(1.0)(8.333333015441895)\n"
 **/

import * as R from 'ramda';
import { isArray } from 'lodash';

const logPatternAnomalyToMap = (derivedAnomalyString) => {
  const anomalies = {};
  if (!derivedAnomalyString || !isArray(derivedAnomalyString)) return anomalies;

  R.forEach((group) => {
    if (group.anomalies) {
      R.forEach((line) => {
        // Ignore empty string
        if ((line || '').trim()) {
          const parts = line.split(',');
          if (parts.length !== 4) {
            console.error('[IFAPI] Anomaly string needs 4 parts seperated by ",", ignored', line);
          } else {
            const timestamp = parseInt(parts[0], 10);
            // parts[1] and parts[2] is ignored, they are used for all clusters.

            // parts[3] Fault hints like:
            // 1.neuron 165(1.0)(8.333333015441895);2.Sub_cause_type[node0](4.0)(1.0);\n
            //
            // contain the pattern id(165), freqence count (1.0) and frequency percentage pct(8.33..)
            const hintsStr = (parts[3].split(':')[1] || '').trim();

            const hints = R.filter((hint) => Boolean(hint), R.map((hint) => hint.trim(), hintsStr.split(';')));
            if (hints.length === 0) {
              console.warn('[IFAPI] Fault hints contains empty strings', hintsStr);
            }

            R.forEach((hint) => {
              const hintRegex = /.*neuron\s*(\d*).*\(([0-9.]*)\).*\(([-]?[0-9.]*)\).*/gi;
              const matches = hintRegex.exec(hint);
              if (!matches || matches.length !== 4) {
                console.error('[IFAPI] Ignore wrong hintsStr, no match nid, val and pct:', hint, matches);
              } else {
                const nid = parseInt(matches[1], 10);
                const val = parseFloat(matches[2]);
                const pct = parseFloat(matches[3]);
                const isHot = pct > 0;
                const isCold = pct <= 0;

                // Store the data in a map to improve performance.
                const nidStr = nid.toString();
                const tsStr = timestamp.toString();
                if (!anomalies[nidStr]) {
                  anomalies[nidStr] = {};
                }
                if (anomalies[nidStr][tsStr]) {
                  console.error('[IFAPI] Duplicated anomaly value at the same time', hint, timestamp);
                }
                anomalies[nidStr][tsStr] = { val, pct, isHot, isCold, nid };
              }
            }, hints);
          }
        }
      }, group.anomalies.split('\\n'));
    }
  }, derivedAnomalyString);

  return anomalies;
};

export default logPatternAnomalyToMap;
