import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';

import { appFieldsMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { getPatternNameIcon, LogRenderers } from '../../../common/utils';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { getInstanceDisplayNameFn } from '../utils';

// echart option html
export const OptionHtml = ({
  data,
  detailKey,
  optionDetail,
  intl,
  currentTheme,
  showIcon,
  instanceDisplayNameMap,
}: Object) => {
  const { sampleMsg, direction, intervalCount, count, shortDescription } = optionDetail[detailKey] || {};
  let rawDataJson;
  try {
    rawDataJson = JSON.parse(sampleMsg);
  } catch (error) {
    // console.debug(error);
  }

  const patternIdAndName = R.toString(data.patternName || data.patternId);
  const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
  const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });

  const isHigher = direction ? direction.toLowerCase() === 'higher' : null;
  const { instanceDisplayName, viewContent } = getInstanceDisplayNameFn({
    instanceDisplayNameMap,
    instanceName: data.instanceName,
    intl,
  });
  return ReactDOMServer.renderToStaticMarkup(
    <div className={`formatter-wrap ${instanceDisplayName ? 'formatter-wrap-w' : ''}`}>
      <div>
        <span className="formatter-lable">{intl.formatMessage(eventActionMessages.componentName)}:</span>
        <span className="formatter-content">{data.componentName}</span>
      </div>
      {viewContent}
      <div>
        <span className="formatter-lable">{intl.formatMessage(appFieldsMessages.patternIdName)}:</span>
        <span className="formatter-content">
          {showIcon && patternIcon}
          {`[${data.patternId}] ${data.patternName || data.patternId}`}
        </span>
      </div>
      {data.dataType === 1 && (
        <div>
          <span className="formatter-lable">{intl.formatMessage(eventMessages.fieldAnomalyType)}:</span>
          <span className="formatter-content">{data.anomalyType}</span>
        </div>
      )}
      <div>
        <span className="formatter-lable">
          {data.dataType === 1 ? intl.formatMessage(appFieldsMessages.count) : 'Duration (min)'}:
        </span>
        <span className="formatter-content">{data.count}</span>
      </div>
      <div className="flex-row">
        <span className="formatter-lable" style={{ flexShrink: 0 }}>
          {intl.formatMessage(eventMessages.shortDescription)}:
        </span>
        <span className="formatter-content">
          {(sampleMsg || shortDescription) && (
            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              {!rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (sampleMsg || shortDescription || '').split('\n')),
                )}
              {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
            </div>
          )}
          {/* {metricName && (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <span style={{ color: 'blue' }}>{metricName}</span>
              <span style={{ color: isHigher ? '#fb786d' : '#58b7ff' }}>{` (count: ${intervalCount})`}</span>
            </div>
          )} */}
        </span>
      </div>
    </div>,
  );
};

export const PopoverContentHtml = ({
  data,
  detailKey,
  optionDetail,
  intl,
  currentTheme,
  showIcon,
  instanceDisplayNameMap,
}: Object) => {
  const { sampleMsg, direction, intervalCount, count, shortDescription } = optionDetail[detailKey] || {};
  let rawDataJson;
  try {
    rawDataJson = JSON.parse(sampleMsg);
  } catch (error) {
    // console.debug(error);
  }
  const isHigher = direction ? direction.toLowerCase() === 'higher' : null;

  const patternIdAndName = R.toString(data.patternName || data.patternId);
  const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
  const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });

  const { instanceDisplayName, viewContent } = getInstanceDisplayNameFn({
    instanceDisplayNameMap,
    instanceName: data.instanceName,
    intl,
  });

  return (
    <div className={`formatter-wrap ${instanceDisplayName ? 'formatter-wrap-w' : ''}`}>
      <div>
        <span className="formatter-lable">{intl.formatMessage(eventActionMessages.componentName)}:</span>
        <span>{data.componentName}</span>
      </div>
      {viewContent}
      <div>
        <span className="formatter-lable">{intl.formatMessage(appFieldsMessages.patternIdName)}:</span>
        <span>
          {showIcon && patternIcon}
          {`[${data.patternId}] ${data.patternName || data.patternId}`}
        </span>
      </div>
      {data.dataType === 1 && (
        <div>
          <span className="formatter-lable">{intl.formatMessage(eventMessages.fieldAnomalyType)}:</span>
          <span>{data.anomalyType}</span>
        </div>
      )}
      <div>
        <span className="formatter-lable">
          {data.dataType === 1 ? intl.formatMessage(appFieldsMessages.count) : 'Duration (min)'}:
        </span>
        <span>{data.count}</span>
      </div>
      <div className="flex-row">
        <span className="formatter-lable" style={{ flexShrink: 0 }}>
          {intl.formatMessage(eventMessages.shortDescription)}:
        </span>
        <span>
          {(sampleMsg || shortDescription) && (
            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              {!rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (sampleMsg || shortDescription || '').split('\n')),
                )}
              {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
            </div>
          )}
          {/* {metricName && (
            <>
              <span style={{ color: 'blue' }}>{metricName}</span>
              <span style={{ color: isHigher ? '#fb786d' : '#58b7ff' }}>{` (count: ${intervalCount})`}</span>
            </>
          )} */}
        </span>
      </div>
    </div>
  );
};

export const PopverTableHtml = ({ data, detailKey, optionDetail, intl, currentTheme, showIcon }: Object) => {
  const { sampleMsg, shortDescription } = optionDetail[detailKey] || {};
  let rawDataJson;
  try {
    rawDataJson = JSON.parse(sampleMsg);
  } catch (error) {
    // console.debug(error);
  }

  const patternIdAndName = R.toString(data.patternName || data.patternId);
  const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
  const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });

  return (
    <div className="formatter-wrap">
      <div>
        <span className="formatter-lable">{intl.formatMessage(appFieldsMessages.patternIdName)}:</span>
        <span>
          {showIcon && patternIcon}
          {`[${data.patternId}] ${data.patternName || data.patternId}`}
        </span>
      </div>
      <div className="flex-row">
        <span className="formatter-lable" style={{ flexShrink: 0 }}>
          {intl.formatMessage(eventMessages.shortDescription)}:
        </span>
        <span>
          {(sampleMsg || shortDescription) && (
            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              {!rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (sampleMsg || shortDescription || '').split('\n')),
                )}
              {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
            </div>
          )}
          {/* {metricName && (
            <>
              <span style={{ color: 'blue' }}>{metricName}</span>
              <span style={{ color: isHigher ? '#fb786d' : '#58b7ff' }}>{` (count: ${intervalCount})`}</span>
            </>
          )} */}
        </span>
      </div>
    </div>
  );
};

export const anomalousOptionHtml = ({
  data,
  intl,
  typeName,
  isCost = false,
  viewBotton = false,
  instanceDisplayNameMap,
}: Object) => {
  let viewTypeName = intl.formatMessage(eventMessages.projectName);
  if (typeName === 'componentName') {
    viewTypeName = intl.formatMessage(eventActionMessages.componentName);
    if (isCost) {
      viewTypeName = 'Accounts name';
    }
  } else if (typeName === 'instanceName') {
    viewTypeName = intl.formatMessage(appFieldsMessages.instanceName);
    if (isCost) {
      viewTypeName = 'Services name';
    }
  }

  let viewTypeNameContent = (
    <div>
      <span className="formatter-lable">{viewTypeName}:</span>
      <span className="formatter-content">
        {typeName === 'projectName' ? data.projectDisplayName || data[typeName] : data[typeName]}
      </span>
    </div>
  );

  let instanceDisplayName;
  if (typeName === 'instanceName') {
    const { instanceDisplayName: viewInstanceDisplayName, viewContent } = getInstanceDisplayNameFn({
      instanceDisplayNameMap,
      instanceName: data[typeName],
      instanceTitle: viewTypeName,
      intl,
      isCost,
    });
    instanceDisplayName = viewInstanceDisplayName;
    viewTypeNameContent = viewContent;
  }

  let numViewType = intl.formatMessage(DashboardMessages.score);
  if (isCost) {
    numViewType = 'Cost';
  }

  const jumpData = R.join('-QvQ-', [data.componentName, data.instanceName]);

  const detailsBtn =
    viewBotton && !isCost
      ? `<div style="margin-bottom: 8px; width: fit-content" onclick="window.dashJumpToInvestigation('${jumpData}')"><button type="button" class="ant-btn ant-btn-default ant-btn-sm">${intl.formatMessage(
          appFieldsMessages.details,
        )}</button></div>`
      : '';

  const box =
    `<div class="formatter-wrap ${instanceDisplayName ? 'formatter-wrap-w' : ''}">` +
    `${detailsBtn}` +
    `${ReactDOMServer.renderToStaticMarkup(
      <>
        {typeName === 'instanceName' && !isCost && (
          <div>
            <span className="formatter-lable">{intl.formatMessage(eventActionMessages.componentName)}:</span>
            <span className="formatter-content">{data.componentName}</span>
          </div>
        )}
        {viewTypeNameContent}
        <div>
          <span className="formatter-lable">{numViewType}:</span>
          <span className="formatter-content">{`${numViewType === 'Cost' ? '$ ' : ''}${Number(
            Number.parseFloat(data.value).toFixed(2),
          )}`}</span>
        </div>
      </>,
    )}` +
    `</div>`;
  return box;
};

export const anomalousPopoverHtml = ({ rowData, typeName, intl, isCost = false }) => {
  let viewTypeName = intl.formatMessage(eventMessages.projectName);
  if (typeName === 'componentName') {
    viewTypeName = intl.formatMessage(eventActionMessages.componentName);
    if (isCost) {
      viewTypeName = 'Accounts name';
    }
  } else if (typeName === 'instanceName') {
    viewTypeName = intl.formatMessage(appFieldsMessages.instanceName);
    if (isCost) {
      viewTypeName = 'Services name';
    }
  }

  let numViewType = intl.formatMessage(DashboardMessages.score);
  if (isCost) {
    numViewType = 'Cost';
  }
  return (
    <div className="formatter-wrap">
      {typeName === 'instanceName' && !isCost && (
        <div>
          <span className="formatter-lable">{intl.formatMessage(eventActionMessages.componentName)}:</span>
          <span className="formatter-content">{rowData.componentName}</span>
        </div>
      )}
      <div>
        <span className="formatter-lable">{viewTypeName}:</span>
        <span className="formatter-content">
          {typeName === 'projectName' ? rowData.projectDisplayName || rowData[typeName] : rowData[typeName]}
        </span>
      </div>
      <div>
        <span className="formatter-lable">{numViewType}:</span>
        <span className="formatter-content">{`${numViewType === 'Cost' ? '$ ' : ''}${Number(
          Number.parseFloat(rowData.value).toFixed(2),
        )}`}</span>
      </div>
    </div>
  );
};

export const rootCauseOptionHtml = ({ data, detailKey, optionDetail, intl, currentTheme }: Object) => {
  const { rootCause, count } = data;
  const { sampleMsg, shortDescription } = optionDetail[detailKey] || {};
  let rawDataJson;
  try {
    rawDataJson = JSON.parse(sampleMsg);
  } catch (error) {
    // console.debug(error);
  }
  return ReactDOMServer.renderToStaticMarkup(
    <div className="formatter-wrap">
      <div>
        <span className="formatter-lable">{intl.formatMessage(eventMessages.projectName)}:</span>
        <span className="formatter-content">{rootCause.projectName}</span>
      </div>
      <div>
        <span className="formatter-lable">{intl.formatMessage(appFieldsMessages.instanceName)}:</span>
        <span className="formatter-content">{rootCause.instanceName}</span>
      </div>
      <div>
        <span className="formatter-lable">{intl.formatMessage(appFieldsMessages.patternIdName)}:</span>
        <span className="formatter-content">{`[${rootCause.patternId}] ${
          rootCause.patternName || rootCause.patternId
        }`}</span>
      </div>
      <div>
        <span className="formatter-lable">{intl.formatMessage(appFieldsMessages.count)}:</span>
        <span className="formatter-content">{count}</span>
      </div>
      <div className="flex-row">
        <span className="formatter-lable" style={{ flexShrink: 0 }}>
          {intl.formatMessage(eventMessages.shortDescription)}:
        </span>
        <span className="formatter-content">
          {(sampleMsg || shortDescription) && (
            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              {!rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (sampleMsg || shortDescription || '').split('\n')),
                )}
              {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
            </div>
          )}
        </span>
      </div>
    </div>,
  );
};

export const rootCausePopoverHtml = ({
  rowData,
  detailKey,
  optionDetail,
  intl,
  currentTheme,
  hideCount,
  instanceDisplayNameMap,
}: Object) => {
  const { instanceName, count, patternId, projectName, patternName, projectDisplayName } = rowData;
  const { sampleMsg, shortDescription } = optionDetail[detailKey] || {};
  let rawDataJson;
  try {
    rawDataJson = JSON.parse(sampleMsg);
  } catch (error) {
    // console.debug(error);
  }

  const { instanceDisplayName, viewContent } = getInstanceDisplayNameFn({ instanceDisplayNameMap, instanceName, intl });
  return (
    <div className={`formatter-wrap ${instanceDisplayName ? 'formatter-wrap-w' : ''}`}>
      <div>
        <span className="formatter-lable">{intl.formatMessage(eventMessages.projectName)}:</span>
        <span className="formatter-content">{projectDisplayName || projectName}</span>
      </div>
      {viewContent}
      <div>
        <span className="formatter-lable">{intl.formatMessage(appFieldsMessages.patternIdName)}:</span>
        <span className="formatter-content">{`[${patternId}] ${patternName || patternId}`}</span>
      </div>
      {!hideCount && (
        <div>
          <span className="formatter-lable">{intl.formatMessage(appFieldsMessages.count)}:</span>
          <span className="formatter-content">{count}</span>
        </div>
      )}
      <div className="flex-row">
        <span className="formatter-lable" style={{ flexShrink: 0 }}>
          {intl.formatMessage(eventMessages.shortDescription)}:
        </span>
        <span className="formatter-content">
          {(sampleMsg || shortDescription) && (
            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              {!rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (sampleMsg || shortDescription || '').split('\n')),
                )}
              {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
            </div>
          )}
        </span>
      </div>
    </div>
  );
};
