/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Spin, Popconfirm, message, Alert } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Container, Table, Column, AutoSizer } from '../../../lib/fui/react';
// import { Defaults } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appButtonsMessages, appMessages } from '../../../common/app/messages';

import AddSlackWebhookModal from './AddSlackWebhookModal';
import { parseJSON } from '../../../common/utils';

type Props = {
  // eslint-disable-next-line
  onClose: Function,
  refresh: Number,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  replace: Function,
  updateLastActionInfo: Function,
};

class ServiceSlackWebhookModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { systemsMap } = props;

    this.state = {
      isLoading: false,
      isDeletingMap: {},
      eventList: [],

      showAddServiceModal: false,
      activeIncident: null,
      systemsMap,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refresh !== nextProps.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials, userInfo } = props;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('system/externalServlies/list'), {
      ...credentials,
      serviceProvider: 'Slack',
    })
      .then(async (data) => {
        const { success, message, extServiceAllInfo } = data || {};

        let eventList = [];
        let userNameList = [];
        let allSystem = [];
        if (success) {
          eventList = extServiceAllInfo || [];
          eventList = R.filter((item) => item.serviceProvider === 'Slack', eventList);
          allSystem = props.systemsMap;
          if (userInfo.isAdmin || userInfo.isLocalAdmin) {
            userNameList = R.map((item) => item.userName, eventList);
            userNameList = R.uniq(userNameList);
            allSystem = await this.getUserSystemMap(userNameList);
          }
        }
        this.setState({ isLoading: false, errMsg: !success ? message : null, eventList, systemsMap: allSystem });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, errMsg: String(err), eventList: [] });
      });
  }

  @autobind
  getUserSystemMap(userNameList) {
    const { credentials } = this.props;
    const requests = [];
    R.forEach((customerName) => {
      requests.push(
        fetchGet(getEndpoint('systemframework', 2), {
          ...credentials,
          customerName,
        }),
      );
    }, userNameList);
    return Promise.all(requests)
      .then((data) => {
        let allSystemMap = {};
        R.forEach((item) => {
          let { ownSystemArr } = item;
          ownSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system };
          }, ownSystemArr || []);
          const systemsMap = {};
          R.forEach(
            (system) => {
              const { systemDisplayName, systemKey, projectDetailsList, isShared, ...rest } = system;
              const { environmentName, systemName: systemId, userName } = systemKey || {};
              const newProjects = parseJSON(projectDetailsList) || [];
              systemsMap[systemId] = {
                ...rest,
                isShared,
                environmentName,
                systemId,
                systemName: systemDisplayName || systemId,
                owner: userName,
                projectDetailsList: newProjects,
              };
            },
            [...ownSystemArr],
          );
          allSystemMap = { ...allSystemMap, ...systemsMap };
        }, data || []);
        return allSystemMap;
      })
      .catch((e) => {
        console.log(String(e));
      });
  }

  @autobind
  handleAddNewClick() {
    this.setState({ showAddServiceModal: true });
  }

  @autobind
  handleEventClick({ rowData }) {
    this.setState({ showAddServiceModal: true, activeIncident: rowData });
  }

  @autobind
  handleExtsvcRemove(rowData) {
    const { intl, credentials } = this.props;
    const { isDeletingMap } = this.state;
    const { id, userName } = rowData;
    const key = `${id}-${userName}`;

    this.setState({ isDeletingMap: { ...isDeletingMap, [key]: true } });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('service-integration'), {
      ...credentials,
      serviceProvider: 'Slack',
      operation: 'delete',
      // eslint-disable-next-line
      service_id: id,
      serviceOwner: userName,
      customerName: userName,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isDeletingMap: { ...isDeletingMap, [key]: false } });
          this.reloadData(this.props);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isDeletingMap: { ...isDeletingMap, [key]: false }, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isDeletingMap: { ...isDeletingMap, [key]: false }, errMsg: String(err) });
      });
  }

  @autobind
  systemRenderer({ rowData }) {
    const { systemsMap } = this.state;
    const { systemId } = rowData;
    return <div className="flex-row">{get(systemsMap, [systemId, 'systemName'], systemId)}</div>;
  }

  @autobind
  optionsRenderer({ rowData }) {
    const { options } = rowData;
    return <div className="max-width hidden-line-with-ellipsis">{R.join(', ', parseJSON(options) || [])}</div>;
  }

  @autobind
  controlRenderer({ rowData }) {
    const { intl } = this.props;
    const { isDeletingMap } = this.state;
    const { id, userName } = rowData;
    const key = `${id}-${userName}`;

    return (
      <div className="flex-row">
        <Button
          size="small"
          type="primary"
          style={{ marginLeft: 8 }}
          onClick={() => this.handleEventClick({ rowData })}
        >
          <EditOutlined /> {intl.formatMessage(appButtonsMessages.edit)}
        </Button>
        <Popconfirm
          placement="topRight"
          title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
          onConfirm={() => this.handleExtsvcRemove(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button
            size="small"
            className="button-color-grey"
            loading={get(isDeletingMap, key, false)}
            style={{ marginLeft: 8 }}
            onClick={(event) => event.stopPropagation()}
          >
            <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.delete)}
          </Button>
        </Popconfirm>
      </div>
    );
  }

  render() {
    const { isLoading, errMsg, eventList } = this.state;
    return (
      <Container className="full-width full-height flex-col">
        <div style={{ marginBottom: 12 }}>
          <Button size="small" type="primary" onClick={this.handleAddNewClick}>
            Add New
          </Button>
        </div>
        <div className="flex-grow flex-min-height">
          <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
            {errMsg && <Alert message={errMsg} type="error" showIcon />}
            {!errMsg && (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    className="with-border"
                    width={width}
                    height={height}
                    headerHeight={40}
                    rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                    rowHeight={40}
                    rowCount={eventList.length}
                    rowGetter={({ index }) => eventList[index]}
                  >
                    <Column width={100} label="Create by" dataKey="createdBy" />
                    <Column width={100} label="User name" dataKey="userName" />
                    <Column
                      width={150}
                      label="System name"
                      dataKey="systemId"
                      flexGrow={1}
                      cellRenderer={this.systemRenderer}
                    />
                    <Column width={150} label="Webhook" dataKey="webHook" flexGrow={1} />
                    <Column width={150} label="Channel name" dataKey="channelName" flexGrow={1} />
                    <Column
                      width={150}
                      label="Options"
                      dataKey="options"
                      cellRenderer={this.optionsRenderer}
                      flexGrow={1}
                    />
                    <Column
                      width={190}
                      label=""
                      dataKey="id"
                      className="text-right"
                      cellRenderer={this.controlRenderer}
                    />
                  </Table>
                )}
              </AutoSizer>
            )}
          </Spin>
        </div>

        {this.state.showAddServiceModal && (
          <AddSlackWebhookModal
            activeIncident={this.state.activeIncident}
            onClose={(reload) =>
              this.setState({ showAddServiceModal: false, activeIncident: null }, () => {
                if (reload) {
                  this.reloadData(this.props);
                }
              })
            }
          />
        )}
      </Container>
    );
  }
}

const ServiceSlackWebhookModal = injectIntl(ServiceSlackWebhookModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return { location, systemsMap, userInfo, credentials };
  },
  { replace, updateLastActionInfo },
)(ServiceSlackWebhookModal);
