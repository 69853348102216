/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Button, Spin, Popconfirm, message, Alert, Typography } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
// import fetchPost from '../../../common/apis/fetchPost';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { Container, Table, Column, AutoSizer } from '../../../lib/fui/react';
import { buildUrl, parseLocation } from '../../../common/utils';
import { hideAppLoader, updateLastActionInfo } from '../../../common/app/actions';

import { appButtonsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { settingsMenusMessages } from '../../../common/settings/messages';
import { eventMessages } from '../../../common/metric/messages';

import EditStatusPageModal from './EditStatusPageModal';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  credentials: Object,
  // eslint-disable-next-line
  replace: Function,
  hideAppLoader: Function,
  updateLastActionInfo: Function,
};

class StatusPageSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      refresh: null,
      isLoading: false,
      isDeletingMap: {},
      eventList: [],

      showEditStatusPageModal: false,
      activeIncident: undefined,

      cacheProjectMap: {},
      cacheMetricMap: {},
    };
  }

  componentDidMount() {
    const { hideAppLoader } = this.props;
    hideAppLoader();
    this.reloadData(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.refresh !== this.state.refresh) {
      this.reloadData(this.props);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials } = props;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('statuspage'), {
      ...credentials,
    })
      .then((data) => {
        const { success, message, statusPageList } = data || {};

        let eventList = [];
        if (success) {
          eventList = statusPageList || [];
        }

        this.setState({ isLoading: false, errMsg: !success ? message : null, eventList });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, errMsg: String(err), eventList: [] });
        // TODO: remove mock data
        // const eventList = [
        //   {
        //     id: 'xxxx',
        //     company: 'IF',
        //     metrics: [
        //       {
        //         projectName: 'test',
        //         owner: 'user',
        //         instance: '127.0.0.1',
        //         metric: 'CPU',
        //       },
        //     ],
        //   },
        // ];
        // this.setState({ isLoading: false, errMsg: null, eventList });
      });
  }

  @autobind
  handleRefresh() {
    this.setState({ refresh: moment.utc().valueOf() });
  }

  @autobind
  handleAddNewClick() {
    this.setState({ showEditStatusPageModal: true });
  }

  @autobind
  handleRemove(event, rowData) {
    event.stopPropagation();

    const { intl, credentials } = this.props;
    const { isDeletingMap } = this.state;
    const { id } = rowData;

    this.setState({ isDeletingMap: { ...isDeletingMap, [id]: true } });
    this.props.updateLastActionInfo();
    fetchDelete(getEndpoint('statuspage'), {
      ...credentials,
      id,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isDeletingMap: { ...isDeletingMap, [id]: false } });
          this.reloadData(this.props);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isDeletingMap: { ...isDeletingMap, [id]: false }, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isDeletingMap: { ...isDeletingMap, [id]: false }, errMsg: String(err) });
      });
  }

  @autobind
  urlCopyRenderer({ rowData }) {
    const { id } = rowData;
    const url = buildUrl(`${window.BASE_URL || ''}/report/companystatus`, {}, { id, category: 'day' });
    return (
      <div className="flex-row">
        <Typography.Paragraph
          copyable={{ text: url }}
          style={{ margin: 0 }}
          onClick={(event) => event.stopPropagation()}
        >
          Public URL
        </Typography.Paragraph>
      </div>
    );
  }

  @autobind
  removeRenderer({ rowData }) {
    const { intl } = this.props;
    const { isDeletingMap } = this.state;
    const { id } = rowData;

    return (
      <div className="flex-row">
        <Button
          size="small"
          className="button-color-grey"
          style={{ marginRight: 8 }}
          onClick={(event) => {
            event.stopPropagation();
            this.setState({ showEditStatusPageModal: true, activeIncident: rowData });
          }}
        >
          {intl.formatMessage(appButtonsMessages.edit)}
        </Button>
        <Popconfirm
          placement="topRight"
          title={intl.formatMessage(appMessages.continueConfirm)}
          onConfirm={(event) => this.handleRemove(event, rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button
            size="small"
            className="button-color-grey"
            loading={get(isDeletingMap, id, false)}
            onClick={(event) => event.stopPropagation()}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </Popconfirm>
      </div>
    );
  }

  @autobind
  metricsRenderer({ rowData }) {
    const { metrics } = rowData;
    return (
      <div className="full-width hidden-line-with-ellipsis">
        {R.addIndex(R.map)((m, index) => {
          return <span key={index}>{`${m.metric}; `}</span>;
        }, metrics)}
      </div>
    );
  }

  @autobind
  changeCacheProjectMap(projectMap) {
    const { cacheProjectMap } = this.state;
    this.setState({ cacheProjectMap: { ...cacheProjectMap, ...projectMap } });
  }

  @autobind
  changeCacheMetricMap(metricMap) {
    const { cacheMetricMap } = this.state;
    this.setState({ cacheMetricMap: { ...cacheMetricMap, ...metricMap } });
  }

  render() {
    const { isLoading, errMsg, eventList } = this.state;
    const { intl, location } = this.props;
    const query = parseLocation(location);

    return (
      <Container fullHeight withGutter className="flex-col">
        <Container className="flex-row flex-center-align" breadcrumb>
          <div className="section">
            <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
            <span className="divider">/</span>
            <NavLink to={buildUrl(BaseUrls.StatusPageSetting, {}, query)}>
              <span className="label">{intl.formatMessage(settingsMenusMessages.statusPage)}</span>
            </NavLink>
          </div>
          <div className="flex-grow" />
          <div>
            <Button size="small" onClick={this.handleRefresh}>
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </div>
        </Container>
        <Container
          className="flex-grow flex-min-height flex-col content-bg corner-10"
          style={{
            margin: '0 16px 8px 16px',
            borderRadius: 4,
            padding: '8px 16px',
          }}
        >
          <div style={{ marginBottom: 12 }}>
            <Button size="small" type="primary" onClick={this.handleAddNewClick}>
              {intl.formatMessage(appButtonsMessages.addNew)}
            </Button>
          </div>

          <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
            {errMsg && <Alert message={errMsg} type="error" showIcon />}
            {!errMsg && (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    className="with-border"
                    width={width}
                    height={height}
                    headerHeight={40}
                    rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                    rowHeight={40}
                    rowCount={eventList.length}
                    rowGetter={({ index }) => eventList[index]}
                  >
                    <Column width={200} label={intl.formatMessage(eventMessages.company)} dataKey="company" />
                    <Column
                      width={150}
                      label={intl.formatMessage(eventMessages.metrics)}
                      dataKey="metrics"
                      flexGrow={1}
                      cellRenderer={this.metricsRenderer}
                    />
                    <Column
                      width={150}
                      label=""
                      dataKey="id"
                      className="text-right"
                      cellRenderer={this.urlCopyRenderer}
                    />
                    <Column
                      width={140}
                      label=""
                      dataKey="id"
                      className="text-right"
                      cellRenderer={this.removeRenderer}
                    />
                  </Table>
                )}
              </AutoSizer>
            )}
          </Spin>
        </Container>

        {this.state.showEditStatusPageModal && (
          <EditStatusPageModal
            activeIncident={this.state.activeIncident}
            onClose={(reload) => {
              this.setState({ showEditStatusPageModal: false, activeIncident: undefined }, () => {
                if (reload) {
                  this.reloadData(this.props);
                }
              });
            }}
            cacheProjectMap={this.state.cacheProjectMap}
            changeCacheProjectMap={this.changeCacheProjectMap}
            cacheMetricMap={this.state.cacheMetricMap}
            changeCacheMetricMap={this.changeCacheMetricMap}
          />
        )}
      </Container>
    );
  }
}

const StatusPageSetting = injectIntl(StatusPageSettingCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    return { location, credentials };
  },
  { replace, hideAppLoader, updateLastActionInfo },
)(StatusPageSetting);
