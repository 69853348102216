/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import VLink from 'valuelink';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Button, Alert, Select as AntSelect, DatePicker, Popover, Checkbox, Card } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Input } from '../../../../lib/fui/react';
import { Regex } from '../../../../common/utils';

import { projectWizardMessages } from '../../../../common/settings/messages';
import { authMessages } from '../../../../common/auth/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  credentials: Object,
  componentState: Object,
};

class ZendeskSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;
    this.state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      host: '',
      accountEmailAddress: '',
      apiToken: '',

      // default fields
      fields: [],
      defaultFields: [],
      timestampField: null,
      instanceField: null,
      additonalFields: [],
      historicalDates: [null, null],
      projectModelFlag: false,
      processPauseFlag: false,

      ...(componentState || {}),
    };
    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
    this.additonalFieldsOptions = [];
  }

  componentDidMount() {
    this.props.saveProjectInfo('Zendesk', {}, this.state);
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { intl, credentials } = this.props;
    const { host, accountEmailAddress, apiToken } = this.state;
    const projectCreationType = 'Zendesk';
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType,
      host,
      accountEmailAddress,
      apiToken,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          const fields = get(data, 'fields', []);
          const defaultFields = get(data, 'defaultFiedls', []);
          this.additonalFieldsOptions = R.map((item) => {
            return { value: item, label: item, disabled: defaultFields.includes(item) };
          }, fields);
          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
              fields,
              defaultFields,
              additonalFields: defaultFields,
            },
            () => {
              this.props.saveProjectInfo('Zendesk', {}, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('Zendesk', {}, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
          },
          () => {
            this.props.saveProjectInfo('Zendesk', {}, this.state);
          },
        );
      });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      host,
      accountEmailAddress,
      apiToken,
      timestampField,
      instanceField,
      additonalFields,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
    } = this.state;
    const { defaultFields } = this.state;
    const newFields = R.difference(additonalFields, defaultFields);
    this.props.createProject(
      'Zendesk',
      {
        host,
        accountEmailAddress,
        apiToken,
        operation: 'register',
        timestampField,
        instanceField,
        additonalFields: JSON.stringify(newFields || []),
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
        projectModelFlag,
        processPauseFlag,
      },
      this.state,
    );
  }

  render() {
    const { intl, isLoading, hasError } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,
      fields,
      timestampField,
      instanceField,
      additonalFields,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
    } = this.state;
    const hostLink = VLink.state(this, 'host').check(
      (x) => Boolean(x),
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const accountEmailAddressLink = VLink.state(this, 'accountEmailAddress')
      .check((x) => Boolean(x), intl.formatMessage(appFieldsMessages.inputRequired))
      .check((value) => Regex.email.test(value), intl.formatMessage(authMessages.errorsEmailIncorrect));
    const apiTokenLink = VLink.state(this, 'apiToken').check(
      (x) => Boolean(x),
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const hasVerifyError = hostLink.error || accountEmailAddressLink.error || apiTokenLink.error;
    const hasErrorRegister =
      hostLink.error ||
      accountEmailAddressLink.error ||
      apiTokenLink.error ||
      !timestampField ||
      !instanceField ||
      hasError;

    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.ZendeskIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item label="Host URL" required>
              <Input className="full-width" valueLink={hostLink} />
            </Form.Item>
            <Form.Item label="Zendesk account email address" required>
              <Input className="full-width" valueLink={accountEmailAddressLink} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Zendesk api token" required>
              <Input
                className="full-width"
                valueLink={apiTokenLink}
                type="password"
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>
        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            <Form.Item label="InsightFinder Analysis Timestamp in Zendesk" required>
              <AntSelect
                value={timestampField}
                onChange={(timestampField) => {
                  this.setState({ timestampField });
                }}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {R.map((field) => {
                  return <AntSelect.Option key={field}>{field}</AntSelect.Option>;
                }, fields)}
              </AntSelect>
            </Form.Item>
            <Form.Item
              label={
                <Popover
                  title={null}
                  content={
                    <div style={{ width: 320 }}>
                      This instance in InsightFinder represents the ticket grouping granularity for pattern learning.
                    </div>
                  }
                  placement="right"
                >
                  <span>
                    InsightFinder Analysis Instance in Zendesk
                    <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
                  </span>
                </Popover>
              }
              required
            >
              <AntSelect
                value={instanceField}
                onChange={(instanceField) => {
                  this.setState({ instanceField });
                }}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {R.map((field) => {
                  return <AntSelect.Option key={field}>{field}</AntSelect.Option>;
                }, fields)}
              </AntSelect>
            </Form.Item>
            <Form.Item label="InsightFinder Analysis Additonal Fields in Zendesk">
              <Checkbox.Group
                options={this.additonalFieldsOptions}
                value={additonalFields}
                onChange={(additonalFields) => {
                  this.setState({ additonalFields });
                }}
              />
            </Form.Item>

            <Form.Item label="Historical Date Range">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={historicalDates}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(historicalDates) => this.setState({ historicalDates })}
              />
              <div className="flex-row" style={{ marginTop: 16 }}>
                <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                  Enable holistic model
                </div>
                <Checkbox
                  checked={projectModelFlag}
                  onChange={(e) => {
                    this.setState({ projectModelFlag: e.target.checked });
                  }}
                />
              </div>
              <div className="flex-row" style={{ marginTop: 16 }}>
                <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                  Enable initial processing pause
                </div>
                <Checkbox
                  checked={processPauseFlag}
                  onChange={(e) => {
                    this.setState({ processPauseFlag: e.target.checked });
                  }}
                />
              </div>
            </Form.Item>
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasErrorRegister}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const ZendeskSetting = injectIntl(ZendeskSettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(ZendeskSetting);
