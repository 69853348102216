import React, { useState } from 'react';
import * as R from 'ramda';
import { Select } from 'antd';
import { Modal } from '../../../src/lib/fui/react';
import { appButtonsMessages } from '../../../src/common/app/messages';

type Props = {
  intl: Object,
  onClose: Function,
  settings: String[],
  jsonFields: String[],
  onSettingChanged: Function,
  summaryLoading: Boolean,
};

const SummarySettingsMode = ({ intl, onClose, settings, onSettingChanged, jsonFields, summaryLoading }: Props) => {
  const [currentSettings, setCurrentSettings] = useState(settings || []);
  return (
    <Modal
      title="Manage summary fields"
      visible
      keyboard={false}
      maskClosable={false}
      bodyStyle={{ padding: '10px 24px' }}
      onCancel={onClose}
      okText={intl.formatMessage(appButtonsMessages.save)}
      onOk={() => onSettingChanged(currentSettings)}
      okButtonProps={{ loading: summaryLoading }}
    >
      <div className="flex-col">
        <p>
          Summary fields add the value of a log field to the summary line at the beginning of a log entry. You can turn
          on or off default summary fields, and add and configure custom summary fields.
        </p>
        <label htmlFor="settings" style={{ fontWeight: 'bold', padding: '5px 0' }}>
          Custom summary fields:
        </label>
        <Select
          name="settings"
          mode="multiple"
          value={currentSettings}
          allowClear
          style={{ width: '100%' }}
          placeholder="Add a new field"
          onChange={(value) => setCurrentSettings(value)}
        >
          {R.map(
            (k) => (
              <Select.Option key={k}> {k} </Select.Option>
            ),
            jsonFields,
          )}
        </Select>
      </div>
    </Modal>
  );
};

export default SummarySettingsMode;
