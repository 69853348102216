/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../utils';
import parseEventList from './parsers/parseEventList';

const getQueryResult = (credentials: Credentials, params: Object) => {
  const { templateId, fixedParams, startTime, endTime, startTimestamp, endTimestamp, ...rest } = params;
  const { forecastInterval } = params;
  const { instanceGroup, isLog } = params;
  const start = params.start || 0;
  const limit = params.limit || Defaults.PagingSize;

  // TODO: Hack code to replace server side query template id for "Show me KPI violation prediction"
  const tmplId = templateId === '668119e595b3420dad08dd78b4299be6' ? 'de593074c127470b9758fe0182e6cb4b' : templateId;
  // change endTimestamp to day's end time if select by day
  let endTimeDayEnd = endTime;
  if (endTime && Number(endTime) === moment.utc(Number(endTime)).startOf('day').valueOf() && !forecastInterval) {
    endTimeDayEnd = moment.utc(Number(endTime)).endOf('day').valueOf();
  }

  return fetchGet(
    getEndpoint(`query/${tmplId}`),
    {
      ...credentials,
      ...rest,
      startTime,
      endTime: endTimeDayEnd,
      startTimestamp,
      endTimestamp,
      start,
      limit,
      topKFlag: true,
    },
    {},
    fixedParams,
  ).then((data) => {
    let resultList = [];
    const typeId = get(get(data, ['data'], [{}])[0], ['typeId'], tmplId);
    if (typeId === '0377137ccb9f4415b14908cb7e41a7fa') {
      resultList = R.map(
        (item) => ({
          typeId: item.typeId,
          result: { data: item.result, stats: item.stats, primaryKey: item.primaryKey },
          meta: item.meta,
          allInstances: item.all_instances_aggregate_result,
        }),
        get(data, 'data', []),
      );
    } else if (typeId === '535c5454d87d49bd970f18e311029a36') {
      resultList = R.map((item) => {
        const { eventsResult, appNameMapping } = item.result;
        let eventList = [];
        if (templateId === '38d06cc6d65e43e98c083946367c80a6') {
          eventList = parseEventList(get(eventsResult, 'All', {}), { isLog });
        } else if (templateId === 'f3afb75038244cb9bff7d738489884b9') {
          eventList = parseEventList(eventsResult, { isLog, instanceGroup });
        } else {
          eventList = parseEventList(eventsResult, { isLog, instanceGroup });
        }
        // get max duration
        const maxDuration = R.reduce(
          R.max,
          0,
          R.map((e) => e.duration, eventList),
        );
        return {
          typeId: item.typeId,
          result: { data: { appNameMapping, eventList, maxDuration }, stats: item.stats },
          meta: item.meta,
          allInstances: item.all_instances_aggregate_result,
        };
      }, get(data, 'data', []));
    } else {
      resultList = get(data, 'data', []);
    }

    return resultList;
  });
};

export default getQueryResult;
