/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 * */

import { Defaults } from '../app';
import { colorMap } from '../../web/share';
/**
 * Calculate the background and color from the anomaly level.
 */

const calcColorOfAnomalyLevel = (level) => {
  const idx = Math.max(0, 5 - level);
  const color = level === 0 ? colorMap.Info : Defaults.Colorbrewer[idx];
  const fontColor = level >= 3 ? '#fff' : '#000';

  return { level, color, fontColor };
};

const calcColorOfHealthScore = (healthScore) => {
  let color = colorMap.Health;
  let fontColor = '#000';
  if (healthScore < 30) {
    color = Defaults.Colorbrewer[0];
    fontColor = '#fff';
  } else if (healthScore < 50) {
    color = Defaults.Colorbrewer[1];
    fontColor = '#fff';
  } else if (healthScore < 70) {
    color = Defaults.Colorbrewer[2];
    fontColor = '#fff';
  } else if (healthScore < 90) {
    color = Defaults.Colorbrewer[3];
  }

  return { color, fontColor };
};

export { calcColorOfAnomalyLevel, calcColorOfHealthScore };
