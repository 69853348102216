/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { get } from 'lodash';
import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { getProjectHoliday } from '../../apis';
import { updateLastActionInfo } from '../../app/actions';
import { setProjectSettings } from '../actions';
import { apiEpicErrorHandle } from '../../errors';
import { getLoaderEpicAction } from '../../utils';

const loadProjectHolidayEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('LOAD_PROJECT_HOLIDAY').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);
    const { projectName } = params;

    let apiAction$ = Observable.concat(Observable.of(setProjectSettings({ holidayList: [] })), hideLoader);
    if (projectName) {
      apiAction$ = Observable.from(getProjectHoliday(credentials, params))
        .concatMap((d) => {
          const holidayList = get(d, ['data', 'holidayList'], []);
          return Observable.concat(Observable.of(setProjectSettings({ holidayList })), hideLoader);
        })
        .catch((err) => {
          Observable.concat(apiEpicErrorHandle(err), hideLoader);
        });
    }

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default loadProjectHolidayEpic;
