import React from 'react';
import { get, isNumber } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import VLink from 'valuelink';
import { Button, message, Spin } from 'antd';

import fetchPostJson from '../../src/common/apis/fetchPostJson';
import getEndpoint from '../../src/common/apis/getEndpoint';
import { State } from '../../src/common/types';
import { Modal, Input } from '../../src/lib/fui/react';
import { updateMetricEventPatternName } from '../../src/common/metric/actions';
import { getLoadStatus } from '../../src/common/utils';
import { ActionTypes } from '../../src/common/log/actions';
import { createLoadAction, updateLastActionInfo } from '../../src/common/app/actions';

import { eventMessages, eventActionMessages } from '../../src/common/metric/messages';
import { settingsMessages } from '../../src/common/settings/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  createLoadAction: Function,
  currentLoadingComponents: Object,
  updateMetricEventPatternName: Function,

  actionDetailsName: String,
  incident: Object,
  project: Object,
  projectName: String,
  incidentPatternName: String,
  instanceGroup: String,
  eventType: String,
  onClose: Function,
  // eslint-disable-next-line
  onNameChanged: Function,

  userAction: String,
  credentials: Object,
  updateLastActionInfo: Function,
  userInfo: Object,
};

const titleMap = {
  setPatternName: eventMessages.setPatternName,
  triageReport: eventMessages.editTriageReport,
  setComponentName: eventMessages.setComponentName,
};

class TakeEventTriageModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    const { userAction, incidentPatternName } = props;

    this.dataLoader = 'event_triage_loader';
    this.patternNameLoadingKey = 'metric_action_pattername';
    this.nameChanged = false;

    const nid = props.incident.nid || props.incident.neuronId || props.incident.patternId;
    const activePatternName =
      incidentPatternName || get(props.incident, 'patternName', isNumber(nid) ? `Pattern ${nid}` : '');
    this.state = {
      patternName: activePatternName,
      customAction: userAction,
      componentName: props.incident.componentName || '',
      isLoadingComponentName: false,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLoadingComponents, incident } = nextProps;

    if (this.props.incident !== nextProps.incident || this.props.projectName !== nextProps.projectName) {
      this.reloadData(nextProps);
    }

    if (this.props.userAction !== nextProps.userAction) {
      this.setState({ customAction: nextProps.userAction });
    }

    const isLoadingPatternName = get(currentLoadingComponents, this.patternNameLoadingKey, false);
    if (!isLoadingPatternName && this.nameChanged) {
      this.nameChanged = false;
      const nid = incident.nid || incident.neuronId || incident.patternId;
      nextProps.onNameChanged(this.state.patternName, nid);
    }
  }

  @autobind
  reloadData(props) {
    const { createLoadAction, incident, projectName, actionDetailsName } = props;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    if (actionDetailsName === 'triageReport') {
      createLoadAction(
        ActionTypes.LOAD_EVENT_TRIAGE,
        {
          projectName,
          neuronId: nid,
          operation: 'loadTriage',
          grouping: 'All',
        },
        this.dataLoader,
      );
    }
  }

  @autobind
  handlePatternNameClick() {
    const { incident, updateMetricEventPatternName, project, projectName, instanceGroup, eventType } = this.props;

    const isLog = get(project, 'isLog') || get(incident, ['isLog']);
    const timestamp = get(incident, ['key', 'timestamp']) || get(incident, ['timestamp']);
    const instanceName = get(incident, ['key', 'instanceName']) || get(incident, ['instanceName']);
    const logProjectParams = { instanceName, instanceGroup };
    const { rootCauseJson, predictFlag } = incident;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    const anomalyRatio = incident.anomalyRatio || incident.anomalyScore;

    const { patternName } = this.state;
    updateMetricEventPatternName(
      projectName,
      {
        ...logProjectParams,
        nid,
        patternName,
        timestamp,
        eventType,
        eventData: JSON.stringify([
          { rootCauseJson: JSON.stringify(rootCauseJson), neuronId: nid, anomalyRatio, predictFlag },
        ]),
      },
      { [this.patternNameLoadingKey]: true },
      this.callBackSetPatternName,
    );
  }

  @autobind
  callBackSetPatternName() {
    this.nameChanged = true;
  }

  @autobind
  handleTriageSave() {
    const { createLoadAction, projectName, eventType, incident } = this.props;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    const { customAction } = this.state;
    if (customAction) {
      createLoadAction(
        ActionTypes.SAVE_EVENT_TRIAGE,
        {
          projectName,
          eventType,
          neuronId: nid,
          triageAction: customAction,
          operation: 'saveTriage',
        },
        this.dataLoader,
      );
    }
  }

  @autobind
  handleCompoentNameClick() {
    const { incident, credentials, userInfo, intl } = this.props;
    const { componentName } = this.state;
    const { containerInfo, instanceName } = incident;

    const { projectOwner } = incident;
    let { projectName } = incident;
    projectName =
      userInfo.isAdmin || projectOwner !== userInfo.userName ? `${projectName}@${projectOwner}` : projectName;

    const grouping = {
      appName: componentName,
      ...(containerInfo
        ? { containerName: containerInfo?.containerName, instanceName: containerInfo?.instanceName }
        : { instanceName }),
    };

    this.setState({ isLoadingComponentName: true });
    this.props.updateLastActionInfo();
    fetchPostJson(getEndpoint('customgrouping'), { ...credentials, projectName }, [grouping])
      .then((data) => {
        message.success(intl.formatMessage(settingsMessages.infoProjectSettingSaved));
        this.setState({ isLoadingComponentName: false }, () => {
          this.props.onClose(true);
        });
      })
      .catch((err) => {
        this.setState({ isLoadingComponentName: false });
        message.error(err.message || String(err));
      });
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  render() {
    const { intl, loadStatus, currentLoadingComponents } = this.props;
    const { actionDetailsName, userAction } = this.props;
    const { customAction, isLoadingComponentName } = this.state;

    const patternNameLink = VLink.state(this, 'patternName').check((x) => Boolean(x), 'Pattern name is required');
    const componentNameLink = VLink.state(this, 'componentName').check((x) => Boolean(x), 'Component name is required');

    const hasErrorPatternName = patternNameLink.error;
    const isLoadingPatternName = get(currentLoadingComponents, this.patternNameLoadingKey, false);
    const { isLoading } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const hasError = customAction === userAction;
    const hasErrorComponetName = componentNameLink.error;

    return (
      <Modal
        width={700}
        title={intl.formatMessage(titleMap[actionDetailsName])}
        visible
        footer={null}
        onCancel={() => this.handleClose()}
        maskClosable={false}
      >
        {actionDetailsName === 'setPatternName' && (
          <Spin spinning={isLoadingPatternName}>
            <h5 style={{ display: 'inline-block', width: 80 }}>{`${intl.formatMessage(
              eventActionMessages.patternName,
            )}:`}</h5>
            <Input valueLink={patternNameLink} style={{ width: 340 }} />
            <Button
              type="primary"
              size="small"
              style={{ float: 'right' }}
              loading={isLoadingPatternName}
              disabled={hasErrorPatternName}
              onClick={this.handlePatternNameClick}
            >
              {intl.formatMessage(eventActionMessages.setPatternName)}
            </Button>
          </Spin>
        )}

        {actionDetailsName === 'triageReport' && (
          <Spin spinning={isLoading}>
            <form className="ui reply form">
              <div className="field">
                <textarea
                  value={customAction}
                  rows="4"
                  onChange={(e) => this.setState({ customAction: e.target.value })}
                />
              </div>
            </form>
            <div className="flex-row flex-end-justify">
              <Button
                type="primary"
                size="small"
                style={{ marginTop: 16 }}
                loading={isLoading}
                disabled={hasError}
                onClick={this.handleTriageSave}
              >
                {intl.formatMessage(eventActionMessages.saveTriage)}
              </Button>
            </div>
          </Spin>
        )}

        {actionDetailsName === 'setComponentName' && (
          <div className="flex-row flex-center-align flex-space-between">
            <div>
              <h5 style={{ display: 'inline-block', width: 115, margin: 0 }}>{`${intl.formatMessage(
                eventMessages.componentName,
              )}:`}</h5>
              <Input valueLink={componentNameLink} style={{ width: 340 }} />
            </div>
            <Button
              type="primary"
              size="small"
              style={{ float: 'right' }}
              loading={isLoadingComponentName}
              disabled={hasErrorComponetName}
              onClick={this.handleCompoentNameClick}
            >
              {intl.formatMessage(eventMessages.setComponentName)}
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

const TakeEventTriageModal = injectIntl(TakeEventTriageModalCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, currentLoadingComponents } = state.app;
    const { userAction } = state.log;
    const { credentials, userInfo } = state.auth;
    return {
      location,
      loadStatus,
      currentLoadingComponents,
      userAction,
      credentials,
      userInfo,
    };
  },
  { updateMetricEventPatternName, createLoadAction, updateLastActionInfo },
)(TakeEventTriageModal);
