/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Input as AntInput, Row } from 'antd';

import { CopyOutlined } from '@ant-design/icons';
import { appButtonsMessages } from '../../../../../common/app/messages';
import { CodeMirror, Container, Tooltip } from '../../../../../lib/fui/react';
import PrometheusIni from './PrometheusIni';

const ProjectAgentSettingPrometheus = ({
  intl,
  userInfo,
  currentProject,
  agentSystemData,
  agentData,
  onSubmit,
  currentTheme,
}) => {
  const [errMsg, setErrMsg] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [prometheusUri, setPrometheusUri] = useState(agentData?.prometheusUri || '');
  const [prometheusUser, setPrometheusUser] = useState(agentData?.prometheusUser || '');
  const [prometheusPass, setPrometheusPass] = useState(agentData?.prometheusPass || '');
  const [prometheusQuery, setPrometheusQuery] = useState(agentData?.prometheusQuery || '');
  const [prometheusQueryJson, setPrometheusQueryJson] = useState(agentData?.prometheusQueryJson || '');
  const [hisTimeRange, setHisTimeRange] = useState(agentData?.hisTimeRange || '');
  const [timestampField, setTimestampField] = useState(agentData?.timestampField || '');
  const [instanceField, setInstanceField] = useState(agentData?.instanceField || '');
  const [instanceWhitelist, setInstanceWhitelist] = useState(agentData?.instanceWhitelist || '');
  const [componentField, setComponentField] = useState(agentData?.componentField || '');
  const [defaultComponentName, setDefaultComponentName] = useState(agentData?.defaultComponentName || '');
  const [deviceField, setDeviceField] = useState(agentData?.deviceField || '');
  const [configContent, setConfigContent] = useState('');
  const hasError = !!errMsg;
  const isValid = !!prometheusQuery && !!prometheusUri && !!instanceField;
  const hasChange =
    prometheusUri !== (agentData?.prometheusUri || '') ||
    prometheusUser !== (agentData?.prometheusUser || '') ||
    prometheusPass !== (agentData?.prometheusPass || '') ||
    prometheusQuery !== (agentData?.prometheusQuery || '') ||
    prometheusQueryJson !== (agentData?.prometheusQueryJson || '') ||
    hisTimeRange !== (agentData?.hisTimeRange || '') ||
    instanceField !== (agentData?.instanceField || '') ||
    instanceWhitelist !== (agentData?.instanceWhitelist || '') ||
    componentField !== (agentData?.componentField || '') ||
    defaultComponentName !== (agentData?.defaultComponentName || '') ||
    timestampField !== (agentData?.timestampField || '') ||
    deviceField !== (agentData?.deviceField || '');

  useEffect(() => {
    setPrometheusUri(agentData?.prometheusUri);
    setPrometheusUser(agentData?.prometheusUser);
    setPrometheusPass(agentData?.prometheusPass);
    setPrometheusQuery(agentData?.prometheusQuery);
    setPrometheusQueryJson(agentData?.prometheusQueryJson);
    setHisTimeRange(agentData?.hisTimeRange);
    setTimestampField(agentData?.timestampField);
    setInstanceField(agentData?.instanceField);
    setInstanceWhitelist(agentData?.instanceWhitelist);
    setComponentField(agentData?.componentField);
    setDefaultComponentName(agentData?.defaultComponentName);
    setDeviceField(agentData?.deviceField);
  }, [agentData]);

  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit(
      {
        prometheusUri,
        prometheusUser,
        prometheusPass,
        prometheusQuery,
        prometheusQueryJson,
        hisTimeRange,
        timestampField,
        instanceField,
        instanceWhitelist,
        componentField,
        defaultComponentName,
        deviceField,
      },
      (err) => {
        if (err) {
          setErrMsg(err);
        }
        setSubmitting(false);
      },
    );
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(configContent);
  };

  const isDark = currentTheme === 'dark';
  const handleGenerate = () => {
    let content = PrometheusIni;
    content = content.replace('{{prometheusUri}}', prometheusUri || '');
    content = content.replace('{{prometheusUser}}', prometheusUser || '');
    content = content.replace('{{prometheusPass}}', prometheusPass || '');
    content = content.replace('{{prometheusQuery}}', prometheusQuery || '');
    content = content.replace('{{prometheusQueryJson}}', prometheusQueryJson || '');
    content = content.replace('{{hisTimeRange}}', hisTimeRange || '');
    content = content.replace('{{timestampField}}', timestampField || '');
    content = content.replace('{{componentField}}', componentField || '');
    content = content.replace('{{defaultComponentName}}', defaultComponentName || '');
    content = content.replace('{{instanceField}}', instanceField || '');
    content = content.replace('{{instanceWhitelist}}', instanceWhitelist || '');
    content = content.replace('{{deviceField}}', deviceField || '');
    content = content.replace('{{containerize}}', deviceField ? 'YES' : 'NO');

    content = content.replace('{{userName}}', userInfo?.userName || '');
    content = content.replace('{{licenseKey}}', userInfo?.licenseKey || '');
    content = content.replace('{{systemName}}', currentProject?.systemName || '');
    content = content.replace('{{projectName}}', currentProject?.projectShortName || '');
    content = content.replaceAll('{{samplingInterval}}', currentProject?.samplingInterval || 5);
    content = content.replaceAll('{{timezone}}', currentProject?.timezone || 'UTC');
    content = content.replaceAll('{{ifUrl}}', window.location.origin || '');

    setConfigContent(content);
  };

  return (
    <Container
      fullHeight
      className="flex-col overflow-y-auto"
      style={{ fontSize: 14, rowGap: 8, paddingRight: 8, width: '100%' }}
    >
      {errMsg && <Alert message="Error" description={errMsg} type="error" showIcon style={{ marginBottom: 16 }} />}
      <div>
        <Form size="small">
          <Row gutter={16}>
            <Col span={24}>
              <div style={{ marginBottom: 8 }}>
                <span className="font-14 bold" style={{ marginRight: 8 }}>
                  Prometheus README:
                </span>
                <a
                  href="https://github.com/insightfinder/InsightAgent/blob/master/prometheus/README.md"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://github.com/insightfinder/InsightAgent/blob/master/prometheus/README.md
                </a>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="font-14 bold" style={{ marginBottom: 8 }}>
                Required Settings:
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required
                tooltip="The url of Prometheus server"
                label="Prometheus uri"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={prometheusUri}
                  onChange={(e) => setPrometheusUri(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="Prometheus query"
                tooltip="Prometheus query to get metrics data"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={prometheusQuery}
                  onChange={(e) => setPrometheusQuery(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required
                label="Instance"
                tooltip="If no instance given, will set to 'Application'. Can also set instance name from multiple fields which separated by commas. Ex: instance_name_part1,instance_name_part2."
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={instanceField}
                  onChange={(e) => setInstanceField(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <div className="font-14 bold" style={{ marginBottom: 8, marginTop: 8 }}>
                Optional Settings:
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Prometheus username"
                tooltip="User of Prometheus"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={prometheusUser}
                  onChange={(e) => setPrometheusUser(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Prometheus password"
                tooltip="Password of Prometheus"
                style={{ marginBottom: 8 }}
                labelCol={4}
              >
                <AntInput.Password
                  value={prometheusPass}
                  onChange={(e) => setPrometheusPass(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Prometheus query JSON"
                tooltip="Path of prometheus query file"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={prometheusQueryJson}
                  onChange={(e) => setPrometheusQueryJson(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Device"
                tooltip="Field name of device. Can also set device name from multiple fields which separated by commas. Ex: device_name_part1,device_name_part2."
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={deviceField}
                  onChange={(e) => setDeviceField(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Timestamp" tooltip="Field to get timestamp." style={{ marginBottom: 8 }} labelCol={4}>
                <AntInput
                  value={timestampField}
                  onChange={(e) => setTimestampField(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Time range"
                tooltip="If these options is set, the agent will query results by time range time range, Example: 2020-04-14 00:00:00,2020-04-15 00:00:00"
                style={{ marginBottom: 8 }}
                labelCol={4}
              >
                <AntInput
                  value={hisTimeRange}
                  onChange={(e) => setHisTimeRange(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Component" tooltip="Field to get the component name" style={{ marginBottom: 8 }}>
                <AntInput
                  value={componentField}
                  onChange={(e) => setComponentField(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Default component"
                tooltip="Default component name if cannot get from component field"
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={defaultComponentName}
                  onChange={(e) => setDefaultComponentName(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Instance whitelist"
                tooltip="A regex string used to define which instances will be filtered."
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={instanceWhitelist}
                  onChange={(e) => setInstanceWhitelist(e.target.value)}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="flex-row">
        <Button type="primary" disabled={hasError || !isValid} onClick={handleGenerate}>
          Generate config
        </Button>
        <div className="flex-grow" />
        <Button
          type="primary"
          disabled={hasError || !hasChange || !isValid}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          {intl.formatMessage(appButtonsMessages.update)}
        </Button>
      </div>
      <div className="flex-grow overflow-y-auto overflow-x-auto" style={{ minHeight: 480 }}>
        <div style={{ position: 'absolute', right: 20, fontSize: 18, zIndex: 10 }}>
          <CopyOutlined onClick={handleCopy} />
        </div>
        <CodeMirror
          className="codeMirror_auto"
          options={{
            lineNumbers: false,
            readOnly: false,
            theme: isDark ? 'material' : 'xq-light',
          }}
          value={configContent}
          onBeforeChange={(editor, data, value) => {
            setConfigContent(value);
          }}
        />
      </div>
    </Container>
  );
};

export default ProjectAgentSettingPrometheus;
