import { createLogic } from 'redux-logic';

import { updateLastActionInfo } from '../../app/actions';
import { ActionTypes, setActiveInstance } from '../actions';
import { getLoadStatusActions } from '../../utils';
import { getActiveInstance } from '../../apis';
import { appMessages } from '../../app/messages';

const loadActiveInstanceLogic = createLogic({
  type: [ActionTypes.LOAD_ACTIVE_INSTANCE_ACTIONS],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions();

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getActiveInstance(credentials, params)
      .then((data) => {
        dispatch(setActiveInstance(data));
        dispatch(hideLoading);
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed get active instances, ignored', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
        dispatch(hideLoading);
      })
      .then(() => {
        done();
      });
  },
});

export default loadActiveInstanceLogic;
