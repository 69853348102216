/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getLogEventList, getLogContextEventList } from '../../apis';
import { appMessages } from '../../app/messages';

const loadLogEntryContextListLogic = createLogic({
  type: [ActionTypes.LOAD_LOG_ENTRY_CONTEXT_LIST],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getLogContextEventList(credentials, params)
      // getLogEventList(credentials, params)
      .then((d) => {
        const { data, clusterInfoList } = d;
        const { events, totalCount, highlightPages, resetPage } = data || {};
        if (callback) {
          callback(events);
        }
        dispatch(
          createSetAction(ActionTypes.SET_LOG_ENTRY_CONTEXT_LIST, params, {
            logEntryContextList: events,
            logEntryContextListTotal: totalCount,
            logEntryContextHighlightPages: highlightPages,
            logEntryContextResetPage: resetPage,
            logEntryContextClusterInfoList: clusterInfoList,
          }),
        );
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get cluster info list', err);
        dispatch(
          createSetAction(ActionTypes.SET_LOG_ENTRY_CONTEXT_LIST, params, {
            logEntryContextList: [],
            logEntryContextListTotal: 0,
            logEntryContextHighlightPages: [],
            logEntryContextClusterInfoList: [],
          }),
        );
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadLogEntryContextListLogic;
