/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import loadProjectLogStructureLogic from './loadProjectLogStructureLogic';
import loadSystemGeneralInfoLogic from './loadSystemGeneralInfoLogic';
import loadProjectKeywordsSettingLogic from './loadProjectKeywordsSettingLogic';
import saveProjectKeywordsSettingLogic from './saveProjectKeywordsSettingLogic';
import removeProjectKeywordsSettingLogic from './removeProjectKeywordsSettingLogic';
import newExternalServiceLogic from './newExternalServiceLogic';
import loadProjectDetectionStatusLogic from './loadProjectDetectionStatusLogic';
import saveProjectDetectionStatusLogic from './saveProjectDetectionStatusLogic';
import loadProjectActionsSettingLogic from './loadProjectActionsSettingLogic';
import loadActiveInstanceLogic from './loadActiveInstanceLogic';
import saveProjectActionsSettingLogic from './saveProjectActionsSettingLogic';
import saveDeployAgentLogic from './saveDeployAgentLogic';
import loadProjectFiltersSettingLogic from './loadProjectFiltersSettingLogic';
import loadProjectModelsSettingLogic from './loadProjectModelsSettingLogic';
import removeProjectLogic from './removeProjectLogic';

export default [
  loadProjectLogStructureLogic,
  loadSystemGeneralInfoLogic,
  loadProjectKeywordsSettingLogic,
  saveProjectKeywordsSettingLogic,
  newExternalServiceLogic,
  removeProjectKeywordsSettingLogic,
  loadProjectDetectionStatusLogic,
  saveProjectDetectionStatusLogic,
  loadProjectActionsSettingLogic,
  saveProjectActionsSettingLogic,
  saveDeployAgentLogic,
  loadProjectFiltersSettingLogic,
  loadProjectModelsSettingLogic,
  loadActiveInstanceLogic,
  removeProjectLogic,
];
