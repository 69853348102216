/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get, isNumber } from 'lodash';
import { autobind } from 'core-decorators';
import moment from 'moment';

import { Defaults } from '../../../common/utils';
import { Container, AutoSizer, Table, Column } from '../../../lib/fui/react';

import EventGroup from '../../../../components/log/loganalysis/event-group';

type Props = {
  width: Number,
  height: Number,
  project: Object,
  queryParams: Object,
  queryResult: Object,
};

class TracingEvent extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.leftWidth = 200;
    this.tracingList = [];

    this.state = {
      tracingIncident: null,

      activeIncident: null,
    };
  }

  componentDidMount() {
    this.convertData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps);
    }
  }

  @autobind
  convertData(props, params = {}) {
    const { queryResult, project } = props;
    const { result } = queryResult || {};
    const projectLinkInfo = get(project, 'projectLinkInfo', []);

    const tracingList = [];
    R.forEach((data) => {
      const { interTraceResultMap, intraTraceResultMap, logEvent, traceId, instanceName } = data;
      const tracingIncident = {
        id: traceId,
        eventList: [],
      };

      R.forEachObjIndexed((val, instanceId) => {
        R.forEach((event) => {
          const { rareEventFlag, ...rest } = event;
          tracingIncident.eventList.push({ ...rest, instanceName: instanceId });
        }, val || []);
      }, interTraceResultMap || {});
      R.forEachObjIndexed((val, instanceId) => {
        R.forEach((event) => {
          const { rareEventFlag, ...rest } = event;
          tracingIncident.eventList.push({ ...rest, instanceName: instanceId });
        }, val || []);
      }, intraTraceResultMap || {});

      if (tracingIncident.eventList.length === 0) {
        tracingIncident.eventList.push(logEvent);
      }
      tracingIncident.eventList = R.sortWith([R.descend(R.prop('timestamp'))], tracingIncident.eventList);
      if (projectLinkInfo.length > 0) {
        tracingIncident.eventList = R.map((e) => {
          return { ...e, projectLinkInfo };
        }, tracingIncident.eventList);
      }

      tracingList.push(tracingIncident);
    }, result || []);

    this.tracingList = tracingList;
    if (tracingList.length > 0) {
      this.setState({ tracingIncident: tracingList[0] });
    }
  }

  @autobind
  handleRowClick({ event, index, rowData }) {
    this.setState({ tracingIncident: rowData });
  }

  render() {
    const { width, height, queryParams, project } = this.props;
    const { tracingIncident } = this.state;
    const eventList = get(tracingIncident, ['eventList'], []);
    const tracingId = get(tracingIncident, ['id']);

    return (
      <Container className="flex-col" style={{ height, width }}>
        <Container className="toolbar">
          <Container className="section">{/* <h4>Tracing Events</h4> */}</Container>
          <Container className="section float-right" />
        </Container>
        <div className="flex-grow flex-row">
          <div className="flex-col" style={{ width: this.leftWidth, padding: '0 4px' }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={30}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  rowCount={this.tracingList.length}
                  rowGetter={({ index }) => this.tracingList[index]}
                  rowStyle={({ index: number }) => {
                    if (number >= 0 && tracingIncident && this.tracingList[number].id === tracingIncident.id) {
                      return { borderLeft: '3px solid orange' };
                    }
                    return {};
                  }}
                  ref={(dataTable) => (this.dataTable = dataTable)}
                  onRowClick={this.handleRowClick}
                >
                  <Column width={200} label="Tracing id" flexGrow={1} dataKey="id" />
                </Table>
              )}
            </AutoSizer>
          </div>
          <div className="flex-grow flex-col">
            <div style={{ fontWeight: 500, fontSize: 14 }}>Tracing Events</div>
            <div className="flex-grow" style={{ overflowY: 'hidden' }}>
              <EventGroup
                name=""
                eventDataset={eventList}
                highlightWord={tracingId}
                showFE={false}
                combineLabels
                hasType
                hasInstance
                hasNid
                showLinkedJump
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default TracingEvent;
