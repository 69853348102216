/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import type { Credentials } from '../types';
import fetchPost from './fetchPost';
import getEndpoint from './getEndpoint';

const deleteEventAction = (credentials: Credentials, actionParams: Object, emailListParams: Object) => {
  const promiseQuery = [
    fetchPost(getEndpoint('userAction', 1), {
      ...credentials,
      ...actionParams,
    }).then((d) => {
      return d;
    }),
  ];
  promiseQuery.push(
    fetchPost(getEndpoint('actionemailsetting', 1), {
      ...credentials,
      ...emailListParams,
    }).then((d) => {
      return d;
    }),
  );

  return Promise.all(promiseQuery).then((results) => {
    const d0 = results[0];
    const d1 = results[1];
    return d0 && d1;
  });
};

export default deleteEventAction;
