import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import { get, round, isNumber } from 'lodash';
import { sortableHandle } from 'react-sortable-hoc';
import { Empty, Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { Defaults, buildUrl } from '../../../common/utils';
import { MoveIcon } from '../../../lib/fui/icons';
import { EChart } from '../../share';

const DragHandle = sortableHandle(() => (
  <span className="hover-display-item" style={{ cursor: 'move', position: 'absolute', right: 8, top: 8 }}>
    <MoveIcon style={{ fontSize: 16 }} />
  </span>
));

const InsightsAlertVolumeStats = (props) => {
  // eslint-disable-next-line
  const { intl, name } = props;
  const {
    // eslint-disable-next-line
    push,
    // eslint-disable-next-line
    updateLastActionInfo,
    // eslint-disable-next-line
    credentials,
    // eslint-disable-next-line
    userInfo,

    // eslint-disable-next-line
    refresh,
    // eslint-disable-next-line
    refreshTime,
    // eslint-disable-next-line
    environmentId,
    // eslint-disable-next-line
    systemId,
    // eslint-disable-next-line
    customerName,
    // eslint-disable-next-line
    startTime,
    // eslint-disable-next-line
    globalInfo,

    // eslint-disable-next-line
    updateState,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [option, setOption] = useState(undefined);

  const buildChartOption = (dataList) => {
    const firstIndex = R.findIndex((item) => Boolean(item.value), dataList);
    const lastIndex = R.findLastIndex((item) => Boolean(item.value), dataList);
    return {
      tooltip: {
        trigger: 'axis',
        confine: true,
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          const { name, seriesName, color, data } = params[0];
          const { realValue } = data;
          return ReactDOMServer.renderToStaticMarkup(
            <div>
              {name}
              <br />
              <span
                style={{
                  display: 'inline-block',
                  marginRight: 5,
                  borderRadius: 10,
                  width: 9,
                  height: 9,
                  backgroundColor: color,
                }}
              />
              {`${seriesName}: ${realValue}`}
            </div>,
          );
        },
      },
      grid: {
        left: 50,
        right: 30,
        top: 40,
        bottom: 40,
      },
      xAxis: {
        type: 'category',
        data: R.map((item) => item.name, dataList),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Count',
          type: 'bar',
          data: R.map((item) => {
            return {
              value: R.max(0, item.value),
              realValue: item.value,
            };
          }, dataList),
          markPoint:
            dataList.length > 0
              ? {
                  data: [
                    firstIndex >= 0 && firstIndex !== lastIndex
                      ? {
                          coord: [firstIndex, dataList[firstIndex].value],
                          value: dataList[firstIndex].value,
                        }
                      : {},
                    lastIndex >= 0
                      ? {
                          coord: [lastIndex, dataList[lastIndex].value],
                          value: dataList[lastIndex].value,
                        }
                      : {},
                  ],
                }
              : {},
        },
      ],
    };
  };

  useEffect(() => {
    let cancel = false;

    // UNSAFE_componentWillReceiveProps
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (
      // eslint-disable-next-line
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      systemId &&
      systemInfo &&
      startTime
    ) {
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').subtract(30, 'days').valueOf();
      const endTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();

      setLoading(true);
      updateState({ isLoadingAlertStats: true });
      updateLastActionInfo();
      Promise.all([
        fetchGet(getEndpoint('insightsAlertPatternCount'), {
          ...credentials,
          environmentName: environmentId,
          customerName: systemInfo.ownerUserName,
          systemName: systemId,
          startTime: startTimestamp,
          endTime: endTimestamp,
          isIncludeIgnored: false,
        }),
      ])
        .then(async (results) => {
          if (cancel) return;

          // get pattern count map
          const patternMap = get(results[0], 'alertTotalPatternCountMap', {});

          let dataList = [];
          R.forEach((ts) => {
            const timestamp = ts * 86400000;
            const value = get(patternMap, String(timestamp));
            dataList.push({
              timestamp,
              value: isNumber(value) ? round(value) : null,
            });
          }, R.range(startTimestamp / 86400000, endTimestamp / 86400000 + 1));

          dataList = R.sortWith([R.ascend(R.prop('timestamp'))], dataList);
          dataList = R.map((item) => {
            const name = moment.utc(item.timestamp).format(Defaults.ShortDayFormat);
            return { ...item, name };
          }, dataList);
          const option = buildChartOption(dataList);

          // get pattern timepair info
          const alertPatternStatsMap = get(results[0], 'alertPatternStatsMap', []);
          let alertPatternStats = [];
          R.forEach((item) => {
            const { projectName, instanceName, patternId } = item[0] || {};
            const key = `${projectName}-${instanceName}-${patternId}`;

            let timepair = [];
            R.forEachObjIndexed((count, timestamp) => {
              timepair.push({
                timestamp: Number(timestamp),
                count,
              });
            }, item[1]);
            timepair = R.sortWith([R.ascend(R.prop('timestamp'))], timepair);

            alertPatternStats.push({
              ...(item[0] || {}),
              key,
              timepair,
            });
          }, alertPatternStatsMap);
          alertPatternStats = R.sortWith(
            [R.descend(R.prop('patternCount')), R.ascend(R.prop('patternId'))],
            alertPatternStats,
          );

          // alert info
          const alertSourceMap = get(results[0], 'alertSourceMap', {});
          const alertCountMap = { incidentCount: 0 };
          R.forEachObjIndexed((val, key) => {
            if (key === 'PagerDuty') {
              alertCountMap.alertCountPagerDuty = val;
            } else if (key === 'ServiceNow') {
              alertCountMap.alertCountServiceNow = val;
            } else {
              alertCountMap.alertCount += val;
            }
          }, alertSourceMap);

          setOption(option);
          setHasData(!R.isEmpty(patternMap));
          setLoading(false);

          updateState({
            isLoadingAlertStats: false,
            alertPatternStats,
            alertCountMap,
          });
        })
        .catch((err) => {
          if (cancel) return;
          console.error(err);
          setHasData(false);
          setLoading(false);

          updateState({
            isLoadingAlertStats: false,
            alertPatternStats: [],
          });
        });
    }

    return () => {
      cancel = true;
    };
  }, [refresh, refreshTime, environmentId, systemId, customerName]);

  return (
    <div
      className="full-height full-width flex-col health-card hover-display"
      style={{ backgroundColor: 'white', borderRadius: '2em', padding: '8px 8px', position: 'relative' }}
    >
      <div
        className="flex-row flex-center-justify bold link"
        style={{ textAlign: 'center', margin: '0 14px 8px 14px', height: 34 }}
        onClick={() => {
          push(buildUrl(BaseUrls.GlobalSystemRootCause, {}, { startTime, customerName, environmentId, systemId }));
        }}
      >
        {name}
      </div>
      <DragHandle />

      <Spin
        spinning={isLoading}
        wrapperClassName="spin-full-height flex-grow flex-center-align flex-center-justify overflow-hidden"
      >
        {!hasData && (
          <div className="full-height flex-row flex-center-align flex-center-justify">
            <Empty imageStyle={{ height: 60 }} />
          </div>
        )}
        {hasData && <EChart width="100%" height="100%" option={option} />}
      </Spin>
    </div>
  );
};

export default InsightsAlertVolumeStats;
