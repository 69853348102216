import React, { useReducer, useState } from 'react';
import { Form, Input, message, Select, Radio, Col, Row } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Modal } from '../../../../lib/fui/react';

import { settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

export default function GroupMetricInstanceNameSettingModal({
  intl,
  onClose,
  projectName,
  credentials,
  project,
}: Object) {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [mode, setMode] = useState('add');
  const [formData, setFormData] = useState({
    prefix: '',
    suffix: '',
    delPattern: '',
  });
  const { prefix, suffix, delPattern } = formData;

  const handleSetData = (obj) => {
    setFormData((prev) => ({ ...prev, ...obj }));
  };

  const handleConfirm = () => {
    const { owner, projectShortName } = project;
    const requestData = {
      ...credentials,
      ...(mode === 'add'
        ? {
            prefix,
            suffix,
            mode,
            projectName: projectShortName,
            customerName: owner,
          }
        : {
            mode,
            delPattern,
            projectName: projectShortName,
            customerName: owner,
          }),
    };
    setIsSubmiting(true);
    fetchPost(getEndpoint('loggroupingupdate'), requestData)
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          onClose(true);
        } else {
          console.log(msg);
        }
        setIsSubmiting(false);
      })
      .catch((e) => {
        console.log(String(e));
        setIsSubmiting(false);
      });
  };

  const bothIsEmpty = !prefix && !suffix;
  const delPatternError = !delPattern;
  return (
    <Modal
      title={intl.formatMessage(settingsMessages.metricInstanceNameSetting)}
      width={650}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={handleConfirm}
      okButtonProps={{ loading: isSubmiting, disabled: mode === 'add' ? bothIsEmpty : delPatternError }}
    >
      <Row style={{ marginBottom: 16 }}>
        <Col offset={6}>
          <Radio.Group onChange={(e) => setMode(e.target.value)} value={mode}>
            <Radio value="add">{intl.formatMessage(appButtonsMessages.add)}</Radio>
            <Radio value="delete">{intl.formatMessage(appButtonsMessages.delete)}</Radio>
          </Radio.Group>
        </Col>
      </Row>
      {mode === 'add' && (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
          <Form.Item label="Prefix" validateStatus={bothIsEmpty ? 'error' : 'success'}>
            <Input onChange={({ target: { value: prefix } }) => handleSetData({ prefix })} value={prefix} />
          </Form.Item>
          <Form.Item label="Suffix" validateStatus={bothIsEmpty ? 'error' : 'success'}>
            <Input onChange={({ target: { value: suffix } }) => handleSetData({ suffix })} value={suffix} />
          </Form.Item>
        </Form>
      )}

      {mode === 'delete' && (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
          <Form.Item
            label="Delete pattern"
            validateStatus={delPattern ? 'success' : 'error'}
            help={delPatternError ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          >
            <Input onChange={({ target: { value: delPattern } }) => handleSetData({ delPattern })} value={delPattern} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
