/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import qs from 'qs';
import momenttz from 'moment-timezone';
import fetchHandler from './fetchHandler';
import refreshTokenFnTime from './refreshTokenFnTime';

/**
 * Use fetch to send POST method.
 * The content type of the sending data is application/x-www-from-urlencode.
 * The response json format is:
 * {
 *   success: true|false
 *   data: object,  // Data object if success is true.
 *   error: string // Error messages if success is false.
 * }
 *
 * @param {string} url The url of the api
 * @param {object} query The query string object
 */
const fetchGet = (
  url: string,
  query: Object,
  extraHeaders: Object = {},
  extraParams: String = '',
  isJsonResponse: Boolean = true,
) => {
  // parse time zone
  const zone = momenttz.tz(momenttz.tz.guess());
  const tzOffset = zone.utcOffset() * 60 * 1000;
  query.tzOffset = tzOffset;

  // remove CSRF token
  if (query.token) {
    query.token = undefined;
  }
  // remove userName
  if (query.userName && url.indexOf('/log-validation-benchmark-metric') < 0) {
    query.userName = undefined;
  }
  // set userName by UserName if it is necessary
  if (query.UserName) {
    query.userName = query.UserName;
    query.UserName = undefined;
  }

  refreshTokenFnTime(query);

  const option = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Environment: process.env.NODE_ENV,
      Accept: 'application/json, text/plain, */*',
      ...extraHeaders,
    },
  };

  return fetchHandler(
    fetch(`${url}?${qs.stringify(query)}${extraParams ? `&${extraParams}` : ''}`, option),
    isJsonResponse,
    option,
  );
};

export default fetchGet;
