/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 **/

import defaultTimezoneEpic from './defaultTimezoneEpic';

const epics = [defaultTimezoneEpic];

export default epics;
