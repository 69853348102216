/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { CausalParser, parseJSON } from '../../common/utils';
import parseCausalIncident from './parsers/parseCausalIncident';

const getCausalIncident = (credentials: Credentials, params: Object) => {
  const {
    causalKey,
    customerName,
    causalName,
    startTimestamp,
    endTimestamp,
    fileName,
    postFix,
    instanceName,
    needCausalProperty,
    joinDependency,
    causalType,
    relationKey,

    timeThreshold,
    count,
    probability,
  } = params;
  const operation = instanceName ? 'intra' : 'inter';

  const requests = [];
  requests.push(
    fetchGet(getEndpoint('eventsrelationprocess'), {
      ...credentials,
      causalKey,
      relationKey,
      customerName,
      causalName,
      startTimestamp,
      endTimestamp,
      fileName: `${fileName}${postFix}`,
      joinDependency,
      operation,
      causalType,

      timeThreshold,
      count,
      probability,
    }).then((result) => {
      let incident = {};
      const causalNodeDegreeMap = parseJSON(result.causalNodeDegreeMap) || {};

      if (causalType === 'KPIPrediction') {
        const data = JSON.parse(result.data || '{}');
        const { kpiPrediction, kpi } = CausalParser.getKpiPrediction(data);
        incident = parseCausalIncident({
          operation,
          data: [],
          mapping: result.mapping,
          kpiPrediction,
          kpi,
          causalNodeDegreeMap,
        });
      } else {
        const data = result.data || [];
        incident = parseCausalIncident({
          operation,
          data,
          mapping: result.mapping,
          kpiPrediction: {},
          kpi: {},
          causalNodeDegreeMap,
        });
      }
      return incident;
    }),
  );
  if (!instanceName || needCausalProperty) {
    requests.push(
      fetchGet(getEndpoint('eventsrelationprocess'), {
        ...credentials,
        causalKey,
        relationKey,
        customerName,
        causalName,
        startTimestamp,
        endTimestamp,
        fileName: `${fileName}_CausalProperty`,
        joinDependency,
      }).then((d) => {
        const dataResult = JSON.parse(d.data || '{}');
        const { possibleIntraFlag, instancePropertyMap } = dataResult;
        const incidentCausalProperty = {
          possibleIntraFlag,
          instancePropertyMap: JSON.parse(instancePropertyMap || '{}'),
        };
        return incidentCausalProperty;
      }),
    );
  }

  return Promise.all(requests).then((results) => {
    return {
      incident: results[0],
      incidentCausalProperty: results[1] || {},
    };
  });
};

export default getCausalIncident;
