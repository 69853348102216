/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import type { Credentials } from '../types';
import fetchPost from './fetchPost';
import getEndpoint from './getEndpoint';

const postEventTriage = (credentials: Credentials, params: Object) => {
  const { operation } = params;
  return fetchPost(
    getEndpoint('userAction', 1),
    {
      ...credentials,
      ...params,
    },
    {},
    operation === 'saveTriage',
  ).then((d) => {
    return d;
  });
};

export default postEventTriage;
