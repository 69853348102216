/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { SyncOutlined } from '@ant-design/icons';
import { Progress, Popover } from 'antd';

import { State } from '../../../common/types';
import {
  Defaults,
  parseLocation,
  getLoadStatus,
  buildLocation,
  buildUrl,
  MetricRenderers,
} from '../../../common/utils';
import { Container, AutoSizer, List } from '../../../lib/fui/react';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/metric/actions';
import { LineChart } from '../../../lib/fui/react/charts';
import { BaseUrls } from '../../app/Constants';
import { appFieldsMessages } from '../../../common/app/messages';

import MetricLineChartEventDetailsModal from './MetricLineChartEventDetailsModal';

type Props = {
  // eslint-disable-next-line
  project: Object,

  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  defaultTimezone: String,
  // eslint-disable-next-line
  userList: Array<Object>,
  projectInstanceComponentMap: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  appNameMapping: Object,
  eventLineChartData: Object,
  eventPercentage: Number,
  columnInfoMap: Object,
  instanceList: Array<Object>,
  metricList: Array<Object>,
  metricDatasetMap: Object,
  metricEventListMap: Object,
  notAvailableMetricList: Array<String>,
  latestDataTime: Number,
  currentTimestamp: Number,
  k8CoverageMap: Object,
  clickAbnormalTip: Function,
  instanceDisplayNameMap: Object,
};

class EventsDetailsLineChartCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.lineChartsLoader = 'eventdetails_linecharts';

    this.allId = '_all_';
    this.chartHeight = 160;
    this.listWidth = 0;
    this.metricPageSize = 10;
    this.instancePageSize = 10;
    this.timeOffset = 0;

    const { eventPercentage } = props;

    let { instanceList } = props;
    let { metricList } = props;
    const { location } = props;
    const query = parseLocation(location);
    const { justSelectMetric, justInstanceList } = query;
    if (justSelectMetric) {
      metricList = justSelectMetric.split(',');
    }
    if (justInstanceList) {
      instanceList = justInstanceList.split(',');
    }

    this.state = {
      instanceList,
      metricList,
      selectedEvent: null,

      showProgressBar: eventPercentage !== 100,
      eventPercentage: 0,

      showEventDetailsModal: false,
    };
  }

  componentDidMount() {
    if (!this.props.eventLineChartData) {
      this.reloadData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextParams = parseLocation(nextProps.location);
    const params = parseLocation(this.props.location);

    const { push, eventLineChartData, eventPercentage, instanceList, metricList } = nextProps;

    if (
      params.projectName !== nextParams.projectName ||
      params.predictedFlag !== nextParams.predictedFlag ||
      params.startTimestamp !== nextParams.startTimestamp ||
      params.endTimestamp !== nextParams.endTimestamp
    ) {
      this.reloadData(nextProps);
    } else if (this.props.eventLineChartData !== eventLineChartData) {
      this.setState({ instanceList, metricList }, () => {
        this.listNode.forceUpdateGrid();

        // when get events, update isLoadEvent.
        push(buildLocation(nextProps.location.pathname, {}, { ...nextParams, isLoadEvent: 'true' }));
      });
    } else if (this.props.eventPercentage !== eventPercentage) {
      this.setState({ eventPercentage });
      if (eventPercentage === 100) {
        this.setState({ showProgressBar: false });
      }
    }
  }

  @autobind
  reloadData(props) {
    const { location, createLoadAction } = props;
    const query = parseLocation(location);
    const { projectName, instanceGroup, predictedFlag } = query;

    // If there is select metric and instance in state, use it, otherwise get from url
    const { instanceList, metricList } = this.state;

    let { startTimestamp, endTimestamp } = query;
    startTimestamp = parseInt(startTimestamp, 10);
    endTimestamp = parseInt(endTimestamp, 10);

    if (projectName && instanceGroup && startTimestamp && endTimestamp) {
      this.setState(
        {
          chartDateWindow: [startTimestamp, endTimestamp],
          showProgressBar: true,
        },
        () => {
          createLoadAction(
            ActionTypes.LOAD_METRIC_EVENT_LINECHARTS,
            {
              filterPredict: true,
              withLinkedActiveInstance: true,
              projectName,
              instanceGroup,
              instanceList,
              metricList,
              startTimeObj: moment.utc(startTimestamp).startOf('day'),
              endTimeObj: moment.utc(endTimestamp).endOf('day'),
              eventType: predictedFlag === 'true' ? 'predicted' : 'detected',
              metricPageSize: this.metricPageSize,
              instancePageSize: this.instancePageSize,
            },
            this.lineChartsLoader,
          );
        },
      );
    }
  }

  @autobind
  renderListItem({ index, key, style }) {
    const {
      intl,
      location,
      projectInstanceComponentMap,
      metricDatasetMap,
      columnInfoMap,
      metricEventListMap,
      latestDataTime,
      currentTimestamp,
      notAvailableMetricList,
      project,
      instanceDisplayNameMap,
    } = this.props;
    const { instanceList } = this.state;
    let { metricList } = this.state;
    let { chartDateWindow } = this.state;

    const query = parseLocation(location);
    metricList = R.difference(metricList, notAvailableMetricList);
    const { projectName, samplingIntervalInSecond } = project;

    // reset cahrt data window by latestDataTime
    if (!chartDateWindow) {
      let { startTimestamp, endTimestamp } = query;
      startTimestamp = parseInt(startTimestamp, 10);
      endTimestamp = parseInt(endTimestamp, 10);
      chartDateWindow = [startTimestamp, endTimestamp];
    } else {
      const currentStartTime = moment.utc().startOf('day').valueOf();
      if (latestDataTime > currentStartTime && latestDataTime < chartDateWindow[1]) {
        chartDateWindow[1] = latestDataTime + 2 * 60 * 1000;
      }
    }

    const renderMetric = (metric) => {
      const mds = get(metricDatasetMap, metric);
      if (mds) {
        const { name, unit, labels, data, rootCauseMap, predictionlabelMap } = mds;
        const labelsVisibility = R.map(
          (l) => {
            const info = columnInfoMap[l];
            return info ? (instanceList || []).includes(info.instanceId) : true;
          },
          R.filter((l) => l !== 'timestamp', labels),
        );

        // merge metric annos
        const annotationTimeMap = {};
        const elist = metricEventListMap[metric] || [];
        R.forEach((event) => {
          const { isPrediction, startTimestamp, endTimestamp } = event;
          const { rootCauseJson } = event;
          let { rootCauseDetailsArr } = rootCauseJson;
          rootCauseDetailsArr = R.filter(
            (item) => item.rootCauseMetric === metric && R.indexOf(item.instanceId, instanceList) >= 0,
            rootCauseDetailsArr || [],
          );

          // push root cause to annotations
          R.forEach((rc) => {
            const timeRangeList = rc.timeRangeList || [];
            if (timeRangeList.length > 0) {
              const rootCauseStartTime =
                timeRangeList[0].startTimestamp - (rc.needHighlightPading ? samplingIntervalInSecond * 1000 : 0);

              if (!R.has(rootCauseStartTime, annotationTimeMap)) {
                annotationTimeMap[rootCauseStartTime] = [];
              }
              annotationTimeMap[rootCauseStartTime].push({
                ...rc,

                // event info
                isPrediction,
                startTimestamp,
                endTimestamp,
              });
            }
          }, rootCauseDetailsArr);
        }, elist);
        const annotations = [];
        R.forEachObjIndexed((annos, timestamp) => {
          annotations.push({
            timestamp: Number(timestamp),
            annos,
          });
        }, annotationTimeMap);
        if (latestDataTime > currentTimestamp) {
          annotations.push({
            timestamp: Number(currentTimestamp),
            isCurrent: true,
          });
        }

        return (
          <LineChart
            width={this.listWidth}
            height={this.chartHeight}
            intl={intl}
            currentTimestamp={currentTimestamp}
            labelMapping={(n) => {
              const { instanceId } = get(columnInfoMap, [n], {});
              const component = get(projectInstanceComponentMap, [projectName, instanceId], '');
              return component && component !== instanceId ? `${instanceId} (${component})` : instanceId;
            }}
            samplingInterval={samplingIntervalInSecond * 1000}
            title={name}
            titlePostfix={unit}
            labels={labels}
            data={data}
            predictionlabelMap={predictionlabelMap}
            annotations={annotations}
            labelsVisibility={labelsVisibility}
            highlightMap={rootCauseMap}
            onCreateAnnotation={this.handleOnCreateAnnotation}
            dateWindow={chartDateWindow}
            onDateWindowChange={this.handleDateWindowSync}
            instanceDisplayNameMap={instanceDisplayNameMap}
          />
        );
      }
      return null;
    };

    return (
      <Container key={key} style={{ ...style, width: this.listWidth }}>
        {renderMetric(metricList[index], true)}
      </Container>
    );
  }

  @autobind
  handleDateWindowSync(dateWindow) {
    this.setState({ chartDateWindow: dateWindow }, () => {
      this.listNode.forceUpdateGrid();
    });
  }

  @autobind
  handleOnCreateAnnotation({ x, timestamp, annos, isCurrent }) {
    const { intl, project, k8CoverageMap, clickAbnormalTip, instanceDisplayNameMap } = this.props;

    if (isCurrent) {
      return (
        <div key="isCurrent" className="anno" style={{ left: x, height: 0, width: 0, marginTop: -6 }}>
          <div
            style={{
              position: 'absolute',
              whiteSpace: 'nowrap',
              color: 'black',
              transform: 'translateX(-50%)',
              marginTop: -16,
            }}
          >
            {`${intl.formatMessage(appFieldsMessages.currentTime)}: ${moment
              .utc(timestamp)
              .format(Defaults.TimeOnlyFormat)}`}
          </div>
          <div
            style={{
              height: 0,
              width: 0,
              borderTop: '4px solid #006633',
              borderLeft: '4px solid transparent',
              borderRight: '4px solid transparent',
              transform: 'translateX(-50%)',
            }}
          />
        </div>
      );
    }

    const maxPct = R.reduce(
      R.max,
      0,
      R.map((anno) => parseFloat(anno.pct), annos),
    );
    const backgroundColor = maxPct >= 200 ? 'red' : maxPct > 0 ? 'orange' : '#2185d0';
    return (
      <Popover
        key={`${timestamp}`}
        overlayClassName="dark"
        placement="right"
        title={null}
        content={MetricRenderers.LineChartAnnotation(
          timestamp,
          annos,
          intl,
          project,
          k8CoverageMap,
          instanceDisplayNameMap,
        )}
      >
        <div
          className="anno"
          style={{ left: x, background: backgroundColor, color: 'var(--card-background)', borderWidth: 1 }}
          onClick={() => clickAbnormalTip({ timestamp, annos, project })}
        >
          <div className="indicator" />
        </div>
      </Popover>
    );
  }

  @autobind
  renderProgressBar(percent, successPercent) {
    const { intl } = this.props;
    if (percent === 100) {
      return (
        <span style={{ fontSize: 14 }}>
          {intl.formatMessage(appFieldsMessages.waiting)}
          <SyncOutlined style={{ marginLeft: 2 }} size="small" spin />
        </span>
      );
    }
    return `${percent}%`;
  }

  @autobind
  onClickZoomOut(e) {
    e.stopPropagation();
    const { push, location } = this.props;
    const params = parseLocation(location);
    push(buildLocation(location.pathname, {}, { ...params, zoomIn: '' }));
  }

  @autobind
  onClickZoomIn(zoomIn) {
    return (e) => {
      e.stopPropagation();
      const { push, location } = this.props;
      const params = parseLocation(location);
      push(buildLocation(location.pathname, {}, { ...params, zoomIn }));
    };
  }

  @autobind
  handleLineChartClick() {
    return (e) => {
      const { location } = this.props;
      const params = parseLocation(location);
      const { projectName, instanceGroup, startTimestamp, endTimestamp, predictedFlag, customerName } = params;

      const startTs = moment.utc(Number(startTimestamp)).startOf('day').valueOf();
      const endTs = moment.utc(Number(endTimestamp)).endOf('day').valueOf();

      const query = {
        projectName,
        instanceGroup,
        customerName,
        startTimestamp: startTs,
        endTimestamp: endTs,
        predictedFlag: predictedFlag === 'true',
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    };
  }

  render() {
    const { intl, location, loadStatus } = this.props;
    const { project, appNameMapping, notAvailableMetricList } = this.props;
    const { metricList, eventPercentage, showProgressBar } = this.state;
    const params = parseLocation(location);
    const { projectName } = params;

    const metricCount = R.difference(metricList, notAvailableMetricList).length;
    const rowCount = metricCount;
    const { isLoading } = getLoadStatus(get(loadStatus, this.lineChartsLoader), intl);

    return (
      <Container className="full-width flex-col">
        {showProgressBar && (
          <div
            className="full-width flex-row flex-center-align flex-center-justify"
            style={{ height: this.chartHeight }}
          >
            <Progress type="circle" percent={eventPercentage} format={this.renderProgressBar} />
          </div>
        )}
        {!showProgressBar && (
          <Container className={`full-width ${isLoading ? ' loading overflow-y-auto' : ''}`}>
            <AutoSizer disableHeight>
              {({ width }) => {
                this.listWidth = width;
                return (
                  <List
                    ref={(n) => {
                      this.listNode = n;
                    }}
                    width={width}
                    height={this.chartHeight}
                    rowCount={rowCount}
                    overscanRowCount={4}
                    rowHeight={this.chartHeight}
                    rowRenderer={this.renderListItem}
                  />
                );
              }}
            </AutoSizer>
          </Container>
        )}

        {this.state.showEventDetailsModal && (
          <MetricLineChartEventDetailsModal
            projectName={projectName}
            project={project}
            selectedEvent={this.state.selectedEvent}
            appNameMapping={appNameMapping}
            onClose={() => this.setState({ showEventDetailsModal: false, selectedEvent: null })}
          />
        )}
      </Container>
    );
  }
}

const EventsDetailsLineChart = injectIntl(EventsDetailsLineChartCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { userInfo } = state.auth;
    const { loadStatus, defaultTimezone, userList, projectInstanceComponentMap } = state.app;
    const { eventLineChartData, eventLoadingPercentage } = state.metric;

    const eventPercentage = eventLoadingPercentage.eventLoadingPercentage;
    const appNameMapping = get(eventLineChartData, 'appNameMapping', {});
    const instanceList = get(eventLineChartData, 'instanceList', []);
    const metricList = get(eventLineChartData, 'metricList', []);
    const metricDatasetMap = get(eventLineChartData, 'metricDatasetMap', {});
    const metricEventListMap = get(eventLineChartData, 'metricEventListMap', {});
    const columnInfoMap = get(eventLineChartData, 'columnInfoMap', {});
    const hasDataMetricList = get(eventLineChartData, 'hasDataMetricList', []);
    const latestDataTime = get(eventLineChartData, 'latestDataTime', 0);
    const currentTimestamp = get(eventLineChartData, 'currentTimestamp');
    const notAvailableMetricList = R.difference(metricList, hasDataMetricList);
    const k8CoverageMap = get(eventLineChartData, 'k8CoverageMap', {});
    const instanceDisplayNameMap = get(eventLineChartData, 'instanceDisplayNameMap', {});

    return {
      location,
      userInfo,
      loadStatus,
      defaultTimezone,
      userList,
      projectInstanceComponentMap,
      appNameMapping,
      eventLineChartData,
      eventPercentage,
      columnInfoMap,
      instanceList,
      metricList,
      metricDatasetMap,
      metricEventListMap,
      notAvailableMetricList,
      latestDataTime,
      currentTimestamp,
      k8CoverageMap,
      instanceDisplayNameMap,
    };
  },
  { push, replace, createLoadAction },
)(EventsDetailsLineChart);
