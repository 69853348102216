/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import { get, round } from 'lodash';

import moment from 'moment';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseJSON } from '../utils';

const getGlobalInstanceAnomalyScore = (credentials: Credentials, params: Object) => {
  const { environmentId, systemIds, startTime, endTime, instanceName, systemHealth } = params;
  const { systemInfo } = params;

  const systemId = systemIds[0];
  let instance = instanceName;
  let container;
  const instanceIds = systemInfo.instanceIds || [];
  if (instanceName.includes('_') && !instanceIds.includes(instanceName)) {
    [container, instance] = instanceName.split('_');
  }

  // parse chart data
  const parseCharts = (dailyScoreMap) => {
    const { intervalInMinutes, dataStartTimestamp, dataEndTimestamp } = systemHealth;
    const needPaddingData = true;
    let systemHealthInstance = [];
    const queryDayTimes = [];
    const queryDayTimesMap = {};
    R.forEach((ts) => {
      const timestamp = ts * 86400000;
      queryDayTimes.push(timestamp);
      queryDayTimesMap[timestamp] = false;
    }, R.range(dataStartTimestamp / 86400000, dataEndTimestamp / 86400000));

    try {
      // pre parse data
      const hasDataMap = R.clone(queryDayTimesMap);
      let allDayAnomalyScoresList = [];
      R.forEachObjIndexed((val, day) => {
        const dayStartTimestamp = Number(day);
        const dayEndTimestamp = moment.utc(dayStartTimestamp).endOf('day').valueOf();
        const scoreMap = parseJSON(val) || {};
        if (!R.isEmpty(scoreMap)) {
          hasDataMap[dayStartTimestamp] = true;
        }

        // parse scores
        const anomalyScoresList = [];
        R.forEach((anomaly) => {
          // filter score, only prediction or current day's data is used
          if (anomaly.prediction || anomaly.timeStamp <= dayEndTimestamp) {
            anomalyScoresList.push({ timestamp: anomaly.timeStamp, score: round(anomaly.score, 4) });
          }
        }, R.values(scoreMap));

        allDayAnomalyScoresList = [...allDayAnomalyScoresList, ...anomalyScoresList];
      }, dailyScoreMap);

      // Add padding time info
      if (needPaddingData) {
        R.forEachObjIndexed((dayHasData, dayTime) => {
          if (!dayHasData) {
            const anomalyScoresList = R.map((mins) => {
              const timestamp = Number(dayTime) + mins * intervalInMinutes * 60000;
              return { timestamp, score: null };
            }, R.range(0, (24 * 60) / intervalInMinutes));
            allDayAnomalyScoresList = [...allDayAnomalyScoresList, ...anomalyScoresList];
          }
        }, hasDataMap);
      }

      // sort all days Anomaly Scores
      allDayAnomalyScoresList = R.sortWith([R.ascend(R.prop('timestamp'))], allDayAnomalyScoresList);

      // update current health info
      systemHealthInstance = [
        {
          ...systemHealth,

          // instance health chart used field
          allDayAnomalyScoresList,
        },
      ];
    } catch (e) {
      console.error(e);
    }

    return systemHealthInstance;
  };

  return fetchGet(getEndpoint('stats/global/instanceanomalyscore', 2), {
    ...credentials,
    envName: environmentId,
    startTime,
    endTime,
    customerName: systemInfo.ownerUserName,
    systemName: systemId,
    instanceName: instance,
    containerName: container,
  }).then((result) => {
    const dailyScoreMap = get(result, 'dailyScoreMap', {});
    const globalHealthByInstance = parseCharts(dailyScoreMap);
    return { globalHealthByInstance };
  });
};

export default getGlobalInstanceAnomalyScore;
