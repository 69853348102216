/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { message, Alert, Form, Input, InputNumber, Button, Select } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { settingsButtonMessages, settingsMessages } from '../../../common/settings/messages';
import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  userList: Array<Object>,
};

class AddServiceNowModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { userInfo } = props;

    this.state = {
      isSubmiting: false,
      errMsg: null,
      isVerify: false,
      verified: undefined,

      serviceHost: '',
      proxy: '',
      account: '',
      password: '',
      dampeningPeriod: 120,
      clientId: '',
      clientSecret: '',
      customerName: !userInfo.isAdmin && !userInfo.isLocalAdmin ? userInfo.userName : undefined,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleSumbit() {
    const { intl, credentials, onClose } = this.props;
    const { serviceHost, proxy, account, password, dampeningPeriod, clientId, clientSecret, customerName } = this.state;

    this.setState({ isSubmiting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('service-integration'), {
      ...credentials,
      operation: 'ServiceNow',
      // eslint-disable-next-line
      service_host: serviceHost,
      proxy,
      account,
      password,
      dampeningPeriod: dampeningPeriod * 60000,
      appId: clientId,
      appKey: clientSecret,
      customerName,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmiting: false });
          onClose(true);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  @autobind
  handleVerify() {
    const { intl, credentials } = this.props;
    const { serviceHost, proxy, account, password, dampeningPeriod, clientId, clientSecret, customerName } = this.state;

    this.setState({ isVerify: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('service-integration'), {
      ...credentials,
      verify: true,
      operation: 'ServiceNow',
      // eslint-disable-next-line
      service_host: serviceHost,
      proxy,
      account,
      password,
      dampeningPeriod: dampeningPeriod * 60000,
      appId: clientId,
      appKey: clientSecret,
      customerName,
    })
      .then((data) => {
        const { success } = data || {};
        if (success) {
          this.setState({ isVerify: false, verified: true });
        } else {
          this.setState({ isVerify: false, verified: false });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isVerify: false, verified: false });
      });
  }

  render() {
    const { intl, onClose, userInfo, userList } = this.props;
    const {
      isSubmiting,
      errMsg,
      isVerify,
      verified,
      serviceHost,
      proxy,
      account,
      password,
      dampeningPeriod,
      clientId,
      clientSecret,
      customerName,
    } = this.state;
    const hasError = !serviceHost || !account || !password || !dampeningPeriod || !verified || !customerName;
    return (
      <Modal
        width={650}
        title={intl.formatMessage(settingsButtonMessages.addWebHook)}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSumbit}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
      >
        <Alert
          type="info"
          message={
            "For ServiceNow, make sure your user is authorized for the 'rest_service' role" +
            " as well as the 'itil' to post incidents."
          }
          banner
          style={{ marginBottom: 16 }}
        />
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          {userInfo.isAdmin && (
            <Form.Item
              label={intl.formatMessage(eventMessages.userName)}
              validateStatus={!customerName ? 'error' : 'success'}
              help={!customerName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                size="small"
                value={customerName}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(customerName) => this.setState({ customerName, verified: undefined })}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Host"
            validateStatus={!serviceHost ? 'error' : 'success'}
            help={!serviceHost ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input
              value={serviceHost}
              onChange={(e) => this.setState({ serviceHost: e.target.value, verified: undefined })}
            />
          </Form.Item>
          <Form.Item label="Proxy">
            <Input value={proxy} onChange={(e) => this.setState({ proxy: e.target.value, verified: undefined })} />
          </Form.Item>
          <Form.Item
            label="Account"
            validateStatus={!account ? 'error' : 'success'}
            help={!account ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input value={account} onChange={(e) => this.setState({ account: e.target.value, verified: undefined })} />
          </Form.Item>
          <Form.Item
            label="Password"
            validateStatus={!password ? 'error' : 'success'}
            help={!password ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input.Password
              autoComplete="new-password"
              value={password}
              onChange={(e) => this.setState({ password: e.target.value, verified: undefined })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(settingsMessages.dampeningPeriod)}
            validateStatus={!dampeningPeriod ? 'error' : 'success'}
            help={!dampeningPeriod ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <InputNumber
              min={1}
              value={dampeningPeriod}
              onChange={(dampeningPeriod) => this.setState({ dampeningPeriod, verified: undefined })}
            />
          </Form.Item>
          <Form.Item label="Client Id">
            <Input value={clientId} onChange={(e) => this.setState({ clientId: e.target.value })} />
          </Form.Item>
          <Form.Item label="Client Secret">
            <Input.Password value={clientSecret} onChange={(e) => this.setState({ clientSecret: e.target.value })} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            <div className="flex-row flex-center-align">
              <Button
                size="small"
                type="primary"
                style={{ marginRight: 16 }}
                loading={isVerify}
                onClick={this.handleVerify}
              >
                Verify
              </Button>
              {verified === true && <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 16 }} />}
              {verified === false && <CloseCircleOutlined style={{ color: '#f5222d', fontSize: 16 }} />}
            </div>
          </Form.Item>

          {errMsg && (
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 },
              }}
            >
              <Alert message={errMsg} type="error" showIcon />
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

const AddServiceNowModal = injectIntl(AddServiceNowModalCore);
export default connect(
  (state) => {
    const { userInfo, credentials } = state.auth;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return { userInfo, credentials, userList };
  },
  { updateLastActionInfo },
)(AddServiceNowModal);
