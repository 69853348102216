/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import * as R from 'ramda';
import { get } from 'lodash';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getGlobalHealthScores } from '../../apis';
import { appMessages } from '../../app/messages';

const loadGlobalHealthLogic = createLogic({
  type: [ActionTypes.LOAD_GLOBAL_HEALTH],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process({ getState, action }, dispatch, done) {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { isAdmin } = state.auth.userInfo;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { isAutoReload, environmentId, customerName } = params;
    const { globalInfo, userList } = state.app;

    // Get the system ids for the environment
    const environment = R.find((item) => item.id === environmentId, globalInfo);
    const systemInfoMap = {};
    R.forEach((system) => {
      systemInfoMap[system.id] = system.systemInfo || {};
    }, get(environment, 'systemList', []));

    // use user/customer time zone to set isCurrentDay
    let { timezoneOffset } = state.app;
    if (isAdmin && customerName) {
      const customerUserInfo = R.find((user) => user.userName === customerName, userList || []);
      timezoneOffset = customerUserInfo ? customerUserInfo.userZoneOffset : timezoneOffset;
    }

    dispatch(showLoading);
    if (!isAutoReload) dispatch(updateLastActionInfo());
    getGlobalHealthScores(credentials, {
      ...params,
      systemInfoMap,
      isAdmin,
      environment,

      timezoneOffset,
    })
      .then(async (data) => {
        const { globalHealthSummary } = data;
        dispatch(createSetAction(ActionTypes.SET_GLOBAL_HEALTH, params, { globalHealthSummary }));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get global system health', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message, error: err }));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }
        done();
      });
  },
});

export default loadGlobalHealthLogic;
