import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';

import { Defaults } from '../../../common/utils';
import { Container, DatePicker, Select, Modal } from '../../../lib/fui/react';
import { appFieldsMessages } from '../../../common/app/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projectList: Array<Object>,

  timeSelectParams: Object,
  categoryOptions: Array<Object>,
  startTimestamp: Number,
  endTimestamp: Number,

  onClose: Function,
};

class ReportSelectModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    const { categoryOptions, startTimestamp, endTimestamp } = props;

    this.categoryOptions = categoryOptions || [];
    this.reportTypeOptions = [];

    const categoryObj = this.categoryOptions.length > 0 ? this.categoryOptions[0] : null;
    this.handleCategoryChange(categoryObj);

    this.state = {
      category: categoryObj ? categoryObj.value : '',
      reportType: this.reportTypeOptions.length > 0 ? this.reportTypeOptions[0].value : '',
      startTimestamp: startTimestamp || moment.utc().startOf('day').valueOf(),
      endTimestamp: endTimestamp || moment.utc().endOf('day').valueOf(),
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = nextProps;
    if (intl.locale !== this.props.intl.locale) {
      moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    }
  }

  @autobind
  handleConfirm() {
    const { category, reportType, ...rest } = this.state;
    const { onClose, timeSelectParams } = this.props;
    if (onClose) {
      const result = {
        timeSelectParams,
        category,
        reportType,
        ...rest,
      };
      onClose(result);
    }
  }

  @autobind
  handleStartTimeChange(dateObj) {
    if (dateObj) {
      let { endTimestamp } = this.state;
      const selectTime = dateObj.valueOf();
      if (selectTime > endTimestamp) {
        endTimestamp = selectTime;
      }
      this.setState({ startTimestamp: selectTime, endTimestamp });
    } else {
      this.setState({ startTimestamp: null });
    }
  }

  @autobind
  handleEndTimeChange(dateObj) {
    if (dateObj) {
      let { startTimestamp } = this.state;
      const selectTime = dateObj.valueOf();
      if (startTimestamp > selectTime) {
        startTimestamp = selectTime;
      }
      this.setState({ endTimestamp: selectTime, startTimestamp });
    } else {
      this.setState({ endTimestamp: null });
    }
  }

  @autobind
  handleCategoryChange(newValue) {
    const category = newValue ? newValue.value : null;
    const categoryObj = R.find((item) => item.value === category, this.categoryOptions);
    if (categoryObj) {
      const { hasCSV, hasPDF } = categoryObj || {};
      const reportTypeOptions = [];
      if (hasCSV) {
        reportTypeOptions.push({ label: 'CSV', value: 'csv' });
      }
      if (hasPDF) {
        reportTypeOptions.push({ label: 'PDF', value: 'pdf' });
      }
      this.reportTypeOptions = reportTypeOptions;
      this.setState({ category, reportType: reportTypeOptions.length > 0 ? reportTypeOptions[0].value : null });
    }
  }

  @autobind
  handleFieldChange({ field }) {
    return (newValue) => {
      const fieldVal = newValue ? newValue.value : null;
      this.setState({ [field]: fieldVal });
    };
  }

  render() {
    const { intl } = this.props;
    const { onClose } = this.props;
    const { startTimestamp, endTimestamp, category, reportType } = this.state;
    const startTimeObj = moment.utc(startTimestamp);
    const endTimeObj = moment.utc(endTimestamp);
    const nowObj = moment.utc();

    const categoryObj = R.find((item) => item.value === category, this.categoryOptions);
    const { needDateCSV, needDatePDF } = categoryObj || {};

    const needDate = (reportType === 'csv' && needDateCSV) || (reportType === 'pdf' && needDatePDF);
    const hasError = !category || !reportType || (needDate && (!startTimestamp || !endTimestamp));
    return (
      <Modal
        width={650}
        title="Download"
        onCancel={() => onClose()}
        onOk={this.handleConfirm}
        okButtonProps={{ disabled: hasError }}
        visible
        maskClosable={false}
      >
        <Container className="flex-col content" style={{ fontSize: 12 }}>
          <div className="flex-row flex-center-align" style={{ padding: '0 0 14px 0' }}>
            <span className="label" style={{ width: 80, fontWeight: 'bold' }}>
              Category:
            </span>
            <Select
              name="category"
              style={{ width: 200 }}
              value={category}
              onChange={this.handleCategoryChange}
              options={this.categoryOptions}
            />
          </div>

          <div className="flex-row flex-center-align" style={{ padding: '0 0 14px 0' }}>
            <span className="label" style={{ width: 80, fontWeight: 'bold' }}>
              Report Type:
            </span>
            <Select
              name="reportType"
              style={{ width: 200 }}
              value={reportType}
              onChange={this.handleFieldChange({ field: 'reportType' })}
              options={this.reportTypeOptions}
            />
          </div>

          {needDate && (
            <div className="flex-row flex-center-align" style={{ height: 28 }}>
              <span className="label" style={{ width: 80, fontWeight: 'bold' }}>
                Start Date:
              </span>
              <div className="ui input datePicker">
                <DatePicker
                  utcOffset={0}
                  className="date"
                  dateFormat={Defaults.DateFormat}
                  todayButton={intl.formatMessage(appFieldsMessages.today)}
                  maxDate={nowObj}
                  showYearDropdown
                  showMonthDropdown
                  selected={startTimeObj}
                  onChange={this.handleStartTimeChange}
                />
              </div>
              <span className="label" style={{ width: 80, fontWeight: 'bold', marginLeft: 20 }}>
                End Date:
              </span>
              <div className="ui input datePicker">
                <DatePicker
                  utcOffset={0}
                  className="date"
                  dateFormat={Defaults.DateFormat}
                  todayButton={intl.formatMessage(appFieldsMessages.today)}
                  maxDate={nowObj}
                  showYearDropdown
                  showMonthDropdown
                  selected={endTimeObj}
                  onChange={this.handleEndTimeChange}
                />
              </div>
            </div>
          )}
        </Container>
      </Modal>
    );
  }
}

const ReportSelectModal = injectIntl(ReportSelectModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    return {
      projectList: projects,
      location,
      loadStatus,
    };
  },
  { push, replace },
)(ReportSelectModal);
