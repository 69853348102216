/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import moment from 'moment';
import * as R from 'ramda';
import { get } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getGlobalKeywordsEvents = (credentials: Credentials, params: Object) => {
  const { projectName, dayString, ...rest } = params;

  // Use the time as the incident id.
  return fetchGet(getEndpoint('logstreaminganomalyevent'), {
    ...credentials,
    projectName,
    ...rest,
    dayTimeMillis: moment.utc(dayString, 'YYYY-MM-DD').valueOf(),
    dayString,
  }).then((data) => {
    let totalCount = 0;
    let events = [];
    const instanceEventsMap = {};
    R.forEachObjIndexed((value, key) => {
      let instanceEvents = value;
      instanceEvents = R.map((e) => {
        const { timestamp, ...others } = e;
        return {
          instanceName: key,
          timestamp,
          ...others,
        };
      }, instanceEvents);
      if (instanceEvents.length > 0) {
        instanceEventsMap[key] = instanceEvents;
      }
      events = R.concat(events, instanceEvents);
      totalCount += instanceEvents.length;
    }, data || {});

    events = R.sortWith([R.ascend(R.prop('timestamp'))])(events);
    return {
      totalCount,
      events,
      instanceEventsMap,
    };
  });
};

export default getGlobalKeywordsEvents;
