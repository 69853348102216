/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Layout, Alert, Drawer, Input, Button } from 'antd';
import { debounce } from 'lodash';

import { AppstoreAddOutlined, HomeOutlined } from '@ant-design/icons';
import type { State } from '../../../common/types';
import { Container } from '../../../lib/fui/react';
import { Defaults, GlobalRenderers, MenuBarRender, buildLocation, parseLocation } from '../../../common/utils';
import { createSetAction, setOpenedSubMenus, setCurrentTheme, setCollapsed } from '../../../common/app/actions';
import { logoff, loginSuccess } from '../../../common/auth/actions';
import { appFieldsMessages, appMessages } from '../../../common/app/messages';

import PageLoader from './PageLoader';
import logo from '../../../../images/InsightFinder_Horizontal_White.svg';
import AppStore from './AppStore';
import refreshTokenFnTime from '../../../common/apis/refreshTokenFnTime';
import BaseUrls from '../BaseUrls';

const { Content, Sider } = Layout;

type Props = {
  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Object,
  location: Object,
  children: Element<any>,

  licenseValid: Boolean,
  licenseWillExpired: Boolean,
  expirationDate: Number,
  currentTheme: String,
  timezoneOffset: Number,
  pageLoaderVisible: Boolean,
  createSetAction: Function,

  credentials: Object,
  userInfo: Object,
  toursState: Object,
  userName: String,
  logoff: Function,
  loginSuccess: Function,
  systemsMap: Object,
  projectSettingsParams: Object,
  openedSubMenus: Array<string>,
  setOpenedSubMenus: Function,
  setCurrentTheme: Function,
  collapsed: Boolean,
  setCollapsed: Function,
  showDetailsFrame: Boolean,
};

class AppCentricPageSingleCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { collapsed } = props;

    this.state = {
      collapsed,
      localTime: moment().format(Defaults.TimeOnlyFormat),
      greetUser: null,
      appStoreOpen: false,
      appFilter: '',
    };
    this.ruleStrMap = {
      ReadOnlyUser: 'ReadOnlyUser',
      LocalAdmin: 'Administrator',
      NormalUser: 'NormalUser',
      Admin: 'Global Administrator',
    };
    this.handleMouseMove = debounce(this.handleMouseMove, 1000, { trailing: true });
  }

  componentDidMount() {
    const { intl } = this.props;
    this.projectName = null;
    this.day = null;
    moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);

    // set the local timezone
    this.setLocalTimezone();
    this.timer = setInterval(() => {
      this.refreshTime();
    }, 20 * 1000);

    // Clear refresh timer
    window.localStorage.removeItem('refreshTokenFnTime');
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};

    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  @autobind
  refreshTime() {
    this.setLocalTimezone();
  }

  @autobind
  setLocalTimezone() {
    const { timezoneOffset } = this.props;
    const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
    const now = moment.utc(nowTimestamp);
    const hours = now.hours();

    let greetUser = 'evening';
    if (hours >= 5 && hours < 11) {
      greetUser = 'morning';
    } else if (hours >= 11 && hours < 16) {
      greetUser = 'afternoon';
    }
    const localTime = now.format(Defaults.TimeOnlyFormat);
    this.setState({ localTime, greetUser });
  }

  @autobind
  onCollapseSider(collapsed) {
    this.setState({ collapsed }, () => {
      this.props.setCollapsed(collapsed);
    });
  }

  @autobind
  handleMouseMove() {
    const { credentials } = this.props;
    refreshTokenFnTime(credentials);
  }

  @autobind
  handleAppSearch(e) {
    const appFilter = e.target.value;
    this.setState({ appFilter });
  }

  render() {
    const { intl, push, location, createSetAction, children, setOpenedSubMenus } = this.props;
    const {
      licenseValid,
      licenseWillExpired,
      expirationDate,
      currentTheme,
      pageLoaderVisible,
      credentials,
      userInfo,
      toursState,
      logoff,
      loginSuccess,
      systemsMap,
      projectSettingsParams,
      openedSubMenus,
      setCurrentTheme,
      showDetailsFrame,
    } = this.props;
    const query = parseLocation(location);
    const pathname = location?.pathname;
    const { localTime, greetUser, appStoreOpen, appFilter } = this.state;

    let userNameDisplay = userInfo.firstName || (userInfo.fullName || '').split(' ')[0] || userInfo.userName;
    userNameDisplay = userInfo?.role ? this.ruleStrMap[userInfo.role] : userNameDisplay;
    userNameDisplay = userInfo.fullName || userNameDisplay;
    const showIntegrations = pathname !== BaseUrls.SettingsIntegrations;

    return (
      <Layout className="app-centric-page" style={{ minHeight: '100vh' }}>
        {(licenseValid === false || licenseWillExpired) && (
          <Alert
            message={
              licenseValid === false
                ? intl.formatMessage(appMessages.licenseValidFaild)
                : intl.formatMessage(appMessages.licenseWillExpired, {
                    date: <b>{moment.utc(expirationDate).format(Defaults.DateFormat)}</b>,
                  })
            }
            banner
          />
        )}
        <Drawer
          className="full-height-drawer"
          title={
            <div style={{ paddingRight: 8 }}>
              <Input.Search
                size="small"
                style={{ marginTop: 0 }}
                placeholder="Find..."
                allowClear
                value={appFilter}
                onChange={this.handleAppSearch}
              />
            </div>
          }
          placement="left"
          width={570}
          open={appStoreOpen}
          onClose={() => this.setState({ appStoreOpen: false, appFilter: '' })}
        >
          <div className="full-height app-store">
            <AppStore
              appFilter={appFilter}
              onAppClick={() => {
                this.setState({ appStoreOpen: false, appFilter: '' });
              }}
            />
          </div>
        </Drawer>

        <Container className="topbar" style={{ alignItems: 'center' }}>
          <Link to="/" className="logo">
            <img src={logo} alt="logo" />
          </Link>
          <div
            style={{ fontSize: 20, marginRight: 20 }}
            className="pl-2 clickable"
            onClick={() => this.setState({ appStoreOpen: true, appFilter: '' })}
          >
            <AppstoreAddOutlined />
          </div>
          <div
            style={{ fontSize: 20, marginRight: 20 }}
            className="clickable"
            onClick={() => {
              push(buildLocation(BaseUrls.GlobalStart, {}, { customerName: query?.customerName }));
            }}
          >
            <HomeOutlined />
          </div>
          {showIntegrations && (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                push(buildLocation(BaseUrls.SettingsIntegrations, {}, { customerName: query?.customerName }));
              }}
            >
              {intl.formatMessage(appFieldsMessages.integrations)}
            </Button>
          )}

          <div
            className="flex-grow flex-row flex-center-align flex-end-justify"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {userNameDisplay && (
              <span>
                {greetUser === 'morning'
                  ? intl.formatMessage(appMessages.goodMorning, { userName: userNameDisplay })
                  : greetUser === 'afternoon'
                  ? intl.formatMessage(appMessages.goodAfternoon, { userName: userNameDisplay })
                  : intl.formatMessage(appMessages.goodEvening, { userName: userNameDisplay })}
              </span>
            )}

            <span style={{ marginLeft: 8 }}>{localTime}</span>
          </div>
          <MenuBarRender
            intl={intl}
            location={location}
            push={push}
            userInfo={userInfo}
            credentials={credentials}
            logoff={logoff}
            loginSuccess={loginSuccess}
            createSetAction={createSetAction}
            toursState={toursState}
            currentTheme={currentTheme}
            setCurrentTheme={setCurrentTheme}
          />
        </Container>

        <Layout>
          <Sider
            collapsedWidth={50}
            theme="dark"
            width={220}
            style={{ fontWeight: 'bold' }}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapseSider}
          >
            {GlobalRenderers.SiderMenuRender({
              intl,
              currentTheme,
              push,
              location,
              userInfo,
              systemsMap,
              projectSettingsParams,
              openedSubMenus,
              setOpenedSubMenus,
              collapsed: this.state.collapsed,
            })}
          </Sider>
          <Layout>
            <Content>
              <PageLoader key="loader" visible={pageLoaderVisible} />
              <div
                className={`full-width full-height ${showDetailsFrame ? '' : 'flex-col'}`}
                style={showDetailsFrame ? { position: 'absolute', top: -1000000000, left: -1000000000 } : {}}
              >
                {children}
              </div>
              <div
                id="DETAILS_FRAME"
                className={`full-width full-height ${showDetailsFrame ? 'flex-col' : 'display-none'}`}
              />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

const AppCentricPageSingle = injectIntl(AppCentricPageSingleCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { userName } = state.auth.userInfo;
    const {
      licenseValid,
      licenseWillExpired,
      expirationDate,
      currentTheme,
      timezoneOffset,
      globalInfoLoaded,
      globalInfo,
      toursState,
      systemsMap,
      openedSubMenus,
      collapsed,
      showDetailsFrame,
    } = state.app;

    const { projectSettingsParams } = state.settings;
    const pageLoaderVisible = Boolean(state.app.pageLoaderVisible);
    return {
      location,
      userInfo,
      credentials,
      userName,
      licenseValid,
      licenseWillExpired,
      expirationDate,
      currentTheme,
      pageLoaderVisible,
      timezoneOffset,
      globalInfoLoaded,
      globalInfo,
      toursState,
      systemsMap,
      projectSettingsParams,
      openedSubMenus,
      collapsed,
      showDetailsFrame,
    };
  },
  { push, replace, createSetAction, logoff, loginSuccess, setOpenedSubMenus, setCurrentTheme, setCollapsed },
)(AppCentricPageSingle);
