import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, round, isNumber } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Spin, Divider, Space } from 'antd';

import { Defaults, sleep } from '../../../common/utils';
import { AutoSizer } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { TriangleDownPath, PinPath } from '../../../lib/fui/icons';

import { appFieldsMessages } from '../../../common/app/messages';

import { EChart } from '../../share';
import GlobalPanelLineChart from './GlobalPanelLineChart';
import GlobalPanelLogQuery from './GlobalPanelLogQuery';

type Props = {
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoading: Boolean,
  // eslint-disable-next-line
  isAutoReload: Boolean,
  // eslint-disable-next-line
  customerName: String,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  defaultTimezone: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  startTime: String,
  endTime: String,
  forceRefreshTime: String,
  customerName: String,
  environmentId: String,
  onSetQueryParams: Function,
};

class SystemHealthCardCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      defaultTimezone: 'UTC',
      chartOption: {},
    };
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.systemHealth !== this.props.systemHealth) {
      this.parseData(nextProps);
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  async parseData(props) {
    const { isAutoReload, systemHealth, systemInfo, credentials, systemsMap } = props;

    this.setState({ isLoading: !isAutoReload });
    await sleep(300);

    // get user timezone
    const { userInfo, customerName, userList } = props;
    let { defaultTimezone } = props;
    // use customer time zone to display data for admin
    if (userInfo.isAdmin && customerName) {
      const customerUserInfo = R.find((user) => user.userName === customerName, userList || []);
      defaultTimezone = customerUserInfo ? customerUserInfo.zone : defaultTimezone;
    } else if (systemInfo.owner !== credentials.userName) {
      const timezone = get(systemsMap, [systemInfo.id, 'timezone']);
      defaultTimezone = timezone || defaultTimezone;
    }

    // parse chart
    const { option: chartOption } = this.buildChartOption(props, systemHealth);

    this.setState({
      isLoading: false,
      defaultTimezone,
      chartOption,
    });
  }

  @autobind
  buildChartOption(props, systemHealth) {
    const { intl } = props;
    const {
      allDayAnomalyScoresList,
      detectionIncidentTimes,
      predictionIncidentTimes,
      isCurrentDay,
      currentTimestamp,
      currentScore,
      predictionIncidentTimestamp,
      dataStartTimestamp,
    } = systemHealth;
    let healthScoresList = allDayAnomalyScoresList || [];
    healthScoresList = R.map(
      (item) => ({ ...item, score: isNumber(item.score) ? R.max(0, item.score) : null, realScore: item.score }),
      healthScoresList,
    );

    // get prediction window area
    // eslint-disable-next-line
    let pastStartTime = null;
    let predictionStartP = 0;
    let predictionStartTime = null;
    if (isCurrentDay) {
      R.addIndex(R.forEach)((item, idx) => {
        if (idx === 0) {
          pastStartTime = item.timestamp;
        }
        if (currentTimestamp >= item.timestamp) {
          predictionStartP = idx;
          predictionStartTime = item.timestamp;
        }
        if (idx === 0) {
          pastStartTime = item.timestamp;
        }
      }, healthScoresList);
    } else if (healthScoresList.length > 0) {
      predictionStartP = healthScoresList.length - 1;
      predictionStartTime = healthScoresList[healthScoresList.length - 1].timestamp;
    }
    const predictionStartTimePercent =
      healthScoresList.length > 0
        ? (Number(predictionStartTime) - healthScoresList[0].timestamp) /
          (healthScoresList[healthScoresList.length - 1].timestamp - healthScoresList[0].timestamp)
        : 1;

    // build health chart
    const dataList = R.addIndex(R.map)((item, index) => {
      return {
        name: item.timestamp,
        value: [item.timestamp, item.score],
        realScore: item.realScore,
      };
    }, healthScoresList);

    // build detection chart
    const dataListdetectionIncident = R.map((timestamp) => {
      return {
        dataId: 'incidentLine',
        value: [timestamp, 100],
        name: timestamp,
        realScore: 100,
        itemStyle: { color: '#FF5142' },
        symbol: `path://${PinPath}`,
        symbolSize: 12,
        symbolOffset: [0, -12],
      };
    }, detectionIncidentTimes || []);
    const seriesDetectionIncident = {
      id: 'incidentLine',
      data: dataListdetectionIncident,
      type: 'scatter',
      showSymbol: true,
      showAllSymbol: true,
      hoverAnimation: false,
      cursor: 'pointer',
    };

    // build prediction chart
    const dataListpredictionIncident = R.map(
      (timestamp) => {
        return {
          dataId: 'predictionIncidentLine',
          value: [timestamp, 100],
          name: timestamp,
          realScore: 100,
          itemStyle: { color: '#ffad66' },
          symbol: `path://${PinPath}`,
          symbolSize: 10,
          symbolOffset: [0, -12],
        };
      },
      R.filter(
        (timestamp) => dataStartTimestamp <= timestamp && timestamp <= currentTimestamp,
        predictionIncidentTimestamp || [],
      ),
    );
    const seriesPredictionIncident = {
      id: 'predictionIncidentLine',
      data: dataListpredictionIncident,
      type: 'effectScatter',
      rippleEffect: {
        period: 2,
        scale: 3,
        brushType: 'stroke',
      },
      showSymbol: true,
      showAllSymbol: true,
      hoverAnimation: false,
      cursor: 'pointer',
    };

    return {
      option: {
        useUTC: true,
        tooltip: {
          trigger: 'axis',
          axisPointer: { animation: false },
          padding: [4, 8],
          textStyle: { fontSize: 10 },
          position(pt) {
            return [0, -10];
          },
          formatter: (params) => {
            const { name, data } = params[0];
            const { realScore } = data;
            return `${moment.utc(Number(name)).format('dddd, MMM DD, HH:mm')}: ${realScore}`;
          },
        },
        grid: { left: 10, right: 10, top: 45, bottom: 24 },
        xAxis: {
          show: false,
          type: 'time',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          show: false,
          type: 'value',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            id: 'healthLine',
            type: 'line',
            showSymbol: false,
            data: R.slice(0, predictionStartP + 1, dataList),
            itemStyle: { color: 'rgba(103, 141, 255, 1)' },
            lineStyle: { width: 1.5 },
            areaStyle: { color: 'rgba(103, 141, 255, 0.3)' },
            markPoint: isCurrentDay
              ? {
                  silent: true,
                  itemStyle: { color: '#788094' },
                  data: [
                    {
                      name: 'currentScore',
                      xAxis: predictionStartTime,
                      yAxis: 100,
                      symbol: `path://${TriangleDownPath}`,
                      symbolSize: 5,
                      symbolOffset: [0, -28],

                      label: {
                        position: 'top',
                        distance: 2,
                        // offset label if current time >= 80% or >= 20%
                        offset: [
                          predictionStartTimePercent >= 0.8 ? -140 : predictionStartTimePercent >= 0.2 ? -68 : -4,
                          0,
                        ],
                        align: 'left',
                        color: '#788094',
                        formatter: `${intl.formatMessage(appFieldsMessages.currentScore)}: ${round(currentScore)}`,
                      },
                    },
                    {
                      name: 'currentTime',
                      xAxis: predictionStartTime,
                      yAxis: 0,
                      symbol: `path://${TriangleDownPath}`,
                      symbolRotate: 180,
                      symbolSize: 5,
                      symbolOffset: [0, 6],
                      label: {
                        position: 'bottom',
                        distance: 0,
                        // offset label if current time >= 80% or >= 20%
                        offset: [
                          predictionStartTimePercent >= 0.8 ? -146 : predictionStartTimePercent >= 0.2 ? -74 : -4,
                          0,
                        ],
                        align: 'left',
                        color: '#788094',
                        formatter: `${intl.formatMessage(appFieldsMessages.currentTime)}: ${moment
                          .utc(currentTimestamp)
                          .format(Defaults.ShortTimeFormat)}`,
                      },
                    },
                  ],
                }
              : {},
          },
          {
            id: 'predictionHealthLine',
            type: 'line',
            showSymbol: false,
            data: R.slice(predictionStartP, Infinity, dataList),
            itemStyle: { color: 'rgba(28, 190, 203, 1)' },
            lineStyle: { width: 1.5 },
            areaStyle: { color: 'rgba(28, 190, 203, 0.3)' },
          },
          seriesDetectionIncident,
          seriesPredictionIncident,
        ],
      },
    };
  }

  render() {
    const {
      intl,
      isLoading: isLoadingChart,
      systemInfo,
      startTime,
      endTime,
      forceRefreshTime = 0,
      customerName,
      environmentId,
      onSetQueryParams,
    } = this.props;
    const { isLoading, defaultTimezone, chartOption } = this.state;
    return (
      <Spin wrapperClassName="full-width" spinning={isLoading || isLoadingChart}>
        <AutoSizer disableHeight>
          {({ width }) => <EChart width={width} height={160} option={chartOption} onEvents={{}} />}
        </AutoSizer>
        <Divider style={{ margin: '6px 0' }} />
        <div className="flex-row flex-center-align">
          <div className="bold light-label">
            {intl.formatMessage(appFieldsMessages.timezoneCaptical)}: {defaultTimezone}
          </div>
          <div className="flex-grow" />
          <Space
            className="flex-row"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <GlobalPanelLineChart
              startTime={startTime}
              endTime={endTime}
              customerName={customerName}
              environmentId={environmentId}
              refresh={forceRefreshTime}
              systemInfo={systemInfo}
            />
            <GlobalPanelLogQuery
              startTime={startTime}
              endTime={endTime}
              customerName={customerName}
              environmentId={environmentId}
              refresh={forceRefreshTime}
              systemInfo={systemInfo}
              onSetQueryParams={onSetQueryParams}
            />
          </Space>
        </div>
      </Spin>
    );
  }
}

const SystemHealthCard = injectIntl(SystemHealthCardCore);
export default connect(
  (state) => {
    const { defaultTimezone, loadStatus, projects, systemsMap, userList } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      defaultTimezone,
      loadStatus,
      projects,
      systemsMap,
      userList,
      credentials,
      userInfo,
    };
  },
  { updateLastActionInfo },
)(SystemHealthCard);
