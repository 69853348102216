import * as R from 'ramda';
import { get, isArray } from 'lodash';

import { message } from 'antd';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseTimelines } from './parsers';

// eslint-disable-next-line no-unused-vars
const mergeIncidentTimelines = (timelines) => {
  const incidentTimelines = R.filter((item) => item.type === 'Incident', timelines);
  const otherTimelines = R.filter((item) => item.type !== 'Incident', timelines);

  // split all same key incidents
  let mergeTimelines = {};
  R.forEach((event) => {
    let key = `${event.projectName}-${event.instanceName}-${event.patternId}`;
    key = event.category === 'metric' ? `${key}-${event.metricNameString}` : key;
    if (!R.has(key, mergeTimelines)) mergeTimelines[key] = [];
    mergeTimelines[key].push(event);
  }, incidentTimelines);

  mergeTimelines = R.map((vals) => {
    let mergedItem = vals[0];
    let otherItems = R.slice(1, Infinity, vals);

    // use has rca one
    const hasRootCauseIndex = R.findIndex((item) => item.rootCauseResultInfo.hasPrecedingEvent, vals);
    if (hasRootCauseIndex !== -1) {
      mergedItem = vals[hasRootCauseIndex];
      otherItems = R.addIndex(R.filter)((item, index) => index !== hasRootCauseIndex, vals);
    }
    // reset timestampMap for log/metric
    mergedItem = {
      ...mergedItem,
      timestampMap: mergedItem.timestampMap
        ? mergedItem.timestampMap
        : {
            [mergedItem.startTimestamp]: 1,
          },
    };

    // merge all evnets
    R.forEach((event) => {
      // add timestampMap for metric anomaly
      const timestampMap = event.timestampMap
        ? event.timestampMap
        : {
            [event.startTimestamp]: 1,
          };
      mergedItem = {
        ...mergedItem,
        timestampMap: { ...mergedItem.timestampMap, ...timestampMap },
        startTimestamp: R.min(mergedItem.startTimestamp, event.startTimestamp),
        endTimestamp: R.max(mergedItem.endTimestamp, event.endTimestamp),
      };
    }, otherItems);

    return mergedItem;
  }, mergeTimelines);

  // return merged/notmerged events
  return [...R.values(mergeTimelines), ...otherTimelines];
};

const getRootCauseTimelines = (credentials: Credentials, params: Object) => {
  const {
    level,
    operation,
    customerName,
    environmentName,
    startTimestamps,
    startTime,
    fromJobAnalysis,
    ignoreFirstDayData,
    systemId,
    systemInfoMap,
    instanceInfoMap,
  } = params;

  let hasErrorMap = null;

  const requests = [];
  R.forEach((startTimestamp) => {
    if (instanceInfoMap) {
      if (instanceInfoMap[startTimestamp]) {
        const { isContainer, ...rest } = instanceInfoMap[startTimestamp];
        const postKey = isContainer ? 'containerIds' : 'instanceIds';
        requests.push(
          fetchGet(getEndpoint('rootcausetimelines', 2), {
            ...credentials,
            environmentName,
            [postKey]: JSON.stringify([rest]),
            systemId,
            customerName,
            startTime: fromJobAnalysis ? startTime : startTimestamp,
            operation: operation === 'logAlert' ? 'Alert' : operation === 'Metric' ? 'MetricAnomaly' : operation,
            ...(operation === 'Incident' ? { includePastOccurrence: true } : {}),
          }).catch((e) => {
            console.warn('[IF_API] failed to get all system timelines', e);
            if (!hasErrorMap) hasErrorMap = { message: e.message || String(e) };
            return [];
          }),
        );
      } else {
        requests.push(Promise.resolve([]));
      }
    } else {
      requests.push(
        fetchGet(getEndpoint('rootcausetimelines', 2), {
          ...credentials,
          environmentName,
          systemIds: JSON.stringify([{ id: systemId }]),
          customerName,
          startTime: fromJobAnalysis ? startTime : startTimestamp,
          operation: operation === 'logAlert' ? 'Alert' : operation === 'Metric' ? 'MetricAnomaly' : operation,
          ...(operation === 'Incident' ? { includePastOccurrence: true } : {}),
        }).catch((e) => {
          console.warn('[IF_API] failed to get all system timelines', e);
          if (!hasErrorMap) hasErrorMap = { message: e.message || String(e) };
          return [];
        }),
      );
    }
  }, startTimestamps);

  return Promise.all(requests).then((results) => {
    const timelinesMap = {};
    R.addIndex(R.forEach)((result, idx) => {
      const timeline = isArray(result) && result.length > 0 ? result[0] : {};
      const { projectNameSet, projectDisplayMap } = get(systemInfoMap, [systemId || timeline?.id], {});
      parseTimelines({
        timeline,
        timelinesMap,
        projectNameSet,
        projectDisplayMap,
        notInclude: ignoreFirstDayData && idx === 0,
        systemId,
      });
    }, results);

    // build timelines
    const systemTimelinesMap = {};
    R.forEachObjIndexed((valList, id) => {
      const newValList = R.filter((item) => !item.notInclude, valList);
      R.forEach((item) => {
        const { systemTimeline } = item;
        const anomalyTimelines = systemTimeline.anomalyTimelines || [];
        const compositeAnomalyTimeLines = systemTimeline.compositeAnomalyTimeLines || [];

        // merge incident timelines per day
        // const newAnomalyTimelines = mergeIncidentTimelines(anomalyTimelines);

        if (!R.has(id, systemTimelinesMap)) {
          systemTimelinesMap[id] = { ...systemTimeline, anomalyTimelines, compositeAnomalyTimeLines };
        } else {
          systemTimelinesMap[id] = {
            ...systemTimeline,
            anomalyTimelines: [...systemTimelinesMap[id].anomalyTimelines, ...anomalyTimelines],
            compositeAnomalyTimeLines: [
              ...systemTimelinesMap[id].compositeAnomalyTimeLines,
              ...compositeAnomalyTimeLines,
            ],
          };
        }
      }, newValList);
    }, timelinesMap);
    const timelines = R.map((timeline) => ({ ...timeline, level }), R.values(systemTimelinesMap));

    if (hasErrorMap) {
      message.error(hasErrorMap.message);
    }

    return {
      globalSystemInfo: timelines[0],
    };
  });
};

export default getRootCauseTimelines;
