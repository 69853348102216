/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
import moment from 'moment';
import { get } from 'lodash';

const isValidCredentials = (credentials: Object, userInfo: Object) => {
  const { userName } = credentials || {};
  // Get tokenExpiredTime in localStorage
  const tokenExpiredTime = Number(window.localStorage.getItem('tokenExpiredTime'));

  if (!userName || !userInfo || !tokenExpiredTime) {
    return false;
  }
  const currtentTimestamp = moment.utc().valueOf();
  return tokenExpiredTime > currtentTimestamp;
};

export default isValidCredentials;
