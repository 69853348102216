/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import * as R from 'ramda';

const appForecastMetricDataParser = (str) => {
  // Skip empty lines.
  const lines = R.filter((l) => Boolean(l), (str || '').split('\n'));

  // Get the first line as the metric names
  const metricMap = {};
  if (lines.length > 0 && lines[0].indexOf('timestamp') >= 0) {
    // Get the metric name and create data array for it.
    const names = R.map((n) => n.split('[')[0], lines[0].split(','));
    const metricList = R.map(() => [], names);

    // Drop the first line and get the values.
    R.forEach((line) => {
      const metrics = line.split(',');
      if (metrics.length !== metricList.length) {
        console.warn('[IF] mismatch line, ignored', names, metrics);
      } else {
        R.addIndex(R.forEach)((m, idx) => {
          metricList[idx].push(parseFloat(m));
        }, metrics);
      }
    }, R.drop(1, lines));

    const metrics = R.zipObj(names, metricList);
    const timestamp = metrics.timestamp;

    let id = 0;
    R.forEachObjIndexed((val, name) => {
      const sdata = R.addIndex(R.map)((v, idx) => [new Date(timestamp[idx]), v], val);
      const sname = ['datetime', name];
      id += 1;
      metricMap[name] = {
        id,
        annotations: undefined,
        groupId: '',
        highlights: [],
        incidentSummary: undefined,
        metrics: name,
        sdata,
        sname,
      };
    }, metrics);
  } else {
    console.warn('[IF] First line is not metric names');
  }

  return metricMap;
};

export default appForecastMetricDataParser;
