/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
/* eslint-disable no-console */

import * as R from 'ramda';
import { isObject, get } from 'lodash';

import moment from 'moment';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';
import { logPatternTopKToArray } from './magicParsers';

const getLogAnomalySequenceInfoList = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, day } = params;
  const startTimeObj = moment.utc(day, Defaults.DateFormat).startOf('day');

  const requests = [];
  requests.push(
    fetchGet(getEndpoint('anomalylogpatternsequencelist'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
    }),
  );

  return Promise.all(requests).then((results) => {
    const sequences = get(results[0], 'data', []);
    let sequenceList = [];
    let maxSequencesCount = 0;

    R.forEach((seq) => {
      const { count: seqencesCount, pattern: id, hasPatternName, patternInfoList } = seq;
      maxSequencesCount = Math.max(maxSequencesCount, seqencesCount);

      if (id) {
        let seqKeywords = [];
        const seqNameWords = [];
        const patternsInfo = R.filter((p) => isObject(p), patternInfoList || []);

        R.forEach((p) => {
          const { nid, patternName } = p;
          const keywords = logPatternTopKToArray(p.topK);
          let name = patternName;
          if (name === `Pattern ${nid}`) {
            seqNameWords.push(keywords[0] || name);
            name = keywords.join('-') || name;
          }
          seqKeywords = seqKeywords.concat(keywords);
        }, patternsInfo);

        sequenceList.push({
          id,
          patterns: seq.pattern,
          isSequence: true,
          name: hasPatternName ? seq.patternName : seqNameWords.join('-'),
          keywords: R.uniq(seqKeywords),
          seqencesCount,
        });
      }
    }, sequences);

    sequenceList = R.sort((a, b) => b.seqencesCount - a.seqencesCount, sequenceList);
    // const totalCount = R.reduce((acc, i) => acc + i.seqencesCount, 0, sequenceList);
    R.forEach((p) => (p.countRatio = p.seqencesCount / maxSequencesCount), sequenceList);
    return {
      anomalySequenceInfoList: sequenceList,
    };
  });
};

export default getLogAnomalySequenceInfoList;
