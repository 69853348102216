import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import { Input, message, Spin } from 'antd';
import { isEmpty } from 'lodash';

import { EChart } from '../../share';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { buildLocation, Defaults, getRegExp, parseLocation } from '../../../common/utils';

import { appFieldsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { AutoSizer } from '../../../lib/fui/react';

const getProjectTitle = (name, projectMap, projectToDisplayNameMap) => {
  const [p, u] = R.split('@', name || '');
  if (!p || !projectMap[p]) {
    return !p ? name : p;
  }
  if (projectMap[p].length > 1) {
    const findP = R.find((item) => item.projectName === p && item.customerName === u, projectMap[p]);
    return findP ? `${findP.projectDisplayName}@${findP.customerName}` : p;
  } else {
    return projectToDisplayNameMap[name]?.projectDisplayName || p;
  }
};

const getMonthStrList = (data) => {
  return R.compose(
    R.uniq(),
    R.map((item) => moment.utc(Number(item)).format(Defaults.MonthFormat)),
  )(R.keys(data || {}));
};

const getSectionTime = (startDate, endDate) => {
  const startTime = moment.utc(startDate).startOf('month').valueOf();
  let endTime = moment
    .utc(endDate || startDate)
    .endOf('month')
    .valueOf();
  const nowTimestamp = moment.utc().endOf('days').valueOf();
  if (endTime > nowTimestamp) {
    endTime = nowTimestamp;
  }
  return { startTime, endTime };
};

/*
    思路：
        1. 获取x轴的数据(时间戳和字符串时间)
        2. 获取接口数据所有的legend名、总数、进行排序、插入颜色
        3. 过滤获取当月的数据(时间戳和字符串时间)
        4. 通过当月的数据
            1）获取当月的legend总数，进行排序
            2）获取当月前10的数据
        5. 处理前10的series
*/
const getOption = (data, selectMonth, searchProjectName, localProjects) => {
  const { startTime, endTime } = getSectionTime(selectMonth);

  // 颜色值
  const colors = [
    '#ad4c34',
    '#df9954',
    '#744a74',
    '#547ec9',
    '#D37387',
    '#83d4de',
    '#1D7324',
    '#e095e0',
    '#68B78C',
    '#ae9be3',

    '#ee8e76',
    '#7e93b7',
    '#6fc876',
    '#a8d6b7',
    '#d9e446',
    '#ca87ca',
    '#4e6da5',
    '#C35989',
    '#918ADF',
    '#D78742',

    '#B9541A',
    '#E9A133',
    '#3878A1',
    '#A3416E',
    '#F1AEA4',
    '#8BCDBC',
    '#399561',
    '#A4D8A8',
    '#764EA2',
    '#B2ADED',

    '#BC5B22',
    '#EEB46C',
    '#3E769E',
    '#ABC4A2',
    '#3B925D',
    '#B2CB9A',
    '#6565C5',
    '#B4B4F2',
    '#98366D',
    '#DD86AF',

    '#FFC940',
    '#FFEEC5',
    '#96ec9d',
    '#E8F8B6',
    '#D1E1FF',
    '#dab45a',
    '#6B9FA1',
    '#FFE39F',
    '#ddd8cc',
    '#E1BAE1',

    // '#20B2AA'
  ];

  let projectMap = {};
  const projectToDisplayNameMap = {};

  data = R.mapObjIndexed((values, day) => {
    return R.map((item) => {
      const newProjects = R.map((p) => {
        const { projectName, userName } = p;
        const projectInfo = R.find((project) => project.projectShortName === projectName, localProjects || []);
        const projectDisplayName = projectInfo?.projectDisplayName || projectName;
        const projectAndUser = `${projectName}@${userName}`;

        if (!projectMap[projectName]) {
          projectMap[projectName] = [{ projectName, projectDisplayName, customerName: userName }];
        } else {
          projectMap[projectName] = [
            ...projectMap[projectName],
            { projectName, projectDisplayName, customerName: userName },
          ];
        }

        if (!projectToDisplayNameMap[projectAndUser]) {
          projectToDisplayNameMap[projectAndUser] = { projectName, projectDisplayName, customerName: userName };
        }
        return { ...p, projectName: projectAndUser, projectDisplayName };
      }, item.projects || []);
      return { ...item, projects: newProjects };
    }, values || []);
  }, data || []);

  projectMap = R.mapObjIndexed((val, key) => {
    return R.uniqBy((item) => `${item.projectName}@${item.customerName}`, val || []);
  }, projectMap);

  const regex = getRegExp(searchProjectName);
  data = R.mapObjIndexed((values, day) => {
    return R.map((item) => {
      const newProjects = R.filter((project) => {
        return regex ? regex.test(`${project.projectDisplayName}@${project.userName}`) : false;
      }, item.projects || []);
      return { ...item, projects: newProjects };
    }, values || []);
  }, data || []);

  // 获取x轴
  const xAxisData = R.compose(
    R.map((item) => moment.utc(Number(item)).format(Defaults.DateFormat)),
    R.filter((item) => Number(item) >= startTime && Number(item) <= endTime),
  )(R.keys(data || {}));
  const xAxisTimestampData = R.compose(
    R.map((item) => Number(item)),
    R.filter((item) => Number(item) >= startTime && Number(item) <= endTime),
  )(R.keys(data || {}));

  // 获取当月份的数据 和 所有月份的legend数据
  let filterSameMonth = {};
  let filterTimeStrSameMonth = {};
  const allLegendTotalObj = {};
  R.forEachObjIndexed((val, key) => {
    R.forEach((item) => {
      const { projects } = item;

      // 获取每个 legend 总数
      R.forEach((_item) => {
        const { projectName, logEventCount } = _item;
        if (!R.has(projectName, allLegendTotalObj)) {
          allLegendTotalObj[projectName] = logEventCount;
        } else {
          allLegendTotalObj[projectName] += logEventCount;
        }
      }, projects || []);
    }, val || []);

    if (Number(key) >= startTime && Number(key) <= endTime) {
      const value = R.map((item) => ({ ...item, value: item.logEventCount }), val || []);
      const timeStr = moment.utc(Number(key)).format(Defaults.DateFormat);
      filterSameMonth = { ...filterSameMonth, [key]: value };
      filterTimeStrSameMonth = { ...filterTimeStrSameMonth, [timeStr]: value };
    }
  }, data || {});

  // 处理总legend 并排序
  let allLegendTotalList = [];
  R.forEachObjIndexed((val, key) => {
    allLegendTotalList.push({ projectName: key, total: val });
  }, allLegendTotalObj);
  allLegendTotalList = R.sortWith([R.descend(R.prop('total'))], allLegendTotalList);

  // 根据总legend Tatal 总数排序设置颜色
  R.addIndex(R.forEach)((item, index) => {
    item.color = index < colors.length ? colors[index] : '#20B2AA';
  }, allLegendTotalList);

  // 获取当月legend project总数并排序数据
  const sameMonthLegendTotal = {};
  const topTenDate = {};
  let hasOther = false;
  R.forEachObjIndexed((val, key) => {
    let projectSortItem = []; // 存储当天所有的project
    R.forEach((item) => {
      const { projects } = item;
      R.forEach((_item) => {
        const { projectName, logEventCount } = _item;
        const findlegendObj = R.find((legendObj) => legendObj.projectName === projectName, allLegendTotalList) || {};

        // 当月legend project总数并排序数据
        if (!R.has(projectName, sameMonthLegendTotal)) {
          sameMonthLegendTotal[projectName] = logEventCount || 0;
        } else {
          sameMonthLegendTotal[projectName] += logEventCount;
        }
        // 把前天的project push到一个数组
        projectSortItem.push({ ..._item, color: findlegendObj.color, value: logEventCount });
      }, projects || []);
    }, val || []);

    // 处理当天 10
    projectSortItem = R.sortWith([R.descend(R.prop('logEventCount'))], projectSortItem);
    let tenList = [];
    let otherItem = {};
    let otherTotal = 0;
    if (projectSortItem.length > 10) {
      hasOther = true;
      tenList = R.take(10)(projectSortItem);
      R.forEach((item) => {
        otherTotal += item.logEventCount;
      }, R.slice(10, Infinity)(projectSortItem));
      otherItem = { projectName: 'Other', logEventCount: otherTotal, color: '#20B2AA', value: otherTotal };
    } else {
      tenList = projectSortItem;
    }
    if (!isEmpty(otherItem)) tenList.push(otherItem);
    tenList = R.sortWith([R.descend(R.prop('logEventCount'))], tenList);
    topTenDate[key] = tenList;
  }, filterSameMonth);

  // 获取当月前10 legend
  let sameMonthLegendTotalList = [];
  R.forEachObjIndexed((val, key) => {
    sameMonthLegendTotalList.push({ projectName: key, total: val });
  }, sameMonthLegendTotal);
  sameMonthLegendTotalList = R.sortWith([R.descend(R.prop('total'))], sameMonthLegendTotalList);

  // 获取当月前10 legend 字符串
  let sameMonthTopTenLegend = [];
  sameMonthTopTenLegend = R.take(10)(R.map((item) => item.projectName, sameMonthLegendTotalList || []));
  if (hasOther) {
    sameMonthTopTenLegend.push('Other');
  }

  // 处理为前10 的series
  const topTenSeriesObj = {};
  R.forEach((item) => {
    const timestampObj = {};
    R.forEach((timestamp) => {
      timestampObj[timestamp] = undefined;
    }, xAxisTimestampData);
    topTenSeriesObj[item] = timestampObj;
  }, sameMonthTopTenLegend);

  R.forEachObjIndexed((val, key) => {
    R.forEach((item) => {
      const { projectName, logEventCount = 0, value = 0 } = item;
      let obj = (topTenSeriesObj[projectName] || {})[key] || {};
      obj = {
        ...item,
        logEventCount: logEventCount + (obj?.logEventCount || 0),
        value: value + (obj?.value || 0),
      };
      (topTenSeriesObj[projectName] || {})[key] = obj;
    }, val || []);
  }, topTenDate);

  const topTenSeriesList = [];
  R.forEachObjIndexed((val, key) => {
    topTenSeriesList.push(val);
  }, topTenSeriesObj);

  const series = R.addIndex(R.map)((item, index) => {
    const filterName = R.filter((val) => val, R.values(item));
    const name = filterName[0]?.projectName;
    const color = filterName[0]?.color;
    return {
      type: 'bar',
      data: R.values(item),
      barMaxWidth: 20,
      zlevel: 20,
      stack: 'total',
      name,
      itemStyle: { color },
    };
  }, topTenSeriesList);

  return {
    backgroundColor: 'transparent',
    legend: {
      top: 10,
      type: 'scroll',
      padding: [20, 30],
      data: sameMonthTopTenLegend,
      formatter: (name) => {
        return getProjectTitle(name, projectMap, projectToDisplayNameMap);
      },
    },
    tooltip: {
      backgroundColor: 'var(--component-background)',
      borderColor: 'transparent',
      textStyle: {
        color: 'var(--text-color)',
      },
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      enterable: true,
      appendToBody: true,
      position: (pos, params, dom, rect, size) => {
        const isRight = size.viewSize[0] / 2 < pos[0];
        const boxWidth = size.contentSize[0]; // 弹框的height
        const boxHeight = size.contentSize[1]; // 弹框的height
        const pointX = pos[0] - (isRight ? boxWidth : 0);
        return [pointX, pos[1] - boxHeight - 5];
      },
      formatter: (params, ticket, callback) => {
        const newParams = R.filter((item) => item.data, params);
        const moveItemName = newParams[0]?.name;

        // 获取当日的数据
        let sameDayList = filterTimeStrSameMonth[moveItemName] || [];
        sameDayList = R.sortWith([R.ascend(R.prop('systemName'))], sameDayList);
        const sameDayProjectObj = [];
        R.forEach((item) => {
          const { projects, systemName } = item;
          let projectObj = [];
          R.forEach((_item) => {
            const { logEventCount, projectName } = _item;
            const findlegendObj =
              R.find((legendObj) => legendObj.projectName === projectName, allLegendTotalList) || {};
            projectObj.push({ ..._item, value: logEventCount, color: findlegendObj.color, systemName });
          }, projects || []);
          projectObj = R.sortWith([R.descend(R.prop('value'))], projectObj);
          sameDayProjectObj.push(projectObj);
        }, sameDayList);

        if (moveItemName) {
          return ReactDOMServer.renderToStaticMarkup(
            <div style={{ maxHeight: 200, maxWidth: 450, overflowY: 'auto', padding: 20 }}>
              <div style={{ fontSize: 16, fontWeight: 'bold' }}>{moveItemName}</div>
              {R.addIndex(R.map)((item, index) => {
                return (
                  <>
                    <div
                      style={{ fontSize: 16, fontWeight: 'bold', margin: '10px 0', whiteSpace: 'normal' }}
                      className="hidden-line-with-ellipsis-multiline"
                      key={index}
                    >
                      {item[0]?.systemName}
                    </div>
                    {R.addIndex(R.map)((_item, _index) => {
                      const { color, value, projectName } = _item;
                      return (
                        <div key={`${_index}children`} className="flex-row flex-center-align">
                          <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color, flexShrink: 0 }} />
                          <div style={{ margin: '0 0 0 5px' }} className="hidden-line-with-ellipsis">
                            {getProjectTitle(projectName, projectMap, projectToDisplayNameMap)}
                          </div>
                          <span style={{ marginRight: 8 }}>:</span>
                          <span style={{ fontSize: 14, fontWeight: 'bold', color, flexShrink: 0 }}>{value}</span>
                        </div>
                      );
                    }, item || [])}
                  </>
                );
              }, sameDayProjectObj)}
            </div>,
          );
        }
      },
    },
    grid: {
      left: 40,
      right: '5%',
      top: 80,
      bottom: 10,
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
      axisLine: { show: true },
      splitLine: { show: false },
      splitArea: { show: false },
    },
    series,
  };
};

export default function AllProjectsChart({
  intl,
  credentials,
  customerName,
  startMonth,
  endMonth,
  forceRefreshTime,
  selectMonth,
  location,
  replace,
  userInfo,
  currentTheme,
  projects,
}: Object) {
  const monthNameMap = useRef({
    '01': intl.formatMessage(appFieldsMessages.Jan),
    '02': intl.formatMessage(appFieldsMessages.Feb),
    '03': intl.formatMessage(appFieldsMessages.Mar),
    '04': intl.formatMessage(appFieldsMessages.Apr),
    '05': intl.formatMessage(appFieldsMessages.May),
    '06': intl.formatMessage(appFieldsMessages.Jun),
    '07': intl.formatMessage(appFieldsMessages.Jul),
    '08': intl.formatMessage(appFieldsMessages.Aug),
    '09': intl.formatMessage(appFieldsMessages.Sep),
    10: intl.formatMessage(appFieldsMessages.Oct),
    11: intl.formatMessage(appFieldsMessages.Nov),
    12: intl.formatMessage(appFieldsMessages.Dec),
  });
  const projectTime = useRef(null);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    chartDate: {},
    loading: false,
    option: {},
    monthStrList: [],
    searchProjectName: '',
  });
  const { chartDate, loading, option, monthStrList, searchProjectName } = state;

  useEffect(() => {
    setState({ loading: true });
    const { startTime, endTime } = getSectionTime(startMonth, endMonth);
    fetchGet(getEndpoint('logoverviewstatus'), {
      ...credentials,
      customerName: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : credentials.userName,
      startTime,
      endTime,
    })
      .then(async (data) => {
        const monthStrList = await getMonthStrList(data);
        const option = await getOption(data, selectMonth, searchProjectName, projects);
        setState({ loading: false, option, monthStrList, chartDate: data });
      })
      .catch((e) => {
        setState({ loading: false });
        message.error(e.message || String(e));
      });
  }, [customerName, startMonth, endMonth, forceRefreshTime, currentTheme]);

  const handleMonthValueChange = async (selectMonth) => {
    setState({ loading: true });
    const query = parseLocation(location);
    // force refresh
    replace(buildLocation(location.pathname, {}, { ...query, selectMonth }));
    const option = await getOption(chartDate, selectMonth, searchProjectName, projects);
    setState({ loading: false, option });
  };

  return (
    <>
      <div className="flex-row flex-center-align flex-center-justify overflow-x-auto" style={{ height: 60 }}>
        {R.map((monthStr) => {
          return (
            <div
              key={monthStr}
              style={{
                cursor: 'pointer',
                margin: '0 12px',
                ...(monthStr === selectMonth
                  ? { fontWeight: 'bold', fontSize: 20, borderBottom: '2px solid #ff5142' }
                  : { fontSize: 14 }),
              }}
              onClick={() => handleMonthValueChange(monthStr)}
            >
              {monthNameMap.current[moment.utc(monthStr, Defaults.MonthFormat).format(Defaults.MonthOnlyFormat)]}
            </div>
          );
        }, monthStrList)}
      </div>
      <div className="flex-grow health-panel corner-8 flex-col" style={{ margin: 16 }}>
        <div style={{ paddingTop: 8, marginLeft: 40 }}>
          <span style={{ marginRight: 8 }}>{intl.formatMessage(eventMessages.projectName)}:</span>
          <Input
            allowClear
            size="small"
            style={{ width: 240 }}
            value={searchProjectName}
            onChange={(e) => {
              const searchValue = e.target.value;
              if (projectTime.current) clearTimeout(projectTime.current);
              setState({ searchProjectName: searchValue, loading: true });
              projectTime.current = setTimeout(() => {
                const option = getOption(chartDate, selectMonth, searchValue, projects);
                setState({ option, loading: false });
              }, 600);
            }}
          />
        </div>
        <Spin spinning={loading} wrapperClassName="full-height spin-full-height flex-col flex-min-height flex-grow">
          <AutoSizer>
            {({ height, width }) => <EChart width={width} height={height} option={option} theme={currentTheme} />}
          </AutoSizer>
        </Spin>
      </div>
    </>
  );
}
