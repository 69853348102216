export default {
    white: '#fff',
    black: '#000',
    transparent: 'rgba(1, 1, 1, 0)',

    globalFontFamily: 'monospace',
    globalCursor: 'default',

    indentBlockWidth: '5px',

    braceFontWeight: 'bold',
    braceCursor: 'pointer',

    ellipsisFontSize: '18px',
    ellipsisLineHeight: '10px',
    ellipsisCursor: 'pointer',

    keyMargin: '0px 5px',
    keyLetterSpacing: '0.5px',
    keyFontStyle: 'none',
    keyBorderRadius: '3px',
    keyColonWeight: 'bold',
    keyVerticalAlign: 'top',
    keyOpacity: '0.85',
    keyOpacityHover: '1',

    keyValPaddingTop: '3px',
    keyValPaddingBottom: '3px',
    keyValPaddingRight: '5px',
    keyValBorderLeft: '1px solid',
    keyValBorderHover: '2px solid',
    keyValPaddingHover: '3px 5px 3px 4px',

    pushedContentMarginLeft: '6px',

    variableValuePaddingRight: '6px',

    nullFontSize: '11px',
    nullFontWeight: 'bold',
    nullPadding: '1px 2px',
    nullBorderRadius: '3px',

    nanFontSize: '11px',
    nanFontWeight: 'bold',
    nanPadding: '1px 2px',
    nanBorderRadius: '3px',

    undefinedFontSize: '11px',
    undefinedFontWeight: 'bold',
    undefinedPadding: '1px 2px',
    undefinedBorderRadius: '3px',

    dataTypeFontSize: '11px',
    dataTypeMarginRight: '4px',
    datatypeOpacity: '0.8',

    objectSizeBorderRadius: '3px',
    objectSizeFontStyle: 'italic',
    objectSizeMargin: '0px 6px 0px 0px',

    clipboardCursor: 'pointer',
    clipboardCheckMarginLeft: '-12px',

    metaDataPadding: '0px 0px 0px 10px',

    arrayGroupMetaPadding: '0px 0px 0px 4px',

    iconContainerWidth: '17px',

    tooltipPadding: '4px',

    editInputMinWidth: '130px',
    editInputBorderRadius: '2px',
    editInputPadding: '5px',
    editInputMarginRight: '4px',
    editInputFontFamily: 'monospace',

    iconCursor: 'pointer',
    iconFontSize: '15px',
    iconPaddingRight: '1px',

    dateValueMarginLeft: '2px',

    iconMarginRight: '3px',

    detectedRowPaddingTop: '3px',

    addKeyCoverBackground: 'rgba(255, 255, 255, 0.3)',
    addKeyCoverPosition: 'absolute',
    addKeyCoverPositionPx: '0px',
    addKeyModalWidth: '200px',
    addKeyModalMargin: 'auto',
    addKeyModalPadding: '10px',
    addKeyModalRadius: '3px'
};
