import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { isArray } from 'lodash';
import { Button, Input, Spin } from 'antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DoubleLeftOutlined,
  DownOutlined,
  SettingOutlined,
  UpOutlined,
} from '@ant-design/icons';

import fetchGet from '../../../../src/common/apis/fetchGet';
import getEndpoint from '../../../../src/common/apis/getEndpoint';
import { Defaults } from '../../../../src/common/app';
import { Defaults as DefaultsUtils, buildUrl } from '../../../../src/common/utils';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Popover,
  SortDirection,
  Table,
} from '../../../../src/lib/fui/react';

import StatusRerunModel from './StatusRerunModel';
import StatusDetailsModel from './StatusDetailsModel';

import { eventMessages } from '../../../../src/common/metric/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../src/common/app/messages';
import UploadFileContent from './UploadFileContent';
import BaseUrls from '../../../../src/web/app/BaseUrls';
import { settingsMessages } from '../../../../src/common/settings/messages';

const typeMap = {
  trainingStatusRecord: 'train',
  baselineStatusRecord: 'baseline',
  detectionStatusRecord: 'detection',
};

const replayTypeMap = {
  replayTrainingStatusRecord: 'train',
  replayBaselineStatusRecord: 'baseline',
  replayDetectionStatusRecord: 'detection',
};

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

const reducer = (oldVal, newVal) => ({ ...oldVal, ...newVal });
const initState = {
  dataList: [],
  loading: false,
  allExpand: true,
  sortBy: null,
  sortDirection: null,
  fileNameSearchValue: '',
  showStatusRerun: false,
  showStatusDetails: false,
  activeStatusRerun: null,
  uploadFileContentModal: false,
  activeEvent: null,
};

export default function FileRerunMetricData({
  intl,
  push,
  refresh,
  userInfo,
  fileList,
  projectName,
  credentials,
  projectList,
  viewFileContent,
  refreshFileRerunData,
  setFileRerunLoading,
  handleRefresh,
}: Object) {
  const dataTableNode = useRef(null);
  const localDataList = useRef([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer(reducer, initState);
  const { dataList, loading, allExpand, sortBy, sortDirection, fileNameSearchValue, showStatusRerun } = state;
  const { activeStatusRerun, showStatusDetails, uploadFileContentModal, activeEvent } = state;

  const updateTable = () => {
    cellMeasureCache.clearAll();
    if (dataTableNode.current) {
      dataTableNode.current.forceUpdate();
      forceUpdate();
    }
  };

  const sortData = (eventList, sortBy, sortDirection) => {
    let sortList = eventList || [];
    sortList = R.sortWith([R.descend(R.prop('timestamp'))])(sortList);
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        if (['startTime', 'endTime', 'isFinished', 'keyType'].includes(sortBy)) {
          sortList = R.map(
            (item) => ({ ...item, timelines: R.sortWith([R.descend(R.prop(sortBy))])(item.timelines) }),
            sortList,
          );
        } else {
          sortList = R.sortWith([R.descend(R.prop(sortBy))])(sortList);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (['startTime', 'endTime', 'isFinished', 'keyType'].includes(sortBy)) {
          sortList = R.map(
            (item) => ({ ...item, timelines: R.sortWith([R.ascend(R.prop(sortBy))])(item.timelines) }),
            sortList,
          );
        } else {
          sortList = R.sortWith([R.ascend(R.prop(sortBy))])(sortList);
        }
      }
    }
    return sortList;
  };

  const filterData = (list) => {
    let filterList = list || [];
    if (fileNameSearchValue) {
      filterList = R.filter((file) => R.toLower(file.fileName).indexOf(fileNameSearchValue) !== -1, filterList);
    }
    filterList = sortData(filterList, sortBy, sortDirection);
    return filterList;
  };

  const buildMergeData = ({ rest, projectName, userName, fileHash, fileName, timestamp }) => {
    const restMap = { projectName, userName, fileHash, fileName, timestamp };
    const mergeListMap = {};
    R.forEachObjIndexed((val, key) => {
      const { map } = val || {};
      R.forEach((item) => {
        const { s, e } = item[0];
        const { isFinished } = item[1];
        const mapKey = `${s}-${e}`;
        if (!R.has(mapKey, mergeListMap)) {
          mergeListMap[mapKey] = {
            startTime: s,
            endTime: e,
            status: [{ isFinished, keyType: typeMap[key] || replayTypeMap[key] }],
            ...restMap,
          };
        } else {
          mergeListMap[mapKey].status = [...mergeListMap[mapKey].status, { isFinished, keyType: typeMap[key] }];
        }
      }, map || []);
    }, rest || {});
    const timelines = R.values(mergeListMap);
    return R.sortWith([R.descend(R.prop('startTime'))])(timelines);
  };

  const parseData = (event) => {
    const eventList = R.map((item) => {
      const { key: fileProjectInfo, metricReplayTaskValueStr, fileName: name, suffix } = item || {};
      const { chunkSet, csvHeader } = item || {};
      const { timestamp, projectLevelPartitionKey } = fileProjectInfo || {};
      const { projectName, userName } = projectLevelPartitionKey;
      const { fileHash, replayTrainingStatusRecord, replayBaselineStatusRecord, replayDetectionStatusRecord, ...rest } =
        metricReplayTaskValueStr;
      const fileName = `${name}.${suffix}`;
      const otherParams = { projectName, userName, fileHash, fileName, timestamp };

      const key = `${projectName}-${userName}-${fileHash}`;
      let timelines = buildMergeData({ rest, ...otherParams });
      const replayTrainingTimelines = buildMergeData({ rest: { replayTrainingStatusRecord }, ...otherParams });
      const replayBaselineTimelines = buildMergeData({ rest: { replayBaselineStatusRecord }, ...otherParams });
      const replayDetectionTimelines = buildMergeData({ rest: { replayDetectionStatusRecord }, ...otherParams });
      timelines = R.map(
        (item) => ({
          ...item,
          chunkSet,
          csvHeader,
          replayTrainingTimelines,
          replayBaselineTimelines,
          replayDetectionTimelines,
        }),
        timelines || [],
      );

      return {
        key,
        projectName,
        userName,
        timestamp,
        isExpand: allExpand,
        fileHash,
        fileName,
        isRootEvent: true,
        timelines,
        chunkSet,
        csvHeader,
      };
    }, event || []);
    return eventList;
  };

  const getReplayActiveData = () => {
    let active = null;
    if (showStatusDetails && activeStatusRerun) {
      const { projectName, userName, fileHash, startTime, endTime, keyType } = activeStatusRerun;
      const rootKey = `${projectName}-${userName}-${fileHash}`;
      const childKey = `${startTime}-${endTime}`;
      const findRoot = R.find((item) => item.key === rootKey, localDataList.current || []);
      if (findRoot) {
        const findChild = R.find((item) => `${item.startTime}-${item.endTime}` === childKey, findRoot?.timelines || []);
        if (findChild) active = { ...findChild, keyType };
      }
    }
    return active;
  };

  const getMetricRerunData = (cancel) => {
    if (cancel) return;
    const projectInfo = R.find((project) => project.value === projectName, projectList);
    if (!projectName || !projectInfo) {
      localDataList.current = [];
      setState({ dataList: [] });
      return;
    }
    setFileRerunLoading(true);
    setState({ loading: true });
    fetchGet(getEndpoint('metricanalysismanage'), {
      ...credentials,
      projectName,
      customerName: projectInfo?.customerName,
    })
      .then((data) => {
        const { success, message: msg } = data;
        if ((success || success === undefined) && isArray(data)) {
          const event = R.map(
            (item) => ({
              ...item,
              key: item?.task?.key || {},
              metricReplayTaskValueStr: item?.task?.metricReplayTaskValue || {},
            }),
            data || [],
          );
          let eventList = parseData(event);
          eventList = sortData(eventList, sortBy, sortDirection);
          const filterEventList = filterData(eventList);
          localDataList.current = filterEventList;
          const activeStatusRerun = getReplayActiveData();
          setFileRerunLoading(false);
          setState({ dataList: eventList, loading: false, activeStatusRerun });
          updateTable();
        } else {
          console.error(msg);
          setFileRerunLoading(false);
          setState({ loading: false });
          updateTable();
        }
      })
      .catch((err) => {
        console.error(String(err));
        setFileRerunLoading(false);
        setState({ loading: false });
        updateTable();
      });
  };

  useEffect(() => {
    let cancel = false;
    getMetricRerunData(cancel);
    return () => {
      cancel = true;
    };
  }, [projectName, refreshFileRerunData, refresh]);

  useEffect(() => {
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        if (['startTime', 'endTime', 'isFinished', 'keyType'].includes(sortBy)) {
          const newDataList = R.map(
            (item) => ({ ...item, timelines: R.sortWith([R.descend(R.prop(sortBy))])(item.timelines) }),
            localDataList.current,
          );
          localDataList.current = newDataList;
        } else {
          localDataList.current = R.sortWith([R.descend(R.prop(sortBy))])(localDataList.current);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (['startTime', 'endTime', 'isFinished', 'keyType'].includes(sortBy)) {
          const newDataList = R.map(
            (item) => ({ ...item, timelines: R.sortWith([R.ascend(R.prop(sortBy))])(item.timelines) }),
            localDataList.current,
          );
          localDataList.current = newDataList;
        } else {
          localDataList.current = R.sortWith([R.ascend(R.prop(sortBy))])(localDataList.current);
        }
      }
      updateTable();
    }
  }, [sortBy, sortDirection]);

  const rootEventRender = ({ dataKey, parent, rowIndex, cellData, columnIndex, rowData }) => {
    const { isRootEvent } = rowData;
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Popover title={null} content={cellData} placement="top" mouseEnterDelay={0.3}>
          <div className="hidden-line-with-ellipsis">{isRootEvent ? cellData : ''}</div>
        </Popover>
      </CellMeasurer>
    );
  };

  const contentRender = ({ dataKey, parent, rowIndex, cellData, columnIndex, rowData }) => {
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Popover title={null} content={cellData} placement="top" mouseEnterDelay={0.3}>
          <span className="hidden-line-with-ellipsis">{cellData}</span>
        </Popover>
      </CellMeasurer>
    );
  };

  const timeRender = ({ dataKey, parent, rowIndex, columnIndex, rowData }) => {
    const { isRootEvent, startTime, endTime, timelines } = rowData;
    const content = (
      <div style={{ padding: '8px 0' }}>
        <div className="hidden-line-with-ellipsis">
          {`${moment.utc(startTime).format(Defaults.TimeFormat)} ~ ${moment.utc(endTime).format(Defaults.TimeFormat)}`}
        </div>
      </div>
    );

    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        {isRootEvent && (
          <>
            {(timelines || []).length === 0 ? (
              <Button size="small" onClick={() => setState({ uploadFileContentModal: true, activeEvent: rowData })}>
                {intl.formatMessage(eventMessages.viewData)}
              </Button>
            ) : (
              <div />
            )}
          </>
        )}
        {!isRootEvent && (
          <div>
            <Popover title={null} content={content} placement="top" mouseEnterDelay={0.3}>
              <div className="hidden-line-with-ellipsis" style={{ marginTop: 8 }}>
                {isRootEvent ? '' : content}
              </div>
            </Popover>
            <Button size="small" onClick={() => setState({ uploadFileContentModal: true, activeEvent: rowData })}>
              {intl.formatMessage(eventMessages.viewData)}
            </Button>
          </div>
        )}
      </CellMeasurer>
    );
  };

  const handleStatusRerun = (rowData, statusContent) => {
    const activeEvent = { ...rowData, ...statusContent };
    setState({ showStatusRerun: true, activeStatusRerun: activeEvent });
  };

  const handleStatusDetails = (rowData, type) => {
    const activeEvent = { ...rowData, keyType: type };
    setState({ showStatusDetails: true, activeStatusRerun: activeEvent });
  };

  const statusRenderer = ({ rowData, columnIndex, dataKey, parent, rowIndex }) => {
    const { isRootEvent, status, timelines, fileHash } = rowData;
    const titleMap = {
      train: eventMessages.trainingStatus,
      baseline: eventMessages.baselineStatus,
      detection: eventMessages.detectionStatus,
    };
    return (
      <>
        {isRootEvent && (timelines || []).length === 0 && (
          <div style={{ color: DefaultsUtils.ColorStatusFont.info }}>
            {intl.formatMessage(eventMessages.inProgress)}
          </div>
        )}
        {!isRootEvent && (
          <CellMeasurer
            cache={cellMeasureCache}
            columnIndex={columnIndex}
            key={dataKey}
            parent={parent}
            rowIndex={rowIndex}
          >
            <div style={{ padding: '4px 0' }}>
              {R.map((item) => {
                const { isFinished, keyType } = item || {};
                let color = DefaultsUtils.ColorStatusFont.success;
                let content = intl.formatMessage(eventMessages.success);
                if (!isFinished) {
                  color = DefaultsUtils.ColorStatusFont.info;
                  content = intl.formatMessage(eventMessages.inProgress);
                }
                return (
                  <div
                    key={`${fileHash}-${keyType}`}
                    className="flex-row flex-center-align"
                    style={{ padding: '4px 0' }}
                  >
                    <div style={{ width: 104, flexShrink: 0 }}>{intl.formatMessage(titleMap[keyType])}:</div>
                    <div
                      className="hidden-line-with-ellipsis clickable"
                      style={{ color, marginRight: 8, width: 65, flexShrink: 0 }}
                    >
                      {content}
                    </div>
                    <Button size="small" onClick={() => handleStatusRerun(rowData, item)}>
                      {intl.formatMessage(eventMessages.rerun)}
                    </Button>
                    <Button
                      size="small"
                      style={{ marginLeft: 4 }}
                      onClick={() => handleStatusDetails(rowData, keyType)}
                    >
                      {intl.formatMessage(eventMessages.fieldDetails)}
                    </Button>
                  </div>
                );
              }, status || [])}
            </div>
          </CellMeasurer>
        )}
      </>
    );
  };

  const expandHeader = () => {
    return (
      <div
        className="full-width text-right clickable"
        style={{ marginRight: 5 }}
        onClick={() => {
          const newDataList = R.map((item) => {
            return { ...item, isExpand: !allExpand };
          }, localDataList.current);
          localDataList.current = newDataList;
          updateTable();
          setState({ allExpand: !allExpand });
        }}
      >
        <DoubleLeftOutlined rotate={allExpand ? 90 : -90} />
      </div>
    );
  };

  const handleJumpToLinechart = (rowData, type) => {
    if (type === 'viewFileData') {
      const { fileHash } = rowData;
      const findItem = R.find((item) => item.hash === fileHash, fileList);
      if (findItem) {
        viewFileContent(findItem);
      }
    } else {
      const activeEvent = { ...rowData, keyType: 'lineChart' };
      setState({ showStatusRerun: true, activeStatusRerun: activeEvent });
    }
  };

  const expandRender = ({ dataKey, parent, rowIndex, rowData }) => {
    const { isRootEvent, isExpand, timelines } = rowData;
    return (
      <>
        {isRootEvent && (timelines || []).length > 0 && (
          <div
            className="full-width flex-row flex-center-align clickable"
            style={{ height: 40, justifyContent: 'right', paddingRight: 5 }}
            onClick={() => {
              rowData.isExpand = !isExpand;
              updateTable();
            }}
          >
            {isExpand ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
          </div>
        )}
        {!isRootEvent && <div />}
      </>
    );
  };

  const expandData = (list) => {
    let newDataList = [];
    R.forEach((item) => {
      if (item.isExpand) {
        newDataList = [...newDataList, item, ...item.timelines];
      } else {
        newDataList = [...newDataList, item];
      }
    }, list);
    return newDataList;
  };

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const handleSearchFileName = (fileNameSearchValue) => {
    if (fileNameSearchValue) {
      localDataList.current = filterData(dataList);
    } else {
      const sortDataList = sortData(dataList, sortBy, sortDirection);
      localDataList.current = sortDataList;
    }
    updateTable();
  };

  const anomalousRender = ({ rowData }) => {
    const { isRootEvent } = rowData;

    if (isRootEvent) {
      return <div />;
    }

    return (
      <Button size="small" onClick={() => handleJumpToLinechart(rowData)}>
        {intl.formatMessage(appFieldsMessages.viewResults)}
      </Button>
    );
  };

  const configRender = ({ rowData }) => {
    const { isRootEvent, userName } = rowData;
    let { projectName } = rowData;
    const projectInfo = R.find((project) => project.value === projectName, projectList);
    if (userName !== userInfo.userName) {
      projectName = `${projectName}@${userName}`;
    }

    if (isRootEvent) {
      return <div />;
    }

    return (
      <Button
        size="small"
        icon={<SettingOutlined />}
        onClick={() => {
          window.open(
            buildUrl(
              BaseUrls.SettingsProject,
              { projectName },
              {
                systemSearch: projectInfo?.systemId || 'emptySystem',
                projectOwner: userName || userInfo.userName,
                customerName: userName,
              },
            ),
            '_blank',
          );
        }}
      >
        {intl.formatMessage(settingsMessages.setting)}
      </Button>
    );
  };

  const newDataList = expandData(localDataList.current);

  return (
    <>
      <Container className="full-height">
        <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-width">
          <div className="flex-col full-width">
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <div className="flex-row flex-center-align">
                <div style={{ width: 70 }}>{intl.formatMessage(eventMessages.fileName)}:</div>
                <Input.Search
                  size="small"
                  style={{ width: 200, marginRight: 16 }}
                  allowClear
                  value={fileNameSearchValue}
                  onSearch={handleSearchFileName}
                  onChange={(event) => setState({ fileNameSearchValue: event.target.value })}
                />
              </div>
              <div className="flex-grow" />
              <Button size="small" type="primary" onClick={() => handleRefresh()}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </div>
            <div className="flex-grow">
              <AutoSizer>
                {({ width, height }) => (
                  <Table
                    className="with-border"
                    width={width}
                    height={height}
                    deferredMeasurementCache={cellMeasureCache}
                    headerHeight={40}
                    rowClassName={({ index }) => {
                      let name = '';
                      const data = newDataList[index];
                      if (data?.isRootEvent) name = 'instance-row';
                      return name;
                    }}
                    rowHeight={cellMeasureCache.rowHeight}
                    rowCount={newDataList.length}
                    rowGetter={({ index }) => newDataList[index]}
                    headerClassName="flex-row flex-center-align"
                    sort={sort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    ref={(c) => {
                      dataTableNode.current = c;
                    }}
                  >
                    <Column
                      width={140}
                      label={intl.formatMessage(eventMessages.projectName)}
                      dataKey="projectName"
                      cellRenderer={rootEventRender}
                      headerRenderer={headerRenderer}
                      disableSort
                    />
                    <Column
                      width={130}
                      flexGrow={1}
                      label={intl.formatMessage(eventMessages.fileName)}
                      dataKey="fileName"
                      cellRenderer={contentRender}
                      headerRenderer={headerRenderer}
                    />
                    <Column
                      width={250}
                      minWidth={120}
                      label={intl.formatMessage(eventMessages.data)}
                      dataKey="startTime"
                      cellRenderer={timeRender}
                      headerRenderer={headerRenderer}
                      disableSort
                    />
                    <Column
                      width={140}
                      label={intl.formatMessage(appFieldsMessages.anomalous)}
                      dataKey="anomalous"
                      disableSort
                      cellRenderer={anomalousRender}
                    />
                    <Column
                      width={300}
                      label={intl.formatMessage(eventMessages.fieldStatus)}
                      dataKey="status"
                      disableSort
                      flexShrink={0}
                      cellRenderer={statusRenderer}
                    />
                    <Column
                      width={124}
                      label={intl.formatMessage(eventMessages.anomalyDetection)}
                      dataKey="config"
                      className="text-center"
                      headerClassName="flex-center-justify"
                      disableSort
                      cellRenderer={configRender}
                    />
                    <Column
                      width={40}
                      label={null}
                      dataKey="isExpand"
                      flexShrink={0}
                      cellRenderer={expandRender}
                      headerRenderer={expandHeader}
                      disableSort
                    />
                  </Table>
                )}
              </AutoSizer>
            </div>
          </div>
        </Spin>
      </Container>
      {showStatusRerun && (
        <StatusRerunModel
          intl={intl}
          userInfo={userInfo}
          credentials={credentials}
          activeEvent={activeStatusRerun}
          onClose={(flag) => {
            if (flag) {
              getMetricRerunData();
            }
            setState({ showStatusRerun: false, activeStatusRerun: null });
          }}
        />
      )}
      {showStatusDetails && (
        <StatusDetailsModel
          intl={intl}
          userInfo={userInfo}
          credentials={credentials}
          activeEvent={activeStatusRerun}
          onClose={(flag) => {
            if (flag) {
              getMetricRerunData();
            } else {
              setState({ showStatusDetails: false, activeStatusRerun: null });
            }
          }}
        />
      )}
      {uploadFileContentModal && (
        <UploadFileContent
          credentials={credentials}
          activeEvent={activeEvent}
          onClose={() => setState({ uploadFileContentModal: false, activeEvent: null })}
        />
      )}
    </>
  );
}
