import React from 'react';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const colors = [
  '#A1D546',
  '#77BA14',
  '#5F9F0E',
  '#FFDA66',
  '#FFCB3F',
  '#FFB200',
  '#DB9200',
  '#B77400',
  '#935900',
  '#FFC4AA',
  '#FF9D7F',
  '#FF7760',
  '#FF392B',
  '#DB1F22',
  '#B71525',
  '#930D26',
  '#7A0826',
];

const options = {
  colors,
  deterministic: true,
  fontFamily: 'impact',
  fontSizes: [16, 50],
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 2,
  rotations: 0,
  rotationAngles: [0, 0],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 100,
};

const WordCloud = (props: Object) => {
  const { words, size = [400, 200] } = props;
  return <ReactWordcloud words={words} options={options} size={size} />;
};
export default WordCloud;
