/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { get } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getEventAlert = (credentials: Credentials, params: Object) => {
  const promiseQuery = [
    fetchGet(getEndpoint('patternemailsetting'), {
      ...credentials,
      ...params,
    })
      .then((d) => {
        const { data } = d || {};
        let emailAlert = JSON.parse(data || '[]');
        emailAlert = R.map((ea) => {
          const { patternId, projectName, userName } = ea.key || {};
          return { ...ea, patternId, projectName, userName };
        }, emailAlert);
        return emailAlert;
      })
      .catch((err) => {
        console.log('[IF_API', err);
        return [];
      }),
  ];

  return Promise.all(promiseQuery).then((results) => {
    const d0 = results[0];
    return {
      emailAlert: d0,
    };
  });
};

export default getEventAlert;
