import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { Skeleton, Spin, message } from 'antd';

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Modal,
  Popover,
  Table,
} from '../../../../src/lib/fui/react';
import fetchGet from '../../../../src/common/apis/fetchGet';
import getEndpoint from '../../../../src/common/apis/getEndpoint';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

const LOADING_LAST_ROW = {
  timestamp: 'xxxxxxx',
  isLastRow: true,
};

export default function UploadFileContent(props: Object) {
  const { activeEvent, onClose, credentials } = props || {};
  const { fileName, fileHash, csvHeader, chunkSet, userName } = activeEvent || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,

    headerList: [],
    chunkList: [],
    fileDataList: [],

    chunkIndex: 0,
  });
  const dataTableNode = useRef(null);
  const { loading, headerList, chunkList, fileDataList, chunkIndex } = state;

  const getFileDataList = (chunkIndex) => {
    setState({ loading: true });

    const fileHeader = R.split(',', csvHeader || '');
    const chunks = R.sort((a, b) => a - b, chunkSet || []);

    fetchGet(getEndpoint('uploadmetricfile'), {
      ...credentials,
      customerName: userName,
      chunkNumber: chunks[chunkIndex],
      hash: fileHash,
    })
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          const parseCSV = R.pipe(R.split('\n'), R.map(R.split(',')))(R.trim(data || ''));
          const mapRowToObject = R.map(R.zipObj(fileHeader))(parseCSV);
          const newParseCSV = [...fileDataList, ...mapRowToObject];
          setState({
            loading: false,
            headerList: fileHeader,
            chunkList: chunks,
            fileDataList: newParseCSV,
            chunkIndex,
          });

          setTimeout(() => {
            cellMeasureCache.clearAll();
            if (dataTableNode.current) {
              dataTableNode.current.forceUpdate();
            }
          });
        } else {
          message.error(msg);
          setState({ loading: false });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        setState({ loading: false });
      });
  };

  useEffect(() => {
    getFileDataList(chunkIndex);
  }, [fileHash]);

  const loadingRendererWrapper = (rowData, cellData, columnIndex) => {
    const { isLastRow } = rowData;

    if (loading) {
      return (
        <div style={{ lineHeight: '40px', alignItems: 'center' }}>
          <Skeleton.Button size="small" active />
        </div>
      );
    } else if (isLastRow) {
      if (columnIndex === 0) {
        return (
          <a
            style={{ lineHeight: '40px', minWidth: 140 }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              getFileDataList(chunkIndex + 1);
            }}
          >
            Load more: +1 Chunk
          </a>
        );
      } else {
        return <div />;
      }
    }
    return <div className="hidden-line-with-ellipsis">{cellData}</div>;
  };

  let newFileDataList = fileDataList;
  if (!R.isNil(chunkList[chunkIndex + 1])) newFileDataList = [...newFileDataList, LOADING_LAST_ROW];

  return (
    <Modal
      title={fileName}
      width={1000}
      bodyStyle={{ height: 560 }}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      footer={null}
    >
      <div className="full-width full-height" style={{ overflow: 'overlay hidden' }}>
        <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-width">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width <= headerList.length * 140 ? headerList.length * 140 : width}
                height={height}
                deferredMeasurementCache={cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={cellMeasureCache.rowHeight}
                rowCount={newFileDataList.length}
                rowGetter={({ index }) => newFileDataList[index]}
                headerClassName="flex-row flex-center-align"
                headerStyle={{ overflow: 'hidden' }}
                ref={(c) => {
                  dataTableNode.current = c;
                }}
              >
                {R.map((item) => {
                  return (
                    <Column
                      width={140}
                      flexGrow={1}
                      key={item}
                      label={item}
                      dataKey={item}
                      cellRenderer={({ dataKey, parent, rowIndex, cellData, columnIndex, rowData }) => {
                        return (
                          <CellMeasurer
                            cache={cellMeasureCache}
                            columnIndex={columnIndex}
                            key={dataKey}
                            parent={parent}
                            rowIndex={rowIndex}
                          >
                            {loadingRendererWrapper(rowData, cellData, columnIndex)}
                          </CellMeasurer>
                        );
                      }}
                      headerRenderer={({ label }) => {
                        return (
                          <Popover title={null} content={label} placement="top" mouseEnterDelay={0.3}>
                            <span className="hidden-line-with-ellipsis">{label}</span>
                          </Popover>
                        );
                      }}
                    />
                  );
                }, headerList)}
              </Table>
            )}
          </AutoSizer>
        </Spin>
      </div>
    </Modal>
  );
}
