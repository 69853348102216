import React from 'react';
import * as R from 'ramda';
// import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Form, Select, Input, message, Spin, Alert } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
// import { Defaults } from '../../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';

type Props = {
  // eslint-disable-next-line
  projectNameList: Array<String>,
  // eslint-disable-next-line
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class AddActionModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { projectNameList } = props;
    this.projectNameList = projectNameList || [];
    this.state = {
      isLoading: false,
      isSubmitting: false,
      errMsg: undefined,

      projectName: '',
      name: '',
      command: '',
      script: '',
      parameter: '',
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  @autobind
  handleSaveAction() {
    const { intl, credentials, onClose } = this.props;
    const { projectName, name, command, script, parameter } = this.state;

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('projectactions'), {
      ...credentials,
      projectName,
      actionsList: JSON.stringify([{ name, command, script, parameter }]),
    })
      .then((d) => {
        const { success, message: errMsg } = d;
        if (!success) {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmitting: false, errMsg });
        } else {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmitting: false });
          onClose(true);
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false, errMsg: String(err) });
      });
  }

  render() {
    const { intl, onClose } = this.props;
    const { isLoading, isSubmitting, errMsg } = this.state;
    const { projectName, name, command, script, parameter } = this.state;

    const disabled = !projectName || !name || !script;
    return (
      <Modal
        title={intl.formatMessage(eventActionMessages.addAction)}
        width={650}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSaveAction}
        okButtonProps={{ disabled, loading: isSubmitting }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Form.Item
              label={intl.formatMessage(eventMessages.projectName)}
              validateStatus={disabled ? 'error' : 'success'}
              help={!projectName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                filterOption
                value={projectName}
                onChange={(projectName) => this.setState({ projectName })}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map((item) => {
                  return (
                    <Select.Option key={item.projectNameReal} title={item.projectNameReal}>
                      {item.projectDisplayName}
                    </Select.Option>
                  );
                }, this.projectNameList || [])}
              </Select>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(eventActionMessages.actionName)}
              validateStatus={disabled ? 'error' : 'success'}
              help={!name ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={name} onChange={(e) => this.setState({ name: e.target.value })} />
            </Form.Item>
            <Form.Item label={intl.formatMessage(appFieldsMessages.command)}>
              <Input value={command} onChange={(e) => this.setState({ command: e.target.value })} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.script)}
              validateStatus={disabled ? 'error' : 'success'}
              help={!script ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={script} onChange={(e) => this.setState({ script: e.target.value })} />
            </Form.Item>
            <Form.Item label={intl.formatMessage(appFieldsMessages.parameter)}>
              <Input value={parameter} onChange={(e) => this.setState({ parameter: e.target.value })} />
            </Form.Item>

            {errMsg && (
              <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
                <Alert message={errMsg} type="error" showIcon />
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const AddActionModal = injectIntl(AddActionModalCore);
export default connect(
  (state) => {
    const { credentials, userInfo } = state.auth;
    return { credentials, userInfo };
  },
  {
    updateLastActionInfo,
  },
)(AddActionModal);
