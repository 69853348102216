/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import { get, isNumber, isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { CaretDownOutlined, CaretUpOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import { Spin, Alert, Button, Popover } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { Container, AutoSizer, List, CellMeasurer, CellMeasurerCache } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Defaults, LogParser, LogRenderers, CellRenderers } from '../../../common/utils';

import { appFieldsMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import { eventMessages } from '../../../common/metric/messages';

import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  instanceName: String,
  // eslint-disable-next-line
  clusterInfo: Object,
  // eslint-disable-next-line
  clustersSampleMsgMap: Object,
  // eslint-disable-next-line
  allPatternNidMap: Object,
  // eslint-disable-next-line
  clusterActiveTimeChange: Number,
  // eslint-disable-next-line
  startTimestamp: Number,
  // eslint-disable-next-line
  endTimestamp: Number,
  // eslint-disable-next-line
  featureToNidMapInfo: Object,
  // eslint-disable-next-line
  featureInfo: Object,
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  currentTheme: String,
  anomalyType: String,
  activePatternId: String,
  setLoading: Function,
  // eslint-disable-next-line
  hostAndPodIdList: Array,
  instanceDisplayNameMap: Object,
};

class LogEntriesImportantList extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.state = {
      isLoading: false,

      eventList: [],

      activeIncident: null,
      selectInstance: undefined,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
      contextKeywordFilter: '',
      showContextModal: false,

      summarySettings: [],
      allExpand: false,
    };
    this.highlightOptions = [
      { label: intl.formatMessage(logMessages.none), value: 'none' },
      { label: intl.formatMessage(logMessages.common), value: 'common' },
      { label: intl.formatMessage(logMessages.difference), value: 'difference' },
    ];
    this.interval = 10 * 60 * 1000;
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.anomalyType !== nextProps.anomalyType ||
      this.props.activePatternId !== nextProps.activePatternId ||
      this.props.clusterActiveTimeChange !== nextProps.clusterActiveTimeChange
    ) {
      if (this.state.allExpand || this.state.sortBy || this.state.sortDirection) {
        this.setState({ allExpand: false, sortBy: null, sortDirection: null }, () => {
          this.reloadData(nextProps, true);
        });
      } else {
        this.reloadData(nextProps, true);
      }
    }
  }

  @autobind
  async reloadData(props, force) {
    const {
      credentials,
      projects,
      projectName,
      instanceName,
      anomalyType,
      activePatternId,
      startTime,
      endTime,
      hostAndPodIdList,
    } = props;
    const { allExpand, sortBy, sortDirection } = this.state;
    if (projectName && instanceName) {
      this.setState({ isLoading: true });

      await fetchGet(getEndpoint('logsummarysettings'), {
        ...credentials,
        projectName,
      })
        .then((summarySettings) => {
          this.setState({ summarySettings });
        })
        .catch((err) => console.log(err));
      const query = {
        ...credentials,
        projectName,
        instanceName,
        startTime,
        endTime,
        logEventType: anomalyType.toLowerCase(),
        patternId: activePatternId === 'all' ? undefined : activePatternId,
        isFetchAll: activePatternId === 'all',
      };
      const requests = [fetchGet(getEndpoint('fetchimportantlog'), query)];

      props.updateLastActionInfo();
      await Promise.all(requests)
        .then((results) => {
          const project = R.find(
            (project) => project.projectName === projectName || project.projectShortName === projectName,
            projects,
          );
          const projectLinkInfo = get(project, 'projectLinkInfo', []);

          // get log entries
          let eventList = R.filter((e) => e.timestamp || e.startTime, get(results[0], ['eventArray'], []));

          eventList = R.sortWith([R.ascend(R.prop('timestamp'))])(eventList);
          eventList = R.map((event) => {
            const { patternId, eventType, count, frequency, rawData, timestamp, startTime } = event;
            const typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', eventType).split('&'));
            const typeAndColor = LogParser.CalculateLogType({ eventType });

            let frequencyStr = '';
            if (frequency) {
              const percent = `${Math.abs(frequency).toFixed(2)}%`;
              const cmp = frequency > 0 ? 'higher' : 'lower';
              frequencyStr = `${isNumber(count) ? `Count: ${count}. ` : ''}Frequency is ${percent} ${cmp} than normal.`;
            }

            let rawDataJson;
            try {
              rawDataJson = JSON.parse(rawData);
              if (!isObject(rawDataJson)) rawDataJson = undefined;
            } catch (error) {
              // console.debug(error);
            }

            const timesTampNum = timestamp || startTime;
            const findHostAndPodInfo =
              R.find((item) => item[1]?.s <= timesTampNum && timesTampNum <= item[1]?.e, hostAndPodIdList || []) || [];

            return {
              ...event,
              timestamp: timesTampNum,
              isExpand: allExpand,
              // eslint-disable-next-line camelcase
              __content_expand_state: allExpand,
              rawDataJson,
              typeList,
              typeAndColor,
              patternId,
              frequencyStr,
              projectLinkInfo,
              hostId: findHostAndPodInfo[0]?.h,
              podId: findHostAndPodInfo[0]?.p,
            };
          }, eventList);
          eventList = this.sortData(eventList, sortBy, sortDirection);

          if (this.listNode) this.listNode.scrollToRow(0);
          this.setState(
            {
              isLoading: false,
              errorMessage: null,
              eventList,
            },
            () => {
              this.cellMeasureCache.clearAll();
              if (this.listNode) this.listNode.forceUpdateGrid();
              if (this.listNode) this.listNode.scrollToRow(0);
            },
          );
          this.props.setLoading(false);
        })
        .catch((err) => {
          this.setState(
            {
              isLoading: false,
              errorMessage: err,
              eventList: [],
            },
            () => {
              this.cellMeasureCache.clearAll();
              if (this.listNode) this.listNode.forceUpdateGrid();
            },
          );
          this.props.setLoading(false);
        });
    }
  }

  @autobind
  renderHostAndPod(rowData, cellKey) {
    const { instanceDisplayNameMap, projects, projectName } = this.props;
    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects,
    );
    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, rowData[cellKey], {
      pn: project?.projectShortName,
      owner: project?.owner,
    });
    return (
      <Popover content={<div>{instanceStr}</div>}>
        <div className="hidden-line-with-ellipsis clickable">{instanceStr}</div>
      </Popover>
    );
  }

  @autobind
  renderListItem(events, hasHostIdAndPodId) {
    return ({ key, index: rowIndex, style, parent }) => {
      const rowData = events[rowIndex];
      if (!rowData) return null;

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style, minHeight: 40 }}>
            <div className="row-column" style={{ width: 110 }}>
              {this.renderTime(rowData)}
            </div>
            <div className="row-column" style={{ width: 80 }}>
              {rowData?.patternId}
            </div>
            {hasHostIdAndPodId && (
              <>
                <div className="row-column" style={{ width: 140 }}>
                  {this.renderHostAndPod(rowData, 'hostId')}
                </div>
                <div className="row-column" style={{ width: 140 }}>
                  {this.renderHostAndPod(rowData, 'podId')}
                </div>
              </>
            )}
            <div className="row-column" style={{ width: 100, flex: 1 }}>
              {this.renderLogEntry(rowIndex, rowData)}
            </div>
            <div className="row-column" style={{ width: 40 }}>
              {this.renderType(rowData)}
            </div>
            <div className="row-column" style={{ width: 70 }}>
              {this.renderControl(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderTime(rowData) {
    const { intl } = this.props;
    const { timestamp, startTime, endTime: endTimestamp, timestampMap } = rowData;
    const startTimestamp = startTime || timestamp;
    const startDayTamp = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
    const startTimeStrDay = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
    const startTimeStrTime = moment.utc(startTimestamp).format(Defaults.ShortTimeOnlyFormat);

    const endDayTamp = moment.utc(endTimestamp).format(Defaults.ShortDayFormat);
    const endTimeStr = moment.utc(endTimestamp).format(Defaults.ShortTimeOnlyFormat);
    const isSameDay = startDayTamp === endDayTamp;

    let timestampList = [];
    R.forEachObjIndexed((count, time) => {
      timestampList.push({ time, count });
    }, timestampMap || {});
    timestampList = R.sortWith([R.ascend(R.prop('time'))])(timestampList);

    const content = (
      <div className="flex-col" style={{ width: 110 - 12 }}>
        <div className="flex-row flex-center-align">
          <span className="flex-grow" style={{ textAlign: 'right' }}>
            {startTimeStrDay}
          </span>
          <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{startTimeStrTime}</span>
        </div>

        {isSameDay && (
          <div className="flex-row flex-center-align">
            <span className="flex-grow" style={{ textAlign: 'right' }} />
            <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStr}</span>
          </div>
        )}

        {!isSameDay && (
          <div className="flex-row flex-center-align">
            <span className="flex-grow" style={{ textAlign: 'right' }}>
              {endDayTamp}
            </span>
            <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStr}</span>
          </div>
        )}
      </div>
    );

    if (timestampList.length > 0) {
      return (
        <Popover
          placement="right"
          content={
            <div style={{ maxHeight: 300, overflowY: 'auto', paddingRight: 10 }}>
              {R.map((item) => {
                return (
                  <div key={item.time} className="flex-row flex-center-align">
                    <div style={{ marginRight: 16, width: 130, flexShrink: 0 }}>
                      <span className="light-label bold" style={{ marginRight: 4 }}>
                        {intl.formatMessage(appFieldsMessages.time)}:
                      </span>
                      <span>{moment.utc(Number(item?.time || 0)).format(Defaults.ShortDateTimeFormat)}</span>
                    </div>
                    <div>
                      <span className="light-label bold" style={{ marginRight: 4 }}>
                        {intl.formatMessage(appFieldsMessages.count)}:
                      </span>
                      <span>{item.count}</span>
                    </div>
                  </div>
                );
              }, timestampList || [])}
            </div>
          }
        >
          {startTime && endTimestamp && <>{content}</>}
          {(!startTime || !endTimestamp) && <div>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</div>}
        </Popover>
      );
    }

    return (
      <>
        {startTime && endTimestamp && (
          <Popover
            placement="right"
            content={
              <div>
                <span className="light-label bold" style={{ marginRight: 4 }}>
                  {intl.formatMessage(appFieldsMessages.startTime)}:
                </span>
                <span>
                  {startTimeStrDay} {startTimeStrTime}
                </span>
                <span className="light-label bold" style={{ marginRight: 4, marginLeft: 8 }}>
                  {intl.formatMessage(appFieldsMessages.endTime)}:
                </span>
                <span>
                  {endDayTamp} {endTimeStr}
                </span>
              </div>
            }
          >
            {content}
          </Popover>
        )}
        {(!startTime || !endTimestamp) && <div>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</div>}
      </>
    );
  }

  @autobind
  renderType(rowData) {
    const { intl } = this.props;
    const { eventType } = rowData;

    return (
      <div className="flex-row flex-wrap full-width">
        {CellRenderers.logShortTypeRenderer({ intl, type: eventType })}
      </div>
    );
  }

  @autobind
  onToggleCollapse() {
    this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
  }

  @autobind
  renderLogEntry(rowIndex, rowData) {
    const { intl, currentTheme } = this.props;
    const { rawData, rawDataJson, frequencyStr, isSmallCluster, anomalyWords, outlierValue, isExpand } = rowData;
    const { summarySettings } = this.state;
    if (rawDataJson) {
      return (
        <LogRenderers.RenderLogContent
          intl={intl}
          rawData={rawData}
          rawDataJson={rawDataJson}
          frequencyStr={frequencyStr}
          isSmallCluster={isSmallCluster}
          anomalyWordList={anomalyWords}
          outlierValue={outlierValue}
          owner={rowData}
          summarySettings={summarySettings}
          onToggleCollapse={this.onToggleCollapse}
          currentTheme={currentTheme}
        />
      );
    }

    return (
      <LogRenderers.ExpandLogContent
        intl={intl}
        rawData={rawData}
        rawDataJson={rawDataJson}
        frequencyStr={frequencyStr}
        isSmallCluster={isSmallCluster}
        anomalyWordList={anomalyWords}
        outlierValue={outlierValue}
        isExpand={isExpand}
        onExpand={this.handleExpandRow(rowIndex)}
        rows={1}
      />
    );
  }

  @autobind
  handleExpandRow(rowIndex) {
    return (isExpand) => {
      const { eventList } = this.state;
      this.setState(
        {
          eventList: update(eventList, {
            [rowIndex]: { $set: { ...(eventList[rowIndex] || {}), isExpand } },
          }),
        },
        () => {
          this.cellMeasureCache.clear(rowIndex);
          if (this.listNode) this.listNode.forceUpdateGrid();
        },
      );
    };
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;
    const { eventType } = rowData;

    return (
      <>
        {eventType !== 'normal' && (
          <Button size="small" onClick={() => this.handleLogContextOneMinClick(rowData)}>
            {intl.formatMessage(eventMessages.context)}
          </Button>
        )}
      </>
    );
  }

  @autobind
  handleLogContextOneMinClick(rowData) {
    const { projectName, instanceName } = this.props || {};
    const { timestamp, startTime } = rowData;
    const selectStartTimestamp = timestamp ? timestamp - 9 * 60 * 1000 : startTime - 9 * 60 * 1000;
    const selectEndTimestamp = timestamp + 60 * 1000 || startTime + 60 * 1000;
    this.setState(
      {
        activeIncident: rowData,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp,
        selectEndTimestamp,
        contextKeywordFilter: '',
      },
      () => {
        this.setState({
          showContextModal: true,
        });
      },
    );
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('timestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);
    return sortList;
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const { eventList } = this.state;
          const sortList = this.sortData(eventList, name, sortDir);
          this.cellMeasureCache.clearAll();
          this.setState({ eventList: sortList });
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  isRowLoaded({ index }) {
    const { eventList } = this.state;
    return eventList && eventList.length > index;
  }

  @autobind
  handleAllExpand() {
    const { allExpand, eventList } = this.state;
    this.setState(
      {
        allExpand: !allExpand,
        // eslint-disable-next-line camelcase
        eventList: R.map((item) => ({ ...item, isExpand: !allExpand, __content_expand_state: !allExpand }), eventList),
      },
      () => {
        this.cellMeasureCache.clearAll();
        if (this.listNode) this.listNode.forceUpdateGrid();
      },
    );
  }

  render() {
    const { intl } = this.props;
    const { isLoading, errorMessage } = this.state;
    const { eventList, sortBy, sortDirection, allExpand } = this.state;

    const hasHostIdAndPodId = R.find((item) => item.hostId || item.podId, eventList || []);
    return (
      <Container className="full-width full-height flex-col">
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          {errorMessage && (
            <div className="full-width full-height" style={{ padding: 16 }}>
              <Alert type="error" message={String(errorMessage)} showIcon />
            </div>
          )}

          {!errorMessage && (
            <div className="full-width full-height flex-col">
              <div className="flex-grow flex-min-height">
                <AutoSizer
                  onResize={() => {
                    this.cellMeasureCache.clearAll();
                    if (this.listNode) this.listNode.forceUpdateGrid();
                    this.forceUpdate();
                  }}
                >
                  {({ width, height }) => (
                    <div className="event-list">
                      <div
                        className="event-list-header"
                        style={{
                          height: this.listHeaderHeight,
                          width,
                          paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                        }}
                      >
                        <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('timestamp')}>
                          <span>{intl.formatMessage(appFieldsMessages.time)}</span>
                          {this.sortIcon(sortBy, sortDirection, 'timestamp')}
                        </div>
                        <div className="header-column" style={{ width: 80 }} onClick={this.headerClick('patternId')}>
                          <span>{intl.formatMessage(appFieldsMessages.pattern)}</span>
                          {this.sortIcon(sortBy, sortDirection, 'patternId')}
                        </div>
                        {hasHostIdAndPodId && (
                          <>
                            <div className="header-column" style={{ width: 140 }} onClick={this.headerClick('hostId')}>
                              <span>Host ID</span>
                              {this.sortIcon(sortBy, sortDirection, 'hostId')}
                            </div>
                            <div className="header-column" style={{ width: 140 }} onClick={this.headerClick('podId')}>
                              <span>Pod ID</span>
                              {this.sortIcon(sortBy, sortDirection, 'podId')}
                            </div>
                          </>
                        )}
                        <div className="header-column" style={{ width: 100, flex: 1 }}>
                          {intl.formatMessage(eventMessages.shortDescription)}
                          <span onClick={this.handleAllExpand}>
                            <DoubleLeftOutlined rotate={allExpand ? 90 : -90} style={{ marginLeft: 4 }} />
                          </span>
                        </div>
                        <div className="header-column" style={{ width: 40 }} onClick={this.headerClick('eventType')}>
                          <span>{intl.formatMessage(appFieldsMessages.type)}</span>
                          {this.sortIcon(sortBy, sortDirection, 'eventType')}
                        </div>
                        <div className="header-column" style={{ width: 70 }} />
                      </div>
                      <List
                        className="event-list-grid corner-0-0-8-8"
                        ref={(listNode) => {
                          this.listNode = listNode;
                        }}
                        width={width}
                        height={height - this.listHeaderHeight}
                        rowCount={eventList.length + 1}
                        overscanRowCount={4}
                        deferredMeasurementCache={this.cellMeasureCache}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowRenderer={this.renderListItem(eventList, hasHostIdAndPodId)}
                        onScrollbarPresenceChange={({ horizontal, vertical }) => {
                          if (vertical) {
                            this.listNodeHeaderScrollbar = true;
                            this.cellMeasureCache.clearAll();
                            if (this.listNode) this.listNode.forceUpdateGrid();
                          } else {
                            this.listNodeHeaderScrollbar = false;
                          }
                          this.forceUpdate();
                        }}
                      />
                    </div>
                  )}
                </AutoSizer>
              </div>
            </div>
          )}
        </Spin>

        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeIncident}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            keywordFilter={this.state.contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
            useTimeRange
          />
        )}
      </Container>
    );
  }
}

const ClusterLogEntries = injectIntl(LogEntriesImportantList);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects, currentTheme } = state.app;
    const { credentials } = state.auth;

    return {
      location,
      loadStatus,
      projects,
      credentials,
      currentTheme,
    };
  },
  { updateLastActionInfo },
)(ClusterLogEntries);
