import React from 'react';
import * as R from 'ramda';
import { get, round } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';

import { State } from '../../../common/types';
import { Container, Table, Column, CellMeasurer, CellMeasurerCache, Tooltip, Scrollbars } from '../../../lib/fui/react';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/log/actions';
import { CellRenderers } from '../../../common/utils';
import { CollapsibleLogContent } from '../../share';

type Props = {
  intl: Object,
  loadStatus: Object,
  incident: Object,
  onClose: Function,
  width: Number,
  height: Number,

  logEntryCausalList: Array<Object>,
};

class LineChartCausalPrediction extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { width } = props;

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.contentWidth = width - 200;
    this.events = [];
    this.preprocessData(props);
  }

  componentDidMount() {
    this.cellMeasureCache.clearAll();

    if (this.dataTable) {
      this.dataTable.forceUpdate();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.logEntryCausalList !== this.props.logEntryCausalList) {
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdate();
      }
    }
  }

  @autobind
  preprocessData(props) {
    const { logEntryCausalList, appNameMapping } = props;
    this.events = R.map((l) => {
      const { timestamp, srcDetails, targetDetails, ...rest } = l;
      const srcInstanceId = get(srcDetails, 'instanceId');
      const targetInstanceId = get(targetDetails, 'instanceId');
      const srcInstanceName = get(appNameMapping, srcInstanceId, srcInstanceId);
      const targetInstanceName = get(appNameMapping, targetInstanceId, targetInstanceId);
      return {
        ...rest,
        timestamp: Number(timestamp),
        srcDetails: { ...srcDetails, instanceName: srcInstanceName },
        targetDetails: { ...targetDetails, instanceName: targetInstanceName },
      };
    }, logEntryCausalList || []);
  }

  @autobind
  getType(type) {
    type = type || 'Metric';
    if (type.indexOf('LogRareEvent') === 0) {
      type = 'LogRareEvent';
    } else if (type.indexOf('LogHotEvent') === 0) {
      type = 'LogHotEvent';
    } else if (type.indexOf('LogColdEvent') === 0) {
      type = 'LogColdEvent';
    }
    return type;
  }

  @autobind
  getLogTypeLegend(type) {
    type = this.getType(type);
    return (
      <Tooltip
        title={<div style={{ maxWidth: 200, wordBreak: 'break-all' }}>{type}</div>}
        placement="top"
        style={{ display: 'inline-block', width: 40 }}
      >
        <div className={`legend-item ${(type || '').toLowerCase()}`}>
          <i className="square icon" />
        </div>
      </Tooltip>
    );
  }

  @autobind
  getMetricLegend(metric) {
    const m = (metric || '').toLowerCase();
    let label = m ? m[0].toUpperCase() : 'M';
    if (m.indexOf('network') === 0) {
      label = 'NET';
    } else if (m.indexOf('disk') === 0) {
      label = 'DISK';
    } else if (m.indexOf('cpu') === 0) {
      label = 'CPU';
    } else if (m.indexOf('mem') === 0) {
      label = 'MEM';
    } else if (m.indexOf('new') === 0) {
      label = 'NEW';
    }

    return (
      <Tooltip
        title={<div style={{ maxWidth: 200, wordBreak: 'break-all' }}>{metric}</div>}
        placement="top"
        style={{ display: 'inline-block' }}
      >
        <span
          className="anomaly-event-legend"
          style={{ border: '1px solid #767676', background: '#767676', color: 'white' }}
        >
          {label}
        </span>
      </Tooltip>
    );
  }

  @autobind
  handleContentChanged() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind
  eventContentAutoHeightRenderer(props) {
    const { dataKey, parent, rowIndex } = props;
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        {this.eventContentRenderer(props)}
      </CellMeasurer>
    );
  }

  @autobind
  eventContentScrollingRenderer(props) {
    return (
      <Scrollbars style={{ height: '100%', width: this.contentWidth }}>{this.eventContentRenderer(props)}</Scrollbars>
    );
  }

  @autobind
  eventContentRenderer(props) {
    const { style, cellData } = props;
    const { type, content, instanceName, avgValue } = cellData;
    const dtype = this.getType(type);

    // Table can only support one cellmeasuer, so use source as the main one.
    const logOptions = {
      width: this.contentWidth - 40, // Space for icon
      highlightWord: null,
      inLine: true,
      ignoreFields: ['projectName'],
      onChanged: this.handleContentChanged,
    };

    if (dtype === 'Metric') {
      return (
        <div className="flex-row flex-center-align" style={{ ...style, width: this.contentWidth }}>
          <div style={{ width: 40 }}>{this.getMetricLegend(content)}</div>
          <div className="flex-grew white-pre" style={{ padding: '4px 0' }}>
            <div className="flex-col">
              <div className="flex-row" style={{ padding: '4px 0' }}>
                <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 16 }}>Instance:</div>
                <div style={{ color: 'green' }}>{instanceName}</div>
              </div>
              <div className="flex-row" style={{ padding: '4px 0' }}>
                <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 16 }}>Metric:</div>
                <div style={{ color: 'blue' }}>{content}</div>
              </div>
              <div className="flex-row" style={{ padding: '4px 0' }}>
                <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 16 }}>Value:</div>
                <div>{round(avgValue, 2)}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ ...style, width: this.contentWidth }}>
        <div className="white-pre" style={{ padding: '4px 0px' }}>
          <div className="flex-col">
            <div className="flex-row" style={{ paddingLeft: 40 }}>
              <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 16 }}>Instance:</div>
              <div style={{ color: 'green' }}>{instanceName}</div>
            </div>
            <Container className="flex-row" style={{ marginBottom: 4, alignItems: 'center' }}>
              {this.getLogTypeLegend(type)}
              <CollapsibleLogContent ownerObject={cellData} message={content} {...logOptions} />
            </Container>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { intl, loadStatus, width, height } = this.props;
    const events = this.events;

    return (
      <Table
        className="with-border"
        width={width}
        height={height}
        deferredMeasurementCache={this.cellMeasureCache}
        headerHeight={40}
        rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
        rowHeight={this.cellMeasureCache.rowHeight}
        rowCount={events.length}
        rowGetter={({ index }) => events[index]}
        ref={(c) => {
          this.dataTable = c;
        }}
      >
        <Column
          width={this.contentWidth}
          label={'Predicted events'}
          flexGrow={1}
          dataKey="targetDetails"
          cellRenderer={this.eventContentAutoHeightRenderer}
        />
        <Column width={100} label="Probability" dataKey="probability" cellRenderer={CellRenderers.probability} />
        <Column
          width={100}
          label="Delay"
          dataKey="delay"
          cellRenderer={({ cellData }) => CellRenderers.humanizeDuration({ period: cellData, intl })}
        />
      </Table>
    );
  }
}

export default LineChartCausalPrediction;
