import React from 'react';
import * as R from 'ramda';
import { Progress } from 'antd';
import { DeleteOutlined, FolderViewOutlined } from '@ant-design/icons';
import { eventMessages } from '../../../../src/common/metric/messages';
import { Popover } from '../../../../src/lib/fui/react';

const textColor = {
  wait: '#d48806', // 等待
  analysis: '#1677ff', // 文件解析中
  progress: '#1677ff', // 进行中
  success: '#52c41a', // 成功
  error: 'var(--red)', // 失败
};

export default function OtherFileListDisplay({ fileList, resetFileState, viewFileContent, removeRile, intl }: Object) {
  const stateTextMap = {
    wait: intl.formatMessage(eventMessages.inQueue), // 等待
    analysis: intl.formatMessage(eventMessages.fileParsing), // 文件解析中
    progress: intl.formatMessage(eventMessages.uploading), // 进行中
    success: intl.formatMessage(eventMessages.success), // 成功
    error: intl.formatMessage(eventMessages.fail), // 失败
  };
  return (
    <>
      <div style={{ padding: '8px 0', fontWeight: 'bold' }}>List of uploaded files being processed:</div>
      <div
        style={{
          width: '100%',
          border: '1px solid var(--virtualized-table-border-color)',
          overflowY: 'auto',
          cursor: 'pointer',
          maxHeight: 200,
          marginBottom: 8,
          flexShrink: 0,
        }}
      >
        {R.addIndex(R.map)((item, index) => {
          const { state, percentage, name, uid } = item;
          return (
            <div key={uid || index}>
              {!R.includes(state, ['error', 'success']) && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px',
                      color: textColor[state],
                    }}
                  >
                    <div style={{ flex: 1, fontSize: 14, minWidth: 0 }}>
                      <div className="flex-row">
                        <div className="flex-grow hidden-line-with-ellipsis bold">{name}</div>
                        <Popover
                          title={null}
                          content={intl.formatMessage(eventMessages.viewFileContent)}
                          mouseEnterDelay={0.3}
                          placement="left"
                        >
                          <div style={{ marginRight: 8, fontSize: 14 }} onClick={() => viewFileContent(item)}>
                            <FolderViewOutlined style={{ color: textColor[state] }} />
                          </div>
                        </Popover>
                        <Popover
                          title={null}
                          content={intl.formatMessage(eventMessages.deleteFile)}
                          mouseEnterDelay={0.3}
                          placement="right"
                        >
                          <div style={{ fontSize: 14 }} onClick={() => removeRile(uid)}>
                            <DeleteOutlined style={{ color: textColor[state] }} />
                          </div>
                        </Popover>
                      </div>
                      <div style={{ fontSize: 12 }}>
                        <span style={{ marginRight: 12 }}>{stateTextMap[state]}</span>
                      </div>
                    </div>
                  </div>
                  {state === 'progress' ? (
                    <Progress
                      strokeLinecap="butt"
                      percent={percentage}
                      strokeColor={textColor.success}
                      showInfo={false}
                      style={{
                        display: 'block',
                        lineHeight: 1,
                        height: 12,
                        marginBottom: 0,
                      }}
                    />
                  ) : (
                    <div style={{ borderBottom: '1px solid var(--virtualized-table-border-color)' }} />
                  )}
                </>
              )}
            </div>
          );
        }, fileList)}
      </div>
    </>
  );
}
