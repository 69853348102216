import React from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Col, Form, Input, List, message, Button, Select, Spin, Typography, Space, Row } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { AutoSizer, Table, Column, Modal, SortDirection } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Regex } from '../../../common/utils';

import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { eventActionMessages } from '../../../common/metric/messages';

type Props = {
  intl: Object,
  incident: Object,
  credentials: Object,
  onClose: Function,
  title: String,
};

class PatternConfigModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.state = {
      loading: true,

      actions: [],
      actionValue: '',
      email: '',
      emailList: [],
      savedEmailList: [],
      sortBy: null,
      sortDirection: null,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
    let { savedEmailList } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        savedEmailList = R.sortWith([R.ascend(R.prop(sortBy))])(savedEmailList);
        if (sortDirection === SortDirection.DESC) {
          savedEmailList = R.sortWith([R.descend(R.prop(sortBy))])(savedEmailList);
        }
        this.setState({ savedEmailList });
      }
    }
  }

  @autobind
  reloadData(props) {
    const { incident } = props;
    const { incidentRule, ruleType } = incident;
    const { rulePrimaryKey } = incidentRule;
    const { uniqueKey, rulePartitionKey } = rulePrimaryKey;
    const { projectName, userName, instanceName, patternId } = rulePartitionKey;

    fetchGet(getEndpoint('IFActionConfigForKBServlet', 2), {
      ruleType: ruleType === 'INSTANCE' ? 'InstanceLevelRuleType' : 'ComponentLevelRuleType',
      UserName: userName,
      projectName,
      instanceName,
      patternId,
      uniqueKey,
    })
      .then((res) => {
        const { success, actions, ruleActionConfigs } = res;
        if (success || success === undefined) {
          const emailRecipients = ruleActionConfigs[0]?.ifActionConfig?.emailRecipientsMap || {};
          const { newActions, bindEmails } = this.parseData({
            rawActions: actions,
            rawEmail: emailRecipients,
          });
          this.setState({ actions: newActions, savedEmailList: bindEmails });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        console.log(String(e));
      });
  }

  @autobind
  parseData({ rawActions = [], rawEmail = [] }) {
    const { actions } = this.state;
    const newActions = R.map((item) => {
      const {
        actionName,
        key: { actionId },
      } = item;
      return { label: actionName, value: actionId };
    }, rawActions);

    let emailArray = R.map((item) => {
      const [action, email] = item;
      const { label: actionName } = R.find((n) => n.value === action, R.concat(newActions, actions)) || {
        label: null,
      };
      return { action, email, actionName };
    }, R.toPairs(rawEmail));
    emailArray = R.filter((n) => n.actionName, emailArray);
    const bindEmails = R.reduce(
      (pre, cur) => {
        const group = R.addIndex(R.map)((item, index) => {
          return { action: cur.action, actionName: cur.actionName, email: item };
        }, cur.email);
        return [...pre, ...group];
      },
      [],
      emailArray,
    );
    return {
      newActions,
      bindEmails,
    };
  }

  @autobind
  onSavedAction() {
    const { credentials, incident } = this.props;
    const { incidentRule, ruleType } = incident;
    const { rulePrimaryKey } = incidentRule;
    const { uniqueKey, rulePartitionKey } = rulePrimaryKey;
    const { projectName, userName, instanceName, patternId } = rulePartitionKey;
    const { savedEmailList } = this.state;

    const groupByAction = R.groupBy((item) => item.action, savedEmailList);

    const emailRecipients = R.mapObjIndexed((value) => {
      return R.map((item) => item.email, value);
    }, groupByAction);

    const actionIds = R.keys(emailRecipients);

    const requestData = {
      ...credentials,
      ruleType: ruleType === 'INSTANCE' ? 'InstanceLevelRuleType' : 'ComponentLevelRuleType',
      UserName: userName,
      projectName,
      instanceName,
      patternId,
      uniqueKey,
      actionIds: JSON.stringify(actionIds),
      emailRecipients: JSON.stringify(emailRecipients),
    };
    this.setState({ loading: true });
    fetchPost(getEndpoint(`IFActionConfigForKBServlet`, 2), requestData)
      .then((res) => {
        message.success('Action updated');
        this.formRef.current.resetFields();
        this.setState({ loading: false, actionValue: '', email: '', emailList: [] });
      })
      .catch((e) => {
        message.error(e.meesage);
        this.setState({ loading: false });
      });
  }

  @autobind
  async handleAddEmail() {
    await this.formRef.current.validateFields();
    const { emailList, email } = this.state;
    if (emailList.includes(email)) {
      message.info('email already exists');
      return;
    }
    this.setState({
      emailList: [...emailList, email],
    });
  }

  @autobind
  handleRemoveEmail(index) {
    const { emailList } = this.state;
    this.setState({
      emailList: R.remove(index, 1, emailList),
    });
  }

  @autobind
  hanldeSaveAction() {
    const { savedEmailList, emailList, actionValue } = this.state;
    const { bindEmails } = this.parseData({ rawEmail: { [actionValue]: emailList } });

    this.setState(
      {
        savedEmailList: R.uniqBy((x) => x.email, R.concat(savedEmailList, bindEmails)),
      },
      () => {
        this.onSavedAction();
      },
    );
  }

  @autobind
  handleActionDelete(rowData, index) {
    const { savedEmailList } = this.state;
    this.setState(
      {
        savedEmailList: R.remove(index, 1, savedEmailList),
      },
      () => {
        this.onDeleteAction(rowData);
      },
    );
  }

  @autobind
  onDeleteAction(rowData) {
    const { credentials, incident } = this.props;
    const { incidentRule, ruleType } = incident;
    const { rulePrimaryKey } = incidentRule;
    const { uniqueKey, rulePartitionKey } = rulePrimaryKey;
    const { projectName, userName, instanceName, patternId } = rulePartitionKey;
    const { action, email } = rowData;

    const requestData = {
      ...credentials,
      ruleType: ruleType === 'INSTANCE' ? 'InstanceLevelRuleType' : 'ComponentLevelRuleType',
      UserName: userName,
      projectName,
      instanceName,
      patternId,
      uniqueKey,
      action,
      email,
    };

    this.setState({ loading: true });
    fetchDelete(getEndpoint(`IFActionConfigForKBServlet`, 2), requestData)
      .then((res) => {
        message.success('Action updated');
        this.setState({ loading: false });
      })
      .catch((e) => {
        message.error(e.meesage);
        this.setState({ loading: false });
      });
  }

  @autobind
  sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  render() {
    const { onClose, title, intl } = this.props;
    const { loading, actions, actionValue, email, emailList, savedEmailList, sortBy, sortDirection } = this.state;

    const isEmailFormat = Regex.email.test(email);
    const hasError = !actionValue || R.isEmpty(emailList);
    return (
      <Modal
        title={title}
        width={850}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        style={{ height: 700 }}
        footer={
          <Space>
            <Button onClick={this.hanldeSaveAction} disabled={hasError} type="primary">
              Save Action
            </Button>
          </Space>
        }
      >
        <Spin wrapperClassName="full-width full-height spin-full-height overflow-y-hidden" spinning={loading}>
          <h3>{intl.formatMessage(eventActionMessages.savedAction)}:</h3>
          <div style={{ width: '100%', height: 220 }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  ref={(c) => {
                    this.dataTable = c;
                  }}
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowHeight={40}
                  rowCount={savedEmailList.length}
                  rowGetter={({ index }) => savedEmailList[index]}
                  rowClassName={({ index }) => {
                    let className = 'clickable';
                    className += index >= 0 && index % 2 === 1 ? ' odd-row' : '';
                    return className;
                  }}
                  sort={this.sortTable}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  <Column
                    width={100}
                    flexGrow={1}
                    label={intl.formatMessage(eventActionMessages.action)}
                    dataKey="actionName"
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={100}
                    flexGrow={1}
                    label={intl.formatMessage(appFieldsMessages.email)}
                    dataKey="email"
                    headerRenderer={this.headerRenderer}
                    disableSort
                  />
                  <Column
                    width={80}
                    label={null}
                    dataKey="operation"
                    cellRenderer={({ rowData, rowIndex }) => (
                      <Button
                        size="small"
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={() => this.handleActionDelete(rowData, rowIndex)}
                      />
                    )}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
          <Form ref={this.formRef} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }} style={{ marginTop: 20 }}>
            <Form.Item
              label="Action"
              rules={[{ required: true, message: intl.formatMessage(appFieldsMessages.inputRequired) }]}
              name="Action"
            >
              <Select
                allowClear
                showSearch
                size="small"
                filterOption
                placeholder=""
                maxTagCount={1}
                options={actions}
                onChange={(value) => this.setState({ actionValue: value })}
                value={actionValue}
              />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage(appFieldsMessages.email)}
              rules={[{ required: true, pattern: Regex.email, message: 'Email is incorrect' }]}
              name="email"
            >
              <Row align="middle" gutter={10}>
                <Col flex={1}>
                  <Input
                    placeholder=""
                    allowClear
                    size="small"
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={this.handleAddEmail}
                    disabled={!isEmailFormat}
                    size="small"
                    icon={<PlusCircleOutlined size={14} />}
                  >
                    {intl.formatMessage(appButtonsMessages.add)}
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="　" colon={false}>
              <List
                bordered
                size="small"
                dataSource={emailList}
                renderItem={(item, index) => (
                  <List.Item className="flex-row flex-space-between">
                    <Typography.Text ellipsis>{item}</Typography.Text>
                    <Button size="small" onClick={() => this.handleRemoveEmail(index)}>
                      {intl.formatMessage(appButtonsMessages.remove)}
                    </Button>
                  </List.Item>
                )}
                style={{ width: '100%', height: '200px', border: '1px solid #d9d9d9', overflowY: 'auto' }}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const PatternConfigModal = injectIntl(PatternConfigModalCore);
export default connect(
  (state) => {
    const { credentials } = state.auth;
    return { credentials };
  },
  { updateLastActionInfo },
)(PatternConfigModal);
