/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Button, Alert, Input, Select, DatePicker, Popover, Checkbox, Divider, Card } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  credentials: Object,
  componentState: Object,
};

class PagerDutySettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;
    this.state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      apiToken: '',
      fields: [],
      services: [],
      selectServices: [],
      timestampField: null,
      instanceField: null,
      historicalDates: [null, null],
      projectModelFlag: true,
      processPauseFlag: false,

      ...(componentState || {}),
    };
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { credentials } = this.props;
    const { apiToken } = this.state;
    const projectCreationType = 'PageDuty';
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType,
      apiToken,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          let fields = get(data, 'fields', []);
          let services = get(data, 'allServices', []);

          fields = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), fields);
          services = R.map((item) => ({ value: item.id, label: item.name }), services);
          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
              fields,
              services,
            },
            () => {
              this.props.saveProjectInfo('PagerDuty', {}, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('PagerDuty', {}, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: err.message || String(err),
          },
          () => {
            this.props.saveProjectInfo('PagerDuty', {}, this.state);
          },
        );
      });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      apiToken,
      selectServices,
      timestampField,
      instanceField,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
    } = this.state;
    this.props.createProject(
      'PagerDuty',
      {
        apiToken,
        operation: 'register',
        services: JSON.stringify(selectServices),
        timestampField,
        instanceField,
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
        projectModelFlag,
        processPauseFlag,
      },
      this.state,
    );
  }

  render() {
    const { intl, hasError, isLoading } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,
      apiToken,
      fields,
      services,
      selectServices,
      timestampField,
      instanceField,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
    } = this.state;
    const servicesIds = R.map((item) => item.value, services);
    const hasVerifyError = !apiToken;
    const hasErrorRegister = hasVerifyError || !timestampField || !instanceField || hasError;

    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.PagerDutyIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item
              label="Api Token"
              validateStatus={!apiToken ? 'error' : 'success'}
              help={!apiToken ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password value={apiToken} onChange={(e) => this.setState({ apiToken: e.target.value })} />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>
        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            <Form.Item label="InsightFinder Analysis Services in PagerDuty" required>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                allowClear
                autoClearSearchValue={false}
                maxTagCount={1}
                showSearch
                value={selectServices}
                onChange={(selectServices) => {
                  this.setState({ selectServices });
                }}
                dropdownRender={(menu) => {
                  const selectAll = R.difference(servicesIds, selectServices).length === 0;
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selectAll}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              this.setState({ selectServices: servicesIds });
                            } else {
                              this.setState({ selectServices: [] });
                            }
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
              >
                {R.map((item) => {
                  return (
                    <Select.Option className="hide-icon" key={item.value}>
                      <Checkbox
                        checked={selectServices.includes(item.value)}
                        onChange={(e) => null}
                        style={{ marginRight: 8 }}
                      />
                      {item.label}
                    </Select.Option>
                  );
                }, services)}
              </Select>
            </Form.Item>
            <Form.Item label="InsightFinder Analysis Timestamp in PagerDuty" required>
              <Select
                value={timestampField}
                onChange={(timestampField) => {
                  this.setState({ timestampField });
                }}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {R.map((field) => {
                  return <Select.Option key={field}>{field}</Select.Option>;
                }, fields)}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <Popover
                  title={null}
                  content={
                    <div style={{ width: 320 }}>
                      This instance in InsightFinder represents the ticket grouping granularity for pattern learning.
                    </div>
                  }
                  placement="right"
                >
                  <span>
                    InsightFinder Analysis Instance in PagerDuty
                    <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
                  </span>
                </Popover>
              }
              required
            >
              <Select
                value={instanceField}
                onChange={(instanceField) => {
                  this.setState({ instanceField });
                }}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {R.map((field) => {
                  return <Select.Option key={field}>{field}</Select.Option>;
                }, fields)}
              </Select>
            </Form.Item>

            <Form.Item label="Historical Date Range">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={historicalDates}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(historicalDates) => this.setState({ historicalDates })}
              />
              <div className="flex-row" style={{ marginTop: 16 }}>
                <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                  Enable holistic model
                </div>
                <Checkbox
                  checked={projectModelFlag}
                  onChange={(e) => {
                    this.setState({ projectModelFlag: e.target.checked });
                  }}
                />
              </div>
              <div className="flex-row" style={{ marginTop: 16 }}>
                <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                  Enable initial processing pause
                </div>
                <Checkbox
                  checked={processPauseFlag}
                  onChange={(e) => {
                    this.setState({ processPauseFlag: e.target.checked });
                  }}
                />
              </div>
            </Form.Item>
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasErrorRegister}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const PagerDutySetting = injectIntl(PagerDutySettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(PagerDutySetting);
