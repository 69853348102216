/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import { get, isNumber } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { BaseUrls } from '../../app/Constants';
import { CollapsibleLogContent } from '../../share';
import { State } from '../../../common/types';
import { Defaults } from '../../../common/app';
import {
  EventRenderers,
  parseLocation,
  CellRenderers,
  anomalySeverityRendererItem,
  buildUrl,
} from '../../../common/utils';
import { Container, List, CellMeasurerCache, CellMeasurer, Dropdown, Tooltip } from '../../../lib/fui/react';
import { createLoadAction } from '../../../common/app/actions';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import EventTimeLineContent from './EventTimeLineContent';

// Model
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import TimeSelectModal from './TimeSelectModal';
import CausalPredictionModal from './CausalPredictionModal';
import SyscallModal from './SyscallModal';
// Control
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import EventEmailAlertsModal from './EventEmailAlertsModal';
import EventActionModal from './EventActionModal';
import EventPublishModal from './EventPublishModal';
import IgnoreModal from './IgnoreModal';
import ReportJiraModal from './ReportJiraModal';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  projects: Array<Object>,
  width: Number,
  height: Number,
  // eslint-disable-next-line
  createLoadAction: Function,
  isAdmin: Boolean,
  isReadUser: Boolean,

  refresh: Number,
  project: Object,
  instanceGroup: String,
  startTime: Number,
  endTime: Number,
  highlightWord: String,
  filterEventList: Array<Object>,

  scrollingToRow: Number,
  appNameMapping: Object,
  metricUnitMap: Object,
  maxDuration: Number,
};

class EventListTableCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = 32;
    this.expandKey = '__event_expand_key';
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 58,
    });

    this.filterEventList = [];
    this.state = {
      // sort
      sortBy: null,
      sortDirection: 'ASC',

      scrollingToRow: null,

      // active item
      selectedItemId: null,
      activeIncident: null,

      // details
      showLogContextModal: false,
      showTimeSelectModal: false,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
      logContextTime: 60 * 1000,

      showSyscallModal: false,
      showCausalPredictionModal: false,

      // control
      showTakeLogActionModal: false,
      actionName: null,
      showEmailAlertsModal: false,
      showEventActionModal: false,
      showPublishModal: false,
      showIgnoreModal: false,
      showReportJiraModal: false,
    };
  }

  componentDidMount() {
    this.filterEventList = get(this.props, ['filterEventList'], []);
    this.sortData(this.props, this.state);
    this.cellMeasureCache.clearAll();
    if (this.listNode) {
      this.listNode.forceUpdateGrid();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filterEventList !== this.props.filterEventList || nextProps.refresh !== this.props.refresh) {
      this.filterEventList = get(nextProps, ['filterEventList'], []);
      this.sortData(nextProps, this.state);
      this.cellMeasureCache.clearAll();
      if (this.listNode) {
        this.listNode.forceUpdateGrid();
      }
    } else if (nextProps.scrollingToRow !== this.props.scrollingToRow) {
      this.cellMeasureCache.clearAll();
      if (this.listNode) {
        this.listNode.forceUpdateGrid();
        this.setState({ scrollingToRow: nextProps.scrollingToRow }, () => {
          this.listNode.scrollToRow(nextProps.scrollingToRow);
        });
      }
    }
  }

  @autobind
  sortData(props, state) {
    const { sortBy, sortDirection } = state;
    let eventList = this.filterEventList;
    // sort by
    if (sortBy && sortDirection) {
      const sortFuc = sortDirection === 'DESC' ? R.descend(R.prop(sortBy)) : R.ascend(R.prop(sortBy));
      eventList = R.sortWith([sortFuc])(eventList);
    }
    this.filterEventList = eventList;
  }

  @autobind
  renderRow({ key, index: rowIndex, style, parent }) {
    const rowData = this.filterEventList[rowIndex];
    if (!rowData) return null;

    const { isReadUser, project } = this.props;
    const isLog = R.has('isLog', rowData) ? rowData.isLog : get(project, 'isLog', false);

    const isExpand = get(rowData, this.expandKey, false);
    const rowProps = ({ dataKey, ...rest }) => {
      return { rowData, parent, rowIndex, dataKey, cellData: get(rowData, dataKey), ...rest };
    };

    let expandContent = null;
    if (isExpand) {
      if (isLog) {
        expandContent = this.renderLogDetails({ style, event: rowData });
      } else {
        expandContent = this.renderMetricDetails({ style, event: rowData });
      }
    }

    const content = (
      <div style={style}>
        <div
          className={`event-list-row${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          style={{ borderColor: isExpand ? 'transparent' : 'rgba(0, 0, 0, 0.12)', cursor: 'pointer' }}
          onClick={this.handleExpand({ rowData })}
        >
          <div className="row-column" style={{ width: 40 }}>
            <i
              className={`icon angle ${isExpand ? 'down' : 'right'}`}
              style={{ cursor: 'pointer' }}
              onClick={this.handleExpand({ rowData }, true)}
            />
          </div>
          {/* <div className="row-column" style={{ width: 60 }}> */}
          {/* {this.idContent({ ...rowProps({ dataKey: 'id' }) })} */}
          {/* </div> */}
          <div className="row-column" style={{ width: 60 }}>
            {this.patternTypeRenderer(rowData)}
          </div>
          <div className="row-column" style={{ width: 100, flex: 1 }}>
            {this.patternNameRenderer(rowData, isLog)}
          </div>
          <div className="row-column" style={{ width: 200, flex: 1 }}>
            {this.patternCauseInstanceRenderer(rowData, isLog)}
          </div>
          <div className="row-column" style={{ width: 160, flex: 1 }}>
            {this.patternCauseRenderer(rowData, isLog)}
          </div>
          <div className="row-column" style={{ width: 110 }}>
            {this.ratioMaxRenderer(rowData)}
          </div>
          <div className="row-column" style={{ width: 140 }}>
            {this.ratioRenderer(rowData)}
          </div>
          <div className="row-column" style={{ width: 110 }}>
            {this.startTimeRenderer({ ...rowProps({ dataKey: 'startTimestamp' }) })}
          </div>
          {/* <div className="row-column" style={{ width: 110 }}>
            {CellRenderers.anomalyEventDurationRenderer({ ...rowProps({ dataKey: 'duration' }) })}
          </div> */}
          {/* <div className="row-column" style={{ width: 140 }}>
            {this.statusRenderer({ ...rowProps({ dataKey: 'ignoreFlag' }) })}
          </div> */}
          <div className="row-column" style={{ width: 130 }} onClick={this.stopEventBubble}>
            {this.detailsRenderer({ ...rowProps({ dataKey: 'id' }) })}
          </div>
          {!isReadUser && (
            <div className="row-column" style={{ width: 130 }} onClick={this.stopEventBubble}>
              {this.controlRenderer({ ...rowProps({ dataKey: 'id' }) })}
            </div>
          )}
        </div>
        {expandContent}
      </div>
    );

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        {content}
      </CellMeasurer>
    );
  }

  @autobind
  stopEventBubble(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  @autobind
  renderLogDetails({ event }) {
    const { width, project, metricUnitMap, appNameMapping, intl, startTime, endTime, highlightWord } = this.props;
    const rawData = get(event, ['rootCauseJson', 'rawData']) || get(event, ['rawData']);
    const timeLineEvents = get(event, ['timeLineEvents'], []);
    const { count, isPrediction, predictionSourceInfo } = event;
    const incidentPredictionFlag = get(predictionSourceInfo, 'incidentPredictionFlag', null);
    let { percentageStr } = event;
    const logOptions = {
      width: width - 80,
      highlightWord,
      ignoreFields: ['projectName'],
      onChanged: this.handleContentChanged,
    };
    percentageStr = percentageStr || '';
    const isHigh = percentageStr.indexOf('higher') >= 0;
    const percent = percentageStr.substr(0, percentageStr.indexOf('%') + 1);

    return (
      <div className="event-list-row detail">
        <div className="event-list-detail">
          <div className="flex-col log-event-group" style={{ fontSize: 12 }}>
            <span className="detail-header">{`${intl.formatMessage(eventMessages.timeline)}:`}</span>
            <div className="flex-grow">
              <EventTimeLineContent
                width="100%"
                height={90}
                project={project}
                scoreFilter="low"
                statusFilter="all"
                logTypeFilter="all"
                specialEvents
                timeLineEvents={timeLineEvents}
                appNameMapping={appNameMapping}
                onItemSelected={this.handleTimelineItemSelected(event)}
                showNumberTooltip
                startTime={startTime}
                endTime={endTime}
                showHasPredictionSourceInfo
              />
            </div>

            {incidentPredictionFlag && (
              <div className="flex-row">
                <span className="detail-header" style={{ width: 130 }}>{`${intl.formatMessage(
                  DashboardMessages.followOnIncident,
                )}:`}</span>
                <span>True</span>
              </div>
            )}
            {isPrediction && Boolean(predictionSourceInfo) && (
              <span className="detail-header">{`${intl.formatMessage(eventMessages.likelyRootCauses)}:`}</span>
            )}
            {isPrediction &&
              Boolean(predictionSourceInfo) &&
              EventRenderers.RenderLogPredictionCauseDetails({
                intl,
                index: 1,
                metricUnitMap,
                predictionSourceInfo,
              })}

            <span className="detail-header">{`${intl.formatMessage(eventMessages.eventSummary)}:`}</span>
            {percentageStr && (
              <div>
                {intl.formatMessage(isHigh ? eventMessages.frequencyHigh : eventMessages.frequencyLow, {
                  percent,
                  count,
                })}
              </div>
            )}
            <CollapsibleLogContent ownerObject={event} message={rawData} {...logOptions} />
          </div>
        </div>
      </div>
    );
  }

  @autobind
  renderMetricDetails({ event }) {
    const { intl, project, metricUnitMap, appNameMapping, startTime, endTime } = this.props;
    const rootCauseDetailsArr = get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    const timeLineEvents = get(event, ['timeLineEvents'], []);
    const { isPrediction, predictionSourceInfo, fromStorage } = event;
    const incidentPredictionFlag = get(predictionSourceInfo, 'incidentPredictionFlag', null);
    const topestRank = 100;
    const blueColor = '#2185d0';
    return (
      <div className="event-list-row detail">
        <div className="event-list-detail">
          <div className="flex-col">
            <span className="detail-header">Timeline:</span>
            <div className="flex-grow">
              <EventTimeLineContent
                width="100%"
                height={90}
                project={project}
                scoreFilter="low"
                statusFilter="all"
                statusFilterAll={fromStorage ? 'historicalPredictions' : 'all'}
                logTypeFilter="all"
                specialEvents
                timeLineEvents={timeLineEvents}
                appNameMapping={appNameMapping}
                onItemSelected={this.handleTimelineItemSelected(event)}
                showNumberTooltip
                startTime={startTime}
                endTime={endTime}
                showHasPredictionSourceInfo
              />
            </div>

            {incidentPredictionFlag && (
              <div className="flex-row">
                <span className="detail-header" style={{ width: 130 }}>{`${intl.formatMessage(
                  DashboardMessages.followOnIncident,
                )}:`}</span>
                <span>True</span>
              </div>
            )}
            {isPrediction && Boolean(predictionSourceInfo) && (
              <span className="detail-header">{`${intl.formatMessage(eventMessages.likelyRootCauses)}:`}</span>
            )}
            {isPrediction &&
              Boolean(predictionSourceInfo) &&
              EventRenderers.RenderLogPredictionCauseDetails({
                intl,
                index: 1,
                metricUnitMap,
                predictionSourceInfo,
              })}

            <span className="detail-header">Event Summary:</span>
            <div className="flex-grow">
              {R.addIndex(R.map)((e, index) => {
                const { appName, rootCauseMetric, rootCauseSource, instanceId, metricValue, pct, direction } = e;
                const { legend } = anomalySeverityRendererItem(e, index, blueColor, topestRank);
                const name = appName && appName !== instanceId ? `${appName} (${instanceId})` : instanceId;

                const unit = get(metricUnitMap, rootCauseMetric);
                return (
                  <div className="flex-row detail-row" key={index}>
                    <div className="detail-item" style={{ width: 320, color: 'green' }}>
                      {name}
                    </div>
                    <div className="detail-item" style={{ width: 60 }}>
                      {legend}
                    </div>
                    <div className="detail-item" style={{ width: 240, color: 'blue' }}>
                      {rootCauseMetric}
                    </div>
                    {metricValue && (
                      <div className="detail-item" style={{ width: 150, textAlign: 'right' }}>
                        {numeral(Number(metricValue)).format(metricValue > 1 ? '0,0' : '0,0.[0000]')}
                        {unit ? `(${unit})` : ''}
                      </div>
                    )}
                    {pct && (
                      <div className="detail-item" style={{ width: 200, flex: 1 }}>
                        is <span style={{ color: 'red' }}>{numeral(Math.abs(Number(pct) / 100)).format('0.0%')}</span>
                        {` ${direction} than ${rootCauseSource || 'normal'}`}
                      </div>
                    )}
                  </div>
                );
              }, rootCauseDetailsArr)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  handleContentChanged() {
    this.cellMeasureCache.clearAll();
    if (this.listNode) {
      this.listNode.forceUpdateGrid();
    }
  }

  @autobind
  handleTimelineItemSelected(event) {
    return (ownEvents) => {
      const eventIds = R.uniq(R.map((e) => e.tooltipItem, ownEvents));
      const eventTs = R.uniq(R.map((e) => e.startTimestamp, ownEvents));
      const selectEvent = R.find(
        (e) => eventIds.indexOf(e.patternId) >= 0 && eventTs.indexOf(e.startTimestamp) >= 0,
        event.timeLineEvents,
      );
      if (selectEvent) {
        event.rootCauseJson = get(selectEvent, 'rootCauseJson', null);
        if (selectEvent.isLog) {
          event.rawData = get(selectEvent, 'rawData', null);
          event.percentageStr = get(selectEvent, 'percentageStr', null);
          event.isPrediction = get(selectEvent, 'isPrediction', null);
          event.predictionSourceInfo = get(selectEvent, 'predictionSourceInfo', null);
        }
        this.cellMeasureCache.clearAll();
        if (this.listNode) {
          this.listNode.forceUpdateGrid();
        }
      }
    };
  }

  @autobind
  handleExpand({ rowData }, stop) {
    return (e) => {
      e.preventDefault();
      if (stop) {
        e.stopPropagation();
      }

      if (rowData) {
        const isExpand = get(rowData, this.expandKey, false);
        rowData[this.expandKey] = !isExpand;
        this.cellMeasureCache.clearAll();
        if (this.listNode) {
          this.listNode.forceUpdateGrid();
        }
      }
    };
  }

  @autobind
  patternTypeRenderer(rowData) {
    const { isPrediction, isRecurrent, isEmailSent, ignoreFlag, isIncidentEvent, isDeploymentEvent } = rowData;

    let background = 'gray';
    let tags = isPrediction ? ['P'] : [];
    let tagDetails = isPrediction ? ['This is a predicted event.'] : [];

    if (isEmailSent) {
      tags = [...tags, 'E'];
      tagDetails = [...tagDetails, 'This is a email sent event.'];
    }
    if (isRecurrent && !isIncidentEvent && !isDeploymentEvent) {
      tags = [...tags, 'R'];
      tagDetails = [...tagDetails, 'This is a recurrent event.'];
    }
    if (ignoreFlag) {
      tags = [...tags, 'I'];
      tagDetails = [...tagDetails, 'This is a ignored event.'];
    }
    if (isIncidentEvent) {
      background = 'red';
      tags = [...tags, 'I'];
      tagDetails = [...tagDetails, 'This is a incident event.'];
    }
    if (isDeploymentEvent) {
      background = 'orange';
      tags = [...tags, 'D'];
      tagDetails = [...tagDetails, 'This is a change event.'];
    }
    return (
      <div className="flex-row" style={{ width: '100%' }}>
        {R.addIndex(R.map)(
          (tag, idx) => (
            <Tooltip
              title={
                <div className="flex-col">
                  <span>{tagDetails[idx]}</span>
                </div>
              }
              placement="top"
              style={{ margin: '0 1.5px' }}
              key={idx}
            >
              <div
                style={{
                  textAlign: 'center',
                  background,
                  color: 'white',
                  padding: '0 2px',
                }}
              >
                {tag}
              </div>
            </Tooltip>
          ),
          tags,
        )}
      </div>
    );
  }

  @autobind
  patternNameRenderer(rowData, isLog) {
    const name = get(rowData, ['patternName']);
    return (
      <Tooltip title={<div>{name}</div>} placement="top">
        <div
          style={{
            wordBreak: 'keep-all',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            width: 88,
          }}
        >
          {name}
        </div>
      </Tooltip>
    );
  }

  @autobind
  patternCauseRenderer(rowData, isLog) {
    const { intl } = this.props;
    return (
      <div style={{ width: '100%' }}>
        {/* {!isLog && <div>{CellRenderers.anomalySeverityRenderer({ rowData })}</div>} */}
        {!isLog && <div>{CellRenderers.metricTypeRenderer({ rowData })}</div>}
        {isLog && (
          <div className="felx-row" style={{ flexWrap: 'wrap' }}>
            {CellRenderers.logTypeRenderer({ intl, rowData })}
          </div>
        )}
      </div>
    );
  }

  @autobind
  patternCauseInstanceRenderer(rowData, isLog) {
    if (!isLog) {
      return (
        <div style={{ width: '100%' }}>
          <div>{CellRenderers.anomalyInstanceRenderer({ rowData })}</div>
        </div>
      );
    }

    const { project } = this.props;
    const instanceInfo = this.getLogInstance(rowData, project);

    return (
      <div style={{ width: '100%' }}>
        <div>{CellRenderers.anomalyLogInstanceRenderer(rowData, instanceInfo)}</div>
      </div>
    );
  }

  @autobind
  statusRenderer({ rowData, cellData }) {
    const { intl } = this.props;
    const { neuronId, isEmailSent, isRecurrent, actionStatus } = rowData;
    const status = [];
    if (isEmailSent) {
      status.push(intl.formatMessage(eventMessages.emailSent));
    }
    if (isRecurrent) {
      status.push(intl.formatMessage(eventMessages.recurrent));
    }

    if (actionStatus && actionStatus.toLowerCase() !== 'initializing') {
      status.push(actionStatus);
    }

    if (neuronId > 0 && cellData) {
      status.push(intl.formatMessage(eventMessages.ignore));
    }

    return (
      <div className="flex-col" style={{ width: '100%' }}>
        {R.addIndex(R.map)((s, idx) => {
          return (
            <Tooltip title={<div>{s}</div>} placement="top" key={idx}>
              <div
                style={{
                  wordBreak: 'keep-all',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  maxWidth: '100%',
                }}
              >
                {s}
              </div>
            </Tooltip>
          );
        }, status)}
      </div>
    );
  }

  @autobind
  ratioMaxRenderer(rowData) {
    const { maxAnomalyScore, isIncidentEvent, isDeploymentEvent } = rowData;
    return (
      <div>
        {isNumber(maxAnomalyScore) && !isIncidentEvent && !isDeploymentEvent ? maxAnomalyScore.toFixed(2) : 'NA'}
      </div>
    );
  }

  @autobind
  ratioRenderer(rowData) {
    const { maxDuration } = this.props;
    const { duration, anomalyScoreList } = rowData;
    const width = maxDuration ? R.max(20, 218 * (duration / maxDuration)) : 218;
    const data = R.map((a) => a.anomalyScore, anomalyScoreList);
    if (data.length === 0) {
      return <div>NA</div>;
    }
    return (
      <div className="flex-col">
        <span style={{ fontSize: 12, color: '#666' }}>
          {CellRenderers.anomalyEventDurationRenderer({ rowData: { duration } })}
        </span>
      </div>
    );
  }

  @autobind
  startTimeRenderer({ cellData }) {
    const time = moment.utc(cellData).format(Defaults.ShortTimeFormat);
    return <div>{time}</div>;
  }

  @autobind
  predictionTimeRenderer({ cellData, isPrediction }) {
    const time = isPrediction ? moment.utc(cellData).format(Defaults.ShortTimeFormat) : '';
    return <div>{time}</div>;
  }

  @autobind
  detailsRenderer({ rowData }) {
    const { project, intl } = this.props;
    const isLog = get(project, 'isLog', false);
    const { hasCausalRelation, hasCausalPrediction, hasSystemCall, isPrediction, isDeploymentEvent } = rowData;

    if (isDeploymentEvent) {
      return <div />;
    }

    if (isLog) {
      return (
        <Dropdown name={intl.formatMessage(eventMessages.details)}>
          <div className="item" onClick={this.handleLogDetailsClick(rowData)}>
            {intl.formatMessage(eventMessages.details)}
          </div>
          <div className="item" onClick={this.handleLogContextOneMinClick(rowData)}>
            {intl.formatMessage(eventMessages.context)}
          </div>
          {hasCausalPrediction && (
            <div className="item" onClick={this.handleCausalPredictionClick(rowData)}>
              {intl.formatMessage(eventMessages.causalPrediction)}
            </div>
          )}
        </Dropdown>
      );
    }

    return (
      <Dropdown name={intl.formatMessage(eventMessages.details)}>
        {/* <div className="item" onClick={this.handleMetricClick(rowData)}>
          Treemap
        </div> */}
        <div className="item" onClick={this.handleMetricDetailsClick(rowData)}>
          {intl.formatMessage(eventMessages.anomalyDetails)}
        </div>
        {!isPrediction && hasSystemCall && (
          <div className="item" onClick={this.handleSyscallClick(rowData)}>
            {intl.formatMessage(eventMessages.syscall)}
          </div>
        )}
        {false && hasCausalRelation && (
          <div className="item" onClick={this.handleCausalClick(rowData)}>
            Causal Graph
          </div>
        )}
        {hasCausalPrediction && (
          <div className="item" onClick={this.handleCausalPredictionClick(rowData)}>
            {intl.formatMessage(eventMessages.causalPrediction)}
          </div>
        )}
      </Dropdown>
    );
  }

  @autobind
  controlRenderer({ rowData }) {
    const { intl } = this.props;
    const { neuronId, ignoreFlag, isDeploymentEvent } = rowData;
    const showIgnore = neuronId > 0;

    if (isDeploymentEvent) {
      return <div />;
    }
    return (
      <Dropdown name={intl.formatMessage(eventMessages.actions)} style={{}}>
        <div className="item" onClick={this.handleActionClick(rowData, 'setPatternName')}>
          {intl.formatMessage(eventMessages.setPatternName)}
        </div>
        <div className="item" onClick={this.handleEventActionClick(rowData)}>
          {intl.formatMessage(eventMessages.takeAction)}
        </div>
        {showIgnore && (
          <div className="item" onClick={this.handleIgnoreClick(rowData)}>
            {ignoreFlag ? intl.formatMessage(eventMessages.unignore) : intl.formatMessage(eventMessages.ignore)}
          </div>
        )}
        {false && (
          <div className="item" onClick={this.handleEmailAlertsClick(rowData)}>
            {intl.formatMessage(eventMessages.emailAlerts)}
          </div>
        )}
        {false && (
          <div className="item" onClick={this.handlePublishClick(rowData)}>
            {intl.formatMessage(eventMessages.publish)}
          </div>
        )}
        {false && (
          <div className="item" onClick={this.handleReportJiraClick(rowData)}>
            {intl.formatMessage(eventMessages.reportJira)}
          </div>
        )}
      </Dropdown>
    );
  }

  @autobind
  getLogInstance(e, project) {
    const instanceName = get(e, ['instanceName'], '');
    const instanceInfoList = get(project, ['instanceInfoList'], []);
    const info = R.find((i) => i.id === instanceName, instanceInfoList);
    if (!info) {
      return { instanceName, appName: instanceName };
    }
    return info;
  }

  @autobind
  handleLogDetailsClick(incident) {
    return (e) => {
      e.stopPropagation();
      const { isAdmin, projects } = this.props;
      const { neuronId, instanceName, user, type, startTimestamp } = incident;
      const day = moment.utc(Number(startTimestamp)).format(Defaults.DateFormat);
      const projectName = isAdmin ? `${incident.projectName}@${user}` : incident.projectName;
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);

      const query = {
        projectName,
        instanceName,

        startTime: day,
        endTime: day,
        activeTab: type === 'rare' ? 'important' : 'clusters',
        activePatternId: type === 'rare' ? undefined : neuronId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: project?.owner,
        anomalyType: type.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    };
  }

  @autobind
  handleLogContextOneMinClick(incident) {
    return (e) => {
      e.stopPropagation();
      const { neuronId, ...rest } = incident;
      const { timestamp, startTimestamp, endTimestamp } = incident;

      const activeIncident = { ...rest, nid: neuronId };
      const instanceName =
        get(activeIncident, ['rootCauseJson', 'appName']) || get(activeIncident, ['rootCauseJson', 'instanceName']);
      this.setState({
        showTimeSelectModal: true,
        selectInstance: instanceName,
        selectStartTimestamp: startTimestamp ? startTimestamp - 60 * 1000 : timestamp - 60 * 1000,
        selectEndTimestamp: endTimestamp ? endTimestamp + 60 * 1000 : timestamp + 60 * 1000,
        activeIncident,
      });
    };
  }

  @autobind
  onCloseTimeSelect(props) {
    const { projectName, instanceName, startTimestamp, endTimestamp, keywordFilter } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState({
        showTimeSelectModal: false,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp: startTimestamp,
        selectEndTimestamp: endTimestamp,
        showLogContextModal: true,
        contextKeywordFilter: keywordFilter,
      });
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  handleCausalPredictionClick(incident) {
    return (e) => {
      e.stopPropagation();
      this.setState({ activeIncident: incident, showCausalPredictionModal: true });
    };
  }

  @autobind
  handleMetricDetailsClick(incident) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();

      const { location, isAdmin } = this.props;
      const paramsLocation = parseLocation(location);
      let { instanceGroup } = paramsLocation;
      let { projectName } = paramsLocation;
      const { patternName, patternId, isPrediction } = incident;

      let metricList = [];
      let instanceList = [];
      const rootCauseDetailsArr = get(incident, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      R.forEachObjIndexed((val) => {
        metricList.push(val.rootCauseMetric);
        instanceList.push(val.instanceId);
      }, rootCauseDetailsArr);
      metricList = R.uniq(metricList);
      instanceList = R.uniq(instanceList);

      if (!projectName) {
        projectName = get(incident, ['key', 'projectName']);
        const userName = get(incident, ['key', 'userName']);
        // projectName = projectName.indexOf('@') >= 0 ? projectName : `${projectName}@${userName}`;
        projectName = isAdmin ? `${projectName}@${userName}` : projectName;
      }
      if (!instanceGroup) {
        instanceGroup = Defaults.InstanceGroup;
      }

      const params = {
        projectName,
        instanceGroup,
        patternName,
        patternId,
        startTimestamp: moment
          .utc(get(incident, ['key', 'startTimestamp']))
          .startOf('day')
          .valueOf(),
        endTimestamp: moment
          .utc(get(incident, ['key', 'endTimestamp']))
          .endOf('day')
          .valueOf(),
        modelType: instanceGroup === 'All' ? 'Holistic' : 'splitByEnv',
        predictedFlag: isPrediction,
        justSelectMetric: R.join(',', metricList),
        justInstanceList: R.join(',', instanceList),
      };
      window.open(buildUrl(BaseUrls.EventsDetails, {}, params), '_blank');
    };
  }

  @autobind
  handleSyscallClick(incident) {
    return (e) => {
      e.stopPropagation();
      this.setState({ activeIncident: incident, showSyscallModal: true });
    };
  }

  @autobind
  handleActionClick(incident, actionName) {
    return (e) => {
      e.stopPropagation();
      this.setState({ activeIncident: incident, showTakeLogActionModal: true, actionName });
    };
  }

  @autobind
  handleEmailAlertsClick(incident) {
    return (e) => {
      e.stopPropagation();
      this.setState({ activeIncident: incident, showEmailAlertsModal: true });
    };
  }

  @autobind
  handleEventActionClick(incident) {
    return (e) => {
      e.stopPropagation();
      this.setState({ activeIncident: incident, showEventActionModal: true });
    };
  }

  @autobind
  handlePublishClick(incident) {
    return (e) => {
      e.stopPropagation();
      this.setState({ activeIncident: incident, showPublishModal: true });
    };
  }

  @autobind
  handleIgnoreClick(incident) {
    return (e) => {
      e.stopPropagation();
      this.setState({ activeIncident: incident, showIgnoreModal: true });
    };
  }

  @autobind
  handleReportJiraClick(incident) {
    return (e) => {
      e.stopPropagation();
      this.setState({ activeIncident: incident, showReportJiraModal: true });
    };
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    this.setState({ showTakeLogActionModal: false }, () => {
      this.filterEventList = R.map((e) => {
        if (e.patternId === patternId) {
          return {
            ...e,
            patternName,
          };
        }
        return e;
      }, this.filterEventList);
      this.cellMeasureCache.clearAll();
      if (this.listNode) {
        this.listNode.forceUpdateGrid();
      }
    });
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          this.sortData(this.props, this.state);
          this.cellMeasureCache.clearAll();
          this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name) {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  setListNode(n) {
    this.listNode = n;
  }

  render() {
    const { width, height, isReadUser } = this.props;
    const { intl, project, instanceGroup, startTime, endTime, appNameMapping } = this.props;
    const { sortBy, sortDirection } = this.state;
    const {
      activeIncident,
      showLogContextModal,
      showTimeSelectModal,
      selectStartTimestamp,
      selectEndTimestamp,
      contextKeywordFilter,
      logContextTime,
      showSyscallModal,
      showCausalPredictionModal,
      showTakeLogActionModal,
      showEmailAlertsModal,
      showEventActionModal,
      showPublishModal,
      showIgnoreModal,
      showReportJiraModal,
      actionName,
    } = this.state;
    const isLog = get(project, 'isLog', false);
    const projectName = get(project, 'projectName');

    return (
      <Container style={{ width, height }}>
        <div className="event-list">
          <div className="event-list-header" style={{ height: this.listHeaderHeight, width }}>
            <div className="header-column" style={{ width: 40 }} />
            <div className="header-column" style={{ width: 60 }} />
            <div className="header-column" style={{ width: 100, flex: 1 }} onClick={this.headerClick('patternName')}>
              <span>{intl.formatMessage(eventMessages.fieldName)}</span>
              {this.sortIcon(sortBy, sortDirection, 'patternName')}
            </div>
            <div className="header-column" style={{ width: 200, flex: 1 }}>
              {intl.formatMessage(eventMessages.fieldRootCauseInstance)}
            </div>
            <div
              className="header-column"
              style={{ width: 160, flex: 1 }}
              onClick={this.headerClick(isLog ? 'type' : '')}
            >
              <span>
                {isLog
                  ? intl.formatMessage(eventMessages.fieldAnomalyType)
                  : intl.formatMessage(eventMessages.fieldRootCauseMetric)}
              </span>
              {isLog && this.sortIcon(sortBy, sortDirection, 'type')}
            </div>
            <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('maxAnomalyScore')}>
              <span>{intl.formatMessage(eventMessages.maxAnomalyScore)}</span>
              {this.sortIcon(sortBy, sortDirection, 'maxAnomalyScore')}
            </div>
            <div className="header-column" style={{ width: 140 }} onClick={this.headerClick('duration')}>
              <span>{intl.formatMessage(eventMessages.fieldAnomalyDuration)}</span>
              {this.sortIcon(sortBy, sortDirection, 'duration')}
            </div>
            <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('startTimestamp')}>
              <span>{intl.formatMessage(eventMessages.fieldStartTime)}</span>
              {this.sortIcon(sortBy, sortDirection, 'startTimestamp')}
            </div>
            {/* <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('duration')}>
              {intl.formatMessage(eventMessages.fieldDuration)}
              {this.sortIcon(sortBy, sortDirection, 'duration')}
            </div> */}
            {/* <div className="header-column" style={{ width: 140 }} onClick={this.headerClick('ignoreFlag')}>
              {intl.formatMessage(eventMessages.fieldStatus)}
              {this.sortIcon(sortBy, sortDirection, 'ignoreFlag')}
            </div> */}
            <div className="header-column" style={{ width: 130 }}>
              {intl.formatMessage(eventMessages.fieldDetails)}
            </div>
            {!isReadUser && (
              <div className="header-column" style={{ width: 130 }}>
                {intl.formatMessage(eventMessages.fieldControl)}
              </div>
            )}
          </div>
          <List
            className="event-list-grid"
            rowCount={this.filterEventList.length}
            ref={this.setListNode}
            deferredMeasurementCache={this.cellMeasureCache}
            overscanRowCount={4}
            rowHeight={this.cellMeasureCache.rowHeight}
            rowRenderer={this.renderRow}
            width={width}
            height={height - this.listHeaderHeight}
          />
        </div>
        {/* Log */}
        {showTimeSelectModal && (
          <TimeSelectModal
            projectName={get(activeIncident, ['projectName'])}
            instanceName={this.state.selectInstance}
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
            timeIntervals={1}
            showKeywordSearch
          />
        )}
        {showLogContextModal && (
          <EventContextModal
            incident={activeIncident}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            keywordFilter={contextKeywordFilter}
            contextTime={logContextTime}
            onClose={() => this.setState({ showLogContextModal: false })}
          />
        )}
        {showCausalPredictionModal && (
          <CausalPredictionModal
            incident={activeIncident}
            appNameMapping={appNameMapping || {}}
            onClose={() => this.setState({ showCausalPredictionModal: false })}
          />
        )}
        {/* Metric Treemap */}
        {/* Metric Syscall */}
        {showSyscallModal && (
          <SyscallModal incident={activeIncident} onClose={() => this.setState({ showSyscallModal: false })} />
        )}

        {/* Control Labeling */}
        {showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={actionName}
            incident={activeIncident}
            project={project}
            projectName={projectName}
            instanceGroup={instanceGroup}
            eventType={activeIncident.isPrediction}
            startTime={startTime}
            actionEndTime={endTime}
            onClose={() => this.setState({ showTakeLogActionModal: false })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {showEmailAlertsModal && (
          <EventEmailAlertsModal
            incident={activeIncident}
            projectName={projectName}
            onClose={() => this.setState({ showEmailAlertsModal: false })}
          />
        )}
        {showEventActionModal && (
          <EventActionModal
            incident={activeIncident}
            project={project}
            projectName={projectName}
            instanceGroup={instanceGroup}
            onClose={() => this.setState({ showEventActionModal: false })}
          />
        )}
        {/* Control Publish */}
        {showPublishModal && (
          <EventPublishModal incident={activeIncident} onClose={() => this.setState({ showPublishModal: false })} />
        )}
        {/* Control Ignore */}
        {showIgnoreModal && (
          <IgnoreModal
            incident={activeIncident}
            projectName={projectName}
            onClose={() => this.setState({ showIgnoreModal: false })}
          />
        )}
        {showReportJiraModal && (
          <ReportJiraModal
            incident={activeIncident}
            projectName={projectName}
            onClose={() => this.setState({ showReportJiraModal: false })}
          />
        )}
      </Container>
    );
  }
}

const EventListTable = injectIntl(EventListTableCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    const { isAdmin, isReadUser } = state.auth.userInfo;

    return { location, loadStatus, projects, isAdmin, isReadUser };
  },
  { createLoadAction },
)(EventListTable);
