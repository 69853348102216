import { get } from 'lodash';

import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const getUserTypeCheck = ({ userName, params }) => {
  const requests = [];
  requests.push(
    fetchPost(getEndpoint('usertypecheck'), {
      UserName: userName,
      ...params,
    }).then((data) => {
      if (data.url) {
        const redirectURL = data.url;
        return { redirectURL };
      }
      return {
        userSuspended: get(data, ['userSuspended']),
        redirectLoginURL: get(data, ['redirectLoginURL']),
        userExist: get(data, ['userExist']),
        IDPSettingMap: get(data, ['IDPSettingMap']),
        idpAvailable: get(data, ['idpAvailable'], [true]),
      };
    }),
  );

  return Promise.all(requests).then((results) => {
    const data = results[0];
    return {
      userSuspended: get(data, ['userSuspended']),
      redirectLoginURL: get(data, ['redirectLoginURL']),
      userExist: get(data, ['userExist']),
      IDPSettingMap: get(data, ['IDPSettingMap']),
      redirectURL: get(data, ['redirectURL']),
      idpAvailable: get(data, ['idpAvailable']),
    };
  });
};

export default getUserTypeCheck;
