/* @flow */
import type { Action, Message } from '../types';

export const ActionTypes = {
  APP_REHYDRATED: 'APP_REHYDRATED',
  APP_START: 'APP_START',
  APP_STARTED: 'APP_STARTED',
  APP_STOP: 'APP_STOP',
  SHOW_APP_LOADER: 'SHOW_APP_LOADER',
  HIDE_APP_LOADER: 'HIDE_APP_LOADER',
  SET_LOAD_STATUS: 'SET_LOAD_STATUS',
  RESET_LAST_ACTION_INFO: 'RESET_LAST_ACTION_INFO',

  LOAD_PROJECT_INFO: 'LOAD_PROJECT_INFO',
  SET_PROJECT_INFO: 'SET_PROJECT_INFO',
  SET_PROJECT_INSTANCE_COMPONENT_MAP: 'SET_PROJECT_INSTANCE_COMPONENT_MAP',
  SET_ACTIVE_INSTANCE: 'SET_ACTIVE_INSTANCE',

  LOAD_INFO_GLOBAL: 'LOAD_INFO_GLOBAL',
  SAVE_COPY_PROJECT: 'SAVE_COPY_PROJECT',
  SET_INFO_GLOBAL: 'SET_INFO_GLOBAL',
  LOAD_INFO_SYSTEM: 'LOAD_INFO_SYSTEM',
  RESET_INFO_GLOBAL: 'RESET_INFO_GLOBAL',
  SET_INFO_SYSTEM_CONFIGS: 'SET_INFO_SYSTEM_CONFIGS',
  SET_SYSTEM_FAVORITES: 'SET_SYSTEM_FAVORITES',

  // User missign info
  SET_USER_MISSING_INFO: 'SET_USER_MISSING_INFO',

  // Awesome Tours
  SET_AWESOME_TOURS: 'SET_AWESOME_TOURS',

  // Set opened submenu
  SET_OPENED_SUBMENUS: 'SET_OPENED_SUBMENUS',

  // Will retired
  SET_LOADER_STATUS: 'SET_LOADER_STATUS',
  SET_COLLAPSED: 'SET_COLLAPSED',
  UPDATAPROJECT: 'UPDATAPROJECT',
};

export const setCurrentTheme = (theme: string): Action => ({
  type: 'SET_CURRENT_THEME',
  payload: { theme },
});

export const setCurrentLocale = (locale: string): Action => ({
  type: 'SET_CURRENT_LOCALE',
  payload: { locale },
});

export const appRehydrated = (): Action => ({
  type: 'APP_REHYDRATED',
});

export const appStart = (): Action => ({
  type: 'APP_START',
});

export const appStarted = (): Action => ({
  type: 'APP_STARTED',
});

export const appStop = (): Action => ({
  type: 'APP_STOP',
});

export const setInitData = (data: Object): Action => ({
  type: 'SET_INIT_DATA',
  payload: data,
});

export const appError = (message: ?Message, error: ?Error): Action => ({
  type: 'APP_ERROR',
  payload: { message, error },
});

export const showAppModal = (type: string, params: ?Object): Action => ({
  type: 'SHOW_APP_MODAL',
  payload: { type, params },
});

export const showAppAlert = (type: string, message: Message, params: ?Object, options: ?Object): Action => ({
  type: 'SHOW_APP_ALERT',
  payload: { type, message, params, options },
});

export const updateLastActionInfo = (data: Object): Action => ({
  type: 'RESET_LAST_ACTION_INFO',
  payload: { data },
});

/**
 * Set the components loading status. The components contains the name and true/false value.
 * This action is handled by reducer.
 */
export const setLoadingComponents = (components: ?Object) => ({
  type: 'SET_LOADING_COMPONENTS',
  payload: {
    ...components,
  },
});

/**
 * Load project info
 */
export const loadProjectInfo = (params: Object, loader: ?any, callback: ?Function) => ({
  type: ActionTypes.LOAD_PROJECT_INFO,
  payload: { params, loader, callback },
});

/**
 * Set the current project group list, which is handled by reducer.
 */
export const setProjectGroupList = (projectName: String, groupList: Array<String>) => ({
  type: 'SET_PROJECT_GROUP_LIST',
  payload: {
    projectName,
    groupList,
  },
});

/**
 * Update default timezone
 */
export const updateDefaultTimezone = (defaultTimezone: String, components: Object) => ({
  type: 'UPDATE_DEFAULT_TIMEZONE',
  payload: {
    defaultTimezone,
    components,
  },
});

export const setDefaultTimezone = (defaultTimezone: String) => ({
  type: 'SET_DEFAULT_TIMEZONE',
  payload: {
    defaultTimezone,
  },
});

/**
 * Set the loaders's status. The status is an object of format {loaderName: bool}
 * Replace with setLoadStatus which support error message
 */
export const setLoaderStatus = (loader: ?Object) => ({
  type: ActionTypes.SET_LOADER_STATUS,
  payload: {
    ...loader,
  },
});

// V2
/**
 * Show the app or page loader
 */
export const showAppLoader = (): Action => ({
  type: ActionTypes.SHOW_APP_LOADER,
});

/**
 * Hide the app or page loader
 */
export const hideAppLoader = (): Action => ({
  type: ActionTypes.HIDE_APP_LOADER,
});

export const setLoadStatus = (loadStatus: ?Object) => ({
  type: ActionTypes.SET_LOAD_STATUS,
  payload: {
    ...loadStatus,
  },
});

/*
 * General action factory to create load type action. Load action are mostly used
 * to get data from server side.
 */
export const createLoadAction = (type, params: Object, loader: ?any, force: Boolean = false, callback: ?Function) => ({
  type,
  payload: { params, loader, force, callback },
});

/*
 * General action factory to create set type action. Set action are mostly used
 * to set the data in the redux store.
 */
export const createSetAction = (type, params: Object, data: any) => ({
  type,
  payload: { params, data },
});

export const setOpenedSubMenus = (submenus: Array<string>): Action => ({
  type: ActionTypes.SET_OPENED_SUBMENUS,
  payload: { openedSubMenus: submenus },
});

export const setCollapsed = (collapsed: Boolean) => ({
  type: ActionTypes.SET_COLLAPSED,
  payload: { collapsed },
});

export const updataProject = (): Action => ({
  type: ActionTypes.UPDATAPROJECT,
});

// 显示和隐藏content主体内容
export const setShowDetailsFrame = (flag: Boolean): Action => ({
  type: 'SET_SHOW_DETAILS_FRAME',
  payload: { showDetailsFrame: flag },
});
