import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Spin, Tabs, message } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Modal } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { EChart } from '../../share';
import { Defaults } from '../../../common/utils';

const getMetricName = (text) => R.pipe(R.split(''), R.lastIndexOf('='), R.splitAt(R.__, text), R.head)(text);

function CDFModalCore(props: Object) {
  const { incident, projectName, onClose, credentials, updateLastActionInfo, isDark } = props || {};
  const { anomalyLogInstance, instanceList = [], startTimestamp, category } = incident || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isLoading: false,
    eventListMap: [],
    activeTab: '',
  });
  const { isLoading, eventListMap, activeTab } = state;
  const isMetric = category === 'metric';
  const metricInfo = get(incident, ['rootCauseJson', 'rootCauseDetailsArr'], [])[0];
  const metricName = metricInfo?.rootCauseMetric || '';

  const isEmptyFlag = (data1) => {
    return R.isEmpty(data1) || R.isNil(data1);
  };

  const parseData = (CDFMap) => {
    const newEventListMap = R.mapObjIndexed((data, scoreKey) => {
      const eventList = [];
      R.forEach((item) => {
        const { referenceDeviation, group, historicalDeviation, cdfData, referenceData, historicalData } = item || {};
        const { historicalInfo, currentInfo } = item || {};
        const cdfSeriesData = R.map((item) => item.value, R.sortWith([R.ascend(R.prop('level'))])(cdfData || []));
        const referenceSeriesData = R.map(
          (item) => item.value,
          R.sortWith([R.ascend(R.prop('level'))])(referenceData || []),
        );
        const historicalSeriesData = R.map(
          (item) => item.value,
          R.sortWith([R.ascend(R.prop('level'))])(historicalData || []),
        );
        const xAxisData = R.map(
          (item) => item.label,
          R.sortWith([R.ascend(R.prop('level'))])(cdfData || referenceData || historicalData || []),
        );

        const series = [
          {
            name: 'This model',
            type: 'line',
            smooth: false,
            data: cdfSeriesData,
            lineStyle: { width: 3 },
            symbolSize: 6,
          },
        ];
        const color = [isDark ? '#3878A1' : '#043e7d'];

        if (!isEmptyFlag(referenceData)) {
          series.push({
            name: 'Reference model',
            type: 'line',
            smooth: false,
            data: referenceSeriesData,
            lineStyle: { width: 3, type: 'dotted' },
            symbolSize: 8,
            symbol: 'triangle',
          });
          color.push('#dd6b66');
        }
        if (!isEmptyFlag(historicalData)) {
          series.push({
            name: 'Historical model',
            type: 'line',
            smooth: false,
            data: historicalSeriesData,
            lineStyle: { width: 3, type: 'dashed' },
            symbolSize: 8,
            symbol: 'rect',
          });
          color.push('#63d555');
        }

        const option = {
          color,
          backgroundColor: 'transparent',
          xAxis: {
            type: 'category',
            data: xAxisData,
            splitLine: { show: false },
            splitArea: { show: false },
            axisLabel: { textStyle: { color: 'gray' } },
          },
          yAxis: {
            type: 'value',
            splitLine: { show: false },
            splitArea: { show: false },
            axisLabel: { textStyle: { color: 'gray' } },
          },
          grid: { left: 50, right: 30, top: 30, bottom: 30 },
          legend: { textStyle: { color: 'gray' } },
          tooltip: {
            backgroundColor: 'var(--component-background)',
            borderColor: 'transparent',
            trigger: 'axis',
            confine: true,
            appendToBody: true,
            formatter: (params, ticket, callback) => {
              return ReactDOMServer.renderToStaticMarkup(
                <>
                  <div>{(params || [])[0]?.name}</div>
                  {R.addIndex(R.map)((item, idx) => {
                    const { seriesName, color, value } = item;
                    return (
                      <div key={idx}>
                        <span
                          style={{
                            display: 'inline-block',
                            marginRight: 5,
                            borderRadius: 10,
                            width: 9,
                            height: 9,
                            backgroundColor: color,
                          }}
                        />
                        {`${seriesName}: ${Number(Number.parseFloat(Math.abs(value || 0)).toFixed(2))}`}
                      </div>
                    );
                  }, params || [])}
                </>,
              );
            },
            textStyle: {
              color: 'var(--text-color)',
            },
          },
          series,
        };

        eventList.push({
          referenceDeviation,
          group,
          option,
          historicalDeviation,
          historicalInfo,
          currentInfo,
          scoreKey,
        });
      }, data || []);
      return eventList;
    }, CDFMap || {});

    setState({ isLoading: false, eventListMap: newEventListMap, activeTab: R.keys(newEventListMap || {})[0] });
  };

  const reloadData = (flag) => {
    setState({ isLoading: true });
    updateLastActionInfo();
    fetchGet(getEndpoint('fetchcdfdata'), {
      ...credentials,
      projectName,
      instanceName: anomalyLogInstance || instanceList[0],
      pageSize: 200000000,
      pageNo: 1,
      timestamp: startTimestamp,
      ...(isMetric ? { scoreKey: getMetricName(metricName) } : {}),
    })
      .then((data) => {
        if (flag) return;
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          parseData(data);
        } else {
          setState({ isLoading: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        setState({ isLoading: false });
        message.error(err.message || String(err));
      });
  };

  useEffect(() => {
    let flag = false;
    reloadData(flag);
    return () => {
      flag = true;
    };
  }, [isDark]);

  const itemRender = (eventList, only) => {
    return (
      <>
        {only && (
          <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
            <div style={{ marginRight: 8 }}>Score key:</div>
            <div className="font-14">{R.keys(eventListMap || {})[0]}</div>
          </div>
        )}
        {R.addIndex(R.map)((item, idx) => {
          const { referenceDeviation, group, option, historicalDeviation, historicalInfo, currentInfo } = item;
          return (
            <div key={idx} className="full-width flex-col" style={{ height: 440, marginTop: idx === 0 ? 0 : 16 }}>
              {!isEmptyFlag(referenceDeviation) && (
                <>
                  <div className="flex-row flex-center-align">
                    <div style={{ marginRight: 8 }}>Shift distance compared to reference:</div>
                    <div className="font-14">
                      {Number(Number.parseFloat(Math.abs(referenceDeviation || 0)).toFixed(2))}
                    </div>
                  </div>
                  <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                    <div style={{ marginRight: 8 }}>Shift direction compared to reference:</div>
                    <div className="font-14">{referenceDeviation < 0 ? 'Left' : 'Right'}</div>
                  </div>
                </>
              )}
              {!isEmptyFlag(historicalDeviation) && (
                <>
                  <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                    <div style={{ marginRight: 8 }}>Shift distance compared to historical:</div>
                    <div className="font-14">
                      {Number(Number.parseFloat(Math.abs(historicalDeviation || 0)).toFixed(2))}
                    </div>
                  </div>
                  <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                    <div style={{ marginRight: 8 }}>Shift direction compared to historical:</div>
                    <div className="font-14">{historicalDeviation < 0 ? 'Left' : 'Right'}</div>
                  </div>
                  <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                    <div style={{ marginRight: 8 }}>Historical model span:</div>
                    <div className="font-14">{`${moment
                      .utc(historicalInfo?.timestamp)
                      .format(Defaults.ShortDateTimeFormat)} ~ ${moment
                      .utc(historicalInfo?.endTime)
                      .format(Defaults.ShortDateTimeFormat)}`}</div>
                  </div>
                </>
              )}
              <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                <div style={{ marginRight: 8 }}>This model span:</div>
                <div className="font-14">{`${moment
                  .utc(currentInfo?.timestamp)
                  .format(Defaults.ShortDateTimeFormat)} ~ ${moment
                  .utc(currentInfo?.endTime)
                  .format(Defaults.ShortDateTimeFormat)}`}</div>
              </div>
              <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                <div style={{ marginRight: 8 }}>Group:</div>
                <div className="font-14">{group}</div>
              </div>
              <div className="flex-grow" style={{ marginTop: 8 }}>
                <EChart width="100%" height="100%" option={option} />
              </div>
            </div>
          );
        }, eventList || [])}
      </>
    );
  };

  return (
    <Modal
      visible
      title="Model drift anomaly"
      onOk={() => onClose()}
      onCancel={() => onClose()}
      width={900}
      bodyStyle={{ height: 540 }}
      footer={null}
    >
      <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
        <div className="full-width full-height">
          {R.keys(eventListMap || {}).length === 1 && itemRender(R.values(eventListMap || {})[0], true)}
          {R.keys(eventListMap || {}).length > 1 && (
            <Tabs
              type="card"
              className="full-height ant-tabs-content-full-height flex-col"
              activeKey={activeTab}
              destroyInactiveTabPane
              onChange={(activeTab) => setState({ activeTab })}
            >
              {R.map((scoreKey) => {
                return (
                  <Tabs.TabPane tab={scoreKey} key={scoreKey} style={{ padding: 16 }}>
                    {itemRender(eventListMap[scoreKey])}
                  </Tabs.TabPane>
                );
              }, R.keys(eventListMap || {}))}
            </Tabs>
          )}
        </div>
      </Spin>
    </Modal>
  );
}

const CDFModal = injectIntl(CDFModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { currentTheme } = state.app;
    const isDark = currentTheme === 'dark';
    return {
      location,
      userInfo,
      credentials,
      isDark,
    };
  },
  { createLoadAction, updateLastActionInfo },
)(CDFModal);
