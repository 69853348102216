import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Form, message, Modal, Select, Spin } from 'antd';
import fetchPost from '../../common/apis/fetchPost';
import getEndpoint from '../../../apis/get-endpoint';
import { appMessages } from '../../common/app/messages';
import fetchGet from '../../common/apis/fetchGet';
import SelectMultiple from '../../../components/custome/SelectMultiple';

type Props = {
  incident: Object,
  onClose: Function,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  systemsMap: Object,
  projects: Array<Object>,
  allProjects: Array<Object>,
  credentials: Object,
  userInfo: Object,
};

class ServiceMapSettingModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { systemsMap, userInfo, incident, allProjects } = props;
    const { systemName, systemId } = incident;

    this.state = {
      isSubmitting: false,
      isLoading: true,
      systemName,
      systemProjects: R.map(
        (item) => ({
          ...item,
          label: `${item.projectDisplayName}${
            userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName ? `@${item.owner}` : ''
          }`,
          value: item.projectName,
        }),
        R.filter(
          (p) => p.systemId === systemId && p.ps !== 'Deleting' && p.cloudType !== 'LogFrequency',
          allProjects || [],
        ),
      ),
      serviceNowStructureList: [],
      serviceNowNodes: [],
      selectedProjects: [],
    };
    this.userSystemNames = R.map(
      (item) => item.systemName,
      R.filter((item) => item.owner === userInfo.userName, R.values(systemsMap)),
    );
  }

  componentDidMount() {
    const { intl, incident, isAdmin, isLocalAdmin, userInfo } = this.props;
    const { systemProjects } = this.state;
    const { userName } = userInfo;
    const { systemId, owner } = incident;
    fetchGet(getEndpoint('serviceNowBySystem'), { systemName: systemId, customerName: owner }, {}, false)
      .then((d) => {
        const { all, selected, selectedProjects, projectList } = d;

        // backend returns incorrect null
        let plistStr = selectedProjects || projectList;
        plistStr = plistStr === 'null' ? '[]' : plistStr;
        let plist = JSON.parse(plistStr);

        let serviceNowStructureList = all || [];
        if (serviceNowStructureList.length === 0) {
          serviceNowStructureList = selected || [];
        }

        if ((plist || []).length === 0) {
          plist = R.filter(
            (x) => !!x,
            R.map((p) => {
              const { projectShortName, owner, dataType } = p;
              return { projectName: projectShortName, userName: owner, dataType };
            }, systemProjects || []),
          );
        }

        this.setState({
          isLoading: false,
          serviceNowStructureList,
          serviceNowNodes: R.map((s) => s?.key?.configurationItem, selected || []),
          selectedProjects: R.map(
            (p) =>
              isAdmin || isLocalAdmin || userName !== (p.customerName || p.owner || p.userName)
                ? `${p.projectName || p.projectShortName}@${p.customerName || p.owner || p.userName}`
                : `${p.projectName || p.projectShortName}`,
            plist || [],
          ),
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  @autobind
  handleSubmit() {
    const { intl, incident, onClose } = this.props;
    const { systemId, owner } = incident;
    const { serviceNowNodes, serviceNowStructureList, selectedProjects, systemProjects } = this.state;
    const serviceNowStructureKeyList = [];
    R.forEach((s) => {
      const { key } = s;
      if (key && serviceNowNodes.indexOf(key.configurationItem) >= 0) {
        serviceNowStructureKeyList.push(key);
      }
    }, serviceNowStructureList);

    let projectList = R.filter(
      (x) => !!x,
      R.map((p) => {
        const pp = R.find((pr) => pr.value === p, systemProjects);
        if (pp) {
          const { projectShortName, owner } = pp;
          return { projectShortName, projectName: projectShortName, customerName: owner, owner };
        } else {
          return null;
        }
      }, selectedProjects || []),
    );

    if (projectList.length === 0) {
      projectList = R.filter(
        (x) => !!x,
        R.map((p) => {
          const { projectShortName, owner } = p;
          return { projectShortName, projectName: projectShortName, customerName: owner, owner };
        }, systemProjects || []),
      );
    }

    this.setState({ isSubmitting: true });
    fetchPost(
      getEndpoint('serviceNowBySystem'),
      {
        systemName: systemId,
        customerName: owner,
        serviceNowStructureKeyList: serviceNowStructureKeyList.length
          ? JSON.stringify(serviceNowStructureKeyList)
          : null,
        selectedProjects: JSON.stringify(projectList),
        projectList: JSON.stringify(projectList),
      },
      {},
      false,
    )
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmitting: false });
        onClose(true);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  onChangeServiceNow(item) {}

  render() {
    const { intl, onClose } = this.props;
    const { isSubmitting, isLoading, serviceNowStructureList, serviceNowNodes, selectedProjects, systemProjects } =
      this.state;

    return (
      <Modal
        title="Service map settings"
        width={650}
        open
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ loading: isSubmitting }}
      >
        <Spin spinning={isLoading} wrapperClassName="">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="ServiceNow nodes">
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                value={serviceNowNodes}
                onChange={(serviceNowNodes) => this.setState({ serviceNowNodes })}
              >
                {R.map((item) => {
                  return (
                    <Select.Option key={item?.key?.configurationItem}>{item?.key?.configurationItem}</Select.Option>
                  );
                }, serviceNowStructureList || [])}
              </Select>
            </Form.Item>
            <Form.Item label="Projects">
              <SelectMultiple
                style={{ width: '100%' }}
                value={selectedProjects}
                onChange={(selectedProjects) => this.setState({ selectedProjects })}
                options={systemProjects}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const ServiceMapSettingModal = injectIntl(ServiceMapSettingModalCore);
export default connect((state) => {
  const { location } = state.router;
  const { loadStatus, systemsMap, projects, allProjects } = state.app;
  const { isAdmin, isLocalAdmin } = state.auth.userInfo;
  const { credentials, userInfo } = state.auth;
  return { location, loadStatus, systemsMap, credentials, userInfo, projects, allProjects, isAdmin, isLocalAdmin };
})(ServiceMapSettingModal);
