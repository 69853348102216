import React from 'react';
import moment from 'moment';
import { isBoolean } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { Spin, Alert, Button } from 'antd';

import { parseLocation, buildUrl, Defaults } from '../../../common/utils';
import { hideAppLoader } from '../../../common/app/actions';
import { authMessages } from '../../../common/auth/messages';
import { BaseUrls } from '../Constants';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { appFieldsMessages } from '../../../common/app/messages';

import { CenterPage } from '.';

type Props = {
  intl: Object,
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  push: Function,
  replace: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userName: String,
  isLoggedIn: Boolean,

  appLoaderVisible: boolean,
  hideAppLoader: Function,

  // eslint-disable-next-line
  isAdmin: Boolean,
  // eslint-disable-next-line
  isLocalAdmin: Boolean,
};

class AppActionPageCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      actionSuccess: null,
      actionMessage: '',
      notFoundMessage: null,
      timeRemaining: 4,
    };
  }

  componentDidMount() {
    this.handleApiCall(this.props);
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  @autobind
  handleApiCall(props) {
    const { location, userName, credentials } = props;
    const { userActionType, userActionJumpView, ...rest } = parseLocation(location);

    if (userActionType === 'ignore') {
      const { customerName } = rest;
      let { projectName } = rest;
      if (userName && customerName !== userName) {
        projectName = `${projectName}@${customerName}`;
      }
      fetchPost(
        getEndpoint('events', 1),
        {
          ...rest,
          ...credentials,
          UserName: userName,
          projectName,
        },
        {},
        false,
      )
        .then((data) => {
          this.setState({ actionSuccess: true }, () => {
            this.timer = setInterval(() => {
              this.handleIgnoreRedirect();
            }, 1000);
          });
        })
        .catch((err) => {
          err.response.json().then((jsonObj) => {
            this.setState({ actionSuccess: false, actionMessage: jsonObj.message });
            if (jsonObj.message.indexOf('not found') > 0) {
              this.setState({ notFoundMessage: jsonObj.message });
            }
          });
        });
    } else if (userActionJumpView === 'unifiedHealthView') {
      this.handleRedirectGV();
    } else if (userActionJumpView === 'incidentPrediction') {
      this.handleRedirectIP();
    } else if (userActionJumpView === 'rootCause') {
      this.handleRedirectRCA();
    } else if (userActionJumpView === 'dailyReportSetting') {
      this.handleRedirectReportSetting();
    } else if (userActionJumpView === 'grouping') {
      this.handleRedirectGrouping();
    } else if (userActionJumpView === 'projectCreation') {
      this.handleRedirectProjectCreation();
    } else if (userActionJumpView === 'accountCreation') {
      this.handleRedirectAccountCreation();
    }
  }

  @autobind
  redirectToLogInPage() {
    const { replace } = this.props;
    const { pathname, search } = window.location;
    const loginRedirectURL = pathname + search;
    window.sessionStorage.setItem('loginRedirectURL', loginRedirectURL);
    replace(buildUrl('/auth/login2', {}, {}));
  }

  @autobind
  redirectToHomePage() {
    const { replace } = this.props;
    replace(buildUrl('/', {}, {}));
  }

  @autobind
  handleIgnoreRedirect() {
    const { replace, location } = this.props;
    const query = parseLocation(location);
    let { timeRemaining } = this.state;

    timeRemaining -= 1;
    this.setState({ timeRemaining }, () => {
      if (timeRemaining === 0) {
        const {
          userActionJumpView,
          environmentName: environmentId,
          customerName,
          systemName: systemId,
          eventTimestamp,
          componentName,
        } = query;

        if (userActionJumpView === 'rootCause' && environmentId && systemId && eventTimestamp) {
          // jump to root cause view
          const { projectName, nid } = query;
          const date = moment.utc(Number(eventTimestamp)).format(Defaults.DateFormat);
          replace(
            buildUrl(
              BaseUrls.GlobalSystemRootCause,
              {},
              {
                // set redirect flag
                redirect: true,

                environmentId,
                startTime: date,
                endTime: date,
                customerName,
                systemId,

                hideIgnore: false,
                eventProjectName: projectName,
                eventPatternId: nid,
                eventTimestamp,
                eventComponentName: componentName,
              },
            ),
          );
        } else if (userActionJumpView === 'incidentPrediction' && environmentId && systemId) {
          // jump to incident prediction view
          const { projectName, nid } = query;
          const date = moment.utc(Number(eventTimestamp)).format(Defaults.DateFormat);
          replace(
            buildUrl(
              BaseUrls.GlobalSystemPrediction,
              {},
              {
                // set redirect flag
                redirect: true,

                environmentId,
                startTime: date,
                endTime: date,
                customerName,
                systemId,

                hideIgnore: false,
                typeFilter: 'all',

                eventProjectName: projectName,
                eventPatternId: nid,
                eventTimestamp,
              },
            ),
          );
        } else {
          // jump to health view
          replace(buildUrl(BaseUrls.GlobalHealth, {}, {}));
        }
      }
    });
  }

  @autobind
  handleRedirectGV() {
    const { replace, location, isLoggedIn } = this.props;
    const query = parseLocation(location);
    const { environmentName: environmentId, customerName, systemName: systemId, date } = query;

    const url = buildUrl(
      BaseUrls.GlobalHealthSystem,
      {},
      {
        environmentId,
        startTime: date,
        endTime: date,
        customerName,
        systemId,
      },
    );

    if (isLoggedIn) {
      replace(url);
    } else {
      window.sessionStorage.setItem('loginRedirectURL', url);
      replace(url);
    }
  }

  @autobind
  handleRedirectIP() {
    const { replace, location, isLoggedIn } = this.props;
    const query = parseLocation(location);
    const {
      environmentName: environmentId,
      customerName,
      systemName: systemId,
      startTime,
      endTime,
      date,
      // jump with filter
      typeFilter,
      // jumpt to incident
      projectName,
      instanceName,
      patternId,
      predictionTime,
      predictedTime,
    } = query;

    const url = buildUrl(
      BaseUrls.GlobalSystemPrediction,
      {},
      {
        // set redirect flag
        redirect: true,

        environmentId,
        startTime: date || startTime,
        endTime: date || endTime,
        customerName,
        systemId,

        typeFilter: typeFilter || 'all',
        eventProjectName: projectName,
        eventInstanceName: instanceName,
        eventPatternId: patternId,
        eventPredictionTime: predictionTime,
        eventTimestamp: predictedTime,
      },
    );

    if (isLoggedIn) {
      replace(url);
    } else {
      window.sessionStorage.setItem('loginRedirectURL', url);
      replace(url);
    }
  }

  @autobind
  handleRedirectRCA() {
    const { replace, location, isLoggedIn } = this.props;
    const query = parseLocation(location);
    const {
      environmentName: environmentId,
      customerName,
      systemName: systemId,
      date,
      projectName,
      logInstanceName,
      instanceName,
      patternId,
      startTime,
      type,
      category,
      componentName,
    } = query;

    const url = buildUrl(
      BaseUrls.GlobalSystemRootCause,
      {},
      {
        // set redirect flag
        redirect: true,

        environmentId,
        startTime: date,
        endTime: date,
        customerName,
        systemId,

        eventCategory: category,
        eventProjectName: projectName,
        eventPatternId: patternId,
        eventTimestamp: startTime,
        eventLogInstanceName: logInstanceName,
        eventInstanceName: instanceName,
        eventPatternType: type,
        eventComponentName: componentName,
      },
    );

    if (isLoggedIn) {
      replace(url);
    } else {
      window.sessionStorage.setItem('loginRedirectURL', url);
      replace(url);
    }
  }

  @autobind
  handleRedirectReportSetting() {
    const { replace, location, isLoggedIn } = this.props;
    const query = parseLocation(location);
    const { customerName, systemName: systemId } = query;
    const url = buildUrl(
      BaseUrls.SystemSetting,
      {},
      {
        // set redirect flag
        redirect: true,
        customerName,
        systemId,
        systemSearch: systemId,
        projectOwner: customerName,
        activeSystemTab: 'email',
        activeLeftTab: 'dailyReport',
      },
    );
    if (isLoggedIn) {
      replace(url);
    } else {
      window.sessionStorage.setItem('loginRedirectURL', url);
      replace(url);
    }
  }

  @autobind
  handleRedirectGrouping() {
    const { replace, location, isLoggedIn, isAdmin, isLocalAdmin, userName } = this.props;
    const query = parseLocation(location);
    const { projectName, systemName, instance, customerName } = query;
    const isSharedUser = customerName !== userName;

    const url = buildUrl(
      BaseUrls.SettingsProject,
      { projectName: isAdmin || isLocalAdmin || isSharedUser ? `${projectName}@${customerName}` : projectName },
      {
        redirect: true,
        systemSearch: systemName,
        projectSearch: '',
        projectOwner: customerName,
        setting: 'grouping',
        start: 0,
        limit: 10,
        metricFilter: '',
        onlyIsKpi: false,
        onlyComputeDifference: false,
        instance,
        isJump: true,
        customerName,
      },
    );
    if (isLoggedIn) {
      replace(url);
    } else {
      window.sessionStorage.setItem('loginRedirectURL', url);
      replace(url);
    }
  }

  @autobind
  handleRedirectProjectCreation() {
    const { replace, location, isLoggedIn } = this.props;
    const query = parseLocation(location);
    const { userName, type, id } = query;

    const url = buildUrl(
      BaseUrls.SettingsProjectWizard,
      {},
      {
        dataSource: type,
        category: 'All',
        customerName: userName,
        id,
        isJump: true,
      },
    );
    if (isLoggedIn) {
      replace(url);
    } else {
      window.sessionStorage.setItem('loginRedirectURL', url);
      replace(url);
    }
  }

  @autobind
  handleRedirectAccountCreation() {
    const { replace, location, isLoggedIn } = this.props;
    const query = parseLocation(location);
    const { signupCode, email } = query;

    const url = buildUrl(
      '/auth/signup2',
      {},
      {
        signupCode,
        email,
      },
    );
    if (isLoggedIn) {
      replace(url);
    } else {
      window.sessionStorage.setItem('loginRedirectURL', url);
      replace(url);
    }
  }

  render() {
    const { intl, push, isLoggedIn, currentTheme } = this.props;
    const { actionSuccess, actionMessage, timeRemaining, notFoundMessage } = this.state;

    return (
      <CenterPage intl={intl} push={push} className="" hideBeacon currentTheme={currentTheme}>
        <div className="flex-row flex-center-align" style={{ fontSize: 14, fontWeight: 500 }}>
          <div style={{ paddingRight: 8 }}>Take User Action:</div>
          {actionSuccess === null && <Spin className="flex-row flex-center-align" size="small" spinning />}
          {isBoolean(actionSuccess) && (
            <div style={{ color: actionSuccess ? 'green' : 'red' }}>
              {actionSuccess
                ? intl.formatMessage(appFieldsMessages.success)
                : intl.formatMessage(appFieldsMessages.error)}
            </div>
          )}
        </div>
        {isLoggedIn && actionMessage && (
          <div style={{ marginTop: 8 }}>
            <Alert message={notFoundMessage || intl.formatMessage(authMessages.errorsNoPrivilege)} type="error" />
          </div>
        )}
        {!isLoggedIn && actionMessage && (
          <div style={{ marginTop: 8 }}>
            <Alert message={intl.formatMessage(authMessages.pleaseLogin)} type="error" />
          </div>
        )}
        {isBoolean(actionSuccess) && actionSuccess && (
          <div className="flex-row" style={{ marginTop: 8 }}>
            <div style={{ paddingRight: 8 }}>Waiting for redirect:</div>
            <div>{`${timeRemaining}s`}</div>
          </div>
        )}
        {!isLoggedIn && actionMessage && (
          <Button style={{ marginTop: 8 }} type="primary" onClick={this.redirectToLogInPage}>
            {intl.formatMessage(authMessages.backLogin)}
          </Button>
        )}
        {isLoggedIn && actionMessage && (
          <Button style={{ marginTop: 8 }} type="primary" onClick={this.redirectToHomePage}>
            Back to United Health View
          </Button>
        )}
      </CenterPage>
    );
  }
}

const AppActionPage = injectIntl(AppActionPageCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials, userInfo } = state.auth;
    const { userName, isAdmin, isLocalAdmin } = userInfo || {};
    const isLoggedIn = Boolean(state.auth.userInfo);
    return {
      location,
      loadStatus,
      appLoaderVisible: state.app.appLoaderVisible,
      credentials,
      userName,
      isLoggedIn,
      isAdmin,
      isLocalAdmin,
      currentTheme: state.app.currentTheme,
    };
  },
  {
    push,
    replace,
    hideAppLoader,
  },
)(AppActionPage);
