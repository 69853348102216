/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import moment from 'moment';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const newCausalTask = (credentials: Credentials, params: Object) => {
  const { causalKey, startTimeObj, endTimeObj, cutIntervalInDayStr, customerName } = params;

  const startTime = startTimeObj.startOf('day').valueOf();
  const endTime = endTimeObj.endOf('day').valueOf();

  return fetchPost(
    `${window.BASE_URL || ''}/localcron/relationpreprocess`,
    {
      ...credentials,
      causalKey,
      startTime,
      endTime,
      cutIntervalInDayStr,
      customerName,
    },
    {},
    false,
  ).then((d) => {
    return d;
  });
};

export default newCausalTask;
