import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import update from 'immutability-helper';
import ThundraTraceChart from 'thundra-trace-chart';
import { get, isNumber } from 'lodash';
import { Alert, Button, Checkbox, Modal, Select, Spin, Switch, message, notification } from 'antd';
import {
  AimOutlined,
  ApartmentOutlined,
  BellOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DoubleLeftOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import { AutoSizer, List, Popover } from '../../../lib/fui/react';
import {
  CellRenderers,
  Defaults,
  EventRenderers,
  LogRenderers,
  buildUrl,
  getPatternNameIcon,
  parseLocation,
} from '../../../common/utils';
import { ActionIcon, CompositeIcon, FixedIcon, InvalidPredictionsIcon, RestoreSvgPath } from '../../../lib/fui/icons';

import { appButtonsMessages, appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import BaseUrls from '../../app/BaseUrls';

const chartBarColor = '#ff7875';
const chartBarActiveColor = '#d9363e';

const listHeaderHeight = 40;
const rowMinHeight = 60;
const dateHeight = 60;

const LLMTitleMap = {
  incident: 'incidentSummary',
  logAlert: 'alertSummary',
  metric: 'metricAnomalieSummary',
  logAnomaly: 'logAnomalieSummary',
  trace: 'traceAnomalieSummary',
  deployment: 'changeSummary',
};

const getTypeColor = (data) => {
  let color = 'var(--type-gray)';
  if (R.includes('hot', data)) {
    color = 'var(--red)';
  } else if (R.includes('cold', data)) {
    color = 'var(--blue)';
  }
  return color;
};

const generateTimeIntervals = (startTime, endTime) =>
  R.unfold((t) => (t >= endTime ? false : [t, t + 600000]), startTime);

const judgeViewEvent = (event, tab) => {
  return !event.isIgnored || (event.patternId >= 10000 && tab === 'logAlert');
};

const statusOptions = [
  { value: 'created', label: 'Created', color: 'currentColor' },
  { value: 'in progress', label: 'Active', color: 'orange' },
  { value: 'closed', label: 'Closed', color: 'green' },
];

const sortIcon = (sortBy, sortDirection, name) => {
  if (sortBy !== name || sortDirection === 'NA') {
    return null;
  }
  if (sortDirection === 'ASC') {
    return <CaretUpOutlined />;
  }
  return <CaretDownOutlined />;
};

const setTooltipText = (intl, typeWord) => {
  let test = null;
  switch (typeWord) {
    case 'hot':
      test = intl.formatMessage(logMessages.hotExplain);
      break;
    case 'newpattern':
      test = intl.formatMessage(logMessages.newpatternExplain);
      break;
    case 'cold':
      test = intl.formatMessage(logMessages.coldExplain);
      break;
    case 'whitelist':
      test = intl.formatMessage(logMessages.whitelistExplain);
      break;
    case 'rare':
      test = intl.formatMessage(logMessages.rareExplain);
      break;
    default:
      break;
  }
  return test;
};

const sortData = (eventList, sortBy, sortDirection) => {
  let sortList = eventList || [];

  // sort by
  let sortFunctions = [
    R.descend(R.prop('hasImportant')),
    R.descend(R.prop('isImportant')),
    R.ascend(R.prop('startTimestamp')),
  ];
  if (sortBy && sortDirection && sortDirection !== 'NA') {
    sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
  }

  if (R.includes(sortBy, ['count', 'hasRootCause'])) {
    sortList = R.map((item) => {
      item.children = R.sortWith(sortFunctions)(item.children || []);
      return item;
    }, sortList || []);
    return sortList;
  }

  if (R.includes(sortBy, ['startTimestamp'])) {
    sortList = R.sortWith(sortFunctions)(
      R.map((item) => {
        item.children = R.sortWith(sortFunctions)(
          R.map((_item) => {
            _item.children = R.sortWith(sortFunctions)(_item?.children || []);
            return _item;
          }, item.children || []),
        );
        return item;
      }, sortList || []),
    );
    return sortList;
  }

  sortList = R.map((item) => {
    item.children = R.sortWith(sortFunctions)(
      R.map((_item) => {
        _item.children = R.sortWith(sortFunctions)(_item?.children || []);
        return _item;
      }, item.children || []),
    );
    return item;
  }, sortList || []);
  return sortList;
};

const sortFunction = (eventList, sortBy, sortDirection) => {
  let sortList = eventList || [];
  // sort by
  let sortFunctions = [R.ascend(R.prop('startTimestamp'))];
  if (sortBy && sortDirection && sortDirection !== 'NA') {
    sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
  }
  sortList = R.sortWith(sortFunctions)(sortList);
  return sortList;
};

const setChildrenTimes = (children) => {
  const newChildren = R.clone(children);
  let firstTimeFlag = '';
  R.forEach((item) => {
    const shortDayTamp = moment.utc(item?.startTimestamp).format(Defaults.ShortDayFormat);
    const shortTimeOnlyTamp = moment.utc(item?.startTimestamp).format(Defaults.ShortTimeOnlyFormat);
    const shortEndDayTamp = moment.utc(item?.endTimestamp).format(Defaults.ShortDayFormat);
    const shortEndTimeOnlyTamp = moment.utc(item?.endTimestamp).format(Defaults.ShortTimeOnlyFormat);
    if (firstTimeFlag !== shortDayTamp) {
      firstTimeFlag = shortDayTamp;
      item.startTimeText = `${shortDayTamp} ${shortTimeOnlyTamp}`;
      item.startEndTimeText = `${shortEndTimeOnlyTamp}`;
      if (shortDayTamp !== shortEndDayTamp) item.startEndTimeText = `${shortEndDayTamp} ${shortEndTimeOnlyTamp}`;
    } else {
      item.startTimeText = shortTimeOnlyTamp;
      item.startEndTimeText = shortEndTimeOnlyTamp;
      if (shortDayTamp !== shortEndDayTamp) {
        item.startTimeText = `${shortDayTamp} ${shortTimeOnlyTamp}`;
        item.startEndTimeText = `${shortEndDayTamp} ${shortEndTimeOnlyTamp}`;
      }
    }
    return item;
  }, newChildren || []);
  return newChildren;
};

const getChartTimeMap = (chartTimeMap, startTime, endTime) => {
  if (!chartTimeMap[`${startTime}-${endTime}`]) {
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    const allDayAnomalyList = generateTimeIntervals(startTimestamp, endTimestamp);
    const filterTimestamps = R.reverse(allDayAnomalyList);
    chartTimeMap[`${startTime}-${endTime}`] = { allDayAnomalyList, filterTimestamps };
  }
};

const getEventInAnomalyTimelines = (rowData) => {
  const { isCompositeAnomaly, anomalyTimelines } = rowData;
  if (isCompositeAnomaly) {
    const findEvent = R.find((item) => get(item.rootCauseResultInfo, 'hasPrecedingEvent'), anomalyTimelines || []);
    if (findEvent) {
      return findEvent;
    }
  }
  return rowData;
};

const rendererExpand = (rowIndex, rowData) => {
  const { isRootEvent, isTimeRoot, isExpand } = rowData;

  return (
    (isRootEvent || isTimeRoot) && (
      <div className="full-width">
        {isExpand ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
      </div>
    )
  );
};

const impactedRender = (rowData, self) => {
  const { intl } = self.props;
  const { componentName } = rowData;

  return (
    <div className="max-width flex-row flex-center-align">
      <div className="flex-grow overflow-hidden">
        <div className="flex-row">
          <Popover
            content={
              <div style={{ maxWidth: 400, wordBreak: 'break-all' }}>
                <div className="flex-row">
                  <div className="light-label bold" style={{ width: 130, flexShrink: 0 }}>
                    {intl.formatMessage(eventMessages.componentName)}:&nbsp;
                  </div>
                  {componentName}
                </div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="right"
            align={{ offset: [12, 0] }}
          >
            <div className="overflow-hidden">
              <div className="hidden-line-with-ellipsis">{componentName}</div>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

const timeRenderer = (rowData) => {
  const { startTimestamp } = rowData;

  return <div className="flex-col">{moment.utc(startTimestamp).format(Defaults.ShortTimeOnlyFormat)}</div>;
};

const renderTrace = (rawData, rawDataJson) => {
  const { outlierValue, componentListStr, id } = rawData;
  const traceId = get(rawDataJson, 'trace_id') || get(rawDataJson, 'traceID');
  const parentId = get(rawDataJson, 'parent_span_id') || get(rawDataJson, 'processID');
  const spanId = get(rawDataJson, 'span_id') || get(rawDataJson, 'spanID');
  const serviceName = get(rawDataJson, ['attributes', 'http.server_name']) || componentListStr;
  const name = get(rawDataJson, 'method_name') || get(rawDataJson, 'operationName');
  const startTime = get(rawDataJson, 'start_time') || get(rawDataJson, 'startTime');
  const duration = Number(get(rawDataJson, 'duration', 0));
  const timestamp = moment.utc(startTime).valueOf();
  const traceInfo = [
    {
      traceId: id,
      parentId,
      id: spanId,
      name,
      timestamp,
      duration,
      serviceName,
      color: '#ffccc7',
      tags: {},
    },
  ];

  return (
    <div className="flex-col" style={{ width: 650, flexShrink: 0 }} onClick={(e) => e.stopPropagation()}>
      <div className="trace-timelines">
        <ThundraTraceChart
          traceId={id}
          traceSummary={traceInfo}
          showHeader={false}
          showMiniTrace={false}
          showSpanDetailTitle={false}
          showSpanDetail={false}
        />
      </div>
      <div className="flex-col">
        <div className="flex-col" style={{ marginBottom: 8 }}>
          <div className="flex-row" style={{ wordBreak: 'break-word' }}>
            <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
              Service Name:
            </span>
            <span style={{ marginLeft: 8 }}>{serviceName}</span>
          </div>
          <div className="flex-row" style={{ wordBreak: 'break-word' }}>
            <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
              Span Info:
            </span>
            <span style={{ marginLeft: 8 }}>{name}</span>
          </div>
          {outlierValue && (
            <LogRenderers.RenderOutlierValue
              className="flex-row"
              style={{ wordBreak: 'break-word' }}
              outlierValue={outlierValue}
              isTrace
            />
          )}
        </div>
      </div>
    </div>
  );
};

const traceRenderer = (rowData) => {
  const { category, rawData, isTrace } = rowData;

  let rawDataJson;
  try {
    rawDataJson = JSON.parse(rawData);
  } catch (error) {
    // console.debug(error)
  }

  if (category !== 'log' || !isTrace || !rawDataJson) {
    return <div />;
  }

  return (
    <Popover
      placement="left"
      content={
        <div className="overflow-y-auto overflow-x-auto" style={{ maxWidth: 650, maxHeight: 350 }}>
          {category === 'log' && isTrace && rawDataJson && renderTrace(rowData, rawDataJson)}
        </div>
      }
      trigger={['click', 'hover']}
    >
      <div className="flex-row flex-center-align" style={{ minHeight: 20 }}>
        <AimOutlined style={{ fontSize: 18 }} />
      </div>
    </Popover>
  );
};

const renderCategory = (rowData, self) => {
  const { intl } = self.props;
  const { typeList, category } = rowData;
  return (
    <>
      {category === 'log' &&
        R.map((type) => {
          return CellRenderers.logShortTypeRenderer({ intl, type });
        }, typeList)}
      {category === 'metric' && CellRenderers.logShortTypeRenderer({ intl, type: 'metric' })}
    </>
  );
};

const renderTriggeredActions = (rowData, index) => {
  const { actionName, timestamp } = rowData;
  return (
    <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
      <div className="row-column" style={{ width: 140 }}>
        {moment.utc(timestamp).format(Defaults.DateTimeFormat)}
      </div>
      <div className="row-column" style={{ width: 120, flex: 1 }}>
        {actionName}
      </div>
    </div>
  );
};

const statusRenderer = (rowData, self) => {
  const { intl } = self.props;
  const { triggeredActionList, isFixedIncident } = rowData;
  const { isNotified, notValidSrc, isCompositeAnomaly, anomalyTimelines } = rowData;
  const hasTriggeredAction = isCompositeAnomaly
    ? !!R.find((item) => item.triggeredActionList?.length > 0, anomalyTimelines || [])
    : triggeredActionList?.length > 0;
  const hasFixedIncident = isCompositeAnomaly
    ? !!R.find((item) => item.isFixedIncident, anomalyTimelines || [])
    : isFixedIncident;
  const hasNotified = isCompositeAnomaly ? !!R.find((item) => item.isNotified, anomalyTimelines || []) : isNotified;
  const hasNotValidSrc = isCompositeAnomaly
    ? !!R.find((item) => item.notValidSrc, anomalyTimelines || [])
    : notValidSrc;

  return (
    <div className="flex-row flex-wrap flex-center-align" style={{ gap: 4 }}>
      {hasTriggeredAction && (
        <Popover
          title={intl.formatMessage(eventMessages.actionTriggered)}
          content={
            isCompositeAnomaly ? (
              intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
            ) : (
              <div className="event-list flex-grow flex-col flex-min-height" style={{ width: 500, maxHeight: 240 }}>
                <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                  <div className="header-column" style={{ width: 140 }}>
                    {intl.formatMessage(eventActionMessages.triggeredTime)}
                  </div>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(eventActionMessages.actionName)}
                  </div>
                </div>
                <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                  {R.addIndex(R.map)((item, index) => renderTriggeredActions(item, index), triggeredActionList)}
                </div>
              </div>
            )
          }
          mouseEnterDelay={0.3}
          placement="left"
        >
          <ActionIcon style={{ fontSize: 16 }} />
        </Popover>
      )}
      {hasFixedIncident && (
        <Popover content={intl.formatMessage(eventMessages.isFixedIncident)} mouseEnterDelay={0.3}>
          <FixedIcon style={{ color: '#ff5142', fontSize: 14 }} />
        </Popover>
      )}
      {hasNotified && (
        <Popover
          content={intl.formatMessage(eventMessages.realTimeNotificationWasSent)}
          mouseEnterDelay={0.3}
          placement="top"
        >
          <BellOutlined size="small" style={{ fontSize: 14, color: '#FF7A00' }} />
        </Popover>
      )}
      {hasNotValidSrc && (
        <Popover
          placement="right"
          title={null}
          content={intl.formatMessage(logMessages.invalidIncidents)}
          trigger="hover"
        >
          <InvalidPredictionsIcon style={{ fontSize: 16, color: 'transparent' }} />
        </Popover>
      )}
    </div>
  );
};

const handleSeparately = (event, instanceComponentMap) => {
  let rootCauseDetailsArr = get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []);
  let allInstanceList = [];
  let instanceAppNames = [];
  let componentList = [];
  if (event.category === 'metric') {
    rootCauseDetailsArr = R.map((pair) => {
      const realInstance = pair.instanceId || pair.instanceName;
      let instanceName = realInstance;
      if (pair.containerName && !realInstance.includes('_')) {
        instanceName = `${pair.containerName}_${realInstance}`;
      }
      allInstanceList.push(instanceName);
      const componentName =
        get(instanceComponentMap, realInstance) || get(instanceComponentMap, instanceName) || instanceName;
      componentList.push(componentName);
      const appName =
        componentName && componentName !== instanceName && componentName !== realInstance
          ? `${instanceName} (${componentName})`
          : instanceName;
      instanceAppNames.push(appName);
      return { ...pair, appName };
    }, rootCauseDetailsArr);
    instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
  } else {
    const { realInstanceName } = event;
    allInstanceList = [...allInstanceList, event.instanceName, realInstanceName];
    const componentName =
      get(instanceComponentMap, realInstanceName) ||
      get(instanceComponentMap, event.instanceName) ||
      event.instanceName;
    componentList.push(componentName);
    const appName =
      componentName && componentName !== event.instanceName && componentName !== event.realInstanceName
        ? `${event.instanceName} (${componentName})`
        : event.instanceName;
    instanceAppNames.push(appName);
  }

  const onlyInstanceString = get(event, ['containerInfo', 'instanceName'], event.realInstanceString);
  allInstanceList = [...allInstanceList, onlyInstanceString];
  componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
  instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
  allInstanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(allInstanceList));

  return { rootCauseDetailsArr, allInstanceList, instanceAppNames, componentList, onlyInstanceString };
};

const handleComposite = (eventList, projects, instanceComponentMap, tabName, getMappingProjectInfo) => {
  const eventIds = {};
  eventList = R.addIndex(R.map)((event, idx) => {
    const projectInfo = R.find((_item) => _item.projectShortName === event.projectName, projects || []);
    if (!eventIds[event.id]) {
      eventIds[event.id] = [event.id];
    } else {
      eventIds[event.id].push(event.id);
    }

    const categoryList = [];
    const { rootCauseDetailsArr, allInstanceList, instanceAppNames, componentList, onlyInstanceString } =
      handleSeparately(event, instanceComponentMap);

    const leadToIncident = get(event.rootCauseResultInfo, 'leadToIncident');
    const causedByChange = get(event.rootCauseResultInfo, 'causedByChangeEvent');
    const hasRootCause = get(event.rootCauseResultInfo, 'hasPrecedingEvent');
    const hasTrailing = get(event.rootCauseResultInfo, 'hasTrailingEvent');

    if (event.isIgnored) categoryList.push('ignoredSrc');

    if (judgeViewEvent(event, tabName)) {
      if (event.isImportant) categoryList.push('important');

      if ((event?.recurringIncidentTimes || []).length > 0) categoryList.push('recurrentIncident');

      if (hasRootCause) categoryList.push('hasRootCause');

      if (!hasRootCause) categoryList.push('noRootCause');

      if (event.isFixedIncident) categoryList.push('fixedIncident');

      if ((event?.triggeredActionList || []).length > 0) categoryList.push('triggered');

      if (event.isNotified) categoryList.push('notification');

      if (!event.notValidSrc) categoryList.push('validSrc');

      if (event.notValidSrc) categoryList.push('notValidSrc');
    }

    const mergeKey = `${event.projectName}-${event.componentName}-${event.patternId}-${event.patternName}`;

    let metricRootCauseObj;
    let frequencyStr = '';
    try {
      metricRootCauseObj = JSON.parse(event.metricRootCause);
    } catch (e) {
      // console.debug(error)
    }

    if (metricRootCauseObj && event.category.toLowerCase() === 'log') {
      const { anomalyValue, percentage, sign } = metricRootCauseObj;
      const percent = `${Math.abs(percentage).toFixed(2)}%`;
      frequencyStr = `${
        isNumber(anomalyValue) ? `Count: ${anomalyValue}. ` : ''
      }Frequency is ${percent} ${sign} than normal.`;
    }

    let anomalyFeatureStr;
    if (!R.isEmpty(event.anomalyFeatureStr) && event.anomalyFeatureStr && event.category.toLowerCase() === 'log') {
      anomalyFeatureStr = R.map((item) => {
        const [label] = item.label.split(':');
        const name = get(item, 'name');
        return { label: name || label, value: item.value };
      }, event.anomalyFeatureStr);
      anomalyFeatureStr = R.sort(R.ascend(R.prop('label')), anomalyFeatureStr);
      const featureGroup = R.groupBy((item) => item.label)(anomalyFeatureStr);

      anomalyFeatureStr = R.map((item) => item, R.toPairs(featureGroup));
    }

    const instanceListStr = event.containerInfo ? event.containerInfo.instanceName : event.instanceNameString;
    const mappingProjectInfo = getMappingProjectInfo({ event: { ...event, instanceListStr } });
    const hasMetricProject = tabName === 'metric' ? true : mappingProjectInfo.dataType === 'Metric';

    return {
      ...event,
      rootCauseJson: { rootCauseDetailsArr },
      appName: R.join(', ', instanceAppNames),
      instanceAppNames,
      componentListStr: event.componentName,
      componentList,
      allInstanceList,
      instanceListStr,
      onlyInstanceString,
      leadToIncident,
      causedByChange,
      hasRootCause,
      hasTrailing,
      isImportant: event.isImportant || false,

      mergeKey,
      frequencyStr,
      hasMetricProject,
      anomalyFeatureStr,
      duration: event.endTimestamp - event.startTimestamp,

      componentName: event.componentName,
      projectDisplayName: projectInfo?.projectDisplayName,
      cloudType: projectInfo?.cloudType,
      id: `${event.id}-${eventIds[event.id].length}`,
      categoryList: [...categoryList, 'compositeAnomaly'],
    };
  }, eventList);
  eventList = sortFunction(eventList, null, null);
  return eventList;
};

const filterEventListTechnology = (state, filterList) => {
  const { componentFilter, instanceFilter, logicPodID, filterContainer, metricFilter, projectFilter } = state;
  const { typeFilter, patternIdFilter, timeFilterStart, timeFilterEnd, keywordSearch, showEnableRCA } = state;
  const { rootCauseFilter, incidentStatusFilter } = state;

  if (projectFilter) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find(
          (item) => R.includes(item.projectName, R.split(',', projectFilter)),
          event?.anomalyTimelines || [],
        );
      }
      return R.includes(event.projectName, R.split(',', projectFilter));
    }, filterList);
  }
  if (componentFilter) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find(
          (item) => R.includes(item.componentName, R.split(',', componentFilter)),
          event?.anomalyTimelines || [],
        );
      }
      return R.includes(event.componentName, R.split(',', componentFilter));
    }, filterList);
  }
  if (instanceFilter) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find(
          (item) =>
            R.includes(item.instanceListStr, R.split(',', instanceFilter)) ||
            R.includes(item.realInstanceName, R.split(',', instanceFilter)),
          event?.anomalyTimelines || [],
        );
      }
      return (
        R.includes(event.instanceListStr, R.split(',', instanceFilter)) ||
        R.includes(event.realInstanceName, R.split(',', instanceFilter))
      );
    }, filterList);
  }
  if (logicPodID) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find(
          (item) =>
            R.includes(item.instanceListStr, R.split(',', logicPodID)) ||
            R.includes(item.realInstanceName, R.split(',', logicPodID)),
          event?.anomalyTimelines || [],
        );
      }
      return (
        R.includes(event.instanceListStr, R.split(',', logicPodID)) ||
        R.includes(event.realInstanceName, R.split(',', logicPodID))
      );
    }, filterList);
  }
  if (filterContainer) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find(
          (item) => R.includes(item?.containerInfo?.containerName, R.split(',', filterContainer)),
          event?.anomalyTimelines || [],
        );
      }
      return R.includes(event?.containerInfo?.containerName, R.split(',', filterContainer));
    }, filterList);
  }
  if (metricFilter) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find(
          (item) =>
            R.difference(R.split(',', metricFilter), item.metricList).length !== R.split(',', metricFilter).length,
          event?.anomalyTimelines || [],
        );
      }
      return R.difference(R.split(',', metricFilter), event.metricList).length !== R.split(',', metricFilter).length;
    }, filterList);
  }
  if (typeFilter) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find(
          (item) =>
            item.typeList &&
            R.difference(R.split(',', typeFilter), item.typeList).length !== R.split(',', typeFilter).length,
          event?.anomalyTimelines || [],
        );
      }
      return (
        event.typeList &&
        R.difference(R.split(',', typeFilter), event.typeList).length !== R.split(',', typeFilter).length
      );
    }, filterList);
  }
  if (patternIdFilter) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find(
          (item) => R.includes(`${String(item.patternId)}/${item.patternName}`, R.split(',', patternIdFilter)),
          event?.anomalyTimelines || [],
        );
      }
      return R.includes(`${String(event.patternId)}/${event.patternName}`, R.split(',', patternIdFilter));
    }, filterList);
  }
  if (timeFilterStart && timeFilterEnd) {
    filterList = R.filter((event) => {
      return (
        (Number(timeFilterStart) <= moment.utc(event.startTimestamp).hours() &&
          moment.utc(event.startTimestamp).hours() <= Number(timeFilterEnd)) ||
        (Number(timeFilterStart) <= moment.utc(event.endTimestamp).hours() &&
          moment.utc(event.endTimestamp).hours() <= Number(timeFilterEnd)) ||
        (moment.utc(event.startTimestamp).hours() <= Number(timeFilterStart) &&
          Number(timeFilterEnd) <= moment.utc(event.endTimestamp).hours())
      );
    }, filterList);
  }
  if (keywordSearch) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find((item) => {
          if (item.generateFromGenericRule) {
            return !!R.find((_item) => {
              const { sourceDetail } = _item || {};
              const { type, content, metricName } = sourceDetail || {};
              return (
                (type !== 'Metric' && R.toLower(content).includes(R.toLower(keywordSearch))) ||
                (type === 'Metric' && R.toLower(metricName || content).includes(R.toLower(keywordSearch)))
              );
            }, item?.predictionSourceInfoList || []);
          }
          return (
            (item.category === 'log' && R.toLower(item.rawData).includes(R.toLower(keywordSearch))) ||
            (item.category === 'metric' && R.toLower(item.metricNameString).includes(R.toLower(keywordSearch)))
          );
        }, event?.anomalyTimelines || []);
      }
      if (event.generateFromGenericRule) {
        return !!R.find((item) => {
          const { sourceDetail } = item || {};
          const { type, content, metricName } = sourceDetail || {};
          return (
            (type !== 'Metric' && R.toLower(content).includes(R.toLower(keywordSearch))) ||
            (type === 'Metric' && R.toLower(metricName || content).includes(R.toLower(keywordSearch)))
          );
        }, event?.predictionSourceInfoList || []);
      }
      return (
        (event.category === 'log' && R.toLower(event.rawData).includes(R.toLower(keywordSearch))) ||
        (event.category === 'metric' && R.toLower(event.metricNameString).includes(R.toLower(keywordSearch)))
      );
    }, filterList);
  }
  if (showEnableRCA) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find((item) => item.isRCAFlag, event?.anomalyTimelines || []);
      }
      return event.isRCAFlag;
    }, filterList);
  }

  if (rootCauseFilter && !R.includes('all', R.split(',', rootCauseFilter))) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly && rootCauseFilter !== 'compositeAnomaly') {
        return !!R.find(
          (item) =>
            R.difference(R.split(',', rootCauseFilter), item.categoryList).length !==
            R.split(',', rootCauseFilter).length,
          event?.anomalyTimelines || [],
        );
      }
      return (
        R.difference(R.split(',', rootCauseFilter), event.categoryList).length !== R.split(',', rootCauseFilter).length
      );
    }, filterList);
  }

  if (incidentStatusFilter) {
    filterList = R.filter((event) => {
      if (event.isCompositeAnomaly) {
        return !!R.find(
          (item) =>
            R.difference(R.split(',', incidentStatusFilter), R.map(R.trim, R.split(',', item.incidentStatus)))
              .length !== R.split(',', incidentStatusFilter).length,
          event?.anomalyTimelines || [],
        );
      }
      return (
        R.difference(R.split(',', incidentStatusFilter), R.map(R.trim, R.split(',', event.incidentStatus))).length !==
        R.split(',', incidentStatusFilter).length
      );
    }, filterList);
  }

  return filterList;
};

const buildMergeData = (eventList, sortBy, sortDirection, self) => {
  const { allExpand, dateAllExpand } = self.state;
  const dateMap = {};
  R.forEach((event) => {
    const { startTimestamp } = event || {};
    const dateFormat = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
    if (!R.has(dateFormat, dateMap)) {
      dateMap[dateFormat] = {
        currentEvent: {
          id: moment.utc(startTimestamp).format(Defaults.DateFormat),
          dateFormat,
          isTimeRoot: true,
          isExpand: dateAllExpand,
          startTimestamp,
          dateEnFormat: moment.utc(startTimestamp).format(Defaults.ShortDayEnFormat),
        },
        timelines: [],
      };
    }
    dateMap[dateFormat].timelines.push(event);
  }, eventList || []);

  let dateMergerList = [];
  R.forEachObjIndexed((val) => {
    let count = 0;
    let logCount = 0;
    let mergeList = [];
    const mergeListKeys = [];
    let mergeListMap = {};
    R.forEach((event) => {
      if (event.isCompositeAnomaly) {
        count += event.anomalyTimelines ? event.anomalyTimelines.length : 0;
        R.forEach((item) => {
          logCount += item.count || 1;
        }, event.anomalyTimelines || []);
      } else {
        count += 1;
        logCount += event.count || 1;
      }

      const { mergeKey } = event;
      if (!R.has(mergeKey, mergeListMap)) {
        mergeListKeys.push(mergeKey);
        mergeListMap[mergeKey] = {
          currentEvent: { id: mergeKey, mergeKey, isRootEvent: true, isExpand: allExpand },
          timelines: [],
        };
      }
      mergeListMap[mergeKey].timelines.push(event);
    }, val.timelines || []);

    mergeListMap = R.mapObjIndexed((val) => {
      let rangeStartTimestamp = Infinity;
      let rangeEndTimestamp = 0;
      let hasImportant = false;
      let isIgnored = false;
      let isRCAFlag = false;
      let isNewAlert = false;
      let isIncident = false;
      let isFixedIncident = false;
      let leadToIncident = false;
      let causedByChange = false;
      let hasRootCause = false;
      let hasTrailing = false;
      let hasHistoricalIncidents = false;
      let typeList = [];
      let isTrace = false;
      let instanceStrList = [];
      let isPrediction = false;
      let isAllCompositeAnomaly = true;
      let hasCompositeAnomaly = false;
      let timelinesCount = 0;
      R.forEach((item) => {
        const { startTimestamp, endTimestamp, containerInfo } = item;
        rangeStartTimestamp = R.min(rangeStartTimestamp, startTimestamp);
        rangeEndTimestamp = R.max(rangeEndTimestamp, endTimestamp);
        hasImportant = hasImportant || item.isImportant;
        isIgnored = isIgnored || item.isIgnored;
        isRCAFlag = isRCAFlag || item.isRCAFlag;
        isNewAlert = isNewAlert || item.isNewAlert;
        isIncident = isIncident || item.isIncident;
        isFixedIncident = isFixedIncident || item.isFixedIncident;
        leadToIncident = leadToIncident || item.leadToIncident;
        causedByChange = causedByChange || item.causedByChange;
        hasRootCause = hasRootCause || item.hasRootCause;
        hasTrailing = hasTrailing || item.hasTrailing;
        hasHistoricalIncidents = hasHistoricalIncidents || item.hasHistoricalIncidents;
        typeList = [...typeList, ...item.typeList];
        isTrace = isTrace || item.isTrace;
        instanceStrList = [
          ...instanceStrList,
          item.instanceListStr,
          containerInfo ? `${containerInfo.containerName}_${item.instanceListStr}` : item.instanceListStr,
        ];
        isPrediction = isPrediction || item.isPrediction;
        if (isAllCompositeAnomaly) isAllCompositeAnomaly = item.isCompositeAnomaly;
        if (!hasCompositeAnomaly) hasCompositeAnomaly = item.isCompositeAnomaly;

        if (item.isCompositeAnomaly) {
          timelinesCount += item.anomalyTimelines ? item.anomalyTimelines.length : 0;
        } else {
          timelinesCount += 1;
        }
      }, val.timelines || []);
      const incident = val.timelines[0] || {};
      const {
        category,
        componentListStr,
        patternName,
        patternId,
        anomalyLogInstance,
        projectName,
        projectOwner,
        rawData,
        anomalyWords,
        outlierValue,
        rootCauseJson,
        componentName,
        projectDisplayName,
      } = incident;
      typeList = R.uniq(typeList);
      instanceStrList = R.uniq(instanceStrList);

      const newCurrentEvent = {
        ...val.currentEvent,
        count: timelinesCount,
        // time range
        startTimestamp: rangeStartTimestamp,
        endTimestamp: rangeEndTimestamp,
        // sample event
        category,
        componentListStr,
        patternName,
        patternId,
        anomalyLogInstance,
        projectName,
        projectOwner,
        rawData,
        anomalyWords,
        outlierValue,
        rootCauseJson,

        // flags
        hasImportant,
        isIgnored,
        isRCAFlag,
        isNewAlert,
        isIncident,
        isFixedIncident,
        leadToIncident,
        causedByChange,
        hasRootCause,
        hasTrailing,
        typeList,
        isTrace,
        componentName,
        instanceStrList,
        isPrediction,
        projectDisplayName,
        duration: rangeEndTimestamp - rangeStartTimestamp,
        isAllCompositeAnomaly,
        hasCompositeAnomaly,
      };
      return { ...val, currentEvent: newCurrentEvent };
    }, mergeListMap);

    // rebuild eventlist
    R.forEach((mergeKey) => {
      let childrenList = mergeListMap[mergeKey].timelines || [];
      childrenList = sortData(childrenList, sortBy, sortDirection);
      mergeList.push({
        ...mergeListMap[mergeKey].currentEvent,
        isExpand: allExpand,
        children: setChildrenTimes(childrenList),
        incidentStatus: R.find((item) => item.incidentStatus === 'in progress', childrenList)
          ? 2
          : R.find((item) => item.incidentStatus === 'closed', childrenList)
          ? 1
          : 0,
      });
    }, mergeListKeys);

    const newCurrentEvent = { ...val.currentEvent, count, logCount };
    mergeList = sortData(mergeList, sortBy, sortDirection);
    dateMergerList.push({ ...newCurrentEvent, children: mergeList });
  }, dateMap);

  dateMergerList = sortData(dateMergerList, sortBy, sortDirection);
  return dateMergerList;
};

const tidyEventList = (
  isJWT,
  self,
  anomalyEventList,
  compositeEventList,
  instanceComponentMap,
  credentials,
  getMappingProjectInfo,
  hasKubernetes,
) => {
  const { tabName, projects } = self.props;
  let eventList = [...anomalyEventList, ...compositeEventList];
  eventList = R.filter((event) => event.timeLineType !== 'future', eventList);
  const hasTableData = eventList.length > 0;

  const eventIds = {};
  let eventInstances = [];
  let eventProjectNameAll = [];
  let eventPatternIds = [];
  let ignoredEventPatternIds = [];
  const componentNameList = [];
  const instanceNameList = [];
  const eventPatternIdsNumMap = {};
  const ignoredEventPatternIdsNumMap = {};
  const incidentStatusCountMap = {};
  const projectAnomalies = {};
  const componentAnomalies = {};
  const instanceAnomalies = {};
  const podAnomalies = {};
  const containerAnomalies = {};
  let metricListAll = [];
  let filterEventsTotal = 0;
  let likelyRootCauseCount = 0;
  let noRootCauseCount = 0;
  let fixedIncidentCount = 0;
  let triggeredCount = 0;
  let importantCount = 0;
  let notificationCount = 0;
  let notValidSrcCount = 0;
  let validSrcCount = 0;
  let ignoredSrcCount = 0;
  let compositeAnomalyCount = 0;
  let recurrentIncidentCount = 0;
  eventList = R.addIndex(R.map)((event, idx) => {
    const projectInfo = R.find((_item) => _item.projectShortName === event.projectName, projects || []);
    if (!eventIds[event.id]) {
      eventIds[event.id] = [event.id];
    } else {
      eventIds[event.id].push(event.id);
    }

    const categoryList = [];

    const { rootCauseDetailsArr, allInstanceList, instanceAppNames, componentList, onlyInstanceString } =
      handleSeparately(event, instanceComponentMap);

    eventProjectNameAll = [
      ...eventProjectNameAll,
      {
        projectName:
          event.projectOwner !== credentials.userName
            ? `${event.projectName}@${event.projectOwner}`
            : event.projectName,
        category: event.category,
        noOwnerProject: event.projectName,
        projectOwner: event.projectOwner,
        projectDisplayName: event.projectDisplayName,
      },
    ];

    eventInstances = R.concat(eventInstances, [onlyInstanceString]);
    metricListAll = R.concat(metricListAll, event.metricList);

    const leadToIncident = get(event.rootCauseResultInfo, 'leadToIncident');
    const causedByChange = get(event.rootCauseResultInfo, 'causedByChangeEvent');
    let hasRootCause = get(event.rootCauseResultInfo, 'hasPrecedingEvent');
    const hasTrailing = get(event.rootCauseResultInfo, 'hasTrailingEvent');
    let hasCompositeRecurringIncident = false;

    if (event.isCompositeAnomaly) {
      event.anomalyTimelines = self.handleCompositeAnomalyTimelines(event?.anomalyTimelines || [], self.props);
      const findCompositeAnomalyRCA = R.find(
        (item) => get(item.rootCauseResultInfo, 'hasPrecedingEvent'),
        event.anomalyTimelines || [],
      );
      const findImportant = R.find((item) => get(item, 'isImportant'), event.anomalyTimelines || []);
      const findRecurringIncident = R.find(
        (item) => (item?.recurringIncidentTimes || []).length > 0,
        event.anomalyTimelines || [],
      );
      if (findCompositeAnomalyRCA) hasRootCause = true;
      if (findImportant) event.isImportant = true;
      if (findRecurringIncident) hasCompositeRecurringIncident = true;
    }

    if (event.isIgnored) {
      if (event.isCompositeAnomaly) {
        R.forEach((item) => {
          const patternKey = `${item.patternId}/${item.patternName}`;
          ignoredEventPatternIds.push({
            patternId: String(item.patternId),
            instanceName: item.instanceNameString,
            patternName: item.patternName,
          });
          if (!ignoredEventPatternIdsNumMap[patternKey]) {
            ignoredEventPatternIdsNumMap[patternKey] = 1;
          } else {
            ignoredEventPatternIdsNumMap[patternKey] += 1;
          }
        }, event.anomalyTimelines || []);
      } else {
        const patternKey = `${event.patternId}/${event.patternName}`;
        ignoredEventPatternIds.push({
          patternId: String(event.patternId),
          instanceName: event.instanceNameString,
          patternName: event.patternName,
        });
        if (!ignoredEventPatternIdsNumMap[patternKey]) {
          ignoredEventPatternIdsNumMap[patternKey] = 1;
        } else {
          ignoredEventPatternIdsNumMap[patternKey] += 1;
        }
      }
    }

    if (judgeViewEvent(event, tabName)) {
      if (event.isCompositeAnomaly) {
        R.forEach((item) => {
          const patternKey = `${item.patternId}/${item.patternName}`;

          if (!R.has(item.projectName, projectAnomalies)) projectAnomalies[item.projectName] = 0;
          projectAnomalies[item.projectName] += 1;

          if (item.componentName) componentNameList.push(item.componentName);
          if (!R.has(item.componentName, componentAnomalies)) componentAnomalies[item.componentName] = 0;
          componentAnomalies[item.componentName] += 1;

          if (item.instanceName)
            instanceNameList.push({
              projectName: item.projectName,
              projectOwner: item.projectOwner,
              instanceName: item.instanceName,
            });
          if (!R.has(item.instanceName, instanceAnomalies)) instanceAnomalies[item.instanceName] = 0;
          instanceAnomalies[item.instanceName] += 1;

          const isContainer = R.includes('_', item.instanceName || '');
          if (isContainer && item.instanceName) {
            const [c, p] = R.split('_', item.instanceName);
            if (!R.has(p, podAnomalies)) podAnomalies[p] = 0;
            podAnomalies[p] += 1;
            if (!R.has(c, containerAnomalies)) containerAnomalies[c] = 0;
            containerAnomalies[c] += 1;
          }

          eventPatternIds.push({
            patternId: String(item.patternId),
            instanceName: item.instanceNameString,
            patternName: item.patternName,
          });
          if (!eventPatternIdsNumMap[patternKey]) {
            eventPatternIdsNumMap[patternKey] = 1;
          } else {
            eventPatternIdsNumMap[patternKey] += 1;
          }
        }, event.anomalyTimelines || []);
      } else {
        const patternKey = `${event.patternId}/${event.patternName}`;

        if (!R.has(event.projectName, projectAnomalies)) projectAnomalies[event.projectName] = 0;
        projectAnomalies[event.projectName] += 1;

        if (event.componentName) componentNameList.push(event.componentName);
        if (!R.has(event.componentName, componentAnomalies)) componentAnomalies[event.componentName] = 0;
        componentAnomalies[event.componentName] += 1;

        if (event.instanceName)
          instanceNameList.push({
            projectName: event.projectName,
            projectOwner: event.projectOwner,
            instanceName: event.instanceName,
          });
        if (!R.has(event.instanceName, instanceAnomalies)) instanceAnomalies[event.instanceName] = 0;
        instanceAnomalies[event.instanceName] += 1;

        const isContainer = R.includes('_', event.instanceName || '');
        if (isContainer && event.instanceName) {
          const [c, p] = R.split('_', event.instanceName);
          if (!R.has(p, podAnomalies)) podAnomalies[p] = 0;
          podAnomalies[p] += 1;
          if (!R.has(c, containerAnomalies)) containerAnomalies[c] = 0;
          containerAnomalies[c] += 1;
        }

        eventPatternIds.push({
          patternId: String(event.patternId),
          instanceName: event.instanceNameString,
          patternName: event.patternName,
        });
        if (!eventPatternIdsNumMap[patternKey]) {
          eventPatternIdsNumMap[patternKey] = 1;
        } else {
          eventPatternIdsNumMap[patternKey] += 1;
        }
      }

      if (event.isCompositeAnomaly) {
        R.forEach((item) => {
          if (!incidentStatusCountMap[item.incidentStatus]) {
            incidentStatusCountMap[item.incidentStatus] = 1;
          } else {
            incidentStatusCountMap[item.incidentStatus] += 1;
          }
        }, event.anomalyTimelines || []);
      } else if (!incidentStatusCountMap[event.incidentStatus]) {
        incidentStatusCountMap[event.incidentStatus] = 1;
      } else {
        incidentStatusCountMap[event.incidentStatus] += 1;
      }
    }

    if (event.isIgnored) {
      if (event.isCompositeAnomaly) {
        R.forEach((item) => {
          if (item.isIgnored) ignoredSrcCount += 1;
        }, event.anomalyTimelines || []);
      } else {
        ignoredSrcCount += 1;
        categoryList.push('ignoredSrc');
      }
    }
    if (judgeViewEvent(event, tabName)) {
      if (event.isCompositeAnomaly) {
        R.forEach((item) => {
          const hasRootCause = get(item.rootCauseResultInfo, 'hasPrecedingEvent');
          if (judgeViewEvent(item, tabName)) {
            filterEventsTotal += 1;

            if (item.isImportant) importantCount += 1;

            if ((item?.recurringIncidentTimes || []).length > 0) recurrentIncidentCount += 1;

            if (hasRootCause) likelyRootCauseCount += 1;

            if (!hasRootCause) noRootCauseCount += 1;

            if (item.isFixedIncident) fixedIncidentCount += 1;

            if ((item?.triggeredActionList || []).length > 0) triggeredCount += 1;

            if (item.isNotified) notificationCount += 1;

            if (!item.notValidSrc) validSrcCount += 1;

            if (item.notValidSrc) notValidSrcCount += 1;
          }
        }, event.anomalyTimelines || []);
      } else {
        filterEventsTotal += 1;

        if (event.isImportant) {
          importantCount += 1;
          categoryList.push('important');
        }

        if ((event?.recurringIncidentTimes || []).length > 0) {
          recurrentIncidentCount += 1;
          categoryList.push('recurrentIncident');
        }

        if (hasRootCause) {
          likelyRootCauseCount += 1;
          categoryList.push('hasRootCause');
        }

        if (!hasRootCause) {
          noRootCauseCount += 1;
          categoryList.push('noRootCause');
        }

        if (event.isFixedIncident) {
          fixedIncidentCount += 1;
          categoryList.push('fixedIncident');
        }

        if ((event?.triggeredActionList || []).length > 0) {
          triggeredCount += 1;
          categoryList.push('triggered');
        }

        if (event.isNotified) {
          notificationCount += 1;
          categoryList.push('notification');
        }

        if (!event.notValidSrc) {
          validSrcCount += 1;
          categoryList.push('validSrc');
        }

        if (event.notValidSrc) {
          notValidSrcCount += 1;
          categoryList.push('notValidSrc');
        }
      }

      if (event.isCompositeAnomaly) {
        compositeAnomalyCount += 1;
        categoryList.push('compositeAnomaly');
      }
    }

    const mergeKey = `${event.projectName}-${event.componentName}-${event.patternId}-${event.patternName}`;

    let metricRootCauseObj;
    let frequencyStr = '';
    try {
      metricRootCauseObj = JSON.parse(event.metricRootCause);
    } catch (e) {
      // console.debug(error)
    }

    if (metricRootCauseObj && event.category.toLowerCase() === 'log') {
      const { anomalyValue, percentage, sign } = metricRootCauseObj;
      const percent = `${Math.abs(percentage).toFixed(2)}%`;
      frequencyStr = `${
        isNumber(anomalyValue) ? `Count: ${anomalyValue}. ` : ''
      }Frequency is ${percent} ${sign} than normal.`;
    }

    let anomalyFeatureStr;
    if (!R.isEmpty(event.anomalyFeatureStr) && event.anomalyFeatureStr && event.category.toLowerCase() === 'log') {
      anomalyFeatureStr = R.map((item) => {
        const [label] = item.label.split(':');
        const name = get(item, 'name');
        return { label: name || label, value: item.value };
      }, event.anomalyFeatureStr);
      anomalyFeatureStr = R.sort(R.ascend(R.prop('label')), anomalyFeatureStr);
      const featureGroup = R.groupBy((item) => item.label)(anomalyFeatureStr);

      anomalyFeatureStr = R.map((item) => item, R.toPairs(featureGroup));
    }

    const instanceListStr = event.containerInfo ? event.containerInfo.instanceName : event.instanceNameString;
    const mappingProjectInfo = getMappingProjectInfo({ event: { ...event, instanceListStr } });
    const hasMetricProject = tabName === 'metric' ? true : mappingProjectInfo.dataType === 'Metric';

    return {
      ...event,
      rootCauseJson: { rootCauseDetailsArr },
      appName: R.join(', ', instanceAppNames),
      instanceAppNames,
      componentListStr: event.componentName,
      componentList,
      allInstanceList,
      instanceListStr,
      onlyInstanceString,
      leadToIncident,
      causedByChange,
      hasRootCause,
      hasTrailing,
      isImportant: event.isImportant || false,

      mergeKey,
      frequencyStr,
      hasMetricProject,
      anomalyFeatureStr,
      duration: event.endTimestamp - event.startTimestamp,
      categoryList,

      componentName: event.componentName,
      projectDisplayName: projectInfo?.projectDisplayName || event.projectName,
      cloudType: projectInfo?.cloudType || '',
      id: `${event.id}-${eventIds[event.id].length}`,
      hasCompositeRecurringIncident,
    };
  }, eventList);

  eventProjectNameAll = R.filter(
    (item) => Boolean(item),
    R.uniqBy((item) => `${item?.projectName}-${item?.category}`, eventProjectNameAll || []),
  );

  const eventListExport = R.filter((e) => judgeViewEvent(e, tabName), eventList);

  const projectListOptions = R.map(
    (item) => ({
      ...item,
      value: item.noOwnerProject,
      label: item.noOwnerProject,
      dataType: R.find((project) => project.projectName === item.projectName, projects || [])?.dataType,
      projectDisplayName: item.projectDisplayName || item.noOwnerProject,
      count: projectAnomalies[item.noOwnerProject] || 0,
    }),
    eventProjectNameAll,
  );

  const componentListOptions = R.sortWith(
    [R.descend(R.prop('count')), R.ascend(R.prop('label'))],
    R.map((c) => ({ label: c, value: c, count: componentAnomalies[c] || 0 }), R.uniq(componentNameList)),
  );

  let instanceOption = [];
  let podOptions = [];
  let containerOption = [];
  R.forEach(
    (ins) => {
      const { instanceName: insKey, ...rest } = ins || {};
      const isContainer = R.includes('_', insKey);
      if (isContainer) {
        const [c, i] = R.split('_', insKey);
        instanceOption.push({ ...rest, value: i, label: i });
        podOptions.push({ ...rest, value: i, label: i });
        containerOption.push({ value: c, label: c });
      } else {
        instanceOption.push({ ...rest, value: insKey, label: insKey });
      }
    },
    R.uniqBy((item) => item.instanceName, instanceNameList),
  );
  instanceOption = R.uniqBy((item) => item.value, instanceOption);
  podOptions = R.uniqBy((item) => item.value, podOptions);
  containerOption = R.uniqBy((item) => item.value, containerOption);
  if (hasKubernetes) {
    instanceOption = R.differenceWith((x, y) => x.value === y.value, instanceOption, podOptions);
  }
  const instanceListOptions = R.sortWith(
    [R.descend(R.prop('count'))],
    R.map((item) => ({ ...item, count: instanceAnomalies[item.value] || 0 }), instanceOption),
  );
  const podListOptions = R.sortWith(
    [R.descend(R.prop('count'))],
    R.map((item) => ({ ...item, count: podAnomalies[item.value] || 0 }), podOptions),
  );
  const containerListOptions = R.sortWith(
    [R.descend(R.prop('count'))],
    R.map((item) => ({ ...item, count: containerAnomalies[item.value] || 0 }), containerOption),
  );

  let anomalyMetricOptions = R.map(
    (metric) => ({ value: metric, label: metric }),
    R.filter((item) => Boolean(item), R.uniq(metricListAll)),
  );
  anomalyMetricOptions = R.sortWith([R.ascend(R.prop('label'))], anomalyMetricOptions);

  eventPatternIds = R.uniqBy((item) => `${item.patternId}/${item.patternName}`, eventPatternIds);
  let patternIdFilterOptions = R.map((item) => {
    const patternKey = `${item.patternId}/${item.patternName}`;
    return {
      value: patternKey,
      label: `${item.patternId} / ${item.patternName}`,
      count: eventPatternIdsNumMap[patternKey] || 1,
      instanceName: item.instanceName,
      patternId: item.patternId,
      patternName: item.patternName,
    };
  }, eventPatternIds);
  patternIdFilterOptions = R.sortWith([R.ascend(R.prop('label'))], patternIdFilterOptions);
  patternIdFilterOptions = R.sortWith([R.descend(R.prop('count'))], patternIdFilterOptions);

  ignoredEventPatternIds = R.uniqBy((item) => `${item.patternId}/${item.patternName}`, ignoredEventPatternIds);
  let ignoredPatternIdFilterOptions = R.map((item) => {
    const patternKey = `${item.patternId}/${item.patternName}`;
    return {
      value: patternKey,
      label: `${item.patternId} / ${item.patternName}`,
      count: ignoredEventPatternIdsNumMap[patternKey] || 1,
      instanceName: item.instanceName,
      patternId: item.patternId,
      patternName: item.patternName,
    };
  }, ignoredEventPatternIds);
  ignoredPatternIdFilterOptions = R.sortWith([R.ascend(R.prop('label'))], ignoredPatternIdFilterOptions);
  ignoredPatternIdFilterOptions = R.sortWith([R.descend(R.prop('count'))], ignoredPatternIdFilterOptions);

  return {
    eventList,
    hasTableData,
    eventProjectNameAll,
    eventListExport,
    incidentStatusCountMap,
    projectListOptions,
    componentListOptions,
    instanceListOptions,
    podListOptions,
    containerListOptions,
    anomalyMetricOptions,
    patternIdFilterOptions,
    ignoredPatternIdFilterOptions,
    filterEventsTotal,
    likelyRootCauseCount,
    noRootCauseCount,
    fixedIncidentCount,
    triggeredCount,
    importantCount,
    notificationCount,
    notValidSrcCount,
    compositeAnomalyCount,
    validSrcCount,
    ignoredSrcCount,
    recurrentIncidentCount,
  };
};

const chartPatternRender = (rowData, self) => {
  const { activeKey } = self.props;
  const { patternId, patternName } = rowData;

  const patternIdAndName = Defaults.PatternIdNameStr({ patternName, patternId }, {}).patternNameStr;
  const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
  const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });
  return (
    <>
      {activeKey === 'incident' && patternIcon}
      <div className="flex-grow hidden-line-with-ellipsis">
        <div className="hidden-line-with-ellipsis">{`[${patternId}]`}</div>
        <div className="hidden-line-with-ellipsis">{patternIdAndName}</div>
      </div>
    </>
  );
};

const chartListHeader = (peersEventList = [], self) => {
  const { intl, tabName } = self.props;
  return (
    <div
      className="event-list-header"
      style={{ height: 40, flexShrink: 0, paddingRight: peersEventList.length > 6 ? 8 : 0 }}
    >
      <div className="header-column" style={{ width: 150 }}>
        {intl.formatMessage(tabName === 'incident' ? eventMessages.incidentPattern : eventMessages.fieldAnomalyPattern)}
      </div>
      <div className="header-column" style={{ width: 100, flex: 1 }}>
        {intl.formatMessage(eventMessages.impacted)}
      </div>
      <div className="header-column" style={{ width: 106 }}>
        {intl.formatMessage(eventMessages.startTime)}
      </div>
      <div className="header-column" style={{ width: 106 }}>
        {intl.formatMessage(eventMessages.endTime)}
      </div>
      <div className="header-column flex-center-justify" style={{ width: 80 }}>
        {`${intl.formatMessage(eventMessages.count)}`}
      </div>
    </div>
  );
};

const chartImpactedRender = (rowData, self, instanceDisplayNameMap) => {
  const { intl } = self.props;
  const { isCompositeAnomaly, projectName, projectOwner } = rowData;
  let { containerInfo, instanceListStr } = rowData;
  if (isCompositeAnomaly) {
    return (
      <div className="flex-row flex-center-align">
        <div style={{ marginRight: 4 }}>
          <ApartmentOutlined style={{ fontSize: 14, marginRight: 8, color: 'var(--primary-color)' }} />
        </div>
        {`(${intl.formatMessage(eventMessages.compositeIncident)})`}
      </div>
    );
  }

  if (!instanceListStr) {
    return <div />;
  }

  if (!containerInfo && R.includes('_', instanceListStr)) {
    containerInfo = {
      containerName: R.split('_', instanceListStr)[0],
      instanceName: R.split('_', instanceListStr)[1],
    };
    instanceListStr = R.split('_', instanceListStr)[1];
  }

  const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, instanceListStr, {
    pn: projectName,
    owner: projectOwner,
  });

  return (
    <>
      <div className="hidden-line-with-ellipsis">{containerInfo ? containerInfo.containerName : ''}</div>
      <div className="hidden-line-with-ellipsis">{instanceDisplayName || instanceListStr}</div>
    </>
  );
};

const chartListItem = (rowData, idx, instanceDisplayNameMap, self) => {
  if (!rowData) return null;
  const { key, isRootEvent, componentName, startTimestamp, endTimestamp, count } = rowData;

  return (
    <div key={`${key}-${idx}`} style={{ height: 50 }} className="event-list">
      {isRootEvent && (
        <div
          className="event-list-category-row flex-row flex-center-align"
          style={{ height: 50 - 1, backgroundColor: 'var(--item-active-bg2)' }}
        >
          <div className="row-column flex-row flex-center-align" style={{ padding: '0 6px', width: 150 }}>
            {chartPatternRender(rowData, self)}
          </div>
          <div className="row-column hidden-line-with-ellipsis" style={{ padding: '0 6px', width: 100, flex: 1 }}>
            {componentName}
          </div>
          <div className="row-column flex-row flex-center-align" style={{ padding: '0 6px', width: 106 }} />
          <div className="row-column flex-row flex-center-align" style={{ padding: '0 6px', width: 106 }} />
          <div
            className="row-column flex-row flex-center-align flex-center-justify"
            style={{ padding: '0 6px', width: 80 }}
          >
            {count}
          </div>
        </div>
      )}
      {!isRootEvent && (
        <div
          className="incident-event-list-row flex-row flex-center-align"
          style={{ height: 50 - 1, borderBottom: '1px solid var(--virtualized-table-border-color)' }}
        >
          <div className="row-column" style={{ padding: '0 6px', width: 150 }} />
          <div className="row-column overflow-hidden" style={{ padding: '0 6px', width: 100, flex: 1 }}>
            {chartImpactedRender(rowData, self, instanceDisplayNameMap)}
          </div>
          <div className="row-column" style={{ padding: '0 6px', width: 106, whiteSpace: 'normal' }}>
            {moment.utc(startTimestamp).format(Defaults.ShortDateTimeFormat)}
          </div>
          <div className="row-column" style={{ padding: '0 6px', width: 106, whiteSpace: 'normal' }}>
            {moment.utc(endTimestamp).format(Defaults.ShortDateTimeFormat)}
          </div>
          <div className="row-column flex-center-justify" style={{ padding: '0 6px', width: 80 }} />
        </div>
      )}
    </div>
  );
};

const getChartOption = (self, eventList, allDayAnomalyList, filterTimestamps, instanceDisplayNameMap) => {
  const { intl } = self.props || {};
  const anomalyScoresMap = {};
  R.forEach((timestamp) => {
    anomalyScoresMap[timestamp] = { timestamp, incidentList: [], value: 0 };
  }, allDayAnomalyList || []);

  R.forEach((item) => {
    const { startTimestamp, isCompositeAnomaly, anomalyTimelines } = item;
    const incidentTimestamp = R.find((time) => time <= startTimestamp, filterTimestamps);
    const anomalyScoresItem = anomalyScoresMap[incidentTimestamp];
    if (!anomalyScoresItem) return;
    anomalyScoresItem.incidentList.push({ ...item });
    if (isCompositeAnomaly) {
      anomalyScoresItem.value += (anomalyTimelines || []).length;
    } else {
      anomalyScoresItem.value += 1;
    }
  }, eventList || []);

  return {
    backgroundColor: 'transparent',
    animation: false,
    grid: { top: 10, left: 60, right: 30, bottom: 40 },
    tooltip: {
      backgroundColor: 'var(--component-background)',
      borderColor: 'transparent',
      trigger: 'axis',
      enterable: true,
      appendToBody: true,
      position: (pos, params, dom, rect, size) => {
        const isRight = size.viewSize[0] / 2 < pos[0];
        const boxWidth = size.contentSize[0];
        const pointX = pos[0] - (isRight ? boxWidth : 0);
        return [pointX, size.viewSize[1] - 20];
      },
      formatter: (params, ticket, callback) => {
        const { data } = params[0];
        const { timestamp, value, incidentList } = data || {};
        const eventList = R.sortWith([R.ascend(R.prop('startTimestamp'))], incidentList || []);
        const mergedList = buildMergeData(eventList, null, null, self);
        let peersEventList = [];
        R.forEach((item) => {
          if (item.children && item.children.length > 0) peersEventList = [...peersEventList, item, ...item.children];
        }, mergedList[0]?.children || []);

        return ReactDOMServer.renderToStaticMarkup(
          <div className="flex-col" style={{ width: 700, minHeight: 200, maxHeight: 400 }}>
            <div>{moment.utc(timestamp).format(Defaults.ShortTimeFormat)}</div>
            <div>{`${intl.formatMessage(appFieldsMessages.count)}: ${numeral(value).format('0,0')}`}</div>
            <div className="flex-grow flex-col event-list overflow-hidden">
              {chartListHeader(peersEventList, self)}
              <div
                className="flex-grow overflow-y-auto"
                style={{ border: '1px solid var(--virtualized-table-border-color)', borderTop: 'none' }}
              >
                {R.addIndex(R.map)(
                  (item, idx) => chartListItem(item, idx, instanceDisplayNameMap, self),
                  peersEventList,
                )}
              </div>
            </div>
          </div>,
        );
      },
      textStyle: {
        color: 'var(--text-color)',
      },
    },
    toolbox: {
      show: true,
      showTitle: true,
      itemGap: 10,
      left: 0,
      top: 30,
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
          icon: null,
          title: { zoom: 'Zoom', back: 'Back' },
          brushStyle: { color: 'gray', opacity: 0.4 },
        },
        restore: {
          title: '',
          icon: `path://${RestoreSvgPath}`,
          emphasis: {
            iconStyle: {
              textPosition: 'top',
              textAlign: 'left',
              textPadding: [0, 0, 10, 0],
            },
          },
        },
      },
    },
    xAxis: {
      type: 'category',
      data: allDayAnomalyList,
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        formatter: (value) =>
          `${moment.utc(Number(value)).format(Defaults.ShortDayFormat)}\n${moment
            .utc(Number(value))
            .format(Defaults.TimeOnlyFormat)}`,
        textStyle: { color: 'gray' },
      },
    },
    yAxis: {
      type: 'value',
      axisLine: { show: true },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: { textStyle: { color: 'gray' } },
    },
    series: [
      {
        name: 'Prediction',
        type: 'bar',
        data: R.values(anomalyScoresMap),
        itemStyle: {
          color: chartBarColor,
        },
      },
    ],
  };
};

const setChartRef = (systemChartRef, systemId, chart) => {
  systemChartRef[systemId] = chart;
};

const getDataChartZoom = (self) => {
  const { tabName } = self.props;
  const { zoomStartTime, zoomEndTime, events: eventList, sortBy, sortDirection } = self.state;
  const events = R.filter((item) => {
    if (zoomStartTime && zoomEndTime) {
      return zoomStartTime <= item.startTimestamp && item.startTimestamp < zoomEndTime;
    } else {
      return true;
    }
  }, eventList || []);
  self.setState({ allExpand: tabName === 'incident', dateAllExpand: true }, () => {
    const filterList = self.filterData(events);
    const mergedList = buildMergeData(filterList, sortBy, sortDirection, self);
    self.setState(
      {
        filterList,
        eventList: mergedList,
        jumpIncident: null,
      },
      async () => {
        if (self.refList) await self.refList.scrollToPosition(1);
        if (self.refList) self.refList.scrollToPosition(1);
      },
    );
  });
};

const onSystemChartClick = (self, systemId) => {
  return (item) => {
    const { tabName } = self.props;
    const { sortBy, sortDirection, chartOption } = self.state;
    const chartRef = self.systemChartRef[systemId];
    const { data, dataIndex } = item || {};
    const { timestamp } = data || {};
    let { incidentList = [] } = data || {};
    incidentList = R.sortWith([R.ascend(R.prop('startTimestamp'))], incidentList || []);

    const newChartOption = { ...chartOption };
    newChartOption.series[0].itemStyle = {
      ...newChartOption.series[0].itemStyle,
      color: (params) => {
        return params.dataIndex === dataIndex ? chartBarActiveColor : chartBarColor;
      },
    };
    chartRef.getEchartsInstance().setOption(newChartOption);

    self.setState(
      {
        allExpand: tabName === 'incident',
        dateAllExpand: true,
        zoomStartTime: timestamp,
        zoomEndTime: timestamp + 10 * 60 * 1000 * 2,
      },
      () => {
        const mergedList = buildMergeData(incidentList, sortBy, sortDirection, self);
        self.setState(
          {
            filterList: incidentList,
            eventList: mergedList,
            jumpIncident: null,
          },
          async () => {
            if (self.refList) await self.refList.scrollToPosition(1);
            if (self.refList) self.refList.scrollToPosition(1);
          },
        );
      },
    );
  };
};

const handleChartZoom = (self, systemId) => {
  return (evt) => {
    const { chartOption } = self.state;
    let zoomStartTime;
    let zoomEndTime;

    const chartRef = self.systemChartRef[systemId];
    if (chartRef) {
      const myChart = chartRef.getEchartsInstance();
      const { batch } = evt || {};
      if (batch && batch.length > 0) {
        const { startValue, endValue } = batch[0] || {};
        if (!R.isNil(startValue) && !R.isNil(endValue)) {
          const axis = myChart.getModel().option.xAxis[0];
          const rangeData = [];
          R.addIndex(R.forEach)((time, index) => {
            if (index >= startValue && index <= endValue) {
              rangeData.push(time);
            }
          }, axis.data);
          if (rangeData.length >= 2) {
            zoomStartTime = rangeData[0];
            zoomEndTime = rangeData[rangeData.length - 1] + 10 * 60 * 1000;
          } else {
            zoomStartTime = rangeData[0];
            zoomEndTime = zoomStartTime + 10 * 60 * 1000;
          }

          const newChartOption = { ...chartOption };
          newChartOption.series[0].itemStyle = {
            ...newChartOption.series[0].itemStyle,
            color: (params) => chartBarColor,
          };
          chartRef.getEchartsInstance().setOption(newChartOption);

          self.setState({ zoomStartTime, zoomEndTime }, () => {
            getDataChartZoom(self);
          });
        }
      }
    }
  };
};

const handleChartRestore = (self, systemId) => {
  return () => {
    const { chartOption } = self.state;
    const chartRef = self.systemChartRef[systemId];
    if (chartRef) {
      const myChart = chartRef.getEchartsInstance();

      // active the dataZoom
      myChart.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: true,
      });
      self.setState({ chartOption, isResetDateZoom: true, zoomStartTime: undefined, zoomEndTime: undefined }, () => {
        getDataChartZoom(self);
      });
    }
  };
};

const handleChartFinish = (self, systemId) => {
  return () => {
    const { isResetDateZoom } = self.state;
    if (isResetDateZoom) {
      self.setState({ isResetDateZoom: false }, () => {
        try {
          const chartRef = self.systemChartRef[systemId];
          if (chartRef) {
            const myChart = chartRef.getEchartsInstance();

            // active the dataZoom
            myChart.dispatchAction({
              type: 'takeGlobalCursor',
              key: 'dataZoomSelect',
              dataZoomSelectActive: true,
            });
          }
        } catch (err) {
          // console.error(err);
        }
      });
    }
  };
};

const customizeRestore = (self, systemId) => {
  const { chartOption } = self.state;
  const chartRef = self.systemChartRef[systemId];
  if (chartRef) {
    const myChart = chartRef.getEchartsInstance();

    const newChartOption = { ...chartOption };
    newChartOption.series[0].itemStyle = {
      ...newChartOption.series[0].itemStyle,
      color: (params) => chartBarColor,
    };
    chartRef.getEchartsInstance().setOption(newChartOption, true);

    // active the dataZoom
    myChart.dispatchAction({
      type: 'takeGlobalCursor',
      key: 'dataZoomSelect',
      dataZoomSelectActive: true,
    });

    self.setState({ isResetDateZoom: true, zoomStartTime: undefined, zoomEndTime: undefined }, () => {
      getDataChartZoom(self);
    });
  }
};

const headerClick = (name, self) => {
  return (e) => {
    e.stopPropagation();
    const { sortBy, sortDirection } = self.state;
    let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
    if (name !== sortBy) {
      sortDir = 'ASC';
    }
    if (name) {
      self.setState({ sortBy: name, sortDirection: sortDir, isLoadingParserData: true }, () => {
        const { filterList } = self.state;
        const mergedList = buildMergeData(filterList, name, sortDir, self);
        self.setState({ eventList: mergedList, isLoadingParserData: false }, () => {
          self.cellMeasureCache.clearAll();
          if (self.refList) self.refList.forceUpdateGrid();
          self.forceUpdate();
        });
      });
    }
  };
};

const reducerHandleEvents = (eventList) => {
  let peersEventList = [];
  let eventCount = 0;
  let totalLogCount = 0;
  R.forEach((event) => {
    eventCount += event.count || 0;
    totalLogCount += event.logCount || 0;
    if (event.isExpand && event.children && event.children.length > 0) {
      let dateChildren = [];
      R.forEach((item) => {
        item.lastChildren = R.last(item?.children || []) || {};
        if (item.isExpand && item.children && item.children.length > 0) {
          dateChildren = [...dateChildren, item, ...item.children];
        } else {
          dateChildren = [...dateChildren, item];
        }
      }, event.children || []);
      peersEventList = [...peersEventList, event, ...dateChildren];
    } else {
      peersEventList = [...peersEventList, event];
    }
  }, eventList || []);

  return { peersEventList, eventCount, totalLogCount };
};

const headerAllExpandClick = (e, self) => {
  e.stopPropagation();
  e.preventDefault();

  const { allExpand, eventList } = self.state;
  R.forEach((item) => {
    if (item.children && item.children.length > 0) {
      item.isExpand = true;
      R.forEach((_item) => {
        if (_item.children && _item.children.length > 0) {
          _item.isExpand = !allExpand;
        }
      }, item.children);
    }
  }, eventList);
  self.setState({ allExpand: !allExpand, dateAllExpand: true }, () => {
    self.cellMeasureCache.clearAll();
    if (self.refList) self.refList.forceUpdateGrid();
    self.forceUpdate();
  });
};

const handleCategoryClick = ({ dateIdx, rootEventIdx, rowData, needHanderDateRoot, self }) => {
  const { isExpand } = rowData;
  const { eventList } = self.state;

  const newEventList = [...(eventList || [])];
  if (needHanderDateRoot) {
    newEventList[dateIdx].isExpand = !isExpand;
  }
  if (newEventList[dateIdx]?.children[rootEventIdx]) {
    newEventList[dateIdx].children[rootEventIdx].isExpand = !isExpand;
  }
  if (newEventList[dateIdx]?.children[rootEventIdx]?.isExpand) {
    self.fatherEvent = rowData;
  }

  self.setState({ eventList: newEventList }, () => {
    self.cellMeasureCache.clearAll();
    if (self.refList) self.refList.forceUpdateGrid();
    self.forceUpdate();
  });
};

const positionEvent = ({ composite, self }) => {
  const { eventList = [], allExpand, dateAllExpand } = self.state;
  const { fatherEvent, childEvent, rowMinHeight, dateHeight } = self;

  if (!fatherEvent) {
    self.cellMeasureCache.clearAll();
    if (self.refList) {
      self.refList.forceUpdateGrid();
      self.refList.scrollToPosition(0);
    }
    self.forceUpdate();
    return;
  }

  const dateEventIdx = R.findIndex(
    (event) => event.id === moment.utc(fatherEvent?.startTimestamp).format(Defaults.DateFormat),
    eventList,
  );

  if (dateEventIdx === -1) {
    self.cellMeasureCache.clearAll();
    if (self.refList) {
      self.refList.forceUpdateGrid();
      self.refList.scrollToPosition(0);
    }
    self.forceUpdate();
    return;
  }

  const findFatherEventIdx = R.findIndex(
    (event) => event.mergeKey === fatherEvent?.mergeKey,
    eventList[dateEventIdx]?.children || [],
  );

  if (findFatherEventIdx === -1) {
    self.cellMeasureCache.clearAll();
    if (self.refList) {
      self.refList.forceUpdateGrid();
      self.refList.scrollToPosition(0);
    }
    self.forceUpdate();
    return;
  }

  let positionTopNum = 0;
  const findChildEventIdx = R.findIndex(
    (event) => event.id === childEvent?.id,
    eventList[dateEventIdx]?.children[findFatherEventIdx]?.children || [],
  );

  if (allExpand) {
    const dateEvents = R.take(dateEventIdx, eventList);
    const mergerEvents = [];
    R.forEach((event) => {
      if (event.children && event.children.length > 0) {
        mergerEvents.push(...event.children);
        R.forEach((item) => {
          if (item.children && item.children.length > 0) mergerEvents.push(...item.children);
        }, event.children);
      }
    }, dateEvents || []);

    const fatherEvents = R.take(findFatherEventIdx, eventList[dateEventIdx]?.children || []);
    const childEvents = [];
    R.forEach((event) => {
      if (event.children && event.children.length > 0) childEvents.push(...event.children);
    }, fatherEvents || []);

    positionTopNum =
      (dateEventIdx + 1) * dateHeight +
      mergerEvents.length * rowMinHeight +
      findFatherEventIdx * rowMinHeight +
      childEvents.length * rowMinHeight;
  } else if (!allExpand && dateAllExpand) {
    const dateEvents = R.take(dateEventIdx, eventList);
    const mergerEvents = [];
    R.forEach((event) => {
      if (event.children && event.children.length > 0) mergerEvents.push(...event.children);
    }, dateEvents || []);

    handleCategoryClick({
      dateIdx: dateEventIdx,
      rootEventIdx: findFatherEventIdx,
      rowData: eventList[dateEventIdx]?.children[findFatherEventIdx] || {},
      self,
    });

    positionTopNum =
      (dateEventIdx + 1) * dateHeight + mergerEvents.length * rowMinHeight + findFatherEventIdx * rowMinHeight;
  } else {
    handleCategoryClick({
      dateIdx: dateEventIdx,
      rootEventIdx: findFatherEventIdx,
      rowData: eventList[dateEventIdx]?.children[findFatherEventIdx] || {},
      needHanderDateRoot: true,
      self,
    });
    positionTopNum = (dateEventIdx + 1) * dateHeight + findFatherEventIdx * rowMinHeight;
  }

  if (findChildEventIdx !== -1) positionTopNum += findChildEventIdx * rowMinHeight + rowMinHeight;

  let newState = {};
  if (composite) {
    newState = { showIndividualIncidentsModal: true, actionComposite: childEvent };
  }

  self.setState({ jumpIncident: childEvent, ...newState }, async () => {
    if (self.refList) await self.refList.scrollToPosition(positionTopNum + 5);
    if (self.refList) self.refList.scrollToPosition(positionTopNum - 5);
  });
};

const getJumpFlag = ({ event, props, isFather, childDouble, individualIncident, isJWT }) => {
  const { location } = props || {};
  let eventProjectName;
  let eventPatternId;
  let eventTimestamp;
  let eventComponentName;
  let eventInstanceName;
  let eventPatternType;
  let eventRootCauseMetric;

  if (isJWT) {
    eventPatternType = props?.eventPatternType;
    eventPatternId = props?.eventPatternId;
    eventProjectName = props?.eventProjectName;
    eventInstanceName = props?.eventInstanceName;
    eventTimestamp = props?.eventTimestamp;
  } else {
    const query = parseLocation(location);
    eventProjectName = query?.eventProjectName;
    eventPatternId = query?.eventPatternId;
    eventTimestamp = query?.eventTimestamp;
    eventComponentName = query?.eventComponentName;
    eventInstanceName = query?.eventInstanceName;
    eventPatternType = query?.eventPatternType;
    eventRootCauseMetric = query?.eventRootCauseMetric;
  }

  if (isFather) {
    return (
      event.projectName === eventProjectName &&
      event.patternId === Number(eventPatternId) &&
      (!eventComponentName || (eventComponentName && event.componentName === eventComponentName)) &&
      (!eventInstanceName || (eventInstanceName && R.includes(eventInstanceName, event.instanceStrList || []))) &&
      event.startTimestamp <= Number(eventTimestamp)
    );
  }

  if (childDouble) {
    return (
      !event.isRootEvent &&
      event.projectName === eventProjectName &&
      event.patternId === Number(eventPatternId) &&
      (!eventComponentName || (eventComponentName && event.componentName === eventComponentName)) &&
      (!eventInstanceName || (eventInstanceName && event.allInstanceList.includes(eventInstanceName))) &&
      (!eventPatternType || (eventPatternType && event.typeList.includes(R.toLower(eventPatternType)))) &&
      (!eventRootCauseMetric || (eventRootCauseMetric && event.metricList.includes(eventRootCauseMetric))) &&
      event.startTimestamp <= Number(eventTimestamp) &&
      event.endTimestamp >= Number(eventTimestamp)
    );
  }

  if (individualIncident) {
    return event.id === individualIncident.id;
  }

  return (
    !event.isRootEvent &&
    event.projectName === eventProjectName &&
    event.patternId === Number(eventPatternId) &&
    (!eventComponentName || (eventComponentName && event.componentName === eventComponentName)) &&
    (!eventInstanceName || (eventInstanceName && event.allInstanceList.includes(eventInstanceName))) &&
    (!eventPatternType || (eventPatternType && event.typeList.includes(R.toLower(eventPatternType)))) &&
    (!eventRootCauseMetric || (eventRootCauseMetric && event.metricList.includes(eventRootCauseMetric))) &&
    event.startTimestamp === Number(eventTimestamp)
  );
};

const getJumpInfo = ({ props, mergedList, eventProjectName, eventPatternId, eventTimestamp, isJWT }) => {
  let jumpFatherIncident;
  let jumpIncident;
  let composite = false;
  if (eventProjectName && eventPatternId && eventTimestamp) {
    R.find((dataEvent) => {
      const fatherEvent = R.find((event) => {
        let childEvent;
        if (event.isAllCompositeAnomaly || event.hasCompositeAnomaly) {
          childEvent = R.find((item) => {
            const flagEvent = R.find(
              (_item) => getJumpFlag({ event: _item, props, isJWT }),
              item?.anomalyTimelines || [],
            );
            if (flagEvent) return flagEvent;
            return R.find(
              (_item) => getJumpFlag({ event: _item, props, childDouble: true, isJWT }),
              item?.anomalyTimelines || [],
            );
          }, event?.children || []);
          if (childEvent) {
            jumpIncident = childEvent;
            composite = true;
          }
        }

        if (!childEvent) {
          childEvent = R.find(
            (item) =>
              getJumpFlag({ event: item, props, isJWT }) ||
              getJumpFlag({ event: item, props, childDouble: true, isJWT }),
            event?.children || [],
          );
          if (childEvent) jumpIncident = childEvent;
        }

        return childEvent;
      }, dataEvent?.children || []);

      if (fatherEvent) jumpFatherIncident = fatherEvent;
      return fatherEvent;
    }, mergedList || []);

    if (!jumpFatherIncident && !jumpIncident) {
      notification.warn({
        message: 'Notification',
        description: `The selected anomaly ${eventPatternId} is no longer valid.`,
        duration: 0,
      });
    }
  }
  return { jumpFatherIncident, jumpIncident, composite };
};

const getIndividualJumpInfo = ({ props, mergedList, eventProjectName, eventPatternId, eventTimestamp, isJWT }) => {
  const { individualIncident, onChangeIndividualIncident } = props;
  let jumpFatherIncident;
  let jumpIncident;
  if (eventProjectName && eventPatternId && eventTimestamp) {
    R.find((dataEvent) => {
      const fatherEvent = R.find((event) => {
        const childEvent = R.find(
          (item) =>
            getJumpFlag({ event: item, props, isJWT }) || getJumpFlag({ event: item, props, childDouble: true, isJWT }),
          event?.children || [],
        );
        if (childEvent) jumpIncident = childEvent;
        return childEvent;
      }, dataEvent?.children || []);
      if (fatherEvent) jumpFatherIncident = fatherEvent;
      return fatherEvent;
    }, mergedList || []);
    if (!jumpFatherIncident && !jumpIncident) {
      notification.warn({
        message: 'Notification',
        description: `The selected anomaly ${eventPatternId} is no longer valid.`,
        duration: 0,
      });
    }
  }

  if (individualIncident) {
    R.find((dataEvent) => {
      const fatherEvent = R.find((event) => {
        const childEvent = R.find(
          (item) => getJumpFlag({ event: item, props, individualIncident, isJWT }),
          event?.children || [],
        );
        if (childEvent) jumpIncident = childEvent;
        return childEvent;
      }, dataEvent?.children || []);
      if (fatherEvent) jumpFatherIncident = fatherEvent;
      return fatherEvent;
    }, mergedList);
    onChangeIndividualIncident();
  }

  return { jumpFatherIncident, jumpIncident };
};

const handleClickDateRow = (rowIndex, rowData, self) => {
  const { isExpand, id } = rowData;
  const { eventList } = self.state;
  rowIndex = R.findIndex(R.propEq('id', id))(eventList);
  const newEventList = [...(eventList || [])];
  newEventList[rowIndex].isExpand = !isExpand;

  self.setState({ eventList: newEventList }, () => {
    self.cellMeasureCache.clearAll();
    if (self.refList) self.refList.forceUpdateGrid();
    self.forceUpdate();
  });
};

const handleIgnoreSumbit = (incident, category, rowIndex, dateIdx, self) => {
  return () => {
    const { eventList } = self.state;
    const { intl, credentials, isAdmin, userName, tabName } = self.props;
    const { isIgnored, isRCAFlag } = incident;
    const { projectOwner, isImportant, startTimestamp, incidentTimestamp, timeLineType } = incident;
    let { projectName, anomalyLogInstance, instanceName, type, patternId } = incident;
    projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
    const tabMap = {
      incident: 'Incident',
      logAlert: 'Alert',
      metric: 'MetricAnomaly',
      logAnomaly: 'logAnomaly',
      trace: 'Trace',
      deployment: 'Deployment',
    };

    if (self.ignoreModal) {
      self.ignoreModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }

    let content;
    if (category === 'ignore') {
      const {
        anomalyLogInstance: childrenAnomalyLogInstance,
        instanceName: childInstanceName,
        type: childrenType,
        patternId: childrenPatternId,
        rootCausesDetailsList,
      } = eventList[dateIdx]?.children[rowIndex].children[0];
      anomalyLogInstance = childrenAnomalyLogInstance;
      instanceName = childInstanceName;
      patternId = childrenPatternId;
      type = childrenType;
      if (category === 'ignore' && !isIgnored) {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, rootCausesDetailsList || []);
        content = R.join('\n', summaryList);
      }
    }

    let operation;
    switch (category) {
      case 'ignore':
        operation = isIgnored ? 'cancelIgnoreFlag' : 'setIgnoreFlag';
        break;
      case 'important':
        operation = isImportant ? 'cancelImportantFlag' : 'setImportantFlag';
        break;
      case 'rootcause':
        operation = isRCAFlag ? 'cancelRCAFlag' : 'setRCAFlag';
        break;
      default:
        break;
    }
    self.props.updateLastActionInfo();
    return fetchPost(
      getEndpoint('events', 1),
      {
        ...credentials,
        projectName,
        instanceName: anomalyLogInstance || instanceName,
        operation,
        nid: patternId,
        content,
        ...(category === 'important'
          ? { timestamp: incidentTimestamp || startTimestamp, timeLineType, type: tabMap[tabName] }
          : { type: type === 'Incident' ? 'incident' : undefined }),
      },
      {},
      false,
    )
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        if (self.ignoreModal) self.ignoreModal.destroy();
        if (self.refresh) {
          self.refresh();
        } else if (self.props.refresh) {
          self.props.refresh();
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        self.cellMeasureCache.clearAll();
        if (self.refList) self.refList.forceUpdateGrid();
        if (self.ignoreModal) {
          self.ignoreModal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      });
  };
};

const handleIgnoreClick = ({ event, category, rowIndex, rootEvent, dateIdx, self }) => {
  const { intl } = self.props;
  const { eventList } = self.state;
  const { isRCAFlag, isImportant } = event;
  let { isIgnored, patternName, patternId } = event;

  if (category === 'ignore') {
    if (!eventList[dateIdx]?.children[rowIndex]?.isExpand) {
      handleCategoryClick({ dateIdx, rootEventIdx: rowIndex, rowData: event, self });
      const {
        isIgnored: chidlrenIsIgnored,
        patternName: childrenPatternName,
        patternId: childrenPatternId,
      } = eventList[dateIdx]?.children[rowIndex].children[0];
      isIgnored = chidlrenIsIgnored;
      patternName = childrenPatternName;
      patternId = childrenPatternId;
    } else {
      self.fatherEvent = event;
    }
  }

  // if (category === 'ignore') {
  //   self.fatherEvent = null;
  // }

  if (category === 'rootcause' || category === 'important') {
    self.fatherEvent = rootEvent;
    self.childEvent = event;
  }

  let content = '';
  switch (category) {
    case 'ignore':
      content = isIgnored
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: 'Ignore',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: 'Ignore',
          });
      break;
    case 'important':
      content = isImportant
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: 'Important',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: 'Important',
          });
      break;
    case 'rootcause':
      content = isRCAFlag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: 'Enable RCA',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: 'Enable RCA',
          });
      break;
    default:
      break;
  }

  self.ignoreModal = Modal.confirm({
    title: intl.formatMessage(appButtonsMessages.confirm),
    content,
    okText: intl.formatMessage(appButtonsMessages.submit),
    cancelText: intl.formatMessage(appButtonsMessages.cancel),
    onOk: handleIgnoreSumbit(event, category, rowIndex, dateIdx, self),
  });
};

const rendererChecked = (rowIndex, rowData, rootEvent, rootEventIdx, dateIdx, self) => {
  const { intl } = self.props;
  const { isChecked } = rowData;
  rowIndex = R.findIndex(R.propEq('id', rowData.id))(rootEvent.children || []);
  return (
    <Popover
      title={null}
      content={intl.formatMessage(eventMessages.addToCompareList)}
      mouseEnterDelay={0.3}
      placement="right"
    >
      <Checkbox
        checked={isChecked}
        onChange={(e) => {
          self.setState(
            (prevState) => ({
              eventList: update(prevState.eventList, {
                [dateIdx]: {
                  children: {
                    [rootEventIdx]: {
                      children: {
                        [rowIndex]: {
                          $set: {
                            ...(prevState.eventList[dateIdx]?.children[rootEventIdx]?.children[rowIndex] || {}),
                            isChecked: e.target.checked,
                          },
                        },
                      },
                    },
                  },
                },
              }),
            }),
            () => {
              if (self.refList) self.refList.forceUpdateGrid();
              const { eventList } = self.state;
              const isAllChecked = R.all(R.equals(true))(
                R.map((event) => {
                  return R.all(R.equals(-1))(
                    R.map(
                      (item) =>
                        R.findIndex((citem) => !citem.isChecked && !citem.isCompositeAnomaly, item.children || []),
                      event.children || [],
                    ),
                  );
                }, eventList || []),
              );
              self.setState({ isAllChecked });
            },
          );
        }}
      />
    </Popover>
  );
};

const handleTeamsDeteleConfirm = (rowData, close, channels, rootEvent, rowIndex, self) => {
  const { intl, credentials, location } = self.props;
  const { environmentId, systemId } = parseLocation(location);
  const { projectName, projectOwner, instanceName, incidentTimestamp } = rowData;

  if (self.deleteTeamsChannelModal) {
    self.deleteTeamsChannelModal.update({
      okButtonProps: { loading: true },
      cancelButtonProps: { disabled: true },
    });
  }

  const requests = [];
  R.forEachObjIndexed((val, channelType) => {
    if (val) {
      if (channelType === 'Teams') {
        requests.push(
          fetchPost(getEndpoint('delete-teams'), {
            ...credentials,
            environmentName: environmentId,
            systemName: systemId,
            customerName: projectOwner,
            projectName,
            instanceName,
            incidentTimestamp,
          }),
        );
      } else if (channelType === 'Slack') {
        requests.push(
          fetchPost(getEndpoint('delete-slack'), {
            ...credentials,
            environmentName: environmentId,
            systemName: systemId,
            customerName: projectOwner,
            projectName,
            instanceName,
            incidentTimestamp,
          }),
        );
      }
    }
  }, self.deleteIncidentCheckedMap);

  self.props.updateLastActionInfo();
  Promise.all(requests)
    .then((results) => {
      const success = R.reduce(
        R.and,
        true,
        R.map((item) => item.success, results),
      );
      const errMsg = R.reduce(
        R.or,
        '',
        R.map((item) => item.message, results),
      );
      if (success) {
        self.fatherEvent = rootEvent;
        self.childEvent = rowData;
        if (self.refresh) {
          self.refresh();
        } else if (self.props.refresh) {
          self.props.refresh();
        }
        close();
      } else if (self.deleteTeamsChannelModal) {
        self.deleteTeamsChannelModal.update({
          content: (
            <div>
              <div>
                <div>
                  {R.map((channel) => {
                    const { channelType, channelName } = channel || {};
                    return (
                      <div key={channelType} className="flex-row">
                        <div className="flex-grow flex-min-width">
                          <span className="light-label bold" style={{ marginRight: 4 }}>
                            {self.channelNameMap[channelType] || channelType}:
                          </span>{' '}
                          {channelName}
                        </div>
                        <Switch
                          size="small"
                          style={{ width: 50 }}
                          checkedChildren={intl.formatMessage(appButtonsMessages.yes)}
                          unCheckedChildren={intl.formatMessage(appButtonsMessages.no)}
                          defaultChecked={self.deleteIncidentCheckedMap[channelType] || false}
                          onChange={(checked) => {
                            self.deleteIncidentCheckedMap[channelType] = checked;
                            if (self.deleteTeamsChannelModal) {
                              const hasChecked = R.reduce(R.or, false, R.values(self.deleteIncidentCheckedMap));
                              self.deleteTeamsChannelModal.update({
                                okButtonProps: { disabled: !hasChecked },
                              });
                            }
                          }}
                        />
                      </div>
                    );
                  }, channels)}
                </div>
              </div>
              <Alert message={errMsg} type="error" style={{ marginTop: 8 }} />
            </div>
          ),
          okButtonProps: { loading: false },
          cancelButtonProps: { disabled: false },
        });
      }
    })
    .catch((error) => {
      message.error(intl.formatMessage(appMessages.apiFaild));
      if (self.deleteTeamsChannelModal) {
        self.deleteTeamsChannelModal.update({
          okButtonProps: { loading: false },
          cancelButtonProps: { disabled: false },
        });
      }
    });
};

const handleTeamsDetele = (rowData, channels, rootEvent, rowIndex, self) => {
  const { intl } = self.props;
  self.deleteIncidentCheckedMap = {};
  self.deleteTeamsChannelModal = Modal.confirm({
    title: intl.formatMessage(eventMessages.deleteTeamsChannelConfirm),
    content: (
      <div>
        {R.map((channel) => {
          const { channelType, channelName } = channel || {};
          return (
            <div key={channelType} className="flex-row">
              <div className="flex-grow flex-min-width">
                <span className="light-label bold" style={{ marginRight: 4 }}>
                  {self.channelNameMap[channelType] || channelType}:
                </span>{' '}
                {channelName}
              </div>
              <Switch
                size="small"
                style={{ width: 50 }}
                checkedChildren={intl.formatMessage(appButtonsMessages.yes)}
                unCheckedChildren={intl.formatMessage(appButtonsMessages.no)}
                defaultChecked={self.deleteIncidentCheckedMap[channelType] || false}
                onChange={(checked) => {
                  self.deleteIncidentCheckedMap[channelType] = checked;
                  if (self.deleteTeamsChannelModal) {
                    const hasChecked = R.reduce(R.or, false, R.values(self.deleteIncidentCheckedMap));
                    self.deleteTeamsChannelModal.update({
                      okButtonProps: { disabled: !hasChecked },
                    });
                  }
                }}
              />
            </div>
          );
        }, channels)}
      </div>
    ),
    okButtonProps: { disabled: true },
    onCancel: () => {
      if (self.deleteTeamsChannelModal) self.deleteTeamsChannelModal.destroy();
      self.fatherEvent = rootEvent;
      self.childEvent = rowData;
      if (self.refresh) {
        self.refresh();
      } else if (self.props.refresh) {
        self.props.refresh();
      }
    },
    onOk: (close) => {
      const hasChecked = R.reduce(R.or, false, R.values(self.deleteIncidentCheckedMap));
      if (hasChecked) {
        handleTeamsDeteleConfirm(rowData, close, channels, rootEvent, rowIndex, self);
      } else {
        self.fatherEvent = rootEvent;
        self.childEvent = rowData;
        if (self.refresh) {
          self.refresh();
        } else if (self.props.refresh) {
          self.props.refresh();
        }
        close();
      }
    },
  });
};

const handleTeamsCheckAndDetele = async (rowData, rootEvent, rowIndex, self) => {
  const { credentials } = self.props;
  const { projectName, projectOwner, instanceName, incidentTimestamp } = rowData;
  self.props.updateLastActionInfo();
  const channels = await fetchGet(getEndpoint('incident-channel-info'), {
    ...credentials,
    customerName: projectOwner,
    projectName,
    instanceName,
    incidentTimestamp,
  })
    .then((data) => {
      return data || [];
    })
    .catch((error) => {
      return [];
    });

  if (channels.length > 0) {
    // delete teams channel
    handleTeamsDetele(rowData, channels, rootEvent, rowIndex, self);
  } else {
    // refresh page if no teams channel
    self.fatherEvent = rootEvent;
    self.childEvent = rowData;
    if (self.refresh) {
      self.refresh();
    } else if (self.props.refresh) {
      self.props.refresh();
    }
  }
};

const onChangeIncidentStatus = ({ rowData, rowIndex, field, value, rootEvent, rootEventIdx, dateIdx, self }) => {
  const { intl, credentials } = self.props;
  const { projectOwner, anomalyLogInstance, instanceName, incidentTimestamp } = rowData;
  let { projectName } = rowData;
  projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
  const oldValue = get(rowData, field);

  self.setState(
    (prevState) => ({
      eventList: update(prevState.eventList, {
        [dateIdx]: {
          children: {
            [rootEventIdx]: {
              children: {
                [rowIndex]: {
                  $set: {
                    ...(prevState.eventList[dateIdx]?.children[rootEventIdx]?.children[rowIndex] || {}),
                    [`isLoading-${field}`]: true,
                    [field]: value,
                  },
                },
              },
            },
          },
        },
      }),
    }),
    () => {
      if (self.refList) self.refList.forceUpdateGrid();
    },
  );

  self.props.updateLastActionInfo();
  fetchPost(getEndpoint('incidentInvestigation'), {
    ...credentials,
    projectName,
    instanceName: anomalyLogInstance || instanceName,
    timestamp: incidentTimestamp,
    [field === 'incidentStatus' ? 'status' : 'severity']: value,
  })
    .then((data) => {
      // delete teams channel if exist
      if (field === 'incidentStatus' && value === 'closed') {
        handleTeamsCheckAndDetele(rowData, rootEvent, rowIndex, self);
      } else {
        self.fatherEvent = rootEvent;
        self.childEvent = rowData;
        if (self.refresh) {
          self.refresh();
        } else if (self.props.refresh) {
          self.props.refresh();
        }
      }
    })
    .catch((err) => {
      message.error(intl.formatMessage(appMessages.apiFaild));
      // rollback
      self.setState(
        (prevState) => ({
          eventList: update(prevState.eventList, {
            [dateIdx]: {
              children: {
                [rootEventIdx]: {
                  children: {
                    [rowIndex]: {
                      $set: {
                        ...(prevState.eventList[dateIdx]?.children[rootEventIdx]?.children[rowIndex] || {}),
                        [`isLoading-${field}`]: false,
                        [field]: oldValue,
                      },
                    },
                  },
                },
              },
            },
          }),
        }),
        () => {
          if (self.refList) self.refList.forceUpdateGrid();
        },
      );
    });
};

const rendererIncidentStatus = (rowData, rowIndex, rootEvent, rootEventIdx, dateIdx, self) => {
  const { intl, credentials, timezoneOffset, isReadUser } = self.props;
  const { 'isLoading-incidentStatus': isLoading, incidentStatus, investigationReporterRecordStatus } = rowData;
  const { isCompositeAnomaly } = rowData;
  rowIndex = R.findIndex(R.propEq('id', rowData.id))(rootEvent.children || []);
  if (isCompositeAnomaly) {
    const colorMap = { 'in progress': 'orange', closed: 'green', created: '' };
    const textMap = { 'in progress': 'Active', closed: 'Closed', created: 'Created' };
    return (
      <div>
        {R.map((item) => {
          const text = R.trim(item || '');
          return <div style={{ color: colorMap[text] }}>{textMap[text] || 'Created'}</div>;
        }, R.split(',', incidentStatus || ''))}
      </div>
    );
  }
  return (
    <Popover
      title={null}
      content={
        isReadUser ? (
          intl.formatMessage(eventMessages.isReadUserDisable)
        ) : (
          <EventRenderers.RenderIncidentStatusTooltipContent
            intl={intl}
            credentials={credentials}
            timezoneOffset={timezoneOffset}
            reporterRecordSet={investigationReporterRecordStatus}
            statusColorMap={self.statusColorMap}
            incident={rowData}
            updateLastActionInfo={self.props.updateLastActionInfo}
          />
        )
      }
      mouseEnterDelay={0.3}
      placement="right"
    >
      <Spin size="small" spinning={!!isLoading} wrapperClassName="spin-full-width full-width">
        <Select
          showArrow={false}
          size="small"
          style={{ width: '100%' }}
          value={incidentStatus}
          onChange={(value) =>
            onChangeIncidentStatus({
              rowData,
              rowIndex,
              field: 'incidentStatus',
              value,
              rootEvent,
              rootEventIdx,
              dateIdx,
              self,
            })
          }
          disabled={isReadUser}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ maxWidth: 650 }}
          className={
            incidentStatus === 'in progress'
              ? 'status-select-progress'
              : incidentStatus === 'closed'
              ? 'status-select-closed'
              : ''
          }
        >
          {R.map((item) => {
            return (
              <Select.Option key={item.value}>
                <span>{item.label}</span>
              </Select.Option>
            );
          }, statusOptions)}
        </Select>
      </Spin>
    </Popover>
  );
};

// 修改时，记得同步 RootCauseList、IndividualIncidentsModal、EmbedRootcausetimeline、EmbedIndividualIncidentsModal 的peersListItem函数
const tableViewList = (isJWT, self, intl, peersListItem) => {
  const { tabName, activeKey, onTableChange } = self.props;
  const { sortBy, sortDirection, allExpand, isAllChecked, eventList, jumpIncident } = self.state;
  const { peersEventList, eventCount, totalLogCount } = reducerHandleEvents(eventList);
  if (onTableChange) {
    onTableChange(peersEventList);
  }
  return (
    <div className="flex-grow event-list" style={{ marginLeft: 8 }}>
      <AutoSizer>
        {({ width, height }) => (
          <>
            <div
              className="event-list-header"
              style={{
                width,
                height: self.listHeaderHeight,
                paddingRight: self.listNodeHeaderScrollbar ? 8 : 0,
              }}
            >
              <div className="header-column flex-center-justify" style={{ minWidth: 56 }} />
              <div
                className="header-column"
                style={{ minWidth: 70, maxWidth: 70 }}
                onClick={headerClick('startTimestamp', self)}
              >
                <span>{intl.formatMessage(appFieldsMessages.date)}</span>
                {sortIcon(sortBy, sortDirection, 'startTimestamp')}
              </div>
              <div
                className="header-column"
                style={{ minWidth: 180, maxWidth: 180 }}
                onClick={headerClick('patternId', self)}
              >
                <span>
                  {intl.formatMessage(
                    tabName === 'incident' ? eventMessages.incidentPattern : eventMessages.fieldAnomalyPattern,
                  )}
                </span>
                {sortIcon(sortBy, sortDirection, 'patternId')}
              </div>
              <div
                className="header-column"
                style={{ minWidth: 210, maxWidth: 210 }}
                onClick={headerClick('componentName', self)}
              >
                <span>{intl.formatMessage(eventMessages.impacted)}</span>
                {sortIcon(sortBy, sortDirection, 'componentName')}
              </div>
              <div className="header-column flex-wrap" style={{ flex: 1, minWidth: 140 }}>
                <span style={{ textDecoration: 'none', flexShrink: 0, marginRight: 8 }}>
                  {intl.formatMessage(eventMessages.shortDescription)}
                </span>
                {tabName === 'logAnomaly' && (
                  <span>{`(${intl.formatMessage(eventMessages.totalLogCount)}: ${totalLogCount})`}</span>
                )}
              </div>
              <div
                className="header-column flex-center-justify text-center"
                style={{ width: 130, minWidth: 130, paddingRight: 16 }}
                onClick={headerClick('hasRootCause', self)}
              >
                <div style={{ position: 'relative', color: 'var(--primary-color)' }}>
                  <span className="flex-row text-center">{intl.formatMessage(appMenusMessages.insights)}</span>
                  <div style={{ position: 'absolute', top: 2, right: -15 }}>
                    {sortIcon(sortBy, sortDirection, 'hasRootCause')}
                  </div>
                </div>
              </div>
              <div
                className="header-column"
                style={{ minWidth: 90, maxWidth: 90 }}
                onClick={headerClick('duration', self)}
              >
                <span>{intl.formatMessage(eventMessages.fieldDuration)}</span>
                {sortIcon(sortBy, sortDirection, 'duration')}
              </div>
              {false && (
                <div
                  className="header-column"
                  style={{ minWidth: 130, maxWidth: 130 }}
                  onClick={headerClick('duration', self)}
                >
                  <span>{intl.formatMessage(eventMessages.fieldDuration)}</span>
                  {sortIcon(sortBy, sortDirection, 'duration')}
                </div>
              )}
              {activeKey === 'trace' && (
                <div className="header-column flex-center-justify" style={{ minWidth: 40 }}>
                  {intl.formatMessage(DashboardMessages.detectedTrace)}
                </div>
              )}
              {activeKey !== 'incident' && (
                <div className="header-column flex-center-justify" style={{ minWidth: 65 }}>
                  {intl.formatMessage(appFieldsMessages.type)}
                </div>
              )}
              <div className="header-column" style={{ minWidth: 70, maxWidth: 70 }}>
                {intl.formatMessage(appMenusMessages.flags)}
              </div>
              {false && (
                <div
                  className="header-column flex-center-justify"
                  style={{ minWidth: 160, maxWidth: 160, wordBreak: 'break-all' }}
                >
                  {`${intl.formatMessage(logMessages.recommendations)}/${intl.formatMessage(appFieldsMessages.Notes)}`}
                </div>
              )}
              {tabName === 'incident' && (
                <div
                  className="header-column"
                  style={{ minWidth: isJWT ? 80 : 110, maxWidth: isJWT ? 80 : 110 }}
                  onClick={headerClick('incidentStatus', self)}
                >
                  <span>{intl.formatMessage(appFieldsMessages.status)}</span>
                  {sortIcon(sortBy, sortDirection, 'incidentStatus')}
                </div>
              )}
              <div
                className="header-column"
                style={{ minWidth: 130, maxWidth: 130 }}
                onClick={headerClick('count', self)}
              >
                <div style={{ position: 'relative', width: '100%', wordBreak: 'break-word', textAlign: 'center' }}>
                  <span>{`${intl.formatMessage(
                    tabName === 'logAnomaly' ? eventMessages.uniqueLogCount : eventMessages.count,
                  )} (${eventCount})`}</span>
                  <div style={{ position: 'absolute', top: '50%', right: -15, transform: 'translateY(-50%)' }}>
                    {sortIcon(sortBy, sortDirection, 'count')}
                  </div>
                </div>
              </div>
              <div
                className="header-column text-center"
                style={{ width: 30 }}
                onClick={(e) => headerAllExpandClick(e, self)}
              >
                <DoubleLeftOutlined rotate={allExpand ? 90 : -90} />
              </div>
              <div className="header-column flex-center-justify" style={{ width: 60 }}>
                {tabName === 'incident' && <div style={{ width: 16 }} />}
                {tabName === 'incident' && !isJWT && (
                  <Checkbox
                    style={{ marginLeft: 8 }}
                    checked={isAllChecked}
                    onChange={(e) => {
                      const { checked } = e.target;
                      R.forEach((item) => {
                        R.forEach((_item) => {
                          R.forEach((__item) => {
                            if (!__item.isCompositeAnomaly) {
                              __item.isChecked = checked;
                            }
                          }, _item.children);
                        }, item.children || []);
                      }, eventList || []);
                      self.setState({ isAllChecked: checked });
                    }}
                  />
                )}
              </div>
            </div>
            <List
              ref={(ref) => {
                self.refList = ref;
              }}
              className="event-list-grid"
              width={width}
              height={height - self.listHeaderHeight}
              rowCount={(peersEventList || []).length}
              overscanRowCount={20}
              deferredMeasurementCache={self.cellMeasureCache}
              rowHeight={self.cellMeasureCache.rowHeight}
              rowRenderer={peersListItem(tabName, peersEventList, jumpIncident)}
              onScrollbarPresenceChange={({ horizontal, vertical }) => {
                if (vertical) {
                  self.listNodeHeaderScrollbar = true;
                } else {
                  self.listNodeHeaderScrollbar = false;
                }
                self.forceUpdate();
              }}
            />
          </>
        )}
      </AutoSizer>
    </div>
  );
};

const handleRecurrentIncidentClick = (rowData, time, systemId, isJWT, self) => {
  const { handleJumpBySelf } = self.props;
  const { projectOwner, patternId, projectName, containerInfo, instanceListStr } = rowData;
  if (isJWT) {
    const startTimeObj = moment.utc(time).startOf('days');
    const endTimeObj = moment.utc(time).endOf('days');
    handleJumpBySelf({
      startTimeObj,
      endTimeObj,

      eventCategory: 'incident',
      eventPatternType: 'incident',
      eventPatternId: patternId,
      eventProjectName: projectName,
      eventInstanceName: containerInfo
        ? `${containerInfo.containerName}_${containerInfo.instanceName}`
        : instanceListStr,
      eventTimestamp: time,
    });
  } else {
    const startTime = moment.utc(time).format(Defaults.DateFormat);
    const query = {
      environmentId: 'All',
      startTime,
      endTime: startTime,
      customerName: projectOwner,
      systemId,

      eventCategory: 'incident',
      eventPatternType: 'incident',
      eventPatternId: patternId,
      eventProjectName: projectName,
      eventInstanceName: containerInfo
        ? `${containerInfo.containerName}_${containerInfo.instanceName}`
        : instanceListStr,
      eventTimestamp: time,
      hideIgnore: false,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  }
};

const recurrentIncidentsViewList = (rowData, intl, systemId, isJWT, self) => {
  const { recurringIncidentTimes } = rowData;
  return (
    <div style={{ maxWidth: 400, maxHeight: 300, overflowY: 'auto', paddingRight: 8 }}>
      {R.map(
        (time) => {
          return (
            <div
              key={time}
              className="flex-row flex-center-align flex-space-between"
              style={{ width: 200, margin: '8px 0' }}
            >
              <div>{moment.utc(time).format(Defaults.DateTimeFormat)}</div>
              <Button
                size="small"
                type="primary"
                onClick={() => handleRecurrentIncidentClick(rowData, time, systemId, isJWT, self)}
              >
                {intl.formatMessage(appFieldsMessages.details)}
              </Button>
            </div>
          );
        },
        R.sort((a, b) => a - b, R.uniq(recurringIncidentTimes || [])),
      )}
    </div>
  );
};

export {
  chartBarColor,
  statusOptions,
  setTooltipText,
  getChartTimeMap,
  tidyEventList,
  handleComposite,
  sortFunction,
  filterEventListTechnology,
  getChartOption,
  buildMergeData,
  setChartRef,
  onSystemChartClick,
  handleChartZoom,
  handleChartRestore,
  handleChartFinish,
  customizeRestore,
  tableViewList,
  positionEvent,
  handleCategoryClick,
  getJumpInfo,
  getIndividualJumpInfo,
  getTypeColor,
  handleClickDateRow,
  handleIgnoreClick,
  rendererChecked,
  rendererIncidentStatus,
  rendererExpand,
  impactedRender,
  timeRenderer,
  traceRenderer,
  renderCategory,
  statusRenderer,
  listHeaderHeight,
  rowMinHeight,
  dateHeight,
  getEventInAnomalyTimelines,
  recurrentIncidentsViewList,
  LLMTitleMap,
};
