/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import { omit } from 'lodash';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Alert } from 'antd';

import { Container } from '../../../src/lib/fui/react';
import { logMessages } from '../../../src/common/log/messages';

import { InlineEditInput } from '../../ui/inlineedit';
import EventTable from './event-table';

type Props = {
  intl: Object,
  refeshTime: Number,
  name: String,
  minHeight: Number,
  eventDataset: Array<Object>,
  showFE: Boolean,
  showRangeTime: Boolean,
  disableSortDateTime: Boolean,
  needSortDatetime: Boolean,
  featureKeywords: Array<Object>,
  keywords: Array<String>,
  onNameChanged: Function,
  nameEditable: Boolean,
  highlightWord: ?String,
  totalCount: Number,
  pageSize: Number,
  pageNo: Number,
  onPageChanged: Function,
  combineLabels: Boolean,
  combineLabelsHiddenOverflow: Boolean,
  hasRankNumber: Boolean,
  hasDatetime: Boolean,
  hasIgnored: Boolean,
  hasInstance: Boolean,
  hasType: Boolean,
  hasCategory: Boolean,
  hasTag: Boolean,
  hasInstanceName: Boolean,
  hasNid: Boolean,
  hasCount: Boolean,
  hasProbability: Boolean,
  hasDelay: Boolean,
  hasPatternAction: Boolean,
  hasContext: Boolean,
  hasDecompress: Boolean,
  clusterSampleMsgs: Object,
  highlightRow: Object,
  highlightPages: Array<Number>,
  isContext: Boolean,
  showSimpleRawData: Boolean,
  showHighlightCategory: Boolean,
  showRawDataFrequency: Boolean,
  showRCAJump: Boolean,
  showLinkedJump: Boolean,
  showExternalJump: Boolean,
  showLogAnalysisJump: Boolean,
  isCritical: Boolean,
  filterWord: String,
  isInfiniteLoad?: Boolean,
  projectName?: String,
  startTime?: Number,
  endTime?: Number,
  initExpandDuration?: String,
  loadMoreRows?: Function,

  summarySettings: Array,
  enableJsonSummary?: Boolean,

  instanceList: Array,
  extra?: Boolean,
  useTimeRange: Boolean,
  initLoad: Boolean,
  getLogTailData: Function,
  isLiveTail: Boolean,
  isManualLoad: Boolean,
  unSubscribeLogTailData: Function,
};

class EventGroup extends React.Component {
  props: Props;

  static defaultProps = {
    name: '',
    eventDataset: [],
    nameEditable: false,
    showFE: true,
    featureKeywords: null,
    keywords: [],
    onNameChanged: () => {},
    highlightWord: null,
    hasDatetime: true,
    isInfiniteLoad: false,
    instanceList: [],
    extra: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      highlightWord: props.highlightWord,
      tableKey: new Date().valueOf(),
    };

    this.normalizeDataset(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.keywords !== this.props.keywords ||
      nextProps.eventDataset !== this.props.eventDataset ||
      nextProps.featureKeywords !== this.props.featureKeywords ||
      nextProps.highlightWord !== this.props.highlightWord
    ) {
      this.normalizeDataset(nextProps);
      this.setState({
        highlightWord: nextProps.highlightWord,
      });
    }
  }

  normalizeDataset(props) {
    const { eventDataset } = props;
    this.normalizedEvents = eventDataset;
  }

  @autobind
  handleKeywordClick(kw) {
    return () => {
      // Clear the highlight if click the same word.
      const { highlightWord } = this.state;
      this.setState({
        highlightWord: kw === highlightWord ? null : kw,
      });
    };
  }

  @autobind
  handleNameChanged(name) {
    this.props.onNameChanged(name);
  }

  @autobind
  handleChangeTableKey() {
    this.setState({ tableKey: new Date().valueOf() });
  }

  render() {
    const {
      intl,
      refeshTime,
      name,
      projectName,
      nameEditable,
      minHeight,
      keywords,
      featureKeywords,
      showFE,
      showRangeTime,
      disableSortDateTime,
      needSortDatetime,
      totalCount,
      pageSize,
      pageNo,
      onPageChanged,
      onNameChanged,
      combineLabels,
      combineLabelsHiddenOverflow,
      hasRankNumber,
      hasDatetime,
      hasIgnored,
      hasInstance,
      hasType,
      hasCategory,
      hasTag,
      hasInstanceName,
      hasNid,
      hasCount,
      hasProbability,
      hasDelay,
      hasPatternAction,
      hasContext,
      hasDecompress,
      clusterSampleMsgs,
      highlightRow,
      highlightPages,
      isContext,
      showSimpleRawData,
      showHighlightCategory,
      showRawDataFrequency,
      showRCAJump,
      showLinkedJump,
      showExternalJump,
      showLogAnalysisJump,
      isCritical,
      filterWord,
      isInfiniteLoad,
      startTime,
      endTime,
      initExpandDuration,
      loadMoreRows,
      summarySettings,
      enableJsonSummary,
      instanceList,
      extra,
      useTimeRange,
      initLoad,
      getLogTailData,
      isLiveTail,
      isManualLoad,
      selectedStartTs,
      isKeywordQuery,
      unSubscribeLogTailData,
      ...others
    } = this.props;
    const { normalizedEvents } = this;
    const props = omit(
      others,
      'onNameChanged',
      'eventDataset',
      'highlightWord',
      'totalCount',
      'pageSize',
      'pageNo',
      'onPageChanged',
      'isInfiniteLoad',
      'startTime',
      'endTime',
      'initExpandDuration',
      'loadMoreRows',
    );
    const { highlightWord, tableKey } = this.state;
    const count = normalizedEvents.length;
    const featureKeywordsOptions = [];
    R.forEach((k) => {
      featureKeywordsOptions.push({ label: k.matchWord, value: k.matchWord, count: k.count });
    }, featureKeywords || []);

    return (
      <Container
        className="full-width full-height log-event-group flex-col"
        {...props}
        style={{ marginLeft: extra ? 16 : 0 }}
      >
        {nameEditable && <InlineEditInput className="title" value={name} onChange={this.handleNameChanged} />}
        {!nameEditable && name && (
          <div className="title" style={{ cursor: 'inherit' }}>
            {name}
          </div>
        )}
        {showFE && !featureKeywords && Array.isArray(keywords) && keywords.length > 0 && (
          <div>
            <span className="label">{`${intl.formatMessage(logMessages.topKeywords)}:`}</span>
            <div style={{ display: 'inline-block' }}>
              {R.uniq(R.map(R.trim, keywords)).map((kw) => (
                <span
                  key={kw}
                  className={`keyword ${kw === highlightWord ? 'highlight' : ''}`}
                  onClick={this.handleKeywordClick(kw)}
                >
                  {kw}
                </span>
              ))}
            </div>
          </div>
        )}
        {(count > 0 || isInfiniteLoad) && (
          <Container className="flex-grow flex-min-height">
            <EventTable
              key={tableKey}
              intl={intl}
              projectName={projectName}
              minHeight={minHeight}
              refeshTime={refeshTime}
              highlightWord={highlightWord}
              eventDataset={normalizedEvents}
              totalCount={totalCount}
              pageSize={pageSize}
              pageNo={pageNo}
              onPageChanged={onPageChanged}
              onNameChanged={onNameChanged}
              combineLabels={combineLabels}
              combineLabelsHiddenOverflow={combineLabelsHiddenOverflow}
              hasRankNumber={hasRankNumber}
              hasDatetime={hasDatetime}
              hasIgnored={hasIgnored}
              hasInstance={hasInstance}
              hasType={hasType}
              hasCategory={hasCategory}
              hasTag={hasTag}
              hasInstanceName={hasInstanceName}
              hasNid={hasNid}
              hasCount={hasCount}
              hasProbability={hasProbability}
              hasDelay={hasDelay}
              hasPatternAction={hasPatternAction}
              hasContext={hasContext}
              hasDecompress={hasDecompress}
              clusterSampleMsgs={clusterSampleMsgs}
              highlightPages={highlightPages}
              highlightRow={highlightRow}
              isContext={isContext}
              showSimpleRawData={showSimpleRawData}
              showHighlightCategory={showHighlightCategory}
              showRawDataFrequency={showRawDataFrequency}
              showRangeTime={showRangeTime}
              disableSortDateTime={disableSortDateTime}
              needSortDatetime={needSortDatetime}
              showRCAJump={showRCAJump}
              showLinkedJump={showLinkedJump}
              showExternalJump={showExternalJump}
              showLogAnalysisJump={showLogAnalysisJump}
              isCritical={isCritical}
              filterWord={filterWord}
              isInfiniteLoad={isInfiniteLoad}
              startTime={startTime}
              endTime={endTime}
              initExpandDuration={initExpandDuration}
              loadMoreRows={loadMoreRows}
              summarySettings={summarySettings}
              enableJsonSummary={enableJsonSummary}
              instanceList={instanceList}
              extra={extra}
              useTimeRange={useTimeRange}
              initLoad={initLoad}
              getLogTailData={getLogTailData}
              isLiveTail={isLiveTail}
              isManualLoad={isManualLoad}
              selectedStartTs={selectedStartTs}
              isKeywordQuery={isKeywordQuery}
              handleChangeTableKey={this.handleChangeTableKey}
              unSubscribeLogTailData={unSubscribeLogTailData}
            />
          </Container>
        )}
        {!count && !isInfiniteLoad && (
          <div style={{ width: '100%', padding: '10px 0' }}>
            <Alert message="No result found in the given time range" type="info" showIcon />
          </div>
        )}
      </Container>
    );
  }
}

export default injectIntl(EventGroup);
