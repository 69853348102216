/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined } from '@ant-design/icons';
import { message, Button, Popconfirm, Spin } from 'antd';

import { State } from '../../../common/types';
import fetchGet from '../../../common/apis/fetchGet';
import fetchDelete from '../../../common/apis/fetchDelete';
import { parseLocation, CellRenderers } from '../../../common/utils';
import { AutoSizer, Table, Column } from '../../../lib/fui/react';
import { hideAppLoader, updateLastActionInfo } from '../../../common/app/actions';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  refresh: Number,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  appLoaderVisible: Boolean,
  credentials: Object,
  hideAppLoader: Function,
  updateLastActionInfo: Function,
};

class SignupManagement extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmitting: false,

      userList: [],
    };
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.refresh !== this.props.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { location, credentials } = props;
    const params = parseLocation(location);
    const { zone } = params;

    this.setState({ isLoading: true });
    props.updateLastActionInfo();
    fetchGet(`${window.BASE_URL || ''}/adminGetSignUpCode`, {
      ...credentials,
      zone,
    })
      .then((d) => {
        let userList = d.message || [];
        userList = this.sortData(userList);

        this.setState({ userList, isLoading: false });
      })
      .catch((err) => {
        this.setState({ userList: [], isLoading: false });
      });
  }

  @autobind
  sortData(datas) {
    const { sortBy, sortDirection } = this.state;

    // sort by
    let sortList = datas;
    let sortFunctions = [R.ascend(R.prop('userName'))];
    if (sortBy && sortDirection) {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);

    return sortList;
  }

  @autobind
  handleUserRemove(rowData) {
    const { intl, credentials } = this.props;
    const { userName } = rowData;

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchDelete(`${window.BASE_URL || ''}/adminGetSignUpCode`, {
      ...credentials,
      usernames: JSON.stringify([userName]),
    })
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData(this.props);
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  configRenderer({ rowData }) {
    const { intl } = this.props;
    return (
      <div className="flex-row flex-end-justify">
        <Popconfirm
          placement="topRight"
          title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
          onConfirm={() => this.handleUserRemove(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button
            size="small"
            className="button-color-grey"
            icon={<DeleteOutlined />}
            onClick={(event) => event.stopPropagation()}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </Popconfirm>
      </div>
    );
  }

  @autobind
  sort({ sortBy, sortDirection }) {
    const { sortDirection: prevSortDirection, userList: oldUserList } = this.state;
    this.setState(
      {
        sortBy,
        sortDirection: !prevSortDirection ? 'ASC' : prevSortDirection === 'DESC' ? undefined : sortDirection,
      },
      () => {
        const userList = this.sortData(oldUserList);
        this.setState({ userList });
      },
    );
  }

  @autobind
  headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey || !sortDirection) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { isLoading, isSubmitting, userList } = this.state;
    const { sortBy, sortDirection } = this.state;

    return (
      <Spin spinning={isLoading || isSubmitting} wrapperClassName="full-height spin-full-height">
        <AutoSizer>
          {({ height }) => (
            <Table
              className="with-border"
              width={1048}
              height={height}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={40}
              rowCount={userList.length}
              rowGetter={({ index }) => userList[index]}
              ref={(table) => {
                this.table = table;
              }}
              sort={this.sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            >
              <Column
                width={200}
                label={intl.formatMessage(eventMessages.userName)}
                dataKey="userName"
                headerRenderer={this.headerRenderer}
                flexGrow={1}
              />
              <Column
                width={200}
                label={intl.formatMessage(appFieldsMessages.email)}
                dataKey="email"
                headerRenderer={this.headerRenderer}
                flexGrow={1}
              />
              <Column
                width={200}
                label={intl.formatMessage(eventMessages.signupCode)}
                dataKey="signupCode"
                headerRenderer={this.headerRenderer}
                flexGrow={1}
              />
              <Column
                width={200}
                label={intl.formatMessage(eventMessages.freeTrialRequestDate)}
                dataKey="registrationDate"
                cellRenderer={CellRenderers.time}
                headerRenderer={this.headerRenderer}
                flexGrow={1}
              />
              <Column width={150} label="" cellRenderer={this.configRenderer} disableSort dataKey="userName" />
            </Table>
          )}
        </AutoSizer>
      </Spin>
    );
  }
}

export default connect(
  (state: State) => {
    const { location, appLoaderVisible } = state.app;
    const { credentials } = state.auth;
    return { location, appLoaderVisible, credentials };
  },
  { hideAppLoader, updateLastActionInfo },
)(SignupManagement);
