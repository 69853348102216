/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

/* @flow */

/* eslint-disable no-console */
import type { AuthState, Action } from '../types';

const initialState = {
  loggedIn: false,
  loggingIn: false,
  credentials: null,
  userInfo: null,
  loginReason: null,
  resetPasswordSucceed: false,
  resetPasswordError: null,
  loginMessage: null,
};

const reducer = (state: AuthState = initialState, action: Action) => {
  if (action.type === 'LOGIN') {
    return { ...state, loggingIn: true };
  } else if (action.type === 'SANDBOX_LOGIN') {
    return { ...state, loggingIn: true };
  } else if (action.type === 'SET_NEW_IDP_USER_STATUS') {
    const { data } = action.payload;
    const { succeed, error } = data;
    return {
      ...state,
      newIdpUserSucceed: succeed,
      newIdpUserError: error,
    };
  } else if (action.type === 'IDP_USER_LOGIN') {
    return { ...state, loggingIn: true };
  } else if (action.type === 'USER_TYPE_CHECK') {
    return { ...state, checking: true };
  } else if (action.type === 'USER_FOUND') {
    const { redirectLoginURL, redirectURL } = action.payload;
    return {
      ...state,
      loginReason: null,
      checking: false,
      checked: true,
      existed: true,
      redirectLoginURL,
      redirectURL,
    };
  } else if (action.type === 'USER_NOT_FOUND') {
    const { idpSettingMap } = action.payload;
    return { ...state, checking: false, checked: false, existed: false, idpSettingMap };
  } else if (action.type === 'SET_USER_AUTH_INFO') {
    const { data } = action.payload;
    const { credentials, userInfo } = data || {};
    const sessionToken = window.localStorage.getItem('X-CSRF-Token');
    return {
      ...state,
      credentials: { ...state.credentials, ...credentials },
      userInfo: { ...state.userInfo, ...userInfo, sessionToken },
    };
  } else if (action.type === 'LOGIN_SUCCESS') {
    const sessionToken = window.localStorage.getItem('X-CSRF-Token');
    const { credentials, userInfo } = action.payload;
    const { role, isIDPUser } = userInfo || {};
    const isAdmin = ['Admin'].indexOf(role) >= 0;
    const isReadUser = ['ReadOnlyUser'].indexOf(role) >= 0;
    const isLocalAdmin = ['LocalAdmin'].indexOf(role) >= 0;

    return {
      ...state,
      loggedIn: true,
      loggingIn: false,
      credentials,
      userInfo: { ...(userInfo || state.userInfo), isAdmin, isReadUser, isLocalAdmin, isIDPUser, sessionToken },
    };
  } else if (action.type === 'LOGIN_FAILURE') {
    const { error, message: loginReason, idpAvailable } = action.payload;
    let remainingRetry = 5;
    let errorMessage = '';
    let lockTime = '';
    if (error) {
      remainingRetry = '';
      errorMessage = error.message;
      if (error.lockDuration) {
        lockTime = (Number(error.lockDuration) / 1000 / 60).toFixed(0);
      }
    }
    return {
      ...state,
      loggedIn: false,
      loggingIn: false,
      loginReason,
      errorMessage,
      lockTime,
      remainingRetry,
      idpAvailable,
    };
  } else if (action.type === 'LOGIN_MESSAGE') {
    const { message } = action.payload;
    return {
      ...state,
      loginMessage: message,
    };
  } else if (action.type === 'LOGIN_PENDING') {
    if (action.payload.error) {
      console.error(action.payload.error);
    }
    const { redirectURL } = action.payload;
    return {
      ...state,
      redirectURL,
    };
  } else if (action.type === 'AUTH_SET_RESET_PASSWORD_BY_TEMP_STATUS') {
    const { succeed, error } = action.payload;
    return {
      ...state,
      resetPasswordSucceed: succeed,
      resetPasswordError: error,
    };
  } else if (action.type === 'SET_RESET_NEW_PASSWORD_STATUS') {
    const { succeed, error } = action.payload;
    return {
      ...state,
      resetNewPasswordSucceed: succeed,
      resetNewPasswordError: error,
    };
  } else if (action.type === 'CLEAR_CREDENTIALS') {
    return {
      ...state,
      loggedIn: false,
      loginReason: null,
      credentials: null,
      userInfo: null,
    };
  }
  return state;
};

export default reducer;
