/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useReducer, cloneElement } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { isFinite, maxBy, round, isString } from 'lodash';
import { Spin, Popover, Progress, Button, message, Select, Checkbox, Tabs } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { AutoSizer, CellMeasurerCache, CellMeasurer, Column, Table } from '../../../lib/fui/react';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import fetchGet from '../../../common/apis/fetchGet';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import fetchPost from '../../../common/apis/fetchPost';

import { Defaults, calcColorOfHealthScore } from '../../../common/utils';
import { eventMessages } from '../../../common/metric/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';

const cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

let localSystemList = []; // this is api result datasource
const TAB_KEY = ['myDashboards', 'sharedDashboards'];
const LayoutConfigures = (props) => {
  const listNode = useRef(null);
  const {
    intl,
    credentials,
    userInfo,
    onShowLayoutDetail,
    startTime,
    endTime,
    organizationName,
    isLoading: isLinkLoading,
  } = props;
  const { isAdmin, isLocalAdmin, isReadUser } = userInfo || {};

  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    sortBy: undefined,
    sortDirection: undefined,
    systemList: [],
    isLoading: false,
    summaryObj: {},
    maxViews: 0,
    dashNameFilter: undefined,
    systemNameFilter: undefined,
    authorNameFilter: undefined,
    dashNameOption: [],
    systemNameOption: [],
    authorNameOption: [],
    isAllChecked: false,
    activeTab: isAdmin || isLocalAdmin ? undefined : TAB_KEY[0],
    total: 0,
  });
  const { isLoading, sortBy, sortDirection, systemList, maxViews, isAllChecked, activeTab, total } = state;
  const { dashNameFilter, systemNameFilter, authorNameFilter } = state;
  const { dashNameOption, systemNameOption, authorNameOption } = state;

  const getSortValue = (obj, key) => {
    const curValue = R.prop(key, obj);

    if (isString(curValue)) {
      return R.toLower(curValue || '0');
    } else {
      return curValue;
    }
  };

  const sortData = (list, sortBy, sortDirection) => {
    let sortList = list || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('startTimestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      // every string should be lower case then sort
      sortFunctions =
        sortDirection === 'DESC'
          ? [R.descend((i) => getSortValue(i, sortBy))]
          : [R.ascend((i) => getSortValue(i, sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(list);

    return sortList;
  };

  const filterData = ({
    events,
    dashNameFilter,
    systemNameFilter,
    authorNameFilter,
    sortBy,
    sortDirection,
    activeTab,
  }) => {
    let eventList = events || localSystemList || [];
    if (dashNameFilter) {
      eventList = R.filter(
        (item) => R.toLower(item?.dashBoardName || '').includes(R.toLower(dashNameFilter || '')),
        eventList,
      );
    }

    if (systemNameFilter) {
      eventList = R.filter(
        (item) => R.toLower(item?.systemName || '').includes(R.toLower(systemNameFilter || '')),
        eventList,
      );
    }

    if (authorNameFilter) {
      eventList = R.filter(
        (item) => R.toLower(item?.author || '').includes(R.toLower(authorNameFilter || '')),
        eventList,
      );
    }
    const total = eventList.length;
    if (activeTab === 'myDashboards') {
      eventList = R.filter((item) => item?.author === userInfo.userName, eventList);
    }
    if (activeTab === 'sharedDashboards') {
      eventList = R.filter((item) => item?.author !== userInfo.userName, eventList);
    }
    eventList = sortData(eventList, sortBy, sortDirection);
    return { eventList, total };
  };

  const fetchData = async (flag) => {
    let newState = { dashNameFilter, systemNameFilter, authorNameFilter, sortBy, sortDirection };
    if (flag) {
      newState = {
        dashNameFilter: undefined,
        systemNameFilter: undefined,
        authorNameFilter: undefined,
        sortBy: undefined,
        sortDirection: undefined,
        isAllChecked: false,
        total: 0,
      };
    }
    setState({ isLoading: true, ...newState });

    await fetchGet(getEndpoint('dashboardmanagement'), {
      ...credentials,
      companyName: organizationName,
    })
      .then(async (data) => {
        let result = data?.result || data;
        if (isString(result)) {
          try {
            result = JSON.parse(result);
          } catch (error) {
            result = [];
          }
        }
        let systemList = [];
        R.forEach((item) => {
          const [systemInfo, layoutInfo] = item;
          let { systemFramework } = systemInfo;
          if (isString(systemFramework)) {
            try {
              systemFramework = JSON.parse(systemFramework);
            } catch (error) {
              systemFramework = {};
            }
          }
          const { systemDisplayName } = systemFramework;
          R.forEach((n) => {
            const { widgetOrder = {}, username: author } = n;
            const { views, dashBoardName = '', systemKey } = widgetOrder;
            const { systemName: systemId, userName: systemOwner } = systemKey;

            systemList.push({
              author,
              systemName: systemDisplayName,
              systemFramework,
              systemId,
              systemOwner,
              views,
              dashBoardName,
            });
          }, layoutInfo);
        }, result);

        systemList = R.sortWith([R.descend(R.prop('views')), R.ascend(R.prop('systemName'))], systemList);

        const systemIdsWithShare = R.map(
          (item) => ({ id: item.systemId, customerName: item.systemOwner, ownerUserName: item.systemOwner }),
          systemList || [],
        );

        const summaryObj = await fetchPost(getEndpoint('dashboard-summary'), {
          ...credentials,
          systemIdsWithShare: JSON.stringify(systemIdsWithShare),
          startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
          endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
        })
          .then((data) => {
            return data || [];
          })
          .catch((e) => {
            return [];
          });

        R.forEach((item) => {
          const findSystem = R.find((_item) => _item.systemKey === item.systemId, summaryObj);
          item.anomalyScore = findSystem?.avgHealthScore;
        }, systemList);

        const { views: maxViews } = maxBy(systemList, (item) => item.views);

        const dashNameOption = R.sortWith(
          [R.ascend(R.prop('label'))],
          R.uniqBy(
            (uniqItem) => uniqItem.label,
            R.map((item) => ({ label: item.dashBoardName, value: item.dashBoardName }), systemList || []),
          ),
        );
        const systemNameOption = R.sortWith(
          [R.ascend(R.prop('label'))],
          R.uniqBy(
            (uniqItem) => uniqItem.label,
            R.map((item) => ({ label: item.systemName, value: item.systemName }), systemList || []),
          ),
        );
        const authorNameOption = R.sortWith(
          [R.ascend(R.prop('label'))],
          R.uniqBy(
            (uniqItem) => uniqItem.label,
            R.map((item) => ({ label: item.author, value: item.author }), systemList || []),
          ),
        );

        localSystemList = systemList;
        const filterResult = filterData({ events: systemList, ...newState, activeTab });
        systemList = filterResult.eventList || [];
        const total = filterResult.total || 0;
        const newIsAllChecked = !R.find((item) => !item.checked, systemList || []);
        setState({
          systemList,
          isLoading: false,
          summaryObj,
          maxViews,
          dashNameOption,
          systemNameOption,
          authorNameOption,
          isAllChecked: newIsAllChecked,
          total,
        });
      })
      .catch((err) => {
        setState({ isLoading: false, systemList: [], summaryObj: [] });
      });
  };

  const renderPopularity = ({ dataKey, parent, rowIndex, cellData, columnIndex, rowData }) => {
    const { views } = rowData;
    const percent = Math.ceil((views >= maxViews ? maxViews : views / maxViews) * 4);
    const content = [];
    R.forEach((number) => {
      content.push(
        <div
          key={`chart${number}`}
          className="bold"
          style={{
            width: 3,
            height: 16,
            background:
              percent === 0
                ? 'var(--text-color-secondary)'
                : number < percent
                ? 'var(--green)'
                : 'var(--text-color-secondary)',
            borderRadius: 2,
          }}
        />,
      );
    }, R.range(0, 5));

    return (
      <CellMeasurer cache={cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <Popover content={<>{views}</>} placement="top" zIndex={99999}>
          <div className="flex-row flex-center-justify flex-center-align clickable full-width" style={{ gap: 4 }}>
            {content}
          </div>
        </Popover>
      </CellMeasurer>
    );
  };

  const getProgressColor = (anomalyScore) => {
    const { color } = calcColorOfHealthScore(isFinite(anomalyScore) ? anomalyScore : 100);
    return color;
  };

  const getProgressPercent = (anomalyScore) => {
    return Math.abs(isFinite(anomalyScore) ? anomalyScore : 0);
  };

  const getProgressFormat = (anomalyScore) => {
    const { color } = calcColorOfHealthScore(isFinite(anomalyScore) ? anomalyScore : 100);
    return (
      <div style={{ color: anomalyScore === 0 ? '#ccc' : color }}>
        {isFinite(anomalyScore) && anomalyScore !== 0 ? round(anomalyScore) : 'NA'}
      </div>
    );
  };

  const allCheckRender = () => {
    return (
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Checkbox
          size="small"
          checked={isAllChecked}
          onChange={(e) => {
            e.stopPropagation();
            setState({ isAllChecked: e.target.checked });
            R.forEach((item) => {
              item.checked = e.target.checked;
            }, systemList || []);
            cellMeasureCache.clearAll();
            if (listNode.current) listNode.current.forceUpdateGrid();
          }}
        />
      </div>
    );
  };

  const checkRender = ({ rowData, rowIndex, dataKey, parent, style, cellData }) => {
    return (
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Checkbox
          size="small"
          checked={cellData}
          onChange={(e) => {
            e.stopPropagation();
            rowData[dataKey] = e.target.checked;
            const newIsAllChecked = !R.find((item) => !item.checked, systemList || []);
            setState({ isAllChecked: newIsAllChecked });
            cellMeasureCache.clearAll();
            if (listNode.current) listNode.current.forceUpdateGrid();
          }}
        />
      </div>
    );
  };

  const renderAnomalyScore = ({ rowData, rowIndex, dataKey, parent, style }) => {
    const { anomalyScore } = rowData;
    return (
      <CellMeasurer cache={cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ padding: '6px 0' }}>
          <Progress
            type="dashboard"
            width={35}
            strokeColor={getProgressColor(anomalyScore)}
            strokeWidth={8}
            percent={getProgressPercent(anomalyScore)}
            format={(percent2) => getProgressFormat(anomalyScore)}
          />
        </div>
      </CellMeasurer>
    );
  };

  const handleListClick = (rowData) => {
    const { systemId, author, dashBoardName, systemFramework } = rowData;
    onShowLayoutDetail({ systemId, customerName: author, dashBoardName, systemFramework });
  };

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const contentRender = ({ dataKey, parent, rowIndex, cellData, columnIndex, rowData }) => {
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Popover title={null} content={cellData} placement="top" mouseEnterDelay={0.3}>
          <span className="hidden-line-with-ellipsis">{cellData}</span>
        </Popover>
      </CellMeasurer>
    );
  };

  const handleAllDelete = () => {
    const events = R.filter((item) => item.checked, localSystemList || []);
    const request = [];
    R.forEach((item) => {
      const { author: targetUser, systemOwner, systemId: systemName, dashBoardName: dashboardName } = item;
      request.push(
        fetchDelete(getEndpoint('dashboardmanagement'), {
          targetUser,
          systemName,
          dashboardName,
          customerName: systemOwner || targetUser,
        }),
      );
    }, events || []);

    setState({ isLoading: true });
    Promise.all(request)
      .then((data) => {
        const findError = R.find((item) => item.success === false, data || []);
        const findSussess = R.find((item) => item.success || item.success === undefined, data || []);
        if (findError) {
          setState({ isLoading: false });
          message.error(findError.message);
          if (findSussess) fetchData();
        } else {
          setState({ isLoading: false });
          message.success(intl.formatMessage(appMessages.apiSuccess));
          fetchData();
        }
      })
      .catch((err) => {
        setState({ isLoading: false });
        message.error(err?.message || String(err));
      });
  };

  // this is unused
  const handleRemove = (rowIndex) => {
    return (e) => {
      e.stopPropagation();
      const newSystemList = R.clone(systemList);
      const {
        author: targetUser,
        systemOwner,
        systemId: systemName,
        dashBoardName: dashboardName,
      } = newSystemList[rowIndex];

      setState({ isLoading: true });
      fetchDelete(getEndpoint('dashboardmanagement'), {
        targetUser,
        systemName,
        dashboardName,
        customerName: systemOwner || targetUser,
      })
        .then((res) => {
          const { success, message: msg } = res;
          if (success === undefined || success) {
            message.success(intl.formatMessage(appMessages.apiSuccess));
            fetchData();
            return;
          }
          setState({ isLoading: false });
          message.error(msg);
        })
        .catch((err) => {
          setState({ isLoading: false });
          message.error(err?.message);
        });
      setState({ systemList: newSystemList });
    };
  };

  // this is unused
  const renderAction = ({ dataKey, parent, rowIndex, cellData, columnIndex, rowData }) => {
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Popover
          content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
          mouseEnterDelay={0.3}
          placement="left"
        >
          <Button size="small" onClick={handleRemove(rowIndex)} disabled={isReadUser}>
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </Popover>
      </CellMeasurer>
    );
  };

  useEffect(() => {
    if (organizationName) {
      fetchData(true);
    }
  }, [organizationName]);

  useEffect(() => {
    cellMeasureCache.clearAll();
    if (listNode.current) listNode.current.forceUpdateGrid();
  }, [systemList]);

  useEffect(() => {
    const sortList = sortData(systemList, sortBy, sortDirection);
    setState({ systemList: sortList });
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (localSystemList && localSystemList.length > 0 && !isAdmin && !isLocalAdmin) {
      if (localSystemList.length === systemList.length) return;
      const { eventList: newSystemList, total } = filterData({
        dashNameFilter,
        systemNameFilter,
        authorNameFilter,
        sortBy,
        sortDirection,
        activeTab,
      });
      const newIsAllChecked = !R.find((item) => !item.checked, newSystemList || []);
      setState({ systemList: newSystemList, isAllChecked: newIsAllChecked, total });
    }
  }, []);

  useEffect(() => {
    // 做数据的过滤
    const { eventList: systemList, total } = filterData({
      dashNameFilter,
      systemNameFilter,
      authorNameFilter,
      sortBy,
      sortDirection,
      activeTab,
    });
    const newIsAllChecked = !R.find((item) => !item.checked, systemList || []);
    setState({
      systemList,
      isAllChecked: newIsAllChecked,
      total,
    });
  }, [dashNameFilter, systemNameFilter, authorNameFilter, activeTab]);

  const isDisabledDelete = !R.find((item) => item.checked, localSystemList || []);

  const onTabChange = (key) => {
    R.forEach((system) => {
      system.checked = false;
    }, systemList);
    setState({ activeTab: key, isAllChecked: false });
  };

  const tabWrap = (children) => {
    if (isAdmin || isLocalAdmin) {
      return children;
    }
    return (
      <Tabs
        onChange={onTabChange}
        destroyInactiveTabPane
        type="card"
        value={activeTab}
        className="full-height ant-tabs-content-full-height"
      >
        {R.map((item) => {
          return (
            <Tabs.TabPane
              tab={`${intl.formatMessage(DashboardMessages[item])} (Total: ${
                activeTab === item ? systemList.length : total - systemList.length
              })`}
              key={item}
              style={{ paddingTop: 12 }}
            >
              {cloneElement(children)}
            </Tabs.TabPane>
          );
        }, TAB_KEY)}
      </Tabs>
    );
  };
  return (
    <Spin wrapperClassName="full-width full-height spin-full-width" spinning={isLoading || isLinkLoading}>
      <div className="flex-col full-width full-height corner-8">
        <div style={{ padding: 10 }}>
          <div style={{ fontWeight: 'bold', fontSize: 20 }}>{`All dashboards (Total: ${total || 0})`}</div>
        </div>
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <div className="flex-row flex-center-align">
            <div style={{ marginRight: 8, fontSize: 14 }}>{intl.formatMessage(DashboardMessages.dashboardName)}:</div>
            <Select
              allowClear
              showSearch
              size="small"
              style={{ width: 200 }}
              value={dashNameFilter}
              options={dashNameOption}
              onChange={(dashNameFilter) => {
                setState({
                  dashNameFilter,
                });
              }}
            />
          </div>
          <div className="flex-row flex-center-align" style={{ marginLeft: 8 }}>
            <div style={{ marginRight: 8, fontSize: 14 }}>{intl.formatMessage(appFieldsMessages.systemName)}:</div>
            <Select
              allowClear
              showSearch
              size="small"
              style={{ width: 200 }}
              value={systemNameFilter}
              options={systemNameOption}
              onChange={(systemNameFilter) => {
                setState({
                  systemNameFilter,
                });
              }}
            />
          </div>
          <div className="flex-row flex-center-align" style={{ marginLeft: 8 }}>
            <div style={{ marginRight: 8, fontSize: 14 }}>{intl.formatMessage(DashboardMessages.author)}:</div>
            <Select
              allowClear
              showSearch
              size="small"
              style={{ width: 200 }}
              value={authorNameFilter}
              options={authorNameOption}
              onChange={(authorNameFilter) => {
                setState({
                  authorNameFilter,
                });
              }}
            />
          </div>
          <div className="flex-grow" />
          <Popover
            content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
            mouseEnterDelay={0.3}
            placement="left"
          >
            <Button type="primary" size="small" disabled={isReadUser || isDisabledDelete} onClick={handleAllDelete}>
              {intl.formatMessage(appButtonsMessages.delete)}
            </Button>
          </Popover>
        </div>
        <div className="flex-grow">
          {tabWrap(
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  rowStyle={{ cursor: 'pointer' }}
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={cellMeasureCache.rowHeight}
                  rowCount={systemList.length}
                  rowGetter={({ index }) => systemList[index]}
                  ref={listNode}
                  sort={sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  deferredMeasurementCache={cellMeasureCache}
                  onRowClick={({ rowData }) => {
                    handleListClick(rowData);
                  }}
                >
                  <Column
                    width={40}
                    label={null}
                    dataKey="checked"
                    headerRenderer={allCheckRender}
                    cellRenderer={checkRender}
                  />
                  <Column
                    width={80}
                    label={intl.formatMessage(DashboardMessages.health)}
                    dataKey="anomalyScore"
                    headerRenderer={headerRenderer}
                    cellRenderer={renderAnomalyScore}
                  />
                  <Column
                    width={150}
                    label={intl.formatMessage(DashboardMessages.dashboardName)}
                    dataKey="dashBoardName"
                    headerRenderer={headerRenderer}
                    cellRenderer={contentRender}
                  />
                  <Column
                    width={150}
                    flexGrow={1}
                    label={intl.formatMessage(appFieldsMessages.systemName)}
                    dataKey="systemName"
                    headerRenderer={headerRenderer}
                    cellRenderer={contentRender}
                  />
                  <Column
                    width={120}
                    label={intl.formatMessage(DashboardMessages.author)}
                    dataKey="author"
                    headerRenderer={headerRenderer}
                    cellRenderer={contentRender}
                  />
                  <Column
                    width={150}
                    label={intl.formatMessage(DashboardMessages.popularity)}
                    dataKey="views"
                    headerRenderer={headerRenderer}
                    cellRenderer={renderPopularity}
                    className="text-center"
                    headerClassName="text-center"
                  />
                  {false && (
                    <Column
                      width={100}
                      label=""
                      dataKey="systemId"
                      headerRenderer={headerRenderer}
                      cellRenderer={renderAction}
                      className="text-center"
                      headerClassName="text-center"
                    />
                  )}
                </Table>
              )}
            </AutoSizer>,
          )}
        </div>
      </div>
    </Spin>
  );
};
export default LayoutConfigures;
