import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { autobind } from 'core-decorators';
import { Button, Card, Collapse, Input, Tabs } from 'antd';
import { PlusOutlined, UpOutlined } from '@ant-design/icons';
import { State } from '../../common/types';
import { hideAppLoader, showAppLoader } from '../../common/app/actions';
import { Container, Popover } from '../../lib/fui/react';
import { appMessages } from '../../common/app/messages';
import { BaseUrls } from '../app/Constants';
import AppStore from '../app/components/AppStore';
import DataIngestionImage from '../../../images/start/data_ingestion_white.svg';
import DataIngestionImageDark from '../../../images/start/data_ingestion_dark.svg';
import IncidentPredictionImage from '../../../images/start/incident_prediction.svg';
import IncidentInvestigationImage from '../../../images/start/incident_investigation.svg';
import InsightsDashboardImage from '../../../images/start/insights_dashboard.png';
import AutomationImage from '../../../images/start/automation.svg';
import { eventMessages } from '../../common/metric/messages';

type Props = {
  intl: Object,
  location: Object,
  userInfo: Object,
  credentials: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  systemsMap: Object,
  projects: Array<Object>,
  allProjects: Array<Object>,
  push: Function,
  replace: Function,
  hideAppLoader: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  showAppLoader: Function,
  userList: Array<Object>,
  currentTheme: string,
  timezoneOffset: Number,
};

class StartPageCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { timezoneOffset } = props;

    const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
    const now = moment.utc(nowTimestamp);
    const hours = now.hours();

    let greetUser = 'evening';
    if (hours >= 5 && hours < 11) {
      greetUser = 'morning';
    } else if (hours >= 11 && hours < 16) {
      greetUser = 'afternoon';
    }

    this.state = {
      greetUser,
      appFilter: '',
    };
  }

  componentDidMount() {}

  @autobind
  handleAppSearch(e) {
    const appFilter = e.target.value;
    this.setState({ appFilter });
  }

  render() {
    const { intl, isReadUser, currentTheme } = this.props;
    const { greetUser, appFilter } = this.state;
    const isDark = currentTheme === 'dark';
    return (
      <Container
        fullHeight
        withGutter
        className="app-store flex-col corner-10"
        style={{ borderRadius: 10, overflowY: 'auto' }}
      >
        <div className="flex-col" style={{ width: '80%', minWidth: 960, margin: '32px auto 0 auto' }}>
          <div className="flex-row" style={{ alignItems: 'center' }}>
            <h2>
              {greetUser === 'morning'
                ? intl.formatMessage(appMessages.goodMorning, { userName: '' })
                : greetUser === 'afternoon'
                ? intl.formatMessage(appMessages.goodAfternoon, { userName: '' })
                : intl.formatMessage(appMessages.goodEvening, { userName: '' })}
            </h2>
            <div className="flex-grow" />
            <div style={{ paddingRight: 8 }}>
              <Input.Search
                size="small"
                style={{ width: 200, marginLeft: 16, marginTop: 0 }}
                placeholder="Find..."
                allowClear
                value={appFilter}
                onChange={this.handleAppSearch}
              />
            </div>
            <div>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
              >
                <Link to={BaseUrls.SettingsIntegrations} className="item">
                  <Button disabled={isReadUser} icon={<PlusOutlined />}>
                    Get data into InsightFinder
                  </Button>
                </Link>
              </Popover>
            </div>
          </div>
          <div>
            <Card style={{ fontSize: 14 }} bodyStyle={{ padding: 12 }} bordered={false}>
              <Collapse
                defaultActiveKey={['1']}
                ghost
                expandIconPosition="right"
                bordered={false}
                expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
              >
                <Collapse.Panel header="Starting guide" key="1">
                  <Tabs defaultActiveKey="1" tabPosition="left">
                    <Tabs.TabPane
                      tab={<div style={{ width: 200, textAlign: 'left' }}>Data Ingestion</div>}
                      key="data_ingestion"
                    >
                      <div className="flex-row">
                        <div className="flex-grow pl-2 flex-col">
                          <h3>Integrations to Power Your Team</h3>
                          <div>
                            Leverage your existing investments through our broad set of existing integrations with
                            leading monitoring tools.
                          </div>
                          <div style={{ marginTop: 20 }}>
                            <Popover
                              content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                              mouseEnterDelay={0.3}
                              placement="left"
                            >
                              <Link to={BaseUrls.SettingsIntegrations} className="item">
                                <Button disabled={isReadUser} type="primary">
                                  View integrations
                                </Button>
                              </Link>
                            </Popover>
                          </div>
                        </div>
                        <div style={{ width: 400, marginLeft: 16 }}>
                          <img
                            alt="InsightAgent"
                            style={{ width: '100%', height: 200, objectFit: 'contain' }}
                            src={isDark ? DataIngestionImageDark : DataIngestionImage}
                          />
                        </div>
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Incident Prediction" key="incident_prediction">
                      <div className="flex-row">
                        <div className="flex-grow pl-2 flex-col">
                          <h3>Preventing Downtime</h3>
                          <div>
                            InsightFinder Incident Prediction engine identifies patterns that lead to business impacting
                            incidents and leverage these patterns to alert IT operation teams before the business is
                            affected.
                          </div>
                          <div style={{ marginTop: 20 }}>
                            <Link to={BaseUrls.GlobalSystemPrediction} className="item">
                              <Button type="primary">Explore a predicted incident</Button>
                            </Link>
                          </div>
                        </div>
                        <div style={{ width: 400, marginLeft: 16 }}>
                          <img
                            alt="Incident Prediction"
                            style={{ width: '100%', height: 200, objectFit: 'contain' }}
                            src={IncidentPredictionImage}
                          />
                        </div>
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Incident Investigation" key="incident_investigation">
                      <div className="flex-row">
                        <div className="flex-grow pl-2 flex-col">
                          <h3>Reducing MTTR to Minutes</h3>
                          <div>
                            InsightFinder Root Cause Analysis engine dramtically shortens mean time to resolution (MTTR)
                            from hours/days to minutes by extracting the most important diagnostic information to
                            pinpoint the issue that is impacting your systems.
                          </div>
                          <div style={{ marginTop: 20 }}>
                            <Link to={BaseUrls.GlobalSystemRootCause} className="item">
                              <Button type="primary">Incident Investigation</Button>
                            </Link>
                          </div>
                        </div>
                        <div style={{ width: 400, marginLeft: 16 }}>
                          <img
                            alt="Incident Investigation"
                            style={{ width: '100%', height: 200, objectFit: 'contain' }}
                            src={IncidentInvestigationImage}
                          />
                        </div>
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Insights Dashboard" key="insights_dashboard">
                      <div className="flex-row">
                        <div className="flex-grow pl-2 flex-col">
                          <div>
                            Survey a comprehensive overview of your IT Ops ecosystem, including anomaly patterns,
                            resource utilization issues, and cloud cost trends. Also view calculations that demonstrate
                            overall cloud cost savings, number of anomalies and incidents detected, and number of
                            incidents predicted and average lead time achieved by the prediction engine.
                          </div>
                          <div style={{ marginTop: 20 }}>
                            <Link to={BaseUrls.GlobalDashboard} className="item">
                              <Button type="primary">Customize dashboard</Button>
                            </Link>
                          </div>
                        </div>
                        <div style={{ width: 400, marginLeft: 16 }}>
                          <img
                            alt="Insights Dashboard"
                            style={{ width: '100%', height: 200, objectFit: 'contain' }}
                            src={InsightsDashboardImage}
                          />
                        </div>
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Predictive Automation" key="automation">
                      <div className="flex-row">
                        <div className="flex-grow pl-2 flex-col">
                          <h3>Automating Level 1 support</h3>
                          <div>
                            InsightFinder predictive automation engine triggers proper repair actions such as instance
                            restart, auto-scaling and migrations by identifying performance bottlenecks and anomalies in
                            real time to scale up resources when needed and scale down resources when workload comes
                            down.
                          </div>
                          <div style={{ marginTop: 20 }}>
                            <Link to={BaseUrls.GlobalAction} className="item">
                              <Button type="primary">Customize action</Button>
                            </Link>
                          </div>
                        </div>
                        <div style={{ width: 400, marginLeft: 16 }}>
                          <img
                            alt="Insights Dashboard"
                            style={{ width: '100%', height: 200, objectFit: 'contain' }}
                            src={AutomationImage}
                          />
                        </div>
                      </div>
                    </Tabs.TabPane>
                  </Tabs>
                </Collapse.Panel>
              </Collapse>
            </Card>
            <AppStore appFilter={appFilter} />
          </div>
        </div>
      </Container>
    );
  }
}

const StartPage = injectIntl(StartPageCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { isAdmin, isLocalAdmin, isReadUser } = state.auth.userInfo;
    const {
      loaderStatus,
      userList,
      loadStatus,
      systemsMap,
      projects,
      allProjects,
      globalInfo,
      currentTheme,
      timezoneOffset,
    } = state.app;
    return {
      location,
      loaderStatus,
      loadStatus,
      userInfo,
      credentials,
      userList,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      systemsMap,
      projects,
      allProjects,
      globalInfo,
      currentTheme,
      timezoneOffset,
    };
  },
  { push, replace, hideAppLoader, showAppLoader },
)(StartPage);
