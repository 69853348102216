import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { replace, push } from 'react-router-redux';

import { Layout, Form, Input, Button, message, Breadcrumb } from 'antd';

import { autobind } from 'core-decorators';
import { NavLink } from 'react-router-dom';
import { parseLocation, buildUrl } from '../../../common/utils';
import { hideAppLoader } from '../../../common/app/actions';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';

import logo from '../../../../images/InsightFinder_Horizontal.svg';
import logoWhite from '../../../../images/InsightFinder_Horizontal_White.svg';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';

const { Item } = Form;
type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  replace: Function,
  hideAppLoader: Function,
  currentTheme: String,
  credentials: Object,
  push: Function,
};

class ExternalZoomAuthSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { hideAppLoader } = this.props;
    hideAppLoader();
  }

  @autobind
  handleSaveClick(values) {
    const { location, credentials, intl, push } = this.props;
    const { code } = parseLocation(location);
    if (!code) {
      message.error('Incorrect Zoom code');
      return;
    }
    // push(buildUrl('/auth/login2', {}, {}));
    this.setState({ loading: true });
    fetchPost(getEndpoint('zoom-auth-login', 1), { ...credentials, code, ...values })
      .then((res) => {
        message.success(intl.formatMessage(appMessages.success));
        this.setState({ loading: false });
      })
      .catch((e) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ loading: false });
      });
  }

  render() {
    const { intl, currentTheme } = this.props;
    const { loading } = this.state;
    return (
      <Layout className="full-width full-height">
        <div className="full-width full-height content-bg" style={{ position: 'relative' }}>
          <div
            className="flex-col flex-center-align flex-center-justify"
            style={{
              padding: 70,
              border: '1px solid var(--border-color-split)',
              borderRadius: 10,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-100%)',
            }}
          >
            <img
              src={currentTheme === 'light' ? logo : logoWhite}
              alt="zoom-logo"
              style={{ width: 200, marginBottom: 30 }}
            />
            <Form size="small" labelCol={{ span: 7 }} onFinish={this.handleSaveClick}>
              <Item
                label={intl.formatMessage(appFieldsMessages.userName)}
                name="UserName"
                rules={[{ required: true, message: intl.formatMessage(appFieldsMessages.inputRequired) }]}
                required
              >
                <Input />
              </Item>
              <Item
                label={intl.formatMessage(appFieldsMessages.password)}
                name="password"
                rules={[{ required: true, message: intl.formatMessage(appFieldsMessages.inputRequired) }]}
                required
              >
                <Input.Password />
              </Item>
              <Item className="flex-row flex-center-justify">
                <Button style={{ width: 300 }} htmlType="submit" type="primary" loading={loading}>
                  {intl.formatMessage(appButtonsMessages.submit)}
                </Button>
              </Item>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }
}

const ExternalZoomAuthSetting = injectIntl(ExternalZoomAuthSettingCore);
export default connect(
  (state) => {
    const { currentTheme } = state.app;
    const { credentials } = state.auth;
    const { location } = state.router;
    return { location, currentTheme, credentials, push };
  },
  { replace, hideAppLoader, push },
)(ExternalZoomAuthSetting);
