/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Alert, Select, message } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
// import { Defaults } from '../../../../common/utils';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { Modal } from '../../../../lib/fui/react';

import { settingsMessages } from '../../../../common/settings/messages';
import { appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { eventMessages } from '../../../../common/metric/messages';

type Props = {
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  currentProject: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  credentials: Object,
  projects: Array<Object>,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  userInfo: Object,
};

class GroupImportInstanceMetaModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { projectName, currentProject, projects } = props;
    let projectList = [];
    if (currentProject.systemId) {
      projectList = R.filter(
        (item) => item.projectName !== projectName && item.systemId === currentProject.systemId,
        projects || [],
      );
    }

    this.state = {
      isSubmiting: false,
      errMsg: '',

      projectName: undefined,
    };
    this.projectList = projectList;
  }

  @autobind
  handleConfirm() {
    const { intl, credentials, onClose, projectName: project, projects } = this.props;
    const { projectName } = this.state;

    this.setState({ isSubmiting: true });
    const projectInfo = R.find((item) => item.projectName === projectName, projects || []);
    const { projectShortName, owner } = projectInfo;

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('importinstancemetadata'), {
      ...credentials,
      project,
      sourceProject: projectShortName,
      sourceUsername: owner,
    })
      .then((data) => {
        const { success, message } = data || {};
        this.setState({ isSubmiting: false, errMsg: !success ? message : null });
        if (success) onClose(true);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  render() {
    const { intl, onClose, userInfo } = this.props;
    const { isSubmiting, errMsg, projectName } = this.state;
    const hasError = !projectName;

    return (
      <Modal
        title={intl.formatMessage(settingsMessages.importInstanceMetaData)}
        width={650}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleConfirm}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
          <Form.Item
            label={intl.formatMessage(eventMessages.fieldCauseProject)}
            validateStatus={!projectName ? 'error' : 'success'}
            help={!projectName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={projectName}
              onChange={(projectName) => this.setState({ projectName })}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.projectName}>{`${item.projectDisplayName}${
                    userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName
                      ? `@${item.owner}`
                      : ''
                  }`}</Select.Option>
                ),
                this.projectList || [],
              )}
            </Select>
          </Form.Item>

          {errMsg && (
            <Form.Item
              wrapperCol={{
                sm: { span: 12, offset: 6 },
              }}
            >
              <Alert message={errMsg} type="error" showIcon />
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

const GroupImportInstanceMetaModal = injectIntl(GroupImportInstanceMetaModalCore);
export default connect(
  (state) => {
    const { credentials, userInfo } = state.auth;
    const { projects } = state.app;
    return {
      credentials,
      projects,
      userInfo,
    };
  },
  { updateLastActionInfo },
)(GroupImportInstanceMetaModal);
