/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { isNumber } from 'lodash';

import { Container, AutoSizer, List, Tooltip } from '../../src/lib/fui/react';
import { chopString } from '../../src/common/utils';

import DataChart from './DataChart';

type ChartsProps = {
  groups: any,
  view: String,
  project: Object,
  columns: String,
  orderByMetric: Boolean,
  onDateWindowChange: Function,
  chartType: String,
  dateWindow: any,
  metricTags: any,
  latestDataTimestamp: any,
  isEmailAert: Boolean,
  selectedInstanceList: any,
};

class DataGroupCharts extends React.PureComponent {
  props: ChartsProps;

  static defaultProps = {
    orderByMetric: true,
  };

  constructor(props) {
    super(props);

    this.columnSize = 4;

    this.state = {
      selectedIndex: null,
    };

    this.convertData(props);
  }

  componentDidMount() {
    this.listNode.forceUpdateGrid();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.groups !== this.props.groups ||
      nextProps.view !== this.props.view ||
      nextProps.orderByMetric !== this.props.orderByMetric
    ) {
      this.convertData(nextProps);
      this.setState({ selectedIndex: null }, () => {
        this.listNode.forceUpdateGrid();
      });
    }
    if (this.props.view !== nextProps.view) {
      if (this.listNode) {
        this.listNode.forceUpdateGrid();
      }
    }
  }

  @autobind
  convertData(props) {
    const { orderByMetric, groups } = props;

    if (orderByMetric) {
      // If group contains highlights, order by the highlights count
      // otherwise, order by name
      this.localGroups = R.sort((a, b) => {
        if (a.highlights.length === b.highlights.length) {
          return (a.metrics || '').localeCompare(b.metrics);
        }
        return b.highlights.length - a.highlights.length;
      }, groups);
    } else {
      this.localGroups = groups;
    }
  }

  @autobind
  setListNode(n) {
    this.listNode = n;
  }

  @autobind
  handleItemClick(idx) {
    return () => {
      const { selectedIndex } = this.state;
      if (selectedIndex === idx) {
        this.setState({ selectedIndex: null }, () => {
          this.listNode.forceUpdateGrid();
        });
      } else {
        this.setState({ selectedIndex: idx }, () => {
          this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  handleItemClear() {
    this.setState({ selectedIndex: null }, () => {
      this.listNode.forceUpdateGrid();
    });
  }

  @autobind
  renderListItem({ index, key, style, isScrolling, isVisible }) {
    const { view } = this.props;
    const isLoading = false;
    const { project, latestDataTimestamp, isEmailAert, chartType, selectedInstanceList } = this.props;

    const isListView = view === 'list';
    const syncDateWindow = isListView;
    const renderGroupChart = (g, isFullWide, isDetailed, isSelected) => (
      <div className="content content-bg" style={isDetailed ? { padding: '28px 8px 4px 8px' } : {}}>
        <Tooltip
          title={<div style={{ maxWidth: 200, wordBreak: 'break-all', display: 'inline-block' }}>{g.metrics}</div>}
          placement="right"
        >
          <div className="ui header">{chopString(g.metrics, 20)}</div>
        </Tooltip>
        <DataChart
          fullWide={isFullWide}
          project={project}
          chartType={chartType}
          enableTriangleHighlight
          data={g}
          latestDataTimestamp={latestDataTimestamp}
          selectedInstanceList={selectedInstanceList}
          isEmailAert={isEmailAert}
          onDateWindowChange={syncDateWindow ? this.props.onDateWindowChange : null}
          dateWindow={syncDateWindow ? this.props.dateWindow : null}
        />
        {isSelected && (
          <div
            style={{
              position: 'absolute',
              width: 20,
              height: 20,
              background: '#333',
              transform: 'rotate(45deg)',
              left: '50%',
              bottom: '-25px',
              marginLeft: -3,
            }}
          />
        )}
        {isDetailed && (
          <i
            onClick={this.handleItemClear}
            className="clickable close icon"
            style={{ position: 'absolute', right: 2, top: 2, color: '#fff', background: 'black' }}
          />
        )}
      </div>
    );

    if (isListView) {
      const group = this.localGroups[index];

      return (
        <Container key={key} style={{ ...style }} className={isLoading ? 'loading' : ''}>
          <div className="detail-charts" key={group.metrics} style={{ position: 'relative' }}>
            {renderGroupChart(group, true)}
          </div>
        </Container>
      );
    }

    // For grid view, display items in one row and also display the selected group in full row.
    const { selectedIndex } = this.state;
    const selectedGroup = isNumber(selectedIndex) ? this.localGroups[selectedIndex] : null;
    const selected = Boolean(selectedGroup);
    const selectedRowId = selected ? Math.floor(selectedIndex / this.columnSize) : null;

    // Display the selected chart in the next row
    if (selected && index === selectedRowId + 1) {
      return (
        <Container key={`${key}-${selectedIndex}`} style={style} className={isLoading ? 'loading' : ''}>
          <div style={{ width: '100%', background: 'rgb(51, 51, 51)', padding: '4px', position: 'relative' }}>
            {renderGroupChart(selectedGroup, true, true)}
          </div>
        </Container>
      );
    }

    // Remove the selected row to get the real row
    let rowIndex = index;
    if (selected && index > selectedRowId + 1) {
      rowIndex = index - 1;
    }

    return (
      <Container key={key} style={style} className={isLoading ? 'loading' : ''}>
        <div className="ui four cards" style={{ margin: 0 }}>
          {R.map((ridx) => {
            const idx = rowIndex * this.columnSize + ridx;
            const group = this.localGroups[idx];
            if (group) {
              return (
                <div
                  key={group.metrics}
                  className="ui card"
                  style={{ position: 'relative' }}
                  onClick={this.handleItemClick(idx)}
                >
                  {renderGroupChart(group, false, false, selectedIndex === idx)}
                </div>
              );
            }
            return null;
          }, R.range(0, this.columnSize))}
        </div>
      </Container>
    );
  }

  render() {
    const { view } = this.props;
    const { selectedIndex } = this.state;
    const isListView = view === 'list';

    const selectedGroup = isNumber(selectedIndex) ? this.localGroups[selectedIndex] : null;
    const selected = Boolean(selectedGroup);

    const selectedRowCount = selected ? 1 : 0;
    const groupCount = this.localGroups.length;
    const rowCount = isListView
      ? groupCount + selectedRowCount
      : Math.ceil(groupCount / this.columnSize) + selectedRowCount;

    return (
      <div className="flex-grow overflow-hidden">
        <AutoSizer>
          {({ width, height }) => (
            <List
              ref={this.setListNode}
              width={width}
              height={height}
              rowCount={rowCount}
              overscanRowCount={4}
              rowHeight={isListView ? 248 : 264}
              rowRenderer={this.renderListItem}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default DataGroupCharts;
