import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { get, floor, isFinite, isArray } from 'lodash';
import Icon, { CloseCircleOutlined, ClusterOutlined } from '@ant-design/icons';
import { Tooltip, Progress, Card, Popover } from 'antd';
import { FavoriteIcon } from '../../../lib/fui/icons';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { calcColorOfHealthScore } from '../../../common/utils';
// import { MoveIcon } from '../../../lib/fui/icons';

import { appFieldsMessages } from '../../../common/app/messages';

import SystemHealthCard from './SystemHealthCard';
import { eventMessages } from '../../../common/metric/messages';

const GlobalHealthSystemList = (props: Object) => {
  const {
    width,
    items,
    intl,
    credentials,
    disablSort,
    systemList,
    healthList,
    handleSystemClick,
    systemLoading,
    isAutoReload,
    customerName,
    environmentId,
    startTime,
    endTime,
    forceRefreshTime,
    onSetQueryParams,
    handleFavorite,
    userInfo,
  } = props;
  const cardWidth = floor((width - 17) / 2);
  return (
    <div className="flex-row flex-wrap" style={{ width, minHeight: 240 }}>
      {items.map((item, index) => {
        const systemInfo = R.find((system) => system.id === item.id, systemList || []);
        const systemHealth = R.find((system) => system.id === item.id, healthList || []);
        const isLoadingSystemChart = get(systemLoading, [item.id], false);
        return (
          <SystemItem
            key={`item-${item.id}`}
            index={index}
            itemIndex={index}
            id={item.id}
            item={item}
            cardWidth={cardWidth}
            intl={intl}
            credentials={credentials}
            disablSort={disablSort}
            systemInfo={systemInfo}
            systemHealth={systemHealth}
            handleSystemClick={handleSystemClick}
            isLoadingSystemChart={isLoadingSystemChart}
            isAutoReload={isAutoReload}
            customerName={customerName}
            environmentId={environmentId}
            startTime={startTime}
            endTime={endTime}
            forceRefreshTime={forceRefreshTime}
            onSetQueryParams={onSetQueryParams}
            handleFavorite={handleFavorite}
            userInfo={userInfo}
          />
        );
      })}
    </div>
  );
};

const SystemItem = (props: Object) => {
  const {
    itemIndex,
    handleFavorite,
    id,
    item,
    cardWidth,
    intl,
    credentials,
    systemInfo: localSystemInfo,
    systemHealth,
    handleSystemClick,
    isLoadingSystemChart,
    isAutoReload,
    customerName,
    environmentId,
    startTime,
    endTime,
    forceRefreshTime,
    onSetQueryParams,
    userInfo,
  } = props;
  const { systemName, owner, favorite } = item;
  const { hasData } = localSystemInfo || {};
  const { avgHealthScore } = systemHealth || {};
  const displayScore = avgHealthScore;
  const { color } = calcColorOfHealthScore(displayScore);

  return (
    <div style={{ width: cardWidth - 8, maxWidth: cardWidth - 8, minWidth: 300, margin: 8 }}>
      <Card
        size="small"
        title={
          <div className="flex-row flex-center-align">
            <div className="flex-grow flex-min-width flex-row flex-center-align">
              <ClusterOutlined style={{ marginRight: 4, fontSize: 14 }} />
              <div className="flex-grow flex-min-width">
                <Tooltip title={`${systemName}${owner ? ` (${owner})` : ''}`} placement="top" mouseEnterDelay={0.3}>
                  <span
                    className="max-width flex-min-width hidden-line-with-ellipsis inline-block font-14 bold"
                    style={{ paddingRight: 20 }}
                  >
                    {`${systemName}${owner ? ` (${owner})` : ''}`}
                  </span>
                </Tooltip>
              </div>
            </div>

            <div>{intl.formatMessage(eventMessages.avgHealthScore)}: </div>
            {!hasData && (
              <Tooltip title={intl.formatMessage(appFieldsMessages.missingData)} placement="top" mouseEnterDelay={0.3}>
                <CloseCircleOutlined style={{ color: 'gray', minWidth: 28, fontSize: 14, margin: '0 8px' }} />
              </Tooltip>
            )}
            {hasData && (
              <Tooltip title={intl.formatMessage(eventMessages.avgHealthScore)} placement="top" mouseEnterDelay={0.3}>
                <Progress
                  type="dashboard"
                  style={{ margin: '0 8px' }}
                  width={28}
                  strokeColor={color}
                  strokeWidth={8}
                  percent={Math.abs(isFinite(displayScore) ? displayScore : 0)}
                  format={(percent2) => {
                    return <div style={{ color }}>{isFinite(displayScore) ? floor(displayScore) : 'N/A'}</div>;
                  }}
                />
              </Tooltip>
            )}

            <div className="flex-grow flex-min-width flex-row flex-end-justify">
              <Popover
                placement="topLeft"
                content={
                  userInfo.isReadUser
                    ? intl.formatMessage(eventMessages.isReadUserDisable)
                    : 'Pin the current system at the top'
                }
              >
                <Icon
                  component={FavoriteIcon}
                  style={{ color: favorite ? 'var(--yellow)' : 'var(--disabled-color)', fontSize: 16 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!userInfo.isReadUser) handleFavorite(e, { ...item, index: itemIndex });
                  }}
                />
              </Popover>
            </div>
          </div>
        }
        style={{ width: '100%' }}
        hoverable
        onClick={(e) => handleSystemClick(e, id)}
      >
        <SystemHealthCard
          systemHealth={systemHealth}
          systemInfo={localSystemInfo}
          isLoading={isLoadingSystemChart}
          isAutoReload={isAutoReload}
          customerName={customerName}
          environmentId={environmentId}
          startTime={startTime}
          endTime={endTime}
          forceRefreshTime={forceRefreshTime}
          onSetQueryParams={onSetQueryParams}
        />
      </Card>
    </div>
  );
};

export default GlobalHealthSystemList;
