import React from 'react';
import * as R from 'ramda';
import { get, isFunction } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { replace } from 'react-router-redux';
import { SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Radio, Row, Select } from 'antd';

import moment from 'moment';
import { Modal, Container, Popover } from '../../../lib/fui/react';
import { appButtonsMessages } from '../../../common/app/messages';
import { settingsMessages } from '../../../common/settings/messages';

type Props = {
  system: Object,
  onConfirm: Function,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  events: Object,
  incident: Object,
  isAdmin: Boolean,
  userName: String,
  activeKey: String,
};

class ProjectSelectorCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { system, events, incident, activeKey, isAdmin, userName } = props;
    const projectNameSet = get(system, ['projectNameSet'], []);
    const globaInstaceMappingProject = get(system, ['globaInstaceMappingProject'], {});

    const projects = R.filter((project) => {
      return (
        project.category === 'metric' &&
        project.projectNameReal.indexOf('frequency') < 0 &&
        project.projectName.indexOf('frequency') < 0 &&
        project.projectDisplayName.indexOf('frequency') < 0
      );
    }, events || []);

    let metricProjects = R.filter(
      (project) =>
        project.dataType === 'Metric' &&
        project.projectNameReal.indexOf('frequency') < 0 &&
        project.projectName.indexOf('frequency') < 0 &&
        project.projectDisplayName.indexOf('frequency') < 0,
      projectNameSet,
    );
    metricProjects = R.sortWith([R.descend(R.compose(R.toLower, R.prop('projectName')))], metricProjects);

    if (incident && activeKey === 'incident') {
      const { projectOwner } = incident;
      let { projectName } = incident;
      projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
      const findIdx = R.findIndex((item) => projectName === item.projectNameReal, metricProjects || []);
      if (findIdx !== -1) {
        const peojectOwnsTheIncident = (metricProjects || [])[findIdx];
        const otherPeoject = R.remove(findIdx, 1, metricProjects || []);
        metricProjects = [{ ...peojectOwnsTheIncident, hasOwnsTheIncident: true }, ...otherPeoject];
      }
    }

    const mappingProjectMap = get(globaInstaceMappingProject, system.id, {});
    const instanceList = R.keys(mappingProjectMap || {});
    let instace1 = incident?.realInstanceName || '';
    instace1 = R.includes('_', instace1) ? R.split('_', instace1)[1] : instace1;
    let instace2 = incident?.instanceListStr || incident?.sourceInstanceName || '';
    instace2 = R.includes('_', instace2) ? R.split('_', instace2)[1] : instace2;
    const findInstance = R.filter(
      (ins) =>
        (instace1 && instace1 === ins) ||
        (instace2 && instace2 === ins) ||
        (instace1 && R.includes(ins, instace1)) ||
        (instace1 && R.includes(instace1, ins)) ||
        (instace2 && R.includes(ins, instace2)) ||
        (instace2 && R.includes(instace2, ins)),
      instanceList || [],
    );
    const mappingProjectInfos = [];
    R.forEach((ins) => {
      const mappingProjects = get(mappingProjectMap, ins, []);
      mappingProjectInfos.push(...R.filter((p) => p.instanceName === ins && p.dataType === 'Metric', mappingProjects));
    }, findInstance || []);

    const newMetricProjects = [];
    R.forEach((item) => {
      const findP = R.find((p) => item.projectName === p.projectName, mappingProjectInfos || []);
      if (findP) newMetricProjects.push({ ...item, instaceName: findP.instanceName, cms: findP.cms });
    }, metricProjects || []);
    metricProjects = newMetricProjects;

    const allProjectName = metricProjects[0]?.projectNameReal || '';

    this.state = {
      currentProjectName: projects.length > 0 ? `${projects[0].projectName}@${projects[0].projectOwner}` : '',
      allProjectName,
      metricProjects,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleProjectChange(projectName) {
    this.setState({ allProjectName: projectName, currentProjectName: '' });
  }

  @autobind
  handleCurrentProjectChange(projectName) {
    this.setState({ currentProjectName: projectName, allProjectName: '' });
  }

  render() {
    const { intl, onClose, system, onConfirm, events } = this.props;
    const { currentProjectName, allProjectName, metricProjects } = this.state;

    let projectNames = R.filter((project) => {
      return (
        project.category === 'metric' &&
        project.projectNameReal.indexOf('frequency') < 0 &&
        project.projectName.indexOf('frequency') < 0 &&
        project.projectDisplayName.indexOf('frequency') < 0
      );
    }, events || []);
    projectNames = R.map((p) => p.projectName, projectNames);

    let projectNameSet = get(system, ['projectNameSet'], []);
    projectNameSet = R.filter(
      (item) =>
        item.projectNameReal.indexOf('frequency') < 0 &&
        item.projectName.indexOf('frequency') < 0 &&
        item.projectDisplayName.indexOf('frequency') < 0,
      projectNameSet,
    );

    const currentProject = R.filter(
      (project) => project.dataType === 'Metric' && projectNames.includes(project.projectName),
      projectNameSet,
    );

    const showError = !allProjectName && metricProjects.length === 0;
    const findProject = R.find((p) => p.projectNameReal === allProjectName, metricProjects || []);

    return (
      <Modal width={650} title="Select project" onCancel={() => onClose()} footer={null} visible maskClosable={false}>
        {showError && <Alert message="The selected instance is not found!" type="error" style={{ marginBottom: 8 }} />}
        <Container className="flex-col content">
          {!R.isEmpty(currentProject) && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 24 }}>
              <span className="label" style={{ width: 150, fontWeight: 'bold', marginLeft: 20 }}>
                {intl.formatMessage(settingsMessages.anomalyMetricProjects)}:
              </span>
              <Select style={{ width: 200 }} value={currentProjectName} onChange={this.handleCurrentProjectChange}>
                {R.map(
                  (item) => (
                    <Select.Option key={item.projectNameReal}>{item.projectDisplayName}</Select.Option>
                  ),
                  currentProject || [],
                )}
              </Select>
            </div>
          )}

          <div className="flex-row flex-center-align" style={{ marginBottom: 24 }}>
            <span className="label" style={{ width: 150, fontWeight: 'bold', marginLeft: 20 }}>
              {R.isEmpty(currentProject) ? 'Metric projects' : intl.formatMessage(settingsMessages.OtherMeticProjects)}:
            </span>
            <Radio.Group
              onChange={(e) => this.handleProjectChange(e.target.value)}
              value={allProjectName}
              className="flex-grow flex-min-width"
            >
              <Row style={{ maxHeight: 200, overflowY: 'auto' }}>
                {R.map((item) => {
                  const title = `${item.projectDisplayName}${item.hasOwnsTheIncident ? ' (Incident project)' : ''}`;
                  return (
                    <Col
                      span={24}
                      key={item.projectNameReal}
                      className="flex-row flex-center-align"
                      style={{ marginTop: 4, height: 25 }}
                    >
                      <Radio key={item.projectNameReal} value={item.projectNameReal} />
                      <span className="flex-grow flex-row flex-center-align overflow-hidden full-height">
                        <Popover content={title} mouseEnterDelay={0.3} placement="topLeft" trigger="hover">
                          <span
                            className="hidden-line-with-ellipsis flex-grow full-height font-12"
                            style={{ lineHeight: '25px' }}
                          >
                            {title}
                          </span>
                        </Popover>
                      </span>
                    </Col>
                  );
                }, metricProjects || [])}
              </Row>
            </Radio.Group>
          </div>
          <div className="flex-row">
            <div className="flex-grow" />
            <Button
              type="primary"
              size="small"
              icon={<SaveOutlined />}
              onClick={() =>
                isFunction(onConfirm)
                  ? onConfirm(currentProjectName || allProjectName, {
                      ins: findProject?.instaceName,
                      cms: findProject?.cms,
                    })
                  : null
              }
              disabled={showError}
            >
              {intl.formatMessage(appButtonsMessages.confirm)}
            </Button>
          </div>
        </Container>
      </Modal>
    );
  }
}

const ProjectSelector = injectIntl(ProjectSelectorCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { isAdmin, userName } = state.auth.userInfo;
    return {
      location,
      isAdmin,
      userName,
    };
  },
  { replace },
)(ProjectSelector);
