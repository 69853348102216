/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import parseQueryString from './parseQueryString';

/**
 * Parse url location query string into object, return empty object is empty string.
 * The query string can be retrieved from location.search.
 */
const parseLocation = (location) => {
  return parseQueryString((location || {}).search);
};

export default parseLocation;
