/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import VLink from 'valuelink';
import numeral from 'numeral';
import { get, toInteger } from 'lodash';
import { autobind } from 'core-decorators';

import { Container, Select, Table, Column, CellMeasurerCache } from '../../../lib/fui/react';
import { sortByString, ifIn, Defaults, CellRenderers } from '../../../common/utils';
import { Defaults as DefaultsApp } from '../../../common/app';
// import { CellRenderers } from '../../../web/share';

import { DataChart } from '../../../../components/share/charts';
import EventGroup from '../../../../components/log/loganalysis/event-group';

type Props = {
  width: Number,
  height: Number,
  queryResult: Object,
  queryParams: Object,
  currentTheme: String,
};

class AnomalyLogEvent extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.contentWidth = 570;
    this.gridOffsetY = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.timeRender = ({ cellData }) => (cellData ? moment.utc(parseInt(cellData, 10)).format('YYYY-MM-DD HH:mm') : '');

    this.timeInterval = [
      { label: '10 minutes', value: 10 * 60 * 1000 },
      { label: '30 minutes', value: 30 * 60 * 1000 },
      { label: '1 hour', value: 60 * 60 * 1000 },
      { label: '2 hours', value: 2 * 60 * 60 * 1000 },
      { label: '6 hours', value: 6 * 60 * 60 * 1000 },
      { label: '1 day', value: 24 * 60 * 60 * 1000 },
      { label: '1 week', value: 7 * 24 * 60 * 60 * 1000 },
    ];

    this.timeRender = ({ cellData }) => (cellData ? moment.utc(parseInt(cellData, 10)).format('YYYY-MM-DD HH:mm') : '');
    this.barRender = ({ barData, activePatternId }) => {
      return (
        <DataChart
          style={{ height: 150, marginTop: 40, marginLeft: -30, marginBottom: 10 }}
          isLogCharts
          chartType="multiStackBar"
          data={barData}
          annotations={[]}
          color={activePatternId ? this.nidMapColor[activePatternId] : null}
          defaultBarColor="rgb(242, 113, 28)"
          onClick={this.handlePatternPointClick}
        />
      );
    };

    const queryResult = props.queryResult || {};
    const instanceName = sortByString(R.keys(queryResult))[0];

    this.logAnomalyList = [];
    this.snameColor = [];
    this.nidMapColor = {};
    this.state = {
      instanceName,
      timeInterval: this.timeInterval[2].value,

      barData: null,
      logAnomalyList: [],
      selectedStartTs: null,
      activePatternId: null,
    };
  }

  componentDidMount() {
    this.reloadData(this.props, this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      const queryResult = nextProps.queryResult || {};
      let { instanceName } = this.state;
      const names = sortByString(R.keys(queryResult));
      if (!ifIn(instanceName, names)) {
        instanceName = names[0];
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      this.props.queryResult !== nextProps.queryResult ||
      this.state.instanceName !== nextState.instanceName ||
      this.state.timeInterval !== nextState.timeInterval
    ) {
      this.reloadData(nextProps, nextState);
    }
  }

  @autobind
  reloadData(props, state) {
    const { queryResult, queryParams, project } = props;
    const { projectName, templateId } = queryParams || {};
    const { instanceName } = state;

    const projectLinkInfo = get(project, 'projectLinkInfo', []);
    const isHot = templateId === '18a34799e749452d96eb20cd9f44f6c0';

    let logAnomalyList = [];
    R.forEachObjIndexed((anomalies) => {
      R.forEach((a) => {
        const { hintTime } = a;
        const result = get(a, 'result', []);
        if (result.length > 0) {
          R.forEach((item) => {
            const { entryCount, eventArray, hintInfo } = item;
            if (eventArray && hintInfo) {
              const { nid } = eventArray[0];
              const { rawData } = eventArray[0];
              const count = entryCount;
              const percent = numeral(Math.abs(parseFloat(hintInfo.percentageStr)) / 100).format('0.0%');
              logAnomalyList.push({
                eventArray: R.sort((a, b) => a.timestamp - b.timestamp, eventArray),
                projectName,
                instanceName,
                timestamp: hintTime,
                nid,
                patternName: hintInfo.patternName,
                direction: hintInfo.changeDirection,
                percent,
                count,
                rawData,
                frequencyStr: `Count: ${count}. Frequency is ${percent} ${isHot ? 'higher' : 'lower'} than normal.`,
              });
            }
          }, result);
        }
      }, anomalies || []);
    }, get(queryResult, instanceName, {}));

    // get data info
    const { snameColor, nidMapColor } = this.getBarNameColors(logAnomalyList);

    if (logAnomalyList.length > 0) {
      logAnomalyList = R.map((event) => {
        return { ...event, projectLinkInfo };
      }, logAnomalyList);
    }

    this.snameColor = snameColor;
    this.nidMapColor = nidMapColor;
    this.logAnomalyList = logAnomalyList;

    // parse data
    this.parseData(queryParams, state);
  }

  @autobind
  parseData(queryParams, state) {
    const { timeInterval, activePatternId } = state;
    let { selectedStartTs } = state;

    let { logAnomalyList } = this;

    // filter
    if (activePatternId) {
      logAnomalyList = R.filter((item) => item.nid === activePatternId, logAnomalyList);
    }

    // get chart data
    const barData = this.getBarData(queryParams, logAnomalyList, timeInterval);

    // sort and filter
    logAnomalyList = R.sortWith([R.ascend(R.prop('timestamp')), R.descend(R.prop('count'))], logAnomalyList);

    if (!selectedStartTs && logAnomalyList.length > 0)
      selectedStartTs = toInteger(logAnomalyList[0].timestamp / timeInterval) * timeInterval;
    logAnomalyList = R.filter(
      (item) => item.timestamp >= selectedStartTs && item.timestamp < selectedStartTs + timeInterval,
      logAnomalyList,
    );

    this.setState({ barData, logAnomalyList, selectedStartTs });
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind
  handlePatternPointClick(startTs, position) {
    this.setState(
      {
        selectedStartTs: startTs,
      },
      () => {
        const { queryParams } = this.props;
        this.parseData(queryParams, this.state);
      },
    );
  }

  @autobind
  anomalyRender({ cellData }) {
    const { percent } = cellData;
    return `${percent.toFixed(2)}%`;
  }

  @autobind
  nameRender({ rowData }) {
    const { patternName, nid } = rowData;
    return patternName || `Pattern ${nid}`;
  }

  @autobind
  handleContentChanged() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind
  contentRender(props) {
    const { queryParams, currentTheme } = this.props;
    const { cellData } = props;
    const message = cellData || '';

    const { keyword } = queryParams || {};
    const keywords = R.map(
      (k) => R.trim(R.replace(/"/g, '', k)),
      R.split(' ', R.replace(/\|\|/g, ' ', R.replace(/&&/g, ' ', keyword || ''))),
    );

    let content;
    if (message[0] !== '{') {
      content = R.join(
        '\n',
        R.map((e) => `${moment.utc(e.timestamp).format(Defaults.TimeFormat)}: ${e.rawData}`, message || []),
      );
    }

    return CellRenderers.logContent(props, this.cellMeasureCache, {
      width: this.contentWidth,
      content,
      highlightWord: keywords,
      onChanged: this.handleContentChanged,
      currentTheme,
    });
  }

  @autobind
  handleInstanceNameChange() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdate();
    }
  }

  @autobind
  handleTimeIntervalChange() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdate();
    }
  }

  @autobind
  getBarData(queryParams, logEntryList, timeInterval) {
    const { startTimeObj, endTimeObj } = queryParams;
    let startT;
    let endT;
    if (startTimeObj && endTimeObj) {
      startT = startTimeObj.valueOf();
      endT = endTimeObj.valueOf();
    }
    if (!startT || !endT) {
      return undefined;
    }

    const nids = [];
    const snames = ['Datetime', 'Sum'];
    R.forEach((log) => {
      const { patternName } = log;
      R.forEach((e) => {
        const { nid } = e;
        if (R.indexOf(nid, nids) < 0) {
          nids.push(nid);
          snames.push(patternName || `Pattern ${nid}`);
        }
      }, log.eventArray || []);
    }, logEntryList);

    const intList = R.range(0, Math.floor((endT - startT) / timeInterval) + 1);
    const dataDict = R.fromPairs(R.map((i) => [i, {}], intList));
    const dataList = [];

    R.forEach((log) => {
      const { count } = log;
      R.forEach((event) => {
        const { nid, timestamp } = event;

        const iVal = Math.floor((timestamp - startT) / timeInterval);
        if (!R.has(iVal, dataDict)) {
          dataDict[iVal] = {};
        }
        if (!R.has(nid, dataDict[iVal])) {
          dataDict[iVal][nid] = 0;
        }
        dataDict[iVal][nid] += count || 1;
      }, log.eventArray || []);
    }, logEntryList);

    R.mapObjIndexed((num, key, obj) => {
      const vals = R.map((o) => get(num, o, 0), nids);
      dataList.push([new Date(startT + key * timeInterval), R.sum(vals), ...vals]);
    }, dataDict);

    const data = { sdata: dataList, sname: snames };
    return data;
  }

  @autobind
  getChartColor(index) {
    const idx = R.mathMod(index, 5);
    return DefaultsApp.Colorbrewer5[idx];
  }

  @autobind
  getBarNameColors(logAnomalyList) {
    const snameColor = [];
    const nidMapColor = {};

    const nids = [];
    R.forEach((event) => {
      if (R.indexOf(event.nid, nids) < 0) {
        nids.push(event.nid);
      }
    }, logAnomalyList || []);

    let index = 0;
    R.forEach((nid) => {
      const color = this.getChartColor(index);
      snameColor.push([nid, color]);
      nidMapColor[nid] = color;
      index += 1;
    }, nids);

    return { snameColor, nidMapColor };
  }

  @autobind
  onClickPattern(patternId) {
    let { activePatternId } = this.state;
    if (activePatternId === patternId) {
      activePatternId = null;
    } else {
      activePatternId = patternId;
    }

    this.setState({ activePatternId, selectedStartTs: null }, () => {
      const { queryParams } = this.props;
      this.parseData(queryParams, this.state);
    });
  }

  render() {
    const { width, height, queryResult, queryParams } = this.props;
    const { barData, logAnomalyList, activePatternId } = this.state;

    const instanceNameLink = VLink.state(this, 'instanceName').onChange(this.handleInstanceNameChange);
    const instanceNameListOptions = R.map((i) => ({ label: i, value: i }), sortByString(R.keys(queryResult)));
    const timeLink = VLink.state(this, 'timeInterval').onChange(this.handleTimeIntervalChange);
    const timeListOptions = this.timeInterval;

    const isHot = queryParams.templateId === '18a34799e749452d96eb20cd9f44f6c0';
    return (
      <Container className="flex-col" style={{ width, height }}>
        <Container className="toolbar" style={{ zIndex: 299, paddingTop: 12 }}>
          <Container className="section">
            <h4>{isHot ? 'Hot Events' : 'Cold Events'}</h4>
          </Container>
          <Container className="section float-right">
            <span className="label">Instance</span>
            <Select options={instanceNameListOptions} valueLink={instanceNameLink} style={{ width: 240 }} />
            <span className="label" style={{ paddingLeft: 10 }}>
              Interval
            </span>
            <Select options={timeListOptions} valueLink={timeLink} style={{ width: 100 }} />
          </Container>
        </Container>

        <div style={{ overflowX: 'auto', height: 40 }}>
          <div className="flex-row">
            {this.snameColor &&
              this.snameColor.map((s) => {
                return (
                  <div
                    key={s[0]}
                    className="flex-col"
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      alignItems: 'center',
                      cursor: 'pointer',
                      border: activePatternId === s[0] ? '1px solid orange' : null,
                    }}
                    onClick={() => this.onClickPattern(s[0])}
                  >
                    <div style={{ whiteSpace: 'pre' }}>{s[0]}</div>
                    <div style={{ background: s[1], height: 15, width: 40, margin: 'auto 0' }} />
                  </div>
                );
              })}
          </div>
        </div>
        <Container className="flex-col" style={{ height: 200 }}>
          {barData && this.barRender({ barData, activePatternId })}
        </Container>

        <div className="flex-grow" style={{ overflowY: 'hidden' }}>
          <EventGroup
            name=""
            eventDataset={logAnomalyList}
            showFE={false}
            hasType={false}
            hasDatetime
            hasCount
            hasNid
            showLinkedJump
            showLogAnalysisJump
            hasContext
            showRawDataFrequency
          />
        </div>
      </Container>
    );
  }
}

export default AnomalyLogEvent;
