/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { isArray } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { message, Spin } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Defaults } from '../../../../common/utils';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { State } from '../../../../common/types';
import { Container } from '../../../../lib/fui/react';

import { appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  projectName: String,
  refreshTime: Number,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class ReplayStatusSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      eventList: [],
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.projectName !== nextProps.projectName || this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, projectName, credentials } = props;
    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('metricreplaystatus'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        // message.success(intl.formatMessage(appMessages.apiSuccess));

        let eventList = [];
        if (isArray(data)) {
          eventList = data;
        }
        eventList = R.map((item) => {
          const { metricReplayStatusKey } = item;
          const { dataStartTime, dataEndTime, replayTriggerTimestamp } = metricReplayStatusKey || {};
          return { ...item, dataStartTime, dataEndTime, replayTriggerTimestamp };
        }, eventList);
        this.setState({ isLoading: false, eventList });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, eventList: [] });
      });
  }

  @autobind
  renderListView(rowData, index) {
    if (!rowData) return null;

    const { totalChunkNumber, currentFinishedChunkNumber, dataStartTime, dataEndTime, replayTriggerTimestamp } =
      rowData;

    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {totalChunkNumber}
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {currentFinishedChunkNumber}
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {moment.utc(dataStartTime).format(Defaults.DateTimeFormat)}
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {moment.utc(dataEndTime).format(Defaults.DateTimeFormat)}
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {moment.utc(replayTriggerTimestamp).format(Defaults.DateTimeFormat)}
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { isLoading, eventList } = this.state;
    return (
      <Container className="full-height">
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <div className="full-height flex-col">
            {/* <div className="flex-row flex-center-align" style={{ marginBottom: 12 }} /> */}

            <div className="event-list flex-grow flex-col flex-min-height">
              <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                <div className="header-column" style={{ width: 100, flex: 1 }}>
                  {intl.formatMessage(settingsMessages.totalChunkNumber)}
                </div>
                <div className="header-column" style={{ width: 100, flex: 1 }}>
                  {intl.formatMessage(settingsMessages.currentFinishedChunkNumber)}
                </div>
                <div className="header-column" style={{ width: 100, flex: 1 }}>
                  {intl.formatMessage(settingsMessages.dataStartTime)}
                </div>
                <div className="header-column" style={{ width: 100, flex: 1 }}>
                  {intl.formatMessage(settingsMessages.dataEndTime)}
                </div>
                <div className="header-column" style={{ width: 100, flex: 1 }}>
                  {intl.formatMessage(settingsMessages.replayTriggerTimestamp)}
                </div>
              </div>
              <div className="event-list-grid flex-grow overflow-y-auto">
                {R.addIndex(R.map)((rowData, index) => this.renderListView(rowData, index), eventList || [])}
              </div>
            </div>
          </div>
        </Spin>
      </Container>
    );
  }
}

const ReplayStatusSetting = injectIntl(ReplayStatusSettingCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials } = state.auth;

    return { location, loadStatus, credentials };
  },
  {
    updateLastActionInfo,
  },
)(ReplayStatusSetting);
