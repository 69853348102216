import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { sortableHandle } from 'react-sortable-hoc';
import { Spin, Empty } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer, Popover } from '../../../lib/fui/react';
import { Defaults, buildUrl } from '../../../common/utils';
import { MoveIcon } from '../../../lib/fui/icons';

const DragHandle = sortableHandle(() => (
  <span className="hover-display-item" style={{ cursor: 'move', position: 'absolute', right: 8, top: 8 }}>
    <MoveIcon style={{ fontSize: 16 }} />
  </span>
));

const InsightsAnomalousComponentsStats = (props) => {
  // eslint-disable-next-line
  const { intl, name } = props;
  const {
    // eslint-disable-next-line
    push,
    // eslint-disable-next-line
    updateLastActionInfo,
    // eslint-disable-next-line
    credentials,
    // eslint-disable-next-line
    userInfo,

    // eslint-disable-next-line
    refresh,
    // eslint-disable-next-line
    refreshTime,
    // eslint-disable-next-line
    environmentId,
    // eslint-disable-next-line
    systemId,
    // eslint-disable-next-line
    customerName,
    // eslint-disable-next-line
    startTime,
    // eslint-disable-next-line
    globalInfo,

    // eslint-disable-next-line
    updateState,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [stats, setComponentStats] = useState([]);

  useEffect(() => {
    let cancel = false;

    // UNSAFE_componentWillReceiveProps
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (
      // eslint-disable-next-line
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      systemId &&
      systemInfo &&
      startTime
    ) {
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();

      setLoading(true);
      updateState({ isLoadingAnomalyInstance: true });
      updateLastActionInfo();
      Promise.all([
        fetchGet(getEndpoint('insightsAnomalyStatsServlet'), {
          ...credentials,
          environmentName: environmentId,
          customerName: systemInfo.ownerUserName,
          systemName: systemId,
          dayTimeMillis: startTimestamp,
        }),
      ])
        .then(async (results) => {
          if (cancel) return;

          // incident stats info
          const { anomalyCountByComponent, anomalyCountByInstance } = results[0] || {};
          let statsComponent = [];
          R.forEachObjIndexed((count, component) => {
            statsComponent.push({
              name: component,
              count,
            });
          }, anomalyCountByComponent);
          statsComponent = R.sortWith([R.descend(R.prop('count')), R.ascend(R.prop('name'))], statsComponent);

          let statsInstance = [];
          R.forEachObjIndexed((count, insatnce) => {
            statsInstance.push({
              name: insatnce,
              count,
            });
          }, anomalyCountByInstance);
          statsInstance = R.sortWith([R.descend(R.prop('count')), R.ascend(R.prop('name'))], statsInstance);

          setComponentStats(statsComponent);
          setLoading(false);
          updateState({
            isLoadingAnomalyInstance: false,
            anomalyInstanceCountInfo: statsInstance,
          });
        })
        .catch((err) => {
          if (cancel) return;
          console.error(err);
          setComponentStats([]);
          setLoading(false);
          updateState({ isLoadingAnomalyInstance: false, anomalyInstanceCountInfo: [] });
        });
    }

    return () => {
      cancel = true;
    };
  }, [refresh, refreshTime, environmentId, systemId, customerName]);

  const handleJumpClick = ({ name }) => {
    const query = {
      environmentId,
      systemId,
      customerName,
      startTime,
      endTime: startTime,

      componentFilter: name,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  };

  const cellMeasureCache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 28,
  });

  return (
    <div
      className="full-height full-width flex-col health-card hover-display"
      style={{ backgroundColor: 'white', borderRadius: '2em', padding: '8px 8px', position: 'relative' }}
    >
      <div
        className="flex-row flex-center-justify bold link"
        style={{ textAlign: 'center', margin: '0 14px 8px 14px', height: 34 }}
        onClick={() => {
          push(buildUrl(BaseUrls.GlobalSystemRootCause, {}, { startTime, customerName, environmentId, systemId }));
        }}
      >
        {name}
      </div>
      <DragHandle />

      <div
        className="flex-grow flex-col flex-center-align flex-center-justify flex-min-height"
        style={{ padding: '0px 8px' }}
      >
        <Spin spinning={isLoading} wrapperClassName="spin-full-height full-width full-height">
          {stats.length === 0 && (
            <div className="full-height flex-col flex-center-align flex-center-justify">
              <Empty imageStyle={{ height: 60 }} />
            </div>
          )}
          {stats.length > 0 && (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowCount={stats.length}
                  overscanRowCount={4}
                  deferredMeasurementCache={cellMeasureCache}
                  rowHeight={cellMeasureCache.rowHeight}
                  rowRenderer={({ key, index: rowIndex, style, parent }) => {
                    const item = stats[rowIndex];
                    if (!item) return null;

                    let color = '#797D92';
                    switch (rowIndex) {
                      case 0:
                        color = '#FF5142';
                        break;
                      case 1:
                        color = '#FF8A61';
                        break;
                      case 2:
                        color = '#FEB207';
                        break;
                      default:
                        break;
                    }
                    return (
                      <CellMeasurer
                        key={key}
                        cache={cellMeasureCache}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={rowIndex}
                      >
                        <div
                          className="flex-row flex-min-width flex-center-align clickable"
                          style={{ ...style, fontSize: 14 }}
                          onClick={() => handleJumpClick(item)}
                        >
                          <div className="circle" style={{ marginRight: 16, background: color }}>
                            <div className="circle-text">{rowIndex + 1}</div>
                          </div>
                          <div className="flex-grow flex-min-width">
                            <Popover title={null} content={item.name} placement="right" mouseEnterDelay={0.3}>
                              <div className="max-width hidden-line-with-ellipsis inline-block">{item.name}</div>
                            </Popover>
                          </div>
                          <div
                            className="flex-row flex-end-justify"
                            style={{ minWidth: 40, color, margin: '0 8px 0 4px' }}
                          >
                            {item.count}
                          </div>
                        </div>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default InsightsAnomalousComponentsStats;
