/* @flow */

import { defineMessages } from 'react-intl';

const appButtonsMessages = defineMessages({
  view: {
    defaultMessage: 'View',
    id: 'app.buttons.view',
  },
  add: {
    defaultMessage: 'Add',
    id: 'app.buttons.add',
  },
  create: {
    defaultMessage: 'Create',
    id: 'app.buttons.create',
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'app.buttons.edit',
  },
  update: {
    defaultMessage: 'Update',
    id: 'app.buttons.update',
  },
  reset: {
    defaultMessage: 'Reset',
    id: 'app.buttons.reset',
  },
  upload: {
    defaultMessage: 'Upload',
    id: 'app.buttons.upload',
  },
  refresh: {
    defaultMessage: 'Refresh',
    id: 'app.buttons.refresh',
  },
  reload: {
    defaultMessage: 'Reload',
    id: 'app.buttons.reload',
  },
  remove: {
    defaultMessage: 'Remove',
    id: 'app.buttons.remove',
  },
  escalateToIncidentLabel: {
    defaultMessage: 'Escalate to incident label',
    id: 'app.buttons.escalateToIncidentLabel',
  },
  removeAll: {
    defaultMessage: 'Remove All',
    id: 'app.buttons.removeAll',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'app.buttons.delete',
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'app.buttons.submit',
  },
  addNew: {
    defaultMessage: 'Add New',
    id: 'app.buttons.addNew',
  },
  confirm: {
    defaultMessage: 'Confirm',
    id: 'app.buttons.confirm',
  },
  finished: {
    defaultMessage: 'Finish',
    id: 'app.buttons.finished',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'app.buttons.cancel',
  },
  save: {
    defaultMessage: 'Save',
    id: 'app.buttons.save',
  },
  close: {
    defaultMessage: 'Close',
    id: 'app.buttons.close',
  },
  lock: {
    defaultMessage: 'Lock',
    id: 'app.buttons.lock',
  },
  unlock: {
    defaultMessage: 'UnLock',
    id: 'app.buttons.unlock',
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'app.buttons.yes',
  },
  no: {
    defaultMessage: 'No',
    id: 'app.buttons.no',
  },
  search: {
    defaultMessage: 'Search',
    id: 'app.buttons.search',
  },
  filter: {
    defaultMessage: 'Filter',
    id: 'app.buttons.filter',
  },
  download: {
    defaultMessage: 'Download',
    id: 'app.buttons.download',
  },
  export: {
    defaultMessage: 'Export',
    id: 'app.buttons.export',
  },
  configure: {
    defaultMessage: 'Configure',
    id: 'app.buttons.configure',
  },
  label: {
    defaultMessage: 'Label',
    id: 'app.buttons.label',
  },
  recover: {
    defaultMessage: 'Recover',
    id: 'app.buttons.recover',
  },
  moveTrash: {
    defaultMessage: 'Move To Trash',
    id: 'app.buttons.moveTrash',
  },
  selectFile: {
    defaultMessage: 'Select File',
    id: 'app.buttons.selectFile',
  },
  sampleFile: {
    defaultMessage: 'Sample File',
    id: 'app.buttons.sampleFile',
  },
  pick: {
    defaultMessage: 'Pick',
    id: 'app.buttons.pick',
  },
  verify: {
    defaultMessage: 'Verify',
    id: 'app.buttons.verify',
  },
  purgeData: {
    defaultMessage: 'Purge Data',
    id: 'app.buttons.purgeData',
  },
  next: {
    defaultMessage: 'Next',
    id: 'app.buttons.next',
  },
  escalateToGlobalKnowledgeBase: {
    defaultMessage: 'Escalate to Global Knowledge Base',
    id: 'app.buttons.escalateToGlobalKnowledgeBase',
  },
  preview: {
    defaultMessage: 'Preview',
    id: 'app.buttons.preview',
  },
  previewInstance: {
    defaultMessage: 'Preview instance',
    id: 'app.buttons.previewInstance',
  },
});

export default appButtonsMessages;
