/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import { isBoolean } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

import { apiCallAfterCreateProject } from './parsers';

const newDynatraceProject = (credentials: Credentials, projectName: String, params: Object) => {
  const {
    // system info
    systemKey,
    systemDisplayName,
    projectOwner,
    samplingInterval,
    ...rest
  } = params;

  return fetchPost(getEndpoint('add-project'), {
    ...credentials,
    projectOwner,
    projectName,
    samplingInterval: samplingInterval / 60,
    ...rest,
  }).then((d) => {
    if (isBoolean(d.success) && d.success === false) {
      return d;
    }

    return apiCallAfterCreateProject({
      credentials,
      projectName,
      systemKey,
      systemDisplayName,
      projectOwner,
    });
  });
};

export default newDynatraceProject;
