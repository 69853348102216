import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
// import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Spin, Alert } from 'antd';

import { Defaults } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { eventActionMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

type Props = {
  // eslint-disable-next-line
  incident: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
};

class TriggeredActionsModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      triggeredActionList: [],
    };
  }

  componentDidMount() {
    this.parseData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  parseData() {
    const { incident } = this.props;
    let triggeredActionList = [];
    if (incident) {
      triggeredActionList = incident.triggeredActionList || [];
    }
    this.setState({ triggeredActionList });
  }

  @autobind
  renderTriggeredActions(rowData, index) {
    const { actionName, timestamp } = rowData;
    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {moment.utc(timestamp).format(Defaults.DateTimeFormat)}
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {actionName}
        </div>
      </div>
    );
  }

  render() {
    const { intl, onClose } = this.props;
    const { isLoading, triggeredActionList } = this.state;

    return (
      <Modal
        visible
        title={intl.formatMessage(logMessages.triggeredActions)}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={850}
        bodyStyle={{ height: 480, overflowY: 'auto' }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          {triggeredActionList.length === 0 && (
            <div style={{ marginBottom: 16 }}>
              <Alert
                message=""
                description={intl.formatMessage(logMessages.noTriggeredActionsFound)}
                type="info"
                showIcon
              />
            </div>
          )}
          {triggeredActionList.length > 0 && (
            <div className="event-list flex-grow flex-col flex-min-height" style={{ marginBottom: 16 }}>
              <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                <div className="header-column" style={{ width: 120, flex: 1 }}>
                  {intl.formatMessage(eventActionMessages.triggeredTime)}
                </div>
                <div className="header-column" style={{ width: 120, flex: 1 }}>
                  {intl.formatMessage(eventActionMessages.actionName)}
                </div>
              </div>
              <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                {R.addIndex(R.map)(
                  (rowData, index) => this.renderTriggeredActions(rowData, index),
                  triggeredActionList,
                )}
              </div>
            </div>
          )}
        </Spin>
      </Modal>
    );
  }
}

const TriggeredActionsModal = injectIntl(TriggeredActionsModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(TriggeredActionsModal);
