import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import tinygradient from 'tinygradient';
import { get } from 'lodash';
import { Spin, Tag } from 'antd';

import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import { buildUrl, Defaults, parseLocation } from '../../common/utils';
import { BaseUrls } from '../app/Constants';

import { EChart } from '../share';

import { appFieldsMessages } from '../../common/app/messages';

const titleMap = { prediction: 'Predictions', incident: 'Incidents', anomolie: 'Anomalies' };

const SystemDailyContext = createContext(null);
const TypeItemContentWrapContext = createContext(null);

const reducerLogic = (oldVal, newVal) => ({ ...oldVal, ...newVal });

const initState = {
  loading: false,
  systemInfoMap: { prediction: [], incident: [], anomolie: [] },
};

const TitleWrapContent = (props: Object) => {
  const { label } = props;
  return (
    <div className="flex-row flex-space-between clickable" style={{ fontSize: 14 }}>
      <div style={{ fontWeight: 'bold' }}>{titleMap[label]}</div>
    </div>
  );
};

const SystemInfoChart = (props: Object) => {
  const { systemInfoMap } = useContext(SystemDailyContext);
  const { echartRef, optionAll, legendColor } = useContext(TypeItemContentWrapContext);
  const { label } = props;

  const getPieOption = (data, gradientColorsList) => {
    const gradient = tinygradient(gradientColorsList);
    const echartData = R.filter((item) => item.value > 0, data);
    const colorsRgb = gradient.rgb(echartData.length < 2 ? 2 : echartData.length);
    const colors = R.map((x) => {
      return x.toHexString();
    }, colorsRgb);
    legendColor.current = colors;
    const option = {
      backgroundColor: 'transparent',
      color: colors,
      legend: { top: -500, type: 'scroll', width: 1, height: 1 },
      series: [
        {
          name: titleMap[label],
          type: 'pie',
          cursor: 'auto',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          percentPrecision: 1,
          itemStyle: {
            emphasis: {
              shadowBlur: 4,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            show: true,
            position: 'inner',
            formatter: (params) => {
              const { percent } = params;
              return percent > 5 ? `${percent}%` : '';
            },
            color: 'white',
            fontWeight: 'bold',
            fontSize: 10,
          },
          emphasis: {
            disabled: true,
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          showEmptyCircle: true,
          emptyCircleStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderColor: '#ccc',
          },
          data: echartData,
        },
      ],
    };
    optionAll.current = option;
    return option;
  };
  const option = useMemo(
    () =>
      getPieOption(
        R.map((item) => ({ ...item, value: item.total, name: item.systemName }), systemInfoMap[label]),
        ['#043e7d', '#6abbf7'],
      ),
    [systemInfoMap[label]],
  );

  return (
    <div style={{ height: '100%', minWidth: '40%', maxWidth: '40%' }}>
      <div style={{ height: '100%' }} className="echarts-cancel-hover">
        <EChart
          setRef={(chart) => {
            echartRef.current = chart;
          }}
          option={option}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
};

const SystemItem = ({ rowData, index, label }: Object) => {
  const { location } = useContext(SystemDailyContext);
  const { echartRef, optionAll, legendColor } = useContext(TypeItemContentWrapContext);
  const query = parseLocation(location);
  const { systemName, total, systemKey } = rowData;

  const mouseLinkageCharts = ({ item, mouse }) => {
    if (echartRef.current) {
      const detailKey = `${item.systemName}-${item.systemKey}-${item.total}`;
      const { data } = optionAll?.current?.series[0] || {};
      const dataIndex = R.findIndex(
        (_item) => `${_item?.systemName}-${_item?.systemKey}-${_item?.total}` === detailKey,
        data,
      );
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.dispatchAction({ type: mouse === 'over' ? 'highlight' : 'downplay', seriesIndex: 0, dataIndex });
    }
  };

  const handleClickLegend = (systemName) => {
    if (echartRef.current) {
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.dispatchAction({ type: 'legendToggleSelect', name: systemName });
    }
  };

  const clickJump = () => {
    let url = BaseUrls.GlobalSystemPrediction;
    let params = { ...query, systemId: systemKey, forceRefreshTime: undefined };
    if (label === 'incident') {
      url = BaseUrls.GlobalSystemRootCause;
      params = { ...query, systemId: systemKey, forceRefreshTime: undefined, eventCategory: 'incidents' };
    } else if (label === 'anomolie') {
      url = BaseUrls.GlobalSystemRootCause;
      params = { ...query, systemId: systemKey, forceRefreshTime: undefined, cancelActiveIncident: 'true' };
    }
    window.open(buildUrl(url, {}, params), '_blank');
  };

  return (
    <div className="flex-row flex-center-align flex-space-between" style={{ marginBottom: 10 }}>
      <div className="flex-row flex-center-align clickable">
        <span
          style={{
            display: 'inline-block',
            width: 14,
            height: 10,
            borderRadius: 4,
            flexShrink: 0,
            background: `${total > 0 ? legendColor.current[index] : 'var(--green)'}`,
          }}
          onClick={() => handleClickLegend(systemName)}
        />
        <span
          className="hidden-line-with-ellipsis-multiline"
          style={{ wordBreak: 'break-word', padding: '0 8px', width: 'fit-content' }}
          // onMouseEnter={(e) => {
          //   e.stopPropagation();
          //   e.preventDefault();
          //   mouseLinkageCharts({ item: rowData, mouse: 'over' });
          // }}
          // onMouseLeave={(e) => {
          //   e.stopPropagation();
          //   e.preventDefault();
          //   mouseLinkageCharts({ item: rowData, mouse: 'out' });
          // }}
          onClick={clickJump}
        >
          {systemName}
        </span>
      </div>
      <Tag
        size="small"
        style={{ borderRadius: 100, marginLeft: 4, fontWeight: 'bold', flexShrink: 0, cursor: 'default' }}
      >
        {total}
      </Tag>
    </div>
  );
};

const SystemInfoList = (props: Object) => {
  const { intl, systemInfoMap } = useContext(SystemDailyContext);
  const { label } = props;

  let content = (
    <div
      className="flex-grow flex-col flex-center-align flex-center-justify"
      style={{ color: 'var(--text-color-secondary)' }}
    >{`No ${label}s for this time period`}</div>
  );

  if (systemInfoMap[label].length > 0) {
    content = (
      <div style={{ height: 'calc(100% - 30px)', overflowY: 'auto' }}>
        {R.addIndex(R.map)(
          (item, index) => (
            <SystemItem key={item.systemKey} rowData={item} index={index} label={label} />
          ),
          systemInfoMap[label],
        )}
      </div>
    );
  }

  return (
    <div className="flex-col" style={{ height: '100%', minWidth: '60%', maxWidth: '60%', paddingLeft: 14 }}>
      <div style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 10 }}>
        {intl.formatMessage(appFieldsMessages.systemName)}
      </div>
      {content}
    </div>
  );
};

const TypeItemContentWrap = (props: Object) => {
  const { label } = props;
  const echartRef = useRef(null);
  const optionAll = useRef({});
  const legendColor = useRef([]);

  const dataInfoValue = useMemo(
    () => ({ echartRef, optionAll, legendColor }),
    [echartRef.current, optionAll.current, legendColor.current],
  );

  return (
    <TypeItemContentWrapContext.Provider value={dataInfoValue}>
      <div className="content-bg corner-8 flex-col" style={{ padding: '20px 20px 24px', height: 'inherit' }}>
        <TitleWrapContent label={label} />
        <div className="flex-row flex-grow" style={{ marginTop: 14, height: 'calc(100% - 34px)' }}>
          <SystemInfoChart label={label} />
          <SystemInfoList label={label} />
        </div>
      </div>
    </TypeItemContentWrapContext.Provider>
  );
};

export default function GlobalHealthChartView(props: Object) {
  const { forceRefreshTime, refreshTime, startTime, endTime, customerName, environmentId, credentials } = props;
  const { systemList, systemListMap } = props;
  const [state, setState] = useReducer(reducerLogic, initState);
  const { systemInfoMap, loading } = state;

  const getSystemInfoList = (cancel) => {
    if (cancel) return;
    const systemIdsWithShare = R.map((id) => ({ id, customerName: get(systemListMap, [id, 'owner'], id) }), systemList);
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    if (systemIdsWithShare.length === 0 || !startTimestamp || !endTimestamp) return;
    setState({ loading: true });
    fetchGet(getEndpoint('system-daily-info'), {
      ...credentials,
      systemIdsWithShare: JSON.stringify(systemIdsWithShare),
      startTime: startTimestamp,
      endTime: endTimestamp,
    })
      .then((data) => {
        const { result, success, message: msg } = data;
        if (success || success === undefined) {
          const dataMap = {};
          R.forEach((item) => {
            const { systemKey } = item;
            if (!R.has(systemKey, dataMap)) {
              item.dailyTimestamp = [item.dailyTimestamp];
              dataMap[systemKey] = item;
            } else {
              dataMap[systemKey] = R.mergeDeepWithKey(
                (k, l, r) => {
                  let val = r;
                  if (k === 'healthScore') {
                    val = R.mean([l, r]);
                  } else if (k === 'dailyTimestamp') {
                    val = [...val, l];
                  } else if (
                    R.includes(k, [
                      'totalAlerts',
                      'totalAnomalies',
                      'totalIncidents',
                      'totalMatchedIncidents',
                      'totalPredictedIncidents',
                    ])
                  ) {
                    val = R.add(l, r);
                  }
                  return val;
                },
                item,
                dataMap[systemKey],
              );
            }
          }, result || []);

          let predictionList = [];
          let incidentList = [];
          let anomolieList = [];
          R.forEachObjIndexed((item) => {
            const { systemKey, totalPredictedIncidents, totalIncidents, totalAnomalies } = item;
            const systemName = get(systemListMap, [item.systemKey, 'systemName'], item.systemKey);
            predictionList.push({ systemKey, total: totalPredictedIncidents, systemName });
            incidentList.push({ systemKey, total: totalIncidents, systemName });
            anomolieList.push({ systemKey, total: totalAnomalies, systemName });
          }, dataMap);
          predictionList = R.sortWith([R.descend(R.prop('total'))], predictionList);
          incidentList = R.sortWith([R.descend(R.prop('total'))], incidentList);
          anomolieList = R.sortWith([R.descend(R.prop('total'))], anomolieList);
          const systemInfoMap = { prediction: predictionList, incident: incidentList, anomolie: anomolieList };
          setState({ systemInfoMap });
        } else {
          console.error(msg);
        }
        setState({ loading: false });
      })
      .catch((err) => {
        console.error(err);
        setState({ loading: false });
      });
  };

  useEffect(() => {
    let cancel = false;
    getSystemInfoList(cancel);
    return () => {
      cancel = true;
    };
  }, [forceRefreshTime, refreshTime, customerName, environmentId]);

  const dataInfoValue = useMemo(() => ({ ...props, systemInfoMap }), [props, systemInfoMap]);

  return (
    <SystemDailyContext.Provider value={dataInfoValue}>
      <div className="flex-row flex-center-align corner-10" style={{ margin: '0 12px 0 8px' }}>
        <Spin spinning={loading} wrapperClassName="gv-daily-loading">
          <div
            style={{
              width: '100%',
              height: 220,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridColumnGap: 12,
              overflow: 'hidden',
            }}
          >
            {R.map(
              (item) => (
                <TypeItemContentWrap key={item} label={item} />
              ),
              R.keys(systemInfoMap),
            )}
          </div>
        </Spin>
      </div>
    </SystemDailyContext.Provider>
  );
}
