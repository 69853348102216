/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

const colorMap = {
  Disabled: '#d9d9d9',
  PageBackground: '#f7f7f7',
  SeparatorBoundary: '#b7b7b7',
  Text: 'rgba(0, 0, 0, 0.87)',
  Info: '#d0f3f8',
  NoData: '#f3f3f3',
  Health: 'rgb(66, 209, 45)',
  HealthBackground: '#bfffc7',
};

export default colorMap;
