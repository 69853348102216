/*  @flow */
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';
import { BadRequestError } from '../errors';

const resetPasswordByTemp = (params) => {
  const { userName, email, tempPassword, password, confirmPassword } = params;
  return fetchPost(getEndpoint('reset-password'), {
    UserName: userName,
    email,
    'temp-pass': tempPassword,
    pass1: password,
    pass2: confirmPassword,
  }).then((resp) => {
    // if (!resp.success) {
    //   throw new BadRequestError(resp.message, resp);
    // }
    return resp;
  });
};

export default resetPasswordByTemp;
