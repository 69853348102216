import React, { useCallback, useEffect, useReducer } from 'react';
import { Spin, Tabs } from 'antd';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment/moment';
import { CodeSandboxOutlined, DatabaseOutlined } from '@ant-design/icons';
import { AutoSizer, Container } from '../../lib/fui/react';
import ServiceMapVisualizer from './component/ServiceMapVisualizer';
import ServiceMap3DVisualizer from './component/ServiceMap3DVisualizer';
import fetchPost from '../../common/apis/fetchPost';
import getEndpoint from '../../common/apis/getEndpoint';
import fetchDelete from '../../common/apis/fetchDelete';
import { buildUrl } from '../../common/utils';
import RecommendationsGPT from '../dashboard/components/RecommendationsGPT';

const DynamicVM = 1;
const DynamicHost = 2;
const timeInterval = 60; // minutes

const processNote = (isK8s, notes = {}, isJwtMode, selectedZone, allZoneNoteMap) => {
  const allNotes = { ...notes };

  if (!isJwtMode && selectedZone !== '__all__') {
    R.forEachObjIndexed((val, key) => {
      const n = R.map((v) => ({ ...v, isAllZone: true }), val || []);
      if (allNotes[key]) {
        allNotes[key] = [...allNotes[key], ...n];
      } else {
        allNotes[key] = [...n];
      }
    }, allZoneNoteMap || {});
  }

  const k8sNote = {};
  let nonK8sNote = {};
  if (!isK8s) {
    nonK8sNote = allNotes;
  } else {
    R.forEachObjIndexed((val, key) => {
      R.forEach((v) => {
        const noteMap = v?.type === 'pod' ? k8sNote : nonK8sNote;
        const note = noteMap[key] || [];
        note.push(v);
        noteMap[key] = note;
      }, val || []);
    }, allNotes);
  }

  return { k8sNote, nonK8sNote };
};

const processData = (allData = [], userCurrentTime, predictionEndTime) => {
  const extendK8CoverageMap = (entity, dataEndTime) => {
    if (!entity?.k8CoverageMap || userCurrentTime >= predictionEndTime) {
      return entity?.k8CoverageMap;
    }
    const lastIndex = Math.floor(dataEndTime / (1000 * 60 * timeInterval));

    const newK8CoverageMap = { coverageMap: { [entity.name]: [] } };
    const coverageMap = entity?.k8CoverageMap?.coverageMap?.[entity.name];
    if (coverageMap && coverageMap.length > 0) {
      const newCoverageMap = [...coverageMap];
      const coverage = newCoverageMap[newCoverageMap.length - 1];
      if (coverage && coverage.length > 1) {
        const { e } = coverage[1];
        const eidx = Math.floor(e / (1000 * 60 * timeInterval));
        if (eidx === lastIndex) {
          const newCoverage = [{ ...coverage[0] }, { s: coverage[1].e + 1, e: predictionEndTime }];
          newCoverageMap.push(newCoverage);
        }
      }
      newK8CoverageMap.coverageMap[entity.name] = newCoverageMap;
    }

    return newK8CoverageMap;
  };

  let componentsData = [];
  let isK8s = false;

  R.forEach((i) => {
    if (i.type === 3) {
      componentsData.push(i);
    } else if (i.type === 2) {
      componentsData.push({
        type: 3,
        name: i.name,
        children: [i],
      });
    }
  }, allData || []);

  const dynamicHosts = {};
  const normalComponentsData = [];
  const dynamicVms = [];
  R.forEach((c) => {
    const { children } = c;
    const newChildren = [];

    R.forEach((i) => {
      const { name, dynamicType } = i;
      if (dynamicType === DynamicHost) {
        dynamicHosts[name] = i;
        newChildren.push(i);
      } else if (dynamicType === DynamicVM) {
        dynamicVms.push(i);
      } else {
        newChildren.push(i);
      }
    }, children || []);
    if (children.length === 0 || newChildren.length !== 0) {
      normalComponentsData.push({
        ...c,
        children: newChildren,
      });
    }
  }, componentsData);

  // Convert VM k8coverageMap to host k8coverageMap
  R.forEach((vm) => {
    const { name } = vm;
    const coverageMap = vm?.k8CoverageMap?.coverageMap?.[name] || [] || [];
    R.forEach((cov) => {
      const { h } = cov[0] || {};
      if (h && dynamicHosts[h]) {
        const newCoverageMap = dynamicHosts[h]?.k8CoverageMap?.coverageMap?.[h] || [];
        newCoverageMap.push([{ h: name }, cov[1]]);
        dynamicHosts[h].children = dynamicHosts[h].children || [];
        if (!R.find((i) => i.name === name, dynamicHosts[h].children)) {
          dynamicHosts[h].children.push({ ...vm, k8CoverageMap: { coverageMap: {} } });
        }
      }
    }, coverageMap);
  }, dynamicVms);

  componentsData = normalComponentsData;

  const k8sComponents = {};
  const componentsDataNew = {};
  R.forEach((c) => {
    const { name, detectedIncidentMap, predictedIncidentMap } = c;
    R.forEach((i) => {
      if (i.isK8s && !isK8s) {
        isK8s = true;
      }
      if (i.isK8s) {
        if (k8sComponents[name]) {
          k8sComponents[name].children.push(i);
        } else {
          k8sComponents[name] = {
            type: 3,
            name,
            id: `${name}-k8s`,
            children: [i],
            detectedIncidentMap,
            predictedIncidentMap,
          };
        }
      } else if (componentsDataNew[name]) {
        componentsDataNew[name].children.push(i);
      } else {
        componentsDataNew[name] = {
          type: 3,
          name,
          id: name,
          children: [i],
          isInstanceRoot: true,
          detectedIncidentMap,
          predictedIncidentMap,
        };
      }
    }, c.children || []);
  }, componentsData);
  componentsData = [...R.values(componentsDataNew), ...(R.values(k8sComponents) || [])];

  const nodeTree = [];
  const nodeMap = {};

  R.forEach((comp) => {
    const { id: cid, name: cname } = comp;
    const component = {
      ...comp,
      id: cid || cname,
      level: 0,
      type: 'component',
      title: cname,
      score: 0,
      scoreRaw: 0,
      children: [],
      allChildren: [],
      parents: [],
    };

    R.forEach((inst) => {
      const { name: iname, isK8s: isK8sHost } = inst;

      if (isK8sHost && !component.isK8s) {
        component.isK8s = true;
      }
      const instance = {
        ...inst,
        level: 1,
        type: isK8s ? (isK8sHost ? 'instance' : 'pod') : 'instance',
        id: `${component.id}_${iname}`,
        title: iname,
        componentName: component.name,
        score: 0,
        scoreRaw: 0,
        children: [],
        allChildren: [],
        parents: [component],
      };

      R.forEach((cont) => {
        const { name: ccname } = cont;
        const container = {
          ...cont,
          level: 2,
          type: 'container',
          id: `${component.id}_${instance.id}_${ccname}`,
          title: ccname,
          componentName: component.name,
          score: 0,
          scoreRaw: 0,
          children: [],
          allChildren: [],
          parents: [component, instance],
        };
        nodeMap[container.id] = container;
        instance.children.push(container);
        instance.allChildren.push(container);
        component.allChildren.push(container);
      }, inst.children || []);
      nodeMap[instance.id] = instance;
      component.children.push(instance);
      component.allChildren.push(instance);
    }, comp.children || []);

    if (component.children.length > 0) {
      nodeMap[component.id] = component;
      nodeTree.push(component);
    }
  }, componentsData || []);

  const nonK8sAllData = isK8s ? R.filter((c) => !c.isK8s, nodeTree) : nodeTree;
  const k8sAllData = isK8s ? R.filter((c) => !!c.isK8s, nodeTree) : [];

  let dataEndTime = 0;
  R.forEach((c) => {
    R.forEach((i) => {
      const coverageMap = i?.k8CoverageMap?.coverageMap?.[i.name] || [] || [];
      R.forEach((cov) => {
        const { e } = cov[1] || {};
        if (e && e > dataEndTime) {
          dataEndTime = e;
        }
      }, coverageMap);
    }, c.children);
  }, nonK8sAllData);

  R.forEach((c) => {
    R.forEach((i) => {
      i.k8CoverageMap = extendK8CoverageMap(i, dataEndTime);
      R.forEach((cont) => {
        cont.k8CoverageMap = extendK8CoverageMap(cont, dataEndTime);
      }, i.children || []);
    }, c.children || []);
  }, nodeTree);

  return {
    isK8s,
    nonK8sAllData,
    k8sAllData,
    allDataMap: nodeMap,
    dataEndTime: dataEndTime || userCurrentTime,
  };
};

export const ServiceMapView = ({
  isLoading = false,
  serviceMapData,
  serviceMapNote,
  startTimeObj,
  endTimeObj,
  isDark,
  intl,
  customerName,
  systemInfo,
  userInfo,
  selectedTime,
  selectedZone,
  k8sSelectedTime,
  onChangeTime = () => {},
  onChangeK8sTime = () => {},
  nodeRootCauseMap,
  onActiveNodeChange = () => {},
  isJwtMode = false,
  jwtToken,
  onFilterShowChange = () => {},
  id,
}) => {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    serviceNowData: [],
    userCurrentTime: null,
    predictionEndTime: null,
    dataEndTime: null,

    isK8s: false,
    nonK8sAllData: {},
    k8sAllData: {},
    allDataMap: {},
    nonK8sNote: {},
    k8sNote: {},

    showRecommendationsGPT: false,
    incidentGPT: null,

    k8sActiveTab: 'instance',
  });

  useEffect(() => {
    const Contains = get(serviceMapData, 'Contains', []);
    const serviceNowData = get(serviceMapData, 'Depends on', []);
    const userCurrentTime = get(serviceMapData, 'userCurrentTime', 0);
    const predictionEndTime = get(serviceMapData, 'predictionEndTime', 0);
    const { nonK8sAllData, k8sAllData, allDataMap, dataEndTime, isK8s } = processData(
      Contains,
      userCurrentTime,
      predictionEndTime,
    );

    setState({
      isK8s,
      serviceNowData,
      userCurrentTime,
      predictionEndTime,
      dataEndTime,
      nonK8sAllData,
      k8sAllData,
      allDataMap,
    });
  }, [serviceMapData]);

  const { serviceNowData } = state;
  const { isK8s, nonK8sAllData, k8sAllData, allDataMap, nonK8sNote, k8sNote, k8sActiveTab } = state;
  const { dataEndTime, userCurrentTime, predictionEndTime, showRecommendationsGPT, incidentGPT } = state;

  useEffect(() => {
    const allZoneNoteMap = get(serviceMapData, 'allZoneNoteMap', {});
    const { k8sNote, nonK8sNote } = processNote(isK8s, serviceMapNote, isJwtMode, selectedZone, allZoneNoteMap);
    setState({ nonK8sNote, k8sNote });
  }, [serviceMapNote, serviceMapData, selectedZone, isK8s]);

  const handleAddNote = (note, type, noteTimeObj) => {
    const zoneName =
      selectedZone === '__all__' ? `all_zone_${systemInfo?.id}` : selectedZone || `zone_${systemInfo?.id}`;
    const url = isJwtMode
      ? buildUrl(
          getEndpoint('allservicemapdata'),
          {},
          {
            zoneName,
            jwt: jwtToken,
            systemName: systemInfo?.id,
            customerName: systemInfo?.ownerUserName || customerName || userInfo?.userName,
          },
        )
      : getEndpoint('servicemapnote');
    const zoneSet = systemInfo?.systemInfo?.zoneSet || [];
    const zoneList = selectedZone
      ? selectedZone === '__all__'
        ? JSON.stringify(isJwtMode ? [] : [...zoneSet, `all_zone_${systemInfo?.id}`])
        : JSON.stringify([selectedZone])
      : JSON.stringify([]);
    return fetchPost(url, {
      systemName: systemInfo?.id,
      customerName: systemInfo?.ownerUserName || customerName || userInfo?.userName,
      userId: userInfo?.userName,
      dailyTimestamp: startTimeObj.valueOf(),
      noteTimestamp: noteTimeObj.valueOf(),
      noteAddTimestamp: moment.now().valueOf(),
      note,
      type,
      zoneList,
      zoneName,
    })
      .then((data) => {
        const allZoneNoteMap = get(serviceMapData, 'allZoneNoteMap', {});
        setTimeout(() => {
          const { k8sNote, nonK8sNote } = processNote(isK8s, data, isJwtMode, selectedZone, allZoneNoteMap);
          setState({ k8sNote, nonK8sNote });
        }, 50);
      })
      .catch((e) => {
        console.log(String(e));
      });
  };

  const handleDeleteNote = (userId, type, noteTimeObj) => {
    const zoneName =
      selectedZone === '__all__' ? `all_zone_${systemInfo?.id}` : selectedZone || `zone_${systemInfo?.id}`;
    const zoneSet = systemInfo?.systemInfo?.zoneSet || [];
    const zoneList = selectedZone
      ? selectedZone === '__all__'
        ? JSON.stringify(isJwtMode ? [] : [...zoneSet, `all_zone_${systemInfo?.id}`])
        : JSON.stringify([selectedZone])
      : JSON.stringify([]);
    return fetchDelete(getEndpoint(isJwtMode ? 'allservicemapdata' : 'servicemapnote'), {
      jwt: jwtToken,
      systemName: systemInfo?.id,
      customerName: systemInfo?.ownerUserName || customerName || userInfo?.userName,
      userId,
      dailyTimestamp: startTimeObj.valueOf(),
      noteTimestamp: noteTimeObj.valueOf(),
      type,
      zoneList,
      zoneName,
    })
      .then((data) => {
        const allZoneNoteMap = get(serviceMapData, 'allZoneNoteMap', {});
        const { k8sNote, nonK8sNote } = processNote(isK8s, data, isJwtMode, selectedZone, allZoneNoteMap);
        setState({ k8sNote, nonK8sNote });
      })
      .catch((e) => {
        console.log(String(e));
      });
  };

  return (
    <Spin spinning={isLoading} wrapperClassName="flex-grow flex-min-height flex-row spin-full-width" id={id}>
      <Container className="flex-col full-height full-width" style={{ zIndex: 0 }}>
        <AutoSizer>
          {({ width, height }) => (
            <Tabs type="card" defaultActiveKey="containment" style={{ width, height }} size="small">
              <Tabs.TabPane tab="Containment" key="containment">
                {!isK8s && (
                  <ServiceMap3DVisualizer
                    isJwtMode={isJwtMode}
                    intl={intl}
                    width={width}
                    height={height - 50}
                    data={nonK8sAllData}
                    userCurrentTime={userCurrentTime}
                    predictionEndTime={predictionEndTime}
                    dataMap={allDataMap}
                    serviceMapNote={nonK8sNote}
                    onAddNote={(note) => {
                      handleAddNote(note, 'instance', selectedTime);
                    }}
                    onDeleteNote={(userId) => {
                      handleDeleteNote(userId, 'instance', selectedTime);
                    }}
                    dataEndTime={dataEndTime}
                    isK8sProject={isK8s}
                    isK8s={false}
                    startTime={startTimeObj}
                    endTime={endTimeObj}
                    selectedTime={selectedTime}
                    isDark={isDark}
                    onChangeTime={(time) => {
                      onChangeTime(time);
                    }}
                    userId={userInfo?.userName}
                    onChangeShowGPT={(time) =>
                      setState({
                        showRecommendationsGPT: true,
                        incidentGPT: {
                          id: `${systemInfo?.id}-${systemInfo?.ownerUserName}-${selectedZone}-${time}`,
                          startTimestamp: time,
                          selectedZone,
                          level: 0,
                        },
                      })
                    }
                    onActiveNodeChange={(instance) => {
                      onActiveNodeChange(instance, selectedTime);
                    }}
                    nodeRootCauseMap={nodeRootCauseMap}
                    selectedZone={selectedZone}
                    onFilterShowChange={onFilterShowChange}
                  />
                )}
                {isK8s && (
                  <Tabs
                    style={{ width, height: height - 50, padding: '0 18px', marginTop: -16 }}
                    size="small"
                    activeKey={k8sActiveTab}
                    onChange={(activeKey) => {
                      setState({ k8sActiveTab: activeKey });
                    }}
                  >
                    <Tabs.TabPane
                      tab={
                        <div>
                          <DatabaseOutlined />
                          <span>Instance</span>
                        </div>
                      }
                      key="instance"
                    >
                      <ServiceMap3DVisualizer
                        isJwtMode={isJwtMode}
                        intl={intl}
                        isK8sProject={isK8s}
                        width={width - 36}
                        height={height - 82}
                        data={nonK8sAllData}
                        userCurrentTime={userCurrentTime}
                        predictionEndTime={predictionEndTime}
                        dataMap={allDataMap}
                        serviceMapNote={nonK8sNote}
                        onAddNote={(note) => {
                          handleAddNote(note, 'instance', selectedTime);
                        }}
                        onDeleteNote={(userId) => {
                          handleDeleteNote(userId, 'instance', selectedTime);
                        }}
                        dataEndTime={dataEndTime}
                        isK8s={false}
                        startTime={startTimeObj}
                        endTime={endTimeObj}
                        selectedTime={selectedTime}
                        isDark={isDark}
                        onChangeTime={(time) => {
                          onChangeTime(time);
                        }}
                        userId={userInfo?.userName}
                        onChangeShowGPT={(time) =>
                          setState({
                            showRecommendationsGPT: true,
                            incidentGPT: {
                              id: `${systemInfo?.id}-${systemInfo?.ownerUserName}-${selectedZone}-${time}`,
                              startTimestamp: time,
                              selectedZone,
                              level: 0,
                            },
                          })
                        }
                        onActiveNodeChange={(instance) => {
                          onActiveNodeChange(instance, selectedTime);
                        }}
                        nodeRootCauseMap={nodeRootCauseMap}
                        selectedZone={selectedZone}
                        onFilterShowChange={k8sActiveTab === 'instance' ? onFilterShowChange : () => {}}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <div>
                          <CodeSandboxOutlined />
                          <span>Pod</span>
                        </div>
                      }
                      key="pod"
                    >
                      <ServiceMap3DVisualizer
                        isJwtMode={isJwtMode}
                        intl={intl}
                        isK8sProject={isK8s}
                        userCurrentTime={userCurrentTime}
                        predictionEndTime={predictionEndTime}
                        width={width - 36}
                        height={height - 82}
                        isK8s
                        data={k8sAllData}
                        dataMap={allDataMap}
                        serviceMapNote={k8sNote}
                        onAddNote={(note) => {
                          handleAddNote(note, 'pod', k8sSelectedTime);
                        }}
                        onDeleteNote={(userId) => {
                          handleDeleteNote(userId, 'pod', k8sSelectedTime);
                        }}
                        dataEndTime={dataEndTime}
                        startTime={startTimeObj}
                        selectedTime={k8sSelectedTime}
                        onChangeTime={(time) => {
                          onChangeK8sTime(time);
                        }}
                        endTime={endTimeObj}
                        isDark={isDark}
                        userId={userInfo?.userName}
                        onChangeShowGPT={(time) =>
                          setState({
                            showRecommendationsGPT: true,
                            incidentGPT: {
                              id: `${systemInfo?.id}-${systemInfo?.ownerUserName}-${selectedZone}-${time}`,
                              startTimestamp: time,
                              selectedZone,
                              level: 1,
                            },
                          })
                        }
                        onActiveNodeChange={(node) => {
                          onActiveNodeChange(node, k8sSelectedTime);
                        }}
                        nodeRootCauseMap={nodeRootCauseMap}
                        selectedZone={selectedZone}
                        onFilterShowChange={k8sActiveTab === 'pod' ? onFilterShowChange : () => {}}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Dependency" key="dependency">
                <ServiceMapVisualizer
                  width={width}
                  height={height - 50}
                  data={serviceNowData}
                  rootName="ServiceNow"
                  onFilterShowChange={onFilterShowChange}
                />
              </Tabs.TabPane>
            </Tabs>
          )}
        </AutoSizer>
      </Container>

      {showRecommendationsGPT && (
        <RecommendationsGPT
          incident={incidentGPT}
          incidentType="ServiceMap"
          title="Service map summary"
          onCancel={() => setState({ showRecommendationsGPT: false, incidentGPT: null })}
          isJWT={isJwtMode}
          jwtToken={jwtToken}
          zoneName={selectedZone === '__all__' ? `all_zone_${systemInfo?.id}` : selectedZone}
        />
      )}
    </Spin>
  );
};
