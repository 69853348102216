/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
import * as R from 'ramda';
import { get } from 'lodash';
import { createLogic } from 'redux-logic';
import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getGlobalCapacityScore } from '../../apis';
import { appMessages } from '../../app/messages';

const loadGlobalCapacityPlanningLogic = createLogic({
  type: [ActionTypes.LOAD_GLOBAL_CAPACITY_PLANNING],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,

  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    // Get the system map for the environment
    const { globalInfo } = state.app;
    const { isAutoReload, environmentId } = params;
    const environment = R.find((environment) => environment.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const projectSystemMap = {};
    R.forEach((system) => {
      R.forEach((item) => (projectSystemMap[item.projectName] = system.id), system.projectNameSet || []);
    }, systemList);

    dispatch(showLoading);
    if (!isAutoReload) dispatch(updateLastActionInfo());
    getGlobalCapacityScore(credentials, { ...params, projectSystemMap })
      .then((data) => {
        dispatch(createSetAction(ActionTypes.SET_GLOBAL_CAPACITY_PLANNING, params, data));
        dispatch(hideLoading);
      })
      .catch((err) => {
        // Not alert: console.error('[IF_API] Failed get capacity score', err);
        dispatch(
          errorLoading(appMessages.errorsAPIMessage, {
            message: err.message,
            error: err,
          }),
        );
      })
      .then(() => {
        done();
      });
  },
});

export default loadGlobalCapacityPlanningLogic;
