import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PredictedIncidentListTable from './PredictedIncidentListTable';

type Props = {
  width: Number,
  height: Number,
  events: Array,
  systemInfo: Object,
  isLoading: Boolean,
  summarySettingsMap: Object,
  title: String,
  typeString: String,
  onRefreshData: Function,
  activeLineIconKey: String,
  changeActiveLineIconKey: Function,
  globalLoading: Boolean,
};
class PredictedIncidentCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      summarySettingsMap,
      isLoading,
      systemInfo,
      width,
      height,
      events = [],
      onRefreshData,
      globalLoading,
    } = this.props;
    const { activeLineIconKey, title, typeString, changeActiveLineIconKey } = this.props;
    return (
      <>
        <div style={{ padding: '8px 0' }}>
          <span className="font-14 bold">{title}</span> - {typeString}
        </div>
        <PredictedIncidentListTable
          tabName="predictedIncidents"
          systemInfo={systemInfo}
          events={events}
          activeEvent={events}
          width={width - 2}
          height={height - 36}
          isLoading={isLoading}
          summarySettingsMap={summarySettingsMap}
          onRefreshData={onRefreshData}
          activeLineIconKey={activeLineIconKey}
          changeActiveLineIconKey={changeActiveLineIconKey}
          globalLoading={globalLoading}
        />
      </>
    );
  }
}

const PredictedIncidentList = injectIntl(PredictedIncidentCore);
export default connect((state: Object) => {
  const { location } = state.router;
  return {
    location,
  };
}, {})(PredictedIncidentList);
