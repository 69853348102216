/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import moment from 'moment';
import { get, round, toInteger, isNumber } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults, parseJSON } from '../utils';

const getGlobalHealthScores = (credentials: Credentials, params: Object) => {
  const {
    environmentId,
    startTimestamp,
    endTimestamp,
    systemIds,

    systemInfoMap,
    isAdmin,
    environment,
  } = params;

  // Split diff systems api call by owners
  const customerMap = {};
  R.forEach((systemId) => {
    if (systemInfoMap[systemId]) {
      const { owner } = systemInfoMap[systemId];
      if (!customerMap[owner]) customerMap[owner] = [];
      customerMap[owner].push(systemId);
    }
  }, systemIds);

  const requests = [];
  R.forEach((customerName) => {
    const systemIds = customerMap[customerName];
    requests.push(
      fetchGet(getEndpoint('stats/global/systemanomalyscore', 2), {
        ...credentials,
        envName: environmentId,
        startTime: startTimestamp,
        endTime: endTimestamp,
        customerName,
        systemIds: JSON.stringify(systemIds),
        shareUserFlag: customerName !== credentials.userName && !isAdmin,
      }),
    );
  }, R.keys(customerMap));

  const parseCharts = (data) => {
    const needPaddingData = true;
    const systemHealth = [];
    const nowTimeObj = moment.utc();
    const nowStartTimestamp = nowTimeObj.clone().startOf('day').valueOf();
    const queryDayTimes = [];
    const queryDayTimesMap = {};
    R.forEach((ts) => {
      const timestamp = ts * 86400000;
      queryDayTimes.push(timestamp);
      queryDayTimesMap[timestamp] = false;
    }, R.range(startTimestamp / 86400000, endTimestamp / 86400000));

    try {
      const systemList = get(environment, 'systemList', []);
      R.forEach((systemInfo) => {
        const { systemName: systemId, ...daysInfo } = systemInfo;

        let hasAnyData = false;
        let hasLastData = false;
        const hasDataMap = R.clone(queryDayTimesMap);
        let currentTimestamp = 0;
        let allDayAnomalyScoresList = [];
        let allIncidentIndicatorList = [];
        let historicalMaxStats = [];
        // parse every days info
        R.forEachObjIndexed((val, day) => {
          const {
            hasData,
            healthScoreSeries,
            incidentIndicator,
            // deploymentIndicator,
            totalMaxStats,
          } = val;
          let { currentTimestamp: cTimestamp } = val;
          hasAnyData = hasAnyData || hasData;
          const dayStartTimestamp = moment.utc(day, Defaults.DateFormat).startOf('day').valueOf();
          const dayEndTimestamp = moment.utc(day, Defaults.DateFormat).endOf('day').valueOf();

          if (cTimestamp === dayStartTimestamp) {
            cTimestamp = dayEndTimestamp;
          }
          if (cTimestamp && cTimestamp > currentTimestamp) {
            currentTimestamp = cTimestamp;
            hasLastData = hasData;
          }

          hasDataMap[dayStartTimestamp] = hasData || false;

          // skip if no data
          if (!hasData) return;

          // parse max score
          historicalMaxStats.push({
            day,
            ...totalMaxStats,
          });

          // parse max score
          historicalMaxStats = R.sortWith([R.ascend(R.prop('day'))], historicalMaxStats);

          // parse scores
          let anomalyScoresList = R.map((anomaly) => {
            return { timestamp: anomaly.timeStamp, score: round(anomaly.score, 1) };
          }, R.values(healthScoreSeries || {}));
          anomalyScoresList = R.sortWith([R.ascend(R.prop('timestamp'))], anomalyScoresList);
          anomalyScoresList = R.filter((anomaly) => anomaly.timestamp >= dayStartTimestamp, anomalyScoresList);
          if (dayStartTimestamp < nowStartTimestamp) {
            anomalyScoresList = R.filter((anomaly) => anomaly.timestamp < dayEndTimestamp, anomalyScoresList);
          }
          allDayAnomalyScoresList = [...allDayAnomalyScoresList, ...anomalyScoresList];

          // parse incident times
          const incidentIndicatorMap = parseJSON(incidentIndicator) || {};
          let incidentIndicatorList = R.values(incidentIndicatorMap);
          // If incident time is older than dayStartTimestamp, change to dayStartTimestamp
          if (dayStartTimestamp < nowStartTimestamp) {
            incidentIndicatorList = R.filter((anomaly) => anomaly.timestamp < dayEndTimestamp, incidentIndicatorList);
          }
          incidentIndicatorList = R.sortWith([R.ascend(R.prop('timestamp'))], incidentIndicatorList);
          incidentIndicatorList = R.map(
            (x) => (x.timestamp < dayStartTimestamp ? { ...x, timestamp: dayStartTimestamp } : x),
            incidentIndicatorList,
          );
          allIncidentIndicatorList = [...allIncidentIndicatorList, ...incidentIndicatorList];
        }, daysInfo || {});

        // get currentTimestamp, and set isCurrentDay
        currentTimestamp = currentTimestamp || nowTimeObj.valueOf();

        const currentTimestampObj = moment.utc(currentTimestamp);
        const isCurrentDay = !(currentTimestampObj.hours() >= 23 && currentTimestampObj.minutes() > 50);

        // Get interval info
        // const intervalInMinutes = get(systemInfoMap, [systemId, 'aggregationInterval'], 1);
        let intervalInMinutes = 1;
        if (allDayAnomalyScoresList.length > 2) {
          const startTimes = allDayAnomalyScoresList[0].timestamp;
          const endTimes = allDayAnomalyScoresList[allDayAnomalyScoresList.length - 1].timestamp;
          intervalInMinutes = round((endTimes - startTimes) / 60 / 1000 / allDayAnomalyScoresList.length);
        }

        // Add padding time info
        if (needPaddingData) {
          R.forEachObjIndexed((dayHasData, dayTime) => {
            if (!dayHasData) {
              const anomalyScoresList = R.map((mins) => {
                const timestamp = Number(dayTime) + mins * intervalInMinutes * 60000;
                return { timestamp, score: null };
              }, R.range(0, (24 * 60) / intervalInMinutes));
              allDayAnomalyScoresList = [...allDayAnomalyScoresList, ...anomalyScoresList];
            }
          }, hasDataMap);
        }

        // sort all days Anomaly Scores
        allDayAnomalyScoresList = R.sortWith([R.ascend(R.prop('timestamp'))], allDayAnomalyScoresList);

        // calculate the score by avg
        let avgHealthScore = 0;
        let currentScore = 0;
        const hasDataScoreList = R.filter((item) => isNumber(item.score), allDayAnomalyScoresList);
        if (isCurrentDay) {
          const currentItem = R.findLast((item) => item.timestamp <= currentTimestamp, hasDataScoreList);
          if (currentItem) {
            currentScore = currentItem.score;
          }
          const filterDatas = R.filter((item) => item.timestamp <= currentTimestamp, hasDataScoreList);
          avgHealthScore =
            filterDatas.length > 0
              ? R.reduce(
                  R.add,
                  0,
                  R.map((item) => item.score, filterDatas),
                ) / filterDatas.length
              : 0;
        } else {
          const filterDatas = R.filter((item) => item, hasDataScoreList);
          avgHealthScore =
            filterDatas.length > 0
              ? R.reduce(
                  R.add,
                  0,
                  R.map((item) => item.score, filterDatas),
                ) / filterDatas.length
              : 0;
        }
        avgHealthScore = toInteger(avgHealthScore);

        // get incident and deployment times from health score info
        const predictionIncidentTimes = [];
        const detectionIncidentTimes = [];
        let predictionIncidentTimestamp = [];
        try {
          R.forEach((value) => {
            const { timestamp, isPrediction, isIgnored, predictionTime } = value || {};
            if (!isIgnored) {
              if (isPrediction) {
                if (predictionTime) {
                  predictionIncidentTimestamp.push(Number(predictionTime));
                }
                predictionIncidentTimes.push(Number(timestamp));
              } else {
                detectionIncidentTimes.push(Number(timestamp));
              }
            }
          }, allIncidentIndicatorList);
        } catch (err) {
          console.error(err);
        }
        predictionIncidentTimestamp = R.uniq(predictionIncidentTimestamp);

        // create system health info
        const system = R.find((s) => s.id === systemId, systemList);
        systemHealth.push({
          id: system.id,
          name: system.name,
          environmentId,
          systemId: system.id,

          hasData: hasAnyData,
          hasLastData,
          isCurrentDay,
          avgHealthScore,

          // health chart used field
          allDayAnomalyScoresList,
          predictionIncidentTimes,
          predictionIncidentTimestamp,
          detectionIncidentTimes,

          intervalInMinutes,
          currentTimestamp,
          currentScore,
          dataStartTimestamp: startTimestamp,
          dataEndTimestamp: endTimestamp,

          // Historical max scores
          historicalMaxStats,
        });
      }, data || []);
    } catch (e) {
      console.error(e);
    }

    return systemHealth;
  };

  return Promise.all(requests).then((results) => {
    const data = [];
    R.forEach((result) => {
      R.forEachObjIndexed((val, key) => {
        data.push(val);
      }, result);
    }, results);
    const globalHealthSummary = parseCharts(data);
    return { globalHealthSummary };
  });
};

export default getGlobalHealthScores;
