import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Spin } from 'antd';

import {
  ApartmentOutlined,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { CellRenderers, Defaults, parseLocation, sleep } from '../../../common/utils';
import { CellMeasurer, CellMeasurerCache, Container, Modal, Popover } from '../../../lib/fui/react';
import {
  buildMergeData,
  dateHeight,
  getIndividualJumpInfo,
  handleCategoryClick,
  handleClickDateRow,
  impactedRender,
  listHeaderHeight,
  positionEvent,
  recurrentIncidentsViewList,
  renderCategory,
  rendererExpand,
  rowMinHeight,
  statusRenderer,
  tableViewList,
  timeRenderer,
  traceRenderer,
} from '../utils/rootCauseTimeLine';

import { eventMessages } from '../../../common/metric/messages';
import { RecurrentIncidentsIcon } from '../../../lib/fui/icons';

type Props = {
  intl: Object,
  incident: Object,
  onClose: Function,
  refreshLoading: Boolean,
  activeKey: String,
  tabName: String,
  currentTheme: String,
  location: Object,
  refresh: Function,
  fatherInstanceRenderer: Function,
  impactInstanceRenderer: Function,
  rendererContent: Function,
  rootCauseChainRender: Function,
  rendererIncidentStatus: Function,
  individualIncident: Object,
  systemId: String,
  handleJumpBySelf: Function,
  clearJumpBySelf: Function,
  eventCategory: String,
  eventPatternType: String,
  eventPatternId: String,
  eventProjectName: String,
  eventInstanceName: String,
  eventTimestamp: String,
};

class EmbedIndividualIncidentsModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = listHeaderHeight;
    this.rowMinHeight = rowMinHeight;
    this.dateHeight = dateHeight;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      fixedHeight: false,
      minHeight: this.dateHeight,
    });

    this.state = {
      isLoadingParserData: false,

      allExpand: true,
      dateAllExpand: true,

      sortBy: null,
      sortDirection: null,

      events: [],
      filterList: [],
      eventList: [],
      isAllChecked: false,
      jumpIncident: undefined,
    };

    this.fatherEvent = null;
    this.childEvent = null;
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextAnomalyTimelines = get(nextProps.incident, ['anomalyTimelines']);
    const anomalyTimelines = get(this.props.incident, ['anomalyTimelines']);
    if (nextAnomalyTimelines !== anomalyTimelines) {
      this.parseData(nextProps);
    }
  }

  @autobind
  async parseData(props) {
    const { incident, eventProjectName, eventPatternId, eventTimestamp, clearJumpBySelf } = props;
    const { sortBy, sortDirection } = this.state;

    this.setState({ isLoadingParserData: true });
    await sleep(300);

    const eventList = get(incident, ['anomalyTimelines'], []);

    // anomaly events filter
    R.addIndex(R.forEach)((item, index) => {
      item.sortNum = index + 1;
    }, eventList || []);

    const filterList = this.filterData(props, eventList);
    const mergedList = buildMergeData(filterList, sortBy, sortDirection, this);

    const { jumpFatherIncident, jumpIncident } = getIndividualJumpInfo({
      props,
      mergedList,
      eventProjectName,
      eventPatternId,
      eventTimestamp,
      isJWT: true,
    });

    this.setState({ isLoadingParserData: false, events: eventList, filterList, eventList: mergedList }, () => {
      if (eventProjectName && eventPatternId && eventTimestamp) {
        clearJumpBySelf();
      }
      if (jumpFatherIncident && jumpIncident) {
        this.fatherEvent = jumpFatherIncident;
        this.childEvent = jumpIncident;
        positionEvent({ self: this });
      } else {
        this.cellMeasureCache.clearAll();
        if (this.refList) this.refList.forceUpdateGrid();
        this.forceUpdate();
      }
    });
  }

  @autobind
  filterData(props, eventList) {
    const filterList = eventList || [];

    this.cellMeasureCache.clearAll();
    if (this.refList) this.refList.forceUpdateGrid();
    this.forceUpdate();

    return filterList;
  }

  @autobind
  peersListItem(tabName, events, jumpIncident) {
    return ({ key, index: rowIndex, style, parent, columnIndex, ...rest }) => {
      const { eventList } = this.state;
      const rowData = events[rowIndex];
      if (!rowData) return null;
      const { intl, activeKey, currentTheme, fatherInstanceRenderer, impactInstanceRenderer } = this.props;
      const { rendererContent, rootCauseChainRender, rendererIncidentStatus, systemId } = this.props;

      const { isImportant, hasImportant, id, isRootEvent, hasRootCause, mergeKey, isCompositeAnomaly } = rowData;
      const { isIgnored, isTimeRoot, startTimestamp, recurringIncidentTimes } = rowData;
      const active = jumpIncident && jumpIncident.id === id;

      const dateData = R.find(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(eventList);
      const dateIdx = R.findIndex(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(eventList);
      const rootEvent = R.find(R.propEq('mergeKey', mergeKey))(dateData?.children || []);
      const rootEventIdx = R.findIndex(R.propEq('mergeKey', mergeKey))(dateData?.children || []);

      return (
        <CellMeasurer
          key={key}
          cache={this.cellMeasureCache}
          parent={parent}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
        >
          <div style={{ ...style }}>
            {isTimeRoot && (
              <div
                className="event-list-category-row clickable"
                style={{ height: this.dateHeight - 1, background: currentTheme === 'dark' ? '#4b4a4a' : '#d0d0d0' }}
                onClick={() => handleClickDateRow(dateIdx, rowData, this)}
              >
                <div className="row-column flex-center-justify" style={{ minWidth: 56 }} />
                <div
                  className="row-column font-14 bold"
                  style={{ minWidth: 70 + 180 + 210, maxWidth: 70 + 180 + 210, paddingRight: 16 }}
                >
                  {rowData.dateEnFormat}
                </div>
                <div className="row-column flex-grow" />
                <div className="row-column text-center" style={{ minWidth: 130, maxWidth: 130 }}>
                  <span className="full-width">{rowData.count}</span>
                </div>
                <div className="row-column" style={{ width: 30 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
                <div className="row-column flex-center-justify" style={{ width: 60 }} />
              </div>
            )}
            {!isTimeRoot && isRootEvent && (
              <div
                className="event-list-category-row clickable"
                style={{ height: this.rowMinHeight - 1, backgroundColor: 'var(--item-active-bg2)' }}
                onClick={() => handleCategoryClick({ dateIdx, rootEventIdx, rowData, self: this })}
              >
                <div className="row-column flex-center-justify" style={{ minWidth: 56 }} />
                <div className="row-column" style={{ minWidth: 70, maxWidth: 70, paddingRight: 16 }} />
                <div className="row-column" style={{ minWidth: 180, maxWidth: 180, paddingRight: 16 }}>
                  {fatherInstanceRenderer(rowData, rootEventIdx, dateIdx, (dateIdx, rootEventIdx, rowData) =>
                    handleCategoryClick({ dateIdx, rootEventIdx, rowData, self: this }),
                  )}
                </div>
                <div className="row-column" style={{ minWidth: 210, maxWidth: 210, paddingRight: 16 }}>
                  {impactedRender(rowData, this)}
                </div>
                <div className="row-column" style={{ flex: 1, minWidth: 140 }} />
                <div className="row-column" style={{ minWidth: 130, maxWidth: 130, paddingRight: 16 }}>
                  {hasRootCause && (
                    <SearchOutlined style={{ fontSize: 16, width: '100%', color: 'var(--primary-color)' }} />
                  )}
                </div>
                <div className="row-column" style={{ minWidth: 90, maxWidth: 90 }} />
                {activeKey === 'trace' && <div className="row-column" style={{ minWidth: 40 }} />}
                {activeKey !== 'incident' && <div className="row-column" style={{ minWidth: 65 }} />}
                <div className="row-column text-center" style={{ minWidth: 70, maxWidth: 70 }} />
                {tabName === 'incident' && <div className="row-column" style={{ minWidth: 80, maxWidth: 80 }} />}
                <div className="row-column text-center" style={{ minWidth: 130, maxWidth: 130 }}>
                  <span className="full-width">{rowData.count}</span>
                </div>
                <div className="row-column" style={{ width: 30 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
                <div className="row-column flex-center-justify" style={{ width: 60 }}>
                  {hasImportant ? (
                    <ExclamationCircleFilled
                      className={`${hasImportant ? 'isImportantColor' : 'hover-color'}`}
                      style={{ color: currentTheme === 'dark' ? '#e6e6e6' : '#cacaca', fontSize: 16 }}
                    />
                  ) : (
                    <div style={{ width: 16 }} />
                  )}
                  <Popover
                    title={null}
                    content={intl.formatMessage(
                      isIgnored ? eventMessages.noIgnorePattern : eventMessages.ignorePattern,
                    )}
                    mouseEnterDelay={0.3}
                    placement="left"
                  >
                    {isIgnored ? (
                      <EyeInvisibleOutlined
                        style={{ color: currentTheme === 'dark' ? '#e6e6e6' : '#949393', fontSize: 14, marginLeft: 8 }}
                      />
                    ) : (
                      <EyeOutlined
                        style={{ color: currentTheme === 'dark' ? '#e6e6e6' : '#949393', fontSize: 14, marginLeft: 8 }}
                      />
                    )}
                  </Popover>
                </div>
              </div>
            )}
            {!isTimeRoot && !isRootEvent && (
              <div className="full-width">
                <div className="flex-grow flex-min-width event-list">
                  <div
                    className={`incident-event-list-row${active ? ' active' : ''}`}
                    onClick={() => this.setState({ jumpIncident: rowData })}
                    style={{
                      borderBottom: '1px solid var(--virtualized-table-border-color)',
                      height: this.rowMinHeight - 1,
                    }}
                  >
                    <div className="row-column flex-center-justify" style={{ minWidth: 56 }}>
                      {isCompositeAnomaly && (
                        <Popover
                          placement="right"
                          title={null}
                          content={intl.formatMessage(eventMessages.compositeIncident)}
                          trigger="hover"
                        >
                          <ApartmentOutlined style={{ fontSize: 14, marginRight: 8, color: 'var(--primary-color)' }} />
                        </Popover>
                      )}
                      {recurringIncidentTimes.length > 0 && (
                        <Popover
                          placement="right"
                          title={intl.formatMessage(eventMessages.recurrentIncidents)}
                          content={recurrentIncidentsViewList(rowData, intl, systemId, true, this)}
                          trigger="hover"
                        >
                          <RecurrentIncidentsIcon style={{ fontSize: 17, color: 'var(--primary-color)' }} />
                        </Popover>
                      )}
                    </div>
                    <div className="row-column" style={{ minWidth: 70, maxWidth: 70, paddingRight: 16 }}>
                      {timeRenderer(rowData)}
                    </div>
                    <div className="row-column" style={{ minWidth: 180, maxWidth: 180, paddingRight: 16 }} />
                    <div className="row-column" style={{ minWidth: 210, maxWidth: 210, paddingRight: 16 }}>
                      {impactInstanceRenderer(rowData)}
                    </div>
                    <div
                      className="row-column"
                      style={{ flex: 1, paddingRight: 10, minWidth: 140, minHeight: this.rowMinHeight }}
                    >
                      {rendererContent(rowData, false)}
                    </div>
                    <div className="row-column" style={{ minWidth: 130, maxWidth: 130, paddingRight: 16 }}>
                      {rootCauseChainRender({ rowData, onlyCloseModal: true })}
                    </div>
                    <div className="row-column" style={{ minWidth: 90, maxWidth: 90 }}>
                      <Popover
                        content={`Duration: ${CellRenderers.humanizeDuration({
                          period: rowData.duration + 1,
                          intl,
                          showSeconds: true,
                          showZero: true,
                          showZeroToOne: true,
                        })}`}
                        mouseEnterDelay={0.3}
                      >
                        {CellRenderers.humanizeDuration({
                          period: rowData.duration + 1,
                          intl,
                          showSeconds: true,
                          showZero: true,
                          shortDisplay: true,
                          showZeroToOne: true,
                        })}
                      </Popover>
                    </div>
                    {activeKey === 'trace' && (
                      <div className="row-column flex-center-justify flex-wrap" style={{ minWidth: 40 }}>
                        {traceRenderer(rowData)}
                      </div>
                    )}
                    {activeKey !== 'incident' && (
                      <div className="row-column flex-center-justify flex-wrap" style={{ minWidth: 65 }}>
                        {renderCategory(rowData, this)}
                      </div>
                    )}
                    <div className="row-column flex-wrap" style={{ minWidth: 70, maxWidth: 70 }}>
                      {statusRenderer(rowData, this)}
                    </div>
                    {tabName === 'incident' && (
                      <>
                        <div className="row-column" style={{ minWidth: 80, maxWidth: 80 }}>
                          {rendererIncidentStatus(rowData)}
                        </div>
                      </>
                    )}
                    <div
                      className="row-column"
                      style={{ minWidth: 130 + 30, maxWidth: 130 + 30, justifyContent: 'center' }}
                    />
                    <div
                      className="row-column flex-center-justify"
                      style={{ minWidth: 60, height: 39, position: 'relative' }}
                    >
                      <Popover
                        title={null}
                        content={
                          isCompositeAnomaly
                            ? intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
                            : intl.formatMessage(
                                isImportant ? eventMessages.removeImportantFlag : eventMessages.markAsImportant,
                              )
                        }
                        mouseEnterDelay={0.3}
                        placement="right"
                      >
                        <ExclamationCircleFilled
                          className={`${isImportant ? 'isImportantColor' : 'hover-color'}`}
                          style={{ color: currentTheme === 'dark' ? '#e6e6e6' : '#cacaca', fontSize: 16 }}
                        />
                      </Popover>
                      {tabName === 'incident' ? (
                        <div style={{ marginLeft: 8 }}>
                          <div style={{ width: 16 }} />
                        </div>
                      ) : (
                        <div style={{ marginLeft: 8 }}>
                          <div style={{ width: 14, height: 14 }} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CellMeasurer>
      );
    };
  }

  render() {
    const { intl, onClose, refreshLoading } = this.props;
    const { isLoadingParserData } = this.state;

    return (
      <Modal
        title={intl.formatMessage(eventMessages.individualIncidents)}
        width={1400}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        bodyStyle={{ height: 600 }}
        footer={null}
      >
        <Container className="flex-grow flex-min-height flex-col full-height">
          <Spin
            spinning={isLoadingParserData || refreshLoading}
            wrapperClassName="full-width full-height spin-full-width"
          >
            <div className="full-width full-height flex-row">
              <div className="full-width full-height flex-col">
                {/* 布局在peersListItem */}
                {tableViewList(true, this, intl, this.peersListItem)}
              </div>
            </div>
          </Spin>
        </Container>
      </Modal>
    );
  }
}

const EmbedIndividualIncidentsModal = injectIntl(EmbedIndividualIncidentsModalCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { dark } = parseLocation(location);
  if (dark) {
    state.app.currentTheme = 'dark';
  } else {
    state.app.currentTheme = 'light';
  }

  const { currentTheme } = state.app;
  return { location, currentTheme };
}, {})(EmbedIndividualIncidentsModal);
