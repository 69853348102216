/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';

const getLogKeywordInfoList = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, day } = params;
  const startTimeObj = moment.utc(day, Defaults.DateFormat).startOf('day');
  const requests = [];
  requests.push(
    fetchGet(getEndpoint('logkeyword'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
      dayString: day,
    }),
  );
  return Promise.all(requests).then((results) => {
    let keywordList = get(results[0], 'data', []);
    let maxEventsCount = 0;

    keywordList = R.map((k) => {
      maxEventsCount = Math.max(maxEventsCount, k.count);
      return { eventsCount: k.count, id: k.keyword, name: k.keyword, patternOId: k.ONid };
    }, keywordList);

    keywordList = R.sort((a, b) => (a.name || '').localeCompare(b.name), keywordList);
    // const totalCount = R.reduce((acc, i) => acc + i.eventsCount, 0, keywordList);
    R.forEach((p) => (p.countRatio = p.eventsCount / maxEventsCount), keywordList);

    return {
      keywordInfoList: keywordList,
    };
  });
};

export default getLogKeywordInfoList;
