/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { injectIntl } from 'react-intl';
import { get, isEqual, isFinite, round, isUndefined, isArray, isInteger } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import {
  DeleteOutlined,
  CheckOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  message,
  notification,
  Pagination,
  Form,
  Checkbox,
  Select as AntdSelect,
  Input as AntdInput,
  InputNumber,
  DatePicker,
  Slider,
  Alert,
  Spin,
  Popconfirm,
  Divider,
  Space,
  Tooltip,
  TreeSelect,
} from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchDelete from '../../../../common/apis/fetchDelete';
import getEndpoint from '../../../../common/apis/getEndpoint';
import {
  Modal,
  Table,
  Column,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Container,
  Select,
  Popover,
  SortDirection,
} from '../../../../lib/fui/react';
import { loadProjectInfo, updateLastActionInfo } from '../../../../common/app/actions';
import { loadProjectMetricSettings, saveProjectMetricSettings } from '../../../../common/settings/actions';
import { buildLocation, parseLocation, getReaderStream, downloadFile } from '../../../../common/utils';
import { AscendingComparer } from '../../../../lib/fui/utils';

import EditMetricTypeModal from './EditMetricTypeModal';
import AlertThresholdsModal from './AlertThresholdsModal';
import MetricPatternNameRegexModal from './MetricPatternNameRegexModal';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { eventMessages } from '../../../../common/metric/messages';
import { causalMessages } from '../../../../common/causal/messages';
import getInstanceDisplayName from '../../../../common/utils/getInstanceDisplayName';
import MetricFileModal from './MetricFileModal';

type Props = {
  intl: Object,
  location: Object,
  // eslint-disable-next-line
  credentials: Object,
  userInfo: Object,
  userList: Array<Object>,
  timezoneOffset: Number,
  projectName: String,
  refreshTime: Number,
  currentProject: Object,
  instanceMetricList: Array<Object>,
  metricListCount: Number,
  possibleMetricType: Array<String>,
  batchPossibleMetricType: Array<String>,
  metricStatsMap: Object,
  excludedMetrics: Array<String>,
  currentLoadingComponents: Object,
  data: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  loadProjectMetricSettings: Function,
  saveProjectMetricSettings: Function,
  entry: String,
  batchMetrics: Array<Object>,
  handleMetricList: Function,
  refs: Function,
  initialMetricSetting: Object,
  updateStatus: String,

  projects: Array<Object>,
  loadProjectInfo: Function,
  patternIdGenerationRule: Number,
  patternNameRegex: String,
  instanceDisplayNameMap: Object,
  batchSamplingInterval: Number,
  setHasNotInteger: Function,
};

const scientificNumbers = (number, places = 6) => {
  if (typeof number !== 'number') return;
  if (number === 0) {
    // eslint-disable-next-line consistent-return
    return numeral(number).format('0.0');
  } else if (number > 999999) {
    // eslint-disable-next-line consistent-return
    return Number.parseFloat(number).toExponential(places).replace('e', 'E');
  } else {
    // eslint-disable-next-line consistent-return
    return numeral(number).format('0.0');
  }
};

class MetricSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.submitLoadingKey = 'settings_metric_submit';
    this.submitGroupUpdateLoadingKey = 'settings_metric_group_update_submit';
    this.instanceLoader = 'settings_metric_instance_loader';

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 70,
    });

    this.localBatchMetrics = R.clone(get(props, 'batchMetrics', []));
    const isBatch = props.entry === 'batch';
    const metricList = isBatch ? this.localBatchMetrics || [] : this.localMetrics || [];
    const samplingInterval = isBatch ? props.batchSamplingInterval : get(props, ['data', 'samplingInterval'], 0) / 60;

    // Make a full copy of the data to avoid side affect with other components.
    this.localMetrics = [];
    this.saveLocalMetrics = [];
    const isCheckedAll = metricList.length > 0 && !R.find((m) => !m.checked, metricList);
    const isKpiAll = metricList.length > 0 && !R.find((m) => !m.isKPI, metricList);
    const isFillZeroAll = metricList.length > 0 && !R.find((m) => !m.fillZero, metricList);

    const isAllComputeDifference = metricList.length > 0 && !R.find((m) => !m.computeDifference, metricList);
    const isAllNearConstantDetection =
      metricList.length > 0 && !R.find((m) => !m.enableBaselineNearConstance, metricList);

    // use user/customer time zone to set isCurrentDay
    const { userInfo, userList, currentProject, patternIdGenerationRule, patternNameRegex } = props;
    const owner = get(currentProject, 'owner', '');
    let { timezoneOffset } = props;
    if (userInfo.isAdmin && owner) {
      const customerUserInfo = R.find((user) => user.userName === currentProject.owner, userList || []);
      timezoneOffset = customerUserInfo ? customerUserInfo.userZoneOffset : timezoneOffset;
    }
    const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
    const params = parseLocation(props.location);
    const { metricFilter, startTimestamp, endTimestamp } = params;
    const startTimeObj = startTimestamp ? moment.utc(parseInt(startTimestamp, 10)) : '';
    const endTimeObj = endTimestamp ? moment.utc(parseInt(endTimestamp, 10)) : '';
    this.state = {
      isDeleting: false,
      errMsg: undefined,

      metricFilter: metricFilter || '',
      statsStartTimeObj: startTimeObj || moment.utc(nowTimestamp),
      statsEndTimeObj: endTimeObj || moment.utc(nowTimestamp),
      page: 1,
      pageSize: 500,

      onlyFilter: {},
      onlyFilterMetric: [],

      // eslint-disable-next-line
      isCheckedAll,
      // eslint-disable-next-line
      isKpiAll,
      // eslint-disable-next-line
      isAllComputeDifference,
      isAllNearConstantDetection,
      isFillZeroAll,

      showGroupingUpdateModal: false,
      showMetricFileModal: false,

      showEditMetricTypeModel: false,

      componentNameList: [],
      ignoredList: [],
      escalateIncidentList: [],

      sortBy: null,
      sortDirection: null,

      instanceGroupList: [],

      activeRowData: null,
      showAlertThresholdsModal: false,

      patternIdGenerationRule,
      patternNameRegex,
      showPatternNameRegexModal: false,

      samplingInterval,
    };
    this.cmp = (a, b) => {
      return (
        a.smetric === b.smetric &&
        // a.specialInterval === b.specialInterval &&
        a.detectionType === b.detectionType &&
        a.isKPI === b.isKPI &&
        a.kpiDurationThreshold === b.kpiDurationThreshold &&
        a.patternNameHigher === b.patternNameHigher &&
        a.patternNameLower === b.patternNameLower &&
        a.computeDifference === b.computeDifference &&
        a.enableBaselineNearConstance === b.enableBaselineNearConstance &&
        a.thresholdAlertLowerBound === b.thresholdAlertLowerBound &&
        a.thresholdAlertUpperBound === b.thresholdAlertUpperBound &&
        a.thresholdAlertLowerBoundNegative === b.thresholdAlertLowerBoundNegative &&
        a.thresholdAlertUpperBoundNegative === b.thresholdAlertUpperBoundNegative &&
        a.thresholdNoAlertLowerBoundNegative === b.thresholdNoAlertLowerBoundNegative &&
        a.thresholdNoAlertUpperBoundNegative === b.thresholdNoAlertUpperBoundNegative &&
        a.thresholdNoAlertLowerBound === b.thresholdNoAlertLowerBound &&
        a.thresholdNoAlertUpperBound === b.thresholdNoAlertUpperBound &&
        a.rougeValue?.l === b.rougeValue?.l &&
        a.rougeValue?.s === b.rougeValue?.s &&
        a.metricType === b.metricType &&
        a.fillZero === b.fillZero &&
        a.anomalyDampening === b.anomalyDampening
      );
    };
    this.checkboxCellRender = ({ dataKey, rowData, cellData }) => (
      <>
        {(!rowData.isInstanceLevel || dataKey !== 'checked') && (
          <input
            className="fui input"
            type="checkbox"
            checked={cellData || false}
            disabled={rowData.isInstanceLevel}
            onChange={this.handleInputChanged(rowData, dataKey)}
          />
        )}
        {rowData.isInstanceLevel && dataKey === 'checked' && <div />}
      </>
    );
    this.modifiedCellRender = ({ cellData }) => <p>{cellData ? 'Yes' : ''}</p>;

    this.instanceToApp = {};
    this.instanceListOption = [];

    this.metricTypeList = [];
    this.detectionTypeList = [
      { value: 'positive', label: 'Positive' },
      { value: 'negative', label: 'Negative' },
    ];
    this.metricPriorityOptions = [
      { label: 'Low', value: 1 },
      { label: 'Medium Low', value: 2 },
      { label: 'Medium', value: 3 },
      { label: 'Medium High', value: 4 },
      { label: 'High', value: 5 },
    ];
    this.localMetricInstanceLevelList = [];
    this.generationRuleOption = [
      { label: 'Metric type', value: 0 },
      { label: 'Metric name', value: 1 },
    ];

    this.MAX_FATCH_NUM = 1000;
  }

  async componentDidMount() {
    if (R.equals(this.props.entry, 'batch')) {
      this.props.refs(this);
      return;
    }
    await this.getInstanceLevel(this.props);
    await this.getLoadProjectInfo(this.props);
    await this.getIgnoreList();
    await this.getEscalateIncident();
    this.parseData(this.props);
    this.reloadData(this.props);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      instanceMetricList,
      possibleMetricType,
      batchPossibleMetricType,
      excludedMetrics,
      patternIdGenerationRule,
      patternNameRegex,
    } = nextProps;
    const nextParams = parseLocation(nextProps.location);
    const params = parseLocation(this.props.location);
    const { intl } = this.props;

    if (
      nextProps.entry === 'batch' &&
      nextProps.updateStatus !== 'tab' &&
      nextProps.batchMetrics !== this.props.batchMetrics
    ) {
      this.setState({ sortBy: null, sortDirection: null });
      this.localBatchMetrics = R.clone(get(nextProps, 'batchMetrics', []));
      const isCheckedAll = this.localBatchMetrics.length > 0 && !R.find((m) => !m.checked, this.localBatchMetrics);
      const isKpiAll = this.localBatchMetrics.length > 0 && !R.find((m) => !m.isKPI, this.localBatchMetrics);
      const isAllComputeDifference =
        this.localBatchMetrics.length > 0 && !R.find((m) => !m.computeDifference, this.localBatchMetrics);
      const isAllNearConstantDetection =
        this.localBatchMetrics.length > 0 && !R.find((m) => !m.enableBaselineNearConstance, this.localBatchMetrics);
      const isFillZeroAll = this.localBatchMetrics.length > 0 && !R.find((m) => !m.fillZero, this.localBatchMetrics);

      // eslint-disable-next-line
      this.setState(
        { isCheckedAll, isKpiAll, isAllComputeDifference, isAllNearConstantDetection, isFillZeroAll },
        () => {
          if (this.table) {
            this.cellMeasureCache.clearAll();
            this.table.forceUpdateGrid();
          }
        },
      );
    }

    if (nextProps.data !== this.props.data) {
      await this.getInstanceLevel(this.props);
      this.parseData(nextProps);
      if (nextProps.entry !== 'batch') {
        this.setState({ samplingInterval: get(nextProps, ['data', 'samplingInterval'], 0) / 60 });
      }
    }

    if (
      nextProps.patternIdGenerationRule !== this.props.patternIdGenerationRule ||
      nextProps.patternNameRegex !== this.props.patternNameRegex
    ) {
      this.setState({ patternIdGenerationRule, patternNameRegex });
    }

    if (
      this.props.projectName !== nextProps.projectName ||
      this.props.refreshTime !== nextProps.refreshTime ||
      params.reloadProject !== nextParams.reloadProject
    ) {
      this.getLoadProjectInfo(nextProps);
    } else if (this.props.projects !== nextProps.projects) {
      this.getComponentNameList(nextProps);
    }

    let changeState = false;
    if (
      this.props.refreshTime !== nextProps.refreshTime ||
      nextParams.instanceId !== params.instanceId ||
      nextParams.metricFilter !== params.metricFilter
    ) {
      if (nextParams.metricFilter !== params.metricFilter) {
        this.setState({ page: 1 });
      }
      this.getIgnoreList();
      this.getEscalateIncident();
      this.reloadData(nextProps);
    } else if (
      nextProps.instanceMetricList !== this.props.instanceMetricList ||
      nextProps.possibleMetricType !== this.props.possibleMetricType ||
      nextProps.batchPossibleMetricType !== this.props.batchPossibleMetricType
    ) {
      this.setState({ isDeleting: true, sortBy: null, sortDirection: null });
      // Add the processing data displayed by Input
      let cloneInstanceMetricList = R.clone(instanceMetricList);
      await this.getInstancelevelmetricsetting(nextProps, cloneInstanceMetricList);
      cloneInstanceMetricList = R.map((item) => {
        return {
          ...item,
          ignoreList: [],
          escalateIncident: [],
          thresholdAlertLowerBoundViewVal: this.handleInputBlue({ value: item.thresholdAlertLowerBound }),
          thresholdAlertUpperBoundViewVal: this.handleInputBlue({ value: item.thresholdAlertUpperBound }),
          thresholdNoAlertLowerBoundViewVal: this.handleInputBlue({ value: item.thresholdNoAlertLowerBound }),
          thresholdNoAlertUpperBoundViewVal: this.handleInputBlue({ value: item.thresholdNoAlertUpperBound }),
          thresholdAlertLowerBoundNegativeViewVal: this.handleInputBlue({
            value: item.thresholdAlertLowerBoundNegative,
          }),
          thresholdAlertUpperBoundNegativeViewVal: this.handleInputBlue({
            value: item.thresholdAlertUpperBoundNegative,
          }),
          thresholdNoAlertLowerBoundNegativeViewVal: this.handleInputBlue({
            value: item.thresholdNoAlertLowerBoundNegative,
          }),
          thresholdNoAlertUpperBoundNegativeViewVal: this.handleInputBlue({
            value: item.thresholdNoAlertUpperBoundNegative,
          }),
          rougeValue: {
            l: this.handleInputBlue({ value: item.rougeValue?.l }),
            s: this.handleInputBlue({ value: item.rougeValue?.s }),
          },
        };
      }, cloneInstanceMetricList);

      this.localMetrics = R.map((item) => {
        R.forEachObjIndexed((c, k) => {
          if (item[k] === 'NaN' || Number.isNaN(item[k]) || R.isNil(item[k])) {
            item[k] = 'None';
          }
        }, item);
        return { ...item };
      }, R.clone(cloneInstanceMetricList));
      R.forEach((item) => {
        R.forEach((_item) => {
          if (item.name === _item?.metricLevelPrimaryKey?.metricName) {
            const componentNameSet = _item?.componentNameSet;
            item.ignoreList =
              String(componentNameSet || '').indexOf('Global_') >= 0
                ? R.map((__item) => __item.value, this.state.componentNameList || [])
                : R.sortBy(R.toLower)(componentNameSet || []);
          }
        }, this.state.ignoredList);
        R.forEach((_item) => {
          if (item.name === _item?.metricLevelPrimaryKey?.metricName) {
            const componentNameSet = _item?.componentNameSet;
            item.escalateIncident =
              String(componentNameSet || '').indexOf('Global_') >= 0
                ? R.map((__item) => __item.value, this.state.componentNameList || [])
                : R.sortBy(R.toLower)(componentNameSet || []);
          }
        }, this.state.escalateIncidentList);

        const stats = get(nextProps.metricStatsMap, item.smetric, []);
        item.stats = isFinite(stats[1]) ? Number(scientificNumbers(stats[1])) : -1;
      }, this.localMetrics);

      this.metricTypeList = R.map(
        (item) => ({ value: item, label: item }),
        nextProps.entry === 'batch' ? batchPossibleMetricType : possibleMetricType,
      );
      this.saveLocalMetrics = R.clone(this.localMetrics);
      changeState = true;
      this.forceUpdate();
      setTimeout(() => {
        this.cellMeasureCache.clearAll();
        if (this.table) this.table.forceUpdateGrid();
        this.setState({ isDeleting: false });
      });
    } else if (nextProps.excludedMetrics !== this.props.excludedMetrics) {
      this.getIgnoreList();
      this.getEscalateIncident();
      if (excludedMetrics && excludedMetrics.length > 0) {
        notification.info({
          message: null,
          description: (
            <div className="flex-row" style={{ maxHeight: 120, overflowY: 'auto' }}>
              {intl.formatMessage(settingsMessages.infoProjectSettingSavedWithExclusion, {
                excludedMetrics: R.join(
                  ', ',
                  R.uniq(
                    R.map(
                      (item) => `${item.metricName} (${this.instanceToApp[item.instanceName] || item.instanceName})`,
                      excludedMetrics,
                    ),
                  ),
                ),
              })}
            </div>
          ),
          duration: 0,
        });
      } else {
        message.success(intl.formatMessage(settingsMessages.infoProjectSettingSaved));
      }
    }

    // If metrics changes, reset the changed state, and get an new clone.
    if (changeState) {
      const isBatch = nextProps.entry === 'batch';
      const metricList = isBatch ? this.localBatchMetrics || [] : this.localMetrics || [];
      const isCheckedAll = metricList.length > 0 && !R.find((m) => !m.checked, metricList);
      const isKpiAll = metricList.length > 0 && !R.find((m) => !m.isKPI, metricList);
      const isAllComputeDifference = metricList.length > 0 && !R.find((m) => !m.computeDifference, metricList);
      const isAllNearConstantDetection =
        metricList.length > 0 && !R.find((m) => !m.enableBaselineNearConstance, metricList);
      const isFillZeroAll = metricList.length > 0 && !R.find((m) => !m.fillZero, metricList);

      // eslint-disable-next-line
      this.setState(
        { isCheckedAll, isKpiAll, isAllComputeDifference, isAllNearConstantDetection, isFillZeroAll },
        () => {
          if (this.table) {
            this.cellMeasureCache.clearAll();
            this.table.forceUpdateGrid();
          }
        },
      );
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        this.localMetrics = R.sortWith([R.ascend(R.prop(sortBy))])(this.localMetrics);
        // this.saveLocalMetrics = R.clone(this.localMetrics);
        if (sortDirection === SortDirection.DESC) {
          this.localMetrics = R.sortWith([R.descend(R.prop(sortBy))])(this.localMetrics);
          // this.saveLocalMetrics = R.clone(this.localMetrics);
        }
        if (this.table) {
          this.cellMeasureCache.clearAll();
          this.table.forceUpdateGrid();
          this.forceUpdate();
        }
      }
    }
  }

  @autobind
  getInstanceLevel(props) {
    const { currentProject, credentials, instanceDisplayNameMap } = props;
    const { projectShortName, owner } = currentProject || {};
    fetchGet(getEndpoint('projects/component'), {
      ...credentials,
      projectName: projectShortName,
      customerName: owner,
    })
      .then((data) => {
        const { success, message: msg } = data;
        if ((success || success === undefined) && isArray(data)) {
          const mergeComponetName = {};
          R.forEach((item) => {
            const { componentName } = item;
            if (!R.has(componentName, mergeComponetName)) {
              mergeComponetName[componentName] = [item];
            } else {
              mergeComponetName[componentName].push(item);
            }
          }, data || []);
          let instanceGroupList = [];
          R.forEachObjIndexed((val, idx) => {
            let children = R.sortWith([R.ascend(R.prop('instanceName'))], val || []);
            children = R.map((item) => {
              const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item.instanceName, {
                pn: currentProject?.projectShortName,
                owner: currentProject?.owner,
              });
              return { key: item.instanceName, title: instanceStr, value: item.instanceName };
            }, children);
            instanceGroupList.push({
              title: idx,
              value: `${idx}-Group`,
              key: `${idx}-Group`,
              children,
              checkable: true,
            });
          }, mergeComponetName);
          instanceGroupList = R.sortWith([R.ascend(R.prop('componentName'))], instanceGroupList);
          this.setState({ instanceGroupList });
        } else {
          console.error(msg);
        }
      })
      .catch((err) => console.error(String(err)));
  }

  @autobind
  handleNaNConvert(data) {
    const replaceStr = R.replace(/NaN/g, null, data || '{}');
    return JSON.parse(replaceStr, (key, value) => {
      if (value === null) return 'None';
      if (R.is(String, value) && value === 'null') return 'None';
      return value;
    });
  }

  @autobind
  setMetricInstance(metricInstanceLevelMap, data) {
    R.forEach((item) => {
      const { instanceName, metricSettingSet } = item;
      R.forEach((_item) => {
        const { smetric, rougeValue } = _item;
        const { upperBound: upperBoundAlert, lowerBound: lowerBoundAlert } = this.handleNaNConvert(
          _item.thresholdAlertBound,
        );
        const { upperBound: upperBoundNegative, lowerBound: lowerBoundNegative } = this.handleNaNConvert(
          _item.thresholdAlertBoundNegative,
        );
        const { upperBound: upperBoundNoAlert, lowerBound: lowerBoundNoAlert } = this.handleNaNConvert(
          _item.thresholdNoAlertBound,
        );
        const { upperBound: upperBoundNoAlertNegative, lowerBound: lowerBoundNoAlertNegative } = this.handleNaNConvert(
          _item.thresholdNoAlertBoundNegative,
        );
        // eslint-disable-next-line max-len
        const key = `${smetric}-${upperBoundAlert}-${lowerBoundAlert}-${upperBoundNegative}-${lowerBoundNegative}-${upperBoundNoAlert}-${lowerBoundNoAlert}`;
        if (!R.has(key, metricInstanceLevelMap)) {
          metricInstanceLevelMap[key] = {
            key,
            smetric,
            name: smetric,
            checked: false,
            isInstanceLevel: true,
            instanceLevelList: R.sortBy(R.toLower)([instanceName]),
            thresholdAlertUpperBound: upperBoundAlert,
            thresholdAlertLowerBound: lowerBoundAlert,
            thresholdAlertUpperBoundNegative: upperBoundNegative,
            thresholdAlertLowerBoundNegative: lowerBoundNegative,
            thresholdNoAlertLowerBoundNegative: lowerBoundNoAlertNegative,
            thresholdNoAlertUpperBoundNegative: upperBoundNoAlertNegative,
            thresholdNoAlertUpperBound: upperBoundNoAlert,
            thresholdNoAlertLowerBound: lowerBoundNoAlert,
            rougeValue,
          };
        } else {
          metricInstanceLevelMap[key].instanceLevelList = R.sortBy(R.toLower)([
            ...metricInstanceLevelMap[key].instanceLevelList,
            instanceName,
          ]);
        }
      }, metricSettingSet || []);
    }, data || []);
  }

  @autobind
  setLocalMetricInstance(metricInstanceLevelMap, cloneInstanceMetricList, metricList) {
    let metricInstanceLevelList = R.values(metricInstanceLevelMap);
    metricInstanceLevelList = R.filter((item) => R.includes(item.smetric, metricList), metricInstanceLevelList);
    metricInstanceLevelList = R.sortWith([R.ascend(R.prop('smetric'))], metricInstanceLevelList || []);
    R.forEach((item) => {
      const { smetric } = item;
      const rootFind = R.find(
        (_item) => _item.smetric === smetric && !_item.isInstanceLevel,
        cloneInstanceMetricList || [],
      );
      const findIdx = R.findIndex((_item) => _item.smetric === smetric, cloneInstanceMetricList || []);
      if (findIdx !== -1) {
        const newItem = { ...rootFind, ...item };
        cloneInstanceMetricList.splice(findIdx + 1, 0, newItem);
      }
    }, metricInstanceLevelList);
    this.localMetricInstanceLevelList = metricInstanceLevelList || [];
  }

  @autobind
  getInstancelevelmetricsetting(props, cloneInstanceMetricList) {
    const metricList = R.map((item) => item.smetric, cloneInstanceMetricList || []);
    const { currentProject, credentials } = props;
    const { projectShortName, owner } = currentProject || {};
    if (metricList.length === 0) return;
    if (metricList.length <= this.MAX_FATCH_NUM) {
      // eslint-disable-next-line consistent-return
      return fetchPost(getEndpoint('instancelevelmetricsetting'), {
        ...credentials,
        projectName: projectShortName,
        customerName: owner,
        metricList: JSON.stringify(metricList),
      })
        .then((data) => {
          const { success, message: msg } = data;
          if ((success || success === undefined) && isArray(data)) {
            const metricInstanceLevelMap = {};
            this.setMetricInstance(metricInstanceLevelMap, data);
            this.setLocalMetricInstance(metricInstanceLevelMap, cloneInstanceMetricList, metricList);
          } else {
            console.error(msg);
          }
        })
        .catch((err) => console.error(String(err)));
    } else {
      const splitEveryTakes = R.splitEvery(this.MAX_FATCH_NUM, metricList);
      const requests = [];
      R.forEach((groupM) => {
        if (groupM.length > 0) {
          requests.push(
            fetchPost(getEndpoint('instancelevelmetricsetting'), {
              ...credentials,
              projectName: projectShortName,
              customerName: owner,
              metricList: JSON.stringify(groupM),
            }),
          );
        }
      }, splitEveryTakes || []);
      Promise.all(requests)
        .then((d) => {
          const metricInstanceLevelMap = {};
          R.forEach((data) => {
            if (isArray(data)) {
              this.setMetricInstance(metricInstanceLevelMap, data);
            }
          }, d || []);
          this.setLocalMetricInstance(metricInstanceLevelMap, cloneInstanceMetricList, metricList);
        })
        .catch((err) => console.error(String(err)));
    }
  }

  @autobind
  getLoadProjectInfo(props) {
    const { projectName, projects, loadProjectInfo } = props;
    const project = R.find((p) => p.projectName === projectName, projects || []);
    if (project && project.hasAllInfo && !project.hasAllInstanceInfo) {
      loadProjectInfo(
        {
          projectName,
          includeInstance: true,
        },
        false,
        this.callbackHandle,
      );
    } else {
      this.getComponentNameList(props);
    }
  }

  @autobind
  callbackHandle() {}

  @autobind
  getComponentNameList(nextProps) {
    const { intl, credentials, projectName, projects } = nextProps;
    const currentProject = R.find((project) => project.projectName === projectName, projects) || {};
    const { isMetric, isContainer, instanceList: instanceContainerList } = currentProject;

    let instanceList = [];
    if (isMetric && isContainer) {
      R.forEach((item) => {
        if (item.includes('_')) {
          instanceList.push(item.split('_')[1]);
        } else {
          instanceList.push(item);
        }
      }, instanceContainerList || []);
    } else {
      instanceList = instanceContainerList || [];
    }
    instanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceList));
    if (instanceList.length > 0) {
      this.props.updateLastActionInfo();
      if (instanceList.length <= this.MAX_FATCH_NUM) {
        fetchPost(getEndpoint('groupingstorage'), {
          ...credentials,
          projectName,
          instanceGroup: 'All',
          instanceList: JSON.stringify(instanceList || []),
        })
          .then((d) => {
            const appResult = d.appResult || {};
            let uniqAppResult = R.uniq(R.values(appResult));
            let containerMetaDataComponents = [];
            R.forEachObjIndexed((cdatas, app) => {
              R.forEach((cdata) => {
                if (cdata.componentName) containerMetaDataComponents.push(cdata.componentName);
              }, cdatas || []);
            }, d?.containerMetaDataMap || {});
            containerMetaDataComponents = R.uniq(containerMetaDataComponents);
            uniqAppResult = R.uniq([...uniqAppResult, ...containerMetaDataComponents]);
            const sortAppResult = R.sortBy(R.toLower)(uniqAppResult);
            const componentNameList = R.map((item) => ({ label: item, value: item }), sortAppResult);
            this.setState({ componentNameList }, () => {
              this.refreshIgnoreAndEscalateIncident();
            });
          })
          .catch((err) => {
            message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
            this.setState({ componentNameList: [] }, () => {
              this.refreshIgnoreAndEscalateIncident();
            });
          });
      } else {
        const splitEveryTakes = R.splitEvery(this.MAX_FATCH_NUM, instanceList);
        const requests = [];
        R.forEach((groupI) => {
          if (groupI.length > 0) {
            requests.push(
              fetchPost(getEndpoint('groupingstorage'), {
                ...credentials,
                projectName,
                instanceGroup: 'All',
                instanceList: JSON.stringify(groupI || []),
              }),
            );
          }
        }, splitEveryTakes || []);
        Promise.all(requests)
          .then((data) => {
            let appResults = [];
            let containerMetaDataComponents = [];
            R.forEach((d) => {
              const appResult = d.appResult || {};
              appResults = [...appResults, ...R.values(appResult)];
              R.forEachObjIndexed((cdatas, app) => {
                R.forEach((cdata) => {
                  if (cdata.componentName) containerMetaDataComponents.push(cdata.componentName);
                }, cdatas || []);
              }, d?.containerMetaDataMap || {});
            }, data || []);
            let uniqAppResult = R.uniq(appResults);
            containerMetaDataComponents = R.uniq(containerMetaDataComponents);
            uniqAppResult = R.uniq([...uniqAppResult, ...containerMetaDataComponents]);
            const sortAppResult = R.sortBy(R.toLower)(uniqAppResult);
            const componentNameList = R.map((item) => ({ label: item, value: item }), sortAppResult);
            this.setState({ componentNameList }, () => {
              this.refreshIgnoreAndEscalateIncident();
            });
          })
          .catch((err) => {
            message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
            this.setState({ componentNameList: [] }, () => {
              this.refreshIgnoreAndEscalateIncident();
            });
          });
      }
    } else {
      this.setState({ componentNameList: [] }, () => {
        this.refreshIgnoreAndEscalateIncident();
      });
    }
  }

  @autobind
  async refreshIgnoreAndEscalateIncident() {
    this.setState({ sortBy: null, sortDirection: null });
    const { instanceMetricList } = this.props;
    let cloneInstanceMetricList = R.clone(instanceMetricList);
    await this.getInstancelevelmetricsetting(this.props, cloneInstanceMetricList);
    cloneInstanceMetricList = R.map((item) => {
      return {
        ...item,
        ignoreList: [],
        escalateIncident: [],
        thresholdAlertLowerBoundViewVal: this.handleInputBlue({ value: item.thresholdAlertLowerBound }),
        thresholdAlertUpperBoundViewVal: this.handleInputBlue({ value: item.thresholdAlertUpperBound }),
        thresholdNoAlertLowerBoundViewVal: this.handleInputBlue({ value: item.thresholdNoAlertLowerBound }),
        thresholdNoAlertUpperBoundViewVal: this.handleInputBlue({ value: item.thresholdNoAlertUpperBound }),
        thresholdAlertLowerBoundNegativeViewVal: this.handleInputBlue({ value: item.thresholdAlertLowerBoundNegative }),
        thresholdAlertUpperBoundNegativeViewVal: this.handleInputBlue({ value: item.thresholdAlertUpperBoundNegative }),
        thresholdNoAlertLowerBoundNegativeViewVal: this.handleInputBlue({
          value: item.thresholdNoAlertLowerBoundNegative,
        }),
        thresholdNoAlertUpperBoundNegativeViewVal: this.handleInputBlue({
          value: item.thresholdNoAlertUpperBoundNegative,
        }),
        rougeValue: {
          l: this.handleInputBlue({ value: item.rougeValue?.l }),
          s: this.handleInputBlue({ value: item.rougeValue?.s }),
        },
      };
    }, cloneInstanceMetricList);

    this.localMetrics = R.map((item) => {
      R.forEachObjIndexed((c, k) => {
        if (item[k] === 'NaN' || Number.isNaN(item[k]) || R.isNil(item[k])) {
          item[k] = 'None';
        }
      }, item);
      return { ...item };
    }, R.clone(cloneInstanceMetricList));

    R.forEach((item) => {
      R.forEach((_item) => {
        if (item.name === _item?.metricLevelPrimaryKey?.metricName) {
          const componentNameSet = _item?.componentNameSet;
          item.ignoreList =
            String(componentNameSet || '').indexOf('Global_') >= 0
              ? R.map((__item) => __item.value, this.state.componentNameList || [])
              : R.sortBy(R.toLower)(componentNameSet || []);
        }
      }, this.state.ignoredList);
      R.forEach((_item) => {
        if (item.name === _item?.metricLevelPrimaryKey?.metricName) {
          const componentNameSet = _item?.componentNameSet;
          item.escalateIncident =
            String(componentNameSet || '').indexOf('Global_') >= 0
              ? R.map((__item) => __item.value, this.state.componentNameList || [])
              : R.sortBy(R.toLower)(componentNameSet || []);
        }
      }, this.state.escalateIncidentList);

      const stats = get(this.props.metricStatsMap, item.smetric, []);
      item.stats = isFinite(stats[1]) ? Number(scientificNumbers(stats[1])) : -1;
    }, this.localMetrics);

    this.saveLocalMetrics = R.clone(this.localMetrics);

    const changeState = true;
    if (changeState) {
      const isCheckedAll = this.localMetrics.length > 0 && !R.find((m) => !m.checked, this.localMetrics);
      const isKpiAll = this.localMetrics.length > 0 && !R.find((m) => !m.isKPI, this.localMetrics);
      const isAllComputeDifference =
        this.localMetrics.length > 0 && !R.find((m) => !m.computeDifference, this.localMetrics);
      const isAllNearConstantDetection =
        this.localMetrics.length > 0 && !R.find((m) => !m.enableBaselineNearConstance, this.localMetrics);
      const isFillZeroAll = this.localMetrics.length > 0 && !R.find((m) => !m.fillZero, this.localMetrics);

      // eslint-disable-next-line
      this.setState({ isCheckedAll, isKpiAll, isAllComputeDifference, isAllNearConstantDetection, isFillZeroAll });
    }
    if (this.table) {
      this.cellMeasureCache.clearAll();
      this.table.forceUpdateGrid();
      this.forceUpdate();
    }
  }

  @autobind
  getIgnoreList() {
    const { intl, credentials, projectName, currentProject } = this.props;
    const owner = get(currentProject, 'owner', '');
    fetchGet(getEndpoint('metriccomponent'), {
      ...credentials,
      projectName,
      customerName: owner,
      operation: 'ignored',
    })
      .then(async (data) => {
        const { componentIgnored } = data;
        const ignoredList = JSON.parse(componentIgnored || null);
        this.setState({ ignoredList });
      })
      .catch((err) => {
        this.setState({ ignoredList: [] });
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  getEscalateIncident() {
    const { intl, credentials, projectName, currentProject } = this.props;
    const owner = get(currentProject, 'owner', '');
    fetchGet(getEndpoint('metriccomponent'), {
      ...credentials,
      projectName,
      customerName: owner,
      operation: 'escalateIncident',
    })
      .then(async (data) => {
        const { componentEscalateIncident } = data;
        const escalateIncidentList = JSON.parse(componentEscalateIncident || null);
        this.setState({ escalateIncidentList });
      })
      .catch((err) => {
        this.setState({ escalateIncidentList: [] });
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  reloadData(props) {
    const { loadProjectMetricSettings, currentProject } = props;
    const { owner } = currentProject;
    const { projectName } = props;
    const params = parseLocation(props.location);
    const { instanceId, metricFilter } = params;
    const { page, pageSize } = this.state;
    const { statsStartTimeObj, statsEndTimeObj, onlyFilter } = this.state;
    const timeRangeStart = statsStartTimeObj.startOf('day').valueOf();
    const timeRangeEnd = statsEndTimeObj.endOf('day').valueOf();

    if (projectName) {
      loadProjectMetricSettings(
        {
          viewCategory: 'metricSettings',
          projectName,
          owner,
          instanceId,
          start: (page - 1) * pageSize,
          limit: pageSize,
          metricFilter,
          onlyFilter,
          // staticts
          timeRangeStart,
          timeRangeEnd,
        },
        { [this.instanceLoader]: true },
      );
    }
  }

  @autobind
  parseData(props) {
    const instanceList = get(props, ['data', 'instanceList'], []);
    this.instanceToApp = get(props, ['data', 'instanceToApp'], {});
    const appList = R.sort(
      AscendingComparer,
      R.uniq(R.map((i) => this.instanceToApp[i.instanceId] || i.instanceId, instanceList)),
    );
    this.instanceListOption = R.map((i) => ({ label: i, value: i }), appList);
  }

  @autobind
  handleGroupingUpdateClick() {
    this.setState({ showGroupingUpdateModal: true });
  }

  @autobind
  async handleGroupingUpdateSave({
    thresholdAlertLowerBound,
    thresholdAlertUpperBound,
    thresholdNoAlertLowerBound,
    thresholdNoAlertUpperBound,
    thresholdAlertLowerBoundNegative,
    thresholdAlertUpperBoundNegative,
    thresholdNoAlertLowerBoundNegative,
    thresholdNoAlertUpperBoundNegative,
    // specialInterval,
    detectionType,
    isKPI,
    escalateIncident,
    kpiDurationThreshold,
    patternNameHigher,
    patternNameLower,
    computeDifference,
    enableBaselineNearConstance,
    fillZero,
    metricType,
    ignoreList,
    rougeValueL,
    rougeValueS,
    anomalyDampening,
  }) {
    const { projectName, currentProject } = this.props;
    const { saveProjectMetricSettings } = this.props;
    const { owner } = currentProject;
    const { location } = this.props;
    const params = parseLocation(location);
    const { instanceId, metricFilter, onlyIsKpi, onlyComputeDifference, onlyIsNoneKpi, onlyThreshold } = params;
    const { page, pageSize, statsStartTimeObj, statsEndTimeObj, patternIdGenerationRule } = this.state;
    const timeRangeStart = statsStartTimeObj.startOf('day').valueOf();
    const timeRangeEnd = statsEndTimeObj.endOf('day').valueOf();

    const diffGroupingUpdate = R.filter((item) => item?.checked, this.localMetrics);

    if (!isUndefined(ignoreList)) {
      await this.setIgnoreList({ diffGroupingUpdate, groupIgnoreList: ignoreList, type: 'ignored' });
    }

    if (!isUndefined(escalateIncident)) {
      await this.setIgnoreList({
        diffGroupingUpdate,
        groupEscalateIncident: escalateIncident,
        type: 'escalateIncident',
      });
    }

    this.setState({ showGroupingUpdateModal: false });

    const toNaN = (str) => {
      return Number(str).toString() === 'NaN' || str === 'None' ? 'NaN' : str;
    };

    const metricList = R.map(
      (metric) => ({
        smetric: metric.smetric,
        thresholdAlertLowerBound: toNaN(thresholdAlertLowerBound || metric.thresholdAlertLowerBound),
        thresholdAlertUpperBound: toNaN(thresholdAlertUpperBound || metric.thresholdAlertUpperBound),
        thresholdNoAlertLowerBound: toNaN(thresholdNoAlertLowerBound || metric.thresholdNoAlertLowerBound),
        thresholdNoAlertUpperBound: toNaN(thresholdNoAlertUpperBound || metric.thresholdNoAlertUpperBound),
        thresholdAlertLowerBoundNegative: toNaN(
          thresholdAlertLowerBoundNegative || metric.thresholdAlertLowerBoundNegative,
        ),
        thresholdAlertUpperBoundNegative: toNaN(
          thresholdAlertUpperBoundNegative || metric.thresholdAlertUpperBoundNegative,
        ),
        thresholdNoAlertLowerBoundNegative: toNaN(
          thresholdNoAlertLowerBoundNegative || metric.thresholdNoAlertLowerBoundNegative,
        ),
        thresholdNoAlertUpperBoundNegative: toNaN(
          thresholdNoAlertUpperBoundNegative || metric.thresholdNoAlertUpperBoundNegative,
        ),
        // specialInterval: specialInterval || metric.specialInterval,
        detectionType: detectionType || metric.detectionType,
        isKPI: !isUndefined(isKPI) ? isKPI : metric.isKPI,
        kpiDurationThreshold: round((kpiDurationThreshold || metric.kpiDurationThreshold) * 60000),
        patternNameHigher: patternNameHigher || metric.patternNameHigher,
        patternNameLower: patternNameLower || metric.patternNameLower,
        computeDifference: !isUndefined(computeDifference) ? computeDifference : metric.computeDifference,
        enableBaselineNearConstance: !isUndefined(enableBaselineNearConstance)
          ? enableBaselineNearConstance
          : metric.enableBaselineNearConstance,
        modified: metric.modified,
        groupId: metric.groupId,
        capacitySetting: metric.capacitySetting,
        fillZero: !isUndefined(fillZero) ? fillZero : metric.fillZero,
        metricType: metricType || metric.metricType,
        rougeValue: { l: toNaN(rougeValueL || metric.l), s: toNaN(rougeValueS || metric.s) },
        anomalyDampening: round((anomalyDampening || metric.anomalyDampening) * 60000),
      }),
      diffGroupingUpdate,
    );
    saveProjectMetricSettings(
      {
        viewCategory: 'metricSettings',
        projectName,
        owner,
        instanceId,
        metricList,
        start: (page - 1) * pageSize,
        limit: pageSize,
        metricFilter,
        onlyFilter: {
          onlyIsKpi,
          onlyComputeDifference,
          onlyIsNoneKpi,
          onlyThreshold,
        },
        timeRangeStart,
        timeRangeEnd,
        patternIdGenerationRule,
      },
      { [this.submitGroupUpdateLoadingKey]: true },
    );
  }

  @autobind
  setIgnoreList({ diffGroupingUpdate, groupIgnoreList, groupEscalateIncident, type }) {
    const { intl, credentials, projectName, currentProject } = this.props;
    const owner = get(currentProject, 'owner', '');
    const { ignoredList, escalateIncidentList, componentNameList } = this.state;

    const requests = [];
    R.forEach((ignoreItem) => {
      let { ignoreList = [], escalateIncident = [] } = ignoreItem;
      if (groupIgnoreList) ignoreList = groupIgnoreList;
      if (groupEscalateIncident) escalateIncident = groupEscalateIncident;
      let addIgnoredList;
      let removeIgnoredList;
      let selectAll = false;
      if (type === 'ignored') {
        const filterIgnoredItem = R.find(
          (item) => item?.metricLevelPrimaryKey?.metricName === ignoreItem.name,
          ignoredList || [],
        );
        let { componentNameSet = [] } = filterIgnoredItem || {};
        const globalContent = R.filter((item) => R.includes('Global_', item), componentNameSet);
        const isGlobal = globalContent.length > 0;
        if (isGlobal) componentNameSet = R.map((__item) => __item.value, componentNameList || []);
        if (isEqual(R.sortBy(R.toLower)(componentNameSet), R.sortBy(R.toLower)(ignoreList))) return;
        addIgnoredList = R.difference(ignoreList, componentNameSet);
        removeIgnoredList = R.difference(componentNameSet, ignoreList);
        selectAll = ignoreList.length === componentNameList.length;
        if (isGlobal && !selectAll) {
          addIgnoredList = ignoreList;
          removeIgnoredList = globalContent;
        }
      } else if (type === 'escalateIncident') {
        const escalateIncidentItem = R.find(
          (item) => item?.metricLevelPrimaryKey?.metricName === ignoreItem.name,
          escalateIncidentList || [],
        );
        let { componentNameSet = [] } = escalateIncidentItem || {};
        const globalContent = R.filter((item) => R.includes('Global_', item), componentNameSet);
        const isGlobal = globalContent.length > 0;
        if (isGlobal) componentNameSet = R.map((__item) => __item.value, componentNameList || []);
        if (isEqual(R.sortBy(R.toLower)(componentNameSet), R.sortBy(R.toLower)(escalateIncident))) return;
        addIgnoredList = R.difference(escalateIncident, componentNameSet);
        removeIgnoredList = R.difference(componentNameSet, escalateIncident);
        selectAll = escalateIncident.length === componentNameList.length;
        if (isGlobal && !selectAll) {
          addIgnoredList = escalateIncident;
          removeIgnoredList = globalContent;
        }
      }

      requests.push(
        fetchPost(getEndpoint('metriccomponent'), {
          ...credentials,
          projectName,
          customerName: owner,
          metricName: ignoreItem?.smetric || ignoreItem?.name,
          ...(selectAll
            ? {}
            : {
                addComponentSet: JSON.stringify(addIgnoredList || []),
                removeComponentSet: JSON.stringify(removeIgnoredList || []),
              }),
          operation: type,
          selectAll,
        }),
      );
    }, diffGroupingUpdate || []);

    return Promise.all(requests)
      .then((data) => {})
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  submitInstanceLevel(diff, type) {
    const toNaN = (str) => {
      return Number(str).toString() === 'NaN' || str === 'None' ? 'NaN' : str;
    };
    const { credentials, currentProject, intl } = this.props;
    const { projectShortName, owner } = currentProject || {};
    let instanceLevelSettings = [];
    R.forEach((item) => {
      const { smetric, thresholdAlertLowerBound, thresholdAlertUpperBound, thresholdNoAlertLowerBound } = item;
      const { thresholdNoAlertUpperBound, thresholdAlertLowerBoundNegative, thresholdAlertUpperBoundNegative } = item;
      const { thresholdNoAlertLowerBoundNegative, thresholdNoAlertUpperBoundNegative } = item;
      const { instanceLevelList, rougeValue, patternNameHigher, patternNameLower } = item;
      const projectSetting = {
        smetric,
        thresholdAlertLowerBound: toNaN(thresholdAlertLowerBound),
        thresholdAlertUpperBound: toNaN(thresholdAlertUpperBound),
        thresholdNoAlertLowerBound: toNaN(thresholdNoAlertLowerBound),
        thresholdNoAlertUpperBound: toNaN(thresholdNoAlertUpperBound),
        thresholdAlertLowerBoundNegative: toNaN(thresholdAlertLowerBoundNegative),
        thresholdAlertUpperBoundNegative: toNaN(thresholdAlertUpperBoundNegative),
        thresholdNoAlertLowerBoundNegative: toNaN(thresholdNoAlertLowerBoundNegative),
        thresholdNoAlertUpperBoundNegative: toNaN(thresholdNoAlertUpperBoundNegative),
        rougeValue: { l: toNaN(rougeValue?.l), s: toNaN(rougeValue?.s) },
        patternNameHigher,
        patternNameLower,
      };
      instanceLevelSettings = [...instanceLevelSettings, { projectSetting, instanceList: instanceLevelList }];
    }, diff || []);

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('instancelevelmetricsetting'), {
      ...credentials,
      projectName: projectShortName,
      customerName: owner,
      metricSettingsStr: JSON.stringify(instanceLevelSettings),
      operation: type,
    })
      .then((data) => {})
      .catch((err) => {
        console.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  handleSubmitInstanceLevel(instanceLevelMetrics) {
    const cmp = (a, b) => {
      return (
        a.key === b.key &&
        a.smetric === b.smetric &&
        isEqual(a.instanceLevelList, b.instanceLevelList) &&
        String(a.thresholdAlertLowerBound) === String(b.thresholdAlertLowerBound) &&
        String(a.thresholdAlertUpperBound) === String(b.thresholdAlertUpperBound) &&
        String(a.thresholdAlertLowerBoundNegative) === String(b.thresholdAlertLowerBoundNegative) &&
        String(a.thresholdAlertUpperBoundNegative) === String(b.thresholdAlertUpperBoundNegative) &&
        String(a.thresholdNoAlertLowerBoundNegative) === String(b.thresholdNoAlertLowerBoundNegative) &&
        String(a.thresholdNoAlertUpperBoundNegative) === String(b.thresholdNoAlertUpperBoundNegative) &&
        String(a.thresholdNoAlertLowerBound) === String(b.thresholdNoAlertLowerBound) &&
        String(a.thresholdNoAlertUpperBound) === String(b.thresholdNoAlertUpperBound) &&
        String(a.rougeValue?.l) === String(b.rougeValue?.l) &&
        String(a.rougeValue?.s) === String(b.rougeValue?.s)
      );
    };
    const removeCmp = (a, b) => a.key === b.key;
    const diff = R.differenceWith(cmp, instanceLevelMetrics, this.localMetricInstanceLevelList);
    let removeDiff = R.differenceWith(removeCmp, this.localMetricInstanceLevelList, instanceLevelMetrics);

    let removeInstanceLeveItems = [];
    if (diff.length > 0) {
      R.forEach((item) => {
        const { key, instanceLevelList } = item;
        const findLocal = R.find((_item) => _item.key === key, this.localMetricInstanceLevelList);
        const removeItem = [];
        R.forEach((_item) => {
          if (!R.includes(_item, instanceLevelList || [])) {
            removeItem.push(_item);
          }
        }, findLocal?.instanceLevelList || []);
        if (removeItem.length > 0) {
          removeInstanceLeveItems = [...removeInstanceLeveItems, { ...item, instanceLevelList: removeItem }];
        }
      }, diff || []);
    }

    removeDiff = [...removeDiff, ...removeInstanceLeveItems];
    if (removeDiff.length > 0) {
      let diffInstanceLevelList = [];
      R.forEach((item) => {
        diffInstanceLevelList = [...diffInstanceLevelList, ...item.instanceLevelList];
      }, diff || []);
      diffInstanceLevelList = R.uniq(diffInstanceLevelList);

      const removeList = [];
      R.forEach((item) => {
        const flagInstanceLevelList = R.filter(
          (_item) => !R.includes(_item, diffInstanceLevelList),
          item.instanceLevelList || [],
        );
        if (flagInstanceLevelList.length > 0) {
          item.instanceLevelList = flagInstanceLevelList;
          removeList.push(item);
        }
      }, removeDiff || []);
      if (removeList.length > 0) {
        this.submitInstanceLevel(removeList, 'delete');
      }
    }

    if (diff.length > 0) {
      this.submitInstanceLevel(diff, 'update');
    }
  }

  @autobind
  async handleSaveClick() {
    const { projectName, instanceMetricList, currentProject, entry, handleMetricList } = this.props;
    const { saveProjectMetricSettings } = this.props;
    const { owner } = currentProject;
    const { location } = this.props;
    const params = parseLocation(location);
    const { instanceId, metricFilter, onlyIsKpi, onlyComputeDifference, onlyIsNoneKpi, onlyThreshold } = params;
    const { page, pageSize, statsStartTimeObj, statsEndTimeObj, patternIdGenerationRule } = this.state;
    const timeRangeStart = statsStartTimeObj.startOf('day').valueOf();
    const timeRangeEnd = statsEndTimeObj.endOf('day').valueOf();

    const isBatch = R.equals(entry, 'batch');

    if (isBatch) {
      handleMetricList(this.localBatchMetrics);
      return;
    }

    const newLocalMetrics = R.filter((item) => !item.isInstanceLevel, this.localMetrics);
    const instanceLevelMetrics = R.filter((item) => item.isInstanceLevel, this.localMetrics);
    await this.handleSubmitInstanceLevel(instanceLevelMetrics);

    // TODO: save instance level metric

    const diff = R.differenceWith(this.cmp, newLocalMetrics, instanceMetricList);

    const ignoreListFilter = R.filter((item) => item.ignoreList, diff);
    const escalateIncidentFilter = R.filter((item) => item.escalateIncident, diff);
    await this.setIgnoreList({ diffGroupingUpdate: ignoreListFilter, type: 'ignored' });
    await this.setIgnoreList({ diffGroupingUpdate: escalateIncidentFilter, type: 'escalateIncident' });

    const toNaN = (str) => {
      return Number(str).toString() === 'NaN' || str === 'None' ? 'NaN' : str;
    };
    const metricList = R.map(
      (metric) => ({
        smetric: metric.smetric,
        thresholdAlertLowerBound: toNaN(metric.thresholdAlertLowerBound),
        thresholdAlertUpperBound: toNaN(metric.thresholdAlertUpperBound),
        thresholdNoAlertLowerBound: toNaN(metric.thresholdNoAlertLowerBound),
        thresholdNoAlertUpperBound: toNaN(metric.thresholdNoAlertUpperBound),
        thresholdAlertLowerBoundNegative: toNaN(metric.thresholdAlertLowerBoundNegative),
        thresholdAlertUpperBoundNegative: toNaN(metric.thresholdAlertUpperBoundNegative),
        thresholdNoAlertLowerBoundNegative: toNaN(metric.thresholdNoAlertLowerBoundNegative),
        thresholdNoAlertUpperBoundNegative: toNaN(metric.thresholdNoAlertUpperBoundNegative),
        // specialInterval: metric.specialInterval,
        detectionType: metric.detectionType,
        isKPI: metric.isKPI,
        kpiDurationThreshold: round(metric.kpiDurationThreshold * 60000),
        patternNameHigher: metric.patternNameHigher,
        patternNameLower: metric.patternNameLower,
        computeDifference: metric.computeDifference,
        enableBaselineNearConstance: metric.enableBaselineNearConstance,
        modified: metric.modified,
        groupId: metric.groupId,
        capacitySetting: metric.capacitySetting,
        metricType: metric.metricType,
        fillZero: metric.fillZero,
        rougeValue: { l: toNaN(metric.rougeValue.l), s: toNaN(metric.rougeValue.s) },
        anomalyDampening: round(metric.anomalyDampening * 60000),
      }),
      diff,
    );

    saveProjectMetricSettings(
      {
        viewCategory: 'metricSettings',
        projectName,
        owner,
        instanceId,
        metricList,
        start: (page - 1) * pageSize,
        limit: pageSize,
        metricFilter,
        onlyFilter: {
          onlyIsKpi,
          onlyComputeDifference,
          onlyIsNoneKpi,
          onlyThreshold,
        },
        timeRangeStart,
        timeRangeEnd,
        patternIdGenerationRule,
      },
      { [this.submitLoadingKey]: true },
    );
  }

  @autobind
  handleInputChanged(rowData, dataKey) {
    return (e) => {
      const { target } = e;
      // const newVal = target.type === 'checkbox' ? target.checked : target.value || '';

      let newVal;
      if (target.type === 'checkbox') {
        newVal = target.checked;
        if (dataKey === 'isKPI' && target.checked === false) {
          rowData.escalateIncident = [];
        }
      } else {
        newVal = target.value || '';
        rowData[`${dataKey}ViewVal`] = newVal;
      }

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  forceUpdateFn() {
    this.table.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleInputBlue({ value }) {
    let viewVal;
    if (!Number.isFinite(value * 1) || value === '' || Number.isFinite(value * 1) === 0) {
      viewVal = value;
    } else if (R.includes('e', value) || R.includes('E', value)) {
      if (R.includes('.', value)) {
        viewVal =
          value.match(/\.(\S*)(e|E)/)[1].length > 6
            ? R.replace('e', 'E', Number.parseFloat(value).toExponential(6))
            : R.replace('e', 'E', Number.parseFloat(value).toExponential());
      } else {
        viewVal =
          value.match(/(\S*)(e|E)/)[1].length > 6
            ? R.replace('e', 'E', Number.parseFloat(value).toExponential(6))
            : R.replace('e', 'E', Number.parseFloat(value).toExponential());
      }
    } else if (value > 999999) {
      viewVal = R.replace('e', 'E', Number.parseFloat(value).toExponential(6));
    } else {
      viewVal = value;
    }
    return viewVal;
  }

  @autobind
  handleInputNumberChanged(rowData, dataKey) {
    return (newVal) => {
      const { entry, setHasNotInteger } = this.props;
      const { samplingInterval } = this.state;
      // Save the data and force update.
      rowData[dataKey] = newVal || 0;

      // if (entry === 'batch' && dataKey === 'specialInterval') {
      //   const hasNotInteger = !isInteger((newVal || 0) / samplingInterval);
      //   setHasNotInteger(hasNotInteger);
      // }

      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleIsAllChecked(fieldName) {
    const { entry } = this.props;
    const metrics = R.equals(entry, 'batch') ? this.localBatchMetrics : this.localMetrics;
    return (e) => {
      const { checked } = e.target;
      R.forEach((m) => {
        const { isInstanceLevel } = m;
        if (fieldName === 'isCheckedAll' && !isInstanceLevel) {
          m.checked = checked;
        } else if (fieldName === 'isKpiAll' && !isInstanceLevel) {
          m.isKPI = checked;
          if (checked === false) {
            m.escalateIncident = [];
          }
        } else if (fieldName === 'isAllComputeDifference' && !isInstanceLevel) {
          m.computeDifference = checked;
        } else if (fieldName === 'isAllNearConstantDetection' && !isInstanceLevel) {
          m.enableBaselineNearConstance = checked;
        } else if (fieldName === 'isFillZeroAll' && !isInstanceLevel) {
          m.fillZero = checked;
          this.setState({ isFillZeroAll: checked });
        }
      }, metrics);
      this.setState({ [fieldName]: checked });
    };
  }

  @autobind
  sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className="flex-row flex-center-align">
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  checkAllHeaderRender(fieldName) {
    return () => {
      const { intl } = this.props;
      return (
        <div className="flex-col flex-center-align">
          {fieldName === 'isKpiAll' && (
            <div className="flex-row flex-center-align" style={{ paddingTop: 15 }}>
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.KPI)}</div>
              <Popover content={intl.formatMessage(settingsMessages.KPIMessage)} mouseEnterDelay={0.3} placement="left">
                <QuestionCircleOutlined />
              </Popover>
            </div>
          )}
          {fieldName === 'isAllComputeDifference' && (
            <div className="flex-row flex-center-align" style={{ paddingTop: 15 }}>
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.computeDifference)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.computeDifferenceMessage)}
                mouseEnterDelay={0.3}
                placement="left"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>
          )}
          {fieldName === 'isAllNearConstantDetection' && (
            <div className="flex-row flex-center-align" style={{ paddingTop: 0 }}>
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.nearConstantDetection)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.nearConstantDetectionMessage)}
                mouseEnterDelay={0.3}
                placement="left"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>
          )}
          {fieldName === 'isFillZeroAll' && (
            <div className="flex-row flex-center-align" style={{ paddingTop: 15 }}>
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.enableMissingDataFilling)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.enableMissingDataFillingMessage)}
                mouseEnterDelay={0.3}
                placement="left"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>
          )}
          <input
            className="fui input"
            type="checkbox"
            style={{ minWidth: 14 }}
            checked={this.state[fieldName]}
            onChange={this.handleIsAllChecked(fieldName)}
          />
        </div>
      );
    };
  }

  @autobind
  handleSelectionTypeChange(rowData, dataKey) {
    return (e) => {
      const newVal = e ? e.value : '';

      // Save the data and force update.
      rowData[dataKey] = newVal ?? e;

      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  renderMetricType({ rowData, dataKey, cellData }) {
    const { possibleMetricType, batchPossibleMetricType, intl, entry } = this.props;
    return (
      <AntdSelect
        showSearch
        style={{ width: '100%' }}
        allowClear
        size="small"
        options={R.map(
          (item) => ({ label: item, value: item }),
          entry === 'batch' ? batchPossibleMetricType : possibleMetricType,
        )}
        value={cellData || ''}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 150 }}
        onChange={this.handleSelectionTypeChange(rowData, dataKey)}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        optionLabelProp="label"
        disabled={rowData.isInstanceLevel}
        dropdownRender={(menu) => (
          <>
            {menu}
            {entry !== 'batch' && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px', width: '100%', justifyContent: 'center' }}>
                  <Button
                    type="primary"
                    size="small"
                    className="flex-row flex-center-align"
                    onClick={() => this.setState({ showEditMetricTypeModel: true })}
                  >
                    {intl.formatMessage(settingsMessages.editMetricType)}
                  </Button>
                </Space>
              </>
            )}
          </>
        )}
      />
    );
  }

  @autobind
  renderIgnore({ rowData, dataKey, cellData }) {
    const { componentNameList } = this.state;
    const allValues = R.map((x) => x.value, componentNameList);
    return (
      <AntdSelect
        size="small"
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        maxTagCount={1}
        className="ignore-tabs"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 250 }}
        value={(cellData === true ? [] : cellData) || []}
        disabled={rowData.isInstanceLevel}
        dropdownRender={(menu) => (
          <>
            <div style={{ padding: '4px 12px 0px', display: 'flex' }}>
              <Checkbox
                indeterminate={(cellData || []).length !== 0 && (cellData || []).length !== componentNameList.length}
                checked={(cellData || []).length === componentNameList.length}
                onChange={(e) => {
                  rowData[dataKey] = e.target.checked ? allValues : [];
                  if (dataKey === 'escalateIncident' && rowData[dataKey].length > 0) {
                    rowData.isKPI = true;
                  }
                  this.table.forceUpdateGrid();
                  this.forceUpdate();
                }}
              />
              <div className="bold" style={{ paddingLeft: '4px' }}>{`Selected (${(cellData || []).length})`}</div>
            </div>
            <Divider style={{ margin: '4px 0' }} />
            {menu}
          </>
        )}
        onChange={(value) => {
          rowData[dataKey] = value;
          rowData[dataKey] = R.sortBy(R.toLower)(rowData[dataKey]);
          if (dataKey === 'escalateIncident' && rowData[dataKey].length > 0) {
            rowData.isKPI = true;
          }
          this.table.forceUpdateGrid();
          this.forceUpdate();
        }}
        options={componentNameList}
      />
    );
  }

  @autobind
  renderInput({ rowData, dataKey, cellData }) {
    return <AntdInput size="small" value={cellData || ''} onChange={this.handleInputChanged(rowData, dataKey)} />;
  }

  @autobind
  renderInputNumber(props) {
    return ({ rowData, dataKey, cellData }) => {
      const { samplingInterval } = this.state;
      // const isAllInteger = dataKey !== 'specialInterval' || isInteger(cellData / samplingInterval);
      return (
        <InputNumber
          {...(props || {})}
          size="small"
          style={{ width: '100%' }}
          controls={false}
          value={cellData}
          disabled={rowData.isInstanceLevel}
          onChange={this.handleInputNumberChanged(rowData, dataKey)}
          min={0}
          precision={0}
          // className={`${isAllInteger ? '' : 'inputIsNil'}`}
          // onBlur={() => {
          //   if (!isAllInteger) {
          //     message.error(`The Number of Samples must be an integer multiple of ${samplingInterval} minutes`);
          //   }
          // }}
        />
      );
    };
  }

  @autobind
  metricPriorityRender({ dataKey, rowData, cellData }) {
    return (
      <Select
        withPortal
        autosize
        clearable
        options={this.metricPriorityOptions}
        value={cellData || ''}
        onChange={this.handleSelectionChange(rowData, dataKey)}
      />
    );
  }

  @autobind
  handleSelectionChange(rowData, dataKey) {
    return (e) => {
      const newVal = e ? e.value : 0;

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  renderMetricName({ rowData }) {
    const { smetric, unit, isInstanceLevel } = rowData;

    return (
      <>
        {!isInstanceLevel && (
          <Popover
            title={null}
            content={
              <div style={{ maxWidth: 250, wordBreak: 'break-all' }}>{unit ? `${smetric} (${unit})` : smetric}</div>
            }
            placement="right"
            mouseEnterDelay={0.3}
          >
            <span className="hidden-line-with-ellipsis max-width inline-block">
              {unit ? `${smetric} (${unit})` : smetric}
            </span>
          </Popover>
        )}
        {isInstanceLevel && <div />}
      </>
    );
  }

  @autobind
  renderPatternName({ rowData }) {
    const { patternNameHigher, patternNameLower } = rowData;
    return (
      <div style={{ paddingRight: 8 }}>
        <div className="flex-row flex-center-align">
          <div style={{ width: 60, flexShrink: 0 }}>Positive:</div>
          <AntdInput
            size="small"
            value={patternNameHigher}
            onChange={(e) => {
              rowData.patternNameHigher = e.target.value;
              this.forceUpdateFn();
            }}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 4 }}>
          <div style={{ width: 60, flexShrink: 0 }}>Negative:</div>
          <AntdInput
            size="small"
            value={patternNameLower}
            onChange={(e) => {
              rowData.patternNameLower = e.target.value;
              this.forceUpdateFn();
            }}
          />
        </div>
      </div>
    );
  }

  @autobind
  renderStatistics({ dataKey, parent, rowIndex, rowData, style }) {
    const { intl, metricStatsMap, entry } = this.props;
    const { smetric } = rowData;
    const stats = get(metricStatsMap, smetric, []);

    if (entry === 'batch') {
      return (
        <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
          <RenderMetricStats style={style} intl={intl} stats={stats} />
        </CellMeasurer>
      );
    }
    return <RenderMetricStats style={style} intl={intl} stats={stats} />;
  }

  @autobind
  getAlertThresholdsData(rowData, field1, field2) {
    const value1 = get(rowData, `${field1}ViewVal`, '');
    const value2 = get(rowData, `${field2}ViewVal`, '');
    let color = 'currentcolor';
    if (isFinite(parseFloat(value1)) && !isFinite(parseFloat(value2))) {
      color = 'var(--red)';
    } else if (!isFinite(parseFloat(value1)) && isFinite(parseFloat(value2))) {
      color = 'var(--blue)';
    }
    return { value1, value2, color };
  }

  @autobind
  handleInstanceChange(instanceId) {
    const { replace, location } = this.props;
    const params = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...params, instanceId }));
  }

  @autobind
  handleInstanceResetClick() {
    this.reloadData(this.props);
  }

  @autobind
  handlePaginationChange(page, pageSize) {
    this.setState({ page }, () => {
      this.getIgnoreList();
      this.getEscalateIncident();
      this.reloadData(this.props);
    });
  }

  @autobind
  metricSearchClick(metricFilter) {
    const { replace, location } = this.props;
    const params = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...params, metricFilter }));
  }

  @autobind
  metricFilterClick(metricFilter) {
    this.setState({ metricFilter });
  }

  @autobind
  handleStatsStartTimeChange(statsStartTimeObj) {
    const { statsEndTimeObj } = this.state;
    this.setState({ statsStartTimeObj, page: 1 }, () => {
      if (statsEndTimeObj >= statsStartTimeObj) {
        this.getIgnoreList();
        this.getEscalateIncident();
        this.reloadData(this.props);
      }
    });
  }

  @autobind
  handleStatsEndTimeChange(statsEndTimeObj) {
    const { statsStartTimeObj } = this.state;
    this.setState({ statsEndTimeObj, page: 1 }, () => {
      if (statsEndTimeObj >= statsStartTimeObj) {
        this.getIgnoreList();
        this.getEscalateIncident();
        this.reloadData(this.props);
      }
    });
  }

  @autobind
  handleSelectFilterChange(value, options, oldVal) {
    const { replace, location } = this.props;
    const params = parseLocation(location);

    let newVal = value;
    const currentActiveValue = R.difference(value, oldVal)[0];
    const isKpi = currentActiveValue === 'onlyIsKpi';
    const isNonKpi = currentActiveValue === 'nonKpi';
    if (isKpi) {
      newVal = R.includes('nonKpi', newVal) ? R.filter((item) => item !== 'nonKpi', newVal) : newVal;
    }
    if (isNonKpi) {
      newVal = R.includes('onlyIsKpi', newVal) ? R.filter((item) => item !== 'onlyIsKpi', newVal) : newVal;
    }

    const onlyFilter = {
      ...(R.includes('onlyIsKpi', newVal) ? { onlyIsKpi: true } : { onlyIsKpi: undefined }),
      ...(R.includes('nonKpi', newVal) ? { onlyIsNoneKpi: true } : { onlyIsNoneKpi: undefined }),
      ...(R.includes('onlyComputeDifference', newVal)
        ? { onlyComputeDifference: true }
        : { onlyComputeDifference: undefined }),
      ...(R.includes('onlyThreshold', newVal) ? { onlyThreshold: true } : { onlyThreshold: undefined }),
    };

    replace(buildLocation(location.pathname, {}, { ...params, ...onlyFilter }));
    this.setState({ onlyFilter, page: 1, onlyFilterMetric: newVal }, () => {
      this.getIgnoreList();
      this.getEscalateIncident();
      this.reloadData(this.props);
    });
  }

  @autobind
  handleDeleteClick() {
    this.setState({ isDeleting: true });
    const { intl, location, projectName, credentials } = this.props;
    const params = parseLocation(location);
    const { instanceId } = params;
    const diffGroupingDelete = R.filter((item) => item?.checked, this.localMetrics);
    const deleteMetrics = R.map((m) => m.smetric, diffGroupingDelete);
    fetchDelete(getEndpoint('projects/componentmetricupdate'), {
      ...credentials,
      projectName,
      appName: instanceId,
      deleteMetrics: JSON.stringify(deleteMetrics),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(`${intl.formatMessage(appMessages.apiSuccess)}. ${errMsg}`);
          this.setState({ isDeleting: false, page: 1 });
          this.reloadData(this.props);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isDeleting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isDeleting: false, errMsg: err.message || String(err) });
      });
  }

  @autobind
  handleUploadClick() {
    this.setState({ showMetricFileModal: true });
  }

  @autobind
  handleDownloadClick() {
    this.exportModal = Modal.confirm({
      title: 'Export metrics',
      content: null,
      okButtonProps: {},
      onOk: this.handleDownloadConfirm,
    });
  }

  @autobind
  async handleDownloadConfirm(close) {
    const { intl, credentials, projectName } = this.props;

    const apiParams = {
      ...credentials,
      projectName,
      start: 0,
      limit: 1000000,
    };
    this.props.updateLastActionInfo();
    const data = await fetchGet(getEndpoint('projects/componentmetricupdate'), apiParams, {}, '', false)
      .then((response) => {
        const reader = response.body.getReader();
        return getReaderStream(reader);
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .catch((err) => {
        console.debug(err);
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
    if (data) {
      const text = await data.text();
      const textJSON = JSON.parse(text) || {};
      const metricSetting = get(textJSON, 'metricSetting', []);
      [].join(',');
      const headers = [
        'Metric name',
        'Detection type',
        '(Positive) Alert threshold (>)',
        '(Positive) No alert min',
        '(Positive) No alert max',
        '(Negative) Alert threshold (<)',
        '(Negative) No alert min',
        '(Negative) No alert max',
        'Invalid value threshold (>)',
        'Invalid value threshold (<)',
        'KPI',
        'KPI duration threshold (min)',
        'Cumulative',
        'Zero filling',
        // 'Special sampling interval',
      ].join(',');

      let csvString = `${headers}\r\n`;
      const csvData = [];
      R.forEach((item) => {
        let rougeValue;
        try {
          rougeValue = JSON.parse(item.rougeValue);
        } catch (error) {
          //
        }
        csvData.push(
          [
            item.smetric,
            item.detectionType,
            item.thresholdAlertLowerBound,
            item.thresholdNoAlertLowerBound,
            item.thresholdNoAlertUpperBound,
            item.thresholdAlertUpperBoundNegative,
            item.thresholdNoAlertLowerBoundNegative,
            item.thresholdNoAlertUpperBoundNegative,
            rougeValue?.l,
            rougeValue?.s,
            item.isKPI,
            item.kpiDurationThreshold / 60000,
            item.computeDifference,
            item.fillZero,
            // item.specialInterval,
          ].join(','),
        );
      }, metricSetting);
      csvString += R.join('\r\n', csvData);
      downloadFile(csvString, `${projectName} metric list.csv`);
    }
    close();
  }

  @autobind
  instanceLevelChange(value, dataKey, rowData) {
    rowData[dataKey] = value;
    rowData[dataKey] = R.sortBy(R.toLower)(value);
    this.cellMeasureCache.clearAll();
    if (this.table) this.table.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleClickAdd({ rowIndex, rowData }) {
    const newRowData = {
      ...rowData,
      thresholdAlertLowerBound: 'None',
      thresholdAlertLowerBoundNegative: 'None',
      thresholdAlertLowerBoundNegativeViewVal: 'None',
      thresholdNoAlertLowerBoundNegative: 'None',
      thresholdNoAlertLowerBoundNegativeViewVal: 'None',
      thresholdAlertLowerBoundViewVal: 'None',
      thresholdAlertUpperBound: 'None',
      thresholdAlertUpperBoundNegative: 'None',
      thresholdAlertUpperBoundNegativeViewVal: 'None',
      thresholdNoAlertUpperBoundNegative: 'None',
      thresholdNoAlertUpperBoundNegativeViewVal: 'None',
      thresholdAlertUpperBoundViewVal: 'None',
      thresholdNoAlertLowerBound: 'None',
      thresholdNoAlertLowerBoundViewVal: 'None',
      thresholdNoAlertUpperBound: 'None',
      thresholdNoAlertUpperBoundViewVal: 'None',
      checked: false,
      isInstanceLevel: true,
      instanceLevelList: [],
      rougeValue: { l: 'None', s: 'None' },
    };
    this.localMetrics.splice(rowIndex + 1, 0, newRowData);
    this.cellMeasureCache.clearAll();
    if (this.table) this.table.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleClickDelete({ rowIndex }) {
    this.localMetrics = R.remove(rowIndex, 1, this.localMetrics);
    this.forceUpdate();
    setTimeout(() => {
      this.cellMeasureCache.clearAll();
      if (this.table) this.table.forceUpdateGrid();
    });
  }

  @autobind
  renderInstanceLevel({ dataKey, parent, rowIndex, columnIndex, rowData }) {
    const { intl } = this.props;
    const { instanceLevelList, isInstanceLevel } = rowData;
    const { instanceGroupList } = this.state;
    const isGlobal = !isInstanceLevel;
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div className="flex-row flex-center-align" style={{ padding: '8px 0' }}>
          {isGlobal && (
            <>
              <span>Global</span>
              <Button size="small" style={{ marginLeft: 6 }} onClick={() => this.handleClickAdd({ rowIndex, rowData })}>
                Add
              </Button>
            </>
          )}
          {!isGlobal && (
            <>
              <TreeSelect
                size="small"
                allowClear
                treeCheckable
                showArrow={false}
                treeDefaultExpandAll
                style={{ maxWidth: 135 }}
                treeData={instanceGroupList}
                autoClearSearchValue={false}
                value={instanceLevelList || []}
                dropdownMatchSelectWidth={500}
                className="flex-grow no-count-num"
                treeNodeFilterProp="title"
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                treeExpandedKeys={R.map((item) => item.value, instanceGroupList)}
                onChange={(value) => this.instanceLevelChange(value, dataKey, rowData)}
              />
              <Button size="small" style={{ marginLeft: 6 }} onClick={() => this.handleClickDelete({ rowIndex })}>
                {intl.formatMessage(appButtonsMessages.delete)}
              </Button>
            </>
          )}
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  alertThresholdsRender({ rowIndex, rowData }) {
    const { intl } = this.props;
    return (
      <Button
        size="small"
        onClick={() => this.setState({ activeRowData: { rowData, rowIndex }, showAlertThresholdsModal: true })}
      >
        {intl.formatMessage(appFieldsMessages.config)}
      </Button>
    );
  }

  render() {
    const {
      intl,
      location,
      instanceMetricList,
      metricListCount,
      possibleMetricType,
      batchPossibleMetricType,
      entry,
      credentials,
      currentProject,
    } = this.props;
    const params = parseLocation(location);
    const { instanceId } = params;
    const { metricFilter, statsStartTimeObj, statsEndTimeObj, patternIdGenerationRule, patternNameRegex } = this.state;
    const { page, pageSize, isDeleting, errMsg, sortBy, sortDirection, onlyFilterMetric } = this.state;
    const { samplingInterval } = this.state;

    const isInstance = Boolean(instanceId);
    const diffGroupingUpdate = R.filter((item) => item?.checked, this.localMetrics);
    const diff = R.differenceWith(this.cmp, this.localMetrics, instanceMetricList);
    // const hasNotInteger = !!R.find(
    //   (item) => !isInteger(item.specialInterval / samplingInterval),
    //   this.localMetrics || [],
    // );
    // const saveDiff = isEqual(this.localMetrics, this.saveLocalMetrics) || hasNotInteger;
    const saveDiff = isEqual(this.localMetrics, this.saveLocalMetrics);

    const hasErrorGroupingUpdate = diffGroupingUpdate.length === 0;
    const hasError = R.equals(entry, 'batch') ? false : diff.length === 0;
    const isSubmittingGroupUpdate = get(this.props.currentLoadingComponents, this.submitGroupUpdateLoadingKey, false);
    const isSubmitting = get(this.props.currentLoadingComponents, this.submitLoadingKey, false);
    const isLoading = get(this.props.currentLoadingComponents, this.instanceLoader, false);
    const metricsList = R.equals(entry, 'batch') ? this.localBatchMetrics : this.localMetrics;
    const metrics = R.equals(entry, 'batch')
      ? R.filter((item) => {
          return metricFilter ? R.toLower(item.smetric).indexOf(R.toLower(metricFilter)) > -1 : true;
        }, metricsList)
      : metricsList;

    return (
      <Container fullHeight className="flex-col">
        <p>{intl.formatMessage(settingsMessages.metricSettingDesc1)}</p>
        <p>{intl.formatMessage(settingsMessages.metricSettingDesc2)}</p>

        {entry !== 'batch' && metrics.length > 0 && (
          <div className="flex-row flex-center-align">
            <div className="flex-row flex-center-align" style={{ marginRight: 8 }}>
              <div style={{ marginRight: 4, fontSize: 14, fontWeight: 'bold' }}>
                {intl.formatMessage(settingsMessages.patternIDGenerationRule)}
              </div>
              <Popover
                content={intl.formatMessage(settingsMessages.patternIDGenerationRuleMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined /> :
              </Popover>
            </div>
            <AntdSelect
              value={patternIdGenerationRule}
              disabled={isLoading || isDeleting}
              style={{ width: 200 }}
              onChange={(patternIdGenerationRule) =>
                this.setState({ patternIdGenerationRule, isDeleting: true }, () => this.handleSaveClick())
              }
              options={this.generationRuleOption}
            />
          </div>
        )}

        {entry !== 'batch' && (
          <Container className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
            <div className="flex-grow" />
            <Pagination
              size="small"
              current={page}
              total={metricListCount}
              pageSize={pageSize}
              onChange={this.handlePaginationChange}
              showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
              showSizeChanger
              pageSizeOptions={['500', '1000']}
              onShowSizeChange={(current, pageSize) => {
                this.setState({ pageSize }, () => {
                  this.handlePaginationChange(current, pageSize);
                });
              }}
            />
            <Button type="primary" size="small" style={{ marginLeft: 8 }} onClick={this.handleDownloadClick}>
              {intl.formatMessage(appButtonsMessages.download)}
            </Button>
            <Button type="primary" size="small" style={{ marginLeft: 8 }} onClick={this.handleUploadClick}>
              {intl.formatMessage(appButtonsMessages.upload)}
            </Button>
          </Container>
        )}

        <Container className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          {entry === 'batch' && (
            <>
              <div className="light-label bold" style={{ marginRight: 8 }}>
                {intl.formatMessage(appFieldsMessages.metric)}:
              </div>
              <div className="flex-row flex-center-align" style={{ width: 160 }}>
                <AntdInput.Search
                  size="small"
                  placeholder="input search text"
                  allowClear
                  onSearch={this.metricFilterClick}
                  enterButton
                />
              </div>
              <div className="flex-grow" />
              {!(isLoading || isDeleting) && (
                <div style={{ marginRight: 16 }}>
                  {intl.formatMessage(appFieldsMessages.metricCount)}:{' '}
                  <span style={{ fontSize: 14, fontWeight: 'bold' }}>{(metrics || []).length}</span>
                </div>
              )}
            </>
          )}
          {entry !== 'batch' && (
            <>
              <div className="light-label bold" style={{ marginRight: 8 }}>
                {intl.formatMessage(appFieldsMessages.metric)}:
              </div>
              <div className="flex-row flex-center-align" style={{ width: 160 }}>
                <AntdInput.Search
                  size="small"
                  placeholder="input search text"
                  allowClear
                  value={metricFilter}
                  onChange={(e) => this.setState({ metricFilter: e.target.value })}
                  onSearch={this.metricSearchClick}
                  enterButton
                />
              </div>
              <div className="light-label bold" style={{ marginLeft: 16, marginRight: 8 }}>
                {intl.formatMessage(settingsMessages.statisticsTime)}:
              </div>
              <DatePicker
                size="small"
                allowClear={false}
                value={statsStartTimeObj}
                onChange={this.handleStatsStartTimeChange}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
              />
              <span style={{ margin: '0 4px' }}>~</span>
              <DatePicker
                size="small"
                allowClear={false}
                value={statsEndTimeObj}
                onChange={this.handleStatsEndTimeChange}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
              />

              <div className="flex-row flex-center-align" style={{ width: 160, marginLeft: 16, marginRight: 8 }}>
                <AntdSelect
                  className="full-width"
                  mode="multiple"
                  size="small"
                  placeholder={intl.formatMessage(appFieldsMessages.seeOnly)}
                  onChange={(value, options) => this.handleSelectFilterChange(value, options, onlyFilterMetric)}
                  showSearch
                  maxTagCount={0}
                  maxTagTextLength={10}
                  allowClear
                  value={onlyFilterMetric}
                  dropdownMatchSelectWidth={false}
                >
                  <AntdSelect.Option value="onlyIsKpi">KPI metrics</AntdSelect.Option>
                  <AntdSelect.Option value="nonKpi">Non KPI metrics</AntdSelect.Option>
                  <AntdSelect.Option value="onlyComputeDifference">Cumulative metrics</AntdSelect.Option>
                  <AntdSelect.Option value="onlyThreshold">Threshold alerting metrics</AntdSelect.Option>
                </AntdSelect>
              </div>
              {patternIdGenerationRule === 1 && (
                <div className="flex-row flex-center-align" style={{ marginRight: 8 }}>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => this.setState({ showPatternNameRegexModal: true })}
                  >
                    {intl.formatMessage(appFieldsMessages.configPatternNameRegex)}
                  </Button>
                </div>
              )}

              <div className="flex-grow" />
              {!(isLoading || isDeleting) && (
                <div style={{ marginRight: 16 }}>
                  {intl.formatMessage(appFieldsMessages.metricCount)}:{' '}
                  <span style={{ fontSize: 14, fontWeight: 'bold' }}>{(metrics || []).length}</span>
                </div>
              )}
            </>
          )}
        </Container>

        <Container
          className="flex-grow flex-min-height"
          style={entry === 'batch' ? {} : { overflow: 'overlay hidden' }}
        >
          <Spin spinning={isLoading || isDeleting} wrapperClassName="full-height spin-full-height">
            {errMsg && <Alert message={errMsg} type="error" showIcon style={{ marginBottom: 8 }} />}

            <div className="flex-grow flex-min-height">
              <AutoSizer>
                {({ width, height }) => (
                  <Table
                    ref={(c) => {
                      this.table = c;
                    }}
                    className="with-border"
                    width={entry === 'batch' ? width : width <= 1985 ? 1985 : width}
                    height={height}
                    headerHeight={60}
                    rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                    rowCount={metrics.length}
                    rowGetter={({ index }) => metrics[index]}
                    deferredMeasurementCache={this.cellMeasureCache}
                    rowHeight={this.cellMeasureCache.rowHeight}
                    sort={this.sortTable}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                  >
                    <Column
                      width={30}
                      flexShrink={0}
                      dataKey="checked"
                      className="text-center"
                      headerClassName="text-center"
                      style={{ marginRight: 8 }}
                      disableSort
                      headerRenderer={this.checkAllHeaderRender('isCheckedAll')}
                      cellRenderer={this.checkboxCellRender}
                    />
                    <Column
                      width={250}
                      minWidth={250}
                      label={intl.formatMessage(appFieldsMessages.metric)}
                      dataKey="smetric"
                      className="white-pre"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.renderMetricName}
                      flexGrow={2}
                      disableSort={entry === 'batch'}
                    />
                    {entry !== 'batch' && patternIdGenerationRule === 1 && (
                      <Column
                        width={200}
                        minWidth={200}
                        flexShrink={0}
                        label={intl.formatMessage(appFieldsMessages.patternName)}
                        dataKey="patternName"
                        className="white-pre"
                        headerRenderer={this.headerRenderer}
                        cellRenderer={this.renderPatternName}
                        disableSort
                      />
                    )}
                    {entry !== 'batch' && (
                      <Column
                        width={250}
                        minWidth={250}
                        label={
                          <div className="flex-row flex-center-align">
                            <div style={{ marginRight: 4 }}>{intl.formatMessage(appFieldsMessages.instance)}</div>
                            <Popover
                              content={intl.formatMessage(settingsMessages.instanceMessage)}
                              mouseEnterDelay={0.3}
                              placement="top"
                            >
                              <QuestionCircleOutlined />
                            </Popover>
                          </div>
                        }
                        dataKey="instanceLevelList"
                        className="white-pre"
                        disableSort
                        headerRenderer={this.headerRenderer}
                        cellRenderer={this.renderInstanceLevel}
                      />
                    )}
                    <Column
                      width={130}
                      label={
                        <div className="flex-row flex-center-align">
                          <div style={{ marginRight: 4 }}>{`${intl.formatMessage(
                            appFieldsMessages.statistics,
                          )}(${intl.formatMessage(appFieldsMessages.avg)})`}</div>
                          <Popover
                            content={intl.formatMessage(settingsMessages.statisticsMessage)}
                            mouseEnterDelay={0.3}
                            placement="top"
                          >
                            <QuestionCircleOutlined />
                          </Popover>
                        </div>
                      }
                      dataKey="stats"
                      headerClassName="break-word"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.renderStatistics}
                      disableSort={entry === 'batch'}
                      flexShrink={0}
                    />
                    <Column
                      width={140}
                      label={
                        <div className="flex-row flex-center-align">
                          <div style={{ marginRight: 4 }}>
                            {intl.formatMessage(appFieldsMessages.alertThresholdsAndDetectionType)}
                          </div>
                          <Popover
                            content={intl.formatMessage(settingsMessages.alertThresholdsAndDetectionTypeMessage)}
                            mouseEnterDelay={0.3}
                            placement="top"
                          >
                            <QuestionCircleOutlined />
                          </Popover>
                        </div>
                      }
                      dataKey="smetric"
                      headerRenderer={({ label }) => <span>{label}</span>}
                      disableSort
                      flexShrink={0}
                      cellRenderer={this.alertThresholdsRender}
                    />
                    <Column
                      width={60}
                      label={intl.formatMessage(settingsMessages.KPI)}
                      dataKey="isKPI"
                      className="text-center"
                      headerClassName="text-center break-all tour-setting-metric-kpi"
                      disableSort
                      flexShrink={0}
                      headerRenderer={this.checkAllHeaderRender('isKpiAll')}
                      cellRenderer={this.checkboxCellRender}
                    />
                    {entry !== 'batch' && (
                      <Column
                        width={135}
                        label={
                          <div className="flex-row flex-center-align">
                            <div style={{ marginRight: 4 }}>{intl.formatMessage(eventMessages.escalateIncident)}</div>
                            <Popover
                              content={intl.formatMessage(settingsMessages.escalateIncidentMessage)}
                              mouseEnterDelay={0.3}
                              placement="left"
                            >
                              <QuestionCircleOutlined />
                            </Popover>
                          </div>
                        }
                        dataKey="escalateIncident"
                        className="text-center"
                        headerClassName="break-word tour-setting-metric-kpi"
                        disableSort
                        flexShrink={0}
                        headerRenderer={({ label }) => <span>{label}</span>}
                        cellRenderer={this.renderIgnore}
                      />
                    )}
                    <Column
                      width={100}
                      label={
                        <div className="flex-row flex-center-align">
                          <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.kpiThreshold)}</div>
                          <Popover
                            content={intl.formatMessage(settingsMessages.kpiThresholdMessage)}
                            mouseEnterDelay={0.3}
                            placement="left"
                          >
                            <QuestionCircleOutlined />
                          </Popover>
                        </div>
                      }
                      dataKey="kpiDurationThreshold"
                      className="text-center"
                      headerClassName="break-work"
                      flexShrink={0}
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.renderInputNumber({ min: 0, precision: 0 })}
                    />
                    {entry !== 'batch' && (
                      <Column
                        width={100}
                        label={
                          <div className="flex-row flex-center-align">
                            <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.dampeningPeriod)}</div>
                            <Popover
                              content={intl.formatMessage(settingsMessages.dampeningPeriodMessage)}
                              mouseEnterDelay={0.3}
                              placement="left"
                            >
                              <QuestionCircleOutlined />
                            </Popover>
                          </div>
                        }
                        dataKey="anomalyDampening"
                        className="text-center"
                        headerClassName="break-work"
                        flexShrink={0}
                        headerRenderer={this.headerRenderer}
                        cellRenderer={this.renderInputNumber({ min: 0, precision: 0 })}
                      />
                    )}
                    {entry !== 'batch' && (
                      <Column
                        width={110}
                        label={intl.formatMessage(settingsMessages.nearConstantDetection)}
                        dataKey="enableBaselineNearConstance"
                        className="text-center"
                        disableSort
                        flexShrink={0}
                        headerClassName="text-center break-all tour-setting-metric-kpi"
                        headerRenderer={this.checkAllHeaderRender('isAllNearConstantDetection')}
                        cellRenderer={this.checkboxCellRender}
                      />
                    )}
                    {entry !== 'batch' && (
                      <Column
                        width={110}
                        label={
                          <div className="flex-row flex-center-align">
                            <div style={{ marginRight: 4 }}>{intl.formatMessage(appFieldsMessages.ignore)}</div>
                            <Popover
                              content={intl.formatMessage(settingsMessages.ignoreMessage)}
                              mouseEnterDelay={0.3}
                              placement="left"
                            >
                              <QuestionCircleOutlined />
                            </Popover>
                          </div>
                        }
                        disableSort
                        dataKey="ignoreList"
                        // headerRenderer={({ label }) => <span>{label}</span>}
                        cellRenderer={this.renderIgnore}
                        flexShrink={0}
                      />
                    )}
                    {!isInstance && (
                      <Column
                        width={100}
                        label={intl.formatMessage(settingsMessages.computeDifference)}
                        dataKey="computeDifference"
                        className="text-center"
                        disableSort
                        flexShrink={0}
                        headerClassName="text-center break-all tour-setting-metric-kpi"
                        headerRenderer={this.checkAllHeaderRender('isAllComputeDifference')}
                        cellRenderer={this.checkboxCellRender}
                      />
                    )}
                    {isInstance && (
                      <Column
                        width={65}
                        label={intl.formatMessage(settingsMessages.modified)}
                        dataKey="modified"
                        className="text-center"
                        disableSort
                        flexShrink={0}
                        headerClassName="text-center"
                        headerRenderer={({ label }) => <span>{label}</span>}
                        cellRenderer={this.modifiedCellRender}
                      />
                    )}
                    <Column
                      width={90}
                      label={intl.formatMessage(settingsMessages.enableMissingDataFilling)}
                      dataKey="fillZero"
                      className="text-center"
                      disableSort
                      headerClassName="text-center break-all tour-setting-metric-kpi"
                      headerRenderer={this.checkAllHeaderRender('isFillZeroAll')}
                      cellRenderer={this.checkboxCellRender}
                      flexShrink={0}
                    />
                    <Column
                      width={120}
                      label={
                        <div className="flex-row flex-center-align">
                          <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.metricType)}</div>
                          <Popover
                            content={intl.formatMessage(settingsMessages.metricTypeMessage)}
                            mouseEnterDelay={0.3}
                            placement="left"
                          >
                            <QuestionCircleOutlined />
                          </Popover>
                        </div>
                      }
                      dataKey="metricType"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.renderMetricType}
                      disableSort={entry === 'batch'}
                      flexShrink={0}
                    />
                    {/* <Column
                      width={120}
                      label={`${intl.formatMessage(settingsMessages.specialSamplingInterval)} (min)`}
                      dataKey="specialInterval"
                      headerClassName="break-word"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.renderInputNumber()}
                      disableSort={entry === 'batch'}
                      flexShrink={0}
                    /> */}
                  </Table>
                )}
              </AutoSizer>
            </div>
          </Spin>
        </Container>

        {entry !== 'batch' && (
          <Container className="field text-right" style={{ marginTop: 8 }}>
            <Button
              type="primary"
              loading={isSubmittingGroupUpdate}
              disabled={hasErrorGroupingUpdate}
              onClick={this.handleGroupingUpdateClick}
            >
              {intl.formatMessage(settingsMessages.groupSettingsUpdate)}
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 8 }}
              loading={isSubmitting}
              disabled={saveDiff}
              onClick={this.handleSaveClick}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
            <Popconfirm
              placement="topRight"
              title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
              onConfirm={this.handleDeleteClick}
              onCancel={(event) => event.stopPropagation()}
            >
              <Button
                type="primary"
                className="button-color-grey"
                style={{ marginLeft: 16 }}
                icon={<DeleteOutlined />}
                loading={isDeleting}
                disabled={hasErrorGroupingUpdate}
                onClick={(event) => event.stopPropagation()}
              >
                {intl.formatMessage(appButtonsMessages.delete)}
              </Button>
            </Popconfirm>
          </Container>
        )}

        {this.state.showPatternNameRegexModal && (
          <MetricPatternNameRegexModal
            intl={intl}
            credentials={credentials}
            currentProject={currentProject}
            patternNameRegex={patternNameRegex}
            onClose={(flag) => {
              if (flag) {
                this.getIgnoreList();
                this.getEscalateIncident();
                this.reloadData(this.props);
              }
              this.setState({ showPatternNameRegexModal: false });
            }}
          />
        )}

        {this.state.showGroupingUpdateModal && (
          <GroupingUpdateModal
            intl={intl}
            detectionTypeList={this.detectionTypeList}
            componentNameList={this.state.componentNameList}
            possibleMetricType={entry === 'batch' ? batchPossibleMetricType : possibleMetricType}
            isSubmitting={isSubmittingGroupUpdate}
            onOk={this.handleGroupingUpdateSave}
            onClose={() => this.setState({ showGroupingUpdateModal: false })}
            samplingInterval={samplingInterval}
          />
        )}
        {this.state.showMetricFileModal && (
          <MetricFileModal
            intl={intl}
            credentials={credentials}
            project={currentProject}
            onOk={() => {
              this.setState({ showMetricFileModal: false }, () => {
                this.reloadData(this.props);
              });
            }}
            onClose={() => {
              this.setState({ showMetricFileModal: false });
            }}
          />
        )}

        {this.state.showEditMetricTypeModel && (
          <EditMetricTypeModal
            intl={intl}
            credentials={credentials}
            currentProject={currentProject}
            onClose={() => this.setState({ showEditMetricTypeModel: false })}
            onOk={() => {
              this.reloadData(this.props);
              this.setState({ showEditMetricTypeModel: false, page: 1 });
            }}
          />
        )}

        {this.state.showAlertThresholdsModal && (
          <AlertThresholdsModal
            intl={intl}
            activeRowData={this.state.activeRowData}
            isBatch={entry === 'batch'}
            onClose={async (data) => {
              if (data) {
                const { rowItem, rowIndex } = data;
                if (entry !== 'batch') {
                  this.localMetrics[rowIndex] = { ...this.localMetrics[rowIndex], ...rowItem };
                } else {
                  this.localBatchMetrics[rowIndex] = { ...this.localBatchMetrics[rowIndex], ...rowItem };
                }
                await this.handleSaveClick();
              }
              this.setState({ showAlertThresholdsModal: false, activeRowData: null });
            }}
          />
        )}
      </Container>
    );
  }
}

type PropsGroupingUpdate = {
  intl: Object,
  detectionTypeList: Array<Object>,
  isSubmitting: Boolean,
  onOk: Function,
  onClose: Function,
  componentNameList: Array<Object>,
  possibleMetricType: Array<Object>,
  samplingInterval: Number,
};

const GroupingUpdateModal = ({
  intl,
  detectionTypeList,
  componentNameList,
  possibleMetricType,
  isSubmitting,
  onOk,
  onClose,
  samplingInterval,
}: PropsGroupingUpdate) => {
  const [thresholdAlertLowerBound, setThresholdAlertLowerBound] = useState('None');
  const [thresholdAlertUpperBound, setThresholdAlertUpperBound] = useState('None');
  const [thresholdAlertLowerBoundViewVal, setThresholdAlertLowerBoundViewVal] = useState('None');
  const [thresholdAlertUpperBoundViewVal, setThresholdAlertUpperBoundViewVal] = useState('None');
  const [thresholdAlertChecked, setThresholdAlertChecked] = useState(false);

  const [thresholdNoAlertLowerBound, setThresholdNoAlertLowerBound] = useState('None');
  const [thresholdNoAlertUpperBound, setThresholdNoAlertUpperBound] = useState('None');
  const [thresholdNoAlertLowerBoundViewVal, setThresholdNoAlertLowerBoundViewVal] = useState('None');
  const [thresholdNoAlertUpperBoundViewVal, setThresholdNoAlertUpperBoundViewVal] = useState('None');
  const [thresholdNoAlertChecked, setThresholdNoAlertChecked] = useState(false);
  const [thresholdNoAlertUpperChecked, setThresholdNoAlertUpperChecked] = useState(false);

  const [thresholdAlertLowerBoundNegative, setThresholdAlertLowerBoundNegative] = useState('None');
  const [thresholdAlertUpperBoundNegative, setThresholdAlertUpperBoundNegative] = useState('None');
  const [thresholdAlertLowerBoundNegativeViewVal, setThresholdAlertLowerBoundNegativeViewVal] = useState('None');
  const [thresholdAlertUpperBoundNegativeViewVal, setThresholdAlertUpperBoundNegativeViewVal] = useState('None');
  const [thresholdNegativeChecked, setThresholdNegativeChecked] = useState(false);

  const [thresholdNoAlertLowerBoundNegative, setThresholdNoAlertLowerBoundNegative] = useState('None');
  const [thresholdNoAlertUpperBoundNegative, setThresholdNoAlertUpperBoundNegative] = useState('None');
  const [thresholdNoAlertLowerBoundNegativeViewVal, setThresholdNoAlertLowerBoundNegativeViewVal] = useState('None');
  const [thresholdNoAlertUpperBoundNegativeViewVal, setThresholdNoAlertUpperBoundNegativeViewVal] = useState('None');
  const [thresholdNoAlertNegativeChecked, setThresholdNoAlertNegativeChecked] = useState(false);
  const [thresholdNoAlertNegativeUpperChecked, setThresholdNoAlertNegativeUpperChecked] = useState(false);

  const [rougeValueL, setRougeValueL] = useState('None');
  const [rougeValueLChecked, setRougeValueLChecked] = useState(false);

  const [rougeValueS, setRougeValueS] = useState('None');
  const [rougeValueSChecked, setRougeValueSChecked] = useState(false);

  // const [specialInterval, setSpecialInterval] = useState(0);
  const [specialIntervalChecked, setSpecialIntervalChecked] = useState(false);
  const [detectionType, setDetectionType] = useState(['positive']);
  const [detectionTypeChecked, setDetectionTypeChecked] = useState(false);
  const [isKPI, setIsKPI] = useState(false);
  const [isKPIChecked, setIsKPIChecked] = useState(false);
  const [escalateIncident, setEscalateIncident] = useState([]);
  const [escalateIncidentChecked, setEscalateIncidentChecked] = useState(false);
  const [kpiDurationThreshold, setKpiDurationThreshold] = useState('');
  const [kpiDurationThresholdChecked, setKpiDurationThresholdChecked] = useState(false);
  const [computeDifference, setComputeDifference] = useState(false);
  const [computeDifferenceChecked, setComputeDifferenceChecked] = useState(false);
  const [enableBaselineNearConstance, setEnableBaselineNearConstance] = useState(false);
  const [enableBaselineNearConstanceChecked, setEnableBaselineNearConstanceChecked] = useState(false);
  const [fillZero, setFillZero] = useState(false);
  const [fillZeroChecked, setFillZeroChecked] = useState(false);
  const [metricType, setMetricType] = useState('');
  const [metricTypeChecked, setMetricTypeChecked] = useState(false);
  const [ignoreList, setIgnoreList] = useState([]);
  const [ignoreListChecked, setIgnoreListChecked] = useState(false);
  const [patternNameHigher, setPatternNameHigher] = useState('');
  const [patternNameLower, setPatternNameLower] = useState('');
  const [patternNameChecked, setPatternNameChecked] = useState('');
  const [anomalyDampening, setAnomalyDampening] = useState('');
  const [anomalyDampeningChecked, setAnomalyDampeningChecked] = useState(false);

  const handleInputBlue = ({ value }) => {
    if (Number.isNaN(value * 1) || value === null || value === '') {
      value = 'None';
    }
    let viewVal;
    if (!Number.isFinite(value * 1) || value === '' || Number.isFinite(value * 1) === 0) {
      viewVal = value;
    } else if (R.includes('e', value) || R.includes('E', value)) {
      if (R.includes('.', value)) {
        viewVal =
          value.match(/\.(\S*)(e|E)/)[1].length > 6
            ? R.replace('e', 'E', Number.parseFloat(value).toExponential(6))
            : R.replace('e', 'E', Number.parseFloat(value).toExponential());
      } else {
        viewVal =
          value.match(/(\S*)(e|E)/)[1].length > 6
            ? R.replace('e', 'E', Number.parseFloat(value).toExponential(6))
            : R.replace('e', 'E', Number.parseFloat(value).toExponential());
      }
    } else if (value > 999999) {
      viewVal = R.replace('e', 'E', Number.parseFloat(value).toExponential(6));
    } else {
      viewVal = value;
    }
    return viewVal;
  };

  const renderLabel = (label, checked, resetHandler, position) => {
    return (
      <div className="flex-row">
        {checked && (
          <div style={{ margin: ' 0 0.5em 0 0', ...(position ? { position: 'absolute', left: -20 } : {}) }}>
            <Tooltip placement="top" title="Click to reset">
              <CheckOutlined onClick={resetHandler} style={{ fontSize: 16, color: '#f85042' }} />
            </Tooltip>
          </div>
        )}
        {label}
      </div>
    );
  };

  const changeDetectionTypeResetData = (detectionType) => {
    const isPositive = R.includes('positive', detectionType);
    const isNegative = R.includes('negative', detectionType);
    const isBoth = isPositive && isNegative;
    if (isBoth) {
      return false;
    } else if (isPositive) {
      setThresholdNegativeChecked(false);
      setThresholdNoAlertNegativeChecked(false);
      setThresholdNoAlertNegativeUpperChecked(false);
      setThresholdAlertLowerBoundNegative('None');
      setThresholdAlertUpperBoundNegative('None');
      setThresholdAlertLowerBoundNegativeViewVal('None');
      setThresholdAlertUpperBoundNegativeViewVal('None');
      setThresholdNoAlertLowerBoundNegative('None');
      setThresholdNoAlertUpperBoundNegative('None');
      setThresholdNoAlertLowerBoundNegativeViewVal('None');
      setThresholdNoAlertUpperBoundNegativeViewVal('None');
    } else if (isNegative) {
      setThresholdAlertChecked(false);
      setThresholdNoAlertChecked(false);
      setThresholdNoAlertUpperChecked(false);
      setThresholdAlertLowerBound('None');
      setThresholdAlertUpperBound('None');
      setThresholdAlertLowerBoundViewVal('None');
      setThresholdAlertUpperBoundViewVal('None');
      setThresholdNoAlertLowerBound('None');
      setThresholdNoAlertUpperBound('None');
      setThresholdNoAlertLowerBoundViewVal('None');
      setThresholdNoAlertUpperBoundViewVal('None');
    }
  };

  const allowSave =
    thresholdAlertLowerBound ||
    thresholdAlertUpperBound ||
    thresholdNoAlertLowerBound ||
    thresholdNoAlertUpperBound ||
    thresholdAlertLowerBoundNegative ||
    thresholdAlertUpperBoundNegative ||
    thresholdNoAlertLowerBoundNegative ||
    thresholdNoAlertUpperBoundNegative ||
    // (specialInterval && isFinite(parseInt(specialInterval, 10))) ||
    (kpiDurationThreshold && isFinite(parseInt(kpiDurationThreshold, 10))) ||
    detectionTypeChecked ||
    isKPIChecked ||
    escalateIncidentChecked ||
    computeDifferenceChecked ||
    enableBaselineNearConstanceChecked ||
    fillZeroChecked ||
    metricTypeChecked ||
    ignoreListChecked ||
    patternNameHigher ||
    patternNameLower ||
    (anomalyDampening && isFinite(parseInt(anomalyDampening, 10)));

  const isPositive = R.includes('positive', detectionType);
  const isNegative = R.includes('negative', detectionType);
  const isBoth = isPositive && isNegative;

  let hasPositiveError = false;
  if (isPositive) {
    if (
      // (thresholdNoAlertLowerBound !== 'None' &&
      //   Number(thresholdNoAlertLowerBound) >= Number(thresholdAlertLowerBound)) ||
      (thresholdNoAlertUpperBound !== 'None' &&
        Number(thresholdNoAlertUpperBound) >= Number(thresholdAlertLowerBound)) ||
      Number(thresholdNoAlertLowerBound) >= Number(thresholdNoAlertUpperBound)
    ) {
      hasPositiveError = true;
    }
  }
  let hasNegativeError = false;
  if (isNegative) {
    if (
      // (thresholdNoAlertLowerBoundNegative !== 'None' &&
      //   Number(thresholdNoAlertLowerBoundNegative) <= Number(thresholdAlertUpperBoundNegative)) ||
      (thresholdNoAlertUpperBoundNegative !== 'None' &&
        Number(thresholdNoAlertUpperBoundNegative) <= Number(thresholdAlertUpperBoundNegative)) ||
      Number(thresholdNoAlertLowerBoundNegative) >= Number(thresholdNoAlertUpperBoundNegative)
    ) {
      hasNegativeError = true;
    }
  }
  let hasBothError = false;
  if (isBoth) {
    if (
      thresholdAlertLowerBound !== 'None' &&
      thresholdAlertUpperBoundNegative !== 'None' &&
      Number(thresholdAlertLowerBound) <= Number(thresholdAlertUpperBoundNegative)
    ) {
      hasBothError = true;
    }
  }
  let hasIgnoreError = false;
  if (rougeValueL !== 'None' && rougeValueS !== 'None' && Number(rougeValueL) <= Number(rougeValueS)) {
    hasIgnoreError = true;
  }

  // const isAllInteger = isInteger(specialInterval / samplingInterval);

  const hasError = hasPositiveError || hasNegativeError || hasBothError || hasIgnoreError;
  const allValues = R.map((x) => x.value, componentNameList);

  return (
    <Modal
      title={intl.formatMessage(settingsMessages.groupSettingsUpdate)}
      width={650}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={() =>
        onOk({
          thresholdAlertLowerBound: thresholdAlertChecked
            ? thresholdAlertLowerBound
            : detectionTypeChecked && !isPositive
            ? 'None'
            : undefined,
          thresholdAlertUpperBound: thresholdAlertChecked
            ? thresholdAlertUpperBound
            : detectionTypeChecked && !isPositive
            ? 'None'
            : undefined,
          thresholdNoAlertLowerBound: thresholdNoAlertChecked
            ? thresholdNoAlertLowerBound
            : detectionTypeChecked && !isPositive
            ? 'None'
            : undefined,
          thresholdNoAlertUpperBound: thresholdNoAlertUpperChecked
            ? thresholdNoAlertUpperBound
            : detectionTypeChecked && !isPositive
            ? 'None'
            : undefined,
          thresholdAlertLowerBoundNegative: thresholdNegativeChecked
            ? thresholdAlertLowerBoundNegative
            : detectionTypeChecked && !isNegative
            ? 'None'
            : undefined,
          thresholdAlertUpperBoundNegative: thresholdNegativeChecked
            ? thresholdAlertUpperBoundNegative
            : detectionTypeChecked && !isNegative
            ? 'None'
            : undefined,
          thresholdNoAlertLowerBoundNegative: thresholdNoAlertNegativeChecked
            ? thresholdNoAlertLowerBoundNegative
            : detectionTypeChecked && !isNegative
            ? 'None'
            : undefined,
          thresholdNoAlertUpperBoundNegative: thresholdNoAlertNegativeUpperChecked
            ? thresholdNoAlertUpperBoundNegative
            : detectionTypeChecked && !isNegative
            ? 'None'
            : undefined,
          rougeValueL: rougeValueLChecked ? rougeValueL : undefined,
          rougeValueS: rougeValueSChecked ? rougeValueS : undefined,
          // specialInterval: specialIntervalChecked ? String(specialInterval) : undefined,
          detectionType: detectionTypeChecked ? (isBoth ? 'both' : detectionType.join()) : undefined,
          isKPI: isKPIChecked ? isKPI : undefined,
          escalateIncident: escalateIncidentChecked ? escalateIncident || [] : undefined,
          kpiDurationThreshold: kpiDurationThresholdChecked ? kpiDurationThreshold : undefined,
          computeDifference: computeDifferenceChecked ? computeDifference : undefined,
          enableBaselineNearConstance: enableBaselineNearConstanceChecked ? enableBaselineNearConstance : undefined,
          fillZero: fillZeroChecked ? fillZero : undefined,
          metricType: metricTypeChecked ? metricType : undefined,
          ignoreList: ignoreListChecked ? ignoreList || [] : undefined,
          patternNameHigher: patternNameChecked ? patternNameHigher : undefined,
          patternNameLower: patternNameChecked ? patternNameLower : undefined,
          anomalyDampening: anomalyDampeningChecked ? anomalyDampening : undefined,
        })
      }
      // okButtonProps={{ disabled: hasError || !allowSave || !isAllInteger, loading: isSubmitting }}
      okButtonProps={{ disabled: hasError || !allowSave, loading: isSubmitting }}
      bodyStyle={{ height: 700, overflowY: 'auto', overflowX: 'hidden', marginRight: 4 }}
    >
      {hasError && (
        <Alert
          showIcon
          type="warning"
          message={intl.formatMessage(appFieldsMessages.Warning)}
          style={{ marginBottom: 18 }}
          description={
            <>
              {isPositive && hasPositiveError && (
                <div>{intl.formatMessage(appFieldsMessages.positiveThresholdsAlert)}</div>
              )}
              {isNegative && hasNegativeError && (
                <div>{intl.formatMessage(appFieldsMessages.negativeThresholdsAlert)}</div>
              )}
              {isBoth && hasBothError && <div>{intl.formatMessage(appFieldsMessages.bothThresholdsAlert)}</div>}
              {hasIgnoreError && <div>Ignore thresholds have conflicts</div>}
            </>
          }
        />
      )}
      <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} size="small">
        {/* <Form.Item
          label={renderLabel(
            `${intl.formatMessage(settingsMessages.specialSamplingInterval)} (min)`,
            specialIntervalChecked,
            () => {
              setSpecialIntervalChecked(false);
              setSpecialInterval(0);
            },
          )}
        >
          <InputNumber
            value={specialInterval}
            onChange={(value) => {
              setSpecialInterval(value || 0);
              setSpecialIntervalChecked(true);
            }}
            min={0}
            precision={0}
            controls={false}
            style={{ width: '100%' }}
            className={`${isAllInteger ? '' : 'inputIsNil'}`}
            onBlur={() => {
              if (!isAllInteger) {
                message.error(`The Number of Samples must be an integer multiple of ${samplingInterval} minutes`);
              }
            }}
          />
        </Form.Item> */}
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                {intl.formatMessage(settingsMessages.detectionType)}
              </div>
              <Popover
                content={intl.formatMessage(settingsMessages.detectionTypeMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            detectionTypeChecked,
            () => {
              setDetectionTypeChecked(false);
              changeDetectionTypeResetData(['positive']);
              setDetectionType(['positive']);
            },
          )}
        >
          <Checkbox.Group
            value={detectionType}
            options={detectionTypeList}
            onChange={(detectionType) => {
              if (detectionType.length > 0) {
                changeDetectionTypeResetData(detectionType);
                setDetectionType(detectionType);
                setDetectionTypeChecked(true);
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel(
            'Alert thresholds',
            thresholdAlertChecked ||
              thresholdNoAlertChecked ||
              thresholdNoAlertUpperChecked ||
              thresholdNegativeChecked ||
              thresholdNoAlertNegativeChecked ||
              thresholdNoAlertNegativeUpperChecked ||
              rougeValueLChecked ||
              rougeValueSChecked,
            () => {
              setThresholdAlertChecked(false);
              setThresholdNoAlertChecked(false);
              setThresholdNoAlertUpperChecked(false);
              setThresholdNegativeChecked(false);
              setThresholdNoAlertNegativeChecked(false);
              setThresholdNoAlertNegativeUpperChecked(false);
              setRougeValueLChecked(false);
              setRougeValueSChecked(false);
              setThresholdAlertLowerBound('None');
              setThresholdAlertUpperBound('None');
              setThresholdAlertLowerBoundViewVal('None');
              setThresholdAlertUpperBoundViewVal('None');
              setThresholdNoAlertLowerBound('None');
              setThresholdNoAlertUpperBound('None');
              setThresholdNoAlertLowerBoundViewVal('None');
              setThresholdNoAlertUpperBoundViewVal('None');
              setThresholdAlertLowerBoundNegative('None');
              setThresholdAlertUpperBoundNegative('None');
              setThresholdAlertLowerBoundNegativeViewVal('None');
              setThresholdAlertUpperBoundNegativeViewVal('None');
              setThresholdNoAlertLowerBoundNegative('None');
              setThresholdNoAlertUpperBoundNegative('None');
              setThresholdNoAlertLowerBoundNegativeViewVal('None');
              setThresholdNoAlertUpperBoundNegativeViewVal('None');
              setRougeValueL('None');
              setRougeValueS('None');
            },
          )}
        >
          {isPositive && (
            <div style={{ marginTop: 2 }}>
              <div className="bold">{intl.formatMessage(appFieldsMessages.positiveAnomalyDetection)}:</div>
              <div className="flex-row flex-center-align" style={{ marginTop: 8, position: 'relative' }}>
                {renderLabel(
                  <div className="flex-row flex-center-align" style={{ width: 160 }}>
                    <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                      {`${intl.formatMessage(appFieldsMessages.alertThreshold)} (>)`}
                    </div>
                    <Popover
                      content={intl.formatMessage(settingsMessages.positiveAlertThresholdTypeMessage)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <QuestionCircleOutlined /> :
                    </Popover>
                  </div>,
                  thresholdAlertChecked,
                  () => {
                    setThresholdAlertChecked(false);
                    setThresholdAlertLowerBound('None');
                    setThresholdAlertLowerBoundViewVal('None');
                  },
                  true,
                )}
                <AntdInput
                  size="small"
                  value={thresholdAlertLowerBoundViewVal}
                  onChange={({ target: { value } }) => {
                    setThresholdAlertLowerBound(value);
                    setThresholdAlertLowerBoundViewVal(value);
                    setThresholdAlertChecked(true);
                  }}
                  onBlur={({ target: { value } }) => {
                    setThresholdAlertLowerBoundViewVal(handleInputBlue({ value }));
                  }}
                />
              </div>
              <div className="flex-row flex-center-align" style={{ marginTop: 8, position: 'relative' }}>
                {renderLabel(
                  <div className="flex-row flex-center-align" style={{ width: 160 }}>
                    <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                      {`${intl.formatMessage(appFieldsMessages.noAlertMin)}`}
                    </div>
                    <Popover
                      content={intl.formatMessage(settingsMessages.positiveNoAlertMinMessage)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <QuestionCircleOutlined /> :
                    </Popover>
                  </div>,
                  thresholdNoAlertChecked,
                  () => {
                    setThresholdNoAlertChecked(false);
                    setThresholdNoAlertLowerBound('None');
                    setThresholdNoAlertLowerBoundViewVal('None');
                  },
                  true,
                )}
                <AntdInput
                  size="small"
                  value={thresholdNoAlertLowerBoundViewVal}
                  onChange={({ target: { value } }) => {
                    setThresholdNoAlertChecked(true);
                    setThresholdNoAlertLowerBound(value);
                    setThresholdNoAlertLowerBoundViewVal(value);
                  }}
                  onBlur={({ target: { value } }) => {
                    setThresholdNoAlertLowerBoundViewVal(handleInputBlue({ value }));
                  }}
                />
              </div>
              <div className="flex-row flex-center-align" style={{ marginTop: 8, position: 'relative' }}>
                {renderLabel(
                  <div className="flex-row flex-center-align" style={{ width: 160 }}>
                    <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                      {`${intl.formatMessage(appFieldsMessages.noAlertMax)}`}
                    </div>
                    <Popover
                      content={intl.formatMessage(settingsMessages.positivenNoAlertMaxMessage)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <QuestionCircleOutlined /> :
                    </Popover>
                  </div>,
                  thresholdNoAlertUpperChecked,
                  () => {
                    setThresholdNoAlertUpperChecked(false);
                    setThresholdNoAlertUpperBound('None');
                    setThresholdNoAlertUpperBoundViewVal('None');
                  },
                  true,
                )}
                <AntdInput
                  size="small"
                  value={thresholdNoAlertUpperBoundViewVal}
                  onChange={({ target: { value } }) => {
                    setThresholdNoAlertUpperChecked(true);
                    setThresholdNoAlertUpperBound(value);
                    setThresholdNoAlertUpperBoundViewVal(value);
                  }}
                  onBlur={({ target: { value } }) => {
                    setThresholdNoAlertUpperBoundViewVal(handleInputBlue({ value }));
                  }}
                />
              </div>
            </div>
          )}
          {isNegative && (
            <div style={{ marginTop: isBoth ? 16 : 2 }}>
              <div className="bold">{intl.formatMessage(appFieldsMessages.negativeAnomalyDetection)}: </div>
              <div className="flex-row flex-center-align" style={{ marginTop: 8, position: 'relative' }}>
                {renderLabel(
                  <div className="flex-row flex-center-align" style={{ width: 160 }}>
                    <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                      {`${intl.formatMessage(appFieldsMessages.alertThreshold)} (<)`}
                    </div>
                    <Popover
                      content={intl.formatMessage(settingsMessages.negativeAlertThresholdMessage)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <QuestionCircleOutlined /> :
                    </Popover>
                  </div>,
                  thresholdNegativeChecked,
                  () => {
                    setThresholdNegativeChecked(false);
                    setThresholdAlertUpperBoundNegative('None');
                    setThresholdAlertUpperBoundNegativeViewVal('None');
                  },
                  true,
                )}
                <AntdInput
                  size="small"
                  value={thresholdAlertUpperBoundNegativeViewVal}
                  onChange={({ target: { value } }) => {
                    setThresholdAlertUpperBoundNegative(value);
                    setThresholdAlertUpperBoundNegativeViewVal(value);
                    setThresholdNegativeChecked(true);
                  }}
                  onBlur={({ target: { value } }) => {
                    setThresholdAlertUpperBoundNegativeViewVal(handleInputBlue({ value }));
                  }}
                />
              </div>
              <div className="flex-row flex-center-align" style={{ marginTop: 8, position: 'relative' }}>
                {renderLabel(
                  <div className="flex-row flex-center-align" style={{ width: 160 }}>
                    <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                      {`${intl.formatMessage(appFieldsMessages.noAlertMin)}`}
                    </div>
                    <Popover
                      content={intl.formatMessage(settingsMessages.negativeNoAlertMinMessage)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <QuestionCircleOutlined /> :
                    </Popover>
                  </div>,
                  thresholdNoAlertNegativeChecked,
                  () => {
                    setThresholdNoAlertNegativeChecked(false);
                    setThresholdNoAlertLowerBoundNegative('None');
                    setThresholdNoAlertLowerBoundNegativeViewVal('None');
                  },
                  true,
                )}
                <AntdInput
                  size="small"
                  value={thresholdNoAlertLowerBoundNegativeViewVal}
                  onChange={({ target: { value } }) => {
                    setThresholdNoAlertLowerBoundNegative(value);
                    setThresholdNoAlertLowerBoundNegativeViewVal(value);
                    setThresholdNoAlertNegativeChecked(true);
                  }}
                  onBlur={({ target: { value } }) => {
                    setThresholdNoAlertLowerBoundNegativeViewVal(handleInputBlue({ value }));
                  }}
                />
              </div>
              <div className="flex-row flex-center-align" style={{ marginTop: 8, position: 'relative' }}>
                {renderLabel(
                  <div className="flex-row flex-center-align" style={{ width: 160 }}>
                    <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                      {`${intl.formatMessage(appFieldsMessages.noAlertMax)}`}
                    </div>
                    <Popover
                      content={intl.formatMessage(settingsMessages.negativeNoAlertMaxMessage)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <QuestionCircleOutlined /> :
                    </Popover>
                  </div>,
                  thresholdNoAlertNegativeUpperChecked,
                  () => {
                    setThresholdNoAlertNegativeUpperChecked(false);
                    setThresholdNoAlertUpperBoundNegative('None');
                    setThresholdNoAlertUpperBoundNegativeViewVal('None');
                  },
                  true,
                )}
                <AntdInput
                  size="small"
                  value={thresholdNoAlertUpperBoundNegativeViewVal}
                  onChange={({ target: { value } }) => {
                    setThresholdNoAlertUpperBoundNegative(value);
                    setThresholdNoAlertUpperBoundNegativeViewVal(value);
                    setThresholdNoAlertNegativeUpperChecked(true);
                  }}
                  onBlur={({ target: { value } }) => {
                    setThresholdNoAlertUpperBoundNegativeViewVal(handleInputBlue({ value }));
                  }}
                />
              </div>
            </div>
          )}
          <div style={{ marginTop: 16 }}>
            <div className="flex-row flex-center-align">
              <div className="bold" style={{ marginRight: 4, flexShrink: 0 }}>
                {intl.formatMessage(settingsMessages.ignoreThresholds)}
              </div>
              <Popover
                content={intl.formatMessage(settingsMessages.ignoreThresholdsMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined /> :
              </Popover>
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8, position: 'relative' }}>
              {renderLabel(
                <span className="bold" style={{ width: 160, flexShrink: 0 }}>
                  {`${intl.formatMessage(appFieldsMessages.invalidValueThreshold)} (>)`}:
                </span>,
                rougeValueLChecked,
                () => {
                  setRougeValueLChecked(false);
                  setRougeValueL('None');
                },
                true,
              )}
              <AntdInput
                size="small"
                value={rougeValueL}
                onChange={({ target: { value } }) => {
                  setRougeValueL(value);
                  setRougeValueLChecked(true);
                }}
                onBlur={({ target: { value } }) => {
                  setRougeValueL(handleInputBlue({ value }));
                }}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8, position: 'relative' }}>
              {renderLabel(
                <span className="bold" style={{ width: 160, flexShrink: 0 }}>
                  {`${intl.formatMessage(appFieldsMessages.invalidValueThreshold)} (<)`}:
                </span>,
                rougeValueSChecked,
                () => {
                  setRougeValueSChecked(false);
                  setRougeValueS('None');
                },
                true,
              )}
              <AntdInput
                size="small"
                value={rougeValueS}
                onChange={({ target: { value } }) => {
                  setRougeValueS(value);
                  setRougeValueSChecked(true);
                }}
                onBlur={({ target: { value } }) => {
                  setRougeValueS(handleInputBlue({ value }));
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          label={renderLabel(intl.formatMessage(appFieldsMessages.patternName), patternNameChecked, () => {
            setPatternNameChecked(false);
            setPatternNameHigher('');
            setPatternNameLower('');
          })}
        >
          <div>
            <div className="flex-row flex-center-align">
              <div style={{ width: 60, flexShrink: 0 }}>Positive:</div>
              <AntdInput
                size="small"
                value={patternNameHigher}
                onChange={(e) => {
                  setPatternNameChecked(true);
                  setPatternNameHigher(e.target.value);
                }}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 4 }}>
              <div style={{ width: 60, flexShrink: 0 }}>Negative:</div>
              <AntdInput
                size="small"
                value={patternNameLower}
                onChange={(e) => {
                  setPatternNameChecked(true);
                  setPatternNameLower(e.target.value);
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.KPI)}</div>
              <Popover content={intl.formatMessage(settingsMessages.KPIMessage)} mouseEnterDelay={0.3} placement="top">
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            isKPIChecked,
            () => {
              setIsKPIChecked(false);
              setIsKPI(false);
            },
          )}
        >
          <Checkbox
            checked={isKPI}
            onChange={() => {
              setIsKPI(!isKPI);
              setIsKPIChecked(true);
            }}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 4 }}>{intl.formatMessage(eventMessages.escalateIncident)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.escalateIncidentMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            escalateIncidentChecked,
            () => {
              setEscalateIncidentChecked(false);
              setEscalateIncident([]);
            },
          )}
        >
          <AntdSelect
            size="small"
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            maxTagCount={2}
            className="ignore-tabs"
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: 150 }}
            value={escalateIncident}
            onChange={(value) => {
              if (value && value.length > 0) {
                setIsKPI(true);
                setIsKPIChecked(true);
              }
              setEscalateIncident(value || []);
              setEscalateIncidentChecked(true);
            }}
            options={componentNameList}
            dropdownRender={(menu) => (
              <>
                <div style={{ padding: '4px 12px 0px', display: 'flex' }}>
                  <Checkbox
                    indeterminate={
                      (escalateIncident || []).length !== 0 &&
                      (escalateIncident || []).length !== componentNameList.length
                    }
                    checked={(escalateIncident || []).length === componentNameList.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setIsKPI(true);
                        setIsKPIChecked(true);
                      }
                      setEscalateIncident(e.target.checked ? allValues : []);
                      setEscalateIncidentChecked(true);
                    }}
                  />
                  <div className="bold" style={{ paddingLeft: '4px' }}>{`Selected (${
                    (escalateIncident || []).length
                  })`}</div>
                </div>
                <Divider style={{ margin: '4px 0' }} />
                {menu}
              </>
            )}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.kpiThreshold)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.kpiThresholdMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            kpiDurationThresholdChecked,
            () => {
              setKpiDurationThresholdChecked(false);
              setKpiDurationThreshold('');
            },
          )}
        >
          <AntdInput
            value={kpiDurationThreshold}
            onChange={(e) => {
              setKpiDurationThreshold(e.target.value);
              setKpiDurationThresholdChecked(true);
            }}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.dampeningPeriod)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.dampeningPeriodMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            anomalyDampeningChecked,
            () => {
              setAnomalyDampeningChecked(false);
              setAnomalyDampening('');
            },
          )}
        >
          <AntdInput
            value={anomalyDampening}
            onChange={(e) => {
              setAnomalyDampening(e.target.value);
              setAnomalyDampeningChecked(true);
            }}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.nearConstantDetection)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.nearConstantDetectionMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            enableBaselineNearConstanceChecked,
            () => {
              setEnableBaselineNearConstanceChecked(false);
              setEnableBaselineNearConstance(false);
            },
          )}
        >
          <Checkbox
            checked={enableBaselineNearConstance}
            onChange={() => {
              setEnableBaselineNearConstance(!enableBaselineNearConstance);
              setEnableBaselineNearConstanceChecked(true);
            }}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 4 }}>{intl.formatMessage(appFieldsMessages.ignore)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.ignoreMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            ignoreListChecked,
            () => {
              setIgnoreListChecked(false);
              setIgnoreList([]);
            },
          )}
        >
          <AntdSelect
            size="small"
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            maxTagCount={2}
            className="ignore-tabs"
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: 150 }}
            value={ignoreList}
            onChange={(value) => {
              setIgnoreList(value || []);
              setIgnoreListChecked(true);
            }}
            options={componentNameList}
            dropdownRender={(menu) => (
              <>
                <div style={{ padding: '4px 12px 0px', display: 'flex' }}>
                  <Checkbox
                    indeterminate={
                      (ignoreList || []).length !== 0 && (ignoreList || []).length !== componentNameList.length
                    }
                    checked={(ignoreList || []).length === componentNameList.length}
                    onChange={(e) => {
                      setIgnoreList(e.target.checked ? allValues : []);
                      setIgnoreListChecked(true);
                    }}
                  />
                  <div className="bold" style={{ paddingLeft: '4px' }}>{`Selected (${(ignoreList || []).length})`}</div>
                </div>
                <Divider style={{ margin: '4px 0' }} />
                {menu}
              </>
            )}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.computeDifference)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.computeDifferenceMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            computeDifferenceChecked,
            () => {
              setComputeDifferenceChecked(false);
              setComputeDifference(false);
            },
          )}
        >
          <Checkbox
            checked={computeDifference}
            onChange={() => {
              setComputeDifference(!computeDifference);
              setComputeDifferenceChecked(true);
            }}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.enableMissingDataFilling)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.enableMissingDataFillingMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            fillZeroChecked,
            () => {
              setFillZeroChecked(false);
              setFillZero(false);
            },
          )}
        >
          <Checkbox
            checked={fillZero}
            onChange={() => {
              setFillZero(!fillZero);
              setFillZeroChecked(true);
            }}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel(
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 4 }}>{intl.formatMessage(settingsMessages.metricType)}</div>
              <Popover
                content={intl.formatMessage(settingsMessages.metricTypeMessage)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>,
            metricTypeChecked,
            () => {
              setMetricTypeChecked(false);
              setMetricType('');
            },
          )}
        >
          <AntdSelect
            showSearch
            style={{ width: '100%' }}
            allowClear
            size="small"
            options={R.map((item) => ({ label: item, value: item }), possibleMetricType)}
            value={metricType || ''}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: 150 }}
            onChange={(e) => {
              setMetricType(e || '');
              setMetricTypeChecked(true);
            }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            optionLabelProp="label"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

type PropsRenderMetricStats = {
  style: Object,
  intl: Object,
  stats: Object,
};
const RenderMetricStats = ({ style, intl, stats }: PropsRenderMetricStats) => {
  // eslint-disable-next-line
  const DomTooltip = (
    <div className="flex-col" style={{ width: 150, maxWidth: 150, wordBreak: 'break-all' }}>
      <div style={{ marginBottom: 8 }}>
        <span className="bold light-label" style={{ marginRight: 8 }}>
          {intl.formatMessage(appFieldsMessages.avg)}:
        </span>
        <span>{isFinite(stats[1]) ? stats[1] : 'N/A'}</span>
      </div>
      <div style={{ marginBottom: 8 }}>
        <span className="bold light-label" style={{ marginRight: 8 }}>
          {intl.formatMessage(appFieldsMessages.min)}:
        </span>
        <span>{isFinite(stats[0]) ? stats[0] : 'N/A'}</span>
      </div>
      <div style={{ marginBottom: 8 }}>
        <span className="bold light-label" style={{ marginRight: 8 }}>
          {intl.formatMessage(appFieldsMessages.max)}:
        </span>
        <span>{isFinite(stats[2]) ? stats[2] : 'N/A'}</span>
      </div>
    </div>
  );

  const scientificNumbers = (number, places = 6) => {
    if (typeof number !== 'number') return;
    if (number === 0) {
      // eslint-disable-next-line consistent-return
      return numeral(number).format('0.0');
    } else if (number > 999999) {
      // eslint-disable-next-line consistent-return
      return Number.parseFloat(number).toExponential(places).replace('e', 'E');
    } else {
      // eslint-disable-next-line consistent-return
      return numeral(number).format('0.0');
    }
  };

  return (
    <Popover
      title={null}
      content={
        <div
          className="full-width flex-col flex-center-justify"
          style={{ ...(style || {}), padding: '4px 0px', width: 300 }}
        >
          <div className="flex-row flex-center-justify">
            <span className="bold light-label" style={{ marginRight: 8 }}>
              {intl.formatMessage(appFieldsMessages.avg)}:
            </span>
            <Popover
              title={null}
              content={isFinite(stats[1]) ? numeral(stats[1]).format('0.0') : 'N/A'}
              mouseEnterDelay={0.3}
            >
              <span>{isFinite(stats[1]) ? scientificNumbers(stats[1]) : 'N/A'}</span>
            </Popover>
          </div>
          <Slider
            style={{ marginTop: 0 }}
            range
            step={null}
            tooltipVisible={false}
            marks={{
              0: {
                style: { transform: 'translateX(-10%)' },
                label: (
                  <Popover
                    title={null}
                    content={isFinite(stats[0]) ? numeral(stats[0]).format('0.0') : 'N/A'}
                    mouseEnterDelay={0.3}
                  >
                    {isFinite(stats[0]) ? scientificNumbers(stats[0]) : 'N/A'}
                  </Popover>
                ),
              },
              100: {
                style: { transform: 'translateX(-90%)' },
                label: (
                  <Popover
                    title={null}
                    content={isFinite(stats[2]) ? numeral(stats[2]).format('0.0') : 'N/A'}
                    mouseEnterDelay={0.3}
                  >
                    {isFinite(stats[2]) ? scientificNumbers(stats[2]) : 'N/A'}
                  </Popover>
                ),
              },
            }}
            defaultValue={[0, 100]}
          />
        </div>
      }
      mouseEnterDelay={0.3}
    >
      <span>{isFinite(stats[1]) ? scientificNumbers(stats[1]) : 'N/A'}</span>
    </Popover>
  );
};

const MetricSetting = injectIntl(MetricSettingCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials, userInfo } = state.auth;
    const { timezoneOffset, userList } = state.app;
    const { instanceSettings, excludedMetrics } = state.settings;
    const instanceMetricList = get(instanceSettings, ['metricList'], []);
    const metricListCount = get(instanceSettings, ['metricListCount'], 0);
    const possibleMetricType = get(instanceSettings, ['possibleMetricType'], []);
    const metricStatsMap = get(instanceSettings, ['metricStatsMap'], {});
    const patternIdGenerationRule = get(instanceSettings, ['patternIdGenerationRule'], 0);
    const patternNameRegex = get(instanceSettings, ['patternNameRegex'], '');
    return {
      location,
      credentials,
      userInfo,
      userList,
      timezoneOffset,
      instanceMetricList,
      metricListCount,
      possibleMetricType,
      metricStatsMap,
      excludedMetrics,
      patternIdGenerationRule,
      patternNameRegex,
    };
  },
  { push, replace, updateLastActionInfo, loadProjectMetricSettings, saveProjectMetricSettings, loadProjectInfo },
)(MetricSetting);
