/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import { injectIntl } from 'react-intl';
import { debounce, get } from 'lodash';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { HomeOutlined } from '@ant-design/icons';
import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Select,
  Tooltip,
  message,
  Spin,
  Pagination,
} from 'antd';
import { NavLink } from 'react-router-dom';

import fetchGet from '../../common/apis/fetchGet';
import fetchPost from '../../common/apis/fetchPost';
import getEndpoint from '../../common/apis/getEndpoint';
import { BaseUrls } from '../app/Constants';
import {
  buildLocation,
  buildUrl,
  Defaults,
  getLoadStatus,
  GlobalParse,
  parseLocation,
  timeScopeControl,
} from '../../common/utils';
import {
  ActionTypes as AppActionTypes,
  createLoadAction,
  createSetAction,
  showAppAlert,
  showAppLoader,
  updateLastActionInfo,
} from '../../common/app/actions';
import { State } from '../../common/types';
import { ActionTypes } from '../../common/dashboard/actions';
import { AutoSizer, Container, Modal, Popover } from '../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMenusMessages, appMessages } from '../../common/app/messages';

import GlobalCronSelectModal from './components/GlobalCronSelectModal';
import GlobalHealthSystemList from './components/GlobalHealthSystemList';
import { DashboardMessages } from '../../common/dashboard/messages';
import InsightQueryBoxModal from './components/InsightQueryBoxModal';
import GlobalHealthChartView from './GlobalHealthChartView';
import { eventMessages } from '../../common/metric/messages';

type Props = {
  location: Object,
  loadStatus: Object,
  intl: Object,
  push: Function,
  replace: Function,
  credentials: Object,
  userInfo: Object,
  userList: Array<Object>,
  // eslint-disable-next-line
  showAppAlert: Function,
  showAppLoader: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  createSetAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,

  globalInfo: Array<Object>,
  allSystemConfigs: Array<Object>,
  globalHealthSummary: Array<Object>,
};

class GlobalHealthViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'dashboard_global_health_view';

    const { intl, location } = props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;

    const allSystemConfigs = props.allSystemConfigs || [];
    const healthSystemIdList = R.map((item) => item.id, allSystemConfigs);
    this.state = {
      startTimeObj: moment.utc(startTime, Defaults.DateFormat),
      endTimeObj: moment.utc(endTime, Defaults.DateFormat),
      endTimeOpen: false,
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      systemLoading: {},
      isAutoReload: false,
      allSystemConfigs,
      allSystemConfigsMap: R.fromPairs(R.map((item) => [item.id, item], allSystemConfigs)),
      globalHealthSummary: props.globalHealthSummary,

      // filter and sort
      selectSystems: healthSystemIdList,
      localSelectSystems: healthSystemIdList,
      systemSort: 'none',
      eventList: R.filter((item) => healthSystemIdList.includes(item.id), allSystemConfigs),
      fetchingValue: '',

      showTimeSelectModal: false,
      selectStartTimestamp: null,
      selectEndTimestamp: null,

      hideChartsData: true,

      updateModelLoading: false,
      orderLoading: false,
      orderListDATA: [],
      healthSystemIdList,

      showInsightQueryBox: false,
      logProjects: [],
      queryParams: {},

      favoriteList: [],
      systemListOptionsFlag: false,
    };
    this.systemSortOptions = [
      { value: 'none', label: intl.formatMessage(appFieldsMessages.custom) },
      { value: 'nameAsc', label: intl.formatMessage(DashboardMessages.systemNameAsc) },
      { value: 'nameDesc', label: intl.formatMessage(DashboardMessages.systemNameDesc) },
      { value: 'scoreAsc', label: intl.formatMessage(DashboardMessages.systemHealthScoreAsc) },
      { value: 'scoreDesc', label: intl.formatMessage(DashboardMessages.systemHealthScoreDesc) },
    ];
    this.systemListOptions = null;
  }

  componentDidMount() {
    this.reloadData(this.props, { isForceReload: true });

    // this.timer = setInterval(() => {
    //   this.refreshTime();
    // }, 5 * 60 * 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      const nextQuery = parseLocation(nextProps.location);
      const query = parseLocation(this.props.location);
      if (
        nextQuery.refreshTime !== query.refreshTime ||
        nextQuery.forceRefreshTime !== query.forceRefreshTime ||
        nextQuery.environmentId !== query.environmentId ||
        nextQuery.customerName !== query.customerName
      ) {
        const isAutoReload = nextQuery.refreshTime !== query.refreshTime;
        const isForceReload = nextQuery.forceRefreshTime !== query.forceRefreshTime;
        this.reloadData(nextProps, { isAutoReload, isForceReload, useStateConfig: isForceReload });
      }
    } else if (nextProps.globalHealthSummary !== this.props.globalHealthSummary) {
      this.reorderDisplay(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  refreshTime(force) {
    const { location, push } = this.props;
    const query = parseLocation(location);
    const { endTime } = query;
    const selectStartTimestamp = moment.utc(endTime, Defaults.DateFormat).startOf('day').valueOf();
    if (force || selectStartTimestamp >= moment.utc().startOf('day').valueOf()) {
      const refreshTime = moment.utc().valueOf();
      push(buildLocation(location.pathname, {}, { ...query, refreshTime }));
    }
  }

  @autobind
  getOrderList({ props, eventList }) {
    const { credentials, userInfo, location, allSystemConfigs } = props;
    const { customerName } = parseLocation(location);
    const { allSystemConfigs: newAllSystemConfigs } = this.state;
    fetchGet(getEndpoint('order'), {
      ...credentials,
      targetUser: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : undefined,
    })
      .then((data) => {
        const { success, message: msg, order } = data;
        if (success || success === undefined) {
          let orederObj = {};
          try {
            orederObj = JSON.parse(order);
          } catch (e) {
            // error
          }

          let { healthChartOrder, healthChartFavoriteOrder } = orederObj;

          const favoriteList = [];
          if (healthChartFavoriteOrder) {
            try {
              healthChartFavoriteOrder = JSON.parse(healthChartFavoriteOrder);
            } catch (e) {
              // error
            }
            const { DATA } = healthChartFavoriteOrder;
            R.addIndex(R.forEach)((item, index) => {
              let { l } = item;
              try {
                l = JSON.parse(l);
              } catch (e) {
                l = {};
              }
              favoriteList.push(l.systemName);
            }, DATA);
            this.setState({
              favoriteList,
            });
          }
          if (healthChartOrder) {
            try {
              healthChartOrder = JSON.parse(healthChartOrder);
            } catch (e) {
              // error
            }

            const { DATA } = healthChartOrder;
            let newEventList = this.setOrderList({ DATA, eventList, flag: true });
            const newAllSystemList = this.setOrderList({ DATA, eventList: newAllSystemConfigs });
            const { newHealthSystemIdList, selectSystems } = this.setHealthSystemIdList({
              DATA,
              healthSystemIdList: R.map((item) => item.id, allSystemConfigs || []),
            });
            newEventList = this.sortData(newEventList);

            this.setState({
              eventList: newEventList,
              orderListDATA: DATA,
              healthSystemIdList: newHealthSystemIdList,
              allSystemConfigs: newAllSystemList,
              selectSystems,
              localSelectSystems: selectSystems,
            });
          } else {
            this.setState({ eventList });
          }
        } else {
          this.setState({ eventList });
          console.error(msg);
        }
        this.setState({ orderLoading: false });
      })
      .catch((e) => {
        console.error(e.message || String(e));
        this.setState({ orderLoading: false, eventList });
      });
  }

  @autobind
  setOrderList({ DATA, eventList, flag = false }) {
    const newItemsRenderList = [];
    let eventListLength = eventList.length;
    R.addIndex(R.forEach)((item, index) => {
      let { l } = item;
      try {
        l = JSON.parse(l);
      } catch (e) {
        l = {};
      }
      const findItem = R.find((findOnly) => findOnly.id === l.systemName, eventList);
      if (findItem) {
        eventListLength -= 1;
        findItem.order = eventListLength;
        newItemsRenderList.push(findItem);
      }
    }, DATA);
    const removeItemsRenderList = [];
    let newItemsRenderListLength = eventList.length - newItemsRenderList.length;
    R.forEach((item) => {
      const findItem = R.find((findOnly) => findOnly.id === item.id, newItemsRenderList);
      if (!findItem) {
        removeItemsRenderList.push(item);
      }
    }, eventList);
    R.forEach((item) => {
      newItemsRenderListLength -= 1;
      item.order = newItemsRenderListLength;
    }, removeItemsRenderList);

    if (flag) {
      return newItemsRenderList;
    }
    return [...newItemsRenderList, ...removeItemsRenderList];
  }

  @autobind
  setHealthSystemIdList({ DATA, healthSystemIdList }) {
    const newItemsRenderList = [];
    R.forEach((item) => {
      let { l } = item;
      try {
        l = JSON.parse(l);
      } catch (e) {
        l = {};
      }
      const findItem = R.find((findOnly) => findOnly === l.systemName, healthSystemIdList);
      if (findItem) {
        newItemsRenderList.push(findItem);
      }
    }, DATA);
    let removeItemsRenderList = [];
    R.forEach((item) => {
      const findItem = R.find((findOnly) => findOnly === item, newItemsRenderList);
      if (!findItem) {
        removeItemsRenderList.push(item);
      }
    }, healthSystemIdList);
    removeItemsRenderList = R.map((item) => ({ systemId: item }), removeItemsRenderList);
    // removeItemsRenderList = R.sortWith([R.ascend(R.prop('systemName'))], removeItemsRenderList);
    removeItemsRenderList = R.map((item) => item.systemId, removeItemsRenderList);
    return {
      newHealthSystemIdList: [...newItemsRenderList, ...removeItemsRenderList],
      selectSystems: newItemsRenderList,
    };
  }

  @autobind
  reloadData(props, params) {
    const { allSystemConfigs: propsAllSystemConfigs, globalHealthSummary } = props;
    const { createLoadAction, location, isAdmin, userInfo } = props;
    const { environmentId, startTime, endTime, customerName } = parseLocation(location);
    const { isForceReload, isAutoReload, useStateConfig } = params;
    const { allSystemConfigs: stateAllSystemConfigs, systemLoading } = this.state;
    const { isLocalAdmin } = userInfo;
    const allSystemConfigs = useStateConfig ? stateAllSystemConfigs : propsAllSystemConfigs;

    if (
      (((isAdmin || isLocalAdmin) && customerName) || !isAdmin || !isLocalAdmin) &&
      environmentId &&
      startTime &&
      endTime
    ) {
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();

      // load system health scores if need
      const newGlobalHealthSummary = [];
      R.forEach((system) => {
        const healthSummary = R.find(R.propEq('id', system.id), globalHealthSummary);
        if (healthSummary) {
          newGlobalHealthSummary.push(healthSummary);
        } else {
          newGlobalHealthSummary.push({
            id: system.id,
            systemId: system.id,
            systemName: system.systemName,
          });
        }
      }, allSystemConfigs);

      const allSystemConfigsMap = R.fromPairs(R.map((item) => [item.id, item], allSystemConfigs));
      const healthSystemIdList = R.map((item) => item.id, allSystemConfigs);
      const needLoadScoreIds =
        isForceReload || isAutoReload
          ? healthSystemIdList
          : R.difference(
              healthSystemIdList,
              R.map((item) => item.id, globalHealthSummary),
            );
      const newSystemLoading = R.clone(systemLoading);
      if (!isAutoReload) {
        R.forEach((systemId) => {
          newSystemLoading[systemId] = true;
        }, needLoadScoreIds);
      }

      this.setState(
        {
          isAutoReload,
          systemLoading: newSystemLoading,
          allSystemConfigs,
          allSystemConfigsMap,
          globalHealthSummary: newGlobalHealthSummary,
          selectSystems: healthSystemIdList,
          localSelectSystems: healthSystemIdList,
          systemSort: 'none',
          // eventList: R.filter((item) => healthSystemIdList.includes(item.id), allSystemConfigs),
          orderLoading: true,
        },
        () => {
          // this.getOrderList({
          //   props,
          //   eventList: R.filter((item) => healthSystemIdList.includes(item.id), allSystemConfigs),
          // });
          if (needLoadScoreIds.length > 0) {
            createLoadAction(
              ActionTypes.LOAD_GLOBAL_HEALTH,
              {
                isAutoReload,
                environmentId,
                customerName,
                systemIds: needLoadScoreIds,
                startTimestamp,
                endTimestamp,
              },
              this.dataLoader,
            );
          } else {
            this.setState({ orderLoading: false });
          }
        },
      );
    }
  }

  @autobind
  reorderDisplay(props) {
    const { globalHealthSummary } = props;
    const { allSystemConfigs, systemLoading } = this.state;

    const allSystemConfigsMap = R.fromPairs(R.map((item) => [item.id, item], allSystemConfigs));
    const healthSystemIdList = R.map((item) => item.id, allSystemConfigs);

    // build health list, hide loading
    const newSystemLoading = R.clone(systemLoading);
    const newGlobalHealthSummary = [];
    R.forEach((system) => {
      newSystemLoading[system.id] = false;

      const healthSummary = R.find(R.propEq('id', system.id), globalHealthSummary);
      if (healthSummary) newGlobalHealthSummary.push(healthSummary);
    }, allSystemConfigs);

    this.setState(
      {
        systemLoading: newSystemLoading,
        allSystemConfigs,
        allSystemConfigsMap,
        globalHealthSummary: newGlobalHealthSummary,
        selectSystems: healthSystemIdList,
        localSelectSystems: healthSystemIdList,
        systemSort: 'none',
        // eventList: R.filter((item) => healthSystemIdList.includes(item.id), allSystemConfigs),
      },
      () => {
        this.getOrderList({
          props,
          eventList: R.filter((item) => healthSystemIdList.includes(item.id), allSystemConfigs),
        });
        this.setState({ isAutoReload: false });
      },
    );
  }

  @autobind
  handleEnvironmentChange(environmentId) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, environmentId }));
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        startTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        endTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'add'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        endTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleStartOpenChange(open) {
    if (!open) {
      this.setState({ endTimeOpen: true });
    }
  }

  @autobind
  handleEndOpenChange(open) {
    this.setState({ endTimeOpen: open });
  }

  @autobind
  handleCustomerNameChange(customerName) {
    const { location, showAppLoader } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    setTimeout(() => {
      const query = parseLocation(location);
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { ...query, customerName, environmentId: undefined },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  handleRefreshClick() {
    const { location, replace } = this.props;
    let query = parseLocation(location);
    const { startTimeObj, endTimeObj } = this.state;
    const startTime = startTimeObj.format(Defaults.DateFormat);
    const endTime = endTimeObj.format(Defaults.DateFormat);

    // update start/end time if changed
    if (startTime !== query.startTime || endTime !== query.endTime) {
      query = { ...query, startTime, endTime };
    }
    // force refresh
    replace(buildLocation(location.pathname, {}, { ...query, forceRefreshTime: moment.utc().valueOf() }));
  }

  @autobind
  handleUpdateGlobalViewClick() {
    const { location } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;
    if (startTime && endTime) {
      const selectStartTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      const selectEndTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      this.setState({ showTimeSelectModal: true, selectStartTimestamp, selectEndTimestamp });
    }
  }

  @autobind
  onCloseTimeSelect(props) {
    const { intl, location, credentials, updateLastActionInfo } = this.props;
    const { startTimestamp, endTimestamp, ...rest } = props || {};
    this.setState({ updateModelLoading: true });
    if (startTimestamp && endTimestamp) {
      updateLastActionInfo();
      GlobalParse.updateGlobalView({
        intl,
        location,
        state: {
          selectStartTimestamp: startTimestamp,
          selectEndTimestamp: endTimestamp,
          ...rest,
        },
        credentials,
        callback: ({ loading, model }) => {
          if (loading) {
            this.setState({ updateModelLoading: false });
          }
          if (model) {
            this.setState({ showTimeSelectModal: false });
          }
        },
      });
    } else {
      this.setState({ showTimeSelectModal: false, updateModelLoading: false });
    }
  }

  @autobind
  onRerunKbPrediction(params) {
    const { intl, credentials } = this.props;
    fetchPost(getEndpoint('rerun-knowledgebase-prediction', 2), { ...credentials, ...params })
      .then((res) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ updateModelLoading: false, showTimeSelectModal: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  }

  @autobind
  handleSelectSystemsChange(selectSystems) {
    this.setState({ selectSystems }, () => {
      const { allSystemConfigs } = this.state;
      let eventList = R.filter((item) => selectSystems.includes(item.id), allSystemConfigs);
      eventList = this.setOrderList({ DATA: this.state.orderListDATA, eventList });
      eventList = this.sortData(eventList);
      this.setState({ eventList });
    });
  }

  @autobind
  handleSystemsSortChange(systemSort) {
    this.setState({ systemSort }, () => {
      const { selectSystems, allSystemConfigs } = this.state;
      let eventList = R.filter((item) => selectSystems.includes(item.id), allSystemConfigs);
      eventList = this.setOrderList({ DATA: this.state.orderListDATA, eventList });
      eventList = this.sortData(eventList);
      this.setState({ eventList });
    });
  }

  @autobind
  sortData(eventList) {
    const { systemSort, globalHealthSummary } = this.state;

    // sort by
    let sortList = eventList;
    sortList = R.map((item) => {
      const systemHealthInfo = R.find((health) => health.systemId === item.id, globalHealthSummary);
      return { ...item, avgHealthScore: systemHealthInfo.avgHealthScore || 100 };
    }, sortList);
    switch (systemSort) {
      case 'none':
        let favorites = R.filter((n) => n.favorite, sortList);
        let systems = R.filter((n) => !n.favorite, sortList);
        favorites = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('systemName')))])(favorites);
        systems = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('systemName')))])(systems);
        sortList = [...favorites, ...systems];
        break;
      case 'nameAsc':
        sortList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('systemName')))])(sortList);
        break;
      case 'nameDesc':
        sortList = R.sortWith([R.descend(R.compose(R.toLower, R.prop('systemName')))])(sortList);
        break;
      case 'scoreAsc':
        sortList = R.sortWith([R.ascend(R.prop('avgHealthScore'))])(sortList);
        break;
      case 'scoreDesc':
        sortList = R.sortWith([R.descend(R.prop('avgHealthScore'))])(sortList);
        break;
      default:
        break;
    }

    return sortList;
  }

  @autobind
  handleSystemConfigUpdate({ allSystemConfigs, favoriteSetting, flag = false }) {
    const { updateLastActionInfo, location, createSetAction, credentials, userInfo } = this.props;
    const params = parseLocation(location);
    const { customerName } = params;

    const { allSystemConfigs: newAllSystemConfigs } = this.state;
    const orderModelSet = R.addIndex(R.map)(
      (item, index) => ({
        l: JSON.stringify({ environmentName: item.environmentId, systemName: item.id, userName: item.owner }),
        o: index,
      }),
      allSystemConfigs,
    );
    this.setState({ orderListDATA: orderModelSet });
    const errContent = <div>Unfortunately, an error has occurred. Please try again!</div>;
    updateLastActionInfo();
    fetchPost(getEndpoint('order'), {
      ...credentials,
      targetUser: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : undefined,
      operation: 'healthChart',
      orderModelSet: JSON.stringify(orderModelSet),
    })
      .then((data) => {
        const { success } = data;
        if (success || success === undefined) {
          createSetAction(AppActionTypes.SET_INFO_SYSTEM_CONFIGS, params, {
            allSystemConfigs: newAllSystemConfigs,
          });
          if (flag) {
            message.success('Saved successfully');
          }
        } else {
          Modal.error({ content: errContent });
        }
      })
      .catch((e) => {
        Modal.error({ content: errContent });
      });

    if (flag) return;
    const favoritesOrderModelSet = R.addIndex(R.map)(
      (item, index) => ({
        l: JSON.stringify({ environmentName: item.environmentId, systemName: item.id, userName: item.owner }),
        o: index,
      }),
      favoriteSetting,
    );
    const favorites = R.map((item) => item.id, favoriteSetting || []);
    fetchPost(getEndpoint('order'), {
      ...credentials,
      targetUser: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : undefined,
      operation: 'healthChartFavorite',
      orderModelSet: JSON.stringify(favoritesOrderModelSet),
    })
      .then((data) => {
        createSetAction(AppActionTypes.SET_SYSTEM_FAVORITES, params, {
          favorites,
        });
      })
      .catch((e) => {});
  }

  @autobind
  handleFavorite(e, systemInfo) {
    e.stopPropagation();
    e.preventDefault();
    const { allSystemConfigs: allSystemConfigsState, globalHealthSummary, eventList, favoriteList } = this.state;
    const { index: oldIndex, systemId } = systemInfo;
    const newIndex = 0;
    let newFavoriteList = favoriteList;
    if (newFavoriteList.includes(systemId)) {
      newFavoriteList = R.filter((item) => item !== systemId, newFavoriteList);
    } else {
      newFavoriteList.push(systemId);
    }

    const allSystemConfigs = [];
    let removeSystemConfigs = [];
    R.forEach((item) => {
      const findItem = R.find((findOnly) => findOnly.id === item.id, eventList);
      if (findItem) {
        allSystemConfigs.push(findItem);
      } else {
        removeSystemConfigs.push(item);
      }
    }, allSystemConfigsState);
    removeSystemConfigs = R.sortWith([R.ascend(R.prop('systemName'))], removeSystemConfigs);

    const card = allSystemConfigs[oldIndex];
    let newAllSystemConfigs = update(allSystemConfigs, {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, card],
      ],
    });

    R.forEach((item) => {
      item.favorite = newFavoriteList.includes(item.systemId);
    }, newAllSystemConfigs);

    let favorites = R.filter((item) => item.favorite, newAllSystemConfigs);
    favorites = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('systemName')))])(favorites);

    let systems = R.filter((item) => !item.favorite, newAllSystemConfigs);
    systems = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('systemName')))])(systems);

    newAllSystemConfigs = [...favorites, ...systems];

    // reorder
    const settings = [];
    let systemConfigsLen = newAllSystemConfigs.length;
    R.forEach((item) => {
      systemConfigsLen -= 1;
      const newVal = { ...item, order: systemConfigsLen };
      settings.push(newVal);
    }, newAllSystemConfigs);

    const favoriteSetting = R.filter((item) => item.favorite, [...settings, ...removeSystemConfigs]);

    const allSystemConfigsMap = R.fromPairs(R.map((item) => [item.id, item], [...settings, ...removeSystemConfigs]));
    const healthSystemIdList = R.map((item) => item.id, [...settings, ...removeSystemConfigs]);
    const selectSystems = R.map((item) => item.id, settings);

    // reorder health chart
    const newGlobalHealthSummary = [];
    R.forEach(
      (system) => {
        const healthSummary = R.find(R.propEq('id', system.id), globalHealthSummary);
        if (healthSummary) newGlobalHealthSummary.push(healthSummary);
      },
      [...newAllSystemConfigs, ...removeSystemConfigs],
    );

    this.setState(
      {
        allSystemConfigs: [...settings, ...removeSystemConfigs],
        allSystemConfigsMap,
        globalHealthSummary: newGlobalHealthSummary,
        selectSystems,
        systemSort: 'none',
        eventList: R.filter((item) => selectSystems.includes(item.id), settings),
        healthSystemIdList,
        favoriteList: newFavoriteList,
      },
      () => {
        this.handleSystemConfigUpdate({ allSystemConfigs: settings, favoriteSetting });
      },
    );
  }

  @autobind
  handleSystemClick(e, systemId) {
    e.stopPropagation();
    const { push, location } = this.props;
    const query = parseLocation(location);
    push(buildUrl(BaseUrls.GlobalHealthSystem, {}, { ...query, systemId }));
  }

  @autobind
  saveSystemList() {
    const { allSystemConfigs, eventList, selectSystems } = this.state;

    const settings = [];
    let removeSystemList = [];
    R.forEach((item) => {
      const findItem = R.find((findOnly) => findOnly.id === item.id, eventList);
      if (findItem) {
        settings.push(findItem);
      } else {
        removeSystemList.push(item);
      }
    }, allSystemConfigs);
    removeSystemList = R.sortWith([R.ascend(R.prop('systemName'))], removeSystemList);
    const healthSystemIdList = R.map((item) => item.id, [...settings, ...removeSystemList]);
    const allSystemConfigsMap = R.fromPairs(R.map((item) => [item.id, item], [...settings, ...removeSystemList]));
    if (this.systemListOptions) this.systemListOptions.blur();
    this.setState(
      {
        allSystemConfigs: [...settings, ...removeSystemList],
        allSystemConfigsMap,
        healthSystemIdList,
        localSelectSystems: selectSystems,
        systemListOptionsFlag: false,
      },
      () => {
        this.handleSystemConfigUpdate({ allSystemConfigs: settings, flag: true });
      },
    );
  }

  @autobind
  handleInsightQueryConfirm(params) {
    const {
      templateId,
      projectName,
      instanceName,
      startTimeObj,
      endTimeObj,
      keyword,
      numOfCluster,
      pattern,
      duration,
      customerName,
    } = params;
    const query = {
      t: templateId,
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      keyword,
      numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
      pattern,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      duration,
      customerName,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    this.setState({ showInsightQueryBox: false });
  }

  @autobind
  onSetQueryParams(queryParams) {
    this.setState(queryParams);
  }

  @autobind
  handleHideChartData(checked) {
    this.setState({ hideChartsData: checked });
  }

  @autobind
  getFilterValue({ searchValue = '' }) {
    const { allSystemConfigsMap, healthSystemIdList } = this.state;
    return R.filter(
      (item) =>
        get(allSystemConfigsMap, [item, 'systemName'], item)
          .toLowerCase()
          .indexOf((searchValue || '').toLowerCase()) >= 0,
      healthSystemIdList,
    );
  }

  @autobind
  isCheckedAll({ searchValue, valueList }) {
    const filterValue = this.getFilterValue({ searchValue });
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  }

  render() {
    const { intl, push, location, loadStatus, userInfo, userList, globalInfo, credentials } = this.props;
    const query = parseLocation(location);
    const {
      startTimeObj,
      endTimeObj,
      endTimeOpen,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
      showTimeSelectModal,
      selectStartTimestamp,
      selectEndTimestamp,

      allSystemConfigsMap,
      globalHealthSummary,
      selectSystems,
      systemSort,
      eventList,
      fetchingValue,
      hideChartsData,
      updateModelLoading,
      orderLoading,
      healthSystemIdList,
      logProjects,

      favoriteList,
      localSelectSystems,
      systemListOptionsFlag,
    } = this.state;
    // render chart states
    const { systemLoading, isAutoReload } = this.state;

    // get system list
    const { customerName, environmentId, startTime, endTime, forceRefreshTime, refreshTime } = query;
    let globalInfoFilter = globalInfo || [];
    let environment = R.find((e) => e.id === environmentId, globalInfoFilter);
    const systemList = get(environment, 'systemList', []);
    if (globalHealthSummary && globalHealthSummary.length === 0) {
      globalInfoFilter = R.filter((e) => e.id !== environmentId, globalInfoFilter);
      environment = null;
    }

    const healthList = [];
    R.forEach((systemId) => {
      const systemHealthInfo = R.find((item) => systemId === item.systemId, globalHealthSummary) || {
        id: systemId,
        systemId,
        systemName: systemId,
      };
      healthList.push(systemHealthInfo);
    }, healthSystemIdList);

    const hasSystemLoading = R.reduce(R.or, false, R.values(systemLoading));
    const { errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);

    const viewHealthTip = window.localStorage.getItem('viewHealthTip');

    const newEventList = R.filter((item) => (hideChartsData ? item?.hasData : true), eventList);
    R.forEach((item) => {
      item.favorite = favoriteList.includes(item.systemId);
    }, newEventList);

    return (
      <Container fullHeight withGutter className="flex-col flex-min-height">
        <Container breadcrumb className="flex-row">
          <div className="flex-grow flex-row flex-center-align">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, {}))}>
                  <HomeOutlined />
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(appMenusMessages.globalHealthView)}</Breadcrumb.Item>
            </Breadcrumb>

            {!userInfo?.isReadUser && (
              <NavLink to={buildUrl(BaseUrls.SettingsIntegrations, {}, { customerName: query?.customerName })}>
                <Button
                  size="small"
                  type="primary"
                  className="tour-insight-discovery"
                  style={{ marginLeft: 16, fontSize: 14, lineHeight: '14px' }}
                >
                  Start data ingestion
                </Button>
              </NavLink>
            )}
            {!userInfo?.isReadUser && (
              <Button style={{ marginLeft: 8 }} onClick={this.handleUpdateGlobalViewClick}>
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            )}
          </div>
          <div className="flex-row flex-center-align">
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.startDate)}</span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={startTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleStartTimeChange}
              onOpenChange={this.handleStartOpenChange}
            />
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.endDate)}</span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={endTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleEndTimeChange}
              open={endTimeOpen}
              onOpenChange={this.handleEndOpenChange}
            />

            {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.environment)}
              </span>
              <Select size="small" value={environmentId} style={{ width: 140 }} onChange={this.handleEnvironmentChange}>
                {R.map(
                  (item) => (
                    <Select.Option key={item.name} value={item.name} title={item.name}>
                      {item.id}
                    </Select.Option>
                  ),
                  globalInfoFilter || [],
                )}
              </Select> */}
            {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
            )}
            {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
              <Select
                showSearch
                size="small"
                value={customerName}
                style={{ width: 100 }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={this.handleCustomerNameChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            )}

            <div
              style={{ marginLeft: 8 }}
              onMouseEnter={() => {
                if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                  this.setState({ tooltipVisibleReloadMouseOver: true });
              }}
              onMouseLeave={() => {
                if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
              }}
            >
              <Tooltip
                mouseEnterDelay={0.3}
                placement="bottomRight"
                visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                title={
                  disableRefresh
                    ? 'Range of days <= 31'
                    : timeChange
                    ? intl.formatMessage(appMessages.clickToReload)
                    : null
                }
              >
                <Button size="small" disabled={disableRefresh} onClick={this.handleRefreshClick}>
                  {intl.formatMessage(appButtonsMessages.refresh)}
                </Button>
              </Tooltip>
            </div>
          </div>
        </Container>
        <Container className="global-view flex-grow flex-col flex-min-width flex-min-height">
          {errorMessage && (
            <div className="full-width full-height" style={{ padding: 16 }}>
              <Alert type="error" message={String(errorMessage)} showIcon />
            </div>
          )}

          {!errorMessage && (
            <>
              <div
                className="flex-row flex-center-align content-bg corner-8"
                style={{ padding: ' 8px 8px', margin: '0 20px 8px 16px' }}
              >
                <span style={{ fontWeight: 700, marginRight: 16 }}>
                  {intl.formatMessage(appFieldsMessages.systemList)}:
                </span>
                <Select
                  open={systemListOptionsFlag}
                  ref={(refs) => {
                    this.systemListOptions = refs;
                  }}
                  disabled={orderLoading}
                  style={{ width: 180, marginRight: 10 }}
                  mode="multiple"
                  allowClear
                  autoClearSearchValue={false}
                  maxTagCount={0}
                  value={selectSystems}
                  onChange={this.handleSelectSystemsChange}
                  showSearch
                  onSearch={(fetchingValue) => this.setState({ fetchingValue })}
                  optionFilterProp="title"
                  optionLabelProp="title"
                  onFocus={(e) => this.setState({ systemListOptionsFlag: true })}
                  onInputKeyDown={(e) => {
                    if (e.keyCode === 8 && e.target.value.length === 0) e.stopPropagation();
                  }}
                  onDropdownVisibleChange={(open) => {
                    const newState = {};
                    if (!open) newState.fetchingValue = '';
                    this.setState(newState);
                  }}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                  dropdownRender={(menu) => {
                    const diff = R.difference(localSelectSystems, selectSystems).length > 0;
                    const addNewDiff = R.difference(selectSystems, localSelectSystems).length > 0;
                    return (
                      <div>
                        <div
                          className="flex-row flex-center-align flex-space-between"
                          style={{ padding: '5px 12px' }}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={this.isCheckedAll({ searchValue: fetchingValue, valueList: selectSystems })}
                            onChange={(e) => {
                              const { checked } = e.target;
                              const filterValue = this.getFilterValue({ searchValue: fetchingValue });
                              const valueDiff = R.difference(selectSystems, filterValue);
                              this.handleSelectSystemsChange(checked ? [...valueDiff, ...filterValue] : valueDiff);
                            }}
                          >
                            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                          </Checkbox>

                          <div>
                            <Button
                              onClick={() => {
                                if (this.systemListOptions) this.systemListOptions.blur();
                                this.setState({ systemListOptionsFlag: false });
                              }}
                              size="small"
                              style={{ marginRight: 8 }}
                            >
                              {intl.formatMessage(appButtonsMessages.cancel)}
                            </Button>
                            <Popover
                              title={null}
                              content={
                                userInfo.isReadUser
                                  ? intl.formatMessage(eventMessages.isReadUserDisable)
                                  : intl.formatMessage(appFieldsMessages.systemListSaveTitle)
                              }
                              mouseEnterDelay={0.3}
                            >
                              <Button
                                disabled={orderLoading || userInfo.isReadUser}
                                onClick={this.saveSystemList}
                                size="small"
                                type={diff || addNewDiff ? 'primary' : 'default'}
                              >
                                {intl.formatMessage(appButtonsMessages.save)}
                              </Button>
                            </Popover>
                          </div>
                        </div>
                        <Divider style={{ margin: '4px 0' }} />
                        {menu}
                      </div>
                    );
                  }}
                >
                  {R.map((item) => {
                    const system = get(allSystemConfigsMap, [item], item);
                    return (
                      <Select.Option
                        className="hide-icon"
                        key={item}
                        title={get(allSystemConfigsMap, [item, 'systemName'], item)}
                        style={{
                          color: selectSystems.includes(item) ? 'var(--primary-color)' : 'var(--black)',
                          background: 'var(--component-background)',
                        }}
                      >
                        <Checkbox
                          checked={selectSystems.includes(item)}
                          onChange={(e) => null}
                          style={{ marginRight: 8 }}
                        />
                        {`${system.systemName}${system.owner ? ` (${system.owner})` : ''}`}
                      </Select.Option>
                    );
                  }, healthSystemIdList)}
                </Select>

                <span style={{ fontWeight: 700, marginRight: 16 }}>
                  {intl.formatMessage(appFieldsMessages.sorting)}:
                </span>
                <Select
                  style={{ width: 230 }}
                  allowClear={false}
                  filterOption
                  options={this.systemSortOptions}
                  value={systemSort}
                  onChange={this.handleSystemsSortChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                  disabled={hasSystemLoading}
                />

                <Checkbox
                  style={{ marginLeft: 15 }}
                  disabled={hasSystemLoading}
                  checked={hideChartsData}
                  onChange={(e) => this.handleHideChartData(e.target.checked)}
                >
                  {intl.formatMessage(appFieldsMessages.hideChartsWithoutData)}
                </Checkbox>
                <div className="flex-grow" />
              </div>
              {!viewHealthTip && (
                <Alert
                  type="info"
                  closable
                  message={intl.formatMessage(appFieldsMessages.useHealthView)}
                  style={{ margin: '0 20px 8px 16px', fontSize: 13, fontWeight: 'bold' }}
                  onClose={(e) => window.localStorage.setItem('viewHealthTip', true)}
                />
              )}
            </>
          )}

          {!errorMessage && (
            <div className="flex-grow flex-min-height overflow-y-auto overflow-x-hidden" style={{ paddingLeft: 8 }}>
              <GlobalHealthChartView
                intl={intl}
                forceRefreshTime={forceRefreshTime}
                refreshTime={refreshTime}
                customerName={customerName}
                environmentId={environmentId}
                credentials={credentials}
                startTime={startTime}
                endTime={endTime}
                systemList={healthSystemIdList}
                systemListMap={allSystemConfigsMap}
                location={location}
              />
              <Spin spinning={orderLoading}>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <GlobalHealthSystemList
                      width={width}
                      handleFavorite={this.handleFavorite}
                      items={newEventList}
                      intl={intl}
                      disablSort={systemSort !== 'none'}
                      systemList={systemList}
                      healthList={healthList}
                      handleSystemClick={this.handleSystemClick}
                      systemLoading={systemLoading}
                      isAutoReload={isAutoReload}
                      customerName={customerName}
                      environmentId={environmentId}
                      startTime={startTime}
                      endTime={endTime}
                      forceRefreshTime={forceRefreshTime}
                      onSetQueryParams={this.onSetQueryParams}
                      userInfo={userInfo}
                    />
                  )}
                </AutoSizer>
              </Spin>
            </div>
          )}
        </Container>

        {showTimeSelectModal && (
          <GlobalCronSelectModal
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            updateModelLoading={updateModelLoading}
            onClose={this.onCloseTimeSelect}
            onRerunKbPrediction={this.onRerunKbPrediction}
          />
        )}

        {this.state.showInsightQueryBox && (
          <InsightQueryBoxModal
            queryParams={this.state.queryParams}
            projects={logProjects}
            onConfirm={this.handleInsightQueryConfirm}
            onClose={() => this.setState({ showInsightQueryBox: false })}
          />
        )}
      </Container>
    );
  }
}

const GlobalHealthView = injectIntl(GlobalHealthViewCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials, userInfo } = state.auth;
    const { loadStatus, globalInfo, allSystemConfigs } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { globalHealthSummary } = state.dashboard;
    return {
      location,
      credentials,
      userInfo,
      loadStatus,
      allSystemConfigs: R.filter((s) => s.showInGV, allSystemConfigs || []),
      userList,
      globalInfo,
      globalHealthSummary,
    };
  },
  { push, replace, showAppAlert, showAppLoader, createLoadAction, createSetAction, updateLastActionInfo },
)(GlobalHealthView);
