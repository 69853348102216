/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';

import { Container, Table, Column, CellMeasurer, CellMeasurerCache } from '../../../lib/fui/react';
import CollapsibeText from '../../metric/components/CollapsibeText';
import { metricUnitParser } from '../../../common/apis/magicParsers';

type Props = {
  width: Number,
  height: Number,
  queryResult: Object,
  queryParams: Object,
};

class RelatedMetricAnomalies extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.gridOffsetY = 60;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheCorrelation = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.state = {
      view: 'correlation',
    };
  }

  componentDidMount() {
    this.convertData(this.props, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps, {});
    }
    if (nextProps.width !== this.props.width || nextProps.queryResult !== this.props.queryResult) {
      this.cellMeasureCache.clearAll();
      this.cellMeasureCacheCorrelation.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdate();
      }
      if (this.dataTableCorrelation) {
        this.dataTableCorrelation.forceUpdate();
      }
    }
  }

  @autobind
  convertData(props, params = {}) {
    const { queryResult } = props;
    const { causalPairResult, mapping } = queryResult;
    const pairContents = [];
    const correlationContents = [];

    R.forEachObjIndexed((value, key) => {
      R.forEachObjIndexed((resValue, resKey) => {
        if (resKey === 'causalPairResult') {
          R.forEach((content) => {
            pairContents.push({
              probability: (content.probability * 100).toFixed(2),
              srcContent: content.srcContent,
              targetContent: content.targetContent,
            });
          }, resValue);
        } else if (resKey === 'correlationPairResult') {
          R.forEach((content) => {
            correlationContents.push({
              probability: (content.probability * 100).toFixed(2),
              srcContent: content.srcContent,
              targetContent: content.targetContent,
            });
          }, resValue);
        }
      }, value);
    }, causalPairResult);

    this.pairContents = pairContents;
    this.correlationContents = correlationContents;
  }

  @autobind
  contentRender(props) {
    const { width } = this.props;
    const { dataKey, parent, rowIndex, cellData, style, rowData } = props;
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <CollapsibeText
          style={{ ...style, width: width - 960 }}
          content={cellData}
          ownerObject={rowData}
          onChanged={this.handleContentChanged}
        />
      </CellMeasurer>
    );
  }
  @autobind
  handleContentChanged() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdate();
    }
  }

  @autobind
  contentRenderCorrelation(props) {
    const { width } = this.props;
    const { dataKey, parent, rowIndex, cellData, style, rowData } = props;
    return (
      <CellMeasurer
        cache={this.cellMeasureCacheCorrelation}
        columnIndex={0}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <CollapsibeText
          style={{ ...style, width: width - 960 }}
          content={cellData}
          ownerObject={rowData}
          onChanged={this.handleContentChangedCorrelation}
        />
      </CellMeasurer>
    );
  }
  @autobind
  handleContentChangedCorrelation() {
    this.cellMeasureCacheCorrelation.clearAll();
    if (this.dataTableCorrelation) {
      this.dataTableCorrelation.forceUpdate();
    }
  }

  @autobind
  handleViewClick(view) {
    return (e) => {
      e.preventDefault();
      this.setState({ view });
    };
  }

  render() {
    const { width, height, queryResult } = this.props;
    let pairContents = this.pairContents || [];
    let correlationContents = this.correlationContents || [];
    pairContents = R.sortBy(R.compose(R.toLower, R.prop('srcContent')))(pairContents);
    correlationContents = R.sortBy(R.compose(R.toLower, R.prop('srcContent')))(correlationContents);

    const { view } = this.state;
    return (
      <Container>
        <div className="ui pointing secondary menu">
          <a
            key="correlation"
            className={`${view === 'correlation' ? 'active' : ''} item`}
            onClick={this.handleViewClick('correlation')}
          >
            Correlation
          </a>
          <a
            key="causal"
            className={`${view === 'causal' ? 'active' : ''} item`}
            onClick={this.handleViewClick('causal')}
          >
            Causal
          </a>
        </div>

        <div className="flex-grow" style={{ overflow: 'hidden' }}>
          {view === 'correlation' && (
            <Table
              className="with-border"
              width={width}
              height={height - this.gridOffsetY}
              deferredMeasurementCache={this.cellMeasureCacheCorrelation}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={this.cellMeasureCacheCorrelation.rowHeight}
              rowCount={correlationContents.length}
              rowGetter={({ index }) => correlationContents[index]}
              ref={(c) => (this.dataTableCorrelation = c)}
            >
              <Column width={350} label="Src content" dataKey="srcContent" />
              <Column
                width={350}
                label="Target content"
                dataKey="targetContent"
                className="raw-data"
                cellRenderer={this.contentRenderCorrelation}
              />
              <Column width={100} flexGrow={1} label="Probability(%)" dataKey="probability" />
            </Table>
          )}
          {view === 'causal' && (
            <Table
              className="with-border"
              width={width}
              height={height - this.gridOffsetY}
              deferredMeasurementCache={this.cellMeasureCache}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={this.cellMeasureCache.rowHeight}
              rowCount={pairContents.length}
              rowGetter={({ index }) => pairContents[index]}
              ref={(c) => (this.dataTable = c)}
            >
              <Column
                width={350}
                label="Src content"
                dataKey="srcContent"
                className="raw-data"
                cellRenderer={this.contentRender}
              />
              <Column width={350} label="Target content" dataKey="targetContent" />
              <Column width={100} flexGrow={1} label="Probability(%)" dataKey="probability" />
            </Table>
          )}
        </div>
      </Container>
    );
  }
}

export default RelatedMetricAnomalies;
