import moment from 'moment';
import Defaults from '../app/Defaults';
import { logMessages } from '../log/messages';

export const DEFAULT_EXPAND_DURATION = '10m';
export const getExpandDurations = (intl) => {
  return [
    {
      id: '5m',
      value: 5 * 60 * 1000,
      text: intl?.formatMessage(logMessages.duration5Minutes),
    },
    {
      id: '10m',
      value: 10 * 60 * 1000,
      text: intl?.formatMessage(logMessages.duration10Minutes),
    },
    { id: '30m', value: 30 * 60 * 1000, text: intl?.formatMessage(logMessages.duration30Minutes) },
    {
      id: '1h',
      value: 60 * 60 * 1000,
      text: intl?.formatMessage(logMessages.duration1Hour),
    },
    { id: '4h', value: 4 * 60 * 60 * 1000, text: intl?.formatMessage(logMessages.duration4Hours) },
    {
      id: '12h',
      value: 12 * 60 * 60 * 1000,
      text: intl?.formatMessage(logMessages.duration12Hours),
    },
    {
      id: '24h',
      value: 24 * 60 * 60 * 1000,
      text: intl?.formatMessage(logMessages.duration24Hours),
    },
  ];
};

export const getDurationText = (intl, id) => {
  const durations = getExpandDurations(intl);
  return durations.find((x) => x.id === id).text;
};

export const getDurationMs = (id) => {
  const durations = getExpandDurations(null);
  return durations.find((x) => x.id === id).value;
};

export const durationRender = (startTime, endTime) => {
  const s = moment.utc(startTime);
  const e = moment.utc(endTime);
  return {
    s: s.format(Defaults.TimeFormat),
    e: e.format(Defaults.TimeFormat),
  };
};
