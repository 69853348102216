import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Spin, message, Alert, Popover } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, parseJSON, GlobalRenderers, parseLocation } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  incident: Object,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  timezoneOffset: Number,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
  globalInfo: Object,
};

class ServiceNowNotesCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      triageReportList: [],
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData() {
    const { intl, incident, credentials } = this.props;
    const { anomalyLogInstance, instanceList, patternId } = incident;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('logpatterntriage'), {
      ...credentials,
      customerName: incident.projectOwner,
      projectName: incident.projectName,
      instanceName: anomalyLogInstance || instanceList[0],
      patternId,
    })
      .then((data) => {
        // get triager report
        let triageReportList = [];
        triageReportList = parseJSON(get(data, 'triageReportList')) || [];
        triageReportList = R.filter((item) => item?.triageReport?.reportSource === 'ServiceNow', triageReportList);
        triageReportList = R.map((item) => {
          const { instanceName, triageReport } = item;
          const { timestamp, fieldName, report, triageReporterManagement, reportSource, id } = triageReport;
          const key = `${instanceName}-${timestamp}-${fieldName}-${report}`;

          let reporterRecordSet = [];
          const { reporterMap } = triageReporterManagement || {};
          R.forEachObjIndexed((val, action) => {
            const { reporterDetailMap } = val || {};
            R.forEach((item) => {
              const { reporterName, reportTimestampSet } = item;
              R.forEach((timestamp) => {
                reporterRecordSet.push({
                  timestamp,
                  reporterName,
                  action,
                });
              }, reportTimestampSet || []);
            }, R.values(reporterDetailMap || {}));
          }, reporterMap);
          reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))], reporterRecordSet);

          return {
            instanceName,

            key,
            timestamp,
            fieldName,
            report,
            triageReporterManagement,
            reporterRecordSet,
            reportSource,
            ticketNumber: id,
          };
        }, triageReportList);
        triageReportList = R.sortWith([R.descend(R.prop('timestamp'))], triageReportList);

        this.setState({ isLoading: false, triageReportList });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, triageReportList: [] });
      });
  }

  render() {
    const { intl, timezoneOffset, location, globalInfo } = this.props;
    const { isLoading, triageReportList } = this.state;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    return (
      <>
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          {triageReportList.length === 0 && (
            <Alert message="" description={intl.formatMessage(logMessages.noServiceNowNotes)} type="info" showIcon />
          )}
          {triageReportList.length > 0 && (
            <div className="event-list flex-grow flex-col flex-min-height">
              <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                <div className="header-column" style={{ width: 140 }}>
                  {intl.formatMessage(eventActionMessages.reportIncidentOccurrenceTime)}
                </div>
                <div className="header-column" style={{ width: 120 }}>
                  {intl.formatMessage(DashboardMessages.ticketNumber)}
                </div>
                <div className="header-column" style={{ width: 120 }}>
                  {intl.formatMessage(appFieldsMessages.instanceName)}
                </div>
                <div className="header-column" style={{ width: 120 }}>
                  {intl.formatMessage(appFieldsMessages.title)}
                </div>
                <div className="header-column" style={{ width: 150, flex: 1 }}>
                  {intl.formatMessage(eventActionMessages.triageReport)}
                </div>
              </div>
              <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                {R.addIndex(R.map)((rowData, index) => {
                  const { instanceName, timestamp, fieldName, report, reporterRecordSet, ticketNumber } = rowData;
                  const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName);
                  return (
                    <div
                      key={index}
                      className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                      style={{ minHeight: 40 }}
                    >
                      <div className="row-column" style={{ width: 140 }}>
                        {moment.utc(Number(timestamp)).format(Defaults.DateTimeFormat)}
                      </div>
                      <div className="row-column" style={{ width: 120, wordBreak: 'break-all' }}>
                        <Popover content={ticketNumber} mouseEnterDelay={0.3} placement="top">
                          <div className="hidden-line-with-ellipsis">{ticketNumber}</div>
                        </Popover>
                      </div>
                      <div className="row-column" style={{ width: 120, wordBreak: 'break-all' }}>
                        {instanceStr}
                      </div>
                      <div className="row-column" style={{ width: 120, wordBreak: 'break-all' }}>
                        {fieldName}
                      </div>
                      <div className="row-column" style={{ width: 150, flex: 1, wordBreak: 'break-all' }}>
                        {report}
                      </div>
                    </div>
                  );
                }, triageReportList)}
              </div>
            </div>
          )}
        </Spin>
      </>
    );
  }
}

const ServiceNowNotes = injectIntl(ServiceNowNotesCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset, globalInfo } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      timezoneOffset,
      userInfo,
      credentials,
      globalInfo,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(ServiceNowNotes);
