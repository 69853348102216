/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
import { get, isInteger, toInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import VLink from 'valuelink';
import numeral from 'numeral';
import { SearchOutlined } from '@ant-design/icons';
import { Switch, Tooltip as AntdTooltip } from 'antd';

import { State } from '../../../common/types';
import { buildUrl } from '../../../common/utils';
import { Container, Select, Input, Tooltip } from '../../../lib/fui/react';
import { loadCausalIncident, resetCausalIncidentData } from '../../../common/causal/actions';
import { Options, Defaults } from '../../../common/app';
import { BaseUrls } from '../../app/Constants';
import { appFieldsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

import CausalRelationTree from './CausalRelationTree';
import ComponentCorrelation from './ComponentCorrelation';
import KPIPrediction from './KPIPrediction';
import DependencyGraph from './DependencyGraph';
import CausalAnomalyInfo from './CausalAnomalyInfo';

type Props = {
  onlyInfo: Boolean,
  showCausalJump: Boolean,
  isFromCausalGroupAnalysis: Boolean,
  className: String,
  isLoadingTasks: Boolean,
  hideCausalRelation: Boolean,
  hideCorrelation: Boolean,
  hideDependency: Boolean,
  hideKpiPrediction: Boolean,
  projects: Array<Object>,
  causalIncidentList: Array<Object>,
  causalGroup: Object,
  currentLoadingComponents: Object,
  incidentParams: Object,
  filterParams: Object,
  view: String,
  joinDependency: Boolean,
  relationTimeThreshold: String,
  relationProbability: String,
  kpiPredictionProbability: String,
  relationCount: Number,
  pattern: Number,
  isShortCausalGraph: Boolean,
  fixedCountAndProb: Boolean,

  intl: Object,
  loadCausalIncident: Function,
  resetCausalIncidentData: Function,
  onViewChange: Function,
  onKpiPredictionProbabilityChanged: Function,
  onJoinDependencyChanged: Function,

  projectName: String,
  instanceName: String,
  needCausalProperty: Boolean,
  instanceGroup: String,

  causalIncidentProperty: Object,
  causalIncident: Object,
  causalInstanceIncident: Object,
};

class CausalIncidentCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl, isFromCausalGroupAnalysis, instanceName, relationCount, relationTimeThreshold, relationProbability } =
      this.props;

    this.modalityFilterOptions = [
      { label: 'All', value: 'all' },
      { label: intl.formatMessage(causalMessages.metricToMetric), value: 'metric-metric' },
      { label: intl.formatMessage(causalMessages.logToLog), value: 'log-log' },
      { label: intl.formatMessage(causalMessages.metricToLog), value: 'log-metric' },
      { label: intl.formatMessage(causalMessages.metricToIncident), value: 'incident-metric' },
      { label: intl.formatMessage(causalMessages.logToIncident), value: 'incident-log' },
      { label: intl.formatMessage(causalMessages.metricToAlert, { flag: '<->' }), value: 'alert-metric' },
      { label: intl.formatMessage(causalMessages.logToAlert, { flag: '<->' }), value: 'alert-log' },
    ];

    this.state = {
      iniInstanceName: isFromCausalGroupAnalysis && instanceName ? instanceName : '',
      instanceName: !isFromCausalGroupAnalysis && instanceName ? instanceName : '',
      relationTimeThreshold,
      relationCount,
      relationProbability,
      filterModality: 'all',
      relationTimeThresholdOptions: Options.RelationTimeThresholdOptions,
    };
  }

  componentDidMount() {
    this.resetCausalParams(this.props, { reloadDuration: true, reloadCount: true, reloadProbability: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.view !== this.props.view || nextProps.causalIncidentList !== this.props.causalIncidentList) {
      this.resetCausalParams(nextProps, { reloadDuration: true, reloadCount: true, reloadProbability: true });
    }
  }

  @autobind
  resetCausalParams(props, { reloadDuration, reloadCount, reloadProbability }) {
    const { view, incidentParams, causalIncidentList, isFromCausalGroupAnalysis, fixedCountAndProb } = props;
    if (fixedCountAndProb) return;
    let {
      relationTimeThreshold: relationTimeThresholdParam,
      relationCount: relationCountParam,
      relationProbability: relationProbabilityParam,
    } = props;
    const { instanceName, relationTimeThreshold, relationCount, relationProbability } = this.state;
    let relationTimeThresholdOptions = Options.RelationTimeThresholdOptions;
    const causalIncidentInfo = R.find(
      (causalIncidentInfo) => causalIncidentInfo.fileName === incidentParams.fileName,
      causalIncidentList || [],
    );
    let maxTimeThreshold = Infinity;
    let minTimeThreshold = relationTimeThresholdOptions.length > 0 ? Number(relationTimeThresholdOptions[0].value) : 0;
    if (causalIncidentInfo) {
      const { showHaveDataGraph } = causalIncidentInfo;
      let postFixStr = 'inter';
      if (instanceName) {
        postFixStr = `intra_${instanceName}`;
      }
      let causalType = 'causal';
      switch (view) {
        case 'correlation':
          causalType = 'correlation';
          break;
        default:
          break;
      }
      let filesList = R.filter((item) => {
        return item.fileName.indexOf(`${postFixStr}_${causalType}`) >= 0;
      }, causalIncidentInfo.casualStartPoint || []);
      filesList = R.sortWith([R.ascend(R.prop('minimumTimeThreshold'))], filesList);
      if (filesList.length > 0) {
        minTimeThreshold = R.last(filesList[0].fileName.split('_'));
        maxTimeThreshold = R.last(filesList[filesList.length - 1].fileName.split('_'));
        const fileInfo = showHaveDataGraph ? filesList[filesList.length - 1] : filesList[0];
        if (reloadDuration || !relationTimeThresholdParam) {
          relationTimeThresholdParam = numeral(
            showHaveDataGraph ? maxTimeThreshold : fileInfo.minimumTimeThreshold / 1000 / 60,
          ).format('0.0');
        }
        if (reloadProbability || !relationProbabilityParam) {
          const probability =
            (showHaveDataGraph ? fileInfo.minimumProbability : fileInfo.maximumProbability) ||
            fileInfo.defaultProbabilityPossible;
          relationProbabilityParam = numeral(toInteger(probability * 10) / 10).format('0.0');
        }
        if (reloadCount || !relationCountParam) {
          const count =
            (showHaveDataGraph ? fileInfo.minimumCount : fileInfo.maximumCount) || fileInfo.defaultMinimumCount;
          relationCountParam = toInteger(count);
        }
      }
    }
    if (!relationTimeThresholdParam) {
      relationTimeThresholdParam = '15.0';
    }
    if (!relationProbabilityParam) {
      relationProbabilityParam = '0.0';
    }
    if (isFromCausalGroupAnalysis) {
      relationProbabilityParam = '0.5';
    }
    if (!relationCountParam) {
      relationCountParam = 1;
    }

    if (relationCountParam === 2147483647) {
      relationCountParam = 5;
    }

    if (parseFloat(relationProbabilityParam) > 1) {
      relationProbabilityParam = '1.0';
    }

    relationTimeThresholdOptions = R.filter(
      (option) => Number(minTimeThreshold) <= Number(option.value) && Number(option.value) <= Number(maxTimeThreshold),
      relationTimeThresholdOptions,
    );

    this.setState({
      relationTimeThreshold: reloadDuration
        ? relationTimeThresholdParam
        : relationTimeThreshold || relationTimeThresholdParam,
      relationCount: reloadCount ? relationCountParam : relationCount || relationCountParam,
      relationProbability: reloadProbability
        ? relationProbabilityParam
        : relationProbability || relationProbabilityParam,
      relationTimeThresholdOptions,
    });
  }

  @autobind
  handleViewClick(view) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.props.onViewChange(view);
    };
  }

  @autobind
  toggleJoinDependencyGraph(e) {
    const { joinDependency } = this.props;
    this.props.onJoinDependencyChanged(!joinDependency);
  }

  @autobind
  handleKpiPredictionProbabilityChanged(newValue) {
    this.props.onKpiPredictionProbabilityChanged(newValue.value);
  }

  @autobind
  handleFilterChange(state, callBackFunction) {
    this.setState(state, callBackFunction);
  }

  @autobind
  handleInstanceChange(instanceName) {
    this.setState({ instanceName }, () => {
      this.resetCausalParams(this.props, { reloadCount: true, reloadProbability: true });
    });
  }

  @autobind
  onChangeProbability(newValue) {
    const newState = {
      relationProbability: newValue ? newValue.value : null,
    };
    if (newValue) {
      const { causalIncident, causalInstanceIncident } = this.props;
      const { instanceName, relationCount } = this.state;
      const hasInstance = Boolean(instanceName);
      const incident = hasInstance ? causalInstanceIncident : causalIncident;
      const maxProbabilityCountMap = get(incident, ['maxProbabilityCountMap']);
      if (maxProbabilityCountMap) {
        const maxRelationCount = get(maxProbabilityCountMap, newValue.value);
        if (relationCount > maxRelationCount) newState.relationCount = maxRelationCount;
      }
    }

    this.setState(newState);
  }

  @autobind
  onChangeModality(newValue) {
    if (newValue) {
      this.setState({ filterModality: newValue.value });
    }
  }

  @autobind
  onChangeCount(newValue) {
    this.setState({ relationCount: newValue });
  }

  @autobind
  handleCausalClick(view) {
    return (event) => {
      event.preventDefault();
      event.stopPropagation();

      const { incidentParams } = this.props;
      const { relationTimeThreshold, relationCount, relationProbability } = this.state;
      const {
        causalName,
        causalKey,
        relationKey,
        fileName,
        customerName,
        startTimestamp,
        endTimestamp,
        joinDependency,
      } = incidentParams;
      const query = {
        causalName,
        causalKey,
        relationKey,
        fileName,
        customerName,
        startTimestamp,
        endTimestamp,
        joinDependency,

        view,
        relationTimeThreshold,
        relationCount,
        relationProbability,
      };
      window.open(buildUrl(BaseUrls.CausalAnalysis, {}, query), '_blank');
    };
  }

  render() {
    const {
      intl,
      onlyInfo,
      className,
      isLoadingTasks,
      view,
      projects,
      incidentParams,
      filterParams,
      causalIncidentList,
      pattern,
    } = this.props;
    const {
      showCausalJump,
      hideCausalRelation,
      hideCorrelation,
      hideDependency,
      hideKpiPrediction,
      isShortCausalGraph,
      fixedCountAndProb,
    } = this.props;
    const { causalGroup, needCausalProperty } = this.props;
    const { projectName, instanceGroup } = this.props;
    const {
      iniInstanceName,
      instanceName,
      relationTimeThreshold,
      relationProbability,
      relationCount,
      filterModality,
      relationTimeThresholdOptions,
    } = this.state;
    const { kpiPredictionProbability } = this.props;
    const joinDependency = Boolean(this.props.joinDependency);
    const causalIncidentInfo = R.find(
      (causalIncidentInfo) => causalIncidentInfo.fileName === incidentParams.fileName,
      causalIncidentList || [],
    );

    const relationCountLink = VLink.state(this, 'relationCount')
      .check((word) => {
        return Number(word) > 0 && isInteger(Number(word));
      })
      .onChange(this.onChangeCount);

    let hasLogProject = false;
    let hasMetricProject = false;
    if (causalGroup && projects) {
      const causalProjectList = get(causalGroup, 'projectList', []);
      R.forEach((cp) => {
        const project = R.find((p) => p.projectShortName === cp.projectName, projects);
        if (project) {
          if (project.isLog) {
            hasLogProject = true;
          }
          if (project.isMetric) {
            hasMetricProject = true;
          }
        }
      }, causalProjectList);
    }

    const causalGraphToolbarStyle = isShortCausalGraph ? { position: 'unset', padding: '0 0 4px 0' } : {};
    return (
      <Container
        className={`${className} incident flex-col ${isLoadingTasks ? 'loading' : ''}`}
        style={{ padding: '0px 0px 8px 0px' }}
      >
        <div className="ui pointing secondary menu" style={{ marginBottom: 8 }}>
          {!hideCausalRelation && (
            <a
              className={`${view.toLowerCase() === 'relation' ? 'active ' : ''}item`}
              style={{ padding: '12px 8px' }}
              onClick={this.handleViewClick('relation')}
            >
              {intl.formatMessage(causalMessages.relatedAnomalies)}
              {showCausalJump && (
                <div style={{ cursor: 'pointer', padding: '0 2px' }} onClick={this.handleCausalClick('relation')}>
                  <Tooltip title={<div>Click For Details</div>} placement="top">
                    <div className="long-name" style={{ maxWidth: '100%', fontSize: 13, cursor: 'pointer' }}>
                      <SearchOutlined size="small" />
                    </div>
                  </Tooltip>
                </div>
              )}
            </a>
          )}
          {!hideCorrelation && (
            <a
              className={`${view.toLowerCase() === 'correlation' ? 'active ' : ''}item`}
              style={{ padding: '12px 8px' }}
              onClick={this.handleViewClick('correlation')}
            >
              {intl.formatMessage(causalMessages.concurrentAnomalies)}
              {showCausalJump && (
                <div style={{ cursor: 'pointer', padding: '0 2px' }} onClick={this.handleCausalClick('correlation')}>
                  <Tooltip title={<div>Click For Details</div>} placement="top">
                    <div className="long-name" style={{ maxWidth: '100%', fontSize: 13, cursor: 'pointer' }}>
                      <SearchOutlined size="small" />
                    </div>
                  </Tooltip>
                </div>
              )}
            </a>
          )}
          {!hideDependency && (
            <a
              className={`${view.toLowerCase() === 'dependency' ? 'active ' : ''}item`}
              style={{ padding: '12px 8px' }}
              onClick={this.handleViewClick('dependency')}
            >
              {intl.formatMessage(causalMessages.dependencyGraph)}
            </a>
          )}
          {!hideKpiPrediction && (
            <a
              className={`${view.toLowerCase() === 'kpiprediction' ? 'active ' : ''}item`}
              style={{ padding: '12px 8px' }}
              onClick={this.handleViewClick('kpiprediction')}
            >
              {intl.formatMessage(causalMessages.kpiPredictionGraph)}
            </a>
          )}
        </div>
        {view === 'relation' && !hideCausalRelation && (
          <Container toolbar style={causalGraphToolbarStyle}>
            <div className="section">
              <span className="label">{intl.formatMessage(causalMessages.duration)}:</span>
              <Select
                withPortal
                options={relationTimeThresholdOptions}
                value={relationTimeThreshold}
                onChange={(newValue) =>
                  this.setState({
                    relationTimeThreshold: newValue ? newValue.value : null,
                  })
                }
                style={{ width: 100 }}
              />
              <span className="label">{intl.formatMessage(causalMessages.probability)}(≥):</span>
              <Select
                options={Options.RelationProbability}
                value={relationProbability}
                onChange={this.onChangeProbability}
                style={{ width: 85 }}
              />
              <span className="label">{intl.formatMessage(appFieldsMessages.count)}(≥):</span>
              <Input type="number" valueLink={relationCountLink} style={{ width: 80 }} />
              <span className="label">{intl.formatMessage(causalMessages.modality)}:</span>
              <Select
                style={{ width: 130 }}
                options={this.modalityFilterOptions}
                value={filterModality}
                onChange={this.onChangeModality}
              />
              <AntdTooltip
                title={intl.formatMessage(causalMessages.joinDependencyGraph)}
                mouseEnterDelay={0.3}
                placement="topRight"
              >
                <Switch size="small" checked={joinDependency} onChange={this.toggleJoinDependencyGraph} />
              </AntdTooltip>
            </div>
          </Container>
        )}
        {view === 'relation' && !hideCausalRelation && !onlyInfo && (
          <CausalRelationTree
            causalIncidentInfo={causalIncidentInfo}
            relationTimeThreshold={relationTimeThreshold}
            relationProbability={relationProbability}
            relationCount={relationCount}
            filterModality={filterModality}
            pattern={pattern}
            projectName={projectName}
            iniInstanceName={iniInstanceName}
            instanceName={instanceName}
            needCausalProperty={needCausalProperty}
            hasLogProject={hasLogProject}
            hasMetricProject={hasMetricProject}
            incidentParams={incidentParams}
            filterParams={filterParams}
            onInstanceChange={this.handleInstanceChange}
            onFilterChange={this.handleFilterChange}
            fixedCountAndProb={fixedCountAndProb}
          />
        )}
        {view === 'correlation' && !hideCorrelation && (
          <Container toolbar style={causalGraphToolbarStyle}>
            <div className="section">
              <span className="label">{intl.formatMessage(causalMessages.probability)}(≥):</span>
              <Select
                options={Options.RelationProbability}
                value={relationProbability}
                onChange={this.onChangeProbability}
                style={{ width: 85 }}
              />
              <span className="label">{intl.formatMessage(appFieldsMessages.count)}(≥):</span>
              <Input type="number" valueLink={relationCountLink} style={{ width: 80 }} />
              <span className="label">{intl.formatMessage(causalMessages.modality)}:</span>
              <Select
                style={{ width: 130 }}
                options={this.modalityFilterOptions}
                value={filterModality}
                onChange={this.onChangeModality}
              />
              <AntdTooltip
                title={intl.formatMessage(causalMessages.joinDependencyGraph)}
                mouseEnterDelay={0.3}
                placement="topRight"
              >
                <Switch size="small" checked={joinDependency} onChange={this.toggleJoinDependencyGraph} />
              </AntdTooltip>
            </div>
          </Container>
        )}
        {view === 'correlation' && !hideCorrelation && !onlyInfo && (
          <ComponentCorrelation
            causalIncidentInfo={causalIncidentInfo}
            relationProbability={relationProbability}
            relationCount={relationCount}
            filterModality={filterModality}
            pattern={pattern}
            hasLogProject={hasLogProject}
            hasMetricProject={hasMetricProject}
            incidentParams={incidentParams}
            filterParams={filterParams}
            iniInstanceName={iniInstanceName}
            instanceName={instanceName}
            needCausalProperty={needCausalProperty}
            onInstanceChange={this.handleInstanceChange}
            onFilterChange={this.handleFilterChange}
            fixedCountAndProb={fixedCountAndProb}
          />
        )}
        {view === 'dependency' && !hideDependency && !onlyInfo && (
          <DependencyGraph
            causalIncidentInfo={causalIncidentInfo}
            hasLogProject={hasLogProject}
            hasMetricProject={hasMetricProject}
            incidentParams={incidentParams}
            filterParams={filterParams}
            projectName={projectName}
            instanceName={instanceName}
            needCausalProperty={needCausalProperty}
            instanceGroup={instanceGroup}
          />
        )}
        {view === 'kpiprediction' && !hideKpiPrediction && (
          <Container toolbar style={causalGraphToolbarStyle}>
            <div className="section">
              <span className="label">Probability:</span>
              <Select
                options={Options.KpiPredictionProbability}
                value={kpiPredictionProbability || Defaults.CausalKpiPredictionProbability}
                onChange={this.handleKpiPredictionProbabilityChanged}
                style={{ width: 100 }}
              />
            </div>
          </Container>
        )}
        {view === 'kpiprediction' && !hideKpiPrediction && !onlyInfo && (
          <KPIPrediction
            causalIncidentInfo={causalIncidentInfo}
            hasLogProject={hasLogProject}
            hasMetricProject={hasMetricProject}
            kpiPredictionProbability={kpiPredictionProbability || Defaults.CausalKpiPredictionProbability}
            incidentParams={incidentParams}
            filterParams={filterParams}
            instanceName={instanceName}
            needCausalProperty={needCausalProperty}
            onInstanceChange={this.handleInstanceChange}
          />
        )}

        {['relation', 'correlation'].indexOf(view) >= 0 && !hideCausalRelation && onlyInfo && (
          <CausalAnomalyInfo
            view={view}
            causalIncidentInfo={causalIncidentInfo}
            relationTimeThreshold={relationTimeThreshold}
            relationProbability={relationProbability}
            relationCount={relationCount}
            pattern={pattern}
            projectName={projectName}
            instanceName={instanceName}
            needCausalProperty={needCausalProperty}
            hasLogProject={hasLogProject}
            hasMetricProject={hasMetricProject}
            incidentParams={incidentParams}
            filterParams={filterParams}
            onInstanceChange={this.handleInstanceChange}
          />
        )}
      </Container>
    );
  }
}

const CausalIncident = injectIntl(CausalIncidentCore);
export default connect(
  (state: State) => {
    const { currentLoadingComponents, projects } = state.app;
    const { incidentData, instanceIncidentData, incidentCausalProperty } = state.causal;
    return {
      projects,
      currentLoadingComponents,
      causalIncident: get(incidentData, 'data', null),
      causalInstanceIncident: get(instanceIncidentData, 'data', null),
      causalIncidentProperty: incidentCausalProperty || {},
    };
  },
  { loadCausalIncident, resetCausalIncidentData },
)(CausalIncident);
