// import * as R from 'ramda';

import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getMatchedIncidentPredictions = (credentials, params) => {
  const { customerName, environmentId, systemId, ...rest } = params;
  const requests = [
    fetchGet(getEndpoint('insightIncidents', 1), {
      ...credentials,
      ...rest,
      customerName,
      environmentName: environmentId,
      systemName: systemId,
    }),
  ];

  return Promise.all(requests).then((results) => {
    const globalSystemMatchedPredictions = results[0] || [];

    return {
      globalSystemMatchedPredictions,
    };
  });
};

export default getMatchedIncidentPredictions;
