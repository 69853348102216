import React from 'react';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import * as R from 'ramda';
import { Select, Button, DatePicker, Tooltip } from 'antd';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { CodeSandboxOutlined, DatabaseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { State } from '../../common/types';
import { hideAppLoader, showAppAlert, showAppLoader } from '../../common/app/actions';
import { settingsMessages } from '../../common/settings/messages';
import BaseUrls from '../app/BaseUrls';
import { Defaults, buildLocation, buildUrl, parseLocation, sleep, downloadPdf } from '../../common/utils';
import { Container, Popover } from '../../lib/fui/react';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../common/app/messages';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import ServiceMapSettingModal from './ServiceMapSettingModal';
import PublicBuildCizizen from '../dashboard/components/PublicBuildCizizen';
import fetchPost from '../../common/apis/fetchPost';
import { eventMessages } from '../../common/metric/messages';
import { ServiceMapView } from './ServiceMapView';
import { PDFIcon } from '../../lib/fui/icons';

type Props = {
  intl: Object,
  location: Object,
  userInfo: Object,
  credentials: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  systemsMap: Object,
  projects: Array<Object>,
  allProjects: Array<Object>,
  push: Function,
  replace: Function,
  hideAppLoader: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  showAppLoader: Function,
  userList: Array<Object>,
  timezoneOffset: Number,
  isDark: Boolean,
};

class GlobalServiceMapCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location, isAdmin, isLocalAdmin } = props;
    const params = parseLocation(location);
    const { systemSearch, projectOwner, customerName, zoneName } = params;
    let { startTime, endTime } = params;

    const systemList = this.getSystemList(props);
    const systemInfo = R.find((system) => system.id === systemSearch, systemList) || systemList[0];

    const time = this.getUserCurrentTime(props, systemInfo, false);
    if (time) {
      startTime = time;
      endTime = time;
    }

    this.userListOptions = [];
    this.state = {
      startTimeObj: moment.utc(startTime || moment.utc(), Defaults.DateFormat),
      endTimeObj: moment.utc(endTime || moment.utc(), Defaults.DateFormat),
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      isLoadingSystem: false,
      isGroupingLoading: false,

      systemList: [],
      systemSearch: systemSearch || '',
      systemInfo,
      projectOwner: projectOwner || (isAdmin || isLocalAdmin ? customerName : null),

      showSystemEditModal: false,
      incident: null,

      summaryObj: {},
      selectedZone: zoneName || `__all__`,

      selectedTime: null,
      k8sSelectedTime: null,
      serviceMapNote: {},
      serviceMapData: {},
      nodeRootCauseMap: {},
      isK8s: false,
      incidentCountByZone: {},
      incidentCountByZoneForPod: {},
    };
  }

  async componentDidMount() {
    const { hideAppLoader, allProjects } = this.props;
    hideAppLoader();
    if (allProjects.length === 0) {
      showAppAlert('info', settingsMessages.alertNoProject);
      push(BaseUrls.SettingsProjectWizard);
    } else {
      this.parseData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.systemId !== query.systemId
    ) {
      this.parseData(nextProps);
    }
  }

  @autobind
  getZoneOptions(zoneSet, systemId, incidentCountByZone = {}, incidentCountByZoneForPod = {}) {
    const total = R.reduce((acc, item) => acc + item, 0, R.values(incidentCountByZone || {}));
    const podTotal = R.reduce((acc, item) => acc + item, 0, R.values(incidentCountByZoneForPod || {}));
    let zoneOptions = R.filter((x) => x !== `zone_${systemId}`, zoneSet).map((item) => ({
      value: item,
      label: item,
      count: incidentCountByZone[item] || 0,
      podCount: incidentCountByZoneForPod[item] || 0,
    }));
    zoneOptions = [
      { value: '__all__', label: 'All zones', count: total, podCount: podTotal },
      {
        value: `zone_${systemId}`,
        label: 'No zones',
        count: incidentCountByZone[`zone_${systemId}`] || 0,
        podCount: incidentCountByZoneForPod[`zone_${systemId}`] || 0,
      },
      ...zoneOptions,
    ];
    return zoneOptions;
  }

  @autobind
  getSystemList(props) {
    const { location, globalInfo, favorites: favoriteList } = props;
    const { environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    let systemList = environment ? environment.systemList : [];
    let favorites = R.filter((item) => favoriteList.includes(item.id), systemList);
    let restSystems = R.filter((item) => !favoriteList.includes(item.id), systemList);
    favorites = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(favorites);
    restSystems = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(restSystems);
    // 把hasData = false 放在后面
    const notHasDataSystems = R.filter((item) => !item.hasData, restSystems);
    restSystems = R.filter((item) => item.hasData, restSystems);

    systemList = [...favorites, ...restSystems, ...notHasDataSystems];
    return systemList;
  }

  @autobind
  getUserCurrentTime(props, systemInfo) {
    const { location, replace } = props;
    let { timezoneOffset } = props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;

    if (systemInfo && systemInfo.timezone) {
      const diffMilliseconds = Math.abs(
        moment
          .utc(endTime, Defaults.DateFormat)
          .endOf('days')
          .diff(moment.utc(startTime, Defaults.DateFormat).startOf('days')),
      );
      const oneDayMilliseconds = 24 * 60 * 60 * 1000;
      const zone = momenttz.tz(systemInfo?.timezone);
      timezoneOffset = zone.utcOffset();
      const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
      const time = moment.utc(nowTimestamp).format(Defaults.DateFormat);
      if (diffMilliseconds > oneDayMilliseconds) {
        replace(
          buildLocation(location.pathname, {}, { ...query, startTime: time, endTime: time, systemId: systemInfo?.id }),
        );
        return time;
      }
    }
    return undefined;
  }

  @autobind
  reloadGrouping(props) {
    const { credentials, location, userInfo } = props;
    const { systemSearch, startTimeObj, endTimeObj, systemInfo, selectedZone } = this.state;
    let query = parseLocation(location);
    const { customerName } = query;
    const startTime = startTimeObj.format(Defaults.DateFormat);
    const endTime = endTimeObj.format(Defaults.DateFormat);
    const zoneSet = systemInfo?.systemInfo?.zoneSet || [];

    // update start/end time if changed
    if (startTime !== query.startTime || endTime !== query.endTime) {
      query = { ...query, startTime, endTime };
    }
    const url = buildUrl(this.props.location.pathname, {}, { ...query, systemId: systemSearch });
    window.history.replaceState(null, null, url);
    this.setState({ isGroupingLoading: true });

    const zoneList = selectedZone
      ? selectedZone === '__all__'
        ? JSON.stringify([])
        : JSON.stringify([selectedZone])
      : undefined;
    const zoneNotesList = selectedZone
      ? selectedZone === '__all__'
        ? JSON.stringify([...zoneSet, `all_zone_${systemInfo?.id}`])
        : JSON.stringify([selectedZone])
      : undefined;
    const requests = [];
    if (systemSearch && startTimeObj && endTimeObj) {
      requests.push(
        fetchGet(getEndpoint('getservicemap'), {
          ...credentials,
          systemName: systemSearch,
          customerName: systemInfo?.ownerUserName || customerName || userInfo.userName,
          startTime: startTimeObj.startOf('days').valueOf(),
          endTime: endTimeObj.endOf('days').valueOf(),
          zoneList,
        }),
      );
      requests.push(
        fetchGet(getEndpoint('servicemapnote'), {
          ...credentials,
          systemName: systemSearch,
          customerName: systemInfo?.ownerUserName || customerName || userInfo.userName,
          dailyTimestamp: startTimeObj.startOf('days').valueOf(),
          zoneList: zoneNotesList,
        }).catch((e) => {
          console.error(e);
        }),
      );
    }

    Promise.all(requests)
      .then((results) => {
        const res = results[0];
        const serviceMapNote = results[1] || {};
        const { success, message } = res;

        if (message && success === false) {
          message.error(message);
          this.setState({ isGroupingLoading: false });
          return;
        }

        let isK8s = false;
        const serviceMapData = res;
        R.forEach((i) => {
          if (i.type === 3) {
            R.forEach((j) => {
              if (j.type === 2) {
                if (j.isK8s && !isK8s) {
                  isK8s = true;
                }
              }
            }, i.children || []);
          } else if (i.type === 2) {
            if (i.isK8s && !isK8s) {
              isK8s = true;
            }
          }
        }, serviceMapData?.Contains || []);

        const newState = {
          serviceMapNote,
          serviceMapData,
          isGroupingLoading: false,
          isK8s,
          nodeRootCauseMap: {},
        };

        if (selectedZone === '__all__') {
          newState.incidentCountByZone = res?.incidentCountByZone || {};
          newState.incidentCountByZoneForPod = res?.incidentCountByZoneForPod || {};
        }

        this.setState(newState);
      })
      .catch((e) => {
        this.setState({ isGroupingLoading: false });
      });
  }

  @autobind
  async parseData(props) {
    const { location } = props;
    let { systemId } = parseLocation(location);

    await this.getSummaryObj(this.props);
    const systemList = this.getSystemList(props);

    if (!systemId && systemList && systemList.length > 0) {
      systemId = systemList ? systemList[0].id : '';
    }
    const systemInfo = R.find((system) => system.id === systemId, systemList) || {};

    const userList = props?.userList || [];
    this.userListOptions = R.map(
      (userName) => ({ value: userName, label: userName }),
      R.sort((a, b) => a.localeCompare(b), R.uniq(R.map((item) => item.userName, userList || []))),
    );

    this.setState({ systemList, systemSearch: systemId, systemInfo, isLoadingSystem: false }, () => {
      this.reloadGrouping(this.props);
    });
  }

  @autobind
  onChangeFilterOwner(projectOwner) {
    const { location, showAppLoader } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    setTimeout(() => {
      const query = parseLocation(location);
      const { startTime, endTime } = query;
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        {
          redirect: undefined,
          customerName: projectOwner,
          projectOwner,
          environmentId: undefined,
          systemId: undefined,
          startTime,
          endTime,
        },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  handleSystemEditClick() {
    const { systemInfo } = this.state;
    if (!systemInfo) return;

    const { id: systemId, systemDisplayName: systemName, ownerUserName: owner } = systemInfo;
    this.setState({
      showSystemEditModal: true,
      incident: {
        systemName,
        owner,
        systemId,
      },
    });
  }

  @autobind
  handleSystemEditClose(reload) {
    this.setState({ showSystemEditModal: false, incident: null, selectedZone: '__all__' }, () => {
      if (reload) {
        this.handleRefreshClick();
      }
    });
  }

  @autobind
  handleSystemClick(systemId) {
    const { systemList } = this.state;
    const { location } = this.props;
    const query = parseLocation(location);
    const systemInfo = R.find((_item) => _item.id === systemId, systemList) || {};

    const { id: systemSearch } = systemInfo;
    this.props.replace(buildLocation(location.pathname, {}, { ...query, systemId: systemSearch }));

    this.setState({ systemSearch, systemInfo, selectedZone: '__all__' });
    if (this.listNode) {
      this.listNode.forceUpdateGrid();
    }
  }

  @autobind
  handleRefreshClick() {
    this.reloadGrouping(this.props);
  }

  @autobind
  handleTimeChange(timeObj) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    const startTimeObj = moment.utc(timeObj.valueOf());
    const endTimeObj = startTimeObj.clone().endOf('day');

    this.setState(
      {
        startTimeObj,
        endTimeObj,
        k8sSelectedTime: null,
      },
      () => {
        replace(
          buildLocation(
            location.pathname,
            {},
            {
              ...query,
              startTime: startTimeObj.format(Defaults.DateFormat),
              endTime: endTimeObj.format(Defaults.DateFormat),
            },
          ),
        );
      },
    );
  }

  @autobind
  onChangeFilterZone(zone) {
    this.setState(
      {
        selectedZone: zone,
      },
      () => {
        this.handleRefreshClick();
      },
    );
  }

  @autobind
  getSummaryObj(props) {
    const { credentials, location, globalInfo } = props;
    const { environmentId, startTime, endTime } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemIdsWithShare = R.map((item) => ({ id: item.id, customerName: item.ownerUserName }), systemList || []);
    if (startTime && endTime) {
      return fetchPost(getEndpoint('dashboard-summary'), {
        ...credentials,
        systemIdsWithShare: JSON.stringify(systemIdsWithShare),
        startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
        endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      })
        .then((data) => {
          this.setState({ summaryObj: data || {} });
        })
        .catch((e) => {
          console.log(String(e));
        });
    }
    return false;
  }

  @autobind
  onActiveNodeChange(activeId, selectedTime) {
    if (!activeId) {
      return;
    }

    const { credentials, location, userInfo } = this.props;
    const { systemSearch, startTimeObj, systemInfo, nodeRootCauseMap } = this.state;
    const query = parseLocation(location);
    const { customerName } = query;
    const parts = activeId.split('_') || [];
    const nodeName = parts[parts.length - 1] || activeId;
    const nodeRootCause = nodeRootCauseMap[nodeName];
    const findActiveRootCauseNodes = (node) => {
      const activeRootCauseNodes = {};
      if (node) {
        const { rootCauseList } = node;
        if (rootCauseList) {
          R.forEach((item) => {
            const { nodeName, metricName } = item;
            if (!activeRootCauseNodes[nodeName]) {
              activeRootCauseNodes[nodeName] = [];
            }
            if (metricName) {
              activeRootCauseNodes[nodeName].push(metricName);
            }
          }, rootCauseList);
        }
      }
      R.forEachObjIndexed((value, key) => {
        activeRootCauseNodes[key] = R.uniq(value);
      }, activeRootCauseNodes);
      return activeRootCauseNodes;
    };
    if (!nodeRootCause) {
      this.setState({ isGroupingLoading: true });
      fetchGet(getEndpoint('serviceextra'), {
        ...credentials,
        nodeName,
        systemName: systemSearch,
        dailyTimestamp: startTimeObj.startOf('days').valueOf(),
        customerName: systemInfo?.ownerUserName || customerName || userInfo.userName,
      })
        .then((d) => {
          const { result } = d || {};
          if (result) {
            const jsonResult = JSON.parse(result);
            const rcMap = {};
            const serviceMapNodeRootCauseList = jsonResult?.serviceMapNodeRootCauseList || [];
            R.forEach((item) => {
              const { nodeName: newNodeName, metricName } = item?.root || {};
              if (nodeName === newNodeName) {
                const rootCauseMap = findActiveRootCauseNodes(item);
                if (!rcMap[nodeName]) {
                  rcMap[nodeName] = { metricNames: {}, rootCauseMap: {} };
                }
                rcMap[nodeName].metricNames[metricName] = metricName;
                rcMap[nodeName].rootCauseMap = R.mergeDeepWith(
                  R.concat,
                  rcMap[nodeName].rootCauseMap || {},
                  rootCauseMap,
                );
              }
            }, serviceMapNodeRootCauseList);
            this.setState({ nodeRootCauseMap: { ...(nodeRootCauseMap || {}), ...rcMap }, isGroupingLoading: false });
          } else {
            this.setState({
              nodeRootCauseMap: { ...(nodeRootCauseMap || {}), [nodeName]: {} },
              isGroupingLoading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({ isGroupingLoading: false });
          console.error(e);
        });
    }
  }

  @autobind
  async exportPDF(systemInfo) {
    try {
      if (this.exportPDFing) {
        return;
      }
      this.exportPDFing = true;
      this.setState({ exportPDFing: true });
      const { location } = this.props;
      const { startTime, endTime } = parseLocation(location);
      const pdfFilename = `${systemInfo.name}-${startTime}-${endTime} ServiceMap.pdf`;
      const pdfContents = document.createElement('div');
      pdfContents.setAttribute('id', 'divToPrint');

      document.body.appendChild(pdfContents);
      const height = this.filterOptions.length * 32 + 160;
      pdfContents.setAttribute(
        'style',
        `min-width: 210mm; 
        width: 100%; 
        height: 100%; 
        overflow: auto; 
        margin-left: auto; 
        margin-right: auto; 
        background-color: white;
        position: absolute;
        top: -100000px;
      `,
      );
      const clientContent = document.createElement('div');
      clientContent.setAttribute(
        'style',
        `width: 100%; 
        min-height: 100%; 
        height: ${height + 84}px; 
        margin-left: auto; 
        margin-right: auto; 
        background-color: white;
      `,
      );
      clientContent.setAttribute('class', 'flex-col');
      const exportContainer = document.getElementById('export-container');
      clientContent.appendChild(exportContainer);

      await pdfContents.appendChild(clientContent);
      await sleep(600);
      await downloadPdf(pdfFilename, [pdfContents], () => {
        this.setState({ exportPDFing: false }, () => {
          document.getElementById('export-container-parent').appendChild(exportContainer);
          document.body.removeChild(pdfContents);
        });
        this.exportPDFing = false;
      });
    } catch (e) {
      console.log(e);
      this.setState({ exportPDFing: false });
      this.exportPDFing = false;
    }
  }

  @autobind
  onFilterShowChange(filterOptions) {
    this.filterOptions = filterOptions || [];
  }

  render() {
    const { intl, isAdmin, isLocalAdmin, userInfo, favorites, isDark, isReadUser } = this.props;
    const {
      startTimeObj,
      endTimeObj,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
      projectOwner,
      selectedZone,
      isLoadingSystem,
      systemList,
      showSystemEditModal,
      incident,
      isGroupingLoading,
      summaryObj,
      systemSearch,
      systemInfo,
      exportPDFing,
    } = this.state;

    const isLoading = isLoadingSystem || isGroupingLoading;
    const zoneSet = systemInfo?.systemInfo?.zoneSet || [];
    const { serviceMapNote, nodeRootCauseMap, serviceMapData, selectedTime, k8sSelectedTime } = this.state;
    const { incidentCountByZone, incidentCountByZoneForPod, isK8s } = this.state;
    const zoneOptions = this.getZoneOptions(
      zoneSet,
      systemInfo?.id,
      incidentCountByZone || {},
      incidentCountByZoneForPod || {},
    );
    return (
      <Container
        fullHeight
        withGutter
        className={`flex-col corner-10  ${exportPDFing ? 'loading' : ''}`}
        style={{ borderRadius: 10 }}
        id="export-container-parent"
      >
        <div id="export-container" className="full-height with-gutter flex-col corner-10">
          <div className="flex-row flex-space-between" style={{ margin: '8px 16px 16px 16px', alignItems: 'end' }}>
            <div className="flex-row flex-center-align" style={{ alignItems: 'end' }}>
              <PublicBuildCizizen
                summaryObj={summaryObj}
                systemList={systemList}
                systemId={systemSearch}
                handleSystemIdChange={this.handleSystemClick}
                isServiceMap
                userInfo={userInfo}
                favorites={favorites}
              />
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
              >
                <Button size="small" disabled={isReadUser} onClick={this.handleSystemEditClick}>
                  Service Map Configuration
                </Button>
              </Popover>
            </div>
            <div className="flex-row flex-grow flex-end-justify flex-center-align" style={{ fontSize: 12 }}>
              <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                <span style={{ fontWeight: 700, padding: '0 1em' }}>Zone</span>
                <Select
                  showArrow={false}
                  showSearch
                  size="small"
                  style={{ width: 360, marginLeft: 8 }}
                  optionFilterProp="children"
                  value={selectedZone}
                  onChange={this.onChangeFilterZone}
                  filterOption={(input, option) => {
                    return option && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  dropdownMatchSelectWidth={false}
                >
                  {zoneOptions.map((item) => (
                    <Select.Option key={item.value} title={item.label}>
                      <div className="flex-row">
                        <span style={{ width: 180 }}>{item.label}</span>
                        <span style={{ width: 86 }}>Incident count:</span>
                        <span className="flex-grow" style={{ textAlign: 'right' }}>
                          <Tooltip title="Instance" mouseEnterDelay={0.3} placement="top">
                            <DatabaseOutlined style={{ paddingRight: 8 }} />
                            <span>{item.count}</span>
                          </Tooltip>
                          {isK8s && (
                            <Tooltip title="Pod" mouseEnterDelay={0.3} placement="top" style={{ paddingRight: 16 }}>
                              <CodeSandboxOutlined style={{ paddingRight: 8, paddingLeft: 8 }} />
                              <span>{item.podCount}</span>
                            </Tooltip>
                          )}
                        </span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.date)}</span>
                <Button
                  icon={<LeftOutlined />}
                  size="small"
                  onClick={() => {
                    this.handleTimeChange(startTimeObj.clone().subtract(1, 'days'));
                  }}
                />
                <DatePicker
                  size="small"
                  allowClear={false}
                  showToday
                  value={startTimeObj}
                  disabledDate={(current) => {
                    return current && current > moment.utc().add(1, 'days').endOf('day');
                  }}
                  onChange={this.handleTimeChange}
                />
                <Button
                  icon={<RightOutlined />}
                  size="small"
                  disabled={endTimeObj.endOf('day') >= moment.utc().add(1, 'days').endOf('day')}
                  onClick={() => {
                    this.handleTimeChange(startTimeObj.clone().add(1, 'days'));
                  }}
                />
              </div>
              {(isAdmin || isLocalAdmin) && (
                <div className="flex-row flex-center-align" style={{ marginLeft: 8, marginRight: 8 }}>
                  <div style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.user)}</div>
                  <Select
                    allowClear
                    showArrow={false}
                    showSearch
                    size="small"
                    style={{ width: 100, marginLeft: 8 }}
                    optionFilterProp="children"
                    value={projectOwner}
                    onChange={this.onChangeFilterOwner}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    dropdownMatchSelectWidth={false}
                  >
                    {this.userListOptions.map((item) => (
                      <Select.Option key={item.value} title={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              <Tooltip
                mouseEnterDelay={0.3}
                placement="bottomRight"
                visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                title={
                  disableRefresh
                    ? 'Range of days <= 31'
                    : timeChange
                    ? intl.formatMessage(appMessages.clickToReload)
                    : null
                }
              >
                <Button size="small" disabled={disableRefresh} onClick={this.handleRefreshClick}>
                  {intl.formatMessage(appButtonsMessages.refresh)}
                </Button>
              </Tooltip>
              <div style={{ marginLeft: 8 }}>
                <Button.Group style={{ marginRight: 8 }}>
                  <Tooltip title="Export PDF" placement="topRight">
                    <Button
                      size="small"
                      icon={<PDFIcon style={{ color: 'white', fontSize: 18 }} />}
                      onClick={() => this.exportPDF(R.find((system) => system.id === systemSearch, systemList))}
                      Tooltip="Export PDF"
                    />
                  </Tooltip>
                </Button.Group>
              </div>
            </div>
          </div>
          <Container
            fullHeight
            className="flex-grow flex-col flex-min-height content-bg corner-10"
            style={{ margin: '0 16px 8px 16px', padding: 8 }}
          >
            <ServiceMapView
              intl={intl}
              isDark={isDark}
              isLoading={isLoading}
              startTimeObj={startTimeObj}
              endTimeObj={endTimeObj}
              userInfo={userInfo}
              systemInfo={systemInfo}
              serviceMapNote={serviceMapNote}
              serviceMapData={serviceMapData}
              selectedTime={selectedTime}
              onChangeTime={(time) => this.setState({ selectedTime: time })}
              k8sSelectedTime={k8sSelectedTime}
              onChangeK8sTime={(time) => this.setState({ k8sSelectedTime: time })}
              selectedZone={selectedZone}
              nodeRootCauseMap={nodeRootCauseMap}
              onActiveNodeChange={this.onActiveNodeChange}
              onFilterShowChange={this.onFilterShowChange}
            />
          </Container>
          {showSystemEditModal && <ServiceMapSettingModal incident={incident} onClose={this.handleSystemEditClose} />}
        </div>
      </Container>
    );
  }
}

const GlobalServiceMap = injectIntl(GlobalServiceMapCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { isAdmin, isLocalAdmin, isReadUser } = state.auth.userInfo;
    const { loaderStatus, userList, loadStatus, systemsMap, projects, allProjects, globalInfo, favorites } = state.app;
    const { currentTheme, timezoneOffset } = state.app;
    const isDark = currentTheme === 'dark';
    return {
      location,
      loaderStatus,
      loadStatus,
      userInfo,
      credentials,
      userList,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      systemsMap,
      projects,
      allProjects,
      globalInfo,
      favorites,
      isDark,
      timezoneOffset,
    };
  },
  { push, replace, hideAppLoader, showAppLoader },
)(GlobalServiceMap);
