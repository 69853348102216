import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import tinygradient from 'tinygradient';
import moment from 'moment';
import { get } from 'lodash';

import { Spin, message } from 'antd';
import { EChart } from '../../share';
import { anomalousOptionHtml } from '../components/OptionHtml';
import DashCellTitle from '../components/DashCellTitle';
import HorizontalchartDetail from '../components/HorizontalchartDetail';
import fetchGet from '../../../common/apis/fetchGet';
import { Defaults } from '../../../common/utils';
import getEndpoint from '../../../common/apis/getEndpoint';
import { getCacheData, setCacheData } from '../utils';

const pieChartStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
};

const typeDistinguish = {
  anomalousProjects: 'project',
  anomalousComponents: 'component',
  anomalousInstances: 'instance',
};

const nameDistinguish = {
  anomalousProjects: 'projectName',
  anomalousComponents: 'componentName',
  anomalousInstances: 'instanceName',
};

const titleDistinguish = {
  anomalousProjects: 'Projects(Top anomalous)',
  anomalousComponents: 'Components(Top anomalous)',
  anomalousInstances: 'Instances(Top anomalous)',
};

const topTitleDistinguish = {
  anomalousProjects: 'Projects',
  anomalousComponents: 'Components',
  anomalousInstances: 'Instances',
};

const NoDataTitleDistinguish = {
  anomalousProjects: 'anomalous projects',
  anomalousComponents: 'anomalous components',
  anomalousInstances: 'anomalous incidents',
};

export default function ComponentsAnomalous(props: Object) {
  const {
    type = 'anomalousComponents',
    intl,
    currentTheme,
    systemId,
    credentials,
    startTime,
    endTime,
    environmentId,
    globalInfo,
    widgetInfo,
    instanceDisplayNameMap,
    getPopUpPosition,
  } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    option: {},
    barData: [],
    showHorizontalchartDetail: false,
    isLoading: false,
  });
  const echartBoxRef = useRef(null);
  const echartRef = useRef(null);
  const { option, barData, showHorizontalchartDetail, isLoading } = state;
  const projectNameListStr = JSON.stringify(widgetInfo?.params?.projectNameList);

  const getPieOption = (data, category, gradientColorsList) => {
    const gradient = tinygradient(gradientColorsList);
    const colorsRgb = gradient.rgb(data.length < 2 ? 2 : data.length);
    const colors = R.map((x) => {
      return x.toHexString();
    }, colorsRgb);
    const option = {
      backgroundColor: 'transparent',
      position: (pos, params, dom, rect, size) => {
        return getPopUpPosition(widgetInfo, pos, size);
      },
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'item',
        enterable: true,
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          const { data } = params;
          return anomalousOptionHtml({ data, intl, typeName: nameDistinguish[type], instanceDisplayNameMap });
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      xAxis: {
        type: 'value',
        axisLine: { show: true },
        splitArea: { show: false },
        axisTick: { show: false, inside: true },
        axisLabel: { show: false },
        splitLine: { show: false },
      },
      yAxis: {
        type: 'category',
        inverse: true,
        data: category,
        axisTick: { show: false, inside: true },
        axisLabel: { fontSize: 11, width: '100', overflow: 'truncate' },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [
        {
          name: titleDistinguish[type],
          type: 'bar',
          data,
          barMaxWidth: '36px',
          itemStyle: {
            color: (item) => {
              const { dataIndex } = item;
              return colors[dataIndex];
            },
          },
          emphasis: {
            itemStyle: {
              color: '#097fd7',
              borderWidth: 0,
            },
          },
        },
      ],
      grid:
        data.length > 0
          ? { top: 30, left: '43%', right: '17%', bottom: 10 }
          : { top: 30, left: 10, right: 10, bottom: 10 },
      label: {
        show: true,
        position: 'right',
        formatter: (params) => {
          const { data } = params;
          let scoreVla = data.value;
          if (scoreVla >= 1000000) {
            scoreVla = `${Number(Number.parseFloat(scoreVla / 1000000).toFixed(2))}M`;
          } else if (scoreVla >= 1000) {
            scoreVla = `${Number(Number.parseFloat(scoreVla / 1000).toFixed(2))}K`;
          } else {
            scoreVla = `${Number(Number.parseFloat(scoreVla).toFixed(2))}`;
          }
          return scoreVla;
        },
      },
      itemStyle: {
        borderRadius: [0, 5, 5, 0],
      },
    };
    return option;
  };

  const parseData = (data) => {
    const { topComponents } = data ? R.clone(data) : {};

    let anomalies = [];
    R.forEachObjIndexed((score, c) => {
      anomalies.push({ componentName: c, score });
    }, topComponents || {});
    anomalies = R.sortWith([R.descend(R.prop('score'))], anomalies);
    anomalies = R.map(
      (item) => ({ ...item, value: item.score < 1 ? item.score : Math.floor(item.score) }),
      anomalies || [],
    );
    const newAnomalies = R.take(3)(anomalies);
    const categoryList = R.map((item) => item[nameDistinguish[type]], newAnomalies || []);
    setState({ option: getPieOption(newAnomalies, categoryList, ['#043e7d', '#6abbf7']), barData: anomalies || [] });
  };

  const reloadData = (systemInfo) => {
    const params = {
      ...credentials,
      customerName: systemInfo?.ownerUserName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      cacheKey: 'topAnomalousComponents',
    };
    if (widgetInfo?.params?.projectNameList && widgetInfo?.params?.projectNameList.length > 0) {
      params.projectNameList = JSON.stringify(widgetInfo?.params?.projectNameList);
    }

    const apiData = getCacheData(params);
    if (apiData) {
      parseData(apiData);
      return;
    }
    if (!systemId) return;
    setState({ isLoading: true });
    fetchGet(getEndpoint('top-anomalous'), R.clone(params))
      .then(async (data) => {
        parseData(data);
        setCacheData(params, data);
        setState({ isLoading: false });
      })
      .catch((e) => {
        setState({ isLoading: false });
        console.error(e.message || String(e));
      });
  };

  useEffect(() => {
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo) {
      reloadData(systemInfo);
    } else {
      setState({ option: getPieOption([], [], ['#043e7d', '#6abbf7']) });
    }
  }, [systemId, projectNameListStr]);

  useEffect(() => {
    if (echartRef.current && echartBoxRef.current) {
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.setOption(option);
    }
  }, [currentTheme]);

  const hasHealthOption = ((option?.series || [])[0]?.data || []).length < 1;

  return (
    <Spin spinning={isLoading} wrapperClassName="spin-base overflow-x-hidden overflow-y-auto">
      <DashCellTitle
        title={topTitleDistinguish[type]}
        subTitle="Top anomalous"
        type={type}
        fillListFlag={barData.length > 0}
        setShowDetail={(showHorizontalchartDetail) => setState({ showHorizontalchartDetail })}
        setTypeName={(typeName) => {}}
      />
      <div className="flex-row flex-center-align" style={{ height: 'calc(100% - 48px)' }} ref={echartBoxRef}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          {!hasHealthOption && (
            <div
              className="font-14"
              style={{ position: 'absolute', left: '50%', transform: 'translate(-15%, 0)', fontWeight: 'bold' }}
            >
              Anomaly score
            </div>
          )}
          <EChart
            setRef={(chart) => {
              echartRef.current = chart;
            }}
            option={option}
            style={pieChartStyle}
            className="chart-pie"
            theme={currentTheme}
          />
          {hasHealthOption && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-55%, -30%)',
                color: 'var(--text-color-secondary)',
                width: 160,
              }}
            >
              No data for this time period
            </div>
          )}
        </div>
      </div>

      {showHorizontalchartDetail && (
        <HorizontalchartDetail
          {...props}
          intl={intl}
          barData={barData}
          open={showHorizontalchartDetail}
          onCancel={() => setState({ showHorizontalchartDetail: false })}
          typeName={type}
          titleDistinguish={titleDistinguish}
          nameDistinguish={nameDistinguish}
          currentTheme={currentTheme}
          systemId={systemId}
        />
      )}
    </Spin>
  );
}
