/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 ** */

import loadMetricAppForecastDataEpic from './loadMetricAppForecastDataEpic';
import loadMetricEventLogListEpic from './loadMetricEventLogListEpic';
import updateMetricEventPatternNameEpic from './updateMetricEventPatternNameEpic';
import updateMetricPatternFlagEpic from './updateMetricPatternFlagEpic';
import loadMetricEventCausalResultEpic from './loadMetricEventCausalResultEpic';

const epics = [
  loadMetricAppForecastDataEpic,
  loadMetricEventLogListEpic,
  loadMetricEventCausalResultEpic,
  updateMetricEventPatternNameEpic,
  updateMetricPatternFlagEpic,
];

export default epics;
