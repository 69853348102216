/* @flow */

import { defineMessages } from 'react-intl';

const usecaseMessages = defineMessages({
  infoBugIssueRemoved: {
    defaultMessage: 'This issue is removed',
    id: 'usecase.info.infoBugIssueRemoved',
  },
});

export default usecaseMessages;
