/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import { get, isNumber, isEmpty } from 'lodash';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';

import { State } from '../../../common/types';
import { Modal } from '../../../../artui/react';
import { Container, Scrollbars, AutoSizer } from '../../../lib/fui/react';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/metric/actions';
import { getLoadStatus } from '../../../common/utils';
import LineChartCausalPrediction from './LineChartCausalPrediction';
import LineChartLogEvents from './LineChartLogEvents';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,
  onClose: Function,

  project: Object,
  appNameMapping: Object,
  selectedEvent: Object,
  lineChartsMetricDetails: Object,
};

class MetricLineChartEventDetailsModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.width = 940;
    this.height = 640;
    this.dataLoader = 'linechart_detail';
    this.expandKey = '__linechart_detail';
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { lineChartsMetricDetails } = nextProps;
    if (lineChartsMetricDetails !== this.props.lineChartsMetricDetails) {
      this.preprocessData(nextProps);
    }
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  @autobind
  preprocessData(props) {}

  @autobind
  reloadData(props) {
    const { selectedEvent, createLoadAction, projectName } = props;
    const { causalFetchedInfoFileName, userName, startTimestamp, endTimestamp } = selectedEvent;
    const rcList = get(selectedEvent, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    const instanceNameList = R.uniq(R.map((r) => r.instanceId, rcList));

    createLoadAction(
      ActionTypes.LOAD_METRIC_LINECHARTS_DETAILS,
      {
        customerName: userName,
        causalFetchedInfoFileName,
        projectName,
        instanceNameList,
        startTimestamp,
        endTimestamp,
      },
      this.dataLoader,
    );
  }

  @autobind
  handleExpandClick(rc) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      const isExpand = get(rc, this.expandKey, true);
      rc[this.expandKey] = !isExpand;
      this.forceUpdate();
    };
  }

  @autobind
  renderRootCause(idx, rc) {
    const { pct, rootCauseMetric, rootCauseSource, metricValue, direction, instanceId } = rc;
    const { lineChartsMetricDetails, appNameMapping } = this.props;
    const isDown = rootCauseSource === 'missing data';
    const name = rc.appName && rc.appName !== instanceId ? `${rc.appName}(${instanceId})` : instanceId;
    const isExpand = get(rc, this.expandKey, true);
    let logEntryCausalList = [];
    let logEntryList = [];
    if (isExpand) {
      const allCausalList = get(lineChartsMetricDetails, ['logEntryCausalList'], []);
      logEntryList = get(lineChartsMetricDetails, ['eventLogListMap', instanceId], []);

      logEntryCausalList = R.filter((container) => {
        const metric = get(container, ['srcDetails', 'content'], '').toLocaleLowerCase();
        const iid = get(container, ['srcDetails', 'instanceId'], '').toLocaleLowerCase();
        return metric === (rootCauseMetric || '').toLocaleLowerCase() && iid === (instanceId || '').toLocaleLowerCase();
      }, allCausalList);
    }

    return (
      <div key={idx}>
        <div
          className="flex-row flex-center-align"
          style={{ height: 32, cursor: 'pointer', paddingRight: 16 }}
          onClick={this.handleExpandClick(rc)}
        >
          <i
            className={`icon angle ${isExpand ? 'down' : 'right'}`}
            style={{ flex: '0 0 20px', cursor: 'pointer' }}
            onClick={this.handleExpandClick(rc)}
          />
          <div className="flex-grow">
            {!isDown && <span style={{ color: 'blue' }}>{rootCauseMetric}</span>}
            {!isDown && <span style={{ paddingRight: 4 }}>({metricValue})</span>}
            {!isDown && <span style={{ color: 'red' }}>{` ${isNumber(pct) ? pct : ''}%`}</span>}
            {!isDown && <span>{` ${direction} than `}</span>}
            {!isDown && <span>{`${rootCauseSource} on `}</span>}
            <span style={{ color: 'green', display: 'inline-block' }}>{name}</span>
            {isDown && <span style={{}}>{' is '}</span>}
            {isDown && <span style={{ color: 'red' }}>down</span>}
          </div>
        </div>
        {isExpand &&
          logEntryCausalList.length > 0 && (
            <div style={{ paddingLeft: 20, paddingRight: 16 }}>
              <div style={{ fontWeight: 'bold', lineHeight: '20px' }}>Causal Prediction</div>
              <LineChartCausalPrediction
                width={this.width - 56}
                height={Math.min(5, logEntryCausalList.length) * 70 + 40}
                logEntryCausalList={logEntryCausalList}
                appNameMapping={appNameMapping}
              />
            </div>
          )}
        {isExpand &&
          logEntryList.length > 0 && (
            <div style={{ paddingLeft: 20, paddingRight: 16 }}>
              <div style={{ fontWeight: 'bold', lineHeight: '20px' }}>Log Events</div>
              <LineChartLogEvents
                width={this.width - 56}
                height={Math.min(5, logEntryList.length) * 44 + 80}
                logEntryList={logEntryList}
              />
            </div>
          )}
        {isExpand &&
          logEntryCausalList.length === 0 &&
          logEntryList.length === 0 && (
            <div style={{ paddingLeft: 20 }}>No anomaly log events and causal prediction found</div>
          )}
      </div>
    );
  }

  render() {
    const { intl, loadStatus, selectedEvent, lineChartsMetricDetails } = this.props;
    const { isLoading } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const rcList = isEmpty(lineChartsMetricDetails)
      ? []
      : get(selectedEvent, ['rootCauseJson', 'rootCauseDetailsArr'], []);

    return (
      <Modal size="big" closable onClose={this.handleClose}>
        <AutoSizer disableHeight>
          {({ width }) => {
            this.width = width;
            return (
              <Container
                className={`${isLoading ? 'loading ' : ''}flex-col`}
                style={{
                  width: width - 20,
                  height: this.height - 26,
                  margin: '16px 10px 10px 10px',
                  fontSize: 12,
                }}
              >
                <Scrollbars style={{ height: '100%', width: '100%' }}>
                  {R.addIndex(R.map)((rc, idx) => this.renderRootCause(idx, rc), rcList)}
                </Scrollbars>
              </Container>
            );
          }}
        </AutoSizer>
      </Modal>
    );
  }
}

const MetricLineChartEventDetailsModal = injectIntl(MetricLineChartEventDetailsModalCore);
export default connect(
  (state: State) => {
    const { loadStatus } = state.app;
    const lineChartsMetricDetails = get(state.metric, ['lineChartsMetricDetails'], {});

    return { loadStatus, lineChartsMetricDetails };
  },
  { createLoadAction },
)(MetricLineChartEventDetailsModal);
