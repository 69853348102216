/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { get } from 'lodash';
import type { QueryState, Action } from '../types';
import { ActionTypes } from './actions';

const initialState: QueryState = {
  templateMap: {},
  templateList: [],
  templateCategoryList: [],
  queryResult: {},
};

const reducer = (state: QueryState = initialState, action: Action): QueryState => {
  if (action.type === ActionTypes.SET_QUERY_TEMPLATE_LIST) {
    const templateList = get(action.payload, 'data', []);
    // Group the template by category and also create an id keyed map.
    const templateMap = R.zipObj(R.map((t) => t.id, templateList), templateList);

    const templateCategoryList = [];
    R.forEachObjIndexed((val, key) => {
      templateCategoryList.push({ name: key, templateList: val });
    }, R.groupBy((t) => t.category, templateList));

    return {
      ...state,
      templateList,
      templateMap,
      templateCategoryList,
    };
  } else if (action.type === 'SET_QUERY_RESULT') {
    const { params, data } = action.payload;
    return {
      ...state,
      queryResult: { params, queryResultList: data },
    };
  } else if (action.type === 'SET_APP_NAME_MAP') {
    const { data } = action.payload;
    const { appNameMapping } = data;
    return {
      ...state,
      appNameMapping,
    };
  }

  return { ...initialState, ...state };
};

export default reducer;
