/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';

import colorMap from './colorMap';

type Props = {
  ownerObject: Object,
  elements: Array<Element<any>>,
  onChanged: Function,
  width: Number,
  style: Object,
};

class CellCollapsibeList extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.expandedStateKey = '__expanded_state__';
    let showAll = false;
    if (props.ownerObject) {
      showAll = Boolean(props.ownerObject[this.expandedStateKey]);
    }
    this.state = { showAll };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.ownerObject !== this.props.ownerObject) {
      this.setState({ showAll: false });
    }
  }

  @autobind
  handleEllipsisClicked() {
    this.setState({ showAll: !this.state.showAll }, () => {
      const { ownerObject, onChanged } = this.props;
      const { showAll } = this.state;
      if (ownerObject) {
        ownerObject[this.expandedStateKey] = showAll;
      }
      if (onChanged) {
        onChanged(showAll);
      }
    });
  }

  render() {
    const { elements, style, width } = this.props;
    const { showAll } = this.state;
    if (elements.length > 0) {
      return (
        <div style={{ ...style, width, padding: showAll ? '6px 0px' : '' }}>
          <div style={{ width: width - 16, display: 'inline-block' }} className={showAll ? 'left-hint-bar' : ''}>
            <div style={{ width: '100%' }} className="white-pre">
              {elements[0]}
            </div>
            {showAll &&
              R.addIndex(R.map)(
                (l, idx) => (
                  <div key={idx + 1} style={{ width: '100%' }} className="white-pre">
                    {l}
                  </div>
                ),
                R.drop(1, elements),
              )}
          </div>
          {elements.length > 1 && (
            <i
              className={`${showAll ? 'angle up' : 'angle down'} icon clickable`}
              style={{ verticalAlign: 'top', color: 'var(--text-color)', fontSize: 14 }}
              onClick={this.handleEllipsisClicked}
            />
          )}
        </div>
      );
    }

    return null;
  }
}

export default CellCollapsibeList;
