import React from 'react';
import { autobind } from 'core-decorators';
import qs from 'qs';
import { Button, message, Spin } from 'antd';

import fetchPostForm from '../../../../common/apis/fetchPostForm';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Modal } from '../../../../lib/fui/react';

import { appButtonsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  onOk: function,
  onClose: Function,
  project: Object,
  credentials: Object,
};

class MetricFileModal extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {
      fileData: null,
      filename: null,
      isLoading: false,
      btnLoading: false,
    };
  }

  componentDidMount() {}

  @autobind
  handleFileChanged(e) {
    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const { type, name: filename } = file;
      if (type === 'text/csv' || filename.indexOf('.csv') >= 0) {
        this.setState({ fileData: file, filename });
      } else {
        this.setState({ fileData: null, filename: null });
      }
    }
  }

  @autobind
  async handleSubmit() {
    const { fileData } = this.state;
    const { project, credentials } = this.props;
    const { name } = fileData || {};
    const formData = new FormData();
    formData.append('data', fileData);
    formData.append('projectName', project?.projectName);
    formData.append('fileName', name);
    formData.append('customerName', project?.owner);
    this.setState({ btnLoading: true });
    fetchPostForm(
      `${getEndpoint('uploadMetricSetting')}?${qs.stringify({
        ...credentials,
        projectName: project?.projectName,
        customerName: project?.owner,
      })}`,
      formData,
    )
      .then((data) => {
        message.success('upload success');
        this.setState({ btnLoading: false }, () => {
          this.props.onOk();
        });
      })
      .catch((err) => {
        this.setState({ btnLoading: false });
        message.error(String(err));
      });
  }

  render() {
    const { intl, onClose } = this.props;
    const { isLoading, btnLoading, filename } = this.state;

    return (
      <>
        <Modal
          title="Metric configuration"
          width={500}
          visible
          maskClosable={false}
          onCancel={() => onClose()}
          onOk={this.handleSubmit}
          okButtonProps={{ loading: btnLoading, disabled: !filename }}
        >
          <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
            <div className="content overflow-y-auto overflow-x-hidden full-width">
              <div className="flex-row">
                <Button style={{ background: '#767676', color: '#fff', marginRight: 10, width: '100%' }}>
                  <div className="ui fileinput-button" style={{ position: 'relative' }}>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      style={{ width: 340 }}
                      onChange={this.handleFileChanged}
                    />
                    {intl.formatMessage(appButtonsMessages.selectFile)}
                  </div>
                </Button>
              </div>
            </div>
          </Spin>
        </Modal>
      </>
    );
  }
}

export default MetricFileModal;
