/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';
import * as R from 'ramda';

import type { Deps } from '../../types';
import { saveProjectSettings, getInitData } from '../../apis';
import { apiEpicErrorHandle } from '../../errors';
import {
  showAppAlert,
  setLoadingComponents,
  updateLastActionInfo,
  loadProjectInfo,
  setInitData,
} from '../../app/actions';
import { loadProjectSettings } from '../actions';
import { settingsMessages } from '../messages';
import { appMessages } from '../../app/messages';

const saveProjectSettingsEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('SAVE_PROJECT_SETTINGS').concatMap((action) => {
    const state = getState();

    const { credentials, userInfo } = state.auth;
    const { projects } = state.app;
    const { projectName, ...params } = state.settings.projectSettingsParams;
    const { settings, components, field } = action.payload;

    // TODO: Based on the settings, call different API to check settings.
    return Observable.concat(
      Observable.of(setLoadingComponents(components)),
      Observable.of(updateLastActionInfo()),
      Observable.from(saveProjectSettings(credentials, projectName, settings, projects))
        .concatMap((res) => {
          if (res.success === false) {
            return Observable.of(
              showAppAlert('error', appMessages.errorsGeneral, {}, { duration: field === 'systemName' ? 0 : 3 }),
            );
          }
          if (field === 'displayName' || Object.keys(settings).includes('displayName')) {
            return Observable.from(getInitData(credentials, userInfo))
              .concatMap((d) => {
                return Observable.concat(
                  Observable.of(setInitData(d)),
                  Observable.of(showAppAlert('info', settingsMessages.infoProjectSettingSaved, {}, { duration: 3 })),
                );
              })
              .catch((err) => {
                return apiEpicErrorHandle(err);
              });
          }

          // The response must be sucess, otherwise it will throw error.
          return Observable.of(
            showAppAlert(
              'info',
              field === 'systemName'
                ? settingsMessages.infoProjectSettingSavedSystemName
                : settingsMessages.infoProjectSettingSaved,
              {},
              { duration: field === 'systemName' ? 0 : 3 },
            ),
          );
        })
        .catch((err) => {
          return apiEpicErrorHandle(err);
        }),
      // Reset all loading components' status.
      Observable.of(setLoadingComponents(R.mapObjIndexed(() => false, components))),
      // Reload the settings
      Observable.of(loadProjectSettings(projectName, params, true)),
      // Reload ptoject info
      Observable.of(loadProjectInfo({ projectName })),
    );
  });

export default saveProjectSettingsEpic;
