/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import * as R from 'ramda';
import { get } from 'lodash';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getLogInstanceList } from '../../apis';
import { appMessages } from '../../app/messages';

const loadLogInstanceInfoLogic = createLogic({
  type: [ActionTypes.LOAD_LOG_INSTANCEINFO_LIST],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { hideLoading: globalHideLoading } = getLoadStatusActions();
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getLogInstanceList(credentials, params)
      .then((data) => {
        const instanceInfoList = get(data, ['instanceInfoList'], []);
        const instanceInfoTotal = get(data, ['instanceInfoTotal'], []);

        dispatch(
          createSetAction(ActionTypes.SET_LOG_INSTANCEINFO_LIST, params, { instanceInfoList, instanceInfoTotal }),
        );
      })
      .catch((err) => {
        console.error('[IF_API] Failed get instance list', err);
        dispatch(
          createSetAction(ActionTypes.SET_LOG_INSTANCEINFO_LIST, params, {
            instanceInfoList: [],
            instanceInfoTotal: 0,
          }),
        );
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }

        dispatch(globalHideLoading);
        dispatch(hideLoading);
        done();
      });
  },
});

export default loadLogInstanceInfoLogic;
