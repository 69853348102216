/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';

import { autobind } from 'core-decorators';
import { get } from 'lodash';
import VLink from 'valuelink';
import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { Modal, Input } from '../../../lib/fui/react';
import { appButtonsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

type Props = {
  intl: Object,
  errorMessage: ?Object,
  currentLoadingComponents: Object,

  causalName: String,
  causalKey: String,
  customerName: String,
  removeCausalGroup: Function,
  onClose: Function,
};

class RemoveGroupModal extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.submitLoader = 'causal_remove_group_submit';
    this.sumbitting = false;

    this.state = {
      causalName: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLoadingComponents, errorMessage } = nextProps;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      if (!errorMessage) {
        this.props.onClose(true);
      }
    }
  }

  @autobind
  handleOnClose() {
    this.props.onClose(false);
  }

  @autobind
  handleSumbit() {
    const { removeCausalGroup } = this.props;
    const causalKey = (this.props.causalKey || '').trim();
    const customerName = (this.props.customerName || '').trim();
    this.sumbitting = true;
    removeCausalGroup({ causalKey, customerName }, { [this.submitLoader]: true });
  }

  render() {
    const { intl, errorMessage, causalName, currentLoadingComponents } = this.props;

    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    const causalNameLink = VLink.state(this, 'causalName').check(
      (x) => (x || '').trim() === causalName.trim(),
      'input the causal name',
    );
    const hasError = causalNameLink.error;

    return (
      <Modal
        width={650}
        title={intl.formatMessage(causalMessages.removeCausalGroup)}
        visible
        footer={null}
        onCancel={this.handleOnClose}
        maskClosable={false}
      >
        <div className="content" style={{ paddingBottom: 0 }}>
          <div className="ui error message">
            <i className="icon warning sign" />
            <span>
              Remove Causal Group will also remove all causal analysis of that group, input the causal name{' '}
              <b>{causalName}</b> below to confirm.
            </span>
          </div>
          <form className={`ui ${hasError ? 'error' : ''} form`}>
            <div className="input inline wide field flex-row" key={name}>
              <label style={{ width: 160, margin: 'auto' }}>{intl.formatMessage(causalMessages.causalName)}:</label>
              <Input valueLink={causalNameLink} fullWidth />
            </div>
          </form>
        </div>
        {Boolean(errorMessage) && (
          <div className="error content">
            <div className="ui error mini message">
              <i className="icon warning sign" />
              <span>{intl.formatMessage(errorMessage.message, errorMessage.params)}</span>
            </div>
          </div>
        )}
        <div className="flex-row flex-end-justify">
          <Button size="small" onClick={this.handleOnClose}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<SaveOutlined />}
            style={{ marginLeft: 8 }}
            disabled={hasError}
            loading={isLoading}
            onClick={this.handleSumbit}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default RemoveGroupModal;
