/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip, Select, DatePicker, message, Divider, Checkbox } from 'antd';
import momenttz from 'moment-timezone';

import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import { State } from '../../common/types';
import { parseLocation, buildLocation, pickNotNil, Defaults } from '../../common/utils';
import {
  createLoadAction,
  showAppLoader,
  createSetAction,
  loadProjectInfo,
  updateLastActionInfo,
} from '../../common/app/actions';
import { Container, AutoSizer } from '../../lib/fui/react';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../common/app/messages';
import { eventMessages } from '../../common/metric/messages';

import EventList from './components/EventList';
import EventExportModal from './components/EventExportModal';
import AllSystemsMetricChart from './components/AllSystemsMetricChart';
import getInstanceDisplayName from '../../common/utils/getInstanceDisplayName';

type Props = {
  // Common props
  intl: Object,
  location: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,

  // eslint-disable-next-line
  createLoadAction: Function,
  showAppLoader: Function,
  // eslint-disable-next-line
  createSetAction: Function,
  // eslint-disable-next-line
  loadProjectInfo: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,

  userList: Array<Object>,
  systemList: Array,
  // eslint-disable-next-line
  globalInfo: Object,
  currentTheme: String,
  defaultTimezone: String,
  timezoneOffset: Number,
};

class EventsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const query = parseLocation(location);
    const { startTime, endTime, startMonth, endMonth } = query;

    this.state = {
      startTimeObj: moment.utc(startTime, Defaults.DateFormat),
      endTimeObj: moment.utc(endTime, Defaults.DateFormat),
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      isLoading: false,
      allAnomalyInstances: [],
      instanceDisplayNameMap: {},
      instanceList: [],

      // instance selection
      selectInstanceList: [],
      instanceChange: false,
      disableRefreshInstance: false,
      searchValue: '',

      showEventExportModal: false,

      startMonthObj: startMonth ? moment.utc(startMonth, Defaults.DateFormat) : null,
      endMonthObj: endMonth ? moment.utc(endMonth, Defaults.DateFormat) : null,
      pickLastTimeValue: undefined,
      packLastTimeDisbale: false,
    };
    this.pickLastTimeOption = [
      { label: 'Last 15 minutes', value: 900000 },
      { label: 'Last 30 minutes', value: 1800000 },
      { label: 'Last 1 hour', value: 3600000 },
      { label: 'Last 2 hours', value: 7200000 },
      { label: 'Last 6 hours', value: 21600000 },
    ];
  }

  componentDidMount() {
    if (!this.applyParamsAndRedirect(this.props)) {
      this.reloadData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const params = parseLocation(this.props.location);
    const nextParams = parseLocation(nextProps.location);
    if (
      params.systemId !== nextParams.systemId ||
      params.projectName !== nextParams.projectName ||
      params.instanceGroup !== nextParams.instanceGroup ||
      params.startTime !== nextParams.startTime ||
      params.endTime !== nextParams.endTime
    ) {
      if (!this.applyParamsAndRedirect(nextProps)) {
        this.reloadData(nextProps);
      }
      if (nextParams.systemId === 'allSystems' && !this.state.startMonthObj && !this.state.endMonthObj) {
        this.setState({
          startMonthObj: nextParams.startMonth ? moment.utc(nextParams.startMonth, Defaults.DateFormat) : null,
          endMonthObj: nextParams.endMonth ? moment.utc(nextParams.endMonth, Defaults.DateFormat) : null,
        });
      }
    }
  }

  @autobind
  applyParamsAndRedirect(props) {
    const { location, replace, systemList: systemLists, userInfo, globalInfo } = props;
    let projects = R.filter((project) => project.isMetric, props.projects);
    const params = parseLocation(location);
    const { customerName } = params;
    let { projectName, instanceGroup, startTime, endTime, systemId } = params;

    let systemList =
      systemLists.length > 0
        ? [
            { systemName: 'All systems', systemId: 'allSystems' },
            ...systemLists,
            { systemName: 'Projects with no system', systemId: 'projectsWithNoSystem' },
          ]
        : [];

    let redirect = false;

    // System select
    if (userInfo.isAdmin || userInfo.isLocalAdmin) {
      systemList = R.filter(
        (item) =>
          item.owner === customerName || item.systemId === 'projectsWithNoSystem' || item.systemId === 'allSystems',
        systemList,
      );
    }
    R.forEach((item) => {
      item.hasProjectList = [];
      R.forEach((_item) => {
        if (_item.systemId && item.systemId === _item.systemId) {
          item.hasProjectList.push(_item);
        } else if (item.systemId === 'projectsWithNoSystem' && !_item.systemId) {
          item.hasProjectList.push(_item);
        }
      }, projects);
    }, systemList);
    systemList = R.filter((item) => item.hasProjectList.length > 0 || item.systemId === 'allSystems', systemList);
    if (systemList.length > 0 && (!systemId || !R.find((s) => s.systemId === systemId, systemList))) {
      // eslint-disable-next-line prefer-destructuring
      systemId = systemList[1]?.systemId;
    }

    // Project select
    projects = R.filter(
      (item) =>
        systemId === 'projectsWithNoSystem'
          ? item.systemId === undefined
          : item.systemId === systemId && item.systemId !== undefined,
      projects,
    );
    if (!projectName && projects.length > 0) {
      projectName = projects[0].projectName || null;
    }
    const project = R.find((p) => p.projectName === projectName, projects || []);
    const instanceGroupList = get(project, 'groupList', []);
    instanceGroup = instanceGroup || instanceGroupList.length > 0 ? instanceGroupList[0] : null;
    if (!startTime || !endTime) {
      startTime = moment.utc().format(Defaults.DateFormat);
      endTime = moment.utc().format(Defaults.DateFormat);
      this.setState({
        startTimeObj: moment.utc(),
        endTimeObj: moment.utc(),
      });
    }

    // Change number to string to avoid false comparation
    const newParams = pickNotNil({
      ...params,

      projectName,
      instanceGroup,
      startTime,
      endTime,
      systemId,
      environmentId: globalInfo[0]?.id || 'All',
    });
    if (!R.equals(newParams, params)) {
      redirect = true;
      replace(buildLocation(location.pathname, {}, newParams));
    }

    return redirect;
  }

  @autobind
  reloadData(props) {
    const { location, loadProjectInfo } = props;
    let projects = R.filter((project) => project.isMetric, props.projects);
    const params = parseLocation(location);
    const { projectName, startTime, endTime, systemId } = params;
    projects = R.filter(
      (item) =>
        systemId === 'projectsWithNoSystem'
          ? item.systemId === undefined
          : item.systemId === systemId && item.systemId !== undefined,
      projects,
    );
    const project = R.find((p) => p.projectName === projectName, projects);
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

    if (project && startTime && endTime) {
      if (!project.hasAllInfo) {
        loadProjectInfo({ projectName, startTimestamp, endTimestamp }, undefined, this.callbackHandle);
      } else {
        this.reloadAnomalyInstance(props);
      }
    } else if (!project && startTime && endTime) {
      this.setState({
        allAnomalyInstances: [],
        instanceDisplayNameMap: {},
        instanceList: [],
        selectInstanceList: [],
        instanceChange: false,
        disableRefreshInstance: false,
        tooltipVisibleReload: false,
      });
    }
  }

  @autobind
  callbackHandle(success) {
    if (success) {
      this.reloadAnomalyInstance(this.props);
    }
  }

  @autobind
  reloadAnomalyInstance(props) {
    const { intl, location, credentials, replace, updateLastActionInfo } = props;
    let projects = R.filter((project) => project.isMetric, props.projects);
    this.setState({ isLoading: true });
    const params = parseLocation(location);
    const { projectName, startTime, endTime, systemId } = params;
    projects = R.filter(
      (item) =>
        systemId === 'projectsWithNoSystem'
          ? item.systemId === undefined
          : item.systemId === systemId && item.systemId !== undefined,
      projects,
    );
    const project = R.find((p) => p.projectName === projectName, projects || []);
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

    updateLastActionInfo();
    const requests = [
      fetchGet(getEndpoint('instancewithanomaly'), {
        ...credentials,
        projectName,
        UserName: project.owner,
        startTime: startTimestamp,
        endTime: endTimestamp,
      }),
      fetchGet(getEndpoint('instance-display-name'), {
        ...credentials,
        instanceDisplayNameRequestList: JSON.stringify([
          { projectName: project?.projectShortName, customerName: project?.owner },
        ]),
      }),
    ];

    Promise.all(requests)
      .then((res) => {
        const [data, d1] = res || [];
        const allAnomalyInstances = R.keys(data || {});

        // default select instances
        const { instanceList: preInstanceList } = this.state;
        let instanceList = preInstanceList.length > 0 ? R.intersection(preInstanceList, allAnomalyInstances) : [];
        if (instanceList.length === 0) {
          instanceList = allAnomalyInstances;
        }
        const selectInstanceList = instanceList;

        const instanceDisplayNameMap = {};
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, d1 || []);

        this.setState(
          {
            isLoading: false,
            allAnomalyInstances,
            instanceList,
            selectInstanceList,
            instanceChange: false,
            disableRefreshInstance: false,
            tooltipVisibleReload: false,
            instanceDisplayNameMap,
          },
          () => {
            // force refresh
            replace(buildLocation(location.pathname, {}, { ...params, forceRefreshTime: moment.utc().valueOf() }));
          },
        );
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isLoading: false,
          allAnomalyInstances: [],
          instanceDisplayNameMap: {},
          instanceList: [],
          selectInstanceList: [],
          instanceChange: false,
          disableRefreshInstance: false,
          tooltipVisibleReload: false,
        });
      });
  }

  @autobind
  handleRefresh(params) {
    const { location, replace } = this.props;
    let query = parseLocation(location);
    let needReload = true;

    const { startTimeObj, endTimeObj, startMonthObj, endMonthObj, ...rest } = params || {};
    if (startTimeObj && endTimeObj) {
      const startTime = startTimeObj.format(Defaults.DateFormat);
      const endTime = endTimeObj.format(Defaults.DateFormat);
      // update start/end time if changed
      if (startTime !== query.startTime || endTime !== query.endTime) {
        query = { ...query, startTime, endTime };
        needReload = false;
      }
    }

    if (startMonthObj && endMonthObj) {
      const startMonthDays = startMonthObj.format(Defaults.DateFormat);
      const endMonthDays = endMonthObj.format(Defaults.DateFormat);
      const startMonth = startMonthObj.format(Defaults.MonthFormat);
      const endMonth = endMonthObj.format(Defaults.MonthFormat);
      if (startMonthDays !== query.startMonth || endMonthDays !== query.endMonth) {
        query = { ...query, startMonth: startMonthDays, endMonth: endMonthDays };
        if (
          moment.utc(query.selectMonth, Defaults.MonthFormat) > moment.utc(endMonth, Defaults.MonthFormat) ||
          moment.utc(query.selectMonth, Defaults.MonthFormat) < moment.utc(startMonth, Defaults.MonthFormat)
        ) {
          const selectMonth = endMonth;
          query = { ...query, selectMonth };
        }
      }
    }

    if (needReload) {
      query = { ...query, forceRefreshTime: moment.utc().valueOf() };
    }

    // force refresh
    replace(buildLocation(location.pathname, {}, { ...rest, ...query }));
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    const disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    this.setState({ startTimeObj, timeChange, disableRefresh, tooltipVisibleReload }, () => {
      if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
    });
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    const disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    this.setState({ endTimeObj, timeChange, disableRefresh, tooltipVisibleReload }, () => {
      if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
    });
  }

  @autobind
  handleProjectChange(projectName) {
    const { push, location } = this.props;
    const params = parseLocation(location);
    const instanceGroup = Defaults.InstanceGroup;

    push(buildLocation(location.pathname, {}, { ...params, projectName, instanceGroup }));
  }

  @autobind
  handleSelectInstanceChange(selectInstanceList) {
    const { instanceList } = this.state;
    const instanceChange = R.symmetricDifference(instanceList, selectInstanceList).length > 0;
    const disableRefreshInstance = selectInstanceList.length === 0;
    const tooltipVisibleReload = instanceChange || disableRefreshInstance;
    this.setState({ selectInstanceList, instanceChange, disableRefreshInstance, tooltipVisibleReload }, () => {
      if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
    });
  }

  @autobind
  handleEventExportClick() {
    this.setState({ showEventExportModal: true });
  }

  @autobind
  handleCustomerNameChange(customerName) {
    const { location, showAppLoader } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    setTimeout(() => {
      const query = parseLocation(location);
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { ...query, customerName, projectName: undefined, systemId: undefined },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  handleSystemIdChange(systemId) {
    const { push, location, timezoneOffset } = this.props;
    const params = parseLocation(location);
    const { startTime, endTime } = params;
    let { startMonth, endMonth, selectMonth } = params;
    if (systemId === 'allSystems') {
      if (!startMonth) {
        const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
        startMonth = moment.utc(nowTimestamp).subtract(7, 'days').format(Defaults.DateFormat);
      }
      if (!endMonth) {
        const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
        endMonth = moment.utc(nowTimestamp).format(Defaults.DateFormat);
      }
      if (!selectMonth) {
        selectMonth = moment.utc(endTime).format(Defaults.MonthFormat);
      }
    }
    this.setState({ pickLastTimeValue: undefined }, () => {
      push(
        buildLocation(
          location.pathname,
          {},
          { ...params, systemId, projectName: undefined, startMonth, endMonth, selectMonth },
        ),
      );
    });
  }

  @autobind
  handleStartMonthChange(startMonthObj) {
    this.setState({ startMonthObj, pickLastTimeValue: undefined });
  }

  @autobind
  handleEndMonthChange(endMonthObj) {
    this.setState({ endMonthObj, pickLastTimeValue: undefined });
  }

  @autobind
  getFilterValue({ searchValue, options, project }) {
    const { instanceDisplayNameMap } = this.state;
    return R.filter((item) => {
      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item, {
        pn: project?.projectShortName,
        owner: project?.owner,
      });
      return (
        (item || '').toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1 ||
        (instanceStr || '').toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1
      );
    }, options);
  }

  @autobind
  isCheckedAll({ searchValue, options, valueList, project }) {
    const filterValue = this.getFilterValue({ searchValue, options, project });
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  }

  render() {
    const {
      intl,
      location,
      userInfo,
      userList,
      systemList: systemLists,
      credentials,
      replace,
      currentTheme,
      defaultTimezone,
    } = this.props;
    const {
      startTimeObj,
      endTimeObj,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
      startMonthObj,
      endMonthObj,
      pickLastTimeValue,
      packLastTimeDisbale,
      allAnomalyInstances,
      instanceDisplayNameMap,
      searchValue,
    } = this.state;
    const { isLoading, selectInstanceList, instanceChange, disableRefreshInstance, showEventExportModal } = this.state;
    const params = parseLocation(location);
    const {
      projectName,
      startTime,
      endTime,
      customerName,
      systemId,
      forceRefreshTime,
      selectMonth,
      startMonth,
      endMonth,
    } = params;
    let projects = R.filter((project) => project.isMetric, this.props.projects);

    let systemList =
      systemLists.length > 0
        ? [
            { systemName: 'All systems', systemId: 'allSystems' },
            ...systemLists,
            { systemName: 'Projects with no system', systemId: 'projectsWithNoSystem' },
          ]
        : [];

    if (userInfo.isAdmin || userInfo.isLocalAdmin) {
      systemList = R.filter(
        (item) =>
          item.owner === customerName || item.systemId === 'projectsWithNoSystem' || item.systemId === 'allSystems',
        systemList,
      );
    }
    R.forEach((item) => {
      item.hasProjectList = [];
      R.forEach((_item) => {
        if (_item.systemId && item.systemId === _item.systemId) {
          item.hasProjectList.push(_item);
        } else if (item.systemId === 'projectsWithNoSystem' && !_item.systemId) {
          item.hasProjectList.push(_item);
        }
      }, projects);
    }, systemList);
    systemList = R.filter((item) => item.hasProjectList.length > 0 || item.systemId === 'allSystems', systemList);

    projects = R.filter(
      (item) =>
        systemId === 'projectsWithNoSystem'
          ? item.systemId === undefined
          : item.systemId === systemId && item.systemId !== undefined,
      projects,
    );
    const project = R.find((p) => p.projectName === projectName, projects) || {};

    return (
      <Container fullHeight withGutter className={`flex-col log-live ${isLoading ? ' loading' : ''}`}>
        <Container className="flex-row" breadcrumb style={{ fontSize: 12 }}>
          <div className="flex-grow" />
          <div className="flex-row flex-center-align" style={{ justifyContent: 'flex-end', height: 28 }}>
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.system)}</span>
            <Select
              showSearch
              size="small"
              value={systemId}
              style={{ width: 140 }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={this.handleSystemIdChange}
              dropdownMatchSelectWidth={false}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.systemId} value={item.systemId}>
                    {`${item.systemName}${item.owner ? ` (${item.owner})` : ''}`}
                  </Select.Option>
                ),
                systemList || [],
              )}
            </Select>

            {systemId !== 'allSystems' && (
              <>
                <span style={{ fontWeight: 700, padding: '0 1em' }}>
                  {intl.formatMessage(appFieldsMessages.project)}
                </span>
                <Select
                  size="small"
                  style={{ width: 130 }}
                  showSearch
                  optionFilterProp="value"
                  placeholder={intl.formatMessage(appFieldsMessages.project)}
                  value={projectName}
                  status={projectName ? '' : 'error'}
                  onChange={this.handleProjectChange}
                  disabled={systemId === 'allSystems'}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {projects.map((item) => {
                    const label = `${item.projectDisplayName}${
                      userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName
                        ? `@${item.owner}`
                        : ''
                    }`;
                    return (
                      <Select.Option key={item.projectName} title={item.projectName} label={label}>
                        {label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </>
            )}

            {systemId !== 'allSystems' && (
              <>
                <span style={{ fontWeight: 700, padding: '0 1em' }}>
                  {intl.formatMessage(eventMessages.anomalyInstance)}
                </span>
                <Select
                  allowClear
                  showSearch
                  size="small"
                  mode="multiple"
                  maxTagCount={0}
                  showArrow={false}
                  className="inline"
                  style={{ width: 160 }}
                  optionLabelProp="title"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  value={selectInstanceList}
                  optionFilterProp="children"
                  autoClearSearchValue={false}
                  placeholder="Select instances"
                  dropdownMatchSelectWidth={500}
                  disabled={systemId === 'allSystems'}
                  onDropdownVisibleChange={(open) => {
                    if (!open) this.setState({ searchValue: '' });
                  }}
                  onChange={this.handleSelectInstanceChange}
                  onSearch={(searchValue) => this.setState({ searchValue })}
                  dropdownRender={(menu) => {
                    return (
                      <div>
                        <div
                          className="flex-row"
                          style={{ padding: '5px 12px' }}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={this.isCheckedAll({
                              searchValue,
                              options: allAnomalyInstances,
                              valueList: selectInstanceList,
                              project,
                            })}
                            onChange={(e) => {
                              const { instanceList } = this.state;
                              const { checked } = e.target;
                              const filterValue = this.getFilterValue({
                                searchValue,
                                options: allAnomalyInstances,
                                project,
                              });
                              const diff = R.difference(selectInstanceList, filterValue);
                              const newSelectInstanceList = checked ? [...diff, ...filterValue] : diff;
                              const instanceChange =
                                R.symmetricDifference(instanceList, newSelectInstanceList).length > 0;
                              const disableRefreshInstance = newSelectInstanceList.length === 0;
                              const tooltipVisibleReload = instanceChange || disableRefreshInstance;
                              this.setState(
                                {
                                  selectInstanceList: newSelectInstanceList,
                                  instanceChange,
                                  disableRefreshInstance,
                                  tooltipVisibleReload,
                                },
                                () => {
                                  if (tooltipVisibleReload)
                                    setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
                                },
                              );
                            }}
                          />
                          <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                        </div>
                        <Divider style={{ margin: '4px 0' }} />
                        {menu}
                      </div>
                    );
                  }}
                >
                  {R.map((item) => {
                    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item, {
                      pn: project?.projectShortName,
                      owner: project?.owner,
                    });
                    return (
                      <Select.Option className="hide-icon" key={item} value={item} title={item} label={instanceStr}>
                        <Checkbox
                          checked={(selectInstanceList || []).includes(item)}
                          onChange={(e) => null}
                          style={{ marginRight: 8 }}
                        />
                        {instanceStr}
                      </Select.Option>
                    );
                  }, allAnomalyInstances)}
                </Select>
              </>
            )}

            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.startDate)}</span>
            {systemId === 'allSystems' && (
              <DatePicker
                size="small"
                allowClear={false}
                value={startMonthObj}
                onChange={this.handleStartMonthChange}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
              />
            )}
            {systemId !== 'allSystems' && (
              <DatePicker
                size="small"
                allowClear={false}
                showToday={false}
                value={startTimeObj}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleStartTimeChange}
              />
            )}

            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.endDate)}</span>
            {systemId === 'allSystems' && (
              <DatePicker
                size="small"
                allowClear={false}
                value={endMonthObj}
                onChange={this.handleEndMonthChange}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
              />
            )}
            {systemId !== 'allSystems' && (
              <DatePicker
                size="small"
                allowClear={false}
                showToday={false}
                value={endTimeObj}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleEndTimeChange}
              />
            )}

            {systemId === 'allSystems' && <span style={{ fontWeight: 700, padding: '0 1em' }}>Pick last time</span>}
            {systemId === 'allSystems' && (
              <Select
                size="small"
                showSearch
                allowClear
                style={{ width: 150 }}
                placeholder="Pick last time"
                value={pickLastTimeValue}
                onChange={(pickLastTimeValue) => {
                  const timeZoneStr = momenttz().tz(defaultTimezone).format(Defaults.DateTimeFormat);
                  const endTimeZone = moment.utc(timeZoneStr);
                  this.setState({
                    pickLastTimeValue,
                    startMonthObj: pickLastTimeValue
                      ? endTimeZone.subtract(pickLastTimeValue, 'ms')
                      : moment.utc(startMonth, Defaults.DateFormat),
                    endMonthObj: pickLastTimeValue ? endTimeZone : moment.utc(endMonth, Defaults.DateFormat),
                  });
                }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 150 }}
                disabled={packLastTimeDisbale}
              >
                {R.map((item) => {
                  return (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                }, this.pickLastTimeOption || [])}
              </Select>
            )}

            {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
            )}
            {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
              <Select
                showSearch
                size="small"
                value={customerName}
                style={{ width: 100 }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={this.handleCustomerNameChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            )}

            <Tooltip title={intl.formatMessage(eventMessages.exportEvents)}>
              <Button
                size="small"
                icon={<DownloadOutlined />}
                style={{ marginLeft: 8 }}
                onClick={this.handleEventExportClick}
              />
            </Tooltip>
            {systemId === 'allSystems' && (
              <Button
                style={{ marginLeft: 8 }}
                size="small"
                onClick={() => {
                  this.setState({ instanceList: selectInstanceList }, () => {
                    this.handleRefresh({ startTimeObj, endTimeObj, startMonthObj, endMonthObj });
                  });
                }}
                disabled={!(startMonthObj <= endMonthObj)}
              >
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            )}
            {systemId !== 'allSystems' && (
              <div
                style={{ marginLeft: 8 }}
                onMouseEnter={() => {
                  if (
                    (disableRefresh || timeChange || disableRefreshInstance || instanceChange) &&
                    !tooltipVisibleReloadMouseOver
                  )
                    this.setState({ tooltipVisibleReloadMouseOver: true });
                }}
                onMouseLeave={() => {
                  if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
                }}
              >
                <Tooltip
                  mouseEnterDelay={0.3}
                  placement="bottomRight"
                  visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                  title={
                    disableRefresh
                      ? 'Range of days <= 31'
                      : disableRefreshInstance
                      ? 'Lenght of instances > 0'
                      : timeChange || instanceChange
                      ? intl.formatMessage(appMessages.clickToReload)
                      : null
                  }
                >
                  <Button
                    size="small"
                    disabled={disableRefresh}
                    onClick={() => {
                      this.setState({ instanceList: selectInstanceList }, () => {
                        this.handleRefresh({ startTimeObj, endTimeObj, startMonthObj, endMonthObj });
                      });
                    }}
                  >
                    {intl.formatMessage(appButtonsMessages.refresh)}
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        </Container>

        <Container className="flex-grow flex-row content-bg corner-10" style={{ margin: '0px 16px 8px 16px' }}>
          <AutoSizer>
            {({ height, width }) => (
              <>
                {systemId === 'allSystems' && (
                  <AllSystemsMetricChart
                    width={width}
                    height={height}
                    intl={intl}
                    credentials={credentials}
                    userInfo={userInfo}
                    customerName={customerName}
                    startMonth={startMonth}
                    endMonth={endMonth}
                    selectMonth={selectMonth}
                    forceRefreshTime={forceRefreshTime}
                    location={location}
                    replace={replace}
                    pickLastTimeValue={pickLastTimeValue}
                    currentTheme={currentTheme}
                    defaultTimezone={defaultTimezone}
                    setPackLastTimeDisbale={(packLastTimeDisbale) => this.setState({ packLastTimeDisbale })}
                    projects={this.props.projects}
                  />
                )}
                {systemId !== 'allSystems' && (
                  <EventList
                    width={width}
                    height={height}
                    project={project}
                    instanceList={this.state.instanceList}
                    allAnomalyInstances={allAnomalyInstances}
                    instanceDisplayNameMap={instanceDisplayNameMap}
                  />
                )}
              </>
            )}
          </AutoSizer>
        </Container>

        {showEventExportModal && (
          <EventExportModal
            projectName={projectName}
            instanceGroup={Defaults.InstanceGroup}
            startTime={startTime}
            endTime={endTime}
            instanceList={this.state.instanceList}
            project={project}
            onClose={() => this.setState({ showEventExportModal: false })}
          />
        )}
      </Container>
    );
  }
}

const Events = injectIntl(EventsCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { projects, loadStatus, systemsMap, globalInfo, timezoneOffset } = state.app;
    let { userList, currentTheme, defaultTimezone } = state.app;
    const { userInfo, credentials } = state.auth;
    const { mainError } = state.metric;

    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    let systemList = R.values(systemsMap || {});
    systemList = R.sortWith([R.ascend(R.prop('systemName'))])(systemList);
    return {
      location,
      loadStatus,
      projects,
      mainError,
      userInfo,
      credentials,
      userList,
      systemList,
      globalInfo,
      currentTheme,
      defaultTimezone,
      timezoneOffset,
    };
  },
  { push, replace, showAppLoader, createLoadAction, createSetAction, loadProjectInfo, updateLastActionInfo },
)(Events);
