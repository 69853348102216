import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { Spin, message } from 'antd';
import { getCacheData, setCacheData } from '../utils';
import { Defaults } from '../../../common/utils';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { scoreLevel } from '../../serviceMap/component/ServiceMapCanvas';
import HoneycombMapView from './HoneycombMapView';

const colors = ['#c9f074', '#fed36f', '#feb04c', '#fd903d', '#fa4a29', '#eb2c21', '#a40126', '#920025'];

const HoneycombMap = (props: Object) => {
  const {
    intl,
    systemId,
    credentials,
    startTime,
    endTime,
    environmentId,
    globalInfo,
    customerName,
    refresh,
    widgetInfo,
    reloadTime,
    projects,
    systemsMap,
    instanceDisplayNameMap,
  } = props;
  const [loading, setLoading] = useState(false);
  const [honeycombByComponent, setHoneycombByComponent] = useState([]);
  const [componentCountMap, setComponentCountMap] = useState({});
  const currentSystem = systemsMap[systemId];
  const hasKubernetes = !!R.find(
    (item) => R.includes('Kubernetes', item?.cloudType || ''),
    currentSystem?.projectDetailsList || [],
  );

  const getHoneycombData = (systemInfo, force) => {
    const params = {
      ...credentials,
      systemName: systemId,
      customerName: systemInfo?.ownerUserName,
      startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
    };

    if (widgetInfo?.params?.projectNameList && widgetInfo?.params?.projectNameList.length > 0) {
      params.projectNameList = JSON.stringify(widgetInfo?.params?.projectNameList);
    }

    const zoneList = widgetInfo?.params?.zoneList || [];
    if (zoneList.length > 0 && !R.includes('__all__', zoneList)) {
      params.zoneList = JSON.stringify(zoneList);
    }

    if (!force) {
      const apiData = getCacheData(params);
      if (apiData) {
        setLoading(false);
        return Promise.resolve(apiData);
      }
    }

    return fetchGet(getEndpoint('honeycomb-data'), R.clone(params))
      .then((res) => {
        setCacheData(params, res);
        return res;
      })
      .catch((e) => {
        message.error(`honeycomb-data API: ${e.message || String(e)}`);
        setLoading(false);
        return [];
      });
  };

  const reloadData = async (systemInfo, force = false) => {
    setLoading(true);
    const { componentCountMap: cCountMap, honeycombByComponent } = await getHoneycombData(systemInfo, true);

    R.forEach((items) => {
      R.forEach((item) => {
        const { incidentCount, projectSet } = item;
        const projectDisplayNameList = [];
        const projectInfos = [];
        R.forEach((project) => {
          const projectInfo = R.find((item) => item.projectShortName === project, projects || []);
          if (projectInfo) {
            projectDisplayNameList.push(projectInfo?.projectDisplayName || project);
            projectInfos.push({ projectName: projectInfo?.projectShortName, projectOwner: projectInfo?.owner });
          }
        }, projectSet || []);
        item.projectDisplayNameList = projectDisplayNameList;
        item.projectInfos = projectInfos;
        item.value = item.score < 1 ? item.score : Math.floor(item.score);
        item.count = incidentCount;
        item.color = colors[scoreLevel(item.score, colors)];
      }, items);
    }, honeycombByComponent || []);

    setHoneycombByComponent(honeycombByComponent || []);
    setComponentCountMap(cCountMap || {});
    setLoading(false);
  };

  useEffect(() => {
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo) {
      reloadData(systemInfo);
    }
  }, [systemId]);

  useEffect(() => {
    if (reloadTime) {
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo) {
        reloadData(systemInfo, true);
      }
    }
  }, [reloadTime]);

  const environment = R.find((e) => e.id === environmentId, globalInfo || []);
  const systemList = get(environment, 'systemList', []);
  const systemInfo = R.find((system) => system.id === systemId, systemList);

  return (
    <Spin spinning={loading} wrapperClassName="full-height overflow-x-hidden overflow-y-auto full-width spin-base">
      <HoneycombMapView
        hasKubernetes={hasKubernetes}
        intl={intl}
        honeycombByComponent={honeycombByComponent}
        componentCountMap={componentCountMap}
        systemInfo={systemInfo}
        systemId={systemId}
        environmentId={environmentId}
        startTime={startTime}
        endTime={endTime}
        customerName={customerName}
        instanceDisplayNameMap={instanceDisplayNameMap}
      />
    </Spin>
  );
};

export default HoneycombMap;
