import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, isEqual, isFinite, isNumber, isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import {
  ArrowRightOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Menu, message, Select, Spin, Tag } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { buildUrl, CellRenderers, Defaults, EventRenderers, GlobalParse, sleep } from '../../../common/utils';
import {
  ChangeEventIcon,
  FlagNewIcon,
  GoodIcon,
  GoodOutlinedIcon,
  IncidentPredictIcon,
  RootCauseIcon,
} from '../../../lib/fui/icons';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Dropdown, List, Modal, Popover } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/dashboard/actions';
import { EChart } from '../../share';

import { appButtonsMessages, appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';

import RenderLeadToIncident from './RenderLeadToIncident';
import TriageReportModal from './TriageReportModal';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import TimeSelectModal from '../../metric/components/TimeSelectModal';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import ReportJiraModal from '../../metric/components/ReportJiraModal';
import ReportServiceNowModal from '../../metric/components/ReportServiceNowModal';
import { logMessages } from '../../../common/log/messages';
import fetchGet from '../../../common/apis/fetchGet';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

// Ignore seconds compare
const isClosedTs = (a, b) => {
  return parseInt(a / (1000 * 60), 10) === parseInt(b / (1000 * 60), 10);
};

const calcAnomalyColor = (score) => {
  const avgScore = isFinite(score) ? score : 0;
  let color = '#ffc107';
  if (avgScore >= 500) {
    color = '#ff0014';
  } else if (avgScore >= 100) {
    color = '#ff5722';
  } else if (avgScore >= 50) {
    color = '#ff9800';
  }

  return color;
};
const fakeContainerNodeName = '__fake_container__';

type Props = {
  style: Object,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refreshTime: Number,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  zoomStartTime: Number,
  // eslint-disable-next-line
  zoomEndTime: Number,
  // eslint-disable-next-line
  selectAnomalyInstance: String,
  // eslint-disable-next-line
  rankMap: Object,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoadingPanelAnomalies: Boolean,
  // eslint-disable-next-line
  isLoadingPanelAlert: Boolean,
  // eslint-disable-next-line
  isLoadingPanelIncident: Boolean,
  // eslint-disable-next-line
  updateState: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  timezoneOffset: Number,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createLoadAction: Function,

  // eslint-disable-next-line
  globalSystemAnomalyTimelines: Array<Object>,
  // eslint-disable-next-line
  globalSystemAlertTimelines: Array<Object>,
  // eslint-disable-next-line
  globalSystemDetectIncidentTimelines: Array<Object>,
  // eslint-disable-next-line
  globalSystemDeployTimelines: Array<Object>,
  // eslint-disable-next-line
  currentTheme: String,
  projectDisplayMap: Object,
};

class GlobalZoomTimelinsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {
      isLoading: false,
      // events: [],
      eventList: [],
      filterEventList: [],
      groupEventList: [],
      optionTimelineRange: undefined,
      currentInstance: {},

      ignoreFilter: true,
      filterProject: undefined,
      filterInstance: undefined,
      filterPattern: undefined,
      filterMetric: undefined,
      summarySettingsMap: {},
      logsummaryLoading: false,
    };
    this.projectOptions = [];
    this.instanceOptions = [];
    this.patternOptions = [];
    this.metricOptions = [];
    this.leadIncidentPatternNameStrMap = {};
    this.expandedNodeMaps = {};
    this.incidentList = [];
    this.incidentEventMap = {};
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.zoomStartTime !== this.props.zoomStartTime ||
      nextProps.zoomEndTime !== this.props.zoomEndTime ||
      nextProps.selectAnomalyInstance !== this.props.selectAnomalyInstance ||
      nextProps.globalSystemAnomalyTimelines !== this.props.globalSystemAnomalyTimelines ||
      nextProps.globalSystemAlertTimelines !== this.props.globalSystemAlertTimelines ||
      nextProps.globalSystemDetectIncidentTimelines !== this.props.globalSystemDetectIncidentTimelines ||
      nextProps.globalSystemDeployTimelines !== this.props.globalSystemDeployTimelines
    ) {
      this.parseData(nextProps);
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  async reloadData() {
    const { createLoadAction, startTime, endTime, environmentId, systemInfo, updateState } = this.props;
    const { projectDisplayMap } = this.props;
    this.setState({ isLoading: true });
    updateState({ isLoadingZoomIn: true });
    await sleep(300);

    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
    createLoadAction(
      ActionTypes.LOAD_GLOBAL_SYSTEM_DEPLOY_TIMELINES,
      {
        isAutoReload: true,
        level: 'system',
        environmentId,
        systemIds: [systemInfo.id],
        startTime: startTimestamp,
        endTime: endTimestamp,
        projectDisplayNameMap: projectDisplayMap,
      },
      false,
      false,
      () => {},
    );
    createLoadAction(
      ActionTypes.LOAD_GLOBAL_SYSTEM_ZOOM_TIMELINES,
      {
        level: 'system',
        environmentId,
        systemIds: [systemInfo.id],
        startTime: startTimestamp,
        endTime: endTimestamp,
        projectDisplayNameMap: projectDisplayMap,
      },
      false,
      false,
      this.callbackSetTimelineState(),
    );
  }

  @autobind
  callbackSetTimelineState() {
    return () => {
      const { updateState } = this.props;
      this.setState({ isLoading: false });
      updateState({ isLoadingZoomIn: false });
    };
  }

  @autobind
  getLogsummarysettings(projectName) {
    const { credentials } = this.props;
    if (projectName) {
      return new Promise((resolve, reject) => {
        fetchGet(getEndpoint('logsummarysettings'), {
          ...credentials,
          projectName,
        })
          .then((summarySettings) => {
            resolve(summarySettings);
          })
          .catch((err) => {
            resolve([]);
          });
      });
    }
    return Promise.resolve([]);
  }

  @autobind
  async reloadRootCause(props, incidents) {
    const { intl, credentials } = props;

    const incidentIdMap = {};
    const request = [];
    R.forEach((incident) => {
      const { id, timelines } = incident;
      const hasRCIncident = R.find((item) => item.hasRootCause, timelines || []);
      if (hasRCIncident) {
        const {
          category,
          projectOwner,
          anomalyLogInstance,
          instanceList,
          startTimestamp,
          endTimestamp,
          patternId,
          type,
          rootCauseTableKey,
        } = incident;
        let { projectName } = incident;

        // parse params to get root cause
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
        const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
        const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
        const event = {
          nid: patternId,
          eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
        };
        incidentIdMap[request.length] = id;
        request.push(
          fetchPost(getEndpoint('logCausalInfoServlet', 1), {
            ...credentials,
            projectName,
            instanceName: anomalyLogInstance || instanceList[0],
            startTime,
            endTime,
            operation: 'rootCauseEvents',
            rootCauseTableKey: JSON.stringify(rootCauseTableKey),
            event: JSON.stringify(event),
          }),
        );
      }
    }, incidents || []);

    const datas = await Promise.all(request)
      .then((results) => {
        return results;
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        console.log(err);
        return [];
      });

    let rootCauseKeyMap = {};
    R.addIndex(R.forEach)((data, idx) => {
      const incidentId = incidentIdMap[idx];
      const { logRootCauseEvents, metricRootCauseEvents } = data || {};
      R.forEach(
        (rootCause) => {
          const { rootCauseKey } = rootCause;
          if (!R.has(rootCauseKey, rootCauseKeyMap)) {
            rootCauseKeyMap[rootCauseKey] = [];
          }
          rootCauseKeyMap[rootCauseKey].push(incidentId);
          // const predictionSourceInfoList = get(rootCause, ['rootCauseChain', 'predictionSourceInfoList'], []);
          // R.forEach((item) => {
          //   const { type: category, sourceProjectName, sourceInstanceName, eventTimestamp, isDeployment } = item;
          //   const sourceDetail = parseJSON(item.sourceDetail) || {};
          //   const { nid, content, type } = sourceDetail;
          //   let { eventType } = CausalParser.getRelationLogType(type);
          //   eventType = R.toLower(eventType);
          //   const rootCauseKey =
          //     category === 'Metric'
          //       ? `${sourceProjectName}-${sourceInstanceName}-${nid}-${eventTimestamp}-notIncident-${content}`
          //       : `${sourceProjectName}-${sourceInstanceName}-${nid}-${eventTimestamp}-notIncident-${
          //           isDeployment ? 'deployment' : eventType
          //         }`;
          //   if (!R.has(rootCauseKey, rootCauseKeyMap)) {
          //     rootCauseKeyMap[rootCauseKey] = [];
          //   }
          //   rootCauseKeyMap[rootCauseKey].push(incidentId);
          // }, predictionSourceInfoList);
        },
        [...(logRootCauseEvents || []), ...(metricRootCauseEvents || [])],
      );
    }, datas);
    // make incident ids uniq
    rootCauseKeyMap = R.mapObjIndexed((val) => {
      return R.uniq(val || []);
    }, rootCauseKeyMap);

    return rootCauseKeyMap;
  }

  @autobind
  async parseData(props) {
    const {
      intl,
      userInfo,
      projects,
      rankMap,
      zoomStartTime,
      zoomEndTime,
      selectAnomalyInstance,
      systemInfo,
      globalSystemAnomalyTimelines,
      globalSystemAlertTimelines,
      globalSystemDetectIncidentTimelines,
      globalSystemDeployTimelines,
      currentTheme,
    } = props;
    const { sortBy, sortDirection, summarySettingsMap } = this.state;
    this.setState({ isLoading: true });
    await sleep(300);

    // get anomaly timelines
    let systemIncidentTimelines =
      (R.find((item) => item.id === systemInfo.id, globalSystemDetectIncidentTimelines) || {}).anomalyTimelines || [];
    systemIncidentTimelines = R.filter(
      (event) => R.toLower(event.type) === 'incident' && event.timeLineType !== 'future',
      systemIncidentTimelines || [],
    );
    systemIncidentTimelines = R.map((item) => ({ ...item, tabName: 'detectedIncidents' }), systemIncidentTimelines);

    let systemDeployTimelines =
      (R.find((item) => item.id === systemInfo.id, globalSystemDeployTimelines) || {}).anomalyTimelines || [];
    systemDeployTimelines = R.map((item) => ({ ...item, tabName: 'detectedChanges' }), systemDeployTimelines);

    let systemAlertTimelines =
      (R.find((item) => item.id === systemInfo.id, globalSystemAlertTimelines) || {}).anomalyTimelines || [];
    systemAlertTimelines = R.map(
      (item) => ({ ...item, tabName: 'detectedAlerts', isAlert: true }),
      systemAlertTimelines,
    );
    let systemAnomalyTimelines =
      (R.find((item) => item.id === systemInfo.id, globalSystemAnomalyTimelines) || {}).anomalyTimelines || [];
    systemAnomalyTimelines = R.map((item) => ({ ...item, tabName: 'detectedAnomalies' }), systemAnomalyTimelines);

    // all anomaly/alert/incidents
    let events = [
      ...systemIncidentTimelines,
      ...systemDeployTimelines,
      ...systemAlertTimelines,
      ...systemAnomalyTimelines,
    ];

    const whitelistSamePatternMap = {};
    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];
      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          const instanceName = pair.instanceId || pair.instanceName;
          const componentName = get(instanceComponentMap, instanceName, instanceName);
          componentList.push(componentName);
          const appName =
            componentName && componentName.indexOf(instanceName) === -1
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        const { realInstanceName } = item;
        const componentName = get(instanceComponentMap, realInstanceName, item.componentName);
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== realInstanceName
            ? `${item.realInstanceName} (${componentName})`
            : item.realInstanceName;
        instanceAppNames.push(appName);
      }
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      const appName = R.join(', ', instanceAppNames);
      const componentNameString = R.join(', ', componentList);
      const onlyInstanceString = get(item, ['containerInfo', 'instanceName'], item.realInstanceString);

      // get status
      const leadToIncident = get(item.rootCauseResultInfo, 'leadToIncident');
      const hasTrailing = get(item.rootCauseResultInfo, 'hasTrailingEvent');
      const causedByChange = get(item.rootCauseResultInfo, 'causedByChangeEvent');
      const hasRootCause = get(item.rootCauseResultInfo, 'hasPrecedingEvent');
      const isWhitelistSamePattern = item.typeList.includes('whitelist');

      // only for whitelist pattern
      const patternKey = `${item.projectName}-${item.instanceNameString}-${item.patternId}`;
      if (isWhitelistSamePattern) {
        whitelistSamePatternMap[patternKey] = item.avgAnomalyScore;
      }

      // build log json info
      let rawDataJson;
      try {
        rawDataJson = JSON.parse(item.rawData);
        if (!isObject(rawDataJson)) rawDataJson = undefined;
      } catch (error) {
        // console.debug(error);
      }

      // get falg isTrace from event's project, build trace info
      const { projectOwner } = item;
      let { projectName } = item;
      projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
      const project = R.find((project) => projectName === project.projectName, projects || []);
      let { isTrace } = project || {};
      isTrace = isTrace && item.tabName === 'detectedAlerts';
      const traceId = get(rawDataJson, 'trace_id');
      let traceInfo;
      if (isTrace) {
        const traceId = get(rawDataJson, 'trace_id');
        const parentId = get(rawDataJson, 'parent_span_id');
        const spanId = get(rawDataJson, 'span_id');
        const serviceName = get(rawDataJson, ['attributes', 'http.server_name']) || componentNameString;
        const name = get(rawDataJson, 'method_name');
        const startTime = get(rawDataJson, 'start_time');
        // use microseconds for timestamp and duration
        const duration = Number(get(rawDataJson, 'duration', 0)) * 1000;
        const timestamp = moment.utc(startTime).valueOf() * 1000;
        traceInfo = {
          traceId,
          parentId,
          id: spanId,
          name,
          timestamp,
          duration,
          serviceName,
          color: '#ffccc7',
          tags: {},
        };
      }

      // build id, and mergeKey
      const mergeKey =
        item.category === 'metric'
          ? `${item.projectName}-${item.instanceNameString}-${item.patternId}-${
              item.isIncident ? 'incident' : 'notIncident'
            }-${item.metricNameString}`
          : isTrace
          ? `${item.projectName}-${item.instanceNameString}-${traceId}` // merge by traceId for trace data
          : `${item.projectName}-${item.instanceNameString}-${item.patternId}`;
      // RC key is not same with merge key, EX: trace data
      const RCKey =
        item.category === 'metric'
          ? `${item.projectName}-${item.instanceNameString}-${item.patternId}-${item.startTimestamp}-${
              item.isIncident ? 'incident' : 'notIncident'
            }-${item.metricNameString}`
          : `${item.projectName}-${item.instanceNameString}-${item.patternId}-${item.startTimestamp}-${
              item.isIncident ? 'incident' : 'notIncident'
            }-${item.typeList[0]}`;

      return {
        ...item,
        mergeKey,
        RCKey,
        patternKey,
        rawDataJson,
        rootCauseJson: { rootCauseDetailsArr },
        appName,
        componentNameString,
        onlyInstanceString,
        instanceAppNames,
        componentList,
        timeRangeKey: `${item.startTimestamp}-${item.endTimestamp}`,
        leadToIncident,
        hasTrailing,
        causedByChange,
        hasRootCause,
        isWhitelistSamePattern,
        patternAndTrace: isTrace ? traceId : item.patternId,
        isTrace,
        traceId,
        traceInfo,
      };
    }, events);
    // reset rare event flag/avgScore if is also whitelist
    events = R.map((item) => {
      if (!item.isWhitelistSamePattern && R.has(item.patternKey, whitelistSamePatternMap)) {
        return {
          ...item,
          isWhitelistSamePattern: true,
          avgAnomalyScore: R.max(item.avgAnomalyScore, whitelistSamePatternMap[item.patternKey]),
        };
      }
      return item;
    }, events);

    // filter selectAnomalyInstance or ignore
    if (selectAnomalyInstance) {
      events = R.filter((anomaly) => {
        return anomaly.instanceList.includes(selectAnomalyInstance);
      }, events);
    }
    let eventList = this.filterIgnoreData(events);

    // get zoom range incidents, and get merged list, (make the id is uniq)
    const mergedIncidentList = this.mergeAnomalyEvents(
      props,
      R.filter(
        (item) => item.isIncident && item.startTimestamp <= zoomEndTime && zoomStartTime <= item.endTimestamp,
        eventList,
      ),
    );
    const mergedIncidentStartTimeMap = {};
    R.forEach((item) => {
      mergedIncidentStartTimeMap[item.id] = item.startTimestamp;
    }, mergedIncidentList);
    // build instance/id map
    const incidentInstanceMap = {};
    R.forEach((item) => {
      incidentInstanceMap[item.onlyInstanceString] = item.id;
    }, mergedIncidentList);
    // get root causes
    const rootCauseKeyMap = await this.reloadRootCause(props, mergedIncidentList);
    // all incident ids which build with RC map
    const incidentIdsInRC = R.uniq(R.reduce(R.concat, [], R.values(rootCauseKeyMap)));

    // get last endTimestamp of all RC list before the zoom end time
    const maxRCStartTimeMap = {};
    const maxRCEndTimeMap = {};

    let rootCauseTableKey = [];
    R.forEach((x) => {
      rootCauseTableKey = [...rootCauseTableKey, ...x.rootCauseTableKey];
    }, eventList);

    if (!R.isEmpty(rootCauseKeyMap)) {
      R.forEach((item) => {
        if (!item.isIncident) {
          let incidentIds = [];
          const rootCauseTableKey = item.rootCauseTableKey || [];
          R.forEachObjIndexed((val, key) => {
            if (R.find((x) => key.indexOf(x) >= 0, rootCauseTableKey)) {
              incidentIds = [...incidentIds, ...val];
            }
          }, rootCauseKeyMap);
          incidentIds = R.uniq(incidentIds);
          // use mergeKey, with no startTimestamp
          // incidentIds = rootCauseKeyMap[item.RCKey] || [];
          // This item is RC and belongs to one of the incidents, and not out of zoom range end
          if (R.intersection(incidentIds, incidentIdsInRC).length > 0 && item.startTimestamp <= zoomEndTime && item) {
            if (!R.has(item.mergeKey, maxRCStartTimeMap)) {
              maxRCStartTimeMap[item.mergeKey] = item.startTimestamp;
            } else {
              maxRCStartTimeMap[item.mergeKey] = R.max(maxRCStartTimeMap[item.mergeKey], item.startTimestamp);
            }
            if (!R.has(item.mergeKey, maxRCEndTimeMap)) {
              maxRCEndTimeMap[item.mergeKey] = item.endTimestamp;
            } else {
              maxRCEndTimeMap[item.mergeKey] = R.max(maxRCEndTimeMap[item.mergeKey], item.endTimestamp);
            }
          }
        }
      }, eventList);
    }
    const minRCStartTimestamp = R.reduce(R.min, Infinity, R.values(maxRCStartTimeMap));
    // const minRCEndTimestamp = R.reduce(R.min, Infinity, R.values(maxRCEndTimeMap));
    // reset rangeStartTime
    const rangeStartTime = R.min(zoomStartTime, minRCStartTimestamp);
    const rangeEndTime = zoomEndTime;

    // filter eventList by range start/end time
    eventList = R.filter((anomaly) => {
      return anomaly.startTimestamp <= rangeEndTime && rangeStartTime <= anomaly.endTimestamp;
    }, eventList);
    // filter incident out of zoom range, (make sure these incident not merged in)
    eventList = R.filter((anomaly) => {
      return !anomaly.isIncident || (anomaly.isIncident && zoomStartTime <= anomaly.endTimestamp);
    }, eventList);

    // merge by mergeKey
    eventList = this.mergeAnomalyEvents(props, eventList);
    // build optons
    this.buildFilterOption(eventList);

    // set anomaly flag if it is root cause or is same instance with incident
    const hasMultiRCList = [];
    const allLogProjectMap = {};
    R.forEach((item) => {
      const { isIncident, id, RCKeys, onlyInstanceString, startTimestamp, allRootCauseTableKey } = item;
      const { projectOwner, category } = item;
      let { projectName } = item;
      projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
      if (category === 'log') {
        allLogProjectMap[projectName] = { projectName: item.projectName, projectOwner };
      }

      const newItem = { ...item, isRootCauseReleated: false, isSameInstanceWithIncident: false };
      if (isIncident) {
        newItem.incidentOrHasSameInstanceOrHasRootCause = true;
        newItem.incidentIdHasRootCauseOrSameInstance = `${incidentIdsInRC.includes(id) ? 'true' : 'false'}-${id}`;
        newItem.filterIncidentId = id;
        hasMultiRCList.push(newItem);
      } else {
        // use RCKeys, without startTimestamp
        // const incidentIds = R.reduce(R.concat, [], R.values(R.pick(RCKeys, rootCauseKeyMap)));
        let incidentIds = [];
        R.forEachObjIndexed((val, key) => {
          if (R.find((x) => key.indexOf(x) >= 0, allRootCauseTableKey || [])) {
            incidentIds = [...incidentIds, ...val];
          }
        }, rootCauseKeyMap);
        incidentIds = R.uniq(incidentIds);

        // this anomaly is RC for multiple incidents
        if (incidentIds && incidentIds.length > 0) {
          R.forEach((incidentId) => {
            const incidentStartTime = mergedIncidentStartTimeMap[incidentId];
            if (startTimestamp <= incidentStartTime) {
              // RC time must <= incidnet time
              const dupItem = { ...newItem };
              dupItem.isRootCauseReleated = true;
              dupItem.incidentIdHasRootCauseOrSameInstance = `true-${incidentId}`;
              dupItem.filterIncidentId = incidentId;

              // this anomly has same instance with incidents
              if (R.has(onlyInstanceString, incidentInstanceMap)) {
                dupItem.isSameInstanceWithIncident = true;
                // use root cause incident id or same instance incident id
                if (!dupItem.incidentIdHasRootCauseOrSameInstance) {
                  dupItem.incidentIdHasRootCauseOrSameInstance = `false-${incidentInstanceMap[onlyInstanceString]}`;
                }
              }
              // hasRootCause anomly, same instance anomly
              dupItem.incidentOrHasSameInstanceOrHasRootCause = Boolean(
                dupItem.isRootCauseReleated || dupItem.isSameInstanceWithIncident,
              );
              hasMultiRCList.push(dupItem);
            } else {
              // this anomly has same instance with incidents
              if (R.has(onlyInstanceString, incidentInstanceMap)) {
                newItem.isSameInstanceWithIncident = true;
                // use root cause incident id or same instance incident id
                if (!newItem.incidentIdHasRootCauseOrSameInstance) {
                  newItem.incidentIdHasRootCauseOrSameInstance = `false-${incidentInstanceMap[onlyInstanceString]}`;
                }
              }
              // hasRootCause anomly, same instance anomly
              newItem.incidentOrHasSameInstanceOrHasRootCause = Boolean(
                newItem.isRootCauseReleated || newItem.isSameInstanceWithIncident,
              );
              hasMultiRCList.push(newItem);
            }
          }, incidentIds);
        } else {
          // this anomly has same instance with incidents
          if (R.has(onlyInstanceString, incidentInstanceMap)) {
            newItem.isSameInstanceWithIncident = true;
            // use root cause incident id or same instance incident id
            if (!newItem.incidentIdHasRootCauseOrSameInstance) {
              newItem.incidentIdHasRootCauseOrSameInstance = `false-${incidentInstanceMap[onlyInstanceString]}`;
            }
          }
          // hasRootCause anomly, same instance anomly
          newItem.incidentOrHasSameInstanceOrHasRootCause = Boolean(
            newItem.isRootCauseReleated || newItem.isSameInstanceWithIncident,
          );
          hasMultiRCList.push(newItem);
        }
      }
    }, eventList);
    eventList = hasMultiRCList;

    const allLogProjectList = R.keys(allLogProjectMap);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < allLogProjectList.length; ++i) {
      if (!R.has(allLogProjectList[i], summarySettingsMap)) {
        // eslint-disable-next-line no-await-in-loop
        summarySettingsMap[allLogProjectList[i]] = await this.getLogsummarysettings(allLogProjectList[i]);
      }
    }

    // build timeline chart option
    const optionTimelineRange = GlobalParse.buildOptionTimelineRange({ rangeStartTime, rangeEndTime });
    eventList = R.map((item) => {
      const { projectOwner } = item;
      let { projectName } = item;
      projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
      const optionTimeline = GlobalParse.buildOptionTimeline({
        intl,
        rankMap,
        rangeStartTime,
        rangeEndTime,
        item,
        summarySettingsMap,
        projectName,
        currentTheme,
      });
      return { ...item, optionTimeline };
    }, eventList);

    // filter
    let filterEventList = this.filterData(eventList);
    // sort
    filterEventList = this.sortData(filterEventList, sortBy, sortDirection);
    const groupEventList = this.groupData(filterEventList);

    if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
    this.setState(
      {
        isLoading: false,
        // events,
        eventList,
        filterEventList,
        groupEventList,
        optionTimelineRange,
        summarySettingsMap,
        // rangeStartTime,
        // rangeEndTime,
      },
      () => {
        this.selectIncidentFirstItem();
      },
    );
  }

  @autobind
  groupData(eventList) {
    const incidentEventMap = {};
    const incidentList = [];
    const nonIncidentList = [];
    const restEventList = [];

    R.forEach((e) => {
      if (e.isIncident) {
        incidentEventMap[e.id] = [];
        incidentList.push(e);
      } else {
        nonIncidentList.push(e);
      }
    }, eventList);

    R.forEach((e) => {
      const { filterIncidentId } = e;
      if (filterIncidentId && incidentEventMap[filterIncidentId]) {
        incidentEventMap[filterIncidentId].push(e);
      } else {
        restEventList.push(e);
      }
    }, nonIncidentList);
    this.incidentList = incidentList;
    this.incidentEventMap = incidentEventMap;

    let groupedEventList = [];
    R.forEach((i) => {
      groupedEventList.push(i);
      groupedEventList = [...groupedEventList, ...this.groupDataCore(incidentEventMap[i.id], i.id)];
    }, incidentList);

    return [...groupedEventList, ...this.groupDataCore(restEventList)];
  }

  @autobind
  groupDataCore(eventList, groupPrefix) {
    const mergeOptionTimeline = (a, b) => {
      const { xAxis: axAxis, series: aseries } = a;
      const { xAxis: bxAxis, series: bseries } = b;

      const series = [
        ...(aseries || []),
        ...R.map(
          (b) => ({
            ...b,
            label: {},
            symbolSize: 14,
            symbol: b.symbol === 'circle' ? 'none' : b.symbol,
            lineStyle: { width: 4 },
          }),
          bseries,
        ),
      ];
      const xAxis = {
        ...bxAxis,
        max: axAxis ? R.max(axAxis.max, bxAxis.max) : bxAxis.max,
        min: axAxis ? R.min(axAxis.min, bxAxis.min) : bxAxis.min,
      };
      const ret = {
        grid: a?.grid || b?.grid,
        useUTC: a?.useUTC || b?.useUTC,
        xAxis,
        yAxis: a?.yAxis || b?.yAxis,
        series,
      };
      return ret;
    };

    const addItemToNode = (e, nodeMap, nodeName, isLeaf = false) => {
      const { avgAnomalyScore, optionTimeline, isRootCauseReleated, startTimestamp } = e;

      let node = nodeMap[nodeName];
      if (!node) {
        node = { avgAnomalyScore: 0, optionTimeline: {}, children: isLeaf ? [] : {}, item: e };
        nodeMap[nodeName] = node;
      }
      node.optionTimeline = mergeOptionTimeline(node.optionTimeline, optionTimeline);
      node.avgAnomalyScore += avgAnomalyScore;
      node.anomalyColor = calcAnomalyColor(node.avgAnomalyScore);
      if (!node.startTimestamp || node.startTimestamp > startTimestamp) {
        node.startTimestamp = startTimestamp;
      }
      if (isRootCauseReleated) node.isRootCauseReleated = true;

      return node;
    };

    const createListItem = (node) => {
      const { uiNodeName } = node;
      const isExpand = !!this.expandedNodeMaps[uiNodeName];
      return { ...node, isExpand };
    };

    const projectMap = {};
    const incidentList = [];
    (eventList || []).forEach((e) => {
      const { projectDisplayName, componentNameString, onlyInstanceString, isIncident } = e;
      if (isIncident) {
        incidentList.push(e);
      } else {
        const containerName = e.containerName || fakeContainerNodeName;

        const projectNode = addItemToNode(e, projectMap, projectDisplayName);
        projectNode.projectDisplayName = projectDisplayName;
        projectNode.uiNodeName = `${groupPrefix || ''}-${projectDisplayName}`;

        const componentNode = addItemToNode(e, projectNode.children, componentNameString);
        componentNode.projectDisplayName = projectDisplayName;
        componentNode.componentNameString = componentNameString;
        componentNode.uiNodeName = `${projectNode.uiNodeName}-${componentNameString}`;

        const containerNode = addItemToNode(e, componentNode.children, containerName);
        containerNode.projectDisplayName = projectDisplayName;
        containerNode.componentNameString = componentNameString;
        containerNode.containerName = containerName;
        containerNode.uiNodeName = `${componentNode.uiNodeName}-${containerName}`;

        const instanceNode = addItemToNode(e, containerNode.children, onlyInstanceString, true);
        instanceNode.projectDisplayName = projectDisplayName;
        instanceNode.componentNameString = componentNameString;
        instanceNode.containerName = containerName;
        instanceNode.onlyInstanceString = onlyInstanceString;
        instanceNode.uiNodeName = `${containerNode.uiNodeName}-${onlyInstanceString}`;
        instanceNode.children.push(e);
      }
    });
    let groupList = [];
    const isRootCauseReleatedList = [];
    const otherIncident = [];

    R.forEach((item) => {
      if (item.isRootCauseReleated) {
        isRootCauseReleatedList.push(item);
      } else {
        otherIncident.push(item);
      }
    }, R.values(projectMap));

    const children = R.isEmpty(isRootCauseReleatedList)
      ? R.sortWith([R.descend(R.prop('avgAnomalyScore'))], otherIncident)
      : R.sortWith([R.ascend(R.path(['item', 'startTimestamp']))], isRootCauseReleatedList);

    // Get all instance nodes and sort by start time
    // add rc sequence for the instance node.
    let allInstanceNode = [];
    let rcSequenceStartTs;
    let rcSequence = 0;
    R.forEach((projectNode) => {
      if (projectNode.isRootCauseReleated) {
        const children = R.values(projectNode.children);
        R.forEach((componentNode) => {
          const children = R.values(componentNode.children);
          R.forEach((containerNode) => {
            const children = R.values(containerNode.children);
            allInstanceNode = [...allInstanceNode, ...children];
          }, children);
        }, children);
      }
    }, children);

    allInstanceNode = R.sortWith([R.ascend(R.prop(['startTimestamp']))], allInstanceNode);
    R.forEach((instanceNode) => {
      const instTs = instanceNode?.startTimestamp;
      if (!rcSequenceStartTs || !isClosedTs(rcSequenceStartTs, instTs)) {
        rcSequence += 1;
        rcSequenceStartTs = instTs;
      }
      instanceNode.RCSequence = rcSequence;
    }, allInstanceNode);

    R.forEach((projectNode) => {
      const rc1 = [];
      const children = R.values(projectNode.children);
      R.forEach((componentNode) => {
        const rc2 = [];
        const children = R.values(componentNode.children);
        R.forEach((containerNode) => {
          const rc3 = [];
          const children = R.values(containerNode.children);
          R.forEach((instanceNode) => {
            if (instanceNode.RCSequence) {
              rc1.push(instanceNode.RCSequence);
              rc2.push(instanceNode.RCSequence);
              rc3.push(instanceNode.RCSequence);
            }
          }, children);
          containerNode.RCSequence = R.uniq(rc3.sort((a, b) => a - b)).join(',');
        }, children);
        componentNode.RCSequence = R.uniq(rc2.sort((a, b) => a - b)).join(',');
      }, children);
      projectNode.RCSequence = R.uniq(rc1.sort((a, b) => a - b)).join(',');
    }, children);

    R.forEach((projectNode) => {
      const projectItem = createListItem(projectNode);
      projectItem.isProjectRoot = true;
      groupList.push(projectItem);

      if (projectItem.isExpand) {
        const children = R.sortWith([R.ascend(R.prop('startTimestamp'))], R.values(projectNode.children));
        R.forEach((componentNode) => {
          const componentItem = createListItem(componentNode);
          componentItem.isComponentRoot = true;
          groupList.push(componentItem);

          if (componentItem.isExpand) {
            const children = R.sortWith([R.ascend(R.prop('startTimestamp'))], R.values(componentNode.children));

            R.forEach((containerNode) => {
              const containerItem = createListItem(containerNode);
              const isFake = containerItem.containerName === fakeContainerNodeName;
              if (!isFake) {
                containerItem.isContainerRoot = true;
                groupList.push(containerItem);
              }
              if (isFake || containerItem.isExpand) {
                const children = R.sortWith([R.ascend(R.prop('startTimestamp'))], R.values(containerNode.children));
                R.forEach((instanceNode) => {
                  const instanceItem = createListItem(instanceNode);
                  instanceItem.isInstanceRoot = true;
                  groupList.push(instanceItem);

                  if (instanceItem.isExpand) {
                    const children = R.sortWith([R.descend(R.prop('avgAnomalyScore'))], instanceNode.children);
                    groupList = [...groupList, ...children];
                  }
                }, children);
              }
            }, children);
          }
        }, children);
      }
    }, children);

    groupList = R.map((item) => {
      return item.isRootCauseReleated ? item : { ...item, isOther: true };
    }, groupList);

    // append incident list to begin of the list
    groupList = [...incidentList, ...groupList];
    return groupList;
  }

  @autobind
  buildFilterOption(eventList) {
    // build filter options
    let projectOptions = R.map(
      (item) => ({ projectName: item.projectName, projectDisplayName: item.projectDisplayName }),
      eventList,
    );
    projectOptions = R.uniq(projectOptions);

    const instanceProjectMap = {};
    R.forEach((item) => {
      const { onlyInstanceString, projectName } = item;
      if (!R.has(onlyInstanceString, instanceProjectMap)) {
        instanceProjectMap[onlyInstanceString] = {
          value: onlyInstanceString,
          projectNames: [],
        };
      }
      instanceProjectMap[onlyInstanceString].projectNames.push(projectName);
    }, eventList);
    let instanceOptions = R.values(instanceProjectMap);
    instanceOptions = R.map((item) => {
      return { ...item, projectNames: R.uniq(item.projectNames) };
    }, instanceOptions);
    instanceOptions = R.uniqWith(R.eqBy(R.prop('value')), instanceOptions);
    instanceOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], instanceOptions);

    const patternProjectMap = {};
    R.forEach((item) => {
      const { isIncident, patternId, id, onlyInstanceString, projectName } = item;
      if (isIncident) {
        if (!R.has(patternId, patternProjectMap)) {
          patternProjectMap[patternId] = {
            value: patternId,
            incidentIds: [],
            projectNames: [],
            instances: [],
          };
        }
        patternProjectMap[patternId].incidentIds.push(id);
        patternProjectMap[patternId].projectNames.push(projectName);
        patternProjectMap[patternId].instances.push(onlyInstanceString);
      }
    }, eventList);
    let patternOptions = R.values(patternProjectMap);
    patternOptions = R.map((item) => {
      return { ...item, projectNames: R.uniq(item.projectNames), instances: R.uniq(item.instances) };
    }, patternOptions);
    patternOptions = R.uniqWith(R.eqBy(R.prop('value')), patternOptions);

    const metricProjectMap = {};
    R.forEach((item) => {
      const { category, metricNameString, onlyInstanceString, projectName } = item;
      if (category === 'metric') {
        if (!R.has(metricNameString, metricProjectMap)) {
          metricProjectMap[metricNameString] = {
            value: metricNameString,
            projectNames: [],
            instances: [],
          };
          metricProjectMap[metricNameString].projectNames.push(projectName);
          metricProjectMap[metricNameString].instances.push(onlyInstanceString);
        }
      }
    }, eventList);
    let metricOptions = R.values(metricProjectMap);
    metricOptions = R.map((item) => {
      return { ...item, projectNames: R.uniq(item.projectNames), instances: R.uniq(item.instances) };
    }, metricOptions);
    metricOptions = R.uniqWith(R.eqBy(R.prop('value')), metricOptions);
    metricOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], metricOptions);

    this.projectOptions = projectOptions;
    this.instanceOptions = instanceOptions;
    this.patternOptions = patternOptions;
    this.metricOptions = metricOptions;
  }

  @autobind
  filterIgnoreData(eventList) {
    const { ignoreFilter } = this.state;
    let filterList = eventList || [];
    if (ignoreFilter) {
      filterList = R.filter((event) => !event.isIgnored, filterList);
    }
    return filterList;
  }

  @autobind
  filterData(eventList) {
    const { filterProject, filterInstance, filterPattern, filterMetric } = this.state;
    let filterList = eventList || [];
    if (filterProject) {
      filterList = R.filter(
        (item) => item.isIncident || item.projectDisplayName === filterProject || item.projectName === filterProject,
        filterList,
      );
    }
    if (filterInstance) {
      filterList = R.filter((item) => item.isIncident || item.onlyInstanceString.includes(filterInstance), filterList);
    }
    if (filterPattern) {
      const patternObj = R.find((item) => item.value === filterPattern, this.patternOptions);
      const incidentIds = get(patternObj, 'incidentIds', []);
      if (incidentIds.length > 0) {
        filterList = R.filter(
          (item) => item.filterIncidentId && incidentIds.includes(item.filterIncidentId),
          filterList,
        );
      } else {
        filterList = R.filter((item) => item.patternId === Number(filterPattern), filterList);
      }
    }
    if (filterMetric) {
      filterList = R.filter(
        (item) => item.isIncident || (item.category === 'metric' && item.metricNameString.includes(filterMetric)),
        filterList,
      );
    }
    return filterList;
  }

  @autobind
  onChangeFilterField(fieldName, fieldValue) {
    const newState = {};
    if (fieldName === 'filterProject') {
      newState.filterInstance = undefined;
      newState.filterPattern = undefined;
    } else if (fieldName === 'filterInstance') {
      newState.filterPattern = undefined;
    }
    // eslint-disable-next-line
    this.setState({ ...newState, [fieldName]: fieldValue }, () => {
      const { sortBy, sortDirection, eventList } = this.state;
      let filterEventList = this.filterData(eventList);
      filterEventList = this.sortData(filterEventList, sortBy, sortDirection);
      const groupEventList = this.groupData(filterEventList);
      this.setState({
        filterEventList,
        groupEventList,
      });
    });
  }

  @autobind
  mergeAnomalyEvents(props, eventList) {
    let newEvents = [];

    const eventsMap = {};
    R.forEach((event) => {
      const {
        mergeKey,
        RCKey,
        rootCauseTableKey,
        isNewAlert,
        isImportant,
        isIgnored,
        hasRootCause,
        hasTrailing,
        leadToIncident,
        causedByChange,
        typeList,
        avgAnomalyScore,
      } = event;
      if (!R.has(mergeKey, eventsMap)) {
        eventsMap[mergeKey] = {
          ...event,
          timelines: [event],
          RCKeys: [RCKey],
          allRootCauseTableKey: [...(rootCauseTableKey || [])],
        };
      } else {
        const newScore = (eventsMap[mergeKey]?.avgAnomalyScore || 0) + avgAnomalyScore || 0;
        eventsMap[mergeKey] = {
          ...eventsMap[mergeKey],
          typeList: R.uniq([...eventsMap[mergeKey].typeList, ...typeList]),
          isNewAlert: eventsMap[mergeKey].isNewAlert || isNewAlert,
          isImportant: eventsMap[mergeKey].isImportant || isImportant,
          isIgnored: eventsMap[mergeKey].isIgnored || isIgnored,
          hasRootCause: eventsMap[mergeKey].hasRootCause || hasRootCause,
          hasTrailing: eventsMap[mergeKey].hasTrailing || hasTrailing,
          leadToIncident: eventsMap[mergeKey].leadToIncident || leadToIncident,
          causedByChange: eventsMap[mergeKey].causedByChange || causedByChange,

          timelines: [...eventsMap[mergeKey].timelines, event],
          RCKeys: [...eventsMap[mergeKey].RCKey, RCKey],
          allRootCauseTableKey: [...eventsMap[mergeKey].allRootCauseTableKey, ...rootCauseTableKey],
          avgAnomalyScore: newScore,
          anomalyColor: calcAnomalyColor(newScore),
        };
      }
    }, eventList || []);
    newEvents = R.values(eventsMap);

    // get overlall info
    newEvents = R.map((item) => {
      // uniq by time
      const timelines = R.uniqWith(R.eqBy(R.prop('timeRangeKey')), item.timelines || []);
      let allStartTimestamp = Infinity;
      let allEndTimestamp = 0;
      R.forEach((event) => {
        allStartTimestamp = R.min(allStartTimestamp, event.startTimestamp);
        allEndTimestamp = R.max(allEndTimestamp, event.endTimestamp);
      }, timelines);
      return { ...item, timelines, allStartTimestamp, allEndTimestamp };
    }, newEvents);

    return newEvents;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [
      // make all incidents and anomlies which hasSameInstance Or HasRootCause top
      R.descend(R.prop('incidentOrHasSameInstanceOrHasRootCause')),
      // split with different incident (include root cause and same instance anomaly)
      R.descend(R.prop('incidentIdHasRootCauseOrSameInstance')),
      // make incident first, then RC, then same instance
      R.descend(R.prop('isIncident')),
      R.descend(R.prop('isRootCauseReleated')),
      R.descend(R.prop('isImportant')),
      R.descend(R.prop('isSameInstanceWithIncident')),
      R.ascend(R.prop('onlyInstanceString')),
      R.descend(R.prop('patternId')),
      R.ascend(R.prop('startTimestamp')),

      // normal sorting
      // R.descend(R.prop('leadToIncident')),
      // R.descend(R.prop('hasTrailing')),
      // R.descend(R.prop('isNewAlert')),
      // R.descend(R.prop('isWhitelistSamePattern')),
      // R.descend(R.prop('avgAnomalyScore')),
      // R.ascend(R.prop('patternId')),
    ];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  handleIgnoreClick({ event, category }) {
    const { intl } = this.props;
    const { isIgnored, isImportant, patternName, patternId } = event;
    const flag = (category === 'ignore' && isIgnored) || (category === 'important' && isImportant);
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: flag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          }),
      onOk: this.handleIgnoreSumbit(event, category),
    });
  }

  @autobind
  handleIgnoreSumbit(incident, category) {
    return () => {
      const { intl, credentials, userInfo } = this.props;
      const { isIgnored, isImportant } = incident;
      const { projectOwner, anomalyLogInstance, instanceName, patternId, type } = incident;
      let { projectName } = incident;
      projectName = projectOwner !== userInfo.userName ? `${projectName}@${projectOwner}` : projectName;

      if (this.ignoreModal) {
        this.ignoreModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      let content;
      if (category === 'ignore' && !isIgnored) {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, incident.rootCausesDetailsList || []);
        content = R.join('\n', summaryList);
      }

      let operation;
      switch (category) {
        case 'ignore':
          operation = isIgnored ? 'cancelIgnoreFlag' : 'setIgnoreFlag';
          break;
        case 'important':
          operation = isImportant ? 'cancelImportantFlag' : 'setImportantFlag';
          break;
        default:
          break;
      }

      this.props.updateLastActionInfo();
      return fetchPost(
        getEndpoint('events', 1),
        {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceName,
          operation,
          nid: patternId,
          type: type === 'Incident' ? 'incident' : undefined,
          content,
        },
        {},
        false,
      )
        .then((data) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.ignoreModal) this.ignoreModal.destroy();

          this.reloadData();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.ignoreModal) {
            this.ignoreModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  handleRCAClick(tabName, event) {
    const { systemInfo, environmentId, customerName } = this.props;
    const {
      isIncident,
      isTrace,
      category,
      type,
      day,
      startTimestamp,
      projectName,
      patternId,
      instanceList,
      metricList,
      isIgnored,
    } = event;
    const startTime = day || moment.utc(startTimestamp).format(Defaults.DateFormat);

    if (['detectedIncidents'].includes(tabName)) {
      const query = {
        environmentId,
        customerName,
        systemId: systemInfo.id,
        startTime,
        endTime: startTime,

        eventCategory: 'incident',
        eventPatternType: 'incident',
        eventProjectName: projectName,
        eventPatternId: patternId,
        eventInstanceName: instanceList[0],
        eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
        eventTimestamp: startTimestamp,
        hideIgnore: !isIgnored,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    } else {
      const query = {
        environmentId,
        customerName,
        systemId: systemInfo.id,
        startTime,
        endTime: startTime,

        hideIgnore: !isIgnored,

        eventCategory: isIncident ? 'incident' : category === 'metric' ? 'metric' : isTrace ? 'trace' : 'log',
        eventPatternType: isIncident ? 'incident' : category === 'metric' ? 'metric' : type,
        eventProjectName: projectName,
        eventPatternId: patternId,
        eventInstanceName: instanceList[0],
        eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
        eventTimestamp: startTimestamp,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    }
  }

  @autobind
  handleDetailsClick(event) {
    const { userInfo, environmentId, customerName, systemInfo, projects } = this.props;
    const {
      projectOwner,
      category,
      startTimestamp,
      endTimestamp,
      anomalyLogInstance,
      instanceList,
      metricList,
      type,
      patternId,
    } = event;

    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const startTimeObj = moment.utc(startTimestamp).startOf('day');
    const endTimeObj = moment.utc(endTimestamp).endOf('day');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    if (category === 'metric') {
      const query = {
        startTime: startTimeObj.format(Defaults.DateFormat),
        endTime: endTimeObj.format(Defaults.DateFormat),
        customerName,
        environmentId,
        systemId: systemInfo.id,

        projectName,
        instanceGroup,
        modelType,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        justInstanceList: R.join(',', instanceList),
        justSelectMetric: R.join(',', metricList),
        // withBaseline: true,
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    } else {
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const query = {
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        activeTab: type === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: project?.owner || projectOwner,
        anomalyType: type.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleOriginalNormalPatternClick(event) {
    const { userInfo, projects } = this.props;
    const { projectOwner, startTimestamp, anomalyLogInstance, instanceList, neuronId, type } = event;

    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const query = {
      projectName,
      instanceName: anomalyLogInstance || instanceList[0],
      startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      activeTab: 'clusters',
      activePatternId: neuronId,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project.owner || projectOwner,
      anomalyType: type.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  handleLogContextClick(event) {
    const { userInfo, projects } = this.props;
    const { startTimestamp, endTimestamp } = event;
    const activeEvent = {
      ...event,
      instanceName: event.anomalyLogInstance || event.instanceName,
      timestamp: event.startTimestamp,
    };
    const activeEventProjectName =
      userInfo.userName !== activeEvent.projectOwner
        ? `${activeEvent.projectName}@${activeEvent.projectOwner}`
        : activeEvent.projectName;
    const activeEventProject = R.find((project) => activeEventProjectName === project.projectName, projects);
    this.setState({
      showTimeSelectModal: true,
      activeEvent,
      activeEventProjectName,
      activeEventProject,
      selectInstance: activeEvent.instanceName,
      selectStartTimestamp: startTimestamp - 60 * 1000,
      selectEndTimestamp: endTimestamp + 60 * 1000,
    });
  }

  @autobind
  onCloseTimeSelect(props) {
    const { projectName, instanceName, startTimestamp, endTimestamp, keywordFilter } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState({
        showTimeSelectModal: false,
        showContextModal: true,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp: startTimestamp,
        selectEndTimestamp: endTimestamp,
        contextKeywordFilter: keywordFilter,
      });
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  handleActionClick(event, actionName) {
    const { userInfo, projects } = this.props;
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const activeEvent = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    const activeEventProjectName =
      userInfo.userName !== activeEvent.projectOwner
        ? `${activeEvent.projectName}@${activeEvent.projectOwner}`
        : activeEvent.projectName;
    const activeEventProject = R.find((project) => activeEventProjectName === project.projectName, projects);
    this.setState({
      showTakeLogActionModal: true,
      actionName,
      activeEvent,
      activeEventProjectName,
      activeEventProject,
    });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    this.setState({ showTakeLogActionModal: false }, () => {
      this.reloadData();
    });
  }

  @autobind
  handleTriageReportClick({ event }) {
    this.setState({ showTriageReportModal: true, activeEvent: event });
  }

  @autobind
  handleReportJiraClick(incident) {
    this.setState({ activeEvent: incident, showReportJiraModal: true });
  }

  @autobind
  handleReportServiceNowClick(incident) {
    this.setState({ activeEvent: incident, showReportServiceNowModal: true });
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }) {
    const { groupEventList } = this.state;
    const item = groupEventList[rowIndex];
    if (!item) return null;

    const { isProjectRoot, isComponentRoot, isContainerRoot, isInstanceRoot } = item;
    const isRoot = isProjectRoot || isComponentRoot || isContainerRoot || isInstanceRoot;
    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
          <div className="row-column" style={{ width: 80 }}>
            {this.scoreRender(item)}
          </div>
          <div className="row-column" style={{ minWidth: 300, flex: 1, maxWidth: 320 }}>
            {this.renderName(item)}
          </div>
          <div className="row-column" style={{ width: 100 }}>
            {!isRoot && (item.isTrace ? this.renderTrace(item) : this.renderPattern(item))}
          </div>
          <div className="row-column" style={{ width: 75 }}>
            {!isRoot && this.renderCategory(item)}
          </div>
          <div className="row-column" style={{ width: 112, padding: 0, display: 'inline-block', textAlign: 'center' }}>
            {this.renderRCFlag(item)}
          </div>
          <div
            className="row-column"
            style={{ width: 90, padding: '0 6px', display: 'inline-block', textAlign: 'left' }}
          >
            {this.renderMetricType(item)}
          </div>
          <div className="row-column" style={{ minWidth: 100, flex: 2 }}>
            <EChart width="100%" height={40} option={item.optionTimeline} onEvents={{}} />
          </div>
          <div className="row-column" style={{ width: 50, padding: 0 }}>
            {!isRoot && this.statusRender(item)}
          </div>
          <div className="row-column" style={{ width: 40 }}>
            {!isRoot && this.renderControl(item)}
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  scoreRender(rowData) {
    const { avgAnomalyScore, anomalyColor } = rowData;
    return (
      <Tag color={anomalyColor}>{numeral(avgAnomalyScore).format(avgAnomalyScore > 1 ? '0,0' : '0,0.[0000]')}</Tag>
    );
  }

  @autobind
  handleRootExpand(rootName) {
    if (this.expandedNodeMaps[rootName]) {
      delete this.expandedNodeMaps[rootName];
    } else {
      this.expandedNodeMaps[rootName] = true;
    }
    const { filterEventList, currentInstance } = this.state;

    let groupEventList = this.groupData(filterEventList);

    if (!R.isEmpty(currentInstance)) {
      const { id } = currentInstance;
      groupEventList = this.filterInstanceMenu(id, groupEventList);
    }
    this.setState({ groupEventList });
  }

  @autobind
  renderName(rowData) {
    const { systemInfo } = this.props;
    const instanceDisplayNameMap = get(systemInfo, ['instanceDisplayNameMap'], {});
    const {
      projectDisplayName,
      isProjectRoot,
      componentNameString,
      isComponentRoot,
      onlyInstanceString,
      isContainerRoot,
      containerName,
      isIncident,
      isInstanceRoot,
      uiNodeName,
    } = rowData;
    const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, onlyInstanceString, {
      pn: rowData?.projectName,
      owner: rowData?.projectOwner,
    });

    const isContainer = containerName && containerName !== fakeContainerNodeName;
    // If not container project, fix instance name bug caused by mis-config agent
    let displayName = isContainer ? onlyInstanceString : (onlyInstanceString || '').split('_')[0];
    const padding = 16;
    const style = isContainer ? { paddingLeft: padding * 4 - 4 } : { paddingLeft: padding * 3 - 4 };

    displayName = instanceDisplayName || displayName;

    if (isIncident) {
      style.paddingLeft = 0;
      if (isContainer) {
        displayName = `${projectDisplayName}/${componentNameString}/${containerName}/${
          instanceDisplayName || onlyInstanceString
        }`;
      } else {
        displayName = `${projectDisplayName}/${componentNameString}/${instanceDisplayName || onlyInstanceString}`;
      }
    }

    if (isProjectRoot) {
      displayName = projectDisplayName;
      style.paddingLeft = 0;
    } else if (isComponentRoot) {
      displayName = componentNameString;
      style.paddingLeft = padding;
    } else if (isContainerRoot) {
      displayName = containerName;
      style.paddingLeft = padding * 2;
    } else if (isInstanceRoot) {
      style.paddingLeft = isContainer ? padding * 3 : padding * 2;
    }
    return (
      <div
        className="clickable flex-row"
        style={{ alignItems: 'center', width: '100%' }}
        onClick={() => this.handleRootExpand(uiNodeName)}
      >
        <div style={{ ...style, lineHeight: '40px', paddingRight: 4 }}>
          {(isProjectRoot || isComponentRoot || isContainerRoot || isInstanceRoot) &&
            (rowData.isExpand ? <DownOutlined /> : <RightOutlined />)}
        </div>
        {!isProjectRoot &&
          !isComponentRoot &&
          !isContainerRoot &&
          !isInstanceRoot &&
          this.renderInstanceContent(displayName, rowData)}
        {(isProjectRoot || isComponentRoot || isContainerRoot || isInstanceRoot) && <span>{displayName}</span>}
      </div>
    );
  }

  @autobind
  renderInstanceContent(cellData, rowData) {
    const { intl, systemInfo } = this.props;
    const instanceDisplayNameMap = get(systemInfo, ['instanceDisplayNameMap'], {});
    const { projectDisplayName, componentNameString, onlyInstanceString, containerName } = rowData;
    const isContainer = containerName && containerName !== fakeContainerNodeName;
    // If not container project, fix instance name bug caused by mis-config agent
    const instanceString = isContainer ? onlyInstanceString : (onlyInstanceString || '').split('_')[0];
    const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, onlyInstanceString, {
      pn: rowData?.projectName,
      owner: rowData?.projectOwner,
    });
    const width = instanceDisplayName ? 140 : 80;
    return (
      <Popover
        title={null}
        content={
          <div style={{ maxWidth: 350, maxHeight: 300, overflowY: 'auto' }}>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </span>
              <span>{projectDisplayName}</span>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </span>
              <span>{componentNameString}</span>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width }}>
                {intl.formatMessage(appFieldsMessages.instance)}:
              </span>
              <span>{instanceString}</span>
            </div>
            {instanceDisplayName && (
              <div style={{ wordBreak: 'break-all' }}>
                <span className="light-label bold inline-block" style={{ width }}>
                  {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                </span>
                <span>{instanceDisplayName}</span>
              </div>
            )}
            {containerName && (
              <div style={{ wordBreak: 'break-all' }}>
                <span className="light-label bold inline-block" style={{ width }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </span>
                <span>{containerName}</span>
              </div>
            )}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <span className="max-width inline-block hidden-line-with-ellipsis">{cellData}</span>
      </Popover>
    );
  }

  @autobind
  renderTrace(rowData) {
    const { intl } = this.props;
    return (
      <Popover
        title={null}
        content={`${intl.formatMessage(DashboardMessages.detectedTrace)}: ${rowData.traceId}`}
        mouseEnterDelay={0.3}
        placement="right"
      >
        <span className="max-width inline-block hidden-line-with-ellipsis">{rowData.traceId}</span>
      </Popover>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternName: rowData.patternName, patternId: rowData.patternId },
      { hasFullName: true },
    );
    return (
      <Popover title={null} content={patternNameStr} mouseEnterDelay={0.3} placement="right">
        <span className="max-width inline-block hidden-line-with-ellipsis">{rowData.patternId}</span>
      </Popover>
    );
  }

  @autobind
  renderCategory(rowData) {
    const { intl } = this.props;
    const { typeList, category, isNewAlert, tabName, isIncident, isPredictedAnomalies } = rowData;
    return (
      <>
        {category === 'log' &&
          R.map((type) => {
            return CellRenderers.logShortTypeRenderer({ intl, type });
          }, typeList)}
        {category === 'metric' &&
          CellRenderers.logShortTypeRenderer({ intl, type: isIncident ? 'incident' : 'metric' })}
        {isNewAlert && (
          <Popover
            placement="top"
            mouseEnterDelay={0.3}
            content={intl.formatMessage(
              tabName === 'detectedAlerts' ? eventMessages.newAlert : eventMessages.newIncident,
            )}
          >
            <FlagNewIcon style={{ color: 'red', fontSize: 14, margin: '0 4px 2px 0' }} />
          </Popover>
        )}
        {isPredictedAnomalies && (
          <span
            style={{
              background: '#808080',
              color: 'white',
              display: 'inline-block',
              width: 16,
              height: 16,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            P
          </span>
        )}
      </>
    );
  }

  @autobind
  renderRCFlag(rowData) {
    const { intl } = this.props;
    const { isRootCauseReleated, RCSequence } = rowData;
    if (isRootCauseReleated) {
      return (
        <Popover content={intl.formatMessage(eventMessages.likelyRootCause)} mouseEnterDelay={0.3} placement="top">
          <Tag color="#FF5142" style={{ padding: '0 4px', margin: 0 }}>
            RC{RCSequence || ''}
          </Tag>
        </Popover>
      );
    }
    return null;
  }

  @autobind
  renderMetricType(rowData) {
    const metricType = rowData?.metricRootCauseJson?.metricType || '';
    return <span>{metricType.toLowerCase() === 'unknown' ? '-' : metricType}</span>;
  }

  @autobind
  statusRender(rowData) {
    const { intl, timezoneOffset, credentials, updateLastActionInfo } = this.props;
    const { tabName } = rowData;
    const { id, isIgnored, isImportant, isNewAlert, reporterRecordMap } = rowData;
    const { leadToIncident, hasTrailing, causedByChange, hasRootCause } = rowData;

    return (
      <div className="flex-row flex-wrap full-width">
        <EventRenderers.RenderPatternReporterTooltip
          intl={intl}
          actionName={
            isImportant
              ? intl.formatMessage(eventMessages.markedAsImportant)
              : intl.formatMessage(eventMessages.markedAsUnimportant)
          }
          timezoneOffset={timezoneOffset}
          reporterRecordSet={get(reporterRecordMap, isImportant ? 'importance' : 'unImportance', [])}
        >
          {isImportant ? (
            <GoodIcon
              style={{ fontSize: 14, margin: '0 4px 2px 0', color: '#32C880' }}
              onClick={() => this.handleIgnoreClick({ event: rowData, category: 'important' })}
            />
          ) : (
            <GoodOutlinedIcon
              style={{ fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={() => this.handleIgnoreClick({ event: rowData, category: 'important' })}
            />
          )}
        </EventRenderers.RenderPatternReporterTooltip>
        <EventRenderers.RenderPatternReporterTooltip
          intl={intl}
          actionName={
            isIgnored
              ? intl.formatMessage(eventMessages.markedAsIgnored)
              : intl.formatMessage(eventMessages.markedAsUnignored)
          }
          timezoneOffset={timezoneOffset}
          reporterRecordSet={get(reporterRecordMap, isIgnored ? 'ignore' : 'unIgnore', [])}
        >
          {isIgnored ? (
            <EyeInvisibleOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          ) : (
            <EyeOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          )}
        </EventRenderers.RenderPatternReporterTooltip>

        {false && leadToIncident && (
          <Popover
            placement="top"
            content={
              <RenderLeadToIncident
                intl={intl}
                credentials={credentials}
                incident={rowData}
                leadIncidentPatternNameStr={this.leadIncidentPatternNameStrMap[id]}
                setIncidentPatternNameStr={(leadIncidentPatternNameStr) => {
                  this.leadIncidentPatternNameStrMap[id] = leadIncidentPatternNameStr;
                }}
                updateLastActionInfo={updateLastActionInfo}
              />
            }
            mouseEnterDelay={0.3}
          >
            <ExclamationCircleOutlined
              style={{ color: '#FF5142', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAClick(tabName, rowData);
              }}
            />
          </Popover>
        )}
        {false && hasTrailing && (
          <Popover
            content={
              <div>
                <div>{intl.formatMessage(DashboardMessages.incidentHasTrailing)}</div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="top"
          >
            <IncidentPredictIcon
              style={{ color: '#FF5142', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAClick(tabName, rowData);
              }}
            />
          </Popover>
        )}
        {false && causedByChange && (
          <Popover
            content={
              <div>
                <div>
                  {tabName === 'detectedAlerts'
                    ? intl.formatMessage(DashboardMessages.causedByChangeAlert)
                    : intl.formatMessage(DashboardMessages.causedByChangeIncident)}
                </div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="top"
          >
            <ChangeEventIcon
              style={{ color: 'orange', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAClick(tabName, rowData);
              }}
            />
          </Popover>
        )}
        {false && hasRootCause && (
          <Popover
            content={
              <div>
                <div>{intl.formatMessage(DashboardMessages.incidentHasRootCause)}</div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="top"
          >
            <RootCauseIcon
              style={{ color: '#FF5142', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAClick(tabName, rowData);
              }}
            />
          </Popover>
        )}
        {false && isNewAlert && (
          <Popover
            placement="top"
            mouseEnterDelay={0.3}
            content={intl.formatMessage(
              tabName === 'detectedAlerts' ? eventMessages.newAlert : eventMessages.newIncident,
            )}
          >
            <FlagNewIcon style={{ color: 'red', fontSize: 14, margin: '0 4px 2px 0' }} />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  renderControl(event) {
    const { intl } = this.props;
    const { tabName, category, typeList, neuronId } = event;
    return (
      <Dropdown
        icon={<SearchOutlined />}
        itemClick={({ key }) => {
          switch (key) {
            case 'details':
              this.handleRCAClick(tabName, event);
              break;
            case 'lineChart':
              this.handleDetailsClick(event);
              break;
            case 'logAnalysis':
              this.handleDetailsClick(event);
              break;
            case 'originalNormalPattern':
              this.handleOriginalNormalPatternClick(event);
              break;
            case 'context':
              this.handleLogContextClick(event);
              break;
            case 'setPatternName':
              this.handleActionClick(event, 'setPatternName');
              break;
            case 'editTriageReport':
              this.handleTriageReportClick({ event });
              break;
            case 'reportJira':
              this.handleReportJiraClick(event);
              break;
            case 'reportServiceNow':
              this.handleReportServiceNowClick(event);
              break;
            default:
              break;
          }
        }}
      >
        <>
          {category === 'metric' && (
            <Menu.Item key="lineChart">{intl.formatMessage(DashboardMessages.lineChart)}</Menu.Item>
          )}
          {category !== 'metric' && (
            <Menu.Item key="logAnalysis">{intl.formatMessage(appMenusMessages.logAnalysis)}</Menu.Item>
          )}
          {['detectedIncidents', 'detectedAlerts'].includes(tabName) && (
            <Menu.Item key="details">{intl.formatMessage(appFieldsMessages.details)}</Menu.Item>
          )}
          {category !== 'metric' && typeList.includes('rare') && isNumber(neuronId) && (
            <Menu.Item key="originalNormalPattern">
              {intl.formatMessage(DashboardMessages.originalNormalPattern)}
            </Menu.Item>
          )}
          {['detectedAlerts'].includes(tabName) && event.category === 'log' && (
            <Menu.Item key="context">{intl.formatMessage(eventMessages.context)}</Menu.Item>
          )}
          <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item>
          <Menu.Item key="editTriageReport">{intl.formatMessage(eventMessages.editTriageReport)}</Menu.Item>
          <Menu.Item key="reportJira">{intl.formatMessage(eventMessages.reportJira)}</Menu.Item>
          <Menu.Item key="reportServiceNow">{intl.formatMessage(eventMessages.reportServiceNow)}</Menu.Item>
        </>
      </Dropdown>
    );
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection, filterEventList } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const newEventList = this.sortData(filterEventList, name, sortDir);
          const groupEventList = this.groupData(newEventList);
          this.setState({ filterEventList: newEventList, groupEventList });
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  selectIncidentFirstItem() {
    if (this.incidentList && this.incidentList.length > 0) {
      this.handleIncidentClick(this.incidentList[0]);
    }
  }

  @autobind
  handleIncidentClick(rowData) {
    const { filterEventList } = this.state;
    const groupEventList = this.groupData(filterEventList);
    const { id = '' } = rowData;
    const newGroupEventList = this.filterInstanceMenu(id, groupEventList);

    this.setState({
      groupEventList: newGroupEventList,
      currentInstance: rowData,
    });
  }

  @autobind
  filterInstanceMenu(id, groupEventList) {
    const newGroupEventList = R.filter((n) => {
      const instance = n.filterIncidentId === id || n.id === id;
      const relateNode = n.isRootCauseReleated ? n.item && n.item.filterIncidentId === id : false;
      const others = n.isOther;
      return instance || relateNode || others;
    }, groupEventList);
    return newGroupEventList;
  }

  @autobind
  renderInstanceListItem({ style, index: rowIndex }, instanceList) {
    const { intl, systemInfo } = this.props;
    const { currentInstance } = this.state;
    const instanceDisplayNameMap = get(systemInfo, ['instanceDisplayNameMap'], {});
    const rowData = instanceList[rowIndex];
    const { id, projectDisplayName, componentNameString, onlyInstanceString, containerName } = rowData;
    const active = isEqual(currentInstance, rowData);
    const projectNames = [];
    const allRootCauseOfIncident = (this.incidentEventMap || {})[id] || [];

    const causalChainMap = {};
    const causalInstanceMap = {};
    R.forEach((item) => {
      const { instanceNameString, projectDisplayName, category, type, startTimestamp } = item;
      const startTs = String(parseInt(startTimestamp / (1000 * 60), 10));

      let rcCategory = '';
      if (category === 'metric') {
        const itemMetricType = item?.metricRootCauseJson?.metricType || null;
        if (itemMetricType) {
          rcCategory = itemMetricType;
        }
      }
      if (category === 'log') {
        rcCategory = type;
      }

      if (!projectNames.includes(projectDisplayName)) {
        projectNames.push(projectDisplayName);
      }

      if (!causalInstanceMap[instanceNameString]) {
        if (!causalChainMap[startTs]) {
          causalChainMap[startTs] = {};
        }

        if (!causalChainMap[startTs][instanceNameString]) {
          causalInstanceMap[instanceNameString] = startTs;
          causalChainMap[startTs][instanceNameString] = {};
        }
        causalChainMap[startTs][instanceNameString][rcCategory] = true;
      } else {
        causalChainMap[causalInstanceMap[instanceNameString]][instanceNameString][rcCategory] = true;
      }
    }, R.sort(R.ascend(R.props(['startTimestamp'])), allRootCauseOfIncident));

    const isContainer = containerName && containerName !== fakeContainerNodeName;
    const instanceString = isContainer ? onlyInstanceString : (onlyInstanceString || '').split('_')[0];
    const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, onlyInstanceString, {
      pn: rowData?.projectName,
      owner: rowData?.projectOwner,
    });
    let displayName = '';
    if (isContainer) {
      displayName = `${projectDisplayName}/${componentNameString}/${containerName}/${
        instanceDisplayName || onlyInstanceString
      }`;
    } else {
      displayName = `${projectDisplayName}/${componentNameString}/${instanceDisplayName || onlyInstanceString}`;
    }

    const hasErrorSummary = R.isEmpty(allRootCauseOfIncident);

    return (
      <Popover
        placement="rightTop"
        content={
          <div className="flex-column" style={{ width: 500 }}>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width: 140 }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </span>
              <span>{projectDisplayName}</span>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width: 140 }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </span>
              <span>{componentNameString}</span>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width: 140 }}>
                {intl.formatMessage(appFieldsMessages.instance)}:
              </span>
              <span>{instanceString}</span>
            </div>
            {instanceDisplayName && (
              <div style={{ wordBreak: 'break-all' }}>
                <span className="light-label bold inline-block" style={{ width: 140 }}>
                  {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                </span>
                <span>{instanceDisplayName}</span>
              </div>
            )}
            {containerName && (
              <div style={{ wordBreak: 'break-all' }}>
                <span className="light-label bold inline-block" style={{ width: 140 }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </span>
                <span>{containerName}</span>
              </div>
            )}

            <div style={{ wordBreak: 'break-word' }} className="flex-row">
              <span className="light-label bold inline-block" style={{ width: 140, wordBreak: 'break-all' }}>
                Root cause summary:
              </span>
              <span className="flex-grow">
                {hasErrorSummary && <div>No causally related anomalies are found</div>}
                {!hasErrorSummary && (
                  <div>
                    <span>This incident has strong causality with this causal chain:</span>
                    {R.addIndex(R.map)(
                      (ts, idx) => {
                        const instanceMap = causalChainMap[ts];
                        const instList = [];
                        R.forEachObjIndexed((val, inst) => {
                          instList.push(`${inst} (${R.keys(val).join(', ')})`);
                        }, instanceMap);
                        return (
                          <>
                            {R.addIndex(R.map)((v, i) => {
                              return (
                                <>
                                  <span style={{ marginLeft: 4, color: 'var(--blue)' }}>{v}</span>
                                  {i !== instList.length - 1 && <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>,</span>}
                                </>
                              );
                            }, instList)}
                            {idx !== R.keys(causalChainMap).length - 1 && (
                              <span style={{ color: 'rgba(0, 0, 0, 0.85)', paddingLeft: '4px' }}>
                                <ArrowRightOutlined />
                              </span>
                            )}
                          </>
                        );
                      },
                      R.sort((a, b) => a - b, R.keys(causalChainMap)),
                    )}
                    <span> from projects</span>
                    <span style={{ marginLeft: 4, color: 'var(--blue)' }}>{projectNames.join(', ')}</span>.
                  </div>
                )}
              </span>
            </div>
          </div>
        }
        trigger="hover"
        key={id}
      >
        <div
          className={`event-list-row ${active ? 'active' : ''}${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          style={{ ...style, minHeight: 40, cursor: 'pointer', paddingLeft: 10 }}
          onClick={() => this.handleIncidentClick(rowData)}
        >
          <div className="max-width inline-block hidden-line-with-ellipsis">{displayName}</div>
        </div>
      </Popover>
    );
  }

  render() {
    const {
      intl,
      style,
      environmentId,
      systemInfo,
      isLoadingPanelAnomalies,
      isLoadingPanelAlert,
      isLoadingPanelIncident,
    } = this.props;
    const { filterProject, filterInstance, filterPattern, filterMetric, sortBy, sortDirection, logsummaryLoading } =
      this.state;
    const { isLoading, groupEventList: groupEventListByFilter, optionTimelineRange } = this.state;
    const instanceDisplayNameMap = get(systemInfo, ['instanceDisplayNameMap'], {});

    let { instanceOptions, patternOptions, metricOptions } = this;
    if (filterProject) {
      instanceOptions = R.filter((item) => item.projectNames.includes(filterProject), instanceOptions);
      patternOptions = R.filter((item) => item.projectNames.includes(filterProject), patternOptions);
      metricOptions = R.filter((item) => item.projectNames.includes(filterProject), metricOptions);
    }
    if (filterInstance) {
      patternOptions = R.filter((item) => item.instances.includes(filterInstance), patternOptions);
      metricOptions = R.filter((item) => item.instances.includes(filterInstance), metricOptions);
    }
    const groupEventList = groupEventListByFilter;

    const incidentList = this.incidentList || [];
    return (
      <div className="flex-grow flex-col flex-min-height content-bg corner-10" style={{ ...style, padding: 8 }}>
        <Spin
          wrapperClassName="full-width full-height spin-full-height"
          spinning={
            isLoading || logsummaryLoading || isLoadingPanelAnomalies || isLoadingPanelAlert || isLoadingPanelIncident
          }
        >
          <div className="flex-row" style={{ marginBottom: 8 }}>
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              placeholder={intl.formatMessage(eventMessages.projectName)}
              style={{ width: 160 }}
              value={filterProject}
              onChange={(filterProject) => this.onChangeFilterField('filterProject', filterProject)}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {this.projectOptions.map((item) => (
                <Select.Option key={item.projectName} value={item.projectName}>
                  {item.projectDisplayName}
                </Select.Option>
              ))}
            </Select>
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              placeholder={intl.formatMessage(eventMessages.instanceName)}
              style={{ width: 160, marginLeft: 8 }}
              optionFilterProp="value"
              value={filterInstance}
              onChange={(filterInstance) => this.onChangeFilterField('filterInstance', filterInstance)}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
              }
            >
              {instanceOptions.map((item) => {
                const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, item.value);
                return (
                  <Select.Option key={item.value} value={item.value} label={instanceDisplayName || item.value}>
                    {instanceDisplayName || item.value}
                  </Select.Option>
                );
              })}
            </Select>
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              placeholder={intl.formatMessage(eventMessages.incidentPatternId)}
              style={{ width: 160, marginLeft: 8 }}
              optionFilterProp="value"
              value={filterPattern}
              onChange={(filterPattern) => this.onChangeFilterField('filterPattern', filterPattern)}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {patternOptions.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              placeholder={intl.formatMessage(eventMessages.metricFilter)}
              style={{ width: 160, marginLeft: 8 }}
              optionFilterProp="value"
              value={filterMetric}
              onChange={(filterMetric) => this.onChangeFilterField('filterMetric', filterMetric)}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {metricOptions.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="flex-grow flex-min-height">
            <AutoSizer>
              {({ width, height }) => {
                const hasIncident = !R.isEmpty(incidentList);
                const instancesWidth = hasIncident ? 200 : 0;
                const listWidth = width - instancesWidth;

                return (
                  <div className="flex-row">
                    {hasIncident && (
                      <div className="event-list" style={{ flex: `0 0 ${instancesWidth}px` }}>
                        <div className="event-list-header" style={{ height: 40, width: instancesWidth - 10 }}>
                          <div className="header-column" style={{ flex: 1 }}>
                            Incident Browse
                          </div>
                        </div>
                        <List
                          className="event-list-grid"
                          ref={(listNode) => {
                            this.incidentListNode = listNode;
                          }}
                          width={instancesWidth - 10}
                          height={height - 40}
                          rowCount={incidentList.length}
                          rowHeight={this.cellMeasureCache.rowHeight}
                          rowRenderer={(data) => this.renderInstanceListItem(data, incidentList)}
                        />
                      </div>
                    )}

                    <div className="event-list flex-grow">
                      <div
                        ref={(listHeader) => {
                          this.listHeader = listHeader;
                        }}
                        className="event-list-header"
                        style={{ height: 40, width: listWidth, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                      >
                        <div
                          className="header-column"
                          style={{ width: 80 }}
                          onClick={this.headerClick('avgAnomalyScore')}
                        >
                          <span>{intl.formatMessage(eventMessages.anomalyScore)}</span>
                          {this.sortIcon(sortBy, sortDirection, 'avgAnomalyScore')}
                        </div>
                        <div className="header-column" style={{ minWidth: 300, flex: 1, maxWidth: 320 }}>
                          {intl.formatMessage(eventMessages.projectInstanceName)}
                        </div>
                        <div
                          className="header-column"
                          style={{ minWidth: 100 }}
                          onClick={this.headerClick('patternAndTrace')}
                        >
                          <span>{intl.formatMessage(logMessages.patternAndTrace)}</span>
                          {this.sortIcon(sortBy, sortDirection, 'patternAndTrace')}
                        </div>
                        <div className="header-column" style={{ minWidth: 75 }}>
                          {intl.formatMessage(appFieldsMessages.type)}
                        </div>
                        <div className="header-column" style={{ minWidth: 112 }}>
                          {intl.formatMessage(eventMessages.likelyRootCause)}
                        </div>
                        <div className="header-column" style={{ minWidth: 90 }}>
                          {intl.formatMessage(eventMessages.metricType)}
                        </div>
                        <div className="header-column" style={{ minWidth: 100, flex: 2 }}>
                          {optionTimelineRange && (
                            <EChart width="100%" height={40} option={optionTimelineRange} onEvents={{}} />
                          )}
                        </div>
                        <div className="header-column" style={{ minWidth: 50 }} />
                        <div className="header-column" style={{ minWidth: 40 }} />
                      </div>
                      <List
                        className="event-list-grid"
                        ref={(listNode) => {
                          this.listNode = listNode;
                        }}
                        width={listWidth}
                        height={height - 40}
                        rowCount={groupEventList.length}
                        overscanRowCount={4}
                        deferredMeasurementCache={this.cellMeasureCache}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowRenderer={this.renderListItem}
                        onScrollbarPresenceChange={({ horizontal, vertical }) => {
                          if (vertical) {
                            this.listNodeHeaderScrollbar = true;
                          } else {
                            this.listNodeHeaderScrollbar = false;
                          }
                          this.forceUpdate();
                        }}
                      />
                    </div>
                  </div>
                );
              }}
            </AutoSizer>
          </div>
        </Spin>

        {this.state.showTimeSelectModal && (
          <TimeSelectModal
            projectName={this.state.activeEventProjectName}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
            timeIntervals={1}
            showKeywordSearch
          />
        )}
        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeEvent}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            keywordFilter={this.state.contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
          />
        )}
        {this.state.showTriageReportModal && (
          <TriageReportModal
            environmentId={environmentId}
            systemId={systemInfo.id}
            incident={this.state.activeEvent}
            onClose={() => this.setState({ showTriageReportModal: false, activeEvent: null })}
          />
        )}
        {this.state.showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={this.state.actionName}
            incident={this.state.activeEvent}
            project={this.state.activeEventProject}
            projectName={this.state.activeEvent.fullProjectName}
            instanceGroup="All"
            eventType={this.state.activeEvent.type}
            onClose={() => this.setState({ showTakeLogActionModal: false })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {this.state.showReportJiraModal && (
          <ReportJiraModal
            incident={this.state.activeEvent}
            projectName={this.state.activeEventProjectName}
            onClose={() => this.setState({ showReportJiraModal: false })}
          />
        )}
        {this.state.showReportServiceNowModal && (
          <ReportServiceNowModal
            incident={this.state.activeEvent}
            projectName={this.state.activeEventProjectName}
            onClose={() => this.setState({ showReportServiceNowModal: false })}
          />
        )}
      </div>
    );
  }
}

const GlobalZoomTimelins = injectIntl(GlobalZoomTimelinsCore);
export default connect(
  (state) => {
    const { loadStatus, timezoneOffset, projects, systemsMap, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    const {
      globalSystemAnomalyTimelines,
      globalSystemAlertTimelines,
      globalSystemDetectIncidentTimelines,
      globalSystemDeployTimelines,
    } = state.dashboard;
    return {
      loadStatus,
      timezoneOffset,
      projects,
      systemsMap,
      credentials,
      userInfo,

      globalSystemAnomalyTimelines,
      globalSystemAlertTimelines,
      globalSystemDetectIncidentTimelines,
      globalSystemDeployTimelines,
      currentTheme,
    };
  },
  { updateLastActionInfo, createLoadAction },
)(GlobalZoomTimelins);
