import React, { useRef, useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import { Spin, message } from 'antd';
import { EChart } from '../../share';
import worldGeo from '../../../../assets/world.geo';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';
import { getCacheData, setCacheData } from '../utils';

const anomalyColors = [
  // '#1F4B99',
  // '#2B5E9C',
  // '#38709E',
  // '#48819F',
  '#5B92A1',
  '#71A3A2',
  '#8AB3A2',
  '#A7C3A2',
  '#C7D1A1',
  '#EBDDA0',
  '#FCD993',
  '#F5C57D',
  '#EDB269',
  '#E49F57',
  '#DA8C46',
  '#CF7937',
  '#C4662A',
  '#B8541E',
  '#AB4015',
  '#9E2B0E',
];

const getAnomalyColor = (idx) => {
  return idx >= anomalyColors.length ? anomalyColors[0] : anomalyColors[anomalyColors.length - idx];
};

const WorldMap = (props: Object) => {
  const { systemId, systemsMap, projects, systemInfo, credentials, startTime, endTime } = props;
  const echartRef = useRef({});
  const [option, setOption] = useState({});
  const [isLoading, setLoading] = useState(false);

  const parseData = (data, locationMap) => {
    const { echarts } = echartRef?.current;
    if (echarts) {
      let scatterData = R.map((key) => {
        const { lg, lt } = data[key];
        return [lg, lt, 100, key, locationMap[key]];
      }, R.keys(data));
      scatterData = R.addIndex(R.map)(
        (x, idx) => [...x, scatterData.length - idx],
        R.sort((a, b) => a[4] - b[4], scatterData),
      );
      echarts.registerMap('world', worldGeo);
      setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b}',
        },
        geo: [
          {
            map: 'world',
            // show: false,
            roam: false,
            geoIndex: 0,
            z: 2,
            itemStyle: {
              normal: {
                areaColor: 'rgba(100,100,100,1)',
                borderColor: 'rgba(100,100,100,1)',
                borderCap: 'round',
                borderJoin: 'bevel',
              },
              emphasis: {
                areaColor: 'rgba(100,100,100,1)',
                borderColor: 'rgba(100,100,100,1)',
                label: {
                  color: 'rgb(235, 123, 24)',
                },
              },
            },
            emphasis: {
              label: {
                show: false,
              },
            },
            tooltip: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            symbolSize: (params) => {
              return 12;
            },
            showEffectOn: 'emphasis',
            rippleEffect: {
              brushType: 'stroke',
            },
            hoverAnimation: true,
            geoIndex: 0,
            itemStyle: {
              color: ({ data }) => getAnomalyColor((data || [])[5]),
            },
            label: {
              formatter: ({ value, marker }) => {
                return `${value[5]}`;
              },
              position: 'right',
              show: true,
            },
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: ({ value, marker }) => {
                const content = ReactDOMServer.renderToStaticMarkup(
                  <div className="flex-col" style={{ fontSize: 12 }}>
                    <div>Rank:{value[5]}</div>
                    <div>
                      {value[3]}:
                      <span className="bold" style={{ marginLeft: 4 }}>
                        {value[4]}
                      </span>
                    </div>
                  </div>,
                );
                return content;
              },
            },
            data: scatterData,
          },
        ],
      });
    }
  };

  const fetchLocation = (data) => {
    const { anomalies = {} } = data;
    const locationMap = {};

    R.forEachObjIndexed((val) => {
      locationMap[val.instanceName] = val.score;
    }, anomalies);
    const params = {
      ...credentials,
      instanceName: JSON.stringify(R.keys(locationMap)),
      customerName: systemInfo?.customerName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      cacheKey: 'city-location',
    };
    const apiData = getCacheData(params);
    if (apiData) {
      parseData(apiData, locationMap);
      setLoading(false);
      return;
    }
    fetchGet(getEndpoint('city-location'), R.clone(params))
      .then((res) => {
        parseData(res, locationMap);
        setCacheData(params, res);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        parseData({}, {});
      });
  };

  const reloadData = () => {
    if (!systemInfo) return;
    const params = {
      ...credentials,
      customerName: systemInfo?.customerName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      limit: 300,
      sortKey: 'score',
      granularity: 'instance',
      groupTogether: true,
    };
    const apiData = getCacheData(params);
    if (apiData) {
      fetchLocation(apiData);
      return;
    }
    if (!systemId) return;
    setLoading(true);
    fetchGet(getEndpoint('pie-chart-index'), R.clone(params))
      .then(async (data) => {
        fetchLocation(data);
        setCacheData(params, data);
      })
      .catch((e) => {
        setLoading(false);
        parseData([]);
        console.error(e.message || String(e));
      });
  };

  useEffect(() => {
    reloadData();
  }, [echartRef, systemId]);
  return (
    <Spin spinning={isLoading} wrapperClassName="spin-base overflow-x-hidden overflow-y-auto full-width full-height">
      <div className="font-14 bold flex-row flex-space-between flex-center-align">
        <span>World map</span>
      </div>
      <div style={{ height: 'calc(100% - 20px)' }}>
        <EChart
          setRef={(r) => (echartRef.current = r)}
          option={option}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </Spin>
  );
};

export default WorldMap;
