/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Spin, Form, Input, Select, Alert, message, Button } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { buildUrl } from '../../../common/utils';
import { BaseUrls } from '../../app/Constants';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  incident: Object,
  // eslint-disable-next-line
  projectName: String,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class ReportJiraModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { rawData, rootCausesDetailsList } = props.incident || {};
    this.state = {
      isLoading: false,
      isSubmiting: false,
      errMessage: undefined,

      projectKey: undefined,
      jiraAccountId: undefined,
      jiraReporterId: undefined,
      issuefields: [],
      summary: '',
      rawData: rawData || this.parseRootCauseList(rootCausesDetailsList),
    };
    this.jiraProjectOptions = [];
    this.jiraAssigneeOptions = [];
    this.jiraReporterOptions = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.incident !== nextProps.incident) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    this.setState({ isLoading: true });
    const { intl, credentials, incident } = this.props;
    const { projectOwner } = incident || {};

    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('jiraGetMeta'), {
      ...credentials,
      customerName: projectOwner,
    })
      .then((d) => {
        const { success, message, projects } = d || {};
        let errMessage;
        let jiraProjectOptions = [];
        if (success) {
          jiraProjectOptions = projects || [];
        } else {
          errMessage = message;
        }
        this.jiraProjectOptions = jiraProjectOptions;
        this.setState({ isLoading: false, errMessage });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, errMessage: JSON.stringify(err) });
      });
  }

  @autobind
  parseRootCauseList(rootCausesDetailsList) {
    const contentList = [];
    R.forEach((item) => {
      const { instanceDown, processCrash } = item;
      const { appName, componentName, rootCauseSource } = item;
      let { instanceId, rootCauseMetric, metricValue, pct, direction } = item;
      const { instanceName, metricName, anomalyValue, percentage, sign } = item;

      if (!instanceId && instanceName) instanceId = instanceName;
      if (!rootCauseMetric && metricName) rootCauseMetric = metricName;
      if (!metricValue && anomalyValue) metricValue = anomalyValue;
      if (!direction && sign) direction = sign;
      if (!pct && percentage) pct = percentage;

      if (pct && Number(pct) < 0) direction = 'lower';
      if (direction === 'positive') {
        direction = 'higher';
      } else if (direction === 'negative') {
        direction = 'lower';
      }
      const name =
        appName ||
        (componentName && componentName.indexOf(instanceId) === -1 ? `${instanceId} (${componentName})` : instanceId);

      if (instanceDown) {
        contentList.push(`Instance: ${name} Status: Missing Data`);
      } else {
        let content = `${rootCauseMetric}(${numeral(metricValue).format(
          metricValue > 1 ? '0,0' : '0,0.0[00000]',
        )}) is ${numeral(Math.abs(Number(pct) / 100)).format('0.0%')} ${direction} than ${
          rootCauseSource || 'normal'
        } at ${name}`;
        if (processCrash) {
          content += ` and process crashed`;
        }
        contentList.push(content);
      }
    }, rootCausesDetailsList);
    return R.join('\n', contentList);
  }

  @autobind
  handleProjectChange(projectKey) {
    this.setState({ projectKey }, () => {
      this.reloadProjectInfo();
    });
  }

  @autobind
  reloadProjectInfo() {
    this.setState({ isLoading: true });
    const { intl, credentials } = this.props;
    const { projectKey } = this.state;

    const requests = [
      fetchGet(getEndpoint('jiraPostEvent'), {
        ...credentials,
        projectKeys: projectKey,
        projectKey,
      }).then((data) => {
        return data;
      }),
      fetchGet(getEndpoint('jiraReporter'), {
        ...credentials,
        projectKeys: projectKey,
        projectKey,
      }).then((data) => {
        return data;
      }),
      fetchGet(getEndpoint('issuefields'), {
        ...credentials,
        projectKeys: projectKey,
        projectKey,
      }).then((data) => {
        return data;
      }),
    ];
    Promise.all(requests)
      .then((results) => {
        const data = results[2] || [];
        const issuefields = [];
        R.forEach((item) => {
          const { name, key, type, allowedValues } = item;
          issuefields.push({
            name,
            key,
            type,
            allowedValues,

            value: type === 'array' ? undefined : '',
          });
        }, data);

        this.jiraAssigneeOptions = results[0] || [];
        this.jiraReporterOptions = results[1] || [];
        this.setState({ isLoading: false, errMessage: undefined, issuefields });
      })
      .catch((err) => {
        this.jiraAssigneeOptions = [];
        this.jiraReporterOptions = [];
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, errMessage: JSON.stringify(err), issuefields: [] });
      });
  }

  @autobind
  handleIssuefieldsChange(index, value) {
    const { issuefields } = this.state;
    const vals = issuefields[index];
    const newIssuefields = update(issuefields, { [index]: { $set: { ...vals, value } } });
    this.setState({ issuefields: newIssuefields });
  }

  @autobind
  handleSubmit() {
    this.setState({ isSubmiting: true });
    const { intl, credentials, onClose, incident } = this.props;
    const { projectKey, jiraAccountId, jiraReporterId, issuefields, summary, rawData } = this.state;
    const { projectName, patternId, anomalyScore, anomalyRatio, projectOwner } = incident;

    // add required fields
    const issuefieldsParams = {};
    R.forEach((field) => {
      issuefieldsParams[field.key] = field.value;
    }, issuefields);

    this.props.updateLastActionInfo();
    fetchPost(
      getEndpoint('jiraPostEvent'),
      {
        ...credentials,
        projectKey,
        jiraAccountId,
        jiraReporterId,
        ...issuefieldsParams,
        summary,
        customerName: projectOwner,

        // info
        projectName,
        patternId,
        anomalyScore: anomalyScore || anomalyRatio,
        rawData,
      },
      {},
      false,
    )
      .then((d) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmiting: false, errMessage: undefined });
        onClose();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMessage: JSON.stringify(err) });
      });
  }

  render() {
    const { intl, onClose } = this.props;
    const { isLoading, isSubmiting, errMessage } = this.state;
    const { projectKey, jiraAccountId, jiraReporterId, issuefields, summary, rawData } = this.state;

    const hasError = !projectKey || !jiraAccountId || !jiraReporterId || !summary || !rawData;
    // const hasError = false;
    return (
      <Modal
        title={intl.formatMessage(eventMessages.reportJira)}
        width={750}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            {errMessage && (
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Alert
                  message={errMessage}
                  description={
                    <span>
                      Please go to{' '}
                      <Button
                        type="link"
                        style={{ margin: 0, padding: 0 }}
                        onClick={() => window.open(buildUrl(BaseUrls.ExternalServiceSetting, {}, {}), '_blank')}
                      >
                        external service
                      </Button>{' '}
                      page and add Jira credentials.
                    </span>
                  }
                  type="error"
                  showIcon
                />
              </Form.Item>
            )}

            <Form.Item
              label="Jira Project"
              validateStatus={!projectKey ? 'error' : 'success'}
              help={!projectKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={projectKey}
                onChange={this.handleProjectChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.key}>{item.name}</Select.Option>
                  ),
                  this.jiraProjectOptions || [],
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Assignee"
              validateStatus={!jiraAccountId ? 'error' : 'success'}
              help={!jiraAccountId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={jiraAccountId}
                onChange={(jiraAccountId) => this.setState({ jiraAccountId })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.accountId}>{item.displayName}</Select.Option>
                  ),
                  this.jiraAssigneeOptions || [],
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Reporter"
              validateStatus={!jiraReporterId ? 'error' : 'success'}
              help={!jiraReporterId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={jiraReporterId}
                onChange={(jiraReporterId) => this.setState({ jiraReporterId })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.accountId}>{item.displayName}</Select.Option>
                  ),
                  this.jiraReporterOptions || [],
                )}
              </Select>
            </Form.Item>

            {R.addIndex(R.map)((field, index) => {
              const { name, type, allowedValues, value } = field;
              if (type === 'array') {
                return (
                  <Form.Item
                    key={index}
                    label={name}
                    validateStatus={!value ? 'error' : 'success'}
                    help={!value ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                    required
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={value}
                      onChange={(value) => this.handleIssuefieldsChange(index, value)}
                    >
                      {R.map(
                        (item) => (
                          <Select.Option key={item.id}>{item.name}</Select.Option>
                        ),
                        allowedValues || [],
                      )}
                    </Select>
                  </Form.Item>
                );
              }
              return (
                <Form.Item
                  key={index}
                  label={name}
                  validateStatus={!value ? 'error' : 'success'}
                  help={!value ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Input value={value} onChange={(e) => this.handleIssuefieldsChange(index, e.target.value)} />
                </Form.Item>
              );
            }, issuefields)}

            <Form.Item
              label="Summary"
              validateStatus={!summary ? 'error' : 'success'}
              help={!summary ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={summary} onChange={(e) => this.setState({ summary: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Description"
              validateStatus={!rawData ? 'error' : 'success'}
              help={!rawData ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
                value={rawData}
                onChange={(e) => this.setState({ rawData: e.target.value })}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const ReportJiraModal = injectIntl(ReportJiraModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials } = state.auth;
    return {
      location,
      loadStatus,
      credentials,
    };
  },
  { updateLastActionInfo },
)(ReportJiraModal);
