import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Checkbox, Input, Select, Spin, message } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { State } from '../../../../common/types';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { AutoSizer, Column, Container, Modal, SortDirection, Table } from '../../../../lib/fui/react';

import { appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

const rowLoading = '__loading__';

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

const AddValidationModal = (props: Object) => {
  const { intl, onClose, credentials, metricProjects, updateLastActionInfo, systemsMap, systemId } = props || {};
  const { projectMetricMap, changeProjectMetricMap, validationList } = props || {};
  const dataTableNode = useRef(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isSubmitting: false,

    metricNameMapList: [],
  });
  const { isSubmitting, metricNameMapList } = state;

  const updateDataTable = () => {
    if (dataTableNode.current) {
      dataTableNode.current.forceUpdate();
      forceUpdate();
    }
  };

  const reloadMetricData = (metricProject, { rowData, dataKey }) => {
    if (!metricProject) return;
    const findProject = R.find((p) => p.projectName === metricProject, metricProjects || []);
    if (!findProject) return;

    rowData[dataKey] = metricProject;
    rowData.metricName = '';
    rowData[rowLoading] = true;
    updateDataTable();

    const projectMetric = projectMetricMap[metricProject];
    if (projectMetric) {
      rowData[rowLoading] = false;
      rowData.metrics = projectMetric.metrics;
      updateDataTable();
      return;
    }

    updateLastActionInfo();
    fetchGet(getEndpoint('metricmetadata'), {
      ...credentials,
      projectName: metricProject,
      customerName: findProject.customerName,
    })
      .then((data) => {
        const { success, message: msg, possibleMetricList } = data || {};
        if (success || success === undefined) {
          const newMetrics = R.sortWith([R.ascend(R.prop('label'))])(
            R.map((m) => ({ value: m, label: m }), R.uniq(possibleMetricList || [])),
          );

          changeProjectMetricMap({ [metricProject]: { metrics: newMetrics } });

          rowData[rowLoading] = false;
          rowData.metrics = newMetrics;
          updateDataTable();
        } else {
          rowData[rowLoading] = false;
          updateDataTable();
          message.error(intl.formatMessage(msg));
        }
      })
      .catch((err) => {
        rowData[rowLoading] = false;
        updateDataTable();
        message.error(intl.formatMessage(err.message || String(err)));
      });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const addValidation = () => {
    setState({
      metricNameMapList: [
        { incidentKeywords: '', metricProject: '', metricName: '', key: uuidv4() },
        ...metricNameMapList,
      ],
    });
  };

  const renderIncidentKeywords = ({ rowData, dataKey }) => {
    return (
      <Input
        size="small"
        allowClear
        value={rowData[dataKey]}
        className={`${rowData[dataKey] ? '' : 'inputIsNil'}`}
        onChange={(e) => {
          rowData[dataKey] = e.target.value;
          updateDataTable();
        }}
      />
    );
  };

  const renderMetricProject = ({ rowData, dataKey }) => {
    return (
      <Select
        showSearch
        size="small"
        style={{ width: '100%' }}
        value={rowData[dataKey]}
        className={`${rowData[dataKey] ? '' : 'jsonKeyNoneError'}`}
        onChange={(value) => reloadMetricData(value, { rowData, dataKey })}
      >
        {R.map(
          (item) => (
            <Select.Option key={item.projectName}>{item.projectDisplayName}</Select.Option>
          ),
          metricProjects || [],
        )}
      </Select>
    );
  };

  const renderMetricName = ({ rowData, dataKey }) => {
    return (
      <Spin spinning={rowData[rowLoading] || false} wrapperClassName="full-width full-height spin-full-height">
        <Select
          size="small"
          showSearch
          style={{ width: '100%' }}
          value={rowData[dataKey]}
          className={`${rowData[dataKey] ? '' : 'jsonKeyNoneError'}`}
          onChange={(value) => {
            rowData[dataKey] = value;
            updateDataTable();
          }}
          options={rowData.metrics || []}
          disabled={!rowData?.metricProject}
        />
      </Spin>
    );
  };

  const deleteRenderer = ({ rowData }) => {
    return (
      <Button
        size="small"
        style={{ backgroundColor: 'var(--gray)', color: 'white' }}
        onClick={() => {
          const newMetricNameMapList = R.filter((item) => item.key !== rowData.key, metricNameMapList || []);
          setState({ metricNameMapList: newMetricNameMapList });
          updateDataTable();
        }}
      >
        {intl.formatMessage(appButtonsMessages.remove)}
      </Button>
    );
  };

  const handleSumbit = () => {
    const systemInfo = systemsMap[systemId];
    if (!systemInfo) return;

    let settingModelsMap = {
      systemName: systemInfo?.systemId,
      userName: systemInfo?.owner,
    };

    const saveOtherList = [...(validationList || []), ...(metricNameMapList || [])];

    const metricNameMap = {};
    R.forEach((item) => {
      const { incidentKeywords, metricProject, metricName } = item || {};
      metricNameMap[incidentKeywords] = { projectName: metricProject, metricName };
    }, saveOtherList || []);

    settingModelsMap = { ...settingModelsMap, metricNameMap };

    setState({ isSubmitting: true });

    updateLastActionInfo();
    fetchPost(getEndpoint('log-incident-validation-setting'), {
      ...credentials,
      settingModels: JSON.stringify([settingModelsMap]),
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          setState({ isSubmitting: false });
          message.success('Saved successfully!  ');
          onClose(true);
        } else {
          setState({ isSubmitting: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        setState({ isSubmitting: false });
        message.error(err.message || String(err));
      });
  };

  const hasError =
    metricNameMapList.length === 0 ||
    !R.reduce(
      R.and,
      true,
      R.addIndex(R.map)((item, idx) => {
        return item.incidentKeywords && item.metricProject && item.metricName;
      }, metricNameMapList),
    );

  return (
    <Modal
      title={intl.formatMessage(appButtonsMessages.add)}
      visible
      width={1100}
      maskClosable={false}
      onCancel={() => onClose()}
      bodyStyle={{ height: 500 }}
      onOk={handleSumbit}
      okText={intl.formatMessage(appButtonsMessages.save)}
      okButtonProps={{ disabled: hasError, loading: isSubmitting }}
    >
      <div className="full-width full-height flex-col">
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <Button type="primary" size="small" onClick={addValidation}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          <div className="flex-grow" />
        </div>
        <Container className="flex-grow flex-col">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={40}
                rowCount={metricNameMapList.length}
                rowGetter={({ index }) => metricNameMapList[index]}
                ref={(c) => {
                  dataTableNode.current = c;
                }}
              >
                <Column
                  width={400}
                  flexGrow={1}
                  label={intl.formatMessage(settingsMessages.incidentKeywords)}
                  dataKey="incidentKeywords"
                  headerRenderer={headerRenderer}
                  cellRenderer={renderIncidentKeywords}
                />
                <Column
                  width={400}
                  flexGrow={1}
                  label={intl.formatMessage(settingsMessages.metricProject)}
                  dataKey="metricProject"
                  headerRenderer={headerRenderer}
                  cellRenderer={renderMetricProject}
                />
                <Column
                  width={400}
                  flexGrow={1}
                  label={intl.formatMessage(settingsMessages.metricName)}
                  dataKey="metricName"
                  headerRenderer={headerRenderer}
                  cellRenderer={renderMetricName}
                />
                <Column
                  width={100}
                  label=""
                  dataKey="delete"
                  headerRenderer={headerRenderer}
                  cellRenderer={deleteRenderer}
                />
              </Table>
            )}
          </AutoSizer>
        </Container>
      </div>
    </Modal>
  );
};

function IncidentValidationSettingCore(props: Object) {
  const { intl, systemsMap, systemId, credentials } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    sortBy: undefined,
    sortDirection: undefined,

    isRemoveCheckedAll: false,
    showAddValidationModal: false,
    projectMetricMap: {},

    validationList: [],
  });
  const { loading, sortBy, sortDirection, validationList, showAddValidationModal, projectMetricMap } = state;

  const metricProjects = useMemo(() => {
    const systemInfo = systemsMap[systemId];
    if (!systemInfo) return [];
    const { projectDetailsList } = systemInfo || {};
    return R.sortWith(
      [R.ascend(R.compose(R.toLower, R.prop('projectDisplayName')))],
      R.map(
        (p) => ({ projectName: p.projectName, projectDisplayName: p.projectDisplayName, customerName: p.customerName }),
        R.filter((project) => project.status !== 'Deleting', projectDetailsList || []),
      ),
    );
  }, [systemsMap, systemId]);

  const sortData = (eventList, sortBy, sortDirection) => {
    let sortList = eventList || [];
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        sortList = R.sortWith([R.descend(R.prop(sortBy))])(sortList);
      } else {
        sortList = R.sortWith([R.ascend(R.prop(sortBy))])(sortList);
      }
    }
    return sortList;
  };

  const reloadData = (cancel) => {
    const systemInfo = systemsMap[systemId];
    if (!systemInfo) return;

    setState({ loading: true });
    updateLastActionInfo();

    fetchGet(getEndpoint('log-incident-validation-setting'), {
      ...credentials,
      systemIdsWithShare: JSON.stringify([{ id: systemInfo?.systemId, customerName: systemInfo?.owner }]),
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          const { metricNameMap } = data[systemId] || {};

          let list = [];
          R.forEachObjIndexed((val, key) => {
            list.push({ incidentKeywords: key, metricProject: val.projectName, metricName: val.metricName });
          }, metricNameMap || {});
          list = sortData(list, 'incidentKeywords', 'ASC');

          setState({ loading: false, validationList: list });
        } else {
          setState({ loading: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        setState({ loading: false });
        message.error(err.message || String(err));
      });
  };

  useEffect(() => {
    let cancel = false;
    reloadData(cancel);
    return () => {
      cancel = true;
      setState({ projectMetricMap: {} });
    };
  }, [systemId]);

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  useEffect(() => {
    setState({ validationList: sortData(validationList, sortBy, sortDirection) });
  }, [sortBy, sortDirection]);

  const handleIsAllChecked = (fieldName) => {
    return ({ target: { checked } }) => {
      R.forEach((item) => {
        if (fieldName === 'isRemoveCheckedAll') {
          item.checked = checked;
        }
      }, validationList || []);
      setState({ [fieldName]: checked });
    };
  };

  const removeCheckAllHeaderRender = (fieldName) => {
    return () => {
      return (
        <>
          {fieldName === 'isRemoveCheckedAll' && (
            <Checkbox checked={state[fieldName]} onChange={handleIsAllChecked(fieldName)} />
          )}
        </>
      );
    };
  };

  const removeCheckBoxRender = ({ rowData, rowIndex }) => {
    const { checked } = rowData;

    return (
      <Checkbox
        checked={checked}
        onChange={(e) => {
          const newValidationList = R.addIndex(R.map)((item, idx) => {
            if (idx === rowIndex) {
              return { ...item, checked: e.target.checked };
            }
            return item;
          }, validationList);
          const isRemoveCheckedAll = !(
            R.find(R.propEq('checked', false), newValidationList) ||
            R.find(R.propEq('checked', undefined), newValidationList)
          );
          setState({ validationList: newValidationList, isRemoveCheckedAll });
        }}
      />
    );
  };

  const metricProjectRender = ({ rowData, dataKey }) => {
    const { projectDetailsList } = systemsMap[systemId] || {};
    const { projectDisplayName } = R.find((p) => p.projectName === rowData[dataKey], projectDetailsList || []) || {};
    return projectDisplayName || rowData[dataKey];
  };

  const handleValidationRemove = () => {
    const systemInfo = systemsMap[systemId];
    if (!systemInfo) return;

    const saveOtherList = R.filter((item) => !item.checked, validationList);

    let settingModelsMap = {
      systemName: systemInfo?.systemId,
      userName: systemInfo?.owner,
    };

    const metricNameMap = {};
    R.forEach((item) => {
      const { incidentKeywords, metricProject, metricName } = item || {};
      metricNameMap[incidentKeywords] = { projectName: metricProject, metricName };
    }, saveOtherList || []);

    settingModelsMap = { ...settingModelsMap, metricNameMap };

    setState({ loading: true });

    updateLastActionInfo();
    fetchPost(getEndpoint('log-incident-validation-setting'), {
      ...credentials,
      settingModels: JSON.stringify([settingModelsMap]),
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          setState({ loading: false, isRemoveCheckedAll: false });
          message.success('Successfully deleted!  ');
          reloadData();
        } else {
          setState({ loading: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        setState({ loading: false });
        message.error(err.message || String(err));
      });
  };

  const handleAddValidation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({ showAddValidationModal: true });
  };

  const deleteValidationList = R.filter((item) => item.checked, validationList);

  return (
    <>
      <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-height">
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <Button type="primary" size="small" onClick={handleAddValidation}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          <div className="flex-grow" />
          <Button
            type="primary"
            size="small"
            disabled={deleteValidationList.length === 0}
            onClick={handleValidationRemove}
          >
            {intl.formatMessage(appButtonsMessages.delete)}
          </Button>
        </div>
        <Container className="flex-grow flex-col">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={40}
                rowCount={validationList.length}
                rowGetter={({ index }) => validationList[index]}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
              >
                <Column
                  width={40}
                  label=""
                  dataKey="checked"
                  disableSort
                  headerRenderer={removeCheckAllHeaderRender('isRemoveCheckedAll')}
                  cellRenderer={removeCheckBoxRender}
                />
                <Column
                  width={400}
                  label={intl.formatMessage(settingsMessages.incidentKeywords)}
                  dataKey="incidentKeywords"
                  headerRenderer={headerRenderer}
                />
                <Column
                  width={280}
                  label={intl.formatMessage(settingsMessages.metricProject)}
                  dataKey="metricProject"
                  flexGrow={1}
                  headerRenderer={headerRenderer}
                  cellRenderer={metricProjectRender}
                />
                <Column
                  width={400}
                  label={intl.formatMessage(settingsMessages.metricName)}
                  dataKey="metricName"
                  headerRenderer={headerRenderer}
                />
              </Table>
            )}
          </AutoSizer>
        </Container>
      </Spin>

      {showAddValidationModal && (
        <AddValidationModal
          {...props}
          validationList={validationList}
          metricProjects={metricProjects}
          projectMetricMap={projectMetricMap}
          changeProjectMetricMap={(projectMetric) => {
            setState({ projectMetricMap: { ...projectMetricMap, ...projectMetric } });
          }}
          onClose={(flag) => {
            setState({ showAddValidationModal: false });
            if (flag) reloadData();
          }}
        />
      )}
    </>
  );
}

const IncidentValidationSetting = injectIntl(IncidentValidationSettingCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { systemsMap } = state.app;
    const { userInfo } = state.auth;

    return {
      credentials,
      systemsMap,
      userInfo,
    };
  },
  { updateLastActionInfo },
)(IncidentValidationSetting);
