import React, { useEffect, useReducer } from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { message, Spin } from 'antd';
import { get, isArray } from 'lodash';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { GlobalParse } from '../../../common/utils';

import { appMessages } from '../../../common/app/messages';

const getRankColor = (rank) => {
  let color = '#797D92';
  switch (rank) {
    case 1:
      color = '#FF5142';
      break;
    case 2:
      color = '#FF8A61';
      break;
    case 3:
      color = '#FEB207';
      break;
    default:
      break;
  }
  return color;
};

export default function RootCausesPopover(props: Object) {
  const { incident, intl, credentials, sourceRootCausesDataMap = {}, handleChangeMap, needRC } = props || {};
  const { isJWT, systemId, jwtToken, zoneName } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    completeChains: '',
  });
  const { loading, completeChains } = state;

  const getPatternNameData = (getPatternNameParams) => {
    const patternNameList = [];
    if (getPatternNameParams.length === 0) {
      return patternNameList;
    }
    return fetchPost(getEndpoint('getpatternname', 1), {
      ...credentials,
      queryString: JSON.stringify(getPatternNameParams),
    })
      .then((data) => {
        const { success, message } = data || {};
        if ((success || success === undefined) && isArray(data)) {
          R.forEach((item) => {
            const { projectName, userName, patternNameByInstance } = item || {};
            R.forEachObjIndexed((patterns, instance) => {
              R.forEach((patternInfo) => {
                const { patternId, patternName } = patternInfo || {};
                patternNameList.push({
                  projectName,
                  userName,
                  instanceName: instance,
                  patternId,
                  patternName,
                });
              }, patterns || []);
            }, patternNameByInstance);
          }, data || []);
          return patternNameList;
        } else {
          console.error(message);
        }
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  };

  const getCausesData = (sourceRootCausesData) => {
    const patternNames = [];
    R.forEach((item) => {
      patternNames.push(item.patternName || item.patternId);
    }, sourceRootCausesData[0]?.sourceInfoList || []);
    const chains = R.join(' -> ', patternNames);
    const completeChains = `${chains} -> ${incident.patternName || incident.patternId}`;
    setState({ completeChains, loading: false });
  };

  const parseDataRC = async (rootCausesData) => {
    const { id } = incident;
    const logRootCauseEvents = get(rootCausesData, ['logRootCauseEvents'], []);
    const metricRootCauseEvents = get(rootCausesData, ['metricRootCauseEvents'], []);
    const { relatedEventList: allRootCauseListLog } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: logRootCauseEvents,
      incident,
    });
    const { relatedEventList: allRootCauseListMetric } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: metricRootCauseEvents,
      incident,
    });
    let sourceRootCausesData = [...allRootCauseListLog, ...allRootCauseListMetric];
    const getRelevance = (r) => {
      if (r === 'high') return 1;
      if (r === 'low') return -1;
      return 0;
    };
    sourceRootCausesData = R.sortWith(
      [(a, b) => getRelevance(b?.relevance) - getRelevance(a?.relevance), R.descend(R.prop('probability'))],
      sourceRootCausesData,
    );
    sourceRootCausesData = R.addIndex(R.map)(
      (item, idx) => ({ ...item, rank: R.toString(idx + 1), rankColor: getRankColor(idx + 1) }),
      sourceRootCausesData,
    );

    let causeChainInfoList = [];
    R.forEach((item) => {
      const { sourceInfoList } = item;
      R.forEach((_item) => {
        const { projectName, owner, projectOwner, anomalyLogInstance, sourceInstanceName } = _item;
        const { sourceDetail, sourceProjectOwner } = _item;
        const { nid, instanceName } = sourceDetail || {};

        const userName = owner || projectOwner || sourceProjectOwner;
        const instanceNameNew = instanceName || anomalyLogInstance || sourceInstanceName;
        causeChainInfoList.push({
          projectName,
          userName,
          instanceName: instanceNameNew,
          patternId: nid,
          key: `${projectName}-${userName}-${instanceNameNew}-${nid}`,
        });
      }, sourceInfoList || []);
    }, sourceRootCausesData);

    // get pattern name
    causeChainInfoList = R.uniqBy((item) => item.key, causeChainInfoList || []);
    const groupSourceInfo = R.groupBy((item) => `${item.projectName}-@-${item.userName}`, causeChainInfoList || []);
    const getPatternNameParams = [];
    R.forEachObjIndexed((sourceInfos, infoKey) => {
      const [p, u] = R.split('-@-', infoKey);
      const groupInstanceName = R.groupBy((item) => item.instanceName, sourceInfos || []);
      const patternNameByInstance = {};
      R.forEachObjIndexed((items, key) => {
        patternNameByInstance[key] = [];
        R.forEach((item) => {
          patternNameByInstance[key].push({ patternId: item.patternId });
        }, items || []);
      }, groupInstanceName || {});
      getPatternNameParams.push({ projectName: p, userName: u, patternNameByInstance });
    }, groupSourceInfo || {});

    let patternNameList = [];
    if (!isJWT) patternNameList = await getPatternNameData(getPatternNameParams);

    R.forEach((item) => {
      const { sourceInfoList } = item;
      R.forEach((_item) => {
        const { projectName, owner, projectOwner, anomalyLogInstance, sourceInstanceName } = _item;
        const { sourceDetail, sourceProjectOwner } = _item;
        const { nid, instanceName, patternName } = sourceDetail || {};

        const userName = owner || projectOwner || sourceProjectOwner;
        const instanceNameNew = instanceName || anomalyLogInstance || sourceInstanceName;
        const key = `${projectName}-${userName}-${instanceNameNew}-${nid}`;
        const findValue = R.find((pt) => {
          const findKey = `${pt.projectName}-${pt.userName}-${pt.instanceName}-${pt.patternId}`;
          return key === findKey;
        }, patternNameList || []);
        if (findValue) {
          _item.patternName = findValue.patternName;
        } else {
          _item.patternName = patternName;
        }
      }, sourceInfoList || []);
    }, sourceRootCausesData);

    handleChangeMap({ ...sourceRootCausesDataMap, [id]: sourceRootCausesData });
    getCausesData(sourceRootCausesData);
  };

  const reloadData = (cancel) => {
    const { projectOwner, startTimestamp, endTimestamp, patternId, type, category, anomalyLogInstance } = incident;
    const { instanceList, rootCauseTableKey, id, rootCauseInfoKey } = incident;
    let { projectName } = incident;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
    const event = {
      nid: patternId,
      eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
    };

    if (sourceRootCausesDataMap[id]) {
      getCausesData(sourceRootCausesDataMap[id]);
      return;
    }

    setState({ loading: true });
    const request = [];
    if (needRC && !isJWT) {
      request.push(
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceList[0],
          startTime,
          endTime,
          operation: 'rootCauseEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
      );
    }

    if (needRC && isJWT) {
      request.push(
        fetchPost(getEndpoint('logCausalInfoJWTServlet', 1), {
          queryString: JSON.stringify(rootCauseInfoKey),
          customerName: credentials?.userName,
          systemName: systemId,
          jwt: jwtToken,
          zoneName,
        }),
      );
    }

    Promise.all(request)
      .then((results) => {
        if (cancel) return;
        if (needRC) {
          parseDataRC(results[0]);
        }
      })
      .catch((err) => {
        if (cancel) return;
        setState({ loading: false });
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  };

  useEffect(() => {
    let cancel = false;
    reloadData(cancel);
    return () => {
      cancel = true;
    };
  }, [incident]);

  return (
    <div className="flex-col flex-center-jusutify" style={{ minWidth: loading ? 400 : 0, maxWidth: 400 }}>
      <div
        className="flex-grow flex-grow flex-wrap"
        style={{ wordWrap: 'anywhere', textAlign: loading ? 'center' : 'left' }}
      >
        {loading ? <Spin size="small" /> : completeChains}
      </div>
    </div>
  );
}
