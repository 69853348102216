/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { RightOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Button, Alert, Input, InputNumber, Select, DatePicker, message, Upload, AutoComplete, Card } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';

import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  credentials: Object,
  componentState: Object,
};

class GoogleCloudCostCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;

    const state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      type: 'service_account',
      projectId: '',
      privateKeyId: '',
      privateKey: '',
      clientEmail: '',
      clientId: '',
      authUri: 'https://accounts.google.com/o/oauth2/auth',
      tokenUri: 'https://oauth2.googleapis.com/token',
      authProviderCertUrl: 'https://www.googleapis.com/oauth2/v1/certs',
      clientCertUrl: '',

      table: '',
      samplingInterval: 1,
      collectionFrequency: 1,
      samplingUnit: 60,
      millisecondUnit: 3600000,
      historicalDates: [null, null],
      projectModelFlag: false,
      processPauseFlag: false,

      configFileList: [],
      isLoadingFile: false,

      optionsTable: [],
    };

    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);

    this.state = {
      ...state,

      ...(componentState || {}),
    };
    this.samplingUnitOption = [{ label: 'hour', value: 60 }];
    this.millisecondUnitOption = [{ label: 'hour', value: 3600000 }];
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isVerifying: true });
    const { intl, credentials } = this.props;
    const {
      type,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      authUri,
      tokenUri,
      authProviderCertUrl,
      clientCertUrl,
    } = this.state;

    const content = {
      [`type`]: type,
      [`project_id`]: projectId,
      [`private_key_id`]: privateKeyId,
      [`private_key`]: (privateKey || '').replaceAll('\\n', '\n'),
      [`client_email`]: clientEmail,
      [`client_id`]: clientId,
      [`auth_uri`]: authUri,
      [`token_uri`]: tokenUri,
      [`auth_provider_x509_cert_url`]: authProviderCertUrl,
      [`client_x509_cert_url`]: clientCertUrl,
    };
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: 'GoogleCloudCost',
      content: btoa(JSON.stringify(content)),
    })
      .then((data) => {
        const { success, message: errMsg, tableList } = data || {};
        if (success === undefined || success) {
          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
              samplingInterval: 1,
              collectionFrequency: 1,
              optionsTable: R.map((item) => ({ value: item, label: item }), tableList || []),
            },
            () => {
              this.props.saveProjectInfo('CloudCost', {}, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('CloudCost', {}, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
          },
          () => {
            this.props.saveProjectInfo('CloudCost', {}, this.state);
          },
        );
      });
  }

  @autobind
  beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('File must smaller than 2MB!');
      return false;
    }

    this.setState({ isLoadingFile: true, configFileList: [file] });
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const fileContent = reader.result;
        let state = { isLoadingFile: false };

        // parse fileContent info
        let ret;
        try {
          ret = JSON.parse(fileContent);
        } catch (e) {
          console.debug(fileContent);
        }
        if (ret) {
          const {
            private_key_id: privateKeyId,
            private_key: privateKey,
            client_id: clientId,
            client_email: clientEmail,
            project_id: projectId,
            client_x509_cert_url: clientCertUrl,
          } = ret;
          state = { ...state, privateKeyId, privateKey, clientId, clientEmail, projectId, clientCertUrl };
        }
        this.setState(state);
      };
    });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      type,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      authUri,
      tokenUri,
      authProviderCertUrl,
      clientCertUrl,
    } = this.state;
    const {
      table,
      samplingInterval,
      collectionFrequency,
      samplingUnit,
      millisecondUnit,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
    } = this.state;

    const content = {
      [`type`]: type,
      [`project_id`]: projectId,
      [`private_key_id`]: privateKeyId,
      [`private_key`]: (privateKey || '').replaceAll('\\n', '\n'),
      [`client_email`]: clientEmail,
      [`client_id`]: clientId,
      [`auth_uri`]: authUri,
      [`token_uri`]: tokenUri,
      [`auth_provider_x509_cert_url`]: authProviderCertUrl,
      [`client_x509_cert_url`]: clientCertUrl,
    };

    this.props.createProject(
      'CloudCost',
      {
        operation: 'register',
        content: btoa(JSON.stringify(content)),

        table,
        samplingInterval: Number(samplingInterval) * samplingUnit,
        collectionFrequency: Number(collectionFrequency) * millisecondUnit,
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
        projectModelFlag,
        processPauseFlag,
      },
      this.state,
    );
  }

  render() {
    const { intl, hasError, isLoading } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,

      type,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      authUri,
      tokenUri,
      authProviderCertUrl,
      clientCertUrl,

      table,
      samplingInterval,
      collectionFrequency,
      samplingUnit,
      millisecondUnit,
      historicalDates,
      configFileList,
      isLoadingFile,
      optionsTable,
    } = this.state;
    const hasVerifyError =
      !type ||
      !projectId ||
      !privateKeyId ||
      !privateKey ||
      !clientEmail ||
      !clientId ||
      !authUri ||
      !tokenUri ||
      !authProviderCertUrl ||
      !clientCertUrl;
    const hasErrorRegister = hasVerifyError || !table || hasError;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.GoogleCloudCost),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item label="JSON Key File">
              <Upload
                onRemove={(file) => this.setState({ configFileList: [] })}
                beforeUpload={this.beforeUpload}
                fileList={configFileList}
              >
                <Button>{isLoadingFile ? <LoadingOutlined /> : <UploadOutlined />} Select File</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="Type"
              validateStatus={!type ? 'error' : 'success'}
              help={!type ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={type} onChange={(e) => this.setState({ type: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Project Id"
              validateStatus={!projectId ? 'error' : 'success'}
              help={!projectId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={projectId} onChange={(e) => this.setState({ projectId: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Private Key Id"
              validateStatus={!privateKeyId ? 'error' : 'success'}
              help={!privateKeyId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={privateKeyId}
                onChange={(e) => this.setState({ privateKeyId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Private Key"
              validateStatus={!privateKey ? 'error' : 'success'}
              help={!privateKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={privateKey}
                onChange={(e) => this.setState({ privateKey: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client Email"
              validateStatus={!clientEmail ? 'error' : 'success'}
              help={!clientEmail ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={clientEmail} onChange={(e) => this.setState({ clientEmail: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Client Id"
              validateStatus={!clientId ? 'error' : 'success'}
              help={!clientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={clientId}
                onChange={(e) => this.setState({ clientId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Auth Uri"
              validateStatus={!authUri ? 'error' : 'success'}
              help={!authUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={authUri} onChange={(e) => this.setState({ authUri: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Token Uri"
              validateStatus={!tokenUri ? 'error' : 'success'}
              help={!tokenUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={tokenUri} onChange={(e) => this.setState({ tokenUri: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Auth Provider Cert Url"
              validateStatus={!authProviderCertUrl ? 'error' : 'success'}
              help={!authProviderCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input
                value={authProviderCertUrl}
                onChange={(e) => this.setState({ authProviderCertUrl: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client Cert Url"
              validateStatus={!clientCertUrl ? 'error' : 'success'}
              help={!clientCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={clientCertUrl} onChange={(e) => this.setState({ clientCertUrl: e.target.value })} />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>
        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            <Form.Item
              label="Table"
              validateStatus={!table ? 'error' : 'success'}
              required
              help={!table ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              {/* <Input value={table} onChange={(e) => this.setState({ table: e.target.value })} /> */}
              <AutoComplete
                options={optionsTable}
                allowClear
                value={table}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf((table || '').toUpperCase()) !== -1
                }
                onChange={(table) => this.setState({ table })}
                placeholder=""
              />
            </Form.Item>

            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.samplingUnitOption}
                  value={samplingUnit}
                  onChange={(samplingUnit) => this.setState({ samplingUnit })}
                />
              </div>
            </Form.Item>

            <Form.Item
              label="Collection Frequency"
              validateStatus={!collectionFrequency ? 'error' : 'success'}
              help={!collectionFrequency ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={collectionFrequency}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ collectionFrequency: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.millisecondUnitOption}
                  value={millisecondUnit}
                  onChange={(millisecondUnit) => this.setState({ millisecondUnit })}
                />
              </div>
            </Form.Item>

            <Form.Item label="Historical Date Range">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={historicalDates}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(historicalDates) => this.setState({ historicalDates })}
              />
            </Form.Item>
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasErrorRegister}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const GoogleCloudCostSetting = injectIntl(GoogleCloudCostCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(GoogleCloudCostSetting);
