import React, { useContext, useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import ReactDOMServer from 'react-dom/server';
import { parseInt } from 'lodash';

import moment from 'moment';
import { Spin } from 'antd';
import { Defaults } from '../../../common/utils';

import { EChart } from '../../share';
import DashCellTitle from '../components/DashCellTitle';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { getCacheData, setCacheData } from '../utils';

const getViewData = (view) => (view || view === 0 ? view : 0);
const floatFixed = (num) => (num ? Number(Number.parseFloat(num).toFixed(3)) : num);
const hexToRgba = (hex, opacity) => {
  if (!hex) return 'red';
  return `rgba(${parseInt(`0x${hex.slice(1, 3)}`)},${parseInt(`0x${hex.slice(3, 5)}`)},${parseInt(
    `0x${hex.slice(5, 7)}`,
  )},${opacity})`;
};

export default function TotalMetricCount({
  TatalSystemCountList,
  type = 'metricCount',
  currentTheme,
  startTime,
  endTime,
  systemId,
  credentials,
  systemInfo,
  projects,
  widgetInfo,
  getPopUpPosition,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    healthOption: {},
    isLoading: false,
  });
  const echartRef = useRef(null);
  const { healthOption, isLoading } = state;

  const getXAxisList = (startTime, endTime) => {
    let timestampList = [];
    R.forEach((ts) => {
      const timestamp = ts * 86400000;
      timestampList.push(moment.utc(timestamp).startOf('days').valueOf());
    }, R.range(startTime / 86400000, endTime / 86400000));
    timestampList = R.uniq(timestampList);
    return { timestampList };
  };

  const buildChartOption = (newData, startTimestamp = 0, endTimestamp = 0) => {
    let colors = [];
    if (currentTheme === 'dark') {
      colors = [
        '#ad4c34',
        '#df9954',
        '#713471',
        '#547ec9',
        '#D37387',
        '#83d4de',
        '#1D7324',
        '#e095e0',
        '#68B78C',
        '#ae9be3',

        '#ee8e76',
        '#7e93b7',
        '#6fc876',
        '#a8d6b7',
        '#d9e446',
        '#ca87ca',
        '#4e6da5',
        '#C35989',
        '#918ADF',
        '#D78742',

        '#B9541A',
        '#E9A133',
        '#3878A1',
        '#A3416E',
        '#F1AEA4',
        '#8BCDBC',
        '#399561',
        '#A4D8A8',
        '#764EA2',
        '#B2ADED',

        '#BC5B22',
        '#EEB46C',
        '#3E769E',
        '#ABC4A2',
        '#3B925D',
        '#B2CB9A',
        '#6565C5',
        '#B4B4F2',
        '#98366D',
        '#DD86AF',

        '#FFC940',
        '#FFEEC5',
        '#96ec9d',
        '#E8F8B6',
        '#D1E1FF',
        '#dab45a',
        '#6B9FA1',
        '#FFE39F',
        '#ddd8cc',
        '#E1BAE1',

        // '#20B2AA'
      ];
    } else {
      colors = [
        '#043e7d',
        '#59C1BD',
        '#768bff',
        '#98ad72',
        '#2192FF',
        '#38E54D',
        '#f4c36d',
        '#acce44',
        '#51718a',
        '#A460ED',

        '#88b1da',
        '#A77979',
        '#1CD6CE',
        '#E8A0BF',
        '#997ec4',
        '#a6c254',
        '#00E0FF',
        '#82A6CB',
        '#9e8c32',
        '#71caaa',

        '#99A799',
        '#4B6587',
        '#89b9d4',
        '#eeb2c6',
        '#ac72cb',
        '#E8AA42',
        '#7D8F69',
        '#0E5E6F',
        '#624F82',
        '#0008C1',

        '#FF8787',
        '#DC5F00',
        '#7978FF',
        '#d6c403',
        '#749F82',
        '#93b3fb',
        '#aad183',
        '#AF0171',
        '#d9b1c7',
        '#E14D2A',

        '#e8ca81',
        '#7cbaa3',
        '#dbb25e',
        '#6ec3ea',
        '#b0a79b',
        '#E80F88',
        '#0096FF',
        '#B270A2',
        '#5A8F7B',
        '#DC3535',

        // '#FBA1A1',
      ];
    }
    const data = newData || [];
    let xAxisData = [];
    const series = [];
    const legendDate = [];
    R.addIndex(R.forEach)((item, index) => {
      const { countList = [], projectName } = item;

      R.forEach((itemsTamp) => {
        xAxisData.push(itemsTamp.dailyTimestamp);
      }, countList);

      const colorName = index <= colors.length - 1 ? colors[index] : '#FBA1A1';
      const itemName = projectName;

      legendDate.push({
        name: itemName,
        itemStyle: {
          color: colorName,
        },
      });

      series.push({
        type: 'bar',
        data: R.map(
          (costItem) => ({
            ...costItem,
            value: getViewData(floatFixed(costItem[type])),
            time: moment.utc(Number(costItem.dailyTimestamp)).format(Defaults.ShortDayFormat),
            flag: costItem.dailyTimestamp < startTimestamp || costItem.dailyTimestamp > endTimestamp,
          }),
          countList,
        ),
        emphasis: {
          itemStyle: {
            color: '#097fd7',
            borderWidth: 0,
          },
        },
        barMaxWidth: 20,
        zlevel: 20,
        stack: 'total',
        name: itemName,
        itemStyle: {
          color: (itemColor) => {
            return hexToRgba(colorName, itemColor?.data?.flag ? 0.7 : 1);
          },
        },
      });
    }, data);

    xAxisData = R.uniq(xAxisData);
    xAxisData = R.sort((a, b) => a - b, xAxisData);
    xAxisData = R.map((item) => moment.utc(Number(item)).format(Defaults.ShortDayFormat), xAxisData);

    const healthOption = {
      backgroundColor: 'transparent',
      legend: {
        show: false,
        type: 'scroll',
        top: 0,
        data: legendDate,
      },
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        enterable: true,
        appendToBody: true,
        position: (pos, params, dom, rect, size) => {
          return getPopUpPosition(widgetInfo, pos, size);
        },
        formatter: (params, ticket, callback) => {
          let newParams = R.filter((item) => item.data, params);
          newParams = R.sortWith([R.descend(R.prop('value'))], newParams);
          if (newParams[0]?.name) {
            return ReactDOMServer.renderToStaticMarkup(
              <div style={{ maxHeight: 230, overflowY: 'auto', padding: 16 }}>
                <div style={{ fontSize: 14, fontWeight: 'bold' }}>{newParams[0]?.name}</div>
                {R.addIndex(R.map)((item, index) => {
                  const { color, data, seriesName } = item;
                  const findProject = R.find((item) => item.projectShortName === seriesName, projects || []);
                  return (
                    <div key={index}>
                      <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color }} />
                      <span style={{ margin: '0 10px 0 5px' }}>{findProject?.projectDisplayName || seriesName}:</span>
                      <span style={{ fontSize: 15, fontWeight: 'bold', color }}>{data.value}</span>
                    </div>
                  );
                }, newParams)}
              </div>,
            );
          }
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      grid:
        data.length > 0 ? { top: 10, left: 50, right: 30, bottom: 20 } : { top: 30, left: 10, right: 10, bottom: 10 },
      xAxis: {
        type: 'category',
        data: xAxisData,
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: {
        type: 'value',
        axisLine: { show: true },
        axisLabel: {
          fontSize: 10,
          width: '42',
          overflow: 'break',
          formatter: (value, index) => {
            let scoreVla = value;
            if (scoreVla >= 1000000) {
              scoreVla = `${Number(Number.parseFloat(scoreVla / 1000000).toFixed(2))}M`;
            }
            if (scoreVla >= 1000) {
              scoreVla = `${Number(Number.parseFloat(scoreVla / 1000).toFixed(2))}K`;
            }
            return scoreVla;
          },
        },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series,
    };
    setState({ healthOption });
  };

  const parseData = (data, newStartTimestamp, newEndTimestamp, startTimestamp, endTimestamp) => {
    let list = data || [];
    const { timestampList } = getXAxisList(newStartTimestamp, newEndTimestamp);

    R.forEach((item) => {
      const { countList } = item;
      let total = 0;
      const itemList = [];
      R.forEach((_item) => {
        const { metricCount, instanceCount } = _item;
        total += type === 'metricCount' ? metricCount : instanceCount;
      }, countList);
      item.total = total;

      R.forEach((itemStamp) => {
        const findCountItem = R.find((item) => item.dailyTimestamp === itemStamp, countList || []);
        if (findCountItem) {
          itemList.push(findCountItem);
        } else {
          itemList.push({ dailyTimestamp: itemStamp, metricCount: 0, instanceCount: 0 });
        }
      }, timestampList);
      item.countList = itemList;
    }, list);
    list = R.filter((item) => item.total > 0, list);
    list = R.sortWith([R.descend(R.prop('total'))], list);

    buildChartOption(list, startTimestamp, endTimestamp);
  };

  const reloadData = () => {
    if (!systemInfo) {
      console.log('system info is undefined');
      return;
    }
    const newTimesTamp = moment.utc(moment.utc(), Defaults.DateFormat).endOf('days').valueOf();
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    const newStartTimestamp = moment.utc(startTimestamp).subtract(7, 'days').valueOf();
    let newEndTimestamp = moment.utc(endTimestamp).add(7, 'days').valueOf();
    if (newTimesTamp < newEndTimestamp) {
      newEndTimestamp = newTimesTamp;
    }
    const params = {
      ...credentials,
      customerName: systemInfo.customerName,
      startTime: newStartTimestamp,
      endTime: newEndTimestamp,
      systemName: systemId,
      cacheKey: 'metricCountMetricoverviewstatus',
    };
    const apiData = getCacheData(params);
    if (apiData) {
      parseData(apiData, newStartTimestamp, newEndTimestamp, startTimestamp, endTimestamp);
      return;
    }

    setState({ isLoading: true });
    fetchGet(getEndpoint('metricoverviewstatus'), R.clone(params))
      .then((data) => {
        setCacheData(params, data);
        parseData(data, newStartTimestamp, newEndTimestamp, startTimestamp, endTimestamp);
        setState({ isLoading: false });
      })
      .catch((err) => {
        setState({ isLoading: false });
        console.error(err);
      });
  };

  useEffect(() => {
    reloadData();
  }, [systemId]);

  useEffect(() => {
    if (echartRef.current) {
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.setOption(healthOption);
    }
  }, [currentTheme]);

  const hasHealthOption = ((healthOption?.series || [])[0]?.data || []).length < 1;
  return (
    <Spin
      spinning={isLoading}
      wrapperClassName="dash-grid-cell content-bg corner-10 spin-base overflow-x-hidden overflow-y-auto"
    >
      <DashCellTitle title={`Total ${type === 'metricCount' ? 'metric' : 'instance'} count`} subTitle="System" />
      <div style={{ position: 'relative', height: 'calc(100% - 48px)' }}>
        <EChart
          setRef={(chart) => {
            echartRef.current = chart;
          }}
          width="100%"
          height="100%"
          option={healthOption}
          onEvents={{}}
          theme={currentTheme}
        />
        {hasHealthOption && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-55%, -30%)',
              color: 'var(--text-color-secondary)',
              width: 160,
            }}
          >
            No data for this time period
          </div>
        )}
      </div>
    </Spin>
  );
}
