import React, { useEffect, useState } from 'react';
import { DatePicker, message } from 'antd';
import moment from 'moment';

import { Modal } from '../../../../src/lib/fui/react';

import { eventMessages } from '../../../../src/common/metric/messages';
import { appFieldsMessages } from '../../../../src/common/app/messages';
import { Defaults } from '../../../../src/common/app';
import fetchPost from '../../../../src/common/apis/fetchPost';
import getEndpoint from '../../../../src/common/apis/getEndpoint';
import { buildUrl } from '../../../../src/common/utils';
import BaseUrls from '../../../../src/web/app/BaseUrls';

export default function StatusRerunModel({ intl, userInfo, credentials, activeEvent, onClose }: Object) {
  const [title, setTitle] = useState('');
  const [dates, setDates] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    let cancel = false;
    if (cancel) return;
    const { keyType, startTime, endTime } = activeEvent || {};
    let title = intl.formatMessage(eventMessages.rerunTraining);
    if (keyType === 'detection') {
      title = intl.formatMessage(eventMessages.rerunDetection);
    } else if (keyType === 'baseline') {
      title = intl.formatMessage(eventMessages.rerunBaseline);
    } else if (keyType === 'lineChart') {
      title = intl.formatMessage(eventMessages.jumpToLinechart);
    }
    const dates = [moment.utc(startTime), moment.utc(endTime)];
    setTitle(title);
    setDates(dates);
    return () => {
      cancel = true;
    };
  }, [activeEvent]);

  const handleRerun = () => {
    const {
      projectName,
      userName,
      timestamp,
      keyType,
      startTime: localsStartTime,
      endTime: localEndTime,
    } = activeEvent;
    let startTime = moment.utc(dates[0], Defaults.DateFormat).startOf('day').valueOf();
    let endTime = moment.utc(dates[1], Defaults.DateFormat).endOf('day').valueOf();
    if (localsStartTime > startTime) startTime = localsStartTime;
    if (localEndTime < endTime) endTime = localEndTime;

    setBtnLoading(true);
    fetchPost(getEndpoint('metricanalysismanage'), {
      ...credentials,
      projectName,
      customerName: userName,
      timestamp,
      rerunTaskList: JSON.stringify([{ s: startTime, e: endTime, type: keyType }]),
    })
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          onClose(true);
          message.success(msg);
        } else {
          console.error(msg);
        }
        setBtnLoading(false);
      })
      .catch((err) => {
        console.error(String(err));
        setBtnLoading(false);
      });
  };

  const handleJumpLineChart = () => {
    const { userName } = activeEvent;
    let { projectName } = activeEvent;
    projectName =
      userInfo.isAdmin || userInfo.isLocalAdmin || userName !== userInfo.userName
        ? `${projectName}@${userName}`
        : projectName;
    const startTime = moment.utc(dates[0], Defaults.DateFormat).startOf('day').valueOf();
    const endTime = moment.utc(dates[1], Defaults.DateFormat).endOf('day').valueOf();

    const params = {
      projectName,
      modelType: 'Holistic',
      startTimestamp: startTime,
      endTimestamp: endTime,
      withBaseline: true,
      customerName: userName,
    };
    onClose();
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, params), '_blank');
  };

  const isLineChart = activeEvent?.keyType === 'lineChart';
  const okText = isLineChart ? intl.formatMessage(appFieldsMessages.jump) : intl.formatMessage(eventMessages.rerun);
  return (
    <Modal
      visible
      title={title}
      onCancel={() => onClose()}
      onOk={isLineChart ? handleJumpLineChart : handleRerun}
      okText={okText}
      okButtonProps={{ loading: btnLoading }}
    >
      <div>
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <div style={{ width: 40 }}>{intl.formatMessage(appFieldsMessages.date)}:</div>
          <DatePicker.RangePicker
            allowClear={false}
            value={dates}
            disabledDate={(current) => {
              const afterTime = moment.utc(activeEvent?.endTime).endOf('day');
              const preTime = moment.utc(activeEvent?.startTime).startOf('day');
              return (current && preTime > current) || (current && current > afterTime);
            }}
            onChange={(datesObj) => setDates(datesObj)}
          />
        </div>
      </div>
    </Modal>
  );
}
