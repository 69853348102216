import React from 'react';
import { Alert, Button, DatePicker, Layout, Select, Tooltip } from 'antd';
import { CodeSandboxOutlined, DatabaseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { isArray, isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import * as R from 'ramda';
import { appButtonsMessages, appFieldsMessages } from '../../common/app/messages';
import { State } from '../../common/types';
import { Defaults, parseJSON, parseLocation } from '../../common/utils';
import { ServiceMapView } from './ServiceMapView';
import { Container } from '../../lib/fui/react';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';

type Props = {
  intl: Object,
  location: Object,
  isDark: boolean,
};

class EmbedServiceMapCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const query = parseLocation(props.location);
    const { systemId, tenant } = query;
    const zoneName = !tenant || tenant === `all_zone_${systemId}` ? '__all__' : tenant;

    this.state = {
      isLoading: false,
      startTimeObj: null,
      endTimeObj: null,
      isInvalidParams: false,

      selectedTime: null,
      k8sSelectedTime: null,
      serviceMapNote: {},
      serviceMapData: {},
      nodeRootCauseMap: {},
      isK8s: false,
      selectedZone: zoneName,
      zoneSet: [],
      zoneOptions: [],
    };
  }

  componentDidMount() {
    this.reloadAll(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.systemId !== query.systemId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.zoneName !== query.zoneName
    ) {
      this.reloadAll(nextProps);
    }
  }

  @autobind
  getUserCurrentTime(props, nonReplace = true) {
    const timezoneOffset = 0;
    const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
    const time = moment.utc(nowTimestamp).format(Defaults.DateFormat);
    return time;
  }

  @autobind
  handleRefreshClick() {
    this.reloadData(this.props);
  }

  @autobind
  reloadAll(props) {
    const { location } = props;
    const query = parseLocation(location);
    const { systemId, customerName, tenant, jwtToken } = query;
    if (!systemId || !customerName || !jwtToken) {
      this.setState({ isInvalidParams: true });
      return;
    }
    const zoneName = tenant || `all_zone_${systemId}`;

    const { startTime, endTime } = query;
    let startTimestamp = moment
      .utc(startTime || moment.utc(), Defaults.DateFormat)
      .startOf('day')
      .valueOf();
    let endTimestamp = moment
      .utc(endTime || moment.utc(), Defaults.DateFormat)
      .endOf('day')
      .valueOf();

    this.setState({ isLoading: true }, () => {
      fetchGet(getEndpoint('jwt-system-basic-info', 1), {
        customerName,
        jwt: jwtToken,
        systemName: systemId,
        startTime: startTimestamp,
        system: systemId,
        endTime: endTimestamp,
        zoneName,
        systemKeyList: JSON.stringify([{ id: systemId, customerName }]),
      })
        .then((data) => {
          const basicInfo = parseJSON(R.values(data || {})?.[0]?.[0]?.basicInfo) || {};
          let zoneSet = [];
          const zoneSetJson = parseJSON(basicInfo?.zoneSet);
          if (isArray(zoneSetJson)) {
            zoneSet = zoneSetJson;
          } else if (isObject(zoneSetJson)) {
            zoneSet = R.keys(zoneSetJson);
          }
          if (!startTime) {
            const timezone = data?.timezone || 'UTC';
            const zone = momenttz.tz(timezone);
            const timezoneOffset = zone.utcOffset();
            const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
            const time = moment.utc(nowTimestamp).format(Defaults.DateFormat);

            startTimestamp = moment.utc(time, Defaults.DateFormat).startOf('day').valueOf();
            endTimestamp = moment.utc(time, Defaults.DateFormat).endOf('day').valueOf();
          }

          const systemInfo = { id: systemId };
          const userInfo = { userName: customerName };
          const startTimeObj = moment.utc(startTimestamp);
          const endTimeObj = moment.utc(endTimestamp);

          this.setState({ startTimeObj, endTimeObj, systemInfo, userInfo, zoneSet }, () => {
            this.reloadData(props);
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false, isInvalidParams: false });
        });
    });
  }

  @autobind
  reloadData(props) {
    const { location } = props;
    const { startTimeObj, endTimeObj, systemInfo, userInfo, zoneSet, selectedZone, zoneOptions } = this.state;
    const query = parseLocation(location);
    const { systemId, customerName, jwtToken, tenant } = query;

    const zoneName = tenant || `all_zone_${systemId}`;
    const zoneList = selectedZone
      ? selectedZone === '__all__'
        ? JSON.stringify([])
        : JSON.stringify([selectedZone])
      : undefined;

    this.setState({ isLoading: true }, () => {
      fetchGet(getEndpoint('allservicemapdata', 1), {
        systemName: systemId,
        customerName,
        jwt: jwtToken,
        zoneName,
        zoneList,
        startTime: startTimeObj.valueOf(),
      })
        .then((data) => {
          const { incidentCountByZone = {}, incidentCountByZoneForPod = {} } = data;
          let newZoneOptions = zoneOptions;
          if (selectedZone === '__all__') {
            newZoneOptions = this.getZoneOptions(zoneSet, systemId, incidentCountByZone, incidentCountByZoneForPod);
          }

          let isK8s = false;
          R.forEach((i) => {
            if (i.type === 3) {
              R.forEach((j) => {
                if (j.type === 2) {
                  if (j.isK8s && !isK8s) {
                    isK8s = true;
                  }
                }
              }, i.children || []);
            } else if (i.type === 2) {
              if (i.isK8s && !isK8s) {
                isK8s = true;
              }
            }
          }, data?.Contains || []);

          this.setState({
            isK8s,
            startTimeObj,
            endTimeObj,
            systemInfo,
            userInfo,
            zoneOptions: newZoneOptions,
            serviceMapNote: data?.noteMap || {},
            serviceMapData: data || {},
            nodeRootCauseMap: {},
            isLoading: false,
            isInvalidParams: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            isInvalidParams: false,
            serviceMapNote: {},
            serviceMapData: {},
          });
        });
    });
  }

  @autobind
  handleTimeChange(timeObj) {
    const { location } = this.props;
    const query = parseLocation(location);
    const { systemId, tenant } = query;
    const { selectedZone } = this.state;
    const startTimeObj = moment.utc(timeObj.valueOf());
    let newSelectedZone = selectedZone;

    if (!tenant || tenant === `all_zone_${systemId}`) {
      newSelectedZone = '__all__';
    }

    this.setState(
      {
        startTimeObj,
        endTimeObj: startTimeObj.clone().endOf('day'),
        selectedTime: null,
        selectedZone: newSelectedZone,
      },
      () => {
        this.handleRefreshClick();
      },
    );
  }

  @autobind
  onChangeFilterZone(zone) {
    this.setState(
      {
        selectedZone: zone,
      },
      () => {
        this.handleRefreshClick();
      },
    );
  }

  @autobind
  onActiveNodeChange(activeId, selectedTime) {
    if (!activeId) {
      return;
    }

    const { location } = this.props;
    const { startTimeObj, nodeRootCauseMap, selectedZone } = this.state;

    const query = parseLocation(location);
    const { systemId, customerName, jwtToken, tenant } = query;

    const zoneName = tenant || `all_zone_${systemId}`;
    const zoneList = selectedZone
      ? selectedZone === '__all__'
        ? JSON.stringify([])
        : JSON.stringify([selectedZone])
      : undefined;

    const parts = activeId.split('_') || [];
    const nodeName = parts[parts.length - 1] || activeId;
    const nodeRootCause = nodeRootCauseMap[nodeName];
    const findActiveRootCauseNodes = (node) => {
      const activeRootCauseNodes = {};
      if (node) {
        const { rootCauseList } = node;
        if (rootCauseList) {
          R.forEach((item) => {
            const { nodeName, metricName } = item;
            if (!activeRootCauseNodes[nodeName]) {
              activeRootCauseNodes[nodeName] = [];
            }
            if (metricName) {
              activeRootCauseNodes[nodeName].push(metricName);
            }
          }, rootCauseList);
        }
      }
      R.forEachObjIndexed((value, key) => {
        activeRootCauseNodes[key] = R.uniq(value);
      }, activeRootCauseNodes);
      return activeRootCauseNodes;
    };

    if (!nodeRootCause) {
      this.setState({ isLoading: true });
      fetchGet(getEndpoint('serviceextraJWT'), {
        systemName: systemId,
        customerName,
        jwt: jwtToken,
        zoneName,
        zoneList,
        dailyTimestamp: startTimeObj.valueOf(),
        nodeName,
      })
        .then((d) => {
          const { result } = d || {};
          if (result) {
            const jsonResult = JSON.parse(result);
            const rcMap = {};
            const serviceMapNodeRootCauseList = jsonResult?.serviceMapNodeRootCauseList || [];
            R.forEach((item) => {
              const { nodeName: newNodeName, metricName } = item?.root || {};
              if (nodeName === newNodeName) {
                const rootCauseMap = findActiveRootCauseNodes(item);
                if (!rcMap[nodeName]) {
                  rcMap[nodeName] = { metricNames: [], rootCauseMap: {} };
                }
                rcMap[nodeName].metricNames[metricName] = metricName;
                rcMap[nodeName].rootCauseMap = R.mergeDeepWith(
                  R.concat,
                  rcMap[nodeName].rootCauseMap || {},
                  rootCauseMap,
                );
              }
            }, serviceMapNodeRootCauseList);
            this.setState({ nodeRootCauseMap: { ...(nodeRootCauseMap || {}), ...rcMap }, isLoading: false });
          } else {
            this.setState({
              nodeRootCauseMap: { ...(nodeRootCauseMap || {}), [nodeName]: {} },
              isLoading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.error(e);
        });
    }
  }

  @autobind
  getZoneOptions(zoneSet, systemId, incidentCountByZone = {}, incidentCountByZoneForPod = {}) {
    const total = R.reduce((acc, item) => acc + item, 0, R.values(incidentCountByZone || {}));
    const podTotal = R.reduce((acc, item) => acc + item, 0, R.values(incidentCountByZoneForPod || {}));
    let zoneOptions = R.filter((x) => x !== `zone_${systemId}`, zoneSet).map((item) => ({
      value: item,
      label: item,
      count: incidentCountByZone[item] || 0,
      podCount: incidentCountByZoneForPod[item] || 0,
    }));
    zoneOptions = [
      { value: '__all__', label: 'All zones', count: total, podCount: podTotal },
      {
        value: `zone_${systemId}`,
        label: 'No zones',
        count: incidentCountByZone[`zone_${systemId}`] || 0,
        podCount: incidentCountByZoneForPod[`zone_${systemId}`] || 0,
      },
      ...zoneOptions,
    ];
    return zoneOptions;
  }

  render() {
    const { intl, isDark, location } = this.props;
    const { isInvalidParams } = this.state;
    const { isLoading, startTimeObj, endTimeObj } = this.state;
    const { systemInfo, userInfo, zoneOptions, selectedZone } = this.state;
    const { selectedTime, k8sSelectedTime, isK8s } = this.state;
    const { serviceMapNote, serviceMapData, nodeRootCauseMap } = this.state;
    const query = parseLocation(location);
    const { jwtToken, systemId, tenant } = query;
    const showZoneOptions = !tenant || tenant === `all_zone_${systemId}`;

    return (
      <Layout className="app-centric-page" style={{ minHeight: '100vh' }}>
        <Container fullHeight withGutter className="flex-col corner-10" style={{ borderRadius: 10 }}>
          <div className="flex-row flex-space-between" style={{ margin: '8px 16px 16px 16px', alignItems: 'end' }}>
            <div className="flex-grow" />
            {showZoneOptions && (
              <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                <span style={{ fontWeight: 700, padding: '0 1em' }}>Zone</span>
                <Select
                  showArrow={false}
                  showSearch
                  size="small"
                  style={{ width: 360, marginLeft: 8 }}
                  optionFilterProp="children"
                  value={selectedZone}
                  onChange={this.onChangeFilterZone}
                  filterOption={(input, option) => {
                    return option && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  dropdownMatchSelectWidth={false}
                >
                  {zoneOptions.map((item) => (
                    <Select.Option key={item.value} title={item.label}>
                      <div className="flex-row">
                        <span style={{ width: 180 }}>{item.label}</span>
                        <span style={{ width: 86 }}>Incident count:</span>
                        <span className="flex-grow" style={{ textAlign: 'right' }}>
                          <Tooltip title="Instance" mouseEnterDelay={0.3} placement="top">
                            <DatabaseOutlined style={{ paddingRight: 8 }} />
                            <span>{item.count}</span>
                          </Tooltip>
                          {isK8s && (
                            <Tooltip title="Pod" mouseEnterDelay={0.3} placement="top" style={{ paddingRight: 16 }}>
                              <CodeSandboxOutlined style={{ paddingRight: 8, paddingLeft: 8 }} />
                              <span>{item.podCount}</span>
                            </Tooltip>
                          )}
                        </span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            <div className="flex-row flex-end-justify flex-center-align" style={{ fontSize: 12 }}>
              {startTimeObj && endTimeObj && (
                <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                  <span style={{ fontWeight: 700, padding: '0 1em' }}>
                    {intl.formatMessage(appFieldsMessages.date)}
                  </span>
                  <Button
                    icon={<LeftOutlined />}
                    size="small"
                    onClick={() => {
                      this.handleTimeChange(startTimeObj.clone().subtract(1, 'days'));
                    }}
                  />
                  <DatePicker
                    size="small"
                    allowClear={false}
                    showToday
                    value={startTimeObj}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                    onChange={this.handleTimeChange}
                  />
                  <Button
                    icon={<RightOutlined />}
                    size="small"
                    disabled={endTimeObj.endOf('day') >= moment.utc().add(1, 'days').endOf('day')}
                    onClick={() => {
                      this.handleTimeChange(startTimeObj.clone().add(1, 'days'));
                    }}
                  />
                </div>
              )}
              <Button size="small" disabled={isLoading} onClick={this.handleRefreshClick}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </div>
          </div>
          <Container
            fullHeight
            className="flex-grow flex-col flex-min-height content-bg corner-10"
            style={{ padding: 8, margin: '0px 16px 8px' }}
          >
            {isInvalidParams && (
              <Alert message="Warning" description="Missing or invalid parameters" type="warning" showIcon />
            )}
            {!isInvalidParams && startTimeObj && endTimeObj && (
              <ServiceMapView
                intl={intl}
                isDark={isDark}
                isLoading={isLoading}
                isJwtMode
                jwtToken={jwtToken}
                startTimeObj={startTimeObj}
                endTimeObj={endTimeObj}
                userInfo={userInfo}
                systemInfo={systemInfo}
                serviceMapNote={serviceMapNote}
                serviceMapData={serviceMapData}
                selectedTime={selectedTime}
                onChangeTime={(time) => this.setState({ selectedTime: time })}
                k8sSelectedTime={k8sSelectedTime}
                onChangeK8sTime={(time) => this.setState({ k8sSelectedTime: time })}
                selectedZone={tenant || '__all__'}
                nodeRootCauseMap={nodeRootCauseMap}
                onActiveNodeChange={this.onActiveNodeChange}
              />
            )}
          </Container>
        </Container>
      </Layout>
    );
  }
}

const EmbedServiceMap = injectIntl(EmbedServiceMapCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { dark } = parseLocation(location);
  if (dark) {
    state.app.currentTheme = 'dark';
  } else {
    state.app.currentTheme = 'light';
  }

  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return { location, isDark };
}, {})(EmbedServiceMap);
