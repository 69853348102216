import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Input, message, Spin } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import fetchDelete from '../../../../common/apis/fetchDelete';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Modal, Popover, Table } from '../../../../lib/fui/react';
import { updateLastActionInfo } from '../../../../common/app/actions';

import { appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

function ZoneSecreteJWTCore(props: Object) {
  const { intl, systemId, systemsMap, zonesecretModal, changeZonesecretModal } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isLoading: false,
    zonesecretList: [],
    activeEvent: null,
    sortBy: null,
    sortDirection: null,
  });
  const dataTable = useRef(null);
  const { isLoading, zonesecretList, activeEvent, sortBy, sortDirection } = state;
  const systemInfo = systemsMap[systemId] || {};

  const onToggleCollapse = useCallback(() => {
    cellMeasureCache.clearAll();
    if (dataTable.current) {
      dataTable.current.forceUpdate();
    }
  }, []);

  const sortData = (eventList, sortBy, sortDirection) => {
    let sortList = eventList || [];

    let sortFunctions = [R.ascend(R.prop('zoneName'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);
    return sortList;
  };

  const reloadData = (cancel) => {
    setState({ isLoading: true });
    fetchGet(getEndpoint('zonesecret'), {
      systemName: systemId,
      customerName: systemInfo.owner,
    })
      .then((res) => {
        if (cancel) return;
        const { success, message: msg } = res || {};
        if (success || success === undefined) {
          setState({ isLoading: false, zonesecretList: sortData(res, sortBy, sortDirection) });
        } else {
          message.error(msg);
          setState({ isLoading: false });
        }
      })
      .catch((err) => {
        if (cancel) return;
        setState({ isLoading: false });
        message.error(err.message || String(err));
      });
  };

  useEffect(() => {
    let cancel = false;
    setState({ sortBy: null, sortDirection: null });
    reloadData(cancel);
    return () => {
      cancel = true;
    };
  }, [systemId]);

  const deleteZoneSecrete = (rowData) => {
    setState({ isLoading: true });
    fetchDelete(getEndpoint('zonesecret'), {
      systemName: systemId,
      customerName: systemInfo?.owner,
      zoneName: rowData.zoneName,
    })
      .then((res) => {
        const { success, message: msg } = res || {};
        if (success || success === undefined) {
          message.success('Successfully deleted!');
          reloadData(false);
        } else {
          message.error(msg);
          setState({ isLoading: false });
        }
      })
      .catch((err) => {
        setState({ isLoading: false });
        message.error(err.message || String(err));
      });
  };

  useEffect(() => {
    if (sortBy) {
      setState({ zonesecretList: sortData(zonesecretList, sortBy, sortDirection) });
      onToggleCollapse();
    }
  }, [sortBy, sortDirection]);

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const handleCellRender = ({ dataKey, parent, rowIndex, rowData, columnIndex, cellData }) => {
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Popover
          content={
            <div style={{ maxWidth: 400, maxHeight: 200, overflowY: 'auto', wordBreak: 'break-word' }}>{cellData}</div>
          }
          placement="topLeft"
          mouseEnterDelay={0.3}
        >
          <div className="hidden-line-with-ellipsis">{cellData}</div>
        </Popover>
      </CellMeasurer>
    );
  };

  const handleDeleteCellRender = ({ rowData }) => {
    return (
      <div>
        <Button
          size="small"
          onClick={() => {
            changeZonesecretModal(true);
            setState({ activeEvent: rowData });
          }}
        >
          {intl.formatMessage(appButtonsMessages.edit)}
        </Button>
        <Button size="small" onClick={() => deleteZoneSecrete(rowData)} style={{ marginLeft: 8 }}>
          {intl.formatMessage(appButtonsMessages.delete)}
        </Button>
      </div>
    );
  };

  return (
    <div className="flex-col full-height">
      <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height spin-overflow-y">
        <div className="flex-grow">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={cellMeasureCache.rowHeight}
                rowCount={zonesecretList.length}
                rowGetter={({ index }) => zonesecretList[index]}
                ref={(c) => {
                  dataTable.current = c;
                }}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
              >
                <Column
                  width={130}
                  flexGrow={1}
                  label={intl.formatMessage(settingsMessages.zoneName)}
                  dataKey="zoneName"
                  headerRenderer={headerRenderer}
                  cellRenderer={handleCellRender}
                />
                <Column
                  width={130}
                  flexGrow={1}
                  label={intl.formatMessage(settingsMessages.zoneKey)}
                  dataKey="zoneKey"
                  headerRenderer={headerRenderer}
                  cellRenderer={handleCellRender}
                />
                <Column width={120} label={null} dataKey="delete" cellRenderer={handleDeleteCellRender} disableSort />
              </Table>
            )}
          </AutoSizer>
        </div>
      </Spin>

      {zonesecretModal && (
        <ZonesecretModalRender
          intl={intl}
          activeEvent={activeEvent}
          systemId={systemId}
          systemInfo={systemInfo}
          onClose={(flag) => {
            changeZonesecretModal(false);
            setState({ activeEvent: null });
            if (flag) reloadData(false);
          }}
        />
      )}
    </div>
  );
}

const ZonesecretModalRender = (props: Object) => {
  const { intl, onClose, activeEvent, systemId, systemInfo } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isBtnLoading: false,
    zoneName: undefined,
    zoneKey: undefined,
  });
  const { isBtnLoading, zoneName, zoneKey } = state;

  useEffect(() => {
    if (activeEvent) {
      setState({ zoneName: activeEvent.zoneName, zoneKey: activeEvent.zoneKey });
    }
  }, [activeEvent]);

  const handleSubmit = () => {
    setState({ isBtnLoading: true });
    fetchPost(getEndpoint('zonesecret'), {
      systemName: systemId,
      customerName: systemInfo?.owner,
      zoneName,
      zoneSecret: zoneKey,
    })
      .then((res) => {
        const { success, message: msg } = res || {};
        if (success || success === undefined) {
          setState({ isBtnLoading: false });
          message.success(activeEvent ? 'Successfully modified!' : 'Added successfully!');
          onClose(true);
        } else {
          message.error(msg);
          setState({ isBtnLoading: false });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        setState({ isBtnLoading: false });
      });
  };

  let modalTitle = intl.formatMessage(settingsMessages.addZoneSecrete);
  if (activeEvent) {
    modalTitle = intl.formatMessage(settingsMessages.editZoneSecrete);
  }

  const isDisabled = !zoneName || !zoneKey;

  return (
    <Modal
      visible
      width={700}
      onCancel={() => onClose()}
      title={modalTitle}
      okText={intl.formatMessage(appButtonsMessages.submit)}
      onOk={handleSubmit}
      okButtonProps={{ loading: isBtnLoading, disabled: isDisabled }}
    >
      <div>
        <div className="flex-row flex-center-align">
          <div style={{ width: 100 }}>
            {intl.formatMessage(settingsMessages.zoneName)}
            <span style={{ color: 'red', margin: '0px 4px' }}>*</span>:
          </div>
          <Input
            size="small"
            allowClear
            value={zoneName}
            onChange={(e) => setState({ zoneName: e.target.value })}
            className={`${zoneName ? '' : 'inputIsNil'}`}
            disabled={activeEvent}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
          <div style={{ width: 100 }}>
            {intl.formatMessage(settingsMessages.zoneKey)}
            <span style={{ color: 'red', margin: '0px 4px' }}>*</span>:
          </div>
          <Input
            size="small"
            allowClear
            value={zoneKey}
            onChange={(e) => setState({ zoneKey: e.target.value })}
            className={`${zoneKey ? '' : 'inputIsNil'}`}
          />
        </div>
      </div>
    </Modal>
  );
};

const ZoneSecreteJWT = injectIntl(ZoneSecreteJWTCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, systemsMap, projects, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isLocalAdmin } = state.auth.userInfo;
    const isDark = currentTheme === 'dark';
    return {
      location,
      loadStatus,
      systemsMap,
      credentials,
      userInfo,
      projects,
      isDark,
      isAdmin,
      isLocalAdmin,
    };
  },
  { updateLastActionInfo },
)(ZoneSecreteJWT);
