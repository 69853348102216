/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import React, { Element } from 'react';
import cx from 'classnames';

type Props = {
  style: Object,
  isAncestor: boolean,
  isParent: boolean,
  isVertical: boolean,
  isFluid: boolean,
  size: ?number,
  children: Element<any>,
  className: string,
};

const Tile = ({
  className,
  style,
  size,
  isFluid = false,
  isAncestor = false,
  isParent = false,
  isVertical = false,
  children,
  ...rest
}: Props) => {
  const classes = cx(
    'fui',
    {
      'is-fluid': isFluid,
      'is-ancestor': isAncestor,
      'is-parent': isParent,
      'is-vertical': isVertical,
    },
    size ? `is-${size}` : '',
    'tile',
    className,
  );

  return (
    <div className={classes} style={{ ...style }} {...rest}>
      {children}
    </div>
  );
};

export default Tile;
