/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import moment from 'moment';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';
import { Defaults } from '../app';

const getLogAnomalyTimelines = (credentials: Credentials, params: Object) => {
  const { startTime } = params;
  const startTimestamp = moment
    .utc(startTime, Defaults.DateFormat)
    .startOf('day')
    .valueOf();

  return fetchPost(getEndpoint('logTimelines', 2), {
    ...credentials,
    startTime: startTimestamp,
  })
    .then(d => {
      return { timelines: d };
    })
    .catch(e => {
      console.error('[IF_API]', e);
      return { timelines: {} };
    });
};

export default getLogAnomalyTimelines;
