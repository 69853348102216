import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';

type Props = {
  ownerObject: Object,
  content: String,
  style: Object,
  className: String,
  onChanged: Function,
};

class CollapsibeText extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.expandedStateKey = '__expanded_state__';
    let showAll = false;
    if (props.ownerObject) {
      showAll = Boolean(props.ownerObject[this.expandedStateKey]);
    }
    this.state = { showAll };
  }

  @autobind
  handleEllipsisClicked() {
    this.setState({ showAll: !this.state.showAll }, () => {
      const { ownerObject, onChanged } = this.props;
      const { showAll } = this.state;
      if (ownerObject) {
        ownerObject[this.expandedStateKey] = showAll;
      }
      if (onChanged) {
        onChanged(showAll);
      }
    });
  }

  render() {
    const { style, content } = this.props;
    const { showAll } = this.state;
    const lines = (content || '').split('\n');
    if (lines.length > 1) {
      return (
        <div style={style}>
          <div key={0} className="clickable" onClick={this.handleEllipsisClicked}>
            {lines[0]}
            <i
              className="ellipsis horizontal icon clicable"
              style={{ paddingLeft: 10 }}
              onClick={this.handleEllipsisClicked}
            />
          </div>
          {showAll && R.addIndex(R.map)((l, idx) => <div key={idx + 1}>{l}</div>, R.drop(1, lines))}
        </div>
      );
    }

    return <div>{content}</div>;
  }
}

export default CollapsibeText;
