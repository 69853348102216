/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getProjectLogStructure = (credentials: Credentials, params: Object) => {
  const { projectName } = params;

  return fetchGet(getEndpoint('logstructure'), {
    ...credentials,
    projectName,
  }).then((d) => {
    const entries = d.entries || [];
    return {
      rawData: d,
      data: {
        logStructureList: d.allPaths,
        logEntryStructure: entries,
      },
    };
  });
};

export default getProjectLogStructure;
