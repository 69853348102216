/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import { push } from 'react-router-redux';
import { NavLink } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { LeftOutlined } from '@ant-design/icons';
import { Pagination, Input as AntInput, Button, Alert, Switch, Steps } from 'antd';

import { buildUrl, CellRenderers, parseLocation, buildLocation, getLoadStatus } from '../../../common/utils';
import { BaseUrls } from '../../app/Constants';
import { Container, Box, Input, AutoSizer, Table, Column } from '../../../lib/fui/react';
import { hideAppLoader, loadProjectInfo, createLoadAction, ActionTypes } from '../../../common/app/actions';
import { State } from '../../../common/types';

import { appButtonsMessages, appMenusMessages } from '../../../common/app/messages';
import { settingsMenusMessages, projectWizardMessages, settingsMessages } from '../../../common/settings/messages';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,
  loaderStatus: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  projects: Array<Object>,
  push: Function,
  // eslint-disable-next-line
  loadProjectInfo: Function,
  showAppAlert: Function,
  hideAppLoader: Function,
  createLoadAction: Function,
};

class CopyProjectCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const params = parseLocation(location);
    const { projectName, description } = params;

    this.state = {
      projectName: projectName || '',
      projectList: [],
      description: description || '',
      currentStep: projectName ? 2 : 1,
      projectSearch: '',

      selectProject: undefined,
      owner: '',
      selectProjectName: '',
      processPauseFlag: false,
    };

    this.dataLoader = 'settings_project_meta_data';
    this.submitLoader = 'app_copy_project_submit';

    this.page = 1;
    this.pageSize = 10;
    this.projects = [];
    this.needReloadMetaData = false;
  }

  componentDidMount() {
    this.timeRenderer = ({ cellData }) => (cellData ? moment(cellData).format('YYYY-MM-DD HH:mm') : '');
    const { projects, showAppAlert, hideAppLoader } = this.props;
    if (projects.length === 0) {
      showAppAlert('info', settingsMessages.alertNoProject);
    } else {
      this.projects = R.clone(projects || []);
      this.filterData(this.props);
      this.reloadProjectMetaData(this.props);
      hideAppLoader();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextParams = parseLocation(nextProps.location);
    const params = parseLocation(this.props.location);

    if (nextParams.page !== params.page || nextParams.pageSize !== params.pageSize) {
      this.reloadProjectMetaData(nextProps);
    } else if (this.props.projects !== nextProps.projects) {
      this.filterData(nextProps);

      if (this.needReloadMetaData) {
        this.needReloadMetaData = false;
        this.reloadProjectMetaData(nextProps);
      } else {
        const page = Number(nextParams.page) || this.page;
        const pageSize = Number(nextParams.pageSize) || this.pageSize;
        const projectList = R.slice((page - 1) * pageSize, page * pageSize, this.projects);
        this.setState({ projectList }, () => {
          if (this.tableNode) {
            this.tableNode.forceUpdateGrid();
            this.tableNode.forceUpdate();
          }
        });
      }
    }
  }

  @autobind
  filterData(props) {
    const { projects } = props;
    const { projectSearch } = this.state;
    this.projects = R.clone(projects || []);
    if (projectSearch) {
      this.projects = R.filter((project) => {
        return R.toLower(project.projectName).indexOf(projectSearch) !== -1;
      }, this.projects);
    }
  }

  @autobind
  reloadProjectMetaData(props) {
    const { location, loadProjectInfo } = props;
    const params = parseLocation(location);
    const page = Number(params.page) || this.page;
    const pageSize = Number(params.pageSize) || this.pageSize;

    const projectList = R.slice((page - 1) * pageSize, page * pageSize, this.projects);

    // Take the projects in the table and load full info and stats.
    const projectNameList = R.map(
      (project) => project.projectName,
      R.filter((project) => !project.hasAllInfo, projectList),
    );
    if (projectNameList.length > 0) {
      loadProjectInfo(
        { projectName: projectNameList, withStats: true },
        { [this.dataLoader]: true },
        this.callbackHandle,
      );
    } else {
      this.setState({ projectList }, () => {
        if (this.tableNode) {
          this.tableNode.forceUpdateGrid();
          this.tableNode.forceUpdate();
        }
      });
    }
  }

  @autobind
  handlePaginationChange(page, pageSize) {
    const { location, push } = this.props;
    const params = parseLocation(location);
    push(buildLocation(location.pathname, {}, { ...params, page, pageSize }));
  }

  @autobind
  handleSearchProject({ field, value, event, isSearch }) {
    if (isSearch || event.type !== 'change') {
      const fieldValue = value ? R.toLower(value) : value;
      this.setState({ [field]: fieldValue }, () => {
        const { location, push } = this.props;
        const params = parseLocation(location);
        push(buildLocation(location.pathname, {}, { ...params, page: 1, [field]: fieldValue }));

        this.filterData(this.props);
        this.reloadProjectMetaData(this.props);
      });
    }
  }

  @autobind
  handleStep1Click(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ currentStep: 2 });
  }

  @autobind
  handleStep2AddMoreClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      currentStep: 1,
    });
  }

  @autobind
  handleStep2Click(e) {
    e.preventDefault();
    e.stopPropagation();

    const { createLoadAction } = this.props;
    const { selectProjectName: selectProject, owner, processPauseFlag, projectName, description } = this.state;
    if (selectProject && owner && projectName) {
      createLoadAction(
        ActionTypes.SAVE_COPY_PROJECT,
        // { selectProject, owner, processPauseFlag, projectName, description },
        { selectProject, owner, processPauseFlag, projectName },
        this.submitLoader,
        false,
        () => this.setState({ currentStep: 3 }),
      );
    }
  }

  @autobind
  handleCompleteClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { projectName } = this.state;
    if (projectName) {
      this.props.push(buildUrl(BaseUrls.SettingsProject, { projectName }, {}));
    } else {
      this.props.push(BaseUrls.SystemSetting);
    }
  }

  @autobind
  handleProjectClick({ event, rowData: project }) {
    this.setState({ selectProject: project, selectProjectName: project.projectName, owner: project.owner });
  }

  @autobind
  headerRowRenderer({ className, columns, style }) {
    return (
      <div className={className} role="row" style={style}>
        {columns}
      </div>
    );
  }

  @autobind
  intervalRenderer({ cellData, rowData }) {
    if (!cellData || rowData.dataType !== 'Metric') {
      return 'NA';
    } else if (parseInt(cellData, 10) === cellData) {
      if (cellData / 60 > 1) {
        return `${cellData / 60} hours`;
      }
      return `${cellData} mins`;
    } else if (parseFloat(cellData) === cellData) {
      return `${(cellData * 60).toFixed(0)}s`;
    }
    return 'NA';
  }

  renderStep1() {
    const { intl, location, loaderStatus } = this.props;
    const params = parseLocation(location);
    const { projectSearch, projectList, selectProject, selectProjectName, processPauseFlag } = this.state;
    const isLoading = get(loaderStatus, this.dataLoader, false);
    const page = Number(params.page) || this.page;
    const pageSize = Number(params.pageSize) || this.pageSize;
    const { isStationary, isMetric } = selectProject || {};

    return (
      <Box style={{ height: '100%' }}>
        <div className="ui form flex-col" style={{ height: '100%' }}>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(projectWizardMessages.step1CopyProject),
            }}
          />

          <div className="flex-row flex-center-align">
            <div className="flex-grow flex-row">
              <AntInput.Search
                size="small"
                placeholder="search project"
                style={{ width: 200, marginRight: 16 }}
                defaultValue={projectSearch}
                allowClear
                enterButton
                onSearch={(value, event) =>
                  this.handleSearchProject({ field: 'projectSearch', value, event, isSearch: true })
                }
                onChange={(event) => this.handleSearchProject({ field: 'projectSearch', event })}
              />
            </div>
            {isStationary && !isMetric && (
              <div className="flex-row" style={{ marginRight: 16 }}>
                <div className="bold" style={{ marginRight: 8 }}>
                  Enable initial processing pause
                </div>
                <Switch
                  size="small"
                  checked={processPauseFlag}
                  onChange={(processPauseFlag) => {
                    this.setState({ processPauseFlag });
                  }}
                />
              </div>
            )}
            <Pagination
              size="small"
              current={page}
              pageSize={pageSize}
              total={this.projects.length}
              onChange={this.handlePaginationChange}
              showTotal={(total) => `Total ${total} items`}
              showSizeChanger
              pageSizeOptions={['10', '20', '50', '100']}
              onShowSizeChange={(current, pageSize) => {
                this.handlePaginationChange(current, pageSize);
              }}
            />
          </div>

          <Container fullHeight className={`flex-grow field${isLoading ? ' loading' : ''}`}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  ref={this.setTableNode}
                  rowCount={projectList.length}
                  rowGetter={({ index }) => projectList[index]}
                  onRowClick={this.handleProjectClick}
                  headerRowRenderer={this.headerRowRenderer}
                  rowStyle={({ index: number }) => {
                    if (number >= 0 && projectList[number] && projectList[number].status === 'Deleting') {
                      return { backgroundColor: 'lightgray' };
                    } else if (
                      number >= 0 &&
                      selectProjectName &&
                      selectProjectName === projectList[number].projectName
                    ) {
                      return {
                        backgroundColor: 'var(--virtualized-table-row-active-bg)',
                        borderLeft: '3px solid orange',
                      };
                    }
                    return {};
                  }}
                >
                  <Column width={180} label="Project name" flexGrow={1} dataKey="projectName" />
                  <Column width={100} label="System name" dataKey="systemName" />
                  <Column
                    width={180}
                    label="Status"
                    flexGrow={1}
                    dataKey="status"
                    cellRenderer={CellRenderers.withTooltip}
                  />
                  <Column width={100} label="Integration type" dataKey="cloudType" />
                  <Column width={100} label="Agent type" dataKey="insightAgentType" />
                  <Column width={80} label="Data type" dataKey="dataType" />
                  <Column width={80} label="Timezone" dataKey="timezone" />
                  <Column
                    width={120}
                    label="Sampling interval"
                    dataKey="samplingInterval"
                    cellRenderer={this.intervalRenderer}
                    className="text-center"
                  />
                  <Column width={120} label="Creation time " dataKey="creationTime" cellRenderer={this.timeRenderer} />
                  <Column width={60} label="Owner" dataKey="owner" />
                </Table>
              )}
            </AutoSizer>
          </Container>
          <div className="inline field text-right">
            <Button size="small" style={{ width: 120 }} disabled={!selectProjectName} onClick={this.handleStep1Click}>
              Next
            </Button>
          </div>
        </div>
      </Box>
    );
  }

  renderStep2() {
    const { intl, loadStatus, projects } = this.props;
    const projectNameLink = VLink.state(this, 'projectName')
      .check((x) => Boolean(x), 'Project name is required')
      .check(
        (name) => !R.find((p) => p.projectName.toLowerCase(0) === name.toLowerCase(), projects),
        'Project name already exists, please use another one',
      )
      .check((name) => /^[0-9a-zA-Z_-]+$/g.test(name), 'Project name can only contains characters: 0-9 a-z A-Z _-');

    const descriptionLink = VLink.state(this, 'description');
    const valid = !projectNameLink.error;

    const { isLoading: isSubmitting } = getLoadStatus(get(loadStatus, this.submitLoader), intl);
    return (
      <Box style={{ height: '100%' }}>
        <div className="ui form" style={{ width: 980 }}>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(projectWizardMessages.step1CopyProject),
            }}
          />
          <div className="flex-row flex-center-align field required" style={{ marginBottom: 24 }}>
            <div className="bold" style={{ width: 220 }}>
              New project Name: <span style={{ color: 'red' }}>*</span>
            </div>
            <Input valueLink={projectNameLink} />
          </div>
          {/* <div className="flex-row flex-center-align field required" style={{ marginBottom: 24 }}>
            <div className="bold" style={{ width: 220 }}>
              Description: (Optional)
            </div>
            <Input valueLink={descriptionLink} />
          </div> */}

          <div className="flex-row flex-space-between">
            <Button
              size="small"
              style={{ width: 120 }}
              icon={<LeftOutlined />}
              disabled={isSubmitting}
              onClick={this.handleStep2AddMoreClick}
            >
              Back
            </Button>
            <Button
              size="small"
              type="primary"
              style={{ width: 120 }}
              disabled={!valid}
              loading={isSubmitting}
              onClick={this.handleStep2Click}
            >
              Register
            </Button>
          </div>
        </div>
      </Box>
    );
  }

  renderStep3() {
    const { intl } = this.props;
    const { projectType, dataType } = this.state;

    const handleAlerAnalysisClick = () => {
      const params = dataType === 'Alert' || dataType === 'Incident' ? { hasAlert: true } : { hasLog: true };
      window.open(buildUrl(BaseUrls.LogCalendar, {}, params), '_blank');
    };

    return (
      <Box style={{ height: '100%' }}>
        <div className="ui form flex-col" style={{ width: 960, height: '100%' }}>
          <Alert
            message="Success"
            description={
              projectType !== 'ServiceNow' ? (
                <div>
                  {intl.formatMessage(projectWizardMessages.step4Introduction, {
                    bold: (str) => <a href="/settings/projects">{str}</a>,
                  })}
                </div>
              ) : (
                <div>
                  {'Congratulations on successfully importing your Service Now Ticket data. ' +
                    'Please take a 10 minute break and check our analysis results in the '}
                  <Button type="link" style={{ padding: 0 }} onClick={handleAlerAnalysisClick}>
                    {dataType === 'Alert' || dataType === 'Incident' ? 'alert analysis' : 'log analysis'}
                  </Button>
                  {' page.'}
                </div>
              )
            }
            type="success"
            showIcon
          />
          <div className="inline field text-right">
            <Button size="small" style={{ width: 120 }} onClick={this.handleCompleteClick}>
              {intl.formatMessage(appButtonsMessages.finished)}
            </Button>
          </div>
        </div>
      </Box>
    );
  }

  render() {
    const { intl, isAdmin, isLocalAdmin, isReadUser } = this.props;
    const { currentStep } = this.state;

    return (
      <Container fullHeight withGutter className="flex-col settings">
        <Container className="flex-row flex-center-align" breadcrumb>
          <div className="section">
            <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
            <span className="divider">/</span>
            <NavLink to={BaseUrls.SystemSetting}>
              <span className="label">{intl.formatMessage(settingsMenusMessages.systemSetting)}</span>
            </NavLink>
            <span className="divider">/</span>
            <span>Copy Existing Project</span>
          </div>
        </Container>

        <Container
          className="flex-grow flex-min-height flex-col content-bg corner-10"
          style={{
            margin: '0 16px 8px 16px',
            borderRadius: 4,
            padding: '8px 16px',
          }}
        >
          {(isAdmin || isLocalAdmin || isReadUser) && (
            <Alert
              message={null}
              description={intl.formatMessage(projectWizardMessages.errorNotAllowAdmin)}
              type="error"
              showIcon
            />
          )}

          {!(isAdmin || isLocalAdmin || isReadUser) && (
            <Container style={{ marginBottom: 8 }}>
              <Steps current={currentStep - 1} type="navigation">
                <Steps.Step
                  title="Select Existing Project"
                  description={<span style={{ whiteSpace: 'nowrap' }}>Select an existing project to copy</span>}
                />
                <Steps.Step
                  title="Create Project"
                  description={<span style={{ whiteSpace: 'nowrap' }}>Choose name and sharing users</span>}
                />
                <Steps.Step
                  title="Finish"
                  description={<span style={{ whiteSpace: 'nowrap' }}>Go to advanced settings</span>}
                />
              </Steps>
            </Container>
          )}
          {!(isAdmin || isLocalAdmin || isReadUser) && (
            <Container className="flex-grow flex-min-height wizard">
              {currentStep === 1 && this.renderStep1()}
              {currentStep === 2 && this.renderStep2()}
              {currentStep === 3 && this.renderStep3()}
            </Container>
          )}
        </Container>
      </Container>
    );
  }
}

const CopyProject = injectIntl(CopyProjectCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, loaderStatus } = state.app;
    let { projects } = state.app;
    projects = R.filter((project) => project.status !== 'Deleting', projects);
    const { isAdmin, isLocalAdmin, isReadUser } = state.auth.userInfo;
    return {
      location,
      loadStatus,
      loaderStatus,
      projects,
      isAdmin,
      isLocalAdmin,
      isReadUser,
    };
  },
  { push, hideAppLoader, loadProjectInfo, createLoadAction },
)(CopyProject);
