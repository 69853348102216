/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import moment from 'moment';
import { get, isNumber } from 'lodash';

import type { Credentials } from '../types';
import { Defaults } from '../app';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { logPatternTopKToArray } from './magicParsers';

const getLogEventList = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, metricInstanceName, dayString, isFetchAll, ...rest } = params;
  const startTimeObj = moment.utc(dayString, Defaults.DateFormat).startOf('day');

  const requests = [];
  requests.push(
    fetchGet(getEndpoint('logstreamingevent'), {
      ...credentials,
      projectName,
      instanceName,
      metricInstanceName,
      isFetchAll: Boolean(isFetchAll),
      ...rest,
      dayTimeMillis: moment.utc(dayString, 'YYYY-MM-DD').valueOf(),
    }).then((d) => {
      const rawData = d;

      let events = R.filter((e) => e.timestamp, get(rawData, 'eventArray', []));
      const totalCount = get(rawData, 'totalMatched', events.length);
      events = R.map((e) => {
        const { timestamp, ...rest } = e;
        return {
          datetime: timestamp,
          timestamp,
          ...rest,
        };
      }, events);

      const compressedSize = get(rawData, ['compressedSize']);
      const originSize = get(rawData, ['originSize']);
      let decreaseRatio;
      if (isNumber(compressedSize) && isNumber(originSize)) {
        decreaseRatio = (originSize - compressedSize) / originSize;
      }

      return {
        totalCount,
        events,
        originSize,
        compressedSize,
        decreaseRatio,
      };
    }),
  );

  requests.push(
    fetchGet(getEndpoint('logstreaminglistpatterns'), {
      ...credentials,
      projectName,
      instanceName,
      metricInstanceName,
      dayTimeMillis: startTimeObj.valueOf(),
    })
      .then((d) => {
        let patterns = get(d, 'patternArray', []);
        let maxEventsCount = 0;
        patterns = R.map((p) => {
          // Convert the topK string into array.
          const featureKeywords = p.topFeatureKeyword || [];
          const kwords = R.take(3, logPatternTopKToArray(p.topK));
          // const keywords = R.map(k => `${k.matchWord}`, featureKeywords || []);
          const keywords = kwords;

          maxEventsCount = Math.max(maxEventsCount, p.count);

          const sampleMsgContent = get(p, ['sampleMsg'], [])[0] || '';
          let msgName = sampleMsgContent;
          let mjson;
          try {
            mjson = JSON.parse(sampleMsgContent);
          } catch (e) {}

          if (mjson && mjson.msg) {
            msgName = mjson.msg;
          }

          // const name = p.patternName === `Pattern ${p.nid}` ? keywords.join('-') || kwords.join('-') : p.patternName;
          const name = p.patternName === `Pattern ${p.nid}` ? '' : p.patternName;
          return {
            ...p,
            keywords,
            keywordsStr: R.join('-', keywords),
            featureKeywords,
            name,
            msgName: msgName || name,
            sampleMsgContent,
            patternName: p.patternName,
            eventsCount: p.count,
          };
        }, patterns);
        patterns = R.sort((a, b) => b.eventsCount - a.eventsCount, patterns);

        // const totalCount = R.reduce((acc, p) => acc + p.eventsCount, 0, patterns);
        R.forEach((p) => (p.countRatio = p.eventsCount / maxEventsCount), patterns);
        const clusterInfoList = patterns;
        return clusterInfoList;
      })
      .catch((err) => {
        console.warn('[IF_API] get pattern list failed, ignored', err);
        return [];
      }),
  );

  return Promise.all(requests).then((results) => {
    const { totalCount, events, originSize, compressedSize, decreaseRatio } = results[0];
    const clusterInfoList = results[1] || [];

    return {
      clusterInfoList,
      data: {
        totalCount,
        events,
        originSize,
        compressedSize,
        decreaseRatio,
      },
    };
  });
};

export default getLogEventList;
