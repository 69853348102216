/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import moment from 'moment';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getJiraProjects = (credentials: Credentials, params: Object) => {
  // Get the jira projects.
  const promiseQuery = [
    fetchGet(
      getEndpoint('jiraGetMeta', 1),
      {
        ...credentials,
        ...params,
      },
      {
        Accept: 'application/json;charset=iso-8859-1',
      },
    ).then((d) => {
      return d;
    }),
  ];

  return Promise.all(promiseQuery).then((results) => {
    const { projects, expand } = results[0] || {};
    const jiraProjects = [];
    R.forEach((val) => {
      const { name, key, issuetypes, assignees } = val;
      jiraProjects.push({
        projectName: name,
        projectKey: key,
        issueTypes: R.map((i) => i.name, issuetypes),
        assignees: assignees || [],
      });
    }, projects);

    return {
      jiraProjects,
    };
  });
};
export default getJiraProjects;
