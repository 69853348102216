import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isObject, isString } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Alert, Input, Select, Spin, message } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { Container, Modal, Popover } from '../../../lib/fui/react';
import { getLoadStatus, Regex, parseJSON } from '../../../common/utils';
import { ActionTypes } from '../../../common/metric/actions';
import { ActionTypes as SettingActionTypes } from '../../../common/settings/actions';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages, eventActionMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';

type Props = {
  // eslint-disable-next-line
  componentList: Array<String>,
  // eslint-disable-next-line
  instanceGroup: String,
  // eslint-disable-next-line
  suggestActions: Array<Object>,
  incident: Object,
  projectName: String,
  projectNameList: Array<String>,
  // eslint-disable-next-line
  actionRootCauseKey: String,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: String,
  // eslint-disable-next-line
  projects: Array<String>,
  projectDisplayMap: Object,
  credentials: Object,
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,

  eventActionList: Array<Object>,
  certainPatternList: Array<Object>,
  emailAlert: Array<Object>,
  projectEmailSettingMap: Object,
  actionProxyServers: Array<Object>,
};

class EventActionModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'event_action_loader';
    this.dataSubmeter = 'event_action_submeter';

    this.actions = [];
    this.instanceListOptions = [];
    this.instanceListOptionsAlways = [
      { value: 'All', label: 'Apply on all instances' },
      { value: 'dynamic', label: 'Dynamic instance targeting' },
    ];
    this.actionTriggered = null;
    this.deleteActionTriggered = false;
    this.addActionTriggered = false;

    const { credentials, projectDisplayMap, projectNameList } = props;
    this.projectNameOptions = [];
    R.forEach((projectFullName) => {
      const [projectName, owner] = projectFullName.split('@');
      const projectNameReal = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
      const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
      this.projectNameOptions.push({ label: projectDisplayName, value: projectFullName });
    }, projectNameList || []);

    this.state = {
      actionNameMap: {},
      actionServerMap: {},
      actionEmailMap: {},
      savedActions: [],

      actionId: '',
      actionType: 'always',
      instanceId: 'dynamic',
      recipients: '',
      proxyServerId: undefined,

      showAddAction: false,
      isLoadingInstance: false,
      newActionName: '',
      newActionCommand: '',
      newActionScript: '',
      newActionParameter: '',
      newProjectName: projectNameList ? projectNameList[0] : '',
    };
    this.listHeaderHeight = 30;
    this.actionTypeOptions = [
      { label: 'one-time', value: 'one-time' },
      { label: 'always', value: 'always' },
    ];
    this.actionOptions = [];
    this.proxyServerOptions = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.incident !== nextProps.incident || this.props.projectName !== nextProps.projectName) {
      this.reloadData(nextProps);
      this.parseData(nextProps);
    } else if (
      this.props.certainPatternList !== nextProps.certainPatternList ||
      this.props.eventActionList !== nextProps.eventActionList ||
      this.props.emailAlert !== nextProps.emailAlert ||
      this.props.projectEmailSettingMap !== nextProps.projectEmailSettingMap ||
      this.props.actionProxyServers !== nextProps.actionProxyServers
    ) {
      this.parseSavedActionData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { projects, createLoadAction, incident, projectName, projectNameList, actionRootCauseKey } = props;
    const { anomalyLogInstance, instanceName, type, startTimestamp, endTimestamp, projectOwner } = incident;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    const eventType = type ? type.charAt(0).toLowerCase() + type.substring(1) : incident.eventType;

    // build event info
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();

    const project = R.find((item) => item.projectName === projectName, projects || []);
    const { systemId } = project || {};

    let params = {
      projectName,
      neuronId: nid,
      operation: 'getAction',
      instanceName: anomalyLogInstance || instanceName,
      eventType,
      needRecipients: true,
      rootCauseKey: actionRootCauseKey,
      startTime,
      endTime,
      systemId,
      customerName: projectOwner,
    };
    if (projectNameList) {
      params = {
        ...params,
        projectNameList: JSON.stringify(projectNameList),
      };
    }
    createLoadAction(ActionTypes.LOAD_EVENT_ACTION, params, this.dataLoader);
  }

  @autobind
  parseData(props) {
    const { incident, currentLocale, suggestActions } = props;
    let suggestActionList = get(incident, 'suggestActionList', []);
    suggestActionList = R.map((item) => parseJSON(item), R.uniq(suggestActionList));

    // get suggest actions
    const isEnglish = currentLocale === 'en';
    R.forEach((suggestAction) => {
      let action = null;
      let componentName = null;
      let instanceName = null;
      if (isString(suggestAction)) {
        const actionString = suggestAction.split(' instanceId ')[0];
        instanceName = suggestAction.split(' instanceId ')[1];
        componentName = actionString.split(' on ')[1];
        action = actionString.split(' on ')[0];
      } else {
        action =
          isObject(suggestAction.actionMap) && !R.isEmpty(suggestAction.actionMap)
            ? isEnglish
              ? suggestAction.actionMap.English
              : suggestAction.actionMap.Chinese
            : suggestAction.action;
        componentName = suggestAction.componentName || '';
        instanceName = suggestAction.instanceName || '';
      }
      this.actions.push({ action, componentName, instanceName });
    }, suggestActions || suggestActionList);

    // get instance list
    // if (isLog) {
    //   instanceList = [incident.instanceName];
    // } else {
    //   instanceList = R.uniq(R.map((item) => item.instanceName, suggestActions || this.actions));
    //   if (instanceList.length === 0) {
    //     instanceList = incident.instanceList || [];
    //   }
    // }
    // this.instanceListOptions = R.map((i) => {
    //   return { label: i, value: i };
    // }, instanceList);
  }

  @autobind
  parseSavedActionData(props) {
    const { incident, certainPatternList, emailAlert, projectEmailSettingMap, eventActionList, actionProxyServers } =
      props;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    if (projectEmailSettingMap) {
      let actionEmailMap = {};
      R.forEachObjIndexed((item, project) => {
        const { actionEmailMap: map } = item;
        actionEmailMap = { ...actionEmailMap, ...map };
      }, projectEmailSettingMap);
      this.setState({ actionEmailMap });
    } else {
      const savedEmailAlert = R.find((ea) => ea.patternId === nid, emailAlert || []);
      if (savedEmailAlert) {
        const { actionEmailMapString } = savedEmailAlert;
        const actionEmailMap = JSON.parse(actionEmailMapString);
        this.setState({ actionEmailMap });
      } else {
        this.setState({ recipients: '' });
      }
    }

    // get saved actions
    const savedActions = (certainPatternList || []).length > 0 ? get(certainPatternList[0], 'savedActions', []) : [];

    // name map
    const actionNameMap = {};
    R.forEach((item) => {
      const { label, value } = item;
      actionNameMap[value] = label;
    }, eventActionList || []);

    // server map
    const actionServerMap = {};
    R.forEach((item) => {
      const { id, serverName, hostName } = item;
      actionServerMap[id] = `${serverName} (${hostName})`;
    }, actionProxyServers || []);

    this.actionOptions = eventActionList || [];
    this.proxyServerOptions = actionProxyServers || [];
    this.setState({ savedActions, actionNameMap, actionServerMap });
  }

  @autobind
  handleTakeAction(operation) {
    return (e) => {
      const {
        projects,
        createLoadAction,
        projectName,
        incident,
        eventActionList,
        projectNameList,
        actionRootCauseKey,
      } = this.props;
      const { actionId, actionType, instanceId, recipients, proxyServerId } = this.state;

      if (actionId && actionType) {
        const {
          // used for metric/log anomalyeventStr
          anomalyRatio,
          anomalyScore,
          count,
          timeLineEvents,
          rawData,

          anomalyLogInstance,
          instanceName,
          startTimestamp,
          endTimestamp,

          causalRootCause,
          rootCauseTableKey,
          projectOwner,
        } = incident || {};
        const project = R.find((item) => item.projectName === projectName, projects || []);
        const { systemId } = project || {};
        const splitId = get(incident, ['key', 'splitId']);
        const nid = incident.nid || incident.neuronId || incident.patternId || incident.targetPatternId;
        const eventType = incident.type
          ? incident.type.charAt(0).toLowerCase() + incident.type.substring(1)
          : incident.eventType;
        const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
        const endTime = moment.utc(endTimestamp).endOf('day').valueOf();

        // build anomalyeventStr
        const rootCauseDetailsArr = [];
        const rcList = get(incident, ['rootCauseJson', 'rootCauseDetailsArr'], []);
        R.forEach((rc) => {
          const { instanceName, metricName, sign, anomalyValue } = rc;
          const { instanceId, rootCauseMetric, direction, metricValue } = rc;
          const causeDetailObj = {
            instanceId: instanceId || instanceName,
            rootCauseMetric: rootCauseMetric || metricName,
            direction: direction || sign,
            metricValue: metricValue || anomalyValue,
          };
          rootCauseDetailsArr.push(causeDetailObj);
        }, rcList);
        const total = count || (timeLineEvents || []).length;

        this.actionTriggered = operation === 'saveAction' ? 'save' : 'trigger';
        const obj = R.find((a) => a.value === actionId, eventActionList);
        createLoadAction(
          ActionTypes.SAVE_EVENT_ACTION,
          {
            patternId: nid,
            neuronId: nid,
            projectName,
            // for GHV and RCA/AA
            projectNameList: JSON.stringify(projectNameList),
            operation:
              operation === 'takeAction' ? obj.operation : instanceId === 'All' ? 'saveActionForAll' : 'saveAction',
            actionId,
            actionType,
            ...(actionType === 'one-time' ? { instanceId } : {}),
            splitId,
            instanceName: anomalyLogInstance || instanceName,
            eventType,
            // Used from metric/log anomaly for both incident/anomloues
            anomalyeventStr: JSON.stringify({
              anomalyScore: anomalyRatio || anomalyScore,
              count: total,
              eventType,
              rawData,
              rootCauseDetailsArr,
            }),
            needRecipients: recipients.length > 0,
            recipients,
            rootCauseKey: actionRootCauseKey,
            startTime,
            endTime,
            // proxy used
            proxyServerId,
            systemId,

            // Used from GHV for incident/deployment
            ...(causalRootCause && rootCauseTableKey
              ? {
                  causalRootCause: JSON.stringify(causalRootCause),
                  rootCauseTableKey: JSON.stringify(rootCauseTableKey),
                }
              : {}),
            customerName: projectOwner,
          },
          this.dataSubmeter,
        );
      }
    };
  }

  @autobind
  onRemoveAction(actionId) {
    return (e) => {
      const { projects, createLoadAction, incident, projectName, projectNameList, actionRootCauseKey } = this.props;
      const { actionNameMap } = this.state;

      if (window.confirm(`Delete this action: ${actionNameMap[actionId]}?`)) {
        const {
          // used for metric/log anomalyeventStr
          anomalyRatio,
          anomalyScore,
          count,
          timeLineEvents,
          rawData,

          anomalyLogInstance,
          instanceName,
          startTimestamp,
          endTimestamp,

          causalRootCause,
          rootCauseTableKey,
          projectOwner,
        } = incident || {};
        const project = R.find((item) => item.projectName === projectName, projects || []);
        const { systemId } = project || {};
        const nid = incident.nid || incident.neuronId || incident.patternId;
        const eventType = incident.type
          ? incident.type.charAt(0).toLowerCase() + incident.type.substring(1)
          : incident.eventType;
        const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
        const endTime = moment.utc(endTimestamp).endOf('day').valueOf();

        // build anomalyeventStr
        const rootCauseDetailsArr = [];
        const rcList = get(incident, ['rootCauseJson', 'rootCauseDetailsArr'], []);
        R.forEach((rc) => {
          const { instanceName, metricName, sign, anomalyValue } = rc;
          const { instanceId, rootCauseMetric, direction, metricValue } = rc;
          const causeDetailObj = {
            instanceId: instanceId || instanceName,
            rootCauseMetric: rootCauseMetric || metricName,
            direction: direction || sign,
            metricValue: metricValue || anomalyValue,
          };
          rootCauseDetailsArr.push(causeDetailObj);
        }, rcList);
        const total = count || (timeLineEvents || []).length;

        this.actionTriggered = null;
        this.addActionTriggered = false;
        this.deleteActionTriggered = true;
        createLoadAction(
          ActionTypes.DELETE_EVENT_ACTION,
          {
            actionParams: {
              projectName,
              projectNameList: JSON.stringify(projectNameList),
              neuronId: nid,
              operation: 'removeAction',
              actionId,
              instanceName: anomalyLogInstance || instanceName,
              eventType,
              // Used from metric/log anomaly for both incident/anomloues
              anomalyeventStr: JSON.stringify({
                anomalyScore: anomalyRatio || anomalyScore,
                count: total,
                eventType,
                rawData,
                rootCauseDetailsArr,
              }),
              rootCauseKey: actionRootCauseKey,
              startTime,
              endTime,
              systemId,

              // Used from GHV for incident/deployment
              ...(causalRootCause && rootCauseTableKey
                ? {
                    causalRootCause: JSON.stringify(causalRootCause),
                    rootCauseTableKey: JSON.stringify(rootCauseTableKey),
                  }
                : {}),
              customerName: projectOwner,
            },
            emailListParams: {
              patternId: nid,
              projectName,
              actionId,
              instanceName: anomalyLogInstance || instanceName,
              emailList: JSON.stringify([]),
              frequency: 'always',
            },
          },
          this.dataLoader,
        );
      }
    };
  }

  @autobind
  addActionClick() {
    this.setState({
      showAddAction: true,
    });
  }

  @autobind
  handleSaveNewAction() {
    const { projects, createLoadAction, incident, projectName, projectNameList, actionRootCauseKey } = this.props;
    const { newActionParameter, newActionCommand, newActionScript, newActionName, newProjectName } = this.state;

    if (newActionName) {
      const { startTimestamp, endTimestamp, projectOwner } = incident || {};
      const project = R.find((item) => item.projectName === projectName, projects || []);
      const { systemId } = project || {};
      // build event info
      const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
      const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
      const actionsList = [
        {
          name: newActionName,
          command: newActionCommand,
          script: newActionScript,
          parameter: newActionParameter,
        },
      ];
      this.addActionTriggered = true;
      createLoadAction(
        SettingActionTypes.SAVE_PROJECT_ACTIONS,
        {
          projectName: newProjectName || projectName,
          projectNameList: JSON.stringify(projectNameList),
          actionsList,
          loadEventActions: true,
          rootCauseKey: actionRootCauseKey,
          startTime,
          endTime,
          systemId,
          customerName: projectOwner,
        },
        this.dataLoader,
        false,
        this.callbackHandle,
      );
    }
  }

  @autobind
  callbackHandle() {
    this.setState({ showAddAction: false });
  }

  @autobind
  handleActionIdChange(actionId) {
    const { actionEmailMap } = this.state;

    this.actionTriggered = null;
    this.setState({ actionId, recipients: R.join(', ', get(actionEmailMap, actionId, [])) }, () => {
      this.getActionInstances();
    });
  }

  @autobind
  handleActionTypeChange(actionType) {
    this.actionTriggered = null;
    this.setState({ actionType, instanceId: actionType === 'one-time' ? 'All' : 'dynamic' }, () => {
      this.getActionInstances();
    });
  }

  @autobind
  getActionInstances() {
    const { intl, credentials } = this.props;
    const { actionId, actionType } = this.state;
    const actionObj = R.find((item) => item.value === actionId, this.actionOptions || []);

    if (actionId && actionObj && actionType === 'one-time') {
      const { projectName } = actionObj;
      this.setState({ isLoadingInstance: true });
      this.props.updateLastActionInfo();
      fetchGet(getEndpoint('projectactions'), {
        ...credentials,
        projectName,
        operation: 'getInstances',
      })
        .then((data) => {
          const { actionInstanceMap } = data || {};
          const instanceListOptions = R.map(
            (i) => {
              return { label: i, value: i };
            },
            R.filter((item) => Boolean(item), get(actionInstanceMap, actionId, [])),
          );
          this.instanceListOptions = [{ value: 'All', label: 'Apply on all instances' }, ...instanceListOptions];
          this.setState({ isLoadingInstance: false });
        })
        .catch((err) => {
          this.instanceListOptions = [];
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isLoadingInstance: false });
        });
    }
  }

  @autobind
  handleClose() {
    const needReload = Boolean(this.actionTriggered) || this.deleteActionTriggered;
    this.actionTriggered = null;
    this.addActionTriggered = false;
    this.deleteActionTriggered = false;
    this.props.onClose(needReload);
  }

  render() {
    const { intl, loadStatus, projectNameList } = this.props;
    const {
      actionNameMap,
      actionServerMap,
      actionEmailMap,
      savedActions,

      showAddAction,
      isLoadingInstance,
      newProjectName,
      actionId,
      actionType,
      instanceId,
      recipients,
      proxyServerId,
    } = this.state;
    const { newActionName, newActionCommand, newActionScript, newActionParameter } = this.state;

    const { isLoading, isLoaded, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const {
      isLoading: submitting,
      isLoaded: isLoadedSubmeting,
      errorMessage: errorMessageSubmeting,
    } = getLoadStatus(get(loadStatus, this.dataSubmeter), intl);
    const action = R.find((a) => a.value === actionId, this.actionOptions);
    const actionTitle = get(action, ['label'], '');
    const hasError =
      !actionId ||
      !actionType ||
      !instanceId ||
      (instanceId === 'All' && !proxyServerId) ||
      (recipients &&
        !R.reduce(
          R.and,
          true,
          R.map((e) => Regex.email.test(R.trim(e)), recipients.split(',')),
        ));

    return (
      <Modal
        width={850}
        title={intl.formatMessage(eventMessages.takeAction)}
        visible
        footer={null}
        maskClosable={false}
        onCancel={() => this.handleClose()}
      >
        <Container
          className={`content flex-col ${isLoading && !errorMessage ? 'loading' : ''}  ${
            submitting && !errorMessageSubmeting ? 'loading' : ''
          }`}
        >
          <Container>
            <div style={{ fontSize: 14, fontWeight: 700 }}>{`${intl.formatMessage(
              eventActionMessages.savedAction,
            )}:`}</div>
            {savedActions.length === 0 && (
              <Alert
                message=""
                description={intl.formatMessage(eventActionMessages.noSavedAction)}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}
            {savedActions.length !== 0 && (
              <div className="event-list" style={{ marginBottom: 16 }}>
                <div className="event-list-header" style={{ height: this.listHeaderHeight, width: '100%' }}>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(eventActionMessages.savedAction)}
                  </div>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(eventActionMessages.actionServer)}
                  </div>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(eventActionMessages.savedEmailAlert)}
                  </div>
                  <div className="header-column" style={{ width: 60 }} />
                </div>
                <div
                  className="event-list-grid"
                  style={{
                    maxHeight: 100,
                    overflowY: 'auto',
                  }}
                >
                  {R.addIndex(R.map)((action, index) => {
                    return (
                      <div
                        key={index}
                        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                        style={{ minHeight: 40 }}
                      >
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          {actionNameMap[action.actionId]}
                        </div>
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          {actionServerMap[action.proxyServerId]}
                        </div>
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          {R.join(', ', actionEmailMap[action.actionId] || [])}
                        </div>
                        <div className="row-column flex-end-justify" style={{ width: 60 }}>
                          <DeleteOutlined onClick={this.onRemoveAction(action.actionId)} />
                        </div>
                      </div>
                    );
                  }, savedActions)}
                </div>
              </div>
            )}

            <div style={{ fontSize: 14, fontWeight: 700 }}>{`${intl.formatMessage(
              eventActionMessages.suggestedAction,
            )}:`}</div>
            {(!this.actions || this.actions.length === 0) && (
              <Alert
                message=""
                description={intl.formatMessage(eventActionMessages.noSuggestedAction)}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}
            {this.actions && this.actions.length > 0 && (
              <div className="event-list" style={{ marginBottom: 16 }}>
                <div className="event-list-header" style={{ height: this.listHeaderHeight, width: '100%' }}>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(eventActionMessages.suggestedAction)}
                  </div>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(eventActionMessages.componentName)}
                  </div>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(eventActionMessages.instanceName)}
                  </div>
                </div>
                <div
                  className="event-list-grid"
                  style={{
                    maxHeight: 100,
                    overflowY: 'auto',
                  }}
                >
                  {R.addIndex(R.map)((action, index) => {
                    return (
                      <div
                        key={index}
                        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                        style={{ minHeight: 40 }}
                      >
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          {action.action}
                        </div>
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          <Popover content={action.componentName} placement="right" mouseEnterDelay={0.3}>
                            <span className="hidden-line-with-ellipsis inline-block max-width">
                              {action.componentName}
                            </span>
                          </Popover>
                        </div>
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          <Popover content={action.instanceName} placement="right" mouseEnterDelay={0.3}>
                            <span className="hidden-line-with-ellipsis inline-block max-width">
                              {action.instanceName}
                            </span>
                          </Popover>
                        </div>
                      </div>
                    );
                  }, this.actions)}
                </div>
              </div>
            )}
          </Container>

          <Container style={{}}>
            <div style={{ margin: 0 }} className="flex-row">
              <div style={{ fontSize: 14, fontWeight: 700 }}>{`${intl.formatMessage(
                eventActionMessages.takeActionOnEvent,
              )}:`}</div>
              <div className="flex-grow" />
              {isLoaded && this.addActionTriggered && (
                <span style={{ color: 'blue', fontSize: 12, paddingLeft: 10 }}>{`${newActionName} is created`}</span>
              )}
              {errorMessage && this.addActionTriggered && (
                <span
                  style={{ color: 'red', fontSize: 12, paddingLeft: 10 }}
                >{`${newActionName} is create error`}</span>
              )}
              {isLoadedSubmeting && this.actionTriggered && (
                <span style={{ color: 'blue', fontSize: 12, paddingLeft: 10 }}>{`${actionTitle} is ${
                  this.actionTriggered === 'save' ? 'saved' : 'triggered'
                }`}</span>
              )}
              {errorMessageSubmeting && this.actionTriggered && (
                <span
                  style={{ color: 'red', fontSize: 12, paddingLeft: 10 }}
                >{`${actionTitle} is ${this.actionTriggered} error`}</span>
              )}
            </div>

            {showAddAction && (
              <div className="flex-row" style={{ marginBottom: 16 }}>
                <div className="content" style={{ marginRight: 16 }}>
                  <div className="light-label">{intl.formatMessage(eventActionMessages.actionName)}</div>
                  <Input
                    size="small"
                    style={{ width: 100 }}
                    value={newActionName}
                    onChange={(e) => {
                      this.addActionTriggered = false;
                      this.setState({ newActionName: e.target.value });
                    }}
                  />
                </div>
                <div className="content" style={{ marginRight: 16 }}>
                  <div className="light-label">{intl.formatMessage(appFieldsMessages.command)}</div>
                  <Input
                    size="small"
                    style={{ width: 100 }}
                    value={newActionCommand}
                    onChange={(e) => {
                      this.setState({ newActionCommand: e.target.value });
                    }}
                  />
                </div>
                <div className="content" style={{ marginRight: 16 }}>
                  <div className="light-label">{intl.formatMessage(appFieldsMessages.script)}</div>
                  <Input
                    size="small"
                    style={{ width: 100 }}
                    value={newActionScript}
                    onChange={(e) => {
                      this.setState({ newActionScript: e.target.value });
                    }}
                  />
                </div>
                <div className="content" style={{ marginRight: 16 }}>
                  <div className="light-label">{intl.formatMessage(appFieldsMessages.parameter)}</div>
                  <Input
                    size="small"
                    style={{ width: 100 }}
                    value={newActionParameter}
                    onChange={(e) => {
                      this.setState({ newActionParameter: e.target.value });
                    }}
                  />
                </div>
                {projectNameList && projectNameList.length > 0 && (
                  <div className="content" style={{ marginRight: 16 }}>
                    <div className="light-label">Project</div>
                    <Select
                      allowClear
                      showArrow={false}
                      showSearch
                      size="small"
                      style={{ width: 150 }}
                      placeholder={intl.formatMessage(appFieldsMessages.project)}
                      value={newProjectName}
                      filterOption
                      optionFilterProp="value"
                      onChange={(newProjectName) => this.setState({ newProjectName })}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <Select.Option key={item.value} title={item.value}>
                            {item.label}
                          </Select.Option>
                        );
                      }, this.projectNameOptions)}
                    </Select>
                  </div>
                )}
                <div className="flex-grow">
                  <div style={{ color: 'white' }}>space</div>
                  <Button size="small" type="primary" style={{ float: 'right' }} onClick={this.handleSaveNewAction}>
                    {intl.formatMessage(eventActionMessages.addAction)}
                  </Button>
                </div>
              </div>
            )}

            <div className="flex-row" style={{ marginBottom: 16 }}>
              <Spin spinning={isLoadingInstance} wrapperClassName="full-height spin-full-width">
                <div className="content" style={{ paddingRight: 20 }}>
                  <div className="light-label">
                    {intl.formatMessage(eventActionMessages.action)}
                    <i
                      className="icon add"
                      style={{ cursor: 'pointer', color: 'blue' }}
                      onClick={this.addActionClick}
                    />
                  </div>
                  <Select
                    allowClear
                    showArrow={false}
                    showSearch
                    size="small"
                    style={{ width: 250 }}
                    placeholder={intl.formatMessage(eventActionMessages.actionName)}
                    value={actionId}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={this.handleActionIdChange}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} title={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.actionOptions)}
                  </Select>
                </div>
                <div className="content" style={{ marginRight: 16 }}>
                  <div style={{ color: 'white' }}>space</div>
                  <Select
                    size="small"
                    style={{ width: 100 }}
                    value={actionType}
                    onChange={this.handleActionTypeChange}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} title={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.actionTypeOptions)}
                  </Select>
                </div>
                <div className="content" style={{ marginRight: 16 }}>
                  <div className="light-label">Instance Id</div>
                  <Select
                    showSearch
                    size="small"
                    style={{ width: 220 }}
                    filterOption
                    optionFilterProp="value"
                    value={instanceId}
                    onChange={(instanceId) => this.setState({ instanceId })}
                    options={actionType === 'one-time' ? this.instanceListOptions : this.instanceListOptionsAlways}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  />
                </div>
              </Spin>
            </div>

            <div className="flex-row" style={{ marginBottom: 16 }}>
              <div className="content full-width">
                <div style={{ fontSize: 14, fontWeight: 700 }}>Notification recipients</div>
                <Input
                  size="small"
                  style={{ width: '100%' }}
                  value={recipients}
                  onChange={(e) => {
                    this.setState({ recipients: e.target.value });
                  }}
                />
              </div>
            </div>

            <div className="flex-row" style={{ marginBottom: 16 }}>
              <div className="content full-width">
                <div style={{ fontSize: 14, fontWeight: 700 }}>
                  Action server
                  {instanceId === 'All' && <span style={{ color: 'red' }}>*</span>}
                </div>
                <Select
                  size="small"
                  style={{ width: '100%' }}
                  allowClear
                  showSearch
                  value={proxyServerId}
                  onChange={(proxyServerId) => this.setState({ proxyServerId })}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Select.Option key={item.id}>
                        <div className="flex-row flex-center-align">
                          <span className="light-label bold">{intl.formatMessage(settingsMessages.serverName)}:</span>
                          <span style={{ margin: '0 8px 0 4px' }}>{item.serverName}</span>
                          <span className="light-label bold">{intl.formatMessage(settingsMessages.hostName)}:</span>
                          <span style={{ margin: '0 8px 0 4px' }}>{item.hostName}</span>
                        </div>
                      </Select.Option>
                    );
                  }, this.proxyServerOptions || [])}
                </Select>
              </div>
            </div>

            <div className="flex-row">
              <div className="flex-grow" />
              <Button
                type="primary"
                size="small"
                disabled={hasError || (submitting && !errorMessageSubmeting) || actionType === 'one-time'}
                onClick={this.handleTakeAction('saveAction')}
              >
                {intl.formatMessage(eventActionMessages.saveAction)}
              </Button>
              <Button
                type="primary"
                size="small"
                style={{ marginLeft: 8 }}
                disabled={hasError || (submitting && !errorMessageSubmeting) || actionType === 'always'}
                onClick={this.handleTakeAction('takeAction')}
              >
                {intl.formatMessage(eventActionMessages.takeAction)}
              </Button>
            </div>
          </Container>
        </Container>
      </Modal>
    );
  }
}

const EventActionModal = injectIntl(EventActionModalCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, projects, projectDisplayMap } = state.app;
    const { credentials } = state.auth;
    const { eventActionList, certainPatternList, emailAlert, projectEmailSettingMap, actionProxyServers } =
      state.metric;
    return {
      location,
      loadStatus,
      credentials,
      currentLocale,
      projects,
      projectDisplayMap,

      eventActionList,
      certainPatternList,
      emailAlert,
      projectEmailSettingMap,
      actionProxyServers,
    };
  },
  { createLoadAction, updateLastActionInfo },
)(EventActionModal);
