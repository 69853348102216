/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import loadJobAnalysisLogic from './loadJobAnalysisLogic';

export default [
  loadJobAnalysisLogic,
];
