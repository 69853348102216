/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Spin } from 'antd';
import { autobind } from 'core-decorators';
import qs from 'qs';

import ProjectAgentSettingPrometheus from './AgentSettings/ProjectAgentSettingPrometheus';
import fetchGet from '../../../../common/apis/fetchGet';
import getEndpoint from '../../../../common/apis/getEndpoint';
import fetchPostForm from '../../../../common/apis/fetchPostForm';
import ProjectAgentSettingKafka from './AgentSettings/ProjectAgentSettingKafka';
import ProjectAgentSettingElastic from './AgentSettings/ProjectAgentSettingElastic';

class ProjectAgentSettingCore extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      allData: {},
      allSystemData: {},
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.projectName !== nextProps.projectName || this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData() {
    const { projectName, currentProject, credentials } = this.props;
    const { systemId } = currentProject;
    this.setState({ loading: true }, () => {
      fetchGet(getEndpoint('agent-config-generator'), {
        ...credentials,
        systemName: systemId,
        customerName: currentProject?.owner,
      })
        .then((sd) => {
          const allSystemData = JSON.parse(sd?.agentConfigContent || '{}');
          fetchGet(getEndpoint('agent-config-generator'), {
            ...credentials,
            projectName,
            customerName: currentProject?.owner,
          })
            .then((d) => {
              const allData = JSON.parse(d?.agentConfigContent || '{}');
              this.setState({ allData, allSystemData, loading: false });
            })
            .catch((err) => {
              this.setState({
                loading: false,
              });
            });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
        });
    });
  }

  @autobind
  handleSubmit(agentData, callback) {
    const { projectName, currentProject } = this.props;
    const { allData } = this.state;
    const activeKey = currentProject?.cloudType?.toLowerCase();

    const customerName = currentProject?.owner;

    const newAllData = { ...allData, [activeKey]: agentData };
    const formData = new FormData();
    formData.append('data', JSON.stringify(newAllData));
    formData.append('projectName', projectName);
    formData.append('customerName', customerName);
    fetchPostForm(
      `${getEndpoint('agent-config-generator')}?${qs.stringify({
        projectName,
        customerName,
      })}`,
      formData,
    )
      .then((d) => {
        this.setState({ allData: JSON.parse(d?.agentConfigContent || '{}') });
        callback();
      })
      .catch((err) => {
        console.error(err);
        callback(err);
      });
  }

  render() {
    const { intl, currentTheme, currentProject, userInfo } = this.props;
    const { allSystemData, allData, loading } = this.state;
    const { cloudType } = currentProject;
    const activeKey = cloudType?.toLowerCase();

    return (
      <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-height">
        {activeKey === 'prometheus' && (
          <ProjectAgentSettingPrometheus
            intl={intl}
            userInfo={userInfo}
            currentProject={currentProject}
            agentSystemData={allSystemData?.prometheus || {}}
            agentData={allData?.prometheus || {}}
            onSubmit={this.handleSubmit}
            currentTheme={currentTheme}
          />
        )}
        {activeKey === 'kafka' && (
          <ProjectAgentSettingKafka
            intl={intl}
            userInfo={userInfo}
            currentProject={currentProject}
            agentSystemData={allSystemData?.kafka || {}}
            agentData={allData?.kafka || {}}
            onSubmit={this.handleSubmit}
            currentTheme={currentTheme}
          />
        )}
        {activeKey === 'elastic' && (
          <ProjectAgentSettingElastic
            intl={intl}
            userInfo={userInfo}
            currentProject={currentProject}
            agentSystemData={allSystemData?.elastic || {}}
            agentData={allData?.elastic || {}}
            onSubmit={this.handleSubmit}
            currentTheme={currentTheme}
          />
        )}
      </Spin>
    );
  }
}

const ProjectAgentSetting = injectIntl(ProjectAgentSettingCore);
export default connect((state) => {
  const { credentials, userInfo } = state.auth;
  return { credentials, userInfo };
}, {})(ProjectAgentSetting);
