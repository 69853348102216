import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { Button, Input, message, Tooltip } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Column, Container, Modal, Table } from '../../../../lib/fui/react';

import { settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appMessages } from '../../../../common/app/messages';
import { eventMessages } from '../../../../common/metric/messages';

export default function EditMetricTypeModal({ intl, onClose, onOk, credentials, currentProject }: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    customerDefineMetricSet: [],
    addOptionValue: '',
    loading: false,
    isHasDelete: true,
    addButtonLoading: false,
    deleteButtonLoading: false,
  });
  const dataTableNode = useRef(null);
  const { customerDefineMetricSet, addOptionValue, loading, isHasDelete, addButtonLoading, deleteButtonLoading } =
    state;

  useEffect(() => {
    setState({ loading: true });
    const { projectShortName, owner } = currentProject;
    fetchGet(getEndpoint('projectmetrictype'), {
      ...credentials,
      project: projectShortName,
      customerName: owner,
    })
      .then((data) => {
        const { customerDefineMetricSet } = data;
        setState({ customerDefineMetricSet, loading: false });
      })
      .catch((e) => {
        setState({ loading: false });
        message.error(e.message || String(e));
      });
  }, []);

  const addOption = () => {
    setState({ addButtonLoading: true });
    const { owner, projectShortName } = currentProject;
    const splitAddOptionValue = R.filter((item) => item, addOptionValue.split(','));
    const mapAddOptionValue = R.map((item) => ({ metricType: item }), splitAddOptionValue);

    fetchPost(getEndpoint('projectmetrictype'), {
      ...credentials,
      project: projectShortName,
      customerName: owner,
      addMetricTypeSet: JSON.stringify(mapAddOptionValue),
      removeMetricTypeSet: JSON.stringify([]),
    })
      .then((data) => {
        const { success, message: msg } = data;
        setState({ addButtonLoading: false });
        if (success || success === undefined) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          onOk();
        } else {
          message.error(msg);
        }
      })
      .catch((e) => {
        setState({ addButtonLoading: false });
        message.error(e.message || String(e));
      });
  };

  const deleteOption = () => {
    setState({ deleteButtonLoading: true });
    const { owner, projectShortName } = currentProject;
    const deletemetricTypeList = R.map(
      (item) => ({ metricType: item.metricType }),
      R.filter((item) => item.checked, customerDefineMetricSet),
    );

    fetchPost(getEndpoint('projectmetrictype'), {
      ...credentials,
      project: projectShortName,
      customerName: owner,
      removeMetricTypeSet: JSON.stringify(deletemetricTypeList),
      addMetricTypeSet: JSON.stringify([]),
    })
      .then(async (data) => {
        setState({ deleteButtonLoading: false });
        const { success, message: msg } = data;
        if (success || success === undefined) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          onOk();
        } else {
          message.error(msg);
        }
      })
      .catch((e) => {
        setState({ deleteButtonLoading: false });
        message.error(e.message || String(e));
      });
  };

  const handleInputChanged = (rowData, dataKey) => {
    return (e) => {
      rowData[dataKey] = e.target.checked;
      dataTableNode.current.forceUpdateGrid();
      const isHasDelete = Boolean(R.find((item) => item.checked === true, customerDefineMetricSet));
      setState({ isHasDelete: !isHasDelete });
    };
  };

  const checkboxCellRender = ({ dataKey, rowData, cellData }) => (
    <input
      className="fui input"
      type="checkbox"
      checked={cellData || false}
      onChange={handleInputChanged(rowData, dataKey)}
    />
  );

  const footerRender = () => (
    <div>
      <Button size="small" onClick={() => onClose()}>
        {intl.formatMessage(appButtonsMessages.cancel)}
      </Button>
      <Tooltip title={isHasDelete ? null : intl.formatMessage(settingsMessages.allMetricSettingReset)}>
        <Button
          size="small"
          type="primary"
          disabled={deleteButtonLoading || isHasDelete}
          loading={deleteButtonLoading}
          onClick={deleteOption}
          style={{ marginLeft: 10 }}
        >
          {intl.formatMessage(appButtonsMessages.delete)}
        </Button>
      </Tooltip>
    </div>
  );

  return (
    <Modal
      title={intl.formatMessage(settingsMessages.editMetricType)}
      width={650}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      footer={footerRender()}
    >
      <Container className="full-width full-height flex-row flex-center-align" style={{ marginBottom: 10 }}>
        <Input
          allowClear
          style={{ width: 200, marginRight: 10 }}
          placeholder={intl.formatMessage(settingsMessages.addMultipleToSplit)}
          value={addOptionValue}
          onChange={(e) => setState({ addOptionValue: e.target.value })}
        />
        <Button
          type="primary"
          size="small"
          className="flex-row flex-center-align"
          onClick={addOption}
          disabled={addButtonLoading || !addOptionValue}
          loading={addButtonLoading}
        >
          {intl.formatMessage(appButtonsMessages.add)}
        </Button>
      </Container>
      <Container className={`full-width full-height flex-row flex-space-between ${loading ? 'loading' : ''}`}>
        <Table
          className="with-border"
          width={600}
          height={250}
          headerHeight={40}
          rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
          rowHeight={40}
          rowCount={customerDefineMetricSet.length}
          rowGetter={({ index }) => customerDefineMetricSet[index]}
          ref={(dataTable) => {
            dataTableNode.current = dataTable;
          }}
          headerStyle={{ display: 'flex' }}
        >
          <Column width={100} flexGrow={1} label={intl.formatMessage(eventMessages.metricType)} dataKey="metricType" />
          <Column
            width={50}
            label={null}
            dataKey="checked"
            cellRenderer={checkboxCellRender}
            style={{ display: 'flex' }}
          />
        </Table>
      </Container>
    </Modal>
  );
}
