export const shouldRunAwesomeTours = (isFirstLogin) => {
  const urlRex = 'https://sandbox.insightfinder.com';
  const url = window.BASE_URL || window.location.origin || '';
  let isSandbox = false;
  if (url === urlRex) {
    isSandbox = true;
  }

  return isFirstLogin && !isSandbox;
};
