/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { LockOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Select, Upload, Avatar, message, Input } from 'antd';

import getEndpoint from '../src/common/apis/getEndpoint';
import fetchPut from '../src/common/apis/fetchPut';
import fetchPostForm from '../src/common/apis/fetchPostForm';
import type { State } from '../src/common/types';
import { Container } from '../src/lib/fui/react';
import { buildUrl, Defaults, Regex } from '../src/common/utils';
import { logoff, loginSuccess, sessionInvalid, ActionTypes } from '../src/common/auth/actions';
import { hideAppLoader, setDefaultTimezone, updateLastActionInfo, createSetAction } from '../src/common/app/actions';

import { appMessages, appFieldsMessages, appButtonsMessages } from '../src/common/app/messages';
import { authMessages } from '../src/common/auth/messages';

import ChangePasswordModal from './ChangePasswordModal';
import { LocaleSelector } from '../src/web/app/components';
import { eventMessages } from '../src/common/metric/messages';

type Props = {
  intl: Object,
  userInfo: Object,
  credentials: Object,
  logoff: Function,
  loginSuccess: Function,
  sessionInvalid: Function,
  defaultTimezone: String,
  hideAppLoader: Function,
  setDefaultTimezone: Function,
  updateLastActionInfo: Function,
  createSetAction: Function,
};

class AccountInfoCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { userInfo } = props;

    this.state = {
      isSubmitting: false,
      uploadingAvatar: false,
      showChangePassword: false,

      fullName: userInfo.fullName,
      email: userInfo.email,
      companyName: userInfo.companyName,
      defaultTimezone: props.defaultTimezone,
    };
    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
  }

  componentDidMount() {
    this.props.hideAppLoader();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const defaultTimezone = get(nextProps, 'defaultTimezone', '');
    if (defaultTimezone !== this.props.defaultTimezone) {
      this.setState({ defaultTimezone });
    }
  }

  @autobind
  handleRefreshClick() {
    window.location.reload();
  }

  @autobind
  beforeUpload(file) {
    const { intl } = this.props;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(intl.formatMessage(appMessages.uploadAvatarWithJPG));
    }
    const isLt100K = file.size / 1024 < 100;
    if (!isLt100K) {
      message.error(intl.formatMessage(appMessages.uploadAvatarWithLimit));
    }
    return isJpgOrPng && isLt100K;
  }

  @autobind
  handleUploadAvatar(options) {
    const { createSetAction } = this.props;
    const { onSuccess, onError, file } = options;

    this.setState({ uploadingAvatar: true });
    const formData = new FormData();
    formData.append('files[]', file);

    this.props.updateLastActionInfo();
    fetchPostForm(getEndpoint('avatar', 2), formData)
      .then((data) => {
        const { avatarId } = data || {};

        createSetAction(
          ActionTypes.SET_USER_AUTH_INFO,
          {},
          {
            credentials: {},
            userInfo: { avatarId },
          },
        );
        onSuccess('Ok');
        this.setState({ uploadingAvatar: false });
        this.forceUpdate();
      })
      .catch((err) => {
        onError({ err });
        this.setState({ uploadingAvatar: false });
      });
  }

  @autobind
  handleChangePasswordClick() {
    this.setState({ showChangePassword: true });
  }

  @autobind
  handleChangePasswordClose(succeed) {
    this.setState({ showChangePassword: false }, () => {
      const { logoff, credentials } = this.props;
      if (succeed) {
        logoff(credentials);
      }
    });
  }

  @autobind
  handleUpdateClick() {
    const { intl, credentials, userInfo, loginSuccess, setDefaultTimezone, defaultTimezone: prevZone } = this.props;
    const { fullName, email, companyName, defaultTimezone } = this.state;
    this.setState({ isSubmitting: true });

    this.props.updateLastActionInfo();
    fetchPut(getEndpoint('customer', 2), {
      fullName: R.replace(/ /g, ',', R.trim(fullName)),
      emailAddress: email,
      companyName,
      timezone: defaultTimezone,
    })
      .then((data) => {
        loginSuccess(credentials, { ...userInfo, fullName, email, companyName });
        setDefaultTimezone(defaultTimezone);
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmitting: false });
        if (defaultTimezone !== prevZone) {
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.code === 401) {
          message.error(err.message);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
        }
        this.setState({ isSubmitting: false });
      });
  }

  render() {
    const { sessionInvalid, userInfo = {}, intl } = this.props;
    const { isSubmitting, uploadingAvatar, showChangePassword, defaultTimezone, fullName, email, companyName } =
      this.state;
    const { isAdmin } = userInfo;

    if (!userInfo.userName) {
      sessionInvalid();
      return null;
    }

    const hasErrFullName = !fullName || !R.trim(fullName).includes(' ');
    const hasErrEmail = !email || !Regex.email.test(email);
    const hasErrCompany = !companyName;
    const hasErrorZone = !defaultTimezone;
    return (
      <Container className="full-height flex-col flex-center-align" style={{ overflow: 'auto', paddingBottom: 30 }}>
        <div style={{ fontSize: 24, fontWeight: 500, margin: '60px 0 24px 0' }}>
          {intl.formatMessage(appMessages.userAccountInformation)}
        </div>
        <div
          style={{
            background: 'var(--content-background)',
            borderRadius: 6,
            width: 760,
            padding: 24,
            fontSize: 14,
          }}
        >
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              label={
                <Avatar
                  size={64}
                  icon={<UserOutlined />}
                  style={{ marginTop: 36 }}
                  src={buildUrl(getEndpoint('avatar', 2), {}, { avatarId: userInfo.avatarId })}
                />
              }
              colon={false}
              style={{ marginBottom: 12 }}
            >
              <div className="flex-row flex-center-align" style={{ height: 64 }}>
                <Upload showUploadList={false} beforeUpload={this.beforeUpload} customRequest={this.handleUploadAvatar}>
                  <Button size="small" loading={uploadingAvatar} icon={<UploadOutlined />}>
                    {intl.formatMessage(appMessages.uploadAvatar)}
                  </Button>
                </Upload>
              </div>
            </Form.Item>

            <Form.Item label={intl.formatMessage(appFieldsMessages.userName)} style={{ marginBottom: 12 }}>
              <span style={{ fontWeight: 'bold' }}>{userInfo.userName}</span>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.fullName)}
              style={{ marginBottom: 12 }}
              validateStatus={hasErrFullName ? 'error' : 'success'}
              help={hasErrFullName ? 'Full name should format as: First Name Last Name' : undefined}
              required
            >
              <Input value={fullName} onChange={(e) => this.setState({ fullName: e.target.value })} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.email)}
              style={{ marginBottom: 12 }}
              validateStatus={hasErrEmail ? 'error' : 'success'}
              help={hasErrEmail ? intl.formatMessage(authMessages.errorsEmailIncorrect) : undefined}
              required
            >
              <Input value={email} onChange={(e) => this.setState({ email: e.target.value })} disabled />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(eventMessages.accountGroupName)}
              style={{ marginBottom: 12 }}
              validateStatus={hasErrCompany ? 'error' : 'success'}
              help={hasErrCompany ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input
                value={companyName}
                onChange={(e) => this.setState({ companyName: e.target.value })}
                disabled={!isAdmin}
              />
            </Form.Item>
            <Form.Item label={intl.formatMessage(appFieldsMessages.licenseKey)} style={{ marginBottom: 12 }}>
              <span style={{ fontWeight: 'bold' }}>{userInfo.licenseKey}</span>
            </Form.Item>
            <Form.Item label={intl.formatMessage(appFieldsMessages.encryptedLicenseKey)} style={{ marginBottom: 12 }}>
              <div className="max-width bold" style={{ wordBreak: 'break-all' }}>
                {userInfo.encryptedLicenseKey}
              </div>
            </Form.Item>
            <Form.Item label={intl.formatMessage(authMessages.expirationDate)} style={{ marginBottom: 12 }}>
              <span style={{ fontWeight: 'bold' }}>
                {userInfo.expirationDate
                  ? moment.utc(userInfo.expirationDate).format(Defaults.DateTimeFormat)
                  : 'Never'}
              </span>
            </Form.Item>
            <Form.Item label={intl.formatMessage(appFieldsMessages.language)} style={{ marginBottom: 12 }}>
              <div style={{ display: 'inline-block' }}>
                <LocaleSelector />
              </div>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.defaultTimezone)}
              style={{ marginBottom: 12 }}
              validateStatus={hasErrorZone ? 'error' : 'success'}
              help={hasErrorZone ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                className="tour-timezone"
                showSearch
                style={{ width: 200 }}
                filterOption
                options={this.timezoneOptions}
                value={defaultTimezone}
                onChange={(defaultTimezone) => this.setState({ defaultTimezone })}
              />
            </Form.Item>

            <Form.Item label={intl.formatMessage(appFieldsMessages.password)} style={{ marginBottom: 12 }}>
              <Button size="small" type="primary" icon={<LockOutlined />} onClick={this.handleChangePasswordClick}>
                {intl.formatMessage(appFieldsMessages.changePassword)}
              </Button>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }} style={{ marginBottom: 12 }}>
              <Button
                size="small"
                type="primary"
                icon={<LockOutlined />}
                disabled={hasErrFullName || hasErrEmail || hasErrCompany || hasErrorZone}
                loading={isSubmitting}
                onClick={this.handleUpdateClick}
              >
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </Form.Item>
          </Form>
        </div>

        {showChangePassword && <ChangePasswordModal onClose={this.handleChangePasswordClose} />}
      </Container>
    );
  }
}

const AccountInfo = injectIntl(AccountInfoCore);
export default connect(
  (state: State) => {
    const { defaultTimezone } = state.app;
    const { credentials } = state.auth;
    return { userInfo: state.auth.userInfo, defaultTimezone, credentials };
  },
  {
    logoff,
    loginSuccess,
    sessionInvalid,
    hideAppLoader,
    setDefaultTimezone,
    updateLastActionInfo,
    createSetAction,
  },
)(AccountInfo);
