import { isBoolean } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

import { apiCallAfterCreateProject } from './parsers';

const newServiceNowProject = (credentials: Credentials, projectName: String, params: Object) => {
  const {
    // system info
    systemKey,
    systemDisplayName,
    projectOwner,
    // labels info
    labelsWhitelist,
    labelsIncident,
    ...rest
  } = params;
  const projectCreationType = 'ServiceNow';
  return fetchPost(getEndpoint('add-project'), {
    ...credentials,
    ...rest,
    operation: 'register',
    projectOwner,
    projectName,
    projectCreationType,
  }).then((d) => {
    if (isBoolean(d.success) && d.success === false) {
      return d;
    }

    return apiCallAfterCreateProject({
      credentials,
      projectName,
      systemKey,
      systemDisplayName,
      labelsWhitelist,
      labelsIncident,
      projectOwner,
    });
  });
};

export default newServiceNowProject;
