/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postEventAlert = (credentials: Credentials, params: Object) => {
  const promiseQuery = [
    fetchPost(getEndpoint('patternemailsetting', 1), {
      ...credentials,
      ...params,
    }).then((d) => {
      return d;
    }),
  ];

  return Promise.all(promiseQuery).then((results) => {
    const d = results[0] || {};
    return {
      d,
    };
  });
};
export default postEventAlert;
