import React from 'react';
import DataChart from './DataChart';

const DataSummaryChart = ({
  summary,
  onDateWindowChange,
  dateWindow,
  latestDataTimestamp,
  isEmailAert,
  onAnnotationClick = () => {},
}: any) => {
  return (
    <div key="summary_chart" className="sixteen wide column" style={{ paddingTop: 0 }}>
      <div className="detail-charts" style={{ position: 'relative' }}>
        <h4 className="ui header">{summary.title}</h4>
        <DataChart
          enableTriangleHighlight={false}
          enableAnnotations
          data={summary}
          fullWide
          isEmailAert={isEmailAert}
          onDateWindowChange={onDateWindowChange}
          dateWindow={dateWindow}
          latestDataTimestamp={latestDataTimestamp}
          onAnnotationClick={onAnnotationClick}
        />
      </div>
    </div>
  );
};

export default DataSummaryChart;
