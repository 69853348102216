/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postProjectExportModel = (credentials: Credentials, params: Object) => {
  let { projectName } = params;

  const idx = projectName.indexOf('@');
  if (idx >= 0) {
    projectName = projectName.substr(0, idx);
  }

  return fetchPost(getEndpoint('exportspecialmodel'), {
    ...credentials,
    ...params,
    projectName,
    grouping: params.instanceGroup,
  }).then(d => {
    return d;
  });
};

export default postProjectExportModel;
