/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { updateLastActionInfo } from '../../app/actions';
import { getSystemGeneralInfo } from '../../apis';
import { getLoaderAction } from '../../utils';
import { ActionTypes } from '../actions';

const loadSystemGeneralInfoLogic = createLogic({
  type: [ActionTypes.LOAD_SYSTEM_GENERAL_INFO],
  cancelType: ActionTypes.APP_STOP,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { appVersion } = state.app;
    const { showLoader, hideLoader } = getLoaderAction(loader);

    dispatch(showLoader);
    dispatch(updateLastActionInfo());
    getSystemGeneralInfo(credentials, params)
      .then((data) => {
        dispatch({
          type: ActionTypes.SET_SYSTEM_GENERAL_INFO,
          payload: {
            params,
            data: { componentVersions: { ...data, ui: appVersion } },
          },
        });
      })
      .catch((err) => {
        console.log('[IF] Failed to get system general info', err);
      })
      .then(() => {
        dispatch(hideLoader);
        done();
      });
  },
});

export default loadSystemGeneralInfoLogic;
