/* @flow */

import { defineMessages } from 'react-intl';

const queryFieldMessages = defineMessages({
  ticketEvents: {
    defaultMessage: 'Ticket Events',
    id: 'query.field.ticketEvents',
  },
  ticketInsights: {
    defaultMessage: 'Ticket insights',
    id: 'query.field.ticketInsights',
  },
  ticketContent: {
    defaultMessage: 'Ticket content',
    id: 'query.field.ticketContent',
  },
  predictedImpact: {
    defaultMessage: 'Predicted Impact',
    id: 'query.field.predictedImpact',
  },
  patternTopKeywords: {
    defaultMessage: 'Pattern Top 5 Keywords',
    id: 'query.field.patternTopKeywords',
  },
  patternTopFeatureKeywords: {
    defaultMessage: 'Pattern Top 5 Feature Keywords',
    id: 'query.field.patternTopFeatureKeywords',
  },
  triageReport: {
    defaultMessage: 'Operator notes',
    id: 'query.field.triageReport',
  },
  trendAnalysis: {
    defaultMessage: 'Trend Analysis',
    id: 'query.field.trendAnalysis',
  },
  causalAnalysis: {
    defaultMessage: 'Causal Graph',
    id: 'query.field.causalAnalysis',
  },
  precedingEvents: {
    defaultMessage: 'Preceding events',
    id: 'query.field.precedingEvents',
  },
  trailingEvents: {
    defaultMessage: 'Trailing events',
    id: 'query.field.trailingEvents',
  },
  openQueryBox: {
    defaultMessage: 'Open Query Box',
    id: 'query.field.openQueryBox',
  },
  queryCategory: {
    defaultMessage: 'Category',
    id: 'query.field.queryCategory',
  },
  noResult: {
    defaultMessage: 'No result found.',
    id: 'query.field.noResult',
  },
  hideAttributingMetrics: {
    defaultMessage: 'Hide Attributing Metrics',
    id: 'query.field.hideAttributingMetrics',
  },
  showAttributingMetrics: {
    defaultMessage: 'Show Attributing Metrics',
    id: 'query.field.showAttributingMetrics',
  },
  maximumPredictionDuration: {
    defaultMessage: 'Maximum prediction duration is {day} days',
    id: 'query.field.maximumPredictionDuration',
  },
});

export default queryFieldMessages;
