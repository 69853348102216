/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getMetricEventList = (credentials: Credentials, params: Object) => {
  const { projectName, linkedProjectName, startTimestamp, endTimestamp, instanceName, relatedLogFileName } = params;

  return fetchGet(getEndpoint('linkedrawlogevents'), {
    ...credentials,
    projectName,
    linkedProjectName,
    instanceName,
    startTimeMillis: startTimestamp,
    endTimeMillis: endTimestamp,
    relatedLogFileName: JSON.stringify(relatedLogFileName),
  }).then(d => {
    const rawData = d;
    return {
      rawData,
      data: d.data,
    };
  });
};
export default getMetricEventList;
