/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import moment from 'moment';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postJiraProjects = (credentials: Credentials, params: Object) => {
  // Post the jira iussue.
  const promiseQuery = [
    fetchPost(
      getEndpoint('jiraPostEvent', 1),
      {
        ...credentials,
        ...params,
      },
      {},
      false,
    ).then((d) => {
      return d;
    }),
  ];

  return Promise.all(promiseQuery).then((results) => {
    const d = results[0] || {};
    return {
      d,
    };
  });
};
export default postJiraProjects;
