/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import { createLoadAction, updateLastActionInfo } from '../../app/actions';
import { ActionTypes } from '../actions';
import { getLoadStatusActions } from '../../utils';
import { deleteEventAction } from '../../apis';
import { appMessages } from '../../app/messages';

const removeEventActionLogic = createLogic({
  type: [ActionTypes.DELETE_EVENT_ACTION],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    const { actionParams, emailListParams } = params;
    const {
      projectName,
      projectNameList,
      neuronId,
      instanceName,
      eventType,
      needRecipients,
      rootCauseKey,
      startTime,
      endTime,
      systemId,
      customerName,
    } = actionParams;
    deleteEventAction(credentials, actionParams, emailListParams)
      .then(() => {
        dispatch(
          createLoadAction(
            ActionTypes.LOAD_EVENT_ACTION,
            {
              projectName,
              projectNameList,
              neuronId,
              operation: 'getAction',
              instanceName,
              eventType,
              needRecipients,
              rootCauseKey,
              startTime,
              endTime,
              systemId,
              customerName,
            },
            loader,
          ),
        );
      })
      .catch((err) => {
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default removeEventActionLogic;
