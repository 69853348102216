/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postProjectKeywordsSetting = (credentials: Credentials, params: Object) => {
  const { keywords, projectName, type, ...rest } = params;

  const requests = [];
  if (type === 'categorylist') {
    const categoryMap = {};
    R.forEach((item) => {
      const { category, keyword } = item;
      categoryMap[category] = keyword ? R.split(',', keyword || '') : [];
    }, keywords);
    requests.push(
      fetchPost(`${window.BASE_URL || ''}/logCategorySetting`, {
        ...credentials,
        projectName,
        categoryMap: JSON.stringify(categoryMap),
      }).then((data) => {
        return data;
      }),
    );
  } else {
    let newKeywords = R.values(keywords);
    if (type === 'incidentlist') {
      newKeywords = R.sortWith([R.ascend(R.prop('order'))])(newKeywords);
    }
    requests.push(
      fetchPost(getEndpoint('projectkeywords'), {
        ...credentials,
        ...rest,
        projectName,
        type: type === 'instanceNameLabels' ? 'instanceName' : type,
        keywords: JSON.stringify(newKeywords),
      }).then((data) => {
        return data;
      }),
    );
  }

  return Promise.all(requests).then((results) => {
    const data1 = results[0] || {};
    return data1;
  });
};

export default postProjectKeywordsSetting;
