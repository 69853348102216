import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import momenttz from 'moment-timezone';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { message, Button, Popover, Select } from 'antd';

import fetchPost from '../../common/apis/fetchPost';
import getEndpoint from '../../common/apis/getEndpoint';
import { buildUrl, Regex, parseLocation } from '../../common/utils';
import { hideAppLoader } from '../../common/app/actions';
import { Input } from '../../lib/fui/react';
import { CenterPage } from '../app/components';
import { appFieldsMessages, appButtonsMessages } from '../../common/app/messages';
import { authMessages } from '../../common/auth/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  hideAppLoader: Function,
  appLoaderVisible: Boolean,
};

class PromotionSignupCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const { code } = parseLocation(location);

    this.state = {
      isLoading: false,
      hasError: false,
      errorMessage: null,
      userNameFocus: false,
      passwordFocus: false,
      phoneFocus: false,

      promotionCode: code || '',
      fname: '',
      lname: '',
      companyName: '',
      phone: '',
      userName: '',
      email: '',
      pass1: '',
      pass2: '',
      timeZone: 'UTC',
    };
    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleSignUpConfirm(event) {
    event.preventDefault();

    const { intl } = this.props;
    const { userName, email, promotionCode, pass1, pass2, timeZone, fname, lname, companyName, phone } = this.state;
    this.setState({ isLoading: true });
    return fetchPost(getEndpoint('signup', 1), {
      'signup-code': promotionCode,
      UserName: userName,
      email,
      pass1,
      pass2,
      timeZone,
      fname,
      lname,
      companyName,
      phone,
    })
      .then((data) => {
        const { success, message: errorMessage } = data;
        if (success) {
          message.success(intl.formatMessage(authMessages.signupSuccess));
          this.setState({ isLoading: false });
          window.location.href = '/auth/login2';
        } else {
          message.error(intl.formatMessage(authMessages.signupFail));
          this.setState({ isLoading: false, hasError: true, errorMessage });
        }
      })
      .catch((err) => {
        if (err.code === 401) {
          // message.error(err.message);
          this.setState({ isLoading: false, hasError: true, errorMessage: String(err.message) });
        } else {
          message.error(intl.formatMessage(authMessages.signupFail));
          this.setState({ isLoading: false, hasError: true, errorMessage: String(err) });
        }
      });
  }

  render() {
    const { intl, push, currentTheme } = this.props;
    const { isLoading, hasError, errorMessage, timeZone } = this.state;

    const userNameLink = VLink.state(this, 'userName')
      .check((value) => value, intl.formatMessage(authMessages.errorsUserNameRequired))
      .check((value) => Regex.userName.test(value), intl.formatMessage(authMessages.errorsUserNameContain));
    const emailLink = VLink.state(this, 'email')
      .check((value) => value, intl.formatMessage(authMessages.errorsEmailRequired))
      .check((value) => Regex.email.test(value), intl.formatMessage(authMessages.errorsEmailIncorrect));
    const promotionCodeLink = VLink.state(this, 'promotionCode').check(
      (value) => value,
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const fnameLink = VLink.state(this, 'fname').check((value) => true);
    const lnameLink = VLink.state(this, 'lname').check((value) => true);
    const companyNameLink = VLink.state(this, 'companyName').check((value) => true);
    const phoneLink = VLink.state(this, 'phone').check(
      (value) => (value ? Regex.phone.test(value) : true),
      intl.formatMessage(authMessages.errorsPhoneIncorrect),
    );
    const password1Link = VLink.state(this, 'pass1')
      .check((value) => value, intl.formatMessage(authMessages.errorsPasswordRequired))
      .check((value) => value.length >= 8, intl.formatMessage(authMessages.errorsPasswordLength))
      .check((value) => value.match(/[0-9]/g), intl.formatMessage(authMessages.errorsPasswordNumber))
      .check((value) => value.match(/[A-Z]/g), intl.formatMessage(authMessages.errorsPasswordUppercase))
      .check((value) => value.match(/[a-z]/g), intl.formatMessage(authMessages.errorsPasswordLowercase))
      .check(
        (value) => value.match(/[~!@#$%^&*_+?:]/g),
        intl.formatMessage(authMessages.errorsPasswordSpecialCharacters),
      );
    const password2Link = VLink.state(this, 'pass2')
      .check((value) => value, intl.formatMessage(authMessages.errorsPasswordRequired))
      .check((value) => value === this.state.pass1, intl.formatMessage(authMessages.errorsPasswordNotMatch));

    const disabled =
      userNameLink.error ||
      emailLink.error ||
      promotionCodeLink.error ||
      password1Link.error ||
      password2Link.error ||
      password1Link.value !== password2Link.value ||
      !timeZone;

    return (
      <CenterPage intl={intl} push={push} className="auth" currentTheme={currentTheme}>
        <h3 style={{ textAlign: 'center' }}>{intl.formatMessage(appFieldsMessages.welcomeToInsightFinder)}</h3>
        <form className={`ui ${hasError ? 'error' : ''} form`}>
          {hasError && <div className="ui error message">{errorMessage}</div>}
          <div className="input inline field required">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.promotionCode)}</label>
            <Input valueLink={promotionCodeLink} style={{ width: 200 }} />
          </div>
          <div className="input inline field">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.fname)}</label>
            <Input valueLink={fnameLink} style={{ width: 200 }} />
          </div>
          <div className="input inline field">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.lname)}</label>
            <Input valueLink={lnameLink} style={{ width: 200 }} />
          </div>
          <div className="input inline field">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.companyName)}</label>
            <Input valueLink={companyNameLink} style={{ width: 200 }} />
          </div>
          <div className="input inline field">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.phoneNumber)}</label>
            <Popover
              visible={this.state.phoneFocus}
              placement="top"
              title={null}
              content={
                <div className="flex-col" style={{ maxWidth: 200 }}>
                  Please pick a phone number only contains numbers.
                </div>
              }
            >
              <Input
                valueLink={phoneLink}
                style={{ width: 200 }}
                onFocus={() => this.setState({ phoneFocus: true })}
                onBlur={() => this.setState({ phoneFocus: false })}
              />
            </Popover>
          </div>
          <div className="input inline field required">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.userName)}</label>
            <Popover
              visible={this.state.userNameFocus}
              placement="top"
              title="User name requirements"
              content={
                <div className="flex-col" style={{ maxWidth: 360 }}>
                  User name should only contain letters and numbers.
                </div>
              }
            >
              <Input
                valueLink={userNameLink}
                style={{ width: 200 }}
                onFocus={() => this.setState({ userNameFocus: true })}
                onBlur={() => this.setState({ userNameFocus: false })}
              />
            </Popover>
          </div>
          <div className="input inline field required">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.email)}</label>
            <Input valueLink={emailLink} style={{ width: 200 }} />
          </div>
          <div className="input inline field required">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.password)}</label>
            <Popover
              visible={this.state.passwordFocus}
              placement="top"
              title="Password requirements"
              content={
                <div className="flex-col" style={{ maxWidth: 360 }}>
                  <div>{'Length >=8.'}</div>
                  <div>Contains at least one lower case and one upper case.</div>
                  <div>Contains at least one number and one special symbol (Examples: ~!@#$%^&*_+?:).</div>
                </div>
              }
            >
              <Input
                type="password"
                valueLink={password1Link}
                style={{ width: 200 }}
                onFocus={() => this.setState({ passwordFocus: true })}
                onBlur={() => this.setState({ passwordFocus: false })}
              />
            </Popover>
          </div>
          <div className="input inline field required">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.confirmPassword)}</label>
            <Input type="password" valueLink={password2Link} style={{ width: 200 }} />
          </div>
          <div className="input inline field required flex-row flex-center-align">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.defaultTimezone)}</label>
            <div className="flex-grow">
              <Select
                style={{ width: '100%' }}
                showSearch
                filterOption
                options={this.timezoneOptions}
                value={timeZone}
                onChange={(timeZone) => this.setState({ timeZone })}
              />
            </div>
          </div>

          <div className="field flex-row">
            <div className="flex-grow">
              {intl.formatMessage(authMessages.haveAnAccount)}?{' '}
              <Button
                type="link"
                style={{ margin: 0, padding: 0 }}
                onClick={() => {
                  const { push } = this.props;
                  push(buildUrl('/auth/login2', {}, {}));
                }}
              >
                {intl.formatMessage(authMessages.login)}
              </Button>
            </div>
            <Button type="primary" loading={isLoading} disabled={disabled} onClick={this.handleSignUpConfirm}>
              {intl.formatMessage(appButtonsMessages.confirm)}
            </Button>
          </div>
        </form>
      </CenterPage>
    );
  }
}

const PromotionSignup = injectIntl(PromotionSignupCore);
export default connect(
  (state) => {
    const { location } = state.router;
    return {
      currentTheme: state.app.currentTheme,
      location,
      appLoaderVisible: state.app.appLoaderVisible,
    };
  },
  { push, replace, hideAppLoader },
)(PromotionSignup);
