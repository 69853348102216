import * as R from 'ramda';
import { get } from 'lodash';

import { metricUnitParser } from '../magicParsers';
import { CausalParser, parseJSON } from '../../utils';

const parseCausalIncident = ({ operation, data, mapping, kpiPrediction, kpi, ...rest }) => {
  let relations = data || [];
  const relationElemInfoMap = {};
  const kpiThresholdMap = kpi || {};
  const maxProbabilityCountMap = {};

  const getTypeCategory = (type, isAlert) => {
    let category;
    if (isAlert) {
      category = 'alert';
    } else if (type === 'Metric') {
      category = 'metric';
    } else if (type === 'Incident') {
      category = 'incident';
    } else {
      category = 'log';
    }
    return category;
  };

  // parse relation:
  relations = R.map((relation) => {
    let { to: toContents, from: fromContents } = relation;
    const { timeDifference, labelObj, count, probability, src, target, elem1, elem2, probabilityCountChoice } =
      relation;
    if (labelObj) {
      fromContents = get(labelObj, 'from', []);
      toContents = get(labelObj, 'to', []);
    }
    const contentTimeDifference = R.map((item) => {
      const { s, t, tP, delay, probability } = item;

      const { type: fType, isAlert: fisAlert } = fromContents[s] || {};
      const { type: tType, isAlert: tisAlert } = toContents[t] || {};
      // build modality => incident > log > metric
      const modality = R.join(
        '-',
        R.sort((a, b) => a.localeCompare(b), [getTypeCategory(fType, fisAlert), getTypeCategory(tType, tisAlert)]),
      );

      return { sP: s, tP: t, timePair: tP, probability, delay, modality };
    }, timeDifference);

    // get content info
    R.forEach((content) => {
      if (content.type === 'Metric') {
        if (operation !== 'intra' && content.content.indexOf(elem1) === 0) {
          content.content = content.content.substring(elem1.length + 1);
        }
      } else if (content.type === 'Incident') {
        content.content = content.rawData || content.content;
      }
    }, fromContents || []);
    R.forEach((content) => {
      if (content.type === 'Metric') {
        if (operation !== 'intra' && content.content.indexOf(elem2) === 0) {
          content.content = content.content.substring(elem2.length + 1);
        }
      } else if (content.type === 'Incident') {
        content.content = content.rawData || content.content;
      }
    }, toContents || []);

    const fromElem = src || elem1;
    const toElem = target || elem2;
    let elem1Info = {};
    let elem2Info = {};
    if (operation === 'intra') {
      elem1Info = CausalParser.getRelationLogType(fromElem, fromContents);
      elem2Info = CausalParser.getRelationLogType(toElem, toContents);
      if (!R.has(fromElem, relationElemInfoMap)) relationElemInfoMap[fromElem] = elem1Info;
      if (!R.has(toElem, relationElemInfoMap)) relationElemInfoMap[toElem] = elem2Info;
    }

    // get max count of probability
    const probabilityCountMap = parseJSON(probabilityCountChoice) || {};
    R.forEachObjIndexed((maxCount, probability) => {
      if (!R.has(probability, maxProbabilityCountMap)) {
        maxProbabilityCountMap[probability] = maxCount;
      } else {
        maxProbabilityCountMap[probability] = R.max(maxCount, maxProbabilityCountMap[probability]);
      }
    }, probabilityCountMap);

    return {
      contentTimeDifference,
      toContents,
      fromContents,
      count,
      probability,
      // change incident node content if from/to list have any incident relation
      elem1: fromElem,
      elem2: toElem,
      elem1Info,
      elem2Info,
      probabilityCountMap,
    };
  }, relations);

  // filter relations if elem1 === elem2
  relations = R.filter((relation) => relation.elem1 !== relation.elem2, relations);

  // Get the metric name to metric short name and unit mapping.
  const metricUnitMap = {};
  const metricShortNameMap = {};
  R.forEach((m) => {
    const { unit, shortName, metric } = m;
    metricUnitMap[metric] = metricUnitParser(unit);
    metricShortNameMap[metric] = shortName;
  }, mapping || []);

  return {
    relation: relations,
    relationElemInfoMap,
    maxProbabilityCountMap,
    metricUnitMap,
    metricShortNameMap,
    kpiPrediction,
    kpiThresholdMap,
    ...rest,
  };
};

export default parseCausalIncident;
