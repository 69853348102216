/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import VLink from 'valuelink';
import * as R from 'ramda';
import { isInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { RightOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox } from 'antd';

import { Input, Select } from '../../../../lib/fui/react';
import { projectWizardMessages, settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  componentState: Object,
};

class ShadowProjectSetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;
    this.state = {
      instanceType: '',
      dataType: 'Metric',
      samplingInterval: 5,
      samplingUnit: 60,
      insightAgentType: '',
      verified: true,
      isGroupingByInstance: false,

      ...(componentState || {}),
    };
    this.metricAgentOptions = [
      { label: 'Live Streaming', value: 'Custom' },
      { label: 'Metric File Replay', value: 'MetricFile' },
      { label: 'Live Streaming Container', value: 'containerStreaming' },
      { label: 'File Replay Container', value: 'containerReplay' },
    ];
    this.logAgentOptions = [
      { label: 'Live Streaming', value: 'Custom' },
      { label: 'Log File Replay', value: 'LogFile' },
      { label: 'Live Streaming Container', value: 'ContainerCustom' },
      { label: 'File Replay Container', value: 'ContainerHistorical' },
    ];
    this.samplingUnit = [
      { label: 'second', value: 1 },
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
  }

  componentDidMount() {
    this.props.saveProjectInfo('ShadowProject', { dataType: this.state.dataType }, this.state);
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { instanceType, dataType, samplingInterval, samplingUnit, insightAgentType, isGroupingByInstance } =
      this.state;
    const isMetric = dataType === 'Metric';
    this.props.createProject(
      'ShadowProject',
      {
        instanceType,
        dataType,
        samplingInterval: Number(samplingInterval) * samplingUnit,
        insightAgentType,
        ...(isMetric ? {} : { isGroupingByInstance }),
      },
      this.state,
    );
  }

  @autobind
  handleDataTypeChange(dataType) {
    let samplingInterval = 5;
    const samplingUnit = 60;
    if (dataType !== 'Metric') {
      samplingInterval = 10;
    }
    this.setState({ samplingInterval, samplingUnit, insightAgentType: null, isGroupingByInstance: false }, () => {
      this.props.saveProjectInfo('Datadog', { dataType }, this.state);
    });
  }

  render() {
    const { intl, isLoading } = this.props;
    const { dataType, isGroupingByInstance } = this.state;

    const isMetricAgent = dataType === 'Metric';

    let agentOptions = [];
    if (isMetricAgent) {
      agentOptions = this.metricAgentOptions;
    } else {
      agentOptions = this.logAgentOptions;
    }

    const instanceTypeLink = VLink.state(this, 'instanceType').check(
      (x) => Boolean(x),
      'The instance type is required',
    );
    const dataTypeLink = VLink.state(this, 'dataType')
      .check((x) => Boolean(x), 'The data type is required')
      .onChange(this.handleDataTypeChange);
    const insightAgentTypeLink = VLink.state(this, 'insightAgentType').check(
      (x) => Boolean(x),
      'The agent type is needed',
    );
    const samplingIntervalLink = VLink.state(this, 'samplingInterval').check((x) => {
      return Number(x) > 0 && isInteger(Number(x));
    }, 'The sampling interal is required and it has to be an integer');
    const samplingUnitLink = VLink.state(this, 'samplingUnit').check((x) => x, 'The sampling unit is required');

    let hasError = dataTypeLink.error || instanceTypeLink.error || insightAgentTypeLink.error || this.props.hasError;
    if (!hasError) {
      if (isMetricAgent) {
        hasError = samplingIntervalLink.error || samplingUnitLink.error;
      }
    }

    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.shadowProject),
          }}
        />

        <Card className="ui form">
          <div className="field">
            <label>Instance Type</label>
            <Select valueLink={instanceTypeLink} options={[{ label: 'Private Cloud', value: 'PrivateCloud' }]} />
          </div>
          <div className="field">
            <label>Data Type</label>
            <Select
              valueLink={dataTypeLink}
              options={[
                { label: intl.formatMessage(settingsMessages.Metric), value: 'Metric' },
                { label: intl.formatMessage(settingsMessages.Log), value: 'Log' },
                { label: intl.formatMessage(settingsMessages.Alert), value: 'Alert' },
                { label: intl.formatMessage(settingsMessages.Incident), value: 'Incident' },
                { label: intl.formatMessage(settingsMessages.Deployment), value: 'Deployment' },
              ]}
            />
          </div>
          <div className="field">
            <label>Agent Type</label>
            <Select valueLink={insightAgentTypeLink} options={agentOptions} />
          </div>
          <div className="field">
            <label>{isMetricAgent ? 'Sampling interval' : 'Hot/Cold event sampling interval'}</label>
            <span className="hint">
              <i className="info icon" />
              The sampling interval just needs an estimation. InsightFinder figures out the sampling rate dynamically
              from your data.
            </span>
            <div className="flex-row">
              <Input
                type="number"
                min={isMetricAgent ? 1 : 10}
                valueLink={samplingIntervalLink}
                style={{ width: 200 }}
              />
              <Select
                valueLink={samplingUnitLink}
                style={{ width: 130, padding: '2px 0' }}
                options={isMetricAgent ? this.samplingUnit : R.filter((item) => item.value !== 1, this.samplingUnit)}
              />
            </div>
          </div>
          {!isMetricAgent && (
            <div>
              <div>{intl.formatMessage(settingsMessages.groupingByInstance)}</div>
              <Checkbox
                checked={isGroupingByInstance}
                onChange={(e) => this.setState({ isGroupingByInstance: e.target.checked })}
              />
            </div>
          )}
        </Card>

        <div style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}>
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasError}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

export default ShadowProjectSetting;
