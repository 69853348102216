import React, { useContext, useEffect, useReducer } from 'react';
import * as R from 'ramda';

import { Tag } from 'antd';
import { Popover } from '../../../lib/fui/react';

import { appFieldsMessages } from '../../../common/app/messages';

export default function BuildCausesCategoriesInfo({ list, style, type, echartRef, optionAll, intl }: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    newList: [],
  });
  const { newList } = state;

  useEffect(() => {
    setState({
      newList: R.take(
        4,
        R.map((item) => ({ ...item, count: item.count, loading: false }), list || []),
      ),
    });
  }, [list]);

  const mouseLinkageCharts = ({ item, mouse }) => {
    if (echartRef) {
      const detailKey = `${item.categoryName}-${item.count}`;
      const { data } = optionAll?.series[0] || [];
      const dataIndex = R.findIndex((_item) => `${_item?.categoryName}-${_item?.count}` === detailKey, data);
      const echartsInstance = echartRef.getEchartsInstance();
      echartsInstance.dispatchAction({ type: mouse === 'over' ? 'highlight' : 'downplay', seriesIndex: 0, dataIndex });
    }
  };

  return (
    <>
      {newList.length > 0 && (
        <div style={{ overflow: 'hidden auto', flex: 1 }}>
          <div className="chart-info-wrap" style={{ ...style, marginLeft: 14 }}>
            <div className="flex-row flex-space-between" style={{ marginBottom: 10, fontSize: 15, lineHeight: '15px' }}>
              <div style={{ fontWeight: 'bold' }}>Cause categories</div>
            </div>
            {R.addIndex(R.map)((item, index) => {
              return (
                <div
                  className="chart-info-group flex-row flex-center-align flex-space-between clickable"
                  key={`${type}-${item.instanceName}-${item.patternId}-${index}`}
                >
                  <Popover
                    title={null}
                    overlayClassName="popover-set-loading-width"
                    overlayStyle={{ maxWidth: 380 }}
                    placement="top"
                    content={
                      <div className="formatter-wrap">
                        <div>
                          <span className="formatter-lable" style={{ width: 140 }}>
                            Cause categories name:
                          </span>
                          <span className="formatter-content">{item.categoryName}</span>
                        </div>
                        <div>
                          <span className="formatter-lable" style={{ width: 140 }}>
                            {intl.formatMessage(appFieldsMessages.count)}:
                          </span>
                          <span className="formatter-content">{item.count}</span>
                        </div>
                      </div>
                    }
                    onVisibleChange={(visible) => mouseLinkageCharts({ item, mouse: visible ? 'over' : 'out' })}
                  >
                    <div className="flex-row flex-center-align">
                      <span
                        className="hidden-line-with-ellipsis-multiline"
                        style={{ wordBreak: 'break-word', padding: '0 8px 0 0', width: 'fit-content' }}
                      >
                        {item.categoryName}
                      </span>
                    </div>
                  </Popover>
                  <Tag size="small" style={{ borderRadius: 100, marginLeft: 4, fontWeight: 'bold', flexShrink: 0 }}>
                    {item.count}
                  </Tag>
                </div>
              );
            }, newList)}
          </div>
        </div>
      )}
      {newList.length < 1 && (
        <div className="insights-no-data" style={{ color: 'var(--text-color-secondary)' }}>
          No data for this time period
        </div>
      )}
    </>
  );
}
