/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { get, isNumber, round, map } from 'lodash';
import { push, replace } from 'react-router-redux';
import { NavLink } from 'react-router-dom';
import { autobind } from 'core-decorators';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Popconfirm, Input, Select, Button, Spin, Switch, Tabs, message, Menu, Checkbox, Radio } from 'antd';

import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import {
  Modal,
  Container,
  Table,
  Column,
  AutoSizer,
  List,
  Dropdown,
  Popover,
  SortDirection,
} from '../../../lib/fui/react';
import { BaseUrls } from '../../app/Constants';
import { parseLocation, buildLocation, getLoadStatus, Defaults, buildUrl } from '../../../common/utils';
import { ActionTypes } from '../../../common/settings/actions';
import {
  hideAppLoader,
  showAppAlert,
  showAppLoader,
  updateLastActionInfo,
  createLoadAction,
  ActionTypes as AppActionTypes,
} from '../../../common/app/actions';
import { State } from '../../../common/types';

import { appMessages, appFieldsMessages, appButtonsMessages, appMenusMessages } from '../../../common/app/messages';
import { settingsMenusMessages, settingsMessages } from '../../../common/settings/messages';
import { eventMessages } from '../../../common/metric/messages';

import { NewSystemModal, SystemEditModal, SystemGeneralSetting, SystemSharelSetting } from './components';
import SystemEmailSetting from './components/SystemEmailSetting';
import SystemScoreSetting from './components/SystemScoreSetting';
import KnowledgeBaseSetting from './components/KnowledgeBaseSetting';
import {
  DangerIcon,
  DocumentShredderIcon,
  MoreIcon,
  MoveToTrashIcon,
  ReturnToResetIcon,
  TrashIcon,
} from '../../../lib/fui/icons';
import KubernetesSetting from './components/KubernetesSetting';
import KubernetesEndpoint from './components/KubernetesEndpoint';
import SystemMaintenanceDay from './components/SystemMaintenanceDay';
import IncidentValidationSetting from './components/IncidentValidationSetting';
import AgentSetting from './components/AgentSetting';
import ZoneSecreteJWTRender from './components/ZoneSecreteJWTRender';

type Props = {
  intl: Object,
  location: Object,
  userInfo: Object,
  credentials: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  systemsMap: Object,
  projects: Array<Object>,
  allProjects: Array<Object>,
  push: Function,
  replace: Function,
  // eslint-disable-next-line
  loaderStatus: Object,
  loadStatus: Object,
  hideAppLoader: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  showAppLoader: Function,
  updateLastActionInfo: Function,
  createLoadAction: Function,
  userList: Array<Object>,
};

class SystemSettingCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.submitLoadingKey = 'settings_project_list_remove';

    const { location, isAdmin, isLocalAdmin, userInfo } = props;
    const params = parseLocation(location);
    const { systemSearch, projectSearch, projectOwner, activeSystemTab, customerName } = params;

    this.state = {
      isLoading: false,
      isLoadingDetails: false,
      isSubmitting: false,

      systemList: [],
      projectList: [],
      systemSearch: systemSearch || '',
      projectSearch: projectSearch || '',
      projectOwner: isAdmin || isLocalAdmin ? customerName : userInfo.userName,
      showTrashcan: true,

      activeSystemTab: activeSystemTab || 'systemComposition',

      showNewSystemModal: false,
      showSystemEditModal: false,
      incident: null,
      deleteSystemValue: 'all',

      projectNameSearch: '',

      sortBy: null,
      sortDirection: null,

      ordinaryOwner: '',
      hideTabs: false,
    };
    this.projectList = [];
    this.userListOptions = [];

    this.intervalRenderer = ({ cellData, rowData }) => {
      if (!cellData) {
        return 'NA';
      } else if (parseInt(cellData, 10) === cellData) {
        if (cellData / 60 > 1) {
          return `${cellData / 60} hours`;
        }
        return `${cellData} mins`;
      } else if (parseFloat(cellData) === cellData) {
        return `${(cellData * 60).toFixed(0)}s`;
      }
      return 'NA';
    };
    this.timeRenderer = ({ cellData }) => (cellData ? moment(cellData).format('YYYY-MM-DD HH:mm') : '');
  }

  componentDidMount() {
    const { hideAppLoader, allProjects } = this.props;
    hideAppLoader();
    // if (allProjects.length === 0) {
    //   showAppAlert('info', settingsMessages.alertNoProject);
    //   push(BaseUrls.SettingsProjectWizard);
    // } else {
    //   this.parseData(this.props);
    // }
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.systemsMap !== this.props.systemsMap || nextProps.allProjects !== this.props.allProjects) {
      this.parseData(nextProps);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        this.projectList = R.sortWith([R.ascend(R.prop(sortBy))])(this.projectList);
        if (sortDirection === SortDirection.DESC) {
          this.projectList = R.sortWith([R.descend(R.prop(sortBy))])(this.projectList);
        }
      }
    }
  }

  @autobind
  parseData(props) {
    // parse system list
    const { projectSearch, ordinaryOwner } = this.state;
    const { systemList, systemSearch: pareSystemSearch } = this.parseSystemList(props);
    const userList = props?.userList || [];
    this.setState(
      {
        isLoading: true,
        isCheckedAll: false,
        systemList,
        systemSearch: ordinaryOwner || projectSearch ? null : pareSystemSearch,
      },
      () => {
        if (this.listNode) this.listNode.forceUpdateGrid();

        const { location, replace, allProjects } = this.props;
        const params = parseLocation(location);

        let projectList = allProjects || [];
        // filter projects
        projectList = this.filterProject(projectList);
        // sort
        projectList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('projectName')))], projectList);

        let filterSystemList = systemList;
        if (ordinaryOwner) {
          filterSystemList = R.filter((s) => R.toLower(s.owner).indexOf(ordinaryOwner || '') !== -1, filterSystemList);
        }
        if (projectSearch) {
          filterSystemList = R.filter((s) => R.find((p) => p.systemId === s.systemId, projectList), filterSystemList);
        }

        let { systemSearch } = params;
        const hasFindS = !!R.find((s) => s.systemId === params.systemSearch, filterSystemList || []);
        if (!hasFindS) systemSearch = filterSystemList[0]?.systemId || null;

        this.userListOptions = R.map(
          (userName) => ({ value: userName, label: userName }),
          R.sort((a, b) => a.localeCompare(b), R.uniq(R.map((item) => item.userName, userList || []))),
        );

        if (ordinaryOwner) {
          projectList = R.filter((p) => {
            return systemSearch === 'emptySystem' ? !p.systemId : p.systemId === systemSearch;
          }, projectList || []);
        }

        const hasKubernetes = !!R.find((p) => R.includes('Kubernetes', p?.cloudType || ''), projectList || []);
        this.projectList = R.clone(projectList);
        replace(buildLocation(location.pathname, {}, { ...params, systemSearch }));

        const findSystemIdx = R.findIndex((s) => s.systemId === systemSearch, filterSystemList || []);
        if (this.listNode) this.listNode.scrollToRow(findSystemIdx);

        this.setState(
          {
            isLoading: false,
            projectList,
            systemList: filterSystemList,
            ...(ordinaryOwner ? { systemSearch } : {}),
            activeSystemTab: this.getActiveSystemTab(hasKubernetes),
          },
          () => {
            // load projects meta data info
            this.reloadProjectDetails(props);
          },
        );
      },
    );
  }

  @autobind
  reloadProjectDetails(props) {
    const { createLoadAction } = props;
    const { projectList } = this.state;

    // Take the projects in the table and load full info and stats.
    const projectNameList = R.map(
      (project) => project.projectName,
      R.filter((project) => !project.hasAllInfo, projectList),
    );
    if (projectNameList.length > 0 && false) {
      this.setState({ isLoadingDetails: true });
      createLoadAction(
        AppActionTypes.LOAD_PROJECT_INFO,
        {
          projectName: projectNameList,
          withStats: true,
        },
        false,
        false,
        this.callbackHandle,
      );
    }
  }

  @autobind
  callbackHandle() {
    this.setState({ isLoadingDetails: false });
  }

  @autobind
  parseSystemList(props) {
    const { intl, userInfo, systemsMap, isAdmin, userList } = props;
    const { projectOwner } = this.state;
    let { systemSearch } = this.state;
    let systemList = R.values(systemsMap);
    if (projectOwner) {
      const allCompanyUsers = {};
      allCompanyUsers[projectOwner] = true;
      if (isAdmin) {
        const localAdmin = R.find((u) => u.userName === projectOwner, userList);
        if (localAdmin?.role === 'LocalAdmin') {
          const { company } = localAdmin;
          R.forEach((u) => {
            if (u.company === company) {
              allCompanyUsers[u.userName] = true;
            }
          }, userList);
        }
      }
      const isManager = userInfo.isAdmin || userInfo.isLocalAdmin;
      if (isManager) {
        systemList = R.filter((item) => allCompanyUsers[item.owner], systemList);
      } else {
        systemList = R.filter((item) => allCompanyUsers[item.owner] || item.isShared, systemList);
      }
    }
    systemList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('systemName')))], systemList);
    systemList.push({
      systemId: 'emptySystem',
      systemName: intl.formatMessage(settingsMessages.projectsNoSystem),
      environmentName: 'All',
      owner: projectOwner || userInfo.userName,
    });
    const systemIds = R.map((item) => item.systemId, systemList);
    if (!systemSearch || !systemIds.includes(systemSearch)) {
      systemSearch = systemList.length > 0 ? systemList[0].systemId : null;
    }
    return { systemList, systemSearch };
  }

  @autobind
  filterProject(projects, globalSearch = false) {
    const { projectOwner, systemSearch, projectSearch } = this.state;
    const { systemsMap, isAdmin, isLocalAdmin } = this.props;

    let projectList = projects;
    // filter by owner
    if (projectOwner && !isAdmin && !isLocalAdmin) {
      projectList = R.filter((project) => {
        return project.owner === projectOwner || project.isShared;
      }, projectList);
    }
    // filter by select system with system id
    if ((systemSearch && !globalSearch) || (systemSearch && !projectSearch)) {
      projectList = R.filter((project) => {
        return systemSearch === 'emptySystem' ? !project.systemId : project.systemId === systemSearch;
      }, projectList);
    }

    // filter by project name
    if (projectSearch) {
      projectList = R.filter((project) => {
        return (
          R.toLower(project.projectDisplayName).indexOf(projectSearch) !== -1 ||
          R.toLower(project.projectName).indexOf(projectSearch) !== -1
        );
      }, projectList);
    }
    return projectList;
  }

  @autobind
  handleRefreshClick() {
    window.location.reload();
  }

  @autobind
  onChangeFilterOwner(projectOwner) {
    const { showAppLoader, location } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    this.setState({ isLoading: true }, () => {
      const params = parseLocation(location);
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { ...params, projectOwner, customerName: projectOwner, systemSearch: undefined },
      );
      window.location.href = pathname + search;
    });
  }

  @autobind
  getActiveSystemTab(hasKubernetes, resetSystemComposition) {
    const { systemsMap } = this.props;
    const { activeSystemTab, systemSearch } = this.state;
    const systemInfo = systemsMap[systemSearch] || {};
    if (activeSystemTab === 'autoScalingSetting' && !hasKubernetes) {
      return 'systemComposition';
    } else if (activeSystemTab === 'kubernetesEndpoint' && !systemInfo?.kubeSystem) {
      return 'systemComposition';
    } else if (resetSystemComposition) {
      return 'systemComposition';
    }
    return activeSystemTab;
  }

  @autobind
  handleSystemClick(rowData) {
    const { systemsMap } = this.props;
    let { activeSystemTab } = this.state;
    const { systemId: systemSearch } = rowData;
    const systemInfo = systemsMap[systemSearch] || {};
    activeSystemTab = systemSearch === 'emptySystem' || systemInfo.isShared ? 'systemComposition' : activeSystemTab;

    this.setState({ isLoading: true, activeSystemTab, systemSearch }, () => {
      if (this.listNode) this.listNode.forceUpdateGrid();

      const { location, replace, allProjects } = this.props;
      const params = parseLocation(location);
      replace(buildLocation(location.pathname, {}, { ...params, systemSearch, activeSystemTab }));

      let projectList = allProjects || [];
      // filter projects
      projectList = this.filterProject(projectList);
      // sort
      projectList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('projectName')))], projectList);

      const hasKubernetes = !!R.find((p) => R.includes('Kubernetes', p?.cloudType || ''), projectList || []);
      this.projectList = R.clone(projectList);
      this.setState({ isLoading: false, projectList, activeSystemTab: this.getActiveSystemTab(hasKubernetes) }, () => {
        // load projects meta data info
        this.reloadProjectDetails(this.props);
      });
    });
  }

  @autobind
  handleSearchOrdinaryOwner({ field, value, event, isSearch }) {
    if (isSearch || event.type !== 'change') {
      const fieldValue = value ? R.toLower(value) : value;
      const { systemList } = this.parseSystemList(this.props);

      this.setState(
        { isLoading: true, [field]: fieldValue, systemList, systemSearch: null, projectSearch: '', hideTabs: false },
        () => {
          if (this.listNode) this.listNode.forceUpdateGrid();

          const { location, replace, allProjects } = this.props;
          const params = parseLocation(location);

          let projectList = allProjects || [];
          // filter projects
          projectList = this.filterProject(projectList, true);
          // sort
          projectList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('projectName')))], projectList);

          let filterSystemList = systemList;
          if (fieldValue) {
            filterSystemList = R.filter((s) => R.toLower(s.owner).indexOf(fieldValue || '') !== -1, filterSystemList);
          }

          let { systemSearch } = params;
          const hasFindS = !!R.find((s) => s.systemId === params.systemSearch, filterSystemList || []);
          if (!hasFindS) systemSearch = filterSystemList[0]?.systemId || null;

          projectList = R.filter((p) => {
            return systemSearch === 'emptySystem' ? !p.systemId : p.systemId === systemSearch;
          }, projectList || []);

          const hasKubernetes = !!R.find((p) => R.includes('Kubernetes', p?.cloudType || ''), projectList || []);
          this.projectList = R.clone(projectList);
          replace(
            buildLocation(
              location.pathname,
              {},
              {
                ...params,
                [field]: fieldValue,
                systemSearch,
                projectSearch: undefined,
                activeSystemTab: 'systemComposition',
              },
            ),
          );

          this.setState(
            {
              isLoading: false,
              projectList,
              systemList: filterSystemList,
              systemSearch,
              activeSystemTab: this.getActiveSystemTab(hasKubernetes, true),
            },
            () => {
              // load projects meta data info
              this.reloadProjectDetails(this.props);
            },
          );
        },
      );
    } else {
      const {
        target: { value },
      } = event;
      const fieldValue = value ? R.toLower(value) : value;
      this.setState({ [field]: fieldValue });
    }
  }

  @autobind
  handleSearchProject({ field, value, event, isSearch }) {
    if (isSearch || event.type !== 'change') {
      const fieldValue = value ? R.toLower(value) : value;
      const { systemList, systemSearch } = this.parseSystemList(this.props);

      this.setState(
        {
          isLoading: true,
          [field]: fieldValue,
          systemList,
          systemSearch: fieldValue ? null : systemSearch,
          ordinaryOwner: '',
          hideTabs: !!fieldValue,
        },
        () => {
          if (this.listNode) this.listNode.forceUpdateGrid();

          const { location, replace, allProjects } = this.props;
          const params = parseLocation(location);
          replace(
            buildLocation(
              location.pathname,
              {},
              {
                ...params,
                [field]: fieldValue,
                systemSearch,
                ordinaryOwner: undefined,
                activeSystemTab: 'systemComposition',
              },
            ),
          );

          let projectList = allProjects || [];
          // filter projects
          projectList = this.filterProject(projectList, true);
          // sort
          projectList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('projectName')))], projectList);

          let filterSystemList = systemList;
          if (fieldValue) {
            filterSystemList = R.filter((s) => R.find((p) => p.systemId === s.systemId, projectList), systemList);
          }

          const hasKubernetes = !!R.find((p) => R.includes('Kubernetes', p?.cloudType || ''), projectList || []);
          this.projectList = R.clone(projectList);
          this.setState(
            {
              isLoading: false,
              projectList,
              systemList: filterSystemList,
              activeSystemTab: this.getActiveSystemTab(hasKubernetes, true),
            },
            () => {
              // load projects meta data info
              this.reloadProjectDetails(this.props);
            },
          );
        },
      );
    } else {
      const {
        target: { value },
      } = event;
      const fieldValue = value ? R.toLower(value) : value;
      this.setState({ [field]: fieldValue });
    }
  }

  @autobind
  handleSystemTabClick(activeSystemTab) {
    this.setState({ activeSystemTab }, () => {
      const { location, replace } = this.props;
      const params = parseLocation(location);
      replace(buildLocation(location.pathname, {}, { ...params, activeSystemTab }));
    });
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }) {
    const { intl, userInfo, isReadUser } = this.props;
    const { systemList, systemSearch, deleteSystemValue } = this.state;

    const rowData = systemList[rowIndex];
    if (!rowData) return null;

    const { systemId, systemName, owner } = rowData;
    const active = systemSearch === systemId;
    return (
      <div
        key={systemId}
        className={`event-list-row ${active ? 'active' : ''}${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
        style={{ ...style, minHeight: 40, cursor: 'pointer' }}
        onClick={() => this.handleSystemClick(rowData)}
      >
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          <Popover content={`${systemName}${owner ? ` (${owner})` : ''}`} placement="right" mouseEnterDelay={0.3}>
            <div className="hidden-line-with-ellipsis" style={{ display: 'inline-block', maxWidth: '100%' }}>
              {`${systemName}${owner ? ` (${owner})` : ''}`}
            </div>
          </Popover>
        </div>
        <div className="row-column flex-end-justify" style={{ width: 60 }}>
          {systemId !== 'emptySystem' &&
            (userInfo.userName === owner || userInfo.isAdmin || userInfo.isLocalAdmin) &&
            !isReadUser && (
              <EditOutlined
                style={{ fontSize: 16, margin: '0 4px' }}
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleSystemEditClick(rowData);
                }}
              />
            )}

          {systemId !== 'emptySystem' &&
            (userInfo.userName === owner || userInfo.isAdmin || userInfo.isLocalAdmin) &&
            !isReadUser && (
              <Popconfirm
                placement="topRight"
                icon={null}
                title={
                  <div>
                    <Radio.Group
                      onChange={({ target: { value } }) => {
                        this.setState({ deleteSystemValue: value });
                      }}
                      value={deleteSystemValue}
                      className="flex-col flex-center-justify"
                      style={{ alignItems: 'start' }}
                    >
                      <Radio value="all">
                        {intl.formatMessage(eventMessages.deleteAllProjects, {
                          systemName: <span style={{ fontWeight: 'bold', fontSize: 14 }}>{systemName}</span>,
                        })}
                      </Radio>
                      <Radio value="only">
                        {intl.formatMessage(eventMessages.deleteBayerSystemNameOnly, {
                          systemName: <span style={{ fontWeight: 'bold', fontSize: 14 }}>{systemName}</span>,
                        })}
                      </Radio>
                    </Radio.Group>
                  </div>
                }
                okText={intl.formatMessage(appButtonsMessages.submit)}
                cancelText={intl.formatMessage(appButtonsMessages.cancel)}
                onConfirm={(event) => {
                  event.stopPropagation();
                  this.handleSystemRemoveClick(rowData);
                }}
                onCancel={(event) => {
                  event.stopPropagation();
                }}
              >
                <DeleteOutlined
                  style={{ fontSize: 16, margin: '0 4px' }}
                  onClick={(event) => event.stopPropagation()}
                />
              </Popconfirm>
            )}
        </div>
      </div>
    );
  }

  @autobind
  handleNewSystemClick() {
    this.setState({ showNewSystemModal: true });
  }

  @autobind
  handleNewSystemClose(reload) {
    this.setState({ showNewSystemModal: false });
    if (reload) {
      this.setState({ isSubmitting: true });
      this.handleRefreshClick();
    }
  }

  @autobind
  handleSystemEditClick(rowData) {
    this.setState({ showSystemEditModal: true, incident: rowData });
  }

  @autobind
  handleSystemEditClose(reload) {
    this.setState({ showSystemEditModal: false, incident: null });
    if (reload) {
      this.setState({ isSubmitting: true });
      this.handleRefreshClick();
    }
  }

  @autobind
  handleSystemRemoveClick(rowData) {
    const { intl, credentials, userInfo } = this.props;
    const { deleteSystemValue } = this.state;
    const { environmentName, systemId, owner } = rowData;
    const sessionToken = userInfo?.sessionToken;
    const systemInfoStr = JSON.stringify({ userName: owner, systemName: systemId, environmentName });
    const systemInfoHashed = CryptoJS.HmacMD5(systemInfoStr, sessionToken).toString();
    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchDelete(
      getEndpoint('systemframework', 2),
      {
        ...credentials,
        systemKey: systemInfoStr,
        deleteAllProjects: deleteSystemValue === 'all',
        'systemKey-hashed': systemInfoHashed,
      },
      {},
      false,
    )
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.handleRefreshClick();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  handleProjectClick({ event, rowData }) {
    const { push, isReadUser, location } = this.props;
    const { systemSearch, projectSearch, projectOwner } = this.state;
    const query = parseLocation(location);

    const { projectName } = rowData;
    if (!isReadUser) {
      push(
        buildLocation(
          BaseUrls.SettingsProject,
          {
            projectName,
          },
          { systemSearch, projectSearch, projectOwner, customerName: query?.customerName },
        ),
      );
    }
  }

  @autobind
  checkAllHeaderRender(fieldName) {
    return () => {
      return (
        <div className="flex-col flex-center-align">
          <Checkbox
            size="small"
            checked={this.state[fieldName] || false}
            onChange={this.handleAllCheckedChange(fieldName)}
          />
        </div>
      );
    };
  }

  @autobind
  handleAllCheckedChange(fieldName) {
    return (e) => {
      const { projectNameSearch } = this.state;
      let localProjectList = this.projectList;
      const { checked } = e.target;
      if (projectNameSearch) {
        localProjectList = R.filter(
          (event) => R.toLower(event.projectName).includes(R.toLower(projectNameSearch)),
          localProjectList,
        );
      }
      R.forEach((m) => {
        if (fieldName === 'isCheckedAll') {
          m.checked = checked;
        }
      }, localProjectList);
      this.setState({ [fieldName]: checked }, () => {
        this.tableNode.forceUpdateGrid();
        this.forceUpdate();
      });
    };
  }

  @autobind
  handleInputChanged(rowData, dataKey) {
    return (e) => {
      const { target } = e;
      const newVal = target.type === 'checkbox' ? target.checked : target.value || '';

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.tableNode.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  checkboxCellRender({ dataKey, rowData, cellData }) {
    const { projectNameSearch, showTrashcan } = this.state;
    let localProjectList = showTrashcan
      ? this.projectList
      : R.filter((item) => item.status !== 'Deleting', this.projectList);
    return (
      <span
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          // Save the data and force update.
          rowData[dataKey] = !cellData;

          if (projectNameSearch) {
            localProjectList = R.filter(
              (event) => R.toLower(event.projectName).includes(R.toLower(projectNameSearch)),
              localProjectList,
            );
          }
          const isCheckedAll = localProjectList.length > 0 && !R.find((m) => !m.checked, localProjectList);
          this.setState({ isCheckedAll });
          this.tableNode.forceUpdateGrid();
          this.forceUpdate();
        }}
      >
        <Checkbox size="small" checked={cellData || false} />
      </span>
    );
  }

  @autobind
  configSettingRender({ rowData }) {
    const { intl, isReadUser } = this.props;
    const { status } = rowData;
    return (
      <div>
        {!['Deleting', 'DeletingData'].includes(status) && (
          <Popover
            content={
              isReadUser
                ? intl.formatMessage(eventMessages.isReadUserDisable)
                : intl.formatMessage(settingsMessages.projectSetting)
            }
            placement="top"
            mouseEnterDelay={0.3}
          >
            <Button
              icon={<SettingOutlined />}
              disabled={isReadUser}
              onClick={(e) => {
                e.stopPropagation();
                this.handleProjectClick({ rowData });
              }}
              size="small"
            >
              {intl.formatMessage(settingsMessages.setting)}
            </Button>
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  nameRenderer({ rowData }) {
    const { intl } = this.props;
    const { projectDisplayName, projectName } = rowData;
    return (
      <Popover
        mouseEnterDelay={0.3}
        content={
          <div>
            <div className="flex-row flex-center-align">
              <span className="bold light-label inline-block" style={{ fontSize: 14, width: 154 }}>
                {intl.formatMessage(eventMessages.displayProjectName)}:
              </span>
              <span>{projectDisplayName}</span>
            </div>
            <div className="flex-row flex-center-align">
              <span className="bold light-label inline-block" style={{ fontSize: 14, width: 154 }}>
                {intl.formatMessage(eventMessages.realProjectName)}:
              </span>
              <span>{projectName}</span>
            </div>
          </div>
        }
      >
        <span className="hidden-line-with-ellipsis inline-block max-width">{projectDisplayName}</span>
      </Popover>
    );
  }

  @autobind
  dataTypeRenderer({ cellData }) {
    const { intl } = this.props;
    const label = settingsMessages[cellData] ? intl.formatMessage(settingsMessages[cellData]) : cellData;
    return (
      <Popover mouseEnterDelay={0.3} content={label}>
        <span className="hidden-line-with-ellipsis inline-block max-width">{label}</span>
      </Popover>
    );
  }

  @autobind
  statusRenderer({ cellData, rowData }) {
    const {
      dataType,
      earliestProcessTimestamp,
      latestProcessTimestamp,
      latestCollectTimestamp,
      lastMissingDate,
      processRate,
    } = rowData;
    const statusMsg = cellData === 'Deleting' ? 'In trash' : cellData;

    let statusIcon = <MoreIcon style={{ color: '#ff780a80', fontSize: 20 }} />;
    if (cellData === 'Deleting') {
      statusIcon = <TrashIcon style={{ fontSize: 14 }} />;
    } else if (cellData === 'Missing data') {
      statusIcon = <DangerIcon style={{ color: '#b20a03', fontSize: 16 }} />;
    } else if (R.startsWith('Detecting Anomalies', cellData || '') || R.startsWith('Detecting to', cellData || '')) {
      statusIcon = <CaretRightOutlined style={{ color: '#147f00', fontSize: 24 }} />;
    } else if (cellData === 'DeletingData') {
      statusIcon = <DocumentShredderIcon style={{ color: 'var(--primary-color)', fontSize: 16 }} />;
    }
    return (
      <Popover
        title={null}
        content={
          <div className="flex-col">
            <div className="flex-row">
              <span style={{ width: 150 }}>Status:</span>
              <span>{statusMsg}</span>
            </div>
            {isNumber(earliestProcessTimestamp) && (
              <div className="flex-row">
                <span style={{ width: 150 }}>Processing Start Time:</span>
                <span>
                  {earliestProcessTimestamp
                    ? moment.utc(earliestProcessTimestamp).format(Defaults.DateTimeFormat)
                    : 'NA'}
                </span>
              </div>
            )}
            {isNumber(latestProcessTimestamp) && (
              <div className="flex-row">
                <span style={{ width: 150 }}>Latest Processing Time:</span>
                <span>
                  {latestProcessTimestamp ? moment.utc(latestProcessTimestamp).format(Defaults.DateTimeFormat) : 'NA'}
                </span>
              </div>
            )}
            {isNumber(latestCollectTimestamp) && (
              <div className="flex-row">
                <span style={{ width: 150 }}>Latest Collection Time:</span>
                <span>
                  {latestCollectTimestamp ? moment.utc(latestCollectTimestamp).format(Defaults.DateTimeFormat) : 'NA'}
                </span>
              </div>
            )}
            {isNumber(lastMissingDate) && (
              <div className="flex-row">
                <span style={{ width: 150 }}>Last Missing Data Time:</span>
                <span>{lastMissingDate ? moment.utc(lastMissingDate).format(Defaults.DateTimeFormat) : 'NA'}</span>
              </div>
            )}
            {dataType !== 'Metric' && (
              <div className="flex-row">
                <span style={{ width: 150 }}>Process Rate:</span>
                <span>{isNumber(processRate) ? round(processRate, 2) : 'NA'}</span>
              </div>
            )}
          </div>
        }
        placement="right"
        mouseEnterDelay={0.3}
      >
        <div className="hidden-line-with-ellipsis">{statusIcon}</div>
      </Popover>
    );
  }

  @autobind
  handleProjectListRemove({ checkedProjects, operation }) {
    return (close) => {
      const { createLoadAction } = this.props;
      const { owner, systemId } = checkedProjects[0] || {};

      createLoadAction(
        ActionTypes.REMOVE_PROJECT,
        {
          systemName: systemId,
          operation,
          customerName: owner,
          projectNameList: JSON.stringify(R.map((item) => item.projectShortName, checkedProjects)),
        },
        this.submitLoadingKey,
      );

      close();
    };
  }

  @autobind
  handleProjectRemove({ rowData, operation }) {
    return (close) => {
      const { createLoadAction } = this.props;
      const { systemId, projectShortName, owner } = rowData;

      createLoadAction(
        ActionTypes.REMOVE_PROJECT,
        {
          systemName: systemId,
          operation,
          customerName: owner,
          projectNameList: JSON.stringify([projectShortName]),
        },
        this.submitLoadingKey,
      );

      close();
    };
  }

  @autobind
  handleProjectPurgeData({ checkedProjects }) {
    return (close) => {
      const { intl, credentials } = this.props;

      const deleteProjects = R.map(
        (item) => ({ projectName: item.projectShortName, userName: item.owner, dataType: item.dataType }),
        checkedProjects || [],
      );
      this.props.updateLastActionInfo();
      fetchDelete(getEndpoint('purgeWholeSystemDataServlet'), {
        ...credentials,
        deleteProjects: JSON.stringify(deleteProjects),
      })
        .then((data) => {
          const { success, message: errMsg } = data || {};
          if (success === undefined || success) {
            message.success(intl.formatMessage(appMessages.apiSuccess));
          } else {
            message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
          }
          close();
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          close();
        });
    };
  }

  @autobind
  controlRenderer({ cellData, rowData }) {
    const { intl, userInfo, allProjects } = this.props;
    const { isAdmin, userName } = userInfo;

    const { owner, projectName, status } = rowData;
    const project = R.find((project) => project.projectName === projectName, allProjects);

    const showSetting = !isAdmin && userName !== owner;

    return (
      <div>
        {!['Deleting'].includes(status) && (
          <Popover content={intl.formatMessage(settingsMessages.projectSetting)} placement="top" mouseEnterDelay={0.3}>
            <SettingOutlined
              style={{ fontSize: 14 }}
              onClick={(e) => {
                e.stopPropagation();
                this.handleProjectClick({ rowData });
              }}
            />
          </Popover>
        )}
        {!showSetting && (
          <>
            {!['Deleting', 'DeletingData'].includes(status) && project && (
              <Popover content={intl.formatMessage(appButtonsMessages.moveTrash)} placement="top" mouseEnterDelay={0.3}>
                <MoveToTrashIcon
                  style={{ fontSize: 14, marginLeft: 4 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    Modal.confirm({
                      title: intl.formatMessage(appButtonsMessages.confirm),
                      content: (
                        <div>
                          {intl.formatMessage(settingsMessages.confirmTrashProject, { project: projectName })}
                          <br />
                          {intl.formatMessage(appMessages.continueConfirm)}
                        </div>
                      ),
                      onOk: this.handleProjectRemove({ rowData, operation: 'Trash' }),
                    });
                  }}
                />
              </Popover>
            )}
            {(['Deleting', 'DeletingData'].includes(status) || !project) && (
              <>
                {project && status !== 'DeletingData' && (
                  <Popover
                    content={intl.formatMessage(appButtonsMessages.recover)}
                    placement="top"
                    mouseEnterDelay={0.3}
                  >
                    <ReturnToResetIcon
                      style={{ color: '#52ae5e', fontSize: 14, marginLeft: 4 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        Modal.confirm({
                          title: intl.formatMessage(appButtonsMessages.confirm),
                          content: (
                            <div>
                              {intl.formatMessage(settingsMessages.confirmRecoverProject, { project: projectName })}
                              <br />
                              {intl.formatMessage(appMessages.continueConfirm)}
                            </div>
                          ),
                          onOk: this.handleProjectRemove({ rowData, operation: 'Recover' }),
                        });
                      }}
                    />
                  </Popover>
                )}
                <Popover
                  content={intl.formatMessage(settingsMenusMessages.deleteProject)}
                  placement="top"
                  mouseEnterDelay={0.3}
                >
                  <DeleteOutlined
                    style={{ color: 'red', fontSize: 14, marginLeft: 4 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      Modal.confirm({
                        title: intl.formatMessage(appButtonsMessages.confirm),
                        content: (
                          <div>
                            {intl.formatMessage(settingsMessages.confirmRemoveProject, { project: projectName })}
                            <br />
                            {intl.formatMessage(appMessages.continueConfirm)}
                          </div>
                        ),
                        onOk: this.handleProjectRemove({ rowData, operation: 'Delete' }),
                      });
                    }}
                  />
                </Popover>
              </>
            )}
            <Popover content={intl.formatMessage(appButtonsMessages.purgeData)} placement="top" mouseEnterDelay={0.3}>
              <DocumentShredderIcon
                style={{ color: 'red', fontSize: 14, marginLeft: 4 }}
                onClick={(e) => {
                  e.stopPropagation();
                  Modal.confirm({
                    title: intl.formatMessage(appButtonsMessages.confirm),
                    content: (
                      <div>
                        {intl.formatMessage(settingsMessages.confirmPurgeProject, { project: projectName })}
                        <br />
                        {intl.formatMessage(appMessages.continueConfirm)}
                      </div>
                    ),
                    onOk: this.handleProjectPurgeData({ rowData }),
                  });
                }}
              />
            </Popover>
          </>
        )}
      </div>
    );
  }

  @autobind
  handleProjectNameSearchChange(projectNameSearch) {
    const { showTrashcan } = this.state;
    let { isCheckedAll } = this.state;
    let localProjectList = showTrashcan
      ? this.projectList
      : R.filter((item) => item.status !== 'Deleting', this.projectList);
    this.setState({ projectNameSearch }, () => {
      localProjectList = R.filter(
        (event) => R.toLower(event.projectDisplayName).includes(R.toLower(projectNameSearch)),
        localProjectList,
      );
      isCheckedAll = localProjectList.length > 0 && !R.find((m) => !m.checked, localProjectList);
      this.setState({ isCheckedAll });
    });
  }

  @autobind
  handleProjectNameChange(e) {
    const { showTrashcan } = this.state;
    let { isCheckedAll } = this.state;
    const { value } = e.target;
    const localProjectList = showTrashcan
      ? this.projectList
      : R.filter((item) => item.status !== 'Deleting', this.projectList);
    if (!value && e.type !== 'change') {
      isCheckedAll = localProjectList.length > 0 && !R.find((m) => !m.checked, localProjectList);
    }
    this.setState({ isCheckedAll });
  }

  @autobind
  sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  renderProjectList(projectList) {
    const { intl, isReadUser, userInfo } = this.props;
    const { isAdmin, userName } = userInfo || {};
    const { isLoading, isLoadingDetails, systemSearch, projectNameSearch, sortBy, sortDirection } = this.state;
    const { owner } = this.projectList[0] || {};

    if (projectNameSearch) {
      projectList = R.filter(
        (event) => R.toLower(event.projectDisplayName).includes(R.toLower(projectNameSearch)),
        projectList,
      );
    }
    const showSetting = !isAdmin && userName !== owner;

    const checkedProjects = R.filter((item) => item.checked, this.projectList);
    const hasErr = !systemSearch || checkedProjects.length === 0;
    const hasErrTrash =
      hasErr ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => !['Deleting', 'DeletingData'].includes(item.status), checkedProjects),
      );
    const hasErrRecover =
      hasErr ||
      !R.reduce(
        R.and,
        true,
        R.map(
          (item) => ['Deleting', 'DeletingData'].includes(item.status) && item.status !== 'DeletingData',
          checkedProjects,
        ),
      );
    const hasErrDelete =
      hasErr ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => ['Deleting', 'DeletingData'].includes(item.status), checkedProjects),
      );
    const hasErrPurge =
      hasErr ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => item.checked, checkedProjects),
      );
    return (
      <Spin spinning={isLoading || isLoadingDetails} wrapperClassName="full-height spin-full-height">
        <div className="flex-grow flex-min-height">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={50}
                ref={(table) => {
                  this.tableNode = table;
                }}
                rowCount={projectList.length}
                rowGetter={({ index }) => projectList[index]}
                rowStyle={({ index: number }) => {
                  if (
                    number >= 0 &&
                    projectList[number] &&
                    R.includes(projectList[number].status, ['Deleting', 'DeletingData'])
                  ) {
                    return { backgroundColor: 'var(--virtualized-table-row-active-gray-bg)' };
                  }
                  return {};
                }}
                sort={this.sortTable}
                sortBy={sortBy}
                sortDirection={sortDirection}
              >
                <Column
                  width={30}
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={this.checkAllHeaderRender('isCheckedAll')}
                  cellRenderer={this.checkboxCellRender}
                />
                <Column
                  width={200}
                  label={intl.formatMessage(eventMessages.projectName)}
                  dataKey="projectDisplayName"
                  flexGrow={1}
                  cellRenderer={this.nameRenderer}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={120}
                  label={intl.formatMessage(settingsMessages.cloudType)}
                  dataKey="cloudType"
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={100}
                  label={intl.formatMessage(settingsMessages.agentType)}
                  dataKey="insightAgentType"
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={100}
                  label={intl.formatMessage(settingsMessages.dataType)}
                  dataKey="dataType"
                  cellRenderer={this.dataTypeRenderer}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={145}
                  label={intl.formatMessage(settingsMessages.samplingInterval)}
                  dataKey="samplingInterval"
                  cellRenderer={this.intervalRenderer}
                  className="text-center"
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={130}
                  label={intl.formatMessage(settingsMessages.creationTime)}
                  dataKey="creationTime"
                  cellRenderer={this.timeRenderer}
                  headerRenderer={this.headerRenderer}
                />
                <Column width={80} label={intl.formatMessage(appFieldsMessages.owner)} dataKey="owner" disableSort />
                <Column
                  width={100}
                  label={intl.formatMessage(appFieldsMessages.status)}
                  dataKey="status"
                  className="text-center"
                  headerClassName="text-center"
                  cellRenderer={this.statusRenderer}
                  disableSort
                />
                <Column
                  width={100}
                  label={intl.formatMessage(settingsMessages.projectSetting)}
                  disableSort
                  dataKey="setting"
                  className="text-center"
                  headerClassName="text-center"
                  cellRenderer={this.configSettingRender}
                />
                {!isReadUser && false && (
                  <Column
                    dataKey="status"
                    width={130}
                    label=""
                    className="text-right"
                    cellRenderer={this.controlRenderer}
                    disableSort
                  />
                )}
              </Table>
            )}
          </AutoSizer>
        </div>
        {!showSetting && (
          <div className="flex-row flex-end-justify" style={{ marginTop: 16 }}>
            <Popover
              content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="top"
            >
              <Button
                type="primary"
                size="small"
                className="button-color-grey"
                onClick={(e) => this.handleProjectsRemove(e, 'moveTrash')}
                disabled={hasErrTrash || isReadUser}
              >
                <MoveToTrashIcon /> {intl.formatMessage(appButtonsMessages.moveTrash)}
              </Button>
            </Popover>

            <Popover
              content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="top"
            >
              <Button
                type="primary"
                size="small"
                className="button-color-grey"
                style={{ marginLeft: 8 }}
                onClick={(e) => this.handleProjectsRemove(e, 'recover')}
                disabled={hasErrRecover || isReadUser}
              >
                <ReturnToResetIcon style={{ color: '#52ae5e' }} /> {intl.formatMessage(appButtonsMessages.recover)}
              </Button>
            </Popover>
            <Popover
              content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="top"
            >
              <Button
                type="primary"
                size="small"
                className="button-color-grey"
                style={{ marginLeft: 8 }}
                onClick={(e) => this.handleProjectsRemove(e, 'delete')}
                disabled={hasErrDelete || isReadUser}
              >
                <DeleteOutlined style={{ color: 'red' }} /> {intl.formatMessage(settingsMenusMessages.deleteProject)}
              </Button>
            </Popover>
            <Popover
              content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="topLeft"
            >
              <Button
                type="primary"
                size="small"
                className="button-color-grey"
                style={{ marginLeft: 8 }}
                onClick={(e) => this.handleProjectsRemove(e, 'purgeData')}
                disabled={hasErrPurge || isReadUser}
              >
                <DocumentShredderIcon style={{ color: 'red', fontSize: 14 }} />{' '}
                {intl.formatMessage(appButtonsMessages.purgeData)}
              </Button>
            </Popover>
          </div>
        )}
      </Spin>
    );
  }

  @autobind
  handleProjectsRemove(e, key) {
    const { intl } = this.props;
    const checkedProjects = R.filter((item) => item.checked, this.projectList);
    const projectNames = R.join(
      ', ',
      R.map((item) => item.projectName, checkedProjects),
    );

    switch (key) {
      case 'moveTrash':
        Modal.confirm({
          title: intl.formatMessage(appButtonsMessages.confirm),
          content: (
            <div>
              {intl.formatMessage(settingsMessages.confirmTrashProject, { project: projectNames })}
              <br />
              {intl.formatMessage(appMessages.continueConfirm)}
            </div>
          ),
          onOk: this.handleProjectListRemove({ checkedProjects, operation: 'Trash' }),
        });
        break;
      case 'recover':
        Modal.confirm({
          title: intl.formatMessage(appButtonsMessages.confirm),
          content: (
            <div>
              {intl.formatMessage(settingsMessages.confirmRecoverProject, { project: projectNames })}
              <br />
              {intl.formatMessage(appMessages.continueConfirm)}
            </div>
          ),
          onOk: this.handleProjectListRemove({ checkedProjects, operation: 'Recover' }),
        });
        break;
      case 'delete':
        Modal.confirm({
          title: intl.formatMessage(appButtonsMessages.confirm),
          content: (
            <div>
              {intl.formatMessage(settingsMessages.confirmRemoveProject, { project: projectNames })}
              <br />
              {intl.formatMessage(appMessages.continueConfirm)}
            </div>
          ),
          onOk: this.handleProjectListRemove({ checkedProjects, operation: 'Delete' }),
        });
        break;
      case 'purgeData':
        Modal.confirm({
          title: intl.formatMessage(appButtonsMessages.confirm),
          content: (
            <div>
              {intl.formatMessage(settingsMessages.confirmPurgeProject, { project: projectNames })}
              <br />
              {intl.formatMessage(appMessages.continueConfirm)}
            </div>
          ),
          onOk: this.handleProjectPurgeData({ checkedProjects }),
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { intl, loadStatus, systemsMap, isAdmin, isLocalAdmin, isReadUser, credentials, location, replace } =
      this.props;
    const query = parseLocation(location);
    const {
      systemList,
      projectSearch,
      projectOwner,
      showTrashcan,
      isSubmitting,
      showNewSystemModal,
      showSystemEditModal,
      incident,
      systemSearch,
      activeSystemTab,
      ordinaryOwner,
      hideTabs,
    } = this.state;
    const localProjectList = showTrashcan
      ? this.projectList
      : R.filter((item) => item.status !== 'Deleting', this.projectList);
    const systemInfo = systemsMap[systemSearch] || {};
    const showKubernetesEndpoint = systemInfo?.kubeSystem;
    const currentSystem = R.clone(systemInfo);
    currentSystem.projectNameSet = R.map(
      (project) => ({
        ...project,
        projectNameReal: project.projectName,
      }),
      localProjectList,
    );
    const hasSystemInfo = !R.isEmpty(systemInfo);
    const hasKubernetes = !!R.find((p) => R.includes('Kubernetes', p?.cloudType || ''), this.projectList || []);
    const { owner: ownerUserName } = currentSystem;
    const { isLoading: isSubmittingProject } = getLoadStatus(get(loadStatus, this.submitLoadingKey), intl);

    return (
      <Container fullHeight withGutter className="flex-col">
        <Container breadcrumb style={{ marginBottom: 0 }}>
          <div className="section" style={{ minHeight: 30 }}>
            <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
            <span className="divider">/</span>
            <NavLink to={buildUrl(BaseUrls.SystemSetting, {}, { customerName: query?.customerName })}>
              <span className="label">{intl.formatMessage(settingsMenusMessages.systemSetting)}</span>
            </NavLink>
          </div>

          <div className="section" style={{ fontSize: 12, marginLeft: 40 }}>
            {!isReadUser && (
              <NavLink to={buildUrl(BaseUrls.SettingsIntegrations, {}, { customerName: query?.customerName })}>
                <Button size="small" type="primary" className="tour-project-add">
                  {intl.formatMessage(settingsMessages.addNewProject)}
                </Button>
              </NavLink>
            )}
            {!isReadUser && (
              <Button size="small" className="tour-system-add" onClick={this.handleNewSystemClick}>
                {intl.formatMessage(settingsMessages.addNewSystem)}
              </Button>
            )}
            {!isAdmin && !isLocalAdmin && !isReadUser && (
              <NavLink to={buildUrl(BaseUrls.SettingsCopyProject, {}, { customerName: query?.customerName })}>
                <Button size="small">{intl.formatMessage(settingsMessages.copyExistingProject)}</Button>
              </NavLink>
            )}
          </div>
          <div className="section float-right" style={{ fontSize: 12 }}>
            <Button size="small" onClick={this.handleRefreshClick}>
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </div>
        </Container>

        <Container
          fullHeight
          className="flex-grow flex-col flex-min-height content-bg corner-10"
          style={{ margin: '0 16px 8px 16px', padding: 8 }}
        >
          <div className="flex-row flex-center-align" style={{ height: 40 }}>
            <div style={{ marginRight: 8, fontSize: 14, fontWeight: 'bold' }}>
              {intl.formatMessage(appFieldsMessages.owner)}:
            </div>
            {(isAdmin || isLocalAdmin) && (
              <Select
                showArrow={false}
                showSearch
                size="small"
                style={{ width: 160, marginRight: 16 }}
                optionFilterProp="children"
                value={projectOwner}
                onChange={this.onChangeFilterOwner}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                dropdownMatchSelectWidth={false}
              >
                {this.userListOptions.map((item) => (
                  <Select.Option key={item.value} title={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            )}
            {!isAdmin && !isLocalAdmin && (
              <Input.Search
                size="small"
                style={{ width: 200, marginRight: 16 }}
                value={ordinaryOwner}
                allowClear
                enterButton
                onSearch={(value, event) =>
                  this.handleSearchOrdinaryOwner({ field: 'ordinaryOwner', value, event, isSearch: true })
                }
                onChange={(event) => this.handleSearchOrdinaryOwner({ field: 'ordinaryOwner', event })}
              />
            )}

            <div style={{ marginRight: 8, fontSize: 14, fontWeight: 'bold' }}>
              {intl.formatMessage(appFieldsMessages.project)}:
            </div>
            <Input.Search
              size="small"
              style={{ width: 200, marginRight: 16 }}
              value={projectSearch}
              allowClear
              enterButton
              onSearch={(value, event) =>
                this.handleSearchProject({ field: 'projectSearch', value, event, isSearch: true })
              }
              onChange={(event) => this.handleSearchProject({ field: 'projectSearch', event })}
            />

            <div style={{ marginRight: 8, fontSize: 14, fontWeight: 'bold' }}>
              {intl.formatMessage(settingsMessages.showTrashProjects)}:
            </div>
            <Switch
              size="small"
              style={{ marginRight: 16 }}
              checkedChildren="on"
              unCheckedChildren="off"
              checked={showTrashcan}
              onChange={(checked, event) => {
                this.setState({ showTrashcan: checked });
              }}
            />
          </div>

          <Spin
            spinning={isSubmitting || isSubmittingProject}
            wrapperClassName="flex-grow flex-min-height flex-row spin-full-width"
          >
            <div className="flex-col flex-min-width flex-min-height" style={{ width: 220, marginRight: 16 }}>
              <AutoSizer>
                {({ width, height }) => (
                  <div className="event-list">
                    <div className="event-list-header banner" style={{ height: 40, width }}>
                      <div className="header-column" style={{ width: 100, flex: 1 }}>
                        {intl.formatMessage(appFieldsMessages.systemName)}
                      </div>
                      <div className="header-column" style={{ width: 60 }} />
                    </div>
                    <List
                      className="event-list-grid"
                      ref={(listNode) => {
                        this.listNode = listNode;
                      }}
                      width={width}
                      height={height - 32}
                      rowCount={systemList.length}
                      rowHeight={40}
                      rowRenderer={this.renderListItem}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
            <Container className="flex-grow flex-min-height flex-min-width">
              <Tabs
                type="card"
                className="full-height ant-tabs-content-full-height flex-col overflow-y-auto overflow-x-hidden"
                activeKey={activeSystemTab}
                onChange={(activeSystemTab) => this.handleSystemTabClick(activeSystemTab)}
              >
                <Tabs.TabPane
                  tab={intl.formatMessage(settingsMessages.systemComposition)}
                  key="systemComposition"
                  style={{ paddingTop: 16 }}
                >
                  {this.renderProjectList(localProjectList)}
                </Tabs.TabPane>
                {systemSearch !== 'emptySystem' && !systemInfo.isShared && !hideTabs && (
                  <Tabs.TabPane
                    tab={intl.formatMessage(settingsMessages.systemSharing)}
                    key="SystemSharing"
                    style={{ paddingTop: 16 }}
                  >
                    <SystemSharelSetting activeSystemTab={activeSystemTab} systemId={systemSearch} />
                  </Tabs.TabPane>
                )}
                {(isAdmin || ownerUserName === credentials.userName || systemInfo.isShared) &&
                  hasSystemInfo &&
                  !isReadUser &&
                  !hideTabs && (
                    <Tabs.TabPane
                      tab={intl.formatMessage(settingsMessages.systemEmailNotificationSetting)}
                      key="email"
                      style={{ paddingTop: 16 }}
                    >
                      <SystemEmailSetting
                        intl={intl}
                        credentials={credentials}
                        isAdmin={isAdmin}
                        system={currentSystem}
                        systemsMap={systemsMap}
                        location={location}
                        replace={replace}
                      />
                    </Tabs.TabPane>
                  )}
                {isAdmin && hasSystemInfo && !hideTabs && (
                  <Tabs.TabPane
                    tab={intl.formatMessage(settingsMessages.systemScoresSetting)}
                    key="maxAnomalyScores"
                    style={{ paddingTop: 16 }}
                  >
                    <SystemScoreSetting
                      intl={intl}
                      credentials={credentials}
                      isAdmin={isAdmin}
                      system={currentSystem}
                    />
                  </Tabs.TabPane>
                )}
                {hasSystemInfo && !hideTabs && (
                  <Tabs.TabPane
                    tab={intl.formatMessage(settingsMessages.knowledgeBaseSetting)}
                    key="knowledgeBaseSetting"
                    style={{ paddingTop: 16 }}
                  >
                    <KnowledgeBaseSetting
                      intl={intl}
                      credentials={credentials}
                      isAdmin={isAdmin}
                      systemsMap={systemsMap}
                      system={currentSystem}
                      isReadUser={isReadUser}
                    />
                  </Tabs.TabPane>
                )}
                {(isAdmin || ownerUserName === credentials.userName || systemInfo.isShared) &&
                  hasSystemInfo &&
                  !isReadUser &&
                  hasKubernetes &&
                  !hideTabs && (
                    <Tabs.TabPane
                      tab={intl.formatMessage(settingsMessages.autoScalingSetting)}
                      key="autoScalingSetting"
                      style={{ paddingTop: 16 }}
                    >
                      <KubernetesSetting activeSystemTab={activeSystemTab} systemId={systemSearch} />
                    </Tabs.TabPane>
                  )}
                {/* 如果项目是分享的，不允许修改，因为普通用户获取不到其他人的license_key */}
                {(isAdmin || ownerUserName === credentials.userName) &&
                  hasSystemInfo &&
                  !isReadUser &&
                  showKubernetesEndpoint &&
                  !hideTabs && (
                    <Tabs.TabPane
                      tab={intl.formatMessage(settingsMessages.kubernetesEndpoint)}
                      key="kubernetesEndpoint"
                      style={{ paddingTop: 16 }}
                    >
                      <KubernetesEndpoint activeSystemTab={activeSystemTab} systemId={systemSearch} />
                    </Tabs.TabPane>
                  )}
                {isAdmin && hasSystemInfo && !hideTabs && (
                  <Tabs.TabPane
                    tab={intl.formatMessage(settingsMessages.zoneSecreteForJWT)}
                    key="zoneSecreteJWT"
                    style={{ paddingTop: 16 }}
                  >
                    <ZoneSecreteJWTRender activeSystemTab={activeSystemTab} systemId={systemSearch} />
                  </Tabs.TabPane>
                )}
                {systemSearch !== 'emptySystem' && !hideTabs && (
                  <Tabs.TabPane
                    tab={intl.formatMessage(settingsMessages.maintenanceDay)}
                    key="maintenanceDay"
                    style={{ paddingTop: 16 }}
                  >
                    <SystemMaintenanceDay activeSystemTab={activeSystemTab} systemId={systemSearch} />
                  </Tabs.TabPane>
                )}
                {systemSearch !== 'emptySystem' && !hideTabs && (
                  <Tabs.TabPane
                    tab={intl.formatMessage(settingsMessages.miscellaneous)}
                    key="general"
                    style={{ paddingTop: 16 }}
                  >
                    <SystemGeneralSetting activeSystemTab={activeSystemTab} systemId={systemSearch} />
                  </Tabs.TabPane>
                )}
                {systemSearch !== 'emptySystem' && !hideTabs && (
                  <Tabs.TabPane
                    tab={intl.formatMessage(settingsMessages.incidentValidationSetting)}
                    key="incidentValidation"
                    style={{ paddingTop: 16 }}
                  >
                    <IncidentValidationSetting activeSystemTab={activeSystemTab} systemId={systemSearch} />
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Container>
          </Spin>
        </Container>

        {showNewSystemModal && <NewSystemModal onClose={this.handleNewSystemClose} />}
        {showSystemEditModal && <SystemEditModal incident={incident} onClose={this.handleSystemEditClose} />}
      </Container>
    );
  }
}

const SystemSetting = injectIntl(SystemSettingCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { isAdmin, isLocalAdmin, isReadUser } = state.auth.userInfo;
    const { loaderStatus, loadStatus, systemsMap, projects, allProjects } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return {
      location,
      loaderStatus,
      loadStatus,
      userInfo,
      credentials,
      userList,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      systemsMap,
      projects,
      allProjects,
    };
  },
  { push, replace, hideAppLoader, showAppLoader, showAppAlert, updateLastActionInfo, createLoadAction },
)(SystemSetting);
