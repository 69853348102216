import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { isObject } from 'lodash';
import { Button, message, Popconfirm, Select, Spin } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from '../../../lib/fui/react';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { appButtonsMessages, appMessages } from '../../../common/app/messages';
import fetchPost from '../../../common/apis/fetchPost';

export default function UpdataGlobalKnowledgeMappingModal({
  onClose,
  intl,
  systemsMap,
  projects,
  activeIncidentKey,
  updateMappingActive,
  incidentList,
  credentials,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isLoading: false,
    globalMappingList: [],
    rulekey: null,
  });
  const { isLoading, globalMappingList } = state;

  const getMapping = () => {
    setState({ isLoading: true });
    const { incidentRule, ruleType } = updateMappingActive;
    const { rulePrimaryKey } = incidentRule;
    const { uniqueKey, rulePartitionKey } = rulePrimaryKey;
    const { projectName, userName, instanceName, patternId } = rulePartitionKey;
    const rulekey = { projectName, userName, instanceName, patternId, ruleType, uniqueKey };
    fetchGet(getEndpoint('global-kb-mapping'), {
      ...credentials,
      ruleInfoList: JSON.stringify([rulekey]),
    })
      .then((data) => {
        const globalMappingList = [];

        R.forEach((item) => {
          if (item && item.length > 1) {
            const { projectName, userName, instanceName, patternId, ruleType, uniqueKey } = item[0];
            if (
              projectName === rulekey.projectName &&
              userName === rulekey.userName &&
              instanceName === rulekey.instanceName &&
              patternId === rulekey.patternId &&
              ruleType === rulekey.ruleType &&
              uniqueKey === rulekey.uniqueKey
            ) {
              const globalMapping = item[1];
              R.forEachObjIndexed((value, key) => {
                globalMappingList.push({ systemId: key, project: value });
              }, globalMapping);
            }
          }
        }, data || []);

        setState({
          globalMappingList,
          rulekey,
          isLoading: false,
        });
      })
      .catch((e) => {
        setState({ isLoading: false });
        console.log(String(e));
      });
  };

  useEffect(() => {
    getMapping();
  }, []);

  const handleAddMappingList = () => {
    const mappingList = [...globalMappingList, { systemId: null, project: null }];
    setState({ globalMappingList: mappingList });
  };

  const handleSubmit = () => {
    setState({ isLoading: true });
    const globalMapping = {};
    R.forEach((item) => {
      const { systemId, project } = item;
      if (systemId && project) {
        globalMapping[systemId] = project;
      }
    }, globalMappingList);

    fetchPost(getEndpoint('global-kb-mapping'), {
      ...credentials,
      ruleInfo: JSON.stringify(state.rulekey),
      globalMapping: JSON.stringify(globalMapping),
    })
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          message.success(msg);
          setState({ isLoading: false });
          onClose();
        } else {
          setState({ isLoading: false });
        }
      })
      .catch((e) => {
        setState({ isLoading: false });
      });
  };

  const isValid =
    R.filter((item) => item.systemId && item.project, globalMappingList).length === globalMappingList.length;

  return (
    <Modal
      title="Global mapping"
      width={640}
      open
      maskClosable={false}
      okButtonProps={{ disabled: !isValid }}
      onCancel={() => onClose()}
      onOk={() => handleSubmit()}
      bodyStyle={{ height: 500 }}
    >
      <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
        <div className="flex-row flex-center-align" style={{ margin: '8px 0' }}>
          <Button size="small" type="primary" style={{ width: 120 }} onClick={handleAddMappingList}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>
        <div className="flex-grow flex-col flex-min-height">
          {globalMappingList.length > 0 && (
            <div className="flex-grow flex-min-height overflow-y-auto" style={{ borderWidth: 0 }}>
              {R.addIndex(R.map)((rowData, index) => {
                const systemId = rowData?.systemId;
                const project = rowData?.project;
                const projectKey = !project ? null : `${project.projectName}@${project.userName}`;
                const projectList = R.filter((item) => item.systemId === systemId, projects || []);
                const existSystemIds = R.reduce(
                  (acc, item) => {
                    if (item.systemId) {
                      acc[item.systemId] = true;
                    }
                    return acc;
                  },
                  {},
                  globalMappingList,
                );
                return (
                  <div className="flex-row flex-center-align" key={index} style={{ minHeight: 40 }}>
                    <div className="flex-row flex-center-align" style={{ width: 250 }}>
                      <Select
                        showSearch
                        size="small"
                        style={{ width: 240 }}
                        placeholder="System"
                        value={systemId || null}
                        optionFilterProp="value"
                        onChange={(val) => {
                          const mappingList = R.update(
                            index,
                            {
                              systemId: val,
                              project: null,
                            },
                            globalMappingList,
                          );
                          setState({ globalMappingList: mappingList });
                        }}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ maxWidth: 650 }}
                      >
                        {R.map(
                          (item) => {
                            return (
                              <Select.Option key={item.systemName} value={item.systemId}>
                                {item.systemName || item.systemId}
                              </Select.Option>
                            );
                          },
                          R.filter(
                            (item) => item.systemId === systemId || !existSystemIds[item.systemId],
                            R.values(systemsMap || {}),
                          ),
                        )}
                      </Select>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 250 }}>
                      <Select
                        showSearch
                        size="small"
                        style={{ width: 240 }}
                        placeholder="Project"
                        value={projectKey}
                        optionFilterProp="value"
                        onChange={(val) => {
                          const project = R.find(
                            (item) => `${item.projectDisplayName}@${item.owner}` === val,
                            projectList,
                          );
                          const mappingList = R.update(
                            index,
                            {
                              systemId,
                              project: !project
                                ? null
                                : {
                                    projectName: project.projectDisplayName,
                                    userName: project.owner,
                                  },
                            },
                            globalMappingList,
                          );
                          setState({ globalMappingList: mappingList });
                        }}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ maxWidth: 650 }}
                      >
                        {R.map((item) => {
                          return (
                            <Select.Option
                              key={item.projectDisplayName}
                              value={`${item.projectDisplayName}@${item.owner}`}
                            >
                              {item.projectDisplayName}
                            </Select.Option>
                          );
                        }, projectList)}
                      </Select>
                    </div>
                    <div className="row-column" style={{ width: 90 }}>
                      <Button
                        size="small"
                        className="button-color-grey"
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                          const mappingList = R.remove(index, 1, globalMappingList);
                          setState({ globalMappingList: mappingList });
                        }}
                      >
                        <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                      </Button>
                    </div>
                  </div>
                );
              }, globalMappingList)}
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
}
