/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FileAddOutlined } from '@ant-design/icons';
import { message, Spin, Button, Popconfirm, Select, Alert, InputNumber } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import fetchDelete from '../../../../common/apis/fetchDelete';
import getEndpoint from '../../../../common/apis/getEndpoint';
// import { Defaults } from '../../../../common/utils';
import { setShowDetailsFrame, updateLastActionInfo } from '../../../../common/app/actions';
import { State } from '../../../../common/types';
import { Modal, Container } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

import LogToMetricModal from './LogToMetricModal';
import CreatePortalDetailsFrame from '../../../app/CreatePortalDetailsFrame';

type Props = {
  projectName: String,
  refreshTime: Number,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  userInfo: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  setShowDetailsFrame: Function,
};

class LogToMetricCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmitting: false,

      eventList: [],

      showLogToMetricModal: false,
      incident: null,

      fieldNameOptions: [],
    };
  }

  async componentDidMount() {
    await this.getLogjsontype();
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.projectName !== nextProps.projectName || this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  getLogjsontype() {
    const { intl, projectName, credentials } = this.props;
    return fetchGet(getEndpoint('logjsontype'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          const fieldNameOptions = R.map((item) => ({ value: item.jsonKey, label: item.jsonKey }), data || []);
          this.setState({ fieldNameOptions });
        } else {
          message.error(msg);
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        this.setState({ fieldNameOptions: [] });
      });
  }

  @autobind
  reloadData(props) {
    const { intl, projectName, credentials } = props;
    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('logtometricsetting'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        // message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isLoading: false, eventList: data || [] });
      })
      .catch((err) => {
        message.error(err.message || String(err));
        this.setState({ isLoading: false, eventList: [] });
      });
  }

  @autobind
  renderListView(rowData, index) {
    if (!rowData) return null;

    const { intl } = this.props;
    const { jsonFlag, metricProjectName, isDeleting } = rowData;

    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 150 }}>
          {jsonFlag ? 'JSON' : 'String'}
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {metricProjectName}
        </div>
        <div className="row-column flex-end-justify" style={{ width: 150 }}>
          <Button
            size="small"
            style={{ marginRight: 8 }}
            className="button-color-grey"
            onClick={() => this.handleRowClick(rowData)}
          >
            {intl.formatMessage(appButtonsMessages.edit)}
          </Button>
          <Popconfirm
            placement="topRight"
            title={intl.formatMessage(appMessages.continueConfirm)}
            onConfirm={(event) => {
              event.stopPropagation();
              this.handleRemoveClick(rowData, index);
            }}
            onCancel={(event) => event.stopPropagation()}
          >
            <Button
              size="small"
              className="button-color-grey"
              disabled={isDeleting}
              loading={isDeleting}
              onClick={(event) => event.stopPropagation()}
            >
              {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  }

  @autobind
  handleRowClick(rowData) {
    const { setShowDetailsFrame } = this.props;
    setShowDetailsFrame(true);
    this.setState({
      incident: rowData,
      showLogToMetricModal: true,
    });
  }

  @autobind
  handleAddClick() {
    const { setShowDetailsFrame } = this.props;
    setShowDetailsFrame(true);
    this.setState({
      incident: null,
      showLogToMetricModal: true,
    });
  }

  @autobind
  handleRemoveClick(rowData, index) {
    const { intl, projectName, credentials } = this.props;
    const { eventList } = this.state;
    const { metricProjectName } = rowData;

    this.setState({ eventList: update(eventList, { [index]: { $set: { ...rowData, isDeleting: true } } }) });
    this.props.updateLastActionInfo();
    fetchDelete(getEndpoint('logtometricsetting'), {
      ...credentials,
      projectName,
      metricProjectName,
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        this.setState({ eventList: update(eventList, { [index]: { $set: { ...rowData, isDeleting: false } } }) });

        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.reloadData(this.props);
        } else {
          message.error(msg);
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        this.setState({ eventList: update(eventList, { [index]: { $set: { ...rowData, isDeleting: false } } }) });
      });
  }

  @autobind
  handleSaveClick(
    { jsonFlag, metricProjectName, regexs, jsonParsers, sampleJsonString, enableMapping },
    stopCloseFlag,
  ) {
    const { projects } = this.props;

    // if project not exist, then create project first
    const project = R.find(
      (item) => item.projectShortName === metricProjectName || item.projectName === metricProjectName,
      projects || [],
    );
    if (!project) {
      this.handleCreateProjectConfirm({
        jsonFlag,
        metricProjectName,
        regexs,
        jsonParsers,
        sampleJsonString,
        enableMapping,
      });
    } else {
      this.createLog2MetricSetting({
        jsonFlag,
        metricProjectName,
        regexs,
        jsonParsers,
        sampleJsonString,
        enableMapping,
        stopCloseFlag,
      });
    }
  }

  @autobind
  handleCreateProjectConfirm({ jsonFlag, metricProjectName, regexs, jsonParsers, sampleJsonString, enableMapping }) {
    const { intl, userInfo, userList, projectName, projects } = this.props;
    const logProject = R.find((item) => item.projectName === projectName, projects || []);
    const { owner } = logProject || {};

    this.projectOwner = owner || userList[0]?.userName || 'user';
    this.samplingInterval = 5;
    this.samplingUnit = 60;
    this.agentType = 'Custom';
    this.samplingUnitOption = [
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
    this.metricAgentOptions = [
      { label: 'Streaming', value: 'Custom' },
      { label: 'Container Streaming', value: 'containerStreaming' },
    ];
    this.openConfirmModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <div className="flex-col">
          <div style={{ marginBottom: 16 }}>
            <Alert message={`Project (${metricProjectName}) does not exist, do you want to create it?`} type="info" />
          </div>
          {userInfo.isAdmin && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
              <div className="light-label bold" style={{ width: 120 }}>{`${intl.formatMessage(
                appFieldsMessages.userName,
              )}:`}</div>
              <div className="flex-grow flex-min-width">
                <Select
                  size="small"
                  style={{ width: '100%' }}
                  allowClear={false}
                  showSearch
                  filterOption
                  defaultValue={this.projectOwner}
                  onChange={(projectOwner) => {
                    this.projectOwner = projectOwner;
                  }}
                  disabled
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              </div>
            </div>
          )}
          <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
            <div className="light-label bold" style={{ width: 120 }}>{`${intl.formatMessage(
              settingsMessages.samplingInterval,
            )}:`}</div>
            <div className="flex-grow flex-min-width">
              <InputNumber
                min={1}
                style={{ width: 200 }}
                defaultValue={this.samplingInterval}
                onChange={(samplingInterval) => {
                  this.samplingInterval = samplingInterval;
                }}
              />
              <Select
                style={{ width: 120, marginLeft: 4 }}
                options={this.samplingUnitOption}
                defaultValue={this.samplingUnit}
                onChange={(samplingUnit) => {
                  this.samplingUnit = samplingUnit;
                }}
              />
            </div>
          </div>
          <div className="flex-row flex-center-align">
            <div className="light-label bold" style={{ width: 120 }}>{`${intl.formatMessage(
              settingsMessages.agentType,
            )}:`}</div>
            <div className="flex-grow flex-min-width">
              <Select
                style={{ width: 200 }}
                options={this.metricAgentOptions}
                defaultValue={this.agentType}
                onChange={(agentType) => {
                  this.agentType = agentType;
                }}
              />
            </div>
          </div>
        </div>
      ),
      width: 650,
      onOk: (close) =>
        this.handleCreateProjectSumbit(
          close,
          { jsonFlag, metricProjectName, regexs, jsonParsers, sampleJsonString, enableMapping },
          this.projectOwner,
          (this.samplingInterval * this.samplingUnit) / 60,
          logProject,
          this.agentType,
        ),
    });
  }

  @autobind
  handleCreateProjectSumbit(
    close,
    { jsonFlag, metricProjectName, regexs, jsonParsers, sampleJsonString, enableMapping },
    projectOwner,
    samplingInterval,
    logProject,
    agentType,
  ) {
    const { intl, credentials } = this.props;

    if (this.openConfirmModal) {
      this.openConfirmModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }

    this.props.updateLastActionInfo();
    Promise.all([
      fetchPost(getEndpoint('add-l2m-project'), {
        ...credentials,
        projectCreationType: 'LogToMetric',
        projectName: metricProjectName,
        UserName: projectOwner,
        samplingInterval,
        systemName: logProject?.systemName || metricProjectName,
        insightAgentType: agentType,
      }),
    ])
      .then((results) => {
        const { success, message: errMsg } = results[0] || {};
        if (success) {
          this.createLog2MetricSetting({
            jsonFlag,
            metricProjectName,
            regexs,
            jsonParsers,
            sampleJsonString,
            enableMapping,
            needRefresh: true,
          });
          close();
        } else if (this.openConfirmModal) {
          this.openConfirmModal.update({
            content: (
              <div>
                <Alert message={errMsg} type="error" style={{ marginTop: 8 }} />
              </div>
            ),
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        if (this.openConfirmModal) {
          this.openConfirmModal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      });
  }

  @autobind
  createLog2MetricSetting({
    jsonFlag,
    metricProjectName,
    regexs,
    jsonParsers,
    sampleJsonString,
    enableMapping,
    stopCloseFlag,
    needRefresh,
  }) {
    const { intl, projectName, credentials, setShowDetailsFrame } = this.props;
    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('logtometricsetting'), {
      ...credentials,
      projectName,
      regexInfo: JSON.stringify({ jsonFlag, metricProjectName, regexs, jsonParsers, sampleJsonString, enableMapping }),
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (needRefresh) {
            setTimeout(() => {
              window.location.reload();
            }, 200);
            return;
          }
          setShowDetailsFrame(Boolean(stopCloseFlag));
          this.setState({ isSubmitting: false, showLogToMetricModal: Boolean(stopCloseFlag) });
          this.reloadData(this.props);
        } else {
          message.error(msg);
          this.setState({ isSubmitting: false });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        this.setState({ isSubmitting: false });
      });
  }

  render() {
    const { intl, projectName } = this.props;
    const { isLoading, isSubmitting, eventList, showLogToMetricModal, incident, fieldNameOptions } = this.state;

    return (
      <Container className="full-height">
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <div className="full-height flex-col">
            <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
              <Button size="small" type="primary" onClick={this.handleAddClick}>
                <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
              </Button>
            </div>

            <div className="event-list flex-grow flex-col flex-min-height">
              <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                <div className="header-column" style={{ width: 150 }}>
                  {intl.formatMessage(settingsMessages.jsonFlag)}
                </div>
                <div className="header-column" style={{ width: 100, flex: 1 }}>
                  {intl.formatMessage(settingsMessages.metricProject)}
                </div>
                <div className="header-column" style={{ width: 150 }} />
              </div>
              <div className="event-list-grid flex-grow overflow-y-auto">
                {R.addIndex(R.map)((rowData, index) => this.renderListView(rowData, index), eventList || [])}
              </div>
            </div>
          </div>
        </Spin>

        {showLogToMetricModal && (
          <CreatePortalDetailsFrame
            domKey="logToMetric"
            onCancel={() => this.setState({ showLogToMetricModal: false })}
            header={[
              { title: intl.formatMessage(settingsMessages.projectSetting), action: 'back' },
              {
                title: `${intl.formatMessage(
                  incident ? appButtonsMessages.edit : appButtonsMessages.add,
                )} (${intl.formatMessage(settingsMessages.logToMetric)})`,
              },
            ]}
          >
            <LogToMetricModal
              projectName={projectName}
              incident={incident}
              isSubmitting={isSubmitting}
              fieldNameOptions={fieldNameOptions}
              onSave={this.handleSaveClick}
            />
          </CreatePortalDetailsFrame>
        )}
      </Container>
    );
  }
}

const LogToMetric = injectIntl(LogToMetricCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    const { credentials, userInfo } = state.auth;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);

    return { location, loadStatus, credentials, userInfo, projects, userList };
  },
  {
    updateLastActionInfo,
    setShowDetailsFrame,
  },
)(LogToMetric);
