import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { appFieldsMessages } from '../../common/app/messages';
import { EntityFilterEx } from './EntityFilterEx';
import { EntityListView } from './EntityListView';

const groupFunction = (isPod, entityId, instanceComponentMap, allRawEntityMap) => {
  const componentId = instanceComponentMap?.[entityId] || entityId;
  const component = {
    id: `component--${componentId}`,
    title: componentId,
    isVirtual: true,
    type: 'component',
  };

  // Pod or container
  const parts = entityId.split('_');
  if (parts.length === 1) {
    return [component];
  }

  // Container
  const podId = parts[1] || 'Unknown';
  let pod = isPod ? allRawEntityMap[podId] : null;
  const isVirtual = !pod;
  if (isVirtual) {
    pod = { id: `${componentId}--${podId}-v`, title: podId, isVirtual, type: isPod ? 'pod' : 'instance' };
  }

  return [component, pod];
};

export const applyInstanceList = (isPod, allInstanceList, anomalyInstanceMap, instanceComponentMap) => {
  const allRawEntityMap = {};
  const allEntityTreeMap = { children: {} };
  let hasContainer = false;
  R.forEach((inst) => {
    const { id } = inst;
    const hasAnomaly = !!anomalyInstanceMap[id];
    const inactive = isPod ? false : inst.inactive;
    const parts = id.split('_');
    inst.hasAnomaly = hasAnomaly;
    inst.inactive = inactive;
    inst.title = parts.length === 1 ? id : parts[0];
    inst.type = !isPod ? (parts.length === 1 ? 'instance' : 'container') : parts.length === 1 ? 'pod' : 'container';
    if (!hasContainer && parts.length > 1) {
      hasContainer = true;
    }

    allRawEntityMap[id] = inst;
  }, allInstanceList);

  R.forEach((inst) => {
    const { id } = inst;
    const groupPaths = groupFunction(isPod, id, instanceComponentMap, allRawEntityMap);
    inst.level = groupPaths.length;
    let parent = allEntityTreeMap;
    for (let i = 0; i < groupPaths.length; i += 1) {
      const group = groupPaths[i];

      let groupEntity = parent.children[group.id];
      if (!groupEntity) {
        groupEntity = {
          ...group,
          level: i,
          children: {},
        };
        parent.children[group.id] = groupEntity;
      }
      parent = groupEntity;
    }

    parent.children[inst.id] = {
      ...inst,
      level: groupPaths.length,
      children: {},
    };
  }, allInstanceList);

  return { allRawEntityList: allInstanceList, allEntityTreeMap, hasContainer };
};

export const InstanceFilter = ({
  intl,
  cloudType,
  allInstanceList,
  instanceComponentMap,
  anomalyInstanceList,
  selectionMap,
  onChange,
  readonlyView,
  instanceDisplayNameMap,
  getEntityTitle = () => {},
}) => {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    allEntityTreeMap: {},
    anomalyInstanceMap: {},
    allRawEntityList: [],
    hasContainer: false,
  });
  const isPod = cloudType?.toLowerCase() === 'kubernetespod';
  const entityType = isPod ? 'Pod' : intl.formatMessage(appFieldsMessages.instance);

  useEffect(() => {
    const anomalyInstanceMap = R.reduce((acc, i) => ({ ...acc, [i]: true }), {}, anomalyInstanceList);
    setState({ anomalyInstanceMap });
  }, [anomalyInstanceList]);

  const { anomalyInstanceMap } = state;

  useEffect(() => {
    const { allRawEntityList, allEntityTreeMap, hasContainer } = applyInstanceList(
      isPod,
      allInstanceList,
      anomalyInstanceMap,
      instanceComponentMap,
    );
    setState({ allRawEntityList, allEntityTreeMap, hasContainer });
  }, [allInstanceList, instanceComponentMap, anomalyInstanceMap]);

  const { allEntityTreeMap, allRawEntityList, hasContainer } = state;

  return !readonlyView ? (
    <EntityFilterEx
      showSearchType
      entityType={entityType}
      allRawEntityList={allRawEntityList || []}
      allEntityTreeMap={allEntityTreeMap || {}}
      hasContainer={hasContainer}
      selectionMap={selectionMap || {}}
      onChange={(selectionMap) => {
        onChange(selectionMap);
      }}
      getEntityTitle={getEntityTitle}
    />
  ) : (
    <EntityListView
      showSearchType
      entityType={entityType}
      allRawEntityList={allRawEntityList || []}
      allEntityTreeMap={allEntityTreeMap || {}}
      hasContainer={hasContainer}
      selectionMap={selectionMap || {}}
      onChange={(selectionMap) => {
        onChange(selectionMap);
      }}
      instanceDisplayNameMap={instanceDisplayNameMap}
      getEntityTitle={getEntityTitle}
    />
  );
};
