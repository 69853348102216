import React, { useReducer } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AutoComplete, Button, message, Select, Tabs } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { Modal } from '../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import { ComputingTimeNew, LogInputContentInfo, MetricInputRuleInfo } from './UpdateGlobalRuleInfo';

const reducerLogic = (oldVal, newVal) => ({ ...oldVal, ...newVal });
const initIncidentDetail = { metricName: '', anomalyValue: 0, percentage: 0, sign: 'higher', type: 'Metric' };
const initDetail = { content: '', value: 0, percentage: 0, direction: 'higher', type: 'Metric' };
const initLogDetail = { content: '', type: 'Log' };
const initState = {
  loading: false,
  activeTab: 'KPIIncidents',
  ruleIncident: { patternName: '', incidentData: initIncidentDetail, delay: 0 },
  srcDetails: [],
  addIncidentType: 'Metric',
};
const bodyHeight = 700;

function CreateNewGlobalKBModal({ incidentData, metricList, onClose, ...props }: Object) {
  const { intl, credentials } = props;
  const [state, setState] = useReducer(reducerLogic, initState);
  const { ruleIncident, srcDetails, loading, activeTab, addIncidentType } = state;

  const isMetricTab = activeTab === 'KPIIncidents';
  const addIsMetric = addIncidentType === 'Metric';

  const submitGlobal = () => {
    setState({ loading: true });
    const lackSrcDetails = (srcDetails || []).length === 0;
    fetchPost(getEndpoint('globallevelrule'), {
      ...credentials,
      ruleIncident: JSON.stringify({
        patternName: ruleIncident.patternName,
        delay: ruleIncident.delay,
        ...(isMetricTab
          ? { incidentRootCause: ruleIncident.incidentData }
          : { incidentData: ruleIncident.incidentData }),
      }),
      ...(lackSrcDetails ? {} : { srcDetails: JSON.stringify(srcDetails) }),
      type: activeTab,
      operation: 2, // 'create'
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          message.success(msg);
          setState({ loading: false });
          onClose(true);
        } else {
          message.error(msg);
          setState({ loading: false });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        setState({ loading: false });
      });
  };

  const handleAdd = () => {
    const addData = addIsMetric ? initDetail : initLogDetail;
    setState({ srcDetails: [addData, ...srcDetails] });
  };

  const handleDelete = (index) => {
    setState({ srcDetails: R.addIndex(R.filter)((item, idx) => idx !== index, srcDetails) });
  };

  const kpiIncidentsRender = () => {
    let metricNameList = metricList[ruleIncident.patternName];
    metricNameList = R.map((item) => ({ label: item, value: item }), metricNameList || []);
    metricNameList = R.sortWith([R.ascend(R.prop('label'))], metricNameList);
    return (
      <>
        <div className="flex-row flex-center-align">
          <div className="light-label bold" style={{ width: 172, flexShrink: 0 }}>
            {intl.formatMessage(appFieldsMessages.delay)}:
          </div>
          <ComputingTimeNew
            event={ruleIncident}
            handleChange={(delay) => {
              setState({ ruleIncident: { ...ruleIncident, delay } });
            }}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
          <div className="light-label bold" style={{ width: 164, flexShrink: 0 }}>
            {intl.formatMessage(eventMessages.incidentPatternName)} <span style={{ color: 'red' }}>*</span>:
          </div>
          <AutoComplete
            allowClear
            size="small"
            value={ruleIncident.patternName}
            options={incidentData}
            style={{ width: 500, marginLeft: 8 }}
            onChange={(patternName) => {
              setState({
                ruleIncident: {
                  ...ruleIncident,
                  patternName,
                  ...(isMetricTab ? { incidentData: { ...ruleIncident.incidentData, metricName: '' } } : {}),
                },
                srcDetails: R.map((item) => {
                  const isMetric = item.type === 'Metric';
                  if (isMetric) {
                    return { ...item, content: '' };
                  } else {
                    return item;
                  }
                }, srcDetails || []),
              });
            }}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
            }
            className={`${ruleIncident.patternName ? '' : 'jsonKeyNoneError'}`}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
          <div className="light-label bold" style={{ width: 164, flexShrink: 0 }}>
            {intl.formatMessage(eventMessages.incidentShortDescription)} <span style={{ color: 'red' }}>*</span>:
          </div>
          {isMetricTab && (
            <div style={{ marginLeft: 8 }}>
              <MetricInputRuleInfo
                key={ruleIncident.patternName}
                event={ruleIncident.incidentData}
                metricList={metricNameList}
                handleChange={(data) => {
                  setState({
                    ruleIncident: {
                      ...ruleIncident,
                      incidentData: { ...ruleIncident.incidentData, ...data },
                    },
                  });
                }}
              />
            </div>
          )}
          {!isMetricTab && (
            <div style={{ marginLeft: 8, width: 500 }}>
              <LogInputContentInfo
                event={ruleIncident}
                handleChange={(data) => {
                  setState({ ruleIncident: { ...ruleIncident, ...data } });
                }}
              />
            </div>
          )}
        </div>

        <div className="flex-row flex-center-align" style={{ marginTop: 28 }}>
          <div className="text-right">{intl.formatMessage(eventMessages.rootCauseShortDescription)}:</div>
          <Select
            size="small"
            style={{ width: 100, marginLeft: 8 }}
            value={addIncidentType}
            onChange={(addIncidentType) => setState({ addIncidentType })}
            options={[
              { value: 'Metric', label: 'Metric' },
              { value: 'Log', label: 'Log' },
            ]}
          />
          <Button size="small" type="primary" style={{ marginLeft: 8 }} onClick={handleAdd}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>

        <div>
          {R.addIndex(R.map)((item, index) => {
            const { type } = item;
            const isMetric = type === 'Metric';
            if (isMetric) {
              return (
                <div
                  style={{ margin: '8px 0 0 0' }}
                  className="flex-row flex-center-align"
                  key={`${index}${ruleIncident.patternName}`}
                >
                  <div style={{ width: 615 }}>
                    <MetricInputRuleInfo
                      event={item}
                      metricList={metricNameList}
                      handleChange={(data) => {
                        setState({
                          srcDetails: update(srcDetails, {
                            [index]: { $set: { ...item, ...data } },
                          }),
                        });
                      }}
                    />
                  </div>
                  {(isMetricTab || srcDetails.length > 1) && (
                    <Button size="small" style={{ marginLeft: 34 }} onClick={() => handleDelete(index)}>
                      {intl.formatMessage(appButtonsMessages.delete)}
                    </Button>
                  )}
                </div>
              );
            } else {
              return (
                <div style={{ margin: '8px 0 0 0' }} className="flex-row flex-center-align" key={index}>
                  <div style={{ width: 615 }}>
                    <LogInputContentInfo
                      event={item}
                      handleChange={(data) => {
                        setState({
                          srcDetails: update(srcDetails, {
                            [index]: { $set: { ...item, ...data } },
                          }),
                        });
                      }}
                    />
                  </div>
                  {(isMetricTab || srcDetails.length > 1) && (
                    <Button size="small" style={{ marginLeft: 34 }} onClick={() => handleDelete(index)}>
                      {intl.formatMessage(appButtonsMessages.delete)}
                    </Button>
                  )}
                </div>
              );
            }
          }, srcDetails)}
        </div>
      </>
    );
  };

  const changeTabKey = (activeTab) => {
    let newState = { activeTab };
    if (activeTab === 'KPIIncidents') {
      newState = {
        ...newState,
        ruleIncident: { patternName: '', incidentData: initIncidentDetail, delay: 0 },
        srcDetails: [],
        addIncidentType: 'Metric',
      };
    } else {
      newState = {
        ...newState,
        ruleIncident: { patternName: '', incidentData: '', delay: 0 },
        srcDetails: [initLogDetail],
        addIncidentType: 'Log',
      };
    }

    setState({ ...newState });
  };

  const incidentPatternError = ruleIncident.patternName;
  const incidentDataError = isMetricTab ? ruleIncident.incidentData.metricName : ruleIncident.incidentData;
  const hasEmptyDetail = R.filter((item) => R.isNil(item.content) || R.isEmpty(item.content), srcDetails).length > 0;
  const hasError = !incidentPatternError || !incidentDataError || hasEmptyDetail;

  return (
    <Modal
      title={intl.formatMessage(eventMessages.createNewGlobalKB)}
      width={900}
      visible
      maskClosable={false}
      bodyStyle={{ maxHeight: bodyHeight, overflowY: 'auto' }}
      onCancel={() => onClose()}
      onOk={submitGlobal}
      okButtonProps={{ disabled: hasError, loading }}
      okText={intl.formatMessage(appButtonsMessages.submit)}
    >
      <Tabs
        type="card"
        className="full-height ant-tabs-content-full-height flex-col ant-tabs-content-y"
        activeKey={activeTab}
        onChange={changeTabKey}
        style={{ maxHeight: bodyHeight - 48 }}
      >
        <Tabs.TabPane tab={intl.formatMessage(eventMessages.KPIIncidents)} key="KPIIncidents" style={{ padding: 16 }}>
          {kpiIncidentsRender()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage(eventMessages.logIncidents)} key="LogIncidents" style={{ padding: 16 }}>
          {kpiIncidentsRender()}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}

const CreateNewGlobalKBModalBase = injectIntl(CreateNewGlobalKBModal);
export default connect((state: State) => {
  const { credentials } = state.auth;
  return { credentials };
})(CreateNewGlobalKBModalBase);
