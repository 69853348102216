/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { NavLink } from 'react-router-dom';
import { FileAddOutlined, EditOutlined, ShareAltOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tabs, Tooltip, Button, Popover } from 'antd';

import { State } from '../../common/types';
import { Container, Input, DatePicker } from '../../lib/fui/react';
import {
  Defaults,
  parseQueryString,
  parseLocation,
  buildLocation,
  buildMatchLocation,
  buildUrl,
  pickNotNil,
  getLoadStatus,
} from '../../common/utils';
import { createLoadAction, loadProjectInfo, updateLastActionInfo } from '../../common/app/actions';
import {
  ActionTypes,
  createCausalGroup,
  createCausalIncident,
  updateCausalGroup,
  handleShareCausalGroup,
  removeCausalGroup,
} from '../../common/causal/actions';
import { appMenusMessages, appButtonsMessages, appFieldsMessages } from '../../common/app/messages';
import { causalMessages } from '../../common/causal/messages';

import CreateGroupModal from './components/CreateGroupModal';
import UpdateGroupModal from './components/UpdateGroupModal';
import RemoveGroupModal from './components/RemoveGroupModal';
import CausalGroupList from './components/CausalGroupList';
import CreateIncidentModal from './components/CreateIncidentModal';
import ShareCausalGroupModal from './components/ShareCausalGroupModal';

import CausalIncidentList from './components/CausalIncidentList';
import CausalIncident from './components/CausalIncident';
import CausalRelationTaskList from './components/CausalRelationTaskList';
import CausalRelationTaskManualList from './components/CausalRelationTaskManualList';
import CausalIncidentTask from './components/CausalIncidentTask';
import CausalDependencyList from './components/CausalDependencyList';

import './causal.scss';

type Props = {
  intl: Object,
  match: Object,
  location: Object,
  push: Function,
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,

  errorMessage: ?Object,
  currentLoadingComponents: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  loaderStatus: Object,
  timezoneOffset: Number,
  // eslint-disable-next-line
  userList: Array<Object>,
  projects: Array<Object>,
  projectDisplayMap: Object,
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  isReadUser: Boolean,
  causalGroupList: Array<Object>,
  defaultCausalMetricPairingThreshold: Number,
  causalIncidentList: Object,

  loadProjectInfo: Function,
  createCausalGroup: Function,
  createCausalIncident: Function,
  updateCausalGroup: Function,
  handleShareCausalGroup: Function,
  removeCausalGroup: Function,
};

class CausalAnalysisCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.incidentListLoader = 'causal_incident_list_loader';

    this.defaultView = 'relation';
    this.incidentListParamNames = ['causalKey', 'customerName', 'causalGraphVersion', 'tasksStartTime', 'tasksEndTime'];
    this.incidentParamNames = [
      ...this.incidentListParamNames,
      'relationKey',
      'fileName',

      'startTimestamp',
      'endTimestamp',
      'joinDependency',

      'resultStartstamp',
      'resultEndstamp',
    ];

    this.state = {
      // eslint-disable-next-line
      nameFilter: '',

      showCreateGroupModal: false,
      showCreateIncidentModal: false,
      showUpdateGroupModal: false,
      showShareCausalGroup: false,
      showRemoveGroupModal: false,
    };
  }

  componentDidMount() {
    this.handleGroupRefreshClick();

    // this.timer = setInterval(() => {
    //   this.refreshTaskTime();
    // }, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  @autobind
  refreshTaskTime() {
    const { location, push } = this.props;
    const query = parseLocation(location);
    const refreshTaskTime = moment.utc().valueOf();
    push(buildLocation(location.pathname, {}, { ...query, refreshTaskTime }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const params = parseLocation(this.props.location);
    const nextParams = parseLocation(nextProps.location);

    if (nextProps.causalGroupList !== this.props.causalGroupList) {
      const needReload = ['newCausal', 'newCausalManual'].indexOf(nextParams.causalGraphVersion) >= 0;
      if (needReload) {
        this.reloadData(nextProps);
      }
    } else if (nextProps.location !== this.props.location && !this.applyParamsAndRedirect(nextProps)) {
      if (
        params.causalGraphVersion !== nextParams.causalGraphVersion ||
        params.customerName !== nextParams.customerName ||
        params.causalKey !== nextParams.causalKey ||
        params.refreshTaskTime !== nextParams.refreshTaskTime
      ) {
        const needReload = ['newCausal', 'newCausalManual'].indexOf(nextParams.causalGraphVersion) >= 0;
        if (needReload) {
          const isAutoReload = params.refreshTaskTime !== nextParams.refreshTaskTime;
          this.reloadData(nextProps, isAutoReload);
        }
      }
    }
  }

  applyParamsAndRedirect(props) {
    const { location, match, push, replace, userInfo, userList, causalGroupList } = props;
    const params = parseQueryString(location.search);
    const { causalGraphVersion, tasksStartTime, tasksEndTime, causalKey, fileName, view } = params;

    let redirect = false;
    let replaceLocation = false;
    let newParams = { ...params };

    if (!causalGraphVersion) {
      newParams.causalGraphVersion = 'newCausal';
      replaceLocation = true;
    }
    if (causalKey && newParams.causalGraphVersion === 'newCausal' && (!tasksStartTime || !tasksEndTime)) {
      // use current causal group owner timezone to display start/end day
      const currentCausalGroup = R.find((c) => c.causalKey === causalKey, causalGroupList);
      const { owner } = currentCausalGroup || {};
      if (currentCausalGroup && owner) {
        let { timezoneOffset } = props;
        if (userInfo.isAdmin && owner) {
          const customerUserInfo = R.find((user) => user.userName === owner, userList || []);
          timezoneOffset = customerUserInfo ? customerUserInfo.userZoneOffset : timezoneOffset;
        }
        const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;

        newParams.tasksStartTime = moment.utc(nowTimestamp).startOf('day').subtract(15, 'days').valueOf();
        newParams.tasksEndTime = moment.utc(nowTimestamp).endOf('day').valueOf();
        replaceLocation = true;
      }
    }

    const showIncident = Boolean(fileName);
    if (showIncident && !view) {
      newParams.view = this.defaultView;
      replaceLocation = true;
    }

    if (newParams.view && newParams.view !== params.view) {
      newParams = {
        ...newParams,
        relationTimeThreshold: undefined,
        relationProbability: undefined,
        kpiPredictionProbability: undefined,
        relationCount: undefined,
        joinDependency: false,
      };
    }

    newParams = pickNotNil(newParams);
    if (!R.equals(newParams, params)) {
      redirect = true;
      if (replaceLocation) {
        replace(buildMatchLocation(match, {}, newParams));
      } else {
        push(buildMatchLocation(match, {}, newParams));
      }
    }

    return redirect;
  }

  @autobind
  reloadData(props, isAutoReload) {
    const { location } = props;
    const { createLoadAction, causalGroupList } = props;
    const params = pickNotNil(parseQueryString(location.search));
    const incidentListParams = R.pick(this.incidentListParamNames, params);
    const { customerName, causalKey, tasksStartTime, tasksEndTime, fileName, causalGraphVersion } = params;
    const tasksStartTimestamp = tasksStartTime ? Number(tasksStartTime) : undefined;
    const tasksEndTimestamp = tasksEndTime ? Number(tasksEndTime) : undefined;
    const currentCausalGroup = R.find((c) => c.causalKey === causalKey, causalGroupList);

    // not auto reload if in relation view
    if (isAutoReload && fileName) {
      return;
    }

    if (customerName && causalKey && currentCausalGroup) {
      const { interval } = currentCausalGroup || {};
      createLoadAction(
        ActionTypes.LOAD_CAUSAL_INCIDENTLIST,
        {
          isAutoReload,
          ...incidentListParams,
          tasksStartTimestamp,
          tasksEndTimestamp,
          interval,
          category: causalGraphVersion === 'newCausal' ? 'Scheduled' : 'On-demand',
        },
        this.incidentListLoader,
      );
    }
  }

  @autobind
  handleGroupRefreshClick() {
    const { createLoadAction } = this.props;
    createLoadAction(ActionTypes.LOAD_CAUSAL_GROUPLIST, {});
  }

  @autobind
  handleRefreshClick() {
    this.refreshTaskTime(this.props);
  }

  @autobind
  onChangeFilterStartTime(dateObj) {
    const { match, replace, location } = this.props;
    const params = parseQueryString(location.search);
    replace(
      buildMatchLocation(match, match.params, {
        ...params,
        tasksStartTime: dateObj ? dateObj.startOf('day').valueOf() : null,
      }),
    );
  }

  @autobind
  onChangeFilterEndTime(dateObj) {
    const { match, replace, location } = this.props;
    const params = parseQueryString(location.search);
    replace(
      buildMatchLocation(match, match.params, {
        ...params,
        tasksEndTime: dateObj ? dateObj.endOf('day').valueOf() : null,
      }),
    );
  }

  @autobind
  handleCreateGroupClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showCreateGroupModal: true });
  }

  @autobind
  handleCreateGroupModalClose(created) {
    this.setState({ showCreateGroupModal: false }, () => {
      if (created) this.handleGroupRefreshClick();
    });
  }

  @autobind
  handleUpdateGroupClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showUpdateGroupModal: true,
    });
  }

  @autobind
  handleUpdateGroupModalClose(updated, isUpdateFlag) {
    const { location, causalGroupList } = this.props;
    const params = parseQueryString(location.search);
    const { causalKey, customerName, causalGraphVersion } = params;
    const currentCausalGroup = R.find((c) => c.causalKey === causalKey, causalGroupList);
    const { causalName } = currentCausalGroup || {};

    let newState = {};
    if (!updated) {
      newState = { showUpdateGroupModal: false };
    }
    this.setState({ ...newState }, () => {
      const { match, push } = this.props;
      if (isUpdateFlag) {
        push(buildMatchLocation(match, {}, { causalName, causalKey, customerName, causalGraphVersion }));
        this.handleGroupRefreshClick();
      }
    });
  }

  @autobind
  handleRemoveGroupClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showRemoveGroupModal: true,
    });
  }

  @autobind
  handleRemoveGroupModalClose(removed) {
    const { match, replace, location } = this.props;
    const { customerName } = parseQueryString(location.search);
    this.setState(
      {
        showRemoveGroupModal: false,
      },
      () => {
        replace(buildUrl(match.path, {}, { customerName }));
        if (removed) this.handleGroupRefreshClick();
      },
    );
  }

  @autobind
  handleCausalGroupSelected(causalGroup) {
    const { match, push } = this.props;
    const { causalKey, customerName } = causalGroup;
    push(buildMatchLocation(match, {}, { causalKey, customerName }));
  }

  @autobind
  handleCreateIncidentClick() {
    this.setState({ showCreateIncidentModal: true });
  }

  @autobind
  handleShareCausalGroupClick() {
    this.setState({ showShareCausalGroup: true });
  }

  @autobind
  handleCreateIncidentModalClose(created) {
    this.setState({ showCreateIncidentModal: false }, () => {
      if (created) {
        const { match, push, location } = this.props;
        const params = parseQueryString(location.search);
        const { causalGraphVersion } = params;

        if (causalGraphVersion !== 'newCausalManual') {
          push(buildMatchLocation(match, match.params, { ...params, causalGraphVersion: 'newCausalManual' }));
        } else {
          this.handleRefreshClick();
        }
      }
    });
  }

  @autobind
  handleIncidentSelected(causalGroup) {
    const { match, push, location } = this.props;
    const incidentParams = R.pick(this.incidentParamNames, causalGroup);
    const params = parseQueryString(location.search);
    push(buildMatchLocation(match, {}, { ...params, ...incidentParams }));
  }

  @autobind
  handleCausalVersionChange(causalGraphVersion) {
    const { match, push, location, userInfo, userList, causalGroupList } = this.props;
    const params = parseQueryString(location.search);
    const { causalKey } = params;

    let tasksStartTime;
    let tasksEndTime;
    if (causalGraphVersion === 'newCausal' && causalKey) {
      // use current causal group owner timezone to display start/end day
      const currentCausalGroup = R.find((c) => c.causalKey === causalKey, causalGroupList);
      const { owner } = currentCausalGroup || {};
      if (currentCausalGroup && owner) {
        let { timezoneOffset } = this.props;
        if (userInfo.isAdmin && owner) {
          const customerUserInfo = R.find((user) => user.userName === owner, userList || []);
          timezoneOffset = customerUserInfo ? customerUserInfo.userZoneOffset : timezoneOffset;
        }
        const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;

        tasksStartTime = moment.utc(nowTimestamp).startOf('day').subtract(15, 'days').valueOf();
        tasksEndTime = moment.utc(nowTimestamp).endOf('day').valueOf();
      }
    }

    push(
      buildMatchLocation(match, match.params, {
        ...params,
        causalGraphVersion,
        tasksStartTime,
        tasksEndTime,
      }),
    );
  }

  @autobind
  handleViewChange(view) {
    const { match, push, location } = this.props;
    const params = parseQueryString(location.search);
    push(buildMatchLocation(match, match.params, { ...params, view }));
  }

  @autobind
  handleJoinDependencyChanged(newValue) {
    const { match, push, location } = this.props;
    const params = parseQueryString(location.search);
    const joinDependency = Boolean(newValue);
    push(buildMatchLocation(match, match.params, { ...params, joinDependency }));
  }

  render() {
    const { location, match, loadStatus, isReadUser, defaultCausalMetricPairingThreshold } = this.props;
    const {
      intl,
      projects,
      projectDisplayMap,
      currentLoadingComponents,
      errorMessage,
      loadProjectInfo,
      credentials,
      userInfo,
      updateLastActionInfo,
    } = this.props;
    const { createCausalGroup, updateCausalGroup, createCausalIncident, handleShareCausalGroup, removeCausalGroup } =
      this.props;
    const { causalGroupList, causalIncidentList } = this.props;

    const {
      showCreateIncidentModal,
      showCreateGroupModal,
      showUpdateGroupModal,
      showRemoveGroupModal,
      showShareCausalGroup,
    } = this.state;

    const params = parseQueryString(location.search);
    const { causalKey, customerName, causalGraphVersion, tasksStartTime, tasksEndTime, refreshTaskTime } = params;
    const { startTimestamp, endTimestamp, opener, pattern } = params;
    const { fileName, view } = params;
    const incidentListParams = R.pick(this.incidentListParamNames, params);
    const incidentParams = R.pick(this.incidentParamNames, params);
    const { relationTimeThreshold, relationProbability, kpiPredictionProbability, relationCount, joinDependency } =
      params;
    const currentCausalGroup = R.find((c) => c.causalKey === causalKey, causalGroupList);
    const { causalName, customerName: causalCustomerName, causalGroupKey } = currentCausalGroup || {};
    const isShareCausal = causalCustomerName !== userInfo.userName && !(userInfo.isAdmin || userInfo.isLocalAdmin);
    const causalIncidentInfo = R.find(
      (causalIncidentInfo) => causalIncidentInfo.fileName === fileName,
      causalIncidentList || [],
    );

    const showIntro = !causalKey && !fileName;
    const fromEventList = opener === 'eventList';
    const showIncidentList = Boolean(causalKey) && !fileName;
    const showIncident = Boolean(causalKey) && Boolean(fileName) && Boolean(view);
    const startTimeObj = moment.utc(parseInt(startTimestamp, 10));
    const endTimeObj = moment.utc(parseInt(endTimestamp, 10));

    // causal list filter
    const nameFilterLink = VLink.state(this, 'nameFilter');
    const nowObj = moment.utc().add(1, 'days');

    const { isLoading } = getLoadStatus(get(loadStatus, this.incidentListLoader), intl);
    return (
      <Container fullHeight withGutter className="causal flex-col">
        <Container className="flex-row" breadcrumb>
          <div className="section">
            <NavLink to={buildUrl(match.path, {}, { customerName })}>
              <span className="label">{intl.formatMessage(appMenusMessages.causalAnalysis)}</span>
            </NavLink>
            {(showIncidentList || showIncident) && <span className="divider">/</span>}
            {showIncidentList && <span className="label">{causalName}</span>}
            {showIncident && (
              <NavLink to={buildUrl(match.path, {}, { ...incidentListParams, customerName })}>
                <span className="label">{causalName}</span>
              </NavLink>
            )}
            {showIncident && <span className="divider">/</span>}
            {showIncident && (
              <span className="label">{`${startTimeObj.format(Defaults.DateFormat)} ~ ${endTimeObj.format(
                Defaults.DateFormat,
              )}`}</span>
            )}
          </div>
          {!showIncidentList && !showIncident && (
            <div className="flex-grow flex-row flex-center-align" style={{ justifyContent: 'flex-end' }}>
              <Button size="small" onClick={this.handleGroupRefreshClick}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </div>
          )}
          {showIncidentList && (
            <div className="flex-grow flex-row flex-center-align" style={{ justifyContent: 'flex-end' }}>
              {['newCausal'].indexOf(causalGraphVersion) >= 0 && (
                <div style={{ fontSize: 14, fontWeight: 'bold', marginRight: 8 }}>
                  {intl.formatMessage(appFieldsMessages.dateRange)}:
                </div>
              )}
              {['newCausal'].indexOf(causalGraphVersion) >= 0 && (
                <div className="ui input">
                  <DatePicker
                    utcOffset={0}
                    todayButton={intl.formatMessage(appFieldsMessages.today)}
                    dateFormat={Defaults.DateFormat}
                    maxDate={nowObj}
                    showYearDropdown
                    showMonthDropdown
                    selected={tasksStartTime ? moment.utc(Number(tasksStartTime)) : null}
                    onChange={this.onChangeFilterStartTime}
                  />
                </div>
              )}
              {['newCausal'].indexOf(causalGraphVersion) >= 0 && <span style={{ padding: '0 4px' }}>~</span>}
              {['newCausal'].indexOf(causalGraphVersion) >= 0 && (
                <div className="ui input" style={{ marginRight: 8 }}>
                  <DatePicker
                    utcOffset={0}
                    todayButton={intl.formatMessage(appFieldsMessages.today)}
                    dateFormat={Defaults.DateFormat}
                    maxDate={nowObj}
                    showYearDropdown
                    showMonthDropdown
                    selected={tasksEndTime ? moment.utc(Number(tasksEndTime)) : null}
                    onChange={this.onChangeFilterEndTime}
                  />
                </div>
              )}
              <Button size="small" onClick={this.handleRefreshClick}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </div>
          )}
        </Container>
        <Container
          className="flex-grow flex-row flex-min-height content-bg corner-10 overflow-hidden"
          style={{ margin: '0 16px 8px 16px', padding: 0 }}
        >
          {!showIncident && (
            <div className="flex-col flex-min-height border-right" style={{ width: 250, fontSize: 12, flexShrink: 0 }}>
              <div
                className="flex-row flex-center-align border-bottom"
                style={{ height: 45, padding: '0 8px', background: 'var(--component-background)' }}
              >
                <Input
                  valueLink={nameFilterLink}
                  icon="icon search"
                  placeholder={intl.formatMessage(appButtonsMessages.search)}
                  style={{ border: 'none' }}
                  fullWidth
                />
              </div>
              <CausalGroupList
                intl={intl}
                projects={projects}
                credentials={credentials}
                userInfo={userInfo}
                projectDisplayMap={projectDisplayMap}
                activeCausalName={causalName}
                nameFilter={nameFilterLink.value}
                causalGroupList={causalGroupList}
                onSelected={this.handleCausalGroupSelected}
              />
            </div>
          )}

          {showIntro && (
            <Container className="flex-col flex-grow content-bg" style={{ padding: 8 }}>
              {fromEventList && (
                <div className="ui warning message" style={{ margin: 0 }}>
                  <b>No causal relation found.</b>
                </div>
              )}
              <div className="ui info message" style={{ color: 'var(--text-color)' }}>
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage(causalMessages.causalAnalysisInfo) }} />
                <br />
                <br />
                <i className="icon info" />
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage(causalMessages.causalGroupInfo) }} />
              </div>
              <div>
                {!isReadUser && (
                  <Button size="small" type="primary" onClick={this.handleCreateGroupClick}>
                    {intl.formatMessage(causalMessages.createCausalGroup)}
                  </Button>
                )}
              </div>
            </Container>
          )}

          {showIncidentList && (
            <Container className="incident-list flex-col flex-grow content-bg">
              <Tabs
                className="full-height ant-tabs-content-full-height flex-col"
                activeKey={causalGraphVersion}
                onChange={this.handleCausalVersionChange}
                tabBarExtraContent={
                  <div className="full-height flex-row flex-center-align">
                    <Button.Group style={{ margin: '0 8px' }}>
                      {!isReadUser && (
                        <Tooltip
                          title={intl.formatMessage(causalMessages.createCausalAnalysis)}
                          placement="top"
                          mouseEnterDelay={0.3}
                        >
                          <Button size="small" type="primary" onClick={this.handleCreateIncidentClick}>
                            <FileAddOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      {!isReadUser && !isShareCausal && (
                        <Tooltip
                          title={intl.formatMessage(appButtonsMessages.update)}
                          placement="top"
                          mouseEnterDelay={0.3}
                        >
                          <Button size="small" type="primary" onClick={this.handleUpdateGroupClick}>
                            <EditOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      {!isReadUser && (
                        <Tooltip
                          title={intl.formatMessage(causalMessages.shareCausalGroup)}
                          placement="top"
                          mouseEnterDelay={0.3}
                        >
                          <Button size="small" type="primary" onClick={this.handleShareCausalGroupClick}>
                            <ShareAltOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      {!isReadUser && (
                        <Tooltip
                          title={intl.formatMessage(causalMessages.removeCausalGroup)}
                          placement="topRight"
                          mouseEnterDelay={0.3}
                        >
                          <Button size="small" type="primary" onClick={this.handleRemoveGroupClick}>
                            <DeleteOutlined />
                          </Button>
                        </Tooltip>
                      )}
                    </Button.Group>
                  </div>
                }
              >
                <Tabs.TabPane
                  tab={
                    <Popover
                      title={null}
                      content={
                        <div style={{ maxWidth: 320 }}>
                          Causal graphs automatically created by InsightFinder based on the user defined schedules
                          (i.e., causal graph calculation time span) over all selected projects (i.e., data sources).
                        </div>
                      }
                      placement="top"
                      mouseEnterDelay={0.3}
                    >
                      Scheduled causal graph creation
                    </Popover>
                  }
                  key="newCausal"
                >
                  <CausalRelationTaskList
                    intl={intl}
                    updateLastActionInfo={updateLastActionInfo}
                    isLoading={isLoading}
                    credentials={credentials}
                    causalGroup={currentCausalGroup}
                    projects={projects}
                    errorMessage={errorMessage}
                    causalIncidentList={R.filter((item) => !item.manualTask, causalIncidentList || [])}
                    onSelected={this.handleIncidentSelected}
                    onReload={this.handleRefreshClick}
                    userInfo={userInfo}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Popover
                      title={null}
                      content={
                        <div style={{ maxWidth: 320 }}>
                          Causal graphs manually created by the user based on dynamically defined time range schedules
                          (e.g., start date, end date) over all selected projects (i.e., data sources).
                        </div>
                      }
                      placement="top"
                      mouseEnterDelay={0.3}
                    >
                      On-demand causal graph creation
                    </Popover>
                  }
                  key="newCausalManual"
                >
                  <CausalRelationTaskManualList
                    intl={intl}
                    location={location}
                    updateLastActionInfo={updateLastActionInfo}
                    isLoading={isLoading}
                    credentials={credentials}
                    causalGroup={currentCausalGroup}
                    projects={projects}
                    errorMessage={errorMessage}
                    causalIncidentList={R.filter((item) => item.manualTask, causalIncidentList || [])}
                    onSelected={this.handleIncidentSelected}
                    onReload={this.handleRefreshClick}
                    userInfo={userInfo}
                  />
                </Tabs.TabPane>
                {!userInfo.isReadUser && (
                  <Tabs.TabPane tab="Dependency map list" key="causalDependency">
                    <CausalDependencyList
                      updateLastActionInfo={updateLastActionInfo}
                      onReload={this.handleRefreshClick}
                      customerName={customerName}
                      causalGroup={currentCausalGroup}
                      refreshTaskTime={refreshTaskTime}
                    />
                  </Tabs.TabPane>
                )}

                {false && (
                  <Tabs.TabPane tab="Historical Causal Graph" key="oldCausal">
                    <CausalIncidentList
                      intl={intl}
                      updateLastActionInfo={updateLastActionInfo}
                      isLoading={isLoading}
                      credentials={credentials}
                      causalGroup={currentCausalGroup}
                      projects={projects}
                      errorMessage={errorMessage}
                      causalIncidentList={causalIncidentList}
                      onSelected={this.handleIncidentSelected}
                      onReload={this.handleRefreshClick}
                    />
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Container>
          )}

          {showIncident && ['newCausal', 'newCausalManual'].indexOf(causalGraphVersion) >= 0 && (
            <CausalIncidentTask
              className="flex-grow"
              isLoadingTasks={isLoading}
              causalGroup={currentCausalGroup}
              causalIncidentInfo={causalIncidentInfo}
              causalIncidentList={causalIncidentList}
              incidentParams={incidentParams}
              view={view}
              relationTimeThreshold={relationTimeThreshold}
              relationProbability={relationProbability}
              relationCount={relationCount}
              joinDependency={joinDependency !== 'false'}
              kpiPredictionProbability={kpiPredictionProbability}
              onViewChange={this.handleViewChange}
              onJoinDependencyChanged={this.handleJoinDependencyChanged}
              hideKpiPrediction
            />
          )}
          {false && showIncident && causalGraphVersion === 'oldCausal' && (
            <CausalIncident
              className="flex-grow"
              isLoadingTasks={isLoading}
              causalGroup={currentCausalGroup}
              causalIncidentInfo={causalIncidentInfo}
              causalIncidentList={causalIncidentList}
              incidentParams={incidentParams}
              view={view}
              relationTimeThreshold={relationTimeThreshold}
              relationProbability={relationProbability}
              relationCount={relationCount}
              joinDependency={joinDependency !== 'false'}
              kpiPredictionProbability={kpiPredictionProbability}
              pattern={pattern}
              onViewChange={this.handleViewChange}
              onJoinDependencyChanged={this.handleJoinDependencyChanged}
              hideKpiPrediction
            />
          )}
        </Container>

        {showCreateGroupModal && (
          <CreateGroupModal
            loadProjectInfo={loadProjectInfo}
            causalGroupList={causalGroupList}
            createCausalGroup={createCausalGroup}
            causalGroup={currentCausalGroup}
            onClose={this.handleCreateGroupModalClose}
            defaultCausalMetricPairingThreshold={defaultCausalMetricPairingThreshold}
          />
        )}
        {showCreateIncidentModal && (
          <CreateIncidentModal
            intl={intl}
            causalGraphVersion={causalGraphVersion}
            errorMessage={errorMessage}
            currentLoadingComponents={currentLoadingComponents}
            projects={projects}
            causalGroup={currentCausalGroup}
            causalName={causalName}
            causalKey={causalKey}
            customerName={customerName}
            createCausalIncident={createCausalIncident}
            onClose={this.handleCreateIncidentModalClose}
          />
        )}

        {showShareCausalGroup && (
          <ShareCausalGroupModal
            intl={intl}
            updateLastActionInfo={updateLastActionInfo}
            credentials={credentials}
            userInfo={userInfo}
            errorMessage={errorMessage}
            currentLoadingComponents={currentLoadingComponents}
            projects={projects}
            causalGroup={currentCausalGroup}
            causalName={causalName}
            causalKey={causalKey}
            customerName={customerName}
            handleShareCausalGroup={handleShareCausalGroup}
            onClose={(reload) => {
              this.setState({ showShareCausalGroup: false }, () => {
                if (reload) this.handleGroupRefreshClick();
              });
            }}
          />
        )}
        {showUpdateGroupModal && (
          <UpdateGroupModal
            causalGroup={currentCausalGroup}
            causalName={causalName}
            causalKey={causalKey}
            customerName={customerName}
            updateCausalGroup={updateCausalGroup}
            onClose={this.handleUpdateGroupModalClose}
            defaultCausalMetricPairingThreshold={defaultCausalMetricPairingThreshold}
          />
        )}
        {showRemoveGroupModal && (
          <RemoveGroupModal
            intl={intl}
            errorMessage={errorMessage}
            currentLoadingComponents={currentLoadingComponents}
            causalName={causalName}
            causalKey={causalKey}
            customerName={causalGroupKey?.userLevelPartitionKey?.userName || customerName}
            removeCausalGroup={removeCausalGroup}
            onClose={this.handleRemoveGroupModalClose}
          />
        )}
      </Container>
    );
  }
}

const CausalAnalysis = injectIntl(CausalAnalysisCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { currentLoadingComponents, loadStatus, loaderStatus, timezoneOffset, userList, projectDisplayMap } =
      state.app;
    let { projects } = state.app;
    const { errorMessage, causalGroupListData, incidentListData } = state.causal;
    const { credentials, userInfo } = state.auth;
    const { isReadUser } = state.auth.userInfo;

    projects = R.filter((project) => project.status !== 'Deleting', projects);
    return {
      location,
      timezoneOffset,
      userList,
      projects,
      projectDisplayMap,
      currentLoadingComponents,
      loadStatus,
      loaderStatus,
      credentials,
      userInfo,
      isReadUser,
      errorMessage,
      causalGroupList: get(causalGroupListData, 'data', []),
      defaultCausalMetricPairingThreshold: get(causalGroupListData, 'defaultCausalMetricPairingThreshold', []),
      causalIncidentList: get(incidentListData, 'data', []),
    };
  },
  {
    push,
    replace,
    createLoadAction,
    updateLastActionInfo,
    loadProjectInfo,
    createCausalGroup,
    createCausalIncident,
    updateCausalGroup,
    handleShareCausalGroup,
    removeCausalGroup,
  },
)(CausalAnalysis);
