import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import update from 'immutability-helper';
import { get, isNumber } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import { Spin, message, Tag, Button, Select, Checkbox } from 'antd';

// import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, GlobalRenderers } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer, Popover } from '../../../lib/fui/react';

import { appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

import RootCauseChainSelectModal from './RootCauseChainSelectModal';
import EventActionModal from '../../metric/components/EventActionModal';
import RootCausesModal from './RootCausesModal';
import RootCauseTimelineList from './RootCauseTimelineList';

type Props = {
  isAutoHeight: Boolean,
  environmentId: String,
  systemId: String,
  rootCauseList: Array<Object>,
  incident: Object,
  onReload: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  timezoneOffset: Number,
  // eslint-disable-next-line
  currentLocale: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
};

class LikelyRootCausesListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    const { rootCauseList } = props;

    this.state = {
      isLoading: false,
      eventList: rootCauseList || [],
      activeChain: null,

      // take action
      rootCauseIncident: null,
      showRootCauseChainSelect: false,
      actionRootCauseKey: null,
      showActionModal: false,

      // details
      showRootCausesModal: false,
    };

    this.relevanceOptions = [
      { label: 'Unspecified', value: 'none', color: 'currentColor' },
      { label: 'High', value: 'high', color: 'red' },
      { label: 'Medium', value: 'medium', color: 'orange' },
      { label: 'Low', value: 'low', color: 'gray' },
    ];
    this.relevanceColorMap = R.fromPairs(R.map((item) => [item.value, item.color], this.relevanceOptions));
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rootCauseList !== this.props.rootCauseList) {
      this.parseData(nextProps);
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  parseData(props) {
    const { isAutoHeight, rootCauseList } = props;

    if (!isAutoHeight) this.cellMeasureCache.clearAll();
    this.setState({
      eventList: rootCauseList || [],
    });
  }

  @autobind
  renderListItemRootCauseItem(item, rowIndex, incident) {
    const { environmentId, systemId } = this.props;
    const { isExpand } = item;
    return (
      <div key={rowIndex} style={{ minHeight: 40 }}>
        <div
          className={`event-list-row clickable ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          onClick={() => this.handleEventClick(rowIndex, item)}
        >
          <div className="row-column flex-center-justify" style={{ width: 30 }} onClick={(e) => e.stopPropagation()}>
            {this.rendererChecked(rowIndex, item)}
          </div>
          <div className="row-column" style={{ width: 30, padding: 0 }}>
            {this.rendererExpand(rowIndex, item)}
          </div>
          <div className="row-column" style={{ width: 40 }}>
            <span className="circle" style={{ background: item.rankColor }}>
              <span className="circle-text">{item.rank}</span>
            </span>
          </div>
          <div className="row-column" style={{ width: 80 }}>
            {numeral(item.probability).format('0.0%')}
          </div>
          <div className="row-column" style={{ width: 60 }}>
            {item.count}
          </div>
          <div className="row-column flex-col break-all" style={{ width: 120, alignItems: 'flex-start' }}>
            {R.addIndex(R.map)((sourceInfo, idx) => {
              const { sourceDetail } = sourceInfo;
              const { isMetric, slope } = sourceDetail || {};
              if (!isMetric || !isNumber(slope)) return null;
              return <span key={idx}>{numeral(slope).format(Math.abs(slope) >= 1 ? '0,0' : '0,0.[000000]')}</span>;
            }, item.sourceInfoList || [])}
          </div>
          <div className="row-column flex-row flex-center-align break-all" style={{ width: 100, flex: 1 }}>
            {this.contentRenderer(item)}
          </div>
          <div className="row-column" style={{ width: 100 }}>
            {item.hasAction ? 'Action created' : '--'}
          </div>
          <div className="row-column flex-row flex-center-align break-all" style={{ width: 120 }}>
            {this.relevanceRenderer(item, rowIndex)}
          </div>
          <div className="row-column" style={{ width: 120 }}>
            {this.takeActionRender(item)}
          </div>
        </div>
        {isExpand && (
          <div className="full-width" style={{ padding: '8px 8px 8px 38px' }}>
            <RootCauseTimelineList
              incident={incident}
              selectChain={item}
              environmentId={environmentId}
              systemId={systemId}
            />
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderListItemRootCauseList(events, incident) {
    return ({ key, index: rowIndex, style, parent }) => {
      const item = events[rowIndex];
      if (!item) return null;

      const { environmentId, systemId } = this.props;
      const { isExpand } = item;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div style={{ ...style }}>
            <div
              className={`event-list-row clickable ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
              onClick={() => this.handleEventClick(rowIndex, item)}
            >
              <div
                className="row-column flex-center-justify"
                style={{ width: 30 }}
                onClick={(e) => e.stopPropagation()}
              >
                {this.rendererChecked(rowIndex, item)}
              </div>
              <div className="row-column" style={{ width: 30, padding: 0 }}>
                {this.rendererExpand(rowIndex, item)}
              </div>
              <div className="row-column" style={{ width: 40 }}>
                <span className="circle" style={{ background: item.rankColor }}>
                  <span className="circle-text">{item.rank}</span>
                </span>
              </div>
              <div className="row-column" style={{ width: 80 }}>
                {numeral(item.probability).format('0.0%')}
              </div>
              <div className="row-column" style={{ width: 60 }}>
                {item.count}
              </div>
              <div className="row-column flex-col break-all" style={{ width: 120, alignItems: 'flex-start' }}>
                {R.addIndex(R.map)((sourceInfo, idx) => {
                  const { sourceDetail } = sourceInfo;
                  const { isMetric, slope } = sourceDetail || {};
                  if (!isMetric || !isNumber(slope)) return null;
                  return <span key={idx}>{numeral(slope).format(Math.abs(slope) >= 1 ? '0,0' : '0,0.[000000]')}</span>;
                }, item.sourceInfoList || [])}
              </div>
              <div className="row-column flex-row flex-center-align break-all" style={{ width: 100, flex: 1 }}>
                {this.contentRenderer(item)}
              </div>
              <div className="row-column" style={{ width: 100 }}>
                {item.hasAction ? 'Action created' : '--'}
              </div>
              <div className="row-column flex-row flex-center-align break-all" style={{ width: 120 }}>
                {this.relevanceRenderer(item, rowIndex)}
              </div>
              <div className="row-column" style={{ width: 120 }}>
                {this.takeActionRender(item)}
              </div>
            </div>
            {isExpand && (
              <div className="full-width" style={{ padding: '8px 8px 8px 38px' }}>
                <RootCauseTimelineList
                  incident={incident}
                  selectChain={item}
                  environmentId={environmentId}
                  systemId={systemId}
                  onToggleCollapse={() => {
                    this.onToggleCollapse(rowIndex, item);
                  }}
                />
              </div>
            )}
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleEventClick(rowIndex, item) {
    const { isAutoHeight } = this.props;
    const { isExpand } = item;
    const { eventList } = this.state;
    this.setState(
      {
        eventList: update(eventList, {
          [rowIndex]: {
            $set: { ...(eventList[rowIndex] || {}), isExpand: !isExpand },
          },
        }),
      },
      () => {
        if (!isAutoHeight) this.cellMeasureCache.clearAll();
        if (this.refList) this.refList.forceUpdateGrid();
      },
    );
  }

  @autobind
  onToggleCollapse(rowIndex, item) {
    this.cellMeasureCache.clearAll();
    if (this.refList) this.refList.forceUpdateGrid();
  }

  @autobind
  rendererChecked(rowIndex, rowData) {
    const { isChecked } = rowData;
    return (
      <Checkbox
        checked={isChecked}
        onChange={(e) => {
          this.setState(
            (prevState) => ({
              eventList: update(prevState.eventList, {
                [rowIndex]: {
                  $set: { ...(prevState.eventList[rowIndex] || {}), isExpand: e.target.checked },
                },
              }),
            }),
            () => {
              if (this.refList) this.refList.forceUpdateGrid();
            },
          );
        }}
      />
    );
  }

  @autobind
  rendererExpand(rowIndex, rowData) {
    const { isExpand } = rowData;

    return (
      <div className="full-width flex-row flex-center-align flex-center-justify" style={{ height: 40 }}>
        {isExpand ? <DownOutlined style={{ fontSize: 14 }} /> : <RightOutlined style={{ fontSize: 14 }} />}
      </div>
    );
  }

  @autobind
  contentRenderer(item) {
    const { intl } = this.props;
    const { sourceInfoList } = item;
    return (
      <div className="full-width">
        {R.addIndex(R.map)((sourceInfo, idx) => {
          const { isMetric, isDeployment, sourceDetail, componentName } = sourceInfo;
          const { content } = sourceDetail;
          return (
            <div key={idx} className="flex-row flex-center-align" style={{ margin: idx !== 0 ? '2px 0' : '2px 0 0 0' }}>
              <span style={{ minWidth: 20 }}>{idx + 1}.</span>
              <span style={{ minWidth: 80 }}>
                {isMetric ? (
                  <Tag className="inline-block" color="red" style={{ lineHeight: '18px' }}>
                    {intl.formatMessage(appFieldsMessages.metric)}
                  </Tag>
                ) : isDeployment ? (
                  <Tag className="inline-block" color="orange" style={{ lineHeight: '18px' }}>
                    {intl.formatMessage(logMessages.deployment)}
                  </Tag>
                ) : (
                  <Tag className="inline-block" style={{ lineHeight: '18px' }}>
                    {intl.formatMessage(appFieldsMessages.log)}
                  </Tag>
                )}
              </span>
              <span className="light-label bold" style={{ minWidth: 80 }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </span>
              <span className="hidden-line-with-ellipsis inline-block" style={{ marginRight: 8 }}>
                {componentName}
              </span>
              {isMetric && (
                <>
                  <span className="light-label bold" style={{ minWidth: 50 }}>
                    {intl.formatMessage(appFieldsMessages.metric)}:
                  </span>
                  <span className="hidden-line-with-ellipsis inline-block">{content}</span>
                </>
              )}
            </div>
          );
        }, sourceInfoList)}
      </div>
    );
  }

  @autobind
  relevanceRenderer(item, rowIndex) {
    const { intl, timezoneOffset, userInfo } = this.props;
    const { isLoading, relevance, reporterRecordSet } = item;
    return (
      <Popover
        title={intl.formatMessage(eventActionMessages.reporter)}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 350, maxHeight: 100 }}>
            {R.addIndex(R.map)((item, idx) => {
              const { timestamp, reporterName, action } = item;
              const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
              return (
                <div key={idx} className="flex-row flex-center-align">
                  <div style={{ width: 80 }}>{moment.utc(customerTimestamp).format(Defaults.ShortTimeFormat)}</div>
                  <div className="bold light-label" style={{ marginRight: 4 }}>
                    {intl.formatMessage(eventActionMessages.reporter)}:
                  </div>
                  <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                  <span style={{ marginLeft: 2 }}>{reporterName}</span>
                  <div className="bold light-label" style={{ marginLeft: 8, marginRight: 4 }}>
                    {intl.formatMessage(eventActionMessages.action)}:
                  </div>
                  <span style={{ margin: '0 4px', color: this.relevanceColorMap[action] }}>{action}</span>
                </div>
              );
            }, reporterRecordSet || [])}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="top"
      >
        <Spin size="small" spinning={!!isLoading} wrapperClassName="spin-full-width full-width">
          <Select
            showArrow={false}
            size="small"
            style={{ width: '100%' }}
            value={relevance}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            disabled={userInfo.isReadUser}
            onChange={(relevance) => this.onChangeRelevance({ rowData: item, rowIndex, relevance })}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          >
            {R.map((item) => {
              return (
                <Select.Option key={item.value}>
                  <span style={{ color: item.color }}>{item.label}</span>
                </Select.Option>
              );
            }, this.relevanceOptions)}
          </Select>
        </Spin>
      </Popover>
    );
  }

  @autobind
  takeActionRender(item) {
    const { intl, userInfo, incident } = this.props;

    if (userInfo.isReadUser || R.toLower(get(incident, 'type', '')) !== 'incident') {
      return null;
    }

    return (
      <Button
        className="tour-RCA-button-takeaction"
        type="primary"
        size="small"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          this.handleSuggestedActionsClick({ event: incident, item });
        }}
      >
        {intl.formatMessage(eventMessages.takeAction)}
      </Button>
    );
  }

  @autobind
  onChangeRelevance({ rowData, rowIndex, relevance }) {
    const { intl, credentials, incident } = this.props;
    const { rootCauseKey, operation } = rowData;
    const {
      category,
      projectOwner,
      anomalyLogInstance,
      instanceList,
      startTimestamp,
      endTimestamp,
      patternId,
      type,
      rootCauseTableKey,
    } = incident;
    let { projectName } = incident;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
    const event = {
      nid: patternId,
      eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
    };
    const oldValue = get(rowData, 'relevance');

    this.setState(
      (prevState) => ({
        eventList: update(prevState.eventList, {
          [rowIndex]: {
            $set: { ...(prevState.eventList[rowIndex] || {}), isLoading: true, relevance },
          },
        }),
      }),
      () => {
        if (this.refList) this.refList.forceUpdateGrid();
      },
    );

    this.props.updateLastActionInfo();
    fetchPost(
      getEndpoint('updateRelevance', 1),
      {
        ...credentials,
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime,
        endTime,
        operation,
        rootCauseTableKey: JSON.stringify(rootCauseTableKey),
        event: JSON.stringify(event),
        rootCauseKey,
        relevance,
      },
      {},
      false,
    )
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.props.onReload();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        // rollback
        this.setState(
          (prevState) => ({
            eventList: update(prevState.eventList, {
              [rowIndex]: {
                $set: { ...(prevState.eventList[rowIndex] || {}), isLoading: false, oldValue },
              },
            }),
          }),
          () => {
            if (this.refList) this.refList.forceUpdateGrid();
          },
        );
      });
  }

  @autobind
  handleSuggestedActionsClick({ event, item }) {
    const { credentials } = this.props;
    const { projectOwner } = event;
    let { projectName } = event;
    // parse params to get root cause
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const rootCauseIncident = {
      ...event,
      projectName,
    };

    const actionRootCauseKey = JSON.stringify(R.map((item) => item.actionRootCauseKey, [item]));
    this.setState({ rootCauseIncident, showActionModal: true, actionRootCauseKey });
  }

  @autobind
  handleGroupSuggestedActionsClick() {
    const { credentials, incident } = this.props;
    const { eventList } = this.state;

    const { projectOwner } = incident;
    let { projectName } = incident;
    // parse params to get root cause
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const rootCauseIncident = {
      ...incident,
      projectName,
    };

    const checkedList = R.filter((item) => item.isChecked, eventList || []);
    const actionRootCauseKey = JSON.stringify(R.map((item) => item.actionRootCauseKey, checkedList));
    this.setState({ rootCauseIncident, showActionModal: true, actionRootCauseKey });
  }

  @autobind
  handleActionSelectConfirm(actionRootCauseKey) {
    this.setState({ showRootCauseChainSelect: false, showActionModal: true, actionRootCauseKey });
  }

  @autobind
  getProjectNameList() {
    const { systemsMap, systemId } = this.props;
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  handleCheckAllChange(e) {
    const isCheckAll = e.target.checked;
    this.setState(
      (prevState) => ({
        eventList: R.map((item) => ({ ...item, isChecked: isCheckAll }), prevState.eventList),
      }),
      () => {
        if (this.refList) this.refList.forceUpdateGrid();
      },
    );
  }

  @autobind
  renderListHeader({ intl, isCheckAll }) {
    return (
      <>
        <div className="header-column flex-center-justify" style={{ width: 30 }}>
          <Checkbox checked={isCheckAll} onChange={(e) => this.handleCheckAllChange(e)} />
        </div>
        <div className="header-column" style={{ width: 30 }} />
        <div className="header-column" style={{ width: 40 }}>
          {intl.formatMessage(logMessages.rankNumber)}
        </div>
        <div className="header-column" style={{ width: 80 }}>
          {intl.formatMessage(appFieldsMessages.probability)}
        </div>
        <div className="header-column" style={{ width: 60 }}>
          {intl.formatMessage(appFieldsMessages.count)}
        </div>
        <div className="header-column" style={{ width: 120 }}>
          {intl.formatMessage(eventMessages.slope)}
        </div>
        <div className="header-column" style={{ width: 100, flex: 1 }}>
          {intl.formatMessage(appMenusMessages.patterns)}
        </div>
        <div className="header-column" style={{ width: 100 }}>
          {intl.formatMessage(appFieldsMessages.actionStatus)}
        </div>
        <div className="header-column" style={{ width: 120 }}>
          {intl.formatMessage(appFieldsMessages.relevance)}
        </div>
        <div className="header-column" style={{ width: 120 }} />
      </>
    );
  }

  render() {
    const { intl, isAutoHeight, incident, environmentId, systemId } = this.props;
    const { isLoading, eventList, activeChain } = this.state;
    const { rootCauseIncident } = this.state;

    const isCheckAll = R.findIndex((item) => !item.isChecked, eventList) === -1;

    return (
      <>
        {isAutoHeight && (
          <div className="event-list">
            <div className="event-list-header" style={{ height: 30 }}>
              {this.renderListHeader({ intl, isCheckAll })}
            </div>
            <div className="event-list-grid">
              {R.addIndex(R.map)(
                (rowData, index) => this.renderListItemRootCauseItem(rowData, index, incident),
                eventList,
              )}
            </div>
          </div>
        )}

        {!isAutoHeight && (
          <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
            <AutoSizer>
              {({ width, height }) => (
                <div className="event-list">
                  <div
                    className="event-list-header"
                    style={{ height: 30, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                  >
                    {this.renderListHeader({ intl, isCheckAll })}
                  </div>
                  <List
                    ref={(ref) => {
                      this.refList = ref;
                    }}
                    className="event-list-grid"
                    width={width}
                    height={height - 30}
                    rowCount={eventList.length}
                    rowHeight={this.cellMeasureCache.rowHeight}
                    overscanRowCount={4}
                    deferredMeasurementCache={this.cellMeasureCache}
                    rowRenderer={this.renderListItemRootCauseList(eventList, incident)}
                    onScrollbarPresenceChange={({ horizontal, vertical }) => {
                      if (vertical) {
                        this.listNodeHeaderScrollbar = true;
                      } else {
                        this.listNodeHeaderScrollbar = false;
                      }
                      this.forceUpdate();
                    }}
                  />
                </div>
              )}
            </AutoSizer>
          </Spin>
        )}

        {this.state.showRootCauseChainSelect && (
          <RootCauseChainSelectModal
            allRootCauseAndPredictList={eventList}
            rootCauseKey={get(activeChain, 'rootCauseKey')}
            incident={rootCauseIncident}
            isLoadingRootCause={isLoading}
            reloadRootCause={() => this.props.onReload()}
            onConfirm={this.handleActionSelectConfirm}
            onClose={() => this.setState({ showRootCauseChainSelect: false })}
          />
        )}
        {this.state.showActionModal && (
          <EventActionModal
            suggestActions={get(activeChain, 'suggestActions', [])}
            incident={rootCauseIncident}
            projectName={rootCauseIncident.projectName}
            projectNameList={this.getProjectNameList()}
            actionRootCauseKey={this.state.actionRootCauseKey}
            onClose={(needReload) => {
              this.setState({ showActionModal: false }, () => {
                if (needReload) this.props.onReload();
              });
            }}
          />
        )}
        {this.state.showRootCausesModal && (
          <RootCausesModal
            environmentId={environmentId}
            systemId={systemId}
            incident={incident}
            activeChain={activeChain}
            onClose={(reload) =>
              this.setState({ showRootCausesModal: false, activeChain: null }, () => {
                if (reload) this.props.onReload();
              })
            }
          />
        )}
      </>
    );
  }
}

const LikelyRootCausesList = injectIntl(LikelyRootCausesListCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset, currentLocale, projectDisplayMap, systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      timezoneOffset,
      currentLocale,
      projectDisplayMap,
      systemsMap,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(LikelyRootCausesList);
