/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import eventListEpic from './eventListEpic';
import loadLogIncidentListEpic from './loadLogIncidentListEpic';
import logDetectionEpic from './logDetectionEpic';
import sequenceEventListEpic from './sequenceEventListEpic';
import setPatternNameEpic from './setPatternNameEpic';
import updateLogPatternFlagEpic from './updateLogPatternFlagEpic';

const epics = [
  eventListEpic,
  loadLogIncidentListEpic,
  sequenceEventListEpic,
  setPatternNameEpic,
  logDetectionEpic,
  updateLogPatternFlagEpic,
];

export default epics;
