/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Button, Alert, Input, Select, DatePicker, Card } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Popover } from '../../../../lib/fui/react';

import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  credentials: Object,
  componentState: Object,
};

class AWSCloudSetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;

    const state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      accessKeyId: '',
      secretKey: '',

      costType: 'UnblendedCost',
      samplingInterval: 1,
      collectionFrequency: 1,
      millisecondUnit: 3600000,
      granularity: 'daily',
      historicalDates: [null, null],
    };

    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);

    this.state = {
      ...state,

      ...(componentState || {}),
    };
    this.costTypeOption = [
      { label: 'AmortizedCost', value: 'AmortizedCost' },
      { label: 'Unblended Cost', value: 'UnblendedCost' },
    ];
    this.samplingUnitOption = [
      { label: 'Hourly', value: 'hourly' },
      { label: 'Daily', value: 'daily' },
    ];
    this.millisecondUnitOption = [{ label: 'hour', value: 3600000 }];
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isVerifying: true });
    const { intl, credentials } = this.props;
    const { accessKeyId, secretKey } = this.state;

    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: 'AWSCost',
      accessKeyId,
      secretKey,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
              samplingInterval: 1,
              collectionFrequency: 1,
            },
            () => {
              this.props.saveProjectInfo('AWSCloud', {}, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('AWSCloud', {}, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
          },
          () => {
            this.props.saveProjectInfo('AWSCloud', {}, this.state);
          },
        );
      });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { accessKeyId, secretKey, costType } = this.state;
    const { samplingInterval, collectionFrequency, granularity, historicalDates, millisecondUnit } = this.state;

    this.props.createProject(
      'AWSCloud',
      {
        operation: 'register',
        accessKeyId,
        secretKey,
        costType,
        granularity,
        samplingInterval: Number(samplingInterval) * (granularity === 'hourly' ? 60 : 1440),
        collectionFrequency: Number(collectionFrequency) * millisecondUnit,
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
      },
      this.state,
    );
  }

  render() {
    const { intl, isLoading, hasError } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,

      accessKeyId,
      secretKey,

      costType,
      samplingInterval,
      collectionFrequency,
      granularity,
      historicalDates,
      millisecondUnit,
    } = this.state;
    const hasVerifyError = !accessKeyId || !secretKey;
    const hasErrorRegister = hasVerifyError;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.AWSCloudIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item
              label="Access Key Id"
              validateStatus={!accessKeyId ? 'error' : 'success'}
              help={!accessKeyId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={accessKeyId}
                onChange={(e) => this.setState({ accessKeyId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Secret Key"
              validateStatus={!secretKey ? 'error' : 'success'}
              help={!secretKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={secretKey}
                onChange={(e) => this.setState({ secretKey: e.target.value })}
              />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>

        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            <Form.Item
              label={
                <div className="flex-row flex-center-align">
                  <span style={{ marginRight: 8 }}>Cost Type</span>
                  <Popover
                    content={
                      <div style={{ width: 400 }}>
                        If you purchased Savings Plans or Reservations, you can choose amortized costs to better
                        analyzing your cost trends. Otherwise, unblended costs should be selected.
                      </div>
                    }
                    placement="right"
                    mouseEnterDelay={0.3}
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </div>
              }
              required
              validateStatus={!costType ? 'error' : 'success'}
              help={!costType ? 'This input is required.' : undefined}
            >
              <Select
                style={{ width: 200 }}
                options={this.costTypeOption}
                value={costType}
                onChange={(costType) => this.setState({ costType })}
              />
            </Form.Item>
            <Form.Item
              label="Granularity"
              validateStatus={!granularity ? 'error' : 'success'}
              help={!granularity ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <Select
                style={{ width: 120 }}
                options={this.samplingUnitOption}
                value={granularity}
                onChange={(granularity) => this.setState({ granularity })}
              />
            </Form.Item>
            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item
              label="Collection Frequency"
              validateStatus={!collectionFrequency ? 'error' : 'success'}
              help={!collectionFrequency ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={collectionFrequency}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ collectionFrequency: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.millisecondUnitOption}
                  value={millisecondUnit}
                  onChange={(millisecondUnit) => this.setState({ millisecondUnit })}
                />
              </div>
            </Form.Item>

            <Form.Item label="Historical Date Range">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={historicalDates}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(historicalDates) => this.setState({ historicalDates })}
              />
            </Form.Item>
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasErrorRegister || hasError}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const GoogleCloudCostSetting = injectIntl(AWSCloudSetting);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(GoogleCloudCostSetting);
