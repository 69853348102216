/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import { get } from 'lodash';

import type { Credentials } from '../types';
import fetchPost from './fetchPost';
import getEndpoint from './getEndpoint';

const getInstanceMetaData = (credentials: Credentials, params: Object) => {
  const { projectName, instanceGroup, isContainerProj, ...rest } = params;

  // Get Instance Meta Data info
  return fetchPost(getEndpoint('instanceMetaData'), {
    ...credentials,
    ...rest,
    projectName,
    instanceGroup,
  }).then((queryResult) => {
    const appNameMapping = {};
    const metricObj = {};
    const containerObj = {};
    let metricList = [];

    const data = queryResult?.data || [];
    const containerLevelTreeMapNodeInfoMap = JSON.parse(
      get(data[0], ['result', 'containerLevelTreeMapNodeInfoMap'], '{}'),
    );
    const instanceLevelTreeMapNodeInfoMap = JSON.parse(
      get(data[0], ['result', 'instanceLevelTreeMapNodeInfoMap'], '{}'),
    );
    const parseContainerLevelTreeMapNodeInfoMap = (containerInfoMap) => {
      R.forEachObjIndexed((list, key) => {
        R.forEach((val) => {
          const fullId = val.idInProjectMetadata;
          let instance = fullId;

          const isContainer = val.idInProjectMetadata !== val.id;
          if (!val.hasContainer || val.metricModelList.length > 0) {
            if (!metricObj[fullId]) metricObj[fullId] = [];
            const metricObjList = val.metricModelList;
            R.forEach((metric) => {
              metricObj[fullId].push(metric.metricName);
            }, metricObjList);
          }
          if (isContainer && isContainerProj) {
            instance = val.idInProjectMetadata.split('_')[1];
            if (!containerObj[instance]) containerObj[instance] = [];
            containerObj[instance].push(val.idInProjectMetadata.split('_')[0]);
          }
          if (val.componentName) appNameMapping[instance] = val.componentName;
        }, list);
      }, containerInfoMap);
    };
    const parseInstanceLevelTreeMapNodeInfoMap = (instanceInfoMap) => {
      R.forEachObjIndexed((val, key) => {
        const fullId = val.idInProjectMetadata;
        let instance = fullId;

        const isContainer = val.idInProjectMetadata !== val.id;
        if (!val.hasContainer || val.metricModelList.length > 0) {
          if (!metricObj[fullId]) metricObj[fullId] = [];
          const metricObjList = val.metricModelList;
          R.forEach((metric) => {
            metricObj[fullId].push(metric.metricName);
          }, metricObjList);
        }
        if (isContainer && isContainerProj) {
          instance = val.idInProjectMetadata.split('_')[1];
          if (!containerObj[instance]) containerObj[instance] = [];
          containerObj[instance].push(val.idInProjectMetadata.split('_')[0]);
        }
        if (val.componentName) appNameMapping[instance] = val.componentName;
      }, instanceInfoMap);
    };
    parseContainerLevelTreeMapNodeInfoMap(containerLevelTreeMapNodeInfoMap);
    parseInstanceLevelTreeMapNodeInfoMap(instanceLevelTreeMapNodeInfoMap);

    R.forEachObjIndexed((val) => {
      metricList = [...metricList, ...val];
    }, metricObj);
    metricList = R.uniq(R.filter((m) => Boolean(m), metricList));
    return { appNameMapping, metricObj, containerObj, metricList };
  });
};
export default getInstanceMetaData;
