/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { createLogic } from 'redux-logic';

import { getQueryResult, getProjectInfo, getInstanceMetaData, getMetricEventList, getActiveInstance } from '../../apis';
import { ActionTypes } from '../actions';
import { createSetAction, ActionTypes as AppActionTypes, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions, GlobalParse } from '../../utils';

import { appMessages } from '../../app/messages';
import getNoop from '../../apis/getNoop';

const loadQueryResultLogic = createLogic({
  type: [ActionTypes.LOAD_QUERY_RESULT],
  cancelType: ActionTypes.CANCEL_QUERY,
  debounce: 300,
  latest: true,
  process: ({ getState, action, cancelled$ }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { allProjects } = state.app;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    dispatch(createSetAction(ActionTypes.SET_QUERY_RESULT, params, []));

    const { isTimeChange, needProjectInfo, ...filterParams } = params || {};
    const { projectName, instanceGroup, templateId } = params || {};
    const { startTime, endTime, startTimestamp, endTimestamp, forecastInterval } = params || {};
    const projectList = [];
    let hasAllInstanceInfo = false;
    let isLog = false;
    let isContainerProj = false;
    const project = R.find((p) => p.projectName === projectName, allProjects || []);

    if (project) {
      isLog = project.isLog || false;
      isContainerProj = project.isContainer || false;
      hasAllInstanceInfo = project.hasAllInstanceInfo || false;
      projectList.push({
        projectName: project.projectShortName,
        customerName: project.owner,
      });
    }
    const needReloadProjectInfo = needProjectInfo && (isTimeChange || !hasAllInstanceInfo);
    const seqMap = {};
    let seq = 0;

    const isMetricTreeMap = templateId === 'e3c3c5e8172f4a1396988747e6dc09be';
    // other params for api call
    // Get api data
    const specialQueryApi = {
      // Show me any predicted anomaly events.
      c8dcea3a7a9fc4060b3a554b16c4a84d3: {
        api: getMetricEventList,
        params: {
          eventType: 'predicted',
          startTimeObj: moment.utc().startOf('day'),
          endTimeObj: moment.utc().endOf('day'),
        },
        typeId: '535c5454d87d49bd970f18e311029a36',
      },
      // eslint-disable-next-line camelcase
      search_all_log_entries: {
        api: getNoop,
        params: {
          startTimestamp: startTime,
          endTimestamp: endTime,
          consolidateFlag: false,
          pageNo: 1,
          pageSize: 100,
        },
        typeId: 'search_all_log_entries',
      },
    };
    const reqDetails = get(specialQueryApi, [templateId]);
    const requests = [
      reqDetails
        ? reqDetails.api(credentials, { ...filterParams, ...reqDetails.params })
        : getQueryResult(credentials, { ...filterParams, isLog }),
    ];
    seqMap.getQuery = seq;
    seq += 1;

    // Get appName mapping info
    const needAppNameMap = [
      'd3554c441470434a958e0fc26cd7fb5a',
      // Show me the most anomalous metric pattern
      '44295d4bd7c0486693af4e6a2ae5e943',
    ];
    if (needAppNameMap.indexOf(templateId) >= 0) {
      requests.push(getInstanceMetaData(credentials, { projectName, instanceGroup, isContainerProj }));
      seqMap.getAppNameMap = seq;
      seq += 1;
    }

    // Get project info
    let projectStartTime = moment.utc().startOf('day').valueOf();
    let projectEndTime = moment.utc().endOf('day').valueOf();
    if (startTime || startTimestamp) {
      projectStartTime = moment
        .utc(Number(startTime || startTimestamp))
        .startOf('day')
        .valueOf();
      if (forecastInterval) {
        projectEndTime = Number(startTime) + Number(forecastInterval);
      } else {
        projectEndTime = moment
          .utc(Number(endTime || endTimestamp))
          .endOf('day')
          .valueOf();
      }
    }
    if (needReloadProjectInfo) {
      requests.push(
        getProjectInfo(credentials, {
          projectList,
          startTimestamp: projectStartTime,
          endTimestamp: projectEndTime,
          includeInstance: true,
        }),
      );
      seqMap.getProject = seq;
      seq += 1;
    }

    Promise.all(requests)
      .then((results) => {
        let data;
        if (needReloadProjectInfo) {
          data = GlobalParse.updateProjectInstanceInfo({ data: results[seqMap.getProject], projectName });
          dispatch(createSetAction(AppActionTypes.SET_PROJECT_INFO, { projectName }, data));
        }
        if (needAppNameMap.indexOf(templateId) >= 0) {
          dispatch(createSetAction(ActionTypes.SET_APP_NAME_MAP, filterParams, results[seqMap.getAppNameMap]));
        }
        if (reqDetails) {
          data = [
            {
              typeId: reqDetails.typeId,
              meta: {},
              result: results[seqMap.getQuery],
            },
          ];
        } else {
          data = results[seqMap.getQuery];
        }
        dispatch(createSetAction(ActionTypes.SET_QUERY_RESULT, filterParams, data));
        if (isMetricTreeMap) {
          const idList = [];
          const treeMapNodeInfoMap = JSON.parse(get(data[0], ['result', 'treeMapNodeInfoMap'], '{}'));
          R.forEachObjIndexed((val, key) => {
            idList.push(val.idInProjectMetadata);
          }, treeMapNodeInfoMap);
          const customerName = project.owner;
          getActiveInstance(credentials, {
            customerName,
            startTime: projectStartTime,
            endTime: projectEndTime,
            projectNameList: JSON.stringify([
              {
                projectName: project.projectShortName,
                projectType: project.dataType,
                idList,
              },
            ]),
          }).then((data) => {
            dispatch(createSetAction(AppActionTypes.SET_ACTIVE_INSTANCE, {}, data));
            dispatch(hideLoading);
          });
        }
        dispatch(hideLoading);
      })
      .catch((err) => {
        dispatch(
          errorLoading(appMessages.errorsAPIMessage, {
            message: err.message,
            error: err,
          }),
        );
      })
      .then(() => {
        done();
      });

    cancelled$.subscribe((cancellAction) => {
      dispatch(hideLoading);
    });
  },
});

export default loadQueryResultLogic;
