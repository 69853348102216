/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { CellMeasurer, CellMeasurerCache, createMasonryCellPositioner, Masonry } from 'react-virtualized';

import { Box } from '../../../lib/fui/react';
import { Defaults } from '../../../common/utils';
import { queryMessages } from '../../../common/query/messages';

type Props = {
  intl: Object,
  projects: Array<Object>,
  isAdmin: Boolean,

  templateCategoryList: Array<Object>,
  width: Number,
  height: Number,
  onTemplateSelected: Function,
};

class QueryCategoryCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.columnWidth = 421;
    this.columnCount = 3;
    this.spacer = 8;

    this.cellMeasureCache = new CellMeasurerCache({
      defaultHeight: 200,
      defaultWidth: this.columnWidth,
      fixedWidth: true,
    });

    this.cellPositioner = createMasonryCellPositioner({
      cellMeasurerCache: this.cellMeasureCache,
      columnCount: this.columnCount,
      columnWidth: this.columnWidth,
      spacer: this.spacer,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (nextProps.height !== this.props.height ||
        nextProps.width !== this.props.width ||
        nextProps.templateCategoryList !== this.props.templateCategoryList) &&
      this.masonry
    ) {
      // IMPORTANT: cellPositoner must be reset when props changed.
      this.cellPositioner.reset({
        columnCount: this.columnCount,
        columnWidth: this.columnWidth,
        spacer: this.spacer,
      });
      this.masonry.recomputeCellPositions();
    }
  }

  @autobind
  handleTemplateClick(template) {
    return (event) => {
      event.preventDefault();
      this.props.onTemplateSelected(template);
    };
  }

  @autobind
  categoryRenderer({ index, key, parent, style }) {
    const { intl, templateCategoryList } = this.props;
    const category = templateCategoryList[index];
    const { name, templateList } = category || {};
    const color = Defaults.ColorNames[index % Defaults.ColorNames.length];

    const messageName = R.replace(/[()]/g, '', R.replace(/ /g, '_', name));
    const nameInfo = R.has(messageName, queryMessages) ? intl.formatMessage(queryMessages[messageName]) : name;
    return (
      <CellMeasurer cache={this.cellMeasureCache} index={index} key={key} parent={parent}>
        <div style={{ ...style }}>
          <Box className="category">
            <div className="header">
              <span className={`ui ${color} label`}>{nameInfo[0]}</span>
              <span className="name">{nameInfo}</span>
            </div>
            {R.map((template) => {
              const displayName = template.name || template.desc;
              const message = R.has(template.id, queryMessages)
                ? intl.formatMessage(queryMessages[template.id], { displayName })
                : displayName;
              return (
                <div className="template" key={template.id}>
                  {template.notImplemented && <div style={{ color: 'grey' }}>{`* ${message}`}</div>}
                  {!template.notImplemented && (
                    <a className="clickable" onClick={this.handleTemplateClick(template)}>
                      {message}
                    </a>
                  )}
                  {!template.notImplemented && Boolean(template.name) && <div className="desc">{message}</div>}
                </div>
              );
            }, templateList)}
          </Box>
        </div>
      </CellMeasurer>
    );
  }

  render() {
    const { templateCategoryList, height, width } = this.props;
    return (
      <Masonry
        width={width}
        height={height}
        cellCount={templateCategoryList.length}
        cellMeasurerCache={this.cellMeasureCache}
        cellPositioner={this.cellPositioner}
        cellRenderer={this.categoryRenderer}
        ref={(masonry) => {
          this.masonry = masonry;
        }}
      />
    );
  }
}

const QueryCategory = injectIntl(QueryCategoryCore);
export default connect((state: State) => {
  const { projects } = state.app;
  const { isAdmin } = state.auth.userInfo;

  return {
    projects,
    isAdmin,
  };
}, {})(QueryCategory);
