/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import { Observable } from 'rxjs/Observable';
import apiErrorHandle from './apiErrorHandle';

const apiEpicErrorHandle = (err: Error) => {
  return Observable.of(apiErrorHandle(err));
};

export default apiEpicErrorHandle;
