/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React, { useState, useEffect } from 'react';

import * as R from 'ramda';
import moment from 'moment';
// import update from 'immutability-helper';
import numeral from 'numeral';
import { floor, get, toInteger } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import {
  SmileOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  HomeOutlined,
  EditOutlined,
  InfoCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  Empty,
  Select,
  Spin,
  Button,
  notification,
  Input,
  message,
  Switch,
  Tag,
  DatePicker,
  Tabs,
  Menu,
  Breadcrumb,
} from 'antd';

import {
  Defaults,
  buildLocation,
  parseLocation,
  getLoadStatus,
  EventRenderers,
  LogRenderers,
  GlobalParse,
  GlobalRenderers,
  buildUrl,
  Regex,
  sleep,
  downloadFile,
  CellRenderers,
} from '../../../common/utils';
import { State } from '../../../common/types';
import { ChangeEventIcon, FlagNewIcon, GoodIcon, GoodOutlinedIcon } from '../../../lib/fui/icons';
import {
  Modal,
  Container,
  AutoSizer,
  List,
  CellMeasurerCache,
  CellMeasurer,
  Dropdown,
  Popover,
} from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo, ActionTypes as AppActionTypes } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/dashboard/actions';
import { BaseUrls } from '../../app/Constants';
import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { colorMap } from '../../share';

import { appFieldsMessages, appButtonsMessages, appMessages, appMenusMessages } from '../../../common/app/messages';
import { eventMessages, eventActionMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';

import ProjectSelectorModal from './ProjectSelectorModal';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import EventActionModal from '../../metric/components/EventActionModal';
import ReportJiraModal from '../../metric/components/ReportJiraModal';
import ReportServiceNowModal from '../../metric/components/ReportServiceNowModal';

import LikelyRootCausesModal from './LikelyRootCausesModal';
import RecommendationsModal from './RecommendationsModal';
import TriageReportModal from './TriageReportModal';
import TriggeredActionsModal from './TriggeredActionsModal';
import { queryFieldMessages } from '../../../common/query/messages';

type Props = {
  refresh: Number,
  handleRefresh: Function,
  handleCustomerNameChange: Function,
  // eslint-disable-next-line
  handleEnvironmentChange: Function,
  handleSystemIdChange: Function,

  intl: Object,
  location: Object,
  loadStatus: Object,
  timezoneOffset: Number,
  // eslint-disable-next-line
  push: Function,
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  userList: Array<Object>,
  userInfo: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  userName: String,
  credentials: Object,
  projects: Array<Object>,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  systemsMap: Object,

  globalInfo: Object,
  globalSystemInfo: Object,
  globalSystemComponentAnomalyCount: Object,
  globalSystemInstanceAnomalyCount: Object,
  globalSystemAlertStatistics: Object,
  // eslint-disable-next-line
  patternNameMap: Object,
  currentTheme: String,
};

class GlobalSystemAlertCauseListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;

    this.notificationKey = 'notification-new-anomaly-event';
    this.dataLoader = 'dashboard_global_system_alert_cause';
    this.statsLoader = 'dashboard_global_system_alert_cause_stats';
    this.listHeaderHeight = 50;
    this.rowMinHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: this.rowMinHeight,
    });
    this.breakAutoLoad = false;

    this.state = {
      startTimeObj: moment.utc(startTime, Defaults.DateFormat),
      endTimeObj: moment.utc(endTime, Defaults.DateFormat),
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      eventList: [],
      eventsStats: {
        alertCount: 0,
        alertReductionRatio: 0,
      },
      // anomalyEventsMap
      activeKey: 'alert',
      jumpIncident: undefined,
      activeEvent: null,
      actionIncident: null,
      patternNameMap: {},

      isLoaded: false,
      isLoadingSystem: false,
      isLoadingParserData: false,

      // take action
      showProjectSelector: false,
      onConfirmProjectSelect: null,
      showTakeLogActionModal: false,
      actionName: null,
      actionRootCauseKey: null,
      showActionModal: false,
      showSoftwareUpdateContextModal: false,
      showReportJiraModal: false,
      showReportServiceNowModal: false,

      // general modals
      showRCModal: false,
      needRC: false,
      needPT: false,
      showRecommendationsModal: false,
      showTriageReportModal: false,
      showTriggeredActionsModal: false,
    };
    this.tabList = [];
    this.eventListExport = [];

    this.componentListOptions = [];
    this.instanceListOptions = [];
    this.anomalyMetricOptions = [];
    this.patternIdFilterOptions = [];
  }

  async componentDidMount() {
    // get rare pattern mapping patternID before start
    await this.handleRarePatternMapFromJump();

    this.reloadData(this.props, { needResetFilter: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (
      nextProps.refresh !== this.props.refresh ||
      nextQuery.refreshTime !== query.refreshTime ||
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.systemId !== query.systemId
    ) {
      const isAutoReload = nextQuery.refreshTime !== query.refreshTime;
      if (!isAutoReload || !this.breakAutoLoad) {
        this.reloadData(nextProps, { isAutoReload });
      }
    } else if (
      nextProps.globalSystemInfo !== this.props.globalSystemInfo ||
      nextProps.globalSystemComponentAnomalyCount !== this.props.globalSystemComponentAnomalyCount ||
      nextProps.globalSystemInstanceAnomalyCount !== this.props.globalSystemInstanceAnomalyCount ||
      nextQuery.reloadSystem !== query.reloadSystem
    ) {
      this.parseData(nextProps);
    } else if (
      (nextQuery.hideIgnore !== query.hideIgnore ||
        nextQuery.componentFilter !== query.componentFilter ||
        nextQuery.instanceFilter !== query.instanceFilter ||
        nextQuery.metricFilter !== query.metricFilter ||
        nextQuery.patternIdFilter !== query.patternIdFilter ||
        nextQuery.timeFilterStart !== query.timeFilterStart ||
        nextQuery.timeFilterEnd !== query.timeFilterEnd) &&
      nextQuery.needFilter === 'true'
    ) {
      if (nextQuery.hideIgnore !== query.hideIgnore) {
        this.reloadDataStats(nextProps, false);
      }
      this.parseData(nextProps);
    } else if (nextProps.globalSystemAlertStatistics !== this.props.globalSystemAlertStatistics) {
      this.parseDataStats(nextProps);
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  loadSystemData() {
    const { location, isAdmin, userName, createLoadAction } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime, customerName, systemId, environmentId } = query;
    this.breakAutoLoad = true;
    if (((isAdmin && customerName) || !isAdmin) && startTime && endTime && systemId && environmentId) {
      createLoadAction(
        AppActionTypes.LOAD_INFO_SYSTEM,
        {
          startTime,
          endTime,
          customerName: isAdmin ? customerName : userName,
          systemName: systemId,
          environmentId,
          anomalyInstanceOnly: false,
        },
        false,
        true,
        this.callbackHandleUpdateReloadSystemTime,
      );
    }
  }

  @autobind
  callbackHandleUpdateReloadSystemTime() {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, reloadSystem: moment.utc().valueOf() }));
    this.breakAutoLoad = false;
    this.setState({ isLoadingSystem: false });
  }

  @autobind
  reloadData(props, params) {
    const { createLoadAction, location, isAdmin, globalInfo } = props;
    const { environmentId, startTime, endTime, customerName, systemId } = parseLocation(location);
    const { isAutoReload } = params;

    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId) {
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo) {
        const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
        const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
        const startTimestamps = [];
        R.forEach((ts) => {
          const timestamp = ts * 86400000;
          startTimestamps.push(timestamp);
        }, R.range(startTimestamp / 86400000, endTimestamp / 86400000));

        this.breakAutoLoad = true;
        this.setState({ isLoaded: isAutoReload, isAutoReload }, () => {
          createLoadAction(
            ActionTypes.LOAD_ALERT_CAUSE_TIMELINES,
            {
              isAutoReload,
              level: 'system',
              environmentName: environmentId,
              customerName: systemInfo.ownerUserName,
              systemId,
              startTimestamps,
              anomalyType: 'all',
            },
            isAutoReload ? false : this.dataLoader,
            false,
            this.callbackSetState,
          );
        });
        this.reloadDataStats(props, { isAutoReload });
      }
    }
  }

  @autobind
  callbackSetState() {
    this.breakAutoLoad = false;
    this.setState({ isLoaded: true });
  }

  @autobind
  reloadDataStats(props, params) {
    const { createLoadAction, location, userName, globalInfo } = props;
    const { isAutoReload } = params;
    const { environmentId, startTime, systemId, hideIgnore } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const startDayTime = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const dayTimeMillis = startDayTime;
    createLoadAction(
      ActionTypes.LOAD_GLOBAL_SYSTEM_ALERT_STATISTICS,
      {
        isAutoReload,
        environmentName: environmentId,
        systemName: systemId,
        customerName: systemInfo.ownerUserName || userName,
        dayTimeMillis,
        includeIgnore: hideIgnore === 'false',
      },
      isAutoReload ? false : this.statsLoader,
    );
    this.setState({ loadedPatternNameMap: false });
  }

  @autobind
  parseDataStats(props) {
    const { globalSystemAlertStatistics } = props;
    const { eventsStats } = this.state;
    const { alertCount, alertReductionRatio } = globalSystemAlertStatistics || {};
    this.setState({
      eventsStats: {
        ...eventsStats,
        alertCount,
        alertReductionRatio,
      },
    });
  }

  @autobind
  async handleRarePatternMapFromJump() {
    const { replace, location, credentials } = this.props;
    const query = parseLocation(location);
    const {
      customerName,
      eventProjectName,
      eventPatternId,
      eventTimestamp,
      eventLogInstanceName,
      eventInstanceName,
      eventPatternType,
    } = query;

    if (eventPatternType === 'rare' && eventProjectName && eventPatternId && eventTimestamp) {
      const projectName =
        customerName !== credentials.userName ? `${eventProjectName}@${customerName}` : eventProjectName;
      const data = await fetchGet(getEndpoint('lograrepatternmapping'), {
        ...credentials,
        projectName,
        instanceName: eventLogInstanceName || eventInstanceName,
        timestamp: eventTimestamp,
        patternId: eventPatternId,
      });
      const { patternId } = data || {};
      if (patternId) {
        replace(buildLocation(location.pathname, {}, { ...query, eventPatternId: patternId }));
      }
    }
  }

  @autobind
  async parseData(props) {
    const { intl, location, globalInfo, globalSystemInfo } = props;
    const { isAutoReload, eventList: stateEventList } = this.state;
    const { sortBy, sortDirection } = this.state;
    let { activeKey } = this.state;

    const startTsParse = moment.utc().valueOf();
    const query = parseLocation(location);
    const {
      eventProjectName,
      eventPatternId,
      eventTimestamp,
      eventInstanceName,
      eventPatternType,
      eventRootCauseMetric,
    } = query;
    const { environmentId, systemId } = query;
    const {
      hideIgnore,
      componentFilter,
      instanceFilter,
      metricFilter,
      patternIdFilter,
      timeFilterStart,
      timeFilterEnd,
    } = query;

    // add wait time befor parse data
    if (!isAutoReload) {
      this.setState({ isLoadingParserData: true });
      await sleep(300);
    }

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    let eventList = [];
    eventList = get(globalSystemInfo, ['anomalyTimelines'], []);

    // filter detection event
    eventList = R.filter((event) => event.timeLineType !== 'future', eventList);
    if (hideIgnore !== 'false') {
      eventList = R.filter((event) => {
        return !event.isIgnored;
      }, eventList);
    }

    // if eventList and system has no details, then reload system details
    if (eventList.length > 0 && !systemInfo.hasAllInstanceInfo) {
      this.setState({ isLoadingSystem: true });
      return this.loadSystemData();
    }

    const startTsEvents = moment.utc().valueOf();
    // parse events for appName、filter instances、filter metrics
    let eventInstances = [];
    let eventComponents = [];
    let allInstanceList = [];
    let eventPatternIds = [];
    let metricListAll = [];
    eventList = R.addIndex(R.map)((event, idx) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];
      let rootCauseDetailsArr = get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (event.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          const realInstance = pair.instanceId || pair.instanceName;
          let instanceName = realInstance;
          if (pair.containerName && !realInstance.includes('_')) {
            instanceName = `${pair.containerName}_${realInstance}`;
          }
          allInstanceList.push(instanceName);
          const componentName =
            get(instanceComponentMap, realInstance) || get(instanceComponentMap, instanceName) || instanceName;
          componentList.push(componentName);
          const appName =
            componentName && componentName !== instanceName && componentName !== realInstance
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);

        instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      } else {
        const { realInstanceName } = event;
        allInstanceList = [...allInstanceList, event.instanceName, realInstanceName];
        const componentName =
          get(instanceComponentMap, realInstanceName) ||
          get(instanceComponentMap, event.instanceName) ||
          event.instanceName;
        componentList.push(componentName);
        const appName =
          componentName && componentName !== event.instanceName && componentName !== event.realInstanceName
            ? `${event.instanceName} (${componentName})`
            : event.instanceName;
        instanceAppNames.push(appName);
      }
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      allInstanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(allInstanceList));
      const onlyInstanceString = get(event, ['containerInfo', 'instanceName'], event.realInstanceString);

      eventComponents = R.concat(eventComponents, componentList);
      eventInstances = R.concat(eventInstances, [onlyInstanceString]);
      metricListAll = R.concat(metricListAll, event.metricList);

      let severity;
      if (event.isIncident) {
        severity = 'critical';
      } else if (R.toLower(event.type) !== 'normal') {
        severity = 'major';
      } else if (R.toLower(event.type) === 'normal') {
        severity = 'minor';
      }

      let typeList = [];
      if (event.category === 'metric' && event.type !== 'Incident') {
        typeList = ['metricanomaly', 'ublAnomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', event.type).split('&'));
      }
      eventPatternIds.push(String(event.patternId));

      return {
        ...event,
        rootCauseJson: { rootCauseDetailsArr },
        appName: R.join(', ', instanceAppNames),
        instanceAppNames,
        componentListStr: R.join(', ', componentList),
        componentList,
        allInstanceList,
        instanceListStr: event.instanceNameString,
        onlyInstanceString,
        typeList,
        severity,
      };
    }, eventList);
    console.debug(`For each events duration: ${(moment.utc().valueOf() - startTsEvents) / 1000} sec`);

    // set instance/metric filter options
    eventComponents = R.filter((item) => Boolean(item), R.uniq(eventComponents));
    this.componentListOptions = R.map(
      (component) => ({
        value: component,
        label: component,
      }),
      eventComponents,
    );
    this.componentListOptions = R.sortWith([R.ascend(R.prop('label'))], this.componentListOptions);
    eventInstances = R.filter((item) => Boolean(item), R.uniq(eventInstances));
    this.instanceListOptions = R.map(
      (instance) => ({
        value: instance,
        label: instance,
      }),
      eventInstances,
    );
    this.instanceListOptions = R.sortWith([R.ascend(R.prop('label'))], this.instanceListOptions);
    this.anomalyMetricOptions = R.map(
      (metric) => ({ value: metric, label: metric }),
      R.filter((item) => Boolean(item), R.uniq(metricListAll)),
    );
    this.anomalyMetricOptions = R.sortWith([R.ascend(R.prop('label'))], this.anomalyMetricOptions);

    eventPatternIds = R.uniq(eventPatternIds);
    let patternIdFilterOptions = R.map((name) => {
      return { value: name, label: name };
    }, eventPatternIds);
    patternIdFilterOptions = R.sortWith([R.ascend(R.prop('label'))], patternIdFilterOptions);
    this.patternIdFilterOptions = patternIdFilterOptions;

    this.eventListExport = eventList;

    // anomaly events filter
    if (componentFilter) {
      eventList = R.filter((event) => {
        return event.componentList.indexOf(componentFilter) !== -1;
      }, eventList);
    }
    if (instanceFilter) {
      eventList = R.filter((event) => {
        return event.onlyInstanceString === instanceFilter;
      }, eventList);
    }
    if (metricFilter) {
      eventList = R.filter((event) => {
        return event.metricList.indexOf(metricFilter) !== -1;
      }, eventList);
    }
    if (patternIdFilter) {
      eventList = R.filter((event) => {
        return String(event.patternId) === patternIdFilter;
      }, eventList);
    }
    if (timeFilterStart && timeFilterEnd) {
      eventList = R.filter((event) => {
        return (
          moment.utc(event.startTimestamp).hours() >= Number(timeFilterStart) &&
          moment.utc(event.startTimestamp).hours() <= Number(timeFilterEnd)
        );
      }, eventList);
    }

    // split eventlist by diff type
    const tabList = [
      { name: 'alert', title: intl.formatMessage(appFieldsMessages.alerts) },
      { name: 'deployment', title: intl.formatMessage(appFieldsMessages.changes) },
    ];
    const tabListNames = R.map((tab) => tab.name, tabList);
    let anomalyEventsMap = {
      'eventList-alert': [],
      'eventList-deployment': [],
    };
    R.forEach((item) => {
      const { isDeployment } = item;
      if (isDeployment) {
        anomalyEventsMap['eventList-deployment'].push(item);
      } else {
        anomalyEventsMap['eventList-alert'].push(item);
      }
    }, eventList);
    activeKey =
      activeKey && anomalyEventsMap[`eventList-${activeKey}`].length > 0
        ? activeKey
        : R.find((l) => l.length > 0, R.values(anomalyEventsMap))
        ? tabListNames[R.findIndex((l) => l.length > 0, R.values(anomalyEventsMap))]
        : 'alert';
    this.tabList = tabList;
    anomalyEventsMap = R.mapObjIndexed((events) => {
      return this.sortData(events, sortBy, sortDirection);
    }, anomalyEventsMap);
    console.debug(`Parser data list duration: ${(moment.utc().valueOf() - startTsParse) / 1000} sec`);

    // if has jump options in params, then highlight the search one
    let jumpIncident;
    if (eventProjectName && eventPatternId && eventTimestamp) {
      jumpIncident = R.find(
        (event) =>
          event.projectName === eventProjectName &&
          event.patternId === Number(eventPatternId) &&
          (!eventInstanceName || (eventInstanceName && event.allInstanceList.includes(eventInstanceName))) &&
          (!eventPatternType || (eventPatternType && event.typeList.includes(R.toLower(eventPatternType)))) &&
          (!eventRootCauseMetric || (eventRootCauseMetric && event.metricList.includes(eventRootCauseMetric))) &&
          event.startTimestamp <= Number(eventTimestamp) &&
          event.endTimestamp >= Number(eventTimestamp),
        eventList,
      );
      activeKey = jumpIncident ? (jumpIncident.isDeployment ? 'deployment' : 'alert') : activeKey;
      if (!jumpIncident) {
        notification.warn({
          message: 'Notification',
          description: `The selected anomaly ${eventPatternId} is no longer valid.`,
          duration: 0,
        });
      }
    }

    // remind user to refresh if the data from auto reload
    if (isAutoReload) {
      // pop up window to confirm update events
      this.setState({ isAutoReload: false }, () => {
        const newRelationCount = eventList.length - stateEventList.length;
        if (newRelationCount > 0) {
          const btn = (
            <Button
              type="primary"
              size="small"
              onClick={() => this.handleNotificationConfirm({ props, eventList, activeKey, anomalyEventsMap })}
            >
              Refresh
            </Button>
          );
          notification.info({
            message: 'Notification',
            description: `${newRelationCount} new anomaly ${
              newRelationCount > 1 ? 'events have' : 'event has'
            } been detected.`,
            btn,
            key: this.notificationKey,
            duration: 0,
          });
        }
      });
    } else {
      // just update event list
      this.setState({ isLoadingParserData: false, eventList, activeKey, ...anomalyEventsMap, jumpIncident }, () => {
        if (jumpIncident) {
          replace(
            buildLocation(
              location.pathname,
              {},
              {
                ...query,
                eventProjectName: undefined,
                eventPatternId: undefined,
                eventTimestamp: undefined,
                eventInstanceName: undefined,
                eventPatternType: undefined,
                eventRootCauseMetric: undefined,
              },
            ),
          );
          const rowIndex = R.findIndex(
            (item) => item.id === jumpIncident.id,
            anomalyEventsMap[`eventList-${activeKey}`] || [],
          );
          setTimeout(() => {
            const scrollCountInCenter = floor((this[`ref-${activeKey}`].props.height || 0) / this.rowMinHeight / 2);
            const afterJumpIncidentCount = (anomalyEventsMap[`eventList-${activeKey}`] || []).length - rowIndex;

            let offsetCount = scrollCountInCenter;
            if (afterJumpIncidentCount < scrollCountInCenter) {
              offsetCount += scrollCountInCenter - afterJumpIncidentCount;
            }
            const offsetRowIndex = rowIndex - offsetCount;
            if (this[`ref-${activeKey}`] && offsetRowIndex > 0) {
              this[`ref-${activeKey}`].scrollToPosition(offsetRowIndex * this.rowMinHeight);
            }
          }, 600);
        }
      });
    }
    return true;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('severity')), R.descend(R.prop('startTimestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  handleNotificationConfirm({ props, eventList, activeKey, anomalyEventsMap }) {
    notification.close(this.notificationKey);
    this.setState({ eventList, activeKey, ...anomalyEventsMap });
  }

  @autobind
  refresh() {
    this.reloadData(this.props, {});
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    const disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    this.setState({ startTimeObj, timeChange, disableRefresh, tooltipVisibleReload }, () => {
      if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
    });
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    const disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    this.setState({ endTimeObj, timeChange, disableRefresh, tooltipVisibleReload }, () => {
      if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
    });
  }

  @autobind
  handleIgnoreFilterChange(checked, event) {
    const { replace, location } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, hideIgnore: checked, needFilter: true }));
  }

  @autobind
  onChangeFilterComponent(value) {
    const { replace, location } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, componentFilter: value, needFilter: true }));
  }

  @autobind
  onChangeFilterInstance(value) {
    const { replace, location } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, instanceFilter: value, needFilter: true }));
  }

  @autobind
  onChangeFilterMetric(value) {
    const { replace, location } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, metricFilter: value, needFilter: true }));
  }

  @autobind
  onChangePatternIdFilter(value) {
    const { replace, location } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, patternIdFilter: value, needFilter: true }));
  }

  @autobind
  onChangeTime(type, value) {
    const { replace, location } = this.props;
    const query = parseLocation(location);

    let { timeFilterStart, timeFilterEnd } = query;
    if (type === 'start') {
      timeFilterStart = value;
      timeFilterEnd =
        timeFilterStart && timeFilterEnd && Number(timeFilterStart) >= Number(timeFilterEnd)
          ? String(Number(timeFilterStart) + 1)
          : timeFilterEnd;
    } else {
      timeFilterEnd = value;
      timeFilterStart =
        timeFilterStart && timeFilterEnd && Number(timeFilterStart) >= Number(timeFilterEnd)
          ? String(Number(timeFilterEnd) - 1)
          : timeFilterStart;
    }
    const needFilter = Boolean(timeFilterStart && timeFilterEnd) || !(timeFilterStart || timeFilterEnd);
    replace(buildLocation(location.pathname, {}, { ...query, timeFilterStart, timeFilterEnd, needFilter }));
  }

  @autobind
  renderTopBar({ isLoadingStats }) {
    const { intl, location } = this.props;
    const {
      hideIgnore,
      componentFilter,
      instanceFilter,
      metricFilter,
      patternIdFilter,
      timeFilterStart,
      timeFilterEnd,
    } = parseLocation(location);
    const { alertCount, alertReductionRatio } = this.state.eventsStats || {};

    return (
      <div
        className="flex-grow flex-col flex-min-height flex-min-width"
        style={{
          padding: '0 16px',
          background: 'white',
          borderRadius: 4,
        }}
      >
        <Spin spinning={isLoadingStats} wrapperClassName="spin-full-height">
          <div className="flex-row flex-center-align" style={{ padding: '12px 0' }}>
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              style={{ width: 150, marginRight: 16 }}
              placeholder={intl.formatMessage(appFieldsMessages.component)}
              value={componentFilter}
              filterOption
              optionFilterProp="value"
              optionLabelProp="value"
              onChange={this.onChangeFilterComponent}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 860 }}
            >
              {R.addIndex(R.map)((item, index) => {
                return (
                  <Select.Option key={item.value} title={item.value}>
                    {item.label}
                  </Select.Option>
                );
              }, this.componentListOptions)}
            </Select>
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              style={{ width: 150, marginRight: 16 }}
              placeholder={intl.formatMessage(appFieldsMessages.instance)}
              value={instanceFilter}
              filterOption
              optionFilterProp="value"
              optionLabelProp="value"
              onChange={this.onChangeFilterInstance}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 860 }}
            >
              {R.addIndex(R.map)((item, index) => {
                return (
                  <Select.Option key={item.value} title={item.value}>
                    {item.label}
                  </Select.Option>
                );
              }, this.instanceListOptions)}
            </Select>

            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              style={{ width: 120, marginRight: 16 }}
              placeholder={intl.formatMessage(appFieldsMessages.metric)}
              value={metricFilter}
              filterOption
              optionFilterProp="value"
              onChange={this.onChangeFilterMetric}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {R.addIndex(R.map)((item, index) => {
                return (
                  <Select.Option key={item.value} title={item.value}>
                    {item.label}
                  </Select.Option>
                );
              }, this.anomalyMetricOptions)}
            </Select>
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              style={{ width: 120, marginRight: 16 }}
              placeholder={intl.formatMessage(eventMessages.patternId)}
              value={patternIdFilter}
              onChange={this.onChangePatternIdFilter}
              optionFilterProp="value"
              filterOption
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {R.addIndex(R.map)((item, index) => {
                return (
                  <Select.Option key={item.value} label={item.label}>
                    {item.label}
                  </Select.Option>
                );
              }, this.patternIdFilterOptions)}
            </Select>

            <Select
              allowClear
              showArrow={false}
              size="small"
              style={{ width: 100 }}
              placeholder={intl.formatMessage(appFieldsMessages.startTime)}
              value={timeFilterStart}
              onChange={(value) => this.onChangeTime('start', value)}
            >
              {R.range(0, 24).map((item) => (
                <Select.Option key={String(item)} title={String(item)}>
                  {`${item}:00`}
                </Select.Option>
              ))}
            </Select>
            <span style={{ padding: '0 4px' }}>~</span>
            <Select
              allowClear
              showArrow={false}
              size="small"
              style={{ width: 100, marginRight: 16 }}
              placeholder={intl.formatMessage(appFieldsMessages.endTime)}
              value={timeFilterEnd}
              onChange={(value) => this.onChangeTime('end', value)}
            >
              {R.range(1, 25).map((item) => (
                <Select.Option key={String(item)} title={String(item)}>
                  {`${item}:00`}
                </Select.Option>
              ))}
            </Select>

            <div className="flex-grow" />
            <span
              className="light-label"
              style={{ fontSize: 14, fontWeight: 500, paddingRight: 8, wordBreak: 'break-all' }}
            >
              {intl.formatMessage(logMessages.hideIgnoredEvents)}:
            </span>
            <Switch size="small" checked={hideIgnore !== 'false'} onChange={this.handleIgnoreFilterChange} />
          </div>

          {false && (
            <div className="flex-col" style={{ paddingBottom: 12, whiteSpace: 'nowrap', overflowX: 'auto' }}>
              <div className="flex-row flex-center-align" style={{ paddingBottom: 8 }}>
                <div style={{ fontSize: 14, fontWeight: 500, marginRight: 8 }}>
                  {intl.formatMessage(DashboardMessages.alertStatistics)}:
                </div>
                <Tag>{`${intl.formatMessage(eventMessages.totalAlerts)}: ${alertCount}`}</Tag>
                <Tag>{`${intl.formatMessage(logMessages.alertReductionRatio)}: ${numeral(alertReductionRatio).format(
                  '0.0%',
                )}`}</Tag>
              </div>
            </div>
          )}
        </Spin>
      </div>
    );
  }

  @autobind
  renderEventList(tabName, events) {
    const { intl } = this.props;
    const { jumpIncident } = this.state;
    const { sortBy, sortDirection } = this.state;
    return (
      <div className="full-height flex-col event-list" style={{ paddingTop: 8 }}>
        <AutoSizer>
          {({ width, height }) => (
            <>
              <div
                className="event-list-header"
                style={{
                  width,
                  height: this.listHeaderHeight,
                  paddingRight: this[`listNodeHeaderScrollbar-${tabName}`] ? 17 : 0,
                }}
              >
                {tabName !== 'deployment' && (
                  <div className="header-column" style={{ width: 80 }} onClick={this.headerClick(tabName, 'severity')}>
                    <span>{intl.formatMessage(logMessages.severity)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'severity')}
                  </div>
                )}
                <div
                  className="header-column"
                  style={{ width: 114 }}
                  onClick={this.headerClick(tabName, 'startTimestamp')}
                >
                  <span>{intl.formatMessage(eventMessages.detectionTime)}</span>
                  {this.sortIcon(sortBy, sortDirection, 'startTimestamp')}
                </div>
                <div
                  className="header-column"
                  style={{ width: 80, maxWidth: 120, flex: 1 }}
                  onClick={this.headerClick(tabName, 'projectName')}
                >
                  <span>{intl.formatMessage(appFieldsMessages.project)}</span>
                  {this.sortIcon(sortBy, sortDirection, 'projectName')}
                </div>
                <div
                  className="header-column"
                  style={{ width: 80, maxWidth: 120, flex: 1 }}
                  onClick={this.headerClick(tabName, 'onlyInstanceString')}
                >
                  <span>{intl.formatMessage(appFieldsMessages.instance)}</span>
                  {this.sortIcon(sortBy, sortDirection, 'onlyInstanceString')}
                </div>
                <div
                  className="header-column"
                  style={{ width: 80, maxWidth: 130, flex: 1 }}
                  onClick={this.headerClick(tabName, 'patternId')}
                >
                  <span>{intl.formatMessage(eventMessages.fieldAnomalyPattern)}</span>
                  {this.sortIcon(sortBy, sortDirection, 'patternId')}
                </div>
                <div className="header-column" style={{ width: 80, flex: 2 }}>
                  {intl.formatMessage(eventMessages.shortDescription)}
                </div>
                <div className="header-column break-word" style={{ width: 80 }}>
                  {intl.formatMessage(eventMessages.likelyRootCauses)}
                </div>
                <div className="header-column break-word" style={{ width: 80 }}>
                  {intl.formatMessage(queryFieldMessages.trailingEvents)}
                </div>
                <div className="header-column break-all" style={{ width: 80 }}>
                  {intl.formatMessage(logMessages.recommendations)}
                </div>
                <div className="header-column" style={{ width: 80 }}>
                  {intl.formatMessage(logMessages.triggeredActions)}
                </div>
                <div className="header-column" style={{ width: 80 }}>
                  {intl.formatMessage(eventActionMessages.triageReport)}
                </div>
                <div className="header-column" style={{ width: 90 }}>
                  {intl.formatMessage(logMessages.labels)}
                </div>
                <div className="header-column" style={{ width: 95 }} />
              </div>
              <List
                ref={(ref) => {
                  this[`ref-${tabName}`] = ref;
                }}
                className="event-list-grid"
                width={width}
                height={height - this.listHeaderHeight}
                rowCount={(events || []).length}
                overscanRowCount={4}
                deferredMeasurementCache={this.cellMeasureCache}
                rowHeight={this.cellMeasureCache.rowHeight}
                rowRenderer={this.renderListItem(tabName, events, jumpIncident)}
                onScrollbarPresenceChange={({ horizontal, vertical }) => {
                  if (vertical) {
                    this[`listNodeHeaderScrollbar-${tabName}`] = true;
                  } else {
                    this[`listNodeHeaderScrollbar-${tabName}`] = false;
                  }
                  this.forceUpdate();
                }}
              />
            </>
          )}
        </AutoSizer>
      </div>
    );
  }

  @autobind
  renderListItem(tabName, events, jumpIncident) {
    return ({ key, index: rowIndex, style, parent }) => {
      const rowData = events[rowIndex];
      if (!rowData) return null;

      const { projectName, onlyInstanceString } = rowData;

      const active = jumpIncident && jumpIncident.id === rowData.id;
      const content = (
        <div
          className={`event-list-row${active ? ' active' : ''}${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          style={{ ...style, minHeight: 40 }}
          onClick={() => this.handleEventClick(rowData)}
        >
          {tabName !== 'deployment' && (
            <div className="row-column" style={{ width: 80 }}>
              {this.severityRenderer(rowData)}
            </div>
          )}
          <div className="row-column" style={{ width: 114 }}>
            {this.timeRenderer(rowData)}
          </div>
          <div className="row-column" style={{ width: 80, maxWidth: 120, flex: 1 }}>
            {this.instanceRenderer(rowData, projectName)}
          </div>
          <div className="row-column" style={{ width: 80, maxWidth: 120, flex: 1 }}>
            {this.instanceRenderer(rowData, onlyInstanceString)}
          </div>
          <div className="row-column" style={{ width: 80, maxWidth: 130, flex: 1 }}>
            {this.patternRenderer(rowData)}
          </div>
          <div className="row-column" style={{ width: 80, flex: 2 }}>
            {this.rendererContent(rowData)}
          </div>
          <div className="row-column" style={{ width: 80 }}>
            {this.rendererRC(rowData)}
          </div>
          <div className="row-column" style={{ width: 80 }}>
            {this.rendererPT(rowData)}
          </div>
          <div className="row-column" style={{ width: 80 }}>
            {this.rendererRecommendations(rowData)}
          </div>
          <div className="row-column" style={{ width: 80 }}>
            {this.rendererTriggeredActions(rowData)}
          </div>
          <div className="row-column" style={{ width: 80 }}>
            {this.rendererTriageReport(rowData)}
          </div>
          <div className="row-column" style={{ width: 90 }}>
            {this.statusRenderer(rowData)}
          </div>
          <div className="row-column" style={{ width: 95 }}>
            {this.controlRenderer(rowData)}
          </div>
        </div>
      );

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          {content}
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleEventClick(rowData) {
    this.setState({ jumpIncident: rowData });
  }

  @autobind
  severityRenderer(rowData) {
    const { intl } = this.props;
    const { severity } = rowData;
    return CellRenderers.alertLevelShortRenderer({ intl, severity });
  }

  @autobind
  statusRenderer(rowData) {
    const { intl, timezoneOffset } = this.props;
    const { isImportant, isIgnored, isNewAlert, rootCauseResultInfo, reporterRecordMap } = rowData;
    const leadToIncident = get(rootCauseResultInfo, 'leadToIncident');
    const causedByChange = get(rootCauseResultInfo, 'causedByChangeEvent');

    return (
      <div className="flex-row flex-wrap full-width">
        <EventRenderers.RenderPatternReporterTooltip
          intl={intl}
          actionName={
            isImportant
              ? intl.formatMessage(eventMessages.markedAsImportant)
              : intl.formatMessage(eventMessages.markedAsUnimportant)
          }
          timezoneOffset={timezoneOffset}
          reporterRecordSet={get(reporterRecordMap, isImportant ? 'importance' : 'unImportance', [])}
        >
          {isImportant ? (
            <GoodIcon
              style={{ fontSize: 14, margin: '0 4px 2px 0', color: '#32C880' }}
              onClick={() => this.handleIgnoreClick({ event: rowData, category: 'important' })}
            />
          ) : (
            <GoodOutlinedIcon
              style={{ fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={() => this.handleIgnoreClick({ event: rowData, category: 'important' })}
            />
          )}
        </EventRenderers.RenderPatternReporterTooltip>
        <EventRenderers.RenderPatternReporterTooltip
          intl={intl}
          actionName={
            isIgnored
              ? intl.formatMessage(eventMessages.markedAsIgnored)
              : intl.formatMessage(eventMessages.markedAsUnignored)
          }
          timezoneOffset={timezoneOffset}
          reporterRecordSet={get(reporterRecordMap, isIgnored ? 'ignore' : 'unIgnore', [])}
        >
          {isIgnored ? (
            <EyeInvisibleOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          ) : (
            <EyeOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          )}
        </EventRenderers.RenderPatternReporterTooltip>

        {leadToIncident && (
          <Popover placement="top" content={intl.formatMessage(DashboardMessages.leadToIncident)} mouseEnterDelay={0.3}>
            <ExclamationCircleOutlined style={{ color: '#FF5142', fontSize: 16 }} />
          </Popover>
        )}
        {causedByChange && (
          <Popover
            content={<div>{intl.formatMessage(DashboardMessages.causedByChangeIncident)}</div>}
            mouseEnterDelay={0.3}
            placement="top"
          >
            <ChangeEventIcon style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'orange' }} />
          </Popover>
        )}
        {isNewAlert && (
          <Popover placement="top" mouseEnterDelay={0.3} content={intl.formatMessage(eventMessages.newIncident)}>
            <FlagNewIcon style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }} />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  timeRenderer(rowData) {
    const { startTimestamp } = rowData;
    return (
      <div className="flex-col" style={{ lineHeight: '14px' }}>
        {moment.utc(startTimestamp).format(Defaults.ShortDateTimeFormat)}
      </div>
    );
  }

  @autobind
  instanceRenderer(rowData, cellData) {
    const { intl } = this.props;
    const { projectName, componentListStr, onlyInstanceString, containerName } = rowData;
    return (
      <Popover
        title={null}
        content={
          <div style={{ maxWidth: 300 }}>
            <div className="flex-row">
              <div className="light-label bold" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </div>
              {projectName}
            </div>
            <div className="flex-row">
              <div className="light-label bold" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </div>
              {componentListStr}
            </div>
            <div className="flex-row">
              <div className="light-label bold" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.instance)}:
              </div>
              {onlyInstanceString}
            </div>
            {containerName && (
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </div>
                {containerName}
              </div>
            )}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="topLeft"
      >
        <span className="hidden-line-with-ellipsis inline-block">{cellData}</span>
      </Popover>
    );
  }

  @autobind
  patternRenderer(rowData) {
    const { intl, location, globalInfo, credentials, isAdmin, userName } = this.props;

    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const ownerUserName = get(systemInfo, 'ownerUserName');

    const { category, patternName, patternId, anomalyLogInstance, projectOwner, startTimestamp } = rowData;
    let { projectName } = rowData;
    projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
    const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;

    const RendererPatternTitle = ({ rowData }) => {
      const { patternNameMap } = this.state;

      const [patternName, setPatternName] = useState(get(patternNameMap, [key, patternId]));
      const [loading, setLoading] = useState(false);
      useEffect(() => {
        if (patternName === undefined) {
          setLoading(true);
          this.props.updateLastActionInfo();
          fetchGet(getEndpoint('getpatternname', 1), {
            ...credentials,
            customerName: ownerUserName,
            projectName,
            instanceName: category === 'log' ? anomalyLogInstance : null,
            patternId,
            startTime: startTimestamp,
          })
            .then((data) => {
              const { patternName } = data;
              if (!patternNameMap[key]) patternNameMap[key] = {};
              patternNameMap[key][patternId] = patternName || '';

              this.setState({ patternNameMap });
              setLoading(false);
              setPatternName(patternName || '');
            })
            .catch((err) => {
              message.error(intl.formatMessage(appMessages.apiFaild));
              console.log(err);
            });
        }
      }, []);
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName, patternId },
        { hasFullName: true, hasPrefix: true },
      );
      return (
        <div>
          {loading && <Spin size="small" />}
          {!loading && <div>{patternNameStr}</div>}
        </div>
      );
    };

    return (
      <div className="max-width flex-row flex-center-align">
        <Popover content={<RendererPatternTitle rowData={rowData} />} mouseEnterDelay={0.3} placement="top">
          <div className="hidden-line-with-ellipsis">
            {
              Defaults.PatternIdNameStr(
                { patternName: get(this.state.patternNameMap, [key, patternId]) || patternName, patternId },
                { hasFullName: true, hasPrefix: true },
              ).patternNameStr
            }
          </div>
        </Popover>
        <Popover content={intl.formatMessage(eventMessages.setPatternName)} mouseEnterDelay={0.3} placement="top">
          <EditOutlined
            className="primary-color"
            style={{ marginLeft: 2 }}
            onClick={() => this.handleChangePatternNameClick({ event: rowData })}
          />
        </Popover>
      </div>
    );
  }

  @autobind
  rendererContent(rowData) {
    const { intl, currentTheme } = this.props;
    const { category, rawData, anomalyWords, outlierValue } = rowData;
    const rootCauseDetailsArr = get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []);

    let content;
    let rawDataJson;
    if (category === 'metric') {
      const summaryList = R.map((event) => {
        return EventRenderers.BuildMetricAnomalySummary({ event });
      }, rootCauseDetailsArr);
      content = R.join('\n', summaryList);
    } else {
      try {
        rawDataJson = JSON.parse(rawData);
      } catch (error) {
        // console.debug(error)
      }
    }

    return (
      <Popover
        placement="right"
        content={
          <div
            className="overflow-y-auto"
            style={{
              maxWidth: 450,
              maxHeight: 380,
              padding: 0,
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            }}
          >
            {category === 'metric' && (
              <div>
                {R.addIndex(R.map)(
                  (event, index) => EventRenderers.RenderMetricAnomalySummary({ intl, event, index }),
                  rootCauseDetailsArr,
                )}
              </div>
            )}
            {category === 'log' && (
              <>
                {anomalyWords && anomalyWords.length > 0 && (
                  <LogRenderers.RenderAnomalyWords
                    style={{ wordBreak: 'break-all', marginBottom: 12 }}
                    anomalyWordList={anomalyWords}
                  />
                )}
                {outlierValue && !R.isEmpty(outlierValue) && (
                  <LogRenderers.RenderOutlierValue
                    style={{ wordBreak: 'break-all', marginBottom: 12 }}
                    outlierValue={outlierValue}
                  />
                )}
                <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                  {!rawDataJson &&
                    R.join(
                      '\n',
                      R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                    )}
                  {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                </div>
              </>
            )}
          </div>
        }
        trigger={['hover', 'click']}
      >
        <div className="max-width flex-row flex-center-align">
          <div className="hidden-line-with-ellipsis">{category === 'metric' ? content : rawData}</div>
          <InfoCircleOutlined className="primary-color" style={{ marginLeft: 2 }} />
        </div>
      </Popover>
    );
  }

  @autobind
  rendererRC(rowData) {
    const { intl } = this.props;
    const { rootCauseResultInfo } = rowData;
    const hasRootCause = get(rootCauseResultInfo, 'hasPrecedingEvent');
    if (!hasRootCause) return <span style={{ paddingLeft: 10 }}>--</span>;

    return (
      <Button size="small" type="link" onClick={() => this.handleRCClick({ event: rowData, needRC: true })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <SearchOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  rendererPT(rowData) {
    const { intl } = this.props;
    const { rootCauseResultInfo } = rowData;
    const hasTrailing = get(rootCauseResultInfo, 'hasTrailingEvent');
    if (!hasTrailing) return <span style={{ paddingLeft: 10 }}>--</span>;

    return (
      <Button size="small" type="link" onClick={() => this.handleRCClick({ event: rowData, needPT: true })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <SearchOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  rendererRecommendations(rowData) {
    const { intl } = this.props;
    return (
      <Button size="small" type="link" onClick={() => this.handleRecommendationsClick({ event: rowData })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <SearchOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  rendererTriggeredActions(rowData) {
    const { intl } = this.props;
    const { triggeredActionList } = rowData;
    if (!triggeredActionList || triggeredActionList.length === 0) return <span style={{ paddingLeft: 10 }}>--</span>;

    return (
      <Button size="small" type="link" onClick={() => this.handleTriggeredActionsClick({ event: rowData })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <SearchOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  rendererTriageReport(rowData) {
    const { intl } = this.props;
    return (
      <Button size="small" type="link" onClick={() => this.handleTriageReportClick({ event: rowData })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <EditOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  controlRenderer(rowData) {
    const { intl } = this.props;
    const { category, patternId, isIncident, isDeployment, rootCauseResultInfo } = rowData;
    const hasRootCause = get(rootCauseResultInfo, 'hasPrecedingEvent');

    return (
      <Dropdown
        name={intl.formatMessage(eventMessages.actions)}
        itemClick={({ key }) => {
          switch (key) {
            case 'setPatternName':
              this.handleChangePatternNameClick({ event: rowData });
              break;
            case 'important':
              this.handleIgnoreClick({ event: rowData, category: key });
              break;
            case 'ignore':
              this.handleIgnoreClick({ event: rowData, category: key });
              break;
            case 'lineChart':
              this.handleLineChartClick({ event: rowData });
              break;
            case 'lineChartForLog':
              this.handleLineChartJump({ event: rowData });
              break;
            case 'details':
              this.handleJumpClick({
                event: rowData,
                isPredicted: false,
                patternSearch: patternId,
              });
              break;
            case 'patternTrend':
              this.handleTrendPatternsClick({ event: rowData });
              break;
            case 'softwareUpdateContext':
              this.handleSoftwareUpdateContextClick({ event: rowData });
              break;
            case 'takeAction':
              this.handleSuggestedActionsClick({ event: rowData });
              break;
            case 'sendEmailAlert':
              this.handleMailAlertClick({ event: rowData });
              break;
            case 'reportJira':
              this.handleReportJiraClick({ event: rowData });
              break;
            case 'reportServiceNow':
              this.handleReportServiceNowClick({ event: rowData });
              break;
            case 'rerunRCA':
              this.handleRerunRCAClick({ event: rowData });
              break;
            case 'deleteIncident':
              Modal.confirm({
                title: <div>{intl.formatMessage(appMessages.continueConfirm)}</div>,
                icon: <ExclamationCircleOutlined />,
                content: null,
                okType: 'danger',
                onOk: (close) => {
                  return this.handleDeleteIncidentClick({ event: rowData });
                },
              });
              break;
            default:
              break;
          }
        }}
      >
        <>
          {/* <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item> */}
          {/* <Menu.Item key="important">
              {isImportant
                ? intl.formatMessage(eventMessages.markAsUnimportant)
                : intl.formatMessage(eventMessages.markAsImportant)}
            </Menu.Item> */}
          {/* <Menu.Item key="ignore">
              {isIgnored
                ? intl.formatMessage(eventMessages.markAsUnignored)
                : intl.formatMessage(eventMessages.markAsIgnored)}
            </Menu.Item> */}
          {/* <Menu.Item key="details">
              {category === 'metric'
                ? intl.formatMessage(appMenusMessages.metricAnalysis)
                : isAlert
                ? intl.formatMessage(appMenusMessages.alertAnalysis)
                : intl.formatMessage(appMenusMessages.logAnalysis)}
            </Menu.Item> */}
          {category === 'metric' && (
            <Menu.Item key="lineChart">{intl.formatMessage(eventMessages.lineChart)}</Menu.Item>
          )}
          {category === 'log' && (
            <Menu.Item key="lineChartForLog">{intl.formatMessage(eventMessages.lineChart)}</Menu.Item>
          )}
          {category === 'log' && (
            <Menu.Item key="details">
              {intl.formatMessage(isDeployment ? appMenusMessages.logAnalysis : appMenusMessages.alertAnalysis)}
            </Menu.Item>
          )}
          {category === 'log' && (
            <Menu.Item key="patternTrend">{intl.formatMessage(eventMessages.patternTrend)}</Menu.Item>
          )}
          {!hasRootCause && isIncident && (
            <Menu.Item key="takeAction">{intl.formatMessage(eventMessages.takeAction)}</Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item key="sendEmailAlert">{intl.formatMessage(eventMessages.sendEmailAlert)}</Menu.Item>
          <Menu.Item key="reportJira">{intl.formatMessage(eventMessages.reportJira)}</Menu.Item>
          <Menu.Item key="reportServiceNow">{intl.formatMessage(eventMessages.reportServiceNow)}</Menu.Item>
          <Menu.Divider />
          <Menu.Item key="rerunRCA">{intl.formatMessage(eventMessages.rerunRCA)}</Menu.Item>
          {isIncident && <Menu.Item key="deleteIncident">{intl.formatMessage(eventMessages.deleteIncident)}</Menu.Item>}
        </>
      </Dropdown>
    );
  }

  @autobind
  headerClick(tabName, name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const events = this.state[`eventList-${tabName}`];
          const sortList = this.sortData(events, name, sortDir);
          this.setState({
            [`eventList-${tabName}`]: sortList,
          });
          this.cellMeasureCache.clearAll();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  handleRCClick({ event, needRC, needPT }) {
    this.setState({ showRCModal: true, activeEvent: event, needRC, needPT });
  }

  @autobind
  handleRecommendationsClick({ event }) {
    this.setState({ showRecommendationsModal: true, activeEvent: event });
  }

  @autobind
  handleTriageReportClick({ event }) {
    this.setState({ showTriageReportModal: true, activeEvent: event });
  }

  @autobind
  handleTriggeredActionsClick({ event }) {
    this.setState({ showTriggeredActionsModal: true, activeEvent: event });
  }

  @autobind
  handleIgnoreClick({ event, category }) {
    const { intl } = this.props;
    const { isIgnored, isImportant, patternName, patternId } = event;
    const flag = (category === 'ignore' && isIgnored) || (category === 'important' && isImportant);
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: flag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          }),
      okText: intl.formatMessage(appButtonsMessages.submit),
      cancelText: intl.formatMessage(appButtonsMessages.cancel),
      onOk: this.handleIgnoreSumbit(event, category),
    });
  }

  @autobind
  handleIgnoreSumbit(incident, category) {
    return () => {
      const { intl, credentials, isAdmin, userName } = this.props;
      const { isIgnored, isImportant } = incident;
      const { projectOwner, anomalyLogInstance, instanceName, patternId, type } = incident;
      let { projectName } = incident;
      projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;

      if (this.ignoreModal) {
        this.ignoreModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      let content;
      if (category === 'ignore' && !isIgnored) {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, incident.rootCausesDetailsList || []);
        content = R.join('\n', summaryList);
      }

      let operation;
      switch (category) {
        case 'ignore':
          operation = isIgnored ? 'cancelIgnoreFlag' : 'setIgnoreFlag';
          break;
        case 'important':
          operation = isImportant ? 'cancelImportantFlag' : 'setImportantFlag';
          break;
        default:
          break;
      }
      this.props.updateLastActionInfo();
      return fetchPost(
        getEndpoint('events', 1),
        {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceName,
          operation,
          nid: patternId,
          type: type === 'Incident' ? 'incident' : undefined,
          content,
        },
        {},
        false,
      )
        .then((data) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.ignoreModal) this.ignoreModal.destroy();
          this.refresh();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.ignoreModal) {
            this.ignoreModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  async handleMailAlertClick({ event }) {
    const { intl, location, credentials, isAdmin, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const ownerUserName = get(systemInfo, 'ownerUserName');

    this.emailAlertModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <div className="flex-row flex-center-align flex-center-justify">
          <Spin spinning />
        </div>
      ),
      okButtonProps: { disabled: true },
      onOk: () => {
        if (this.emailAlertModal) this.emailAlertModal.destroy();
      },
    });

    const emailAddress = await fetchGet(getEndpoint('healthviewsetting', 2), {
      ...credentials,
      customerName: ownerUserName,
      environmentName: environmentId,
      shareUserFlag: ownerUserName !== credentials.userName && !isAdmin,
    })
      .then((data) => {
        const systemConfigs = [];
        R.forEachObjIndexed((system, systemId) => {
          const { environmentName } = system.key || {};
          systemConfigs.push({ ...system, systemId, id: systemId, environmentId: environmentName });
        }, data);
        const systemConfigInfo = get(data, systemId);
        return systemConfigInfo ? systemConfigInfo.alertEmail : '';
      })
      .catch((error) => {
        console.error('[IF_API] ', error);
        return '';
      });

    this.handleMailAlertModal(event, emailAddress);
  }

  @autobind
  handleMailAlertModal(event, emailAddress) {
    const { intl } = this.props;
    this.emailAddressList = R.map((email) => R.trim(email), R.split(',', emailAddress || ''));

    // handle content change
    const onChangeMailList = (e) => {
      const value = e.target.value || '';
      const hasErrorEmailAddress = !R.reduce(
        (a, b) => a && b,
        true,
        R.map((email) => Regex.email.test(R.trim(email)), value.split(',')),
      );
      this.emailAddressList = R.map((email) => R.trim(email), R.split(',', value));
      if (this.emailAlertModal) {
        this.emailAlertModal.update({ okButtonProps: { disabled: hasErrorEmailAddress } });
      }
    };

    this.emailAlertModal.update({
      content: (
        <div>
          <div>{`${intl.formatMessage(eventMessages.sendEmailToAddress)}:`}</div>
          <Input.TextArea rows={3} defaultValue={emailAddress} onChange={onChangeMailList} />
        </div>
      ),
      okButtonProps: { disabled: false },
      onOk: this.handleMailAlertSumbit(event),
    });
  }

  @autobind
  handleMailAlertSumbit(incident) {
    return () => {
      const { intl, location, credentials, userName } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const { projectName, projectOwner, startTimestamp, endTimestamp, type, instanceName, patternId } = incident;

      if (this.emailAlertModal) {
        this.emailAlertModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      this.props.updateLastActionInfo();
      return Promise.all([
        fetchPost(getEndpoint('globalalertemail', 2), {
          ...credentials,
          environmentName: environmentId,
          systemName: systemId,
          projectName,
          customerName: projectOwner || userName,
          startTime: startTimestamp,
          endTime: endTimestamp,
          type,
          instanceName,
          patternId,
          emailAddressList: JSON.stringify(this.emailAddressList),
          operation: 'Email',
        }),
      ])
        .then((results) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.emailAlertModal) this.emailAlertModal.destroy();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.emailAlertModal) {
            this.emailAlertModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  handleJumpClick({ isPredicted, patternSearch, event }) {
    const { isAdmin, isReadUser, isLocalAdmin, location, userName, projects } = this.props;
    const { customerName, startTime, endTime, environmentId } = parseLocation(location);
    const {
      category,
      projectOwner,
      user,
      anomalyLogInstance,
      instanceName,
      patternId,
      type: eventType,
      fromStorage,
      startTimestamp,
    } = event;
    let { projectName } = event;
    const isSharedUser = (projectOwner || user) !== userName;
    if (isAdmin || isReadUser || isLocalAdmin || isSharedUser) {
      projectName = `${projectName}@${projectOwner || user || customerName}`;
    }
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);

    if (category !== 'log') {
      const query = {
        projectName,
        instanceGroup,
        startTime,
        endTime,

        patternSearch,
        statusFilterAll: isPredicted && fromStorage ? 'historicalPredictions' : 'all',
      };
      window.open(buildUrl(BaseUrls.MetricEvents, {}, query), '_blank');
    } else if (category === 'log') {
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const interval = 10 * 60 * 1000;
      const activeTimestamp = toInteger(startTimestamp / interval) * interval;
      const query = {
        projectName,
        instanceName: anomalyLogInstance || instanceName,
        startTime,
        endTime,
        activeTab: eventType === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        activeTimestamp,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: project?.owner || projectOwner,
        anomalyType: eventType.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleCausalGraphClick({ event }) {
    const { location } = this.props;
    const { causalGraphInfo, rootCauseFilterCount } = this.state;
    const { startTime, endTime, causalKey, joinDependency, duration } = causalGraphInfo;
    const { customerName, environmentId } = parseLocation(location);
    const { projectName, instanceName, patternId, type, startTimestamp, endTimestamp } = event;
    const rootCauseDetailsArr = get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    const causalInstance =
      rootCauseDetailsArr && rootCauseDetailsArr.length > 0
        ? rootCauseDetailsArr[0].instanceId || rootCauseDetailsArr[0].instanceName
        : instanceName;
    const metric =
      rootCauseDetailsArr && rootCauseDetailsArr.length > 0
        ? rootCauseDetailsArr[0].rootCauseMetric || rootCauseDetailsArr[0].metricName
        : null;

    if (causalKey && startTime && endTime) {
      const query = {
        causalKey,
        customerName,
        causalGraphVersion: 'newCausal',
        tasksStartTime: startTime,
        tasksEndTime: endTime,
        joinDependency,
      };
      window.open(buildUrl(BaseUrls.CausalAnalysis, {}, query), '_blank');
    } else {
      const query = {
        customerName,
        environmentId,
        projectName,
        instanceName: causalInstance,
        startTimestamp: startTime || startTimestamp,
        endTimestamp: endTime || endTimestamp,
        needCausalProperty: true,
        nid: patternId,
        type,
        metric,
        causalGraphVersion: 'newCausal',
        duration,
        count: rootCauseFilterCount,
        probability: '0.0',
        joinDependency,
        showHaveDataGraph: true,
      };
      window.open(buildUrl(BaseUrls.CausalGroupAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleTrendPatternsClick({ event }) {
    const { isAdmin, isReadUser, isLocalAdmin, location, userName } = this.props;
    const { customerName, startTime, endTime } = parseLocation(location);
    const { projectOwner, user, anomalyLogInstance, instanceName, patternId } = event;
    let { projectName } = event;
    const isSharedUser = (projectOwner || user) !== userName;
    if (isAdmin || isReadUser || isLocalAdmin || isSharedUser) {
      projectName = `${projectName}@${projectOwner || user || customerName}`;
    }
    const startTimeObj = moment.utc(startTime, Defaults.DateFormat).subtract(7, 'days').startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');

    const query = {
      t: '953de6a33d8a4b96ac9c100bf69ba3fc',
      projectName,
      instanceName: anomalyLogInstance || instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      pattern: patternId,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
  }

  @autobind
  handleLineChartClick({ event }) {
    const { isAdmin, isReadUser, isLocalAdmin, location, userName } = this.props;
    const { customerName, startTime, endTime, environmentId, systemId } = parseLocation(location);
    const { projectOwner, metricList, instanceList } = event;
    let { projectName } = event;
    const isSharedUser = projectOwner !== userName;
    if (isAdmin || isReadUser || isLocalAdmin || isSharedUser) {
      projectName = `${projectName}@${projectOwner || customerName}`;
    }
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,
      projectName,
      instanceGroup,
      modelType,
      startTimestamp,
      endTimestamp,
      justSelectMetric: R.join(',', metricList),
      justInstanceList: R.join(',', instanceList),
      withBaseline: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleLineChartJump({ event }) {
    const { location, globalInfo } = this.props;
    const { customerName, startTime, endTime, environmentId, systemId } = parseLocation(location);

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const metricProjects = R.filter((project) => project.dataType === 'Metric', projectNameSet);
    if (metricProjects.length > 1) {
      this.setState({
        showProjectSelector: true,
        onConfirmProjectSelect: this.handleLineChartsJumpConfirm({ event }),
      });
    } else {
      const { projectNameReal } = metricProjects[0];
      const startTimeObj = moment.utc(startTime, Defaults.DateFormat).startOf('day');
      const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';
      const query = {
        startTime,
        endTime,
        customerName,
        environmentId,
        systemId,
        projectName: projectNameReal,
        instanceGroup,
        modelType,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        justInstanceList: event.realInstanceName,
        withBaseline: true,
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    }
  }

  @autobind
  handleLineChartsJumpConfirm({ event }) {
    return (projectName) => {
      const { location } = this.props;
      const { customerName, startTime, endTime, environmentId, systemId } = parseLocation(location);
      const startTimeObj = moment.utc(startTime, Defaults.DateFormat).startOf('day');
      const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';
      const query = {
        startTime,
        endTime,
        customerName,
        environmentId,
        systemId,
        projectName,
        instanceGroup,
        modelType,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        justInstanceList: event.realInstanceName,
        withBaseline: true,
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    };
  }

  @autobind
  handleChangePatternNameClick({ event }) {
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const actionIncident = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    this.setState({ showTakeLogActionModal: true, actionName: 'setPatternName', actionIncident });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    this.setState({ showTakeLogActionModal: false, actionName: null, actionIncident: null, patternNameMap: {} }, () => {
      this.refresh();
    });
  }

  @autobind
  handleSoftwareUpdateContextClick({ event }) {
    this.setState({ showSoftwareUpdateContextModal: true });
  }

  @autobind
  handleReportJiraClick({ event }) {
    this.setState({ showReportJiraModal: true, activeEvent: event });
  }

  @autobind
  handleReportServiceNowClick({ event }) {
    this.setState({ showReportServiceNowModal: true, activeEvent: event });
  }

  @autobind
  handleRerunRCAClick({ event }) {
    const { intl, location, credentials } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const {
      projectName,
      projectOwner,
      anomalyLogInstance,
      instanceName,
      startTimestamp,
      endTimestamp,
      patternId,
      type,
    } = event;

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('onDemandRCA'), {
      ...credentials,
      projectName,
      customerName: projectOwner,
      environmentName: environmentId,
      systemName: systemId,
      instanceName: anomalyLogInstance || instanceName,
      type,
      operation: 'preceding',
      startTime: startTimestamp,
      endTime: endTimestamp,
      patternId,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  handleDeleteIncidentClick({ event }) {
    const { intl, credentials, userInfo } = this.props;
    const { anomalyLogInstance, instanceName, patternId, incidentTimestamp, startTimestamp } = event || {};
    const { projectOwner } = event;
    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    this.props.updateLastActionInfo();
    return fetchPost(getEndpoint('deleteincident'), {
      ...credentials,
      projectName,
      instanceName: anomalyLogInstance || instanceName,
      timestamp: incidentTimestamp || startTimestamp,
      patternId,
    })
      .then((data) => {
        const { success, message: msg } = data || {};

        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${msg}`);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  handleSuggestedActionsClick({ event }) {
    this.setState({ showActionModal: true, activeEvent: event });
  }

  @autobind
  getProjectNameList() {
    const { location, systemsMap } = this.props;
    const { systemId } = parseLocation(location);
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  handleExport(close) {
    this.exportModal = Modal.confirm({
      title: 'Export all alerts as CSV?',
      content: null,
      closable: true,
      onOk: this.handleOkComfirmCSV,
    });
  }

  @autobind
  async handleOkComfirmCSV(close) {
    // start download data
    if (this.exportModal) {
      this.exportModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }
    await sleep(300);

    const { location, userInfo, globalInfo } = this.props;
    const { patternNameMap } = this.state;
    const { startTime, endTime, systemId, environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const systemName = systemInfo ? systemInfo.name : systemId;
    const fname = `${systemName} ${startTime}~${endTime} alerts.csv`;
    let csvString = `${R.join(',', [
      'Pattern type',
      'Anomaly score',
      'Start time',
      'End time',
      'Pattern ID',
      'Pattern Name',
      'Project',
      'Component',
      'Instance',
      'Short description',
    ])}\r\n`;

    const csvData = [];
    const eventListExport = R.sortWith(
      [
        R.descend(R.prop('isIncident')),
        R.ascend(R.prop('type')),
        R.descend(R.prop('patternId')),
        R.ascend(R.prop('startTimestamp')),
      ],
      this.eventListExport,
    );
    R.addIndex(R.forEach)((item, idx) => {
      // get incident pattern name
      const { patternId, patternName, anomalyLogInstance, category, projectOwner, rawData, rootCausesDetailsList } =
        item;
      let { projectName } = item;
      projectName =
        userInfo.isAdmin || projectOwner !== userInfo.userName ? `${projectName}@${projectOwner}` : projectName;
      const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
      const incidentPatternName = get(patternNameMap, [key, patternId]);
      const activePatternName = incidentPatternName !== undefined ? incidentPatternName : patternName;
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName: activePatternName, patternId },
        { hasPrefix: true, hasFullName: false },
      );

      let shortDescription = '';
      if (category === 'log') {
        try {
          const rawDataJson = JSON.parse(rawData);
          shortDescription = JSON.stringify(rawDataJson, null, 4);
        } catch (error) {
          shortDescription = R.join(
            '\n',
            R.filter((x) => Boolean(x), (rawData || '').split('\n')),
          );
        }
      } else {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, rootCausesDetailsList);
        shortDescription = R.join('\n', summaryList);
      }

      csvData.push(
        R.join(',', [
          item.type,
          item.anomalyRatio,
          moment.utc(item.startTimestamp).format(Defaults.DateTimeFormat),
          moment.utc(item.endTimestamp).format(Defaults.DateTimeFormat),
          patternId,
          patternNameStr,
          item.projectName,
          item.componentListStr,
          item.instanceListStr,
          `"${R.replace(/"/g, '""', shortDescription)}"`,
        ]),
      );
    }, eventListExport);
    csvString += R.join('\r\n', csvData);

    // download csv file
    downloadFile(csvString, fname);

    // close confrim modal after
    close();
  }

  render() {
    const { intl, loadStatus, location, userList, userInfo, userName, globalInfo } = this.props;
    const { redirect, customerName, environmentId, systemId, eventProjectName } = parseLocation(location);
    const {
      startTimeObj,
      endTimeObj,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
    } = this.state;
    const { activeKey, eventList, patternNameMap, activeEvent, actionIncident } = this.state;
    const { isLoaded, isLoadingSystem, isLoadingParserData } = this.state;

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    let incidentPatternName = null;
    let projectName;
    if (activeEvent) {
      const { category, patternId, anomalyLogInstance, projectOwner } = activeEvent;
      projectName =
        userInfo.userName !== projectOwner ? `${activeEvent.projectName}@${projectOwner}` : activeEvent.projectName;
      const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
      incidentPatternName = get(patternNameMap, [key, patternId]);
    }

    const hasResult = eventList.length > 0;
    const { isLoading } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const { isLoading: isLoadingStats } = getLoadStatus(get(loadStatus, this.statsLoader), intl);
    return (
      <Container
        className={`flex-grow flex-col flex-min-height flex-min-width ${
          isLoading || isLoadingSystem ? 'loading ' : ''
        } ${isLoaded ? ' loaded' : ''}`}
      >
        <Container breadcrumb className="flex-row">
          <div className="flex-grow flex-row flex-center-align">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, {}))}>
                  <HomeOutlined />
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(appMenusMessages.globalSystemAlertCause)}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Select
                  showSearch
                  size="small"
                  value={systemId}
                  style={{ width: 120 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleSystemIdChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ),
                    systemList || [],
                  )}
                </Select>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Button
              size="small"
              onClick={this.handleExport}
              style={{ marginLeft: 16 }}
              disabled={this.eventListExport.length === 0}
            >
              {intl.formatMessage(appButtonsMessages.export)}
            </Button>
          </div>
          <div className="flex-row">
            <Container className="flex-row" style={{ alignItems: 'center' }}>
              <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.startDate)}
              </span>
              <DatePicker
                size="small"
                allowClear={false}
                showToday={false}
                value={startTimeObj}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleStartTimeChange}
              />
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.endDate)}</span>
              <DatePicker
                size="small"
                allowClear={false}
                showToday={false}
                value={endTimeObj}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleEndTimeChange}
              />

              {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.environment)}
              </span>
              <Select
                size="small"
                value={environmentId}
                style={{ width: 140 }}
                onChange={this.props.handleEnvironmentChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.name} value={item.name} title={item.name}>
                      {item.id}
                    </Select.Option>
                  ),
                  globalInfo || [],
                )}
              </Select> */}
              {userInfo.isAdmin && (
                <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
              )}
              {userInfo.isAdmin && (
                <Select
                  showSearch
                  size="small"
                  value={customerName}
                  style={{ width: 100 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleCustomerNameChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              )}

              <div
                style={{ marginLeft: 8 }}
                onMouseEnter={() => {
                  if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                    this.setState({ tooltipVisibleReloadMouseOver: true });
                }}
                onMouseLeave={() => {
                  if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
                }}
              >
                <Popover
                  mouseEnterDelay={0.3}
                  placement="bottomRight"
                  visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                  content={
                    disableRefresh
                      ? 'Range of days <= 31'
                      : timeChange
                      ? intl.formatMessage(appMessages.clickToReload)
                      : null
                  }
                >
                  <Button
                    size="small"
                    type="primary"
                    disabled={disableRefresh}
                    onClick={() => this.props.handleRefresh({ startTimeObj, endTimeObj })}
                  >
                    {intl.formatMessage(appButtonsMessages.refresh)}
                  </Button>
                </Popover>
              </div>
            </Container>
          </div>
        </Container>
        <Container className="global-view flex-grow flex-col flex-min-height" style={{ margin: '0 16px 8px 16px' }}>
          <Container className="flex-row flex-min-height" style={{ paddingBottom: 16 }}>
            {this.renderTopBar({ isLoadingStats })}
          </Container>

          <Container className="flex-grow flex-row flex-min-height" style={{ background: 'white', borderRadius: 4 }}>
            <Spin spinning={isLoadingParserData} wrapperClassName="full-width full-height spin-full-width">
              {!isLoaded && redirect === 'true' && (
                <div className="full-width full-height flex-row flex-center-align flex-center-justify">
                  <Empty
                    description={
                      <span style={{ fontSize: 16 }}>
                        {intl.formatMessage(appMessages.projectNotBelongUser, {
                          projectName: <b>{eventProjectName}</b>,
                          userName: <b>{userName}</b>,
                        })}
                      </span>
                    }
                  />
                </div>
              )}
              {isLoaded && !hasResult && (
                <div className="full-width full-height flex-row flex-center-align flex-center-justify">
                  <Empty
                    image={<SmileOutlined style={{ fontSize: 100, color: colorMap.Health }} />}
                    description={
                      <span className="flex-col">
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: 700,
                            lineHeight: '36px',
                          }}
                        >
                          There are currently no anomaly events for this system today.
                        </span>
                        <span>Select a different system or day to view results.</span>
                      </span>
                    }
                  >
                    <a href={null} onClick={this.refresh}>
                      {intl.formatMessage(appButtonsMessages.refresh)}
                    </a>
                  </Empty>
                </div>
              )}
              {isLoaded && hasResult && (
                <div className="full-width full-height" style={{ padding: 8 }}>
                  <Tabs
                    type="card"
                    className="full-height ant-tabs-content-full-height"
                    activeKey={activeKey}
                    onChange={(activeKey) => this.setState({ activeKey })}
                  >
                    {R.map((item) => {
                      const { name: tabName, title } = item;
                      const events = this.state[`eventList-${tabName}`];
                      return (
                        <Tabs.TabPane tab={title} key={tabName}>
                          {this.renderEventList(tabName, events)}
                        </Tabs.TabPane>
                      );
                    }, this.tabList)}
                  </Tabs>
                </div>
              )}
            </Spin>
          </Container>
        </Container>
        {this.state.showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={this.state.actionName}
            incident={actionIncident}
            incidentPatternName={incidentPatternName}
            projectName={actionIncident.fullProjectName}
            instanceGroup={environmentId}
            eventType={actionIncident.type}
            onClose={() => this.setState({ showTakeLogActionModal: false, actionName: null, actionIncident: null })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {this.state.showActionModal && (
          <EventActionModal
            suggestActions={get(activeEvent, 'suggestActionList', [])}
            incident={activeEvent}
            projectName={projectName}
            projectNameList={this.getProjectNameList()}
            actionRootCauseKey={this.state.actionRootCauseKey}
            onClose={(needReload) => {
              this.setState({ showActionModal: false, activeEvent: null });
            }}
          />
        )}
        {this.state.showSoftwareUpdateContextModal && (
          <GlobalRenderers.SoftwareUpdateContextModal
            intl={intl}
            incident={activeEvent}
            onCancel={() => this.setState({ showSoftwareUpdateContextModal: false })}
          />
        )}
        {this.state.showProjectSelector && (
          <ProjectSelectorModal
            system={systemInfo}
            onConfirm={this.state.onConfirmProjectSelect}
            onClose={() => this.setState({ showProjectSelector: false })}
          />
        )}
        {this.state.showReportJiraModal && (
          <ReportJiraModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showReportJiraModal: false })}
          />
        )}
        {this.state.showReportServiceNowModal && (
          <ReportServiceNowModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showReportServiceNowModal: false })}
          />
        )}

        {/* General modals */}
        {this.state.showRCModal && (
          <LikelyRootCausesModal
            incident={activeEvent}
            environmentId={environmentId}
            systemId={systemId}
            projectName={projectName}
            functionRC="alert"
            needRC={this.state.needRC}
            needPT={this.state.needPT}
            onClose={() => this.setState({ showRCModal: false, activeEvent: null, needRC: false, needPT: false })}
          />
        )}
        {this.state.showRecommendationsModal && (
          <RecommendationsModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showRecommendationsModal: false, activeEvent: null })}
          />
        )}
        {this.state.showTriageReportModal && (
          <TriageReportModal
            environmentId={environmentId}
            systemId={systemId}
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showTriageReportModal: false, activeEvent: null })}
          />
        )}
        {this.state.showTriggeredActionsModal && (
          <TriggeredActionsModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showTriggeredActionsModal: false, activeEvent: null })}
          />
        )}
      </Container>
    );
  }
}

const GlobalSystemAlertCauseList = injectIntl(GlobalSystemAlertCauseListCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset, globalInfo, projects, projectDisplayMap, systemsMap, currentTheme } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isReadUser, isLocalAdmin, userName } = state.auth.userInfo;
    const {
      globalSystemInfo,
      globalSystemComponentAnomalyCount,
      globalSystemInstanceAnomalyCount,
      globalSystemAlertStatistics,
      patternNameMap,
    } = state.dashboard;
    return {
      credentials,
      userList,
      userInfo,
      isAdmin,
      userName,
      projects,
      projectDisplayMap,
      systemsMap,
      isReadUser,
      isLocalAdmin,
      location,
      loadStatus,
      timezoneOffset,
      globalInfo,
      globalSystemInfo,
      globalSystemComponentAnomalyCount,
      globalSystemInstanceAnomalyCount,
      globalSystemAlertStatistics,
      patternNameMap,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(GlobalSystemAlertCauseList);
