/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { round } from 'lodash';
import moment from 'moment';

import type { Credentials } from '../types';
import { Defaults } from '../../common/utils';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getEventsHistory = (credentials: Credentials, params: Object) => {
  const { environmentName, startTimestamp, endTimestamp, systemIds, customerName, isHistorical, isAdmin } = params;

  const promiseQuery = [
    fetchGet(getEndpoint('stats/global/systemanomalyscore', 2), {
      ...credentials,
      envName: environmentName,
      startTime: startTimestamp,
      endTime: endTimestamp,
      customerName,
      systemIds: JSON.stringify(systemIds),
      isHistorical,
      shareUserFlag: customerName !== credentials.userName && !isAdmin,
    }),
  ];

  return Promise.all(promiseQuery).then((results) => {
    const eventsHistory = [];
    R.forEach((queryResult) => {
      R.forEach((data) => {
        const { systemName, healthScoreMap } = data;
        const anomalyScoress = {};
        R.forEachObjIndexed((val, key) => {
          const day = moment.utc(Number(key)).format(Defaults.DateFormat);
          anomalyScoress[day] = { timestamp: Number(key), score: round(val, 2) };
        }, healthScoreMap || {});
        eventsHistory.push({
          systemId: systemName,
          systemName,
          anomalyScoress,
        });
      }, queryResult);
    }, results);
    return {
      eventsHistory,
    };
  });
};
export default getEventsHistory;
