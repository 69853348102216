/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import * as R from 'ramda';
import { get, isNil, max, sum } from 'lodash';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions, ifIn, calcAnomalyLevel } from '../../utils';
import { getGlobalSystemInstances } from '../../apis';
import { appMessages } from '../../app/messages';

const loadGlobalSystemInstancesLogic = createLogic({
  type: [ActionTypes.LOAD_GLOBAL_SYSTEM_INSTANCES],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { environmentId } = params;
    const { globalInfo } = state.app;

    // Get the system id for the environment
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const instanceIds = get(environment, 'instanceIds', []);
    const environmentName = get(environment, 'name');

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getGlobalSystemInstances(credentials, { ...params, instanceIds, environmentName })
      .then((d) => {
        // Build the tree structure based on the global info.
        const instanceInfo = [];

        R.forEach((system) => {
          let regionList = [];
          R.forEach((region) => {
            let componentList = [];
            R.forEach((component) => {
              let instanceList = [];
              let predictedInstanceList = [];
              R.forEach((inst) => {
                const { id, name } = inst;
                const pastScore = get(inst, ['healthScores', 'past'], 0);
                const currentScore = get(inst, ['healthScores', 'current'], 0);
                const futureScore = get(inst, ['healthScores', 'future'], 0);
                const allScore = max([currentScore, pastScore, futureScore]);
                const detectedScore = max([currentScore, pastScore]);
                const predictedScore = futureScore;
                if (!isNil(detectedScore) && id && ifIn(id, component.instanceIds || [])) {
                  instanceList.push({
                    instanceId: id,
                    instanceName: name,
                    score: detectedScore,
                    allScore,
                    pastScore,
                    currentScore,
                    futureScore,
                    anomalyLevel: calcAnomalyLevel(detectedScore),
                  });
                }
                if (!isNil(predictedScore) && id && ifIn(id, component.instanceIds || [])) {
                  predictedInstanceList.push({
                    instanceId: id,
                    instanceName: name,
                    allScore,
                    score: predictedScore,
                    pastScore,
                    currentScore,
                    futureScore,
                    anomalyLevel: calcAnomalyLevel(predictedScore),
                  });
                }
              }, d || []);
              instanceList = R.sort((a, b) => b.score - a.score, instanceList);
              predictedInstanceList = R.sort((a, b) => b.score - a.score, predictedInstanceList);
              const maxAllScore = max(R.map((i) => i.allScore, [...instanceList, ...predictedInstanceList]));
              const maxScore = max(R.map((i) => i.score, instanceList));
              const maxPredictedScore = max(R.map((i) => i.score, predictedInstanceList));
              const sumPast = sum(R.map((i) => i.pastScore, instanceList));
              const sumCurrent = sum(R.map((i) => i.currentScore, instanceList));
              const sumFuture = sum(R.map((i) => i.futureScore, instanceList));
              componentList.push({
                componentId: component.id,
                componentName: component.name,
                instanceList,
                predictedInstanceList,
                maxAllScore,
                maxScore,
                maxPredictedScore,
                sumPast,
                sumCurrent,
                sumFuture,
              });
            }, region.componentList);
            componentList = R.sort((a, b) => b.maxScore - a.maxScore, componentList);
            const predictedComponentList = R.sort((a, b) => b.maxPredictedScore - a.maxPredictedScore, componentList);
            const maxScore = max(R.map((i) => i.maxAllScore, componentList));
            const sumPast = sum(R.map((i) => i.sumPast, componentList));
            const sumCurrent = sum(R.map((i) => i.sumCurrent, componentList));
            const sumFuture = sum(R.map((i) => i.sumFuture, componentList));
            regionList.push({
              regionId: region.id,
              regionName: region.name,
              componentList,
              predictedComponentList,
              maxScore,
              sumPast,
              sumCurrent,
              sumFuture,
            });
          }, system.regionList);
          regionList = R.sort((a, b) => b.maxScore - a.maxScore, regionList);
          const maxScore = max(R.map((i) => i.maxScore, regionList));
          const sumPast = sum(R.map((i) => i.sumPast, regionList));
          const sumCurrent = sum(R.map((i) => i.sumCurrent, regionList));
          const sumFuture = sum(R.map((i) => i.sumFuture, regionList));
          instanceInfo.push({
            systemId: system.id,
            systemName: system.name,
            regionList,
            maxScore,
            sumPast,
            sumCurrent,
            sumFuture,
          });
        }, get(environment, 'systemList', []));

        dispatch(createSetAction(ActionTypes.SET_GLOBAL_SYSTEM_INSTANCES, params, instanceInfo));
        dispatch(hideLoading);
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed get global system instances, ignored', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadGlobalSystemInstancesLogic;
