/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, message, Select, Radio, InputNumber } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { State } from '../../../../common/types';
import { getLoadStatus } from '../../../../common/utils';
import {
  AutoSizer,
  Table,
  Column,
  Container,
  CellMeasurerCache,
  CellMeasurer,
  Popover,
} from '../../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../../common/app/actions';
import { ActionTypes } from '../../../../common/settings/actions';

import { appButtonsMessages, appMessages } from '../../../../common/app/messages';
import { eventActionMessages } from '../../../../common/metric/messages';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchDelete from '../../../../common/apis/fetchDelete';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  // Special props
  width: Number,
  height: Number,
  projectName: String,
  refreshTime: Number,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  actionsList: Array<Object>,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class ActionSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'action_setting_loader';
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.k8sOptions = [
      { label: 'Scale Down To', value: 'ScaleDownTo' },
      { label: 'Scale Up To', value: 'ScaleUpTo' },
      { label: 'Scale Down By', value: 'ScaleDownBy' },
      { label: 'Scale Up By', value: 'ScaleUpBy' },
    ];

    this.state = { actionFilter: '', actionSearch: '', isUpdating: false, cmdOptions: [], isLoading: false };
    this.dataList = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { projectName, createLoadAction, credentials } = props;
    this.setState({
      isLoading: true,
    });
    if (projectName) {
      fetchGet(getEndpoint(`IFProjectActionsServlet`, 2), {
        projectName,
        ...credentials,
      })
        .then((res) => {
          const { actionServers, actions } = res;
          const cmdOptions = [];
          const dataList = [];

          R.forEach((serverMap) => {
            const {
              actionCMDList,
              key: { serverId },
            } = serverMap;
            R.forEach((cmd) => {
              cmdOptions.push({ title: cmd, value: cmd, serverId });
            }, actionCMDList);
          }, actionServers);

          R.forEach((item) => {
            const { actionType, actionName, actionData, key, actionDampeningPeriod } = item;
            const newActionData = JSON.parse(actionData);
            let obj = {};
            if (actionType === 'k8s') {
              R.forEach((action) => {
                if (newActionData[action.value]) {
                  obj = { command: action.value, commandNumber: newActionData[action.value] };
                }
              }, this.k8sOptions);
            } else {
              obj = { command: newActionData.cmd, commandNumber: '' };
            }

            dataList.push({
              actionName,
              actionType,
              ...obj,
              target: newActionData.target,
              key,
              actionDampeningPeriod,
            });
          }, actions);
          this.dataList = dataList;
          this.dataTable.forceUpdateGrid();
          this.forceUpdate();
          this.setState({
            isLoading: false,
            cmdOptions,
          });
        })
        .catch((e) => {
          message.error(e.message);
          this.setState({
            isLoading: false,
          });
        });
      createLoadAction(ActionTypes.LOAD_PROJECT_ACTIONS, { projectName }, this.dataLoader);
    }
  }

  @autobind
  parseData(props) {
    const { actionsList } = props;

    const localEventList = this.filterData(R.clone(actionsList || []), this.state);
    this.dataList = localEventList;
    this.dataTable.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  filterData(eventList, state) {
    const { actionSearch } = state;
    let filterList = eventList || [];

    if (actionSearch) {
      filterList = R.filter((event) => R.toLower(event.actionName).includes(R.toLower(actionSearch)), filterList);
    }

    return filterList;
  }

  @autobind
  handleAddClick(e) {
    e.preventDefault();
    this.dataList.unshift({
      actionName: '',
      actionType: 'k8s',
      command: 'ScaleDownTo',
      commandNumber: '',
      target: 'single',
      actionDampeningPeriod: 0,
    });
    this.dataTable.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleRemove(rowData, rowIndex) {
    return async () => {
      this.dataList = R.remove(rowIndex, 1, this.dataList);
      this.dataTable.forceUpdateGrid();
      this.forceUpdate();
      const { projectName, credentials, intl } = this.props;
      const { key } = rowData;
      const self = this;
      if (key) {
        const { actionId } = key;
        this.setState({ isLoading: true });
        await fetchDelete(getEndpoint(`IFProjectActionsServlet`, 2), {
          projectName,
          ...credentials,
          deleteActionIds: JSON.stringify([actionId]),
        })
          .then((res) => {
            self.setState({ isLoading: false });
            message.success(intl.formatMessage(appMessages.apiSuccess));
          })
          .catch((e) => {
            self.setState({ isLoading: false });
            message.error(intl.formatMessage(appMessages.apiFaild));
          });
      }
    };
  }

  @autobind
  handleSaveClick(e) {
    e.preventDefault();
    const { projectName, actionsList, credentials } = this.props;
    const { cmdOptions } = this.state;
    const diff = R.difference(this.dataList, actionsList || []);

    const actions = R.map((item) => {
      const { actionName, actionType, command, commandNumber, target, key, actionDampeningPeriod } = item;
      const k8s = { [command]: commandNumber, target };
      const script = { cmd: command, target };
      const isType = R.equals(actionType, 'k8s');
      if (R.equals(actionType, 'script')) {
        const { serverId } = R.find((item) => item.value === command, cmdOptions);
        script.serverId = serverId;
      }
      const actionId = get(key, 'actionId', null);
      if (actionId) {
        return {
          actionId,
          actionName,
          actionType,
          actionData: isType ? k8s : script,
          actionDampeningPeriod,
        };
      }

      return {
        actionName,
        actionType,
        actionData: isType ? k8s : script,
        actionDampeningPeriod,
      };
    }, diff);

    this.setState({
      isUpdating: true,
    });
    fetchPost(
      getEndpoint(`IFProjectActionsServlet?actions=${R.trim(JSON.stringify(actions))}&projectName=${projectName}`, 2),
      {
        projectName,
        ...credentials,
        actions: R.trim(JSON.stringify(actions)),
      },
    )
      .then((res) => {
        message.success('Action Updated');
        this.setState({
          isUpdating: false,
        });
        this.reloadData(this.props);
      })
      .catch((e) => {
        message.success(e.message);
        this.setState({
          isUpdating: false,
        });
      });
  }

  @autobind
  removeRenderer({ rowData, rowIndex }) {
    const { intl } = this.props;
    return (
      <Popconfirm
        placement="top"
        title={`delete this action:${rowData.actionName}?`}
        onConfirm={this.handleRemove(rowData, rowIndex)}
        okText="Yes"
        cancelText="No"
      >
        <div className="hover-show ui grey button">{intl.formatMessage(appButtonsMessages.remove)}</div>
      </Popconfirm>
    );
  }

  @autobind
  handleInputChanged(rowData, dataKey) {
    return (e) => {
      const newVal = e.target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.dataTable.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleCmdInputChanged(rowData, dataKey) {
    return (e) => {
      const newVal = e.target.value || '';
      if (/^\d+$/gi.test(newVal) || R.isEmpty(newVal)) {
        rowData[dataKey] = newVal;
        this.dataTable.forceUpdateGrid();
        this.forceUpdate();
      }
    };
  }

  @autobind
  renderCellMeasureInput(props) {
    const { dataKey, parent, rowIndex, cellData, rowData, style } = props;
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ ...style, width: '100%' }}>
          <Input value={cellData} onChange={this.handleInputChanged(rowData, dataKey)} size="small" />
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  renderCommand(props) {
    const { dataKey, parent, rowIndex, cellData, rowData, style } = props;
    const { cmdOptions } = this.state;

    const isK8s = rowData.actionType === 'k8s';

    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        {isK8s && (
          <Select
            defaultValue={this.k8sOptions[0].value}
            style={{ ...style, width: '100%' }}
            options={this.k8sOptions}
            value={cellData}
            size="small"
            onChange={this.handleSelectChange(rowData, dataKey)}
          />
        )}

        {!isK8s && (
          <Select
            style={{ ...style, width: '100%' }}
            options={cmdOptions}
            value={cellData}
            size="small"
            onChange={this.handleSelectChange(rowData, dataKey)}
          />
        )}
      </CellMeasurer>
    );
  }

  @autobind
  renderCellSelect(props) {
    const { dataKey, parent, rowIndex, cellData, rowData, style } = props;
    const options = [
      { label: 'Auto Scaling', value: 'k8s' },
      { label: 'Script', value: 'script' },
    ];
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <Select
          style={{ ...style, width: '100%' }}
          options={options}
          value={cellData}
          onChange={this.handleSelectChange(rowData, dataKey)}
          size="small"
        />
      </CellMeasurer>
    );
  }

  @autobind
  renderCellRadio(props) {
    const { dataKey, parent, rowIndex, cellData, rowData, style } = props;
    const options = [
      {
        label: 'Dynamic Instance',
        value: 'single',
      },
      {
        label: 'All Instance',
        value: 'all',
        disabled: rowData.actionType === 'k8s',
      },
    ];
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ ...style }}>
          <Radio.Group options={options} value={cellData} onChange={this.handleRadioChange(rowData, dataKey)} />
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  renderInput(props) {
    const { dataKey, cellData, rowData } = props;
    return <Input value={cellData} onChange={this.handleInputChanged(rowData, dataKey)} size="small" />;
  }

  @autobind
  renderCmdInput(props) {
    const { dataKey, cellData, rowData } = props;
    const isK8s = rowData.actionType === 'k8s';

    return (
      <>
        {isK8s && (
          <Input
            value={cellData}
            onChange={this.handleCmdInputChanged(rowData, dataKey)}
            placeholder="Please input number"
            size="small"
          />
        )}
      </>
    );
  }

  @autobind
  renderDampeningPeriod(props) {
    const { dataKey, cellData, rowData } = props;
    return <InputNumber value={(cellData || 0) / 60 / 1000} onChange={this.handleDampeningPeriod(rowData, dataKey)} />;
  }

  @autobind
  handleDampeningPeriod(rowData, dataKey) {
    return (value) => {
      const newVal = value || 0;
      if (/^\d+$/gi.test(newVal) || R.isEmpty(newVal)) {
        rowData[dataKey] = (newVal || 0) * 60 * 1000;
        this.dataTable.forceUpdateGrid();
        this.forceUpdate();
      }
    };
  }

  @autobind
  handleSelectChange(rowData, dataKey) {
    return (value) => {
      const newVal = value || '';
      rowData.command = '';
      rowData[dataKey] = newVal;
      this.dataTable.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleRadioChange(rowData, dataKey) {
    return (e) => {
      const newVal = e.target.value || '';
      rowData[dataKey] = newVal;
      this.dataTable.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  headerRendererCommand({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    return (
      <div>
        {label}
        <Popover
          title={null}
          content={
            <div style={{ maxWidth: 350, wordBreak: 'break-word' }}>
              Command refers to the execution command (e.g., ansible, sh, python, python2, python3) for running the
              defined scripts.
            </div>
          }
          mouseEnterDelay={0.3}
        >
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Popover>
      </div>
    );
  }

  @autobind
  handleActionSearchChange(actionSearch) {
    this.setState({ actionSearch }, () => {
      const { actionsList } = this.props;
      this.dataList = this.filterData(R.clone(actionsList || []), this.state);

      this.dataTable.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  handleSync() {
    const { intl, updateLastActionInfo, credentials, projectName } = this.props;
    this.setState({ isUpdating: true });
    updateLastActionInfo();
    fetchPost(
      getEndpoint('projectactions'),
      {
        ...credentials,
        projectName,
        operation: 'sync',
      },
      {},
      false,
    )
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isUpdating: false });
        this.reloadData(this.props);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isUpdating: false });
      });
  }

  render() {
    const { width, height } = this.props;
    const { intl, loadStatus, actionsList } = this.props;
    const { isUpdating, isLoading } = this.state;

    const diff = R.difference(this.dataList, actionsList || []);

    const findEmpty = R.filter((item) => {
      const { actionName, actionType, commandNumber, command } = item;
      const isType = R.equals(actionType, 'k8s');
      return R.isEmpty(actionName) || (isType ? R.isEmpty(commandNumber) : R.isEmpty(command));
    }, diff);

    const hasError = diff.length === 0 || !R.isEmpty(findEmpty);

    const { errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    return (
      <Container
        style={{ width, height }}
        className={`flex-col ${isLoading && !errorMessage ? ' loading' : ''}`}
        fullHeight
      >
        <div className="field">
          <Button type="primary" size="small" onClick={this.handleAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>
        <Container className={`flex-grow field${isLoading ? ' disabled' : ''}`} style={{ padding: '8px 0' }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCache.rowHeight}
                rowCount={this.dataList.length}
                rowGetter={({ index }) => this.dataList[index]}
                ref={(c) => {
                  this.dataTable = c;
                }}
              >
                <Column
                  width={200}
                  label={intl.formatMessage(eventActionMessages.actionName)}
                  dataKey="actionName"
                  cellRenderer={this.renderCellMeasureInput}
                />
                <Column
                  width={140}
                  label={intl.formatMessage(eventActionMessages.actionType)}
                  dataKey="actionType"
                  cellRenderer={this.renderCellSelect}
                />
                <Column
                  width={160}
                  label="Command Name"
                  dataKey="command"
                  cellRenderer={this.renderCommand}
                  headerRenderer={this.headerRendererCommand}
                />
                <Column width={150} label="" dataKey="commandNumber" cellRenderer={this.renderCmdInput} />
                <Column
                  width={320}
                  label={intl.formatMessage(eventActionMessages.actionTarget)}
                  dataKey="target"
                  cellRenderer={this.renderCellRadio}
                />
                <Column
                  width={140}
                  label={intl.formatMessage(settingsMessages.dampeningPeriod)}
                  dataKey="actionDampeningPeriod"
                  cellRenderer={this.renderDampeningPeriod}
                />
                <Column
                  width={100}
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRenderer}
                  dataKey="actionName"
                />
              </Table>
            )}
          </AutoSizer>
        </Container>
        <Container className="field flex-row">
          <div className="flex-grow" />
          <Button size="small" type="primary" loading={isUpdating} disabled={hasError} onClick={this.handleSaveClick}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </Container>
      </Container>
    );
  }
}

const ActionSetting = injectIntl(ActionSettingCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials } = state.auth;
    const { actionsList } = state.settings;

    return { location, loadStatus, credentials, actionsList };
  },
  { createLoadAction, updateLastActionInfo },
)(ActionSetting);
