/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { get, round } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getMetricCapacityPlanningCalendar = (credentials: Credentials, params: Object) => {
  const { projectName, env, startTimestamp, endTimestamp } = params;

  return fetchGet(getEndpoint('fetchpredictedcapacityscore'), {
    ...credentials,
    projectName,
    instanceGroup: env,
    startTimestamp,
    endTimestamp,
  }).then((data) => {
    const { PredictCapacityPlaningScoreResult, realTimeCapacityPlaningScoreResult } = data || {};
    const capacityPlanningCalendarInfo = PredictCapacityPlaningScoreResult || {};
    const capacityPlanningCalendarRealInfo = realTimeCapacityPlaningScoreResult || {};
    return {
      capacityPlanningCalendarInfo,
      capacityPlanningCalendarRealInfo,
    };
  });
};
export default getMetricCapacityPlanningCalendar;
