/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Alert, Form, Select, Input } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Container, Popover } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

class EndpointSettingAWSCost extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoadingKey: false,
      isLoading: false,
      errorMessageKey: '',

      accessKeyId: '',
      secretKey: '',
      costType: 'UnblendedCost',
      granularity: '',
      samplingInterval: 0,
      collectionFrequency: 0,
      millisecondUnit: 3600000,
    };
    this.accessKeyId = '';
    this.secretKey = '';
    this.costType = 'UnblendedCost';
    this.granularity = '';
    this.samplingInterval = 0;
    this.collectionFrequency = 0;

    this.costTypeOption = [
      { label: 'AmortizedCost', value: 'AmortizedCost' },
      { label: 'Unblended Cost', value: 'UnblendedCost' },
    ];
    this.granularityOption = [
      { label: 'Hourly', value: 'hourly' },
      { label: 'Daily', value: 'daily' },
    ];
    this.millisecondUnitOption = [{ label: 'hour', value: 3600000 }];
  }

  componentDidMount() {
    this.reloadKey(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadKey(nextProps);
    }
  }

  @autobind
  reloadKey(props) {
    const { credentials, projectName, currentProject } = props;
    const { millisecondUnit } = this.state;

    this.setState({ isLoadingKey: true });
    const cloudType = get(currentProject, ['cloudType']);
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const {
          success,
          message,
          accessKeyId,
          secretKey,
          costType,
          granularity,
          samplingInterval,
          collectionFrequency,
        } = data || {};
        if (success === undefined || success) {
          this.accessKeyId = accessKeyId;
          this.secretKey = secretKey;
          this.costType = costType || 'UnblendedCost';
          this.granularity = granularity;
          this.samplingInterval = Number(samplingInterval) / (granularity === 'hourly' ? 3600 : 24 * 3600);
          this.collectionFrequency = Number(collectionFrequency) / millisecondUnit;
          this.setState({
            isLoadingKey: false,
            errorMessageKey: undefined,

            accessKeyId: accessKeyId || '',
            secretKey: secretKey || '',
            costType: costType || 'UnblendedCost',
            granularity: granularity || '',
            samplingInterval: Number(samplingInterval) / (granularity === 'hourly' ? 3600 : 24 * 3600),
            collectionFrequency: Number(collectionFrequency) / millisecondUnit,
          });
        } else {
          this.setState({
            isLoadingKey: false,
            errorMessageKey: message,

            accessKeyId: '',
            secretKey: '',
            costType: 'UnblendedCost',
            granularity: '',
            samplingInterval: 0,
            collectionFrequency: 0,
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoadingKey: false,
          errorMessageKey: errMsg || String(err),

          accessKeyId: '',
          secretKey: '',
          costType: 'UnblendedCost',
          granularity: '',
          samplingInterval: 0,
          collectionFrequency: 0,
        });
      });
  }

  @autobind
  handleUpdateKey() {
    const { credentials, projectName } = this.props;
    const { accessKeyId, secretKey, costType, granularity, samplingInterval, collectionFrequency, millisecondUnit } =
      this.state;
    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      accessKeyId,
      secretKey,
      costType,
      granularity,
      samplingInterval: Number(samplingInterval) * (granularity === 'hourly' ? 3600 : 24 * 3600),
      collectionFrequency: Number(collectionFrequency) * millisecondUnit,
    })
      .then((data) => {
        const { success, message } = data;
        if (success === undefined || success) {
          this.setState({ isLoadingKey: false, errorMessageKey: undefined });
          this.reloadKey(this.props);
        } else {
          this.setState({
            isLoadingKey: false,
            errorMessageKey: message,
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({ isLoadingKey: false, errorMessageKey: errMsg || String(err) });
      });
  }

  render() {
    const { intl } = this.props;
    const { isLoadingKey, isLoading, errorMessageKey } = this.state;
    const { accessKeyId, secretKey, costType, granularity, samplingInterval, collectionFrequency, millisecondUnit } =
      this.state;

    const hasErrKey =
      !accessKeyId || !secretKey || !costType || !granularity || !samplingInterval || !collectionFrequency;
    const hasChangeKey =
      this.accessKeyId !== accessKeyId ||
      this.secretKey !== secretKey ||
      this.costType !== costType ||
      this.granularity !== granularity ||
      this.samplingInterval !== samplingInterval ||
      this.collectionFrequency !== collectionFrequency;
    return (
      <Container fullHeight className={`overflow-y-auto ${isLoadingKey || isLoading ? ' loading' : ''}`}>
        <div className="full-height" style={{ width: 800 }}>
          <Form layout="vertical">
            <Form.Item
              label="Access Key ID"
              required
              validateStatus={!accessKeyId ? 'error' : 'success'}
              help={!accessKeyId ? 'This input is required.' : undefined}
            >
              <Input.Password value={accessKeyId} onChange={(e) => this.setState({ accessKeyId: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Secret Key"
              required
              validateStatus={!secretKey ? 'error' : 'success'}
              help={!secretKey ? 'This input is required.' : undefined}
            >
              <Input.Password value={secretKey} onChange={(e) => this.setState({ secretKey: e.target.value })} />
            </Form.Item>
            <Form.Item
              label={
                <div className="flex-row flex-center-align">
                  <span style={{ marginRight: 8 }}>Cost Type</span>
                  <Popover
                    content={
                      <div style={{ width: 400 }}>
                        If you purchased Savings Plans or Reservations, you can choose amortized costs to better
                        analyzing your cost trends. Otherwise, unblended costs should be selected.
                      </div>
                    }
                    placement="right"
                    mouseEnterDelay={0.3}
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </div>
              }
              required
              validateStatus={!costType ? 'error' : 'success'}
              help={!costType ? 'This input is required.' : undefined}
            >
              <Select
                options={this.costTypeOption}
                value={costType}
                onChange={(costType) => this.setState({ costType })}
              />
            </Form.Item>
            <Form.Item
              label="Granularity"
              required
              validateStatus={!granularity ? 'error' : 'success'}
              help={!granularity ? 'This input is required.' : undefined}
            >
              <Select
                options={this.granularityOption}
                value={granularity}
                onChange={(granularity) => this.setState({ granularity })}
              />
            </Form.Item>
            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item
              label="Collection Frequency"
              validateStatus={!collectionFrequency ? 'error' : 'success'}
              help={!collectionFrequency ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={collectionFrequency}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ collectionFrequency: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.millisecondUnitOption}
                  value={millisecondUnit}
                  onChange={(millisecondUnit) => this.setState({ millisecondUnit })}
                />
              </div>
            </Form.Item>

            {errorMessageKey && (
              <Alert message="Error" description={errorMessageKey} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button type="primary" disabled={hasErrKey || !hasChangeKey} onClick={this.handleUpdateKey}>
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    );
  }
}

const EndpointSettingGoogleCloud = injectIntl(EndpointSettingAWSCost);
export default connect((state) => {
  return {};
}, {})(EndpointSettingGoogleCloud);
