/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { CaretDownOutlined, CaretUpOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message, Tag, TimePicker, Select } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { hideAppLoader, updateLastActionInfo } from '../../../common/app/actions';
import { Container, AutoSizer, Table, Column, Popover } from '../../../lib/fui/react';
import { State } from '../../../common/types';
import { Defaults, CellRenderers, parseJSON } from '../../../common/utils';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  intl: Object,
  refresh: Number,
  credentials: Object,
  hideAppLoader: Function,
  updateLastActionInfo: Function,
  appLoaderVisible: Boolean,
};

class TaskStatusCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.gridOffsetY = 40;
    this.taskStatus = [];

    const startTimeObj = moment.utc().subtract(1, 'hours');
    const endTimeObj = moment.utc();

    this.state = {
      isLoading: false,
      dates: [startTimeObj, endTimeObj],
      startTimeObj,
      endTimeObj,
      taskStatus: [],
      filterQueue: null,
    };
    this.jobRunningStatus = ['running', 'retrying', 'waiting', 'terminating', 'terminated', 'success'];
    this.queueOptions = [];
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.refresh !== this.props.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const self = this;
    const { credentials } = props;
    const { startTimeObj, endTimeObj } = this.state;

    self.setState({ isLoading: true });
    props.updateLastActionInfo();
    fetchGet(getEndpoint('jobstatus'), {
      ...credentials,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
    })
      .then((data) => {
        const jobStatusMap = parseJSON(get(data, 'jobStatusMap')) || {};

        let taskStatus = [];
        R.forEachObjIndexed((val, queueName) => {
          R.forEach((item) => {
            const { jobId, jobRunningStatus, jobParameter, jobRunningProperty, jobStatusKey, ...rest } = item;
            const { registeredTimestamp, jobOriginator } = jobStatusKey;
            const parameter = parseJSON(jobParameter);
            const { cronType, startTimestamp, endTimestamp } = parameter;
            const runningProperty = parseJSON(jobRunningProperty);
            const { jobStartTime, retryCount, maxRetryCount, timeout } = runningProperty;
            const jobEndTime = jobStartTime ? jobStartTime + timeout : null;
            let statusCode = 1;
            switch (jobRunningStatus) {
              case 'success':
                statusCode = 1;
                break;
              case 'terminated':
                statusCode = 2;
                break;
              case 'terminating':
                statusCode = 3;
                break;
              case 'waiting':
                statusCode = 4;
                break;
              case 'retrying':
                statusCode = 5;
                break;
              case 'running':
                statusCode = 6;
                break;
              default:
                break;
            }

            taskStatus.push({
              ...rest,
              jobStatusKey,
              parameter,
              runningProperty,

              jobId,
              queueName,
              statusCode,
              jobRunningStatus,
              registeredTimestamp,
              jobOriginator,
              cronType,
              startTimestamp,
              endTimestamp,
              jobStartTime,
              jobEndTime,
              retryCount,
              maxRetryCount,
              timeout,
            });
          }, val);
        }, jobStatusMap);
        taskStatus = this.sortData(taskStatus, this.state);

        let queues = R.uniq(R.map((item) => item.queueName, taskStatus));
        queues = R.sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        }, queues);
        this.queueOptions = R.map((queue) => ({ value: queue, label: queue }), queues);

        this.taskStatus = R.clone(taskStatus);
        self.setState({ taskStatus, isLoading: false });
      })
      .catch((err) => {
        this.taskStatus = [];
        self.setState({ taskStatus: [], isLoading: false });
      });
  }

  @autobind
  sortData(datas, state) {
    const { sortBy, sortDirection } = state;

    // sort by
    let sortList = datas;
    let sortFunctions = [R.descend(R.prop('statusCode')), R.descend(R.prop('jobStartTime'))];
    if (sortBy && sortDirection) {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);

    return sortList;
  }

  @autobind
  handleSelectChange(filterQueue) {
    let { taskStatus } = this;
    if (filterQueue) {
      taskStatus = R.filter((item) => item.queueName === filterQueue, taskStatus);
    }
    this.setState({ filterQueue, taskStatus });
  }

  @autobind
  statusRenderer(props) {
    const { intl, cellData, rowData } = props;
    const { retryCount, maxRetryCount, timeout, startTimestamp, endTimestamp } = rowData;
    let color = 'normal';
    switch (cellData) {
      case 'running':
        color = Defaults.ColorStatusFont.info;
        break;
      case 'retrying':
        color = 'lightseagreen';
        break;
      case 'waiting':
        color = Defaults.ColorStatusFont.warning;
        break;
      case 'terminating':
        color = Defaults.ColorStatusFont.error;
        break;
      case 'terminated':
        color = Defaults.ColorStatusFont.error;
        break;
      case 'success':
        color = Defaults.ColorStatusFont.success;
        break;
      default:
        break;
    }

    return (
      <div className="flex-row flex-center-align">
        <Tag color={color}>{cellData}</Tag>
        <Popover
          title={null}
          content={
            <div className="flex-col">
              <div className="flex-row flex-center-align">
                <div style={{ width: 110 }}>{intl.formatMessage(eventMessages.retryCount)}:</div>
                <div>{retryCount}</div>
              </div>
              <div className="flex-row flex-center-align">
                <div style={{ width: 110 }}>{intl.formatMessage(eventMessages.maxRetryCount)}:</div>
                <div>{maxRetryCount}</div>
              </div>
              <div className="flex-row flex-center-align">
                <div style={{ width: 110 }}>{intl.formatMessage(eventMessages.timeout)}:</div>
                <div>{CellRenderers.humanizeDuration({ period: timeout, intl })}</div>
              </div>
              <div className="flex-row flex-center-align">
                <div style={{ width: 110 }}>{intl.formatMessage(eventMessages.taskParametersStartTime)}:</div>
                <div>{moment.utc(startTimestamp).format(Defaults.DateTimeFormat)}</div>
              </div>
              <div className="flex-row flex-center-align">
                <div style={{ width: 110 }}>{intl.formatMessage(eventMessages.taskParametersEndTime)}:</div>
                <div>{moment.utc(endTimestamp).format(Defaults.DateTimeFormat)}</div>
              </div>
            </div>
          }
          mouseEnterDelay={0.3}
          placement="top"
        >
          <QuestionCircleOutlined style={{ fontSize: 14 }} />
        </Popover>
      </div>
    );
  }

  @autobind
  handleTimeChange(category, timeObj) {
    if (category === 'startTime') {
      this.setState({ startTimeObj: timeObj });
    } else {
      this.setState({ endTimeObj: timeObj });
    }
  }

  @autobind
  timestampRenderer(props) {
    const { cellData } = props;
    return <div>{cellData ? moment.utc(Number(cellData)).format(Defaults.DateTimeFormat) : 'N/A'}</div>;
  }

  @autobind
  controlRenderer({ rowData }) {
    const { intl } = this.props;
    const { jobRunningStatus } = rowData;
    const disabled = ['terminating', 'terminated', 'success'].includes(jobRunningStatus);
    return (
      <div className="flex-row">
        <Popconfirm
          placement="topRight"
          title={<div>{intl.formatMessage(eventMessages.terminateThisJob)}</div>}
          onConfirm={this.handleKillJob(rowData)}
          onCancel={(event) => event.stopPropagation()}
          disabled={disabled}
        >
          <Button size="small" type="primary" onClick={(event) => event.stopPropagation()} disabled={disabled}>
            {intl.formatMessage(eventMessages.killTask)}
          </Button>
        </Popconfirm>
      </div>
    );
  }

  @autobind
  handleKillJob(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      const { intl, credentials } = this.props;
      const { jobStatusKey } = rowData;

      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('jobstatus'), {
        ...credentials,
        jobRunningStatus: 'terminating',
        jobStatusKey: JSON.stringify(jobStatusKey),
      })
        .then((d) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.reloadData(this.props);
        })
        .catch((err) => {
          console.warn('[IF_API] api call failed, ignored', err);
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    };
  }

  @autobind
  sort({ sortBy, sortDirection }) {
    const { taskStatus, sortDirection: prevSortDirection } = this.state;
    this.setState({
      sortBy,
      sortDirection: !prevSortDirection ? 'ASC' : prevSortDirection === 'DESC' ? undefined : sortDirection,
      taskStatus: this.sortData(taskStatus, {
        ...this.state,
        sortBy,
        sortDirection: !prevSortDirection ? 'ASC' : prevSortDirection === 'DESC' ? undefined : sortDirection,
      }),
    });
  }

  @autobind
  headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey || !sortDirection) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { isLoading, dates, startTimeObj, endTimeObj, taskStatus, filterQueue } = this.state;
    const { sortBy, sortDirection } = this.state;
    const timeChange = startTimeObj && endTimeObj && (startTimeObj !== dates[0] || endTimeObj !== dates[1]);

    return (
      <Container className="flex-col" fullHeight>
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <div className="bold" style={{ marginRight: 8 }}>
            {intl.formatMessage(logMessages.timeRange)}:
          </div>
          <TimePicker
            size="small"
            allowClear={false}
            value={startTimeObj}
            onSelect={(timeObj) => this.handleTimeChange('startTime', timeObj)}
            popupClassName="antd-timepicker"
          />
          <div style={{ margin: '0 4px' }}>~</div>
          <TimePicker
            size="small"
            allowClear={false}
            value={endTimeObj}
            onSelect={(timeObj) => this.handleTimeChange('endTime', timeObj)}
            popupClassName="antd-timepicker"
          />
          <Popover
            mouseEnterDelay={0.3}
            visible={timeChange}
            title={null}
            content={timeChange ? intl.formatMessage(appMessages.clickToReload) : null}
          >
            <Button
              type="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={() => {
                this.setState({ dates: [startTimeObj, endTimeObj] }, () => {
                  this.reloadData(this.props);
                });
              }}
            >
              {intl.formatMessage(appButtonsMessages.reload)}
            </Button>
          </Popover>

          <div className="bold" style={{ marginLeft: 16, marginRight: 8 }}>
            {intl.formatMessage(eventMessages.queue)}:
          </div>
          <Select
            allowClear
            showSearch
            size="small"
            style={{ width: 240 }}
            value={filterQueue}
            onChange={this.handleSelectChange}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.queueOptions.map((item) => (
              <Select.Option key={item.value} title={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Container className={`flex-grow ${isLoading ? ' loading' : ''}`}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={50}
                rowCount={taskStatus.length}
                rowGetter={({ index }) => taskStatus[index]}
                ref={(table) => {
                  this.table = table;
                }}
                sort={this.sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
              >
                <Column
                  width={100}
                  label={intl.formatMessage(eventMessages.taskId)}
                  dataKey="jobId"
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={100}
                  label={intl.formatMessage(eventMessages.queue)}
                  dataKey="queueName"
                  flexGrow={1}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={100}
                  label={intl.formatMessage(eventMessages.originator)}
                  dataKey="jobOriginator"
                  flexGrow={1}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={100}
                  label={intl.formatMessage(eventMessages.cronType)}
                  dataKey="cronType"
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={150}
                  label={intl.formatMessage(eventMessages.enqueueTime)}
                  dataKey="registeredTimestamp"
                  cellRenderer={this.timestampRenderer}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={150}
                  label={intl.formatMessage(eventMessages.taskStartTime)}
                  dataKey="jobStartTime"
                  cellRenderer={this.timestampRenderer}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={150}
                  label={intl.formatMessage(eventMessages.taskCompletionDeadline)}
                  dataKey="jobEndTime"
                  cellRenderer={this.timestampRenderer}
                  headerRenderer={this.headerRenderer}
                />
                <Column
                  width={120}
                  label={intl.formatMessage(appFieldsMessages.status)}
                  dataKey="jobRunningStatus"
                  cellRenderer={this.statusRenderer}
                  headerRenderer={this.headerRenderer}
                />
                <Column width={100} label="" cellRenderer={this.controlRenderer} dataKey="queueName" disableSort />
              </Table>
            )}
          </AutoSizer>
        </Container>
      </Container>
    );
  }
}

const TaskStatus = injectIntl(TaskStatusCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { appLoaderVisible } = state.app;
    return { credentials, appLoaderVisible };
  },
  { hideAppLoader, updateLastActionInfo },
)(TaskStatus);
