import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { Button, notification, Spin, Tooltip } from 'antd';
import {
  ApartmentOutlined,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  SearchOutlined,
  LineChartOutlined,
} from '@ant-design/icons';

import { CellMeasurer, CellMeasurerCache, Container, Modal, Popover } from '../../../lib/fui/react';
import { buildLocation, CellRenderers, Defaults, parseLocation, sleep } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import CompareIncidentsNewModal from './CompareIncidentsNewModal';
import {
  buildMergeData,
  dateHeight,
  getIndividualJumpInfo,
  getTypeColor,
  handleCategoryClick,
  handleClickDateRow,
  handleIgnoreClick,
  impactedRender,
  listHeaderHeight,
  positionEvent,
  recurrentIncidentsViewList,
  renderCategory,
  rendererChecked,
  rendererExpand,
  rendererIncidentStatus,
  rowMinHeight,
  statusOptions,
  statusRenderer,
  tableViewList,
  timeRenderer,
  traceRenderer,
} from '../utils/rootCauseTimeLine';
import { RecurrentIncidentsIcon } from '../../../lib/fui/icons';

type Props = {
  intl: Object,
  incident: Object,
  // eslint-disable-next-line
  credentials: Object,
  onClose: Function,
  // eslint-disable-next-line
  projects: Array<Object>,
  location: Object,
  // eslint-disable-next-line
  globalInfo: Object,
  activeKey: String,
  tabName: String,
  currentTheme: String,
  isReadUser: Boolean,
  // eslint-disable-next-line
  isAdmin: Boolean,
  isReadUser: Boolean,
  // eslint-disable-next-line
  userName: String,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  refresh: Function,
  refreshLoading: Boolean,
  summarySettingsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  timezoneOffset: Number,
  controlRenderer: Function,
  // eslint-disable-next-line
  individualIncident: Object,
  rootCauseChainRender: Function,
  fatherInstanceRenderer: Function,
  impactInstanceRenderer: Function,
  rendererContent: Function,
};

class IndividualIncidentsModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = listHeaderHeight;
    this.rowMinHeight = rowMinHeight;
    this.dateHeight = dateHeight;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      fixedHeight: false,
      minHeight: this.dateHeight,
    });

    this.statusColorMap = R.fromPairs(R.map((item) => [item.value, item.color], statusOptions));

    this.state = {
      isLoadingParserData: false,

      allExpand: true,
      dateAllExpand: true,

      sortBy: null,
      sortDirection: null,

      events: [],
      filterList: [],
      eventList: [],
      isAllChecked: false,
      jumpIncident: undefined,

      showCompareIncidentsModal: false,
      compareIncidents: [],
    };

    this.mergeListMap = {};

    this.fatherEvent = null;
    this.childEvent = null;

    this.channelNameMap = {
      Teams: 'Teams channel',
      Slack: 'Slack channel',
    };
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextAnomalyTimelines = get(nextProps.incident, ['anomalyTimelines']);
    const anomalyTimelines = get(this.props.incident, ['anomalyTimelines']);
    if (nextAnomalyTimelines !== anomalyTimelines) {
      this.parseData(nextProps);
    }
  }

  @autobind
  async parseData(props) {
    const { incident, getMappingProjectInfo, getPodAndHostData, location, replace } = props;
    const { sortBy, sortDirection } = this.state;

    const query = parseLocation(location);
    const { eventProjectName, eventPatternId, eventTimestamp } = query;

    this.setState({ isLoadingParserData: true });
    await sleep(300);

    let eventList = get(incident, ['anomalyTimelines'], []);

    let filterEventPodAndHost = R.filter((item) => R.includes('Kubernetes', item.cloudType || ''), eventList);
    filterEventPodAndHost = R.map((item) => {
      if (item.category !== 'metric') {
        const mappingProjectInfo = getMappingProjectInfo({ event: item });
        if (mappingProjectInfo.dataType === 'Metric') {
          const newItem = { ...item, projectName: mappingProjectInfo.projectName || item.projectName };
          return newItem;
        } else {
          return item;
        }
      } else {
        return item;
      }
    }, filterEventPodAndHost || []);
    const groupPodAndHost = R.groupBy((item) => item.projectName, filterEventPodAndHost);
    const podAndHostData = await getPodAndHostData(groupPodAndHost);

    eventList = R.map((event) => {
      const { cloudType, category, instanceListStr, startTimestamp, endTimestamp } = event || {};
      let { projectName } = event || {};
      if (R.includes('Kubernetes', cloudType || '') && category !== 'metric') {
        const { projectName: mappingProjectName } = getMappingProjectInfo({ event });
        projectName = mappingProjectName || projectName;
      }
      const podAndHostTimes = podAndHostData[`${projectName}-${instanceListStr}`];
      let thenPodAndHost =
        R.find(
          (item) =>
            (item[1].s <= startTimestamp && startTimestamp < item[1].e) ||
            (item[1].s <= endTimestamp && endTimestamp < item[1].e),
          podAndHostTimes || [],
        ) || [];
      thenPodAndHost = thenPodAndHost[0]?.h && thenPodAndHost[0]?.p ? thenPodAndHost : [];
      return {
        ...event,
        ...(thenPodAndHost.length > 0
          ? { pod: thenPodAndHost[0].p, host: thenPodAndHost[0].h }
          : { pod: null, host: null }),
      };
    }, eventList || []);

    // anomaly events filter
    R.addIndex(R.forEach)((item, index) => {
      item.sortNum = index + 1;
    }, eventList || []);

    const filterList = this.filterData(props, eventList);
    const mergedList = buildMergeData(filterList, sortBy, sortDirection, this);

    const { jumpFatherIncident, jumpIncident } = getIndividualJumpInfo({
      props,
      mergedList,
      eventProjectName,
      eventPatternId,
      eventTimestamp,
      isJWT: false,
    });

    this.setState({ isLoadingParserData: false, events: eventList, filterList, eventList: mergedList }, () => {
      if (eventProjectName && eventPatternId && eventTimestamp) {
        replace(
          buildLocation(
            location.pathname,
            {},
            {
              ...query,
              redirect: undefined,
              eventProjectName: undefined,
              eventPatternId: undefined,
              eventTimestamp: undefined,
              eventLogInstanceName: undefined,
              eventInstanceName: undefined,
              eventComponentName: undefined,
              eventPatternType: undefined,
              eventRootCauseMetric: undefined,
              eventRootCauseKey: undefined,

              // reset filter params
              hideIgnore: undefined,
            },
          ),
        );
      }

      if (jumpFatherIncident && jumpIncident) {
        this.fatherEvent = jumpFatherIncident;
        this.childEvent = jumpIncident;
        positionEvent({ self: this });
      } else {
        this.cellMeasureCache.clearAll();
        if (this.refList) this.refList.forceUpdateGrid();
        this.forceUpdate();
      }
    });
  }

  @autobind
  filterData(props, eventList) {
    const filterList = eventList || [];

    this.cellMeasureCache.clearAll();
    if (this.refList) this.refList.forceUpdateGrid();
    this.forceUpdate();

    return filterList;
  }

  @autobind
  peersListItem(tabName, events, jumpIncident) {
    return ({ key, index: rowIndex, style, parent, columnIndex, ...rest }) => {
      const { eventList } = this.state;
      const rowData = events[rowIndex];
      if (!rowData) return null;
      const { intl, activeKey, currentTheme, isReadUser, controlRenderer, rootCauseChainRender, handleLineChartJump } =
        this.props;
      const { fatherInstanceRenderer, impactInstanceRenderer } = this.props;
      const { rendererContent, location } = this.props;
      const { systemId } = parseLocation(location);
      const { isImportant, hasImportant, id, isRootEvent, hasRootCause, mergeKey, isCompositeAnomaly } = rowData;
      const { isAllCompositeAnomaly, isIgnored, isTimeRoot, startTimestamp, recurringIncidentTimes, children } =
        rowData;

      let showChartIconChildrens = [];
      if (!!children && children.length > 0) {
        showChartIconChildrens = R.filter((e) => !!e.instanceListStr, children);
      }
      let enableChartIconChildrens = [];
      if (!!showChartIconChildrens && showChartIconChildrens.length > 0) {
        enableChartIconChildrens = R.filter((e) => !!e.hasMetricProject, showChartIconChildrens);
      }
      const active = jumpIncident && jumpIncident.id === id;

      const dateData = R.find(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(eventList);
      const dateIdx = R.findIndex(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(eventList);
      const rootEvent = R.find(R.propEq('mergeKey', mergeKey))(dateData?.children || []);
      const rootEventIdx = R.findIndex(R.propEq('mergeKey', mergeKey))(dateData?.children || []);

      return (
        <CellMeasurer
          key={key}
          cache={this.cellMeasureCache}
          parent={parent}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
        >
          <div style={{ ...style }}>
            {isTimeRoot && (
              <div
                className="event-list-category-row clickable"
                style={{ height: this.dateHeight - 1, background: currentTheme === 'dark' ? '#4b4a4a' : '#d0d0d0' }}
                onClick={() => handleClickDateRow(dateIdx, rowData, this)}
              >
                <div className="row-column flex-center-justify" style={{ minWidth: 56 }} />
                <div
                  className="row-column font-14 bold"
                  style={{ minWidth: 70 + 180 + 210, maxWidth: 70 + 180 + 210, paddingRight: 16 }}
                >
                  {rowData.dateEnFormat}
                </div>
                <div className="row-column flex-grow" />
                <div className="row-column text-center" style={{ minWidth: 130, maxWidth: 130 }}>
                  <span className="full-width">{rowData.count}</span>
                </div>
                <div className="row-column" style={{ width: 30 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
                <div className="row-column flex-center-justify" style={{ width: 60 }} />
              </div>
            )}
            {!isTimeRoot && isRootEvent && (
              <div
                className="event-list-category-row clickable"
                style={{
                  height: this.rowMinHeight - 1,
                  backgroundColor: 'var(--item-active-bg2)',
                }}
                onClick={() => handleCategoryClick({ dateIdx, rootEventIdx, rowData, self: this })}
              >
                <div className="row-column flex-center-justify" style={{ minWidth: 56 }} />
                <div className="row-column" style={{ minWidth: 70, maxWidth: 70, paddingRight: 16 }} />
                <div className="row-column" style={{ minWidth: 180, maxWidth: 180, paddingRight: 16 }}>
                  {fatherInstanceRenderer(rowData, rootEventIdx, dateIdx, (dateIdx, rootEventIdx, rowData) =>
                    handleCategoryClick({ dateIdx, rootEventIdx, rowData, self: this }),
                  )}
                </div>
                <div className="row-column" style={{ minWidth: 210, maxWidth: 210, paddingRight: 16 }}>
                  {showChartIconChildrens.length > 0 && handleLineChartJump && (
                    <Tooltip title="Metric anomalies" mouseEnterDelay={0.3} placement="top">
                      <Button
                        size="small"
                        disabled={enableChartIconChildrens.length === 0}
                        icon={<LineChartOutlined style={{ fontSize: 16 }} />}
                        style={{
                          border: 'none',
                          backgroundColor: 'rgba(0,0,0,0)',
                          marginRight: 4,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (activeKey === 'incident') {
                            handleLineChartJump({ events: enableChartIconChildrens });
                          }
                        }}
                      />
                    </Tooltip>
                  )}
                  {impactedRender(rowData, this)}
                </div>
                <div className="row-column" style={{ flex: 1, minWidth: 140 }} />
                <div className="row-column" style={{ minWidth: 130, maxWidth: 130, paddingRight: 16 }}>
                  {hasRootCause && (
                    <SearchOutlined style={{ fontSize: 16, width: '100%', color: 'var(--primary-color)' }} />
                  )}
                </div>
                <div className="row-column" style={{ minWidth: 90, maxWidth: 90 }} />
                {activeKey === 'trace' && <div className="row-column" style={{ minWidth: 40 }} />}
                {activeKey !== 'incident' && <div className="row-column" style={{ minWidth: 65 }} />}
                <div className="row-column text-center" style={{ minWidth: 70, maxWidth: 70 }} />
                {tabName === 'incident' && <div className="row-column" style={{ minWidth: 110, maxWidth: 110 }} />}
                <div className="row-column text-center" style={{ minWidth: 130, maxWidth: 130 }}>
                  <span className="full-width">{rowData.count}</span>
                </div>
                <div className="row-column" style={{ width: 30 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
                <div className="row-column flex-center-justify" style={{ width: 60 }}>
                  {hasImportant ? (
                    <ExclamationCircleFilled
                      className={`${hasImportant ? 'isImportantColor' : 'hover-color'}`}
                      style={{ color: currentTheme === 'dark' ? '#e6e6e6' : '#cacaca', fontSize: 16 }}
                    />
                  ) : (
                    <div style={{ width: 16 }} />
                  )}
                  {!isReadUser && !isAllCompositeAnomaly && (
                    <Popover
                      title={null}
                      content={
                        isReadUser
                          ? intl.formatMessage(eventMessages.isReadUserDisable)
                          : isIgnored
                          ? intl.formatMessage(eventMessages.noIgnorePattern)
                          : intl.formatMessage(eventMessages.ignorePattern)
                      }
                      mouseEnterDelay={0.3}
                    >
                      {isIgnored ? (
                        <EyeInvisibleOutlined
                          className="hover-color"
                          style={{
                            color: currentTheme === 'dark' ? '#e6e6e6' : '#949393',
                            fontSize: 14,
                            marginLeft: 8,
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (!isReadUser)
                              handleIgnoreClick({
                                event: rowData,
                                category: 'ignore',
                                rowIndex: rootEventIdx,
                                dateIdx,
                                self: this,
                              });
                          }}
                        />
                      ) : (
                        <EyeOutlined
                          className="hover-color"
                          style={{
                            color: currentTheme === 'dark' ? '#e6e6e6' : '#949393',
                            fontSize: 14,
                            marginLeft: 8,
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (!isReadUser)
                              handleIgnoreClick({
                                event: rowData,
                                category: 'ignore',
                                rowIndex: rootEventIdx,
                                dateIdx,
                                self: this,
                              });
                          }}
                        />
                      )}
                    </Popover>
                  )}
                </div>
              </div>
            )}
            {!isTimeRoot && !isRootEvent && (
              <div className="full-width">
                <div className="flex-grow flex-min-width event-list">
                  <div
                    className={`incident-event-list-row${active ? ' active' : ''}`}
                    onClick={() => this.setState({ jumpIncident: rowData })}
                    style={{
                      borderBottom: '1px solid var(--virtualized-table-border-color)',
                    }}
                  >
                    <div className="row-column flex-center-justify" style={{ minWidth: 56 }}>
                      {isCompositeAnomaly && (
                        <Popover
                          placement="right"
                          title={null}
                          content={intl.formatMessage(eventMessages.compositeIncident)}
                          trigger="hover"
                        >
                          <ApartmentOutlined style={{ fontSize: 14, marginRight: 8, color: 'var(--primary-color)' }} />
                        </Popover>
                      )}
                      {recurringIncidentTimes.length > 0 && (
                        <Popover
                          placement="right"
                          title={intl.formatMessage(eventMessages.recurrentIncidents)}
                          content={recurrentIncidentsViewList(rowData, intl, systemId, false, this)}
                          trigger="hover"
                        >
                          <RecurrentIncidentsIcon style={{ fontSize: 17, color: 'var(--primary-color)' }} />
                        </Popover>
                      )}
                    </div>
                    <div className="row-column" style={{ minWidth: 70, maxWidth: 70, paddingRight: 16 }}>
                      {timeRenderer(rowData)}
                    </div>
                    <div className="row-column" style={{ minWidth: 180, maxWidth: 180, paddingRight: 16 }} />
                    <div className="row-column" style={{ minWidth: 210, maxWidth: 210, paddingRight: 16 }}>
                      {impactInstanceRenderer(rowData)}
                    </div>
                    <div
                      className="row-column"
                      style={{ flex: 1, paddingRight: 10, minWidth: 140, minHeight: this.rowMinHeight }}
                    >
                      {rendererContent(rowData, false)}
                    </div>
                    <div className="row-column" style={{ minWidth: 130, maxWidth: 130, paddingRight: 16 }}>
                      {rootCauseChainRender({ rowData, onlyCloseModal: true })}
                    </div>
                    <div className="row-column" style={{ minWidth: 90, maxWidth: 90 }}>
                      <Popover
                        content={`Duration: ${CellRenderers.humanizeDuration({
                          period: rowData.duration,
                          intl,
                          showSeconds: true,
                          showZero: true,
                          showZeroToOne: true,
                        })}`}
                        mouseEnterDelay={0.3}
                      >
                        {CellRenderers.humanizeDuration({
                          period: rowData.duration,
                          intl,
                          showSeconds: true,
                          showZero: true,
                          shortDisplay: true,
                          showZeroToOne: true,
                        })}
                      </Popover>
                    </div>
                    {activeKey === 'trace' && (
                      <div className="row-column flex-center-justify flex-wrap" style={{ minWidth: 40 }}>
                        {traceRenderer(rowData)}
                      </div>
                    )}
                    {activeKey !== 'incident' && (
                      <div className="row-column flex-center-justify flex-wrap" style={{ minWidth: 65 }}>
                        {renderCategory(rowData, this)}
                      </div>
                    )}
                    <div className="row-column flex-wrap" style={{ minWidth: 70, maxWidth: 70 }}>
                      {statusRenderer(rowData, this)}
                    </div>
                    {tabName === 'incident' && (
                      <>
                        <div className="row-column" style={{ minWidth: 110, maxWidth: 110 }}>
                          {rendererIncidentStatus(rowData, rowIndex, rootEvent, rootEventIdx, dateIdx, this)}
                        </div>
                      </>
                    )}
                    <div
                      className="row-column"
                      style={{ minWidth: 130 + 30, maxWidth: 130 + 30, justifyContent: 'center' }}
                    >
                      {controlRenderer(rowData, rowIndex, rootEvent, tabName, dateIdx)}
                    </div>
                    <div className="row-column flex-center-justify" style={{ minWidth: 60, height: 39 }}>
                      <Popover
                        title={null}
                        content={
                          isReadUser
                            ? intl.formatMessage(eventMessages.isReadUserDisable)
                            : intl.formatMessage(
                                isImportant ? eventMessages.removeImportantFlag : eventMessages.markAsImportant,
                              )
                        }
                        mouseEnterDelay={0.3}
                        placement="right"
                      >
                        <ExclamationCircleFilled
                          className={`${isImportant ? 'isImportantColor' : 'hover-color'}`}
                          style={{ color: currentTheme === 'dark' ? '#e6e6e6' : '#cacaca', fontSize: 16 }}
                          onClick={(event) => {
                            if (!isReadUser)
                              handleIgnoreClick({
                                event: rowData,
                                category: 'important',
                                rowIndex: rootEventIdx,
                                dateIdx,
                                rootEvent,
                                self: this,
                              });
                          }}
                        />
                      </Popover>
                      {tabName === 'incident' ? (
                        <div style={{ marginLeft: 8 }}>
                          {!isCompositeAnomaly ? (
                            rendererChecked(rowIndex, rowData, rootEvent, rootEventIdx, dateIdx, this)
                          ) : (
                            <div style={{ width: 16 }} />
                          )}
                        </div>
                      ) : (
                        <div style={{ marginLeft: 8 }}>
                          <div style={{ width: 14, height: 14, backgroundColor: getTypeColor(rowData.typeList) }} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleCompareIncidentsClick() {
    const { eventList } = this.state;
    const compareIncidents = R.unnest(
      R.unnest(
        R.map(
          (event) =>
            R.map(
              (item) =>
                R.sortWith([R.ascend(R.prop('startTimestamp'))])(
                  R.filter((citem) => citem.isChecked, item.children || []),
                ),
              event.children,
            ),
          eventList || [],
        ),
      ),
    );

    this.setState({ showCompareIncidentsModal: true, compareIncidents });
  }

  render() {
    const { onClose, intl, tabName, refreshLoading, location } = this.props;
    const { eventList, isLoadingParserData } = this.state;

    const { environmentId, systemId } = parseLocation(location);

    const hasErrCompareIncidents = R.all(R.equals(true))(
      R.map((event) => {
        return R.all(R.equals(-1))(
          R.map(
            (item) => R.findIndex((citem) => citem.isChecked && !citem.isCompositeAnomaly, item.children || []),
            event.children || [],
          ),
        );
      }, eventList || []),
    );

    return (
      <Modal
        title={intl.formatMessage(eventMessages.individualIncidents)}
        width={1400}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        bodyStyle={{ height: 600 }}
        footer={null}
      >
        <Container className="flex-grow flex-min-height flex-col full-height">
          <Spin
            spinning={isLoadingParserData || refreshLoading}
            wrapperClassName="full-width full-height spin-full-width"
          >
            <div className="full-width full-height flex-row">
              <div className="full-width full-height flex-col">
                <div className="flex-row flex-center-align" style={{ marginLeft: 8, marginBottom: 8 }}>
                  {tabName === 'incident' && (
                    <Button
                      size="small"
                      type="primary"
                      onClick={this.handleCompareIncidentsClick}
                      style={{ margin: '8px 16px 0 0' }}
                      disabled={hasErrCompareIncidents}
                    >
                      {intl.formatMessage(logMessages.compareIncidents)}
                    </Button>
                  )}
                </div>
                {/* 布局在peersListItem */}
                {tableViewList(false, this, intl, this.peersListItem)}
              </div>
            </div>
          </Spin>
        </Container>

        {this.state.showCompareIncidentsModal && (
          <CompareIncidentsNewModal
            environmentId={environmentId}
            systemId={systemId}
            summarySettingsMap={this.props.summarySettingsMap}
            compareIncidents={this.state.compareIncidents}
            onClose={() => this.setState({ showCompareIncidentsModal: false, compareIncidents: [] })}
          />
        )}
      </Modal>
    );
  }
}

const IndividualIncidentsModal = injectIntl(IndividualIncidentsModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, projects, systemsMap, globalInfo, currentTheme, timezoneOffset } = state.app;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isReadUser, isLocalAdmin, userName } = userInfo;
    return {
      location,
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,
      globalInfo,
      currentTheme,
      isReadUser,
      isAdmin,
      isLocalAdmin,
      userName,
      timezoneOffset,
    };
  },
  { updateLastActionInfo, replace },
)(IndividualIncidentsModal);
