import React, { useReducer, useState } from 'react';
import { Breadcrumb, Button, DatePicker, Input, Tooltip, message, notification, Popconfirm } from 'antd';
import * as R from 'ramda';
import moment from 'moment';
import { ReloadOutlined, FormOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Container, Popover } from '../../../lib/fui/react';
import { appButtonsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { Defaults, buildLocation, buildUrl, parseLocation, timeScopeControl } from '../../../common/utils';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import SelectApplyInfoModal from './SelectApplyInfoModal';
import fetchPostJson from '../../../common/apis/fetchPostJson';
import BaseUrls from '../../app/BaseUrls';
import { eventMessages } from '../../../common/metric/messages';
import { PDFIcon } from '../../../lib/fui/icons';

const SystemHeader = (props: Object) => {
  const {
    isDesignMode,
    credentials,
    onChangeMode,
    intl,
    location,
    replace,
    startTime,
    endTime,
    userInfo,
    onShowWidget,
    onApplyOtherLayout,
    isCreate,
    dashBoardName,
    customerName,
    systemId,
    editable,
    push,
    fromSystemOptions,
    onExportPDF,
  } = props;
  const [showSystemSelection, setShowSystemSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDashNameInput, setShowDashNameInput] = useState(false);
  const [dashName, setDashName] = useState(dashBoardName);
  const [oldDashName, setOldDashName] = useState(dashBoardName);

  const [state, setState] = useReducer((oldVla, newVal) => ({ ...oldVla, ...newVal }), {
    showApply: false,
    endTimeOpen: false,
    timeChange: false,
    disableRefresh: false,
    tooltipVisibleReload: false,
    tooltipVisibleReloadMouseOver: false,
    startTimeObj: moment.utc(startTime, Defaults.DateFormat),
    endTimeObj: moment.utc(endTime, Defaults.DateFormat),
    startTimeObjValueOf: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
    endTimeObjValueOf: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
  });

  const {
    showApply,
    startTimeObj,
    endTimeObj,
    endTimeOpen,
    timeChange,
    disableRefresh,
    tooltipVisibleReload,
    tooltipVisibleReloadMouseOver,
  } = state;

  const handleStartTimeChange = (timeObj) => {
    const startTimeObj = moment.utc(timeObj.valueOf());
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    disableRefresh = false;
    setState({
      startTimeObj,
      endTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'add'),
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
    });
    if (tooltipVisibleReload) setTimeout(() => setState({ tooltipVisibleReload: false }), 2000);
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      setState({ endTimeOpen: true });
    }
  };

  const handleEndTimeChange = (timeObj) => {
    const endTimeObj = moment.utc(timeObj.valueOf());
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    disableRefresh = false;
    setState({
      startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
      endTimeObj,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
    });
    if (tooltipVisibleReload) setTimeout(() => setState({ tooltipVisibleReload: false }), 2000);
  };

  const handleEndOpenChange = (open) => {
    setState({ endTimeOpen: open });
  };

  const handleRefreshClick = () => {
    sessionStorage.removeItem('dashboardApiMap');
    let query = parseLocation(location);
    if (query?.saveFlag === 'true') {
      message.warning(intl.formatMessage(eventMessages.notSaveDashboardMessage));
      return;
    }

    const startTime = startTimeObj.format(Defaults.DateFormat);
    const endTime = endTimeObj.format(Defaults.DateFormat);
    // update start/end time if changed
    if (startTime !== query.startTime || endTime !== query.endTime) {
      query = { ...query, startTime, endTime };
    }
    // force refresh
    replace(buildLocation(location.pathname, {}, { ...query }));
  };

  const onClose = () => {
    setShowSystemSelection(false);
  };

  const handleApply = async (systemId, targetCustomerName, dashboardName, adminSystemsMap, fromSystemId) => {
    const {
      startTime,
      endTime,
      systemsMap: ordinarySystemsMap,
      systemStatisticsMap,
      systemId: currentSystemId,
    } = props;
    const { isCloudCost } = systemStatisticsMap[fromSystemId];
    const { isAdmin, isLocalAdmin } = props;

    const systemsMap = !isAdmin && !isLocalAdmin ? ordinarySystemsMap : adminSystemsMap;

    const startTimeObjValueOf = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimeObjValueOf = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    if (!systemsMap[systemId]) {
      return;
    }
    const { owner, systemName } = systemsMap[systemId] || {};

    setIsLoading(true);
    const statistics = await fetchGet(getEndpoint('dashboard-statistics'), {
      ...credentials,
      customerName: owner,
      systemName: systemId,
      startTime: startTimeObjValueOf,
      endTime: endTimeObjValueOf,
    })
      .then((data) => {
        setIsLoading(false);

        const { allCost, avgCost } = data || {};
        return {
          targetIsCloudCost: (avgCost || avgCost === 0) && allCost,
          hasCloudCost: (avgCost || avgCost === 0) && allCost === false,
        };
      })
      .catch((e) => {
        console.error(e.message || String(e));
        setIsLoading(false);
        return {};
      });
    const { targetIsCloudCost } = statistics;
    const { systemName: currentSystemName, name } = ordinarySystemsMap[currentSystemId] || {};

    if (targetIsCloudCost === isCloudCost) {
      await onApplyOtherLayout(systemId, targetCustomerName, dashboardName, systemsMap, fromSystemId);
      onClose();
      notification.success({
        duration: 3,
        message: intl.formatMessage(appMessages.apiSuccess),
        description: (
          <div>
            Currently
            <span style={{ margin: '0 4px', fontWeight: 'bold', color: 'var(--blue)' }}>
              {currentSystemName || name}
            </span>
            has been synchronized to
            <span style={{ margin: '0 4px', fontWeight: 'bold', color: 'var(--blue)' }}>{systemName}.</span>
          </div>
        ),
      });
    } else {
      notification.error({
        duration: null,
        message: 'The project type does not match the target system',
        description: (
          <div>
            Currently
            <span style={{ margin: '0 4px', fontWeight: 'bold', color: 'var(--blue)' }}>
              {currentSystemName || name}
            </span>
            and the target system
            <span style={{ margin: '0 4px', fontWeight: 'bold', color: 'var(--blue)' }}>{systemName}</span>are not of
            the same type.
          </div>
        ),
      });
    }
  };

  const handleUpdateDashName = () => {
    if (!dashName) return;
    const query = parseLocation(location);
    const { systemsMap } = props;
    const { owner } = systemsMap[systemId];

    fetchPostJson(
      getEndpoint(`dashboardmanagement`),
      {
        ...credentials,
        targetUser: customerName || userInfo.userName,
      },
      [
        {
          systemName: systemId,
          dashboardName: dashName || '',
          oldDashboardName: oldDashName,
          customerName: owner || customerName || userInfo.userName,
        },
      ],
    )
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        setState({ loading: false });
        setShowDashNameInput(false);
        setOldDashName(dashName);
        replace(buildLocation(location.pathname, {}, { ...query, dashBoardName: dashName }));
      })
      .catch((err) => {
        message.error(String(err));
        setState({ loading: false });
      });
  };

  const systemListView = [
    ...R.filter((option) => option.value === systemId, fromSystemOptions || []),
    ...R.filter((option) => option.value !== systemId && !!option.hasPermission, fromSystemOptions || []),
    ...R.filter((option) => option.value !== systemId && !option.hasPermission, fromSystemOptions || []),
  ];

  return (
    <Container breadcrumb className="flex-row header-conatiner" style={{ marginBottom: 0 }}>
      <div className="flex-grow flex-row flex-center-align">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a
              style={{ color: 'var(--link-color)', fontSize: 14 }}
              onClick={() => {
                const query = parseLocation(location);
                if (query?.saveFlag === 'true') {
                  message.warning(intl.formatMessage(eventMessages.notSaveDashboardMessage));
                  return;
                }
                if (query.customerName) {
                  query.customerName = sessionStorage.getItem('dashboardCustomerName');
                }

                push(buildUrl(BaseUrls.GlobalDashboard, {}, { ...query }));
              }}
            >
              {intl.formatMessage(appMenusMessages.globalSystemInsights)}
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <div style={{ position: 'relative' }} className="flex-row flex-center-align">
              {!showDashNameInput && (
                <div style={{ lineHeight: 1, fontSize: 14 }}>
                  {oldDashName}{' '}
                  {!userInfo.isReadUser && (
                    <span style={{ marginLeft: 4 }} onClick={(e) => setShowDashNameInput(true)}>
                      <FormOutlined style={{ fontSize: 14 }} className="clickable" />
                    </span>
                  )}
                </div>
              )}
              {showDashNameInput && (
                <div className="flex-row flex-center-align">
                  <Input
                    style={{ display: 'inline-block', width: 200 }}
                    value={dashName}
                    onChange={(e) => {
                      setDashName(e.target.value);
                    }}
                  />
                  <span onClick={(e) => handleUpdateDashName()}>
                    <CheckCircleOutlined
                      style={{
                        cursor: 'pointer',
                        color: `${dashName ? 'var(--green)' : 'var(--disabled-color)'}`,
                        fontSize: 18,
                        marginLeft: 10,
                      }}
                    />
                  </span>
                  <span
                    onClick={(e) => {
                      setDashName(oldDashName);
                      setShowDashNameInput(false);
                    }}
                  >
                    <CloseCircleOutlined
                      style={{
                        cursor: 'pointer',
                        fontSize: 18,
                        marginLeft: 10,
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="group" style={{ marginLeft: 30, minWidth: 120 }}>
          <div className="group-title">Start date</div>
          <div className="group-content">
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={startTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={handleStartTimeChange}
              onOpenChange={handleStartOpenChange}
            />
          </div>
        </div>

        <div className="group" style={{ minWidth: 120 }}>
          <div className="group-title">End date</div>
          <div className="group-content">
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={endTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={handleEndTimeChange}
              open={endTimeOpen}
              onOpenChange={handleEndOpenChange}
            />
          </div>
        </div>
        <div className="group" style={{ marginLeft: 30, width: 120, flexShrink: 0 }}>
          <div className="group-title">Selected data range</div>
          <div className="group-content" style={{ fontSize: 16, fontWeight: 700 }}>
            {endTimeObj.diff(startTimeObj, 'days') + 1} Day
          </div>
        </div>
        <div className="flex-row flex-center-align" style={{ flexShrink: 0, marginTop: 18 }}>
          {!isDesignMode && !userInfo.isReadUser && (
            <div style={{ position: 'relative', marginLeft: 20 }}>
              {!isCreate && (
                <Button
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSystemSelection(true);
                  }}
                  loading={isLoading}
                >
                  Copy this dashboard
                </Button>
              )}
              {isCreate && showApply && (
                <Button
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSystemSelection(true);
                  }}
                  loading={isLoading}
                >
                  Copy this dashboard
                </Button>
              )}
            </div>
          )}
          <div style={{ marginLeft: 10 }}>
            {editable && !userInfo.isReadUser && (
              <>
                {!isDesignMode && (
                  <Button size="small" style={{ fontSize: 14, lineHeight: '14px' }} onClick={() => onChangeMode(true)}>
                    {intl.formatMessage(appButtonsMessages.edit)}
                  </Button>
                )}
                {isDesignMode && (
                  <>
                    <Popconfirm
                      placement="bottom"
                      title="Are you sure you would like to cancel?"
                      okText={intl.formatMessage(appButtonsMessages.yes)}
                      cancelText={intl.formatMessage(appButtonsMessages.no)}
                      onConfirm={() => onChangeMode(false)}
                      onCancel={(event) => event.stopPropagation()}
                    >
                      <Button size="small" style={{ fontSize: 14, lineHeight: '14px' }}>
                        {intl.formatMessage(appButtonsMessages.cancel)}
                      </Button>
                    </Popconfirm>

                    <Button
                      size="small"
                      style={{ marginLeft: 10, fontSize: 14, lineHeight: '14px' }}
                      onClick={() => onShowWidget(true)}
                      type="primary"
                    >
                      Open widgets
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setState({ showApply: true });
                        onChangeMode(true, dashName);
                      }}
                      style={{ marginLeft: 10, fontSize: 14, lineHeight: '14px' }}
                    >
                      {intl.formatMessage(appButtonsMessages.save)}
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
          {!isDesignMode && (
            <div style={{ marginLeft: 8 }}>
              <Button.Group style={{ marginRight: 8 }}>
                <Tooltip title="Export PDF" placement="topRight">
                  <Button
                    size="small"
                    icon={<PDFIcon style={{ color: 'white', fontSize: 18 }} />}
                    onClick={() => onExportPDF()}
                    Tooltip="Export PDF"
                  />
                </Tooltip>
              </Button.Group>
            </div>
          )}
        </div>
        <div className="flex-row group" style={{ marginLeft: 30, flexShrink: 0, minWidth: 440, maxWidth: 440 }}>
          <div className="group-title">System list</div>
          <div className="group-content full-width">
            <Popover
              content={R.map((option) => {
                return (
                  <div style={{ color: option.hasPermission ? 'var(--text-color)' : 'gray' }}>
                    {option.label}
                    {!option.hasPermission ? ' (No permission) ' : ''}
                    {option.value === systemId ? ' (Default) ' : ''}
                  </div>
                );
              }, systemListView)}
              placement="leftBottom"
            >
              <div className="flex-row flex-center-align full-width">
                <div className="font-12 bold hidden-line-with-ellipsis clickable">
                  {R.addIndex(R.map)((option, index) => {
                    return (
                      <span style={{ color: option.hasPermission ? 'var(--text-color)' : 'gray' }}>
                        {option.label}
                        {option.value === systemId ? ' (Default) ' : ''}
                        {!option.hasPermission ? ' (No permission) ' : ''}
                        {index !== fromSystemOptions.length - 1 ? ', ' : ''}
                      </span>
                    );
                  }, systemListView)}
                </div>
                {systemListView.length > 1 && (
                  <div className="clickable bold" style={{ marginLeft: 5, color: 'var(--blue)', flexShrink: 0 }}>
                    more
                  </div>
                )}
              </div>
            </Popover>
          </div>
        </div>
      </div>
      <div className="flex-row flex-center-align">
        <div
          className="header-operation"
          onMouseEnter={() => {
            if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
              setState({ tooltipVisibleReloadMouseOver: true });
          }}
          onMouseLeave={() => {
            if (tooltipVisibleReloadMouseOver) setState({ tooltipVisibleReloadMouseOver: false });
          }}
        >
          <Tooltip
            mouseEnterDelay={0.3}
            placement="bottomRight"
            visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
            title={
              disableRefresh ? 'Range of days <= 31' : timeChange ? intl.formatMessage(appMessages.clickToReload) : null
            }
          >
            <Button
              icon={<ReloadOutlined />}
              size="small"
              disabled={disableRefresh}
              onClick={handleRefreshClick}
              style={{ marginLeft: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </Tooltip>
        </div>
      </div>
      {showSystemSelection && <SelectApplyInfoModal {...props} onClose={onClose} onApply={handleApply} />}
    </Container>
  );
};

export default SystemHeader;
