import React from 'react';
import cx from 'classnames';
import { autobind } from 'core-decorators';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

type Props = {
  type: string,
  valueLink: Object,
  disableErrorHint: boolean,
  className: string,
  fullWidth: boolean,
  icon: string,
  iconSearch: boolean,
  iconSearchClick: Function,
  autoComplete: String,
  onFocus: Function,
  onBlur: Function,
  initVerify: Boolean,
};

class Input extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
      inputType: 'password',
    };
  }

  @autobind
  handleBlur() {
    if (this.props.onBlur) this.props.onBlur();
    this.setState({ blurred: true });
  }

  @autobind
  handleFocus() {
    if (this.props.onFocus) this.props.onFocus();
    this.setState({ blurred: false });
  }

  render() {
    const {
      type,
      valueLink,
      disableErrorHint,
      icon,
      fullWidth,
      className,
      iconSearch,
      iconSearchClick,
      autoComplete,
      initVerify,
      ...rest
    } = this.props;
    const { blurred, inputType } = this.state;
    const hasError = Boolean(valueLink.error);
    const showErrorHints = (initVerify && hasError && valueLink.value) || (blurred && hasError && !disableErrorHint);

    // Only change the validation styles when blur.
    const classes = cx(
      'ui',
      {
        invalid: showErrorHints,
        icon: !!icon && type !== 'password',
        'full-width': fullWidth,
      },
      className,
      'input',
    );
    const iconProp = iconSearch
      ? { onClick: iconSearchClick, style: { cursor: 'pointer', pointerEvents: 'auto' } }
      : {};
    return (
      <>
        {type === 'password' && (
          <div className={classes}>
            <input
              {...rest}
              type={inputType}
              value={valueLink.value}
              onChange={valueLink.action((x, e) => e.target.value)}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              autoComplete={autoComplete}
              className="corner-6"
              style={{ paddingRight: 24 }}
            />
            {inputType === 'password' && (
              <EyeInvisibleOutlined
                className="clickable full-height flex-row flex-center-align"
                style={{ position: 'absolute', top: 0, right: 0, marginRight: 6, color: '#8c8c8c' }}
                onClick={() => this.setState({ inputType: 'text' })}
              />
            )}
            {inputType === 'text' && (
              <EyeOutlined
                className="clickable full-height flex-row flex-center-align"
                style={{ position: 'absolute', top: 0, right: 0, marginRight: 6, color: '#8c8c8c' }}
                onClick={() => this.setState({ inputType: 'password' })}
              />
            )}
            {showErrorHints && <div className="error-text">{valueLink.error}</div>}
          </div>
        )}
        {type !== 'password' && (
          <div className={classes}>
            <input
              {...rest}
              type={type}
              value={valueLink.value}
              onChange={valueLink.action((x, e) => e.target.value)}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              autoComplete={autoComplete}
              className="corner-6"
            />
            {icon && <i className={icon} {...iconProp} />}
            {showErrorHints && <div className="error-text">{valueLink.error}</div>}
          </div>
        )}
      </>
    );
  }
}

export default Input;
