/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { Form, Button, message, Popconfirm, Spin, Select, Input, Checkbox } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { ActionTypes, createSetAction, updateLastActionInfo } from '../../../../common/app/actions';
import { State } from '../../../../common/types';
import { AutoSizer, Container, Modal, Popover } from '../../../../lib/fui/react';

import { appMessages, appFieldsMessages, appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { eventMessages } from '../../../../common/metric/messages';

type Props = {
  // eslint-disable-next-line
  activeSystemTab: String,
  systemId: String,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  updateLastActionInfo: Function,
  createSetAction: Function,
};

class SystemShareSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { systemsMap, systemId } = props;
    const systemInfo = systemsMap[systemId] || {};

    this.state = {
      isLoading: false,
      systemInfo,

      isSubmittingShareUser: false,
      showAddShareUserModal: false,
      shareUserList: [],
      userNameValue: '',

      isCheckedAll: false,
    };
    this.localShareUserList = [];
  }

  componentDidMount() {
    this.parseSharedUserData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.systemId !== this.props.systemId || nextProps.systemsMap !== this.props.systemsMap) {
      const systemInfo = nextProps.systemsMap[nextProps.systemId] || {};
      this.setState({ userNameValue: '', systemInfo }, () => {
        this.parseSharedUserData(nextProps);
      });
    }
  }

  @autobind
  handleRefreshClick() {
    window.location.reload();
  }

  @autobind
  parseSharedUserData(props) {
    const { systemsMap, systemId } = props;
    const { sortBy, sortDirection } = this.state;
    const systemInfo = systemsMap[systemId] || {};
    const localShareUserList = R.map((user) => {
      return { userName: user, checked: false };
    }, get(systemInfo, 'shareUserSet', []));
    const shareUserList = this.sortData(localShareUserList, sortBy, sortDirection);
    this.localShareUserList = shareUserList;
    this.setState({ shareUserList }, () => {
      this.getCheckedAll();
      this.forceUpdate();
    });
  }

  @autobind
  handleAddShareUserClose(reload) {
    this.setState({ showAddShareUserModal: false });
  }

  @autobind
  handleRemoveShareUserClick(userNames) {
    const { intl, credentials, systemsMap, systemId, createSetAction } = this.props;
    const { systemInfo } = this.state;
    const { shareUserSet } = systemInfo || {};
    const newShareUserSet = R.difference(shareUserSet || [], userNames || []);
    const newSystemsMap = {
      ...systemsMap,
      [systemId]: { ...(systemsMap[systemId] || {}), shareUserSet: newShareUserSet || [] },
    };
    this.setState({ isSubmittingShareUser: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('systemframework', 2), {
      ...credentials,
      operation: 'removeShareSystem',
      systemKey: JSON.stringify({
        userName: systemInfo.owner,
        systemName: systemInfo.systemId,
        environmentName: systemInfo.environmentName,
      }),
      removeFrom: JSON.stringify(userNames),
    })
      .then((data) => {
        setTimeout(() => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          createSetAction(ActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { systemsMap: newSystemsMap });
          this.setState({ isSubmittingShareUser: false });
        }, 1000);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmittingShareUser: false });
      });
  }

  @autobind
  getCheckedAll() {
    const { shareUserList } = this.state;
    const isCheckedAll = !R.find((item) => !item.checked, shareUserList || []);
    this.setState({ isCheckedAll });
  }

  @autobind
  handleCheck(checked, index) {
    const { shareUserList } = this.state;
    shareUserList[index].checked = checked;
    this.setState({ shareUserList }, () => {
      this.getCheckedAll();
    });
    this.forceUpdate();
  }

  @autobind
  renderShareUser(rowData, index) {
    const { intl, userInfo } = this.props;
    const { userName, checked } = rowData;

    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 60 }}>
          <Checkbox checked={checked} onChange={(e) => this.handleCheck(e.target.checked, index)} />
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {userName}
        </div>
        <div className="row-column" style={{ width: 110 }}>
          <Popover
            content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
            mouseEnterDelay={0.3}
            placement="left"
          >
            <Popconfirm
              placement="topRight"
              title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
              okText={intl.formatMessage(appButtonsMessages.yes)}
              cancelText={intl.formatMessage(appButtonsMessages.no)}
              onConfirm={() => this.handleRemoveShareUserClick([userName])}
              onCancel={(event) => event.stopPropagation()}
              disabled={userInfo.isReadUser}
            >
              <Button size="small" onClick={(event) => event.stopPropagation()} disabled={userInfo.isReadUser}>
                <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
              </Button>
            </Popconfirm>
          </Popover>
        </div>
      </div>
    );
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('userName'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection, shareUserList } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const newEventList = this.sortData(shareUserList, name, sortDir);
          this.setState({ shareUserList: newEventList }, () => {
            if (this.listNode) this.listNode.forceUpdateGrid();
            this.forceUpdate();
          });
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  onUseNameSearch(value) {
    if (value) {
      const shareUserList = R.filter(
        (user) => (user?.userName ?? '').toLowerCase().includes((value || '').toLowerCase()),
        this.localShareUserList,
      );
      this.setState({ shareUserList }, () => {
        this.getCheckedAll();
      });
    } else {
      this.setState({ shareUserList: this.localShareUserList }, () => {
        this.getCheckedAll();
      });
    }
  }

  @autobind
  handleCheckAll(e) {
    const { shareUserList } = this.state;
    const { checked } = e.target;
    R.forEach((item) => {
      item.checked = checked;
    }, shareUserList);
    this.setState({
      shareUserList,
      isCheckedAll: checked,
    });
    this.forceUpdate();
  }

  @autobind
  handleGroupRemove() {
    const { shareUserList } = this.state;
    const selectedUser = R.filter((item) => item.checked, shareUserList);
    const userNames = R.map((item) => item.userName, selectedUser);
    this.handleRemoveShareUserClick(userNames);
  }

  render() {
    const { intl, credentials, userInfo, systemsMap, systemId } = this.props;
    const { isLoading, userNameValue, isCheckedAll } = this.state;
    const { isSubmittingShareUser, showAddShareUserModal, sortBy, sortDirection, shareUserList } = this.state;

    const hasErrDelete = R.isEmpty(R.filter((item) => item.checked, shareUserList));

    return (
      <Container className={`full-height flex-min-width flex-min-height flex-col ${isLoading ? ' loading' : ''}`}>
        <Spin spinning={isSubmittingShareUser} wrapperClassName="full-height spin-full-height">
          <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
            <div className="flex-row flex-center-align" style={{ marginRight: 16 }}>
              <div className="light-label bold font-16">{intl.formatMessage(eventMessages.userName)}:</div>
              <Input.Search
                size="small"
                allowClear
                value={userNameValue}
                style={{ width: 200, marginLeft: 8 }}
                placeholder={intl.formatMessage(eventMessages.userName)}
                onSearch={this.onUseNameSearch}
                onChange={(e) => this.setState({ userNameValue: e.target.value })}
              />
            </div>

            <div className="flex-row flex-center-align">
              <div className="light-label bold font-16">{intl.formatMessage(settingsMessages.systemSharedUsers)}:</div>
              <Popover
                content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="bottom"
              >
                <Button
                  size="small"
                  type="primary"
                  style={{ marginLeft: 16, marginRight: 10 }}
                  disabled={userInfo.isReadUser}
                  onClick={(event) => this.setState({ showAddShareUserModal: true })}
                >
                  <UserAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
                </Button>
              </Popover>
            </div>
            <Popover
              content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="bottom"
            >
              <Popconfirm
                placement="topRight"
                title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                okText={intl.formatMessage(appButtonsMessages.yes)}
                cancelText={intl.formatMessage(appButtonsMessages.no)}
                onConfirm={() => this.handleGroupRemove()}
                onCancel={(event) => event.stopPropagation()}
                disabled={userInfo.isReadUser}
              >
                <Button
                  type="primary"
                  size="small"
                  className="button-color-grey"
                  onClick={(event) => event.stopPropagation()}
                  disabled={hasErrDelete || userInfo.isReadUser}
                >
                  <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                </Button>
              </Popconfirm>
            </Popover>
          </div>
          <Container className="full-height">
            <AutoSizer>
              {({ width, height }) => (
                <div
                  className="event-list full-width flex-col flex-min-height"
                  style={{ marginBottom: 24, height, width }}
                >
                  <div className="event-list-header" style={{ height: 40, width: '100%', flexShrink: 0 }}>
                    <div className="header-column" style={{ width: 60 }}>
                      <Checkbox checked={isCheckedAll} onChange={this.handleCheckAll} />
                    </div>
                    <div
                      className="header-column"
                      style={{ width: 120, flex: 1 }}
                      onClick={this.headerClick('userName')}
                    >
                      <span>{intl.formatMessage(appFieldsMessages.userName)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'userName')}
                    </div>
                    <div className="header-column" style={{ width: 110 }} />
                  </div>
                  <div className="event-list-grid overflow-y-auto overflow-x-hidden flex-min-height">
                    {R.addIndex(R.map)((rowData, index) => this.renderShareUser(rowData, index), shareUserList)}
                  </div>
                </div>
              )}
            </AutoSizer>
          </Container>
        </Spin>

        {showAddShareUserModal && (
          <AddShareUserModal
            intl={intl}
            onClose={this.handleAddShareUserClose}
            credentials={credentials}
            updateLastActionInfo={this.props.updateLastActionInfo}
            userInfo={userInfo}
            systemsMap={systemsMap}
            systemId={systemId}
            createSetAction={this.props.createSetAction}
          />
        )}
      </Container>
    );
  }
}

type PropsModal = {
  intl: Object,
  onClose: Function,
  credentials: Object,
  // eslint-disable-next-line
  activeIncident: Object,
  updateLastActionInfo: Function,
  userInfo: Object,
  systemsMap: Object,
  systemId: String,
  createSetAction: Function,
};

const AddShareUserModal = ({
  intl,
  onClose,
  credentials,
  updateLastActionInfo,
  userInfo,
  systemsMap,
  systemId,
  createSetAction,
}: PropsModal) => {
  const [userList, setUserList] = useState([]);
  const [userName, setUserName] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const systemInfo = systemsMap[systemId];

  useEffect(() => {
    if (!systemInfo) return message.error('Missing system info!');
    setLoading(true);
    updateLastActionInfo();
    fetchGet(getEndpoint('customer', 2), {
      ...credentials,
    })
      .then((data) => {
        let users = data || [];
        users = R.sortWith([R.ascend(R.prop('userName'))], users);
        users = R.filter((item) => item.userName !== userInfo?.userName && item.userName !== systemInfo?.owner, users);
        users = [{ userName: 'All users in my group' }, ...users];
        setLoading(false);
        setUserList(users);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        console.log(err);
        setLoading(false);
        setUserList([]);
      });
  }, []);

  const handleSaveClick = (userName) => {
    if (!systemInfo) return message.error('Missing system info!');
    setIsSubmitting(true);
    updateLastActionInfo();
    fetchPost(getEndpoint('systemframework', 2), {
      ...credentials,
      operation: userName === 'All users in my group' ? 'shareSystemWithinCompany' : 'addShareSystem',
      systemKey: JSON.stringify({
        userName: systemInfo.owner,
        systemName: systemInfo.systemId,
        environmentName: systemInfo.environmentName,
      }),
      ...(userName === 'All users in my group' ? {} : { shareTo: userName }),
    })
      .then((data) => {
        const { shareUserSet } = data || {};
        setTimeout(() => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          const newSystemsMap = {
            ...systemsMap,
            [systemId]: { ...(systemsMap[systemId] || {}), shareUserSet: shareUserSet || [] },
          };
          createSetAction(ActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { systemsMap: newSystemsMap });
          setIsSubmitting(false);
          onClose(true);
        }, 1000);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        setIsSubmitting(false);
      });
  };

  const disabled = !userName;
  return (
    <Modal
      title={intl.formatMessage(settingsMessages.addShareUser)}
      width={650}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={() => handleSaveClick(userName)}
      okButtonProps={{ disabled, loading: isSubmitting }}
    >
      <Spin spinning={isLoading} wrapperClassName="spin-full-height full-width full-height">
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          <Form.Item label={intl.formatMessage(appFieldsMessages.userName)} required>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={userName}
              onChange={(userName) => setUserName(userName)}
            >
              {R.map((user) => {
                return (
                  <Select.Option key={user.userName}>{`${user.userName}${
                    user.companyName ? ` (${user.companyName})` : ''
                  }`}</Select.Option>
                );
              }, userList)}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

const SystemGeneralSetting = injectIntl(SystemShareSettingCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    return { location, loadStatus, systemsMap, credentials, userInfo };
  },
  {
    updateLastActionInfo,
    createSetAction,
  },
)(SystemGeneralSetting);
