/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';

const MetricParser = {
  getMetricTreeStructure: (allMetrics, isTree = true) => {
    // handle tree structure metrics
    const treeDataJson = {};
    let treeData = [];
    const treeDataKeys = [];

    const buildTreeDataJson = (dataJson, metricTreeList, parentKey) => {
      if (metricTreeList.length > 0) {
        const firstKey = metricTreeList[0];
        const newKey = parentKey ? `${parentKey}/${firstKey}` : firstKey;
        if (!R.has(firstKey, dataJson)) {
          dataJson[firstKey] = {
            title: firstKey,
            value: newKey,
            children: {},
          };
        }
        buildTreeDataJson(dataJson[firstKey].children, R.slice(1, Infinity, metricTreeList), newKey);
      } else {
        treeDataKeys.push(parentKey);
      }
    };
    const changeTreeJsonToList = (treeNode) => {
      return {
        ...treeNode,
        children: R.map(
          (item) => changeTreeJsonToList(item),
          R.sortWith([R.ascend(R.prop('value'))], R.values(treeNode.children)),
        ),
      };
    };

    // build tree json
    R.forEach((metric) => {
      const metricTreeList = isTree ? R.split('/', metric) : [metric];
      buildTreeDataJson(treeDataJson, metricTreeList, '');
    }, allMetrics);

    // build tree list
    treeData = R.map((item) => {
      return changeTreeJsonToList(item);
    }, R.sortWith([R.ascend(R.prop('value'))], R.values(treeDataJson)));

    return { treeDataJson, treeData, treeDataKeys };
  },
};

export default MetricParser;
