/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import qs from 'qs';
import { isString } from 'lodash';
import * as R from 'ramda';

const pickNotNil = R.pickBy((a) => !R.isNil(a));

/**
 * Parse url query string into object, return empty object is empty string.
 * The query string can be retrieved from location.search.
 */
const parseQueryString = (search) => {
  let query = search;
  if (isString(query) && query.length > 0) {
    if (query[0] === '?') {
      // Remove leading char ?
      query = query.slice(1);
    }

    // Convert predefined short name to the real name. t is used in query template
    const { e, s, c, r, ...rest } = qs.parse(query);
    return pickNotNil({ environmentId: e, systemId: s, componentId: c, regionId: r, ...rest });
  }
  return {};
};

export default parseQueryString;
