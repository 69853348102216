import bugRepositoryEpic from './bugRepositoryEpic';
import removeBugIssueEpic from './removeBugIssueEpic';
import saveBugRepositoryEpic from './saveBugRepositoryEpic';

const epics = [
  bugRepositoryEpic,
  removeBugIssueEpic,
  saveBugRepositoryEpic,
];

export default epics;
