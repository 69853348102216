/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import { get } from 'lodash';

const handleNaNConvert = (data) => {
  const replaceStr = R.replace(/NaN/g, null, data || '{}');
  return JSON.parse(replaceStr, (key, value) => {
    if (value === null) return 'None';
    if (R.is(String, value) && value === 'null') return 'None';
    return value;
  });
};

const parseMetricSettings = (metricSettings, metricUnitMapping) => {
  const metricUnitMap = {};
  R.forEach((item) => {
    const { unit, shortMetric } = item;
    metricUnitMap[item.metric] = { unit, shortMetric };
  }, metricUnitMapping);

  const metrics = R.map((setting) => {
    // parse metric setting info
    const kpiDurationThreshold = setting.kpiDurationThreshold || 0;
    const anomalyDampening = setting.anomalyDampening || 0;
    const thresholdAlertLowerBoundNegative =
      setting.thresholdAlertLowerBoundNegative * 1 === 0 ? 'NaN' : setting.thresholdAlertLowerBoundNegative;
    const thresholdAlertUpperBound =
      setting.thresholdAlertUpperBound * 1 === 0 ? 'NaN' : setting.thresholdAlertUpperBound;

    // parse capacitySetting
    const capacitySetting =
      setting.capacitySetting && setting.capacitySetting.indexOf('{') === 0
        ? JSON.parse(setting.capacitySetting)
        : null;
    let isIgnore = false;
    let cpSettingModified = false;
    let triggeringMode;
    let threshold = 0.0;
    if (capacitySetting instanceof Object) {
      isIgnore = Boolean(capacitySetting.isIgnore);
      cpSettingModified = Boolean(capacitySetting.modified);
      triggeringMode = capacitySetting.triggeringMode || undefined;
      threshold = Number(capacitySetting.threshold);
    }

    // get unit info
    const name = setting.smetric;
    // const pos = name.indexOf('/');
    // if (pos !== -1) {
    //   name = name.substring(0, pos);
    // }
    let unit = get(metricUnitMap, [name, 'unit'], '');
    const shortMetric = get(metricUnitMap, [name, 'shortMetric'], '');
    const pos2 = unit.indexOf('(');
    const pos3 = unit.indexOf(')');
    if (pos2 !== -1 && pos3 !== -1) {
      unit = unit.substring(pos2 + 1, pos3);
    }

    return {
      ...setting,
      checked: false,

      // unit info
      name,
      unit,
      shortMetric,
      kpiDurationThreshold: kpiDurationThreshold / 60000,
      anomalyDampening: anomalyDampening / 60000,

      // capacitySetting
      isIgnore,
      cpSettingModified,
      triggeringMode,
      threshold,
      rougeValue: setting.rougeValue ? handleNaNConvert(setting.rougeValue) : { l: 'None', s: 'None' },
      thresholdAlertLowerBoundNegative,
      thresholdAlertUpperBound,
    };
  }, metricSettings);

  return metrics;
};

export default parseMetricSettings;
