import React from 'react';
import * as R from 'ramda';
import ReactJson from '@dinuac/react-json-view';
import { toInteger } from 'lodash';
import { AutoComplete, Input, InputNumber, Select } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const hasKey = (obj, keys) => {
  let key = keys[0];
  R.forEach((k) => {
    if (R.has(k, obj || {})) {
      key = k;
    }
  }, keys || {});
  return key;
};

const ComputingTimeNew = ({ event = {}, handleChange = () => {} }: Object) => {
  let duration = event?.delay || 0;
  let days = 0;
  let hours = 0;
  let minutes = 0;

  if (!R.isNil(duration)) {
    days = toInteger(duration / 86400000);
    duration %= 86400000;
    hours = String(toInteger(duration / 3600000));
    duration %= 3600000;
    minutes = String(toInteger(duration / 60000));
  }

  return (
    <>
      <InputNumber
        size="small"
        style={{ width: 120 }}
        addonAfter="Days"
        min={0}
        value={days}
        controls={{ upIcon: <PlusOutlined />, downIcon: <MinusOutlined /> }}
        onChange={(days) => {
          const durationThreshold =
            (days ? Number(days) : 0) * 86400000 +
            (hours ? Number(hours) : 0) * 3600000 +
            (minutes ? Number(minutes) : 0) * 60000;
          handleChange(durationThreshold);
        }}
      />
      <InputNumber
        size="small"
        style={{ width: 120, margin: '0 10px' }}
        addonAfter="Hours"
        min={0}
        max={23}
        value={hours}
        controls={{ upIcon: <PlusOutlined />, downIcon: <MinusOutlined /> }}
        onChange={(hours) => {
          const durationThreshold =
            (days ? Number(days) : 0) * 86400000 +
            (hours ? Number(hours) : 0) * 3600000 +
            (minutes ? Number(minutes) : 0) * 60000;
          handleChange(durationThreshold);
        }}
      />
      <InputNumber
        size="small"
        style={{ width: 120 }}
        addonAfter="Minutes"
        min={0}
        max={59}
        value={minutes}
        controls={{ upIcon: <PlusOutlined />, downIcon: <MinusOutlined /> }}
        onChange={(minutes) => {
          const durationThreshold =
            (days ? Number(days) : 0) * 86400000 +
            (hours ? Number(hours) : 0) * 3600000 +
            (minutes ? Number(minutes) : 0) * 60000;
          handleChange(durationThreshold);
        }}
      />
    </>
  );
};

const MetricInputRuleInfo = ({ event = {}, metricList, incidentList, handleChange = () => {} }: Object) => {
  const { metricName, content, anomalyValue, avgValue, value, percentage, sign, metricDirection } = event || {};
  const { direction: mDirection } = event || {};

  const optionList = incidentList || metricList;

  const rootCauseMetric = metricName || content;
  const metricValue = anomalyValue || avgValue || value || 0;
  const pct = percentage || 0;
  let direction = sign || metricDirection || mDirection;

  if (R.includes(direction, ['positive', 'higher'])) {
    direction = 'higher';
  } else if (R.includes(direction, ['negative', 'lower'])) {
    direction = 'lower';
  }

  return (
    <>
      {optionList && (
        <AutoComplete
          allowClear
          size="small"
          value={rootCauseMetric}
          options={optionList}
          style={{ width: 200 }}
          className={`${rootCauseMetric ? '' : 'jsonKeyNoneError'}`}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
          }
          onChange={(value) => {
            handleChange({ [hasKey(event, ['metricName', 'content'])]: value });
          }}
        />
      )}
      {!optionList && (
        <Input
          allowClear
          size="small"
          value={rootCauseMetric}
          style={{ width: 200, marginRight: 4, ...(rootCauseMetric ? {} : { borderColor: '#ff7c6b' }) }}
          onChange={(e) => {
            handleChange({ [hasKey(event, ['metricName', 'content'])]: e.target.value });
          }}
        />
      )}

      <span style={{ color: 'var(--green)' }}>
        <span>&nbsp;(avg:&nbsp;</span>
        <InputNumber
          min={0}
          size="small"
          value={metricValue}
          controls={false}
          precision={5}
          style={{ width: 80, margin: '0 4px' }}
          onChange={(value) => {
            handleChange({ [hasKey(event, ['anomalyValue', 'avgValue', 'value'])]: R.isNil(value) ? 0 : value });
          }}
        />
        <span>)</span>
      </span>

      <span>&nbsp;is&nbsp;</span>

      <InputNumber
        min={0}
        size="small"
        addonAfter="%"
        controls={false}
        precision={2}
        value={pct}
        style={{ width: 100, margin: '0 4px' }}
        onChange={(value) => {
          handleChange({ percentage: R.isNil(value) ? 0 : value });
        }}
      />

      <Select
        size="small"
        value={direction}
        style={{ width: 75 }}
        options={[
          { label: 'higher', value: 'higher' },
          { label: 'lower', value: 'lower' },
        ]}
        onChange={(value) => {
          handleChange({ [hasKey(event, ['sign', 'metricDirection', 'direction'])]: value });
        }}
      />

      <span>&nbsp;than&nbsp;</span>
      <span style={{ color: 'var(--blue)' }}>{` normal`}</span>
    </>
  );
};

const LogInputContentInfo = ({ event = {}, handleChange = () => {} }: Object) => {
  const { content, incidentData } = event || {};
  return (
    <Input.TextArea
      allowClear
      size="small"
      autoSize={{ minRows: 1, maxRows: 10 }}
      value={content || incidentData}
      className={`${content || incidentData ? '' : 'inputIsNil'}`}
      onChange={(e) => {
        handleChange({ [hasKey(event, ['content', 'incidentData'])]: e.target.value });
      }}
    />
  );
};

const LogInputJsonInfo = ({
  event = {},
  currentTheme,
  collapsed = 2,
  onToggleCollapse,
  handleChange = () => {},
}: Object) => {
  return (
    <div onClick={(event) => event.stopPropagation()} style={{ padding: 4 }}>
      <ReactJson
        name={null}
        src={event || {}}
        theme={currentTheme === 'light' ? 'rjv-default' : 'ashes'}
        collapsed={collapsed}
        indentWidth={2}
        enableClipboard={false}
        displayDataTypes={false}
        onToggleCollapse={onToggleCollapse}
        style={{ whiteSpace: 'pre-wrap' }}
        onEdit={(edit) => {
          handleChange({ content: edit.updated_src });
        }}
      />
    </div>
  );
};

export { ComputingTimeNew, MetricInputRuleInfo, LogInputContentInfo, LogInputJsonInfo };
