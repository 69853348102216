/* @flow */

/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */

import { Observable } from 'rxjs/Observable';
import type { Deps } from '../../types';
import { getLoaderEpicAction } from '../../utils';
import { apiEpicErrorHandle } from '../../errors';
import { getMetricAppForecastData } from '../../apis';
import { updateLastActionInfo } from '../../app/actions';
import { setMetricAppForecastData, setMetricAppForecastDateWindow } from '../actions';

const loadMetricAppForecastDataEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('LOAD_METRIC_APP_FORECAST_DATA').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(getMetricAppForecastData(credentials, params))
      .concatMap((d) => {
        return Observable.of(setMetricAppForecastData(params, d.data));
      })
      .catch((err) => {
        return apiEpicErrorHandle(err);
      });

    return Observable.concat(
      Observable.of(setMetricAppForecastData(params)),
      Observable.of(setMetricAppForecastDateWindow()),
      showLoader,
      Observable.of(updateLastActionInfo()),
      apiAction$,
      hideLoader,
    );
  });

export default loadMetricAppForecastDataEpic;
