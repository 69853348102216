import React from 'react';
import VLink from 'valuelink';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { message, Button, Popover, Checkbox, Alert } from 'antd';

import fetchPost from '../../common/apis/fetchPost';
import getEndpoint from '../../common/apis/getEndpoint';
import { Regex, buildUrl } from '../../common/utils';
import { hideAppLoader } from '../../common/app/actions';
import { Input } from '../../lib/fui/react';
import { CenterPage } from '../app/components';

import { appFieldsMessages, appMessages } from '../../common/app/messages';
import { authMessages } from '../../common/auth/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  appLoaderVisible: Boolean,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  hideAppLoader: Function,
  currentTheme: String,
};

class SignupCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      hasError: false,
      hasSuccess: false,
      errorMessage: null,
      agreePrivacyPolicy: false,

      userName: '',
      email: '',
      fname: '',
      lname: '',
      companyName: '',
      phone: '',

      phoneFocus: false,
      userNameFocus: false,
      emailFocus: false,
    };
  }

  componentDidMount() {
    const urlRex = 'https://sandbox.insightfinder.com';
    const url = window.BASE_URL || window.location.origin || '';
    if (url === urlRex) {
      this.props.replace(buildUrl('/auth/sandbox-signup', {}, {}));
      return;
    }
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleSignUp(event) {
    event.preventDefault();

    const { intl } = this.props;
    const { userName, email, fname, lname, companyName, phone } = this.state;
    this.setState({ isLoading: true });
    return fetchPost(getEndpoint('get-signup-code', 1), {
      accept: true,
      UserName: userName,
      email,
      fname,
      lname,
      companyName,
      phone,
    })
      .then((data) => {
        const { success, message: errorMessage } = data;
        if (success) {
          this.setState({ isLoading: false, hasError: false });
          window.alert('We will review your request, and will email you the sign up code after confirmation.');
          window.location.href = '/auth/signup2';
        } else {
          message.error(intl.formatMessage(authMessages.signupFail));
          this.setState({ isLoading: false, hasError: true, errorMessage });
        }
      })
      .catch((err) => {
        // message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, hasError: true, errorMessage: err.message || String(err) });
      });
  }

  @autobind
  handlePostEmail(event) {
    event.preventDefault();

    const { email } = this.state;
    this.setState({ isLoading: true, hasError: false, errorMessage: '', hasSuccess: false });
    return fetchPost(getEndpoint('emailcollect', 1), { email })
      .then((data) => {
        this.setState({ isLoading: false, hasError: false, hasSuccess: true });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          hasError: true,
          hasSuccess: false,
          errorMessage: err.message || String(err),
        });
      });
  }

  render() {
    const { intl, push, currentTheme } = this.props;
    const { isLoading, hasError, errorMessage, agreePrivacyPolicy, hasSuccess } = this.state;

    const emailLink = VLink.state(this, 'email')
      .check((value) => value, intl.formatMessage(appFieldsMessages.inputRequired))
      .check((value) => Regex.email.test(value), intl.formatMessage(authMessages.errorsEmailIncorrect));

    const disabled = !agreePrivacyPolicy || emailLink.error;

    return (
      <CenterPage intl={intl} push={push} className="auth" currentTheme={currentTheme}>
        <h3 style={{ textAlign: 'center' }}>{intl.formatMessage(authMessages.getStartedForFree)}</h3>
        <form className={`ui ${hasError ? 'error' : ''} form login_class`} style={{ width: 360 }}>
          {hasError && <div className="ui error message">{errorMessage}</div>}
          {hasSuccess && <Alert message={intl.formatMessage(authMessages.successEmailTip)} type="success" />}

          <div className="input inline field required flex-col" style={{ margin: '36px 0' }}>
            <label style={{ width: 54, margin: '0 0 4px 0', fontSize: 14 }}>
              {intl.formatMessage(appFieldsMessages.email)}
            </label>
            <Popover
              visible={this.state.emailFocus}
              placement="top"
              title={null}
              content={
                <div className="flex-col" style={{ maxWidth: 200 }}>
                  Please use your company email address in your trial request.
                </div>
              }
            >
              <Input
                valueLink={emailLink}
                className="flex-grow"
                style={{ height: 40 }}
                onFocus={() => this.setState({ emailFocus: true })}
                onBlur={() => this.setState({ emailFocus: false })}
              />
            </Popover>
          </div>

          <div className="inline flex-row flex-center-align" style={{ margin: '0em 0em 1.5em' }}>
            <Checkbox
              checked={agreePrivacyPolicy}
              onChange={(e) => {
                this.setState({ agreePrivacyPolicy: e.target.checked });
              }}
            />
            <div style={{ marginLeft: 8, textTransform: 'none' }}>
              {intl.formatMessage(authMessages.hintsAgree)}
              <a href="https://insightfinder.com/terms-of-use" target="_blank" rel="noreferrer">
                &nbsp;{intl.formatMessage(authMessages.termsUse)}
              </a>{' '}
              {intl.formatMessage(appFieldsMessages.and)}
              <a href="https://insightfinder.com/privacy-policy" target="_blank" rel="noreferrer">
                &nbsp;{intl.formatMessage(authMessages.privacyPolicy)}
              </a>
              .
            </div>
          </div>

          <div className="field flex-row">
            <div className="flex-grow">
              {intl.formatMessage(authMessages.haveAnAccount)}?{' '}
              <Button
                type="link"
                style={{ margin: 0, padding: 0 }}
                onClick={() => {
                  push(buildUrl('/auth/login2', {}, {}));
                }}
              >
                {intl.formatMessage(authMessages.login)}
              </Button>
            </div>
            <Button type="primary" loading={isLoading} disabled={disabled} onClick={this.handlePostEmail}>
              {intl.formatMessage(authMessages.buttonsSignUp)}
            </Button>
          </div>

          {/* <div style={{ color: '#276F86', padding: '4px 0px' }}>
            <i className="info icon" style={{ margin: 0, padding: 0 }} />
            <span>{intl.formatMessage(authMessages.hintsWaitReviewRequest)}</span>
          </div> */}
        </form>
      </CenterPage>
    );
  }
}

const Signup = injectIntl(SignupCore);
export default connect(
  (state) => {
    const { location } = state.router;
    return {
      currentTheme: state.app.currentTheme,
      location,
      appLoaderVisible: state.app.appLoaderVisible,
    };
  },
  { push, replace, hideAppLoader },
)(Signup);
