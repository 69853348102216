/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { getLoaderEpicAction, pickNotNil } from '../../utils';
import { deleteCausalGroup } from '../../apis';
import { updateLastActionInfo } from '../../app/actions';
import { setCausalErrorMessage } from '../actions';
import { appMessages } from '../../app/messages';
import { Defaults } from '../../app';

const removeCausalGroupEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('REMOVE_CAUSAL_GROUP').concatMap((action) => {
    const { loader } = action.payload;
    const params = pickNotNil(action.payload.params || {});
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(deleteCausalGroup(credentials, params))
      .concatMap(() => {
        return hideLoader;
      })
      .catch((err) => {
        const message = err.message || {};
        return Observable.concat(
          Observable.of(setCausalErrorMessage(appMessages.errorsMessage, { message })),
          hideLoader,
          Observable.of(setCausalErrorMessage(null)).delay(Defaults.ErrorHideDelay),
        );
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default removeCausalGroupEpic;
