import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { UserAddOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { isMobile } from 'react-device-detect';

import { buildUrl } from '../../../common/utils';
import { Container, Image, Box } from '../../../lib/fui/react';

import logo from '../../../../images/logo_horizontal.png';
import logoWhite from '../../../../images/InsightFinder_Horizontal_White.svg';
import undrawArtificialIntelligenceOyxx from '../../../../images/undraw_Artificial_intelligence_oyxx.svg';
import undrawCreativeExperiment8dk3 from '../../../../images/undraw_creative_experiment_8dk3.svg';
import undrawHealthyOptionsSdo3 from '../../../../images/undraw_healthy_options_sdo3.svg';
import undrawThroughTheWindow51ew from '../../../../images/undraw_through_the_window_51ew.svg';

import { authMessages } from '../../../common/auth/messages';
import { appFieldsMessages } from '../../../common/app/messages';

type Props = {
  intl: Object,
  push: Function,
  style: Object,
  className: string,
  hideBeacon: Boolean,
  children: Element<any>,
  idpTypeCallback: Function,
  currentTheme: String,
};

const addBeacon = () => {
  const iniBeacon = (e, t, n) => {
    const a = () => {
      const e = t.getElementsByTagName('script')[0];
      const n = t.createElement('script');
      n.type = 'text/javascript';
      n.async = !0;
      n.src = 'https://beacon-v2.helpscout.net';
      e.parentNode.insertBefore(n, e);
    };
    n = (t, n, a) => {
      e.Beacon.readyQueue.push({ method: t, options: n, data: a });
    };
    e.Beacon = n;
    n.readyQueue = [];
    if (t.readyState === 'complete') {
      return a();
    }
    return e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
  };
  iniBeacon(window, document, window.Beacon || (() => {}));
  window.Beacon('init', '63059072-2061-4f21-b9a6-7fff6aafd46b');
};

const cleanBeacon = () => {
  if (window.Beacon) window.Beacon('destroy');
};

const CenterPage = ({
  intl,
  push,
  style,
  className,
  hideBeacon,
  children,
  idpTypeCallback,
  currentTheme,
  introduceRender,
}: Props) => {
  const [typeValue, setTypeValue] = useState('Insightfinder');
  const { pathname } = useSelector((state) => state.router.location);

  if (!hideBeacon) {
    useEffect(() => {
      // Embed Beacon
      if (!isMobile) addBeacon();

      // Specify how to clean up after this effect:
      return () => {
        // Clean Beacon
        if (!isMobile) cleanBeacon();
      };
    }, []);
  }

  return (
    <Container
      className={`full-height full-width center-page ${!isMobile ? 'flex-col flex-min-height' : ''} ${className || ''}`}
      style={style}
    >
      <div className="flex-row flex-center-align flex-space-between" style={{ marginBottom: 12 }}>
        <Image
          size={isMobile ? 150 : 200}
          src={currentTheme === 'light' ? logo : logoWhite}
          className="clickable"
          onClick={() => {
            window.location.href = 'https://insightfinder.com';
          }}
        />

        {![
          '/login',
          '/auth/login2',
          '/auth/virtual_login',
          '/auth/signup',
          '/auth/signup2',
          '/auth/awsLandingPage',
          '/auth/sandbox-signup',
        ].includes(pathname) && (
          <div>
            <UserAddOutlined style={{ fontSize: 28 }} />
            <span style={{ margin: '0 8px' }}>{intl.formatMessage(authMessages.hintsNewUser)}</span>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                push(buildUrl('/auth/signup', {}, {}));
              }}
            >
              {intl.formatMessage(authMessages.register)}
            </Button>
          </div>
        )}
        {/* {(pathname === '/login' || pathname === '/auth/login2') && (
          <div className="input field required">
            <span style={{ marginRight: 10 }}>{intl.formatMessage(appFieldsMessages.idpType)}</span>
            <Select
              showSearch
              value={typeValue}
              onChange={(value) => {
                setTypeValue(value);
                idpTypeCallback(value);
              }}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              style={{ width: 150 }}
            >
              <Select.Option value="Insightfinder">Insightfinder</Select.Option>
              <Select.Option value="Azure">Azure AD</Select.Option>
              <Select.Option value="MS">MS AD</Select.Option>
              <Select.Option value="LDAPS">OpenLDAP</Select.Option>
            </Select>
          </div>
        )} */}
        {['/auth/login2', '/auth/signup', '/auth/signup2'].includes(pathname) && <></>}
      </div>

      <div className="flex-grow flex-min-height overflow-y-auto overflow-x-hidden">
        <div className="flex-row flex-center-align flex-center-justify" style={{ margin: '60px 0' }}>
          <div className="flex-col login_img_class">
            <Image size={200} style={{ margin: '24px 60px' }} src={undrawThroughTheWindow51ew} />
            <Image size={200} style={{ margin: '24px 60px' }} src={undrawArtificialIntelligenceOyxx} />
          </div>
          <div>
            {introduceRender && (
              <Box style={{ minWidth: 418, marginBottom: 24 }} className="login_class" raised>
                {introduceRender()}
              </Box>
            )}
            <Box
              style={{ minWidth: 418, padding: '24px 48px', borderRadius: '2.5em' }}
              raised
              className="content-bg login_class"
            >
              {children}
            </Box>
          </div>

          <div className="flex-col login_img_class">
            <Image size={200} style={{ margin: '24px 60px' }} src={undrawCreativeExperiment8dk3} />
            <Image size={200} style={{ margin: '24px 60px' }} src={undrawHealthyOptionsSdo3} />
          </div>
        </div>
      </div>

      <div className="flex-row flex-center-align flex-wrap" style={{ marginTop: 12 }}>
        <div style={{ width: 200 }}>
          <Button type="link" href="https://insightfinder.com/privacy-policy" style={{ padding: '0 8px' }}>
            {intl.formatMessage(authMessages.privacy)}
          </Button>
          |
          <Button type="link" href="https://insightfinder.com/terms-of-use" style={{ padding: '0 8px' }}>
            {intl.formatMessage(authMessages.terms)}
          </Button>
        </div>
        <div className="flex-grow" />
        <div className="copyright">{`InsightFinder © ${moment().year()}`}</div>
        <Button type="link" href="mailto:info@insightfinder.com" style={{ padding: '0 8px' }}>
          info@insightfinder.com
        </Button>
        <Button type="link" href="mailto:support@insightfinder.com" style={{ padding: '0 8px' }}>
          support@insightfinder.com
        </Button>
        <div className="flex-grow" />
        <div style={{ width: 200 }} />
      </div>
    </Container>
  );
};

export default CenterPage;
