/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import { isBoolean } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

import { apiCallAfterCreateProject } from './parsers';

const newAWSCloudWatchProject = (credentials: Credentials, projectName: String, params: Object) => {
  const {
    instanceTypes,
    iamAccessKey,
    secretAccessKey,
    samplingInterval,
    collectionInterval,
    // system info
    systemKey,
    systemDisplayName,
    projectOwner,
    // labels info
    labelsWhitelist,
    labelsIncident,
    dataType,
    ...rest
  } = params;

  const projectCreationType = dataType === 'Metric' ? 'AMSMetric' : 'AMSLog';
  const samplingIntervalInSeconds = parseInt(samplingInterval, 10);
  const collectionIntervalInSeconds = parseInt(collectionInterval, 10);
  return fetchPost(getEndpoint('add-project'), {
    ...credentials,
    ...rest,
    operation: 'register',
    projectOwner,
    projectName,
    dataType,
    projectCreationType,
    instanceType: instanceTypes.toString(),
    email: '',
    accessKey: iamAccessKey,
    secretKey: secretAccessKey,
    hasAgentData: false,
    samplingInterval: samplingIntervalInSeconds / 60,
    samplingIntervalInSeconds,
    collectionInterval: collectionIntervalInSeconds / 60,
    collectionIntervalInSeconds,
  }).then((d) => {
    if (isBoolean(d.success) && d.success === false) {
      return d;
    }

    return apiCallAfterCreateProject({
      credentials,
      projectName,
      systemKey,
      systemDisplayName,
      labelsWhitelist,
      labelsIncident,
      projectOwner,
    });
  });
};

export default newAWSCloudWatchProject;
