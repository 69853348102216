/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const newExternalService = (credentials: Credentials, params: Object) => {
  const { operation, serviceHost, ...rest } = params;
  const serviceKey = operation === 'Slack' ? params.webhook : params.serviceKey;

  return fetchPost(
    getEndpoint('service-integration'),
    {
      ...credentials,
      ...rest,
      operation,
      service_host: serviceHost,
      service_key: serviceKey,
    },
    {},
    false,
  );
};

export default newExternalService;
