import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { AutoComplete, Checkbox, Divider, Input, Select } from 'antd';

import { Modal } from '../../../../lib/fui/react';
import { DashboardMessages } from '../../../../common/dashboard/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

const algorithmOperation = [
  { label: 'Sum', value: 1 },
  { label: 'Substraction', value: 2 },
  { label: 'Multiplication', value: 3 },
  { label: 'Division', value: 4 },
];
const judgeRegexKeyFlag = (value) => {
  let flag = false;
  if (R.includes('=', value || '')) {
    const [k] = R.split('=', value || '');
    if (k) flag = true;
  } else if (value) {
    flag = true;
  }
  return flag;
};

function DerivedValueModelCore({ activeEvent, fieldNameOptions = [], onClose, ...props }: Object) {
  const { intl } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    derivedValueModel: {},
    searchValue: '',
  });
  const { derivedValueModel, searchValue } = state;
  const { baseValue, actualValue, operation, mappingIdList } = derivedValueModel;

  useEffect(() => {
    const { mappingIdList: asMappingIdList, ...rest } = activeEvent?.derivedValueModel || {};
    setState({ derivedValueModel: { ...rest, mappingIdList: asMappingIdList || [] } });
  }, [activeEvent]);

  const regexAutoCompleteChange = (dataKey, regexV, cellData) => {
    const newRegexV = regexV ? `=${regexV}` : '';
    const newCellData = cellData ? `${cellData}${newRegexV}` : newRegexV;
    setState({ derivedValueModel: { ...derivedValueModel, [dataKey]: newCellData } });
  };

  const regexInputChange = (dataKey, regexK, cellData) => {
    const newRegexK = regexK || '';
    const newCellData = cellData ? `${newRegexK}=${cellData}` : newRegexK;
    setState({ derivedValueModel: { ...derivedValueModel, [dataKey]: newCellData } });
  };

  const regexValueRender = (dataKey, requiredFlag) => {
    const regexValue = derivedValueModel[dataKey] || '';
    let regexK = regexValue;
    let regexV;
    if (R.includes('=', regexValue)) {
      const [k, v] = R.split('=', regexValue);
      regexK = k;
      regexV = v;
    }

    return (
      <div className="">
        <AutoComplete
          size="small"
          allowClear
          options={fieldNameOptions}
          value={regexK}
          filterOption={(inputValue, option) => option.value.toUpperCase().indexOf((regexK || '').toUpperCase()) !== -1}
          onChange={(cellData) => regexAutoCompleteChange(dataKey, regexV, cellData)}
          style={{ width: 200 }}
          className={`${regexK || !requiredFlag ? '' : 'jsonKeyNoneError'}`}
        />
        <span style={{ margin: '0 10px' }}>=</span>
        <Input
          size="small"
          allowClear
          value={regexV}
          style={{ width: 200 }}
          onChange={(e) => regexInputChange(dataKey, regexK, e.target.value)}
        />
      </div>
    );
  };

  const onSubmit = () => {
    const data = {
      ...(baseValue ? { baseValue } : {}),
      ...(actualValue ? { actualValue } : {}),
      ...(operation ? { operation } : {}),
      ...(mappingIdList.length > 0 ? { mappingIdList } : {}),
      index: activeEvent?.index,
    };
    onClose(data);
  };

  const getListOptionValues = (option) => {
    return R.map((item) => item.value, option);
  };

  const getFilterValue = ({ searchValue, options, type = 'label' }) => {
    return R.filter(
      (item) => (item[type] || '').toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1,
      options,
    );
  };

  const isCheckedAll = ({ searchValue, options, valueList }) => {
    const filterValue = getListOptionValues(getFilterValue({ searchValue, options }));
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  };

  const requiredFlag = operation || (mappingIdList || []).length > 0 || baseValue || actualValue;
  const baseValueFlag = judgeRegexKeyFlag(baseValue);
  const actualValueFlag = judgeRegexKeyFlag(actualValue);
  const hasError = requiredFlag
    ? !operation || (mappingIdList || []).length === 0 || !baseValueFlag || !actualValueFlag
    : false;

  let newOption = [];
  R.forEach((item) => {
    const option = R.find((_item) => _item.value === item, fieldNameOptions);
    if (!option) newOption.push({ label: item, value: item });
  }, mappingIdList || []);
  newOption = [...newOption, ...fieldNameOptions];
  const allOptionContents = R.map((item) => item.value, newOption || []);
  const showSearchOption = searchValue && !R.includes(searchValue, allOptionContents);
  if (showSearchOption) newOption = [{ label: searchValue, value: searchValue }, ...newOption];

  return (
    <Modal
      visible
      width={700}
      onCancel={() => onClose()}
      onOk={onSubmit}
      okText={intl.formatMessage(appButtonsMessages.submit)}
      title={intl.formatMessage(DashboardMessages.derivedValueModel)}
      okButtonProps={{ disabled: hasError }}
    >
      <div>
        <div className="flex-row flex-center-align full-width">
          <div style={{ width: 100 }}>
            {intl.formatMessage(settingsMessages.baseValue)}:
            {requiredFlag && <span style={{ color: 'red', marginLeft: 4 }}>*</span>}
          </div>
          {regexValueRender('baseValue', requiredFlag)}
        </div>
        <div className="flex-row flex-center-align full-width" style={{ marginTop: 8 }}>
          <div style={{ width: 100 }}>
            {intl.formatMessage(settingsMessages.actualValue)}:
            {requiredFlag && <span style={{ color: 'red', marginLeft: 4 }}>*</span>}
          </div>
          {regexValueRender('actualValue', requiredFlag)}
        </div>
        <div className="flex-row flex-center-align full-width" style={{ marginTop: 8 }}>
          <div style={{ width: 100 }}>
            {intl.formatMessage(appFieldsMessages.operation)}:
            {requiredFlag && <span style={{ color: 'red', marginLeft: 4 }}>*</span>}
          </div>
          <Select
            size="small"
            showSearch
            allowClear
            style={{ width: 428 }}
            options={algorithmOperation}
            value={operation}
            className={`${operation || !requiredFlag ? '' : 'jsonKeyNoneError'}`}
            onChange={(operation) => setState({ derivedValueModel: { ...derivedValueModel, operation } })}
          />
        </div>
        <div className="flex-row flex-center-align full-width" style={{ marginTop: 8 }}>
          <div style={{ width: 100 }}>
            {intl.formatMessage(settingsMessages.mappingIds)}:
            {requiredFlag && <span style={{ color: 'red', marginLeft: 4 }}>*</span>}
          </div>
          <Select
            allowClear
            size="small"
            mode="multiple"
            style={{ width: 428 }}
            autoClearSearchValue={false}
            optionFilterProp="children"
            optionLabelProp="value"
            className={`no-count-num-row ${
              (mappingIdList || []).length > 0 || !requiredFlag ? '' : 'jsonKeyNoneError'
            }`}
            value={mappingIdList}
            onChange={(mappingIdList) => setState({ derivedValueModel: { ...derivedValueModel, mappingIdList } })}
            onDropdownVisibleChange={(open) => {
              if (!open) setState({ searchValue: '' });
            }}
            onSearch={(searchValue) => setState({ searchValue })}
            dropdownRender={(menu) => {
              return (
                <div>
                  <div
                    className="flex-row flex-center-align"
                    style={{ padding: '5px 12px' }}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={isCheckedAll({ searchValue, options: newOption, valueList: mappingIdList || [] })}
                      onChange={(e) => {
                        const { checked } = e.target;
                        const filterValue = getListOptionValues(getFilterValue({ searchValue, options: newOption }));
                        const diff = R.difference(mappingIdList || [], filterValue);
                        setState({
                          derivedValueModel: {
                            ...derivedValueModel,
                            mappingIdList: checked ? [...diff, ...filterValue] : diff,
                          },
                        });
                      }}
                    />
                    <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                  </div>
                  <Divider style={{ margin: '4px 0' }} />
                  {menu}
                </div>
              );
            }}
          >
            {R.map((item) => {
              return (
                <Select.Option className="hide-icon" key={item.value} value={item.value}>
                  <Checkbox
                    checked={(mappingIdList || []).includes(item.value)}
                    onChange={(e) => null}
                    style={{ marginRight: 8 }}
                  />
                  {item.label}
                </Select.Option>
              );
            }, newOption)}
          </Select>
        </div>
      </div>
    </Modal>
  );
}

const DerivedValueModel = injectIntl(DerivedValueModelCore);
export default connect((state) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;

  return { location, userInfo, credentials };
}, {})(DerivedValueModel);
