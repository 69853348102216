import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import { Input, message, Spin } from 'antd';
import { isEmpty, times } from 'lodash';
import momenttz from 'moment-timezone';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { AutoSizer, Container } from '../../../lib/fui/react';
import { buildLocation, Defaults, parseLocation, getRegExp } from '../../../common/utils';

import { appFieldsMessages } from '../../../common/app/messages';
import { EChart } from '../../share';
import { eventMessages } from '../../../common/metric/messages';

const getProjectTitle = (name, projectMap, projectToDisplayNameMap) => {
  const [p, u] = R.split('@', name || '');
  if (!p || !projectMap[p]) {
    return !p ? name : p;
  }
  if (projectMap[p].length > 1) {
    const findP = R.find((item) => item.projectName === p && item.customerName === u, projectMap[p]);
    return findP ? `${findP.projectDisplayName}@${findP.customerName}` : p;
  } else {
    return projectToDisplayNameMap[name]?.projectDisplayName || p;
  }
};

const getSectionTime = (startDate, endDate) => {
  // const startTime = moment.utc(startDate).startOf('month').valueOf();
  // let endTime = moment
  //   .utc(endDate || startDate)
  //   .endOf('month')
  //   .valueOf();
  const startTime = moment.utc(startDate).startOf('days').valueOf();
  let endTime = moment
    .utc(endDate || startDate)
    .endOf('days')
    .valueOf();
  const nowTimestamp = moment.utc().endOf('days').valueOf();
  if (endTime > nowTimestamp) {
    endTime = nowTimestamp;
  }
  return { startTime, endTime };
};

// const getTimeTabList = (startMonth, endMonth) => {
//   const { startTime, endTime } = getSectionTime(startMonth, endMonth);
//   let monthStrList = [];
//   R.forEach((ts) => {
//     const timestamp = ts * 86400000;
//     monthStrList.push(moment.utc(timestamp).format(Defaults.MonthFormat));
//   }, R.range(startTime / 86400000, endTime / 86400000));
//   monthStrList = R.uniq(monthStrList);
//   return monthStrList;
// };

const getXAxisList = (startTime, endTime) => {
  let xAxisList = ['product'];
  let timestampList = [];
  R.forEach((ts) => {
    const timestamp = ts * 86400000;
    xAxisList.push(moment.utc(timestamp).format(Defaults.DateFormat));
    timestampList.push(moment.utc(timestamp).startOf('days').valueOf());
  }, R.range(startTime / 86400000, endTime / 86400000));
  xAxisList = R.uniq(xAxisList);
  timestampList = R.uniq(timestampList);
  return { xAxisList, timestampList };
};

const getNowTime = (pickLastTimeValue, timeZoneStr) => {
  let nowEndTime = moment.utc(timeZoneStr).startOf('minute').valueOf();
  const setEndMinutes = Math.floor(moment(nowEndTime).utc().minutes() / 15) * 15;
  nowEndTime = moment(nowEndTime).utc().minutes(setEndMinutes).startOf('minute').valueOf();
  const nowStartTime = nowEndTime - pickLastTimeValue;
  return { nowStartTime, nowEndTime };
};

const getXAxisLastTimeList = (pickLastTimeValue, timeZoneStr) => {
  let nowEndTime = moment.utc(timeZoneStr).startOf('minute').valueOf();
  const setEndMinutes = Math.floor(moment(nowEndTime).utc().minutes() / 15) * 15;
  nowEndTime = moment(nowEndTime).utc().minutes(setEndMinutes).startOf('minute').valueOf();
  const nowStartTime = nowEndTime - pickLastTimeValue;

  let xAxisLastTime = [];
  const xAxisLastTimestamp = [];
  R.forEach((ts) => {
    const timestamp = ts * 900000;
    xAxisLastTime.push(momenttz(timestamp).tz('UTC').format(Defaults.TimeOnlyFormat));
    xAxisLastTimestamp.push({ s: timestamp, e: timestamp + 900000 });
  }, R.range(nowStartTime / 900000, nowEndTime / 900000));

  xAxisLastTime = ['product', ...xAxisLastTime];
  return { xAxisLastTime, xAxisLastTimestamp };
};

const getOption = (list, startMonth, endMonth, searchProjectName, projects) => {
  const { startTime, endTime } = getSectionTime(startMonth, endMonth);
  let data = list || [];

  let projectMap = {};
  const projectToDisplayNameMap = {};

  data = R.map((item) => {
    const { projectName, customerName } = item;
    const projectInfo = R.find((project) => project.projectShortName === projectName, projects || []);
    const projectDisplayName = projectInfo?.projectDisplayName || projectName;
    const projectAndUser = `${projectName}@${customerName}`;

    if (!projectMap[projectName]) {
      projectMap[projectName] = [{ projectName, projectDisplayName, customerName }];
    } else {
      projectMap[projectName] = [...projectMap[projectName], { projectName, projectDisplayName, customerName }];
    }

    if (!projectToDisplayNameMap[projectAndUser]) {
      projectToDisplayNameMap[projectAndUser] = { projectName, projectDisplayName, customerName };
    }
    return { ...item, projectName: projectAndUser, projectDisplayName };
  }, data);

  projectMap = R.mapObjIndexed((val, key) => {
    return R.uniqBy((item) => `${item.projectName}@${item.customerName}`, val || []);
  }, projectMap);

  const regex = getRegExp(searchProjectName);
  data = R.filter((item) => {
    return regex ? regex.test(`${item.projectDisplayName}@${item.customerName}`) : false;
  }, data || []);

  // color值
  const colors = [
    '#ad4c34',
    '#df9954',
    '#744a74',
    '#547ec9',
    '#D37387',
    '#83d4de',
    '#1D7324',
    '#e095e0',
    '#68B78C',
    '#ae9be3',

    '#ee8e76',
    '#7e93b7',
    '#6fc876',
    '#a8d6b7',
    '#d9e446',
    '#ca87ca',
    '#4e6da5',
    '#C35989',
    '#918ADF',
    '#D78742',

    '#B9541A',
    '#E9A133',
    '#3878A1',
    '#A3416E',
    '#F1AEA4',
    '#8BCDBC',
    '#399561',
    '#A4D8A8',
    '#764EA2',
    '#B2ADED',

    '#BC5B22',
    '#EEB46C',
    '#3E769E',
    '#ABC4A2',
    '#3B925D',
    '#B2CB9A',
    '#6565C5',
    '#B4B4F2',
    '#98366D',
    '#DD86AF',

    '#FFC940',
    '#FFEEC5',
    '#96ec9d',
    '#E8F8B6',
    '#D1E1FF',
    '#dab45a',
    '#6B9FA1',
    '#FFE39F',
    '#ddd8cc',
    '#E1BAE1',

    // '#20B2AA'
  ];

  // 获取X轴数据
  const { xAxisList = [], timestampList = [] } = getXAxisList(startTime, endTime);

  R.forEach((item) => {
    const { countList } = item;
    let total = 0;
    R.forEach((_item) => {
      const { instanceCount, metricCount } = _item;
      total += instanceCount + metricCount;
    }, countList);
    item.total = total;
  }, data);
  data = R.sortWith([R.descend(R.prop('total'))], data);

  // 处理data 和 获取legend
  let handleDataList = [];
  let legendList = [];
  R.addIndex(R.forEach)((item, index) => {
    const { projectName, customerName, total } = item;
    let { countList } = item;
    const itemList = [];
    let legendTotal = 0;
    // let instanceTotal = 0;
    // let metricTotal = 0;

    // 设置颜色
    const color = index < colors.length ? colors[index] : '#20B2AA';

    // 把数据过滤为当前点击月份下的
    countList = R.filter((val) => {
      return val.dailyTimestamp >= startTime && val.dailyTimestamp <= endTime;
    }, countList);

    R.forEach((_item) => {
      const { dailyTimestamp, instanceCount, metricCount } = _item;
      const timeStr = moment.utc(dailyTimestamp).format(Defaults.DateFormat);
      itemList.push({ projectName, customerName, dailyTimestamp, instanceCount, metricCount, color, timeStr });
      legendTotal += instanceCount + metricCount;
      // instanceTotal += instanceCount;
      // metricTotal += metricCount;
    }, countList);

    handleDataList.push({ projectName, customerName, countList: itemList, color, total });
    // handleDataList.push({ projectName, customerName, countList: itemList, color, total, instanceTotal, metricTotal});
    legendList.push({ projectName, total: legendTotal });
  }, data);
  handleDataList = R.filter((item) => item.total > 0, handleDataList);

  // 处理legend
  legendList = R.filter((item) => item.total > 0, legendList);
  legendList = R.sortWith([R.descend(R.prop('total'))], legendList);
  const legendData = R.take(10)(R.map((item) => item.projectName, legendList || []));

  // 前10
  // let topTeninstanceList = R.sortWith([R.descend(R.prop('instanceTotal'))], handleDataList);
  // let topTenmetricList = R.sortWith([R.descend(R.prop('metricTotal'))], handleDataList);

  // // Instance前10
  // const tenListInstance = [];
  // let otherItemInstance = {};
  // if (topTeninstanceList.length > 10) {
  //   legendData.push('Other');
  //   legendData = R.uniq(legendData);
  //   // 处理前10
  //   R.forEach((item) => {
  //     const { countList, projectName, customerName, color } = item;
  //     const itemList = [];
  //     R.forEach((itemStamp) => {
  //       const findCountItem = R.find((item) => item.dailyTimestamp === itemStamp, countList || []);
  //       if (findCountItem) {
  //         itemList.push(findCountItem);
  //       } else {
  //         const timeStr = moment.utc(itemStamp).format(Defaults.DateFormat);
  //         itemList.push({
  //           projectName,
  //           customerName,
  //           dailyTimestamp: itemStamp,
  //           instanceCount: 0,
  //           metricCount: 0,
  //           color,
  //           timeStr,
  //         });
  //       }
  //     }, timestampList);
  //     tenListInstance.push({ ...item, countList: itemList });
  //   }, R.take(10)(topTeninstanceList));
  //   // 处理Other
  //   const otherStampObj = {};
  //   const otherStampList = [];
  //   R.forEach((itemStamp) => {
  //     otherStampObj[itemStamp] = { instanceCount: 0, metricCount: 0 };
  //   }, timestampList);
  //   R.forEach((item) => {
  //     const { countList } = item;
  //     if (countList.length > 0) {
  //       R.forEach((_item) => {
  //         const { dailyTimestamp, instanceCount, metricCount } = _item;
  //         if (R.has(dailyTimestamp, otherStampObj)) {
  //           otherStampObj[dailyTimestamp].instanceCount += instanceCount;
  //           otherStampObj[dailyTimestamp].metricCount += metricCount;
  //         }
  //       }, countList);
  //     }
  //   }, R.slice(10, Infinity)(topTeninstanceList));
  //   R.forEachObjIndexed((val, key) => {
  //     otherStampList.push({
  //       ...val,
  //       dailyTimestamp: Number(key),
  //       color: '#20B2AA',
  //       projectName: 'Other',
  //       customerName: '',
  //       timeStr: moment.utc(Number(key)).format(Defaults.DateFormat),
  //     });
  //   }, otherStampObj);
  //   otherItemInstance = {
  //     projectName: 'Other',
  //     customerName: '',
  //     color: '#20B2AA',
  //     countList: otherStampList,
  //   };
  // } else {
  //   R.forEach((item) => {
  //     const { countList, projectName, customerName, color } = item;
  //     const itemList = [];
  //     R.forEach((itemStamp) => {
  //       const findCountItem = R.find((item) => item.dailyTimestamp === itemStamp, countList || []);
  //       if (findCountItem) {
  //         itemList.push(findCountItem);
  //       } else {
  //         const timeStr = moment.utc(itemStamp).format(Defaults.DateFormat);
  //         itemList.push({
  //           projectName,
  //           customerName,
  //           dailyTimestamp: itemStamp,
  //           instanceCount: 0,
  //           metricCount: 0,
  //           color,
  //           timeStr,
  //         });
  //       }
  //     }, timestampList);
  //     tenListInstance.push({ ...item, countList: itemList });
  //   }, topTeninstanceList);
  // }
  // topTeninstanceList = tenListInstance;
  // if (!isEmpty(otherItemInstance)) topTeninstanceList.push(otherItemInstance);

  // // Metric前10
  // const tenListMetric = [];
  // let otherItemMetric = {};
  // if (topTenmetricList.length > 10) {
  //   legendData.push('Other');
  //   legendData = R.uniq(legendData);
  //   // 处理前10
  //   R.forEach((item) => {
  //     const { countList, projectName, customerName, color } = item;
  //     const itemList = [];
  //     R.forEach((itemStamp) => {
  //       const findCountItem = R.find((item) => item.dailyTimestamp === itemStamp, countList || []);
  //       if (findCountItem) {
  //         itemList.push(findCountItem);
  //       } else {
  //         const timeStr = moment.utc(itemStamp).format(Defaults.DateFormat);
  //         itemList.push({
  //           projectName,
  //           customerName,
  //           dailyTimestamp: itemStamp,
  //           instanceCount: 0,
  //           metricCount: 0,
  //           color,
  //           timeStr,
  //         });
  //       }
  //     }, timestampList);
  //     tenListMetric.push({ ...item, countList: itemList });
  //   }, R.take(10)(topTenmetricList));
  //   // 处理Other
  //   const otherStampObj = {};
  //   const otherStampList = [];
  //   R.forEach((itemStamp) => {
  //     otherStampObj[itemStamp] = { instanceCount: 0, metricCount: 0 };
  //   }, timestampList);
  //   R.forEach((item) => {
  //     const { countList } = item;
  //     if (countList.length > 0) {
  //       R.forEach((_item) => {
  //         const { dailyTimestamp, instanceCount, metricCount } = _item;
  //         if (R.has(dailyTimestamp, otherStampObj)) {
  //           otherStampObj[dailyTimestamp].instanceCount += instanceCount;
  //           otherStampObj[dailyTimestamp].metricCount += metricCount;
  //         }
  //       }, countList);
  //     }
  //   }, R.slice(10, Infinity)(topTenmetricList));
  //   R.forEachObjIndexed((val, key) => {
  //     otherStampList.push({
  //       ...val,
  //       dailyTimestamp: Number(key),
  //       color: '#20B2AA',
  //       projectName: 'Other',
  //       customerName: '',
  //       timeStr: moment.utc(Number(key)).format(Defaults.DateFormat),
  //     });
  //   }, otherStampObj);
  //   otherItemMetric = {
  //     projectName: 'Other',
  //     customerName: '',
  //     color: '#20B2AA',
  //     countList: otherStampList,
  //   };
  // } else {
  //   R.forEach((item) => {
  //     const { countList, projectName, customerName, color } = item;
  //     const itemList = [];
  //     R.forEach((itemStamp) => {
  //       const findCountItem = R.find((item) => item.dailyTimestamp === itemStamp, countList || []);
  //       if (findCountItem) {
  //         itemList.push(findCountItem);
  //       } else {
  //         const timeStr = moment.utc(itemStamp).format(Defaults.DateFormat);
  //         itemList.push({
  //           projectName,
  //           customerName,
  //           dailyTimestamp: itemStamp,
  //           instanceCount: 0,
  //           metricCount: 0,
  //           color,
  //           timeStr,
  //         });
  //       }
  //     }, timestampList);
  //     tenListMetric.push({ ...item, countList: itemList });
  //   }, topTenmetricList);
  // }
  // topTenmetricList = tenListMetric;
  // if (!isEmpty(otherItemMetric)) topTenmetricList.push(otherItemMetric);

  // 处理 Instance 和 metrics 的 source/series 数据
  let sourceInstancesList = [];
  let sourceMetricsList = [];
  let seriesInstancesList = [];
  let seriesMetricsList = [];
  R.forEach((item) => {
    const { projectName, color, customerName } = item;
    let { countList } = item;
    const instanceList = [];
    const metricList = [];
    const itemList = [];
    let instanceCountTotal = 0;
    let metricCountTotal = 0;

    // 把当前月份的时间戳加入进去
    R.forEach((itemStamp) => {
      const findCountItem = R.find((item) => item.dailyTimestamp === itemStamp, countList || []);
      if (findCountItem) {
        itemList.push(findCountItem);
      } else {
        const timeStr = moment.utc(itemStamp).format(Defaults.DateFormat);
        itemList.push({
          projectName,
          customerName,
          dailyTimestamp: itemStamp,
          instanceCount: 0,
          metricCount: 0,
          color,
          timeStr,
        });
      }
    }, timestampList);

    countList = itemList;

    R.forEach((_item) => {
      const { instanceCount, metricCount } = _item;
      instanceList.push(instanceCount);
      metricList.push(metricCount);
      instanceCountTotal += instanceCount;
      metricCountTotal += metricCount;
    }, countList || []);

    sourceInstancesList.push([projectName, ...instanceList]);
    sourceMetricsList.push([projectName, ...metricList]);
    seriesInstancesList.push({
      type: 'bar',
      seriesLayoutBy: 'row',
      stack: 'total',
      barMaxWidth: 20,
      zlevel: 20,
      // itemStyle: { color },
      instanceCountTotal,
      projectName,
    });
    seriesMetricsList.push({
      type: 'bar',
      xAxisIndex: 1,
      yAxisIndex: 1,
      seriesLayoutBy: 'row',
      stack: 'total1',
      barMaxWidth: 20,
      zlevel: 20,
      // itemStyle: { color },
      metricCountTotal,
      projectName,
    });
  }, handleDataList);

  // 排序
  let sourceInstancesObj = [];
  let sourceMetricsObj = [];
  R.addIndex(R.forEach)((item, index) => {
    let total = 0;
    R.addIndex(R.forEach)((_item, _index) => {
      if (_index > 0) {
        total += _item;
      }
    }, item);
    sourceInstancesObj.push({ list: item, total });
  }, sourceInstancesList);
  sourceInstancesObj = R.sortWith([R.descend(R.prop('total'))], sourceInstancesObj);
  sourceInstancesList = R.map((item) => item.list, sourceInstancesObj);
  R.addIndex(R.forEach)((item, index) => {
    let total = 0;
    R.addIndex(R.forEach)((_item, _index) => {
      if (_index > 0) {
        total += _item;
      }
    }, item);
    sourceMetricsObj.push({ list: item, total });
  }, sourceMetricsList);
  sourceMetricsObj = R.sortWith([R.descend(R.prop('total'))], sourceMetricsObj);
  sourceMetricsList = R.map((item) => item.list, sourceMetricsObj);

  seriesInstancesList = R.sortWith([R.descend(R.prop('instanceCountTotal'))], seriesInstancesList);
  R.forEach((item) => {
    const findItem = R.find((_item) => _item.projectName === item.projectName, handleDataList);
    item.itemStyle = { color: findItem.color };
  }, seriesInstancesList);
  seriesMetricsList = R.sortWith([R.descend(R.prop('metricCountTotal'))], seriesMetricsList);
  R.forEach((item) => {
    const findItem = R.find((_item) => _item.projectName === item.projectName, handleDataList);
    item.itemStyle = { color: findItem.color };
  }, seriesMetricsList);

  return {
    backgroundColor: 'transparent',
    legend: {
      top: 10,
      type: 'scroll',
      padding: [20, 30],
      data: legendData,
      formatter: (name) => {
        return getProjectTitle(name, projectMap, projectToDisplayNameMap);
      },
    },
    tooltip: {
      backgroundColor: 'var(--component-background)',
      borderColor: 'transparent',
      textStyle: {
        color: 'var(--text-color)',
      },
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      enterable: true,
      appendToBody: true,
      position: (pos, params, dom, rect, size) => {
        const isRight = size.viewSize[0] / 2 < pos[0];
        const boxWidth = size.contentSize[0]; // 弹框的height
        const boxHeight = size.contentSize[1]; // 弹框的height
        const pointX = pos[0] - (isRight ? boxWidth : 0);
        return [pointX, pos[1] - boxHeight - 5];
      },
      formatter: (params, ticket, callback) => {
        let viewHtmlList = [];
        R.forEach((item) => {
          const { data, dimensionNames, encode, color } = item;
          const index = (encode?.y || [])[0] || 0;
          const title = (dimensionNames || [])[index] || '';
          if ((data || [])[index] > 0) {
            viewHtmlList.push({
              title: index > seriesInstancesList.length ? title.slice(0, title.length - 2) : title,
              value: (data || [])[index],
              color,
            });
          }
        }, params);
        viewHtmlList = R.sortWith([R.descend(R.prop('value'))], viewHtmlList);

        return ReactDOMServer.renderToStaticMarkup(
          <div style={{ maxHeight: 200, maxWidth: 450, overflow: 'auto', padding: 20 }}>
            <div style={{ fontSize: 14, fontWeight: 'bold' }}>{params[0]?.name}</div>
            {R.addIndex(R.map)((item, index) => {
              const { color, title, value } = item;
              return (
                <div key={index} className="flex-row flex-center-align">
                  <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color, flexShrink: 0 }} />
                  <div style={{ margin: '0 0 0 5px' }} className="hidden-line-with-ellipsis">
                    {getProjectTitle(title, projectMap, projectToDisplayNameMap)}
                  </div>
                  <span style={{ marginRight: 8 }}>:</span>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color, flexShrink: 0 }}>{value}</span>
                </div>
              );
            }, viewHtmlList)}
          </div>,
        );
      },
    },
    dataset: {
      source: [xAxisList, ...sourceInstancesList, ...sourceMetricsList],
    },
    xAxis: [
      {
        type: 'category',
        gridIndex: 0,
        axisLine: { show: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      {
        type: 'category',
        gridIndex: 1,
        axisLine: { show: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
    ],
    yAxis: [
      { gridIndex: 0, axisLine: { show: true }, splitLine: { show: false }, splitArea: { show: false } },
      { gridIndex: 1, axisLine: { show: true }, splitLine: { show: false }, splitArea: { show: false } },
    ],
    grid: [
      { top: '14%', bottom: '50%', left: 40, right: '5%', containLabel: true },
      { top: '60%', bottom: '5%', left: 40, right: '5%', containLabel: true },
    ],
    series: [...seriesInstancesList, ...seriesMetricsList],
  };
};

const getPickLastTimeOption = (list, pickLastTimeValue, timeZoneStr, searchProjectName, projects) => {
  const { nowStartTime, nowEndTime } = getNowTime(pickLastTimeValue, timeZoneStr);
  let data = list || [];

  let projectMap = {};
  const projectToDisplayNameMap = {};

  data = R.map((item) => {
    const { projectName, customerName } = item;
    const projectInfo = R.find((project) => project.projectShortName === projectName, projects || []);
    const projectDisplayName = projectInfo?.projectDisplayName || projectName;
    const projectAndUser = `${projectName}@${customerName}`;

    if (!projectMap[projectName]) {
      projectMap[projectName] = [{ projectName, projectDisplayName, customerName }];
    } else {
      projectMap[projectName] = [...projectMap[projectName], { projectName, projectDisplayName, customerName }];
    }

    if (!projectToDisplayNameMap[projectAndUser]) {
      projectToDisplayNameMap[projectAndUser] = { projectName, projectDisplayName, customerName };
    }
    return { ...item, projectName: projectAndUser, projectDisplayName };
  }, data);

  projectMap = R.mapObjIndexed((val, key) => {
    return R.uniqBy((item) => `${item.projectName}@${item.customerName}`, val || []);
  }, projectMap);

  const regex = getRegExp(searchProjectName);
  data = R.filter((item) => {
    return regex ? regex.test(`${item.projectDisplayName}@${item.customerName}`) : false;
  }, data || []);

  // color值
  const colors = [
    '#ad4c34',
    '#df9954',
    '#744a74',
    '#547ec9',
    '#D37387',
    '#83d4de',
    '#1D7324',
    '#e095e0',
    '#68B78C',
    '#ae9be3',

    '#ee8e76',
    '#7e93b7',
    '#6fc876',
    '#a8d6b7',
    '#d9e446',
    '#ca87ca',
    '#4e6da5',
    '#C35989',
    '#918ADF',
    '#D78742',

    '#B9541A',
    '#E9A133',
    '#3878A1',
    '#A3416E',
    '#F1AEA4',
    '#8BCDBC',
    '#399561',
    '#A4D8A8',
    '#764EA2',
    '#B2ADED',

    '#BC5B22',
    '#EEB46C',
    '#3E769E',
    '#ABC4A2',
    '#3B925D',
    '#B2CB9A',
    '#6565C5',
    '#B4B4F2',
    '#98366D',
    '#DD86AF',

    '#FFC940',
    '#FFEEC5',
    '#96ec9d',
    '#E8F8B6',
    '#D1E1FF',
    '#dab45a',
    '#6B9FA1',
    '#FFE39F',
    '#ddd8cc',
    '#E1BAE1',

    // '#20B2AA'
  ];

  // 过滤为nowStartTime ～ nowEndTime 之间的数据
  R.forEach((item) => {
    let { metricStatsOverviewChunkSet } = item;
    metricStatsOverviewChunkSet = R.filter((_item) => {
      const { timePair } = _item;
      return timePair.s >= nowStartTime && timePair.e <= nowEndTime;
    }, metricStatsOverviewChunkSet || []);
    item.metricStatsOverviewChunkSet = metricStatsOverviewChunkSet;
  }, data);

  // R.forEach((item) => {
  //   let { metricStatsOverviewChunkSet } = item;
  //   metricStatsOverviewChunkSet = R.map(
  //     (_item) => ({ ..._item, s: _item.timePair.s }),
  //     metricStatsOverviewChunkSet || [],
  //   );
  //   item.metricStatsOverviewChunkSet = metricStatsOverviewChunkSet;
  // }, data);
  // R.forEach((item) => {
  //   let { metricStatsOverviewChunkSet } = item;
  //   metricStatsOverviewChunkSet = R.sortWith([R.ascend(R.prop('s'))], metricStatsOverviewChunkSet);
  //   item.metricStatsOverviewChunkSet = metricStatsOverviewChunkSet;
  // }, data);
  // console.log(data, 'data');

  // 获取instances + metrics = total 进行排序
  R.forEach((item) => {
    const { metricStatsOverviewChunkSet } = item;
    let total = 0;
    R.forEach((_item) => {
      const { numberOfInstanceHaveData, numberOfMetricHaveData } = _item;
      total += numberOfInstanceHaveData + numberOfMetricHaveData;
    }, metricStatsOverviewChunkSet || []);
    item.total = total;
  }, data);

  // 把 total = 0 的过滤掉
  data = R.filter((item) => item.total > 0, data);
  // 根据 total 进行排序
  data = R.sortWith([R.descend(R.prop('total'))], data);

  // 获取legend数据，并且设置颜色, 把父对象的数据放入到子对象里
  const legendList = [];
  let legendData = [];
  R.addIndex(R.forEach)((item, index) => {
    const { projectName, customerName } = item;
    let { metricStatsOverviewChunkSet } = item;
    const color = index < colors.length ? colors[index] : '#20B2AA';

    legendList.push({ projectName, color });

    metricStatsOverviewChunkSet = R.map(
      (_item) => ({ ..._item, projectName, customerName, color }),
      metricStatsOverviewChunkSet || [],
    );
    item.color = color;
    item.metricStatsOverviewChunkSet = metricStatsOverviewChunkSet;
  }, data);
  legendData = R.take(10)(R.map((item) => item.projectName, legendList || []));

  // 获取X轴
  const { xAxisLastTime, xAxisLastTimestamp } = getXAxisLastTimeList(pickLastTimeValue, timeZoneStr);

  // 循环时间，在此时间发生的system归类到一起
  const stampDateList = {};
  const stampStrObj = {};
  R.forEach((item) => {
    const { s, e } = item;
    const stampObj = [];
    const timeStr = momenttz(s).tz('UTC').format(Defaults.TimeOnlyFormat);
    R.forEach((_item) => {
      const { metricStatsOverviewChunkSet } = _item;
      const findItem = R.find(
        (findItem) => findItem.timePair.s >= s && findItem.timePair.e <= e,
        metricStatsOverviewChunkSet,
      );
      stampObj.push(findItem);
    }, data);
    stampDateList[s] = stampObj;
    stampStrObj[timeStr] = stampObj;
  }, xAxisLastTimestamp || []);

  const stampStrObjData = {};
  R.forEachObjIndexed((val, key) => {
    let instanceTotal = 0;
    let metricTotal = 0;
    R.forEach((item) => {
      instanceTotal += item?.numberOfInstanceHaveData || 0;
      metricTotal += item?.numberOfMetricHaveData || 0;
    }, val || []);
    stampStrObjData[key] = { instanceTotal, metricTotal };
  }, stampStrObj);

  // 处理当天 top 10
  let hasOther = false;
  const instancesTenData = {};
  const metricsTenData = {};
  const timeStrObj = {};
  R.forEachObjIndexed((val, key) => {
    //  处理instances
    let instancesTenList = [];
    let instancesOtherItem = {};
    let instancesOtherTotal = 0;
    const instancesList = R.sortWith([R.descend(R.prop('numberOfInstanceHaveData'))], val);
    if (instancesList.length > Infinity) {
      hasOther = true;
      instancesTenList = R.take(10)(instancesList);
      R.forEach((item) => {
        instancesOtherTotal += item?.numberOfInstanceHaveData || 0;
      }, R.slice(10, Infinity)(instancesList));
      instancesOtherItem = {
        projectName: 'Other',
        numberOfInstanceHaveData: instancesOtherTotal,
        color: '#20B2AA',
        value: instancesOtherTotal,
      };
    } else {
      instancesTenList = instancesList;
    }
    if (!isEmpty(instancesOtherItem)) instancesTenList.push(instancesOtherItem);
    instancesTenList = R.sortWith([R.descend(R.prop('numberOfInstanceHaveData'))], instancesTenList);
    instancesTenData[key] = instancesTenList;

    // 处理metrics
    let metricsTenList = [];
    let metricsOtherItem = {};
    let metricsOtherTotal = 0;
    const metricsList = R.sortWith([R.descend(R.prop('numberOfMetricHaveData'))], val);
    if (metricsList.length > Infinity) {
      hasOther = true;
      metricsTenList = R.take(10)(metricsList);
      R.forEach((item) => {
        metricsOtherTotal += item?.numberOfMetricHaveData || 0;
      }, R.slice(10, Infinity)(metricsList));
      metricsOtherItem = {
        projectName: 'Other',
        numberOfMetricHaveData: metricsOtherTotal,
        color: '#20B2AA',
        value: metricsOtherTotal,
      };
    } else {
      metricsTenList = metricsList;
    }
    if (!isEmpty(metricsOtherItem)) metricsTenList.push(metricsOtherItem);
    metricsTenList = R.sortWith([R.descend(R.prop('numberOfMetricHaveData'))], metricsTenList);
    metricsTenData[key] = metricsTenList;

    // 获取时间戳字符串范围
    const timeKey = momenttz(Number(key)).tz('UTC').format(Defaults.TimeOnlyFormat);
    const timeEndVal = momenttz(Number(key) + 900000)
      .tz('UTC')
      .format(Defaults.TimeOnlyFormat);
    timeStrObj[timeKey] = `${timeKey} ~ ${timeEndVal}`;
  }, stampDateList);

  if (hasOther) {
    legendData.push('Other');
  }

  // 获取 dataset / series
  const instancesDataset = [];
  const metricsDataset = [];
  const instancesSeries = [];
  const metricsSeries = [];
  R.forEach((item) => {
    // 获取instances dataset
    const instancesList = [];
    R.forEachObjIndexed((instancesVal, instancesKey) => {
      const findVal = R.find((findItem) => findItem?.projectName === item.projectName, instancesVal) || {};
      instancesList.push(findVal?.numberOfInstanceHaveData || 0);
    }, instancesTenData);
    instancesDataset.push([item.projectName, ...instancesList]);
    instancesSeries.push({
      type: 'bar',
      seriesLayoutBy: 'row',
      stack: 'total',
      barMaxWidth: 20,
      zlevel: 20,
      itemStyle: { color: item.color },
    });

    // 获取metrics dataset
    const metricsList = [];
    R.forEachObjIndexed((metricsVal, metricsKey) => {
      const findVal = R.find((findItem) => findItem?.projectName === item.projectName, metricsVal) || {};
      metricsList.push(findVal?.numberOfMetricHaveData || 0);
    }, metricsTenData);
    metricsDataset.push([item.projectName, ...metricsList]);
    metricsSeries.push({
      type: 'bar',
      xAxisIndex: 1,
      yAxisIndex: 1,
      seriesLayoutBy: 'row',
      stack: 'total1',
      barMaxWidth: 20,
      zlevel: 20,
      itemStyle: { color: item.color },
    });
  }, legendList);

  if (instancesSeries[instancesSeries.length - 1]) {
    (instancesSeries[instancesSeries.length - 1] || {}).label = {
      show: true,
      position: 'top',
      formatter: (params) => {
        const { name } = params;
        return stampStrObjData[name]?.instanceTotal < 100 && stampStrObjData[name]?.instanceTotal > 0
          ? stampStrObjData[name]?.instanceTotal
          : '';
      },
    };
  }

  if (metricsSeries[metricsSeries.length - 1]) {
    (metricsSeries[metricsSeries.length - 1] || {}).label = {
      show: true,
      position: 'top',
      formatter: (params) => {
        const { name } = params;
        return stampStrObjData[name]?.metricTotal < 100 && stampStrObjData[name]?.metricTotal > 0
          ? stampStrObjData[name]?.metricTotal
          : '';
      },
    };
  }

  return {
    backgroundColor: 'transparent',
    legend: {
      top: 10,
      type: 'scroll',
      padding: [20, 30],
      data: legendData,
      formatter: (name) => {
        return getProjectTitle(name, projectMap, projectToDisplayNameMap);
      },
    },
    tooltip: {
      backgroundColor: 'var(--component-background)',
      borderColor: 'transparent',
      textStyle: {
        color: 'var(--text-color)',
      },
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      enterable: true,
      appendToBody: true,
      position: (pos, params, dom, rect, size) => {
        const isRight = size.viewSize[0] / 2 < pos[0];
        const boxWidth = size.contentSize[0]; // 弹框的height
        const boxHeight = size.contentSize[1]; // 弹框的height
        const pointX = pos[0] - (isRight ? boxWidth : 0);
        return [pointX, pos[1] - boxHeight - 5];
      },
      formatter: (params, ticket, callback) => {
        let viewHtmlList = [];
        R.forEach((item) => {
          const { data, dimensionNames, encode, color } = item;
          const index = (encode?.y || [])[0] || 0;
          const title = (dimensionNames || [])[index] || '';
          if ((data || [])[index] > 0) {
            viewHtmlList.push({
              title: index > instancesSeries.length ? title.slice(0, title.length - 2) : title,
              value: (data || [])[index],
              color,
            });
          }
        }, params);
        viewHtmlList = R.sortWith([R.descend(R.prop('value'))], viewHtmlList);

        return ReactDOMServer.renderToStaticMarkup(
          <div style={{ maxHeight: 200, maxWidth: 450, overflow: 'auto', padding: 20 }}>
            <div style={{ fontSize: 14, fontWeight: 'bold' }}>{timeStrObj[params[0]?.name]}</div>
            {R.addIndex(R.map)((item, index) => {
              const { color, title, value } = item;
              return (
                <div key={index} className="flex-row flex-center-align">
                  <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color, flexShrink: 0 }} />
                  <div style={{ margin: '0 0 0 5px' }} className="hidden-line-with-ellipsis">
                    {getProjectTitle(title, projectMap, projectToDisplayNameMap)}
                  </div>
                  <span style={{ marginRight: 8 }}>:</span>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color, flexShrink: 0 }}>{value}</span>
                </div>
              );
            }, viewHtmlList)}
          </div>,
        );
      },
    },
    dataset: {
      source: [xAxisLastTime, ...instancesDataset, ...metricsDataset],
    },
    xAxis: [
      {
        type: 'category',
        gridIndex: 0,
        axisLine: { show: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      {
        type: 'category',
        gridIndex: 1,
        axisLine: { show: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
    ],
    yAxis: [
      { gridIndex: 0, axisLine: { show: true }, splitLine: { show: false }, splitArea: { show: false } },
      { gridIndex: 1, axisLine: { show: true }, splitLine: { show: false }, splitArea: { show: false } },
    ],
    grid: [
      { top: '14%', bottom: '50%', left: 40, right: '5%', containLabel: true },
      { top: '60%', bottom: '5%', left: 40, right: '5%', containLabel: true },
    ],
    series: [...instancesSeries, ...metricsSeries],
  };
};

export default function AllSystemsMetricChart({
  width,
  height,
  intl,
  credentials,
  userInfo,
  customerName,
  startMonth,
  endMonth,
  // selectMonth,
  forceRefreshTime,
  location,
  replace,
  pickLastTimeValue,
  currentTheme,
  defaultTimezone,
  setPackLastTimeDisbale,
  projects,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    dataList: [],
    monthStrList: [],
    option: {},
    searchProjectName: '',
  });
  const { loading, dataList, monthStrList, option, searchProjectName } = state;
  const projectTime = useRef(null);
  const monthNameMap = useRef({
    '01': intl.formatMessage(appFieldsMessages.Jan),
    '02': intl.formatMessage(appFieldsMessages.Feb),
    '03': intl.formatMessage(appFieldsMessages.Mar),
    '04': intl.formatMessage(appFieldsMessages.Apr),
    '05': intl.formatMessage(appFieldsMessages.May),
    '06': intl.formatMessage(appFieldsMessages.Jun),
    '07': intl.formatMessage(appFieldsMessages.Jul),
    '08': intl.formatMessage(appFieldsMessages.Aug),
    '09': intl.formatMessage(appFieldsMessages.Sep),
    10: intl.formatMessage(appFieldsMessages.Oct),
    11: intl.formatMessage(appFieldsMessages.Nov),
    12: intl.formatMessage(appFieldsMessages.Dec),
  });

  useEffect(() => {
    const timeZoneStr = momenttz().tz(defaultTimezone).format(Defaults.DateTimeFormat);
    const endTimeZone = moment.utc(timeZoneStr).valueOf();
    setState({ loading: true /* monthStrList: getTimeTabList(startMonth, endMonth) */ });
    setPackLastTimeDisbale(true);
    const startTime = pickLastTimeValue
      ? endTimeZone - pickLastTimeValue
      : moment.utc(startMonth, Defaults.DateFormat).startOf('day').valueOf();
    const endTime = pickLastTimeValue ? endTimeZone : moment.utc(endMonth, Defaults.DateFormat).endOf('day').valueOf();

    fetchGet(getEndpoint('metricoverviewstatus'), {
      ...credentials,
      customerName: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : credentials.userName,
      startTime,
      endTime,
      interval: pickLastTimeValue ? 900000 : undefined,
    })
      .then(async (data) => {
        let option = {};
        if (pickLastTimeValue) {
          option = await getPickLastTimeOption(data || [], pickLastTimeValue, timeZoneStr, searchProjectName, projects);
        } else {
          // option = await getOption(data || [], selectMonth);
          option = await getOption(data || [], startMonth, endMonth, searchProjectName, projects);
        }

        setState({ loading: false, dataList: data || [], option });
        setPackLastTimeDisbale(false);
      })
      .catch((e) => {
        setState({ loading: false });
        setPackLastTimeDisbale(false);
        message.error(e.message || String(e));
      });
  }, [customerName, startMonth, endMonth, forceRefreshTime, currentTheme, pickLastTimeValue]);

  // const handleMonthValueChange = async (selectMonth) => {
  //   setState({ loading: true });
  //   const query = parseLocation(location);
  //   // force refresh
  //   replace(buildLocation(location.pathname, {}, { ...query, selectMonth }));
  //   const option = await getOption(dataList, selectMonth);
  //   setState({ loading: false, option });
  // };

  return (
    <Container className="flex-grow flex-col flex-min-height" style={{ padding: '8px 16px 16px 16px', width, height }}>
      {/* 月份 */}
      {/* {!pickLastTimeValue && (
        <div className="flex-row flex-center-align overflow-x-auto flex-center-justify" style={{ height: 60 }}>
          {R.map((monthStr) => {
            return (
              <div
                key={monthStr}
                style={{
                  cursor: 'pointer',
                  margin: '0 12px',
                  ...(monthStr === selectMonth
                    ? { fontWeight: 'bold', fontSize: 20, borderBottom: '2px solid #ff5142' }
                    : { fontSize: 14 }),
                }}
                onClick={() => handleMonthValueChange(monthStr)}
              >
                {monthNameMap.current[moment.utc(monthStr, Defaults.MonthFormat).format(Defaults.MonthOnlyFormat)]}
              </div>
            );
          }, monthStrList)}
        </div>
      )} */}

      {/* 图表 */}
      <div className="flex-grow health-panel corner-10" style={{ margin: 16, padding: '0px 20px' }}>
        <div style={{ paddingTop: 8, marginLeft: 40 }}>
          <span style={{ marginRight: 8 }}>{intl.formatMessage(eventMessages.projectName)}:</span>
          <Input
            allowClear
            size="small"
            style={{ width: 240 }}
            value={searchProjectName}
            onChange={(e) => {
              const timeZoneStr = momenttz().tz(defaultTimezone).format(Defaults.DateTimeFormat);
              const searchValue = e.target.value;
              if (projectTime.current) clearTimeout(projectTime.current);
              setState({ searchProjectName: searchValue, loading: true });
              projectTime.current = setTimeout(() => {
                let option = {};
                if (pickLastTimeValue) {
                  option = getPickLastTimeOption(dataList, pickLastTimeValue, timeZoneStr, searchValue, projects);
                } else {
                  option = getOption(dataList, startMonth, endMonth, searchValue, projects);
                }
                setState({ option, loading: false });
              }, 600);
            }}
          />
        </div>
        <Spin spinning={loading} wrapperClassName="full-height spin-full-height flex-col flex-min-height">
          <AutoSizer>
            {({ height: echartHeight, width: echartWidth }) => (
              <div style={{ height: echartHeight, width: echartWidth, position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top:
                      echartHeight < 600 ? (echartHeight < 500 ? (echartHeight < 430 ? '12%' : '15%') : '18%') : '21%',
                    left: '0%',
                    fontSize: 14,
                    fontWeight: 'bold',
                    writingMode: 'vertical-rl',
                    transform: 'rotate(180deg)',
                  }}
                >
                  {loading ? '' : 'Number of instances'}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top:
                      echartHeight < 600 ? (echartHeight < 500 ? (echartHeight < 430 ? '61%' : '63%') : '65%') : '67%',
                    left: '0%',
                    fontSize: 14,
                    fontWeight: 'bold',
                    writingMode: 'vertical-rl',
                    transform: 'rotate(180deg)',
                  }}
                >
                  {loading ? '' : 'Number of metrics'}
                </div>
                {pickLastTimeValue && (
                  <>
                    <div
                      className="hidden-line-with-ellipsis"
                      style={{
                        position: 'absolute',
                        top:
                          echartHeight < 600
                            ? echartHeight < 500
                              ? echartHeight < 430
                                ? '46.1%'
                                : '46.6%'
                              : '47.1%'
                            : '48%',
                        left: '-1%',
                        fontWeight: 'bold',
                        width: 80,
                        textAlign: 'center',
                      }}
                    >
                      {loading ? '' : defaultTimezone}
                    </div>
                    <div
                      className="hidden-line-with-ellipsis"
                      style={{
                        position: 'absolute',
                        top:
                          echartHeight < 600
                            ? echartHeight < 500
                              ? echartHeight < 430
                                ? '91.1%'
                                : '91.6%'
                              : '92.1%'
                            : '93%',
                        left: '-1%',
                        fontWeight: 'bold',
                        width: 80,
                        textAlign: 'center',
                      }}
                    >
                      {loading ? '' : defaultTimezone}
                    </div>
                  </>
                )}

                <EChart width={echartWidth} height={echartHeight} option={option} theme={currentTheme} />
              </div>
            )}
          </AutoSizer>
        </Spin>
      </div>
    </Container>
  );
}
