import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Alert, Button, Form, Input, Select } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Container } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

class EndpointSettingAzureCost extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoadingKey: false,
      updateLoading: false,
      errorMessageKey: '',

      secondsUnit: 86400,
      millisecondUnit: 3600000,

      tenantId: '',
      billingAccountId: '',
      clientId: '',
      clientSecret: '',
      samplingInterval: 0,
      collectionFrequency: 0,
    };

    this.secondsUnitOption = [{ label: 'day', value: 86400 }];
    this.millisecondUnitOption = [{ label: 'hour', value: 3600000 }];
  }

  componentDidMount() {
    this.reloadKey(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadKey(nextProps);
    }
  }

  @autobind
  reloadKey(props) {
    const { credentials, projectName, currentProject } = props;
    const { secondsUnit, millisecondUnit } = this.state;
    this.setState({ isLoadingKey: true });
    const cloudType = get(currentProject, ['cloudType']);
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const {
          success,
          message,
          tenantId,
          billingAccountId,
          clientId,
          clientSecret,
          samplingInterval,
          collectionFrequency,
        } = data;
        if (success === undefined || success) {
          this.billingAccountId = billingAccountId;
          this.tenantId = tenantId;
          this.clientId = clientId;
          this.clientSecret = clientSecret;
          this.samplingInterval = Number(samplingInterval) / secondsUnit;
          this.collectionFrequency = Number(collectionFrequency) / millisecondUnit;
          this.setState({
            isLoadingKey: false,
            errorMessageKey: undefined,

            tenantId: tenantId || '',
            billingAccountId: billingAccountId || '',
            clientId: clientId || '',
            clientSecret: clientSecret || '',
            samplingInterval: Number(samplingInterval) / secondsUnit,
            collectionFrequency: Number(collectionFrequency) / millisecondUnit,
          });
        } else {
          this.setState({
            isLoadingKey: false,
            errorMessageKey: message,
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoadingKey: false,
          errorMessageKey: errMsg || String(err),
        });
      });
  }

  @autobind
  handleUpdateKey() {
    const { credentials, projectName } = this.props;
    const {
      tenantId,
      billingAccountId,
      clientId,
      clientSecret,
      samplingInterval,
      collectionFrequency,
      secondsUnit,
      millisecondUnit,
    } = this.state;
    this.setState({ updateLoading: true });
    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      tenantId,
      billingAccountId,
      clientId,
      clientSecret,
      samplingInterval: Number(samplingInterval) * secondsUnit,
      collectionFrequency: Number(collectionFrequency) * millisecondUnit,
    })
      .then((data) => {
        const { success, message } = data;
        if (success === undefined || success) {
          this.setState({ isLoadingKey: false, errorMessageKey: undefined, updateLoading: false });
          this.reloadKey(this.props);
        } else {
          this.setState({
            isLoadingKey: false,
            updateLoading: false,
            errorMessageKey: message,
          });
        }
      })
      .catch((err) => {
        this.setState({ updateLoading: false });
        const { message: errMsg } = err || {};
        this.setState({ isLoadingKey: false, errorMessageKey: errMsg || String(err) });
      });
  }

  render() {
    const { intl } = this.props;
    const {
      tenantId,
      billingAccountId,
      clientId,
      clientSecret,
      samplingInterval,
      collectionFrequency,
      isLoadingKey,
      errorMessageKey,
      secondsUnit,
      millisecondUnit,
      updateLoading,
    } = this.state;
    const hasErrKey =
      !tenantId || !billingAccountId || !clientId || !clientSecret || !samplingInterval || !collectionFrequency;
    const hasChangeKey =
      this.tenantId !== tenantId ||
      this.billingAccountId !== billingAccountId ||
      this.clientId !== clientId ||
      this.clientSecret !== clientSecret ||
      this.samplingInterval !== samplingInterval ||
      this.collectionFrequency !== collectionFrequency;

    return (
      <Container fullHeight className={`overflow-y-auto ${isLoadingKey ? ' loading' : ''}`}>
        <div className="full-height" style={{ width: 800 }}>
          <Form layout="vertical">
            <Form.Item
              label="Tenant ID"
              validateStatus={!tenantId ? 'error' : 'success'}
              help={!tenantId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={tenantId}
                onChange={(e) => this.setState({ tenantId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client ID"
              validateStatus={!clientId ? 'error' : 'success'}
              help={!clientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={clientId}
                onChange={(e) => this.setState({ clientId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client Secret"
              validateStatus={!clientSecret ? 'error' : 'success'}
              help={!clientSecret ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={clientSecret}
                onChange={(e) => this.setState({ clientSecret: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Billing Account ID"
              validateStatus={!billingAccountId ? 'error' : 'success'}
              help={!billingAccountId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={billingAccountId}
                onChange={(e) => this.setState({ billingAccountId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.secondsUnitOption}
                  value={secondsUnit}
                  onChange={(secondsUnit) => this.setState({ secondsUnit })}
                />
              </div>
            </Form.Item>
            <Form.Item
              label="Collection Frequency"
              validateStatus={!collectionFrequency ? 'error' : 'success'}
              help={!collectionFrequency ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={collectionFrequency}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ collectionFrequency: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.millisecondUnitOption}
                  value={millisecondUnit}
                  onChange={(millisecondUnit) => this.setState({ millisecondUnit })}
                />
              </div>
            </Form.Item>

            {errorMessageKey && (
              <Alert message="Error" description={errorMessageKey} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasErrKey || !hasChangeKey}
                loading={updateLoading}
                onClick={this.handleUpdateKey}
              >
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    );
  }
}

const EndpointSettingAzureCostCloud = injectIntl(EndpointSettingAzureCost);
export default connect((state) => {
  return {};
}, {})(EndpointSettingAzureCostCloud);
