/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { message, Alert, Form, Input, Spin, Select, AutoComplete } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { Regex } from '../../../common/utils';

type Props = {
  activeIncident: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  updateLastActionInfo: Function,

  isConnect: Boolean,
};

class AddMSTeamsNameModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { systemsMap } = props;
    let systemList = R.filter((system) => !system.isShared, R.values(systemsMap));
    systemList = R.sortWith([R.ascend(R.prop('systemName'))], systemList);

    const activeIncident = props.activeIncident || {};

    this.state = {
      isLoading: false,
      isSubmiting: false,
      errMsg: null,

      customerName: activeIncident.owner || null,
      systemId: activeIncident.systemId || null,
      microsoftTeamName: activeIncident.microsoftTeamName || '',
      predicted: activeIncident.predicted || '',
      detected: activeIncident.detected || '',
      newAlert: activeIncident.newAlert || '',
      webhook: activeIncident.webhook || '',
      email: activeIncident.email || '',
      channelNameList: [],
      emailList: [],
    };
    this.systemList = systemList || [];
  }

  componentDidMount() {
    const { isConnect } = this.props;
    if (!isConnect) {
      this.hasChannelNameList();
    } else {
      this.getEmailList(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  hasChannelNameList() {
    const { intl, credentials } = this.props;
    const { microsoftTeamName, customerName, systemId } = this.state;
    fetchGet(getEndpoint('teams-list-channels'), {
      ...credentials,
      teamName: microsoftTeamName,
      customerName,
      systemName: systemId,
      environmentName: 'All',
    })
      .then((res) => {
        res = R.map((item) => ({ value: item.channelName }), res || []);
        this.setState({ errMsg: null, channelNameList: res });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ errMsg: String(err) });
      });
  }

  @autobind
  getEmailList(props) {
    const { systemsMap, credentials, intl } = props;
    const { systemId } = this.state;
    const systemInfo = systemsMap[systemId] || {};
    const localShareUserList = R.concat(
      [{ userName: systemInfo.owner }],
      R.map((user) => {
        return { userName: user };
      }, get(systemInfo, 'shareUserSet', [])),
    );
    fetchGet(getEndpoint('customer', 2), {
      ...credentials,
    })
      .then((data) => {
        let emailList = [];
        R.addIndex(R.map)((item, index) => {
          if (typeof item.userName === 'string' && item.userName) {
            R.forEach((userItem) => {
              if (item.userName === userItem.userName) {
                userItem.value = Regex.email.test(userItem.email)
                  ? userItem.email
                  : Regex.email.test(item.userName)
                  ? item.userName
                  : Regex.email.test(`${item.userName}@qq.com`)
                  ? item.userName
                  : '';
                emailList.push({ value: userItem.value });
              }
            }, R.sortWith([R.ascend(R.prop('userName'))], data || []));
          }
        }, localShareUserList);
        emailList = R.uniqWith(
          R.eqBy(R.prop('value')),
          R.filter((filterItem) => filterItem.value, emailList),
        );
        this.setState({ emailList });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  }

  @autobind
  handleSumbit() {
    const { intl, credentials, onClose } = this.props;
    const { customerName, systemId, microsoftTeamName, webhook, email } = this.state;

    this.setState({ isSubmiting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('authenticate-teams'), {
      ...credentials,
      environmentName: 'All',
      customerName,
      systemName: systemId,
      teamName: microsoftTeamName,
      webhook,
      email,
    })
      .then((data) => {
        const { success, message: errMsg, redirectUrl } = data || {};
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmiting: false, errMsg: null });
          window.open(redirectUrl, '_blank');
          onClose(true, false);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  @autobind
  handleUpdate() {
    const { intl, credentials, onClose } = this.props;
    const { customerName, systemId, microsoftTeamName, predicted, detected, newAlert } = this.state;
    this.setState({ isSubmiting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('teams-init-channels'), {
      ...credentials,
      environmentName: 'All',
      customerName,
      systemName: systemId,
      teamName: microsoftTeamName,
      predicted,
      detected,
      newAlert,
    })
      .then((data) => {
        const {
          success,
          message: errMsg,
          predictedInitializationErrorCode,
          detectedInitializationErrorCode,
          newAlertInitializationErrorCode,
          errorCode,
        } = data || {};
        if (success === undefined || success) {
          if (
            [
              predictedInitializationErrorCode,
              detectedInitializationErrorCode,
              newAlertInitializationErrorCode,
            ].includes('NO_MEMBERS')
          ) {
            this.setState({
              isSubmiting: false,
              errMsg:
                'There were no members in the team that we knew about or could add to the new channel so we did not make a new channel. The user should make sure the team they are using is correct and has members and maybe pre-configure the channel in teams prior to linking here.',
            });
          }
          if (
            [
              predictedInitializationErrorCode,
              detectedInitializationErrorCode,
              newAlertInitializationErrorCode,
            ].includes('FAILED_TO_CREATE_CHANNEL')
          ) {
            this.setState({
              isSubmiting: false,
              errMsg:
                "We didn't create the channel although we thought we had a valid request. The response we got from Microsoft indicated that they did not make a channel.",
            });
          }
          if (
            [
              predictedInitializationErrorCode,
              detectedInitializationErrorCode,
              newAlertInitializationErrorCode,
            ].includes('NO_TOKEN')
          ) {
            this.setState({
              isSubmiting: false,
              errMsg:
                'The back-end could not retrieve a required application permissions access token needed to make the channel. Probably a permissions issue - the user should go back through the Connect process again and possibly check the global system Microsoft teams integration config.',
            });
          }
          if (errorCode === 'NEEDS_RECONNECT') {
            this.setState({
              isSubmiting: false,
              errMsg:
                'We don’t have the user’s delegate permissions token or it is invalid. The user needs to click the ‘Connect’ button again and go through the Microsoft auth flow or we won’t be able to send a message to the incident channels they configured.',
            });
          }
          if (
            !predictedInitializationErrorCode &&
            !detectedInitializationErrorCode &&
            !newAlertInitializationErrorCode &&
            !errorCode
          ) {
            message.success(intl.formatMessage(appMessages.apiSuccess));
            this.setState({ isSubmiting: false, errMsg: null });
            onClose(true, false);
          }
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  render() {
    const { intl, onClose, isConnect } = this.props;
    const {
      isLoading,
      isSubmiting,
      errMsg,
      customerName,
      systemId,
      microsoftTeamName,
      predicted,
      detected,
      newAlert,
      email,
      emailList,
      channelNameList,
    } = this.state;
    const hasError = !customerName || !systemId || !microsoftTeamName || isConnect ? !email : false;
    return (
      <Modal
        width={850}
        title="Get permission to retrieve messages from teams"
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={isConnect ? this.handleSumbit : this.handleUpdate}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
      >
        <Spin spinning={isLoading} wrapperClassName="spin-full-height full-width full-height">
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            {errMsg && (
              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Alert message={errMsg} type="error" showIcon />
              </Form.Item>
            )}

            <Form.Item
              label="System Name"
              validateStatus={!systemId ? 'error' : 'success'}
              help={!systemId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                disabled
                showSearch
                filterOption
                optionFilterProp="value"
                value={systemId}
                onChange={(systemId) => this.setState({ systemId })}
              >
                {R.map(
                  (system) => (
                    <Select.Option key={system.systemId} value={system.systemId}>
                      {system.systemName}
                    </Select.Option>
                  ),
                  this.systemList,
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Team Name"
              validateStatus={!microsoftTeamName ? 'error' : 'success'}
              help={!microsoftTeamName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input
                value={microsoftTeamName}
                onChange={(e) => this.setState({ microsoftTeamName: e.target.value })}
                disabled={!isConnect}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              validateStatus={isConnect ? (!email ? 'error' : 'success') : null}
              help={isConnect ? (!email ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined) : undefined}
              required={isConnect}
            >
              <AutoComplete
                allowClear
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                options={emailList}
                value={email}
                onChange={(email) => this.setState({ email })}
                disabled={!isConnect}
              />
            </Form.Item>
            {!isConnect && (
              <>
                <Form.Item label="Predicted Incident Channel Name">
                  <AutoComplete
                    allowClear
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    options={channelNameList}
                    value={predicted}
                    onChange={(predicted) => this.setState({ predicted })}
                  />
                </Form.Item>
                <Form.Item label="Detected Incident Channel Name">
                  <AutoComplete
                    allowClear
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    options={channelNameList}
                    value={detected}
                    onChange={(detected) => this.setState({ detected })}
                  />
                </Form.Item>
                <Form.Item label="New Pattern Alert Channel Name">
                  <AutoComplete
                    allowClear
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    options={channelNameList}
                    value={newAlert}
                    onChange={(newAlert) => this.setState({ newAlert })}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const AddMSTeamsNameModal = injectIntl(AddMSTeamsNameModalCore);
export default connect(
  (state) => {
    const { userInfo, credentials } = state.auth;
    const { systemsMap, projects } = state.app;
    return { userInfo, credentials, systemsMap, projects };
  },
  { updateLastActionInfo },
)(AddMSTeamsNameModal);
