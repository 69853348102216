/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
**/

import appStartLogic from './appStartLogic';
import loadProjectInfoLogic from './loadProjectInfoLogic';
import loadInfoGlobalLogic from './loadInfoGlobalLogic';
import loadInfoSystemLogic from './loadInfoSystemLogic';
import saveCopyProjectLogic from './saveCopyProjectLogic';
import resetLastActionLogic from './resetLastActionLogic';

export default [
  appStartLogic,
  loadProjectInfoLogic,
  loadInfoGlobalLogic,
  loadInfoSystemLogic,
  saveCopyProjectLogic,
  resetLastActionLogic,
];
