/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import * as R from 'ramda';
import { get } from 'lodash';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getGlobalHealthScores } from '../../apis';
import { appMessages } from '../../app/messages';

const loadGlobalSystemHealthChartLogic = createLogic({
  type: [ActionTypes.LOAD_GLOBAL_SYSTEM_HEALTH_CHART],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { isAdmin } = state.auth.userInfo;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { isAutoReload, environmentId, customerName, systemId } = params;
    const { globalInfo } = state.app;

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const environmentName = get(environment, 'name');
    const ownerUserName = get(environment, 'ownerUserName');
    const systemInfoMap = {};
    R.forEach((system) => {
      systemInfoMap[system.id] = system.systemInfo || {};
    }, get(environment, 'systemList', []));

    dispatch(showLoading);
    if (!isAutoReload) dispatch(updateLastActionInfo());
    getGlobalHealthScores(credentials, {
      ...params,
      customerName: ownerUserName || customerName,
      environment,
      environmentName,
      systemNames: [systemId],
      systemInfoMap,
      isAdmin,
    })
      .then((data) => {
        const { globalHealthSummary } = data;
        dispatch(
          createSetAction(ActionTypes.SET_GLOBAL_SYSTEM_HEALTH_CHART, params, {
            globalSystemHealth: globalHealthSummary,
          }),
        );
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get global system health', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadGlobalSystemHealthChartLogic;
