/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const loadProjectEpisodeWord = (credentials: Credentials, projectName: String) => {
  return fetchGet(getEndpoint('logAnalysis'), {
    ...credentials,
    projectName,
    operation: 'readonly',
    isExistentIncident: true,
  }).then((d) => {
    const rawData = d.data;
    const episodes = rawData.episodeMapArr || [];
    const words = R.map((w) => ({ ...w, isCritical: false }), rawData.wordCountArr || []);

    const selectedKeywords = R.sort(
      (a, b) => a.name.localeCompare(b.name),
      R.map(
        (w) => ({
          ...w,
          id: w.index,
          name: w.pattern,
        }),
        R.filter((w) => w.selected, words),
      ),
    );

    const data = {
      episodes,
      words,
      selectedKeywords,
    };

    return {
      rawData,
      data,
    };
  });
};

export default loadProjectEpisodeWord;
