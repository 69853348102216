import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { isArray, isObject } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Alert, Button, DatePicker, Layout, Select, Spin, Tabs, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import { State } from '../../common/types';
import { Defaults, parseJSON, parseLocation, timeScopeControl } from '../../common/utils';
import { Container } from '../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMenusMessages, appMessages } from '../../common/app/messages';
import { eventMessages } from '../../common/metric/messages';
import { DashboardMessages } from '../../common/dashboard/messages';

import EmbedRootcausetimeline from './components/EmbedRootcausetimeline';
import LikelyRootCausesRCA from './components/LikelyRootCausesRCA';

type Props = {
  intl: Object,
  location: Object,
  currentTheme: String,
};

class EmbedSystemRootCauseCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl, location } = props;
    const query = parseLocation(location);
    const { systemId, tenant } = query;
    const zoneName = !tenant || tenant === `all_zone_${systemId}` ? '__all__' : tenant;

    this.state = {
      isLoading: false,
      startTimeObj: null,
      endTimeObj: null,

      systemInfo: {},
      userInfo: {},
      selectedZone: zoneName,
      forceRefreshTime: undefined,

      isInvalidParams: false,
      endTimeOpen: false,
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      activeKey: 'incident',
      jumpBySelfTimeKey: +new Date(),

      showEventsRCA: false,
      needRC: false,
      needPT: false,
      activeEvent: null,
      rcaFlag: true,

      eventCategory: undefined,
      eventPatternType: undefined,
      eventPatternId: undefined,
      eventProjectName: undefined,
      eventInstanceName: undefined,
      eventTimestamp: undefined,
    };

    this.tabList = [
      { name: 'incident', title: intl.formatMessage(eventMessages.incidents) },
      { name: 'logAlert', title: intl.formatMessage(eventMessages.alertAnomaly) },
      { name: 'metric', title: intl.formatMessage(DashboardMessages.metricAnomalies) },
      { name: 'logAnomaly', title: intl.formatMessage(DashboardMessages.logAnomalies) },
      { name: 'trace', title: intl.formatMessage(DashboardMessages.traceAnomalies) },
      { name: 'deployment', title: intl.formatMessage(appFieldsMessages.changes) },
    ];
  }

  componentDidMount() {
    const { intl } = this.props;
    moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    this.reloadAll(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.systemId !== query.systemId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.zoneName !== query.zoneName
    ) {
      this.reloadAll(nextProps);
    }
  }

  @autobind
  handleRefreshClick() {
    this.setState({ forceRefreshTime: moment.utc().valueOf() });
  }

  @autobind
  getZoneOptions(zoneSet, systemId) {
    let zoneOptions = R.filter((x) => x !== `zone_${systemId}`, zoneSet).map((item) => ({
      value: item,
      label: item,
    }));
    zoneOptions = [
      { value: '__all__', label: 'All zones' },
      {
        value: `zone_${systemId}`,
        label: 'No zones',
      },
      ...zoneOptions,
    ];
    return zoneOptions;
  }

  @autobind
  reloadAll(props) {
    const { location } = props;
    const query = parseLocation(location);
    const { systemId, customerName, tenant, jwtToken } = query;
    if (!systemId || !customerName || !jwtToken) {
      this.setState({ isInvalidParams: true });
      return;
    }
    const zoneName = tenant || `all_zone_${systemId}`;

    const { startTime, endTime } = query;
    const startTimestamp = moment
      .utc(startTime || moment.utc(), Defaults.DateFormat)
      .startOf('day')
      .valueOf();
    const endTimestamp = moment
      .utc(endTime || moment.utc(), Defaults.DateFormat)
      .endOf('day')
      .valueOf();

    this.setState({ isLoading: true }, () => {
      fetchGet(getEndpoint('systemframework-jwt', 2), {
        systemName: systemId,
        customerName,
        zoneName,
        jwt: jwtToken,
        startTime: startTimestamp,
        endTime: endTimestamp,
      })
        .then((data) => {
          let { ownSystemArr, shareSystemArr } = data;
          ownSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system };
          }, ownSystemArr || []);
          shareSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system, isShared: true };
          }, shareSystemArr || []);
          const systemsMap = {};
          R.forEach(
            (system) => {
              const { systemDisplayName, systemKey, projectDetailsList, basicInfoArr = [], isShared, ...rest } = system;
              const { environmentName, systemName: systemId, userName } = systemKey || {};
              let zoneSet = [];
              if (basicInfoArr.length > 0) {
                const basicInfo = parseJSON(basicInfoArr[0]?.basicInfo) || {};
                const zoneSetJson = parseJSON(basicInfo?.zoneSet);
                if (isArray(zoneSetJson)) {
                  zoneSet = zoneSetJson;
                } else if (isObject(zoneSetJson)) {
                  zoneSet = R.keys(zoneSetJson);
                }
              }
              const zoneOptions = this.getZoneOptions(zoneSet, systemId);

              let newProjects = parseJSON(projectDetailsList) || [];
              newProjects = R.map((item) => {
                const { userName, projectClassType, ...restItem } = item;
                return {
                  ...restItem,
                  isShared,
                  customerName: userName,
                  projectType: projectClassType,
                };
              }, newProjects);
              if (!systemsMap[systemId]) {
                systemsMap[systemId] = {
                  ...rest,
                  isShared,
                  environmentName,
                  systemId,
                  systemName: systemDisplayName || systemId,
                  owner: userName,
                  projectDetailsList: newProjects,
                  zoneOptions,
                };
              }
            },
            [...ownSystemArr, ...shareSystemArr],
          );

          const systemInfo = systemsMap?.[systemId] || {};
          const timezone = systemInfo?.timezone || 'UTC';
          const zone = momenttz.tz(timezone);
          const timezoneOffset = zone.utcOffset();
          const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;

          let { startTime, endTime } = query;
          if (!startTime) {
            startTime = moment.utc(nowTimestamp).format(Defaults.DateFormat);
          }
          if (!endTime) {
            endTime = moment.utc(nowTimestamp).format(Defaults.DateFormat);
          }

          const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
          const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

          const userInfo = { userName: customerName, timezoneOffset };
          const startTimeObj = moment.utc(startTimestamp);
          const endTimeObj = moment.utc(endTimestamp);
          this.setState({ startTimeObj, endTimeObj, systemInfo, userInfo, isLoading: false }, () => {
            this.handleRefreshClick();
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false, isInvalidParams: false });
        });
    });
  }

  @autobind
  handleStartOpenChange(open) {
    if (!open) {
      this.setState({ endTimeOpen: true });
    }
  }

  @autobind
  handleEndOpenChange(open) {
    this.setState({ endTimeOpen: open });
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const { endTimeObj } = this.state;
    const startTimeObj = moment.utc(timeObj.valueOf());
    const timeChange = startTimeObj.format(Defaults.DateFormat) !== this.state.startTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    disableRefresh = false;
    this.setState({
      startTimeObj,
      endTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'add'),
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
    });
    if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
  }

  @autobind
  handleEndTimeChange(timeObj) {
    if (!timeObj) return;
    const { startTimeObj } = this.state;
    const endTimeObj = moment.utc(timeObj.valueOf());
    const timeChange = endTimeObj.format(Defaults.DateFormat) !== this.state.endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    disableRefresh = false;

    this.setState({
      startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
      endTimeObj,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
    });

    if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
  }

  @autobind
  onChangeFilterZone(zone) {
    this.setState({ selectedZone: zone });
  }

  @autobind
  handleJumpBySelf({
    startTimeObj,
    endTimeObj,
    eventCategory,
    eventPatternType,
    eventPatternId,
    eventProjectName,
    eventInstanceName,
    eventTimestamp,
  }) {
    let activeKey = 'incident';
    if (R.toLower(eventCategory) === 'incident') {
      activeKey = 'incident';
    } else if (R.toLower(eventCategory) === 'metric') {
      activeKey = 'metric';
    } else if (R.toLower(eventCategory) === 'deployment') {
      activeKey = 'deployment';
    } else if (R.toLower(eventCategory) === 'trace') {
      activeKey = 'trace';
    } else if (R.toLower(eventCategory) === 'logalert') {
      activeKey = 'logAlert';
    } else {
      activeKey = 'logAnomaly';
    }

    this.setState({
      startTimeObj,
      endTimeObj,
      activeKey,
      jumpBySelfTimeKey: +new Date(),

      eventCategory,
      eventPatternType,
      eventPatternId,
      eventProjectName,
      eventInstanceName,
      eventTimestamp,
    });
  }

  @autobind
  clearJumpBySelf() {
    this.setState({
      eventCategory: undefined,
      eventPatternType: undefined,
      eventPatternId: undefined,
      eventProjectName: undefined,
      eventInstanceName: undefined,
      eventTimestamp: undefined,
    });
  }

  render() {
    const { intl, location, currentTheme } = this.props;
    const { isInvalidParams } = this.state;
    const { isLoading, startTimeObj, endTimeObj } = this.state;
    const { endTimeOpen, tooltipVisibleReloadMouseOver, disableRefresh, timeChange, tooltipVisibleReload } = this.state;
    const { systemInfo, userInfo, selectedZone, activeKey, forceRefreshTime } = this.state;
    const { showEventsRCA, needRC, needPT, activeEvent, rcaFlag, jumpBySelfTimeKey } = this.state;
    const { eventCategory, eventPatternType, eventPatternId, eventProjectName, eventInstanceName, eventTimestamp } =
      this.state;
    const query = parseLocation(location);
    const { tenant, systemId, jwtToken, environmentId } = query;
    const showZoneOptions = !tenant || tenant === `all_zone_${systemId}`;
    const zoneOptions = systemInfo?.zoneOptions || [];

    let projectNameRCA;
    if (activeEvent) {
      const { projectOwner } = activeEvent;
      projectNameRCA =
        userInfo.userName !== projectOwner ? `${activeEvent.projectName}@${projectOwner}` : activeEvent.projectName;
    }

    return (
      <Layout className="app-centric-page" style={{ minHeight: '100vh' }}>
        <Container
          fullHeight
          withGutter
          className={`${showEventsRCA ? 'display-none' : 'flex-col'} corner-10`}
          style={{ borderRadius: 10 }}
        >
          <div className="flex-row flex-space-between" style={{ margin: '8px 16px 16px 16px', alignItems: 'end' }}>
            <div className="flex-grow" />
            {showZoneOptions && false && (
              <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                <span style={{ fontWeight: 700, padding: '0 1em' }}>Zone</span>
                <Select
                  showArrow={false}
                  showSearch
                  size="small"
                  style={{ width: 360, marginLeft: 8 }}
                  optionFilterProp="children"
                  value={selectedZone}
                  onChange={this.onChangeFilterZone}
                  filterOption={(input, option) => {
                    return option && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  dropdownMatchSelectWidth={false}
                >
                  {zoneOptions.map((item) => (
                    <Select.Option key={item.value} title={item.label}>
                      <div className="flex-row">
                        <span style={{ width: 180 }}>{item.label}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            <div className="flex-row flex-end-justify flex-center-align" style={{ fontSize: 12 }}>
              {startTimeObj && endTimeObj && (
                <>
                  <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                    <span style={{ fontWeight: 700, padding: '0 1em' }}>
                      {intl.formatMessage(appFieldsMessages.startDate)}
                    </span>
                    <DatePicker
                      size="small"
                      allowClear={false}
                      showToday
                      value={startTimeObj}
                      disabledDate={(current) => {
                        return current && current > moment.utc().add(1, 'days').endOf('day');
                      }}
                      onChange={this.handleStartTimeChange}
                      onOpenChange={this.handleStartOpenChange}
                    />
                  </div>
                  <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                    <span style={{ fontWeight: 700, padding: '0 1em' }}>
                      {intl.formatMessage(appFieldsMessages.endDate)}
                    </span>
                    <DatePicker
                      size="small"
                      allowClear={false}
                      showToday
                      value={endTimeObj}
                      disabledDate={(current) => {
                        return current && current > moment.utc().add(1, 'days').endOf('day');
                      }}
                      onChange={this.handleEndTimeChange}
                      open={endTimeOpen}
                      onOpenChange={this.handleEndOpenChange}
                    />
                  </div>
                </>
              )}
              <div
                className="header-operation"
                onMouseEnter={() => {
                  if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                    this.setState({ tooltipVisibleReloadMouseOver: true });
                }}
                onMouseLeave={() => {
                  if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
                }}
              >
                <Tooltip
                  mouseEnterDelay={0.3}
                  placement="bottomRight"
                  visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                  title={
                    disableRefresh
                      ? 'Range of days <= 31'
                      : timeChange
                      ? intl.formatMessage(appMessages.clickToReload)
                      : null
                  }
                >
                  <Button
                    icon={<ReloadOutlined />}
                    size="small"
                    disabled={disableRefresh}
                    onClick={this.handleRefreshClick}
                    style={{ marginLeft: 8 }}
                  >
                    {intl.formatMessage(appButtonsMessages.refresh)}
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
          <Container
            fullHeight
            className="flex-grow flex-col flex-min-height content-bg corner-10"
            style={{ padding: 8, margin: '0px 16px 8px' }}
          >
            {isInvalidParams && (
              <Alert message="Warning" description="Missing or invalid parameters" type="warning" showIcon />
            )}
            {!isInvalidParams && startTimeObj && endTimeObj && (
              <Spin
                spinning={isLoading}
                wrapperClassName="full-height overflow-x-hidden overflow-y-auto full-width spin-base"
              >
                <Tabs
                  key={jumpBySelfTimeKey}
                  type="card"
                  className="full-width full-height ant-tabs-content-full-height"
                  activeKey={activeKey}
                  onChange={(activeKey) => this.setState({ activeKey })}
                >
                  {R.map((item) => {
                    const { name: tabName, title } = item;
                    return (
                      <Tabs.TabPane tab={title} key={tabName}>
                        <EmbedRootcausetimeline
                          tabName={tabName}
                          userInfo={userInfo}
                          systemInfo={systemInfo}
                          jwtToken={jwtToken}
                          systemId={systemId}
                          activeKey={activeKey}
                          startTimeObj={startTimeObj}
                          endTimeObj={endTimeObj}
                          selectedZone={selectedZone}
                          environmentName={environmentId}
                          forceRefreshTime={forceRefreshTime}
                          handleChangeRCAModal={({ activeEvent, needRC, needPT, handleCallBack }) => {
                            this.handleCallBack = handleCallBack;
                            this.setState({ showEventsRCA: true, activeEvent, needRC, needPT });
                          }}
                          handleJumpBySelf={this.handleJumpBySelf}
                          clearJumpBySelf={this.clearJumpBySelf}
                          eventCategory={eventCategory}
                          eventPatternType={eventPatternType}
                          eventPatternId={eventPatternId}
                          eventProjectName={eventProjectName}
                          eventInstanceName={eventInstanceName}
                          eventTimestamp={eventTimestamp}
                        />
                      </Tabs.TabPane>
                    );
                  }, this.tabList)}
                </Tabs>
              </Spin>
            )}
          </Container>
        </Container>

        {showEventsRCA && (
          <div className="flex-col full-height">
            <Container breadcrumb className="flex-row" style={{ marginBottom: 0 }}>
              <div className="flex-row flex-center-align" style={{ width: 'fit-content' }}>
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => {
                    if (this.handleCallBack) this.handleCallBack(activeEvent);
                    this.setState({
                      showEventsRCA: false,
                      activeEvent: null,
                      needRC: false,
                      needPT: false,
                      rcaFlag: true,
                    });
                    this.handleCallBack = undefined;
                  }}
                >
                  {intl.formatMessage(appMenusMessages.globalSystemRootCause)}
                </Button>
                <div style={{ marginLeft: 4 }}>{`/ ${intl.formatMessage(
                  needPT ? eventMessages.trailingEventsDetails : eventMessages.rootCauseDetails,
                )}`}</div>
              </div>
            </Container>
            <div
              className="flex-grow flex-min-height flex-col content-bg flex-min-width corner-10"
              style={{ margin: '0 16px', padding: 8 }}
            >
              <LikelyRootCausesRCA
                incident={activeEvent}
                environmentId={environmentId}
                systemId={systemId}
                projectName={projectNameRCA}
                functionRC="root cause"
                needRC={needRC}
                needPT={needPT}
                rcaFlag={rcaFlag}
                isJWT
                jwtToken={jwtToken}
                zoneName={selectedZone === '__all__' ? `all_zone_${systemId}` : selectedZone}
              />
            </div>
          </div>
        )}
      </Layout>
    );
  }
}

const EmbedSystemRootCause = injectIntl(EmbedSystemRootCauseCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { dark } = parseLocation(location);
  if (dark) {
    state.app.currentTheme = 'dark';
  } else {
    state.app.currentTheme = 'light';
  }

  const { currentTheme } = state.app;

  return { location, currentTheme };
}, {})(EmbedSystemRootCause);
