import React from 'react';
import * as R from 'ramda';
import { get, isObject } from 'lodash';
import { Tag } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { LogRenderers } from '../../../common/utils';

const RenderLogContent = ({ rowData, summarySettings, currentTheme, onToggleCollapse, style }: Object) => {
  const { isExpand, rawData } = rowData;
  const settings = summarySettings || [];

  const handleExpand = (e) => {
    e.stopPropagation();
    rowData.isExpand = !isExpand;
    if (onToggleCollapse) {
      onToggleCollapse();
    }
  };

  let rawDataJson;
  try {
    rawDataJson = JSON.parse(rawData);
    if (!isObject(rawDataJson)) rawDataJson = undefined;
  } catch (error) {
    // ignore
  }
  return (
    <div className="flex-col max-width" style={{ ...(style || {}) }}>
      <div>
        <div
          className="flex-row"
          style={{ minHeight: '40px', alignItems: 'center', flexGrow: '1' }}
          onClick={handleExpand}
        >
          {settings.length === 0 && (
            <div className="hidden-line-with-ellipsis clickable text-primary flex-grow">{rawData}</div>
          )}
          {settings.length > 0 && (
            <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap' }} className="clickable flex-grow">
              {R.addIndex(R.map)((s, idx) => {
                const v = get(rawDataJson, s.replaceAll('->', '.'));
                if (!v) return null;
                return (
                  <div
                    key={s}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                      flex: 'initial',
                      marginBottom: 4,
                    }}
                  >
                    <Tag color="processing" style={{ marginRight: 2, padding: '0 2px' }}>
                      {s}
                    </Tag>
                    <div
                      className="hidden-line-with-ellipsis"
                      style={{ paddingRight: 8, display: 'inline', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      dangerouslySetInnerHTML={{ __html: v }}
                    />
                  </div>
                );
              }, settings)}
            </div>
          )}
          <div className="clickable" style={{ lineHeight: '40px', paddingRight: 4 }}>
            {isExpand ? <UpOutlined /> : <DownOutlined />}
          </div>
        </div>
        <div style={{ display: isExpand ? 'block' : 'none' }}>
          <LogRenderers.JsonTree data={rawDataJson} onToggleCollapse={onToggleCollapse} currentTheme={currentTheme} />
        </div>
      </div>
    </div>
  );
};

export default RenderLogContent;
