import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import VLink from 'valuelink';

import { State } from '../../../common/types';
import { Modal } from '../../../../artui/react';
import { Select, Input, Container } from '../../../lib/fui/react';
import { ifIn, getLoadStatus } from '../../../common/utils';

type Props = {
  intl: Object,
  loadStatus: Object,
  currentLoadingComponents: Object,

  project: Object,
  projectName: String,
  customerName: String,
  instanceGroup: String,
  onClose: Function,
};

class NewJobModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.submitLoader = 'causal_create_group_submit';
    this.paramsLoader = 'causal_create_group_params';
    this.sumbitting = false;

    this.state = {
      masterIP: '',
      historyServerPort: 19888,
      resourcePort: 8088,
      nodeManagerPort: 8042,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLoadingComponents, loadStatus, intl } = nextProps;
    const { isParamsLoading, errorMessage } = getLoadStatus(get(loadStatus, this.paramsLoader), intl);
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      if (!errorMessage) {
        this.props.onClose(true);
      }
    }
  }

  @autobind
  handleOnClose() {
    this.props.onClose(false);
  }

  @autobind
  handleSumbit() {
    // const { createMapReduceJob } = this.props;
    const { masterIP, historyServerPort } = this.state;
    this.sumbitting = true;
    // createMapReduceJob({ masterIP, historyServerPort }, { [this.submitLoader]: true });
  }

  render() {
    const { intl, currentLoadingComponents, loadStatus } = this.props;

    const masterIPLink = VLink.state(this, 'masterIP').check((n) => Boolean(n), 'Input MapReduce Master IP');
    const historyServerPortLink = VLink.state(this, 'historyServerPort').check(
      (i) => parseInt(i, 10) > 0,
      'History Server Port must be an integer',
    );
    const resourcePortLink = VLink.state(this, 'resourcePort').check(
      (i) => parseInt(i, 10) > 0,
      'Resource Port must be an integer',
    );
    const nodeManagerPortLink = VLink.state(this, 'nodeManagerPort').check(
      (i) => parseInt(i, 10) > 0,
      'Node Manager Port must be an integer',
    );

    const hasError = masterIPLink.error;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    const { isParamsLoading, errorMessage } = getLoadStatus(get(loadStatus, this.paramsLoader), intl);

    return (
      <Modal size="large" closable={false} onClose={this.handleOnClose} style={{ width: 700 }}>
        <div className="create-group content">
          <div className="ui info mini message">
            <i className="icon info" />
            <span>Create a MapReduce project.</span>
          </div>
          <form className={`ui ${hasError ? 'error' : ''} ${isParamsLoading ? 'loading ' : ''} form`}>
            <div className="input inline field flex-row" key={1}>
              <label style={{ width: 150 }}>Master IP Address:</label>
              <Input valueLink={masterIPLink} size="small" style={{ width: 210 }} />
              <label style={{ width: 150 }}> History Server Port: </label>
              <Input type="number" valueLink={historyServerPortLink} style={{ width: 210 }} />
            </div>
            <div className="input inline field flex-row" key={2}>
              <label style={{ width: 150 }}> Resource Port: </label>
              <Input type="number" valueLink={resourcePortLink} style={{ width: 210 }} />
              <label style={{ width: 150 }}> Node Manager Port: </label>
              <Input type="number" valueLink={nodeManagerPortLink} style={{ width: 210 }} />
            </div>
          </form>
        </div>
        {Boolean(errorMessage) && (
          <div className="error content">
            <div className="ui error mini message">
              <i className="icon warning sign" />
              <span>{intl.formatMessage(errorMessage.message, errorMessage.params)}</span>
            </div>
          </div>
        )}
        <div className="actions">
          <div className="ui button deny" onClick={this.handleOnClose}>
            Cancel
          </div>
          <div className={`ui button labeled ${hasError ? 'disabled' : ''}`}>
            <div className={`ui button orange ${isLoading ? 'loading' : ''}`} onClick={this.handleSumbit}>
              <i className="save icon" />
              <span>Create</span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const NewJobModal = injectIntl(NewJobModalCore);
export default connect((state: State) => ({}), {})(NewJobModal);
