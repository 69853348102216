import React, { useState } from 'react';
import * as R from 'ramda';

import {
  ControlOutlined,
  DownOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  BulbOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Menu, Dropdown, message, Popover, Avatar } from 'antd';

import ThemeIcon from '../../../images/theme.svg';
import fetchPost from '../apis/fetchPost';
import getEndpoint from '../apis/getEndpoint';
import { buildUrl, parseUrl, Defaults, getUIMenus, parseLocation } from '.';
import { BaseUrls } from '../../web/app/Constants';
import { ActionTypes as AppActionTypes } from '../app/actions';
import { GettingStartIcon } from '../../lib/fui/icons';

import { appMenusMessages, appFieldsMessages, appMessages } from '../app/messages';

import InterfaceSettingsModal from '../../web/settings/system/InterfaceSettingsModal';

// User Account Menu Render
const MenuBarRender = ({
  intl,
  location,
  push,
  userInfo,
  credentials,
  logoff,
  loginSuccess,
  createSetAction,
  toursState,
  currentTheme,
  setCurrentTheme,
}) => {
  const [showInterfaceSettings, setInterfaceSettings] = useState(false);
  const [isSubmittingInterfaceSettings, setSubmittingInterfaceSettings] = useState(false);
  const [rowData, setRowData] = useState({});
  const params = parseLocation(location);
  const { customerName } = params || {};

  const handleInterfaceConfigSubmit = (rowData, cards) => {
    setSubmittingInterfaceSettings(true);

    const displayedMenus = R.map(
      (item) => item.id,
      R.filter((item) => item.display, cards),
    );
    fetchPost(getEndpoint('userrole'), {
      ...credentials,
      customerName: credentials.userName,
      updateMenu: JSON.stringify(displayedMenus),
    })
      .then((data) => {
        // create new userInfo
        const newUserInfo = { ...userInfo, displayedMenus };
        loginSuccess(credentials, newUserInfo);

        message.success(intl.formatMessage(appMessages.apiSuccess));
        setSubmittingInterfaceSettings(false);
        setInterfaceSettings(false);
        window.location.reload();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        setSubmittingInterfaceSettings(false);
      });
  };

  const handleMenuClick = ({ key }) => {
    if (key === 'gettingStart') {
      // reback the tour location
      const { stepIndex, url } = toursState || {};
      if (url) {
        const { pathname } = parseUrl(url);
        if (stepIndex === 0 || pathname !== location.pathname) {
          push(url);
        }
      }

      createSetAction(AppActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { runAwesomeTours: false });
      setTimeout(() => {
        createSetAction(AppActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { runAwesomeTours: true });
      }, 600);
    } else if (key === 'themeMode') {
      setCurrentTheme(currentTheme === 'light' ? 'dark' : 'light');
    } else if (key === 'accountProfile') {
      push(buildUrl(BaseUrls.AccountInfo, {}, { customerName }));
    } else if (key === 'menuConfig') {
      const menuConfigs = [];
      const menus = getUIMenus(intl, appMenusMessages);
      const displayedMenus = userInfo.displayedMenus || Defaults.DisplayedMenus;
      R.forEach((menu) => {
        const index = displayedMenus.indexOf(menu.id);
        let display = false;
        if (index !== -1) {
          display = true;
        }
        menuConfigs.push({
          ...menu,
          display,
          order: index,
        });
      }, menus);
      setRowData({ ...userInfo, menuConfigs });
      setInterfaceSettings(true);
    } else if (key === 'signout') {
      logoff(credentials);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="gettingStart">
        <div>
          <GettingStartIcon style={{ margin: '0 6px 0 0' }} />
          {intl.formatMessage(appMenusMessages.guidedTour)}
        </div>
      </Menu.Item>
      {/* <Menu.Item key="themeMode">
        <div>
          {currentTheme === 'light' ? (
            <img src={ThemeIcon} style={{ width: 14, marginRight: 6 }} />
          ) : (
            <img src={ThemeIcon} style={{ filter: 'invert(1)', width: 14, marginRight: 6 }} />
          )}
          {intl.formatMessage(
            currentTheme === 'light' ? appMenusMessages.themeModeLight : appMenusMessages.themeModeDark,
          )}
        </div>
      </Menu.Item> */}
      <Menu.Item key="accountProfile">
        <div>
          <UserOutlined style={{ margin: '0 6px 0 0' }} />
          {intl.formatMessage(appMenusMessages.accountProfile)}
        </div>
      </Menu.Item>
      <Menu.Item key="menuConfig">
        <div>
          <ControlOutlined style={{ margin: '0 6px 0 0' }} />
          Menu Config
        </div>
      </Menu.Item>
      <Menu.Item key="signout">
        <div>
          <LogoutOutlined style={{ margin: '0 6px 0 0' }} />
          {intl.formatMessage(appMenusMessages.signout)}
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="menu-bar">
      <div className="setting-bar">
        <Popover
          overlayClassName="small-popover"
          placement="bottom"
          title={null}
          content={intl.formatMessage(appMenusMessages.help)}
        >
          <QuestionCircleOutlined
            onClick={() => push(buildUrl(BaseUrls.Help, {}, { customerName }))}
            style={{ fontSize: 16 }}
          />
        </Popover>
        <div
          className="clickable pl-1"
          onClick={() => {
            handleMenuClick({ key: 'themeMode' });
          }}
        >
          <Popover overlayClassName="small-popover" placement="bottom" title={null} content="Light / Dark">
            {currentTheme === 'light' ? (
              <img src={ThemeIcon} style={{ filter: 'invert(1)', width: 20, display: 'block' }} />
            ) : (
              <img src={ThemeIcon} style={{ filter: 'invert(1)', width: 20, display: 'block' }} />
            )}
          </Popover>
        </div>
      </div>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className="user" style={{ cursor: 'pointer' }}>
          <Avatar
            size="small"
            style={{ backgroundColor: '#87d068' }}
            src={buildUrl(getEndpoint('avatar', 2), {}, { avatarId: userInfo.avatarId, customerName })}
          >
            {R.toUpper((userInfo?.userName || '')[0] || '')}
          </Avatar>
          <span style={{ marginLeft: 8 }}>
            {userInfo.userName} <DownOutlined />
          </span>
        </div>
      </Dropdown>

      {showInterfaceSettings && (
        <InterfaceSettingsModal
          intl={intl}
          onOk={handleInterfaceConfigSubmit}
          onCancel={() => setInterfaceSettings(false)}
          rowData={rowData}
          isSubmitting={isSubmittingInterfaceSettings}
        />
      )}
    </div>
  );
};

export default MenuBarRender;
