/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import * as R from 'ramda';
import { get } from 'lodash';

import { Defaults } from '../../utils';

const parseEvents = (events, params) => {
  const { isLog } = params;
  const neuronIds = [];
  const recurrentNeuronIds = [];
  R.forEachObjIndexed((evt) => {
    const { neuronId } = evt;
    if (R.indexOf(neuronId, neuronIds) >= 0) {
      if (R.indexOf(neuronId, recurrentNeuronIds) < 0) {
        recurrentNeuronIds.push(neuronId);
      }
    } else {
      neuronIds.push(neuronId);
    }
  }, events);

  const eventList = R.addIndex(R.map)((evt, idx) => {
    const { patternId, startTimestamp, endTimestamp, anomalyScore, rawData } = evt;
    const { ignoreFlag, emailStatus, causalFetchedInfoFileName } = evt;
    let { neuronId, anomalyRatio, rootCauseJson, actionStatus, patternName, duration } = evt;

    // parse log event format
    if (R.isNil(rootCauseJson)) {
      rootCauseJson = { rawData };
    }
    if (R.isNil(anomalyRatio)) {
      anomalyRatio = anomalyScore;
    }

    const eventSummary = get(rootCauseJson, ['rootCauseDetails'], '');
    // const criticalThresholdEvents = get(rootCauseJson, ['CriticalThresholdEvents'], 'None');

    // If patternName not exists, use neuronId to build.for log event, use patternId
    if (R.isNil(neuronId)) {
      neuronId = patternId;
    }
    // if (!patternName) {
    //   patternName = criticalThresholdEvents !== 'None' ? criticalThresholdEvents : patternName;
    // }
    if (!patternName) {
      if (neuronId === undefined && evt.type === 'removedInstance') {
        patternName = 'Instance Down';
        duration = 0;
      } else if (neuronId === -1) {
        patternName = 'Threshold violation';
      } else if (neuronId === -2) {
        patternName = 'Instance Down';
        duration = 0;
      } else if (neuronId === -3) {
        patternName = 'Service Hang';
      } else if (neuronId === -4) {
        patternName = 'System outage';
      } else {
        patternName = `${Defaults.PatternNamePrefix}${neuronId}`;
      }
    }

    // log and Causal relation
    const { causalRelationKey, causalStatus, logStatus } = evt;
    const hasLog = (logStatus || '').toLowerCase().indexOf('finish') === 0;
    const relatedLogFileName = get(evt, 'relatedLogFileName', {});
    const hasCausalRelation = Boolean(causalRelationKey) && (causalStatus || '').toLowerCase() === 'finish';

    // Parse syscall list
    let systemCallList = [];
    R.forEachObjIndexed((val, key) => {
      const { impactFactor, impactedTIDs, numAffectedThread, procNames, totalNumThread } = val;
      const procMap = JSON.parse(procNames || '{}');
      const tids = JSON.parse(impactedTIDs || '[]');

      const processInfoList = [];
      R.forEachObjIndexed((val, key) => {
        const name = key.trim();
        const tids = R.filter(
          (t) => Boolean(t),
          R.map((t) => t.replace('TID', '').trim(), val.split(',')),
        );

        processInfoList.push({
          name,
          TIDs: tids,
        });
      }, procMap);

      systemCallList.push({
        datetime: parseInt(key, 10),
        impactedTIDs: tids,
        impactFactor,
        procNames: R.sort((a, b) => a.name.localeCompare(b.name), processInfoList),
        numAffectedThread,
        totalNumThread,
      });
    }, get(evt, 'systemCallResult', {}));
    systemCallList = R.sort((a, b) => b.datetime - a.datetime, systemCallList);

    return {
      ...evt,
      isLog,
      rootCauseJson,
      id: events.length - idx,
      neuronId,
      anomalyRatio,
      creationTime: evt.creationTime || evt.detectionCreationTime,
      ignoreFlag: Boolean(ignoreFlag),
      isEmailSent: (emailStatus || '').toLowerCase() === 'sent',
      actionStatus,
      patternName,
      startTimestamp,
      duration,
      endTimestamp,
      eventSummary,
      hasLog,
      relatedLogFileName,
      hasSystemCall: systemCallList.length > 0,
      hasCausalPrediction: Boolean(causalFetchedInfoFileName),
      causalFetchedInfoFileName,
      systemCallList,
      hasCausalRelation,
      causalRelationKey,
    };
  }, events);

  return eventList;
};

export default parseEvents;
