/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { get, isString } from 'lodash';
import { Defaults, parseJSON } from '../../../common/utils';

const parseDeploymentList = (deploymentResult, params) => {
  let eventList = [];
  R.forEach((event) => {
    const { deploymentMsg, key } = event;
    const { key: keyIntra, timestamp } = key || {};
    const { instanceName } = keyIntra || {};

    let predictionSourceInfo = parseJSON(event.predictionSourceInfo);
    if (predictionSourceInfo) {
      const { sourceDetail, ...rest } = predictionSourceInfo;
      predictionSourceInfo = {
        sourceDetail: parseJSON(sourceDetail),
        ...rest,
      };
    }

    eventList.push({
      ...event,
      key: keyIntra,
      isDeploymentEvent: true,
      isLog: true,
      isPrediction: false,
      predictionSourceInfo,
      type: 'deployment',
      patternName: `Pattern 0`,
      patternId: 0,
      neuronId: 0,
      anomalyRatio: 1,
      timestamp,
      startTimestamp: timestamp,
      endTimestamp: timestamp,
      instanceName,
      anomalyScoreList: [],
      rootCauseJson: { rawData: deploymentMsg },
    });
  }, deploymentResult || []);

  eventList = R.map((event) => {
    return { ...event, timeLineEvents: [event] };
  }, eventList);

  eventList = R.sort((a, b) => b.timestamp - a.timestamp, eventList);

  return eventList;
};

export default parseDeploymentList;
