/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import { Observable } from 'rxjs/Observable';
import { isBoolean } from 'lodash';

import type { Deps } from '../../types';
import { setProjectCreationStatus } from '../actions';
import { setInitData, updateLastActionInfo } from '../../app/actions';
import {
  getInitData,
  newCustomProject,
  newKubernetesProject,
  newAWSCloudProject,
  newAWSCloudWatchProject,
  newDataDogProject,
  newPagerDutyProject,
  newServiceNowProject,
  newZendeskProject,
  newSumoLogicProject,
  newGoogleProject,
  newNewRelicProject,
  newCATechnologyProject,
  newGoogleAdsProject,
  newGoogleCloudProject,
  newGoogleBigQueryProject,
  newGoogleCloudCostProject,
  newGoogleCloudPubSubProject,
  newMicrosoftAzureProject,
  newJDBCProject,
  newShadowProjectProject,
  newSnowflake,
  newAzureCostProject,
  newDynatraceProject,
  newNagiosProject,
} from '../../apis';
import { apiEpicErrorHandle } from '../../errors';

const createProjectEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('CREATE_PROJECT').concatMap((action) => {
    const state = getState();

    const { credentials, userInfo } = state.auth;
    const { projectName, projectType, params } = action.payload;

    let api = Observable.empty();
    switch (projectType) {
      case 'InsightAgent':
        api = newCustomProject(credentials, projectName, params);
        break;
      case 'Kubernetes':
        api = newKubernetesProject(credentials, projectName, params);
        break;
      case 'AWSCloud':
        api = newAWSCloudProject(credentials, projectName, params);
        break;
      case 'AWSCloudWatch':
        api = newAWSCloudWatchProject(credentials, projectName, params);
        break;
      case 'Datadog':
        api = newDataDogProject(credentials, projectName, params);
        break;
      case 'PagerDuty':
        api = newPagerDutyProject(credentials, projectName, params);
        break;
      case 'ServiceNow':
        api = newServiceNowProject(credentials, projectName, params);
        break;
      case 'Zendesk':
        api = newZendeskProject(credentials, projectName, params);
        break;
      case 'SumoLogic':
        api = newSumoLogicProject(credentials, projectName, params);
        break;
      case 'NewRelic':
        api = newNewRelicProject(credentials, projectName, params);
        break;
      case 'CATechnology':
        api = newCATechnologyProject(credentials, projectName, params);
        break;
      case 'GoogleAds':
        api = newGoogleAdsProject(credentials, projectName, params);
        break;
      case 'GoogleCloud':
        api = newGoogleCloudProject(credentials, projectName, params);
        break;
      case 'BigQuery':
        api = newGoogleBigQueryProject(credentials, projectName, params);
        break;
      case 'CloudCost':
        api = newGoogleCloudCostProject(credentials, projectName, params);
        break;
      case 'CloudPubSub':
        api = newGoogleCloudPubSubProject(credentials, projectName, params);
        break;
      case 'MicrosoftAzure':
        api = newMicrosoftAzureProject(credentials, projectName, params);
        break;
      case 'JDBC':
        api = newJDBCProject(credentials, projectName, params);
        break;
      case 'Google':
        api = newGoogleProject(credentials, projectName, params);
        break;
      case 'ShadowProject':
        api = newShadowProjectProject(credentials, projectName, params);
        break;
      case 'snowflake':
        api = newSnowflake(credentials, projectName, params);
        break;
      case 'AzureCost':
        api = newAzureCostProject(credentials, projectName, params);
        break;
      case 'Dynatrace':
        api = newDynatraceProject(credentials, projectName, params);
        break;
      case 'Nagios':
        api = newNagiosProject(credentials, projectName, params);
        break;
      default:
        api = Observable.empty();
    }

    const apiAction$ = Observable.from(api)
      .concatMap((data1) => {
        if (isBoolean(data1.success) && data1.success === false) {
          return Observable.concat(
            // apiEpicErrorHandle(data1.message),
            Observable.of(setProjectCreationStatus(data1.message || 'failed', {})),
          );
        }
        // return Observable.from(getInitData(credentials, userInfo))
        //   .concatMap((d) => {
        //     return Observable.concat(
        //       Observable.of(setInitData(d)),
        //       Observable.of(setProjectCreationStatus('success', data1)),
        //     );
        //   })
        //   .catch((err) => {
        //     return apiEpicErrorHandle(err);
        //   });
        return Observable.concat(Observable.of(setProjectCreationStatus('success', data1)));
      })
      .catch((err) => {
        return Observable.concat(
          // apiEpicErrorHandle(err),
          Observable.of(
            setProjectCreationStatus(
              err && err.message
                ? err.message
                : err.response
                ? `${err.response.status}: ${err.response.statusText}`
                : String(err),
              {},
            ),
          ),
        );
      });

    return Observable.concat(Observable.of(updateLastActionInfo()), apiAction$);
  });

export default createProjectEpic;
