/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
/* eslint-disable no-console */

import { showAppAlert } from '../app/actions';
import { sessionInvalid } from '../auth/actions';
import { appMessages } from '../app/messages';
import BadRequestError from './BadRequestError';
import NotFoundError from './NotFoundError';
import InvalidDataError from './InvalidDataError';
import PermissionError from './PermissionError';
import NetworkError from './NetworkError';
import HttpError from './HttpError';

const apiErrorHandle = (err: Error) => {
  console.error(['API call failed', err]);
  if (err instanceof NotFoundError) {
    return showAppAlert('error', appMessages.errorsNotFound);
  }

  if (err instanceof BadRequestError) {
    return showAppAlert('error', appMessages.errorsBadRequest);
  }

  if (err instanceof InvalidDataError) {
    return showAppAlert('error', appMessages.errorsInvalidData);
  }

  if (err instanceof NetworkError) {
    return showAppAlert('error', appMessages.errorsNetwork);
  }

  if (err instanceof PermissionError) {
    return sessionInvalid(err);
  }

  if (err instanceof HttpError) {
    return showAppAlert('error', appMessages.errorsServer);
  }

  // if (err) {
  //   switch (err.constructor) {
  //     case NotFoundError:
  //       return showAppAlert('error', appMessages.errorsNotFound);
  //     case BadRequestError:
  //       return showAppAlert('error', appMessages.errorsBadRequest);
  //     case InvalidDataError:
  //       return showAppAlert('error', appMessages.errorsInvalidData);
  //     case NetworkError:
  //       return showAppAlert('error', appMessages.errorsNetwork);
  //     case PermissionError:
  //       return sessionInvalid(err);
  //     case HttpError:
  //       return showAppAlert('error', appMessages.errorsServer);
  //     default:
  //       break;
  //   }
  // }

  return showAppAlert('error', appMessages.errorsGeneral);
};

export default apiErrorHandle;
