import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isArray } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { LineChartOutlined } from '@ant-design/icons';
import { Spin, Button, Select } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { GlobalParse, buildUrl, parseLocation } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appButtonsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import RootCauseChainSelectModal from './RootCauseChainSelectModal';
import EventActionModal from '../../metric/components/EventActionModal';
import RootCausesModal from './RootCausesModal';
import LikelyRootCausesVisualizer from './LikelyRootCausesVisualizer';
import fetchPost from '../../../common/apis/fetchPost';

type Props = {
  environmentId: String,
  systemId: String,
  incident: Object,
  rootCausesData: Object,
  predictedData: Object,
  onReload: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
  currentTheme: String,
  needRC: Boolean,
  needPT: Boolean,
  isJWT: Boolean,
};

const getRankColor = (rank) => {
  let color = '#797D92';
  switch (rank) {
    case 1:
      color = '#FF5142';
      break;
    case 2:
      color = '#FF8A61';
      break;
    case 3:
      color = '#FEB207';
      break;
    default:
      break;
  }
  return color;
};

class LikelyRootCausesTableRCACore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      allRootCauseListMap: {},
      allPredictedListMap: {},
      activeChain: null,

      // take action
      rootCauseIncident: null,
      operation: null,
      showRootCauseChainSelect: false,
      actionRootCauseKey: null,
      showActionModal: false,

      // details
      showRootCausesModal: false,

      sourceRootCausesData: [],
      trailingEventsData: [],
    };

    this.relevanceOptions = [
      { label: 'Unspecified', value: 'none', color: 'currentColor' },
      { label: 'High', value: 'high', color: 'red' },
      { label: 'Medium', value: 'medium', color: 'orange' },
      { label: 'Low', value: 'low', color: 'gray' },
    ];
    this.relevanceColorMap = R.fromPairs(R.map((item) => [item.value, item.color], this.relevanceOptions));
    this.summarySettingsMap = {};
    this.initDataFlag = false;
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.rootCausesData !== this.props.rootCausesData ||
      nextProps.predictedData !== this.props.predictedData
    ) {
      this.parseData(nextProps);
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  async parseData(props) {
    const { credentials, projectDisplayMap, rootCausesData, predictedData, incident, isJWT } = props;

    this.setState({ isLoading: true });

    // RC
    const logRootCauseEvents = get(rootCausesData, ['logRootCauseEvents'], []);
    const metricRootCauseEvents = get(rootCausesData, ['metricRootCauseEvents'], []);
    const { relatedEventList: allRootCauseListLog } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: logRootCauseEvents,
      credentials,
      projectDisplayMap,
      incident,
      isExpand: true,
    });
    const { relatedEventList: allRootCauseListMetric } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: metricRootCauseEvents,
      credentials,
      projectDisplayMap,
      incident,
      isExpand: true,
    });
    const allRootCauseListMap = { logRC: allRootCauseListLog, metricRC: allRootCauseListMetric };

    // PT
    const logPredictedEvents = get(predictedData, ['logPredictedEvents'], []);
    const metricPredictedEvents = get(predictedData, ['metricPredictedEvents'], []);
    const { relatedEventList: allPredictedListLog } = GlobalParse.parseRootCauseInfo({
      operation: 'predictedEvents',
      chains: logPredictedEvents,
      credentials,
      projectDisplayMap,
      incident,
      isExpand: true,
    });
    const { relatedEventList: allPredictedListMetric } = GlobalParse.parseRootCauseInfo({
      operation: 'predictedEvents',
      chains: metricPredictedEvents,
      credentials,
      projectDisplayMap,
      incident,
      isExpand: true,
    });
    const allPredictedListMap = { logPT: allPredictedListLog, metricPT: allPredictedListMetric };

    let sourceRootCausesData = [...allRootCauseListLog, ...allRootCauseListMetric];
    let trailingEventsData = [...allPredictedListLog, ...allPredictedListMetric];
    const getRelevance = (r) => {
      if (r === 'high') return 1;
      if (r === 'low') return -1;
      return 0;
    };

    sourceRootCausesData = R.sortWith(
      [(a, b) => getRelevance(b?.relevance) - getRelevance(a?.relevance), R.descend(R.prop('probability'))],
      sourceRootCausesData,
    );
    trailingEventsData = R.sortWith(
      [(a, b) => getRelevance(b?.relevance) - getRelevance(a?.relevance), R.descend(R.prop('probability'))],
      trailingEventsData,
    );
    sourceRootCausesData = R.addIndex(R.map)(
      (item, idx) => ({ ...item, rank: R.toString(idx + 1), rankColor: getRankColor(idx + 1) }),
      sourceRootCausesData,
    );
    trailingEventsData = R.addIndex(R.map)(
      (item, idx) => ({ ...item, rank: R.toString(idx + 1), rankColor: getRankColor(idx + 1) }),
      trailingEventsData,
    );

    let eventProjectNameAll = [];
    let causeChainInfoList = [];
    R.forEach(
      (item) => {
        const { sourceInfoList } = item;
        R.forEach((_item) => {
          const { projectName, owner, type, projectOwner, anomalyLogInstance, sourceInstanceName } = _item;
          const { sourceDetail, sourceProjectOwner } = _item;
          const { nid, instanceName } = sourceDetail || {};
          eventProjectNameAll.push({
            projectName: owner !== credentials.userName ? `${projectName}@${owner}` : projectName,
            category: type,
          });

          const userName = owner || projectOwner || sourceProjectOwner;
          const instanceNameNew = instanceName || anomalyLogInstance || sourceInstanceName;
          causeChainInfoList.push({
            projectName,
            userName,
            instanceName: instanceNameNew,
            patternId: nid,
            key: `${projectName}-${userName}-${instanceNameNew}-${nid}`,
          });
        }, sourceInfoList || []);
      },
      [...sourceRootCausesData, ...trailingEventsData],
    );

    eventProjectNameAll = [
      ...eventProjectNameAll,
      {
        projectName:
          incident.projectOwner !== credentials.userName
            ? `${incident.projectName}@${incident.projectOwner}`
            : incident.projectName,
        category: incident.category,
      },
    ];

    // get pattern name
    causeChainInfoList = R.uniqBy((item) => item.key, causeChainInfoList || []);
    const groupSourceInfo = R.groupBy((item) => `${item.projectName}-@-${item.userName}`, causeChainInfoList || []);
    const getPatternNameParams = [];
    R.forEachObjIndexed((sourceInfos, infoKey) => {
      const [p, u] = R.split('-@-', infoKey);
      const groupInstanceName = R.groupBy((item) => item.instanceName, sourceInfos || []);
      const patternNameByInstance = {};
      R.forEachObjIndexed((items, key) => {
        patternNameByInstance[key] = [];
        R.forEach((item) => {
          patternNameByInstance[key].push({ patternId: item.patternId });
        }, items || []);
      }, groupInstanceName || {});
      getPatternNameParams.push({ projectName: p, userName: u, patternNameByInstance });
    }, groupSourceInfo || {});

    let patternNameList = [];
    if (!isJWT) patternNameList = await this.getPatternNameData(getPatternNameParams);

    R.forEach(
      (item) => {
        const { sourceInfoList } = item;
        R.forEach((_item) => {
          const { projectName, owner, type, projectOwner, anomalyLogInstance, sourceInstanceName } = _item;
          const { sourceDetail, sourceProjectOwner } = _item;
          const { nid, instanceName, patternName } = sourceDetail || {};

          eventProjectNameAll.push({
            projectName: owner !== credentials.userName ? `${projectName}@${owner}` : projectName,
            category: type,
          });

          const userName = owner || projectOwner || sourceProjectOwner;
          const instanceNameNew = instanceName || anomalyLogInstance || sourceInstanceName;
          const key = `${projectName}-${userName}-${instanceNameNew}-${nid}`;
          const findValue = R.find((pt) => {
            const findKey = `${pt.projectName}-${pt.userName}-${pt.instanceName}-${pt.patternId}`;
            return key === findKey;
          }, patternNameList || []);
          if (findValue) {
            _item.patternName = findValue.patternName;
          } else {
            _item.patternName = patternName;
          }
        }, sourceInfoList || []);
      },
      [...sourceRootCausesData, ...trailingEventsData],
    );

    this.initDataFlag = true;
    eventProjectNameAll = R.uniqBy((item) => item.projectName, eventProjectNameAll);
    let summarySettingsMap = {};
    if (!isJWT) summarySettingsMap = await this.getLogsummarysettings(eventProjectNameAll);
    this.summarySettingsMap = { ...summarySettingsMap, ...(incident?.summarySettingsMap || {}) };
    this.setState({
      isLoading: false,
      sourceRootCausesData,
      trailingEventsData,
      allRootCauseListMap,
      allPredictedListMap,
    });
  }

  @autobind
  getPatternNameData(getPatternNameParams) {
    const { credentials } = this.props;
    const patternNameList = [];
    if (getPatternNameParams.length === 0) {
      return patternNameList;
    }
    return fetchPost(getEndpoint('getpatternname', 1), {
      ...credentials,
      queryString: JSON.stringify(getPatternNameParams),
    })
      .then((data) => {
        const { success, message } = data || {};
        if ((success || success === undefined) && isArray(data)) {
          R.forEach((item) => {
            const { projectName, userName, patternNameByInstance } = item || {};
            R.forEachObjIndexed((patterns, instance) => {
              R.forEach((patternInfo) => {
                const { patternId, patternName } = patternInfo || {};
                patternNameList.push({
                  projectName,
                  userName,
                  instanceName: instance,
                  patternId,
                  patternName,
                });
              }, patterns || []);
            }, patternNameByInstance);
          }, data || []);
          return patternNameList;
        } else {
          console.error(message);
        }
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  }

  @autobind
  getLogsummarysettings(eventProjectNameAll) {
    const { credentials } = this.props;
    const projects = [];
    R.forEach((p) => {
      const { projectName, category } = p;
      if (!R.includes(category, ['metric'])) projects.push(projectName);
    }, eventProjectNameAll || []);

    const request = [];
    R.forEach((projectName) => {
      request.push(
        fetchGet(getEndpoint('logsummarysettings'), {
          ...credentials,
          projectName,
        }),
      );
    }, projects || []);

    return Promise.all(request)
      .then((data) => {
        const summarySettingsMap = {};
        R.addIndex(R.forEach)((projectName, idx) => {
          summarySettingsMap[projectName] = data[idx];
        }, projects || []);
        return summarySettingsMap;
      })
      .catch((err) => {
        console.err(err.message || String(err));
        return {};
      });
  }

  @autobind
  handleOverallLineChartClick(e) {
    const { intl, credentials, incident } = this.props;
    const { allRootCauseListMap } = this.state;

    // build project metric/instance info map
    const projectMap = {};
    R.forEach((chainList) => {
      R.forEach((chain) => {
        const { sourceInfoList } = chain;
        R.forEach((sourceInfo) => {
          const { sourceDetail, instanceName, owner, displayTimestamp } = sourceInfo;
          const { isMetric, content } = sourceDetail || {};
          if (isMetric) {
            let { projectName } = sourceInfo;
            projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;

            if (!R.has(projectName, projectMap)) {
              projectMap[projectName] = {
                projectName,
                times: [],
                instances: [],
                metrics: [],
                metricsMap: {},
              };
            }

            projectMap[projectName].times.push(displayTimestamp);
            projectMap[projectName].instances.push(instanceName);
            projectMap[projectName].metrics.push(content);
            if (!R.has(content, projectMap[projectName].metricsMap)) {
              projectMap[projectName].metricsMap[content] = [];
            }
            projectMap[projectName].metricsMap[content].push(instanceName);
          }
        }, sourceInfoList || []);
      }, chainList);
    }, R.values(allRootCauseListMap));

    const projectNames = R.keys(projectMap);
    this.selectProject = projectNames.length > 0 ? projectNames[0] : undefined;
    Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      bodyStyle: { marginLeft: 0 },
      style: { minWidth: 500 },
      content: (
        <div className="flex-row flex-center-align">
          <div style={{ marginRight: 8 }}>Metric Project:</div>
          <div className="flex-grow">
            <Select
              size="small"
              style={{ width: 250 }}
              filterOption
              options={R.map((item) => ({ value: item, label: item }), projectNames)}
              defaultValue={this.selectProject}
              onChange={(project) => {
                this.selectProject = project;
              }}
            />
          </div>
        </div>
      ),
      onOk: (close) => this.handleSelectProjectConfirm(close, incident, get(projectMap, this.selectProject, {})),
    });
  }

  @autobind
  handleSelectProjectConfirm(close, incident, params) {
    const { projectName, times, instances, metrics, metricsMap } = params;
    const { startTimestamp: incidentStart, endTimestamp: incidentEnd, patternId, isIncident, isDeployment } = incident;
    const rootCauseStart = R.reduce(R.min, incidentStart, times);
    const rootCauseEnd = R.reduce(R.max, incidentStart, times);
    const startTimestamp = moment.utc(rootCauseStart).startOf('day').valueOf();
    const endTimestamp = moment.utc(rootCauseEnd).endOf('day').valueOf();
    const { location } = this.props;
    const { customerName } = parseLocation(location);

    const incidentInfo = {
      startTimestamp: incidentStart,
      endTimestamp: incidentEnd,
      patternId,
      type: isIncident ? 'incident' : isDeployment ? 'deployment' : 'normal',
    };
    const metricAnomalyMap = R.mapObjIndexed((val) => {
      return R.uniq(val);
    }, metricsMap);

    const query = {
      customerName,
      projectName,
      instanceGroup: 'All',
      startTimestamp,
      endTimestamp,
      justSelectMetric: R.join(',', R.uniq(metrics)),
      justInstanceList: R.join(',', R.uniq(instances)),
      // withBaseline: true,
      incidentInfo: JSON.stringify(incidentInfo),
      metricAnomalyMap: JSON.stringify(metricAnomalyMap),
      showRCAFlag: true,
      // startTimeWindow: rootCauseStart - 600000,
      // endTimeWindow: rootCauseEnd + 600000,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');

    close();
  }

  @autobind
  handleGroupSuggestedActionsClick({ tabName, operation }) {
    const { credentials, incident } = this.props;
    const dataField = operation === 'rootCauseEvents' ? 'allRootCauseListMap' : 'allPredictedListMap';
    const { [dataField]: dataFieldMap } = this.state;

    const { projectOwner } = incident;
    let { projectName } = incident;
    // parse params to get root cause
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const rootCauseIncident = {
      ...incident,
      projectName,
    };

    const checkedList = R.filter((item) => item.isChecked, dataFieldMap[tabName] || []);
    const actionRootCauseKey = JSON.stringify(R.map((item) => item.actionRootCauseKey, checkedList));
    this.setState({ rootCauseIncident, showActionModal: true, actionRootCauseKey });
  }

  @autobind
  handleActionsClick(data) {
    const dataField = data ? [data] : [];

    const { credentials, incident } = this.props;
    const { projectOwner } = incident;
    let { projectName } = incident;
    // parse params to get root cause
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const rootCauseIncident = {
      ...incident,
      projectName,
    };
    const checkedList = R.filter((item) => item.isChecked, dataField);
    const actionRootCauseKey = JSON.stringify(R.map((item) => item.actionRootCauseKey, checkedList));
    this.setState({ rootCauseIncident, showActionModal: true, actionRootCauseKey });
  }

  @autobind
  handleActionSelectConfirm(actionRootCauseKey) {
    this.setState({ showRootCauseChainSelect: false, showActionModal: true, actionRootCauseKey });
  }

  @autobind
  getProjectNameList() {
    const { systemsMap, systemId } = this.props;
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  anomalyLineChartRedner() {
    const { intl, rootCausesData, needRC, needPT } = this.props;
    const { sourceRootCausesData, trailingEventsData } = this.state;
    return (
      <div className="flex-row flex-center-align flex-end-justify">
        {rootCausesData && needRC && (
          <div style={{ marginBottom: 8 }}>
            <Button
              size="small"
              type="primary"
              icon={<LineChartOutlined />}
              onClick={this.handleOverallLineChartClick}
              disabled={(needRC && sourceRootCausesData.length === 0) || (needPT && trailingEventsData === 0)}
            >
              {intl.formatMessage(eventMessages.anomalyLineChart)}
            </Button>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { incident, rootCausesData, predictedData, environmentId, systemId, currentTheme, needRC, needPT } =
      this.props;
    const { isJWT } = this.props;
    const { isLoading } = this.state;
    const { allRootCauseListMap, allPredictedListMap, activeChain } = this.state;
    const { rootCauseIncident, operation } = this.state;
    const { sourceRootCausesData, trailingEventsData } = this.state;

    return (
      <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
        <div className="full-width full-height ant-tabs-content-full-height flex-grow flex-min-height">
          {rootCausesData && needRC && (
            <div className="full-height flex-col">
              <LikelyRootCausesVisualizer
                key="RC"
                currentTheme={currentTheme}
                incident={incident}
                systemId={systemId}
                data={sourceRootCausesData}
                environmentId={environmentId}
                onReload={this.props.onReload}
                summarySettingsMap={this.summarySettingsMap}
                initDataFlag={this.initDataFlag}
                handleChangeInitDataFlag={(flag) => {
                  this.initDataFlag = flag;
                }}
                anomalyLineChartRedner={isJWT ? undefined : this.anomalyLineChartRedner}
                isJWT={isJWT}
              />
            </div>
          )}
          {predictedData && needPT && (
            <div className="full-height flex-col">
              <LikelyRootCausesVisualizer
                key="PT"
                currentTheme={currentTheme}
                incident={incident}
                systemId={systemId}
                data={trailingEventsData}
                environmentId={environmentId}
                onReload={this.props.onReload}
                summarySettingsMap={this.summarySettingsMap}
                isPT
                handleActionsClick={this.handleActionsClick}
                initDataFlag={this.initDataFlag}
                handleChangeInitDataFlag={(flag) => {
                  this.initDataFlag = flag;
                }}
                anomalyLineChartRedner={isJWT ? undefined : this.anomalyLineChartRedner}
                isJWT={isJWT}
              />
            </div>
          )}
        </div>

        {this.state.showRootCauseChainSelect && (
          <RootCauseChainSelectModal
            allRootCauseAndPredictList={
              operation === 'rootCauseEvents'
                ? get(allRootCauseListMap, 'logRC', [])
                : get(allPredictedListMap, 'logPT', [])
            }
            rootCauseKey={get(activeChain, 'rootCauseKey')}
            incident={rootCauseIncident}
            isLoadingRootCause={isLoading}
            reloadRootCause={() => this.props.onReload()}
            onConfirm={this.handleActionSelectConfirm}
            onClose={() => this.setState({ showRootCauseChainSelect: false })}
          />
        )}
        {this.state.showActionModal && (
          <EventActionModal
            suggestActions={get(activeChain, 'suggestActions', [])}
            incident={rootCauseIncident}
            projectName={rootCauseIncident.projectName}
            projectNameList={this.getProjectNameList()}
            actionRootCauseKey={this.state.actionRootCauseKey}
            onClose={(needReload) => {
              this.setState({ showActionModal: false }, () => {
                if (needReload) this.props.onReload();
              });
            }}
          />
        )}
        {this.state.showRootCausesModal && (
          <RootCausesModal
            environmentId={environmentId}
            systemId={systemId}
            incident={incident}
            activeChain={activeChain}
            onClose={(reload) =>
              this.setState({ showRootCausesModal: false, activeChain: null }, () => {
                if (reload) this.props.onReload();
              })
            }
          />
        )}
      </Spin>
    );
  }
}

const LikelyRootCausesTableRCA = injectIntl(LikelyRootCausesTableRCACore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { projectDisplayMap, systemsMap } = state.app;
    const { userInfo } = state.auth;

    const { dark, jwtToken, customerName } = parseLocation(location);
    let credentials = {};
    if (jwtToken) {
      if (dark) {
        state.app.currentTheme = 'dark';
      } else {
        state.app.currentTheme = 'light';
      }
      credentials = { userName: customerName };
    } else {
      credentials = state.auth?.credentials;
    }
    const { currentTheme } = state.app;

    return {
      location,
      projectDisplayMap,
      systemsMap: systemsMap || {},
      userInfo: userInfo || {},
      credentials,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(LikelyRootCausesTableRCA);
