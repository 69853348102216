/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import { isObject, isEmpty, isBoolean } from 'lodash';
import { setLoaderStatus, showAppLoader, hideAppLoader } from '../app/actions';

/**
 * Get actions for loader, the return object contains show & hide loader action.
 * The default will use the app loader.
 * If loader is null or false, will not get the loader.
 */
const getLoaderAction = (loader: any = true) => {
  let showLoader = null;
  let hideLoader = null;

  if (isObject(loader) && !isEmpty(loader)) {
    // If loader is an object, which contains the name of the loader ui components
    // like { components_loader: true, }
    showLoader = setLoaderStatus(loader);
    hideLoader = setLoaderStatus(R.mapObjIndexed(() => false, loader));
  } else if (isBoolean(loader) && loader) {
    // If loader is true, get the app loader.
    showLoader = showAppLoader();
    hideLoader = hideAppLoader();
  }

  return { showLoader, hideLoader };
};

export default getLoaderAction;
