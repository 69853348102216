import React from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import $ from 'jquery-migrate';
import { get, isNumber, isEqual, replace, isNaN } from 'lodash';
import { connect } from 'react-redux';
import { Dygraph } from '../../artui/react/dataviz';
import { Defaults } from '../../src/common/app';
import { State } from '../../src/common/types';

// Set to true to prevent console output; migrateWarnings still maintained
$.migrateMute = true;

type Props = {
  enableTriangleHighlight: Boolean,
  enableAnnotations: Boolean,
  onDateWindowChange: Function,
  onAnnotationClick: Function,
  dateWindow: any,
  chartType: String,
  project: Object,
  fullWide: Boolean,
  isLogCharts: Boolean,
  selectedInstanceList: any,
  isDark: Boolean,
};

class DataChartCore extends React.PureComponent {
  props: Props;

  static defaultProps = {
    enableTriangleHighlight: true,
    enableAnnotations: false,
    onAnnotationClick: () => {},
    chartType: 'line',
    isLogCharts: false,
    fullWide: true,
  };

  constructor(props) {
    super(props);

    this.dateWindow = [];
    this.lastSelectedInstanceList = [];
  }

  @autobind
  handleBarAnnotationClick(anno) {
    const { onAnnotationClick } = this.props;
    if (onAnnotationClick) {
      onAnnotationClick(anno);
    }
  }

  @autobind
  handleAnnotationClick(anno) {
    if (anno && anno.div) {
      const { x } = anno;
      const { onAnnotationClick } = this.props;
      const dowAnnotations = this.setWeekdaysForBarChar(this.props.data);
      let { annotations } = this.props;
      annotations = annotations || dowAnnotations;

      // Get the annotion from data which has full infos.
      const annotation = R.find((a) => a.x === x, annotations);
      if (annotation) {
        let { text } = annotation;
        const $p = $(anno.div);
        let title = moment.utc(parseInt(anno.x, 10)).format(Defaults.TimeFormat);
        title = `<div class="header">${title}</div>`;
        text = replace(text, /[,;]\n/g, '<br>');
        text = replace(text, /Root cause /g, '');
        text = replace(text, /, metric:/g, '<br>&nbsp;&nbsp;&nbsp;&nbsp;metric:');

        const newTextList = [];
        let tID = 1;
        R.forEach((t) => {
          newTextList.push(`${tID}.${t}`);
          tID += 1;
        }, R.split('\n', text) || []);
        const newText = R.join('\n', newTextList);

        const content = `<div class="content">${newText}</div>`;
        const $html = $(`<div class="dygraph popup-content">${title}${content}</div>`);

        $p.popup({
          on: 'click',
          html: $html,
        });
        $p.popup('show');
      }
    }
  }

  @autobind
  handleClick(e, x) {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(x, { x: e.clientX, y: e.clientY });
    }
  }

  @autobind
  handleDrawCallback(g) {
    const { onDateWindowChange } = this.props;
    if (onDateWindowChange) {
      const dw = g.xAxisRange();
      if (!isEqual(dw, this.dateWindow)) {
        onDateWindowChange(g.xAxisRange());
        this.dateWindow = dw;
      }
    }
  }

  @autobind
  barChartPlotter(e) {
    const { barColors, defaultBarColor } = this.props;
    const ctx = e.drawingContext;
    const { points } = e;
    const yBottom = e.dygraph.toDomYCoord(0);

    // Find the minimum separation between x-values.
    // This determines the bar width.
    let minSep = 8;
    if (points.length === 2) {
      minSep = R.min(points[1].canvasx - points[0].canvasx, 8);
    }
    for (let i = 2; i < points.length - 1; i++) {
      const sep = points[i].canvasx - points[i - 1].canvasx;
      if (sep > 0 && sep < minSep) minSep = sep;
    }

    const barWidth = Math.floor((2.0 / 3) * minSep);

    // Do the actual plotting.
    for (let i = 0; i < points.length; i++) {
      const p = points[i];
      const centerX = p.canvasx;

      ctx.fillStyle = !barColors ? defaultBarColor || e.color : barColors[p.xval] || e.color;
      ctx.strokeStyle = !barColors ? defaultBarColor || e.color : barColors[p.xval] || e.color;

      ctx.fillRect(centerX, p.canvasy, barWidth, yBottom - p.canvasy);
      ctx.strokeRect(centerX, p.canvasy, barWidth, yBottom - p.canvasy);
    }
  }

  @autobind
  getChartColor(index) {
    const { color } = this.props;
    if (color) {
      return color;
    }
    const idx = R.mathMod(index, 5);
    return Defaults.Colorbrewer5[idx];
  }

  @autobind
  multiBarChartPlotter(e) {
    // We need to handle all the series simultaneously.
    if (e.seriesIndex !== 0) return;

    const g = e.dygraph;
    const ctx = e.drawingContext;
    const sets = e.allSeriesPoints;
    const yBottom = e.dygraph.toDomYCoord(0);

    // Find the minimum separation between x-values.
    // This determines the bar width.
    let minSep = Infinity;
    for (let j = 0; j < sets.length; j++) {
      const points = sets[j];
      for (let i = 1; i < points.length; i++) {
        const sep = points[i].canvasx - points[i - 1].canvasx;
        if (sep < minSep) minSep = sep;
      }
    }
    const barWidth = Math.floor((2.0 / 3) * minSep);

    for (let j = 0; j < sets.length; j++) {
      ctx.fillStyle = this.getChartColor(j);
      ctx.strokeStyle = this.getChartColor(j);
      for (let i = 0; i < sets[j].length; i++) {
        const p = sets[j][i];
        const centerX = p.canvasx;
        const xLeft = centerX - barWidth / 2 + j * (barWidth / sets.length);

        ctx.fillRect(xLeft, p.canvasy, barWidth / sets.length, yBottom - p.canvasy);

        ctx.strokeRect(xLeft, p.canvasy, barWidth / sets.length, yBottom - p.canvasy);
      }
    }
  }

  @autobind
  multiStackBarChartPlotter(e) {
    // We need to handle all the series simultaneously.
    if (e.seriesIndex !== 0) return;

    const g = e.dygraph;
    const ctx = e.drawingContext;
    // ignore sum list
    const sets = R.slice(1, Infinity, e.allSeriesPoints);
    const yBottom = e.dygraph.toDomYCoord(0);

    // Find the minimum separation between x-values.
    // This determines the bar width.
    let minSep = Infinity;
    for (let j = 0; j < sets.length; j++) {
      const points = sets[j];
      for (let i = 1; i < points.length; i++) {
        const sep = points[i].canvasx - points[i - 1].canvasx;
        if (sep < minSep) minSep = sep;
      }
    }
    const barWidth = Math.floor((2.0 / 3) * minSep);
    const barHeight = {};

    for (let j = 0; j < sets.length; j++) {
      ctx.fillStyle = this.getChartColor(j);
      ctx.strokeStyle = this.getChartColor(j);
      for (let i = 0; i < sets[j].length; i++) {
        const p = sets[j][i];
        const centerX = p.canvasx;
        const xLeft = centerX - barWidth / 2;

        if (!R.has(i, barHeight)) {
          barHeight[i] = 0;
        }

        // const barHeightOne = yBottom - p.canvasy;
        // barHeight[i] += barHeightOne;
        // p.canvasy = yBottom - barHeight[i];
        // p.y = p.canvasy / yBottom;
        // ctx.fillRect(xLeft, p.canvasy, barWidth, barHeightOne);
        // ctx.strokeRect(xLeft, p.canvasy, barWidth, barHeightOne);

        const barHeightOne = yBottom - p.canvasy;
        ctx.fillRect(xLeft, p.canvasy - barHeight[i], barWidth, barHeightOne);
        ctx.strokeRect(xLeft, p.canvasy - barHeight[i], barWidth, barHeightOne);
        barHeight[i] += barHeightOne;
      }
    }
  }

  @autobind
  setWeekdaysForBarChar() {
    const { data, chartType } = this.props;

    const days = [
      ['Su', 'Sunday'],
      ['Mo', 'Monday'],
      ['Tu', 'Tuesday'],
      ['We', 'Wednesday'],
      ['Th', 'Thursday'],
      ['Fr', 'Friday'],
      ['Sa', 'Saturday'],
    ];

    if (chartType === 'bar' && data.sdata && data.sname) {
      const yname = data.sname[1];
      const annotations = [];
      // Skip the last one to make annotation looks better.
      for (let i = 0; i < data.sdata.length - 1; i += 1) {
        const value = data.sdata[i];
        if (isNumber(value[1]) && !isNaN(value[1])) {
          const x = moment.utc(value[0]);
          const wd = x.weekday();
          annotations.push({
            series: yname,
            x: x.valueOf(),
            shortText: days[wd][0],
            text: days[wd][1],
          });
        }
      }

      return annotations;
    }

    return data.annotations;
  }

  @autobind
  getInstanceData(data) {
    const { project, selectedInstanceList } = this.props;
    const { instanceMetricMap } = data;
    let { sdata, sname } = data;

    // Insert null if missing timestamp, get the minimal interval
    if (sdata && sdata.length > 0) {
      let minInterval = Infinity;
      for (let i = 1; i < sdata.length; i += 1) {
        const diff = sdata[i][0].valueOf() - sdata[i - 1][0].valueOf();
        if (diff > 0 && diff < minInterval) {
          minInterval = diff;
        }
      }
      let interval = get(project, 'samplingIntervalInSecond', 0) * 1000 || minInterval;
      if (interval !== Infinity) {
        const newSData = [];
        newSData.push(sdata[0]);
        interval *= 12; // Only insert null if have big gap
        for (let i = 1; i < sdata.length; i += 1) {
          const diff = sdata[i][0].valueOf() - sdata[i - 1][0].valueOf();
          if (diff >= interval) {
            // Push empty
            const vals = new Array(sdata[i].length).fill(null);
            vals[0] = new Date(sdata[i - 1][0].valueOf() + minInterval);
            newSData.push(vals);
          }
          newSData.push(sdata[i]);
        }
        sdata = newSData;
      }
    }

    const diff = R.difference(selectedInstanceList || [], this.lastSelectedInstanceList || []);

    if (diff.length === 0 && this.lastSData === sdata && this.lastSName === sname) {
      sdata = this.lastSData;
      sname = this.lastSName;
    } else if (selectedInstanceList) {
      const metricList = R.uniq(
        R.reduce(
          (acc, i) => {
            return R.uniq(acc.concat(instanceMetricMap[i] || []));
          },
          [],
          selectedInstanceList,
        ),
      );

      const indics = R.reduce(
        (acc, m) => {
          const idx = R.indexOf(m, sname);
          return idx >= 0 ? [...acc, idx] : acc;
        },
        [],
        metricList,
      );

      if (indics.length > 0) {
        // Selet the items in the list.
        sname = [
          sname[0],
          ...R.addIndex(R.filter)((s, idx) => {
            return R.findIndex((i) => i === idx, indics) > -1;
          }, sname),
        ];

        sdata = R.map((s) => {
          return [
            s[0], // timestamp
            ...R.addIndex(R.filter)((a, idx) => {
              return R.findIndex((i) => i === idx, indics) > -1;
            }, s),
          ];
        }, sdata);
      }
      this.lastSData = sdata;
      this.lastSName = sname;
      this.lastSelectedInstanceList = selectedInstanceList;
    } else {
      this.lastSData = sdata;
      this.lastSName = sname;
      this.lastSelectedInstanceList = selectedInstanceList;
    }

    return { sdata, sname };
  }

  render() {
    const { className } = this.props;
    let {
      data,
      enableAnnotations,
      enableTriangleHighlight,
      chartType,
      onDateWindowChange,
      dateWindow,
      latestDataTimestamp,
      fullWide,
      isEmailAert,
      eventEndTime,
      eventStartTime,
      annotations,
      onClick,
      isLogCharts,
      style,
      isDark,
    } = this.props;
    const dowAnnotations = this.setWeekdaysForBarChar(data);
    annotations = annotations || dowAnnotations;
    const listenDrawCallback = !!onDateWindowChange;
    let { sdata, sname } = this.getInstanceData(data);
    // Remove the empty [].
    sname = R.map((sn) => R.replace(/\[\]/g, '', sn), sname);
    return (
      <Dygraph
        style={{ width: '100%', height: '200px', ...style }}
        isLogCharts={isLogCharts}
        chartType={chartType}
        className={`${className} ${chartType} ${fullWide ? 'full-wide' : 'narrow-wide'}`}
        axisLineColor="gray"
        axisLabelColor="var(--text-color)"
        axisLabelWidth={55}
        highlightCircleSize={2}
        strokeWidth={2}
        labelsDivStyles={{
          zIndex: 100,
          padding: '4px',
          maxHeight: '100px',
          overflow: 'auto',
          fontSize: '12px',
          top: '-20px',
          right: '-10px',
          left: 'auto',
          background: 'rgba(15, 26, 30, 0.9)',
          color: 'white',
          border: '1px solid rgba(15, 26, 30, 1)',
        }}
        highlightSeriesOpts={{ strokeWidth: 2, strokeBorderWidth: 1, highlightCircleSize: 3 }}
        highlightSeriesBackgroundAlpha={isDark ? 0.9 : 0.3}
        includeZero
        data={sdata}
        ylabel={data.unit}
        labels={sname}
        highlights={data.highlights}
        highlightStartTime={eventStartTime}
        highlightEndTime={eventEndTime}
        latestDataTimestamp={latestDataTimestamp}
        isEmailAert={isEmailAert}
        drawCallback={listenDrawCallback ? this.handleDrawCallback : null}
        {...(dateWindow ? { dateWindow } : {})}
        annotations={enableAnnotations || chartType === 'bar' ? annotations : null}
        {...(chartType === 'bar'
          ? { plotter: this.barChartPlotter }
          : chartType === 'multiBar'
          ? { plotter: this.multiBarChartPlotter }
          : chartType === 'multiStackBar'
          ? { plotter: this.multiStackBarChartPlotter }
          : {})}
        onAnnotationClick={chartType === 'bar' ? this.handleBarAnnotationClick : this.handleAnnotationClick}
        enableTriangleHighlight={enableTriangleHighlight}
        onClick={onClick ? this.handleClick : null}
      />
    );
  }
}

export default connect((state: State) => {
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';

  return { isDark };
})(DataChartCore);
