/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import { get, isNumber } from 'lodash';

import moment from 'moment/moment';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseJSON, EndPointParser } from '../utils';

import parseMetricSettings from './parsers/parseMetricSettings';
import fetchPost from './fetchPost';

const loadProjectSettings = async (credentials: Credentials, params: Object) => {
  const [projectName, customerName] = params.projectName.split('@');
  const requests = [];

  requests.push(
    fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
      ...credentials,
      projectList: JSON.stringify([{ projectName, customerName }]),
      startTime: moment.utc().startOf('day').subtract(1, 'days').valueOf(),
      endTime: moment.utc().endOf('day').valueOf(),
      includeInstance: true,
    }),
  );

  requests.push(
    fetchGet(getEndpoint('project-setting', 2), {
      ...credentials,
      projectList: JSON.stringify([{ projectName, customerName }]),
    }),
  );

  return Promise.all(requests).then((results) => {
    const { instanceStructureSet = [] } = results[0]?.data[0];

    let instanceNameList = [];
    R.forEach((inc) => {
      const { i, c } = inc || {};
      if (i) {
        instanceNameList = [...instanceNameList, i];
        R.forEach((item) => {
          instanceNameList = [...instanceNameList, `${item}_${i}`];
        }, c || []);
      }
    }, instanceStructureSet || []);

    const res = results[1];
    const { settingList } = res;

    let settings = settingList[projectName];
    try {
      settings = JSON.parse(settings);
    } catch (error) {
      settings = {};
    }
    const { DATA } = settings;
    const rawData = R.clone(DATA);

    const webhookUrl = get(rawData, 'webhookUrl', '');
    const webhookAlertDampening = get(rawData, 'webhookAlertDampening', '');
    const maxWebHookRequestSize = get(rawData, 'maxWebHookRequestSize', 1);
    const trainingFilter = get(rawData, 'trainingFilter', false);
    const proxy = get(rawData, 'proxy', '');
    let modelSpanRangeStr = get(rawData, 'modelSpanRangeStr');
    try {
      modelSpanRangeStr = JSON.parse(modelSpanRangeStr);
    } catch (error) {
      modelSpanRangeStr = { trainingStartTime: null, trainingEndTime: null };
    }

    let modelSpan = get(rawData, 'modelSpan');
    modelSpan = isNumber(modelSpan) ? String(modelSpan) : modelSpan;

    const linkedLogProjects = get(rawData, 'linkedLogProjects', []);

    const enableMetricDataPrediction = get(rawData, 'enableMetricDataPrediction');

    const pValue = get(rawData, 'pValue', '0.9');
    const cValue = get(rawData, 'cValue', '5');

    const disableLogCompressEvent = get(rawData, 'disableLogCompressEvent', false);
    const predictionWindow = get(rawData, 'predictionWindow', '4');
    const learningSkippingPeriod = get(rawData, 'learningSkippingPeriod ', '');
    const detectionSkippingPeriod = get(rawData, 'detectionSkippingPeriod', '');
    const emailpvalue = get(rawData, 'emailpvalue', '0.9');
    const emailcvalue = get(rawData, 'emailcvalue', '5');

    const derivedpvalue = get(rawData, 'derivedpvalue', '0.9');
    const logFreqWindow = get(rawData, 'logFreqWindow', '10');
    const rareEventAlertThresholds = get(rawData, 'rareEventAlertThresholds', '10');
    const newPatternRange = get(rawData, 'newPatternRange', 1);
    const similaritySensitivity = get(rawData, 'similaritySensitivity', '');
    const whitelistNumberLimit = get(rawData, 'whitelistNumberLimit', '');
    const newPatternNumberLimit = get(rawData, 'newPatternNumberLimit', '');
    const incidentPredictionWindow = get(rawData, 'incidentPredictionWindow', '36');
    const minIncidentPredictionWindow = get(rawData, 'minIncidentPredictionWindow', '');
    const incidentRelationSearchWindow = get(rawData, 'incidentRelationSearchWindow', '');
    const incidentEventLimit = get(rawData, 'incidentEventLimit', '');
    const incidentPredictionEventLimit = get(rawData, 'incidentPredictionEventLimit', '');
    const predictionCorrelationSensitivity = String(get(rawData, 'predictionCorrelationSensitivity', 0.5));
    const rootCauseCountThreshold = get(rawData, 'rootCauseCountThreshold', '');
    const rootCauseProbabilityThreshold = get(rawData, 'rootCauseProbabilityThreshold', '');
    const rootCauseLogMessageSearchRange = get(rawData, 'rootCauseLogMessageSearchRange', '');

    const causalPredictionSetting = String(get(rawData, 'causalPredictionSetting', 0));
    const rootCauseRankSetting = String(get(rawData, 'rootCauseRankSetting', 0));
    const normalEventCausalFlag = get(rawData, 'normalEventCausalFlag', false);
    const dailyModelSpan = get(rawData, 'dailyModelSpan', '0');
    const keywordFeatureNumber = get(rawData, 'keywordFeatureNumber', '');
    const maxLogModelSize = get(rawData, 'maxLogModelSize', '');
    const modelKeywordSetting = String(get(rawData, 'modelKeywordSetting', 0));
    const featureAnalysisFlag = get(rawData, 'featureAnalysisFlag', false);
    const projectModelFlag = get(rawData, 'projectModelFlag', false);
    const largeProject = get(rawData, 'largeProject', false);
    const zoneNameKey = get(rawData, 'zoneNameKey', '');
    const nlpFlag = get(rawData, 'nlpFlag', false);
    const collectAllRareEventsFlag = get(rawData, 'collectAllRareEventsFlag', false);
    const newAlertFlag = get(rawData, 'newAlertFlag', false);
    const rareNumberLimit = get(rawData, 'rareNumberLimit', '');
    let rareAnomalyType = get(rawData, 'rareAnomalyType');
    rareAnomalyType = isNumber(rareAnomalyType) ? String(rareAnomalyType) : rareAnomalyType;
    const hotNumberLimit = get(rawData, 'hotNumberLimit', '');
    const logPatternLimitLevel = get(rawData, 'logPatternLimitLevel', '1024');
    const coldNumberLimit = get(rawData, 'coldNumberLimit', '');
    const coldEventThreshold = get(rawData, 'coldEventThreshold', '');
    const hotEventThreshold = get(rawData, 'hotEventThreshold', '');
    const hotEventDetectionMode = String(get(rawData, 'hotEventDetectionMode', 1));
    const causalPredictionCacheTimeout = get(rawData, 'causalPredictionCacheTimeout', '0');
    const causalMinDelay = get(rawData, 'causalMinDelay', '0');
    const maximumRootCauseResultSize = get(rawData, 'maximumRootCauseResultSize', '');
    const multiHopSearchLevel = get(rawData, 'multiHopSearchLevel', '');
    const multiHopSearchLimit = get(rawData, 'multiHopSearchLimit', '');
    const avgPerIncidentDowntimeCost = get(rawData, 'avgPerIncidentDowntimeCost');
    const alertHourlyCost = get(rawData, 'alertHourlyCost');
    const alertAverageTime = get(rawData, 'alertAverageTime');
    const enableKnowledgeBasedPrediction = get(rawData, 'enableKnowledgeBasedPrediction');
    const enableKPIPrediction = get(rawData, 'enableKPIPrediction');
    const enablePeriodAnomalyFilter = get(rawData, 'enablePeriodAnomalyFilter');
    const enableCumulativeDetect = get(rawData, 'enableCumulativeDetect');
    const enableUBLDetect = get(rawData, 'enableUBLDetect');
    const projectDisplayName = get(rawData, 'projectDisplayName');

    let logAnomalyEventBaseScore = get(rawData, 'logAnomalyEventBaseScore');
    if (logAnomalyEventBaseScore) {
      try {
        logAnomalyEventBaseScore = JSON.parse(logAnomalyEventBaseScore);
      } catch (e) {
        // console.error(e);
      }
    }
    logAnomalyEventBaseScore = logAnomalyEventBaseScore || [75, 0.1, 0.075, 1, 40];

    const maximumThreads = get(rawData, 'maximumThreads', 1);
    const logDetectionMinCount = get(rawData, 'logDetectionMinCount', 1000);
    const logCacheSize = get(rawData, 'logCacheSize', 1000);
    const logDetectionSize = get(rawData, 'logDetectionSize', 1000);
    const maximumDetectionWaitTime = get(rawData, 'maximumDetectionWaitTime', '');
    const keywordSetting = get(rawData, 'keywordSetting', '0');

    const maximumHint = get(rawData, 'maximumHint');
    const dynamicBaselineDetectionFlag = get(rawData, 'dynamicBaselineDetectionFlag');
    const enableBaselineDetectionDoubleVerify = get(rawData, 'enableBaselineDetectionDoubleVerify');
    const positiveBaselineViolationFactor = get(rawData, 'positiveBaselineViolationFactor', 0);
    const negativeBaselineViolationFactor = get(rawData, 'negativeBaselineViolationFactor', 0);

    const instanceType = get(rawData, 'instanceType', '');
    const endPointConfig = parseJSON(get(rawData, 'endPointConfig'));

    const retentionTime = get(rawData, 'retentionTime', '');
    const fillNaNZero = get(rawData, 'fillNaNZero', false);
    let minValidModelSpan = get(rawData, 'minValidModelSpan', '');
    if (minValidModelSpan) minValidModelSpan = Number(minValidModelSpan) / 60000;
    const predictionTrainingDataLength = get(rawData, 'predictionTrainingDataLength', '');

    const jdbcCommands = get(rawData, 'jdbcCommands', '');

    const splunkDomain = get(rawData, 'splunkDomain', '');
    const splunkToken = get(rawData, 'splunkToken', '');
    const ignoreInstanceForKB = get(rawData, 'ignoreInstanceForKB', false);
    const showInstanceDown = get(rawData, 'showInstanceDown', false);

    const nameKey = EndPointParser.getEndPointNameKey(get(endPointConfig, 'ElasticSearch', []));
    const elasticSearchEndPointList = EndPointParser.zipEndPointList(get(endPointConfig, 'ElasticSearch', []));
    const elasticSearchUser = nameKey.name;
    const elasticSearchPassword = nameKey.key;

    // mapreduce endpoint
    const mapReduceDataNode = EndPointParser.zipEndPointList(get(endPointConfig, 'MapReduce_DataNode', []));
    const mapReduceMasterHistory = EndPointParser.zipEndPointList(get(endPointConfig, 'MapReduce_Master_History', []));
    const mapReduceMasterNodeManager = EndPointParser.zipEndPointList(
      get(endPointConfig, 'MapReduce_Master_NodeManager', []),
    );
    const mapReduceMasterResource = EndPointParser.zipEndPointList(
      get(endPointConfig, 'MapReduce_Master_Resource', []),
    );
    const mapReduceNameNode = EndPointParser.zipEndPointList(get(endPointConfig, 'MapReduce_NameNode', []));
    const mapReduceYARN = EndPointParser.zipEndPointList(get(endPointConfig, 'MapReduce_YARN', []));

    // hbase endpoint
    const hbaseMasterNodesList = EndPointParser.zipEndPointList(get(endPointConfig, 'HBase_Master', []));
    const hbaseNameNodeList = EndPointParser.zipEndPointList(get(endPointConfig, 'HBase_NameNode', []));
    const hbaseDataNodeList = EndPointParser.zipEndPointList(get(endPointConfig, 'HBase_DataNode', []));
    const habseYarnManagerList = EndPointParser.zipEndPointList(get(endPointConfig, 'HBase_YARN', []));
    const hbaseRegionServerList = EndPointParser.zipEndPointList(get(endPointConfig, 'HBase_RegionServer', []));

    // sample interval
    let samplingInterval = get(rawData, 'samplingInterval', '');
    samplingInterval = parseInt(samplingInterval, 10);
    const highRatioCValue = get(rawData, 'highRatioCValue', 0);

    const shouldConfigSamplingInterval = get(rawData, 'shouldConfigSamplingInterval', false);
    const instanceToApp = get(rawData, 'instanceToApp', {});
    let instanceList = R.map(
      (instanceId) => ({
        instanceId,
        instanceName:
          instanceToApp[instanceId] && instanceToApp[instanceId].indexOf(instanceId) === -1
            ? `${instanceId} (${instanceToApp[instanceId]})`
            : instanceId,
      }),
      R.keys(instanceToApp),
    );

    if ((instanceList || []).length === 0) {
      instanceList = instanceNameList.map((instanceName) => ({
        instanceId: instanceName,
        instanceName,
      }));
    }

    // The metricUnitMapping and metricSettings is a string, converted it into array.
    // Meanwhile, needs merge metric info from both string.
    const metricSettings = JSON.parse(get(rawData, 'metricSettings', '[]'));
    const metricListCount = JSON.parse(get(rawData, 'metricListCount', 0));
    const metricUnitMapping = JSON.parse(get(rawData, 'metricUnitMapping', '[]'));

    const enableHotEvent = get(rawData, 'enableHotEvent', false);
    const anomalyDetectionMode = get(rawData, 'anomalyDetectionMode', 0);
    const enableFillGap = get(rawData, 'enableFillGap', false);
    const webhookHeaderList = get(rawData, 'webhookHeaderList', []);
    const webhookTypeSetStr = JSON.parse(get(rawData, 'webhookTypeSetStr', '[]'));
    const metrics = parseMetricSettings(metricSettings, metricUnitMapping);
    const baseValueSetting = get(rawData, 'baseValueSetting', {});
    const anomalyGapToleranceCount = get(rawData, 'anomalyGapToleranceCount', 0);
    let webhookBlackListSetStr = get(rawData, 'webhookBlackListSetStr', '[]');
    if (webhookBlackListSetStr === 'null') webhookBlackListSetStr = '[]';
    webhookBlackListSetStr = JSON.parse(webhookBlackListSetStr);
    let webhookCriticalKeywordSetStr = get(rawData, 'webhookCriticalKeywordSetStr', '[]');
    if (webhookCriticalKeywordSetStr === 'null') webhookCriticalKeywordSetStr = '[]';
    webhookCriticalKeywordSetStr = JSON.parse(webhookCriticalKeywordSetStr);
    const prettyJsonConvertorFlag = get(rawData, 'prettyJsonConvertorFlag', false);
    const incidentConsolidationInterval = get(rawData, 'incidentConsolidationInterval', 600000);
    const multiLineFlag = get(rawData, 'multiLineFlag', false);
    const featureOutlierSensitivity = get(rawData, 'featureOutlierSensitivity', 'medium');
    const incidentCountThreshold = get(rawData, 'incidentCountThreshold', 5);
    const incidentDurationThreshold = get(rawData, 'incidentDurationThreshold', 600000);
    const disableModelKeywordStatsCollection = get(rawData, 'disableModelKeywordStatsCollection', false);
    const filterByAnomalyInBaselineGeneration = get(rawData, 'filterByAnomalyInBaselineGeneration', false);
    const baselineDuration = get(rawData, 'baselineDuration', 86400000);
    const cdfSetting = R.filter((item) => item.scoreKey, get(rawData, 'cdfSetting', []));
    const UBLRetentionTime = get(rawData, 'UBLRetentionTime', 0);
    const anomalyDampening = get(rawData, 'anomalyDampening', 0);
    const instanceConvertFlag = get(rawData, 'instanceConvertFlag', false);
    const compositeRCALimit = get(rawData, 'compositeRCALimit', 1);
    const isGroupingByInstance = get(rawData, 'isGroupingByInstance', false);

    const data = {
      retentionTime,
      fillNaNZero,
      trainingFilter,
      minValidModelSpan,
      modelSpan,
      linkedLogProjects,
      modelSpanRangeStr,
      proxy,
      webhookUrl,
      webhookAlertDampening,
      maxWebHookRequestSize,
      instanceType,

      jdbcCommands,

      splunkDomain,
      splunkToken,

      elasticSearchEndPointList,
      elasticSearchUser,
      elasticSearchPassword,

      mapReduceDataNode,
      mapReduceMasterHistory,
      mapReduceMasterNodeManager,
      mapReduceMasterResource,
      mapReduceNameNode,
      mapReduceYARN,

      hbaseMasterNodesList,
      hbaseNameNodeList,
      hbaseDataNodeList,
      habseYarnManagerList,
      hbaseRegionServerList,

      predictionWindow,
      learningSkippingPeriod,
      detectionSkippingPeriod,
      pValue,
      cValue,
      emailpvalue,
      emailcvalue,
      maximumHint,
      derivedpvalue,
      dynamicBaselineDetectionFlag,
      enableBaselineDetectionDoubleVerify,
      enablePeriodAnomalyFilter,
      enableCumulativeDetect,
      enableUBLDetect,
      enableMetricDataPrediction,
      positiveBaselineViolationFactor,
      negativeBaselineViolationFactor,

      maximumThreads,
      logDetectionMinCount,
      logCacheSize,
      logDetectionSize,
      maximumDetectionWaitTime,
      keywordSetting,
      nlpFlag,
      rareEventAlertThresholds,
      newPatternRange,
      normalEventCausalFlag,
      dailyModelSpan,
      keywordFeatureNumber,
      maxLogModelSize,
      modelKeywordSetting,
      featureAnalysisFlag,
      projectModelFlag,
      largeProject,
      zoneNameKey,
      collectAllRareEventsFlag,
      newAlertFlag,
      similaritySensitivity,
      whitelistNumberLimit,
      newPatternNumberLimit,
      incidentPredictionWindow,
      minIncidentPredictionWindow,
      incidentRelationSearchWindow,
      incidentEventLimit,
      incidentPredictionEventLimit,
      predictionCorrelationSensitivity,
      rootCauseCountThreshold,
      rootCauseProbabilityThreshold,
      rootCauseLogMessageSearchRange,
      causalPredictionSetting,
      rootCauseRankSetting,
      logAnomalyEventBaseScore,
      causalPredictionCacheTimeout,
      causalMinDelay,
      maximumRootCauseResultSize,
      multiHopSearchLevel,
      multiHopSearchLimit,
      avgPerIncidentDowntimeCost,
      alertHourlyCost,
      alertAverageTime,
      enableKnowledgeBasedPrediction,
      enableKPIPrediction,

      rareNumberLimit,
      rareAnomalyType,
      hotNumberLimit,
      logPatternLimitLevel,
      coldNumberLimit,
      coldEventThreshold,
      hotEventThreshold,
      hotEventDetectionMode,
      instanceStructureSet,
      logFreqWindow,
      metrics,
      metricListCount,
      instanceToApp,
      instanceList,
      samplingInterval,
      shouldConfigSamplingInterval,
      predictionTrainingDataLength,
      anomalyGapToleranceCount,
      ignoreInstanceForKB,
      highRatioCValue,
      disableLogCompressEvent,
      showInstanceDown,
      enableHotEvent,
      projectDisplayName,
      anomalyDetectionMode,
      enableFillGap,
      webhookHeaderList,
      webhookTypeSetStr,
      baseValueSetting,
      webhookBlackListSetStr,
      webhookCriticalKeywordSetStr,
      prettyJsonConvertorFlag,
      incidentConsolidationInterval,
      multiLineFlag,
      featureOutlierSensitivity,
      incidentCountThreshold,
      incidentDurationThreshold,
      disableModelKeywordStatsCollection,
      filterByAnomalyInBaselineGeneration,
      baselineDuration,
      cdfSetting,
      UBLRetentionTime,
      anomalyDampening,
      instanceConvertFlag,
      compositeRCALimit,
      isGroupingByInstance,
    };

    return {
      rawData,
      data,
    };
  });
};

export default loadProjectSettings;
