/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2024
 * *****************************************************************************
 * */

import * as R from 'ramda';
import moment from 'moment';
import parseEventList from './parsers/parseEventList';
import parseIncidentList from './parsers/parseIncidentList';
import parseDeploymentList from './parsers/parseDeploymentList';
import { metricUnitParser } from './magicParsers';

function formatNormalEventList(results, instanceGroup, eventType, filterPredict, nowTimestamp) {
  let eventList = [];
  let incidentList = [];
  let deploymentList = [];
  if (results.length > 0) {
    for (let i = 0; i < results.length; i += 1) {
      eventList = R.concat(
        eventList,
        parseEventList(results[i].data, { instanceGroup, isPrediction: eventType === 'predicted' }),
      );
      incidentList = R.concat(incidentList, parseIncidentList(results[i].incidentResult, {}));
      deploymentList = R.concat(deploymentList, parseDeploymentList(results[i].deploymentResult, {}));
    }
  }
  // Get the metric name to metric short name and unit mapping.
  const metricUnitMap = {};
  const metricShortNameMap = {};
  const data1 = results[0] || {};
  R.forEach((m) => {
    const { unit, shortName, metric } = m;
    metricUnitMap[metric] = metricUnitParser(unit);
    metricShortNameMap[metric] = shortName;
  }, data1.metricUnitMapping || []);

  // filter and merge
  if (eventType === 'predicted' && filterPredict) {
    const nowTime = nowTimestamp || moment.utc().valueOf();
    eventList = R.map((item) => {
      const { rootCauseJson } = item;
      const { rootCauseDetailsArr } = rootCauseJson || {};
      const filterRC = R.map((rc) => {
        const { timePairArr } = rc || {};
        const filterPair = R.filter((tr) => tr.endTimestamp >= nowTime, timePairArr || []);
        return { ...rc, timePairArr: filterPair };
      }, rootCauseDetailsArr || []);
      return { ...item, rootCauseJson: { ...rootCauseJson, rootCauseDetailsArr: filterRC } };
    }, eventList);
  }
  eventList = [...eventList, ...incidentList, ...deploymentList];
  eventList = R.sortWith(
    [R.descend(R.prop('maxAnomalyScore')), R.descend(R.prop('duration')), R.descend(R.prop('startTimestamp'))],
    eventList,
  );

  // get max duration
  const maxDuration = R.reduce(
    R.max,
    0,
    R.map((event) => event.duration, eventList),
  );

  const eventListMap = {
    eventList,
    metricUnitMap,
    metricShortNameMap,
    maxDuration,
  };
  return eventListMap;
}

function formatWithPrediction(results, instanceGroup, eventType, filterPredict, nowTimestamp) {
  const data1 = results[0];
  const data2 = results[1];
  const eventList1 = parseEventList(data1.data, { instanceGroup, isDetectedAnomaly: true });
  const incidentList = parseIncidentList(data1.incidentResult, {});
  const deploymentList = parseDeploymentList(data1.deploymentResult, {});
  let eventList2 = parseEventList(data2.data, { instanceGroup, isPrediction: true });
  if (filterPredict) {
    const nowTime = nowTimestamp || moment.utc().valueOf();
    eventList2 = R.map((item) => {
      const { rootCauseJson } = item;
      const { rootCauseDetailsArr } = rootCauseJson || {};
      const filterRC = R.map((rc) => {
        const { timePairArr } = rc || {};
        const filterPair = R.filter((tr) => tr.endTimestamp >= nowTime, timePairArr || []);
        return { ...rc, timePairArr: filterPair };
      }, rootCauseDetailsArr || []);
      return { ...item, rootCauseJson: { ...rootCauseJson, rootCauseDetailsArr: filterRC } };
    }, eventList2);
  }

  // Get the metric name to metric short name and unit mapping.
  const metricUnitMap = {};
  const metricShortNameMap = {};
  R.forEach((m) => {
    const { unit, shortName, metric } = m;
    metricUnitMap[metric] = metricUnitParser(unit);
    metricShortNameMap[metric] = shortName;
  }, data1.metricUnitMapping || []);

  // rewrite id for detect and predicted events
  let eventList = [...eventList1, ...eventList2];
  eventList = [...eventList, ...incidentList, ...deploymentList];
  eventList = R.sort((eventA, eventB) => eventB.startTimestamp - eventA.startTimestamp, eventList);

  // get max duration
  const maxDuration = R.reduce(
    R.max,
    0,
    R.map((event) => event.duration, eventList),
  );

  const eventListMap = {
    eventList,
    metricUnitMap,
    metricShortNameMap,
    maxDuration,
  };
  return eventListMap;
}
export { formatNormalEventList, formatWithPrediction };
