import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button } from 'antd';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';

import { BaseComponent, Link } from '../../artui/react';
import { hideAppLoader } from '../../src/common/app/actions';
import { CenterPage } from '../../src/web/app/components';

import { appFieldsMessages, appButtonsMessages } from '../../src/common/app/messages';
import { authMessages } from '../../src/common/auth/messages';

class ForgotPasswordCore extends BaseComponent {
  constructor(props) {
    super(props);
    this.$el = null;

    this.state = {
      userName: '',
      email: '',
      error: '',
    };
  }

  componentDidMount() {
    this.props.hideAppLoader();
    if (this.$el) {
      this.$el.find('.ui.submit.button').api({
        action: 'get temp password',
        method: 'POST',
        beforeSend: (settings) => {
          this.setState({
            error: '',
          });
          settings.data = {
            userName: this.state.userName,
            email: this.state.email,
          };
          return settings;
        },
        beforeXHR(xhr) {
          xhr.setRequestHeader('accept', 'application/json');
          return xhr;
        },
        onSuccess: (resp) => {
          alert(resp.message);
          window.location.href = '/auth/login2';
        },
        onFailure: (resp) => {
          if (resp && resp.message) {
            this.setState({
              error: resp.message,
            });
          }
        },
      });
    }
  }

  render() {
    const { intl, push, currentTheme } = this.props;
    const { email, error, userName } = this.state;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const disabled = !re.test(email) || !userName;

    return (
      <CenterPage intl={intl} push={push} className="auth" currentTheme={currentTheme}>
        <h3 style={{ textAlign: 'center' }}>{intl.formatMessage(authMessages.hintsGetPassword)}</h3>
        <form className={cx('ui', { error: !!error }, 'form login_class')} ref={(c) => (this.$el = $(c))}>
          {!!error && <div className="ui error mini message">{error}</div>}
          <div className="ui left aligned">
            <div className="field required">
              <label>{intl.formatMessage(appFieldsMessages.userName)}</label>
              <div className="ui icon input">
                <i className="user icon" />
                <input
                  type="text"
                  name="userName"
                  value={userName}
                  onChange={(e) => this.setState({ error: '', userName: e.target.value })}
                />
              </div>
            </div>
            <div className="field required">
              <label>{intl.formatMessage(appFieldsMessages.email)}</label>
              <div className="ui icon input">
                <i className="mail icon" />
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => this.setState({ error: '', email: e.target.value })}
                />
              </div>
            </div>
            <div className="field" style={{ textAlign: 'right' }}>
              <span style={{ marginRight: 8 }}>{intl.formatMessage(authMessages.hintsForgot)}</span>
              <Link tabIndex={-1} to="/auth/forgotUsername">
                {intl.formatMessage(authMessages.hintsUserName)}
              </Link>
            </div>
            <div className="field">
              <div
                className={cx('ui fluid submit button', { disabled })}
                style={{ background: '#FF5142', color: 'white' }}
              >
                {intl.formatMessage(appButtonsMessages.confirm)}
              </div>
            </div>
            <div className="field" style={{ borderTop: '1px solid #ccc', paddingTop: 10, textAlign: 'right' }}>
              <Link to="/auth/login2">
                <Button type="primary" disabled={disabled} onClick={(event) => event.stopPropagation()} ghost>
                  {intl.formatMessage(authMessages.buttonsSignIn)}
                </Button>
              </Link>
            </div>
          </div>
        </form>
      </CenterPage>
    );
  }
}

const ForgotPassword = injectIntl(ForgotPasswordCore);
export default connect(
  (state) => ({
    currentTheme: state.app.currentTheme,
  }),
  { push, hideAppLoader },
)(ForgotPassword);
