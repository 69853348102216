/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from 'react-virtualized';
import { autobind } from 'core-decorators';
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DoubleLeftOutlined,
} from '@ant-design/icons';
import { Menu, Select, Spin } from 'antd';
import { Container, Tooltip, Table, Column, AutoSizer, Popover, Dropdown } from '../../../lib/fui/react';

import { State } from '../../../common/types';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/log/actions';
import { CellRenderers, Defaults, buildUrl, getLoadStatus } from '../../../common/utils';
import { logMessages } from '../../../common/log/messages';
import { eventMessages } from '../../../common/metric/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import TimeSelectModal from '../../metric/components/TimeSelectModal';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import BaseUrls from '../../app/BaseUrls';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  instanceName: String,
  // eslint-disable-next-line
  incident: Object,
  // eslint-disable-next-line
  clusterActiveTimeChange: Number,
  // eslint-disable-next-line
  startTimestamp: Number,
  // eslint-disable-next-line
  endTimestamp: Number,
  // eslint-disable-next-line
  featureInfo: Object,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  // eslint-disable-next-line
  logEntryDaySampleMsg: String,
  // eslint-disable-next-line
  logEntryDayListTotal: Number,
  currentTheme: String,
  instanceDisplayNameMap: Object,
};

class LogEntryDayContentCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.dataLoader = 'log_day_data_content';
    this.clusterSampleMsgs = {};
    this.keywords = '';
    this.pageSize = 200;
    this.state = {
      eventList: [],
      pageNo: 1,
      showHighlightCategory: 'none',
      allExpand: true,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
      contextTime: 60 * 1000,
      contextKeywordFilter: '',
    };
    this.highlightOptions = [
      { label: intl.formatMessage(logMessages.none), value: 'none' },
      { label: intl.formatMessage(logMessages.common), value: 'common' },
      { label: intl.formatMessage(logMessages.difference), value: 'difference' },
    ];
    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.clusterActiveTimeChange !== nextProps.clusterActiveTimeChange ||
      this.props.activeKey !== nextProps.activeKey
    ) {
      this.reloadData(nextProps, true);
    }
  }

  @autobind
  reloadData(props, force) {
    const {
      createLoadAction,
      activeKey,
      projectName,
      instanceName,
      incident,
      startTimestamp,
      endTimestamp,
      featureInfo,
    } = props;
    if (activeKey === 'logEntries' && projectName && instanceName && incident && startTimestamp && endTimestamp) {
      let { pageNo } = this.state;
      pageNo = force ? 1 : pageNo + 1;
      const { featureIsJson, featureLabel, featureValue, featureIsNumber, featureInterval, featurePosition } =
        featureInfo || {};
      const dayTimeMillis = moment.utc(startTimestamp).startOf('day').valueOf();
      const { patternId } = incident || {};
      const query = {
        projectName,
        instanceName,
        dayTimeMillis,
        startTime: featureLabel ? startTimestamp : undefined,
        endTime: featureLabel ? endTimestamp : undefined,
        patternIds: JSON.stringify([patternId]),
        pageNo,
        pageSize: this.pageSize,
      };
      if (featureIsJson) {
        query.jsonPath = featureLabel;
      }
      if (featureIsNumber) {
        query.featureInterval = featureInterval;
        query.featurePosition = featurePosition;
      } else {
        query.featureWord = featureValue;
      }
      if (!query.featureWord) query.featureWord = '';
      this.setState({ pageNo });

      createLoadAction(ActionTypes.LOAD_LOG_DAY_ENTRY_LIST, query, this.dataLoader, force, ({ logEntryDayList }) => {
        this.preprocessData({ ...props, logEntryDayList, force });
      });
    }
  }

  @autobind
  preprocessData(props) {
    const { logEntryDayList, logEntryDaySampleMsg, projects, projectName, instanceName, force, keyword } = props;
    const { allExpand } = this.state;
    const { eventList: prevEventList } = this.state;
    let eventList = [];
    if (eventList.length > 0) {
      this.clusterSampleMsgs = {
        [`${eventList[0].nid}[0]`]: logEntryDaySampleMsg,
      };
    }

    const project = R.find((p) => p.projectName === projectName || p.projectShortName === projectName, projects);
    const projectLinkInfo = get(project, 'projectLinkInfo', []);
    eventList = R.map((e) => {
      return {
        ...e,
        projectName,
        instanceName,
        project,
        projectLinkInfo,
        isExpand: allExpand,
        __expanded_state__: allExpand,
        __content_expand_state: allExpand,
        value: instanceName,
      };
    }, logEntryDayList);

    try {
      this.keywords = JSON.parse(keyword);
    } catch (error) {
      this.keywords = '';
    }

    this.setState({ eventList: force ? eventList : [...prevEventList, ...eventList] }, () => {
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
    });
  }

  @autobind
  renderTime(rowData) {
    const { timestamp } = rowData;
    return <div>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</div>;
  }

  @autobind
  renderType(rowData) {
    const { intl } = this.props;
    const { eventType } = rowData;

    return (
      <div className="flex-row flex-wrap full-width">
        {CellRenderers.logShortTypeRenderer({ intl, type: eventType })}
      </div>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { patternName, patternId } = rowData;
    const { patternNameStr } = Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true });
    return (
      <Popover mouseEnterDelay={0.3} title={null} content={patternNameStr}>
        <div className="hidden-line-with-ellipsis inline-block max-width">{patternNameStr}</div>
      </Popover>
    );
  }

  @autobind
  renderIncidentName({ cellData, rowData }) {
    const { projects, projectName, instanceDisplayNameMap } = this.props;
    const { value } = rowData;
    const currentProject = R.find((project) => project.projectName === projectName, projects || []);
    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, value, {
      pn: currentProject?.projectShortName,
      owner: currentProject?.owner,
    });

    return (
      <Popover title={null} content={instanceStr}>
        <div
          className="hidden-line-with-ellipsis clickable"
          style={{ fontSize: value === 'All Instance' ? 16 : 12, fontWeight: value === 'All Instance' ? 600 : 500 }}
        >
          {instanceStr}
        </div>
      </Popover>
    );
  }

  @autobind
  logPatternIdRenderer(rowData) {
    const { nid } = rowData || {};
    const { patternIdStr: msg } = Defaults.PatternIdNameStr({ patternId: nid }, {});
    return (
      <div className="flex-row" style={{ height: '40px', alignItems: 'center' }}>
        <div className="flex-grow flex-min-width flex-row">
          <Tooltip placement="top" mouseEnterDelay={0.3} title={msg}>
            <span
              style={{
                color: 'gray',
                lineHeight: '14px',
                height: 14,
                minWidth: 10,
                textAlign: 'center',
                border: `1px solid gray`,
                borderRadius: '20%',
                padding: '0 2px',
                cursor: 'default',
                display: 'inline-block',
              }}
            >
              {nid}
            </span>
          </Tooltip>
        </div>
      </div>
    );
  }

  @autobind
  onToggleCollapse() {
    this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
  }

  @autobind
  renderLogEntry(props) {
    const { intl, currentTheme } = this.props;
    const { eventList } = this.state;
    const { rowData } = props;
    props.cellData = rowData.rawData;
    props.style = {};
    return (
      <div style={{ width: '100%' }}>
        {CellRenderers.logContent(props, this.cellMeasureCache, {
          width: '90%',
          highlightWord: this.keywords,
          isCritical: false,
          onChanged: this.onToggleCollapse,
          showRawDataFrequency: false,
          frequencyStr: '',
          allParsedEventList: eventList,
          intl,
          summarySettings: [],
          enableJsonSummary: true,
          currentTheme,
          isCacheColumn: true,
          isKeywordQuery: true,
        })}
      </div>
    );
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;

    const handleMenuClick = ({ key }) => {
      switch (key) {
        case 'context':
          this.handleLogContextOneMinClick(rowData);
          break;
        case 'jumpToRCA':
          this.handleRCAJumpClick(rowData);
          break;
        default:
          break;
      }
    };

    return (
      <div className="flex-row flex-end-justify">
        <Dropdown name={intl.formatMessage(logMessages.investigate)} itemClick={handleMenuClick}>
          <Menu.Item key="context">{intl.formatMessage(eventMessages.context)}</Menu.Item>
          <Menu.Item key="jumpToRCA">{intl.formatMessage(appFieldsMessages.jumpToRCA)}</Menu.Item>
        </Dropdown>
      </div>
    );
  }

  @autobind
  handleRCAJumpClick(rowData) {
    const { project, timestamp } = rowData;
    const day = moment.utc(timestamp).format(Defaults.DateFormat);
    const { owner, systemId } = project || {};
    const query = {
      environmentId: 'All',
      startTime: day,
      endTime: day,
      customerName: owner,
      systemId,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  }

  @autobind
  handleLogContextOneMinClick(rowData) {
    const { instanceName, timestamp, startTime, endTime } = rowData;
    this.setState({
      showTimeSelectModal: true,
      selectInstance: instanceName,
      selectStartTimestamp: startTime ? startTime - 60 * 1000 : timestamp - 60 * 1000,
      selectEndTimestamp: endTime ? endTime + 60 * 1000 : timestamp + 60 * 1000,
      activeIncident: rowData,
    });
  }

  @autobind onCloseTimeSelect(props) {
    const { projectName, instanceName, startTimestamp, endTimestamp, keywordFilter } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState({
        showTimeSelectModal: false,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp: startTimestamp,
        selectEndTimestamp: endTimestamp,
        showContextModal: true,
        contextKeywordFilter: keywordFilter,
        contextTime: 60 * 1000,
      });
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('timestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);
    return sortList;
  }

  @autobind
  renderListItem(events) {
    return (props: Object) => {
      const { key, index: rowIndex, style, parent } = props;
      const rowData = events[rowIndex];
      if (!rowData) return null;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style, minHeight: 40 }}>
            <div className="row-column" style={{ width: 110 }}>
              {this.renderTime(rowData)}
            </div>
            <div className="row-column" style={{ width: 150 }}>
              {this.renderIncidentName({ rowData })}
            </div>
            <div className="row-column" style={{ width: 60 }}>
              {this.renderType(rowData)}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {this.logPatternIdRenderer(rowData)}
            </div>
            <div className="row-column" style={{ width: 100, flex: 1 }}>
              {this.renderLogEntry({ ...props, rowData, dataKey: key, rowIndex })}
            </div>
            <div className="row-column" style={{ width: 120 }}>
              {this.renderControl(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  isRowLoaded({ index }) {
    const { eventList } = this.state;
    return eventList && eventList.length > index;
  }

  @autobind
  handleExpandRow(rowIndex) {
    return (isExpand) => {
      const { eventList } = this.state;
      this.setState(
        {
          eventList: update(eventList, {
            [rowIndex]: { $set: { ...(eventList[rowIndex] || {}), isExpand } },
          }),
        },
        () => {
          this.cellMeasureCache.clear(rowIndex);
          if (this.listNode) this.listNode.forceUpdateGrid();
        },
      );
    };
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const { eventList } = this.state;
          const sortList = this.sortData(eventList, name, sortDir);
          this.setState({ eventList: sortList });
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  handleAllExpand() {
    const { allExpand, eventList } = this.state;
    this.setState(
      {
        allExpand: !allExpand,
        // eslint-disable-next-line camelcase
        eventList: R.map(
          (item) => ({
            ...item,
            isExpand: !allExpand,
            __expanded_state__: !allExpand,
            __content_expand_state: !allExpand,
          }),
          eventList,
        ),
      },
      () => {
        window.setTimeout(() => {
          this.cellMeasureCache.clearAll();
          if (this.listNode) {
            this.listNode.forceUpdateGrid();
          }
        }, 50);
      },
    );
  }

  render() {
    const { intl, loadStatus, logEntryDayListTotal, instanceDisplayNameMap } = this.props;
    const {
      eventList,
      sortBy,
      sortDirection,
      showHighlightCategory,
      allExpand,
      selectStartTimestamp,
      selectEndTimestamp,
      contextTime,
      contextKeywordFilter,
    } = this.state;
    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    return (
      <Container
        className={`full-height flex-col  ${isLoading && !errorMessage ? ' loading' : ''}`}
        style={{ overflowY: 'hidden' }}
      >
        <Spin spinning={isLoading} wrapperClassName="spin-base">
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            rowCount={logEntryDayListTotal}
            loadMoreRows={() => this.reloadData(this.props)}
            threshold={3}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer
                onResize={() => {
                  this.cellMeasureCache.clearAll();
                  this.forceUpdate();
                }}
              >
                {({ width, height }) => (
                  <div className="event-list">
                    <div
                      className="event-list-header"
                      style={{
                        height: this.listHeaderHeight,
                        width,
                        paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                      }}
                    >
                      <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('timestamp')}>
                        <span>{intl.formatMessage(logMessages.dateTime)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'timestamp')}
                      </div>
                      <div className="header-column" style={{ width: 150 }} onClick={this.headerClick('instanceName')}>
                        <span>{intl.formatMessage(appFieldsMessages.instanceName)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'instanceName')}
                      </div>
                      <div className="header-column" style={{ width: 60 }} onClick={this.headerClick('eventType')}>
                        <span>{intl.formatMessage(appFieldsMessages.type)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'eventType')}
                      </div>
                      <div className="header-column" style={{ width: 100 }} onClick={this.headerClick('patternId')}>
                        <span>{intl.formatMessage(logMessages.patternId)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'patternId')}
                      </div>
                      <div className="header-column" style={{ width: 100, flex: 1 }}>
                        {intl.formatMessage(eventMessages.shortDescription)}
                        <span onClick={this.handleAllExpand}>
                          <DoubleLeftOutlined rotate={allExpand ? 90 : -90} style={{ marginLeft: 4 }} />
                        </span>
                      </div>
                      <div className="header-column" style={{ width: 120 }} />
                    </div>
                    <List
                      className="event-list-grid corner-0-0-8-8"
                      ref={(listNode) => {
                        this.listNode = listNode;
                        registerChild(listNode);
                      }}
                      onRowsRendered={onRowsRendered}
                      width={width}
                      height={height - this.listHeaderHeight}
                      rowCount={eventList.length + 1}
                      overscanRowCount={4}
                      deferredMeasurementCache={this.cellMeasureCache}
                      rowHeight={this.cellMeasureCache.rowHeight}
                      rowRenderer={this.renderListItem(eventList)}
                      onScrollbarPresenceChange={({ horizontal, vertical }) => {
                        if (vertical) {
                          this.listNodeHeaderScrollbar = true;
                          this.cellMeasureCache.clearAll();
                          if (this.listNode) this.listNode.forceUpdateGrid();
                        } else {
                          this.listNodeHeaderScrollbar = false;
                        }
                        this.forceUpdate();
                      }}
                    />
                  </div>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </Spin>
        {this.state.showTimeSelectModal && (
          <TimeSelectModal
            projectName={get(this.state.activeIncident, 'projectName')}
            instanceName={this.state.selectInstance}
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
            timeIntervals={1}
            showKeywordSearch
          />
        )}
        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeIncident}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            contextTime={contextTime}
            keywordFilter={contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
          />
        )}
      </Container>
    );
  }
}

const LogEntryDayContent = injectIntl(LogEntryDayContentCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects, currentTheme } = state.app;
    const logEntryDaySampleMsg = get(state.log, 'logEntryDaySampleMsg', '');
    const logEntryDayListTotal = get(state.log, 'logEntryDayListTotal', 0);

    return {
      currentTheme,
      location,
      loadStatus,
      projects,
      logEntryDaySampleMsg,
      logEntryDayListTotal,
    };
  },
  { createLoadAction },
)(LogEntryDayContent);
