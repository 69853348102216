/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { autobind } from 'core-decorators';
import { CellMeasurer, CellMeasurerCache, createMasonryCellPositioner, Masonry } from '../../../../lib/fui/react';
import ModelSettingTile from './ModelSettingTile';

type Props = {
  intl: Object,

  modelList: Array<Object>,
  width: Number,
  height: Number,
  projects: Object,
  credentials: Object,
  userInfo: Object,
  projectName: String,
  instanceGroup: String,
  onModelSelect: Function,
  onModelRemove: Function,
  onModelPick: Function,
  onModelExport: Function,
  selectedModelKey: String,
  showDetailsBut: Boolean,
  handleModalDetails: Function,
};

class ModelTileList extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { showDetailsBut } = props;

    this.columnWidth = 138;
    this.columnHeight = showDetailsBut ? 200 : 178;
    this.columnCount = 6;
    this.spacer = 4;

    this.cellMeasureCache = new CellMeasurerCache({
      defaultHeight: this.columnHeight,
      defaultWidth: this.columnWidth,
      fixedWidth: true,
      fixedHeight: true,
    });

    const width = props;
    this.cellPositioner = createMasonryCellPositioner({
      cellMeasurerCache: this.cellMeasureCache,
      columnCount: this.columnCount,
      columnWidth: this.columnWidth,
      columnHeight: this.columnHeight,
      spacer: this.spacer,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (nextProps.height !== this.props.height ||
        nextProps.width !== this.props.width ||
        nextProps.modelList !== this.props.modelList) &&
      this.masonry
    ) {
      // IMPORTANT: cellPositoner must be reset when props changed.
      this.cellPositioner.reset({
        columnCount: this.columnCount,
        columnWidth: this.columnWidth,
        columnHeight: this.columnHeight,
        spacer: this.spacer,
      });
      this.masonry.recomputeCellPositions();
    }
  }

  @autobind
  modelRenderer(selectedModelKey) {
    return ({ index, key, parent, style }) => {
      const { intl, projects, credentials, userInfo, projectName, instanceGroup, modelList } = this.props;
      const { onModelSelect, onModelRemove, onModelPick, onModelExport, showDetailsBut, handleModalDetails } =
        this.props;
      const m = modelList[index];

      if (m) {
        const isSelect = selectedModelKey === m.modelKey;
        return (
          <CellMeasurer cache={this.cellMeasureCache} index={index} key={key} parent={parent}>
            <ModelSettingTile
              intl={intl}
              style={{ ...style }}
              model={m}
              projects={projects}
              credentials={credentials}
              userInfo={userInfo}
              projectName={projectName}
              instanceGroup={instanceGroup}
              onModelSelect={onModelSelect}
              onModelRemove={onModelRemove}
              onModelPick={onModelPick}
              onModelExport={onModelExport}
              isSelect={isSelect}
              showDetailsBut={showDetailsBut}
              handleModalDetails={handleModalDetails}
            />
          </CellMeasurer>
        );
      }
      return null;
    };
  }

  render() {
    const { modelList, height, width, selectedModelKey } = this.props;
    return (
      <Masonry
        width={width}
        height={height}
        cellCount={modelList.length}
        cellMeasurerCache={this.cellMeasureCache}
        cellPositioner={this.cellPositioner}
        cellRenderer={this.modelRenderer(selectedModelKey)}
        ref={(c) => {
          this.masonry = c;
        }}
      />
    );
  }
}

export default ModelTileList;
