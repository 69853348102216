/* @flow */

import { defineMessages } from 'react-intl';

const step1Introduction = `
Projects define which data is analyzed together. Separate data from different, unrelated sources into separate projects.
`;

const step2Introduction = `
Please select the data source for this project.
You can filter the data sources by the OS, platform or application and select the data sources you want to add. 
`;

const step3Introduction = `
Following the precedure to config settings or install agent for the selected data sources.
`;

const step4Introduction = `
Congratulation, you have setup the project. It might take about 15 minutes for the data source agent to work, 
you can check the status of the data source in the <bold>Project Settings</bold>.
`;

const PublicCloudIntro = `
Uses public Cloud APIs to monitor and report system metrics for your instances.
`;

const PublicDataDogIntro = `
Uses Datadog APIs to monitor and report system metrics or events for your instances.
`;

const ServiceNowIntro = `
Uses ServiceNow APIs to import incident ticket data into InsightFinder for incident data analysis.
`;

const PublicCATechnologyIntro = `
Uses CA Technology APIs to monitor and report system metrics for your instances.
`;
const GoogleAdsIntro = `
Uses Google Ads APIs to monitor and report metrics for Microservices and Container Based Apps.
`;
const JDBCIntro = `
Uses JDBC to monitor and report metrics for databases. 
`;

const InsightAgentIntro = `
Uses <b>InsightAgent</b> to monitor and report system logs/metrics for your instances. For details, please visit 
<a>https://github.com/insightfinder/InsightAgent</a>.
`;

const AWSCloudWatchIntro = `
AWS instance monitoring data (i.e., metrics, logs, traces) can be easily ingested into InsightFinder via Cloudwatch APIs.
`;

const GoogleCloudMonitoringIntro = `
Uses Google Cloud APIs to monitor and report system metrics for your Google instances.'
`;

const cAdvisorIntro = `
This InsightFinder agent can be used to monitor system metrics of docker containers using cadvisor.
`;

const projectWizardMessages = defineMessages({
  errorNotAllowAdmin: {
    defaultMessage: 'This account can view project only, use other account to create new project',
    id: 'settings.projectWizard.errorNotAllowAdmin',
  },
  step1CopyProject: {
    defaultMessage:
      'Projects represent different data sources. ' +
      'They can be combined to form systems. ' +
      'All project data is analyzed together in the system. ' +
      'Select from a list of available projects to copy below. ' +
      'Optionally, describe the new project.',
    id: 'settings.projectWizard.step1CopyProject',
  },
  step1Introduction: {
    defaultMessage: step1Introduction,
    id: 'settings.projectWizard.step1Introduction',
  },
  step2Introduction: {
    defaultMessage: step2Introduction,
    id: 'settings.projectWizard.step2Introduction',
  },
  step3Introduction: {
    defaultMessage: step3Introduction,
    id: 'settings.projectWizard.step3Introduction',
  },
  step4Introduction: {
    defaultMessage: step4Introduction,
    id: 'settings.projectWizard.step4Introduction',
  },
  PublicCloudIntro: {
    defaultMessage: PublicCloudIntro,
    id: 'settings.projectWizard.PublicCloudIntro',
  },
  InsightAgentIntro: {
    defaultMessage: InsightAgentIntro,
    id: 'settings.projectWizard.InsightAgentIntro',
  },
  KubernetesIntro: {
    defaultMessage: 'Uses Kubernetes APIs to monitor and report metrics.',
    id: 'settings.projectWizard.KubernetesIntro',
  },
  AWSCloudWatchIntro: {
    defaultMessage: AWSCloudWatchIntro,
    id: 'settings.projectWizard.AWSCloudWatchIntro',
  },
  followingPermissions: {
    defaultMessage: 'IMPORTANT: please make sure the IAM access key has the following permissions',
    id: 'settings.projectWizard.followingPermissions',
  },
  AWSCloudIntro: {
    defaultMessage: 'Uses the AWS cloud cost API to monitor ',
    id: 'settings.projectWizard.AWSCloudIntro',
  },
  GoogleCloudMonitoringIntro: {
    defaultMessage: GoogleCloudMonitoringIntro,
    id: 'settings.projectWizard.GoogleCloudMonitoringIntro',
  },
  PublicDataDogIntro: {
    defaultMessage: PublicDataDogIntro,
    id: 'settings.projectWizard.PublicDataDogIntro',
  },
  ServiceNowIntro: {
    defaultMessage: ServiceNowIntro,
    id: 'settings.projectWizard.ServiceNowIntro',
  },
  PagerDutyIntro: {
    defaultMessage: 'Uses PagerDuty APIs to monitor and report alerts.',
    id: 'settings.projectWizard.PagerDutyIntro',
  },
  ZendeskIntro: {
    defaultMessage: 'Uses Zendesk APIs to monitor and report logs.',
    id: 'settings.projectWizard.ZendeskIntro',
  },
  SumoLogicIntro: {
    defaultMessage: 'Uses Sumo Logic APIs to monitor and report logs.',
    id: 'settings.projectWizard.SumoLogicIntro',
  },
  PublicNewRelicIntro: {
    defaultMessage: 'Uses NewRelic APIs to monitor and report logs/metrics.',
    id: 'settings.projectWizard.PublicNewRelicIntro',
  },
  PublicCATechnologyIntro: {
    defaultMessage: PublicCATechnologyIntro,
    id: 'settings.projectWizard.PublicCATechnologyIntro',
  },
  GoogleAdsIntro: {
    defaultMessage: GoogleAdsIntro,
    id: 'settings.projectWizard.GoogleAdsIntro',
  },
  GoogleCloudIntro: {
    defaultMessage: 'Uses Google Cloud APIs to monitor and report metrics.',
    id: 'settings.projectWizard.GoogleCloudIntro',
  },
  GoogleBigQueryIntro: {
    defaultMessage: 'Uses Google BigQuery APIs to monitor and report logs/metrics.',
    id: 'settings.projectWizard.GoogleBigQueryIntro',
  },
  GoogleCloudCost: {
    defaultMessage: 'Uses Google Cloud Cost APIs to monitor.',
    id: 'settings.projectWizard.GoogleCloudCost',
  },
  AzureCost: {
    defaultMessage: 'Uses Azure Cost APIs to monitor.',
    id: 'settings.projectWizard.AzureCost',
  },
  GoogleCloudPubSubIntro: {
    defaultMessage: 'Uses Google Cloud PubSub APIs to monitor and report logs.',
    id: 'settings.projectWizard.GoogleCloudPubSubIntro',
  },
  MicrosoftAzureIntro: {
    defaultMessage: 'Uses Azure Monitoring APIs to monitor and report logs/metrics.',
    id: 'settings.projectWizard.MicrosoftAzureIntro',
  },
  JDBCIntro: {
    defaultMessage: JDBCIntro,
    id: 'settings.projectWizard.JDBCIntro',
  },
  snowIntro: {
    defaultMessage: 'Uses Snowflake to monitor and report metrics for databases.',
    id: 'settings.projectWizard.snowIntro',
  },
  snowflakePerformanceMonitoring: {
    defaultMessage: 'Snowflake performance monitoring',
    id: 'settings.projectWizard.snowflakePerformanceMonitoring',
  },
  shadowProject: {
    defaultMessage: 'Uses InsightAgent to import anomalies into InsightFinder.',
    id: 'settings.projectWizard.shadowProject',
  },
  cAdvisorIntro: {
    defaultMessage: cAdvisorIntro,
    id: 'settings.projectWizard.cAdvisorIntro',
  },
  cAdvisorAgent: {
    defaultMessage: 'Null',
    id: 'settings.projectWizard.cAdvisorAgent',
  },
  GitActionsIntro: {
    defaultMessage: 'Uses GitHub Actions to monitor and report events.',
    id: 'settings.projectWizard.GitActionsIntro',
  },
});

export default projectWizardMessages;
