/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import { get } from 'lodash';
import * as R from 'ramda';
import moment from 'moment';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getMetricEventList } from '../../apis';

const loadReportMetricLogic = createLogic({
  type: [ActionTypes.LOAD_REPORT_METRIC],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);
    const { projectName, startTimestamp, endTimestamp } = params;
    const startTimeObj = moment.utc(Number(startTimestamp)).startOf('day');
    const endTimeObj = moment.utc(Number(endTimestamp)).endOf('day');

    dispatch(showLoading);
    dispatch(updateLastActionInfo());

    // First, get events list
    let data = {};
    try {
      data = await getMetricEventList(credentials, {
        withPredict: true,
        projectName,
        instanceGroup: 'All',
        startTimeObj,
        endTimeObj,
      });
    } catch (e) {
      console.log(e);
    }

    const metricUnitMap = get(data, ['eventListMap', 'metricUnitMap'], {});
    let allEventList = get(data, ['eventListMap', 'eventList'], []);
    allEventList = R.sort((a, b) => a.timestamp - b.timestamp, allEventList);

    dispatch(createSetAction(ActionTypes.SET_REPORT_METRIC, params, { allEventList, metricUnitMap }));

    dispatch(hideLoading);
    done();
  },
});

export default loadReportMetricLogic;
