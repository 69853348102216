import React from 'react';
import Markdown from 'react-markdown';
import hljs from 'highlight.js';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { State } from '../../../common/types';

import '../../app/themes/markdown-github.css';

function EncapsulationMarkdownCore(props: Object) {
  const { message } = props || {};

  return (
    <Markdown
      components={{
        code: (props) => {
          const { className, children } = props;
          const language = className && className.split('-')[1]; // 获取语言
          if (language) {
            // 使用 highlight.js 进行代码高亮
            const highlightedCode = hljs.highlight(language, children.toString()).value;
            return (
              <pre>
                <code className={`hljs ${className}`} dangerouslySetInnerHTML={{ __html: highlightedCode }} />
              </pre>
            );
          }
          // 如果没有语言指定，则默认渲染
          return (
            <code
              className={className}
              style={{
                padding: '.2em .6em',
                background: 'var(--inline-code-background)',
                borderRadius: 3,
                wordBreak: 'break-word',
                display: 'inline-block',
              }}
            >
              {children}
            </code>
          );
        },
      }}
    >
      {message}
    </Markdown>
  );
}

const EncapsulationMarkdown = injectIntl(EncapsulationMarkdownCore);
export default connect((state: State) => {
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return { isDark };
}, {})(EncapsulationMarkdown);
