import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { isArray, isNumber } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { autobind } from 'core-decorators';
import { Button, Checkbox, message, notification, Pagination, Popconfirm, Select, Spin } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined } from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPut from '../../../common/apis/fetchPut';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  List,
  Popover,
  SortDirection,
  Table,
} from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import {
  buildLocation,
  CausalParser,
  CellRenderers,
  EventRenderers,
  LogRenderers,
  parseLocation,
} from '../../../common/utils';

import RecommendationModal from './RecommendationModal';
import UpdataGlobalKnowledgeBaseModal from './UpdataGlobalKnowledgeBaseModal';
import CreateNewGlobalKBModal from './CreateNewGlobalKBModal';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';
import UpdataGlobalKnowledgeMappingModal from './UpdataGlobalKnowledgeMappingModal';

type Props = {
  // eslint-disable-next-line
  refresh: Number,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  currentTheme: String,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  projects: Array<Object>,
};

class GlobalKnowledgeBaseCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl, location } = props;
    const params = parseLocation(location);
    const { jumpKey } = params;

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

    this.state = {
      loading: false,
      incidentMap: {},
      allIncidentList: [],
      incidentList: [],
      localPredictionRuleList: [],
      incidentPredictionRuleList: [],
      filterPredictionRuleList: [],
      activeIncidentKey: undefined,

      sortBy: null,
      sortDirection: null,
      page: 1,
      pageSize: 100,
      isSubmitting: false,

      checkAll: false,
      filterPatternId: null,
      filterSourcePatternIds: [],
      sourcePatternListOptions: [],

      addRecommendationModalFlag: false,
      addRecommendationActive: {},
      showUpdataGlobalKnowledgeBaseModal: false,
      updataGlobalKnowledgeBaseActive: {},
      showUpdateMappingModal: false,
      updateMappingActive: {},
      incidentData: [],
      metricList: [],
      showCreateNewGlobalModal: false,
    };
    this.patternListOptions = [];
    this.statusOptions = [
      { value: -1, label: intl.formatMessage(eventMessages.ignored) },
      { value: 1, label: intl.formatMessage(settingsMessages.confirmed) },
    ];
    this.jumpKey = jumpKey;
  }

  async componentDidMount() {
    await this.getGlobalKBParam();
    this.reloadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const prevQuery = parseLocation(prevProps.location);
    const query = parseLocation(location);
    if (
      prevProps.refresh !== this.props.refresh ||
      prevQuery.refreshTime !== query.refreshTime ||
      prevQuery.environmentId !== query.environmentId ||
      prevQuery.customerName !== query.customerName
    ) {
      this.setState({ filterPatternId: null, filterSourcePatternIds: [] });
      this.reloadData();
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
    let { incidentList } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        incidentList = R.sortWith([R.ascend(R.prop(sortBy))])(incidentList);
        if (sortDirection === SortDirection.DESC) {
          incidentList = R.sortWith([R.descend(R.prop(sortBy))])(incidentList);
        }
        this.setState({ incidentList });
      }
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  getGlobalKBParam() {
    const { credentials } = this.props;
    return fetchGet(getEndpoint('global-kb-param', 1), {
      ...credentials,
    })
      .then((data) => {
        const { success, message: msg, incidentList, metricNameMap } = data || {};
        if (success || success === undefined) {
          let incidentListData = R.map((item) => ({ label: item, value: item }), incidentList || []);
          incidentListData = R.sortWith([R.ascend(R.prop('label'))], incidentListData);
          this.setState({ incidentData: incidentListData, metricList: metricNameMap });
        } else {
          console.error(msg);
        }
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  }

  @autobind
  reloadData(updatePage = true) {
    const { credentials, intl, location } = this.props;
    const { jumpMergeKey } = parseLocation(location);
    const { page, activeIncidentKey } = this.state;
    this.setState({ loading: true, page: updatePage ? 1 : page, checkAll: false });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('globallevelrule', 1), {
      ...credentials,
    })
      .then((res) => {
        const incidentMap = {};
        const incidentList = [];
        let patternListOptions = [];
        let { LogIncidents: ruleInfoList, KPIIncidents } = res || {};
        if ((isArray(ruleInfoList) && ruleInfoList.length > 0) || (isArray(KPIIncidents) && KPIIncidents.length > 0)) {
          ruleInfoList = R.map((item) => ({ ...item, isKpiIncidents: false }), ruleInfoList || []);
          KPIIncidents = R.map((item) => ({ ...item, isKpiIncidents: true }), KPIIncidents || []);

          R.forEach(
            (item) => {
              const { incidentRule } = item || {};
              const { rulePrimaryKey, patternName } = incidentRule || {};
              const { rulePartitionKey, uniqueKey } = rulePrimaryKey || {};
              const { patternId } = rulePartitionKey;
              const key = patternName || uniqueKey;
              if (!R.has(key, incidentMap)) {
                incidentMap[key] = [item];
              } else {
                incidentMap[key].push(item);
              }
              patternListOptions.push(patternId);
            },
            [...KPIIncidents, ...ruleInfoList],
          );

          R.forEachObjIndexed((value, key) => {
            let patternIds = [];
            let uniqueKeys = [];
            R.forEach((item) => {
              const { incidentRule } = item || {};
              const { rulePrimaryKey } = incidentRule || {};
              const { rulePartitionKey, uniqueKey } = rulePrimaryKey || {};
              const { patternId } = rulePartitionKey;
              patternIds = [...patternIds, patternId];
              uniqueKeys = [...uniqueKeys, uniqueKey];
            }, value || []);
            patternIds = R.uniq(patternIds);
            uniqueKeys = R.uniq(uniqueKeys);
            incidentList.push({ key, patternIds, uniqueKeys });
          }, incidentMap);
        } else {
          this.clearCauseData();
        }

        patternListOptions = R.uniq(patternListOptions);
        this.patternListOptions = R.map((item) => ({ value: item, label: item }), patternListOptions);

        const filterIncidentList = this.filterDataList(incidentList);
        this.setState(
          {
            incidentMap,
            allIncidentList: incidentList,
            incidentList: filterIncidentList,
          },
          () => {
            this.jumpToItem({ incidentList, activeKey: jumpMergeKey });
            const findItem = R.find(
              (item) => R.includes(jumpMergeKey, item.uniqueKeys) || item.key === activeIncidentKey,
              incidentList,
            );
            this.handleIncidentClick(findItem || incidentList[0] || {}, updatePage);
          },
        );
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ loading: false }, () => {
          this.clearJumpData();
        });
      });
  }

  @autobind
  jumpToItem({ incidentList, activeKey }) {
    const findIdx = R.findIndex(
      (item) => R.includes(activeKey, item.uniqueKeys) || item.key === activeKey,
      incidentList || [],
    );
    if (this.dataTable) {
      this.dataTable.scrollToRow(findIdx);
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind
  jumpListToItem({ incidentList, activeKey }) {
    const { pageSize, page } = this.state;
    const findIdx = R.findIndex((item) => item.key === activeKey, incidentList || []);
    let index = findIdx;
    if (findIdx >= pageSize) {
      index = findIdx - (page - 1) * pageSize;
    }
    if (this.listNode) {
      this.listNode.scrollToRow(index);
      this.listNode.forceUpdateGrid();
    }
    this.clearJumpData();
  }

  @autobind
  handleIncidentClick(rowData, updatePage = true) {
    const { location } = this.props;
    const { jumpMergeKey } = parseLocation(location);
    const { incidentMap, pageSize } = this.state;
    let { page } = this.state;
    const { key } = rowData;
    if (!jumpMergeKey) {
      this.jumpKey = undefined;
    }
    const predictionRuleList = R.map((item) => {
      const { incidentRule, ruleType } = item;
      const {
        sourceDetailSet = [],
        rulePrimaryKey: {
          rulePartitionKey: { instanceName, patternId, projectName, userName },
          uniqueKey,
        },
      } = incidentRule;

      const sourcePatternIds = R.sort((a, b) => a - b, R.uniq(R.map((_item) => _item.nid, sourceDetailSet || [])));

      const key = `${projectName}-${instanceName}-${patternId}-${userName}-${ruleType}-${uniqueKey}`;
      return { ...item, key, sourcePatternIds, checked: false };
    }, incidentMap[key] || []);

    let sourcePatternListOptions = [];
    R.forEach((item) => {
      sourcePatternListOptions.push(...(item.sourcePatternIds || []));
    }, predictionRuleList || []);
    sourcePatternListOptions = R.map(
      (item) => ({ value: item, label: item }),
      R.sort((a, b) => a - b, R.uniq(sourcePatternListOptions)),
    );

    const findItemIdx = R.findIndex((item) => item.key === this.jumpKey, predictionRuleList);
    if (findItemIdx >= pageSize) {
      page = Math.ceil((findItemIdx + 1) / pageSize);
    }
    const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, predictionRuleList);

    if (this.jumpKey) {
      updatePage = false;
    }

    this.setState(
      {
        page: updatePage ? 1 : page,
        activeIncidentKey: rowData.key,
        loading: false,
        localPredictionRuleList,
        incidentPredictionRuleList: predictionRuleList,
        filterPredictionRuleList: predictionRuleList,
        sourcePatternListOptions,
        filterSourcePatternIds: [],
      },
      () => {
        if (this.jumpKey) {
          this.jumpListToItem({ incidentList: predictionRuleList, activeKey: this.jumpKey });
        }
        this.cellMeasureCache.clearAll();
        if (this.listNode) {
          this.listNode.forceUpdateGrid();
          this.forceUpdate();
        }
      },
    );
  }

  @autobind
  filterDataList(incidentList) {
    const { filterPatternId } = this.state;
    let filterIncidentList = incidentList || [];

    if (filterPatternId || filterPatternId === 0) {
      filterIncidentList = R.filter((item) => R.includes(filterPatternId, item.patternIds), filterIncidentList);
    }

    return filterIncidentList;
  }

  @autobind
  clearCauseData() {
    this.setState(
      {
        localPredictionRuleList: [],
        incidentPredictionRuleList: [],
        filterPredictionRuleList: [],
      },
      () => {
        this.clearJumpData();
      },
    );
  }

  @autobind
  clearJumpData() {
    const { location, replace } = this.props;
    const params = parseLocation(location);
    const { jumpMergeKey, jumpKey } = params;
    if (jumpMergeKey && jumpKey) {
      replace(buildLocation(location.pathname, {}, { ...params, jumpMergeKey: undefined, jumpKey: undefined }));
    }
  }

  @autobind
  sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  renderPatternId({ rowData, cellData }) {
    const { intl, currentTheme } = this.props;
    const { incidentMap } = this.state;
    const { key } = rowData;

    const { incidentRule } = incidentMap[key][0] || {};
    const { incidentData, patternName } = incidentRule || {};
    let incidentDataJson;
    try {
      incidentDataJson = !isNumber(JSON.parse(incidentData)) ? JSON.parse(incidentData) : undefined;
    } catch (error) {
      // console.debug(error)
    }
    const isMetricIncident = Boolean(
      incidentDataJson?.metricName &&
        incidentDataJson?.sign &&
        (incidentDataJson?.percentage || incidentDataJson?.percentage === 0),
    );
    return (
      <Popover
        placement="right"
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 350, paddingRight: 8 }}>
            <div className="flex-col">
              {patternName ? (
                <div className="full-width flex-row">
                  <div className="light-label bold" style={{ minWidth: 90, display: 'inline-block' }}>
                    {intl.formatMessage(appFieldsMessages.patternName)}:
                  </div>
                  <div style={{ wordBreak: 'break-all' }}>{patternName}</div>
                </div>
              ) : (
                <div className="full-width flex-row">
                  <div className="light-label bold" style={{ minWidth: 110, display: 'inline-block' }}>
                    {intl.formatMessage(eventMessages.shortDescription)}:
                  </div>
                  {isMetricIncident && (
                    <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                      {EventRenderers.RenderMetricAnomalySummary({ intl, event: incidentDataJson })}
                    </div>
                  )}
                  {!isMetricIncident && (
                    <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                      {!incidentDataJson &&
                        R.join(
                          '\n',
                          R.filter((x) => Boolean(x), (incidentData || '').split('\n')),
                        )}
                      {incidentDataJson && (
                        <LogRenderers.JsonTree data={incidentDataJson} currentTheme={currentTheme} />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        }
      >
        <div className="max-width flex-row" style={{ height: '100%' }}>
          <div className="hidden-line-with-ellipsis flex-center-justify">
            {patternName ? (
              <div className="hidden-line-with-ellipsis">{patternName}</div>
            ) : (
              <>
                {isMetricIncident ? (
                  <>{EventRenderers.BuildMetricAnomalySummary({ event: incidentDataJson })}</>
                ) : (
                  <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                    {!incidentDataJson && <div className="hidden-line-with-ellipsis">{incidentData}</div>}
                    {incidentDataJson && (
                      <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                        <LogRenderers.RenderLogContent
                          intl={intl}
                          rawData={incidentData}
                          rawDataJson={incidentDataJson}
                          owner={incidentData}
                          enableExpansion={false}
                          currentTheme={currentTheme}
                          clearStyle
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Popover>
    );
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.descend(R.prop('matchedCount'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortByList, sortDirectionList, localPredictionRuleList } = this.state;
      let sortDir = sortDirectionList === 'ASC' ? 'DESC' : sortDirectionList === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortByList) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortByList: name, sortDirectionList: sortDir }, () => {
          const newEventList = this.sortData(localPredictionRuleList, name, sortDir);
          this.setState({ localPredictionRuleList: newEventList }, () => {
            this.cellMeasureCache.clearAll();
            if (this.listNode) {
              this.listNode.forceUpdateGrid();
              this.forceUpdate();
            }
          });
        });
      }
    };
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;

    return (
      <div className="flex-row">
        <Button
          type="primary"
          size="small"
          style={{ marginLeft: 8 }}
          onClick={() => this.setState({ showUpdateMappingModal: true, updateMappingActive: rowData })}
        >
          Mapping
        </Button>
        <Button
          type="primary"
          size="small"
          style={{ marginLeft: 8 }}
          onClick={() =>
            this.setState({ showUpdataGlobalKnowledgeBaseModal: true, updataGlobalKnowledgeBaseActive: rowData })
          }
        >
          Update Global Rule
        </Button>
        <Button
          type="primary"
          size="small"
          style={{ marginLeft: 8 }}
          onClick={() => this.handleReCommendation(rowData)}
        >
          {intl.formatMessage(eventMessages.addRecommendation)}
        </Button>
      </div>
    );
  }

  @autobind
  handleReCommendation(rowData) {
    this.setState({ addRecommendationModalFlag: true, addRecommendationActive: rowData });
  }

  @autobind
  renderSourcePatterns(rowData) {
    const { intl, projects, currentTheme } = this.props;
    const {
      incidentRule: {
        sourceDetailSet = [],
        incidentData,
        rulePrimaryKey: {
          rulePartitionKey: { patternId },
        },
        patternName,
      },
      isKpiIncidents,
    } = rowData;
    let predictionSourceInfo = R.sortWith([R.descend(R.prop('matchedCount'))], sourceDetailSet);
    const noHasOldTimeStamp = R.find((item) => item.timeStamp !== 0, predictionSourceInfo || []);
    if (noHasOldTimeStamp) {
      predictionSourceInfo = R.sortWith([R.ascend(R.prop('timeStamp'))], sourceDetailSet);
    }
    let contents = R.addIndex(R.map)((item, index) => {
      const content = R.equals(item.type, 'Metric') ? item.content : `${(item.content || '').substr(0, 50)}...`;
      return (
        <div
          key={`rootCauseContentKey${index}`}
          className="hidden-line-with-ellipsis inline-block max-width"
          style={{ display: 'block' }}
        >
          {`${predictionSourceInfo.length > 1 ? `• ` : ''}${content}`}
        </div>
      );
    }, predictionSourceInfo.slice(0, 2));

    if (predictionSourceInfo.length > 2) {
      const otherDom = (
        <div
          key="rootCauseContentKey..."
          className="hidden-line-with-ellipsis inline-block max-width"
          style={{ display: 'block' }}
        >
          <div
            className="flex-row flex-center-align"
            style={{ lineHeight: 1, fontSize: 14, fontWeight: 'bold', paddingBottom: 8 }}
          >
            ...
          </div>
        </div>
      );
      contents = [...contents, otherDom];
    }

    let incidentDataJson;
    try {
      incidentDataJson = !isNumber(JSON.parse(incidentData)) ? JSON.parse(incidentData) : undefined;
    } catch (error) {
      // console.debug(error)
    }
    const isMetricIncident = Boolean(
      incidentDataJson?.metricName &&
        incidentDataJson?.sign &&
        (incidentDataJson?.percentage || incidentDataJson?.percentage === 0),
    );

    if (predictionSourceInfo.length === 0 && isKpiIncidents) {
      contents = <div className="hidden-line-with-ellipsis">{incidentDataJson?.metricName}</div>;
    }

    return (
      <Popover
        placement="right"
        mouseEnterDelay={0.3}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 350, paddingRight: 8 }}>
            {predictionSourceInfo.length > 0 && (
              <div style={{ borderBottom: '1px dashed var(--virtualized-table-border-color)' }}>
                {R.addIndex(R.map)((item, index) => {
                  const { projectName, nid, componentName, content, metricDirection, duration } = item;
                  let { type } = item;
                  const project = R.find((project) => project.projectShortName === projectName, projects || []);
                  let { isLogType, eventType } = CausalParser.getRelationLogType(type);
                  if (project && project.isDeployment) {
                    type = 'deployment';
                    isLogType = true;
                    eventType = 'deployment';
                  }
                  const sourceDetail = { ...item, isLogType, eventType, type };

                  let rawDataJson;
                  try {
                    rawDataJson = !isNumber(JSON.parse(content)) ? JSON.parse(content) : undefined;
                  } catch (error) {
                    // console.debug(error)
                  }
                  return (
                    <div key={index} className="flex-col" style={{ marginBottom: 16 }}>
                      <div className="flex-row">
                        <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                          {intl.formatMessage(eventMessages.rootCauseHop)}:
                        </div>
                        <div style={{ wordBreak: 'break-all' }}>{index + 1}</div>
                      </div>
                      {componentName && (
                        <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                          <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                            {intl.formatMessage(appFieldsMessages.component)}:
                          </div>
                          <div style={{ wordBreak: 'break-all' }}>{componentName}</div>
                        </div>
                      )}
                      <div className="flex-row">
                        <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                          {intl.formatMessage(appFieldsMessages.pattern)}:
                        </div>
                        <div style={{ wordBreak: 'break-all' }}>{nid}</div>
                      </div>
                      {Boolean(duration) && (
                        <div className="flex-row">
                          <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                            {intl.formatMessage(appFieldsMessages.duration)}:
                          </div>
                          <div style={{ wordBreak: 'break-all' }}>
                            {CellRenderers.humanizeDuration({ period: duration, intl, showSeconds: true })}
                          </div>
                        </div>
                      )}
                      <div className="flex-row">
                        <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                          {intl.formatMessage(appFieldsMessages.type)}:
                        </div>
                        <div style={{ wordBreak: 'break-all' }}>
                          {isLogType
                            ? CellRenderers.logTypeRenderer({ intl, rowData: { type: eventType }, isHorizontal: true })
                            : intl.formatMessage(appFieldsMessages.metric)}
                        </div>
                      </div>

                      <div className="full-width flex-row">
                        <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                          {intl.formatMessage(eventMessages.shortDescription)}:
                        </div>
                        {metricDirection && (
                          <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                            {EventRenderers.RenderMetricAnomalySummary({ intl, event: sourceDetail })}
                          </div>
                        )}
                      </div>

                      {!metricDirection && (
                        <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                          {!rawDataJson &&
                            R.join(
                              '\n',
                              R.filter((x) => Boolean(x), (content || '').split('\n')),
                            )}
                          {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                        </div>
                      )}
                    </div>
                  );
                }, predictionSourceInfo)}
              </div>
            )}
            <div className="flex-col" style={{ marginBottom: 8, paddingBottom: 8, marginTop: 8 }}>
              <div className="full-width flex-row">
                <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                  {intl.formatMessage(appFieldsMessages.pattern)}:
                </div>
                <div style={{ wordBreak: 'break-all' }}>{patternId}</div>
              </div>
              {patternName ? (
                <div className="full-width flex-row">
                  <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                    {intl.formatMessage(appFieldsMessages.patternName)}:
                  </div>
                  <div style={{ wordBreak: 'break-all' }}>{patternName}</div>
                </div>
              ) : (
                <div className="full-width flex-row">
                  <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                    {intl.formatMessage(eventMessages.shortDescription)}:
                  </div>
                  {isMetricIncident && (
                    <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                      {EventRenderers.RenderMetricAnomalySummary({ intl, event: incidentDataJson })}
                    </div>
                  )}
                  {!isMetricIncident && (
                    <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                      {!incidentDataJson &&
                        R.join(
                          '\n',
                          R.filter((x) => Boolean(x), (incidentData || '').split('\n')),
                        )}
                      {incidentDataJson && (
                        <LogRenderers.JsonTree data={incidentDataJson} currentTheme={currentTheme} />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        }
      >
        <div
          style={{
            overflow: 'hidden',
            height: contents.length === 1 ? '40px' : contents.length === 2 ? ' 50px' : '66px',
          }}
          className="max-width flex-col flex-center-justify"
        >
          {contents}
        </div>
      </Popover>
    );
  }

  @autobind
  handleStatusChange(statusFlag, rowData, rowIndex) {
    const { localPredictionRuleList } = this.state;
    const fontIndex = R.findIndex((item) => item.key === rowData.key, localPredictionRuleList);
    this.setState({
      localPredictionRuleList: update(localPredictionRuleList, {
        [fontIndex]: { $set: { ...rowData, statusFlag } },
      }),
    });
  }

  @autobind
  handleCheckAllChange(e) {
    const checkAll = e.target.checked;
    const { localPredictionRuleList } = this.state;
    const newLocalPredictionRuleList = R.map((item) => {
      return { ...item, checked: checkAll };
    }, localPredictionRuleList);
    this.setState({ checkAll, localPredictionRuleList: newLocalPredictionRuleList });
  }

  @autobind
  handlePaginationChange(page, pageSize) {
    this.setState({ page, pageSize, checkAll: false }, async () => {
      const { incidentPredictionRuleList } = this.state;
      const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      this.setState({ localPredictionRuleList }, () => {
        this.cellMeasureCache.clearAll();
        if (this.listNode) {
          this.listNode.forceUpdateGrid();
          this.forceUpdate();
        }
      });
    });
  }

  @autobind
  handlePaginationSizeChange(oldPage, pageSize) {
    const page = 1;
    this.setState({ page, pageSize, checkAll: false }, async () => {
      const { incidentPredictionRuleList } = this.state;
      const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      this.setState({ localPredictionRuleList }, () => {
        this.cellMeasureCache.clearAll();
        if (this.listNode) {
          this.listNode.forceUpdateGrid();
          this.forceUpdate();
        }
      });
    });
  }

  @autobind
  handleSaveClick() {
    const { intl, credentials } = this.props;
    const { localPredictionRuleList, filterPredictionRuleList } = this.state;

    this.setState({ isSubmitting: true });
    let diff = R.difference(localPredictionRuleList, filterPredictionRuleList);
    diff = R.map((item) => {
      const { statusFlag, incidentRule, ruleType } = item;
      const { rulePrimaryKey } = incidentRule;
      const { rulePartitionKey, uniqueKey } = rulePrimaryKey;
      const { projectName, instanceName, userName, patternId } = rulePartitionKey;

      return [
        {
          projectName,
          userName,
          instanceName,
          patternId,
          uniqueKey,
          ruleType,
        },
        statusFlag,
      ];
    }, diff);
    this.props.updateLastActionInfo();
    fetchPut(getEndpoint('knowledge-base'), {
      ...credentials,
      operation: 'update',
      rules: JSON.stringify(diff),
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData(false);
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  handleRuleRemove() {
    const { intl, credentials } = this.props;
    const { localPredictionRuleList } = this.state;

    this.setState({ isSubmitting: true });
    let diff = R.filter((item) => item.checked, localPredictionRuleList);
    diff = R.map((item) => {
      const { ruleType, incidentRule } = item;
      const {
        rulePrimaryKey: { rulePartitionKey, uniqueKey },
      } = incidentRule;
      const { projectName, instanceName, userName, patternId } = rulePartitionKey;
      return {
        projectName,
        instanceName,
        userName,
        patternId,
        uniqueKey,
        ruleType,
      };
    }, diff);

    this.props.updateLastActionInfo();
    fetchDelete(getEndpoint('globallevelrule'), {
      ...credentials,
      rules: JSON.stringify(diff),
    })
      .then((data) => {
        const { success, message: msg } = data;
        this.setState({ isSubmitting: false });
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.reloadData();
          return;
        }
        message.error(`Error: ${msg}`);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  onChangeFilterPatternId(filterPatternId) {
    const { allIncidentList, activeIncidentKey } = this.state;
    this.setState({ filterPatternId, page: 1, checkAll: false, filterSourcePatternIds: [] }, async () => {
      const filterIncidentList = this.filterDataList(allIncidentList);
      this.setState({ incidentList: filterIncidentList }, () => {
        if (!R.isEmpty(filterIncidentList)) {
          const fintItem = R.find((item) => item.key === activeIncidentKey, filterIncidentList);
          this.handleIncidentClick(fintItem || filterIncidentList[0]);
        } else {
          this.clearCauseData();
        }
      });
    });
  }

  @autobind
  renderListItem({ index: rowIndex, style, parent }, { newLocalPredictionRuleList }) {
    const { intl } = this.props;
    const rowData = newLocalPredictionRuleList[rowIndex];
    if (!rowData) return null;
    const { checked, statusFlag, incidentRule } = rowData;
    const key = incidentRule.rulePrimaryKey.uniqueKey;

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div className={`event-list-row${this.jumpKey === rowData.key ? ' active' : ''}`} style={{ ...style }}>
          <div className="row-column flex-center-justify" style={{ width: 40 }}>
            <Checkbox
              checked={checked}
              onChange={(e) => {
                // Save the data and force update.
                const checked = e.target.checked || false;
                const { localPredictionRuleList } = this.state;
                const fontIndex = R.findIndex((item) => item.key === rowData.key, localPredictionRuleList);
                this.setState({
                  localPredictionRuleList: update(localPredictionRuleList, {
                    [fontIndex]: { $set: { ...rowData, checked } },
                  }),
                });
                if (this.listNode) {
                  this.listNode.forceUpdateGrid();
                  this.forceUpdate();
                }
              }}
            />
          </div>
          <div className="row-column" style={{ width: 160, flex: 1 }}>
            {this.renderSourcePatterns(rowData)}
          </div>
          <div className="row-column" style={{ width: 140 }}>
            {CellRenderers.humanizeDuration({ period: rowData.incidentRule.delay, intl, showSeconds: true })}
          </div>
          <div className="row-column flex-row flex-end-justify" style={{ width: 120 }}>
            <Select
              style={{ width: '100%' }}
              value={statusFlag}
              onChange={(statusFlag) => this.handleStatusChange(statusFlag, rowData, rowIndex)}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ),
                this.statusOptions || [],
              )}
            </Select>
          </div>
          <div className="row-column flex-col" style={{ width: 380 }}>
            {this.renderControl(rowData)}
          </div>
        </div>
      </CellMeasurer>
    );
  }

  render() {
    const { intl, credentials, systemsMap, projects } = this.props;
    const { sortBy, sortDirection, loading, checkAll, sortByList, sortDirectionList, page, pageSize } = this.state;
    const { isSubmitting, filterPatternId, filterSourcePatternIds, showCreateNewGlobalModal } = this.state;
    const { addRecommendationModalFlag, addRecommendationActive, incidentPredictionRuleList } = this.state;
    const { incidentList, activeIncidentKey, localPredictionRuleList, filterPredictionRuleList } = this.state;
    const { incidentData, metricList } = this.state;

    const diff = R.difference(localPredictionRuleList, filterPredictionRuleList);
    const hasError = diff.length === 0;
    const diffChecked = R.filter((item) => item.checked, localPredictionRuleList);
    const hasErrorRemove = diffChecked.length === 0;

    let newLocalPredictionRuleList = localPredictionRuleList || [];
    if (filterSourcePatternIds && filterSourcePatternIds.length > 0) {
      newLocalPredictionRuleList = R.filter((item) => {
        return R.difference(filterSourcePatternIds, item.sourcePatternIds).length === 0;
      }, newLocalPredictionRuleList);
    }

    return (
      <div className="full-height flex-row" style={{ paddingTop: 8 }}>
        <Container className="flex-grow flex-min-height flex-col">
          <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-width">
            <div
              className="full-width full-height flex-col"
              style={{ background: 'var(--content-background)', padding: 8 }}
            >
              <div className="flex-row flex-center-align flex-wrap" style={{ padding: '0px 0px 8px 8px' }}>
                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                  <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.incidentPattern)}:</div>
                  <Select
                    allowClear
                    showSearch
                    size="small"
                    style={{ width: 130 }}
                    placeholder={intl.formatMessage(eventMessages.incidentPattern)}
                    value={filterPatternId}
                    optionFilterProp="value"
                    onChange={this.onChangeFilterPatternId}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                    disabled={loading}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.patternListOptions)}
                  </Select>
                </div>
                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                  <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.rootCausePattern)}:</div>
                  <Select
                    allowClear
                    showSearch
                    size="small"
                    style={{ width: 140 }}
                    placeholder={intl.formatMessage(eventMessages.rootCausePattern)}
                    mode="multiple"
                    value={filterSourcePatternIds}
                    optionFilterProp="value"
                    onChange={(filterSourcePatternIds) =>
                      this.setState({ filterSourcePatternIds, page: 1, checkAll: false }, () => {
                        this.cellMeasureCache.clearAll();
                        if (this.listNode) {
                          this.listNode.forceUpdateGrid();
                          this.forceUpdate();
                        }
                      })
                    }
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.state.sourcePatternListOptions)}
                  </Select>
                </div>
                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                  <Button
                    size="small"
                    type="primary"
                    style={{ marginLeft: 8 }}
                    onClick={() => this.setState({ showCreateNewGlobalModal: true })}
                  >
                    {intl.formatMessage(appButtonsMessages.create)}
                  </Button>
                </div>
              </div>
              <div className="flex-grow flex-min-width flex-min-height flex-row" style={{ padding: 8 }}>
                <div className="full-height" style={{ width: '30%', maxWidth: 320, marginRight: 16 }}>
                  <AutoSizer>
                    {({ width, height }) => (
                      <Table
                        className="with-border"
                        ref={(c) => {
                          this.dataTable = c;
                        }}
                        width={width}
                        height={height}
                        headerHeight={40}
                        rowHeight={40}
                        rowCount={(incidentList || []).length}
                        rowGetter={({ index }) => (incidentList || [])[index]}
                        onRowClick={({ rowData }) => {
                          this.setState({ page: 1 }, () => {
                            this.handleIncidentClick(rowData);
                          });
                        }}
                        rowClassName={({ index }) => {
                          let className = 'clickable';
                          className += index >= 0 && index % 2 === 1 ? ' odd-row' : '';
                          // Ignore header row.
                          if (index >= 0) {
                            const incident = (incidentList || [])[index];
                            if (incident) {
                              if (incident.key === activeIncidentKey) {
                                className += ' active';
                              }
                            }
                          }
                          return className;
                        }}
                        sort={this.sortTable}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                      >
                        <Column
                          width={100}
                          flexGrow={1}
                          label={intl.formatMessage(eventMessages.incidents)}
                          dataKey="patternId"
                          disableSort
                          cellRenderer={this.renderPatternId}
                          headerRenderer={this.headerRenderer}
                        />
                      </Table>
                    )}
                  </AutoSizer>
                </div>

                <div className="flex-grow flex-min-width flex-col">
                  <div className="flex-grow flex-min-height">
                    <AutoSizer>
                      {({ width, height }) => (
                        <div className="event-list">
                          <div
                            className="event-list-header"
                            style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                          >
                            <div className="header-column flex-center-justify" style={{ width: 40 }}>
                              <Checkbox checked={checkAll} onChange={this.handleCheckAllChange} />
                            </div>
                            <div className="header-column" style={{ width: 160, flex: 1 }}>
                              {intl.formatMessage(eventMessages.rootCauseMetricOrLog)}
                            </div>
                            <div className="header-column" style={{ width: 140 }}>
                              {intl.formatMessage(appFieldsMessages.delay)}
                            </div>
                            <div className="header-column" style={{ width: 120 }}>
                              {intl.formatMessage(appFieldsMessages.status)}
                            </div>
                            <div className="header-column" style={{ width: 380 }} />
                          </div>
                          <List
                            className="event-list-grid"
                            ref={(listNode) => {
                              this.listNode = listNode;
                            }}
                            width={width}
                            height={height - 40}
                            rowCount={(newLocalPredictionRuleList || []).length}
                            overscanRowCount={4}
                            deferredMeasurementCache={this.cellMeasureCache}
                            rowHeight={this.cellMeasureCache.rowHeight}
                            rowRenderer={(props) => this.renderListItem(props, { newLocalPredictionRuleList })}
                            onScrollbarPresenceChange={({ horizontal, vertical }) => {
                              if (vertical) {
                                this.listNodeHeaderScrollbar = true;
                              } else {
                                this.listNodeHeaderScrollbar = false;
                              }
                              this.forceUpdate();
                            }}
                          />
                        </div>
                      )}
                    </AutoSizer>
                  </div>

                  <div className="flex-row" style={{ padding: '8px 0px' }}>
                    <div className="flex-row flex-center-align">
                      <div className="flex-grow flex-row flex-center-align" />
                      <Pagination
                        size="small"
                        current={page}
                        total={incidentPredictionRuleList.length}
                        pageSize={pageSize}
                        onChange={this.handlePaginationChange}
                        showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                        showSizeChanger
                        pageSizeOptions={['10', '20', '40', '60', '100', '500', '1000']}
                        onShowSizeChange={this.handlePaginationSizeChange}
                      />
                    </div>
                    <div className="flex-grow" />
                    <Button
                      size="small"
                      type="primary"
                      loading={isSubmitting}
                      disabled={hasError}
                      onClick={this.handleSaveClick}
                      style={{ marginLeft: 8 }}
                    >
                      {intl.formatMessage(appButtonsMessages.update)}
                    </Button>

                    <Popconfirm
                      placement="topRight"
                      title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                      okText={intl.formatMessage(appButtonsMessages.yes)}
                      cancelText={intl.formatMessage(appButtonsMessages.no)}
                      onConfirm={() => this.handleRuleRemove()}
                      onCancel={(event) => event.stopPropagation()}
                    >
                      <Button
                        type="primary"
                        size="small"
                        className="button-color-grey"
                        style={{ marginLeft: 8 }}
                        loading={isSubmitting}
                        disabled={hasErrorRemove}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </Container>

        {addRecommendationModalFlag && (
          <RecommendationModal
            intl={intl}
            activeIncidentKey={activeIncidentKey}
            addRecommendationActive={addRecommendationActive}
            incidentList={incidentList}
            credentials={credentials}
            onClose={() => {
              this.setState({ addRecommendationModalFlag: false });
            }}
          />
        )}
        {this.state.showUpdataGlobalKnowledgeBaseModal && (
          <UpdataGlobalKnowledgeBaseModal
            incident={this.state.updataGlobalKnowledgeBaseActive}
            incidentData={incidentData}
            metricList={metricList}
            title="Update Global Rule"
            onClose={(reload) => {
              this.setState({ showUpdataGlobalKnowledgeBaseModal: false, updataGlobalKnowledgeBaseActive: {} }, () => {
                if (reload) this.reloadData();
              });
            }}
          />
        )}
        {this.state.showUpdateMappingModal && (
          <UpdataGlobalKnowledgeMappingModal
            intl={intl}
            systemsMap={systemsMap}
            projects={projects}
            activeIncidentKey={activeIncidentKey}
            updateMappingActive={this.state.updateMappingActive}
            incidentList={incidentList}
            credentials={credentials}
            onClose={() => {
              this.setState({ showUpdateMappingModal: false, updateMappingActive: {} });
            }}
          />
        )}
        {showCreateNewGlobalModal && (
          <CreateNewGlobalKBModal
            incidentData={incidentData}
            metricList={metricList}
            onClose={(reload) => {
              this.setState({ showCreateNewGlobalModal: false }, () => {
                if (reload) this.reloadData();
              });
            }}
          />
        )}
      </div>
    );
  }
}

const GlobalKnowledgeBase = injectIntl(GlobalKnowledgeBaseCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials, userInfo } = state.auth;
    const { currentTheme, projectDisplayMap, systemsMap, projects } = state.app;
    return {
      location,
      credentials,
      userInfo,
      currentTheme,
      systemsMap,
      projectDisplayMap,
      projects,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(GlobalKnowledgeBase);
