/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import * as R from 'ramda';
import moment from 'moment';

const parseIncidentEntryList = (incidentEvents, params) => {
  const eventList = R.map((event) => {
    const { incidentData, incidentKey, predictionFlag, causalDelay, patternName, patternId } = event;
    const { incidentCompositeKey, timestamp } = incidentKey || {};
    const { id, projectName, userName } = incidentCompositeKey || {};
    const startTimestamp = moment.utc(timestamp).valueOf();

    return {
      ...event,
      incidentData,
      incidentKey,
      projectName,
      userName,
      rawData: incidentData,
      type: 'incident',
      patternName,
      patternId,
      neuronId: patternId,
      nid: patternId,
      timestamp: startTimestamp,
      startTimestamp,
      endTimestamp: startTimestamp,
      instanceName: id,
      isPrediction: !!predictionFlag,
      delay: causalDelay,
    };
  }, incidentEvents || []);

  return eventList;
};

export default parseIncidentEntryList;
