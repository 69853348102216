/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { message, Spin, Button, Select, Checkbox } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
// import { Defaults } from '../../../../common/utils';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { State } from '../../../../common/types';
import { Container } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import fetchPostJson from '../../../../common/apis/fetchPostJson';

type Props = {
  projectName: String,
  refreshTime: Number,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class FieldSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmitting: false,

      eventList: [],
      localEventList: [],

      allSummaryCheck: false,
    };
    this.typeOptions = [
      { value: 'string', label: 'string' },
      { value: 'number', label: 'number' },
      { value: 'JSONArray', label: 'JSONArray' },
    ];
    this.cmp = (x, y) => x.jsonKey === y.jsonKey && x.type === y.type && x.summaryCheck === y.summaryCheck;
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.projectName !== nextProps.projectName || this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { projectName, credentials } = props;
    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();

    const request = [
      fetchGet(getEndpoint('logjsontype'), {
        ...credentials,
        projectName,
      }),
      fetchGet(getEndpoint('logsummarysettings'), {
        ...credentials,
        projectName,
      }),
    ];

    Promise.all(request)
      .then((res) => {
        const [data, summaryData] = res || [];
        const eventData = R.map(
          (item) => ({ ...item, summaryCheck: R.includes(item.jsonKey, summaryData || []) }),
          data || [],
        );
        const allSummaryCheck = eventData.length > 0 && !R.find((m) => !m.summaryCheck, eventData);
        this.setState({
          isLoading: false,
          eventList: eventData || [],
          localEventList: eventData || [],
          allSummaryCheck,
        });
      })
      .catch((err) => {
        message.error(err.message || String(err));
        this.setState({ isLoading: false, eventList: [], localEventList: [], allSummaryCheck: false });
      });
  }

  @autobind
  renderListView(rowData, index) {
    if (!rowData) return null;
    const { localEventList } = this.state;
    const { jsonKey, type, summaryCheck } = rowData;

    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {jsonKey}
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          <Select
            className="full-width"
            allowClear={false}
            filterOption
            options={this.typeOptions}
            value={type}
            onChange={(type) =>
              this.setState((prevState) => ({
                localEventList: update(prevState.localEventList, {
                  [index]: { $set: { ...(prevState.localEventList[index] || {}), type } },
                }),
              }))
            }
          />
        </div>
        <div className="row-column flex-center-justify" style={{ width: 140 }}>
          <Checkbox
            checked={summaryCheck}
            onChange={(e) => {
              const newEvent = R.addIndex(R.map)((item, idx) => {
                if (idx === index) {
                  return { ...item, summaryCheck: e.target.checked };
                }
                return item;
              }, localEventList || []);
              const allSummaryCheck = newEvent.length > 0 && !R.find((m) => !m.summaryCheck, newEvent);
              this.setState({ localEventList: newEvent, allSummaryCheck });
            }}
          />
        </div>
      </div>
    );
  }

  @autobind
  handleSaveClick() {
    const { projectName, credentials } = this.props;

    this.setState({ isSubmitting: true });
    const { localEventList, eventList } = this.state;

    const diff = R.differenceWith(this.cmp, localEventList, eventList);
    this.props.updateLastActionInfo();

    const summarySettings = R.map(
      (item) => item.jsonKey,
      R.filter((_item) => _item.summaryCheck, localEventList || []),
    );

    const request = [
      fetchPost(getEndpoint('logjsontype'), {
        ...credentials,
        projectName,
        jsonTypes: JSON.stringify(diff),
      }),
      fetchPostJson(getEndpoint('logsummarysettings'), { ...credentials, projectName }, summarySettings).then((res) =>
        res.json(),
      ),
    ];

    Promise.all(request)
      .then((res) => {
        const [data, summaryData] = res || [];
        const { success, message: msg } = data || {};
        const { success1, message: msg1 } = summaryData || {};
        if ((success || success === undefined) && (success1 || success1 === undefined)) {
          message.success(msg || msg1);
          this.setState({ isSubmitting: false });
          this.reloadData(this.props);
        } else {
          message.error(msg || msg1);
          this.setState({ isSubmitting: false });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        this.setState({ isSubmitting: false });
      });
  }

  render() {
    const { intl } = this.props;
    const { isLoading, isSubmitting, eventList, localEventList, allSummaryCheck } = this.state;
    const diff = R.differenceWith(this.cmp, localEventList, eventList);
    const hasError = diff.length === 0;
    return (
      <Container className="full-height">
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <div className="full-height flex-col">
            {/* <div className="flex-row flex-center-align" style={{ marginBottom: 12 }} /> */}

            <div className="event-list flex-grow flex-col flex-min-height">
              <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                <div className="header-column" style={{ width: 100, flex: 1 }}>
                  {intl.formatMessage(settingsMessages.jsonKey)}
                </div>
                <div className="header-column" style={{ width: 100, flex: 1 }}>
                  {intl.formatMessage(appFieldsMessages.type)}
                </div>
                <div className="header-column flex-center-justify flex-col" style={{ width: 140 }}>
                  {intl.formatMessage(settingsMessages.summarySettings)}
                  <Checkbox
                    checked={allSummaryCheck}
                    onChange={(e) => {
                      const newList = R.map(
                        (item) => ({
                          ...item,
                          summaryCheck: e.target.checked,
                        }),
                        localEventList || [],
                      );
                      this.setState({ allSummaryCheck: e.target.checked, localEventList: newList });
                    }}
                  />
                </div>
              </div>
              <div className="event-list-grid flex-grow overflow-y-auto">
                {R.addIndex(R.map)((rowData, index) => this.renderListView(rowData, index), localEventList || [])}
              </div>
            </div>

            <div className="flex-row" style={{ marginTop: 12 }}>
              <div className="flex-grow" />
              <Button
                size="small"
                type="primary"
                disabled={hasError}
                loading={isSubmitting}
                onClick={this.handleSaveClick}
              >
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </div>
          </div>
        </Spin>
      </Container>
    );
  }
}

const FieldSetting = injectIntl(FieldSettingCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials } = state.auth;

    return { location, loadStatus, credentials };
  },
  {
    updateLastActionInfo,
  },
)(FieldSetting);
