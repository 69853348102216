/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Layout, Alert, Drawer, Input, Button } from 'antd';
import { AppstoreAddOutlined, HomeOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

import { Container } from '../../../lib/fui/react';
import { parseLocation, buildLocation, Defaults, GlobalRenderers, MenuBarRender } from '../../../common/utils';
import {
  createLoadAction,
  createSetAction,
  ActionTypes as AppActionTypes,
  setOpenedSubMenus,
  setCurrentTheme,
  setCollapsed,
} from '../../../common/app/actions';
import { logoff, loginSuccess } from '../../../common/auth/actions';
import { appFieldsMessages, appMessages } from '../../../common/app/messages';

import PageLoader from './PageLoader';
import logo from '../../../../images/InsightFinder_Horizontal_White.svg';
import AppStore from './AppStore';
import BaseUrls from '../BaseUrls';
import refreshTokenFnTime from '../../../common/apis/refreshTokenFnTime';

const { Content, Sider } = Layout;

type Props = {
  intl: Object,
  location: Object,
  push: Function,
  replace: Function,
  children: Element<any>,

  licenseValid: Boolean,
  licenseWillExpired: Boolean,
  expirationDate: Number,
  currentTheme: String,
  // eslint-disable-next-line
  timezoneOffset: Number,
  pageLoaderVisible: Boolean,
  // eslint-disable-next-line
  createLoadAction: Function,
  createSetAction: Function,

  credentials: Object,
  userInfo: Object,
  userName: String,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  // eslint-disable-next-line
  userList: Array<Object>,
  globalInfoLoaded: Boolean,
  globalInfo: Array<Object>,
  needsGlobalInfo: Boolean,
  toursState: Object,
  logoff: Function,
  loginSuccess: Function,
  systemsMap: Object,
  openedSubMenus: Array<string>,
  setOpenedSubMenus: Function,
  setCurrentTheme: Function,
  collapsed: Boolean,
  setCollapsed: Function,
  showDetailsFrame: Boolean,
};

let pendoInited = false;

class AppCentricPageCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { collapsed } = props;

    this.state = {
      collapsed,
      localTime: moment().format(Defaults.TimeOnlyFormat),
      greetUser: null,
      appStoreOpen: false,
      appFilter: '',
    };
    this.ruleStrMap = {
      ReadOnlyUser: 'ReadOnlyUser',
      LocalAdmin: 'Administrator',
      NormalUser: 'NormalUser',
      Admin: 'Global Administrator',
    };
    this.handleMouseMove = debounce(this.handleMouseMove, 1000, { trailing: true });
  }

  componentDidMount() {
    const { intl, userInfo } = this.props;
    moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    if (!this.applyParamsAndRedirect(this.props)) {
      this.loadData(this.props);
    }

    try {
      if (pendo && !pendoInited && pendo.initialize) {
        const { signupDate, expirationDate } = userInfo || {};
        const urlRex = 'https://sandbox.insightfinder.com';
        const url = window.BASE_URL || window.location.origin || '';

        let account = { id: 'demoUser', name: 'Demo' };
        if (urlRex === url) {
          account = { id: 'Sandbox User', name: 'Demo' };
        } else if (signupDate) {
          const timeDifference = Math.abs(expirationDate - signupDate);
          const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);
          if (differenceInDays <= 30) {
            account = { id: 'Free Trial User', name: 'Demo' };
          }
        }

        pendo.initialize({
          visitor: {
            id: userInfo?.userName || 'demoUser',
            email: userInfo?.email || 'demo@insightfinder.com',
            full_name: userInfo?.fullName || 'Demo',
          },
          account,
        });
        pendo.validateEnvironment();
        pendoInited = true;
      }
    } catch (e) {}

    // set the local timezone
    this.setLocalTimezone();
    this.timer = setInterval(() => {
      this.refreshTime();
    }, 20 * 1000);

    // Clear refresh timer
    window.localStorage.removeItem('refreshTokenFnTime');
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl, replace, location, isAdmin, userName, globalInfo, isLocalAdmin } = nextProps;
    if (intl.locale !== this.props.intl.locale) {
      moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    }
    const nextQuery = parseLocation(location);
    const query = parseLocation(this.props.location);
    const { startTime, endTime, customerName, environmentId } = nextQuery;
    if (nextProps.location !== this.props.location) {
      if (!startTime || !endTime || ((isAdmin || isLocalAdmin) && !customerName)) {
        this.applyParamsAndRedirect(nextProps);
      } else if (!environmentId || startTime !== query.startTime || endTime !== query.endTime) {
        this.loadData(nextProps);
      }
    } else if (nextProps.globalInfo !== this.props.globalInfo) {
      // Get the environmentId
      if (globalInfo && globalInfo.length > 0) {
        const envId = (
          R.find((i) => i.id === environmentId, globalInfo) ||
          R.find((i) => i.id === 'All', globalInfo) ||
          R.find((i) => i.ownerUserName === userName, globalInfo) ||
          R.find((i) => i.ownerUserName === customerName, globalInfo) ||
          globalInfo[0]
        ).id;
        replace(
          buildLocation(
            location.pathname,
            {},
            { ...nextQuery, customerName, startTime, endTime, environmentId: envId },
          ),
        );
      }
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};

    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  @autobind
  handleMouseMove() {
    const { credentials } = this.props;
    refreshTokenFnTime(credentials);
  }

  @autobind
  applyParamsAndRedirect(props) {
    const { location, replace, isAdmin, userList, isLocalAdmin } = props;
    let { timezoneOffset } = props;
    const query = parseLocation(location);

    let redirect = false;
    let { customerName, startTime, endTime } = query;

    if ((isAdmin || isLocalAdmin) && !customerName && userList && userList.length > 0) {
      const user = userList[0] || R.find((u) => u.userName === 'user' || u.userName === 'guest', userList);
      if (user) {
        customerName = user.userName;
        timezoneOffset = user.userZoneOffset;
        redirect = true;
      }
    }

    if (!startTime) {
      const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
      startTime = moment.utc(nowTimestamp).format(Defaults.DateFormat);
      if (location.pathname === '/global/health') {
        startTime = moment.utc(nowTimestamp).subtract(1, 'days').format(Defaults.DateFormat);
      }
      redirect = true;
    }
    if (!endTime) {
      const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
      endTime = moment.utc(nowTimestamp).format(Defaults.DateFormat);
      redirect = true;
    }

    if (redirect) {
      replace(buildLocation(location.pathname, {}, { ...query, startTime, endTime, customerName }));
    }

    return redirect;
  }

  @autobind
  refreshTime() {
    this.setLocalTimezone();
  }

  @autobind
  setLocalTimezone() {
    const { timezoneOffset } = this.props;
    const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
    const now = moment.utc(nowTimestamp);
    const hours = now.hours();

    let greetUser = 'evening';
    if (hours >= 5 && hours < 11) {
      greetUser = 'morning';
    } else if (hours >= 11 && hours < 16) {
      greetUser = 'afternoon';
    }
    const localTime = now.format(Defaults.TimeOnlyFormat);
    this.setState({ localTime, greetUser });
  }

  @autobind
  loadData(props) {
    const { location, isAdmin, userName, createLoadAction, isLocalAdmin, needsGlobalInfo } = props;
    const query = parseLocation(location);
    const { startTime, endTime, customerName } = query;

    if (startTime && endTime && (!isAdmin || (isAdmin && customerName))) {
      createLoadAction(
        AppActionTypes.LOAD_INFO_GLOBAL,
        {
          startTime,
          endTime,
          customerName: isAdmin || isLocalAdmin ? customerName : userName,
          environmentOnly: false,
          needsGlobalInfo,
        },
        true,
        true,
        false,
      );
    }
  }

  @autobind
  onCollapseSider(collapsed) {
    this.setState({ collapsed }, () => {
      this.props.setCollapsed(collapsed);
    });
  }

  @autobind
  handleAppSearch(e) {
    const appFilter = e.target.value;
    this.setState({ appFilter });
  }

  render() {
    const { intl, push, location, setOpenedSubMenus, createSetAction, children } = this.props;
    const {
      licenseValid,
      licenseWillExpired,
      expirationDate,
      currentTheme,
      pageLoaderVisible,
      credentials,
      userInfo,
      toursState,
      logoff,
      loginSuccess,
      openedSubMenus,
      systemsMap,
      setCurrentTheme,
      showDetailsFrame,
    } = this.props;
    const pathname = location?.pathname;
    const { globalInfoLoaded } = this.props;
    const query = parseLocation(location);
    const { localTime, greetUser, appStoreOpen, appFilter } = this.state;
    let userNameDisplay = userInfo.firstName || (userInfo.fullName || '').split(' ')[0] || userInfo.userName;
    userNameDisplay = userInfo?.role ? this.ruleStrMap[userInfo.role] : userNameDisplay;
    userNameDisplay = userInfo.fullName || userNameDisplay;
    const showAppStore = pathname !== BaseUrls.GlobalStart;

    return (
      <Layout className="app-centric-page" style={{ minHeight: '100vh' }}>
        {(licenseValid === false || licenseWillExpired) && (
          <Alert
            message={
              licenseValid === false
                ? intl.formatMessage(appMessages.licenseValidFaild)
                : intl.formatMessage(appMessages.licenseWillExpired, {
                    date: <b>{moment.utc(expirationDate).format(Defaults.DateFormat)}</b>,
                  })
            }
            banner
          />
        )}
        <Drawer
          className="full-height-drawer"
          title={
            <div style={{ paddingRight: 8 }}>
              <Input.Search
                size="small"
                style={{ marginTop: 0 }}
                placeholder="Find..."
                allowClear
                value={appFilter}
                onChange={this.handleAppSearch}
              />
            </div>
          }
          placement="left"
          width={570}
          open={appStoreOpen}
          onClose={() => this.setState({ appStoreOpen: false, appFilter: '' })}
        >
          <div className="full-height app-store">
            <AppStore
              appFilter={appFilter}
              onAppClick={() => {
                this.setState({ appStoreOpen: false, appFilter: '' });
              }}
            />
          </div>
        </Drawer>
        <Container className="topbar" style={{ alignItems: 'center' }}>
          <Link to="/" className="logo">
            <img src={logo} alt="logo" />
          </Link>
          {showAppStore && (
            <div
              style={{ fontSize: 20, marginRight: 20 }}
              className="pl-2 clickable"
              onClick={() => this.setState({ appStoreOpen: true, appFilter: '' })}
            >
              <AppstoreAddOutlined />
            </div>
          )}
          {showAppStore && (
            <div
              style={{ fontSize: 20, marginRight: 20 }}
              className="clickable"
              onClick={() => {
                push(buildLocation(BaseUrls.GlobalStart, {}, { customerName: query?.customerName }));
              }}
            >
              <HomeOutlined />
            </div>
          )}
          <Button
            size="small"
            type="primary"
            onClick={() => {
              push(buildLocation(BaseUrls.SettingsIntegrations, {}, { customerName: query?.customerName }));
            }}
          >
            {intl.formatMessage(appFieldsMessages.integrations)}
          </Button>
          <div
            className="flex-grow flex-row flex-center-align flex-end-justify"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <span>
              {greetUser === 'morning'
                ? intl.formatMessage(appMessages.goodMorning, { userName: userNameDisplay })
                : greetUser === 'afternoon'
                ? intl.formatMessage(appMessages.goodAfternoon, { userName: userNameDisplay })
                : intl.formatMessage(appMessages.goodEvening, { userName: userNameDisplay })}
            </span>
            <span style={{ marginLeft: 8 }}>{localTime}</span>
          </div>
          <MenuBarRender
            intl={intl}
            location={location}
            push={push}
            userInfo={userInfo}
            credentials={credentials}
            logoff={logoff}
            loginSuccess={loginSuccess}
            createSetAction={createSetAction}
            toursState={toursState}
            currentTheme={currentTheme}
            setCurrentTheme={setCurrentTheme}
          />
        </Container>

        <Layout>
          <Sider
            collapsedWidth={50}
            theme="dark"
            width={220}
            style={{ fontWeight: 'bold' }}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapseSider}
          >
            {GlobalRenderers.SiderMenuRender({
              intl,
              currentTheme,
              push,
              location,
              userInfo,
              systemsMap,
              openedSubMenus,
              setOpenedSubMenus,
              collapsed: this.state.collapsed,
            })}
          </Sider>
          <Layout>
            <Content>
              <PageLoader key="loader" visible={pageLoaderVisible} />
              <div
                className={`full-width full-height ${showDetailsFrame ? '' : 'flex-col'}`}
                style={showDetailsFrame ? { position: 'absolute', top: -1000000000, left: -1000000000 } : {}}
              >
                {globalInfoLoaded && children}
              </div>
              <div
                id="DETAILS_FRAME"
                className={`full-width full-height ${showDetailsFrame ? 'flex-col' : 'display-none'}`}
              />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

const AppCentricPage = injectIntl(AppCentricPageCore);
export default connect(
  (state) => {
    const { userInfo, credentials } = state.auth;
    const { isAdmin, userName, isLocalAdmin } = state.auth?.userInfo;
    const { location } = state.router;
    const {
      licenseValid,
      licenseWillExpired,
      expirationDate,
      currentTheme,
      timezoneOffset,
      globalInfoLoaded,
      globalInfo,
      toursState,
      systemsMap,
      openedSubMenus,
      collapsed,
      showDetailsFrame,
    } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);

    const pageLoaderVisible = Boolean(state.app.pageLoaderVisible);
    return {
      isAdmin,
      isLocalAdmin,
      location,
      userInfo,
      credentials,
      userName,
      licenseValid,
      licenseWillExpired,
      expirationDate,
      currentTheme,
      timezoneOffset,
      pageLoaderVisible,
      globalInfoLoaded,
      globalInfo,
      userList,
      toursState,
      systemsMap,
      openedSubMenus,
      collapsed,
      showDetailsFrame,
    };
  },
  {
    push,
    replace,
    createLoadAction,
    createSetAction,
    logoff,
    loginSuccess,
    setOpenedSubMenus,
    setCurrentTheme,
    setCollapsed,
  },
)(AppCentricPage);
