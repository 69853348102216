import { Root } from '../../protos/js/Root_pb';
import { LogTail } from '../../protos/js/LogTail_pb';
import { Unsubscribe } from '../../protos/js/Unsubscribe_pb';

export const unsubscribe = ({ unsubscribeAll, taskids }) => {
  const root = new Root();
  const unsubscribe = new Unsubscribe();
  unsubscribe.setUnsubscribeall(unsubscribeAll);
  unsubscribe.setTaskidsList(taskids);
  root.setUnsubscribe(unsubscribe);
  return root;
};

export const logTailSend = ({ projectName, useName, instanceName, keyword, taskid }) => {
  const root = new Root();
  const logTail = new LogTail();
  logTail.setUsername(useName);
  logTail.setProjectname(projectName);
  logTail.setInstancename(instanceName);
  logTail.setKeyword(keyword);
  logTail.setPeriod(60); // 表示ws间隔多少时间返回数据
  logTail.setTaskid(taskid);
  root.setLogtail(logTail);
  return root;
};
