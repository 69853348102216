/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import moment from 'moment';

import { ActionTypes } from '../actions';
import { logoff } from '../../auth/actions';

const resetLastActionLogic = createLogic({
  type: [ActionTypes.RESET_LAST_ACTION_INFO],
  cancelType: ActionTypes.APP_STOP,
  debounce: 600,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { data } = action.payload;
    const { credentials, loggedIn } = state.auth;

    const tokenExpiredTime = window.localStorage.getItem('tokenExpiredTime');
    if (loggedIn && (!tokenExpiredTime || moment.utc().valueOf() > Number(tokenExpiredTime))) {
      // log off
      window.alert("User's token expired, please login again.");
      const { pathname, search } = window.location;
      const loginRedirectURL = pathname + search;
      dispatch(logoff(credentials, loginRedirectURL));
    } else {
      const lastActionTime = moment.utc().valueOf();
      window.localStorage.setItem('lastActionInfo', JSON.stringify({ lastActionTime, ...(data || {}) }));
      console.debug(`Last Action Time: ${moment.utc(lastActionTime).format('HH:mm:ss')}`);
    }

    done();
  },
});

export default resetLastActionLogic;
