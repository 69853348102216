import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import ThundraTraceChart from 'thundra-trace-chart';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { get, isArray } from 'lodash';
import { Button, Descriptions, Select, Spin, Tag, message } from 'antd';
import { LineChartOutlined, MessageOutlined, UnorderedListOutlined } from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import BaseUrls from '../../app/BaseUrls';
import { Modal, Popover, Tooltip } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import {
  CellRenderers,
  Defaults,
  EventRenderers,
  GlobalParse,
  LogRenderers,
  buildUrl,
  parseJSON,
  parseLocation,
} from '../../../common/utils';

import { eventMessages } from '../../../common/metric/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { logMessages } from '../../../common/log/messages';
import { appButtonsMessages, appFieldsMessages, appMenusMessages } from '../../../common/app/messages';

import PredictionFlow from './PredictionFlow';
import ProjectSelectorModal from './ProjectSelectorModal';
import InsightQueryBoxModal from './InsightQueryBoxModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  incident: Object,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  location: Object,
  projectDisplayMap: Object,
  credentials: Object,
  currentTheme: String,
  globalInfo: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  userName: String,
  projects: Array<Object>,
  isJWT: Boolean,
  jwtToken: String,
  zoneName: String,
};

class PredictionRootCauseRCACore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      historicalIncident: null,
      detectedIncident: null,
      allIncidentList: [],
      summarySettings: [],

      showProjectSelector: false,
      onConfirmProjectSelect: null,
      showInsightQueryBox: false,
      queryLogProjects: [],
    };

    this.listHeaderHeight = 50;
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData(props) {
    const { location, globalInfo, incident, credentials, isJWT, jwtToken, zoneName } = props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const ownerUserName = get(systemInfo, 'ownerUserName');

    if (incident) {
      this.setState({ loading: true });

      const { patternId, instanceName } = incident;
      const { consolidatedTimePair, predictionSourceInfoList } = incident;
      const { metricRootCauseJson, projectName, projectOwner } = incident;
      const { sourceProjectName, sourceProjectOwner } = predictionSourceInfoList[0] || {};

      let metricinfo = {};
      if (!R.isEmpty(metricRootCauseJson)) {
        metricinfo = { metric: metricRootCauseJson.metricName, direction: metricRootCauseJson.sign };
      }

      const request = [];

      if (!isJWT) {
        const projectAndOwnerName =
          projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
        request.push(
          fetchGet(getEndpoint('incidentrelation', 1), {
            ...credentials,
            customerName: ownerUserName,
            patternId,
            projectName,
            systemName: systemId,
            instanceName,
            consolidatedTimePair: JSON.stringify(consolidatedTimePair || []),
            sourceProjectName,
            sourceProjectOwner,
            ...(!R.isEmpty(metricinfo) ? { metricinfo: JSON.stringify(metricinfo) } : {}),
          }),
        );
        request.push(
          fetchGet(getEndpoint('logsummarysettings'), {
            ...credentials,
            projectName: projectAndOwnerName,
          }),
        );
      } else {
        request.push(
          fetchGet(getEndpoint('incidentrelation-jwt', 1), {
            ...credentials,
            customerName: credentials?.userName || 'user',
            patternId,
            projectName: `${projectName}@${projectOwner}`,
            systemName: systemId,
            instanceName,
            consolidatedTimePair: JSON.stringify(consolidatedTimePair || []),
            sourceProjectName,
            sourceProjectOwner,
            ...(!R.isEmpty(metricinfo) ? { metricinfo: JSON.stringify(metricinfo) } : {}),
            jwt: jwtToken,
            zoneName,
          }),
        );
      }

      props.updateLastActionInfo();
      Promise.all(request)
        .then((results) => {
          const [res1, res2] = results || [];

          let { historicalIncident, detectedIncident, allIncidentList } = this.handleIncidentrelationData(props, res1);
          if (incident.isFixedIncident) allIncidentList = [incident];

          const summarySettings = res2 || [];

          this.setState({
            historicalIncident,
            detectedIncident,
            allIncidentList,
            loading: false,
            summarySettings,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error(err.message || String(err));
        });
    }
  }

  @autobind
  handleIncidentrelationData(props, data) {
    const { location, globalInfo } = props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);

    const eventList = parseJSON(data?.incidentRelationList || '[]');
    let detectedIncidentList = [];
    let historicalIncidentList = [];
    let invalidIncidentList = [];
    let detectedTimeList = [];
    let historicalTimeList = [];
    let invalidTimeList = [];

    R.forEach((event) => {
      const { predictionTime, relatedIncident, relationStatus } = event;
      const isCorresponding = relationStatus === 'corresponding';
      const isHistorical = relationStatus === 'historical';
      const notValid = relationStatus === 'notValid';
      const { incidentData, incidentKey, predictionFlag, patternName, patternId } = relatedIncident || {};
      const { leadTime, rootCause } = relatedIncident || {};
      const { incidentCompositeKey, timestamp } = incidentKey || {};
      const { id, projectName, userName } = incidentCompositeKey || {};
      const timestampObj = moment.utc(timestamp);
      const instanceName = id;

      const instanceInfo = R.find((item) => {
        return item.systemId === systemId && item.id === instanceName;
      }, R.values(get(environment, 'allInstanceLocalMap', {})));
      const componentName = instanceInfo ? instanceInfo.componentId : null;
      const appName = componentName
        ? componentName === instanceName || instanceName.indexOf(`(${componentName})`) >= 0
          ? instanceName
          : `${componentName} (${instanceName})`
        : instanceName;

      const incident = {
        ...relatedIncident,
        predictionTime,
        projectName,
        instanceName,
        appName,
        userName,
        type: 'incident',
        rawData: incidentData,
        rootCause: parseJSON(rootCause),
        patternName,
        patternId,
        timestamp: timestampObj.valueOf(),
        isPrediction: !!predictionFlag,
        leadTime,
      };

      if (isCorresponding) {
        detectedIncidentList.push(incident);
        detectedTimeList.push(timestampObj.valueOf());
      } else if (isHistorical) {
        historicalIncidentList.push(incident);
        historicalTimeList.push(timestampObj.valueOf());
      } else if (notValid) {
        invalidIncidentList.push(incident);
        invalidTimeList.push(timestampObj.valueOf());
      }
    }, eventList);

    detectedTimeList = R.sort((a, b) => b - a, R.uniq(detectedTimeList));
    historicalTimeList = R.sort((a, b) => b - a, R.uniq(historicalTimeList));
    invalidTimeList = R.sort((a, b) => b - a, R.uniq(invalidTimeList));
    detectedIncidentList = R.sortWith([R.descend(R.prop('timestamp'))])(detectedIncidentList);
    historicalIncidentList = R.sortWith([R.descend(R.prop('timestamp'))])(historicalIncidentList);
    invalidIncidentList = R.sortWith([R.descend(R.prop('timestamp'))])(invalidIncidentList);

    const detectedIncident =
      detectedTimeList.length > 0
        ? { ...detectedIncidentList[detectedIncidentList.length - 1], timelist: detectedTimeList }
        : null;
    const historicalIncident =
      historicalTimeList.length > 0
        ? { ...historicalIncidentList[historicalIncidentList.length - 1], timelist: historicalTimeList }
        : null;
    historicalIncidentList = R.map((historicalIncident) => {
      return { predictionTime: historicalIncident.predictionTime };
    }, historicalIncidentList);
    invalidIncidentList = R.map((invalidIncident) => {
      return { predictionTime: invalidIncident.predictionTime, notValid: true };
    }, invalidIncidentList);
    let allIncidentList = R.concat(detectedIncidentList, historicalIncidentList);
    allIncidentList = R.concat(allIncidentList, invalidIncidentList);
    allIncidentList = R.sortWith([R.ascend(R.prop('predictionTime'))])(allIncidentList);
    allIncidentList = R.uniqBy(
      (item) => moment.utc(item.incidentKey?.timestamp).valueOf() + item.predictionTime,
      allIncidentList,
    );

    return { historicalIncident, detectedIncident, allIncidentList };
  }

  @autobind
  incidentListRender() {
    const { intl, incident } = this.props;
    const { allIncidentList } = this.state;
    return (
      <>
        {allIncidentList && allIncidentList.length > 0 && (
          <div className="event-list" style={{ paddingBottom: 8 }}>
            <div
              className="event-list-header"
              style={{
                height: this.listHeaderHeight,
                width: '100%',
                paddingRight: allIncidentList.length > 3 ? 8 : 0,
              }}
            >
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(eventMessages.predictionTime)}
              </div>
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(eventMessages.detectionTime)}
              </div>
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(eventMessages.leadTime)}
              </div>
            </div>
            <div className="event-list-grid" style={{ maxHeight: 121, overflowY: 'overlay' }}>
              {R.addIndex(R.map)(
                (rowData, index) => this.renderCorrespondingInfo(rowData, index, incident),
                allIncidentList,
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  @autobind
  renderCorrespondingInfo(detectedIncident, index, incident) {
    const { intl } = this.props;
    const { isFixedIncident, predictionTimeStamp } = incident;
    const { timestamp, leadTime, predictionTime } = detectedIncident;
    return (
      <div
        key={index}
        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
        style={{ minHeight: 40, pointerEvents: 'none' }}
      >
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {predictionTime ? moment.utc(predictionTime).format(Defaults.DateTimeFormat) : '-'}
        </div>
        <div className="row-column flex-row" style={{ width: 120, flex: 1 }}>
          {timestamp && !isFixedIncident ? moment.utc(timestamp).format(Defaults.DateTimeFormat) : '-'}
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {!isFixedIncident &&
            (leadTime ? CellRenderers.humanizeDuration({ period: timestamp - predictionTimeStamp, intl }) : '-')}
          {isFixedIncident && (
            <Tag color="red" style={{ marginLeft: 8 }}>
              {intl.formatMessage(DashboardMessages.fixed)}
            </Tag>
          )}
        </div>
      </div>
    );
  }

  @autobind
  incidentDetailsRender() {
    const { incident } = this.props;
    const { detectedIncident } = this.state;
    return (
      <div style={{ marginBottom: 8 }} className="ant-descriptions-table">
        <Descriptions bordered size="small">
          <Descriptions.Item label="Predicted incident summary" labelStyle={{ width: 200 }}>
            {this.renderIncidentDetails(incident)}
          </Descriptions.Item>
          {detectedIncident && (
            <Descriptions.Item
              label={this.renderDetectedIncidentTitle(detectedIncident)}
              span={1}
              labelStyle={{ width: 220 }}
            >
              {this.renderDetectedIncidentDetails(detectedIncident)}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  }

  @autobind
  renderIncidentDetails(incident) {
    const { intl, currentTheme } = this.props;
    const { summarySettings } = this.state;
    const { category, rawData, isTrace, patternName, patternId } = incident;

    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternName, patternId },
      { hasFullName: true, hasPrefix: true },
    );

    const rootCauseDetailsArr = get(incident, ['rootCauseJson', 'rootCauseDetailsArr'], []);

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error)
    }
    return (
      <>
        <Popover placement="left" content={patternNameStr}>
          <div className="max-width flex-row" style={{ marginBottom: 4 }}>
            <div style={{ flexShrink: 0, width: 110 }}>{intl.formatMessage(appFieldsMessages.patternIdName)}:</div>
            <div className="hidden-line-with-ellipsis flex-grow">{patternNameStr}</div>
          </div>
        </Popover>
        <Popover
          placement="bottom"
          content={
            <div className="overflow-y-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
              {category === 'metric' && (
                <div>
                  {R.addIndex(R.map)(
                    (event, index) =>
                      EventRenderers.RenderMetricAnomalySummary({
                        intl,
                        event: { ...event, patternName: event.patternName },
                        index,
                      }),
                    rootCauseDetailsArr,
                  )}
                </div>
              )}
              {category === 'log' && (
                <>
                  {isTrace ? (
                    rawDataJson && this.renderTrace(incident, rawDataJson)
                  ) : (
                    <div style={{ wordWrap: 'break-word' }}>
                      {!rawDataJson && (
                        <div style={{ whiteSpace: 'break-spaces' }}>
                          {R.join(
                            '\n',
                            R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                          )}
                        </div>
                      )}
                      {rawDataJson && (
                        <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                          <LogRenderers.RenderLogContent
                            intl={intl}
                            rawData={rawData}
                            rawDataJson={rawDataJson}
                            owner={incident}
                            summarySettings={summarySettings}
                            enableExpansion={false}
                            currentTheme={currentTheme}
                            clearStyle
                            noExpand
                            notViewJsonString
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          }
        >
          <div className="max-width flex-row" style={{ height: '100%', width: 'fit-content' }}>
            <div className="hidden-line-with-ellipsis-multiline clickable">
              {category === 'metric' ? (
                <div className="flex-center-align full-height hidden-line-with-ellipsis-multiline">
                  {R.addIndex(R.map)(
                    (event, index) =>
                      EventRenderers.RenderMetricAnomalySummary({
                        intl,
                        event: { ...event, patternName: event.patternName },
                        index,
                      }),
                    rootCauseDetailsArr,
                  )}
                </div>
              ) : (
                <>
                  {!rawDataJson && <div className="hidden-line-with-ellipsis-multiline">{rawData}</div>}
                  {rawDataJson && (
                    <LogRenderers.RenderLogContent
                      intl={intl}
                      rawData={rawData}
                      rawDataJson={rawDataJson}
                      owner={incident}
                      summarySettings={summarySettings}
                      enableExpansion={false}
                      currentTheme={currentTheme}
                      clearStyle
                      multilineEllipsis
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Popover>
      </>
    );
  }

  @autobind
  renderDetectedIncidentTitle(detectedIncident) {
    const { intl, isReadUser, currentTheme, location, globalInfo, isJWT } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    const { summarySettings } = this.state;
    const { timelist, appName, isPrediction, patternName, patternId, rootCause } = detectedIncident;
    const { rawData, projectName, userName } = detectedIncident;

    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternName, patternId },
      { hasFullName: true, hasPrefix: true },
    );
    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error);
    }

    const isMetric = Boolean(rootCause) && !rawData;

    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, appName, {
      pn: projectName,
      owner: userName,
    });

    const DescriptionTitle = (
      <div className="flex-row">
        <div className="flex-grow hidden-line-with-ellipsis" style={{ paddingRight: 16 }}>
          {intl.formatMessage(logMessages.correspondingDetectedIncident)}:
          {!isReadUser && !isJWT && (
            <Button.Group style={{ marginLeft: 8 }}>
              {isMetric && (
                <Tooltip title={intl.formatMessage(eventMessages.lineChart)} mouseEnterDelay={0.3} placement="top">
                  <Button
                    size="small"
                    icon={<LineChartOutlined />}
                    onClick={() => this.handleIncidentChartClick(detectedIncident)}
                  />
                </Tooltip>
              )}
              <Tooltip title={intl.formatMessage(appFieldsMessages.details)} mouseEnterDelay={0.3} placement="top">
                <Button
                  size="small"
                  icon={<UnorderedListOutlined />}
                  onClick={() => this.handleJumpIncidentClick(detectedIncident)}
                />
              </Tooltip>
            </Button.Group>
          )}
        </div>
      </div>
    );

    return (
      <div className="flex-row flex-center-align">
        <div>Detected incident summary</div>
        <Popover
          placement="bottom"
          content={
            <div className="overflow-y-auto ant-descriptions-table" style={{ maxWidth: 540, maxHeight: 350 }}>
              <Descriptions bordered size="small" column={4} title={DescriptionTitle} labelStyle={{ width: 115 }}>
                <Descriptions.Item label={intl.formatMessage(logMessages.type)} span={2}>
                  {CellRenderers.logTypeRenderer({
                    intl,
                    rowData: detectedIncident,
                    isPrediction,
                    withPredictionPrefix: true,
                  })}
                </Descriptions.Item>
                <Descriptions.Item label={intl.formatMessage(appFieldsMessages.patternIdName)} span={2}>
                  <div style={{ wordBreak: 'break-all' }}>{patternNameStr}</div>
                </Descriptions.Item>

                <Descriptions.Item label={intl.formatMessage(appFieldsMessages.instanceName)} span={2}>
                  <Tooltip mouseEnterDelay={0.3} placement="top" title={<div>{instanceStr}</div>}>
                    <div style={{ wordBreak: 'break-all' }}>{instanceStr}</div>
                  </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label={intl.formatMessage(eventMessages.detectionTime)} span={2}>
                  {R.addIndex(R.map)(
                    (timestamp, index) => (
                      <div key={index}>
                        {moment.utc(timestamp).format(Defaults.DateTimeFormat)}
                        <br />
                      </div>
                    ),
                    timelist,
                  )}
                </Descriptions.Item>
                {rootCause?.timePairList && (
                  <Descriptions.Item label={intl.formatMessage(appFieldsMessages.duration)} span={4}>
                    {(rootCause?.timePairList || [])[0]
                      ? CellRenderers.humanizeDuration({
                          period: (rootCause?.timePairList || [])[0].e - (rootCause?.timePairList || [])[0].s + 60000,
                          intl,
                        })
                      : '-'}
                  </Descriptions.Item>
                )}

                <Descriptions.Item label={intl.formatMessage(eventMessages.eventSummary)} span={4}>
                  {rawData && (
                    <LogRenderers.RenderLogContent
                      intl={intl}
                      rawData={rawData}
                      rawDataJson={rawDataJson}
                      owner={detectedIncident}
                      summarySettings={summarySettings}
                      currentTheme={currentTheme}
                    />
                  )}
                  {rootCause && rootCause.metricName && (
                    <div style={{ wordBreak: 'break-word' }}>
                      {EventRenderers.RenderMetricAnomalySummary({ intl, event: { ...rootCause, patternName } })}
                    </div>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </div>
          }
        >
          <Button size="small" icon={<MessageOutlined />} style={{ marginLeft: 4 }} />
        </Popover>
      </div>
    );
  }

  @autobind
  renderDetectedIncidentDetails(detectedIncident) {
    const { intl, currentTheme } = this.props;
    const { summarySettings } = this.state;
    const { rootCause, patternName, patternId } = detectedIncident;
    const { rawData } = detectedIncident;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error);
    }

    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternName, patternId },
      { hasFullName: true, hasPrefix: true },
    );

    return (
      <>
        <Popover placement="left" content={patternNameStr}>
          <div className="max-width flex-row" style={{ marginBottom: 4 }}>
            <div style={{ flexShrink: 0, width: 110 }}>{intl.formatMessage(appFieldsMessages.patternIdName)}:</div>
            <div className="hidden-line-with-ellipsis flex-grow">{patternNameStr}</div>
          </div>
        </Popover>
        <Popover
          placement="bottom"
          content={
            <div className="overflow-y-auto overflow-x-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
              {rawData && (
                <LogRenderers.RenderLogContent
                  intl={intl}
                  rawData={rawData}
                  rawDataJson={rawDataJson}
                  owner={detectedIncident}
                  summarySettings={summarySettings}
                  currentTheme={currentTheme}
                  enableExpansion={false}
                  clearStyle
                  noExpand
                  notViewJsonString
                />
              )}
              {rootCause && rootCause.metricName && (
                <div style={{ wordBreak: 'break-word' }}>
                  {EventRenderers.RenderMetricAnomalySummary({
                    intl,
                    event: { ...rootCause, patternName },
                  })}
                </div>
              )}
            </div>
          }
        >
          <div className="max-width flex-row" style={{ height: '100%', width: 'fit-content' }}>
            <div className="hidden-line-with-ellipsis-multiline clickable">
              {rawData && (
                <>
                  {!rawDataJson && <div className="hidden-line-with-ellipsis-multiline">{rawData}</div>}
                  {rawDataJson && (
                    <LogRenderers.RenderLogContent
                      intl={intl}
                      rawData={rawData}
                      rawDataJson={rawDataJson}
                      owner={detectedIncident}
                      summarySettings={summarySettings}
                      currentTheme={currentTheme}
                      enableExpansion={false}
                      clearStyle
                      multilineEllipsis
                    />
                  )}
                </>
              )}
              {rootCause && rootCause.metricName && (
                <div className="flex-center-align full-height hidden-line-with-ellipsis-multiline">
                  {EventRenderers.RenderMetricAnomalySummary({
                    intl,
                    event: { ...rootCause, patternName },
                  })}
                </div>
              )}
            </div>
          </div>
        </Popover>
      </>
    );
  }

  @autobind
  handleIncidentChartClick(rowData) {
    const { location, credentials } = this.props;
    const { environmentId } = parseLocation(location);
    const { userName, timestamp, rootCause } = rowData;
    let { projectName } = rowData;
    if (userName !== credentials.userName) {
      projectName = `${projectName}@${userName}`;
    }
    const startTs = moment.utc(timestamp).startOf('day').valueOf();
    const endTs = moment.utc(timestamp).endOf('day').valueOf();
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    const { instanceName, metricName } = rootCause || {};

    const query = {
      projectName,
      instanceGroup,
      modelType,
      startTimestamp: startTs,
      endTimestamp: endTs,
      justSelectMetric: metricName,
      justInstanceList: instanceName,
      withBaseline: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleMatchedIncidentPatternClick(rowData) {
    const { credentials, projects } = this.props;
    const { userName, timestamp, anomalyLogInstance, instanceName, patternId, type: eventType } = rowData;
    let { projectName } = rowData;
    if (userName !== credentials.userName) {
      projectName = `${projectName}@${userName}`;
    }
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const startTime = moment.utc(timestamp).format(Defaults.DateFormat);
    const endTime = moment.utc(timestamp).format(Defaults.DateFormat);

    const query = {
      projectName,
      instanceName: anomalyLogInstance || instanceName,
      startTime,
      endTime,
      activeTab: eventType.toLowerCase() === 'rare' ? 'important' : 'clusters',
      activePatternId: eventType.toLowerCase() === 'rare' ? undefined : patternId,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project?.owner || userName,
      anomalyType: eventType.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  handleJumpIncidentClick(rowData) {
    const { location } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const { userName, timestamp, projectName, patternId, instanceName, anomalyLogInstance, isIgnored, rootCause } =
      rowData;
    const { metricName } = rootCause || {};

    const startTime = moment.utc(timestamp).format(Defaults.DateFormat);
    const endTime = moment.utc(timestamp).format(Defaults.DateFormat);
    const query = {
      environmentId,
      customerName: userName,
      systemId,
      startTime,
      endTime,

      eventCategory: 'incident',
      eventPatternType: 'incident',
      eventProjectName: projectName,
      eventPatternId: patternId,
      eventInstanceName: anomalyLogInstance || instanceName,
      eventRootCauseMetric: metricName || undefined,
      eventTimestamp: timestamp,
      hideIgnore: !isIgnored,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  }

  @autobind
  handleOverallLineChartClick() {
    const { intl, credentials, incident } = this.props;

    const { predictionSourceInfoList = [] } = incident || {};
    const projectMap = {};
    R.forEach((sourceInfo) => {
      const {
        sourceDetail,
        sourceInstanceName: instanceName,
        sourceProjectOwner: owner,
        predictionTime: displayTimestamp,
      } = sourceInfo;
      const { type, content } = sourceDetail || {};
      if ((type || '').toLowerCase() === 'metric') {
        let { sourceProjectName: projectName } = sourceInfo;
        projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;

        if (!R.has(projectName, projectMap)) {
          projectMap[projectName] = { projectName, times: [], instances: [], metrics: [], metricsMap: {} };
        }

        projectMap[projectName].times.push(displayTimestamp);
        projectMap[projectName].instances.push(instanceName);
        projectMap[projectName].metrics.push(content);
        if (!R.has(content, projectMap[projectName].metricsMap)) {
          projectMap[projectName].metricsMap[content] = [];
        }
        projectMap[projectName].metricsMap[content].push(instanceName);
      }
    }, predictionSourceInfoList);

    const projectNames = R.keys(projectMap);
    let selectProject = projectNames.length > 0 ? projectNames[0] : undefined;
    Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      bodyStyle: { marginLeft: 0 },
      style: { minWidth: 500 },
      content: selectProject ? (
        <div className="flex-row flex-center-align">
          <div style={{ marginRight: 8 }}>Metric Project:</div>
          <div className="flex-grow">
            <Select
              size="small"
              style={{ width: 250 }}
              filterOption
              options={R.map((item) => ({ value: item, label: item }), projectNames)}
              defaultValue={selectProject}
              onChange={(project) => {
                selectProject = project;
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex-row flex-center-align">
          <div style={{ marginRight: 8 }}>No anomaly metric project</div>
        </div>
      ),
      onOk: (close) =>
        selectProject ? this.handleSelectProjectConfirm(close, incident, get(projectMap, selectProject, {})) : close(),
    });
  }

  @autobind
  handleSelectProjectConfirm(close, incident, params) {
    const { location } = this.props;
    const { customerName } = parseLocation(location);
    const { projectName, times, instances, metrics, metricsMap } = params;
    const { startTimestamp: incidentStart, endTimestamp: incidentEnd, patternId, isIncident, isDeployment } = incident;
    const rootCauseStart = R.reduce(R.min, incidentStart, times);
    const rootCauseEnd = R.reduce(R.max, incidentStart, times);
    const startTimestamp = moment.utc(rootCauseStart).startOf('day').valueOf();
    const endTimestamp = moment.utc(rootCauseEnd).endOf('day').valueOf();

    const incidentInfo = {
      startTimestamp: incidentStart,
      endTimestamp: incidentEnd,
      patternId,
      type: isIncident ? 'incident' : isDeployment ? 'deployment' : 'normal',
      isPrediction: true,
    };
    const metricAnomalyMap = R.mapObjIndexed((val) => {
      return R.uniq(val);
    }, metricsMap);

    const query = {
      customerName,
      projectName,
      instanceGroup: 'All',
      startTimestamp,
      endTimestamp,
      justSelectMetric: R.join(',', R.uniq(metrics)),
      justInstanceList: R.join(',', R.uniq(instances)),
      incidentInfo: JSON.stringify(incidentInfo),
      metricAnomalyMap: JSON.stringify(metricAnomalyMap),
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');

    close();
  }

  @autobind
  renderTrace(rawData, rawDataJson) {
    const { outlierValue, componentName, id } = rawData;
    const traceId = get(rawDataJson, 'trace_id');
    const parentId = get(rawDataJson, 'parent_span_id');
    const spanId = get(rawDataJson, 'span_id');
    const serviceName = get(rawDataJson, ['attributes', 'http.server_name']) || componentName;
    const name = get(rawDataJson, 'method_name');
    const startTime = get(rawDataJson, 'start_time');
    const duration = Number(get(rawDataJson, 'duration', 0)) * 1000;
    const timestamp = moment.utc(startTime).valueOf() * 1000;
    const traceInfo = [
      {
        traceId: id,
        parentId,
        id: spanId,
        name,
        timestamp,
        duration,
        serviceName,
        color: '#ffccc7',
        tags: {},
      },
    ];

    return (
      <div className="flex-col" style={{ width: 650, flexShrink: 0 }} onClick={(e) => e.stopPropagation()}>
        <div className="trace-timelines">
          <ThundraTraceChart
            traceId={id}
            traceSummary={traceInfo}
            showHeader={false}
            showMiniTrace={false}
            showSpanDetailTitle={false}
            showSpanDetail={false}
          />
        </div>
        <div className="flex-col">
          <div className="flex-col" style={{ marginBottom: 8 }}>
            <div className="flex-row" style={{ wordBreak: 'break-word' }}>
              <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
                Service Name:
              </span>
              <span style={{ marginLeft: 8 }}>{serviceName}</span>
            </div>
            <div className="flex-row" style={{ wordBreak: 'break-word' }}>
              <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
                Span Info:
              </span>
              <span style={{ marginLeft: 8 }}>{name}</span>
            </div>
            {outlierValue && (
              <LogRenderers.RenderOutlierValue
                className="flex-row"
                style={{ wordBreak: 'break-word' }}
                outlierValue={outlierValue}
                isTrace
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  handleLineChartClick({ event }) {
    const { isAdmin, isReadUser, isLocalAdmin, location, userName } = this.props;
    const { customerName, startTime, endTime, environmentId, systemId } = parseLocation(location);
    const { isPrediction, projectOwner, metricList, realInstanceName, instanceList } = event;
    const { predictionTime, startTimestamp: predictionOccurrenceTime } = event;
    let { projectName } = event;
    const isSharedUser = projectOwner !== userName;
    if (isAdmin || isLocalAdmin || isReadUser || isLocalAdmin || isSharedUser) {
      projectName = `${projectName}@${projectOwner || customerName}`;
    }

    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';
    const justInstanceList = realInstanceName ? [realInstanceName] : instanceList;

    let startTimestamp;
    const predictionTimeObj = moment.utc(predictionTime);
    if (predictionTimeObj.hours() < 2) {
      startTimestamp = moment.utc(predictionTime).subtract(1, 'days').startOf('day').valueOf();
    } else {
      startTimestamp = moment.utc(predictionTime).startOf('day').valueOf();
    }
    const endTimestamp = moment.utc(predictionOccurrenceTime).endOf('day').valueOf();

    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,
      projectName,
      instanceGroup,
      modelType,
      startTimestamp,
      endTimestamp,
      predictedFlag: Boolean(isPrediction),
      detectedEventType: Boolean(isPrediction),
      justSelectMetric: R.join(',', metricList),
      justInstanceList: R.join(',', justInstanceList),
    };

    if (event.containerInfo) {
      const { containerName, instanceName } = event.containerInfo;
      query.justInstanceList = `${containerName}_${instanceName}`;
    }

    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleLineChartJump({ event, predictionSourceInfo }) {
    const { location, globalInfo } = this.props;
    const { customerName, startTime, endTime, environmentId, systemId } = parseLocation(location);

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const metricProjects = R.filter((project) => project.dataType === 'Metric', projectNameSet);

    if (metricProjects.length > 1) {
      this.setState({
        showProjectSelector: true,
        onConfirmProjectSelect: this.handleLineChartsJumpConfirm({ event }),
        detailsActiveIncident: predictionSourceInfo || {
          ...event,
          instanceListStr: event.containerInfo
            ? `${event?.containerInfo?.containerName}_${event?.containerInfo?.instanceName}`
            : event.realInstanceName,
        },
      });
    } else {
      const { projectNameReal } = metricProjects[0];
      const startTimeObj = moment.utc(startTime, Defaults.DateFormat).startOf('day');
      const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';
      const query = {
        startTime,
        endTime,
        customerName,
        environmentId,
        systemId,
        projectName: projectNameReal,
        instanceGroup,
        modelType,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        justInstanceList: event.realInstanceName,
        withBaseline: true,
      };

      if (event.containerInfo) {
        const { containerName, instanceName } = event.containerInfo;
        query.justInstanceList = `${containerName}_${instanceName}`;
      }

      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    }
  }

  @autobind
  handleLineChartsJumpConfirm({ event }) {
    return (projectName) => {
      const { location } = this.props;
      const { customerName, startTime, endTime, environmentId, systemId } = parseLocation(location);
      const { predictionTime, startTimestamp: predictionOccurrenceTime } = event;
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';

      let startTimestamp;
      const predictionTimeObj = moment.utc(predictionTime);
      if (predictionTimeObj.hours() < 2) {
        startTimestamp = moment.utc(predictionTime).subtract(1, 'days').startOf('day').valueOf();
      } else {
        startTimestamp = moment.utc(predictionTime).startOf('day').valueOf();
      }
      const endTimestamp = moment.utc(predictionOccurrenceTime).endOf('day').valueOf();

      const query = {
        startTime,
        endTime,
        customerName,
        environmentId,
        systemId,
        projectName,
        instanceGroup,
        modelType,
        startTimestamp,
        endTimestamp,
        justInstanceList: event.realInstanceName,
        withBaseline: true,
      };

      if (event.containerInfo) {
        const { containerName, instanceName } = event.containerInfo;
        query.justInstanceList = `${containerName}_${instanceName}`;
      }

      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    };
  }

  @autobind
  onPredictionJumpLogClick(event) {
    const { userName, projects } = this.props;
    const { patternId, instanceName, type, projectName: pn, projectOwner, startTimestamp } = event;
    let projectName = pn;
    const isOwner = projectOwner === userName;
    if (!isOwner) {
      projectName = `${projectName}@${projectOwner}`;
    }
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);

    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(event, ['isIncident'], false);
    const dateTimeObj = moment.utc(startTimestamp).startOf('day');
    const dateTime = dateTimeObj.format(Defaults.DateFormat);
    const query = {
      projectName,
      instanceName,
      startTime: dateTime,
      endTime: dateTime,
      activeTab: R.toLower(type) === 'rare' ? 'important' : 'clusters',
      activePatternId: patternId,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project?.owner || projectOwner,
      anomalyType: type.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  onPredictionSourceLineChartClick(event, predictionSourceInfo) {
    const { location, userName } = this.props;
    const { customerName, startTime, endTime, environmentId, systemId } = parseLocation(location);
    const { predictionTime, startTimestamp: predictionOccurrenceTime } = event;
    const { sourceProjectName, sourceProjectOwner, sourceInstanceName, containerId, sourceDetail } =
      predictionSourceInfo || {};
    const { content } = sourceDetail || {};

    let projectName = sourceProjectName;
    const isOwner = sourceProjectOwner === userName;
    if (!isOwner) {
      projectName = `${projectName}@${sourceProjectOwner}`;
    }
    let startTimestamp;
    const predictionTimeObj = moment.utc(predictionTime);
    if (predictionTimeObj.hours() < 2) {
      startTimestamp = moment.utc(predictionTime).subtract(1, 'days').startOf('day').valueOf();
    } else {
      startTimestamp = moment.utc(predictionTime).startOf('day').valueOf();
    }
    const endTimestamp = moment.utc(predictionOccurrenceTime).endOf('day').valueOf();
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,

      projectName,
      instanceGroup,
      modelType,
      startTimestamp,
      endTimestamp,
      justSelectMetric: content,
      justInstanceList:
        containerId && !sourceInstanceName.includes('_') ? `${containerId}_${sourceInstanceName}` : sourceInstanceName,
      withBaseline: true,
    };

    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  onPredictionSourceJumpClick(event, predictionSourceInfo) {
    const { userName, projects } = this.props;
    const { predictionTime, sourceProjectName, sourceProjectOwner, sourceInstanceName, sourceDetail } =
      predictionSourceInfo || {};
    const { type, nid, instanceNameInThisProject } = parseJSON(sourceDetail) || {};
    let projectName = sourceProjectName;
    const isOwner = sourceProjectOwner === userName;
    if (!isOwner) {
      projectName = `${projectName}@${sourceProjectOwner}`;
    }
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const dateTimeObj = moment.utc(predictionTime).startOf('day');
    const dateTime = dateTimeObj.format(Defaults.DateFormat);

    const query = {
      projectName,
      instanceName: instanceNameInThisProject || sourceInstanceName,
      startTime: dateTime,
      endTime: dateTime,
      activeTab: R.toLower(type) === 'rare' ? 'important' : 'clusters',
      activePatternId: nid,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project?.owner || sourceProjectOwner,
      anomalyType: type.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  onPredictionSourceTrendPatternsClick(event, predictionSourceInfo) {
    const { userName } = this.props;
    const { predictionTime, sourceProjectName, sourceProjectOwner, sourceInstanceName, sourceDetail } =
      predictionSourceInfo || {};
    const { nid, instanceNameInThisProject } = parseJSON(sourceDetail) || {};

    let projectName = sourceProjectName;
    const isOwner = sourceProjectOwner === userName;
    if (!isOwner) {
      projectName = `${projectName}@${sourceProjectOwner}`;
    }
    const startTimeObj = moment.utc(predictionTime).subtract(7, 'days').startOf('day');
    const endTimeObj = moment.utc(predictionTime).endOf('day');

    const query = {
      t: '953de6a33d8a4b96ac9c100bf69ba3fc',
      projectName,
      instanceName: instanceNameInThisProject || sourceInstanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      pattern: nid,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
  }

  @autobind
  onPredictionSourceLogQueryClick(event, predictionSourceInfo) {
    const { userName, projectDisplayMap } = this.props;
    const { sourceProjectName, sourceProjectOwner } = predictionSourceInfo || {};
    const projectName =
      sourceProjectOwner !== userName ? `${sourceProjectName}@${sourceProjectOwner}` : sourceProjectName;
    const projectDisplayName = get(projectDisplayMap, projectName, projectName);
    this.setState({
      showInsightQueryBox: true,
      queryLogProjects: [{ projectNameReal: projectName, projectDisplayName }],
    });
  }

  @autobind
  onConfirmInsightQueryProjectSelect(params) {
    const { templateId, projectName, instanceName, startTimeObj, endTimeObj, keyword } = params;
    const { numOfCluster, pattern, duration } = params;
    const query = {
      t: templateId,
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      keyword,
      numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
      pattern,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      duration,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    this.setState({ showInsightQueryBox: false });
  }

  @autobind
  renderDetectedIncident(category, detectedIncident, location, globalInfo) {
    const { intl, isReadUser, credentials, projects, currentTheme, isJWT } = this.props;

    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    const { summarySettings } = this.state;
    const { timelist, appName, isPrediction, patternName, userName, patternId, rootCause } = detectedIncident;
    const { rawData } = detectedIncident;
    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternName, patternId },
      { hasFullName: true, hasPrefix: true },
    );
    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error);
    }

    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, appName, {
      pn: detectedIncident?.projectName,
      owner: userName,
    });

    const isMetric = Boolean(rootCause) && !rawData;
    let { projectName } = detectedIncident;
    if (userName !== credentials.userName) {
      projectName = `${projectName}@${userName}`;
    }
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);

    const DescriptionTitle = (
      <div className="flex-row">
        <div className="flex-grow hidden-line-with-ellipsis" style={{ paddingRight: 16 }}>
          {category === 'historical'
            ? intl.formatMessage(logMessages.historicalDetectedIncident)
            : intl.formatMessage(logMessages.correspondingDetectedIncident)}
          :
          {!isReadUser && !isJWT && (
            <Button.Group style={{ marginLeft: 8 }}>
              {isMetric && (
                <Tooltip title={intl.formatMessage(eventMessages.lineChart)} mouseEnterDelay={0.3} placement="top">
                  <Button size="small" onClick={() => this.handleIncidentChartClick(detectedIncident)}>
                    <LineChartOutlined />
                  </Button>
                </Tooltip>
              )}
              {!isMetric && (
                <Tooltip
                  title={
                    isAlert || isIncident
                      ? intl.formatMessage(appMenusMessages.alertAnalysis)
                      : intl.formatMessage(appMenusMessages.logAnalysis)
                  }
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <Button size="small" onClick={() => this.handleMatchedIncidentPatternClick(detectedIncident)}>
                    <UnorderedListOutlined />
                  </Button>
                </Tooltip>
              )}
            </Button.Group>
          )}
        </div>
      </div>
    );

    return (
      <Descriptions bordered size="small" title={DescriptionTitle}>
        <Descriptions.Item label={intl.formatMessage(logMessages.type)}>
          {CellRenderers.logTypeRenderer({ intl, rowData: detectedIncident, isPrediction, withPredictionPrefix: true })}
        </Descriptions.Item>
        <Descriptions.Item label={intl.formatMessage(eventMessages.patternName)} span={2}>
          <div style={{ wordBreak: 'break-all' }}>{patternNameStr}</div>
        </Descriptions.Item>

        <Descriptions.Item label={intl.formatMessage(appFieldsMessages.instanceName)}>
          <Tooltip mouseEnterDelay={0.3} placement="top" title={<div>{instanceStr}</div>}>
            <div style={{ wordBreak: 'break-all' }}>{instanceStr}</div>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label={intl.formatMessage(eventMessages.detectionTime)} span={2}>
          {R.addIndex(R.map)(
            (timestamp, index) => (
              <div key={index}>
                {moment.utc(timestamp).format(Defaults.DateTimeFormat)}
                <br />
              </div>
            ),
            timelist,
          )}
        </Descriptions.Item>
        {rootCause?.timePairList && (
          <Descriptions.Item label={intl.formatMessage(appFieldsMessages.duration)} span={3}>
            {(rootCause?.timePairList || [])[0]
              ? CellRenderers.humanizeDuration({
                  period: (rootCause?.timePairList || [])[0].e - (rootCause?.timePairList || [])[0].s + 60000,
                  intl,
                })
              : '-'}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={intl.formatMessage(eventMessages.eventSummary)} span={3}>
          {rawData && (
            <LogRenderers.RenderLogContent
              intl={intl}
              rawData={rawData}
              rawDataJson={rawDataJson}
              owner={detectedIncident}
              summarySettings={summarySettings}
              currentTheme={currentTheme}
            />
          )}
          {rootCause && rootCause.metricName && (
            <div style={{ wordBreak: 'break-word' }}>
              {EventRenderers.RenderMetricAnomalySummary({ intl, event: rootCause })}
            </div>
          )}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  render() {
    const { intl, incident, currentTheme, projects, location, globalInfo, isJWT } = this.props;
    const { loading, historicalIncident, detectedIncident, allIncidentList, summarySettings } = this.state;
    const { detailsActiveIncident } = this.state;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    let predictionSourceInfoList = [];
    if (incident) {
      predictionSourceInfoList = incident.predictionSourceInfoList || [];
      predictionSourceInfoList = R.addIndex(R.map)((item, index) => {
        return { ...item, hop: index + 1 };
      }, predictionSourceInfoList || []);
    }

    return (
      <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-height">
        {!loading && (
          <div className="flex-col full-height">
            {this.incidentListRender()}
            {this.incidentDetailsRender()}
            {!isJWT && (
              <div className="text-right" style={{ marginBottom: 8 }}>
                <Button
                  size="small"
                  type="primary"
                  icon={<LineChartOutlined />}
                  style={{ width: 160 }}
                  onClick={this.handleOverallLineChartClick}
                >
                  {intl.formatMessage(eventMessages.anomalyLineChart)}
                </Button>
              </div>
            )}
            <PredictionFlow
              key={incident?.id}
              intl={intl}
              currentTheme={currentTheme}
              projects={projects || []}
              event={incident}
              globalInfo={globalInfo || {}}
              systemId={systemId}
              detectedIncident={detectedIncident}
              historicalIncident={historicalIncident}
              predictionSourceInfoList={predictionSourceInfoList}
              environmentId={environmentId}
              summarySettings={summarySettings}
              incidentPatternName={incident?.patternName}
              allIncidentList={allIncidentList}
              onLineChartClick={this.handleLineChartClick}
              onLineChartJump={this.handleLineChartJump}
              onPredictionJumpLogClick={this.onPredictionJumpLogClick}
              onPredictionSourceLineChartClick={this.onPredictionSourceLineChartClick}
              onPredictionSourceJumpClick={this.onPredictionSourceJumpClick}
              onPredictionSourceTrendPatternsClick={this.onPredictionSourceTrendPatternsClick}
              onPredictionSourceLogQueryClick={this.onPredictionSourceLogQueryClick}
              renderDetectedIncident={this.renderDetectedIncident}
              isJWT={isJWT}
            />
          </div>
        )}

        {this.state.showProjectSelector && (
          <ProjectSelectorModal
            system={systemInfo}
            incident={detailsActiveIncident || {}}
            onConfirm={this.state.onConfirmProjectSelect}
            onClose={() => this.setState({ showProjectSelector: false })}
          />
        )}

        {this.state.showInsightQueryBox && (
          <InsightQueryBoxModal
            projects={this.state.queryLogProjects}
            queryParams={{}}
            onConfirm={this.onConfirmInsightQueryProjectSelect}
            onClose={() => this.setState({ showInsightQueryBox: false })}
          />
        )}
      </Spin>
    );
  }
}

const PredictionRootCauseRCA = injectIntl(PredictionRootCauseRCACore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { projectDisplayMap = {}, globalInfo = {}, projects } = state.app;
    const { isAdmin, isLocalAdmin, isReadUser, userName } = state?.auth?.userInfo || {};

    const { dark, jwtToken, customerName } = parseLocation(location);
    let credentials = {};
    if (jwtToken) {
      if (dark) {
        state.app.currentTheme = 'dark';
      } else {
        state.app.currentTheme = 'light';
      }
      credentials = { userName: customerName };
    } else {
      credentials = state.auth?.credentials;
    }
    const { currentTheme } = state.app;

    return {
      location,
      projectDisplayMap,
      credentials,
      currentTheme,
      globalInfo,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      userName,
      projects,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(PredictionRootCauseRCA);
