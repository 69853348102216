/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { debounce } from 'lodash';
import { autobind } from 'core-decorators';
import { RightOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Alert,
  TreeSelect,
  Divider,
  Checkbox,
  Form,
  InputNumber,
  message,
  Select,
  Upload,
  Input,
  Card,
} from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { MetricParser } from '../../../../common/utils';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { projectWizardMessages } from '../../../../common/settings/messages';

type Props = {
  intl: Object,
  credentials: Object,
  saveProjectInfo: Function,
  componentState: Object,
};

class GoogleCloudSetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;
    this.state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      isLoadingFile: false,
      configFileList: [],

      privateKeyId: '',
      privateKey: '',
      clientId: '',
      clientEmail: '',
      projectId: '',

      instanceTypeMetricsMap: {},
      instanceTypeList: [],
      preSelectedMetrics: [],
      instanceTypes: [],
      treeData: [],
      treeDataKeys: [],
      selectedMetrics: [],
      treeDataKpi: [],
      treeDataKeysKpi: [],
      kpiMetrics: [],
      samplingInterval: 5,
      samplingUnit: 60,

      ...(componentState || {}),
    };
    this.samplingUnitOption = [
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
    this.onBuildKPIMetricsThrottled = debounce(this.onBuildKPIMetrics, 600);
  }

  @autobind
  beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('File must smaller than 2MB!');
      return false;
    }

    this.setState({ isLoadingFile: true, configFileList: [file] });
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const fileContent = reader.result;
        let state = { isLoadingFile: false };

        // parse fileContent info
        let ret;
        try {
          ret = JSON.parse(fileContent);
        } catch (e) {
          console.debug(fileContent);
        }
        if (ret) {
          const {
            private_key_id: privateKeyId,
            private_key: privateKey,
            client_id: clientId,
            client_email: clientEmail,
            project_id: projectId,
            client_Cert_Url: clientCertUrl,
          } = ret;
          state = { ...state, privateKeyId, privateKey, clientId, clientEmail, projectId, clientCertUrl };
        }

        this.setState(state);
      };
    });
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { credentials } = this.props;
    const { privateKeyId, privateKey, clientId, clientEmail, projectId } = this.state;
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: 'GoogleCloudProject',
      privateKeyId,
      privateKey,
      clientId,
      clientEmail,
      projectId,
    })
      .then((data) => {
        const { success, message: errMsg, metricsList, preSelected } = data;
        if (success) {
          // build instanceType Metrics Map
          let instanceTypeMetricsMap = {};
          R.forEach((val) => {
            R.forEach((item) => {
              const { instanceType, metrics } = item;
              if (!R.has(instanceType, instanceTypeMetricsMap)) instanceTypeMetricsMap[instanceType] = [];

              instanceTypeMetricsMap[instanceType] = [...instanceTypeMetricsMap[instanceType], ...(metrics || [])];
            }, val || []);
          }, metricsList);
          // filter kubernetes metrics
          instanceTypeMetricsMap = R.mapObjIndexed((val, key) => {
            if (key === 'Others') {
              return R.filter((item) => !item.startsWith('kubernetes.io/'), val);
            } else {
              return val;
            }
          }, instanceTypeMetricsMap);
          const instanceTypeList = R.keys(instanceTypeMetricsMap);

          this.setState(
            {
              instanceTypeMetricsMap,
              instanceTypeList,
              preSelectedMetrics: preSelected,
              isVerifying: false,
              verified: true,
              verifiedMessage: '',
            },
            () => {
              this.props.saveProjectInfo('GoogleCloud', {}, this.state);
            },
          );
        } else {
          this.setState(
            {
              instanceTypeMetricsMap: {},
              instanceTypeList: [],
              preSelectedMetrics: [],
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg || 'No metric data is found under the selected instance type.',
            },
            () => {
              this.props.saveProjectInfo('GoogleCloud', {}, this.state);
            },
          );
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState(
          {
            instanceTypeMetricsMap: {},
            instanceTypeList: [],
            preSelectedMetrics: [],
            isVerifying: false,
            verified: false,
            verifiedMessage: errMsg || 'No metric data is found under the selected instance type.',
          },
          () => {
            this.props.saveProjectInfo('GoogleCloud', {}, this.state);
          },
        );
      });
  }

  @autobind
  handleInstanceTypeChange(instanceTypes) {
    this.setState({ instanceTypes }, () => {
      const { instanceTypeMetricsMap, preSelectedMetrics, selectedMetrics } = this.state;
      // build tree data
      let allMetrics = [];
      R.forEachObjIndexed((metrics) => {
        allMetrics = [...allMetrics, ...metrics];
      }, R.pick(instanceTypes, instanceTypeMetricsMap));
      const { treeData, treeDataKeys } = MetricParser.getMetricTreeStructure(allMetrics);

      // set pre selected metrics
      let newMetrics = selectedMetrics;
      R.forEach((val) => {
        const { metrics } = val;
        newMetrics = [...newMetrics, ...(metrics || [])];
      }, preSelectedMetrics);
      newMetrics = R.intersection(newMetrics, treeDataKeys);

      // build select metric tree data
      const { treeData: treeDataKpi, treeDataKeys: treeDataKeysKpi } = MetricParser.getMetricTreeStructure(newMetrics);

      this.setState({
        treeData,
        treeDataKeys,
        selectedMetrics: newMetrics,
        treeDataKpi,
        treeDataKeysKpi,
        kpiMetrics: [],
      });
    });
  }

  @autobind
  handleConfirmClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const {
      privateKeyId,
      privateKey,
      clientId,
      clientEmail,
      projectId,
      samplingInterval,
      samplingUnit,
      selectedMetrics,
      kpiMetrics,
    } = this.state;
    this.props.createProject(
      'GoogleCloud',
      {
        privateKeyId,
        privateKey,
        clientId,
        clientEmail,
        projectId,
        samplingInterval: Number(samplingInterval) * samplingUnit,
        metrics: JSON.stringify(selectedMetrics),
        kpiMetrics: JSON.stringify(kpiMetrics),
      },
      this.state,
    );
  }

  @autobind
  onBuildKPIMetrics(selectedMetrics) {
    // build select metric tree data
    const { treeData: treeDataKpi, treeDataKeys: treeDataKeysKpi } =
      MetricParser.getMetricTreeStructure(selectedMetrics);
    this.setState({ treeDataKpi, treeDataKeysKpi, kpiMetrics: [] });
  }

  render() {
    const { intl, isLoading } = this.props;
    const { isLoadingFile, configFileList } = this.state;
    const { privateKeyId, privateKey, clientId, clientEmail, projectId } = this.state;
    const {
      isVerifying,
      verified,
      verifiedMessage,
      instanceTypeList,
      instanceTypes,
      treeData,
      selectedMetrics,
      treeDataKpi,
      kpiMetrics,
      samplingInterval,
      samplingUnit,
    } = this.state;

    const hasVerifyError = !privateKeyId || !privateKey || !clientId || !clientEmail || !projectId;
    const hasError =
      hasVerifyError ||
      !selectedMetrics ||
      selectedMetrics.length === 0 ||
      !samplingInterval ||
      !samplingUnit ||
      this.props.hasError;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.GoogleCloudIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item label="JSON Key File">
              <Upload
                onRemove={(file) => this.setState({ configFileList: [] })}
                beforeUpload={this.beforeUpload}
                fileList={configFileList}
              >
                <Button>{isLoadingFile ? <LoadingOutlined /> : <UploadOutlined />} Select File</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="Private key Id"
              required
              validateStatus={!privateKeyId ? 'error' : 'success'}
              help={!privateKeyId ? 'This input is required.' : undefined}
            >
              <Input.Password value={privateKeyId} onChange={(e) => this.setState({ privateKeyId: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Private key"
              required
              validateStatus={!privateKey ? 'error' : 'success'}
              help={!privateKey ? 'This input is required.' : undefined}
            >
              <Input.Password value={privateKey} onChange={(e) => this.setState({ privateKey: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Client Id"
              required
              validateStatus={!clientId ? 'error' : 'success'}
              help={!clientId ? 'This input is required.' : undefined}
            >
              <Input.Password value={clientId} onChange={(e) => this.setState({ clientId: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Client email"
              required
              validateStatus={!clientEmail ? 'error' : 'success'}
              help={!clientEmail ? 'This input is required.' : undefined}
            >
              <Input value={clientEmail} onChange={(e) => this.setState({ clientEmail: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Project Id"
              required
              validateStatus={!projectId ? 'error' : 'success'}
              help={!projectId ? 'This input is required.' : undefined}
            >
              <Input value={projectId} onChange={(e) => this.setState({ projectId: e.target.value })} />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>
        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            <Form.Item
              label="Instance Type"
              validateStatus={R.isEmpty(instanceTypes) ? 'error' : 'success'}
              help={R.isEmpty(instanceTypes) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                mode="multiple"
                allowClear
                autoClearSearchValue={false}
                maxTagCount={1}
                value={instanceTypes}
                onChange={this.handleInstanceTypeChange}
                showSearch
                optionFilterProp="key"
                optionLabelProp="key"
                dropdownRender={(menu) => {
                  const selectAll = R.difference(instanceTypeList, instanceTypes).length === 0;
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selectAll}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              this.handleInstanceTypeChange(instanceTypeList);
                            } else {
                              this.handleInstanceTypeChange([]);
                            }
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
              >
                {R.map((item) => {
                  return (
                    <Select.Option className="hide-icon" key={item}>
                      <Checkbox
                        checked={instanceTypes.includes(item)}
                        onChange={(e) => null}
                        style={{ marginRight: 8 }}
                      />
                      {item}
                    </Select.Option>
                  );
                }, instanceTypeList)}
              </Select>
            </Form.Item>
            <Form.Item
              label="Metrics"
              required
              validateStatus={selectedMetrics.length === 0 ? 'error' : 'success'}
              help={selectedMetrics.length === 0 ? 'This input is required.' : undefined}
            >
              <TreeSelect
                style={{ width: '100%' }}
                allowClear
                maxTagCount={1}
                showSearch
                autoClearSearchValue={false}
                treeDefaultExpandAll={false}
                treeCheckable
                treeNodeLabelProp="title"
                treeData={treeData}
                value={selectedMetrics}
                onChange={(selectedMetrics) =>
                  this.setState({ selectedMetrics }, () => {
                    this.onBuildKPIMetricsThrottled(selectedMetrics);
                  })
                }
              />
            </Form.Item>
            <Form.Item label="KPI Metrics">
              <TreeSelect
                style={{ width: '100%' }}
                allowClear
                maxTagCount={1}
                showSearch
                autoClearSearchValue={false}
                treeDefaultExpandAll={false}
                treeCheckable
                treeNodeLabelProp="title"
                treeData={treeDataKpi}
                value={kpiMetrics}
                onChange={(kpiMetrics) => this.setState({ kpiMetrics })}
              />
            </Form.Item>
            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.samplingUnitOption}
                  value={samplingUnit}
                  onChange={(samplingUnit) => this.setState({ samplingUnit })}
                />
              </div>
            </Form.Item>
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasError}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

export default GoogleCloudSetting;
