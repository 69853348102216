/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { Popconfirm, message } from 'antd';
import { DeleteOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';

import fetchPut from '../../../common/apis/fetchPut';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';
import { Container, AutoSizer, Table, Column } from '../../../lib/fui/react';
import { appButtonsMessages, appMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

type Props = {
  intl: Object,
  updateLastActionInfo: Function,
  isLoading: Boolean,
  credentials: Object,
  // eslint-disable-next-line
  causalGroup: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  errorMessage: ?Object,
  causalIncidentList: Object,
  onSelected: Function,
  onReload: Function,
};

class CausalIncidentList extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {};
  }

  UNSAFE_componentWillReceiveProps() {}

  @autobind
  handleIncidentClick({ rowData }) {
    const { onSelected } = this.props;
    if (onSelected) {
      onSelected(rowData);
    }
  }

  @autobind
  controlRenderer({ rowData }) {
    const { intl } = this.props;
    const { lock } = rowData;
    return (
      <div className="flex-row" style={{ justifyContent: 'flex-end' }}>
        <Popconfirm
          placement="topRight"
          title={
            <div>
              {intl.formatMessage(causalMessages.removeRelation)}
              <br />
              {intl.formatMessage(appMessages.continueConfirm)}
            </div>
          }
          okText={intl.formatMessage(appButtonsMessages.yes)}
          cancelText={intl.formatMessage(appButtonsMessages.no)}
          onConfirm={this.handleIncidentRemoveClick(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <DeleteOutlined style={{ fontSize: 16, margin: '0 4px' }} onClick={(event) => event.stopPropagation()} />
        </Popconfirm>
        <Popconfirm
          placement="topRight"
          title={
            lock ? (
              <div>
                {intl.formatMessage(causalMessages.unlockRelation)}
                <br />
                {intl.formatMessage(appMessages.continueConfirm)}
              </div>
            ) : (
              <div>
                {intl.formatMessage(causalMessages.lockRelation)}
                <br />
                {intl.formatMessage(appMessages.continueConfirm)}
              </div>
            )
          }
          okText={intl.formatMessage(appButtonsMessages.yes)}
          cancelText={intl.formatMessage(appButtonsMessages.no)}
          onConfirm={this.handleIncidentLockClick(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          {lock ? (
            <LockOutlined
              style={{ fontSize: 16, margin: '0 4px', color: 'orange' }}
              onClick={(event) => event.stopPropagation()}
            />
          ) : (
            <UnlockOutlined style={{ fontSize: 16, margin: '0 4px' }} onClick={(event) => event.stopPropagation()} />
          )}
        </Popconfirm>
      </div>
    );
  }

  @autobind
  handleIncidentRemoveClick(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();

      const { intl, credentials, onReload } = this.props;
      const { causalKey, relationKey, customerName } = rowData;
      this.props.updateLastActionInfo();
      fetchDelete(
        getEndpoint('eventsrelationprocess'),
        {
          ...credentials,
          relationKey,
          causalKey,
          customerName,
        },
        {},
        false,
      )
        .then(() => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          onReload();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    };
  }

  @autobind
  handleIncidentLockClick(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();

      const { intl, credentials, onReload } = this.props;
      const { lock, relationKey } = rowData;
      this.props.updateLastActionInfo();
      fetchPut(getEndpoint('eventsrelationprocess'), { ...credentials, relationKey, lock: !lock }, {}, false)
        .then(() => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          onReload();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    };
  }

  @autobind
  utcTimeRenderer({ cellData }) {
    const time = moment.utc(cellData).format(Defaults.TimeFormat);
    return <div>{time}</div>;
  }

  render() {
    const { intl } = this.props;
    const { isLoading, causalIncidentList } = this.props;

    return (
      <Container className={`${isLoading ? 'loading ' : ''} flex-grow overflow-y-auto`}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              width={width}
              height={height}
              headerHeight={40}
              rowHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowCount={causalIncidentList.length}
              rowGetter={({ index }) => causalIncidentList[index]}
              onRowClick={this.handleIncidentClick}
            >
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.relationStartTime)}
                dataKey="startTimestamp"
                cellRenderer={this.utcTimeRenderer}
              />
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.relationEndTime)}
                dataKey="endTimestamp"
                cellRenderer={this.utcTimeRenderer}
              />
              <Column width={120} label={intl.formatMessage(causalMessages.status)} dataKey="status" />
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.requestTime)}
                dataKey="requestTime"
                cellRenderer={this.utcTimeRenderer}
              />
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.finishTime)}
                dataKey="finishTime"
                cellRenderer={this.utcTimeRenderer}
              />
              <Column width={80} label="" cellRenderer={this.controlRenderer} dataKey="id" flexGrow={1} />
            </Table>
          )}
        </AutoSizer>
      </Container>
    );
  }
}

export default CausalIncidentList;
