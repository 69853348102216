/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { autobind } from 'core-decorators';
import { RightOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Input, Select, Card } from 'antd';
import * as R from 'ramda';
import momenttz from 'moment-timezone';

import { CodeMirror } from '../../../../lib/fui/react';

import { projectWizardMessages, settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  componentState: Object,
  defaultTimezone: String,
};

class JDBCSetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl } = props;
    const { componentState, defaultTimezone } = props;
    this.state = {
      connectionString: '',
      dbUserName: '',
      dbPassword: '',
      timezone: defaultTimezone,

      dataType: 'Metric',
      samplingInterval: 5,
      samplingUnit: 60,
      collectionInterval: 5,
      collectionUnit: 60,
      verified: true,

      ...(componentState || {}),
    };
    this.dataTypeOptions = [
      { label: intl.formatMessage(settingsMessages.Metric), value: 'Metric' },
      { label: intl.formatMessage(settingsMessages.Log), value: 'Log' },
      { label: intl.formatMessage(settingsMessages.Alert), value: 'Alert' },
      { label: intl.formatMessage(settingsMessages.Incident), value: 'Incident' },
    ];
    this.samplingUnitOptions = [
      // { label: 'second', value: 1 },
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
  }

  componentDidMount() {
    this.props.saveProjectInfo('JDBC', {}, this.state);
  }

  @autobind
  handleDataTypeChange(dataType) {
    let samplingInterval = 5;
    let collectionInterval = 5;
    if (dataType !== 'Metric') {
      samplingInterval = 10;
      collectionInterval = 10;
    }
    this.setState({ samplingInterval, collectionInterval }, () => {
      this.props.saveProjectInfo('JDBC', { dataType }, this.state);
    });
  }

  @autobind
  handleConfirmClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const {
      connectionString,
      dbUserName,
      dbPassword,
      timezone,
      dataType,
      samplingInterval,
      samplingUnit,
      collectionInterval,
      collectionUnit,
    } = this.state;
    this.props.createProject(
      'JDBC',
      {
        connectionString,
        dbUserName,
        dbPassword,
        timezone,
        dataType,
        samplingInterval: Number(samplingInterval) * samplingUnit,
        collectionInterval: Number(collectionInterval) * collectionUnit,
      },
      this.state,
    );
  }

  render() {
    const { intl, isLoading, currentTheme } = this.props;
    const {
      connectionString,
      dbUserName,
      dbPassword,
      timezone,
      dataType,
      samplingInterval,
      samplingUnit,
      collectionInterval,
      collectionUnit,
    } = this.state;

    const hasError =
      !connectionString ||
      !dataType ||
      !samplingInterval ||
      !samplingUnit ||
      !collectionInterval ||
      !collectionUnit ||
      !timezone ||
      this.props.hasError;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.JDBCIntro),
          }}
        />

        <Card>
          <Form layout="vertical">
            <Form.Item
              label="Database Connection"
              validateStatus={!connectionString ? 'error' : 'success'}
              help={!connectionString ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <CodeMirror
                options={{ lineNumbers: true, mode: 'sql', theme: currentTheme === 'dark' ? 'material' : 'xq-light' }}
                value={connectionString}
                onBeforeChange={(editor, data, value) => this.setState({ connectionString: value })}
              />
            </Form.Item>
            <Form.Item label="Database User">
              <Input value={dbUserName} onChange={(e) => this.setState({ dbUserName: e.target.value })} />
            </Form.Item>
            <Form.Item label="Database Password">
              <Input.Password
                autoComplete="new-password"
                value={dbPassword}
                onChange={(e) => this.setState({ dbPassword: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage(appFieldsMessages.defaultTimezone)}
              validateStatus={!timezone ? 'error' : 'success'}
              help={!timezone ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                className="tour-timezone"
                showSearch
                style={{ width: 200 }}
                filterOption
                options={this.timezoneOptions}
                value={timezone}
                onChange={(timezone) => this.setState({ timezone })}
              />
            </Form.Item>

            <Form.Item
              label="Data type"
              validateStatus={!dataType ? 'error' : 'success'}
              help={!dataType ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                options={this.dataTypeOptions}
                value={dataType}
                onChange={(dataType) => {
                  this.setState({ dataType }, () => {
                    this.handleDataTypeChange(dataType);
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.samplingUnitOptions}
                  value={samplingUnit}
                  onChange={(samplingUnit) => this.setState({ samplingUnit })}
                />
              </div>
            </Form.Item>

            <Form.Item
              label="Collection Interval"
              validateStatus={!collectionInterval ? 'error' : 'success'}
              help={!collectionInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-row flex-center-align">
                <InputNumber
                  min={1}
                  style={{ width: 200 }}
                  value={collectionInterval}
                  onChange={(collectionInterval) => this.setState({ collectionInterval })}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.samplingUnitOptions}
                  value={collectionUnit}
                  onChange={(collectionUnit) => this.setState({ collectionUnit })}
                />
              </div>
            </Form.Item>
          </Form>
        </Card>

        <div style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}>
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasError}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

export default JDBCSetting;
