/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import moment from 'moment';
import * as R from 'ramda';
import { get } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';
import { parseJSON } from '../utils';

const getMetaDataNew = (credentials: Credentials, params: Object) => {
  const { startTime, endTime, systemId, environmentId, anomalyInstanceOnly, userName, systemInfoMap } = params;
  const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
  const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
  const system = {
    systemName: systemId,
    environmentName: environmentId,
  };
  const customerName = get(systemInfoMap, [systemId, 'owner']);

  return fetchGet(getEndpoint('info/metadataAggregate', 2), {
    ...credentials,
    startTime: startTimestamp,
    endTime: endTimestamp,
    customerName,
    systemName: systemId,
    anomalyInstanceOnly,
  }).then((data) => {
    const { aggregatedResult, dailyResults } = data || {};
    const { globalViewComponentInfoResult, globalViewInstanceInfoResult, instanceInfoIndex } = aggregatedResult || {};
    const instanceInfoMap = R.invertObj(instanceInfoIndex?.instanceInfoMapping || {});

    let components = {};
    let instances = {};
    let tabCounter = {};
    const instanceDisplayNameMap = {};
    const containerInstanceMap = {};
    const allTabInfoDailyMap = {};
    const globaInstaceMappingProject = {};

    const componentsByDay = globalViewComponentInfoResult?.globViewComponentLevelInfoMap || {};
    const instancesByDay = globalViewInstanceInfoResult?.g || {};
    const projectInfo = globalViewInstanceInfoResult?.p?.projectInfoList || [];
    const componentsMapping = {};
    const instancesMapping = {};

    components = R.mapObjIndexed((component, key) => {
      const { iil, i } = component || {};
      const instanceIdSet = R.map((iidx) => instanceInfoMap[iidx], iil || []);
      componentsMapping[i] = { instanceIdSet, index: i, key };
      return { id: key, name: key, environmentName: environmentId, systemId, instanceList: instanceIdSet };
    }, componentsByDay);

    if (!globaInstaceMappingProject[systemId]) globaInstaceMappingProject[systemId] = {};
    instances = R.mapObjIndexed((instance, key) => {
      const { ip, na, nae, h } = instance || {};
      const { dc, cm, id, idn } = instance || {};
      const containerMaping = {};

      const instanceProperty = {
        instanceInfoList: R.map(
          (item) => ({
            instanceName: item.in || instanceInfoMap[item.ini] || key,
            componentName: item.cn || item.in || instanceInfoMap[item.ini] || key,
            content: item.c,
            isMetric: item.im,
            logInstanceName: item.li,
            needFetchAnomaly: item.nfa,
            instanceDisplayName: item.idn,
            grouping: item.g,
            parentName: item.pn,
            licenseKey: item.lk,
            isContainer: item.ic,
            zone: item.z || '',
            i: item.i,
            ...(projectInfo[item.i] || {}),
          }),
          ip.i || [],
        ),
        lastMetricDataReceiveTimestamp: ip.l,
      };
      const containerMap = R.mapObjIndexed(
        (item) => ({
          containerId: item.c,
          hasData: item.h,
          numberOfAnomaly: item.na,
          numberOfAnomalyExcludeIgnore: item.nae,
          lastMetricDataReceiveTimestamp: item.l,
          possibleInstanceNameSet: item.pi,
          possibleProjectNameSet: item.pp,
          componentName: item.cn,
          index: item.i,
          dailyChunkMap: item.dc,
        }),
        cm || {},
      );

      instanceDisplayNameMap[key] = idn;

      R.forEach((ins) => {
        const { instanceName, projectName, userName } = ins;
        const newIns = { ...ins, cms: R.keys(cm || {}) };
        if (!globaInstaceMappingProject[systemId][instanceName]) {
          globaInstaceMappingProject[systemId][instanceName] = [newIns];
        } else {
          globaInstaceMappingProject[systemId][instanceName].push(newIns);
        }
        instanceDisplayNameMap[`${projectName}-${userName}-${key}`] = idn;
      }, instanceProperty?.instanceInfoList || []);

      if (!R.isEmpty(containerMap)) {
        R.forEachObjIndexed((containerVal, containerKey) => {
          const ckey = `${containerKey}_${key}`;
          if (!R.has(ckey, containerInstanceMap)) {
            containerInstanceMap[ckey] = key;
          }
          containerMaping[containerVal.index] = { ...(containerVal || {}), key: containerKey };
        }, containerMap);
      }

      const chunkList = R.pipe(R.values, R.flatten, R.uniq)(dc || {});

      instancesMapping[id] = {
        instanceId: instance?.i || key,
        instanceProperty,
        numberOfAnomaly: na,
        numberOfAnomalyExcludeIgnore: nae,
        dailyChunkMap: dc,
        hasIncident: h,
        containerMap,
        allPossibleInstanceName: instance?.apin,
        index: id,
        instanceDisplayName: idn,
        key,
        containerMaping,
      };

      return {
        id: key,
        name: key,
        environmentName: environmentId,
        systemId,
        instanceProperty,
        containerList: containerMap,
        numberOfAnomaly: na,
        numberOfAnomalyExcludeIgnore: nae,
        hasIncident: h,
        chunkList,
        dailyChunkMap: dc,
      };
    }, instancesByDay);

    R.forEach((tabResults) => {
      const { globalViewBasicInfo } = tabResults || {};
      const { pastStartTime, tabCounterSet } = globalViewBasicInfo || {};
      const tabCounterByDay = parseJSON(tabCounterSet || '[]');

      const timeStr = moment.utc(pastStartTime).format(Defaults.DateShortFormat);
      const handleTabCounter = R.map((tab) => {
        const { componentLevelCountSet, instanceLevelCountSet } = tab || {};
        const newComponentLevelCountSet = [];
        R.forEach((item) => {
          if (componentsMapping[item.id])
            newComponentLevelCountSet.push({ ...item, c: componentsMapping[item.id]?.key });
        }, componentLevelCountSet || []);

        const newInstanceLevelCountSet = [];
        R.forEach((item) => {
          const { id, cid } = item || {};
          if (instancesMapping[id]) {
            const { key, dailyChunkMap, containerMaping } = instancesMapping[id] || {};
            const isPod = R.values(containerMaping || {}).length > 0;
            const containerData = (containerMaping || {})[cid];
            let i = key;
            let cidc;
            if (containerData) {
              i = `${containerData.key}_${key}`;
              cidc = (containerData.dailyChunkMap || {})[timeStr];
            }
            const c = (dailyChunkMap || {})[timeStr];
            newInstanceLevelCountSet.push({
              ...item,
              i,
              isPod,
              ...(R.isNil(c) ? {} : { c }),
              ...(R.isNil(cidc) ? {} : { cidc }),
            });
          }
        }, instanceLevelCountSet || []);
        return {
          ...tab,
          componentLevelCountSet: newComponentLevelCountSet,
          instanceLevelCountSet: newInstanceLevelCountSet,
        };
      }, tabCounterByDay || []);
      allTabInfoDailyMap[pastStartTime] = R.clone(handleTabCounter);

      R.forEach((item) => {
        const { tabName } = item;
        if (!R.has(tabName, tabCounter)) {
          tabCounter[tabName] = item;
        } else {
          const { componentLevelCountSet, instanceLevelCountSet, systemLevelCount } = tabCounter[tabName];
          tabCounter[tabName].componentLevelCountSet = R.concat(componentLevelCountSet, item.componentLevelCountSet);
          tabCounter[tabName].instanceLevelCountSet = R.concat(instanceLevelCountSet, item.instanceLevelCountSet);
          tabCounter[tabName].systemLevelCount = {
            ...tabCounter[tabName].systemLevelCount,
            u: systemLevelCount.u + item.systemLevelCount.u,
            t: systemLevelCount.t + item.systemLevelCount.t,
          };
        }
      }, handleTabCounter || []);
    }, dailyResults || []);

    tabCounter = R.mapObjIndexed((tabItem, key) => {
      const { componentLevelCountSet, instanceLevelCountSet, systemLevelCount } = tabItem;
      const componentMap = {};
      const instanceMap = {};
      R.forEach((item) => {
        const { c, u, t } = item;
        if (!R.has(c, componentMap)) {
          componentMap[c] = { u, t };
        } else {
          componentMap[c].u += u;
          componentMap[c].t += t;
        }
      }, componentLevelCountSet);

      R.forEach((item) => {
        const { i, u, t, cid, id, isPod, c } = item;
        if (!R.has(i, instanceMap)) {
          instanceMap[i] = { u, t, id, isPod, ...(R.isNil(cid) ? {} : { cid }), c: c || [] };
        } else {
          instanceMap[i].c = R.uniq([...instanceMap[i].c, ...(c || [])]);
          instanceMap[i].u += u;
          instanceMap[i].t += t;
        }
      }, instanceLevelCountSet);

      const newComponentLevelCountSet = [];
      let newInstanceLevelCountSet = [];
      R.forEachObjIndexed((comItem, comKey) => {
        newComponentLevelCountSet.push({ c: comKey, ...comItem });
      }, componentMap);

      const containers = [];
      const pods = [];
      R.forEachObjIndexed((insItem, insKey) => {
        const { cid, isPod } = insItem;
        const isContainer = !R.isNil(cid);
        if (isContainer) {
          const [c, i] = R.split('_', insKey);
          const logicPodID = instanceMap[i] || { u: 0, t: 0, c: [] };
          const containerAndPod = instanceMap[insKey] || { u: 0, t: 0, c: [] };
          newInstanceLevelCountSet.push({ i, ...logicPodID });
          pods.push({ p: i, ...logicPodID });
          containers.push({ ct: c, containerAndPod: insKey, ...containerAndPod });
        } else if (isPod) {
          pods.push({ p: insKey, ...insItem });
          newInstanceLevelCountSet.push({ i: insKey, ...insItem });
        } else {
          newInstanceLevelCountSet.push({ i: insKey, ...insItem });
        }
      }, instanceMap);
      newInstanceLevelCountSet = R.uniqBy((item) => item.i, newInstanceLevelCountSet);

      return {
        systemLevelCount,
        tabName: key,
        componentLevelCountSet: newComponentLevelCountSet,
        instanceLevelCountSet: newInstanceLevelCountSet,
        containers,
        pods,
      };
    }, tabCounter);

    return {
      components,
      instances,
      tabCounter,
      containerInstanceMap,
      allTabInfoDailyMap,
      globaInstaceMappingProject,
      instanceDisplayNameMap,
    };
  });
};

export default getMetaDataNew;
