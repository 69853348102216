/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import React from 'react';
import * as R from 'ramda';
import { get, has, isObject, isEmpty } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import numeral from 'numeral';
import * as d3 from 'd3';
import dagre from 'dagre';

import { CausalParser } from '../../../common/utils';
import { Container, AutoSizer } from '../../../lib/fui/react';
import { loadCausalIncident, resetCausalIncidentData } from '../../../common/causal/actions';
import dagreD3 from '../../../../components/ui/dagre-d3';

const d3tip = require('d3-tip');

type Props = {
  hasLogProject: Boolean,
  hasMetricProject: Boolean,
  instanceName: String,
  causalInstanceIncident: Object,
  kpiPredictionProbability: String,
  incidentParams: Object,
  causalIncidentInfo: Object,
  onInstanceChange: Function,

  intl: Object,
  match: Object,
  location: Object,
  push: Function,
  replace: Function,
  loadCausalIncident: Function,
  resetCausalIncidentData: Function,
  currentLoadingComponents: Object,

  causalIncidentProperty: Object,
  causalIncident: Object,
  causalInstanceIncident: Object,
};

class KPIPredictionCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.container = null;
    this.defaultZoom = 7;
    this.incidentLoader = 'causal_incident_correlation_loader';

    this.instanceMapping = {};
    this.intraInstanceList = [];

    this.state = {
      currentZoom: this.defaultZoom,

      relationList: [],
    };
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextIncidentParams = nextProps.incidentParams || {};
    const incidentParams = this.props.incidentParams || {};
    if (
      nextIncidentParams.causalKey !== incidentParams.causalKey ||
      nextIncidentParams.causalName !== incidentParams.causalName ||
      nextIncidentParams.customerName !== incidentParams.customerName ||
      nextIncidentParams.startTimestamp !== incidentParams.startTimestamp ||
      nextIncidentParams.endTimestamp !== incidentParams.endTimestamp ||
      nextIncidentParams.fileName !== incidentParams.fileName ||
      nextIncidentParams.joinDependency !== incidentParams.joinDependency ||
      nextProps.instanceName !== this.props.instanceName
    ) {
      this.reloadData(nextProps);
    } else if (
      nextProps.causalIncident !== this.props.causalIncident ||
      nextProps.causalInstanceIncident !== this.props.causalInstanceIncident
    ) {
      this.renderChart(nextProps);
    } else if (nextProps.kpiPredictionProbability !== this.props.kpiPredictionProbability) {
      this.renderChart(nextProps);
    }
  }

  UNSAFE_componentWillMount() {
    this.clearChart();
  }

  @autobind
  reloadData(props, force = false) {
    const { loadCausalIncident, incidentParams, instanceName } = props;
    const {
      causalKey,
      relationKey,
      customerName,
      causalName,
      startTimestamp,
      endTimestamp,
      fileName,
      joinDependency,
    } = incidentParams;
    const causalType = 'KPIPrediction';
    let postFixStr = 'inter';
    if (instanceName) {
      postFixStr = `intra_${instanceName}`;
    }
    const postFix = `_${postFixStr}_${causalType}`;
    loadCausalIncident(
      {
        causalType,
        causalKey,
        relationKey,
        customerName,
        causalName,
        instanceName,
        startTimestamp,
        endTimestamp,
        fileName,
        postFix,
        joinDependency,
      },
      force,
      { [this.incidentLoader]: true },
    );
  }

  @autobind
  getChartData(props) {
    const { causalIncident, causalInstanceIncident, instanceName } = props;
    const { kpiPredictionProbability } = props;

    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;
    const relationMap = get(incident, ['kpiPrediction'], {});
    const metricUnits = get(incident, ['metricUnitMap'], {});
    const relationList = [];

    R.forEachObjIndexed((sval, servers) => {
      if (isObject(sval)) {
        const snames = servers.split(',');
        const lname = snames[0];
        const rname = snames[1];
        const llabels = {};
        let rlables = [];

        R.forEachObjIndexed((mval, metrics) => {
          const { kpiData, kpiAttrribution } = mval;
          if (kpiData && has(kpiData, kpiPredictionProbability)) {
            const mnames = metrics.split(',');
            const metric = mnames[0];
            const mergedMetric = metric;
            const kpi = mnames[1];
            llabels[kpi] = llabels[kpi] || {};
            let kpiValue = numeral(kpiData[kpiPredictionProbability]).format('0.[00]');
            if (isNaN(kpiValue)) {
              kpiValue = numeral(kpiData[kpiPredictionProbability]).format('0.00e+0');
            }
            const value = `${kpiValue}${metricUnits[metric] ? ` (${metricUnits[metric]})` : ''}`;
            if (get(llabels, [kpi, mergedMetric])) {
              llabels[kpi][mergedMetric].push({ value, kpiAttrribution });
            } else {
              llabels[kpi][mergedMetric] = [{ value, kpiAttrribution }];
            }
            rlables.push(kpi);
          }
        }, sval);
        rlables = R.uniq(rlables);
        // For the kpi predictions, the right label is always kpi metric.
        if (rlables.length > 0) {
          R.forEach((kpi) => {
            const kpiLeftLabels = llabels[kpi];
            if (R.keys(llabels).length > 0) {
              relationList.push({
                left: lname,
                right: `${rname}[${kpi}]`,
                rightInstance: rname,
                kpi,
                label: '',
                leftLabel: kpiLeftLabels,
                rightLabel: `${rname}(${kpi})`,
              });
            }
          }, rlables);
        }
      }
    }, relationMap);

    return { relationList };
  }

  clearChart() {
    if (this.tip) {
      this.tip.destroy();
    }

    if (this.container) {
      d3.select(this.container)
        .select('svg')
        .remove();
    }
  }

  @autobind
  getAppName(instancePropertyMap, instanceName) {
    let iName = instanceName;
    if (instanceName) {
      let metricName = '';
      if (instanceName.indexOf('[') >= 0) {
        [iName, metricName] = iName.split('[');
      }
      const instanceProperty = get(instancePropertyMap, iName, {});
      let appName = get(instanceProperty, ['projectInstanceMetadata', 'componentName']) || iName;
      if (instanceName.indexOf('[') >= 0) {
        appName = `${appName}[${metricName}`;
      }
      return appName;
    }
    return iName;
  }

  @autobind
  getWithAppName(instancePropertyMap, instanceName) {
    const appName = this.getAppName(instancePropertyMap, instanceName);
    return appName === instanceName ? appName : `${appName}(${instanceName})`;
  }

  renderChart(props) {
    this.clearChart();

    if (!this.container) return;

    // get data
    const { relationList } = this.getChartData(props);
    this.setState({ relationList });

    const { causalIncidentProperty, causalIncident, causalInstanceIncident, instanceName } = props;
    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;
    const kpiPrediction = get(incident, ['kpiPrediction'], {});
    const kpiThresholdMap = get(incident, ['kpiThresholdMap'], {});
    const metricUnitMap = get(incident, ['metricUnitMap'], {});
    const { instancePropertyMap } = causalIncidentProperty || {};

    const { currentZoom } = this.state;

    const g = new dagre.graphlib.Graph({
      directed: true,
      multigraph: true,
    });

    g.setGraph({
      rankdir: 'LR',
      align: 'DR',
      ranker: 'tight-tree',
      ranksep: 5 + currentZoom * 50,
      nodesep: 5 + currentZoom * 20,
      edgesep: 5 + currentZoom * 10,
      marginx: 10,
      marginy: 10,
    });

    // Get unique left & right side of the relations as the node name.
    const nodeNames = R.uniq(
      R.concat(
        R.map((relation) => relation.left, relationList),
        R.map((relation) => relation.right, relationList),
      ),
    );
    R.forEach((id) => {
      const name = this.getAppName(instancePropertyMap, id);
      const label = CausalParser.trimString(name, 26);
      g.setNode(id, {
        id: CausalParser.parseSvgSelector(id),
        title: name,
        shape: 'circle',
        type: 'Instance',
        label,
        name,
        width: -8,
        height: -8,
      });
    }, nodeNames);

    // Add lines
    const arrowhead = 'vee';
    R.addIndex(R.forEach)((rel, idx) => {
      const { left, right } = rel;
      const meta = {
        id: `${CausalParser.parseSvgSelector(left)}-${CausalParser.parseSvgSelector(right)}`,
        relation: rel,
        label: `[${idx + 1}]`,
        lineInterpolate: 'monotone',
        arrowhead,
        labelpos: 'l',
        labeloffset: 5,
      };
      g.setEdge(left, right, meta, 'kpiPrediction');
    }, relationList);

    const svg = d3.select(this.container).append('svg');
    const inner = svg.append('g');
    const render = dagreD3.render();
    render(inner, g);

    // add d3 tooltip
    this.tip = d3tip()
      .attr('class', 'd3-tip')
      .direction('e')
      .offset([0, 10])
      .html(({ d, n, e, g }) => {
        // node
        if (n) {
          const { name, type } = n;
          if (type === 'Instance') {
            return `<div style="width:280px;word-break: break-word;">Instance Name <b>${name}</b></div>`;
          }
        } else if (e) {
          const kpiByInstance = {};
          R.forEachObjIndexed((val, key) => {
            const inst = key.split(',')[1];
            R.forEachObjIndexed((mval, mkey) => {
              const kpi = mkey.split(',')[1];
              kpiByInstance[inst] = R.uniq(R.filter((x) => Boolean(x), [...kpiByInstance[inst], kpi]));
            }, val || {});
          }, kpiPrediction);
          const inst = get(e, ['relation', 'right'], '');
          const kpiList = kpiByInstance[inst] || [];

          const leftLabel = get(e, ['relation', 'leftLabel'], {});
          const leftLabels = [];
          R.forEachObjIndexed((val, key) => {
            R.forEach((v) => {
              leftLabels.push(
                `<div class="flex-row">` +
                  `<div>${key}</div>` +
                  `<div style="padding: 0 4px;">${'>='}</div>` +
                  `<div>${v.value}</div>` +
                  `<div class="flex-grow" style="min-width: 40px;"></div>` +
                  `<div>${(v.kpiAttrribution * 100).toFixed(2)}%</div>` +
                  `</div>`,
              );
            }, val);
          }, leftLabel);
          let kpiContent = '';
          R.forEach((kpi) => {
            kpiContent += `<div class="flex-row" style="padding-left: 16px;line-height: 18px;">${kpi} >= ${
              kpiThresholdMap[kpi]
            } ${metricUnitMap[kpi] ? metricUnitMap[kpi] : ''}</div>`;
          }, kpiList);

          const content =
            `<div style="font-size: 13px">` +
            `<div class="flex-row">KPI Anomalies on ${this.getWithAppName(
              instancePropertyMap,
              get(e, ['relation', 'rightInstance'], ''),
            ) + `[${get(e, ['relation', 'kpi'], '')}]`}:</div>` +
            kpiContent +
            `<div class="flex-row">Root Cause Suspects on ${this.getWithAppName(
              instancePropertyMap,
              get(e, ['relation', 'left'], ''),
            )}:</div>` +
            `<div class="flex-grow flex-row" style="justify-content: center"></div>` +
            '</div>' +
            `<div class="label" style="border-top:1px solid #ccc;">` +
            `${R.join('', leftLabels)}</div>`;
          return content;
        }
        return d;
      });
    const tip = this.tip;
    svg.call(tip);

    // The callback cannot use lambda, since d3tip needs 'this'.
    svg
      .selectAll('.node')
      .on('mouseover', function(d) {
        const n = g.node(d);
        if (n) {
          const edges = g.nodeEdges(d) || [];
          edges.forEach((ed) => {
            const e = g.edge(ed);
            const { id } = e;
            svg.select(`path#${id}`).classed('hover', true);
          });
          tip.show({ d, n, g }, this);
        }
      })
      .on('mouseout', function(d) {
        const n = g.node(d);
        if (n) {
          const edges = g.nodeEdges(d) || [];
          edges.forEach((ed) => {
            const e = g.edge(ed);
            const { id } = e;
            svg.select(`path#${id}`).classed('hover', false);
          });
          tip.hide(n, this);
        }
      });
    svg
      .selectAll('.edgeLabel')
      .on('mouseover', function(d) {
        const e = g.edge(d);
        if (e) {
          const { id } = e;
          svg.select(`path#${id}`).classed('hover', true);
          tip.show({ d, e, g }, this);
        }
      })
      .on('mouseout', function(d) {
        const e = g.edge(d);
        if (e) {
          const { id } = e;
          svg.select(`path#${id}`).classed('hover', false);
          tip.hide(e, this);
        }
      });

    const gbox = inner.node().getBBox();
    const width = gbox.width;
    const height = gbox.height + 40;

    svg.attr({
      width,
      height,
      viewBox: `${gbox.x} ${gbox.y} ${width} ${height}`,
    });
  }

  @autobind
  handleZoomInClick() {
    let { currentZoom } = this.state;
    currentZoom += 1;
    this.setState({ currentZoom }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  handleZoomOutClick() {
    let { currentZoom } = this.state;
    currentZoom = Math.max(currentZoom - 1, 1);
    this.setState({ currentZoom }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  handleNodeChange() {
    this.cellMeasureCache.clear();
    this.setState({ currentZoom: this.defaultZoom }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  handleInstanceIdClick(id) {
    return () => {
      this.setState({ filterNodes: null }, () => {
        this.props.onInstanceChange(id);
      });
    };
  }

  @autobind
  handleClearInstance() {
    this.setState({ filterNodes: null }, () => {
      this.props.onInstanceChange(null);
    });
  }

  render() {
    const {
      currentLoadingComponents,
      causalIncidentProperty,
      causalIncident,
      causalInstanceIncident,
      instanceName,
    } = this.props;

    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;
    const { relationList } = this.state;
    const kpiThresholdMap = get(incident, ['kpiThresholdMap'], {});
    const kpiPrediction = get(incident, ['kpiPrediction'], {});
    const { instancePropertyMap } = causalIncidentProperty || {};

    const metricUnitMap = get(incident, ['metricUnitMap'], {});
    const hasRelation = !isEmpty(kpiPrediction);
    const instanceList = get(causalIncident, 'instanceList', []);
    const appName = this.getWithAppName(instancePropertyMap, instanceName);

    // key: ip-172-31-18-84,ip-172-31-20-161
    // val: AppSvrRespTime,TotalTime
    const kpiByInstance = {};
    R.forEachObjIndexed((val, key) => {
      const inst = key.split(',')[1];
      R.forEachObjIndexed((mval, mkey) => {
        const kpi = mkey.split(',')[1];
        kpiByInstance[inst] = R.uniq(R.filter((x) => Boolean(x), [...kpiByInstance[inst], kpi]));
      }, val || {});
    }, kpiPrediction);

    const isIncidentLoading = get(currentLoadingComponents, this.incidentLoader, false);
    return (
      <Container className={`flex-grow flex-row ${isIncidentLoading ? 'loading' : ''}`}>
        {!hasRelation && (
          <Container className="chart message flex-grow">
            {!hasInstance && (
              <div className="ui mini warning message" style={{ wordBreak: 'break-all' }}>
                No KPI Prediction found for this time period,
                {instanceList.length > 0 && <span>Or view the KPI Prediction for instance:</span>}
                {instanceList.length > 0 &&
                  R.map(
                    (i) => (
                      <span className="link" key={i} onClick={this.handleInstanceIdClick(i)}>
                        {this.getWithAppName(instancePropertyMap, i)}
                      </span>
                    ),
                    instanceList,
                  )}
              </div>
            )}
            {hasInstance && (
              <div className="ui mini warning message" style={{ wordBreak: 'break-all' }}>
                No KPI Prediction found for&nbsp;
                <span className="instance">{appName}</span>
                ,&nbsp;
                <span className="link" onClick={this.handleClearInstance}>
                  back to instance KPI Prediction
                </span>
              </div>
            )}
          </Container>
        )}
        <Container className={`${hasRelation ? '' : 'invisible '}chart flex-col flex-grow`}>
          <div className="toolbar">
            {!hasInstance && (
              <div className="title" style={{ textAlign: 'center' }}>
                <h4>KPI Prediction between Instances</h4>
              </div>
            )}
            {hasInstance && (
              <div className="title">
                <h4>
                  <Popover
                    title={null}
                    content={'Back to instances KPI Prediction'}
                    placement="right"
                    mouseEnterDelay={0.3}
                  >
                    <i className="arrow left icon" onClick={this.handleClearInstance} />
                  </Popover>
                  KPI Prediction for instance:
                  <span className="instance">
                    <i className="circle icon" />
                    {appName}
                  </span>
                </h4>
              </div>
            )}
          </div>
          <div className="flex-grow flex-row">
            <Container
              className={`flex-col ${hasRelation ? '' : 'invisible chart'}`}
              style={{ width: 400, paddingLeft: 12, wordBreak: 'break-word', borderRight: '1px solid #eee' }}
            >
              <div className="overflow-y-auto flex-grow">
                {R.map((inst) => {
                  const kpiList = kpiByInstance[inst];
                  if (kpiList.length === 0) return null;
                  const relations = [];
                  R.addIndex(R.forEach)((val, index) => {
                    if (val.rightInstance === inst) {
                      relations.push({ ...val, index });
                    }
                  }, relationList);
                  return (
                    <div key={inst}>
                      {hasInstance && <h4 style={{ marginBottom: 4 }}>KPI Anomalies:</h4>}
                      {!hasInstance && (
                        <h4 style={{ marginBottom: 4, fontSize: 12 }}>
                          {'KPI Anomalies on '}
                          <span style={{ color: '#566f84' }}>{this.getWithAppName(instancePropertyMap, inst)}</span>:
                        </h4>
                      )}
                      {R.map((kpi) => {
                        return (
                          <div key={kpi} style={{ paddingBottom: 4 }}>
                            <div style={{ color: 'blue', fontWeight: 500 }} key={kpi}>{`${kpi} >= ${
                              kpiThresholdMap[kpi]
                            } ${metricUnitMap[kpi] ? `${metricUnitMap[kpi]}` : ''}`}</div>
                            <div style={{ fontSize: 12, fontWeight: 500 }}>Root Cause Suspects:</div>
                            <div style={{ padding: '4px 16px 0 4px' }}>
                              {R.map(
                                (relation) => {
                                  return (
                                    <div className="flex-row" style={{ paddingBottom: 4 }} key={relation.index}>
                                      <div
                                        style={{
                                          color: '#794b02',
                                          textDecoration: 'underline',
                                          fontWeight: 'bold',
                                          fontStyle: 'italic',
                                        }}
                                      >{`[${relation.index + 1}]`}</div>
                                      <div className="flex-grow" style={{ paddingLeft: 4 }}>
                                        {!hasInstance && (
                                          <div>
                                            {'On '}
                                            <span style={{ color: '#566f84' }}>
                                              {this.getWithAppName(instancePropertyMap, relation.left)}
                                            </span>
                                          </div>
                                        )}
                                        <ul style={{ margin: 0, padding: 0, fontSize: 13 }}>
                                          {R.map((metricName) => {
                                            const metric = relation.leftLabel[metricName][0];
                                            return (
                                              <li
                                                style={{
                                                  lineHeight: '20px',
                                                  listStyle: hasInstance ? 'none' : undefined,
                                                }}
                                                key={metricName}
                                              >
                                                <span>{`${metricName} >= ${metric.value}`}</span>
                                                <span style={{ float: 'right' }}>
                                                  {`${(metric.kpiAttrribution * 100).toFixed(2)}%`}
                                                </span>
                                              </li>
                                            );
                                          }, R.keys(relation.leftLabel || {}))}
                                        </ul>
                                      </div>
                                    </div>
                                  );
                                },
                                R.filter((relation) => relation.kpi === kpi, relations),
                              )}
                            </div>
                          </div>
                        );
                      }, kpiList)}
                    </div>
                  );
                }, R.keys(kpiByInstance))}
              </div>
            </Container>
            <Container className="flex-grow overflow-y-auto">
              <div className="zoom">
                <i className="plus icon" onClick={this.handleZoomInClick} />
                <i className="minus icon" onClick={this.handleZoomOutClick} />
              </div>
              <AutoSizer>
                {({ width, height }) => (
                  <div style={{ width, height }}>
                    <div
                      className="d3-container"
                      style={{ textAlign: 'left' }}
                      ref={(c) => {
                        this.container = c;
                      }}
                    />
                  </div>
                )}
              </AutoSizer>
            </Container>
          </div>
        </Container>
      </Container>
    );
  }
}

const KPIPrediction = injectIntl(KPIPredictionCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { userName } = state.auth.userInfo;
    const { currentLoadingComponents, projects } = state.app;
    const { incidentData, instanceIncidentData, incidentCausalProperty } = state.causal;
    return {
      location,
      userName,
      currentLoadingComponents,
      projects,
      causalIncident: get(incidentData, 'data', null),
      causalInstanceIncident: get(instanceIncidentData, 'data', null),
      causalIncidentProperty: incidentCausalProperty || {},
    };
  },
  {
    push,
    replace,
    loadCausalIncident,
    resetCausalIncidentData,
  },
)(KPIPrediction);
