/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React, { useState } from 'react';
// import * as R from 'ramda';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
import { Form, Button, Upload, DatePicker, Alert } from 'antd';

import { downloadFile } from '../../../common/utils';
import { Modal } from '../../../lib/fui/react';

import { appButtonsMessages } from '../../../common/app/messages';

const UploadDependencyModal = ({ intl, onOk, onCancel, isSubmitting, errMsg }: Object) => {
  const [relationFileList, setRelationFileList] = useState([]);
  // const [noRelationFileList, setNoRelationFileList] = useState([]);
  const [dates, setDates] = useState([moment.utc(), moment.utc()]);

  const handleDownloadDependencyExample = () => {
    const fname = `dependency_relations_example.csv`;
    const csvString = 'Source,Target\r\ncomponent-1,component-2\r\ncomponent-1,component-3\r\n';
    downloadFile(csvString, fname);
  };

  const disabled = relationFileList.length === 0;
  return (
    <Modal
      title="Upload Dependency Map"
      width={650}
      visible
      onCancel={onCancel}
      footer={[
        <Button size="small" key="cancel" onClick={onCancel}>
          {intl.formatMessage(appButtonsMessages.cancel)}
        </Button>,
        <Button
          size="small"
          key="submit"
          type="primary"
          loading={isSubmitting}
          disabled={disabled}
          onClick={() => onOk(dates, relationFileList)}
        >
          {intl.formatMessage(appButtonsMessages.submit)}
        </Button>,
      ]}
      maskClosable={false}
    >
      <Alert
        type="info"
        message=""
        description={
          <div className="flex-col">
            <span>The dependency map can be uploaded as a CSV file with a format of</span>
            <code
              className="flex-col"
              style={{
                background: 'aliceblue',
                padding: 4,
              }}
            >
              <span>source1, target11, target12</span>
              <span>source2, target21, target22</span>
            </code>
            <span>
              You can also download{' '}
              <Button size="small" type="link" style={{ padding: 0 }} onClick={handleDownloadDependencyExample}>
                example
              </Button>{' '}
              dependency map and edit the downloaded CSV file directly.
            </span>
          </div>
        }
        banner
        style={{ marginBottom: 16 }}
      />
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
        <Form.Item label="Date Range" required>
          <DatePicker.RangePicker
            allowClear={false}
            style={{ width: 220 }}
            value={dates}
            onChange={(dates) => {
              setDates(dates);
            }}
          />
        </Form.Item>
        <Form.Item label="May impact file" required>
          <Upload
            onRemove={(file) => {
              setRelationFileList([]);
            }}
            beforeUpload={(file) => {
              setRelationFileList([file]);
              return false;
            }}
            fileList={relationFileList}
          >
            <Button size="small">
              <UploadOutlined /> Select File
            </Button>
          </Upload>
        </Form.Item>
        {/* <Form.Item label="May not impact file" required>
          <Upload
            onRemove={(file) => {
              setNoRelationFileList([]);
            }}
            beforeUpload={(file) => {
              setNoRelationFileList([file]);
              return false;
            }}
            fileList={noRelationFileList}
          >
            <Button>
              <UploadOutlined /> Select File
            </Button>
          </Upload>
        </Form.Item> */}
        {errMsg && (
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default UploadDependencyModal;
