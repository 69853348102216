import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import {
  CaretDownOutlined,
  ClusterOutlined,
  CodepenOutlined,
  CodeSandboxOutlined,
  DatabaseOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Checkbox, Input, Select, Spin, Tooltip } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import { AutoSizer, List } from '../../lib/fui/react';
import getInstanceDisplayName from '../../common/utils/getInstanceDisplayName';

const ROW_HEIGHT = 28;

const getEntityIcon = (type) => {
  switch (type) {
    case 'component':
      return (
        <Tooltip title="Component" mouseEnterDelay={0.3} placement="top">
          <ClusterOutlined />
        </Tooltip>
      );
    case 'pod':
      return (
        <Tooltip title="Pod" mouseEnterDelay={0.3} placement="top">
          <CodeSandboxOutlined />
        </Tooltip>
      );
    case 'container':
      return (
        <Tooltip title="Container" mouseEnterDelay={0.3} placement="top">
          <CodepenOutlined />
        </Tooltip>
      );
    case 'instance':
      return (
        <Tooltip title="Instance" mouseEnterDelay={0.3} placement="top">
          <DatabaseOutlined />
        </Tooltip>
      );
    default:
      return null;
  }
};

const BuildEntityList = (
  entityType,
  allEntityTreeMap,
  search = null,
  hasAnomaly = false,
  hasData = false,
  searchType,
  instanceDisplayNameMap = {},
) => {
  const isPod = entityType === 'Pod';
  const allEntityList = [];
  const entityList = [];
  const allRealEntityList = [];
  const searchTypeLower = searchType?.toLowerCase();
  let searchTypeLevel = 0;
  if (searchTypeLower === 'component') {
    searchTypeLevel = 0;
  } else if (searchTypeLower === 'pod' || searchTypeLower === 'instance') {
    searchTypeLevel = 1;
  } else if (searchTypeLower === 'container') {
    searchTypeLevel = 2;
  }

  const processEntity = (e, parent) => {
    const elem = { ...e, children: [], allChildren: [], parents: [] };
    const entityTitle = elem.title || elem.id;
    const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, entityTitle);

    const matchSearch =
      search && search.trim()
        ? searchTypeLower
          ? searchTypeLower === elem?.type?.toLowerCase()
            ? entityTitle.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
              (instanceDisplayName || entityTitle).toLowerCase().indexOf(search.toLowerCase()) >= 0
            : true
          : entityTitle.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            (instanceDisplayName || entityTitle).toLowerCase().indexOf(search.toLowerCase()) >= 0
        : true;
    const isLeaf = isEmpty(e.children);

    if (!e.isVirtual) {
      allRealEntityList.push(elem);
    }

    if (parent) {
      elem.parents = [parent.id, ...(parent.parents || [])];
    }

    if (!matchSearch) {
      return { entity: null, allEntity: [] };
    }

    if (isLeaf) {
      if (hasData && elem.inactive) {
        return { entity: null, allEntity: [] };
      }

      if (hasAnomaly && !elem.hasAnomaly) {
        return { entity: null, allEntity: [] };
      }
    }

    allEntityList.push(elem);
    if (!e.isVirtual) {
      entityList.push(elem);
    }

    if (isLeaf) {
      if (!search || isUndefined(elem.level) || (search && searchTypeLevel <= elem.level)) {
        return { entity: elem, allEntity: [] };
      } else {
        allEntityList.pop(elem);
        if (!e.isVirtual) {
          entityList.pop(elem);
        }
        return { entity: null, allEntity: [] };
      }
    }

    R.forEachObjIndexed((val, key) => {
      const { entity, allEntity } = processEntity(val, elem);
      if (entity) {
        elem.children.push(entity);
        elem.allChildren = [...elem.allChildren, ...(entity.isVirtual ? [] : [entity]), ...allEntity];
      }
    }, e.children || {});

    if (elem.children.length === 0) {
      if (e.isVirtual) {
        allEntityList.pop();
        return { entity: null, allEntity: [] };
      } else if (search && searchTypeLower !== elem?.type?.toLowerCase()) {
        allEntityList.pop();
        entityList.pop();
        return { entity: null, allEntity: [] };
      } else if (!matchSearch || (hasData && elem.inactive) || (hasAnomaly && !elem.hasAnomaly)) {
        allEntityList.pop();
        entityList.pop();
        return { entity: null, allEntity: [] };
      }
    }

    return { entity: elem, allEntity: elem.allChildren };
  };

  R.forEachObjIndexed((val) => processEntity(val, null), allEntityTreeMap.children || {});

  return { allEntityList, entityList, allRealEntityList };
};

export const EntityFilterEx = ({
  showSearchType,
  entityType,
  allRawEntityList,
  allEntityTreeMap,
  hasContainer,
  selectionMap,
  onChange,
  loading = false,
  onRefresh,
  instanceDisplayNameMap = {},
  getEntityTitle = () => {},
}) => {
  const listNode = useRef(null);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    allEntityList: [],
    allRealEntityList: [],
    entityList: [],
    search: null,
    hasAnomaly: false,
    hasData: false,
    collapseMap: {},
    collapseEntityList: [],
    pageIndex: 0,
    pageSize: 10,
    searchType: showSearchType ? entityType : null,
  });

  const { pageIndex, pageSize } = state;
  const { hasAnomaly, hasData, search, searchType } = state;
  useEffect(() => {
    const { allEntityList, entityList, allRealEntityList } = BuildEntityList(
      entityType,
      allEntityTreeMap,
      search,
      hasAnomaly,
      hasData,
      searchType,
      instanceDisplayNameMap,
    );
    setState({ allEntityList, entityList, allRealEntityList });
  }, [search, searchType, hasAnomaly, hasData, allEntityTreeMap]);

  const { allEntityList, entityList, collapseMap, allRealEntityList } = state;

  useEffect(() => {
    setState({
      collapseEntityList: R.filter((e) => {
        const parents = e.parents || [];
        for (let i = 0; i < parents.length; i += 1) {
          if (collapseMap[parents[i]]) {
            return false;
          }
        }
        return true;
      }, allEntityList),
    });
  }, [allEntityList, collapseMap]);

  const { collapseEntityList } = state;

  return (
    <div className="flex-col flex-grow full-height">
      <div className="flex-row flex-center-alignt" style={{ marginBottom: 12, alignItems: 'center' }}>
        <span className="label" style={{ fontSize: 18 }}>
          {entityType}
        </span>
        {onRefresh && (
          <ReloadOutlined
            className="clickable"
            style={{ fontSize: 14, paddingLeft: 8 }}
            onClick={() => {
              onRefresh();
            }}
          />
        )}
        <div className="flex-grow" />
        <div style={{ paddingRight: 8 }}>{(R.values(selectionMap) || []).length} selected /</div>
        <div style={{ paddingRight: 0 }}>{(allRealEntityList || []).length} total</div>
      </div>
      <div className="flex-row" style={{ height: 24 }}>
        <div className="flex-row flex-center-align" style={{ paddingRight: 8 }}>
          <Checkbox
            checked={hasAnomaly}
            onChange={() => {
              setState({ hasAnomaly: !hasAnomaly });
            }}
            style={{ paddingRight: 8 }}
          />
          Anomaly
        </div>
        <div className="flex-row flex-center-align" style={{ paddingRight: 8 }}>
          <Checkbox
            checked={hasData}
            onChange={() => {
              setState({ hasData: !hasData });
            }}
            style={{ paddingRight: 8 }}
          />
          With data
        </div>
      </div>
      <div className="flex-row" style={{ height: 24 }}>
        <div className="flex-row flex-center-align" style={{ paddingRight: 8 }}>
          <div
            className="clickable"
            style={{ color: 'var(--link-color)' }}
            onClick={() => {
              R.forEach((c) => {
                selectionMap[c.id] = true;
              }, entityList);
              onChange(selectionMap);
            }}
          >
            {`Select ${entityList.length === allRealEntityList.length ? 'all' : entityList.length}`}
          </div>
        </div>
        <div className="flex-row flex-center-align" style={{ paddingRight: 8 }}>
          <div
            className="clickable"
            style={{ color: 'var(--link-color)' }}
            onClick={() => {
              if (entityList.length === allRealEntityList.length) {
                onChange({});
              } else {
                R.forEach((c) => {
                  delete selectionMap[c.id];
                }, entityList);
                onChange(selectionMap);
              }
            }}
          >
            {`Unselect ${entityList.length === allRealEntityList.length ? 'all' : entityList.length}`}
          </div>
        </div>
        <div className="flex-grow" />
        <div className="flex-row flex-center-align" style={{ paddingRight: 8 }}>
          <div
            className="clickable"
            style={{ color: 'var(--link-color)' }}
            onClick={() => {
              const idx = pageIndex === 0 ? 0 : pageIndex - 1;
              const selectionMap = {};

              R.forEach((c) => {
                selectionMap[c.id] = true;
              }, R.take(pageSize, R.drop(idx * pageSize, entityList)));

              setState({ pageIndex: idx });

              onChange(selectionMap);
              if (listNode.current) {
                listNode.current.scrollToRow(idx * pageSize);
              }
            }}
          >
            {`<Prev ${pageSize}`}
          </div>
        </div>
        <div className="flex-row flex-center-align" style={{}}>
          <div
            className="clickable"
            style={{ color: 'var(--link-color)' }}
            onClick={() => {
              const selectionMap = {};
              const idx =
                pageIndex === Math.ceil(entityList.length / pageSize) - 1
                  ? Math.ceil(entityList.length / pageSize) - 1
                  : pageIndex + 1;

              R.forEach((c) => {
                selectionMap[c.id] = true;
              }, R.take(pageSize, R.drop(idx * pageSize, entityList)));

              setState({ pageIndex: idx });
              onChange(selectionMap);

              if (listNode.current) {
                listNode.current.scrollToRow((idx + 1) * pageSize);
              }
            }}
          >
            {`Next ${pageSize}>`}
          </div>
        </div>
      </div>
      {showSearchType && (
        <div className="flex-row flex-center-align" style={{ height: 32, paddingBottom: 6 }}>
          <Select
            showArrow={false}
            size="small"
            style={{ width: '100%' }}
            placeholder=""
            value={searchType}
            onChange={(val) => {
              setState({ searchType: val });
            }}
            dropdownMatchSelectWidth
          >
            {R.map(
              (t) => (
                <Select.Option key={t} value={t}>
                  {t}
                </Select.Option>
              ),
              entityType === 'Instance'
                ? hasContainer
                  ? ['Component', 'Instance', 'Container']
                  : ['Component', 'Instance']
                : ['Component', 'Pod', 'Container'],
            )}
          </Select>
        </div>
      )}
      <div className="flex-row flex-center-align" style={{ height: 24 }}>
        <Input.Search
          allowClear
          style={{ flex: 1 }}
          size="small"
          placeholder="Search"
          value={search}
          onSearch={(search) => setState({ search })}
          onChange={(e) => {
            setState({ search: e.target.value });
          }}
        />
      </div>
      <div className="flex-grow">
        <Spin spinning={loading} wrapperClassName="full-height spin-full-height">
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={listNode}
                width={width}
                height={height}
                rowCount={collapseEntityList.length}
                rowHeight={ROW_HEIGHT}
                rowRenderer={({ index: idx, style }) => {
                  const entity = collapseEntityList[idx];
                  const { id, title, children = [], allChildren = [], level = 0, type } = entity;
                  const { isVirtual = false, inactive = false, hasAnomaly = false } = entity;
                  const color = hasAnomaly ? 'var(--primary-color)' : inactive ? 'gray' : 'var(--black)';
                  const realChildren = R.filter((c) => !c.isVirtual, children);
                  const checkedChildren = R.filter((c) => selectionMap[c.id], realChildren);
                  const realAllChildren = R.filter((c) => !c.isVirtual, allChildren);
                  const checkedAllChildren = R.filter((c) => selectionMap[c.id], realAllChildren);
                  const checked = !isVirtual ? selectionMap[id] : checkedAllChildren.length === realAllChildren.length;
                  const indeterminate = checkedChildren.length > 0 && checkedChildren.length < realChildren.length;
                  const icon = getEntityIcon(type);
                  const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, title);
                  const hasInstanceDiaplayName = R.includes(type, ['instance', 'pod']) && instanceDisplayName;
                  const realTitle = hasInstanceDiaplayName ? instanceDisplayName : title;

                  return (
                    <div key={id} className="flex-row clickable" style={{ ...style, alignItems: 'center' }}>
                      {level > 0 && <div style={{ width: 16 * level }} />}
                      {allChildren.length === 0 && <div style={{ width: 16 }} />}
                      {allChildren.length > 0 && (
                        <div
                          style={{ width: 16 }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (collapseMap[id]) {
                              delete collapseMap[id];
                            } else {
                              collapseMap[id] = true;
                            }
                            setState({ collapseMap: { ...collapseMap } });
                          }}
                        >
                          <CaretDownOutlined rotate={collapseMap[id] ? -90 : 0} />
                        </div>
                      )}
                      <div
                        onClick={() => {
                          if (checked) {
                            if (!isVirtual) delete selectionMap[id];

                            R.forEach((c) => {
                              if (!c.isVirtual) {
                                delete selectionMap[c.id];
                              }
                            }, allChildren);
                          } else {
                            if (!isVirtual) selectionMap[id] = true;

                            R.forEach((c) => {
                              if (!c.isVirtual) {
                                selectionMap[c.id] = true;
                              }
                            }, allChildren);
                          }
                          onChange(selectionMap);
                        }}
                      >
                        <Checkbox checked={checked} indeterminate={indeterminate} style={{ paddingRight: 8 }} />
                      </div>
                      {!!icon && <div style={{ width: 20, fontSize: 14, color }}>{icon}</div>}
                      <Tooltip
                        title={getEntityTitle(type, isVirtual ? title : id, instanceDisplayNameMap)}
                        mouseEnterDelay={0.3}
                        placement="right"
                        overlayInnerStyle={hasInstanceDiaplayName ? { width: 320 } : {}}
                      >
                        <div className="flex-grow hidden-line-with-ellipsis" style={{ color }}>
                          {realTitle}
                        </div>
                      </Tooltip>
                      {allChildren.length > 0 && (
                        <div style={{ paddingRight: 16 }}>{`${checkedAllChildren.length}/${allChildren.length}`}</div>
                      )}
                    </div>
                  );
                }}
              />
            )}
          </AutoSizer>
        </Spin>
      </div>
    </div>
  );
};
