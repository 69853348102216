import React, { useReducer } from 'react';
import * as R from 'ramda';
import { Form, Input, message } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';

import { Modal } from '../../../lib/fui/react';
import { eventMessages } from '../../../common/metric/messages';

export default function RecommendationContentModal({
  intl,
  onClose,
  credentials,
  rootCauseLevel,
  addRecommendationActive,
  getRecommendation,
  activeEvent,
  recommendationList,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    recommendation: activeEvent || '',
    isSubmitting: false,
    oldRecommendationList: R.map((item) => item.recommendation, recommendationList || []),
  });
  const { recommendation, isSubmitting, oldRecommendationList } = state;

  const hasError = !recommendation;

  const handleConfirmClick = () => {
    setState({ isSubmitting: true });
    const { incidentRule, ruleType } = addRecommendationActive;
    const { rulePrimaryKey } = incidentRule;
    const { uniqueKey, rulePartitionKey } = rulePrimaryKey;
    const { projectName, userName, instanceName, patternId } = rulePartitionKey;
    const ruleInfo = JSON.stringify([{ projectName, userName, instanceName, patternId, ruleType, uniqueKey }]);

    if (activeEvent) {
      const editRecommendationIndex = R.indexOf(activeEvent, oldRecommendationList);
      let suggestions = [];
      if (editRecommendationIndex >= 0) {
        suggestions = R.update(editRecommendationIndex, recommendation, oldRecommendationList);
        fetchPost(getEndpoint('getrecommendations'), {
          ...credentials,
          predictionRuleInfo: ruleInfo,
          suggestions: JSON.stringify(suggestions),
        })
          .then((data) => {
            const { success, message: msg } = data;
            if (success || success === undefined) {
              message.success(msg);
              getRecommendation();
              onClose();
            } else {
              console.log(msg);
            }
            setState({ isSubmitting: false });
          })
          .catch((e) => {
            setState({ isSubmitting: false });
            console.log(String(e));
          });
      }
    } else {
      fetchPost(getEndpoint('getrecommendations'), {
        ...credentials,
        predictionRuleInfo: ruleInfo,
        suggestions: JSON.stringify([recommendation, ...oldRecommendationList]),
      })
        .then((data) => {
          const { success, message: msg } = data;
          if (success || success === undefined) {
            message.success(msg);
            getRecommendation();
            onClose();
          } else {
            console.log(msg);
          }
          setState({ isSubmitting: false });
        })
        .catch((e) => {
          setState({ isSubmitting: false });
          console.log(String(e));
        });
    }
  };

  return (
    <Modal
      visible
      width={650}
      title={intl.formatMessage(eventMessages.addRecommendation)}
      onOk={handleConfirmClick}
      onCancel={() => onClose()}
      okButtonProps={{ disabled: hasError, loading: isSubmitting }}
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item label={intl.formatMessage(eventMessages.recommendation)} required>
          <Input.TextArea
            rows={4}
            value={recommendation}
            onChange={(e) => setState({ recommendation: e.target.value })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
