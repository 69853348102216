/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Select, Alert, Form, TreeSelect, Checkbox, Divider, Input } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { parseJSON } from '../../../../common/utils';
import { Container, Popover } from '../../../../lib/fui/react';

import { appFieldsMessages } from '../../../../common/app/messages';
import { compactJson, tagFilterJSONFormat, validateAndFormatJson } from '../dataSource/AWSCloudWatchSetting';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

const filterTreeNodes = (inputValue = '', treeNode = {}, treeNodeFilterProp = 'titleFilter') => {
  return treeNode[treeNodeFilterProp].toLowerCase().includes(inputValue.toLowerCase());
};

class EndpointSettingCloudWatchMetricCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoadingKey: false,
      isLoading: false,
      isUpdateKey: false,
      isSubmitting: false,
      errorMessageKey: '',
      errorMessage: '',

      accessKey: '',
      secretKey: '',
      treeDataInstance: [],
      instances: [],
      allMetrics: [],
      metrics: [],
      possibleAutoScalingGroup: [],
      autoScalingGroup: [],
      possibleExtraNamespaces: [],
      extraNamespace: [],
      isCustomFilter: false,
      awsTagFilter: '',
    };
    this.accessKey = '';
    this.secretKey = '';

    this.componentInsMap = {};
    this.filterTreeData = [];
  }

  componentDidMount() {
    this.reloadKey(this.props);
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadKey(nextProps);
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadKey(props) {
    const { credentials, projectName, currentProject } = props;

    this.setState({ isLoadingKey: true });
    const cloudType = get(currentProject, ['cloudType']);
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const { success, message, accessKey, secretKey } = data || {};
        if (success === undefined || success) {
          this.accessKey = accessKey;
          this.secretKey = secretKey;
          this.setState({
            isLoadingKey: false,
            errorMessageKey: undefined,

            accessKey,
            secretKey,
          });
        } else {
          this.setState({
            isLoadingKey: false,
            errorMessageKey: message,

            accessKey: '',
            secretKey: '',
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoadingKey: false,
          errorMessageKey: errMsg || String(err),

          accessKey: '',
          secretKey: '',
        });
      });
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName } = props;

    this.setState({ isLoading: true });
    return fetchGet(getEndpoint('awsconfig'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const { success, message, currentSetting, possibleSetting } = data || {};
        if (success === undefined || success) {
          const {
            instanceWhiteList: instances,
            metricWhiteList: metrics,
            autoScalingGroup,
            awsNamespace: extraNamespace,
            isCustomFilter,
            awsTagFilter,
          } = parseJSON(currentSetting) || {};
          let {
            instances: allInstances,
            metrics: allMetrics,
            possibleAutoScalingGroup,
            possibleExtraNamespaces,
          } = parseJSON(possibleSetting) || {};

          possibleAutoScalingGroup = possibleAutoScalingGroup || [];
          possibleExtraNamespaces = possibleExtraNamespaces || [];

          let newInstances = [];
          R.forEach((item) => {
            let newItem;
            try {
              newItem = JSON.parse(item);
            } catch (error) {
              //
            }
            if (newItem) newInstances.push(newItem.c);
          }, instances || []);
          newInstances = R.uniq(newInstances);

          // build tree data
          allInstances = R.map((item) => {
            const { componentName, instanceId } = item;
            return { ...item, componentName: componentName || instanceId };
          }, allInstances);
          allInstances = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('componentName')))], allInstances);
          allMetrics = R.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          }, allMetrics || []);

          // build tree list
          const componentInsMap = {};
          R.forEach((item) => {
            const { componentName } = item;
            if (!R.has(componentName, componentInsMap)) componentInsMap[componentName] = [];
            componentInsMap[componentName].push(item);
          }, allInstances);

          let treeData = [];
          R.forEachObjIndexed((insList, cmp) => {
            const children = R.map((item) => {
              const { instanceId, instanceType, publicIp } = item;
              const key = `${cmp}$${instanceId}`;

              const titleFilter = `Id: ${instanceId} Type: ${instanceType} Public IP: ${publicIp}`;
              const title = (
                <span>
                  <span className="light-label bold">Id:</span>
                  <span style={{ margin: '0 8px 0 4px' }}>{instanceId}</span>
                  <span className="light-label bold">Type:</span>
                  <span style={{ margin: '0 8px 0 4px' }}>{instanceType}</span>
                  <span className="light-label bold">Public IP:</span>
                  <span style={{ margin: '0 8px 0 4px' }}>{publicIp}</span>
                </span>
              );
              return {
                titleFilter,
                title,
                value: key,
                key,
                checkable: false,
              };
            }, insList);
            const key = cmp;
            treeData.push({
              titleFilter: cmp,
              title: cmp,
              value: key,
              key,
              children,
            });
          }, componentInsMap);
          treeData = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], treeData);

          this.componentInsMap = componentInsMap;
          this.filterTreeData = treeData;
          this.setState({
            isLoading: false,
            errorMessage: undefined,

            treeDataInstance: treeData,
            instances: newInstances,
            allMetrics,
            metrics,
            possibleAutoScalingGroup,
            autoScalingGroup: autoScalingGroup || [],
            possibleExtraNamespaces,
            extraNamespace: R.filter((item) => Boolean(item), extraNamespace || []),
            isCustomFilter,
            awsTagFilter: JSON.stringify(awsTagFilter, null, 2),
          });
        } else {
          this.componentInsMap = {};
          this.setState({
            isLoading: false,
            errorMessage: message,

            treeDataInstance: [],
            instances: [],
            allMetrics: [],
            metrics: [],
            possibleAutoScalingGroup: [],
            autoScalingGroup: [],
            possibleExtraNamespaces: [],
            extraNamespace: [],
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.componentInsMap = {};
        this.setState({
          isLoading: false,
          errorMessage: errMsg || String(err),

          treeDataInstance: [],
          instances: [],
          allMetrics: [],
          metrics: [],
          possibleAutoScalingGroup: [],
          autoScalingGroup: [],
          possibleExtraNamespaces: [],
          extraNamespace: [],
        });
      });
  }

  @autobind
  handleUpdateKey() {
    const { credentials, projectName } = this.props;

    this.setState({ isUpdateKey: true });
    const { accessKey, secretKey, isCustomFilter, awsTagFilter } = this.state;
    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      accessKey,
      secretKey,
      ...(isCustomFilter ? { awsTagFilter: compactJson(awsTagFilter), isCustomFilter } : {}),
    })
      .then((data) => {
        this.setState({ isUpdateKey: false, errorMessageKey: undefined });
        this.reloadKey(this.props);
        this.reloadData(this.props);
      })
      .catch((err) => {
        this.setState({ isUpdateKey: false, errorMessageKey: String(err) });
      });
  }

  @autobind
  handleSubmit() {
    const { credentials, projectName } = this.props;

    this.setState({ isSubmitting: true });
    const { instances, metrics, autoScalingGroup, extraNamespace, isCustomFilter, awsTagFilter } = this.state;

    const newInstances = [];
    R.forEach((item) => {
      if (this.componentInsMap[item]) {
        R.forEach((i) => {
          newInstances.push({ c: i.componentName, i: i.rawInstanceId, r: i.region });
        }, this.componentInsMap[item] || []);
      }
    }, instances || []);

    return fetchPost(getEndpoint('awsconfig'), {
      ...credentials,
      projectName,
      newInstances: JSON.stringify(newInstances),
      metrics: JSON.stringify(metrics),
      autoScalingGroup: JSON.stringify(autoScalingGroup),
      extraNamespace: JSON.stringify(extraNamespace),
      ...(isCustomFilter ? { awsTagFilter: compactJson(awsTagFilter), isCustomFilter } : {}),
    })
      .then((data) => {
        this.setState({ isSubmitting: false, errorMessage: undefined });
        this.reloadData(this.props);
      })
      .catch((err) => {
        this.setState({ isSubmitting: false, errorMessage: String(err) });
      });
  }

  @autobind
  handleInstanceChange(instances) {
    this.setState({ instances });
  }

  @autobind
  handleMetricChange(metrics) {
    this.setState({ metrics });
  }

  @autobind
  handleAutoScalingGroupChange(autoScalingGroup) {
    this.setState({ autoScalingGroup });
  }

  @autobind
  handleExtraNamespaceChange(extraNamespace) {
    this.setState({ extraNamespace });
  }

  render() {
    const { intl } = this.props;
    const { isLoadingKey, isLoading, isUpdateKey, isSubmitting, errorMessageKey, errorMessage } = this.state;
    const {
      accessKey,
      secretKey,
      treeDataInstance,
      instances,
      allMetrics,
      metrics,
      possibleAutoScalingGroup,
      autoScalingGroup,
      possibleExtraNamespaces,
      extraNamespace,
      isCustomFilter,
      awsTagFilter,
    } = this.state;

    const tagFilterValidate = validateAndFormatJson(compactJson(awsTagFilter));

    const hasErrKey = !accessKey || !secretKey;
    const hasChangeKey = this.secretKey !== secretKey || this.accessKey !== accessKey;
    const hasFormErr =
      !accessKey ||
      !secretKey ||
      instances.length === 0 ||
      metrics.length === 0 ||
      !tagFilterValidate ||
      (isCustomFilter ? !awsTagFilter : false);
    return (
      <Container fullHeight className={`overflow-y-auto ${isLoadingKey || isLoading ? ' loading' : ''}`}>
        <div className="full-height" style={{ width: 800 }}>
          <Form layout="vertical">
            <Form.Item
              label="Access Key"
              required
              validateStatus={!accessKey ? 'error' : 'success'}
              help={!accessKey ? 'This input is required.' : undefined}
            >
              <Input.Password value={accessKey} onChange={(e) => this.setState({ accessKey: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Secret Key"
              required
              validateStatus={!secretKey ? 'error' : 'success'}
              help={!secretKey ? 'This input is required.' : undefined}
            >
              <Input.Password value={secretKey} onChange={(e) => this.setState({ secretKey: e.target.value })} />
            </Form.Item>
            {errorMessageKey && (
              <Alert message="Error" description={errorMessageKey} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasErrKey || !hasChangeKey}
                loading={isUpdateKey}
                onClick={this.handleUpdateKey}
              >
                Update access/secret key
              </Button>
            </Form.Item>

            <Form.Item
              label="Instances"
              required
              validateStatus={instances.length === 0 ? 'error' : 'success'}
              help={instances.length === 0 ? 'This input is required.' : undefined}
            >
              <TreeSelect
                className="no-count-num-row"
                style={{ width: '100%' }}
                disabled={hasChangeKey || isCustomFilter}
                showSearch
                autoClearSearchValue={false}
                treeCheckable
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                treeNodeFilterProp="titleFilter"
                treeNodeLabelProp="title"
                treeData={treeDataInstance}
                value={instances}
                onChange={this.handleInstanceChange}
                dropdownRender={(menu) => {
                  const allInstanceKey = [];
                  this.filterTreeData.forEach((item) => allInstanceKey.push(item.value));
                  const selectAllInstanceKey = instances;
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 24px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={R.difference(allInstanceKey, selectAllInstanceKey).length === 0}
                          onChange={(e) => {
                            const { checked } = e.target;
                            const diff = R.difference(selectAllInstanceKey, allInstanceKey);
                            this.setState({ instances: checked ? [...diff, ...allInstanceKey] : diff });
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
                onSearch={(searchValue) => {
                  if (!searchValue) {
                    this.filterTreeData = treeDataInstance;
                  } else {
                    const filterNodes = (nodes) => {
                      return nodes
                        .map((node) => {
                          const { children, ...restNode } = node;
                          const filteredChildren = children ? filterNodes(children) : [];

                          if (filterTreeNodes(searchValue, node, 'titleFilter')) {
                            return {
                              ...restNode,
                              children: filteredChildren.length > 0 ? filteredChildren : children,
                            };
                          } else if (filteredChildren.length > 0) {
                            return { ...restNode, children: filteredChildren };
                          }

                          return null;
                        })
                        .filter((node) => node !== null);
                    };
                    this.filterTreeData = filterNodes(treeDataInstance);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Metrics"
              required
              validateStatus={metrics.length === 0 ? 'error' : 'success'}
              help={metrics.length === 0 ? 'This input is required.' : undefined}
            >
              <Select
                className="no-count-num-row"
                style={{ width: '100%' }}
                disabled={hasChangeKey}
                mode="multiple"
                autoClearSearchValue={false}
                value={metrics}
                onChange={this.handleMetricChange}
                showSearch
                optionFilterProp="title"
                optionLabelProp="title"
                dropdownRender={(menu) => {
                  const selectAll = R.difference(allMetrics, metrics).length === 0;
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selectAll}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              this.setState({ metrics: allMetrics });
                            } else {
                              this.setState({ metrics: [] });
                            }
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
              >
                {R.map((metric) => {
                  return (
                    <Select.Option className="hide-icon" key={metric} title={metric}>
                      <Checkbox
                        checked={metrics.indexOf(metric) !== -1}
                        onChange={(e) => null}
                        style={{ marginRight: 8 }}
                      />
                      {metric}
                    </Select.Option>
                  );
                }, allMetrics)}
              </Select>
            </Form.Item>
            {isCustomFilter && (
              <Form.Item
                label={
                  <div style={{ marginBottom: 4 }}>
                    Tag filter in JSON format
                    <Popover
                      content={
                        <div style={{ maxWidth: 400, maxHeight: 400, overflowY: 'auto', display: 'flex' }}>
                          <div style={{ marginRight: 8 }}>Example:</div>
                          <div style={{ flex: 1, wordBreak: 'break-word' }}>
                            <pre
                              style={{
                                height: '100%',
                                paddingRight: 8,
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                              }}
                            >
                              {tagFilterJSONFormat}
                            </pre>
                          </div>
                        </div>
                      }
                      mouseEnterDelay={0.3}
                      placement="right"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                    </Popover>
                  </div>
                }
              >
                <Input.TextArea
                  allowClear
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  value={awsTagFilter}
                  onChange={(e) => this.setState({ awsTagFilter: e.target.value })}
                />
                {!tagFilterValidate || (isCustomFilter ? !awsTagFilter : false) ? (
                  <div style={{ color: 'red', marginTop: 4 }}>JSON format error</div>
                ) : (
                  ''
                )}
              </Form.Item>
            )}

            <Form.Item label="Auto scaling group">
              <Select
                style={{ width: '100%' }}
                disabled={hasChangeKey}
                mode="multiple"
                autoClearSearchValue={false}
                maxTagCount={1}
                value={autoScalingGroup}
                onChange={this.handleAutoScalingGroupChange}
                showSearch
                optionFilterProp="title"
                optionLabelProp="title"
                dropdownRender={(menu) => {
                  const selectAll = R.difference(possibleAutoScalingGroup, autoScalingGroup).length === 0;
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selectAll}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              this.setState({ autoScalingGroup: possibleAutoScalingGroup });
                            } else {
                              this.setState({ autoScalingGroup: [] });
                            }
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
              >
                {R.map((item) => {
                  return (
                    <Select.Option className="hide-icon" key={item} title={item}>
                      <Checkbox
                        checked={autoScalingGroup.indexOf(item) !== -1}
                        onChange={(e) => null}
                        style={{ marginRight: 8 }}
                      />
                      {item}
                    </Select.Option>
                  );
                }, possibleAutoScalingGroup)}
              </Select>
            </Form.Item>
            <Form.Item label="Custom metric namespace">
              <Select
                style={{ width: '100%' }}
                disabled={hasChangeKey}
                mode="multiple"
                autoClearSearchValue={false}
                maxTagCount={1}
                value={extraNamespace}
                onChange={this.handleExtraNamespaceChange}
                showSearch
                optionFilterProp="title"
                optionLabelProp="title"
                dropdownRender={(menu) => {
                  const selectAll = R.difference(possibleExtraNamespaces, extraNamespace).length === 0;
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selectAll}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              this.setState({ extraNamespace: possibleExtraNamespaces });
                            } else {
                              this.setState({ extraNamespace: [] });
                            }
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
              >
                {R.map((item) => {
                  return (
                    <Select.Option className="hide-icon" key={item} title={item}>
                      <Checkbox
                        checked={extraNamespace.indexOf(item) !== -1}
                        onChange={(e) => null}
                        style={{ marginRight: 8 }}
                      />
                      {item}
                    </Select.Option>
                  );
                }, possibleExtraNamespaces)}
              </Select>
            </Form.Item>
            {errorMessage && (
              <Alert message="Error" description={errorMessage} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasChangeKey || hasFormErr}
                loading={isSubmitting}
                onClick={this.handleSubmit}
              >
                Update instances/metrics
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    );
  }
}

const EndpointSettingCloudWatchMetric = injectIntl(EndpointSettingCloudWatchMetricCore);
export default connect((state) => {
  return {};
}, {})(EndpointSettingCloudWatchMetric);
