import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Alert, Button, Card, Empty, Spin, Tabs, Tag } from 'antd';
import { CloseCircleOutlined, RightSquareOutlined, SearchOutlined } from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { CellRenderers, Defaults, parseLocation, sleep } from '../../../common/utils';
import { parseTimelines } from '../../../common/apis/parsers';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Container, Popover, Tooltip } from '../../../lib/fui/react';
import {
  anomalyTypeOptions,
  childrenBorder,
  customizeRestore,
  dateHeight,
  filterData,
  findJumpEvent,
  getChartOption,
  getChartTimeMap,
  getEventsByOccurrence,
  handleChartFinish,
  handleChartRestore,
  handleChartZoom,
  handleMergeCategoryDateClick,
  handleMergeCategoryParentClick,
  impactedRender,
  listHeaderHeight,
  occurrenceTime,
  onSystemChartClick,
  patternRenderer,
  positionEvent,
  reducerHandleEvents,
  rendererContent,
  rendererExpand,
  renderTopBarRender,
  rowMinHeight,
  setChartRef,
  statusChainRenderer,
  tableViewList,
  tidyEventList,
  timeRenderer,
} from '../utils/predictionTimeLine';
import { EChart } from '../../share';
import { logMessages } from '../../../common/log/messages';
import { GoodIcon, GoodOutlinedIcon, RobotIcon } from '../../../lib/fui/icons';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { appFieldsMessages, appMenusMessages } from '../../../common/app/messages';
import PredictionRootCauseRCA from './PredictionRootCauseRCA';
import RecommendationsGPT from './RecommendationsGPT';

type Props = {
  intl: Object,
  location: Object,
  selectedZone: String,
  forceRefreshTime: Number,
  userInfo: Object,
  jwtToken: String,
  systemId: String,
  startTimeObj: Object,
  endTimeObj: Object,
  environmentName: String,
  systemInfo: Object,
  currentTheme: String,

  handleJumpBySelf: Function,
  clearJumpBySelf: Function,
  eventInstanceName: String,
  eventPatternType: String,
  eventRootCauseMetric: String,
  eventPredictionTime: String,
  eventTimestamp: String,
  eventPatternId: String,
  eventProjectName: String,
  changeShowPredictionSourceInfo: Function,
};

class EmbedPredictiontimelineCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl } = props;

    this.listHeaderHeight = listHeaderHeight;
    this.rowMinHeight = rowMinHeight;
    this.dateHeight = dateHeight;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: this.dateHeight,
    });

    this.state = {
      // 因为默认是IF Prod system,里面是有Kubernetes项目
      // 所以默认是true,如果需要动态数据判断,那么需要请求loadProjectsMetaDataInfo API,获取project的cloudType
      hasKubernetes: true,
      isLoadingParserData: false,
      errMessage: undefined,

      globalSystemInfo: {},
      events: [],
      filterList: [],
      groupEventList: [],
      incident: null,
      isShowPredictionSourceInfo: false,
      showRecommendationsGPT: false,
      activeRecommendation: null,

      chartOption: {},
      isResetDateZoom: true,
      zoomStartTime: undefined,
      zoomEndTime: undefined,
      activeKey: 'incident',

      hasTableData: false,
      showFilterPanel: false,
      sortBy: null,
      sortDirection: null,
      allExpand: true,
      dateAllExpand: true,

      // filter
      filterLoading: false,
      predictionStatusFilter: undefined,
      typeFilter: undefined,
      componentFilter: undefined,
      instanceFilter: undefined,
      logicPodIDFilter: undefined,
      containerFilter: undefined,
      patternIdFilter: undefined,
      sourceFilter: undefined,

      patternIdsFilter: undefined,
      componentsFilter: undefined,
      instancesFilter: undefined,
      podsFilter: undefined,
      containersFilter: undefined,

      predictionStatusClose: true,
      predictionCategoryClose: true,
      impactedComponentsClose: true,
      impactedInstancesClose: true,
      impactedPodsClose: true,
      impactedContainersClose: true,
      patternIdNameClose: true,
      sourceCategoryClose: true,
    };

    this.anomalyTypeOptions = anomalyTypeOptions(intl);
    this.eventIncidentList = [];
    this.componentListOptions = [];
    this.instanceListOptions = [];
    this.podOptions = [];
    this.containerOption = [];
    this.predictionSourceOptions = [];
    this.patternIdFilterOptions = [];
    this.predictionStatusCountMap = {};

    this.chartTimeMap = {};
    this.systemChartRef = {};

    this.fatherEvent = undefined;
    this.childEvent = undefined;
  }

  componentDidMount() {
    if (this.props.forceRefreshTime) this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.forceRefreshTime !== this.props.forceRefreshTime ||
      nextProps.selectedZone !== this.props.selectedZone
    ) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { environmentName, jwtToken, systemId, userInfo, selectedZone } = props || {};
    const { startTimeObj, endTimeObj, systemInfo } = props || {};
    this.setState({ isLoadingParserData: true, errMessage: undefined, incident: undefined });

    const request = [];
    const startTimestamp = startTimeObj.clone().startOf('days').valueOf();
    const endTimestamp = endTimeObj.clone().endOf('days').valueOf();
    const startTimestamps = [];
    R.forEach((ts) => {
      const timestamp = ts * 86400000;
      startTimestamps.push(timestamp);
    }, R.range(startTimestamp / 86400000, endTimestamp / 86400000));
    R.forEach((startTimestamp) => {
      request.push(
        fetchGet(getEndpoint('incidentpredictiontimelinesJWT', 2), {
          environmentName: environmentName || 'All',
          jwt: jwtToken,
          startTime: startTimestamp,
          systemName: systemId,
          customerName: userInfo?.userName || 'user',
          zoneName: selectedZone === '__all__' ? `all_zone_${systemId}` : selectedZone,
        }),
      );
    }, startTimestamps);

    Promise.all(request)
      .then((results) => {
        const { success, message: msg } = results || {};
        if (success || success === undefined) {
          const timelinesMap = {};
          R.addIndex(R.forEach)((result, idx) => {
            const timeline = result[0];
            const { projectDetailsList: projectNameSet, projectDisplayMap } = systemInfo || {};
            parseTimelines({
              timeline,
              timelinesMap,
              projectNameSet,
              projectDisplayMap,
              notInclude: false,
            });
          }, R.slice(0, startTimestamps.length, results));

          // build timelines
          const systemTimelinesMap = {};
          R.forEachObjIndexed((valList, id) => {
            const newValList = R.filter((item) => !item.notInclude, valList);
            R.forEach((item) => {
              const { systemTimeline } = item;
              if (!R.has(id, systemTimelinesMap)) {
                systemTimelinesMap[id] = systemTimeline;
              } else {
                systemTimelinesMap[id] = {
                  ...systemTimeline,
                  anomalyTimelines: [...systemTimelinesMap[id].anomalyTimelines, ...systemTimeline.anomalyTimelines],
                };
              }
            }, newValList);
          }, timelinesMap);
          const timelines = R.map((timeline) => ({ ...timeline, level: 'system' }), R.values(systemTimelinesMap));

          // add triggeredAction info
          let globalSystemInfo = timelines[0];
          let { anomalyTimelines } = globalSystemInfo || {};
          anomalyTimelines = R.map((event) => {
            const predictionTime = event.predictionTimeStamp;
            const consolidatedTimePair = event.consolidatedTimePair || [];
            if (consolidatedTimePair.length === 0) {
              consolidatedTimePair.push({
                predictionTime,
                predictionOccurrence: event.startTimestamp,
              });
            }
            return {
              ...event,
              predictionTime,
              consolidatedTimePair,
            };
          }, anomalyTimelines || []);

          globalSystemInfo = { ...globalSystemInfo, anomalyTimelines };

          this.setState({ errMessage: undefined, globalSystemInfo }, () => {
            this.parseData(props);
          });
        } else {
          this.setState({ isLoadingParserData: false, errMessage: msg });
        }
      })
      .catch((err) => {
        this.setState({ isLoadingParserData: false, errMessage: err.meesage || String(err) });
      });
  }

  @autobind
  parseData(props) {
    const { startTimeObj, endTimeObj, systemInfo, clearJumpBySelf } = props || {};
    const { eventProjectName, eventPatternId, eventTimestamp, eventPredictionTime } = props || {};
    const { globalSystemInfo, hasKubernetes } = this.state;

    const startTime = startTimeObj.format(Defaults.DateFormat);
    const endTime = endTimeObj.format(Defaults.DateFormat);

    getChartTimeMap(this.chartTimeMap, startTime, endTime);

    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    let anomalyEventList = get(globalSystemInfo, ['anomalyTimelines'], []);
    //  get prediction incident
    anomalyEventList = R.filter(
      (event) => R.toLower(event.type) === 'incident' && event.timeLineType === 'future',
      anomalyEventList,
    );

    const {
      eventList: events,
      hasTableData,
      anomalyTypeOptions,
      instanceListOptions,
      podListOptions,
      containerListOption,
      predictionSourceOptions,
      componentListOptions,
      patternIdFilterOptions,
      predictionStatusCountMap,
    } = tidyEventList(true, this, anomalyEventList, instanceComponentMap, hasKubernetes);

    this.anomalyTypeOptions = anomalyTypeOptions;

    this.instanceListOptions = instanceListOptions;
    this.podOptions = podListOptions;
    this.containerOption = containerListOption;

    this.predictionSourceOptions = predictionSourceOptions;

    this.componentListOptions = componentListOptions;

    this.patternIdFilterOptions = patternIdFilterOptions;

    this.predictionStatusCountMap = predictionStatusCountMap;

    const filterList = filterData(events, this);
    const chartOption = this.handleChartOption(filterList);
    const groupEventList = getEventsByOccurrence(this, filterList);

    const { jumpFatherIncident, jumpIncident, isShowPredictionSourceInfo } = findJumpEvent({
      groupEventList,
      isJWT: true,
      props,
    });

    if (isShowPredictionSourceInfo) props.changeShowPredictionSourceInfo(true);

    this.setState(
      {
        events,
        filterList,
        groupEventList,
        incident: jumpIncident,
        isLoadingParserData: false,
        isShowPredictionSourceInfo,
        chartOption,
        isResetDateZoom: true,
        zoomStartTime: undefined,
        zoomEndTime: undefined,
        hasTableData,
      },
      () => {
        if (eventProjectName && eventPatternId && (eventTimestamp || eventPredictionTime)) {
          clearJumpBySelf();
        }

        if (jumpFatherIncident && jumpIncident) {
          this.fatherEvent = jumpFatherIncident;
          this.childEvent = jumpIncident;
          positionEvent({ self: this });
        } else {
          positionEvent({ self: this });
        }
      },
    );
  }

  @autobind
  handleChartOption(eventList) {
    const { startTimeObj, endTimeObj } = this.props;
    const startTime = startTimeObj.format(Defaults.DateFormat);
    const endTime = endTimeObj.format(Defaults.DateFormat);
    const { allDayAnomalyList = [], filterTimestamps = [] } = this.chartTimeMap[`${startTime}-${endTime}`] || {};
    return getChartOption(this, eventList, allDayAnomalyList, filterTimestamps, {});
  }

  @autobind
  onChangeFilter(value, type) {
    const { hasKubernetes } = this.state;
    let newValue;
    if (value.length > 0) newValue = R.join(',', value);

    let otherQuery = {};
    if (R.includes(type, ['instanceFilter'])) {
      if (hasKubernetes) {
        otherQuery = { logicPodIDFilter: undefined, containerFilter: undefined };
      }
    }
    if (R.includes(type, ['logicPodIDFilter'])) {
      if (hasKubernetes) {
        otherQuery = { instanceFilter: undefined };
        if (!newValue) {
          otherQuery = { ...otherQuery, containerFilter: undefined };
        }
      }
    }

    this.setState({ [type]: newValue, filterLoading: true, ...otherQuery }, async () => {
      await sleep(100);
      const { events } = this.state;
      const filterList = filterData(events, this);
      const chartOption = this.handleChartOption(filterList);
      const groupEventList = getEventsByOccurrence(this, filterList);
      this.setState(
        {
          filterList,
          groupEventList,
          chartOption,
          filterLoading: false,
          isResetDateZoom: true,
          zoomStartTime: undefined,
          zoomEndTime: undefined,
        },
        async () => {
          if (this.listNode) await this.listNode.scrollToPosition(1);
          if (this.listNode) this.listNode.scrollToPosition(1);
        },
      );
    });
  }

  @autobind
  handleMergeCategoryClick(rowIndex, rowData, rootEvent) {
    const incident = rowData;
    this.fatherEvent = rootEvent;
    this.childEvent = rowData;
    this.setState({ incident });
  }

  @autobind
  rootCauseChainRenderer(rowData) {
    const { intl } = this.props;
    const incident = rowData;
    const { predictionSourceInfoList } = incident;
    const patternNames = R.map((item) => item.patternName, predictionSourceInfoList || []);
    const nameChains = R.join('->', patternNames);
    const completeChains = `${nameChains}->${incident.patternName}`;
    return (
      <div className="flex-row flex-center-align full-width flex-center-justify">
        <Popover
          placement="top"
          title={intl.formatMessage(logMessages.rootCauseChain)}
          content={
            <div className="flex-col flex-center-jusutify" style={{ maxWidth: 400 }}>
              <div className="flex-grow flex-grow flex-wrap" style={{ wordWrap: 'anywhere', textAlign: 'left' }}>
                {completeChains}
              </div>
            </div>
          }
        >
          <Button
            size="small"
            icon={<SearchOutlined style={{ fontSize: 16, color: 'var(--primary-color)' }} />}
            style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}
            onClick={(e) => {
              e.stopPropagation();
              const incident = R.clone(rowData);
              this.props.changeShowPredictionSourceInfo(true);
              this.setState({ incident, activeKey: 'incident', isShowPredictionSourceInfo: true });
            }}
          />
        </Popover>
        <Popover mouseEnterDelay={0.3} placement="left" content={intl.formatMessage(logMessages.predictionSummary)}>
          <RobotIcon
            className="clickable"
            style={{ fontSize: 20, color: 'var(--primary-color)', marginLeft: 8 }}
            onClick={() => this.setState({ showRecommendationsGPT: true, activeRecommendation: rowData })}
          />
        </Popover>
      </div>
    );
  }

  @autobind
  statusContentRenderer(rowData) {
    const { status, isFixedIncident, incidentStatus } = rowData;
    const colorMap = { 'in progress': 'orange', closed: 'green', created: 'currentColor' };
    const textMap = { 'in progress': 'Active', closed: 'Closed', created: 'Created' };
    if (isFixedIncident || status === 'Auto resolved') {
      return <span>{status}</span>;
    }
    return <div style={{ color: colorMap[incidentStatus] }}>{textMap[incidentStatus] || 'Created'}</div>;
  }

  @autobind
  confirmButtonRender(rowData) {
    const { intl } = this.props;
    const { isImportant } = rowData;
    return (
      <Popover
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 400, maxHeight: 150 }}>
            {isImportant
              ? intl.formatMessage(eventMessages.removeLikeFlag)
              : intl.formatMessage(eventMessages.markAsLike)}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="left"
      >
        <Button size="small" disabled style={{ marginRight: 4 }}>
          {isImportant ? <GoodIcon style={{ color: 'var(--green)' }} /> : <GoodOutlinedIcon />}
        </Button>
      </Popover>
    );
  }

  @autobind
  ignoreButtonRender(rowData) {
    const { isIgnored } = rowData;
    return (
      <Popover
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 400, maxHeight: 150 }}>
            {isIgnored ? 'Remove dislike flag' : 'Dislike'}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="left"
      >
        <Button size="small" disabled>
          {isIgnored && <GoodIcon style={{ color: 'var(--yellow)', transform: 'rotateX(180deg)' }} />}
          {!isIgnored && <GoodOutlinedIcon style={{ transform: 'rotateX(180deg)' }} />}
        </Button>
      </Popover>
    );
  }

  @autobind
  renderListItem(events) {
    return ({ key, index: rowIndex, style, parent }) => {
      const { currentTheme, intl } = this.props;
      const { incident, groupEventList } = this.state;
      const rowData = events[rowIndex];

      if (!rowData) return null;
      const active = rowData.id === incident?.id;
      const { isParent, isTimeRoot, dateEnFormat, startTimestamp, expandKey } = rowData;

      const dateData = R.find(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(groupEventList);
      const dateIdx = R.findIndex(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(
        groupEventList,
      );
      const rootEvent = R.find(R.propEq('expandKey', expandKey))(dateData?.events || []);
      const rootEventIdx = R.findIndex(R.propEq('expandKey', expandKey))(dateData?.events || []);

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div
            className="event-list-category-row flex-row"
            style={{ ...style, borderBottom: 'none', borderColor: 'transparent' }}
          >
            {isTimeRoot && (
              <div
                className="flex-row full-width"
                style={{
                  backgroundColor: currentTheme === 'dark' ? '#4b4a4a' : '#d0d0d0',
                  height: this.dateHeight - 1,
                  borderBottom: '1px solid transparent',
                  borderColor: 'var(--virtualized-table-border-color)',
                }}
                onClick={() => handleMergeCategoryDateClick(this, rowData)}
              >
                <div className="row-column flex-center-justify" style={{ width: 40 }} />
                <div
                  className="row-column font-14 bold"
                  style={{ width: 90 + 120 + 90 + 160 + 190, minWidth: 90 + 120 + 90 + 160 + 190, paddingRight: 16 }}
                >
                  {dateEnFormat}
                </div>
                <div className="row-column flex-grow" />
                <div className="row-column flex-center-justify break-word" style={{ width: 110 }}>
                  {rowData.count}
                </div>
                <div className="row-column" style={{ width: 70, padding: 0 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
              </div>
            )}
            {!isTimeRoot && isParent && (
              <div
                className="flex-row full-width"
                style={{
                  backgroundColor: 'var(--item-active-bg2)',
                  height: this.rowMinHeight - 1,
                  borderBottom: '1px solid transparent',
                  borderColor: 'var(--virtualized-table-border-color)',
                }}
                onClick={() => handleMergeCategoryParentClick({ self: this, dateIdx, rootEventIdx, rowData })}
              >
                <div
                  className="row-column flex-center-justify"
                  style={{ width: 40 }}
                  onClick={(e) => e.stopPropagation()}
                />
                <div className="row-column" style={{ width: 90, minWidth: 90 }} />
                <div className="row-column" style={{ width: 120, minWidth: 120, paddingRight: 16 }} />
                <div className="row-column" style={{ width: 90, minWidth: 90 }} />
                <div className="row-column" style={{ width: 160, paddingRight: 16, minWidth: 160 }}>
                  {patternRenderer(this, true, rowData, isParent)}
                </div>
                <div className="row-column" style={{ width: 190, paddingRight: 16, minWidth: 190 }}>
                  {impactedRender({ intl, rowData, isChild: false, instanceDisplayNameMap: {} })}
                </div>
                <div className="row-column" style={{ flex: 1, minWidth: 140 }} />
                <div
                  className="row-column"
                  style={{ width: 130, minWidth: 130, ...childrenBorder, paddingRight: 16 }}
                />
                <div className="row-column" style={{ width: 100 }} />
                <div className="row-column" style={{ minWidth: 95 }} />
                <div className="row-column flex-center-justify break-word" style={{ width: 110 }}>
                  {rowData.count}
                </div>
                <div className="row-column" style={{ width: 70, padding: 0 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
              </div>
            )}
            {!isTimeRoot && !isParent && (
              <div
                className={`event-list-row full-width ${active ? ' active' : ''}`}
                style={{
                  height: '100%',
                  borderBottom: '1px solid var(--virtualized-table-border-color)',
                  ...(active ? {} : { cursor: 'pointer' }),
                }}
                onClick={() => this.handleMergeCategoryClick(rowIndex, rowData, rootEvent)}
              >
                <div
                  className="row-column flex-center-justify"
                  style={{ width: 40, ...childrenBorder }}
                  onClick={(e) => e.stopPropagation()}
                />
                <div className="row-column" style={{ width: 90, minWidth: 90, paddingRight: 16 }}>
                  {timeRenderer(rowData)}
                </div>
                <div className="row-column" style={{ width: 120, minWidth: 120 }}>
                  {occurrenceTime(rowData)}
                </div>
                <div className="row-column" style={{ width: 90, minWidth: 90, paddingRight: 16 }}>
                  {CellRenderers.humanizeDuration({
                    period: rowData.leadTime,
                    intl: this.props.intl,
                    showSeconds: true,
                    showZero: true,
                    shortDisplay: true,
                    showZeroToOne: true,
                  })}
                </div>
                <div className="row-column" style={{ width: 160, paddingRight: 16, minWidth: 160 }} />
                <div className="row-column" style={{ width: 190, paddingRight: 16, minWidth: 190 }}>
                  {impactedRender({ intl, rowData, isChild: true, instanceDisplayNameMap: {} })}
                </div>
                <div className="row-column" style={{ flex: 1, paddingRight: 10, minWidth: 140 }}>
                  {rendererContent(rowData, this)}
                </div>
                <div className="row-column" style={{ width: 130, minWidth: 130, ...childrenBorder, paddingRight: 16 }}>
                  {this.rootCauseChainRenderer(rowData, rowIndex, active)}
                </div>
                <div className="row-column flex-wrap" style={{ width: 100, gap: 4 }}>
                  {statusChainRenderer(rowData, this)}
                </div>
                <div className="row-column" style={{ minWidth: 95 }}>
                  {this.statusContentRenderer(rowData)}
                </div>
                <div className="row-column flex-center-justify break-word" style={{ width: 110 }} />
                <div className="row-column" style={{ width: 70, padding: 0 }}>
                  {/* {this.confirmButtonRender(rowData)}
                  {this.ignoreButtonRender(rowData, rowIndex, rootEvent)} */}
                </div>
              </div>
            )}
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderTriggeredActions(rowData, index) {
    const { actionName, timestamp } = rowData;
    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {moment.utc(timestamp).format(Defaults.DateTimeFormat)}
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {actionName}
        </div>
      </div>
    );
  }

  render() {
    const { systemId, intl, jwtToken, selectedZone } = this.props;
    const { isLoadingParserData, errMessage, isShowPredictionSourceInfo, showFilterPanel, filterLoading } = this.state;
    const { hasTableData, chartOption, zoomStartTime, zoomEndTime, groupEventList, incident, activeKey } = this.state;

    let triggeredActionList = [];
    let validationTimeList = [];
    if (incident) {
      triggeredActionList = incident.triggeredActionList || [];
      validationTimeList = incident.validationTimeList || [];
    }

    const { eventOccurrenceList, predictionCount } = reducerHandleEvents(groupEventList);

    return (
      <Spin spinning={isLoadingParserData} wrapperClassName="full-width full-height spin-full-height">
        {errMessage && <Alert message="Warning" description={errMessage} type="warning" showIcon />}
        {!errMessage && (
          <>
            <div className={`full-height ${isShowPredictionSourceInfo ? 'display-none' : 'flex-col'}`}>
              <Container
                className="global-view flex-grow flex-row flex-min-height content-bg flex-min-width overflow-hidden corner-10"
                style={{ margin: '0 8px' }}
              >
                <div
                  className="flex-col full-height"
                  style={{
                    width: showFilterPanel ? 270 : 0,
                    padding: '8px 0 8px 8px',
                    position: 'relative',
                    flexShrink: 0,
                  }}
                >
                  <Tooltip
                    title={showFilterPanel ? 'Close filters' : 'Show filters'}
                    mouseEnterDelay={0.3}
                    placement="top"
                  >
                    <div
                      className="clickable"
                      style={{ position: 'absolute', right: -17, top: 5, fontSize: 18, color: 'gray', zIndex: 99 }}
                      onClick={() => {
                        this.setState({ showFilterPanel: !showFilterPanel });
                      }}
                    >
                      <RightSquareOutlined rotate={showFilterPanel ? 180 : 0} />
                    </div>
                  </Tooltip>
                  <Card
                    style={{ width: '100%', height: '100%', display: showFilterPanel ? 'unset' : 'none' }}
                    bodyStyle={{ height: '100%', padding: 8, overflowY: 'auto' }}
                  >
                    <Container className="flex-col flex-min-height flex-min-width full-height">
                      {renderTopBarRender({ self: this, isLoadingParserData, instanceDisplayNameMap: {} })}
                    </Container>
                  </Card>
                </div>
                <Container className="flex-grow block flex-row flex-min-height" style={{ padding: '8px 8px 0 0px' }}>
                  <Spin
                    spinning={isLoadingParserData || filterLoading}
                    wrapperClassName="full-width full-height spin-full-width"
                  >
                    {!isLoadingParserData && !hasTableData && (
                      <div className="flex-grow flex-row flex-center-align flex-center-justify content-bg">
                        <Empty
                          imageStyle={{ height: 200 }}
                          description={
                            <span className="flex-col">
                              <span style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 8 }}>
                                No results found
                              </span>
                              <span style={{ fontSize: 14 }}>Try adjusting the date range</span>
                            </span>
                          }
                        />
                      </div>
                    )}
                    {!isLoadingParserData && hasTableData && (
                      <div className="flex-col full-width flex-grow">
                        <div style={{ height: 130 }} className="log-entries-echart">
                          <AutoSizer>
                            {({ width, height }) => (
                              <EChart
                                setRef={(chart) => setChartRef(this.systemChartRef, systemId, chart)}
                                width={width}
                                height={height}
                                option={chartOption}
                                renderer="svg"
                                onEvents={{
                                  click: onSystemChartClick(this, systemId),
                                  datazoom: handleChartZoom(this, systemId),
                                  restore: handleChartRestore(this, systemId),
                                  rendered: handleChartFinish(this, systemId),
                                }}
                              />
                            )}
                          </AutoSizer>
                        </div>
                        <div className="flex-row flex-center-align" style={{ margin: '0 0 8px 8px' }}>
                          {zoomStartTime && zoomEndTime && (
                            <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                              <div style={{ marginRight: 8 }}>{`${moment
                                .utc(zoomStartTime)
                                .format(Defaults.ShortDateTimeFormat)} ~ ${moment
                                .utc(zoomEndTime - 10 * 60 * 1000)
                                .format(Defaults.ShortDateTimeFormat)}`}</div>
                              <div className="font-14 clickable" onClick={() => customizeRestore(this, systemId)}>
                                <CloseCircleOutlined />
                              </div>
                            </div>
                          )}
                          <div className="flex-grow" />
                        </div>
                        {tableViewList(true, this, intl, eventOccurrenceList, predictionCount, this.renderListItem)}
                      </div>
                    )}
                  </Spin>
                </Container>
              </Container>
            </div>
            {isShowPredictionSourceInfo && (
              <div className="flex-col full-height">
                <Container breadcrumb className="flex-row" style={{ marginBottom: 0 }}>
                  <div className="flex-row flex-center-align" style={{ width: 'fit-content' }}>
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={() => {
                        this.props.changeShowPredictionSourceInfo(false);
                        this.setState({ isShowPredictionSourceInfo: false });
                      }}
                    >
                      {intl.formatMessage(appMenusMessages.globalSystemPrediction)}
                    </Button>
                    <div style={{ marginLeft: 4 }}>{`/ ${intl.formatMessage(appFieldsMessages.details)}`}</div>
                  </div>
                </Container>
                <div
                  className="flex-grow flex-min-height flex-col content-bg flex-min-width corner-10"
                  style={{ margin: '0 16px', padding: 8 }}
                >
                  {incident && (
                    <Spin spinning={false} wrapperClassName="full-height spin-full-height">
                      <Tabs
                        type="card"
                        size="small"
                        className="full-height ant-tabs-content-full-height flex-col tabs-extra-full"
                        activeKey={activeKey}
                        onChange={(activeKey) => this.setState({ activeKey })}
                      >
                        <Tabs.TabPane
                          tab={intl.formatMessage(appFieldsMessages.incident)}
                          key="incident"
                          style={{ paddingTop: 8, border: '1px solid var(--border-color-split)', borderTop: 0 }}
                        >
                          <div className="flex-col full-height">
                            <div className="flex-col flex-grow overflow-hidden" style={{ paddingBottom: 8 }}>
                              <PredictionRootCauseRCA
                                incident={incident}
                                isJWT
                                jwtToken={jwtToken}
                                zoneName={selectedZone === '__all__' ? `all_zone_${systemId}` : selectedZone}
                              />
                            </div>
                          </div>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                          tab={
                            <div>
                              {intl.formatMessage(logMessages.triggeredActions)}
                              <Tag style={{ borderRadius: 100, marginLeft: 4, fontWeight: 'bold' }}>
                                {triggeredActionList.length}
                              </Tag>
                            </div>
                          }
                          className="overflow-y-auto"
                          key="triggeredActions"
                          style={{ paddingTop: 8, border: '1px solid var(--border-color-split)', borderTop: 0 }}
                        >
                          {triggeredActionList.length === 0 && (
                            <div style={{ marginBottom: 16 }}>
                              <Alert
                                message=""
                                description={intl.formatMessage(logMessages.noTriggeredActionsFound)}
                                type="info"
                                showIcon
                              />
                            </div>
                          )}
                          {triggeredActionList.length > 0 && (
                            <div className="flex-row" style={{ marginBottom: 4, lineHeight: '24px' }}>
                              <span style={{ fontSize: 14, fontWeight: 700 }}>
                                {intl.formatMessage(logMessages.triggeredActions)}:
                              </span>
                            </div>
                          )}
                          {triggeredActionList.length > 0 && (
                            <div className="event-list flex-grow flex-col flex-min-height" style={{ marginBottom: 16 }}>
                              <div
                                className="event-list-header"
                                style={{ height: this.listHeaderHeight, width: '100%' }}
                              >
                                <div className="header-column" style={{ width: 120, flex: 1 }}>
                                  {intl.formatMessage(eventActionMessages.triggeredTime)}
                                </div>
                                <div className="header-column" style={{ width: 120, flex: 1 }}>
                                  {intl.formatMessage(eventActionMessages.actionName)}
                                </div>
                              </div>
                              <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                                {R.addIndex(R.map)(
                                  (rowData, index) => this.renderTriggeredActions(rowData, index),
                                  triggeredActionList,
                                )}
                              </div>
                            </div>
                          )}
                          {validationTimeList.length > 0 && (
                            <div className="flex-row" style={{ marginBottom: 4, lineHeight: '24px' }}>
                              <span style={{ fontSize: 14, fontWeight: 700 }}>
                                {intl.formatMessage(logMessages.validationTime)}:
                              </span>
                            </div>
                          )}
                          {validationTimeList.length > 0 && (
                            <div className="event-list flex-grow flex-min-height flex-col" style={{ marginBottom: 16 }}>
                              <div
                                className="event-list-header"
                                style={{ height: this.listHeaderHeight, width: '100%' }}
                              >
                                <div className="header-column" style={{ width: 120, flex: 1 }}>
                                  {intl.formatMessage(appFieldsMessages.startTime)}
                                </div>
                                <div className="header-column" style={{ width: 120, flex: 1 }}>
                                  {intl.formatMessage(appFieldsMessages.endTime)}
                                </div>
                              </div>
                              <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                                {R.addIndex(R.map)((rowData, index) => {
                                  const { startTime, endTime } = rowData;
                                  return (
                                    <div
                                      key={index}
                                      className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                                      style={{ minHeight: 40 }}
                                    >
                                      <div className="row-column" style={{ width: 120, flex: 1 }}>
                                        {moment.utc(startTime).format(Defaults.DateTimeFormat)}
                                      </div>
                                      <div className="row-column" style={{ width: 120, flex: 1 }}>
                                        {moment.utc(endTime).format(Defaults.DateTimeFormat)}
                                      </div>
                                    </div>
                                  );
                                }, validationTimeList)}
                              </div>
                            </div>
                          )}
                        </Tabs.TabPane>
                      </Tabs>
                    </Spin>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {this.state.showRecommendationsGPT && (
          <RecommendationsGPT
            incident={this.state.activeRecommendation}
            incidentType="Prediction"
            title={intl.formatMessage(logMessages.predictionSummary)}
            onCancel={() => this.setState({ showRecommendationsGPT: false, activeRecommendation: null })}
            isJWT
            jwtToken={jwtToken}
            zoneName={selectedZone === '__all__' ? `all_zone_${systemId}` : selectedZone}
          />
        )}
      </Spin>
    );
  }
}

const EmbedPredictiontimeline = injectIntl(EmbedPredictiontimelineCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { dark } = parseLocation(location);
  if (dark) {
    state.app.currentTheme = 'dark';
  } else {
    state.app.currentTheme = 'light';
  }

  const { currentTheme } = state.app;
  return { location, currentTheme };
}, {})(EmbedPredictiontimeline);
