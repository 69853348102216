import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { Button, Collapse } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import { State } from '../../../common/types';
import { hideAppLoader, showAppLoader } from '../../../common/app/actions';
import { BaseUrls } from '../Constants';
import { AppCategories, AppManifests } from './AppManifests';
import { buildLocation, buildUrl, parseLocation } from '../../../common/utils';
import { IntegrationsMetadata } from '../../settings/project/dataSource';
import { Popover } from '../../../lib/fui/react';
import { appFieldsMessages } from '../../../common/app/messages';

type Props = {
  intl: Object,
  location: Object,
  userInfo: Object,
  credentials: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  systemsMap: Object,
  projects: Array<Object>,
  allProjects: Array<Object>,
  push: Function,
  replace: Function,
  hideAppLoader: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  showAppLoader: Function,
  userList: Array<Object>,
  currentTheme: string,
  timezoneOffset: Number,
  appFilter: string,
  onAppClick: Function,
};

class AppStoreCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { userInfo, appFilter } = props;
    this.needInvert = [
      'AWS CloudWatch',
      'DataDog',
      'AWS Cost',
      'AWS CloudWatch',
      'Apache Kafka Metric',
      'Apache Kafka Log',
      'Zendesk',
      'VMWare VCenter',
      'Sentry',
      'Snowflake performance monitoring',
      'Nagios',
      'MariaDB',
      'Microsoft SQL Server',
      'Collectd',
      'CA Technology',
      'VMWare VCenter',
      'Dynatrace',
    ];

    this.categoryOptions = [
      { value: 'Featured', label: 'Featured integrations' },
      // { value: 'All', label: 'All Categories' },
      // { value: 'APM', label: 'APM' },
      // { value: 'CI/CD', label: 'CI/CD' },
      // { value: 'Databases', label: 'Databases' },
      // { value: 'Incident Management', label: 'Incident Management' },
      // { value: 'Log Monitoring', label: 'Log Monitoring' },
      // { value: 'Event Monitoring', label: 'Event Monitoring' },
      // { value: 'Metric Monitoring', label: 'Metric Monitoring' },
      // { value: 'Microservices', label: 'Microservices' },
      // { value: 'Federated Learning', label: 'Federated Learning' },
      // { value: 'Custom', label: 'Custom' },
      // { value: 'Cost', label: 'Cost' },
    ];
    this.integrationsMetadata = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('display')))], IntegrationsMetadata);
    this.appManifests = R.filter((a) => {
      return a.roleChecker ? a.roleChecker(userInfo) : true;
    }, AppManifests);

    const integrationsMap = {};
    R.forEach((i) => {
      const { category = [] } = i;
      if (R.includes('Featured', category)) {
        R.forEach((c) => {
          if (integrationsMap[c]) {
            integrationsMap[c].push(i);
          } else {
            integrationsMap[c] = [i];
          }
        }, category);
      }
    }, this.integrationsMetadata);

    this.integrationsMap = integrationsMap;
    this.state = { ...this.filterApp(appFilter) };

    this.specialWidth = ['Dynatrace', 'Snowflake performance monitoring', 'Extrahop', 'VMWare VCenter'];
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { appFilter } = this.props;
    if (appFilter !== prevProps.appFilter) {
      this.setState({ ...this.filterApp(appFilter) });
    }
  }

  @autobind
  handleIntegrationAppClick(rowData) {
    const { push, onAppClick } = this.props;
    const { name, isBatch } = rowData;

    push(buildUrl(BaseUrls.SettingsProjectWizard, {}, { dataSource: name, batch: isBatch }));
    if (onAppClick) {
      onAppClick();
    }
  }

  @autobind
  handleAppClick(link) {
    const { push, onAppClick } = this.props;

    push(buildUrl(link));
    if (onAppClick) {
      onAppClick();
    }
  }

  @autobind
  filterApp(appFilter) {
    const integrationsMap = { ...this.integrationsMap };
    if (appFilter) {
      R.forEach((key) => {
        integrationsMap[key] = R.filter(
          (item) => R.toLower(item.display).indexOf(R.toLower(appFilter)) !== -1,
          integrationsMap[key],
        );
      }, R.keys(integrationsMap));
    }

    let { appManifests } = this;
    if (appFilter) {
      appManifests = R.filter((a) => {
        const { keywords = [] } = a;
        // no keywords means match all
        if (keywords.length === 0) return true;
        return R.find((k) => R.toLower(k).indexOf(R.toLower(appFilter)) !== -1, keywords);
      }, appManifests);
    }
    return { integrationsMap, appManifests };
  }

  render() {
    const { currentTheme, isReadUser, intl, location, push } = this.props;
    const query = parseLocation(location);
    const { integrationsMap = {}, appManifests = [] } = this.state;
    const isDark = currentTheme === 'dark';
    const hasApps = !!R.find((item) => item.category === 'apps', appManifests || []);
    const hasSetting = !!R.find((item) => item.category === 'settings', appManifests || []);
    let newAppCategories = AppCategories;
    if (!hasApps) {
      newAppCategories = R.filter((item) => item.name !== 'apps', newAppCategories || []);
    }
    if (!hasSetting) {
      newAppCategories = R.filter((item) => item.name !== 'settings', newAppCategories || []);
    }
    let hasIntegration = false;
    R.forEachObjIndexed((value, key) => {
      hasIntegration = hasIntegration || (value || []).length > 0;
    }, integrationsMap || {});

    return (
      <div>
        {!isReadUser && hasIntegration && (
          <div className="collapse-section">
            <Collapse
              defaultActiveKey={['1']}
              ghost
              expandIconPosition="right"
              bordered={false}
              expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
            >
              <Collapse.Panel
                header={
                  <div className="flex-row flex-center-align" style={{ marginRight: 36 }}>
                    <div style={{ marginRight: 14 }}>Integrations page (i.e., add new project page)</div>
                    <Button
                      size="small"
                      type="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        push(buildLocation(BaseUrls.SettingsIntegrations, {}, { customerName: query?.customerName }));
                      }}
                    >
                      {intl.formatMessage(appFieldsMessages.otherIntegrations)}
                    </Button>
                    <div className="flex-grow" />
                  </div>
                }
                key="1"
              >
                <div className="flex-col">
                  {R.map((c) => {
                    const { value, label } = c;
                    const items = integrationsMap[value] || [];
                    if (items.length > 0) {
                      return (
                        <div className="flex-col">
                          <div key={value} style={{ fontWeight: 'bold', margin: '12px 0' }}>
                            {label}
                          </div>
                          <div className="flex-grow flex-wrap flex-row" style={{ gap: 14 }}>
                            {R.map((item) => {
                              const { icon, display, shortDetails } = item;
                              return (
                                <div
                                  key={`${value}-${display}-${icon}`}
                                  className="corner-4 flex-col"
                                  style={{
                                    width: 'calc(24% - 2px)',
                                    height: 180,
                                    background: 'var(--card-background)',
                                    padding: '18px 12px',
                                  }}
                                  onClick={() => this.handleIntegrationAppClick(item)}
                                >
                                  <div
                                    className="text-center corner-4 flex-row flex-center-align flex-center-justify"
                                    style={{
                                      width: R.includes(display, this.specialWidth) ? 110 : 50,
                                      height: 50,
                                      background: 'var(--virtualized-table-header-bg)',
                                      flexShrink: 0,
                                    }}
                                  >
                                    <img
                                      alt={display}
                                      src={icon}
                                      style={{
                                        width: R.includes(display, this.specialWidth) ? 100 : 40,
                                        height: 40,
                                        objectFit: 'contain',
                                        filter: isDark && this.needInvert.includes(display) ? 'invert(1)' : 'none',
                                      }}
                                    />
                                  </div>
                                  <div className="font-14 bold" style={{ margin: '8px 0', flexShrink: 0 }}>
                                    {display}
                                  </div>
                                  <Popover
                                    content={<div style={{ maxWidth: 300 }}>{shortDetails}</div>}
                                    mouseEnterDelay={0.3}
                                  >
                                    <div className="overflow-hidden flex-grow hidden-line-with-ellipsis-three clickable">
                                      {shortDetails}
                                    </div>
                                  </Popover>
                                </div>
                              );
                            }, items)}
                          </div>
                        </div>
                      );
                    } else {
                      return <div key={value} />;
                    }
                  }, this.categoryOptions)}
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
        )}
        {R.map(
          (cat) => (
            <div className="collapse-section" key={cat.name}>
              <Collapse
                defaultActiveKey={[cat.name]}
                ghost
                expandIconPosition="right"
                bordered={false}
                expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
              >
                <Collapse.Panel header={cat.label} key={cat.name}>
                  <div className="flex-row flex-wrap clickable" style={{ gap: 14 }}>
                    {R.map(
                      (item) => {
                        const { name, icon, label, link, shortDetails } = item;
                        return (
                          <div
                            key={name}
                            className="corner-4 flex-col"
                            style={{
                              width: 'calc(24% - 2px)',
                              height: 180,
                              background: 'var(--card-background)',
                              padding: '18px 12px',
                            }}
                            onClick={() => this.handleAppClick(link)}
                          >
                            <div
                              className="text-center corner-4"
                              style={{
                                width: 50,
                                height: 50,
                                background: 'var(--virtualized-table-header-bg)',
                                flexShrink: 0,
                                fontSize: 34,
                              }}
                            >
                              {icon}
                            </div>
                            <div className="font-14 bold" style={{ margin: '8px 0', flexShrink: 0 }}>
                              {label}
                            </div>
                            <Popover
                              content={<div style={{ maxWidth: 300 }}>{shortDetails}</div>}
                              mouseEnterDelay={0.3}
                            >
                              <div className="overflow-hidden flex-grow hidden-line-with-ellipsis-three clickable">
                                {shortDetails}
                              </div>
                            </Popover>
                          </div>
                        );
                      },
                      R.filter((item) => item.category === cat.name, appManifests),
                    )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            </div>
          ),
          newAppCategories,
        )}
      </div>
    );
  }
}

const AppStore = injectIntl(AppStoreCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { isAdmin, isLocalAdmin, isReadUser } = state.auth.userInfo;
    const {
      loaderStatus,
      userList,
      loadStatus,
      systemsMap,
      projects,
      allProjects,
      globalInfo,
      currentTheme,
      timezoneOffset,
    } = state.app;
    return {
      location,
      loaderStatus,
      loadStatus,
      userInfo,
      credentials,
      userList,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      systemsMap,
      projects,
      allProjects,
      globalInfo,
      currentTheme,
      timezoneOffset,
    };
  },
  { push, replace, hideAppLoader, showAppLoader },
)(AppStore);
