/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import { autobind } from 'core-decorators';

import { withTooltip } from '../../../common/utils';
import NormalBehaviorTable from './NormalBehaviorTable';

type Props = {
  width: Number,
  height: Number,
  project: Object,
  queryResult: Object,
};

class NormalBehavior extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      view: 'table',
    };
  }

  @autobind
  handleChangeView(view) {
    return () => {
      this.setState({ view });
    };
  }

  render() {
    const { width, height, project, queryResult } = this.props;
    const { view } = this.state;

    return (
      <div className="flex-col">
        <div
          className="flex-row flex-center-align"
          style={{ flex: '0 0 32px', marginBottom: 4, borderBottom: '1px solid #ccc' }}
        >
          <div style={{ fontWeight: 500, fontSize: 14 }}>Metric Statistics</div>
          <div className="flex-grow" />
          {withTooltip(
            <div
              className={`ui basic button icon${view === 'chart' ? ' active' : ''}`}
              onClick={this.handleChangeView('chart')}
            >
              <i className="icon chart line" />
            </div>,
            'Chart View',
          )}
          {withTooltip(
            <div
              className={`ui basic button icon${view === 'table' ? ' active' : ''}`}
              onClick={this.handleChangeView('table')}
            >
              <i className="icon table" />
            </div>,
            'Table View',
          )}
        </div>
        {view === 'table' && (
          <NormalBehaviorTable width={width} height={height - 32} project={project} queryResult={queryResult} />
        )}
        {view === 'chart' && <div />}
      </div>
    );
  }
}
export default NormalBehavior;
