import React from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';

import { State } from '../../common/types';
import { createLoadAction } from '../../common/app/actions';
import { ActionTypes } from '../../common/usecase/actions';
import { parseLocation, buildLocation } from '../../common/utils';
import { Console } from '../../../artui/react';
import LiveAnalysisCharts from '../../../components/cloud/liveanalysis/LiveAnalysisCharts';

type Props = {
  intl: Object,
  push: Function,
  location: Object,
  createLoadAction: Function,

  bugDetails: Object,
};

class BugDetailsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.bugDetails !== this.props.bugDetails) {
      this.processData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { createLoadAction } = props;
    const params = parseLocation(props.location);
    const { fromUser, pdKey } = params;
    let { projectName } = params;
    projectName = projectName.indexOf('@') >= 0 ? projectName : `${projectName}@${fromUser}`;

    if (projectName && fromUser && pdKey) {
      createLoadAction(ActionTypes.LOAD_BUG_DETAILS, { projectName, fromUser, pdKey });
    }
  }
  @autobind
  processData(props) {}

  render() {
    const { data, eventsSummaryData, eventsGroupHighlights, debugData, timeRanking, freqRanking } = this.state;
    const params = parseLocation(this.props.location);
    let { projectName, modelName, pvalue, cvalue, modelType, bugId, latestDataTimestamp, caller } = params;
    if (projectName === '') {
      projectName = modelName;
    }

    const loading = false;

    return (
      <Console>
        <Console.Topbar logo={require('../../../images/InsightFinder_Horizontal_White.svg')}>
          <div className="topbar-text">
            {bugId && (
              <div className="title">
                Please view incident name / bug ID: <b>{bugId}</b>
                <br />
              </div>
            )}
            {!bugId && (
              <div className="title">
                Please view snapshot of email alert.<br />
              </div>
            )}
          </div>
        </Console.Topbar>
        <LiveAnalysisCharts
          projectName={projectName}
          data={data}
          isUseCaseDetails
          enablePublish
          eventsSummaryData={eventsSummaryData}
          eventsGroupHighlights={eventsGroupHighlights}
          debugData={debugData}
          latestDataTimestamp={latestDataTimestamp}
          isEmailAert={(caller || '').toLowerCase() === 'emailalert'}
          timeRanking={timeRanking}
          freqRanking={freqRanking}
          bugId={bugId}
          loading={loading}
        />
      </Console>
    );
  }
}

const BugDetails = injectIntl(BugDetailsCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { bugDetails } = state.usecase;
    return {
      location,
      loadStatus,
      bugDetails,
    };
  },
  { push, replace, createLoadAction },
)(BugDetails);
