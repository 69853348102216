import React from 'react';
import * as R from 'ramda';
import { get, isFunction } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { SaveOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';

import { Modal, Container } from '../../../lib/fui/react';
import { appButtonsMessages } from '../../../common/app/messages';

type Props = {
  system: Object,
  onConfirm: Function,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  incident: Object,
  isAdmin: Boolean,
  userName: String,
  activeKey: String,
};

class ProjectLogSelectorCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { system, incident, activeKey, isAdmin, userName } = props;
    const projectNameSet = get(system, ['projectNameSet'], []);
    let logProjects = R.filter((project) => project.dataType !== 'Metric', projectNameSet);
    logProjects = R.sortWith([R.descend(R.compose(R.toLower, R.prop('projectName')))], logProjects);

    if (incident && activeKey === 'incident') {
      const { projectOwner } = incident;
      let { projectName } = incident;
      projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
      const findIdx = R.findIndex((item) => projectName === item.projectNameReal, logProjects || []);
      if (findIdx !== -1) {
        const peojectOwnsTheIncident = (logProjects || [])[findIdx];
        const otherPeoject = R.remove(findIdx, 1, logProjects || []);
        logProjects = [{ ...peojectOwnsTheIncident, hasOwnsTheIncident: true }, ...otherPeoject];
      }
    }

    this.state = {
      allProjectName: logProjects.length > 0 ? logProjects[0].projectNameReal : '',
      logProjects,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    const { intl, onClose, onConfirm } = this.props;
    const { allProjectName, logProjects } = this.state;

    return (
      <Modal width={650} title="Select project" onCancel={() => onClose()} footer={null} visible maskClosable={false}>
        <Container className="flex-col content">
          <div className="flex-row flex-center-align" style={{ marginBottom: 24 }}>
            <span className="label" style={{ width: 150, fontWeight: 'bold', marginLeft: 20 }}>
              Log projects:
            </span>
            <Select
              style={{ width: 200 }}
              value={allProjectName}
              onChange={(allProjectName) => this.setState({ allProjectName })}
              allowClear
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 450 }}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.projectNameReal}>{`${item.projectDisplayName}${
                    item.hasOwnsTheIncident ? ' (Incident project)' : ''
                  }`}</Select.Option>
                ),
                logProjects || [],
              )}
            </Select>
          </div>
          <div className="flex-row">
            <div className="flex-grow" />
            <Button
              type="primary"
              size="small"
              icon={<SaveOutlined />}
              onClick={() => (isFunction(onConfirm) ? onConfirm(allProjectName, logProjects) : null)}
            >
              {intl.formatMessage(appButtonsMessages.confirm)}
            </Button>
          </div>
        </Container>
      </Modal>
    );
  }
}

const ProjectLogSelector = injectIntl(ProjectLogSelectorCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { isAdmin, userName } = state.auth.userInfo;
    return {
      location,
      isAdmin,
      userName,
    };
  },
  { replace },
)(ProjectLogSelector);
