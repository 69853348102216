/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
import * as R from 'ramda';
import { get } from 'lodash';
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo, showAppAlert } from '../../app/actions';
import { getLoadStatusActions, ifIn } from '../../utils';
import { getInstanceMetaData } from '../../apis';
import { appMessages } from '../../app/messages';

const loadMetricEventLineChartsInstanceMetaDataLogic = createLogic({
  type: [ActionTypes.LOAD_METRIC_EVENT_LINECHARTS_INSTANCE_META_DATA],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,

  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { eventLineChartData } = state.metric;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    const {
      projectName,
      instanceGroup,
      isContainerProj,

      metricDatasetMap,
      instancePageIndex,
      selectInstanceList,
      selectMetricList,
    } = params;

    const queryList = [
      getInstanceMetaData(credentials, {
        projectName,
        instanceGroup,
        isContainerProj,
        idList: JSON.stringify(selectInstanceList),
      }),
    ];
    Promise.all(queryList)
      .then((results) => {
        // const appNameMapping = get(results[0], ['appNameMapping'], {});
        const metricObj = get(results[0], ['metricObj'], {});
        let allMetricList = get(results[0], ['metricList'], []);

        // merge old instance info and new instance info
        const metricUnitMapping = get(eventLineChartData, 'metricUnitMapping', {});
        const anomalyInstanceMetricMap = get(eventLineChartData, 'anomalyInstanceMetricMap', {});
        const prevAllMetricList = get(eventLineChartData, 'allMetricList', []);
        const prevMetricObj = get(eventLineChartData, 'metricObj', {});

        const anomalyMetricByInstances = R.uniq(
          R.reduce(R.concat, [], R.values(R.pick(selectInstanceList, anomalyInstanceMetricMap))),
        );

        allMetricList = [...allMetricList, ...prevAllMetricList];
        allMetricList = R.sort((a, b) => {
          return a.localeCompare(b);
        }, R.uniq(R.filter((x) => Boolean(x), allMetricList)));
        allMetricList = [
          ...R.filter((name) => ifIn(name, anomalyMetricByInstances), allMetricList),
          ...R.filter((name) => !ifIn(name, anomalyMetricByInstances), allMetricList),
        ];
        const allMetricInfoList = R.map((metric) => {
          const minfo = metricUnitMapping[metric] || {};
          let shortValue = metric;
          if (metric.indexOf('/') >= 0) {
            shortValue = R.takeLast(1, R.split('/', metric));
          }
          shortValue = `${R.take(8, shortValue)}${shortValue.length > 8 ? '..' : ''}`;
          return { id: metric, name: metric, shortValue, shortName: minfo.shortMetric, unit: minfo.unit || '' };
        }, allMetricList);

        const filterMetricList = R.uniq(
          R.reduce(
            R.concat,
            [],
            R.map((ci) => get(metricObj, ci, []), selectInstanceList),
            // R.map((ci) => get(metricObj, ci, []), R.map((i) => GlobalParse.getContainerInstanceId(i), instanceList)),
          ),
        );
        const filterMetricInfoList = R.filter((m) => filterMetricList.indexOf(m.id) >= 0, allMetricInfoList);
        const newMetricList = R.filter((m) => filterMetricList.includes(m), selectMetricList);

        const newLineChartData = {
          ...(eventLineChartData || {}),
          // use project appNameMapping by default
          // appNameMapping: { ...get(eventLineChartData, 'appNameMapping', {}), ...appNameMapping },
          anomalyMetricByInstances,

          metricObj: { ...prevMetricObj, ...metricObj },
          allMetricList,
          allMetricInfoList,
          filterMetricInfoList,

          // reset default data
          metricDatasetMap,
          instancePageIndex,
          selectInstanceList,
          metricPageIndex: 1,
          selectMetricList: newMetricList,
        };
        dispatch(createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS, params, newLineChartData));
      })
      .catch((err) => {
        console.error('[IF_API] Failed get linechart inactive instance info', err);
        dispatch(hideLoading);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message, error: err }));
        dispatch(showAppAlert('error', appMessages.errorsAPIMessage, { message: err.message, error: err }));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }

        dispatch(hideLoading);
        done();
      });
  },
});

export default loadMetricEventLineChartsInstanceMetaDataLogic;
