/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import ReactDOMServer from 'react-dom/server';
import { replace } from 'react-router-redux';
import { get, floor, isFunction } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { PauseOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Spin, Alert, Input, Tooltip, Card, Empty, Button, Tabs } from 'antd';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { Container, AutoSizer, Modal } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { LogRenderers } from '../../../common/utils';

import { EChart } from '../../share';
import { appFieldsMessages } from '../../../common/app/messages';
import fetchGet from '../../../common/apis/fetchGet';

type Props = {
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  instanceName: String,
  // eslint-disable-next-line
  startTimestamp: Number,
  // eslint-disable-next-line
  endTimestamp: Number,
  // eslint-disable-next-line
  clusterInfo: Object,
  // eslint-disable-next-line
  clusterActivePatternChange: Number,
  // eslint-disable-next-line
  handleFeatureKeywordChange: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  replace: Function,
  onShowAllInstance: Function,
  renderKeywordFilter: Function,
};

const DragHandle = sortableHandle(({ intl }) => (
  <Tooltip title={intl.formatMessage(appFieldsMessages.dragAndDrop)} placement="top" mouseEnterDelay={0.3}>
    <div
      style={{ width: 20, height: 20, cursor: 'move', display: 'inline-block' }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <PauseOutlined style={{ transform: 'rotate(90deg)', fontSize: 16, cursor: 'move' }} />
    </div>
  </Tooltip>
));

const SortableList = SortableContainer(({ children }) => {
  return <>{children}</>;
});

const SortableItem = SortableElement(({ children }) => {
  return <>{children}</>;
});

class LogEntriesFeatureChartCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      errorMessage: null,

      featureToNidMapInfo: {},
      keyEntry: {},
      fieldFilter: '',
      fieldSearch: '',
      keywordFilter: '',
      keywordSearch: '',
      allPieChart: [],

      showAllInstance: false,
      showPieChartArea: false,

      activeKey: '1',
      localCdfDataListMap: {},
      cdfDataListMap: {},
      scoreKeyFilter: '',
    };
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.clusterActivePatternChange !== nextProps.clusterActivePatternChange) {
      this.reloadData(nextProps, true);
    }
  }

  @autobind
  parseItemData(rowData, scoreKey) {
    const { cdfData } = rowData || {};
    const cdfSeriesData = R.map((item) => item.value, R.sortWith([R.ascend(R.prop('level'))])(cdfData || []));
    const xAxisData = R.map((item) => item.label, R.sortWith([R.ascend(R.prop('level'))])(cdfData || []));
    const option = {
      color: ['#3878A1'],
      backgroundColor: 'transparent',
      xAxis: {
        type: 'category',
        data: xAxisData,
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: { textStyle: { color: 'gray' } },
      },
      yAxis: {
        type: 'value',
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: { textStyle: { color: 'gray' } },
      },
      grid: { left: 34, right: 30, top: 10, bottom: 30 },
      // legend: { textStyle: { color: 'gray' } },
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        confine: true,
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          return ReactDOMServer.renderToStaticMarkup(
            <>
              {R.addIndex(R.map)((item, idx) => {
                const { seriesName, color, value, name } = item;
                return (
                  <div key={idx}>
                    <div>{name}</div>
                    <span
                      style={{
                        display: 'inline-block',
                        marginRight: 5,
                        borderRadius: 10,
                        width: 9,
                        height: 9,
                        backgroundColor: color,
                      }}
                    />
                    {`${seriesName}: ${Number(Number.parseFloat(Math.abs(value || 0)).toFixed(2))}`}
                  </div>
                );
              }, params || [])}
            </>,
          );
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      series: [
        {
          name: 'Current data',
          type: 'line',
          smooth: false,
          data: cdfSeriesData,
          lineStyle: { width: 3 },
          symbolSize: 6,
        },
      ],
    };
    return { ...rowData, option, scoreKey };
  }

  @autobind
  reloadData(props, force) {
    const { credentials, projectName, instanceName, startTimestamp, endTimestamp, clusterInfo, projects } = props;
    if (projectName && instanceName && clusterInfo) {
      this.setState({ isLoading: true });
      const { isAllPattern, patternId } = clusterInfo || {};
      const startTimestampList = R.map(
        (i) => startTimestamp + i * 86400000,
        R.range(0, (endTimestamp + 1 - startTimestamp) / 86400000),
      );

      const requests = [
        fetchGet(getEndpoint('fetchcdfdata'), {
          ...credentials,
          projectName,
          instanceName,
          pageSize: 200000000,
          pageNo: 1,
          timestamp: startTimestamp,
        }),
      ];
      R.forEach((dayTimeMillis) => {
        requests.push(
          fetchPost(getEndpoint('fetchfeaturekeyword'), {
            ...credentials,
            projectName,
            instanceName,
            dayTimeMillis,
            nid: isAllPattern ? null : patternId,
          }),
        );
      }, startTimestampList);
      props.updateLastActionInfo();
      Promise.all(requests)
        .then((res) => {
          const { success } = res[0];
          const newCdfDataListMap = {};
          if (success || success === undefined) {
            R.forEachObjIndexed((data, scoreKey) => {
              let cdfData = [];
              cdfData = R.map((item) => this.parseItemData(item, scoreKey), data || []);
              cdfData = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('group')))], cdfData);
              if (cdfData.length > 0) {
                newCdfDataListMap[scoreKey] = cdfData;
              }
            }, res[0] || {});
          }

          // merge values from multiple days
          const results = R.slice(1, Infinity, res);
          const featureToNidMapInfo = {};
          const mergeMap = {};
          R.forEach((data) => {
            R.forEachObjIndexed((val) => {
              R.forEach((item) => {
                // merge featureToNidMapInfo: featureLabel -> featureValue -> timestamp -> nids
                const { patternIdMap, featureLabel, featureValue, histogram, numericHistogram, numericInfo, ...rest } =
                  item;
                if (!R.has(featureLabel, featureToNidMapInfo)) {
                  featureToNidMapInfo[featureLabel] = {};
                }
                if (!R.has(featureValue, featureToNidMapInfo[featureLabel])) {
                  featureToNidMapInfo[featureLabel][featureValue] = {};
                }
                featureToNidMapInfo[featureLabel][featureValue] = {
                  ...featureToNidMapInfo[featureLabel][featureValue],
                  ...(patternIdMap || {}),
                };

                // merge multiple days data by featureLabel
                const { distribution, interval, max, min } = numericInfo || {};
                if (!R.has(featureLabel, mergeMap)) {
                  mergeMap[featureLabel] = {};
                }
                if (!R.has(featureValue, mergeMap[featureLabel])) {
                  mergeMap[featureLabel][featureValue] = {
                    featureLabel,
                    featureValue,
                    histogram,
                    numericHistogram,
                    numericInfo: item.isNumeric ? { distribution, interval, max, min } : undefined,
                    ...rest,
                  };
                } else {
                  mergeMap[featureLabel][featureValue].histogram = {
                    ...(mergeMap[featureLabel][featureValue].histogram || {}),
                    ...(histogram || {}),
                  };
                  if (item.isNumeric) {
                    mergeMap[featureLabel][featureValue].numericHistogram = R.mergeWith(
                      R.mergeRight,
                      mergeMap[featureLabel][featureValue].numericHistogram || {},
                      numericHistogram || {},
                    );
                    mergeMap[featureLabel][featureValue].numericInfo = {
                      distribution: R.mergeWith(
                        R.add,
                        distribution,
                        get(mergeMap[featureLabel][featureValue].numericInfo, 'distribution', {}),
                      ),
                      interval,
                      max: R.max(max, get(mergeMap[featureLabel][featureValue].numericInfo, 'max', 0)),
                      min: R.min(min, get(mergeMap[featureLabel][featureValue].numericInfo, 'min', 0)),
                    };
                  }
                }
              }, val);
            }, data);
          }, results);

          // build keyword map
          let keyEntry = {};
          R.forEachObjIndexed((valLabel, featureLabel) => {
            R.forEachObjIndexed((valValue) => {
              const { isNumeric } = valValue;
              const key = `${featureLabel}-${isNumeric}`;
              if (!R.has(key, keyEntry)) {
                keyEntry[key] = [];
              }
              keyEntry[key].push(valValue);
            }, valLabel);
          }, mergeMap);
          keyEntry = R.mapObjIndexed((val) => {
            const newVal = R.map((item) => {
              const { isNumeric, histogram, numericInfo } = item;
              const { distribution } = numericInfo || {};
              const count = isNumeric ? R.sum(R.values(distribution || {})) : R.sum(R.values(histogram || {}));
              return {
                ...item,
                count,
              };
            }, val);
            return newVal;
          }, keyEntry);

          // parse chart
          const { pieInfos, numericChartInfos } = this.parsePieData(keyEntry);

          const project = R.find((project) => project.projectName === projectName, projects);
          const { isHolistic } = project || {};

          const allPieChart = [...numericChartInfos, ...pieInfos];

          this.setState(
            {
              isLoading: false,
              errorMessage: null,
              featureToNidMapInfo,
              keyEntry,
              allPieChart,
              showAllInstance: isHolistic,
              showPieChartArea: !R.isEmpty(allPieChart),
              localCdfDataListMap: newCdfDataListMap,
              cdfDataListMap: newCdfDataListMap,
              activeKey: R.isEmpty(allPieChart) ? '2' : '1',
              scoreKeyFilter: '',
            },
            () => {
              setTimeout(() => {
                this.forceUpdate();
              }, 100);
            },
          );
        })
        .catch((err) => {
          this.setState(
            {
              isLoading: false,
              errorMessage: err,
              featureToNidMapInfo: {},
              keyEntry: {},
              allPieChart: [],
              showPieChartArea: false,
              localCdfDataListMap: {},
              cdfDataListMap: {},
            },
            () => {
              setTimeout(() => {
                this.forceUpdate();
              }, 100);
            },
          );
        });
    }
  }

  @autobind
  parsePieData(keyEntry) {
    const { fieldSearch, keywordSearch } = this.state;

    // build chart options
    let pieInfos = [];
    let numericChartInfos = [];
    R.forEachObjIndexed((data) => {
      const { featureLabel, name } = data[0] || {};

      let showPie = true;
      // label filter item
      if (fieldSearch) {
        if (R.toLower(featureLabel).indexOf(R.toLower(fieldSearch)) === -1) {
          showPie = false;
        }
      }
      if (keywordSearch) {
        showPie = false;
        data = R.map((item) => {
          if (R.toLower(item.featureValue).indexOf(R.toLower(keywordSearch)) >= 0) {
            if (!showPie) showPie = true;
            return item;
          }
          return { ...item, hide: true };
        }, data);
      }

      if (showPie) {
        // sepicel number value
        let isNumber = false;
        R.forEach((item) => {
          isNumber = isNumber || item.isNumeric;
        }, data);

        if (isNumber) {
          R.forEach((item) => {
            const { numericInfo } = item;
            const { max, min } = numericInfo;
            const option = LogRenderers.GetNumericChartOption(featureLabel, item);
            numericChartInfos.push({
              featureLabel,
              option,
              max,
              min,
              name: name && !R.isEmpty(name) ? name : undefined,
              type: 'numberIc',
            });
          }, data);
        } else {
          const option = LogRenderers.GetPieOption(featureLabel, data);
          pieInfos.push({
            featureLabel,
            option,
            name: name && !R.isEmpty(name) ? name : undefined,
            type: 'pieInfo',
          });
        }
      }
    }, keyEntry);

    pieInfos = R.sortWith([R.ascend(R.prop('featureLabel'))], pieInfos);
    numericChartInfos = R.sortWith([R.ascend(R.prop('featureLabel'))], numericChartInfos);
    return { pieInfos, numericChartInfos };
  }

  @autobind
  handleFieldSearchChange(fieldSearch) {
    this.setState({ fieldSearch }, () => {
      const { keyEntry } = this.state;
      // parse chart
      const { pieInfos, numericChartInfos } = this.parsePieData(keyEntry);
      this.setState({ allPieChart: [...numericChartInfos, ...pieInfos] });
    });
  }

  @autobind
  handleKeywordSearchChange(keywordSearch) {
    this.setState({ keywordSearch }, () => {
      const { keyEntry } = this.state;
      // parse chart
      const { pieInfos, numericChartInfos } = this.parsePieData(keyEntry);
      this.setState({ allPieChart: [...numericChartInfos, ...pieInfos] });
    });
  }

  // @autobind
  handleGroupSearchChange(scoreKeyFilter) {
    this.setState({ scoreKeyFilter }, () => {
      const { localCdfDataListMap } = this.state;
      let keys = R.keys(localCdfDataListMap || {});
      keys = R.filter((item) => R.toLower(item || '').includes(R.toLower(scoreKeyFilter || '')), keys);
      const newCdfDataListMap = {};
      R.forEach((item) => {
        newCdfDataListMap[item] = localCdfDataListMap[item];
      }, keys);
      this.setState({ cdfDataListMap: newCdfDataListMap });
    });
  }

  @autobind
  onChartClick() {
    return (item, pie) => {
      if (pie.dispatchAction) {
        const { seriesIndex, dataIndex } = item;
        pie.dispatchAction({ type: 'downplay' });
        pie.dispatchAction({ type: 'highlight', seriesIndex, dataIndex });
      }
      const { handleFeatureKeywordChange } = this.props;
      if (isFunction(handleFeatureKeywordChange)) {
        const { featureToNidMapInfo, keyEntry } = this.state;
        const { data } = item;
        const { isJson, isNumeric, name, interval, featureLabel, featureValue } = data;
        const key = `${featureLabel}-${isNumeric || false}`;
        const valList = get(keyEntry, key, []);
        const valObj = R.find((item) => item.featureValue === featureValue, valList);
        const featureFreqVector = isNumeric
          ? get(valObj, ['numericHistogram', name], {})
          : get(valObj, 'histogram', {});
        handleFeatureKeywordChange({
          featureFreqVector,
          featureToNidMapInfo,
          featureInfo: {
            featureIsJson: isJson,
            featureLabel,
            featureValue,
            featureIsNumber: isNumeric,
            featureInterval: interval,
            featurePosition: name,
          },
        });
      }
    };
  }

  @autobind
  onSortEnd({ oldIndex, newIndex, collection, isKeySorting }) {
    const { allPieChart } = this.state;
    this.setState({
      allPieChart: R.move(oldIndex, newIndex, R.clone(allPieChart)),
    });
  }

  @autobind
  renderNumericChartInfos(pie, width) {
    return (
      <Card
        size="small"
        title={
          <div className="flex-row flex-center-align">
            <Tooltip title={pie.featureLabel} placement="top" mouseEnterDelay={0.3}>
              <span className="max-width hidden-line-with-ellipsis inline-block font-14 bold">
                {pie.name || pie.featureLabel}
              </span>
            </Tooltip>
          </div>
        }
        style={{ width: '100%', cursor: 'default', borderLeft: 'none', borderRight: 'none' }}
        hoverable
        extra={<DragHandle intl={this.props.intl} />}
      >
        <div>Max: {numeral(pie.max).format(pie.max > 1 ? '0,0' : '0.[000000]')}</div>
        <div>Min: {numeral(pie.min).format(pie.min > 1 ? '0,0' : '0.[000000]')}</div>
        <EChart height={180} option={pie.option} onClick={this.onChartClick(pie.featureWord)} />
      </Card>
    );
  }

  @autobind
  renderPieInfos(pie, width) {
    return (
      <Card
        size="small"
        title={
          <div className="flex-row flex-center-align">
            <Tooltip title={pie.featureLabel} placement="top" mouseEnterDelay={0.3}>
              <span className="max-width hidden-line-with-ellipsis inline-block font-14 bold">
                {pie.name || pie.featureLabel}
              </span>
            </Tooltip>
          </div>
        }
        style={{ width: '100%', cursor: 'default', borderLeft: 'none', borderRight: 'none' }}
        hoverable
        extra={<DragHandle intl={this.props.intl} />}
      >
        <div style={{ height: 18 * 2 }} />
        <EChart height={180} option={pie.option} onClick={this.onChartClick(pie.featureWord)} />
      </Card>
    );
  }

  @autobind
  renderListItem({ allPieChart, width, height }) {
    const noData = R.isEmpty(allPieChart) || !allPieChart;
    return (
      <div className="flex-col" style={{ width, height }}>
        {noData && <Empty />}
        {!noData &&
          R.addIndex(R.map)((item, index) => {
            const { type } = item;
            const isNumberIc = type === 'numberIc';
            return (
              <SortableItem key={`pirChart${index}`} index={index}>
                <div
                  style={{
                    width,
                    minWidth: 170,
                    height: 280,
                  }}
                >
                  {isNumberIc && this.renderNumericChartInfos(item, width)}
                  {!isNumberIc && this.renderPieInfos(item, width)}
                </div>
              </SortableItem>
            );
          }, allPieChart)}
      </div>
    );
  }

  render() {
    const { renderKeywordFilter } = this.props;
    const { isLoading, errorMessage, fieldFilter, keywordFilter, allPieChart, showAllInstance, showPieChartArea } =
      this.state;
    const { activeKey, scoreKeyFilter, cdfDataListMap, localCdfDataListMap } = this.state;

    return (
      (showPieChartArea || R.keys(localCdfDataListMap || {}).length > 0) && (
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <Tabs
            className="full-height ant-tabs-content-full-height"
            tabBarStyle={{ width: 240, marginLeft: 8, marginRight: 8, flexShrink: 0 }}
            tabPosition="top"
            type="card"
            destroyInactiveTabPane
            activeKey={activeKey}
            onChange={(activeKey) => this.setState({ activeKey })}
          >
            {showPieChartArea && (
              <Tabs.TabPane tab="Extracted feature" key="1" className="ui overflow-y-auto">
                <div
                  className="full-height"
                  style={{ width: 240, marginLeft: 8, border: '1px solid var(--border-color-base)' }}
                >
                  <Container className="full-width full-height flex-col ">
                    {errorMessage && (
                      <div className="full-width full-height" style={{ padding: 16 }}>
                        <Alert type="error" message={String(errorMessage)} showIcon />
                      </div>
                    )}

                    {!errorMessage && (
                      <div className="full-width full-height flex-col">
                        <div className="flex-col" style={{ padding: '0 8px 8px 8px' }}>
                          {showAllInstance && (
                            <div className="flex-row flex-center-align full-width flex-space-between">
                              All instance features:
                              <Button
                                icon={<FullscreenOutlined />}
                                size="small"
                                onClick={this.props.onShowAllInstance}
                              />
                            </div>
                          )}
                          {renderKeywordFilter()}
                          <Input.Search
                            size="small"
                            placeholder="Field search"
                            style={{ width: 'auto', marginTop: 6 }}
                            value={fieldFilter}
                            allowClear
                            onSearch={(value) => this.handleFieldSearchChange(value)}
                            onChange={(e) => this.setState({ fieldFilter: e.target.value })}
                          />
                          <Input.Search
                            size="small"
                            placeholder="Value search"
                            style={{ width: 'auto', marginLeft: 0, marginTop: 6 }}
                            value={keywordFilter}
                            allowClear
                            onSearch={(value) => this.handleKeywordSearchChange(value)}
                            onChange={(e) => this.setState({ keywordFilter: e.target.value })}
                          />
                        </div>
                        <div className="flex-col flex-min-height overflow-y-auto overflow-x-hidden full-width full-height">
                          <AutoSizer>
                            {({ width, height }) => (
                              <SortableList
                                axis="y"
                                useDragHandle
                                helperClass="sortableHelperAntModal"
                                onSortEnd={(params) => this.onSortEnd(params)}
                              >
                                {this.renderListItem({ allPieChart, width, height })}
                              </SortableList>
                            )}
                          </AutoSizer>
                        </div>
                      </div>
                    )}
                  </Container>
                </div>
              </Tabs.TabPane>
            )}
            {R.keys(localCdfDataListMap || {}).length > 0 && (
              <Tabs.TabPane tab="CDF graph" key="2" className="ui overflow-y-auto">
                <div
                  className="full-height flex-col"
                  style={{ width: 240, marginLeft: 8, border: '1px solid var(--border-color-base)' }}
                >
                  <div className="flex-col" style={{ padding: '0 8px 8px 8px' }}>
                    <Input.Search
                      size="small"
                      placeholder="Score key search"
                      style={{ width: 'auto', marginTop: 6 }}
                      value={scoreKeyFilter}
                      allowClear
                      onSearch={(value) => this.handleGroupSearchChange(value)}
                      onChange={(e) => this.setState({ scoreKeyFilter: e.target.value })}
                    />
                  </div>
                  <div className="full-width full-height overflow-y-auto">
                    {R.addIndex(R.map)((scoreKey, index) => {
                      return (
                        <div
                          key={`${scoreKey}-${index}`}
                          style={{
                            paddingTop: index === 0 ? 0 : 8,
                            marginBottom: 8,
                            borderTop: index === 0 ? 'none' : '2px dashed var(--virtualized-table-border-color)',
                          }}
                        >
                          <div className="flex-row flex-center-align">
                            <div className="font-14" style={{ marginRight: 8, flexShrink: 0 }}>
                              Score key:
                            </div>
                            <div className="font-14 hidden-line-with-ellipsis">{scoreKey}</div>
                          </div>
                          {R.addIndex(R.map)((item, idx) => {
                            const { group, option } = item;
                            return (
                              <div
                                key={`${scoreKey}-${idx}-${group}`}
                                className="full-width flex-col"
                                style={{ height: 200, flexShrink: 0, marginTop: idx === 0 ? 0 : 16 }}
                              >
                                <div className="flex-row flex-center-align" style={{ marginTop: 8, marginLeft: 8 }}>
                                  <div style={{ marginRight: 8, flexShrink: 0 }}>Group:</div>
                                  <div className="hidden-line-with-ellipsis">{group}</div>
                                </div>
                                <div className="flex-grow" style={{ marginTop: 8 }}>
                                  <EChart width="100%" height="100%" option={option} />
                                </div>
                              </div>
                            );
                          }, cdfDataListMap[scoreKey] || [])}
                        </div>
                      );
                    }, R.keys(cdfDataListMap || {}))}
                  </div>
                </div>
              </Tabs.TabPane>
            )}
          </Tabs>
        </Spin>
      )
    );
  }
}

const LogEntriesFeatureChart = injectIntl(LogEntriesFeatureChartCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    const { credentials } = state.auth;

    return {
      location,
      loadStatus,
      credentials,
      projects: R.filter((project) => !project.isMetric, projects),
    };
  },
  { updateLastActionInfo, replace },
)(LogEntriesFeatureChart);
