import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as R from 'ramda';
import tinygradient from 'tinygradient';
import { Input, message, Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { EChart } from '../../share';
import { buildLocation, Defaults, getRegExp, parseLocation } from '../../../common/utils';

import { appFieldsMessages } from '../../../common/app/messages';
import { AutoSizer } from '../../../lib/fui/react';

const getMonthStrList = (data) => {
  return R.compose(
    R.uniq(),
    R.map((item) => moment.utc(Number(item)).format(Defaults.MonthFormat)),
  )(R.keys(data || {}));
};

const getSectionTime = (startDate, endDate) => {
  const startTime = moment.utc(startDate).startOf('month').valueOf();
  let endTime = moment
    .utc(endDate || startDate)
    .endOf('month')
    .valueOf();
  const nowTimestamp = moment.utc().endOf('days').valueOf();
  if (endTime > nowTimestamp) {
    endTime = nowTimestamp;
  }
  return { startTime, endTime };
};

const getOption = (data, selectMonth, systemLists, searchSystemName) => {
  const { startTime, endTime } = getSectionTime(selectMonth);

  const regex = getRegExp(searchSystemName);
  data = R.mapObjIndexed((values, day) => {
    return R.filter((item) => {
      return regex ? regex.test(item.systemName) : false;
    }, values || []);
  }, data || []);

  const xAxisData = R.compose(
    R.map((item) => moment.utc(Number(item)).format(Defaults.DateFormat)),
    R.filter((item) => Number(item) >= startTime && Number(item) <= endTime),
  )(R.keys(data || {}));

  let filterSameMonth = {};
  let legendList = [];
  R.forEachObjIndexed((val, key) => {
    R.forEach((item) => {
      legendList.push(item.systemName);
    }, val || []);
    if (Number(key) >= startTime && Number(key) <= endTime) {
      const value = R.map((item) => ({ ...item, value: item.logEventCount }), val || []);
      filterSameMonth = { ...filterSameMonth, [key]: value };
    }
  }, data || {});
  legendList = R.uniq(legendList);

  // 把返回的system在当前用户没有的添加进去
  const newSystemLists = systemLists || [];
  R.forEach((item) => {
    if (!R.find((val) => val.systemName === item, systemLists || [])) {
      newSystemLists.push({ systemName: item });
    }
  }, legendList);

  // 设置颜色
  const colors = [
    '#ad4c34',
    '#df9954',
    '#744a74',
    '#547ec9',
    '#D37387',
    '#83d4de',
    '#1D7324',
    '#e095e0',
    '#68B78C',
    '#ae9be3',

    '#ee8e76',
    '#7e93b7',
    '#6fc876',
    '#a8d6b7',
    '#d9e446',
    '#ca87ca',
    '#4e6da5',
    '#C35989',
    '#918ADF',
    '#D78742',

    '#B9541A',
    '#E9A133',
    '#3878A1',
    '#A3416E',
    '#F1AEA4',
    '#8BCDBC',
    '#399561',
    '#A4D8A8',
    '#764EA2',
    '#B2ADED',

    '#BC5B22',
    '#EEB46C',
    '#3E769E',
    '#ABC4A2',
    '#3B925D',
    '#B2CB9A',
    '#6565C5',
    '#B4B4F2',
    '#98366D',
    '#DD86AF',

    '#FFC940',
    '#FFEEC5',
    '#96ec9d',
    '#E8F8B6',
    '#D1E1FF',
    '#dab45a',
    '#6B9FA1',
    '#FFE39F',
    '#ddd8cc',
    '#E1BAE1',

    // '#20B2AA'
  ];
  const systemColor = {};
  if (newSystemLists.length <= colors.length) {
    R.addIndex(R.forEach)((item, index) => {
      systemColor[item.systemName] = colors[index];
    }, newSystemLists || []);
  } else {
    const statistics = {};
    R.forEachObjIndexed((val, key) => {
      R.forEach((item) => {
        if (item) {
          if (R.has(item.systemName, statistics)) {
            statistics[item.systemName] += item.logEventCount;
          } else {
            statistics[item.systemName] = item.logEventCount;
          }
        }
      }, val || []);
    }, data || {});
    let statisticsList = [];
    R.forEachObjIndexed((val, key) => {
      statisticsList.push({ key, val });
    }, statistics);
    statisticsList = R.sort(R.descend(R.prop('val')), statisticsList);
    R.addIndex(R.forEach)((item, index) => {
      if (index < colors.length) {
        systemColor[item.key] = colors[index];
      } else {
        systemColor[item.key] = '#20B2AA';
      }
    }, statisticsList);
  }

  // 根据几种的system创建几种series数据
  const dataList = [];
  const timeStrDataList = [];
  R.forEach((item) => {
    const obj = {};
    const timeStrObj = {};
    R.forEachObjIndexed((val, keys) => {
      const timeStr = moment.utc(Number(keys)).format(Defaults.DateFormat);
      R.forEach((items) => {
        if (!obj[keys] && items.systemName === item) {
          obj[keys] = { ...items, color: systemColor[item] };
          timeStrObj[timeStr] = { ...items, color: systemColor[item] };
        }
      }, val);
      obj[keys] = obj[keys] || undefined;
      timeStrObj[timeStr] = timeStrObj[timeStr] || undefined;
    }, filterSameMonth);
    dataList.push(obj);
    timeStrDataList.push(timeStrObj);
  }, legendList);

  // 获取前10
  const topTenDate = {};
  R.forEachObjIndexed((val, key) => {
    const valSortItem = R.sortWith([R.descend(R.prop('value'))], val || []);
    let tenList = [];
    let otherItem = {};
    let otherTotal = 0;
    if (valSortItem.length > 10) {
      legendList.push('Other');
      legendList = R.uniq(legendList);
      tenList = R.slice(0, 10)(valSortItem);
      R.forEach((item) => {
        otherTotal += item.value;
      }, R.slice(10, Infinity)(valSortItem));
      otherItem = { systemName: 'Other', logEventCount: otherTotal, value: otherTotal };
    } else {
      tenList = valSortItem;
    }
    topTenDate[key] = tenList;
    if (!isEmpty(otherItem)) topTenDate[key].push(otherItem);
  }, filterSameMonth);

  const newDataList = [];
  R.forEach((item) => {
    const obj = {};
    R.forEachObjIndexed((val, keys) => {
      R.forEach((items) => {
        if (!obj[keys] && items.systemName === item) {
          obj[keys] = { ...items, color: items.systemName === 'Other' ? '#20B2AA' : systemColor[item] };
        }
      }, val);
      obj[keys] = obj[keys] || undefined;
    }, topTenDate);
    newDataList.push(obj);
  }, legendList);

  // 获取legend
  let legendListSort = [];
  R.forEach((item) => {
    const obj = {};
    R.forEachObjIndexed((val, key) => {
      if (val && !obj[val.systemName]) {
        obj[val.systemName] = val.value;
      } else if (val && obj[val.systemName]) {
        obj[val.systemName] += val.value;
      }
    }, item || {});
    if (!isEmpty(obj)) {
      legendListSort.push({
        name: R.keys(obj)[0],
        value: R.values(obj)[0],
      });
    }
  }, dataList);
  legendListSort = R.sortWith([R.descend(R.prop('value'))], legendListSort);
  const legendData = R.take(10)(R.map((item) => item.name, legendListSort || []));
  if (legendList.includes('Other')) {
    legendData.push('Other');
  }

  // 然后根据数组循环创建series
  const series = R.addIndex(R.map)((item, idx) => {
    const filterName = R.filter((val) => val, R.values(item));
    const name = filterName[0]?.systemName;
    const color = filterName[0]?.color;
    return {
      type: 'bar',
      data: R.values(item),
      barMaxWidth: 20,
      zlevel: 20,
      stack: 'total',
      name,
      itemStyle: { color },
    };
  }, newDataList);

  return {
    backgroundColor: 'transparent',
    legend: {
      top: 10,
      type: 'scroll',
      padding: [20, 30],
      data: legendData,
    },
    tooltip: {
      backgroundColor: 'var(--component-background)',
      borderColor: 'transparent',
      textStyle: {
        color: 'var(--text-color)',
      },
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      enterable: true,
      appendToBody: true,
      position: (pos, params, dom, rect, size) => {
        const isRight = size.viewSize[0] / 2 < pos[0];
        const boxWidth = size.contentSize[0]; // 弹框的height
        const boxHeight = size.contentSize[1]; // 弹框的height
        const pointX = pos[0] - (isRight ? boxWidth : 0);
        return [pointX, pos[1] - boxHeight - 5];
      },
      formatter: (params, ticket, callback) => {
        const newParams = R.filter((item) => item.data, params);
        const moveItemName = newParams[0]?.name;
        let viewTooltipData = [];
        R.forEach((item) => {
          if (item[moveItemName]) viewTooltipData.push(item[moveItemName]);
        }, timeStrDataList);
        viewTooltipData = R.sortWith([R.descend(R.prop('value'))], viewTooltipData);
        if (moveItemName) {
          return ReactDOMServer.renderToStaticMarkup(
            <div style={{ maxHeight: 200, maxWidth: 450, overflowY: 'auto', padding: 20 }}>
              <div style={{ fontSize: 14, fontWeight: 'bold' }}>{newParams[0]?.name}</div>
              {R.addIndex(R.map)((item, index) => {
                const { color, systemName, value } = item;
                return (
                  <div key={index} className="flex-row flex-center-align">
                    <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color, flexShrink: 0 }} />
                    <div style={{ margin: '0 0 0 5px' }} className="hidden-line-with-ellipsis">
                      {systemName}
                    </div>
                    <span style={{ marginRight: 8 }}>:</span>
                    <span style={{ fontSize: 14, fontWeight: 'bold', color, flexShrink: 0 }}>{value}</span>
                  </div>
                );
              }, viewTooltipData)}
            </div>,
          );
        }
      },
    },
    grid: {
      left: 40,
      right: '5%',
      top: 80,
      bottom: 10,
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
      axisLine: { show: true },
      splitLine: { show: false },
      splitArea: { show: false },
    },
    series,
  };
};

export default function AllSystemsChart({
  credentials,
  customerName,
  startMonth,
  endMonth,
  forceRefreshTime,
  intl,
  selectMonth,
  location,
  replace,
  userInfo,
  systemList,
  currentTheme,
}: Object) {
  const monthNameMap = useRef({
    '01': intl.formatMessage(appFieldsMessages.Jan),
    '02': intl.formatMessage(appFieldsMessages.Feb),
    '03': intl.formatMessage(appFieldsMessages.Mar),
    '04': intl.formatMessage(appFieldsMessages.Apr),
    '05': intl.formatMessage(appFieldsMessages.May),
    '06': intl.formatMessage(appFieldsMessages.Jun),
    '07': intl.formatMessage(appFieldsMessages.Jul),
    '08': intl.formatMessage(appFieldsMessages.Aug),
    '09': intl.formatMessage(appFieldsMessages.Sep),
    10: intl.formatMessage(appFieldsMessages.Oct),
    11: intl.formatMessage(appFieldsMessages.Nov),
    12: intl.formatMessage(appFieldsMessages.Dec),
  });
  const systemTime = useRef(null);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    chartDate: {},
    loading: false,
    option: {},
    monthStrList: [],
    searchSystemName: '',
  });
  const { chartDate, loading, option, monthStrList, searchSystemName } = state;
  const systemLists = R.filter(
    (item) => item.systemId !== 'allSystems' && item.systemId !== 'projectsWithNoSystem',
    systemList || [],
  );

  useEffect(() => {
    setState({ loading: true });
    const { startTime, endTime } = getSectionTime(startMonth, endMonth);
    fetchGet(getEndpoint('logoverviewstatus'), {
      ...credentials,
      customerName: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : credentials.userName,
      startTime,
      endTime,
    })
      .then(async (data) => {
        const monthStrList = await getMonthStrList(data);
        const option = await getOption(data, selectMonth, systemLists, searchSystemName);
        setState({ loading: false, option, monthStrList, chartDate: data });
      })
      .catch((e) => {
        setState({ loading: false });
        message.error(e.message || String(e));
      });
  }, [customerName, startMonth, endMonth, forceRefreshTime, currentTheme]);

  const handleMonthValueChange = async (selectMonth) => {
    setState({ loading: true });
    const query = parseLocation(location);
    // force refresh
    replace(buildLocation(location.pathname, {}, { ...query, selectMonth }));
    const option = await getOption(chartDate, selectMonth, systemLists, searchSystemName);
    setState({ loading: false, option });
  };

  return (
    <>
      <div className="flex-row flex-center-align flex-center-justify overflow-x-auto" style={{ height: 60 }}>
        {R.map((monthStr) => {
          return (
            <div
              key={monthStr}
              style={{
                cursor: 'pointer',
                margin: '0 12px',
                ...(monthStr === selectMonth
                  ? { fontWeight: 'bold', fontSize: 20, borderBottom: '2px solid #ff5142' }
                  : { fontSize: 14 }),
              }}
              onClick={() => handleMonthValueChange(monthStr)}
            >
              {monthNameMap.current[moment.utc(monthStr, Defaults.MonthFormat).format(Defaults.MonthOnlyFormat)]}
            </div>
          );
        }, monthStrList)}
      </div>
      <div className="flex-grow health-panel corner-8 flex-col">
        <div style={{ paddingTop: 8, marginLeft: 40 }}>
          <span style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.systemName)}:</span>
          <Input
            allowClear
            size="small"
            style={{ width: 240 }}
            value={searchSystemName}
            onChange={(e) => {
              const searchValue = e.target.value;
              if (systemTime.current) clearTimeout(systemTime.current);
              setState({ searchSystemName: searchValue, loading: true });
              systemTime.current = setTimeout(() => {
                const option = getOption(chartDate, selectMonth, systemLists, searchValue);
                setState({ option, loading: false });
              }, 600);
            }}
          />
        </div>
        <Spin spinning={loading} wrapperClassName="full-height spin-full-height flex-col flex-min-height flex-grow">
          <AutoSizer>
            {({ height, width }) => <EChart width={width} height={height} option={option} theme={currentTheme} />}
          </AutoSizer>
        </Spin>
      </div>
    </>
  );
}
