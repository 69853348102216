import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Select, message, Spin, Input, Form, Switch } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
// import { Defaults } from '../../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Defaults, sleep, Regex, parseLocation } from '../../../common/utils';
import { Container, DatePicker, Modal } from '../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  timeSelectParams: Object,
  startTimestamp: Number,
  endTimestamp: Number,
  needProject: Boolean,
  needAlertProject: Boolean,
  needPattern: Boolean,
  needLogProject: Boolean,
  defaultProjectName: String,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  credentials: Object,
  projectList: Array<Object>,
  updateRerunOtherProject: Function,
  userInfo: Object,
};

class DateSelectModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.patternCategoryOptions = [
      { value: 'rerunCalendar', label: 'Rerun calendar' },
      { value: 'rerunLog', label: 'Rerun log detection' },
      { value: 'rerunDetectionToANewProject', label: 'Rerun detection to a different project' },
      { value: 'rerunLogIncident', label: 'Rerun existing log incident' },
      { value: 'LogDataDeletion', label: 'Rerun Log Data Deletion' },
      { value: 'rerunRare', label: 'Rerun rare events' },
      { value: 'newPattern', label: 'Rerun new pattern events' },
      { value: 'rerunHotEvent', label: 'Rerun log frequency data collection' },
      { value: 'rerunWhitelistDetection', label: 'Rerun keyword detection events' },
      { value: 'rerunFeatureCollection', label: 'Rerun feature collection' },
      { value: 'rerunOutlierDetection', label: 'Rerun feature outlier detection' },
      { value: 'rerunOutlierModelCreation', label: 'Rerun feature outlier model creation' },
      { value: 'rerunLogToMetric', label: 'Rerun log to metric' },
      { value: 'rerunJsonField', label: 'Rerun json field' },
      { value: 'rerunAnomalyFeature', label: 'Rerun anomaly feature' },
      { value: 'rerunMISCCluster', label: 'Rerun MISC cluster' },
      { value: 'rerunAllpatternFeature', label: 'Rerun all pattern feature' },
      { value: 'rerunServiceNowNotes', label: 'Rerun serviceNow notes' },
      { value: 'rerunLogPatternName', label: 'Rerun log pattern name' },
      { value: 'rerunZoneCollection', label: 'Rerun zone collection' },
      { value: 'rerunCDFDataStream', label: 'Rerun CDF data stream' },
      { value: 'rerunCDFDataCollect', label: 'Rerun CDF data collect' },
    ];

    const { startTimestamp, endTimestamp, defaultProjectName } = props;
    this.state = {
      startTimestamp: startTimestamp || moment.utc().valueOf(),
      endTimestamp: endTimestamp || moment.utc().valueOf(),
      projectName: defaultProjectName,
      patternCategory: 'rerunCalendar',

      // other options field
      isLoading: false,
      metricProjectName: undefined,
      targetProjectName: null,
      showTargetProject: false,
      projectSwitchType: true,
    };
  }

  componentDidMount() {}

  @autobind
  handleConfirm() {
    const { startTimestamp, endTimestamp, projectName, patternCategory, showTargetProject, targetProjectName } =
      this.state;
    const { metricProjectName } = this.state;
    const { onClose, timeSelectParams, needProject, needPattern, projectList, intl, credentials } = this.props;
    if (onClose) {
      let result = {
        timeSelectParams,
        startTimestamp,
        endTimestamp,

        // other fields
        metricProjectName,
      };
      if (needProject) {
        const project = R.find((p) => p.projectName === projectName, projectList);
        const { projectShortName, owner, projectTypeRaw } = project || {};
        result = {
          ...result,
          userName: owner,
          projectName: projectShortName,
          projectType: projectTypeRaw,
        };
      }
      if (needPattern) {
        result = {
          ...result,
          patternCategory,
        };
      }
      if (patternCategory === 'rerunDetectionToANewProject' && showTargetProject) {
        const project = R.find((p) => p.projectName === projectName, projectList);
        const { projectShortName, owner } = project || {};
        this.props.updateRerunOtherProject({
          ...credentials,
          projectName: `${projectShortName}@${owner}`,
          customerName: owner,
          startTime: startTimestamp,
          endTime: endTimestamp,
          targetProjectName,
        });
        return;
      }
      onClose(result);
    }
  }

  @autobind
  handleStartTimeChange(dateObj) {
    if (dateObj) {
      let { endTimestamp } = this.state;
      const selectTime = dateObj.valueOf();
      if (selectTime > endTimestamp) {
        endTimestamp = selectTime;
      }
      this.setState({ startTimestamp: selectTime, endTimestamp });
    } else {
      this.setState({ startTimestamp: undefined });
    }
  }

  @autobind
  handleEndTimeChange(dateObj) {
    if (dateObj) {
      let { startTimestamp } = this.state;
      const selectTime = dateObj.valueOf();
      if (startTimestamp > selectTime) {
        startTimestamp = selectTime;
      }
      this.setState({ endTimestamp: selectTime, startTimestamp });
    } else {
      this.setState({ endTimestamp: undefined });
    }
  }

  @autobind
  handleProjectChange(projectName) {
    this.setState({ projectName }, async () => {
      await sleep(300);

      const { patternCategory } = this.state;
      if (patternCategory === 'rerunLogToMetric') {
        this.reloadLogToMetricData();
      }
    });
  }

  @autobind
  handleOperationChange(patternCategory) {
    this.setState({ patternCategory, showTargetProject: false }, async () => {
      await sleep(300);

      const { patternCategory } = this.state;
      if (patternCategory === 'rerunLogToMetric') {
        this.reloadLogToMetricData();
      }
      if (patternCategory === 'rerunDetectionToANewProject') {
        this.setState({ showTargetProject: true });
      }
    });
  }

  @autobind
  reloadLogToMetricData() {
    const { intl, credentials, projectList } = this.props;
    this.setState({ isLoading: true });
    const { projectName } = this.state;
    const project = R.find((p) => p.projectName === projectName, projectList);
    const { owner } = project || {};
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('logtometricsetting'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const names = R.map((item) => item.metricProjectName, data || []);
        this.metricProjectList = R.filter(
          (item) => item.owner === owner && names.includes(item.projectShortName),
          projectList,
        );
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.metricProjectList = [];
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { intl } = this.props;
    const { onClose, needProject, needLogProject, needAlertProject, needPattern, location, userInfo } = this.props;
    const params = parseLocation(location);
    const { customerName } = params;
    const { startTimestamp, endTimestamp, projectName, patternCategory, targetProjectName, showTargetProject } =
      this.state;
    const { isLoading, metricProjectName, projectSwitchType } = this.state;
    const startTimeObj = moment.utc(startTimestamp);
    const endTimeObj = moment.utc(endTimestamp);
    const nowObj = moment.utc();

    let { projectList } = this.props;
    if (needLogProject) {
      projectList = R.filter((p) => p.isLog || p.isDeployment, projectList);
    } else if (needAlertProject) {
      projectList = R.filter((p) => p.isAlert || p.isIncident, projectList);
    }

    const hasErrorProject = !targetProjectName || !Regex.projectName.test(targetProjectName);

    const hasError =
      !startTimestamp || !endTimestamp || (needProject ? !projectName : false) || showTargetProject
        ? hasErrorProject
        : false;

    const notShowTime = !R.includes(patternCategory, ['rerunLogPatternName', 'rerunZoneCollection']);
    return (
      <Modal
        title="Rerun"
        width={650}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleConfirm}
        okButtonProps={{ disabled: hasError }}
      >
        <Container className="flex-col content">
          {notShowTime && (
            <>
              <div className="flex-row flex-center-align" style={{ height: 28, padding: '20px 0' }}>
                <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
                  Start Date:
                </span>
                <div className="ui input datePicker" style={{ width: 235 }}>
                  <DatePicker
                    utcOffset={0}
                    className="date full-width"
                    dateFormat={Defaults.DateFormat}
                    todayButton={intl.formatMessage(appFieldsMessages.today)}
                    maxDate={nowObj}
                    showYearDropdown
                    showMonthDropdown
                    selected={startTimeObj}
                    onChange={this.handleStartTimeChange}
                  />
                </div>
              </div>
              <div className="flex-row flex-center-align" style={{ height: 28, padding: '20px 0' }}>
                <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
                  End Date:
                </span>
                <div className="ui input datePicker" style={{ width: 235 }}>
                  <DatePicker
                    utcOffset={0}
                    className="date full-width"
                    dateFormat={Defaults.DateFormat}
                    todayButton={intl.formatMessage(appFieldsMessages.today)}
                    maxDate={nowObj}
                    showYearDropdown
                    showMonthDropdown
                    selected={endTimeObj}
                    onChange={this.handleEndTimeChange}
                  />
                </div>
              </div>
            </>
          )}

          {needProject && (
            <div className="flex-row flex-center-align" style={{ height: 28, padding: '20px 0' }}>
              <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
                Project:
              </span>
              <Select
                style={{ width: 235 }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={projectName}
                onChange={this.handleProjectChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.projectName}>{`${item.projectDisplayName}${
                      userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName
                        ? `@${item.owner}`
                        : ''
                    }`}</Select.Option>
                  ),
                  projectList || [],
                )}
              </Select>
            </div>
          )}

          {needPattern && (
            <div className="flex-row flex-center-align" style={{ height: 28, padding: '20px 0' }}>
              <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
                Operation:
              </span>
              <Select
                style={{ width: 235 }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={patternCategory}
                onChange={this.handleOperationChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ),
                  this.patternCategoryOptions || [],
                )}
              </Select>
            </div>
          )}

          {patternCategory === 'rerunLogToMetric' && (
            <div className="flex-row flex-center-align" style={{ height: 28, padding: '20px 0' }}>
              <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
                Metric project:
              </span>
              <Spin spinning={isLoading}>
                <Select
                  style={{ width: 235 }}
                  showSearch
                  value={metricProjectName}
                  onChange={(metricProjectName) => this.setState({ metricProjectName })}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.projectName}>
                        {item.projectDisplayName || item.projectName}
                      </Select.Option>
                    ),
                    this.metricProjectList || [],
                  )}
                </Select>
              </Spin>
            </div>
          )}

          {showTargetProject && (
            <>
              <div className="flex-row flex-center-align" style={{ height: 28, padding: '20px 0' }}>
                <span>{intl.formatMessage(eventMessages.selectFromExistingProjects)}</span>
                <Switch
                  size="small"
                  checked={projectSwitchType}
                  value={projectSwitchType}
                  style={{ margin: '0 8px' }}
                  onChange={(projectSwitchType) => this.setState({ projectSwitchType, targetProjectName: null })}
                />
                <span>{intl.formatMessage(eventMessages.createANewProject)}</span>
              </div>

              <div className="flex-row flex-center-align" style={{ height: 28, padding: '20px 0' }}>
                <span className="label required" style={{ width: 120, fontWeight: 'bold' }}>
                  {intl.formatMessage(eventMessages.projectName)}:
                </span>

                <div className="flex-col" style={{ marginTop: hasErrorProject ? 20 : 0 }}>
                  {projectSwitchType && (
                    <Input
                      value={targetProjectName}
                      onChange={(e) => this.setState({ targetProjectName: e.target.value })}
                      style={{ width: 235 }}
                    />
                  )}
                  {!projectSwitchType && (
                    <Select
                      style={{ width: 235 }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={targetProjectName}
                      onChange={(targetProjectName) => this.setState({ targetProjectName })}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.map(
                        (item) => (
                          <Select.Option key={item.projectShortName}>{item.projectShortName}</Select.Option>
                        ),
                        R.filter((item) => item.owner === (customerName || userInfo?.userName), projectList || []),
                      )}
                    </Select>
                  )}
                  <div style={{ color: 'var(--red)' }}>
                    {!targetProjectName
                      ? intl.formatMessage(appFieldsMessages.inputRequired)
                      : !Regex.projectName.test(targetProjectName)
                      ? 'Project name can only contain characters: 0-9 a-z A-Z -'
                      : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </Container>
      </Modal>
    );
  }
}

const DateSelectModal = injectIntl(DateSelectModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      projectList: projects,
      location,
      loadStatus,
      credentials,
      userInfo,
    };
  },
  { push, replace, updateLastActionInfo },
)(DateSelectModal);
