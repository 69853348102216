/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 **/

import { schemeRdYlBu, schemePaired } from 'd3-scale-chromatic';

/**
 * Default value for some options.
 */
const Defaults = {
  AllGroupId: '_all',
  ErrorHideDelay: 3000,
  InstanceGroup: 'All',
  MonthFormat: 'YYYY-MM',
  DateShortFormat: 'YYYYMMDD',
  DateFormat: 'YYYY-MM-DD',
  ShortTimeFormat: 'MM-DD HH:mm',
  TimeFormat: 'YYYY-MM-DD HH:mm',
  SmartTimeFormat: 'YYYYMMDDHHmm',

  SmallNumberOfDays: 3,
  NumberOfDays: 14,
  LargeNumberOfDays: 365 * 2,

  CausalRelationProbability: '0.8',
  CausalCorrelationProbability: '0.8',
  CausalKpiPredictionProbability: '0.75',
  CausalCronInterval: 3,

  // http://colorbrewer2.org/#type=sequential&scheme=RdYlBu&n=11
  Colorbrewer: schemeRdYlBu[11],
  Colorbrewer5: schemeRdYlBu[5],
  ColorbrewerMore: schemePaired,
  NoLogEntryFound: 'No log entries for this time period could be found.',
};

export default Defaults;
