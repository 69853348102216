/* @flow */

import { defineMessages } from 'react-intl';

const appMenusMessages = defineMessages({
  dashboard: {
    defaultMessage: 'Dashboard',
    id: 'app.menus.dashboard',
  },
  metricAnalysis: {
    defaultMessage: 'Metric Analysis',
    id: 'app.menus.metricAnalysis',
  },
  metricAppForecast: {
    defaultMessage: 'App Forecast',
    id: 'app.menus.metricAppForecast',
  },
  causalAnalysis: {
    defaultMessage: 'Causal Analysis',
    id: 'app.menus.causalAnalysis',
  },
  eventHistory: {
    defaultMessage: 'History',
    id: 'app.menus.eventHistory',
  },
  globalView: {
    defaultMessage: 'Global View',
    id: 'app.menus.globalView',
  },
  globalHealthView: {
    defaultMessage: 'Unified Health View',
    id: 'app.menus.globalHealthView',
  },
  globalCapacityView: {
    defaultMessage: 'Capacity Planning',
    id: 'app.menus.globalCapacityView',
  },
  insights: {
    defaultMessage: 'Insights',
    id: 'app.menus.insights',
  },
  flags: {
    defaultMessage: 'Flags',
    id: 'app.menus.flags',
  },
  actions: {
    defaultMessage: 'Actions',
    id: 'app.menus.actions',
  },
  prediction: {
    defaultMessage: 'Prediction',
    id: 'app.menus.prediction',
  },
  automation: {
    defaultMessage: 'Automation',
    id: 'app.menus.automation',
  },
  linechart: {
    defaultMessage: 'Line Chart',
    id: 'app.menus.linechart',
  },
  historicalMaxScores: {
    defaultMessage: 'Historical Max Anomaly Scores',
    id: 'app.menus.historicalMaxScores',
  },
  globalSystemInsights: {
    defaultMessage: 'Insights Dashboard',
    id: 'app.menus.globalSystemInsights',
  },
  globalSystemPrediction: {
    defaultMessage: 'Incident Prediction',
    id: 'app.menus.globalSystemPrediction',
  },
  globalSystemRootCause: {
    defaultMessage: 'Incident Investigation',
    id: 'app.menus.globalSystemRootCause',
  },
  globalSystemAlertCause: {
    defaultMessage: 'Alert Insight',
    id: 'app.menus.globalSystemAlertCause',
  },
  globalSystemKnowledgeBase: {
    defaultMessage: 'Knowledge Base',
    id: 'app.menus.globalSystemKnowledgeBase',
  },
  globalSystemCorrectlyPredicted: {
    defaultMessage: 'Correctly Predicted Incidents',
    id: 'app.menus.globalSystemCorrectlyPredicted',
  },
  globalSystemIgnoredIncident: {
    defaultMessage: 'Ignored Incidents',
    id: 'app.menus.globalSystemIgnoredIncident',
  },
  rootCauses: {
    defaultMessage: 'Root Causes',
    id: 'app.menus.rootCauses',
  },
  logQuery: {
    defaultMessage: 'Log Query',
    id: 'app.menus.logQuery',
  },
  insightQuery: {
    defaultMessage: 'Insight Query',
    id: 'app.menus.insightQuery',
  },
  analysis: {
    defaultMessage: 'Analysis',
    id: 'app.menus.analysis',
  },
  events: {
    defaultMessage: 'Events',
    id: 'app.menus.events',
  },
  patterns: {
    defaultMessage: 'Patterns',
    id: 'app.menus.patterns',
  },
  jobAnalysis: {
    defaultMessage: 'Job Analysis',
    id: 'app.menus.jobAnalysis',
  },
  calendar: {
    defaultMessage: 'Calendar',
    id: 'app.menus.calendar',
  },
  logAnalysis: {
    defaultMessage: 'Log/Trace Analysis',
    id: 'app.menus.logAnalysis',
  },
  alertAnalysis: {
    defaultMessage: 'Alert/Incident Analysis',
    id: 'app.menus.alertAnalysis',
  },
  settings: {
    defaultMessage: 'Settings',
    id: 'app.menus.settings',
  },
  system: {
    defaultMessage: 'System',
    id: 'app.menus.system',
  },
  issues: {
    defaultMessage: 'Issues',
    id: 'app.menus.issues',
  },
  bugRepository: {
    defaultMessage: 'Bug Repository',
    id: 'app.menus.bugRepository',
  },
  fileAnalysis: {
    defaultMessage: 'Anomaly Detection',
    id: 'app.menus.fileAnalysis',
  },
  help: {
    defaultMessage: 'Help',
    id: 'app.menus.help',
  },
  signout: {
    defaultMessage: 'Sign out',
    id: 'app.menus.signout',
  },
  themeModeLight: {
    defaultMessage: '\u2022 Light / Dark',
    id: 'app.menus.themeMode.light',
  },
  themeModeDark: {
    defaultMessage: 'Light / \u2022 Dark',
    id: 'app.menus.themeMode.dark',
  },
  accountProfile: {
    defaultMessage: 'Account Profile',
    id: 'app.menus.accountProfile',
  },
  gettingStart: {
    defaultMessage: 'Getting Start',
    id: 'app.menus.gettingStart',
  },
  guidedTour: {
    defaultMessage: 'Guided Tour',
    id: 'app.menus.guidedTour',
  },
  serviceMap: {
    defaultMessage: 'Service Map',
    id: 'app.menus.serviceMap',
  },
});

export default appMenusMessages;
