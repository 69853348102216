/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { DataFrame } from 'dataframe-js';
import { autobind } from 'core-decorators';
import { get, isNumber, parseInt, isString } from 'lodash';

import { Container, MultiGrid, Popover } from '../../../lib/fui/react';
import { Up, Down } from '../../../lib/fui/icons';

type Props = {
  width: Number,
  height: Number,
  queryResult: Object,
  queryParams: Object,
};

class TopestList extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.gridOffsetY = 0;
    this.gridOffsetX = 40;
    this.state = {};
    this.convertData(props.queryResult, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps.queryResult, {});
    }
  }

  @autobind
  convertData(queryResult, params = {}) {
    const { instanceToApp, top3Instance, topKAnomalyMap } = queryResult;

    if (!top3Instance || !topKAnomalyMap) {
      this.queryError = queryResult || 'Project missing data';
      return;
    }

    const dataKeys = [];
    R.forEach((value) => {
      dataKeys.push(parseInt(value));
    }, R.keys(top3Instance));
    dataKeys.sort();

    const dataList = [];
    R.forEach((key) => {
      const topInstance = top3Instance[key];
      const topMetricList = get(topKAnomalyMap, topInstance, []);
      if (topMetricList.length > 0) {
        dataList.push([
          get(instanceToApp, topInstance, 'N/A'),
          topInstance,
          topMetricList[0],
          topMetricList[1],
          topMetricList[2],
        ]);
      }
    }, dataKeys);

    if (dataList.length === 0) {
      this.queryError = 'No result found';
      return;
    }
    this.queryError = null;

    const otherColumns = ['MetricTop1', 'MetricTop2', 'MetricTop3'];
    const fixedColmns = ['Application', 'Instance'];
    const allColumns = [...fixedColmns, ...otherColumns];

    let df = new DataFrame(dataList, allColumns);
    df = df.sortBy(fixedColmns[0], true);

    this.gridData = df.toArray();
    this.gridDataHeaders = allColumns;
    this.gridFixedHeaders = fixedColmns;
    this.gridNormalHeaders = otherColumns;

    if (this.grid) {
      this.grid.forceUpdate();
    }
  }

  @autobind
  cellRenderer({ columnIndex, key, rowIndex, style }) {
    const color = '';

    // Grid headers
    if (rowIndex === 0) {
      const colname = this.gridDataHeaders[columnIndex] || '';
      return (
        <div className={`header ${color} cell`} key={key} style={style}>
          <Popover
            content={colname}
            placement="top-middle"
            style={{ zIndex: 300, width: '100%' }}
            mouseEnterDelay={300}
          >
            <div
              style={{
                height: '100%',
                display: 'block',
                wordBreak: 'break-all',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <div className="text">{colname}</div>
            </div>
          </Popover>
        </div>
      );
    }

    // Grid data
    const dataRowIdx = rowIndex - 1;
    let content = '';

    if (columnIndex < this.gridFixedHeaders.length) {
      content = get(this.gridData, `[${dataRowIdx}][${columnIndex}]`, '');
    } else {
      const val = get(this.gridData, `[${dataRowIdx}][${columnIndex}]`, {});
      content = (
        <Popover
          content={
            <>
              {val.metricName || '-'}
              {val.metricDirection === 'higher' ? (
                <Up color={'red'} verticalAlign={'middle'} />
              ) : val.metricDirection === 'lower' ? (
                <Down color={'blue'} verticalAlign={'middle'} />
              ) : null}
            </>
          }
          placement="top-middle"
          style={{ zIndex: 300, width: '100%' }}
        >
          <div
            style={{
              height: '100%',
              display: 'block',
              wordBreak: 'break-all',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {val.metricName || '-'}
            {val.metricDirection === 'higher' ? (
              <Up color={'red'} verticalAlign={'middle'} />
            ) : val.metricDirection === 'lower' ? (
              <Down color={'blue'} verticalAlign={'middle'} />
            ) : null}
          </div>
        </Popover>
      );
    }

    if (isNumber(content)) {
      content = content.toFixed(2);
    } else if (content === 'NaN') {
      content = <span className="text">-</span>;
    } else if (isString(content)) {
      content = (
        <Popover content={content} placement="top-middle" style={{ zIndex: 300, width: '100%' }} mouseEnterDelay={300}>
          <div
            style={{
              height: '100%',
              display: 'block',
              wordBreak: 'break-all',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <div className="left text">{content}</div>
          </div>
        </Popover>
      );
    }
    return (
      <div className={`${color} cell`} key={key} style={style}>
        {content}
      </div>
    );
  }

  render() {
    const { height, width } = this.props;
    const hasError = Boolean(this.queryError);

    return (
      <Container className="content flex-col" style={{ height, width }}>
        <Container className="toolbar" style={{ paddingTop: 12 }}>
          <Container className="section">
            <h4>Top 3 anomalous metrics</h4>
          </Container>
          <Container className="section float-right" />
        </Container>
        {hasError && <div className="ui error message">{this.queryError}</div>}
        {!hasError && (
          <MultiGrid
            ref={(grid) => (this.grid = grid)}
            width={R.min(this.gridData[0].length * 140, width)}
            height={R.min(63 + this.gridData.length * 40, height - this.gridOffsetY)}
            columnWidth={({ index }) => 140}
            rowHeight={({ index }) => (index === 0 ? 63 : 40)}
            columnCount={this.gridDataHeaders.length}
            rowCount={this.gridData.length + 1}
            cellRenderer={this.cellRenderer}
            enableFixedColumnScroll
            enableFixedRowScroll
            fixedColumnCount={this.gridFixedHeaders.length}
            fixedRowCount={1}
            style={{ border: '1px solid rgba(34, 36, 38, 0.15)' }}
            styleBottomLeftGrid={{ borderRight: '2px solid #aaa', backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
            styleTopLeftGrid={{
              borderBottom: '2px solid #aaa',
              borderRight: '2px solid #aaa',
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
            }}
            styleTopRightGrid={{
              borderBottom: '2px solid #aaa',
            }}
          />
        )}
      </Container>
    );
  }
}

export default TopestList;
