import React, { useEffect, useState, useCallback } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, round, isFinite, isFunction, isNumber, isObject, isEqual } from 'lodash';

import {
  EyeOutlined,
  EyeInvisibleOutlined,
  ApartmentOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  DeploymentUnitOutlined,
  EditOutlined,
  ExportOutlined,
  LineChartOutlined,
  MailOutlined,
  UnorderedListOutlined,
  ProfileOutlined,
  DeleteFilled,
  ArrowRightOutlined,
  ClockCircleFilled,
} from '@ant-design/icons';
import {
  Descriptions,
  Tooltip,
  Button,
  Popover,
  Timeline,
  Empty,
  Popconfirm,
  message,
  Spin,
  Divider,
  Space,
  Alert,
} from 'antd';

import fetchGet from '../apis/fetchGet';
import fetchPost from '../apis/fetchPost';
import getEndpoint from '../apis/getEndpoint';
import { Defaults, GlobalRenderers, CellRenderers, LogRenderers, CausalParser, parseJSON, GlobalParse } from '.';
import {
  GraphIcon,
  TrendIcon,
  QeuryIcon,
  IncidentPredictIcon,
  RootCauseIcon,
  FixedIcon,
  GoodIcon,
  GoodOutlinedIcon,
  SavedActionIcon,
  JiraIcon,
  ServiceNowIcon,
  InsightfinderIcon,
} from '../../lib/fui/icons';
import { appButtonsMessages, appFieldsMessages, appMenusMessages, appMessages } from '../app/messages';
import { DashboardMessages } from '../dashboard/messages';
import { eventActionMessages, eventMessages } from '../metric/messages';
import { logMessages } from '../log/messages';
import getInstanceDisplayName from './getInstanceDisplayName';

// Log component renderers
const EventRenderers = {
  /* eslint react/prop-types: 0 */
  RenderEventEntry: ({
    intl,
    isReadUser,
    projects,
    idx,
    event,
    incidentPatternName,
    causalGraphInfo,
    instanceName,
    style,
    category,
    metricUnitMap,
    expandLimit,
    showDescriptionTitle,
    showDetectionTimePair,
    rootCauseList,
    isAlert,
    onTriageReportClick,
    showPatternClick,
    onPatternClick,
    showOriginalNormalPatternClick,
    onOriginalNormalPatternClick,
    showRootCausal,
    onIncidentRootCausalClick,
    showCausalGraph,
    onCausalGraphClick,
    showTrendPatterns,
    onTrendPatternsClick,
    hideAllButton,
    onLineChartClick,
    showLineChartJump,
    onLineChartJump,
    showIPJump,
    onIPClick,
    showRCAJump,
    onRCAClick,
    showSuggestedActions,
    onSuggestedActionsClick,
    showSoftwareUpdateContext,
    onSoftwareUpdateContextClick,
    showChangePatternName,
    onChangePatternNameClick,
    showIgnore,
    showImportant,
    onIgnoreClick,
    showMailAlert,
    onMailAlertClick,
    showExportAlert,
    onExportAlertClick,
    showReportJiraModal,
    onReportJiraClick,
    showReportServiceNow,
    onReportServiceNowClick,
    showRerunRCA,
    onRerunRCAClick,
    showDeleteIncident,
    onDeleteIncidentClick,
    summarySettings,
    systemId,
    isLoading,
    incident,
    rootCausesData,
    predictedData,
    showTitleRender = false,
    credentials,
    projectDisplayMap,
    currentTheme,
    instanceDisplayNameMap,
  }) => {
    const {
      patternId,
      patternName,
      isPrediction,
      instanceAppNames,
      isFixedIncident,
      generateFromMetricAnomalyIncident,
      type,
      isIgnored,
      isImportant,
      causalGraphInfo: causalGraphInfoFromEvent,
      isConfirmed,
    } = event;
    const shouldExpandLimit = expandLimit || 1;

    const activePatternName = incidentPatternName !== undefined ? incidentPatternName : patternName;
    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternName: activePatternName, patternId },
      { hasPrefix: true, hasFullName: true },
    );

    // RC
    const logRootCauseEvents = get(rootCausesData, ['logRootCauseEvents'], []);
    const metricRootCauseEvents = get(rootCausesData, ['metricRootCauseEvents'], []);
    const { relatedEventList: allRootCauseListLog } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: logRootCauseEvents,
      credentials,
      projectDisplayMap,
    });
    const { relatedEventList: allRootCauseListMetric } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: metricRootCauseEvents,
      credentials,
      projectDisplayMap,
    });

    const sourceInfoListLog = R.map((n) => n.sourceInfoList, allRootCauseListLog);
    const sourceInfoListMetric = R.map((n) => n.sourceInfoList, allRootCauseListMetric);
    const allSourceInfoList = R.flatten(R.concat(sourceInfoListLog, sourceInfoListMetric));
    const projectNames = [];
    const instanceTypeMap = {};
    R.forEach((sourceInfo) => {
      const { instanceName, sourceDetail, sourceProjectName } = sourceInfo;
      const { eventType, metricType, type, content } = sourceDetail;

      if (!projectNames.includes(sourceProjectName)) {
        projectNames.push(sourceProjectName);
      }
      const category = type === 'Metric' ? metricType || content : eventType;
      if (instanceTypeMap[instanceName]) {
        if (!instanceTypeMap[instanceName].includes(category)) {
          instanceTypeMap[instanceName].push(category);
        }
        return;
      }
      instanceTypeMap[instanceName] = [category];
    }, allSourceInfoList);

    const hasErrorSummary = R.isEmpty(instanceTypeMap) || R.isEmpty(projectNames);

    const isIncident = R.toLower(type).indexOf('incident') >= 0;
    const showCausalGraphJump = showCausalGraph && (causalGraphInfo || causalGraphInfoFromEvent);
    const DescriptionTitle = (
      <div className="flex-row">
        <div
          className="flex-grow flex-row flex-center-align"
          style={{
            // paddingRight: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'keep-all',
            whiteSpace: 'nowrap',
          }}
        >
          {isFixedIncident && (
            <Tooltip title="Is fixed incident" mouseEnterDelay={0.3}>
              <FixedIcon style={{ color: '#ff5142', fontSize: 14, marginRight: 4 }} />
            </Tooltip>
          )}
          {`${
            isFixedIncident
              ? `${intl.formatMessage(DashboardMessages.fixed)} `
              : `${
                  isPrediction
                    ? intl.formatMessage(appFieldsMessages.predicted)
                    : intl.formatMessage(appFieldsMessages.detected)
                } `
          }${generateFromMetricAnomalyIncident ? `${intl.formatMessage(appFieldsMessages.kpi)} ` : ''}${
            isIncident
              ? intl.formatMessage(eventMessages.incident)
              : category === 'metric'
              ? intl.formatMessage(eventMessages.metric)
              : intl.formatMessage(eventMessages.log)
          }`}
          :
          <div className="flex-row flex-center-align flex-space-between flex-grow">
            <div>
              <Button.Group style={{ marginLeft: 8 }}>
                <Tooltip
                  title={
                    isConfirmed
                      ? intl.formatMessage(eventMessages.removeLikeFlag)
                      : intl.formatMessage(eventMessages.markAsLike)
                  }
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <Button size="small" onClick={() => onIgnoreClick({ event, category: 'confirm' })}>
                    {isConfirmed ? <GoodIcon style={{ color: 'var(--green)' }} /> : <GoodOutlinedIcon />}
                  </Button>
                </Tooltip>
                {showIgnore && (
                  <Tooltip
                    title={
                      isIgnored
                        ? intl.formatMessage(eventMessages.markAsUnignored)
                        : intl.formatMessage(eventMessages.markAsIgnored)
                    }
                    mouseEnterDelay={0.3}
                    placement="top"
                  >
                    <Button size="small" onClick={() => onIgnoreClick({ event, category: 'ignore' })}>
                      {isIgnored && <GoodIcon style={{ color: '#ff5142', transform: 'rotateX(180deg)' }} />}
                      {!isIgnored && <GoodOutlinedIcon style={{ transform: 'rotateX(180deg)' }} />}
                    </Button>
                  </Tooltip>
                )}
              </Button.Group>
            </div>
            <div>
              {!isReadUser && (
                <Button.Group
                  style={{
                    marginLeft:
                      showChangePatternName ||
                      showMailAlert ||
                      (showExportAlert && onExportAlertClick) ||
                      showPatternClick ||
                      (showOriginalNormalPatternClick && category === 'log') ||
                      showIPJump ||
                      showRCAJump ||
                      (showTrendPatterns && category === 'log') ||
                      showRootCausal ||
                      showCausalGraphJump ||
                      showSuggestedActions ||
                      showSoftwareUpdateContext
                        ? 8
                        : 0,
                  }}
                >
                  {showChangePatternName && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.setPatternName)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onChangePatternNameClick({ event })}>
                        <EditOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {showMailAlert && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.sendEmailAlert)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onMailAlertClick({ event })}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {showExportAlert && onExportAlertClick && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.sendExportAlert)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onExportAlertClick({ event })}>
                        <ExportOutlined />
                      </Button>
                    </Tooltip>
                  )}

                  {showPatternClick && (
                    <Tooltip
                      title={
                        category === 'metric'
                          ? intl.formatMessage(appMenusMessages.metricAnalysis)
                          : isAlert
                          ? intl.formatMessage(appMenusMessages.alertAnalysis)
                          : intl.formatMessage(appMenusMessages.logAnalysis)
                      }
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button
                        type="primary"
                        size="small"
                        onClick={() =>
                          onPatternClick({ isPredicted: false, patternSearch: patternId, typeSearch: type, event })
                        }
                      >
                        <UnorderedListOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {showOriginalNormalPatternClick && category === 'log' && (
                    <Tooltip
                      title={intl.formatMessage(DashboardMessages.originalNormalPattern)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onOriginalNormalPatternClick({ event })}>
                        <ProfileOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {showIPJump && (
                    <Tooltip
                      title={intl.formatMessage(appMenusMessages.globalSystemPrediction)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onIPClick({ event })}>
                        <IncidentPredictIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showRCAJump && (
                    <Tooltip
                      title={intl.formatMessage(appMenusMessages.globalSystemRootCause)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onRCAClick({ event })}>
                        <RootCauseIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showTrendPatterns && category === 'log' && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.patternTrend)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onTrendPatternsClick({ event })}>
                        <TrendIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showRootCausal && (
                    <Tooltip
                      title={intl.formatMessage(DashboardMessages.rootCauses)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onIncidentRootCausalClick({ event })}>
                        <ApartmentOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {showCausalGraphJump && (
                    <Tooltip
                      title={intl.formatMessage(DashboardMessages.causalGraph)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onCausalGraphClick({ event })}>
                        <GraphIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showSuggestedActions && (
                    <Tooltip title={intl.formatMessage(eventMessages.takeAction)} mouseEnterDelay={0.3} placement="top">
                      <Button type="primary" size="small" onClick={() => onSuggestedActionsClick({ event })}>
                        <SavedActionIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showSoftwareUpdateContext && (
                    <Tooltip
                      title={intl.formatMessage(appFieldsMessages.softwareUpdateContext)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onSoftwareUpdateContextClick({ event })}>
                        <DeploymentUnitOutlined />
                      </Button>
                    </Tooltip>
                  )}
                </Button.Group>
              )}
              {!isReadUser && (
                <Button.Group
                  style={{
                    marginLeft:
                      (showReportJiraModal && onReportJiraClick) || (showReportServiceNow && onReportServiceNowClick)
                        ? 8
                        : 0,
                  }}
                >
                  {showReportJiraModal && onReportJiraClick && (
                    <Tooltip title={intl.formatMessage(eventMessages.reportJira)} mouseEnterDelay={0.3} placement="top">
                      <Button size="small" onClick={() => onReportJiraClick({ event })}>
                        <JiraIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showReportServiceNow && onReportServiceNowClick && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.reportServiceNow)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onReportServiceNowClick({ event })}>
                        <ServiceNowIcon />
                      </Button>
                    </Tooltip>
                  )}
                </Button.Group>
              )}
              {!isReadUser && (
                <Button.Group
                  style={{
                    marginLeft:
                      (showRerunRCA && onRerunRCAClick) || (showDeleteIncident && onDeleteIncidentClick) ? 8 : 0,
                  }}
                >
                  {showRerunRCA && onRerunRCAClick && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.rerunRCAForAnomaly)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onRerunRCAClick({ event })}>
                        <InsightfinderIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showDeleteIncident && onDeleteIncidentClick && (
                    <Popconfirm
                      title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                      okText={intl.formatMessage(appButtonsMessages.yes)}
                      cancelText={intl.formatMessage(appButtonsMessages.no)}
                      onConfirm={() => onDeleteIncidentClick({ event })}
                      onCancel={(event) => event.stopPropagation()}
                    >
                      <Tooltip
                        title={intl.formatMessage(eventMessages.deleteIncident)}
                        mouseEnterDelay={0.3}
                        placement="top"
                      >
                        <Button size="small" onClick={(e) => e.stopPropagation()}>
                          <DeleteFilled />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  )}
                </Button.Group>
              )}
            </div>
          </div>
        </div>

        <div className="flex-row" style={{ fontWeight: 'normal' }} />
      </div>
    );

    const viewInstanceAppNames = R.map((item) => {
      const [i, c] = item.split(' (');
      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, i, {
        pn: event?.projectName,
        owner: event?.projectOwner,
      });
      return `${instanceStr}${c ? ` (${c}` : ''}`;
    }, instanceAppNames || []);
    return (
      <div key={idx} className="ant-descriptions-table" style={{ ...style, padding: 0, margin: '0 0 16px 0' }}>
        <Descriptions
          bordered
          size="small"
          column={4}
          title={showDescriptionTitle !== false && showTitleRender ? DescriptionTitle : null}
        >
          {!isPrediction && (
            <Descriptions.Item label={intl.formatMessage(appFieldsMessages.instanceName)} span={2}>
              <div style={{ wordBreak: 'break-all', minWidth: 80 }}>
                {`${R.join(', ', R.take(shouldExpandLimit, viewInstanceAppNames))}${
                  instanceAppNames.length > shouldExpandLimit ? '...' : ''
                }`}
                {instanceAppNames.length > shouldExpandLimit && (
                  <Popover
                    placement="right"
                    content={
                      <div
                        className="log-event-group flex-col"
                        style={{
                          width: 250,
                          maxHeight: 300,
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          fontSize: 12,
                          padding: '0 0',
                        }}
                      >
                        <div>
                          {R.addIndex(R.map)(
                            (item, idx) => (
                              <div key={idx}>{item}</div>
                            ),
                            instanceAppNames,
                          )}
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button
                      type="link"
                      style={{
                        padding: 0,
                        height: 'auto',
                        fontSize: 12,
                      }}
                    >
                      {intl.formatMessage(appFieldsMessages.expand)}
                    </Button>
                  </Popover>
                )}

                {!hideAllButton && category === 'metric' && (
                  <Tooltip title={intl.formatMessage(eventMessages.lineChart)} mouseEnterDelay={0.3} placement="top">
                    <Button
                      size="small"
                      style={{ height: 20, marginLeft: 8 }}
                      onClick={() => onLineChartClick({ event })}
                    >
                      <LineChartOutlined />
                    </Button>
                  </Tooltip>
                )}
                {!hideAllButton && category !== 'metric' && showLineChartJump && (
                  <Tooltip title={intl.formatMessage(eventMessages.lineChart)} mouseEnterDelay={0.3} placement="top">
                    <Button
                      size="small"
                      style={{ height: 20, marginLeft: 8 }}
                      onClick={() => onLineChartJump({ event })}
                    >
                      <LineChartOutlined />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </Descriptions.Item>
          )}
          {!isPrediction && (
            <Descriptions.Item label={intl.formatMessage(appFieldsMessages.patternIdName)} span={2}>
              <div style={{ wordBreak: 'break-all', minWidth: 80 }}>{patternNameStr}</div>
            </Descriptions.Item>
          )}

          {!isPrediction && (
            <Descriptions.Item label={intl.formatMessage(eventMessages.shortDescription)} span={4}>
              <EventRenderers.RenderShortDescription
                intl={intl}
                event={event}
                metricUnitMap={metricUnitMap}
                defaultContentSize={500}
                summarySettings={summarySettings}
                currentTheme={currentTheme}
              />
            </Descriptions.Item>
          )}

          {!isPrediction && (
            <Descriptions.Item label="Root cause summary" span={4}>
              {hasErrorSummary && <div>No causally related anomalies are found</div>}
              {!hasErrorSummary && (
                <div>
                  <span style={{ marginRight: 4 }}>This incident has strong causality with this causal chain:</span>
                  {R.addIndex(R.map)((item, index) => {
                    const isLast = index === R.toPairs(instanceTypeMap).length - 1;
                    const [instance, types] = item;
                    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instance, {
                      pn: event?.projectName,
                      owner: event?.projectOwner,
                    });
                    return (
                      <span style={{ marginRight: 4, color: 'var(--blue)' }}>
                        {instanceStr}
                        <span style={{ margin: '0 2px' }}>({types.join(', ')})</span>
                        {isLast ? '' : <ArrowRightOutlined style={{ marginLeft: 4 }} />}
                      </span>
                    );
                  }, R.toPairs(instanceTypeMap))}
                  from projects
                  {R.addIndex(R.map)(
                    (projectName, index) => (
                      <span style={{ marginLeft: 4, color: 'var(--blue)' }}>
                        {projectName}
                        {index === projectNames.length - 1 ? '' : ','}
                      </span>
                    ),
                    projectNames,
                  )}
                </div>
              )}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  },

  RenderEventOperation: ({
    intl,
    isReadUser,
    projects,
    idx,
    event,
    incidentPatternName,
    causalGraphInfo,
    instanceName,
    style,
    category,
    metricUnitMap,
    expandLimit,
    showDescriptionTitle,
    showDetectionTimePair,
    rootCauseList,
    isAlert,
    onTriageReportClick,
    showPatternClick,
    onPatternClick,
    showOriginalNormalPatternClick,
    onOriginalNormalPatternClick,
    showRootCausal,
    onIncidentRootCausalClick,
    showCausalGraph,
    onCausalGraphClick,
    showTrendPatterns,
    onTrendPatternsClick,
    hideAllButton,
    onLineChartClick,
    showLineChartJump,
    onLineChartJump,
    showIPJump,
    onIPClick,
    showRCAJump,
    onRCAClick,
    showSuggestedActions,
    onSuggestedActionsClick,
    showSoftwareUpdateContext,
    onSoftwareUpdateContextClick,
    showChangePatternName,
    onChangePatternNameClick,
    showIgnore,
    showImportant,
    onIgnoreClick,
    showMailAlert,
    onMailAlertClick,
    showExportAlert,
    onExportAlertClick,
    showReportJiraModal,
    onReportJiraClick,
    showReportServiceNow,
    onReportServiceNowClick,
    showRerunRCA,
    onRerunRCAClick,
    showDeleteIncident,
    onDeleteIncidentClick,
    summarySettings,
    systemId,
    isLoading,
    incident,
    rootCausesData,
    predictedData,
    showTitleRender = false,
    credentials,
    projectDisplayMap,
    currentTheme,
  }) => {
    const { patternId, type, causalGraphInfo: causalGraphInfoFromEvent } = event;

    // RC
    const logRootCauseEvents = get(rootCausesData, ['logRootCauseEvents'], []);
    const metricRootCauseEvents = get(rootCausesData, ['metricRootCauseEvents'], []);
    const { relatedEventList: allRootCauseListLog } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: logRootCauseEvents,
      credentials,
      projectDisplayMap,
    });
    const { relatedEventList: allRootCauseListMetric } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: metricRootCauseEvents,
      credentials,
      projectDisplayMap,
    });

    const sourceInfoListLog = R.map((n) => n.sourceInfoList, allRootCauseListLog);
    const sourceInfoListMetric = R.map((n) => n.sourceInfoList, allRootCauseListMetric);
    const allSourceInfoList = R.flatten(R.concat(sourceInfoListLog, sourceInfoListMetric));
    const projectNames = [];
    const instanceTypeMap = {};
    R.forEach((sourceInfo) => {
      const { instanceName, sourceDetail, sourceProjectName } = sourceInfo;
      const { eventType, metricType, type, content } = sourceDetail;

      if (!projectNames.includes(sourceProjectName)) {
        projectNames.push(sourceProjectName);
      }
      const category = type === 'Metric' ? metricType || content : eventType;
      if (instanceTypeMap[instanceName]) {
        if (!instanceTypeMap[instanceName].includes(category)) {
          instanceTypeMap[instanceName].push(category);
        }
        return;
      }
      instanceTypeMap[instanceName] = [category];
    }, allSourceInfoList);

    const showCausalGraphJump = showCausalGraph && (causalGraphInfo || causalGraphInfoFromEvent);
    return (
      <div key={idx} style={{ ...style, padding: 0 }}>
        <div className="flex-row">
          <div
            className="flex-grow flex-row flex-center-align"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'keep-all',
              whiteSpace: 'nowrap',
            }}
          >
            <div className="flex-row flex-center-align flex-space-between flex-grow">
              {!isReadUser && (
                <Space>
                  {showChangePatternName && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.setPatternName)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onChangePatternNameClick({ event })}>
                        <EditOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {showMailAlert && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.sendEmailAlert)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onMailAlertClick({ event })}>
                        <MailOutlined />
                        {intl.formatMessage(eventActionMessages.email)}
                      </Button>
                    </Tooltip>
                  )}
                  {showExportAlert && onExportAlertClick && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.sendExportAlert)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onExportAlertClick({ event })}>
                        <ExportOutlined />
                        {intl.formatMessage(eventMessages.export)}
                      </Button>
                    </Tooltip>
                  )}

                  {showPatternClick && (
                    <Tooltip
                      title={
                        category === 'metric'
                          ? intl.formatMessage(appMenusMessages.metricAnalysis)
                          : isAlert
                          ? intl.formatMessage(appMenusMessages.alertAnalysis)
                          : intl.formatMessage(appMenusMessages.logAnalysis)
                      }
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button
                        type="primary"
                        size="small"
                        onClick={() =>
                          onPatternClick({ isPredicted: false, patternSearch: patternId, typeSearch: type, event })
                        }
                      >
                        <UnorderedListOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {showOriginalNormalPatternClick && category === 'log' && (
                    <Tooltip
                      title={intl.formatMessage(DashboardMessages.originalNormalPattern)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onOriginalNormalPatternClick({ event })}>
                        <ProfileOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {showIPJump && (
                    <Tooltip
                      title={intl.formatMessage(appMenusMessages.globalSystemPrediction)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onIPClick({ event })}>
                        <IncidentPredictIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showRCAJump && (
                    <Tooltip
                      title={intl.formatMessage(appMenusMessages.globalSystemRootCause)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onRCAClick({ event })}>
                        <RootCauseIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showTrendPatterns && category === 'log' && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.patternTrend)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onTrendPatternsClick({ event })}>
                        <TrendIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showRootCausal && (
                    <Tooltip
                      title={intl.formatMessage(DashboardMessages.rootCauses)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onIncidentRootCausalClick({ event })}>
                        <ApartmentOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {showCausalGraphJump && (
                    <Tooltip
                      title={intl.formatMessage(DashboardMessages.causalGraph)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onCausalGraphClick({ event })}>
                        <GraphIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showSuggestedActions && (
                    <Tooltip title={intl.formatMessage(eventMessages.takeAction)} mouseEnterDelay={0.3} placement="top">
                      <Button type="primary" size="small" onClick={() => onSuggestedActionsClick({ event })}>
                        <SavedActionIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showSoftwareUpdateContext && (
                    <Tooltip
                      title={intl.formatMessage(appFieldsMessages.softwareUpdateContext)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button type="primary" size="small" onClick={() => onSoftwareUpdateContextClick({ event })}>
                        <DeploymentUnitOutlined />
                      </Button>
                    </Tooltip>
                  )}
                </Space>
              )}
              {!isReadUser && (
                <Button.Group
                  style={{
                    marginLeft:
                      (showReportJiraModal && onReportJiraClick) || (showReportServiceNow && onReportServiceNowClick)
                        ? 8
                        : 0,
                  }}
                >
                  {showReportJiraModal && onReportJiraClick && (
                    <Tooltip title={intl.formatMessage(eventMessages.reportJira)} mouseEnterDelay={0.3} placement="top">
                      <Button size="small" onClick={() => onReportJiraClick({ event })}>
                        <JiraIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showReportServiceNow && onReportServiceNowClick && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.reportServiceNow)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onReportServiceNowClick({ event })}>
                        <ServiceNowIcon />
                      </Button>
                    </Tooltip>
                  )}
                </Button.Group>
              )}
              {!isReadUser && (
                <Button.Group
                  style={{
                    marginLeft:
                      (showRerunRCA && onRerunRCAClick) || (showDeleteIncident && onDeleteIncidentClick) ? 8 : 0,
                  }}
                >
                  {showRerunRCA && onRerunRCAClick && (
                    <Tooltip
                      title={intl.formatMessage(eventMessages.rerunRCAForAnomaly)}
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <Button size="small" onClick={() => onRerunRCAClick({ event })}>
                        <InsightfinderIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {showDeleteIncident && onDeleteIncidentClick && (
                    <Popconfirm
                      title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                      okText={intl.formatMessage(appButtonsMessages.yes)}
                      cancelText={intl.formatMessage(appButtonsMessages.no)}
                      onConfirm={() => onDeleteIncidentClick({ event })}
                      onCancel={(event) => event.stopPropagation()}
                    >
                      <Tooltip
                        title={intl.formatMessage(eventMessages.deleteIncident)}
                        mouseEnterDelay={0.3}
                        placement="top"
                      >
                        <Button size="small" onClick={(e) => e.stopPropagation()}>
                          <DeleteFilled />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  )}
                </Button.Group>
              )}
            </div>
          </div>

          <div className="flex-row" style={{ fontWeight: 'normal' }} />
        </div>
      </div>
    );
  },

  RenderShortDescription: ({
    intl,
    event,
    metricUnitMap = {},
    shouldExpandLimit = 3,
    defaultContentSize,
    onToggleCollapse,
    summarySettings,
    currentTheme,
    isRoot,
  }) => {
    const {
      rawData,
      anomalyWords,
      outlierValue,
      category,
      metricRootCause,
      anomalyFeatureStr: anomalyFeatureArr,
      generateFromGenericRule,
    } = event;
    const rootCauseDetailsArr = get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []);

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
      if (!isObject(rawDataJson)) rawDataJson = undefined;
    } catch (error) {
      // console.debug(error);
    }

    let metricRootCauseObj;
    let frequencyStr = '';
    try {
      metricRootCauseObj = JSON.parse(metricRootCause);
    } catch (e) {
      // console.debug(error)
    }
    if (metricRootCauseObj && category === 'log') {
      const { anomalyValue, percentage, sign } = metricRootCauseObj;
      const percent = `${Math.abs(percentage).toFixed(2)}%`;
      frequencyStr = `${
        isNumber(anomalyValue) ? `Count: ${anomalyValue}. ` : ''
      }Frequency is ${percent} ${sign} than normal.`;
    }

    let anomalyFeatureStr;

    if (anomalyFeatureArr && category === 'log') {
      anomalyFeatureStr = R.map((item) => {
        const [label] = (item.label || '').split(':');
        const name = get(item, 'name');
        return { label: name || label, value: item.value };
      }, anomalyFeatureArr);
      anomalyFeatureStr = R.sort(R.ascend(R.prop('label')), anomalyFeatureStr);
      const featureGroup = R.groupBy((item) => item.label)(anomalyFeatureStr);

      anomalyFeatureStr = R.map((item) => item, R.toPairs(featureGroup));
    }

    return (
      <div className="flex-grow flex-min-height flex-min-width">
        {category === 'metric' ? (
          <>
            {rootCauseDetailsArr.length > 0 && (
              <div className="flex-col" style={{ whiteSpace: 'break-spaces' }}>
                {R.addIndex(R.map)(
                  (event, index) =>
                    EventRenderers.RenderMetricAnomalySummary({
                      intl,
                      event: { ...event, generateFromGenericRule, isRoot },
                      index,
                      metricUnitMap,
                    }),
                  R.take(shouldExpandLimit, rootCauseDetailsArr),
                )}
                {rootCauseDetailsArr.length > shouldExpandLimit && (
                  <Popover
                    placement="right"
                    content={
                      <div
                        className="log-event-group flex-col"
                        style={{
                          width: 650,
                          maxHeight: 400,
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          fontSize: 12,
                          padding: '0 0',
                        }}
                      >
                        <div>
                          {R.addIndex(R.map)(
                            (event, index) =>
                              EventRenderers.RenderMetricAnomalySummary({ intl, event, index, metricUnitMap }),
                            rootCauseDetailsArr,
                          )}
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button
                      type="link"
                      style={{
                        padding: 0,
                        height: 'auto',
                        fontSize: 12,
                      }}
                    >
                      {intl.formatMessage(appFieldsMessages.expand)}
                    </Button>
                  </Popover>
                )}
              </div>
            )}
          </>
        ) : (
          <div style={{ wordBreak: 'break-word' }}>
            {rawData && (
              <LogRenderers.RenderLogContent
                intl={intl}
                rawData={rawData}
                rawDataJson={rawDataJson}
                onToggleCollapse={onToggleCollapse}
                frequencyStr={frequencyStr}
                anomalyWordList={anomalyWords}
                outlierValue={outlierValue}
                owner={event}
                summarySettings={summarySettings}
                currentTheme={currentTheme}
                anomalyFeatureStr={anomalyFeatureStr}
              />
            )}
          </div>
        )}
      </div>
    );
  },
  BuildMetricAnomalySummary: ({
    category,
    event,
    metricUnitMap = {},
    hideName = true,
    hideTime = true,
    hideAvg = false,
  }) => {
    const { instanceDown, isInstanceDown, processCrash } = event;
    const { appName, componentName, timePairArr } = event;
    let { instanceId, rootCauseMetric, metricValue, direction, pct, rootCauseSource } = event;
    const {
      instanceName,
      metricName,
      content,
      anomalyValue,
      avgValue,
      sign,
      metricDirection,
      percentage,
      thresholdSetting,
    } = event;

    if (R.isNil(instanceId) && instanceName) instanceId = instanceName;
    if (R.isNil(rootCauseMetric) && metricName) rootCauseMetric = metricName;
    if (R.isNil(rootCauseMetric) && content) rootCauseMetric = content;
    if (R.isNil(metricValue) && isNumber(anomalyValue)) metricValue = anomalyValue;
    if (R.isNil(metricValue) && isNumber(avgValue)) metricValue = avgValue;
    if (R.isNil(direction) && sign) direction = sign;
    if (R.isNil(direction) && metricDirection) direction = metricDirection;
    if (R.isNil(pct) && isNumber(percentage)) pct = percentage;
    if (isNumber(thresholdSetting)) rootCauseSource = 'threshold';

    if (direction === 'positive') {
      direction = 'higher';
    } else if (direction === 'negative') {
      direction = 'lower';
    }
    const isHigher = direction ? direction.toLowerCase() === 'higher' : null;
    const isThreshold = rootCauseSource === 'threshold';
    const unit = get(metricUnitMap, rootCauseMetric);
    const name =
      appName ||
      (componentName && componentName.indexOf(instanceId) === -1 ? `${instanceId} (${componentName})` : instanceId);
    const startTimestamp = timePairArr && timePairArr.length > 0 ? timePairArr[0].startTimestamp : null;

    if (instanceDown || isInstanceDown) {
      return `Missing data: ${name}`;
    }
    if (category === 'shortDisplay') {
      return `${processCrash ? 'Process Crash: True; ' : ''}Metric: ${rootCauseMetric}; Value: ${numeral(
        metricValue,
      ).format(metricValue > 1 ? '0,0' : '0,0.0[00000]')} ${isHigher ? 'higher' : 'lower'}`;
    }
    return `${rootCauseMetric}${
      hideAvg || R.isNil(metricValue)
        ? ''
        : ` (avg: ${numeral(metricValue).format(metricValue > 1 ? '0,0' : '0,0.0[0000]')}${unit ? ` ${unit}` : ''})`
    } is${pct && !isThreshold ? ` ${numeral(Math.abs(Number(pct) / 100)).format('0.00%')}` : ''}${
      direction
        ? ` ${direction} than ${rootCauseSource || 'normal'}${
            isNumber(thresholdSetting)
              ? ` (${numeral(thresholdSetting).format(thresholdSetting > 1 ? '0,0' : '0,0.0[00000]')})`
              : ''
          }`
        : ''
    }${!hideName && name ? ` at ${name}` : ''}${
      !hideTime && startTimestamp ? ` at ${moment.utc(startTimestamp).format(Defaults.ShortTimeFormat)}` : ''
    }${processCrash ? ` and process crashed` : ''}`;
  },
  RenderMetricAnomalySummary: ({
    intl,
    category,
    event,
    index = 0,
    metricUnitMap = {},
    hideName = true,
    hideTime = true,
    hideAvg = false,
    withEllipsis,
    labelMinWidth = 110,
    highlightColor = 'var(--blue)',
    needAnd,
  }) => {
    const { instanceDown, isInstanceDown, processCrash } = event;
    const { appName, componentName, timePairArr } = event;
    let { instanceId, rootCauseMetric, metricValue, direction, pct, rootCauseSource } = event;
    const {
      instanceName,
      metricName,
      content,
      anomalyValue,
      avgValue,
      sign,
      metricDirection,
      percentage,
      thresholdSetting,
      generateFromGenericRule,
      isRoot,
      patternName,
      patternId,
      avgAnomalyValue,
      avgNormalValue,
    } = event;

    if (R.isNil(instanceId) && instanceName) instanceId = instanceName;
    if (R.isNil(rootCauseMetric) && metricName) rootCauseMetric = metricName;
    if (R.isNil(rootCauseMetric) && content) rootCauseMetric = content;
    if (R.isNil(metricValue) && isNumber(anomalyValue)) metricValue = anomalyValue;
    if (R.isNil(metricValue) && isNumber(avgValue)) metricValue = avgValue;
    if (R.isNil(metricValue) && isNumber(avgAnomalyValue)) metricValue = avgAnomalyValue;
    if (R.isNil(metricValue) && isNumber(avgNormalValue)) metricValue = avgNormalValue;
    if (R.isNil(direction) && sign) direction = sign;
    if (R.isNil(direction) && metricDirection) direction = metricDirection;
    if (R.isNil(pct) && isNumber(percentage)) pct = percentage;
    if (isNumber(thresholdSetting)) rootCauseSource = 'threshold';
    if (!R.isNil(metricValue)) metricValue = Number(Number.parseFloat(metricValue).toFixed(5));

    if (direction === 'positive') {
      direction = 'higher';
    } else if (direction === 'negative') {
      direction = 'lower';
    }
    const isHigher = direction ? direction.toLowerCase() === 'higher' : null;
    const isThreshold = rootCauseSource === 'threshold';
    const unit = get(metricUnitMap, rootCauseMetric);
    const name =
      appName ||
      (componentName && componentName.indexOf(instanceId) === -1 ? `${instanceId} (${componentName})` : instanceId);
    const startTimestamp = timePairArr && timePairArr.length > 0 ? timePairArr[0].startTimestamp : null;

    // isRoot 在 Incident Predicted页面 中是 Predicted Incident
    if (generateFromGenericRule && isRoot) {
      return (
        <div
          key={index}
          className={withEllipsis ? 'hidden-line-with-ellipsis' : ''}
          style={{ wordBreak: 'break-word' }}
        >
          <span style={{ color: highlightColor }}>{rootCauseMetric}</span>
          <span>{' is approaching its'}</span>
          <span>{` ${direction === 'higher' ? 'upper' : 'lower'}`}</span>
          <span>{` bound`}</span>
          <span>{` (${numeral(metricValue).format(metricValue > 1 ? '0,0' : '0,0.0[00000]')}${
            metricValue > 100 ? '' : '%'
          })`}</span>
        </div>
      );
    }
    if (generateFromGenericRule && !isRoot) {
      return (
        <div
          key={index}
          className={withEllipsis ? 'hidden-line-with-ellipsis' : ''}
          style={{ wordBreak: 'break-word' }}
        >
          <span style={{ color: highlightColor }}>{rootCauseMetric}</span>
          <span>{` (${numeral(metricValue).format(metricValue > 1 ? '0,0' : '0,0.0[00000]')})`}</span>
          <span>{' is trending'}</span>
          <span>{` ${direction === 'higher' ? 'up' : 'down'}`}</span>
        </div>
      );
    }

    if (instanceDown || isInstanceDown) {
      return (
        <div key={index} className="flex-col">
          <div className={withEllipsis ? 'hidden-line-with-ellipsis' : ''} style={{ wordBreak: 'break-all' }}>
            <span className="bold light-label inline-block" style={{ minWidth: labelMinWidth }}>
              {intl.formatMessage(appFieldsMessages.instance)}:
            </span>
            <span style={{ marginRight: 16 }}>{name}</span>
          </div>
          <div className={withEllipsis ? 'hidden-line-with-ellipsis' : ''} style={{ wordBreak: 'break-all' }}>
            <span className="bold light-label inline-block" style={{ minWidth: labelMinWidth }}>
              {intl.formatMessage(appFieldsMessages.patternIdName)}:
            </span>
            <span>
              {
                Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true, hasPrefix: false })
                  .patternNameStr
              }
            </span>
          </div>
          {false && (
            <div className={withEllipsis ? 'hidden-line-with-ellipsis' : ''} style={{ wordBreak: 'break-all' }}>
              <span className="bold light-label inline-block" style={{ minWidth: labelMinWidth }}>
                {intl.formatMessage(appFieldsMessages.status)}:
              </span>
              <span>{intl.formatMessage(appFieldsMessages.missingData)}</span>
              <i className="icon down arrow" style={{ color: 'var(--red)' }} />
            </div>
          )}
        </div>
      );
    }

    if (category === 'shortDisplay') {
      return (
        <div key={index} className="flex-col">
          {processCrash && (
            <div className={withEllipsis ? 'hidden-line-with-ellipsis' : ''} style={{ wordBreak: 'break-all' }}>
              <span className="bold light-label inline-block" style={{ marginRight: 16 }}>
                {intl.formatMessage(appFieldsMessages.processCrash)}:
              </span>
              <span style={{ color: 'var(--red)' }}>True</span>
            </div>
          )}
          <div className={withEllipsis ? 'hidden-line-with-ellipsis' : ''} style={{ wordBreak: 'break-all' }}>
            <span className="bold light-label inline-block" style={{ minWidth: labelMinWidth }}>
              {intl.formatMessage(appFieldsMessages.metric)}:
            </span>
            <span>{rootCauseMetric}</span>
          </div>
          <div className={withEllipsis ? 'hidden-line-with-ellipsis' : ''} style={{ wordBreak: 'break-all' }}>
            <span className="bold light-label inline-block" style={{ minWidth: labelMinWidth }}>
              {intl.formatMessage(appFieldsMessages.value)}:
            </span>
            <span>{numeral(metricValue).format(metricValue > 1 ? '0,0' : '0,0.0[00000]')}</span>
            {isHigher && <i className="icon up arrow" style={{ color: 'var(--red)' }} />}
            {!isHigher && <i className="icon down arrow" style={{ color: 'var(--blue)' }} />}
          </div>
        </div>
      );
    }

    return (
      <div key={index} className={withEllipsis ? 'hidden-line-with-ellipsis' : ''} style={{ wordBreak: 'break-word' }}>
        <span style={{ color: highlightColor }}>{rootCauseMetric}</span>
        {hideAvg || R.isNil(metricValue) ? (
          ''
        ) : (
          <span style={{ color: 'var(--green)' }}>{` (avg: ${numeral(metricValue).format(
            metricValue > 1 ? '0,0' : '0,0.0[00000]',
          )}${unit ? ` ${unit}` : ''})`}</span>
        )}
        <span>{' is'}</span>
        {!!pct && !isThreshold && (
          <span style={{ color: isHigher ? 'var(--red)' : 'var(--blue)' }}>{` ${numeral(
            Math.abs(Number(pct) / 100),
          ).format('0.00%')}`}</span>
        )}
        {direction && <span>{` ${direction}`}</span>}
        {direction && <span>{` than`}</span>}
        {direction && <span style={{ color: highlightColor }}>{` ${rootCauseSource || 'normal'}`}</span>}
        {direction && isNumber(thresholdSetting) && (
          <span style={{ color: 'var(--green)' }}>{` (${numeral(thresholdSetting).format(
            thresholdSetting > 1 ? '0,0' : '0,0.0[00000]',
          )})`}</span>
        )}
        {!hideName && name && <span>{` at `}</span>}
        {!hideName && name && <span style={{ color: 'var(--green)' }}>{name}</span>}
        {!hideTime && startTimestamp && <span>{` at `}</span>}
        {!hideTime && startTimestamp && (
          <span style={{ color: 'var(--green)' }}>{moment.utc(startTimestamp).format(Defaults.ShortTimeFormat)}</span>
        )}
        {processCrash && <span>{` and `}</span>}
        {processCrash && <span style={{ color: 'var(--red)' }}>process crashed</span>}
        {needAnd && <span style={{ margin: '0px 4px' }}>and</span>}
      </div>
    );
  },
  RenderRootCauseSimpleList: ({ intl, rowData, index, currentTheme }) => {
    const {
      type,
      componentName,
      patternId,
      patternName,
      metricInstanceName,
      count,
      probability,
      displayTimestamp,
      delay,
    } = rowData;
    const { category, content, metricDirection, avgValue, rawData, rootCause } = rowData;

    const appName = componentName
      ? componentName === metricInstanceName || metricInstanceName.indexOf(`(${componentName})`) >= 0
        ? metricInstanceName
        : `${metricInstanceName} (${componentName})`
      : metricInstanceName;

    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternName, patternId },
      { hasFullName: true, hasPrefix: true },
    );

    let rootCauseJson;
    try {
      rootCauseJson = JSON.parse(rootCause);
    } catch (error) {
      console.debug(error);
    }

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      console.debug(error);
    }
    const isHigher = ['positive', 'higher'].includes(metricDirection);

    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column flex-col" style={{ flex: 1, alignItems: 'flex-start', padding: 6 }}>
          <div className="flex-row">
            <div
              className="bold light-label"
              style={{
                width: 160,
                textAlign: 'right',
                marginRight: 16,
              }}
            >
              {intl.formatMessage(appFieldsMessages.patternIdName)}:
            </div>
            <div>{patternNameStr}</div>
          </div>
          <div className="flex-row">
            <div
              className="bold light-label"
              style={{
                width: 160,
                textAlign: 'right',
                marginRight: 16,
              }}
            >
              {intl.formatMessage(logMessages.instance)}:
            </div>
            <div>{appName}</div>
          </div>
          <div className="flex-row">
            <div
              className="bold light-label"
              style={{
                width: 160,
                textAlign: 'right',
                marginRight: 16,
              }}
            >
              {intl.formatMessage(logMessages.accumulatedProbability)}:
            </div>
            <div>{`${(probability * 100).toFixed(2)}%`}</div>
          </div>
          <div className="flex-row">
            <div
              className="bold light-label"
              style={{
                width: 160,
                textAlign: 'right',
                marginRight: 16,
              }}
            >
              {intl.formatMessage(appFieldsMessages.time)}:
            </div>
            <div>{moment.utc(displayTimestamp).format(Defaults.ShortDateTimeFormat)}</div>
          </div>
          <div className="flex-row">
            <div
              className="bold light-label"
              style={{
                width: 160,
                textAlign: 'right',
                marginRight: 16,
              }}
            >
              {intl.formatMessage(appFieldsMessages.delay)}:
            </div>
            <div>{CellRenderers.humanizeDuration({ period: delay, intl })}</div>
          </div>
          <div className="flex-row">
            <div
              className="bold light-label"
              style={{
                width: 160,
                textAlign: 'right',
                marginRight: 16,
              }}
            >
              {intl.formatMessage(logMessages.type)}:
            </div>
            <div>{CellRenderers.logTypeRenderer({ intl, rowData: { type } })}</div>
          </div>
          {/* <div className="flex-row">
            <div
              className="bold light-label"
              style={{
                width: 160,
                textAlign: 'right',
                marginRight: 16,
              }}
            >
              {intl.formatMessage(logMessages.category)}:
            </div>
            <div>{patternNameStr}</div>
          </div> */}
          <div className="flex-row">
            <div
              className="bold light-label"
              style={{
                width: 160,
                textAlign: 'right',
                marginRight: 16,
              }}
            >
              {intl.formatMessage(logMessages.count)}:
            </div>
            <div>{count}</div>
          </div>
          <div className="flex-row">
            <div
              className="bold light-label"
              style={{
                width: 160,
                textAlign: 'right',
                marginRight: 16,
              }}
            >
              {intl.formatMessage(eventMessages.shortDescription)}:
            </div>
            <div className="flex-grow">
              {rawData && (
                <LogRenderers.ExpandLogJSONContent
                  intl={intl}
                  rawData={rawData}
                  rawDataJson={rawDataJson}
                  currentTheme={currentTheme}
                />
              )}
              {category === 'incident' &&
                rootCauseJson &&
                EventRenderers.RenderMetricAnomalySummary({
                  intl,
                  category: 'shortDisplay',
                  event: rootCauseJson,
                  index: 0,
                  metricUnitMap: {},
                })}
              {category === 'metric' && (
                <div style={{ wordBreak: 'break-all' }}>
                  <span className="bold light-label" style={{ marginRight: 16 }}>
                    {intl.formatMessage(appFieldsMessages.metric)}:
                  </span>
                  <span style={{ marginRight: 16 }}>{content}</span>
                  <span className="bold light-label" style={{ marginRight: 16 }}>
                    {intl.formatMessage(appFieldsMessages.value)}:
                  </span>
                  <span>{round(avgValue, 2)}</span>
                  {isHigher && <i className="icon up arrow" style={{ color: 'var(--red)' }} />}
                  {!isHigher && <i className="icon down arrow" style={{ color: 'var(--blue)' }} />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },

  RenderPredictionTimelines: ({
    intl,
    projects,
    metricUnitMap,
    predictionSourceInfoList,
    event,
    incidentPatternName,
    onLineChartClick,
    onLineChartJump,
    onPredictionSourceLineChartClick,
    onPredictionSourceJumpClick,
    onPredictionSourceTrendPatternsClick,
    onPredictionSourceLogQueryClick,
    onPredictionJumpLogClick,
    summarySettings,
    currentTheme = 'light',
    scroll,
    maxHeight,
    detectedIncident,
    historicalIncident,
    renderDetectedIncident,
    showDetected,
    activeNode,
    hideDetectedIncident,
    instanceDisplayNameMap,
    isJWT,
  }) => {
    const [timelines, setTimelines] = useState([]);
    const [timelineInfos, setTimelineInfos] = useState([]);
    const [sourceInfos, setSourceInfos] = useState([]);
    const [activeKey, setActiveKey] = useState(null);
    const [predictionTimeList, setPredictionTimeList] = useState([]);
    const [occurrenceList, setOccurrenceList] = useState([]);
    const [prevActiveNode, setPrevActiveNode] = useState(activeNode);
    const [prevEvent, setPrevEvent] = useState(event);
    const [prevSourceInfoList, setPrevSourceInfoList] = useState(predictionSourceInfoList);

    if (!isEqual(prevEvent, event) && !isEqual(prevSourceInfoList, predictionSourceInfoList)) {
      setPrevEvent(event);
      setPrevSourceInfoList(predictionSourceInfoList);
    }

    if (!isEqual(prevActiveNode, activeNode)) {
      setPrevActiveNode(activeNode);
    }

    useEffect(() => {
      const incidentKey = prevEvent.id || R.join('-', prevEvent.rootCauseTableKey || []);
      let eventList = [];
      R.forEach((item) => {
        const { sourceKey } = item;
        const newEvent = { ...item, key: sourceKey };
        eventList.push(newEvent);
      }, prevSourceInfoList);

      // build timelines with sorted
      let timelines = R.map((item) => {
        const { sourceKey, predictionTime, sourceDetail } = item;
        const { nid } = sourceDetail || {};
        return { ...item, timestamp: predictionTime, patternIds: [nid], key: sourceKey };
      }, eventList);

      // add root
      timelines.push({ key: incidentKey, timestamp: prevEvent.startTimestamp, isRoot: true, event: prevEvent });
      timelines = R.sortWith([R.ascend(R.prop('timestamp'))], timelines);

      // append incident event to timelineInfos
      eventList.push({ ...prevEvent, key: incidentKey, timestamp: prevEvent.startTimestamp, isRoot: true });
      eventList = R.sortWith([R.ascend(R.prop('timestamp'))], eventList);

      let predictionTimeList = R.filter((item) => !item.isRoot, timelines || []);
      predictionTimeList = R.groupBy((item) => moment.utc(item.timestamp).format('MM-DD'), predictionTimeList);
      const predictionIndentList = [];
      R.forEachObjIndexed((values, key) => {
        R.addIndex(R.forEach)((item, index) => {
          predictionIndentList.push({ ...item, gutter: index === 0 ? 0 : 1 });
        }, values);
      }, predictionTimeList);
      predictionTimeList = predictionIndentList;
      const occurrenceList = R.filter((item) => item.isRoot, timelines || []);

      setOccurrenceList(occurrenceList);
      setPredictionTimeList(predictionTimeList);
      setTimelineInfos(eventList);

      const sourceInfos = eventList;
      let findActiveItem = R.find(
        (item) =>
          `${item.sourceInstanceName}-${item.timestamp}` === `${prevActiveNode?.instanceName}-${prevActiveNode?.time}`,
        predictionTimeList,
      );
      if (prevActiveNode?.isIncident) {
        findActiveItem = R.find((item) => item.key === prevActiveNode?.id, occurrenceList);
      }

      setActiveKey(findActiveItem?.key || null);
      if (findActiveItem) {
        const timeEl = document.getElementById(`time-${findActiveItem.key}`);
        const el = document.getElementById(findActiveItem.key);
        if (timeEl) timeEl.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
        if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
      setTimelines(timelines);
      setSourceInfos(sourceInfos);
    }, [prevEvent, prevSourceInfoList, prevActiveNode]);

    const handleTimelineClick = useCallback(
      (item, rowIndex) => {
        const { key } = item;
        const clear = key && key === activeKey;
        const newActiveKey = clear ? null : key;
        setActiveKey(newActiveKey);

        if (!scroll) {
          const sourceInfos = newActiveKey
            ? R.filter((item) => item.key === newActiveKey, timelineInfos)
            : timelineInfos;
          setSourceInfos(sourceInfos);
        } else {
          const el = document.getElementById(key);
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      },
      [activeKey, timelineInfos],
    );

    return (
      <div className="flex-row" style={{ width: '100%', height: scroll ? '100%' : 'auto', maxHeight }}>
        <div
          style={{
            width: 250,
            minWidth: 230,
            padding: '0 0 0 20px',
            height: '100%',
            overflow: 'hidden auto',
          }}
        >
          <Timeline>
            <div className="flex-row flex-center-align" style={{ marginBottom: 10, marginLeft: -10 }}>
              <span className="font-14">Prediction time</span>
            </div>
            {R.addIndex(R.map)((item, index) => {
              const { key, timestamp, patternIds, gutter = 0, sourceInstanceName } = item;
              const isActive = activeKey === key;

              let allOwnList = [];
              R.forEach((g) => {
                R.forEach((n) => {
                  const ownList = n?.data?.ownList || [];
                  allOwnList = [...allOwnList, ...ownList];
                }, g.nodes || []);
              }, activeNode?.data?.groups || []);
              const list = (R.isEmpty(allOwnList) ? activeNode?.data?.ownList : allOwnList) || [];
              const activeKeys = R.uniq(R.map((node) => `${node.sourceInstanceName}-${node.predictionTime}`, list));
              const isHighlight = R.includes(`${sourceInstanceName}-${timestamp}`, activeKeys);

              return (
                <Timeline.Item
                  key={`${index}predictedTime${key}${timestamp}`}
                  color="green"
                  dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
                  pending={index === predictionTimeList.length - 1}
                  style={{ paddingBottom: index === predictionTimeList.length - 1 ? 0 : 20, marginLeft: gutter * 5 }}
                >
                  <div
                    id={`time-${key}`}
                    className="flex-col"
                    style={{
                      cursor: 'pointer',
                      color: isActive ? '#ff5142' : 'currentColor',
                      marginLeft: -(gutter * 5),
                    }}
                    onClick={() => handleTimelineClick(item)}
                  >
                    <div className="flex-row flex-center-align">
                      <span
                        className="font-14 bold"
                        style={{
                          width: 90,
                          flexShrink: 0,
                          backgroundColor: isHighlight ? 'var(--item-active-bg3)' : 'initial',
                        }}
                      >
                        {moment.utc(timestamp).format(Defaults.ShortTimeFormat)}
                      </span>
                      <div style={{ wordBreak: 'break-word', marginLeft: 6 }}>
                        <span>{item?.sourceDetail?.patternName || R.join(', ', patternIds || [])}</span>
                      </div>
                      {isActive && <CheckOutlined style={{ marginLeft: 6 }} />}
                    </div>
                  </div>
                </Timeline.Item>
              );
            }, predictionTimeList || [])}
          </Timeline>
          <Timeline>
            <div className="flex-row flex-center-align" style={{ marginBottom: 10, marginLeft: -10 }}>
              <span className="font-14">Predicted occurrence time</span>
            </div>
            {R.addIndex(R.map)((item, index) => {
              const { key, timestamp, event } = item;
              const isActive = activeKey === key;
              const isHighlight = key === activeNode?.id;

              const { patternId, patternName } = event || {};
              return (
                <Timeline.Item
                  key={`${index}occurrence${key}${timestamp}`}
                  color="green"
                  dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
                  pending={index === occurrenceList.length - 1}
                  style={{ paddingBottom: index === occurrenceList.length - 1 ? 0 : 20 }}
                >
                  <div
                    id={`time-${key}`}
                    className="flex-col"
                    style={{ cursor: 'pointer', color: isActive ? '#ff5142' : 'currentColor' }}
                    onClick={() => handleTimelineClick(item)}
                  >
                    <div className="flex-row flex-center-align">
                      <span
                        className="font-14 bold"
                        style={{
                          width: 90,
                          flexShrink: 0,
                          backgroundColor: isHighlight ? 'var(--item-active-bg3)' : 'initial',
                        }}
                      >
                        {moment.utc(timestamp).format(Defaults.ShortTimeFormat)}
                      </span>
                      <span style={{ marginLeft: 6 }}>{patternName || patternId}</span>
                      {isActive && <CheckOutlined style={{ marginLeft: 6 }} />}
                    </div>
                  </div>
                </Timeline.Item>
              );
            }, occurrenceList || [])}
          </Timeline>
        </div>
        <div className="flex-grow flex-min-width event-list" style={{ overflow: scroll || 'initial' }}>
          <div
            className="event-list-grid"
            style={{
              borderTopWidth: 1,
              borderTopColor: 'transparent',
              borderLeftColor: 'transparent',
              borderRightColor: 'transparent',
            }}
          >
            {R.addIndex(R.map)((item, index) => {
              const { isRoot, key, hop } = item;
              return (
                <>
                  {!isRoot && hop === 1 && (
                    <div style={{ fontSize: 14, fontWeight: 'bold', padding: '20px 0 0 6px' }}>Causal chain:</div>
                  )}
                  <div
                    key={index + key}
                    className="event-list-row"
                    style={{
                      minHeight: 40,
                      paddingTop: 8,
                    }}
                    id={key}
                  >
                    <div className="row-column" style={{ width: 120, flex: 1 }}>
                      {isRoot && (
                        <EventRenderers.RenderPredictionDetails
                          key={index}
                          intl={intl}
                          index={index}
                          event={prevEvent}
                          incidentPatternName={incidentPatternName}
                          onLineChartClick={onLineChartClick}
                          onLineChartJump={onLineChartJump}
                          summarySettings={summarySettings}
                          currentTheme={currentTheme}
                          predictionSourceInfo={item}
                          onPredictionJumpLogClick={onPredictionJumpLogClick}
                          instanceDisplayNameMap={instanceDisplayNameMap}
                          isJWT={isJWT}
                        />
                      )}

                      {!isRoot && (
                        <EventRenderers.RenderLogPredictionCauseDetails
                          key={index}
                          intl={intl}
                          index={index}
                          event={prevEvent}
                          projects={projects || []}
                          metricUnitMap={metricUnitMap}
                          predictionSourceInfo={item}
                          onLineChartJump={onLineChartJump}
                          showHop={prevSourceInfoList.length > 1}
                          onPredictionSourceLineChartClick={onPredictionSourceLineChartClick}
                          onPredictionSourceJumpClick={onPredictionSourceJumpClick}
                          onPredictionSourceTrendPatternsClick={onPredictionSourceTrendPatternsClick}
                          onPredictionSourceLogQueryClick={onPredictionSourceLogQueryClick}
                          summarySettings={summarySettings}
                          currentTheme={currentTheme}
                          instanceDisplayNameMap={instanceDisplayNameMap}
                          isJWT={isJWT}
                        />
                      )}
                    </div>
                  </div>
                </>
              );
            }, sourceInfos)}
          </div>
          {!prevEvent.isFixedIncident && showDetected && (
            <div style={{ padding: '20px 0 8px' }}>
              <div>
                {!detectedIncident && !historicalIncident && (
                  <div style={{ marginBottom: 16 }}>
                    <Alert
                      message=""
                      description={intl.formatMessage(logMessages.noHistoricalIncidentFound)}
                      type="info"
                      showIcon
                    />
                  </div>
                )}
                {historicalIncident && (
                  <div style={{ marginBottom: 16 }} className="ant-descriptions-table">
                    {renderDetectedIncident('historical', historicalIncident)}
                  </div>
                )}
                {!detectedIncident && !hideDetectedIncident && (
                  <div style={{ marginBottom: 16 }}>
                    <Alert
                      message=""
                      description={intl.formatMessage(logMessages.noCorrespondingIncidentFound)}
                      type="info"
                      showIcon
                    />
                  </div>
                )}
                {detectedIncident && !hideDetectedIncident && (
                  <div style={{ marginBottom: 16 }} className="ant-descriptions-table">
                    {renderDetectedIncident('corresponding', detectedIncident)}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
  RenderPredictionDetails: ({
    intl,
    index,
    event,
    incidentPatternName,
    onLineChartClick,
    onLineChartJump,
    summarySettings,
    currentTheme,
    predictionSourceInfo,
    onPredictionJumpLogClick,
    instanceDisplayNameMap,
    isJWT,
  }) => {
    const {
      category,
      isPrediction,
      isFixedIncident,
      patternId,
      patternName,
      projectDisplayName,
      projectName,
      componentListStr,
      instanceListStr,
      instanceNameString,
      componentName,
      realInstanceName,
      startTimestamp,
      containerInfo,
      generateFromGenericRule,
      projectOwner,
    } = event || {};

    const activePatternName = incidentPatternName && !generateFromGenericRule ? incidentPatternName : patternName;
    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternName: activePatternName, patternId },
      { hasPrefix: true, hasFullName: true },
    );
    const isPredictedIncident = isPrediction;

    const { instanceDisplayName, instanceName, containerName } = getInstanceDisplayName(
      instanceDisplayNameMap,
      containerInfo ? `${containerInfo?.containerName}_${containerInfo?.instanceName}` : realInstanceName,
      {
        pn: projectName,
        owner: projectOwner,
      },
    );
    const width = instanceDisplayName ? 140 : 85;

    return (
      <div className="max-width flex-col" style={{ marginBottom: 8 }} key={index}>
        <div className="flex-row flex-center-align">
          <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16, fontSize: 14 }}>
            {moment.utc(startTimestamp).format(Defaults.ShortTimeFormat)}
          </div>
          {CellRenderers.logTypeRenderer({
            intl,
            rowData: event,
            isPrediction,
            withPredictionPrefix: true,
            isFixedIncident,
          })}
        </div>
        <div className="flex-row flex-center-align">
          <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16, flexShrink: 0 }}>
            {intl.formatMessage(appFieldsMessages.patternIdName)}:
          </div>
          <div className="flex-row flex-center-align flex-wrap flex-grow overflow-hidden">
            <div className="hidden-line-with-ellipsis" style={{ marginRight: 8 }}>
              {patternNameStr}
            </div>
            {category === 'metric' && !isJWT && (
              <Tooltip title={intl.formatMessage(eventMessages.lineChart)} mouseEnterDelay={0.3} placement="top">
                <Button
                  size="small"
                  icon={<LineChartOutlined />}
                  style={{ height: 22 }}
                  onClick={() => onLineChartClick({ event })}
                />
              </Tooltip>
            )}
            {category !== 'metric' && !isJWT && (
              <Tooltip title={intl.formatMessage(eventMessages.lineChart)} mouseEnterDelay={0.3} placement="top">
                <Button
                  size="small"
                  icon={<LineChartOutlined />}
                  style={{ height: 22 }}
                  onClick={() => onLineChartJump({ event })}
                />
              </Tooltip>
            )}
            {category !== 'metric' && isPredictedIncident && isFunction(onPredictionJumpLogClick) && !isJWT && (
              <Tooltip
                overlayClassName="small-popover"
                placement="top"
                mouseEnterDelay={0.3}
                title={intl.formatMessage(appMenusMessages.logAnalysis)}
              >
                <Button
                  size="small"
                  icon={<UnorderedListOutlined />}
                  style={{ height: 22, marginLeft: 4 }}
                  onClick={() => onPredictionJumpLogClick(event, predictionSourceInfo)}
                />
              </Tooltip>
            )}
          </div>
        </div>
        {containerName && (
          <div className="flex-row">
            <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16 }}>
              {intl.formatMessage(appFieldsMessages.container)}:
            </div>
            <div className="flex-grow flex-min-width" style={{ wordBreak: 'break-all' }}>
              {containerName}
            </div>
          </div>
        )}
        <div className="flex-row flex-center-align">
          <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16 }}>
            {intl.formatMessage(appFieldsMessages.instance)}:
          </div>
          <span className="flex-grow hidden-line-with-ellipsis">
            <Popover
              mouseEnterDelay={0.3}
              placement="top"
              content={
                <div style={{ maxWidth: 400, maxHeight: 400, overflowY: 'auto' }}>
                  <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                    <div className="bold" style={{ minWidth: width }}>
                      {intl.formatMessage(appFieldsMessages.project)}:
                    </div>
                    {projectDisplayName || projectName}
                  </div>
                  <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                    <div className="bold" style={{ minWidth: width }}>
                      {intl.formatMessage(appFieldsMessages.component)}:
                    </div>
                    {componentName}
                  </div>
                  <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                    <div className="bold" style={{ minWidth: width }}>
                      {intl.formatMessage(appFieldsMessages.instance)}:
                    </div>
                    {instanceName}
                  </div>
                  {instanceDisplayName && (
                    <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                      <div className="bold" style={{ minWidth: width }}>
                        {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                      </div>
                      {instanceDisplayName}
                    </div>
                  )}
                </div>
              }
            >
              <div className="hidden-line-with-ellipsis inline-block max-width">
                {instanceDisplayName || instanceName}
              </div>
            </Popover>
          </span>
        </div>
        <div className="flex-row" style={{ marginTop: 8 }}>
          <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16 }}>
            {intl.formatMessage(eventMessages.shortDescription)}:
          </div>
          <EventRenderers.RenderShortDescription
            intl={intl}
            event={event}
            defaultContentSize={280}
            summarySettings={summarySettings}
            currentTheme={currentTheme}
            isRoot
          />
        </div>
      </div>
    );
  },
  RenderLogPredictionCauseDetails: ({
    intl,
    projects,
    index,
    metricUnitMap,
    predictionSourceInfo,
    showHop,
    event,
    onLineChartJump,
    onPredictionSourceLineChartClick,
    onPredictionSourceJumpClick,
    onPredictionSourceTrendPatternsClick,
    onPredictionSourceLogQueryClick,
    summarySettings,
    currentTheme,
    instanceDisplayNameMap,
    isJWT,
  }) => {
    const { generateFromMetricAnomalyIncident, generateFromGenericRule } = event;
    const {
      sourceProjectName,
      componentName,
      sourceInstanceName,
      containerId,
      hop,
      predictionTime,
      sourceProjectOwner,
    } = predictionSourceInfo || {};
    const sourceDetail = parseJSON(predictionSourceInfo.sourceDetail) || {};
    const { type, nid, content, patternName } = sourceDetail;
    const isMetric = type === 'Metric';
    // const content = JSON.stringify({ user: 'zinan', testlog: content1 });

    let newType = type;
    let isAlert = false;
    let isIncident = false;

    // change the event type if is deployment project
    const project = R.find((project) => project.projectShortName === sourceProjectName, projects || []);
    let projectDisplayName;
    if (project) {
      if (R.toLower(project.dataType) === 'deployment') newType = 'deployment';
      isAlert = get(project, ['dataType', 'isAlert'], false);
      isIncident = get(project, ['dataType', 'isIncident'], false);
      projectDisplayName = project.projectDisplayName || '';
    }

    const { eventType } = CausalParser.getRelationLogType(newType);
    let rawDataJson;
    try {
      rawDataJson = JSON.parse(content);
    } catch (error) {
      // console.debug(error);
    }
    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternId: nid, patternName: patternName || nid },
      { hasFullName: true },
    );

    const { instanceDisplayName, instanceName, containerName } = getInstanceDisplayName(
      instanceDisplayNameMap,
      sourceInstanceName,
      {
        pn: sourceProjectName,
        owner: sourceProjectOwner,
      },
    );
    const width = instanceDisplayName ? 140 : 85;

    return (
      <div className="max-width flex-col" style={{ marginBottom: 8 }} key={`predictionCause${index}`}>
        <div className="flex-row flex-center-align">
          <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16, fontSize: 14 }}>
            {moment.utc(predictionTime).format(Defaults.ShortTimeFormat)}
          </div>
          <div className="flex-row flex-grow flex-wrap flex-center-align">
            {CellRenderers.logTypeRenderer({ intl, rowData: { type: eventType } })}
            <span style={{ width: 16, display: 'inline-block' }} />
          </div>
        </div>

        {showHop && (
          <div className="flex-row">
            <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16 }}>
              {intl.formatMessage(eventMessages.predictionHop)}:
            </div>
            <span>{hop}</span>
          </div>
        )}

        <div className="flex-row flex-center-align">
          <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16, flexShrink: 0 }}>
            {intl.formatMessage(appFieldsMessages.patternIdName)}:
          </div>
          <div className="flex-row flex-center-align flex-wrap flex-grow overflow-hidden">
            <span className="hidden-line-with-ellipsis" style={{ marginRight: 8 }}>
              {patternNameStr}
            </span>
            {type === 'Metric' && isFunction(onPredictionSourceLineChartClick) && !isJWT && (
              <Popover
                overlayClassName="small-popover"
                placement="top"
                title={null}
                mouseEnterDelay={0.3}
                content={intl.formatMessage(DashboardMessages.lineChart)}
              >
                <Button
                  size="small"
                  icon={<LineChartOutlined />}
                  style={{ height: 22, marginRight: 4 }}
                  onClick={() => onPredictionSourceLineChartClick(event, predictionSourceInfo)}
                />
              </Popover>
            )}
            {type !== 'Metric' && isFunction(onLineChartJump) && !isJWT && (
              <Popover
                overlayClassName="small-popover"
                placement="top"
                title={null}
                mouseEnterDelay={0.3}
                content={intl.formatMessage(DashboardMessages.lineChart)}
              >
                <Button
                  size="small"
                  icon={<LineChartOutlined />}
                  style={{ height: 22, marginRight: 4 }}
                  onClick={() => onLineChartJump({ event, predictionSourceInfo })}
                />
              </Popover>
            )}
            {type !== 'Metric' && isFunction(onPredictionSourceJumpClick) && !isJWT && (
              <Popover
                overlayClassName="small-popover"
                placement="top"
                title={null}
                mouseEnterDelay={0.3}
                content={
                  isAlert || isIncident
                    ? intl.formatMessage(appMenusMessages.alertAnalysis)
                    : intl.formatMessage(appMenusMessages.logAnalysis)
                }
              >
                <Button
                  size="small"
                  icon={<UnorderedListOutlined />}
                  style={{ height: 22, marginRight: 4 }}
                  onClick={() => onPredictionSourceJumpClick(event, predictionSourceInfo)}
                />
              </Popover>
            )}
            {type !== 'Metric' && isFunction(onPredictionSourceTrendPatternsClick) && !isJWT && (
              <Popover
                overlayClassName="small-popover"
                placement="top"
                title={null}
                mouseEnterDelay={0.3}
                content={intl.formatMessage(eventMessages.patternTrend)}
              >
                <Button
                  size="small"
                  icon={<TrendIcon />}
                  style={{ height: 22, marginRight: 4 }}
                  onClick={() => onPredictionSourceTrendPatternsClick(event, predictionSourceInfo)}
                />
              </Popover>
            )}
            {type !== 'Metric' && isFunction(onPredictionSourceLogQueryClick) && !isJWT && (
              <Popover
                overlayClassName="small-popover"
                placement="top"
                title={null}
                mouseEnterDelay={0.3}
                content={intl.formatMessage(appMenusMessages.logQuery)}
              >
                <Button
                  size="small"
                  icon={<QeuryIcon />}
                  style={{ height: 22, marginRight: 4 }}
                  onClick={() => onPredictionSourceLogQueryClick(event, predictionSourceInfo)}
                />
              </Popover>
            )}
          </div>
        </div>
        {containerName && (
          <div className="flex-row">
            <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16 }}>
              {intl.formatMessage(appFieldsMessages.container)}:
            </div>
            <span className="flex-grow" style={{ wordBreak: 'break-all' }}>
              {containerName}
            </span>
          </div>
        )}
        <div className="flex-row flex-center-align">
          <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16 }}>
            {intl.formatMessage(appFieldsMessages.instance)}:
          </div>
          <span className="flex-grow hidden-line-with-ellipsis">
            <Popover
              mouseEnterDelay={0.3}
              placement="top"
              content={
                <div style={{ maxWidth: 400, maxHeight: 400, overflowY: 'auto' }}>
                  <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                    <div className="bold" style={{ minWidth: width }}>
                      {intl.formatMessage(appFieldsMessages.project)}:
                    </div>
                    {projectDisplayName || sourceProjectName}
                  </div>
                  <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                    <div className="bold" style={{ minWidth: width }}>
                      {intl.formatMessage(appFieldsMessages.component)}:
                    </div>
                    {componentName}
                  </div>
                  <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                    <div className="bold" style={{ minWidth: width }}>
                      {intl.formatMessage(appFieldsMessages.instance)}:
                    </div>
                    {instanceName}
                  </div>
                  {instanceDisplayName && (
                    <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                      <div className="bold" style={{ minWidth: width }}>
                        {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                      </div>
                      {instanceDisplayName}
                    </div>
                  )}
                </div>
              }
            >
              <span className="hidden-line-with-ellipsis inline-block max-width">
                {instanceDisplayName || instanceName}
              </span>
            </Popover>
          </span>
        </div>
        <div className="flex-row" style={{ marginTop: 8 }}>
          <div className="bold light-label" style={{ width: 120, textAlign: 'left', marginRight: 16 }}>
            {intl.formatMessage(eventMessages.shortDescription)}:
          </div>
          {type === 'Metric' && (
            <div className="flex-grow flex-min-width" style={{ wordBreak: 'break-word' }}>
              {EventRenderers.RenderMetricAnomalySummary({
                intl,
                event: {
                  ...sourceDetail,
                  // avgValue: generateFromMetricAnomalyIncident ? sourceDetail.realValue : sourceDetail.avgValue,
                  avgValue: sourceDetail.realValue || sourceDetail.avgValue,
                  generateFromGenericRule,
                },
                metricUnitMap,
              })}
            </div>
          )}
          {type !== 'Metric' && content && (
            <div className="flex-grow flex-min-width">
              <LogRenderers.RenderLogContent
                intl={intl}
                rawData={content}
                rawDataJson={rawDataJson}
                owner={event}
                summarySettings={summarySettings}
                currentTheme={currentTheme}
              />
            </div>
          )}
        </div>
      </div>
    );
  },
  RenderTraceDetails: ({ intl, userInfo, projects, incident, currentTheme }: Object) => {
    return (
      <div className="full-width">
        {EventRenderers.RenderEventEntry({
          intl,
          isReadUser: userInfo.isReadUser,
          projects,
          idx: incident.id,
          event: incident,
          instanceName: incident.instanceName,
          style: {},
          category: incident.category,
          metricUnitMap: {},
          isAlert: R.toLower(incident.type) !== 'deployment',
          showDescriptionTitle: false,
          currentTheme,
        })}
      </div>
    );
  },
  RenderPatternReporterTooltip: ({
    intl,
    timezoneOffset = 0,
    reporterRecordSet,
    children,
    placement = 'top',
    isReadUser,
  }: Object) => {
    return (
      <Popover
        title={isReadUser ? null : intl.formatMessage(eventActionMessages.reporter)}
        content={
          isReadUser ? (
            intl.formatMessage(eventMessages.isReadUserDisable)
          ) : (
            <div className="flex-col overflow-y-auto" style={{ maxWidth: 400, maxHeight: 150 }}>
              {R.addIndex(R.map)((item, idx) => {
                const { timestamp, reporterName, action } = item;
                const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
                return (
                  <div key={idx} className="flex-row flex-center-align">
                    <div>{moment.utc(customerTimestamp).format(Defaults.ShortTimeFormat)}</div>
                    <div className="bold light-label" style={{ marginLeft: 16, marginRight: 8 }}>
                      {intl.formatMessage(eventActionMessages.reporter)}:
                    </div>
                    <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                    <span style={{ margin: '0 4px' }}>{reporterName}</span>
                    <div className="bold light-label" style={{ marginLeft: 8, marginRight: 4 }}>
                      {intl.formatMessage(eventActionMessages.action)}:
                    </div>
                    <span style={{ margin: '0 4px' }}>{action}</span>
                  </div>
                );
              }, reporterRecordSet)}
            </div>
          )
        }
        mouseEnterDelay={0.3}
        placement={placement}
      >
        {children}
      </Popover>
    );
  },
  RenderIncidentStatusTooltipContent: ({
    intl,
    credentials,
    timezoneOffset = 0,
    reporterRecordSet,
    statusColorMap,
    incident,
    updateLastActionInfo,
  }: Object) => {
    const channelNameMap = {
      Teams: 'Teams channel',
      Slack: 'Slack channel',
    };
    const statusMap = {
      created: 'Created',
      'in progress': 'Active',
      closed: 'Closed',
    };
    const [loading, setLoading] = useState(false);
    const [channels, setChannels] = useState([]);
    useEffect(() => {
      setLoading(true);

      const { projectName, projectOwner, instanceName, incidentTimestamp } = incident || {};
      if (updateLastActionInfo) updateLastActionInfo();
      fetchGet(getEndpoint('incident-channel-info'), {
        ...credentials,
        customerName: projectOwner,
        projectName,
        instanceName,
        incidentTimestamp,
      })
        .then((data) => {
          setChannels(data || []);
          setLoading(false);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          setLoading(false);
        });
    }, [incident]);

    return (
      <div className="flex-col overflow-y-auto" style={{ maxWidth: 350, maxHeight: 150, minWidth: 350, minHeight: 30 }}>
        <Spin spinning={loading}>
          {R.map((channel) => {
            const { channelType, channelName } = channel || {};
            return (
              <div key={channelType}>
                <span className="light-label bold" style={{ marginRight: 4 }}>
                  {channelNameMap[channelType] || channelType}:
                </span>{' '}
                {channelName}
              </div>
            );
          }, channels)}
          {channels.length > 0 && <Divider style={{ margin: '4px 0' }} />}
          <div>
            {R.addIndex(R.map)((item, idx) => {
              const { timestamp, reporterName, action } = item;
              const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
              return (
                <div key={idx} className="flex-row flex-center-align">
                  <div style={{ width: 80 }}>{moment.utc(customerTimestamp).format(Defaults.ShortTimeFormat)}</div>
                  <div className="bold light-label" style={{ marginRight: 4 }}>
                    {intl.formatMessage(eventActionMessages.reporter)}:
                  </div>
                  <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                  <span style={{ marginLeft: 2 }}>{reporterName}</span>
                  <div className="bold light-label" style={{ marginLeft: 8, marginRight: 4 }}>
                    {intl.formatMessage(eventActionMessages.action)}:
                  </div>
                  <span style={{ margin: '0 4px', color: statusColorMap[action] }}>{statusMap[action] || action}</span>
                </div>
              );
            }, reporterRecordSet)}
            {reporterRecordSet.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: 0 }} />}
          </div>
        </Spin>
      </div>
    );
  },
  RenderIncidentRCATooltipContent: ({ intl, credentials, incident, updateLastActionInfo }: Object) => {
    const [loading, setLoading] = useState(false);
    const [logRootCauseEvents, setlogRootCauseEvents] = useState([]);
    const [metricRootCauseEvents, setmetricRootCauseEvents] = useState([]);
    useEffect(() => {
      setLoading(true);
      const {
        category,
        anomalyLogInstance,
        instanceList,
        startTimestamp,
        endTimestamp,
        rootCauseTableKey,
        patternId,
        type,
        projectOwner,
      } = incident;
      let { projectName } = incident;
      projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
      const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
      const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
      const event = {
        nid: patternId,
        eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
      };
      if (updateLastActionInfo) updateLastActionInfo();
      fetchPost(getEndpoint('logCausalInfoServlet', 1), {
        ...credentials,
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime,
        endTime,
        operation: 'rootCauseEvents',
        rootCauseTableKey: JSON.stringify(rootCauseTableKey),
        event: JSON.stringify(event),
      })
        .then((data) => {
          const { logRootCauseEvents, metricRootCauseEvents } = data;
          setlogRootCauseEvents(logRootCauseEvents || []);
          setmetricRootCauseEvents(metricRootCauseEvents || []);
          setLoading(false);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          setLoading(false);
        });
    }, [incident]);
    return (
      <Spin spinning={loading}>
        <div style={{ maxWidth: 300 }}>
          <div className="flex-row">
            <div className="light-label bold">
              {`${intl.formatMessage(appFieldsMessages.RCA)} - ${intl.formatMessage(logMessages.relatedAnomalies)}`}
            </div>
          </div>
          <Divider style={{ margin: '5px 0' }} />
          <div className="flex-row flex-space-between">
            <div className="light-label bold" style={{ marginRight: 10 }}>
              {intl.formatMessage(logMessages.precedingAnomalies)}
            </div>
            {logRootCauseEvents.length}
          </div>
          <div className="flex-row flex-space-between">
            <div className="light-label bold" style={{ marginRight: 10 }}>
              {intl.formatMessage(eventMessages.metricSourceRootCauses)}
            </div>
            {metricRootCauseEvents.length}
          </div>
        </div>
      </Spin>
    );
  },
  RenderIncidentNotesToolTipContent: ({
    intl,
    credentials,
    environmentId,
    systemId,
    incident,
    updateLastActionInfo,
  }) => {
    const { isIncident } = incident;
    const [loading, setLoading] = useState(false);
    const [summaryNotesList, setSummaryNotesList] = useState([]);
    const [responseItemsList, setResponseItemsList] = useState([]);
    const [slackNotesList, setSlackNotesList] = useState([]);
    useEffect(() => {
      setLoading(true);
      const {
        anomalyLogInstance,
        instanceList,
        patternId,
        projectOwner,
        projectName,
        instanceName,
        incidentTimestamp,
      } = incident;
      const request = [];
      request.push(
        fetchGet(getEndpoint('logpatterntriage'), {
          ...credentials,
          customerName: projectOwner,
          projectName,
          instanceName: anomalyLogInstance || instanceList[0],
          patternId,
        }),
      );
      if (isIncident) {
        request.push(
          fetchGet(getEndpoint('get-teams'), {
            ...credentials,
            environmentName: environmentId,
            systemName: systemId,
            customerName: projectOwner,
            projectName,
            instanceName,
            incidentTimestamp,
          }),
        );
        request.push(
          fetchGet(getEndpoint('get-slack'), {
            ...credentials,
            environmentName: environmentId,
            systemName: systemId,
            customerName: projectOwner,
            projectName,
            instanceName,
            incidentTimestamp,
          }),
        );
      }
      if (updateLastActionInfo) updateLastActionInfo();
      Promise.all(request)
        .then((data) => {
          if (data[0]) {
            const summaryNotesData = data[0];
            let triageReportList = [];
            triageReportList = parseJSON(get(summaryNotesData, 'triageReportList')) || [];
            triageReportList = R.map((item) => {
              const { instanceName, triageReport } = item;
              const { timestamp, fieldName, report, triageReporterManagement } = triageReport;
              const key = `${instanceName}-${timestamp}-${fieldName}-${report}`;

              let reporterRecordSet = [];
              const { reporterMap } = triageReporterManagement || {};
              R.forEachObjIndexed((val, action) => {
                const { reporterDetailMap } = val || {};
                R.forEach((item) => {
                  const { reporterName, reportTimestampSet } = item;
                  R.forEach((timestamp) => {
                    reporterRecordSet.push({
                      timestamp,
                      reporterName,
                      action,
                    });
                  }, reportTimestampSet || []);
                }, R.values(reporterDetailMap || {}));
              }, reporterMap);
              reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))], reporterRecordSet);

              return {
                instanceName,
                key,
                timestamp,
                fieldName,
                report,
                triageReporterManagement,
                reporterRecordSet,
              };
            }, triageReportList);
            triageReportList = R.sortWith([R.descend(R.prop('timestamp'))], triageReportList);
            setSummaryNotesList(triageReportList);
          }
          if (data[1]) {
            const { success } = data[1] || {};
            if (success) {
              fetchGet(getEndpoint('summarize-teams'), {
                ...credentials,
                environmentName: environmentId,
                systemName: systemId,
                customerName: projectOwner,
                projectName,
                instanceName,
                incidentTimestamp,
                amount: 'full',
              })
                .then((data) => {
                  const { success, responseItems } = data || {};
                  if (success) {
                    setResponseItemsList(responseItems || []);
                  }
                })
                .catch((err) => {
                  message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
                  setLoading(false);
                });
            }
          }
          if (data[2]) {
            const { success } = data[2] || {};
            if (success) {
              fetchGet(getEndpoint('summarize-slack'), {
                ...credentials,
                environmentName: environmentId,
                systemName: systemId,
                customerName: projectOwner,
                projectName,
                instanceName,
                incidentTimestamp,
                amount: 'full',
              })
                .then((data) => {
                  const { success, responseItems } = data || {};
                  if (success) {
                    setSlackNotesList(responseItems || []);
                  }
                })
                .catch((err) => {
                  message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
                  setLoading(false);
                });
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          setLoading(false);
          setSummaryNotesList([]);
          setResponseItemsList([]);
          setSlackNotesList([]);
        });
    }, [incident]);
    return (
      <Spin spinning={loading}>
        <div style={{ maxWidth: 300 }}>
          <div className="flex-row">
            <div className="light-label bold">{intl.formatMessage(eventActionMessages.viewOperatorNotes)}</div>
          </div>
          <Divider style={{ margin: '5px 0' }} />
          <div className="flex-row flex-space-between">
            <div className="light-label bold" style={{ marginRight: 10 }}>
              {intl.formatMessage(eventActionMessages.summaryNotes)}
            </div>
            {summaryNotesList.length}
          </div>
          {isIncident && (
            <>
              <div className="flex-row flex-space-between">
                <div className="light-label bold" style={{ marginRight: 10 }}>
                  {intl.formatMessage(eventActionMessages.microsoftTeams)}
                </div>
                {responseItemsList.length}
              </div>
              <div className="flex-row flex-space-between">
                <div className="light-label bold" style={{ marginRight: 10 }}>
                  {intl.formatMessage(eventActionMessages.slackNotes)}
                </div>
                {slackNotesList.length}
              </div>
            </>
          )}
        </div>
      </Spin>
    );
  },
};

export { EventRenderers };
