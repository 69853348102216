import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import VLink from 'valuelink';
import RcDatePicker from 'react-datepicker';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Select, Alert, Spin, Button, message } from 'antd';

import { State } from '../../../src/common/types';
import { Modal, Input } from '../../../src/lib/fui/react';
import { BackgroundCall, Defaults, localDateToUtcObj, utcObjToLocalDate } from '../../../src/common/utils';
import {
  createLoadAction,
  loadProjectInfo,
  updateLastActionInfo,
  createSetAction,
} from '../../../src/common/app/actions';
import { getLogContextEventList } from '../../../src/common/apis';

import { appFieldsMessages } from '../../../src/common/app/messages';

// eslint-disable-next-line
import EventGroup from './event-group';
import { TimeInput } from '../../../src/web/share/TimeInput';
import { DEFAULT_EXPAND_DURATION, getDurationMs } from '../../../src/common/utils/DurationUtils';
import fetchGet from '../../../src/common/apis/fetchGet';
import getEndpoint from '../../../apis/get-endpoint';
import getInstanceDisplayName from '../../../src/common/utils/getInstanceDisplayName';

type Props = {
  incident: Object,
  // eslint-disable-next-line
  projectName: String,
  instanceName: String,
  startTimestamp: Number,
  endTimestamp: Number,
  keywordFilter: String,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  projectList: Array<Object>,
  // eslint-disable-next-line
  projectInstanceComponentMap: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  loadProjectInfo: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createSetAction: Function,

  // eslint-disable-next-line
  logEntryContextListTotal: Number,
  logEntryContextHighlightPages: Array<Number>,
  // eslint-disable-next-line
  logEntryContextResetPage: ?Number,
  useTimeRange: Boolean,
};

class EventContextModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.filterData = [];
    this.events = [];
    this.pageSize = 99999;
    this.MAX_TOTAL_COUNT = Number.MAX_SAFE_INTEGER;

    this.clusterSampleMsgs = {};

    const { projectList } = props;
    const { incident, startTimestamp, endTimestamp, keywordFilter } = props;
    let { projectName, instanceName } = props;
    projectName = projectName || get(incident, ['projectName']);
    instanceName = instanceName || get(incident, ['instanceName']);
    const keyword = R.trim(R.replace(/"/g, '', keywordFilter || ''));

    const currentProject = R.find((project) => project.projectName === projectName, projectList || []);
    const systemProjectList =
      currentProject && currentProject.systemId
        ? R.filter((project) => !project.isMetric && project.systemId === currentProject.systemId, projectList || [])
        : [];

    this.state = {
      isLoading: false,
      isLoaded: false,
      initLoad: true,

      projectName,
      instanceName,
      keywordFilter: keywordFilter || '',
      keyword: keyword || '',
      startTimeObj: moment.utc(startTimestamp),
      endTimeObj: moment.utc(endTimestamp),
      startTimestamp,
      endTimestamp,

      tableKey: new Date().valueOf(),
    };
    this.projectListOptions =
      systemProjectList.length > 0
        ? R.map((item) => ({ value: item.projectName, label: item.projectDisplayName }), systemProjectList)
        : [{ value: projectName, label: currentProject ? currentProject.projectDisplayName : projectName }];
    this.instanceListOptions = [];
  }

  componentDidMount() {
    const { projectList } = this.props;
    const { projectName } = this.state;

    // relaod project meta data
    const currentProject = R.find((project) => project.projectName === projectName, projectList || []);
    if (currentProject && !currentProject.hasAllInstanceInfo) {
      this.reloadInstance();
    } else {
      this.parseData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projectList !== this.props.projectList) {
      this.parseData(nextProps);
    }
  }

  @autobind
  reloadInstance() {
    const { loadProjectInfo } = this.props;
    const { projectName, startTimestamp, endTimestamp } = this.state;

    if (projectName && startTimestamp && endTimestamp) {
      this.setState({ isLoading: true });
      const startTimeObj = moment.utc(startTimestamp).startOf('day');
      const endTimeObj = moment.utc(endTimestamp).endOf('day');
      loadProjectInfo(
        {
          projectName,
          includeInstance: true,
          startTimestamp: startTimeObj.valueOf(),
          endTimestamp: endTimeObj.valueOf(),
        },
        false,
      );
    }
  }

  @autobind
  async parseData(props) {
    const { projectList, projectInstanceComponentMap, credentials, updateLastActionInfo, createSetAction } = props;
    const { projectName, instanceName, startTimeObj, endTimeObj } = this.state;
    const currentProject = R.find((project) => project.projectName === projectName, projectList);
    const instanceList = currentProject ? currentProject.instanceList : [];
    this.setState({ isLoading: true });

    // get all components from instances
    const prevInstanceComponentMap = get(projectInstanceComponentMap, projectName, {});
    const { allInstanceComponentMap } = await BackgroundCall.GetSetComponent({
      updateLastActionInfo,
      createSetAction,
      credentials,
      prevInstanceComponentMap,
      projectName,
      instanceNameList: instanceList,
    });
    const appNameList = !R.isEmpty(allInstanceComponentMap)
      ? { ...prevInstanceComponentMap, ...allInstanceComponentMap }
      : prevInstanceComponentMap;

    let newInstance = instanceName;
    if (!instanceName || !instanceList.includes(instanceName)) {
      newInstance = instanceList[0];
    }

    const instanceDisplayNameMap = {};
    await fetchGet(getEndpoint('instance-display-name'), {
      ...credentials,
      instanceDisplayNameRequestList: JSON.stringify([
        { projectName: currentProject?.projectShortName, customerName: currentProject?.owner },
      ]),
    })
      .then((d1) => {
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, d1 || []);
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });

    this.instanceListOptions = R.map((i) => {
      const component = get(appNameList, i);
      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, i, {
        pn: currentProject?.projectShortName,
        owner: currentProject?.owner,
      });
      return { value: i, label: component && component !== i ? `${instanceStr} (${component})` : instanceStr };
    }, instanceList || []);
    this.events = [];
    this.filterData = [];
    this.clusterSampleMsgs = {};
    this.setState({
      instanceName: newInstance,
      isLoading: false,
      isLoaded: true,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      initLoad: true,
    });
  }

  @autobind
  handleRefresh() {
    const { startTimeObj, endTimeObj } = this.state;
    this.events = [];
    this.filterData = [];
    this.clusterSampleMsgs = {};
    this.setState({
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      initLoad: true,
      tableKey: new Date().valueOf(),
    });
  }

  @autobind
  handleInstanceChange(instanceName) {
    this.setState({ instanceName });
  }

  @autobind
  iconSearchClick(e) {
    e.stopPropagation();
    e.preventDefault();
    const { keywordFilter } = this.state;
    const keyword = R.trim(R.replace(/"/g, '', keywordFilter));
    this.filterData = R.sort((a, b) => a.timestamp - b.timestamp, this.events);
    this.setState({ keyword });
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  @autobind
  async loadMoreRows(startTimestamp, endTimestamp, i, isUpLoader) {
    const { credentials, incident } = this.props;
    const { timestamp, rawDataBackup } = incident;
    const { projectName, instanceName, initLoad } = this.state;
    const rawData = rawDataBackup || get(incident, ['rootCauseJson', 'rawData']) || get(incident, ['rawData']);

    if (startTimestamp > endTimestamp) {
      startTimestamp = endTimestamp - getDurationMs(DEFAULT_EXPAND_DURATION);
      this.setState({ startTimeObj: moment.utc(startTimestamp) });
    }

    const patternId = incident.patternId || incident.nid;
    const apiParams = {
      projectName,
      instanceName,
      timestamp,
      startTimestamp,
      endTimestamp,
      consolidateFlag: false,
      pageNo: 1,
      pageSize: this.pageSize,
    };
    // console.log(moment.utc(startTimestamp).format('MM-DD HH:mm:ss'), startTimestamp);
    // console.log(moment.utc(endTimestamp).format('MM-DD HH:mm:ss'), endTimestamp);

    if (patternId) {
      apiParams.patternIds = JSON.stringify([patternId]);
    }
    if (initLoad) {
      apiParams.nidToHighlight = patternId;
    }
    if (isUpLoader) {
      this.setState({ startTimeObj: moment.utc(startTimestamp) });
    } else {
      this.setState({ endTimeObj: moment.utc(endTimestamp) });
    }

    let events = [];
    const clusterSampleMsgs = {};
    let succeed = false;
    let scrollIndex = 0;

    try {
      const d = await getLogContextEventList(credentials, apiParams);

      const { data, clusterInfoList } = d;
      const totalCount = data?.totalCount;
      events = data?.events || [];
      if (totalCount > events.length) {
        console.warn('[IF] page size is too small to retrieve all log entries in time range');
      }

      R.forEach((c) => {
        const sampleMsgs = get(c, ['sampleMsg'], []);
        R.addIndex(R.forEach)((s, idx) => {
          clusterSampleMsgs[`${c.nid}[${idx}]`] = s;
        }, sampleMsgs);
      }, clusterInfoList || []);
      succeed = true;
      this.events = R.sort((a, b) => b.timestamp - a.timestamp, [...this.events, ...events]);
      scrollIndex = R.findLastIndex((item) => {
        return (item.timestamp === timestamp && item.rawData === rawData) || item.highlightFlag;
      }, events);
      scrollIndex = scrollIndex <= 0 ? Math.ceil(events.length / 2) : scrollIndex;

      this.setState({
        initLoad: false,
      });
    } catch (err) {
      console.error('[IF_API] Failed get event list', err);
    }
    return {
      succeed,
      events,
      clusterSampleMsgs,
      ...(initLoad && scrollIndex > 0 ? { scrollIndex } : {}),
    };
  }

  render() {
    const { intl, incident, logEntryContextHighlightPages, useTimeRange } = this.props;
    const { isLoading, isLoaded } = this.state;
    const { projectName, instanceName, startTimeObj, endTimeObj, keyword, initLoad, tableKey } = this.state;

    const keywordFilterLink = VLink.state(this, 'keywordFilter').check(
      (value) => !value || (Boolean(value) && R.test(/^"[^\n\r]+"$/, value)),
      'keyword must be in the right format',
    );

    const { timestamp, rawDataBackup } = incident;
    const rawData = rawDataBackup || get(incident, ['rootCauseJson', 'rawData']) || get(incident, ['rawData']);
    const hasSearchError = keywordFilterLink.error;
    return (
      <Modal
        width={1400}
        title="Context"
        onCancel={this.handleClose}
        visible
        maskClosable={false}
        footer={null}
        bodyStyle={{ height: 700 }}
      >
        <Spin spinning={isLoading}>
          <div className="flex-col" style={{ height: 650 }}>
            <div className="flex-row" style={{ marginBottom: 8 }}>
              <div className="flex-grow flex-row flex-center-align" style={{ paddingRight: 16 }}>
                <span className="label bold" style={{ minWidth: 100 }}>
                  {intl.formatMessage(appFieldsMessages.project)}:
                </span>
                <Select
                  className="full-width"
                  showSearch
                  filterOption
                  options={this.projectListOptions}
                  value={projectName}
                  onChange={(projectName) =>
                    this.setState({ projectName, instanceName: undefined }, () => {
                      this.reloadInstance();
                    })
                  }
                />
              </div>
              <div className="flex-grow flex-row flex-center-align">
                <span className="label bold" style={{ minWidth: 120 }}>
                  {intl.formatMessage(appFieldsMessages.instance)}:
                </span>
                <Select
                  className="full-width"
                  showSearch
                  filterOption
                  options={this.instanceListOptions}
                  value={instanceName}
                  onChange={this.handleInstanceChange}
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginBottom: 8 }}>
              <div className="flex-grow flex-row flex-center-align rc-date-picker">
                <span className="label bold" style={{ minWidth: 100 }}>
                  Keyword Filter:
                </span>
                <div style={{ width: 200 }}>
                  <Input
                    valueLink={keywordFilterLink}
                    placeholder={`"keyword"`}
                    icon="icon search"
                    fullWidth
                    style={{ height: 24 }}
                    iconSearch
                    iconSearchClick={hasSearchError ? () => {} : this.iconSearchClick}
                  />
                </div>

                <span className="label bold" style={{ minWidth: 80, marginLeft: 16 }}>
                  Start Time:
                </span>
                <RcDatePicker
                  showTimeInput
                  timeIntervals={15}
                  showPopperArrow={false}
                  timeFormat={Defaults.DatePickerTime}
                  dateFormat={Defaults.DateTimeFormat1}
                  selected={utcObjToLocalDate(startTimeObj)}
                  customTimeInput={<TimeInput />}
                  maxDate={utcObjToLocalDate(moment.utc().add(1, 'days').endOf('day'))}
                  onChange={(startTime) => {
                    this.setState({ startTimeObj: localDateToUtcObj(startTime), initLoad: true });
                  }}
                />
                <span className="label bold" style={{ minWidth: 80, marginLeft: 16 }}>
                  End Time:
                </span>
                <RcDatePicker
                  showTimeInput
                  timeIntervals={15}
                  showPopperArrow={false}
                  timeFormat={Defaults.DatePickerTime}
                  dateFormat={Defaults.DateTimeFormat1}
                  selected={utcObjToLocalDate(endTimeObj)}
                  customTimeInput={<TimeInput />}
                  maxDate={utcObjToLocalDate(moment.utc().add(1, 'days').endOf('day'))}
                  onChange={(endTime) => {
                    this.setState({ endTimeObj: localDateToUtcObj(endTime), initLoad: true });
                  }}
                />

                <Button size="small" onClick={this.handleRefresh} style={{ marginLeft: 20 }}>
                  Reload
                </Button>
              </div>
            </div>

            <div className="flex-grow flex-min-height flex-col">
              {isLoaded && (
                <EventGroup
                  key={tableKey}
                  name=""
                  eventDataset={this.filterData}
                  totalCount={this.MAX_TOTAL_COUNT}
                  pageSize={this.pageSize}
                  pageNo={1}
                  loadMoreRows={this.loadMoreRows}
                  isContext
                  showFE={false}
                  highlightWord={keyword}
                  highlightPages={logEntryContextHighlightPages || []}
                  highlightRow={{ timestamp, rawData }}
                  hasType={false}
                  hasCount={false}
                  hasNid
                  hasDecompress
                  clusterSampleMsgs={this.clusterSampleMsgs}
                  filterWord={keyword}
                  isInfiniteLoad
                  isManualLoad
                  startTime={startTimeObj.valueOf()}
                  endTime={endTimeObj.valueOf()}
                  useTimeRange={useTimeRange}
                  initLoad={initLoad}
                />
              )}
            </div>
          </div>
        </Spin>
      </Modal>
    );
  }
}

const EventContextModal = injectIntl(EventContextModalCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { loadStatus, projects: projectList, projectInstanceComponentMap } = state.app;
    const {
      logEntryContextList,
      logEntryContextListTotal,
      logEntryContextHighlightPages,
      logEntryContextResetPage,
      logEntryContextClusterInfoList,
    } = state.log;

    return {
      location,
      loadStatus,
      credentials,
      projectList,
      projectInstanceComponentMap,
      logEntryContextList,
      logEntryContextListTotal,
      logEntryContextHighlightPages,
      logEntryContextResetPage,
      logEntryContextClusterInfoList,
    };
  },
  { createLoadAction, loadProjectInfo, updateLastActionInfo, createSetAction },
)(EventContextModal);
