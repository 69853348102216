/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { getLoaderEpicAction, pickNotNil } from '../../utils';
import { getProjectLogStructure } from '../../apis';
import { apiEpicErrorHandle } from '../../errors';
import { updateLastActionInfo } from '../../app/actions';
import { setSettingsInfo } from '../actions';

const loadProjectLogStructureEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('LOAD_PROJECT_LOGSTRUCTURE_SETTINGS').concatMap((action) => {
    const { loader } = action.payload;
    const params = pickNotNil(action.payload.params || {});
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(getProjectLogStructure(credentials, params))
      .concatMap((d) => {
        return Observable.of(setSettingsInfo(d.data));
      })
      .catch((err) => {
        return apiEpicErrorHandle(err);
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$, hideLoader);
  });

export default loadProjectLogStructureEpic;
