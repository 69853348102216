/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import * as CryptoJS from 'crypto-js';
import { get, isInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Tooltip,
  Select,
  Checkbox,
  message,
  Form,
  InputNumber,
  Spin,
  Col,
  Row,
  Input,
  Modal,
  AutoComplete,
} from 'antd';

import { State } from '../../../../common/types';
import { getLoadStatus } from '../../../../common/utils';
import { Container, Popover } from '../../../../lib/fui/react';
import { loadProjectHoliday, saveProjectHoliday, removeProjectHoliday } from '../../../../common/settings/actions';
import { showAppAlert, updateLastActionInfo, createLoadAction } from '../../../../common/app/actions';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../../common/app/messages';

import { settingsMessages } from '../../../../common/settings/messages';
import { DashboardMessages } from '../../../../common/dashboard/messages';
import KeywordsSetting from './KeywordsSetting';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import fetchGet from '../../../../common/apis/fetchGet';
import { eventMessages } from '../../../../common/metric/messages';

const { Item } = Form;

type Props = {
  intl: Object,
  currentLoadingComponents: Object,
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  refreshTime: Number,
  // eslint-disable-next-line
  createLoadAction: Function,
  credentials: Object,
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  holidayList: Array<Object>,
  projectSettings: Object,

  // eslint-disable-next-line
  showAppAlert: Function,
  updateLastActionInfo: Function,
  saveProjectSettings: Function,
  loadProjectHoliday: Function,
  saveProjectHoliday: Function,
  removeProjectHoliday: Function,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  keywordsList: Object,
  settingsLoader: String,
};

class BasicSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl, currentProject, systemsMap } = props;

    this.state = {
      hasErrors: true,
      oldValMap: {},
      fieldNameOptions: [],
    };

    this.submitKeywordLoader = 'setting_keywords_loading';
    this.dataLoader = 'get_keywords_loading';
    this.submitLoader = 'project_base_setting';

    this.cValueUnit = [
      { label: intl.formatMessage(appFieldsMessages.minute), value: 60 },
      { label: intl.formatMessage(appFieldsMessages.hour), value: 3600 },
      { label: intl.formatMessage(appFieldsMessages.day), value: 86400 },
    ];

    this.sensitivityOptions = [
      { label: intl.formatMessage(DashboardMessages.low), value: '0.99' },
      { label: intl.formatMessage(DashboardMessages.mediumLow), value: '0.95' },
      { label: intl.formatMessage(DashboardMessages.medium), value: '0.9' },
      { label: intl.formatMessage(DashboardMessages.mediumHigh), value: '0.75' },
      { label: intl.formatMessage(DashboardMessages.high), value: '0.5' },
    ];

    this.logSensitivityOptions = [
      { label: intl.formatMessage(DashboardMessages.low), value: 'low' },
      { label: intl.formatMessage(DashboardMessages.mediumLow), value: 'mediumLow' },
      { label: intl.formatMessage(DashboardMessages.medium), value: 'medium' },
      { label: intl.formatMessage(DashboardMessages.mediumHigh), value: 'mediumHigh' },
      { label: intl.formatMessage(DashboardMessages.high), value: 'high' },
    ];

    this.outlierSensitivityOptions = [
      { label: intl.formatMessage(DashboardMessages.low), value: 'low' },
      { label: intl.formatMessage(DashboardMessages.medium), value: 'medium' },
      { label: intl.formatMessage(DashboardMessages.high), value: 'high' },
    ];

    this.localSystemId = currentProject.systemId || 'emptySystem';
    let systemOptions = R.uniqBy(
      (n) => n.systemId,
      R.sortWith(
        [R.ascend(R.compose(R.toLower, R.prop('systemName')))],
        R.filter((item) => item.owner && item.systemId && item.systemName, R.values(systemsMap)),
      ),
    );
    systemOptions = R.filter((item) => item.owner === currentProject.owner, systemOptions || []);
    this.systemOptions = [
      ...systemOptions,
      { systemId: 'emptySystem', systemName: intl.formatMessage(settingsMessages.noSystem) },
    ];
  }

  async componentDidMount() {
    await this.getLogjsontype();
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.parseData(nextProps);
    }
  }

  @autobind
  getLogjsontype() {
    const { intl, projectName, credentials } = this.props;
    return fetchGet(getEndpoint('logjsontype'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          const fieldNameOptions = R.map((item) => ({ value: item.jsonKey, label: item.jsonKey }), data || []);
          this.setState({ fieldNameOptions });
        } else {
          message.error(msg);
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ fieldNameOptions: [] });
      });
  }

  @autobind
  parseData(props) {
    const { data, currentProject } = props;

    const {
      pValue,
      cValue,
      highRatioCValue,
      anomalyGapToleranceCount,
      dynamicBaselineDetectionFlag,
      similaritySensitivity,
      projectModelFlag,
      isGroupingByInstance,
      largeProject,
      zoneNameKey,
      samplingInterval = 1,
      projectDisplayName,
      retentionTime,
      featureOutlierSensitivity,
      UBLRetentionTime,
      anomalyDampening,
    } = data;
    if (this.formRef) {
      const [cValueNumber, cValueUnit] = this.handlecValue(cValue, samplingInterval);
      const [hightcValueNumber, highcValueUnit] = this.handlecValue(highRatioCValue, samplingInterval);
      const [anomalyGapToleranceNumber, anomalyGapToleranceUnit] = this.handlecValue(
        anomalyGapToleranceCount,
        samplingInterval,
      );

      const valMap = {
        pValue: String(pValue),
        cValueUnit,
        highcValueUnit,
        anomalyGapToleranceUnit,
        dynamicBaselineDetectionFlag,
        similaritySensitivity,
        projectModelFlag,
        isGroupingByInstance,
        largeProject,
        zoneNameKey,
        projectDisplayName,
        retentionTime,
        systemId: currentProject?.systemId || 'emptySystem',
        featureOutlierSensitivity,
        UBLRetentionTime,
      };
      const newValMap = {
        ...valMap,
        cValue: cValueNumber,
        highRatioCValue: hightcValueNumber,
        anomalyGapToleranceCount: anomalyGapToleranceNumber,
        anomalyDampening: anomalyDampening / 60000,
      };
      const oldValMap = {
        ...valMap,
        cValue,
        highRatioCValue,
        anomalyGapToleranceCount,
        anomalyDampening,
      };
      this.formRef.setFieldsValue(newValMap);
      this.setState({ samplingInterval, oldValMap });
    }
  }

  @autobind
  handlecValue(cValue, samplingInterval) {
    let cValueNumber = Number(cValue) * samplingInterval;
    let cValueUnit = 1;

    if (isInteger(cValueNumber / 86400)) {
      cValueUnit = 86400;
      cValueNumber /= cValueUnit;
      return [cValueNumber, cValueUnit];
    }

    if (isInteger(cValueNumber / 3600)) {
      cValueUnit = 3600;
      cValueNumber /= cValueUnit;
      return [cValueNumber, cValueUnit];
    }

    if (isInteger(cValueNumber / 60)) {
      cValueUnit = 60;
      cValueNumber /= cValueUnit;
      return [cValueNumber, cValueUnit];
    }
    return [cValueNumber, cValueUnit];
  }

  @autobind
  onFinish() {
    const { saveProjectSettings, projectName, currentProject, systemsMap, credentials, userInfo } = this.props;
    const { isMetric } = currentProject;
    const currentSystemInfo = currentProject?.systemId ? systemsMap[currentProject?.systemId] : {};

    const { samplingInterval, oldValMap } = this.state;
    this.formRef
      .validateFields()
      .then((formData) => {
        const {
          cValue,
          cValueUnit,
          highRatioCValue,
          highcValueUnit,
          anomalyGapToleranceCount,
          anomalyGapToleranceUnit,
          systemId,
        } = formData;
        if (
          isMetric &&
          (!isInteger((cValue * cValueUnit) / samplingInterval) ||
            !isInteger((highRatioCValue * highcValueUnit) / samplingInterval) ||
            !isInteger((anomalyGapToleranceCount * anomalyGapToleranceUnit) / samplingInterval))
        ) {
          message.error(`The Number of Samples must be an integer multiple of ${samplingInterval} minutes`);
          return;
        }
        const systemInfo = systemsMap[systemId === 'emptySystem' ? currentProject.systemId : systemId];

        const params = this.convertFields(formData);
        const hasSetData = { oldValMap, newValMap: params };

        const requests = [
          saveProjectSettings(
            projectName,
            { ...params, hasSetData: JSON.stringify(hasSetData) },
            { [this.submitLoader]: true },
          ),
        ];
        if (systemInfo && this.localSystemId !== systemId) {
          const { systemName, owner } = systemInfo;
          const sessionToken = userInfo?.sessionToken;
          const systemInfoStr = JSON.stringify([
            {
              customerName: owner,
              systemName: systemId === 'emptySystem' ? currentProject.systemId : systemId,
              systemDisplayName: systemName,
              projectNameSet: [
                {
                  projectName: projectName.split('@')[0],
                  userName: projectName.split('@')[1] || credentials.userName,
                },
              ],
            },
          ]);
          const systemInfoHashed = CryptoJS.HmacMD5(systemInfoStr, sessionToken).toString();
          requests.push(
            fetchPost(
              getEndpoint('systemframework', 2),
              {
                ...credentials,
                customerName: currentSystemInfo.owner || credentials.userName,
                operation: systemId === 'emptySystem' ? 'removeProjectFromSystem' : 'addProjectToSystem',
                systemInfo: systemInfoStr,
                'systemInfo-hashed': systemInfoHashed,
              },
              {},
              false,
            ),
          );
        }

        Promise.all(requests)
          .then((results) => {
            if (this.localSystemId !== systemId) window.location.reload();
          })
          .catch((e) => {
            Modal.error({ content: 'Unfortunately, an error has occurred. Please try again!' });
          });
      })
      .catch((errorInfo) => {
        message.error('Please check the form');
      });
  }

  @autobind
  convertFields(formData) {
    let { systemId, ...newFields } = R.clone(formData);
    const { currentProject } = this.props;
    const { samplingInterval } = this.state;
    const { isMetric } = currentProject;
    if (isMetric) {
      const {
        cValue,
        cValueUnit,
        highRatioCValue,
        highcValueUnit,
        anomalyGapToleranceCount,
        anomalyGapToleranceUnit,
        dynamicBaselineDetectionFlag,
        pValue,
        projectDisplayName,
        retentionTime,
        UBLRetentionTime,
        anomalyDampening,
      } = newFields;
      newFields = {
        pValue: String(pValue),
        cValue: (cValue * cValueUnit) / samplingInterval,
        highRatioCValue: (highRatioCValue * highcValueUnit) / samplingInterval,
        anomalyGapToleranceCount: (anomalyGapToleranceCount * anomalyGapToleranceUnit) / samplingInterval,
        dynamicBaselineDetectionFlag,
        projectDisplayName,
        retentionTime,
        UBLRetentionTime,
        anomalyDampening: anomalyDampening * 60000,
      };
    }
    return newFields;
  }

  @autobind
  handleFormChange() {
    const hasErrors = this.formRef.getFieldsError().some(({ errors }) => errors.length);
    this.setState({
      hasErrors,
    });
  }

  @autobind
  batchSettting() {
    const { intl } = this.props;

    return (
      <>
        <Col span={12}>
          <Item
            label={intl.formatMessage(eventMessages.displayProjectName)}
            name="projectDisplayName"
            rules={[{ required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) }]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label={intl.formatMessage(appFieldsMessages.systemName)}
            name="systemId"
            rules={[{ required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) }]}
          >
            <Select showSearch optionFilterProp="children" filterOption dropdownMatchSelectWidth={250}>
              {R.map(
                (item) => (
                  <Select.Option key={item.systemId} value={item.systemId} title={`${item.systemName}@${item.owner}`}>
                    {item.systemId === 'emptySystem' ? item.systemName : `${item.systemName}@${item.owner}`}
                  </Select.Option>
                ),
                this.systemOptions || [],
              )}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label={`${intl.formatMessage(settingsMessages.dataRetention)} (${intl.formatMessage(
              appFieldsMessages.day,
            )})`}
            name="retentionTime"
            rules={[{ required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) }]}
          >
            <InputNumber style={{ width: '100%' }} min={0} precision={0} />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label={`${intl.formatMessage(settingsMessages.modelRetention)} (${intl.formatMessage(
              appFieldsMessages.day,
            )})`}
            name="UBLRetentionTime"
            rules={[{ required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) }]}
          >
            <InputNumber style={{ width: '100%' }} min={0} precision={0} />
          </Item>
        </Col>
      </>
    );
  }

  render() {
    const { intl, loadStatus, currentProject, settingsLoader } = this.props;
    const { hasErrors, fieldNameOptions } = this.state;
    const { isMetric } = currentProject;

    const { isLoading } = getLoadStatus(get(loadStatus, this.submitKeywordLoader), intl);
    const { isLoading: isDataLoading } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const { isLoading: isSettingLoaing } = getLoadStatus(get(loadStatus, settingsLoader), intl);
    const isSubmitting = get(this.props.currentLoadingComponents, this.submitLoader, false);

    return (
      <Container fullHeight className="overflow-y-auto" style={{ paddingRight: 12 }}>
        <Spin spinning={isLoading || isDataLoading || isSettingLoaing}>
          <h3 className="project-setting-title" id="project-setting-anchor-general">
            Basic setting
          </h3>

          {isMetric && (
            <>
              <Form
                layout="vertical"
                wrapperCol={{ span: 12 }}
                ref={(r) => (this.formRef = r)}
                onFieldsChange={this.handleFormChange}
              >
                <Row>
                  {this.batchSettting()}
                  <Col span={12}>
                    <Item
                      label={intl.formatMessage(settingsMessages.sensitivity)}
                      name="pValue"
                      rules={[{ required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) }]}
                    >
                      <Select options={this.sensitivityOptions} style={{ width: '100%' }} />
                    </Item>
                  </Col>
                  <Col span={12} style={{ height: 80 }}>
                    <Item label={intl.formatMessage(settingsMessages.lowAnomalyRatioDurationThreshold)} required>
                      <div className="flex-row" style={{ columnGap: 10 }}>
                        <Item
                          name="cValue"
                          rules={[
                            {
                              required: true,
                              message: 'Please input an integer number',
                              pattern: /^([1-9]|[1-9][0-9]|[0-9]{2}|[0-9]{3})$/,
                              min: 1,
                            },
                          ]}
                          className="flex-grow"
                        >
                          <InputNumber style={{ width: '100%' }} />
                        </Item>
                        <Item
                          name="cValueUnit"
                          rules={[
                            { required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) },
                          ]}
                        >
                          <Select options={this.cValueUnit} style={{ width: '80' }} />
                        </Item>
                      </div>
                    </Item>
                  </Col>
                  <Col span={12} style={{ height: 80 }}>
                    <Item label={intl.formatMessage(settingsMessages.highAnomalyRatioDurationThreshold)} required>
                      <div className="flex-row" style={{ columnGap: 10 }}>
                        <Item
                          name="highRatioCValue"
                          rules={[
                            {
                              required: true,
                              message: 'Please input an integer number',
                              pattern: /^([1-9]|[1-9][0-9]|[0-9]{2}|[0-9]{3})$/,
                              min: 1,
                            },
                          ]}
                          className="flex-grow"
                        >
                          <InputNumber style={{ width: '100%' }} />
                        </Item>

                        <Item
                          name="highcValueUnit"
                          rules={[
                            { required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) },
                          ]}
                        >
                          <Select options={this.cValueUnit} style={{ width: 80 }} />
                        </Item>
                      </div>
                    </Item>
                  </Col>
                  <Col span={12} style={{ height: 80 }}>
                    <Item
                      label={
                        <div className="flex-row flex-center-align">
                          <div style={{ marginRight: 8 }}>
                            {intl.formatMessage(settingsMessages.anomalyGapToleranceDuration)}
                          </div>
                          <Popover
                            content={intl.formatMessage(settingsMessages.anomalyGapToleranceDurationTooltip)}
                            mouseEnterDelay={0.3}
                            placement="top"
                          >
                            <QuestionCircleOutlined className="clickable" />
                          </Popover>
                        </div>
                      }
                      required
                    >
                      <div className="flex-row" style={{ columnGap: 10 }}>
                        <Item
                          name="anomalyGapToleranceCount"
                          rules={[
                            {
                              required: true,
                              message: 'Please input an integer number',
                              pattern: /^(0|[1-9]|[1-9][0-9]|[0-9]{2}|[0-9]{3})$/,
                              min: 1,
                            },
                          ]}
                          className="flex-grow"
                        >
                          <InputNumber style={{ width: '100%' }} min={0} />
                        </Item>

                        <Item
                          name="anomalyGapToleranceUnit"
                          rules={[
                            { required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) },
                          ]}
                        >
                          <Select options={this.cValueUnit} style={{ width: 80 }} />
                        </Item>
                      </div>
                    </Item>
                  </Col>
                  <Col span={12} style={{ height: 80 }}>
                    <Item
                      label={intl.formatMessage(settingsMessages.enableDynamicBaselineDetection)}
                      name="dynamicBaselineDetectionFlag"
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Item>
                  </Col>
                  <Col span={12} style={{ height: 80 }}>
                    <Item
                      label={intl.formatMessage(settingsMessages.largeProject)}
                      name="largeProject"
                      valuePropName="checked"
                    >
                      <Checkbox disabled />
                    </Item>
                  </Col>
                  <Col span={12} style={{ height: 80 }}>
                    <Item
                      label={intl.formatMessage(settingsMessages.dampeningPeriod)}
                      name="anomalyDampening"
                      rules={[
                        {
                          required: true,
                          message: 'Please input an integer number',
                          pattern: /^([1-9]|[1-9][0-9]|[0-9]{2}|[0-9]{3})$/,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} min={1} precision={0} />
                    </Item>
                  </Col>
                </Row>
              </Form>
              <div className="flex-row flex-align-center flex-end-justify" style={{ marginBottom: 20 }}>
                <Button size="small" type="primary" onClick={this.onFinish} disabled={hasErrors} loading={isSubmitting}>
                  {intl.formatMessage(appButtonsMessages.update)}
                </Button>
              </div>
            </>
          )}

          {!isMetric && (
            <>
              <Form
                layout="vertical"
                wrapperCol={{ span: 12 }}
                ref={(r) => (this.formRef = r)}
                onFieldsChange={this.handleFormChange}
              >
                <Row>
                  {this.batchSettting()}
                  <Col span={12}>
                    <Item
                      label={
                        <>
                          <Tooltip placement="top" title={intl.formatMessage(settingsMessages.similarityDegreeDesc)}>
                            {intl.formatMessage(settingsMessages.similarityDegree)}
                            <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                          </Tooltip>
                        </>
                      }
                      name="similaritySensitivity"
                      rules={[{ required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) }]}
                    >
                      <Select options={this.logSensitivityOptions} style={{ width: '100%' }} />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item
                      label={
                        <>
                          <Tooltip placement="top" title={intl.formatMessage(settingsMessages.rareSensitivityDesc)}>
                            {intl.formatMessage(settingsMessages.sensitivity)}
                            <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                          </Tooltip>
                        </>
                      }
                      name="pValue"
                      rules={[{ required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) }]}
                    >
                      <Select options={this.sensitivityOptions} style={{ width: '100%' }} />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item
                      label={intl.formatMessage(settingsMessages.featureOutlierDetectionSensitivity)}
                      name="featureOutlierSensitivity"
                    >
                      <Select options={this.outlierSensitivityOptions} style={{ width: '100%' }} />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item
                      label={intl.formatMessage(settingsMessages.holisticModel)}
                      name="projectModelFlag"
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label={intl.formatMessage(settingsMessages.zoneNameKey)} name="zoneNameKey">
                      <AutoComplete
                        allowClear
                        size="small"
                        options={fieldNameOptions}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
                        }
                        style={{ width: '100%' }}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ maxWidth: 650 }}
                      />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label="Large project" name="largeProject" valuePropName="checked">
                      <Checkbox disabled />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item
                      label={intl.formatMessage(settingsMessages.groupingByInstance)}
                      name="isGroupingByInstance"
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Item>
                  </Col>
                </Row>
              </Form>
              <div className="flex-row flex-align-center flex-end-justify" style={{ marginBottom: 20 }}>
                <Button size="small" type="primary" onClick={this.onFinish} disabled={hasErrors} loading={isSubmitting}>
                  {intl.formatMessage(appButtonsMessages.update)}
                </Button>
              </div>

              <KeywordsSetting {...this.props} pickComponents={['whitelist', 'trainingWhitelist', 'incident']} />
            </>
          )}
        </Spin>
      </Container>
    );
  }
}

const BasicSetting = injectIntl(BasicSettingCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { systemsMap, loadStatus } = state.app;
    const { userInfo } = state.auth;
    const { holidayList, projectSettings } = state.settings;
    const { isAdmin, isLocalAdmin } = userInfo || {};

    return {
      credentials,
      systemsMap,
      userInfo,

      holidayList,
      projectSettings,
      isAdmin,
      isLocalAdmin,

      loadStatus,
    };
  },
  {
    showAppAlert,
    saveProjectHoliday,
    loadProjectHoliday,
    removeProjectHoliday,
    updateLastActionInfo,
    createLoadAction,
  },
)(BasicSetting);
