import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import tinygradient from 'tinygradient';
import moment from 'moment';
import { get } from 'lodash';
import { Button, message, Popover, Spin } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import {
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Modal,
  SortDirection,
  Table,
} from '../../../lib/fui/react';
import { buildUrl, Defaults, parseLocation } from '../../../common/utils';
import { EChart } from '../../share';

import { rootCauseOptionHtml, rootCausePopoverHtml } from './OptionHtml';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import BaseUrls from '../../app/BaseUrls';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

export default function CauseschartDetail({
  barData,
  open,
  onCancel,
  typeName,
  titleDistinguish,
  credentials,
  location,
  intl,
  globalInfo,
  currentTheme,
  systemId,
  startTime,
  endTime,
  environmentId,
  customerName,
}: Object) {
  const query = parseLocation(location);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    list: barData || [],
    tableDataList: R.map((item) => ({ ...item.rootCause, loading: false, count: item.count }), barData || []),
    sortBy: null,
    sortDirection: null,
  });
  const echartRef = useRef(null);
  const dataTableNode = useRef(null);
  const optionDetail = useRef({});
  const echartsAxiosDebugger = useRef(true);
  const { list, tableDataList, sortBy, sortDirection } = state;

  const getOptionDetail = (rowData, detailKey, callback) => {
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    fetchGet(getEndpoint('pie-chart-detail'), {
      ...credentials,
      customerName: systemInfo?.ownerUserName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).subtract(1, 'days').startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      instanceName: rowData.instanceName,
      dataType: rowData.dataType,
      patternId: rowData.patternId,
      metricName: rowData.dataType === 0 && rowData.supportInfo ? rowData.supportInfo : undefined,
    })
      .then((data) => {
        const { success, insightDetail } = data;
        if (success === undefined || success) {
          optionDetail.current[detailKey] = insightDetail;
          echartsAxiosDebugger.current = true;
        } else {
          echartsAxiosDebugger.current = true;
        }
        callback();
      })
      .catch((err) => {
        echartsAxiosDebugger.current = true;
        if (err?.code >= 500 || err?.code === 400) {
          console.error(intl.formatMessage(appMessages.apiFaild));
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
        }
      });
  };

  const getPieOption = (data, gradientColorsList) => {
    const gradient = tinygradient(gradientColorsList);
    const colorsRgb = gradient.rgb(data.length < 2 ? 2 : data.length);
    const colors = R.map((x) => {
      return x.toHexString();
    }, colorsRgb);
    const option = {
      backgroundColor: 'transparent',
      color: colors,
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'item',
        enterable: true,
        appendToBody: true,
        position: (pos, params, dom, rect, size) => {
          const boxWidth = size.contentSize[0]; // 弹框的width
          return [pos[0] - boxWidth - 5, pos[1] + 5];
        },
        formatter: (params, ticket, callback) => {
          const { data } = params;
          const detailKey = `${typeName}-${data?.rootCause?.instanceName || ''}-${data?.rootCause?.patternId || ''}-${
            data.count
          }`;
          if (!R.has(detailKey, optionDetail.current)) {
            if (echartsAxiosDebugger.current) {
              echartsAxiosDebugger.current = false;
              getOptionDetail(data.rootCause, detailKey, () => {
                const newFormatter = rootCauseOptionHtml({
                  data,
                  detailKey,
                  optionDetail: optionDetail.current,
                  intl,
                  currentTheme,
                });
                callback(ticket, newFormatter);
              });
            }
          }
          if (optionDetail.current[detailKey]) {
            return rootCauseOptionHtml({ data, detailKey, optionDetail: optionDetail.current, intl, currentTheme });
          } else {
            return ReactDOMServer.renderToStaticMarkup(<Spin />);
          }
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      series: [
        {
          name: 'Top Root Causes',
          type: 'pie',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          itemStyle: {
            emphasis: {
              shadowBlur: 4,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            show: true,
            position: 'inner',
            formatter: (params) => {
              const { data, percent } = params;
              return percent > 5 ? data?.count : '';
            },
            color: 'white',
            fontWeight: 'bold',
            fontSize: 10,
          },
          emphasis: {
            disabled: false,
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          emptyCircleStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderColor: '#ccc',
          },
          data,
        },
      ],
    };
    return option;
  };

  const [option] = useState(() => getPieOption(list, ['#043e7d', '#6abbf7']));

  const mouseOverTableRow = ({ event, index, rowData }, type) => {
    if (echartRef.current) {
      const detailKey = `${rowData.instanceName}-${rowData.patternId}`;
      const { data } = option?.series[0] || [];
      const dataIndex = R.findIndex(
        (_item) => `${_item?.rootCause?.instanceName}-${_item?.rootCause?.patternId}` === detailKey,
        data,
      );
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.dispatchAction({ type: type === 'over' ? 'highlight' : 'downplay', seriesIndex: 0, dataIndex });
    }
  };

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  useEffect(() => {
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        setState({ tableDataList: R.sortWith([R.descend(R.prop(sortBy))])(tableDataList) });
      } else {
        setState({ tableDataList: R.sortWith([R.ascend(R.prop(sortBy))])(tableDataList) });
      }
      if (dataTableNode.current) {
        dataTableNode.current.forceUpdate();
      }
    }
  }, [sortBy, sortDirection]);

  const patternIdRender = ({ rowData, rowIndex, dataKey, parent, style }) => {
    const { instanceName, patternId, projectName } = rowData;
    const rowKey = `${projectName || ''}-${instanceName || ''}-${patternId || ''}`;

    const findRootCause =
      R.find((item) => {
        const { projectName, instanceName, patternId } = item.rootCause || {};
        const itemkey = `${projectName || ''}-${instanceName || ''}-${patternId || ''}`;
        return itemkey === rowKey;
      }, list) || {};

    return (
      <CellMeasurer cache={cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ width: 200 }}>
          {R.addIndex(R.map)((val, idx) => {
            const { instanceName, patternId, count } = val;
            const incidentsdetailKey = `${typeName}-${instanceName || ''}-${patternId || ''}-${count}`;
            return (
              <Popover
                key={idx}
                title={null}
                content={
                  <Spin spinning={rowData.loading}>
                    {rootCausePopoverHtml({
                      rowData: val,
                      detailKey: incidentsdetailKey,
                      optionDetail: optionDetail.current,
                      intl,
                      currentTheme,
                    })}
                  </Spin>
                }
                placement="rightTop"
                onVisibleChange={(visible) => {
                  if (visible) {
                    if (!R.has(incidentsdetailKey, optionDetail.current)) {
                      rowData.loading = true;
                      if (dataTableNode.current) {
                        dataTableNode.current.forceUpdate();
                      }
                      getOptionDetail(val, incidentsdetailKey, () => {
                        rowData.loading = false;
                        if (dataTableNode.current) {
                          dataTableNode.current.forceUpdate();
                        }
                      });
                    }
                  }
                }}
              >
                <span key={idx} style={{ cursor: 'pointer' }} className="patternId-hover">
                  {val.patternId}
                </span>
                {idx < findRootCause.resultantIncidents.length - 1 ? ', ' : ''}
              </Popover>
            );
          }, findRootCause.resultantIncidents)}
        </div>
      </CellMeasurer>
    );
  };

  const jumpToInvestigation = (rowData) => {
    if (query?.saveFlag === 'true') {
      message.warning(intl.formatMessage(eventMessages.notSaveDashboardMessage));
      return;
    }

    const { instanceName } = rowData;
    const jumpParams = {
      // set redirect flag
      redirect: true,

      environmentId,
      startTime,
      endTime,
      customerName,
      systemId,

      jumpInstanceName: instanceName,
    };

    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, jumpParams), '_blank');
  };

  const detailsRender = ({ rowData }) => {
    return (
      <Button size="small" onClick={() => jumpToInvestigation(rowData)}>
        {intl.formatMessage(appFieldsMessages.details)}
      </Button>
    );
  };

  return (
    <Modal
      title={titleDistinguish}
      width={760}
      visible
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      className="dash-cell-detail-modal"
    >
      <Container className="full-width full-height flex-row flex-space-between">
        <div className="cell-detail-wrapper flex-row flex-space-between" style={{ width: '100%', height: 500 }}>
          <div className="flex-grow" style={{ height: '230px', marginRight: 10, position: 'relative' }}>
            <EChart
              setRef={(chart) => {
                echartRef.current = chart;
              }}
              option={option}
              style={{ width: '100%', height: '100%' }}
              className="chart-pie"
            />
          </div>
          <Table
            className="with-border"
            width={500}
            height={480}
            headerHeight={40}
            rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
            rowHeight={cellMeasureCache.rowHeight}
            rowCount={tableDataList.length}
            rowGetter={({ index }) => tableDataList[index]}
            ref={dataTableNode}
            sort={sort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onRowMouseOver={({ event, index, rowData }) => {
              mouseOverTableRow({ event, index, rowData }, 'over');
            }}
            onRowMouseOut={({ event, index, rowData }) => {
              mouseOverTableRow({ event, index, rowData }, 'out');
            }}
            deferredMeasurementCache={cellMeasureCache}
          >
            <Column
              width={110}
              flexGrow={1}
              label={intl.formatMessage(eventMessages.projectName)}
              dataKey="projectName"
              headerRenderer={headerRenderer}
            />
            <Column
              width={122}
              flexGrow={1}
              label={intl.formatMessage(appFieldsMessages.instanceName)}
              dataKey="instanceName"
              headerRenderer={headerRenderer}
            />
            <Column
              width={200}
              label="Incident pattern list"
              dataKey="patternId"
              headerRenderer={headerRenderer}
              cellRenderer={patternIdRender}
              style={{
                whiteSpace: 'pre-wrap',
              }}
              disableSort
            />
            <Column width={80} label="" dataKey="button" cellRenderer={detailsRender} />
          </Table>
        </div>
      </Container>
    </Modal>
  );
}
