/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import { isBoolean } from 'lodash';
import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

import { apiCallAfterCreateProject } from './parsers';

const NewApiTypes = ['Splunk'];

const newCustomProject = (credentials: Credentials, projectName: String, params: Object) => {
  const {
    instanceType,
    projectCloudType,
    samplingInterval,
    region,
    iamAccessKey,
    secretAccessKey,
    // system info
    systemKey,
    systemDisplayName,
    projectOwner,
    // labels info
    labelsWhitelist,
    labelsIncident,
    dataSource,
    dataType,
    ...rest
  } = params;

  const samplingIntervalInSeconds = parseInt(samplingInterval, 10) || 5 * 60;
  const projectCreationType = dataSource === 'Splunk' ? 'Splunk' : dataType === 'Metric' ? 'CustomMetric' : 'CustomLog';

  return fetchPost(getEndpoint('add-project'), {
    ...credentials,
    ...rest,
    projectName,
    projectOwner,
    projectCreationType,
    instanceType,
    dataType,
    projectCloudType: projectCloudType || instanceType,
    samplingInterval: samplingIntervalInSeconds / 60,
    samplingIntervalInSeconds,
    zone: region,
    email: '',
    'access-key': iamAccessKey,
    'secrete-key': secretAccessKey,
  }).then((d) => {
    if (isBoolean(d.success) && d.success === false) {
      return d;
    }

    return apiCallAfterCreateProject({
      credentials,
      projectName,
      projectOwner,
      systemKey,
      systemDisplayName,
      labelsWhitelist,
      labelsIncident,
    });
  });
};

export default newCustomProject;
