import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import VLink from 'valuelink';
import { Button } from 'antd';

import { State } from '../../../common/types';
import { Container, Input, Select, AutoSizer, Table, Column, Modal } from '../../../lib/fui/react';
import { getLoadStatus, Regex } from '../../../common/utils';
import { ActionTypes } from '../../../common/metric/actions';
import { createLoadAction } from '../../../common/app/actions';
import { eventActionMessages } from '../../../common/metric/messages';

type Props = {
  incident: Object,
  projectName: String,
  instanceName: String,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  createLoadAction: Function,
  emailAlert: Array<Object>,
};

class EventEmailAlertsModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    this.dataLoader = 'event_email_alert_loader';
    this.dataSubmiter = 'event_email_alert_submit';
    this.sumbitting = false;
    this.actionTypeOptions = [
      { label: 'always', value: 'always' },
      { label: 'one-time', value: 'oneTime' },
    ];

    this.savedEmailAlerts = [];
    this.state = {
      recipients: '',
      actionType: 'always',
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { incident, emailAlert, intl, loadStatus } = nextProps;
    if (this.props.incident !== incident) {
      this.reloadData(nextProps);
    } else if (this.props.emailAlert !== emailAlert) {
      this.parseData(nextProps);
    }

    const { isLoaded, errorMessage } = getLoadStatus(get(loadStatus, this.dataSubmiter), intl);
    if (isLoaded && this.sumbitting) {
      this.sumbitting = false;
      if (!errorMessage) {
        // this.props.onClose(true);
        this.reloadData(nextProps);
      }
    }
  }

  @autobind
  reloadData(props) {
    const { createLoadAction, incident, projectName, instanceName } = props;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    const type = incident.type || incident.eventType;
    createLoadAction(
      ActionTypes.LOAD_EVENT_ALERT,
      {
        projectName,
        patternId: nid,
        instanceName: instanceName || incident.instanceName,
        type,
      },
      this.dataLoader,
    );
  }

  @autobind
  parseData(props) {
    const { incident, emailAlert } = props;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    const savedEmailAlert = R.find((ea) => ea.patternId === nid, emailAlert || []);
    if (savedEmailAlert) {
      const { emailList, ...rest } = savedEmailAlert;
      this.savedEmailAlerts = R.map((e) => {
        return {
          email: e,
          ...rest,
        };
      }, emailList || []);
      this.setState({ recipients: R.join(',', emailList) });
    } else {
      this.savedEmailAlerts = [];
      this.setState({ recipients: '' });
    }
  }

  @autobind
  handleEmailAlertSave() {
    const { createLoadAction, projectName, instanceName, incident } = this.props;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    const type = incident.type || incident.eventType;
    const { recipients, actionType } = this.state;
    if (recipients && actionType) {
      this.sumbitting = true;
      createLoadAction(
        ActionTypes.SAVE_EVENT_ALERT,
        {
          projectName,
          patternId: nid,
          instanceName: instanceName || incident.instanceName,
          type,
          emailList: JSON.stringify(R.split(',', recipients)),
          frequency: actionType,
        },
        this.dataSubmiter,
      );
    }
  }

  @autobind
  removeRenderer({ rowData }) {
    const { intl } = this.props;
    return (
      <div className="ui grey button" onClick={this.handleEmailRemove(rowData)}>
        {intl.formatMessage(eventActionMessages.remove)}
      </div>
    );
  }

  @autobind
  handleEmailRemove(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();

      const { createLoadAction, projectName, instanceName, incident } = this.props;
      const nid = incident.nid || incident.neuronId || incident.patternId;
      const type = incident.type || incident.eventType;
      const { email } = rowData;
      if (window.confirm(`Delete email: ${email}?`)) {
        createLoadAction(
          ActionTypes.DELETE_EVENT_ALERT,
          {
            projectName,
            patternId: nid,
            instanceName: instanceName || incident.instanceName,
            type,
            emailList: JSON.stringify([email]),
          },
          this.dataLoader,
        );
      }
    };
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  render() {
    const { intl, loadStatus } = this.props;
    const actionTypeLink = VLink.state(this, 'actionType').check((x) => x, 'The action type is required');
    const recipientsLink = VLink.state(this, 'recipients')
      .check((x) => Boolean(x), 'Recipients is required')
      .check(
        (x) =>
          R.reduce(
            (a, b) => a && b,
            true,
            R.map((e) => Regex.email.test(e), x.split(',')),
          ),
        'Email is incorrect',
      );

    const emailList = this.savedEmailAlerts;
    const { isLoading } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const { isLoading: isSubmiting } = getLoadStatus(get(loadStatus, this.dataSubmiter), intl);
    const hasError = actionTypeLink.error || recipientsLink.error;
    return (
      <Modal
        width={650}
        title={intl.formatMessage(eventActionMessages.savedEmailAlert)}
        onCancel={this.handleClose}
        visible
        maskClosable={false}
        footer={null}
      >
        <Container className={`flex-col ${isLoading ? 'loading' : ''}`} fullHeight>
          <div style={{ paddingBottom: 20 }}>
            <div style={{ fontWeight: 500, fontSize: 14, lineHeight: '20px' }}>{`${intl.formatMessage(
              eventActionMessages.savedEmailAlert,
            )}`}</div>
            {emailList.length > 0 && (
              <div style={{ height: emailList.length >= 3 ? 150 : 30 + 40 * emailList.length }}>
                <AutoSizer>
                  {({ width, height }) => (
                    <Table
                      className="with-border"
                      width={width}
                      height={height}
                      headerHeight={30}
                      rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                      rowHeight={40}
                      rowCount={emailList.length}
                      rowGetter={({ index }) => emailList[index]}
                    >
                      <Column
                        width={300}
                        flexGrow={1}
                        label={intl.formatMessage(eventActionMessages.email)}
                        dataKey="email"
                      />
                      {false && <Column width={80} dataKey="email" cellRenderer={this.removeRenderer} />}
                    </Table>
                  )}
                </AutoSizer>
              </div>
            )}
            {emailList.length === 0 && (
              <div className="ui info message">
                <i className="icon info" />
                {intl.formatMessage(eventActionMessages.noSavedEmailAlerts)}
              </div>
            )}
            <div className="flex-row" style={{ padding: '5px 0' }}>
              <div className="flex-grow" />
              <div
                className={`ui button grey ${isSubmiting ? 'disabled' : ''}`}
                onClick={this.handleEmailRemove({ email: 'All' })}
              >
                {intl.formatMessage(eventActionMessages.remove)}
              </div>
            </div>
          </div>
          <Container className="flex-grow flex-col">
            <div style={{ fontWeight: 500, fontSize: 14, lineHeight: '20px' }}>{`${intl.formatMessage(
              eventActionMessages.createEmailAlert,
            )}`}</div>
            {false && (
              <div className="flex-row" style={{ padding: '5px 0' }}>
                <div style={{ width: 100, lineHeight: '28px' }}>
                  {intl.formatMessage(eventActionMessages.emailAlertType)}:
                </div>
                <Select
                  valueLink={actionTypeLink}
                  className={`${isSubmiting ? 'disabled' : ''}`}
                  style={{ width: 130 }}
                  options={this.actionTypeOptions}
                />
              </div>
            )}
            <div className="flex-row" style={{ padding: '5px 0' }}>
              <div style={{ width: 100, lineHeight: '28px' }}>
                {intl.formatMessage(eventActionMessages.emailRecipients)}:
              </div>
              <Input
                valueLink={recipientsLink}
                placeholder="admin@email.com"
                className={`${isSubmiting ? 'disabled' : ''}`}
                style={{ width: 380 }}
              />
            </div>
            <div className="flex-row" style={{ padding: '5px 0' }}>
              <div className="flex-grow" />
              <Button
                size="small"
                type="primary"
                loading={isSubmiting}
                disabled={hasError}
                onClick={this.handleEmailAlertSave}
              >
                {intl.formatMessage(eventActionMessages.saveAlert)}
              </Button>
            </div>
          </Container>
        </Container>
      </Modal>
    );
  }
}

const EventEmailAlertsModal = injectIntl(EventEmailAlertsModalCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { emailAlert } = state.metric;
    return {
      location,
      loadStatus,
      emailAlert,
    };
  },
  { createLoadAction },
)(EventEmailAlertsModal);
