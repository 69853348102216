import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import VLink from 'valuelink';
import moment from 'moment';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';

import { hideAppLoader, createLoadAction } from '../../common/app/actions';
import { ActionTypes } from '../../common/auth/actions';
import { parseQueryString } from '../../common/utils';
import { appFieldsMessages } from '../../common/app/messages';
import { authMessages } from '../../common/auth/messages';
import { CenterPage, LocaleSelector } from '../app/components';
import { Input } from '../../lib/fui/react';

type Props = {
  appLoaderVisible: boolean,
  redirectURL: String,
  idpSettingMap: Object,
  intl: Object,
  push: Function,
  location: Object,
  loadStatus: Object,
  hideAppLoader: Function,
  createLoadAction: Function,
  isExisted: Boolean,
  newIdpUserError: String,
  newIdpUserSucceed: Boolean,
};

class IDPRegisterCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const params = parseQueryString(location.search);
    const { idp, userName } = params;

    this.state = {
      isLoading: false,
      hasError: false,
      idpType: idp,
      userName,
      password: '',

      idpName: '',
      clientId: '',
      clientSecret: '',
      tenant: '',
      domainName: '',
      serverAddress: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.redirectURL !== nextProps.redirectURL) {
      window.location.href = nextProps.redirectURL;
    } else if (
      this.props.newIdpUserError !== nextProps.newIdpUserError ||
      this.props.newIdpUserSucceed !== nextProps.newIdpUserSucceed
    ) {
      const { newIdpUserError, newIdpUserSucceed } = nextProps;
      this.setState({ newIdpUserError, newIdpUserSucceed, hasError: true });
    }
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  handleSubmit() {
    const { createLoadAction } = this.props;
    const { userName, idpType, idpName, clientId, clientSecret, tenant, domainName, serverAddress, password } =
      this.state;

    let params = {
      idpType,
      userName,
    };

    if (idpType === 'MS') {
      params = {
        ...params,
        password,
        idpName,
        idpInfo: {
          serverAddress,
          domainName,
        },
      };
    } else if (idpType === 'LDAPS') {
      params = {
        ...params,
        clientSecret,
      };
    } else if (idpType === 'Azure') {
      params = {
        ...params,
        idpName,
        idpInfo: {
          clientId,
          clientSecret,
          tenant,
        },
      };
    } else if (idpType === 'Google') {
      params = {
        ...params,
        idpInfo: {
          clientId,
          clientSecret,
        },
      };
    }

    this.setState({ isLoading: true });
    createLoadAction(ActionTypes.NEW_IDP_USER, params, false, false, this.callbackHandle);
  }

  @autobind
  callbackHandle() {
    this.setState({ isLoading: false });
  }

  render() {
    const { intl, push, currentTheme } = this.props;
    const { isLoading, hasError, idpType, newIdpUserError } = this.state;

    const userNameLink = VLink.state(this, 'userName').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsUserNameRequired),
    );
    const idpNameLink = VLink.state(this, 'idpName').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsIDPNameRequired),
    );
    const clientIdLink = VLink.state(this, 'clientId').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsClientIdRequired),
    );
    const clientSecretLink = VLink.state(this, 'clientSecret').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsClientSecretRequired),
    );
    const tenantLink = VLink.state(this, 'tenant').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsTenantRequired),
    );
    const domainNameLink = VLink.state(this, 'domainName').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsDomainNameRequired),
    );
    const serverAddressLink = VLink.state(this, 'serverAddress').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsServerAddressRequired),
    );
    const passwordLink = VLink.state(this, 'password').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsPasswordRequired),
    );

    return (
      <CenterPage intl={intl} push={push} className="auth" currentTheme={currentTheme}>
        <h3 style={{ textAlign: 'center' }}>{idpType}</h3>
        <form className={`ui ${hasError ? 'error' : ''} form`} style={{ width: 360 }}>
          {hasError && <div className="ui error message">{newIdpUserError}</div>}

          {idpType === 'Azure' && (
            <div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.userName)}</label>
                <Input valueLink={userNameLink} icon="user icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.idpName)}</label>
                <Input valueLink={idpNameLink} icon="user icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.clientId)}</label>
                <Input valueLink={clientIdLink} icon="user icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.clientSecret)}</label>
                <Input valueLink={clientSecretLink} icon="lock icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.tenant)}</label>
                <Input valueLink={tenantLink} icon="lock icon" />
              </div>
            </div>
          )}
          {idpType === 'MS' && (
            <div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.userName)}</label>
                <Input valueLink={userNameLink} icon="user icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.idpName)}</label>
                <Input valueLink={idpNameLink} icon="user icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.serverAddress)}</label>
                <Input valueLink={serverAddressLink} icon="user icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.domainName)}</label>
                <Input valueLink={domainNameLink} icon="lock icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.password)}</label>
                <Input type="password" valueLink={passwordLink} icon="lock icon" autoComplete="new-password" />
              </div>
            </div>
          )}
          {idpType === 'LDAPS' && (
            <div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.userName)}</label>
                <Input valueLink={userNameLink} icon="user icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.clientSecret)}</label>
                <Input type="password" valueLink={clientSecretLink} icon="lock icon" autoComplete="new-password" />
              </div>
            </div>
          )}
          {idpType === 'Google' && (
            <div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.userName)}</label>
                <Input valueLink={userNameLink} icon="user icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.clientId)}</label>
                <Input valueLink={clientIdLink} icon="user icon" />
              </div>
              <div className="input field required">
                <label>{intl.formatMessage(appFieldsMessages.clientSecret)}</label>
                <Input valueLink={clientSecretLink} icon="lock icon" />
              </div>
            </div>
          )}

          <div className="field" style={{ textAlign: 'right' }}>
            <Button type="primary" loading={isLoading} onClick={this.handleSubmit}>
              {intl.formatMessage(authMessages.buttonsSignUp)}
            </Button>
          </div>
        </form>
      </CenterPage>
    );
  }
}

const IDPRegister = injectIntl(IDPRegisterCore);
export default connect(
  (state) => ({
    appLoaderVisible: state.app.appLoaderVisible,
    loadStatus: state.app.loadStatus,
    location: state.router.location,
    idpSettingMap: state.auth.idpSettingMap,
    redirectURL: state.auth.redirectURL,
    currentTheme: state.app.currentTheme,
    newIdpUserSucceed: state.auth.newIdpUserSucceed,
    newIdpUserError: state.auth.newIdpUserError,
  }),
  { push, createLoadAction, hideAppLoader },
)(IDPRegister);
