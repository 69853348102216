/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import { isString, isNumber, isBoolean } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPut from './fetchPut';

/**
 * Save project settings with changed value.
 */
const saveProjectSettings = (credentials: Credentials, projectName: String, settings: Object, projects) => {
  const jsonParams = {};
  R.forEachObjIndexed((val, key) => {
    if (
      isString(val) ||
      isNumber(val) ||
      isBoolean(val) ||
      R.indexOf('webhookHeaderList', key) >= 0 ||
      R.indexOf('baseValueSetting', key) >= 0 ||
      R.indexOf('cdfSetting', key) >= 0 ||
      key === 'linkedLogProjects'
    ) {
      jsonParams[key] = val;
    } else {
      jsonParams[key] = val ? JSON.stringify(val) : null;
    }
  }, settings);

  const project = R.find((item) => item.projectName === projectName || item.projectShortName === projectName, projects);
  const { projectShortName, owner: customerName, dataType } = project || {};

  const { hasSetData, notPostHasSetData, ...restJsonParams } = jsonParams;

  const { oldValMap, newValMap } = JSON.parse(hasSetData || '{}');
  const diffOldValMap = {};
  const diffNewValMap = {};

  R.forEachObjIndexed((val, key) => {
    if (!R.equals(oldValMap?.[key], val)) {
      diffOldValMap[key] = oldValMap?.[key];
      diffNewValMap[key] = val;
    }
  }, newValMap);

  return fetchPut(getEndpoint('project-setting', 2), {
    ...credentials,
    projectList: JSON.stringify([{ projectName: projectShortName, customerName }]),
    setting: JSON.stringify(restJsonParams),
    ...(notPostHasSetData
      ? {}
      : { hasSetData: JSON.stringify({ oldValMap: diffOldValMap, newValMap: diffNewValMap }) }),
    touchedSensitivitySetting: true,
    dataType: dataType.toLowerCase() === 'metric' ? 'Metric' : 'Log',
  }).then((d) => {
    return d;
  });
};

export default saveProjectSettings;
