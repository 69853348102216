/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { get, isString, isArray } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import parseMetricCapacityPlanning from './parsers/parseMetricCapacityPlanning';

const getMetricCapacityPlanning = (credentials: Credentials, params: Object) => {
  const { projectName, env, startTimestamp, endTimestamp, smartDay, isRealCapacityPlanning } = params;

  const envVal = env === 'All' ? `PROD(${env})` : env.indexOf(':') >= 0 ? R.split(':', env)[1] : env;

  const requests = [];
  if (isArray(projectName)) {
    R.forEach((item) => {
      requests.push(
        fetchGet(getEndpoint('capacityplanning'), {
          ...credentials,
          projectName: item,
          evn: envVal,
          startTimestamp,
          endTimestamp,
          isRealCapacityPlanning,
        }).then((data) => {
          return { ...(data || {}), projectNameStr: item };
        }),
      );
    }, projectName);
  } else {
    requests.push(
      fetchGet(getEndpoint('capacityplanning'), {
        ...credentials,
        projectName,
        evn: envVal,
        startTimestamp,
        endTimestamp,
        isRealCapacityPlanning,
      }).then((data) => {
        return { ...(data || {}), projectNameStr: projectName };
      }),
    );
  }

  return Promise.all(requests).then((results) => {
    const anomalyInfo = [];
    R.forEach((data) => {
      const { instanceObj, componentResult, aggregatedWeightedScore, projectNameStr, metricGroupingPriorityMap } =
        data || {};
      const dayComponentResult = get(componentResult, [smartDay], {});

      const dayAnomalyInfo = parseMetricCapacityPlanning({
        projectName: projectNameStr,
        env,
        instanceObj,
        dayAllResult: dayComponentResult,
        dayAllMetricsScore: aggregatedWeightedScore,
        metricGroupingPriorityMap,
      });
      anomalyInfo.push(dayAnomalyInfo);
    }, results);
    return {
      anomalyInfo: isString(projectName) ? anomalyInfo[0] : anomalyInfo,
    };
  });
};
export default getMetricCapacityPlanning;
