/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { get, round } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getProjectDetectionStatus = (credentials: Credentials, params: Object) => {
  const { projectName, pageNo, pageSize } = params;

  const requests = [];
  requests.push(
    fetchGet(`${window.BASE_URL || ''}/updateDetection`, {
      ...credentials,
      projectName,
      pageNo,
      pageSize,
    }).then((data) => {
      const { Result } = data || {};
      const { chunkInfo, detectionIndex, pageInfo } = Result || {};
      const detectionStatus = {};
      R.forEachObjIndexed((value, key) => {
        detectionStatus[key] = value;
      }, chunkInfo);
      R.forEachObjIndexed((value, key) => {
        detectionStatus[key].detectionIndex = value;
      }, detectionIndex);

      const detectionStatusList = [];
      R.forEachObjIndexed((value, key) => {
        const { totalEntry, detectionIndex } = value;

        // add status for instance detection
        let status;
        if (totalEntry === detectionIndex) {
          status = 'done';
        } else if (totalEntry > detectionIndex) {
          status = 'in progress';
        } else if (totalEntry < detectionIndex) {
          status = 'error';
        }

        detectionStatusList.push({
          status,
          instanceName: key,
          ...value,
        });
      }, detectionStatus);

      return {
        pageInfo,
        detectionStatus: detectionStatusList,
      };
    }),
  );
  requests.push(
    fetchGet(getEndpoint('logProjectProcessingStatus'), {
      ...credentials,
      projectName,
      pageNo,
      pageSize,
    }).then((data) => {
      const { instanceStatusMap, projectDetectionStatus } = data || {};
      return {
        instanceStatusMap,
        projectDetectionStatus,
      };
    }),
  );
  requests.push(
    fetchGet(getEndpoint('detectionprogress'), {
      ...credentials,
      projectName,
      pageNo,
      pageSize,
    }).then((data) => {
      const detectionProgress = data || {};
      return {
        detectionProgress,
      };
    }),
  );

  return Promise.all(requests).then((results) => {
    let { detectionStatus } = results[0] || {};
    const { pageInfo } = results[0] || {};
    const { instanceStatusMap, projectDetectionStatus } = results[1] || {};
    const { detectionProgress } = results[2] || {};
    detectionStatus = R.map((val) => {
      let newVal = { ...val };
      if (R.has(val.instanceName, instanceStatusMap || {})) {
        newVal = { ...newVal, ...instanceStatusMap[val.instanceName] };
      }
      if (R.has(val.instanceName, detectionProgress || {})) {
        const progressInfo = [];
        R.forEachObjIndexed(
          (timestamp, detectionIndex) => progressInfo.push({ timestamp, detectionIndex }),
          detectionProgress[val.instanceName] || {},
        );
        newVal = { ...newVal, progressInfo };
      }
      return newVal;
    }, detectionStatus || []);
    return {
      pageInfo,
      detectionStatus,
      projectDetectionStatus,
    };
  });
};

export default getProjectDetectionStatus;
