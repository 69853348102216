/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getQueryTemplateList = (credentials: Credentials) => {
  return fetchGet(getEndpoint('query/templatelist'), {
    ...credentials,
  }).then((d) => {
    const templateList = [
      {
        category: 'Log Insights',
        id: 'search_all_log_entries',
        desc: 'Grep all log entries',
        requiredParams: ['logProjectName', 'startTimestamp', 'endTimestamp', 'duration', 'instanceName'],
        optionalParams: [],
      },
      {
        category: 'Log Insights',
        id: 'live_tail_log_entries',
        desc: 'Live tail',
        requiredParams: ['logProjectName', 'startTimestamp', 'endTimestamp', 'duration', 'instanceName'],
        optionalParams: [],
      },
      ...(d.data || []),
    ];
    return R.map((t) => {
      const notImplemented = (t.desc || '')[0] === '*';
      return {
        ...t,
        desc: notImplemented ? t.desc.substr(1) : t.desc,
        notImplemented,
      };
    }, templateList);
  });
};

export default getQueryTemplateList;
