import { createLogic } from 'redux-logic';
import * as R from 'ramda';

import { newIdpRegister } from '../../apis';
import { ActionTypes as AppActionTypes, createSetAction } from '../../app/actions';
import { ActionTypes, loginPending } from '../actions';
import { getLoadStatusActions, parseJSON, getReaderStream } from '../../utils';
import { appMessages } from '../../app/messages';

const newIdpUserLogic = createLogic({
  type: [ActionTypes.NEW_IDP_USER],
  cancelType: AppActionTypes.APP_STOP,
  latest: true,
  debounce: 300,
  process: ({ getState, action }, dispatch, done) => {
    const { params, loader, callback } = action.payload;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    dispatch(showLoading);
    newIdpRegister(params)
      .then((data) => {
        const { redirectURL } = data;
        dispatch(loginPending(redirectURL));
        dispatch(createSetAction(ActionTypes.SET_NEW_IDP_USER_STATUS, {}, { succeed: true, error: null }));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF] Failed to auth', err);
        return getReaderStream(err.response.body.getReader());
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => blob.text())
      .then((errBody) => {
        const { message: errMsg } = parseJSON(errBody) || {};
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: errMsg }));
        dispatch(createSetAction(ActionTypes.SET_NEW_IDP_USER_STATUS, {}, { succeed: false, error: errMsg }));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }
        done();
      });
  },
});

export default newIdpUserLogic;
