import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import tinygradient from 'tinygradient';
import moment from 'moment';
import { get } from 'lodash';
import { Spin, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Popover } from '../../../lib/fui/react';
import { Defaults, parseLocation } from '../../../common/utils';

import DashCellTitle from '../components/DashCellTitle';
import OverandunderDetail from '../components/OverandunderDetail';
import CreatePortalDetailsFrame from '../../app/CreatePortalDetailsFrame';

import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { getCacheData, getInstanceDisplayNameFn, setCacheData } from '../utils';
import { appMenusMessages } from '../../../common/app/messages';
import { settingsMessages } from '../../../common/settings/messages';

const getColorList = (num, gradientColorsList) => {
  const gradient = tinygradient(gradientColorsList);
  const colorsRgb = gradient.rgb(num).reverse();
  return R.map((x) => {
    return x.toHexString();
  }, colorsRgb);
};

const typeMapping = {
  CPU: 'CPU',
  MEM: 'MEM',
  Storage: 'Stor',
};

const getViewData = (view) => (view || view === 0 ? view : 0);
const floatFixed = (num) =>
  num
    ? Number(
        Number.parseFloat(num) < 1 && Number.parseFloat(num) > 0
          ? Number.parseFloat(num).toFixed(1)
          : Number.parseFloat(num).toFixed(0),
      )
    : num;
const floatFixedTwo = (num) => (num ? Number(Number.parseFloat(num).toFixed(2)) : num);
const returnView = (num) => (num === 0 ? <CheckOutlined /> : num);

export default function Overandunder({
  systemId,
  startTime,
  endTime,
  forceRefreshTime,
  environmentId,
  credentials,
  location,
  globalInfo,
  currentTheme,
  intl,
  instanceDisplayNameMap,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    provisioningColorList: getColorList(100, ['#9e2b0e', '#9e2b0e']),
    overProvisioningColorList: getColorList(100, ['#6abbf7', '#6abbf7']),
    typeList: [],
    underList: [],
    overList: [],
    newData: [],
    dailyProvision: [],
    showOverandunderDetail: false,
    isLoading: false,
  });
  const {
    provisioningColorList,
    overProvisioningColorList,
    typeList,
    underList,
    overList,
    newData,
    dailyProvision,
    showOverandunderDetail,
    isLoading,
  } = state;
  const { dashBoardName } = parseLocation(location);

  const parseData = ({ provisioning, dailyProvision }) => {
    const data = R.map(
      (item) => ({
        projectSet: item.ps,
        componentName: item.cn,
        instanceName: item.in,
        provisionMetric: item.pm,
        avgValue: item.av,
        minValue: item.minv,
        maxValue: item.maxv,
      }),
      provisioning || [],
    );

    // 类型
    let typeList = [];
    typeList = [{ type: 'CPU' }, { type: 'MEM' }, { type: 'Storage' }];

    // 获取 under / over 的数据
    const newData = R.sortWith([R.descend(R.prop('avgValue'))], data);

    const filterUnderData = R.filter((item) => getViewData(floatFixedTwo(item.avgValue)) >= 0.7, newData);
    const filterOverData = R.sortWith(
      [R.ascend(R.prop('avgValue'))],
      R.filter((item) => getViewData(floatFixedTwo(item.avgValue)) <= 0.3, newData),
    );

    // 获取 Under-provisioned 前3
    const underTopThreeData = [];
    R.forEach((item) => {
      const { instanceName } = item;
      if (underTopThreeData.length < 3 && !underTopThreeData.includes(instanceName)) {
        underTopThreeData.push(instanceName);
      }
    }, filterUnderData);

    // 针对 Under-provisioned 前3 创建对象 把值传入进去
    const underTopThreeDataObj = {};
    R.forEach((item) => {
      const itemList = [];
      R.forEach((_item) => {
        const { type } = _item;
        itemList.push({ type });
      }, typeList);
      underTopThreeDataObj[item] = itemList;
    }, underTopThreeData);

    // 把 Under-provisioned 的 avgValue 插入相对的前3里
    const underList = [];
    R.forEachObjIndexed((val, key) => {
      const list = [];
      R.forEach((item) => {
        const { type } = item;
        const findItem =
          R.find((only) => only.instanceName === key && only.provisionMetric === type, filterUnderData) || {};
        const { avgValue, minValue, maxValue } = findItem;
        item.avgValue = avgValue || 0;
        item.minValue = minValue || 0;
        item.maxValue = maxValue || 0;
        item.name = key;
        list.push(item);
      }, val || []);
      underList.push(list);
    }, underTopThreeDataObj);

    // 获取 Over-provisioned 前3
    const overTopThreeData = [];
    R.forEach((item) => {
      const { instanceName } = item;
      if (overTopThreeData.length < 3 && !overTopThreeData.includes(instanceName)) {
        overTopThreeData.push(instanceName);
      }
    }, filterOverData);

    // 针对 Over-provisioned 前3 创建对象 把值传入进去
    const overTopThreeDataObj = {};
    R.forEach((item) => {
      const itemList = [];
      R.forEach((_item) => {
        const { type } = _item;
        itemList.push({ type });
      }, typeList);
      overTopThreeDataObj[item] = itemList;
    }, overTopThreeData);

    // 把 Over-provisioned 的 avgValue 插入相对的前3里
    const overList = [];
    R.forEachObjIndexed((val, key) => {
      const list = [];
      R.forEach((item) => {
        const { type } = item;
        const findItem =
          R.find((only) => only.instanceName === key && only.provisionMetric === type, filterOverData) || {};
        const { avgValue, minValue, maxValue } = findItem;
        item.avgValue = avgValue || 0;
        item.minValue = minValue || 0;
        item.maxValue = maxValue || 0;
        item.name = key;
        list.push(item);
      }, val || []);
      overList.push(list);
    }, overTopThreeDataObj);

    setState({ typeList, underList, overList, newData, dailyProvision, isLoading: false });
  };

  const reloadData = async (systemInfo) => {
    setState({ isLoading: true });
    const params = {
      ...credentials,
      customerName: systemInfo?.ownerUserName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      granularity: 'instance',
    };
    // const apiData = getCacheData(params);
    // if (apiData) {
    //   await parseData(apiData);
    //   return;
    // }

    if (!systemId) return;
    fetchGet(getEndpoint('dashboard-provisioning'), R.clone(params))
      .then(async (data) => {
        const { success, message: msg, provisioning, dailyProvision } = data;
        if (success === undefined || success) {
          await parseData({ provisioning, dailyProvision });
          // setCacheData(params, { provisioning, dailyProvision });
        } else {
          console.error(msg);
          setState({ isLoading: false });
        }
      })
      .catch((e) => {
        console.error(e.message || String(e));
        setState({ isLoading: false });
      });
  };

  useEffect(() => {
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo) {
      reloadData(systemInfo);
    }
  }, [systemId, currentTheme]);

  return (
    <Spin spinning={isLoading} wrapperClassName="spin-base">
      <DashCellTitle
        title=""
        subTitle=""
        type="overandunder"
        fillListFlag={newData.length > 0}
        setShowDetail={(showOverandunderDetail) => {
          setState({ showOverandunderDetail });
        }}
        setTypeName={(typeName) => {}}
      />
      <div className="provisioning" style={{ height: 'calc(100% - 48px)', marginRight: '-10px' }}>
        <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'auto' }}>
          <div style={{ position: 'absolute', left: '0px', height: '100%', width: '100%', paddingRight: 10 }}>
            <div className="provisioning-title">
              <div style={{ minWidth: 90, color: 'var(--text-color)', fontSize: 16 }}>Over-utilized hosts</div>
              <div className="provisioning-title-tabs">
                {R.map((item) => {
                  return <div key={item.type}>{typeMapping[item.type]}</div>;
                }, typeList)}
              </div>
            </div>
            {underList.length > 0 && (
              <div className="provisioning-list">
                {R.addIndex(R.map)((item, index) => {
                  const findItem = R.find((only) => item[0]?.name === only?.instanceName, newData || []) || {};
                  const { instanceDisplayName, width, viewContent, onlyInstanceName } = getInstanceDisplayNameFn({
                    instanceDisplayNameMap,
                    instanceName: findItem.instanceName,
                    intl,
                    style: { color: 'var(--light-label)', display: 'inline-block' },
                  });
                  return (
                    <div key={index} className="provisioning-list-item">
                      <div className="item-content">
                        <Popover
                          placement="right"
                          content={
                            <div style={{ fontSize: 12, fontWeight: 'bold', maxWidth: 500 }}>
                              <div>
                                <span style={{ color: 'var(--light-label)', width, display: 'inline-block' }}>
                                  {intl.formatMessage(eventActionMessages.componentName)}:{' '}
                                </span>
                                <span>{findItem.componentName}</span>
                              </div>
                              {viewContent}
                              <div>
                                <span style={{ color: 'var(--light-label)', width, display: 'inline-block' }}>
                                  {intl.formatMessage(eventMessages.projectName)}:{' '}
                                </span>
                                <span>{(findItem?.projectSet || []).join()}</span>
                              </div>
                            </div>
                          }
                        >
                          <div style={{ width: 'fit-content' }}>
                            <span className="item-indicate clickable">{index + 1}</span>
                            <span className="item-label clickable hidden-line-with-ellipsis-multiline">
                              {instanceDisplayName || onlyInstanceName}
                            </span>
                          </div>
                        </Popover>
                      </div>
                      <div className="extra-info">
                        {R.map((_item) => {
                          const avgValue = getViewData(floatFixed(_item.avgValue * 100));
                          const minValue = getViewData(floatFixed(_item.minValue * 100));
                          const maxValue = getViewData(floatFixed(_item.maxValue * 100));
                          const findValue =
                            R.find(
                              (findVal) =>
                                findVal.instanceName === item[0]?.name && findVal.provisionMetric === _item.type,
                              newData,
                            ) || {};
                          const newAvgValue =
                            avgValue === 0 ? getViewData(floatFixed(findValue.avgValue * 100)) : avgValue;
                          const newMinValue =
                            minValue === 0 ? getViewData(floatFixed(findValue.minValue * 100)) : minValue;
                          const newMaxValue =
                            maxValue === 0 ? getViewData(floatFixed(findValue.maxValue * 100)) : maxValue;
                          return (
                            <Popover
                              key={_item.type}
                              content={
                                <div style={{ fontSize: 12, fontWeight: 'bold', maxWidth: 500 }}>
                                  <div>
                                    <span
                                      style={{ color: 'var(--light-label)' }}
                                    >{`Minimum ${_item.type} Usage: `}</span>
                                    <span>{`${newMinValue}%`}</span>
                                  </div>
                                  <div>
                                    <span
                                      style={{ color: 'var(--light-label)' }}
                                    >{`Average ${_item.type} Usage: `}</span>
                                    <span>{`${newAvgValue}%`}</span>
                                  </div>
                                  <div>
                                    <span
                                      style={{ color: 'var(--light-label)' }}
                                    >{`Maximum ${_item.type} Usage: `}</span>
                                    <span>{`${newMaxValue}%`}</span>
                                  </div>
                                </div>
                              }
                            >
                              <div
                                className="clickable"
                                key={_item.type}
                                style={{
                                  background: `${
                                    avgValue === 0
                                      ? ''
                                      : `linear-gradient(0deg, 
                            ${provisioningColorList[avgValue - 1]}, 
                            ${provisioningColorList[avgValue - 1]} 
                            ${avgValue}%, transparent 0)`
                                    // ${avgValue}%, transparent 0) #ebeaea`
                                  }`,
                                  color: '#fff',
                                }}
                              >
                                {returnView(getViewData(floatFixed(_item.avgValue * 100)))}
                              </div>
                            </Popover>
                          );
                        }, item || [])}
                      </div>
                    </div>
                  );
                }, underList)}
              </div>
            )}
            {underList.length < 1 && (
              <div
                style={{
                  color: 'var(--text-color-secondary)',
                  fontSize: 12,
                  textAlign: 'center',
                  lineHeight: '50px',
                }}
              >
                No over-utilized hosts is found
              </div>
            )}

            <div style={{ borderTop: '1px dashed var(--border-color-base)', marginTop: 8, paddingBottom: 8 }} />

            <div className="provisioning-title">
              <div style={{ minWidth: 90, color: 'var(--text-color)', fontSize: 16 }}>Under-utilized hosts</div>
              <div className="provisioning-title-tabs">
                {R.map((item) => {
                  return <div key={item.type}>{typeMapping[item.type]}</div>;
                }, typeList)}
              </div>
            </div>
            {overList.length > 0 && (
              <div className="provisioning-list">
                {R.addIndex(R.map)((item, index) => {
                  const findItem = R.find((only) => item[0]?.name === only?.instanceName, newData || []) || {};
                  const { instanceDisplayName, width, viewContent, onlyInstanceName } = getInstanceDisplayNameFn({
                    instanceDisplayNameMap,
                    instanceName: findItem.instanceName,
                    intl,
                    style: { color: 'var(--light-label)', display: 'inline-block' },
                  });
                  return (
                    <div key={index} className="provisioning-list-item">
                      <div className="item-content">
                        <Popover
                          placement="right"
                          content={
                            <div style={{ fontSize: 12, fontWeight: 'bold', maxWidth: 500 }}>
                              <div>
                                <span style={{ color: 'var(--light-label)', width, display: 'inline-block' }}>
                                  {intl.formatMessage(eventActionMessages.componentName)}:{' '}
                                </span>
                                <span>{findItem.componentName}</span>
                              </div>
                              {viewContent}
                              <div>
                                <span style={{ color: 'var(--light-label)', width, display: 'inline-block' }}>
                                  {intl.formatMessage(eventMessages.projectName)}:{' '}
                                </span>
                                <span>{(findItem?.projectSet || []).join()}</span>
                              </div>
                            </div>
                          }
                        >
                          <div style={{ width: 'fit-content' }}>
                            <span className="item-indicate clickable">{index + 1}</span>
                            <span className="item-label clickable">{instanceDisplayName || onlyInstanceName}</span>
                          </div>
                        </Popover>
                      </div>
                      <div className="extra-info">
                        {R.map((_item) => {
                          const avgValue = getViewData(floatFixed(_item.avgValue * 100));
                          const minValue = getViewData(floatFixed(_item.minValue * 100));
                          const maxValue = getViewData(floatFixed(_item.maxValue * 100));
                          const findValue =
                            R.find(
                              (findVal) =>
                                findVal.instanceName === item[0]?.name && findVal.provisionMetric === _item.type,
                              newData,
                            ) || {};
                          const newAvgValue =
                            avgValue === 0 ? getViewData(floatFixed(findValue.avgValue * 100)) : avgValue;
                          const newMinValue =
                            minValue === 0 ? getViewData(floatFixed(findValue.minValue * 100)) : minValue;
                          const newMaxValue =
                            maxValue === 0 ? getViewData(floatFixed(findValue.maxValue * 100)) : maxValue;
                          return (
                            <Popover
                              key={_item.type}
                              content={
                                <div style={{ fontSize: 12, fontWeight: 'bold', maxWidth: 500 }}>
                                  <div>
                                    <span
                                      style={{ color: 'var(--light-label)' }}
                                    >{`Minimum ${_item.type} Usage: `}</span>
                                    <span>{`${newMinValue}%`}</span>
                                  </div>
                                  <div>
                                    <span
                                      style={{ color: 'var(--light-label)' }}
                                    >{`Average ${_item.type} Usage: `}</span>
                                    <span>{`${newAvgValue}%`}</span>
                                  </div>
                                  <div>
                                    <span
                                      style={{ color: 'var(--light-label)' }}
                                    >{`Maximum ${_item.type} Usage: `}</span>
                                    <span>{`${newMaxValue}%`}</span>
                                  </div>
                                </div>
                              }
                            >
                              <div
                                className="clickable"
                                key={_item.type}
                                style={{
                                  background: `${
                                    avgValue === 0
                                      ? ''
                                      : `linear-gradient(0deg, 
                            ${overProvisioningColorList[avgValue - 1]}, 
                            ${overProvisioningColorList[avgValue - 1]} 
                            ${avgValue}%, transparent 0)`
                                  }`,
                                  color: 'var(--black)',
                                }}
                              >
                                {returnView(getViewData(floatFixed(_item.avgValue * 100)))}
                              </div>
                            </Popover>
                          );
                        }, item || [])}
                      </div>
                    </div>
                  );
                }, overList)}
              </div>
            )}
            {overList.length < 1 && (
              <div
                style={{
                  color: 'var(--text-color-secondary)',
                  fontSize: 12,
                  textAlign: 'center',
                  lineHeight: '50px',
                }}
              >
                No under-utilized hosts is found
              </div>
            )}
          </div>
        </div>
      </div>

      {showOverandunderDetail && (
        <OverandunderDetail
          intl={intl}
          barData={newData}
          typeList={typeList}
          getViewData={getViewData}
          floatFixed={floatFixed}
          currentTheme={currentTheme}
          dailyProvision={dailyProvision}
          onCancel={() => setState({ showOverandunderDetail: false })}
          instanceDisplayNameMap={instanceDisplayNameMap}
        />
      )}

      {false && (
        <CreatePortalDetailsFrame
          domKey="overandunderDetail"
          onCancel={() => setState({ showOverandunderDetail: false })}
          header={[{ title: dashBoardName, action: 'back' }, { title: 'Utilized hosts(Over and under)' }]}
        >
          <OverandunderDetail
            intl={intl}
            barData={newData}
            typeList={typeList}
            getViewData={getViewData}
            floatFixed={floatFixed}
            currentTheme={currentTheme}
            dailyProvision={dailyProvision}
          />
        </CreatePortalDetailsFrame>
      )}
    </Spin>
  );
}
