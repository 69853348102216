/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { isPlainObject, isArray, isString } from 'lodash';

import { LogRenderers } from '../../../common/utils';

type Props = {
  width: Number,
  height: Number,
  queryResult: Object,
  currentTheme: String,
};

class RawContent extends React.PureComponent {
  props: Props;

  render() {
    const { queryResult, currentTheme } = this.props;
    const { width, height } = this.props;
    if (isPlainObject(queryResult) || isArray(queryResult)) {
      return (
        <div className="raw-content" style={{ width, height }}>
          <LogRenderers.JsonTree data={queryResult} currentTheme={currentTheme} />
        </div>
      );
    } else if (isString(queryResult)) {
      try {
        const json = JSON.parse(queryResult);
        return (
          <div className="raw-content" style={{ width, height }}>
            <LogRenderers.JsonTree data={json} currentTheme={currentTheme} />
          </div>
        );
      } catch (e) {
        return <div className="raw-content" dangerouslySetInnerHTML={{ __html: queryResult }} />;
      }
    }
    return <div className="raw-content" dangerouslySetInnerHTML={{ __html: queryResult }} />;
  }
}

export default RawContent;
