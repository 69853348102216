import React from 'react';
import VLink from 'valuelink';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { Button, Popover, Checkbox, message } from 'antd';
import { isString } from 'lodash';
import { Redirect } from 'react-router';

import type { State, Message } from '../../common/types';

import fetchPost from '../../common/apis/fetchPost';
import getEndpoint from '../../common/apis/getEndpoint';
import { Regex, buildLocation, buildUrl, parseLocation } from '../../common/utils';
import { hideAppLoader } from '../../common/app/actions';
import { clearCredentials, sandboxLogin } from '../../common/auth/actions';
import { Input } from '../../lib/fui/react';
import { CenterPage } from '../app/components';

import { appFieldsMessages } from '../../common/app/messages';
import { authMessages } from '../../common/auth/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  appLoaderVisible: Boolean,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  hideAppLoader: Function,
  currentTheme: String,
  sandboxLogin: Function,
  isLoggedIn: Boolean,
  loginReason: Message,
  loginMessage: String,
  isLoggingIn: boolean,
  credentials: Object,
  clearCredentials: Function,
};

class SandBoxSignupCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    const { location } = props;
    const { email, fname, lname, companyName } = parseLocation(location);

    this.state = {
      agreePrivacyPolicy: false,
      email: email || '',
      fname: fname || '',
      lname: lname || '',
      companyName: companyName || '',

      emailFocus: false,

      emailVerify: true,
      emailLoading: false,
      emailVerifyError: false,
      errorMessage: undefined,
    };
  }

  componentDidMount() {
    const { replace, isLoggedIn, credentials, clearCredentials, location } = this.props;
    const query = parseLocation(location);
    replace(
      buildLocation(
        location.pathname,
        {},
        { ...query, email: undefined, fname: undefined, lname: undefined, companyName: undefined },
      ),
    );
    if (!isLoggedIn || credentials) {
      clearCredentials();
      replace(buildUrl(this.getSandboxEnvironment() ? '/auth/sandbox-signup' : '/auth/login2', {}, {}));
    }
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  getSandboxEnvironment() {
    const urlRex = 'https://sandbox.insightfinder.com';
    let url = window.BASE_URL || window.location.origin || '';
    if (R.includes('localhost', url)) {
      url = urlRex;
    }
    return url === urlRex;
  }

  @autobind
  handleLogin(event) {
    event.preventDefault();
    const { sandboxLogin } = this.props;

    const { email, fname, lname, companyName } = this.state;

    sandboxLogin({ email, fname, lname, companyName });
  }

  @autobind
  handlePostEmail(event) {
    event.preventDefault();
    const { sandboxLogin } = this.props;
    const { email } = this.state;

    this.setState({ emailLoading: true, emailVerify: false, emailVerifyError: false, errorMessage: undefined });
    return fetchPost(getEndpoint('sandbox-account-check', 1), {
      email,
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        let content;
        try {
          content = JSON.parse(msg);
        } catch (error) {
          // error
        }

        if ((success || success === undefined) && content) {
          const { exist } = content || {};
          const otherState = { emailLoading: false, emailVerifyError: false, errorMessage: undefined };
          if (!exist) {
            message.warning(
              'The current email address is not registered. You will enter the sandbox registration page in 3 seconds.',
            );
            setTimeout(() => {
              window.location.href = 'https://insightfinder.com/sandbox/';
            }, 3000);
            // this.setState({ ...otherState, emailVerify: true });
          } else {
            this.setState({ ...otherState, emailVerify: false }, () => {
              sandboxLogin({ email });
            });
          }
        } else {
          this.setState({ emailLoading: false, emailVerifyError: true, errorMessage: msg });
        }
      })
      .catch((err) => {
        this.setState({ emailLoading: false, emailVerifyError: true, errorMessage: err.message || String(err) });
      });
  }

  @autobind
  introduceRender() {
    const { intl } = this.props;
    return (
      <div style={{ width: 482 }} className="text-center login_class_form">
        <h2>{intl.formatMessage(authMessages.startedInsightFinderSandboxToday)}</h2>
        <h4 style={{ marginBottom: 8 }}>{intl.formatMessage(authMessages.experienceInsightFinderFirsthand)}</h4>
        <h4>{intl.formatMessage(authMessages.ourCloudBasedDemoSandbox)}</h4>
      </div>
    );
  }

  render() {
    const { intl, push, currentTheme, isLoggedIn, loginReason, loginMessage, isLoggingIn } = this.props;
    const { agreePrivacyPolicy, emailFocus, emailVerify, emailLoading, emailVerifyError, errorMessage } = this.state;

    const hasError = (!isLoggedIn && loginReason) || emailVerifyError;

    if (isLoggedIn) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    const loginMessageStr = isString(loginMessage) ? loginMessage : JSON.stringify(loginMessage || '');
    const loginErrorMessage =
      R.includes('tokenExpiredTime', loginMessageStr) &&
      R.includes('avatarId', loginMessageStr) &&
      R.includes('data', loginMessageStr)
        ? intl.formatMessage(authMessages.errorsWrongCredential)
        : loginMessage;

    const fnameLink = VLink.state(this, 'fname').check(
      (value) => value,
      intl.formatMessage(appFieldsMessages.inputRequired),
    );

    const lnameLink = VLink.state(this, 'lname').check(
      (value) => value,
      intl.formatMessage(appFieldsMessages.inputRequired),
    );

    const companyNameLink = VLink.state(this, 'companyName').check(
      (value) => value,
      intl.formatMessage(appFieldsMessages.inputRequired),
    );

    const emailLink = VLink.state(this, 'email')
      .check((value) => value, intl.formatMessage(appFieldsMessages.inputRequired))
      .check((value) => Regex.email.test(value), intl.formatMessage(authMessages.errorsEmailIncorrect));

    const emailVerifyDisabled = emailLink.error;
    const loginDisabled =
      !agreePrivacyPolicy || fnameLink.error || lnameLink.error || companyNameLink.error || emailVerifyDisabled;

    return (
      <CenterPage
        intl={intl}
        push={push}
        className="auth"
        currentTheme={currentTheme}
        introduceRender={emailVerify ? undefined : this.introduceRender}
      >
        <h3 style={{ textAlign: 'center' }}>{intl.formatMessage(authMessages.startedInsightFinderSandbox)}</h3>
        <form className={`ui ${hasError ? 'error' : ''} form login_class_form`} style={{ width: 410 }}>
          {hasError && <div className="ui error message">{loginErrorMessage || errorMessage}</div>}

          {emailVerify && (
            <>
              <div className="input inline field required flex-col" style={{ marginTop: 36, cursor: 'not-allowed' }}>
                <label style={{ width: 54, margin: '0 0 4px 0', fontSize: 14 }}>
                  {intl.formatMessage(appFieldsMessages.email)}
                </label>
                <Popover
                  visible={emailFocus}
                  placement="top"
                  title={null}
                  content={
                    <div className="flex-col" style={{ maxWidth: 200 }}>
                      Please use your company email address in your trial request.
                    </div>
                  }
                >
                  <Input
                    valueLink={emailLink}
                    className="flex-grow"
                    disabled
                    onFocus={() => this.setState({ emailFocus: true })}
                    onBlur={() => this.setState({ emailFocus: false })}
                    initVerify
                  />
                </Popover>
              </div>

              <div className="input inline field required flex-col" style={{ cursor: 'not-allowed' }}>
                <label style={{ width: 120, margin: '0 0 4px 0' }}>{intl.formatMessage(appFieldsMessages.fname)}</label>
                <Input valueLink={fnameLink} style={{ width: 200 }} disabled />
              </div>

              <div className="input inline field required flex-col" style={{ cursor: 'not-allowed' }}>
                <label style={{ width: 120, margin: '0 0 4px 0' }}>{intl.formatMessage(appFieldsMessages.lname)}</label>
                <Input valueLink={lnameLink} style={{ width: 200 }} disabled />
              </div>

              <div className="input inline field required flex-col" style={{ marginBottom: 36, cursor: 'not-allowed' }}>
                <label style={{ width: 120, margin: '0 0 4px 0' }}>
                  {intl.formatMessage(appFieldsMessages.companyName)}
                </label>
                <Input valueLink={companyNameLink} style={{ width: 200 }} disabled />
              </div>
            </>
          )}

          {!emailVerify && (
            <div className="input inline field required flex-col" style={{ margin: '36px 0' }}>
              <label style={{ width: 54, margin: '0 0 4px 0', fontSize: 14 }}>
                {intl.formatMessage(appFieldsMessages.email)}
              </label>
              <Popover
                visible={emailFocus}
                placement="top"
                title={null}
                content={
                  <div className="flex-col" style={{ maxWidth: 200 }}>
                    Please use your company email address in your trial request.
                  </div>
                }
              >
                <Input
                  valueLink={emailLink}
                  className="flex-grow"
                  style={{ height: 40 }}
                  onFocus={() => this.setState({ emailFocus: true })}
                  onBlur={() => this.setState({ emailFocus: false })}
                />
              </Popover>
            </div>
          )}

          {emailVerify && (
            <div className="inline flex-row flex-center-align" style={{ margin: '0em 0em 1.5em' }}>
              <Checkbox
                checked={agreePrivacyPolicy}
                onChange={(e) => {
                  this.setState({ agreePrivacyPolicy: e.target.checked });
                }}
              />
              <div style={{ marginLeft: 8, textTransform: 'none' }}>
                {intl.formatMessage(authMessages.hintsAgree)}
                <a href="https://insightfinder.com/terms-of-use" target="_blank" rel="noreferrer">
                  &nbsp;{intl.formatMessage(authMessages.termsUse)}
                </a>{' '}
                {intl.formatMessage(appFieldsMessages.and)}
                <a href="https://insightfinder.com/privacy-policy" target="_blank" rel="noreferrer">
                  &nbsp;{intl.formatMessage(authMessages.privacyPolicy)}
                </a>
                .
              </div>
            </div>
          )}

          <div className="field flex-row">
            <div className="flex-grow" />
            {emailVerify && (
              <Button
                type="primary"
                loading={isLoggingIn}
                disabled={loginDisabled}
                onClick={this.handleLogin}
                htmlType="submit"
              >
                {intl.formatMessage(authMessages.buttonsSignUp)}
              </Button>
            )}
            {!emailVerify && (
              <Button
                type="primary"
                loading={emailLoading || isLoggingIn}
                disabled={emailVerifyDisabled}
                onClick={this.handlePostEmail}
                htmlType="submit"
              >
                {intl.formatMessage(authMessages.buttonsSignIn)}
              </Button>
            )}
          </div>
        </form>
      </CenterPage>
    );
  }
}

const SandBoxSignup = injectIntl(SandBoxSignupCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { currentTheme, appLoaderVisible } = state.app;
    const { loggedIn, loginReason, loginMessage, loggingIn, credentials } = state.auth;
    return {
      currentTheme,
      location,
      appLoaderVisible,
      isLoggedIn: loggedIn,
      loginReason,
      loginMessage,
      isLoggingIn: loggingIn,
      credentials,
    };
  },
  { push, replace, hideAppLoader, sandboxLogin, clearCredentials },
)(SandBoxSignup);
