/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

const onWindowInit = () => {
  window.Promise = require('../common/configureBluebird');
  require('whatwg-fetch');

  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
  require('@formatjs/intl-pluralrules/locale-data/zh');

  require('./main');
};

// Intl.js and Browserify/webpack
// github.com/andyearnshaw/Intl.js/#intljs-and-browserifywebpack
if (!window.Intl) {
  // Import intl.js if needed
  import('../common/polyfillIntl.js').then(() => {
    onWindowInit();
  });
} else {
  onWindowInit();
}
