/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get, isInteger, isNumber, toInteger, toString } from 'lodash';
import { autobind } from 'core-decorators';
import VLink from 'valuelink';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { QuestionCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Tooltip, Tabs, Popover, Alert, InputNumber, Select as AntdSelect, Switch } from 'antd';

import { Container, Input, Select } from '../../../../lib/fui/react';
import { showAppAlert } from '../../../../common/app/actions';

import { appMenusMessages, appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

import {
  AnomalyThresholdSensitivity,
  FeatureOutlierSensitivity,
  SimilaritySensitivity,
} from '../../../app/components/Selectors';

type Props = {
  intl: Object,
  projectName: String,
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  saveProjectSettings: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  tabStyle: Object,
  entry: String,
  refs: Function,
  handleLogSensitivity: Function,
  updateStatus: String,
};

class LogSensitivitySettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.submitLoadingKey = 'setting_alertsensitivity_submit';

    const pValue = get(props, ['data', 'pValue']);
    const rareEventAlertThresholds = get(props, ['data', 'rareEventAlertThresholds']) || 0;
    const incidentCountThreshold = get(props, ['data', 'incidentCountThreshold']) || 0;
    const newPatternRange = get(props, ['data', 'newPatternRange'], '');
    const normalEventCausalFlag = get(props, ['data', 'normalEventCausalFlag'], false);
    const dailyModelSpan = get(props, ['data', 'dailyModelSpan'], '');
    const keywordFeatureNumber = get(props, ['data', 'keywordFeatureNumber'], '');
    const maxLogModelSize = get(props, ['data', 'maxLogModelSize'], '');
    const modelKeywordSetting = get(props, ['data', 'modelKeywordSetting'], '');
    const featureAnalysisFlag = get(props, ['data', 'featureAnalysisFlag'], false);
    const projectModelFlag = get(props, ['data', 'projectModelFlag'], false);
    const isGroupingByInstance = get(props, ['data', 'isGroupingByInstance'], false);
    const nlpFlag = get(props, ['data', 'nlpFlag'], false);
    const disableModelKeywordStatsCollection = get(props, ['data', 'disableModelKeywordStatsCollection'], false);
    const collectAllRareEventsFlag = get(props, ['data', 'collectAllRareEventsFlag'], false);
    const newAlertFlag = get(props, ['data', 'newAlertFlag'], false);
    const similaritySensitivity = get(props, ['data', 'similaritySensitivity'], '');
    const featureOutlierSensitivity = get(props, ['data', 'featureOutlierSensitivity'], 'medium');
    const whitelistNumberLimit = get(props, ['data', 'whitelistNumberLimit'], '');
    const newPatternNumberLimit = get(props, ['data', 'newPatternNumberLimit'], '');
    const incidentPredictionWindow = get(props, ['data', 'incidentPredictionWindow'], '');
    const minIncidentPredictionWindow = get(props, ['data', 'minIncidentPredictionWindow'], '');
    const incidentRelationSearchWindow = get(props, ['data', 'incidentRelationSearchWindow'], '');
    const incidentPredictionEventLimit = get(props, ['data', 'incidentPredictionEventLimit'], '');
    const predictionCountThreshold = get(props, ['data', 'predictionCountThreshold'], '');
    const rootCauseCountThreshold = get(props, ['data', 'rootCauseCountThreshold'], '');
    const compositeRCALimit = get(props, ['data', 'compositeRCALimit'], 1);
    const rootCauseProbabilityThreshold = get(props, ['data', 'rootCauseProbabilityThreshold'], '');
    const rootCauseLogMessageSearchRange = get(props, ['data', 'rootCauseLogMessageSearchRange'], '');
    const maximumThreads = get(props, ['data', 'maximumThreads'], '');
    const logPatternLimitLevel = get(props, ['data', 'logPatternLimitLevel'], '1024');
    const logDetectionMinCount = get(props, ['data', 'logDetectionMinCount'], '');
    const maximumDetectionWaitTime = get(props, ['data', 'maximumDetectionWaitTime'], '');
    const keywordSetting = get(props, ['data', 'keywordSetting'], '0');
    const logAnomalyEventBaseScore = get(props, ['data', 'logAnomalyEventBaseScore']) || [];
    const causalPredictionSetting = get(props, ['data', 'causalPredictionSetting']);
    const rootCauseRankSetting = get(props, ['data', 'rootCauseRankSetting']);
    const causalPredictionCacheTimeout = get(props, ['data', 'causalPredictionCacheTimeout'], '0');
    const causalMinDelay = get(props, ['data', 'causalMinDelay'], '0');
    const maximumRootCauseResultSize = get(props, ['data', 'maximumRootCauseResultSize'], '');
    const multiHopSearchLevel = get(props, ['data', 'multiHopSearchLevel'], '');
    const multiHopSearchLimit = get(props, ['data', 'multiHopSearchLimit'], '');
    const avgPerIncidentDowntimeCost = get(props, ['data', 'avgPerIncidentDowntimeCost'], '');
    const alertHourlyCost = get(props, ['data', 'alertHourlyCost'], '');
    const alertAverageTime = get(props, ['data', 'alertAverageTime'], '');
    const rareAnomalyType = get(props, ['data', 'rareAnomalyType']);
    const shouldConfigSamplingInterval = get(props, ['data', 'shouldConfigSamplingInterval']);
    const samplingInterval = get(props, ['data', 'samplingInterval']);
    const incidentConsolidationInterval = get(props, ['data', 'incidentConsolidationInterval']);
    const incidentDurationThreshold = get(props, ['data', 'incidentDurationThreshold']);
    const samplingUnit = get(props, ['data', 'samplingUnit']);
    const ignoreInstanceForKB = get(props, ['data', 'ignoreInstanceForKB'], false);
    const disableLogCompressEvent = get(props, ['data', 'disableLogCompressEvent'], false);
    const anomalyDetectionMode = get(props, ['data', 'anomalyDetectionMode'], 0);
    const showInstanceDown = get(props, ['data', 'showInstanceDown'], false);
    const hotEventDetectionMode = get(props, ['data', 'hotEventDetectionMode'], '1');
    const retentionTime = get(props, ['data', 'retentionTime'], 365);
    const UBLRetentionTime = get(props, ['data', 'UBLRetentionTime'], 365);
    const enableHotEvent = get(props, ['data', 'enableHotEvent']);

    const {
      rareNumberLimit,
      hotNumberLimit,
      coldNumberLimit,
      logDetectionSize,
      coldEventThreshold,
      hotEventThreshold,
    } = get(props, ['data'], {});

    const initState = {
      activeKey: '1',

      pValue,
      rareEventAlertThresholds,
      incidentCountThreshold,
      newPatternRange,
      normalEventCausalFlag,
      dailyModelSpan,
      keywordFeatureNumber,
      maxLogModelSize,
      modelKeywordSetting,
      featureAnalysisFlag,
      projectModelFlag,
      isGroupingByInstance,
      nlpFlag,
      disableModelKeywordStatsCollection,
      collectAllRareEventsFlag,
      newAlertFlag,
      similaritySensitivity,
      featureOutlierSensitivity,
      maximumThreads,
      logDetectionMinCount,
      logDetectionSize: logDetectionSize || 0,
      maximumDetectionWaitTime,
      keywordSetting: String(keywordSetting),
      logPatternLimitLevel,
      rareNumberLimit: rareNumberLimit || 0,
      rareAnomalyType: String(rareAnomalyType),
      hotNumberLimit: hotNumberLimit || 0,
      coldNumberLimit: coldNumberLimit || 0,
      coldEventThreshold: coldEventThreshold || 0,
      hotEventThreshold: hotEventThreshold || 0,
      hotEventDetectionMode,
      whitelistNumberLimit,
      newPatternNumberLimit,
      incidentPredictionWindow: String(incidentPredictionWindow),
      minIncidentPredictionWindow: String(minIncidentPredictionWindow),
      incidentRelationSearchWindow,
      incidentPredictionEventLimit,
      shouldConfigSamplingInterval,
      samplingInterval,
      incidentConsolidationInterval: Number(incidentConsolidationInterval) / 60000,
      incidentDurationThreshold: Number(incidentDurationThreshold) / 60000,
      samplingUnit,
      predictionCountThreshold,
      rootCauseCountThreshold,
      compositeRCALimit,
      rootCauseProbabilityThreshold: isNumber(rootCauseProbabilityThreshold)
        ? toInteger(rootCauseProbabilityThreshold * 100)
        : '',
      rootCauseLogMessageSearchRange,
      rareEventBaseScore: logAnomalyEventBaseScore[0] || 0,
      hotEventBaseScore: logAnomalyEventBaseScore[1] || 0,
      coldEventBaseScore: logAnomalyEventBaseScore[2] || 0,
      whitelistEventBaseScore: logAnomalyEventBaseScore[3] || 0,
      newPatternEventBaseScore: logAnomalyEventBaseScore[4] || 0,
      criticalEventBaseScore: logAnomalyEventBaseScore[5] || 0,
      causalPredictionSetting,
      rootCauseRankSetting,
      causalPredictionCacheTimeout: causalPredictionCacheTimeout / 1000 / 60,
      causalMinDelay: causalMinDelay / 1000 / 60,
      maximumRootCauseResultSize,
      multiHopSearchLevel,
      multiHopSearchLimit,
      avgPerIncidentDowntimeCost,
      alertHourlyCost,
      alertAverageTime: alertAverageTime / 1000 / 60,
      ignoreInstanceForKB,
      disableLogCompressEvent,
      anomalyDetectionMode,
      showInstanceDown,
      retentionTime,
      UBLRetentionTime,
      enableHotEvent,
    };
    this.state = initState;
    this.localData = initState;

    this.modelKeywordSettingOptions = [
      { label: intl.formatMessage(settingsMessages.lettersOnly), value: '0' },
      { label: intl.formatMessage(settingsMessages.lettersAndNumbers), value: '1' },
    ];
    this.keywordSettingOptions = [
      { label: intl.formatMessage(settingsMessages.none), value: '-1' },
      { label: intl.formatMessage(settingsMessages.lettersOnly), value: '0' },
      { label: intl.formatMessage(settingsMessages.lettersAndNumbers), value: '1' },
    ];
    this.rareAnomalyTypeOptions = [
      { label: intl.formatMessage(settingsMessages.rareAnomalyTypeAll), value: '0' },
      { label: intl.formatMessage(settingsMessages.rareAnomalyTypeWordsOnly), value: '1' },
      { label: intl.formatMessage(settingsMessages.rareAnomalyTypeNumericOnly), value: '2' },
    ];
    this.predictionRelationsOptions = [
      { label: intl.formatMessage(settingsMessages.allRelationships), value: '0' },
      { label: intl.formatMessage(settingsMessages.interInstanceRelationships), value: '1' },
      { label: intl.formatMessage(settingsMessages.intraInstanceRelationships), value: '2' },
    ];
    this.rootCauseRankOptions = [
      { label: intl.formatMessage(settingsMessages.intraInstanceFirst), value: '0' },
      { label: intl.formatMessage(settingsMessages.interInstanceFirst), value: '1' },
      { label: intl.formatMessage(settingsMessages.noPreference), value: '2' },
    ];
    this.samplingUnitOption = [
      { label: intl.formatMessage(appFieldsMessages.minute), value: 60 },
      { label: intl.formatMessage(appFieldsMessages.hour), value: 3600 },
      { label: intl.formatMessage(appFieldsMessages.day), value: 86400 },
    ];
    this.hotEventDetectionModeOption = [
      { label: intl.formatMessage(settingsMessages.hotEventDetectionModeAll), value: '0' },
      { label: intl.formatMessage(settingsMessages.hotEventDetectionModeWhitelist), value: '1' },
    ];
  }

  componentDidMount() {
    if (this.props.entry === 'batch') {
      this.props.refs(this);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const pValue = get(newProps, ['data', 'pValue']);
    const rareEventAlertThresholds = get(newProps, ['data', 'rareEventAlertThresholds']);
    const incidentCountThreshold = get(newProps, ['data', 'incidentCountThreshold']);
    const newPatternRange = get(newProps, ['data', 'newPatternRange']);
    const similaritySensitivity = get(newProps, ['data', 'similaritySensitivity']);
    const featureOutlierSensitivity = get(newProps, ['data', 'featureOutlierSensitivity']);
    const normalEventCausalFlag = get(newProps, ['data', 'normalEventCausalFlag']);
    const dailyModelSpan = get(newProps, ['data', 'dailyModelSpan']);
    const keywordFeatureNumber = get(newProps, ['data', 'keywordFeatureNumber']);
    const maxLogModelSize = get(newProps, ['data', 'maxLogModelSize']);
    const modelKeywordSetting = get(newProps, ['data', 'modelKeywordSetting']);
    const featureAnalysisFlag = get(newProps, ['data', 'featureAnalysisFlag']);
    const projectModelFlag = get(newProps, ['data', 'projectModelFlag']);
    const isGroupingByInstance = get(newProps, ['data', 'isGroupingByInstance']);
    const nlpFlag = get(newProps, ['data', 'nlpFlag'], false);
    const disableModelKeywordStatsCollection = get(newProps, ['data', 'disableModelKeywordStatsCollection'], false);
    const whitelistNumberLimit = get(newProps, ['data', 'whitelistNumberLimit']);
    const newPatternNumberLimit = get(newProps, ['data', 'newPatternNumberLimit']);
    const incidentPredictionWindow = get(newProps, ['data', 'incidentPredictionWindow']);
    const minIncidentPredictionWindow = get(newProps, ['data', 'minIncidentPredictionWindow']);
    const incidentRelationSearchWindow = get(newProps, ['data', 'incidentRelationSearchWindow']);
    const incidentPredictionEventLimit = get(newProps, ['data', 'incidentPredictionEventLimit']);
    const predictionCountThreshold = get(newProps, ['data', 'predictionCountThreshold']);
    const rootCauseCountThreshold = get(newProps, ['data', 'rootCauseCountThreshold']);
    const compositeRCALimit = get(newProps, ['data', 'compositeRCALimit']);
    const rootCauseProbabilityThreshold = get(newProps, ['data', 'rootCauseProbabilityThreshold']);
    const rootCauseLogMessageSearchRange = get(newProps, ['data', 'rootCauseLogMessageSearchRange']);
    const collectAllRareEventsFlag = get(newProps, ['data', 'collectAllRareEventsFlag']);
    const newAlertFlag = get(newProps, ['data', 'newAlertFlag']);
    const maximumThreads = get(newProps, ['data', 'maximumThreads']);
    const logDetectionMinCount = get(newProps, ['data', 'logDetectionMinCount']);
    const maximumDetectionWaitTime = get(newProps, ['data', 'maximumDetectionWaitTime']);
    const keywordSetting = get(newProps, ['data', 'keywordSetting']);
    const logAnomalyEventBaseScore = get(newProps, ['data', 'logAnomalyEventBaseScore']) || [];
    const causalPredictionCacheTimeout = get(newProps, ['data', 'causalPredictionCacheTimeout']);
    const causalMinDelay = get(newProps, ['data', 'causalMinDelay']);
    const causalPredictionSetting = get(newProps, ['data', 'causalPredictionSetting']);
    const rootCauseRankSetting = get(newProps, ['data', 'rootCauseRankSetting']);
    const maximumRootCauseResultSize = get(newProps, ['data', 'maximumRootCauseResultSize']);
    const multiHopSearchLevel = get(newProps, ['data', 'multiHopSearchLevel']);
    const multiHopSearchLimit = get(newProps, ['data', 'multiHopSearchLimit']);
    const avgPerIncidentDowntimeCost = get(newProps, ['data', 'avgPerIncidentDowntimeCost']);
    const logPatternLimitLevel = get(newProps, ['data', 'logPatternLimitLevel']);
    const alertHourlyCost = get(newProps, ['data', 'alertHourlyCost']);
    const alertAverageTime = get(newProps, ['data', 'alertAverageTime']);
    const rareAnomalyType = get(newProps, ['data', 'rareAnomalyType']);
    const shouldConfigSamplingInterval = get(newProps, ['data', 'shouldConfigSamplingInterval']);
    const samplingInterval = get(newProps, ['data', 'samplingInterval']);
    const incidentConsolidationInterval = get(newProps, ['data', 'incidentConsolidationInterval']);
    const incidentDurationThreshold = get(newProps, ['data', 'incidentDurationThreshold']);
    const samplingUnit = get(newProps, ['data', 'samplingUnit']);
    const ignoreInstanceForKB = get(newProps, ['data', 'ignoreInstanceForKB']);
    const disableLogCompressEvent = get(newProps, ['data', 'disableLogCompressEvent'], false);
    const anomalyDetectionMode = get(newProps, ['data', 'anomalyDetectionMode'], 0);
    const showInstanceDown = get(newProps, ['data', 'showInstanceDown']);
    const hotEventDetectionMode = get(newProps, ['data', 'hotEventDetectionMode'], '1');
    const retentionTime = get(newProps, ['data', 'retentionTime'], 365);
    const UBLRetentionTime = get(newProps, ['data', 'UBLRetentionTime'], 365);
    const enableHotEvent = get(newProps, ['data', 'enableHotEvent']);

    const {
      rareNumberLimit,
      hotNumberLimit,
      coldNumberLimit,
      logDetectionSize,
      coldEventThreshold,
      hotEventThreshold,
    } = get(newProps, ['data'], {});

    // If the props is changed, set the local state.
    if (
      (pValue !== get(this.props, ['data', 'pValue']) ||
        similaritySensitivity !== get(this.props, ['data', 'similaritySensitivity']) ||
        featureOutlierSensitivity !== get(this.props, ['data', 'featureOutlierSensitivity']) ||
        rareEventAlertThresholds !== get(this.props, ['data', 'rareEventAlertThresholds']) ||
        incidentCountThreshold !== get(this.props, ['data', 'incidentCountThreshold']) ||
        newPatternRange !== get(this.props, ['data', 'newPatternRange']) ||
        normalEventCausalFlag !== get(this.props, ['data', 'normalEventCausalFlag']) ||
        dailyModelSpan !== get(this.props, ['data', 'dailyModelSpan']) ||
        keywordFeatureNumber !== get(this.props, ['data', 'keywordFeatureNumber']) ||
        maxLogModelSize !== get(this.props, ['data', 'maxLogModelSize']) ||
        modelKeywordSetting !== get(this.props, ['data', 'modelKeywordSetting']) ||
        featureAnalysisFlag !== get(this.props, ['data', 'featureAnalysisFlag']) ||
        projectModelFlag !== get(this.props, ['data', 'projectModelFlag']) ||
        isGroupingByInstance !== get(this.props, ['data', 'isGroupingByInstance']) ||
        nlpFlag !== get(this.props, ['data', 'nlpFlag']) ||
        disableModelKeywordStatsCollection !== get(this.props, ['data', 'disableModelKeywordStatsCollection']) ||
        whitelistNumberLimit !== get(this.props, ['data', 'whitelistNumberLimit']) ||
        newPatternNumberLimit !== get(this.props, ['data', 'newPatternNumberLimit']) ||
        incidentPredictionWindow !== get(this.props, ['data', 'incidentPredictionWindow']) ||
        minIncidentPredictionWindow !== get(this.props, ['data', 'minIncidentPredictionWindow']) ||
        incidentRelationSearchWindow !== get(this.props, ['data', 'incidentRelationSearchWindow']) ||
        incidentPredictionEventLimit !== get(this.props, ['data', 'incidentPredictionEventLimit']) ||
        predictionCountThreshold !== get(this.props, ['data', 'predictionCountThreshold']) ||
        rootCauseCountThreshold !== get(this.props, ['data', 'rootCauseCountThreshold']) ||
        compositeRCALimit !== get(this.props, ['data', 'compositeRCALimit']) ||
        rootCauseProbabilityThreshold !== get(this.props, ['data', 'rootCauseProbabilityThreshold']) ||
        rootCauseLogMessageSearchRange !== get(this.props, ['data', 'rootCauseLogMessageSearchRange']) ||
        collectAllRareEventsFlag !== get(this.props, ['data', 'collectAllRareEventsFlag']) ||
        newAlertFlag !== get(this.props, ['data', 'newAlertFlag']) ||
        maximumThreads !== get(this.props, ['data', 'maximumThreads']) ||
        logDetectionMinCount !== get(this.props, ['data', 'logDetectionMinCount']) ||
        logDetectionSize !== get(this.props, ['data', 'logDetectionSize']) ||
        maximumDetectionWaitTime !== get(this.props, ['data', 'maximumDetectionWaitTime']) ||
        keywordSetting !== get(this.props, ['data', 'keywordSetting']) ||
        logAnomalyEventBaseScore !== get(this.props, ['data', 'logAnomalyEventBaseScore']) ||
        rareNumberLimit !== get(this.props, ['data', 'rareNumberLimit']) ||
        shouldConfigSamplingInterval !== get(this.props, ['data', 'shouldConfigSamplingInterval']) ||
        rareAnomalyType !== get(this.props, ['data', 'rareAnomalyType']) ||
        hotNumberLimit !== get(this.props, ['data', 'hotNumberLimit']) ||
        logPatternLimitLevel !== get(this.props, ['data', 'logPatternLimitLevel']) ||
        coldNumberLimit !== get(this.props, ['data', 'coldNumberLimit']) ||
        coldEventThreshold !== get(this.props, ['data', 'coldEventThreshold']) ||
        hotEventThreshold !== get(this.props, ['data', 'hotEventThreshold']) ||
        hotEventDetectionMode !== get(this.props, ['data', 'hotEventDetectionMode']) ||
        samplingInterval !== get(this.props, ['data', 'samplingInterval']) ||
        incidentConsolidationInterval !== get(this.props, ['data', 'incidentConsolidationInterval']) ||
        incidentDurationThreshold !== get(this.props, ['data', 'incidentDurationThreshold']) ||
        samplingUnit !== get(this.props, ['data', 'samplingUnit']) ||
        causalPredictionSetting !== get(this.props, ['data', 'causalPredictionSetting']) ||
        rootCauseRankSetting !== get(this.props, ['data', 'rootCauseRankSetting']) ||
        causalPredictionCacheTimeout !== get(this.props, ['data', 'causalPredictionCacheTimeout']) ||
        causalMinDelay !== get(this.props, ['data', 'causalMinDelay']) ||
        maximumRootCauseResultSize !== get(this.props, ['data', 'maximumRootCauseResultSize']) ||
        multiHopSearchLevel !== get(this.props, ['data', 'multiHopSearchLevel']) ||
        multiHopSearchLimit !== get(this.props, ['data', 'multiHopSearchLimit']) ||
        avgPerIncidentDowntimeCost !== get(this.props, ['data', 'avgPerIncidentDowntimeCost']) ||
        alertHourlyCost !== get(this.props, ['data', 'alertHourlyCost']) ||
        alertAverageTime !== get(this.props, ['data', 'alertAverageTime']) ||
        ignoreInstanceForKB !== get(this.props, ['data', 'ignoreInstanceForKB']) ||
        disableLogCompressEvent !== get(this.props, ['data', 'disableLogCompressEvent']) ||
        anomalyDetectionMode !== get(this.props, ['data', 'anomalyDetectionMode']) ||
        retentionTime !== get(this.props, ['data', 'retentionTime'], 365) ||
        UBLRetentionTime !== get(this.props, ['data', 'UBLRetentionTime'], 365) ||
        enableHotEvent !== get(this.props, ['data', 'enableHotEvent']) ||
        showInstanceDown !== get(this.props, ['data', 'showInstanceDown'])) &&
      !R.isEmpty(newProps.data)
    ) {
      const isBatch = R.equals(newProps.entry, 'batch');
      if (isBatch && newProps.updateStatus === 'tab') {
        return;
      }
      const forms = {
        pValue,
        rareEventAlertThresholds,
        incidentCountThreshold,
        newPatternRange,
        similaritySensitivity,
        featureOutlierSensitivity,
        normalEventCausalFlag,
        dailyModelSpan,
        keywordFeatureNumber,
        maxLogModelSize,
        modelKeywordSetting,
        featureAnalysisFlag,
        projectModelFlag,
        isGroupingByInstance,
        nlpFlag,
        disableModelKeywordStatsCollection,
        collectAllRareEventsFlag,
        newAlertFlag,
        maximumThreads,
        logDetectionMinCount,
        logDetectionSize,
        maximumDetectionWaitTime,
        keywordSetting: String(keywordSetting),
        rareNumberLimit,
        rareAnomalyType: String(rareAnomalyType),
        hotNumberLimit,
        logPatternLimitLevel,
        coldNumberLimit,
        coldEventThreshold,
        hotEventThreshold,
        hotEventDetectionMode,
        whitelistNumberLimit,
        newPatternNumberLimit,
        shouldConfigSamplingInterval,
        samplingInterval,
        incidentConsolidationInterval: Number(incidentConsolidationInterval) / 60000,
        incidentDurationThreshold: Number(incidentDurationThreshold) / 60000,
        samplingUnit,
        incidentPredictionWindow: String(incidentPredictionWindow),
        minIncidentPredictionWindow: String(minIncidentPredictionWindow),
        incidentRelationSearchWindow,
        incidentPredictionEventLimit,
        predictionCountThreshold,
        rootCauseCountThreshold,
        compositeRCALimit,
        rootCauseProbabilityThreshold: isNumber(rootCauseProbabilityThreshold)
          ? toInteger(rootCauseProbabilityThreshold * 100)
          : '',
        rootCauseLogMessageSearchRange,
        rareEventBaseScore: logAnomalyEventBaseScore[0],
        hotEventBaseScore: logAnomalyEventBaseScore[1],
        coldEventBaseScore: logAnomalyEventBaseScore[2],
        whitelistEventBaseScore: logAnomalyEventBaseScore[3],
        newPatternEventBaseScore: logAnomalyEventBaseScore[4],
        criticalEventBaseScore: logAnomalyEventBaseScore[5],
        causalPredictionSetting,
        rootCauseRankSetting,
        causalPredictionCacheTimeout: causalPredictionCacheTimeout / 1000 / 60,
        causalMinDelay: causalMinDelay / 1000 / 60,
        maximumRootCauseResultSize,
        multiHopSearchLevel,
        multiHopSearchLimit,
        avgPerIncidentDowntimeCost,
        alertHourlyCost,
        alertAverageTime: alertAverageTime / 1000 / 60,
        ignoreInstanceForKB,
        disableLogCompressEvent,
        anomalyDetectionMode,
        showInstanceDown,
        retentionTime,
        UBLRetentionTime,
        enableHotEvent,
      };
      this.setState(forms);
      this.localData = forms;
    }
  }

  @autobind
  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  @autobind
  diffFlagVal(newVal, oldVal) {
    return toString(newVal) !== toString(oldVal);
  }

  @autobind
  getBaseScorVal() {
    const { rareEventBaseScore, hotEventBaseScore, coldEventBaseScore, whitelistEventBaseScore } = this.localData;
    const { newPatternEventBaseScore, criticalEventBaseScore } = this.localData;
    return JSON.stringify([
      parseFloat(rareEventBaseScore),
      parseFloat(hotEventBaseScore),
      parseFloat(coldEventBaseScore),
      parseFloat(whitelistEventBaseScore),
      parseFloat(newPatternEventBaseScore),
      parseFloat(criticalEventBaseScore),
    ]);
  }

  @autobind
  diffSensitivity(sensitivity) {
    const { samplingUnit } = this.state;
    const sensitivityObj = { oldValMap: {}, newValMap: {} };
    R.forEach((item) => {
      const newVal = sensitivity[item];
      const oldVal = this.localData[item];
      if (item === 'logAnomalyEventBaseScore') {
        const oldSetVal = this.getBaseScorVal();
        if (this.diffFlagVal(newVal, oldSetVal)) {
          sensitivityObj.oldValMap[item] = oldSetVal;
          sensitivityObj.newValMap[item] = newVal;
        }
        return;
      }
      if (!R.has(item, this.localData)) return;
      if (
        item === 'causalPredictionCacheTimeout' ||
        item === 'causalMinDelay' ||
        item === 'alertAverageTime' ||
        item === 'incidentConsolidationInterval' ||
        item === 'incidentDurationThreshold'
      ) {
        const oldSetVal = isNumber(oldVal) ? oldVal * 60 * 1000 : oldVal;
        if (this.diffFlagVal(newVal, oldSetVal)) {
          sensitivityObj.oldValMap[item] = oldSetVal;
          sensitivityObj.newValMap[item] = newVal;
        }
      } else if (item === 'rootCauseProbabilityThreshold') {
        const oldSetVal = isNumber(oldVal) ? oldVal / 100 : oldVal;
        if (this.diffFlagVal(newVal, oldSetVal)) {
          sensitivityObj.oldValMap[item] = oldSetVal;
          sensitivityObj.newValMap[item] = newVal;
        }
      } else if (item === 'samplingInterval') {
        const oldSetVal = isNumber(oldVal) ? (oldVal * samplingUnit) / 60 : oldVal;
        if (this.diffFlagVal(newVal, oldSetVal)) {
          sensitivityObj.oldValMap[item] = oldSetVal;
          sensitivityObj.newValMap[item] = newVal;
        }
      } else if (toString(newVal) !== toString(oldVal)) {
        if (!Number.isNaN(newVal)) {
          sensitivityObj.oldValMap[item] = oldVal;
          sensitivityObj.newValMap[item] = newVal;
        }
      }
    }, R.keys(sensitivity));
    return sensitivityObj;
  }

  @autobind
  handleSaveClick() {
    const { saveProjectSettings, projectName, entry, handleLogSensitivity } = this.props;
    const {
      similaritySensitivity,
      featureOutlierSensitivity,
      normalEventCausalFlag,
      featureAnalysisFlag,
      projectModelFlag,
      isGroupingByInstance,
      nlpFlag,
      disableModelKeywordStatsCollection,
      collectAllRareEventsFlag,
      newAlertFlag,
      causalPredictionSetting,
      rootCauseRankSetting,
      rareAnomalyType,
      shouldConfigSamplingInterval,
      samplingInterval,
      incidentConsolidationInterval,
      incidentDurationThreshold,
      samplingUnit,
      ignoreInstanceForKB,
      disableLogCompressEvent,
      anomalyDetectionMode,
      showInstanceDown,
      retentionTime,
      UBLRetentionTime,
      enableHotEvent,
    } = this.state;

    const logPatternLimitLevel = parseInt(this.state.logPatternLimitLevel, 10);
    const pValue = parseFloat(this.state.pValue);
    const rareEventAlertThresholds = parseInt(this.state.rareEventAlertThresholds, 10);
    const incidentCountThreshold = parseInt(this.state.incidentCountThreshold, 10);
    const newPatternRange = parseInt(this.state.newPatternRange, 10);
    const dailyModelSpan = parseInt(this.state.dailyModelSpan, 10);
    const keywordFeatureNumber = parseInt(this.state.keywordFeatureNumber, 10);
    const maxLogModelSize = parseInt(this.state.maxLogModelSize, 10);
    const modelKeywordSetting = Number(this.state.modelKeywordSetting);
    const maximumThreads = parseInt(this.state.maximumThreads, 10);
    const logDetectionMinCount = parseInt(this.state.logDetectionMinCount, 10);
    const logDetectionSize = parseInt(this.state.logDetectionSize, 10);
    const maximumDetectionWaitTime = parseInt(this.state.maximumDetectionWaitTime, 10);
    const keywordSetting = parseInt(this.state.keywordSetting, 10);
    const rareEventBaseScore = parseFloat(this.state.rareEventBaseScore);
    const hotEventBaseScore = parseFloat(this.state.hotEventBaseScore);
    const coldEventBaseScore = parseFloat(this.state.coldEventBaseScore);
    const whitelistEventBaseScore = parseFloat(this.state.whitelistEventBaseScore);
    const newPatternEventBaseScore = parseFloat(this.state.newPatternEventBaseScore);
    const criticalEventBaseScore = parseFloat(this.state.criticalEventBaseScore);
    const whitelistNumberLimit = this.state.whitelistNumberLimit ? parseInt(this.state.whitelistNumberLimit, 10) : null;
    const newPatternNumberLimit = this.state.newPatternNumberLimit
      ? parseInt(this.state.newPatternNumberLimit, 10)
      : null;
    const incidentPredictionWindow = this.state.incidentPredictionWindow
      ? parseInt(this.state.incidentPredictionWindow, 10)
      : null;
    const minIncidentPredictionWindow = this.state.minIncidentPredictionWindow
      ? parseInt(this.state.minIncidentPredictionWindow, 10)
      : null;
    const incidentRelationSearchWindow = this.state.incidentRelationSearchWindow
      ? parseInt(this.state.incidentRelationSearchWindow, 10)
      : null;
    const incidentPredictionEventLimit = this.state.incidentPredictionEventLimit
      ? parseInt(this.state.incidentPredictionEventLimit, 10)
      : null;
    const predictionCountThreshold = this.state.predictionCountThreshold
      ? parseInt(this.state.predictionCountThreshold, 10)
      : null;
    const rootCauseCountThreshold = this.state.rootCauseCountThreshold
      ? parseInt(this.state.rootCauseCountThreshold, 10)
      : null;
    const compositeRCALimit = this.state.compositeRCALimit ? parseInt(this.state.compositeRCALimit, 10) : null;
    const rootCauseProbabilityThreshold = this.state.rootCauseProbabilityThreshold
      ? parseFloat(this.state.rootCauseProbabilityThreshold)
      : null;
    const rootCauseLogMessageSearchRange = this.state.rootCauseLogMessageSearchRange
      ? parseInt(this.state.rootCauseLogMessageSearchRange, 10)
      : null;

    const rareNumberLimit = this.state.rareNumberLimit ? parseInt(this.state.rareNumberLimit, 10) : 0;
    const hotNumberLimit = this.state.hotNumberLimit ? parseInt(this.state.hotNumberLimit, 10) : 0;
    const coldNumberLimit = this.state.coldNumberLimit ? parseInt(this.state.coldNumberLimit, 10) : 0;
    const coldEventThreshold = this.state.coldEventThreshold ? parseInt(this.state.coldEventThreshold, 10) : 0;
    const hotEventThreshold = this.state.hotEventThreshold ? parseInt(this.state.hotEventThreshold, 10) : 0;
    const hotEventDetectionMode = Number(this.state.hotEventDetectionMode);
    const causalPredictionCacheTimeout = this.state.causalPredictionCacheTimeout
      ? parseInt(this.state.causalPredictionCacheTimeout, 10)
      : null;
    const causalMinDelay = this.state.causalMinDelay ? parseInt(this.state.causalMinDelay, 10) : null;
    const maximumRootCauseResultSize = this.state.maximumRootCauseResultSize
      ? parseInt(this.state.maximumRootCauseResultSize, 10)
      : null;
    const multiHopSearchLevel = this.state.multiHopSearchLevel ? parseInt(this.state.multiHopSearchLevel, 10) : null;
    const multiHopSearchLimit = this.state.multiHopSearchLimit ? parseInt(this.state.multiHopSearchLimit, 10) : null;
    const avgPerIncidentDowntimeCost = this.state.avgPerIncidentDowntimeCost
      ? parseInt(this.state.avgPerIncidentDowntimeCost, 10)
      : null;
    const alertHourlyCost = this.state.alertHourlyCost ? parseInt(this.state.alertHourlyCost, 10) : null;
    const alertAverageTime = this.state.alertAverageTime ? parseInt(this.state.alertAverageTime, 10) : null;

    const requestData = {
      pValue,
      similaritySensitivity,
      featureOutlierSensitivity,
      rareEventAlertThresholds,
      incidentCountThreshold,
      newPatternRange,
      causalPredictionCacheTimeout: causalPredictionCacheTimeout * 60 * 1000,
      normalEventCausalFlag,
      dailyModelSpan,
      keywordFeatureNumber,
      maxLogModelSize,
      modelKeywordSetting,
      featureAnalysisFlag,
      projectModelFlag,
      isGroupingByInstance,
      nlpFlag,
      disableModelKeywordStatsCollection,
      whitelistNumberLimit,
      newPatternNumberLimit,
      incidentPredictionWindow: String(incidentPredictionWindow),
      minIncidentPredictionWindow: String(minIncidentPredictionWindow),
      incidentRelationSearchWindow,
      incidentPredictionEventLimit,
      predictionCountThreshold,
      rootCauseCountThreshold,
      // compositeRCALimit,
      rootCauseProbabilityThreshold: isNumber(rootCauseProbabilityThreshold) ? rootCauseProbabilityThreshold / 100 : '',
      rootCauseLogMessageSearchRange,
      collectAllRareEventsFlag,
      newAlertFlag,
      maximumThreads,
      logDetectionMinCount,
      logDetectionSize,
      maximumDetectionWaitTime,
      keywordSetting,
      logPatternLimitLevel,
      rareNumberLimit,
      rareAnomalyType: Number(rareAnomalyType),
      hotNumberLimit,
      coldNumberLimit,
      coldEventThreshold,
      hotEventThreshold,
      hotEventDetectionMode,
      ...(shouldConfigSamplingInterval ? { samplingInterval: (samplingInterval * samplingUnit) / 60 } : {}),
      incidentConsolidationInterval: incidentConsolidationInterval * 60000,
      incidentDurationThreshold: incidentDurationThreshold * 60000,
      logAnomalyEventBaseScore: JSON.stringify([
        rareEventBaseScore,
        hotEventBaseScore,
        coldEventBaseScore,
        whitelistEventBaseScore,
        newPatternEventBaseScore,
        criticalEventBaseScore,
      ]),
      causalPredictionSetting,
      rootCauseRankSetting,
      causalMinDelay: causalMinDelay * 60 * 1000,
      maximumRootCauseResultSize,
      multiHopSearchLevel,
      multiHopSearchLimit,
      avgPerIncidentDowntimeCost,
      alertHourlyCost,
      alertAverageTime: alertAverageTime * 60 * 1000,
      ignoreInstanceForKB,
      disableLogCompressEvent,
      anomalyDetectionMode,
      showInstanceDown,
      retentionTime,
      UBLRetentionTime,
      enableHotEvent,
    };
    if (entry === 'batch') {
      handleLogSensitivity(requestData);
      return;
    }

    const hasSetData = this.diffSensitivity(requestData);
    if (R.keys(hasSetData.newValMap).length < 1 || R.keys(hasSetData.oldValMap).length < 1) {
      return;
    }
    requestData.hasSetData = JSON.stringify(hasSetData);
    saveProjectSettings(projectName, requestData, { [this.submitLoadingKey]: true });
  }

  @autobind
  renderFieldItem(FormFieldItem, fieldNames = []) {
    const { entry } = this.props;
    const isBatch = R.equals(entry, 'batch');
    const initialValue = R.map(
      (n) => (R.isNil(this.localData[n]) ? this.localData[n] : this.localData[n].toString().toLowerCase()),
      fieldNames,
    );
    const currentValue = R.map(
      (n) => (R.isNil(this.state[n]) ? this.state[n] : this.state[n].toString().toLowerCase()),
      fieldNames,
    );
    return (
      <>
        {isBatch && (
          <div className="flex-row" style={{ alignItems: 'end' }}>
            <div style={{ height: 20, width: 28, margin: ' 0em 0em 1.5em' }}>
              {!R.equals(initialValue, currentValue) && <CheckOutlined style={{ fontSize: 16, color: '#f85042' }} />}
            </div>
            {FormFieldItem}
          </div>
        )}
        {!isBatch && FormFieldItem}
      </>
    );
  }

  render() {
    const { intl, projectName, tabStyle, entry, tabPosition } = this.props;
    const {
      samplingInterval,
      incidentConsolidationInterval,
      samplingUnit,
      incidentCountThreshold,
      incidentDurationThreshold,
    } = this.state;

    const similaritySensitivityLink = VLink.state(this, 'similaritySensitivity').check(
      (value) => Boolean(value),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const pvalueLink = VLink.state(this, 'pValue').check(
      (value) => Boolean(value),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const featureOutlierSensitivityLink = VLink.state(this, 'featureOutlierSensitivity').check(
      (value) => Boolean(value),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );

    const rareAlertThresholdsLink = VLink.state(this, 'rareEventAlertThresholds').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input an integer number',
    );
    const newPatternRangeLink = VLink.state(this, 'newPatternRange').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0),
      'Please input a positive integer number',
    );
    const rareEventBaseScoreLink = VLink.state(this, 'rareEventBaseScore').check(
      (value) => !value || (Boolean(value) && parseFloat(value) > 0),
      'Please input a positive float number',
    );
    const hotEventBaseScoreLink = VLink.state(this, 'hotEventBaseScore').check(
      (value) => !value || (Boolean(value) && parseFloat(value) > 0),
      'Please input a positive float number',
    );
    const coldEventBaseScoreLink = VLink.state(this, 'coldEventBaseScore').check(
      (value) => !value || (Boolean(value) && parseFloat(value) > 0),
      'Please input a positive float number',
    );
    const whitelistEventBaseScoreLink = VLink.state(this, 'whitelistEventBaseScore').check(
      (value) => !value || (Boolean(value) && parseFloat(value) > 0),
      'Please input a positive float number',
    );
    const whitelistNumberLimitLink = VLink.state(this, 'whitelistNumberLimit').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const newPatternEventBaseScoreLink = VLink.state(this, 'newPatternEventBaseScore').check(
      (value) => !value || (Boolean(value) && parseFloat(value) > 0),
      'Please input a positive float number',
    );
    const newPatternNumberLimitLink = VLink.state(this, 'newPatternNumberLimit').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const incidentPredictionWindowLink = VLink.state(this, 'incidentPredictionWindow').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const minIncidentPredictionWindowLink = VLink.state(this, 'minIncidentPredictionWindow').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const incidentRelationSearchWindowLink = VLink.state(this, 'incidentRelationSearchWindow').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const incidentPredictionEventLimitLink = VLink.state(this, 'incidentPredictionEventLimit').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const predictionCountThresholdLink = VLink.state(this, 'predictionCountThreshold').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const rootCauseCountThresholdLink = VLink.state(this, 'rootCauseCountThreshold').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const rootCauseProbabilityThresholdLink = VLink.state(this, 'rootCauseProbabilityThreshold').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0 && parseInt(value, 10) <= 100),
      'Please input a positive integer number',
    );
    const rootCauseLogMessageSearchRangeLink = VLink.state(this, 'rootCauseLogMessageSearchRange').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );

    const criticalEventBaseScoreLink = VLink.state(this, 'criticalEventBaseScore').check(
      (value) => !value || (Boolean(value) && parseFloat(value) >= 0),
      'Please input a positive float number',
    );

    const dailyModelSpanLink = VLink.state(this, 'dailyModelSpan').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const keywordFeatureNumberLink = VLink.state(this, 'keywordFeatureNumber').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0),
      'Please input a positive integer number',
    );
    const maxLogModelSizeLink = VLink.state(this, 'maxLogModelSize').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0),
      'Please input a positive integer number',
    );
    const modelKeywordSettingLink = VLink.state(this, 'modelKeywordSetting');

    const rareNumberLimitLink = VLink.state(this, 'rareNumberLimit').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const rareAnomalyTypeLink = VLink.state(this, 'rareAnomalyType').check(
      (x) => Boolean(x),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const hotNumberLimitLink = VLink.state(this, 'hotNumberLimit').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const coldNumberLimitLink = VLink.state(this, 'coldNumberLimit').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const coldEventThresholdLink = VLink.state(this, 'coldEventThreshold').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 1),
      'Please input a greater than 0 positive integer number',
    );
    const hotEventThresholdLink = VLink.state(this, 'hotEventThreshold').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 1),
      'Please input a greater than 0 positive integer number',
    );
    const hotEventDetectionModeLink = VLink.state(this, 'hotEventDetectionMode');

    const logDetectionMaxThreadsLink = VLink.state(this, 'maximumThreads').check(
      (value) => Boolean(value) && parseInt(value, 10) > 0,
      'Please input a positive integer number',
    );
    const logDetectionMinCountLink = VLink.state(this, 'logDetectionMinCount').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0),
      'Please input a positive integer number',
    );
    const causalPredictionCacheTimeoutLink = VLink.state(this, 'causalPredictionCacheTimeout').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const causalMinDelayLink = VLink.state(this, 'causalMinDelay').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const maximumRootCauseResultSizeLink = VLink.state(this, 'maximumRootCauseResultSize').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const multiHopSearchLevelLink = VLink.state(this, 'multiHopSearchLevel').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 2),
      'Please input a positive integer number',
    );
    const multiHopSearchLimitLink = VLink.state(this, 'multiHopSearchLimit').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const logDetectionSizeLink = VLink.state(this, 'logDetectionSize').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0),
      'Please input a positive integer number',
    );
    const maximumDetectionWaitTimeLink = VLink.state(this, 'maximumDetectionWaitTime').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0),
      'Please input a positive integer number',
    );
    const keywordSettingLink = VLink.state(this, 'keywordSetting');
    const logPatternLimitLevelLink = VLink.state(this, 'logPatternLimitLevel').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0),
      'Please input a positive integer number',
    );
    const predictionRelationsLink = VLink.state(this, 'causalPredictionSetting').check(
      (x) => Boolean(x),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const rootCauseRankLink = VLink.state(this, 'rootCauseRankSetting').check(
      (x) => Boolean(x),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const avgPerIncidentDowntimeCostLink = VLink.state(this, 'avgPerIncidentDowntimeCost').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const alertHourlyCostLink = VLink.state(this, 'alertHourlyCost').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const alertAverageTimeLink = VLink.state(this, 'alertAverageTime').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const retentionTimeLink = VLink.state(this, 'retentionTime').check((x) => {
      return (Number(x) >= 0 && isInteger(Number(x))) || null || '' || 0;
    }, 'Please input an integer number');
    const UBLRetentionTimeLink = VLink.state(this, 'UBLRetentionTime').check((x) => {
      return (Number(x) >= 0 && isInteger(Number(x))) || null || '' || 0;
    }, 'Please input an integer number');
    const hasError =
      similaritySensitivityLink.error ||
      pvalueLink.error ||
      featureOutlierSensitivityLink.error ||
      rareAlertThresholdsLink.error ||
      newPatternRangeLink.error ||
      causalPredictionCacheTimeoutLink.error ||
      causalMinDelayLink.error ||
      maximumRootCauseResultSizeLink.error ||
      multiHopSearchLevelLink.error ||
      multiHopSearchLimitLink.error ||
      rareEventBaseScoreLink.error ||
      hotEventBaseScoreLink.error ||
      coldEventBaseScoreLink.error ||
      whitelistEventBaseScoreLink.error ||
      whitelistNumberLimitLink.error ||
      newPatternEventBaseScoreLink.error ||
      newPatternNumberLimitLink.error ||
      incidentPredictionWindowLink.error ||
      minIncidentPredictionWindowLink.error ||
      incidentRelationSearchWindowLink.error ||
      incidentPredictionEventLimitLink.error ||
      predictionCountThresholdLink.error ||
      rootCauseCountThresholdLink.error ||
      rootCauseProbabilityThresholdLink.error ||
      rootCauseLogMessageSearchRangeLink.error ||
      dailyModelSpanLink.error ||
      keywordFeatureNumberLink.error ||
      maxLogModelSizeLink.error ||
      modelKeywordSettingLink.error ||
      criticalEventBaseScoreLink.error ||
      logDetectionMaxThreadsLink.error ||
      logDetectionMinCountLink.error ||
      maximumDetectionWaitTimeLink.error ||
      rareNumberLimitLink.error ||
      rareAnomalyTypeLink.error ||
      hotNumberLimitLink.error ||
      coldNumberLimitLink.error ||
      coldEventThresholdLink.error ||
      hotEventThresholdLink.error ||
      hotEventDetectionModeLink.error ||
      predictionRelationsLink.error ||
      rootCauseRankLink.error ||
      avgPerIncidentDowntimeCostLink.error ||
      alertHourlyCostLink.error ||
      alertAverageTimeLink.error;
    const isSubmitting = get(this.props.currentLoadingComponents, this.submitLoadingKey, false);
    return (
      <Container fullHeight className="flex-col flex-min-height">
        <div className="flex-grow overflow-y-auto overflow-x-hidden">
          <Tabs
            className="full-height ant-tabs-content-full-height tabs-content-padding-top"
            tabBarStyle={{ width: tabPosition === 'top' ? undefined : 250 }}
            tabPosition={tabPosition || 'left'}
            activeKey={this.state.activeKey}
            onChange={(activeKey) => this.setState({ activeKey })}
            style={tabStyle}
          >
            <Tabs.TabPane
              tab={intl.formatMessage(settingsMessages.logProcessing)}
              key="1"
              className="ui form overflow-y-auto"
            >
              <h3>{intl.formatMessage(settingsMessages.logTraining)}</h3>
              {entry === 'batch' &&
                this.renderFieldItem(
                  <div>
                    <label>
                      {intl.formatMessage(settingsMessages.similarityDegree)}
                      <Tooltip placement="top" title={intl.formatMessage(settingsMessages.similarityDegreeDesc)}>
                        <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                      </Tooltip>
                    </label>
                    <div className="select field required" style={{ width: 180 }}>
                      <SimilaritySensitivity intl={intl} valueLink={similaritySensitivityLink} />
                    </div>
                  </div>,
                  ['similaritySensitivity'],
                )}
              {entry === 'batch' &&
                this.renderFieldItem(
                  <div>
                    <label>
                      {intl.formatMessage(settingsMessages.sensitivity)}
                      <Tooltip placement="top" title={intl.formatMessage(settingsMessages.rareSensitivityDesc)}>
                        <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                      </Tooltip>
                    </label>
                    <div className="select field required" style={{ width: 180 }}>
                      <AnomalyThresholdSensitivity intl={intl} valueLink={pvalueLink} />
                    </div>
                  </div>,
                  ['pValue'],
                )}
              {entry === 'batch' &&
                this.renderFieldItem(
                  <div>
                    <label>{intl.formatMessage(settingsMessages.featureOutlierDetectionSensitivity)}</label>
                    <div className="select field required" style={{ width: 180 }}>
                      <FeatureOutlierSensitivity intl={intl} valueLink={featureOutlierSensitivityLink} />
                    </div>
                  </div>,
                  ['featureOutlierSensitivity'],
                )}
              {entry === 'batch' &&
                this.renderFieldItem(
                  <div
                    className="select field flex-row"
                    style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                  >
                    <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>
                      {intl.formatMessage(settingsMessages.holisticModel)}
                    </label>
                    <input
                      name="projectModelFlag"
                      type="checkbox"
                      checked={this.state.projectModelFlag}
                      onChange={this.handleInputChange}
                    />
                  </div>,
                  ['projectModelFlag'],
                )}
              {entry === 'batch' &&
                this.renderFieldItem(
                  <div
                    className="select field flex-row"
                    style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                  >
                    <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>
                      {intl.formatMessage(settingsMessages.groupingByInstance)}
                    </label>
                    <input
                      name="isGroupingByInstance"
                      type="checkbox"
                      checked={this.state.isGroupingByInstance}
                      onChange={this.handleInputChange}
                    />
                  </div>,
                  ['isGroupingByInstance'],
                )}
              {this.renderFieldItem(
                <div className="select field" style={{ width: 180 }}>
                  <label style={{ textTransform: 'none' }}>{intl.formatMessage(settingsMessages.modelSpan)}</label>
                  <Input
                    type="number"
                    min="0"
                    name="dailyModelSpan"
                    valueLink={dailyModelSpanLink}
                    style={{ width: 180 }}
                  />
                </div>,
                ['dailyModelSpan'],
              )}
              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.featureVectorLength)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input type="number" min="1" name="keywordFeatureNumber" valueLink={keywordFeatureNumberLink} />
                  </div>
                </div>,
                ['keywordFeatureNumber'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.maximumTrainingDataSample)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input type="number" min="1" name="maxLogModelSize" valueLink={maxLogModelSizeLink} />
                  </div>
                </div>,
                ['maxLogModelSize'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.modelKeywordSetting)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Select options={this.modelKeywordSettingOptions} valueLink={modelKeywordSettingLink} />
                  </div>
                </div>,
                ['modelKeywordSetting'],
              )}

              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>
                    {intl.formatMessage(settingsMessages.enableNLP)}
                  </label>
                  <input
                    name="nlpFlag"
                    type="checkbox"
                    checked={this.state.nlpFlag}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['nlpFlag'],
              )}

              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>
                    {intl.formatMessage(settingsMessages.disableModelKeywordStatsCollection)}
                  </label>
                  <input
                    name="disableModelKeywordStatsCollection"
                    type="checkbox"
                    checked={this.state.disableModelKeywordStatsCollection}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['disableModelKeywordStatsCollection'],
              )}

              {false &&
                this.renderFieldItem(
                  <div
                    className="select field flex-row"
                    style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                  >
                    <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>Enable feature training</label>
                    <input
                      name="featureAnalysisFlag"
                      type="checkbox"
                      checked={this.state.featureAnalysisFlag}
                      onChange={this.handleInputChange}
                    />
                  </div>,
                  ['featureAnalysisFlag'],
                )}

              <h3>{intl.formatMessage(settingsMessages.logDetection)}</h3>

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.maximumLogDetectionThread)}</label>
                  <div className="select field required">
                    <div style={{ width: 180 }}>
                      <Input
                        type="number"
                        min="0"
                        name="maximumThreads"
                        valueLink={logDetectionMaxThreadsLink}
                        style={{ width: 180 }}
                      />
                    </div>
                  </div>
                </div>,
                ['maximumThreads'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.minimumLogEntryValue)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="thresholds"
                      valueLink={logDetectionMinCountLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['logDetectionMinCount'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.maximumLogEntryValue)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="logDetectionSize"
                      valueLink={logDetectionSizeLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['logDetectionSize'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.maximumWaitTimeForTriggering)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="maximumDetectionWaitTime"
                      valueLink={maximumDetectionWaitTimeLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['maximumDetectionWaitTime'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.keywordSetting)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Select options={this.keywordSettingOptions} valueLink={keywordSettingLink} />
                  </div>
                </div>,
                ['keywordSetting'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.patternLimitSize)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.patternLimitSizeDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input type="number" min="1" name="logPatternLimitLevel" valueLink={logPatternLimitLevelLink} />
                  </div>
                </div>,
                ['logPatternLimitLevel'],
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={intl.formatMessage(appMenusMessages.globalSystemPrediction)}
              key="2"
              className="ui form overflow-y-auto"
            >
              <Alert
                message={null}
                description={intl.formatMessage(settingsMessages.incidentPredictionDesc, { projectName })}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.numberLimit)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="incidentPredictionEventLimit"
                      valueLink={incidentPredictionEventLimitLink}
                      style={{ width: 180 }}
                      icon="icon close"
                      iconSearch
                      iconSearchClick={() => incidentPredictionEventLimitLink.set('')}
                    />
                  </div>
                </div>,
                ['incidentPredictionEventLimit'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.maximumPredictionInterval)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="incidentPredictionWindow"
                      valueLink={incidentPredictionWindowLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['incidentPredictionWindow'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.minimumPredictionInterval)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="minIncidentPredictionWindow"
                      valueLink={minIncidentPredictionWindowLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['minIncidentPredictionWindow'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.incidentPredictionValidationWindow)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="incidentRelationSearchWindow"
                      valueLink={incidentRelationSearchWindowLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['incidentRelationSearchWindow'],
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={intl.formatMessage(settingsMessages.rootCauseAnalysisSetting)}
              key="3"
              className="ui form overflow-y-auto"
            >
              <Alert
                message={null}
                description={intl.formatMessage(settingsMessages.rootCauseAnalysisDesc, { projectName })}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
              {this.renderFieldItem(
                <div className="select field flex-row" style={{ justifyContent: 'start', alignItems: 'center' }}>
                  <label style={{ width: 260, textTransform: 'none' }}>
                    {intl.formatMessage(settingsMessages.includeNormalPattern)}
                    <Popover
                      title={null}
                      content={
                        <div style={{ maxWidth: 320 }}>{intl.formatMessage(settingsMessages.normalLogPatternDesc)}</div>
                      }
                      mouseEnterDelay={0.3}
                    >
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Popover>
                  </label>
                  <Switch
                    size="small"
                    name="normalEventCausalFlag"
                    checkedChildren={intl.formatMessage(appButtonsMessages.yes)}
                    unCheckedChildren={intl.formatMessage(appButtonsMessages.no)}
                    checked={this.state.normalEventCausalFlag}
                    onChange={(value) => this.setState({ normalEventCausalFlag: value })}
                  />
                </div>,
                ['normalEventCausalFlag'],
              )}
              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseNumberLimitIncident)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="maximumRootCauseResultSize"
                      valueLink={maximumRootCauseResultSizeLink}
                    />
                  </div>
                </div>,
                ['maximumRootCauseResultSize'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseCountThreshold)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="rootCauseCountThreshold"
                      valueLink={rootCauseCountThresholdLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['rootCauseCountThreshold'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseProbabilityThreshold)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="rootCauseProbabilityThreshold"
                      valueLink={rootCauseProbabilityThresholdLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['rootCauseProbabilityThreshold'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseLogSearchWindow)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="rootCauseLogMessageSearchRange"
                      valueLink={rootCauseLogMessageSearchRangeLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['rootCauseLogMessageSearchRange'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.IPAndRCARelationSelection)}</label>
                  <div className="select field required" style={{ width: 180 }}>
                    <Select valueLink={predictionRelationsLink} options={this.predictionRelationsOptions} />
                  </div>
                </div>,
                ['causalPredictionSetting'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.maximumRootCauseHop)}
                    <Popover
                      title={null}
                      content={
                        <div style={{ maxWidth: 320 }}>
                          {intl.formatMessage(settingsMessages.valueShouldLargeThan, { a: () => 1 })}
                        </div>
                      }
                      mouseEnterDelay={0.3}
                    >
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Popover>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="2"
                      name="multiHopSearchLevel"
                      valueLink={multiHopSearchLevelLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['multiHopSearchLevel'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseRank)}</label>
                  <div className="select field required" style={{ width: 180 }}>
                    <Select valueLink={rootCauseRankLink} options={this.rootCauseRankOptions} />
                  </div>
                </div>,
                ['rootCauseRankSetting'],
              )}

              {/* {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.compositeRCALimit)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <InputNumber
                      type="number"
                      style={{ width: '100%' }}
                      min={0}
                      precision={0}
                      value={this.state.compositeRCALimit}
                      onChange={(value) => this.setState({ compositeRCALimit: value || 1 })}
                    />
                  </div>
                </div>,
                ['compositeRCALimit'],
              )} */}
            </Tabs.TabPane>
            {false && (
              <Tabs.TabPane
                tab={intl.formatMessage(settingsMessages.knowledgeBase)}
                key="5"
                className="ui form overflow-y-auto"
              >
                <Alert
                  message={null}
                  description={intl.formatMessage(settingsMessages.knowledgeBaseDesc, { projectName })}
                  type="info"
                  showIcon
                  style={{ marginBottom: 16 }}
                />

                {this.renderFieldItem(
                  <div
                    className="select field flex-row"
                    style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                  >
                    <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>
                      {intl.formatMessage(settingsMessages.ignoreInstanceForKB)}
                    </label>
                    <input
                      name="ignoreInstanceForKB"
                      type="checkbox"
                      checked={this.state.ignoreInstanceForKB}
                      onChange={this.handleInputChange}
                    />
                  </div>,
                  ['ignoreInstanceForKB'],
                )}
              </Tabs.TabPane>
            )}

            <Tabs.TabPane
              tab={intl.formatMessage(settingsMessages.logAnomalyDetection)}
              key="10"
              className="ui form overflow-y-auto"
            >
              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>
                    {intl.formatMessage(settingsMessages.disableLogCompressEvent)}
                  </label>
                  <input
                    name="disableLogCompressEvent"
                    type="checkbox"
                    checked={this.state.disableLogCompressEvent}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['disableLogCompressEvent'],
              )}

              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>Enable anomaly detection</label>
                  <input
                    name="anomalyDetectionMode"
                    type="checkbox"
                    checked={this.state.anomalyDetectionMode === 0}
                    onChange={({ target: { checked } }) => {
                      this.setState({ anomalyDetectionMode: checked ? 0 : -1 });
                    }}
                  />
                </div>,
                ['anomalyDetectionMode'],
              )}

              <h3>{intl.formatMessage(settingsMessages.incidentEventDetection)}</h3>
              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.incidentConsolidationInterval)}</label>
                  <div className="flex-row select field required" style={{ alignItems: 'center' }}>
                    <InputNumber
                      min={0}
                      style={{ width: 180 }}
                      value={incidentConsolidationInterval}
                      onChange={(incidentConsolidationInterval) =>
                        this.setState({ incidentConsolidationInterval: incidentConsolidationInterval || 0 })
                      }
                    />
                  </div>
                </div>,
                ['incidentConsolidationInterval'],
              )}
              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.incidentCountThreshold)}</label>
                  <div className="flex-row select field required" style={{ alignItems: 'center' }}>
                    <InputNumber
                      min={0}
                      style={{ width: 180 }}
                      value={incidentCountThreshold}
                      onChange={(incidentCountThreshold) =>
                        this.setState({ incidentCountThreshold: incidentCountThreshold || 0 })
                      }
                    />
                  </div>
                </div>,
                ['incidentCountThreshold'],
              )}
              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.incidentDurationThreshold)}</label>
                  <div className="flex-row select field required" style={{ alignItems: 'center' }}>
                    <InputNumber
                      min={0}
                      style={{ width: 180 }}
                      value={incidentDurationThreshold}
                      onChange={(incidentDurationThreshold) =>
                        this.setState({ incidentDurationThreshold: incidentDurationThreshold || 0 })
                      }
                    />
                  </div>
                </div>,
                ['incidentDurationThreshold'],
              )}

              <h3>{intl.formatMessage(settingsMessages.rareEventDetection)}</h3>
              <p>{intl.formatMessage(settingsMessages.rareEventDetectionDesc)}</p>

              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>
                    {intl.formatMessage(settingsMessages.enableAllRareEventDetection)}
                  </label>
                  <input
                    name="collectAllRareEventsFlag"
                    type="checkbox"
                    checked={this.state.collectAllRareEventsFlag}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['collectAllRareEventsFlag'],
              )}

              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>
                    {intl.formatMessage(settingsMessages.missingDataIncidentEscalation)}
                  </label>
                  <input
                    name="showInstanceDown"
                    type="checkbox"
                    checked={this.state.showInstanceDown}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['showInstanceDown'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.rareEventClusterSize)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.rareEventClusterSizeDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="rareEventAlertThresholds"
                      valueLink={rareAlertThresholdsLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['rareEventAlertThresholds'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.rareEventBaseScore)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.rareEventBaseScoreDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="rareEventBaseScore"
                      valueLink={rareEventBaseScoreLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['rareEventBaseScore'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.rareEventNumberLimit)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.rareEventNumberLimitDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="rareNumberLimit"
                      valueLink={rareNumberLimitLink}
                      style={{ width: 180 }}
                      icon="icon close"
                      iconSearch
                      iconSearchClick={() => rareNumberLimitLink.set('')}
                    />
                  </div>
                </div>,
                ['rareNumberLimit'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rareAnomalyType)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Select options={this.rareAnomalyTypeOptions} valueLink={rareAnomalyTypeLink} />
                  </div>
                </div>,
                ['rareAnomalyType'],
              )}

              <h3>{intl.formatMessage(settingsMessages.whitelistEventDetection)}</h3>

              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14, textTransform: 'none' }}>Enable new pattern only</label>
                  <input
                    name="newAlertFlag"
                    type="checkbox"
                    checked={this.state.newAlertFlag}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['newAlertFlag'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.baseScore)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.baseScoreDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="whitelistEventBaseScore"
                      valueLink={whitelistEventBaseScoreLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['whitelistEventBaseScore'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.numberLimit)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.numberLimitDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="whitelistNumberLimit"
                      valueLink={whitelistNumberLimitLink}
                      style={{ width: 180 }}
                      icon="icon close"
                      iconSearch
                      iconSearchClick={() => whitelistNumberLimitLink.set('')}
                    />
                  </div>
                </div>,
                ['whitelistNumberLimit'],
              )}

              <h3>{intl.formatMessage(settingsMessages.newPatternDetection)}</h3>

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.baseScore)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.baseScoreDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="newPatternEventBaseScore"
                      valueLink={newPatternEventBaseScoreLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['newPatternEventBaseScore'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.numberLimit)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.numberLimitDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="newPatternNumberLimit"
                      valueLink={newPatternNumberLimitLink}
                      style={{ width: 180 }}
                      icon="icon close"
                      iconSearch
                      iconSearchClick={() => newPatternNumberLimitLink.set('')}
                    />
                  </div>
                </div>,
                ['newPatternNumberLimit'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.newPatternDetectionPeriod)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.newPatternDetectionPeriodDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="newPatternRange"
                      valueLink={newPatternRangeLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['newPatternRange'],
              )}

              <h3>{intl.formatMessage(settingsMessages.criticalEventDetection)}</h3>
              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.baseScore)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.baseScoreDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 14, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="criticalEventBaseScore"
                      valueLink={criticalEventBaseScoreLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['criticalEventBaseScore'],
              )}
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={intl.formatMessage(settingsMessages.hotColdEventDetection)}
              key="11"
              className="ui form overflow-y-auto"
            >
              <Alert
                message={null}
                description={intl.formatMessage(settingsMessages.hotColdEventDetectionDesc)}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14 }}>
                    {intl.formatMessage(settingsMessages.enableHotEvent)}
                  </label>
                  <input
                    name="enableHotEvent"
                    type="checkbox"
                    checked={this.state.enableHotEvent}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['enableHotEvent'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.hotEventUnitScore)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.baseScoreDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="hotEventBaseScore"
                      valueLink={hotEventBaseScoreLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['hotEventBaseScore'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.coldEventUnitScore)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.baseScoreDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="coldEventBaseScore"
                      valueLink={coldEventBaseScoreLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['coldEventBaseScore'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.hotEventNumberLimit)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.hotEventNumberLimitDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="hotNumberLimit"
                      valueLink={hotNumberLimitLink}
                      style={{ width: 180 }}
                      icon="icon close"
                      iconSearch
                      iconSearchClick={() => hotNumberLimitLink.set('')}
                    />
                  </div>
                </div>,
                ['hotNumberLimit'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.coldEventNumberLimit)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.coldEventNumberLimitDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="coldNumberLimit"
                      valueLink={coldNumberLimitLink}
                      style={{ width: 180 }}
                      icon="icon close"
                      iconSearch
                      iconSearchClick={() => coldNumberLimitLink.set('')}
                    />
                  </div>
                </div>,
                ['coldNumberLimit'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.hotEventThreshold)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.hotEventThresholdDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="hotEventThreshold"
                      valueLink={hotEventThresholdLink}
                      style={{ width: 180 }}
                      icon="icon close"
                      iconSearch
                      iconSearchClick={() => hotEventThresholdLink.set('')}
                    />
                  </div>
                </div>,
                ['hotEventThreshold'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.coldEventThreshold)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.coldEventThresholdDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="coldEventThreshold"
                      valueLink={coldEventThresholdLink}
                      style={{ width: 180 }}
                      icon="icon close"
                      iconSearch
                      iconSearchClick={() => coldEventThresholdLink.set('')}
                    />
                  </div>
                </div>,
                ['coldEventThreshold'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.hotSamplingInterval)}</label>
                  <div className="flex-row select field required" style={{ alignItems: 'center' }}>
                    <InputNumber
                      min={1}
                      style={{ width: 90 }}
                      value={samplingInterval}
                      onChange={(samplingInterval) => this.setState({ samplingInterval })}
                      disabled
                    />
                    <AntdSelect
                      style={{ width: 80, marginLeft: 10 }}
                      options={this.samplingUnitOption}
                      value={samplingUnit}
                      onChange={(samplingUnit) => this.setState({ samplingUnit })}
                      disabled
                    />
                  </div>
                </div>,
                ['samplingUnit', 'samplingInterval'],
              )}
              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.hotEventDetectionMode)}</label>
                  <div className="select field " style={{ width: 180 }}>
                    <Select valueLink={hotEventDetectionModeLink} options={this.hotEventDetectionModeOption} />
                  </div>
                </div>,
                ['hotEventDetectionMode'],
              )}
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={intl.formatMessage(settingsMessages.laborCost)}
              key="13"
              className="ui form overflow-y-auto"
            >
              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.averageIncidentDowntimeCost)}</label>
                  <div className="select field">
                    <div style={{ width: 180 }}>
                      <Input
                        type="number"
                        min="0"
                        name="avgPerIncidentDowntimeCost"
                        valueLink={avgPerIncidentDowntimeCostLink}
                      />
                    </div>
                  </div>
                </div>,
                ['avgPerIncidentDowntimeCost'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.hourlyLaborCost)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input type="number" min="0" name="alertHourlyCost" valueLink={alertHourlyCostLink} />
                  </div>
                </div>,
                ['alertHourlyCost'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.averageTriageTime)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input type="number" min="0" name="alertAverageTime" valueLink={alertAverageTimeLink} />
                  </div>
                </div>,
                ['alertAverageTime'],
              )}
            </Tabs.TabPane>
            {entry === 'batch' && (
              <Tabs.TabPane
                tab={intl.formatMessage(settingsMessages.retention)}
                key="14"
                className="ui form overflow-y-auto"
              >
                {this.renderFieldItem(
                  <div>
                    <label>
                      {`${intl.formatMessage(settingsMessages.dataRetention)} (${intl.formatMessage(
                        appFieldsMessages.day,
                      )})`}
                    </label>
                    <div className="select field">
                      <div style={{ width: 180 }}>
                        <Input type="number" min="0" name="retentionTime" valueLink={retentionTimeLink} />
                      </div>
                    </div>
                  </div>,
                  ['retentionTime'],
                )}
                {this.renderFieldItem(
                  <div>
                    <label>
                      {`${intl.formatMessage(settingsMessages.modelRetention)} (${intl.formatMessage(
                        appFieldsMessages.day,
                      )})`}
                    </label>
                    <div className="select field">
                      <div style={{ width: 180 }}>
                        <Input type="number" min="0" name="UBLRetentionTime" valueLink={UBLRetentionTimeLink} />
                      </div>
                    </div>
                  </div>,
                  ['UBLRetentionTime'],
                )}
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>

        {entry !== 'batch' && (
          <div className="flex-row flex-end-justify" style={{ marginTop: 16 }}>
            <Button type="primary" loading={isSubmitting} disabled={hasError} onClick={this.handleSaveClick}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </div>
        )}
      </Container>
    );
  }
}

const LogSensitivitySetting = injectIntl(LogSensitivitySettingCore);
export default connect(
  (state) => {
    return {};
  },
  { push, replace, showAppAlert },
)(LogSensitivitySetting);
