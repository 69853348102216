/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get, isObject } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import update from 'immutability-helper';
import { autobind } from 'core-decorators';
import { CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from 'react-virtualized';
import { Menu, Spin } from 'antd';
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DoubleLeftOutlined,
} from '@ant-design/icons';

import { Container, Tooltip, Table, Column, AutoSizer, Popover, Dropdown } from '../../../lib/fui/react';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { sortByString, sleep, Defaults, CellRenderers } from '../../../common/utils';
import { DataChart } from '../../../../components/share/charts';

import { eventMessages } from '../../../common/metric/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import TimeSelectModal from '../../metric/components/TimeSelectModal';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';

type Props = {
  intl: Object,
  width: Number,
  height: Number,
  queryResult: Object,
  queryParams: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  updateLastActionInfo: Function,
  metadata: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  credentials: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  projects: Array<Object>,
  // eslint-disable-next-line react/no-unused-prop-types
  isAdmin: Boolean,
  currentTheme: String,
  allInstances: Object,
};

class LogKeywordEvent extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.contentWidth = 900;
    this.gridOffsetY = 40;
    this.interval = 10 * 60 * 1000;
    this.logFrequencyMap = {};

    this.barRender = ({ barData, annotations }) => {
      return (
        <DataChart
          style={{ height: 120, marginTop: 40, marginLeft: -30 }}
          isLogCharts
          chartType="bar"
          data={barData}
          // enableAnnotations
          annotations={annotations}
          defaultBarColor="rgb(242, 113, 28)"
          onClick={this.handlePatternPointClick}
        />
      );
    };

    const { queryParams } = props;
    this.keywords = [];
    this.state = {
      instanceName: this.getQueryInstanceName(queryParams?.instanceName) || 'All Instance',
      barExpend: true,
      isLoading: false,
      totalEntry: 0,
      eventList: [],
      pageNo: 1,
      pageSize: 200,
      summarySettings: [],

      allExpand: true,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
      contextTime: 60 * 1000,
      contextKeywordFilter: '',
    };

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
  }

  async componentDidMount() {
    const { queryResult, queryParams } = this.props;
    this.reloadData(queryResult, queryParams, this.state);
    const summarySettings = await this.getLogsummarysettings(queryParams.projectName);
    this.setState({ summarySettings });
    if (this.selectedStartTs) {
      this.reloadEvents(this.props, this.state, this.selectedStartTs, true);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { queryResult, queryParams } = nextProps;
    if (this.props.queryResult !== nextProps.queryResult || this.state.instanceName !== nextState.instanceName) {
      this.reloadData(queryResult, queryParams, nextState);
      if (this.selectedStartTs) {
        this.reloadEvents(nextProps, nextState, this.selectedStartTs, true);
      }
    }
  }

  @autobind
  getQueryInstanceName(queryInstanceName) {
    let instanceName = queryInstanceName;
    if (R.startsWith('[', instanceName)) {
      instanceName = JSON.parse(instanceName)[0];
    } else if (R.includes(',', instanceName)) {
      instanceName = R.split(',', instanceName)[0];
    }
    return instanceName;
  }

  @autobind
  getLogsummarysettings(projectName) {
    const { credentials } = this.props;
    return new Promise((resolve, reject) => {
      fetchGet(getEndpoint('logsummarysettings'), {
        ...credentials,
        projectName,
      })
        .then((summarySettings) => {
          resolve(summarySettings);
        })
        .catch((err) => {
          resolve([]);
        });
    });
  }

  @autobind
  reloadData(queryResult, queryParams, state) {
    // get barchart data
    this.logFrequencyMap = {};
    if (state.instanceName && state.instanceName !== 'All Instance') {
      this.logFrequencyMap = get(queryResult, ['data', state.instanceName], {});
    } else {
      this.logFrequencyMap = this.props.allInstances || {};
    }

    const barData = this.getBarData(queryParams, this.logFrequencyMap);
    this.barData = barData;

    // get keywords
    const { keyword } = queryParams || {};
    this.keywords = R.map(
      (k) => R.trim(R.replace(/"/g, '', k)),
      R.split(' ', R.replace(/AND/g, ' ', R.replace(/OR/g, ' ', keyword || ''))),
    );
    this.keywords = R.filter((k) => Boolean(k), this.keywords);
  }

  @autobind
  getBarData(queryParams, logFrequencyMap) {
    const { startTimeObj, endTimeObj } = queryParams;
    const startT = startTimeObj.valueOf();
    const endT = endTimeObj.valueOf();
    if (!startT || !endT) {
      return undefined;
    }

    const xstart = new Date(startT);
    const xend = new Date(endT);

    let logPeriods = R.map(
      (l) => {
        return [new Date(Number(l[0])), l[1]];
      },
      R.sortBy(
        R.prop(0),
        R.map((l) => [Number(l[0]), l[1]], R.toPairs(logFrequencyMap)),
      ),
    );

    if (logPeriods.length > 0) {
      this.selectedStartTs = logPeriods[0][0].getTime();
    }

    // Append start & end if missing
    if (logPeriods.length > 0) {
      if (logPeriods[0][0].valueOf() > startT) {
        logPeriods = [[xstart, null], ...logPeriods];
      }
      if (logPeriods[logPeriods.length - 1][0].valueOf() < endT) {
        logPeriods = [...logPeriods, [xend, null]];
      }
    } else {
      logPeriods = [
        [xstart, null],
        [xend, null],
      ];
    }

    const data = { sdata: logPeriods, sname: ['Datetime', 'Events Count'] };
    return data;
  }

  @autobind
  handlePatternPointClick(startTs, position) {
    this.selectedStartTs = startTs;
    this.setState({ pageNo: 1 }, () => {
      this.reloadEvents(this.props, { ...this.state, pageNo: 1 }, startTs, true);
    });
  }

  @autobind
  reloadEvents(props, state, startTs, force) {
    const { queryParams, metadata, credentials, projects, intl } = props;
    const { instanceName, pageSize, allExpand, eventList: prevEventList } = state;
    let { pageNo } = state;
    const { projectName, keyword } = queryParams;
    const sessionKey = get(metadata, ['sessionKey'], null);
    const project = R.find((p) => p.projectName === projectName || p.projectShortName === projectName, projects);
    const projectLinkInfo = get(project, 'projectLinkInfo', []);

    const sdata = get(this.barData, ['sdata'], []);
    const selectBarData = R.find((data) => data[0].valueOf() === startTs, sdata);
    const count = selectBarData ? selectBarData[1] : 0;
    pageNo = force ? 1 : pageNo + 1;

    this.setState({ isLoading: true, pageNo }, () => {
      if (projectName && startTs && sessionKey) {
        props.updateLastActionInfo();
        fetchGet(getEndpoint('query/histogramquery'), {
          ...credentials,
          projectName,
          sessionKey,
          timestamp: startTs,
          instanceName: instanceName === 'All Instance' ? undefined : instanceName,
          keyword,
          pageNo,
          pageSize,
          count,
        })
          .then((d) => {
            let eventList = [];
            let totalEntry = 0;
            R.forEach((instanceData) => {
              const { totalMatched, data } = instanceData;
              totalEntry += totalMatched || 0;
              eventList = [...eventList, ...R.map((e) => ({ ...e, projectName, rawData: e.data }), data)];
            }, d || []);

            eventList = R.map((item) => {
              const { rawData, nid, instanceName } = item;
              let rawDataJson;
              try {
                rawDataJson = JSON.parse(rawData);
                if (!isObject(rawDataJson)) rawDataJson = undefined;
              } catch (error) {
                // console.debug(error);
              }
              return {
                ...item,
                rawDataJson,
                patternId: nid,
                isExpand: allExpand,
                __expanded_state__: allExpand,
                __content_expand_state: allExpand,
                value: instanceName,
              };
            }, eventList);

            if (projectLinkInfo.length > 0) {
              eventList = R.map((e) => {
                return { ...e, projectLinkInfo };
              }, eventList);
            }

            let newEventList = force ? eventList : [...prevEventList, ...eventList];
            newEventList = R.sort((a, b) => a.timestamp - b.timestamp, newEventList);
            this.setState(
              {
                totalEntry,
                eventList: newEventList,
                isLoading: false,
              },
              () => {
                this.cellMeasureCache.clearAll();
                if (this.listNode) this.listNode.forceUpdateGrid();
              },
            );
          })
          .catch((err) => {
            this.setState({ isLoading: false });
          });
      }
    });
  }

  @autobind
  handlePageChanged(pageNo, pageSize) {
    this.setState({ pageNo, pageSize }, () => {
      this.reloadEvents(this.props, { ...this.state, pageNo, pageSize }, this.selectedStartTs, true);
    });
  }

  @autobind
  handleInstanceChange(instanceName) {
    this.setState({ instanceName });
  }

  @autobind
  renderIncidentName({ cellData, rowData }) {
    const { value } = rowData;
    return (
      <Popover title={null} content={value}>
        <div
          className="hidden-line-with-ellipsis clickable"
          style={{ fontSize: value === 'All Instance' ? 16 : 12, fontWeight: value === 'All Instance' ? 600 : 500 }}
        >
          {value}
        </div>
      </Popover>
    );
  }

  @autobind
  renderCount({ cellData, rowData }) {
    const { queryResult, allInstances } = this.props;
    const data = get(queryResult, ['data'], {});
    const { value } = rowData;
    let count = 0;
    if (value !== 'All Instance') {
      count = R.sum(R.values(data[value]));
    } else {
      count = R.sum(R.values(allInstances || {}));
    }
    return (
      <div style={{ fontSize: value === 'All Instance' ? 16 : 12, fontWeight: value === 'All Instance' ? 600 : 500 }}>
        {count}
      </div>
    );
  }

  @autobind
  logPatternIdRenderer(rowData) {
    const { nid } = rowData || {};
    const { patternIdStr: msg } = Defaults.PatternIdNameStr({ patternId: nid }, {});
    return (
      <div className="flex-row" style={{ height: '40px', alignItems: 'center' }}>
        <div className="flex-grow flex-min-width flex-row">
          <Tooltip placement="top" mouseEnterDelay={0.3} title={msg}>
            <span
              style={{
                color: 'gray',
                lineHeight: '14px',
                height: 14,
                minWidth: 10,
                textAlign: 'center',
                border: `1px solid gray`,
                borderRadius: '20%',
                padding: '0 2px',
                cursor: 'default',
                display: 'inline-block',
              }}
            >
              {nid}
            </span>
          </Tooltip>
        </div>
      </div>
    );
  }

  @autobind
  async handleIncidentClick(rowData) {
    await sleep(300);
    this.setState({ pageNo: 1 }, () => {
      this.handleInstanceChange(rowData.value);
    });
  }

  @autobind
  renderListItem(events) {
    return (props: Object) => {
      const { key, index: rowIndex, style, parent } = props;
      const rowData = events[rowIndex];
      if (!rowData) return null;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style, minHeight: 40 }}>
            <div className="row-column" style={{ width: 110 }}>
              {this.renderTime(rowData)}
            </div>
            <div className="row-column" style={{ width: 150 }}>
              {this.renderIncidentName({ rowData })}
            </div>
            <div className="row-column" style={{ width: 60 }}>
              {this.renderType(rowData)}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {this.logPatternIdRenderer(rowData)}
            </div>
            <div className="row-column" style={{ width: 100, flex: 1 }}>
              {this.renderLogEntry({ ...props, rowData, dataKey: key, rowIndex })}
            </div>
            <div className="row-column" style={{ width: 120 }}>
              {this.renderControl(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderTime(rowData) {
    const { timestamp } = rowData;
    return <div>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</div>;
  }

  @autobind
  renderType(rowData) {
    const { intl } = this.props;
    const { eventType } = rowData;

    return (
      <div className="flex-row flex-wrap full-width">
        {CellRenderers.logShortTypeRenderer({ intl, type: eventType })}
      </div>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { patternName, patternId } = rowData;
    const { patternNameStr } = Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true });
    return (
      <Popover mouseEnterDelay={0.3} title={null} content={patternNameStr}>
        <div className="hidden-line-with-ellipsis inline-block max-width">{patternNameStr}</div>
      </Popover>
    );
  }

  @autobind
  onToggleCollapse() {
    this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
  }

  @autobind
  handleAllExpand() {
    const { allExpand, eventList } = this.state;
    this.setState(
      {
        allExpand: !allExpand,
        // eslint-disable-next-line camelcase
        eventList: R.map(
          (item) => ({
            ...item,
            isExpand: !allExpand,
            __expanded_state__: !allExpand,
            __content_expand_state: !allExpand,
          }),
          eventList,
        ),
      },
      () => {
        window.setTimeout(() => {
          this.cellMeasureCache.clearAll();
          if (this.listNode) {
            this.listNode.forceUpdateGrid();
          }
        }, 50);
      },
    );
  }

  @autobind
  renderLogEntry(props) {
    const { intl, currentTheme } = this.props;
    const { summarySettings, eventList } = this.state;
    const { rowData } = props;
    props.cellData = rowData.rawData;
    props.style = {};
    return (
      <div style={{ width: '100%' }}>
        {CellRenderers.logContent(props, this.cellMeasureCache, {
          width: '90%',
          highlightWord: this.keywords,
          isCritical: false,
          onChanged: this.onToggleCollapse,
          showRawDataFrequency: false,
          frequencyStr: '',
          allParsedEventList: eventList,
          intl,
          summarySettings: summarySettings.length > 0 ? summarySettings : [],
          enableJsonSummary: true,
          currentTheme,
          isCacheColumn: true,
          isKeywordQuery: true,
        })}
      </div>
    );
  }

  @autobind
  handleExpandRow(rowIndex) {
    return (isExpand) => {
      const { eventList } = this.state;
      this.setState(
        {
          eventList: update(eventList, {
            [rowIndex]: { $set: { ...(eventList[rowIndex] || {}), isExpand } },
          }),
        },
        () => {
          this.cellMeasureCache.clear(rowIndex);
          if (this.listNode) this.listNode.forceUpdateGrid();
        },
      );
    };
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;
    const { eventType } = rowData;

    const handleMenuClick = ({ key }) => {
      switch (key) {
        case 'context':
          this.handleLogContextOneMinClick(rowData);
          break;
        default:
          break;
      }
    };

    return (
      <div className="flex-row flex-end-justify">
        <Dropdown name={intl.formatMessage(logMessages.investigate)} itemClick={handleMenuClick}>
          <Menu.Item key="context">{intl.formatMessage(eventMessages.context)}</Menu.Item>
        </Dropdown>
      </div>
    );
  }

  @autobind
  handleLogContextOneMinClick(rowData) {
    const { instanceName, timestamp, startTime, endTime } = rowData;
    this.setState({
      showTimeSelectModal: true,
      selectInstance: instanceName,
      selectStartTimestamp: startTime ? startTime - 60 * 1000 : timestamp - 60 * 1000,
      selectEndTimestamp: endTime ? endTime + 60 * 1000 : timestamp + 60 * 1000,
      activeIncident: rowData,
    });
  }

  @autobind onCloseTimeSelect(props) {
    const { projectName, instanceName, startTimestamp, endTimestamp, keywordFilter } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState({
        showTimeSelectModal: false,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp: startTimestamp,
        selectEndTimestamp: endTimestamp,
        showContextModal: true,
        contextKeywordFilter: keywordFilter,
        contextTime: 60 * 1000,
      });
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('timestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);
    return sortList;
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const { eventList } = this.state;
          const sortList = this.sortData(eventList, name, sortDir);
          this.setState({ eventList: sortList });
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  isRowLoaded({ index }) {
    const { eventList } = this.state;
    return eventList && eventList.length > index;
  }

  render() {
    const { width, height, queryResult, metadata, intl, queryParams } = this.props;
    const instanceNameListOptions = R.map((i) => {
      return { label: i, value: i };
    }, sortByString(R.keys(queryResult.data)));

    const {
      barExpend,
      totalEntry,
      isLoading,
      instanceName,
      eventList,
      sortBy,
      sortDirection,
      allExpand,
      selectStartTimestamp,
      selectEndTimestamp,
      contextTime,
      contextKeywordFilter,
    } = this.state;
    const { barData } = this;
    const title = get(metadata, ['title'], null);

    let tableInstanceNameList = instanceNameListOptions;
    if (!queryParams?.instanceName) {
      tableInstanceNameList = [{ lable: 'All Instance', value: 'All Instance' }, ...tableInstanceNameList];
    }
    return (
      <Container style={{ width, height }}>
        <Container className="toolbar" style={{ zIndex: 299 }}>
          <Container className="section">
            <h4>
              {title || 'Rare Event'}
              <span style={{ fontSize: 14, paddingLeft: 28, fontWeight: 'normal' }}>{`Total: ${R.sum(
                R.values(this.logFrequencyMap),
              )}`}</span>
            </h4>
          </Container>
        </Container>

        <Container className="flex-row">
          <Tooltip title={`Click to ${barExpend ? 'hide' : 'show'} bar chart`} placement="top">
            <div
              style={{ cursor: 'pointer', marginTop: -5 }}
              onClick={() => {
                this.setState({ barExpend: !barExpend });
              }}
            >
              {barExpend && <ArrowDownOutlined style={{ fontSize: 14 }} />}
              {!barExpend && <ArrowRightOutlined style={{ fontSize: 14 }} />}
              {`Click to ${barExpend ? 'hide' : 'show'} bar chart`}
            </div>
          </Tooltip>
        </Container>

        <Container className="flex-row">
          <div style={{ width: 270, flexShrink: 0, margin: '10px 10px 0 0' }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  ref={(c) => {
                    this.dataTable = c;
                  }}
                  width={width}
                  height={height}
                  headerHeight={30}
                  rowHeight={40}
                  rowCount={tableInstanceNameList.length}
                  rowGetter={({ index }) => tableInstanceNameList[index]}
                  onRowClick={({ rowData }) => {
                    this.handleIncidentClick(rowData);
                  }}
                  rowClassName={({ index }) => {
                    let className = 'clickable';
                    className += index >= 0 && index % 2 === 1 ? ' odd-row' : '';
                    // Ignore header row.
                    if (index >= 0) {
                      if (tableInstanceNameList[index].value === instanceName) {
                        className += ' active';
                      }
                    }
                    return className;
                  }}
                >
                  <Column
                    width={100}
                    flexGrow={1}
                    label={intl.formatMessage(eventMessages.incident)}
                    dataKey="value"
                    cellRenderer={this.renderIncidentName}
                  />
                  <Column
                    width={80}
                    label={intl.formatMessage(appFieldsMessages.count)}
                    dataKey="count"
                    cellRenderer={this.renderCount}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
          <div>
            {barExpend && (
              <Container style={{ width: width - 280 }} className="flex-col">
                {barData && this.barRender({ barData, annotations: [] })}
              </Container>
            )}
            <Container
              className="flex-row"
              style={{
                width: width - 280,
                height: height - 45 - (barExpend ? 160 : 0),
                overflowY: 'auto',
                marginTop: 20,
              }}
            >
              <Spin spinning={isLoading} wrapperClassName="spin-base">
                <InfiniteLoader
                  isRowLoaded={this.isRowLoaded}
                  rowCount={totalEntry}
                  loadMoreRows={() => this.reloadEvents(this.props, this.state, this.selectedStartTs, false)}
                  threshold={3}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ width, height }) => (
                        <div className="event-list">
                          <div
                            className="event-list-header"
                            style={{
                              height: this.listHeaderHeight,
                              width,
                              paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                            }}
                          >
                            <div
                              className="header-column"
                              style={{ width: 110 }}
                              onClick={this.headerClick('timestamp')}
                            >
                              <span>{intl.formatMessage(logMessages.dateTime)}</span>
                              {this.sortIcon(sortBy, sortDirection, 'timestamp')}
                            </div>
                            <div
                              className="header-column"
                              style={{ width: 150 }}
                              onClick={this.headerClick('instanceName')}
                            >
                              <span>{intl.formatMessage(appFieldsMessages.instanceName)}</span>
                              {this.sortIcon(sortBy, sortDirection, 'instanceName')}
                            </div>
                            <div
                              className="header-column"
                              style={{ width: 60 }}
                              onClick={this.headerClick('eventType')}
                            >
                              <span>{intl.formatMessage(appFieldsMessages.type)}</span>
                              {this.sortIcon(sortBy, sortDirection, 'eventType')}
                            </div>
                            <div
                              className="header-column"
                              style={{ width: 100 }}
                              onClick={this.headerClick('patternId')}
                            >
                              <span>{intl.formatMessage(logMessages.patternId)}</span>
                              {this.sortIcon(sortBy, sortDirection, 'patternId')}
                            </div>
                            <div className="header-column" style={{ width: 100, flex: 1 }}>
                              {intl.formatMessage(eventMessages.shortDescription)}
                              <span onClick={this.handleAllExpand}>
                                <DoubleLeftOutlined rotate={allExpand ? 90 : -90} style={{ marginLeft: 4 }} />
                              </span>
                            </div>
                            <div className="header-column" style={{ width: 120 }} />
                          </div>
                          <List
                            className="event-list-grid corner-0-0-8-8"
                            ref={(listNode) => {
                              this.listNode = listNode;
                              registerChild(listNode);
                            }}
                            onRowsRendered={onRowsRendered}
                            width={width}
                            height={height - this.listHeaderHeight}
                            rowCount={eventList.length + 1}
                            overscanRowCount={4}
                            deferredMeasurementCache={this.cellMeasureCache}
                            rowHeight={this.cellMeasureCache.rowHeight}
                            rowRenderer={this.renderListItem(eventList)}
                            onScrollbarPresenceChange={({ horizontal, vertical }) => {
                              if (vertical) {
                                this.listNodeHeaderScrollbar = true;
                                this.cellMeasureCache.clearAll();
                                if (this.listNode) this.listNode.forceUpdateGrid();
                              } else {
                                this.listNodeHeaderScrollbar = false;
                              }
                              this.forceUpdate();
                            }}
                          />
                        </div>
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              </Spin>
            </Container>
          </div>
        </Container>
        {this.state.showTimeSelectModal && (
          <TimeSelectModal
            projectName={get(this.state.activeIncident, 'projectName')}
            instanceName={this.state.selectInstance}
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
            timeIntervals={1}
            showKeywordSearch
          />
        )}
        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeIncident}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            contextTime={contextTime}
            keywordFilter={contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
          />
        )}
      </Container>
    );
  }
}

export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { projects, currentTheme } = state.app;
    const { isAdmin } = state.auth.userInfo;
    return { credentials, isAdmin, projects, currentTheme };
  },
  { updateLastActionInfo },
)(LogKeywordEvent);
