import React, { useCallback } from 'react';
import { Button } from 'antd';

import { Container } from '../../../../lib/fui/react';

import { settingsMessages } from '../../../../common/settings/messages';

import EditComponent from './EditComponent';

export default function GroupEditComponent({
  intl,
  projectName,
  childReloadData,
  localInstanceGroupList,
  onChangeShowEditComponent,
}: Object) {
  return (
    <div className="flex-col full-height">
      <Container breadcrumb className="flex-row" style={{ marginBottom: 0 }}>
        <div className="flex-row flex-center-align" style={{ width: 'fit-content' }}>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => onChangeShowEditComponent({ showEditComponent: false })}
          >
            {intl.formatMessage(settingsMessages.projectSetting)}
          </Button>
          <div style={{ marginLeft: 4 }}>{`/ ${intl.formatMessage(settingsMessages.editComponentMap)}`}</div>
        </div>
      </Container>
      <div
        className="flex-grow flex-min-height flex-col content-bg flex-min-width corner-10"
        style={{ margin: '0 16px 16px 16px', padding: 8 }}
      >
        <EditComponent
          projectName={projectName}
          childReloadData={childReloadData}
          localInstanceGroupList={localInstanceGroupList}
        />
      </div>
    </div>
  );
}
