/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get, isNumber } from 'lodash';
import Helmet from 'react-helmet';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';

import { CollapsibleLogContent } from '../../share';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { parseLocation, getLoadStatus, ifIn, LogParser, LogRenderers } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/log/actions';
import { Container } from '../../../lib/fui/react';
import parseIncidentEntryList from '../../../common/apis/parsers/parseIncidentEntryList';

type Props = {
  // Common props
  intl: Object,
  location: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  projects: Array<Object>,
  credentials: Object,

  instanceInfoList: Array<Object>,
};

class LogImportantEventReportCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.pageSize = 50;
    this.state = { isLoading: false, instancesDetailInfos: [] };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.instanceInfoList !== nextProps.instanceInfoList) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { location, credentials, createLoadAction, instanceInfoList } = props;
    const params = parseLocation(location);
    const { projectName, day } = params;
    const instanceList = R.map((instanceInfo) => instanceInfo.id, instanceInfoList || []);

    if (instanceList.length === 0) {
      createLoadAction(ActionTypes.LOAD_LOG_INSTANCEINFO_LIST, { projectName, day }, null);
    } else if (instanceList.length > 0) {
      this.setState({ isLoading: true });
      const requests = [];
      R.forEach((instanceName) => {
        requests.push(
          fetchGet(getEndpoint('fetchimportantlog'), {
            ...credentials,
            projectName,
            instanceName,
            day,
          }).then((data) => {
            return { ...data, instanceName };
          }),
        );
      }, instanceList);
      props.updateLastActionInfo();
      Promise.all(requests).then((results) => {
        const instancesDetailInfos = [];
        R.forEach((result) => {
          const { instanceName, eventArray, incidentResult } = result || {};
          let logImportantEntryList = R.map((l) => {
            const { frequency, eventType, count } = l;

            let typeGroup = null;
            let frequencyStr = '';
            if (frequency) {
              const percent = `${Math.abs(frequency).toFixed(2)}%`;
              const cmp = frequency > 0 ? 'higher' : 'lower';
              frequencyStr = `${isNumber(count) ? `Count:${count}.` : ''}Frequency is ${percent} ${cmp} than normal.`;
            }
            if (
              eventType.indexOf('hot') === 0 ||
              eventType.indexOf('cold') === 0 ||
              eventType.indexOf('rare') === 0 ||
              eventType.indexOf('critical') === 0
            ) {
              typeGroup = eventType;
            } else {
              typeGroup = 'whitelist';
            }

            return { ...l, frequencyStr, typeGroup };
          }, eventArray || []);

          // add incident event to log event list
          let incidentEvents = parseIncidentEntryList(incidentResult || [], {});
          incidentEvents = R.map((event) => {
            const { type } = event;
            return { ...event, eventType: type, typeGroup: type };
          }, incidentEvents);
          logImportantEntryList = [...logImportantEntryList, ...incidentEvents];

          logImportantEntryList = R.map((event) => {
            return {
              ...event,
              typeAndColor: LogParser.CalculateLogType(event),
            };
          }, logImportantEntryList);
          instancesDetailInfos.push({
            instanceName,
            entries: logImportantEntryList,
          });
        }, results);
        this.setState({ instancesDetailInfos, isLoading: false });
      });
    }
  }

  @autobind
  parseData(props) {}

  render() {
    const { intl, loadStatus } = this.props;
    const { location } = this.props;
    const params = parseLocation(location);
    const { isLoading, instancesDetailInfos } = this.state;
    const { projectName, day } = params;
    const width = 700;
    const logOptions = { width: 700, highlightWord: null, hideExpanded: true };

    return (
      <Container className={`report ${isLoading ? 'loading' : ''}`} style={{ width: '100%', minHeight: 200 }}>
        <Helmet title={`report-${projectName}-${day}`} htmlAttributes={{ class: 'report' }} />
        <Container style={{ fontSize: 12, width, margin: '0 auto', padding: '16px 0' }}>
          {R.addIndex(R.map)((item, index) => {
            return (
              <div key={index} className="section">
                <div style={{ fontSize: 14, fontWeight: 'bold', marginTop: 8, color: 'blue' }}>{`Instance: ${
                  item.instanceName
                }`}</div>
                <div>
                  {R.addIndex(R.map)((event, indexEvent) => {
                    const { typeAndColor, nid, patternName, count, category } = event;
                    const message = event.rawData || '';
                    const { color } = typeAndColor.length > 0 ? typeAndColor[0] : 'gray';
                    return (
                      <div key={indexEvent} style={{ marginTop: 8 }}>
                        <div style={{ fontWeight: 'bold', fontSize: 13 }}>{`Important Event (${moment
                          .utc(event.timestamp)
                          .toString()})`}</div>
                        <div className="flex-row" style={{ flexWrap: 'wrap', padding: '4px 0' }}>
                          <div className="flex-row" style={{ marginRight: 12 }}>
                            <div style={{ paddingRight: 4, fontWeight: 500 }}>Pattern:</div>
                            <span
                              className="anomaly-event-legend"
                              style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
                            >
                              {patternName || (isNumber(nid) ? `Pattern ${nid}` : 'NA')}
                            </span>
                          </div>

                          <div className="flex-row" style={{ marginRight: 12 }}>
                            <div style={{ paddingRight: 4, fontWeight: 500 }}>Type:</div>
                            <div className="flex-grow flex-row">
                              {R.addIndex(R.map)(
                                (tc, idx) => LogRenderers.LogTypeItemRenderer({ intl, idx, tc }),
                                typeAndColor,
                              )}
                            </div>
                          </div>

                          <div className="flex-row" style={{ marginRight: 12 }}>
                            <div style={{ paddingRight: 4, fontWeight: 500 }}>Count:</div>
                            <span
                              className="anomaly-event-legend"
                              style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
                            >
                              {isNumber(count) ? count : ''}
                            </span>
                          </div>

                          <div className="flex-row" style={{ marginRight: 12 }}>
                            <div style={{ paddingRight: 4, fontWeight: 500 }}>Category:</div>
                            <div className="flex-grow flex-row">
                              {R.addIndex(R.map)(
                                (msg, idx) => (
                                  <span
                                    key={idx}
                                    className="anomaly-event-legend"
                                    style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
                                  >
                                    {msg}
                                  </span>
                                ),
                                R.split('&', category || 'NA'),
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="log-event-group"
                          style={{ fontSize: 12, padding: '0 0', wordBreak: 'break-all' }}
                        >
                          <CollapsibleLogContent ownerObject={null} message={message} {...logOptions} />
                        </div>
                      </div>
                    );
                  }, R.sort((eventA, eventB) => eventA.timestamp - eventB.timestamp, item.entries || []))}
                </div>
              </div>
            );
          }, instancesDetailInfos)}
        </Container>
      </Container>
    );
  }
}

const LogImportantEventReport = injectIntl(LogImportantEventReportCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { projects, loadStatus } = state.app;
    const instanceInfoList = state.log.instanceInfoList || [];
    return { location, credentials, loadStatus, projects, instanceInfoList };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(LogImportantEventReport);
