/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import { connect } from 'react-redux';
import { get, isString } from 'lodash';
import * as R from 'ramda';
import { autobind } from 'core-decorators';

import { State } from '../../../common/types';
import { Container } from '../../../lib/fui/react';
import parseCausalIncident from '../../../common/apis/parsers/parseCausalIncident';
import CausalIncident from '../../causal/components/CausalIncident';
import { loadCausalIncident } from '../../../common/causal/actions';

type Props = {
  width: Number,
  height: Number,
  queryResult: Object,
  queryParams: Object,
  loadCausalIncident: Function,
};

class KPIViolationPrediction extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.causalGroupList = [];
    this.convertData(props, {});
    this.defaultkpiPredictionProbability = '1.0';
    this.state = {
      view: 'kpiprediction',
      relationTimeThreshold: '15.0',
      relationProbability: undefined,
      relationCount: undefined,
      kpiPredictionProbability: this.defaultkpiPredictionProbability,
      joinDependency: false,
      selectedCausalGroup: get(this.causalGroupList[0], 'name'),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps, {});
    }
  }

  convertData(props, params = {}) {
    const { queryResult } = props;
    const { mapping, causalResult } = queryResult;
    const causalGroupList = [];
    R.forEachObjIndexed((val, key) => {
      const { causalGroupInfo, relationKey, fileName } = val;
      const kpiPrediction = get(val, ['kpiPrediction', 'kpiResult'], {});
      const kpi = get(val, ['kpiPrediction', 'kpiSetting'], {});
      const instanceMapping = JSON.parse(val.instanceMapping || '{}');

      const causalIncident = parseCausalIncident(val, {}, kpiPrediction, instanceMapping, mapping, kpi);
      causalGroupList.push({ name: key, causalIncident, causalGroupInfo, relationKey, fileName });
    }, causalResult || {});
    this.causalGroupList = causalGroupList;
  }

  @autobind
  handleListItemClick(name) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        selectedCausalGroup: name,
        view: 'kpiprediction',
        relationTimeThreshold: undefined,
        relationProbability: undefined,
        relationCount: undefined,
        kpiPredictionProbability: this.defaultkpiPredictionProbability,
        joinDependency: false,
      });
    };
  }

  @autobind
  handleViewChange(view) {
    this.setState({ view });
  }

  @autobind
  handleRelationTimeThresholdChanged(newValue) {
    this.setState({ relationTimeThreshold: newValue });
  }

  @autobind
  handleRelationProbabilityChanged(newValue) {
    this.setState({ relationProbability: newValue });
  }

  @autobind
  handleRelationCountChanged(newValue) {
    this.setState({ relationCount: newValue });
  }

  @autobind
  handleKpiPredictionProbabilityChanged(newValue) {
    this.setState({ kpiPredictionProbability: newValue });
  }

  @autobind
  handleJoinDependencyChanged(newValue) {
    this.setState({ joinDependency: newValue });
  }

  render() {
    const { height, width, loadCausalIncident, queryParams } = this.props;
    const { selectedCausalGroup, view } = this.state;
    const {
      relationTimeThreshold,
      relationProbability,
      relationCount,
      kpiPredictionProbability,
      joinDependency,
    } = this.state;
    const causal = R.find((c) => c.name === selectedCausalGroup, this.causalGroupList) || {};
    const causalGroup = get(causal, 'causalGroupInfo', {});
    const { causalKey, causalName, userName } = causalGroup;
    const { relationKey, fileName } = causal;
    const { startTimeObj, endTimeObj } = queryParams;
    const incidentParams = {
      causalName,
      causalKey,
      customerName: userName,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      relationKey,
      fileName,
    };

    return (
      <Container className="flex-col causal" style={{ height, width }}>
        <div className="ui pointing secondary menu" style={{ marginBottom: 0 }}>
          {R.map(
            (c) => (
              <a
                key={c.name}
                className={`${c.name === selectedCausalGroup ? 'active' : ''} item`}
                onClick={this.handleListItemClick(c.name)}
              >
                {c.name}
              </a>
            ),
            this.causalGroupList,
          )}
        </div>
        <CausalIncident
          className="flex-grow"
          incidentParams={incidentParams}
          causalGroup={causalGroup}
          view={view}
          relationTimeThreshold={relationTimeThreshold}
          relationProbability={relationProbability}
          kpiPredictionProbability={kpiPredictionProbability}
          relationCount={relationCount}
          joinDependency={joinDependency}
          onViewChange={this.handleViewChange}
          onRelationTimeThresholdChanged={this.handleRelationTimeThresholdChanged}
          onRelationProbabilityChanged={this.handleRelationProbabilityChanged}
          onRelationCountChanged={this.handleRelationCountChanged}
          onJoinDependencyChanged={this.handleJoinDependencyChanged}
          onKpiPredictionProbabilityChanged={this.handleKpiPredictionProbabilityChanged}
          loadCausalIncident={loadCausalIncident}
          hideCausalRelation
          hideCorrelation
        />
      </Container>
    );
  }
}

export default connect(
  (state: State) => {
    return {};
  },
  { loadCausalIncident },
)(KPIViolationPrediction);
