/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import { get, debounce, ceil, isFinite, floor } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import {
  DashboardOutlined,
  FileTextOutlined,
  HistoryOutlined,
  LineChartOutlined,
  ProfileOutlined,
  FundOutlined,
  TableOutlined,
  AppstoreAddOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  AimOutlined,
} from '@ant-design/icons';
import {
  Tooltip,
  Button,
  Select,
  Spin,
  Empty,
  Divider,
  Pagination,
  DatePicker,
  message,
  Tag,
  Progress,
  Switch,
  Checkbox,
} from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { BaseUrls } from '../../app/Constants';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes as AuthActionTypes } from '../../../common/auth/actions';
import {
  buildUrl,
  Defaults,
  GlobalParse,
  downloadFile,
  downloadImage,
  calcColorOfHealthScore,
} from '../../../common/utils';
import { Modal, Popover } from '../../../lib/fui/react';
import {
  IncidentPredictIcon,
  RootCauseIcon,
  CapacityPlanningIcon,
  QeuryIcon,
  SunnyIcon,
  RainingIcon,
  PinLegendIcon,
  ActionIcon,
  FixedIcon,
  ChangeEventIcon,
} from '../../../lib/fui/icons';
import { EChart } from '../../share';

import { appMessages, appMenusMessages, appFieldsMessages, appButtonsMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { logMessages } from '../../../common/log/messages';

import EventShortListModal from './EventShortListModal';
import ProjectSelectorModal from './ProjectSelectorModal';
import InsightQueryBoxModal from './InsightQueryBoxModal';
import ProjectInsertIncidentModal from './ProjectInsertIncidentModal';
import SystemScoreHistorical from './SystemScoreHistorical';

import zoomViewImg from '../../../../images/zoomViewImg.jpg';
import { UHV_PANEL_HEIGHT } from './GlobalConstants';
import dashboardMessages from '../../../common/dashboard/messages/DashboardMessages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  userName: String,
  startTime: String,
  endTime: String,
  customerName: String,
  environmentId: String,
  // eslint-disable-next-line
  view: String,
  refresh: Number,
  systemIncidentTimelines: Array<Object>,
  systemHealth: Object,
  systemInfo: Object,
  showHistoricalPredictions: Boolean,
  showDetectedIncidents: Boolean,
  isLoading: Boolean,
  selectAnomalyInstance: Boolean,
  // eslint-disable-next-line
  rankMap: Object,
  alertTimelines: Array<Object>,
  incidentTimelines: Array<Object>,
  isLoadingZoomIn: Boolean,
  onChangeDatazoomTime: Function,
  onChangeSystemDropdown: Function,
  onChangeSystemAnomalyInstance: Function,
  onChangeSystemPredictions: Function,
  onChangeSystemDetectedIncidents: Function,
  onReloadSystemIncidentTimelines: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  push: Function,
  createLoadAction: Function,
  // eslint-disable-next-line
  defaultTimezone: String,
  // eslint-disable-next-line
  currentLocale: String,
  credentials: Object,
  isAdmin: Boolean,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,

  // eslint-disable-next-line
  summarySettingsMap: Object,
  logsummaryLoading: Boolean,
  // eslint-disable-next-line
  currentTheme: String,
  // ref
  healthRow: Function,
  changeProjectActive: Function,
  userInfo: Object,
};

class SystemHealthRowCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { selectAnomalyInstance } = props;
    const {
      systemHealth,
      incidentTimelinesMap,
      chartOption,
      instanceContainerList,
      systemInfo,
      view,
      showHistoricalPredictions,
      showDetectedIncidents,
    } = this.parseData(props);
    this.state = {
      systemHealth,
      incidentTimelinesMap,
      chartOption,
      instanceContainerList,
      systemInfo,
      view,
      showHistoricalPredictions,
      showDetectedIncidents,
      selectAnomalyInstance,

      isResetDateZoom: true,
      isLoadingZoomIn: null,
      zoomStartTime: null,
      zoomEndTime: null,
      showSystemEventModel: false,
      systemTimestamp: null,
      queryStartTime: null,
      queryEndTime: null,
      seriesId: null,
      timelines: [],

      instancePageIndex: 1,
      instancePageSize: 20,
      fetchingInstanceValue: null,
      fetchingInstanceContainerList: [],

      showProjectSelector: false,
      showInsightQueryBox: false,
      queryParams: {},
      currentSystem: null,
      onConfirmProjectSelect: null,
      showEmailModal: false,
      showScoreModal: false,
      showHistoricalScoreModal: false,
      showInsertIncidentModal: false,

      newZoomTip: window.localStorage.getItem('newZoomTipFlag'),
      newZoomTipChecked: false,
    };
    this.systemChartRef = {};
    this.localHealthScore = 90;
    this.splitAnomalyDuratuon = 60 * 60 * 1000;
  }

  componentDidMount() {
    window.onClickHandleGHVChartTipHide = this.handleChartTipHide;
    this.props.healthRow(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.refresh !== this.props.refresh ||
      nextProps.systemIncidentTimelines !== this.props.systemIncidentTimelines ||
      nextProps.systemHealth !== this.props.systemHealth ||
      nextProps.systemInfo !== this.props.systemInfo ||
      nextProps.showHistoricalPredictions !== this.props.showHistoricalPredictions ||
      nextProps.showDetectedIncidents !== this.props.showDetectedIncidents ||
      nextProps.rankMap !== this.props.rankMap ||
      nextProps.alertTimelines !== this.props.alertTimelines ||
      nextProps.incidentTimelines !== this.props.incidentTimelines
    ) {
      if (!R.isEmpty(nextProps.systemHealth) && nextProps.systemHealth && nextProps.systemIncidentTimelines) {
        // from isLoadingZoomIn or rankMap change in zoom in status
        const { zoomStartTime, zoomEndTime } = this.state;
        const isLoadingZoomIn =
          nextProps.isLoadingZoomIn || (zoomStartTime && zoomEndTime && nextProps.rankMap !== this.props.rankMap);
        const {
          systemHealth,
          incidentTimelinesMap,
          chartOption,
          instanceContainerList,
          systemInfo,
          view,
          showHistoricalPredictions,
          showDetectedIncidents,
        } = this.parseData(nextProps);
        this.setState({
          systemHealth,
          incidentTimelinesMap,
          chartOption,
          instanceContainerList,
          systemInfo,
          view,
          showHistoricalPredictions,
          showDetectedIncidents,

          isResetDateZoom: true,
          isLoadingZoomIn,
          ...(isLoadingZoomIn ? {} : { zoomStartTime: null, zoomEndTime: null }),
        });
        if (!isLoadingZoomIn) {
          nextProps.onChangeDatazoomTime({
            systemId: systemHealth.id,
            zoomStartTime: null,
            zoomEndTime: null,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    window.onClickHandleGHVChartTipHide = undefined;
  }

  @autobind
  getSystemIncidentTimelins(allDayAnomalyScoresList, timelines, predictionIncidentTimes, nowTimestamp, rankMap) {
    // get all timestamps from realy health score
    const anomalyScoresList = R.map(
      (item) => ({
        ...item,
        incidentList: [],
        predictionIncidentList: [],
        actionList: [],
        fixedIncidentList: [],
        deploymentIncidentList: [],
        alertList: [],
        traceList: [],
      }),
      allDayAnomalyScoresList || [],
    );
    let filterTimestamps = R.map((item) => item.timestamp, anomalyScoresList);
    filterTimestamps = R.reverse(filterTimestamps);

    // add timelines for anomalyScoresList
    R.forEach((item) => {
      const {
        splitRangeTimestamp,
        startTimestamp,
        predictionTime,
        type,
        isTrace,
        timeLineType,
        dayStartTimestamp,
        isFixedIncident,
        triggeredTimeMap,
        validationTimeList,
        patternId,
        patternName,
      } = item;

      // set action time for both detected/predicted incident
      let triggeredActionTime;
      let triggeredActionList = [];
      if (type === 'Incident') {
        // filter actions
        R.forEachObjIndexed((val, actionName) => {
          R.forEach((timestamp) => {
            triggeredActionList.push({
              actionName,
              timestamp,
              patternId,
              patternName,
            });
          }, val);
        }, triggeredTimeMap);
        triggeredActionList = R.sortWith([R.ascend(R.prop('timestamp'))], triggeredActionList);
        triggeredActionTime = triggeredActionList.length > 0 ? triggeredActionList[0].timestamp : undefined;
      }

      // find the score time when the incident is happend after.
      // const pointTime =
      //   timeLineType === 'future' && isFixedIncident
      //     ? triggeredActionTime || predictionTime
      //     : splitRangeTimestamp || startTimestamp;
      // Change to predictionTime
      const pointTime = timeLineType === 'future' ? predictionTime : splitRangeTimestamp || startTimestamp;
      const incidentTimestamp = R.find((time) => time <= pointTime, filterTimestamps);
      const anomalyScoresItem = R.find((item) => item.timestamp === incidentTimestamp, anomalyScoresList);
      if (!anomalyScoresItem) return;

      if (type === 'Incident') {
        if (timeLineType !== 'future') {
          anomalyScoresItem.incidentList.push({ ...item, rank: get(rankMap, [item.id]) });
        } else {
          // use incident indicator to to filter prediction incident
          // if (!predictionIncidentTimes.includes(startTimestamp)) {
          //   console.debug(`Ignored prediction incident: ${moment.utc(startTimestamp).format(Defaults.DateTimeFormat)}`);
          //   return;
          // }

          anomalyScoresItem.predictionIncidentList.push({ ...item, triggeredActionList });
          anomalyScoresItem.incidentOwnTime = dayStartTimestamp;
        }

        // display actions if has triggered action, and not out of
        let inValidationTimeWindow = true;
        // filter for prediction incident
        if (timeLineType === 'future' && !isFixedIncident && validationTimeList && validationTimeList.length > 0) {
          inValidationTimeWindow = validationTimeList[0].endTime >= nowTimestamp;
        }
        if (triggeredActionTime && inValidationTimeWindow) {
          const actionTimestamp = R.find((time) => time <= triggeredActionTime, filterTimestamps);
          const anomalyScoresActionItem = R.find((item) => item.timestamp === actionTimestamp, anomalyScoresList);
          if (anomalyScoresActionItem) {
            anomalyScoresActionItem.actionList.push({ ...item, triggeredActionTime, triggeredActionList });
            if (timeLineType === 'future' && isFixedIncident) {
              anomalyScoresActionItem.fixedIncidentList.push({ ...item, triggeredActionList });
            } else if (isFixedIncident) {
              anomalyScoresActionItem.fixedIncidentList.push({ ...item, triggeredActionList });
            }
          }
        }
      } else if (type === 'Deployment') {
        anomalyScoresItem.deploymentIncidentList.push({ ...item, rank: get(rankMap, [item.id]) });
      } else {
        anomalyScoresItem.alertList.push({ ...item, rank: get(rankMap, [item.id]) });
      }
    }, timelines);

    // reduce incident point display, not include deployment
    let mergeAnomalyScoresList = [];
    // let mergeIncidentList = [];
    let mergePredictionIncidentList = [];
    // let mergeDeploymentIncidentList = [];
    const healthScoreCount = anomalyScoresList.length;
    let iniEndTimestamp = anomalyScoresList.length > 0 ? anomalyScoresList[healthScoreCount - 1].timestamp : 0;
    R.addIndex(R.forEach)((item, index) => {
      const { timestamp, predictionIncidentList, ...rest } = item;
      // mergeIncidentList = R.concat(mergeIncidentList, incidentList);
      mergePredictionIncidentList = R.concat(mergePredictionIncidentList, predictionIncidentList);

      // reduce by 15mins
      if (index < healthScoreCount - 1 && timestamp > iniEndTimestamp - 0) {
        mergeAnomalyScoresList.push({
          timestamp,
          ...rest,
          // incidentList: [],
          predictionIncidentList: [],
        });
      } else {
        mergeAnomalyScoresList.push({
          timestamp,
          ...rest,
          // incidentList: mergeIncidentList,
          predictionIncidentList: mergePredictionIncidentList,
        });
        iniEndTimestamp = timestamp;
        // mergeIncidentList = [];
        mergePredictionIncidentList = [];
      }
    }, R.reverse(anomalyScoresList));
    // reduce incidents from end to start
    mergeAnomalyScoresList = R.reverse(mergeAnomalyScoresList);

    // add flag if has incident or deployment anomaly
    mergeAnomalyScoresList = R.map((item) => {
      const hasIntraAnomaly =
        item.incidentList.length > 0 ||
        item.predictionIncidentList.length > 0 ||
        item.actionList.length > 0 ||
        item.fixedIncidentList.length > 0 ||
        item.deploymentIncidentList.length > 0 ||
        item.alertList.length > 0;
      return { ...item, hasIntraAnomaly };
    }, mergeAnomalyScoresList);

    return mergeAnomalyScoresList;
  }

  @autobind
  handleSystemClick(systemInfo, category) {
    return (event) => {
      event.preventDefault();
      event.stopPropagation();

      const { push, customerName, endTime, environmentId } = this.props;
      const { id } = systemInfo;
      const query = {
        startTime: endTime,
        endTime,
        customerName,
        environmentId,
        systemId: id,
      };

      switch (category) {
        case 'details':
          push(buildUrl(BaseUrls.GlobalSystemInfo, {}, query));
          break;
        case 'insights':
          window.open(buildUrl(BaseUrls.GlobalSystemInsights, {}, query), '_blank');
          break;
        case 'predictedIncidents':
          window.open(buildUrl(BaseUrls.GlobalSystemPrediction, {}, query), '_blank');
          break;
        case 'detectedAnomalies':
          window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
          break;
        default:
          break;
      }
    };
  }

  @autobind
  handleCapacityPlanningClick(systemInfo) {
    return (event) => {
      event.stopPropagation();
      event.preventDefault();

      const { endTime, environmentId, customerName } = this.props;
      const projectNameSet = get(systemInfo, ['projectNameSet'], []);
      const metricProjects = R.filter((project) => project.dataType === 'Metric', projectNameSet);
      if (metricProjects.length > 1) {
        this.setState({
          showProjectSelector: true,
          currentSystem: systemInfo,
          onConfirmProjectSelect: this.handleCapacityPlanningConfirm,
        });
      } else {
        const { projectNameReal } = metricProjects[0];
        const systemId = get(systemInfo, ['id']);
        const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
        const startTimeObj = moment.utc(endTime, Defaults.DateFormat).startOf('day');
        const endTimeObj = moment.utc(endTime, Defaults.DateFormat).add(7, 'days').startOf('day');
        const query = {
          startTime: endTime,
          endTime,
          customerName,
          environmentId,
          systemId,

          projectName: projectNameReal,
          instanceGroup,
          dateTimestamp: startTimeObj.valueOf(),
          startTimestamp: startTimeObj.valueOf(),
          endTimestamp: endTimeObj.valueOf(),
        };
        window.open(buildUrl(BaseUrls.CapacityPlanningCalendar, {}, query), '_blank');
      }
    };
  }

  @autobind
  handleCapacityPlanningConfirm(projectName) {
    const { endTime, environmentId, customerName } = this.props;
    const { currentSystem } = this.state;
    const systemId = get(currentSystem, 'id');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    const startTimeObj = moment.utc(endTime, Defaults.DateFormat).startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).add(7, 'days').startOf('day');
    const query = {
      startTime: endTime,
      endTime,
      customerName,
      environmentId,
      systemId,

      projectName,
      instanceGroup,
      dateTimestamp: startTimeObj.valueOf(),
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
    };
    window.open(buildUrl(BaseUrls.CapacityPlanningCalendar, {}, query), '_blank');
  }

  @autobind
  handleLineChartClick(systemInfo) {
    return (event) => {
      event.stopPropagation();
      event.preventDefault();

      const { startTime, endTime, environmentId, customerName } = this.props;
      const projectNameSet = get(systemInfo, ['projectNameSet'], []);
      const metricProjects = R.filter((project) => project.dataType === 'Metric', projectNameSet);
      if (metricProjects.length > 1) {
        this.setState({
          showProjectSelector: true,
          currentSystem: systemInfo,
          onConfirmProjectSelect: this.handleLineChartsConfirm,
        });
      } else {
        const { projectNameReal } = metricProjects[0];
        const systemId = get(systemInfo, ['id']);
        const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
        let modelType = 'Holistic';
        const startTimeObj = moment.utc(startTime, Defaults.DateFormat).startOf('day');
        const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
        if (instanceGroup !== 'All') modelType = 'splitByEnv';
        const query = {
          startTime,
          endTime,
          customerName,
          environmentId,
          systemId,
          projectName: projectNameReal,
          instanceGroup,
          modelType,
          startTimestamp: startTimeObj.valueOf(),
          endTimestamp: endTimeObj.valueOf(),
          predictedFlag: false,
        };
        window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
      }
    };
  }

  @autobind
  handleLineChartsConfirm(projectName) {
    const { startTime, endTime, environmentId, customerName } = this.props;
    const { currentSystem } = this.state;
    const systemId = get(currentSystem, 'id');
    const startTimeObj = moment.utc(startTime, Defaults.DateFormat).startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';
    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,
      projectName,
      instanceGroup,
      modelType,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      predictedFlag: false,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleInsightQueryClick(systemInfo) {
    return (event) => {
      event.stopPropagation();
      event.preventDefault();

      const { startTime, endTime } = this.props;
      this.setState({
        showInsightQueryBox: true,
        queryParams: {
          startTimeObj: moment.utc(startTime, Defaults.DateFormat).startOf('day'),
          endTimeObj: moment.utc(endTime, Defaults.DateFormat).endOf('day'),
        },
        currentSystem: systemInfo,
        onConfirmProjectSelect: this.handleInsightQueryConfirm,
      });
    };
  }

  @autobind
  handleInsightQueryConfirm(params) {
    const {
      templateId,
      projectName,
      instanceName,
      startTimeObj,
      endTimeObj,
      keyword,
      numOfCluster,
      pattern,
      duration,
    } = params;
    const query = {
      t: templateId,
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      keyword,
      numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
      pattern,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      duration,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    this.setState({ showInsightQueryBox: false });
  }

  @autobind
  handleHistoryClick(systemInfo) {
    return (event) => {
      event.preventDefault();
      event.stopPropagation();

      const { endTime, environmentId, customerName } = this.props;
      const { id } = systemInfo;
      const query = {
        startTime: endTime,
        endTime,
        customerName,
        environmentId,
        systemId: id,
      };
      window.open(buildUrl(BaseUrls.EventsHistory, {}, query), '_blank');
    };
  }

  @autobind
  handleSystemEmailConfig(systemInfo) {
    this.setState({ showEmailModal: true, currentSystem: systemInfo });
  }

  @autobind
  handleSystemScoreConfig(systemInfo) {
    this.setState({ showScoreModal: true, currentSystem: systemInfo });
  }

  @autobind
  handleSystemHistoricalScore(systemInfo, systemHealth) {
    this.setState({ showHistoricalScoreModal: true, currentSystem: systemInfo, currentSystemHealth: systemHealth });
  }

  @autobind
  handleSystemInsertIncident(systemInfo) {
    this.setState({ showInsertIncidentModal: true, currentSystem: systemInfo });
  }

  @autobind
  handleChartTipHide(systemId) {
    // hide tip when click chart
    const elems = document.getElementsByClassName('ghv-chart-tooltip');
    R.forEach((elem) => {
      elem.parentNode.style.display = 'none';
    }, elems);
  }

  @autobind
  onSystemChartClick(systemHealth) {
    return (item) => {
      const { createLoadAction, changeProjectActive } = this.props;
      const { incidentTimelinesMap } = this.state;
      const { seriesId, name, data } = item;
      // use dataId if series is incident/fixed/action/deployment/prediction
      const { dataId } = data || {};

      // check the session info
      createLoadAction(AuthActionTypes.UPDATE_USER_AUTH_INFO, {});

      // hide tip when click chart
      const chartRef = this.systemChartRef[systemHealth.id];
      if (chartRef) {
        const echartsInstance = chartRef.getEchartsInstance();
        echartsInstance.dispatchAction({ type: 'hideTip' });
      }

      const intervalInMinutes = get(systemHealth, 'intervalInMinutes', 1);
      const timelines = dataId ? get(incidentTimelinesMap, `${dataId}-${name}`, []) : [];

      const typeId = dataId || seriesId;
      if (R.includes(typeId, ['predictionIncidentLine', 'incidentLine', 'deploymentLine', 'traceLine'])) {
        const timeItem = (timelines || [])[0] || {};
        let activeKey = `predictedIncidents-${timeItem.predictionTime}-${timeItem.projectName}`;
        let type = 'prediction';
        if (typeId === 'incidentLine') {
          type = 'incident';
          activeKey = `detectedIncidents-${timeItem.startTimestamp}-${timeItem.projectName}`;
        } else if (typeId === 'deploymentLine') {
          type = 'changes';
          activeKey = `detectedAlerts-${timeItem.startTimestamp}-${timeItem.projectName}`;
        } else if (typeId === 'traceLine') {
          type = 'changes';
          activeKey = `detectedAlerts-${timeItem.startTimestamp}-${timeItem.projectName}`;
        }

        const projectActive = `${type}-${timeItem.projectName}`;
        changeProjectActive(projectActive, true, true, activeKey);
        return;
      }

      this.setState({
        showSystemEventModel: true,
        systemTimestamp: Number(name),
        queryStartTime: Number(name) - (intervalInMinutes + 10) * 60 * 1000,
        queryEndTime: Number(name) + (intervalInMinutes + 10) * 60 * 1000,
        seriesId: typeId,
        timelines,
      });
    };
  }

  @autobind
  onSystemChartDatazoom(evt, systemHealth) {
    let zoomStartTime;
    let zoomEndTime;

    const systemId = systemHealth.id;
    const chartRef = this.systemChartRef[systemId];
    if (chartRef) {
      const myChart = chartRef.getEchartsInstance();
      const { batch } = evt || {};

      // if zoom from manual trigger, then batch is empty, and not reset zoomStartTime/zoomEndTime
      if (batch && batch.length > 0) {
        // trigger from dataZoom tool
        const { fromReset, startValue, endValue } = batch[0] || {};
        if (!R.isNil(startValue) && !R.isNil(endValue)) {
          const axis = myChart.getModel().option.xAxis[0];
          const dataLength = axis.data.length;
          if (fromReset !== true && (startValue <= 5 || endValue >= dataLength - 6)) {
            // rezoom
            myChart.dispatchAction({
              type: 'dataZoom',
              batch: [
                {
                  fromReset: true,
                  startValue: startValue <= 5 ? 0 : startValue,
                  endValue: endValue >= dataLength - 6 ? dataLength - 1 : endValue,
                },
              ],
            });
          } else {
            // handle zoom
            zoomStartTime = axis.data[startValue];
            zoomEndTime = axis.data[endValue];
            if (endValue === dataLength - 1) {
              // reset zoomEndTime to day's end
              zoomEndTime = moment.utc(zoomEndTime).endOf('day').valueOf();
            }

            this.setState({ zoomStartTime, zoomEndTime });
            this.props.onChangeDatazoomTime({ systemId, zoomStartTime, zoomEndTime });
          }
        } else {
          // reset zoom
          this.setState({ zoomStartTime, zoomEndTime });
          this.props.onChangeDatazoomTime({ systemId, zoomStartTime, zoomEndTime });
        }
      }
    }
  }

  @autobind
  onSystemChartRestore(evt, systemHealth) {
    const systemId = systemHealth.id;
    const chartRef = this.systemChartRef[systemId];
    if (chartRef) {
      const myChart = chartRef.getEchartsInstance();
      myChart.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: true,
      });
      let zoomStartTime;
      let zoomEndTime;
      this.setState({ zoomStartTime, zoomEndTime });
      this.props.onChangeDatazoomTime({ systemId, zoomStartTime, zoomEndTime });
    }
  }

  @autobind
  onSystemChartFinished(systemHealth) {
    const { isResetDateZoom, isLoadingZoomIn } = this.state;

    // if line chart is reset, default active the dataZoom
    if (isResetDateZoom) {
      this.setState({ isResetDateZoom: false, isLoadingZoomIn: false }, () => {
        const systemId = systemHealth.id;
        try {
          const chartRef = this.systemChartRef[systemId];
          if (chartRef) {
            const { zoomStartTime, zoomEndTime } = this.state;
            const myChart = chartRef.getEchartsInstance();

            // active the dataZoom
            myChart.dispatchAction({
              type: 'takeGlobalCursor',
              key: 'dataZoomSelect',
              dataZoomSelectActive: true,
            });

            // if line chart is reset and this reset is from zoom view, then reset zoom range back
            if (isLoadingZoomIn) {
              const axis = myChart.getModel().option.xAxis[0];
              const startValue = R.findIndex((item) => item === zoomStartTime, axis.data || []);
              const endValue = R.findIndex((item) => item === zoomEndTime, axis.data || []);
              // myChart.dispatchAction({ type: 'dataZoom', start: 0, end: 100 });
              myChart.dispatchAction({ type: 'dataZoom', startValue, endValue });
            }
          }
        } catch (err) {
          // console.error(err);
        }
      });
    }
  }

  @autobind
  onZoomIn(zoomStartTime, zoomEndTime) {
    const { systemHealth } = this.props;
    const chartRef = this.systemChartRef[systemHealth.id];
    const myChart = chartRef.getEchartsInstance();
    const axis = myChart.getModel().option.xAxis[0];
    const startValue = R.findIndex((item) => item === zoomStartTime, axis.data || []);
    const endValue = R.findIndex((item) => item === zoomEndTime, axis.data || []);
    myChart.dispatchAction({ type: 'dataZoom', startValue, endValue });
  }

  @autobind
  setChartRef(systemId, chart) {
    this.systemChartRef[systemId] = chart;
  }

  @autobind
  parseData(props) {
    const {
      intl,
      credentials,
      currentLocale,
      customerName,
      userList,
      systemsMap,
      isAdmin,
      userName,
      view,
      systemIncidentTimelines,
      systemHealth,
      systemInfo,
      showHistoricalPredictions,
      showDetectedIncidents,
      selectAnomalyInstance,
      rankMap,
      alertTimelines,
      incidentTimelines,
      summarySettingsMap,
      currentTheme,
    } = props;
    if (!systemHealth) return {};
    const instanceDisplayNameMap = get(systemInfo, ['instanceDisplayNameMap'], {});
    const startTsParser = moment.utc().valueOf();

    const { isCurrentDay, allDayAnomalyScoresList, predictionIncidentTimes, intervalInMinutes, currentTimestamp } =
      systemHealth;

    let { defaultTimezone } = props;
    // use customer time zone to display data for admin
    // if (isAdmin && customerName) {
    //   const customerUserInfo = R.find((user) => user.userName === customerName, userList || []);
    //   defaultTimezone = customerUserInfo ? customerUserInfo.zone : defaultTimezone;
    // } else
    if (systemInfo.owner !== userName) {
      const timezone = get(systemsMap, [systemInfo.id, 'timezone']);
      defaultTimezone = timezone || defaultTimezone;
    }

    // parse Historical Predictions control
    const nowTimestamp = currentTimestamp;

    // split alert timelines by hour
    const alertTimelinesSplit = [];
    R.forEach((item) => {
      const { startTimestamp, endTimestamp } = item;
      const hours =
        endTimestamp > startTimestamp ? ceil((endTimestamp - startTimestamp) / this.splitAnomalyDuratuon) : 1;
      R.forEach((hour) => {
        const splitRangeTimestamp = startTimestamp + hour * this.splitAnomalyDuratuon;
        alertTimelinesSplit.push({ ...item, splitRangeTimestamp });
      }, R.range(0, hours));
    }, alertTimelines || []);

    // get system incident timelins
    const { dataStartTimestamp } = systemHealth;
    let timelines = [...(systemIncidentTimelines || []), ...alertTimelinesSplit, ...incidentTimelines];
    timelines = R.filter((item) => !item.isIgnored && item.startTimestamp >= dataStartTimestamp, timelines);
    const anomalyScoresList = this.getSystemIncidentTimelins(
      allDayAnomalyScoresList,
      timelines,
      predictionIncidentTimes,
      nowTimestamp,
      rankMap,
    );

    // parse select anomaly instance timestamp
    let selectInstanceIncidentsTimes = [];
    let selectInstancePredictionIncidentsTimes = [];
    let selectInstanceActionIncidentsTimes = [];
    let selectInstanceFixedIncidentsTimes = [];
    let selectInstanceDeploymentsTimes = [];
    let selectInstanceAlertsTimes = [];

    if (selectAnomalyInstance) {
      const intraAnomalyList = R.filter((item) => item.hasIntraAnomaly, anomalyScoresList);
      const selectInstanceIncidents = R.filter((item) => {
        return R.map((item) => item.instanceName, item.incidentList || []).includes(selectAnomalyInstance);
      }, intraAnomalyList);
      selectInstanceIncidentsTimes = R.map((item) => item.timestamp, selectInstanceIncidents);
      const selectInstancePredictionIncidents = R.filter((item) => {
        return R.map((item) => item.instanceName, item.predictionIncidentList || []).includes(selectAnomalyInstance);
      }, intraAnomalyList);
      selectInstancePredictionIncidentsTimes = R.map((item) => item.timestamp, selectInstancePredictionIncidents);
      const selectInstanceActionIncidents = R.filter((item) => {
        return R.map((item) => item.instanceName, item.actionList || []).includes(selectAnomalyInstance);
      }, intraAnomalyList);
      selectInstanceActionIncidentsTimes = R.map((item) => item.timestamp, selectInstanceActionIncidents);
      const selectInstanceFixedIncidents = R.filter((item) => {
        return R.map((item) => item.instanceName, item.fixedIncidentList || []).includes(selectAnomalyInstance);
      }, intraAnomalyList);
      selectInstanceFixedIncidentsTimes = R.map((item) => item.timestamp, selectInstanceFixedIncidents);
      const selectInstanceDeployments = R.filter((item) => {
        return R.map((item) => item.instanceName, item.deploymentIncidentList || []).includes(selectAnomalyInstance);
      }, intraAnomalyList);
      selectInstanceDeploymentsTimes = R.map((item) => item.timestamp, selectInstanceDeployments);
      const selectInstanceAlerts = R.filter((item) => {
        return R.map((item) => item.instanceName, item.alertList || []).includes(selectAnomalyInstance);
      }, intraAnomalyList);
      selectInstanceAlertsTimes = R.map((item) => item.timestamp, selectInstanceAlerts);
    }

    // deployment instances
    const deploymentInstances = R.uniq(
      R.map(
        (item) => item.instanceName,
        R.filter((item) => item.type === 'Deployment', systemIncidentTimelines || []),
      ),
    );
    // build all instance info list
    let instanceContainerList = [];
    R.forEach((instance) => {
      const { containerList, id, componentId } = instance;
      const diffComponent = componentId !== id;
      const isDeployment = deploymentInstances.includes(id);
      const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, id);

      // has anomaly instance
      instanceContainerList.push({
        type: 'instance',
        diffComponent,
        componentId,
        instanceId: id,
        id,
        viewId: instanceDisplayName || id,
        anomalyCount: instance.hasAnomaly,
        hasAnomaly: !isDeployment && instance.hasAnomaly > 0,
        isDeployment,
      });

      // has anomaly container
      R.forEach((container) => {
        instanceContainerList.push({
          type: 'container',
          diffComponent,
          componentId,
          instanceId: id,
          id: `${container.id}_${id}`,
          viewId: `${container.id}_${instanceDisplayName || id}`,
          anomalyCount: container.hasAnomaly,
          hasAnomaly: !isDeployment && container.hasAnomaly > 0,
          isDeployment,
        });
      }, containerList);
    }, get(systemInfo, ['instanceList'], []));
    instanceContainerList = R.sortWith(
      [
        R.descend(R.prop('anomalyCount')),
        R.ascend(R.compose(R.toLower, R.prop('componentId'))),
        R.ascend(R.compose(R.toLower, R.prop('id'))),
      ],
      instanceContainerList,
    );

    const { incidentTimelinesMap, option: chartOption } = GlobalParse.getHealthChartOption({
      intl,
      credentials,
      currentLocale,
      defaultTimezone,
      isAdmin,
      userName,
      systemHealth,
      nowTimestamp,
      isCurrentDay,
      intervalInMinutes,
      anomalyScoresList,
      showHistoricalPredictions,
      showDetectedIncidents,
      healthScore: this.localHealthScore,
      selectAnomalyInstance,
      selectInstanceIncidentsTimes,
      selectInstancePredictionIncidentsTimes,
      selectInstanceActionIncidentsTimes,
      selectInstanceFixedIncidentsTimes,
      selectInstanceDeploymentsTimes,
      selectInstanceAlertsTimes,
      summarySettingsMap,
      currentTheme,
    });

    // Merge the incident with the same day
    let chartOptionNew = chartOption;

    const mergeDaily = true;
    if (mergeDaily) {
      const series = chartOption.series || [];
      const incidentLine = R.find((x) => x.id === 'incidentLine', series);
      if (incidentLine) {
        const lineData = incidentLine.data || [];
        let mergedLineData = [];
        const timelineMap = [];

        R.forEach((d) => {
          if (d.dataId !== 'incidentLine') {
            mergedLineData.push(d);
          } else {
            const { timelineStartTime, timelineEndTime, projectName, tooltipTimelines, realScore, itemStyle } = d;
            const hourly = moment.utc(timelineStartTime).format('YYYY-MM-DD HH');
            R.forEach((t) => {
              const patternId = t?.patternId;
              const instanceName = t?.instanceName;
              t.timelineStartTime = timelineStartTime;
              t.timelineEndTime = timelineEndTime;

              const key = `${hourly}-${projectName}-${instanceName}-${patternId}`;
              if (!timelineMap[key]) timelineMap[key] = d;
              const current = timelineMap[key];
              if (current.realScore > realScore) {
                current.realScore = realScore;
                current.itemStyle = itemStyle;
              }

              current.tooltipTimelines = [...(current.tooltipTimelines || []), t];
            }, tooltipTimelines);
          }
          mergedLineData = [...mergedLineData, ...(R.values(timelineMap) || [])];
        }, lineData);

        incidentLine.data = mergedLineData;
      }
      chartOptionNew = { ...chartOption, series };
    }

    console.debug(`Parse data duration: ${(moment.utc().valueOf() - startTsParser) / 1000} sec`);
    return {
      systemHealth,
      incidentTimelinesMap,
      chartOption: chartOptionNew,
      instanceContainerList: R.uniqBy((n) => n.id, instanceContainerList),
      systemInfo,
      view,
      showHistoricalPredictions,
      showDetectedIncidents,
    };
  }

  @autobind
  renderButtons({ isLoading, view, systemInfo, systemHealth }) {
    const { intl, credentials, isAdmin } = this.props;

    const ownerUserName = get(systemInfo, ['ownerUserName']);
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const metricProjects = R.filter((project) => project.dataType === 'Metric', projectNameSet);
    const logProjects = R.filter((project) => project.dataType !== 'Metric', projectNameSet);

    return (
      <div
        className={view === 'grid' ? 'flex-col' : 'flex-min-width flex-row flex-end-justify'}
        style={view === 'grid' ? {} : { padding: '0 16px' }}
      >
        {false && (
          <Button.Group
            className={view === 'grid' ? 'column' : ''}
            style={view === 'grid' ? { margin: 'auto 8px auto 0' } : {}}
          >
            <Tooltip
              title={intl.formatMessage(appMenusMessages.globalSystemInsights)}
              placement={view === 'grid' ? 'left' : 'top'}
              mouseEnterDelay={0.3}
            >
              <Button
                type="primary"
                style={{ padding: '0 6px', width: 28, fontSize: 14 }}
                onClick={this.handleSystemClick(systemInfo, 'insights')}
              >
                <DashboardOutlined />
              </Button>
            </Tooltip>

            <Tooltip
              title={intl.formatMessage(appMenusMessages.globalSystemPrediction)}
              placement={view === 'grid' ? 'left' : 'top'}
              mouseEnterDelay={0.3}
            >
              <Button
                type="primary"
                style={{ padding: '0 6px', width: 28, fontSize: 14 }}
                onClick={this.handleSystemClick(systemInfo, 'predictedIncidents')}
              >
                <IncidentPredictIcon />
              </Button>
            </Tooltip>
            <Tooltip
              title={intl.formatMessage(appMenusMessages.globalSystemRootCause)}
              placement={view === 'grid' ? 'left' : 'top'}
              mouseEnterDelay={0.3}
            >
              <Button
                type="primary"
                style={{ padding: '0 6px', width: 28, fontSize: 14 }}
                onClick={this.handleSystemClick(systemInfo, 'detectedAnomalies')}
              >
                <RootCauseIcon />
              </Button>
            </Tooltip>
            {false && metricProjects.length > 0 && (
              <Tooltip
                title={intl.formatMessage(appMenusMessages.globalCapacityView)}
                placement={view === 'grid' ? 'left' : 'top'}
                mouseEnterDelay={0.3}
              >
                <Button
                  type="primary"
                  style={{ padding: '0 6px', width: 28, fontSize: 14 }}
                  onClick={this.handleCapacityPlanningClick(systemInfo)}
                >
                  <CapacityPlanningIcon />
                </Button>
              </Tooltip>
            )}
            {metricProjects.length > 0 && (
              <Tooltip
                title={intl.formatMessage(DashboardMessages.lineChart)}
                placement={view === 'grid' ? 'left' : 'top'}
                mouseEnterDelay={0.3}
              >
                <Button type="primary" icon={<LineChartOutlined />} onClick={this.handleLineChartClick(systemInfo)} />
              </Tooltip>
            )}
            {logProjects.length > 0 && (
              <Tooltip
                title={intl.formatMessage(appMenusMessages.logQuery)}
                placement={view === 'grid' ? 'left' : 'top'}
                mouseEnterDelay={0.3}
              >
                <Button
                  type="primary"
                  style={{ padding: '0 6px', width: 28, fontSize: 14 }}
                  onClick={this.handleInsightQueryClick(systemInfo)}
                >
                  <QeuryIcon />
                </Button>
              </Tooltip>
            )}

            {false && (
              <Tooltip
                title={intl.formatMessage(DashboardMessages.anomalyHistory)}
                placement={view === 'grid' ? 'left' : 'top'}
                mouseEnterDelay={0.3}
              >
                <Button type="primary" icon={<HistoryOutlined />} onClick={this.handleHistoryClick(systemInfo)} />
              </Tooltip>
            )}
            {false && (
              <Tooltip
                title={intl.formatMessage(DashboardMessages.anomalyDetails)}
                placement={view === 'grid' ? 'left' : 'top'}
                mouseEnterDelay={0.3}
              >
                <Button
                  type="primary"
                  icon={<ProfileOutlined />}
                  onClick={this.handleSystemClick(systemInfo, 'details')}
                />
              </Tooltip>
            )}
            {false && metricProjects.length > 0 && (
              <Tooltip
                title={intl.formatMessage(DashboardMessages.treeMap)}
                placement={view === 'grid' ? 'left' : 'top'}
                mouseEnterDelay={0.3}
              >
                <Button type="primary" icon={<TableOutlined />} onClick={this.handleTreeMapClick(systemInfo)} />
              </Tooltip>
            )}
            {false && logProjects.length > 0 && (
              <Tooltip
                title={intl.formatMessage(DashboardMessages.logEntries)}
                placement={view === 'grid' ? 'left' : 'top'}
                mouseEnterDelay={0.3}
              >
                <Button type="primary" icon={<FileTextOutlined />} onClick={this.handleLatestLogsClick(systemInfo)} />
              </Tooltip>
            )}
          </Button.Group>
        )}

        <Button.Group
          className={view === 'grid' ? 'column' : ''}
          style={view === 'grid' ? { margin: '16px 8px auto 0' } : { margin: '0 0 0 16px' }}
        >
          {false && (
            <Tooltip title="Historical max scores" placement={view === 'grid' ? 'left' : 'top'} mouseEnterDelay={0.3}>
              <Button
                type="primary"
                style={{ background: '#31C780', borderColor: '#31C780' }}
                icon={<FundOutlined />}
                onClick={() => this.handleSystemHistoricalScore(systemInfo, systemHealth)}
              />
            </Tooltip>
          )}
          <Tooltip title="Ingest incident data" placement={view === 'grid' ? 'left' : 'top'} mouseEnterDelay={0.3}>
            <Button
              type="primary"
              style={{ background: '#31C780', borderColor: '#31C780' }}
              icon={<AppstoreAddOutlined />}
              onClick={() => this.handleSystemInsertIncident(systemInfo)}
            />
          </Tooltip>
          <Tooltip title="Download" placement={view === 'grid' ? 'left' : 'top'} mouseEnterDelay={0.3}>
            <Button
              type="primary"
              style={{ background: '#31C780', borderColor: '#31C780', padding: '0 6px', width: 60, fontSize: 14 }}
              disabled={isLoading || !systemHealth.hasData}
              onClick={() => this.handleSystemDownloadClick(systemInfo, systemHealth)}
            >
              {/* <DownloadOutlined /> */}
              {intl.formatMessage(appButtonsMessages.export)}
            </Button>
          </Tooltip>
        </Button.Group>
      </div>
    );
  }

  @autobind
  handleSystemDownloadClick(systemInfo, systemHealth) {
    const { intl, startTime, endTime } = this.props;
    const startTimeObj = moment.utc(startTime, Defaults.DateFormat);
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat);
    this.downloadCategory = 'csv';
    this.rangeStartDate = startTimeObj;
    this.rangeEndDate = endTimeObj;

    const Content = () => (
      <div>
        <div className="flex-row flex-center-align">
          <span className="light-label bold" style={{ width: 100 }}>
            {intl.formatMessage(appFieldsMessages.category)}:
          </span>
          <Select
            style={{ width: UHV_PANEL_HEIGHT }}
            showSearch
            filterOption
            optionFilterProp="value"
            defaultValue={this.downloadCategory}
            onChange={(downloadCategory) => {
              this.downloadCategory = downloadCategory;
              if (this.exportModal) {
                this.exportModal.update({ content: <Content /> });
              }
            }}
          >
            <Select.Option value="csv">CSV</Select.Option>
            <Select.Option value="image">Image</Select.Option>
          </Select>
        </div>

        {this.downloadCategory === 'csv' && (
          <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
            <span className="light-label bold" style={{ width: 100 }}>
              {intl.formatMessage(appFieldsMessages.startDate)}:
            </span>
            <DatePicker
              allowClear={false}
              showToday={false}
              defaultValue={startTimeObj}
              onChange={(date) => {
                this.rangeStartDate = date;
              }}
              disabledDate={(current) => {
                return current && this.rangeEndDate && current > this.rangeEndDate;
              }}
            />
          </div>
        )}
        {this.downloadCategory === 'csv' && (
          <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
            <span className="light-label bold" style={{ width: 100 }}>
              {intl.formatMessage(appFieldsMessages.endDate)}:
            </span>
            <DatePicker
              allowClear={false}
              showToday={false}
              defaultValue={endTimeObj}
              onChange={(date) => {
                this.rangeEndDate = date;
              }}
              disabledDate={(current) => {
                return current && this.rangeStartDate && current < this.rangeStartDate;
              }}
            />
          </div>
        )}
      </div>
    );

    this.exportModal = Modal.confirm({
      title: intl.formatMessage(DashboardMessages.exportSystemHealthScore),
      content: <Content />,
      okButtonProps: {},
      onOk: () => {
        if (this.downloadCategory === 'csv') {
          return this.handleSystemDownloadCsvConfirm(systemInfo, systemHealth, this.rangeStartDate, this.rangeEndDate);
        }
        return this.handleSystemDownloadImageConfirm(systemInfo, systemHealth);
      },
    });
  }

  @autobind
  handleSystemDownloadCsvConfirm(systemInfo, systemHealth, startTimeObj, endTimeObj) {
    // start download data
    if (this.exportModal) {
      this.exportModal.update({
        okButtonProps: { loading: true },
      });
    }
    const exportCallback = () => {
      if (this.exportModal) this.exportModal.destroy();
    };

    const { intl, credentials, environmentId, isAdmin } = this.props;
    const { id, name, ownerUserName } = systemInfo;
    return fetchGet(getEndpoint('stats/global/systemanomalyscore', 2), {
      ...credentials,
      envName: environmentId,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      customerName: ownerUserName,
      systemIds: JSON.stringify([id]),
      shareUserFlag: ownerUserName !== credentials.userName && !isAdmin,
    })
      .then((result) => {
        let allDayAnomalyScoresList = [];
        R.forEach((data) => {
          // eslint-disable-next-line
          const { systemName, ...rest } = data;
          R.forEachObjIndexed((val, day) => {
            const { healthScoreSeries } = val;
            let anomalyScoresList = R.values(healthScoreSeries || {});
            anomalyScoresList = R.map((item) => {
              const { timeStamp, score } = item;
              return { timestamp: timeStamp, time: moment.utc(timeStamp).format(Defaults.DateTimeFormat), score };
            }, anomalyScoresList);

            allDayAnomalyScoresList = [...allDayAnomalyScoresList, ...anomalyScoresList];
          }, rest || {});
        }, result || []);

        // sort all days Anomaly Scores
        allDayAnomalyScoresList = R.sortWith([R.ascend(R.prop('timestamp'))], allDayAnomalyScoresList);

        // download csv file
        const fname = `Health score (${name} ${startTimeObj.format(Defaults.DateFormat)}-${endTimeObj.format(
          Defaults.DateFormat,
        )}).csv`;
        let csvString = 'Time,Health Score\r\n';
        R.forEach((item) => {
          csvString += `${item.time},${item.score}\r\n`;
        }, allDayAnomalyScoresList);
        downloadFile(csvString, fname);

        exportCallback();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        console.debug(err);
      });
  }

  @autobind
  handleSystemDownloadImageConfirm(systemInfo, systemHealth) {
    // start download data
    if (this.exportModal) {
      this.exportModal.update({
        okButtonProps: { loading: true },
      });
    }
    const exportCallback = () => {
      if (this.exportModal) this.exportModal.destroy();
    };

    // const { hasData, avgHealthScore } = systemHealth;
    const echartsRef = this.systemChartRef[systemInfo.id];
    const echartsInstance = echartsRef.getEchartsInstance();
    const imgSrc = echartsInstance.getDataURL({
      type: 'png',
      pixelRatio: 2,
      backgroundColor: '#fff',
      excludeComponents: ['toolbox', 'tooltip'],
    });

    const filename = `${systemInfo.name}-Health Cahrt.png`;
    const content = ReactDOMServer.renderToStaticMarkup(
      <div id="divToPrint" style={{ padding: 8 }}>
        <div className="flex-row flex-center-align" style={{ height: 40, marginTop: 8 }}>
          <div
            className="flex-grow flex-row flex-min-width flex-row flex-center-align"
            style={{ padding: '0 16px', fontWeight: 'bold', fontSize: 20 }}
          >
            <div className="flex-grow flex-row flex-min-width flex-center-align">
              <div className="hidden-line-with-ellipsis" style={{ display: 'inline-block', maxWidth: '100%' }}>
                {systemHealth.name}
              </div>
            </div>
          </div>

          <div className="flex-grow" />
        </div>

        <img id="chart" style={{ width: 1024, height: 200 }} alt="chart" src={imgSrc} />
      </div>,
    );
    return downloadImage(filename, content, exportCallback);
  }

  @autobind
  handleAnomalyInstanceSearch(fetchingInstanceValue) {
    let fetchingInstanceContainerList = [];
    if (fetchingInstanceValue) {
      const { instanceContainerList } = this.state;

      // start searching
      this.setState({ fetchingInstanceContainerList: [] });
      fetchingInstanceContainerList = R.filter((item) => {
        return (
          item.componentId.toLowerCase().includes(fetchingInstanceValue.toLowerCase()) ||
          item.id.toLowerCase().includes(fetchingInstanceValue.toLowerCase()) ||
          item.viewId.toLowerCase().includes(fetchingInstanceValue.toLowerCase())
        );
      }, instanceContainerList);
    }

    // end searching
    this.setState({ instancePageIndex: 1, fetchingInstanceValue, fetchingInstanceContainerList });
  }

  render() {
    const {
      intl,
      credentials,
      isAdmin,
      environmentId,
      customerName,
      startTime,
      endTime,
      isLoading,
      rankMap,
      logsummaryLoading,
    } = this.props;
    const {
      onChangeSystemDropdown,
      onChangeSystemAnomalyInstance,
      onChangeSystemPredictions,
      onChangeSystemDetectedIncidents,
    } = this.props;
    const {
      systemHealth,
      chartOption,
      instanceContainerList,
      systemInfo,
      view,
      showHistoricalPredictions,
      showDetectedIncidents,
      selectAnomalyInstance,
      zoomStartTime,
      zoomEndTime,
      newZoomTip,
      newZoomTipChecked,
    } = this.state;
    const {
      showSystemEventModel,
      systemTimestamp,
      queryStartTime,
      queryEndTime,
      seriesId,
      timelines,
      showProjectSelector,
      showInsightQueryBox,
      queryParams,
      currentSystem,
      currentSystemHealth,
      onConfirmProjectSelect,
      showHistoricalScoreModal,
      showInsertIncidentModal,
    } = this.state;
    const { instancePageIndex, instancePageSize, fetchingInstanceValue, fetchingInstanceContainerList } = this.state;
    const instanceList = R.slice(
      (instancePageIndex - 1) * instancePageSize,
      instancePageIndex * instancePageSize,
      (fetchingInstanceValue ? fetchingInstanceContainerList : instanceContainerList) || [],
    );
    const { color: currentScoreColor } = calcColorOfHealthScore((systemHealth || {}).avgHealthScore);

    const { hasData } = systemHealth || {};
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const logProjects = R.filter((project) => project.dataType !== 'Metric', projectNameSet);

    return (
      <div
        className="health-panel flex-grow flex-row flex-min-width content-bg corner-10-10-0-0"
        style={{ margin: '0px 8px 0 8px', height: UHV_PANEL_HEIGHT + 40, position: 'relative', boxShadow: 'none' }}
      >
        <Spin
          spinning={logsummaryLoading || isLoading}
          wrapperClassName="flex-grow flex-min-height flex-min-width spin-full-height"
        >
          {!isLoading && !systemHealth && (
            <div className="full-height full-width flex-row flex-center-align flex-center-justify">
              <Empty />
            </div>
          )}
          {view === 'list' && (
            <>
              <div className="flex-row flex-center-align" style={{ height: 40, marginTop: 8 }}>
                <div
                  className="flex-row flex-min-width flex-row flex-center-align"
                  style={{ padding: '0 16px', fontWeight: 'bold', fontSize: 20 }}
                >
                  <div className="flex-grow flex-row flex-min-width flex-center-align">
                    <Tooltip
                      title={
                        <div className="flex-col" style={{ maxWidth: 400, maxHeight: 400, overflowY: 'auto' }}>
                          <span>{`${intl.formatMessage(DashboardMessages.system)}:`}</span>
                          <span style={{ paddingLeft: 20 }}>{systemHealth.name}</span>
                          <span>{`${intl.formatMessage(DashboardMessages.projectList)}:`}</span>
                          {R.addIndex(R.map)((project, idx) => {
                            return (
                              <span key={`row${project}${idx}`} style={{ paddingLeft: 20 }}>
                                {R.includes('@', project?.projectDisplayName || '')
                                  ? project?.projectDisplayName
                                  : `${project?.projectDisplayName}@${project?.userName}`}
                              </span>
                            );
                          }, projectNameSet)}
                        </div>
                      }
                      placement="rightBottom"
                      mouseEnterDelay={0.3}
                      overlayStyle={{ maxWidth: 400 }}
                    >
                      <div style={{ display: 'inline-block', maxWidth: '100%' }}>
                        <div className="flex-row" style={{ alignItems: 'flex-end' }}>
                          <div style={{ lineHeight: 1 }}>{systemHealth.name}</div>
                          {systemHealth.isCurrentDay && (
                            <Progress
                              type="dashboard"
                              style={{ margin: '0 10px' }}
                              width={25}
                              strokeColor={currentScoreColor}
                              strokeWidth={8}
                              percent={Math.abs(
                                isFinite(systemHealth.avgHealthScore) ? systemHealth.avgHealthScore : 0,
                              )}
                              format={(percent2) => {
                                return (
                                  <div style={{ color: currentScoreColor }}>
                                    {isFinite(systemHealth.avgHealthScore) ? floor(systemHealth.avgHealthScore) : 'N/A'}
                                  </div>
                                );
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex-grow flex-row flex-center-align">
                  <Select
                    allowClear
                    showSearch
                    autoClearSearchValue={false}
                    size="small"
                    style={{ width: 220 }}
                    placeholder="Anomalous Instance"
                    optionLabelProp="title"
                    filterOption={false}
                    notFoundContent={
                      <div className="flex-row flex-center-justify">
                        <Empty />
                      </div>
                    }
                    onDropdownVisibleChange={onChangeSystemDropdown({ systemInfo })}
                    value={selectAnomalyInstance}
                    onChange={(value) => {
                      this.setState(
                        {
                          selectAnomalyInstance: value,
                          fetchingInstanceValue: null,
                          fetchingInstanceContainerList: [],
                        },
                        () => {
                          onChangeSystemAnomalyInstance(systemHealth, value);
                        },
                      );
                    }}
                    onSearch={debounce(this.handleAnomalyInstanceSearch, 600)}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ minWidth: 380, maxWidth: 860 }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '2px 0' }} />
                        <div style={{ padding: '4px 8px' }} onMouseDown={(event) => event.preventDefault()}>
                          <Pagination
                            size="small"
                            showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                            total={
                              (fetchingInstanceValue ? fetchingInstanceContainerList : instanceContainerList).length
                            }
                            current={instancePageIndex}
                            pageSize={instancePageSize}
                            onChange={(page) => this.setState({ instancePageIndex: page })}
                            showSizeChanger
                            pageSizeOptions={['10', '20', '40', '60', '100', '500', '1000']}
                            onShowSizeChange={(page, pageSize) =>
                              this.setState({ instancePageIndex: page, instancePageSize: pageSize })
                            }
                          />
                        </div>
                      </div>
                    )}
                  >
                    {R.addIndex(R.map)((instanceContainer, idx) => {
                      return (
                        <Select.Option key={instanceContainer.id} className="flex-row" title={instanceContainer.id}>
                          {!instanceContainer.hasAnomaly && (
                            <SunnyIcon style={{ color: '#ffc800', fontSize: 14, marginRight: 8 }} />
                          )}
                          {instanceContainer.hasAnomaly && (
                            <RainingIcon style={{ color: Defaults.Colorbrewer[0], fontSize: 14, marginRight: 8 }} />
                          )}

                          <span className="inline-block hidden-line-with-ellipsis">
                            {instanceContainer.diffComponent && (
                              <span style={{ paddingRight: 8 }}>{instanceContainer.componentId}</span>
                            )}
                            <span>{instanceContainer.viewId}</span>
                          </span>

                          <span style={{ margin: '0 4px 0 8px', fontWeight: 'bold' }}>{`  ${
                            instanceContainer.isDeployment ? 'Change count' : 'Anomaly count'
                          }: `}</span>
                          <Tag color="#FF5142" style={{ height: 20, padding: '0 4px' }}>
                            {instanceContainer.anomalyCount}
                          </Tag>
                        </Select.Option>
                      );
                    }, instanceList)}
                  </Select>
                </div>
                <div className="flex-row flex-center-align">
                  <div className="flex-grow flex-row flex-center-align flex-end-justify">
                    {false && (
                      <div className="flex-row flex-center-align">
                        <span className="light-label" style={{ marginRight: 10 }}>
                          {intl.formatMessage(DashboardMessages.showHistoricalPredictions)}
                        </span>
                        <Switch
                          size="small"
                          checked={showHistoricalPredictions}
                          onChange={onChangeSystemPredictions({ systemInfo })}
                        />
                      </div>
                    )}
                    <div className="flex-row flex-center-align" style={{ marginLeft: 8 }}>
                      <span className="light-label" style={{ marginRight: 10 }}>
                        {intl.formatMessage(DashboardMessages.showDetectedIncidents)}
                      </span>
                      <Switch
                        size="small"
                        checked={showDetectedIncidents}
                        onChange={onChangeSystemDetectedIncidents({ systemInfo })}
                      />
                    </div>
                  </div>
                  {this.renderButtons({ isLoading, view, systemInfo, systemHealth })}
                </div>
              </div>
              <div className="flex-row flex-center-justify" style={{ height: 40, padding: '8px 16px' }}>
                <div className="flex-row  flex-center-align">
                  <PinLegendIcon style={{ color: '#ffad66', fontSize: 20 }} />
                  <span style={{ marginRight: 4 }}>:</span>
                  <span style={{ lineHeight: 1, marginTop: 2, fontWeight: 'bold' }}>
                    {intl.formatMessage(DashboardMessages.predictedIncident)}
                  </span>
                </div>
                <div className="flex-row  flex-center-align" style={{ marginLeft: 12 }}>
                  <PinLegendIcon style={{ color: '#FF5142', fontSize: 20 }} />
                  <span style={{ marginRight: 4 }}>:</span>
                  <span style={{ lineHeight: 1, marginTop: 2, fontWeight: 'bold' }}>
                    {intl.formatMessage(DashboardMessages.detectedIncident)}
                  </span>
                </div>
                <div className="flex-row  flex-center-align" style={{ marginLeft: 12 }}>
                  <ChangeEventIcon style={{ color: 'darkorange', fontSize: 18 }} />
                  <span style={{ marginRight: 4 }}>:</span>
                  <span style={{ lineHeight: 1, marginTop: 2, fontWeight: 'bold' }}>
                    {intl.formatMessage(logMessages.changeEvent)}
                  </span>
                </div>
                <div className="flex-row  flex-center-align" style={{ marginLeft: 12 }}>
                  <ActionIcon style={{ color: '#037AEF', fontSize: 16 }} />
                  <span style={{ marginRight: 4 }}>:</span>
                  <span style={{ lineHeight: 1, marginTop: 2, fontWeight: 'bold' }}>
                    {intl.formatMessage(logMessages.triggeredAction)}
                  </span>
                </div>
                <div className="flex-row  flex-center-align" style={{ marginLeft: 12 }}>
                  <FixedIcon style={{ color: '#ff5142', fontSize: 16 }} />
                  <span style={{ marginRight: 4 }}>:</span>
                  <span style={{ lineHeight: 1, marginTop: 2, fontWeight: 'bold' }}>
                    {intl.formatMessage(logMessages.fixedIncident)}
                  </span>
                </div>
              </div>
              <div
                className="flex-row"
                style={{ height: 140, margin: '8px 0', padding: '0 16px', position: 'relative' }}
              >
                {zoomStartTime && (
                  <div className="light-label bold" style={{ position: 'absolute', left: 84, top: 20 }}>
                    {intl.formatMessage(appFieldsMessages.start)}:{' '}
                    {moment.utc(zoomStartTime).format(Defaults.ShortTimeFormat)}
                  </div>
                )}
                {zoomEndTime && (
                  <div className="light-label bold" style={{ position: 'absolute', right: 32, top: 20 }}>
                    {intl.formatMessage(appFieldsMessages.end)}:{' '}
                    {moment.utc(zoomEndTime).format(Defaults.ShortTimeFormat)}
                  </div>
                )}
                {hasData && (
                  <EChart
                    setRef={(chart) => this.setChartRef(systemHealth.id, chart)}
                    width="100%"
                    height="100%"
                    option={chartOption}
                    renderer="svg"
                    onEvents={{
                      click: this.onSystemChartClick(systemHealth),
                      datazoom: (evt) => this.onSystemChartDatazoom(evt, systemHealth),
                      restore: (evt) => this.onSystemChartRestore(evt, systemHealth),
                      rendered: () => this.onSystemChartFinished(systemHealth),
                    }}
                  />
                )}
                {!hasData && (
                  <div className="flex-grow flex-center-align flex-center-justify">
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={`No data were received on ${moment
                        .utc(startTime, Defaults.DateFormat)
                        .format(Defaults.DateFormat2)}`}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </Spin>

        {!(logsummaryLoading || isLoading) && !newZoomTip && (
          <div
            className="flex-row flex-center-align flex-center-justify"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'rgb(0, 0, 0, 40%)',
              zIndex: 2,
            }}
          >
            <div className="flex-row content-bg" style={{ width: 400, height: '95%', padding: 10 }}>
              <div className="clickable">
                <ExclamationCircleOutlined style={{ fontSize: 25, color: '#faad14' }} />
              </div>
              <div className="flex-grow flex-col" style={{ padding: '0 10px' }}>
                <div style={{ fontSize: 14, fontWeight: 'bold', padding: '4px 0 10px 0' }}>
                  {intl.formatMessage(DashboardMessages.zoomTimelineTipTitle)}
                </div>
                <div style={{ fontSize: 13, marginBottom: 5 }}>
                  {intl.formatMessage(DashboardMessages.zoomTimelineTipText)}
                </div>
                <div style={{ height: 70 }}>
                  <img src={zoomViewImg} alt="" style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ textAlign: 'center', marginTop: 5 }}>
                  <Checkbox
                    checked={newZoomTipChecked}
                    onChange={(e) => this.setState({ newZoomTipChecked: e.target.checked })}
                  >
                    {intl.formatMessage(DashboardMessages.zoomTimelineTipCheckboxText)}
                  </Checkbox>
                </div>
              </div>
              <div
                className="clickable"
                onClick={() =>
                  this.setState({ newZoomTip: true }, () => {
                    if (newZoomTipChecked) {
                      window.localStorage.setItem('newZoomTipFlag', true);
                    }
                  })
                }
              >
                <CloseOutlined style={{ fontSize: 16 }} />
              </div>
            </div>
          </div>
        )}

        {showSystemEventModel && (
          <EventShortListModal
            customerName={customerName}
            environmentId={environmentId}
            systemId={get(systemHealth, 'systemId')}
            startTime={startTime}
            endTime={endTime}
            rankMap={rankMap}
            intervalInMinutes={get(systemHealth, 'intervalInMinutes', 1)}
            selectInstance={selectAnomalyInstance}
            systemTimestamp={systemTimestamp}
            queryStartTime={queryStartTime}
            queryEndTime={queryEndTime}
            seriesId={seriesId}
            timelines={timelines}
            onClose={(systemIds) => {
              this.setState({ showSystemEventModel: false });
              if (systemIds) this.props.onReloadSystemIncidentTimelines(systemIds);
            }}
          />
        )}
        {showProjectSelector && (
          <ProjectSelectorModal
            system={currentSystem}
            onConfirm={onConfirmProjectSelect}
            onClose={() => this.setState({ showProjectSelector: false })}
          />
        )}
        {showInsightQueryBox && (
          <InsightQueryBoxModal
            queryParams={queryParams}
            projects={logProjects}
            onConfirm={onConfirmProjectSelect}
            onClose={() => this.setState({ showInsightQueryBox: false })}
          />
        )}

        {showHistoricalScoreModal && (
          <SystemScoreHistorical
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={currentSystem}
            systemHealth={currentSystemHealth}
            onClose={() => this.setState({ showHistoricalScoreModal: false })}
          />
        )}
        {showInsertIncidentModal && (
          <ProjectInsertIncidentModal
            projectNameSet={get(currentSystem, 'projectNameSet', [])}
            onClose={() => this.setState({ showInsertIncidentModal: false })}
          />
        )}
      </div>
    );
  }
}

const SystemHealthRow = injectIntl(SystemHealthRowCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { defaultTimezone, currentLocale, userList, systemsMap, currentTheme } = state.app;
    const { credentials } = state.auth;
    const { isAdmin } = state.auth.userInfo;
    return {
      location,
      defaultTimezone,
      currentLocale,
      userList,
      systemsMap,
      credentials,
      isAdmin,
      currentTheme,
      userInfo: state.auth.userInfo,
    };
  },
  { push, createLoadAction },
)(SystemHealthRow);
