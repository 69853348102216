/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchDelete from './fetchDelete';

const deleteProjectHoliday = (credentials: Credentials, params: Object) => {
  const { projectName, holidayName } = params;
  return fetchDelete(getEndpoint('holiday'), { ...credentials, projectName, holidayName }, {}, false).then(() => {
    return {};
  });
};

export default deleteProjectHoliday;
