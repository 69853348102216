/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, round, isString } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Button, Menu, Select, Input, Pagination, message } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { BaseUrls } from '../../app/Constants';
import { State } from '../../../common/types';
import { Container, AutoSizer, List, CellMeasurerCache, CellMeasurer, Dropdown, Popover } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/metric/actions';
import { parseLocation, getLoadStatus, buildUrl, BackgroundCall, CellRenderers, Defaults } from '../../../common/utils';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import DateSelectModal from './DateSelectModal';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import EventActionModal from './EventActionModal';
import IgnoreModal from './IgnoreModal';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { DashboardMessages } from '../../../common/dashboard/messages';
import fetchGet from '../../../common/apis/fetchGet';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  width: Number,
  height: Number,
  project: Object,
  // eslint-disable-next-line
  instanceList: Array<Object>,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,

  eventListMap: Object,
  instanceDisplayNameMap: Object,
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

class EventListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'events_loader';

    const { intl, location } = props;
    const params = parseLocation(location);
    const { patternSearch, statusFilterAll } = params;

    this.state = {
      page: 1,
      pageSize: 20,

      eventList: [],

      // filter
      statusFilter: 'noIgnore',
      statusFilterAll: statusFilterAll || 'all',
      metricFilter: undefined,
      componentFilter: undefined,
      instanceFilter: undefined,
      patternIdFilter: patternSearch || '',
      patternIdSearch: patternSearch || '',

      showTimeSelectModal: false,
      timeSelectParams: {},
      selectStartTimestamp: undefined,
      selectEndTimestamp: undefined,

      activeIncident: null,
      showTakeLogActionModal: false,
      actionName: null,
      showEventActionModal: false,
      showIgnoreModal: false,

      isLoadingIncident: false,
    };

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.eventList = [];

    this.hasStatusFilterAllOptions = [
      { label: intl.formatMessage(eventMessages.detectedAndPredicted), value: 'all' },
      { label: intl.formatMessage(eventMessages.onlyDetected), value: 'onlyDetected' },
      { label: intl.formatMessage(DashboardMessages.detectedIncident), value: 'onlyDetectedIncident' },
      { label: intl.formatMessage(eventMessages.onlyPredicted), value: 'onlyPredicted' },
      { label: intl.formatMessage(eventMessages.historicalPredictions), value: 'historicalPredictions' },
    ];
    this.hasStatusFilterOptions = [
      { label: intl.formatMessage(eventMessages.all), value: 'all' },
      { label: intl.formatMessage(eventMessages.hideIgnore), value: 'noIgnore' },
    ];
    this.appNameMapping = {};
    this.hasMetricFilterOptions = [];
    this.hasInstanceFilterOptions = [];
    this.hasComponentFilterOptions = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const params = parseLocation(this.props.location);
    const nextParams = parseLocation(nextProps.location);
    if (params.forceRefreshTime !== nextParams.forceRefreshTime) {
      this.reloadData(nextProps);
    } else if (this.props.eventListMap !== nextProps.eventListMap) {
      const detecteds = await this.reloadIncident(nextProps);
      this.parseData(nextProps, detecteds);
    }
  }

  @autobind
  reloadData(props) {
    const { createLoadAction, location, project, instanceList, allAnomalyInstances } = props;
    const params = parseLocation(location);
    const { startTime, endTime } = params;
    if (project && project.hasAllInfo) {
      if (instanceList.length > 0) {
        const startTimeObj = moment.utc(startTime, Defaults.DateFormat).startOf('day');
        const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
        createLoadAction(
          ActionTypes.LOAD_EVENTLIST,
          {
            ...params,
            project,
            startTimeObj,
            endTimeObj,
            instanceList,
            allAnomalyInstances,

            withPredict: true,
            filterPredict: false,
          },
          this.dataLoader,
        );
      } else {
        this.appNameMapping = {};
        this.hasMetricFilterOptions = [];
        this.hasInstanceFilterOptions = [];
        this.hasComponentFilterOptions = [];
        this.setState({ eventList: [] }, () => {
          this.eventList = [];
          this.applyEventFilter(props);
        });
      }
    } else {
      this.eventList = [];
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    }
  }

  @autobind
  parseData(props, detectedData) {
    const { eventListMap } = props;
    const { sortBy, sortDirection } = this.state;

    let allEventList = get(eventListMap, ['eventList'], []);
    allEventList = [...allEventList, ...detectedData];
    const appNameMapping = {};
    // get metric and instance filter options
    let metricList = [];
    let instanceList = [];
    let componentList = [];
    R.forEach((e) => {
      R.forEach((mm) => {
        const { rootCauseMetric, appName, containerName, instanceId: oldInstanceId } = mm;
        let instanceId = oldInstanceId;
        if (containerName && R.includes(`${containerName}_`, instanceId)) {
          instanceId = R.split(`${containerName}_`, instanceId)[1];
        }
        metricList.push(rootCauseMetric);
        instanceList.push(instanceId);
        componentList.push(appName);
        appNameMapping[instanceId] = appName;
      }, get(e, ['rootCauseJson', 'rootCauseDetailsArr'], []));
    }, allEventList);
    metricList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(metricList));
    instanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceList));
    componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));

    // split event
    let eventList = [];
    R.forEach((event) => {
      const { isPrediction, isEmailSent, ignoreFlag, isIncidentEvent, isDeploymentEvent, fromStorage } = event;
      R.forEach((mm) => {
        const {
          isIgnored,
          patternId,
          patternName,
          instanceId: oldInstanceId,
          containerName,
          appName: component,
          rootCauseMetric,
          direction,
          pct,
          timePairArr,
          totalScore,
          isDetectedIncident,
          isDetectedAnomaly,
        } = mm;
        let instanceId = oldInstanceId;
        if (containerName && R.includes(`${containerName}_`, instanceId)) {
          instanceId = R.split(`${containerName}_`, instanceId)[1];
        }
        const appName =
          appNameMapping[instanceId] && appNameMapping[instanceId] !== instanceId
            ? `${instanceId} (${appNameMapping[instanceId]})`
            : instanceId;

        // get score
        const score = totalScore || Math.abs(pct);

        // split time pair by day
        const timePairMap = {};
        R.forEach((tr) => {
          const { startTimestamp, endTimestamp } = tr;
          const day = moment.utc(startTimestamp).format(Defaults.DateFormat);
          if (!R.has(day, timePairMap)) {
            timePairMap[day] = [];
          }
          timePairMap[day].push({ day, startTimestamp, endTimestamp });
        }, timePairArr);

        R.forEachObjIndexed((val, day) => {
          const key = `${uuidv4()}-${instanceId}-${rootCauseMetric}-${patternId}-${day}`;
          eventList.push({
            key,
            event,
            // not used, only for track
            rootCauseDetails: mm,

            isPrediction,
            isEmailSent,
            ignoreFlag,
            isIncidentEvent,
            isDeploymentEvent,
            fromStorage,

            isIgnored,

            patternId,
            patternName,
            appName,
            component,
            oldInstanceId,
            instanceId,
            containerName,
            rootCauseMetric,
            direction,
            day,
            score,

            timePairArr: val,
            isDetectedIncident,
            isDetectedAnomaly,
          });
        }, timePairMap);
      }, get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []));
    }, allEventList);

    // merge events
    eventList = this.mergeEventList(eventList);

    // sort events
    eventList = this.sortData(eventList, sortBy, sortDirection);
    this.appNameMapping = appNameMapping;
    this.hasMetricFilterOptions = R.map((m) => ({ label: m, value: m }), metricList);
    this.hasInstanceFilterOptions = R.map((i) => ({ label: i, value: i }), instanceList);
    this.hasComponentFilterOptions = R.map((item) => ({ value: item, label: item }), componentList);

    this.setState({ eventList }, () => {
      this.applyEventFilter(props);
    });
  }

  @autobind
  parseDetectedIncident(data) {
    let rootCauseJson = R.map((item) => ({ ...JSON.parse(item.rootCause), isDetectedIncident: true }), data);
    rootCauseJson = rootCauseJson.filter((item) => item.metricName);
    let rootCauseDetailsArr = rootCauseJson;
    rootCauseDetailsArr = R.map(
      (rc) => ({
        ...rc,
        appName: rc.componentName,
        direction: rc.sign,
        instanceId: rc.instanceName,
        metricValue: rc.anomalyValue,
        pct: parseFloat(rc.percentage),
        rootCauseMetric: rc.metricName,
        timePairArr: R.map(
          (val) => ({ startTimestamp: val.s, endTimestamp: val.e, duration: val.e - val.s }),
          rc.timePairList,
        ),
      }),
      rootCauseDetailsArr,
    );
    // sort by pct
    rootCauseDetailsArr = R.sort((a, b) => {
      return Math.abs(b.pct) - Math.abs(a.pct);
    }, rootCauseDetailsArr);

    rootCauseJson = { ...rootCauseJson, rootCauseDetailsArr };
    const detecteds = [{ rootCauseJson }];
    return detecteds;
  }

  @autobind
  reloadIncident(props) {
    const { intl, location, credentials, instanceList } = props;
    const params = parseLocation(location);
    const { projectName, startTime, endTime } = params;
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
    this.setState({ isLoadingIncident: true });
    return new Promise((resolve) => {
      fetchPost(getEndpoint('incident'), {
        ...credentials,
        projectName,
        startTime: startTimestamp,
        endTime: endTimestamp,
        instanceWithAnomalyList: JSON.stringify(instanceList || []),
        operation: 'display',
      })
        .then((res) => {
          const { message } = res;
          const detecteds = this.parseDetectedIncident(res);
          resolve(message ? [] : detecteds);
          this.setState({ isLoadingIncident: false });
        })
        .catch((e) => {
          resolve([]);
          this.setState({ isLoadingIncident: false });
        });
    });
  }

  @autobind
  mergeEventList(eventList) {
    const { project } = this.props;
    const eventListMap = {};

    R.forEach((event) => {
      if (!R.has(event.key, eventListMap)) {
        eventListMap[event.key] = event;
      } else {
        eventListMap[event.key] = {
          ...eventListMap[event.key],

          isPrediction: eventListMap[event.key].isPrediction || event.isPrediction,
          isEmailSent: eventListMap[event.key].isEmailSent || event.isEmailSent,
          ignoreFlag: eventListMap[event.key].ignoreFlag || event.ignoreFlag,
          isIncidentEvent: eventListMap[event.key].isIncidentEvent || event.isIncidentEvent,
          isDeploymentEvent: eventListMap[event.key].isDeploymentEvent || event.isDeploymentEvent,
          fromStorage: eventListMap[event.key].fromStorage || event.fromStorage,

          isIgnored: eventListMap[event.key].isIgnored || event.isIgnored,

          score: R.max(eventListMap[event.key].score, event.score),
          timePairArr: [...eventListMap[event.key].timePairArr, ...event.timePairArr],
        };
      }
    }, eventList);

    // build merge data
    let newEventList = R.values(eventListMap);
    newEventList = R.map((event) => {
      const { timePairArr } = event;

      let timePairList = [];
      let allDuration = 0;
      let startTimestamp;
      let endTimestamp;
      R.forEach((tr) => {
        const { startTimestamp: st, endTimestamp: et, duration } = tr;
        timePairList.push([st, et, duration]);
        allDuration += duration || et - st;
        startTimestamp = startTimestamp ? R.min(startTimestamp, st) : st;
        endTimestamp = endTimestamp ? R.max(endTimestamp, et) : et;
      }, timePairArr);
      const duration =
        timePairArr.length > 0 ? round(allDuration / timePairArr.length) + (project.samplingInterval || 0) * 60000 : 0;
      timePairList = R.sortBy(R.prop(0), timePairList);

      return {
        ...event,

        timePairList,
        duration,
        startTimestamp,
        endTimestamp,
      };
    }, newEventList);

    return newEventList;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.compose(R.toLower, R.prop('instanceId'))), R.descend(R.prop('startTimestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  applyEventFilter(props) {
    const { statusFilter, statusFilterAll, metricFilter, componentFilter, instanceFilter, patternIdSearch } =
      this.state;
    let { eventList } = this.state;

    // filter by statusFilter
    eventList = R.filter((event) => {
      if (statusFilter === 'noIgnore') {
        return event.isIgnored !== true;
      }
      return true;
    }, eventList);

    // filter by statusFilterAll
    eventList = R.filter((event) => {
      if (statusFilterAll === 'historicalPredictions') {
        return event.isPrediction === true && event.fromStorage;
      } else if (statusFilterAll === 'onlyPredicted') {
        return event.isPrediction === true && !event.fromStorage;
      } else if (statusFilterAll === 'onlyDetected') {
        return event.isDetectedAnomaly === true;
      } else if (statusFilterAll === 'onlyDetectedIncident') {
        return event.isDetectedIncident === true;
      }
      return !event.fromStorage;
    }, eventList);

    // filter by metricFilter
    if (metricFilter) {
      eventList = R.filter((event) => {
        return event.rootCauseMetric === metricFilter;
      }, eventList);
    }

    // filter by componentFilter
    if (componentFilter) {
      eventList = R.filter((event) => {
        return event.component === componentFilter;
      }, eventList);
    }

    // filter by instanceFilter
    if (instanceFilter) {
      eventList = R.filter((event) => {
        return event.instanceId === instanceFilter;
      }, eventList);
    }

    // filter by patternId
    if (patternIdSearch) {
      eventList = R.filter(
        (event) =>
          String(event.patternId).includes(R.toLower(patternIdSearch)) ||
          (event.patternName && R.toLower(event.patternName).includes(R.toLower(patternIdSearch))),
        eventList,
      );
    }

    this.eventList = eventList;

    this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  rerunTrainingOrDetection(val) {
    const { location } = this.props;
    const isTraining = val.split('_')[0] === 'train';
    const isCurrentDay = val.split('_')[1] === 'current';

    if (!isCurrentDay) {
      const params = parseLocation(location);
      const { startTime, endTime } = params;
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

      this.setState({
        showTimeSelectModal: true,
        timeSelectParams: {
          isTraining,
          isCurrentDay,
        },
        selectStartTimestamp: startTimestamp,
        selectEndTimestamp: endTimestamp,
      });
    } else {
      this.rerun({ isTraining, isCurrentDay });
    }
  }

  @autobind
  onCloseTimeSelect(props) {
    const { timeSelectParams, startTimestamp, endTimestamp } = props || {};
    if (startTimestamp && endTimestamp && Boolean(timeSelectParams)) {
      const { isTraining, isCurrentDay } = timeSelectParams || {};
      this.setState(
        {
          showTimeSelectModal: false,
        },
        () => {
          this.rerun({
            isTraining,
            isCurrentDay,
            selectStartTimestamp: startTimestamp,
            selectEndTimestamp: endTimestamp,
          });
        },
      );
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  showOverallInstanceChart(e) {
    e.stopPropagation();
    const { location } = this.props;
    const params = parseLocation(location);

    const { projectName, startTime, endTime, customerName } = params;
    let { instanceGroup } = params;
    instanceGroup = instanceGroup || 'All';
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

    const url = buildUrl(
      BaseUrls.MetricLineCharts,
      {},
      {
        projectName,
        customerName,
        instanceGroup,
        modelType,
        startTimestamp,
        endTimestamp,
      },
    );
    window.open(url, '_blank');
  }

  @autobind
  rerun({ isTraining, isCurrentDay, selectStartTimestamp, selectEndTimestamp }) {
    const { intl, credentials, location, project } = this.props;
    const params = parseLocation(location);
    const { instanceGroup, startTime, endTime } = params;
    let { projectName } = params;

    const owner = get(project, ['owner']);
    const isStationary = get(project, ['isStationary']);
    if ((projectName || '').indexOf('@') >= 0) {
      projectName = (projectName || '').split('@')[0];
    }
    let modelTypeNew = 'Holistic';
    if (instanceGroup !== 'All') modelTypeNew = 'splitByEnv';
    let url = '';
    let cronParams = {};
    const startDayTimestamp =
      selectStartTimestamp || moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endDayTimestamp = selectEndTimestamp || moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    const interval = endDayTimestamp - startDayTimestamp;

    if (!isStationary) {
      if (isTraining) {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        cronParams = {
          ...credentials,
          customerName: owner,
          rerunFunction: 'metrictraining',
          projectName,
          projectType: project.projectType === 'Agent' ? 'CUSTOM' : project.projectType,
          asofTimestamp: endDayTimestamp,
          windowMillis: interval,
          runAllData: false,
          startTime: startDayTimestamp,
          endTime: endDayTimestamp,
        };
      } else {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        cronParams = {
          ...credentials,
          customerName: owner,
          rerunFunction: 'metricdetection',
          projectName,
          projectType: project.projectType === 'Agent' ? 'CUSTOM' : project.projectType,
          asofTimestamp: endDayTimestamp,
          windowMillis: interval,
          modelTypeNew,
          runAllData: false,
          startTime: startDayTimestamp,
          endTime: endDayTimestamp,
        };
      }
    } else if (isTraining) {
      url = `${window.BASE_URL || ''}/api/v1/metricreplay`;
      cronParams = {
        ...credentials,
        customerName: owner,
        projectName,
        projectType: project.projectType === 'Agent' ? 'CUSTOM' : project.projectType,
        startTime: startDayTimestamp,
        endTime: endDayTimestamp,
        doTraining: true,
        doDetection: false,
        doNormalStats: false,
        doGV: false,
        runAllData: false,
      };
    } else {
      url = `${window.BASE_URL || ''}/api/v1/metricreplay`;
      cronParams = {
        ...credentials,
        projectName,
        customerName: owner,
        projectType: project.projectType === 'Agent' ? 'CUSTOM' : project.projectType,
        startTime: startDayTimestamp,
        endTime: endDayTimestamp,
        doTraining: false,
        doDetection: true,
        doNormalStats: true,
        doGV: true,
        runAllData: false,
      };
    }
    this.props.updateLastActionInfo();
    BackgroundCall.CallUrlJob({ intl, method: 'GET', url, params: cronParams, isJsonResponse: false });
  }

  //
  // data prerender filter
  //
  @autobind
  handleStatusFilterChange(statusFilter) {
    this.setState({ statusFilter }, () => {
      this.applyEventFilter(this.props);
    });
  }

  @autobind
  handleStatusFilterAllChange(statusFilterAll) {
    this.setState({ statusFilterAll }, () => {
      this.applyEventFilter(this.props);
    });
  }

  @autobind
  handleMetricFilterChange(metricFilter) {
    this.setState({ metricFilter }, () => {
      this.applyEventFilter(this.props);
    });
  }

  @autobind
  handleComponentFilterChange(componentFilter) {
    this.setState({ componentFilter }, () => {
      this.applyEventFilter(this.props);
    });
  }

  @autobind
  handleInstanceFilterChange(instanceFilter) {
    this.setState({ instanceFilter }, () => {
      this.applyEventFilter(this.props);
    });
  }

  @autobind
  handlePatternIdSearchChange(patternIdSearch) {
    this.setState({ patternIdSearch }, () => {
      this.applyEventFilter(this.props);
    });
  }

  @autobind
  renderListItem(eventList) {
    return ({ key, index: rowIndex, style, parent }) => {
      const { intl, userInfo, instanceDisplayNameMap, project } = this.props;

      const rowData = eventList[rowIndex];
      if (!rowData) return null;

      const { instanceId, component, rootCauseMetric, patternName, patternId, duration, direction, day, score } =
        rowData;
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName, patternId },
        { hasFullName: true, hasPrefix: true },
      );

      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceId, {
        pn: project?.projectShortName,
        owner: project?.owner,
      });

      const content = (
        <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style, minHeight: 40 }}>
          <div className="row-column" style={{ width: 60 }}>
            {this.patternTypeRenderer(rowData)}
          </div>
          <div className="row-column" style={{ width: 100, flex: 1 }}>
            <Popover mouseEnterDelay={0.3} placement="top" title={null} content={component}>
              <div className="hidden-line-with-ellipsis inline-block">{component}</div>
            </Popover>
          </div>
          <div className="row-column" style={{ width: 100, flex: 1 }}>
            <Popover mouseEnterDelay={0.3} placement="top" title={null} content={instanceStr}>
              <div className="hidden-line-with-ellipsis inline-block">{instanceStr}</div>
            </Popover>
          </div>
          <div className="row-column flex-row" style={{ width: 100, flex: 1 }}>
            <Popover mouseEnterDelay={0.3} placement="top" title={null} content={rootCauseMetric}>
              <div className="hidden-line-with-ellipsis inline-block">{rootCauseMetric}</div>
            </Popover>
            {direction === 'higher' && <i className="icon up arrow" style={{ color: 'red' }} />}
            {direction !== 'higher' && <i className="icon down arrow" style={{ color: 'blue' }} />}
          </div>
          <div className="row-column" style={{ width: 100, flex: 1 }}>
            <Popover mouseEnterDelay={0.3} placement="top" title={null} content={patternNameStr}>
              <div className="hidden-line-with-ellipsis inline-block">{patternNameStr}</div>
            </Popover>
          </div>
          <div className="row-column" style={{ minWidth: 100 }}>
            {day}
          </div>
          <div className="row-column" style={{ minWidth: 110 }}>
            {this.renderTimePair(rowData)}
          </div>
          <div className="row-column" style={{ minWidth: 140, flex: 1 }}>
            {CellRenderers.humanizeDuration({ period: duration, intl })}
          </div>
          <div className="row-column" style={{ width: 120 }}>
            {numeral(score).format(score > 1 ? '0,0' : '0,0.[0000]')}
          </div>
          <div className="row-column flex-end-justify" style={{ width: userInfo.isReadUser ? 100 : 180 }}>
            {this.renderControl(rowData)}
          </div>
        </div>
      );

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          {content}
        </CellMeasurer>
      );
    };
  }

  @autobind
  patternTypeRenderer(rowData) {
    const { isPrediction, isEmailSent, isIncidentEvent, isDeploymentEvent, isDetectedIncident } = rowData;
    const { isIgnored } = rowData;
    let background = 'gray';
    let tags = isPrediction ? ['P'] : [];
    let tagDetails = isPrediction ? ['This is a predicted event.'] : [];

    if (isEmailSent) {
      tags = [...tags, 'E'];
      tagDetails = [...tagDetails, 'This is a email sent event.'];
    }
    if (isIgnored) {
      tags = [...tags, 'I'];
      tagDetails = [...tagDetails, 'This is a ignored event.'];
    }
    if (isIncidentEvent) {
      background = 'red';
      tags = [...tags, 'I'];
      tagDetails = [...tagDetails, 'This is a incident event.'];
    }
    if (isDeploymentEvent) {
      background = 'orange';
      tags = [...tags, 'D'];
      tagDetails = [...tagDetails, 'This is a change event.'];
    }
    if (isDetectedIncident) {
      background = 'red';
      tags = [...tags, 'I'];
      tagDetails = [...tagDetails, 'This is a detected incident.'];
    }
    return (
      <div className="flex-row" style={{ width: '100%' }}>
        {R.addIndex(R.map)(
          (tag, idx) => (
            <Popover key={idx} content={tagDetails[idx]} placement="top" mouseEnterDelay={0.3}>
              <span style={{ textAlign: 'center', background, color: 'white', padding: '0 2px', marginRight: 2 }}>
                {tag}
              </span>
            </Popover>
          ),
          tags,
        )}
      </div>
    );
  }

  @autobind
  renderTimePair(rowData) {
    const { intl } = this.props;
    const { timePairList } = rowData;
    return CellRenderers.timePair({ intl, cellData: timePairList });
  }

  @autobind
  renderControl(rowData) {
    const { intl, userInfo } = this.props;
    const { event, isIgnored, patternId, patternName } = rowData;

    return (
      <div className="flex-row">
        <Button size="small" onClick={() => this.handleMetricDetailsClick(rowData)}>
          {intl.formatMessage(eventMessages.lineChart)}
        </Button>

        {!userInfo.isReadUser && (
          <Dropdown
            itemStyle={{ marginLeft: 8 }}
            align={{ offset: [60, 0] }}
            butStyle={{ width: 120 }}
            name={intl.formatMessage(eventMessages.actions)}
            itemClick={({ key }) => {
              if (key === 'setPatternName') {
                this.handleActionClick({ ...event, patternId, patternName }, 'setPatternName');
              } else if (key === 'takeAction') {
                this.handleEventActionClick({ ...event, patternId, patternName });
              } else if (key === 'ignore') {
                this.handleIgnoreClick({ ...event, isIgnored, patternId, patternName });
              }
            }}
          >
            <>
              <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item>
              <Menu.Item key="takeAction">{intl.formatMessage(eventMessages.takeAction)}</Menu.Item>
              <Menu.Item key="ignore">
                {isIgnored ? intl.formatMessage(eventMessages.unignore) : intl.formatMessage(eventMessages.ignore)}
              </Menu.Item>
            </>
          </Dropdown>
        )}
      </div>
    );
  }

  @autobind
  handleMetricDetailsClick(rowData) {
    const { location } = this.props;
    const paramsLocation = parseLocation(location);
    const { projectName, instanceGroup, customerName } = paramsLocation;
    const { oldInstanceId, rootCauseMetric, startTimestamp, endTimestamp } = rowData;

    const params = {
      projectName,
      instanceGroup,
      customerName,
      modelType: instanceGroup === 'All' ? 'Holistic' : 'splitByEnv',
      startTimestamp: moment.utc(startTimestamp).startOf('day').valueOf(),
      endTimestamp: moment.utc(endTimestamp).endOf('day').valueOf(),
      justSelectMetric: rootCauseMetric,
      justInstanceList: oldInstanceId,
      withBaseline: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, params), '_blank');
  }

  @autobind
  handleActionClick(incident, actionName) {
    this.setState({ activeIncident: incident, showTakeLogActionModal: true, actionName });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    this.setState({ showTakeLogActionModal: false }, () => {
      this.eventList = R.map((e) => {
        if (e.patternId === patternId) {
          return {
            ...e,
            patternName,
          };
        }
        return e;
      }, this.eventList);

      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  handleEventActionClick(incident) {
    this.setState({ activeIncident: incident, showEventActionModal: true });
  }

  @autobind
  handleIgnoreClick(incident) {
    this.setState({ activeIncident: incident, showIgnoreModal: true });
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          this.eventList = this.sortData(this.eventList, name, sortDir);

          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const { intl, width, height, location, loadStatus, userInfo, project, instanceDisplayNameMap } = this.props;
    const { sortBy, sortDirection, page, pageSize } = this.state;
    const {
      statusFilter,
      statusFilterAll,
      metricFilter,
      componentFilter,
      instanceFilter,
      patternIdFilter,

      showTimeSelectModal,
      timeSelectParams,
      selectStartTimestamp,
      selectEndTimestamp,
    } = this.state;
    const {
      showTakeLogActionModal,
      showEventActionModal,
      showIgnoreModal,
      activeIncident,
      actionName,
      isLoadingIncident,
    } = this.state;
    const params = parseLocation(location);
    const { projectName, instanceGroup } = params;

    const eventList = R.slice((page - 1) * pageSize, page * pageSize, this.eventList);
    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    return (
      <Container
        style={{ width, height }}
        className={`flex-col ${(isLoading && !errorMessage) || isLoadingIncident ? ' loading' : ''}`}
      >
        <Container className="flex-row" style={{ padding: '8px 16px 16px 16px' }}>
          <div className="flex-grow flex-row flex-center-align">
            <Select
              size="small"
              value={statusFilterAll}
              style={{ width: 180 }}
              onChange={this.handleStatusFilterAllChange}
              dropdownMatchSelectWidth={false}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value} title={item.label}>
                    {item.label}
                  </Select.Option>
                ),
                this.hasStatusFilterAllOptions,
              )}
            </Select>
            <Select
              size="small"
              value={statusFilter}
              style={{ marginLeft: 8, width: 100 }}
              onChange={this.handleStatusFilterChange}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value} title={item.label}>
                    {item.label}
                  </Select.Option>
                ),
                this.hasStatusFilterOptions,
              )}
            </Select>
          </div>

          {!userInfo.isReadUser && (
            <Dropdown
              itemStyle={{ marginLeft: 8 }}
              name={intl.formatMessage(eventMessages.rerunTrainingOrDetection)}
              itemClick={({ key }) => {
                this.rerunTrainingOrDetection(key);
              }}
            >
              <>
                <Menu.Item key="train_current">{intl.formatMessage(eventMessages.rerunTrainingCur)}</Menu.Item>
                <Menu.Item key="detect_current">{intl.formatMessage(eventMessages.rerunDetectionCur)}</Menu.Item>
                <Menu.Item key="train_all">{intl.formatMessage(eventMessages.rerunTrainingAll)}</Menu.Item>
                <Menu.Item key="detect_all">{intl.formatMessage(eventMessages.rerunDetectionAll)}</Menu.Item>
              </>
            </Dropdown>
          )}
          <Button size="small" style={{ marginLeft: 8 }} onClick={this.showOverallInstanceChart}>
            {intl.formatMessage(eventMessages.overallLineChart)}
          </Button>
        </Container>

        <Container className="flex-row" style={{ padding: '0 16px 8px 16px' }}>
          <div className="flex-grow flex-row flex-center-align">
            <Select
              size="small"
              style={{ width: 150, marginLeft: 8 }}
              placeholder={intl.formatMessage(eventMessages.componentFilter)}
              showSearch
              optionFilterProp="value"
              filterOption
              value={componentFilter}
              onChange={this.handleComponentFilterChange}
              allowClear
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 680 }}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value} title={item.label}>
                    {item.label}
                  </Select.Option>
                ),
                this.hasComponentFilterOptions,
              )}
            </Select>
            <Select
              size="small"
              style={{ width: 150, marginLeft: 8 }}
              placeholder={intl.formatMessage(eventMessages.instanceFilter)}
              showSearch
              optionFilterProp="value"
              value={instanceFilter}
              onChange={this.handleInstanceFilterChange}
              allowClear
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 680 }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
              }
            >
              {R.map((item) => {
                const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item.label, {
                  pn: project?.projectShortName,
                  owner: project?.owner,
                });
                return (
                  <Select.Option key={item.value} title={item.label} label={instanceStr}>
                    {instanceStr}
                  </Select.Option>
                );
              }, this.hasInstanceFilterOptions)}
            </Select>
            <Select
              size="small"
              style={{ width: 150, marginLeft: 8 }}
              placeholder={intl.formatMessage(eventMessages.metricFilter)}
              showSearch
              optionFilterProp="value"
              filterOption
              value={metricFilter}
              onChange={this.handleMetricFilterChange}
              allowClear
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 680 }}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value} title={item.label}>
                    {item.label}
                  </Select.Option>
                ),
                this.hasMetricFilterOptions,
              )}
            </Select>
            <Input.Search
              size="small"
              placeholder={intl.formatMessage(eventMessages.patternIdSearch)}
              style={{ width: 160, marginLeft: 8 }}
              value={patternIdFilter}
              allowClear
              enterButton
              onSearch={(value) => this.handlePatternIdSearchChange(value)}
              onChange={(e) => this.setState({ patternIdFilter: e.target.value })}
            />
          </div>
          <Pagination
            size="small"
            current={page}
            total={this.eventList.length}
            pageSize={pageSize}
            onChange={(page) => this.setState({ page })}
            showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
            showSizeChanger
            pageSizeOptions={['20', '40', '60', '100', '500', '1000']}
            onShowSizeChange={(page, pageSize) => this.setState({ page, pageSize })}
          />
        </Container>
        <Container className="flex-grow flex-row" style={{ padding: '0 16px' }}>
          <AutoSizer>
            {({ width, height }) => (
              <div className="event-list">
                <div
                  className="event-list-header"
                  style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                >
                  <div className="header-column" style={{ width: 60 }} />
                  <div
                    className="header-column"
                    style={{ width: 100, flex: 1 }}
                    onClick={this.headerClick('component')}
                  >
                    <span>{intl.formatMessage(eventMessages.anomalyComponent)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'component')}
                  </div>
                  <div
                    className="header-column"
                    style={{ width: 100, flex: 1 }}
                    onClick={this.headerClick('instanceId')}
                  >
                    <span>{intl.formatMessage(eventMessages.anomalyInstance)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'instanceId')}
                  </div>
                  <div
                    className="header-column"
                    style={{ width: 100, flex: 1 }}
                    onClick={this.headerClick('rootCauseMetric')}
                  >
                    <span>{intl.formatMessage(eventMessages.anomalyMetric)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'rootCauseMetric')}
                  </div>
                  <div
                    className="header-column"
                    style={{ width: 100, flex: 1 }}
                    onClick={this.headerClick('patternId')}
                  >
                    <span>{intl.formatMessage(eventMessages.fieldAnomalyPattern)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'patternId')}
                  </div>
                  <div className="header-column" style={{ width: 100 }} onClick={this.headerClick('day')}>
                    <span>{intl.formatMessage(appFieldsMessages.Day)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'day')}
                  </div>
                  <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('startTimestamp')}>
                    <span>{intl.formatMessage(appFieldsMessages.time)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'startTimestamp')}
                  </div>
                  <div
                    className="header-column"
                    style={{ minWidth: 140, flex: 1 }}
                    onClick={this.headerClick('duration')}
                  >
                    <span>{intl.formatMessage(eventMessages.avgDuration)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'duration')}
                  </div>
                  <div className="header-column" style={{ minWidth: 120 }} onClick={this.headerClick('score')}>
                    <span>{intl.formatMessage(eventMessages.anomalyScore)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'score')}
                  </div>
                  <div className="header-column" style={{ width: userInfo.isReadUser ? 100 : 180 }} />
                </div>
                <List
                  ref={(listNode) => {
                    this.listNode = listNode;
                  }}
                  onScrollbarPresenceChange={({ horizontal, vertical }) => {
                    if (vertical) {
                      this.listNodeHeaderScrollbar = true;
                    } else {
                      this.listNodeHeaderScrollbar = false;
                    }
                    this.forceUpdate();
                  }}
                  className="event-list-grid"
                  width={width}
                  height={height - 40}
                  rowCount={eventList.length}
                  deferredMeasurementCache={this.cellMeasureCache}
                  rowHeight={this.cellMeasureCache.rowHeight}
                  rowRenderer={this.renderListItem(eventList)}
                />
              </div>
            )}
          </AutoSizer>
        </Container>

        {showTimeSelectModal && (
          <DateSelectModal
            timeSelectParams={timeSelectParams}
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
          />
        )}

        {showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={actionName}
            incident={activeIncident}
            project={project}
            projectName={projectName}
            instanceGroup={instanceGroup}
            eventType={activeIncident.isPrediction}
            onClose={() => this.setState({ showTakeLogActionModal: false })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {showEventActionModal && (
          <EventActionModal
            incident={activeIncident}
            project={project}
            projectName={projectName}
            instanceGroup={instanceGroup}
            onClose={() => this.setState({ showEventActionModal: false })}
          />
        )}
        {showIgnoreModal && (
          <IgnoreModal
            incident={activeIncident}
            projectName={projectName}
            onClose={(reload) =>
              this.setState({ showIgnoreModal: false }, () => {
                if (reload) this.reloadData(this.props);
              })
            }
          />
        )}
      </Container>
    );
  }
}

const EventList = injectIntl(EventListCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { userInfo, credentials } = state.auth;
    const { eventListMap } = state.metric;
    return { location, loadStatus, userInfo, credentials, eventListMap };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(EventList);
