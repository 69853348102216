import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postCopyProject = (credentials: Credentials, params: Object) => {
  return fetchPost(getEndpoint('copyproject'), {
    ...credentials,
    ...params,
  })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.error('[IF_API]', err);
      return err;
    });
};

export default postCopyProject;
