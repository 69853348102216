import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import { Spin } from 'antd';

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Modal,
  Popover,
  Table,
} from '../../../../src/lib/fui/react';
import { eventMessages } from '../../../../src/common/metric/messages';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

const FileDataModal = ({ activeRow, getCSVData, onClose, intl }: Object) => {
  const [loading, setLoading] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [fileDataList, setFileDataList] = useState([]);
  const dataTableNode = useRef(null);
  const { name, fileData } = activeRow;

  const getDataList = async () => {
    setLoading(true);
    const { fileDataList, headerList } = await getCSVData(fileData);
    setHeaderList(headerList);
    setFileDataList(R.take(200, fileDataList));
    setLoading(false);
    setTimeout(() => {
      cellMeasureCache.clearAll();
      if (dataTableNode.current) {
        dataTableNode.current.forceUpdate();
      }
    });
  };

  useEffect(() => {
    let cancel = false;
    if (cancel) return;
    getDataList();
    // eslint-disable-next-line consistent-return
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <Modal
      title={name}
      width={1000}
      bodyStyle={{ height: 560 }}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      footer={null}
    >
      <div style={{ marginBottom: 8 }}>
        {intl.formatMessage(eventMessages.showTheFirstNumLines, {
          num: 200,
        })}
        :{' '}
      </div>
      <Container style={{ height: 'calc(100% - 20px)', overflow: 'overlay hidden' }}>
        <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-width">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width <= headerList.length * 120 ? headerList.length * 120 : width}
                height={height}
                deferredMeasurementCache={cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={cellMeasureCache.rowHeight}
                rowCount={fileDataList.length}
                rowGetter={({ index }) => fileDataList[index]}
                headerClassName="flex-row flex-center-align"
                headerStyle={{ overflow: 'hidden' }}
                ref={(c) => {
                  dataTableNode.current = c;
                }}
              >
                {R.map((item) => {
                  return (
                    <Column
                      width={120}
                      flexGrow={1}
                      key={item}
                      label={item}
                      dataKey={item}
                      cellRenderer={({ dataKey, parent, rowIndex, cellData, columnIndex }) => {
                        return (
                          <CellMeasurer
                            cache={cellMeasureCache}
                            columnIndex={columnIndex}
                            key={dataKey}
                            parent={parent}
                            rowIndex={rowIndex}
                          >
                            <div className="hidden-line-with-ellipsis">{cellData}</div>
                          </CellMeasurer>
                        );
                      }}
                      headerRenderer={({ label }) => {
                        return (
                          <Popover title={null} content={label} placement="top" mouseEnterDelay={0.3}>
                            <span className="hidden-line-with-ellipsis">{label}</span>
                          </Popover>
                        );
                      }}
                    />
                  );
                }, headerList)}
              </Table>
            )}
          </AutoSizer>
        </Spin>
      </Container>
    </Modal>
  );
};

export default FileDataModal;
