/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const deleteBugIssue = (credentials: Credentials, params: Object) => {
  const { owner, id, dataChunkName } = params;
  return fetchPost(getEndpoint('removeissue'), {
    ...credentials,
    pdkey: id,
    fromUser: owner,
    dataChunkName,
  }).then(d => {
    return d;
  });
};

export default deleteBugIssue;
