/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { get, isString, isObject, isArray } from 'lodash';

const zipEndPointMap = (endPointMap) => {
  if (isObject(endPointMap)) {
    const { protocolName, address, port } = endPointMap;
    return `${protocolName}://${address}:${port}`;
  }
  return endPointMap;
};

const parseEndPointMapStr = (endPointMapStr) => {
  try {
    const protocolName = endPointMapStr.split(':')[0];
    const address = endPointMapStr.split('//')[1].split(':')[0];
    const port = endPointMapStr.split('//')[1].split(':')[1];
    return { protocolName, address, port };
  } catch (e) {
    return {};
  }
};

const EndPointParser = {
  getEndPointNameKey: (endPointList) => {
    if (isArray(endPointList) && endPointList.length > 0) {
      return { name: get(endPointList[0], 'name', ''), key: get(endPointList[0], 'key', '') };
    }
    return { name: '', key: '' };
  },
  zipEndPointList: (endPointList) => {
    if (isArray(endPointList)) {
      return R.join(';', R.map((i) => zipEndPointMap(i), endPointList));
    }
    return endPointList;
  },
  parserEndPointStr: (endPointStr, name, key) => {
    if (isString(endPointStr)) {
      return R.filter(
        (e) => Boolean(e),
        R.map((mstr) => {
          const res = parseEndPointMapStr(mstr);
          return name || key ? { ...res, name, key } : res;
        }, R.split(';', endPointStr)),
      );
    }
    return endPointStr;
  },
};

export default EndPointParser;
