import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { Link, Redirect } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { Button, Divider } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import type { State, Message } from '../../common/types';
import { Input, Select, Image } from '../../lib/fui/react';
import { hideAppLoader, createLoadAction } from '../../common/app/actions';
import { ActionTypes, userTypeCheck, idpUserLogin, clearCredentials } from '../../common/auth/actions';
import { buildLocation, parseQueryString, LoginRenderers, sleep, buildUrl } from '../../common/utils';

import { appFieldsMessages } from '../../common/app/messages';
import { authMessages } from '../../common/auth/messages';

import { CenterPage, LocaleSelector } from '../app/components';

type Props = {
  appLoaderVisible: boolean,
  credentials: Object,
  isChecked: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  isChecking: boolean,
  isLoggedIn: boolean,
  redirectLoginURL: String,
  redirectURL: String,
  intl: Object,
  push: Function,
  replace: Function,
  location: Object,
  hideAppLoader: Function,
  createLoadAction: Function,
  userTypeCheck: Function,
  idpUserLogin: Function,
  clearCredentials: Function,
  loginReason: Message,
  errorMessage: String,
  isExisted: Boolean,
  idpSettingMap: Object,
  idpAvailable: Boolean,
};

class UserTypeCheckCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const params = parseQueryString(location.search);
    const { state, error } = params;
    const loginRedirectURL = window.sessionStorage.getItem('loginRedirectURL');
    window.sessionStorage.removeItem('loginRedirectURL');
    this.state = {
      isChecking: false,
      loginRedirectURL,
      isLogging: false,

      idpType: 'Insightfinder',
      userName: '',
      hasError: false,
      idpState: state || null,
      error: error || null,
    };
    // this.idpTypeOptions = [
    //   { label: 'Insightfinder', value: 'Insightfinder' },
    //   { label: 'Azure AD', value: 'Azure' },
    //   { label: 'MS AD', value: 'MS' },
    //   { label: 'OpenLDAP', value: 'LDAPS' },
    // ];
  }

  componentDidMount() {
    if (!this.props.isLoggedIn && this.props.credentials) {
      this.props.clearCredentials();
    }
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    const { idpState } = this.state;
    if (idpState) {
      this.props.idpUserLogin(idpState);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { replace, push, credentials, isExisted, redirectLoginURL, redirectURL } = nextProps;
    if (
      nextProps.isChecked !== this.props.isChecked ||
      nextProps.redirectLoginURL !== this.props.redirectLoginURL ||
      nextProps.redirectURL !== this.props.redirectURL ||
      nextProps.credentials !== this.props.credentials
    ) {
      const { userName } = this.state;
      const isIdpUser = redirectLoginURL && redirectLoginURL.indexOf('checkidpuser') > 0;
      if (credentials && !R.isEmpty(credentials)) {
        replace(buildLocation('/', {}));
      } else if (redirectURL) {
        window.location.href = redirectURL;
      } else if (isExisted && redirectLoginURL) {
        push(buildLocation('/auth/login2', {}, { userName, isIdpUser }));
      }
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  handleNext(e) {
    e.preventDefault();
    const { userName, idpType, timezone } = this.state;
    this.setState({ isChecking: true });
    this.props.userTypeCheck(
      userName,
      { idpType: idpType && idpType !== 'Insightfinder' ? idpType : undefined, timezone },
      () => this.setState({ isChecking: false }),
    );
  }

  @autobind
  handleEnterSubmit(e) {
    const { userName, idpType, timezone } = this.state;
    if (e.key === 'Enter' && userName) {
      e.preventDefault();
      this.setState({ isChecking: true });
      this.props.userTypeCheck(
        userName,
        { idpType: idpType && idpType !== 'Insightfinder' ? idpType : undefined, timezone },
        () => this.setState({ isChecking: false }),
      );
    }
  }

  @autobind
  async responseGoogle(response) {
    const { clientId, credential } = response || {};
    if (clientId && credential) {
      this.setState({ isLogging: true });
      await sleep(300);

      const { createLoadAction } = this.props;
      const params = {
        idpType: 'Google',
        // eslint-disable-next-line camelcase
        idpInfo: JSON.stringify({ clientId, id_token: credential }),
      };
      createLoadAction(ActionTypes.SINGLESIGNINLOGIN, params, false, false, this.callbackHandleLoading);
    }
  }

  @autobind
  callbackHandleLoading() {
    // reset reloadSystem
    this.setState({ isLogging: false });
  }

  @autobind
  idpTypeCallback(idpType) {
    this.setState({ idpType });
  }

  render() {
    const {
      intl,
      push,
      location,
      isLoggedIn,
      loginReason,
      errorMessage,
      isExisted,
      idpSettingMap,
      idpAvailable,
      currentTheme,
    } = this.props;
    const { isChecking, error, loginRedirectURL, isLogging } = this.state;
    let { hasError } = this.state;

    // const idpTypeLink = VLink.state(this, 'idpType').check(
    //   (x) => x,
    //   intl.formatMessage(appFieldsMessages.inputRequired),
    // );
    const userNameLink = VLink.state(this, 'userName').check(
      (x) => x,
      intl.formatMessage(authMessages.errorsUserNameRequired),
    );
    const from = get(location, 'state.from', '/');

    if (isLoggedIn) {
      if (from && from.pathname && from.pathname === '/account-info') {
        return <Redirect to="/" />;
      }
      if (loginRedirectURL) {
        return <Redirect to={loginRedirectURL} />;
      }
      return <Redirect to={from} />;
    }
    hasError =
      hasError || errorMessage || loginReason || error || (!isExisted && idpSettingMap) || idpAvailable === false;
    return (
      <CenterPage
        intl={intl}
        push={push}
        idpTypeCallback={this.idpTypeCallback}
        className="auth"
        currentTheme={currentTheme}
      >
        <form className={`ui ${hasError ? 'error' : ''} form`} style={{ width: 320 }}>
          {/* <div className="flex-row flex-center-align flex-space-around" style={{ marginBottom: 5 }}> */}

          <div style={{ marginBottom: 10, textAlign: 'center' }}>
            <UserAddOutlined style={{ fontSize: 20 }} />
            <span style={{ margin: '0 8px' }}>{intl.formatMessage(authMessages.hintsNewUser)}</span>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                push(buildUrl('/auth/signup', {}, {}));
              }}
            >
              {intl.formatMessage(authMessages.register)}
            </Button>
          </div>
          {/* </div> */}

          {idpAvailable === false && (
            <div className="ui error message"> {intl.formatMessage(authMessages.errorsUserIdpAvailable)}</div>
          )}
          {(errorMessage || loginReason) && (
            <div className="ui error message">{errorMessage || intl.formatMessage(loginReason)}</div>
          )}
          {error && <div className="ui error message">{intl.formatMessage(authMessages.errorsWrongCredential)}</div>}
          {!isExisted && idpSettingMap && (
            <div className="ui error message">{intl.formatMessage(authMessages.errorsUserNotExist)}</div>
          )}

          {/* <div className="input field required">
            <label htmlFor="input-idpType">{intl.formatMessage(appFieldsMessages.idpType)}</label>
            <Select
              id="input-idpType"
              disabled={isChecking || isLogging}
              valueLink={idpTypeLink}
              options={this.idpTypeOptions}
            />
          </div> */}
          <div className="input field required">
            <label htmlFor="input-userName">{intl.formatMessage(appFieldsMessages.userName)}</label>
            <Input
              id="input-userName"
              disabled={isChecking || isLogging}
              valueLink={userNameLink}
              icon="user icon"
              onKeyPress={this.handleEnterSubmit}
            />
          </div>
          <div className="field">
            <Button
              type="primary"
              style={{ width: '100%' }}
              loading={isChecking}
              disabled={isLogging}
              onClick={this.handleNext}
            >
              {intl.formatMessage(authMessages.buttonsNext)}
            </Button>
          </div>

          <div className="field" style={{}}>
            <span style={{ marginRight: 8 }}>{intl.formatMessage(authMessages.hintsForgot)}</span>
            <Link tabIndex={-1} to="/auth/forgotPassword">
              {intl.formatMessage(authMessages.hintsPassword)}
            </Link>
            <span style={{ margin: '0 4px' }} />
            <Link tabIndex={-1} to="/auth/forgotUsername">
              {intl.formatMessage(authMessages.hintsUserName)}
            </Link>
          </div>

          <Divider style={{ margin: '16px 0' }} />
          <LoginRenderers.GoogleLogin width={320} onGoogleSignIn={this.responseGoogle} />

          <div className="field flex-row flex-space-between" style={{ marginTop: 10 }}>
            <div>
              <span style={{ marginRight: 8 }}>{intl.formatMessage(authMessages.hintsQuestions)}</span>
              <Button type="link" href="https://insightfinder.com/contact" style={{ padding: 0 }}>
                {intl.formatMessage(authMessages.contactSupport)}
              </Button>
            </div>
            <LocaleSelector style={{ marginBottom: 10 }} />
          </div>
        </form>
      </CenterPage>
    );
  }
}

const UserTypeCheck = injectIntl(UserTypeCheckCore);
export default connect(
  (state: State) => ({
    appLoaderVisible: state.app.appLoaderVisible,
    location: state.router.location,
    isExisted: state.auth.existed,
    idpSettingMap: state.auth.idpSettingMap,
    isChecked: state.auth.checked,
    isChecking: state.auth.checking,
    loginReason: state.auth.loginReason,
    errorMessage: state.auth.errorMessage,
    isLoggedIn: state.auth.loggedIn,
    redirectLoginURL: state.auth.redirectLoginURL,
    redirectURL: state.auth.redirectURL,
    currentTheme: state.app.currentTheme,
    credentials: state.auth.credentials,
    idpAvailable: state.auth.idpAvailable,
  }),
  { replace, push, userTypeCheck, idpUserLogin, hideAppLoader, clearCredentials, createLoadAction },
)(UserTypeCheck);
