import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Alert, Button, Card, DatePicker, Form, Input, Select } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';

import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  credentials: Object,
};

class AzureCloudCostSetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const state = {
      verifiedMessage: '',
      verified: false,
      isVerifying: false,
      tenantId: '',
      clientId: '',
      clientSecret: '',
      billingAccountId: '',
      samplingInterval: 1,
      collectionFrequency: 1,
      secondsUnit: 1440,
      millisecondUnit: 3600000,
      historicalDates: [null, null],
    };

    this.state = {
      ...state,
    };

    this.secondsUnitOption = [{ label: 'day', value: 1440 }];
    this.millisecondUnitOption = [{ label: 'hour', value: 3600000 }];
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isVerifying: true });
    const { intl, credentials } = this.props;
    const { tenantId, clientId, clientSecret, billingAccountId } = this.state;
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: 'AzureCost',
      tenantId,
      clientId,
      clientSecret,
      billingAccountId,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
              samplingInterval: 1,
              collectionFrequency: 1,
            },
            () => {
              this.props.saveProjectInfo('AzureCost', {}, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('AzureCost', {}, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
          },
          () => {
            this.props.saveProjectInfo('AzureCost', {}, this.state);
          },
        );
      });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      tenantId,
      clientId,
      clientSecret,
      billingAccountId,
      samplingInterval,
      secondsUnit,
      collectionFrequency,
      millisecondUnit,
      historicalDates,
    } = this.state;

    this.props.createProject(
      'AzureCost',
      {
        operation: 'register',
        tenantId,
        clientId,
        clientSecret,
        billingAccountId,
        samplingInterval: Number(samplingInterval) * secondsUnit,
        collectionFrequency: Number(collectionFrequency) * millisecondUnit,
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
      },
      this.state,
    );
  }

  render() {
    const { intl, hasError, isLoading } = this.props;
    const {
      verifiedMessage,
      verified,
      isVerifying,
      tenantId,
      clientId,
      clientSecret,
      billingAccountId,
      samplingInterval,
      secondsUnit,
      collectionFrequency,
      millisecondUnit,
      historicalDates,
    } = this.state;
    const hasVerifyError = !tenantId || !clientId || !clientSecret || !billingAccountId;
    const hasErrorRegister = hasVerifyError || hasError;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.AzureCost),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item
              label="Tenant ID"
              validateStatus={!tenantId ? 'error' : 'success'}
              help={!tenantId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={tenantId}
                onChange={(e) => this.setState({ tenantId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client ID"
              validateStatus={!clientId ? 'error' : 'success'}
              help={!clientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={clientId}
                onChange={(e) => this.setState({ clientId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client Secret"
              validateStatus={!clientSecret ? 'error' : 'success'}
              help={!clientSecret ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={clientSecret}
                onChange={(e) => this.setState({ clientSecret: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Billing Account ID"
              validateStatus={!billingAccountId ? 'error' : 'success'}
              help={!billingAccountId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={billingAccountId}
                onChange={(e) => this.setState({ billingAccountId: e.target.value })}
              />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>

        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.secondsUnitOption}
                  value={secondsUnit}
                  onChange={(secondsUnit) => this.setState({ secondsUnit })}
                />
              </div>
            </Form.Item>
            <Form.Item
              label="Collection Frequency"
              validateStatus={!collectionFrequency ? 'error' : 'success'}
              help={!collectionFrequency ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={collectionFrequency}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ collectionFrequency: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.millisecondUnitOption}
                  value={millisecondUnit}
                  onChange={(millisecondUnit) => this.setState({ millisecondUnit })}
                />
              </div>
            </Form.Item>
            <Form.Item label="Historical Date Range">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={historicalDates}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(historicalDates) => this.setState({ historicalDates })}
              />
            </Form.Item>
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasErrorRegister}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const AzureCostSetting = injectIntl(AzureCloudCostSetting);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(AzureCostSetting);
