import React from 'react';
import * as R from 'ramda';
import { get, isFunction } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { replace } from 'react-router-redux';
import { SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Radio, Row, Select } from 'antd';
import { Modal, Container, Popover } from '../../../lib/fui/react';
import { appButtonsMessages } from '../../../common/app/messages';
import SelectMultiple from '../../../../components/custome/SelectMultiple';

type Props = {
  system: Object,
  onConfirm: Function,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  incidents: Array,
  isAdmin: Boolean,
  userName: String,
  activeKey: String,
};

class ProjectSelectorCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { system, incidents, activeKey, isAdmin, userName } = props;
    const projectNameSet = get(system, ['projectNameSet'], []);
    const globaInstaceMappingProject = get(system, ['globaInstaceMappingProject'], {});

    let metricProjects = R.filter(
      (project) =>
        project.dataType === 'Metric' &&
        project.projectNameReal.indexOf('frequency') < 0 &&
        project.projectName.indexOf('frequency') < 0 &&
        project.projectDisplayName.indexOf('frequency') < 0,
      projectNameSet,
    );
    metricProjects = R.sortWith([R.descend(R.compose(R.toLower, R.prop('projectName')))], metricProjects);
    if (incidents && activeKey === 'incident') {
      R.forEach((incident) => {
        const { projectOwner } = incident;
        let { projectName } = incident;
        projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
        const findIdx = R.findIndex((item) => projectName === item.projectNameReal, metricProjects || []);
        if (findIdx !== -1) {
          const peojectOwnsTheIncident = (metricProjects || [])[findIdx];
          const otherPeoject = R.remove(findIdx, 1, metricProjects || []);
          metricProjects = [{ ...peojectOwnsTheIncident, hasOwnsTheIncident: true }, ...otherPeoject];
        }
      }, incidents);
    }

    const mappingProjectMap = get(globaInstaceMappingProject, system.id, {});
    const instanceList = R.keys(mappingProjectMap || {});
    let findInstance = [];
    R.forEach((incident) => {
      let instace1 = incident?.realInstanceName || '';
      instace1 = R.includes('_', instace1) ? R.split('_', instace1)[1] : instace1;
      let instace2 = incident?.instanceListStr || incident?.sourceInstanceName || '';
      instace2 = R.includes('_', instace2) ? R.split('_', instace2)[1] : instace2;
      const curFindInstance = R.filter(
        (ins) =>
          (instace1 && instace1 === ins) ||
          (instace2 && instace2 === ins) ||
          (instace1 && R.includes(ins, instace1)) ||
          (instace1 && R.includes(instace1, ins)) ||
          (instace2 && R.includes(ins, instace2)) ||
          (instace2 && R.includes(instace2, ins)),
        instanceList || [],
      );
      findInstance = R.uniq([...findInstance, ...curFindInstance]);
    }, incidents || []);

    const mappingProjectInfos = {};
    R.forEach((ins) => {
      const mappingProjects = get(mappingProjectMap, ins, []);
      const projects = R.filter((p) => p.instanceName === ins && p.dataType === 'Metric', mappingProjects);
      R.forEach((p) => {
        const projectInfo = R.find(
          (item) =>
            item.projectNameReal ===
            (isAdmin || p.userName !== userName ? `${p.projectName}@${p.userName}` : p.projectName),
          metricProjects || [],
        );
        if (!projectInfo) {
          return;
        }
        if (!mappingProjectInfos[projectInfo.projectNameReal]) {
          mappingProjectInfos[projectInfo.projectNameReal] = { ...p, ...projectInfo, ins: [] };
        }
        mappingProjectInfos[projectInfo.projectNameReal].ins.push(ins);
      }, projects || []);
    }, findInstance || []);

    const mappingProjectVals = R.sortWith([R.descend(R.prop('hasOwnsTheIncident'))])(
      R.map(
        (item) => ({ ...item, hasOwnsTheIncident: item.hasOwnsTheIncident || false }),
        R.values(mappingProjectInfos),
      ),
    );
    const allProjectName = mappingProjectVals[0]?.projectNameReal || '';

    this.state = {
      allProjectName,
      intsanceValues: R.find((e) => e.projectNameReal === allProjectName, mappingProjectVals)?.ins || [],
      metricProjects: mappingProjectVals,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleProjectChange(projectName) {
    const intsanceValues = R.find((e) => e.projectNameReal === projectName, this.state.metricProjects)?.ins || [];
    this.setState({ allProjectName: projectName, intsanceValues });
  }

  @autobind
  handleInstanceChange(intsanceValues) {
    this.setState({ intsanceValues });
  }

  render() {
    const { intl, onClose, onConfirm } = this.props;
    const { allProjectName, metricProjects, intsanceValues } = this.state;

    const showError = !allProjectName && metricProjects.length === 0;
    const findProject = R.find((p) => p.projectNameReal === allProjectName, metricProjects || []);
    return (
      <Modal width={650} title="Select project" onCancel={() => onClose()} footer={null} visible maskClosable={false}>
        {showError && <Alert message="The selected instance is not found!" type="error" style={{ marginBottom: 8 }} />}
        <Container className="flex-col content">
          <div className="flex-row flex-center-align" style={{ marginBottom: 24 }}>
            <span className="label" style={{ width: 150, fontWeight: 'bold', marginLeft: 20 }}>
              Metric Projects:
            </span>
            <Radio.Group
              onChange={(e) => this.handleProjectChange(e.target.value)}
              value={allProjectName}
              className="flex-grow flex-min-width"
            >
              <Row style={{ maxHeight: 200, overflowY: 'auto' }}>
                {R.map((item) => {
                  const title = `${item.projectDisplayName}${item.hasOwnsTheIncident ? ' (Incident project)' : ''}`;
                  return (
                    <Col
                      span={24}
                      key={item.projectNameReal}
                      className="flex-row flex-center-align"
                      style={{ marginTop: 4, height: 25 }}
                    >
                      <Radio key={item.projectNameReal} value={item.projectNameReal} />
                      <span className="flex-grow flex-row flex-center-align overflow-hidden full-height">
                        <Popover content={title} mouseEnterDelay={0.3} placement="topLeft" trigger="hover">
                          <span
                            className="hidden-line-with-ellipsis flex-grow full-height font-12"
                            style={{ lineHeight: '25px' }}
                          >
                            {title}
                          </span>
                        </Popover>
                      </span>
                    </Col>
                  );
                }, metricProjects || [])}
              </Row>
            </Radio.Group>
          </div>
          <div className="flex-row flex-center-align" style={{ marginBottom: 24 }}>
            <span className="label" style={{ width: 150, fontWeight: 'bold', marginLeft: 20 }}>
              Instances:
            </span>
            <SelectMultiple
              style={{ width: 200 }}
              value={intsanceValues || []}
              onChange={this.handleInstanceChange}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 450 }}
              options={R.map((item) => ({ value: item, label: item }), findProject?.ins || [])}
            />
          </div>
          <div className="flex-row">
            <div className="flex-grow" />
            <Button
              type="primary"
              size="small"
              disabled={showError || (intsanceValues || []).length <= 0}
              icon={<SaveOutlined />}
              onClick={() =>
                isFunction(onConfirm)
                  ? onConfirm(allProjectName, {
                      ins: intsanceValues,
                      cms: findProject?.cms,
                    })
                  : null
              }
            >
              {intl.formatMessage(appButtonsMessages.confirm)}
            </Button>
          </div>
        </Container>
      </Modal>
    );
  }
}

const ProjectSelector = injectIntl(ProjectSelectorCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { isAdmin, userName } = state.auth.userInfo;
    return {
      location,
      isAdmin,
      userName,
    };
  },
  { replace },
)(ProjectSelector);
