/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import loadGlobalHealthLogic from './loadGlobalHealthLogic';
import loadGlobalCapacityPlanningLogic from './loadGlobalCapacityPlanningLogic';
import loadGlobalSystemHealthLogic from './loadGlobalSystemHealthLogic';
import loadGlobalSystemInstanceHealthLogic from './loadGlobalSystemInstanceHealthLogic';
import loadGlobalSystemHealthChartLogic from './loadGlobalSystemHealthChartLogic';
import loadGlobalSystemInstancesLogic from './loadGlobalSystemInstancesLogic';
import loadGlobalTopAnomalyKeywordsLogic from './loadGlobalTopAnomalyKeywordsLogic';
import loadGlobalSystemLevelTimelinesLogic from './loadGlobalSystemLevelTimelinesLogic';
import loadGlobalSystemIncidentTimelinesLogic from './loadGlobalSystemIncidentTimelinesLogic';
import loadGlobalSystemAnomalyTimelinesLogic from './loadGlobalSystemAnomalyTimelinesLogic';
import loadGlobalPredictedAnomalyTimelinesLogic from './loadGlobalPredictedAnomalyTimelinesLogic';
import loadGlobalSystemAlertTimelinesLogic from './loadGlobalSystemAlertTimelinesLogic';
import loadGlobalSystemDetectIncidentTimelinesLogic from './loadGlobalSystemDetectIncidentTimelinesLogic';
import loadGlobalSystemZoomTimelinesLogic from './loadGlobalSystemZoomTimelinesLogic';
import loadGlobalKeywordsEventsLogic from './loadGlobalKeywordsEventsLogic';
import loadGlobalSystemCausalGroupLogic from './loadGlobalSystemCausalGroupLogic';
import loadPatternNameMapLogic from './loadPatternNameMapLogic';
import loadGlobalInsightsWidgetsConfigLogic from './loadGlobalInsightsWidgetsConfigLogic';
import loadGlobalSystemAlertStatisticsLogic from './loadGlobalSystemAlertStatisticsLogic';
import loadMatchedIncidentPredictionsLogic from './loadMatchedIncidentPredictionsLogic';
import loadGlobalSystemDeployTimelinesLogic from './loadGlobalSystemDeployTimelinesLogic';
import loadGlobalSystemTraceTimelinesLogic from './loadGlobalSystemTraceTimelinesLogic';

// version2
import loadIncidentPredictionTimelinesLogic from './loadIncidentPredictionTimelinesLogic';
import loadRootCauseTimelinesLogic from './loadRootCauseTimelinesLogic';
import loadAlertCauseTimelinesLogic from './loadAlertCauseTimelinesLogic';

export default [
  loadGlobalHealthLogic,
  loadGlobalCapacityPlanningLogic,
  loadGlobalSystemHealthLogic,
  loadGlobalSystemInstanceHealthLogic,
  loadGlobalSystemHealthChartLogic,
  loadGlobalSystemInstancesLogic,
  loadGlobalTopAnomalyKeywordsLogic,
  loadGlobalSystemLevelTimelinesLogic,
  loadGlobalSystemIncidentTimelinesLogic,
  loadGlobalSystemAnomalyTimelinesLogic,
  loadGlobalPredictedAnomalyTimelinesLogic,
  loadGlobalSystemAlertTimelinesLogic,
  loadGlobalSystemDetectIncidentTimelinesLogic,
  loadGlobalSystemZoomTimelinesLogic,
  loadGlobalKeywordsEventsLogic,
  loadGlobalSystemCausalGroupLogic,
  loadGlobalInsightsWidgetsConfigLogic,
  loadMatchedIncidentPredictionsLogic,

  loadGlobalSystemAlertStatisticsLogic,
  loadIncidentPredictionTimelinesLogic,
  loadRootCauseTimelinesLogic,
  loadAlertCauseTimelinesLogic,
  loadPatternNameMapLogic,
  loadGlobalSystemDeployTimelinesLogic,
  loadGlobalSystemTraceTimelinesLogic,
];
