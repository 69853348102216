/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import { get } from 'lodash';
import { createLogic } from 'redux-logic';

import { BaseUrls } from '../../../web/app/Constants';
import { login as loginApi, getInitData, getDisplayedMenus, getUserMissingInfo } from '../../apis';
import {
  ActionTypes as AppActionTypes,
  setInitData,
  appError,
  showAppModal,
  updateLastActionInfo,
  createSetAction,
} from '../../app/actions';
import { ActionTypes, loginSuccess, loginPending, loginMessage } from '../actions';
import { loginErrorHandler } from '../../errors';
import { appMessages } from '../../app/messages';
import { buildUrl, getLoaderAction, Defaults } from '../../utils';
import { shouldRunAwesomeTours } from './utils';

const loginLogic = createLogic({
  type: [ActionTypes.LOGIN],
  cancelType: AppActionTypes.APP_STOP,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const { showLoader, hideLoader } = getLoaderAction();
    const { isIdpUser, userName } = action.payload;
    window.localStorage.removeItem('refreshTokenFnTime');
    // Just redirect if idpUser
    if (isIdpUser) {
      loginApi(action.payload)
        .then((data) => {
          const { redirectURL, message } = data;
          if (!redirectURL) {
            dispatch(loginMessage(message));
            loginErrorHandler({ message }, dispatch, done, userName, true);
          } else {
            dispatch(loginPending(redirectURL));
          }
        })
        .catch((err) => {
          dispatch(loginMessage(err?.message));
          loginErrorHandler(err, dispatch, done, userName);
        });
    } else {
      loginApi(action.payload)
        .then(async (data) => {
          const { credentials, userInfo } = data;
          dispatch(showLoader);
          const possibleMenu = await getDisplayedMenus(credentials);
          const displayedMenus = get(possibleMenu, [credentials.userName], Defaults.DisplayedMenus);

          // get user missing info
          const userMissingInfo = await getUserMissingInfo(credentials);
          const { needsInput } = userMissingInfo || {};

          if (needsInput) {
            dispatch(
              createSetAction(
                AppActionTypes.SET_USER_MISSING_INFO,
                {},
                { needInputUserMissingInfo: needsInput, userMissingInfo },
              ),
            );
          } else if (userInfo.isFirstLogin) {
            dispatch(
              createSetAction(
                AppActionTypes.SET_AWESOME_TOURS,
                {},
                {
                  runAwesomeTours: shouldRunAwesomeTours(userInfo.isFirstLogin),
                  toursState: {
                    stepIndex: 0,
                    url: buildUrl(
                      BaseUrls.GlobalHealth,
                      {},
                      { startTime: Defaults.demoDay(), endTime: Defaults.demoDay(), customerName: 'demoUser' },
                    ),
                  },
                },
              ),
            );
          }
          dispatch(updateLastActionInfo());

          // Load the init
          getInitData(credentials, userInfo)
            .then((data) => {
              dispatch(setInitData(data));
              if (data.licenseValid === false) {
                dispatch(showAppModal('warning', { message: appMessages.licenseValidFaild }));
              }
            })
            .catch((err) => {
              dispatch(appError(appMessages.errorsServer, err));
            })
            .then(() => {
              dispatch(loginSuccess(credentials, { ...userInfo, displayedMenus }));
              dispatch(hideLoader);
              done();
            });
        })
        .catch((err) => {
          dispatch(loginMessage(err?.message));
          loginErrorHandler(err, dispatch, done, userName);
        });
    }
  },
});

export default loginLogic;
