import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { sortableHandle } from 'react-sortable-hoc';
import { Spin, Popover, Empty, message } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { Defaults, buildUrl, LogRenderers } from '../../../common/utils';
import { MoveIcon } from '../../../lib/fui/icons';

import { eventMessages } from '../../../common/metric/messages';
import { appFieldsMessages, appMessages } from '../../../common/app/messages';

const DragHandle = sortableHandle(() => (
  <span className="hover-display-item" style={{ cursor: 'move', position: 'absolute', right: 8, top: 8 }}>
    <MoveIcon style={{ fontSize: 16 }} />
  </span>
));

const InsightsIncidentPatternStats = (props) => {
  // eslint-disable-next-line
  const { intl, name, currentTheme } = props;
  const {
    // eslint-disable-next-line
    push,
    // eslint-disable-next-line
    updateLastActionInfo,
    // eslint-disable-next-line
    projects,
    // eslint-disable-next-line
    globalInfo,
    // eslint-disable-next-line
    credentials,
    // eslint-disable-next-line
    userInfo,

    // eslint-disable-next-line
    refresh,
    // eslint-disable-next-line
    refreshTime,
    // eslint-disable-next-line
    environmentId,
    // eslint-disable-next-line
    systemId,
    // eslint-disable-next-line
    customerName,
    // eslint-disable-next-line
    startTime,

    // eslint-disable-next-line
    isLoadingStats,
    // eslint-disable-next-line
    incidentPatternStats,
  } = props;
  const [patternNameMap, setPatternNameMap] = useState({});

  const environment = R.find((e) => e.id === environmentId, globalInfo || []);
  const systemList = get(environment, 'systemList', []);
  const systemInfo = R.find((system) => system.id === systemId, systemList);
  const { ownerUserName, projectNameSet } = systemInfo || {};
  const projectDisplayMap = {};
  R.forEach((item) => {
    projectDisplayMap[item.projectName] = item.projectDisplayName;
  }, projectNameSet || []);

  const handlePatternClick = (item) => {
    const { timepair } = item || {};
    if (timepair && timepair.length > 0) {
      const { timestamp } = timepair[timepair.length - 1];
      const day = moment.utc(timestamp).format(Defaults.DateFormat);

      const query = {
        environmentId,
        systemId,
        customerName,
        startTime: day,
        endTime: day,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    }
  };

  return (
    <div
      className="full-height full-width flex-col health-card hover-display"
      style={{ backgroundColor: 'white', borderRadius: '2em', padding: '8px 8px', position: 'relative' }}
    >
      <div
        className="flex-row flex-center-justify bold link"
        style={{ textAlign: 'center', margin: '0 14px 8px 14px', height: 34 }}
        onClick={() => {
          push(buildUrl(BaseUrls.GlobalSystemRootCause, {}, { startTime, customerName, environmentId, systemId }));
        }}
      >
        {name}
      </div>
      <DragHandle />

      <div className="flex-grow flex-col flex-min-height" style={{ padding: '0px 8px' }}>
        <Spin
          spinning={isLoadingStats}
          wrapperClassName="spin-full-height full-width overflow-y-auto overflow-x-hidden"
        >
          {(incidentPatternStats || []).length === 0 && (
            <div className="full-height flex-col flex-center-align flex-center-justify">
              <Empty imageStyle={{ height: 60 }} />
            </div>
          )}
          {(incidentPatternStats || []).length > 0 &&
            R.addIndex(R.map)((item, index) => {
              let color = '#797D92';
              switch (index) {
                case 0:
                  color = '#FF5142';
                  break;
                case 1:
                  color = '#FF8A61';
                  break;
                case 2:
                  color = '#FEB207';
                  break;
                default:
                  break;
              }
              return (
                <div
                  key={index}
                  className="flex-row flex-center-align clickable"
                  style={{ lineHeight: '28px', fontSize: 14 }}
                  onClick={() => handlePatternClick(item)}
                >
                  <div className="circle" style={{ marginRight: 16, background: color }}>
                    <div className="circle-text">{index + 1}</div>
                  </div>
                  <div className="flex-grow flex-min-width">
                    <Popover
                      title={null}
                      content={
                        <RendererPatternTitle
                          intl={intl}
                          item={item}
                          patternNameMap={patternNameMap}
                          setPatternNameMap={setPatternNameMap}
                          credentials={credentials}
                          updateLastActionInfo={updateLastActionInfo}
                          ownerUserName={ownerUserName}
                          projectDisplayMap={projectDisplayMap}
                          currentTheme={currentTheme}
                        />
                      }
                      placement="right"
                      mouseEnterDelay={0.3}
                    >
                      <div className="max-width flex-row">
                        <div className="flex-min-width hidden-line-with-ellipsis inline-block">
                          {item.componentName}
                        </div>
                        <div style={{ paddingLeft: 4 }}>[{item.patternId}]</div>
                      </div>
                    </Popover>
                  </div>
                  <div className="flex-row flex-end-justify" style={{ minWidth: 40, color, margin: '0 8px 0 4px' }}>
                    {item.patternCount}
                  </div>
                </div>
              );
            }, incidentPatternStats)}
        </Spin>
      </div>
    </div>
  );
};

type PropsRendererPatternTitle = {
  intl: Object,
  item: Object,
  patternNameMap: Object,
  setPatternNameMap: Function,
  credentials: Object,
  updateLastActionInfo: Function,
  ownerUserName: String,
  projectDisplayMap: Object,
  currentTheme: String,
};

const RendererPatternTitle = ({
  intl,
  item,
  patternNameMap,
  setPatternNameMap,
  credentials,
  updateLastActionInfo,
  ownerUserName,
  projectDisplayMap,
  currentTheme,
}: PropsRendererPatternTitle) => {
  const { key, patternId, projectName, componentName, instanceName, description: rawData, timepair } = item;
  const [patternName, setPatternName] = useState(get(patternNameMap, [key]));
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (ownerUserName && patternName === undefined) {
      setLoading(true);
      updateLastActionInfo();
      fetchGet(getEndpoint('getpatternname', 1), {
        ...credentials,
        customerName: ownerUserName,
        projectName: ownerUserName !== credentials.userName ? `${projectName}@${ownerUserName}` : projectName,
        instanceName,
        patternId,
      })
        .then((data) => {
          const { patternName } = data;
          patternNameMap[key] = patternName || '';

          setPatternNameMap(patternNameMap);
          setLoading(false);
          setPatternName(patternName || '');
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  let rawDataJson;
  try {
    rawDataJson = JSON.parse(rawData);
  } catch (error) {
    // console.debug(error);
  }
  return (
    <div>
      {loading && <Spin size="small" />}
      {!loading && (
        <div className="flex-col overflow-y-auto " style={{ maxWidth: 480, maxHeight: 350, paddingRight: 8 }}>
          <div className="flex-row">
            <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.projectName)}:</div>
            <div className="flex-grow">{get(projectDisplayMap, projectName, projectName)}</div>
          </div>
          <div className="flex-row">
            <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.componentName)}:</div>
            <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
              {componentName}
            </div>
          </div>
          <div className="flex-row">
            <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.instanceName)}:</div>
            <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
              {instanceName}
            </div>
          </div>

          <div className="flex-row">
            <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.patternId)}:</div>
            <div className="flex-grow">{patternId}</div>
          </div>
          <div className="flex-row">
            <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.patternName)}:</div>
            <div className="flex-grow">{patternName}</div>
          </div>
          <div className="flex-row">
            <div style={{ fontWeight: 'bold', minWidth: 120 }}>
              {intl.formatMessage(eventMessages.shortDescription)}:
            </div>
            <div className="flex-grow" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              {rawData &&
                !rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                )}
              {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
            </div>
          </div>
          <div className="flex-row">
            <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(appFieldsMessages.time)}:</div>
            <div className="flex-grow flex-col">
              {R.map((tp) => {
                return (
                  <div className="flex-row" key={String(tp.timestamp)}>
                    <div className="light-label bold" style={{ width: 100 }}>
                      {moment.utc(tp.timestamp).format(Defaults.ShortTimeFormat)}
                    </div>
                    <div>{`Count: ${tp.count}`}</div>
                  </div>
                );
              }, timepair || [])}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InsightsIncidentPatternStats;
