import React, { useEffect, useState } from 'react';
import { Spin, Tag } from 'antd';
import * as R from 'ramda';

import { Popover } from '../../../lib/fui/react';

import { PopoverContentHtml } from './OptionHtml';

import { appFieldsMessages } from '../../../common/app/messages';
import { getPatternNameIcon } from '../../../common/utils';

const fullListTitle = {
  anomalies: 'anomalies',
  incidents: 'incidents',
  alerts: 'alerts',
};

const BuildIndicateInfo = ({
  list,
  style,
  groupStyle,
  type,
  echartRef,
  chartData,
  optionDetail,
  echartsAxiosDebugger,
  getOptionDetail,
  setEchartsAxiosDebugger,
  intl,
  currentTheme,
  instanceDisplayNameMap,
}: Object) => {
  const [newList, setNewList] = useState(list || []);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) {
      setTimeout(() => setRefresh({ refresh: false }));
    }
  }, [refresh]);

  useEffect(() => {
    const metricList = R.filter((item) => item.dataType === 0, list || []);
    const logList = R.filter((item) => item.dataType === 1, list || []);
    let newMetricList = [];
    let newLogList = [];

    newMetricList = R.take(2, metricList);
    newLogList = R.take(2, logList);

    if (metricList.length < 2) {
      newMetricList = metricList;
      newLogList = R.take(4 - metricList.length, logList);
    }
    if (logList.length < 2) {
      newLogList = logList;
      newMetricList = R.take(4 - logList.length, metricList);
    }

    const arr = R.sort((a, b) => b.count - a.count, [...newMetricList, ...newLogList]);
    setNewList(R.map((item) => ({ ...item, loading: false }), arr || []));
  }, [list]);

  const mouseLinkageCharts = ({ type, item, mouse }) => {
    if (echartRef) {
      const detailKey = `${item.instanceName}-${item.patternId}`;
      const { data } = chartData?.series[0] || [];
      const dataIndex = R.findIndex((item) => `${item.instanceName}-${item.patternId}` === detailKey, data);
      const echartsInstance = echartRef.getEchartsInstance();
      echartsInstance.dispatchAction({ type: mouse === 'over' ? 'highlight' : 'downplay', seriesIndex: 0, dataIndex });
    }
  };
  return (
    <>
      {newList.length > 0 && (
        <div style={{ overflow: 'hidden auto', flex: 1 }}>
          <div className="chart-info-wrap" style={{ ...style, marginLeft: 14, maxWidth: 240 }}>
            <div className="flex-row flex-space-between" style={{ marginBottom: 10, fontSize: 14, lineHeight: '16px' }}>
              <div style={{ fontWeight: 'bold' }}>{intl.formatMessage(appFieldsMessages.patternIdName)}</div>
            </div>
            {R.addIndex(R.map)((item, index) => {
              const detailKey = `${type}-${item.instanceName}-${item.patternId}-${item.count}`;

              const patternIdAndName = R.toString(item.patternName || item.patternId);
              const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
              const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });

              return (
                <div
                  className="chart-info-group flex-row flex-center-align flex-space-between clickable"
                  key={`${type}-${item.instanceName}-${item.patternId}-${item.count}-${index}`}
                >
                  <Popover
                    title={null}
                    overlayClassName="popover-set-loading-width"
                    content={
                      <Spin spinning={item.loading} style={{ width: 350 }}>
                        {PopoverContentHtml({
                          data: item,
                          detailKey,
                          optionDetail: optionDetail || {},
                          intl,
                          currentTheme,
                          showIcon: type === 'incidents',
                          instanceDisplayNameMap,
                        })}
                      </Spin>
                    }
                    overlayStyle={{ maxWidth: 380 }}
                    placement="top"
                    onVisibleChange={(visible) => {
                      if (visible) {
                        mouseLinkageCharts({ type, item, mouse: 'over' });
                        if (!R.has(detailKey, optionDetail)) {
                          if (echartsAxiosDebugger) {
                            setEchartsAxiosDebugger(false);
                            item.loading = true;
                            setRefresh({ refresh: true });
                            getOptionDetail(item, detailKey, () => {
                              item.loading = false;
                              setRefresh({ refresh: true });
                            });
                          }
                        } else {
                          setRefresh({ refresh: true });
                        }
                      } else {
                        mouseLinkageCharts({ type, item, mouse: 'out' });
                      }
                    }}
                  >
                    <div className="flex-row flex-center-align">
                      <span
                        className="hidden-line-with-ellipsis-multiline"
                        style={{ wordBreak: 'break-word', padding: '0 8px 0 0', width: 'fit-content' }}
                      >
                        {type === 'incidents' && patternIcon}
                        {`[${item.patternId}] ${item.patternName || item.patternId}`}
                      </span>
                    </div>
                  </Popover>
                  <Tag size="small" style={{ borderRadius: 100, marginLeft: 4, fontWeight: 'bold', flexShrink: 0 }}>
                    {item.count}
                  </Tag>
                </div>
              );
            }, newList)}
          </div>
        </div>
      )}
      {newList.length < 1 && (
        <div className="insights-no-data" style={{ color: 'var(--text-color-secondary)' }}>
          No data for this time period
        </div>
      )}
    </>
  );
};
export default BuildIndicateInfo;
