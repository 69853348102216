import React, { useEffect, useCallback } from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { sortableHandle } from 'react-sortable-hoc';
import { Button } from 'antd';

import { buildUrl } from '../../../common/utils';
import { BaseUrls } from '../../../web/app/Constants';
import { MoveIcon } from '../../../lib/fui/icons';
import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';

const DragHandle = sortableHandle(() => (
  <span className="hover-display-item" style={{ cursor: 'move', position: 'absolute', right: 8, top: 8 }}>
    <MoveIcon style={{ fontSize: 16 }} />
  </span>
));

const InsightsTips = (props) => {
  // eslint-disable-next-line
  const { intl, name } = props;
  const {
    // eslint-disable-next-line
    push,
    // eslint-disable-next-line
    updateLastActionInfo,
    // eslint-disable-next-line
    credentials,
    // eslint-disable-next-line
    userInfo,

    // eslint-disable-next-line
    refresh,
    // eslint-disable-next-line
    refreshTime,
    // eslint-disable-next-line
    environmentId,
    // eslint-disable-next-line
    systemId,
    // eslint-disable-next-line
    customerName,
    // eslint-disable-next-line
    startTime,
    // eslint-disable-next-line
    globalInfo,
  } = props;
  const environment = R.find((e) => e.id === environmentId, globalInfo || []);
  const systemList = get(environment, 'systemList', []);
  const systemInfo = R.find((system) => system.id === systemId, systemList);
  const projectNameSet = get(systemInfo, 'projectNameSet', []);
  const metricProject = R.find((item) => item.dataType === 'Metric', projectNameSet);
  const logProject =
    R.find((item) => item.dataType === 'Log', projectNameSet) ||
    R.find((item) => item.dataType !== 'Metric', projectNameSet);

  useEffect(() => {
    // UNSAFE_componentWillReceiveProps
  }, []);

  const handleDependencyClick = useCallback(() => {
    const params = {};
    window.open(buildUrl(BaseUrls.CausalAnalysis, {}, params), '_blank');
  }, []);
  const handleThresholdsClick = useCallback(
    () => {
      if (metricProject) {
        const params = {};
        params.setting = 'threshold';
        const { projectNameReal } = metricProject;
        window.open(buildUrl(BaseUrls.SettingsProject, { projectName: projectNameReal }, params), '_blank');
      }
    },
    [metricProject],
  );
  const handleUpdatePreferencesClick = useCallback(
    () => {
      const params = {};
      let projectNameReal = '';
      if (metricProject) {
        params.setting = 'alert';
        projectNameReal = metricProject.projectNameReal;
      } else if (logProject) {
        params.setting = 'logthreshold';
        projectNameReal = logProject.projectNameReal;
      }
      window.open(buildUrl(BaseUrls.SettingsProject, { projectName: projectNameReal }, params), '_blank');
    },
    [metricProject, logProject],
  );
  const handleUpdateMetricIncidentsClick = useCallback(
    () => {
      if (metricProject) {
        const params = { setting: 'threshold' };
        const projectNameReal = metricProject.projectNameReal;
        window.open(buildUrl(BaseUrls.SettingsProject, { projectName: projectNameReal }, params), '_blank');
      }
    },
    [metricProject],
  );
  const handleUpdateLogIncidentsClick = useCallback(
    () => {
      if (logProject) {
        const params = { setting: 'keywords', keywordCategory: 'incident' };
        const projectNameReal = logProject.projectNameReal;
        window.open(buildUrl(BaseUrls.SettingsProject, { projectName: projectNameReal }, params), '_blank');
      }
    },
    [logProject],
  );

  let index = 1;
  const tips = [
    <div key={index} className="flex-row flex-center-align" style={{ fontSize: 14 }}>
      <div className="circle" style={{ marginRight: 8 }}>
        <div className="circle-text">{index}</div>
      </div>
      <div className="flex-grow" style={{}}>
        <Button type="link" style={{ padding: '0 2px', fontSize: 14 }} onClick={handleDependencyClick}>
          {intl.formatMessage(appButtonsMessages.create)}
        </Button>
        {intl.formatMessage(DashboardMessages.dependencyMaps)}
      </div>
    </div>,
  ];
  if (metricProject) {
    index += 1;
    tips.push(
      <div key={index} className="flex-row flex-center-align" style={{ fontSize: 14 }}>
        <div className="circle" style={{ marginRight: 8 }}>
          <div className="circle-text">{index}</div>
        </div>
        <div className="flex-grow" style={{}}>
          <Button type="link" style={{ padding: '0 2px', fontSize: 14 }} onClick={handleThresholdsClick}>
            {intl.formatMessage(appButtonsMessages.configure)}
          </Button>
          {intl.formatMessage(appFieldsMessages.thresholds)}
        </div>
      </div>,
    );
  }
  if (metricProject || logProject) {
    index += 1;
    tips.push(
      <div key={index} className="flex-row flex-center-align" style={{ fontSize: 14 }}>
        <div className="circle" style={{ marginRight: 8 }}>
          <div className="circle-text">{index}</div>
        </div>
        <div className="flex-grow" style={{}}>
          <Button type="link" style={{ padding: '0 2px', fontSize: 14 }} onClick={handleUpdatePreferencesClick}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
          {intl.formatMessage(DashboardMessages.systemPreferences)}
        </div>
      </div>,
    );
  }
  if (metricProject) {
    index += 1;
    tips.push(
      <div key={index} className="flex-row flex-center-align" style={{ fontSize: 14 }}>
        <div className="circle" style={{ marginRight: 8 }}>
          <div className="circle-text">{index}</div>
        </div>
        <div className="flex-grow" style={{}}>
          <Button type="link" style={{ padding: '0 2px', fontSize: 14 }} onClick={handleUpdateMetricIncidentsClick}>
            {intl.formatMessage(appButtonsMessages.label)}
          </Button>
          {intl.formatMessage(DashboardMessages.metricIncidents)}
        </div>
      </div>,
    );
  }
  if (logProject) {
    index += 1;
    tips.push(
      <div key={index} className="flex-row flex-center-align" style={{ fontSize: 14 }}>
        <div className="circle" style={{ marginRight: 8 }}>
          <div className="circle-text">{index}</div>
        </div>
        <div className="flex-grow" style={{}}>
          <Button type="link" style={{ padding: '0 2px', fontSize: 14 }} onClick={handleUpdateLogIncidentsClick}>
            {intl.formatMessage(appButtonsMessages.label)}
          </Button>
          {intl.formatMessage(DashboardMessages.logIncidents)}
        </div>
      </div>,
    );
  }

  return (
    <div
      className="full-height full-width flex-col health-card hover-display"
      style={{ backgroundColor: 'white', borderRadius: '2em', padding: '8px 8px', position: 'relative' }}
    >
      <div
        className="flex-row flex-center-justify bold"
        style={{ textAlign: 'center', margin: '0 14px 8px 14px', height: 34 }}
      >
        {name}
        <DragHandle />
      </div>

      <div className="flex-grow flex-col overflow-y-auto" style={{ padding: '0px 8px' }}>
        {tips}
      </div>
    </div>
  );
};

export default InsightsTips;
