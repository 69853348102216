import * as R from 'ramda';
import moment from 'moment';
import { createLogic } from 'redux-logic';

import { getUserTypeCheck } from '../../apis';
import { ActionTypes as AppActionTypes } from '../../app/actions';
import { ActionTypes, userFound, userNotFound, loginFailure } from '../actions';
import { PermissionError } from '../../errors';

import { authMessages } from '../messages';

const userTypeCheckLogic = createLogic({
  type: [ActionTypes.USER_TYPE_CHECK],
  cancelType: AppActionTypes.APP_STOP,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    // const { showLoader, hideLoader } = getLoaderAction();
    const { callback } = action.payload;

    // dispatch(showLoader);
    getUserTypeCheck(action.payload)
      .then((data) => {
        const {
          userSuspended,
          IDPSettingMap,
          redirectLoginURL,
          userExist,
          redirectURL,
          idpAvailable,
          idpAvailableMessage,
        } = data;
        if (userSuspended) {
          dispatch(loginFailure(authMessages.errorsExpired));
        } else if (redirectURL) {
          dispatch(userFound(null, redirectURL));
        } else if (userExist) {
          dispatch(userFound(`${redirectLoginURL}/${moment.utc().valueOf()}`));
        } else if (!idpAvailable) {
          dispatch(loginFailure(null, null, idpAvailable));
        } else {
          const idpSettingMap = IDPSettingMap;
          dispatch(userNotFound(idpSettingMap));
        }
      })
      .catch((err) => {
        console.error('[IF] Failed to auth', err);
        if (err instanceof PermissionError) {
          dispatch(loginFailure(authMessages.errorsWrongCredential, err));
        } else {
          dispatch(loginFailure(authMessages.errorsLoginFailure, err));
        }
      })
      .then(() => {
        if (R.type(callback) === 'Function') {
          callback();
        }

        // dispatch(hideLoader);
        done();
      });
  },
});

export default userTypeCheckLogic;
