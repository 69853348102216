/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

/* eslint-disable no-console */
import * as R from 'ramda';
import { get, isPlainObject } from 'lodash';
import moment from 'moment';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults, monthFormat, dateFormat, parseJSON } from '../utils';

const getLogIncidentList = (credentials: Credentials, params: Object) => {
  const { category, projectName, customerName, month, year } = params;

  const monthObj = category === 'daily' ? moment.utc(month, monthFormat).startOf('month') : null;
  const monthlyDate = category === 'daily' ? monthObj.valueOf() : null;
  const yearObj = moment.utc(year, Defaults.YearFormat).startOf('year');
  const yearToDisplay = category === 'daily' ? '' : yearObj.valueOf();

  return Promise.all([
    fetchGet(getEndpoint('logstreaming'), {
      ...credentials,
      operation: category === 'daily' ? 'list' : 'yearly',
      projectName,
      monthlyDate,
      yearToDisplay,
    }),
    fetchGet(getEndpoint('logAlertPatternServlet'), {
      ...credentials,
      operation: category,
      customerName,
      projectName,
      dayTimeMillis: category === 'daily' ? monthObj.valueOf() : yearObj.valueOf(),
    }),
  ]).then((results) => {
    const dataPatterns = results[0];
    const dataRatios = results[1];

    if (!isPlainObject(dataPatterns.data)) {
      console.error('[IF] expected data contains object of incidents and instances, but get', dataPatterns.data);
    }

    // get reductionRatio info by every day/month
    const alertReductionRatioMap = {};
    const alertReductionRatio = parseJSON(get(dataRatios, ['total alert reduction ratio'])) || [];
    R.forEach((item) => {
      const { dailyTimeStamp, reductionRatio } = item;
      alertReductionRatioMap[dailyTimeStamp] = reductionRatio;
    }, alertReductionRatio);

    // Check whether there is duplicated incidents.
    const allIncidentList = get(dataPatterns.data, 'incidents', []);
    let incidentList = R.uniqBy((i) => i.incidentStartTime, allIncidentList);
    if (incidentList.length !== allIncidentList.length) {
      console.error(
        '[IF] duplicated incident with the same incidentStartTime, use the first one',
        allIncidentList,
        incidentList,
      );
    }

    // Sort incident by start time.
    incidentList = R.sort(
      (a, b) => moment.utc(a.incidentStartTime).valueOf() - moment.utc(b.incidentStartTime).valueOf(),
      incidentList,
    );
    let incidents = [];

    if (category === 'daily') {
      const daysInMonth = monthObj.daysInMonth();
      let currentDay = 1;
      R.forEach((incident) => {
        const startTime = moment.utc(incident.incidentStartTime).startOf('day');
        const timeNumber = startTime.date();

        // Prepend empty item for the missing days.
        while (currentDay < timeNumber) {
          incidents.push({
            isEmpty: true,
            timeNumber: monthObj.date(),
            weekday: monthObj.weekday(),
            id: monthObj.valueOf().toString(),
            name: monthObj.format(dateFormat),
          });
          monthObj.add(1, 'day');
          currentDay += 1;
        }

        const clusterCount = incident.numOfCluster || (incident.cluster || []).length;
        const alertReductionRatio = alertReductionRatioMap[startTime.valueOf()] || 0;
        incidents.push({
          ...incident,
          isEmpty: false,
          timeNumber: startTime.date(),
          weekday: startTime.weekday(),
          id: startTime.valueOf().toString(),
          name: startTime.format(dateFormat),
          totalEventsCount: incident.logentrycount,
          rareEventsCount: incident.rareEventsSize,
          clusterCount,
          alertReductionRatio,
        });
        monthObj.add(1, 'day');
        currentDay += 1;
      }, incidentList);
      while (currentDay <= daysInMonth) {
        incidents.push({
          isEmpty: true,
          timeNumber: monthObj.date(),
          weekday: monthObj.weekday(),
          id: monthObj.valueOf().toString(),
        });
        monthObj.add(1, 'day');
        currentDay += 1;
      }
    } else if (category === 'monthly') {
      const yearMap = {};
      R.forEach((incident) => {
        const startTime = moment.utc(incident.incidentStartTime).startOf('month');
        const month = startTime.month();

        const clusterCount = incident.numOfCluster || (incident.cluster || []).length;
        const alertReductionRatio = alertReductionRatioMap[startTime.valueOf()] || 0;
        yearMap[month] = {
          ...incident,
          isEmpty: false,
          timeNumber: moment().month(month).format(Defaults.MonthShortFormat),
          weekday: false,
          id: startTime.valueOf().toString(),
          name: startTime.format(monthFormat),
          totalEventsCount: incident.logentrycount,
          rareEventsCount: incident.rareEventsSize,
          clusterCount,
          alertReductionRatio,
        };
        incidents.push(yearMap[month]);
      }, incidentList);
      R.forEach((currentMonth) => {
        if (!R.has(currentMonth, yearMap)) {
          const timeObj = moment.utc().year(Number(year)).month(currentMonth);
          incidents.push({
            isEmpty: true,
            timeNumber: moment().month(currentMonth).format(Defaults.MonthShortFormat),
            weekday: false,
            incidentStartTime: timeObj.format(),
            id: timeObj.valueOf().toString(),
          });
        }
      }, R.range(0, 12));
      incidents = R.sort(
        (a, b) => moment.utc(a.incidentStartTime).valueOf() - moment.utc(b.incidentStartTime).valueOf(),
        incidents,
      );
    }

    return {
      data: {
        incidentList: incidents,
      },
    };
  });
};

export default getLogIncidentList;
