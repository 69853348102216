import * as R from 'ramda';
import numeral from 'numeral';
import { get, isArray, isString, isFinite, isEmpty } from 'lodash';
import { LogParser, LogTypes, parseJSON } from '../../utils';

const parsePredictionSourceInfo = ({ predictionSourceInfo, event, instanceName, containerName }) => {
  const rawPredictionSourceInfoList = predictionSourceInfo ? parseJSON(predictionSourceInfo) : undefined;
  const predictionSourceInfoList = [];
  if (rawPredictionSourceInfoList) {
    const { generateFromGenericRule, generateFromMetricAnomalyIncident, patternId, componentName } = event;
    R.forEach((predictionSourceInfo) => {
      const { timePairStr, ...rest } = predictionSourceInfo;
      let { sourceDetail } = predictionSourceInfo;
      let timePairs = timePairStr ? parseJSON(timePairStr) || [] : [];
      timePairs = R.map((tr) => {
        if (isString(tr)) {
          const trs = tr.split(',');
          return { s: Number(trs[0]), e: Number(trs[1]) };
        }
        return tr;
      }, timePairs);

      sourceDetail = parseJSON(sourceDetail) || {};
      if (sourceDetail.type) {
        sourceDetail.type = sourceDetail.type.toLowerCase() === 'metricalert' ? 'Metric' : sourceDetail.type;
      }

      predictionSourceInfo = {
        timePairs,
        ...rest,
        sourceDetail,
      };

      // parse kpi/rule prediction
      if (generateFromGenericRule || generateFromMetricAnomalyIncident) {
        const { nid } = predictionSourceInfo.sourceDetail || {};
        const newNid = R.isNil(nid) ? patternId : nid;
        predictionSourceInfo = {
          ...predictionSourceInfo,
          sourceKey: `KPI-${newNid}-${predictionSourceInfo.predictionTime}`,
          componentName: generateFromMetricAnomalyIncident ? componentName : predictionSourceInfo.componentName,
          containerId: generateFromMetricAnomalyIncident ? containerName : predictionSourceInfo.containerId,
          sourceDetail: generateFromMetricAnomalyIncident
            ? { ...sourceDetail, nid: newNid }
            : predictionSourceInfo.sourceDetail,
        };
      }
      predictionSourceInfoList.push(predictionSourceInfo);
    }, rawPredictionSourceInfoList);
  }
  return predictionSourceInfoList;
};

const parseRootCausesDetailsList = (event) => {
  // The Api change the field rootCausesDetailsList to metricRootCause, this function
  // convert metricRootCause to previous format
  let ret;
  let rc = parseJSON(get(event, 'metricRootCause', '{}')) || {};
  if (get(event, 'rootCause')) rc = get(event, 'rootCause');
  event.metricRootCauseJson = rc;
  if (isEmpty(rc)) {
    const rootCausesDetailsList = get(event, 'rootCausesDetailsList', []);
    if (rootCausesDetailsList.length > 0) {
      console.error(
        '[IF_API] II-11854 rootCausesDetailsList has results but not metricRootCause',
        rootCausesDetailsList,
        rc,
      );
    }
    ret = rootCausesDetailsList;
  } else {
    const { percentage, instanceName, componentName, slop, anomalyValue, metricName, sign, timePairList, ...rest } = rc;
    const myArrayList = R.map((t) => {
      const { s, e } = t;
      if (s && e) {
        return { map: { startTimestamp: s, endTimestamp: e, duration: e - s } };
      } else {
        return {};
      }
    }, timePairList || []);

    const item = {
      pct: percentage,
      instanceId: instanceName,
      componentName,
      anomalySlop: slop,
      metricValue: anomalyValue,
      rootCauseMetric: metricName,
      direction: sign,
      timePairArr: { myArrayList },
      ...rest,
    };
    ret = [item];
  }
  return ret;
};

const parseTimelinesList = (
  timelines,
  projectNameSet,
  projectDisplayMap,
  isCompositeAnomaly = false,
  occurrenceList,
) => {
  return R.filter(
    (e) => !!e,
    R.map((event) => {
      const {
        type,
        patternId,
        projectName,
        startTimestamp,
        timeLineType,
        isPrediction,
        containerInfo,
        anomalyLogInstance,
        realInstanceName,
        anomalyRatio,
        averageAnomalyScore,
        predictionTimeStamp,
        predictionSourceInfo,
        anomalyTimelines = [],
        id: recurringIncidentId,
      } = event;

      // get projectDisplayName
      const { dataType } = R.find((item) => item.projectName === projectName, projectNameSet || []) || {};
      if (!dataType) {
        return null;
      }

      const projectDisplayName = get(projectDisplayMap, projectName, projectName);

      const realTypeList = R.map((word) => {
        let typeWord = R.toLower(word);
        if (R.indexOf('(', typeWord) > 0) {
          typeWord = typeWord.split('(')[0];
        }
        return typeWord;
      }, type.split('&'));
      const isIncident = realTypeList.includes('incident');
      const isDeployment = realTypeList.includes('deployment');
      const rootCausesDetailsList = parseRootCausesDetailsList(event);
      const category = dataType.toLowerCase() === 'metric' ? 'metric' : 'log';

      // remove category logic, use system project dataType

      // if (rootCausesDetailsList.length > 0) {
      //   category = 'metric';
      // } else if (R.difference(realTypeList, LogTypes).length === 0) {
      //   category = 'log';
      // } else {
      //   console.error('[IF_API] unknown anomalyTimelines type', type, event?.metricRootCause);
      // }

      let typeList = [];
      if (category === 'metric' && type !== 'Incident') {
        typeList = ['metric', 'metricanomaly', 'ublanomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', event.type).split('&'));
      }

      // For container project use containerInfo, for non-container project use anomalyLogInstance or realInstanceName
      let instanceName = anomalyLogInstance || realInstanceName;
      let containerName;
      if (containerInfo) {
        instanceName = `${containerInfo.containerName}_${containerInfo.instanceName}`;
        containerName = containerInfo.containerName || undefined;
      }
      // auto build components/instances/metrics list
      let instanceDown = false;
      let instanceList = [];
      let realInstances = [];
      let metricList = [];
      if (category === 'metric') {
        R.forEach((pair) => {
          instanceDown = instanceDown || pair.instanceDown;
          if (pair.rootCauseMetric || pair.metricName) metricList.push(pair.rootCauseMetric || pair.metricName);
          const realInstance = pair.instanceId || pair.instanceName;
          let instanceName = realInstance;
          if (pair.containerName && !realInstance.includes('_')) {
            instanceName = `${pair.containerName}_${realInstance}`;
          }
          if (instanceName) instanceList.push(instanceName);
          if (realInstance) realInstances.push(realInstance);
        }, rootCausesDetailsList);
      } else {
        if (instanceName) instanceList.push(instanceName);
        if (realInstanceName) realInstances.push(realInstanceName);
      }
      instanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceList));
      realInstances = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(realInstances));
      metricList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(metricList));
      const instanceNameString = R.join(', ', instanceList);
      const realInstanceString = R.join(', ', realInstances);
      const metricNameString = R.join(', ', metricList);
      instanceName = instanceName || instanceList[0]; // some metric timeline missing realInstanceName

      // set anomaly color by avgAnomalyScore
      // const avgAnomalyScore = isFinite(averageAnomalyScore) ? averageAnomalyScore : anomalyRatio;
      const avgAnomalyScore = numeral(numeral(anomalyRatio).format(anomalyRatio > 1 ? '0,0' : '0,0.[0000]')).value();
      let anomalyColor = '#ffc107';
      if (avgAnomalyScore >= 500) {
        anomalyColor = '#ff0014';
      } else if (avgAnomalyScore >= 100) {
        anomalyColor = '#ff5722';
      } else if (avgAnomalyScore >= 50) {
        anomalyColor = '#ff9800';
      }

      // build uniq id
      const id =
        category === 'metric'
          ? `${projectName}-${
              realInstanceName || instanceNameString
            }-${patternId}-${startTimestamp}-${type}-${metricNameString}`
          : `${projectName}-${realInstanceName || instanceNameString}-${patternId}-${startTimestamp}-${type}`;

      // parse prediction source info
      const predictionSourceInfoList = parsePredictionSourceInfo({
        predictionSourceInfo,
        event,
        instanceName,
        containerName,
      });

      // parse suggest action
      let suggestActionList = get(event, 'suggestActionList', []);
      suggestActionList = R.map((item) => {
        let action = null;
        let actionMap = {};
        let componentName = null;
        let instanceName = null;
        try {
          const suggestAction = isString(item) ? JSON.parse(item) : item;
          actionMap = suggestAction.actionMap || {};
          componentName = suggestAction.componentName || null;
          instanceName = suggestAction.instanceName || null;
        } catch (err) {
          const actionString = item.split(' instanceId ')[0];
          instanceName = item.split(' instanceId ')[1];
          componentName = actionString.split(' on ')[1];
          action = actionString.split(' on ')[0];
        }
        return { action, actionMap, componentName, instanceName };
      }, suggestActionList);

      // triggered action list
      const triggeredTimeMap = event.triggeredTimeMap || {};
      let triggeredActionList = [];
      R.forEachObjIndexed((val, actionName) => {
        R.forEach((timestamp) => {
          triggeredActionList.push({
            actionName,
            timestamp,
          });
        }, val);
      }, triggeredTimeMap);
      triggeredActionList = R.sortWith([R.ascend(R.prop('timestamp'))], triggeredActionList);

      // validation time list
      const validationTimeWindow = event.validationTimeWindow || {};
      const validationTimeList = validationTimeWindow && !R.isEmpty(validationTimeWindow) ? [validationTimeWindow] : [];

      // parse reporter
      const reporterMap = get(event, ['patternReporter', 'reporterMap'], {});
      const reporterRecordMap = R.mapObjIndexed((val, action) => {
        let reporterRecordSet = [];
        const { reporterDetailMap } = val || {};
        R.forEach((item) => {
          const { reporterName, reportTimestampSet } = item;
          R.forEach((timestamp) => {
            reporterRecordSet.push({
              timestamp,
              reporterName,
              action,
            });
          }, reportTimestampSet || []);
        }, R.values(reporterDetailMap || {}));

        reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))], reporterRecordSet);

        return reporterRecordSet;
      }, reporterMap);

      // parse reporter
      let investigationReporterRecordStatus = [];
      let investigationReporterRecordSeverity = [];
      if (isIncident) {
        const investigationReporterMap = get(event, ['investigationReporter', 'reporterMap'], {});
        R.forEachObjIndexed((val, action) => {
          const reporterRecordSet = [];
          const { reporterDetailMap } = val || {};
          R.forEach((item) => {
            const { reporterName, reportTimestampSet } = item;
            R.forEach((timestamp) => {
              reporterRecordSet.push({ timestamp, reporterName, action });
            }, reportTimestampSet || []);
          }, R.values(reporterDetailMap || {}));
          if (['created', 'in progress', 'closed'].includes(action)) {
            investigationReporterRecordStatus = R.concat(investigationReporterRecordStatus, reporterRecordSet);
          } else {
            investigationReporterRecordSeverity = R.concat(investigationReporterRecordSeverity, reporterRecordSet);
          }
        }, investigationReporterMap);
        investigationReporterRecordStatus = R.sortWith(
          [R.descend(R.prop('timestamp'))],
          investigationReporterRecordStatus,
        );
        investigationReporterRecordSeverity = R.sortWith(
          [R.descend(R.prop('timestamp'))],
          investigationReporterRecordSeverity,
        );
      }

      let recurringIncidentTimes = [];
      const findRecurringIncident = R.find(
        (item) => R.includes(recurringIncidentId, item?.idSet || []),
        occurrenceList || [],
      );
      if (findRecurringIncident) recurringIncidentTimes = findRecurringIncident?.occurrenceSet || [];
      recurringIncidentTimes = R.filter((item) => item !== startTimestamp, recurringIncidentTimes);

      return {
        ...event,
        id,
        category,
        ...(category === 'log' ? { typeAndColor: LogParser.CalculateLogType({ typeList: realTypeList }) } : {}),
        isIncident,
        isDeployment,
        isTrace: dataType === 'Trace',
        typeList,
        instanceDown,
        instanceName,
        containerName,
        instanceList,
        instanceNameString,
        realInstances,
        realInstanceString,
        metricList,
        metricNameString,
        avgAnomalyScore,
        anomalyColor,

        projectDisplayName,
        displayTimestamp: startTimestamp,
        rootCausesDetailsList,
        rootCauseJson: { rootCauseDetailsArr: rootCausesDetailsList },
        isPrediction: isPrediction || timeLineType === 'future',
        predictionTime: predictionTimeStamp,
        predictionSourceInfoList,
        suggestActionList,
        triggeredActionList,
        validationTimeList,
        reporterRecordMap,
        investigationReporterRecordStatus,
        investigationReporterRecordSeverity,
        ...(anomalyTimelines.length > 0
          ? {
              anomalyTimelines: parseTimelinesList(
                anomalyTimelines,
                projectNameSet,
                projectDisplayMap,
                false,
                occurrenceList,
              ),
            }
          : {}),
        isCompositeAnomaly,
        recurringIncidentTimes,
        recurringIncidentId,
      };
    }, timelines || []),
  );
};

const parseTimelinesCategory = ({ timeline, projectNameSet, projectDisplayMap, systemId }) => {
  // futurnEndTime is not correct, calculate from currentEndTime
  const {
    pastStartTime,
    currentStartTime,
    endStartTime: currentEndTime,
    futureEndTime,
    anomalyTimelines,
    compositeAnomalyTimeLines,
    id,
    name,
    occurrenceList,
  } = timeline;

  if (!(pastStartTime <= currentStartTime && currentStartTime <= currentEndTime && currentEndTime < futureEndTime)) {
    console.debug('[IF_API] time window not correct', pastStartTime, currentStartTime, currentEndTime, futureEndTime);
  }

  let timelines = [];
  try {
    if (isString(anomalyTimelines)) {
      timelines = JSON.parse(anomalyTimelines) || [];
    } else if (isArray(anomalyTimelines)) {
      timelines = anomalyTimelines;
    }
  } catch (error) {
    console.error(error);
  }

  let compositeTimelines = [];
  try {
    if (isString(compositeAnomalyTimeLines)) {
      compositeTimelines = JSON.parse(compositeAnomalyTimeLines) || [];
    } else if (isArray(compositeAnomalyTimeLines)) {
      compositeTimelines = compositeAnomalyTimeLines;
    }
  } catch (error) {
    console.error(error);
  }

  // parse list
  timelines = parseTimelinesList(timelines, projectNameSet, projectDisplayMap, false, occurrenceList);
  compositeTimelines = parseTimelinesList(compositeTimelines, projectNameSet, projectDisplayMap, true, occurrenceList);

  // sort timelines
  timelines = R.sort((timelineA, timelineB) => timelineA.startTimestamp - timelineB.startTimestamp, timelines);
  compositeTimelines = R.sort(
    (timelineA, timelineB) => timelineA.startTimestamp - timelineB.startTimestamp,
    compositeTimelines,
  );

  return {
    ...timeline,
    pastStartTime,
    currentStartTime,
    currentEndTime,
    futureEndTime,
    anomalyTimelines: timelines,
    compositeAnomalyTimeLines: compositeTimelines,
    id: systemId || id,
    name: systemId || name,
  };
};

export { parseTimelinesCategory, parseTimelinesList };
