/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Spin, Alert, Form, Select, message, Input, InputNumber } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo, loadProjectInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import fetchGet from '../../../common/apis/fetchGet';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import { parseJSON } from '../../../common/utils';

type Props = {
  // eslint-disable-next-line
  activeItemIndex: Number,
  activeItem: Object,
  onSubmit: Function,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  loadProjectInfo: Function,
  projectInstanceDisplayNameMap: Object,
  handleChangeDisplayName: Function,
  userList: Array<Object>,
  cacheProjectMap: Object,
  cacheMetricMap: Object,
  changeCacheProjectMap: Function,
  changeCacheMetricMap: Function,
};

const MAX_FATCH_NUM = 30;

class AddStatusMetricModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { activeItem, userList } = props;

    this.state = {
      isLoading: false,

      userName: activeItem ? activeItem.owner : userList[0]?.userName || 'user',
      projectName: activeItem ? activeItem.projectName : undefined,
      instance: activeItem ? activeItem.instance : undefined,
      metric: activeItem ? activeItem.metric : undefined,
      metricDisplayName: activeItem ? activeItem.metricDisplayName : '',
      description: activeItem ? activeItem.description : '',
      unit: activeItem ? activeItem.unit : '',
      alertValue: activeItem ? activeItem.alertValue : 0,
    };
    this.projectList = [];
    this.instanceListOptions = [];
    this.metricList = [];
  }

  componentDidMount() {
    this.getProjectList();
  }

  componentDidUpdate(prevProps, prevState) {}

  @autobind
  async getProjectList() {
    const { userInfo, projects, activeItem } = this.props;
    this.setState({ isLoading: true });
    let projectList = [];
    if (userInfo.isAdmin || userInfo.isLocalAdmin) {
      projectList = await this.getSystemframework();
    } else {
      projectList = R.filter((project) => project.owner === userInfo.userName, projects || []);
    }
    const activeProject = activeItem
      ? R.find(
          (project) => project.owner === activeItem.owner && project.projectShortName === activeItem.projectName,
          projectList || [],
        )
      : undefined;
    this.projectList = projectList;
    this.setState({ isLoading: false, projectName: activeProject?.projectName }, () => {
      this.reloadProjectInstance();
      this.reloadInstanceMetric();
    });
  }

  @autobind
  getSystemframework() {
    const { credentials, changeCacheProjectMap, cacheProjectMap } = this.props;
    const { userName } = this.state;
    if (cacheProjectMap[userName]) {
      return cacheProjectMap[userName];
    }
    return fetchGet(getEndpoint('systemframework', 2), {
      ...credentials,
      customerName: userName,
      needDetail: false,
    })
      .then(async (results) => {
        let { ownSystemArr, sameCompanySystemArray } = results || {};

        ownSystemArr = R.map((item) => {
          const system = parseJSON(item) || {};
          return { ...system };
        }, ownSystemArr || []);
        sameCompanySystemArray = R.map((item) => {
          const system = parseJSON(item) || {};
          return { ...system };
        }, sameCompanySystemArray || []);

        const systemsMap = {};
        const projectSystemKeyMap = {};
        let allProjects = [];
        R.forEach(
          (system) => {
            const { systemDisplayName, systemKey, projectDetailsList, ...rest } = system;
            const { environmentName, systemName: systemId, userName } = systemKey || {};
            let newProjects = parseJSON(projectDetailsList) || [];
            newProjects = R.map((item) => {
              const { userName, projectClassType, ...restItem } = item;
              return {
                ...restItem,
                customerName: userName,
                projectType: projectClassType,
              };
            }, newProjects);

            systemsMap[systemId] = {
              ...rest,
              environmentName,
              systemId,
              systemName: systemDisplayName || systemId,
              owner: userName,
              projectDetailsList: newProjects,
            };
            R.forEach((item) => {
              const { projectName, customerName } = item;
              const projectFullName = `${projectName}@${customerName}`;
              projectSystemKeyMap[projectFullName] = systemId;
            }, newProjects);

            allProjects = [...allProjects, ...newProjects];
          },
          // [...ownSystemArr, ...sameCompanySystemArray],
          [...ownSystemArr],
        );

        const projectList = R.map((item) => {
          const { customerName, projectName, projectType } = item;
          return { customerName, projectName, projectType };
        }, allProjects);
        const metaSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, projectList || []);
        const metaRequests = [];
        R.forEach((group) => {
          if (group.length > 0) {
            metaRequests.push(
              fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
                ...credentials,
                projectList: JSON.stringify(group),
              }),
            );
          }
        }, metaSplitEveryTakes || []);
        await Promise.all(metaRequests)
          .then((res) => {
            let groupMetaProject = [];
            R.forEach((data) => {
              groupMetaProject = [...groupMetaProject, ...get(data, 'data', [])];
            }, res || []);
            R.forEach((item) => {
              const findInfo = R.find((_item) => _item.projectKey === item.projectKey, groupMetaProject);
              if (findInfo) {
                item.projectDisplayName = findInfo.projectDisplayName;
                item.cloudType = findInfo.cloudType;
                item.projectKey = findInfo.projectKey;
              }
            }, allProjects);
          })
          .catch((err) => {
            console.error('[IF] Failed to get project stats', err);
            return {};
          });

        const uniqProjects = {};
        R.forEach((project) => {
          const { projectName } = project;
          if (!R.has(projectName, uniqProjects)) {
            uniqProjects[projectName] = project;
          }
        }, allProjects);

        let projectStats = {};
        const statusSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, R.values(uniqProjects) || []);
        const statusRequests = [];
        R.forEach((group) => {
          if (group.length > 0) {
            statusRequests.push(
              fetchPost(getEndpoint('getprojectstatus'), {
                ...credentials,
                projectList: JSON.stringify(group),
              }),
            );
          }
        }, statusSplitEveryTakes || []);
        await Promise.all(statusRequests)
          .then((res) => {
            let groupProjectStats = {};
            R.forEach((data) => {
              groupProjectStats = { ...groupProjectStats, ...get(data, 'data', {}) };
            }, res || []);
            projectStats = R.mapObjIndexed((item) => {
              return { ...item, projectStatus: parseJSON(item.projectStatus) };
            }, groupProjectStats);
          })
          .catch((err) => {
            console.error('[IF] Failed to get project stats', err);
            return {};
          });

        let projects = R.map((project) => {
          const { customerName, dataType, projectName, projectType } = project;
          let newProjectName = projectName;
          if (customerName !== credentials.userName) {
            newProjectName = `${projectName}@${customerName}`;
          }

          const isMetric = dataType && dataType.toLowerCase() === 'metric';
          const isLog = dataType && ['log', 'trace'].includes(dataType.toLowerCase());
          const isTrace = dataType && dataType.toLowerCase() === 'trace';
          const isDeployment = dataType && dataType.toLowerCase() === 'deployment';
          const isAlert = dataType && dataType.toLowerCase() === 'alert';
          const isIncident = dataType && dataType.toLowerCase() === 'incident';

          // systemInfo
          const projectFullName = `${projectName}@${customerName}`;
          const systemKey = projectSystemKeyMap[projectFullName];
          const { environmentName, systemId, systemName, timezone } = get(systemsMap, [systemKey], {});

          const { ps } = projectStats[project.projectKey]?.projectStatus || {};

          return {
            ...project,
            // system info
            systemKey,
            environmentName,
            systemId,
            systemName,
            timezone,

            // project meta info
            hasAllInfo: false,
            hasAllInstanceInfo: false,
            projectName: newProjectName,
            projectShortName: projectName,
            projectType: projectType.toLowerCase() === 'custom' ? 'Agent' : projectType,
            projectTypeRaw: projectType,
            dataType,
            owner: customerName,

            // project type
            isMetric,
            isLog,
            isTrace,
            isDeployment,
            isAlert,
            isIncident,

            ps,
          };
        }, R.values(uniqProjects));
        projects = R.sort((a, b) => (a.projectName || '').localeCompare(b.projectName), projects);
        projects = R.filter((project) => project.ps !== 'Deleting' && project.ps !== 'In trash', projects);

        projects = R.filter((item) => item.cloudType !== 'LogFrequency', projects || []);
        changeCacheProjectMap({ [userName]: projects });
        return projects;
      })
      .catch((e) => {
        message.error('[IF] systemframework exception', e);
        return [];
      });
  }

  @autobind
  handleProjectChange(projectName) {
    this.instanceListOptions = [];
    this.metricList = [];
    this.setState({ projectName, instance: undefined, metric: undefined }, () => {
      this.reloadProjectInstance();
    });
  }

  @autobind
  getInstanceDisplayNameData(currentProject) {
    const { credentials, handleChangeDisplayName } = this.props;
    return fetchGet(getEndpoint('instance-display-name'), {
      ...credentials,
      instanceDisplayNameRequestList: JSON.stringify([
        { projectName: currentProject?.projectShortName, customerName: currentProject?.owner },
      ]),
    })
      .then((d1) => {
        const instanceDisplayNameMap = {};
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, d1 || []);
        handleChangeDisplayName({ [currentProject?.projectShortName]: instanceDisplayNameMap });
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });
  }

  @autobind
  async reloadProjectInstance() {
    const { projects, loadProjectInfo, projectInstanceDisplayNameMap } = this.props;
    const { projectName } = this.state;

    const currentProject = R.find((project) => project.projectName === projectName, projects || []);
    if (projectName && currentProject && !currentProject.hasAllInstanceInfo) {
      this.setState({ isLoading: true });
      if (!projectInstanceDisplayNameMap[currentProject?.projectShortName]) {
        await this.getInstanceDisplayNameData(currentProject);
      }
      loadProjectInfo(
        {
          projectName,
          includeInstance: true,
        },
        false,
        this.callbackHandle,
      );
    } else {
      const instanceList = get(currentProject, ['instanceList'], []);
      const instanceDisplayNameMap = projectInstanceDisplayNameMap[currentProject?.projectShortName];
      this.instanceListOptions = R.map((i) => {
        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, i, {
          pn: currentProject?.projectShortName,
          owner: currentProject?.owner,
        });
        return { value: i, label: instanceStr };
      }, instanceList);
      this.forceUpdate();
    }
  }

  @autobind
  callbackHandle() {
    const { projectInstanceDisplayNameMap } = this.props;
    const { projectName } = this.state;
    const currentProject = R.find((project) => project.projectName === projectName, this.props.projects || []);
    const instanceDisplayNameMap = projectInstanceDisplayNameMap[currentProject?.projectShortName];
    const instanceList = get(currentProject, ['instanceList'], []);
    this.instanceListOptions = R.map((i) => {
      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, i, {
        pn: currentProject?.projectShortName,
        owner: currentProject?.owner,
      });
      return { value: i, label: instanceStr };
    }, instanceList);
    this.setState({ isLoading: false });
    this.forceUpdate();
  }

  @autobind
  handleInstanceChange(instance) {
    this.metricList = [];
    this.setState({ instance, metric: undefined }, () => {
      this.reloadInstanceMetric();
    });
  }

  @autobind
  reloadInstanceMetric() {
    const { intl, credentials, projects, changeCacheMetricMap, cacheMetricMap } = this.props;
    const { projectName, instance } = this.state;
    if (projectName && instance) {
      const project = R.find((project) => project.projectName === projectName, projects || []);
      if (cacheMetricMap[`${project?.projectKey}-${instance}`]) {
        this.metricList = cacheMetricMap[`${project?.projectKey}-${instance}`];
        this.forceUpdate();
        return;
      }

      this.setState({ isLoading: true });
      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('instanceMetaData'), {
        ...credentials,
        projectName,
        instanceGroup: 'All',
        isContainerProj: get(project, ['isContainer'], false),
        idList: JSON.stringify([instance]),
      })
        .then((result) => {
          const { success, message, data } = result;
          const isContainer = R.includes('_', instance);
          const treeMapNodeInfoMap = JSON.parse(get(data[0], ['result', 'instanceLevelTreeMapNodeInfoMap'], '{}'));
          const containertTreeMapNodeInfoMap = JSON.parse(
            get(data[0], ['result', 'containerLevelTreeMapNodeInfoMap'], '{}'),
          );
          const metricList = [];
          if (!isContainer) {
            R.forEach((instanceInfo) => {
              R.forEach((metricObj) => {
                metricList.push(metricObj.metricName);
              }, instanceInfo.metricModelList || []);
            }, R.values(treeMapNodeInfoMap));
          } else {
            R.forEach((instanceInfos) => {
              R.forEach((instanceInfo) => {
                R.forEach((metricObj) => {
                  metricList.push(metricObj.metricName);
                }, instanceInfo.metricModelList || []);
              }, instanceInfos || []);
            }, R.values(containertTreeMapNodeInfoMap));
          }

          this.metricList = R.sort((a, b) => R.toString(a).localeCompare(R.toString(b)), R.uniq(metricList));
          changeCacheMetricMap({ [`${project?.projectKey}-${instance}`]: this.metricList });
          this.setState({ isLoading: false, errMsg: !success ? message : null });
        })
        .catch((err) => {
          this.metricList = [];
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isLoading: false, errMsg: String(err) });
        });
    }
  }

  @autobind
  handleMetricChange(metric) {
    this.setState({ metric });
  }

  @autobind
  handleSubmit() {
    const { activeItemIndex, onSubmit, projects } = this.props;
    const { projectName, instance, metric, metricDisplayName, description, unit, alertValue } = this.state;
    const project = R.find((project) => project.projectName === projectName, projects || []);
    const { projectShortName, owner } = project;

    onSubmit({
      activeItemIndex,
      projectName: projectShortName,
      owner,
      instance,
      metric,
      metricDisplayName,
      description,
      unit,
      alertValue,
    });
  }

  @autobind
  handleCustomerNameChange(userName) {
    this.projectList = [];
    this.instanceListOptions = [];
    this.metricList = [];
    this.setState({ userName, projectName: undefined, instance: undefined, metric: undefined }, () => {
      this.getProjectList();
    });
  }

  render() {
    const { intl, onClose, userList, userInfo, activeItem } = this.props;
    const {
      isLoading,
      errMsg,
      projectName,
      instance,
      metric,
      metricDisplayName,
      description,
      unit,
      alertValue,
      userName,
    } = this.state;
    const hasError =
      !projectName || !instance || !metric || (userInfo.isAdmin || userInfo.isLocalAdmin ? !userName : false);

    return (
      <Modal
        width={650}
        title="Add status page metric"
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: hasError }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
            {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
              <Form.Item
                label={intl.formatMessage(appFieldsMessages.user)}
                validateStatus={!userName ? 'error' : 'success'}
                help={!userName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <Select
                  showSearch
                  value={userName}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.handleCustomerNameChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                  disabled={activeItem}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.project)}
              validateStatus={!projectName ? 'error' : 'success'}
              help={!projectName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={projectName}
                onChange={this.handleProjectChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.projectName}>{item.projectDisplayName}</Select.Option>
                  ),
                  this.projectList || [],
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.instance)}
              validateStatus={!instance ? 'error' : 'success'}
              help={!instance ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                  (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.instanceListOptions}
                value={instance}
                onChange={this.handleInstanceChange}
                dropdownMatchSelectWidth={650}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.metric)}
              validateStatus={!metric ? 'error' : 'success'}
              help={!metric ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={metric}
                onChange={this.handleMetricChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item}>{item}</Select.Option>
                  ),
                  this.metricList || [],
                )}
              </Select>
            </Form.Item>
            <Form.Item label="Metric display name">
              <Input value={metricDisplayName} onChange={(e) => this.setState({ metricDisplayName: e.target.value })} />
            </Form.Item>
            <Form.Item label="Description">
              <Input value={description} onChange={(e) => this.setState({ description: e.target.value })} />
            </Form.Item>
            <Form.Item label="Unit">
              <Input value={unit} onChange={(e) => this.setState({ unit: e.target.value })} />
            </Form.Item>
            <Form.Item label="Alert value">
              <InputNumber
                style={{ width: '100%' }}
                value={alertValue}
                onChange={(alertValue) => this.setState({ alertValue })}
              />
            </Form.Item>

            {errMsg && (
              <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 8 },
                }}
                style={{ margin: 0 }}
              >
                <Alert message={errMsg} type="error" showIcon />
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const AddStatusMetricModal = injectIntl(AddStatusMetricModalCore);
export default connect(
  (state) => {
    const { projects } = state.app;
    const { userInfo, credentials } = state.auth;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return { projects, userInfo, credentials, userList };
  },
  { updateLastActionInfo, loadProjectInfo },
)(AddStatusMetricModal);
