/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'react-router-redux';
import { Button, notification } from 'antd';

import getEndpoint from '../../../common/apis/getEndpoint';
import fetchGet from '../../../common/apis/fetchGet';
import { parseLocation, buildLocation } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Container } from '../../../lib/fui/react';
import { logMessages } from '../../../../src/common/log/messages';

import EventGroup from '../../../../components/log/loganalysis/event-group';

type Props = {
  intl: Object,
  location: Object,
  push: Function,
  updateLastActionInfo: Function,
  loadStatus: Object,
  credentials: Object,
  isAdmin: Boolean,
  userName: String,

  width: Number,
  height: Number,
  project: Object,
  queryParams: Object,
  queryResult: Object,
};

class RecentEventCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.notificationKey = 'notification-new-recent-logs';
    this.state = {
      total: 0,
      page: 1,
      pageSize: 100,

      clusterInfoList: [],
      clusterSampleMsgs: {},
      eventList: [],
    };
  }

  componentDidMount() {
    this.convertData(this.props, {});

    this.timer = setInterval(() => {
      this.getNewLogs();
    }, 60 * 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps, {});
    }
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    notification.destroy();
  }

  @autobind
  getNewLogs() {
    const { queryParams, credentials, project } = this.props;
    const { templateId, projectName, instanceName, timeObj } = queryParams || {};
    const { projectType } = project;
    const time = timeObj.valueOf();
    const nowTimestammp = moment.utc().valueOf();
    // this.props.updateLastActionInfo();
    fetchGet(getEndpoint(`query/${templateId}`), {
      ...credentials,
      projectName,
      logProjectName: projectName,
      instanceName,
      time: nowTimestammp,
      interval: nowTimestammp - Number(time),
      projectType,
    }).then((data) => {
      const numOfResult = data.data[0].numOfResult;
      if (numOfResult > 0) {
        console.log(`${numOfResult} new logs are received after ${time}.`);
        const btn = (
          <Button type="primary" size="small" onClick={this.handleNotificationConfirm}>
            Reload Log Entries
          </Button>
        );
        notification.info({
          message: 'Notification',
          description: `Received ${numOfResult} new log entries.`,
          btn,
          key: this.notificationKey,
          duration: 0,
        });
      }
    });
  }

  @autobind
  handleNotificationConfirm() {
    notification.close(this.notificationKey);

    const { location, push } = this.props;
    const query = parseLocation(location);
    const refreshTime = moment.utc().valueOf();
    push(buildLocation(location.pathname, {}, { ...query, time: refreshTime }));
  }

  @autobind
  convertData(props, params = {}) {
    const { queryResult, otherResult } = props;
    const { numOfResult, pageNumber, pageSize } = otherResult;

    let clusterInfoList = [];
    let eventList = [];
    R.forEachObjIndexed((val, dayTs) => {
      const { clusterInfo, events } = val;
      clusterInfoList = [...clusterInfoList, ...clusterInfo];
      eventList = [...eventList, ...events];
    }, queryResult || {});

    clusterInfoList = R.uniqWith(R.eqBy(R.prop('nid')), clusterInfoList);
    eventList = R.sortWith([R.descend(R.prop('timestamp'))], eventList);

    const clusterSampleMsgs = {};
    R.forEach((c) => {
      const sampleMsgs = get(c, ['sampleMsg'], []);
      R.addIndex(R.forEach)((s, idx) => {
        clusterSampleMsgs[`${c.nid}[${idx}]`] = s;
      }, sampleMsgs);
    }, clusterInfoList);

    this.setState({ total: numOfResult, page: pageNumber, pageSize, clusterInfoList, clusterSampleMsgs, eventList });
  }

  @autobind
  handlePageChanged(pageNo) {
    const { push, location } = this.props;
    const params = parseLocation(location);
    this.setState({ page: pageNo }, () => {
      push(buildLocation(location.pathname, {}, { ...params, pageNumber: pageNo }));
    });
  }

  render() {
    const { intl, width, height } = this.props;
    const { page, pageSize, total, clusterSampleMsgs, eventList } = this.state;

    return (
      <Container className="flex-col" style={{ height, width }}>
        <div className="flex-grow flex-col" style={{ overflowY: 'hidden' }}>
          {eventList.length === 0 && (
            <div
              className="ui info message"
              style={{
                margin: 8,
                height: 50,
              }}
            >
              {intl.formatMessage(logMessages.noLogEntryFound)}
            </div>
          )}
          {eventList.length > 0 && (
            <EventGroup
              name=""
              eventDataset={eventList}
              totalCount={total}
              pageSize={pageSize}
              pageNo={page}
              onPageChanged={this.handlePageChanged}
              isContext
              showFE={false}
              hasType={false}
              hasCount
              hasNid
              hasDecompress
              clusterSampleMsgs={clusterSampleMsgs}
              needSortDatetime
            />
          )}
        </div>
      </Container>
    );
  }
}

const RecentEvent = injectIntl(RecentEventCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials } = state.auth;
    const { isAdmin, userName } = state.auth.userInfo;

    return {
      location,
      loadStatus,
      credentials,
      isAdmin,
      userName,
    };
  },
  { push, updateLastActionInfo },
)(RecentEvent);
