import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import Papa from 'papaparse';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import { Button, Form, message, Select, Spin } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import fetchPostForm from '../../../../common/apis/fetchPostForm';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { AutoSizer, Column, Container, Modal, Table } from '../../../../lib/fui/react';
import { Constants } from '../../../../common/utils';

import { appButtonsMessages, appMessages } from '../../../../common/app/messages';
import fetchGet from '../../../../common/apis/fetchGet';
import { eventMessages } from '../../../../common/metric/messages';
import fetchDelete from '../../../../common/apis/fetchDelete';

type Props = {
  intl: Object,
  onClose: Function,
  groupTypeFile: String,
  project: Object,
  projectName: String,
  credentials: Object,
};

const titleMap = {
  instanceName: 'Upload instance name mapping file',
  componentName: 'Upload component name mapping file',
  instanceAndComponentName: 'Upload instance component name mapping file',
};

const sourceTitleMap = {
  instanceName: 'Instance source column',
  componentName: 'Component source column',
  instanceAndComponentName: 'Instance column',
};

const targetTitleMap = {
  instanceName: 'Instance target column',
  componentName: 'Component target column',
  instanceAndComponentName: 'Component column',
};

class GroupTypeFileModal extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {
      fileData: null,
      filename: null,
      fileType: null,
      csvFileData: null,

      source: null,
      target: null,

      errorMessage: null,

      mappingFileFlag: false,
      isLoading: false,
      btnLoading: false,
      previewData: {},
      previewModal: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  @autobind
  reloadData(props) {
    const { groupTypeFile, projectName, credentials, project } = props;
    this.setState({ isLoading: true });
    fetchGet(getEndpoint('projects/groupMapping'), {
      ...credentials,
      projectName,
      label: groupTypeFile,
      customerName: project?.owner,
      download: false,
    })
      .then(async (data) => {
        const { mappingfilename, instancename, componentname, instanceAndComponentname, jsonData } = data || {};
        if (mappingfilename) {
          let previewData = {};
          try {
            previewData = await jsonData;
          } catch (error) {
            console.error(error.message || String(error));
          }
          this.setState({
            isLoading: false,
            previewData,
            filename: mappingfilename,
            mappingFileFlag: Boolean(instancename || componentname || instanceAndComponentname),
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error(err.message || String(err));
      });
  }

  @autobind
  saveFileUploadNode(node) {
    this.fileUploadNode = node;
  }

  @autobind
  setFileData({ file, filename, result }) {
    this.setState({
      fileData: file,
      filename,
      fileType: 'csv',
      csvFileData: result,
      source: null,
      target: null,
      mappingFileFlag: false,
    });
  }

  @autobind
  handleFileChanged(e) {
    const { intl } = this.props;
    const { filename: stateFilename, mappingFileFlag } = this.state;
    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const { type, name: filename } = file;
      if (type === 'text/csv' || filename.indexOf('.csv') >= 0) {
        if (stateFilename && mappingFileFlag) {
          this.uploadModal = Modal.confirm({
            title: <div>{intl.formatMessage(appMessages.previousConfirm)}</div>,
            icon: <ExclamationCircleOutlined />,
            content: null,
            okType: 'danger',
            okText: intl.formatMessage(appButtonsMessages.yes),
            cancelText: intl.formatMessage(appButtonsMessages.no),
            onOk: (close) => {
              Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                  if (this.uploadModal) this.uploadModal.destroy();
                  this.setFileData({ file, filename, result });
                },
              });
            },
            onCancel: () => {
              this.fileUploadNode.value = '';
              if (this.uploadModal) this.uploadModal.destroy();
            },
          });
        } else {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              this.setFileData({ file, filename, result });
            },
          });
        }
      } else {
        this.setState({ errorMessage: 'The file format is incorrect.', fileType: null });
      }
    }
  }

  @autobind
  async handleSumbit() {
    await this.formRef.current.validateFields();
    const { source, target, fileData } = this.state;
    const { project, groupTypeFile, projectName } = this.props;
    const { name } = fileData || {};
    const formData = new FormData();
    formData.append('data', fileData);
    formData.append('projectName', projectName);
    formData.append('label', groupTypeFile);
    formData.append('source', source);
    formData.append('target', target);
    formData.append('fileName', name);
    formData.append('customerName', project?.owner);
    this.setState({ btnLoading: true });
    fetchPostForm(getEndpoint('projects/groupMapping'), formData)
      .then((data) => {
        message.success('upload success');
        this.setState({ btnLoading: false }, () => {
          this.props.onClose();
        });
      })
      .catch((err) => {
        this.setState({ btnLoading: false });
        message.error(String(err));
      });
  }

  @autobind
  handlePreView() {
    this.setState({ previewModal: true });
  }

  @autobind
  handleClickDownload() {
    const { groupTypeFile, projectName, credentials, project } = this.props;
    this.setState({ isLoading: true });
    fetchGet(getEndpoint('projects/groupMapping'), {
      ...credentials,
      projectName,
      label: groupTypeFile,
      customerName: project?.owner,
      download: true,
    })
      .then((data) => {
        const { mappingfilename } = data;
        if (mappingfilename) {
          const url = `${
            window.BASE_URL || ''
          }/api/v1/projects/groupMapping?projectName=${projectName}&label=${groupTypeFile}&customerName=${
            project?.owner
          }&download=${true}`;
          const aTag = document.createElement('a');
          document.body.appendChild(aTag);
          aTag.href = url;
          aTag.download = mappingfilename;
          aTag.target = '_blank';
          aTag.click();
          document.body.removeChild(aTag);
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error(err.message || String(err));
      });
  }

  @autobind
  handleClickDelete() {
    const { groupTypeFile, projectName, credentials, project } = this.props;
    this.setState({ isLoading: true });
    fetchDelete(getEndpoint('projects/groupMapping'), {
      ...credentials,
      projectName,
      label: groupTypeFile,
      customerName: project?.owner,
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          this.setState({ isLoading: false, filename: null, mappingFileFlag: false });
          message.success(msg);
        } else {
          this.setState({ isLoading: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error(err.message || String(err));
      });
  }

  render() {
    const { intl, onClose, groupTypeFile } = this.props;
    const { filename, errorMessage, fileType, csvFileData, source, target, isLoading, btnLoading, mappingFileFlag } =
      this.state;
    const { previewModal, previewData } = this.state;

    const hasCsvFile = fileType === 'csv';
    let csvColumnOptions = [];
    if (hasCsvFile) {
      csvColumnOptions = R.map(
        (n) => ({ label: n, value: n }),
        R.uniq(R.filter((n) => Boolean((n || '').trim()), get(csvFileData, Constants.PapaFields, []))),
      );
    }

    return (
      <>
        <Modal
          title={titleMap[groupTypeFile]}
          width={850}
          visible
          maskClosable={false}
          onCancel={() => onClose()}
          onOk={this.handleSumbit}
          okButtonProps={{ loading: btnLoading }}
        >
          <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
            <div className="content overflow-y-auto overflow-x-hidden full-width">
              {errorMessage && <div className="ui error message">{errorMessage}</div>}

              <div className="flex-row">
                <Button size="small" style={{ background: '#767676', color: '#fff', marginRight: 10 }}>
                  <div className="ui fileinput-button" style={{ position: 'relative' }}>
                    <input
                      ref={this.saveFileUploadNode}
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={this.handleFileChanged}
                    />
                    {intl.formatMessage(appButtonsMessages.selectFile)}
                  </div>
                </Button>

                <span className="text-blue" style={{ margin: 'auto 0.5em', wordBreak: 'break-all' }}>
                  {filename}
                </span>
                {filename && mappingFileFlag && (
                  <div style={{ flexShrink: 0 }}>
                    <Button size="small" style={{ marginLeft: 8 }} onClick={this.handlePreView}>
                      {intl.formatMessage(appButtonsMessages.preview)}
                    </Button>
                    <Button size="small" style={{ marginLeft: 8 }} onClick={this.handleClickDownload}>
                      {intl.formatMessage(appButtonsMessages.download)}
                    </Button>
                    <Button size="small" style={{ marginLeft: 8 }} onClick={this.handleClickDelete}>
                      {intl.formatMessage(appButtonsMessages.delete)}
                    </Button>
                  </div>
                )}
              </div>

              {hasCsvFile && csvColumnOptions.length > 0 && (
                <div style={{ margin: '8px 0' }}>
                  <Form ref={this.formRef} labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} style={{ marginTop: 20 }}>
                    <Form.Item
                      required
                      label={sourceTitleMap[groupTypeFile]}
                      rules={[{ required: true, message: `${sourceTitleMap[groupTypeFile]} is required` }]}
                      name="source"
                    >
                      <Select
                        value={source}
                        options={csvColumnOptions}
                        style={{ width: 258 }}
                        onChange={(source) => this.setState({ source })}
                      />
                    </Form.Item>
                    <Form.Item
                      required
                      label={targetTitleMap[groupTypeFile]}
                      rules={[{ required: true, message: `${targetTitleMap[groupTypeFile]} is required` }]}
                      name="target"
                    >
                      <Select
                        value={target}
                        options={csvColumnOptions}
                        style={{ width: 258 }}
                        onChange={(target) => this.setState({ target })}
                      />
                    </Form.Item>
                  </Form>
                </div>
              )}

              {hasCsvFile && csvColumnOptions.length === 0 && (
                <div
                  style={{ margin: '8px 0' }}
                  dangerouslySetInnerHTML={{ __html: "The CSV file doesn't contains any headers" }}
                />
              )}
            </div>
          </Spin>
        </Modal>

        {previewModal && (
          <PreviewModal
            intl={intl}
            previewData={previewData}
            groupTypeFile={groupTypeFile}
            onClose={() => this.setState({ previewModal: false })}
          />
        )}
      </>
    );
  }
}

const PreviewModal = ({ intl, onClose, groupTypeFile, previewData }: Object) => {
  const table = useRef(null);
  const [eventList, setEventList] = useState([]);

  const titleMap = {
    instanceName: {
      source: intl.formatMessage(eventMessages.sourceInstanceName),
      target: intl.formatMessage(eventMessages.targetInstanceName),
    },
    componentName: {
      source: intl.formatMessage(eventMessages.sourceComponentName),
      target: intl.formatMessage(eventMessages.targetComponentName),
    },
    instanceAndComponentName: {
      source: intl.formatMessage(eventMessages.sourceInstanceName),
      target: intl.formatMessage(eventMessages.targetComponentName),
    },
  };

  useEffect(() => {
    const events = [];
    R.forEachObjIndexed((target, source) => {
      events.push({ source, target });
    }, previewData || {});
    setEventList(events);
  }, [previewData]);

  return (
    <Modal
      title={intl.formatMessage(appButtonsMessages.preview)}
      width={900}
      bodyStyle={{ height: 660 }}
      onCancel={() => onClose()}
      visible
      footer={
        <div>
          <Button size="small" onClick={() => onClose()}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>
        </div>
      }
    >
      <Container className="flex-grow flex-min-height full-height">
        <AutoSizer>
          {({ width, height }) => (
            <Table
              className="with-border"
              width={width}
              height={height}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={40}
              rowCount={eventList.length}
              rowGetter={({ index }) => eventList[index]}
              ref={(c) => {
                table.current = c;
              }}
            >
              <Column
                width={140}
                label={titleMap[groupTypeFile].source}
                dataKey="source"
                flexGrow={1}
                className="white-pre"
              />
              <Column
                width={140}
                label={titleMap[groupTypeFile].target}
                dataKey="target"
                flexGrow={1}
                className="white-pre"
              />
            </Table>
          )}
        </AutoSizer>
      </Container>
    </Modal>
  );
};

export default GroupTypeFileModal;
