/* eslint-disable max-len, quote-props, quotes */
export default [
  {
    id: 'app.apiFaild',
    defaultMessage: 'An error has occurred, please try again',
  },
  {
    id: 'app.apiSubmit',
    defaultMessage: 'Actions have been submitted',
  },
  {
    id: 'app.apiSuccess',
    defaultMessage: 'Action completed',
  },
  {
    id: 'app.buttons.add',
    defaultMessage: 'Add',
  },
  {
    id: 'app.buttons.addNew',
    defaultMessage: 'Add New',
  },
  {
    id: 'app.buttons.cancel',
    defaultMessage: 'Cancel',
  },
  {
    id: 'app.buttons.close',
    defaultMessage: 'Close',
  },
  {
    id: 'app.buttons.configure',
    defaultMessage: 'Configure',
  },
  {
    id: 'app.buttons.confirm',
    defaultMessage: 'Confirm',
  },
  {
    id: 'app.buttons.create',
    defaultMessage: 'Create',
  },
  {
    id: 'app.buttons.delete',
    defaultMessage: 'Delete',
  },
  {
    id: 'app.buttons.download',
    defaultMessage: 'Download',
  },
  {
    id: 'app.buttons.edit',
    defaultMessage: 'Edit',
  },
  {
    id: 'app.buttons.escalateToGlobalKnowledgeBase',
    defaultMessage: 'Escalate to Global Knowledge Base',
  },
  {
    id: 'app.buttons.escalateToIncidentLabel',
    defaultMessage: 'Escalate to incident label',
  },
  {
    id: 'app.buttons.export',
    defaultMessage: 'Export',
  },
  {
    id: 'app.buttons.filter',
    defaultMessage: 'Filter',
  },
  {
    id: 'app.buttons.finished',
    defaultMessage: 'Finish',
  },
  {
    id: 'app.buttons.label',
    defaultMessage: 'Label',
  },
  {
    id: 'app.buttons.lock',
    defaultMessage: 'Lock',
  },
  {
    id: 'app.buttons.moveTrash',
    defaultMessage: 'Move To Trash',
  },
  {
    id: 'app.buttons.next',
    defaultMessage: 'Next',
  },
  {
    id: 'app.buttons.no',
    defaultMessage: 'No',
  },
  {
    id: 'app.buttons.pick',
    defaultMessage: 'Pick',
  },
  {
    id: 'app.buttons.preview',
    defaultMessage: 'Preview',
  },
  {
    id: 'app.buttons.previewInstance',
    defaultMessage: 'Preview instance',
  },
  {
    id: 'app.buttons.purgeData',
    defaultMessage: 'Purge Data',
  },
  {
    id: 'app.buttons.recover',
    defaultMessage: 'Recover',
  },
  {
    id: 'app.buttons.refresh',
    defaultMessage: 'Refresh',
  },
  {
    id: 'app.buttons.reload',
    defaultMessage: 'Reload',
  },
  {
    id: 'app.buttons.remove',
    defaultMessage: 'Remove',
  },
  {
    id: 'app.buttons.removeAll',
    defaultMessage: 'Remove All',
  },
  {
    id: 'app.buttons.reset',
    defaultMessage: 'Reset',
  },
  {
    id: 'app.buttons.sampleFile',
    defaultMessage: 'Sample File',
  },
  {
    id: 'app.buttons.save',
    defaultMessage: 'Save',
  },
  {
    id: 'app.buttons.search',
    defaultMessage: 'Search',
  },
  {
    id: 'app.buttons.selectFile',
    defaultMessage: 'Select File',
  },
  {
    id: 'app.buttons.submit',
    defaultMessage: 'Submit',
  },
  {
    id: 'app.buttons.unlock',
    defaultMessage: 'UnLock',
  },
  {
    id: 'app.buttons.update',
    defaultMessage: 'Update',
  },
  {
    id: 'app.buttons.upload',
    defaultMessage: 'Upload',
  },
  {
    id: 'app.buttons.verify',
    defaultMessage: 'Verify',
  },
  {
    id: 'app.buttons.view',
    defaultMessage: 'View',
  },
  {
    id: 'app.buttons.yes',
    defaultMessage: 'Yes',
  },
  {
    id: 'app.clickToReload',
    defaultMessage: 'Please click this button to reload data!',
  },
  {
    id: 'app.continueConfirm',
    defaultMessage: 'Are you sure you would like to continue?',
  },
  {
    id: 'app.dashBoardWidgetSame',
    defaultMessage: 'The same widgets exist and their systems are also the same.',
  },
  {
    id: 'app.dynatraceVerificationError',
    defaultMessage: 'Please validate your envrionment ID and token.',
  },
  {
    id: 'app.dynatraceVerificationSuccessful',
    defaultMessage:
      'Congratulations! Your key verification is successful. We will send you an email when data ingestion is ready to proceed.',
  },
  {
    id: 'app.errors.apiMessage',
    defaultMessage: '{message}',
  },
  {
    id: 'app.errors.badRequest',
    defaultMessage: 'The request parameters are incorrect, please check your input.',
  },
  {
    id: 'app.errors.general',
    defaultMessage: 'There is something wrong, please try again.',
  },
  {
    id: 'app.errors.instanceGroupNotFound',
    defaultMessage: "The group {instanceGroup} doesn't exist. Select another project or group.",
  },
  {
    id: 'app.errors.invalidData',
    defaultMessage: 'The data is invalid, please try again.',
  },
  {
    id: 'app.errors.message',
    defaultMessage: '{message}',
  },
  {
    id: 'app.errors.network',
    defaultMessage: 'Connection timeout, please network connection.',
  },
  {
    id: 'app.errors.noInstance',
    defaultMessage: 'There is no instance has log entries during the time period, please choose another time period',
  },
  {
    id: 'app.errors.noLogProject',
    defaultMessage: 'There is no log type project, please go to <a>Settings</a> to add a new one.',
  },
  {
    id: 'app.errors.notFound',
    defaultMessage: 'The request resource does not exist',
  },
  {
    id: 'app.errors.projectNotFound',
    defaultMessage: "The project {projectName} doesn't exist. Select another project.",
  },
  {
    id: 'app.errors.server',
    defaultMessage: "Sorry, we've had a server error, Please refresh and try again.",
  },
  {
    id: 'app.fields.abnomalAndNormal',
    defaultMessage: 'Abnomal/Normal',
  },
  {
    id: 'app.fields.abnomalInstances',
    defaultMessage: 'Abnomal Instances',
  },
  {
    id: 'app.fields.abnomalMetrics',
    defaultMessage: 'Abnomal Metrics',
  },
  {
    id: 'app.fields.actionStatus',
    defaultMessage: 'Action status',
  },
  {
    id: 'app.fields.alerts',
    defaultMessage: 'Alerts',
  },
  {
    id: 'app.fields.alertThreshold',
    defaultMessage: 'Alert threshold',
  },
  {
    id: 'app.fields.alertThresholds',
    defaultMessage: 'Alert thresholds',
  },
  {
    id: 'app.fields.alertThresholdsAndDetectionType',
    defaultMessage: 'Alert thresholds and detection type',
  },
  {
    id: 'app.fields.all',
    defaultMessage: 'All',
  },
  {
    id: 'app.fields.and',
    defaultMessage: 'and',
  },
  {
    id: 'app.fields.anomalous',
    defaultMessage: 'Anomalies',
  },
  {
    id: 'app.fields.anomalyDetectionSetting',
    defaultMessage: 'Anomaly detection setting',
  },
  {
    id: 'app.fields.Apr',
    defaultMessage: 'Apr',
  },
  {
    id: 'app.fields.at',
    defaultMessage: 'at',
  },
  {
    id: 'app.fields.Aug',
    defaultMessage: 'Aug',
  },
  {
    id: 'app.fields.avg',
    defaultMessage: 'Avg',
  },
  {
    id: 'app.fields.bothThresholdsAlert',
    defaultMessage: 'Thresholds for positive and negative anomaly detection have conflicts',
  },
  {
    id: 'app.fields.calculationEndDate',
    defaultMessage: 'Calculation end date',
  },
  {
    id: 'app.fields.calculationStartDate',
    defaultMessage: 'Calculation start date',
  },
  {
    id: 'app.fields.category',
    defaultMessage: 'Category',
  },
  {
    id: 'app.fields.causal',
    defaultMessage: 'Causal Summary',
  },
  {
    id: 'app.fields.changePassword',
    defaultMessage: 'Change Password',
  },
  {
    id: 'app.fields.changes',
    defaultMessage: 'Changes',
  },
  {
    id: 'app.fields.channel',
    defaultMessage: 'Channel',
  },
  {
    id: 'app.fields.checkAll',
    defaultMessage: 'Check all',
  },
  {
    id: 'app.fields.checkedOff',
    defaultMessage: 'off',
  },
  {
    id: 'app.fields.checkedOn',
    defaultMessage: 'on',
  },
  {
    id: 'app.fields.clickForDetails',
    defaultMessage: 'Click for details',
  },
  {
    id: 'app.fields.clientId',
    defaultMessage: 'Client ID',
  },
  {
    id: 'app.fields.clientSecret',
    defaultMessage: 'Client Secret',
  },
  {
    id: 'app.fields.command',
    defaultMessage: 'Command',
  },
  {
    id: 'app.fields.companyName',
    defaultMessage: 'Company Name',
  },
  {
    id: 'app.fields.completeYourSignup',
    defaultMessage: 'Complete Your Signup',
  },
  {
    id: 'app.fields.component',
    defaultMessage: 'Component',
  },
  {
    id: 'app.fields.config',
    defaultMessage: 'Config',
  },
  {
    id: 'app.fields.configPatternNameRegex',
    defaultMessage: 'Config pattern name regex',
  },
  {
    id: 'app.fields.configuration',
    defaultMessage: 'Configuration',
  },
  {
    id: 'app.fields.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  {
    id: 'app.fields.contactPhone',
    defaultMessage: 'Contact Phone',
  },
  {
    id: 'app.fields.container',
    defaultMessage: 'Container',
  },
  {
    id: 'app.fields.containerCount',
    defaultMessage: 'Container count',
  },
  {
    id: 'app.fields.count',
    defaultMessage: 'Count',
  },
  {
    id: 'app.fields.currentScore',
    defaultMessage: 'Current Health Score',
  },
  {
    id: 'app.fields.currentTime',
    defaultMessage: 'Current Time',
  },
  {
    id: 'app.fields.custom',
    defaultMessage: 'Custom',
  },
  {
    id: 'app.fields.date',
    defaultMessage: 'Date',
  },
  {
    id: 'app.fields.dateRange',
    defaultMessage: 'Date Range',
  },
  {
    id: 'app.fields.day',
    defaultMessage: 'day',
  },
  {
    id: 'app.fields.Day',
    defaultMessage: 'Day',
  },
  {
    id: 'app.fields.days',
    defaultMessage: 'days',
  },
  {
    id: 'app.fields.Dec',
    defaultMessage: 'Dec',
  },
  {
    id: 'app.fields.default',
    defaultMessage: 'Default',
  },
  {
    id: 'app.fields.defaultTimezone',
    defaultMessage: 'Timezone',
  },
  {
    id: 'app.fields.delay',
    defaultMessage: 'Delay',
  },
  {
    id: 'app.fields.details',
    defaultMessage: 'Details',
  },
  {
    id: 'app.fields.detected',
    defaultMessage: 'Detected',
  },
  {
    id: 'app.fields.detectionRegex',
    defaultMessage: 'Detection regex',
  },
  {
    id: 'app.fields.domainName',
    defaultMessage: 'Domain Name',
  },
  {
    id: 'app.fields.dragAndDrop',
    defaultMessage: 'Drag and drop',
  },
  {
    id: 'app.fields.duration',
    defaultMessage: 'Duration',
  },
  {
    id: 'app.fields.ellipsis',
    defaultMessage: 'Ellipsis',
  },
  {
    id: 'app.fields.email',
    defaultMessage: 'Email',
  },
  {
    id: 'app.fields.emailAddress',
    defaultMessage: 'Email Address',
  },
  {
    id: 'app.fields.encryptedLicenseKey',
    defaultMessage: 'Encrypted License Key',
  },
  {
    id: 'app.fields.end',
    defaultMessage: 'End',
  },
  {
    id: 'app.fields.endDate',
    defaultMessage: 'End date',
  },
  {
    id: 'app.fields.endMonth',
    defaultMessage: 'End Month',
  },
  {
    id: 'app.fields.endTime',
    defaultMessage: 'End time',
  },
  {
    id: 'app.fields.environment',
    defaultMessage: 'Environment',
  },
  {
    id: 'app.fields.error',
    defaultMessage: 'error',
  },
  {
    id: 'app.fields.every',
    defaultMessage: 'Every',
  },
  {
    id: 'app.fields.excludingKeywords',
    defaultMessage: 'Excluding Keyword(s)',
  },
  {
    id: 'app.fields.expand',
    defaultMessage: 'Expand',
  },
  {
    id: 'app.fields.exportAs',
    defaultMessage: 'Export as',
  },
  {
    id: 'app.fields.Feb',
    defaultMessage: 'Feb',
  },
  {
    id: 'app.fields.fname',
    defaultMessage: 'First Name',
  },
  {
    id: 'app.fields.from',
    defaultMessage: 'From',
  },
  {
    id: 'app.fields.fullName',
    defaultMessage: 'Full Name',
  },
  {
    id: 'app.fields.group',
    defaultMessage: 'Group',
  },
  {
    id: 'app.fields.hideChartsWithoutData',
    defaultMessage: 'Hide charts without data',
  },
  {
    id: 'app.fields.hour',
    defaultMessage: 'hour',
  },
  {
    id: 'app.fields.hours',
    defaultMessage: 'hours',
  },
  {
    id: 'app.fields.icon',
    defaultMessage: 'Icon',
  },
  {
    id: 'app.fields.idpName',
    defaultMessage: 'IDP Name',
  },
  {
    id: 'app.fields.idpType',
    defaultMessage: 'IDP Type',
  },
  {
    id: 'app.fields.ignore',
    defaultMessage: 'Ignore',
  },
  {
    id: 'app.fields.impactedComponents',
    defaultMessage: 'Impacted components',
  },
  {
    id: 'app.fields.impactedContainers',
    defaultMessage: 'Impacted containers',
  },
  {
    id: 'app.fields.impactedInstances',
    defaultMessage: 'Impacted instances',
  },
  {
    id: 'app.fields.impactedPods',
    defaultMessage: 'Impacted pods',
  },
  {
    id: 'app.fields.incident',
    defaultMessage: 'Incident',
  },
  {
    id: 'app.fields.incidentId',
    defaultMessage: 'Incident Id',
  },
  {
    id: 'app.fields.incidentPattern',
    defaultMessage: 'Incident Pattern',
  },
  {
    id: 'app.fields.incidentRegex',
    defaultMessage: 'Incident regex',
  },
  {
    id: 'app.fields.info',
    defaultMessage: 'info',
  },
  {
    id: 'app.fields.inputRequired',
    defaultMessage: 'This input is required',
  },
  {
    id: 'app.fields.instance',
    defaultMessage: 'Instance',
  },
  {
    id: 'app.fields.instanceCount',
    defaultMessage: 'Instance count',
  },
  {
    id: 'app.fields.instanceDisplayName',
    defaultMessage: 'Instance display name',
  },
  {
    id: 'app.fields.instanceName',
    defaultMessage: 'Instance name',
  },
  {
    id: 'app.fields.instanceNameCheck',
    defaultMessage: 'Cannot add the :_[] into the instance name',
  },
  {
    id: 'app.fields.instanceRegex',
    defaultMessage: 'Instance regex',
  },
  {
    id: 'app.fields.integrations',
    defaultMessage: 'Integrations',
  },
  {
    id: 'app.fields.interval',
    defaultMessage: 'Interval',
  },
  {
    id: 'app.fields.intervalMins',
    defaultMessage: 'Interval(mins)',
  },
  {
    id: 'app.fields.invalidValueThreshold',
    defaultMessage: 'Invalid value threshold',
  },
  {
    id: 'app.fields.investigate',
    defaultMessage: 'Investigate',
  },
  {
    id: 'app.fields.Jan',
    defaultMessage: 'Jan',
  },
  {
    id: 'app.fields.Jul',
    defaultMessage: 'Jul',
  },
  {
    id: 'app.fields.jump',
    defaultMessage: 'Jump',
  },
  {
    id: 'app.fields.jumpToExternalSystem',
    defaultMessage: 'Jump to external system',
  },
  {
    id: 'app.fields.jumpToLinkedLogProjects',
    defaultMessage: 'Jump to linked log projects',
  },
  {
    id: 'app.fields.jumpToLinkedProjects',
    defaultMessage: 'Jump to linked metric projects',
  },
  {
    id: 'app.fields.jumpToLogAnalysis',
    defaultMessage: 'Jump to log analysis',
  },
  {
    id: 'app.fields.jumpToRCA',
    defaultMessage: 'Jump to RCA',
  },
  {
    id: 'app.fields.Jun',
    defaultMessage: 'Jun',
  },
  {
    id: 'app.fields.keywords',
    defaultMessage: 'Keyword(s)',
  },
  {
    id: 'app.fields.kpi',
    defaultMessage: 'KPI',
  },
  {
    id: 'app.fields.language',
    defaultMessage: 'Language',
  },
  {
    id: 'app.fields.lastSoftwareUpdateTime',
    defaultMessage: 'Last Software Update Time',
  },
  {
    id: 'app.fields.licenseKey',
    defaultMessage: 'License Key',
  },
  {
    id: 'app.fields.lname',
    defaultMessage: 'Last Name',
  },
  {
    id: 'app.fields.log',
    defaultMessage: 'Log',
  },
  {
    id: 'app.fields.logicPodID',
    defaultMessage: 'Logic pod ID',
  },
  {
    id: 'app.fields.logKeywordsFields',
    defaultMessage: 'Anomaly Keywords',
  },
  {
    id: 'app.fields.mappingKey',
    defaultMessage: 'mapping Key',
  },
  {
    id: 'app.fields.Mar',
    defaultMessage: 'Mar',
  },
  {
    id: 'app.fields.max',
    defaultMessage: 'Max',
  },
  {
    id: 'app.fields.maxCount',
    defaultMessage: 'Max Count',
  },
  {
    id: 'app.fields.maxProb',
    defaultMessage: 'Max Prob',
  },
  {
    id: 'app.fields.May',
    defaultMessage: 'May',
  },
  {
    id: 'app.fields.metric',
    defaultMessage: 'Metric',
  },
  {
    id: 'app.fields.metricCount',
    defaultMessage: 'Metric count',
  },
  {
    id: 'app.fields.min',
    defaultMessage: 'Min',
  },
  {
    id: 'app.fields.minute',
    defaultMessage: 'minute',
  },
  {
    id: 'app.fields.minutes',
    defaultMessage: 'minutes',
  },
  {
    id: 'app.fields.missingData',
    defaultMessage: 'Missing Data',
  },
  {
    id: 'app.fields.mode',
    defaultMessage: 'Mode',
  },
  {
    id: 'app.fields.modelType',
    defaultMessage: 'Model Type',
  },
  {
    id: 'app.fields.month',
    defaultMessage: 'month',
  },
  {
    id: 'app.fields.Month',
    defaultMessage: 'Month',
  },
  {
    id: 'app.fields.months',
    defaultMessage: 'months',
  },
  {
    id: 'app.fields.name',
    defaultMessage: 'Name',
  },
  {
    id: 'app.fields.negativeAnomalyDetection',
    defaultMessage: 'Negative (lower than normal) anomaly detection',
  },
  {
    id: 'app.fields.negativeThresholdsAlert',
    defaultMessage: 'Thresholds for negative anomaly detection have conflicts',
  },
  {
    id: 'app.fields.newPassword',
    defaultMessage: 'New Password',
  },
  {
    id: 'app.fields.noAlertMax',
    defaultMessage: 'No alert max',
  },
  {
    id: 'app.fields.noAlertMin',
    defaultMessage: 'No alert min',
  },
  {
    id: 'app.fields.normalInstances',
    defaultMessage: 'Normal Instances',
  },
  {
    id: 'app.fields.normalMetrics',
    defaultMessage: 'Normal Metrics',
  },
  {
    id: 'app.fields.Notes',
    defaultMessage: 'Notes',
  },
  {
    id: 'app.fields.Nov',
    defaultMessage: 'Nov',
  },
  {
    id: 'app.fields.Oct',
    defaultMessage: 'Oct',
  },
  {
    id: 'app.fields.on',
    defaultMessage: 'on',
  },
  {
    id: 'app.fields.operation',
    defaultMessage: 'Operation',
  },
  {
    id: 'app.fields.options',
    defaultMessage: 'Options',
  },
  {
    id: 'app.fields.order',
    defaultMessage: 'Order',
  },
  {
    id: 'app.fields.otherIntegrations',
    defaultMessage: 'Other integrations',
  },
  {
    id: 'app.fields.owner',
    defaultMessage: 'Owner',
  },
  {
    id: 'app.fields.parameter',
    defaultMessage: 'Script parameter',
  },
  {
    id: 'app.fields.password',
    defaultMessage: 'Password',
  },
  {
    id: 'app.fields.pastTime',
    defaultMessage: 'Past Time',
  },
  {
    id: 'app.fields.pattern',
    defaultMessage: 'Pattern',
  },
  {
    id: 'app.fields.patternIdName',
    defaultMessage: 'Pattern ID/Name',
  },
  {
    id: 'app.fields.patternName',
    defaultMessage: 'Pattern name',
  },
  {
    id: 'app.fields.patternNameRegex',
    defaultMessage: 'Pattern name regex',
  },
  {
    id: 'app.fields.Pause',
    defaultMessage: 'Pause',
  },
  {
    id: 'app.fields.phoneNumber',
    defaultMessage: 'Phone Number',
  },
  {
    id: 'app.fields.physicalPodID',
    defaultMessage: 'Physical pod ID',
  },
  {
    id: 'app.fields.positiveAnomalyDetection',
    defaultMessage: 'Positive (higher than normal) anomaly detection',
  },
  {
    id: 'app.fields.positiveThresholdsAlert',
    defaultMessage: 'Thresholds for positive anomaly detection have conflicts',
  },
  {
    id: 'app.fields.predicted',
    defaultMessage: 'Predicted',
  },
  {
    id: 'app.fields.predictedOccurrence',
    defaultMessage: 'Predicted Occurrence Time',
  },
  {
    id: 'app.fields.prob',
    defaultMessage: 'Prob',
  },
  {
    id: 'app.fields.probability',
    defaultMessage: 'Probability',
  },
  {
    id: 'app.fields.processCrash',
    defaultMessage: 'Process Crash',
  },
  {
    id: 'app.fields.project',
    defaultMessage: 'Project',
  },
  {
    id: 'app.fields.projectDisplayName',
    defaultMessage: 'Project Display Name',
  },
  {
    id: 'app.fields.projects',
    defaultMessage: 'Projects',
  },
  {
    id: 'app.fields.promotionCode',
    defaultMessage: 'Promotion Code',
  },
  {
    id: 'app.fields.query',
    defaultMessage: 'Query',
  },
  {
    id: 'app.fields.RCA',
    defaultMessage: 'RCA',
  },
  {
    id: 'app.fields.recommendation',
    defaultMessage: 'Recommendation',
  },
  {
    id: 'app.fields.regex',
    defaultMessage: 'Regex',
  },
  {
    id: 'app.fields.relevance',
    defaultMessage: 'Relevance',
  },
  {
    id: 'app.fields.resultEndDate',
    defaultMessage: 'Result end date',
  },
  {
    id: 'app.fields.resultStartDate',
    defaultMessage: 'Result start date',
  },
  {
    id: 'app.fields.script',
    defaultMessage: 'Script file name',
  },
  {
    id: 'app.fields.second',
    defaultMessage: 'second',
  },
  {
    id: 'app.fields.seconds',
    defaultMessage: 'seconds',
  },
  {
    id: 'app.fields.seeOnly',
    defaultMessage: 'See Only',
  },
  {
    id: 'app.fields.selectAll',
    defaultMessage: 'Select all',
  },
  {
    id: 'app.fields.selectAllAnomaly',
    defaultMessage: 'Select All Anomalous',
  },
  {
    id: 'app.fields.selectAllAnomalyThisPage',
    defaultMessage: 'Select all anomalous in current page',
  },
  {
    id: 'app.fields.selectAllThisPage',
    defaultMessage: 'Select all in current page',
  },
  {
    id: 'app.fields.selectMatchedOnly',
    defaultMessage: 'Select matched only',
  },
  {
    id: 'app.fields.selectTime',
    defaultMessage: 'Select time',
  },
  {
    id: 'app.fields.selectTimezone',
    defaultMessage: 'Please select timezone to update',
  },
  {
    id: 'app.fields.Sep',
    defaultMessage: 'Sep',
  },
  {
    id: 'app.fields.serverAddress',
    defaultMessage: 'Server Address',
  },
  {
    id: 'app.fields.signUp',
    defaultMessage: 'Sign Up',
  },
  {
    id: 'app.fields.signupCode',
    defaultMessage: 'Signup Code',
  },
  {
    id: 'app.fields.similarityLevel',
    defaultMessage: 'Similarity Level',
  },
  {
    id: 'app.fields.softwareUpdateContext',
    defaultMessage: 'Software Update Context',
  },
  {
    id: 'app.fields.softwareUpdateInformation',
    defaultMessage: 'Update Information',
  },
  {
    id: 'app.fields.sort',
    defaultMessage: 'Sort',
  },
  {
    id: 'app.fields.sorting',
    defaultMessage: 'Sorting',
  },
  {
    id: 'app.fields.start',
    defaultMessage: 'Start',
  },
  {
    id: 'app.fields.startDate',
    defaultMessage: 'Start date',
  },
  {
    id: 'app.fields.startMonth',
    defaultMessage: 'Start Month',
  },
  {
    id: 'app.fields.startTime',
    defaultMessage: 'Start time',
  },
  {
    id: 'app.fields.statistics',
    defaultMessage: 'Statistics',
  },
  {
    id: 'app.fields.status',
    defaultMessage: 'Status',
  },
  {
    id: 'app.fields.success',
    defaultMessage: 'success',
  },
  {
    id: 'app.fields.system',
    defaultMessage: 'System',
  },
  {
    id: 'app.fields.systemId',
    defaultMessage: 'System Id',
  },
  {
    id: 'app.fields.systemList',
    defaultMessage: 'System List',
  },
  {
    id: 'app.fields.systemListSaveTitle',
    defaultMessage: 'Hide unchecked system and save system list order',
  },
  {
    id: 'app.fields.systemName',
    defaultMessage: 'System name',
  },
  {
    id: 'app.fields.tempPassword',
    defaultMessage: 'Temp Password',
  },
  {
    id: 'app.fields.tenant',
    defaultMessage: 'Tenant',
  },
  {
    id: 'app.fields.thresholds',
    defaultMessage: 'thresholds',
  },
  {
    id: 'app.fields.time',
    defaultMessage: 'Time',
  },
  {
    id: 'app.fields.timelines',
    defaultMessage: 'Timelines',
  },
  {
    id: 'app.fields.timezone',
    defaultMessage: 'timezone',
  },
  {
    id: 'app.fields.timezoneCaptical',
    defaultMessage: 'Timezone',
  },
  {
    id: 'app.fields.title',
    defaultMessage: 'Title',
  },
  {
    id: 'app.fields.to',
    defaultMessage: 'To',
  },
  {
    id: 'app.fields.today',
    defaultMessage: 'Today',
  },
  {
    id: 'app.fields.token',
    defaultMessage: 'Token',
  },
  {
    id: 'app.fields.tomorrow',
    defaultMessage: 'Tomorrow',
  },
  {
    id: 'app.fields.topK',
    defaultMessage: 'Top Ranked',
  },
  {
    id: 'app.fields.topKPatterns',
    defaultMessage: 'Top K Patterns',
  },
  {
    id: 'app.fields.totalCount',
    defaultMessage: 'Total count',
  },
  {
    id: 'app.fields.traces',
    defaultMessage: 'Traces',
  },
  {
    id: 'app.fields.trainingWindow',
    defaultMessage: 'Training Window(days)',
  },
  {
    id: 'app.fields.triggeredActionTime',
    defaultMessage: 'Triggered Action Time',
  },
  {
    id: 'app.fields.type',
    defaultMessage: 'Type',
  },
  {
    id: 'app.fields.unignore',
    defaultMessage: 'Unignore',
  },
  {
    id: 'app.fields.unique',
    defaultMessage: 'Unique',
  },
  {
    id: 'app.fields.uniqueCount',
    defaultMessage: 'Unique count',
  },
  {
    id: 'app.fields.updateTimezone',
    defaultMessage: 'Update Timezone',
  },
  {
    id: 'app.fields.uploadNewData',
    defaultMessage: 'Upload new data',
  },
  {
    id: 'app.fields.useHealthView',
    defaultMessage:
      'Welcome to the unified health view of your systems. Please click on any card to view the details of detected insights.',
  },
  {
    id: 'app.fields.user',
    defaultMessage: 'User',
  },
  {
    id: 'app.fields.userName',
    defaultMessage: 'User Name',
  },
  {
    id: 'app.fields.value',
    defaultMessage: 'Value',
  },
  {
    id: 'app.fields.viewExistingData',
    defaultMessage: 'View existing data',
  },
  {
    id: 'app.fields.viewResults',
    defaultMessage: 'View results',
  },
  {
    id: 'app.fields.waiting',
    defaultMessage: 'Waiting',
  },
  {
    id: 'app.fields.warning',
    defaultMessage: 'warning',
  },
  {
    id: 'app.fields.Warning',
    defaultMessage: 'Warning',
  },
  {
    id: 'app.fields.week',
    defaultMessage: 'week',
  },
  {
    id: 'app.fields.welcomeToInsightFinder',
    defaultMessage: 'Welcome to InsightFinder!',
  },
  {
    id: 'app.fields.year',
    defaultMessage: 'year',
  },
  {
    id: 'app.fields.years',
    defaultMessage: 'years',
  },
  {
    id: 'app.fields.yesterday',
    defaultMessage: 'Yesterday',
  },
  {
    id: 'app.goodAfternoon',
    defaultMessage: 'Good afternoon {userName}!',
  },
  {
    id: 'app.goodEvening',
    defaultMessage: 'Good evening {userName}!',
  },
  {
    id: 'app.goodMorning',
    defaultMessage: 'Good morning {userName}!',
  },
  {
    id: 'app.licenseValidFaild',
    defaultMessage:
      'Your InsightFinder license is expired. Please contact your InsightFinder representative to renew or extend your license.',
  },
  {
    id: 'app.licenseWillExpired',
    defaultMessage:
      'Your InsightFinder license will expire at {date}. Please contact your InsightFinder representative to renew or extend your license.',
  },
  {
    id: 'app.menus.accountProfile',
    defaultMessage: 'Account Profile',
  },
  {
    id: 'app.menus.actions',
    defaultMessage: 'Actions',
  },
  {
    id: 'app.menus.alertAnalysis',
    defaultMessage: 'Alert/Incident Analysis',
  },
  {
    id: 'app.menus.analysis',
    defaultMessage: 'Analysis',
  },
  {
    id: 'app.menus.automation',
    defaultMessage: 'Automation',
  },
  {
    id: 'app.menus.bugRepository',
    defaultMessage: 'Bug Repository',
  },
  {
    id: 'app.menus.calendar',
    defaultMessage: 'Calendar',
  },
  {
    id: 'app.menus.causalAnalysis',
    defaultMessage: 'Causal Analysis',
  },
  {
    id: 'app.menus.dashboard',
    defaultMessage: 'Dashboard',
  },
  {
    id: 'app.menus.eventHistory',
    defaultMessage: 'History',
  },
  {
    id: 'app.menus.events',
    defaultMessage: 'Events',
  },
  {
    id: 'app.menus.fileAnalysis',
    defaultMessage: 'Anomaly Detection',
  },
  {
    id: 'app.menus.flags',
    defaultMessage: 'Flags',
  },
  {
    id: 'app.menus.gettingStart',
    defaultMessage: 'Getting Start',
  },
  {
    id: 'app.menus.globalCapacityView',
    defaultMessage: 'Capacity Planning',
  },
  {
    id: 'app.menus.globalHealthView',
    defaultMessage: 'Unified Health View',
  },
  {
    id: 'app.menus.globalSystemAlertCause',
    defaultMessage: 'Alert Insight',
  },
  {
    id: 'app.menus.globalSystemCorrectlyPredicted',
    defaultMessage: 'Correctly Predicted Incidents',
  },
  {
    id: 'app.menus.globalSystemIgnoredIncident',
    defaultMessage: 'Ignored Incidents',
  },
  {
    id: 'app.menus.globalSystemInsights',
    defaultMessage: 'Insights Dashboard',
  },
  {
    id: 'app.menus.globalSystemKnowledgeBase',
    defaultMessage: 'Knowledge Base',
  },
  {
    id: 'app.menus.globalSystemPrediction',
    defaultMessage: 'Incident Prediction',
  },
  {
    id: 'app.menus.globalSystemRootCause',
    defaultMessage: 'Incident Investigation',
  },
  {
    id: 'app.menus.globalView',
    defaultMessage: 'Global View',
  },
  {
    id: 'app.menus.guidedTour',
    defaultMessage: 'Guided Tour',
  },
  {
    id: 'app.menus.help',
    defaultMessage: 'Help',
  },
  {
    id: 'app.menus.historicalMaxScores',
    defaultMessage: 'Historical Max Anomaly Scores',
  },
  {
    id: 'app.menus.insightQuery',
    defaultMessage: 'Insight Query',
  },
  {
    id: 'app.menus.insights',
    defaultMessage: 'Insights',
  },
  {
    id: 'app.menus.issues',
    defaultMessage: 'Issues',
  },
  {
    id: 'app.menus.jobAnalysis',
    defaultMessage: 'Job Analysis',
  },
  {
    id: 'app.menus.linechart',
    defaultMessage: 'Line Chart',
  },
  {
    id: 'app.menus.logAnalysis',
    defaultMessage: 'Log/Trace Analysis',
  },
  {
    id: 'app.menus.logQuery',
    defaultMessage: 'Log Query',
  },
  {
    id: 'app.menus.metricAnalysis',
    defaultMessage: 'Metric Analysis',
  },
  {
    id: 'app.menus.metricAppForecast',
    defaultMessage: 'App Forecast',
  },
  {
    id: 'app.menus.patterns',
    defaultMessage: 'Patterns',
  },
  {
    id: 'app.menus.prediction',
    defaultMessage: 'Prediction',
  },
  {
    id: 'app.menus.rootCauses',
    defaultMessage: 'Root Causes',
  },
  {
    id: 'app.menus.serviceMap',
    defaultMessage: 'Service Map',
  },
  {
    id: 'app.menus.settings',
    defaultMessage: 'Settings',
  },
  {
    id: 'app.menus.signout',
    defaultMessage: 'Sign out',
  },
  {
    id: 'app.menus.system',
    defaultMessage: 'System',
  },
  {
    id: 'app.menus.themeMode.dark',
    defaultMessage: 'Light / • Dark',
  },
  {
    id: 'app.menus.themeMode.light',
    defaultMessage: '• Light / Dark',
  },
  {
    id: 'app.messageTitle',
    defaultMessage: 'This is a {type} message',
  },
  {
    id: 'app.previousConfirm',
    defaultMessage: 'Are you sure you want to overwrite the previous file?',
  },
  {
    id: 'app.projectNotBelongUser',
    defaultMessage: 'The project {projectName} does not belong to the user {userName}!',
  },
  {
    id: 'app.removeProjectAllRule',
    defaultMessage: 'This action will delete rules belong to this project: {projectName}.',
  },
  {
    id: 'app.submit',
    defaultMessage: 'Submit',
  },
  {
    id: 'app.timelineDetectedEvents',
    defaultMessage: 'events are detected during {start} ~ {end}',
  },
  {
    id: 'app.timelinePredictedEvents',
    defaultMessage: 'events are predicted during {start} ~ {end}',
  },
  {
    id: 'app.updateUserInfo',
    defaultMessage: 'Hi {userName}, please update your user information.',
  },
  {
    id: 'app.uploadAvatar',
    defaultMessage: 'Upload Avatar',
  },
  {
    id: 'app.uploadAvatarWithJPG',
    defaultMessage: 'You can only upload JPG/PNG file!',
  },
  {
    id: 'app.uploadAvatarWithLimit',
    defaultMessage: 'Image must smaller than 100KB!',
  },
  {
    id: 'app.userAccountInformation',
    defaultMessage: 'User Account Information',
  },
  {
    id: 'app.userNotFound',
    defaultMessage: 'User does not exist.',
  },
  {
    id: 'app.waitingDownload',
    defaultMessage: 'In the process of retrieving data...',
  },
  {
    id: 'auth.backLogin',
    defaultMessage: 'Back login page',
  },
  {
    id: 'auth.buttons.butOtherSignIn',
    defaultMessage: 'Sign in with {name}',
  },
  {
    id: 'auth.buttons.chooseAnotherLoginMethod',
    defaultMessage: 'Choose another log-in method',
  },
  {
    id: 'auth.buttons.next',
    defaultMessage: 'Next',
  },
  {
    id: 'auth.buttons.signin',
    defaultMessage: 'Sign In',
  },
  {
    id: 'auth.buttons.signup',
    defaultMessage: 'Sign Up',
  },
  {
    id: 'auth.errors.errorsClientIdRequired',
    defaultMessage: 'Client ID is required',
  },
  {
    id: 'auth.errors.errorsClientSecretRequired',
    defaultMessage: 'Client secret is required',
  },
  {
    id: 'auth.errors.errorsDomainNameRequired',
    defaultMessage: 'Domain name is required',
  },
  {
    id: 'auth.errors.errorsEmailIncorrect',
    defaultMessage: 'Email is incorrect',
  },
  {
    id: 'auth.errors.errorsEmailIncorrectPerson',
    defaultMessage: 'Please do not use your personal mailbox',
  },
  {
    id: 'auth.errors.errorsEmailRequired',
    defaultMessage: 'Email is required',
  },
  {
    id: 'auth.errors.errorsExpired',
    defaultMessage:
      'Your trial account has expired. Please contact our sales team (sales@insightfinder.com) to upgrade your account.',
  },
  {
    id: 'auth.errors.errorsIDPNameRequired',
    defaultMessage: 'IDP name is required',
  },
  {
    id: 'auth.errors.errorsPasswordContain',
    defaultMessage: 'Password must not contain user name',
  },
  {
    id: 'auth.errors.errorsPasswordLength',
    defaultMessage: 'Password must be 8 characters or longer',
  },
  {
    id: 'auth.errors.errorsPasswordLowercase',
    defaultMessage: 'Password must contain at least 1 lowercase alphabetical character',
  },
  {
    id: 'auth.errors.errorsPasswordNumber',
    defaultMessage: 'Password must contain at least 1 numeric character',
  },
  {
    id: 'auth.errors.errorsPasswordRequired',
    defaultMessage: 'Password is required',
  },
  {
    id: 'auth.errors.errorsPasswordSpecialCharacters',
    defaultMessage: 'Password must contain at least one special character',
  },
  {
    id: 'auth.errors.errorsPasswordUppercase',
    defaultMessage: 'Password must contain at least 1 uppercase alphabetical character',
  },
  {
    id: 'auth.errors.errorsPhoneIncorrect',
    defaultMessage: 'Phone number is incorrect',
  },
  {
    id: 'auth.errors.errorsServerAddressRequired',
    defaultMessage: 'Server Address is required',
  },
  {
    id: 'auth.errors.errorsTenantRequired',
    defaultMessage: 'Tenant is required',
  },
  {
    id: 'auth.errors.errorsUserIdpAvailable',
    defaultMessage: 'The chosen IDP is not available.',
  },
  {
    id: 'auth.errors.errorsUserNameContain',
    defaultMessage: 'User name should only contain letters and numbers',
  },
  {
    id: 'auth.errors.errorsUserNotExist',
    defaultMessage: 'Sorry, this user is not exist. Please contact InsightFinder and try again.',
  },
  {
    id: 'auth.errors.lockAdmin',
    defaultMessage: 'User locked by maximum login times. Please contact InsightFinder to unlock your admin account.',
  },
  {
    id: 'auth.errors.lockUser',
    defaultMessage: 'User locked by maximum login times.',
  },
  {
    id: 'auth.errors.loginFailure',
    defaultMessage: 'Cannot login, please check the network and try again.',
  },
  {
    id: 'auth.errors.noPrivilege',
    defaultMessage:
      'Current logged in user doesn’t have the privilege, need project owner or admin account to finish the action.',
  },
  {
    id: 'auth.errors.passwordNotMatch',
    defaultMessage: 'Password not match',
  },
  {
    id: 'auth.errors.tempPasswordRequired',
    defaultMessage: 'Temp Password is required',
  },
  {
    id: 'auth.errors.tokenInvalid',
    defaultMessage: 'Your session has expired please login again',
  },
  {
    id: 'auth.errors.userNameRequired',
    defaultMessage: 'User name is required',
  },
  {
    id: 'auth.errors.wrongCredential',
    defaultMessage:
      'Sorry, the information entered does not match our records or this account is suspended. Please contact InsightFinder and try again.',
  },
  {
    id: 'auth.expirationDate',
    defaultMessage: 'Expiration date',
  },
  {
    id: 'auth.hint.contactSupport',
    defaultMessage: 'Contact support',
  },
  {
    id: 'auth.hint.experienceInsightFinderFirsthand',
    defaultMessage:
      'Experience InsightFinder firsthand through our comprehensive guided demo. Discover the power to predict incidents and reduce system downtime.',
  },
  {
    id: 'auth.hint.getSandboxStartedForFree',
    defaultMessage: 'Play with InsightFinder Sandbox for free now!',
  },
  {
    id: 'auth.hint.getStartedForFree',
    defaultMessage: 'Get started for free',
  },
  {
    id: 'auth.hint.haveAnAccount',
    defaultMessage: 'Have an account',
  },
  {
    id: 'auth.hint.hintsAgree',
    defaultMessage: 'I agree to',
  },
  {
    id: 'auth.hint.hintsForgot',
    defaultMessage: 'Forgot',
  },
  {
    id: 'auth.hint.hintsGetPassword',
    defaultMessage: 'Get a temporary reset link',
  },
  {
    id: 'auth.hint.hintsGetSignupCode',
    defaultMessage: 'Get a registration code now',
  },
  {
    id: 'auth.hint.hintsGetUsername',
    defaultMessage: 'Retrieve user name by email',
  },
  {
    id: 'auth.hint.hintsHaveSignupCode',
    defaultMessage: 'Already have a registration code?',
  },
  {
    id: 'auth.hint.hintsPassword',
    defaultMessage: 'password?',
  },
  {
    id: 'auth.hint.hintsQuestions',
    defaultMessage: 'Questions?',
  },
  {
    id: 'auth.hint.hintsWaitReviewRequest',
    defaultMessage: 'We will review your request, and will email you the registration code after confirmation.',
  },
  {
    id: 'auth.hint.newuser',
    defaultMessage: 'New user?',
  },
  {
    id: 'auth.hint.or',
    defaultMessage: 'or',
  },
  {
    id: 'auth.hint.ourCloudBasedDemoSandbox',
    defaultMessage:
      'Our cloud-based demo sandbox lets you explore key features including data integration, incident prediction, root cause analysis, proactive automated remediation, and more!',
  },
  {
    id: 'auth.hint.readyToGetStartedWithInsightFinder',
    defaultMessage: 'Ready to get started with InsightFinder',
  },
  {
    id: 'auth.hint.resetPassword',
    defaultMessage: 'Reset Password',
  },
  {
    id: 'auth.hint.signUpHere',
    defaultMessage: 'Sign up here',
  },
  {
    id: 'auth.hint.startedInsightFinderSandbox',
    defaultMessage: 'Get started with the InsightFinder Sandbox!',
  },
  {
    id: 'auth.hint.startedInsightFinderSandboxToday',
    defaultMessage: 'Get Started with the InsightFinder Sandbox Today!',
  },
  {
    id: 'auth.hint.successEmailTip',
    defaultMessage: 'Please check your email to verify your account',
  },
  {
    id: 'auth.hint.userName',
    defaultMessage: 'User name?',
  },
  {
    id: 'auth.login',
    defaultMessage: 'Log in',
  },
  {
    id: 'auth.pleaseLogin',
    defaultMessage: 'Please login',
  },
  {
    id: 'auth.privacy',
    defaultMessage: 'Privacy',
  },
  {
    id: 'auth.privacyPolicy',
    defaultMessage: 'privacy policy',
  },
  {
    id: 'auth.register',
    defaultMessage: 'Register',
  },
  {
    id: 'auth.signupFail',
    defaultMessage: 'Signup failed',
  },
  {
    id: 'auth.signupSuccess',
    defaultMessage: 'Signup success',
  },
  {
    id: 'auth.terms',
    defaultMessage: 'Terms',
  },
  {
    id: 'auth.termsUse',
    defaultMessage: 'terms of use',
  },
  {
    id: 'auth.wrongParameters',
    defaultMessage: 'Wrong parameters: {params}!',
  },
  {
    id: 'causal.alertToIncident',
    defaultMessage: 'Alert {flag} Incident',
  },
  {
    id: 'causal.backToInstances',
    defaultMessage: 'Back to instances relation',
  },
  {
    id: 'causal.causalAlert',
    defaultMessage: 'Alert',
  },
  {
    id: 'causal.causalAnalysisInfo',
    defaultMessage:
      'Causal Analysis is used to analyze the relationship between metrics, log entries, and KPIs. It provides a cross-project view of data by analyzing different projects and instance groups within a Causal Group.',
  },
  {
    id: 'causal.causalGroupInfo',
    defaultMessage:
      'To view an existing Causal Group, select from the left side or click "Create Causal Group" below. After creating the Causal Group, you can create Causal Analyses for different time windows.',
  },
  {
    id: 'causal.causalGroups',
    defaultMessage: 'Causal Groups',
  },
  {
    id: 'causal.causalIncident',
    defaultMessage: 'Incident',
  },
  {
    id: 'causal.causalKey',
    defaultMessage: 'Causal Key',
  },
  {
    id: 'causal.causalLog',
    defaultMessage: 'Log',
  },
  {
    id: 'causal.causalMetric',
    defaultMessage: 'Metric',
  },
  {
    id: 'causal.causalName',
    defaultMessage: 'Causal Name',
  },
  {
    id: 'causal.causalTaskDuration',
    defaultMessage: 'Causal task duration',
  },
  {
    id: 'causal.clickHightlight',
    defaultMessage: 'Click for hightlight',
  },
  {
    id: 'causal.concurrentAnomalies',
    defaultMessage: 'Concurrent Anomalies',
  },
  {
    id: 'causal.concurrentAnomaliesInfo',
    defaultMessage: 'Concurrent Anomalies Info',
  },
  {
    id: 'causal.considerAnomalyAfterIncident',
    defaultMessage: 'Consider post-incident anomalies',
  },
  {
    id: 'causal.correlationInterval',
    defaultMessage: 'Correlation Interval',
  },
  {
    id: 'causal.createCausalAnalysis',
    defaultMessage: 'Create Causal Analysis',
  },
  {
    id: 'causal.createCausalGroup',
    defaultMessage: 'Create Causal Group',
  },
  {
    id: 'causal.createCausalGroupInfo',
    defaultMessage: 'Add Project/Instance Group',
  },
  {
    id: 'causal.createCausalGroupTip',
    defaultMessage: 'Create a Causal Group for one or multiple project/instance group.',
  },
  {
    id: 'causal.createTimeRangeError',
    defaultMessage: 'only {maxNumberOfDays} days is allowed for this causal group',
  },
  {
    id: 'causal.createTimeRangeInfo',
    defaultMessage: 'Select the start time and end time to create the causal analysis',
  },
  {
    id: 'causal.days',
    defaultMessage: 'days',
  },
  {
    id: 'causal.dependencyGraph',
    defaultMessage: 'Dependency Map',
  },
  {
    id: 'causal.disableMetricToIncidentDurationLimitation',
    defaultMessage: 'Disable Metric To Incident Duration Limitation',
  },
  {
    id: 'causal.duration',
    defaultMessage: 'Duration',
  },
  {
    id: 'causal.editCausalGroup',
    defaultMessage: 'Edit causal group',
  },
  {
    id: 'causal.editPairingDuration',
    defaultMessage: 'Edit pairing duration',
  },
  {
    id: 'causal.enableAnomalyToIncidentGapCheck',
    defaultMessage: 'Enable anomaly to incident gap check',
  },
  {
    id: 'causal.enableDependencyFiltering',
    defaultMessage: 'Enable dependency map filtering',
  },
  {
    id: 'causal.enableIncidentOnly',
    defaultMessage: 'Enable incident only',
  },
  {
    id: 'causal.enableInterRelation',
    defaultMessage: 'Enable inter relation',
  },
  {
    id: 'causal.enableLatestComponentLevelCausalValidations',
    defaultMessage: 'Enable latest component level causal validations',
  },
  {
    id: 'causal.enableOrphanIncidentParing',
    defaultMessage: 'Enable orphan incident pairing',
  },
  {
    id: 'causal.eventsRelationLookBackTime',
    defaultMessage: 'Causal Graph Search Range (days)',
  },
  {
    id: 'causal.eventsRelationsForInstance',
    defaultMessage: 'Events relations for instance',
  },
  {
    id: 'causal.filterMetric',
    defaultMessage: 'Metric',
  },
  {
    id: 'causal.filterOptions',
    defaultMessage: 'Filter Options',
  },
  {
    id: 'causal.finish',
    defaultMessage: 'finish',
  },
  {
    id: 'causal.finishTime',
    defaultMessage: 'Finish time',
  },
  {
    id: 'causal.graphView',
    defaultMessage: 'Graph View',
  },
  {
    id: 'causal.group',
    defaultMessage: 'Group',
  },
  {
    id: 'causal.groupInterval',
    defaultMessage: 'Causal Graph Calculation Time Span (days)',
  },
  {
    id: 'causal.hasRelation',
    defaultMessage: 'Inter May Impact',
  },
  {
    id: 'causal.inboundNodeTop',
    defaultMessage: 'Inbound Node{top}',
  },
  {
    id: 'causal.incidentToMetric',
    defaultMessage: 'Incident {flag} Metric',
  },
  {
    id: 'causal.instanceFilter',
    defaultMessage: 'Instance Filter',
  },
  {
    id: 'causal.instanceRelations',
    defaultMessage: 'Instance Relations',
  },
  {
    id: 'causal.intervalLimit',
    defaultMessage: 'Causal interval limit',
  },
  {
    id: 'causal.intraMayNotImapct',
    defaultMessage: 'Intra May Not Imapct',
  },
  {
    id: 'causal.joinDependencyGraph',
    defaultMessage: 'Join Dependency Map',
  },
  {
    id: 'causal.keywordFilter',
    defaultMessage: 'Keyword Filter',
  },
  {
    id: 'causal.kpiPredictionGraph',
    defaultMessage: 'KPI Prediction Graph',
  },
  {
    id: 'causal.lockRelation',
    defaultMessage: 'This relation will set to lock status.',
  },
  {
    id: 'causal.logToAlert',
    defaultMessage: 'Log {flag} Alert',
  },
  {
    id: 'causal.logToIncident',
    defaultMessage: 'Log {flag} Incident',
  },
  {
    id: 'causal.logToLog',
    defaultMessage: 'Log {flag} Log',
  },
  {
    id: 'causal.mayImpact',
    defaultMessage: 'may impact',
  },
  {
    id: 'causal.metricToAlert',
    defaultMessage: 'Metric {flag} Alert',
  },
  {
    id: 'causal.metricToIncident',
    defaultMessage: 'Metric {flag} Incident',
  },
  {
    id: 'causal.metricToLog',
    defaultMessage: 'Metric {flag} Log',
  },
  {
    id: 'causal.metricToMetric',
    defaultMessage: 'Metric {flag} Metric',
  },
  {
    id: 'causal.modality',
    defaultMessage: 'Relation',
  },
  {
    id: 'causal.modalityFilter',
    defaultMessage: 'Modality Filter',
  },
  {
    id: 'causal.noCasualAnomaliesFound',
    defaultMessage: 'No Casual Anomalies found for',
  },
  {
    id: 'causal.noCasualAnomaliesFoundLongInfo',
    defaultMessage:
      'No Casual Anomalies found for this time period, view "Concurrent Anomalies". Or view the relation for instance',
  },
  {
    id: 'causal.noCasualRelationFuond',
    defaultMessage: 'No root cause relation is found',
  },
  {
    id: 'causal.noConcurrentAnomaliesFound',
    defaultMessage: 'No Concurrent Anomalies found for',
  },
  {
    id: 'causal.noConcurrentAnomaliesFoundLongInfo',
    defaultMessage:
      'No Concurrent Anomalies found for this time period, view "Causal Anomalies". Or view the relation for instance',
  },
  {
    id: 'causal.noConcurrentCorrelationFuond',
    defaultMessage: 'No concurrent correlation is found',
  },
  {
    id: 'causal.nodeFilter',
    defaultMessage: 'Node Filter',
  },
  {
    id: 'causal.nodeList',
    defaultMessage: 'Node List',
  },
  {
    id: 'causal.noDependencyRelationFuond',
    defaultMessage: 'No dependency relation is found',
  },
  {
    id: 'causal.nonRootTreeNode',
    defaultMessage: 'Non-root Node',
  },
  {
    id: 'causal.noRelation',
    defaultMessage: 'Inter May Not Imapct',
  },
  {
    id: 'causal.outboundNodeTop',
    defaultMessage: 'Outbound Node{top}',
  },
  {
    id: 'causal.pairingDuration',
    defaultMessage: 'Pairing duration',
  },
  {
    id: 'causal.patternFilter',
    defaultMessage: 'Pattern Filter',
  },
  {
    id: 'causal.probability',
    defaultMessage: 'Probability',
  },
  {
    id: 'causal.relatedAnomalies',
    defaultMessage: 'Related Anomalies',
  },
  {
    id: 'causal.relationEndTime',
    defaultMessage: 'Relation end time',
  },
  {
    id: 'causal.relationInfoStep1',
    defaultMessage: 'anomalies on',
  },
  {
    id: 'causal.relationInfoStep2',
    defaultMessage: 'were followed within {relationTimeThreshold} mins by anomalies on',
  },
  {
    id: 'causal.relationInfoStep3',
    defaultMessage: 'happened concurrently with anomalies on',
  },
  {
    id: 'causal.relationInfoStep4',
    defaultMessage: 'with probability',
  },
  {
    id: 'causal.relationStartTime',
    defaultMessage: 'Relation start time',
  },
  {
    id: 'causal.removeCausalGroup',
    defaultMessage: 'Remove Causal Group',
  },
  {
    id: 'causal.removeRelation',
    defaultMessage: 'This relation will be removed.',
  },
  {
    id: 'causal.requestTime',
    defaultMessage: 'Request time',
  },
  {
    id: 'causal.rerunCausal',
    defaultMessage: 'Rerun Causal',
  },
  {
    id: 'causal.rerunCausalTasks',
    defaultMessage: 'Rerun causal tasks',
  },
  {
    id: 'causal.rerunRelation',
    defaultMessage: 'This relation will be rerun.',
  },
  {
    id: 'causal.rerunTheWholeCausalGraph',
    defaultMessage: 'Rerun the whole causal graph',
  },
  {
    id: 'causal.retentionTime',
    defaultMessage: 'Retention (days)',
  },
  {
    id: 'causal.rootTreeNode',
    defaultMessage: 'Root Node',
  },
  {
    id: 'causal.scope',
    defaultMessage: 'Scope',
  },
  {
    id: 'causal.shareCausalGroup',
    defaultMessage: 'Share Causal Group',
  },
  {
    id: 'causal.sharedUsers',
    defaultMessage: 'Shared users',
  },
  {
    id: 'causal.shareUser',
    defaultMessage: 'Share user',
  },
  {
    id: 'causal.showSubTasks',
    defaultMessage: 'Show Sub Tasks',
  },
  {
    id: 'causal.source',
    defaultMessage: 'Source',
  },
  {
    id: 'causal.sourceCandidateAnomalyTypes',
    defaultMessage: 'Source candidate anomaly types',
  },
  {
    id: 'causal.sourceList',
    defaultMessage: 'Source List',
  },
  {
    id: 'causal.sourceShortDescription',
    defaultMessage: 'Source short description',
  },
  {
    id: 'causal.status',
    defaultMessage: 'Status',
  },
  {
    id: 'causal.target',
    defaultMessage: 'Target',
  },
  {
    id: 'causal.targets',
    defaultMessage: 'Targets',
  },
  {
    id: 'causal.targetShortDescription',
    defaultMessage: 'Target short description',
  },
  {
    id: 'causal.unlockRelation',
    defaultMessage: 'This relation will remove lock status.',
  },
  {
    id: 'causal.viewCausalGraph',
    defaultMessage: 'View Causal Graph',
  },
  {
    id: 'causal.viewRelationForInstance',
    defaultMessage: 'View the relation for instance',
  },
  {
    id: 'dashboard.accuracy',
    defaultMessage: 'Accuracy',
  },
  {
    id: 'dashboard.addContainer',
    defaultMessage: 'Add container',
  },
  {
    id: 'dashboard.addInstance',
    defaultMessage: 'Add instance',
  },
  {
    id: 'dashboard.aggregationMode',
    defaultMessage: 'Aggregation mode',
  },
  {
    id: 'dashboard.alertPatternCountByInstance',
    defaultMessage: 'Alert Pattern Count By Components',
  },
  {
    id: 'dashboard.alertPatternCountByType',
    defaultMessage: 'Alert Pattern Count By Type',
  },
  {
    id: 'dashboard.anomalousLogSize',
    defaultMessage: 'Anomalous log size',
  },
  {
    id: 'dashboard.anomalyChartsFeatureKeyword',
    defaultMessage: 'Log Feature',
  },
  {
    id: 'dashboard.anomalyChartsInstancePercentage',
    defaultMessage: 'Top 10 Instances',
  },
  {
    id: 'dashboard.anomalyChartsLogPercentage',
    defaultMessage: 'Top 10 Log Types',
  },
  {
    id: 'dashboard.anomalyChartsMetricPercentage',
    defaultMessage: 'Top 10 Metrics',
  },
  {
    id: 'dashboard.anomalyCompressionRatio',
    defaultMessage: 'Anomaly compression ratio',
  },
  {
    id: 'dashboard.anomalyDetails',
    defaultMessage: 'Anomaly Details',
  },
  {
    id: 'dashboard.anomalyEventsDetected',
    defaultMessage: 'Detected Anomalous Events',
  },
  {
    id: 'dashboard.anomalyEventsPredicted',
    defaultMessage: 'Predicted Anomalous Events',
  },
  {
    id: 'dashboard.anomalyHistory',
    defaultMessage: 'Anomaly History',
  },
  {
    id: 'dashboard.anomalyInstanceStatistics',
    defaultMessage: 'Anomaly Statistics',
  },
  {
    id: 'dashboard.anomalyScoreCharts',
    defaultMessage: 'Anomaly Score Charts',
  },
  {
    id: 'dashboard.anomalyScoreChartsEndTime',
    defaultMessage: 'Current Time: {nowTime} GMT',
  },
  {
    id: 'dashboard.anomalyStatistics',
    defaultMessage: 'Summary',
  },
  {
    id: 'dashboard.anomalyStatisticsByInstance',
    defaultMessage: 'Anomaly Statistics By Components',
  },
  {
    id: 'dashboard.anomalyStatisticsByType',
    defaultMessage: 'Anomaly Statistics By Type',
  },
  {
    id: 'dashboard.anomalyTimeline',
    defaultMessage: 'Anomalous Event Timelines',
  },
  {
    id: 'dashboard.anomalyTimelineWithTime',
    defaultMessage: 'Anomalous Event Timelines (detection time: {time})',
  },
  {
    id: 'dashboard.assignedTo',
    defaultMessage: 'Assigned to',
  },
  {
    id: 'dashboard.author',
    defaultMessage: 'Author',
  },
  {
    id: 'dashboard.autoFixedIncidents',
    defaultMessage: 'Auto-fixed incidents',
  },
  {
    id: 'dashboard.balloon',
    defaultMessage: 'Balloon',
  },
  {
    id: 'dashboard.bottleneckComponent',
    defaultMessage: 'Bottleneck Component',
  },
  {
    id: 'dashboard.bottleneckContainer',
    defaultMessage: 'Bottleneck Container',
  },
  {
    id: 'dashboard.capacityPlanning',
    defaultMessage: 'Capacity Planning',
  },
  {
    id: 'dashboard.capacityPlanningAnomaly',
    defaultMessage: 'Predicted System Usage Score',
  },
  {
    id: 'dashboard.capacityScore',
    defaultMessage: 'Capacity Score',
  },
  {
    id: 'dashboard.causalAnalysis',
    defaultMessage: 'Causal Analysis',
  },
  {
    id: 'dashboard.causalGraph',
    defaultMessage: 'Causal Graph',
  },
  {
    id: 'dashboard.causalGroup',
    defaultMessage: 'Causal',
  },
  {
    id: 'dashboard.causedByChangeAlert',
    defaultMessage: 'This alert is caused by a change event.',
  },
  {
    id: 'dashboard.causedByChangeAnomaly',
    defaultMessage: 'This anomaly is caused by a change event.',
  },
  {
    id: 'dashboard.causedByChangeIncident',
    defaultMessage: 'This incident is caused by a change event.',
  },
  {
    id: 'dashboard.changeHasNoTrailing',
    defaultMessage: 'This change event does not have trailing results.',
  },
  {
    id: 'dashboard.changeHasTrailing',
    defaultMessage: 'This change event has trailing results.',
  },
  {
    id: 'dashboard.clickBalloonForDetails',
    defaultMessage: 'Click the {name} for details',
  },
  {
    id: 'dashboard.clickForDetails',
    defaultMessage: 'Click For Details',
  },
  {
    id: 'dashboard.clickForLineChart',
    defaultMessage: 'Click for linechart',
  },
  {
    id: 'dashboard.clickToSeeDetails',
    defaultMessage: 'Click to see details',
  },
  {
    id: 'dashboard.component',
    defaultMessage: 'Component',
  },
  {
    id: 'dashboard.compressedLogSize',
    defaultMessage: 'Compressed log size',
  },
  {
    id: 'dashboard.container',
    defaultMessage: 'Container',
  },
  {
    id: 'dashboard.containers',
    defaultMessage: 'Containers',
  },
  {
    id: 'dashboard.correlatedAnomalies',
    defaultMessage: 'Correlated anomalies',
  },
  {
    id: 'dashboard.current',
    defaultMessage: 'Current',
  },
  {
    id: 'dashboard.currentDay',
    defaultMessage: 'Current Day',
  },
  {
    id: 'dashboard.currentDayAvg',
    defaultMessage: 'Current Day Avg',
  },
  {
    id: 'dashboard.currentHour',
    defaultMessage: 'Current Hour',
  },
  {
    id: 'dashboard.currentHourDayPredicted',
    defaultMessage: 'Current Hour, Current Day, Predicted (4 hrs)',
  },
  {
    id: 'dashboard.currentSystemStatus',
    defaultMessage: 'Current System Status',
  },
  {
    id: 'dashboard.dashboardName',
    defaultMessage: 'Dashboard name',
  },
  {
    id: 'dashboard.dataCompressionRatio',
    defaultMessage: 'Overall compression ratio',
  },
  {
    id: 'dashboard.dependencyMaps',
    defaultMessage: 'dependency maps',
  },
  {
    id: 'dashboard.derivedValueModel',
    defaultMessage: 'Derived value model',
  },
  {
    id: 'dashboard.detected',
    defaultMessage: 'Detected',
  },
  {
    id: 'dashboard.detectedAlerts',
    defaultMessage: 'Alerts/Changes',
  },
  {
    id: 'dashboard.detectedAnomalies',
    defaultMessage: 'Detected Anomalies',
  },
  {
    id: 'dashboard.detectedDeployments',
    defaultMessage: 'Change Events',
  },
  {
    id: 'dashboard.detectedIncident',
    defaultMessage: 'Detected Incident',
  },
  {
    id: 'dashboard.detectedIncidents',
    defaultMessage: 'Detected Incidents',
  },
  {
    id: 'dashboard.detectedTrace',
    defaultMessage: 'Trace',
  },
  {
    id: 'dashboard.difference',
    defaultMessage: 'Difference',
  },
  {
    id: 'dashboard.exportSystemHealthScore',
    defaultMessage: 'Export system health score?',
  },
  {
    id: 'dashboard.falseNegativeRate',
    defaultMessage: 'False negative rate',
  },
  {
    id: 'dashboard.falsePositiveRate',
    defaultMessage: 'False positive rate',
  },
  {
    id: 'dashboard.falsePositives',
    defaultMessage: 'False positives',
  },
  {
    id: 'dashboard.filter',
    defaultMessage: 'Filter',
  },
  {
    id: 'dashboard.fixed',
    defaultMessage: 'Fixed',
  },
  {
    id: 'dashboard.fixedDetectedIncidentCount',
    defaultMessage: 'Auto-fixed Incidents',
  },
  {
    id: 'dashboard.fixedDetection',
    defaultMessage: 'Fixed detection',
  },
  {
    id: 'dashboard.fixedPredictedIncidentCount',
    defaultMessage: 'Auto-fixed Incidents',
  },
  {
    id: 'dashboard.fixedPrediction',
    defaultMessage: 'Fixed prediction',
  },
  {
    id: 'dashboard.followOnIncident',
    defaultMessage: 'Followed By Incident',
  },
  {
    id: 'dashboard.groupByComponent',
    defaultMessage: 'Group by component',
  },
  {
    id: 'dashboard.health',
    defaultMessage: 'Health',
  },
  {
    id: 'dashboard.healthChart',
    defaultMessage: 'Health Chart',
  },
  {
    id: 'dashboard.healthChartAnswer',
    defaultMessage: 'Please click and zoom in on the health chart to view likely root causes and anomalies',
  },
  {
    id: 'dashboard.healthScore',
    defaultMessage: 'Health Score',
  },
  {
    id: 'dashboard.healthScoreCharts',
    defaultMessage: 'Health Score Charts',
  },
  {
    id: 'dashboard.high',
    defaultMessage: 'High',
  },
  {
    id: 'dashboard.hourlyAvgAnomalyScore',
    defaultMessage: 'Hourly Avg Anomaly Score',
  },
  {
    id: 'dashboard.ignored',
    defaultMessage: 'Ignored',
  },
  {
    id: 'dashboard.incidentCount',
    defaultMessage: 'Current day incident count',
  },
  {
    id: 'dashboard.incidentCountPagerDuty',
    defaultMessage: 'Current day count from PagerDuty',
  },
  {
    id: 'dashboard.incidentCountServiceNow',
    defaultMessage: 'Current day count from ServiceNow',
  },
  {
    id: 'dashboard.incidentHasRootCause',
    defaultMessage: 'This incident has root cause results.',
  },
  {
    id: 'dashboard.incidentHasTrailing',
    defaultMessage: 'This incident has trailing results.',
  },
  {
    id: 'dashboard.incidents',
    defaultMessage: 'incidents',
  },
  {
    id: 'dashboard.insightdWelcome',
    defaultMessage: 'Welcome {name}! Here are your latest insights as of {week}, {month} {day} at {time}.',
  },
  {
    id: 'dashboard.insightsAlertPatterns',
    defaultMessage: 'What are my top alert patterns in the past 30 days?',
  },
  {
    id: 'dashboard.insightsAlertsSuppressed',
    defaultMessage: 'How many alerts can be suppressed per day on average in the past 30 days?',
  },
  {
    id: 'dashboard.insightsAlertVolume',
    defaultMessage: 'How is my system alerts trending in the past 30 days?',
  },
  {
    id: 'dashboard.insightsAnomalousComponents',
    defaultMessage: 'What are my top anomalous components in the current day?',
  },
  {
    id: 'dashboard.insightsAnomalousInstances',
    defaultMessage: 'What are my top anomalous instances in the current day?',
  },
  {
    id: 'dashboard.insightsDowntimeSavings',
    defaultMessage: 'Downtime savings',
  },
  {
    id: 'dashboard.insightsHealthTrending',
    defaultMessage: 'How is my system health trending in the past 30 days?',
  },
  {
    id: 'dashboard.insightsIncidentPatterns',
    defaultMessage: 'What are my top incident patterns in the past 30 days?',
  },
  {
    id: 'dashboard.insightsIncidentVolume',
    defaultMessage: 'How is my system incidents trending in the past 30 days?',
  },
  {
    id: 'dashboard.insightsInvestigated',
    defaultMessage: 'Who on my team has investigated the most incidents?',
  },
  {
    id: 'dashboard.insightsLaborCostSavings',
    defaultMessage: 'Total opportunity cost saving',
  },
  {
    id: 'dashboard.insightsMTTR',
    defaultMessage: 'MTTR',
  },
  {
    id: 'dashboard.insightsMTTRDescription',
    defaultMessage:
      'Your mean time to resolve incidents based on total incidents from the past 30 days divided by total time from detection to resolution.',
  },
  {
    id: 'dashboard.insightsMTTRTrendVs',
    defaultMessage: 'MTTR trend vs. previous 30 days',
  },
  {
    id: 'dashboard.insightsPredictedIncidents',
    defaultMessage: 'What is the average prediction accuracy in the past 30 days?',
  },
  {
    id: 'dashboard.insightsPreventIncidents',
    defaultMessage: 'How else can I prevent incidents?',
  },
  {
    id: 'dashboard.insightsRootCauseCategories',
    defaultMessage: 'What are my top root cause categories in the current day?',
  },
  {
    id: 'dashboard.insightsRootCauseInstances',
    defaultMessage: 'What are my top root cause instances in the current day?',
  },
  {
    id: 'dashboard.insightsRootCausePatterns',
    defaultMessage: 'What are my top root cause patterns in the current day?',
  },
  {
    id: 'dashboard.insightsSavingsTrendVs',
    defaultMessage: 'Savings trend vs. previous 30 days',
  },
  {
    id: 'dashboard.insightsStatisticsAverageLeadTime',
    defaultMessage: 'Average prediction lead time',
  },
  {
    id: 'dashboard.insightsStatisticsIncidentVolume',
    defaultMessage: 'Incident volume',
  },
  {
    id: 'dashboard.insightsStatisticsIncidentVolumeTrendVs',
    defaultMessage: 'Incidents trend vs. previous 30 days',
  },
  {
    id: 'dashboard.insightsStatisticsTotalMatched',
    defaultMessage: 'Total correctly predicted incidents',
  },
  {
    id: 'dashboard.instance',
    defaultMessage: 'Instance',
  },
  {
    id: 'dashboard.instances',
    defaultMessage: 'Instances',
  },
  {
    id: 'dashboard.intervalHours',
    defaultMessage: 'Interval(hours)',
  },
  {
    id: 'dashboard.kpiViolation',
    defaultMessage: 'KPI Violation',
  },
  {
    id: 'dashboard.latestLogs',
    defaultMessage: 'Latest Logs',
  },
  {
    id: 'dashboard.leadToIncident',
    defaultMessage: 'Lead to incident',
  },
  {
    id: 'dashboard.limitFlag',
    defaultMessage: 'Limit Flag',
  },
  {
    id: 'dashboard.lineChart',
    defaultMessage: 'Line Chart',
  },
  {
    id: 'dashboard.logAnalysis',
    defaultMessage: 'Log Analysis',
  },
  {
    id: 'dashboard.logAnomalies',
    defaultMessage: 'Log Anomalies',
  },
  {
    id: 'dashboard.logAnomaliesEvents',
    defaultMessage: 'Number of Anomalous Log Events',
  },
  {
    id: 'dashboard.logCompressionRatio',
    defaultMessage: 'Log compression ratio',
  },
  {
    id: 'dashboard.logEntries',
    defaultMessage: 'Log Entries',
  },
  {
    id: 'dashboard.logIncidents',
    defaultMessage: 'log incidents',
  },
  {
    id: 'dashboard.low',
    defaultMessage: 'Low',
  },
  {
    id: 'dashboard.machineOutage',
    defaultMessage: 'Machine Outage',
  },
  {
    id: 'dashboard.manualIncidentCreation',
    defaultMessage: 'Manual incident creation',
  },
  {
    id: 'dashboard.matchedDetectedIncident',
    defaultMessage: 'Matched Detected Incident',
  },
  {
    id: 'dashboard.medium',
    defaultMessage: 'Medium',
  },
  {
    id: 'dashboard.mediumHigh',
    defaultMessage: 'Medium High',
  },
  {
    id: 'dashboard.mediumLow',
    defaultMessage: 'Medium Low',
  },
  {
    id: 'dashboard.metricAndLogAnomalys',
    defaultMessage: 'Anomaly Events',
  },
  {
    id: 'dashboard.metricAnomalies',
    defaultMessage: 'Metric Anomalies',
  },
  {
    id: 'dashboard.metricAnomaliesEvents',
    defaultMessage: 'Number of Consolidated Metric Anomaly Events',
  },
  {
    id: 'dashboard.metricIncidents',
    defaultMessage: 'metric incidents',
  },
  {
    id: 'dashboard.metricLogAnomalies',
    defaultMessage: 'Metric Anomalies, Log Anomalies, Predicted',
  },
  {
    id: 'dashboard.metricThresholdViolations',
    defaultMessage: 'Metric Threshold Violations',
  },
  {
    id: 'dashboard.moveToTop',
    defaultMessage: 'Move to top',
  },
  {
    id: 'dashboard.myDashboards',
    defaultMessage: 'My dashboards',
  },
  {
    id: 'dashboard.noCapacitySet',
    defaultMessage: 'For Capacity Planning capabilities, please set up Metric Kpi for monitored Systems.',
  },
  {
    id: 'dashboard.now',
    defaultMessage: 'Now',
  },
  {
    id: 'dashboard.originalLogSize',
    defaultMessage: 'Original log size',
  },
  {
    id: 'dashboard.originalNormalPattern',
    defaultMessage: 'Original normal pattern',
  },
  {
    id: 'dashboard.popularity',
    defaultMessage: 'Popularity',
  },
  {
    id: 'dashboard.predicatedHours',
    defaultMessage: 'Predicted(4hr)',
  },
  {
    id: 'dashboard.predicted',
    defaultMessage: 'Predicted',
  },
  {
    id: 'dashboard.predictedDayAvg',
    defaultMessage: 'Predicted Day Avg',
  },
  {
    id: 'dashboard.predictedIncident',
    defaultMessage: 'Predicted Incident',
  },
  {
    id: 'dashboard.predictedIncidents',
    defaultMessage: 'Predicted Incidents',
  },
  {
    id: 'dashboard.predictionAccuracy',
    defaultMessage: 'Prediction accuracy',
  },
  {
    id: 'dashboard.predictionLeadTime',
    defaultMessage: 'Preventive lead time',
  },
  {
    id: 'dashboard.predictionStatistics',
    defaultMessage: 'Prediction statistics',
  },
  {
    id: 'dashboard.predictionStatisticsByInstance',
    defaultMessage: 'Prediction Statistics By Components',
  },
  {
    id: 'dashboard.predictionStatisticsByType',
    defaultMessage: 'Prediction Statistics',
  },
  {
    id: 'dashboard.predictSystemStatus',
    defaultMessage: 'Predicted System Status',
  },
  {
    id: 'dashboard.previousDay',
    defaultMessage: 'Previous Day',
  },
  {
    id: 'dashboard.previousDayAvg',
    defaultMessage: 'Previous Day Avg',
  },
  {
    id: 'dashboard.priority',
    defaultMessage: 'Priority',
  },
  {
    id: 'dashboard.projectDataCompressionRatio',
    defaultMessage: 'Project data compression ratio',
  },
  {
    id: 'dashboard.projectList',
    defaultMessage: 'Project List',
  },
  {
    id: 'dashboard.reduceContainer',
    defaultMessage: 'Reduce container',
  },
  {
    id: 'dashboard.reduceInstance',
    defaultMessage: 'Reduce instance',
  },
  {
    id: 'dashboard.region',
    defaultMessage: 'Region',
  },
  {
    id: 'dashboard.requestedBy',
    defaultMessage: 'Requested by',
  },
  {
    id: 'dashboard.rootCauses',
    defaultMessage: 'Root Causes',
  },
  {
    id: 'dashboard.score',
    defaultMessage: 'Score',
  },
  {
    id: 'dashboard.selectedRank',
    defaultMessage: 'Selected rank',
  },
  {
    id: 'dashboard.sharedDashboards',
    defaultMessage: 'Shared dashboards',
  },
  {
    id: 'dashboard.showAllEvents',
    defaultMessage: 'Show All Events',
  },
  {
    id: 'dashboard.showDetectedAnomalies',
    defaultMessage: 'Show all detected anomalies',
  },
  {
    id: 'dashboard.showDetectedIncidents',
    defaultMessage: 'Show detected incidents',
  },
  {
    id: 'dashboard.showHistoricalPredictions',
    defaultMessage: 'Show historical predictions',
  },
  {
    id: 'dashboard.showPartEvents',
    defaultMessage: 'Show Last Hour Events',
  },
  {
    id: 'dashboard.showPredictedIncidents',
    defaultMessage: 'Show all predicted incidents',
  },
  {
    id: 'dashboard.softwareUpdates',
    defaultMessage: 'Software Updates',
  },
  {
    id: 'dashboard.suggestedActions',
    defaultMessage: 'Suggested Actions',
  },
  {
    id: 'dashboard.switchToCharts',
    defaultMessage: 'Switch to Charts',
  },
  {
    id: 'dashboard.switchToEvents',
    defaultMessage: 'Switch to Events',
  },
  {
    id: 'dashboard.switchToTimelines',
    defaultMessage: 'Switch to Timelines',
  },
  {
    id: 'dashboard.system',
    defaultMessage: 'System',
  },
  {
    id: 'dashboard.systemHealthScoreAsc',
    defaultMessage: 'System Health Score Ascending',
  },
  {
    id: 'dashboard.systemHealthScoreDesc',
    defaultMessage: 'System Health Score Descending',
  },
  {
    id: 'dashboard.systemNameAsc',
    defaultMessage: 'System Name Ascending',
  },
  {
    id: 'dashboard.systemNameDesc',
    defaultMessage: 'System Name Descending',
  },
  {
    id: 'dashboard.systemPreferences',
    defaultMessage: 'system preferences',
  },
  {
    id: 'dashboard.threshold',
    defaultMessage: 'Threshold',
  },
  {
    id: 'dashboard.ticketNumber',
    defaultMessage: 'Ticket number',
  },
  {
    id: 'dashboard.topLogAnomaly',
    defaultMessage: 'Top Log Anomaly Keywords & Fields',
  },
  {
    id: 'dashboard.topRankedRootCause',
    defaultMessage: 'Number of top ranked root causes to display',
  },
  {
    id: 'dashboard.traceAnomalies',
    defaultMessage: 'Trace Anomalies',
  },
  {
    id: 'dashboard.treeMap',
    defaultMessage: 'Tree Map',
  },
  {
    id: 'dashboard.triangle',
    defaultMessage: 'Triangle',
  },
  {
    id: 'dashboard.truePositiveRate',
    defaultMessage: 'True positive rate',
  },
  {
    id: 'dashboard.truePositives',
    defaultMessage: 'True positives',
  },
  {
    id: 'dashboard.updateRelevance',
    defaultMessage: 'Update Relevance',
  },
  {
    id: 'dashboard.updateTotalMax',
    defaultMessage: 'Update Total Max',
  },
  {
    id: 'dashboard.zoomTimelineTipCheckboxText',
    defaultMessage: 'Thanks, don’t show me this again',
  },
  {
    id: 'dashboard.zoomTimelineTipText',
    defaultMessage: 'To zoom into a specific range of time, click and drag on the timeline with the cursor',
  },
  {
    id: 'dashboard.zoomTimelineTipTitle',
    defaultMessage: 'How to zoom in on the timeline',
  },
  {
    id: 'event.account',
    defaultMessage: 'Account',
  },
  {
    id: 'event.accountGroup',
    defaultMessage: 'Account group',
  },
  {
    id: 'event.accountGroupName',
    defaultMessage: 'Account Group Name',
  },
  {
    id: 'event.accountGroupSearch',
    defaultMessage: 'Account group search',
  },
  {
    id: 'event.action.action',
    defaultMessage: 'Action',
  },
  {
    id: 'event.action.actionData',
    defaultMessage: 'Action data',
  },
  {
    id: 'event.action.actionName',
    defaultMessage: 'Action name',
  },
  {
    id: 'event.action.actionParameter',
    defaultMessage: 'Action parameter',
  },
  {
    id: 'event.action.actionServer',
    defaultMessage: 'Action server',
  },
  {
    id: 'event.action.actionTarget',
    defaultMessage: 'Target',
  },
  {
    id: 'event.action.actionType',
    defaultMessage: 'Action type',
  },
  {
    id: 'event.action.addAction',
    defaultMessage: 'Add action',
  },
  {
    id: 'event.action.addEmail',
    defaultMessage: 'Add Email',
  },
  {
    id: 'event.action.addSavedAction',
    defaultMessage: 'Add Saved actions',
  },
  {
    id: 'event.action.componentName',
    defaultMessage: 'Component name',
  },
  {
    id: 'event.action.createEmailAlert',
    defaultMessage: 'Create email alert',
  },
  {
    id: 'event.action.email',
    defaultMessage: 'Email',
  },
  {
    id: 'event.action.emailAlertType',
    defaultMessage: 'Alert type',
  },
  {
    id: 'event.action.emailRecipients',
    defaultMessage: 'Recipients',
  },
  {
    id: 'event.action.insightFinderNotes',
    defaultMessage: 'InsightFinder Notes',
  },
  {
    id: 'event.action.instanceName',
    defaultMessage: 'Instance name',
  },
  {
    id: 'event.action.lastReporter',
    defaultMessage: 'Last Reporter',
  },
  {
    id: 'event.action.microsoftTeams',
    defaultMessage: 'Microsoft Teams',
  },
  {
    id: 'event.action.microsoftTeamsNotes',
    defaultMessage: 'Microsoft Teams Notes',
  },
  {
    id: 'event.action.noSavedAction',
    defaultMessage: 'No saved action',
  },
  {
    id: 'event.action.noSavedEmailAlerts',
    defaultMessage: 'No saved email alerts',
  },
  {
    id: 'event.action.noSuggestedAction',
    defaultMessage: 'No suggested action',
  },
  {
    id: 'event.action.occurrenceTime',
    defaultMessage: 'occurrence time',
  },
  {
    id: 'event.action.patternName',
    defaultMessage: 'Name',
  },
  {
    id: 'event.action.remove',
    defaultMessage: 'Remove',
  },
  {
    id: 'event.action.replies',
    defaultMessage: 'Replies',
  },
  {
    id: 'event.action.reporter',
    defaultMessage: 'Reporter',
  },
  {
    id: 'event.action.reporterName',
    defaultMessage: 'Reporter name',
  },
  {
    id: 'event.action.reportIncidentOccurrenceTime',
    defaultMessage: 'Incident occurrence time',
  },
  {
    id: 'event.action.saveAction',
    defaultMessage: 'Save action',
  },
  {
    id: 'event.action.saveAlert',
    defaultMessage: 'Save alert',
  },
  {
    id: 'event.action.savedAction',
    defaultMessage: 'Saved actions',
  },
  {
    id: 'event.action.savedEmailAlert',
    defaultMessage: 'Saved email alert',
  },
  {
    id: 'event.action.saveTriage',
    defaultMessage: 'Save triage',
  },
  {
    id: 'event.action.serviceNowNotes',
    defaultMessage: 'ServiceNow Notes',
  },
  {
    id: 'event.action.setPatternName',
    defaultMessage: 'Set name',
  },
  {
    id: 'event.action.slackNotes',
    defaultMessage: 'Slack Notes',
  },
  {
    id: 'event.action.suggestedAction',
    defaultMessage: 'Suggested action',
  },
  {
    id: 'event.action.summaryNotes',
    defaultMessage: 'Summary Notes',
  },
  {
    id: 'event.action.takeAction',
    defaultMessage: 'Take action',
  },
  {
    id: 'event.action.takeActionOnEvent',
    defaultMessage: 'Take action on this event',
  },
  {
    id: 'event.action.triageReport',
    defaultMessage: 'Operator notes',
  },
  {
    id: 'event.action.triggeredTime',
    defaultMessage: 'Triggered time',
  },
  {
    id: 'event.action.viewOperatorNotes',
    defaultMessage: 'View operator notes',
  },
  {
    id: 'event.actions',
    defaultMessage: 'Actions',
  },
  {
    id: 'event.actionsTriggered',
    defaultMessage: 'Actions triggered',
  },
  {
    id: 'event.actionTriggered',
    defaultMessage: 'Action triggered',
  },
  {
    id: 'event.additional',
    defaultMessage: 'Additional',
  },
  {
    id: 'event.addRecommendation',
    defaultMessage: 'Add Recommendation',
  },
  {
    id: 'event.addToCompareList',
    defaultMessage: 'Add to compare list / delete list',
  },
  {
    id: 'event.aggregationInterval',
    defaultMessage: 'Aggregation Interval',
  },
  {
    id: 'event.alertAnomaly',
    defaultMessage: 'Alerts',
  },
  {
    id: 'event.alertCategory',
    defaultMessage: 'Alert category',
  },
  {
    id: 'event.alertHealthScore',
    defaultMessage: 'Alert health score',
  },
  {
    id: 'event.alertNotificationCoverage',
    defaultMessage: 'Alert notification coverage',
  },
  {
    id: 'event.alertNotificationDampeningPeriod',
    defaultMessage: 'Alert notification dampening period(min)',
  },
  {
    id: 'event.alertReductionRatio',
    defaultMessage: 'Alert reduction ratio',
  },
  {
    id: 'event.alertstatistics',
    defaultMessage: 'Alert statistics',
  },
  {
    id: 'event.alertStatistics',
    defaultMessage: 'Alert Statistics',
  },
  {
    id: 'event.all',
    defaultMessage: 'All',
  },
  {
    id: 'event.allInstances',
    defaultMessage: 'All instances',
  },
  {
    id: 'event.allLogs',
    defaultMessage: 'All logs',
  },
  {
    id: 'event.alreadyExistInTheSelectedProject',
    defaultMessage: 'Instances/Metrics already exist in the selected project',
  },
  {
    id: 'event.analysisGroup',
    defaultMessage: 'Analysis Group',
  },
  {
    id: 'event.anomaliesEmpty',
    defaultMessage: 'Congratulations! You have zero detected or impending anomalies.',
  },
  {
    id: 'event.anomalous',
    defaultMessage: 'Anomalous',
  },
  {
    id: 'event.anomalousEventCount',
    defaultMessage: 'Anomalous event count',
  },
  {
    id: 'event.anomalousLogs',
    defaultMessage: 'Anomalous logs',
  },
  {
    id: 'event.anomalyComponent',
    defaultMessage: 'Anomaly component',
  },
  {
    id: 'event.anomalyDetails',
    defaultMessage: 'Anomaly Details',
  },
  {
    id: 'event.anomalyDetection',
    defaultMessage: 'Anomaly detection',
  },
  {
    id: 'event.anomalyInstance',
    defaultMessage: 'Anomaly instance',
  },
  {
    id: 'event.anomalyInstances',
    defaultMessage: 'Anomalous instances',
  },
  {
    id: 'event.anomalyLineChart',
    defaultMessage: 'Anomaly Line Chart',
  },
  {
    id: 'event.anomalyMetric',
    defaultMessage: 'Anomaly metric',
  },
  {
    id: 'event.anomalyScore',
    defaultMessage: 'Anomaly score',
  },
  {
    id: 'event.anomalyType',
    defaultMessage: 'Anomaly Type',
  },
  {
    id: 'event.areYouSureDeleteThisUser',
    defaultMessage: 'Are you sure delete this user?',
  },
  {
    id: 'event.areYouSureToUploadTheFile',
    defaultMessage: 'Are you sure to upload the file?',
  },
  {
    id: 'event.authenticationSwitch',
    defaultMessage: 'Authentication Switch (On/Off)',
  },
  {
    id: 'event.automaticallyCreatedRules',
    defaultMessage: 'Automatically created rules',
  },
  {
    id: 'event.avgDuration',
    defaultMessage: 'Avg duration',
  },
  {
    id: 'event.avgHealthScore',
    defaultMessage: 'Avg Health Score',
  },
  {
    id: 'event.baselineStatus',
    defaultMessage: 'Baseline status',
  },
  {
    id: 'event.cancelUpload',
    defaultMessage: 'Cancel upload',
  },
  {
    id: 'event.capacity.addInstances',
    defaultMessage: 'Add {count} {name}',
  },
  {
    id: 'event.capacity.average',
    defaultMessage: 'Average {metric}',
  },
  {
    id: 'event.capacity.avgUsagePast',
    defaultMessage: 'Avg usage (past 30 days data)',
  },
  {
    id: 'event.capacity.avgUsageReal',
    defaultMessage: 'Avg usage (past {lines} lines data)',
  },
  {
    id: 'event.capacity.currentDeployment',
    defaultMessage: 'Current change',
  },
  {
    id: 'event.capacity.full',
    defaultMessage: 'Full',
  },
  {
    id: 'event.capacity.maximum',
    defaultMessage: 'Maximum {metric}',
  },
  {
    id: 'event.capacity.noChange',
    defaultMessage: 'No change',
  },
  {
    id: 'event.capacity.predictedAvgUsageCurrent',
    defaultMessage: 'Predicted Avg usage (current day)',
  },
  {
    id: 'event.capacity.predictionDeviationCurrent',
    defaultMessage: 'Prediction deviation (current day)',
  },
  {
    id: 'event.capacity.predictionsForRequired',
    defaultMessage: 'Predictions for required instances to keep below thresholds',
  },
  {
    id: 'event.capacity.removeInstances',
    defaultMessage: 'Remove {count} {name}',
  },
  {
    id: 'event.causalGraph',
    defaultMessage: 'Causal Graph',
  },
  {
    id: 'event.causalPrediction',
    defaultMessage: 'Causal Prediction',
  },
  {
    id: 'event.changeCategory',
    defaultMessage: 'Change category',
  },
  {
    id: 'event.changesEmpty',
    defaultMessage: 'Your team did not deploy any changes between {startTime} and {endTime}',
  },
  {
    id: 'event.clearQueueData',
    defaultMessage:
      'Purging this queue({queueName}) will cause a loss of data. Are you sure you would like to continue?',
  },
  {
    id: 'event.clickForDetails',
    defaultMessage: 'Click for Details ({startTime})',
  },
  {
    id: 'event.cold',
    defaultMessage: 'Cold',
  },
  {
    id: 'event.commonRootCauses',
    defaultMessage: 'Common root causes',
  },
  {
    id: 'event.company',
    defaultMessage: 'Company',
  },
  {
    id: 'event.componentFilter',
    defaultMessage: 'Component Filter',
  },
  {
    id: 'event.componentName',
    defaultMessage: 'Component name',
  },
  {
    id: 'event.componentVersion',
    defaultMessage: 'Component Version',
  },
  {
    id: 'event.compositeIncident',
    defaultMessage: 'Composite incident',
  },
  {
    id: 'event.compositeIncidents',
    defaultMessage: 'Composite incidents',
  },
  {
    id: 'event.configPatternName',
    defaultMessage: 'Config pattern name',
  },
  {
    id: 'event.confirmedByOperator',
    defaultMessage: 'Confirmed by operator',
  },
  {
    id: 'event.consumers',
    defaultMessage: 'Consumers',
  },
  {
    id: 'event.containerName',
    defaultMessage: 'Container name',
  },
  {
    id: 'event.context',
    defaultMessage: 'Context',
  },
  {
    id: 'event.contextLong',
    defaultMessage: 'Context (5mins)',
  },
  {
    id: 'event.contextShort',
    defaultMessage: 'Context (1mins)',
  },
  {
    id: 'event.coreEngine',
    defaultMessage: 'Core Engine',
  },
  {
    id: 'event.count',
    defaultMessage: 'Count',
  },
  {
    id: 'event.createANewProject',
    defaultMessage: 'Create a new project',
  },
  {
    id: 'event.createChannelOnSlack',
    defaultMessage: 'Go to Slack channel',
  },
  {
    id: 'event.createChannelOnTeams',
    defaultMessage: 'Go to Teams channel',
  },
  {
    id: 'event.createNewGlobalKB',
    defaultMessage: 'Create new global knowledge base',
  },
  {
    id: 'event.critical',
    defaultMessage: 'Critical',
  },
  {
    id: 'event.cronStatus',
    defaultMessage: 'Cron Status',
  },
  {
    id: 'event.cronType',
    defaultMessage: 'Cron type',
  },
  {
    id: 'event.currentPage',
    defaultMessage: 'Current Page',
  },
  {
    id: 'event.data',
    defaultMessage: 'Data',
  },
  {
    id: 'event.date',
    defaultMessage: 'Date',
  },
  {
    id: 'event.dateWithDuplicateData',
    defaultMessage: 'Data already exist on the following dates',
  },
  {
    id: 'event.defaultEmailAlertRecipient',
    defaultMessage: 'Default Email Alert Recipient',
  },
  {
    id: 'event.deleteAllProjects',
    defaultMessage: 'Delete all projects in {systemName} system',
  },
  {
    id: 'event.deleteBayerSystemNameOnly',
    defaultMessage: 'Delete system {systemName} only',
  },
  {
    id: 'event.deleteFile',
    defaultMessage: 'Delete file',
  },
  {
    id: 'event.deleteIncident',
    defaultMessage: 'Delete this incident',
  },
  {
    id: 'event.deleteTeamsChannelConfirm',
    defaultMessage: 'Delete channel?',
  },
  {
    id: 'event.description',
    defaultMessage: 'Description',
  },
  {
    id: 'event.details',
    defaultMessage: 'Details',
  },
  {
    id: 'event.detectedAndPredicted',
    defaultMessage: 'Detected And Predicted anomaly',
  },
  {
    id: 'event.detectedIncidentCountThreshold',
    defaultMessage: 'Detected incident count threshold',
  },
  {
    id: 'event.detectionStatus',
    defaultMessage: 'Detection status',
  },
  {
    id: 'event.detectionTime',
    defaultMessage: 'Detection time',
  },
  {
    id: 'event.detectionTimeInfo',
    defaultMessage: 'Detection time details',
  },
  {
    id: 'event.differentRootCauses',
    defaultMessage: 'Different root causes',
  },
  {
    id: 'event.disableRunRCA',
    defaultMessage: 'Disable RCA',
  },
  {
    id: 'event.display',
    defaultMessage: 'Display',
  },
  {
    id: 'event.displayProjectName',
    defaultMessage: 'Project display name',
  },
  {
    id: 'event.downloadAnomalyLogEntries',
    defaultMessage: 'Download anomaly log entries',
  },
  {
    id: 'event.editTriageReport',
    defaultMessage: 'Edit Summary Notes',
  },
  {
    id: 'event.effective',
    defaultMessage: 'Effective',
  },
  {
    id: 'event.emailAlerts',
    defaultMessage: 'Email Alerts',
  },
  {
    id: 'event.emailSent',
    defaultMessage: 'Email Sent',
  },
  {
    id: 'event.emailServerSettings',
    defaultMessage: 'Email Server Settings',
  },
  {
    id: 'event.emailServiceSwitch',
    defaultMessage: 'Email Service Switch (On/Off)',
  },
  {
    id: 'event.emailSettings',
    defaultMessage: 'Email Settings',
  },
  {
    id: 'event.enableAlertNotification',
    defaultMessage: 'Enable alert notification',
  },
  {
    id: 'event.enableDailyReport',
    defaultMessage: 'Enable daily report',
  },
  {
    id: 'event.enableHealthAlert',
    defaultMessage: 'Enable health alert',
  },
  {
    id: 'event.enableRunRCA',
    defaultMessage: 'Enable RCA',
  },
  {
    id: 'event.enableSystemDownDetection',
    defaultMessage: 'Enable system down detection',
  },
  {
    id: 'event.enableWeeklyReport',
    defaultMessage: 'Enable weekly report',
  },
  {
    id: 'event.encryption',
    defaultMessage: 'Encryption',
  },
  {
    id: 'event.endTime',
    defaultMessage: 'End Time',
  },
  {
    id: 'event.enqueueTime',
    defaultMessage: 'Enqueue time',
  },
  {
    id: 'event.escalateIncident',
    defaultMessage: 'Escalate incident',
  },
  {
    id: 'event.escalateThisIncident',
    defaultMessage: 'Escalate this incident',
  },
  {
    id: 'event.estimatedLeadTime',
    defaultMessage: 'Estimated lead time',
  },
  {
    id: 'event.eventLabel',
    defaultMessage: 'Events',
  },
  {
    id: 'event.eventsHistory',
    defaultMessage: 'Events History',
  },
  {
    id: 'event.eventSummary',
    defaultMessage: 'Event summary',
  },
  {
    id: 'event.execute',
    defaultMessage: 'Execute',
  },
  {
    id: 'event.expirationDate',
    defaultMessage: 'Expiration Date',
  },
  {
    id: 'event.export',
    defaultMessage: 'Export',
  },
  {
    id: 'event.exportEvents',
    defaultMessage: 'Export Events',
  },
  {
    id: 'event.fail',
    defaultMessage: 'Fail',
  },
  {
    id: 'event.failReason',
    defaultMessage: 'Fail reason',
  },
  {
    id: 'event.falsePositive',
    defaultMessage: 'False positive',
  },
  {
    id: 'event.fieldAnomalyDuration',
    defaultMessage: 'Anomaly Duration',
  },
  {
    id: 'event.fieldAnomalyPattern',
    defaultMessage: 'Anomaly pattern',
  },
  {
    id: 'event.fieldAnomalyScore',
    defaultMessage: 'Anomaly Scores',
  },
  {
    id: 'event.fieldAnomalyType',
    defaultMessage: 'Anomaly type',
  },
  {
    id: 'event.fieldCauseComponent',
    defaultMessage: 'Source component',
  },
  {
    id: 'event.fieldCauseContainer',
    defaultMessage: 'Source container',
  },
  {
    id: 'event.fieldCauseInstance',
    defaultMessage: 'Source instance',
  },
  {
    id: 'event.fieldCauseProject',
    defaultMessage: 'Source project',
  },
  {
    id: 'event.fieldControl',
    defaultMessage: 'Control',
  },
  {
    id: 'event.fieldDetails',
    defaultMessage: 'Details',
  },
  {
    id: 'event.fieldDuration',
    defaultMessage: 'Duration',
  },
  {
    id: 'event.fieldIncidentType',
    defaultMessage: 'Incident type',
  },
  {
    id: 'event.fieldName',
    defaultMessage: 'Name',
  },
  {
    id: 'event.fieldRootCauseInstance',
    defaultMessage: 'Root cause instances',
  },
  {
    id: 'event.fieldRootCauseMetric',
    defaultMessage: 'Root cause metric types',
  },
  {
    id: 'event.fieldStartTime',
    defaultMessage: 'Start time',
  },
  {
    id: 'event.fieldStatus',
    defaultMessage: 'Status',
  },
  {
    id: 'event.fileAlreadyExistsError',
    defaultMessage: 'The file already exists, no need to upload it again',
  },
  {
    id: 'event.fileFormatError',
    defaultMessage: '{fileName} the file needs to be in csv format',
  },
  {
    id: 'event.fileName',
    defaultMessage: 'File name',
  },
  {
    id: 'event.fileNameHasNoDuplicateMetricAndInstance',
    defaultMessage: '"{fileName}" has no duplicate metric and instance',
  },
  {
    id: 'event.fileParsing',
    defaultMessage: 'File parsing',
  },
  {
    id: 'event.fileUploadError',
    defaultMessage: 'File upload error, please upload again',
  },
  {
    id: 'event.fileUploadMaxError',
    defaultMessage: '{fileName} exceeds the maximum 2G upload size!',
  },
  {
    id: 'event.firstName',
    defaultMessage: 'First name',
  },
  {
    id: 'event.fixedIncidents',
    defaultMessage: 'Fixed incidents',
  },
  {
    id: 'event.freeTrialRequestDate',
    defaultMessage: 'Free trial request date',
  },
  {
    id: 'event.frequencyHigh',
    defaultMessage: 'Frequency(count {count}) is {percent} higher than normal.',
  },
  {
    id: 'event.frequencyLow',
    defaultMessage: 'Frequency(count {count}) is {percent} lower than normal.',
  },
  {
    id: 'event.futureEvents',
    defaultMessage: 'Future Events',
  },
  {
    id: 'event.generalEmailSettings',
    defaultMessage: 'General Email Settings',
  },
  {
    id: 'event.goSlackChannelWithCreateAutomatically',
    defaultMessage: 'Go to Slack channel, select an existing channel or create a new channel.',
  },
  {
    id: 'event.goTeamsChannelWithCreateAutomatically',
    defaultMessage: 'Go to Teams channel, select an existing channel or create a new channel.',
  },
  {
    id: 'event.groupTakeAction',
    defaultMessage: 'Group Configure Action',
  },
  {
    id: 'event.hasRootCauses',
    defaultMessage: 'Has root causes',
  },
  {
    id: 'event.healthScore',
    defaultMessage: 'Health Score',
  },
  {
    id: 'event.hideIgnore',
    defaultMessage: 'Hide Ignore',
  },
  {
    id: 'event.higherThanNormal',
    defaultMessage: 'higher than normal',
  },
  {
    id: 'event.historicalIncients',
    defaultMessage: 'Historical incidents',
  },
  {
    id: 'event.historicalPredictions',
    defaultMessage: 'Historical Predictions',
  },
  {
    id: 'event.homeAccountGroup',
    defaultMessage: 'Home account group',
  },
  {
    id: 'event.host',
    defaultMessage: 'Host',
  },
  {
    id: 'event.hosts',
    defaultMessage: 'Hosts',
  },
  {
    id: 'event.hot',
    defaultMessage: 'Hot',
  },
  {
    id: 'event.hotEventOnly',
    defaultMessage: 'Hot event only',
  },
  {
    id: 'event.ignore',
    defaultMessage: 'Ignore',
  },
  {
    id: 'event.ignored',
    defaultMessage: 'Ignored',
  },
  {
    id: 'event.ignorePattern',
    defaultMessage: 'Ignore pattern',
  },
  {
    id: 'event.impacted',
    defaultMessage: 'Impacted',
  },
  {
    id: 'event.importanceFlag',
    defaultMessage: 'Importance flag',
  },
  {
    id: 'event.important',
    defaultMessage: 'Important',
  },
  {
    id: 'event.incident',
    defaultMessage: 'Incident',
  },
  {
    id: 'event.incidentCategory',
    defaultMessage: 'Incident category',
  },
  {
    id: 'event.incidentComponent',
    defaultMessage: 'Incident component',
  },
  {
    id: 'event.incidentCount',
    defaultMessage: 'Incident count',
  },
  {
    id: 'event.incidentDescription',
    defaultMessage: 'Incident Description',
  },
  {
    id: 'event.incidentEmpty',
    defaultMessage: 'Congratulations! You have zero detected or impending incident.',
  },
  {
    id: 'event.incidentInstance',
    defaultMessage: 'Incident instance',
  },
  {
    id: 'event.incidentPattern',
    defaultMessage: 'Incident pattern',
  },
  {
    id: 'event.incidentPatternId',
    defaultMessage: 'Incident Pattern ID',
  },
  {
    id: 'event.incidentPatternName',
    defaultMessage: 'Incident pattern name',
  },
  {
    id: 'event.incidentProject',
    defaultMessage: 'Incident project',
  },
  {
    id: 'event.incidents',
    defaultMessage: 'Incidents',
  },
  {
    id: 'event.incidentShortDescription',
    defaultMessage: 'Incident short description',
  },
  {
    id: 'event.incidentsRootCauses',
    defaultMessage: 'Incidents w/wo root causes',
  },
  {
    id: 'event.incidentStatus',
    defaultMessage: 'Incident status',
  },
  {
    id: 'event.individualIncidents',
    defaultMessage: 'Individual incidents',
  },
  {
    id: 'event.info',
    defaultMessage: 'Info',
  },
  {
    id: 'event.inProgress',
    defaultMessage: 'In progress',
  },
  {
    id: 'event.inQueue',
    defaultMessage: 'In queue',
  },
  {
    id: 'event.instanceFilter',
    defaultMessage: 'Instance Filter',
  },
  {
    id: 'event.instanceName',
    defaultMessage: 'Instance name',
  },
  {
    id: 'event.instancesWithDuplicateData',
    defaultMessage: 'Instances with duplicate data',
  },
  {
    id: 'event.intervalLabel',
    defaultMessage: 'Interval',
  },
  {
    id: 'event.inUse',
    defaultMessage: 'In use',
  },
  {
    id: 'event.investigationPatternConfig',
    defaultMessage: 'Configure Action',
  },
  {
    id: 'event.isFixedIncident',
    defaultMessage: 'Is fixed incident',
  },
  {
    id: 'event.isPredictedEvent',
    defaultMessage: 'This is a predicted event',
  },
  {
    id: 'event.isReadUserDisable',
    defaultMessage: 'The read only user is not authorized to perform the function',
  },
  {
    id: 'event.jumpKeywordSearch',
    defaultMessage: 'Jump To Keyword Search',
  },
  {
    id: 'event.jumpPatternTrend',
    defaultMessage: 'Jump To Pattern Trend',
  },
  {
    id: 'event.jumpToLinechart',
    defaultMessage: 'Jump to linechart',
  },
  {
    id: 'event.killTask',
    defaultMessage: 'Kill Task',
  },
  {
    id: 'event.knowledgeBaseType',
    defaultMessage: 'Knowledge base type',
  },
  {
    id: 'event.KPIIncidents',
    defaultMessage: 'KPI Incidents',
  },
  {
    id: 'event.lastLoginTime',
    defaultMessage: 'Last login time',
  },
  {
    id: 'event.lastName',
    defaultMessage: 'Last name',
  },
  {
    id: 'event.lastSeenTime',
    defaultMessage: 'Last seen time',
  },
  {
    id: 'event.lastUpdatedGMT',
    defaultMessage: 'Last updated (GMT)',
  },
  {
    id: 'event.leadTime',
    defaultMessage: 'Lead time',
  },
  {
    id: 'event.license',
    defaultMessage: 'License',
  },
  {
    id: 'event.likelyRootCause',
    defaultMessage: 'Likely root cause',
  },
  {
    id: 'event.likelyRootCauses',
    defaultMessage: 'Likely root causes',
  },
  {
    id: 'event.lineChart',
    defaultMessage: 'Line Chart',
  },
  {
    id: 'event.log',
    defaultMessage: 'Log',
  },
  {
    id: 'event.logAnomaly',
    defaultMessage: 'Logs',
  },
  {
    id: 'event.logCategory',
    defaultMessage: 'Log anomaly category',
  },
  {
    id: 'event.logIncidents',
    defaultMessage: 'Log Incidents',
  },
  {
    id: 'event.logMaxScore',
    defaultMessage: 'Log Max Score',
  },
  {
    id: 'event.logSourceRootCauses',
    defaultMessage: 'Log source root causes',
  },
  {
    id: 'event.logSourceTrailingEvents',
    defaultMessage: 'Log trailing events',
  },
  {
    id: 'event.logToMetricContext',
    defaultMessage: 'Log to metric context',
  },
  {
    id: 'event.logType',
    defaultMessage: 'Log type',
  },
  {
    id: 'event.lowerThanNormal',
    defaultMessage: 'lower than normal',
  },
  {
    id: 'event.managedAccountGroup',
    defaultMessage: 'Managed account group',
  },
  {
    id: 'event.manuallyCreatedRules',
    defaultMessage: 'Manually created rules',
  },
  {
    id: 'event.manuallyInputRecommendations',
    defaultMessage: 'Manually input recommendations',
  },
  {
    id: 'event.markAsIgnored',
    defaultMessage: 'Mark as ignored',
  },
  {
    id: 'event.markAsImportant',
    defaultMessage: 'Mark as important',
  },
  {
    id: 'event.markAsLike',
    defaultMessage: 'Like it',
  },
  {
    id: 'event.markAsUnignored',
    defaultMessage: 'Mark as unignored',
  },
  {
    id: 'event.markAsUnimportant',
    defaultMessage: 'Mark as unimportant',
  },
  {
    id: 'event.markedAsIgnored',
    defaultMessage: 'Marked as ignored',
  },
  {
    id: 'event.markedAsImportant',
    defaultMessage: 'Marked as important',
  },
  {
    id: 'event.markedAsUnignored',
    defaultMessage: 'Marked as unignored',
  },
  {
    id: 'event.markedAsUnimportant',
    defaultMessage: 'Marked as unimportant',
  },
  {
    id: 'event.markIgnoreStatus',
    defaultMessage: 'Events of the pattern: {pattern} will be marked with "{status}" status',
  },
  {
    id: 'event.matchedIncidentDetails',
    defaultMessage: 'Matched incident details',
  },
  {
    id: 'event.matchedIncidentPatternID',
    defaultMessage: 'Matched incident pattern ID',
  },
  {
    id: 'event.max',
    defaultMessage: 'Max',
  },
  {
    id: 'event.maxAnomalyScore',
    defaultMessage: 'Max anomalyScore',
  },
  {
    id: 'event.maxRetryCount',
    defaultMessage: 'Max Retry Count',
  },
  {
    id: 'event.menuConfig',
    defaultMessage: 'Menu Config',
  },
  {
    id: 'event.messages',
    defaultMessage: 'Messages',
  },
  {
    id: 'event.metric',
    defaultMessage: 'Metric',
  },
  {
    id: 'event.metricAnomalies',
    defaultMessage: 'Metric Anomalies',
  },
  {
    id: 'event.metricAnomaly',
    defaultMessage: 'Metrics',
  },
  {
    id: 'event.metricCategory',
    defaultMessage: 'Metric anomaly category',
  },
  {
    id: 'event.metricFilter',
    defaultMessage: 'Metric Filter',
  },
  {
    id: 'event.metricInstanceName',
    defaultMessage: 'Metric instance name',
  },
  {
    id: 'event.metricMaxScore',
    defaultMessage: 'Metric Max Score',
  },
  {
    id: 'event.metrics',
    defaultMessage: 'Metrics',
  },
  {
    id: 'event.metricSourceRootCauses',
    defaultMessage: 'Metric source root causes',
  },
  {
    id: 'event.metricSourceTrailingEvents',
    defaultMessage: 'Metric trailing events',
  },
  {
    id: 'event.metricsWithDuplicateData',
    defaultMessage: 'Metrics with duplicate data',
  },
  {
    id: 'event.metricType',
    defaultMessage: 'Metric type',
  },
  {
    id: 'event.min',
    defaultMessage: 'Min',
  },
  {
    id: 'event.minAnomalyScore',
    defaultMessage: 'Min AnomalyScore',
  },
  {
    id: 'event.newAlert',
    defaultMessage: 'New alert',
  },
  {
    id: 'event.newIncident',
    defaultMessage: 'New incident',
  },
  {
    id: 'event.newPattern',
    defaultMessage: 'New',
  },
  {
    id: 'event.nextQuarter',
    defaultMessage: 'Next Quarter',
  },
  {
    id: 'event.noDataForThisTimePeriod',
    defaultMessage: 'No data for this time period',
  },
  {
    id: 'event.nodeLimit',
    defaultMessage: 'Node Limit',
  },
  {
    id: 'event.noHistoryFound',
    defaultMessage: 'No history anomalies for this time period could be found.',
  },
  {
    id: 'event.noIgnorePattern',
    defaultMessage: 'Unignore the pattern',
  },
  {
    id: 'event.noPreviewYet',
    defaultMessage: 'NO PREVIEW YET',
  },
  {
    id: 'event.normal',
    defaultMessage: 'Normal',
  },
  {
    id: 'event.normalStatus',
    defaultMessage: 'Normal',
  },
  {
    id: 'event.noRootCauses',
    defaultMessage: 'No root causes',
  },
  {
    id: 'event.notificationsSent',
    defaultMessage: 'Notifications sent',
  },
  {
    id: 'event.notificationType',
    defaultMessage: 'Notification type',
  },
  {
    id: 'event.notInUse',
    defaultMessage: 'Not in use',
  },
  {
    id: 'event.notSaveDashboardMessage',
    defaultMessage: 'Please click the Save button to save the current settings!',
  },
  {
    id: 'event.onlyDetected',
    defaultMessage: 'Detected anomaly',
  },
  {
    id: 'event.onlyPredicted',
    defaultMessage: 'Predicted anomaly',
  },
  {
    id: 'event.originator',
    defaultMessage: 'Originator',
  },
  {
    id: 'event.otherPage',
    defaultMessage: 'Other Page',
  },
  {
    id: 'event.others',
    defaultMessage: 'Others',
  },
  {
    id: 'event.overallLineChart',
    defaultMessage: 'Overall Line Chart',
  },
  {
    id: 'event.overallTreemap',
    defaultMessage: 'Overall Treemap',
  },
  {
    id: 'event.pastIncidentRC',
    defaultMessage: 'Past Incident Root Causes',
  },
  {
    id: 'event.patternId',
    defaultMessage: 'Pattern id',
  },
  {
    id: 'event.patternIdSearch',
    defaultMessage: 'Pattern Search',
  },
  {
    id: 'event.patternName',
    defaultMessage: 'Pattern Name',
  },
  {
    id: 'event.patternNotFound',
    defaultMessage: 'This pattern({pattern}) was not found.',
  },
  {
    id: 'event.patternTrend',
    defaultMessage: 'Pattern Trend',
  },
  {
    id: 'event.pendingTime',
    defaultMessage: 'Pending time',
  },
  {
    id: 'event.perInstanceChart',
    defaultMessage: 'View per instance chart',
  },
  {
    id: 'event.permanentlyDeleteThisCronJob',
    defaultMessage: 'This action will permanently remove this cron job({operation}) and all associated data.',
  },
  {
    id: 'event.predictedIncidentDetails',
    defaultMessage: 'Predicted incident details',
  },
  {
    id: 'event.predictedIncidentPatternID',
    defaultMessage: 'Predicted incident pattern ID',
  },
  {
    id: 'event.predictedOccurrenceTime',
    defaultMessage: 'Predicted occurrence time',
  },
  {
    id: 'event.predictionAccuracy',
    defaultMessage: 'Prediction accuracy',
  },
  {
    id: 'event.predictionCategory',
    defaultMessage: 'Prediction category',
  },
  {
    id: 'event.predictionCount',
    defaultMessage: 'Count',
  },
  {
    id: 'event.predictionHop',
    defaultMessage: 'Prediction Hop',
  },
  {
    id: 'event.predictionProbability',
    defaultMessage: 'Probability',
  },
  {
    id: 'event.predictionSourceCategory',
    defaultMessage: 'Prediction source category',
  },
  {
    id: 'event.predictionStatus',
    defaultMessage: 'Prediction status',
  },
  {
    id: 'event.predictionTime',
    defaultMessage: 'Prediction time',
  },
  {
    id: 'event.predictionTimeInfo',
    defaultMessage: 'Prediction time details',
  },
  {
    id: 'event.predictionTriggeringEvents',
    defaultMessage: 'Prediction triggering events',
  },
  {
    id: 'event.previousQuarter',
    defaultMessage: 'Previous Quarter',
  },
  {
    id: 'event.projectAlreadyExistsError',
    defaultMessage: 'The project already exists, please do not create it again',
  },
  {
    id: 'event.projectCannotBeEmpty',
    defaultMessage: 'Project cannot be empty!',
  },
  {
    id: 'event.projectDisplayName',
    defaultMessage: 'Display Name',
  },
  {
    id: 'event.projectInstanceName',
    defaultMessage: 'Project/component/instance name',
  },
  {
    id: 'event.projectName',
    defaultMessage: 'Project name',
  },
  {
    id: 'event.projectSource',
    defaultMessage: 'Project source',
  },
  {
    id: 'event.publish',
    defaultMessage: 'Publish',
  },
  {
    id: 'event.purge',
    defaultMessage: 'Purge',
  },
  {
    id: 'event.quarter1',
    defaultMessage: 'Quarter 1',
  },
  {
    id: 'event.quarter2',
    defaultMessage: 'Quarter 2',
  },
  {
    id: 'event.quarter3',
    defaultMessage: 'Quarter 3',
  },
  {
    id: 'event.quarter4',
    defaultMessage: 'Quarter 4',
  },
  {
    id: 'event.queue',
    defaultMessage: 'Queue',
  },
  {
    id: 'event.rabbitmqServerStatus',
    defaultMessage: 'Rabbitmq Server Status',
  },
  {
    id: 'event.rabbitMQStatus',
    defaultMessage: 'RabbitMQ Status',
  },
  {
    id: 'event.rare',
    defaultMessage: 'Rare',
  },
  {
    id: 'event.realProjectName',
    defaultMessage: 'Project real name',
  },
  {
    id: 'event.realTimeNotificationWasSent',
    defaultMessage: 'Real time notification was sent',
  },
  {
    id: 'event.recommendation',
    defaultMessage: 'Recommendation',
  },
  {
    id: 'event.recommendationList',
    defaultMessage: 'Recommendation List',
  },
  {
    id: 'event.recurrent',
    defaultMessage: 'Recurrent',
  },
  {
    id: 'event.recurrentEvents',
    defaultMessage: 'Recurrent Events',
  },
  {
    id: 'event.recurrentIncidents',
    defaultMessage: 'Recurrent incidents',
  },
  {
    id: 'event.removeImportantFlag',
    defaultMessage: 'Remove importance flag',
  },
  {
    id: 'event.reportJira',
    defaultMessage: 'Report to Jira',
  },
  {
    id: 'event.reportServiceNow',
    defaultMessage: 'Report to ServiceNow',
  },
  {
    id: 'event.rerun',
    defaultMessage: 'Rerun',
  },
  {
    id: 'event.rerunBaseline',
    defaultMessage: 'Rerun Baseline',
  },
  {
    id: 'event.rerunDetection',
    defaultMessage: 'Rerun Detection',
  },
  {
    id: 'event.rerunDetectionAll',
    defaultMessage: 'Rerun Detection(All Days)',
  },
  {
    id: 'event.rerunDetectionCur',
    defaultMessage: 'Rerun Detection(Current Day)',
  },
  {
    id: 'event.rerunRCA',
    defaultMessage: 'Rerun root cause',
  },
  {
    id: 'event.rerunRCAForAnomaly',
    defaultMessage: 'Rerun root cause for this anomaly event',
  },
  {
    id: 'event.rerunTraining',
    defaultMessage: 'Rerun Training',
  },
  {
    id: 'event.rerunTrainingAll',
    defaultMessage: 'Rerun Training(All Days)',
  },
  {
    id: 'event.rerunTrainingCur',
    defaultMessage: 'Rerun Training(Current Day)',
  },
  {
    id: 'event.rerunTrainingOrDetection',
    defaultMessage: 'Rerun Training or Detection',
  },
  {
    id: 'event.resetIgnoreStatus',
    defaultMessage: 'Pattern: {pattern} is marked with "{status}" status, submit to reset the status',
  },
  {
    id: 'event.retryCount',
    defaultMessage: 'Retry Count',
  },
  {
    id: 'event.RmoveLikeFlag',
    defaultMessage: 'Unlike it',
  },
  {
    id: 'event.role',
    defaultMessage: 'Role',
  },
  {
    id: 'event.roleManagement',
    defaultMessage: 'Role Management',
  },
  {
    id: 'event.roleName',
    defaultMessage: 'Role name',
  },
  {
    id: 'event.rootCauseComponentName',
    defaultMessage: 'Root Cause Component Name',
  },
  {
    id: 'event.rootCauseDetails',
    defaultMessage: 'Root cause details',
  },
  {
    id: 'event.rootCauseHop',
    defaultMessage: 'Root Cause',
  },
  {
    id: 'event.rootCauseLeadTime',
    defaultMessage: 'Root Cause Lead Time',
  },
  {
    id: 'event.rootCauseLevel',
    defaultMessage: 'Root cause level',
  },
  {
    id: 'event.rootCauseLogMessage',
    defaultMessage: 'Root Cause Log Message',
  },
  {
    id: 'event.rootCauseMetricAnomalyDegree',
    defaultMessage: 'Root Cause Metric Anomaly Degree',
  },
  {
    id: 'event.rootCauseMetricName',
    defaultMessage: 'Root Cause Metric Name',
  },
  {
    id: 'event.rootCauseMetricOrLog',
    defaultMessage: 'Root cause metric / Log list',
  },
  {
    id: 'event.rootCauseMetricValue',
    defaultMessage: 'Root Cause Metric Value',
  },
  {
    id: 'event.rootCausePattern',
    defaultMessage: 'Root cause pattern',
  },
  {
    id: 'event.rootCausePatternIDList',
    defaultMessage: 'Root cause pattern ID list',
  },
  {
    id: 'event.rootCauseProject',
    defaultMessage: 'Root cause project',
  },
  {
    id: 'event.rootCauseShortDescription',
    defaultMessage: 'Root cause short description',
  },
  {
    id: 'event.ruleType',
    defaultMessage: 'Rule type',
  },
  {
    id: 'event.running',
    defaultMessage: 'Running',
  },
  {
    id: 'event.seeMatchingInstance',
    defaultMessage: 'Fill out the form to see values from matching instance',
  },
  {
    id: 'event.selectFromExistingProjects',
    defaultMessage: 'Select from the existing projects',
  },
  {
    id: 'event.selectTimeRange',
    defaultMessage: 'Select the time range to export events of',
  },
  {
    id: 'event.sendEmailAlert',
    defaultMessage: 'Send email alert',
  },
  {
    id: 'event.sendEmailToAddress',
    defaultMessage: 'Send email alert to these email address',
  },
  {
    id: 'event.sendExportAlert',
    defaultMessage: 'Export alert',
  },
  {
    id: 'event.serviceHost',
    defaultMessage: 'Service host',
  },
  {
    id: 'event.setAnnotation',
    defaultMessage: 'Annotate incident',
  },
  {
    id: 'event.setComponentName',
    defaultMessage: 'Set component name',
  },
  {
    id: 'event.setPatternName',
    defaultMessage: 'Set pattern name',
  },
  {
    id: 'event.shortDescription',
    defaultMessage: 'Short description',
  },
  {
    id: 'event.shortDescriptionKeywordSearch',
    defaultMessage: 'Short description keyword search',
  },
  {
    id: 'event.shortDescriptionOfPredicted',
    defaultMessage: 'Short description of predicted incidents',
  },
  {
    id: 'event.showContext',
    defaultMessage: 'Show context',
  },
  {
    id: 'event.showTheFirstNumLines',
    defaultMessage: 'show the first {num} lines',
  },
  {
    id: 'event.signupCode',
    defaultMessage: 'Signup code',
  },
  {
    id: 'event.signupDate',
    defaultMessage: 'Signup date',
  },
  {
    id: 'event.signupManagement',
    defaultMessage: 'Signup Management',
  },
  {
    id: 'event.slope',
    defaultMessage: 'Slope',
  },
  {
    id: 'event.SMTPEmailAddress',
    defaultMessage: 'SMTP Email Address',
  },
  {
    id: 'event.SMTPPassword',
    defaultMessage: 'SMTP Password',
  },
  {
    id: 'event.SMTPPort',
    defaultMessage: 'SMTP Port',
  },
  {
    id: 'event.SMTPServer',
    defaultMessage: 'SMTP Server',
  },
  {
    id: 'event.SMTPUsername',
    defaultMessage: 'SMTP Username',
  },
  {
    id: 'event.sourceComponentName',
    defaultMessage: 'Source component name',
  },
  {
    id: 'event.sourceInstanceName',
    defaultMessage: 'Source instance name',
  },
  {
    id: 'event.startTime',
    defaultMessage: 'Start Time',
  },
  {
    id: 'event.success',
    defaultMessage: 'Success',
  },
  {
    id: 'event.suggestedAction',
    defaultMessage: 'Suggested action',
  },
  {
    id: 'event.supportContact',
    defaultMessage: 'Support Contact',
  },
  {
    id: 'event.suspended',
    defaultMessage: 'Suspended',
  },
  {
    id: 'event.syscall',
    defaultMessage: 'Syscall',
  },
  {
    id: 'event.takeAction',
    defaultMessage: 'Configure Action',
  },
  {
    id: 'event.targetComponentName',
    defaultMessage: 'Target component name',
  },
  {
    id: 'event.targetInstanceName',
    defaultMessage: 'Target instance name',
  },
  {
    id: 'event.taskCompletionDeadline',
    defaultMessage: 'Task completion deadline',
  },
  {
    id: 'event.taskId',
    defaultMessage: 'Task id',
  },
  {
    id: 'event.taskParametersEndTime',
    defaultMessage: 'Task parameters end time',
  },
  {
    id: 'event.taskParametersStartTime',
    defaultMessage: 'Task parameters start time',
  },
  {
    id: 'event.taskStartTime',
    defaultMessage: 'Task start time',
  },
  {
    id: 'event.taskStatus',
    defaultMessage: 'Task Status',
  },
  {
    id: 'event.terminateThisJob',
    defaultMessage: 'Are you sure that you would like to kill this job?',
  },
  {
    id: 'event.testEmailSettings',
    defaultMessage: 'Test Email Settings',
  },
  {
    id: 'event.theFileAlreadyExists',
    defaultMessage: 'The file already exists',
  },
  {
    id: 'event.thereIsNoProjectsBelongToThisUser',
    defaultMessage: 'There is no projects belong to this user.',
  },
  {
    id: 'event.theseProjectsWillBeDeleted',
    defaultMessage: 'These projects will be deleted:',
  },
  {
    id: 'event.thisUserWillRemoveLockStatus',
    defaultMessage: 'This user will remove lock status.',
  },
  {
    id: 'event.timeLabel',
    defaultMessage: 'Time',
  },
  {
    id: 'event.timeline',
    defaultMessage: 'Timeline',
  },
  {
    id: 'event.timeout',
    defaultMessage: 'Timeout',
  },
  {
    id: 'event.toBeDetermined',
    defaultMessage: 'To be determined',
  },
  {
    id: 'event.topLikelyRootCauses',
    defaultMessage: 'Top likely root causes',
  },
  {
    id: 'event.totalAlerts',
    defaultMessage: 'Total Alerts',
  },
  {
    id: 'event.totalLabel',
    defaultMessage: 'Total',
  },
  {
    id: 'event.totalLogCount',
    defaultMessage: 'Total anomalous log count',
  },
  {
    id: 'event.totalPatterns',
    defaultMessage: 'Total Patterns',
  },
  {
    id: 'event.totalSize',
    defaultMessage: 'Total size',
  },
  {
    id: 'event.totalUsers',
    defaultMessage: 'Total users',
  },
  {
    id: 'event.traceCategory',
    defaultMessage: 'Trace anomaly category',
  },
  {
    id: 'event.trailingEventsDetails',
    defaultMessage: 'Trailing events details',
  },
  {
    id: 'event.trainingStatus',
    defaultMessage: 'Training status',
  },
  {
    id: 'event.triggerCron',
    defaultMessage: 'Are you sure that you would like to trigger cron {operation}?',
  },
  {
    id: 'event.truePositive',
    defaultMessage: 'True positive',
  },
  {
    id: 'event.truePositiveCount',
    defaultMessage: 'True positive count',
  },
  {
    id: 'event.unignore',
    defaultMessage: 'Unignore',
  },
  {
    id: 'event.unIgnored',
    defaultMessage: 'Unignored',
  },
  {
    id: 'event.uniqueAlertPatterns',
    defaultMessage: 'Unique Alert Patterns',
  },
  {
    id: 'event.uniqueLogCount',
    defaultMessage: 'Unique anomalous log count',
  },
  {
    id: 'event.updateEmailSettings',
    defaultMessage: 'Update Email Settings',
  },
  {
    id: 'event.uploaded',
    defaultMessage: 'Uploaded',
  },
  {
    id: 'event.uploading',
    defaultMessage: 'Uploading',
  },
  {
    id: 'event.uploadMaxSizeTitle',
    defaultMessage: 'Upload a single file up to 2G!',
  },
  {
    id: 'event.uploadToolTipContent',
    defaultMessage: 'Click or drag CSV files to this area to upload',
  },
  {
    id: 'event.userInterfaceSettings',
    defaultMessage: 'User Interface Settings',
  },
  {
    id: 'event.userManagement',
    defaultMessage: 'User Management',
  },
  {
    id: 'event.userName',
    defaultMessage: 'User name',
  },
  {
    id: 'event.userSearch',
    defaultMessage: 'User search',
  },
  {
    id: 'event.viewData',
    defaultMessage: 'View data',
  },
  {
    id: 'event.viewFileContent',
    defaultMessage: 'View file content',
  },
  {
    id: 'event.viewIndividualIncidents',
    defaultMessage: 'View individual incidents',
  },
  {
    id: 'event.viewIndividualIncidentsDetails',
    defaultMessage: 'Please click on “view individual incidents” to see the details',
  },
  {
    id: 'event.webUI',
    defaultMessage: 'Web UI',
  },
  {
    id: 'event.whiteList',
    defaultMessage: 'WhiteList',
  },
  {
    id: 'help.helpAgent',
    defaultMessage: 'How to install Insight Agent?',
  },
  {
    id: 'help.helpAgent1',
    defaultMessage: 'Step 1: Retrieve your license key here if you forget.',
  },
  {
    id: 'help.helpAgent2',
    defaultMessage: 'Step 2: Select the agent(s) you want to install.',
  },
  {
    id: 'help.helpAgent3',
    defaultMessage: 'Step 3: Deploy one or multiple agents using our deployment code.',
  },
  {
    id: 'help.helpDashboard',
    defaultMessage: 'Dashboard',
  },
  {
    id: 'help.helpDashboard1',
    defaultMessage: 'View health score and anomaly info of your systems.',
  },
  {
    id: 'help.helpDashboard2',
    defaultMessage: 'View incident prediction of your systems.',
  },
  {
    id: 'help.helpDashboard3',
    defaultMessage: 'View detection anomalous and root cause analysis of your systems.',
  },
  {
    id: 'help.helpDashboard4',
    defaultMessage: 'View alert summary and root cause analysis of your systems.',
  },
  {
    id: 'help.helpLog',
    defaultMessage: 'Log',
  },
  {
    id: 'help.helpLog1',
    defaultMessage: 'View log analysis for patterns and anomalies in log files.',
  },
  {
    id: 'help.helpSettings',
    defaultMessage: 'Settings',
  },
  {
    id: 'help.helpSettings1',
    defaultMessage: 'See a list of existing projects, and register/remove project.',
  },
  {
    id: 'help.helpSettings2',
    defaultMessage: 'Anomaly detection and metric threshold settings.',
  },
  {
    id: 'help.helpSettings3',
    defaultMessage: 'Project sharing with collaborators.',
  },
  {
    id: 'help.helpSettings4',
    defaultMessage: 'Third party incident reporting service.',
  },
  {
    id: 'help.helpStart',
    defaultMessage: 'How to get started?',
  },
  {
    id: 'help.helpStart1',
    defaultMessage: 'Step 1: Register your project according to data source types.',
  },
  {
    id: 'help.helpStart2',
    defaultMessage: 'Step 2: View anomaly detection and diagnosis results in Dashboard.',
  },
  {
    id: 'help.helpStart3',
    defaultMessage: 'Step 3: Configure your projects in settings.',
  },
  {
    id: 'help.welcome',
    defaultMessage: 'Welcome',
  },
  {
    id: 'help.welcomeInfo',
    defaultMessage:
      'Thanks for choosing InsightFinder to monitor and manage your applications and systems. We provide a general use guide here to help answer some of the questions you may have. We will be glad to receive your contributions and suggestions both with respect to the content we outline below and other areas that you feel may need better or more in-depth explanation. If you need to get in touch with us, please send an email to info@insightfinder.com.',
  },
  {
    id: 'log.accumulatedProbability',
    defaultMessage: 'Accumulated probability',
  },
  {
    id: 'log.actionTriggered',
    defaultMessage: 'Actions Triggered',
  },
  {
    id: 'log.active',
    defaultMessage: 'Active',
  },
  {
    id: 'log.alert',
    defaultMessage: 'Alert',
  },
  {
    id: 'log.alertReductionRatio',
    defaultMessage: 'Alert Reduction Ratio',
  },
  {
    id: 'log.alertSummary',
    defaultMessage: 'Alert summary',
  },
  {
    id: 'log.allAlertEntries',
    defaultMessage: 'All alert entries',
  },
  {
    id: 'log.allAnomalousLogs',
    defaultMessage: 'All anomalous logs',
  },
  {
    id: 'log.allEvents',
    defaultMessage: 'All events (including ignored events)',
  },
  {
    id: 'log.allLogEntries',
    defaultMessage: 'All log entries',
  },
  {
    id: 'log.Analysis',
    defaultMessage: 'Analysis',
  },
  {
    id: 'log.anomalousLogSize',
    defaultMessage: 'Anomalous Log Size',
  },
  {
    id: 'log.anomalyLogSequences',
    defaultMessage: 'Anomaly Log Sequences',
  },
  {
    id: 'log.anomalyScore',
    defaultMessage: 'Anomaly score',
  },
  {
    id: 'log.anomalySequence',
    defaultMessage: 'Anomaly Sequence',
  },
  {
    id: 'log.avgLeadTime',
    defaultMessage: 'Avg Lead Time',
  },
  {
    id: 'log.category',
    defaultMessage: 'Category',
  },
  {
    id: 'log.changeEvent',
    defaultMessage: 'Change Event',
  },
  {
    id: 'log.changeSummary',
    defaultMessage: 'Change summary',
  },
  {
    id: 'log.clickForSample',
    defaultMessage: 'Click for Sample log entry',
  },
  {
    id: 'log.clusterFrequencyHigher',
    defaultMessage: 'Frequency(count {count}) of this pattern is {percent}% higher than normal.',
  },
  {
    id: 'log.clusterFrequencyLower',
    defaultMessage: 'Frequency(count {count}) of this pattern is {percent}% lower than normal.',
  },
  {
    id: 'log.clusters',
    defaultMessage: 'Total Patterns',
  },
  {
    id: 'log.clusterSampleLog',
    defaultMessage: 'Latest Sample Log Records',
  },
  {
    id: 'log.cold',
    defaultMessage: 'Cold',
  },
  {
    id: 'log.coldEventsPeriods',
    defaultMessage: 'Cold Events',
  },
  {
    id: 'log.coldExplain',
    defaultMessage: 'those anomalous logs have abnormally low frequency',
  },
  {
    id: 'log.common',
    defaultMessage: 'Common',
  },
  {
    id: 'log.commonLogSequences',
    defaultMessage: 'Common Log Sequences',
  },
  {
    id: 'log.commonSequence',
    defaultMessage: 'Common Sequence',
  },
  {
    id: 'log.compareCommonLogs',
    defaultMessage: 'Compare logs',
  },
  {
    id: 'log.compareIncidents',
    defaultMessage: 'Compare incidents',
  },
  {
    id: 'log.compressed',
    defaultMessage: 'Compressed',
  },
  {
    id: 'log.compressedSize',
    defaultMessage: 'Compressed Size',
  },
  {
    id: 'log.context',
    defaultMessage: 'Context',
  },
  {
    id: 'log.correspondingDetectedIncident',
    defaultMessage: 'Corresponding Detected Incident',
  },
  {
    id: 'log.count',
    defaultMessage: 'Count',
  },
  {
    id: 'log.critical',
    defaultMessage: 'Critical',
  },
  {
    id: 'log.currentCluster',
    defaultMessage: 'Current Pattern',
  },
  {
    id: 'log.currentKeyword',
    defaultMessage: 'Current Keyword',
  },
  {
    id: 'log.currentTotal',
    defaultMessage: 'Current total',
  },
  {
    id: 'log.Daily',
    defaultMessage: 'Daily',
  },
  {
    id: 'log.dailyLogAnalysis',
    defaultMessage: 'Daily log analysis',
  },
  {
    id: 'log.dateTime',
    defaultMessage: 'DateTime',
  },
  {
    id: 'log.dateTimes',
    defaultMessage: 'DateTimes',
  },
  {
    id: 'log.decompressed',
    defaultMessage: 'Decompressed',
  },
  {
    id: 'log.decreaseRatio',
    defaultMessage: 'Decrease Ratio',
  },
  {
    id: 'log.deleteIncidents',
    defaultMessage: 'Delete Incidents',
  },
  {
    id: 'log.deployment',
    defaultMessage: 'Change',
  },
  {
    id: 'log.desc.cold',
    defaultMessage: 'Cold log patterns which appear less frequently today than before.',
  },
  {
    id: 'log.desc.critical',
    defaultMessage: 'Critical log patterns which include critical keywords such as "fail, fatal".',
  },
  {
    id: 'log.desc.hot',
    defaultMessage: 'Hot log patterns which appear more frequently today than before.',
  },
  {
    id: 'log.desc.newpattern',
    defaultMessage: 'New log patterns which have never seen before within the past 7 days.',
  },
  {
    id: 'log.desc.rare',
    defaultMessage: 'Rare log patterns which are rarely seen today.',
  },
  {
    id: 'log.desc.whitelist',
    defaultMessage: 'Log patterns which include user-defined important keywords such as "timeout exception".',
  },
  {
    id: 'log.deviation',
    defaultMessage: 'Deviation',
  },
  {
    id: 'log.difference',
    defaultMessage: 'Difference',
  },
  {
    id: 'log.duration10Minutes',
    defaultMessage: '10 minutes',
  },
  {
    id: 'log.duration12Hours',
    defaultMessage: '12 hours',
  },
  {
    id: 'log.duration1Hour',
    defaultMessage: '1 hour',
  },
  {
    id: 'log.duration24Hours',
    defaultMessage: '24 hours',
  },
  {
    id: 'log.duration30Minutes',
    defaultMessage: '30 minutes',
  },
  {
    id: 'log.duration4Hours',
    defaultMessage: '4 hours',
  },
  {
    id: 'log.duration5Minutes',
    defaultMessage: '5 minutes',
  },
  {
    id: 'log.error.errorNoIncident',
    defaultMessage: 'The log incident cannot find, please refresh and try again.',
  },
  {
    id: 'log.error.infoRerunTriggerred',
    defaultMessage: 'Rerun detection for this log incident has been triggerred.',
  },
  {
    id: 'log.estimatedDelay',
    defaultMessage: 'Estimated delay',
  },
  {
    id: 'log.estimatedLeadTime',
    defaultMessage: 'Estimated Lead Time',
  },
  {
    id: 'log.estimatedMaxDelay',
    defaultMessage: 'Estimated Max Delay',
  },
  {
    id: 'log.estimatedMinDelay',
    defaultMessage: 'Estimated Min Delay',
  },
  {
    id: 'log.extendTimeBy',
    defaultMessage: 'Extend time by',
  },
  {
    id: 'log.extractedRatio',
    defaultMessage: 'Extracted Ratio',
  },
  {
    id: 'log.extractedSize',
    defaultMessage: 'Extracted Size',
  },
  {
    id: 'log.featureKeyword',
    defaultMessage: 'Feature Keyword',
  },
  {
    id: 'log.featureKeywordDistribution',
    defaultMessage: 'Feature Keyword Distribution (Top 10)',
  },
  {
    id: 'log.featureKeywordDistributionAll',
    defaultMessage: 'Feature Keyword Distribution',
  },
  {
    id: 'log.featureKeywordMapping',
    defaultMessage: 'Feature Keyword Mapping (Top 10)',
  },
  {
    id: 'log.featureKeywordMappingAll',
    defaultMessage: 'Feature Keyword Mapping',
  },
  {
    id: 'log.federatedLearning',
    defaultMessage: 'Federated Learning Reduction Ratio',
  },
  {
    id: 'log.fieldKeywords',
    defaultMessage: 'Field Keywords',
  },
  {
    id: 'log.fixedIncident',
    defaultMessage: 'Fixed Incident',
  },
  {
    id: 'log.fixedPrediction',
    defaultMessage: 'Fixed Predictions',
  },
  {
    id: 'log.frequency',
    defaultMessage: 'Frequency',
  },
  {
    id: 'log.futurePredictions',
    defaultMessage: 'Future Predictions',
  },
  {
    id: 'log.hideIgnoredEvents',
    defaultMessage: 'Hide Ignored Events',
  },
  {
    id: 'log.hideNoResultsEvents',
    defaultMessage: 'Hide Events with no Root Causes',
  },
  {
    id: 'log.highlightOption',
    defaultMessage: 'Highlight Option',
  },
  {
    id: 'log.historicalDetectedIncident',
    defaultMessage: 'Historical Detected Incident',
  },
  {
    id: 'log.historicalPredictions',
    defaultMessage: 'Historical Predictions',
  },
  {
    id: 'log.hot',
    defaultMessage: 'Hot',
  },
  {
    id: 'log.hotEventsPeriods',
    defaultMessage: 'Hot Events',
  },
  {
    id: 'log.hotExplain',
    defaultMessage: 'those anomalous logs have abnormally high frequency',
  },
  {
    id: 'log.ignored',
    defaultMessage: 'Ignored',
  },
  {
    id: 'log.ignoredIncidents',
    defaultMessage: 'Ignored incidents',
  },
  {
    id: 'log.importantAlert',
    defaultMessage: 'Anomalous alert entries',
  },
  {
    id: 'log.importantLog',
    defaultMessage: 'Anomalous log entries',
  },
  {
    id: 'log.incident',
    defaultMessage: 'Incident',
  },
  {
    id: 'log.incidentSummary',
    defaultMessage: 'Incident summary',
  },
  {
    id: 'log.instance',
    defaultMessage: 'Instance',
  },
  {
    id: 'log.invalidIncidents',
    defaultMessage: 'Invalid incidents',
  },
  {
    id: 'log.invalidPrediction',
    defaultMessage: 'Invalid Predictions',
  },
  {
    id: 'log.investigate',
    defaultMessage: 'Investigate',
  },
  {
    id: 'log.keywordAlerts',
    defaultMessage: 'Keyword Alerts',
  },
  {
    id: 'log.keywords',
    defaultMessage: 'Keywords',
  },
  {
    id: 'log.keywordSearch',
    defaultMessage: 'Keyword Search',
  },
  {
    id: 'log.labels',
    defaultMessage: 'Labels',
  },
  {
    id: 'log.leadTimeSummary',
    defaultMessage: 'Lead Time Summary',
  },
  {
    id: 'log.linkedEvents',
    defaultMessage: 'Linked Events',
  },
  {
    id: 'log.LLMRecommendation',
    defaultMessage: 'LLM recommendation',
  },
  {
    id: 'log.logAnomalieSummary',
    defaultMessage: 'Log anomaly summary',
  },
  {
    id: 'log.logEntry',
    defaultMessage: 'Records',
  },
  {
    id: 'log.logEvents',
    defaultMessage: 'Log Events',
  },
  {
    id: 'log.logPush',
    defaultMessage: 'Push Log',
  },
  {
    id: 'log.logSequences',
    defaultMessage: 'Sequences',
  },
  {
    id: 'log.logTypeFilter',
    defaultMessage: 'Log Type Filter',
  },
  {
    id: 'log.lookBackTimeWindow',
    defaultMessage: 'Look back time window',
  },
  {
    id: 'log.major',
    defaultMessage: 'Major',
  },
  {
    id: 'log.matchedIncident',
    defaultMessage: 'Matched Incident',
  },
  {
    id: 'log.matchedPredictions',
    defaultMessage: 'Matched Predictions',
  },
  {
    id: 'log.maxDelay',
    defaultMessage: 'Max delay',
  },
  {
    id: 'log.metricAnomalieSummary',
    defaultMessage: 'Metric anomaly summary',
  },
  {
    id: 'log.metricanomaly',
    defaultMessage: 'Metric',
  },
  {
    id: 'log.metricEvents',
    defaultMessage: 'Metric Events',
  },
  {
    id: 'log.minDelay',
    defaultMessage: 'Min Delay',
  },
  {
    id: 'log.minor',
    defaultMessage: 'Minor',
  },
  {
    id: 'log.monthly',
    defaultMessage: 'Back To Calendar',
  },
  {
    id: 'log.Monthly',
    defaultMessage: 'Monthly',
  },
  {
    id: 'log.monthlyLogAnalysis',
    defaultMessage: 'Monthly log analysis',
  },
  {
    id: 'log.newClusters',
    defaultMessage: 'New Patterns',
  },
  {
    id: 'log.newpattern',
    defaultMessage: 'New',
  },
  {
    id: 'log.newpatternExplain',
    defaultMessage:
      'those anomalous logs have a new pattern that never appeared in the past user-defined period (e.g.,7 days)',
  },
  {
    id: 'log.noCorrespondingIncidentFound',
    defaultMessage: 'No corresponding detected incident found.',
  },
  {
    id: 'log.noHistoricalIncidentFound',
    defaultMessage: 'No historical detected incident found.',
  },
  {
    id: 'log.noLogEntryFound',
    defaultMessage: 'No log entries for this time period could be found.',
  },
  {
    id: 'log.none',
    defaultMessage: 'None',
  },
  {
    id: 'log.noRecommendationsFound',
    defaultMessage: 'No recommendations for this anomaly event.',
  },
  {
    id: 'log.noRelatedAnomaly',
    defaultMessage: 'No Related Alert/Anomaly is found.',
  },
  {
    id: 'log.normal',
    defaultMessage: 'Normal',
  },
  {
    id: 'log.noRootCauseFound',
    defaultMessage: 'No likely root causes',
  },
  {
    id: 'log.noServiceNowNotes',
    defaultMessage: 'No ServiceNow Notes',
  },
  {
    id: 'log.noTriageReport',
    defaultMessage: 'No summary notes',
  },
  {
    id: 'log.noTriggeredActionsFound',
    defaultMessage: 'No triggered actions for this anomaly event.',
  },
  {
    id: 'log.numOfCriticalEvent',
    defaultMessage: 'Critical Events',
  },
  {
    id: 'log.numOfIncidentEvent',
    defaultMessage: 'Incident Events',
  },
  {
    id: 'log.numOfWhitelistEvent',
    defaultMessage: 'Whitelist Events',
  },
  {
    id: 'log.occurrenceTime',
    defaultMessage: 'Estimated Occurrence Time',
  },
  {
    id: 'log.originalSize',
    defaultMessage: 'Original Size',
  },
  {
    id: 'log.pattern',
    defaultMessage: 'Pattern',
  },
  {
    id: 'log.patternAndTrace',
    defaultMessage: 'Pattern / Trace',
  },
  {
    id: 'log.patternId',
    defaultMessage: 'Pattern ID',
  },
  {
    id: 'log.patternName',
    defaultMessage: 'Pattern Name',
  },
  {
    id: 'log.patternNameKey',
    defaultMessage: 'Pattern name key',
  },
  {
    id: 'log.patternSampleLog',
    defaultMessage: '[Pattern {patternName}] Sample Log [{labelIdx}]',
  },
  {
    id: 'log.precedingAnomalies',
    defaultMessage: 'Log source root causes',
  },
  {
    id: 'log.precision',
    defaultMessage: 'Precision',
  },
  {
    id: 'log.predictedEvents',
    defaultMessage: 'Predicted Events',
  },
  {
    id: 'log.predictionAccuracy',
    defaultMessage: 'Prediction Accuracy',
  },
  {
    id: 'log.predictionCount',
    defaultMessage: 'Prediction Count',
  },
  {
    id: 'log.predictionLabels',
    defaultMessage: 'Prediction labels',
  },
  {
    id: 'log.predictionSummary',
    defaultMessage: 'Prediction summary',
  },
  {
    id: 'log.probability',
    defaultMessage: 'Probability',
  },
  {
    id: 'log.rankNumber',
    defaultMessage: 'Rank',
  },
  {
    id: 'log.rare',
    defaultMessage: 'Rare',
  },
  {
    id: 'log.rareEvents',
    defaultMessage: 'Rare Events',
  },
  {
    id: 'log.rareExplain',
    defaultMessage: 'those anomalous logs have unusual patterns that are rarely seen',
  },
  {
    id: 'log.recall',
    defaultMessage: 'Recall',
  },
  {
    id: 'log.recommendations',
    defaultMessage: 'Recommendations',
  },
  {
    id: 'log.recommendedSteps',
    defaultMessage: 'Recommended action steps',
  },
  {
    id: 'log.regexPlaceHolderForIncident',
    defaultMessage:
      '(support nested json): key=value(value can be a word, sentence or regex) e.g key1->key2=value (means if the given key’s value contains the value, then matched) key1=.* (means any values of this key will be matched)',
  },
  {
    id: 'log.regexPlaceHolderForOther',
    defaultMessage: '(support nested json): key e.g key1->key2 or key1',
  },
  {
    id: 'log.regexPlaceHolderForWhiteList',
    defaultMessage: 'values can be word, sentence or regex',
  },
  {
    id: 'log.regexPlaceHolderForWhiteListJsonKey',
    defaultMessage: 'support nested json, key1->key2',
  },
  {
    id: 'log.relatedAnomalies',
    defaultMessage: 'Related Alerts/Anomalies',
  },
  {
    id: 'log.rerunHotEventDetection',
    defaultMessage: 'Rerun Hot Event Detection',
  },
  {
    id: 'log.rerunKeywordDetection',
    defaultMessage: 'Rerun Keyword Detection',
  },
  {
    id: 'log.resolved',
    defaultMessage: 'Auto resolved',
  },
  {
    id: 'log.rootCauseChain',
    defaultMessage: 'Likely root cause chains',
  },
  {
    id: 'log.rootCauseList',
    defaultMessage: 'Likely root cause list',
  },
  {
    id: 'log.rootCauseSummary',
    defaultMessage: 'Root cause summary',
  },
  {
    id: 'log.sampleLog',
    defaultMessage: 'Sample Log',
  },
  {
    id: 'log.sampleLogEntry',
    defaultMessage: 'Sample Log Records',
  },
  {
    id: 'log.selectFeature',
    defaultMessage: 'Select Feature',
  },
  {
    id: 'log.severity',
    defaultMessage: 'Severity',
  },
  {
    id: 'log.showEnableRCA',
    defaultMessage: 'Show Enable RCA',
  },
  {
    id: 'log.showPie',
    defaultMessage: 'View Feature Statistics',
  },
  {
    id: 'log.showSampleLog',
    defaultMessage: 'View Sample Logs',
  },
  {
    id: 'log.status',
    defaultMessage: 'Status',
  },
  {
    id: 'log.systemHealthSummary',
    defaultMessage: 'System health summary',
  },
  {
    id: 'log.tag',
    defaultMessage: 'Tag',
  },
  {
    id: 'log.timeRange',
    defaultMessage: 'Time Range',
  },
  {
    id: 'log.top10Keywords',
    defaultMessage: 'Top 10 Keywords',
  },
  {
    id: 'log.top10Patterns',
    defaultMessage: 'Top 10 Patterns',
  },
  {
    id: 'log.topKeywords',
    defaultMessage: 'Top Keywords',
  },
  {
    id: 'log.total',
    defaultMessage: 'Total',
  },
  {
    id: 'log.totalAlert',
    defaultMessage: 'Total Alert',
  },
  {
    id: 'log.totalAlerts',
    defaultMessage: 'Total Alerts',
  },
  {
    id: 'log.totalAnomalousLogEntries',
    defaultMessage: 'Total anomalous log entries',
  },
  {
    id: 'log.totalEvents',
    defaultMessage: 'Total Events',
  },
  {
    id: 'log.totalLog',
    defaultMessage: 'Total Log',
  },
  {
    id: 'log.totalLogEntries',
    defaultMessage: 'Total log entries',
  },
  {
    id: 'log.totalLogs',
    defaultMessage: 'Total Logs',
  },
  {
    id: 'log.totalMatch',
    defaultMessage: 'Total Match',
  },
  {
    id: 'log.traceAnomalieSummary',
    defaultMessage: 'Trace anomaly summary',
  },
  {
    id: 'log.trailingAnomalies',
    defaultMessage: 'Prediction chain rank list',
  },
  {
    id: 'log.triggeredAction',
    defaultMessage: 'Triggered Action',
  },
  {
    id: 'log.triggeredActions',
    defaultMessage: 'Triggered actions',
  },
  {
    id: 'log.type',
    defaultMessage: 'Pattern type',
  },
  {
    id: 'log.ublanomaly',
    defaultMessage: 'Metric',
  },
  {
    id: 'log.unmatchedIncident',
    defaultMessage: 'Unmatched Incident',
  },
  {
    id: 'log.unmatchedPredictions',
    defaultMessage: 'Unmatched Predictions',
  },
  {
    id: 'log.validationTime',
    defaultMessage: 'Validation Time',
  },
  {
    id: 'log.validIncidents',
    defaultMessage: 'Valid incidents',
  },
  {
    id: 'log.validPredictions',
    defaultMessage: 'Valid Predictions',
  },
  {
    id: 'log.whitelist',
    defaultMessage: 'WhiteList',
  },
  {
    id: 'log.whitelistExplain',
    defaultMessage: 'those anomalous logs have matched whitelist keyword labels',
  },
  {
    id: 'log.wordSentenceRegex',
    defaultMessage: 'word, sentence or regex',
  },
  {
    id: 'query.009be3dae7314c168cee40257404ac06',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.0339074566234192966c641e7fb7d317',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.0377137ccb9f4415b14908cb7e41a7fa',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.18a34799e749452d96eb20cd9f44f6c0',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.38d06cc6d65e43e98c083946367c80a6',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.44295d4bd7c0486693af4e6a2ae5e943',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.4e2438c80bb0472f84f0aa6a3a03469c',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.5046cdfc80824588aadae3018abefbe3',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.668119e595b3420dad08dd78b4299be6',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.680e10ddf5ac44b8867690c0ff472c67',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.6f0627d11427480b95e8d012f83c83f0',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.6fff86729e6749be963227242f39d176',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.78e1072bae6b48b783214a0658425e14',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.8d49a00c0dcb4c45b65d792b37990e2e',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.9076db738dc24cf38e4f9db53ba1deb3',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.953de6a33d8a4b96ac9c100bf69ba3fc',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.a73d0c1ac08146eba9bfbf7bd09c5f20',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.Application_Performance_Insights',
    defaultMessage: 'Application Performance Insights',
  },
  {
    id: 'query.b81364228d574502b7f6c3e454b9a21b',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.b919ba042c4b4d69a455262e18174dd0',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.c550706ee50d4b77a076b6127f1ca2ce',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.c8dcea3a7a9fc4060b3a554b16c4a84d3',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.d2361b74c70e4ef2a41c58accb785cd4',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.d3554c441470434a958e0fc26cd7fb5a',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.de593074c127470b9758fe0182e6cb4b',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.e3c3c5e8172f4a1396988747e6dc09be',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.eadc0e347ad24cae84bb32aaefe72418',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.f14e65eca3844ce29745c3d47fe90b00',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.f1f82460f99711e9aaef0800200c9a66',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.f3afb75038244cb9bff7d738489884b9',
    defaultMessage: '{displayName}',
  },
  {
    id: 'query.field.causalAnalysis',
    defaultMessage: 'Causal Graph',
  },
  {
    id: 'query.field.hideAttributingMetrics',
    defaultMessage: 'Hide Attributing Metrics',
  },
  {
    id: 'query.field.maximumPredictionDuration',
    defaultMessage: 'Maximum prediction duration is {day} days',
  },
  {
    id: 'query.field.noResult',
    defaultMessage: 'No result found.',
  },
  {
    id: 'query.field.openQueryBox',
    defaultMessage: 'Open Query Box',
  },
  {
    id: 'query.field.patternTopFeatureKeywords',
    defaultMessage: 'Pattern Top 5 Feature Keywords',
  },
  {
    id: 'query.field.patternTopKeywords',
    defaultMessage: 'Pattern Top 5 Keywords',
  },
  {
    id: 'query.field.precedingEvents',
    defaultMessage: 'Preceding events',
  },
  {
    id: 'query.field.predictedImpact',
    defaultMessage: 'Predicted Impact',
  },
  {
    id: 'query.field.queryCategory',
    defaultMessage: 'Category',
  },
  {
    id: 'query.field.showAttributingMetrics',
    defaultMessage: 'Show Attributing Metrics',
  },
  {
    id: 'query.field.ticketContent',
    defaultMessage: 'Ticket content',
  },
  {
    id: 'query.field.ticketEvents',
    defaultMessage: 'Ticket Events',
  },
  {
    id: 'query.field.ticketInsights',
    defaultMessage: 'Ticket insights',
  },
  {
    id: 'query.field.trailingEvents',
    defaultMessage: 'Trailing events',
  },
  {
    id: 'query.field.trendAnalysis',
    defaultMessage: 'Trend Analysis',
  },
  {
    id: 'query.field.triageReport',
    defaultMessage: 'Operator notes',
  },
  {
    id: 'query.Key_Performance_Indicator_KPI_Prediction',
    defaultMessage: 'Key Performance Indicator (KPI) Prediction',
  },
  {
    id: 'query.Log_Insights',
    defaultMessage: 'Log Insights',
  },
  {
    id: 'query.Metric_Insights',
    defaultMessage: 'Metric Insights',
  },
  {
    id: 'query.Predictive_Alert',
    defaultMessage: 'Predictive Alert',
  },
  {
    id: 'query.Root_Cause_Analysis',
    defaultMessage: 'Root Cause Analysis',
  },
  {
    id: 'settings.actionExecutionTime',
    defaultMessage: 'Action execution time(Min)',
  },
  {
    id: 'settings.actions',
    defaultMessage: 'Actions',
  },
  {
    id: 'settings.actionTriggeringDampeningPeriod',
    defaultMessage: 'Action triggering dampening period (min)',
  },
  {
    id: 'settings.actualValue',
    defaultMessage: 'Actual value',
  },
  {
    id: 'settings.addBaseValueMapping',
    defaultMessage: 'Add base value mapping',
  },
  {
    id: 'settings.additionalMetricName',
    defaultMessage: 'Additional metric name',
  },
  {
    id: 'settings.additionalMetricNameKey',
    defaultMessage: 'Additional metric name key',
  },
  {
    id: 'settings.addMultipleToSplit',
    defaultMessage: 'Add multiple to "," split',
  },
  {
    id: 'settings.addNewProject',
    defaultMessage: 'Add New Project',
  },
  {
    id: 'settings.addNewSystem',
    defaultMessage: 'Add New System',
  },
  {
    id: 'settings.addProxy',
    defaultMessage: 'Add proxy',
  },
  {
    id: 'settings.addShareUser',
    defaultMessage: 'Add share user',
  },
  {
    id: 'settings.addTheHelmChartRepo',
    defaultMessage: 'Add the helm chart repo',
  },
  {
    id: 'settings.addZoneSecrete',
    defaultMessage: 'Add zone secrete',
  },
  {
    id: 'settings.agentSetting',
    defaultMessage: 'Data Collection',
  },
  {
    id: 'settings.agentType',
    defaultMessage: 'Agent type',
  },
  {
    id: 'settings.Alert',
    defaultMessage: 'Alert',
  },
  {
    id: 'settings.alert.noProject',
    defaultMessage: 'There is no project, please create new one.',
  },
  {
    id: 'settings.alertBufferTime',
    defaultMessage: 'Alert buffer time (min)',
  },
  {
    id: 'settings.alertEmail',
    defaultMessage: 'Alert notification',
  },
  {
    id: 'settings.alertThreshold',
    defaultMessage: 'Alert range',
  },
  {
    id: 'settings.alertThresholdsAndDetectionTypeMessage',
    defaultMessage: 'Detailed configuration for alerting and generating anomalies.',
  },
  {
    id: 'settings.allMetricSettingReset',
    defaultMessage: 'All the metric setting use this type will be reset!',
  },
  {
    id: 'settings.allRelationships',
    defaultMessage: 'All relationships',
  },
  {
    id: 'settings.anomalyDetectionSetting',
    defaultMessage: 'Anomaly Detection Setting',
  },
  {
    id: 'settings.anomalyDetectionSettingDesc',
    defaultMessage:
      'This setting controls the sensitivity with which InsightFinder will detect and create anomaly events and display them in the Dashboard. With higher sensitivity, system detects more anomalies.',
  },
  {
    id: 'settings.anomalyFeature',
    defaultMessage: 'Anomaly Feature',
  },
  {
    id: 'settings.anomalyGapToleranceDuration',
    defaultMessage: 'Anomaly gap tolerance duration',
  },
  {
    id: 'settings.anomalyGapToleranceDurationTooltip',
    defaultMessage: 'If value is 0 it means disable the anomaly gap tolerance duration',
  },
  {
    id: 'settings.anomalyMetricProjects',
    defaultMessage: 'Anomaly metric projects',
  },
  {
    id: 'settings.anomalyNumberLimitRootCauseSearch',
    defaultMessage: 'Anomaly number limit in root cause search',
  },
  {
    id: 'settings.anomalyString',
    defaultMessage: 'Anomaly string',
  },
  {
    id: 'settings.authentication',
    defaultMessage: 'Authentication',
  },
  {
    id: 'settings.autoFixValidationWindow',
    defaultMessage: 'Auto-fix validation window(Hour)',
  },
  {
    id: 'settings.autoScalingConfiguration',
    defaultMessage: 'Auto-scaling Configuration',
  },
  {
    id: 'settings.autoScalingSetting',
    defaultMessage: 'Auto-scaling setting',
  },
  {
    id: 'settings.averageIncidentDowntimeCost',
    defaultMessage: 'Average per incident downtime cost (Dollars)',
  },
  {
    id: 'settings.averageTriageTime',
    defaultMessage: 'Average triage time (min)',
  },
  {
    id: 'settings.bacthConfigurations',
    defaultMessage: 'Batch Configurations',
  },
  {
    id: 'settings.baselineSpanHours',
    defaultMessage: 'Baseline span (hours)',
  },
  {
    id: 'settings.baseScore',
    defaultMessage: 'Base score',
  },
  {
    id: 'settings.baseScoreDesc',
    defaultMessage: 'The unit anomaly score assigned to each anomaly event',
  },
  {
    id: 'settings.baseValue',
    defaultMessage: 'Base value',
  },
  {
    id: 'settings.baseValueKeys',
    defaultMessage: 'Base value keys',
  },
  {
    id: 'settings.baseValueName',
    defaultMessage: 'Baseline key name',
  },
  {
    id: 'settings.batchName',
    defaultMessage: 'Batch name',
  },
  {
    id: 'settings.blockedIndex',
    defaultMessage: 'Blocked index',
  },
  {
    id: 'settings.button.addIdentityProvider',
    defaultMessage: 'Add Global External Service Provider Setting',
  },
  {
    id: 'settings.button.addWebHook',
    defaultMessage: 'Add WebHook',
  },
  {
    id: 'settings.capacityPlanningSetting',
    defaultMessage: 'Capacity Planning Setting',
  },
  {
    id: 'settings.capacityPriority',
    defaultMessage: 'Capacity priority',
  },
  {
    id: 'settings.category',
    defaultMessage: 'Category',
  },
  {
    id: 'settings.categoryDesc',
    defaultMessage: 'Keywords/regex that are used to create category labels for log entries.',
  },
  {
    id: 'settings.causalAnalysisSetting',
    defaultMessage: 'Causal Analysis Setting',
  },
  {
    id: 'settings.causalPredictionCacheTimeout',
    defaultMessage: 'Causal prediction cache timeout (min)',
  },
  {
    id: 'settings.characterBlacklistSetting',
    defaultMessage: 'Character blacklist setting',
  },
  {
    id: 'settings.clearLock',
    defaultMessage: 'Clear Lock',
  },
  {
    id: 'settings.clickToDisplayDeletedCheckbox',
    defaultMessage: 'Click to display the deleted checkbox',
  },
  {
    id: 'settings.cloudType',
    defaultMessage: 'Integration type',
  },
  {
    id: 'settings.coldEventNumberLimit',
    defaultMessage: 'Cold event number limit',
  },
  {
    id: 'settings.coldEventNumberLimitDesc',
    defaultMessage: 'The maximum number of cold events detect per day',
  },
  {
    id: 'settings.coldEventThreshold',
    defaultMessage: 'Cold event threshold',
  },
  {
    id: 'settings.coldEventThresholdDesc',
    defaultMessage: 'When the number of events is smaller than the threshold, the log analysis detects the cold events',
  },
  {
    id: 'settings.coldEventUnitScore',
    defaultMessage: 'Cold event unit score',
  },
  {
    id: 'settings.compositeRCALimit',
    defaultMessage: 'Composite RCA limit',
  },
  {
    id: 'settings.compositeValidThreshold',
    defaultMessage: 'Composite rule valid threshold',
  },
  {
    id: 'settings.computeDifference',
    defaultMessage: 'Cumulative',
  },
  {
    id: 'settings.computeDifferenceMessage',
    defaultMessage: 'For metrics that is counter type and continue increases.',
  },
  {
    id: 'settings.confidenceLevel',
    defaultMessage: 'Confidence level (%)',
  },
  {
    id: 'settings.confirmed',
    defaultMessage: 'Confirmed',
  },
  {
    id: 'settings.confirmPurgeProject',
    defaultMessage: 'This action will permanently purge all data about this project ({project}).',
  },
  {
    id: 'settings.confirmRecoverProject',
    defaultMessage: 'This action will recover project ({project}).',
  },
  {
    id: 'settings.confirmRemoveProject',
    defaultMessage: 'This action will permanently remove this project ({project}) and all associated data.',
  },
  {
    id: 'settings.confirmTrashProject',
    defaultMessage: 'This action will move project ({project}) to trash.',
  },
  {
    id: 'settings.containerNameKey',
    defaultMessage: 'Container name key',
  },
  {
    id: 'settings.containerNameRegex',
    defaultMessage: 'Container name regex',
  },
  {
    id: 'settings.content',
    defaultMessage: 'Content',
  },
  {
    id: 'settings.copy',
    defaultMessage: 'Copy',
  },
  {
    id: 'settings.copyExistingProject',
    defaultMessage: 'Copy Existing Project',
  },
  {
    id: 'settings.copyOrDownloadTheValueYaml',
    defaultMessage: 'Copy / Download the values.yaml',
  },
  {
    id: 'settings.countByLogEntriesExplain',
    defaultMessage: 'The number of all log entries that match the instance name regex in each sampling interval',
  },
  {
    id: 'settings.countByMetricValueExplain',
    defaultMessage:
      'The count of all the values of the metrics defined in Metric field grouped by the instance name regex in each sampling interval',
  },
  {
    id: 'settings.createNewUser',
    defaultMessage: 'Create new user',
  },
  {
    id: 'settings.creationTime',
    defaultMessage: 'Creation time',
  },
  {
    id: 'settings.criticalEventDetection',
    defaultMessage: 'Critical Event Detection',
  },
  {
    id: 'settings.criticalKeywordSetting',
    defaultMessage: 'Critical keyword setting',
  },
  {
    id: 'settings.cumulativeDistributionFunction',
    defaultMessage: 'Cumulative Distribution Function',
  },
  {
    id: 'settings.currentFinishedChunkNumber',
    defaultMessage: 'Current finished chunk number',
  },
  {
    id: 'settings.dailyReport',
    defaultMessage: 'Daily report notification',
  },
  {
    id: 'settings.dampeningPeriod',
    defaultMessage: 'Dampening period (min)',
  },
  {
    id: 'settings.dampeningPeriodMessage',
    defaultMessage:
      'Generate anomalies only for this period. Any anomalies beyond the dampening period will not be generated.',
  },
  {
    id: 'settings.dataEndTime',
    defaultMessage: 'Data end time',
  },
  {
    id: 'settings.dataRetention',
    defaultMessage: 'Data retention',
  },
  {
    id: 'settings.dataStartTime',
    defaultMessage: 'Data start time',
  },
  {
    id: 'settings.dataType',
    defaultMessage: 'Data type',
  },
  {
    id: 'settings.dataTypesToCollect',
    defaultMessage: 'Data types to collect',
  },
  {
    id: 'settings.Deployment',
    defaultMessage: 'Change Events',
  },
  {
    id: 'settings.detectionBlacklist',
    defaultMessage: 'Detection blocklist',
  },
  {
    id: 'settings.detectionBlacklistDesc',
    defaultMessage: 'Log entries that include the specified keywords/regex are always filtered out.',
  },
  {
    id: 'settings.detectionIndex',
    defaultMessage: 'Detection index',
  },
  {
    id: 'settings.detectionStatus',
    defaultMessage: 'Detection Status',
  },
  {
    id: 'settings.detectionType',
    defaultMessage: 'Detection type',
  },
  {
    id: 'settings.detectionTypeMessage',
    defaultMessage:
      'Positive - higher than normal detection, Negative - lower than normal detection, can choose either one or both.',
  },
  {
    id: 'settings.disableLogCompressEvent',
    defaultMessage: 'Disable normal log saving in edge-brain',
  },
  {
    id: 'settings.disableModelKeywordStatsCollection',
    defaultMessage: 'Disable model keyword stats collection',
  },
  {
    id: 'settings.dispatchDetection',
    defaultMessage: 'Dispatch Detection',
  },
  {
    id: 'settings.dynamicBaselineViolationFactor',
    defaultMessage: 'Dynamic Baseline Violation Factor',
  },
  {
    id: 'settings.editComponentMap',
    defaultMessage: 'Edit component mapping',
  },
  {
    id: 'settings.editMetricType',
    defaultMessage: 'Edit Metric Type',
  },
  {
    id: 'settings.editSystem',
    defaultMessage: 'Edit system',
  },
  {
    id: 'settings.editTableMapping',
    defaultMessage: 'Edit table mapping',
  },
  {
    id: 'settings.editZoneSecrete',
    defaultMessage: 'Edit zone secrete',
  },
  {
    id: 'settings.emailAlertSetting',
    defaultMessage: 'New pattern notification',
  },
  {
    id: 'settings.enableAllRareEventDetection',
    defaultMessage: 'Enable all rare event detection',
  },
  {
    id: 'settings.enableAndDisableMapping',
    defaultMessage: 'Enable/Disable mapping',
  },
  {
    id: 'settings.enableAnomalyDataFiltering',
    defaultMessage: 'Enable anomaly data filtering in training',
  },
  {
    id: 'settings.enableAnomalyFilterForBaseline',
    defaultMessage: 'Enable anomaly filter for baseline',
  },
  {
    id: 'settings.enableAutoScaling',
    defaultMessage: 'Enable auto-scaling',
  },
  {
    id: 'settings.enableBaselineValidation',
    defaultMessage: 'Enable Baseline Validation',
  },
  {
    id: 'settings.enableCumulativeDetect',
    defaultMessage: 'Enable automatic cumulative metric detection',
  },
  {
    id: 'settings.enableDynamicBaselineDetection',
    defaultMessage: 'Enable dynamic baseline detection',
  },
  {
    id: 'settings.enableEmailNotification',
    defaultMessage: 'Enable email notification',
  },
  {
    id: 'settings.enableGlobalKnowledgeBase',
    defaultMessage: 'Enable global knowledge base prediction',
  },
  {
    id: 'settings.enableHorizontalScaling',
    defaultMessage: 'Enable horizontal scaling',
  },
  {
    id: 'settings.enableHotEvent',
    defaultMessage: 'Enable Hot/Cold Event Detection',
  },
  {
    id: 'settings.enableIgnoreInstancePrediction',
    defaultMessage: 'Enable instance ignoring prediction',
  },
  {
    id: 'settings.enableInstanceDownDetection',
    defaultMessage: 'Enable instance down detection',
  },
  {
    id: 'settings.enableJSONPreprocess',
    defaultMessage: 'Enable JSON preprocess',
  },
  {
    id: 'settings.enableJSONPreprocessToolTip',
    defaultMessage: 'Convert some unstructured JSON data to structured one',
  },
  {
    id: 'settings.enableKnowledgeBasedPrediction',
    defaultMessage: 'Enable knowledge base driven incident prediction',
  },
  {
    id: 'settings.enableKPIPrediction',
    defaultMessage: 'Enable KPI incident prediction',
  },
  {
    id: 'settings.enableMetricPrediction',
    defaultMessage: 'Enable metric prediction',
  },
  {
    id: 'settings.enableMissingDataFilling',
    defaultMessage: 'Zero filling',
  },
  {
    id: 'settings.enableMissingDataFillingMessage',
    defaultMessage: 'Use the value zero for any missing data points.',
  },
  {
    id: 'settings.enableNewAlertEmail',
    defaultMessage: 'Enable new pattern alert email',
  },
  {
    id: 'settings.enableNLP',
    defaultMessage: 'Enable NLP',
  },
  {
    id: 'settings.enablePeriodAnomalyFilter',
    defaultMessage: 'Enable period detection filter',
  },
  {
    id: 'settings.enableRegexMultiline',
    defaultMessage: 'Enable regex multiline',
  },
  {
    id: 'settings.enableUBLDetect',
    defaultMessage: 'Enable UBL detection',
  },
  {
    id: 'settings.enableVerticalScaling',
    defaultMessage: 'Enable vertical scaling',
  },
  {
    id: 'settings.endpoint',
    defaultMessage: 'Endpoint',
  },
  {
    id: 'settings.error.emptyInput',
    defaultMessage: 'This setting cannot be empty, please input value.',
  },
  {
    id: 'settings.error.emptySelection',
    defaultMessage: 'This setting cannot be empty, please select from options.',
  },
  {
    id: 'settings.error.errorNoIncident',
    defaultMessage: 'The incident cannot find, please refresh and try again.',
  },
  {
    id: 'settings.error.noProjectHolidayModel',
    defaultMessage: 'There is no holiday period specified. Please setup the holiday period under General tab.',
  },
  {
    id: 'settings.error.noProjectModel',
    defaultMessage: 'There is no model found for {projectName}, please change start/end date or choose other groups.',
  },
  {
    id: 'settings.error.notNumberInput',
    defaultMessage: 'This setting value is invalid, please input number value.',
  },
  {
    id: 'settings.escalateIncidentMessage',
    defaultMessage: 'When an anomaly occurs, escalate to an incident. KPI needs to be selected.',
  },
  {
    id: 'settings.exploreMoreHere',
    defaultMessage: 'Explore more here',
  },
  {
    id: 'settings.falsePositiveInfo',
    defaultMessage: 'False positive info',
  },
  {
    id: 'settings.feature',
    defaultMessage: 'Feature',
  },
  {
    id: 'settings.featureDesc',
    defaultMessage: 'Keywords/regex (e.g., IP address) that are used to extract log entry count distributions.',
  },
  {
    id: 'settings.featureOutlierDetectionSensitivity',
    defaultMessage: 'Feature outlier detection sensitivity',
  },
  {
    id: 'settings.featureVectorLength',
    defaultMessage: 'Feature vector length',
  },
  {
    id: 'settings.FFTMetricPrediction',
    defaultMessage: 'FFT Metric Prediction',
  },
  {
    id: 'settings.fieldName',
    defaultMessage: 'Field name',
  },
  {
    id: 'settings.fieldSetting',
    defaultMessage: 'Field',
  },
  {
    id: 'settings.filterSelfToSelfRule',
    defaultMessage: 'Enable self-to-self rule filtering',
  },
  {
    id: 'settings.general',
    defaultMessage: 'General',
  },
  {
    id: 'settings.grouping',
    defaultMessage: 'Grouping',
  },
  {
    id: 'settings.groupingByInstance',
    defaultMessage: 'Grouping by instance',
  },
  {
    id: 'settings.groupingPriority',
    defaultMessage: 'Grouping priority',
  },
  {
    id: 'settings.groupNameKey',
    defaultMessage: 'Group name key',
  },
  {
    id: 'settings.groupSettingsUpdate',
    defaultMessage: 'Group settings update',
  },
  {
    id: 'settings.healthAlert',
    defaultMessage: 'Incident detection/health alert notification',
  },
  {
    id: 'settings.healthEmail',
    defaultMessage: 'Health alert notification',
  },
  {
    id: 'settings.healthPriority',
    defaultMessage: 'Health priority',
  },
  {
    id: 'settings.healthView',
    defaultMessage: 'Health view',
  },
  {
    id: 'settings.healthViewUpdatePeriod',
    defaultMessage: 'Health View Update Period',
  },
  {
    id: 'settings.helmRepoAddInsightfinder',
    defaultMessage: 'helm repo add insightfinder',
  },
  {
    id: 'settings.highAnomalyRatioDurationThreshold',
    defaultMessage: 'High anomaly ratio duration threshold',
  },
  {
    id: 'settings.holidayModelList',
    defaultMessage: 'Holiday Model List',
  },
  {
    id: 'settings.holidays',
    defaultMessage: 'Holidays',
  },
  {
    id: 'settings.holisticModel',
    defaultMessage: 'Holistic model',
  },
  {
    id: 'settings.hostName',
    defaultMessage: 'Host name',
  },
  {
    id: 'settings.hotColdEventDetection',
    defaultMessage: 'Hot/Cold Event Detection',
  },
  {
    id: 'settings.hotColdEventDetectionDesc',
    defaultMessage:
      'Sensitivity: This setting controls sensitivity InsightFinder will alert on frequency anomaly in given a time window. With higher sensitivity, system detects more anomalies.',
  },
  {
    id: 'settings.hotColdSensitivityDesc',
    defaultMessage: 'Higher sensitivity produces more hot/cold events',
  },
  {
    id: 'settings.hotEventCalmDownPeriod',
    defaultMessage: 'Hot/Cold event detection dampening period (days)',
  },
  {
    id: 'settings.hotEventDetectionMode',
    defaultMessage: 'All patterns / Whitelist patterns only',
  },
  {
    id: 'settings.hotEventDetectionModeAll',
    defaultMessage: 'All patterns',
  },
  {
    id: 'settings.hotEventDetectionModeWhitelist',
    defaultMessage: 'Whitelist patterns only',
  },
  {
    id: 'settings.hotEventNumberLimit',
    defaultMessage: 'Hot event number limit',
  },
  {
    id: 'settings.hotEventNumberLimitDesc',
    defaultMessage: 'The maximum number of hot events detect per day',
  },
  {
    id: 'settings.hotEventThreshold',
    defaultMessage: 'Hot event threshold',
  },
  {
    id: 'settings.hotEventThresholdDesc',
    defaultMessage: 'When the number of events is larger than the threshold, the log analysis detects the hot events',
  },
  {
    id: 'settings.hotEventUnitScore',
    defaultMessage: 'Hot event unit score',
  },
  {
    id: 'settings.hotSamplingInterval',
    defaultMessage: 'Hot event detection sampling interval',
  },
  {
    id: 'settings.hourlyLaborCost',
    defaultMessage: 'Hourly labor cost (Dollars)',
  },
  {
    id: 'settings.ignoredDeployments',
    defaultMessage: 'Deployments that have scaling disabled',
  },
  {
    id: 'settings.ignoreInstanceForKB',
    defaultMessage: 'Ignore Instance For KB',
  },
  {
    id: 'settings.ignoreMessage',
    defaultMessage: 'Ignore any anomaly on chosen components.',
  },
  {
    id: 'settings.ignoreThresholds',
    defaultMessage: 'Ignore thresholds',
  },
  {
    id: 'settings.ignoreThresholdsMessage',
    defaultMessage:
      'Used for ignoring out of bound metric values. Example, for disk usage %, any value below 0 or above 100 can be ignored.',
  },
  {
    id: 'settings.importInstanceMetaData',
    defaultMessage: 'Import instance meta data',
  },
  {
    id: 'settings.incident',
    defaultMessage: 'Incident labels',
  },
  {
    id: 'settings.Incident',
    defaultMessage: 'Incident',
  },
  {
    id: 'settings.incidentConsolidationInterval',
    defaultMessage: 'Incident consolidation interval (min)',
  },
  {
    id: 'settings.incidentCountThreshold',
    defaultMessage: 'Incident count filtering threshold (<)',
  },
  {
    id: 'settings.incidentDesc',
    defaultMessage: 'Keywords/regex that are used to identify which log entries indicate incidents.',
  },
  {
    id: 'settings.incidentDetection',
    defaultMessage: 'Incident Detection',
  },
  {
    id: 'settings.incidentDurationThreshold',
    defaultMessage: 'Incident duration filtering threshold (<) (min)',
  },
  {
    id: 'settings.incidentEmail',
    defaultMessage: 'Incident detection notification',
  },
  {
    id: 'settings.incidentEventDetection',
    defaultMessage: 'Incident Event Detection',
  },
  {
    id: 'settings.incidentKeywords',
    defaultMessage: 'Incident keywords',
  },
  {
    id: 'settings.incidentPatternOrder',
    defaultMessage: 'The incident pattern is matched based on the listed order.',
  },
  {
    id: 'settings.incidentPredictionDesc',
    defaultMessage:
      'The following settings provide configurations for those incidents that are predicted from the current project (i.e., prediction source project).',
  },
  {
    id: 'settings.incidentPredictionLookWindow',
    defaultMessage: 'Incident prediction look ahead window (Hours)',
  },
  {
    id: 'settings.incidentPredictionMinimumLeadTime',
    defaultMessage: 'Incident prediction minimum lead time (min)',
  },
  {
    id: 'settings.incidentPredictionValidationWindow',
    defaultMessage: 'Incident prediction validation window (Hours)',
  },
  {
    id: 'settings.incidentThreshold',
    defaultMessage: 'Incident range',
  },
  {
    id: 'settings.incidentValidationSetting',
    defaultMessage: 'Incident validation setting',
  },
  {
    id: 'settings.includeNormalPattern',
    defaultMessage: 'Include normal pattern in causal analysis',
  },
  {
    id: 'settings.info.externalServiceAdded',
    defaultMessage: 'The external service is added',
  },
  {
    id: 'settings.info.infoComponentChange',
    defaultMessage:
      'Please update the dependency graph under the causal analysis page since the component name is changed.',
  },
  {
    id: 'settings.info.infoProjectlMoveToTrash',
    defaultMessage: 'The project is move to trash',
  },
  {
    id: 'settings.info.infoProjectlRecovered',
    defaultMessage: 'The project is recovered',
  },
  {
    id: 'settings.info.infoProjectSettingSavedSystemName',
    defaultMessage:
      'Please rerun globalview using the update button in Unified Health View under admin account after you are done with changing the system name of all related projects.',
  },
  {
    id: 'settings.info.projectlRemoved',
    defaultMessage: 'Project deletion request has succeeded',
  },
  {
    id: 'settings.info.projectlRemovedFail',
    defaultMessage: 'Project deletion request has failed',
  },
  {
    id: 'settings.info.projectModelPicked',
    defaultMessage: 'The project model is picked',
  },
  {
    id: 'settings.info.projectModelRemoved',
    defaultMessage: 'The project model is removed',
  },
  {
    id: 'settings.info.projectSettingSaved',
    defaultMessage: 'The setting is saved.',
  },
  {
    id: 'settings.info.projectSettingSavedWithExclusion',
    defaultMessage: 'The setting is saved. Exclude following metrics: {excludedMetrics}',
  },
  {
    id: 'settings.info.userRoleSaved',
    defaultMessage: 'User role is saved',
  },
  {
    id: 'settings.installationMode',
    defaultMessage: 'Installation mode',
  },
  {
    id: 'settings.installationModeExisting',
    defaultMessage: 'Use existing {type} system',
  },
  {
    id: 'settings.installationModeInstall',
    defaultMessage: 'Install new {type} system for me',
  },
  {
    id: 'settings.installTheHelmChart',
    defaultMessage: 'Install the helm chart',
  },
  {
    id: 'settings.instanceDownDampening',
    defaultMessage: 'Instance down dampening (min)',
  },
  {
    id: 'settings.instanceDownDetection',
    defaultMessage: 'Instance down notification',
  },
  {
    id: 'settings.instanceDownThreshold',
    defaultMessage: 'Instance down duration threshold (min)',
  },
  {
    id: 'settings.instanceMessage',
    defaultMessage: 'The instances that this metric configuration is associated with.',
  },
  {
    id: 'settings.instanceNameKey',
    defaultMessage: 'Instance name key',
  },
  {
    id: 'settings.instanceNameLabels',
    defaultMessage: 'Instance name',
  },
  {
    id: 'settings.instanceNameLabelsDesc',
    defaultMessage: 'Keywords/regex that are used to get instance name.',
  },
  {
    id: 'settings.instanceNameRegex',
    defaultMessage: 'Instance name regex',
  },
  {
    id: 'settings.instanceSelectAllDay',
    defaultMessage: 'Show All Day Instances',
  },
  {
    id: 'settings.instanceSelectCurrentDay',
    defaultMessage: 'Show Current Day Instances',
  },
  {
    id: 'settings.instanceSettingDesc1',
    defaultMessage:
      'An Instance is used to define logical views on the data for this project. When a grouping is defined, the engine will analyze the data in each group individually and concurrently. You can create different views for the collected data.',
  },
  {
    id: 'settings.instanceSettingDesc2',
    defaultMessage:
      'When metrics or log data from different instances are collected, by default they will be in the All group. To group instances, set the same name for those instances. Or select json or csv file to update grouping in batch.',
  },
  {
    id: 'settings.interInstanceFirst',
    defaultMessage: 'Inter-instance first',
  },
  {
    id: 'settings.interInstanceRelationships',
    defaultMessage: 'Inter-instance relationships',
  },
  {
    id: 'settings.intraInstanceFirst',
    defaultMessage: 'Intra-instance first',
  },
  {
    id: 'settings.intraInstanceRelationships',
    defaultMessage: 'Intra-instance relationships',
  },
  {
    id: 'settings.inUseConfirmed',
    defaultMessage: 'In use/Confirmed',
  },
  {
    id: 'settings.IPAddress',
    defaultMessage: 'IP address',
  },
  {
    id: 'settings.IPAndRCARelationSelection',
    defaultMessage: 'Incident prediction/RCA relation selection',
  },
  {
    id: 'settings.isSourceProject',
    defaultMessage: 'Is source project',
  },
  {
    id: 'settings.jsonFieldName',
    defaultMessage: 'Json field name',
  },
  {
    id: 'settings.jsonFlag',
    defaultMessage: 'Json flag',
  },
  {
    id: 'settings.jsonKey',
    defaultMessage: 'Json key',
  },
  {
    id: 'settings.JsonKey',
    defaultMessage: 'Json Key',
  },
  {
    id: 'settings.kbTrainingLength',
    defaultMessage: 'Knowledge base training length',
  },
  {
    id: 'settings.keywordSetting',
    defaultMessage: 'Keyword setting',
  },
  {
    id: 'settings.knowledgeBase',
    defaultMessage: 'Knowledge Base',
  },
  {
    id: 'settings.knowledgeBaseDesc',
    defaultMessage:
      'The following settings provide configurations for those incidents that are defined in the current project (e.g., prediction target project).',
  },
  {
    id: 'settings.knowledgeBaseSetting',
    defaultMessage: 'Knowledge base setting',
  },
  {
    id: 'settings.knownSystemList',
    defaultMessage: 'Known system list',
  },
  {
    id: 'settings.KPI',
    defaultMessage: 'KPI',
  },
  {
    id: 'settings.KPIMessage',
    defaultMessage:
      'Set metric to KPI to generate an alert. If this is not checked it will appear only as a metric anomaly.',
  },
  {
    id: 'settings.kpiThreshold',
    defaultMessage: 'KPI duration threshold (min)',
  },
  {
    id: 'settings.kpiThresholdMessage',
    defaultMessage: 'Metric anomaly needs to last at least this duration threshold to become a KPI metric alert.',
  },
  {
    id: 'settings.kubernetesEndpoint',
    defaultMessage: 'Kubernetes endpoint',
  },
  {
    id: 'settings.kubernetesInstallInstructions',
    defaultMessage: 'Please walkthrough the following instructions to install it.',
  },
  {
    id: 'settings.kubernetesStepInstructions',
    defaultMessage:
      'You have filled all the infomation we need to install the InsightFinder Kubernetes Collector to your cluster.',
  },
  {
    id: 'settings.labels',
    defaultMessage: 'Labels',
  },
  {
    id: 'settings.laborCost',
    defaultMessage: 'Downtime/Labor cost',
  },
  {
    id: 'settings.largeProject',
    defaultMessage: 'Large project',
  },
  {
    id: 'settings.lastDateRun',
    defaultMessage: 'Last date run',
  },
  {
    id: 'settings.lettersAndNumbers',
    defaultMessage: 'Letters and Numbers',
  },
  {
    id: 'settings.lettersOnly',
    defaultMessage: 'Letters only',
  },
  {
    id: 'settings.level',
    defaultMessage: 'Level',
  },
  {
    id: 'settings.linkingLogProjects',
    defaultMessage: 'Linking Log Projects',
  },
  {
    id: 'settings.linkingLogProjectsDesc',
    defaultMessage:
      'To update component name automatically for multiple log projects, link this project with others log projects.',
  },
  {
    id: 'settings.linkingProjects',
    defaultMessage: 'Linking Projects',
  },
  {
    id: 'settings.lockDuration',
    defaultMessage: 'Lock duration',
  },
  {
    id: 'settings.Log',
    defaultMessage: 'Log',
  },
  {
    id: 'settings.logAnomalyDetection',
    defaultMessage: 'Log Anomaly Detection',
  },
  {
    id: 'settings.logCacheSize',
    defaultMessage: 'Log cache size',
  },
  {
    id: 'settings.logDetection',
    defaultMessage: 'Log Detection',
  },
  {
    id: 'settings.logProcessing',
    defaultMessage: 'Log Processing',
  },
  {
    id: 'settings.logReceiving',
    defaultMessage: 'Log Receiving',
  },
  {
    id: 'settings.logToMetric',
    defaultMessage: 'Log To Metric',
  },
  {
    id: 'settings.logToMetricBaseValue',
    defaultMessage: 'Log To Metric Base Value',
  },
  {
    id: 'settings.logTraining',
    defaultMessage: 'Log Training',
  },
  {
    id: 'settings.loki',
    defaultMessage: 'Loki',
  },
  {
    id: 'settings.lowAnomalyRatioDurationThreshold',
    defaultMessage: 'Low anomaly ratio duration threshold',
  },
  {
    id: 'settings.maintenanceDay',
    defaultMessage: 'Maintenance window',
  },
  {
    id: 'settings.manualEntryToAddNewContent',
    defaultMessage: 'Manual entry to add new content',
  },
  {
    id: 'settings.mappingIds',
    defaultMessage: 'Mapping ids',
  },
  {
    id: 'settings.mappingKeys',
    defaultMessage: 'Mapping keys',
  },
  {
    id: 'settings.matchedCount',
    defaultMessage: 'Matched count',
  },
  {
    id: 'settings.maximumDownInstanceReportingNumber',
    defaultMessage: 'Maximum down instance reporting number',
  },
  {
    id: 'settings.maximumHintNumber',
    defaultMessage: 'Maximum Hint Number',
  },
  {
    id: 'settings.maximumLimitMemory',
    defaultMessage: 'Maximum limit memory',
  },
  {
    id: 'settings.maximumLogDetectionThread',
    defaultMessage: 'Maximum log detection threads',
  },
  {
    id: 'settings.maximumLogEntryValue',
    defaultMessage: 'Maximum log entry value in one analysis batch',
  },
  {
    id: 'settings.maximumPayloadSize',
    defaultMessage: 'Maximum payload size (MB)',
  },
  {
    id: 'settings.maximumPredictionInterval',
    defaultMessage: 'Maximum prediction interval (Hours)',
  },
  {
    id: 'settings.maximumRootCauseDelay',
    defaultMessage: 'Maximum root cause delay (min)',
  },
  {
    id: 'settings.maximumRootCauseHop',
    defaultMessage: 'Maximum root cause hop count',
  },
  {
    id: 'settings.maximumTrainingDataSample',
    defaultMessage: 'Maximum training data sample value per model',
  },
  {
    id: 'settings.maximumWaitTimeForTriggering',
    defaultMessage: 'Maximum wait time for triggering one analysis batch (min)',
  },
  {
    id: 'settings.maxValue',
    defaultMessage: 'Max value',
  },
  {
    id: 'settings.menus.actionMsg',
    defaultMessage: 'Action',
  },
  {
    id: 'settings.menus.deleteProject',
    defaultMessage: 'Delete project',
  },
  {
    id: 'settings.menus.externalService',
    defaultMessage: 'External service settings',
  },
  {
    id: 'settings.menus.identityProvider',
    defaultMessage: 'Global system settings',
  },
  {
    id: 'settings.menus.ifSetting',
    defaultMessage: 'InsightFinder platform settings',
  },
  {
    id: 'settings.menus.serviceMapMsg',
    defaultMessage: 'Service Map',
  },
  {
    id: 'settings.menus.statusPage',
    defaultMessage: 'Status page settings',
  },
  {
    id: 'settings.menus.systemBatch',
    defaultMessage: 'System batch configuration',
  },
  {
    id: 'settings.menus.systemSetting',
    defaultMessage: 'System settings',
  },
  {
    id: 'settings.Metric',
    defaultMessage: 'Metric',
  },
  {
    id: 'settings.metricConfiguration',
    defaultMessage: 'Metric Configuration',
  },
  {
    id: 'settings.metricDataPredictionTrainingDataLength',
    defaultMessage: 'Metric Data Prediction Training Data Length(Min)',
  },
  {
    id: 'settings.metricInstanceNameSetting',
    defaultMessage: 'Metric instance name setting',
  },
  {
    id: 'settings.metricModelSpan',
    defaultMessage: 'Model training span',
  },
  {
    id: 'settings.metricName',
    defaultMessage: 'Metric name',
  },
  {
    id: 'settings.metricNameRegex',
    defaultMessage: 'Metric name regex',
  },
  {
    id: 'settings.metricProject',
    defaultMessage: 'Metric project',
  },
  {
    id: 'settings.metricProjectName',
    defaultMessage: 'Metric project name',
  },
  {
    id: 'settings.metricProjects',
    defaultMessage: 'Metric projects',
  },
  {
    id: 'settings.metricSettingDesc1',
    defaultMessage: 'Note: these settings are not required for basic alerting and notifications.',
  },
  {
    id: 'settings.metricSettingDesc2',
    defaultMessage: 'Use these settings to be notified before specific SLAs are breached.',
  },
  {
    id: 'settings.metricType',
    defaultMessage: 'Metric Type',
  },
  {
    id: 'settings.metricTypeMessage',
    defaultMessage: 'The category this metric belongs to.',
  },
  {
    id: 'settings.metricValueKey',
    defaultMessage: 'Metric value key name',
  },
  {
    id: 'settings.metricValueRegex',
    defaultMessage: 'Metric value regex',
  },
  {
    id: 'settings.minimumFrequencyHotDetection',
    defaultMessage: 'Minimum log frequency for hot event detection',
  },
  {
    id: 'settings.minimumLogEntryValue',
    defaultMessage: 'Minimum log entry value for one analysis batch',
  },
  {
    id: 'settings.minimumPredictionInterval',
    defaultMessage: 'Minimum prediction interval (min)',
  },
  {
    id: 'settings.minimumRequestMemory',
    defaultMessage: 'Minimum request memory',
  },
  {
    id: 'settings.minValidModelSpan',
    defaultMessage: 'Min valid model span (min)',
  },
  {
    id: 'settings.minValue',
    defaultMessage: 'Min value',
  },
  {
    id: 'settings.miscellaneous',
    defaultMessage: 'Miscellaneous',
  },
  {
    id: 'settings.missingDataIncidentEscalation',
    defaultMessage: 'Enable missing data incident escalation',
  },
  {
    id: 'settings.modelDesc',
    defaultMessage:
      'Each heat map models the behavior of one instance. Red areas represent frequent behaviors (i.e. normal states) and the size of the red areas indicates the ranges of different metric values.',
  },
  {
    id: 'settings.modelKeywordSetting',
    defaultMessage: 'Model keyword setting',
  },
  {
    id: 'settings.modelList',
    defaultMessage: 'Model List',
  },
  {
    id: 'settings.modelRetention',
    defaultMessage: 'Model retention',
  },
  {
    id: 'settings.modelSpan',
    defaultMessage: 'Model span (Days)',
  },
  {
    id: 'settings.modelTraining',
    defaultMessage: 'Model Training',
  },
  {
    id: 'settings.modified',
    defaultMessage: 'Modified',
  },
  {
    id: 'settings.namespace',
    defaultMessage: 'Namespace',
  },
  {
    id: 'settings.namespaces',
    defaultMessage: 'Namespaces',
  },
  {
    id: 'settings.nearConstantDetection',
    defaultMessage: 'Near constant detection',
  },
  {
    id: 'settings.nearConstantDetectionMessage',
    defaultMessage:
      'For metrics that are typically at a constant value, this detect any slight changes in those values.',
  },
  {
    id: 'settings.negativeAlertThresholdMessage',
    defaultMessage: 'Generating anomaly when metric value is lower than this threshold.',
  },
  {
    id: 'settings.negativeAnomalyDetection',
    defaultMessage: 'Negative anomaly detection',
  },
  {
    id: 'settings.negativeNoAlertMaxMessage',
    defaultMessage: 'No anomaly will be generated when metric value is lower than this value.',
  },
  {
    id: 'settings.negativeNoAlertMinMessage',
    defaultMessage: 'No anomaly will be generated when metric value is higher than this value.',
  },
  {
    id: 'settings.newPatternDetection',
    defaultMessage: 'New Pattern Detection',
  },
  {
    id: 'settings.newPatternDetectionPeriod',
    defaultMessage: 'New pattern detection period (days)',
  },
  {
    id: 'settings.newPatternDetectionPeriodDesc',
    defaultMessage:
      'The time window for checking whether a pattern has appeared before. If the pattern has not appeared before in the specified time window, the pattern is labelled as a new pattern',
  },
  {
    id: 'settings.noAlertThreshold',
    defaultMessage: 'No alert range',
  },
  {
    id: 'settings.noCapacityPlanningFound',
    defaultMessage: 'No capacity planning configuration found for this component.',
  },
  {
    id: 'settings.noIncidentThreshold',
    defaultMessage: 'No incident range',
  },
  {
    id: 'settings.noMetricConfigurationFound',
    defaultMessage: 'No metric configuration found for this component.',
  },
  {
    id: 'settings.none',
    defaultMessage: 'None',
  },
  {
    id: 'settings.noPreference',
    defaultMessage: 'No preference',
  },
  {
    id: 'settings.normalLogPattern',
    defaultMessage: 'Normal log pattern',
  },
  {
    id: 'settings.normalLogPatternDesc',
    defaultMessage:
      'This setting allows you to include or exclude Normal type log patterns from Causal Analysis. All other Log types will always be included in Causal Analysis, including, Critical, Hot, Cold, Whitelist, Rare and New Log pattens.',
  },
  {
    id: 'settings.normalPatternName',
    defaultMessage: 'Normal log pattern name',
  },
  {
    id: 'settings.noSystem',
    defaultMessage: 'No System',
  },
  {
    id: 'settings.notificationDampeningPeriod',
    defaultMessage: 'Notification dampening period (min)',
  },
  {
    id: 'settings.numberLimit',
    defaultMessage: 'Number limit',
  },
  {
    id: 'settings.numberLimitDesc',
    defaultMessage: 'The maximum number of anomaly events detect per day',
  },
  {
    id: 'settings.numberOfBuckets',
    defaultMessage: 'Number of buckets',
  },
  {
    id: 'settings.OtherMeticProjects',
    defaultMessage: 'Other metic projects',
  },
  {
    id: 'settings.patternIDGenerationRule',
    defaultMessage: 'Pattern ID generation rule',
  },
  {
    id: 'settings.patternIDGenerationRuleMessage',
    defaultMessage: 'Generate unique pattern id for metrics based on their metric name or metric type.',
  },
  {
    id: 'settings.patternLimitSize',
    defaultMessage: 'Pattern limit size',
  },
  {
    id: 'settings.patternLimitSizeDesc',
    defaultMessage: 'Typical limit range is 10 - 1024',
  },
  {
    id: 'settings.patternNameLabels',
    defaultMessage: 'Pattern name labels',
  },
  {
    id: 'settings.patternNameLabelsDesc',
    defaultMessage: 'Keywords/regex that are used to identify pattern names.',
  },
  {
    id: 'settings.patternNameLabelsOrder',
    defaultMessage: 'The pattern is matched based on the listed order.',
  },
  {
    id: 'settings.period',
    defaultMessage: 'Period',
  },
  {
    id: 'settings.pod',
    defaultMessage: 'Pod',
  },
  {
    id: 'settings.podName',
    defaultMessage: 'Pod name',
  },
  {
    id: 'settings.pods',
    defaultMessage: 'Pods',
  },
  {
    id: 'settings.positiveAlertThresholdTypeMessage',
    defaultMessage: 'Generating anomaly when metric value is higher than this threshold.',
  },
  {
    id: 'settings.positiveAnomalyDetection',
    defaultMessage: 'Positive anomaly detection',
  },
  {
    id: 'settings.positivenNoAlertMaxMessage',
    defaultMessage: 'No anomaly will be generated when metric value is lower than this value.',
  },
  {
    id: 'settings.positiveNoAlertMinMessage',
    defaultMessage: 'No anomaly will be generated when metric value is higher than this value.',
  },
  {
    id: 'settings.predictedIncidentCountThreshold',
    defaultMessage: 'Predicted incident count threshold',
  },
  {
    id: 'settings.predictedIncidentNotification',
    defaultMessage: 'Incident prediction notification',
  },
  {
    id: 'settings.predictedIncidentProbabilityThreshold',
    defaultMessage: 'Predicted incident probability threshold (%)',
  },
  {
    id: 'settings.predictionLookSheadWindow',
    defaultMessage: 'Prediction look ahead window (min)',
  },
  {
    id: 'settings.predictionRuleActiveThreshold',
    defaultMessage:
      'Prediction rule enabling probability threshold (%) for predicting incidents produced in the current project',
  },
  {
    id: 'settings.predictionRuleEnablingStatus',
    defaultMessage: 'Prediction rule enabling status for incident project',
  },
  {
    id: 'settings.predictionRuleFalsePositiveThreshold',
    defaultMessage: 'False positive threshold',
  },
  {
    id: 'settings.predictionRuleInactiveThreshold',
    defaultMessage:
      'Prediction rule disabling probability threshold (%) for predicting incidents produced in the current project',
  },
  {
    id: 'settings.predictionSource',
    defaultMessage: 'Prediction source',
  },
  {
    id: 'settings.prerequsites',
    defaultMessage: 'Prerequsites',
  },
  {
    id: 'settings.progressDetectionInfo',
    defaultMessage: 'Progress Detection Info',
  },
  {
    id: 'settings.projectDetectionStatus',
    defaultMessage: 'Project Detection Status',
  },
  {
    id: 'settings.projectLevelMaintenanceWindows',
    defaultMessage: 'Project level maintenance windows',
  },
  {
    id: 'settings.projectNameToNamespaceMapping',
    defaultMessage: 'Project name to namespace mapping',
  },
  {
    id: 'settings.projectSetting',
    defaultMessage: 'Project setting',
  },
  {
    id: 'settings.projectSharing',
    defaultMessage: 'Project Sharing',
  },
  {
    id: 'settings.projectSharingDesc1',
    defaultMessage:
      'If you are collaborating with other users, you may invite them to view data associated with your Projects.',
  },
  {
    id: 'settings.projectSharingDesc2',
    defaultMessage:
      'To share your project, enter their User ID(s) in the field below and click Update button. Those users will be able to view your project on their next login.',
  },
  {
    id: 'settings.projectsNoSystem',
    defaultMessage: 'Projects with no system',
  },
  {
    id: 'settings.projectWizard.AWSCloudIntro',
    defaultMessage: 'Uses the AWS cloud cost API to monitor',
  },
  {
    id: 'settings.projectWizard.AWSCloudWatchIntro',
    defaultMessage:
      'AWS instance monitoring data (i.e., metrics, logs, traces) can be easily ingested into InsightFinder via Cloudwatch APIs.',
  },
  {
    id: 'settings.projectWizard.AzureCost',
    defaultMessage: 'Uses Azure Cost APIs to monitor.',
  },
  {
    id: 'settings.projectWizard.cAdvisorAgent',
    defaultMessage: 'Null',
  },
  {
    id: 'settings.projectWizard.cAdvisorIntro',
    defaultMessage:
      'This InsightFinder agent can be used to monitor system metrics of docker containers using cadvisor.',
  },
  {
    id: 'settings.projectWizard.errorNotAllowAdmin',
    defaultMessage: 'This account can view project only, use other account to create new project',
  },
  {
    id: 'settings.projectWizard.followingPermissions',
    defaultMessage: 'IMPORTANT: please make sure the IAM access key has the following permissions',
  },
  {
    id: 'settings.projectWizard.GitActionsIntro',
    defaultMessage: 'Uses GitHub Actions to monitor and report events.',
  },
  {
    id: 'settings.projectWizard.GoogleAdsIntro',
    defaultMessage: 'Uses Google Ads APIs to monitor and report metrics for Microservices and Container Based Apps.',
  },
  {
    id: 'settings.projectWizard.GoogleBigQueryIntro',
    defaultMessage: 'Uses Google BigQuery APIs to monitor and report logs/metrics.',
  },
  {
    id: 'settings.projectWizard.GoogleCloudCost',
    defaultMessage: 'Uses Google Cloud Cost APIs to monitor.',
  },
  {
    id: 'settings.projectWizard.GoogleCloudIntro',
    defaultMessage: 'Uses Google Cloud APIs to monitor and report metrics.',
  },
  {
    id: 'settings.projectWizard.GoogleCloudMonitoringIntro',
    defaultMessage: "Uses Google Cloud APIs to monitor and report system metrics for your Google instances.'",
  },
  {
    id: 'settings.projectWizard.GoogleCloudPubSubIntro',
    defaultMessage: 'Uses Google Cloud PubSub APIs to monitor and report logs.',
  },
  {
    id: 'settings.projectWizard.InsightAgentIntro',
    defaultMessage:
      'Uses <b>InsightAgent</b> to monitor and report system logs/metrics for your instances. For details, please visit <a>https://github.com/insightfinder/InsightAgent</a>.',
  },
  {
    id: 'settings.projectWizard.JDBCIntro',
    defaultMessage: 'Uses JDBC to monitor and report metrics for databases.',
  },
  {
    id: 'settings.projectWizard.KubernetesIntro',
    defaultMessage: 'Uses Kubernetes APIs to monitor and report metrics.',
  },
  {
    id: 'settings.projectWizard.MicrosoftAzureIntro',
    defaultMessage: 'Uses Azure Monitoring APIs to monitor and report logs/metrics.',
  },
  {
    id: 'settings.projectWizard.PagerDutyIntro',
    defaultMessage: 'Uses PagerDuty APIs to monitor and report alerts.',
  },
  {
    id: 'settings.projectWizard.PublicCATechnologyIntro',
    defaultMessage: 'Uses CA Technology APIs to monitor and report system metrics for your instances.',
  },
  {
    id: 'settings.projectWizard.PublicCloudIntro',
    defaultMessage: 'Uses public Cloud APIs to monitor and report system metrics for your instances.',
  },
  {
    id: 'settings.projectWizard.PublicDataDogIntro',
    defaultMessage: 'Uses Datadog APIs to monitor and report system metrics or events for your instances.',
  },
  {
    id: 'settings.projectWizard.PublicNewRelicIntro',
    defaultMessage: 'Uses NewRelic APIs to monitor and report logs/metrics.',
  },
  {
    id: 'settings.projectWizard.ServiceNowIntro',
    defaultMessage:
      'Uses ServiceNow APIs to import incident ticket data into InsightFinder for incident data analysis.',
  },
  {
    id: 'settings.projectWizard.shadowProject',
    defaultMessage: 'Uses InsightAgent to import anomalies into InsightFinder.',
  },
  {
    id: 'settings.projectWizard.snowflakePerformanceMonitoring',
    defaultMessage: 'Snowflake performance monitoring',
  },
  {
    id: 'settings.projectWizard.snowIntro',
    defaultMessage: 'Uses Snowflake to monitor and report metrics for databases.',
  },
  {
    id: 'settings.projectWizard.step1CopyProject',
    defaultMessage:
      'Projects represent different data sources. They can be combined to form systems. All project data is analyzed together in the system. Select from a list of available projects to copy below. Optionally, describe the new project.',
  },
  {
    id: 'settings.projectWizard.step1Introduction',
    defaultMessage:
      'Projects define which data is analyzed together. Separate data from different, unrelated sources into separate projects.',
  },
  {
    id: 'settings.projectWizard.step2Introduction',
    defaultMessage:
      'Please select the data source for this project. You can filter the data sources by the OS, platform or application and select the data sources you want to add.',
  },
  {
    id: 'settings.projectWizard.step3Introduction',
    defaultMessage: 'Following the precedure to config settings or install agent for the selected data sources.',
  },
  {
    id: 'settings.projectWizard.step4Introduction',
    defaultMessage:
      'Congratulation, you have setup the project. It might take about 15 minutes for the data source agent to work, you can check the status of the data source in the <bold>Project Settings</bold>.',
  },
  {
    id: 'settings.projectWizard.SumoLogicIntro',
    defaultMessage: 'Uses Sumo Logic APIs to monitor and report logs.',
  },
  {
    id: 'settings.projectWizard.ZendeskIntro',
    defaultMessage: 'Uses Zendesk APIs to monitor and report logs.',
  },
  {
    id: 'settings.prometheus',
    defaultMessage: 'Prometheus',
  },
  {
    id: 'settings.proxy',
    defaultMessage: 'Proxy',
  },
  {
    id: 'settings.rareAnomalyType',
    defaultMessage: 'Rare anomaly type',
  },
  {
    id: 'settings.rareAnomalyTypeAll',
    defaultMessage: 'All',
  },
  {
    id: 'settings.rareAnomalyTypeNumericOnly',
    defaultMessage: 'Numerical value only',
  },
  {
    id: 'settings.rareAnomalyTypeWordsOnly',
    defaultMessage: 'Words only',
  },
  {
    id: 'settings.rareEventBaseScore',
    defaultMessage: 'Rare event base score',
  },
  {
    id: 'settings.rareEventBaseScoreDesc',
    defaultMessage: 'The unit anomaly score assigned to each rare event',
  },
  {
    id: 'settings.rareEventClusterSize',
    defaultMessage: 'Rare event cluster size',
  },
  {
    id: 'settings.rareEventClusterSizeDesc',
    defaultMessage: 'Larger rare event cluster size produces more rare events',
  },
  {
    id: 'settings.rareEventDetection',
    defaultMessage: 'Rare Event Detection',
  },
  {
    id: 'settings.rareEventDetectionDesc',
    defaultMessage:
      'This setting controls the sensitivity with which InsightFinder will cluster logs and detect anomalous logs. With higher sensitivity, system detects more anomalies.',
  },
  {
    id: 'settings.rareEventNumberLimit',
    defaultMessage: 'Rare event number limit',
  },
  {
    id: 'settings.rareEventNumberLimitDesc',
    defaultMessage: 'The maximum number of rare events detect per day',
  },
  {
    id: 'settings.rareSensitivityDesc',
    defaultMessage: 'Higher sensitivity produces more rare events',
  },
  {
    id: 'settings.regexIsCaseSensitive',
    defaultMessage: 'Regex is case sensitive.',
  },
  {
    id: 'settings.regularModelList',
    defaultMessage: 'Regular Model List',
  },
  {
    id: 'settings.replayStatus',
    defaultMessage: 'Replay Status',
  },
  {
    id: 'settings.replayTriggerTimestamp',
    defaultMessage: 'Replay trigger time',
  },
  {
    id: 'settings.resetDetectionIndex',
    defaultMessage: 'Reset Detection Index',
  },
  {
    id: 'settings.restoreLogRawData',
    defaultMessage: 'Restore log raw data',
  },
  {
    id: 'settings.retention',
    defaultMessage: 'Retention',
  },
  {
    id: 'settings.retentionTime',
    defaultMessage: 'Retention Time',
  },
  {
    id: 'settings.rootCauseAnalysisDesc',
    defaultMessage:
      'This page provides configurations for the root causes that lead to any incident produced by this project "{projectName}".',
  },
  {
    id: 'settings.rootCauseAnalysisSetting',
    defaultMessage: 'Root Cause Analysis Setting',
  },
  {
    id: 'settings.rootCauseCountThreshold',
    defaultMessage: 'Root cause count threshold',
  },
  {
    id: 'settings.rootCauseInfo',
    defaultMessage: 'Root cause stats',
  },
  {
    id: 'settings.rootCauseLogSearchWindow',
    defaultMessage: 'Root cause log message search window (min)',
  },
  {
    id: 'settings.rootCauseNumberLimitIncident',
    defaultMessage: 'Root cause number limit per incident',
  },
  {
    id: 'settings.rootCauseProbabilityThreshold',
    defaultMessage: 'Root cause probability threshold (%)',
  },
  {
    id: 'settings.rootCauseRank',
    defaultMessage: 'Root cause rank',
  },
  {
    id: 'settings.samples',
    defaultMessage: 'Samples',
  },
  {
    id: 'settings.samplingInterval',
    defaultMessage: 'Sampling interval',
  },
  {
    id: 'settings.scaleDownThreshold',
    defaultMessage: 'Scale down threshold',
  },
  {
    id: 'settings.scaleUpThreshold',
    defaultMessage: 'Scale up threshold',
  },
  {
    id: 'settings.scoreKey',
    defaultMessage: 'Score key',
  },
  {
    id: 'settings.sensitiveDataFilter',
    defaultMessage: 'Sensitive data filter',
  },
  {
    id: 'settings.sensitiveDataFilterDesc',
    defaultMessage: 'Keywords/regex that are used to identify which log entries sensitive data filter.',
  },
  {
    id: 'settings.sensitivity',
    defaultMessage: 'Sensitivity',
  },
  {
    id: 'settings.sequenceScope',
    defaultMessage: 'Sequence scope',
  },
  {
    id: 'settings.sequenceScopeDesc',
    defaultMessage: 'Defines the sequence scope for the log entires.',
  },
  {
    id: 'settings.serverName',
    defaultMessage: 'Server name',
  },
  {
    id: 'settings.setting',
    defaultMessage: 'Setting',
  },
  {
    id: 'settings.showTrashProjects',
    defaultMessage: 'Show projects in trash',
  },
  {
    id: 'settings.similarityDegree',
    defaultMessage: 'Similarity degree',
  },
  {
    id: 'settings.similarityDegreeDesc',
    defaultMessage: 'Higher similarity degree produces more patterns.',
  },
  {
    id: 'settings.specialSamplingInterval',
    defaultMessage: 'Special sampling interval',
  },
  {
    id: 'settings.statisticsMessage',
    defaultMessage: 'For the time period chosen above, the average value of this metric.',
  },
  {
    id: 'settings.statisticsTime',
    defaultMessage: 'Statistics time',
  },
  {
    id: 'settings.stringContent',
    defaultMessage: 'String content',
  },
  {
    id: 'settings.summarySettings',
    defaultMessage: 'Summary settings',
  },
  {
    id: 'settings.systemCapacitySetting',
    defaultMessage: 'Capacity Planning Setting',
  },
  {
    id: 'settings.systemComposition',
    defaultMessage: 'System composition',
  },
  {
    id: 'settings.systemDown',
    defaultMessage: 'System down notification',
  },
  {
    id: 'settings.systemEmailNotificationSetting',
    defaultMessage: 'Notification settings',
  },
  {
    id: 'settings.systemEmailSetting',
    defaultMessage: 'Email',
  },
  {
    id: 'settings.systemLevel',
    defaultMessage: 'System level',
  },
  {
    id: 'settings.systemLevelMaintenanceWindows',
    defaultMessage: 'System level maintenance windows',
  },
  {
    id: 'settings.SystemName',
    defaultMessage: 'System Name',
  },
  {
    id: 'settings.systemProxy',
    defaultMessage: 'Action server list',
  },
  {
    id: 'settings.systemScoresSetting',
    defaultMessage: 'Max anomaly scores',
  },
  {
    id: 'settings.systemSharedUsers',
    defaultMessage: 'System shared users',
  },
  {
    id: 'settings.systemSharing',
    defaultMessage: 'System sharing',
  },
  {
    id: 'settings.targetProject',
    defaultMessage: 'Target project',
  },
  {
    id: 'settings.threshold',
    defaultMessage: 'Threshold',
  },
  {
    id: 'settings.timeInsensitiveRuleMatching',
    defaultMessage: 'Enable time insensitive rule matching',
  },
  {
    id: 'settings.timestampFormat',
    defaultMessage: 'Timestamp format',
  },
  {
    id: 'settings.timestampFormatExplain',
    defaultMessage: 'It needs to be defined if the timestamp key is not in epoch format',
  },
  {
    id: 'settings.timestampKey',
    defaultMessage: 'Timestamp key',
  },
  {
    id: 'settings.timestampRegex',
    defaultMessage: 'Timestamp regex',
  },
  {
    id: 'settings.timestampRegexExplain',
    defaultMessage:
      'The timestamp field from the log entry using regex. If not provided, it will use the the log entry timestamp',
  },
  {
    id: 'settings.timeWindowType',
    defaultMessage: 'Time window type',
  },
  {
    id: 'settings.TopAnomalousInstanceNumberLimit',
    defaultMessage: 'Top anomalous instance number limit',
  },
  {
    id: 'settings.totalChunk',
    defaultMessage: 'Total chunk',
  },
  {
    id: 'settings.totalChunkNumber',
    defaultMessage: 'Total chunk number',
  },
  {
    id: 'settings.totalEntry',
    defaultMessage: 'Total entry',
  },
  {
    id: 'settings.Trace',
    defaultMessage: 'Trace',
  },
  {
    id: 'settings.trainingBlacklist',
    defaultMessage: 'Training blocklist',
  },
  {
    id: 'settings.trainingBlacklistDesc',
    defaultMessage: 'Keywords/regex that are always excluded during model training.',
  },
  {
    id: 'settings.trainingLock',
    defaultMessage: 'Training lock',
  },
  {
    id: 'settings.trainingModelSpan',
    defaultMessage: 'Training model span range',
  },
  {
    id: 'settings.trainingWhitelist',
    defaultMessage: 'Training allowedlist',
  },
  {
    id: 'settings.trainingWhitelistDesc',
    defaultMessage: 'Keywords/regex that are always included during model training.',
  },
  {
    id: 'settings.triageReport',
    defaultMessage: 'Operator notes',
  },
  {
    id: 'settings.triageReportDesc',
    defaultMessage: 'Keywords/regex that are used to identify which log entries indicate summary notes.',
  },
  {
    id: 'settings.triggeringMode',
    defaultMessage: 'Triggering mode',
  },
  {
    id: 'settings.truePositiveInfo',
    defaultMessage: 'True positive info',
  },
  {
    id: 'settings.uniqueCountByMetricValueExplain',
    defaultMessage:
      'The count of all the unique values of the metrics defined in Metric field grouped by the instance name regex in each sampling interval',
  },
  {
    id: 'settings.unmatchedCount',
    defaultMessage: 'Unmatched count',
  },
  {
    id: 'settings.updateBaseValueMapping',
    defaultMessage: 'Update base value mapping',
  },
  {
    id: 'settings.userDefinedExplain',
    defaultMessage:
      'To plot the value of the metric defined by regex grouped by the instance name regex in each sampling interval',
  },
  {
    id: 'settings.valueShouldLargeThan',
    defaultMessage: 'This field must be greater than <a>0</a>.',
  },
  {
    id: 'settings.warn.projectModelNotExists',
    defaultMessage: 'The project model not exists, please select another one',
  },
  {
    id: 'settings.webhook',
    defaultMessage: 'Webhook',
  },
  {
    id: 'settings.webhookDampeningPeriod',
    defaultMessage: 'Webhook dampening period (min)',
  },
  {
    id: 'settings.webhookHeaders',
    defaultMessage: 'Webhook headers',
  },
  {
    id: 'settings.webhookSecretKey',
    defaultMessage: 'Webhook secret key',
  },
  {
    id: 'settings.webhookUrl',
    defaultMessage: 'Webhook url',
  },
  {
    id: 'settings.weeklyReport',
    defaultMessage: 'Weekly report notification',
  },
  {
    id: 'settings.whitelist',
    defaultMessage: 'Detection keywords',
  },
  {
    id: 'settings.whitelistDesc',
    defaultMessage: 'Keywords/regular expressions that are used to detect which log entries will produce alerts.',
  },
  {
    id: 'settings.whitelistEventDetection',
    defaultMessage: 'Whitelist Event Detection',
  },
  {
    id: 'settings.whitelistLabelsOrder',
    defaultMessage: 'The detection keywords is matched based on the listed order.',
  },
  {
    id: 'settings.zone',
    defaultMessage: 'Zone',
  },
  {
    id: 'settings.zoneKey',
    defaultMessage: 'Zone key',
  },
  {
    id: 'settings.zoneLevel',
    defaultMessage: 'Zone level',
  },
  {
    id: 'settings.zoneName',
    defaultMessage: 'Zone name',
  },
  {
    id: 'settings.zoneNameKey',
    defaultMessage: 'Zone name key',
  },
  {
    id: 'settings.zoneSecreteForJWT',
    defaultMessage: 'Zone secret for JWT',
  },
  {
    id: 'usecase.info.infoBugIssueRemoved',
    defaultMessage: 'This issue is removed',
  },
  {
    id: 'usecase.info.infoCausalGroupRemoved',
    defaultMessage: 'This causal group is removed',
  },
  {
    id: 'usecase.info.infoCausalIncidentRemoved',
    defaultMessage: 'This item is removed',
  },
];
