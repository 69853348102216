import React from 'react';
// import * as R from 'ramda';
import moment from 'moment';
// import { get } from 'lodash';
import {} from 'antd';

import { Defaults } from '../../../common/utils';
import { Modal, AutoSizer, Table, Column } from '../../../lib/fui/react';

// import { DashboardMessages } from '../../../common/dashboard/messages';

// eslint-disable-next-line
const SystemScoreHistorical = ({ intl, credentials, isAdmin, customerName, system, systemHealth, onClose }: Object) => {
  const { historicalMaxStats } = systemHealth || {};

  const dayRender = ({ cellData }) =>
    cellData ? moment.utc(cellData, Defaults.SmartDateFormat).format(Defaults.DateFormat) : '';
  return (
    <Modal
      title="Historical max scores"
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      footer={null}
      width={650}
    >
      <AutoSizer disableHeight>
        {({ width }) => (
          <Table
            className="with-border"
            width={width}
            height={300}
            headerHeight={30}
            rowHeight={40}
            rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
            rowCount={historicalMaxStats.length}
            rowGetter={({ index }) => historicalMaxStats[index]}
          >
            <Column width={120} label="Day" dataKey="day" cellRenderer={dayRender} />
            <Column width={100} label="Max metric score" dataKey="metricTotalNinetyNinePercentileVal" flexGrow={1} />
            <Column width={100} label="Max log score" dataKey="logTotalNinetyNinePercentileVal" flexGrow={1} />
          </Table>
        )}
      </AutoSizer>
    </Modal>
  );
};

export default SystemScoreHistorical;
