/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isEmpty, debounce, toInteger } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import Icon, { SearchOutlined } from '@ant-design/icons';
import { Radio, Tooltip, Input, Empty } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { parseJSON } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal, Container, AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';
import { Tree } from '../../../lib/fui/icons';
import { D3Tree } from '../../share';

import { appFieldsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

type Props = {
  onCancel: Function,
  customerName: String,
  causalGroup: Object,
  incident: Object,
  metaData: Object,

  intl: Object,
  loadStatus: Object,
  projects: Array<Object>,
  projectDisplayMap: Object,
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class ViewDependencyModalCore extends D3Tree {
  props: Props;

  constructor(props) {
    super(props);

    // update tree orientation
    this.orientation = 'left-to-right';

    // local data
    this.componentCausalInfo = {};
    this.relationElemInfoMap = {};
    this.relationInfoMap = {};
    this.metaData = {};
    this.relations = [];
    this.allRootNodes = [];

    this.filterNodeList = [];

    this.nodeRelationMap = {};

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 30,
    });

    this.state = {
      isLoading: false,

      hasRelation: false,
      relationList: [],

      graphView: 'source',
      rootnode: null,
      nodeSearchVal: null,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.causalGroup !== this.props.causalGroup) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { credentials, customerName, causalGroup, incident, metaData } = props;

    if (customerName && causalGroup && incident) {
      this.setState({ isLoading: true });

      // parse data
      const { relationList, noRelationList } = incident;

      // remove no relation data
      const noRelations = R.map((item) => `${item.s.id}-${item.t.id}`, noRelationList || []);

      const relations = [];
      R.forEach((item) => {
        const { s: elem1, t: elem2 } = item;
        const key = `${elem1.id}-${elem2.id}`;
        if (!noRelations.includes(key)) {
          relations.push({
            elem1: elem1.id,
            elem2: elem2.id,
          });
        }
      }, relationList || []);
      this.relations = relations;

      this.props.updateLastActionInfo();
      const componentCausalInfo = {};

      const projectMetaData = metaData?.projectMetaData || [];
      const projectMetaDataMap = {};
      R.forEach((i) => {
        const { userName, projectName } = JSON.parse(i?.m || {});
        const projectNameReal = userName !== credentials.userName ? `${projectName}@${userName}` : projectName;
        projectMetaDataMap[String(i?.p)] = projectNameReal;
      }, projectMetaData);

      // parse metadata
      const instanceMetaData = JSON.parse(metaData?.instanceMetaData, '{}');

      R.forEachObjIndexed((val) => {
        const { componentName, instanceListStr } = val;
        R.forEach((item) => {
          const projectNameReal = projectMetaDataMap[String(item?.p)];

          if (!R.has(componentName, componentCausalInfo)) {
            componentCausalInfo[componentName] = {
              id: componentName,
              ownProjectNames: [projectNameReal],
            };
          } else if (!componentCausalInfo[componentName].ownProjectNames.includes(projectNameReal)) {
            componentCausalInfo[componentName].ownProjectNames.push(projectNameReal);
          }
        }, JSON.parse(instanceListStr || '[]'));
      }, instanceMetaData);

      this.componentCausalInfo = componentCausalInfo;
      this.setState({ isLoading: false }, () => {
        this.renderChart(props);
      });
    }
  }
  @autobind
  nodeClick(props) {
    return async (d) => {
      const { root } = props;
      const { isExpandNode, path, direction } = d || {};

      if (isExpandNode) {
        const nodeData = this.findNodeByPath(root, path);
        // get next nodes
        const nodeRelation = get(this.nodeRelationMap, nodeData.node);
        const childrenNodeNames = nodeRelation.nextNodes || [];

        let nodeNames = [];
        let startIndex = null;
        if (direction === 'next') {
          startIndex = this.nodePathExpand[path].display + this.autoDisplayNode;
        } else {
          startIndex = R.max(0, this.nodePathExpand[path].display - this.autoDisplayNode);
        }
        this.nodePathExpand[path].display = startIndex;
        nodeNames = R.slice(startIndex, startIndex + this.autoDisplayNode, childrenNodeNames);
        const needNextNode = this.nodePathExpand[path].total > startIndex + this.autoDisplayNode;
        const needPreviousNode = startIndex > 0;

        const newChildren = this.createTreeData({
          nodeRelationMap: this.nodeRelationMap,
          parentAllNodeNameMap: nodeData.newParentAllNodeNameMap,
          nodeNames,
          parentNode: nodeData.node,
          parentPath: nodeData.path,
          level: nodeData.level + 1,
          hasInstance: nodeData.hasInstance,
        });

        // build new children
        nodeData.children = newChildren;
        if (needNextNode) {
          nodeData.children.push(this.getExpandNode({ path, direction: 'next' }));
        }
        if (needPreviousNode) {
          nodeData.children.push(this.getExpandNode({ path, direction: 'previous' }));
        }

        // update tree
        this.updateTree(nodeData, props);
      }
    };
  }

  @autobind
  getChartData(props) {
    const { graphView, nodeSearchVal } = this.state;
    let { rootnode } = this.state;

    const relationList = [];
    const relationKeys = [];
    R.forEach((relation) => {
      const key = `${relation.elem1}-${relation.elem2}`;
      if (relationKeys.indexOf(key) === -1) {
        relationList.push(relation);
        relationKeys.push(key);
      }
    }, this.relations);
    const hasRelation = !isEmpty(relationList);

    // create tree data
    if (relationList.length >= 100) {
      this.autoDisplayLevel = 2;
    } else {
      this.autoDisplayLevel = this.defaultMaxLevel;
    }

    const relationInfoMap = {};
    const nodeRelationMap = {};
    let fromNodeNames = [];
    let toNodeNames = [];

    // create node map
    R.forEach((relation) => {
      const { elem1, elem2 } = relation;

      // set relationInfoMap
      relationInfoMap[`${elem1}-${elem2}`] = relation;

      // reduce relation infos
      fromNodeNames = [...fromNodeNames, elem1];
      toNodeNames = [...toNodeNames, elem2];

      if (!R.has(elem1, nodeRelationMap))
        nodeRelationMap[elem1] = { nextNodes: [], previousNodes: [], nextNodeRelations: [], previousNodeRelations: [] };
      if (!R.has(elem2, nodeRelationMap))
        nodeRelationMap[elem2] = { nextNodes: [], previousNodes: [], nextNodeRelations: [], previousNodeRelations: [] };

      nodeRelationMap[elem1].nextNodes.push(elem2);
      nodeRelationMap[elem2].previousNodes.push(elem1);
      nodeRelationMap[elem1].nextNodeRelations.push(relation);
      nodeRelationMap[elem2].previousNodeRelations.push(relation);
    }, relationList);

    fromNodeNames = R.uniq(fromNodeNames);
    toNodeNames = R.uniq(toNodeNames);
    const allRootNodes = graphView === 'target' ? toNodeNames : fromNodeNames;

    this.relationInfoMap = relationInfoMap;
    this.allRootNodes = allRootNodes;
    this.nodeRelationMap = nodeRelationMap;

    // filter node list
    this.handleNodeFilter(nodeSearchVal);
    if (!rootnode || (rootnode && this.filterNodeList.indexOf(rootnode) === -1)) {
      if (this.filterNodeList.length > 0) rootnode = this.filterNodeList[0];
    }

    const startTs = moment.utc().valueOf();
    const treeData = this.createTreeData({
      nodeRelationMap: this.nodeRelationMap,
      parentAllNodeNameMap: {},
      nodeNames: rootnode ? [rootnode] : [],
      parentNode: null,
      parentPath: null,
      level: 1,
    });
    console.debug(`Create tree duration: ${(moment.utc().valueOf() - startTs) / 1000} sec`);

    this.setState({
      hasRelation,
      relationList,
      rootnode,
    });
    return {
      treeData: treeData.length > 0 ? treeData[0] : {},
    };
  }

  @autobind
  renderTip({ operation, target, d }) {
    if (operation === 'node') {
      const { node } = d;
      return node;
    }
    return '';
  }

  @autobind
  onChangeGraphView(event) {
    const graphView = event.target.value;
    this.setState({ graphView }, () => {
      // update tree orientation
      this.orientation = graphView === 'target' ? 'right-to-left' : 'left-to-right';
      this.renderChart(this.props);
    });
  }

  @autobind
  onChangeRootnode(event) {
    const rootnodeVal = event.target.value;
    this.setState({ rootnode: rootnodeVal }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  handleNodeFilter(nodeSearchVal) {
    const filterNodeList = this.allRootNodes;
    this.filterNodeList = nodeSearchVal
      ? R.filter((node) => {
          return R.toLower(node).indexOf(R.toLower(nodeSearchVal)) !== -1;
        }, filterNodeList)
      : filterNodeList;
  }

  @autobind
  renderListItemInter(rootnodeVal, filterNodeList) {
    return ({ key, index: rowIndex, style, parent }) => {
      const { intl, projectDisplayMap } = this.props;

      const node = filterNodeList[rowIndex];
      if (!node) return null;

      const label = node;

      const ownProjectNames = get(this.componentCausalInfo, [node, 'ownProjectNames'], []);
      const title =
        ownProjectNames.length > 0 ? (
          <div>
            <div>{node}</div>
            <div style={{ marginTop: 4 }}>{intl.formatMessage(appFieldsMessages.project)}:</div>
            {R.addIndex(R.map)((projectNameReal, idx) => {
              const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
              return <div key={idx}>{projectDisplayName}</div>;
            }, ownProjectNames)}
          </div>
        ) : (
          node
        );

      const content = (
        <div className="flex-row flex-center-align" style={{ ...style, width: 'auto', maxWidth: '100%' }}>
          <Tooltip title={title} placement="topLeft" mouseEnterDelay={0.3}>
            <Radio
              className="hidden-line-with-ellipsis inline-block max-width"
              value={node}
              checked={rootnodeVal === node}
            >
              {label}
            </Radio>
          </Tooltip>
        </div>
      );

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          {content}
        </CellMeasurer>
      );
    };
  }

  render() {
    const { intl, onCancel } = this.props;
    const { isLoading, graphView } = this.state;
    const { hasRelation, rootnode, nodeSearchVal } = this.state;

    const { filterNodeList } = this;

    return (
      <Modal
        title="Dependency Map"
        width={960}
        visible
        onCancel={onCancel}
        footer={null}
        bodyStyle={{ padding: 8 }}
        maskClosable={false}
      >
        <Container
          className={`${isLoading ? 'loading ' : ''} causal flex-row flex-min-width corner-10`}
          style={{ height: 500, border: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <div
            className="flex-col flex-min-height"
            style={{ width: 240, padding: '0 8px', borderRight: '1px solid lightgrey' }}
          >
            <Radio.Group
              className="flex-grow flex-col flex-min-height"
              onChange={this.onChangeRootnode}
              value={rootnode}
            >
              <div className="flex-row" style={{ marginTop: 8 }}>
                <div style={{ fontWeight: 'bold', fontSize: 16 }}>{intl.formatMessage(causalMessages.nodeList)}</div>
                <div className="flex-grow flex-row flex-end-justify">
                  <Radio.Group value={graphView} size="small" onChange={this.onChangeGraphView}>
                    <Tooltip title="Prediction Graph" placement="top">
                      <Radio.Button value="source">
                        <Icon size="small" component={() => <Tree color="currentColor" size="12px" rotate={-90} />} />
                      </Radio.Button>
                    </Tooltip>
                    <Tooltip title="Root Cause Graph" placement="top">
                      <Radio.Button value="target">
                        <Icon size="small" component={() => <Tree color="currentColor" size="12px" rotate={90} />} />
                      </Radio.Button>
                    </Tooltip>
                  </Radio.Group>
                </div>
              </div>

              <div className="flex-row" style={{ marginTop: 8 }}>
                <Input
                  allowClear
                  size="small"
                  placeholder="input search node"
                  value={nodeSearchVal}
                  onChange={({ target: { value } }) => {
                    this.setState(
                      { nodeSearchVal: value },
                      debounce(() => {
                        this.handleNodeFilter(value);
                        this.cellMeasureCache.clearAll();
                        this.forceUpdate();
                      }, 600),
                    );
                  }}
                  style={{ width: '100%' }}
                  prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                />
              </div>

              <div className="flex-grow">
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      ref={(listNode) => {
                        this.listNode = listNode;
                      }}
                      width={width}
                      height={height}
                      rowCount={filterNodeList.length}
                      deferredMeasurementCache={this.cellMeasureCache}
                      rowHeight={this.cellMeasureCache.rowHeight}
                      rowRenderer={this.renderListItemInter(rootnode, filterNodeList)}
                    />
                  )}
                </AutoSizer>
              </div>
            </Radio.Group>
          </div>
          <div className="flex-grow">
            <AutoSizer>
              {({ width, height }) => (
                <div style={{ width, height }}>
                  <div
                    className="d3-tree-container"
                    style={hasRelation ? {} : { display: 'none' }}
                    ref={(container) => {
                      this.container = container;
                    }}
                  />
                </div>
              )}
            </AutoSizer>

            {!hasRelation && (
              <div className="full-height flex-row flex-center-align flex-center-justify">
                <Empty description={intl.formatMessage(causalMessages.noDependencyRelationFuond)} />
              </div>
            )}
          </div>
        </Container>
      </Modal>
    );
  }
}

const ViewDependencyModal = injectIntl(ViewDependencyModalCore);
export default connect(
  (state) => {
    const { loadStatus, projects, projectDisplayMap } = state.app;
    const { credentials } = state.auth;
    return {
      loadStatus,
      projects,
      projectDisplayMap,
      credentials,
    };
  },
  { updateLastActionInfo },
)(ViewDependencyModal);
