/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Button, Spin, Popconfirm, message, Alert } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Container, Table, Column, AutoSizer, Modal } from '../../../lib/fui/react';
// import { Defaults } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import AddJiraModal from './AddJiraModal';

type Props = {
  // eslint-disable-next-line
  onClose: Function,
  refresh: Number,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  replace: Function,
  updateLastActionInfo: Function,
};

class ServiceJiraModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isDeletingMap: {},
      eventList: [],

      showAddJiraModal: false,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refresh !== nextProps.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials } = props;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('system/externalServlies/list'), {
      ...credentials,
      serviceProvider: 'AtlassianJira',
    })
      .then((data) => {
        const { success, message, extServiceAllInfo } = data || {};

        let eventList = [];
        if (success) {
          eventList = extServiceAllInfo || [];
          eventList = R.filter((item) => item.serviceProvider === 'AtlassianJira', eventList);
        }

        this.setState({ isLoading: false, errMsg: !success ? message : null, eventList });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, errMsg: String(err), eventList: [] });
      });
  }

  @autobind
  handleAddNewClick() {
    this.setState({ showAddJiraModal: true });
  }

  @autobind
  handleExtsvcRemove(rowData) {
    const { intl, credentials } = this.props;
    const { isDeletingMap } = this.state;
    const { id, userName } = rowData;
    const key = `${id}-${userName}`;

    this.setState({ isDeletingMap: { ...isDeletingMap, [key]: true } });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('service-integration'), {
      ...credentials,
      serviceProvider: 'AtlassianJira',
      operation: 'delete',
      // eslint-disable-next-line
      service_id: id,
      serviceOwner: userName,
      customerName: userName,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isDeletingMap: { ...isDeletingMap, [key]: false } });
          this.reloadData(this.props);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isDeletingMap: { ...isDeletingMap, [key]: false }, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isDeletingMap: { ...isDeletingMap, [key]: false }, errMsg: String(err) });
      });
  }

  @autobind
  removeRenderer({ rowData }) {
    const { intl } = this.props;
    const { isDeletingMap } = this.state;
    const { id, userName } = rowData;
    const key = `${id}-${userName}`;

    return (
      <div className="flex-row">
        <Popconfirm
          placement="topRight"
          title={intl.formatMessage(appMessages.continueConfirm)}
          onConfirm={() => this.handleExtsvcRemove(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button
            size="small"
            className="button-color-grey"
            loading={get(isDeletingMap, key, false)}
            onClick={(event) => event.stopPropagation()}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </Popconfirm>
      </div>
    );
  }

  render() {
    const { onClose, userInfo, intl } = this.props;
    const { isLoading, errMsg, eventList } = this.state;
    return (
      <Modal width={1024} title="Jira" visible maskClosable={false} onCancel={() => onClose()} footer={null}>
        <Container className="flex-col" style={{ height: 500 }}>
          <div style={{ marginBottom: 12 }}>
            <Button size="small" type="primary" onClick={this.handleAddNewClick}>
              Add New
            </Button>
          </div>
          <div className="flex-grow flex-min-height">
            <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
              {errMsg && <Alert message={errMsg} type="error" showIcon />}
              {!errMsg && (
                <AutoSizer>
                  {({ height, width }) => (
                    <Table
                      className="with-border"
                      width={width}
                      height={height}
                      headerHeight={40}
                      rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                      rowHeight={40}
                      rowCount={eventList.length}
                      rowGetter={({ index }) => eventList[index]}
                    >
                      <Column width={100} label={intl.formatMessage(eventMessages.userName)} dataKey="userName" />
                      <Column
                        width={150}
                        label={intl.formatMessage(eventMessages.serviceHost)}
                        dataKey="service_host"
                        flexGrow={1}
                      />
                      <Column
                        width={150}
                        label={intl.formatMessage(eventMessages.account)}
                        dataKey="account"
                        flexGrow={1}
                      />
                      {/* <Column width={150} label="API token" dataKey="password" flexGrow={1} /> */}
                      <Column
                        width={110}
                        label=""
                        dataKey="id"
                        className="text-right"
                        cellRenderer={this.removeRenderer}
                      />
                    </Table>
                  )}
                </AutoSizer>
              )}
            </Spin>
          </div>

          {this.state.showAddJiraModal && (
            <AddJiraModal
              onClose={(reload) =>
                this.setState({ showAddJiraModal: false }, () => {
                  if (reload) {
                    this.reloadData(this.props);
                  }
                })
              }
            />
          )}
        </Container>
      </Modal>
    );
  }
}

const ServiceJiraModal = injectIntl(ServiceJiraModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return { location, systemsMap, userInfo, credentials };
  },
  { replace, updateLastActionInfo },
)(ServiceJiraModal);
