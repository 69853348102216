/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';
import * as R from 'ramda';

import type { Deps } from '../../types';
import { postProjectAgentList } from '../../apis';
import { apiEpicErrorHandle } from '../../errors';
import { getLoaderEpicAction } from '../../utils';
import { showAppAlert, updateLastActionInfo } from '../../app/actions';
import { setProjectSettings } from '../actions';
import { settingsMessages } from '../messages';

const saveProjectAgentListEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('SAVE_PROJECT_AGENTLIST').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);
    const prevProjectSettings = state.settings.projectSettings || {};

    const apiAction$ = Observable.from(postProjectAgentList(credentials, params))
      .concatMap(() => {
        return Observable.concat(
          Observable.of(
            setProjectSettings({
              // The agentList includes the whole list, so we don't need merge the changes.
              projectSettings: { ...prevProjectSettings, agentList: params.agentList },
            }),
          ),
          Observable.of(showAppAlert('info', settingsMessages.infoProjectSettingSaved)),
          hideLoader,
        );
      })
      .catch((err) => {
        Observable.concat(apiEpicErrorHandle(err), hideLoader);
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default saveProjectAgentListEpic;
