/* @flow */
import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  HomeOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  InfoCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { Select, Spin, Button, notification, Pagination, message, Breadcrumb } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { buildUrl, parseLocation, buildLocation, LogRenderers, EventRenderers } from '../../../common/utils';
import { Modal, Container, AutoSizer, List, CellMeasurerCache, CellMeasurer, Popover } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo, ActionTypes as AppActionTypes } from '../../../common/app/actions';

import { appMenusMessages, appFieldsMessages, appButtonsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

type Props = {
  refresh: Number,
  handleRefresh: Function,
  handleCustomerNameChange: Function,
  // eslint-disable-next-line
  handleEnvironmentChange: Function,
  handleSystemIdChange: Function,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: String,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  globalInfo: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  currentTheme: String,
};

class GlobalSystemIgnoredIncidentViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

    this.state = {
      isLoaded: false,
      isLoading: false,
      eventList: [],

      page: 1,
      pageSize: 20,
      componentFilter: undefined,
      instanceFilter: undefined,
      patternIdFilter: undefined,
    };
    this.localEventList = [];

    this.projectList = [];
    this.componentListOptions = [];
    this.instanceListOptions = [];
    this.patternListOptions = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { reloadSystem } = this.state;
    const { location } = this.props;
    const prevQuery = parseLocation(prevProps.location);
    const query = parseLocation(location);
    if (
      prevProps.refresh !== this.props.refresh ||
      prevState.reloadSystem !== reloadSystem ||
      prevQuery.environmentId !== query.environmentId ||
      prevQuery.customerName !== query.customerName ||
      prevQuery.systemId !== query.systemId
    ) {
      this.reloadData(this.props);
    } else if (prevQuery.projectName !== query.projectName) {
      this.reloadRuleData(this.props);
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData(props) {
    const { replace, createLoadAction, location, isAdmin, globalInfo } = props;
    const query = parseLocation(location);
    const { startTime, endTime, environmentId, customerName, systemId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId && systemInfo) {
      this.setState({ isLoading: true, isLoaded: false, page: 1 }, () => {
        if (!systemInfo.hasAllInstanceInfo) {
          createLoadAction(
            AppActionTypes.LOAD_INFO_SYSTEM,
            {
              startTime,
              endTime,
              environmentId,
              systemName: systemId,
              anomalyInstanceOnly: false,
            },
            false,
            true,
            this.callbackHandleSystemUpdate,
          );
        } else {
          const projectNameSet = get(systemInfo, ['projectNameSet'], []);
          this.projectList = projectNameSet;
          this.projectList = R.sortWith(
            [
              R.ascend(R.compose(R.toLower, R.prop('projectDisplayName'))),
              R.ascend(R.compose(R.toLower, R.prop('projectNameReal'))),
            ],
            this.projectList,
          );
          this.projectList = [
            { projectNameReal: 'all', projectDisplayName: 'All', userName: systemInfo?.ownerUserName || customerName },
            ...this.projectList,
          ];

          const projectNames = R.map((item) => item.projectNameReal, this.projectList);
          let { projectName } = query;
          let needReplace = false;
          if (!projectName || !projectNames.includes(projectName)) {
            projectName = projectNames.length > 0 ? projectNames[0] : 'all';
            needReplace = true;
          }

          if (needReplace) {
            this.setState({ isLoading: false });
            replace(buildLocation(location.pathname, {}, { ...query, projectName }));
          } else {
            this.reloadRuleData(props);
          }
        }
      });
    }
  }

  @autobind
  callbackHandleSystemUpdate() {
    // reset reloadSystem
    this.setState({ reloadSystem: moment.utc().valueOf() });
  }

  @autobind
  reloadRuleData(props, flag) {
    const { intl, location, credentials, globalInfo } = props;
    const { projectName, systemId, customerName, environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    if (projectName) {
      this.setState({
        isLoading: true,
        ...(flag ? {} : { componentFilter: undefined, instanceFilter: undefined, patternIdFilter: undefined }),
      });
      props.updateLastActionInfo();
      fetchGet(getEndpoint('ignoredDetectedIncident'), {
        ...credentials,
        ...(projectName === 'all'
          ? { systemName: systemId, customerName: systemInfo?.ownerUserName || customerName }
          : { projectName }),
      })
        .then((data) => {
          const { sortBy, sortDirection, page, pageSize } = this.state;

          let eventList = data || [];

          let allComponents = [];
          let allInstances = [];
          let patternIds = [];
          eventList = R.map((item) => {
            const { instanceName, nid } = item;
            const componentName = instanceName;
            allComponents.push(componentName);
            allInstances.push(instanceName);
            patternIds.push(nid);
            return {
              ...item,
              componentName,
              isIgnored: true,
            };
          }, eventList);

          allComponents = R.sort(
            (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
            R.filter((item) => Boolean(item), R.uniq(allComponents)),
          );
          this.componentListOptions = R.map((item) => ({ value: item, label: item }), allComponents);
          allInstances = R.sort(
            (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
            R.filter((item) => Boolean(item), R.uniq(allInstances)),
          );
          this.instanceListOptions = R.map((item) => ({ value: item, label: item }), allInstances);
          patternIds = R.sort(
            (a, b) => a - b,
            R.filter((item) => Boolean(item), R.uniq(patternIds)),
          );
          this.patternListOptions = R.map((patternId) => ({ value: patternId, label: patternId }), patternIds);

          let localEventList = this.filterData(eventList, this.state);
          localEventList = R.slice((page - 1) * pageSize, page * pageSize, localEventList);
          localEventList = this.sortData(localEventList, sortBy, sortDirection);

          this.localEventList = localEventList;
          this.setState({
            isLoaded: true,
            isLoading: false,
            eventList,
          });
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          this.localEventList = [];
          this.setState({
            isLoaded: true,
            isLoading: false,
            eventList: [],
          });
        });
    } else {
      this.localEventList = [];
      this.setState({
        isLoaded: true,
        isLoading: false,
        eventList: [],
      });
    }
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('instanceName')), R.ascend(R.prop('nid'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  filterData(eventList, state) {
    const { componentFilter, instanceFilter, patternIdFilter } = state;
    let filterList = eventList || [];

    if (componentFilter) {
      filterList = R.filter((event) => event.componentName === componentFilter, filterList);
    }
    if (instanceFilter) {
      filterList = R.filter((event) => event.instanceName === instanceFilter, filterList);
    }
    if (patternIdFilter) {
      filterList = R.filter((event) => event.nid === patternIdFilter, filterList);
    }

    return filterList;
  }

  @autobind
  onChangeProjectName(projectName) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    this.setState({ page: 1 }, () => {
      replace(buildLocation(location.pathname, {}, { ...query, projectName }));
    });
  }

  @autobind
  onChangeFilterComponent(componentFilter) {
    this.setState({ componentFilter, page: 1 }, () => {
      const { eventList, sortBy, sortDirection, page, pageSize } = this.state;
      let localEventList = this.filterData(eventList, this.state);
      localEventList = R.slice((page - 1) * pageSize, page * pageSize, localEventList);
      localEventList = this.sortData(localEventList, sortBy, sortDirection);

      this.localEventList = localEventList;
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  onChangeFilterInstance(instanceFilter) {
    this.setState({ instanceFilter, page: 1 }, () => {
      const { eventList, sortBy, sortDirection, page, pageSize } = this.state;
      let localEventList = this.filterData(eventList, this.state);
      localEventList = R.slice((page - 1) * pageSize, page * pageSize, localEventList);
      localEventList = this.sortData(localEventList, sortBy, sortDirection);

      this.localEventList = localEventList;
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  onChangeFilterPatternId(patternIdFilter) {
    this.setState({ patternIdFilter, page: 1 }, () => {
      const { eventList, sortBy, sortDirection, page, pageSize } = this.state;
      let localEventList = this.filterData(eventList, this.state);
      localEventList = R.slice((page - 1) * pageSize, page * pageSize, localEventList);
      localEventList = this.sortData(localEventList, sortBy, sortDirection);

      this.localEventList = localEventList;
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  renderListItem(events, { isMetric }) {
    return ({ key, index: rowIndex, style, parent }) => {
      const rowData = events[rowIndex];
      if (!rowData) return null;

      const { projectName, componentName, nid } = rowData;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
            <div className="row-column" style={{ width: 120, flex: 1 }}>
              {this.renderPattern(projectName)}
            </div>
            {!isMetric && (
              <div className="row-column" style={{ width: 120, flex: 1 }}>
                {this.renderPattern(componentName)}
              </div>
            )}
            <div className="row-column" style={{ width: 120, flex: 1 }}>
              {this.renderPattern(nid)}
            </div>
            <div className="row-column" style={{ width: 120, flex: 2 }}>
              {this.renderDetails(rowData, { isMetric })}
            </div>
            <div className="row-column" style={{ width: 110 }}>
              {this.statusRender(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderPattern(patternNameStr) {
    return (
      <Popover content={patternNameStr} placement="right" mouseEnterDelay={0.3}>
        <div className="hidden-line-with-ellipsis inline-block max-width">{patternNameStr || '-'}</div>
      </Popover>
    );
  }

  @autobind
  renderDetails(rowData, { isMetric }) {
    const { currentTheme } = this.props;
    const { content } = rowData;

    if (!content) return 'N/A';

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(content);
    } catch (error) {
      // console.debug(error)
    }
    return (
      <Popover
        placement="right"
        content={
          <div
            className="overflow-y-auto"
            style={{
              maxWidth: 450,
              maxHeight: 380,
              padding: 0,
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            }}
          >
            {isMetric && <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>{content}</div>}
            {!isMetric && (
              <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                {!rawDataJson &&
                  R.join(
                    '\n',
                    R.filter((x) => Boolean(x), (content || '').split('\n')),
                  )}
                {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
              </div>
            )}
          </div>
        }
        trigger={['hover', 'click']}
      >
        <div className="max-width flex-row flex-center-align">
          <div className="hidden-line-with-ellipsis">{content}</div>
          <InfoCircleOutlined className="primary-color" style={{ marginLeft: 2 }} />
        </div>
      </Popover>
    );
  }

  @autobind
  statusRender(rowData) {
    const { intl } = this.props;
    const { isIgnored } = rowData;

    return (
      <div className="flex-row">
        <Popover
          content={
            isIgnored
              ? intl.formatMessage(eventMessages.markAsUnignored)
              : intl.formatMessage(eventMessages.markAsIgnored)
          }
          mouseEnterDelay={0.3}
          placement="top"
        >
          {isIgnored ? (
            <EyeInvisibleOutlined
              style={{ fontSize: 14, color: 'red' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          ) : (
            <EyeOutlined
              style={{ fontSize: 14 }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          )}
        </Popover>
      </div>
    );
  }

  @autobind
  handleIgnoreClick({ event, category }) {
    const { intl } = this.props;
    const { isIgnored, isImportant, patternName, patternId } = event;
    const flag = (category === 'ignore' && isIgnored) || (category === 'important' && isImportant);
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: flag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          }),
      onOk: this.handleIgnoreSumbit(event, category),
    });
  }

  @autobind
  handleIgnoreSumbit(incident, category) {
    return () => {
      const { intl, credentials, userInfo } = this.props;
      const { isIgnored, isImportant } = incident;
      const { userName, instanceName, nid } = incident;
      let { projectName } = incident;
      projectName = userName !== userInfo.userName ? `${projectName}@${userName}` : projectName;

      if (this.ignoreModal) {
        this.ignoreModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      let content;
      if (category === 'ignore' && !isIgnored) {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, incident.rootCausesDetailsList || []);
        content = R.join('\n', summaryList);
      }

      let operation;
      switch (category) {
        case 'ignore':
          operation = isIgnored ? 'cancelIgnoreFlag' : 'setIgnoreFlag';
          break;
        case 'important':
          operation = isImportant ? 'cancelImportantFlag' : 'setImportantFlag';
          break;
        default:
          break;
      }

      this.props.updateLastActionInfo();
      return fetchPost(
        getEndpoint('events', 1),
        {
          ...credentials,
          projectName,
          instanceName,
          operation,
          nid,
          type: 'incident',
          content,
        },
        {},
        false,
      )
        .then((data) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.ignoreModal) this.ignoreModal.destroy();

          this.reloadRuleData(this.props, true);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.ignoreModal) {
            this.ignoreModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) sortDir = 'ASC';
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          let localEventList = this.filterData(this.localEventList, this.state);
          localEventList = this.sortData(localEventList, name, sortDir);
          this.localEventList = localEventList;
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  handlePaginationChange(page, pageSize) {
    this.setState({ page, pageSize }, () => {
      const { eventList, sortBy, sortDirection } = this.state;
      let localEventList = this.filterData(eventList, this.state);
      localEventList = R.slice((page - 1) * pageSize, page * pageSize, localEventList);
      localEventList = this.sortData(localEventList, sortBy, sortDirection);
      this.localEventList = localEventList;

      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  render() {
    const { intl, push, location, projects, userList, userInfo, globalInfo } = this.props;
    const { isLoaded, isLoading, page, pageSize, componentFilter, patternIdFilter, sortBy, sortDirection, eventList } =
      this.state;
    const query = parseLocation(location);
    const { customerName, environmentId, systemId, projectName } = query;

    // get system list
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const project = R.find((project) => project.projectName === projectName, projects || []);
    const { isMetric } = project || {};

    const events = this.localEventList;
    return (
      <Container
        fullHeight
        withGutter
        className={`global-view flex-col flex-min-height flex-min-width ${isLoaded ? ' loaded' : ''}`}
      >
        <Container breadcrumb className="flex-row">
          <div className="flex-grow">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, {}))}>
                  <HomeOutlined />
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalSystemRootCause, {}, { ...query }))}>
                  {intl.formatMessage(appMenusMessages.globalSystemRootCause)}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(appMenusMessages.globalSystemIgnoredIncident)}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="flex-row">
            <Container className="flex-row" style={{ alignItems: 'center' }}>
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.system)}</span>
              <Select
                showSearch
                size="small"
                value={systemId}
                style={{ width: 140 }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  this.setState({ page: 1 }, () => {
                    this.props.handleSystemIdChange(value);
                  });
                }}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ),
                  systemList || [],
                )}
              </Select>

              {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.environment)}
              </span>
              <Select
                size="small"
                value={environmentId}
                style={{ width: 140 }}
                onChange={this.props.handleEnvironmentChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.name} value={item.name} title={item.name}>
                      {item.id}
                    </Select.Option>
                  ),
                  globalInfo || [],
                )}
              </Select> */}
              {userInfo.isAdmin && (
                <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
              )}
              {userInfo.isAdmin && (
                <Select
                  showSearch
                  size="small"
                  value={customerName}
                  style={{ width: 100 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleCustomerNameChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              )}

              <Button size="small" onClick={() => this.props.handleRefresh()} style={{ marginLeft: 8 }}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </Container>
          </div>
        </Container>

        <Container
          className="flex-grow flex-col flex-min-height corner-10"
          style={{ margin: '0 16px 8px 16px', background: 'white' }}
        >
          <div className="flex-grow flex-col flex-min-height">
            <div className="flex-row flex-center-align" style={{ padding: '8px 8px 0px 8px' }}>
              <div className="light-label bold">{intl.formatMessage(eventMessages.rootCauseProject)}:</div>
              <Select
                showSearch
                size="small"
                style={{ width: 140, marginLeft: 8, marginRight: 16 }}
                value={projectName}
                optionFilterProp="value"
                onChange={this.onChangeProjectName}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.addIndex(R.map)((item, index) => {
                  return (
                    <Select.Option key={item.projectNameReal} value={item.projectNameReal}>
                      {item.projectDisplayName || item.projectNameReal}
                    </Select.Option>
                  );
                }, this.projectList || [])}
              </Select>
              {!isMetric && (
                <Select
                  allowClear
                  showArrow={false}
                  showSearch
                  size="small"
                  style={{ width: 140, marginRight: 16 }}
                  placeholder={intl.formatMessage(appFieldsMessages.component)}
                  value={componentFilter}
                  optionFilterProp="value"
                  filterOption
                  onChange={this.onChangeFilterComponent}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Select.Option key={item.value} label={item.label}>
                        {item.label}
                      </Select.Option>
                    );
                  }, this.componentListOptions)}
                </Select>
              )}
              <Select
                allowClear
                showArrow={false}
                showSearch
                size="small"
                style={{ width: 140, marginRight: 16 }}
                placeholder={intl.formatMessage(appFieldsMessages.pattern)}
                value={patternIdFilter}
                optionFilterProp="value"
                filterOption
                onChange={this.onChangeFilterPatternId}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.addIndex(R.map)((item, index) => {
                  return (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                }, this.patternListOptions)}
              </Select>
              <div className="flex-grow flex-row flex-center-align" />
              <Pagination
                size="small"
                current={page}
                total={eventList.length}
                pageSize={pageSize}
                onChange={this.handlePaginationChange}
                showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                showSizeChanger
                pageSizeOptions={['10', '20', '40', '60', '100', '500', '1000']}
                onShowSizeChange={this.handlePaginationSizeChange}
              />
            </div>
            <div className="flex-grow flex-min-width flex-min-height flex-row" style={{ padding: 8 }}>
              <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
                <AutoSizer>
                  {({ width, height }) => (
                    <div className="event-list">
                      <div
                        className="event-list-header"
                        style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                      >
                        <div
                          className="header-column"
                          style={{ width: 120, flex: 1 }}
                          onClick={this.headerClick('projectName')}
                        >
                          <span>{intl.formatMessage(eventMessages.projectName)}</span>
                          {this.sortIcon(sortBy, sortDirection, 'projectName')}
                        </div>
                        {!isMetric && (
                          <div
                            className="header-column"
                            style={{ width: 120, flex: 1 }}
                            onClick={this.headerClick('componentName')}
                          >
                            <span>{intl.formatMessage(eventMessages.componentName)}</span>
                            {this.sortIcon(sortBy, sortDirection, 'componentName')}
                          </div>
                        )}
                        <div
                          className="header-column"
                          style={{ width: 120, flex: 1 }}
                          onClick={this.headerClick('nid')}
                        >
                          <span>{intl.formatMessage(eventMessages.patternId)}</span>
                          {this.sortIcon(sortBy, sortDirection, 'nid')}
                        </div>
                        <div className="header-column" style={{ width: 120, flex: 2 }}>
                          {intl.formatMessage(eventMessages.shortDescription)}
                        </div>
                        <div className="header-column" style={{ width: 110 }} />
                      </div>
                      <List
                        className="event-list-grid"
                        ref={(listNode) => {
                          this.listNode = listNode;
                        }}
                        width={width}
                        height={height - 40}
                        rowCount={events.length}
                        overscanRowCount={4}
                        deferredMeasurementCache={this.cellMeasureCache}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowRenderer={this.renderListItem(events, { isMetric })}
                        onScrollbarPresenceChange={({ horizontal, vertical }) => {
                          if (vertical) {
                            this.listNodeHeaderScrollbar = true;
                          } else {
                            this.listNodeHeaderScrollbar = false;
                          }
                          this.forceUpdate();
                        }}
                      />
                    </div>
                  )}
                </AutoSizer>
              </Spin>
            </div>
          </div>
        </Container>
      </Container>
    );
  }
}

const GlobalSystemIgnoredIncidentView = injectIntl(GlobalSystemIgnoredIncidentViewCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, systemsMap, projects, projectDisplayMap, globalInfo, currentTheme } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { credentials, userInfo } = state.auth;
    return {
      location,
      loadStatus,
      currentLocale,
      userList,
      systemsMap,
      projects,
      projectDisplayMap,
      credentials,
      userInfo,

      globalInfo,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(GlobalSystemIgnoredIncidentView);
