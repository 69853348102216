import * as R from 'ramda';
import { createLogic } from 'redux-logic';

import { ActionTypes, setInitData, showAppModal, appError, updateLastActionInfo } from '../actions';
import { getLoadStatusActions } from '../../utils';
import { postCopyProject, getInitData } from '../../apis';
import { appMessages } from '../messages';

const saveCopyProjectLogic = createLogic({
  type: [ActionTypes.SAVE_COPY_PROJECT],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials, userInfo } = state.auth;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    postCopyProject(credentials, params)
      .then((result) => {
        const { success } = result;
        if (success) {
          // Load the init
          getInitData(credentials, userInfo)
            .then((data) => {
              dispatch(setInitData(data));
              if (data.licenseValid === false) {
                dispatch(showAppModal('warning', { message: appMessages.licenseValidFaild }));
              } else if (R.type(callback) === 'Function') {
                callback();
              }
            })
            .catch((err) => {
              dispatch(appError(appMessages.errorsServer, err));
            })
            .then(() => {
              dispatch(hideLoading);
              done();
            });
        }
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed POST copy project, ignored', err);
        dispatch(showAppModal('warning', { message: err.message }));
        dispatch(hideLoading);
        done();
      });
  },
});

export default saveCopyProjectLogic;
