/* @flow */
import type { Action } from '../types';

export const ActionTypes = {
  SET_METRIC_LINECHARTS_SUMMARY: 'SET_METRIC_LINECHARTS_SUMMARY',
  LOAD_METRIC_LINECHARTS: 'LOAD_METRIC_LINECHARTS',
  LOAD_METRIC_EVENT_LINECHARTS: 'LOAD_METRIC_EVENT_LINECHARTS',
  LOAD_METRIC_LINECHARTS_DETAILS: 'LOAD_METRIC_LINECHARTS_DETAILS',
  LOAD_METRIC_EVENT_LINECHARTS_BASELINE: 'LOAD_METRIC_EVENT_LINECHARTS_BASELINE',
  SET_METRIC_EVENT_LINECHARTS_BASELINE: 'SET_METRIC_EVENT_LINECHARTS_BASELINE',
  SET_METRIC_EVENT_LINECHARTS_BASELINE_PROGRESS: 'SET_METRIC_EVENT_LINECHARTS_BASELINE_PROGRESS',
  SET_METRIC_LINECHARTS_DETAILS: 'SET_METRIC_LINECHARTS_DETAILS',
  SET_METRIC_EVENT_LINECHARTS: 'SET_METRIC_EVENT_LINECHARTS',
  SET_METRIC_EVENT_LINECHARTS_PROGRESS: 'SET_METRIC_EVENT_LINECHARTS_PROGRESS',
  LOAD_METRIC_EVENT_LINECHARTS_INSTANCE_META_DATA: 'LOAD_METRIC_EVENT_LINECHARTS_INSTANCE_META_DATA',
  SET_METRIC_LINECHARTS: 'SET_METRIC_LINECHARTS',
  SET_METRIC_LINECHARTS_SELECTION: 'SET_METRIC_LINECHARTS_SELECTION',
  LOAD_EVENTLIST: 'LOAD_EVENTLIST',
  SET_EVENTLIST: 'SET_EVENTLIST',
  LOAD_ANOMALY_LOG_EVENTS: 'LOAD_ANOMALY_LOG_EVENTS',
  SET_ANOMALY_LOG_EVENTS: 'SET_ANOMALY_LOG_EVENTS',
  SAVE_EVENT_PUBLISH: 'SAVE_EVENT_PUBLISH',
  LOAD_METRIC_ANOMALY: 'LOAD_METRIC_ANOMALY',
  SET_METRIC_ANOMALY: 'SET_METRIC_ANOMALY',
  LOAD_METRIC_REAL_CAPACITY: 'LOAD_METRIC_REAL_CAPACITY',
  SET_METRIC_REAL_CAPACITY: 'SET_METRIC_REAL_CAPACITY',
  LOAD_METRIC_CAPACITY_PLANNING: 'LOAD_METRIC_CAPACITY_PLANNING',
  SET_METRIC_CAPACITY_PLANNING: 'SET_METRIC_CAPACITY_PLANNING',
  LOAD_EVENTS_HISTORY: 'LOAD_EVENTS_HISTORY',
  SET_EVENTS_HISTORY: 'SET_EVENTS_HISTORY',
  LOAD_JIRA_PROJECT_LISTS: 'LOAD_JIRA_PROJECT_LISTS',
  SET_JIRA_PROJECT_LISTS: 'SET_JIRA_PROJECT_LISTS',
  LOAD_JIRA_PROJECT_ASSIGNER_LISTS: 'LOAD_JIRA_PROJECT_ASSIGNER_LISTS',
  SET_JIRA_PROJECT_ASSIGNER_LISTS: 'SET_JIRA_PROJECT_ASSIGNER_LISTS',
  SAVE_JIRA_PROJECT_IUSSUE: 'SAVE_JIRA_PROJECT_IUSSUE',
  LOAD_EVENT_ALERT: 'LOAD_EVENT_ALERT',
  SET_EVENT_ALERT: 'SET_EVENT_ALERT',
  SAVE_EVENT_ALERT: 'SAVE_EVENT_ALERT',
  DELETE_EVENT_ALERT: 'DELETE_EVENT_ALERT',
  LOAD_EVENT_ACTION: 'LOAD_EVENT_ACTION',
  SET_EVENT_ACTION: 'SET_EVENT_ACTION',
  SAVE_EVENT_ACTION: 'SAVE_EVENT_ACTION',
  DELETE_EVENT_ACTION: 'DELETE_EVENT_ACTION',
};

export const updateMetricPatternFlag = (params: Object, loader: ?any): Action => ({
  type: 'UPDATE_METRIC_PATTERN_FLAG',
  payload: { params, loader },
});

export const setMetricPatternFlag = (params: Object): Action => ({
  type: 'SET_METRIC_PATTERN_FLAG',
  payload: { params },
});

export const setModalErrorMessage = (message: any = null, params: Object = {}): Action => ({
  type: 'SET_MODAL_ERROR_MESSAGE',
  payload: { message, params },
});

export const setErrorMessage = (isPrimary: Boolean = false, message: any = null, params: Object = {}): Action => ({
  type: 'SET_ERROR_MESSAGE',
  payload: { isPrimary, message, params },
});

export const loadMetricEventLogList = (params: Object, force: Boolean = false, loader: ?any): Action => ({
  type: 'LOAD_METRIC_EVENT_LOGLIST',
  payload: { params, force, loader },
});

export const setMetricEventLogList = (params: Object, data: any): Action => ({
  type: 'SET_METRIC_EVENT_LOGLIST',
  payload: { params, data },
});

export const loadMetricEventCausalResult = (params: Object, force: Boolean = false, loader: ?any): Action => ({
  type: 'LOAD_METRIC_EVENT_CAUSALRESULT',
  payload: { params, force, loader },
});

export const setMetricEventCausalResult = (params: Object, data: any): Action => ({
  type: 'SET_METRIC_EVENT_CAUSALRESULT',
  payload: { params, data },
});

/**
 * Set the metric event summary related data with specified view. This is handled by reducer.
 */
export const setMetricEventSummary = (view: String, params: Object, info: any): Action => ({
  type: 'SET_METRIC_EVENT_SUMMARY',
  payload: { view, params, info },
});

export const updateMetricEventPatternName = (
  projectName: String,
  params: Object,
  components: Object,
  callBack: Function,
): Action => ({
  type: 'UPDATE_METRIC_EVENT_PATTERN_NAME',
  payload: { projectName, params, components, callBack },
});

export const setMetricAppForecastDateWindow = (dateWindow: Array): Action => ({
  type: 'SET_METRIC_APP_FORECAST_DATE_WINDOW',
  payload: { dateWindow },
});

export const loadMetricAppForecastData = (params: Object, force: Boolean = false, loader: ?any): Action => ({
  type: 'LOAD_METRIC_APP_FORECAST_DATA',
  payload: { params, force, loader },
});

export const setMetricAppForecastData = (params: Object, data: Object): Action => ({
  type: 'SET_METRIC_APP_FORECAST_DATA',
  payload: { params, data },
});
