import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, isFinite, isNumber, floor } from 'lodash';
import { ClockCircleFilled, UnorderedListOutlined } from '@ant-design/icons';
import { Spin, Button, Tooltip, Progress } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { Defaults, buildUrl, CellRenderers, calcColorOfHealthScore, parseJSON } from '../../../common/utils';
import {
  InsightsSavingIcon,
  InsightsIncidentsIcon,
  PagerDutyIcon,
  ServiceNowIcon,
  AlertEventIcon,
} from '../../../lib/fui/icons';
import { AutoSizer } from '../../../lib/fui/react';

// import { appFieldsMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';

const InsightsStatistics = (props: Object) => {
  const {
    intl,
    updateLastActionInfo,
    credentials,
    userInfo,

    refresh,
    refreshTime,
    environmentId,
    systemId,
    customerName,
    startTime,
    globalInfo,
    isLoadingOverall,
    overallInfo,
    isLoadingAlertStats,
    alertCountMap,
    isLoadingHealthScore,
    avgHealthScore,
  } = props;
  const [isLoading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [laborCostSaving, setLaborCostSaving] = useState({
    laborCostSaving: 0,
  });
  const [alertInfo, setAlertStats] = useState({});
  const [incidentCountMap, setIncidentCountMap] = useState({});

  useEffect(() => {
    let cancel = false;

    // UNSAFE_componentWillReceiveProps
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      systemId &&
      systemInfo &&
      startTime
    ) {
      setLoading(true);
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      updateLastActionInfo();
      Promise.all([
        fetchGet(getEndpoint('insightsAlertCount'), {
          ...credentials,
          environmentName: environmentId,
          systemName: systemId,
          customerName: systemInfo.ownerUserName,
          dayTimeMillis: moment.utc(startTime, Defaults.DateFormat).valueOf(),
        }),
        fetchGet(getEndpoint('insightsIncidentStatsAlert'), {
          ...credentials,
          environmentName: environmentId,
          customerName: systemInfo.ownerUserName,
          systemName: systemId,
          dayTimeMillis: startTimestamp,
          includeIgnore: false,
        }),
      ])
        .then((results) => {
          if (cancel) return;

          // Labor cost saving
          const alertCostMap = get(results[0], ['overall', 'projectAlertCostMap']);
          let laborCostSaving = R.isEmpty(alertCostMap) ? undefined : 0;
          R.forEachObjIndexed((val) => {
            laborCostSaving += val;
          }, alertCostMap);
          setLaborCostSaving({
            laborCostSaving,
          });

          // alert info
          const alertStatistics = parseJSON(get(results[1], ['rollingAverage', 'totalAlertStatistics'])) || {};
          const { alertReductionRatio } = alertStatistics;
          setAlertStats({ alertReductionRatio });

          // incident info
          const incidentSourceMap = get(results[1], ['daily', 'incidentPatternStatistics', 'incidentSourceMap'], {});
          const incidentCountMap = { incidentCount: 0 };
          R.forEachObjIndexed((val, key) => {
            if (key === 'PagerDuty') {
              incidentCountMap.incidentCountPagerDuty = val;
            } else if (key === 'ServiceNow') {
              incidentCountMap.incidentCountServiceNow = val;
            } else {
              incidentCountMap.incidentCount += val;
            }
          }, incidentSourceMap);
          setIncidentCountMap(incidentCountMap);

          setLoading(false);
        })
        .catch((err) => {
          if (cancel) return;
          console.error(err);
          setLoading(false);
        });
    }

    return () => {
      cancel = true;
    };
  }, [refresh, refreshTime, environmentId, systemId, customerName]);

  const handleMatchedIncidentClick = () => {
    const params = {
      startTime: moment.utc(startTime, Defaults.DateFormat).subtract(1, 'months').format(Defaults.DateFormat),
      endTime: startTime,
      customerName,
      environmentId,
      systemId,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemPredictionMatched, {}, params), '_blank');
  };
  const incidentCount = get(incidentCountMap, 'incidentCount', 0);
  const incidentCountPagerDuty = get(incidentCountMap, 'incidentCountPagerDuty');
  const alertCountPagerDuty = get(alertCountMap, 'alertCountPagerDuty');
  const incidentCountServiceNow = get(incidentCountMap, 'incidentCountServiceNow');
  const { color: healthScoreColor } = calcColorOfHealthScore(isFinite(avgHealthScore) ? avgHealthScore : 100);

  return (
    <Spin
      spinning={isLoading || isLoadingOverall || isLoadingAlertStats || isLoadingHealthScore}
      wrapperClassName="full-width"
    >
      <div className="flex-row" style={{ height: 100, minHeight: 100, marginBottom: 16, overflowX: 'auto' }}>
        <div
          className="flex-col flex-center-align flex-center-justify"
          style={{
            marginRight: 16,
            background: 'white',
            borderRadius: 6,
            padding: '6px 8px 0 8px',
            width: 120,
            minWidth: 120,
          }}
        >
          <div className="bold light-label" style={{ marginBottom: 4 }}>
            {intl.formatMessage(DashboardMessages.healthScore)}
          </div>
          <div className="flex-grow flex-min-height flex-row flex-center-justify">
            <AutoSizer disableWidth>
              {({ height }) => (
                <Progress
                  type="dashboard"
                  style={{ width: height, height }}
                  width="100%"
                  strokeColor={healthScoreColor}
                  strokeWidth={10}
                  percent={Math.abs(isFinite(avgHealthScore) ? avgHealthScore : 0)}
                  format={(percent2) => {
                    return (
                      <span className="font-18 bold" style={{ color: healthScoreColor }}>
                        {isFinite(avgHealthScore) ? floor(avgHealthScore) : '--'}
                      </span>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
        </div>

        <div
          className="flex-grow flex-col flex-center-justify"
          style={{ marginRight: 16, background: 'white', borderRadius: 6, padding: '0 12px', minWidth: 200 }}
        >
          <div className="flex-row flex-center-align flex-min-width">
            <InsightsIncidentsIcon style={{ color: 'red', fontSize: 50, marginRight: 12 }} />
            <div className="full-width flex-row flex-center-align bold light-label" style={{ lineHeight: '18px' }}>
              {intl.formatMessage(DashboardMessages.incidentCount)}
            </div>
          </div>
          <div className="full-width flex-row flex-center-align font-14" style={{ paddingLeft: 62 }}>
            <span style={{ fontWeight: 'bold', color: 'red' }}>
              {isNumber(incidentCount) ? numeral(incidentCount).format('0,0') : 0}
            </span>
          </div>
        </div>

        {isNumber(incidentCountServiceNow) && (
          <div
            className="flex-grow flex-col flex-center-justify"
            style={{ marginRight: 16, background: 'white', borderRadius: 6, padding: '0 12px', minWidth: 200 }}
          >
            <div className="flex-row flex-center-align flex-min-width">
              <ServiceNowIcon style={{ fontSize: 50, marginRight: 12 }} />
              <div className="full-width flex-row flex-center-align bold light-label" style={{ lineHeight: '18px' }}>
                {intl.formatMessage(DashboardMessages.incidentCountServiceNow)}
              </div>
            </div>
            <div className="full-width flex-row flex-center-align font-14" style={{ paddingLeft: 62 }}>
              <span style={{ fontWeight: 'bold' }}>
                {isNumber(incidentCountServiceNow) ? numeral(incidentCountServiceNow).format('0,0') : 0}
              </span>
            </div>
          </div>
        )}

        {(isNumber(alertCountPagerDuty) || isNumber(incidentCountPagerDuty)) && (
          <div
            className="flex-grow flex-col flex-center-justify"
            style={{ marginRight: 16, background: 'white', borderRadius: 6, padding: '0 12px', minWidth: 200 }}
          >
            <div className="flex-row flex-center-align flex-min-width">
              <PagerDutyIcon style={{ fontSize: 50, marginRight: 12 }} />
              <div className="full-width flex-row flex-center-align bold light-label" style={{ lineHeight: '18px' }}>
                {intl.formatMessage(DashboardMessages.incidentCountPagerDuty)}
              </div>
            </div>
            <div className="full-width flex-row flex-center-align font-14">
              <span style={{ fontWeight: 'bold', marginRight: 4 }}>Incident:</span>
              {isNumber(incidentCountPagerDuty) ? numeral(incidentCountPagerDuty).format('0,0') : 0}
              <span style={{ fontWeight: 'bold', marginRight: 4, marginLeft: 12 }}>Alert:</span>
              {isNumber(alertCountPagerDuty) ? numeral(alertCountPagerDuty).format('0,0') : 0}
            </div>
          </div>
        )}

        <div
          className="flex-grow flex-col flex-center-justify"
          style={{ marginRight: 16, background: 'white', borderRadius: 6, padding: '0 12px', minWidth: 260 }}
        >
          <div className="flex-row flex-center-align flex-min-width">
            <AlertEventIcon style={{ color: 'orange', fontSize: 50, marginRight: 12 }} />
            <div className="full-width flex-row flex-center-align bold light-label" style={{ lineHeight: '18px' }}>
              {intl.formatMessage(DashboardMessages.insightsAlertsSuppressed)}
            </div>
          </div>
          <div className="full-width flex-row flex-center-align font-14" style={{ paddingLeft: 62 }}>
            <span style={{ fontWeight: 'bold' }}>
              {isNumber(alertInfo.alertReductionRatio)
                ? numeral(floor(alertInfo.alertReductionRatio, 2)).format('0%')
                : 0}
            </span>
          </div>
        </div>

        <div
          className="flex-grow flex-col flex-center-justify"
          style={{ marginRight: 16, background: 'white', borderRadius: 6, padding: '0 12px', minWidth: 200 }}
        >
          <div className="flex-row flex-center-align flex-min-width">
            <InsightsSavingIcon style={{ color: '#FE8961', fontSize: 50, marginRight: 12 }} />
            <div className="full-width flex-row flex-center-align bold light-label" style={{ lineHeight: '18px' }}>
              {intl.formatMessage(DashboardMessages.insightsStatisticsTotalMatched)}
            </div>
          </div>
          <div className="full-width flex-row flex-center-align font-14" style={{ paddingLeft: 62 }}>
            <div className="flex-grow flex-min-width" style={{ fontWeight: 'bold' }}>
              {isNumber(overallInfo.totalMatchOverallConsolidated)
                ? numeral(overallInfo.totalMatchOverallConsolidated).format('0,0')
                : '0'}
            </div>
            <Button size="small" icon={<UnorderedListOutlined />} onClick={() => handleMatchedIncidentClick()}>
              {intl.formatMessage(eventMessages.details)}
            </Button>
          </div>
        </div>

        <div
          className="flex-grow flex-col flex-center-justify"
          style={{ background: 'white', borderRadius: 6, padding: '0 12px', minWidth: 200 }}
        >
          <div className="flex-row flex-center-align flex-min-width">
            <ClockCircleFilled style={{ color: '#96A4C3', fontSize: 50, marginRight: 12 }} />
            <div className="full-width flex-row flex-center-align bold light-label" style={{ lineHeight: '18px' }}>
              {intl.formatMessage(DashboardMessages.insightsStatisticsAverageLeadTime)}
            </div>
          </div>
          <div className="full-width flex-row flex-center-align font-14" style={{ paddingLeft: 62 }}>
            <Tooltip
              placement="top"
              title={
                isNumber(overallInfo.avgLeadTimeOverall)
                  ? CellRenderers.humanizeDuration({ period: overallInfo.avgLeadTimeOverall, intl, showZero: true })
                  : '0'
              }
              mouseEnterDelay={0.3}
            >
              <div className="hidden-line-with-ellipsis" style={{ fontWeight: 'bold' }}>
                {isNumber(overallInfo.avgLeadTimeOverall)
                  ? CellRenderers.humanizeDuration({
                      period: overallInfo.avgLeadTimeOverall,
                      intl,
                      shortDisplay: true,
                      showZero: true,
                    })
                  : '0'}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default InsightsStatistics;
