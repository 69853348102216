import React from 'react';
import { autobind } from 'core-decorators';

import { Modal } from '../../../../artui/react';
import CausalPrediction from './CausalPrediction';

type Props = {
  onClose: Function,
  incident: Object,
  appNameMapping: Object,
};

class CausalPredictionModal extends React.Component {
  props: Props;

  @autobind
  handleClose() {
    this.props.onClose();
  }

  render() {
    const { incident, appNameMapping } = this.props;
    const width = 1020;
    const height = 560;

    return (
      <Modal size="big" closable onClose={this.handleClose}>
        <div style={{ padding: '16px 10px 8px' }}>
          <CausalPrediction width={width} height={height} incident={incident} appNameMapping={appNameMapping} />
        </div>
      </Modal>
    );
  }
}

export default CausalPredictionModal;
