/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, range } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { autobind } from 'core-decorators';
import {
  SettingOutlined,
  EyeInvisibleOutlined,
  DownOutlined,
  RightOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { Menu, Spin, Modal, Tabs, Select, message } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { createLoadAction, createSetAction, updateLastActionInfo } from '../../../common/app/actions';
import { BaseUrls } from '../../app/Constants';
import { Container, AutoSizer, List, CellMeasurerCache, CellMeasurer, Dropdown, Popover } from '../../../lib/fui/react';
import {
  Defaults,
  buildUrl,
  parseLocation,
  getReaderStream,
  downloadFile,
  sleep,
  BackgroundCall,
} from '../../../common/utils';

import LogEntryDayContent from '../../log/components/LogEntryDayContent';
import ClusterFeatureChart from '../../log/components/ClusterFeatureChart';
import { EChart } from '../../share';

// Control
import TriageReport from '../../dashboard/components/TriageReport';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import EventEmailAlertsModal from '../../metric/components/EventEmailAlertsModal';
import EventActionModal from '../../metric/components/EventActionModal';
import ReportJiraModal from '../../metric/components/ReportJiraModal';
import IgnoreModal from '../../metric/components/IgnoreModal';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { logMessages } from '../../../common/log/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';

import WordCloud from '../../log/components/WorkCloud';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

function isEdge() {
  const ua = navigator.userAgent.toLowerCase();
  let tem;
  let M = ua.match(/(edg(?=\/))\/?\s*(\d+)/i) || [];
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(Edge|edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.includes('edg') && M[1] < 114;
}

type Props = {
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  createSetAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  userName: String,
  // eslint-disable-next-line
  isAdmin: Boolean,
  isReadUser: Boolean,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  projectInstanceComponentMap: Object,

  width: Number,
  height: Number,
  queryParams: Object,
  queryResult: Object,
  // eslint-disable-next-line
  keywordsList: Object,
  // eslint-disable-next-line
  globalInfo: Object,
  currentTheme: String,
};

class AllLogPatternsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = 30;
    this.rowMinHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: this.rowMinHeight,
    });

    this.barClickType = 'details';

    this.clusterColor = '#678dff';
    this.featureColor = '#FEE090';
    this.dayDuration = 86400000;
    this.featureDuration = 86400000 || 10 * 60 * 1000;
    this.leftWidth = 320;
    this.leftGraphWidth = 440;
    this.featureLimit = 100;

    this.pieSize = 200;
    this.pieRight = '12%';
    this.topFeatureWord = [];
    this.topMappingInfo = [];
    this.keyEntry = {};
    this.keyMapEntry = {};

    const interval = 10 * 60 * 1000;

    this.state = {
      allPatternCount: 0,
      allPatternLogEntryCount: 0,
      events: [],
      filterList: [],
      eventList: [],
      isLoadingParserData: false,

      // filter
      componentName: undefined,

      // active incident
      incident: null,
      chartOption: null,
      activeTimestamp: null,
      interval,
      clusterActivePatternChange: undefined,
      clusterActiveTimeChange: null,

      activeKey: 'logEntries',

      // control
      activeIncident: null,
      showTakeLogActionModal: false,
      actionName: null,
      showEmailAlertsModal: false,
      showEventActionModal: false,
      showReportJiraModal: false,
    };
    this.componentInstanceMap = {};
    this.componentNameListOptions = [];
    this.mergeListMap = {};
    this.isEdge = isEdge();
    this.instanceDisplayNameMap = {};
  }

  async componentDidMount() {
    await this.getInstanceDisplayNameData();
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.parseData(nextProps);
    }
  }

  @autobind
  getInstanceDisplayNameData() {
    const { queryParams, projects, credentials } = this.props;
    const { projectName } = queryParams;
    const currentProject = R.find((project) => project.projectName === projectName, projects || {});

    return fetchGet(getEndpoint('instance-display-name'), {
      ...credentials,
      instanceDisplayNameRequestList: JSON.stringify([
        { projectName: currentProject?.projectShortName, customerName: currentProject?.owner },
      ]),
    })
      .then((d1) => {
        const instanceDisplayNameMap = {};
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, d1 || []);
        this.instanceDisplayNameMap = instanceDisplayNameMap;
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  }

  @autobind
  async parseData(props) {
    const {
      queryParams,
      queryResult,
      projectInstanceComponentMap,
      updateLastActionInfo,
      createSetAction,
      credentials,
    } = props;
    const { projectName } = queryParams;
    const { sortBy, sortDirection } = this.state;

    this.setState({ isLoadingParserData: true });
    await sleep(300);

    // parser duration
    const handleStartTime = moment.utc().valueOf();

    // get all components from instances
    const prevInstanceComponentMap = get(projectInstanceComponentMap, projectName, {});
    const instanceNameList = R.keys(queryResult || {});
    const { allInstanceComponentMap } = await BackgroundCall.GetSetComponent({
      updateLastActionInfo,
      createSetAction,
      credentials,
      prevInstanceComponentMap,
      projectName,
      instanceNameList,
    });
    const appNameList = !R.isEmpty(allInstanceComponentMap)
      ? { ...prevInstanceComponentMap, ...allInstanceComponentMap }
      : prevInstanceComponentMap;

    const componentInstanceMap = {};
    const componentCountMap = {};
    R.forEachObjIndexed((val, instance) => {
      const key = get(appNameList, instance, instance);

      if (!R.has(key, componentInstanceMap)) componentInstanceMap[key] = [];
      componentInstanceMap[key].push(instance);

      if (!R.has(key, componentCountMap)) componentCountMap[key] = { value: key, label: key, count: 0 };
      R.forEachObjIndexed((value) => {
        componentCountMap[key].count += value.count;
      }, val);
    }, queryResult);
    this.componentInstanceMap = componentInstanceMap;
    this.componentNameListOptions = R.sortWith([R.descend(R.prop('count'))], R.values(componentCountMap));

    // parser data
    let eventList = [];
    R.forEachObjIndexed((val, instanceName) => {
      let events = R.values(val);
      events = R.map((event) => {
        const { nid } = event;
        const componentName = get(appNameList, instanceName, instanceName);

        const id = `${instanceName}-${nid}`;
        const mergeKey = `${componentName}-${nid}`;
        return {
          ...event,

          id,
          isLog: true,
          patternId: nid,
          neuronId: nid,
          componentName,
          instanceName,

          mergeKey,
        };
      }, events);
      eventList = [...eventList, ...events];
    }, queryResult);

    // anomaly events filter
    const filterList = this.filterData(eventList);
    const sortList = this.sortData(filterList, sortBy, sortDirection);
    let mergedList = this.buildMergeData(sortList);
    // also sort merged list
    mergedList = this.sortData(mergedList, 'patternId', 'DESC');

    // get uniq pattern count and total log entry count
    let allPatterns = [];
    let allPatternLogEntryCount = 0;
    R.forEach((data) => {
      const { patternId, count } = data;
      allPatternLogEntryCount += count;
      allPatterns.push(patternId);
    }, mergedList);
    allPatterns = R.uniq(allPatterns);
    const allPatternCount = allPatterns.length;
    console.debug(`Parse data duration: ${(moment.utc().valueOf() - handleStartTime) / 1000} sec`);

    // default select one row
    let incident;
    if (sortList.length > 0) {
      incident = sortList[0];
    }
    const clusterTimeline = get(incident, ['clusterTimeline'], {});
    const chartOption = this.createChartOption({ clusterTimeline });
    const clusterList = R.sortBy(
      R.prop(0),
      R.map((item) => [Number(item[0]), item[1]], R.toPairs(clusterTimeline)),
    );
    const activeTimestamp = (R.find((item) => Boolean(item[1]), clusterList) || clusterList[0])[0];

    this.setState({
      isLoadingParserData: false,
      allPatternCount,
      allPatternLogEntryCount,
      events: eventList,
      filterList,
      eventList: mergedList,
      activeKey: 'logEntries',

      incident,
      chartOption,
      activeTimestamp,
      clusterActivePatternChange: moment.utc().valueOf(),
      clusterActiveTimeChange: moment.utc().valueOf(),
    });
  }

  @autobind
  filterData(eventList) {
    const { componentName } = this.state;
    let filterList = eventList || [];

    if (componentName) {
      filterList = R.filter((event) => componentName === event.componentName, filterList);
    }
    return filterList;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.descend(R.prop('hasIncident')), R.descend(R.prop('count'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  buildMergeData(eventList, defaultExpandAll) {
    let mergeList = [];

    // build merge event map and keys
    const mergeListKeys = [];
    let mergeListMap = {};
    R.forEach((event) => {
      const { mergeKey } = event;
      if (!R.has(mergeKey, mergeListMap)) {
        mergeListKeys.push(mergeKey);
        mergeListMap[mergeKey] = {
          currentEvent: { id: mergeKey, mergeKey, isRootEvent: true, isExpand: true },
          timelines: [],
        };
      }
      mergeListMap[mergeKey].timelines.push(event);
    }, eventList || []);
    mergeListMap = R.mapObjIndexed((val) => {
      let hasIncident = false;
      let isIgnored = false;
      let count = 0;
      R.forEach((item) => {
        hasIncident = hasIncident || item.hasIncident;
        isIgnored = isIgnored || item.isIgnored;
        count += item.count;
      }, val.timelines || []);
      const incident = val.timelines[0] || {};
      const { patternId, componentName, patternName } = incident;
      const newCurrentEvent = {
        ...val.currentEvent,
        timelinesCount: val.timelines.length,
        count,
        // sample event
        patternId,

        // flags
        hasIncident,
        isIgnored,

        componentName,
        patternName,
      };
      return { ...val, currentEvent: newCurrentEvent };
    }, mergeListMap);
    this.mergeListMap = mergeListMap;

    // rebuild eventlist
    R.forEach((mergeKey) => {
      if (defaultExpandAll) {
        mergeList.push({ ...this.mergeListMap[mergeKey].currentEvent, isExpand: true });
        mergeList = [...mergeList, ...this.mergeListMap[mergeKey].timelines];
      } else {
        mergeList.push(this.mergeListMap[mergeKey].currentEvent);
        // Because isexpand above is true by default (expanded), you need to add timelines
        // If you do not expand by default, change the expanded to false and delete this line
        mergeList = [...mergeList, ...this.mergeListMap[mergeKey].timelines];
      }
    }, mergeListKeys);

    return mergeList;
  }

  @autobind
  handleComponentNameChange(componentName) {
    this.setState({ componentName, isLoadingParserData: true }, () => {
      const { sortBy, sortDirection, events } = this.state;
      const filterList = this.filterData(events);
      const sortList = this.sortData(filterList, sortBy, sortDirection);
      let mergedList = this.buildMergeData(sortList);
      // also sort merged list
      mergedList = this.sortData(mergedList, sortBy, sortDirection);

      // get uniq pattern count and total log entry count
      let allPatterns = [];
      let allPatternLogEntryCount = 0;
      R.forEach((data) => {
        const { patternId, count } = data;
        allPatternLogEntryCount += count;
        allPatterns.push(patternId);
      }, mergedList);
      allPatterns = R.uniq(allPatterns);
      const allPatternCount = allPatterns.length;

      // default select one row
      let { incident } = this.state;
      if (sortList.length > 0) {
        incident = sortList[0];
      }
      const clusterTimeline = get(incident, ['clusterTimeline'], {});
      const chartOption = this.createChartOption({ clusterTimeline });
      const clusterList = R.sortBy(
        R.prop(0),
        R.map((item) => [Number(item[0]), item[1]], R.toPairs(clusterTimeline)),
      );
      const activeTimestamp = (R.find((item) => Boolean(item[1]), clusterList) || clusterList[0])[0];

      this.setState({
        isLoadingParserData: false,
        allPatternCount,
        allPatternLogEntryCount,
        filterList,
        eventList: mergedList,
        activeKey: 'logEntries',

        incident,
        chartOption,
        activeTimestamp,
        clusterActivePatternChange: moment.utc().valueOf(),
        clusterActiveTimeChange: moment.utc().valueOf(),
      });
    });
  }

  @autobind
  handleCategoryClick(rowIndex, rowData) {
    const { mergeKey, isExpand } = rowData;
    const { eventList } = this.state;

    let newEventList = eventList || [];
    newEventList[rowIndex].isExpand = !isExpand;
    if (newEventList[rowIndex].isExpand) {
      newEventList = [
        ...R.slice(0, rowIndex + 1, newEventList),
        ...this.mergeListMap[mergeKey].timelines,
        ...R.slice(rowIndex + 1, Infinity, newEventList),
      ];
    } else {
      newEventList = R.filter((e) => e.isRootEvent || e.mergeKey !== mergeKey, newEventList);
    }

    this.setState({ eventList: newEventList });
  }

  @autobind
  handleEventClick(incident) {
    const clusterTimeline = get(incident, ['clusterTimeline'], {});
    const clusterList = R.sortBy(
      R.prop(0),
      R.map((item) => [Number(item[0]), item[1]], R.toPairs(clusterTimeline)),
    );
    const timestamp = (R.find((item) => Boolean(item[1]), clusterList) || clusterList[0])[0];
    this.setState({
      activeKey: 'logEntries',

      incident,
      chartOption: this.createChartOption({ clusterTimeline }),
      activeTimestamp: timestamp,
      clusterActivePatternChange: moment.utc().valueOf(),
      clusterActiveTimeChange: moment.utc().valueOf(),
    });
  }

  @autobind
  renderListItem(eventList, incident) {
    return ({ key, index: rowIndex, style, parent }) => {
      const rowData = eventList[rowIndex];
      if (!rowData) return null;

      let content;
      if (rowData.isRootEvent) {
        const { patternId, patternName, componentName } = rowData;
        const { patternNameStr } = Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true });
        content = (
          <div
            className="event-list-category-row clickable odd-row"
            style={{ ...style, minHeight: 40 }}
            onClick={() => this.handleCategoryClick(rowIndex, rowData)}
          >
            {/* <div className="row-column" style={{ width: 30, padding: 0 }}>
              {this.rendererExpand(rowIndex, rowData)}
            </div> */}
            <div className="row-column" style={{ width: 80, flex: 1 }}>
              <Popover title={null} content={patternId === -2 ? 'MISC' : patternNameStr} mouseEnterDelay={0.3}>
                <div className="hidden-line-with-ellipsis inline-block max-width">
                  {patternId === -2 ? 'MISC' : patternNameStr}
                </div>
              </Popover>
            </div>
            <div className="row-column" style={{ maxWidth: 115, minWidth: 115 }}>
              <Popover title={null} content={componentName} mouseEnterDelay={0.3}>
                <div className="hidden-line-with-ellipsis inline-block max-width">{componentName}</div>
              </Popover>
            </div>
            <div className="row-column" style={{ width: 50 }}>
              {this.countRenderer(rowData)}
            </div>
            <div className="row-column" style={{ width: 46 }}>
              {this.statusRenderer(rowData)}
            </div>
          </div>
        );
      } else {
        const active = incident.id === rowData.id;
        content = (
          <div
            className={`event-list-row clickable ${active ? ' active' : ''}`}
            style={{ ...style, minHeight: 40 }}
            onClick={() => this.handleEventClick(rowData)}
          >
            {/* <div className="row-column" style={{ width: 30, padding: 0 }}>
              {this.rendererExpand(rowIndex, rowData)}
            </div> */}
            {/* <div className="row-column" style={{ width: 46 }}>
              {this.statusRenderer(rowData)}
            </div> */}
            <div className="row-column" style={{ width: 20, padding: 0 }} />
            <div className="row-column" style={{ width: 80, flex: 1, paddingLeft: 0 }}>
              {this.patternRenderer(rowData)}
            </div>
            <div className="row-column" style={{ width: 50 }}>
              {this.countRenderer(rowData)}
            </div>
            <div className="row-column" style={{ width: 46 }}>
              {this.controlRenderer(rowData)}
            </div>
          </div>
        );
      }

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          {content}
        </CellMeasurer>
      );
    };
  }

  @autobind
  rendererExpand(rowIndex, rowData) {
    const { isRootEvent, isExpand } = rowData;

    if (!isRootEvent) return <div className="full-width flex-row flex-center-align flex-center-justify">•</div>;
    return (
      <div className="full-width flex-row flex-center-align flex-center-justify" style={{ height: 40 }}>
        {isExpand ? <DownOutlined style={{ fontSize: 14 }} /> : <RightOutlined style={{ fontSize: 14 }} />}
      </div>
    );
  }

  @autobind
  statusRenderer(rowData) {
    const { intl } = this.props;
    const { hasIncident, isIgnored } = rowData;
    return (
      <div className="flex-row full-width">
        {hasIncident && (
          <Popover
            overlayClassName="small-popover"
            placement="top"
            title={null}
            content={intl.formatMessage(eventMessages.incident)}
          >
            <div
              style={{
                textAlign: 'center',
                background: 'red',
                color: 'white',
                padding: '0 4px',
              }}
            >
              I
            </div>
          </Popover>
        )}
        {isIgnored && (
          <Popover content={intl.formatMessage(eventMessages.ignored)} mouseEnterDelay={0.3} placement="top">
            <EyeInvisibleOutlined style={{ fontSize: 16, marginLeft: 4, color: 'red' }} />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  patternRenderer(rowData) {
    const { intl, queryParams, projects } = this.props;
    const { projectName } = queryParams;
    const currentProject = R.find((project) => project.projectName === projectName, projects || []);

    const { patternId, patternName, componentName, instanceName, topKeywordsString } = rowData;

    const { instanceStr } = getInstanceDisplayName(this.instanceDisplayNameMap, instanceName, {
      pn: currentProject?.projectShortName,
      owner: currentProject?.owner,
    });

    const words = R.map(
      (word) => {
        word = R.trim(word);
        const regex = /(?<=\()(.+?)(?=\))/g;
        const value = word.match(regex)[0];
        const text = word.replace(regex, '').replace(/\(\)/g, '');
        return { text, value };
      },
      topKeywordsString ? topKeywordsString.split(',') : [],
    );
    return (
      <Popover
        placement="right"
        mouseEnterDelay={0.3}
        content={
          <div style={{ maxWidth: 500 }}>
            <div>{`Pattern ID: ${patternId}`}</div>
            <div>{`Pattern Name: ${patternName}`}</div>
            <div>{`Component: ${componentName}`}</div>
            <div>{`Instance: ${instanceStr}`}</div>
            {topKeywordsString && (
              <>
                {!this.isEdge && (
                  <>
                    <div>{intl.formatMessage(logMessages.topKeywords)}:</div>
                    <WordCloud words={words} />
                  </>
                )}
                {this.isEdge && (
                  <div className="flex-row">
                    <div>{intl.formatMessage(logMessages.topKeywords)}:</div>
                    <div className="flex-grow" style={{ marginLeft: 10 }}>
                      {R.map(
                        (item) => (
                          <div key={item.value}>{`${item.text} (${item.value})`}</div>
                        ),
                        R.slice(0, 3, words),
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        }
      >
        <span className="hidden-line-with-ellipsis inline-block max-width">{instanceStr}</span>
      </Popover>
    );
  }

  @autobind
  countRenderer(rowData) {
    const { count } = rowData;
    return (
      <Popover placement="right" mouseEnterDelay={0.3} content={numeral(count).format('0,0')}>
        <span className="hidden-line-with-ellipsis inline-block max-width">{count}</span>
      </Popover>
    );
  }

  @autobind
  controlRenderer(rowData) {
    const { intl, isReadUser } = this.props;
    const { isIgnored } = rowData;

    const handleMenuClick = ({ key, domEvent }) => {
      domEvent.stopPropagation();

      switch (key) {
        case 'setPatternName':
          this.handleActionClick(rowData, 'setPatternName');
          break;
        case 'emailAlerts':
          this.handleEmailAlertsClick(rowData);
          break;
        case 'takeAction':
          this.handleEventActionClick(rowData);
          break;
        case 'reportJira':
          this.handleReportJiraClick(rowData);
          break;
        case 'export':
          this.handleExportClick(rowData);
          break;
        case 'ignore':
          this.handleIgnoreClick(rowData);
          break;
        default:
          break;
      }
    };
    return (
      <Dropdown
        itemClick={handleMenuClick}
        icon={<SettingOutlined />}
        onClick={(event) => event.stopPropagation()}
        disabled={isReadUser}
      >
        <>
          <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item>
          <Menu.Item key="ignore">
            {isIgnored ? intl.formatMessage(eventMessages.unignore) : intl.formatMessage(eventMessages.ignore)}
          </Menu.Item>
          <Menu.Item key="emailAlerts">{intl.formatMessage(eventMessages.emailAlerts)}</Menu.Item>
          <Menu.Item key="takeAction">{intl.formatMessage(eventMessages.takeAction)}</Menu.Item>
          <Menu.Item key="export">{intl.formatMessage(eventMessages.export)}</Menu.Item>
          {false && <Menu.Item key="reportJira">{intl.formatMessage(eventMessages.reportJira)}</Menu.Item>}
        </>
      </Dropdown>
    );
  }

  @autobind
  handleActionClick(incident, actionName) {
    this.setState({ activeIncident: incident, showTakeLogActionModal: true, actionName });
  }

  @autobind
  handleEmailAlertsClick(incident) {
    this.setState({ activeIncident: incident, showEmailAlertsModal: true });
  }

  @autobind
  handleEventActionClick(incident) {
    this.setState({ activeIncident: incident, showEventActionModal: true });
  }

  @autobind
  handleReportJiraClick(incident) {
    this.setState({ activeIncident: incident, showReportJiraModal: true });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    const { push } = this.props;
    this.setState({ showTakeLogActionModal: false }, () => {
      const params = parseLocation(this.props.location);
      push(
        buildUrl(
          BaseUrls.Query,
          {},
          {
            ...params,
            refreshTime: moment.utc().valueOf(),
          },
        ),
      );
    });
  }

  @autobind
  async handleExportClick(incident) {
    const { intl, queryParams, location } = this.props;
    const params = parseLocation(location);
    const { projectName } = params;
    const { startTimeObj, endTimeObj } = queryParams;
    const startTime = startTimeObj.valueOf();
    const endTime = endTimeObj.endOf('day').valueOf();
    const { instanceName, patternId } = incident;

    const apiParams = {
      projectName,
      instanceName,
      startTime,
      endTime,
      patternId,
    };

    // show waiting download modal
    const progressModal = Modal.info({
      title: null,
      footer: null,
      centered: true,
      // maskClosable: true,
      className: 'hide-confirm-btns',
      okButtonProps: { display: 'none' },
      content: (
        <div className="flex-row flex-center-align flex-center-justify">
          <Spin tip={intl.formatMessage(appMessages.waitingDownload)} />
        </div>
      ),
    });

    this.props.updateLastActionInfo();
    const data = await fetchGet(getEndpoint('logExportAllEvents'), apiParams, {}, '', false)
      .then((response) => {
        const reader = response.body.getReader();
        return getReaderStream(reader);
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .catch((err) => {
        console.debug(err);
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
    if (data) {
      downloadFile(data, `${projectName}-${instanceName}-${patternId}-${startTime}-${endTime}.json`, 'text/json');
    }
    progressModal.destroy();
  }

  @autobind
  handleIgnoreClick(incident) {
    this.setState({ activeIncident: incident, showIgnoreModal: true });
  }

  @autobind
  handleIgnoreConfirm(isConfirmed) {
    const { push } = this.props;
    this.setState({ showIgnoreModal: false }, () => {
      if (isConfirmed) {
        const params = parseLocation(this.props.location);
        push(buildUrl(BaseUrls.Query, {}, { ...params, refreshTime: moment.utc().valueOf() }));
      }
    });
  }

  @autobind
  createChartOption({ clusterTimeline, isFeature, anomalyFrequencyMap }) {
    const { intl, queryParams } = this.props;
    const { startTimeObj, endTimeObj } = queryParams;
    const startTs = startTimeObj.valueOf();
    const endTs = endTimeObj.endOf('day').valueOf();
    R.forEach((timestamp) => {
      if (!R.has(String(timestamp), clusterTimeline)) {
        clusterTimeline[String(timestamp)] = 0;
      }
    }, range(startTs, endTs, isFeature ? this.featureDuration : this.dayDuration));

    let datas = R.toPairs(clusterTimeline || []);
    datas = R.map((d) => [Number(d[0]), d[1]], datas);
    datas = R.sortBy(R.prop(0), datas);
    datas = R.map(
      (d) => ({
        name: moment.utc(d[0]).format(isFeature ? Defaults.TimeFormat : Defaults.DateFormat),
        timestamp: d[0],
        value: d[1],
        count: d[1],
      }),
      datas,
    );

    // add hot anomaly flag
    if (!isFeature && !R.isEmpty(anomalyFrequencyMap)) {
      R.forEachObjIndexed((frequency, timestamp) => {
        let isHot;
        if (frequency > 0) {
          isHot = true;
        }

        const dayStart = moment.utc(Number(timestamp)).startOf('day').valueOf();
        const itemInfo = R.find((item) => item.timestamp === dayStart, datas);
        if (itemInfo) {
          itemInfo.isHot = itemInfo.isHot || isHot;
        }
      }, anomalyFrequencyMap);
    }

    return {
      backgroundColor: 'transparent',
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          label: {
            show: true,
          },
        },
        formatter: (params, ticket, callback) => {
          const { data, color } = params[0] || {};
          const { timestamp, count, isHot } = data || {};

          return ReactDOMServer.renderToStaticMarkup(
            <div style={{ color: 'var(--text-color)' }}>
              {moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color }} />
              <span>
                {intl.formatMessage(appFieldsMessages.count)}: {numeral(count).format('0,0')}.
              </span>
              {isHot && <span style={{ marginLeft: 4 }}>Has hot anomalous.</span>}
            </div>,
          );
        },
      },
      title: {
        show: false,
      },
      legend: {
        show: false,
      },
      grid: {
        left: 70,
        right: '10%',
        top: 40,
        bottom: 60,
      },
      xAxis: {
        type: 'category',
        data: R.map((d) => d.name, datas),
        axisLabel: {
          textStyle: {
            color: 'gray',
          },
        },
      },
      yAxis: {
        name: 'Count',
        type: 'value',
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: {
          show: true,
          formatter: (value) => {
            if (value > 10000) value = numeral(value).format('0.00e+0');
            return value;
          },
          textStyle: {
            color: 'gray',
          },
        },
      },
      dataZoom: [
        {
          show: true,
          height: 20,
        },
        {
          type: 'inside',
        },
        {
          show: true,
          yAxisIndex: 0,
          filterMode: 'empty',
          width: 20,
          height: '70%',
          showDataShadow: false,
          left: '93%',
        },
      ],
      series: [
        {
          name: 'Count',
          data: R.map((item) => {
            const itemStyle = {};
            const { isHot } = item;
            if (isHot) {
              itemStyle.color = 'orange';
            }
            return { ...item, itemStyle };
          }, datas),
          type: 'bar',
          itemStyle: {
            color: isFeature ? this.featureColor : this.clusterColor,
          },
        },
      ],
    };
  }

  @autobind
  onChartClick(incident) {
    return (item) => {
      const { data } = item;
      const { timestamp } = data;

      this.setState({
        activeTimestamp: timestamp,
        clusterActiveTimeChange: moment.utc().valueOf(),
      });
    };
  }

  @autobind
  handleTabChange(activeKey) {
    this.setState({ activeKey });
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir, isLoadingParserData: true }, () => {
          const { filterList } = this.state;
          const sortList = this.sortData(filterList, name, sortDir);
          let mergedList = this.buildMergeData(sortList);
          // also sort merged list
          mergedList = this.sortData(mergedList, name, sortDir);
          this.setState({ eventList: mergedList, isLoadingParserData: false });
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const { intl, width, height, projects, queryParams, currentTheme } = this.props;
    const {
      componentName,
      allPatternCount,
      allPatternLogEntryCount,
      eventList,
      isLoadingParserData,

      incident,
      clusterActivePatternChange,
      chartOption,
      activeTimestamp,
      interval,
      clusterActiveTimeChange,
      activeKey,
    } = this.state;
    const { sortBy, sortDirection } = this.state;
    const {
      activeIncident,
      showTakeLogActionModal,
      actionName,
      showEmailAlertsModal,
      showEventActionModal,
      showReportJiraModal,
      showIgnoreModal,
    } = this.state;
    const { projectName, startTimeObj, endTimeObj, keyword } = queryParams;
    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects,
    );

    return (
      <Container className="content flex-col flex-min-height" style={{ height, width }}>
        <Container className="toolbar">
          <Container className="section">
            <span className="label bold" style={{ padding: '0 4px' }}>
              Unique Pattern Count: <span style={{ color: '#1890ff' }}>{allPatternCount}</span>
            </span>
            <span className="label bold" style={{ padding: '0 4px' }}>
              Total Log Entry Count:{' '}
              <span style={{ color: '#1890ff' }}>{numeral(allPatternLogEntryCount).format('0,0')}</span>
            </span>
          </Container>
          <Container className="section float-right">
            <Select
              allowClear
              showSearch
              size="small"
              style={{ width: 220 }}
              placeholder={intl.formatMessage(appFieldsMessages.component)}
              optionFilterProp="value"
              filterOption
              value={componentName}
              onChange={this.handleComponentNameChange}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {R.map(
                (item) => (
                  <Select.Option
                    key={item.value}
                    value={item.value}
                    title={`${item.label}  ${intl.formatMessage(logMessages.count)}: ${item.count}`}
                  >
                    {item.label}
                    <span style={{ margin: '0 4px 0 8px', fontWeight: 'bold' }}>
                      {intl.formatMessage(logMessages.count)}:
                    </span>
                    {item.count}
                  </Select.Option>
                ),
                this.componentNameListOptions,
              )}
            </Select>
          </Container>
        </Container>

        <Container className="flex-grow flex-min-height">
          <Spin spinning={isLoadingParserData} wrapperClassName="full-width full-height spin-full-width">
            <div className="full-width full-height flex-row">
              <Container className="full-height event-list" style={{ minWidth: this.leftWidth, marginRight: 16 }}>
                <AutoSizer>
                  {({ width, height }) => (
                    <>
                      <div
                        className="event-list-header"
                        style={{
                          width,
                          height: this.listHeaderHeight,
                          paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                        }}
                      >
                        {/* <div className="header-column" style={{ width: 30 }} /> */}
                        <div
                          className="header-column"
                          style={{ width: 80, flex: 1 }}
                          onClick={this.headerClick('patternId')}
                        >
                          <span>{intl.formatMessage(appFieldsMessages.pattern)}</span>
                          {this.sortIcon(sortBy, sortDirection, 'patternId')}
                        </div>
                        <div className="header-column" style={{ maxWidth: 115, minWidth: 115 }}>
                          {intl.formatMessage(eventActionMessages.componentName)}
                        </div>
                        <div className="header-column" style={{ width: 50 }} onClick={this.headerClick('count')}>
                          <span>{intl.formatMessage(appFieldsMessages.count)}</span>
                          {this.sortIcon(sortBy, sortDirection, 'count')}
                        </div>
                        <div className="header-column" style={{ width: 46 }} />
                        {/* <div className="header-column" style={{ width: 40 }} /> */}
                      </div>
                      <List
                        ref={(ref) => {
                          this.refList = ref;
                        }}
                        className="event-list-grid"
                        width={width}
                        height={height - this.listHeaderHeight}
                        rowCount={(eventList || []).length}
                        overscanRowCount={4}
                        deferredMeasurementCache={this.cellMeasureCache}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowRenderer={this.renderListItem(eventList, incident)}
                        onScrollbarPresenceChange={({ horizontal, vertical }) => {
                          if (vertical) {
                            this.listNodeHeaderScrollbar = true;
                          } else {
                            this.listNodeHeaderScrollbar = false;
                          }
                          this.forceUpdate();
                        }}
                      />
                    </>
                  )}
                </AutoSizer>
              </Container>
              <Container className="flex-grow flex-min-width flex-col">
                <Container style={{ height: 200 }}>
                  {chartOption && (
                    <EChart
                      height="100%"
                      option={chartOption}
                      onClick={this.onChartClick(incident)}
                      theme={currentTheme}
                    />
                  )}
                </Container>

                <Container className="flex-grow flex-min-height flex-row">
                  <Tabs
                    className="full-height full-width ant-tabs-content-full-height flex-col flex-min-height"
                    type="card"
                    activeKey={activeKey}
                    onChange={this.handleTabChange}
                  >
                    <Tabs.TabPane
                      tab={intl.formatMessage(logMessages.featureKeywordDistributionAll)}
                      key="featureKeyword"
                      style={{ paddingTop: 8 }}
                    >
                      {incident && (
                        <ClusterFeatureChart
                          activeKey={activeKey}
                          projectName={projectName}
                          instanceName={incident.instanceName}
                          startTimestamp={startTimeObj.valueOf()}
                          endTimestamp={endTimeObj.valueOf()}
                          clusterInfo={incident}
                          clusterActivePatternChange={clusterActivePatternChange}
                          // handleFeatureKeywordChange={this.handleFeatureKeywordChange}
                        />
                      )}
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={intl.formatMessage(DashboardMessages.logEntries)}
                      key="logEntries"
                      style={{ paddingTop: 8 }}
                    >
                      {incident && (
                        <LogEntryDayContent
                          activeKey={activeKey}
                          projectName={projectName}
                          instanceName={incident.instanceName}
                          incident={incident}
                          clusterActiveTimeChange={clusterActiveTimeChange}
                          startTimestamp={activeTimestamp}
                          endTimestamp={activeTimestamp ? activeTimestamp + interval : null}
                          keyword={keyword || ''}
                          instanceDisplayNameMap={this.instanceDisplayNameMap}
                        />
                      )}
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={intl.formatMessage(eventMessages.editTriageReport)}
                      key="editTriageReport"
                      style={{ paddingTop: 8 }}
                    >
                      {activeKey === 'editTriageReport' && incident && (
                        <TriageReport
                          incident={{
                            projectName: projectName.includes('@') ? projectName.split('@')[0] : projectName,
                            projectOwner: project.owner,
                            anomalyLogInstance: incident.instanceName,
                            patternId: incident.patternId,
                          }}
                          instanceDisplayNameMap={this.instanceDisplayNameMap}
                        />
                      )}
                    </Tabs.TabPane>
                  </Tabs>
                </Container>
              </Container>
            </div>
          </Spin>
        </Container>

        {/* Control Labeling */}
        {showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={actionName}
            incident={activeIncident}
            project={project}
            projectName={projectName}
            eventType={activeIncident.isPrediction}
            onClose={() => this.setState({ showTakeLogActionModal: false })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {showEmailAlertsModal && (
          <EventEmailAlertsModal
            incident={activeIncident}
            projectName={projectName}
            onClose={() => this.setState({ showEmailAlertsModal: false })}
          />
        )}
        {showEventActionModal && (
          <EventActionModal
            incident={activeIncident}
            project={project}
            projectName={projectName}
            onClose={() => this.setState({ showEventActionModal: false })}
          />
        )}
        {showReportJiraModal && (
          <ReportJiraModal
            incident={activeIncident}
            projectName={projectName}
            onClose={() => this.setState({ showReportJiraModal: false })}
          />
        )}
        {showIgnoreModal && (
          <IgnoreModal
            incident={this.state.activeIncident}
            projectName={projectName}
            onClose={(isConfirmed) => this.handleIgnoreConfirm(isConfirmed)}
          />
        )}
      </Container>
    );
  }
}

const AllLogPatterns = injectIntl(AllLogPatternsCore);
export default connect(
  (state) => {
    const { credentials } = state.auth;
    const { location } = state.router;
    const { loadStatus, projects, projectInstanceComponentMap, currentTheme } = state.app;
    const { isReadUser, isAdmin, userName } = state.auth.userInfo;
    const { keywordsList } = state.settings;
    return {
      location,
      loadStatus,
      credentials,
      isAdmin,
      isReadUser,
      userName,
      projects,
      projectInstanceComponentMap,
      keywordsList,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, createSetAction, updateLastActionInfo },
)(AllLogPatterns);
