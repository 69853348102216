import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Spin, message, Alert } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, parseJSON } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal, AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';
import { parseTimelinesList } from '../../../common/apis/magicParsers';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import LikelyRootCauses from './LikelyRootCauses';

type Props = {
  environmentId: String,
  systemId: String,
  // eslint-disable-next-line
  incident: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  globalInfo: Object,
  projectDisplayMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
};

class PastIncidentRCModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: this.rowMinHeight,
    });

    this.state = {
      isLoading: false,
      eventList: [],
      activeEvent: undefined,

      isLoadingRC: false,
      activeIncident: undefined,
      rootCausesData: undefined,
      errMsg: undefined,
    };
    this.listHeaderHeight = 30;
  }

  componentDidMount() {
    this.reloadData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData() {
    const { intl, incident, credentials } = this.props;
    this.setState({ isLoading: true });
    const { projectOwner, category, instanceName, patternId, metricList, incidentTimestamp, instanceDown } = incident;
    let { projectName } = incident;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;

    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('incidentOccurrence'), {
      ...credentials,
      operation: 'getOccurrenceSet',
      project: projectName,
      instanceName,
      isMetric: category === 'metric',
      patternId,
      metricName: instanceDown ? 'instanceDown' : metricList && metricList.length > 0 ? metricList[0] : undefined,
      timestamp: incidentTimestamp,
    })
      .then((data) => {
        let eventList = [];
        R.forEach((item) => {
          eventList.push({ timestamp: item });
        }, data || []);

        // sort
        eventList = R.sortWith([R.descend(R.prop('timestamp'))], eventList);
        const activeEvent = eventList.length > 0 ? eventList[0] : undefined;

        this.setState({ isLoading: false, eventList, activeEvent }, () => {
          this.reloadRC();
        });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, eventList: [], activeEvent: undefined });
      });
  }

  @autobind
  reloadRC() {
    const { intl, incident, credentials, systemId, environmentId, globalInfo, projectDisplayMap } = this.props;
    const { activeEvent } = this.state;

    if (activeEvent) {
      this.setState({ isLoadingRC: true });
      const { projectOwner, category, instanceName, patternId, metricList, instanceDown } = incident;
      let { projectName } = incident;
      projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
      const timestamp = get(activeEvent, 'timestamp');

      this.props.updateLastActionInfo();
      fetchGet(getEndpoint('incidentOccurrence'), {
        ...credentials,
        operation: 'getRCA',
        project: projectName,
        instanceName,
        isMetric: category === 'metric',
        patternId,
        metricName: instanceDown ? 'instanceDown' : metricList && metricList.length > 0 ? metricList[0] : undefined,
        timestampSet: JSON.stringify([timestamp]),
      })
        .then((data) => {
          const { success, message: errMsg } = data || {};
          if (success) {
            let rootCauses = get(data, timestamp);
            rootCauses = parseJSON(rootCauses);
            const { anomalyTimeLine, ...rootCausesData } = rootCauses || {};

            // parse timelines
            const environment = R.find((e) => e.id === environmentId, globalInfo);
            const systemList = environment ? environment.systemList : [];
            const systemInfo = R.find((system) => system.id === systemId, systemList);
            const projectNameSet = get(systemInfo, ['projectNameSet'], []);
            const timelines = parseTimelinesList([anomalyTimeLine], projectNameSet, projectDisplayMap, false);
            const activeIncident = timelines[0];

            this.setState({ isLoadingRC: false, rootCausesData, activeIncident, errMsg: undefined });
          } else {
            this.setState({ isLoadingRC: false, rootCausesData: undefined, activeIncident: undefined, errMsg });
          }
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          this.setState({
            isLoadingRC: false,
            rootCausesData: undefined,
            activeIncident: undefined,
            errMsg: err.message || String(err),
          });
        });
    }
  }

  @autobind
  handleEventClick(rowData) {
    this.setState({ activeEvent: rowData }, () => {
      this.reloadRC();
    });
  }

  @autobind
  renderListItem(eventList, activeEvent) {
    return ({ key, index: rowIndex, style, parent }) => {
      const rowData = eventList[rowIndex];
      if (!rowData) return null;

      const active = rowData.timestamp === activeEvent.timestamp;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div
            className={`event-list-row clickable ${active ? ' active' : ''}`}
            style={{ ...style, minHeight: 40 }}
            onClick={() => this.handleEventClick(rowData)}
          >
            <div className="row-column" style={{ width: 80, flex: 1 }}>
              {this.timeRenderer(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  timeRenderer(rowData) {
    const { timestamp } = rowData;
    return moment.utc(timestamp).format(Defaults.DateTimeFormat);
  }

  render() {
    const { intl, onClose, environmentId, systemId } = this.props;
    const { isLoading, eventList, activeEvent } = this.state;
    const { isLoadingRC, rootCausesData, activeIncident, errMsg } = this.state;

    return (
      <Modal
        visible
        title={intl.formatMessage(eventMessages.historicalIncients)}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={1200}
        bodyStyle={{ height: 500, overflowY: 'auto' }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <div className="full-width full-height flex-row">
            <div className="event-list flex-col flex-min-height" style={{ width: 200, marginRight: 16 }}>
              <AutoSizer>
                {({ width, height }) => (
                  <>
                    <div
                      className="event-list-header"
                      style={{
                        width,
                        height: this.listHeaderHeight,
                        paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                      }}
                    >
                      <div className="header-column" style={{ width: 100, flex: 1 }}>
                        {intl.formatMessage(appFieldsMessages.time)}
                      </div>
                    </div>
                    <List
                      ref={(ref) => {
                        this.refList = ref;
                      }}
                      className="event-list-grid"
                      width={width}
                      height={height - this.listHeaderHeight}
                      rowCount={(eventList || []).length}
                      overscanRowCount={4}
                      deferredMeasurementCache={this.cellMeasureCache}
                      rowHeight={this.cellMeasureCache.rowHeight}
                      rowRenderer={this.renderListItem(eventList, activeEvent)}
                      onScrollbarPresenceChange={({ horizontal, vertical }) => {
                        if (vertical) {
                          this.listNodeHeaderScrollbar = true;
                        } else {
                          this.listNodeHeaderScrollbar = false;
                        }
                        this.forceUpdate();
                      }}
                    />
                  </>
                )}
              </AutoSizer>
            </div>
            <div className="flex-grow flex-min-width">
              {errMsg && <Alert message={errMsg} type="error" showIcon />}
              {!errMsg && (
                <LikelyRootCauses
                  environmentId={environmentId}
                  systemId={systemId}
                  isLoading={isLoadingRC}
                  incident={activeIncident}
                  rootCausesData={rootCausesData}
                  onReload={this.reloadRC}
                />
              )}
            </div>
          </div>
        </Spin>
      </Modal>
    );
  }
}

const PastIncidentRCModal = injectIntl(PastIncidentRCModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, globalInfo, projectDisplayMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      globalInfo,
      projectDisplayMap,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(PastIncidentRCModal);
