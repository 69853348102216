import React, { Fragment, useEffect, useReducer } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import yaml from 'js-yaml';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AutoComplete, Button, Card, Checkbox, Form, Input, message, Select, Spin } from 'antd';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { State } from '../../../../common/types';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { PreviewIcon } from '../../../../lib/fui/icons';
import { CodeMirror, Modal, Popover } from '../../../../lib/fui/react';

import { settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { eventMessages } from '../../../../common/metric/messages';

const dataTypeCommon = { endpoint: undefined, authentication: false, username: undefined, password: undefined };
const dataTypesOptions = ['Metric', 'Log'];
const defaultMode = 'install';
const modeMap = {
  existing: 1,
  install: 2,
};
const modeReverseMap = {
  1: 'existing',
  2: 'install',
};
const dataTypeHideMap = {
  prometheus: 'embeddedprometheus',
  loki: 'embeddedloki',
};

function KubernetesEndpointCore({ activeSystemTab, systemId, ...props }: Object) {
  const { credentials, systemsMap, intl, currentTheme, isAdmin, isLocalAdmin, userInfo, userList } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isLoading: false,
    systemName: undefined,
    namespaces: [],
    namespacesOption: [],
    dataTypes: [],
    dataTypeSettings: [
      {
        type: 'Metric',
        name: 'prometheus',
        title: intl.formatMessage(settingsMessages.prometheus),
        hiddle: true,
        endpointUrl: 'http://prometheus-server.monitor.svc.cluster.local',
        ...dataTypeCommon,
      },
      {
        type: 'Log',
        name: 'loki',
        title: intl.formatMessage(settingsMessages.loki),
        hiddle: true,
        endpointUrl: 'http://loki-gateway.monitor.svc.cluster.local',
        ...dataTypeCommon,
      },
    ],
    installationMode: [
      {
        type: 'Metric',
        value: '',
        option: [
          {
            value: 'existing',
            label: intl.formatMessage(settingsMessages.installationModeExisting, { type: 'prometheus' }),
          },
          {
            value: 'install',
            label: intl.formatMessage(settingsMessages.installationModeInstall, { type: 'prometheus' }),
          },
        ],
      },
      {
        type: 'Log',
        value: '',
        option: [
          {
            value: 'existing',
            label: intl.formatMessage(settingsMessages.installationModeExisting, { type: 'loki' }),
          },
          { value: 'install', label: intl.formatMessage(settingsMessages.installationModeInstall, { type: 'loki' }) },
        ],
      },
    ],

    showPreviewCodeModal: false,
    previewCode: null,

    saveFlag: false,
    btnLoading: false,
  });
  const { isLoading, systemName, namespaces, dataTypes, dataTypeSettings, installationMode } = state;
  const { showPreviewCodeModal, previewCode, btnLoading, namespacesOption, saveFlag } = state;
  const isDark = currentTheme === 'dark';

  const parseData = (data) => {
    const newDataTypes = [];
    if (data.enableMetric) {
      newDataTypes.push('Metric');
    }
    if (data.enableLog) {
      newDataTypes.push('Log');
    }

    const newInstallationMode = R.map((item) => {
      if (item.type === 'Metric') {
        return { ...item, value: modeReverseMap[data.metricInstallMode || 0] };
      } else if (item.type === 'Log') {
        return { ...item, value: modeReverseMap[data.logInstallMode || 0] };
      }
      return item;
    }, installationMode || []);

    const newDataTypeSettings = R.map((item) => {
      if (item.type === 'Metric') {
        return {
          ...item,
          endpoint: data.prometheusEndpoint || undefined,
          authentication: data.enablePrometheusAuth || false,
          username: data.prometheusAuthUserName || undefined,
          password: data.prometheusAuthPassword || undefined,
          hiddle: !data.enableMetric,
        };
      } else if (item.type === 'Log') {
        return {
          ...item,
          endpoint: data.lokiEndpoint || undefined,
          authentication: data.enableLokiAuth || false,
          username: data.lokiAuthUserName || undefined,
          password: data.lokiAuthPassword || undefined,
          hiddle: !data.enableLog,
        };
      }
      return item;
    }, dataTypeSettings || []);

    return { newDataTypes, newInstallationMode, newDataTypeSettings };
  };

  const verifyData = (
    systemNameParams,
    namespacesParams,
    dataTypesParams,
    dataTypeSettingsParams,
    installationModeParams,
  ) => {
    const namespacesError = namespacesParams.length === 0;
    const dataTypesError = dataTypesParams.length === 0;
    const dataTypeSettingError = !!R.find((item) => {
      const modeRequired =
        R.find((mode) => mode.type === item.type, installationModeParams || [])?.value === 'existing';
      return (
        !item.hiddle &&
        ((modeRequired && !item.endpoint) ||
          (item.authentication && modeRequired && (!item.username || !item.password)))
      );
    }, dataTypeSettingsParams);
    const hasError = !systemNameParams || namespacesError || dataTypesError || dataTypeSettingError;
    const showDataTypeCard = R.find((item) => item.value === 'existing', installationModeParams || []);
    return { hasError, dataTypesError, showDataTypeCard };
  };

  const reloadData = () => {
    const systemInfo = systemsMap[systemId] || {};

    setState({ isLoading: true });
    fetchGet(getEndpoint('k8s-integration-setting'), {
      ...credentials,
      customerName: systemInfo.owner,
      systemName: systemId,
      environmentName: systemInfo.environmentName || 'All',
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          const { newDataTypes, newInstallationMode, newDataTypeSettings } = parseData(data || {});
          const newNamespaces = R.filter((item) => item, R.split(',', data?.namespaces || ''));
          const newState = {
            systemName: data?.systemShowName,
            namespaces: newNamespaces,
            namespacesOption: R.map((item) => ({ value: item, label: item }), newNamespaces || []),
            dataTypes: newDataTypes,
            installationMode: newInstallationMode,
            dataTypeSettings: newDataTypeSettings,
          };

          const { hasError } = verifyData(
            data?.systemShowName,
            newNamespaces,
            newDataTypes,
            newDataTypeSettings,
            newInstallationMode,
          );
          setState({ ...newState, isLoading: false, saveFlag: !hasError });
        } else {
          setState({ isLoading: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        setState({ isLoading: false });
        message.error(err.message || String(err));
      });
  };

  useEffect(() => {
    reloadData();
    return () => {
      setState({ saveFlag: false });
    };
  }, [systemId]);

  const handleChangeDataTypes = (dataTypes, oldDataTypes) => {
    const newValue = R.difference(dataTypes, oldDataTypes);

    const newDataTypeSettings = R.map((item) => {
      if (R.includes(item.type, newValue)) {
        return { ...item, hiddle: false, ...dataTypeCommon };
      } else if (!R.includes(item.type, dataTypes)) {
        return { ...item, hiddle: true, ...dataTypeCommon };
      } else {
        return item;
      }
    }, dataTypeSettings);

    const newInstallationMode = R.map((item) => {
      if (R.includes(item.type, newValue)) {
        return { ...item, value: defaultMode };
      } else if (!R.includes(item.type, dataTypes)) {
        return { ...item, value: '' };
      } else {
        return item;
      }
    }, installationMode || []);

    setState({
      dataTypes,
      dataTypeSettings: newDataTypeSettings,
      installationMode: newInstallationMode,
      saveFlag: false,
    });
  };

  const handleChangeInstallationMode = (value, type) => {
    const newDataTypeSettings = R.map((item) => {
      if (item.type === type && value === 'install') {
        return { ...item, ...dataTypeCommon };
      } else {
        return item;
      }
    }, dataTypeSettings);

    const newInstallationMode = R.map((item) => {
      if (type === item.type) {
        return { ...item, value };
      } else {
        return item;
      }
    }, installationMode || []);
    setState({ dataTypeSettings: newDataTypeSettings, installationMode: newInstallationMode, saveFlag: false });
  };

  const handleCopyClick = (content) => {
    if (!navigator.clipboard) {
      try {
        const input = document.createElement('input');
        input.setAttribute('value', content);
        document.body.appendChild(input);
        input.select();
        document.execCommand('Copy');
        document.body.removeChild(input);
        message.success('Copy success!');
      } catch (error) {
        message.error('Copy error!');
      }
    } else {
      navigator.clipboard.writeText(content).then(
        () => message.success('Copy success!'),
        () => message.error('Copy error!'),
      );
    }
  };

  const handleYamlFormat = () => {
    const typeSettingMap = {};
    R.forEach((item) => {
      const { hiddle, name, endpoint, authentication, username, password } = item || {};
      if (!hiddle) {
        typeSettingMap[name] = { endpoint: endpoint || '' };
        if (authentication) {
          typeSettingMap[name] = { ...typeSettingMap[name], username, password };
        }
        if (!endpoint) {
          typeSettingMap[dataTypeHideMap[name]] = { enabled: true };
        }
      }
    }, dataTypeSettings || []);

    let url = window.BASE_URL || window.location.origin || '';
    if (R.includes('localhost', url)) {
      url = 'https://stg.insightfinder.com';
    }
    const systemInfo = systemsMap[systemId] || {};
    const insightfinder = {
      url,
      user_name: userInfo?.userName,
      license_key: userInfo?.licenseKey,
      system_name: systemInfo?.systemName,
      system_id: systemInfo?.systemId,
    };
    if (isAdmin || isLocalAdmin) {
      const findUser = R.find((item) => item.userName === systemInfo.owner, userList || []);
      if (findUser) {
        insightfinder.user_name = findUser.userName;
        insightfinder.license_key = findUser.licenseKey;
      }
    }

    const projects = [];
    if (R.includes('Metric', dataTypes || [])) {
      projects.push({ name: 'node-metrics', type: 'metric', target: 'node' });
    }
    R.forEach((spaces) => {
      projects.push({ name: `${spaces}-namespace-event`, type: 'event', target: 'namespace', namespace: spaces });
    }, namespaces || []);
    R.forEach((item) => {
      R.forEach((spaces) => {
        if (item === 'Metric') {
          projects.push({ name: `${spaces}-pod-metric`, type: 'metric', target: 'pod', namespace: spaces });
          projects.push({ name: `${spaces}-pvc-metric`, type: 'metric', target: 'pvc', namespace: spaces });
        } else if (item === 'Log') {
          projects.push({ name: `${spaces}-pod-log`, type: 'log', target: 'pod', namespace: spaces });
        }
      }, namespaces || []);
    }, dataTypes || []);

    return { typeSettingMap, insightfinder, projects };
  };

  const previewYamlContent = () => {
    const { typeSettingMap, insightfinder, projects } = handleYamlFormat();
    try {
      const yamlString = yaml.dump({ ...typeSettingMap, insightfinder, projects });
      setState({ showPreviewCodeModal: true, previewCode: yamlString });
    } catch (error) {
      message.error('Error converting to YAML:', error);
    }
  };

  const downLoadYaml = () => {
    const { typeSettingMap, insightfinder, projects } = handleYamlFormat();
    try {
      const yamlString = yaml.dump({ ...typeSettingMap, insightfinder, projects });
      const yamlBlob = new Blob([yamlString], { type: 'text/yaml' });
      const url = window.URL.createObjectURL(yamlBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'values.yaml';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      message.success('downLoad success!');
    } catch (error) {
      message.error('Error converting to YAML:', error);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const systemInfo = systemsMap[systemId] || {};

    const namespacesStr = R.join(',', namespaces);

    const enableMetric = R.includes('Metric', dataTypes);
    let metricInstallMode;
    const enableLog = R.includes('Log', dataTypes);
    let logInstallMode;
    R.forEach((item) => {
      if (item.type === 'Metric') {
        metricInstallMode = modeMap[item.value];
      } else if (item.type === 'Log') {
        logInstallMode = modeMap[item.value];
      }
    }, installationMode || []);

    let prometheusEndpoint = '';
    let enablePrometheusAuth = false;
    let prometheusAuthUserName = '';
    let prometheusAuthPassword = '';

    let lokiEndpoint = '';
    let enableLokiAuth = false;
    let lokiAuthUserName = '';
    let lokiAuthPassword = '';
    R.forEach((item) => {
      if (item.type === 'Metric') {
        prometheusEndpoint = item.endpoint;
        enablePrometheusAuth = item.authentication;
        prometheusAuthUserName = item.username;
        prometheusAuthPassword = item.password;
      } else if (item.type === 'Log') {
        lokiEndpoint = item.endpoint;
        enableLokiAuth = item.authentication;
        lokiAuthUserName = item.username;
        lokiAuthPassword = item.password;
      }
    }, dataTypeSettings || []);

    setState({ btnLoading: true, saveFlag: false });

    fetchPost(getEndpoint('k8s-integration-setting'), {
      ...credentials,
      customerName: systemInfo.owner,
      settings: JSON.stringify({
        systemName: systemInfo.systemId,
        envName: systemInfo.environmentName || 'All',
        userName: systemInfo.owner,
        systemShowName: systemInfo.systemName,

        namespaces: namespacesStr,

        enableMetric,
        metricInstallMode,
        enableLog,
        logInstallMode,

        prometheusEndpoint,
        enablePrometheusAuth,
        prometheusAuthUserName,
        prometheusAuthPassword,

        lokiEndpoint,
        enableLokiAuth,
        lokiAuthUserName,
        lokiAuthPassword,
      }),
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          reloadData();
          message.success('Updated successfully');
          setState({ btnLoading: false, saveFlag: true });
        } else {
          setState({ btnLoading: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        setState({ btnLoading: false });
        message.error(err.message || String(err));
      });
  };

  const { hasError, dataTypesError, showDataTypeCard } = verifyData(
    systemName,
    namespaces,
    dataTypes,
    dataTypeSettings,
    installationMode,
  );
  return (
    <div className="flex-col full-height">
      <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height spin-overflow-y">
        <Card style={{ marginTop: 16 }}>
          <Form layout="vertical">
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.systemName)}
              validateStatus={!systemName ? 'error' : 'success'}
              help={!systemName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <AutoComplete size="small" value={systemName} style={{ width: 800 }} options={[]} disabled />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(settingsMessages.namespace)}
              validateStatus={!namespaces.length > 0 ? 'error' : 'success'}
              help={!namespaces.length > 0 ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                size="small"
                mode="tags"
                allowClear
                value={namespaces}
                style={{ width: 800 }}
                options={namespacesOption}
                onChange={(namespaces) => setState({ namespaces, saveFlag: false })}
                getPopupContainer={(triggerNode) => triggerNode?.parentElement || document.body}
              />
            </Form.Item>
            <div className="flex-row">
              <Form.Item
                label={intl.formatMessage(settingsMessages.dataType)}
                validateStatus={!dataTypes.length > 0 ? 'error' : 'success'}
                help={!dataTypes.length > 0 ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
                style={{ width: 130 }}
              >
                <Checkbox.Group
                  value={dataTypes}
                  onChange={(value) => handleChangeDataTypes(value, dataTypes)}
                  className="flex-col"
                >
                  {R.addIndex(R.map)(
                    (item, idx) => (
                      <div key={item} style={{ ...(idx > 0 ? { marginTop: idx * 10 } : {}) }}>
                        <Checkbox value={item}>{item}</Checkbox>
                      </div>
                    ),
                    dataTypesOptions || [],
                  )}
                </Checkbox.Group>
              </Form.Item>
              <Form.Item label={intl.formatMessage(settingsMessages.installationMode)}>
                <div className="flex-col">
                  {R.addIndex(R.map)((item, idx) => {
                    const { type, value, option } = item || {};
                    const disabled = !R.includes(type, dataTypes);
                    return (
                      <Select
                        size="small"
                        key={type}
                        value={value}
                        style={{ width: 670, ...(idx > 0 ? { marginTop: 8 } : {}) }}
                        options={option}
                        disabled={disabled}
                        onChange={(value) => handleChangeInstallationMode(value, type)}
                        getPopupContainer={(triggerNode) => triggerNode?.parentElement || document.body}
                      />
                    );
                  }, installationMode || [])}
                </div>
              </Form.Item>
            </div>
          </Form>
        </Card>

        {!dataTypesError && showDataTypeCard && (
          <Card style={{ marginTop: 16 }}>
            <Form layout="horizontal">
              {R.map((item) => {
                const { type, title, endpoint, endpointUrl, authentication, username, password, hiddle } = item || {};
                const modeRequired = R.find((mode) => mode.type === type, installationMode || [])?.value === 'existing';
                if (hiddle || !modeRequired) {
                  return <div key={type} />;
                }
                return (
                  <Fragment key={type}>
                    <div className="font-14 bold" style={{ marginBottom: 8 }}>
                      {title}:
                    </div>
                    <div style={{ paddingLeft: 16 }}>
                      <Form.Item
                        label={<div style={{ width: 90 }}>{intl.formatMessage(settingsMessages.endpoint)}</div>}
                        validateStatus={modeRequired && !endpoint ? 'error' : 'success'}
                        help={
                          modeRequired && !endpoint ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined
                        }
                        required={modeRequired}
                      >
                        <Input
                          size="small"
                          allowClear
                          value={endpoint}
                          style={{ width: 680 }}
                          placeholder={endpointUrl}
                          onChange={(e) => {
                            const findIdx = R.findIndex((_item) => _item.type === type, dataTypeSettings);
                            setState({
                              dataTypeSettings: update(dataTypeSettings, {
                                [findIdx]: { $set: { ...item, endpoint: e.target.value } },
                              }),
                              saveFlag: false,
                            });
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        colon={false}
                        label={<div style={{ width: 90 }}>{intl.formatMessage(settingsMessages.authentication)}</div>}
                      >
                        <Checkbox
                          size="small"
                          checked={authentication}
                          onChange={(e) => {
                            const findIdx = R.findIndex((_item) => _item.type === type, dataTypeSettings);
                            const { checked } = e.target;
                            let newState = { authentication: e.target.checked };
                            if (!checked) {
                              newState = { ...newState, username: undefined, password: undefined };
                            }
                            setState({
                              dataTypeSettings: update(dataTypeSettings, {
                                [findIdx]: { $set: { ...item, ...newState } },
                              }),
                              saveFlag: false,
                            });
                          }}
                        />
                      </Form.Item>
                      {authentication && (
                        <>
                          <Form.Item
                            label={<div style={{ width: 90 }}>{intl.formatMessage(eventMessages.userName)}</div>}
                            validateStatus={modeRequired && !username ? 'error' : 'success'}
                            help={
                              modeRequired && !username
                                ? intl.formatMessage(appFieldsMessages.inputRequired)
                                : undefined
                            }
                            required={modeRequired}
                          >
                            <Input
                              size="small"
                              allowClear
                              value={username}
                              style={{ width: 680 }}
                              onChange={(e) => {
                                const findIdx = R.findIndex((_item) => _item.type === type, dataTypeSettings);
                                setState({
                                  dataTypeSettings: update(dataTypeSettings, {
                                    [findIdx]: { $set: { ...item, username: e.target.value } },
                                  }),
                                  saveFlag: false,
                                });
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label={<div style={{ width: 90 }}>{intl.formatMessage(appFieldsMessages.password)}</div>}
                            validateStatus={modeRequired && !password ? 'error' : 'success'}
                            help={
                              modeRequired && !password
                                ? intl.formatMessage(appFieldsMessages.inputRequired)
                                : undefined
                            }
                            required={modeRequired}
                          >
                            <Input.Password
                              size="small"
                              allowClear
                              value={password}
                              style={{ width: 680 }}
                              onChange={(e) => {
                                const findIdx = R.findIndex((_item) => _item.type === type, dataTypeSettings);
                                setState({
                                  dataTypeSettings: update(dataTypeSettings, {
                                    [findIdx]: { $set: { ...item, password: e.target.value } },
                                  }),
                                  saveFlag: false,
                                });
                              }}
                            />
                          </Form.Item>
                        </>
                      )}
                    </div>
                  </Fragment>
                );
              }, dataTypeSettings || [])}
            </Form>
          </Card>
        )}

        <div style={{ margin: '16px 20px 0 0', textAlign: 'right' }}>
          <Button type="primary" style={{ width: 120 }} disabled={hasError} loading={btnLoading} onClick={handleSave}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </div>

        <Card style={{ marginTop: 16, ...(saveFlag ? {} : { width: 0, height: 0, overflow: 'hidden' }) }}>
          <div className="font-14 bold" style={{ marginBottom: 4 }}>
            {intl.formatMessage(settingsMessages.kubernetesStepInstructions)}
          </div>
          <div className="font-14 bold" style={{ marginBottom: 4 }}>
            {intl.formatMessage(settingsMessages.kubernetesInstallInstructions)}
          </div>
          <div className="font-14" style={{ marginTop: 8 }}>
            <div className="bold">1. {intl.formatMessage(settingsMessages.addTheHelmChartRepo)}:</div>
            <div className="flex-row flex-center-align" style={{ paddingLeft: 16, width: 800, position: 'relative' }}>
              <CodeMirror
                className="codeMirror_auto"
                options={{
                  readOnly: 'nocursor',
                  lineNumbers: false,
                  theme: isDark ? 'material' : 'xq-light',
                }}
                value="helm repo add insightfinder https://insightfinder.github.io/charts"
              />
              <Popover content={intl.formatMessage(settingsMessages.copy)} mouseEnterDelay={0.3} placement="top">
                <Button
                  size="small"
                  type="primary"
                  icon={<CopyOutlined />}
                  style={{ marginLeft: 8, position: 'absolute', right: 8 }}
                  disabled={hasError}
                  onClick={() => handleCopyClick('helm repo add insightfinder https://insightfinder.github.io/charts')}
                />
              </Popover>
            </div>
          </div>
          <div className="font-14 flex-row flex-center-align" style={{ marginTop: 8 }}>
            <div className="bold">2. {intl.formatMessage(settingsMessages.copyOrDownloadTheValueYaml)}</div>
            <div className="flex-row flex-center-align">
              <Popover content={intl.formatMessage(appButtonsMessages.preview)} mouseEnterDelay={0.3} placement="top">
                <Button
                  size="small"
                  type="primary"
                  icon={<PreviewIcon style={{ fontSize: 14 }} />}
                  onClick={previewYamlContent}
                  style={{ marginLeft: 8 }}
                  disabled={hasError}
                />
              </Popover>
            </div>
          </div>
          <div className="font-14" style={{ marginTop: 8 }}>
            <div className="bold">3. {intl.formatMessage(settingsMessages.installTheHelmChart)}</div>
            <div className="flex-row flex-center-align" style={{ paddingLeft: 16, width: 800, position: 'relative' }}>
              <CodeMirror
                className="codeMirror_auto"
                options={{
                  readOnly: 'nocursor',
                  lineNumbers: false,
                  theme: isDark ? 'material' : 'xq-light',
                }}
                value="helm install -f ./values.yaml if-kubernetes-agent insightfinder/if-kubernetes-agent"
              />
              <Popover content={intl.formatMessage(settingsMessages.copy)} mouseEnterDelay={0.3} placement="top">
                <Button
                  size="small"
                  type="primary"
                  icon={<CopyOutlined />}
                  style={{ marginLeft: 8, position: 'absolute', right: 8 }}
                  disabled={hasError}
                  onClick={() =>
                    handleCopyClick(
                      'helm install -f ./values.yaml if-kubernetes-agent insightfinder/if-kubernetes-agent',
                    )
                  }
                />
              </Popover>
            </div>
          </div>
        </Card>
      </Spin>

      {showPreviewCodeModal && (
        <PreviewCode
          intl={intl}
          isDark={isDark}
          previewCode={previewCode}
          handleCopyClick={handleCopyClick}
          downLoadYaml={downLoadYaml}
          onClose={() => setState({ showPreviewCodeModal: false, previewCode: null })}
        />
      )}
    </div>
  );
}

const PreviewCode = (props: Object) => {
  const { onClose, previewCode, intl, isDark, handleCopyClick, downLoadYaml } = props;

  return (
    <Modal
      visible
      title={intl.formatMessage(appButtonsMessages.preview)}
      onCancel={() => onClose()}
      width={950}
      bodyStyle={{ height: 600, overflowY: 'auto' }}
      footer={[
        <Button size="small" key="cancel" onClick={onClose}>
          {intl.formatMessage(appButtonsMessages.cancel)}
        </Button>,
      ]}
    >
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <CodeMirror
          className="codeMirror_auto"
          options={{
            readOnly: 'nocursor',
            lineNumbers: false,
            theme: isDark ? 'material' : 'xq-light',
          }}
          value={previewCode}
        />
        <Popover content={intl.formatMessage(settingsMessages.copy)} mouseEnterDelay={0.3} placement="top">
          <Button
            size="small"
            type="primary"
            icon={<CopyOutlined />}
            style={{ marginLeft: 8, position: 'absolute', top: 8, right: 40 }}
            onClick={() => handleCopyClick(previewCode)}
          />
        </Popover>
        <Popover content={intl.formatMessage(appButtonsMessages.download)} mouseEnterDelay={0.3} placement="top">
          <Button
            size="small"
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downLoadYaml}
            style={{ marginLeft: 8, position: 'absolute', top: 8, right: 8 }}
          />
        </Popover>
      </div>
    </Modal>
  );
};

const KubernetesEndpoint = injectIntl(KubernetesEndpointCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, systemsMap, projects, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isLocalAdmin } = state.auth.userInfo;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return {
      location,
      loadStatus,
      systemsMap,
      credentials,
      userInfo,
      projects,
      currentTheme,
      isAdmin,
      isLocalAdmin,
      userList,
    };
  },
  { updateLastActionInfo },
)(KubernetesEndpoint);
