/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { autobind } from 'core-decorators';

import { buildLocation, parseLocation, Defaults } from '../../common/utils';
import { showAppAlert, showAppLoader, createLoadAction } from '../../common/app/actions';
import { State } from '../../common/types';
import { Container } from '../../lib/fui/react';
import { BaseUrls } from '../app/Constants';

type Props = {
  children: Element<any>,
  view: String,

  location: Object,
  intl: Object,
  push: Function,
  replace: Function,
  showAppAlert: Function,
  showAppLoader: Function,
  createLoadAction: Function,

  loadStatus: Object,
  credentials: Object,
  globalInfo: Array<Object>,
  favorites: Array,
};

const noFilterSystemUrl = ['/global/systemknowledgebase', '/global/system/ignoredincident', '/global/health/system'];

class GlobalSystemPlatformViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { replace, location, globalInfo, favorites: favoriteList } = this.props;
    const query = parseLocation(location);
    const { redirect, environmentId, systemId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    let systemList = environment ? environment.systemList : [];
    systemList = R.sort((systemA, systemB) => systemA.name.localeCompare(systemB.name), systemList);
    if (!R.includes(location.pathname, noFilterSystemUrl)) {
      systemList = R.filter((item) => item.hasData, systemList);
      let favorites = R.filter((item) => favoriteList.includes(item.id), systemList);
      let restSystems = R.filter((item) => !favoriteList.includes(item.id), systemList);
      favorites = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(favorites);
      restSystems = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(restSystems);
      systemList = [...favorites, ...restSystems];
    }

    // Auto select the first system
    // If not from redirect, and no system id or the system id not in the list
    if (
      redirect !== 'true' &&
      systemId === 'globalKnowledgeBase' &&
      location.pathname === '/global/systemknowledgebase'
    ) {
      replace(buildLocation(location.pathname, {}, { ...query }));
    } else if (
      redirect !== 'true' &&
      systemList.length > 0 &&
      (!systemId || !R.find((s) => s.id === systemId, systemList)) &&
      location.pathname !== '/global/systeminsights'
    ) {
      replace(buildLocation(location.pathname, {}, { ...query, systemId: systemList[0].id }));
    }

    this.timer = setInterval(() => {
      if (
        location.pathname === '/global/systemprediction' ||
        location.pathname === '/global/systemrootcause' ||
        location.pathname === '/global/systemknowledgebase'
      ) {
        return;
      }
      this.refreshTime();
    }, 5 * 60 * 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { replace, location, globalInfo, favorites: favoriteList } = nextProps;
    const preQuery = parseLocation(this.props.location);
    const nextQuery = parseLocation(nextProps.location);
    const { redirect, environmentId, systemId } = nextQuery;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    let systemList = environment ? environment.systemList : [];
    systemList = R.sort((systemA, systemB) => systemA.name.localeCompare(systemB.name), systemList);
    if (!R.includes(location.pathname, noFilterSystemUrl)) {
      systemList = R.filter((item) => item.hasData, systemList);
      let favorites = R.filter((item) => favoriteList.includes(item.id), systemList);
      let restSystems = R.filter((item) => !favoriteList.includes(item.id), systemList);
      favorites = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(favorites);
      restSystems = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(restSystems);
      systemList = [...favorites, ...restSystems];
    }

    // Auto select the first system
    // If not from redirect, and no system id or the system id not in the list
    if (
      redirect !== 'true' &&
      systemId === 'globalKnowledgeBase' &&
      location.pathname === '/global/systemknowledgebase'
    ) {
      if (!R.equals(preQuery, nextQuery)) {
        replace(buildLocation(location.pathname, {}, { ...nextQuery }));
      }
    } else if (
      redirect !== 'true' &&
      systemList.length > 0 &&
      (!systemId || !R.find((s) => s.id === systemId, systemList)) &&
      location.pathname !== '/global/systeminsights'
    ) {
      replace(buildLocation(location.pathname, {}, { ...nextQuery, systemId: systemList[0].id }));
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  @autobind
  refreshTime() {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    const { startTime } = query;
    const selectStartTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    if (selectStartTimestamp >= moment.utc().startOf('day').valueOf()) {
      const refreshTime = moment.utc().valueOf();
      replace(buildLocation(location.pathname, {}, { ...query, refreshTime }));
    }
  }

  @autobind
  handleDateTimeChange(newDate, newParams) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    const startTime = newDate.clone().startOf('day').format(Defaults.DateFormat);

    replace(
      buildLocation(
        location.pathname,
        {},
        {
          ...query,
          ...(newParams || {}),
          startTime,
          endTime: startTime,
        },
      ),
    );
  }

  @autobind
  handleStartTimeChange(newDate, newParams) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    const startTimeObj = newDate.clone().startOf('day');
    const startTime = startTimeObj.format(Defaults.DateFormat);

    let { endTime } = query;
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat);
    if (endTime && startTimeObj > endTimeObj) {
      endTime = newDate.clone().format(Defaults.DateFormat);
    }

    replace(
      buildLocation(
        location.pathname,
        {},
        {
          ...query,
          ...(newParams || {}),
          startTime,
          endTime,
        },
      ),
    );
  }

  @autobind
  handleEndTimeChange(newDate) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    let { startTime } = query;
    const endTimeObj = newDate.clone().endOf('day');
    const endTime = endTimeObj.format(Defaults.DateFormat);

    const startTimeObj = moment.utc(startTime, Defaults.DateFormat);
    if (endTime && startTimeObj > endTimeObj) {
      startTime = newDate.clone().format(Defaults.DateFormat);
    }

    replace(
      buildLocation(
        location.pathname,
        {},
        {
          ...query,
          startTime,
          endTime,
        },
      ),
    );
  }

  @autobind
  handleCustomerNameChange(customerName) {
    const { location, showAppLoader } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    setTimeout(() => {
      const query = parseLocation(location);
      const { startTime, endTime } = query;
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { redirect: undefined, customerName, environmentId: undefined, systemId: undefined, startTime, endTime },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  handleEnvironmentChange(environmentId) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    const { customerName, startTime, endTime } = query;
    replace(
      buildLocation(
        location.pathname,
        {},
        {
          // clear redirect flag
          redirect: undefined,

          customerName,
          environmentId,
          systemId: undefined,
          startTime,
          endTime,
        },
      ),
    );
  }

  @autobind
  handleSystemIdChange(systemId) {
    const { replace, location } = this.props;
    const query = parseLocation(location);
    const { customerName, startTime, endTime, environmentId } = query;
    let otherQuery = {};
    if (location.pathname === '/global/systemrootcause' || location.pathname === BaseUrls.GlobalSystemPrediction) {
      otherQuery = query;
    }
    replace(
      buildLocation(
        location.pathname,
        {},
        {
          ...otherQuery,
          // clear redirect flag
          redirect: undefined,

          customerName,
          environmentId,
          startTime,
          endTime,

          systemId,
        },
      ),
    );
  }

  @autobind
  handleRefresh(params) {
    const { location, replace } = this.props;
    let query = parseLocation(location);
    let needReload = true;

    const { startTimeObj, endTimeObj, ...rest } = params || {};
    if (startTimeObj && endTimeObj) {
      const startTime = startTimeObj.format(Defaults.DateFormat);
      const endTime = endTimeObj.format(Defaults.DateFormat);
      // update start/end time if changed
      if (startTime !== query.startTime || endTime !== query.endTime) {
        query = { ...query, startTime, endTime };
        needReload = false;
      }
    }

    if (needReload) {
      query = { ...query, forceRefreshTime: moment.utc().valueOf() };
    }

    // force refresh
    replace(buildLocation(location.pathname, {}, { ...rest, ...query }));
  }

  render() {
    const { children, location } = this.props;
    const { forceRefreshTime } = parseLocation(location);

    return (
      <Container fullHeight withGutter className="flex-col flex-min-height">
        {React.Children.map(children, (element) => (
          <element.type
            {...element.props}
            refresh={forceRefreshTime}
            handleRefresh={this.handleRefresh}
            handleDateTimeChange={this.handleDateTimeChange}
            handleStartTimeChange={this.handleStartTimeChange}
            handleEndTimeChange={this.handleEndTimeChange}
            handleCustomerNameChange={this.handleCustomerNameChange}
            handleEnvironmentChange={this.handleEnvironmentChange}
            handleSystemIdChange={this.handleSystemIdChange}
          />
        ))}
      </Container>
    );
  }
}

const GlobalSystemPlatformViewSingle = injectIntl(GlobalSystemPlatformViewCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { loadStatus, globalInfo, favorites } = state.app;
    return {
      location,
      credentials,
      loadStatus,
      globalInfo,
      favorites,
    };
  },
  { push, replace, showAppAlert, showAppLoader, createLoadAction },
)(GlobalSystemPlatformViewSingle);
