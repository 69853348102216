import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Spin, Switch, Select, DatePicker, TreeSelect, Divider, Checkbox, Radio } from 'antd';
import { isArray, isFunction } from 'lodash';
import RcDatePicker from 'react-datepicker';

import { Defaults, localDateToUtcObj, parseLocation, utcObjToLocalDate } from '../../../common/utils';
import { Modal, Container } from '../../../lib/fui/react';
import { loadProjectInfo } from '../../../common/app/actions';

import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { TimeInput } from '../../share/TimeInput';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  systemsMap: Object,
  projectList: Array<Object>,
  userInfo: Object,
  loadProjectInfo: Function,

  startTimestamp: Number,
  endTimestamp: Number,
  onClose: Function,
  updateModelLoading: Boolean,
  onRerunKbPrediction: Function,
};

class GlobalCronSelectModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    const { startTimestamp, endTimestamp } = props;

    this.globalUpdateOptions = [
      {
        label: 'Rerun for system',
        updateOptions: [
          { label: 'Rerun Global View', value: 'GlobalView' },
          { label: 'Rerun Insight Summary', value: 'RerunInsightSummary' },
          { label: 'Rerun Root Cause Analysis', value: 'RerunRootCauseAnalysis' },
          // { label: 'Rerun Knowledge Base', value: 'RerunKnowledgeBase' },
          { label: 'Rerun Historical Incidents', value: 'RerunHistoricalIncidents' },
          { label: 'Rerun Knowledge Base Training', value: 'RerunHnowledgeBaseTraining' },
          // { label: 'Rerun System Level Incident Prediction', value: 'RerunSystemLevelIncidentPrediction' },
          { label: 'Rerun Incident Prediction', value: 'RerunKbPrediction' },
          { label: 'Rerun Incident Prediction Accuracy Statistics', value: 'RerunIncidentPrediction' },
          { label: 'Rerun Service Map', value: 'RerunServiceMap' },
          { label: 'Rerun Composite Incident Detection', value: 'RerunCompositeIncidentDetection' },
        ],
      },
      {
        label: 'Rerun for metric project',
        updateOptions: [
          // { label: 'Rerun Metric-Driven Incident Prediction', value: 'MetricDrivenPrediction' },
          { label: 'Rerun Baseline', value: 'Baseline' },
          { label: 'Rerun Metric Training', value: 'RerunTraining' },
          { label: 'Rerun Metric Detection', value: 'RerunDetection' },
          { label: 'Rerun Metric Prediction', value: 'RerunMetricPrediction' },
          { label: 'Rerun Capacity Planning', value: 'RerunCapacityPlanning' },
          { label: 'Rerun Metric Instance Metadata', value: 'RerunMetricInstanceMetadata' },
          { label: 'Rerun Instance Metadata And Down Detection', value: 'RerunInstanceDownIncident' },
          { label: 'Rerun Metric Collection', value: 'RerunMetricCollect' },
          { label: 'Rerun Metric Project Info', value: 'RerunMetricProjectInfo' },
          { label: 'Rerun Metric Project Statistics', value: 'MetricProjectStatistics' },
          // { label: 'Rerun Normal Behavior Statistics', value: 'NormalBehaviorStatistics' },
          // { label: 'Rerun Capacity Planning', value: 'AppForecast' },
          // { label: 'Rerun KPI Prediction', value: 'KPIPrediction' },
        ],
      },
      {
        label: 'Rerun for log project',
        updateOptions: [
          { label: 'Rerun Log Detection', value: 'LogDetection' },
          { label: 'Rerun Log Incident', value: 'LogIncident' },
          // { label: 'Rerun Log-Driven Incident Prediction', value: 'LogDrivenPrediction' },
          { label: 'Rerun Log Common Pattern', value: 'LogCommonPattern' },
          { label: 'Rerun Log Data Collection', value: 'LogHistoricalDataCollect' },
          { label: 'Rerun Log Data Deletion', value: 'LogDataDeletion' },
        ],
      },
      {
        label: 'Fault Injection',
        updateOptions: [{ label: 'Deploy buggy release', value: 'DeployBuggyRelease' }],
      },
    ];
    this.localProjectListOption = [];
    this.state = {
      isLoading: false,
      globalUpdate: 'GlobalView',
      updateTotalMax: false,
      projectName: '',
      systemId: '',
      startTimestamp: startTimestamp || moment.utc().startOf('days').valueOf(),
      endTimestamp: endTimestamp || moment.utc().startOf('days').valueOf(),
      systemListOption: [],
      projectListOption: [],
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = nextProps;
    if (intl.locale !== this.props.intl.locale) {
      moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    }
  }

  parseData(props) {
    const { userInfo, systemsMap, projectList, location } = props;
    const { customerName } = parseLocation(location);

    const { userName, companyName } = userInfo;
    const currentOwner = customerName || userName;

    let systemListOption = R.values(systemsMap);
    let projectListOption = projectList;
    projectListOption = R.map(
      (item) => ({
        ...item,
        systemName: item.systemName || 'Projects with no system',
        systemId: item.systemId || 'projectsWithNoSystem',
      }),
      projectListOption || [],
    );

    systemListOption = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('systemName')))], systemListOption);
    if (!R.includes(R.toLower('InsightFinder'), R.toLower(companyName || ''))) {
      projectListOption = R.filter((item) => item.owner === currentOwner, projectListOption);
      systemListOption = R.filter((item) => item.owner === currentOwner, systemListOption);
    }

    projectListOption = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('projectDisplayName')))], projectListOption);
    const projectgroupBySystem = R.groupBy((item) => item.systemId, projectListOption);
    let treeData = [];
    R.forEachObjIndexed((projects, system) => {
      const parentNode = {
        title: projects[0].systemName,
        name: projects[0].systemName,
        value: projects[0].systemId,
        key: projects[0].systemId,
        children: [],
      };
      R.forEach((project) => {
        const name = `${project.projectDisplayName}${
          userInfo.isAdmin || userInfo.isLocalAdmin || project.owner !== userInfo.userName ? `@${project.owner}` : ''
        }`;
        parentNode.children.push({
          title: name,
          name,
          value: project.projectName,
          key: project.projectName,
          dataType: project.dataType,
        });
      }, projects || []);
      treeData.push(parentNode);
    }, projectgroupBySystem || {});
    treeData = R.filter((item) => item.children.length > 0, treeData || []);

    const noSystemNode = R.filter((item) => item.value === 'projectsWithNoSystem', treeData || []);
    const hasSystemNode = R.filter((item) => item.value !== 'projectsWithNoSystem', treeData || []);

    let treeNodes = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))], hasSystemNode);
    treeNodes = [...treeNodes, ...noSystemNode];

    this.localProjectListOption = treeNodes;
    this.setState({ systemListOption, projectListOption: treeNodes });
  }

  @autobind
  handleConfirm() {
    const { startTimestamp, endTimestamp, globalUpdate, updateTotalMax, projectName, systemId } = this.state;
    const { onClose, systemsMap, projectList } = this.props;

    const systemIsArr = ['RerunKbPrediction', 'RerunHnowledgeBaseTraining', 'RerunSystemLevelIncidentPrediction'];
    const projectIsArr = [
      'RerunMetricInstanceMetadata',
      'RerunTraining',
      'RerunDetection',
      'RerunCapacityPlanning',
      'RerunMetricProjectInfo',
      'MetricProjectStatistics',
    ];

    if (onClose) {
      const systemList = R.values(systemsMap);
      let system = {};
      if (!systemIsArr.includes(globalUpdate)) {
        system = R.find((item) => item.systemId === systemId, systemList);
      }
      const { owner: systemOwner } = system || {};

      let project = {};
      let projectShortName = null;
      let projectTypeRaw = null;
      if (!projectIsArr.includes(globalUpdate)) {
        project = R.find((p) => p.projectName === projectName, projectList);
        const { projectShortName: shortName, projectTypeRaw: typeRaw } = project || {};
        projectShortName = shortName;
        projectTypeRaw = typeRaw;
      } else {
        const projects = [];
        R.forEach((item) => {
          projects.push(R.find((p) => p.projectName === item, projectList));
        }, projectName);
        projectShortName = projects;
      }

      let systemIds = [];
      let systemIdsWithShare = [];
      if (systemId) {
        const selectedSystemList = R.filter((item) => systemId.includes(item.systemId), systemList);
        systemIds = R.map((item) => ({ id: item.systemId }), selectedSystemList);
        systemIdsWithShare = R.map((item) => ({ id: item.systemId, customerName: item.owner }), selectedSystemList);
      }
      onClose({
        startTimestamp,
        endTimestamp,
        globalUpdate,
        updateTotalMax,
        project,
        projectName: projectShortName,
        projectType: projectTypeRaw,
        systemName: systemId,
        systemOwner,
        systemIds: JSON.stringify(systemIds),
        systemIdsWithShare: JSON.stringify(systemIdsWithShare),
      });
    }
  }

  @autobind
  handleStartTimeChange(dateObj) {
    if (dateObj) {
      let { endTimestamp } = this.state;
      const selectTime = dateObj.valueOf();
      if (selectTime > endTimestamp) {
        endTimestamp = selectTime;
      }
      this.setState({ startTimestamp: selectTime, endTimestamp });
    } else {
      this.setState({ startTimestamp: null });
    }
  }

  @autobind
  handleEndTimeChange(dateObj) {
    if (dateObj) {
      let { startTimestamp } = this.state;
      const selectTime = dateObj.valueOf();
      if (startTimestamp > selectTime) {
        startTimestamp = selectTime;
      }
      this.setState({ endTimestamp: selectTime, startTimestamp });
    } else {
      this.setState({ endTimestamp: null });
    }
  }

  @autobind
  handleGlobalUpdateChange(globalUpdate) {
    const { projectList, loadProjectInfo } = this.props;
    let projectNames = [];
    if (globalUpdate === 'LogHistoricalDataCollect' || globalUpdate === 'LogDataDeletion') {
      const projects = R.filter((p) => p.dataType !== 'Metric' && !p.hasAllInfo, projectList);
      projectNames = R.map((project) => project.projectName, projects);
    }
    const needReloadProjectInfo = projectNames.length > 0;

    let systemId = null;
    if (
      ['RerunHnowledgeBaseTraining', 'RerunSystemLevelIncidentPrediction', 'RerunKbPrediction'].includes(globalUpdate)
    ) {
      systemId = [];
    }
    let projectName = null;
    if (
      [
        'RerunMetricInstanceMetadata',
        'RerunTraining',
        'RerunDetection',
        'RerunCapacityPlanning',
        'RerunMetricProjectInfo',
        'MetricProjectStatistics',
      ].includes(globalUpdate)
    ) {
      projectName = [];
    }
    this.setState({ globalUpdate, systemId, projectName, isLoading: needReloadProjectInfo }, () => {
      if (needReloadProjectInfo) {
        loadProjectInfo({ projectName: projectNames }, false, this.callbackHandle);
      }
    });
  }

  @autobind
  handleProjectChange(projectName) {
    const { projectList, loadProjectInfo } = this.props;
    const { globalUpdate } = this.state;
    let project = {};
    let needReloadProjectInfo = false;
    let projectNames = [];
    const flag = [
      'RerunMetricInstanceMetadata',
      'RerunTraining',
      'RerunDetection',
      'RerunCapacityPlanning',
      'RerunMetricProjectInfo',
      'MetricProjectStatistics',
    ].includes(globalUpdate);
    if (!flag) {
      project = R.find((project) => project.projectName === projectName, projectList);
      needReloadProjectInfo = project && !project.hasAllInfo;
    } else {
      let projectNameArr = [];
      R.forEach((item) => {
        projectNameArr.push(R.find((project) => project.projectName === item && !project.hasAllInfo, projectList));
      }, projectName);
      projectNameArr = R.filter((item) => item, projectNameArr);
      projectNames = R.map((item) => item.projectName, projectNameArr);
      needReloadProjectInfo = projectNames.length > 0;
    }
    this.setState({ projectName }, () => {
      if (needReloadProjectInfo) {
        loadProjectInfo({ projectName: flag ? projectNames : projectName }, false, this.callbackHandle);
      }
    });
  }

  @autobind
  handleSystemChange(systemId) {
    this.setState({ systemId });
  }

  @autobind
  callbackHandle() {
    this.setState({ isLoading: false });
  }

  @autobind
  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name || '';

    this.setState({
      [name]: value,
    });
  }

  @autobind
  getFilterTreeData(searchValue, treeData) {
    return R.filter((item) => {
      item.children = R.filter(
        (item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
        item.children || [],
      );
      return item.children.length > 0;
    }, R.clone(treeData || []));
  }

  render() {
    const { intl, location, userInfo, updateModelLoading } = this.props;
    const {
      isLoading,
      startTimestamp,
      endTimestamp,
      globalUpdate,
      updateTotalMax,
      projectName,
      systemId,
      systemListOption,
    } = this.state;
    let { projectListOption } = this.state;

    const { customerName } = parseLocation(location);

    const startTimeObj = moment.utc(startTimestamp);
    const endTimeObj = moment.utc(endTimestamp);

    const showDatePicker = !['RerunKnowledgeBase', 'LogCommonPattern', 'DeployBuggyRelease'].includes(globalUpdate);
    let hasError = showDatePicker ? !startTimestamp || !endTimestamp : false;
    const showTimePicker = ['LogHistoricalDataCollect', 'LogDataDeletion'].includes(globalUpdate);

    let showSystemPicker = false;
    let showProjectPicker = false;
    if (
      [
        'MetricDrivenPrediction',
        'Baseline',
        'RerunTraining',
        'RerunDetection',
        'RerunMetricPrediction',
        'RerunCapacityPlanning',
        'RerunMetricInstanceMetadata',
        'RerunInstanceDownIncident',
        'RerunMetricCollect',
        'RerunMetricProjectInfo',
        'MetricProjectStatistics',
        // 'NormalBehaviorStatistics',
        // 'AppForecast',
        // 'KPIPrediction',
      ].includes(globalUpdate)
    ) {
      showProjectPicker = true;
      projectListOption = R.filter((p) => {
        p.children = R.filter((item) => item.dataType === 'Metric', p.children || []);
        return p.children.length > 0;
      }, R.clone(projectListOption));
      const flagProjectNameIsArr = [
        'RerunMetricInstanceMetadata',
        'RerunTraining',
        'RerunDetection',
        'RerunCapacityPlanning',
        'RerunMetricProjectInfo',
        'MetricProjectStatistics',
      ].includes(globalUpdate);
      hasError = hasError || (flagProjectNameIsArr ? projectName.length < 1 : !projectName);
    } else if (
      [
        'LogDetection',
        'LogIncident',
        'LogDrivenPrediction',
        'LogCommonPattern',
        'LogHistoricalDataCollect',
        'LogDataDeletion',
      ].includes(globalUpdate)
    ) {
      showProjectPicker = true;
      projectListOption = R.filter((p) => {
        p.children = R.filter((item) => item.dataType !== 'Metric', p.children || []);
        return p.children.length > 0;
      }, R.clone(projectListOption));
      hasError = hasError || !projectName;
    } else if (
      [
        'GlobalView',
        'RerunInsightSummary',
        'RerunServiceMap',
        'RerunCompositeIncidentDetection',
        'RerunIncidentPrediction',
        'RerunRootCauseAnalysis',
        'RerunKnowledgeBase',
        'RerunHistoricalIncidents',
        'RerunHnowledgeBaseTraining',
        'RerunSystemLevelIncidentPrediction',
        'RerunKbPrediction',
      ].includes(globalUpdate)
    ) {
      showSystemPicker = true;
      const flagSystemIdIsArr = ['RerunHnowledgeBaseTraining', 'RerunSystemLevelIncidentPrediction'].includes(
        globalUpdate,
      );
      hasError =
        globalUpdate === 'GlobalView' ? hasError : hasError || (flagSystemIdIsArr ? systemId.length < 1 : !systemId);
    }

    const isMultiple = [
      'RerunMetricInstanceMetadata',
      'RerunTraining',
      'RerunDetection',
      'RerunCapacityPlanning',
      'RerunMetricProjectInfo',
      'MetricProjectStatistics',
    ].includes(globalUpdate);

    if (!isMultiple) {
      projectListOption = R.map((item) => {
        const newChildren = R.map(
          (_item) => ({
            ..._item,
            title: (
              <div className="flex-row flex-center-align">
                <Radio size="small" style={{ marginRight: 4 }} checked={projectName === _item.value} />
                <span>{_item.title}</span>
              </div>
            ),
          }),
          item.children || [],
        );
        return {
          ...item,
          disabled: true,
          title: <span style={{ fontSize: 14 }}>{item.title}</span>,
          children: newChildren,
        };
      }, R.clone(projectListOption || []));
    }
    const treeDefaultExpandedKeys = R.map((item) => item.key, projectListOption);

    return (
      <Modal
        title={intl.formatMessage(appButtonsMessages.update)}
        width={650}
        visible
        maskClosable={false}
        onCancel={() => this.props.onClose()}
        okButtonProps={{ disabled: hasError || updateModelLoading }}
        onOk={this.handleConfirm}
      >
        <Container className="flex-col content" style={{ fontSize: 12 }}>
          <Spin spinning={isLoading}>
            <div className="flex-row flex-center-align" style={{ height: 28 }}>
              <span className="light-label bold" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.category)}:
              </span>
              <div className="flex-grow">
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  value={globalUpdate}
                  optionFilterProp="label"
                  filterOption
                  onChange={this.handleGlobalUpdateChange}
                  dropdownMatchSelectWidth={false}
                >
                  {R.addIndex(R.map)(
                    (item, idx) => (
                      <Select.OptGroup key={idx} label={item.label}>
                        {R.map((updateOption) => {
                          return (
                            <Select.Option key={updateOption.value} label={updateOption.label}>
                              {updateOption.label}
                            </Select.Option>
                          );
                        }, item.updateOptions || [])}
                      </Select.OptGroup>
                    ),
                    this.globalUpdateOptions || [],
                  )}
                </Select>
              </div>
            </div>
            {userInfo.isAdmin && showSystemPicker && (
              <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
                <span className="light-label bold" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.user)}:
                </span>
                <span>{customerName}</span>
              </div>
            )}
            {showDatePicker && (
              <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
                <span className="light-label bold" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.startDate)}:
                </span>
                {!showTimePicker && (
                  <DatePicker
                    style={{ width: 150 }}
                    allowClear={false}
                    showToday={false}
                    showTime={false}
                    value={startTimeObj}
                    onChange={this.handleStartTimeChange}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                  />
                )}
                {showTimePicker && (
                  <div style={{ width: 150 }}>
                    <RcDatePicker
                      showTimeSelect
                      showTimeInput
                      timeIntervals={15}
                      showPopperArrow={false}
                      timeFormat={Defaults.DatePickerTime}
                      dateFormat={Defaults.DateTimeFormat1}
                      selected={utcObjToLocalDate(startTimeObj)}
                      customTimeInput={<TimeInput />}
                      onChange={(startTime) => {
                        this.setState({ startTimestamp: localDateToUtcObj(startTime).valueOf() });
                      }}
                    />
                  </div>
                )}
                <span className="light-label bold" style={{ width: 80, marginLeft: 24 }}>
                  {intl.formatMessage(appFieldsMessages.endDate)}:
                </span>
                {!showTimePicker && (
                  <DatePicker
                    style={{ width: 150 }}
                    allowClear={false}
                    showToday={false}
                    showTime={globalUpdate === 'LogHistoricalDataCollect' || globalUpdate === 'LogDataDeletion'}
                    value={endTimeObj}
                    onChange={this.handleEndTimeChange}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                  />
                )}
                {showTimePicker && (
                  <div style={{ width: 150 }}>
                    <RcDatePicker
                      showTimeSelect
                      showTimeInput
                      timeIntervals={15}
                      showPopperArrow={false}
                      timeFormat={Defaults.DatePickerTime}
                      dateFormat={Defaults.DateTimeFormat1}
                      selected={utcObjToLocalDate(endTimeObj)}
                      customTimeInput={<TimeInput />}
                      onChange={(endTime) => {
                        this.setState({ endTimestamp: localDateToUtcObj(endTime).valueOf() });
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {showProjectPicker && (
              <div className="flex-row flex-center-align" style={{ marginTop: 16 }}>
                <span className="light-label bold" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.project)}:
                </span>
                <div className="flex-grow">
                  <TreeSelect
                    className="no-count-num"
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    value={projectName}
                    onChange={this.handleProjectChange}
                    treeCheckable={isMultiple}
                    multiple={isMultiple}
                    autoClearSearchValue={false}
                    treeDefaultExpandAll
                    treeDefaultExpandedKeys={treeDefaultExpandedKeys}
                    treeData={projectListOption}
                    treeNodeLabelProp="name"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650, maxHeight: 400 }}
                    onSearch={(searchValue) => {
                      const filterTreeData = searchValue
                        ? this.getFilterTreeData(searchValue, this.localProjectListOption)
                        : this.localProjectListOption;
                      this.setState({ projectListOption: filterTreeData });
                    }}
                    dropdownRender={(menu) => {
                      const filterValue = [];
                      R.forEach((item) => {
                        filterValue.push(...R.map((project) => project.value, item.children));
                      }, projectListOption);
                      return (
                        <div>
                          {isMultiple && (
                            <div
                              className="flex-row"
                              style={{ padding: '5px 24px' }}
                              onMouseDown={(event) => event.preventDefault()}
                            >
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={R.difference(filterValue, projectName).length === 0}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const diff = R.difference(projectName, filterValue);
                                  this.handleProjectChange(checked ? [...diff, ...filterValue] : diff);
                                }}
                              />
                              Select all
                            </div>
                          )}
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            )}
            {showSystemPicker && (
              <div className="flex-row flex-center-align" style={{ marginTop: 16 }}>
                <span className="light-label bold" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.system)}:
                </span>
                <div className="flex-grow">
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    value={systemId}
                    optionFilterProp="label"
                    filterOption
                    onChange={this.handleSystemChange}
                    dropdownMatchSelectWidth={false}
                    mode={
                      [
                        'RerunHnowledgeBaseTraining',
                        'RerunSystemLevelIncidentPrediction',
                        'RerunKbPrediction',
                      ].includes(globalUpdate)
                        ? 'multiple'
                        : ''
                    }
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.systemId} label={item.systemName}>
                          {`${item.systemName}${item.owner ? ` (${item.owner})` : ''}`}
                        </Select.Option>
                      );
                    }, systemListOption || [])}
                  </Select>
                </div>
              </div>
            )}
            {globalUpdate === 'GlobalView' && (
              <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
                <span className="light-label bold" style={{ marginRight: 8 }}>
                  {intl.formatMessage(DashboardMessages.updateTotalMax)}:
                </span>
                <Switch
                  checked={updateTotalMax}
                  onChange={(checked, event) => {
                    this.setState({ updateTotalMax: checked });
                  }}
                />
              </div>
            )}
          </Spin>
        </Container>
      </Modal>
    );
  }
}

const GlobalCronSelectModal = injectIntl(GlobalCronSelectModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, projects, systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;

    return {
      location,
      loadStatus,
      systemsMap,
      projectList: R.filter((project) => project.status !== 'Deleting', projects || []),
      userInfo,
      credentials,
    };
  },
  { push, replace, loadProjectInfo },
)(GlobalCronSelectModal);
