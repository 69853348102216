import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import tinygradient from 'tinygradient';
import moment from 'moment';
import { get } from 'lodash';

import { Spin, message } from 'antd';
import { EChart } from '../../share';
import { OptionHtml } from '../components/OptionHtml';
import DashCellTitle from '../components/DashCellTitle';
import fetchGet from '../../../common/apis/fetchGet';
import { Defaults } from '../../../common/utils';
import getEndpoint from '../../../common/apis/getEndpoint';
import { getCacheData, setCacheData } from '../utils';
import { appMessages } from '../../../common/app/messages';
import BuildIndicateInfo from '../components/BuildIndicateInfo';
import DashCellDetail from '../components/DashCellDetail';

const pieChartStyle = {
  width: '40%',
  height: '90%',
};
const optionDetail = {};

export default function PatternsAnomalies(props: Object) {
  let echartsAxiosDebugger = true;

  const {
    intl,
    currentTheme,
    systemId,
    credentials,
    startTime,
    endTime,
    environmentId,
    globalInfo,
    isCloudCost,
    instanceDisplayNameMap,
    getPopUpPosition,
    widgetInfo,
  } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    option: {},
    barData: [],
    isLoading: false,
    chartData: [],
    insightDetail: [],
    showDetail: false,
    refresh: 0,
  });
  const echartRef = useRef(null);
  const { option, isLoading, chartData, showDetail, refresh } = state;

  const getOptionDetail = (rowData, detailKey, callback) => {
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    fetchGet(getEndpoint('pie-chart-detail'), {
      ...credentials,
      customerName: systemInfo?.ownerUserName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      instanceName: rowData.instanceName,
      dataType: rowData.dataType,
      patternId: rowData.patternId,
      metricName: rowData.dataType === 0 && rowData.supportInfo ? rowData.supportInfo : undefined,
    })
      .then((data) => {
        const { success, insightDetail } = data;
        if (success === undefined || success) {
          optionDetail[detailKey] = insightDetail;
          echartsAxiosDebugger = true;
        } else {
          echartsAxiosDebugger = true;
        }
        callback();
      })
      .catch((err) => {
        if (err?.code >= 500) {
          console.error(intl.formatMessage(appMessages.apiFaild));
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
        }
      });
  };

  const getPieOption = (data, gradientColorsList, { intl, typeName }) => {
    data = R.map((item) => ({ ...item, value: item.dataType === 0 ? 1 : item.count }), data);
    const gradient = tinygradient(['#043e7d', '#6abbf7']); // ['#9e2b0e', '#fcd189']
    const colorsRgb = gradient.rgb(data.length < 2 ? 2 : data.length);
    const colors = R.map((x) => {
      return x.toHexString();
    }, colorsRgb);
    data = R.sort((a, b) => b.value - a.value, data);
    const option = {
      color: colors,
      backgroundColor: 'transparent',
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'item',
        enterable: true,
        appendToBody: true,
        position: (pos, params, dom, rect, size) => {
          return getPopUpPosition(widgetInfo, pos, size);
        },
        formatter: (params, ticket, callback) => {
          const { data } = params;
          const detailKey = `${typeName}-${data.instanceName}-${data.patternId}-${data.count}`;
          if (!R.has(detailKey, optionDetail)) {
            if (echartsAxiosDebugger) {
              echartsAxiosDebugger = false;
              getOptionDetail(data, detailKey, () => {
                const newFormatter = OptionHtml({
                  data,
                  detailKey,
                  optionDetail,
                  intl,
                  currentTheme,
                  instanceDisplayNameMap,
                });
                callback(ticket, newFormatter);
                setState({ refresh: moment.utc().valueOf() });
              });
            }
          }
          if (optionDetail[detailKey]) {
            return OptionHtml({ data, detailKey, optionDetail, intl, currentTheme, instanceDisplayNameMap });
          } else {
            return ReactDOMServer.renderToStaticMarkup(<Spin />);
          }
        },
      },
      series: [
        {
          name: 'Detected (Top anomalies)',
          type: 'pie',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          itemStyle: {
            emphasis: {
              shadowBlur: 4,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            show: false,
            position: 'inner',
            formatter: (params) => {
              const { data, percent } = params;
              return percent > 5 ? data?.value : '';
            },
            color: 'white',
            fontWeight: 'bold',
            fontSize: 10,
          },
          emphasis: {
            disabled: false,
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          emptyCircleStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderColor: '#ccc',
          },
          data,
        },
      ],
    };
    return option;
  };

  const parseData = (data) => {
    let { anomalies } = data ? R.clone(data) : {};
    anomalies = R.map((item) => ({ ...item, value: item.count }), anomalies || []);
    setState({
      option: getPieOption(anomalies, ['#5d4157', '#a8caba'], { intl, typeName: 'anomalies' }),
      chartData: anomalies,
    });
  };

  const reloadData = (systemInfo) => {
    const params = {
      ...credentials,
      customerName: systemInfo?.ownerUserName,
      systemName: systemId,
      startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
      endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      limit: 20,
      sortKey: 'count',
      granularity: 'pattern',
    };
    const apiData = getCacheData(params);
    if (apiData) {
      parseData(apiData, isCloudCost);
      return;
    }
    if (!systemId) return;
    setState({ isLoading: true });
    fetchGet(getEndpoint('pie-chart-index'), R.clone(params))
      .then((data) => {
        parseData(data, isCloudCost);
        setCacheData(params, data);
        setState({ isLoading: false });
      })
      .catch((e) => {
        setState({ isLoading: false });
        console.error(e.message || String(e));
      });
  };

  useEffect(() => {
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo) {
      reloadData(systemInfo);
    } else {
      setState({ option: getPieOption([], [], ['#043e7d', '#6abbf7']) });
    }
  }, [systemId]);

  useEffect(() => {
    if (echartRef.current) {
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.setOption(option);
    }
  }, [currentTheme, refresh]);

  return (
    <Spin spinning={isLoading} wrapperClassName="spin-base overflow-x-hidden overflow-y-auto">
      <DashCellTitle
        title="Patterns"
        subTitle="Top anomalies"
        type="anomalies"
        fillListFlag={(chartData || []).length > 0}
        setShowDetail={(showDetail) => setState({ showDetail })}
      />
      <div className="flex-row flex-space-between" style={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>
        <EChart
          setRef={(chart) => {
            echartRef.current = chart;
          }}
          theme={currentTheme}
          option={option}
          className="chart-pie"
          style={pieChartStyle}
        />
        <BuildIndicateInfo
          intl={intl}
          list={chartData}
          type="anomalies"
          echartRef={echartRef?.current}
          chartData={option}
          optionDetail={optionDetail}
          echartsAxiosDebugger={echartsAxiosDebugger}
          setEchartsAxiosDebugger={(flag) => {
            echartsAxiosDebugger = flag;
          }}
          getOptionDetail={getOptionDetail}
          currentTheme={currentTheme}
          instanceDisplayNameMap={instanceDisplayNameMap}
        />
        {showDetail && (
          <DashCellDetail
            {...props}
            open={showDetail}
            title="Detected (Top anomalies)"
            list={chartData}
            onCancel={() => setState({ showDetail: false })}
            typeName="anomalies"
          />
        )}
      </div>
    </Spin>
  );
}
