import React from 'react';
import VLink from 'valuelink';
import { autobind } from 'core-decorators';

import { Input, Modal } from '../../../../lib/fui/react';
import { ifIn } from '../../../../common/utils';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  intl: Object,
  onClose: Function,
  webhookHeaderList: Array<Object>,
  saveWebhookHeaderList: Function,
};

class WebhookHeaderModal extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      headerKey: '',
      headerValue: '',
    };
  }

  componentDidMount() {}

  @autobind
  handleSubmit() {
    const { webhookHeaderList = [] } = this.props;
    const { headerKey, headerValue } = this.state;
    const newWebhookHeaderList = [...(webhookHeaderList || []), { key: headerKey, value: headerValue }];
    this.props.saveWebhookHeaderList(newWebhookHeaderList);
  }

  render() {
    const { intl, onClose, webhookHeaderList = [] } = this.props;
    const headerKeyLink = VLink.state(this, 'headerKey')
      .check((n) => Boolean(n), 'input header key')
      .check((n) => !ifIn(n, webhookHeaderList), 'the key already exists');
    const headerValueLink = VLink.state(this, 'headerValue').check((n) => Boolean(n), 'input header value');

    const hasError = headerKeyLink.error || headerValueLink.error;
    return (
      <Modal
        title={intl.formatMessage(settingsMessages.webhookHeaders)}
        width={650}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: hasError }}
      >
        <div className={`ui ${hasError ? 'error' : ''} form`} style={{ fontSize: 12 }}>
          <div className="input inline wide field flex-row flex-center-align">
            <label style={{ width: 120 }}>Header key:</label>
            <div style={{ width: 500 }}>
              <Input fullWidth valueLink={headerKeyLink} size="small" />
            </div>
          </div>
          <div className="input inline wide field flex-row flex-center-align">
            <label style={{ width: 120 }}></label>
            <div style={{ width: 500 }}>
              If the key is &quot;signature&quot;, the value will be used to generate a signature security header with
              HmacSHA256 algorithm.
            </div>
          </div>
          <div className="input inline wide field flex-row flex-center-align">
            <label style={{ width: 120 }}>Header value:</label>
            <div style={{ width: 500 }}>
              <Input fullWidth valueLink={headerValueLink} size="small" />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default WebhookHeaderModal;
