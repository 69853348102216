/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { get, isNumber } from 'lodash';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Button, Spin, message, Alert, Form, Input } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Modal, Container, Table, Column, AutoSizer, Popover } from '../../../lib/fui/react';
// import { Defaults } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';

import AddStatusMetricModal from './AddStatusMetricModal';
import { settingsMenusMessages } from '../../../common/settings/messages';
import fetchGet from '../../../common/apis/fetchGet';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  activeIncident: Object,
  // eslint-disable-next-line
  onClose: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  replace: Function,
  updateLastActionInfo: Function,
  cacheProjectMap: Object,
  cacheMetricMap: Object,
  changeCacheProjectMap: Function,
  changeCacheMetricMap: Function,
};

class EditStatusPageModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { activeIncident } = props;
    this.state = {
      isSubmitting: false,
      company: get(activeIncident, 'company', ''),
      eventList: get(activeIncident, 'metrics', []),

      activeItemIndex: undefined,
      activeItem: undefined,
      showAddStatusMetricModal: false,
      projectInstanceDisplayNameMap: {},
    };
  }

  componentDidMount() {}

  @autobind
  getInstanceDisplayNameData() {
    const { credentials } = this.props;
    const { eventList } = this.state;
    if ((eventList || []).length > 0) {
      this.setState({ isSubmitting: true });
      const projectInstanceDisplayNameMap = {};
      fetchGet(getEndpoint('instance-display-name'), {
        ...credentials,
        instanceDisplayNameRequestList: JSON.stringify(
          R.map((p) => ({ projectName: p?.projectName, customerName: p?.owner }), eventList || []),
        ),
      })
        .then((d1) => {
          R.forEach((item) => {
            const instanceDisplayNameMap = {};
            const [pInfo, iList] = item || [];
            const { projectName, customerName } = pInfo || {};
            R.forEach((instanceInfo) => {
              const { instanceSet, instanceDisplayName } = instanceInfo || {};
              R.forEach((instance) => {
                instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
                instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
              }, instanceSet || []);
            }, iList || []);
            projectInstanceDisplayNameMap[projectName] = instanceDisplayNameMap;
          }, d1 || []);
          this.setState({ projectInstanceDisplayNameMap, isSubmitting: false });
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          message.error(err.message || String(err));
        });
    }
  }

  @autobind
  handleAddNewClick() {
    this.setState({ showAddStatusMetricModal: true, activeItemIndex: undefined, activeItem: undefined });
  }

  @autobind
  handleSubmit() {
    const { intl, credentials, activeIncident, onClose } = this.props;
    const { company, eventList } = this.state;

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('statuspage'), {
      ...credentials,
      id: get(activeIncident, 'id'),
      company,
      metrics: JSON.stringify(eventList),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmitting: false });
          onClose(true);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmitting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false, errMsg: String(err) });
      });
  }

  @autobind
  removeRenderer({ rowIndex, rowData }) {
    const { intl } = this.props;

    return (
      <div className="flex-row">
        <Button
          size="small"
          className="button-color-grey"
          style={{ marginRight: 8 }}
          onClick={(event) => {
            event.stopPropagation();
            this.setState({
              showAddStatusMetricModal: true,
              activeItemIndex: rowIndex,
              activeItem: rowData,
            });
          }}
        >
          {intl.formatMessage(appButtonsMessages.edit)}
        </Button>
        <Button
          size="small"
          className="button-color-grey"
          onClick={(event) => {
            event.stopPropagation();
            this.handleRemove(rowIndex);
          }}
        >
          {intl.formatMessage(appButtonsMessages.remove)}
        </Button>
      </div>
    );
  }

  @autobind
  handleRemove(rowIndex) {
    const { eventList } = this.state;
    const newEventList = R.remove(rowIndex, 1, eventList);
    this.setState({ eventList: newEventList });
  }

  @autobind
  handleSaveStatusMetric({ activeItemIndex, ...rest }) {
    const { eventList } = this.state;
    const newData = { ...rest };
    if (isNumber(activeItemIndex)) {
      this.setState({
        showAddStatusMetricModal: false,
        eventList: update(eventList, { [activeItemIndex]: { $set: newData } }),
      });
    } else {
      this.setState({ showAddStatusMetricModal: false, eventList: [newData, ...eventList] });
    }
  }

  @autobind
  renderProjectName({ rowData }) {
    const { credentials, projectDisplayMap } = this.props;
    const { projectName, owner } = rowData;
    const projectNameReal = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
    const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
    return projectDisplayName;
  }

  @autobind
  renderInstanceName({ rowData }) {
    const { projectInstanceDisplayNameMap } = this.state;
    const instanceDisplayNameMap = projectInstanceDisplayNameMap[rowData?.projectName];
    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, rowData.instance, {
      pn: rowData?.projectName,
      owner: rowData?.owner,
    });
    return (
      <Popover content={instanceStr} mouseEnterDelay={0.3}>
        <div className="full-width hidden-line-with-ellipsis">{instanceStr}</div>
      </Popover>
    );
  }

  render() {
    const { intl, onClose, activeIncident } = this.props;
    const { isSubmitting, errMsg, company, eventList, activeItemIndex, activeItem } = this.state;
    const { projectInstanceDisplayNameMap } = this.state;

    const hasError = !company || eventList.length === 0;
    return (
      <Modal
        width={1200}
        title={intl.formatMessage(settingsMenusMessages.statusPage)}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ loading: isSubmitting, disabled: hasError }}
      >
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.companyName)}
            validateStatus={!company ? 'error' : 'success'}
            help={!company ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input
              disabled={Boolean(activeIncident)}
              value={company}
              onChange={(e) => this.setState({ company: e.target.value })}
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage(appFieldsMessages.metric)} required>
            <Container className="flex-col" style={{ height: 350 }}>
              <div style={{ marginBottom: 12 }}>
                <Button size="small" type="primary" onClick={this.handleAddNewClick}>
                  Add New
                </Button>
              </div>
              <div className="flex-grow flex-min-height">
                <Spin spinning={isSubmitting} wrapperClassName="full-height spin-full-height">
                  <AutoSizer>
                    {({ height, width }) => (
                      <Table
                        className="with-border"
                        width={width}
                        height={height}
                        headerHeight={40}
                        rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                        rowHeight={40}
                        rowCount={eventList.length}
                        rowGetter={({ index }) => eventList[index]}
                      >
                        <Column
                          width={100}
                          label="Project name"
                          dataKey="projectName"
                          flexGrow={1}
                          cellRenderer={this.renderProjectName}
                        />
                        <Column width={80} label="Owner" dataKey="owner" />
                        <Column
                          width={100}
                          label="Instance name"
                          dataKey="instance"
                          flexGrow={1}
                          cellRenderer={this.renderInstanceName}
                        />
                        <Column width={100} label="Metric name" dataKey="metric" flexGrow={1} />
                        <Column width={100} label="Metric display name" dataKey="metricDisplayName" flexGrow={1} />
                        <Column width={100} label="Unit" dataKey="unit" />
                        <Column width={100} label="Alert value" dataKey="alertValue" />
                        <Column
                          width={140}
                          label=""
                          dataKey="id"
                          className="text-right"
                          cellRenderer={this.removeRenderer}
                        />
                      </Table>
                    )}
                  </AutoSizer>
                </Spin>
              </div>
            </Container>
          </Form.Item>

          {errMsg && (
            <Form.Item wrapperCol={{ span: 20, offset: 4 }} style={{ margin: 0 }}>
              <Alert message={errMsg} type="error" showIcon />
            </Form.Item>
          )}
        </Form>

        {this.state.showAddStatusMetricModal && (
          <AddStatusMetricModal
            activeItemIndex={activeItemIndex}
            activeItem={activeItem}
            onSubmit={this.handleSaveStatusMetric}
            onClose={() => this.setState({ showAddStatusMetricModal: false })}
            projectInstanceDisplayNameMap={projectInstanceDisplayNameMap}
            handleChangeDisplayName={(displayNameMap) => {
              this.setState({ projectInstanceDisplayNameMap: { ...projectInstanceDisplayNameMap, ...displayNameMap } });
            }}
            cacheProjectMap={this.props.cacheProjectMap}
            changeCacheProjectMap={this.props.changeCacheProjectMap}
            cacheMetricMap={this.props.cacheMetricMap}
            changeCacheMetricMap={this.props.changeCacheMetricMap}
          />
        )}
      </Modal>
    );
  }
}

const EditStatusPageModal = injectIntl(EditStatusPageModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { systemsMap, projectDisplayMap } = state.app;
    const { credentials } = state.auth;
    return { location, systemsMap, projectDisplayMap, credentials };
  },
  { replace, updateLastActionInfo },
)(EditStatusPageModal);
