import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { connect } from 'react-redux';
import { State } from '../../common/types';

import { Root } from '../../protos/js/Root_pb';
import { Ping } from '../../protos/js/Ping_pb';
import { Auth } from '../../protos/js/Auth_pb';

import fetchPost from '../../common/apis/fetchPost';
import getEndpoint from '../../common/apis/getEndpoint';

const WebSocketContext = createContext(null);
const TopicSubscriptions = {
  // [Root.InnerMessageCase.LOGTAIL]: null,
};

function WebSocketMiddleware({ children, ...props }: Object) {
  const [socketUrl, setSocketUrl] = useState(null);
  const [wsUserInfo, setWsUserInfo] = useState(null);

  const parseData = (data) => {
    let events = [];
    try {
      events = JSON.parse(data);
    } catch (error) {
      console.err(error.message || String(error));
    }
    return events;
  };

  const { sendMessage, readyState } = useWebSocket(socketUrl, {
    onMessage: (event) => {
      const fileReader = new FileReader();
      fileReader.onload = (fevent) => {
        const root2 = Root.deserializeBinary(fevent.target.result);
        let subscriber = TopicSubscriptions[root2.getInnerMessageCase()];

        // getLogtail key 需要加上 Instancename
        if (root2.getInnerMessageCase() === Root.InnerMessageCase.LOGTAIL) {
          subscriber =
            TopicSubscriptions[
              `${root2.getInnerMessageCase()}-${root2.getLogtail().getProjectname()}-${root2
                .getLogtail()
                .getInstancename()}`
            ];
        }

        if (subscriber) {
          switch (root2.getInnerMessageCase()) {
            case Root.InnerMessageCase.PING:
              // console.log(root2.getPing().getPingnum());
              break;
            case Root.InnerMessageCase.LOGTAIL:
              subscriber(parseData(root2.getLogtail().getData()));
              break;
            default:
              break;
          }
        }
      };
      fileReader.readAsArrayBuffer(event.data);
    },
    onOpen: (event) => {
      let num = 0;
      setInterval(() => {
        const ping = new Ping();
        num += 1;
        ping.setPingnum(num);
        const root = new Root();
        root.setPing(ping);
        // Serializes to a UInt8Array.
        const bytes = root.serializeBinary();
        // var message2 = Ping.Ping.deserializeBinary(bytes);
        sendMessage(bytes);
      }, 10000);

      // send auth
      const root = new Root();
      const auth = new Auth();
      auth.setUsername(wsUserInfo?.authUserName);
      auth.setToken(wsUserInfo?.authToken);
      root.setAuth(auth);
      const bytes = root.serializeBinary();
      sendMessage(bytes);
    },
  });

  const getWebSocketUrl = (cancel) => {
    if (cancel) return;
    const { credentials, userInfo } = props;
    fetchPost(getEndpoint('wss-ops'), {
      ...credentials,
      ops: 'startWss',
      UserName: userInfo?.userName,
    })
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          const { authToken, authUserName, wsUrl } = data || {};
          setSocketUrl(wsUrl);
          setWsUserInfo({ authToken, authUserName });
        } else {
          console.error(msg);
        }
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  };

  useEffect(() => {
    let cancel = false;
    getWebSocketUrl(cancel);
    return () => {
      cancel = true;
    };
  }, []);

  const subscribeTopic = useCallback(
    (topic, request, subscriber) => {
      // todo: check the topic exists
      TopicSubscriptions[topic] = subscriber;
      const bytes = request.serializeBinary();
      sendMessage(bytes);
    },
    [readyState],
  );

  const unSubscribeTopic = useCallback(
    (topic, request) => {
      TopicSubscriptions[topic] = null;
      const bytes = request.serializeBinary();
      sendMessage(bytes);
    },
    [readyState],
  );

  const dataInfoValue = useMemo(() => ({ subscribeTopic, unSubscribeTopic }), [subscribeTopic, unSubscribeTopic]);

  return <WebSocketContext.Provider value={dataInfoValue}>{children}</WebSocketContext.Provider>;
}

export default connect((state: State) => {
  const { credentials, userInfo } = state.auth;
  return { credentials, userInfo };
})(WebSocketMiddleware);

export { WebSocketContext };
