/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import VLink from 'valuelink';
import { get, isNumber, isString, isInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { Popover } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Container, Select, MultiGrid, SortDirection, DataFrame, AutoSizer } from '../../../lib/fui/react';
import { defaultComparer } from '../../../lib/fui/utils';
import { Defaults } from '../../../common/utils';

type Props = {
  width: Number,
  height: Number,
  project: Object,
  queryResult: Object,
};

class NormalBehaviorTable extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.gridOffset = 40;
    this.state = {
      day: null,
      componentName: null,
      instanceName: [],
      sortBy: 'timestamp',
      sortDirection: SortDirection.ASC,
      metricList: [],
    };

    this.convertData(props.queryResult, {}, props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps.queryResult, {}, nextProps);
    }
  }

  @autobind
  convertData(queryResult, params = {}, props = {}) {
    const { day, componentName, instanceName, metricList } = params;
    const { project } = props;

    // The data is converted to object array, and then to grid.
    const dataList = [];
    let metricNames = [];
    const dayList = [];
    const componentNameList = [];
    const instanceNameList = [];
    const cloudType = get(project, 'cloudType');

    R.forEach((d) => {
      const { day: dname, behaviors } = d;
      const timestamp = moment(dname, 'YYYYMMDD').valueOf();
      dayList.push(timestamp);
      if (!day || timestamp === day) {
        if (behaviors) {
          R.forEach((b) => {
            const aname = b['app-name'];
            const iname = b['instance-id']; // instance id must exists
            const itype = b['instance-type'];
            const row = { timestamp, componentName: aname, instanceName: iname, instanceType: itype };
            let addRow = false;

            componentNameList.push(aname);
            if (!componentName || (componentName && componentName === aname)) {
              instanceNameList.push(iname);
            }

            if (componentName) {
              if (instanceName && instanceName.length > 0) {
                addRow = instanceName.indexOf(iname) >= 0;
              } else {
                addRow = componentName === aname;
              }
            } else if (instanceName && instanceName.length > 0) {
              addRow = instanceName.indexOf(iname) >= 0;
            } else {
              addRow = true;
            }

            if (addRow) {
              const statAvg = get(b, ['stat', 'avg'], {});
              R.forEachObjIndexed((val, k) => {
                const name = k;
                metricNames.push(name);
                row[name] = `${parseFloat(val).toFixed(2)}+`;
              }, statAvg);

              const statStd = get(b, ['stat', 'std'], {});
              R.forEachObjIndexed((val, k) => {
                const name = k;
                metricNames.push(name);
                row[name] += `/-${parseFloat(val).toFixed(2)}`;
              }, statStd);

              dataList.push(row);
            }
          }, behaviors);
        }
      }
    }, queryResult);

    // Remove duplicate name and sort it
    metricNames = R.sort((a, b) => a.localeCompare(b), R.uniq(metricNames));
    const otherColumns = (metricList || []).length > 0 ? metricList : metricNames;

    const fixedColmns = ['timestamp'];
    if (componentNameList.length > 0) {
      fixedColmns.push('componentName');
    }
    fixedColmns.push('instanceName');
    if (cloudType === 'AWS' || cloudType === 'CloudWatch') {
      fixedColmns.push('instanceType');
    }
    const allColumns = [...fixedColmns, ...otherColumns];
    let df = new DataFrame(dataList, allColumns);
    this.dataframe = df;

    df = df.sortBy('timestamp', false);
    this.gridData = df.toArray();
    this.gridDataHeaders = allColumns;
    this.gridFixedHeaders = fixedColmns;
    this.gridNormalHeaders = otherColumns;

    this.dayListOption = R.map(
      (d) => ({ label: moment(d).format(Defaults.DateFormat), value: d }),
      R.filter((x) => !!x, R.uniq(dayList)),
    );
    this.componentNameListOptions = R.map(
      (a) => ({ label: a, value: a }),
      R.sort((a, b) => a.localeCompare(b), R.uniq(R.filter((x) => !!x, componentNameList))),
    );
    this.instanceNameListOptions = R.map(
      (a) => ({ label: a, value: a }),
      R.sort((a, b) => a.localeCompare(b), R.uniq(R.filter((x) => !!x, instanceNameList))),
    );
    this.metricListOptions = R.map((m) => ({ label: m, value: m }), metricNames);

    if (this.grid) {
      this.grid.forceUpdate();
    }
  }

  @autobind
  handleDayChange(day) {
    const { metricList } = this.state;
    this.convertData(this.props.queryResult, { day, componentName: null, instanceName: null, metricList }, this.props);
    this.setState({ componentName: null, instanceName: null });
  }

  @autobind
  handleAppNameChange(componentName) {
    const { day, metricList } = this.state;
    this.convertData(this.props.queryResult, { day, componentName, instanceName: null, metricList }, this.props);
    this.setState({ instanceName: null });
  }

  @autobind
  handleInstanceNameChange(instanceName) {
    const { day, metricList, componentName } = this.state;
    this.convertData(this.props.queryResult, { day, componentName, instanceName, metricList }, this.props);
  }

  @autobind
  handleMetricListChange(val) {
    const { day, componentName, instanceName } = this.state;
    this.convertData(this.props.queryResult, { day, componentName, instanceName, metricList: val }, this.props);
  }

  @autobind
  getColumnWidth({ index }) {
    const name = this.gridDataHeaders[index];
    // date
    if (index === 0) return 100;

    if (index < this.gridFixedHeaders.length) {
      if (name === 'componentName' || name === 'instanceName') return 180;
      return 100;
    }
    return 180;
  }

  @autobind
  handleColumnHeaderClick(colname) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();

      let { sortBy, sortDirection } = this.state;
      if (sortBy === colname) {
        sortDirection = sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
      } else {
        sortBy = colname;
        sortDirection = SortDirection.ASC;
      }

      if (this.dataframe && this.grid) {
        const df = this.dataframe.sortBy(sortBy, sortDirection === SortDirection.DESC, (a, b) => {
          // If it's special avg+/std format, compare with avg
          if (isString(a) && isString(b)) {
            const aidx = (a || '').indexOf('+/-');
            const bidx = (b || '').indexOf('+/-');
            if (aidx > 0 && bidx > 0) {
              const aavg = parseFloat(a.substring(0, aidx));
              const bavg = parseFloat(b.substring(0, bidx));
              return defaultComparer(aavg, bavg);
            }
          }

          return defaultComparer(a, b);
        });
        this.gridData = df.toArray();
        this.grid.forceUpdate();
      }
      this.setState({ sortBy, sortDirection });
    };
  }

  @autobind
  cellRenderer({ columnIndex, key, rowIndex, style }) {
    // Grid header
    if (rowIndex === 0) {
      const colname = this.gridDataHeaders[columnIndex] || '';
      let colDisplayName = colname;
      if (colname === 'componentName') {
        colDisplayName = 'Component';
      } else if (colname === 'instanceName') {
        colDisplayName = 'Instance';
      } else if (colname === 'timestamp') {
        colDisplayName = 'Date';
      } else if (colname === 'instanceType') {
        colDisplayName = 'Instance Type';
      }
      const { sortBy, sortDirection } = this.state;
      const sortIcon = () => {
        if (sortBy !== colname) {
          return null;
        }
        if (sortDirection === 'ASC') {
          return <CaretUpOutlined />;
        }
        return <CaretDownOutlined />;
      };
      return (
        <div className="header cell" key={key} style={style} onClick={this.handleColumnHeaderClick(colname)}>
          <Popover title={null} content={colDisplayName} placement="right" mouseEnterDelay={0.3}>
            <div className="text">{colDisplayName}</div>
          </Popover>
          {sortIcon()}
        </div>
      );
    }
    // Grid data
    const dataRowIdx = rowIndex - 1;
    let content = get(this.gridData, `[${dataRowIdx}][${columnIndex}]`, '');

    if (columnIndex === 0) {
      // timestamp as date
      content = moment(content).format(Defaults.DateFormat);
    } else if (isNumber(content) && !isInteger(content)) {
      content = content.toFixed(4);
    } else if (isString(content)) {
      content = (
        <Popover title={null} content={content} placement="right" mouseEnterDelay={0.3}>
          <div className="text">{content}</div>
        </Popover>
      );
    }
    return (
      <div className="cell" key={key} style={style}>
        {content}
      </div>
    );
  }

  render() {
    const { width, height } = this.props;
    const dayLink = VLink.state(this, 'day').onChange(this.handleDayChange);
    const componentNameLink = VLink.state(this, 'componentName').onChange(this.handleAppNameChange);
    const instanceNameLink = VLink.state(this, 'instanceName').onChange(this.handleInstanceNameChange);
    const metricListLink = VLink.state(this, 'metricList').onChange(this.handleMetricListChange);

    return (
      <Container className="flex-grow flex-col" style={{ width, height }}>
        <Container className="toolbar" style={{ zIndex: 301 }}>
          <Container className="section">
            <span className="label">Day</span>
            <Select options={this.dayListOption} valueLink={dayLink} clearable style={{ width: 120 }} />
            <span className="label">Component:</span>
            <Select
              options={this.componentNameListOptions}
              valueLink={componentNameLink}
              clearable
              style={{ width: 180 }}
            />
            <span className="label">Instance:</span>
            <Select
              options={this.instanceNameListOptions}
              multi
              autosize
              valueLink={instanceNameLink}
              clearable
              style={{ minWidth: 180, maxWidth: 360 }}
            />
            <span className="label">Metric:</span>
            <Select
              options={this.metricListOptions}
              multi
              autosize
              valueLink={metricListLink}
              clearable
              style={{ minWidth: 180 }}
            />
          </Container>
        </Container>
        <div className="flex-grow">
          <AutoSizer>
            {({ width, height }) => (
              <MultiGrid
                ref={(c) => (this.grid = c)}
                width={width}
                height={height}
                columnWidth={this.getColumnWidth}
                rowHeight={({ index }) => (index === 0 ? 54 : 40)}
                columnCount={this.gridDataHeaders.length}
                rowCount={this.gridData.length + 1}
                cellRenderer={this.cellRenderer}
                enableFixedColumnScroll
                enableFixedRowScroll
                fixedColumnCount={this.gridFixedHeaders.length}
                fixedRowCount={1}
                style={{ border: '1px solid rgba(34, 36, 38, 0.15)' }}
                styleBottomLeftGrid={{ borderRight: '2px solid #aaa', backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
                styleTopLeftGrid={{
                  borderBottom: '2px solid #aaa',
                  borderRight: '2px solid #aaa',
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                }}
                styleTopRightGrid={{
                  borderBottom: '2px solid #aaa',
                }}
              />
            )}
          </AutoSizer>
        </div>
      </Container>
    );
  }
}

export default NormalBehaviorTable;
