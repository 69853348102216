import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Spin, message, Alert, Button, Input } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, SaveOutlined } from '@ant-design/icons';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { AutoSizer, Container, Table, Column, Popover, SortDirection } from '../../../../lib/fui/react';

import { settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appMessages } from '../../../../common/app/messages';
import { queryFieldMessages } from '../../../../common/query/messages';

type Props = {
  projectName: String,
  refreshTime: Number,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class AutoScalingConfigurationPage extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isCheckedAll: true,
      isSubmitting: false,

      eventList: [],

      sortBy: null,
      sortDirection: null,
      podNameFilter: '',
      podNameSearch: '',
    };

    this.diffEventList = [];

    this.checkboxCellRender = ({ dataKey, rowData, cellData }) => (
      <input
        className="fui input"
        type="checkbox"
        checked={cellData || false}
        onChange={this.handleInputChanged(rowData, dataKey)}
      />
    );

    this.cmp = (a, b) => {
      return a.podName === b.podName && a.checked === b.checked;
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.projectName !== nextProps.projectName || this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
    let { eventList } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        eventList = R.sortWith([R.ascend(R.prop(sortBy))])(eventList);
        if (sortDirection === SortDirection.DESC) {
          eventList = R.sortWith([R.descend(R.prop(sortBy))])(eventList);
        }
        this.setState({ eventList });
      }
    }
  }

  @autobind
  reloadData(props) {
    const { intl, projectName, credentials } = props;
    this.setState({ isLoading: true });
    fetchGet(getEndpoint('K8SPodsFilterServlet'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        if (data) {
          const { allDeployments, savedDeployments } = data;
          const eventList = R.map((item) => {
            return {
              podName: item,
              checked: R.includes(item, savedDeployments || []),
            };
          }, allDeployments || []);
          const isCheckedAll = eventList.length > 0 && !R.find((m) => !m.checked, eventList);
          this.diffEventList = R.clone(eventList);
          this.setState({ isLoading: false, eventList, isCheckedAll });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, eventList: [] });
      });
  }

  @autobind
  renderDeploymentName({ rowData }) {
    const { podName } = rowData;
    return (
      <Popover title={null} content={<span>{podName}</span>} placement="right" mouseEnterDelay={0.3}>
        <span>{podName}</span>
      </Popover>
    );
  }

  @autobind
  checkAllHeaderRender(fieldName) {
    const { intl } = this.props;
    return () => {
      return (
        <div className="flex-row flex-center-align flex-end-justify">
          <span style={{ fontSize: 13, lineHeight: '13px' }}>
            {intl.formatMessage(settingsMessages.enableAutoScaling)}
          </span>
          <input
            className="fui input"
            type="checkbox"
            style={{ minWidth: 14 }}
            checked={this.state[fieldName]}
            onChange={this.handleIsAllChecked(fieldName)}
          />
        </div>
      );
    };
  }

  @autobind
  handleIsAllChecked(fieldName) {
    return (e) => {
      const { podNameSearch } = this.state;
      let { eventList } = this.state;
      const { checked } = e.target;
      if (podNameSearch) {
        eventList = R.filter((event) => R.toLower(event.podName).includes(R.toLower(podNameSearch)), eventList);
      }
      R.forEach((item) => {
        item.checked = checked;
      }, eventList);
      this.setState({ [fieldName]: checked });
    };
  }

  @autobind
  handleInputChanged(rowData, dataKey) {
    return (e) => {
      const { podNameSearch } = this.state;
      let { eventList } = this.state;
      const { target } = e;
      const newVal = target.checked;
      rowData[dataKey] = newVal;

      if (podNameSearch) {
        eventList = R.filter((event) => R.toLower(event.podName).includes(R.toLower(podNameSearch)), eventList);
      }
      const isCheckedAll = eventList.length > 0 && !R.find((m) => !m.checked, eventList);
      this.setState({ isCheckedAll });
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  deploymentNamedeploymentName() {
    const { intl, credentials, projectName } = this.props;
    const { eventList } = this.state;
    this.setState({ isSubmitting: true });
    const savedDeployments = R.map(
      (key) => key.podName,
      R.filter((item) => item.checked, eventList),
    );
    fetchPost(getEndpoint('K8SPodsFilterServlet'), {
      ...credentials,
      projectName,
      savedDeployments: JSON.stringify(savedDeployments),
    })
      .then((data) => {
        const { success } = data;
        if (success) {
          this.setState(
            { isSubmitting: false, sortBy: null, sortDirection: null, podNameFilter: '', podNameSearch: '' },
            () => {
              this.reloadData(this.props);
            },
          );
        } else {
          this.setState({ isSubmitting: false });
          message.error(intl.formatMessage(appMessages.apiFaild));
        }
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  }

  @autobind
  sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  handlePodNameSearchChange(podNameSearch) {
    let { eventList, isCheckedAll } = this.state;
    this.setState({ podNameSearch }, () => {
      eventList = R.filter((event) => R.toLower(event.podName).includes(R.toLower(podNameSearch)), eventList);
      isCheckedAll = eventList.length > 0 && !R.find((m) => !m.checked, eventList);
      this.setState({ isCheckedAll });
    });
  }

  @autobind
  handlePodNameChange(e) {
    const { eventList } = this.state;
    let { isCheckedAll } = this.state;
    const { value } = e.target;
    if (!value && e.type !== 'change') {
      isCheckedAll = eventList.length > 0 && !R.find((m) => !m.checked, eventList);
    }
    this.setState({ podNameFilter: value, isCheckedAll });
  }

  render() {
    const { intl } = this.props;
    const { isLoading, isSubmitting, sortBy, sortDirection, podNameFilter, podNameSearch } = this.state;
    let { eventList } = this.state;

    if (podNameSearch) {
      eventList = R.filter((event) => R.toLower(event.podName).includes(R.toLower(podNameSearch)), eventList);
    }

    const haseventList = eventList.length > 0 || podNameSearch;
    const diff = R.differenceWith(this.cmp, eventList, this.diffEventList);
    const hasError = diff.length === 0;
    return (
      <Container fullHeight className="full-width full-height flex-col flex-min-height">
        {haseventList && (
          <Input.Search
            size="small"
            placeholder={intl.formatMessage(settingsMessages.podName)}
            style={{ width: 200, marginBottom: 10 }}
            value={podNameFilter}
            allowClear
            enterButton
            onSearch={(value) => this.handlePodNameSearchChange(value)}
            onChange={(e) => this.handlePodNameChange(e)}
          />
        )}
        <Container className="flex-grow flex-min-height">
          <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
            {!haseventList && (
              <Alert
                message={intl.formatMessage(queryFieldMessages.noResult)}
                type="error"
                showIcon
                style={{ marginBottom: 8, width: '100%', height: 30 }}
              />
            )}
            {haseventList && (
              <div className="flex-grow flex-min-height">
                <AutoSizer>
                  {({ width, height }) => (
                    <Table
                      className="with-border"
                      width={width}
                      height={height}
                      headerHeight={40}
                      rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                      rowHeight={50}
                      rowCount={eventList.length}
                      rowGetter={({ index }) => eventList[index]}
                      ref={(c) => {
                        this.table = c;
                      }}
                      sort={this.sortTable}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                    >
                      <Column
                        width={200}
                        flexGrow={1}
                        dataKey="podName"
                        label={intl.formatMessage(settingsMessages.podName)}
                        cellRenderer={this.renderDeploymentName}
                        headerRenderer={this.headerRenderer}
                      />
                      <Column
                        width={190}
                        dataKey="checked"
                        className="text-right"
                        headerClassName="text-right"
                        headerRenderer={this.checkAllHeaderRender('isCheckedAll')}
                        cellRenderer={this.checkboxCellRender}
                        disableSort
                      />
                    </Table>
                  )}
                </AutoSizer>
              </div>
            )}
          </Spin>
        </Container>
        {haseventList && (
          <Container className="flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              icon={<SaveOutlined />}
              style={{ marginLeft: 8 }}
              loading={isSubmitting}
              disabled={hasError || isLoading}
              onClick={this.deploymentNamedeploymentName}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </Container>
    );
  }
}

const AutoScalingConfiguration = injectIntl(AutoScalingConfigurationPage);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials } = state.auth;

    return { location, loadStatus, credentials };
  },
  {
    updateLastActionInfo,
  },
)(AutoScalingConfiguration);
