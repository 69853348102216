/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get, round, isNumber } from 'lodash';
import VLink from 'valuelink';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Progress, Button, Popover } from 'antd';

import { State } from '../../common/types';
import { BaseUrls } from '../app/Constants';
import { appFieldsMessages, appButtonsMessages } from '../../common/app/messages';
import { DashboardMessages } from '../../common/dashboard/messages';
import { capacityPlanningMessages } from '../../common/metric/messages';
import {
  parseLocation,
  getLoadStatus,
  buildLocation,
  pickNotNil,
  Defaults,
  ifIn,
  GlobalParse,
  buildUrl,
} from '../../common/utils';
import { Defaults as DefaultsApp } from '../../common/app';
import { Container, Select, DatePicker } from '../../lib/fui/react';
import { createLoadAction, loadProjectInfo } from '../../common/app/actions';
import { ActionTypes } from '../../common/metric/actions';
import { EChart } from '../share';

import CapacityPlanningTable from './CapacityPlanningTable';

type Props = {
  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  loadProjectInfo: Function,
  projects: Array<Object>,
  credentials: Object,

  capacityPlanningCalendarInfo: Object,
  capacityPlanningCalendarRealInfo: Object,
  anomalyInfo: Object,
};

class CapacityPlanningCalendarCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'capacity_planning_calendar_loader';
    this.dataDetailsLoader = 'capacity_planning_loader';
    this.predictionInterval = 7;
    this.capacityInfo = [];
    this.capacityRealInfo = [];
    this.chartOption = {};

    this.metricList = [];
    this.selectMetricOptions = [];
    this.eventList = [];

    this.selectComponentOptions = [];
    this.bottleneckComponents = [];

    this.state = {
      timeChange: false,
      selectMetric: null,

      selectComponent: null,
      selectMetrics: [],
      isContainerProject: false,
    };
    this.headerBorder = '1px solid white';
  }

  componentDidMount() {
    if (!this.applyParamsAndRedirect(this.props)) {
      this.reloadData(this.props);
      this.reloadDataDetails(this.props);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextParams = parseLocation(nextProps.location);
    const params = parseLocation(this.props.location);
    if (
      this.props.projects !== nextProps.projects ||
      nextParams.projectName !== params.projectName ||
      nextParams.instanceGroup !== params.instanceGroup ||
      nextParams.startTimestamp !== params.startTimestamp ||
      nextParams.endTimestamp !== params.endTimestamp ||
      nextParams.componentsStr !== params.componentsStr
    ) {
      if (!this.applyParamsAndRedirect(nextProps)) {
        this.reloadData(nextProps);
        this.reloadDataDetails(nextProps);
      }
    } else if (
      nextParams.dateTimestamp !== params.dateTimestamp ||
      nextParams.isRealCapacityPlanning !== params.isRealCapacityPlanning
    ) {
      this.reloadDataDetails(nextProps);
    }

    if (
      this.props.capacityPlanningCalendarInfo !== nextProps.capacityPlanningCalendarInfo ||
      this.props.capacityPlanningCalendarRealInfo !== nextProps.capacityPlanningCalendarRealInfo
    ) {
      this.parseData(nextProps);
    } else if (this.props.anomalyInfo !== nextProps.anomalyInfo) {
      this.parseDataDetails(nextProps);
    }
  }
  @autobind
  applyParamsAndRedirect(props) {
    const { location, replace, projects } = props;
    const params = parseLocation(location);

    const {
      customerName,
      environmentId,
      startTime,
      endTime,
      systemId,
      dateTimestamp,
      componentsStr,
      isRealCapacityPlanning,
    } = params;
    let { projectName, instanceGroup, startTimestamp, endTimestamp } = params;
    let redirect = false;

    if (!projectName && projects.length > 0) {
      projectName = R.filter((p) => !p.isLog, projects)[0].projectName;
    }
    instanceGroup = instanceGroup || Defaults.InstanceGroup;
    if (!startTimestamp || !endTimestamp) {
      startTimestamp = moment.utc().startOf('day').valueOf();
      endTimestamp = moment.utc().endOf('day').valueOf();
    }

    // Change number to string to avoid false comparation
    const newParams = pickNotNil({
      customerName,
      environmentId,
      startTime,
      endTime,
      systemId,

      projectName,
      instanceGroup,
      dateTimestamp,
      startTimestamp,
      endTimestamp,
      componentsStr,
      isRealCapacityPlanning,
    });

    if (!R.equals(newParams, params)) {
      redirect = true;
      replace(buildLocation(location.pathname, {}, newParams));
    }

    return redirect;
  }
  @autobind
  reloadData(props, force = false) {
    const { location, createLoadAction, loadProjectInfo, projects } = props;
    const { timeChange } = this.state;
    const params = parseLocation(location);
    const { projectName, instanceGroup, startTimestamp, endTimestamp } = params;
    const project = R.find(
      (p) => p.projectName === projectName,
      R.filter((p) => !p.isLog, projects),
    );
    this.getProjectType();
    if (project && startTimestamp && endTimestamp) {
      if (timeChange || !project.hasAllInfo || force) {
        this.setState({ timeChange: false }, () => {
          loadProjectInfo({ projectName, includeInstance: true, startTimestamp, endTimestamp });
        });
      } else {
        createLoadAction(
          ActionTypes.LOAD_METRIC_CAPACITY_PLANNING,
          {
            projectName,
            env: instanceGroup,
            startTimestamp,
            endTimestamp,
          },
          this.dataLoader,
        );
      }
    }
  }
  @autobind
  getProjectType(project) {
    this.setState({ isLoading: true });
    const insightAgentType = get(project, 'insightAgentType', '');
    const isContainerProject = insightAgentType === 'containerStreaming' || insightAgentType === 'containerReplay';
    this.setState({
      isLoading: false,
      isContainerProject,
    });
  }
  @autobind
  parseData(props) {
    const { capacityPlanningCalendarInfo, capacityPlanningCalendarRealInfo } = props;
    let capacityInfo = [];
    let capacityRealInfo = [];
    R.forEachObjIndexed((score, shortDay) => {
      const dayObj = moment.utc(shortDay, Defaults.SmartDateFormat);
      const timestamp = dayObj.valueOf();
      capacityInfo.push({
        dayStr: dayObj.format(Defaults.DateFormat),
        timestamp,
        score: round(score * 100),
      });
    }, capacityPlanningCalendarInfo);
    R.forEachObjIndexed((score, shortDay) => {
      const dayObj = moment.utc(shortDay, Defaults.SmartDateFormat);
      const timestamp = dayObj.valueOf();
      capacityRealInfo.push({
        dayStr: dayObj.format(Defaults.DateFormat),
        timestamp,
        score: round(score * 100),
      });
    }, capacityPlanningCalendarRealInfo);
    capacityInfo = R.sortWith([R.ascend(R.prop('timestamp'))], capacityInfo);
    capacityRealInfo = R.sortWith([R.ascend(R.prop('timestamp'))], capacityRealInfo);
    this.capacityInfo = capacityInfo;
    this.capacityRealInfo = capacityRealInfo;
    this.chartOption = this.getChartOption(props);
  }
  @autobind
  getChartOption(props) {
    const { intl } = this.props;
    const itemStyle = {
      normal: {},
      emphasis: {
        barBorderWidth: 1,
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowColor: 'rgba(0,0,0,0.5)',
      },
    };
    return {
      color: [DefaultsApp.Colorbrewer[9]],
      tooltip: {
        formatter: (params, ticket, callback) => {
          const { name, value } = params;
          return `${intl.formatMessage(DashboardMessages.clickForDetails)}<br />${name}: ${value}`;
        },
      },
      grid: { left: '5%', right: '5%', top: '15%', bottom: '15%' },
      xAxis: {
        type: 'category',
        data: R.map((c) => c.dayStr, this.capacityInfo),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Real Capacity Score',
          type: 'bar',
          barGap: 0,
          itemStyle,
          data: R.map(
            (capacity) => ({
              value: capacity.score,
              label: {},
              itemStyle: { color: '#d0f3f8' },
            }),
            this.capacityRealInfo,
          ),
        },
        {
          name: 'Predicted Capacity Score',
          type: 'bar',
          itemStyle,
          data: R.map(
            (capacity) => ({
              value: capacity.score,
              label: {},
              itemStyle: { color: capacity.score >= 50 ? '#F06E50' : '#B4DD92' },
            }),
            this.capacityInfo,
          ),
        },
      ],
    };
  }

  @autobind
  reloadDataDetails(props) {
    const { location, createLoadAction, projects } = props;
    const params = parseLocation(location);
    const { projectName, instanceGroup, dateTimestamp, componentsStr, isRealCapacityPlanning } = params;
    const project = R.find(
      (p) => p.projectName === projectName,
      R.filter((p) => !p.isLog, projects),
    );
    this.getProjectType(project);
    if (project && dateTimestamp) {
      const startTimestamp = moment.utc(Number(dateTimestamp)).startOf('day').valueOf();
      const endTimestamp = moment.utc(Number(dateTimestamp)).endOf('day').valueOf();

      const componentsAll = JSON.parse(componentsStr || '[]');
      const smartDay = moment.utc(Number(dateTimestamp)).format(Defaults.SmartDateFormat);

      this.setState({ selectComponent: null }, () => {
        createLoadAction(
          ActionTypes.LOAD_METRIC_ANOMALY,
          {
            projectName,
            env: instanceGroup,
            componentsStr: `[${R.join(',', componentsAll)}]`,
            smartDay,
            startTimestamp,
            endTimestamp,
            isRealCapacityPlanning,
          },
          this.dataDetailsLoader,
        );
      });
    }
  }
  @autobind
  parseDataDetails(props) {
    const { anomalyInfo } = props;
    const { systemInfos } = anomalyInfo || {};
    const { selectComponent } = this.state;

    this.bottleneckComponents = this.getBottleneckComponents(systemInfos);
    this.selectComponentOptions = R.map((bottleneckComponent) => {
      return { value: bottleneckComponent.component, label: bottleneckComponent.component };
    }, this.bottleneckComponents);
    const selectMetrics = this.getSelectMetricsByComponent(systemInfos, selectComponent);
    this.setState({ selectMetrics });
  }
  @autobind
  getBottleneckComponents(systemInfos) {
    let bottleneckComponents = [];
    R.forEachObjIndexed((val, metric) => {
      if (metric !== 'All Metrics' && val.length !== 0) {
        const componentInfos = get(val[0], 'componentInfos', []);
        R.forEach((componentObj) => {
          bottleneckComponents.push({
            component: componentObj.name,
            priority: '1',
          });
        }, componentInfos);
      }
    }, systemInfos);
    // bottleneckComponents.push({
    //   component: 'All',
    //   priority: '0',
    // });
    bottleneckComponents = R.uniqWith((componentA, componentB) => {
      return componentA.component === componentB.component;
    }, R.sortWith([R.ascend(R.prop('priority'))], bottleneckComponents));
    return bottleneckComponents;
  }
  @autobind
  parseDataDetailsByMetric(props) {
    const { anomalyInfo } = props;
    const { systemInfos } = anomalyInfo || {};
    let { metricList } = anomalyInfo || {};
    let { selectMetric } = this.state;

    metricList = R.sortWith([R.ascend(R.prop(0))], metricList || []);
    if (metricList && metricList.length > 0) {
      this.metricList = metricList;
      this.selectMetricOptions = R.map((m) => {
        return { value: m, label: m === 'All Metrics' ? 'Aggregated' : m };
      }, metricList);
      selectMetric = ifIn(selectMetric, metricList) ? selectMetric : metricList[0];
      this.setState({ selectMetric }, () => {
        this.eventList = get(systemInfos, selectMetric, []);
      });
    }
  }

  @autobind
  handleProjectChange(newValue) {
    const projectName = newValue ? newValue.value : null;
    const { push, location } = this.props;
    const params = parseLocation(location);
    const instanceGroup = Defaults.InstanceGroup;

    push(buildLocation(location.pathname, {}, { ...params, projectName, instanceGroup }));
  }
  @autobind
  handleInstanceGroupChange(newValue) {
    const instanceGroup = newValue ? newValue.value : null;
    const { push, location } = this.props;
    const params = parseLocation(location);
    push(buildLocation(location.pathname, {}, { ...params, instanceGroup }));
  }
  @autobind
  handleStartTimeChange(dateObj) {
    if (dateObj) {
      const startTimestamp = dateObj.startOf('day').valueOf();
      const { push, location } = this.props;
      const params = parseLocation(location);
      params.startTimestamp = startTimestamp;

      const { endTimestamp } = params;
      if (Number(startTimestamp) > Number(endTimestamp)) {
        params.endTimestamp = startTimestamp;
      }

      this.setState({ timeChange: true }, () => {
        push(buildLocation(location.pathname, {}, params));
      });
    }
  }
  @autobind
  handleEndTimeChange(dateObj) {
    if (dateObj) {
      const endTimestamp = dateObj.startOf('day').valueOf();
      const { push, location } = this.props;
      const params = parseLocation(location);
      params.endTimestamp = endTimestamp;

      const { startTimestamp } = params;
      if (Number(endTimestamp) < Number(startTimestamp)) {
        params.startTimestamp = endTimestamp;
      }

      this.setState({ timeChange: true }, () => {
        push(buildLocation(location.pathname, {}, params));
      });
    }
  }

  @autobind
  onChartClick(item) {
    const { push, location } = this.props;
    const params = parseLocation(location);

    const { dataIndex, seriesIndex } = item;
    const isRealCapacityPlanning = seriesIndex === 0;
    const info = seriesIndex === 0 ? this.capacityRealInfo[dataIndex] : this.capacityInfo[dataIndex];
    const dateTimestamp = get(info, ['timestamp']);
    push(buildLocation(location.pathname, {}, { ...params, dateTimestamp, isRealCapacityPlanning }));
  }

  @autobind
  handleComponentChange(component) {
    const { anomalyInfo } = this.props;
    const { systemInfos } = anomalyInfo || {};

    const selectMetrics = this.getSelectMetricsByComponent(systemInfos, component);
    this.setState({ selectMetrics });
  }
  @autobind
  handleMetricChange(metric) {
    const { anomalyInfo } = this.props;
    const { systemInfos } = anomalyInfo || {};
    this.eventList = get(systemInfos, metric, []);
  }

  @autobind
  getSelectMetricsByComponent(systemInfos, component) {
    let selectMetrics = [];
    if (component) {
      const metricsWithScore = [];
      R.forEachObjIndexed((val, metric) => {
        if (metric !== 'All Metrics' && val.length !== 0) {
          const componentInfos = get(val[0], 'componentInfos', []);
          R.forEach((componentObj) => {
            if (componentObj.name === component) {
              // if (componentObj.capacityScore >= 50) {
              //   metricsWithScore.push([componentObj.capacityScore, metric]);
              // } else if (
              //   componentObj.targetAvgInstancesL1 < componentObj.actualInstances ||
              //   componentObj.targetAvgInstancesL2 < componentObj.actualInstances ||
              //   componentObj.targetMaxInstancesL1 < componentObj.actualInstances ||
              //   componentObj.targetMaxInstancesL2 < componentObj.actualInstances
              // ) {
              //   metricsWithScore.push([componentObj.capacityScore, metric]);
              // }
              metricsWithScore.push([componentObj.capacityScore, metric]);
            }
          }, componentInfos);
        }
      }, systemInfos);
      selectMetrics = R.uniq(R.map((metrics) => metrics[1], R.sortWith([R.descend(R.prop(0))], metricsWithScore)));
    } else {
      selectMetrics = ['All Metrics'];
    }
    return selectMetrics;
  }

  @autobind
  renderInstanceCircle({ intl, allMetricsInfo, targetInstancesName }) {
    const componentInfos = get(allMetricsInfo, 'componentInfos', []);

    // get components recommendations
    let recommendationList = [];
    R.forEach((componentInfo) => {
      const { name, actualInstances } = componentInfo;
      const targetInstances = get(componentInfo, [targetInstancesName]);
      const relativeInstances = targetInstances - actualInstances;
      if (relativeInstances !== 0) recommendationList.push({ name, relativeInstances });
    }, componentInfos);
    recommendationList = R.sortWith(
      [R.descend(R.prop('relativeInstances')), R.ascend(R.prop('name'))],
      recommendationList,
    );

    return (
      <div
        className="flex-grow flex-row flex-center-align flex-center-justify"
        style={{ height: 130, padding: '10px 0 0 0' }}
      >
        <div className="health-card bold flex-col flex-center-justify">
          {recommendationList.length === 0 && (
            <span style={{ color: DefaultsApp.Colorbrewer[9] }}>
              {intl.formatMessage(capacityPlanningMessages.noChange)}
            </span>
          )}
          {recommendationList.length > 0 &&
            R.addIndex(R.map)((recommendation, idx) => {
              const name = recommendation.name;
              const relativeInstances = recommendation.relativeInstances;
              if (relativeInstances > 0)
                return (
                  <span key={idx} style={{ color: '#F06E50' }}>
                    {intl.formatMessage(capacityPlanningMessages.addInstances, {
                      count: Math.abs(relativeInstances),
                      name,
                    })}
                  </span>
                );
              return (
                <span key={idx} style={{ color: '#B4DD92' }}>
                  {intl.formatMessage(capacityPlanningMessages.removeInstances, {
                    count: Math.abs(relativeInstances),
                    name,
                  })}
                </span>
              );
            }, R.take(3, recommendationList))}
          {recommendationList.length > 3 && (
            <div>
              <Popover
                placement="right"
                content={
                  <div
                    className="flex-col"
                    style={{ maxHeight: 250, overflowY: 'auto', fontSize: 12, fontWeight: 'bold' }}
                  >
                    {R.addIndex(R.map)((recommendation, idx) => {
                      const name = recommendation.name;
                      const relativeInstances = recommendation.relativeInstances;
                      if (relativeInstances > 0)
                        return (
                          <span key={idx}>
                            {intl.formatMessage(capacityPlanningMessages.addInstances, {
                              count: Math.abs(relativeInstances),
                              name,
                            })}
                          </span>
                        );
                      return (
                        <span key={idx}>
                          {intl.formatMessage(capacityPlanningMessages.removeInstances, {
                            count: Math.abs(relativeInstances),
                            name,
                          })}
                        </span>
                      );
                    }, recommendationList)}
                  </div>
                }
                trigger="click"
              >
                <Button type="link" style={{ padding: 0, height: 'auto', fontSize: 12 }}>
                  {intl.formatMessage(appFieldsMessages.expand)}
                </Button>
              </Popover>
            </div>
          )}
        </div>
      </div>
    );
  }
  @autobind
  renderAllMetricsCapacityPlanning() {
    const { intl, anomalyInfo } = this.props;
    const { systemInfos } = anomalyInfo || {};
    const allMetricsInfo = get(systemInfos, ['All Metrics'], [{}])[0];
    const capacityScore = Math.round(allMetricsInfo.capacityScore);
    let color = '#B4DD92';
    if (isNumber(capacityScore)) {
      if (capacityScore >= 50) {
        color = '#F06E50';
      }
    }
    return (
      <Container style={{ padding: '10px 0px 20px 0px' }}>
        <div className="flex-row">
          <div className="flex-col" style={{ width: 200 }}>
            <div style={{ textAlign: 'center' }}>{intl.formatMessage(DashboardMessages.capacityPlanningAnomaly)}</div>
            <div className="flex-grow" style={{ textAlign: 'center', height: 130, padding: '10px 0 0 0' }}>
              <Progress
                type="dashboard"
                percent={capacityScore}
                width={120}
                strokeWidth={16}
                gapDegree={90}
                strokeLinecap={'square'}
                strokeColor={color}
                status={capacityScore >= 50 ? 'exception' : 'normal'}
                format={(percent, successPercent) => `${capacityScore}%`}
              />
            </div>
          </div>
          <div className="flex-grow flex-center-align">
            <div style={{ textAlign: 'center' }}>{`${intl.formatMessage(
              appFieldsMessages.avg,
            )} < 50% ${intl.formatMessage(appFieldsMessages.recommendation)}`}</div>
            {this.renderInstanceCircle({ intl, allMetricsInfo, targetInstancesName: 'targetAvgInstancesL1' })}
          </div>
          <div className="flex-grow flex-center-align">
            <div style={{ textAlign: 'center' }}>{`${intl.formatMessage(
              appFieldsMessages.avg,
            )} < 80% ${intl.formatMessage(appFieldsMessages.recommendation)}`}</div>
            {this.renderInstanceCircle({ intl, allMetricsInfo, targetInstancesName: 'targetAvgInstancesL2' })}
          </div>
          <div className="flex-grow flex-center-align">
            <div style={{ textAlign: 'center' }}>{`${intl.formatMessage(
              appFieldsMessages.max,
            )} < 50% ${intl.formatMessage(appFieldsMessages.recommendation)}`}</div>
            {this.renderInstanceCircle({ intl, allMetricsInfo, targetInstancesName: 'targetMaxInstancesL1' })}
          </div>
          <div className="flex-grow flex-center-align">
            <div style={{ textAlign: 'center' }}>{`${intl.formatMessage(
              appFieldsMessages.max,
            )} < 80% ${intl.formatMessage(appFieldsMessages.recommendation)}`}</div>
            {this.renderInstanceCircle({ intl, allMetricsInfo, targetInstancesName: 'targetMaxInstancesL2' })}
          </div>
        </div>
      </Container>
    );
  }

  @autobind
  renderTableHeader(isAllMetric) {
    const { intl } = this.props;
    const { isContainerProject } = this.state;
    const params = parseLocation(location);
    const { isRealCapacityPlanning, dateTimestamp } = params;
    const label1 = '50';
    const label2 = '80';
    const unit = '%';
    const metric = isAllMetric ? 'All Metrics' : '';

    let avgUsageTitle = '';
    const isCurrentDay =
      moment.utc(Number(dateTimestamp)).startOf('day').valueOf() === moment.utc().startOf('day').valueOf();
    if (isRealCapacityPlanning === 'true') {
      avgUsageTitle = intl.formatMessage(capacityPlanningMessages.avgUsageReal, { lines: 10 });
    } else if (isCurrentDay) {
      avgUsageTitle = intl.formatMessage(capacityPlanningMessages.avgUsageReal, { lines: 1000 });
    } else {
      avgUsageTitle = intl.formatMessage(capacityPlanningMessages.avgUsagePast);
    }

    return (
      <div className="event-list">
        <div className="event-list-header" style={{ height: this.listHeaderHeight, width: '100%' }}>
          <div className="header-child-column" style={{ width: 28 }} />
          {isAllMetric && (
            <div className="header-child-column flex-row" style={{ width: 200, height: this.listHeaderHeightPercent }}>
              <i className="icon sitemap" style={{ color: 'white', lineHeight: '18px' }} />
              <span>{intl.formatMessage(DashboardMessages.system)}</span>
              <span style={{ padding: '0 4px' }}>/</span>
              <span
                style={{
                  minWidth: 14,
                  display: 'inline-block',
                  background: 'grey',
                  color: 'white',
                  textAlign: 'center',
                  marginRight: 4,
                }}
              >
                C
              </span>
              <span>
                {isContainerProject
                  ? intl.formatMessage(DashboardMessages.container)
                  : intl.formatMessage(DashboardMessages.component)}
              </span>
            </div>
          )}
          {!isAllMetric && (
            <div className="header-child-column flex-row" style={{ width: 200, height: this.listHeaderHeightPercent }}>
              <span>{intl.formatMessage(appFieldsMessages.metric)}</span>
              <span
                style={{
                  minWidth: 14,
                  display: 'inline-block',
                  background: 'grey',
                  color: 'white',
                  textAlign: 'center',
                  marginRight: 4,
                }}
              />
            </div>
          )}
          <div
            className="flex-col"
            style={{
              width: (isAllMetric ? 170 : 526) + 2,
              height: this.listHeaderHeightPercent,
              borderLeft: this.headerBorder,
            }}
          >
            <div className="header-column" style={{ height: 20, justifyContent: 'center' }}>
              {intl.formatMessage(capacityPlanningMessages.currentDeployment)}
            </div>
            <div className="flex-grow flex-row" style={{ alignItems: 'flex-end' }}>
              <div className="header-child-column" style={{ width: 80, height: '50%' }}>
                {isContainerProject
                  ? intl.formatMessage(DashboardMessages.containers)
                  : intl.formatMessage(DashboardMessages.instances)}
              </div>
              <div className="header-child-column" style={{ width: 90, height: '50%', borderLeft: this.headerBorder }}>
                {intl.formatMessage(DashboardMessages.score)}
              </div>
              {!isAllMetric && (
                <div
                  className="header-child-column"
                  style={{ width: 92, height: '50%', borderLeft: this.headerBorder }}
                >
                  {avgUsageTitle}
                </div>
              )}
              {!isAllMetric && (
                <div
                  className="header-child-column"
                  style={{ width: 80, height: '50%', borderLeft: this.headerBorder }}
                >
                  {intl.formatMessage(DashboardMessages.threshold)}
                </div>
              )}
              {!isAllMetric && (
                <div
                  className="header-child-column"
                  style={{ width: 184, height: '50%', borderLeft: this.headerBorder }}
                >
                  {intl.formatMessage(capacityPlanningMessages.predictedAvgUsageCurrent)}
                </div>
              )}
            </div>
          </div>
          <div
            className="flex-col"
            style={{
              flex: 1,
              height: this.listHeaderHeightPercent,
              borderLeft: this.headerBorder,
            }}
          >
            <div className="header-column" style={{ height: 20, justifyContent: 'center' }}>
              {intl.formatMessage(capacityPlanningMessages.predictionsForRequired)}
            </div>
            <div className="flex-grow flex-row">
              <div className="flex-col" style={{ flex: 1 }}>
                <div className="header-column" style={{ height: 20, justifyContent: 'center' }}>
                  {intl.formatMessage(capacityPlanningMessages.average, { metric })}
                </div>
                <div className="flex-grow flex-row">
                  <div className="flex-grow flex-col">
                    <div className="header-column" style={{ height: 20, justifyContent: 'center' }}>
                      {` < ${label1} ${unit}`}
                    </div>
                    <div className="header-child-column flex-grow flex-row" style={{ justifyContent: 'space-between' }}>
                      <div>
                        {isContainerProject
                          ? intl.formatMessage(DashboardMessages.containers)
                          : intl.formatMessage(DashboardMessages.instances)}
                      </div>
                      <div>{intl.formatMessage(DashboardMessages.difference)}</div>
                    </div>
                  </div>
                  <div className="flex-grow flex-col" style={{ borderLeft: this.headerBorder }}>
                    <div className="header-column" style={{ height: 20, justifyContent: 'center' }}>
                      {` < ${label2} ${unit}`}
                    </div>
                    <div className="header-child-column flex-grow flex-row" style={{ justifyContent: 'space-between' }}>
                      <div>
                        {isContainerProject
                          ? intl.formatMessage(DashboardMessages.containers)
                          : intl.formatMessage(DashboardMessages.instances)}
                      </div>
                      <div>{intl.formatMessage(DashboardMessages.difference)}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-col" style={{ flex: 1, borderLeft: this.headerBorder }}>
                <div className="header-column" style={{ height: 20, justifyContent: 'center' }}>
                  {intl.formatMessage(capacityPlanningMessages.maximum, { metric })}
                </div>
                <div className="flex-grow flex-row">
                  <div className="flex-grow flex-col">
                    <div className="header-column" style={{ height: 20, justifyContent: 'center' }}>
                      {` < ${label1} ${unit}`}
                    </div>
                    <div className="header-child-column flex-grow flex-row" style={{ justifyContent: 'space-between' }}>
                      <div>
                        {isContainerProject
                          ? intl.formatMessage(DashboardMessages.containers)
                          : intl.formatMessage(DashboardMessages.instances)}
                      </div>
                      <div>{intl.formatMessage(DashboardMessages.difference)}</div>
                    </div>
                  </div>
                  <div className="flex-grow flex-col" style={{ borderLeft: this.headerBorder }}>
                    <div className="header-column" style={{ height: 20, justifyContent: 'center' }}>
                      {` < ${label2} ${unit}`}
                    </div>
                    <div className="header-child-column flex-grow flex-row" style={{ justifyContent: 'space-between' }}>
                      <div>
                        {isContainerProject
                          ? intl.formatMessage(DashboardMessages.containers)
                          : intl.formatMessage(DashboardMessages.instances)}
                      </div>
                      <div>{intl.formatMessage(DashboardMessages.difference)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  renderMetricCapacityPlanning(metric, idx) {
    const { intl, anomalyInfo } = this.props;
    const params = parseLocation(location);
    const { isRealCapacityPlanning } = params;
    const { systemInfos } = anomalyInfo || {};
    const capacityInfos = get(systemInfos, metric, []);
    const isAllMetric = metric === 'All Metrics';
    const { selectComponent } = this.state;
    return (
      <Container key={idx} className={`events-view`} style={{ padding: 0 }}>
        <CapacityPlanningTable
          rowIndex={idx}
          metric={metric}
          capacityInfos={capacityInfos}
          isAllMetric={isAllMetric}
          isRealCapacityPlanning={isRealCapacityPlanning === 'true'}
          selectComponent={selectComponent}
        />
      </Container>
    );
  }

  @autobind
  onClickLineCharts(selectMetrics, selectComponent) {
    return (event) => {
      event.stopPropagation();
      event.preventDefault();

      const { location, anomalyInfo } = this.props;
      const params = parseLocation(location);
      const { projectName, instanceGroup, dateTimestamp } = params;
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';
      const environmentId = GlobalParse.getEnvByInstanceGroup(instanceGroup);
      const isFeature = Number(dateTimestamp) > moment.utc().valueOf();
      const startTimestamp = isFeature
        ? moment.utc().add(-7, 'day').startOf('day').valueOf()
        : moment.utc(Number(dateTimestamp)).add(-7, 'day').startOf('day').valueOf();
      const endTimestamp = isFeature
        ? moment.utc().endOf('day').valueOf()
        : moment.utc(Number(dateTimestamp)).endOf('day').valueOf();
      let instanceList = [];
      const { systemInfos, componentInstanceMap } = anomalyInfo || {};
      R.forEach((metric) => {
        const capacityInfos = get(systemInfos, metric, []);
        R.forEach((capacityInfo) => {
          R.forEach((componentInfo) => {
            if (componentInfo.name === selectComponent) {
              instanceList = [...instanceList, ...get(componentInstanceMap, componentInfo.name, [])];
            }
          }, capacityInfo.componentInfos || []);
        }, capacityInfos);
      }, selectMetrics);
      instanceList = R.uniq(instanceList);

      const query = {
        environmentId,
        projectName,
        instanceGroup,
        modelType,
        startTimestamp,
        endTimestamp,
        predictedFlag: false,
        justSelectMetric: R.join(',', selectMetrics),
        justInstanceList: R.join(',', instanceList),
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    };
  }

  @autobind
  sortMetrics(systemInfos, selectMetrics, selectComponent) {
    const sortedMetrics = [];
    let metricInfoList = [];

    R.forEach((metric) => {
      const rowData = get(systemInfos, metric, []);
      const componentInfos = get(rowData[0], 'componentInfos', []);
      const componentInfo = R.filter((component) => {
        return component.name === selectComponent;
      }, componentInfos);
      const { capacityScore } = componentInfo[0];
      metricInfoList.push({ metric, capacityScore });
    }, selectMetrics);

    const scoreSort = R.sortWith([R.descend(R.prop('capacityScore'))]);
    metricInfoList = scoreSort(metricInfoList);

    R.forEach((obj) => {
      sortedMetrics.push(obj.metric);
    }, metricInfoList);

    return sortedMetrics;
  }

  render() {
    const { intl, loadStatus, anomalyInfo } = this.props;
    const { systemInfos } = anomalyInfo || {};
    let { projects } = this.props;
    const { selectComponent, isContainerProject } = this.state;
    let { selectMetrics } = this.state;
    projects = R.filter((p) => !p.isLog, projects);
    const params = parseLocation(location);
    const { projectName, instanceGroup, startTimestamp, endTimestamp, dateTimestamp } = params;

    const maxDateObj = moment.utc().add(this.predictionInterval, 'day');
    const startTimeObj = moment.utc(Number(startTimestamp));
    const endTimeObj = moment.utc(Number(endTimestamp));
    const project = R.find((p) => p.projectName === projectName, projects) || {};
    const instanceGroupList = get(project, 'groupList', []);

    const selectMetricLink = VLink.state(this, 'selectMetric').onChange(this.handleMetricChange);
    const selectComponentLink = VLink.state(this, 'selectComponent').onChange(this.handleComponentChange);
    const dayStr = moment.utc(Number(dateTimestamp)).format(Defaults.DateFormat);

    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const { isLoading: isLoadingDetails, errorMessage: errorMessageDetails } = getLoadStatus(
      get(loadStatus, this.dataDetailsLoader),
      intl,
    );
    const isAllMetrics = selectMetrics.length === 1 && selectMetrics[0] === 'All Metrics';
    if (selectComponent) selectMetrics = this.sortMetrics(systemInfos, selectMetrics, selectComponent);
    return (
      <Container fullHeight withGutter className="flex-col log-live">
        <Container className="flex-row" breadcrumb style={{ fontSize: 12 }}>
          <div className="section" style={{ height: 24 }}>
            <span>{intl.formatMessage(DashboardMessages.capacityPlanning)}</span>
            <span className="divider">/</span>
            <Select
              name="project"
              inline
              style={{ width: 200, zIndex: 0 }}
              options={R.map((p) => ({ label: p.projectName, value: p.projectName }), projects)}
              value={projectName}
              onChange={this.handleProjectChange}
              placeholder={`${intl.formatMessage(appFieldsMessages.project)}...`}
            />
            <span className="divider">/</span>
            <Select
              name="group"
              inline
              style={{ width: 200, zIndex: 0 }}
              placeholder="Group"
              options={R.map((g) => ({ label: g, value: g }), instanceGroupList)}
              value={instanceGroup}
              onChange={this.handleInstanceGroupChange}
            />
          </div>
          <div className="flex-grow flex-row flex-center-align" style={{ justifyContent: 'flex-end', height: 28 }}>
            <span className="label" style={{ fontWeight: 500, paddingRight: 8 }}>
              {intl.formatMessage(appFieldsMessages.startDate)}
            </span>
            <div className="ui input datePicker">
              <DatePicker
                utcOffset={0}
                className="day"
                todayButton="Now"
                dateFormat={Defaults.DateFormat}
                selected={startTimeObj}
                maxDate={maxDateObj}
                showYearDropdown
                showMonthDropdown
                onChange={this.handleStartTimeChange}
              />
            </div>
            <span className="label" style={{ fontWeight: 500, paddingRight: 8 }}>
              {intl.formatMessage(appFieldsMessages.endDate)}
            </span>
            <div className="ui input datePicker">
              <DatePicker
                utcOffset={0}
                className="day"
                todayButton="Now"
                dateFormat={Defaults.DateFormat}
                selected={endTimeObj}
                maxDate={maxDateObj}
                showYearDropdown
                showMonthDropdown
                onChange={this.handleEndTimeChange}
              />
            </div>
            <Button
              size="small"
              onClick={() => {
                this.reloadData(this.props, true);
                this.reloadDataDetails(this.props);
              }}
              style={{ marginLeft: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </div>
        </Container>
        {errorMessage && (
          <Container style={{ margin: 8 }}>
            <div className="ui mini error message">{errorMessage}</div>
          </Container>
        )}
        {!errorMessage && (
          <Container className={`events-view flex-col flex-min-height flex-grow ${isLoading ? 'loading' : ''}`}>
            <div
              className="flex-col"
              style={{
                height: 200,
                padding: 8,
                marginBottom: 4,
                background: 'white',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: `${2 / 14}rem`,
              }}
            >
              <div style={{ fontWeight: 500, fontSize: 14 }}>
                {intl.formatMessage(DashboardMessages.capacityPlanningAnomaly)}:
              </div>
              <div className="flex-grow">
                <EChart height={'100%'} option={this.chartOption} onClick={this.onChartClick} />
              </div>
            </div>
            <div
              style={{
                padding: 8,
                marginBottom: 4,
                background: 'white',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: `${2 / 14}rem`,
              }}
            >
              <Container className="flex-row flex-center-align">
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    width: 120,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {`${intl.formatMessage(appFieldsMessages.date)}: ${dayStr}`}
                </span>
                <span
                  style={{
                    width: 14,
                    height: 14,
                    background: DefaultsApp.Colorbrewer[9],
                    marginRight: 8,
                    borderRadius: '0.14rem',
                  }}
                />
                <span style={{ paddingRight: 8 }}>
                  {isContainerProject
                    ? intl.formatMessage(DashboardMessages.container)
                    : intl.formatMessage(DashboardMessages.instance)}
                </span>
                <span
                  style={{
                    width: 14,
                    height: 14,
                    background: '#B4DD92',
                    marginRight: 8,
                    display: 'flex',
                    fontSize: 12,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '0.14rem',
                  }}
                >
                  -
                </span>
                <span style={{ paddingRight: 8 }}>
                  {isContainerProject
                    ? intl.formatMessage(DashboardMessages.reduceContainer)
                    : intl.formatMessage(DashboardMessages.reduceInstance)}
                </span>
                <span
                  style={{
                    width: 14,
                    height: 14,
                    background: '#F06E50',
                    marginRight: 8,
                    display: 'flex',
                    fontSize: 12,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '0.14rem',
                    color: 'white',
                  }}
                >
                  +
                </span>
                <span style={{ paddingRight: 8 }}>
                  {isContainerProject
                    ? intl.formatMessage(DashboardMessages.addContainer)
                    : intl.formatMessage(DashboardMessages.addInstance)}
                </span>
                <span className="flex-grow" />
                <span className="label" style={{ fontWeight: 500, paddingRight: 8 }}>
                  {isContainerProject
                    ? intl.formatMessage(DashboardMessages.bottleneckContainer)
                    : intl.formatMessage(DashboardMessages.bottleneckComponent)}
                  :
                </span>
                <Select
                  options={this.selectComponentOptions}
                  valueLink={selectComponentLink}
                  style={{ width: 200 }}
                  clearable
                />
                {false && (
                  <Select options={this.selectMetricOptions} valueLink={selectMetricLink} style={{ width: 180 }} />
                )}
              </Container>
            </div>
            {errorMessageDetails && (
              <Container style={{ margin: 8 }}>
                <div className="ui mini error message">{errorMessageDetails}</div>
              </Container>
            )}
            {!errorMessageDetails && (
              <Container
                className={`flex-grow ${!isLoading && isLoadingDetails ? 'loading' : ''}`}
                style={{
                  padding: 8,
                  background: 'white',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: `${2 / 14}rem`,
                  overflowY: 'auto',
                }}
              >
                {!selectComponent && this.renderAllMetricsCapacityPlanning()}
                {!isLoadingDetails && selectMetrics.length > 0 && selectComponent && (
                  <Container className="events-view" style={{ padding: 0 }}>
                    <div className="flex-row" style={{ padding: '10px 0 0 0', background: 'white' }}>
                      <a
                        style={{
                          marginLeft: 4,
                          color: '#4183C4',
                          cursor: 'pointer',
                          display: 'inline-block',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                        onClick={this.onClickLineCharts(selectMetrics, selectComponent)}
                      >
                        <i className="icon chart line" />
                        <span>{intl.formatMessage(DashboardMessages.lineChart)}</span>
                      </a>
                    </div>
                  </Container>
                )}
                {!isLoadingDetails && this.renderTableHeader(isAllMetrics)}
                {!isLoadingDetails &&
                  R.addIndex(R.map)((metric, idx) => this.renderMetricCapacityPlanning(metric, idx), selectMetrics)}
              </Container>
            )}
          </Container>
        )}
      </Container>
    );
  }
}

const CapacityPlanningCalendar = injectIntl(CapacityPlanningCalendarCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { projects } = state.app;
    const { anomalyInfo } = state.metric;
    const { capacityPlanningCalendarInfo, capacityPlanningCalendarRealInfo } = state.metric;

    return {
      credentials,
      location,
      loadStatus,
      projects,
      capacityPlanningCalendarInfo,
      capacityPlanningCalendarRealInfo,
      anomalyInfo,
    };
  },
  { push, replace, createLoadAction, loadProjectInfo },
)(CapacityPlanningCalendar);
