/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import moment from 'moment';
import * as R from 'ramda';
import { createLogic } from 'redux-logic';
import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getMetricEventList } from '../../apis';
import { appMessages } from '../../app/messages';

const loadEventListLogic = createLogic({
  type: [ActionTypes.LOAD_EVENTLIST],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,

  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials, userInfo } = state.auth;
    const { projects, userList } = state.app;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());

    const { projectName } = params;
    const projectInfo = R.find((p) => p.projectName === projectName, projects || []);
    const { owner } = projectInfo || {};
    // use user/customer time zone to set isCurrentDay
    let { timezoneOffset } = state.app;
    if (userInfo.isAdmin && owner) {
      const customerUserInfo = R.find((user) => user.userName === owner, userList || []);
      timezoneOffset = customerUserInfo ? customerUserInfo.userZoneOffset : timezoneOffset;
    }
    const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;

    getMetricEventList(credentials, { ...params, nowTimestamp })
      .then((result) => {
        dispatch(createSetAction(ActionTypes.SET_EVENTLIST, params, result));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get event list', err);
        dispatch(createSetAction(ActionTypes.SET_EVENTLIST, params, { eventListMap: {} }));
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadEventListLogic;
