/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Button } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import { parseLocation } from '../../../common/utils';
import { Container, AutoSizer, Table, Column } from '../../../lib/fui/react';
import { State } from '../../../common/types';
import { loadRabbitMQStatusList } from '../../../common/settings/actions';
import { hideAppLoader, updateLastActionInfo } from '../../../common/app/actions';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  location: Object,

  rabbitMQStatusInfo: String,
  rabbitMQStatusList: Array<Object>,
  loadRabbitMQStatusList: Function,
  hideAppLoader: Function,
  updateLastActionInfo: Function,
  appLoaderVisible: Boolean,
  intl: Object,
};

class RabbitMQStatus extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.nameSort = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('queueName')))]);

    this.state = { isLoading: false, rabbitMQStatusInfo: false, rabbitMQStatusList: [] };
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rabbitMQStatusList !== this.props.rabbitMQStatusList) {
      this.parseData(nextProps);
    }
  }

  @autobind
  parseData(props) {
    const { rabbitMQStatusInfo, rabbitMQStatusList } = props;
    this.setState({ rabbitMQStatusInfo, rabbitMQStatusList: this.nameSort(rabbitMQStatusList) });
  }

  @autobind
  purgeRenderer({ rowData }) {
    const { intl } = this.props;
    return (
      <Button size="small" type="primary" onClick={this.handlePurge(rowData)}>
        {intl.formatMessage(eventMessages.purge)}
      </Button>
    );
  }

  @autobind
  handlePurge(rowData) {
    const { intl } = this.props;
    return () => {
      if (window.confirm(`${intl.formatMessage(eventMessages.clearQueueData, { queueName: rowData.queueName })}`)) {
        const self = this;
        const { location, credentials } = self.props;
        const params = parseLocation(location);
        const { zone } = params;
        self.setState({ isLoading: true });
        self.props.updateLastActionInfo();
        fetchPost(`${window.BASE_URL || ''}/adminGetRabbitMQStats`, {
          ...credentials,
          rabbitMqQueueName: rowData.queueName,
          vhost: '/',
          zone,
        })
          .then((data1) => {
            self.setState({ isLoading: false });
            setTimeout(() => {
              self.setState({ isLoading: true });
              fetchGet(`${window.BASE_URL || ''}/adminGetRabbitMQStats`, {
                ...credentials,
                zone,
              }).then((data2) => {
                const { success, message } = data2 || {};
                const rabbitMQStatusInfo = success ? null : message;
                const rabbitMQStatusList = success ? message || [] : [];
                self.setState(
                  {
                    isLoading: false,
                    rabbitMQStatusInfo,
                    rabbitMQStatusList: this.nameSort(rabbitMQStatusList),
                  },
                  () => {
                    if (self.table) {
                      self.table.forceUpdate();
                    }
                  },
                );
              });
            }, 5000);
          })
          .catch((error) => {
            console.warn('[IF_API] failed to purge queue', error);
            self.setState({
              isLoading: false,
            });
          });
      }
    };
  }

  render() {
    const { intl } = this.props;
    const { isLoading, rabbitMQStatusInfo, rabbitMQStatusList } = this.state;

    return (
      <Container className={`flex-col ${isLoading ? ' loading' : ''}`} fullHeight>
        <Container style={{ lineHeight: '30px' }}>
          <span style={{ fontWeight: 500 }}>{intl.formatMessage(eventMessages.rabbitmqServerStatus)}: </span>
          {!rabbitMQStatusInfo && <span style={{ color: 'green' }}>{intl.formatMessage(eventMessages.running)}</span>}
          {rabbitMQStatusInfo && <span style={{ color: 'red' }}>{rabbitMQStatusInfo}</span>}
        </Container>
        <Container className="flex-grow">
          <AutoSizer disableWidth>
            {({ height }) => (
              <Table
                className="with-border"
                width={860}
                height={height}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={40}
                rowCount={rabbitMQStatusList.length}
                rowGetter={({ index }) => rabbitMQStatusList[index]}
                ref={(table) => {
                  this.table = table;
                }}
              >
                <Column width={300} label={intl.formatMessage(eventMessages.queue)} dataKey="queueName" />
                <Column width={300} flexGrow={1} label={intl.formatMessage(eventMessages.host)} dataKey="hostName" />
                <Column
                  width={130}
                  headerClassName="text-right"
                  className="text-right"
                  label={intl.formatMessage(eventMessages.consumers)}
                  dataKey="consumerNumber"
                />
                <Column
                  width={130}
                  headerClassName="text-right"
                  className="text-right"
                  label={intl.formatMessage(eventMessages.messages)}
                  dataKey="messageNumber"
                />
                <Column width={100} label="" cellRenderer={this.purgeRenderer} dataKey="queueName" />
              </Table>
            )}
          </AutoSizer>
        </Container>
      </Container>
    );
  }
}

export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { appLoaderVisible } = state.app;
    const { rabbitMQStatusList, rabbitMQStatusInfo } = state.settings;
    return { location, credentials, appLoaderVisible, rabbitMQStatusList, rabbitMQStatusInfo };
  },
  { hideAppLoader, loadRabbitMQStatusList, updateLastActionInfo },
)(RabbitMQStatus);
