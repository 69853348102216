/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { push } from 'react-router-redux';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Button } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { Container, Box, Select } from '../../../lib/fui/react';
import { State, Message } from '../../../common/types';
import { parseQueryString, buildMatchLocation, buildUrl } from '../../../common/utils';
import { loadRabbitMQStatusList } from '../../../common/settings/actions';

import { appButtonsMessages, appMenusMessages } from '../../../common/app/messages';
import { settingsMenusMessages, settingsMessages } from '../../../common/settings/messages';
import { eventMessages } from '../../../common/metric/messages';

import UserManagement from './UserManagement';
import RoleManagement from './RoleManagement';
import SignupManagement from './SignupManagement';
import RabbitMQStatus from './RabbitMQStatus';
import EmailSettings from './EmailSettings';
import General from './General';
import Cronstatus from './Cronstatus';
import TaskStatus from './TaskStatus';

type Props = {
  intl: Object,
  currentErrorMessage: ?Message,
  location: Object,
  userInfo: Object,
  match: Object,

  push: Function,
  // eslint-disable-next-line
  loadRabbitMQStatusList: Function,
};

class InsightfinderSettingsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl, userInfo } = props;
    const { isLocalAdmin } = userInfo;

    this.defaultView = isLocalAdmin ? 'user' : 'general';
    this.viewsInfo = [
      { key: 'general', name: intl.formatMessage(settingsMessages.general), component: General },
      { key: 'role', name: intl.formatMessage(eventMessages.roleManagement), component: RoleManagement },
      { key: 'user', name: intl.formatMessage(eventMessages.userManagement), component: UserManagement },
      { key: 'signup', name: intl.formatMessage(eventMessages.signupManagement), component: SignupManagement },
      { key: 'mq', name: intl.formatMessage(eventMessages.rabbitMQStatus), component: RabbitMQStatus },
      { key: 'Cronstatus', name: intl.formatMessage(eventMessages.cronStatus), component: Cronstatus },
      { key: 'taskStatus', name: intl.formatMessage(eventMessages.taskStatus), component: TaskStatus },
      { key: 'emailSettings', name: intl.formatMessage(eventMessages.emailSettings), component: EmailSettings },
    ];
    this.localViewInfo = [
      { key: 'user', name: intl.formatMessage(eventMessages.userManagement), component: UserManagement },
    ];
    this.zoneListOption = [{ label: 'US', value: 'US' }];
    this.state = { refresh: null };
  }

  componentDidMount() {
    if (!this.applyParamsAndRedirect(this.props)) {
      this.reloadData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location && !this.applyParamsAndRedirect(nextProps)) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  applyParamsAndRedirect(props) {
    const { location, match, push } = props;
    const params = parseQueryString(location.search);
    let { view, zone } = params;
    let redirect = false;

    if (!view) {
      view = this.defaultView;
      redirect = true;
    }
    if (!zone) {
      zone = this.zoneListOption ? this.zoneListOption[0].value : '';
      redirect = true;
    }

    if (redirect) {
      push(buildMatchLocation(match, {}, { view, zone, ...params }));
    }
    return redirect;
  }

  @autobind
  reloadData(props) {
    const { location, loadRabbitMQStatusList } = props;
    const params = parseQueryString(location.search);
    const { view, zone } = params;
    if (view === 'mq') {
      loadRabbitMQStatusList({ zone });
    }
  }

  @autobind
  handleViewClick(view) {
    return (e) => {
      e.preventDefault();
      const { location, match, push } = this.props;
      const params = parseQueryString(location.search);
      push(buildMatchLocation(match, {}, { ...params, view }));
    };
  }

  @autobind
  handleRefreshClick() {
    this.setState({ refresh: moment.utc().valueOf() });
  }

  @autobind
  handleZoneChange(val) {
    const { value: zone } = val || {};

    const { location, match, push } = this.props;
    const params = parseQueryString(location.search);
    push(buildMatchLocation(match, {}, { ...params, zone }));
  }

  render() {
    const { intl, location, currentErrorMessage, userInfo } = this.props;
    const { isLocalAdmin } = userInfo;
    const { refresh } = this.state;
    const params = parseQueryString(location.search);
    const { view, zone, customerName } = params;
    const hasError = false;
    const viewsInfo = isLocalAdmin ? this.localViewInfo : this.viewsInfo;
    const viewInfo = R.find((v) => v.key === view, viewsInfo);
    const { zoneListOption } = this;
    const zoneInfo = R.find((v) => v.value === zone, zoneListOption);
    const defaultZone = this.zoneListOption ? this.zoneListOption[0].value : '';
    const zoneID = zoneInfo ? zoneInfo.value : defaultZone;

    return (
      <Container fullHeight withGutter className="flex-col">
        <Container breadcrumb>
          <div className="section">
            <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
            <span className="divider">/</span>
            <NavLink to={buildUrl(BaseUrls.InsightfinderSetting, {}, { customerName })}>
              <span className="label">{intl.formatMessage(settingsMenusMessages.ifSetting)}</span>
            </NavLink>
          </div>
          <div className="section float-right clearfix" style={{ fontSize: 12, marginRight: 0 }}>
            <Button size="small" onClick={this.handleRefreshClick}>
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </div>
        </Container>
        {hasError && (
          <Container fullHeight>
            <div
              className="ui error message"
              style={{ marginTop: 16 }}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(currentErrorMessage, {}),
              }}
            />
          </Container>
        )}
        {!hasError && (
          <Container
            fullHeight
            className="overflow-y-auto content-bg corner-10"
            style={{ margin: '0 8px', padding: 15 }}
          >
            <Box className="flex-col" style={{ height: '100%', paddingTop: 0 }} isBox={false}>
              <Container className="field flex-row" style={{ marginTop: 14, marginLeft: 15 }}>
                <label style={{ margin: 'auto 1em auto 0' }}>Zone:</label>
                <Select
                  name="zone"
                  autosize={false}
                  clearable
                  style={{ width: 200 }}
                  options={zoneListOption}
                  value={zoneID}
                  onChange={this.handleZoneChange}
                />
              </Container>

              <div className="ui pointing secondary menu">
                {R.map(
                  (info) => (
                    <a
                      key={info.key}
                      className={`${info.key === view ? 'active' : ''} item`}
                      onClick={this.handleViewClick(info.key)}
                    >
                      {info.name}
                    </a>
                  ),
                  viewsInfo,
                )}
              </div>
              <div className="flex-grow" style={{ overflow: 'hidden' }}>
                {viewInfo && React.createElement(viewInfo.component, { intl, refresh, userInfo })}
              </div>
            </Box>
          </Container>
        )}
      </Container>
    );
  }
}

const InsightfinderSettings = injectIntl(InsightfinderSettingsCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { currentErrorMessage } = state.settings;
    const { userInfo, credentials } = state.auth;

    return { location, currentErrorMessage, userInfo, credentials };
  },
  { push, loadRabbitMQStatusList },
)(InsightfinderSettings);
