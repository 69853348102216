/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import { get, isString, toInteger } from 'lodash';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import moment from 'moment';

import { State } from '../../common/types';
import { Defaults, parseJSON, parseLocation } from '../../common/utils';
import { Container } from '../../lib/fui/react';
import { createLoadAction } from '../../common/app/actions';
import { ActionTypes } from '../../common/causal/actions';
import CausalIncident from './components/CausalIncident';
import CausalIncidentTask from './components/CausalIncidentTask';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,
  push: Function,
  replace: Function,
  // eslint-disable-next-line
  isAdmin: Boolean,
  userName: String,
  // eslint-disable-next-line
  createLoadAction: Function,
  projects: Array<Object>,

  causalGroupAnalysis: Object,
};

class CausalGroupAnalysisCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.defaultRelationTimeThreshold = '15.0';
    this.defaultRelationProbability = '0.0';
    this.defaultRelationCount = 1;
    this.defaultkpiPredictionProbability = '1.0';

    this.dataLoader = 'causal_group_analysis_loader';
    const { location } = this.props;
    const query = parseLocation(location);
    const { joinDependency, duration, count, probability } = query;
    const relationTimeThreshold = duration ? `${toInteger(Number(duration) / 60000)}.0` : '';
    const fixedCountAndProb = Boolean(count && probability);
    this.state = {
      startTime: '',
      endTime: '',
      causalGroupList: [],
      selectedCausalGroup: '',
      causalGroupInfo: {},

      view: 'relation',
      relationTimeThreshold: relationTimeThreshold || this.defaultRelationTimeThreshold,
      relationProbability: probability || this.defaultRelationProbability,
      relationCount: count || this.defaultRelationCount,
      kpiPredictionProbability: this.defaultkpiPredictionProbability,
      joinDependency: joinDependency || false,
      fixedCountAndProb,
    };
  }

  componentDidMount() {
    this.reloadData(this.props, this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      const nextQuery = parseLocation(nextProps.location);
      const query = parseLocation(this.props.location);
      if (
        nextQuery.customerName !== query.customerName ||
        nextQuery.environmentId !== query.environmentId ||
        nextQuery.projectName !== query.projectName ||
        nextQuery.instanceName !== query.instanceName ||
        nextQuery.startTimestamp !== query.startTimestamp ||
        nextQuery.needCausalProperty !== query.needCausalProperty ||
        nextQuery.type !== query.type ||
        nextQuery.nid !== query.nid ||
        nextQuery.metric !== query.metric
      ) {
        this.reloadData(nextProps, this.state);
      }
    }
    if (nextProps.causalGroupAnalysis !== this.props.causalGroupAnalysis) {
      this.parserData(nextProps);
    }
  }

  @autobind
  reloadData(props, state) {
    const { isAdmin, userName, createLoadAction, location } = props;
    const query = parseLocation(location);
    const { customerName, environmentId, projectName, startTimestamp } = query;
    if (((isAdmin && customerName) || !isAdmin) && environmentId && projectName && startTimestamp) {
      const startTime = moment.utc(Number(startTimestamp)).format(Defaults.DateFormat);
      createLoadAction(ActionTypes.LOAD_CAUSAL_GROUP_ANALYSIS, {
        startTime,
        customerName: customerName || userName,
        projectInfoList: [{ projectName, grouping: environmentId }],
      });
    } else {
      this.resetData(props);
    }
  }

  @autobind
  parserData(props) {
    const { location, causalGroupAnalysis } = props;
    const query = parseLocation(location);
    let { startTimestamp, endTimestamp } = query;
    const { causalGraphVersion } = query;
    let causalGroupList = [];
    R.forEachObjIndexed((val, key) => {
      let causalInfo = val.causalInfo || {};
      let causalGroupInfo = val.CausalGroupInfo || {};
      let causalInfoOld = val.causalInfoOld || {};
      if (isString(causalInfo)) {
        causalInfo = JSON.parse(causalInfo);
      }
      if (isString(causalGroupInfo)) {
        causalGroupInfo = JSON.parse(causalGroupInfo);
      }
      if (isString(causalInfoOld)) {
        causalInfoOld = JSON.parse(causalInfoOld);
      }
      const casualStartPoint = parseJSON(causalInfoOld.casualStartPoint) || [];

      causalGroupList.push({
        name: key,
        causalInfo,
        causalInfoOld,
        causalGroupInfo,
        projectLength: get(causalGroupInfo, 'projectList', [].length),
        casualStartPoint,
      });
    }, causalGroupAnalysis || {});
    causalGroupList = R.sortWith([R.descend(R.prop('projectLength'))], causalGroupList);
    const selectedCausalGroup = causalGroupList.length > 0 ? causalGroupList[0].name : '';
    const causal = causalGroupList.length > 0 ? causalGroupList[0] : null;
    if (!startTimestamp) {
      startTimestamp =
        causalGroupList.length > 0
          ? causalGraphVersion === 'oldCausal'
            ? causalGroupList[0].causalInfoOld.relationStartTime
            : causalGroupList[0].causalInfo.relationStartTime
          : '';
    }
    if (!endTimestamp) {
      endTimestamp =
        causalGroupList.length > 0
          ? causalGraphVersion === 'oldCausal'
            ? causalGroupList[0].causalInfoOld.relationEndTime
            : causalGroupList[0].causalInfo.relationEndTime
          : '';
    }
    const startTime = moment.utc(Number(startTimestamp)).format(Defaults.DateFormat);
    const endTime = moment.utc(Number(endTimestamp)).format(Defaults.DateFormat);
    this.setState({
      startTime,
      endTime,
      causalGroupList,
      selectedCausalGroup,
      causalGroupInfo: causal ? this.getCausalGroupInfo({ query, causal }) : null,
    });
  }

  @autobind
  getCausalGroupInfo({ query, causal }) {
    const { startTimestamp, endTimestamp, type, nid, metric, showHaveDataGraph, causalGraphVersion } = query;

    const causalGroup = get(causal, `${causalGraphVersion === 'oldCausal' ? 'causalInfoOld' : 'causalGroupInfo'}`, {});
    const { name: causalName } = causal;
    const { causalKey, causalGroupKey, relationKey, fileName, userName, postFix } = causalGroup;
    const incidentParams = {
      causalName,
      causalKey: causalKey || causalGroupKey,
      relationKey,
      fileName,
      customerName: userName,
      startTimestamp,
      endTimestamp,
    };
    const causalIncidentList = [
      {
        ...causal,
        ...incidentParams,
        postFix,
        showHaveDataGraph: showHaveDataGraph === 'true',
      },
    ];
    const filterParams = { type, nid, metric };
    return {
      causalGroup,
      causal,
      incidentParams,
      causalIncidentList,
      filterParams,
    };
  }

  @autobind
  resetData(props) {
    this.setState({ causalGroupList: [] });
  }

  @autobind
  handleListItemClick(selectedCausalGroup) {
    return (event) => {
      event.preventDefault();
      event.stopPropagation();

      const { location } = this.props;
      const query = parseLocation(location);
      let { startTimestamp, endTimestamp } = query;
      const { causalGroupList } = this.state;
      const causal = R.find((causalGroup) => causalGroup.name === selectedCausalGroup, causalGroupList) || {};
      const group = R.find((group) => group.name === selectedCausalGroup, causalGroupList);
      if (!startTimestamp) startTimestamp = group.causalInfo.relationStartTime;
      if (!endTimestamp) endTimestamp = group.causalInfo.relationEndTime;
      const startTime = moment.utc(Number(startTimestamp)).format(Defaults.DateFormat);
      const endTime = moment.utc(Number(endTimestamp)).format(Defaults.DateFormat);
      this.setState({
        startTime,
        endTime,
        selectedCausalGroup,
        causalGroupInfo: this.getCausalGroupInfo({ query, causal }),

        view: 'relation',
        relationTimeThreshold: this.defaultRelationTimeThreshold,
        relationProbability: this.defaultRelationProbability,
        relationCount: this.defaultRelationCount,
        kpiPredictionProbability: this.defaultkpiPredictionProbability,
        joinDependency: false,
      });
    };
  }

  @autobind
  handleViewChange(view) {
    this.setState({ view });
  }

  @autobind
  handleRelationTimeThresholdChanged(newValue) {
    this.setState({ relationTimeThreshold: newValue });
  }

  @autobind
  handleRelationProbabilityChanged(newValue) {
    this.setState({ relationProbability: newValue });
  }

  @autobind
  handleRelationCountChanged(newValue) {
    this.setState({ relationCount: newValue });
  }

  @autobind
  handleJoinDependencyChanged(newValue) {
    this.setState({ joinDependency: Boolean(newValue) });
  }

  @autobind
  handleKpiPredictionProbabilityChanged(newValue) {
    this.setState({ kpiPredictionProbability: newValue });
  }

  render() {
    const { intl, location } = this.props;
    const query = parseLocation(location);
    const { instanceName, needCausalProperty } = query;
    let { causalGraphVersion } = query;
    const { causalGroupList, selectedCausalGroup, causalGroupInfo, startTime, endTime } = this.state;
    const {
      view,
      relationTimeThreshold,
      relationProbability,
      kpiPredictionProbability,
      relationCount,
      joinDependency,
      fixedCountAndProb,
    } = this.state;
    const { causalGroup, causalIncidentList, filterParams } = causalGroupInfo || {};
    const causalIncidentInfo = R.find(
      (causalIncidentInfo) => causalIncidentInfo.fileName === causalIncidentInfo.fileName,
      causalIncidentList || [],
    );
    if (!causalGraphVersion) causalGraphVersion = 'newCausal';
    const incidentParams = { ...get(causalGroupInfo, 'incidentParams', {}), joinDependency };

    return (
      <Container fullHeight withGutter className={`flex-col causal white-bg`}>
        <div className="flex-grow flex-col flex-min-height" style={{ padding: '0 8px 0 8px', overflow: 'hidden' }}>
          {!selectedCausalGroup && (
            <div className="flex-grow">
              <div className="ui mini warning message" style={{ margin: 8 }}>
                No Causal Results
              </div>
            </div>
          )}
          {selectedCausalGroup && (
            <div className="flex-grow flex-col flex-min-height">
              <div className="ui pointing secondary menu" style={{ marginBottom: 0 }}>
                <span className="label" style={{ marginTop: 10 }}>
                  Groups:
                </span>
                {R.map(
                  (causalGroup) => (
                    <a
                      key={causalGroup.name}
                      className={`${causalGroup.name === selectedCausalGroup ? 'active' : ''} item`}
                      onClick={this.handleListItemClick(causalGroup.name)}
                    >
                      {causalGroup.name}
                    </a>
                  ),
                  causalGroupList,
                )}
                <span className="divider" style={{ marginTop: 10 }}>
                  /
                </span>
                <span style={{ marginTop: 10, marginLeft: 10 }}>
                  {startTime} ~ {endTime}
                </span>
              </div>
              {causalGraphVersion === 'newCausal' && (
                <CausalIncidentTask
                  className="flex-grow"
                  causalGroup={selectedCausalGroup}
                  causalIncidentInfo={causalIncidentInfo}
                  causalIncidentList={causalIncidentList}
                  incidentParams={incidentParams}
                  view={view}
                  relationTimeThreshold={relationTimeThreshold}
                  relationProbability={relationProbability}
                  relationCount={relationCount}
                  joinDependency={joinDependency}
                  kpiPredictionProbability={kpiPredictionProbability}
                  onViewChange={this.handleViewChange}
                  onJoinDependencyChanged={this.handleJoinDependencyChanged}
                  hideKpiPrediction
                  fixedCountAndProb={fixedCountAndProb}
                />
              )}
              {causalGraphVersion === 'oldCausal' && (
                <CausalIncident
                  className="flex-grow"
                  causalIncidentList={causalIncidentList}
                  isFromCausalGroupAnalysis
                  incidentParams={incidentParams}
                  filterParams={filterParams}
                  causalGroup={causalGroup}
                  instanceName={instanceName}
                  needCausalProperty={needCausalProperty === 'true'}
                  view={view}
                  relationTimeThreshold={relationTimeThreshold}
                  relationProbability={relationProbability}
                  kpiPredictionProbability={kpiPredictionProbability}
                  relationCount={relationCount}
                  joinDependency={joinDependency}
                  onViewChange={this.handleViewChange}
                  onRelationTimeThresholdChanged={this.handleRelationTimeThresholdChanged}
                  onRelationProbabilityChanged={this.handleRelationProbabilityChanged}
                  onRelationCountChanged={this.handleRelationCountChanged}
                  onJoinDependencyChanged={this.handleJoinDependencyChanged}
                  onKpiPredictionProbabilityChanged={this.handleKpiPredictionProbabilityChanged}
                  hideDependency
                  hideKpiPrediction
                  fixedCountAndProb={fixedCountAndProb}
                />
              )}
            </div>
          )}
        </div>
      </Container>
    );
  }
}

const CausalGroupAnalysis = injectIntl(CausalGroupAnalysisCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { projects, loadStatus } = state.app;
    const { isAdmin, userName } = state.auth.userInfo;
    const { causalGroupAnalysis } = state.causal;
    return { isAdmin, userName, location, loadStatus, projects, causalGroupAnalysis };
  },
  { push, replace, createLoadAction },
)(CausalGroupAnalysis);
