/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import moment from 'moment';
import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';

const getLogKeywordStats = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, day } = params;
  const startTimeObj = moment.utc(day, Defaults.DateFormat).startOf('day');

  const requests = [];

  requests.push(
    fetchGet(getEndpoint('logfetchkeywordtimefrequency'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
      dayString: day,
    })
      .then((d) => {
        const logKeywordFrequency = d || {};

        let maxEventsCount = 0;
        let keywordList = [];
        R.forEachObjIndexed((val, keyword) => {
          const { totalCount } = val;
          maxEventsCount = Math.max(maxEventsCount, totalCount);
          keywordList.push({
            eventsCount: totalCount,
            id: keyword,
            name: keyword,
            patternOId: null,
          });
        }, logKeywordFrequency);

        keywordList = R.sort((a, b) => (a.name || '').localeCompare(b.name), keywordList);
        R.forEach((p) => (p.countRatio = p.eventsCount / maxEventsCount), keywordList);

        return { keywordInfoList: keywordList, logKeywordFrequency };
      })
      .catch((err) => {
        console.warn('[IF_API] get keyword list failed, ignored', err);
        return {};
      }),
  );

  return Promise.all(requests).then((results) => {
    const { keywordInfoList, logKeywordFrequency } = results[0] || {};
    return { keywordInfoList, logKeywordFrequency };
  });
};

export default getLogKeywordStats;
