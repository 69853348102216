import { useEffect, useState } from 'react';

export const useExternalScript = (url) => {
  const [state, setState] = useState(url ? 'loading' : 'idle');

  useEffect(() => {
    if (!url) {
      setState('idle');
      return;
    }

    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e) => {
      setState(e.type === 'load' ? 'ready' : 'error');
    };

    if (!script) {
      script = document.createElement('script');
      script.type = 'application/javascript';
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }

    script.addEventListener('load', handleScript);
    script.addEventListener('error', handleScript);

    // eslint-disable-next-line consistent-return
    return () => {
      script.removeEventListener('load', handleScript);
      script.removeEventListener('error', handleScript);
    };
  }, [url]);

  return state;
};

const ScriptUtil = {
  useScript: (url, onload, ...rest) => {
    useEffect(() => {
      const script = document.createElement('script');

      script.src = url;
      script.onload = () => onload(...rest);

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }, [url, onload, ...rest]);
  },
};

export default ScriptUtil;
