import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import {
  CaretDownOutlined,
  CloseOutlined,
  ClusterOutlined,
  CodepenOutlined,
  CodeSandboxOutlined,
  DatabaseOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Input, Select, Spin, Tooltip } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import { AutoSizer, List, Modal } from '../../lib/fui/react';
import { EntityFilterEx } from './EntityFilterEx';
import getInstanceDisplayName from '../../common/utils/getInstanceDisplayName';

const ROW_HEIGHT = 28;

const getEntityIcon = (type) => {
  switch (type) {
    case 'component':
      return (
        <Tooltip title="Component" mouseEnterDelay={0.3} placement="top">
          <ClusterOutlined />
        </Tooltip>
      );
    case 'pod':
      return (
        <Tooltip title="Pod" mouseEnterDelay={0.3} placement="top">
          <CodeSandboxOutlined />
        </Tooltip>
      );
    case 'container':
      return (
        <Tooltip title="Container" mouseEnterDelay={0.3} placement="top">
          <CodepenOutlined />
        </Tooltip>
      );
    case 'instance':
      return (
        <Tooltip title="Instance" mouseEnterDelay={0.3} placement="top">
          <DatabaseOutlined />
        </Tooltip>
      );
    default:
      return null;
  }
};

export const BuildSelectEntityList = (entityType, allEntityTreeMap, selectionMap) => {
  const allEntityList = [];
  const allRealEntityList = [];

  const processEntity = (e, parent) => {
    const elem = { ...e, children: [], allChildren: [], parents: [] };

    const isLeaf = isEmpty(e.children);

    if (!e.isVirtual) {
      allRealEntityList.push(elem);
    }

    if (parent) {
      elem.parents = [parent.id, ...(parent.parents || [])];
    }

    allEntityList.push(elem);

    if (isLeaf) {
      return { entity: elem, allEntity: [] };
    }

    R.forEachObjIndexed((val, key) => {
      const { entity, allEntity } = processEntity(val, elem);
      if (entity) {
        elem.children.push(entity);
        elem.allChildren = [...elem.allChildren, ...(entity.isVirtual ? [] : [entity]), ...allEntity];
      }
    }, e.children || {});

    if (elem.children.length === 0) {
      if (e.isVirtual) {
        allEntityList.pop();
        return { entity: null, allEntity: [] };
      }
    }

    return { entity: elem, allEntity: elem.allChildren };
  };

  R.forEachObjIndexed((val) => processEntity(val, null), allEntityTreeMap.children || {});
  const allSelectedEntityList = R.filter((e) => {
    const { id, allChildren = [] } = e;
    const hasCheckedAllChildren = !!R.find((c) => selectionMap[c.id], allChildren);
    const checked = !!selectionMap[id];
    return checked || hasCheckedAllChildren;
  }, allEntityList);

  return { allEntityList, allSelectedEntityList, allRealEntityList };
};

export const EntityListView = ({
  showSearchType,
  entityType,
  allRawEntityList,
  allEntityTreeMap,
  hasContainer,
  selectionMap,
  onChange,
  loading = false,
  onRefresh,
  instanceDisplayNameMap = {},
  getEntityTitle = () => {},
}) => {
  const listNode = useRef(null);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    allEntityList: [],
    allSelectedEntityList: [],
    allRealEntityList: [],
    collapseMap: {},
    collapseEntityList: [],
    showEditModal: false,
    editSelectionMap: { ...selectionMap },
  });

  useEffect(() => {
    setTimeout(() => {
      const { allEntityList, allSelectedEntityList, allRealEntityList } = BuildSelectEntityList(
        entityType,
        allEntityTreeMap,
        selectionMap,
      );
      setState({ allEntityList, allRealEntityList, allSelectedEntityList, editSelectionMap: { ...selectionMap } });
    }, 10);
  }, [selectionMap, allEntityTreeMap, entityType]);

  const { allEntityList, allSelectedEntityList, collapseMap, allRealEntityList } = state;
  const { showEditModal, editSelectionMap } = state;

  useEffect(() => {
    setState({
      collapseEntityList: R.filter((e) => {
        const parents = e.parents || [];
        for (let i = 0; i < parents.length; i += 1) {
          if (collapseMap[parents[i]]) {
            return false;
          }
        }
        return true;
      }, allSelectedEntityList),
    });
  }, [allSelectedEntityList, collapseMap]);

  const { collapseEntityList } = state;

  return (
    <div className="flex-col flex-grow">
      <div className="flex-row flex-center-alignt" style={{ marginBottom: 12, alignItems: 'center' }}>
        <span className="label" style={{ fontSize: 18 }}>
          {entityType}
        </span>
        <div className="flex-grow" />
        <div className="flex-row flex-center-align" style={{ paddingRight: 8 }}>
          <div
            className="clickable"
            style={{ color: 'var(--link-color)' }}
            onClick={() => {
              onChange({});
            }}
          >
            Clear
          </div>
        </div>
        <div className="flex-row flex-center-align" style={{ paddingRight: 8 }}>
          <div
            className="clickable"
            style={{ color: 'var(--link-color)' }}
            onClick={() => {
              setState({ showEditModal: true });
            }}
          >
            More
          </div>
        </div>
        <div style={{ paddingRight: 8 }}>{(R.values(selectionMap) || []).length} selected /</div>
        <div style={{ paddingRight: 0 }}>{(allRealEntityList || []).length} total</div>
      </div>
      <div className="flex-grow">
        <Spin spinning={loading} wrapperClassName="full-height spin-full-height">
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={listNode}
                width={width}
                height={height}
                rowCount={collapseEntityList.length}
                rowHeight={ROW_HEIGHT}
                rowRenderer={({ index: idx, style }) => {
                  const entity = collapseEntityList[idx];
                  const { id, title, allChildren = [], level = 0, type } = entity;
                  const { isVirtual = false, inactive = false, hasAnomaly = false } = entity;
                  const color = hasAnomaly ? 'var(--primary-color)' : inactive ? 'gray' : 'var(--black)';
                  const realAllChildren = R.filter((c) => !c.isVirtual, allChildren);
                  const checkedAllChildren = R.filter((c) => selectionMap[c.id], realAllChildren);
                  const icon = getEntityIcon(type);
                  const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, title);
                  const hasInstanceDiaplayName = R.includes(type, ['instance', 'pod']) && instanceDisplayName;
                  const realTitle = hasInstanceDiaplayName ? instanceDisplayName : title;

                  return (
                    <div
                      key={id}
                      className="flex-row clickable hover-display"
                      style={{ ...style, alignItems: 'center' }}
                    >
                      {level > 0 && <div style={{ width: 16 * level }} />}
                      {checkedAllChildren.length === 0 && <div style={{ width: 16 }} />}
                      {checkedAllChildren.length > 0 && (
                        <div
                          style={{ width: 16 }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (collapseMap[id]) {
                              delete collapseMap[id];
                            } else {
                              collapseMap[id] = true;
                            }
                            setState({ collapseMap: { ...collapseMap } });
                          }}
                        >
                          <CaretDownOutlined rotate={collapseMap[id] ? -90 : 0} />
                        </div>
                      )}
                      {!!icon && <div style={{ width: 20, fontSize: 14, color }}>{icon}</div>}
                      <Tooltip
                        title={getEntityTitle(type, isVirtual ? title : id, instanceDisplayNameMap)}
                        mouseEnterDelay={0.3}
                        placement="right"
                        overlayInnerStyle={hasInstanceDiaplayName ? { width: 320 } : {}}
                      >
                        <div className="flex-grow hidden-line-with-ellipsis" style={{ color }}>
                          {realTitle}
                        </div>
                      </Tooltip>
                      {!isVirtual && (
                        <div className="hover-display-item" style={{ position: 'absolute', right: 0, top: 0 }}>
                          <Button
                            type="link"
                            icon={<CloseOutlined />}
                            onClick={() => {
                              delete selectionMap[id];

                              R.forEach((c) => {
                                if (!c.isVirtual) {
                                  delete selectionMap[c.id];
                                }
                              }, allChildren);
                              onChange(selectionMap);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            )}
          </AutoSizer>
        </Spin>
        {showEditModal && (
          <Modal
            title={`Select ${entityType}`}
            width={480}
            visible
            maskClosable={false}
            onCancel={() => setState({ showEditModal: false })}
            onOk={() => {
              onChange(editSelectionMap);
              setState({ showEditModal: false });
            }}
          >
            <div style={{ height: 480 }}>
              <EntityFilterEx
                showSearchType
                entityType={entityType}
                allRawEntityList={allRawEntityList}
                allEntityTreeMap={allEntityTreeMap}
                hasContainer={hasContainer}
                selectionMap={editSelectionMap}
                onChange={(selectionMap) => {
                  setState({ editSelectionMap: selectionMap });
                }}
                instanceDisplayNameMap={instanceDisplayNameMap}
                getEntityTitle={getEntityTitle}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};
