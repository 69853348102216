import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import Papa from 'papaparse';
import { round, floor, isFinite } from 'lodash';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { WarningOutlined } from '@ant-design/icons';
import { Progress } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { parseLocation, buildLocation, Defaults } from '../../../common/utils';
import { hideAppLoader } from '../../../common/app/actions';

import logo from '../../../../images/InsightFinder_Horizontal.svg';
import logoWhite from '../../../../images/InsightFinder_Horizontal_White.svg';
import { EChart } from '../../share';

type Props = {
  location: Object,
  appLoaderVisible: Boolean,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  hideAppLoader: Function,
  currentTheme: String,
};

class StatusComponayCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      showPercent: false,
      percent: 0,
      availabilityInfo: undefined,
      systemMetricList: [],
    };
  }

  componentDidMount() {
    moment.locale('en');

    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    this.reloadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = parseLocation(prevProps.location);
    const params = parseLocation(this.props.location);
    if (params.id !== prevParams.id || params.category !== prevParams.category) {
      this.reloadData();
    }
  }

  @autobind
  reloadData() {
    const { location } = this.props;
    const { id, category } = parseLocation(location);

    if (id && category) {
      if (this.timer) clearInterval(this.timer);
      this.setState({ showPercent: true, percent: 0 });
      this.timer = setInterval(() => {
        const { percent } = this.state;
        let newPercent = percent + 10;
        newPercent = R.min(newPercent, 90);
        this.setState((prevState) => ({
          percent: newPercent,
        }));
      }, 300);
      fetchGet(getEndpoint('status/company'), {
        id,
        category,
      })
        .then((d) => {
          const { success, mesage: errMsg, data } = d || {};
          if (success) {
            // build event list
            const eventList = [];
            R.forEach((item) => {
              const { metric, alertValue, splitCsvData, ...rest } = item || {};
              const csvData = splitCsvData[metric] || '';

              // parse csv data
              const paJson = Papa.parse(csvData, {
                header: true,
                skipEmptyLines: true,
                // dynamicTyping: true,
                transform: (value) => Number(value),
              });
              let sortData = paJson.data || [];
              if (sortData.length > 0) {
                sortData = R.sortWith([R.ascend(R.prop('timestamp'))], sortData);

                // build dataList and avg val
                const dataList = R.map((info) => {
                  const { timestamp, ...rest } = info || {};
                  const valueList = R.values(rest);
                  return {
                    timestamp,
                    value: valueList.length > 0 ? round(valueList[0], 2) : undefined,
                  };
                }, sortData || []);
                const avgVal = round(R.mean(R.map((item) => item.value || 0, dataList)), 2);

                eventList.push({
                  ...rest,
                  metric,
                  alertValue: Number(alertValue),
                  avgVal,
                  dataList,
                });
              }
            }, data || []);

            // build availability bar chart
            const availabilityInfo = this.buildMetricStatusOption(category, eventList);

            // build metric line chart
            const systemMetricList = [];
            R.forEach((item) => {
              const { dataList, ...rest } = item || {};
              const option = this.buildMetricOption(item, dataList);
              systemMetricList.push({
                ...rest,
                option,
              });
            }, eventList);

            // not render after switch category
            const { category: newCategory } = parseLocation(this.props.location);
            if (newCategory === category) {
              if (this.timer) clearInterval(this.timer);
              this.setState({ percent: 100, availabilityInfo, systemMetricList }, () => {
                setTimeout(() => this.setState({ showPercent: false }), 1000);
              });
            }
          } else {
            if (this.timer) clearInterval(this.timer);
            this.setState({ showPercent: false });
            console.error(String(errMsg));
          }
        })
        .catch((err) => {
          if (this.timer) clearInterval(this.timer);
          this.setState({ showPercent: false });
          console.error(err.message || String(err));
        });
    }
  }

  @autobind
  buildMetricStatusOption(category, eventList) {
    // get startTimestamp
    const startTimes = R.map((item) => (R.head(item.dataList) || {}).timestamp, eventList);
    const startTimestamp = R.filter((time) => Boolean(time), startTimes)[0];
    const endTimes = R.map((item) => (R.last(item.dataList) || {}).timestamp, eventList);
    const endTimestamp = R.filter((time) => Boolean(time), endTimes)[0];
    if (!startTimestamp || !endTimestamp) return undefined;

    let interval = 10 * 60000;
    if (category === 'week') {
      interval = 60 * 60000;
    } else if (category === 'month') {
      interval = 60 * 60000;
    }

    let missingDataCount = 0;
    let dataLen = 0;

    const dataMap = {};
    R.forEach((event) => {
      const { metricDisplayName, dataList } = event;
      R.forEach((item) => {
        const { timestamp, value } = item;
        const pointTime = floor(timestamp / interval) * interval;
        if (!R.has(pointTime, dataMap)) dataMap[pointTime] = { timestamp: pointTime, missingDataMap: {} };
        // if (alertValue > 0 && value >= alertValue) {
        //   dataMap[pointTime].anomalyMap[metricDisplayName] = true;
        // }
        if (!isFinite(value)) {
          missingDataCount += 1;
          dataMap[pointTime].missingDataMap[metricDisplayName] = true;
        }
        dataLen += 1;
      }, dataList);
    }, eventList);
    const dataList = R.sortWith([R.ascend(R.prop('timestamp'))], R.values(dataMap));

    const data = R.map((info) => {
      const { missingDataMap } = info;
      const missingDataMetrics = R.keys(missingDataMap);
      const missingData = missingDataMetrics.length > 0;

      const itemStyle = {};
      if (missingData) {
        itemStyle.color = '#abebbb';
      }
      return { value: 1, missingData, missingDataMetrics, itemStyle };
    }, dataList || []);

    const availabilityPct = dataLen > 0 ? (dataLen - missingDataCount) / dataLen : 0;

    return {
      availabilityPct,
      startTimestamp,
      endTimestamp,
      option: {
        useUTC: true,
        backgroundColor: 'transparent',
        tooltip: {
          backgroundColor: 'var(--component-background)',
          borderColor: 'transparent',
          axisPointer: { animation: false },
          position(pt) {
            return [pt[0] - 140, '110%'];
          },
          formatter: (params) => {
            const { name, data } = params || {};
            const { missingData, missingDataMetrics } = data || {};
            return ReactDOMServer.renderToStaticMarkup(
              <div className="flex-col" style={{ width: 280, color: 'var(--text-color)' }}>
                <div className="bold">{`${moment.utc(Number(name)).format('dddd, MMM DD, HH:mm')}`}</div>
                {!missingData && <div style={{}}>No missing data.</div>}
                {missingData && (
                  <div style={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}>
                    <span style={{ fontWeight: 'bold', marginRight: 8 }}>
                      <WarningOutlined style={{ color: '#faad14' }} /> Missing data metrics:
                    </span>
                    <span>{R.join(', ', missingDataMetrics)}.</span>
                  </div>
                )}
              </div>,
            );
          },
          appendToBody: true,
        },
        grid: { left: 0, right: 0, top: 0, bottom: 0 },
        xAxis: {
          show: false,
          type: 'category',
          data: R.map((item) => item.timestamp, dataList || []),
        },
        yAxis: {
          show: false,
          type: 'value',
        },
        series: [{ type: 'bar', barWidth: '60%', data, itemStyle: { color: '#4dc771' } }],
      },
    };
  }

  @autobind
  buildMetricOption(item, dataList) {
    const data = R.map((info) => {
      return {
        name: info.timestamp,
        value: [info.timestamp, info.value],
      };
    }, dataList || []);

    return {
      useUTC: true,
      backgroundColor: 'transparent',
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        axisPointer: { animation: false },
        padding: [4, 8],
        textStyle: { fontSize: 10 },
        position(pt) {
          return [30, '0%'];
        },
        formatter: (params) => {
          const { value } = params[0];
          return ReactDOMServer.renderToStaticMarkup(
            <div style={{ color: 'var(--text-color)' }}>{`${moment.utc(value[0]).format('dddd, MMM DD, HH:mm')}: ${
              value[1]
            }${item.unit ? ` ${item.unit}` : ''}`}</div>,
          );
        },
      },
      grid: { left: 45, right: 10, top: 10, bottom: 20 },
      xAxis: {
        type: 'time',
        boundaryGap: false,
      },
      yAxis: {
        type: 'value',
        // position: 'right',
        boundaryGap: [0, '100%'],
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [{ type: 'line', showSymbol: false, data }],
    };
  }

  @autobind
  handleCategoryClick(category) {
    const { replace, location } = this.props;
    const params = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...params, category }));
  }

  render() {
    const { location, currentTheme } = this.props;
    const { category } = parseLocation(location);
    const { showPercent, percent, availabilityInfo, systemMetricList } = this.state;

    return (
      <div className="full-width full-height overflow-y-auto content-bg">
        <div style={{ width: '100%', margin: '0 auto', background: 'rgba(0, 0, 0, 0.85)', padding: '45px 0 100px 0' }}>
          <div className="flex-row flex-center-justify">
            <img src={logoWhite} alt="logo" style={{ height: 40 }} />
          </div>
        </div>

        <div style={{ width: '90%', maxWidth: 1100, margin: '20px auto 0 auto' }}>
          <div
            style={{
              boxShadow: '0 20px 60px rgb(0 0 0 / 10%)',
              borderRadius: 6,
              color: '#131a26',
              background: 'white',
              padding: 60,
              marginTop: -77,
            }}
            className="flex-row flex-center-align flex-space-between"
          >
            <div style={{ fontSize: 36 }} className="flex-row flex-center-align flex-space-between bold">
              <div className="flex-row flex-center-align flex-space-between">
                <div className="inline-block" style={{ position: 'relative', width: 60, height: 60, marginRight: 30 }}>
                  <span
                    style={{ background: '#3bd671', position: 'absolute', width: 60, height: 60, borderRadius: '50%' }}
                    className="inline-block"
                  />
                  <span
                    style={{ background: '#3bd671', position: 'absolute', width: 60, height: 60, borderRadius: '50%' }}
                    className="inline-block dot-is-pig"
                  />
                </div>
                All systems <span style={{ color: 'var(--green)', marginLeft: 10 }}>operational</span>
              </div>
            </div>
            {availabilityInfo?.availabilityPct && (
              <div style={{ fontSize: 18, fontWeight: 'bold', color: '#abebbb' }}>Down alert</div>
            )}
          </div>
          <div className="flex-row" style={{ lineHeight: '24px', marginBottom: 12, marginTop: 30 }}>
            <div
              className="flex-grow bold hidden-line-with-ellipsis"
              style={{ fontSize: 28, lineHeight: '38px', marginRight: 24 }}
            >
              System Metrics
            </div>
            <div className="font-16 bold" style={{ marginTop: 10 }}>
              <span
                style={{ color: category === 'day' ? 'var(--primary-color-active)' : '', cursor: 'pointer' }}
                onClick={() => this.handleCategoryClick('day')}
              >
                Day
              </span>
              <span
                style={{
                  marginLeft: 16,
                  paddingLeft: 16,
                  borderLeft: '1px solid var(--tag-border-color)',
                  color: category === 'week' ? 'var(--primary-color-active)' : '',
                  cursor: 'pointer',
                }}
                onClick={() => this.handleCategoryClick('week')}
              >
                Week
              </span>
              <span
                style={{
                  marginLeft: 16,
                  paddingLeft: 16,
                  borderLeft: '1px solid var(--tag-border-color)',
                  color: category === 'month' ? 'var(--primary-color-active)' : '',
                  cursor: 'pointer',
                }}
                onClick={() => this.handleCategoryClick('month')}
              >
                Month
              </span>
            </div>
          </div>

          {showPercent && <Progress percent={percent} />}

          {availabilityInfo && (
            <div
              style={{
                marginTop: '1.5rem',
                // border: '1px solid var(--tag-border-color)',
                padding: '40px 60px',
                boxShadow: '0 20px 60px rgb(0 0 0 / 10%)',
                borderRadius: 6,
                marginBottom: 40,
              }}
              className="corner-8"
            >
              <div
                className="flex-row flex-space-between"
                style={{ lineHeight: '28px', fontSize: 20, marginBottom: '1rem', overflow: 'hidden' }}
              >
                <div className="flex-grow flex-min-width hidden-line-with-ellipsis inline-block">Availability</div>
              </div>
              <EChart width="100%" height="60px" option={availabilityInfo.option} onEvents={{}} theme={currentTheme} />
              <div className="flex-row flex-space-between font-14" style={{ lineHeight: '24px', marginTop: 8 }}>
                <div>{moment.utc(availabilityInfo.startTimestamp).format(Defaults.ShortTimeFormat)}</div>
                <div
                  style={{
                    flex: 1,
                    background: 'var(--tag-border-color)',
                    opacity: 0.6,
                    height: 2,
                    margin: '12px 16px 0 16px',
                  }}
                />
                <div>{numeral(availabilityInfo.availabilityPct).format('0.0%')} availability</div>
                <div
                  style={{
                    flex: 1,
                    background: 'var(--tag-border-color)',
                    opacity: 0.6,
                    height: 2,
                    margin: '12px 16px 0 16px',
                  }}
                />
                <div>{moment.utc(availabilityInfo.endTimestamp).format(Defaults.ShortTimeFormat)}</div>
              </div>
            </div>
          )}

          <div style={{ boxShadow: '0 20px 60px rgb(0 0 0 / 10%)', borderRadius: 6, padding: 60 }}>
            {R.addIndex(R.map)((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    borderBottom: '1px solid var(--tag-border-color)',
                    padding: '1.5rem 1.5rem 2rem',
                    marginTop: 30,
                  }}
                >
                  <div
                    className="flex-row flex-space-between"
                    style={{ lineHeight: '28px', fontSize: 20, marginBottom: '1rem', overflow: 'hidden' }}
                  >
                    <div className="flex-grow flex-min-width hidden-line-with-ellipsis inline-block">
                      {item.metricDisplayName || item.metric}
                    </div>
                    {item.unit && <div style={{ marginLeft: 24 }}>{`${item.avgVal} ${item.unit}`}</div>}
                  </div>
                  <EChart width="100%" height="120px" option={item.option} onEvents={{}} theme={currentTheme} />
                </div>
              );
            }, systemMetricList || [])}
          </div>

          <div
            className="flex-row flex-space-between font-14"
            style={{
              marginTop: 70,
              paddingBottom: 70,
              paddingTop: 12,
              borderTop: '1px solid var(--tag-border-color)',
            }}
          >
            <div />
            <div>Powered by Insightfinder</div>
          </div>
        </div>
      </div>
    );
  }
}

const StatusComponay = injectIntl(StatusComponayCore);
export default connect(
  (state) => {
    const { location } = state.router;
    return {
      location,
      appLoaderVisible: state.app.appLoaderVisible,
      currentTheme: state.app.currentTheme,
    };
  },
  { push, replace, hideAppLoader },
)(StatusComponay);
