import React, { useContext, useEffect, useReducer, useState } from 'react';
import * as R from 'ramda';
import { Spin, Tag } from 'antd';

import { Popover } from '../../../lib/fui/react';

import { rootCausePopoverHtml } from './OptionHtml';

import { appFieldsMessages } from '../../../common/app/messages';

export default function BuildCausesInfo({
  list,
  style,
  type,
  echartRef,
  optionAll,
  optionDetail,
  echartsAxiosDebugger,
  setEchartsAxiosDebugger,
  getOptionDetail,
  intl,
  currentTheme,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    newList: [],
    refresh: false,
  });
  const { newList, refresh } = state;

  useEffect(() => {
    if (refresh) {
      setTimeout(() => setState({ refresh: false }));
    }
  }, [refresh]);

  useEffect(() => {
    setState({
      newList: R.take(
        4,
        R.map((item) => ({ ...item.rootCause, count: item.count, loading: false }), list || []),
      ),
    });
  }, [list]);

  const mouseLinkageCharts = ({ item, mouse }) => {
    if (echartRef) {
      const detailKey = `${item.instanceName}-${item.patternId}`;
      const { data } = optionAll?.series[0] || [];
      const dataIndex = R.findIndex(
        (_item) => `${_item?.rootCause?.instanceName}-${_item?.rootCause?.patternId}` === detailKey,
        data,
      );
      const echartsInstance = echartRef.getEchartsInstance();
      echartsInstance.dispatchAction({ type: mouse === 'over' ? 'highlight' : 'downplay', seriesIndex: 0, dataIndex });
    }
  };

  return (
    <>
      {newList.length > 0 && (
        <div style={{ overflow: 'hidden auto', flex: 1 }}>
          <div className="chart-info-wrap" style={{ ...style, marginLeft: 14 }}>
            <div className="flex-row flex-space-between" style={{ marginBottom: 10, fontSize: 15, lineHeight: '15px' }}>
              <div style={{ fontWeight: 'bold' }}>{intl.formatMessage(appFieldsMessages.instanceName)}</div>
            </div>
            {R.addIndex(R.map)((item, index) => {
              const detailKey = `${type}-${item?.instanceName || ''}-${item?.patternId || ''}-${item.count}`;
              return (
                <div
                  className="chart-info-group flex-row flex-center-align flex-space-between clickable"
                  key={`${type}-${item.instanceName}-${item.patternId}-${index}`}
                >
                  <Popover
                    title={null}
                    overlayClassName="popover-set-loading-width"
                    overlayStyle={{ maxWidth: 380 }}
                    placement="top"
                    content={
                      <Spin spinning={item.loading} style={{ width: 350 }}>
                        {rootCausePopoverHtml({
                          rowData: item,
                          detailKey,
                          optionDetail: optionDetail || {},
                          intl,
                          currentTheme,
                        })}
                      </Spin>
                    }
                    onVisibleChange={(visible) => {
                      if (visible) {
                        mouseLinkageCharts({ item, mouse: 'over' });
                        if (!R.has(detailKey, optionDetail)) {
                          if (echartsAxiosDebugger) {
                            setEchartsAxiosDebugger(false);
                            item.loading = true;
                            setState({ refresh: true });
                            getOptionDetail(item, detailKey, () => {
                              item.loading = false;
                              setState({ refresh: true });
                            });
                          }
                        } else {
                          setState({ refresh: true });
                        }
                      } else {
                        mouseLinkageCharts({ item, mouse: 'out' });
                      }
                    }}
                  >
                    <div className="flex-row flex-center-align">
                      <span
                        className="hidden-line-with-ellipsis-multiline"
                        style={{ wordBreak: 'break-word', padding: '0 8px 0 0', width: 'fit-content' }}
                      >
                        {`[${item.patternId}] ${item.instanceName}`}
                      </span>
                    </div>
                  </Popover>
                  <Tag size="small" style={{ borderRadius: 100, marginLeft: 4, fontWeight: 'bold', flexShrink: 0 }}>
                    {item.count}
                  </Tag>
                </div>
              );
            }, newList)}
          </div>
        </div>
      )}
      {newList.length < 1 && (
        <div className="insights-no-data" style={{ color: 'var(--text-color-secondary)' }}>
          No causes for this time period
        </div>
      )}
    </>
  );
}
