/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import loadMetricEventLineChartsLogic from './loadMetricEventLineChartsLogic';
import loadMetricEventLineChartsBaselineLogic from './loadMetricEventLineChartsBaselineLogic';
import loadMetricEventLineChartsInstanceMetaDataLogic from './loadMetricEventLineChartsInstanceMetaDataLogic';
import loadMetricLineChartsLogic from './loadMetricLineChartsLogic';
import loadMetricLineChartsDetailsLogic from './loadMetricLineChartsDetailsLogic';
import loadEventListLogic from './loadEventListLogic';
import loadAnomalyLogEventsLogic from './loadAnomalyLogEventsLogic';
import saveEventPublishLogic from './saveEventPublishLogic';
import loadMetricCapacityPlanningLogic from './loadMetricCapacityPlanningLogic';
import loadMetricCapacityPlanningCalendarLogic from './loadMetricCapacityPlanningCalendarLogic';
import loadMetricCapacityScoreRealTimeLogic from './loadMetricCapacityScoreRealTimeLogic';
import loadEventHistoryLogic from './loadEventHistoryLogic';
import loadJiraProjectsLogic from './loadJiraProjectsLogic';
import saveJiraIussueLogic from './saveJiraIussueLogic';
import loadJiraProjectAssignersLogic from './loadJiraProjectAssignersLogic';
import loadEventAlertLogic from './loadEventAlertLogic';
import saveEventAlertLogic from './saveEventAlertLogic';
import removeEventAlertLogic from './removeEventAlertLogic';
import loadEventActionLogic from './loadEventActionLogic';
import saveEventActionLogic from './saveEventActionLogic';
import removeEventActionLogic from './removeEventActionLogic';

export default [
  loadMetricEventLineChartsLogic,
  loadMetricEventLineChartsBaselineLogic,
  loadMetricEventLineChartsInstanceMetaDataLogic,
  loadMetricLineChartsDetailsLogic,
  loadMetricLineChartsLogic,
  loadEventListLogic,
  loadAnomalyLogEventsLogic,
  saveEventPublishLogic,
  loadMetricCapacityPlanningLogic,
  loadMetricCapacityPlanningCalendarLogic,
  loadMetricCapacityScoreRealTimeLogic,
  loadEventHistoryLogic,
  loadJiraProjectsLogic,
  saveJiraIussueLogic,
  loadJiraProjectAssignersLogic,
  loadEventAlertLogic,
  saveEventAlertLogic,
  removeEventAlertLogic,
  loadEventActionLogic,
  saveEventActionLogic,
  removeEventActionLogic,
];
