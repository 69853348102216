/* @flow */
import { get } from 'lodash';

/* eslint-disable no-console */
import { HttpError, NetworkError, BadRequestError, NoAuthError, PermissionError, NotFoundError } from '../errors';

const getErrMsg = async (resp) => {
  let errMsg;
  let responseJson;

  const response = await resp.text();
  try {
    responseJson = JSON.parse(response);
    errMsg = get(responseJson, 'message', resp.statusText);
  } catch {
    errMsg = response || resp.statusText;
  }

  return { errMsg, responseJson };
};

/**
 * General response handler for fetch command.
 * The response json format is:
 * {
 *   success: true|false
 *   data: object,  // Data object if success is true.
 *   error: string // Error messages if success is false.
 * }
 *
 * @param {Promise} command The promise of the fetch command.
 */
const fetchPostJsonHandler = (command: Object) =>
  command
    .catch((err) => {
      throw new NetworkError(err);
    })
    .then(async (resp) => {
      if (resp.ok) {
        return resp;
      } else if (resp.status === 422) {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new HttpError(errMsg, resp.status, resp, responseJson);
      } else if (resp.status === 400) {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new BadRequestError(errMsg, resp.status, resp, responseJson);
      } else if (resp.status === 401) {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new NoAuthError(errMsg, resp.status, resp, responseJson);
      } else if (resp.status === 403) {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new PermissionError(errMsg, resp.status, resp, responseJson);
      } else if (resp.status === 404) {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new NotFoundError(errMsg, resp.status, resp, responseJson);
      } else {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new HttpError(errMsg, resp.status, resp, responseJson);
      }
    })
    .then((resp) => {
      return resp;
    });

export default fetchPostJsonHandler;
