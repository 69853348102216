/* @flow */
import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import { autobind } from 'core-decorators';
import { get, isNumber } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DeleteOutlined, FileAddOutlined, EditOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import {
  Select,
  Spin,
  Button,
  notification,
  Pagination,
  Popconfirm,
  Checkbox,
  message,
  Form,
  Input,
  InputNumber,
  Alert,
} from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import {
  Defaults,
  parseLocation,
  parseJSON,
  LogRenderers,
  CellRenderers,
  CausalParser,
  sleep,
  EventRenderers,
  BackgroundCall,
} from '../../../common/utils';
import {
  Modal,
  AutoSizer,
  List,
  CellMeasurerCache,
  CellMeasurer,
  Table,
  Column,
  Popover,
  SortDirection,
} from '../../../lib/fui/react';
import { updateLastActionInfo, createSetAction } from '../../../common/app/actions';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';

import GlobalSystemKnowledgeBaseActionModal from './GlobalSystemKnowledgeBaseActionModal';

type Props = {
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  tabName: String,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  isLoadingSystem: Boolean,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: String,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  projectInstanceComponentMap: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  globalInfo: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createSetAction: Function,
  currentTheme: String,
};

class KnowledgeBaseRuleManualListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

    this.state = {
      isLoaded: false,
      isLoading: false,
      isLoadingLocalList: false,
      isSubmitting: false,

      page: 1,
      pageSize: 20,
      checkAll: false,
      rootCauseProject: undefined,
      filterIncidentComponent: undefined,
      filterIncidentInstance: undefined,
      filterSourcePatternIds: [],
      filterPatternId: null,
      filterStatus: null,

      predictionRuleList: [],
      filterPredictionRuleList: [],
      incidentList: [],
      activeIncidentKey: undefined,
      incidentPredictionRuleList: [],
      localPredictionRuleList: [],

      // add rule
      showAddNewRule: false,
      activeEvent: undefined,
      isSubmitingNewRule: false,

      // config action
      showSystemPredictionRuleActionModal: false,
      actionProjectNameList: [],
      actionKeySet: [],
      actionIncidentPredictionLibKeys: [],
      systemProjectNameList: [],

      sortBy: null,
      sortDirection: null,
    };
    this.projectList = [];
    this.cmp = (a, b) => a.key === b.key && a.statusFlag === b.statusFlag;
    this.incidentComponentListOptions = [];
    this.incidentInstanceListOptions = [];
    this.sourcePatternListOptions = [];
    this.patternListOptions = [];
    this.statusOptions = [
      { value: -1, label: intl.formatMessage(eventMessages.ignored) },
      { value: 1, label: intl.formatMessage(settingsMessages.confirmed) },
    ];
  }

  componentDidMount() {
    this.resetProject();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const prevQuery = parseLocation(prevProps.location);
    const query = parseLocation(location);
    if (
      prevProps.refresh !== this.props.refresh ||
      prevQuery.refreshTime !== query.refreshTime ||
      prevQuery.environmentId !== query.environmentId ||
      prevQuery.customerName !== query.customerName ||
      prevQuery.systemId !== query.systemId ||
      prevQuery.reloadSystem !== query.reloadSystem
    ) {
      this.resetProject();
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
    let { incidentList } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        incidentList = R.sortWith([R.ascend(R.prop(sortBy))])(incidentList);
        if (sortDirection === SortDirection.DESC) {
          incidentList = R.sortWith([R.descend(R.prop(sortBy))])(incidentList);
        }
        this.setState({ incidentList });
      }
    }
  }

  @autobind
  resetProject() {
    const { location, globalInfo } = this.props;
    const { rootCauseProject } = this.state;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    let projectNameSet = get(systemInfo, ['projectNameSet'], []);
    projectNameSet = R.filter((item) => item.dataType === 'Metric' || item.dataType === 'Log', projectNameSet);
    const projectNames = R.map((p) => p.projectNameReal, projectNameSet);
    this.projectList = projectNameSet;
    if (!rootCauseProject || (rootCauseProject && !projectNames.includes(rootCauseProject))) {
      const rootCauseProject = this.projectList.length > 0 ? this.projectList[0].projectNameReal : undefined;
      this.setState({ rootCauseProject }, () => {
        this.reloadData();
      });
    } else {
      this.reloadData();
    }
  }

  @autobind
  reloadData() {
    const { location, userInfo, credentials, globalInfo, projects } = this.props;
    const { environmentId, startTime, endTime, customerName, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const { rootCauseProject } = this.state;

    if (
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      startTime &&
      endTime &&
      systemId &&
      systemInfo &&
      systemInfo.hasAllInstanceInfo &&
      rootCauseProject
    ) {
      this.setState({ isLoading: true, page: 1 });
      this.props.updateLastActionInfo();
      Promise.all([
        fetchGet(getEndpoint('predictionrulegeneric'), {
          ...credentials,
          projectName: rootCauseProject,
        }),
      ])
        .then((results) => {
          // get all instance info and component info
          const environment = R.find((e) => e.id === environmentId, globalInfo);
          const systemList = get(environment, 'systemList', []);
          const systemInfo = R.find((system) => system.id === systemId, systemList);
          const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

          let predictionRuleList = [];
          let incidentComponents = [];
          let incidentInstances = [];
          let patternIdsSource = [];
          let patternIds = [];

          // parse data
          R.forEach((result) => {
            R.addIndex(R.forEach)((item, idx) => {
              const { incidentPredictionLibPrimaryKey, projectActionKeySet, predictionRules } = item;
              const { contentMatchKey, key } = incidentPredictionLibPrimaryKey;
              const { projectName: srcProjectName, instanceName, userName } = key;
              const sourceProjectName = `${srcProjectName}@${userName}`;
              const sourceInstanceName = instanceName;
              R.forEach((rule) => {
                const { statusFlag, incidentRule, ...rest } = rule;
                const { componentName, metricInstanceName, patternId, patternName, incidentData } = incidentRule;
                const incidentCompositeKey = get(incidentRule, ['incidentKey', 'incidentCompositeKey'], {});
                const { projectName, userName, id } = incidentCompositeKey;
                const incidentProjectName = `${projectName}@${userName}`;
                const incidentInstanceName = id;
                const incidentComponent =
                  get(instanceComponentMap, metricInstanceName) ||
                  get(instanceComponentMap, incidentInstanceName) ||
                  componentName;
                const incidentPatternId = patternId;
                const incidentPatternName = patternName;
                const { patternNameStr: incidentPatternNameStr } = Defaults.PatternIdNameStr(
                  { patternName: incidentPatternName, patternId: incidentPatternId },
                  { hasFullName: true },
                );
                const mergeKey = `${projectName}@${userName}-${incidentInstanceName}-${patternId}`;

                // parse predictionSourceInfo
                const sourcePatternIds = [];
                let predictionSourceInfo = incidentRule.predictionSourceInfo || [];
                predictionSourceInfo = R.map((item) => {
                  const { sourceProjectName } = item;
                  const project = R.find((project) => project.projectShortName === sourceProjectName, projects || []);

                  const sourceDetail = parseJSON(item.sourceDetail) || {};
                  const { nid, patternName } = sourceDetail;
                  const { patternNameStr: sourcePatternNameStr } = Defaults.PatternIdNameStr(
                    { patternName, patternId: nid },
                    { hasFullName: true },
                  );

                  let { type } = sourceDetail;
                  let { isLogType, eventType } = CausalParser.getRelationLogType(type);
                  if (project && project.isDeployment) {
                    type = 'deployment';
                    isLogType = true;
                    eventType = 'deployment';
                  }

                  sourcePatternIds.push(nid);

                  return {
                    ...item,
                    sourceDetail: { ...sourceDetail, type, isLogType, eventType },
                    patternId: nid,
                    patternName,
                    sourcePatternNameStr,
                  };
                }, predictionSourceInfo);
                const sourceInfo = predictionSourceInfo[0] || {};
                const sourceDetail = sourceInfo.sourceDetail || {};

                let allSourcePatternIds = sourcePatternIds;
                allSourcePatternIds = R.sort((a, b) => a - b, R.uniq(allSourcePatternIds));
                let allPatternIds = [incidentPatternId];
                allPatternIds = R.sort((a, b) => a - b, R.uniq(allPatternIds));

                incidentComponents = [...incidentComponents, incidentComponent];
                incidentInstances = [...incidentInstances, incidentInstanceName];
                patternIdsSource = [...patternIdsSource, ...allSourcePatternIds];
                patternIds = [...patternIds, ...allPatternIds];

                const key =
                  `${sourceProjectName}-${sourceInstanceName}-${contentMatchKey}` +
                  `-${incidentProjectName}-${incidentInstanceName}-${incidentPatternId}`;
                predictionRuleList.push({
                  rawIndex: idx,
                  key,
                  mergeKey,

                  incidentPredictionLibPrimaryKey,
                  projectActionKeySet,
                  ...rest,
                  // incident info
                  incidentCompositeKey,
                  incidentProjectName,
                  incidentComponent,
                  incidentInstanceName,
                  incidentPatternId,
                  incidentPatternName,
                  incidentPatternNameStr,
                  incidentData,

                  // prediction source info
                  projectName: sourceProjectName,
                  componentName: get(
                    instanceComponentMap,
                    sourceInfo.sourceInstanceName,
                    sourceInfo.sourceInstanceName,
                  ),
                  instanceName: sourceInfo.sourceInstanceName,
                  contentMatchKey,
                  sourcePatternIds,
                  statusFlag,
                  sourceDetail,
                  predictionSourceInfo,

                  // filter used info
                  allSourcePatternIds,
                  allPatternIds,
                });
              }, predictionRules || []);
            }, result);
          }, results);
          predictionRuleList = R.sortWith(
            [R.descend(R.prop('matchedCount')), R.ascend(R.prop('incidentPatternId'))],
            predictionRuleList,
          );
          incidentComponents = R.sort(
            (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
            R.uniq(incidentComponents),
          );
          incidentInstances = R.sort(
            (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
            R.uniq(incidentInstances),
          );
          patternIdsSource = R.sort((a, b) => a - b, R.uniq(patternIdsSource));
          patternIds = R.sort((a, b) => a - b, R.uniq(patternIds));

          this.incidentComponentListOptions = R.map(
            (component) => ({ value: component, label: component }),
            incidentComponents,
          );
          this.incidentInstanceListOptions = R.map(
            (instance) => ({ value: instance, label: instance }),
            incidentInstances,
          );
          this.sourcePatternListOptions = R.map(
            (patternId) => ({ value: patternId, label: patternId }),
            patternIdsSource,
          );
          this.patternListOptions = R.map((patternId) => ({ value: patternId, label: patternId }), patternIds);

          // build root cause list
          const filterPredictionRuleList = this.filterData(predictionRuleList);

          // build incident list
          const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
            this.buildIncidentList(filterPredictionRuleList);

          this.setState({
            isLoaded: true,
            isLoading: false,
            predictionRuleList,

            filterPredictionRuleList,
            incidentList,
            activeIncidentKey,
            incidentPredictionRuleList,
            localPredictionRuleList,
          });
        })
        .catch((err) => {
          this.incidentComponentListOptions = [];
          this.incidentInstanceListOptions = [];
          this.sourcePatternListOptions = [];
          this.patternListOptions = [];
          this.setState({
            isLoaded: true,
            isLoading: false,
            predictionRuleList: [],
            filterPredictionRuleList: [],
            incidentList: [],
            activeIncidentKey: undefined,
            incidentPredictionRuleList: [],
            localPredictionRuleList: [],
          });
        });
    } else {
      this.incidentComponentListOptions = [];
      this.incidentInstanceListOptions = [];
      this.sourcePatternListOptions = [];
      this.patternListOptions = [];
      this.setState({
        isLoaded: true,
        isLoading: false,
        predictionRuleList: [],
        filterPredictionRuleList: [],
        incidentList: [],
        activeIncidentKey: undefined,
        incidentPredictionRuleList: [],
        localPredictionRuleList: [],
      });
    }
  }

  @autobind
  filterData(predictionRuleList) {
    const { filterIncidentComponent, filterIncidentInstance, filterSourcePatternIds, filterPatternId, filterStatus } =
      this.state;
    let filterPredictionRuleList = predictionRuleList || [];

    if (filterIncidentComponent) {
      filterPredictionRuleList = R.filter(
        (rule) => rule.incidentComponent === filterIncidentComponent,
        filterPredictionRuleList,
      );
    }
    if (filterIncidentInstance) {
      filterPredictionRuleList = R.filter(
        (rule) => rule.incidentInstanceName === filterIncidentInstance,
        filterPredictionRuleList,
      );
    }
    if (filterSourcePatternIds && filterSourcePatternIds.length > 0) {
      filterPredictionRuleList = R.filter((rule) => {
        // either of filterSourcePatternIds is include
        // const hasSearchVal = R.find((item) => filterSourcePatternIds.includes(item), rule.allSourcePatternIds);
        // need both include filterSourcePatternIds
        const hasSearchVal = R.difference(filterSourcePatternIds, rule.allSourcePatternIds).length === 0;
        return hasSearchVal;
      }, filterPredictionRuleList);
    }
    if (filterPatternId) {
      filterPredictionRuleList = R.filter(
        (rule) => rule.allPatternIds.includes(filterPatternId),
        filterPredictionRuleList,
      );
    }
    if (isNumber(filterStatus)) {
      filterPredictionRuleList = R.filter((rule) => rule.statusFlag === filterStatus, filterPredictionRuleList);
    }

    return filterPredictionRuleList;
  }

  @autobind
  buildIncidentList(filterPredictionRuleList) {
    const incidentListMap = {};
    R.forEach((item) => {
      const {
        mergeKey,
        incidentCompositeKey,
        incidentProjectName,
        incidentComponent,
        incidentInstanceName,
        incidentPatternId,
        incidentPatternName,
        incidentPatternNameStr,
        incidentData,
        matchedCount,
      } = item;
      if (!R.has(mergeKey, incidentListMap)) {
        incidentListMap[mergeKey] = [];
      }
      incidentListMap[mergeKey].push({
        mergeKey,
        incidentCompositeKey,
        incidentProjectName,
        incidentComponent,
        incidentInstanceName,
        incidentPatternId,
        incidentPatternName,
        incidentPatternNameStr,
        incidentData,

        matchedCount,
      });
    }, filterPredictionRuleList);

    let incidentList = [];
    R.forEachObjIndexed((val) => {
      const info = val[0] || {};
      const matchedCount = R.sum(R.map((item) => item.matchedCount, val));
      const rootCauseCount = val.length;
      incidentList.push({
        ...info,

        matchedCount,
        rootCauseCount,
      });
    }, incidentListMap);

    incidentList = R.sortWith([R.descend(R.prop('matchedCount')), R.ascend(R.prop('incidentPatternId'))], incidentList);
    // reset incident key
    const incidentListKeys = R.map((item) => item.mergeKey, incidentList);
    let { activeIncidentKey } = this.state;
    if (incidentListKeys.indexOf(activeIncidentKey) === -1) {
      activeIncidentKey = incidentListKeys[0];
    }

    // get prediction rules by incident
    const incidentPredictionRuleList = R.filter(
      (item) => item.mergeKey === activeIncidentKey,
      filterPredictionRuleList,
    );

    // get local prediciton rules by page
    const { page, pageSize } = this.state;
    const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);

    return { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList };
  }

  @autobind
  handleAddNewRule() {
    this.setState({ showAddNewRule: true, activeEvent: undefined });
  }

  @autobind
  handleAddNewRuleSave({
    data,
    componentName,
    metricName,
    metricValue,
    metricPercentage,
    metricSign,
    delay,
    dataType,
    logMessage,
  }) {
    const { intl, credentials, location } = this.props;
    const { systemId } = parseLocation(location);
    const { rootCauseProject } = this.state;
    this.setState({ isSubmitingNewRule: true });
    this.props.updateLastActionInfo();
    const projectNameSplit = rootCauseProject.split('@');
    const requestData = {
      ...credentials,
      operation: 'create',
      systemName: systemId,
      projectName: projectNameSplit[0],
      customerName: projectNameSplit[1],
      data,
      type: dataType,
      metricInfo: JSON.stringify({
        componentName,
        metricName,
        metricValue,
        metricPercentage,
        metricSign,
        delay,
      }),
    };
    if (R.equals(dataType, 'Log')) {
      delete requestData.metricInfo;
      delete requestData.userName;

      requestData.logInfo = JSON.stringify({
        data: logMessage,
        componentName,
        delay,
      });
    }
    fetchPost(getEndpoint('predictionrulegeneric'), requestData)
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmitingNewRule: false, showAddNewRule: false });
          this.reloadData();
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
          this.setState({ isSubmitingNewRule: false });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isSubmitingNewRule: false });
      });
  }

  @autobind
  handleEditRuleClick(rowData) {
    const { incidentData, predictionSourceInfo } = rowData;
    const { sourceDetail, componentName, delay } = predictionSourceInfo[0] || {};
    const { content, avgValue, metricDirection, percentage } = sourceDetail || {};
    let metricSign = 'lower';
    if (['higher', 'positive'].includes(metricDirection)) {
      metricSign = 'higher';
    }
    const activeEvent = {
      rowData,

      data: incidentData,
      componentName,
      metricName: content,
      metricValue: avgValue,
      metricPercentage: Math.abs(percentage),
      metricSign,
      delay: delay / 60000,
      logMessage: content,
    };
    this.setState({ showAddNewRule: true, activeEvent });
  }

  @autobind
  handleEditRuleSave({
    data,
    componentName,
    metricName,
    metricValue,
    metricPercentage,
    metricSign,
    delay,
    dataType,
    logMessage,
  }) {
    const { intl, credentials } = this.props;
    const { activeEvent } = this.state;
    const {
      projectName,
      instanceName,
      contentMatchKey,
      incidentProjectName,
      incidentInstanceName,
      incidentPatternId,
      statusFlag,
    } = activeEvent.rowData || {};
    const rule = {
      projectName,
      instanceName: instanceName || incidentInstanceName,
      contentMatchKey,
      incidentProjectName,
      incidentInstanceName,
      incidentPatternId,
      statusFlag,
    };
    this.setState({ isSubmitingNewRule: true });
    this.props.updateLastActionInfo();
    const requestData = {
      ...credentials,
      type: dataType,
      operation: 'edit',
      rule: JSON.stringify(rule),
      data,
      metricInfo: JSON.stringify({ componentName, metricName, metricValue, metricPercentage, metricSign, delay }),
    };
    if (R.equals(dataType, 'Log')) {
      delete requestData.metricInfo;
      requestData.logInfo = JSON.stringify({
        componentName,
        delay,
        data: logMessage,
      });
    }
    fetchPost(getEndpoint('predictionrulegeneric'), requestData)
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmitingNewRule: false, showAddNewRule: false });
          this.reloadData();
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
          this.setState({ isSubmitingNewRule: false });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isSubmitingNewRule: false });
      });
  }

  @autobind
  handleSaveClick() {
    const { intl, credentials } = this.props;
    const { filterPredictionRuleList, localPredictionRuleList } = this.state;

    this.setState({ isSubmitting: true });
    let diff = R.differenceWith(this.cmp, localPredictionRuleList, filterPredictionRuleList);
    diff = R.map((item) => {
      const {
        projectName,
        instanceName,
        contentMatchKey,
        incidentProjectName,
        incidentInstanceName,
        incidentPatternId,
        statusFlag,
      } = item;
      return {
        projectName,
        instanceName: instanceName || incidentInstanceName,
        contentMatchKey,
        incidentProjectName,
        incidentInstanceName,
        incidentPatternId,
        statusFlag,
      };
    }, diff);

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('predictionrulegeneric'), {
      ...credentials,
      rules: JSON.stringify(diff),
      operation: 'update',
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData();
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  handleRuleRemove() {
    const { intl, credentials } = this.props;
    const { rootCauseProject } = this.state;
    const { localPredictionRuleList } = this.state;

    this.setState({ isSubmitting: true });
    let diff = R.filter((item) => item.checked, localPredictionRuleList);
    diff = R.map((item) => {
      const {
        projectName,
        instanceName,
        contentMatchKey,
        incidentProjectName,
        incidentInstanceName,
        incidentPatternId,
      } = item;
      return {
        projectName,
        instanceName: instanceName || incidentInstanceName,
        contentMatchKey,
        incidentProjectName,
        incidentInstanceName,
        incidentPatternId,
      };
    }, diff);

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('predictionrulegeneric'), {
      ...credentials,
      operation: 'delete',
      projectName: rootCauseProject,
      rules: JSON.stringify(diff),
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData();
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  handleRuleRemoveAll() {
    const { intl } = this.props;
    const { rootCauseProject } = this.state;
    Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <>
          <div>{intl.formatMessage(appMessages.removeProjectAllRule, { projectName: rootCauseProject })}</div>
          <div>{intl.formatMessage(appMessages.continueConfirm)}</div>
        </>
      ),
      onOk: this.handleRuleRemoveAllConfirm,
    });
  }

  @autobind
  handleRuleRemoveAllConfirm(close) {
    const { intl, credentials } = this.props;
    const { rootCauseProject } = this.state;
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('predictionrulegeneric'), {
      ...credentials,
      projectName: rootCauseProject,
      operation: 'deleteAll',
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData();
        close();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        close();
      });
  }

  @autobind
  onChangeRootCauseProject(rootCauseProject) {
    this.setState({ rootCauseProject }, () => {
      this.reloadData();
    });
  }

  @autobind
  onChangeFilterIncidentComponent(filterIncidentComponent) {
    const { predictionRuleList } = this.state;
    this.setState({ filterIncidentComponent, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  onChangeFilterIncidentInstance(filterIncidentInstance) {
    const { predictionRuleList } = this.state;
    this.setState({ filterIncidentInstance, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  onChangeFilterSourcePatternId(filterSourcePatternIds) {
    const { predictionRuleList } = this.state;
    this.setState({ filterSourcePatternIds, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  onChangeFilterPatternId(filterPatternId) {
    const { predictionRuleList } = this.state;
    this.setState({ filterPatternId, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  onChangeFilterStatus(filterStatus) {
    const { predictionRuleList } = this.state;
    this.setState({ filterStatus, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  handlePaginationChange(page, pageSize) {
    this.setState({ page, pageSize, checkAll: false, isLoadingLocalList: true }, async () => {
      await sleep(300);

      const { incidentPredictionRuleList } = this.state;
      const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      this.setState({ isLoadingLocalList: false, localPredictionRuleList });
    });
  }

  @autobind
  handlePaginationSizeChange(oldPage, pageSize) {
    const page = 1;
    this.setState({ page, pageSize, checkAll: false, isLoadingLocalList: true }, async () => {
      await sleep(300);

      const { incidentPredictionRuleList } = this.state;
      const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      this.setState({ isLoadingLocalList: false, localPredictionRuleList });
    });
  }

  @autobind
  handleIncidentClick(rowData) {
    const activeIncidentKey = rowData.mergeKey;
    const { filterPredictionRuleList } = this.state;
    this.setState({ activeIncidentKey, page: 1, checkAll: false, isLoadingLocalList: true }, async () => {
      await sleep(300);

      // get prediction rules by incident
      const incidentPredictionRuleList = R.filter(
        (item) => item.mergeKey === activeIncidentKey,
        filterPredictionRuleList,
      );

      // get local prediciton rules by page
      const { page, pageSize } = this.state;
      const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      this.setState({ isLoadingLocalList: false, incidentPredictionRuleList, localPredictionRuleList });
    });
  }

  @autobind
  renderIncidentPattern({ cellData, rowData }) {
    const { intl, credentials, projectDisplayMap, currentTheme } = this.props;
    const { incidentCompositeKey, incidentComponent, incidentPatternNameStr, incidentData: content } = rowData;
    const { projectName, userName } = incidentCompositeKey;
    const projectNameReal = userName !== credentials.userName ? `${projectName}@${userName}` : projectName;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(content);
    } catch (error) {
      // console.debug(error)
    }

    return (
      <Popover
        placement="right"
        mouseEnterDelay={0.3}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 350, paddingRight: 8 }}>
            <div className="flex-row">
              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </div>
              <div style={{ wordBreak: 'break-all' }}>{get(projectDisplayMap, projectNameReal, projectNameReal)}</div>
            </div>
            <div className="flex-row">
              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </div>
              <div style={{ wordBreak: 'break-all' }}>{incidentComponent}</div>
            </div>
            <div className="flex-row">
              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.pattern)}:
              </div>
              <div style={{ wordBreak: 'break-all' }}>{incidentPatternNameStr}</div>
            </div>

            <div className="full-width flex-row">
              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                {intl.formatMessage(eventMessages.shortDescription)}:
              </div>
            </div>
            <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
              {!rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (content || '').split('\n')),
                )}
              {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
            </div>
          </div>
        }
      >
        <div className="hidden-line-with-ellipsis inline-block max-width">{cellData}</div>
      </Popover>
    );
  }

  @autobind
  handleCheckAllChange(e) {
    const checkAll = e.target.checked;
    const { localPredictionRuleList } = this.state;
    const newLocalEventList = R.map((item) => {
      return { ...item, checked: checkAll };
    }, localPredictionRuleList);
    this.setState({ checkAll, localPredictionRuleList: newLocalEventList });
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }) {
    const rowData = this.state.localPredictionRuleList[rowIndex];
    if (!rowData) return null;

    const {
      checked,

      statusFlag,
    } = rowData;

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
          <div className="row-column flex-center-justify" style={{ width: 40 }}>
            <Checkbox
              checked={checked}
              onChange={(e) => {
                // Save the data and force update.
                const checked = e.target.checked || false;
                const { localPredictionRuleList } = this.state;
                this.setState({
                  localPredictionRuleList: update(localPredictionRuleList, {
                    [rowIndex]: { $set: { ...rowData, checked } },
                  }),
                });
              }}
            />
          </div>

          <div className="row-column" style={{ width: 160, flex: 1 }}>
            {this.renderSourcePatterns(rowData)}
          </div>
          <div className="row-column flex-row flex-end-justify" style={{ width: 120 }}>
            <Select
              style={{ width: '100%' }}
              value={statusFlag}
              onChange={(statusFlag) => this.handleStatusChange(statusFlag, rowData, rowIndex)}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ),
                this.statusOptions || [],
              )}
            </Select>
          </div>
          <div className="row-column flex-col" style={{ width: 220 }}>
            {this.renderControl(rowData)}
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  handleStatusChange(statusFlag, rowData, rowIndex) {
    const { localPredictionRuleList } = this.state;
    this.setState({
      localPredictionRuleList: update(localPredictionRuleList, {
        [rowIndex]: { $set: { ...rowData, statusFlag } },
      }),
    });
  }

  @autobind
  renderSourcePatterns(rowData) {
    const { intl, credentials, projectDisplayMap, currentTheme } = this.props;
    const { predictionSourceInfo } = rowData;
    const contents = R.addIndex(R.map)((item, index) => {
      const content = R.equals(item.dataType, 'Metric')
        ? item.sourceDetail.content
        : item.sourceDetail.content.substr(0, 50);
      return (
        <div
          key={`rootCauseContentKey${index}`}
          className="hidden-line-with-ellipsis inline-block max-width"
          style={{ display: 'block' }}
        >
          {content}
        </div>
      );
    }, predictionSourceInfo);
    return (
      <Popover
        placement="right"
        mouseEnterDelay={0.3}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 350, paddingRight: 8 }}>
            {R.addIndex(R.map)((item, index) => {
              const { sourceDetail, sourceProjectName, sourceProjectOwner, sourcePatternNameStr, componentName } = item;
              const { isLogType, eventType, type } = sourceDetail || {};
              const { content, metricDirection } = sourceDetail || {};
              const projectNameReal =
                sourceProjectOwner !== credentials.userName
                  ? `${sourceProjectName}@${sourceProjectOwner}`
                  : sourceProjectName;
              let rawDataJson;
              try {
                rawDataJson = JSON.parse(content);
              } catch (error) {
                // console.debug(error)
              }

              return (
                <div key={index} className="flex-col" style={{ marginBottom: 16 }}>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(eventMessages.rootCauseHop)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>{index + 1}</div>
                  </div>
                  <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.project)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>
                      {get(projectDisplayMap, projectNameReal, projectNameReal)}
                    </div>
                  </div>
                  <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.component)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>{componentName}</div>
                  </div>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.pattern)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>{sourcePatternNameStr}</div>
                  </div>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.type)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>
                      {R.equals(type, 'Log')
                        ? intl.formatMessage(appFieldsMessages.log)
                        : intl.formatMessage(appFieldsMessages.metric)}
                    </div>
                  </div>

                  <div className="full-width flex-row">
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(eventMessages.shortDescription)}:
                    </div>
                    {metricDirection && (
                      <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                        {EventRenderers.RenderMetricAnomalySummary({ intl, event: sourceDetail })}
                      </div>
                    )}
                  </div>

                  {!metricDirection && (
                    <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                      {!rawDataJson &&
                        R.join(
                          '\n',
                          R.filter((x) => Boolean(x), (content || '').split('\n')),
                        )}
                      {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                    </div>
                  )}
                </div>
              );
            }, predictionSourceInfo)}
          </div>
        }
      >
        <div style={{ overflow: 'hidden' }} className="max-width ">
          {contents}
        </div>
      </Popover>
    );
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;

    return (
      <div className="flex-row">
        <Button
          type="primary"
          size="small"
          style={{ marginLeft: 8 }}
          onClick={() => this.handleConfigActionsClick(rowData)}
        >
          {intl.formatMessage(eventMessages.takeAction)}
        </Button>
        <Button type="primary" size="small" style={{ marginLeft: 8 }} onClick={() => this.handleEditRuleClick(rowData)}>
          <EditOutlined /> {intl.formatMessage(appButtonsMessages.edit)}
        </Button>
      </div>
    );
  }

  @autobind
  handleConfigActionsClick(rowData) {
    const { location, credentials, systemsMap, projectDisplayMap } = this.props;
    const { systemId } = parseLocation(location);
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const systemProjectNameList = R.map((item) => {
      const projectNameReal =
        item.customerName !== credentials.userName ? `${item.projectName}@${item.customerName}` : item.projectName;
      return {
        projectNameReal,
        projectDisplayName: get(projectDisplayMap, projectNameReal, projectNameReal),
      };
    }, get(systemInfo, 'projectDetailsList', []));

    let actionProjectNameList = [];
    let actionKeySet = [];
    const actionIncidentPredictionLibKeys = [];

    const { incidentPredictionLibPrimaryKey, predictionSourceInfo, projectActionKeySet } = rowData;
    R.forEach((item) => {
      const { sourceProjectName, sourceProjectOwner } = item;
      actionProjectNameList.push(`${sourceProjectName}@${sourceProjectOwner}`);
    }, predictionSourceInfo);
    actionProjectNameList = R.uniq(actionProjectNameList);
    actionKeySet = [...actionKeySet, ...(projectActionKeySet || [])];
    actionKeySet = R.uniqWith(R.eqBy(R.prop('actionId')), R.uniq(actionKeySet));
    actionIncidentPredictionLibKeys.push(incidentPredictionLibPrimaryKey);

    this.setState({
      showSystemPredictionRuleActionModal: true,
      actionProjectNameList,
      actionKeySet,
      actionIncidentPredictionLibKeys,
      systemProjectNameList,
    });
  }

  @autobind
  handleMultipleConfigActionsClick() {
    const { location, credentials, systemsMap, projectDisplayMap } = this.props;
    const { systemId } = parseLocation(location);
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const systemProjectNameList = R.map((item) => {
      const projectNameReal =
        item.customerName !== credentials.userName ? `${item.projectName}@${item.customerName}` : item.projectName;
      return {
        projectNameReal,
        projectDisplayName: get(projectDisplayMap, projectNameReal, projectNameReal),
      };
    }, get(systemInfo, 'projectDetailsList', []));

    let actionProjectNameList = [];
    let actionKeySet = [];
    const actionIncidentPredictionLibKeys = [];

    const { localPredictionRuleList } = this.state;
    const diffChecked = R.filter((item) => item.checked, localPredictionRuleList);
    R.forEach((item) => {
      const { incidentPredictionLibPrimaryKey, predictionSourceInfo, projectActionKeySet } = item;
      R.forEach((item) => {
        const { sourceProjectName, sourceProjectOwner } = item;
        actionProjectNameList.push(`${sourceProjectName}@${sourceProjectOwner}`);
      }, predictionSourceInfo);
      actionKeySet = [...actionKeySet, ...(projectActionKeySet || [])];
      actionIncidentPredictionLibKeys.push(incidentPredictionLibPrimaryKey);
    }, diffChecked);
    actionProjectNameList = R.uniq(actionProjectNameList);
    actionKeySet = R.uniqWith(R.eqBy(R.prop('actionId')), R.uniq(actionKeySet));

    this.setState({
      showSystemPredictionRuleActionModal: true,
      actionProjectNameList,
      actionKeySet,
      actionIncidentPredictionLibKeys,
      systemProjectNameList,
    });
  }

  @autobind
  sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  render() {
    const { intl, credentials, projects, location, isLoadingSystem } = this.props;
    const {
      isLoaded,
      isLoading,
      isLoadingLocalList,
      isSubmitting,

      rootCauseProject,

      incidentList,
      activeIncidentKey,
      incidentPredictionRuleList,
      localPredictionRuleList,

      page,
      pageSize,
      checkAll,

      sortBy,
      sortDirection,
    } = this.state;
    const { systemId } = parseLocation(location);

    const diff = R.differenceWith(this.cmp, localPredictionRuleList, incidentPredictionRuleList);
    const hasError = diff.length === 0;
    const diffChecked = R.filter((item) => item.checked, localPredictionRuleList);
    const hasErrorRemove = diffChecked.length === 0;
    return (
      <div
        className={`full-height flex-row ${isLoading || isLoadingSystem ? 'loading ' : ''} ${
          isLoaded ? ' loaded' : ''
        }`}
        style={{ paddingTop: 8 }}
      >
        <Spin spinning={isLoadingSystem} wrapperClassName="full-width full-height spin-full-width">
          <div className="full-width full-height flex-col">
            <div className="flex-row flex-center-align" style={{ padding: '0 8px' }}>
              <div className="flex-grow flex-row flex-center-align">
                <Select
                  showSearch
                  size="small"
                  style={{ width: 150, margin: '8px 16px 0 0' }}
                  placeholder={intl.formatMessage(eventMessages.rootCauseProject)}
                  value={rootCauseProject}
                  optionFilterProp="value"
                  onChange={this.onChangeRootCauseProject}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Select.Option key={item.projectNameReal} value={item.projectNameReal}>
                        {item.projectDisplayName || item.projectNameReal}
                      </Select.Option>
                    );
                  }, this.projectList || [])}
                </Select>
                <Button
                  size="small"
                  type="primary"
                  style={{ margin: '8px 16px 0 0' }}
                  disabled={false}
                  onClick={this.handleAddNewRule}
                >
                  <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
                </Button>
              </div>
              <Pagination
                size="small"
                current={page}
                total={incidentPredictionRuleList.length}
                pageSize={pageSize}
                onChange={this.handlePaginationChange}
                showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                showSizeChanger
                pageSizeOptions={['10', '20', '40', '60', '100', '500', '1000']}
                onShowSizeChange={this.handlePaginationSizeChange}
              />
            </div>

            <div className="flex-grow flex-min-width flex-min-height flex-row" style={{ padding: 8 }}>
              <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
                <div style={{ width: '30%', maxWidth: 320, marginRight: 16 }}>
                  <AutoSizer>
                    {({ width, height }) => (
                      <Table
                        className="with-border"
                        width={width}
                        height={height}
                        headerHeight={30}
                        rowHeight={40}
                        rowCount={incidentList.length}
                        rowGetter={({ index }) => incidentList[index]}
                        onRowClick={({ rowData }) => {
                          this.handleIncidentClick(rowData);
                        }}
                        rowClassName={({ index }) => {
                          let className = 'clickable';
                          className += index >= 0 && index % 2 === 1 ? ' odd-row' : '';
                          // Ignore header row.
                          if (index >= 0) {
                            if (incidentList[index].mergeKey === activeIncidentKey) {
                              className += ' active';
                            }
                          }
                          return className;
                        }}
                        sort={this.sortTable}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                      >
                        <Column
                          width={100}
                          flexGrow={1}
                          label={intl.formatMessage(eventMessages.incidentPattern)}
                          dataKey="incidentPatternNameStr"
                          cellRenderer={this.renderIncidentPattern}
                          headerRenderer={this.headerRenderer}
                        />
                        <Column
                          width={120}
                          flexGrow={1}
                          label={intl.formatMessage(eventMessages.incidentComponent)}
                          dataKey="incidentComponent"
                          cellRenderer={this.renderIncidentPattern}
                          headerRenderer={this.headerRenderer}
                        />
                      </Table>
                    )}
                  </AutoSizer>
                </div>

                <div className="flex-grow flex-min-width flex-col">
                  <div className="flex-grow flex-min-height">
                    <Spin spinning={isLoadingLocalList} wrapperClassName="full-width full-height spin-full-height">
                      <AutoSizer>
                        {({ width, height }) => (
                          <div className="event-list">
                            <div
                              className="event-list-header"
                              style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                            >
                              <div className="header-column flex-center-justify" style={{ width: 40 }}>
                                <Checkbox checked={checkAll} onChange={this.handleCheckAllChange} />
                              </div>
                              <div className="header-column" style={{ width: 160, flex: 1 }}>
                                {intl.formatMessage(eventMessages.rootCauseMetricOrLog)}
                              </div>
                              <div className="header-column" style={{ width: 120 }}>
                                {intl.formatMessage(appFieldsMessages.status)}
                              </div>
                              <div className="header-column" style={{ width: 220 }} />
                            </div>
                            <List
                              className="event-list-grid"
                              ref={(listNode) => {
                                this.listNode = listNode;
                              }}
                              width={width}
                              height={height - 40}
                              rowCount={localPredictionRuleList.length}
                              overscanRowCount={4}
                              deferredMeasurementCache={this.cellMeasureCache}
                              rowHeight={this.cellMeasureCache.rowHeight}
                              rowRenderer={this.renderListItem}
                              onScrollbarPresenceChange={({ horizontal, vertical }) => {
                                if (vertical) {
                                  this.listNodeHeaderScrollbar = true;
                                } else {
                                  this.listNodeHeaderScrollbar = false;
                                }
                                this.forceUpdate();
                              }}
                            />
                          </div>
                        )}
                      </AutoSizer>
                    </Spin>
                  </div>

                  <div className="flex-row" style={{ padding: 8 }}>
                    <div className="flex-grow" />
                    <Button
                      size="small"
                      type="primary"
                      loading={isSubmitting}
                      disabled={hasError}
                      onClick={this.handleSaveClick}
                    >
                      {intl.formatMessage(appButtonsMessages.update)}
                    </Button>

                    <Button
                      type="primary"
                      size="small"
                      style={{ marginLeft: 8 }}
                      loading={isSubmitting}
                      disabled={hasErrorRemove}
                      onClick={() => this.handleMultipleConfigActionsClick()}
                    >
                      {intl.formatMessage(eventMessages.takeAction)}
                    </Button>

                    <Popconfirm
                      placement="topRight"
                      title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                      okText={intl.formatMessage(appButtonsMessages.yes)}
                      cancelText={intl.formatMessage(appButtonsMessages.no)}
                      onConfirm={() => this.handleRuleRemove()}
                      onCancel={(event) => event.stopPropagation()}
                    >
                      <Button
                        type="primary"
                        size="small"
                        className="button-color-grey"
                        style={{ marginLeft: 8 }}
                        loading={isSubmitting}
                        disabled={hasErrorRemove}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                      </Button>
                    </Popconfirm>
                    <Button
                      type="primary"
                      size="small"
                      className="button-color-grey"
                      style={{ marginLeft: 8 }}
                      onClick={this.handleRuleRemoveAll}
                    >
                      <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.removeAll)}
                    </Button>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </Spin>

        {this.state.showAddNewRule && (
          <KnowledgeBaseRuleEdit
            intl={intl}
            credentials={credentials}
            projects={projects}
            projectInstanceComponentMap={this.props.projectInstanceComponentMap}
            projectName={rootCauseProject}
            activeEvent={this.state.activeEvent}
            isSubmiting={this.state.isSubmitingNewRule}
            handleSumbit={this.state.activeEvent ? this.handleEditRuleSave : this.handleAddNewRuleSave}
            onClose={() => this.setState({ showAddNewRule: false, activeEvent: undefined })}
            updateLastActionInfo={this.props.updateLastActionInfo}
            createSetAction={this.props.createSetAction}
          />
        )}

        {this.state.showSystemPredictionRuleActionModal && (
          <GlobalSystemKnowledgeBaseActionModal
            systemId={systemId}
            actionProjectNameList={this.state.actionProjectNameList}
            actionKeySet={this.state.actionKeySet}
            actionIncidentPredictionLibKeys={this.state.actionIncidentPredictionLibKeys}
            systemProjectNameList={this.state.systemProjectNameList}
            onClose={(reload) => {
              this.setState(
                {
                  showSystemPredictionRuleActionModal: false,
                  actionProjectNameList: [],
                  actionKeySet: [],
                  actionIncidentPredictionLibKeys: [],
                },
                () => {
                  if (reload) this.reloadData();
                },
              );
            }}
          />
        )}
      </div>
    );
  }
}

const KnowledgeBaseRuleEdit = ({
  intl,
  credentials,
  projects,
  projectInstanceComponentMap,
  projectName,
  activeEvent,
  isSubmiting,
  handleSumbit,
  onClose,
  updateLastActionInfo,
  createSetAction,
}: Object) => {
  // metric options
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [componentOptions, setComponentOptions] = useState([]);
  const [metricOptions, setMetricOptions] = useState([]);

  const [data, setData] = useState(activeEvent ? activeEvent.data : '');
  const [componentName, setComponentName] = useState(activeEvent ? activeEvent.componentName : undefined);
  const [metricName, setMetricName] = useState(activeEvent ? activeEvent.metricName : undefined);
  const [metricValue, setMetricValue] = useState(activeEvent ? activeEvent.metricValue : 0);
  const [metricPercentage, setPercentage] = useState(activeEvent ? activeEvent.metricPercentage : 0);
  const [metricSign, setSign] = useState(activeEvent ? activeEvent.metricSign : 'lower');
  const [delay, setDelay] = useState(activeEvent ? activeEvent.delay : 0);
  const [dataType, setDataType] = useState(null);
  const [logMessage, setLogMessage] = useState(activeEvent ? activeEvent.logMessage : '');

  useEffect(() => {
    setLoading(true);

    const project = R.find((project) => project.projectName === projectName, projects || []) || {};

    const asyncFn = async () => {
      const requests = [];

      await fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
        ...credentials,
        projectList: JSON.stringify([{ projectName: project.projectShortName, customerName: project.owner }]),
        startTime: moment.utc().startOf('day').subtract(1, 'days').valueOf(),
        endTime: moment.utc().endOf('day').valueOf(),
        includeInstance: true,
      }).then((res) => {
        const projectMetaData = get(res, 'data', [])[0];
        const { dataType } = projectMetaData;
        if (R.equals(dataType, 'Metric')) {
          requests.push(
            fetchGet(getEndpoint('projects/componentmetricupdate'), {
              ...credentials,
              projectName,
              start: 0,
              limit: 1000000,
            }).then((data) => {
              return get(data, 'metricSetting', []);
            }),
          );
        }
        if (R.equals(dataType, 'Log')) {
          requests.push(
            fetchGet(getEndpoint('projects/component', 1), {
              ...credentials,
              projectName,
            }),
          );
        }
        Promise.all(requests)
          .then(async (results) => {
            const logComponents = results[0] || [];
            const metricResult = results[0] || [];
            const metricOptions = R.map((item) => ({ value: item.smetric, label: item.smetric }), metricResult);
            setMetricOptions(metricOptions);

            const instanceStructureSet = get(projectMetaData, ['instanceStructureSet'], []);
            let instanceNameList = [];
            R.forEach((inc) => {
              const { i, c } = inc || {};
              if (i) {
                instanceNameList = [...instanceNameList, i];
                R.forEach((item) => {
                  instanceNameList = [...instanceNameList, `${item}_${i}`];
                }, c || []);
              }
            }, instanceStructureSet || []);

            // get all components from instances
            const prevInstanceComponentMap = get(projectInstanceComponentMap, projectName, {});
            const { hasError, allInstanceComponentMap } = await BackgroundCall.GetSetComponent({
              updateLastActionInfo,
              createSetAction,
              credentials,
              prevInstanceComponentMap,
              projectName,
              instanceNameList,
            });

            // build appNameList
            if (hasError) {
              throw new Error('Get components faild.');
            }
            const appNameList = !R.isEmpty(allInstanceComponentMap)
              ? { ...prevInstanceComponentMap, ...allInstanceComponentMap }
              : prevInstanceComponentMap;

            let components = R.values(appNameList);
            components = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(components));
            const metricComponentOptions = R.map((item) => ({ value: item, label: item }), components);
            const logComponentOptions = R.uniq(
              R.map((item) => ({ label: item.componentName, value: item.componentName }), logComponents),
            );
            const componentOptions = R.equals(projectMetaData.dataType, 'Metric')
              ? metricComponentOptions
              : logComponentOptions;
            setComponentOptions(componentOptions);
            setDataType(projectMetaData.dataType);

            setLoading(false);
            setErrMsg();
          })
          .catch((err) => {
            setLoading(false);
            setErrMsg(`${err.message || String(err)}`);
          });
      });
    };

    asyncFn();
    updateLastActionInfo();

    return () => {};
  }, [projectName]);

  const isEdit = Boolean(activeEvent);
  const hasError = R.equals(dataType, 'Metric')
    ? !data || !componentName || !metricName || !metricSign
    : (!data, !logMessage);
  return (
    <Modal
      width={650}
      title={intl.formatMessage(isEdit ? appButtonsMessages.edit : appButtonsMessages.add)}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={() =>
        handleSumbit({
          data,
          componentName,
          metricName,
          metricValue,
          metricPercentage,
          metricSign,
          delay,
          dataType,
          logMessage,
        })
      }
      okButtonProps={{ disabled: hasError, loading: isSubmiting }}
    >
      <Spin spinning={isLoading}>
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelWrap>
          {errMsg && (
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Alert message={errMsg} type="error" showIcon />
            </Form.Item>
          )}

          <Form.Item
            label={intl.formatMessage(eventMessages.incidentDescription)}
            validateStatus={!data ? 'error' : 'success'}
            help={!data ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input.TextArea rows={4} value={data} onChange={(e) => setData(e.target.value)} />
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(eventMessages.rootCauseComponentName)}
            validateStatus={!componentName ? 'error' : 'success'}
            help={!componentName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Select
              showSearch
              allowClear={false}
              options={componentOptions}
              value={componentName}
              onChange={(value) => setComponentName(value)}
              disabled={isEdit}
            />
          </Form.Item>
          {R.equals(dataType, 'Metric') && (
            <>
              <Form.Item
                label={intl.formatMessage(eventMessages.rootCauseMetricName)}
                validateStatus={!metricName ? 'error' : 'success'}
                help={!metricName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <Select
                  showSearch
                  allowClear={false}
                  options={metricOptions}
                  value={metricName}
                  onChange={(value) => setMetricName(value)}
                  disabled={isEdit}
                />
              </Form.Item>
              <Form.Item label={intl.formatMessage(eventMessages.rootCauseMetricValue)} required>
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  value={metricValue}
                  onChange={(value) => {
                    setMetricValue(value);
                  }}
                />
              </Form.Item>
              <Form.Item label={intl.formatMessage(eventMessages.rootCauseMetricAnomalyDegree)} required>
                <div className="flex-row flex-center-align">
                  <InputNumber
                    style={{ width: 160, marginRight: 8 }}
                    min={0}
                    addonAfter="%"
                    value={metricPercentage}
                    onChange={(value) => setPercentage(value)}
                  />
                  <Select
                    allowClear={false}
                    options={[
                      { value: 'lower', label: intl.formatMessage(eventMessages.lowerThanNormal) },
                      { value: 'higher', label: intl.formatMessage(eventMessages.higherThanNormal) },
                    ]}
                    value={metricSign}
                    onChange={(value) => setSign(value)}
                    disabled={isEdit}
                  />
                </div>
              </Form.Item>
            </>
          )}
          {R.equals(dataType, 'Log') && (
            <Form.Item label={intl.formatMessage(eventMessages.rootCauseLogMessage)} required>
              <Input
                style={{ width: '100%' }}
                value={logMessage}
                onChange={(e) => {
                  setLogMessage(e.target.value);
                }}
                disabled={isEdit}
              />
            </Form.Item>
          )}
          <Form.Item label={intl.formatMessage(eventMessages.rootCauseLeadTime)} required>
            <InputNumber min={0} addonAfter="minutes" value={delay} onChange={(value) => setDelay(value)} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

const KnowledgeBaseRuleManualList = injectIntl(KnowledgeBaseRuleManualListCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials, userInfo } = state.auth;
    const {
      loadStatus,
      currentLocale,
      systemsMap,
      projects,
      projectInstanceComponentMap,
      projectDisplayMap,
      globalInfo,
      currentTheme,
    } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return {
      location,
      credentials,
      userInfo,
      userList,
      loadStatus,
      currentLocale,
      systemsMap,
      projects,
      projectInstanceComponentMap,
      projectDisplayMap,
      globalInfo,
      currentTheme,
    };
  },
  { push, replace, updateLastActionInfo, createSetAction },
)(KnowledgeBaseRuleManualList);
