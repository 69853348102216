/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

/* @flow */
import type { Action } from '../types';

export const ActionTypes = {
  LOAD_PROJECT_LOGSTRUCTURE: 'LOAD_PROJECT_LOGSTRUCTURE',
  LOAD_SYSTEM_GENERAL_INFO: 'LOAD_SYSTEM_VERSION',
  SET_SYSTEM_GENERAL_INFO: 'SET_SYSTEM_GENERAL_INFO',
  SET_PROJECT_LOGSTRUCTURE: 'SET_PROJECT_LOGSTRUCTURE',
  SET_PROJECT_PATTERN_SETTING: 'SET_PROJECT_PATTERN_SETTING',
  LOAD_PROJECT_KEYWORDS_SETTING: 'LOAD_PROJECT_KEYWORDS_SETTING',
  SET_PROJECT_KEYWORDS_SETTING: 'SET_PROJECT_KEYWORDS_SETTING',
  SAVE_PROJECT_KEYWORDS_SETTING: 'SAVE_PROJECT_KEYWORDS_SETTING',
  DELETE_PROJECT_KEYWORDS_SETTING: 'DELETE_PROJECT_KEYWORDS_SETTING',
  LOAD_PROJECT_JDBC_CONNECTION_SETTING: 'LOAD_PROJECT_JDBC_CONNECTION_SETTING',
  SAVE_PROJECT_JDBC_SQL_SETTING: 'SAVE_PROJECT_JDBC_SQL_SETTING',
  NEW_EXTERNAL_SERVICE: 'NEW_EXTERNAL_SERVICE',
  LOAD_PROJECT_DETECTION_STATUS: 'LOAD_PROJECT_DETECTION_STATUS',
  SET_PROJECT_DETECTION_STATUS: 'SET_PROJECT_DETECTION_STATUS',
  SAVE_PROJECT_DETECTION_STATUS: 'SAVE_PROJECT_DETECTION_STATUS',
  LOAD_PROJECT_ACTIONS: 'LOAD_PROJECT_ACTIONS',
  SET_PROJECT_ACTIONS: 'SET_PROJECT_ACTIONS',
  SAVE_PROJECT_ACTIONS: 'SAVE_PROJECT_ACTIONS',
  LOAD_PROJECT_MODELS: 'LOAD_PROJECT_MODELS',
  SET_PROJECT_MODELS: 'SET_PROJECT_MODELS',
  SAVE_PROJECT_DEPLOY_AGENT: 'SAVE_PROJECT_DEPLOY_AGENT',
  SET_SAVE_PROJECT_DEPLOY_AGENT: 'SET_SAVE_PROJECT_DEPLOY_AGENT',
  LOAD_PROJECT_FILTERS_SETTING: 'LOAD_PROJECT_FILTERS_SETTING',
  SET_PROJECT_FILTERS_SETTING: 'SET_PROJECT_FILTERS_SETTING',
  DELETE_PROJECT_FILTERS_SETTING: 'DELETE_PROJECT_FILTERS_SETTING',
  SAVE_PROJECT_FILTERS_SETTING: 'SAVE_PROJECT_FILTERS_SETTING',
  LOAD_ACTIVE_INSTANCE_ACTIONS: 'LOAD_ACTIVE_INSTANCE_ACTIONS',
  SET_ACTIVE_INSTANCE_ACTIONS: 'SET_ACTIVE_INSTANCE_ACTIONS',
  REMOVE_PROJECT: 'REMOVE_PROJECT',
};

/**
 * Action used to load project settings from server. It's handled by epic.
 */
export const loadProjectSettings = (
  projectName: String,
  params: Object,
  force: Boolean = false,
  callback: Function,
): Action => ({
  type: 'LOAD_PROJECT_SETTINGS',
  payload: { projectName, params, force, callback },
});

export const loadActiveInstance = (params: Object, force: Boolean = false): Action => ({
  type: 'LOAD_ACTIVE_INSTANCE_ACTIONS',
  payload: { params, force },
});

export const loadProjectHoliday = (params: Object, loader: ?any): Action => ({
  type: 'LOAD_PROJECT_HOLIDAY',
  payload: { params, loader },
});

export const loadProjectLogStructure = (params: Object, force: Boolean = false, loader: ?any): Action => ({
  type: 'LOAD_PROJECT_LOGSTRUCTURE_SETTINGS',
  payload: { params, force, loader },
});

export const saveLogEntryStructure = (params: Object, loader: ?any): Action => ({
  type: 'SAVE_LOGSTRUCTURE_SETTINGS',
  payload: { params, loader },
});

export const removeLogEntryStructure = (params: Object, loader: ?any): Action => ({
  type: 'REMOVE_LOGSTRUCTURE_SETTINGS',
  payload: { params, loader },
});

export const loadLogSequenceScopeList = (params: Object, force: Boolean = false, loader: ?any): Action => ({
  type: 'LOAD_LOGSEQUENCESCOPE_SETTINGS',
  payload: { params, force, loader },
});

export const saveLogSequenceScopeList = (params: Object, loader: ?any): Action => ({
  type: 'SAVE_LOGSEQUENCESCOPE_SETTINGS',
  payload: { params, loader },
});

export const removeLogSequenceScopeList = (params: Object, loader: ?any): Action => ({
  type: 'REMOVE_LOGSEQUENCESCOPE_SETTINGS',
  payload: { params, loader },
});

export const saveProjectHoliday = (params: Object, loader: ?any): Action => ({
  type: 'SAVE_PROJECT_HOLIDAY',
  payload: { params, loader },
});

export const removeProjectHoliday = (params: Object, loader: ?any): Action => ({
  type: 'REMOVE_PROJECT_HOLIDAY',
  payload: { params, loader },
});

export const loadProjectMetricSettings = (params: Object, loader: ?any): Action => ({
  type: 'LOAD_PROJECT_METRIC_SETTINGS',
  payload: { params, loader },
});

export const saveProjectMetricSettings = (params: Object, loader: ?any): Action => ({
  type: 'SAVE_PROJECT_METRIC_SETTINGS',
  payload: { params, loader },
});

export const resetProjectMetricSettings = (params: Object, loader: ?any): Action => ({
  type: 'RESET_PROJECT_METRIC_SETTINGS',
  payload: { params, loader },
});

export const setProjectMetricSettings = (payload: Object): Action => ({
  type: 'SET_PROJECT_METRIC_SETTINGS',
  payload,
});
export const setProjectCapacitySettings = (payload: Object): Action => ({
  type: 'SET_PROJECT_CAPACITY_SETTINGS',
  payload,
});

export const setProjectMetricSettingExcludedMetrics = (payload: Object): Action => ({
  type: 'SET_PROJECT_METRIC_SETTING_EXCLUDED_METRICS',
  payload,
});

/**
 * Action used to save project settings to server, It's handled by epic.
 * The components parameter contains loading status needed to change.
 */
export const saveProjectSettings = (
  projectName: String,
  settings: Object,
  components: ?Object,
  field: String,
): Action => ({
  type: 'SAVE_PROJECT_SETTINGS',
  payload: {
    projectName,
    settings,
    components,
    field,
  },
});

export const saveProjectAgentList = (params: Object, loader: ?any): Action => ({
  type: 'SAVE_PROJECT_AGENTLIST',
  payload: { params, loader },
});

export const saveProjectInstanceGroup = (params: Object, loader: ?any, callback: Function): Action => ({
  type: 'SAVE_PROJECT_INSTANCE_GROUP',
  payload: { params, loader, callback },
});

/**
 * Create project, the project parameters are different with each type.
 * This action is handled by epic and reducer.
 */
export const createProject = (projectName: String, projectType: String, params: Object): Action => ({
  type: 'CREATE_PROJECT',
  payload: { projectName, projectType, params },
});

/**
 * Set the project creation status.
 */
export const setProjectCreationStatus = (status: String, info: Object): Action => ({
  type: 'SET_PROJECT_CREATION_STATUS',
  payload: { status, info },
});

/**
 * Set the current settings APIs parameters, if parameters not changed, will reuse the data
 * instead of calling the API. Each API will has it's own status.
 */
export const setSettingsApisParams = (name: String, params: Object) => ({
  type: 'SET_SETTINGS_API_PARAMS',
  payload: { name, params },
});

/**
 * Action used to set the project settings and related states in redux store.
 * It's handled by reducer.
 */
export const setProjectSettings = (info: Object): Action => ({
  type: 'SET_PROJECT_SETTINGS',
  payload: { ...info },
});

/**
 * Action used to pick the project model.
 * This action is handled by epic.
 */
export const pickProjectModel = (projectName: String, instanceGroup, modelKey: String, params: Object): Action => ({
  type: 'PICK_PROJECT_MODEL',
  payload: { projectName, instanceGroup, modelKey, params },
});

export const exportProjectModel = (params: Object, loader: ?any): Action => ({
  type: 'EXPORT_PROJECT_MODEL',
  payload: { params, loader },
});

/**
 * Update the project model's status, the status can be 'removed', 'picked'.
 * This action is handled by reducer.
 */
export const updateProjectModelStatus = (modelKey: String, status: String): Action => ({
  type: 'UPDATE_PROJECT_MODEL_STATUS',
  payload: { modelKey, status },
});

/**
 * Load external service list, which is handled by epic.
 */
export const loadExternalServiceList = (): Action => ({
  type: 'LOAD_EXTERNAL_SERVICE_LIST',
  payload: {},
});

export const loadRabbitMQStatusList = (params: Object, force: Boolean = false, loader: ?any): Action => ({
  type: 'LOAD_RABBITMQ_STATUS_LIST',
  payload: { params, force, loader },
});

/**
 * Add a external service with the type. The params is different from each type.
 * This is handled by epic.
 */
export const addExternalService = (params: Object, components: Object = {}): Action => ({
  type: 'ADD_EXTERNAL_SERVICE',
  payload: { params, components },
});

/**
 * Remove external service, which is handled by epic.
 */
export const removeExternalService = (serviceId, serviceOwner): Action => ({
  type: 'REMOVE_EXTERNAL_SERVICE',
  payload: { serviceId, serviceOwner },
});

/**
 * General action to update settings state, which is handled by reducer.
 */
export const setSettingsInfo = (info: Object): Action => ({
  type: 'SET_SETTINGS_INFO',
  payload: { ...info },
});

/**
 * Action to update active instance state, which is handled by reducer.
 */
export const setActiveInstance = (data: Object): Action => ({
  type: 'SET_ACTIVE_INSTANCE_ACTIONS',
  payload: { ...data },
});
