import React from 'react';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { State } from '../../../common/types';
import { Modal } from '../../../lib/fui/react';

import { appMessages, appFieldsMessages } from '../../../common/app/messages';

type Props = {
  intl: Object,
  alertModals: Array<Object>,
};

class AppModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {
      showModalIds: [],
    };
  }

  componentDidMount() {
    const { alertModals } = this.props;
    if (alertModals.length > 0) {
      this.showModal(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.alertModals !== this.props.alertModals) {
      this.showModal(nextProps);
    }
  }

  @autobind
  showModal(props) {
    const { intl, alertModals } = props;
    const { showModalIds } = this.state;
    const newModalIds = R.clone(showModalIds);
    R.forEach((modal) => {
      if (showModalIds.indexOf(modal.id) < 0) {
        newModalIds.push(modal.id);
        const { title, message } = modal.params || {};
        const ModalObj = {
          centered: true,
          title: title
            ? intl.formatMessage(title)
            : intl.formatMessage(appMessages.messageTitle, {
                type: intl.formatMessage(get(appFieldsMessages, modal.type)),
              }),
          content: message ? intl.formatMessage(message) : '',
        };
        switch (modal.type) {
          case 'info':
            Modal.info(ModalObj);
            break;
          case 'success':
            Modal.success(ModalObj);
            break;
          case 'error':
            Modal.error(ModalObj);
            break;
          case 'warning':
            Modal.warning(ModalObj);
            break;
          default:
            break;
        }
      }
    }, alertModals);
    this.setState({ showModalIds: newModalIds });
  }

  render() {
    return null;
  }
}

const AppModal = injectIntl(AppModalCore);

export default connect(
  (state: State) => ({
    alertModals: state.app.modals,
  }),
  {},
)(AppModal);
