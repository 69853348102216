import React, { useReducer } from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { DatePicker } from 'antd';
import { Modal } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';

import { appFieldsMessages } from '../../../common/app/messages';

function ViewCausalSelectTimeModalCore(props: Object) {
  const { intl, onClose, onSumbit, activeEvent, isOnDemandCausal } = props || {};
  const { startTimestamp, endTimestamp } = activeEvent || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    rangeStartDate: moment.utc(startTimestamp),
    rangeEndDate: moment.utc(endTimestamp),
    resultStartDate: moment.utc(startTimestamp),
    resultEndDate: moment.utc(endTimestamp),
  });
  const { rangeStartDate, rangeEndDate, resultStartDate, resultEndDate } = state;

  return (
    <Modal
      visible
      title={intl.formatMessage(appFieldsMessages.selectTime)}
      onOk={() => onSumbit(activeEvent, state)}
      onCancel={() => onClose()}
    >
      <div>
        <div className="flex-row flex-center-align">
          <span style={{ width: 140 }}>{intl.formatMessage(appFieldsMessages.calculationStartDate)}:</span>
          <DatePicker
            size="small"
            allowClear={false}
            value={rangeStartDate}
            format="MM-DD HH:mm"
            onChange={(date) => {
              setState({ rangeStartDate: date, resultStartDate: date });
            }}
            disabledDate={(current) => {
              if (isOnDemandCausal) {
                return (
                  current &&
                  (current < moment.utc(startTimestamp) ||
                    current > moment.utc(endTimestamp) ||
                    (rangeEndDate && current > rangeEndDate))
                );
              }
              return current && current > moment.utc().add(1, 'days').endOf('day');
            }}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
          <span style={{ width: 140 }}>{intl.formatMessage(appFieldsMessages.calculationEndDate)}:</span>
          <DatePicker
            size="small"
            allowClear={false}
            value={rangeEndDate}
            format="MM-DD HH:mm"
            onChange={(date) => {
              setState({ rangeEndDate: date, resultEndDate: date });
            }}
            disabledDate={(current) => {
              if (isOnDemandCausal) {
                return (
                  current &&
                  (current < moment.utc(startTimestamp) ||
                    current > moment.utc(endTimestamp) ||
                    (rangeStartDate && current < rangeStartDate))
                );
              }
              return current && current > moment.utc().add(1, 'days').endOf('day');
            }}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
          <span style={{ width: 140 }}>{intl.formatMessage(appFieldsMessages.resultStartDate)}:</span>
          <DatePicker
            size="small"
            allowClear={false}
            value={resultStartDate}
            format="MM-DD HH:mm"
            onChange={(date) => {
              setState({ resultStartDate: date });
            }}
            disabledDate={(current) => {
              return (
                current &&
                (current < rangeStartDate.clone().startOf('day') || current > rangeEndDate.clone().endOf('day'))
              );
            }}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
          <span style={{ width: 140 }}>{intl.formatMessage(appFieldsMessages.resultEndDate)}:</span>
          <DatePicker
            size="small"
            allowClear={false}
            value={resultEndDate}
            format="MM-DD HH:mm"
            onChange={(date) => {
              setState({ resultEndDate: date });
            }}
            disabledDate={(current) => {
              return (
                current &&
                (current < rangeStartDate.clone().startOf('day') || current > rangeEndDate.clone().endOf('day'))
              );
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

const ViewCausalSelectTimeModal = injectIntl(ViewCausalSelectTimeModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { currentTheme } = state.app;
    const isDark = currentTheme === 'dark';
    return {
      location,
      userInfo,
      credentials,
      isDark,
    };
  },
  { createLoadAction, updateLastActionInfo },
)(ViewCausalSelectTimeModal);
