/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import React from 'react';
import { autobind } from 'core-decorators';
import { isNumber, isEqual } from 'lodash';

import Dygraph from '../../../dygraph';

type Props = {
  className: string,
  style: Object,

  width: Number,
  height: Number,
  interval: Number,
  xRangePad: Number,
  data: any,
  color: String,

  dateWindow: any,
  onDateWindowChange: Function,
  underlayCallback: Function,
  highlightCallback: Function,
  drawCallback: Function,
  clickCallback: Function,
  unhighlightCallback: Function,
};

class DotChart extends React.PureComponent {
  props: Props;
  static defaultProps = {
    xRangePad: 0,
    interval: 10 * 60 * 1000,
  };

  constructor(props) {
    super(props);

    this.chartNode = null;
    this.dygraph = null;
    this.labels = ['timestamp', 'val'];

    this.dygraphOptions = {
      includeZero: true,
      xRangePad: props.xRangePad,
      labelsUTC: true,
      axisLabelFontSize: 12,
      showLabelsOnHighlight: false,
      strokeWidth: 1,
      highlightSeriesOpts: { strokeWidth: 0, highlightCircleSize: 0 },
      highlightSeriesBackgroundAlpha: 1,
      colors: [props.color],
      plotter: this.dotChartPlotter,
      underlayCallback: this.handleUnderlayCallback,
      drawCallback: this.handleDrawCallback,
      highlightCallback: this.handleHighlightCallback,
      unhighlightCallback: this.handleUnhighlightCallback,
      clickCallback: this.handleClickCallback,
      axisLabelFormatter: () => '',
      axisLineColor: 'white',
      labelsKMB: true,
      axes: { y: { drawAxis: true, drawGrid: false, axisLabelWidth: 48 }, x: { drawGrid: false, drawAxis: false } },
    };
  }

  @autobind
  handleClickCallback(e, x, points) {
    const { clickCallback } = this.props;

    if (clickCallback) {
      clickCallback(e, x, points, this.dygraph);
    }
  }

  @autobind
  handleHighlightCallback(e, x, points, row, seriesName) {
    const { highlightCallback } = this.props;
    if (highlightCallback) {
      highlightCallback(e, x, points, row, seriesName, this.dygraph);
    }
  }

  @autobind
  handleUnhighlightCallback(e) {
    const { unhighlightCallback } = this.props;
    if (unhighlightCallback) {
      unhighlightCallback(e, this.dygraph);
    }
  }

  @autobind
  handleDrawCallback(g, initial) {
    const { onDateWindowChange, drawCallback } = this.props;
    if (onDateWindowChange && !initial) {
      const dw = g.xAxisRange();
      if (!isEqual(dw, this.currentDateWindow)) {
        onDateWindowChange(g.xAxisRange());
        this.currentDateWindow = dw;
      }
    }

    if (drawCallback) {
      drawCallback(g, initial);
    }
  }

  @autobind
  handleUnderlayCallback(canvas, area, g) {
    const { underlayCallback } = this.props;

    if (underlayCallback) {
      underlayCallback(canvas, area, g);
    }
  }

  @autobind
  dotChartPlotter(e) {
    // We need to handle all the series simultaneously.
    if (e.seriesIndex !== 0) return;

    const { interval, color } = this.props;
    const g = e.dygraph;
    const ctx = e.drawingContext;
    const sets = e.allSeriesPoints;
    const yBottom = g.toDomYCoord(0);
    const barWidth = g.toDomXCoord(interval) - g.toDomXCoord(0);
    const padding = 0; // Math.max(barWidth / 10, 2);

    for (let j = 0; j < sets.length; j += 1) {
      ctx.fillStyle = color;
      ctx.strokeStyle = color;
      for (let i = 0; i < sets[j].length; i += 1) {
        const p = sets[j][i];
        if (isNumber(p.yval)) {
          const centerX = p.canvasx;
          const xLeft = centerX - barWidth / 2 + padding / 2;
          const height = p.canvasy - yBottom;
          const dotHeight = height / 2;
          const offsetY = (height - dotHeight) / 2;

          ctx.fillRect(xLeft, p.canvasy - offsetY, barWidth - padding, -dotHeight);
          // ctx.strokeRect(xLeft, p.canvasy - offsetY, barWidth - padding, -dotHeight);
        }
      }
    }
  }

  componentDidMount() {
    if (this.chartNode) {
      const { data, dateWindow } = this.props;
      let options = { ...this.dygraphOptions, labels: this.labels };
      if (dateWindow) {
        options = { ...options, dateWindow };
      }
      this.dygraph = new Dygraph(this.chartNode, data, options);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { width, height } = nextProps;
    if (width && height && this.dygraph && (width !== this.props.width || height !== this.props.height)) {
      this.dygraph.resize(width, height);
    }
  }

  componentWillUpdate(nextProps) {
    if (this.dygraph) {
      const { data, dateWindow } = nextProps;
      if (data !== this.props.data || dateWindow !== this.props.dateWindow) {
        let options = { file: data };
        if (dateWindow) {
          options = { ...options, dateWindow };
        }
        this.dygraph.updateOptions(options);
      }
    }
  }

  componentWillUnmount() {
    if (this.dygraph) {
      this.dygraph.destroy();
      this.dygraph = null;
    }
  }

  render() {
    const { className, style, width, height } = this.props;
    return (
      <div className={`fui dotchart ${className || ''}`} style={{ width, height }}>
        <div style={{ ...style, width, height }} ref={(c) => (this.chartNode = c)} />
      </div>
    );
  }
}

export default DotChart;
