/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const getMetricLineCharts = (credentials: Credentials, params: Object) => {
  const { projectName, instanceGroup, startTimeObj, endTimeObj } = params;
  const { predictedFlag, instanceList, metricList } = params;

  return fetchPost(getEndpoint('retrievedatafilter'), {
    ...credentials,
    projectName,
    grouping: instanceGroup,
    startTimestamp: startTimeObj.valueOf(),
    endTimestamp: endTimeObj.valueOf(),
    instanceList: JSON.stringify(instanceList),
    metricList: JSON.stringify(metricList),
    predictedFlag,
  }).then((d) => {
    return d;
  });
};
export default getMetricLineCharts;
