import React, { useEffect, useMemo, useReducer } from 'react';
import { Button, Form, Input, Select, message } from 'antd';
import * as R from 'ramda';
import { Modal } from '../../../lib/fui/react';
import { DashboardLoyout } from '../../../lib/fui/icons';
import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import fetchGet from '../../../common/apis/fetchGet';
import { parseJSON } from '../../../common/utils';

const stencilOption = [
  { label: 'Default insights', value: 'defaultInsights' },
  { label: 'Empty', value: 'empty' },
];

const templateMap = {
  defaultInsights: [
    { dashboardWidget: { w: 7, h: 6, x: 0, y: 6 }, l: 'topIncidentsPatterns' },
    { dashboardWidget: { w: 7, h: 7, x: 7, y: 5 }, l: 'SystemTotalIncidentCount' },
    { dashboardWidget: { w: 7, h: 7, x: 7, y: 12 }, l: 'topRootCauses' },
    { dashboardWidget: { w: 7, h: 5, x: 0, y: 19 }, l: 'SystemTotalLogCount' },
    { dashboardWidget: { w: 7, h: 5, x: 0, y: 24 }, l: 'SystemTotalInstanceCount' },
    { dashboardWidget: { w: 7, h: 7, x: 0, y: 12 }, l: 'SystemTotalMetricCount' },
    { dashboardWidget: { w: 10, h: 10, x: 13, y: 19 }, l: 'overAndUnderUtilized' },
    { dashboardWidget: { w: 9, h: 19, x: 14, y: 0 }, l: 'HoneycombMap' },
    { dashboardWidget: { w: 7, h: 3, x: 0, y: 0 }, l: 'insights' },
    { dashboardWidget: { w: 6, h: 10, x: 7, y: 19 }, l: 'topAnomalousPatterns' },
    { dashboardWidget: { w: 7, h: 5, x: 7, y: 0 }, l: 'IncidentPredictionAccuracy' },
    { dashboardWidget: { w: 7, h: 3, x: 0, y: 3 }, l: 'AlertReductionRatio' },
  ],
  empty: [],
};

const DashboardCreation = (props: Object) => {
  const {
    credentials,
    intl,
    userInfo,
    onClose,
    onShowLayoutDetail,
    userListOfOrganization = [],
    organizationName,
    isLoading: isLinkLoading,
  } = props;
  const { isAdmin, isLocalAdmin, userName } = userInfo;

  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    dashBoardName: null,
    systemValue: null,
    otherSystemValue: [],
    userNameValue: isAdmin || isLocalAdmin ? null : userName,
    loading: false,
    systemList: [],
    templateValue: 'defaultInsights',
  });
  const { dashBoardName, systemValue, otherSystemValue, loading, systemList, userNameValue, templateValue } = state;

  const parseSystemData = () => {
    setState({ loading: true, systemValue: '', systemList: [] });
    fetchGet(getEndpoint('systemframework', 2), { customerName: userNameValue, needDetail: false })
      .then((res) => {
        let { ownSystemArr, shareSystemArr } = res;
        ownSystemArr = R.addIndex(R.map)((item, index) => {
          const system = parseJSON(item) || {};
          return { ...system, isShared: false };
        }, ownSystemArr || []);
        shareSystemArr = R.addIndex(R.map)((item, index) => {
          const system = parseJSON(item) || {};
          return { ...system, isShared: true };
        }, shareSystemArr || []);

        let systemList = R.map(
          (item) => {
            const { systemKey, systemDisplayName } = item;
            const { userName, systemName } = systemKey;
            return {
              ...item,
              ownerUserName: userName,
              name: systemDisplayName,
              id: systemName,
              label: `${systemDisplayName} (${userName})`,
              value: systemName,
            };
          },
          [...ownSystemArr, ...shareSystemArr],
        );

        systemList = R.sort((systemA, systemB) => systemA.name.localeCompare(systemB.name), systemList);
        setState({ systemList, loading: false });
      })
      .catch((err) => {
        setState({ systemList: [], loading: false });
      });
  };
  const handleCreate = () => {
    const selectSystem = R.find((item) => item.id === systemValue, systemList);
    const { ownerUserName } = selectSystem;

    if (!ownerUserName || !systemValue) return;
    const otherSystems = []; // include default system
    R.forEach((system) => {
      if (system.value === systemValue || R.includes(system.value, otherSystemValue)) {
        otherSystems.push({
          userName: system.ownerUserName,
          systemName: system.id,
          environmentName: system.systemKey?.environmentName || 'All',
        });
      }
    }, systemList || []);
    setState({ loading: true });
    fetchPost(getEndpoint('dashboardorder'), {
      ...credentials,
      targetUser: userNameValue,
      customerName: ownerUserName,
      systemName: systemValue,
      orderModelSet: JSON.stringify(templateMap[templateValue] || []),
      dashboardName: dashBoardName || '',
      'other-systems': JSON.stringify(otherSystems),
    })
      .then((data) => {
        onShowLayoutDetail(
          { systemId: systemValue, customerName: userNameValue, dashBoardName, systemFramework: selectSystem },
          true,
        );
        setState({ loading: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        setState({ loading: false });
      });
  };

  useEffect(() => {
    if (userNameValue) {
      parseSystemData();
    }
  }, [userNameValue]);

  const otherSystemList = useMemo(() => {
    // exclude selected default system
    return R.filter((e) => e.value !== systemValue, systemList);
  }, [systemValue]);

  const hasError = !dashBoardName || !systemValue || (isAdmin || isLocalAdmin ? !userNameValue : false);

  return (
    <Modal title="Create a dashboard" visible footer={null} onCancel={() => onClose()}>
      <Form layout="vertical">
        <Form.Item label="Dashboard name" required>
          <Input value={dashBoardName} onChange={(e) => setState({ dashBoardName: e.target.value })} />
        </Form.Item>
        {(isAdmin || isLocalAdmin) && (
          <Form.Item label={intl.formatMessage(appFieldsMessages.user)} required>
            <Select
              showSearch
              value={userNameValue}
              style={{ width: '100%' }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(userNameValue) => setState({ userNameValue })}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item} value={item}>
                    {`${item} (${organizationName})`}
                  </Select.Option>
                ),
                userListOfOrganization || [],
              )}
            </Select>
          </Form.Item>
        )}

        {userNameValue && (
          <Form.Item label="System" required>
            <Select
              showSearch
              value={systemValue}
              options={systemList}
              optionFilterProp="label"
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(systemValue) => setState({ systemValue, otherSystemValue: [] })}
              loading={loading}
            />
          </Form.Item>
        )}

        {/*  other system seletor */}
        {!!userNameValue && !!systemValue && otherSystemList.length > 0 && (
          <Form.Item label="Other System">
            <Select
              showSearch
              mode="multiple"
              value={otherSystemValue}
              options={otherSystemList}
              optionFilterProp="label"
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(otherSystemValue) => setState({ otherSystemValue: otherSystemValue })}
              loading={loading}
            />
          </Form.Item>
        )}

        <Form.Item label="Template" required>
          <Select
            showSearch
            value={templateValue}
            options={stencilOption}
            optionFilterProp="label"
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(templateValue) => setState({ templateValue })}
          />
        </Form.Item>
      </Form>

      <div className="text-center">
        <Button
          size="large"
          disabled={hasError}
          loading={loading || isLinkLoading}
          type="primary"
          onClick={handleCreate}
          style={{ width: 300 }}
          icon={<DashboardLoyout style={{ color: '#cbb1ed', fontSize: 16 }} />}
        >
          New dashboard
        </Button>
      </div>
      {/* <div className="createNewDashboard">
        <DashboardLayoutSvg style={{ fontSize: 80 }} />
        <div className="description">Snap widgets into place on a grid</div>
        <Button
          size="large"
          disabled={hasError}
          loading={loading || isLinkLoading}
          type="primary"
          onClick={handleCreate}
          style={{ width: 300 }}
        >
          New dashboard
        </Button>
      </div> */}
    </Modal>
  );
};

export default DashboardCreation;
