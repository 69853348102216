import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const logout = (credentials: Credentials, params: Object) => {
  return fetchPost(getEndpoint('logout'), {
    ...credentials,
  }).then((d) => {
    return d;
  });
};
export default logout;
