import React from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Col, Form, Input, List, message, Button, Select, Spin, Typography, Space, Row, Table, Divider } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { Modal } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import fetchPost from '../../../common/apis/fetchPost';
import { Regex } from '../../../common/utils';
import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { eventActionMessages } from '../../../common/metric/messages';
import fetchDelete from '../../../common/apis/fetchDelete';

type Props = {
  intl: Object,
  incident: Object,
  credentials: Object,
  // eslint-disable-next-line
  projectName: String,
  onClose: Function,
  title: String,
  activeKey: String,
};

class PatternConfigModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.keyMap = {
      incident: 'incident',
      logAlert: 'logAnomaly',
      metric: 'metricAnomaly',
      logAnomaly: 'logAnomaly',
    };
    this.formRef = React.createRef();
    this.state = {
      loading: true,

      actions: [],
      actionValue: '',
      email: '',
      emailList: [],
      savedEmailList: [],
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  @autobind
  reloadData(props) {
    const {
      projectName,
      incident: { patternId, type },
      activeKey,
    } = props;
    const requestData = {
      projectName,
      patternId,
      type: this.keyMap[activeKey],
    };
    if (['logAnomaly', 'logAlert'].includes(activeKey)) {
      requestData.anomalyType = type;
    }
    fetchGet(getEndpoint('ActionConfigServlet', 2), requestData)
      .then((res) => {
        const { actions, emailRecipients } = res;
        const { newActions, bindEmails } = this.parseData({
          rawActions: actions,
          rawEmail: emailRecipients,
        });
        this.setState({
          actions: newActions,
          savedEmailList: bindEmails,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }

  @autobind
  parseData({ rawActions = [], rawEmail = [] }) {
    const { actions } = this.state;
    const newActions = R.map((item) => {
      const {
        actionName,
        key: { actionId },
      } = item;
      return { label: actionName, value: actionId };
    }, rawActions);

    let emailArray = R.map((item) => {
      const [action, email] = item;
      const { label: actionName } = R.find((n) => n.value === action, R.concat(newActions, actions)) || {
        label: null,
      };
      return { action, email, actionName };
    }, R.toPairs(rawEmail));
    emailArray = R.filter((n) => n.actionName, emailArray);
    const bindEmails = R.reduce(
      (pre, cur) => {
        const group = R.addIndex(R.map)((item, index) => {
          return { action: cur.action, actionName: cur.actionName, email: item };
        }, cur.email);
        return [...pre, ...group];
      },
      [],
      emailArray,
    );
    return {
      newActions,
      bindEmails,
    };
  }

  @autobind
  async handleTakeAction() {
    const {
      credentials,
      activeKey,
      projectName,
      incident: { patternId, instanceName, type },
    } = this.props;
    const { emailList, actionValue } = this.state;

    const requestData = {
      ...credentials,
      patternId,
      projectName,
      emailRecipient: JSON.stringify(emailList),
      actionId: actionValue,
      instanceName,
      type: this.keyMap[activeKey],
    };
    if (['logAnomaly', 'logAlert'].includes(activeKey)) {
      requestData.anomalyType = type;
    }

    this.setState({
      loading: true,
    });
    fetchPost(getEndpoint(`IFActionTriggerServlet`, 2), requestData)
      .then((res) => {
        message.success('Action updated');
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        message.error(e.meesage);
        this.setState({
          loading: false,
        });
      });
  }

  @autobind
  onSavedAction() {
    const {
      credentials,
      activeKey,
      projectName,
      incident: { patternId, instanceName, type },
    } = this.props;
    const { savedEmailList } = this.state;

    const groupByAction = R.groupBy((item) => item.action, savedEmailList);

    const emailRecipients = R.mapObjIndexed((value) => {
      return R.map((item) => item.email, value);
    }, groupByAction);

    const actionIds = R.keys(emailRecipients);

    const requestData = {
      ...credentials,
      patternId,
      projectName,
      emailRecipients: JSON.stringify(emailRecipients),
      actionIds: JSON.stringify(actionIds),
      instanceName,
      type: this.keyMap[activeKey],
    };
    if (['logAnomaly', 'logAlert'].includes(activeKey)) {
      requestData.anomalyType = type;
    }
    this.setState({
      loading: true,
    });
    fetchPost(getEndpoint(`ActionConfigServlet`, 2), requestData)
      .then((res) => {
        message.success('Action updated');
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        message.error(e.meesage);
        this.setState({
          loading: false,
        });
      });
  }

  @autobind
  async handleAddEmail() {
    await this.formRef.current.validateFields();
    const { emailList, email } = this.state;
    if (emailList.includes(email)) {
      message.info('email already exists');
      return;
    }
    this.setState({
      emailList: [...emailList, email],
    });
  }

  @autobind
  handleRemoveEmail(index) {
    const { emailList } = this.state;
    this.setState({
      emailList: R.remove(index, 1, emailList),
    });
  }

  @autobind
  hanldeSaveAction() {
    const { savedEmailList, emailList, actionValue } = this.state;
    const { bindEmails } = this.parseData({ rawEmail: { [actionValue]: emailList } });

    this.setState(
      {
        savedEmailList: R.uniqBy((x) => x.email, R.concat(savedEmailList, bindEmails)),
      },
      () => {
        this.onSavedAction();
      },
    );
  }

  @autobind
  handleActionDelete(rowData, index) {
    const { savedEmailList } = this.state;
    this.setState(
      {
        savedEmailList: R.remove(index, 1, savedEmailList),
      },
      () => {
        this.onDeleteAction(rowData);
      },
    );
  }

  @autobind
  onDeleteAction(rowData) {
    const {
      credentials,
      activeKey,
      projectName,
      incident: { patternId, instanceName, type },
    } = this.props;
    const { action, email } = rowData;

    const requestData = {
      ...credentials,
      patternId,
      projectName,
      action,
      email,
      instanceName,
      type: this.keyMap[activeKey],
    };

    if (['logAnomaly', 'logAlert'].includes(activeKey)) {
      requestData.anomalyType = type;
    }
    this.setState({
      loading: true,
    });
    fetchDelete(getEndpoint(`ActionConfigServlet`, 2), requestData)
      .then((res) => {
        message.success('Action updated');
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        message.error(e.meesage);
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { onClose, title, intl } = this.props;
    const { loading, actions, actionValue, email, emailList, savedEmailList } = this.state;

    const isEmailFormat = Regex.email.test(email);
    const hasError = !actionValue || R.isEmpty(emailList);
    return (
      <Modal
        title={title}
        width={850}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        style={{ height: 700 }}
        footer={
          <Space>
            <Button onClick={this.hanldeSaveAction} disabled={hasError} type="primary">
              Save Action
            </Button>
            <Button onClick={this.handleTakeAction} disabled={hasError} type="primary">
              Take Action
            </Button>
          </Space>
        }
      >
        <Spin wrapperClassName="full-width full-height spin-full-height overflow-y-hidden" spinning={loading}>
          <h3>{intl.formatMessage(eventActionMessages.savedAction)}:</h3>
          <Table
            dataSource={savedEmailList}
            pagination={false}
            size="small"
            scroll={{
              y: 150,
            }}
            rowKey={(row) => row.email}
          >
            <Table.Column
              title="Action"
              dataIndex="actionName"
              sorter={(a, b) => a.actionName.localeCompare(b.actionName)}
              width={350}
            />
            <Table.Column title="Email" dataIndex="email" key="email" />
            <Table.Column
              dataIndex="action"
              key="operation"
              width={80}
              render={(_, record, index) => {
                return (
                  <Button
                    size="small"
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => this.handleActionDelete(record, index)}
                  />
                );
              }}
            />
          </Table>
          <h3 style={{ margin: '30px 0 20px 0' }}>Take action:</h3>
          <Form ref={this.formRef} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }} style={{ width: '99%' }}>
            <Form.Item
              label="Action"
              rules={[{ required: true, message: intl.formatMessage(appFieldsMessages.inputRequired) }]}
              name="Action"
            >
              <Select
                allowClear
                showSearch
                size="small"
                filterOption
                placeholder=""
                maxTagCount={1}
                options={actions}
                onChange={(value) => this.setState({ actionValue: value })}
                value={actionValue}
              />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage(appFieldsMessages.email)}
              rules={[{ required: true, pattern: Regex.email, message: 'Email is incorrect' }]}
              name="email"
            >
              <Row align="middle" gutter={10}>
                <Col flex={1}>
                  <Input
                    placeholder=""
                    allowClear
                    size="small"
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={this.handleAddEmail}
                    disabled={!isEmailFormat}
                    size="small"
                    icon={<PlusCircleOutlined size={14} />}
                  >
                    {intl.formatMessage(appButtonsMessages.add)}
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            {emailList && emailList.length > 0 && (
              <Form.Item label="　" colon={false}>
                <List
                  bordered
                  size="small"
                  dataSource={emailList}
                  renderItem={(item, index) => (
                    <List.Item className="flex-row flex-space-between">
                      <Typography.Text ellipsis>{item}</Typography.Text>
                      <Button size="small" onClick={() => this.handleRemoveEmail(index)}>
                        {intl.formatMessage(appButtonsMessages.remove)}
                      </Button>
                    </List.Item>
                  )}
                  style={{ width: '100%', height: '200px', border: '1px solid #d9d9d9', overflowY: 'auto' }}
                  className="corner-10"
                />
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const PatternConfigModal = injectIntl(PatternConfigModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, projects, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      location,
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,
    };
  },
  { updateLastActionInfo },
)(PatternConfigModal);
