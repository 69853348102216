/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2024
 * *****************************************************************************
 */
import * as R from 'ramda';
import { round } from 'lodash';

import { parseJSON } from '../../utils';

/**
 * calculationBaseLineLogic is a function that calculates the original baseline data for the line chart.
 * @param {Array} dataList - The data（baseline response） list object.
 * @param {Array} metricList - The metric list object.
 */
const calculationBaseLineLogic = (dataList, metricList) => {
  const datasetInstanceBaselineList = [];
  const eventLineChartBaselineData = {};
  R.forEach((data) => {
    const interval = 60 * 1000;
    // parse baseline
    R.forEachObjIndexed((value, instance) => {
      if (!datasetInstanceBaselineList.includes(instance)) datasetInstanceBaselineList.push(instance);

      const baselineList = parseJSON(value) || [];
      R.forEach((baseline) => {
        const { baseLineData } = baseline;
        if (baseLineData) {
          const baseLineDataList = parseJSON(baseLineData) || [];
          R.forEach((item) => {
            const { s: statsModelForInstance, t: timePair } = item;
            const { s: statsModelMap } = statsModelForInstance || {};
            const { e: endTime, s: startTime } = timePair;

            const timeAllList = [];
            const endTimeMin = round(endTime / interval);
            const startTimeMin = round(startTime / interval);
            R.forEach((timeMin) => {
              timeAllList.push(timeMin * interval);
            }, R.range(startTimeMin, endTimeMin + 1));

            R.forEachObjIndexed((metricVal, metricName) => {
              if (!R.has(metricName, eventLineChartBaselineData)) {
                eventLineChartBaselineData[metricName] = {};
              }

              R.forEach((timestamp) => {
                if (R.has('u', metricVal) && R.has('l', metricVal)) {
                  const { u: upperBound, l: lowerBound } = metricVal;
                  if (!R.has(timestamp, eventLineChartBaselineData[metricName])) {
                    eventLineChartBaselineData[metricName][timestamp] = {};
                  }
                  eventLineChartBaselineData[metricName][timestamp][instance] = {
                    upperBound,
                    lowerBound,
                  };
                }
              }, timeAllList);
            }, R.pick(metricList || [], statsModelMap || {}));
          }, baseLineDataList);
        }
      }, baselineList);
    }, data || {});
  }, dataList);
  return { datasetInstanceBaselineList, eventLineChartBaselineData };
};
export default calculationBaseLineLogic;
