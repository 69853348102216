/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { get, round, isString } from 'lodash';
import moment from 'moment';

import parseEventList from './parsers/parseEventList';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const parseJSON = (str) => {
  let ret = str;
  if (isString(str)) {
    try {
      ret = JSON.parse(str);
    } catch (ex) {
      ret = str;
    }
  }
  return ret;
};

const getJobAnalysis = (credentials: Credentials, params: Object) => {
  const { customerName, startTime, endTime, cloudType, day } = params;

  return fetchGet(getEndpoint('jobListData'), {
    ...credentials,
    customerName,
    startTime,
    endTime,
    day,
    cloudtype: cloudType,
  })
    .then((d) => {
      const jobAnalysis = [];
      R.forEachObjIndexed((val, systemId) => {
        const { environment, jobs } = val;
        R.forEachObjIndexed((jobVal, jobId) => {
          const { jobMetric, event } = jobVal;
          let { healthScore } = jobVal;
          healthScore = Math.round(healthScore * 100) / 100;
          const {
            finishTime,
            startTime,
            stages,
            failedMapAttempts,
            killedMapAttempts,
            finishedTime,
            startedTime,
          } = jobMetric;
          let { state, recommendations } = jobMetric;
          const executionTime = finishTime ? finishTime - startTime : finishedTime - startedTime;
          const anomalyState = healthScore < 95 ? 'FAILED' : 'SUCCEEDED';
          let jobStatus = 'Success';

          if (anomalyState !== 'SUCCEEDED') {
            jobStatus = 'Fail';
          }

          if (!state) {
            R.forEach((stage) => {
              state = stage.status || state;
            }, stages);
          }

          switch (cloudType) {
            case 'MapReduce':
              if (state === 'FAILED' || state === 'KILL_WAIT' || state === 'KILLED' || failedMapAttempts) {
                jobStatus = 'Fail';
              }
              break;
            case 'Spark':
              if (state === 'FAILED') {
                jobStatus = 'Fail';
              }
              break;
            case 'Jenkins':
              break;
            default:
          }

          recommendations = [];
          R.forEach((evt) => {
            try {
              const rc = parseJSON(evt.rootCauseJson);
              const suggestedActions = R.map((s) => {
                const idx = s.indexOf('instanceId');
                return idx > 0 ? s.substr(0, idx) : s;
              }, R.filter((i) => Boolean(i), get(rc, ['suggestedActions'], '').split('\n')));
              recommendations = [...recommendations, ...suggestedActions];
            } catch (e) {}
          }, event);

          recommendations = R.uniq(recommendations || []);
          if (state) {
            jobAnalysis.push({
              ...jobMetric,
              systemId,
              environment,
              executionTime,
              healthScore,
              recommendations,
              state,
              anomalyState,
              jobStatus,
              event: parseEventList({ All: event }, { instanceGroup: 'All' }),
            });
          }
        }, jobs || {});
      }, d || {});

      return {
        jobAnalysis,
      };
    })
    .catch((e) => {
      console.error('[IF_API] ', e);
      return { jobAnalysis: [] };
    });
};

export default getJobAnalysis;
