/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import addExternalServiceEpic from './addExternalServiceEpic';
import createProjectEpic from './createProjectEpic';
import loadExternalServiceListEpic from './loadExternalServiceListEpic';
import loadProjectSettingsEpic from './loadProjectSettingsEpic';
import loadProjectHolidayEpic from './loadProjectHolidayEpic';
import loadRabbitMQStatusListEpic from './loadRabbitMQStatusListEpic';
import loadProjectMetricSettingsEpic from './loadProjectMetricSettingsEpic';
import pickProjectModelEpic from './pickProjectModelEpic';
import exportProjectModelEpic from './exportProjectModelEpic';
import removeExternalServiceEpic from './removeExternalServiceEpic';
import saveProjectSettingsEpic from './saveProjectSettingsEpic';
import saveProjectInstanceGroupEpic from './saveProjectInstanceGroupEpic';
import saveProjectAgentListEpic from './saveProjectAgentListEpic';
import saveProjectHolidayEpic from './saveProjectHolidayEpic';
import saveProjectMetricSettingsEpic from './saveProjectMetricSettingsEpic';
import removeProjectHolidayEpic from './removeProjectHolidayEpic';
import loadProjectLogStructureEpic from './loadProjectLogStructureEpic';
import removeLogStructureEpic from './removeLogEntryStructureEpic';
import saveLogStructureEpic from './saveLogStructureEpic';
import saveLogSequenceScopeEpic from './saveLogSequenceScopeEpic';
import loadProjectLogSequenceScopeEpic from './loadProjectLogSequenceScopeEpic';
import removeLogSequenceScopeEpic from './removeLogSequenceScopeEpic';

const epics = [
  addExternalServiceEpic,
  createProjectEpic,
  loadExternalServiceListEpic,
  loadProjectSettingsEpic,
  loadProjectHolidayEpic,
  loadProjectMetricSettingsEpic,
  loadRabbitMQStatusListEpic,
  pickProjectModelEpic,
  exportProjectModelEpic,
  removeExternalServiceEpic,
  saveProjectSettingsEpic,
  saveProjectAgentListEpic,
  saveProjectInstanceGroupEpic,
  saveProjectHolidayEpic,
  saveProjectMetricSettingsEpic,
  removeProjectHolidayEpic,
  loadProjectLogStructureEpic,
  removeLogStructureEpic,
  saveLogStructureEpic,
  loadProjectLogSequenceScopeEpic,
  saveLogSequenceScopeEpic,
  removeLogSequenceScopeEpic,
];

export default epics;
