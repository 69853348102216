import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { get, round } from 'lodash';
import { Button, InputNumber, Form, Alert, Spin, message, Row, Col } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
// import { Defaults } from '../../../common/utils';
// import { BaseUrls } from '../../app/Constants';
import { Modal } from '../../../../lib/fui/react';
import { FireworksIcon } from '../../../../lib/fui/icons';

import { appMessages, appButtonsMessages } from '../../../../common/app/messages';
import { eventMessages } from '../../../../common/metric/messages';

const RenderMaxScore = ({ intl, credentials, system }: Object) => {
  const { environmentId, owner, systemId } = system;
  const [errMsg, setErrMsg] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [settings, setSettings] = useState([]);
  const [selectSystem, setSelectSystem] = useState({});
  useEffect(() => {
    setLoading(true);
    fetchGet(getEndpoint('healthviewsetting', 2), {
      ...credentials,
      customerName: owner,
      environmentName: environmentId,
    })
      .then((data) => {
        const systemConfigs = [];
        R.forEachObjIndexed((system, systemId) => {
          const { environmentName } = system.key || {};
          systemConfigs.push({ ...system, systemId, id: systemId, environmentId: environmentName });
        }, data);
        setSettings(systemConfigs);

        const selectSystem = get(data, systemId);
        setSelectSystem(selectSystem);
        setErrMsg(null);
        setLoading(false);
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setLoading(false);
      });
  }, [systemId]);

  const handleSubmit = () => {
    setSubmitting(true);
    const index = R.findIndex(R.propEq('id', systemId))(settings);
    const card = settings[index] || {};
    fetchPost(getEndpoint('healthviewsetting', 2), {
      ...credentials,
      customerName: owner,
      environmentName: environmentId,
      settings: JSON.stringify(
        update(settings, {
          [index]: { $set: { ...card, ...(selectSystem || {}) } },
        }),
      ),
    })
      .then((data) => {
        if (data && data.success) {
          Modal.success({
            content: (
              <div>
                Congratulations! You have successfully updated!
                <FireworksIcon style={{ fontSize: 18, marginLeft: 8 }} />
              </div>
            ),
          });
          setErrMsg(null);
          setSubmitting(false);
        } else {
          if (data.message) setErrMsg(data.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setSubmitting(false);
      });
  };

  return (
    <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height spin-overflow-y">
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} style={{ marginTop: 16 }}>
        {errMsg && (
          <Form.Item wrapperCol={{ offset: 8, span: 14 }} style={{ margin: 0 }}>
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}

        <Form.Item label={intl.formatMessage(eventMessages.logMaxScore)}>
          <InputNumber
            style={{ width: 150 }}
            value={round(selectSystem.logTotal99Percentile || 0, 2)}
            onChange={(logTotal99Percentile) => setSelectSystem({ ...selectSystem, logTotal99Percentile })}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage(eventMessages.metricMaxScore)}>
          <InputNumber
            style={{ width: 150 }}
            value={round(selectSystem.metricTotal99Percentile || 0, 2)}
            onChange={(metricTotal99Percentile) => setSelectSystem({ ...selectSystem, metricTotal99Percentile })}
          />
        </Form.Item>
      </Form>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" loading={isSubmitting} onClick={handleSubmit}>
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

// eslint-disable-next-line
const SystemScoreSetting = ({ intl, credentials, isAdmin, system }: Object) => {
  const { owner } = system;
  return (
    <RenderMaxScore intl={intl} credentials={credentials} isAdmin={isAdmin} customerName={owner} system={system} />
  );
};

export default SystemScoreSetting;
