import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { Button, Input, message, Modal } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { AutoSizer, Column, Container, Table } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

export default function MetricPatternNameRegexModal(props: Object) {
  const { intl, credentials, currentProject, onClose, patternNameRegex } = props;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    regexList: [],
    loading: false,
  });
  const { regexList, loading } = state;
  const listRef = useRef(null);

  useEffect(() => {
    let regexs = R.split(',', patternNameRegex || '');
    regexs = R.filter((regex) => regex, regexs);
    setState({ regexList: R.map((regex) => ({ regex }), regexs) });
  }, [patternNameRegex]);

  const handleAdd = () => {
    setState({ regexList: [{ regex: '' }, ...regexList] });
    if (listRef.current) listRef.current.forceUpdateGrid();
  };

  const regexCellRender = ({ dataKey, rowData, cellData }) => {
    return (
      <Input
        allowClear
        value={cellData}
        onChange={(e) => {
          rowData[dataKey] = e.target.value;
          if (listRef.current) listRef.current.forceUpdateGrid();
          forceUpdate();
        }}
        className={`${cellData ? '' : 'inputIsNil'}`}
      />
    );
  };

  const handleRemove = (rowIndex) => {
    const newRegexList = R.addIndex(R.filter)((item, index) => index !== rowIndex, regexList);
    setState({ regexList: newRegexList });
    if (listRef.current) listRef.current.forceUpdateGrid();
  };

  const removeCellRender = ({ rowIndex }) => {
    return (
      <Button size="small" className="flex-row flex-center-align" onClick={() => handleRemove(rowIndex)}>
        {intl.formatMessage(appButtonsMessages.remove)}
      </Button>
    );
  };

  const handleSave = () => {
    setState({ loading: true });

    const patternNameRegex = R.map((item) => item.regex, regexList || []).toString();

    fetchPost(getEndpoint('events'), {
      ...credentials,
      projectName: currentProject?.projectName,
      operation: 'setPatternNameRegex',
      patternNameRegex,
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          message.success(msg);
          setState({ loading: false });
          onClose(true);
        } else {
          message.error(msg);
          setState({ loading: false });
        }
      })
      .catch((err) => {
        setState({ loading: false });
        message.error(err.message || String(err));
      });
  };

  const hasNullInput = !!R.find((item) => !item.regex, regexList);
  const hasError = hasNullInput;

  return (
    <Modal
      title={intl.formatMessage(appFieldsMessages.configPatternNameRegex)}
      width={650}
      visible
      onCancel={() => onClose()}
      onOk={handleSave}
      okButtonProps={{ disabled: hasError, loading }}
    >
      <Container className="full-width full-height flex-row flex-center-align" style={{ marginBottom: 10 }}>
        <Button type="primary" size="small" className="flex-row flex-center-align" onClick={handleAdd}>
          {intl.formatMessage(appButtonsMessages.add)}
        </Button>
      </Container>

      <Container className="full-width flex-row flex-space-between" style={{ height: 400 }}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              className="with-border"
              width={width}
              height={height}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={40}
              rowCount={regexList.length}
              rowGetter={({ index }) => regexList[index]}
              ref={(dataTable) => {
                listRef.current = dataTable;
              }}
              headerStyle={{ display: 'flex' }}
            >
              <Column
                width={100}
                flexGrow={1}
                label={intl.formatMessage(appFieldsMessages.patternNameRegex)}
                dataKey="regex"
                cellRenderer={regexCellRender}
              />
              <Column
                width={80}
                label={null}
                dataKey="remove"
                cellRenderer={removeCellRender}
                style={{ display: 'flex' }}
              />
            </Table>
          )}
        </AutoSizer>
      </Container>
    </Modal>
  );
}
