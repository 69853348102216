/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postLogStructure = (credentials: Credentials, params: Object) => {
  const { projectName, entries } = params;
  return fetchPost(getEndpoint('logstructure'), {
    ...credentials,
    projectName,
    entries: JSON.stringify(entries),
  }).then(d => {
    return d;
  });
};

export default postLogStructure;
