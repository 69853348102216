/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import { get } from 'lodash';
import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getProjectAgentList = (credentials: Credentials, projectName: String) => {
  return fetchGet(getEndpoint('projects/syscall/settings'), {
    ...credentials,
    projectName,
    operation: 'listSyscallInstances',
  }).then((d) => {
    const rawData = d.data;
    let agentList = rawData || [];

    agentList = R.map((a) => ({ ...a, agentType: 'Syscall' }), agentList);
    const data = { agentList };

    return { rawData, data };
  });
};

export default getProjectAgentList;
