/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 * */

const Regex = {
  phone: /^[0-9]{1,20}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  emailExcludePerson: /^((?!.*@mail|@gmail|@yahoo|@msn|@hotmail|@live|@qq|@163.*).)*$/,
  url: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
  userName: /^[a-zA-Z0-9]{1,256}$/,
  projectName: /^[0-9a-zA-Z-]+$/,
  instanceName: /^((?!:|_|\[|\]).){1,256}$/,
};

export default Regex;
