/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Form, message, Popover, Select, Input as AntdInput, InputNumber, Spin } from 'antd';
import momenttz from 'moment-timezone';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { State } from '../../../../common/types';
import { EndPointParser } from '../../../../common/utils';
import { Container, Input, CodeMirror } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  tabKey: String,

  intl: Object,
  projectName: String,
  currentLoadingComponents: Object,
  saveProjectSettings: Function,

  // eslint-disable-next-line
  jdbcCommands: String,

  // eslint-disable-next-line
  splunkDomain: String,
  // eslint-disable-next-line
  splunkToken: String,

  // eslint-disable-next-line
  elasticSearchEndPointList: String,
  // eslint-disable-next-line
  elasticSearchUser: String,
  // eslint-disable-next-line
  elasticSearchPassword: String,

  // eslint-disable-next-line
  hbaseMasterNodesList: String,
  // eslint-disable-next-line
  hbaseNameNodeList: String,
  // eslint-disable-next-line
  hbaseDataNodeList: String,
  // eslint-disable-next-line
  habseYarnManagerList: String,
  // eslint-disable-next-line
  hbaseRegionServerList: String,

  // eslint-disable-next-line
  mapReduceDataNode: String,
  // eslint-disable-next-line
  mapReduceMasterHistory: String,
  // eslint-disable-next-line
  mapReduceMasterNodeManager: String,
  // eslint-disable-next-line
  mapReduceMasterResource: String,
  // eslint-disable-next-line
  mapReduceNameNode: String,
  // eslint-disable-next-line
  mapReduceYARN: String,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  currentProject: Object,
  refreshTime: Number,
  defaultTimezone: String,
};

class EndpointSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { defaultTimezone } = props;
    this.submitLoadingKey = 'settings_sharedUserNames_submit';
    this.systemNameLoader = 'settings_system_name_submit';
    this.jdbcCommandsLoader = 'settings_jdbc_commands_submit';
    this.splunkLoader = 'settings_splunk_submit';
    this.elasticSearchLoader = 'settings_elasticSearch_submit';
    this.mapReduceLoader = 'settings_mapReduce_submit';
    this.hbaseLoader = 'settings_hbase_submit';

    this.namesSeparator = ',';
    this.settingFieldList = [
      'splunkDomain',
      'splunkToken',

      // 'jdbcCommands',

      'elasticSearchEndPointList',
      'elasticSearchUser',
      'elasticSearchPassword',

      'hbaseMasterNodesList',
      'hbaseNameNodeList',
      'hbaseDataNodeList',
      'habseYarnManagerList',
      'hbaseRegionServerList',

      'mapReduceDataNode',
      'mapReduceMasterHistory',
      'mapReduceMasterNodeManager',
      'mapReduceMasterResource',
      'mapReduceNameNode',
      'mapReduceYARN',
    ];
    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
    this.samplingUnitOptions = [
      { label: 'minute', value: 1 },
      { label: 'hour', value: 60 },
      { label: 'day', value: 1440 },
    ];

    this.state = {
      ...this.getSettingFields(props, this.settingFieldList),
      isLoadingKey: false,
      timezone: defaultTimezone,
      collectionInterval: null,
      connectionString: null,
      dbPassword: null,
      dbUserName: null,
      jdbcCommands: null,
      collectionUnit: 1,
    };
  }

  componentDidMount() {
    if (this.props.tabKey === 'JDBCDataSource') {
      this.reloadKey(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {};
    let changed = false;

    // If the users prop is changed, set the local state.
    const nextPropsFields = this.getSettingFields(nextProps, this.settingFieldList);
    const statesFields = this.getSettingFields(this.state, this.settingFieldList);
    R.forEachObjIndexed((value, key) => {
      if (!R.identical(value, get(statesFields, key, ''))) {
        newState[key] = value;
        changed = true;
      }
    }, nextPropsFields);

    if (changed) {
      this.setState(newState);
    }

    if (this.props.refreshTime !== nextProps.refreshTime) {
      if (nextProps.tabKey === 'JDBCDataSource') {
        this.reloadKey(nextProps);
      }
    }
  }

  @autobind
  reloadKey(props) {
    const { credentials, projectName, currentProject } = props;

    this.setState({ isLoadingKey: true });
    const cloudType = get(currentProject, ['cloudType']);
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const {
          success,
          message: msg,
          timezone,
          collectionInterval,
          connectionString,
          dbPassword,
          dbUserName,
          sqlQuery,
        } = data;
        if (success === undefined || success) {
          this.setState({
            timezone,
            isLoadingKey: false,
            collectionInterval,
            connectionString,
            dbPassword,
            dbUserName,
            jdbcCommands: sqlQuery,
            collectionUnit: 1,
          });
        } else {
          message.error(msg || String(msg));
          this.setState({ isLoadingKey: false });
        }
      })
      .catch((e) => {
        message.error(e.message || String(e));
        this.setState({ isLoadingKey: false });
      });
  }

  @autobind
  getSettingFields(props, fieldList) {
    const settingVals = {};
    R.forEach((f) => {
      settingVals[f] = get(props, f, '');
    }, fieldList);
    return settingVals;
  }

  @autobind
  updateJDBCCommands(editor, data, value) {
    this.setState({ jdbcCommands: value });
  }

  @autobind
  async handleJDBCSaveClick() {
    const { saveProjectSettings, projectName, credentials } = this.props;
    const { timezone, collectionInterval, connectionString, dbPassword, dbUserName, collectionUnit } = this.state;
    const jdbcCommands = this.state.jdbcCommands || '';

    await fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      timezone,
      collectionInterval: Number(collectionInterval) * collectionUnit,
      connectionString,
      dbPassword,
      dbUserName,
      sqlQuery: jdbcCommands,
    })
      .then((data) => {
        const { success, message: msg } = data;
        if (success === undefined || success) {
          this.reloadKey(this.props);
        } else {
          message.error(msg);
        }
      })
      .catch((e) => {
        message.error(e.message || String(e));
      });

    // saveProjectSettings(projectName, { jdbcCommands }, { [this.jdbcCommandsLoader]: true });
  }

  @autobind
  handleSplunkSettingClick() {
    const { saveProjectSettings, projectName } = this.props;
    const { splunkDomain, splunkToken } = this.state;
    saveProjectSettings(
      projectName,
      { splunkSettings: 'yes', splunkDomain, splunkToken },
      { [this.splunkLoader]: true },
    );
  }

  @autobind
  handleElasticSearchSettingClick() {
    const { saveProjectSettings, projectName } = this.props;
    const { elasticSearchEndPointList, elasticSearchUser, elasticSearchPassword } = this.state;
    const updateEndPoint = {
      ElasticSearch: EndPointParser.parserEndPointStr(
        elasticSearchEndPointList,
        elasticSearchUser,
        elasticSearchPassword,
      ),
    };
    saveProjectSettings(
      projectName,
      {
        updateEndPoint: JSON.stringify(updateEndPoint),
      },
      { [this.elasticSearchLoader]: true },
    );
  }

  @autobind
  handleMapReduceSettingClick() {
    const { saveProjectSettings, projectName } = this.props;
    const {
      mapReduceDataNode,
      mapReduceMasterHistory,
      mapReduceMasterNodeManager,
      mapReduceMasterResource,
      mapReduceNameNode,
      mapReduceYARN,
    } = this.state;
    const updateEndPoint = {
      MapReduce_DataNode: EndPointParser.parserEndPointStr(mapReduceDataNode),
      MapReduce_Master_History: EndPointParser.parserEndPointStr(mapReduceMasterHistory),
      MapReduce_Master_NodeManager: EndPointParser.parserEndPointStr(mapReduceMasterNodeManager),
      MapReduce_Master_Resource: EndPointParser.parserEndPointStr(mapReduceMasterResource),
      MapReduce_NameNode: EndPointParser.parserEndPointStr(mapReduceNameNode),
      MapReduce_YARN: EndPointParser.parserEndPointStr(mapReduceYARN),
    };
    saveProjectSettings(
      projectName,
      {
        updateEndPoint: JSON.stringify(updateEndPoint),
      },
      { [this.mapReduceLoader]: true },
    );
  }

  @autobind
  handleHBaseSettingClick() {
    const { saveProjectSettings, projectName } = this.props;
    const { hbaseMasterNodesList, hbaseNameNodeList, hbaseDataNodeList, habseYarnManagerList, hbaseRegionServerList } =
      this.state;
    const updateEndPoint = {
      HBase_Master: EndPointParser.parserEndPointStr(hbaseMasterNodesList),
      HBase_RegionServer: EndPointParser.parserEndPointStr(hbaseRegionServerList),
      HBase_YARN: EndPointParser.parserEndPointStr(habseYarnManagerList),
      HBase_NameNode: EndPointParser.parserEndPointStr(hbaseNameNodeList),
      HBase_DataNode: EndPointParser.parserEndPointStr(hbaseDataNodeList),
    };
    saveProjectSettings(
      projectName,
      {
        updateEndPoint: JSON.stringify(updateEndPoint),
      },
      { [this.hbaseLoader]: true },
    );
  }

  render() {
    const { intl, currentLoadingComponents, tabKey } = this.props;
    const {
      isLoadingKey,
      timezone,
      jdbcCommands,
      connectionString,
      dbUserName,
      dbPassword,
      collectionInterval,
      collectionUnit,
    } = this.state;
    const splunkDomainLink = VLink.state(this, 'splunkDomain');
    const splunkTokenLink = VLink.state(this, 'splunkToken');

    const elasticSearchEndpointLink = VLink.state(this, 'elasticSearchEndPointList');
    const elasticSearchUserNameLink = VLink.state(this, 'elasticSearchUser');
    const elasticSearchPasswordLink = VLink.state(this, 'elasticSearchPassword');

    const hbaseMasterNodesLink = VLink.state(this, 'hbaseMasterNodesList');
    const nameNodeListLink = VLink.state(this, 'hbaseNameNodeList');
    const dataNodeListLink = VLink.state(this, 'hbaseDataNodeList');
    const resourceManagerListLink = VLink.state(this, 'habseYarnManagerList');
    const hbaseRegionServerListLink = VLink.state(this, 'hbaseRegionServerList');

    const mapReduceDataNodeLink = VLink.state(this, 'mapReduceDataNode');
    const mapReduceMasterHistoryLink = VLink.state(this, 'mapReduceMasterHistory');
    const mapReduceMasterNodeManagerLink = VLink.state(this, 'mapReduceMasterNodeManager');
    const mapReduceMasterResourceLink = VLink.state(this, 'mapReduceMasterResource');
    const mapReduceNameNodeLink = VLink.state(this, 'mapReduceNameNode');
    const mapReduceYARNLink = VLink.state(this, 'mapReduceYARN');

    const isJDBCCommandsSaving = get(currentLoadingComponents, this.jdbcCommandsLoader, false);
    const isSplunkSettingSaving = get(currentLoadingComponents, this.splunkLoader, false);
    const isElasticSearchSaving = get(currentLoadingComponents, this.elasticSearchLoader, false);
    const isHBaseSaving = get(currentLoadingComponents, this.hbaseLoader, false);
    const isMapReduceSaving = get(currentLoadingComponents, this.mapReduceLoader, false);

    const JDBCHasError = !timezone || !connectionString || !collectionInterval;

    return (
      // <Container fullHeight className={`overflow-y-auto ${isLoadingKey ? ' loading' : ''}`}>
      <Container fullHeight className="overflow-y-auto">
        <form className="ui form" style={{ width: 800 }}>
          {tabKey === 'splunkExport' && (
            <div className="field">
              <p>Set the Endpoint and Token to export the data to splunk.</p>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>Endpoint</h3>
                <div className="input" style={{ width: 300 }}>
                  <Input valueLink={splunkDomainLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>Token</h3>
                <div className="input" style={{ width: 300 }}>
                  <Input valueLink={splunkTokenLink} />
                </div>
              </div>
              <Button
                type="primary"
                style={{ marginTop: 8 }}
                loading={isSplunkSettingSaving}
                onClick={this.handleSplunkSettingClick}
              >
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </div>
          )}
        </form>

        {tabKey === 'JDBCDataSource' && (
          <Spin spinning={isLoadingKey}>
            <h3 className="flex-row flex-center-align">
              JDBC query example:
              <Popover
                title={null}
                content={
                  <div className="flex-col" style={{ width: 600, maxHeight: 400, overflowY: 'auto' }}>
                    <h4>JDBC query example:</h4>
                    <div>
                      <span style={{ paddingRight: 4 }}>●</span>select collectTime AS
                      <b> time_stamp[yyyy-MM-dd HH:mm:ss]</b>, hostname AS instance_name, cpuUsed AS cpuUsed, cpuTotal
                      AS cpuTotal, memUsed AS memUsed, diskUsed AS diskUsed, loadAvg AS loadAvg from kpi1 WHERE hostname
                      = 'machine1';
                    </div>
                    <div style={{ fontWeight: 500 }}>This query’s timestamp format is 2019-05-08 15:31:24</div>
                    <div style={{ paddingTop: 8 }}>
                      <span style={{ paddingRight: 4 }}>●</span>select collectTime AS
                      <b> time_stamp[yyyyMMddHHmmss]</b>, hostname AS instance_name, cpuUsed AS cpuUsed, cpuTotal AS
                      cpuTotal, memUsed AS memUsed, diskUsed AS diskUsed, loadAvg AS loadAvg from kpi3 WHERE hostname =
                      'machine1';
                    </div>
                    <div style={{ fontWeight: 500 }}>This query’s timestamp format is 20190508153124</div>
                    <div style={{ paddingTop: 8 }}>
                      <span style={{ paddingRight: 4 }}>●</span>select collectTime AS <b>time_stamp</b>, hostname AS
                      instance_name, cpuUsed AS cpuUsed, cpuTotal AS cpuTotal, memUsed AS memUsed, diskUsed AS diskUsed,
                      loadAvg AS loadAvg from kpi2 WHERE hostname = 'machine1';
                    </div>
                    <div style={{ fontWeight: 500 }}>
                      This query’s doesn’t have timestamp format, so we treate it as epochtime, which the format in the
                      database is long.
                    </div>
                    <div style={{ paddingTop: 8 }}>
                      <span style={{ paddingRight: 4 }}>●</span>select collectTime AS
                      <b> time_stamp[yyyy-MM-dd'T'HH:mm:ss.SSS'Z']</b>, hostname AS instance_name, cpuUsed AS cpuUsed,
                      cpuTotal AS cpuTotal, memUsed AS memUsed, diskUsed AS diskUsed, loadAvg AS loadAvg from kpi2 WHERE
                      hostname = 'machine1';
                    </div>
                    <div style={{ fontWeight: 500 }}>This query’s timestamp format is 2019-05-08T15:31:24.300Z</div>
                    <div style={{ paddingTop: 8 }}>
                      Basically, <b>yyyy</b> is year, <b>MM</b> is month, <b>dd</b> is day, <b>HH</b> is hour, <b>mm</b>{' '}
                      is minute, <b>ss</b> is second, <b>SSS</b> is millissecond.
                    </div>
                  </div>
                }
                placement="rightTop"
                trigger="click"
              >
                <QuestionCircleFilled style={{ fontSize: 16, marginLeft: 4 }} />
              </Popover>
            </h3>
            <p>For JDBC Data source, set the SQL select commands use to query the data.</p>
            <p>
              Use field name &quot;time_stamp&quot; for timestamp, and field name &quot;instance_name&quot; for the name
              of instance. All metric names can be specified freely and value should be double or float.
            </p>
            <CodeMirror
              options={{ lineNumbers: true, mode: 'sql' }}
              onBeforeChange={this.updateJDBCCommands}
              value={jdbcCommands || ''}
            />

            <Form layout="vertical">
              <Form.Item
                style={{ marginTop: 20 }}
                label="Database Connection"
                validateStatus={!connectionString ? 'error' : 'success'}
                help={!connectionString ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <CodeMirror
                  options={{ lineNumbers: true, mode: 'sql' }}
                  value={connectionString}
                  onBeforeChange={(editor, data, value) => this.setState({ connectionString: value })}
                />
              </Form.Item>

              <Form.Item label="Database User">
                <AntdInput value={dbUserName} onChange={(e) => this.setState({ dbUserName: e.target.value })} />
              </Form.Item>
              <Form.Item label="Database Password">
                <AntdInput.Password
                  autoComplete="new-password"
                  value={dbPassword}
                  onChange={(e) => this.setState({ dbPassword: e.target.value })}
                />
              </Form.Item>

              <Form.Item
                label={intl.formatMessage(appFieldsMessages.defaultTimezone)}
                validateStatus={!timezone ? 'error' : 'success'}
                help={!timezone ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <Select
                  className="tour-timezone"
                  showSearch
                  style={{ width: 200 }}
                  filterOption
                  options={this.timezoneOptions}
                  value={timezone}
                  onChange={(timezone) => this.setState({ timezone })}
                />
              </Form.Item>

              <Form.Item
                label="Collection Interval"
                validateStatus={!collectionInterval ? 'error' : 'success'}
                help={!collectionInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <div className="flex-row flex-center-align">
                  <InputNumber
                    min={1}
                    style={{ width: 200 }}
                    value={collectionInterval}
                    onChange={(collectionInterval) => this.setState({ collectionInterval })}
                  />
                  <Select
                    style={{ width: 120, marginLeft: 4 }}
                    options={this.samplingUnitOptions}
                    value={collectionUnit}
                    onChange={(collectionUnit) => this.setState({ collectionUnit })}
                  />
                </div>
              </Form.Item>
            </Form>

            <Button
              type="primary"
              style={{ marginTop: 8 }}
              loading={isJDBCCommandsSaving}
              onClick={this.handleJDBCSaveClick}
              disabled={JDBCHasError}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Spin>
        )}

        <form className="ui form" style={{ width: 800 }}>
          {tabKey === 'ElasticSearch' && (
            <div className="field">
              <p>For ElasticSearch Setting, set the ElasticSearch Endpoint、ElasticSearch UserName.</p>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>Endpoint</h3>
                <div className="input" style={{ width: 300 }}>
                  <Input valueLink={elasticSearchEndpointLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>UserName</h3>
                <div className="input" style={{ width: 300 }}>
                  <Input valueLink={elasticSearchUserNameLink} autoComplete="false" />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>Password</h3>
                <div className="input" style={{ width: 300 }}>
                  <Input type="password" valueLink={elasticSearchPasswordLink} />
                </div>
              </div>
              <Button
                type="primary"
                style={{ marginTop: 8 }}
                loading={isElasticSearchSaving}
                onClick={this.handleElasticSearchSettingClick}
              >
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </div>
          )}
        </form>

        <form className="ui form" style={{ width: 800 }}>
          {tabKey === 'HBase' && (
            <div className="field">
              <p>
                For HBase Setting, set the HBase MasterNode(s)、NameNode(s)、DataNode(s)、Resource
                Manager(YARN)、RegionServer(s).
              </p>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>MasterNode(s)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={hbaseMasterNodesLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>NameNode(s)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={nameNodeListLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>DataNode(s)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={dataNodeListLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>Resource Manager (YARN)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={resourceManagerListLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>RegionServer(s)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={hbaseRegionServerListLink} />
                </div>
              </div>
              <Button
                type="primary"
                style={{ marginTop: 8 }}
                loading={isHBaseSaving}
                onClick={this.handleHBaseSettingClick}
              >
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </div>
          )}
        </form>

        <form className="ui form" style={{ width: 800 }}>
          {tabKey === 'MapReduce' && (
            <div className="field">
              <p>
                For MapReduce Setting, set the HBase
                DataNode(s)、NameNode(s)、MasterHistory(s)、MasterNodeManager(s)、MasterResource(s)、Resource
                Manager(YARN).
              </p>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>DataNode(s)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={mapReduceDataNodeLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>NameNode(s)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={mapReduceNameNodeLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>MasterHistory(s)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={mapReduceMasterHistoryLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>MasterNodeManager(s)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={mapReduceMasterNodeManagerLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>MasterResource(s)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={mapReduceMasterResourceLink} />
                </div>
              </div>
              <div className="flex-row field" style={{ alignItems: 'center' }}>
                <h3 style={{ margin: 0, width: 240 }}>Resource Manager (YARN)</h3>
                <div className="input" style={{ width: 500 }}>
                  <Input valueLink={mapReduceYARNLink} />
                </div>
              </div>
              <Button
                type="primary"
                style={{ marginTop: 8 }}
                loading={isMapReduceSaving}
                onClick={this.handleMapReduceSettingClick}
              >
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </div>
          )}
        </form>
      </Container>
    );
  }
}

const EndpointSetting = injectIntl(EndpointSettingCore);

export default connect((state: State) => {
  const {
    jdbcCommands,

    splunkDomain,
    splunkToken,

    elasticSearchEndPointList,
    elasticSearchUser,
    elasticSearchPassword,

    hbaseMasterNodesList,
    hbaseNameNodeList,
    hbaseDataNodeList,
    habseYarnManagerList,
    hbaseRegionServerList,

    mapReduceDataNode,
    mapReduceMasterHistory,
    mapReduceMasterNodeManager,
    mapReduceMasterResource,
    mapReduceNameNode,
    mapReduceYARN,
  } = state.settings.projectSettings;
  const { defaultTimezone } = state.app;
  return {
    jdbcCommands,

    splunkDomain,
    splunkToken,

    elasticSearchEndPointList,
    elasticSearchUser,
    elasticSearchPassword,

    hbaseMasterNodesList,
    hbaseNameNodeList,
    hbaseDataNodeList,
    habseYarnManagerList,
    hbaseRegionServerList,

    mapReduceDataNode,
    mapReduceMasterHistory,
    mapReduceMasterNodeManager,
    mapReduceMasterResource,
    mapReduceNameNode,
    mapReduceYARN,
    defaultTimezone,
  };
}, {})(EndpointSetting);
