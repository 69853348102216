export const sampleFileData = `timestamp, cpu[node1]:1,memory[node1]:2,disk_read[node1]:3,disk_write[node1]:4,network_receive[node1]:5,network_send[node1]:6, cpu[node2]:1,memory[node2]:2,disk_read[node2]:3,disk_write[node2]:4,network_receive[node2]:5,network_send[node2]:6, cpu[node3]:1,memory[node3]:2,disk_read[node3]:3,disk_write[node3]:4,network_receive[node3]:5,network_send[node3]:6, cpu[node4]:1,memory[node4]:2,disk_read[node4]:3,disk_write[node4]:4,network_receive[node4]:5,network_send[node4]:6, cpu[node5]:1,memory[node5]:2,disk_read[node5]:3,disk_write[node5]:4,network_receive[node5]:5,network_send[node5]:6
1442555275000,8.7380231,1050.804224,2.732032,0.0,46.175,43.11,3.4068913,1138.601984,0.262144,0.0,5.853,4.709,3.5621096,1628.110848,1.800192,0.0,7.458,6.303,2.8296526,1264.095232,0.004096,0.0,5.119,4.932,3.8720168,1713.414144,0.004096,0.0,7.772,7.607
1442555336000,5.5654848,1072.873472,2.660352,0.0,30.342,27.591,1.7036675,1134.46912,0.032768,0.0,4.211,4.197,2.0013945,1621.93408,0.575488,0.0,4.033,3.53,1.7999406,1264.930816,0.0,0.0,5.399,4.72,1.6588607,1711.345664,0.0,0.0,3.266,3.376
1442555397000,7.0453733,1078.009856,2.482176,0.0,44.761,42.019,2.5252425,1133.842432,0.065536,0.0,6.401,5.038,2.2465352,1628.061696,2.117632,0.0,7.609,6.333,2.7270371,1241.595904,0.0,0.0,6.045,5.455,2.6808957,1714.76992,0.0,0.0,6.111,5.851
1442555457000,7.3391743,1097.367552,2.711552,0.0,37.253,35.301,3.3210812,1139.24096,0.098304,0.0,6.902,6.544,2.5287536,1627.447296,1.683456,0.0,5.328,4.66,3.1230276,1213.743104,0.090112,0.0,5.626,4.671,2.6102019,1719.7056,0.0,0.0,5.153,3.885
1442555517000,6.488525,1098.46528,3.731456,0.0,49.01,46.438,2.3300133,1142.796288,0.065536,0.0,6.015,5.283,2.4738245,1637.482496,2.019328,0.0,5.822,5.963,2.687311,1200.386048,0.0,0.0,7.274,6.256,2.1199127,1721.102336,0.0,0.0,8.319,7.255
1442555577000,8.6754222,1099.259904,4.54656,0.0,50.038,46.677,3.1377512,1219.805184,0.065536,0.0,6.628,4.867,3.3364378,1632.292864,1.298432,0.0,6.122,5.466,3.1246996,1201.053696,0.0,0.0,7.84,7.429,3.1033786,1720.459264,0.0,0.0,6.508,5.821
1442555637000,8.8233972,1099.145216,4.308992,0.0,50.456,47.226,3.1826687,1226.412032,0.0,0.0,7.184,6.977,3.0291741,1645.355008,1.31072,0.0,5.31,5.258,3.471829,1169.526784,0.0,0.0,6.873,5.941,3.4357024,1720.15616,0.0,0.0,8.604,7.362
1442555697000,8.3390728,1101.021184,3.571712,0.0,56.426,53.281,3.1422758,1234.915328,0.0,0.0,7.827,6.31,2.214883,1646.01856,1.308672,0.0,6.639,5.677,3.1025284,1159.9872,0.0,0.0,7.57,6.186,6.8750121,1716.297728,0.032768,0.0,9.574,8.464
1442555757000,10.2822911,1093.435392,2.940928,0.0,64.124,60.517,3.4852079,1222.807552,0.0,0.0,7.741,7.12,3.875427,1643.896832,1.978368,0.0,10.108,8.389,3.4761696,1091.534848,0.0,0.0,7.278,6.467,3.3899614,1719.701504,0.0,0.0,10.782,9.204
1442555817000,9.2595392,1095.094272,3.244032,0.0,53.138,50.474,3.3930914,1224.74496,0.032768,0.0,8.478,7.291,3.1178732,1642.938368,2.000896,0.0,5.708,5.678,3.6516831,1089.80224,0.0,0.0,7.074,5.598,3.529915,1720.922112,0.0,0.0,8.065,7.137
1442555877000,8.0101223,1099.44832,2.097152,0.0,47.852,44.833,1.4640421,1226.436608,0.032768,0.0,6.533,5.218,6.7150868,1647.742976,2.107392,0.0,5.405,5.465,3.2892588,1086.132224,0.0,0.0,5.542,4.86,3.6394281,1724.260352,0.0,0.0,9.293,7.867
1442555937000,8.2809416,1094.885376,2.465792,0.0,51.368,48.283,3.1122787,1225.785344,0.065536,0.0,6.387,5.695,2.8422144,1644.347392,2.074624,0.0,7.068,6.393,3.8319949,1083.88352,0.0,0.0,6.651,6.257,3.6250044,1722.65472,0.0,0.0,8.125,7.951
1442555998000,10.141166,1096.323072,2.490368,0.0,55.964,52.326,3.1302174,1231.302656,0.098304,0.0,7.244,7.1,4.0664125,1645.056,2.10944,0.0,8.269,7.029,3.9382872,1085.58336,0.0,0.0,7.294,6.657,3.6005464,1721.32352,0.0,0.0,7.471,7.158
1442556059000,9.5354086,1096.577024,3.059712,0.0,64.051,59.054,3.3778292,1232.797696,0.032768,0.0,8.328,7.677,4.1334735,1647.140864,2.469888,0.0,8.45,7.719,2.6586687,1083.33056,0.0,0.0,7.63,6.4,5.1504049,1723.76064,0.0,0.0,9.446,8.639
1442556120000,6.0229561,1098.964992,3.649536,0.0,44.982,42.38,2.2098334,1247.780864,0.0,0.0,6.779,5.149,2.1916481,1646.882816,1.8432,0.0,4.851,4.934,1.9543879,1081.573376,0.0,0.0,7.875,6.931,5.4201203,1723.977728,0.0,0.0,7.534,6.06
1442556180000,7.5485225,1099.677696,3.428352,0.0,52.084,48.748,2.3772282,1249.0752,0.0,0.0,6.433,5.545,3.3888883,1648.340992,1.531904,0.0,7.1,6.26,3.0387455,1084.993536,0.0,0.0,7.837,6.788,2.3406992,1724.223488,0.0,0.0,7.599,6.717
1442556241000,8.0233689,1101.062144,3.067904,0.0,51.311,48.04,2.7369976,1250.045952,0.0,0.0,5.53,4.416,2.064922,1648.787456,1.554432,0.0,6.258,5.645,3.1301115,1081.663488,0.0,0.0,8.698,7.735,2.7153856,1725.31712,0.0,0.0,7.594,5.979
1442556301000,9.6535341,1100.169216,1.421312,0.0,56.786,52.169,2.6848711,1252.57728,0.0,0.0,6.483,6.385,7.7133596,1648.76288,1.753088,0.0,6.456,5.655,3.579517,1080.356864,0.0,0.0,6.69,5.38,3.8476313,1726.676992,0.0,0.0,9.626,8.959
1442556361000,9.4405336,1094.475776,2.465792,0.0,52.071,48.938,2.8098507,1249.452032,0.0,0.0,7.142,7.167,2.0919822,1630.896128,0.73728,0.0,4.952,3.635,4.3698559,1047.57248,0.0,0.0,9.064,8.127,2.1940342,1719.549952,0.0,0.0,6.635,6.421
1442556421000,8.4966685,1099.575296,2.801664,0.0,49.59,47.14,1.8795001,1249.931264,0.0,0.0,5.921,5.032,3.0394595,1635.876864,2.074624,0.0,7.3,6.494,2.9347563,1052.356608,0.0,0.0,8.084,6.669,3.1229739,1723.355136,0.0,0.0,6.87,6.47
1442556481000,7.8298521,1097.940992,3.31776,0.0,42.806,40.645,3.9965964,1258.991616,0.032768,0.0,6.635,5.689,2.2821385,1632.088064,1.529856,0.0,4.323,4.061,2.7833712,1047.236608,0.0,0.0,7.52,6.225,3.6449531,1723.916288,0.0,0.0,7.318,5.882
1442556542000,8.7223353,1097.85088,2.920448,0.0,50.267,47.079,3.3747956,1258.749952,0.065536,0.0,7.143,6.372,2.6437217,1641.0624,2.015232,0.0,7.995,7.062,2.4830353,1044.512768,0.0,0.0,6.995,6.098,3.0614454,1723.629568,0.0,0.0,6.367,5.387
1442556603000,9.9504509,1097.54368,2.78528,0.0,47.911,44.17,3.1304153,1259.433984,0.0,0.0,6.116,5.561,3.2073566,1640.747008,1.460224,0.0,5.681,5.432,4.505259,1039.577088,0.0,0.0,7.258,6.747,3.1784204,1724.407808,0.0,0.0,7.123,6.357
1442556664000,9.1889731,1099.411456,3.780608,0.02048,63.563,59.77,3.6091197,1259.196416,0.0,0.0,8.181,6.408,3.5373651,1640.763392,1.65888,0.012288,7.879,7.005,3.3832646,1031.524352,0.0,0.0,8.305,7.419,3.4226311,1725.116416,0.0,0.0,10.764,9.478
1442556724000,8.7934042,1099.730944,2.772992,0.02048,59.892,56.771,3.1335017,1262.006272,0.032768,0.0,11.074,9.126,2.7009498,1640.853504,1.398784,0.004096,7.098,5.7,2.7561294,1026.666496,0.0,0.0,7.44,6.29,2.7240464,1726.40256,0.0,0.0,8.68,7.853
1442556784000,7.5292799,1100.632064,4.308992,0.02048,55.31,52.045,2.6388128,1261.129728,0.0,0.0,8.445,6.953,2.2264226,1641.59488,1.759232,0.004096,6.07,6.109,2.6532132,1022.357504,0.0,0.0,7.568,6.068,3.0745518,1726.046208,0.0,0.0,8.696,7.432
1442556845000,11.7063194,1102.442496,4.358144,0.024576,67.731,63.459,3.6971776,1261.58848,0.032768,0.0,8.023,7.409,4.1172215,1640.706048,1.130496,0.012288,6.535,5.737,4.8160796,1030.115328,0.0,0.0,8.672,8.052,4.3317589,1726.124032,0.0,0.0,11.969,9.894
1442556905000,9.3197879,1099.923456,2.895872,0.024576,59.107,54.674,2.3179904,1262.010368,0.0,0.0,7.029,7.006,2.6519674,1638.01088,1.087488,0.016384,6.16,5.33,4.2129692,1024.991232,0.0,0.0,8.611,8.179,3.2951119,1726.558208,0.0,0.0,8.883,8.386
1442556965000,9.5376686,1087.471616,2.351104,0.028672,53.659,50.27,3.2846919,1247.371264,0.0,0.0,7.812,7.087,3.2440899,1597.448192,1.519616,0.012288,6.44,5.891,3.1116168,1006.333952,0.0,0.0,8.605,7.376,3.5998646,1728.540672,0.0,0.0,6.73,6.177
1442557025000,9.0067798,1089.429504,3.715072,0.02048,64.143,59.687,3.5523555,1249.099776,0.065536,0.0,10.898,9.326,2.7686059,1598.193664,1.519616,0.014336,7.363,6.399,3.1237719,1008.070656,0.0,0.0,11.008,8.96,2.7028515,1730.105344,0.0,0.0,6.952,6.381
1442557086000,10.4606623,1090.62144,3.641344,0.0,61.096,57.194,3.3431587,1247.059968,0.021845,0.0,7.411,6.708,4.110802,1597.554688,1.691648,0.006826,8.626,6.815,3.8209237,1012.432896,0.0,0.0,8.488,6.928,3.6160102,1731.821568,0.0,0.0,9.222,8.535
1442557146000,10.9185843,1093.033984,3.190784,0.0,66.66,62.617,4.1863373,1246.121984,0.0,0.0,8.606,7.588,3.7939058,1600.339968,1.302528,0.0,7.467,6.193,4.2353929,1011.2,0.032768,0.0,9.695,8.855,4.1813347,1733.353472,0.0,0.0,10.373,8.178
1442557206000,8.6561066,1092.038656,3.108864,0.004096,69.436,64.618,3.1808598,1242.615808,0.0,0.0,9.863,7.529,2.6208391,1593.126912,1.529856,0.0,8.105,6.701,3.2868566,1008.61952,0.0,0.0,11.524,9.077,3.3936799,1736.736768,0.0,0.0,9.141,8.447
1442557266000,8.6976335,1094.488064,3.25632,0.004096,80.388,75.198,3.6986697,1245.495296,0.0,0.0,14.239,11.027,2.834301,1590.94784,1.972224,0.0,9.387,7.765,3.3789538,1010.024448,0.032768,0.0,8.981,7.835,2.7499238,1735.5776,0.0,0.0,11.898,9.115
1442557326000,10.6852787,1091.141632,3.39968,0.0,80.314,75.421,4.1921907,1245.696,0.0,0.0,12.789,11.199,3.8367073,1586.475008,2.021376,0.0,7.089,6.704,3.6810988,1007.22688,0.0,0.0,11.728,9.147,4.4749623,1738.149888,0.0,0.0,9.755,9.139
1442557386000,9.7331907,1093.251072,3.4816,0.0,67.832,63.611,3.0594428,1246.027776,0.0,0.0,8.004,7.232,4.1852612,1590.779904,1.5872,0.0,9.363,7.375,3.5891436,1009.82784,0.0,0.0,10.588,9.352,2.8944192,1738.09664,0.0,0.0,10.142,7.944
1442557447000,11.0172066,1090.863104,4.087808,0.0,63.261,58.42,4.2264198,1253.834752,0.0,0.0,8.763,7.192,3.6017497,1586.85184,0.884736,0.0,6.71,5.258,3.8498321,1010.716672,0.0,0.0,8.504,8.236,4.3261369,1738.891264,0.0,0.0,9.75,7.216
1442557507000,7.959043,1095.8848,3.19488,0.0,66.918,62.656,3.2107054,1254.965248,0.0,0.0,9.595,7.972,2.5930657,1574.100992,1.564672,0.0,7.824,7.101,3.1310879,1019.805696,0.0,0.0,11.359,9.383,3.6500885,1739.665408,0.0,0.0,8.853,7.432
1442557568000,11.2247251,1096.94976,2.932736,0.0,72.114,67.019,4.4648058,1252.622336,0.032768,0.0,10.308,9.183,3.3688037,1530.0608,1.359872,0.0,6.525,5.92,4.029873,1010.192384,0.032768,0.0,9.471,7.303,4.0633736,1745.772544,0.0,0.0,11.815,10.509
1442557629000,8.8144053,1097.244672,4.77184,0.0,67.961,63.562,2.7268314,1251.086336,0.0,0.0,6.458,6.365,3.2057382,1527.349248,2.048,0.0,9.546,8.354,3.3154748,1017.393152,0.0,0.0,10.637,9.313,3.3874281,1744.384,0.0,0.0,9.039,8.094
1442557689000,10.0143039,1098.89536,3.100672,0.0,68.74,64.269,2.5647325,1247.072256,0.0,0.0,8.618,7.018,4.0211115,1530.310656,2.136064,0.0,8.74,7.54,4.3619846,1015.775232,0.0,0.0,9.152,8.346,3.5174021,1745.28512,0.0,0.0,9.43,9.001
1442557749000,12.2206607,1095.327744,2.875392,0.0,80.7,74.826,3.8785919,1246.912512,0.0,0.0,10.737,8.94,3.6527073,1529.421824,1.599488,0.0,9.112,7.646,3.4521341,1017.860096,0.0,0.0,13.92,12.052,3.5990166,1746.432,0.0,0.0,9.79,8.906
1442557809000,8.9625561,1098.477568,1.921024,0.0,66.243,62.023,2.9013077,1250.881536,0.0,0.0,8.766,7.52,2.8101646,1527.918592,1.267712,0.0,6.039,5.459,3.9896522,1017.204736,0.0,0.0,10.904,8.58,3.9499396,1748.656128,0.0,0.0,10.424,9.795
1442557869000,11.8887823,1101.303808,2.039808,0.0,73.791,68.818,5.9490685,1244.99968,0.036864,0.0,10.254,8.185,2.9854454,1527.279616,2.013184,0.0,6.869,6.989,4.3292212,1016.799232,0.0,0.0,10.63,9.231,4.4439707,1749.520384,0.0,0.0,11.626,10.37
1442557929000,12.2484731,1102.25408,4.775936,0.0,73.728,68.172,3.7552871,1245.55264,0.0,0.0,8.733,7.88,4.0731601,1528.56576,1.683456,0.0,8.359,7.542,5.3929557,1017.4464,0.0,0.0,10.354,8.896,5.093808,1749.143552,0.0,0.0,11.222,9.116
1442557989000,11.4889491,1101.369344,3.751936,0.0,70.737,65.137,3.7869046,1245.65504,0.0,0.0,6.238,5.521,3.4181499,1528.205312,1.216512,0.0,7.058,6.291,4.7595111,1021.25568,0.0,0.0,11.777,10.323,4.0234119,1745.768448,0.0,0.0,12.502,10.775
1442558050000,7.5842843,1102.70464,2.609152,0.0,62.236,58.602,3.3886755,1240.567808,0.0,0.0,8.726,7.909,2.6994855,1527.103488,1.605632,0.0,7.209,6.748,2.1465073,1019.916288,0.0,0.0,7.386,6.784,2.8459301,1748.430848,0.0,0.0,9.955,8.323
1442558110000,7.4951042,1098.899456,4.407296,0.0,73.622,68.1,2.98124,1248.329728,0.0,0.0,8.916,7.933,3.3662629,1526.673408,1.189888,0.0,9.647,8.049,2.8061641,1020.960768,0.0,0.0,10.292,8.977,2.9673059,1750.073344,0.0,0.0,10.037,8.249
1442558170000,9.688607,1102.364672,2.78528,0.0,73.039,68.578,3.8170684,1228.009472,0.0,0.0,10.47,9.133,2.9988072,1494.990848,1.851392,0.0,9.394,7.874,3.0770946,1028.706304,0.0,0.0,8.301,7.246,3.9864967,1752.317952,0.0,0.0,11.649,10.084
1442558230000,10.0819625,1100.767232,2.961408,0.0,66.547,61.672,3.6673947,1231.355904,0.0,0.0,10.228,9.456,3.0157671,1489.600512,1.49504,0.0,6.98,5.512,3.6036904,1026.994176,0.0,0.0,9.185,8.163,3.2678547,1746.026496,0.0,0.0,9.987,8.191
1442558290000,11.1432617,1104.334848,3.780608,0.0,76.668,71.096,4.5467518,1236.799488,0.0,0.0,9.517,9.167,4.501914,1489.24416,1.982464,0.0,9.103,8.502,4.0074336,1031.041024,0.0,0.0,11.754,10.204,3.9634255,1748.385792,0.0,0.0,9.427,8.027
1442558350000,8.3612407,1105.334272,4.3008,0.0,70.03,66.029,2.6555913,1229.08672,0.0,0.0,7.479,6.818,3.2605735,1491.5584,1.619968,0.0,8.95,7.844,2.9571592,1030.995968,0.0,0.0,10.244,9.267,3.0420018,1749.540864,0.0,0.0,10.075,8.583
1442558411000,9.9075522,1106.608128,1.921024,0.0,70.026,65.482,3.419689,1232.785408,0.0,0.0,9.986,8.823,3.2523461,1492.492288,1.046528,0.0,7.514,6.087,3.921091,1033.17504,0.0,0.0,10.58,8.535,4.0120086,1754.263552,0.0,0.0,10.339,8.507
1442558471000,9.9216814,1105.518592,3.301376,0.0,66.264,61.767,3.5358934,1225.424896,0.0,0.0,6.982,6.243,3.6221801,1493.807104,1.552384,0.0,8.332,7.443,3.6388065,1027.842048,0.0,0.0,9.21,8.229,3.8535394,1754.017792,0.0,0.0,10.985,9.118
1442558531000,10.6970384,1107.144704,4.063232,0.0,77.36,73.053,4.4396178,1223.053312,0.0,0.0,10.922,8.939,4.4185953,1495.748608,1.779712,0.0,10.328,8.664,3.5818266,1028.8128,0.032768,0.0,11.028,9.863,3.3229087,1751.646208,0.0,0.0,9.767,8.022
1442558591000,11.2533252,1107.419136,3.354624,0.0,76.477,71.229,3.828673,1226.952704,0.0,0.0,10.805,9.135,3.6100948,1495.53152,1.245184,0.0,8.226,6.922,3.863921,1032.23296,0.0,0.0,9.904,7.998,4.3292425,1757.585408,0.0,0.0,11.844,10.318
1442558651000,11.0243907,1107.775488,1.765376,0.0,63.724,59.286,3.1331271,1223.168,0.0,0.0,6.961,6.239,4.1820417,1495.064576,2.154496,0.0,9.076,8.43,3.8103334,1030.61504,0.0,0.0,10.029,9.177,3.6826434,1755.267072,0.0,0.0,8.181,7.901
1442558711000,7.5069866,1107.980288,3.821568,0.0,53.186,50.598,2.1601256,1223.856128,0.0,0.0,6.576,6.72,2.2784527,1490.16576,1.949696,0.0,6.686,6.215,2.761551,1027.735552,0.0,0.0,6.932,6.195,3.3317368,1753.919488,0.0,0.0,7.809,7.185
1442558771000,6.7706426,1105.36704,3.182592,0.0,50.472,48.159,2.187169,1206.665216,0.0,0.0,7.007,5.774,2.9618217,1479.380992,1.159168,0.0,6.199,5.368,2.6472944,1030.311936,0.0,0.0,7.5,6.524,2.0764245,1723.940864,0.0,0.0,6.907,6.926
1442558832000,8.9035468,1113.25184,2.072576,0.0,47.463,45.02,17.2844189,1219.403776,0.589824,0.0,6.474,6.18,2.575336,1477.287936,1.796096,0.0,5.932,6.124,3.0780112,1046.331392,0.0,0.0,7.734,6.608,3.1542478,1725.27616,0.0,0.0,7.764,6.024
1442558892000,10.2462543,1114.140672,3.178496,0.0,69.824,65.254,3.7905795,1226.350592,0.0,0.0,8.656,7.035,2.906902,1475.084288,1.714176,0.0,8.111,6.632,4.746457,1040.195584,0.0,0.0,11.37,9.352,3.0477562,1724.964864,0.0,0.0,10.03,8.469
1442558952000,10.5173941,1110.532096,3.878912,0.0,74.345,69.223,4.0774008,1221.558272,0.0,0.0,11.639,9.792,4.0277184,1474.514944,1.456128,0.0,8.673,7.211,3.4934696,1034.981376,0.0,0.0,8.044,7.274,4.2838532,1732.804608,0.0,0.0,10.404,9.435
1442559012000,10.2224034,1115.189248,3.907584,0.0,54.231,50.424,3.3804774,1217.523712,0.0,0.0,7.43,5.786,3.7642698,1474.945024,1.703936,0.0,7.627,6.925,4.5007333,1035.280384,0.0,0.0,7.029,5.591,3.9037224,1725.034496,0.0,0.0,8.469,7.985
1442559072000,10.7147936,1113.735168,3.026944,0.0,64.595,60.176,3.9348746,1218.740224,0.0,0.0,9.378,8.122,3.3534348,1473.855488,1.458176,0.0,5.928,5.123,4.2807192,1034.289152,0.0,0.0,11.12,9.269,4.2293745,1725.759488,0.0,0.0,10.62,8.773
1442559132000,8.4934968,1114.714112,2.707456,0.0,74.306,70.301,3.2605455,1215.094784,0.0,0.0,10.377,9.261,3.1069333,1479.016448,2.06848,0.0,9.254,7.795,3.6243468,1038.680064,0.0,0.0,10.862,8.406,3.3904565,1716.06016,0.0,0.0,10.639,8.729
1442559193000,11.9368334,1115.713536,2.78528,0.0,76.511,71.858,10.3133338,1214.574592,0.0,0.0,10.708,8.177,4.7973904,1475.23584,2.220032,0.0,9.89,8.756,3.9300163,1044.033536,0.0,0.0,8.884,8.438,4.24621,1712.422912,0.0,0.0,12.89,9.935
1442559254000,8.3561427,1116.459008,2.56,0.0,64.664,60.818,2.9722954,1220.067328,0.0,0.0,7.62,6.996,10.4457381,1468.956672,1.589248,0.014336,7.602,6.747,2.979882,1043.562496,0.0,0.0,8.261,7.277,3.1818305,1716.543488,0.0,0.0,10.182,9.984
1442559315000,9.0192356,1116.626944,2.646016,0.008192,64.998,60.532,2.5263425,1220.5056,0.0,0.0,6.598,5.977,3.9073248,1468.358656,1.470464,0.014336,7.177,6.693,3.5879786,1048.199168,0.0,0.0,9.509,9.448,3.6819564,1719.508992,0.0,0.0,10.095,9.123
1442559376000,11.1803099,1112.158208,1.343488,0.008192,33.39,31.533,3.7847798,1203.023872,0.0,0.0,4.27,3.611,2.9258643,1446.555648,1.458176,0.006144,4.975,4.916,3.6201578,1033.822208,0.0,0.0,3.996,3.48,4.7198718,1678.524416,0.0,0.0,10.859,9.279
1442559437000,9.0223606,1125.412864,3.506176,0.0,71.059,65.896,4.0852616,1223.90528,0.0,0.0,8.476,8.677,2.3257398,1456.72192,1.650688,0.0,7.667,6.478,3.9580872,1057.60768,0.0,0.0,9.53,8.33,3.6708591,1685.778432,0.0,0.0,11.434,9.3
1442559497000,7.8253939,1125.154816,4.096,0.0,66.981,61.865,2.632801,1218.859008,0.0,0.0,6.829,6.506,2.7051605,1459.712,2.107392,0.0,8.056,7.187,4.8949016,1057.603584,0.0,0.0,9.4,8.163,3.288228,1689.657344,0.0,0.0,10.572,8.804
1442559557000,9.7561677,1124.265984,2.60096,0.0,76.14,71.559,3.9797117,1223.200768,0.0,0.0,9.395,8.531,3.1901552,1458.028544,1.374208,0.0,9.776,7.826,3.7382155,1058.275328,0.0,0.0,9.601,7.329,3.4687609,1690.50112,0.0,0.0,12.187,11.006
1442559618000,12.1671696,1133.314048,2.70336,0.0,82.41,77.45,4.3997953,1233.391616,0.021845,0.0,10.939,10.096,4.6225039,1457.41824,1.462272,0.0,10.001,9.085,4.7610571,1055.15008,0.0,0.0,10.079,8.827,4.2548649,1693.782016,0.0,0.0,10.637,9.163
1442559679000,3.9133359,1136.242688,2.117632,0.008192,42.555,39.261,0.9212474,1226.641408,0.0,0.0,7.081,6.403,0.289841,1456.955392,0.960512,0.0,2.967,3.157,0.8921215,1052.7744,0.0,0.0,5.984,5.638,1.185097,1691.570176,0.0,0.0,6.955,6.843
1442559739000,11.8538022,1138.122752,3.42016,0.0,85.226,80.272,5.0311564,1237.753856,0.0,0.0,12.801,10.589,4.4297767,1457.475584,1.619968,0.0,10.363,8.791,4.6781825,1054.244864,0.0,0.0,12.867,11.033,4.427888,1689.575424,0.0,0.0,10.789,9.609
1442559799000,11.9429195,1136.57856,2.605056,0.0,75.907,71.234,4.9651758,1235.668992,0.0,0.0,11.234,9.097,4.1660793,1463.177216,1.654784,0.0,9.323,8.46,4.0515063,1055.002624,0.0,0.0,9.66,8.233,4.9597901,1688.522752,0.0,0.0,10.707,9.673
1442559859000,11.0745561,1140.846592,3.239936,0.0,79.935,75.201,3.9273062,1234.448384,0.065536,0.0,8.63,8.418,3.2343401,1461.530624,1.406976,0.0,8.461,6.844,5.0254417,1064.251392,0.0,0.0,13.358,10.597,4.4907775,1687.01952,0.0,0.0,12.079,11.513
1442559919000,11.4154675,1141.239808,1.957888,0.0,87.249,81.457,4.1823565,1242.382336,0.032768,0.0,11.82,10.992,4.3219691,1466.4704,1.9968,0.0,8.546,7.609,4.0429375,1059.65568,0.0,0.0,12.802,10.809,4.0901283,1683.365888,0.0,0.0,13.024,11.85
1442559979000,5.9730501,1131.814912,1.622016,0.0,49.654,46.246,3.02026,1223.774208,0.0,0.0,5.35,5.166,2.3101401,1457.020928,1.29024,0.0,6.018,5.43,2.1158396,1055.809536,0.0,0.0,6.6,6.432,2.1027947,1659.748352,0.0,0.0,7.28,7.272
1442560040000,11.0601028,1139.269632,3.022848,0.0,76.156,71.067,5.0248872,1199.546368,0.0,0.0,11.574,10.305,3.5763943,1454.186496,2.250752,0.0,7.642,7.315,3.9427,1061.21216,0.0,0.0,9.472,9.019,4.5108078,1641.025536,0.0,0.0,11.454,10.782
1442560101000,8.5795136,1143.099392,2.633728,0.0,59.461,55.557,3.2983782,1202.720768,0.0,0.0,7.068,6.662,2.9351918,1452.859392,1.099776,0.0,8.104,6.669,3.5610941,1063.149568,0.0,0.0,8.652,7.967,3.3567811,1644.036096,0.0,0.0,8.596,7.335
1442560161000,9.8195745,1143.595008,4.468736,0.0,87.138,81.415,4.7462972,1205.731328,0.0,0.0,10.771,9.693,2.4563133,1459.15904,1.845248,0.0,9.46,8.821,7.537831,1072.082944,0.0,0.0,11.669,11.015,4.184183,1644.752896,0.0,0.0,12.557,9.731
1442560221000,11.0603025,1140.375552,2.736128,0.0,72.222,66.948,3.640536,1208.0128,0.0,0.0,9.084,8.363,4.2560769,1457.586176,1.826816,0.0,7.808,7.686,4.2446155,1074.720768,0.0,0.0,11.657,9.648,3.8651126,1643.6224,0.0,0.0,10.027,8.515
1442560281000,9.0279488,1146.61376,2.936832,0.0,68.445,63.67,3.7893931,1209.946112,0.0,0.0,7.566,7.242,2.9484484,1456.111616,1.019904,0.0,8.174,6.95,3.9198399,1066.561536,0.0,0.0,8.052,6.776,3.636133,1645.146112,0.0,0.0,11.691,9.624
1442560341000,7.9589133,1138.671616,2.801664,0.0,66.669,63.016,2.234289,1209.479168,0.0,0.0,9.086,7.905,2.3649678,1454.85824,1.130496,0.0,7.958,6.354,3.0859236,1067.122688,0.0,0.0,9.636,8.761,3.1146228,1643.97056,0.0,0.0,9.241,8.18
1442560401000,10.7432988,1143.574528,2.383872,0.0,83.876,78.073,4.2549479,1209.475072,0.0,0.0,10.392,8.46,4.0405434,1455.566848,1.970176,0.0,9.237,8.916,4.1008451,1068.670976,0.0,0.0,13.249,11.623,4.590236,1643.020288,0.0,0.0,12.414,10.541
1442560461000,9.4749022,1147.080704,2.486272,0.0,68.852,64.41,3.3621485,1213.784064,0.0,0.0,8.832,6.906,3.6278818,1457.54112,1.519616,0.0,8.988,8.462,6.1110879,1071.824896,0.004096,0.0,9.739,9.088,3.1518289,1643.52,0.0,0.0,9.455,8.308
1442560522000,6.8376057,1144.85248,3.60448,0.0,66.831,61.983,3.228405,1209.909248,0.0,0.0,7.325,6.962,3.5292297,1457.9712,1.296384,0.0,8.732,7.32,3.7332398,1072.96768,0.0,0.0,10.783,9.528,2.7918416,1635.45088,0.0,0.0,9.032,8.267
1442560582000,10.3937737,1141.73952,1.949696,0.0,66.896,62.335,3.5425516,1211.809792,0.0,0.0,9.889,8.277,3.2005298,1459.904512,1.236992,0.0,8.716,7.636,4.0053753,1075.13856,0.0,0.0,8.314,7.764,3.793501,1643.13088,0.0,0.0,10.061,8.822
1442560642000,11.1422546,1144.971264,1.765376,0.0,73.133,67.961,4.2319313,1195.618304,0.0,0.0,9.079,8.719,4.4435123,1447.596032,1.722368,0.0,10.296,8.348,4.0380763,1073.160192,0.0,0.0,11.294,9.294,3.2031717,1587.2,0.0,0.0,8.811,6.722
1442560702000,10.6005405,1137.463296,2.646016,0.0,58.619,55.41,3.7717106,1203.87584,0.0,0.0,7.637,6.249,2.6799279,1449.472,0.72704,0.0,5.037,4.119,4.4397363,1073.004544,0.0,0.0,9.222,7.954,4.980434,1586.405376,0.0,0.0,11.699,8.972
1442560762000,9.5500793,1142.652928,3.547136,0.0,79.139,74.317,3.6223132,1198.354432,0.0,0.0,10.504,8.841,3.5205301,1448.448,1.675264,0.0,10.111,8.337,3.9317197,1071.86176,0.0,0.0,11.838,9.864,3.5454377,1596.256256,0.0,0.0,11.946,10.241
1442560822000,9.0926396,1144.684544,3.006464,0.0,74.949,69.471,3.2376912,1204.154368,0.032768,0.0,8.974,7.497,3.2586231,1452.228608,2.441216,0.0,10.05,9.981,3.1628037,1076.711424,0.0,0.0,10.432,9.457,3.5499533,1592.877056,0.0,0.0,9.781,8.005
1442560882000,7.7176573,1151.410176,2.90816,0.0,64.89,60.616,2.7810097,1203.777536,0.032768,0.0,7.631,6.551,2.232356,1449.897984,1.15712,0.0,6.55,6.49,2.963919,1077.59616,0.0,0.0,10.66,8.805,2.8812642,1598.103552,0.0,0.0,10.317,8.848
1442560942000,11.0275393,1148.260352,2.342912,0.0,71.356,66.805,3.3527642,1207.058432,0.0,0.0,9.138,7.849,3.8075146,1449.136128,1.296384,0.0,8.188,6.964,4.0259138,1076.662272,0.0,0.0,9.275,7.726,4.2620734,1598.644224,0.0,0.0,12.253,10.556
1442561002000,11.532691,1151.85664,3.416064,0.0,73.727,68.954,4.3620464,1215.287296,0.0,0.0,9.123,8.22,4.2316912,1452.941312,1.439744,0.0,8.877,7.351,4.1396636,1075.89632,0.0,0.0,11.131,9.128,3.9801396,1598.640128,0.0,0.0,10.831,9.492
1442561063000,10.6585837,1154.8672,4.415488,0.0,70.499,66.952,3.5925161,1209.778176,0.0,0.0,9.71,8.622,3.2858882,1451.859968,1.470464,0.0,10.009,8.353,3.7402351,1077.3504,0.0,0.0,9.015,7.918,4.0801165,1598.152704,0.0,0.0,9.256,7.82
1442561123000,11.6722082,1156.071424,1.744896,0.0,78.519,73.488,3.3752732,1212.076032,0.032768,0.0,9.765,8.073,3.6672678,1455.239168,2.152448,0.0,9.11,8.453,5.0932252,1077.956608,0.0,0.0,13.169,11.637,3.5376253,1597.575168,0.0,0.0,10.301,9.101
1442561183000,9.6697585,1159.909376,3.268608,0.0,80.244,74.662,4.059472,1209.6512,0.0,0.0,11.547,9.218,3.3958495,1453.203456,1.150976,0.0,8.889,7.682,3.4504399,1086.148608,0.0,0.0,9.938,8.602,3.8268062,1595.31008,0.0,0.0,11.552,10.83
1442561243000,11.334049,1150.185472,3.375104,0.0,79.647,74.372,4.0647644,1193.181184,0.0,0.0,9.344,8.071,3.8129418,1416.216576,0.999424,0.0,9.372,7.767,4.6208057,1082.331136,0.0,0.0,11.754,11.426,4.0558998,1574.674432,0.0,0.0,10.443,9.55
1442561303000,11.5437323,1152.47104,3.436544,0.0,77.907,73.408,4.6384486,1193.771008,0.0,0.0,9.936,8.282,4.7898029,1414.770688,2.193408,0.0,10.337,9.202,4.308865,1084.698624,0.0,0.0,8.784,7.629,4.6522907,1577.648128,0.0,0.0,12.141,10.848
1442561363000,10.5063506,1152.237568,3.678208,0.0,81.722,75.848,4.1333893,1200.607232,0.0,0.0,11.965,10.84,4.060478,1415.548928,2.041856,0.0,9.094,8.257,4.1040452,1087.1808,0.0,0.0,10.261,8.644,3.5529441,1578.205184,0.0,0.0,12.338,9.859
1442561423000,11.5620011,1152.77824,2.793472,0.0,82.284,75.659,4.3691988,1196.924928,0.024576,0.0,11.556,9.719,3.9338726,1417.486336,1.796096,0.0,8.437,8.175,4.4216513,1090.883584,0.0,0.0,9.485,8.222,3.7379288,1582.63296,0.0,0.0,11.426,10.516
1442561484000,14.7963096,1156.366336,3.497984,0.0,102.001,95.477,4.5256234,1206.280192,0.0,0.0,12.293,10.025,5.3118297,1416.503296,1.738752,0.0,11.901,9.662,5.7724616,1088.57344,0.0,0.0,14.541,11.894,6.0242174,1582.731264,0.0,0.0,15.279,12.492
1442561545000,10.7050851,1159.000064,4.206592,0.0,88.069,83.491,3.5370463,1199.644672,0.032768,0.0,11.68,10.355,3.1479696,1410.822144,0.929792,0.018432,8.594,7.446,4.4497305,1091.923968,0.0,0.0,12.383,11.493,4.2493552,1578.442752,0.032768,0.0,14.336,11.744
1442561605000,12.6251554,1164.009472,1.88416,0.0,95.729,88.789,4.2168427,1208.000512,0.0,0.0,11.682,9.715,3.231093,1408.925696,2.121728,0.016384,10.613,10.041,5.2026474,1097.125888,0.0,0.0,13.421,12.225,5.2544,1573.392384,0.0,0.0,14.345,11.911
1442561666000,5.6235096,1157.091328,0.688128,0.0,24.934,23.336,2.2649682,1198.649344,0.0,0.0,5.811,5.531,5.4578686,1400.070144,0.843776,0.01024,4.286,3.889,1.9460108,1085.054976,0.0,0.0,6.156,5.162,1.1692574,1559.289856,0.0,0.0,2.271,2.463
1442561727000,6.0857953,1155.346432,2.117632,0.024576,70.177,65.222,2.2905985,1194.02496,0.0,0.0,10.491,8.546,2.3694985,1376.825344,1.325056,0.0,8.464,7.074,2.8297313,1060.573184,0.0,0.0,9.29,7.609,2.9193648,1533.984768,0.0,0.0,10.134,9.39
1442561787000,8.9282221,1154.760704,3.31776,0.032768,79.63,74.752,4.6963624,1199.218688,0.0,0.0,12.087,11.296,3.0440855,1379.377152,1.224704,0.0,7.397,5.92,3.1104317,1065.832448,0.0,0.0,12.268,9.681,3.8218812,1535.295488,0.0,0.0,10.825,8.798
1442561848000,11.9250846,1140.011008,2.351104,0.024576,79.63,74.524,5.1247041,1197.727744,0.012288,0.0,10.684,9.513,3.9591476,1369.100288,1.492992,0.0,8.341,7.591,4.2983901,1061.285888,0.0,0.0,10.331,9.208,4.7031636,1506.680832,0.032768,0.0,11.895,10.251
1442561908000,13.4312253,1146.22464,3.211264,0.032768,92.968,87.079,4.9584256,1197.2608,0.0,0.0,10.779,9.64,4.8879711,1366.900736,1.712128,0.0,12.099,11.299,4.7713951,1067.556864,0.0,0.0,11.524,10.108,4.843559,1505.333248,0.0,0.0,13.401,11.979
1442561968000,13.754533,1150.144512,3.207168,0.028672,83.053,78.655,4.9501959,1200.308224,0.0,0.0,11.737,10.865,4.6438352,1371.36128,1.716224,0.0,9.801,8.865,4.5002784,1065.51296,0.0,0.0,11.173,9.002,4.9177769,1505.591296,0.0,0.0,12.515,10.376
1442562028000,12.2982563,1147.92448,3.055616,0.036864,70.671,66.192,5.8629435,1195.17184,0.0,0.0,10.952,9.724,4.056746,1368.952832,0.919552,0.0,6.915,6.074,4.5071042,1063.227392,0.0,0.0,9.011,9.051,4.6278752,1504.325632,0.0,0.0,9.771,8.31
1442562088000,12.3081435,1148.768256,2.301952,0.016384,81.872,77.33,4.3001511,1194.921984,0.0,0.0,11.279,9.483,4.5791741,1365.958656,1.849344,0.0,11.556,9.612,4.6810044,1065.095168,0.0,0.0,9.584,7.947,4.515718,1510.395904,0.032768,0.0,12.97,11.099
1442562148000,12.8940308,1148.801024,4.25984,0.032768,82.101,77.309,5.5908731,1192.22272,0.004096,0.0,9.988,8.238,4.4919211,1371.971584,1.787904,0.0,10.975,9.194,5.0706032,1065.119744,0.0,0.0,13.088,10.554,4.9162917,1506.553856,0.0,0.0,10.592,8.079
1442562208000,4.1139925,1149.566976,0.544768,0.02048,21.915,20.508,34.4763145,1226.715136,6.352896,0.0,2.914,2.803,1.4873101,1376.219136,0.233472,0.0,2.968,2.488,1.3482895,1063.882752,0.0,0.0,3.182,2.876,1.8952568,1507.598336,0.0,0.0,4.7,4.191
1442562268000,12.5945083,1146.5728,4.169728,0.032768,79.464,74.648,4.8881832,1229.017088,0.0,0.0,10.339,9.019,4.7445149,1367.6544,1.47456,0.0,10.34,9.233,5.2053237,1062.064128,0.0,0.0,11.343,8.852,4.8456301,1499.127808,0.0,0.0,11.073,9.306
1442562328000,11.6149267,1145.040896,1.642496,0.024576,68.201,64.232,4.5263898,1231.753216,0.0,0.0,8.752,7.926,3.9062102,1369.194496,1.480704,0.0,9.654,8.121,3.9320532,1063.591936,0.0,0.0,10.016,8.583,3.8780697,1498.730496,0.0,0.0,9.828,9.274
1442562388000,12.4744848,1146.187776,2.363392,0.016384,59.434,55.369,5.8677172,1234.477056,0.0,0.0,7.181,6.387,3.8761397,1370.574848,1.431552,0.0,6.291,5.995,4.5784451,1064.259584,0.0,0.0,8.745,7.703,4.634566,1496.178688,0.0,0.0,8.313,8.008
1442562448000,11.9676019,1134.96064,1.949696,0.008192,65.923,61.161,5.156039,1223.675904,0.0,0.0,9.477,8.935,3.9574964,1362.464768,0.847872,0.0,7.348,6.384,5.3658207,1058.46784,0.0,0.0,11.657,10.16,3.6354832,1481.33888,0.0,0.0,6.849,6.717
1442562508000,8.7525699,1131.13088,1.675264,0.024576,60.597,56.487,3.267167,1225.408512,0.0,0.0,6.096,5.797,3.0255466,1358.942208,0.903168,0.0,7.009,6.261,3.349703,1057.52576,0.0,0.0,8.633,7.946,3.1770162,1479.970816,0.0,0.0,10.582,9.781
1442562568000,11.8673286,1132.601344,3.289088,0.008192,79.878,75.226,4.6538467,1227.022336,0.0,0.0,10.406,8.891,3.7104427,1358.307328,0.796672,0.0,9.086,7.051,4.2464003,1062.74816,0.0,0.0,10.233,9.204,5.0096322,1479.274496,0.0,0.0,12.705,11.027
1442562628000,9.6747176,1138.19648,3.657728,0.008192,59.005,55.701,4.2755271,1231.290368,0.0,0.0,7.267,6.634,3.622834,1363.53792,1.632256,0.0,8.036,7.561,3.2341174,1063.424,0.0,0.0,6.863,5.975,3.8974486,1481.998336,0.0,0.0,9.07,8.204
1442562688000,12.1134201,1134.22336,2.371584,0.024576,88.715,83.08,4.1384726,1229.955072,0.0,0.0,11.668,10.038,3.5146276,1363.202048,0.751616,0.0,10.486,8.207,3.6320843,1060.048896,0.0,0.0,12.181,10.297,4.1400768,1479.704576,0.0,0.0,13.882,11.486
1442562748000,11.91339,1134.850048,1.527808,0.012288,67.968,63.192,4.4167075,1231.704064,0.0,0.0,9.222,8.244,3.9393745,1365.4016,0.882688,0.0,7.869,6.97,3.0559052,1065.75872,0.0,0.0,8.571,7.496,5.1084088,1480.572928,0.0,0.0,10.971,9.313
1442562808000,12.32971,1139.634176,2.53952,0.016384,79.007,74.533,5.7530463,1234.235392,0.0,0.0,11.981,10.004,4.0275123,1367.085056,1.08544,0.0,7.6,6.871,4.8237272,1067.982848,0.0,0.0,10.729,9.278,4.1754094,1485.758464,0.0,0.0,13.073,11.272
1442562868000,12.2501445,1136.754688,2.68288,0.016384,71.391,66.759,5.6080098,1231.970304,0.0,0.0,11.505,9.838,4.4184166,1367.605248,1.245184,0.0,9.671,8.451,4.1967729,1062.87104,0.0,0.0,8.04,6.638,4.2401844,1482.211328,0.0,0.0,10.42,10.43
1442562928000,11.8649325,1139.38432,2.490368,0.016384,76.91,72.462,4.3635969,1233.313792,0.0,0.0,9.622,7.756,4.188055,1367.539712,1.507328,0.0,9.923,8.098,3.4380995,1060.6592,0.0,0.0,9.985,8.151,4.6956233,1480.37632,0.0,0.0,12.864,10.783
1442562988000,13.6156618,1141.628928,2.58048,0.02048,76.025,70.562,5.3444104,1234.161664,0.0,0.0,8.733,8.241,4.728512,1368.756224,0.886784,0.0,10.427,8.569,4.9694421,1062.244352,0.0,0.0,9.969,9.588,5.1947758,1484.951552,0.0,0.0,10.543,9.98
1442563048000,11.4258817,1135.087616,2.781184,0.024576,79.222,74.224,5.2817079,1202.343936,0.0,0.0,12.068,10.089,3.4096204,1360.236544,1.886208,0.0,9.6,8.652,3.9516877,1055.76448,0.0,0.0,9.767,8.351,3.7060018,1461.547008,0.0,0.0,11.415,9.767
1442563109000,11.666652,1134.866432,1.773568,0.0,91.173,85.177,4.3359658,1202.106368,0.0,0.0,10.281,9.713,3.3863225,1359.273984,1.875968,0.0,10.338,9.455,4.3558314,1060.573184,0.0,0.0,12.981,12.016,4.2805859,1461.18656,0.0,0.0,15.954,12.492
1442563169000,12.4159143,1136.472064,2.400256,0.0,78.018,72.903,4.3610979,1202.835456,0.0,0.0,11.405,9.871,4.3016341,1364.279296,1.98656,0.0,9.212,8.746,4.5269043,1068.17536,0.0,0.0,8.029,7.591,3.8539167,1462.165504,0.0,0.0,12.164,10.674
1442563229000,12.5057357,1141.239808,2.51904,0.0,91.695,85.787,4.2789947,1205.448704,0.0,0.0,10.066,8.959,4.7261557,1362.415616,1.824768,0.0,10.849,9.826,5.2076458,1067.593728,0.0,0.0,13.987,12.37,5.2779624,1460.535296,0.0,0.0,13.222,10.859
1442563289000,11.9590036,1139.511296,2.994176,0.0,90.01,83.958,3.6664785,1205.563392,0.0,0.0,8.637,8.008,4.277103,1365.85216,2.060288,0.0,10.949,9.753,4.5473793,1074.74944,0.0,0.0,12.544,11.881,4.7551802,1466.241024,0.0,0.0,15.669,13.243
1442563349000,11.0025726,1141.919744,3.620864,0.0,79.66,74.556,3.9899231,1206.960128,0.0,0.0,9.175,8.065,3.9486611,1357.312,1.507328,0.0,11.168,10.285,4.6422476,1072.345088,0.0,0.0,11.601,10.383,4.8130557,1463.05024,0.0,0.0,10.242,9.493
1442563409000,9.5844002,1140.342784,3.919872,0.0,70.114,66.241,3.1536431,1208.13568,0.0,0.0,10.024,8.198,8.9075224,1353.777152,2.228224,0.0,6.216,5.562,3.7205803,1075.34336,0.0,0.0,10.3,9.281,4.2716211,1465.409536,0.0,0.0,11.662,10.551
1442563469000,11.8253091,1135.869952,3.670016,0.0,90.283,84.118,4.6681146,1209.05728,0.0,0.0,12.837,10.852,3.8875413,1354.088448,1.568768,0.0,9.454,8.703,4.7880288,1071.869952,0.0,0.0,11.502,10.316,4.3744558,1462.304768,0.0,0.0,13.726,11.423
1442563530000,11.3914155,1133.494272,0.94208,0.0,80.996,76.394,4.4232572,1211.887616,0.0,0.0,12.67,11.484,3.7165701,1355.259904,1.449984,0.0,9.127,7.477,3.9122543,1074.343936,0.0,0.0,11.131,10.444,3.7765648,1461.526528,0.0,0.0,10.684,8.972
1442563590000,11.4739304,1134.42816,2.797568,0.0,88.356,83.159,4.5290929,1207.820288,0.0,0.0,9.972,8.764,4.2917127,1355.145216,1.564672,0.0,10.04,9.062,3.7294508,1077.583872,0.0,0.0,14.981,12.711,3.8668402,1457.93024,0.0,0.0,13.095,11.235
1442563650000,10.6585804,1133.83424,2.531328,0.0,78.234,73.394,3.9762414,1194.835968,0.0,0.0,10.768,9.453,3.239222,1347.97312,1.552384,0.0,8.656,8.184,3.8431873,1067.3152,0.0,0.0,10.745,9.934,3.7324771,1442.67264,0.0,0.0,11.651,10.191
1442563710000,11.5579023,1141.18656,2.818048,0.0,72.948,68.107,4.323931,1193.803776,0.0,0.0,8.246,7.666,4.2252737,1350.668288,1.734656,0.0,7.924,7.596,4.2948692,1067.155456,0.0,0.0,10.345,9.534,4.406043,1436.729344,0.0,0.0,11.793,10.875
1442563770000,13.0275465,1142.00576,2.998272,0.0,104.386,97.011,5.1333744,1191.047168,0.0,0.0,11.906,11.115,4.8239558,1352.474624,1.9456,0.0,12.018,10.457,4.3360003,1070.723072,0.0,0.0,14.46,12.563,4.9807049,1436.848128,0.0,0.0,14.673,13.016
1442563830000,12.3543362,1142.673408,3.600384,0.0,81.005,75.395,4.3890859,1190.887424,0.0,0.0,8.583,8.748,4.5141392,1353.87136,1.574912,0.0,9.447,8.746,4.61424,1068.654592,0.0,0.0,11.575,9.654,4.5280651,1434.099712,0.0,0.0,12.083,10.905
1442563890000,10.1046907,1146.753024,3.559424,0.0,66.205,62.647,2.9137483,1194.016768,0.049152,0.0,8.281,7.859,3.7024152,1349.832704,1.712128,0.0,9.809,8.644,3.5505336,1068.957696,0.0,0.0,9.109,7.611,3.4793506,1432.580096,0.0,0.0,9.463,7.98
1442563950000,10.7306684,1146.703872,2.658304,0.0,94.097,87.113,4.2681112,1199.202304,0.0,0.0,12.593,10.483,3.808508,1352.859648,1.128448,0.0,9.521,8.186,3.8876185,1085.997056,0.0,0.0,14.96,12.732,3.6937812,1418.514432,0.0,0.0,12.696,11.505
1442564010000,11.2084458,1145.311232,2.871296,0.0,87.83,82.64,4.3255799,1198.57152,0.0,0.0,12.265,11.381,4.1540981,1353.691136,1.25952,0.0,10.547,9.459,4.4857358,1082.126336,0.0,0.0,11.412,9.723,4.3361883,1420.337152,0.0,0.0,12.476,11.26
1442564070000,11.2070799,1141.276672,3.05152,0.0,93.059,86.406,3.7348265,1209.778176,0.0,0.0,10.512,8.431,4.0719786,1358.651392,1.400832,0.0,11.898,9.612,4.4180635,1081.356288,0.0,0.0,13.131,10.9,4.1703809,1412.009984,0.0,0.0,13.913,11.396
1442564130000,10.6793208,1141.932032,2.719744,0.0,81.408,76.437,3.7873273,1213.177856,0.0,0.0,11.854,9.955,3.4491108,1357.058048,1.685504,0.0,9.165,7.433,4.2171076,1084.207104,0.0,0.0,14.28,12.422,4.3547155,1418.58816,0.0,0.0,9.772,8.684
1442564190000,11.7856112,1145.483264,3.104768,0.0,101.047,93.402,4.6947989,1209.634816,0.0,0.0,13.73,12.194,4.3501255,1354.989568,0.980992,0.0,12.85,9.741,4.1634535,1081.131008,0.0,0.0,12.543,9.985,4.7877608,1406.68928,0.0,0.0,13.86,12.352
1442564251000,7.2798552,1137.90976,2.322432,0.0,62.088,57.869,6.2514558,1200.746496,0.0,0.0,7.213,6.543,2.8231556,1343.488,1.394688,0.0,7.01,6.831,2.3977363,1071.583232,0.0,0.0,9.201,8.567,2.6729373,1374.900224,0.0,0.0,9.448,8.854
1442564312000,11.1391914,1150.857216,1.712128,0.0,81.328,76.712,3.9485281,1203.810304,0.032768,0.0,12.452,10.833,4.3963382,1349.255168,1.951744,0.0,10.23,8.779,3.6646398,1076.854784,0.0,0.0,12.695,10.501,3.8151852,1336.250368,0.0,0.0,9.898,7.692
1442564373000,14.1081811,1151.537152,2.084864,0.0,93.624,86.258,4.9734748,1202.225152,0.0,0.0,12.38,10.887,3.9061854,1350.709248,1.72032,0.0,8.96,8.059,5.0178278,1077.383168,0.0,0.0,12.52,10.339,5.2095141,1334.001664,0.0,0.0,13.98,12.153
1442564433000,12.4788296,1154.433024,2.121728,0.0,76.507,71.343,4.8108764,1209.909248,0.0,0.0,9.817,8.833,3.6451583,1353.723904,1.120256,0.0,8.33,7.473,4.2114694,1081.110528,0.0,0.0,11.862,10.194,4.9793131,1323.712512,0.0,0.0,11.139,9.722
1442564493000,12.8337788,1151.705088,3.313664,0.0,86.531,81.443,5.0627156,1207.230464,0.0,0.0,11.756,10.047,3.977454,1349.132288,1.3824,0.0,9.101,7.671,4.8594912,1081.2416,0.0,0.0,12.541,10.939,5.1721045,1324.507136,0.0,0.0,12.922,11.109
1442564553000,11.8393869,1155.371008,2.797568,0.0,78.513,72.703,4.1443087,1210.048512,0.0,0.0,9.001,7.937,4.3374019,1351.688192,1.142784,0.0,7.9,6.922,4.0716488,1082.69568,0.0,0.0,11.33,9.216,3.9845092,1324.032,0.0,0.0,12.254,10.509
1442564613000,13.076671,1158.660096,3.158016,0.0,79.634,74.719,4.8681997,1212.178432,0.0,0.0,9.72,9.036,4.3699149,1352.577024,1.132544,0.0,8.432,6.991,4.9690524,1091.633152,0.0,0.0,11.741,10.065,5.5142203,1327.136768,0.0,0.0,11.583,10.172
1442564673000,10.5308344,1160.916992,4.870144,0.0,65.842,62.143,3.3975602,1219.076096,0.0,0.0,7.726,7.165,3.4815567,1355.083776,1.470464,0.0,7.277,6.929,3.5343434,1095.221248,0.0,0.0,10.502,8.479,4.2590057,1320.861696,0.0,0.0,9.159,7.577
1442564733000,14.3220652,1154.650112,2.154496,0.0,99.568,93.157,5.8381585,1217.37216,0.0,0.0,13.789,11.369,4.7614723,1352.224768,1.554432,0.0,12.329,10.281,5.3080326,1092.521984,0.0,0.0,13.141,10.526,5.6574753,1326.096384,0.720896,0.0,15.605,13.615
1442564793000,10.7248749,1155.862528,2.097152,0.0,63.224,59.335,4.5538294,1216.380928,0.0,0.0,9.054,7.169,3.4449205,1355.231232,1.497088,0.0,6.457,5.803,3.2567801,1091.526656,0.0,0.0,7.787,7.318,4.1185657,1318.498304,0.0,0.0,9.72,8.34
1442564854000,10.6762578,1152.929792,3.108864,0.0,76.999,71.613,4.0770842,1216.08192,0.0,0.0,10.716,9.582,3.8705353,1361.44896,1.472512,0.0,7.758,7.267,3.7616275,1093.566464,0.0,0.0,9.367,8.345,3.7208886,1301.364736,0.0,0.0,11.944,9.878
1442564914000,12.4909819,1157.44768,1.765376,0.0,98.619,92.405,5.053905,1214.271488,0.0,0.0,13.951,12.628,4.3183351,1352.876032,1.447936,0.0,10.305,9.069,4.2290528,1090.367488,0.0,0.0,13.088,12.324,4.602711,1261.682688,0.0,0.0,13.815,12.403
1442564974000,10.4153455,1155.289088,3.375104,0.0,84.465,80.031,3.1032087,1213.591552,0.0,0.0,10.862,8.469,3.9401602,1357.06624,1.067008,0.0,11.808,9.597,3.6638736,1092.579328,0.0,0.0,11.607,10.705,4.136773,1258.860544,0.032768,0.0,12.277,11.145
1442565034000,11.7470076,1156.108288,3.887104,0.0,83.747,78.693,4.4417829,1216.995328,0.0,0.0,10.259,8.732,4.3739463,1355.886592,1.36192,0.0,8.921,8.134,4.404399,1093.046272,0.0,0.0,10.85,9.982,4.8927441,1259.388928,0.0,0.0,13.938,12.891
1442565094000,12.8625184,1157.947392,2.244608,0.0,90.271,84.908,4.8848501,1224.175616,0.0,0.0,11.947,11.733,3.9913549,1356.55424,1.026048,0.0,9.476,7.759,5.3826938,1097.965568,0.0,0.0,13.863,12.44,5.425808,1260.916736,0.0,0.0,14.048,12.939
1442565155000,11.948034,1161.875456,3.981312,0.0,88.89,82.977,5.7218924,1221.431296,0.0,0.0,10.321,8.737,4.4362159,1359.314944,1.900544,0.016384,11.373,10.341,4.2526678,1094.668288,0.0,0.0,11.474,9.235,4.8602535,1262.362624,0.0,0.0,13.07,11.305
1442565216000,12.0760767,1162.715136,2.62144,0.0,66.179,62.242,3.8946679,1223.081984,0.0,0.0,8.893,8.003,4.4615878,1355.927552,1.349632,0.006144,8.645,8.201,4.255531,1100.910592,0.0,0.0,9.234,8.729,4.1765018,1262.559232,0.0,0.0,9.235,7.539
1442565276000,10.8958276,1160.310784,2.277376,0.0,89.909,82.924,4.6062572,1226.539008,0.0,0.0,14.574,11.882,4.64857,1355.689984,1.181696,0.016384,10.727,8.786,4.171696,1105.637376,0.0,0.0,12.614,9.983,3.9176716,1259.839488,0.0,0.0,10.227,9.312
1442565336000,11.5042167,1160.183808,2.285568,0.0,86.321,80.523,4.9190394,1228.894208,0.0,0.0,12.81,9.777,3.8049581,1354.1376,1.581056,0.016384,8.875,7.899,4.3317642,1107.177472,0.0,0.0,11.373,10.611,4.737837,1261.780992,0.0,0.0,13.574,11.132
1442565396000,10.6575773,1163.014144,1.511424,0.0,80.608,74.62,3.3180538,1227.07968,0.0,0.0,7.256,6.962,4.4778038,1350.438912,2.244608,0.01024,11.732,11.287,4.4573182,1097.822208,0.0,0.0,12.78,10.833,3.3416513,1259.29472,0.0,0.0,10.537,9.189
1442565456000,10.4845011,1165.25056,2.629632,0.0,83.792,77.929,4.139457,1228.816384,0.0,0.0,11.259,9.175,4.8909966,1350.762496,1.409024,0.016384,7.857,7.13,3.7249084,1102.954496,0.0,0.0,12.458,11.051,3.2436865,1260.044288,0.0,0.0,11.081,10.444
1442565516000,11.807391,1157.464064,3.129344,0.0,84.911,79.711,3.9783584,1213.513728,0.0,0.0,11.214,9.688,4.5623928,1354.657792,1.619968,0.014336,11.466,9.796,4.654059,1089.961984,0.0,0.0,11.038,9.364,4.531184,1246.273536,0.0,0.0,12.385,9.523
1442565576000,10.8900204,1162.268672,2.27328,0.0,82.378,77.082,3.9278441,1215.85664,0.0,0.0,10.097,9.56,4.4490771,1355.767808,0.995328,0.014336,10.648,9.197,3.7801664,1093.623808,0.0,0.0,11.03,9.288,4.1722619,1246.994432,0.032768,0.0,10.918,8.952
1442565637000,10.3873452,1162.104832,2.564096,0.0,103.438,96.626,4.5079003,1218.158592,0.0,0.0,13.337,11.653,4.5419362,1354.514432,1.974272,0.0,13.706,11.315,4.3569711,1097.138176,0.0,0.0,12.296,10.258,4.4347917,1243.89376,0.0,0.0,16.92,13.52
1442565697000,9.6838037,1161.637888,3.162112,0.028672,75.904,70.419,3.5595918,1222.066176,0.0,0.0,8.725,7.901,2.8140062,1356.013568,1.200128,0.0,8.1,6.93,3.9008006,1095.540736,0.0,0.0,9.173,8.485,3.9774234,1248.346112,0.0,0.0,13.261,11.244
1442565757000,11.5422809,1162.81344,2.756608,0.0,90.962,84.698,3.9133091,1221.865472,0.0,0.0,10.537,9.206,4.0839082,1348.558848,1.736704,0.0,12.365,10.413,3.4506662,1098.498048,0.0,0.0,12.265,10.399,4.3305499,1241.43616,0.0,0.0,13.2,11.701
1442565817000,13.1002287,1164.693504,2.527232,0.0,94.45,87.989,5.1708638,1225.617408,0.0,0.0,12.294,11.345,4.9314715,1351.507968,1.325056,0.0,12.172,9.648,4.3905397,1097.17504,0.0,0.0,12.406,10.001,4.805961,1243.17696,0.0,0.0,12.927,12.205
1442565877000,10.8990777,1164.767232,3.801088,0.0,88.534,83.404,5.1067827,1223.569408,0.0,0.0,12.282,10.742,3.5235368,1349.398528,0.751616,0.0,9.345,7.021,4.1726582,1100.214272,0.0,0.0,13.831,11.544,4.2138293,1242.251264,0.0,0.0,11.514,10.236
1442565937000,10.9158116,1164.648448,3.670016,0.0,87.577,82.802,4.8277938,1228.619776,0.0,0.0,11.648,9.643,4.1071269,1346.420736,1.296384,0.0,10.073,8.633,4.2779165,1100.435456,0.0,0.0,12.771,10.769,4.7951948,1241.915392,0.0,0.0,13.468,11.517
1442565997000,12.3495139,1164.43136,1.503232,0.0,87.886,80.992,4.1339883,1226.878976,0.0,0.0,8.972,9.34,3.4884301,1342.111744,0.843776,0.0,9.76,8.284,4.3848054,1103.802368,0.0,0.0,12.672,11.332,5.1858323,1240.33024,0.0,0.0,13.803,11.856
1442566058000,11.7764933,1171.120128,3.321856,0.02048,91.804,85.963,4.9249719,1229.991936,0.0,0.0,12.065,10.333,4.0492107,1345.45408,1.622016,0.0,12.272,9.659,3.9876457,1100.685312,0.0,0.0,11.047,9.019,4.3332131,1244.844032,0.0,0.0,13.414,11.385
1442566118000,12.963905,1164.67712,2.625536,0.016384,106.647,101.049,4.8715752,1208.205312,0.0,0.0,11.71,9.385,4.7941714,1338.417152,1.480704,0.0,13.523,10.603,4.4430415,1091.751936,0.0,0.0,13.058,10.4,5.1624422,1227.190272,0.0,0.0,19.721,16.02
1442566179000,14.089406,1165.602816,4.235264,0.036864,99.835,93.908,5.3506379,1208.807424,0.0,0.0,12.4,10.725,5.1142314,1340.829696,1.6384,0.0,11.58,9.962,5.2910612,1096.585216,0.0,0.0,12.942,11.19,5.339865,1226.305536,0.0,0.0,14.991,13.16
1442566239000,15.1202983,1170.829312,1.990656,0.012288,95.516,89.685,6.3255206,1209.09824,0.0,0.0,13.147,11.457,3.8492799,1339.322368,1.181696,0.0,12.144,9.88,5.5396267,1092.677632,0.0,0.0,11.499,9.707,5.9627451,1230.761984,0.0,0.0,14.268,11.741
1442566299000,12.9801616,1170.071552,3.03104,0.024576,96.473,90.018,5.7008173,1214.64832,0.0,0.0,12.25,10.861,4.1684643,1341.714432,1.400832,0.0,9.276,7.834,5.1544377,1099.0592,0.0,0.0,13.815,11.594,5.589176,1227.808768,0.0,0.0,16.033,12.958
1442566359000,13.0984133,1171.78368,2.445312,0.032768,89.749,83.186,5.0666257,1213.7472,0.0,0.0,11.908,11.109,4.2484054,1341.427712,1.400832,0.0,8.999,7.941,4.1769007,1096.462336,0.0,0.0,12.953,11.393,5.9953362,1228.877824,0.0,0.0,12.064,10.467
1442566419000,12.1136161,1176.236032,4.608,0.036864,94.104,89.243,4.6561945,1215.561728,0.0,0.0,11.92,10.126,4.2122474,1342.316544,1.359872,0.0,11.098,9.598,4.5002996,1093.738496,0.0,0.0,12.215,9.126,4.2231377,1227.722752,0.0,0.0,14.277,11.961
1442566479000,14.7527137,1172.832256,3.342336,0.004096,97.607,91.546,5.7233162,1218.953216,0.0,0.0,13.093,11.522,4.8981698,1344.282624,1.693696,0.0,9.915,9.258,5.0957615,1106.49344,0.0,0.0,13.485,11.88,5.5770806,1231.745024,0.0,0.0,14.129,12.469
1442566539000,12.1231934,1170.132992,3.97312,0.016384,90.816,85.162,4.8470645,1219.883008,0.0,0.0,12.978,10.58,3.9712714,1339.011072,1.282048,0.0,9.902,8.8,5.0032659,1102.811136,0.0,0.0,12.049,11.13,4.8442132,1229.6192,0.0,0.0,12.097,11.407
1442566599000,12.6784034,1175.36768,2.347008,0.004096,68.993,64.892,4.6262512,1221.193728,0.0,0.0,9.474,8.726,4.6621839,1340.7232,1.435648,0.0,8.655,7.49,5.1547838,1108.176896,0.0,0.0,10.011,8.773,4.4976785,1225.129984,0.0,0.0,9.219,7.443
1442566659000,13.3962208,1173.921792,3.219456,0.028672,93.305,87.132,4.916503,1216.602112,0.0,0.0,10.656,10.227,4.5420142,1339.813888,1.189888,0.0,10.745,9.664,5.7280049,1099.61216,0.0,0.0,14.843,13.506,4.6472882,1224.757248,0.0,0.0,12.568,10.736
1442566719000,13.5779354,1175.027712,2.49856,0.032768,97.637,91.451,6.2145332,1197.40416,0.0,0.0,16.758,13.962,4.9819553,1332.576256,0.93184,0.0,9.22,6.896,5.5577866,1094.598656,0.0,0.0,11.622,10.831,5.4550136,1212.02688,0.0,0.0,15.124,13.476
1442566779000,13.6231258,1174.355968,2.605056,0.012288,88.501,82.458,4.5443786,1197.03552,0.0,0.0,9.792,8.913,4.220127,1336.172544,1.80224,0.0,11.762,10.558,5.0331553,1096.146944,0.0,0.0,11.237,9.452,5.0824617,1219.371008,0.032768,0.0,13.343,12.231
1442566839000,13.0482698,1173.057536,2.146304,0.024576,94.423,87.988,5.3601072,1202.814976,0.0,0.0,13.167,11.495,4.3121174,1335.083008,1.15712,0.0,10.293,8.758,4.8670292,1097.129984,0.0,0.0,13.492,11.264,4.8833603,1216.991232,0.004096,0.0,13.657,11.772
1442566899000,7.6447187,1181.024256,1.376256,0.028672,48.644,45.495,99.9690828,1206.894592,0.098304,0.0,6.116,5.343,2.7234148,1337.892864,1.286144,0.0,5.4,5.906,3.046653,1094.08256,0.0,0.0,7.716,6.679,2.8002879,1215.401984,0.0,0.0,7.756,7.36
1442566960000,9.1105603,1178.03008,3.035136,0.0,89.562,84.629,3.7468481,1206.263808,0.0,0.0,11.932,10.865,2.9757401,1335.386112,1.470464,0.0,9.716,8.146,96.2544825,1100.886016,0.02048,0.0,12.661,10.907,3.638465,1210.740736,0.0,0.0,12.713,10.857
1442567020000,12.6354114,1176.252416,2.080768,0.0,86.928,81.038,4.1032849,1202.569216,0.0,0.0,10.141,9.706,4.3626595,1336.64768,1.179648,0.0,8.678,7.857,5.0314912,1093.677056,0.0,0.0,12.796,11.041,5.1718201,1214.681088,0.032768,0.0,14.044,12.789
1442567080000,12.0690101,1175.94112,2.53952,0.0,90.279,84.335,5.3296844,1204.514816,0.0,0.0,12.843,11.079,4.6475606,1341.587456,1.527808,0.0,10.682,9.674,4.1240945,1095.602176,0.0,0.0,11.152,9.553,4.94925,1210.966016,0.0,0.0,13.475,12.002
1442567140000,14.5539521,1175.248896,2.801664,0.0,121.211,112.644,5.8824776,1204.146176,0.0,0.0,15.626,13.808,4.3269325,1339.00288,1.765376,0.0,12.469,10.819,5.6426727,1102.880768,0.0,0.0,17.059,14.277,5.6113337,1211.449344,0.0,0.0,18.219,16.268
1442567200000,12.1213251,1172.107264,3.01056,0.0,85.065,79.303,4.7587189,1209.708544,0.0,0.0,11.151,10.675,3.4849848,1332.973568,1.042432,0.0,8.353,7.296,5.1081236,1103.458304,0.0,0.0,12.715,9.703,4.2967074,1209.782272,0.004096,0.0,12.735,10.932
1442567260000,11.8411143,1181.380608,2.58048,0.0,94.898,87.678,4.209756,1206.784,0.0,0.0,12.911,11.786,4.3362581,1332.89984,1.57696,0.0,9.693,8.571,4.9818767,1102.794752,0.0,0.0,12.656,10.49,4.890705,1208.672256,0.0,0.0,13.546,11.965
1442567320000,10.8493109,1175.642112,1.937408,0.0,81.601,76.026,4.0142133,1188.810752,0.0,0.0,12.048,10.488,2.6539122,1326.198784,1.839104,0.0,8.711,8.023,3.6765296,1097.728,0.0,0.0,11.4,10.348,4.090895,1207.431168,0.0,0.0,11.884,10.63
1442567380000,12.4654679,1177.305088,3.289088,0.0,94.753,88.621,5.2494035,1192.681472,0.0,0.0,12.481,11.747,4.8909392,1325.34272,1.59744,0.0,12.053,10.095,4.2764036,1101.47584,0.0,0.0,12.709,10.793,4.5357087,1209.93792,0.0,0.0,12.737,11.036
1442567441000,10.0848192,1181.392896,4.120576,0.0,73.131,69.126,3.5336528,1192.22272,0.0,0.0,9.615,8.65,3.2747091,1327.669248,0.806912,0.0,7.336,6.232,3.7297041,1097.396224,0.0,0.0,12.453,9.566,3.6551253,1209.503744,0.0,0.0,9.998,8.747
1442567501000,12.3782872,1180.135424,2.760704,0.0,84.36,79.433,5.3278135,1196.630016,0.0,0.0,11.792,9.812,4.0602303,1326.36672,1.417216,0.0,9.762,8.278,4.3210941,1097.125888,0.0,0.0,10.748,9.477,4.138822,1211.846656,0.0,0.0,14.147,11.73
1442567561000,11.4147413,1177.817088,1.757184,0.0,73.864,68.975,4.5537879,1196.552192,0.0,0.0,9.949,9.067,4.7137769,1329.557504,2.326528,0.0,11.599,10.832,4.6396635,1097.904128,0.0,0.0,10.13,8.04,4.4906678,1209.479168,0.032768,0.0,9.583,9.215
1442567621000,13.6241787,1183.6416,2.490368,0.0,97.861,90.871,4.2801764,1196.572672,0.0,0.0,10.546,9.134,3.9249746,1328.242688,1.50528,0.0,8.944,7.994,5.6915525,1096.58112,0.0,0.0,14.344,13.059,4.8528731,1207.320576,0.0,0.0,15.354,14.204
1442567681000,14.0168265,1183.248384,2.854912,0.0,97.251,91.391,5.6995655,1196.376064,0.0,0.0,11.615,10.052,5.41856,1333.53472,1.820672,0.0,14.481,11.861,5.703624,1100.066816,0.0,0.0,13.878,11.674,5.3784689,1208.680448,0.0,0.0,13.704,11.783
1442567741000,9.575665,1186.34496,3.293184,0.0,79.564,74.844,3.1812672,1195.339776,0.0,0.0,8.968,7.72,4.6418299,1331.69152,1.343488,0.0,9.389,8.014,3.329921,1104.19968,0.0,0.0,11.942,9.85,3.4976525,1205.796864,0.0,0.0,12.724,10.245
1442567801000,12.9239243,1184.432128,2.84672,0.0,95.307,88.551,5.1716342,1204.420608,0.0,0.0,10.6,9.759,5.4550459,1331.109888,1.738752,0.0,10.567,9.005,5.5195252,1114.673152,0.0,0.0,14.827,12.803,5.1046129,1206.575104,0.0,0.0,13.438,11.566
1442567861000,9.9322754,1178.021888,2.486272,0.0,83.528,78.099,3.1178022,1204.57216,0.0,0.0,9.461,9.437,3.6349986,1329.0496,1.779712,0.0,10.355,9.542,3.6555061,1112.358912,0.0,0.0,11.894,9.6,3.7487069,1205.796864,0.0,0.0,10.778,10.178
1442567921000,8.018602,1177.23136,2.809856,0.0,67.432,63.893,2.8610669,1183.666176,0.0,0.0,9.93,8.82,2.8610665,1323.040768,0.833536,0.0,7.264,6.656,2.6006334,1105.05984,0.0,0.0,9.71,8.651,3.1128864,1204.031488,0.0,0.0,10.029,8.466
1442567981000,11.5813477,1180.704768,3.477504,0.0,91.767,85.971,3.6177567,1182.318592,0.0,0.0,10.55,8.587,3.1988746,1323.065344,1.03424,0.0,11.448,8.465,4.5520976,1104.359424,0.0,0.0,12.088,10.499,5.258266,1203.867648,0.0,0.0,14.761,11.629
1442568041000,13.0280501,1178.89024,3.424256,0.0,99.773,93.909,5.1593828,1183.625216,0.0,0.0,13.526,11.801,4.5775872,1321.562112,1.357824,0.0,13.037,10.969,4.9717614,1103.208448,0.0,0.0,13.025,11.731,5.4301257,1203.482624,0.0,0.0,14.594,13.221
1442568101000,10.5535108,1184.862208,1.867776,0.0,79.031,74.446,4.3976104,1186.459648,0.0,0.0,11.309,10.573,3.29152,1322.323968,1.36192,0.0,8.334,6.892,3.5675606,1104.023552,0.0,0.0,11.522,10.145,4.0249196,1206.657024,0.004096,0.0,11.936,10.015
1442568162000,11.3402552,1183.391744,2.875392,0.0,71.742,67.466,4.1700087,1189.756928,0.032768,0.0,8.142,6.721,3.5807623,1323.470848,2.148352,0.0,9.585,8.738,3.859897,1106.505728,0.0,0.0,9.788,9.494,4.2627179,1204.875264,0.0,0.0,11.332,10.343
1442568222000,10.9442741,1181.55264,3.362816,0.0,87.426,82.103,5.0546877,1186.79552,0.0,0.0,13.405,10.589,5.0012956,1322.467328,1.830912,0.0,11.312,10.575,3.3655324,1106.550784,0.0,0.0,10.521,9.104,4.1448705,1201.987584,0.0,0.0,10.887,9.511
1442568282000,11.1597901,1184.632832,4.124672,0.0,86.94,82.176,4.2880217,1187.741696,0.0,0.0,13.075,10.724,3.4153434,1328.095232,1.548288,0.0,9.868,8.273,4.2847541,1108.410368,0.0,0.0,12.12,11.01,4.6139238,1202.15552,0.0,0.0,14.872,12.311
1442568342000,13.9088359,1181.61408,2.342912,0.0,90.258,84.813,5.7720204,1191.800832,0.0,0.0,11.638,9.65,5.152591,1332.150272,2.158592,0.0,12.078,10.624,5.3626002,1106.448384,0.0,0.0,11.922,10.106,5.1751936,1205.075968,0.0,0.0,13.207,10.822
1442568402000,9.229643,1180.336128,3.424256,0.0,86.656,81.041,3.5291054,1188.888576,0.0,0.0,11.233,9.863,3.5139954,1330.13504,1.323008,0.0,11.331,8.979,2.9792021,1104.252928,0.0,0.0,10.871,8.324,4.4957631,1202.200576,0.012288,0.0,12.986,11.742
1442568463000,8.643673,1182.027776,2.142208,0.0,85.387,80.375,3.5791221,1195.798528,0.0,0.0,10.105,9.869,3.1446142,1330.327552,2.033664,0.0,10.425,9.283,3.3025005,1112.481792,0.0,0.0,12.249,10.792,3.3724518,1202.909184,0.0,0.0,11.487,10.136
1442568523000,1.6162437,1171.034112,1.265664,0.0,52.146,47.988,0.5912397,1169.973248,0.0,0.0,6.991,4.96,0.6545927,1314.054144,1.409024,0.0,6.794,6.887,0.592309,1093.636096,0.0,0.0,6.731,5.776,0.4946862,1194.409984,0.0,0.0,7.348,5.984
1442568584000,12.0571136,1177.591808,2.547712,0.0,92.853,86.987,4.4402733,1178.804224,0.0,0.0,11.347,10.523,4.2401853,1322.459136,1.619968,0.0,11.729,10.901,4.5252849,1103.593472,0.0,0.0,11.567,9.418,4.9979761,1204.699136,0.0,0.0,15.093,13.125
1442568645000,12.754324,1183.744,3.170304,0.0,92.057,86.266,5.2000316,1179.66848,0.0,0.0,12.203,10.12,4.8702027,1324.191744,1.306624,0.006144,11.225,10.178,4.7397316,1104.723968,0.0,0.0,14.101,12.472,4.628123,1201.799168,0.0,0.0,11.114,9.962
1442568705000,11.3496849,1181.876224,2.359296,0.0,73.956,69.838,3.5351539,1182.375936,0.0,0.0,8.109,7.33,3.8465196,1323.917312,1.777664,0.006144,10.153,8.684,4.0268539,1106.366464,0.0,0.0,9.204,7.791,4.6777202,1201.287168,0.0,0.0,12.556,10.82
1442568765000,11.5579495,1183.25248,2.367488,0.0,86.014,80.484,4.0123523,1187.299328,0.0,0.0,11.45,9.516,4.162247,1320.640512,1.685504,0.014336,9.511,8.572,4.588523,1106.51392,0.0,0.0,12.047,10.015,4.8322161,1201.8688,0.0,0.0,12.478,11.245
1442568825000,10.1335211,1183.98976,2.883584,0.0,74.0,68.479,4.2110905,1191.174144,0.0,0.0,12.361,11.288,3.4891695,1319.174144,0.792576,0.012288,7.922,7.408,2.8261268,1105.743872,0.0,0.0,10.783,9.547,3.0907828,1203.822592,0.0,0.0,7.611,6.193
1442568885000,11.0450544,1185.398784,3.100672,0.0,105.45,99.263,2.8345239,1189.122048,0.0,0.0,10.501,9.365,4.6419752,1319.780352,1.517568,0.008192,14.876,11.945,4.2463648,1105.436672,0.0,0.0,14.133,12.457,4.2807482,1202.561024,0.0,0.0,15.661,12.809
1442568945000,12.5548145,1185.316864,3.325952,0.0,86.668,81.557,4.8817227,1191.702528,0.0,0.0,12.921,10.798,3.0696503,1324.556288,0.827392,0.016384,8.447,6.804,4.7209327,1106.804736,0.0,0.0,12.226,10.426,4.7255053,1203.572736,0.0,0.0,13.635,11.755
1442569005000,10.216156,1183.555584,3.641344,0.0,88.388,83.271,4.620227,1191.059456,0.0,0.0,13.039,10.865,4.38882,1322.471424,1.685504,0.01024,9.823,9.213,3.6098721,1107.398656,0.0,0.0,14.958,11.586,3.9065436,1199.616,0.0,0.0,9.211,7.546
1442569066000,13.5673003,1188.892672,4.329472,0.0,87.676,81.597,5.2558097,1197.019136,0.0,0.0,12.246,11.399,4.8459817,1324.617728,0.821248,0.014336,8.949,7.019,5.2964126,1108.164608,0.0,0.0,10.078,8.867,5.3620743,1202.62656,0.0,0.0,14.457,11.661
1442569126000,8.7803172,1187.618816,2.740224,0.0,74.941,70.549,3.4261255,1192.22272,0.0,0.0,9.548,8.257,3.3130067,1326.14144,1.783808,0.014336,8.607,8.069,3.1578327,1113.186304,0.0,0.0,11.392,10.082,3.6391823,1202.122752,0.0,0.0,10.715,9.357
1442569186000,13.2385175,1189.363712,2.220032,0.0,90.512,85.113,4.5784914,1179.455488,0.0,0.0,10.393,8.553,4.9144565,1318.670336,1.542144,0.016384,8.97,8.981,5.3570769,1104.965632,0.0,0.0,13.872,12.762,4.9720569,1196.478464,0.0,0.0,14.467,12.07
1442569246000,11.6430817,1187.569664,2.797568,0.0,98.793,92.302,4.0624829,1184.41984,0.0,0.0,13.239,10.775,4.1785866,1319.5264,1.366016,0.012288,11.438,9.513,4.4348802,1104.621568,0.0,0.0,12.884,10.144,4.8399564,1197.522944,0.0,0.0,16.202,13.755
1442569307000,12.8706917,1189.462016,1.343488,0.0,82.715,77.634,4.5437441,1177.78432,0.0,0.0,10.233,9.89,4.7109334,1316.220928,1.714176,0.0,11.429,9.632,4.9577857,1107.566592,0.0,0.0,12.475,10.634,3.7526363,1193.906176,0.0,0.0,10.182,8.851
1442569367000,12.8338596,1190.6048,2.408448,0.0,90.846,85.393,5.2390299,1179.697152,0.0,0.0,11.673,10.636,4.3819595,1315.643392,1.030144,0.0,10.574,8.05,4.9592091,1109.393408,0.0,0.0,12.005,11.156,4.908542,1198.313472,0.0,0.0,14.278,12.249
1442569427000,10.8351788,1191.415808,3.670016,0.0,75.942,71.206,3.6006599,1183.490048,0.0,0.0,11.336,10.279,3.6651353,1316.036608,1.222656,0.0,7.587,6.942,3.1205327,1106.436096,0.0,0.0,10.721,9.471,3.4856439,1200.054272,0.0,0.0,10.738,9.164
1442569487000,9.5891238,1189.113856,3.088384,0.0,76.39,72.118,3.6050552,1179.308032,0.0,0.0,9.221,8.476,4.4370605,1316.900864,1.019904,0.0,11.42,9.329,3.6513773,1106.952192,0.0,0.0,10.463,9.012,3.3785732,1196.163072,0.0,0.0,10.29,8.848
1442569547000,10.6792469,1191.903232,2.646016,0.0,72.892,68.929,3.5796046,1186.967552,0.0,0.0,10.041,7.955,2.9473454,1319.469056,1.378304,0.0,7.484,7.102,4.2137411,1111.773184,0.0,0.0,11.268,10.167,4.3087804,1197.34272,0.0,0.0,10.066,8.684
1442569607000,36.2276215,1193.332736,1.789952,0.0,60.543,56.54,2.0845003,1183.80544,0.0,0.0,7.783,6.773,1.9533136,1322.409984,1.222656,0.0,7.562,6.689,2.0118158,1111.351296,0.0,0.0,9.416,8.096,2.4873121,1192.8576,0.0,0.0,9.062,8.387
1442569668000,10.5569139,1196.01152,2.646016,0.02048,84.077,78.7,4.2310567,1186.332672,0.0,0.0,10.768,9.099,3.5767349,1322.450944,1.073152,0.0,10.398,8.816,3.8154293,1112.489984,0.0,0.0,12.974,11.04,3.8735029,1189.920768,0.0,0.0,12.181,9.97
1442569728000,8.5188493,1196.019712,1.78176,0.016384,55.668,52.273,3.4978293,1187.127296,0.0,0.0,6.653,6.024,3.8426702,1319.473152,0.7168,0.0,7.724,6.325,12.384689,1115.5456,4.685824,0.0,8.034,7.07,3.59315,1192.513536,0.0,0.0,7.85,7.104
1442569788000,11.7080055,1196.560384,2.441216,0.008192,86.987,81.874,4.6589003,1174.188032,0.0,0.0,13.488,11.711,4.2120416,1318.719488,0.800768,0.0,8.049,6.463,4.558617,1107.615744,0.0,0.0,13.308,11.187,4.0238097,1189.53984,0.0,0.0,11.425,10.098
1442569848000,10.6163947,1198.784512,3.047424,0.016384,62.086,58.28,2.7276609,1172.963328,0.0,0.0,6.734,6.853,4.5800063,1318.2976,1.009664,0.0,7.475,6.796,4.0824835,1109.028864,0.0,0.0,7.829,7.444,4.1996285,1194.27072,0.0,0.0,11.841,10.18
1442569908000,10.4417308,1198.329856,2.965504,0.036864,80.677,74.993,3.9613298,1173.610496,0.0,0.0,10.629,9.057,4.0405359,1321.2672,1.755136,0.0,7.747,7.233,3.4106178,1108.815872,0.0,0.0,10.942,9.646,4.6324477,1193.51296,0.0,0.0,12.824,10.923
1442569969000,9.6952454,1202.020352,2.654208,0.036864,88.077,82.562,3.5029943,1172.86912,0.0,0.0,10.759,9.553,3.4504253,1321.566208,1.191936,0.0,9.294,8.35,3.1976475,1113.321472,0.0,0.0,14.186,13.073,3.4206257,1190.1952,0.0,0.0,12.398,10.343
1442570030000,12.5816284,1204.436992,1.806336,0.0,84.364,78.146,4.6961349,1177.870336,0.0,0.0,9.93,8.932,4.5822539,1321.283584,1.103872,0.0,11.22,8.982,4.2686599,1114.33728,0.0,0.0,13.203,10.612,4.8245281,1190.87104,0.0,0.0,10.331,9.499
1442570090000,11.6355078,1197.891584,1.609728,0.0,93.285,87.566,3.8472127,1177.608192,0.0,0.0,12.078,10.78,4.7892951,1319.747584,1.579008,0.0,11.075,9.731,4.8665201,1114.742784,0.0,0.0,13.884,12.001,4.7454653,1192.71424,0.0,0.0,14.308,11.564
1442570150000,12.5906259,1207.611392,3.948544,0.024576,85.734,79.852,4.5742014,1183.879168,0.0,0.0,11.319,9.994,4.6278483,1319.66976,1.394688,0.0,9.222,8.479,4.1807973,1111.400448,0.0,0.0,10.946,8.973,5.0126116,1189.072896,0.0,0.0,13.237,10.935
1442570210000,13.8088276,1204.604928,4.308992,0.0,118.117,110.713,5.4037665,1182.449664,0.0,0.0,14.125,11.343,5.8292121,1316.39296,1.214464,0.0,13.732,10.706,5.1730654,1112.33024,0.0,0.0,16.868,13.29,6.7132301,1189.179392,0.0,0.0,18.319,15.842
1442570270000,12.8818649,1202.40128,3.371008,0.004096,90.034,84.704,4.2267335,1183.08864,0.0,0.0,11.555,10.215,4.9613311,1314.705408,2.074624,0.0,10.566,9.485,4.9546093,1114.136576,0.0,0.0,12.216,10.479,4.6692364,1188.519936,0.0,0.0,13.486,12.03
1442570330000,13.7876011,1203.79392,2.220032,0.0,82.793,77.484,4.8270495,1190.264832,0.0,0.0,10.347,9.251,4.7970143,1319.79264,0.950272,0.0,9.576,7.622,5.7849443,1113.858048,0.0,0.0,14.051,11.963,5.7716151,1189.33504,0.0,0.0,11.409,9.233
1442570391000,9.3719399,1191.186432,2.695168,0.0,87.364,81.81,4.6414848,1180.557312,0.0,0.0,13.817,12.934,2.6943668,1313.476608,1.253376,0.0,9.896,8.568,3.3981786,1113.030656,0.0,0.0,10.717,9.446,3.2939117,1187.926016,0.0,0.0,11.472,9.852
1442570452000,12.5702396,1191.796736,3.837952,0.0,89.92,84.529,4.9932919,1180.418048,0.0,0.0,13.597,11.379,4.8026848,1308.524544,1.349632,0.0,8.964,8.521,5.0599367,1113.567232,0.0,0.0,12.497,9.829,4.8255484,1192.562688,0.0,0.0,13.722,11.322
1442570512000,12.0346891,1202.061312,2.43712,0.0,84.603,78.859,3.9096793,1182.777344,0.0,0.0,9.65,8.625,4.098972,1309.872128,1.18784,0.0,10.184,8.593,4.5507765,1112.543232,0.0,0.0,13.591,11.395,4.3698935,1192.194048,0.0,0.0,10.468,9.412
1442570572000,11.2676657,1202.024448,3.631104,0.0,90.961,85.252,4.2123168,1179.475968,0.0,0.0,12.611,11.305,3.1758059,1309.782016,0.919552,0.0,9.225,7.002,4.0605407,1115.041792,0.0,0.0,12.532,11.833,4.0904052,1190.56384,0.0,0.0,12.552,11.229
1442570632000,13.227923,1195.552768,1.507328,0.0,99.131,92.558,4.8800226,1186.14016,0.0,0.0,13.431,11.303,4.9661613,1310.568448,1.406976,0.0,11.918,10.111,4.3075284,1115.930624,0.0,0.0,12.85,11.878,5.0405444,1193.689088,0.0,0.0,15.319,13.689
1442570692000,14.6291106,1198.592,2.113536,0.0,94.198,87.87,4.5246399,1185.103872,0.0,0.0,9.702,8.753,5.0381182,1313.501184,2.103296,0.0,10.784,9.047,6.1508262,1116.430336,0.0,0.0,16.614,13.883,5.6023668,1189.810176,0.0,0.0,13.032,11.524
1442570752000,12.0590939,1203.449856,4.01408,0.0,95.117,90.281,4.344328,1181.71648,0.0,0.0,13.33,10.576,4.7943824,1313.779712,1.247232,0.0,10.469,8.71,4.4986682,1117.863936,0.0,0.0,14.548,13.108,4.8899598,1188.962304,0.0,0.0,13.841,10.757
1442570812000,11.7195405,1202.622464,2.633728,0.0,88.31,83.005,4.0843414,1184.579584,0.0,0.0,12.032,9.315,4.6628528,1314.332672,2.435072,0.0,10.933,10.521,4.8366766,1117.806592,0.0,0.0,11.291,9.686,4.1244492,1189.306368,0.0,0.0,12.788,11.358
1442570873000,13.0758515,1208.164352,2.33472,0.0,85.787,80.197,3.9624359,1185.411072,0.0,0.0,9.772,8.703,4.5871591,1310.633984,1.470464,0.0,8.375,8.227,5.1622667,1118.343168,0.0,0.0,14.431,13.291,4.7015137,1189.961728,0.0,0.0,11.527,10.334
1442570933000,10.8548047,1206.448128,1.949696,0.0,91.682,85.452,4.0229689,1184.129024,0.0,0.0,11.564,10.338,3.7140118,1310.519296,1.329152,0.0,11.85,9.693,3.9844622,1116.766208,0.0,0.0,12.57,10.942,3.9649807,1190.354944,0.0,0.0,12.689,11.898
1442570993000,10.1575557,1203.6096,1.80224,0.0,73.896,69.097,3.9896192,1178.017792,0.0,0.0,9.09,7.334,3.5805889,1308.250112,1.589248,0.0,8.329,7.571,3.2395677,1112.870912,0.0,0.0,11.005,9.81,3.5717138,1185.325056,0.0,0.0,10.828,9.1
1442571053000,12.0611574,1199.63648,1.871872,0.0,87.929,82.467,5.3235822,1175.3472,0.0,0.0,13.33,12.179,3.539693,1308.041216,1.681408,0.0,11.078,9.576,3.7698814,1115.9552,0.0,0.0,11.266,10.098,3.9507763,1185.918976,0.0,0.0,11.704,9.776
1442571113000,12.0919962,1201.598464,2.822144,0.0,88.623,84.065,4.6484584,1177.346048,0.0,0.0,12.952,10.894,3.9433995,1310.580736,1.394688,0.0,9.624,8.038,4.4767446,1120.509952,0.0,0.0,11.901,10.68,4.3856205,1183.096832,0.0,0.0,12.429,11.15
1442571173000,10.7062037,1201.37728,3.87072,0.0,97.768,92.441,4.9062322,1179.267072,0.0,0.0,13.172,10.885,3.6559344,1310.55616,1.767424,0.0,10.059,8.883,5.4281292,1120.776192,0.0,0.0,14.365,12.69,4.8817912,1184.047104,0.0,0.0,15.055,12.234
1442571233000,12.0377058,1200.35328,2.813952,0.0,99.386,92.995,4.0873301,1184.186368,0.0,0.0,13.225,11.599,4.1783334,1310.224384,1.251328,0.0,11.759,9.125,4.6870399,1121.755136,0.0,0.0,16.25,12.997,4.2431395,1185.148928,0.0,0.0,12.126,10.59
1442571293000,12.5586934,1201.23392,3.653632,0.0,89.985,84.436,5.2668199,1184.202752,0.0,0.0,14.168,12.114,3.8580744,1312.21504,1.685504,0.0,9.588,8.6,4.6806882,1119.473664,0.0,0.0,9.185,7.655,4.4822474,1186.291712,0.0,0.0,14.538,12.434
1442571353000,11.0297261,1204.908032,3.186688,0.0,78.305,74.16,4.6290455,1185.56672,0.0,0.0,9.065,8.356,3.8722553,1312.505856,1.363968,0.0,9.985,8.908,3.9157753,1123.647488,0.0,0.0,11.083,10.147,3.9928395,1187.663872,0.0,0.0,10.564,9.72
1442571414000,11.2228669,1207.45984,3.735552,0.0,83.705,78.441,3.9685727,1190.690816,0.0,0.0,10.351,9.8,4.1045573,1312.202752,1.120256,0.0,9.607,8.783,4.5767506,1124.184064,0.0,0.0,10.835,9.236,4.2028748,1187.639296,0.0,0.0,12.772,10.517
1442571474000,8.016004,1205.22752,2.3552,0.0,65.393,60.879,2.8008995,1186.131968,0.0,0.0,8.488,6.715,2.6219822,1309.978624,1.497088,0.0,7.749,6.92,2.3808994,1121.8944,0.0,0.0,9.293,7.982,2.3742243,1186.238464,0.0,0.0,9.076,7.999
1442571534000,12.7037961,1205.039104,2.912256,0.0,91.796,86.139,4.9940391,1190.219776,0.0,0.0,12.088,11.267,4.3357756,1308.811264,1.765376,0.0,11.603,9.851,5.3937656,1123.684352,0.0,0.0,13.888,11.344,3.9340149,1186.123776,0.0,0.0,11.253,10.172
1442571594000,11.2166106,1203.38432,2.17088,0.0,82.975,77.501,4.4729525,1169.98144,0.0,0.0,11.105,9.142,4.925222,1305.305088,2.576384,0.0,11.381,10.393,4.0329605,1114.23488,0.0,0.0,11.539,10.305,3.1822146,1173.393408,0.0,0.0,10.775,9.213
1442571654000,10.9059237,1205.354496,3.125248,0.0,78.504,73.676,3.1323736,1179.46368,0.0,0.0,10.097,8.573,4.3157381,1305.735168,1.329152,0.0,10.82,8.808,4.201092,1118.568448,0.0,0.0,9.785,8.911,3.4434739,1181.667328,0.0,0.0,12.068,10.788
1442571714000,11.4172346,1201.651712,2.830336,0.0,74.363,69.354,4.2125848,1173.405696,0.0,0.0,10.237,9.132,3.904591,1307.439104,1.755136,0.0,9.04,7.968,4.1996077,1124.683776,0.0,0.0,10.697,9.521,4.6238464,1185.755136,0.0,0.0,8.646,7.975
1442571774000,10.989106,1203.44576,2.486272,0.0,90.485,85.483,3.7964189,1177.997312,0.0,0.0,10.484,9.983,3.9412947,1304.317952,1.503232,0.0,11.942,10.096,4.4307892,1125.13024,0.0,0.0,12.833,11.056,4.0006984,1183.305728,0.0,0.0,13.378,11.515
1442571835000,11.6461134,1204.355072,3.629056,0.0,83.475,78.138,4.3343324,1181.835264,0.0,0.0,9.389,8.817,4.417146,1305.960448,1.503232,0.012288,10.311,9.308,4.3911361,1126.58432,0.0,0.0,11.137,9.495,3.9292411,1179.979776,0.0,0.0,12.6,9.883
1442571895000,10.0888935,1204.293632,1.572864,0.0,71.708,67.036,3.5176342,1183.342592,0.0,0.0,9.604,9.321,3.8571256,1305.972736,1.708032,0.014336,8.448,7.927,3.5699693,1124.806656,0.0,0.0,9.194,7.066,3.2584608,1185.538048,0.0,0.0,10.282,10.329
1442571955000,9.602342,1206.706176,3.190784,0.0,77.563,72.582,4.1013286,1182.621696,0.0,0.0,10.507,8.894,3.6363989,1307.906048,1.980416,0.016384,10.436,8.739,3.3485063,1128.865792,0.0,0.0,12.083,10.231,3.732498,1183.68256,0.0,0.0,10.012,9.344
1442572015000,9.8124726,1212.837888,3.342336,0.0,85.916,80.718,3.7593373,1185.869824,0.0,0.0,11.181,9.714,2.9869455,1308.000256,1.265664,0.012288,8.423,6.959,4.0255,1131.139072,0.0,0.0,13.629,11.596,3.9959602,1182.298112,0.0,0.0,11.617,10.596
1442572075000,9.9842083,1209.745408,2.64192,0.0,73.867,68.644,6.1234957,1185.849344,0.0,0.0,9.231,8.152,2.9311474,1306.775552,1.179648,0.006144,8.694,7.476,3.8231351,1128.824832,0.0,0.0,10.577,9.425,3.4903171,1180.454912,0.0,0.0,10.671,9.223
1442572135000,8.6014105,1210.068992,0.98304,0.0,71.446,65.363,3.2464678,1190.4,0.0,0.0,8.036,7.531,2.6076478,1311.444992,1.792,0.008192,9.301,8.419,2.9402846,1127.661568,0.0,0.0,8.049,7.563,2.6109173,1182.425088,0.0,0.0,11.814,10.452
1442572195000,10.5479945,1203.44576,3.182592,0.0,75.399,70.58,4.7512424,1177.403392,0.0,0.0,10.706,9.433,3.96254,1300.795392,1.378304,0.014336,9.219,8.125,4.0815836,1119.895552,0.0,0.0,8.594,8.39,3.5943036,1175.076864,0.032768,0.0,10.321,8.581
1442572256000,12.24811,1201.205248,2.46784,0.0,76.621,71.053,3.5743946,1174.8352,0.0,0.0,9.02,7.2,4.5596456,1301.03296,2.312192,0.01024,8.742,7.955,4.4157146,1119.444992,0.0,0.0,10.237,9.398,4.5026798,1178.202112,0.0,0.0,11.72,10.855
1442572317000,10.6170989,1204.416512,3.23584,0.0,72.034,68.089,3.5490957,1173.07392,0.0,0.0,8.234,7.436,3.9645615,1298.292736,1.77152,0.0,9.288,8.797,3.5271261,1120.649216,0.0,0.0,10.781,9.423,4.6715631,1178.394624,0.016384,0.0,10.071,8.627
1442572378000,14.0226336,1209.032704,3.371008,0.036864,110.317,102.615,5.2384431,1179.193344,0.0,0.0,13.063,11.227,5.6291367,1298.563072,1.572864,0.0,14.814,13.033,5.5203575,1127.40352,0.0,0.0,17.504,15.572,5.4813785,1177.288704,0.0,0.0,13.615,10.563
1442572438000,13.0426142,1208.868864,2.617344,0.004096,81.207,76.119,4.2782584,1178.68544,0.0,0.0,9.749,8.812,4.0732292,1301.1968,1.76128,0.0,9.278,9.118,4.6736534,1128.292352,0.0,0.0,11.088,9.267,5.0139676,1179.807744,0.0,0.0,12.687,11.886
1442572498000,12.9053492,1208.70912,3.084288,0.028672,71.02,66.404,5.5346819,1179.316224,0.0,0.0,11.993,9.807,4.06029,1299.881984,1.511424,0.0,7.362,6.836,4.6224241,1129.201664,0.0,0.0,9.728,9.351,4.883249,1179.856896,0.0,0.0,9.363,8.605
1442572558000,12.38079,1208.561664,3.36896,0.04096,86.45,80.915,4.5397843,1186.54976,0.0,0.0,10.87,8.884,4.7734286,1298.649088,1.574912,0.0,9.79,8.8,5.0544735,1130.909696,0.0,0.0,14.868,12.408,3.9651309,1179.553792,0.0,0.0,9.631,8.282
1442572618000,10.6691867,1207.590912,2.527232,0.032768,82.567,77.846,3.5104385,1185.95584,0.0,0.0,11.957,10.369,3.7546783,1299.996672,1.55648,0.0,11.442,9.438,4.170705,1128.759296,0.0,0.0,10.695,9.773,4.0826792,1181.622272,0.0,0.0,11.5,8.954
1442572679000,8.7522229,1213.005824,2.838528,0.036864,79.884,74.963,3.2823993,1184.997376,0.0,0.0,10.021,7.98,3.2126993,1302.052864,1.697792,0.0,10.226,9.008,3.1332686,1126.56384,0.0,0.0,12.85,10.045,3.5648455,1179.574272,0.0,0.0,10.563,8.662
1442572739000,1.2361832,1210.097664,0.221184,0.032768,20.512,18.693,0.481625,1185.988608,0.0,0.0,3.418,2.764,30.4712741,1307.529216,1.312768,0.0,3.539,2.921,0.2010244,1124.51584,0.0,0.0,3.336,2.994,0.5990525,1179.521024,0.0,0.0,3.086,3.417
1442572799000,10.5205087,1199.120384,2.256896,0.016384,75.195,69.84,3.143757,1167.110144,0.0,0.0,9.508,8.433,3.5278342,1316.335616,1.7408,0.0,7.492,6.835,3.8228387,1116.901376,0.0,0.0,11.01,9.668,3.4777939,1165.512704,0.0,0.0,12.475,9.805
1442572859000,9.1284909,1200.943104,3.129344,0.024576,72.159,66.808,3.6627111,1170.599936,0.0,0.0,11.274,10.275,2.8586381,1316.671488,1.64864,0.0,7.692,7.14,3.4629995,1114.161152,0.0,0.0,8.802,7.819,3.0962335,1163.341824,0.032768,0.0,10.699,9.314
1442572919000,12.2745307,1200.340992,2.215936,0.024576,81.481,76.527,4.8451159,1178.0096,0.094208,0.0,11.358,9.607,3.8290291,1320.26368,1.97632,0.0,9.745,8.69,3.8232272,1115.000832,0.0,0.0,9.73,8.955,4.2327021,1165.119488,0.0,0.0,13.815,11.31
1442572980000,51.6907813,1254.166528,4.204544,0.008192,32.086,30.076,0.946567,1175.035904,0.032768,0.0,4.304,3.434,1.0913546,1319.64928,0.823296,0.0,4.399,4.326,1.4053872,1112.35072,0.0,0.0,4.449,3.799,0.9117275,1164.070912,0.0,0.0,4.89,4.558
1442573040000,10.5278364,1229.221888,3.186688,0.02048,62.608,58.702,4.200118,1174.614016,0.0,0.0,8.498,7.268,3.2796822,1314.668544,1.835008,0.0,8.332,7.502,3.6530088,1122.770944,0.0,0.0,8.336,7.512,3.0458414,1171.693568,0.065536,0.0,8.929,7.977
1442573101000,9.9006668,1225.289728,3.526656,0.0,77.818,72.267,3.2996226,1174.892544,0.0,0.0,11.022,9.892,3.5381819,1313.665024,1.660928,0.0,9.134,7.976,3.4535334,1122.512896,0.0,0.0,10.947,10.027,4.1840977,1173.184512,0.0,0.0,9.706,9.076
1442573161000,10.3207385,1228.648448,3.710976,0.0,70.671,65.714,3.4771966,1173.417984,0.0,0.0,9.351,7.234,4.3121347,1311.330304,1.906688,0.0,9.029,8.125,3.3298013,1122.16064,0.0,0.0,9.412,9.351,3.60553,1169.08032,0.0,0.0,9.727,9.153
1442573221000,11.652551,1231.364096,4.165632,0.0,78.67,74.298,4.5915256,1174.777856,0.032768,0.0,11.483,11.1,4.0408316,1316.51584,2.254848,0.0,10.771,9.581,4.4307467,1123.201024,0.0,0.0,10.24,7.567,3.3073751,1169.932288,0.0,0.0,10.504,8.948
1442573281000,8.8379148,1232.023552,3.817472,0.0,72.695,68.078,2.9798397,1173.254144,0.0,0.0,9.175,8.305,2.9717598,1313.767424,1.67936,0.0,7.551,6.654,3.4374947,1123.065856,0.0,0.0,11.292,9.444,3.9388974,1174.388736,0.0,0.0,10.225,9.116
1442573341000,8.0077909,1234.956288,4.79232,0.0,74.019,68.756,2.7663472,1174.9376,0.0,0.0,9.013,7.888,3.0878402,1314.033664,1.523712,0.0,8.215,7.553,2.9936696,1123.852288,0.0,0.0,10.553,9.421,2.9127494,1174.290432,0.0,0.0,11.448,10.287
1442573401000,10.9116188,1229.422592,2.33472,0.0,76.609,71.787,3.3451397,1171.37408,0.065536,0.0,9.669,7.173,4.4206367,1308.532736,2.207744,0.01024,9.386,9.092,4.2597624,1120.485376,0.0,0.0,9.262,7.427,4.2581369,1173.93408,0.0,0.0,13.313,12.376
1442573461000,9.650033,1225.691136,2.961408,0.0,73.27,68.378,3.0947635,1165.185024,0.0,0.0,11.608,9.609,3.5434903,1305.358336,1.800192,0.01024,8.911,7.499,3.4773694,1117.626368,0.0,0.0,8.235,8.134,3.6498629,1160.081408,0.0,0.0,10.33,9.427
1442573521000,10.2996391,1236.471808,4.374528,0.0,70.495,66.25,3.341111,1166.209024,0.0,0.0,8.92,7.609,3.7766014,1305.76384,1.093632,0.006144,9.074,7.051,4.0214652,1116.192768,0.0,0.0,10.712,8.731,4.0259687,1161.842688,0.0,0.0,8.747,8.251
1442573581000,12.3350855,1235.61984,4.28032,0.0,86.233,81.086,4.8832845,1166.348288,0.0,0.0,11.477,9.828,4.6338677,1306.492928,1.222656,0.008192,10.329,8.619,4.4833613,1117.995008,0.0,0.0,12.52,10.934,4.5205611,1162.32192,0.0,0.0,12.357,11.047
1442573641000,10.9803537,1233.543168,5.287936,0.0,82.052,77.484,4.7207399,1167.74912,0.0,0.0,10.471,8.406,3.3046194,1303.883776,1.00352,0.014336,9.713,8.074,3.8392538,1116.40576,0.0,0.0,11.424,9.514,4.6409567,1158.955008,0.0,0.0,13.13,11.922
1442573701000,9.2310985,1233.477632,3.436544,0.0,73.314,68.636,3.1393322,1173.27872,0.0,0.0,8.989,7.691,2.9336015,1309.22496,1.447936,0.004096,9.295,8.134,3.7799493,1120.342016,0.0,0.0,10.174,9.44,3.8068814,1160.433664,0.0,0.0,10.284,8.18
1442573761000,9.6348293,1229.55776,1.51552,0.0,73.325,67.84,3.3876097,1175.556096,0.0,0.0,8.798,7.6,3.2534682,1307.516928,1.861632,0.01024,11.251,9.661,3.2796655,1126.699008,0.0,0.0,9.432,8.148,3.1384609,1161.879552,0.0,0.0,10.086,9.312
1442573821000,9.1567192,1231.060992,3.600384,0.0,78.904,73.702,2.8606761,1168.052224,0.0,0.0,8.964,7.138,3.0553454,1308.52864,1.370112,0.008192,8.667,7.227,3.5953372,1125.9904,0.0,0.0,12.913,10.51,3.6991695,1160.658944,0.02048,0.0,12.561,10.793
1442573881000,10.7610105,1231.568896,3.387392,0.0,81.701,77.238,4.0305926,1168.965632,0.0,0.0,13.364,10.577,2.9067962,1306.861568,1.130496,0.01024,8.433,7.206,4.0722803,1126.191104,0.0,0.0,12.127,10.413,4.5396485,1156.366336,0.0,0.0,11.793,10.425
1442573941000,11.6804223,1230.409728,3.067904,0.0,60.466,55.737,4.3301898,1169.833984,0.0,0.0,7.236,6.448,4.2866105,1307.222016,1.961984,0.014336,6.095,6.232,3.8570487,1122.164736,0.0,0.0,8.547,7.008,3.6503665,1154.039808,0.0,0.0,9.028,8.734
1442574001000,10.7607957,1232.22016,2.94912,0.0,68.985,65.161,3.6994365,1173.843968,0.0,0.0,9.469,7.6,3.6177602,1308.270592,2.05824,0.004096,7.595,6.955,4.1534235,1125.584896,0.0,0.0,11.123,9.71,4.1290319,1157.091328,0.0,0.0,9.634,8.438
1442574061000,10.3188154,1228.537856,2.715648,0.0,73.072,67.753,4.3382695,1165.06624,0.0,0.0,10.169,8.339,3.578771,1301.069824,1.818624,0.008192,8.457,7.802,3.4695811,1115.21792,0.0,0.0,9.74,8.266,3.7647621,1146.548224,0.0,0.0,10.783,9.95
1442574121000,6.5143702,1228.558336,1.961984,0.0,50.28,46.654,2.3886442,1166.270464,0.0,0.0,6.463,5.481,3.3503209,1303.9616,1.746944,0.006144,5.385,5.247,2.4815198,1116.418048,0.0,0.0,7.501,5.777,2.6853742,1145.905152,0.0,0.0,8.17,6.693
1442574181000,5.6346269,1233.281024,2.404352,0.0,42.427,39.989,2.2773996,1171.603456,0.0,0.0,6.028,5.982,18.5949147,1305.317376,3.268608,0.002048,4.13,3.315,2.1568272,1118.466048,0.0,0.0,5.895,4.715,1.9900825,1146.24512,0.0,0.0,7.678,5.968
1442574241000,10.5564852,1234.132992,3.46112,0.0,67.097,63.034,4.2507585,1170.788352,0.0,0.0,8.805,6.863,3.1520829,1306.615808,1.861632,0.014336,8.394,7.165,4.1497455,1118.429184,0.0,0.0,7.222,5.805,3.9880193,1149.534208,0.0,0.0,12.221,10.689
1442574301000,8.5902111,1238.290432,3.223552,0.0,64.632,60.857,3.4507136,1170.296832,0.0,0.0,9.754,8.164,3.8765913,1307.185152,0.995328,0.012288,7.657,6.382,3.3315592,1121.898496,0.0,0.0,9.623,9.196,3.5218433,1151.44704,0.0,0.0,8.341,6.763
1442574361000,8.535847,1234.243584,3.641344,0.0,69.06,64.809,3.3902454,1173.721088,0.0,0.0,9.397,8.514,3.4673821,1307.693056,1.849344,0.014336,9.865,8.274,3.7430541,1119.993856,0.0,0.0,9.449,8.561,2.6768144,1152.45056,0.0,0.0,8.666,7.785
1442574421000,7.5617038,1237.46304,2.99008,0.0,58.176,54.814,2.3725302,1171.603456,0.0,0.0,7.178,7.095,2.5140767,1307.406336,1.759232,0.004096,6.22,5.841,2.6833457,1122.28352,0.0,0.0,8.448,8.081,3.1410206,1153.527808,0.004096,0.0,9.638,8.403
1442574481000,10.0440292,1237.856256,3.207168,0.0,67.929,64.266,3.9944339,1169.604608,0.0,0.0,9.678,8.25,3.3352449,1307.5456,1.80224,0.01024,8.303,7.315,3.5970952,1127.378944,0.0,0.0,10.276,9.295,3.9858923,1151.717376,0.0,0.0,9.206,7.166
1442574541000,9.4690877,1237.184512,3.01056,0.0,78.863,73.929,3.8624028,1170.313216,0.0,0.0,10.071,8.788,3.4668783,1307.549696,1.941504,0.008192,8.821,8.464,4.4569325,1128.824832,0.0,0.0,12.897,9.876,3.4231585,1153.24928,0.0,0.0,11.536,10.186
1442574601000,10.3041114,1239.302144,3.043328,0.0,81.573,75.629,3.6720316,1170.620416,0.0,0.0,11.008,8.999,2.8974921,1307.885568,1.03424,0.01024,9.66,7.723,4.1476644,1129.545728,0.0,0.0,10.233,10.071,4.5879898,1151.102976,0.0,0.0,11.198,10.039
1442574661000,11.1300785,1233.416192,3.072,0.0,67.857,63.255,3.8741459,1171.746816,0.0,0.0,9.717,8.625,4.0076243,1300.992,1.15712,0.008192,8.134,6.596,4.0401149,1117.126656,0.0,0.0,10.576,8.946,3.8114457,1141.764096,0.0,0.0,7.491,6.928
1442574721000,9.13459,1235.525632,2.99008,0.0,59.389,54.873,3.5916467,1174.102016,0.0,0.0,9.102,8.335,2.2998566,1303.740416,1.169408,0.016384,5.109,4.539,3.2901737,1122.074624,0.0,0.0,8.63,7.353,2.9942481,1140.98176,0.0,0.0,8.47,7.761
1442574781000,11.2484099,1236.729856,1.601536,0.0,76.306,71.127,4.6694297,1172.701184,0.0,0.0,9.399,9.28,4.2237891,1303.703552,2.125824,0.008192,7.335,7.535,5.6236592,1123.721216,0.0,0.0,12.682,10.349,4.1415063,1145.05728,0.032768,0.0,12.128,9.685
1442574841000,10.0701202,1238.50752,4.493312,0.0,71.258,67.241,3.5564141,1169.944576,0.0,0.0,8.128,6.811,3.9176841,1304.342528,1.753088,0.006144,9.388,7.739,3.1480914,1124.51584,0.0,0.0,10.986,10.127,4.0810539,1143.95136,0.0,0.0,10.995,9.544
1442574901000,10.7948336,1238.601728,3.579904,0.0,59.221,56.198,3.9940135,1171.533824,0.0,0.0,8.397,7.365,3.7312053,1312.31744,1.652736,0.018432,7.333,6.287,4.0036707,1123.794944,0.0,0.0,7.126,6.507,4.4751588,1144.807424,0.0,0.0,10.262,8.737
1442574961000,10.8225245,1239.048192,3.125248,0.0,69.949,65.256,3.6517325,1169.977344,0.0,0.0,8.898,7.443,3.7940687,1311.338496,1.724416,0.012288,6.371,6.148,4.0390782,1126.146048,0.0,0.0,10.824,9.932,3.7446683,1144.614912,0.0,0.0,11.581,9.886
1442575021000,11.0240331,1237.958656,2.985984,0.0,66.953,62.73,4.6331599,1172.041728,0.0,0.0,9.381,7.798,4.4295364,1310.117888,1.6896,0.006144,9.325,8.532,3.4022915,1125.584896,0.0,0.0,8.682,8.03,3.3737199,1143.742464,0.0,0.0,8.911,7.333
1442575081000,9.0087982,1239.1424,3.059712,0.0,70.804,67.381,3.4976389,1178.841088,0.0,0.0,10.251,8.52,2.7142578,1309.458432,1.196032,0.014336,8.07,7.156,3.3018916,1126.797312,0.0,0.0,10.613,9.303,2.6254561,1145.155584,0.0,0.0,9.961,8.263
1442575141000,9.9558866,1236.488192,3.096576,0.0,69.67,65.194,4.4970284,1178.382336,0.0,0.0,10.212,9.752,2.3916356,1308.880896,1.206272,0.016384,8.101,6.531,3.7626348,1124.08576,0.0,0.0,9.33,8.451,3.2560874,1145.376768,0.0,0.0,9.884,8.536
1442575201000,9.3272657,1239.384064,3.002368,0.0,80.732,75.535,3.6433933,1177.980928,0.0,0.0,11.318,9.355,3.7783269,1310.265344,1.695744,0.012288,10.9,8.843,2.9235989,1132.945408,0.0,0.0,10.514,9.295,3.2766853,1145.884672,0.0,0.0,10.787,9.454
1442575261000,11.383323,1236.246528,3.74784,0.0,71.93,67.704,4.3675376,1178.836992,0.0,0.0,10.769,9.271,4.4756258,1301.741568,1.417216,0.004096,8.128,7.35,4.1079281,1126.342656,0.0,0.0,8.509,7.573,4.7563702,1140.26496,0.0,0.0,12.107,11.203
1442575321000,9.6769497,1237.180416,3.690496,0.0,79.948,74.911,3.0906754,1175.035904,0.0,0.0,9.123,7.323,3.1013988,1300.324352,0.970752,0.014336,6.492,6.002,3.9573237,1128.800256,0.0,0.0,12.897,11.077,5.0529163,1144.68864,0.0,0.0,14.273,11.956
1442575382000,11.7450897,1234.612224,3.731456,0.0,86.132,79.967,3.8984196,1173.397504,0.0,0.0,11.719,9.587,4.0821456,1302.46656,1.78176,0.0,9.464,8.473,5.2942726,1126.191104,0.0,0.0,11.357,10.097,4.567029,1139.064832,0.0,0.0,14.593,11.734
1442575442000,10.9889182,1240.379392,4.268032,0.0,61.317,57.952,4.0296602,1176.977408,0.0,0.0,6.58,7.017,3.7153479,1304.236032,0.825344,0.0,7.533,5.539,4.4867764,1128.923136,0.0,0.0,9.188,8.21,3.8858401,1139.46624,0.0,0.0,10.271,8.709
1442575502000,10.8539113,1240.375296,3.60448,0.0,67.82,63.299,4.4639001,1178.181632,0.0,0.0,7.354,6.886,4.6821961,1306.935296,2.179072,0.0,10.634,9.653,3.7765224,1130.483712,0.0,0.0,9.16,7.605,4.1052052,1141.383168,0.0,0.0,9.398,9.015
1442575562000,11.9671939,1237.774336,1.892352,0.0,79.547,73.288,4.5517445,1175.007232,0.0,0.0,11.057,9.307,4.4237033,1304.887296,2.330624,0.0,9.72,9.395,4.1540773,1127.89504,0.0,0.0,10.643,9.199,4.0338664,1143.431168,0.0,0.0,9.479,9.541
1442575622000,11.8311041,1239.699456,2.777088,0.0,83.948,76.896,4.3772926,1176.100864,0.0,0.0,9.927,8.309,4.3438779,1303.949312,1.591296,0.0,9.334,7.714,4.0400149,1130.68032,0.0,0.0,11.54,10.882,3.818553,1143.902208,0.0,0.0,13.097,11.043
1442575682000,9.3930785,1240.3712,1.859584,0.0,67.05,62.415,3.4229302,1176.899584,0.0,0.0,8.795,8.279,3.5842316,1309.188096,1.978368,0.0,10.237,9.135,3.362073,1131.548672,0.0,0.0,8.167,7.483,2.8038368,1144.5248,0.0,0.0,9.92,8.066
1442575742000,11.777388,1242.35776,3.448832,0.0,76.06,70.467,3.0992196,1182.646272,0.0,0.0,7.723,7.023,4.6322689,1308.594176,2.607104,0.0,10.294,9.101,4.1949018,1130.12736,0.0,0.0,10.008,8.807,4.1147364,1145.749504,0.0,0.0,12.076,9.848
1442575802000,10.0446591,1245.5936,3.149824,0.0,71.409,67.046,3.4151588,1183.514624,0.0,0.0,10.086,8.115,3.553114,1309.986816,1.6384,0.0,8.363,7.935,3.858696,1129.967616,0.0,0.0,10.383,8.493,3.6083605,1145.274368,0.0,0.0,10.122,9.628
1442575862000,13.1829347,1238.58944,3.416064,0.0,83.157,78.169,4.6728494,1181.94176,0.0,0.0,9.363,8.237,4.7845486,1304.8832,1.337344,0.0,11.557,8.998,4.4888703,1123.680256,0.0,0.0,11.624,9.741,4.9933284,1137.655808,0.0,0.0,12.514,11.003
1442575923000,11.8916011,1237.610496,3.203072,0.0,85.165,79.669,4.1299627,1178.349568,0.0,0.0,10.827,9.491,3.9401764,1304.035328,1.769472,0.0,11.188,9.425,4.6639151,1128.603648,0.0,0.0,14.791,12.391,3.5217265,1132.957696,0.0,0.0,8.763,7.295
1442575983000,12.4438213,1237.438464,3.514368,0.0,81.617,76.647,4.7986737,1179.029504,0.0,0.0,10.833,9.382,3.6857433,1305.68192,1.5872,0.0,8.852,7.849,4.8971682,1130.496,0.0,0.0,12.512,11.514,5.3277071,1123.590144,0.0,0.0,12.036,9.24
1442576043000,14.3340186,1242.157056,3.596288,0.0,93.15,86.562,4.502062,1180.745728,0.0,0.0,12.108,9.896,5.2395446,1307.025408,1.855488,0.0,8.82,8.15,6.229458,1131.86816,0.0,0.0,14.447,12.705,5.1580328,1121.828864,0.0,0.0,13.54,10.69
1442576103000,10.8829537,1243.58656,3.11296,0.0,69.473,64.266,3.4510693,1182.879744,0.0,0.0,9.157,8.633,3.0289621,1308.708864,1.374208,0.0,7.552,6.966,3.4737618,1132.986368,0.0,0.0,10.318,9.245,3.8693527,1116.708864,0.0,0.0,8.514,8.192
1442576163000,13.7419994,1242.431488,3.64544,0.0,85.309,80.021,4.4292204,1180.61056,0.0,0.0,10.52,8.862,5.5669251,1308.860416,2.535424,0.0,11.153,9.329,5.4809193,1133.641728,0.0,0.0,11.747,10.217,4.3173614,1119.162368,0.0,0.0,12.353,10.838
1442576223000,12.3655507,1245.384704,4.284416,0.0,79.086,74.148,5.0036753,1181.425664,0.0,0.0,10.745,9.688,3.6532383,1307.967488,1.964032,0.0,8.258,7.578,4.5528295,1131.147264,0.0,0.0,11.493,8.703,4.0697985,1118.793728,0.0,0.0,11.532,10.698
1442576283000,13.5040164,1245.749248,3.833856,0.0,92.487,85.646,4.7762898,1181.990912,0.0,0.0,10.613,9.018,4.5341206,1307.693056,1.7408,0.0,9.966,9.251,4.7187593,1132.937216,0.0,0.0,12.363,11.302,5.1394818,1119.62112,0.0,0.0,14.894,13.366
1442576343000,13.4441876,1245.364224,4.153344,0.0,78.368,73.288,5.4574432,1181.945856,0.004096,0.0,11.084,9.544,3.6127831,1307.721728,1.908736,0.0,8.387,7.085,4.4408359,1131.134976,0.0,0.0,10.442,8.593,3.9459998,1117.933568,0.0,0.0,10.987,9.684
1442576403000,10.2139342,1247.318016,3.096576,0.0,64.727,60.83,3.6119168,1182.232576,0.0,0.0,6.879,6.535,4.1223602,1315.131392,2.103296,0.0,10.13,8.54,4.1030502,1130.160128,0.0,0.0,8.01,7.869,3.6380696,1115.512832,0.0,0.0,10.544,7.941
1442576463000,3.3932042,1227.165696,1.88416,0.0,49.763,47.281,1.470052,1176.399872,0.0,0.0,7.095,6.963,1.2343438,1297.69472,1.49504,0.0,6.529,5.764,1.2162711,1114.251264,0.0,0.0,8.479,6.432,1.0037565,1103.273984,0.0,0.0,6.557,5.31
1442576523000,10.8717737,1235.337216,3.821568,0.0,75.764,71.365,3.6974399,1184.477184,0.0,0.0,9.986,9.361,3.1804334,1305.853952,2.002944,0.0,10.009,8.23,4.0757982,1124.888576,0.0,0.0,10.32,8.58,4.1218245,1111.51104,0.0,0.0,10.658,8.837
1442576583000,10.9197596,1242.9312,2.433024,0.0,96.882,91.244,4.1384685,1187.598336,0.0,0.0,14.551,13.445,4.2905037,1306.537984,1.542144,0.0,9.463,7.963,4.347465,1123.807232,0.0,0.0,14.539,12.179,4.6604851,1111.625728,0.008192,0.0,13.369,11.349
1442576643000,10.1124897,1244.372992,5.873664,0.0,76.589,71.28,3.116484,1185.312768,0.0,0.0,8.007,7.368,3.462295,1307.344896,1.757184,0.0,9.066,7.79,4.2173208,1130.569728,0.0,0.0,10.55,9.877,3.6980587,1113.075712,0.0,0.0,11.383,9.816
1442576704000,11.23343,1244.147712,4.66944,0.0,75.651,71.22,4.3776266,1186.455552,0.0,0.0,8.738,7.878,4.0471558,1308.393472,2.238464,0.0,8.849,7.676,4.7100886,1134.14144,0.0,0.0,11.186,9.213,4.3244541,1112.244224,0.0,0.0,11.906,11.522
1442576764000,9.7393953,1247.9488,4.767744,0.0,79.601,73.34,2.7733403,1186.607104,0.0,0.0,8.425,6.929,3.7860424,1309.53216,1.90464,0.0,10.029,9.471,2.5771707,1134.587904,0.0,0.0,10.463,9.479,4.5776291,1118.707712,0.0,0.0,12.491,10.676
1442576825000,9.783685,1246.633984,5.013504,0.02048,59.16,54.824,3.5901299,1185.611776,0.0,0.0,7.155,6.783,3.4260324,1307.79136,2.18112,0.0,7.612,6.926,3.1786175,1133.502464,0.0,0.0,7.844,7.142,2.8381763,1114.943488,0.0,0.0,6.918,6.138
1442576885000,12.7080127,1246.355456,2.535424,0.032768,74.966,69.07,4.9028527,1186.2016,0.0,0.0,9.534,7.586,3.9199369,1308.577792,1.957888,0.0,6.895,6.408,5.0606356,1136.631808,0.0,0.0,12.285,10.834,4.7731546,1112.936448,0.004096,0.0,10.465,9.461
1442576945000,11.3592282,1246.384128,4.104192,0.016384,81.421,76.202,4.2885312,1186.267136,0.0,0.0,10.813,8.58,4.9943606,1308.901376,1.814528,0.0,9.788,8.463,4.4995814,1136.070656,0.0,0.0,12.001,9.941,3.7342622,1116.020736,0.0,0.0,11.3,10.097
1442577005000,9.1719008,1245.138944,2.27328,0.016384,62.999,59.108,3.8373274,1187.438592,0.0,0.0,8.303,7.299,2.9686398,1309.679616,1.988608,0.0,7.816,7.741,3.4967244,1134.968832,0.0,0.0,10.273,8.313,3.026295,1113.083904,0.0,0.0,8.944,7.813
1442577065000,12.0745547,1245.118464,2.883584,0.028672,75.423,70.548,4.7936359,1186.279424,0.0,0.0,9.27,7.686,3.8693466,1313.652736,2.756608,0.0,9.62,9.096,4.626689,1132.732416,0.0,0.0,10.991,8.634,4.4147419,1116.545024,0.0,0.0,11.018,9.144
1442577125000,11.8312153,1240.576,2.125824,0.028672,65.785,61.554,4.7633239,1183.879168,0.0,0.0,8.808,7.986,3.9606668,1302.233088,1.306624,0.0,7.333,6.345,4.0259032,1130.586112,0.0,0.0,10.184,9.172,3.6642011,1106.395136,0.0,0.0,9.628,7.817
1442577185000,9.3467929,1241.899008,2.097152,0.016384,58.084,54.205,3.166095,1188.974592,0.0,0.0,6.235,5.943,3.3185118,1304.137728,1.685504,0.0,7.148,6.424,3.3122597,1130.344448,0.0,0.0,7.317,6.824,4.1623498,1106.137088,0.0,0.0,10.576,8.941
1442577245000,10.8824594,1245.179904,3.584,0.028672,75.475,70.473,4.0689411,1189.888,0.0,0.0,8.863,7.723,4.6061771,1306.157056,2.03776,0.0,10.151,9.119,3.8073298,1127.7312,0.0,0.0,11.777,9.195,4.3702089,1107.222528,0.0,0.0,9.771,8.327
1442577305000,7.4099587,1246.396416,3.817472,0.028672,68.673,64.32,2.3613064,1190.600704,0.0,0.0,7.131,6.471,2.9891469,1305.878528,2.267136,0.0,8.39,8.017,2.9467502,1134.927872,0.0,0.0,9.737,8.12,2.7340446,1098.846208,0.0,0.0,10.516,8.868
1442577365000,10.9302004,1246.212096,4.022272,0.016384,76.178,72.196,3.7143801,1190.07232,0.0,0.0,10.188,8.293,3.8027509,1305.6,1.409024,0.0,8.987,7.246,4.5226566,1137.463296,0.032768,0.0,12.436,10.879,4.1319797,1106.837504,0.0,0.0,10.179,8.244
1442577425000,9.1531801,1246.183424,3.395584,0.028672,73.602,68.618,3.2416061,1191.964672,0.0,0.0,8.45,8.673,3.6179499,1305.899008,1.677312,0.0,9.126,7.849,3.3110942,1136.713728,0.0,0.0,11.105,9.408,2.9174707,1105.145856,0.0,0.0,9.356,8.622
1442577485000,9.4454219,1250.758656,2.646016,0.008192,63.247,58.854,3.5895298,1195.012096,0.0,0.0,6.699,5.882,2.9063141,1308.823552,1.406976,0.0,7.954,6.442,2.9520268,1142.45632,0.0,0.0,9.026,8.162,3.3864633,1109.42208,0.0,0.0,9.551,8.428
1442577545000,9.0135665,1252.151296,2.80576,0.016384,61.338,57.502,3.0036511,1193.71776,0.0,0.0,8.28,7.391,3.1217997,1308.418048,1.841152,0.0,6.651,6.379,3.282441,1140.588544,0.0,0.0,7.189,7.135,3.0321589,1110.044672,0.0,0.0,9.603,9.029
1442577605000,10.3182084,1248.759808,3.305472,0.024576,73.744,69.531,4.8156088,1192.931328,0.0,0.0,10.399,9.275,3.1942724,1307.19744,1.871872,0.0,8.232,7.129,3.6561703,1138.85184,0.0,0.0,11.196,9.279,3.9511936,1100.959744,0.0,0.0,10.215,8.768
1442577666000,10.4843675,1250.234368,3.72736,0.032768,72.451,67.805,4.474136,1192.886272,0.0,0.0,9.465,8.879,3.3878555,1307.140096,1.39264,0.0,9.605,8.485,4.4226798,1139.642368,0.0,0.0,9.13,7.892,4.1010739,1102.249984,0.0,0.0,10.474,8.775
1442577726000,12.1208057,1247.236096,2.400256,0.02048,80.341,74.973,4.6176436,1193.136128,0.0,0.0,9.993,8.839,4.1285609,1302.618112,1.46432,0.0,8.937,7.648,5.3114192,1133.129728,0.0,0.0,11.941,10.687,5.0903466,1088.053248,0.0,0.0,12.234,9.941
1442577786000,10.912504,1247.1296,3.354624,0.016384,82.81,78.454,4.2550824,1192.259584,0.0,0.0,12.199,9.392,3.9608658,1299.595264,1.585152,0.0,10.884,9.693,4.3642033,1134.30528,0.0,0.0,11.504,9.883,4.2694129,1085.366272,0.0,0.0,11.166,9.602
1442577846000,12.6094653,1248.768,2.932736,0.02048,77.771,73.363,5.3357341,1202.93376,0.0,0.0,8.346,8.67,3.7260328,1301.676032,1.636352,0.0,10.268,8.256,5.0255436,1132.31872,0.0,0.0,12.339,10.743,4.84008,1088.208896,0.032768,0.0,12.015,9.301
1442577906000,10.5110837,1251.0208,2.048,0.02048,76.008,70.121,4.4025162,1202.700288,0.0,0.0,10.092,9.132,3.8843179,1302.003712,1.472512,0.0,8.07,6.806,3.6801987,1132.879872,0.0,0.0,8.97,8.647,3.9685165,1088.43008,0.0,0.0,13.124,11.654
1442577966000,11.7333754,1252.564992,3.637248,0.016384,77.476,71.301,3.5109902,1202.36032,0.0,0.0,9.369,7.804,4.1470154,1302.695936,1.46432,0.0,8.163,7.37,4.1977533,1132.93312,0.0,0.0,11.027,10.462,3.8892134,1087.393792,0.0,0.0,11.356,9.663
1442578026000,11.0229379,1251.45088,2.555904,0.028672,71.648,66.745,3.8053429,1203.331072,0.0,0.0,8.438,7.757,3.5529176,1302.417408,1.214464,0.0,9.561,8.054,3.8888779,1138.368512,0.0,0.0,10.109,9.543,3.7910217,1085.415424,0.0,0.0,10.291,8.816
1442578087000,9.0822912,1253.72416,3.346432,0.0,61.514,57.152,2.6470106,1202.253824,0.0,0.0,7.152,6.694,3.4688149,1303.261184,1.88416,0.0,8.103,7.348,3.3683439,1138.970624,0.0,0.0,9.468,7.869,3.1834553,1083.420672,0.0,0.0,7.265,7.128
1442578147000,9.7493337,1253.883904,3.764224,0.0,74.601,71.184,3.4012344,1203.429376,0.0,0.0,9.527,8.498,3.0655254,1303.617536,1.69984,0.0,7.96,6.542,3.7324754,1136.5376,0.0,0.0,10.104,7.788,3.8133928,1083.22816,0.0,0.0,13.161,10.14
1442578207000,11.1193133,1255.718912,3.919872,0.0,63.859,59.841,4.7472252,1202.638848,0.0,0.0,8.17,7.575,2.781004,1302.777856,1.056768,0.0,6.909,6.364,3.8417253,1137.082368,0.0,0.0,9.971,9.171,4.4366499,1083.285504,0.0,0.0,8.688,7.146
1442578267000,10.9526938,1255.677952,2.850816,0.0,69.874,65.52,3.8117046,1203.613696,0.0,0.0,9.936,8.814,3.9319008,1303.199744,1.501184,0.0,8.714,7.434,3.4970374,1134.370816,0.0,0.0,9.381,7.368,4.5525609,1085.718528,0.0,0.0,10.822,8.845
1442578327000,10.284778,1253.154816,4.214784,0.0,69.38,64.961,4.6470797,1200.488448,0.0,0.0,10.331,8.519,4.3008111,1298.653184,1.327104,0.0,8.963,7.409,4.0743264,1132.433408,0.0,0.0,8.133,6.985,3.4048375,1076.822016,0.032768,0.0,9.258,9.069
1442578387000,10.2880263,1251.131392,2.859008,0.004096,71.5,66.806,4.1509906,1200.0256,0.0,0.0,9.98,9.233,2.1797401,1296.109568,1.204224,0.0,5.175,4.682,4.1295291,1132.224512,0.0,0.0,12.643,10.623,3.8666912,1073.860608,0.0,0.0,10.315,8.967
1442578447000,8.5000674,1257.10336,3.387392,0.0,78.593,73.893,3.146102,1201.209344,0.0,0.0,10.053,8.291,2.8151789,1299.546112,1.763328,0.0,8.736,7.404,3.8172366,1133.744128,0.0,0.0,11.454,9.437,3.2603953,1073.31584,0.0,0.0,11.956,10.574
1442578508000,11.5173651,1253.965824,2.715648,0.0,70.474,66.419,3.4803103,1201.266688,0.0,0.0,9.124,8.919,3.9985175,1298.18624,1.492992,0.0,9.88,7.773,4.0288163,1131.913216,0.0,0.0,9.559,8.131,4.5951674,1071.849472,0.0,0.0,10.675,9.855
1442578568000,12.7528882,1258.491904,3.371008,0.0,84.142,78.852,5.5767607,1214.27968,0.0,0.0,11.317,9.056,3.5584612,1306.816512,1.292288,0.0,7.708,7.149,5.8304632,1134.297088,0.0,0.0,12.396,9.834,5.5558871,1073.410048,0.065536,0.0,14.377,12.076
1442578628000,11.6382223,1257.320448,3.87072,0.0,87.574,81.637,3.7404709,1212.489728,0.0,0.0,9.37,7.933,4.0975107,1305.21088,1.316864,0.0,9.273,7.47,4.7250942,1133.99808,0.0,0.0,13.771,11.604,4.9052817,1081.716736,0.0,0.0,13.591,11.874
1442578688000,12.0174092,1254.93248,2.53952,0.0,88.833,83.052,5.3692795,1214.03392,0.0,0.0,12.529,10.066,4.7728606,1304.879104,1.630208,0.0,9.707,7.841,4.489026,1135.157248,0.0,0.0,11.667,9.814,4.6853078,1083.047936,0.0,0.0,14.341,13.284
1442578748000,11.7753555,1256.747008,4.58752,0.0,79.793,74.894,3.5971239,1213.554688,0.0,0.0,8.768,7.943,3.4906368,1306.238976,1.357824,0.0,7.675,6.447,4.6817591,1135.730688,0.0,0.0,11.804,11.61,4.4336965,1083.252736,0.0,0.0,13.295,10.768
1442578808000,10.9842949,1257.39008,3.928064,0.0,88.432,82.949,5.764729,1213.44,0.0,0.0,12.809,10.682,4.9971526,1305.88672,1.820672,0.0,10.3,8.825,5.5422849,1141.53472,0.0,0.0,13.436,11.049,5.4048994,1081.475072,0.0,0.0,12.584,10.558
1442578868000,13.0662773,1255.419904,3.76832,0.0,76.041,70.75,4.6614442,1213.968384,0.0,0.0,8.712,8.136,4.1545843,1304.65792,1.513472,0.0,10.437,9.165,4.6787804,1140.871168,0.0,0.0,10.513,9.434,5.2911787,1081.729024,0.0,0.0,10.324,10.138
1442578928000,13.2156262,1252.093952,2.64192,0.0,82.873,77.58,4.685411,1211.04384,0.0,0.0,10.085,9.201,5.6230571,1302.274048,2.459648,0.0,12.666,10.591,5.0479938,1138.839552,0.0,0.0,11.663,9.426,5.5581108,1073.164288,0.0,0.0,10.596,9.568
1442578988000,12.1967111,1253.76512,3.137536,0.0,76.74,70.709,3.4280195,1212.510208,0.0,0.0,9.296,8.124,3.2451718,1299.51744,2.162688,0.0,6.899,6.94,5.1383101,1139.34336,0.0,0.0,11.532,10.737,4.9565338,1075.556352,0.0,0.0,11.481,10.149
1442579048000,11.9921869,1255.636992,3.698688,0.0,81.724,76.572,4.7382937,1217.241088,0.0,0.0,13.081,10.958,3.9229391,1300.897792,1.855488,0.0,9.831,8.622,4.376792,1138.81088,0.0,0.0,8.94,7.692,4.5500464,1069.252608,0.0,0.0,11.069,10.071
1442579108000,11.5778305,1257.680896,3.674112,0.0,73.536,69.749,4.1973974,1213.689856,0.0,0.0,9.772,8.65,4.765482,1302.540288,2.36544,0.0,10.288,9.063,4.2960199,1139.941376,0.0,0.0,10.95,9.868,3.7243013,1067.671552,0.0,0.0,7.492,6.626
1442579168000,12.5517185,1256.751104,2.281472,0.0,73.916,68.667,5.0026087,1213.82912,0.0,0.0,9.884,8.305,4.6210603,1301.44256,1.81248,0.0,9.306,8.445,3.8427496,1140.842496,0.0,0.0,12.082,10.881,4.1741084,1067.80672,0.0,0.0,9.807,8.5
1442579228000,10.6676617,1257.291776,4.349952,0.0,65.882,61.56,4.8378734,1214.877696,0.0,0.0,10.837,9.251,3.0693278,1300.447232,1.181696,0.0,6.602,5.639,3.2642642,1141.8624,0.0,0.0,7.765,7.583,3.9193063,1069.940736,0.032768,0.0,9.772,9.557
1442579288000,12.6990095,1258.848256,2.883584,0.0,87.866,82.225,5.3443122,1219.067904,0.0,0.0,14.873,12.814,4.0025789,1305.76384,1.511424,0.0,9.493,8.413,4.7469163,1140.912128,0.0,0.0,10.718,9.582,4.5506766,1070.235648,0.0,0.0,12.589,10.534
1442579348000,12.2191207,1260.539904,3.31776,0.0,86.255,80.848,3.0461425,1217.724416,0.0,0.0,9.241,7.12,5.32896,1304.9856,1.558528,0.0,12.451,9.65,4.5556694,1139.826688,0.0,0.0,12.824,11.712,4.745643,1073.860608,0.0,0.0,11.004,9.821
1442579408000,12.0486633,1259.700224,2.408448,0.0,82.1,76.812,5.4223822,1219.15392,0.0,0.0,11.288,10.692,4.0328446,1304.723456,1.78176,0.0,10.89,9.049,4.1266727,1139.912704,0.0,0.0,11.815,10.324,4.2517608,1074.05312,0.0,0.0,10.501,9.314
1442579468000,10.6756413,1263.644672,4.210688,0.0,63.631,59.613,4.8848686,1221.070848,0.0,0.0,8.163,7.577,4.0169285,1307.168768,1.886208,0.0,9.612,8.393,3.6662665,1147.318272,0.0,0.0,7.101,6.761,3.2619367,1076.281344,0.0,0.0,8.561,7.537
1442579528000,13.0714371,1254.289408,3.534848,0.0,81.155,76.412,5.7688431,1218.06848,0.0,0.0,12.118,9.591,5.6156233,1304.031232,1.714176,0.0,11.526,9.259,4.9695666,1145.102336,0.0,0.0,10.763,9.38,4.021703,1074.597888,0.004096,0.0,10.624,9.178
1442579588000,12.771401,1253.605376,2.715648,0.0,93.324,88.094,4.7355796,1214.713856,0.0,0.0,11.97,10.901,4.7464906,1302.212608,1.861632,0.0,11.817,10.495,4.6062984,1143.291904,0.0,0.0,11.348,9.739,5.4051006,1071.689728,0.0,0.0,15.47,13.137
1442579648000,10.3835115,1256.689664,2.043904,0.0,74.183,69.07,3.8731854,1217.466368,0.0,0.0,9.424,8.16,3.2826601,1305.018368,2.12992,0.0,9.11,8.449,3.7990623,1143.76704,0.0,0.0,10.021,8.516,3.5728667,1072.361472,0.0,0.0,11.569,10.078
1442579708000,11.4066868,1255.632896,2.945024,0.0,81.133,76.643,5.1351737,1220.235264,0.0,0.0,10.64,9.825,4.01962,1305.604096,1.337344,0.0,9.111,7.219,3.9991016,1144.09472,0.0,0.0,12.122,11.104,4.3403836,1073.999872,0.0,0.0,12.21,10.756
1442579769000,11.1781037,1259.2128,4.1984,0.0,76.786,72.203,3.8885142,1219.13344,0.0,0.0,10.223,8.059,4.130374,1304.522752,1.554432,0.0,10.226,8.635,3.6442934,1142.972416,0.0,0.0,11.063,9.235,3.9345544,1074.126848,0.0,0.0,9.754,9.358
1442579830000,11.7068059,1261.764608,3.108864,0.0,79.563,73.461,4.9162388,1221.61152,0.0,0.0,10.183,9.008,4.8121912,1306.968064,1.458176,0.01024,9.745,7.613,4.2333591,1142.337536,0.0,0.0,8.829,7.959,4.120392,1080.037376,0.0,0.0,12.573,10.736
1442579890000,11.1932727,1260.50304,2.564096,0.0,69.959,65.138,4.1183492,1219.432448,0.0,0.0,8.908,8.056,4.075243,1305.133056,1.482752,0.006144,8.809,7.372,3.7448068,1141.075968,0.0,0.0,8.936,7.779,3.6166383,1074.9952,0.0,0.0,11.438,9.592
1442579950000,11.8174851,1256.923136,2.330624,0.0,75.366,70.836,4.0931069,1218.297856,0.0,0.0,11.874,9.734,3.8391188,1305.858048,2.002944,0.008192,9.2,8.566,4.2937177,1143.816192,0.0,0.0,9.577,7.926,3.9075595,1074.413568,0.0,0.0,10.492,9.042
1442580010000,11.4707211,1259.061248,2.12992,0.0,74.602,69.648,4.8111731,1222.148096,0.0,0.0,11.509,9.909,3.6907155,1304.076288,1.159168,0.012288,7.965,6.439,4.4882397,1141.0432,0.0,0.0,11.138,9.467,4.0923224,1073.659904,0.0,0.0,10.243,9.29
1442580070000,9.9584934,1260.797952,4.227072,0.0,77.812,72.748,3.2925956,1223.098368,0.0,0.0,9.966,8.585,3.2534443,1304.788992,1.060864,0.012288,8.013,5.807,3.760115,1142.829056,0.0,0.0,9.95,9.929,3.7931646,1076.396032,0.0,0.0,12.965,11.076
1442580131000,8.8509731,1255.64928,2.301952,0.0,53.207,49.959,2.9628679,1221.005312,0.0,0.0,6.515,5.624,3.0375736,1302.884352,1.746944,0.008192,5.783,5.068,3.409485,1138.372608,0.0,0.0,9.359,8.682,3.5287333,1075.75296,0.0,0.0,8.223,7.282
1442580192000,11.3719375,1260.412928,2.809856,0.0,87.504,81.34,4.0301935,1219.350528,0.0,0.0,10.836,9.114,3.3990439,1301.086208,1.654784,0.0,9.288,7.989,4.5087883,1140.744192,0.0,0.0,12.84,11.282,4.7011802,1074.880512,0.0,0.0,13.725,11.877
1442580252000,11.3287442,1261.461504,4.8128,0.0,79.859,74.363,4.1305988,1220.595712,0.0,0.0,9.161,7.624,4.6069124,1304.457216,2.236416,0.0,9.877,8.423,4.5191701,1147.478016,0.0,0.0,9.527,8.868,3.5527191,1074.679808,0.0,0.0,13.839,10.89
1442580313000,10.4967346,1263.464448,4.112384,0.0,78.517,73.086,4.182141,1220.882432,0.0,0.0,11.671,10.236,2.8904423,1304.199168,1.742848,0.0,8.449,7.39,3.8184156,1150.062592,0.0,0.0,9.694,8.334,3.7587889,1074.958336,0.0,0.0,11.732,10.091
1442580373000,11.1957576,1265.197056,4.882432,0.0,81.931,77.633,4.1111409,1221.640192,0.0,0.0,12.325,10.594,2.8250972,1304.178688,1.652736,0.0,8.164,7.417,3.6277756,1146.187776,0.0,0.0,10.5,8.648,5.0803031,1077.342208,0.0,0.0,12.34,10.951
1442580433000,12.5743438,1264.283648,3.723264,0.0,96.477,89.431,5.4308029,1222.684672,0.0,0.0,14.393,12.186,4.9126047,1303.949312,2.03776,0.0,10.84,9.364,5.0879764,1146.642432,0.0,0.0,13.477,10.93,4.759976,1077.321728,0.0,0.0,12.557,11.216
1442580493000,11.1426688,1266.700288,3.432448,0.0,87.721,82.127,3.9705239,1224.155136,0.0,0.0,11.405,9.447,4.6784295,1306.578944,2.533376,0.0,12.368,11.308,4.1197724,1143.758848,0.0,0.0,11.501,10.237,3.944392,1077.59616,0.0,0.0,11.656,10.306
1442580553000,10.4773589,1267.290112,4.911104,0.0,84.105,78.742,4.0290835,1221.083136,0.0,0.0,10.869,8.4,4.0428627,1304.346624,1.4336,0.0,11.603,9.269,3.7798418,1144.75008,0.0,0.0,11.196,10.307,4.1714448,1076.670464,0.0,0.0,10.144,9.113
1442580613000,11.4889282,1272.098816,4.58752,0.0,82.572,77.155,3.3036162,1223.376896,0.0,0.0,7.872,7.028,4.6875641,1306.099712,1.800192,0.0,11.98,10.213,4.9062923,1147.478016,0.0,0.0,11.506,10.037,4.5229033,1077.20704,0.0,0.0,13.043,11.17
1442580673000,10.8162231,1266.184192,3.6864,0.0,78.549,74.433,4.5064362,1220.79232,0.0,0.0,10.774,8.459,4.1815784,1304.199168,1.687552,0.0,9.607,8.255,4.2466864,1143.361536,0.0,0.0,10.683,10.03,5.1850362,1078.689792,0.0,0.0,11.527,9.906
1442580734000,4.0670024,1254.924288,0.704512,0.0,12.957,11.967,1.6742873,1213.70624,0.0,0.0,2.423,2.16,1.4156114,1295.171584,0.376832,0.0,1.989,2.018,1.3185689,1134.587904,0.0,0.0,2.036,2.089,1.2862133,1072.279552,0.0,0.0,2.822,2.583
1442580794000,9.9783248,1262.317568,3.547136,0.0,74.94,70.152,2.8223122,1219.555328,0.0,0.0,8.745,7.293,3.9156996,1301.377024,1.14688,0.0,9.474,7.419,4.1796442,1145.97888,0.0,0.0,10.045,9.747,4.4477184,1076.813824,0.0,0.0,12.228,11.017
1442580854000,10.4013442,1265.201152,3.985408,0.0,75.41,71.386,3.5755953,1222.836224,0.0,0.0,11.6,9.114,4.2055596,1304.215552,1.841152,0.0,8.923,8.126,3.7537016,1147.580416,0.0,0.0,10.51,8.631,4.4024184,1077.415936,0.0,0.0,9.637,8.648
1442580914000,8.6501424,1264.771072,3.244032,0.0,72.691,68.568,3.4431681,1224.27392,0.032768,0.0,10.91,9.245,2.3859968,1304.481792,1.404928,0.0,8.5,6.95,3.5127487,1150.0544,0.0,0.0,10.428,9.364,3.0270027,1077.374976,0.0,0.0,10.461,9.008
1442580974000,12.1227122,1266.405376,2.891776,0.0,76.79,71.384,5.1070658,1224.138752,0.0,0.0,9.523,8.412,4.3710772,1303.199744,1.624064,0.0,9.409,8.7,4.4737405,1151.2832,0.0,0.0,11.5,8.757,4.521729,1077.792768,0.0,0.0,11.374,9.713
1442581034000,11.1425103,1266.327552,2.465792,0.0,79.537,74.013,4.2026202,1223.737344,0.0,0.0,11.02,8.891,4.1201498,1304.264704,1.611776,0.0,7.798,7.248,4.5127241,1153.622016,0.0,0.0,11.616,10.933,4.0570153,1079.99232,0.0,0.0,11.791,10.104
1442581094000,9.694569,1271.066624,4.95616,0.0,76.017,70.86,3.5072443,1223.86432,0.0,0.0,10.281,8.975,3.6176892,1303.973888,1.169408,0.0,7.82,7.487,3.6740385,1153.097728,0.0,0.0,11.436,10.097,3.38118,1079.627776,0.0,0.0,10.737,8.857
1442581154000,10.6615412,1268.867072,3.01056,0.0,62.966,58.185,3.7554526,1223.634944,0.0,0.0,7.459,6.154,2.8853136,1305.550848,1.421312,0.0,6.536,6.171,4.0798732,1151.811584,0.0,0.0,9.298,7.638,3.8698593,1079.578624,0.0,0.0,10.489,9.691
1442581214000,9.5447443,1271.246848,4.108288,0.0,67.157,63.191,3.1733561,1223.049216,0.0,0.0,7.768,6.461,3.272595,1304.162304,1.550336,0.0,7.507,6.864,4.1967097,1151.52896,0.0,0.0,11.982,9.618,4.0320095,1079.607296,0.0,0.0,9.466,8.244
1442581274000,12.7816723,1269.661696,3.145728,0.0,86.445,80.846,3.8976688,1223.630848,0.0,0.0,11.02,9.064,4.7298043,1304.608768,1.814528,0.0,9.422,8.16,4.4177778,1147.662336,0.0,0.0,11.905,9.754,4.9054035,1081.110528,0.0,0.0,14.149,12.86
1442581334000,10.0428392,1271.996416,3.948544,0.0,66.07,62.048,3.5449561,1227.255808,0.0,0.0,8.104,6.561,4.4379709,1312.4608,2.531328,0.0,9.322,8.32,2.6443849,1147.94496,0.0,0.0,6.821,6.915,3.6521332,1079.308288,0.0,0.0,10.772,9.046
1442581394000,1.9393317,1262.698496,0.38912,0.0,29.386,26.831,0.4620929,1225.928704,0.0,0.0,3.351,3.249,0.7033927,1302.769664,0.602112,0.0,4.001,3.213,0.6762724,1150.50496,0.0,0.0,4.225,3.909,40.9902456,1076.731904,0.806912,0.0,5.038,4.689
1442581454000,9.6365999,1268.191232,3.756032,0.0,75.771,70.51,3.7258012,1225.6256,0.0,0.0,9.392,8.065,3.383029,1303.232512,1.642496,0.0,9.58,8.523,3.4274648,1146.400768,0.0,0.0,11.612,10.065,3.2767054,1081.581568,0.0,0.0,9.452,8.956
1442581515000,10.2499406,1269.579776,2.557952,0.004096,77.081,72.0,4.0741564,1225.940992,0.0,0.0,10.143,8.569,3.0611232,1303.396352,1.31072,0.0,7.628,6.626,4.082142,1143.508992,0.0,0.0,11.211,9.589,3.9575109,1078.5792,0.0,0.0,12.045,10.955
1442581576000,9.4033296,1271.406592,2.727936,0.032768,68.378,64.008,3.4685163,1228.599296,0.065536,0.0,10.731,8.935,3.3294629,1306.820608,1.636352,0.0,7.302,6.719,3.2564595,1143.816192,0.0,0.0,9.106,7.899,3.4343933,1078.730752,0.0,0.0,10.471,9.055
1442581636000,9.2417135,1276.071936,1.974272,0.012288,65.616,60.924,3.3968049,1227.767808,0.0,0.0,9.067,8.182,2.9674793,1306.607616,1.31072,0.0,6.9,5.932,3.4553296,1152.401408,0.0,0.0,10.219,9.247,3.108715,1077.559296,0.0,0.0,9.111,7.919
1442581696000,10.6949428,1279.40608,4.440064,0.028672,74.3,69.699,4.319293,1229.594624,0.0,0.0,9.579,8.227,4.732513,1305.972736,1.562624,0.0,10.004,8.236,4.0117993,1151.168512,0.0,0.0,9.297,7.686,3.7289795,1081.757696,0.0,0.0,10.333,9.288
1442581756000,10.9539354,1276.899328,2.965504,0.02048,68.063,62.912,3.9911794,1228.517376,0.0,0.0,10.147,9.397,2.7550666,1304.113152,1.150976,0.0,6.609,6.174,4.3442737,1150.836736,0.0,0.0,10.65,9.363,3.0139075,1077.829632,0.0,0.0,8.421,7.097
1442581816000,10.7449956,1278.844928,3.321856,0.004096,71.306,66.468,4.1790826,1227.169792,0.0,0.0,9.619,8.403,3.4076257,1304.256512,1.265664,0.0,8.179,6.947,4.0996036,1151.954944,0.0,0.0,9.938,7.558,3.2700926,1079.640064,0.0,0.0,10.836,10.318
1442581877000,10.152421,1279.643648,4.73088,0.004096,69.295,64.984,2.8238172,1227.403264,0.004096,0.0,6.973,5.672,3.5845983,1306.99264,1.88416,0.0,9.196,7.533,3.4394667,1154.404352,0.0,0.0,8.476,7.224,4.0266671,1082.109952,0.0,0.0,10.856,10.128
1442581938000,12.0834949,1277.833216,2.29376,0.0,76.408,71.445,4.0404725,1229.131776,0.0,0.0,10.506,9.131,4.1166471,1304.383488,1.947648,0.0,8.212,7.711,4.2345199,1155.366912,0.0,0.0,12.195,10.934,5.0920679,1081.872384,0.0,0.0,10.85,9.374
1442581998000,13.2324948,1270.116352,3.575808,0.0,88.958,82.629,4.8492901,1218.691072,0.0,0.0,11.315,9.674,4.8166759,1298.927616,1.306624,0.0,10.225,7.979,4.6191882,1156.665344,0.0,0.0,14.258,12.105,4.9549177,1078.083584,0.0,0.0,12.346,10.768
1442582058000,13.2501152,1273.147392,3.25632,0.0,70.012,65.637,5.3947035,1223.712768,0.0,0.0,11.809,9.458,3.9020773,1302.720512,1.794048,0.0,6.795,6.57,4.0281039,1154.646016,0.0,0.0,9.486,7.67,4.9018112,1077.211136,0.032768,0.0,9.14,9.031
1442582118000,11.8624817,1273.89696,3.87072,0.0,82.361,77.293,4.8835162,1224.515584,0.0,0.0,11.502,9.718,3.5468659,1302.315008,1.067008,0.0,8.701,6.916,4.2490731,1153.814528,0.0,0.0,11.418,10.59,3.8192404,1079.369728,0.032768,0.0,12.89,11.12
1442582178000,12.2708948,1273.99936,1.814528,0.0,72.357,68.307,5.2864252,1235.488768,0.065536,0.0,12.385,11.17,3.4469865,1302.061056,1.472512,0.0,6.979,5.994,4.1515497,1150.578688,0.0,0.0,9.882,7.976,4.5105082,1087.504384,0.0,0.0,11.429,9.442
1442582238000,13.7363388,1276.342272,3.391488,0.0,80.809,75.463,5.1252991,1233.543168,0.0,0.0,11.204,9.327,3.5509154,1311.88736,0.776192,0.0,8.4,6.54,5.6396129,1152.0,0.0,0.0,13.248,11.852,5.6384312,1087.737856,0.0,0.0,12.034,10.256
1442582298000,11.3952532,1277.550592,3.076096,0.0,79.002,73.5,4.389474,1232.396288,0.0,0.0,10.788,9.051,4.8505382,1310.498816,1.800192,0.0,10.605,9.108,3.256411,1151.758336,0.0,0.0,9.2,8.518,4.3033533,1087.492096,0.0,0.0,11.689,9.138
1442582358000,12.3444123,1279.05792,4.25984,0.0,80.024,75.209,4.327963,1231.556608,0.0,0.0,9.85,8.522,4.3577766,1309.229056,1.67936,0.0,8.729,7.754,5.0804182,1150.763008,0.0,0.0,13.525,11.885,4.0123211,1086.529536,0.0,0.0,9.573,8.364
1442582418000,12.0942048,1280.83968,3.592192,0.0,74.95,69.471,4.650623,1230.700544,0.0,0.0,10.185,8.684,5.4402517,1311.117312,1.95584,0.0,9.133,8.843,4.774605,1160.650752,0.012288,0.0,10.03,8.148,3.1934417,1087.50848,0.0,0.0,12.029,10.269
1442582478000,11.8702765,1279.832064,2.80576,0.0,67.053,62.679,4.7386542,1232.367616,0.0,0.0,9.739,8.31,3.5352703,1311.035392,2.101248,0.0,8.017,7.57,3.9471083,1161.097216,0.0,0.0,8.647,7.121,3.9562449,1088.708608,0.0,0.0,9.033,8.122
1442582538000,15.716352,1283.678208,4.855808,0.0,55.293,52.07,2.8998533,1232.338944,0.0,0.0,7.021,6.182,2.4937713,1310.02368,1.253376,0.0,7.168,6.0,2.8543497,1162.088448,0.0,0.0,8.89,7.791,3.0255523,1087.336448,0.0,0.0,9.043,7.875
1442582599000,10.0969017,1274.785792,2.953216,0.0,74.366,70.375,2.8010003,1226.223616,0.0,0.0,9.372,8.865,2.9796909,1303.240704,1.077248,0.0,7.551,6.417,4.3372164,1158.18496,0.0,0.0,9.833,8.963,4.4619042,1081.991168,0.0,0.0,13.514,12.24
1442582659000,9.7869091,1277.05088,3.002368,0.0,79.174,74.791,3.687074,1226.944512,0.0,0.0,10.418,8.771,3.8164849,1305.858048,1.785856,0.0,11.298,9.115,2.772601,1158.332416,0.0,0.0,9.904,8.641,3.158047,1079.1936,0.0,0.0,11.387,10.793
1442582720000,52.1111198,1281.241088,3.436544,0.0,51.157,47.5,1.1042106,1229.205504,0.0,0.0,7.115,6.139,0.6075131,1307.557888,1.316864,0.0,6.547,5.926,0.9897192,1156.247552,0.0,0.0,8.16,6.586,0.8658138,1081.335808,0.0,0.0,7.05,5.592
1442582780000,9.9738668,1283.948544,4.415488,0.0,70.587,67.056,3.567944,1230.913536,0.0,0.0,9.45,8.909,3.6367659,1309.544448,1.90464,0.0,8.131,6.927,3.6064631,1160.638464,0.0,0.0,10.812,8.619,3.8184592,1078.505472,0.0,0.0,10.754,8.625
1442582840000,10.5287492,1284.214784,5.193728,0.0,70.873,66.709,4.1286801,1229.606912,0.0,0.0,8.611,7.476,3.9206177,1308.0576,2.054144,0.0,8.221,7.426,3.6667023,1158.47168,0.0,0.0,10.24,8.885,3.4340051,1080.754176,0.0,0.0,10.692,9.207
1442582900000,10.1346332,1280.385024,2.338816,0.0,82.956,77.598,4.1028903,1236.50048,0.0,0.0,10.887,9.54,3.9047537,1308.901376,2.019328,0.0,11.255,9.143,3.535171,1157.869568,0.0,0.0,9.658,8.234,3.8390903,1084.243968,0.0,0.0,12.983,11.445
1442582960000,7.0999791,1281.081344,1.470464,0.0,36.401,33.778,2.3880985,1235.98848,0.0,0.0,4.994,3.731,19.3932707,1315.704832,5.330944,0.0,4.254,3.732,2.813671,1164.275712,0.0,0.0,5.499,5.262,2.6141219,1086.332928,0.0,0.0,4.743,4.8
1442583020000,8.4089007,1280.59392,4.206592,0.0,64.78,60.881,2.4608467,1237.8112,0.0,0.0,6.943,6.343,3.3131228,1315.45088,1.67936,0.0,8.557,7.601,3.5563373,1159.069696,0.0,0.0,11.594,9.92,2.8255811,1085.325312,0.036864,0.0,8.027,7.835
1442583080000,11.2465804,1280.888832,2.359296,0.0,71.78,67.268,4.3516067,1238.482944,0.0,0.0,10.781,9.38,3.6734166,1314.971648,2.256896,0.0,9.488,7.991,4.0741837,1156.77184,0.0,0.0,9.682,8.828,3.3634814,1083.170816,0.0,0.0,9.133,7.297
1442583140000,10.0755897,1284.030464,2.469888,0.0,81.383,74.996,3.7870674,1236.987904,0.0,0.0,11.206,10.418,3.7262697,1314.28352,1.712128,0.0,9.448,8.051,3.2215564,1163.599872,0.0,0.0,10.524,8.841,3.3451963,1080.64768,0.0,0.0,11.657,10.747
1442583200000,11.7839687,1278.418944,3.981312,0.0,82.762,78.369,4.8354795,1229.43488,0.032768,0.0,12.426,10.416,4.2192085,1302.167552,1.007616,0.0,8.935,7.27,4.3495085,1158.234112,0.0,0.0,11.739,10.418,5.184698,1079.709696,0.0,0.0,11.919,10.58
1442583260000,11.2074372,1285.042176,3.190784,0.0,78.273,72.543,3.7773333,1232.613376,0.0,0.0,9.133,8.354,3.948105,1306.652672,1.273856,0.0,8.702,7.175,4.3287815,1162.62912,0.0,0.0,10.237,8.887,3.8892417,1075.990528,0.0,0.0,11.288,9.859
1442583320000,9.5308123,1284.808704,2.818048,0.0,81.746,77.411,3.4528143,1231.753216,0.032768,0.0,10.512,8.608,3.7108834,1307.05408,2.318336,0.0,10.111,8.797,3.7276501,1162.010624,0.0,0.0,12.428,10.806,3.7917391,1077.547008,0.0,0.0,11.936,10.205
1442583381000,9.1845577,1284.837376,2.449408,0.0,66.76,62.728,3.9513625,1235.37408,0.0,0.0,10.599,9.353,2.5726043,1306.566656,1.267712,0.004096,7.657,6.93,3.5706274,1162.42432,0.0,0.0,8.952,7.859,3.3059236,1078.419456,0.0,0.0,9.67,8.742
1442583442000,12.4902505,1286.356992,2.711552,0.0,87.424,81.759,4.028051,1237.577728,0.0,0.0,10.19,8.699,5.0087092,1308.614656,1.030144,0.014336,10.641,8.196,4.6552251,1163.743232,0.0,0.0,11.356,9.896,4.6626522,1082.679296,0.0,0.0,14.469,12.477
1442583502000,11.4242897,1289.756672,4.911104,0.0,69.161,64.772,4.1327738,1235.288064,0.032768,0.0,9.23,7.711,4.7684879,1308.991488,1.75104,0.01024,9.101,8.174,3.8707974,1162.616832,0.0,0.0,7.997,7.113,4.0584408,1073.80736,0.0,0.0,10.889,9.375
1442583562000,12.9277726,1288.92928,3.448832,0.0,75.954,70.076,4.710976,1235.275776,0.0,0.0,10.255,8.873,4.310061,1309.528064,1.292288,0.012288,7.61,5.96,5.4600073,1164.374016,0.0,0.0,11.116,9.034,4.3231367,1073.102848,0.0,0.0,11.185,9.874
1442583622000,12.4453685,1290.002432,2.506752,0.0,73.785,69.667,5.1821555,1242.021888,0.0,0.0,8.8,7.525,4.0882325,1310.855168,2.01728,0.004096,9.255,7.897,4.4236343,1161.854976,0.0,0.0,10.784,9.15,4.3589296,1080.578048,0.0,0.0,11.25,10.682
1442583682000,13.0276562,1287.839744,3.842048,0.0,83.476,78.446,4.4303159,1238.970368,0.0,0.0,10.087,7.483,5.136362,1308.336128,1.888256,0.018432,9.802,8.213,5.2749579,1160.507392,0.0,0.0,11.961,10.946,5.0688386,1077.776384,0.0,0.0,12.488,11.457
1442583743000,11.5953405,1289.220096,3.264512,0.0,81.815,76.277,4.0719515,1241.06752,0.0,0.0,10.207,8.636,3.4182086,1315.16416,1.447936,0.0,10.358,8.441,4.5802667,1162.825728,0.0,0.0,10.18,8.979,4.4723977,1082.302464,0.0,0.0,13.848,11.126
1442583804000,9.8221892,1281.482752,3.670016,0.0,69.484,65.259,2.4106832,1233.887232,0.0,0.0,7.631,6.469,3.9645352,1307.734016,1.705984,0.0,8.431,7.967,3.3862267,1161.199616,0.0,0.0,9.955,8.917,3.7249664,1079.263232,0.016384,0.0,12.12,10.147
1442583864000,12.8201028,1282.859008,2.428928,0.0,74.828,70.036,4.3474199,1234.968576,0.0,0.0,8.811,8.189,4.5552836,1309.81888,2.22208,0.0,9.631,8.804,4.9371705,1163.55072,0.0,0.0,11.072,9.552,4.8878723,1072.80384,0.0,0.0,10.793,9.125
1442583924000,11.8381173,1283.022848,2.117632,0.0,78.361,73.427,3.2167902,1237.082112,0.0,0.0,8.702,7.863,4.4996628,1308.434432,1.6384,0.0,10.33,9.054,4.2692485,1164.337152,0.0,0.0,11.926,10.045,4.4902369,1075.290112,0.0,0.0,12.612,11.697
1442583984000,11.226898,1285.206016,3.620864,0.0,75.319,69.843,4.0816621,1238.401024,0.0,0.0,10.97,9.778,3.7994588,1312.124928,1.447936,0.0,7.881,7.002,3.9359988,1164.402688,0.0,0.0,9.481,9.359,4.0216035,1077.071872,0.0,0.0,10.442,9.835
1442584044000,11.8922328,1286.168576,2.154496,0.0,83.91,78.777,4.0846854,1236.750336,0.0,0.0,10.258,8.803,3.8510088,1310.896128,2.179072,0.0,10.464,8.952,4.1775548,1163.743232,0.0,0.0,11.54,10.312,4.3531766,1077.448704,0.0,0.0,12.805,10.026
1442584104000,11.7230293,1288.687616,3.459072,0.0,90.134,83.634,4.0772665,1239.38816,0.0,0.0,11.38,10.299,4.6281276,1310.9248,1.562624,0.0,9.339,8.321,4.4336541,1164.65664,0.0,0.0,11.262,9.369,4.7134205,1074.249728,0.0,0.0,15.7,13.288
1442584164000,12.0682265,1286.750208,2.506752,0.0,74.613,69.441,4.0882876,1237.89312,0.0,0.0,9.048,8.333,3.5614933,1312.370688,1.544192,0.0,6.782,6.155,4.6312506,1161.613312,0.0,0.0,11.964,10.322,4.5872486,1072.746496,0.0,0.0,11.803,10.287
1442584224000,11.9614021,1292.480512,3.014656,0.0,85.451,79.563,4.065929,1239.461888,0.0,0.0,9.62,9.184,3.6621993,1310.322688,1.253376,0.0,7.35,6.279,4.7554037,1168.605184,0.0,0.0,16.309,14.765,4.8721909,1076.015104,0.0,0.0,12.318,10.314
1442584284000,14.0892308,1292.754944,1.814528,0.0,87.175,81.742,5.2757048,1242.84928,0.0,0.0,9.978,9.455,4.1774099,1312.104448,1.476608,0.0,10.654,8.565,5.5561175,1169.973248,0.0,0.0,13.51,11.238,5.5061072,1078.82496,0.0,0.0,13.232,12.716
1442584344000,12.1519332,1290.543104,4.276224,0.0,82.169,77.303,4.2717036,1243.394048,0.0,0.0,10.887,9.963,4.1434276,1309.315072,1.25952,0.0,9.334,6.878,4.457336,1167.900672,0.0,0.0,11.658,9.761,4.321025,1076.801536,0.0,0.0,12.544,11.45
1442584404000,10.9640362,1283.854336,2.572288,0.0,83.861,78.427,3.171659,1237.573632,0.0,0.0,8.706,7.172,4.2851034,1303.785472,1.916928,0.0,10.622,9.031,4.2920962,1163.55072,0.0,0.0,12.223,10.244,4.3741695,1075.912704,0.0,0.0,13.509,11.464
1442584464000,3.8309707,1285.132288,1.204224,0.0,52.926,48.792,1.5684329,1239.63392,0.0,0.0,6.436,6.152,18.2248211,1309.184,4.120576,0.0,5.498,4.93,1.2078685,1159.868416,0.0,0.0,8.952,8.026,1.1762398,1071.484928,0.0,0.0,7.774,6.19
1442584524000,10.1783507,1289.252864,1.8432,0.0,64.119,60.09,3.9793825,1241.870336,0.0,0.0,8.914,8.387,3.3401664,1312.124928,2.930688,0.0,8.825,8.429,4.201278,1168.42496,0.0,0.0,11.114,9.199,3.2433745,1072.64,0.0,0.0,7.154,6.091
1442584584000,9.7628139,1289.506816,1.499136,0.0,68.205,63.382,3.5072362,1242.29632,0.0,0.0,8.97,7.552,2.8309342,1310.322688,1.462272,0.0,7.41,6.338,4.0003429,1167.306752,0.0,0.0,9.955,9.421,3.2017232,1074.09408,0.0,0.0,9.403,8.282
1442584644000,11.7215525,1291.554816,3.15392,0.0,70.946,67.422,4.1685084,1244.557312,0.0,0.0,8.051,7.155,4.6982363,1315.59424,2.224128,0.0,8.745,8.247,5.0198939,1168.613376,0.0,0.0,12.569,11.047,4.2466328,1074.0736,0.0,0.0,10.449,8.294
1442584704000,13.4296309,1292.304384,2.842624,0.0,87.277,81.232,4.5348439,1245.130752,0.0,0.0,10.519,9.355,5.1088496,1314.271232,2.076672,0.0,10.133,9.339,5.2552157,1169.14176,0.0,0.0,12.389,10.489,5.5211917,1072.795648,0.0,0.0,13.068,12.494
1442584764000,9.7052743,1293.889536,4.34176,0.0,70.647,66.295,4.8543211,1242.943488,0.0,0.0,9.59,8.404,13.701857,1313.132544,1.468416,0.0,9.142,7.456,3.5139852,1171.341312,0.0,0.0,9.751,8.058,3.1209582,1072.181248,0.0,0.0,8.655,6.605
1442584824000,11.7712886,1294.19264,4.288512,0.0,74.306,69.96,3.6104788,1241.153536,0.0,0.0,10.447,8.586,4.4875921,1312.063488,1.435648,0.0,8.582,6.99,4.0149516,1172.037632,0.0,0.0,10.438,9.82,4.1532376,1071.230976,0.0,0.0,9.916,8.776
1442584884000,11.0872486,1293.856768,3.833856,0.0,82.608,77.173,4.6706317,1243.426816,0.0,0.0,9.546,7.993,3.5473543,1311.809536,1.404928,0.0,8.34,8.179,4.2824303,1171.963904,0.0,0.0,13.333,10.638,3.7181413,1073.139712,0.032768,0.0,12.769,10.008
1442584945000,11.0391907,1296.617472,2.78528,0.024576,81.426,76.155,4.4096701,1246.298112,0.0,0.0,11.214,10.494,3.2787633,1315.651584,1.568768,0.0,8.54,7.453,4.2535353,1170.976768,0.0,0.0,10.903,9.012,4.196122,1072.029696,0.0,0.0,13.69,10.52
1442585005000,8.092016,1281.622016,1.29024,0.004096,29.034,26.919,2.655011,1233.010688,0.0,0.0,4.445,4.07,3.3839247,1301.241856,0.7168,0.0,4.547,4.04,3.1086875,1161.056256,0.0,0.0,4.629,3.657,2.2882264,1065.025536,0.0,0.0,3.219,2.942
1442585065000,10.2787168,1288.712192,2.121728,0.028672,76.75,72.55,3.8564986,1248.595968,0.0,0.0,10.082,8.136,3.5098915,1304.162304,1.980416,0.0,11.427,9.661,3.1273464,1166.610432,0.0,0.0,9.496,7.697,3.2279929,1068.371968,0.0,0.0,11.014,10.246
1442585125000,13.1580701,1290.596352,2.351104,0.024576,88.177,83.185,4.3227512,1248.403456,0.0,0.0,12.37,10.351,4.2937139,1303.535616,1.492992,0.0,8.329,7.155,4.815138,1163.599872,0.0,0.0,13.048,11.033,4.5240059,1069.678592,0.0,0.0,14.911,12.549
1442585185000,12.7619643,1294.25408,3.690496,0.012288,87.499,81.986,4.7206075,1251.147776,0.0,0.0,12.705,10.315,4.6699971,1308.274688,1.343488,0.0,11.11,8.654,4.1994239,1172.267008,0.0,0.0,11.779,10.763,4.2532012,1068.048384,0.032768,0.0,11.603,10.801
1442585245000,10.4223223,1295.040512,3.325952,0.008192,63.349,59.728,4.2791562,1254.162432,0.0,0.0,9.924,8.837,3.0978383,1310.154752,0.79872,0.0,7.228,6.182,2.98596,1173.479424,0.0,0.0,7.925,6.894,4.0800596,1073.065984,0.0,0.0,9.764,8.259
1442585305000,7.5907705,1293.840384,2.523136,0.0,60.104,56.818,3.0196493,1254.383616,0.0,0.0,8.063,6.899,2.8844346,1311.338496,1.245184,0.0,7.102,6.302,2.2726866,1178.619904,0.0,0.0,9.175,8.406,3.4583977,1070.129152,0.0,0.0,10.079,9.065
1442585366000,8.647439,1297.649664,3.584,0.028672,65.714,61.838,3.4395348,1252.343808,0.0,0.0,7.492,6.973,3.058823,1311.404032,1.091584,0.0,7.621,6.136,2.9942378,1179.336704,0.0,0.0,9.648,8.796,3.1109428,1072.603136,0.0,0.0,10.009,8.773
1442585427000,10.6906839,1294.82752,2.895872,0.024576,73.362,68.32,4.5398322,1252.540416,0.0,0.0,9.611,8.198,4.171163,1310.531584,1.230848,0.0,7.673,5.892,3.6321853,1175.30624,0.0,0.0,11.069,10.14,4.1544665,1075.310592,0.0,0.0,10.852,10.529
1442585487000,8.7154746,1298.464768,3.612672,0.036864,69.671,65.79,3.3991696,1251.508224,0.0,0.0,9.629,7.565,2.3057,1310.523392,1.032192,0.0,5.068,4.585,3.6037763,1173.250048,0.0,0.0,10.87,9.418,3.4636485,1071.67744,0.0,0.0,12.632,11.157
1442585547000,11.3391916,1297.747968,2.686976,0.004096,80.548,74.538,3.6615815,1250.689024,0.0,0.0,11.385,9.732,3.388664,1310.867456,1.695744,0.0,8.973,8.617,4.614494,1172.119552,0.0,0.0,10.286,9.434,4.4446671,1067.483136,0.0,0.0,11.918,10.463
1442585607000,7.5578587,1294.839808,3.13344,0.032768,74.123,68.49,3.0299156,1251.958784,0.0,0.0,9.743,8.576,2.5519427,1308.053504,1.08544,0.0,10.562,8.487,2.4271046,1174.044672,0.0,0.0,9.239,7.484,2.0301844,1069.940736,0.0,0.0,9.374,8.748
1442585667000,9.965134,1293.971456,2.53952,0.012288,65.504,60.49,3.370106,1248.489472,0.0,0.0,7.421,6.626,3.132067,1301.762048,1.892352,0.0,9.095,8.043,3.7045342,1178.206208,0.0,0.0,8.835,8.492,3.4039759,1069.50656,0.0,0.0,9.549,8.464
1442585728000,11.6465692,1290.801152,4.941824,0.0,70.683,66.623,4.1224712,1248.894976,0.0,0.0,10.838,8.957,4.429244,1295.478784,1.449984,0.0,8.707,7.779,4.9120149,1172.852736,0.0,0.0,10.057,9.152,3.8216016,1069.285376,0.0,0.0,8.491,7.162
1442585789000,10.4672277,1293.942784,1.59744,0.0,78.152,73.404,4.2455373,1259.925504,0.0,0.0,9.896,8.025,3.1871855,1299.7632,2.066432,0.0,8.73,7.412,3.4115632,1175.826432,0.012288,0.0,11.049,9.545,4.8353318,1073.197056,0.0,0.0,13.752,11.347
1442585849000,10.7492113,1297.289216,2.711552,0.0,81.165,75.03,4.6519985,1258.881024,0.0,0.0,9.481,9.158,3.5462049,1297.559552,1.282048,0.0,8.245,6.671,4.0377147,1174.503424,0.0,0.0,12.794,11.613,3.164845,1074.745344,0.024576,0.0,12.843,10.252
1442585909000,11.2177499,1299.898368,3.121152,0.0,77.256,72.714,3.7648261,1257.648128,0.0,0.0,9.607,8.428,3.5975381,1296.674816,1.538048,0.0,8.42,7.087,4.8092441,1176.014848,0.0,0.0,11.307,11.175,3.5450914,1074.42176,0.0,0.0,11.479,10.244
1442585969000,9.457329,1297.465344,2.187264,0.0,73.393,68.607,3.6754133,1257.152512,0.0,0.0,8.453,8.054,3.091956,1306.456064,2.228224,0.0,9.217,8.509,3.3037197,1173.979136,0.0,0.0,10.432,9.301,3.5726712,1072.132096,0.0,0.0,10.639,9.098
1442586029000,10.7212621,1298.915328,3.62496,0.0,74.595,69.374,3.794422,1258.991616,0.0,0.0,9.835,8.536,4.606594,1306.714112,2.516992,0.0,10.331,9.597,3.5096356,1182.818304,0.0,0.0,9.316,8.015,3.9330218,1074.630656,0.0,0.0,10.434,9.012
1442586089000,12.0274308,1296.932864,3.145728,0.0,83.001,78.306,6.025434,1259.58144,0.0,0.0,9.878,9.033,4.3464181,1308.602368,2.138112,0.0,11.352,9.714,4.0358215,1183.363072,0.0,0.0,12.298,9.636,4.4173081,1075.175424,0.0,0.0,12.07,10.461
1442586150000,11.5239952,1293.500416,2.940928,0.0,80.813,75.154,4.1170616,1260.404736,0.0,0.0,11.5,9.943,3.9430646,1305.165824,1.738752,0.0,10.759,10.27,3.8889169,1183.244288,0.0,0.0,9.549,8.667,3.2065839,1075.208192,0.0,0.0,10.664,9.832
1442586211000,11.0950108,1295.85152,2.588672,0.0,78.703,73.617,3.5863364,1258.491904,0.0,0.0,7.811,7.551,3.9330331,1305.219072,2.678784,0.008192,9.851,9.32,3.9642388,1183.281152,0.0,0.0,12.304,10.32,3.8574628,1078.018048,0.0,0.0,12.788,11.108
1442586271000,8.3649588,1294.843904,2.453504,0.0,67.619,63.093,2.921641,1252.876288,0.0,0.0,6.577,6.17,2.4290007,1286.365184,1.624064,0.02048,8.245,6.993,6.3876462,1181.618176,0.0,0.0,10.508,8.933,4.1806182,1072.14848,0.0,0.0,11.238,10.277
1442586331000,8.2256223,1298.501632,4.038656,0.0,69.205,65.41,3.3336006,1258.123264,0.0,0.0,10.1,8.098,2.7152869,1291.558912,1.298432,0.008192,7.2,6.216,3.2844997,1186.062336,0.0,0.0,10.03,8.856,2.8571823,1070.374912,0.0,0.0,9.136,8.504
1442586391000,8.3397409,1302.478848,3.989504,0.0,67.976,63.906,2.9312017,1258.389504,0.0,0.0,10.402,8.779,2.5457513,1292.259328,1.671168,0.008192,9.056,7.37,3.3692291,1184.735232,0.0,0.0,8.613,7.512,2.3719179,1072.197632,0.008192,0.0,8.721,8.302
1442586451000,10.5297332,1303.40864,3.11296,0.0,75.728,71.285,3.934768,1259.634688,0.0,0.0,11.235,10.138,5.1093533,1294.610432,1.88416,0.014336,9.97,9.525,3.3990244,1179.66848,0.0,0.0,9.929,8.856,3.4230958,1078.976512,0.0,0.0,9.776,8.144
1442586511000,10.7657991,1301.901312,3.063808,0.0,63.475,58.789,2.6054971,1261.9776,0.0,0.0,7.184,6.2,2.5468862,1291.481088,1.14688,0.014336,5.401,4.959,3.9765973,1185.533952,0.0,0.0,9.881,9.939,3.5384203,1076.875264,0.0,0.0,9.845,9.553
1442586571000,9.4929254,1307.004928,4.235264,0.0,60.993,57.382,3.6410871,1263.058944,0.0,0.0,8.351,7.479,2.7632046,1291.38688,1.482752,0.006144,7.474,6.612,5.940752,1185.087488,0.0,0.0,7.636,6.746,3.371676,1080.557568,0.0,0.0,8.779,8.342
1442586632000,11.6938946,1307.414528,4.583424,0.0,77.079,72.182,3.9209733,1264.18944,0.0,0.0,9.472,7.6,4.4014213,1291.935744,2.080768,0.01024,10.818,9.427,4.2921302,1182.3104,0.0,0.0,10.415,8.931,4.6206474,1081.991168,0.0,0.0,10.173,8.703
1442586692000,12.6103036,1302.454272,3.74784,0.0,71.617,67.631,3.7013423,1263.53408,0.0,0.0,10.097,9.122,3.4122046,1295.388672,1.398784,0.014336,8.913,8.009,4.6586947,1181.106176,0.0,0.0,10.589,9.378,4.6965235,1082.597376,0.0,0.0,8.594,7.94
1442586752000,12.3165324,1304.117248,3.813376,0.0,69.416,64.861,4.6078292,1264.92672,0.0,0.0,8.56,7.817,4.5960009,1293.570048,1.861632,0.014336,8.717,8.168,4.4996697,1186.983936,0.0,0.0,9.085,8.102,4.465937,1082.85952,0.0,0.0,9.773,9.182
1442586812000,11.1914642,1303.601152,5.18144,0.0,82.581,77.063,3.6907178,1263.394816,0.0,0.0,9.609,8.549,4.6672554,1292.529664,1.47456,0.018432,9.74,8.913,4.1706262,1187.1232,0.0,0.0,12.206,9.887,4.5675995,1081.532416,0.0,0.0,11.824,9.485
1442586872000,6.6226166,1300.250624,3.063808,0.0,57.56,54.36,2.55097,1259.634688,0.032768,0.0,8.01,7.326,2.1862588,1282.654208,1.099776,0.01024,6.157,5.393,1.6923357,1188.61824,0.0,0.0,7.799,6.482,2.3150428,1082.40896,0.0,0.0,9.157,8.575
1442586932000,11.4722563,1304.35072,4.268032,0.0,71.082,67.339,4.7661938,1262.071808,0.0,0.0,9.439,8.875,3.4322365,1283.862528,0.770048,0.014336,8.083,6.035,4.8525505,1189.249024,0.0,0.0,12.461,10.848,4.3859387,1081.397248,0.0,0.0,11.035,9.605
1442586992000,10.0221892,1297.24416,3.538944,0.0,67.991,63.932,4.0688096,1258.827776,0.0,0.0,9.31,7.355,3.7527532,1283.190784,2.0992,0.016384,10.705,8.897,3.7638289,1185.898496,0.0,0.0,7.863,7.79,3.2860505,1105.518592,0.0,0.0,9.98,7.884
1442587053000,11.4447332,1293.991936,3.694592,0.0,85.62,80.736,4.1935479,1261.21984,0.0,0.0,12.075,10.218,4.6145618,1285.287936,2.326528,0.0,12.008,10.382,4.1922251,1182.355456,0.0,0.0,10.511,8.616,4.2845689,1110.335488,0.0,0.0,13.062,10.713
1442587113000,5.9125192,1293.14816,1.183744,0.0,47.825,44.511,1.7878852,1261.490176,0.0,0.0,6.134,5.795,2.4600602,1283.080192,1.314816,0.0,5.931,5.552,2.050186,1183.748096,0.0,0.0,6.64,5.785,2.4297939,1114.451968,0.0,0.0,7.641,7.308
1442587174000,9.6325388,1293.389824,3.366912,0.0,62.637,57.908,3.684817,1258.921984,0.0,0.0,8.081,8.058,3.1138962,1284.128768,1.355776,0.0,6.166,5.676,3.5118573,1183.547392,0.0,0.0,9.08,8.576,3.8102481,1111.28576,0.0,0.0,10.212,8.433
1442587234000,9.518047,1293.55776,4.153344,0.0,72.415,67.958,3.3619916,1264.484352,0.0,0.0,8.41,7.287,3.3690639,1282.88768,2.127872,0.0,7.397,8.263,3.8827993,1183.244288,0.0,0.0,9.737,9.08,4.3533332,1114.50112,0.0,0.0,11.819,10.028
1442587294000,10.0401737,1297.625088,2.572288,0.0,74.713,70.033,3.331341,1263.3088,0.0,0.0,9.198,7.836,3.9095511,1280.53248,1.810432,0.0,9.933,9.026,3.502801,1184.776192,0.0,0.0,10.772,10.475,3.3862119,1114.673152,0.0,0.0,11.437,10.151
1442587354000,10.8041735,1295.17568,2.543616,0.0,69.92,65.153,3.3330918,1261.305856,0.0,0.0,10.011,8.585,3.6744554,1279.934464,1.24928,0.0,6.389,5.33,3.7766556,1185.492992,0.0,0.0,10.509,9.618,3.8758021,1112.875008,0.0,0.0,9.349,9.271
1442587414000,9.3110028,1297.502208,2.113536,0.0,80.696,75.471,3.61019,1261.182976,0.032768,0.0,8.059,6.755,3.2551524,1278.275584,1.880064,0.0,9.586,8.875,3.7469003,1183.670272,0.0,0.0,11.471,9.289,4.1574353,1113.04704,0.0,0.0,14.293,12.367
1442587474000,15.6359563,1298.649088,2.304,0.0,54.622,49.688,1.6863749,1258.647552,0.02048,0.0,7.26,6.385,1.4615855,1266.982912,0.909312,0.0,4.78,4.091,2.4992253,1182.90432,0.008192,0.0,7.316,6.687,2.963858,1106.583552,0.028672,0.0,9.378,7.96
1442587534000,9.6796515,1300.140032,3.805184,0.0,62.968,59.409,3.5629974,1258.573824,0.0,0.0,9.582,8.772,3.045884,1266.827264,1.253376,0.0,6.453,5.824,3.4104822,1188.728832,0.0,0.0,9.577,8.057,3.5266747,1103.323136,0.0,0.0,9.081,7.002
1442587594000,10.6054746,1300.262912,2.854912,0.0,73.758,69.544,4.5412927,1261.3632,0.065536,0.0,8.846,7.93,2.9246363,1269.542912,0.985088,0.0,7.884,6.133,4.4250296,1188.33152,0.0,0.0,9.825,8.759,4.387456,1104.728064,0.0,0.0,13.324,11.282
1442587654000,10.7729909,1300.328448,3.616768,0.0,71.704,66.783,3.9411366,1261.600768,0.0,0.0,9.871,8.951,3.0331145,1265.094656,1.122304,0.0,7.476,6.566,3.8269234,1188.646912,0.0,0.0,11.149,10.064,3.1944293,1105.14176,0.0,0.0,8.532,8.174
1442587714000,9.6820842,1304.543232,3.078144,0.0,65.213,61.195,4.7012539,1261.768704,0.0,0.0,8.693,8.307,3.7845925,1265.299456,1.773568,0.0,7.364,7.194,4.0389518,1184.452608,0.0,0.0,9.382,7.743,2.914449,1108.078592,0.0,0.0,10.0,7.865
1442587774000,11.1665797,1309.401088,3.559424,0.0,74.765,69.873,4.3609914,1264.553984,0.0,0.0,10.09,8.469,4.9287029,1268.670464,2.048,0.0,8.733,8.022,3.3920201,1185.435648,0.0,0.0,10.263,8.779,4.2376135,1107.111936,0.0,0.0,11.051,9.825
1442587834000,11.1287984,1304.076288,4.276224,0.0,65.803,61.303,3.7649137,1264.009216,0.0,0.0,8.937,7.659,3.8569259,1267.286016,1.93536,0.0,7.885,7.54,4.414024,1185.501184,0.0,0.0,10.487,8.759,4.2750577,1105.01888,0.0,0.0,7.861,7.094
1442587895000,9.5131579,1306.791936,4.898816,0.0,66.934,63.643,3.8251346,1263.4112,0.032768,0.0,8.852,7.154,3.5838713,1265.283072,1.748992,0.0,8.373,7.306,2.9558048,1186.541568,0.0,0.0,8.38,7.264,3.4045296,1106.558976,0.0,0.0,9.532,7.976
1442587955000,10.2856838,1304.113152,2.740224,0.0,73.288,68.908,3.7358768,1264.88576,0.0,0.0,10.099,8.843,3.2515292,1268.342784,1.396736,0.0,9.109,8.046,3.2861756,1188.225024,0.0,0.0,10.608,8.874,3.0256674,1107.247104,0.0,0.0,9.628,8.474
1442588016000,8.6257742,1306.308608,3.842048,0.0,64.71,60.509,3.5585722,1266.42176,0.0,0.0,8.446,7.745,3.6958149,1266.126848,2.410496,0.01024,9.295,7.934,3.5033204,1185.619968,0.0,0.0,9.143,7.207,2.9923546,1104.334848,0.0,0.0,8.975,7.537
1442588076000,9.7906229,1306.157056,2.41664,0.0,66.118,61.942,4.1602163,1261.297664,0.0,0.0,9.25,7.38,3.8399605,1256.697856,1.497088,0.008192,8.448,6.602,2.9651189,1187.069952,0.0,0.0,9.071,8.057,4.0225151,1102.626816,0.0,0.0,9.695,8.358
1442588136000,11.2084252,1305.739264,1.712128,0.0,70.17,65.043,3.9166994,1262.645248,0.0,0.0,8.814,7.6,3.4760875,1258.319872,1.394688,0.014336,8.333,6.813,3.9615435,1186.103296,0.0,0.0,7.897,7.058,4.2018002,1100.496896,0.032768,0.0,12.014,11.599
1442588196000,9.7824856,1309.71648,3.096576,0.0,67.372,62.881,4.5160278,1262.907392,0.0,0.0,11.042,9.563,3.986389,1258.7008,1.820672,0.014336,7.841,7.236,3.1315138,1185.30048,0.0,0.0,8.075,7.523,3.0993197,1097.711616,0.0,0.0,8.491,7.816
1442588256000,11.5304113,1309.696,3.506176,0.0,76.56,72.123,3.9418243,1264.533504,0.032768,0.0,11.216,9.563,4.0851931,1261.11744,1.83296,0.016384,9.482,8.453,4.4744862,1187.340288,0.0,0.0,10.012,8.696,3.6979341,1100.22656,0.0,0.0,11.635,9.443
1442588316000,10.1735322,1307.68896,2.633728,0.0,62.996,59.133,2.9751572,1265.299456,0.0,0.0,7.072,5.526,3.4015324,1264.193536,1.630208,0.014336,8.837,7.617,3.4120619,1195.606016,0.0,0.0,8.693,8.164,4.2933469,1102.839808,0.0,0.0,9.274,8.358
1442588376000,9.4824598,1306.17344,3.883008,0.0,60.623,57.436,3.4061565,1265.238016,0.0,0.0,7.28,6.424,3.3329544,1257.254912,1.82272,0.008192,8.375,7.311,3.0757237,1192.210432,0.0,0.0,7.419,6.58,3.5744445,1103.867904,0.0,0.0,9.258,8.402
1442588437000,10.9401139,1308.688384,2.84672,0.028672,70.195,66.683,3.6582133,1267.560448,0.0,0.0,6.892,6.781,4.1529362,1257.086976,1.845248,0.012288,10.039,8.578,3.8470026,1195.78624,0.0,0.0,11.904,10.462,3.8262185,1111.63392,0.0,0.0,10.017,8.977
1442588497000,4.8085686,1305.858048,2.572288,0.024576,43.936,40.723,5.6791149,1266.671616,0.14336,0.0,5.603,5.049,1.4686304,1252.63872,0.999424,0.01024,4.701,4.589,1.6353777,1192.398848,0.0,0.0,6.205,5.467,2.1344044,1105.24416,0.0,0.0,5.859,5.773
1442588557000,4.4075071,1305.76384,0.73728,0.016384,34.829,32.7,1.3667009,1265.307648,0.0,0.0,4.098,3.423,1.4696299,1260.31872,0.894976,0.012288,3.761,3.54,2.1392002,1192.83712,0.077824,0.0,6.48,4.935,1.6455226,1104.11776,0.0,0.0,4.379,3.453
1442588617000,9.8466813,1306.836992,3.436544,0.024576,69.132,64.857,4.5578516,1266.577408,0.0,0.0,9.875,8.256,3.057087,1260.670976,1.216512,0.012288,7.084,6.048,3.1257006,1193.111552,0.0,0.0,8.821,8.53,3.8823055,1103.368192,0.0,0.0,11.227,9.816
1442588677000,11.8471423,1307.848704,3.465216,0.016384,74.928,70.549,4.3026252,1257.107456,0.0,0.0,8.877,7.17,4.4302067,1255.268352,1.98656,0.008192,10.094,9.016,3.3984086,1191.047168,0.0,0.0,10.14,8.584,4.4465133,1095.163904,0.0,0.0,11.775,9.981
1442588737000,11.2462599,1307.353088,2.875392,0.016384,61.986,57.831,4.1319049,1266.843648,0.0,0.0,7.753,6.663,3.7469385,1253.04832,1.728512,0.01024,7.469,6.651,4.1023761,1189.986304,0.032768,0.0,9.179,7.689,3.3154486,1094.119424,0.0,0.0,9.567,8.48
1442588798000,9.3562333,1309.155328,1.695744,0.036864,74.412,69.22,3.8357016,1264.283648,0.0,0.0,9.517,8.121,3.7337931,1252.061184,1.814528,0.0,8.458,7.478,3.9282622,1190.662144,0.0,0.0,13.21,11.522,4.1410641,1093.804032,0.0,0.0,9.694,8.042
1442588858000,10.8013583,1309.691904,2.973696,0.02048,76.28,71.897,5.32188,1264.730112,0.0,0.0,12.611,10.332,3.803694,1252.511744,0.954368,0.0,7.313,5.753,4.4953078,1189.011456,0.0,0.0,10.389,9.244,3.2228067,1095.438336,0.0,0.0,12.28,10.585
1442588918000,11.1665679,1309.151232,2.92864,0.024576,76.941,72.43,4.1628643,1269.3504,0.0,0.0,10.4,8.953,3.3274269,1254.17472,2.260992,0.0,9.875,8.448,4.0894039,1190.109184,0.0,0.0,11.913,10.982,3.9512727,1094.885376,0.0,0.0,8.413,7.67
1442588978000,7.7085216,1311.68256,2.015232,0.028672,51.07,47.644,3.1414259,1269.8624,0.004096,0.0,6.294,5.791,2.9482098,1254.313984,1.75104,0.0,5.552,5.794,2.8789601,1192.7552,0.0,0.0,7.197,6.361,3.138036,1095.241728,0.0,0.0,8.508,6.741
1442589038000,10.4453498,1310.404608,2.830336,0.028672,72.681,68.084,3.359123,1269.665792,0.0,0.0,9.331,8.7,3.6681856,1257.398272,1.918976,0.0,8.184,7.68,3.4410298,1195.76576,0.0,0.0,8.408,8.185,3.6813891,1092.943872,0.0,0.0,12.959,10.159
1442589098000,9.3077609,1312.321536,3.076096,0.024576,66.907,62.851,2.9921577,1269.813248,0.0,0.0,7.398,6.151,4.2555069,1254.678528,1.75104,0.0,9.939,7.98,3.663159,1196.78976,0.0,0.0,8.379,8.22,3.2472637,1095.737344,0.0,0.0,9.958,7.547
1442589158000,9.3286715,1314.238464,2.428928,0.02048,74.586,70.581,4.0639405,1270.66112,0.0,0.0,10.046,8.156,3.6366866,1253.978112,1.3312,0.0,9.097,7.95,3.0385818,1196.023808,0.0,0.0,9.857,8.166,2.8340996,1096.261632,0.0,0.0,11.071,9.963
1442589219000,11.3547136,1315.627008,3.534848,0.0,69.401,64.239,3.6460793,1272.721408,0.0,0.0,8.727,7.675,2.5727748,1254.068224,0.995328,0.0,6.627,6.314,4.0979825,1198.641152,0.0,0.0,11.655,10.748,3.8262399,1097.91232,0.0,0.0,7.804,7.974
1442589280000,11.0790277,1310.285824,2.424832,0.0,59.672,55.464,3.8245204,1264.074752,0.0,0.0,6.346,5.575,3.5630029,1253.70368,1.861632,0.0,7.506,6.643,4.11291,1195.270144,0.0,0.0,10.75,9.618,3.7823279,1092.329472,0.0,0.0,8.312,7.502
1442589340000,19.50623,1307.779072,2.555904,0.0,79.368,74.046,3.8674518,1265.508352,0.032768,0.0,11.872,9.463,3.5615426,1255.395328,1.572864,0.0,9.576,7.849,4.0996908,1191.112704,0.0,0.0,8.246,6.955,3.3599439,1091.444736,0.0,0.0,13.832,11.104
1442589400000,11.4299634,1310.650368,3.4816,0.0,75.415,69.612,3.7714268,1265.17248,0.0,0.0,9.382,7.122,4.6590897,1260.208128,2.179072,0.0,9.572,8.475,3.7967604,1192.841216,0.0,0.0,10.203,8.98,4.4128679,1090.408448,0.032768,0.0,10.867,8.85
1442589460000,10.2883764,1309.77792,3.682304,0.0,81.745,76.553,4.5423239,1275.224064,0.0,0.0,11.704,10.177,2.6900331,1254.97344,1.37216,0.0,9.333,7.005,4.4168926,1194.483712,0.0,0.0,11.915,9.805,3.8833811,1093.644288,0.032768,0.0,10.808,9.728
1442589520000,12.121448,1311.408128,3.52256,0.0,72.251,67.605,4.2097794,1278.308352,0.0,0.0,7.801,6.579,4.4675303,1255.940096,1.286144,0.0,7.977,6.718,4.9959213,1198.170112,0.0,0.0,11.201,10.534,4.8223343,1094.582272,0.0,0.0,10.674,9.644
1442589581000,11.8648727,1309.548544,3.50208,0.0,74.577,70.454,4.3330547,1276.276736,0.032768,0.0,9.302,8.261,4.4224534,1255.948288,1.871872,0.0,10.544,8.561,3.3724986,1196.634112,0.0,0.0,9.138,8.178,3.7533267,1090.41664,0.0,0.0,11.525,9.55
1442589642000,12.1893228,1309.036544,2.748416,0.0,79.667,75.397,4.3624653,1275.949056,0.0,0.0,11.261,9.275,3.8908331,1259.098112,1.216512,0.0,8.895,6.99,5.0939709,1199.955968,0.0,0.0,11.522,9.22,4.984111,1089.339392,0.0,0.0,12.182,10.483
1442589702000,11.8074974,1306.370048,2.269184,0.0,76.369,71.365,5.2907786,1277.652992,0.0,0.0,11.77,10.305,3.2953693,1256.32512,1.888256,0.0,7.421,6.652,4.2796328,1196.322816,0.0,0.0,9.774,8.49,3.9352296,1088.90112,0.0,0.0,12.472,10.919
1442589762000,10.4913154,1307.230208,2.318336,0.0,73.24,68.17,3.4119575,1275.142144,0.012288,0.0,8.09,6.56,4.1380788,1256.148992,2.166784,0.0,8.549,7.814,4.2830612,1204.944896,0.0,0.0,11.197,8.982,3.9776696,1097.03168,0.0,0.0,13.047,11.032
1442589822000,13.1340738,1305.923584,3.280896,0.0,82.07,76.847,5.4090645,1277.6448,0.0,0.0,12.669,11.047,3.949618,1257.222144,1.35168,0.0,9.146,7.779,4.2419074,1204.18304,0.0,0.0,12.553,10.588,4.4629498,1094.20544,0.0,0.0,10.125,8.759
1442589882000,6.914162,1303.109632,2.891776,0.0,68.947,63.983,3.2463046,1268.34688,0.0,0.0,9.143,8.576,2.2990948,1250.988032,1.216512,0.0,7.868,6.849,2.4726673,1198.522368,0.0,0.0,11.333,10.157,2.8293314,1089.069056,0.0,0.0,8.157,7.863
1442589943000,10.3677765,1301.79072,2.766848,0.0,72.753,68.113,3.3772353,1266.62656,0.057344,0.0,9.221,8.083,3.3781806,1257.680896,1.376256,0.0,8.217,7.065,4.3002892,1198.583808,0.0,0.0,10.101,8.371,4.1300385,1095.200768,0.032768,0.0,11.489,11.076
1442590003000,9.2891997,1304.522752,3.072,0.0,69.081,64.628,3.2244257,1269.362688,0.0,0.0,7.126,6.84,2.5550764,1263.353856,1.165312,0.0,8.445,7.443,3.496443,1201.999872,0.0,0.0,10.136,9.331,3.8898962,1100.009472,0.0,0.0,11.386,9.574
1442590064000,10.1397496,1308.905472,2.883584,0.0,69.969,64.82,3.7810741,1269.47328,0.0,0.0,10.105,8.464,3.8177815,1259.732992,1.9968,0.0,9.317,8.548,3.8438367,1204.916224,0.0,0.0,7.815,7.3,3.2910798,1097.506816,0.0,0.0,9.468,8.107
1442590124000,9.3966149,1307.377664,2.818048,0.0,68.825,64.006,4.2313557,1271.17312,0.0,0.0,8.849,7.708,3.6952807,1262.34624,1.46432,0.0,7.547,6.667,4.0363281,1200.308224,0.0,0.0,9.503,7.657,3.9043886,1102.032896,0.0,0.0,12.933,10.954
1442590184000,8.5278855,1317.064704,1.994752,0.0,69.357,64.719,2.891982,1278.5664,0.0,0.0,9.723,8.162,3.6729878,1267.347456,1.923072,0.0,10.366,8.92,2.8733854,1202.110464,0.0,0.0,7.788,7.038,3.630553,1101.774848,0.0,0.0,9.182,8.041
1442590244000,10.5814831,1314.041856,3.971072,0.0,75.843,71.314,4.9010281,1276.674048,0.0,0.0,10.871,9.698,3.5719112,1264.070656,1.6896,0.0,6.912,6.283,3.3471465,1199.824896,0.0,0.0,9.677,7.83,3.7421045,1100.488704,0.0,0.0,13.949,10.426
1442590304000,10.3552267,1316.589568,5.431296,0.0,70.151,66.835,4.059117,1274.597376,0.0,0.0,9.951,8.67,3.736426,1263.312896,1.271808,0.0,8.618,7.918,3.7274063,1201.606656,0.0,0.0,9.849,7.52,4.3422531,1100.8,0.0,0.0,8.359,7.954
1442590364000,9.0583522,1311.801344,3.162112,0.0,67.682,63.668,3.2204766,1277.493248,0.0,0.0,8.772,8.723,3.1519626,1258.102784,1.859584,0.0,8.919,8.254,3.4510765,1204.215808,0.0,0.0,9.444,8.001,2.8483792,1097.818112,0.0,0.0,9.16,7.684
1442590425000,8.6729261,1313.01376,3.375104,0.0,65.438,61.39,3.2022646,1276.391424,0.0,0.0,7.38,6.899,3.1105849,1257.34912,1.961984,0.0,7.764,6.828,4.0594353,1201.11104,0.0,0.0,10.343,8.594,4.6105584,1103.5648,0.032768,0.0,9.872,9.029
1442590486000,10.5033995,1307.500544,2.482176,0.0,70.571,66.27,4.4621596,1276.301312,0.0,0.0,10.623,9.366,2.7801327,1259.43808,1.161216,0.002048,5.93,5.325,4.276638,1201.750016,0.0,0.0,10.326,8.954,4.2296507,1102.082048,0.0,0.0,11.138,10.383
1442590546000,10.1322049,1300.557824,3.50208,0.0,71.938,68.042,3.7155814,1267.740672,0.0,0.0,8.455,8.0,4.1069138,1256.988672,1.499136,0.01024,9.519,7.667,4.3768874,1199.951872,0.008192,0.0,11.201,9.762,3.6140032,1100.435456,0.0,0.0,10.559,8.575
1442590606000,11.314139,1300.238336,1.990656,0.0,78.487,73.346,4.1866985,1268.711424,0.004096,0.0,11.086,9.746,3.7611435,1255.809024,1.312768,0.01024,10.109,8.637,4.0313956,1197.903872,0.0,0.0,9.67,8.304,3.2928596,1101.389824,0.0,0.0,12.381,11.3
1442590666000,12.0569152,1304.301568,3.596288,0.0,73.01,68.528,4.1317465,1269.362688,0.032768,0.0,8.225,7.371,3.3539441,1258.545152,0.806912,0.01024,6.542,4.989,5.5119204,1200.226304,0.0,0.0,11.325,9.829,4.9412461,1104.756736,0.032768,0.0,12.881,11.544
1442590726000,10.1212169,1307.27936,3.690496,0.0,79.413,73.823,3.3889728,1266.061312,0.0,0.0,9.191,9.278,2.5390007,1261.125632,1.386496,0.01024,8.851,7.391,4.1314774,1202.642944,0.0,0.0,9.984,9.046,3.5028425,1103.757312,0.0,0.0,12.715,11.112
1442590786000,10.7386052,1304.440832,3.52256,0.0,76.863,72.38,4.4143404,1266.25792,0.0,0.0,9.16,7.556,2.6609523,1255.432192,1.171456,0.012288,8.74,7.965,4.1740457,1200.979968,0.0,0.0,9.548,7.522,4.3896916,1097.527296,0.0,0.0,14.381,12.79
1442590847000,12.4510819,1309.069312,2.072576,0.028672,77.626,71.84,5.2276044,1269.768192,0.0,0.0,12.348,10.981,3.9197118,1259.241472,1.3312,0.018432,8.615,7.566,3.7120373,1199.824896,0.0,0.0,9.463,8.114,4.6454608,1094.971392,0.0,0.0,10.808,10.027
1442590907000,9.2678387,1306.37824,3.49184,0.016384,72.765,69.013,3.2239805,1268.03968,0.012288,0.0,8.057,6.867,2.9555386,1256.308736,1.292288,0.01024,8.921,7.616,3.7950322,1203.376128,0.0,0.0,12.325,10.154,4.3370564,1095.581696,0.0,0.0,11.431,9.417
1442590967000,10.6502296,1303.420928,2.940928,0.028672,67.257,62.14,3.1576179,1274.052608,0.0,0.0,7.396,6.357,3.596164,1255.333888,1.970176,0.004096,9.891,9.165,3.9054788,1199.681536,0.0,0.0,8.511,7.909,3.7554915,1089.404928,0.0,0.0,8.387,7.292
1442591027000,10.8116799,1306.513408,3.510272,0.024576,67.193,63.809,4.3995967,1272.434688,0.0,0.0,7.999,7.914,3.7253757,1253.66272,1.353728,0.016384,8.043,6.954,3.8115285,1203.306496,0.0,0.0,9.319,8.118,4.6968857,1090.117632,0.0,0.0,11.703,9.71
1442591087000,10.1548244,1304.592384,1.941504,0.028672,80.161,75.167,3.413696,1274.994688,0.0,0.0,11.68,10.603,3.5293344,1254.510592,1.71008,0.014336,8.987,8.223,3.0324045,1204.5312,0.0,0.0,9.424,8.875,4.5802967,1087.102976,0.0,0.0,12.064,10.657
1442591147000,11.561023,1302.577152,2.807808,0.024576,67.174,61.853,4.0113511,1265.410048,0.0,0.0,9.278,9.024,3.6517112,1249.701888,1.882112,0.008192,8.16,8.033,3.6487972,1199.2064,0.0,0.0,8.17,7.589,4.3065513,1081.5488,0.004096,0.0,9.418,7.894
1442591207000,11.2929274,1305.485312,3.743744,0.024576,67.33,63.386,3.9382703,1262.42816,0.0,0.0,9.011,7.891,4.4502511,1250.783232,1.724416,0.014336,7.859,6.43,4.017728,1199.69792,0.0,0.0,11.154,9.139,3.8539606,1082.85952,0.032768,0.0,9.634,9.242
1442591268000,10.6659392,1306.771456,3.653632,0.032768,79.586,73.96,3.9724264,1269.010432,0.0,0.0,9.238,7.981,4.6061846,1252.90496,1.972224,0.0,10.141,8.596,3.3946789,1202.540544,0.0,0.0,11.562,9.546,4.4105365,1083.666432,0.0,0.0,11.139,9.367
1442591328000,10.2221961,1306.980352,3.13344,0.016384,64.635,60.6,3.9876515,1267.26144,0.0,0.0,8.227,7.195,2.5546232,1252.51584,1.4848,0.0,7.324,6.473,3.6988576,1207.529472,0.0,0.0,9.453,8.289,3.8633581,1082.871808,0.0,0.0,9.476,7.895
1442591388000,10.0196175,1308.807168,2.295808,0.008192,53.975,50.567,4.024101,1267.249152,0.0,0.0,7.526,6.882,3.0673677,1253.568512,1.777664,0.0,7.264,6.823,3.3841139,1207.119872,0.0,0.0,6.765,6.6,3.1792406,1083.858944,0.032768,0.0,7.754,7.449
1442591448000,12.6856931,1309.720576,3.13344,0.028672,78.672,73.217,4.070262,1267.67104,0.0,0.0,8.385,7.472,5.2805043,1257.730048,2.148352,0.0,10.671,9.176,4.6232302,1206.706176,0.0,0.0,12.683,10.434,4.307703,1083.232256,0.0,0.0,9.928,8.553
1442591508000,11.2227134,1314.422784,2.924544,0.016384,77.861,73.615,4.1853888,1265.54112,0.032768,0.0,10.093,8.355,3.9559292,1253.634048,1.900544,0.0,9.868,8.537,3.8750898,1207.353344,0.0,0.0,9.811,8.865,4.790679,1083.990016,0.0,0.0,11.81,10.072
1442591568000,9.3725884,1311.92832,2.342912,0.028672,63.832,59.373,4.0866834,1271.144448,0.032768,0.0,9.043,7.748,3.5098016,1255.923712,2.000896,0.0,8.413,8.026,3.3892878,1206.669312,0.0,0.0,8.978,7.782,2.6137015,1088.376832,0.0,0.0,8.193,6.846
1442591628000,10.7485424,1311.563776,3.035136,0.028672,71.331,66.843,3.6759202,1264.119808,0.0,0.0,10.354,8.421,3.7927307,1254.137856,2.1504,0.0,8.067,7.121,4.1776856,1208.041472,0.0,0.0,9.503,8.426,4.3529431,1083.633664,0.0,0.0,10.453,9.422
1442591689000,13.0287896,1310.69952,3.575808,0.0,74.784,70.771,4.513047,1273.925632,0.0,0.0,10.779,9.762,5.0619992,1252.999168,1.619968,0.0,9.779,8.461,4.0320557,1207.88992,0.0,0.0,10.648,9.174,4.7213511,1081.413632,0.0,0.0,9.41,7.994
1442591749000,8.0148041,1314.594816,4.173824,0.0,55.431,51.786,1.9142591,1264.656384,0.032768,0.0,5.93,5.031,3.1972075,1250.65216,1.554432,0.0,6.678,5.687,3.9666585,1199.935488,0.0,0.0,9.338,8.126,10.9151052,1078.747136,0.012288,0.0,7.808,6.757
1442591809000,11.2515117,1307.193344,2.322432,0.0,74.613,70.288,3.629959,1263.394816,0.024576,0.0,9.117,7.732,4.325939,1253.044224,1.37216,0.0,10.161,8.671,4.0828846,1200.20992,0.0,0.0,12.06,11.547,3.9476236,1083.920384,0.0,0.0,9.938,8.635
1442591869000,9.9522344,1307.078656,2.904064,0.0,73.618,69.663,3.4537959,1267.716096,0.0,0.0,7.69,6.955,4.309552,1258.745856,2.246656,0.0,9.728,8.97,3.6716315,1198.804992,0.0,0.0,9.965,7.988,3.8060289,1084.47744,0.0,0.0,11.333,9.877
1442591929000,11.2720015,1308.75392,2.6624,0.0,71.433,67.149,4.2127612,1267.245056,0.0,0.0,9.24,9.095,3.5394921,1257.037824,1.785856,0.0,8.259,7.404,4.6318183,1200.566272,0.0,0.0,10.555,9.03,4.042733,1082.945536,0.0,0.0,10.888,8.803
1442591989000,11.2569282,1311.88736,3.28704,0.0,73.151,68.908,4.2061311,1269.600256,0.0,0.0,9.92,8.784,3.0111647,1251.36896,1.662976,0.0,7.404,6.874,5.16589,1202.675712,0.0,0.0,11.622,8.916,4.55186,1085.82912,0.0,0.0,10.716,9.092
1442592049000,10.8757086,1311.45728,3.293184,0.0,75.243,69.996,4.3172204,1267.318784,0.024576,0.0,8.387,7.272,4.6747548,1252.528128,1.845248,0.0,9.612,8.862,4.0269588,1207.898112,0.0,0.0,10.808,9.525,8.4672873,1086.85312,0.0,0.0,11.022,9.482
1442592109000,10.4692105,1313.918976,3.497984,0.0,66.661,62.893,3.7007283,1270.419456,0.004096,0.0,8.01,7.205,4.3551584,1256.030208,2.304,0.0,9.281,9.244,3.8333554,1208.819712,0.0,0.0,8.535,7.617,3.7527604,1085.239296,0.0,0.0,10.495,8.456
1442592169000,11.81424,1313.271808,2.9696,0.0,77.663,72.676,4.6367234,1267.867648,0.0,0.0,8.527,7.73,3.8259971,1256.935424,1.732608,0.0,10.135,8.696,4.7195934,1210.458112,0.0,0.0,11.216,9.48,4.645605,1085.063168,0.0,0.0,11.833,10.308
1442592229000,10.1935425,1312.878592,2.961408,0.0,81.302,76.513,3.0037725,1264.304128,0.0,0.0,9.431,8.351,4.1981063,1256.849408,1.675264,0.0,9.016,7.764,3.7307126,1209.954304,0.0,0.0,11.831,9.595,4.046754,1086.85312,0.0,0.0,12.5,11.111
1442592289000,13.2822735,1314.152448,2.224128,0.0,71.263,67.341,4.0097579,1268.125696,0.0,0.0,6.598,6.004,4.159223,1257.984,1.243136,0.0,9.625,7.432,4.7940986,1212.710912,0.0,0.0,9.631,8.992,6.376957,1088.692224,0.0,0.0,13.377,12.92
1442592349000,8.6411222,1303.728128,4.022272,0.0,64.787,60.913,3.1002318,1260.19584,0.0,0.0,9.228,7.666,3.4951706,1250.025472,1.101824,0.0,6.455,5.747,2.7120627,1203.372032,0.0,0.0,6.983,6.589,4.3151727,1075.986432,0.0,0.0,11.689,10.317
1442592409000,11.3804736,1309.970432,2.904064,0.0,76.453,70.493,4.0417921,1261.891584,0.0,0.0,9.181,8.772,3.8727274,1253.425152,1.425408,0.0,7.516,6.419,4.0159372,1201.147904,0.0,0.0,11.057,10.886,3.9012134,1076.609024,0.0,0.0,11.688,9.848
1442592470000,13.3027143,1311.842304,2.5088,0.0,87.4,81.959,5.0860573,1268.133888,0.0,0.0,11.306,9.857,4.6697316,1252.589568,1.726464,0.0,12.144,10.686,5.0151615,1205.256192,0.0,0.0,12.848,10.668,5.2149287,1077.952512,0.0,0.0,10.798,9.144
1442592530000,11.7674806,1321.209856,4.21888,0.0,73.925,69.883,4.0607119,1273.171968,0.0,0.0,9.193,7.977,5.2698282,1256.87808,1.939456,0.0,9.699,9.063,4.5189175,1202.438144,0.0,0.0,10.19,9.005,4.9214828,1093.414912,0.0,0.0,10.321,9.057
1442592590000,10.2009085,1316.302848,2.90816,0.0,69.265,63.943,3.3594961,1273.667584,0.0,0.0,8.489,7.361,3.850489,1253.933056,1.779712,0.0,7.384,6.554,4.3034421,1206.091776,0.0,0.0,10.067,9.338,4.7310491,1091.05152,0.0,0.0,10.593,9.48
1442592650000,10.5856656,1317.920768,4.07552,0.0,76.075,70.934,3.8502538,1276.3136,0.0,0.0,9.87,9.675,3.7162674,1252.769792,1.306624,0.0,9.742,7.252,3.8013798,1206.525952,0.0,0.0,9.128,7.798,8.5039447,1090.961408,0.0,0.0,11.206,10.584
1442592710000,10.8129672,1318.150144,3.821568,0.0,80.821,75.995,3.620115,1273.663488,0.0,0.0,9.288,7.911,4.0945501,1253.036032,1.792,0.0,10.028,8.684,4.0690165,1209.430016,0.0,0.0,14.684,12.24,4.1398859,1088.270336,0.0,0.0,11.266,10.34
1442592770000,13.2384996,1321.250816,4.317184,0.0,86.248,81.115,5.1059123,1273.716736,0.0,0.0,12.885,10.753,5.2640232,1252.737024,1.134592,0.0,10.543,7.702,4.6337995,1208.037376,0.0,0.0,11.657,9.921,4.7917827,1089.282048,0.0,0.0,12.326,10.531
1442592831000,11.8467042,1319.64928,4.816896,0.0,80.386,75.881,4.0877968,1274.949632,0.0,0.0,8.58,7.571,4.198179,1252.450304,1.851392,0.0,10.798,9.199,3.4055878,1213.472768,0.0,0.0,11.212,9.294,4.9325799,1086.386176,0.0,0.0,11.856,10.077
1442592891000,11.6789907,1320.30464,4.21888,0.0,80.183,75.651,4.7657639,1272.508416,0.0,0.0,11.14,8.978,4.3691416,1257.848832,2.308096,0.0,11.287,9.224,4.405704,1212.90752,0.0,0.0,11.527,9.589,3.8662334,1085.702144,0.036864,0.0,9.27,9.035
1442592951000,10.3782106,1313.660928,4.886528,0.0,70.215,65.751,3.4730525,1255.424,0.0,0.0,9.379,8.402,3.705122,1248.84992,1.224704,0.0,7.423,6.023,4.1541483,1196.711936,0.032768,0.0,10.286,9.251,4.5400502,1077.264384,0.0,0.0,9.495,8.331
1442593011000,10.6745823,1311.281152,3.653632,0.0,76.952,71.914,3.3881649,1257.684992,0.0,0.0,9.982,8.677,4.5369996,1253.810176,2.4576,0.0,9.922,9.016,3.8711899,1195.700224,0.065536,0.0,11.748,9.586,3.1248761,1078.39488,0.0,0.0,8.815,7.975
1442593071000,10.5258458,1316.089856,2.666496,0.0,64.303,59.966,3.9517752,1258.487808,0.0,0.0,8.85,8.482,3.7715727,1251.81952,2.134016,0.0,9.361,8.655,3.183325,1198.32576,0.0,0.0,7.577,6.835,3.2601172,1080.651776,0.0,0.0,8.626,7.743
1442593131000,13.0068017,1316.216832,3.31776,0.0,85.231,79.456,4.3971306,1265.50016,0.0,0.0,10.18,9.906,4.6984797,1250.766848,1.001472,0.0,9.276,7.018,5.6219356,1194.913792,0.032768,0.0,11.465,11.547,4.8057911,1083.920384,0.098304,0.0,13.544,11.208
1442593191000,12.212491,1315.602432,2.625536,0.0,88.526,83.119,4.6614025,1273.315328,0.0,0.0,11.911,10.192,4.3122066,1253.43744,1.562624,0.0,12.247,9.362,5.1004579,1198.297088,0.065536,0.0,13.398,11.807,4.3705276,1094.28736,0.0,0.0,11.592,10.691
1442593251000,20.6433889,1315.721216,4.179968,0.0,57.061,52.602,2.7714249,1273.176064,0.008192,0.0,6.313,5.55,2.7643982,1254.662144,1.3312,0.0,7.141,6.409,3.0585365,1201.082368,0.032768,0.0,7.825,7.17,2.9505409,1097.076736,0.0,0.0,8.387,8.246
1442593312000,10.6460815,1317.60128,4.7104,0.0,81.475,76.383,4.4944113,1276.198912,0.0,0.0,11.052,9.77,3.6637519,1252.421632,1.767424,0.0,11.041,9.156,3.5734444,1200.746496,0.0,0.0,11.751,9.797,3.8345248,1093.193728,0.0,0.0,10.097,9.157
1442593372000,12.1428522,1316.339712,2.781184,0.0,72.838,67.058,4.0093585,1277.534208,0.0,0.0,8.58,7.465,4.313965,1257.668608,2.291712,0.0,9.256,8.639,3.9675617,1204.06016,0.065536,0.0,9.701,8.615,4.3945509,1099.350016,0.0,0.0,10.409,9.383
1442593432000,9.6273558,1315.704832,2.445312,0.0,69.517,64.622,3.3154513,1277.554688,0.0,0.0,8.178,7.514,3.0699548,1258.65984,1.660928,0.0,9.063,7.849,3.389843,1203.314688,0.0,0.0,10.354,9.546,3.7956675,1092.878336,0.0,0.0,8.604,7.823
1442593492000,11.507147,1316.012032,2.772992,0.0,81.665,75.812,5.0994605,1274.585088,0.0,0.0,12.488,10.064,3.6364534,1257.132032,1.509376,0.0,8.885,8.242,4.2492281,1203.003392,0.032768,0.0,10.997,9.162,4.5532585,1094.897664,0.0,0.0,11.545,10.026
1442593552000,8.7684853,1308.082176,3.784704,0.0,66.779,63.071,4.5372362,1254.977536,0.0,0.0,9.447,8.557,2.8570372,1244.270592,1.046528,0.0,7.222,5.804,3.1779316,1200.697344,0.0,0.0,10.526,9.67,3.1574533,1086.11584,0.0,0.0,8.286,7.541
1442593612000,10.1960312,1309.065216,4.97664,0.0,63.966,59.851,4.2223957,1254.793216,0.0,0.0,8.596,7.955,3.1554628,1245.24544,1.804288,0.0,7.099,6.983,3.081917,1200.914432,0.0,0.0,8.182,7.216,4.3677831,1089.18784,0.0,0.0,9.678,8.303
1442593673000,10.4731363,1308.008448,4.599808,0.0,68.939,65.155,3.9918481,1259.393024,0.032768,0.0,8.154,7.651,4.632282,1253.728256,1.759232,0.0,9.234,7.916,3.7477851,1198.272512,0.0,0.0,6.986,6.265,3.9817894,1098.104832,0.0,0.0,12.885,10.445
1442593734000,10.9865677,1305.21088,3.489792,0.0,83.022,77.916,4.0219727,1265.58208,0.0,0.0,11.735,10.335,2.9218052,1256.964096,1.210368,0.0,9.163,7.373,3.7927009,1198.923776,0.032768,0.0,11.385,10.2,4.4791666,1094.26688,0.0,0.0,12.16,10.272
1442593794000,9.9919012,1308.966912,2.371584,0.0,74.257,69.714,3.0956994,1265.819648,0.0,0.0,7.835,7.566,3.6398894,1257.693184,1.189888,0.0,8.621,7.054,3.1557141,1199.476736,0.0,0.0,10.098,9.2,4.5513915,1093.431296,0.0,0.0,13.421,12.265
1442593854000,9.8622837,1305.649152,3.641344,0.0,72.053,66.314,3.6165911,1267.54816,0.0,0.0,7.907,7.235,4.0737963,1259.208704,1.789952,0.0,8.697,8.517,3.5810758,1198.841856,0.0,0.0,8.893,7.668,3.9507486,1100.304384,0.032768,0.0,10.936,9.029
1442593914000,10.9702775,1303.580672,2.166784,0.0,64.236,60.116,3.7537499,1268.40832,0.0,0.0,7.834,6.605,4.3464747,1263.63648,2.060288,0.0,9.407,8.13,4.4685864,1202.675712,0.0,0.0,9.348,8.084,4.1704985,1100.537856,0.0,0.0,10.162,8.706
1442593974000,10.5845623,1304.399872,3.588096,0.0,78.102,73.564,4.216377,1266.335744,0.0,0.0,10.45,9.053,3.4417387,1262.477312,1.550336,0.0,8.454,7.893,3.9446714,1199.947776,0.008192,0.0,11.283,9.372,3.3594398,1098.682368,0.0,0.0,11.74,9.798
1442594034000,10.244893,1307.295744,1.96608,0.0,67.461,62.81,3.4062356,1265.270784,0.032768,0.0,9.172,7.348,3.8943835,1261.727744,1.814528,0.0,8.727,8.323,3.9374293,1200.799744,0.004096,0.0,8.788,7.834,3.7669022,1098.203136,0.0,0.0,9.058,7.433
1442594094000,10.7354582,1307.078656,3.301376,0.0,74.973,69.694,3.8276203,1263.693824,0.0,0.0,9.642,7.448,3.276253,1262.067712,2.23232,0.0,6.527,6.514,4.3594152,1202.72896,0.0,0.0,9.722,8.747,5.1065147,1100.32896,0.0,0.0,13.309,12.011
1442594154000,0.8590256,1290.031104,0.0,0.0,1.133,1.128,0.2076517,1239.891968,0.0,0.0,1.255,1.108,0.3972175,1242.980352,0.065536,0.0,0.375,0.612,0.1743954,1187.188736,0.0,0.0,0.873,0.817,0.2067694,1082.55232,0.032768,0.0,0.45,0.498
1442594214000,9.025152,1310.74048,2.379776,0.0,61.951,58.08,2.8687912,1256.214528,0.0,0.0,7.921,7.1,3.1075817,1249.611776,1.478656,0.0,6.778,6.453,3.340369,1188.962304,0.0,0.0,9.091,7.967,3.3930186,1094.033408,0.0,0.0,8.501,7.855
1442594275000,11.6077793,1307.72992,3.178496,0.0,72.209,66.739,4.0623779,1251.975168,0.0,0.0,8.148,7.328,4.1767508,1253.617664,1.636352,0.0,9.737,8.759,4.6046692,1193.029632,0.0,0.0,9.425,8.193,4.6389425,1094.701056,0.0,0.0,9.972,9.218
1442594335000,10.4849006,1311.666176,2.224128,0.0,73.718,69.655,3.6653975,1254.56384,0.0,0.0,10.731,9.453,2.8696581,1254.940672,1.462272,0.0,7.788,6.628,4.5241371,1200.123904,0.0,0.0,11.116,9.796,3.6931382,1095.614464,0.0,0.0,11.944,9.487
1442594395000,3.4536694,1307.840512,1.765376,0.0,34.015,32.325,1.0743265,1257.971712,0.0,0.0,4.799,4.433,22.5073925,1261.28128,5.052416,0.0,4.35,4.602,1.4335548,1197.318144,0.0,0.0,5.61,4.913,1.6604247,1097.027584,0.0,0.0,4.961,3.95
1442594455000,10.6532818,1309.761536,3.09248,0.0,68.939,64.237,4.5360686,1258.512384,0.0,0.0,8.69,7.44,4.1727458,1259.180032,2.056192,0.0,7.83,7.774,3.2976915,1199.39072,0.0,0.0,9.418,8.872,4.2252553,1098.358784,0.0,0.0,10.084,8.446
1442594515000,10.0655193,1311.019008,5.087232,0.0,69.97,65.968,3.7645292,1258.975232,0.0,0.0,9.697,7.675,3.9042097,1258.78272,1.634304,0.0,9.356,7.976,4.1945594,1200.119808,0.0,0.0,8.544,7.539,3.7854532,1099.452416,0.0,0.0,10.395,9.415
1442594575000,11.5360228,1314.926592,3.551232,0.0,76.464,71.699,4.7474193,1259.040768,0.0,0.0,8.704,7.683,3.9642861,1256.071168,1.204224,0.0,9.118,6.984,4.5980507,1199.960064,0.0,0.0,11.107,9.146,4.6223017,1104.257024,0.0,0.0,12.133,10.882
1442594636000,8.1499788,1314.738176,3.82976,0.0,61.912,57.607,3.3023744,1261.318144,0.0,0.0,8.547,7.406,2.3832771,1255.780352,1.44384,0.006144,6.896,5.963,2.9770968,1199.792128,0.0,0.0,8.523,8.013,3.0365711,1104.334848,0.0,0.0,8.457,8.42
1442594697000,10.6382258,1308.971008,1.880064,0.024576,65.077,60.504,3.5242586,1256.706048,0.028672,0.0,8.105,7.954,3.6117224,1249.411072,1.329152,0.002048,8.638,6.904,3.5951597,1224.863744,0.0,0.0,9.456,7.939,3.495717,1100.025856,0.0,0.0,9.459,8.324
1442594757000,6.5040785,1308.483584,2.945024,0.012288,58.753,54.923,2.9159432,1249.255424,0.0,0.0,8.565,6.526,1.9839881,1247.883264,1.318912,0.014336,5.984,5.563,2.4790163,1218.08896,0.0,0.0,9.723,8.347,2.4262782,1096.011776,0.0,0.0,7.485,6.674
1442594817000,10.2641565,1304.735744,2.035712,0.024576,60.941,56.557,4.4427961,1253.801984,0.032768,0.0,11.286,8.984,3.684526,1244.663808,1.04448,0.002048,6.036,5.074,3.9037767,1216.520192,0.032768,0.0,7.932,8.018,4.2045384,1092.222976,0.0,0.0,7.828,6.876
1442594877000,9.6766049,1303.10144,2.547712,0.028672,75.917,70.152,4.3550787,1251.565568,0.0,0.0,10.067,8.782,3.8062406,1242.099712,1.6896,0.01024,8.642,7.411,3.7887166,1219.661824,0.0,0.0,9.642,8.269,3.5613996,1088.966656,0.0,0.0,12.544,10.169
1442594937000,10.2917355,1305.366528,1.67936,0.016384,65.265,61.234,4.3366326,1250.304,0.094208,0.0,8.944,8.014,3.1894988,1242.906624,1.349632,0.01024,8.141,6.633,3.5789963,1223.852032,0.0,0.0,10.248,8.632,4.0211663,1093.234688,0.0,0.0,8.98,8.18
1442594997000,9.4252044,1309.724672,2.781184,0.012288,48.334,44.419,4.0826302,1253.855232,0.032768,0.0,6.955,6.15,2.3293752,1241.243648,1.572864,0.004096,5.384,5.113,2.9406966,1220.579328,0.0,0.0,7.996,6.844,2.7749266,1094.094848,0.0,0.0,6.07,4.969
1442595057000,8.8701915,1308.127232,2.78528,0.012288,60.715,56.17,3.633318,1254.735872,0.0,0.0,8.699,7.937,3.0828818,1245.34784,1.959936,0.006144,7.159,6.665,3.3374585,1223.266304,0.0,0.0,6.739,6.255,2.9531116,1095.04512,0.0,0.0,9.372,7.499
1442595117000,8.5772705,1308.63104,2.236416,0.012288,47.849,44.783,3.4404273,1253.29408,0.0,0.0,7.343,6.881,2.2311534,1243.19744,1.755136,0.01024,6.254,5.662,3.4161832,1222.51264,0.0,0.0,6.874,6.053,2.8576943,1099.603968,0.0,0.0,5.412,4.985
1442595177000,11.8989752,1312.239616,1.593344,0.016384,68.173,63.885,4.2768994,1255.50592,0.0,0.0,8.701,7.838,3.8049489,1242.80832,0.923648,0.012288,7.56,6.445,4.1632536,1221.050368,0.0,0.0,10.853,9.599,4.7847928,1099.485184,0.0,0.0,10.051,9.302
1442595237000,9.8261624,1312.768,3.149824,0.012288,63.235,58.962,4.4715824,1257.472,0.0,0.0,8.972,8.359,3.6666584,1250.177024,1.591296,0.012288,8.477,7.468,3.5399436,1221.61152,0.0,0.0,9.003,7.405,3.2012192,1096.204288,0.0,0.0,7.676,5.354
1442595297000,11.4194083,1313.492992,5.853184,0.024576,70.439,66.425,4.6334482,1260.613632,0.0,0.0,8.59,6.788,4.0616311,1248.858112,1.767424,0.014336,8.75,8.02,3.4327421,1223.553024,0.004096,0.0,8.508,6.855,4.2401172,1097.854976,0.0,0.0,12.502,11.114
1442595357000,10.0337658,1311.727616,3.026944,0.012288,63.031,59.458,3.4136282,1260.040192,0.0,0.0,7.204,5.982,3.9410462,1252.081664,2.154496,0.012288,9.154,8.438,3.8020938,1224.024064,0.0,0.0,7.913,6.68,3.6650689,1104.83456,0.028672,0.0,10.572,9.575
1442595417000,9.2369285,1299.673088,2.813952,0.008192,61.244,57.003,3.3549914,1248.39936,0.0,0.0,6.985,6.976,2.7667941,1244.975104,1.49504,0.012288,8.007,7.507,3.835854,1209.782272,0.0,0.0,9.433,7.498,3.2301001,1096.04864,0.0,0.0,8.463,6.984
1442595477000,5.3004614,1303.437312,1.222656,0.004096,17.787,16.891,3.646211,1261.52704,2.387968,0.0,2.713,2.518,2.5350872,1246.482432,0.630784,0.01024,3.202,2.829,1.6341589,1203.28192,0.0,0.0,2.664,2.412,2.0740534,1103.765504,0.0,0.0,3.273,3.089
1442595537000,12.35112,1306.591232,3.076096,0.0,66.84,62.294,3.6568971,1255.34208,0.0,0.0,6.409,6.058,4.4987651,1246.195712,2.00704,0.006144,7.665,7.399,4.5045873,1204.98176,0.0,0.0,9.9,8.878,4.8055989,1100.824576,0.0,0.0,10.797,8.831
1442595597000,9.9252017,1307.922432,3.719168,0.0,60.093,56.288,3.6357218,1254.27712,0.032768,0.0,7.373,6.149,3.6997352,1248.78848,1.345536,0.012288,7.562,6.459,3.7564358,1211.0848,0.0,0.0,8.877,7.523,3.4877648,1104.039936,0.032768,0.0,9.158,8.022
1442595657000,13.1042747,1308.655616,2.347008,0.0,81.834,76.464,5.2809236,1253.04832,0.0,0.0,11.315,10.424,4.7412514,1246.076928,1.906688,0.014336,10.037,8.878,4.7060692,1208.479744,0.0,0.0,10.53,9.92,4.9292314,1101.66016,0.0,0.0,11.882,9.338
1442595717000,9.7502427,1317.90848,3.936256,0.0,62.709,58.325,4.0057262,1259.896832,0.0,0.0,10.02,8.171,3.3726838,1243.701248,1.701888,0.012288,8.415,7.734,2.7769057,1201.963008,0.0,0.0,6.514,6.286,3.2250233,1097.129984,0.0,0.0,8.57,7.23
1442595778000,9.3247125,1310.851072,2.715648,0.032768,77.088,72.121,2.7682266,1244.422144,0.0,0.0,10.207,8.657,3.3340135,1249.497088,1.431552,0.0,8.644,7.348,3.5104539,1208.967168,0.0,0.0,9.73,8.876,4.4707658,1096.76544,0.0,0.0,12.952,11.745
1442595839000,9.7230354,1313.009664,4.067328,0.032768,65.934,61.818,4.5050171,1245.966336,0.012288,0.0,10.778,9.386,3.2480412,1248.256,0.598016,0.0,7.372,5.68,3.1389451,1203.46624,0.0,0.0,8.238,7.658,3.1666111,1102.270464,0.0,0.0,9.903,8.303
1442595899000,10.9305449,1309.892608,2.420736,0.028672,64.755,60.799,2.8634641,1246.49472,0.0,0.0,7.29,6.426,3.5602024,1247.830016,1.529856,0.0,8.968,8.145,4.4801991,1204.30592,0.0,0.0,9.448,9.306,4.4480488,1101.12768,0.0,0.0,10.036,8.818
1442595959000,9.7721801,1308.741632,3.608576,0.016384,62.021,58.16,4.4464932,1248.309248,0.0,0.0,10.103,8.968,3.3005763,1249.660928,1.327104,0.0,7.96,6.888,4.0848179,1206.70208,0.0,0.0,7.418,6.529,3.877383,1102.880768,0.0,0.0,9.581,7.685
1442596019000,9.0581517,1308.450816,3.260416,0.012288,59.247,56.037,3.5751521,1237.23776,0.012288,0.0,7.417,6.947,3.222332,1240.825856,1.181696,0.0,6.478,5.719,2.8597712,1198.53056,0.0,0.0,9.343,8.124,3.2842792,1099.001856,0.032768,0.0,9.753,8.066
1442596079000,11.5961417,1307.291648,3.432448,0.024576,63.391,59.525,3.6200102,1240.236032,0.0,0.0,9.034,8.034,3.0087339,1245.42976,1.284096,0.0,7.342,6.872,3.3028337,1200.541696,0.0,0.0,9.609,8.078,2.9173383,1105.436672,0.0,0.0,7.732,6.557
1442596140000,10.735158,1310.294016,3.497984,0.0,64.308,60.955,3.442091,1240.399872,0.0,0.0,8.496,7.636,4.0963257,1242.25536,1.95584,0.0,7.553,7.276,3.0723851,1200.685056,0.0,0.0,8.356,7.025,4.6092034,1101.316096,0.0,0.0,10.472,9.847
1442596201000,9.6920625,1309.679616,3.018752,0.0,65.85,61.162,3.8282033,1240.068096,0.0,0.0,8.626,6.963,3.1833908,1240.436736,1.683456,0.0,9.14,7.673,4.1827269,1200.553984,0.0,0.0,8.596,7.999,3.318552,1102.761984,0.0,0.0,9.34,8.019
1442596261000,9.4254245,1309.753344,2.465792,0.0,72.029,67.192,3.5569113,1249.529856,0.0,0.0,9.332,8.536,2.9488384,1242.570752,1.501184,0.0,7.115,7.355,3.3613116,1201.893376,0.0,0.0,10.155,8.739,3.8884355,1099.739136,0.0,0.0,13.27,11.053
1442596321000,10.9810656,1306.791936,1.609728,0.0,68.911,64.46,3.9797897,1248.82944,0.0,0.0,9.909,9.144,3.7662306,1240.547328,1.88416,0.0,8.627,8.098,3.6892519,1207.263232,0.0,0.0,9.556,8.849,3.7964679,1098.338304,0.0,0.0,8.935,8.282
1442596381000,11.4525294,1308.889088,5.214208,0.0,79.487,73.628,3.8546279,1248.014336,0.032768,0.0,9.822,8.037,3.0187994,1239.04,0.948224,0.0,8.844,6.553,4.3034044,1209.769984,0.0,0.0,12.477,10.371,5.0745688,1100.71808,0.0,0.0,11.737,11.055
1442596441000,10.4927233,1313.427456,5.271552,0.0,67.728,63.955,3.3438685,1250.79552,0.0,0.0,8.833,7.413,3.3178197,1242.636288,1.39264,0.0,7.218,6.793,3.059711,1213.579264,0.0,0.0,10.335,9.826,3.8640809,1098.473472,0.0,0.0,9.671,8.821
1442596501000,9.9283288,1317.82656,4.161536,0.0,66.888,62.743,4.8684289,1253.380096,0.0,0.0,9.79,8.411,4.0412927,1244.934144,1.544192,0.0,8.018,6.904,2.2061887,1212.002304,0.0,0.0,8.033,6.162,4.0450362,1100.095488,0.0,0.0,10.136,8.736
1442596561000,11.7568934,1316.286464,3.323904,0.0,81.453,76.67,4.0821358,1252.974592,0.0,0.0,10.882,8.816,4.1145783,1240.342528,1.609728,0.0,10.599,8.642,5.0674057,1210.609664,0.0,0.0,10.844,9.303,3.3971107,1101.25056,0.0,0.0,11.64,9.424
1442596622000,10.7165641,1307.246592,2.670592,0.0,70.514,66.185,3.354896,1241.419776,0.032768,0.0,8.427,7.643,4.0319439,1240.182784,1.810432,0.0,8.431,7.566,3.4619826,1195.25376,0.0,0.0,11.01,9.429,3.9879674,1099.214848,0.0,0.0,10.33,9.633
1442596682000,9.0629087,1309.560832,3.387392,0.0,61.836,57.192,2.0135122,1240.887296,0.0,0.0,6.216,5.959,3.0475382,1238.85568,1.363968,0.0,6.485,5.334,3.9847877,1197.28128,0.0,0.0,10.087,8.655,2.8881255,1097.146368,0.0,0.0,9.702,9.083
1442596742000,9.5587182,1311.490048,4.05504,0.0,69.366,64.781,4.6222554,1246.789632,0.0,0.0,9.516,8.32,3.2243234,1237.745664,1.841152,0.0,9.336,7.659,3.076419,1199.775744,0.0,0.0,7.277,6.185,4.2429857,1099.460608,0.0,0.0,11.565,9.364
1442596802000,9.6711583,1311.281152,2.72384,0.0,80.876,75.723,3.4264996,1244.307456,0.0,0.0,10.06,9.044,3.3200182,1237.89312,1.560576,0.0,9.14,7.72,3.8691863,1200.488448,0.0,0.0,11.884,10.531,3.5655086,1110.663168,0.0,0.0,13.005,10.263
1442596862000,9.9703111,1313.214464,3.411968,0.0,69.048,64.616,3.8297139,1246.633984,0.0,0.0,10.118,8.966,3.4087178,1242.484736,1.572864,0.0,7.816,6.887,3.7912096,1202.405376,0.0,0.0,10.35,8.628,3.1947256,1106.128896,0.0,0.0,8.494,7.507
1442596922000,10.1976673,1310.027776,1.82272,0.0,71.873,67.769,4.1252118,1246.24896,0.0,0.0,10.864,8.53,3.5805149,1248.894976,2.213888,0.0,8.403,7.937,3.9295167,1203.023872,0.0,0.0,9.959,9.905,3.7951413,1104.896,0.0,0.0,10.605,8.705
1442596983000,9.5547804,1315.328,4.263936,0.0,68.177,63.153,3.8704834,1248.813056,0.0,0.0,10.507,8.645,2.8411154,1248.169984,0.647168,0.0,7.294,5.353,2.9575964,1203.191808,0.0,0.0,9.111,9.276,3.3311409,1113.186304,0.0,0.0,8.79,7.916
1442597043000,11.1514507,1306.107904,3.223552,0.0,79.242,74.216,4.2637613,1254.977536,0.0,0.0,10.274,8.894,3.7336618,1239.658496,1.292288,0.0,8.457,7.358,4.5237454,1208.528896,0.0,0.0,10.748,8.602,3.7381702,1102.848,0.0,0.0,12.316,10.321
1442597103000,10.4138058,1309.298688,2.84672,0.0,81.655,75.544,4.6659099,1253.015552,0.0,0.0,9.222,7.662,3.4810623,1241.305088,1.488896,0.0,9.575,8.683,3.3588724,1211.932672,0.0,0.0,13.391,10.459,4.3596674,1103.294464,0.0,0.0,10.499,9.365
1442597163000,9.9850588,1307.38176,4.022272,0.0,69.183,64.963,3.4045076,1253.408768,0.0,0.0,8.526,7.269,3.9362132,1244.34432,2.578432,0.0,9.667,8.674,4.2122598,1207.103488,0.0,0.0,8.807,7.595,4.1411521,1103.425536,0.0,0.0,10.194,8.416
1442597223000,10.6543765,1301.929984,3.100672,0.0,63.322,58.934,3.8367305,1247.137792,0.024576,0.0,8.449,7.407,3.3856553,1235.84512,1.509376,0.0,6.878,6.171,4.2562536,1199.198208,0.0,0.0,9.779,8.689,4.0135854,1094.365184,0.0,0.0,10.039,8.222
1442597283000,9.6226061,1306.959872,2.850816,0.0,69.17,65.271,3.7359361,1249.529856,0.016384,0.0,9.888,6.979,3.3297908,1233.641472,1.304576,0.0,8.04,6.453,4.4060923,1198.637056,0.065536,0.0,9.318,8.334,3.8201462,1093.668864,0.0,0.0,11.108,8.617
1442597343000,10.783613,1309.0816,4.79232,0.0,72.501,69.231,4.1858918,1251.262464,0.004096,0.0,9.373,7.649,3.1480543,1234.071552,1.538048,0.0,7.521,6.832,4.3819007,1202.561024,0.0,0.0,11.181,9.566,4.9581238,1091.584,0.016384,0.0,12.939,10.149
1442597403000,9.5052346,1310.12608,4.151296,0.0,65.946,61.685,3.4403108,1250.95936,0.0,0.0,8.81,7.334,3.0745535,1234.690048,1.13664,0.0,6.407,5.769,3.9581198,1206.616064,0.0,0.0,8.575,7.888,3.9441787,1089.96608,0.0,0.0,11.839,9.523
1442597463000,12.11372,1310.871552,4.132864,0.0,63.116,59.352,4.1242839,1258.55744,0.0,0.0,8.634,6.927,4.2106554,1240.588288,1.579008,0.0,7.244,6.037,3.9047388,1209.99936,0.0,0.0,8.111,7.966,4.3825129,1091.244032,0.0,0.0,8.917,8.21
1442597523000,11.0839204,1306.247168,1.933312,0.0,76.514,70.9,4.7051334,1254.813696,0.04096,0.0,11.735,10.172,3.8310733,1236.123648,1.64864,0.0,7.888,6.545,4.0458305,1204.609024,0.032768,0.0,11.203,10.109,3.4128816,1094.144,0.032768,0.0,9.989,8.201
1442597583000,11.7712325,1310.642176,4.296704,0.0,73.86,69.041,4.8397886,1255.264256,0.0,0.0,9.213,7.709,3.3485047,1232.809984,1.19808,0.0,9.353,7.829,4.1790145,1204.723712,0.0,0.0,9.891,8.254,4.1332892,1098.747904,0.0,0.0,11.369,9.456
1442597643000,9.7252827,1308.626944,3.145728,0.0,64.866,60.336,3.457674,1258.405888,0.0,0.0,7.638,6.848,3.9344564,1234.587648,2.056192,0.0,8.679,7.657,3.1013934,1206.124544,0.0,0.0,8.149,7.846,3.8235378,1096.839168,0.008192,0.0,8.553,8.164
1442597703000,10.052854,1309.773824,3.534848,0.0,69.702,65.332,3.7430478,1257.603072,0.0,0.0,8.045,6.919,2.5579855,1242.169344,1.609728,0.0,7.84,7.123,3.8100144,1204.61312,0.0,0.0,11.837,10.307,3.6660905,1098.088448,0.0,0.0,10.441,8.866
1442597763000,10.9800968,1309.67552,3.272704,0.0,78.025,72.718,4.3268202,1255.038976,0.0,0.0,9.647,8.656,3.9109747,1238.372352,1.452032,0.0,8.465,6.73,4.1559612,1213.046784,0.0,0.0,11.719,11.154,4.4177497,1096.994816,0.0,0.0,11.48,9.303
1442597823000,10.2306736,1305.206784,4.538368,0.0,66.273,62.449,4.1141961,1245.40928,0.032768,0.0,8.612,7.705,3.9211639,1229.08672,1.531904,0.0,9.003,7.29,3.7636999,1200.0256,0.0,0.0,8.887,7.434,2.9072478,1089.20832,0.0,0.0,9.418,7.548
1442597883000,9.3363498,1306.906624,3.854336,0.0,67.032,62.995,3.4242563,1249.75104,0.012288,0.0,8.733,7.836,2.0068317,1232.904192,1.087488,0.0,6.813,5.44,3.4746137,1206.177792,0.0,0.0,8.929,7.799,4.2822741,1094.643712,0.0,0.0,10.62,9.529
1442597943000,10.2185837,1306.738688,2.973696,0.0,67.878,64.056,3.0861744,1250.189312,0.0,0.0,8.294,7.347,3.902493,1233.195008,1.830912,0.0,8.397,7.718,3.5347736,1206.480896,0.0,0.0,10.704,9.55,3.5080575,1090.27328,0.0,0.0,9.936,8.527
1442598003000,9.8975321,1308.020736,2.736128,0.0,65.576,61.852,3.2463393,1251.864576,0.0,0.0,8.484,7.594,3.3096198,1231.5648,1.181696,0.0,7.987,6.747,3.8340693,1203.892224,0.0,0.0,9.423,8.169,3.8485289,1092.345856,0.0,0.0,10.083,8.977
1442598063000,10.2479049,1311.428608,3.936256,0.0,68.906,64.584,4.0599,1252.421632,0.004096,0.0,8.268,7.876,2.7135977,1234.563072,1.759232,0.0,7.864,7.992,4.4344785,1205.4528,0.0,0.0,8.92,7.52,3.915369,1096.249344,0.0,0.0,10.75,8.823
1442598123000,9.8433394,1315.008512,1.72032,0.0,66.445,61.441,2.2409458,1254.281216,0.0,0.0,7.968,6.615,3.6330503,1231.413248,1.728512,0.0,9.15,8.038,4.2392932,1209.544704,0.0,0.0,9.146,8.589,3.7754057,1099.108352,0.0,0.0,9.443,9.651
1442598183000,11.4427611,1312.62464,3.371008,0.0,74.966,69.326,4.0473222,1259.311104,0.0,0.0,10.505,8.942,4.6126347,1232.822272,1.570816,0.0,10.012,8.884,4.361024,1207.832576,0.0,0.0,9.105,8.724,3.8957358,1100.357632,0.0,0.0,10.568,9.073
1442598243000,6.139891,1293.238272,1.921024,0.0,53.911,50.825,99.7493143,1425.788928,0.0,0.0,7.999,7.565,2.6555242,1209.094144,1.634304,0.0,6.634,5.931,1.7407205,1165.426688,0.0,0.0,5.921,5.225,2.7579968,1080.868864,0.0,0.0,10.678,8.961
1442598303000,7.5976873,1278.500864,2.289664,0.0,56.268,53.022,99.7012516,1440.157696,0.032768,0.0,8.865,7.464,2.409525,1190.309888,1.411072,0.0,6.446,5.533,2.7513653,1145.176064,0.0,0.0,8.636,8.333,2.84646,1081.208832,0.0,0.0,7.12,6.701
1442598363000,6.2696211,1286.610944,3.948544,0.0,50.04,46.798,104.781776,1433.76384,0.0,0.0,5.435,4.111,1.8942655,1190.756352,1.869824,0.0,6.45,5.815,2.7365201,1145.11872,0.0,0.0,8.934,8.253,2.2288263,1077.67808,0.0,0.0,6.837,6.529
1442598423000,2.3258972,1277.652992,1.86368,0.0,38.356,36.26,99.1924083,1404.678144,0.0,0.0,4.505,3.739,0.8877221,1166.094336,0.96256,0.0,5.229,4.315,1.068647,1116.188672,0.0,0.0,5.837,5.105,0.7895892,1065.529344,0.0,0.0,6.795,5.93
1442598483000,6.7538336,1295.572992,3.096576,0.0,64.667,61.004,99.6411987,1411.162112,0.0,0.0,6.963,5.991,2.5711261,1177.792512,1.72032,0.0,7.951,6.602,2.6995872,1122.357248,0.0,0.0,10.59,8.715,2.6123209,1079.271424,0.0,0.0,10.824,9.925
1442598543000,7.6380726,1294.11072,3.506176,0.0,59.184,56.004,99.7477389,1413.210112,0.065536,0.0,7.087,6.833,3.2134932,1181.810688,1.66912,0.0,8.152,7.294,2.9993898,1125.859328,0.0,0.0,7.464,6.666,2.3869556,1080.459264,0.0,0.0,9.626,7.433
1442598603000,6.2570121,1292.713984,2.523136,0.0,47.906,44.713,99.7779482,1415.286784,0.0,0.0,5.528,5.421,1.8805016,1182.437376,1.654784,0.0,5.727,5.647,2.1251761,1129.549824,0.0,0.0,7.941,6.621,2.1934599,1080.139776,0.0,0.0,6.529,5.428
1442598664000,7.363798,1293.463552,2.125824,0.0,64.743,59.933,99.6921721,1420.460032,0.0,0.0,8.381,7.406,2.8363698,1182.142464,1.77152,0.0,8.877,7.674,2.7394077,1129.824256,0.0,0.0,7.885,6.969,2.4455682,1078.321152,0.0,0.0,9.194,8.605
1442598724000,6.368173,1297.276928,3.334144,0.0,71.939,66.232,99.6397839,1421.23008,0.0,0.0,8.392,7.76,2.4472088,1183.678464,1.861632,0.0,9.454,8.31,2.5967656,1128.935424,0.0,0.0,8.543,7.881,1.7397106,1078.771712,0.0,0.0,10.892,9.199
1442598785000,8.3529208,1299.709952,3.833856,0.0,69.926,65.499,99.9236995,1421.979648,0.0,0.0,10.266,8.062,3.3621917,1183.080448,1.329152,0.0,9.448,7.786,2.8481879,1128.484864,0.0,0.0,8.833,8.128,3.0631464,1080.184832,0.032768,0.0,8.645,7.872
1442598845000,7.6321109,1299.173376,3.047424,0.0,57.126,54.492,99.7327257,1423.233024,0.036864,0.0,8.639,7.359,2.3984281,1183.6416,1.216512,0.0,6.203,5.47,2.9309304,1130.827776,0.0,0.0,8.26,7.422,2.9966772,1082.052608,0.0,0.0,9.633,8.365
1442598905000,10.9104699,1317.781504,1.949696,0.0,62.599,58.514,3.759017,1211.510784,0.0,0.0,6.307,5.755,3.9156488,1206.308864,2.373632,0.0,8.586,7.873,3.8699774,1145.561088,0.0,0.0,8.577,7.473,4.2276201,1104.912384,0.0,0.0,11.409,9.723
1442598965000,10.0530137,1317.859328,3.682304,0.0,59.032,55.455,3.2556593,1209.335808,0.004096,0.0,9.345,8.396,3.3584249,1205.63712,1.49504,0.0,7.06,6.318,3.8916458,1147.53536,0.0,0.0,7.794,7.177,3.3551107,1107.90656,0.0,0.0,7.918,7.249
1442599026000,9.3242379,1318.723584,2.125824,0.0,73.561,68.312,3.3808964,1214.185472,0.016384,0.0,9.437,7.931,3.5190316,1203.716096,1.748992,0.01024,9.918,8.139,3.5145485,1149.464576,0.0,0.0,9.307,7.749,3.3046955,1101.033472,0.0,0.0,11.162,9.796
1442599086000,9.5531026,1315.639296,3.555328,0.0,64.367,59.749,2.4051211,1214.550016,0.098304,0.0,7.463,6.321,2.7261784,1199.845376,1.202176,0.012288,7.069,6.634,4.287618,1149.15328,0.0,0.0,9.434,7.803,3.9962883,1108.000768,0.0,0.0,10.747,9.986
1442599146000,10.6827788,1316.491264,3.01056,0.0,71.259,66.65,4.2622805,1214.652416,0.0,0.0,10.597,9.197,3.0524746,1203.204096,1.038336,0.01024,7.496,6.167,3.4298917,1149.91104,0.0,0.0,10.461,8.164,4.1377198,1105.514496,0.0,0.0,10.28,9.511
1442599206000,8.2937059,1320.239104,3.186688,0.0,58.714,54.511,3.2825453,1218.502656,0.0,0.0,7.931,7.946,2.8757617,1206.849536,1.163264,0.012288,8.267,6.365,3.1290241,1151.389696,0.0,0.0,7.223,6.965,2.4276485,1104.805888,0.0,0.0,7.891,7.215
1442599266000,8.5035363,1320.882176,2.842624,0.0,70.717,65.342,2.7378208,1216.303104,0.0,0.0,8.853,7.566,2.7384999,1206.460416,1.099776,0.006144,7.796,6.663,3.0507454,1157.09952,0.0,0.0,9.418,9.517,3.4522001,1107.451904,0.0,0.0,10.745,9.567
1442599326000,5.6120185,1321.623552,2.859008,0.0,65.271,61.623,2.3093486,1218.850816,0.0,0.0,11.948,10.018,3.0949946,1209.532416,0.755712,0.012288,5.63,4.404,2.7845839,1158.873088,0.0,0.0,9.117,8.301,2.3675566,1107.709952,0.0,0.0,10.45,8.984
1442599386000,9.4461128,1321.365504,1.724416,0.0,47.338,44.667,2.6316472,1222.156288,4.407296,0.0,6.046,4.981,3.9732432,1206.5792,1.34144,0.012288,6.754,6.38,3.4859683,1159.90528,0.0,0.0,7.867,6.577,3.2926037,1109.069824,0.0,0.0,6.727,6.269
1442599446000,11.423464,1324.961792,2.367488,0.0,79.505,74.447,4.5228063,1223.929856,0.0,0.0,11.476,9.845,4.0635446,1210.322944,1.910784,0.002048,8.913,7.536,3.9391292,1161.056256,0.004096,0.0,10.619,9.222,4.0390969,1113.706496,0.0,0.0,12.261,10.582
1442599506000,10.746105,1324.802048,3.002368,0.0,87.046,81.938,4.397789,1227.452416,0.0,0.0,11.737,10.294,4.3018338,1208.99584,1.841152,0.016384,11.163,9.499,4.6080922,1161.150464,0.0,0.0,13.918,11.944,3.8384485,1111.248896,0.0,0.0,11.087,9.867
1442599567000,13.5929452,1326.964736,2.347008,0.0,64.386,59.538,3.9073687,1232.0768,0.0,0.0,7.658,6.778,2.927648,1211.666432,1.81248,0.014336,7.45,7.012,2.9219682,1163.85792,0.0,0.0,9.304,7.84,3.4158288,1113.030656,0.0,0.0,9.965,9.31
1442599628000,10.0333531,1327.57504,2.686976,0.016384,73.199,67.523,3.295224,1228.181504,0.0,0.0,6.167,6.206,3.5240536,1209.581568,1.452032,0.0,9.966,8.891,3.6147234,1166.323712,0.0,0.0,11.288,10.237,3.7819766,1113.66144,0.0,0.0,11.22,9.895
1442599688000,11.0447976,1321.234432,3.50208,0.02048,63.647,59.993,3.5432733,1218.932736,0.0,0.0,8.303,7.324,4.2073379,1206.546432,1.34144,0.0,6.982,6.396,4.1861102,1151.967232,0.0,0.0,8.433,7.33,4.2340318,1110.114304,0.618496,0.0,9.195,8.221
1442599748000,11.1080234,1321.336832,3.260416,0.012288,74.162,69.804,3.9821557,1219.629056,0.0,0.0,8.853,7.454,4.0587595,1200.836608,1.585152,0.0,10.436,8.587,3.7166347,1154.527232,0.0,0.0,10.773,9.081,4.7155093,1109.123072,0.0,0.0,11.048,9.739
1442599808000,13.1299288,1323.23328,2.74432,0.02048,89.885,84.043,4.6576083,1216.585728,0.0,0.0,12.957,11.365,5.1346095,1207.304192,1.998848,0.0,10.961,10.052,4.6748619,1157.115904,0.0,0.0,12.145,10.365,4.3042084,1109.950464,0.0,0.0,13.291,10.552
1442599868000,10.9935729,1323.880448,3.346432,0.036864,78.175,73.128,3.2439742,1215.934464,0.0,0.0,7.861,7.044,3.2198654,1203.93728,0.847872,0.0,8.451,6.79,4.3585731,1158.672384,0.0,0.0,12.38,10.953,4.7362804,1115.070464,0.028672,0.0,13.359,11.499
1442599928000,9.0339061,1325.006848,1.875968,0.02048,60.114,56.366,3.3328654,1218.400256,0.0,0.0,7.529,7.393,3.305035,1203.318784,1.183744,0.0,6.745,5.662,3.2487799,1156.325376,0.0,0.0,8.937,8.6,3.1403444,1111.822336,0.0,0.0,9.852,8.25
1442599989000,9.301831,1332.641792,2.420736,0.012288,70.608,65.565,3.5802522,1219.272704,0.004096,0.0,8.298,7.719,3.2633968,1207.689216,0.649216,0.0,8.853,6.34,3.4837722,1167.835136,0.0,0.0,9.32,8.539,3.4824517,1113.309184,0.0,0.0,11.647,10.171
1442600049000,9.1985683,1331.847168,3.54304,0.02048,60.397,56.164,3.0478201,1217.949696,0.0,0.0,8.652,7.672,3.196614,1206.94784,1.411072,0.0,5.648,5.796,3.2008507,1164.300288,0.0,0.0,7.952,7.758,3.1556325,1113.595904,0.0,0.0,7.367,6.929
1442600109000,10.8408668,1330.491392,4.591616,0.016384,75.364,70.697,4.6630587,1220.161536,0.0,0.0,10.412,9.25,3.6390717,1210.159104,0.813056,0.0,7.883,5.694,4.6089276,1167.835136,0.0,0.0,11.424,9.437,4.3629221,1112.875008,0.0,0.0,10.851,10.311
1442600169000,9.5558767,1330.765824,3.219456,0.028672,71.08,66.023,3.5107882,1234.57536,0.0,0.0,10.747,9.338,3.7579275,1210.699776,1.3312,0.0,8.403,6.916,3.6366752,1166.073856,0.0,0.0,10.166,7.866,3.4202582,1111.547904,0.0,0.0,9.486,8.711
1442600229000,9.9074687,1330.929664,3.076096,0.028672,61.591,57.955,3.5623431,1230.62272,0.0,0.0,7.198,7.345,3.4238904,1211.654144,2.183168,0.0,8.325,7.281,3.0164317,1163.485184,0.0,0.0,8.033,7.575,3.8350479,1111.519232,0.0,0.0,9.841,8.191
1442600289000,9.7433631,1326.845952,4.272128,0.02048,72.479,67.743,3.6934234,1219.489792,0.0,0.0,8.039,7.047,3.3986066,1194.397696,1.366016,0.0,7.705,6.986,3.5007578,1156.599808,0.0,0.0,11.356,10.101,3.8079683,1100.242944,0.0,0.0,10.692,8.943
1442600349000,10.3583346,1325.3632,4.214784,0.024576,75.918,71.352,3.5179758,1221.124096,0.0,0.0,9.65,8.473,3.9846255,1198.55104,2.203648,0.0,9.562,8.286,3.804934,1155.62496,0.0,0.0,10.146,7.876,3.9599798,1102.516224,0.0,0.0,10.688,9.071
1442600409000,11.1899031,1331.802112,3.651584,0.028672,73.57,69.335,3.9021944,1221.496832,0.0,0.0,11.319,9.769,3.0723595,1199.702016,1.359872,0.0,7.362,6.11,4.5155727,1157.357568,0.0,0.0,10.067,8.895,3.9480333,1108.774912,0.0,0.0,11.882,9.59
1442600469000,10.592876,1327.435776,2.72384,0.024576,57.246,54.02,3.6837251,1218.531328,0.0,0.0,7.603,6.623,3.2483115,1197.072384,1.071104,0.0,7.456,6.41,3.6487866,1151.655936,0.0,0.0,8.02,6.692,4.5085917,1114.591232,0.0,0.0,9.063,8.744
1442600529000,13.0153102,1335.853056,3.13344,0.012288,45.254,42.703,3.3491283,1220.317184,0.0,0.0,8.162,5.735,3.0997939,1199.43168,1.384448,0.0,5.182,5.077,4.6478833,1154.02752,0.0,0.0,8.114,6.801,2.6843593,1115.901952,0.0,0.0,4.785,3.902
1442600589000,11.2136057,1333.727232,1.359872,0.004096,37.185,35.188,3.4530255,1222.115328,0.0,0.0,5.343,4.591,3.19467,1204.228096,0.786432,0.0,4.428,3.798,2.5162392,1157.582848,0.0,0.0,4.614,3.628,4.0982118,1117.323264,0.0,0.0,7.857,6.41
1442600649000,4.4537795,1335.263232,0.516096,0.016384,20.996,19.559,1.3088012,1221.89824,0.0,0.0,2.526,1.858,1.3502865,1202.147328,0.34816,0.0,3.423,3.211,0.8878167,1158.397952,0.0,0.0,3.675,3.282,1.4830415,1117.483008,0.0,0.0,3.542,3.871
1442600709000,10.8499307,1336.32,1.343488,0.02048,57.627,53.585,4.0536738,1223.266304,0.008192,0.0,8.492,7.318,4.400113,1203.355648,0.731136,0.0,6.766,5.929,3.5293089,1159.835648,0.0,0.0,8.39,7.073,3.0503235,1118.629888,0.004096,0.0,8.866,7.356
1442600769000,11.3694209,1334.96832,1.449984,0.016384,46.789,44.005,3.4118838,1225.691136,0.0,0.0,6.957,6.229,2.3291492,1201.307648,0.421888,0.0,5.29,3.724,3.4395271,1158.803456,0.0,0.0,6.89,5.876,3.8997586,1115.344896,0.032768,0.0,7.291,6.158
1442600829000,12.1616177,1339.76064,1.363968,0.016384,40.704,37.648,2.4564299,1222.10304,0.0,0.0,4.531,4.198,3.803434,1204.195328,1.429504,0.0,5.192,4.593,3.1139289,1162.317824,0.0,0.0,5.868,4.996,3.5312989,1118.236672,0.032768,0.0,8.056,7.258
1442600889000,12.3966232,1332.92032,1.72032,0.012288,37.067,35.053,3.5232215,1209.278464,0.0,0.0,6.33,4.98,1.4571229,1193.873408,0.468992,0.0,4.286,3.584,4.4839531,1161.129984,0.0,0.0,6.267,5.763,3.3630396,1111.629824,0.0,0.0,5.0,4.787
1442600949000,9.2404503,1333.694464,2.056192,0.024576,34.346,33.497,2.644973,1215.660032,0.0,0.0,4.223,4.204,2.8318998,1193.394176,0.74752,0.0,4.891,3.937,2.7795662,1159.032832,0.0,0.0,4.636,4.095,3.8512776,1113.76384,0.0,0.0,7.173,6.4
1442601009000,7.5994487,1332.604928,0.991232,0.016384,13.253,12.801,2.389599,1217.26976,0.0,0.0,2.807,2.348,1.9311354,1200.070656,0.333824,0.0,2.476,2.07,1.7825693,1162.100736,0.0,0.0,1.88,1.693,1.1998497,1110.749184,0.0,0.0,1.679,1.492
1442601069000,8.9934636,1334.509568,3.047424,0.012288,61.439,57.831,3.3530042,1216.585728,0.0,0.0,9.029,7.196,2.8725474,1196.118016,1.581056,0.0,6.171,5.384,3.6166774,1164.455936,0.0,0.0,9.411,7.949,3.0911082,1113.841664,0.0,0.0,9.469,7.837
1442601129000,9.5390021,1329.598464,2.535424,0.016384,73.149,68.963,3.7186398,1218.097152,0.0,0.0,9.906,8.796,3.3727379,1198.8992,1.376256,0.0,8.884,7.49,3.3364851,1159.757824,0.0,0.0,9.246,8.25,3.8585538,1111.04,0.0,0.0,12.188,10.266
1442601189000,12.3036149,1330.05312,1.880064,0.004096,67.57,62.69,3.7779111,1223.790592,0.0,0.0,7.506,7.142,4.1931202,1201.31584,2.187264,0.0,8.517,7.804,4.6787232,1159.192576,0.0,0.0,8.787,8.571,4.8242919,1113.120768,0.0,0.0,10.407,9.25
1442601249000,10.7598966,1332.518912,2.629632,0.024576,63.519,59.574,4.0250148,1220.673536,0.0,0.0,9.06,7.583,3.9008458,1202.671616,1.550336,0.0,7.702,6.793,4.2929324,1162.350592,0.0,0.0,9.764,8.244,3.7143956,1115.009024,0.0,0.0,8.635,7.777
1442601309000,10.1556246,1335.56224,3.833856,0.024576,67.846,63.566,3.5754918,1217.961984,0.0,0.0,8.184,7.46,3.5110075,1204.641792,2.080768,0.0,9.481,7.844,3.7583582,1163.436032,0.0,0.0,8.674,7.802,4.0152985,1114.714112,0.0,0.0,10.045,8.186
1442601369000,10.2736893,1336.19712,2.207744,0.016384,69.335,64.815,3.8117383,1223.450624,0.0,0.0,9.11,8.07,2.9722706,1202.827264,1.46432,0.0,6.88,6.482,4.1677464,1164.824576,0.0,0.0,10.072,9.581,3.927214,1120.272384,0.0,0.0,10.732,8.768
1442601430000,9.5663906,1338.179584,2.6624,0.0,71.869,67.308,3.8206241,1221.660672,0.0,0.0,10.206,9.586,3.8683392,1199.480832,1.787904,0.0,9.413,8.3,3.2738768,1166.60224,0.0,0.0,11.522,9.157,2.7649334,1121.538048,0.0,0.0,8.709,7.551
1442601490000,9.6347294,1334.632448,3.592192,0.0,56.561,52.878,3.1853144,1213.394944,0.0,0.0,7.248,7.157,3.1984835,1190.03136,1.445888,0.0,7.111,6.207,3.4445577,1154.568192,0.0,0.0,7.741,6.121,3.6815439,1115.50464,0.0,0.0,8.962,6.903
1442601550000,11.2154885,1333.604352,5.605376,0.0,76.992,73.279,4.4409289,1212.002304,0.0,0.0,8.796,8.369,4.8247892,1189.720064,1.640448,0.0,11.28,9.064,3.9685682,1157.353472,0.0,0.0,11.081,9.17,3.8432388,1113.25184,0.0,0.0,10.853,9.385
1442601610000,12.3912657,1337.565184,2.494464,0.0,54.557,50.725,3.7656629,1211.928576,0.0,0.0,8.112,7.115,3.2145818,1191.97696,0.888832,0.0,5.355,4.287,4.9030888,1164.353536,0.032768,0.0,8.314,7.357,3.7060338,1116.504064,0.0,0.0,8.357,6.902
1442601670000,10.8409719,1336.635392,1.900544,0.0,55.746,50.744,2.8409333,1212.432384,0.0,0.0,8.353,7.324,3.618607,1192.57088,1.957888,0.0,6.32,6.652,3.057601,1162.15808,0.0,0.0,7.845,7.512,2.2173878,1117.396992,0.0,0.0,6.738,5.771
1442601730000,10.5825679,1336.377344,2.945024,0.0,71.544,66.324,5.3198545,1211.469824,0.004096,0.0,10.661,9.965,3.5583683,1192.50944,1.593344,0.0,8.259,6.865,4.3544482,1162.58816,0.0,0.0,9.871,8.479,3.1564321,1119.51872,0.065536,0.0,9.466,8.14
1442601790000,12.0854614,1336.152064,3.448832,0.0,60.644,57.042,5.3277439,1216.59392,0.0,0.0,9.262,7.79,3.0124037,1192.747008,1.193984,0.0,6.098,5.403,4.6143446,1162.981376,0.0,0.0,8.94,7.861,3.6466143,1121.550336,0.0,0.0,9.251,8.558
1442601850000,8.3133679,1339.666432,0.524288,0.0,36.08,33.848,1.7546624,1216.720896,0.0,0.0,5.07,4.496,1.943529,1197.195264,0.854016,0.0,4.457,4.352,2.1100296,1165.582336,0.0,0.0,5.332,4.344,1.8022457,1124.757504,0.0,0.0,6.22,4.856
1442601910000,10.7245663,1338.478592,1.90464,0.0,69.72,65.381,4.3521108,1219.403776,0.0,0.0,8.785,8.288,3.5148246,1195.52,1.536,0.0,8.638,7.029,3.5472454,1163.12064,0.0,0.0,11.045,8.969,3.8614294,1123.336192,0.0,0.0,10.106,8.793
1442601970000,10.5550528,1341.411328,4.837376,0.0,70.158,65.546,4.7153808,1218.945024,0.0,0.0,10.342,8.382,3.7705812,1198.395392,0.577536,0.0,8.054,6.22,4.2962042,1163.497472,0.0,0.0,9.374,7.957,4.1584546,1120.411648,0.0,0.0,9.895,8.703
1442602030000,11.8318897,1339.86304,3.330048,0.0,74.685,69.925,5.2335346,1219.137536,0.0,0.0,10.115,8.406,4.1705371,1198.907392,1.51552,0.0,8.288,7.076,3.8801691,1166.020608,0.0,0.0,11.286,10.516,4.2698227,1122.54976,0.0,0.0,10.233,8.6
1442602090000,10.351948,1329.426432,3.629056,0.0,73.191,68.138,4.0721167,1207.484416,0.0,0.0,9.823,8.593,3.1272672,1188.16768,0.954368,0.0,8.112,6.652,4.4512289,1155.371008,0.049152,0.0,9.957,9.098,3.7075151,1112.977408,0.0,0.0,10.626,8.616
1442602150000,8.0747304,1332.686848,2.879488,0.0,68.483,63.152,3.0498196,1209.593856,0.0,0.0,9.451,8.048,3.0913566,1188.84352,1.982464,0.0,8.663,8.176,2.8656663,1157.718016,0.0,0.0,8.409,7.44,2.2935266,1112.2688,0.0,0.0,9.27,8.17
1442602211000,8.4330569,1335.570432,2.994176,0.0,65.006,61.244,3.0717285,1212.43648,0.0,0.0,8.761,7.928,3.0035227,1193.791488,1.837056,0.0,7.855,7.145,3.5523044,1160.527872,0.0,0.0,9.874,7.819,3.0443281,1117.16352,0.0,0.0,9.659,7.788
1442602271000,9.7079025,1332.436992,2.594816,0.0,77.33,72.06,3.4891812,1215.123456,0.0,0.0,10.523,8.611,2.925243,1189.646336,1.76128,0.0,8.581,8.056,4.0321996,1160.101888,0.0,0.0,11.662,10.517,3.7415582,1115.693056,0.0,0.0,10.803,9.047
1442602331000,10.6117836,1332.424704,1.503232,0.0,75.203,70.09,3.6606602,1212.510208,0.0,0.0,9.967,7.94,3.8788221,1191.77216,2.342912,0.0,8.649,7.933,2.9977072,1160.040448,0.0,0.0,10.438,9.661,3.4213639,1120.587776,0.0,0.0,12.458,10.457
1442602391000,10.4000992,1339.379712,4.317184,0.0,71.543,67.172,5.3050166,1216.835584,0.0,0.0,10.985,10.056,2.6991881,1191.448576,1.024,0.0,7.641,5.981,3.7616614,1165.266944,0.0,0.0,9.365,8.268,4.4176889,1118.691328,0.0,0.0,9.843,8.364
1442602451000,10.2083557,1339.285504,2.859008,0.0,81.374,76.193,3.230606,1219.846144,0.0,0.0,10.912,9.763,4.112003,1192.63232,1.732608,0.0,9.76,8.222,3.9818608,1168.551936,0.0,0.0,12.657,11.174,3.773483,1120.464896,0.0,0.0,10.906,8.731
1442602511000,10.7005475,1340.366848,2.859008,0.0,77.543,73.425,4.0035139,1215.721472,0.0,0.0,10.614,9.228,3.2887086,1191.677952,1.65888,0.0,9.343,7.993,4.3407373,1171.808256,0.0,0.0,12.128,10.625,4.2401779,1117.372416,0.0,0.0,10.884,9.569
1442602571000,11.5778384,1337.851904,2.53952,0.0,75.086,70.39,4.4103538,1218.011136,0.0,0.0,10.708,9.648,4.0910035,1192.484864,1.634304,0.0,10.78,9.708,3.7029213,1171.693568,0.0,0.0,10.038,8.821,4.1311914,1117.241344,0.0,0.0,10.134,8.359
1442602632000,11.6649652,1340.39552,3.063808,0.0,68.256,63.48,3.7758711,1221.586944,0.0,0.0,9.455,8.699,3.6072295,1195.290624,1.294336,0.0,6.767,6.155,4.2369733,1174.09792,0.0,0.0,9.952,8.455,3.5594379,1120.485376,0.0,0.0,9.669,9.308
1442602693000,0.8829389,1321.709568,2.02752,0.0,27.245,26.069,0.1932834,1203.376128,0.0,0.0,3.971,3.043,0.23633,1174.716416,0.423936,0.0,3.356,2.659,0.2331276,1150.81216,0.0,0.0,4.924,4.661,0.5456354,1097.510912,0.0,0.0,5.774,5.477
1442602753000,11.293329,1332.482048,3.09248,0.0,71.224,66.806,3.7750248,1214.947328,0.0,0.0,7.849,6.553,4.8480922,1190.305792,1.949696,0.0,10.467,9.162,4.1338615,1160.331264,0.0,0.0,10.318,9.017,3.7225329,1108.967424,0.0,0.0,10.204,8.291
1442602813000,11.5805919,1335.472128,2.3552,0.0,64.645,59.543,4.3987366,1217.507328,0.0,0.0,7.82,7.205,3.8103983,1192.087552,1.206272,0.008192,6.908,6.284,4.1709757,1166.667776,0.0,0.0,9.251,8.231,3.8599998,1124.384768,0.0,0.0,9.149,8.557
1442602873000,11.035043,1334.878208,4.673536,0.0,80.366,75.166,5.0592483,1217.753088,0.0,0.0,11.325,9.893,5.0063772,1193.55392,0.905216,0.016384,10.122,8.358,4.4783111,1166.098432,0.0,0.0,9.52,8.338,3.5854399,1115.459584,0.0,0.0,12.346,9.051
1442602933000,9.842024,1335.689216,2.920448,0.0,69.704,65.111,3.3758004,1218.076672,0.0,0.0,8.998,7.473,3.0075813,1195.06944,1.990656,0.012288,8.325,7.466,4.1611603,1166.188544,0.0,0.0,8.955,7.853,4.3436749,1117.700096,0.0,0.0,11.357,10.26
1442602993000,8.9892369,1339.531264,2.572288,0.0,59.204,55.629,3.5135945,1219.080192,0.0,0.0,8.735,7.443,3.0054224,1195.515904,1.308672,0.01024,6.765,6.303,1.9107445,1168.73216,0.0,0.0,6.865,6.063,3.4408156,1121.13664,0.0,0.0,9.548,9.112
1442603053000,9.5319786,1337.602048,3.66592,0.0,78.476,73.909,3.8311104,1221.615616,0.004096,0.0,12.701,10.747,3.6537561,1192.091648,1.120256,0.012288,8.914,6.992,3.3978481,1166.921728,0.0,0.0,12.473,10.034,3.3882304,1119.789056,0.0,0.0,9.568,8.481
1442603114000,11.1212942,1336.283136,1.3312,0.0,65.849,60.971,3.8718187,1220.681728,0.0,0.0,9.958,8.521,2.8629306,1195.261952,1.529856,0.0,6.546,5.761,3.6235232,1172.15232,0.0,0.0,8.88,8.392,4.5822377,1119.322112,0.0,0.0,10.205,9.449
1442603174000,11.2381941,1340.166144,4.05504,0.0,63.404,60.197,3.5162963,1218.617344,0.0,0.0,8.572,7.692,4.1422342,1195.630592,1.542144,0.0,8.351,7.064,3.7269892,1171.984384,0.0,0.0,8.166,7.277,4.074955,1120.866304,0.0,0.0,9.176,8.075
1442603234000,9.9535821,1343.275008,3.518464,0.0,71.925,67.246,4.3700511,1222.488064,0.0,0.0,11.273,9.933,2.6703459,1194.205184,0.731136,0.0,7.608,5.639,3.1090092,1169.788928,0.0,0.0,9.604,7.595,3.7751739,1119.96928,0.0,0.0,11.0,9.942
1442603294000,10.8937816,1347.690496,3.203072,0.0,78.853,73.64,3.6034646,1224.474624,0.0,0.0,9.742,8.45,3.7915964,1197.293568,1.804288,0.0,9.542,8.135,4.5142833,1171.034112,0.0,0.0,10.616,8.88,4.0748583,1119.58016,0.0,0.0,11.571,10.018
1442603354000,9.8745811,1335.242752,3.1744,0.0,59.618,55.725,3.6603685,1215.197184,0.0,0.0,8.043,7.393,3.5925919,1185.763328,2.353152,0.0,8.055,7.699,3.3008607,1162.440704,0.0,0.0,7.72,6.946,2.7717256,1108.262912,0.0,0.0,7.767,6.664
1442603414000,9.3707203,1339.375616,3.309568,0.0,63.493,58.498,3.1908477,1215.635456,0.0,0.0,10.191,8.779,3.366493,1186.480128,1.8432,0.0,6.998,6.729,3.0771432,1167.228928,0.0,0.0,8.754,7.044,2.9168364,1108.471808,0.0,0.0,7.019,6.845
1442603474000,9.8521357,1343.475712,0.43008,0.0,23.736,22.539,3.1629534,1227.436032,0.0,0.0,4.752,4.711,1.5016434,1190.055936,0.172032,0.0,2.855,2.205,2.8053617,1166.639104,0.0,0.0,3.598,3.34,2.8354103,1113.780224,0.0,0.0,4.386,4.218
1442603534000,10.7609436,1342.03392,1.335296,0.0,30.187,28.326,2.2605587,1224.122368,0.0,0.0,4.529,3.657,1.1258253,1189.13024,0.452608,0.0,3.079,2.903,4.0540722,1167.028224,0.0,0.0,5.773,5.428,2.6948636,1116.065792,0.0,0.0,4.562,4.228
1442603594000,7.6028281,1344.98304,0.868352,0.0,34.462,32.239,1.8883612,1227.030528,0.0,0.0,3.684,3.176,1.7504045,1193.844736,1.345536,0.0,4.827,4.439,1.7796507,1168.805888,0.0,0.0,4.757,4.651,2.6629573,1120.169984,0.0,0.0,6.596,6.73
1442603654000,6.4533635,1344.905216,0.83968,0.0,28.066,26.378,1.4055529,1227.075584,0.0,0.0,4.0,3.27,0.7070494,1194.893312,0.681984,0.0,2.911,3.19,2.060043,1165.832192,0.0,0.0,4.805,4.449,2.0662045,1122.28352,0.0,0.0,4.867,4.737
1442603714000,12.9904277,1345.691648,2.10944,0.0,38.717,35.841,1.6296252,1228.324864,0.0,0.0,3.592,2.982,3.5385085,1194.459136,1.200128,0.0,5.238,5.37,2.9472956,1167.675392,0.0,0.0,5.764,4.851,3.9261853,1120.301056,0.0,0.0,6.816,5.851
1442603774000,8.3221284,1343.905792,1.818624,0.0,28.503,27.404,1.5982722,1229.541376,0.0,0.0,3.986,3.702,1.6471302,1197.48608,0.290816,0.0,3.236,2.678,3.246401,1170.485248,0.0,0.0,4.028,3.918,2.386435,1121.46432,0.0,0.0,5.17,4.925
1442603834000,5.1892082,1343.541248,0.851968,0.0,26.134,24.717,1.0872915,1231.065088,0.0,0.0,3.474,3.14,1.2888218,1195.778048,0.731136,0.0,3.782,3.329,1.5215325,1172.84864,0.0,0.0,6.242,4.935,1.1705993,1120.047104,0.0,0.0,3.392,2.797
1442603895000,9.8804025,1341.366272,1.990656,0.0,33.971,32.167,2.6909248,1229.197312,0.0,0.0,4.967,4.137,2.609596,1196.01152,1.378304,0.0,5.326,5.236,2.523419,1168.105472,0.0,0.0,4.468,4.249,2.9500719,1122.336768,0.0,0.0,5.215,4.522
1442603955000,8.6417201,1342.840832,1.65888,0.0,33.073,31.281,2.3628016,1231.249408,0.0,0.0,5.269,4.521,2.3704247,1187.524608,0.923648,0.0,4.627,4.319,2.381506,1163.42784,0.0,0.0,4.462,4.123,1.9791373,1119.166464,0.0,0.0,4.994,4.912
1442604015000,11.0687272,1340.366848,3.31776,0.0,60.382,56.335,3.7302643,1221.28384,0.0,0.0,6.666,6.207,3.5780435,1183.182848,1.943552,0.0,7.84,6.637,3.9096529,1174.13888,0.0,0.0,8.306,6.642,4.3458597,1117.229056,0.0,0.0,9.369,8.117
1442604075000,10.7087046,1342.799872,2.531328,0.0,65.573,60.973,4.3446605,1222.549504,0.0,0.0,8.4,7.773,3.4546229,1184.628736,1.366016,0.0,8.519,6.722,3.9960589,1170.542592,0.0,0.0,10.41,9.085,3.456181,1115.46368,0.0,0.0,8.132,7.346
1442604135000,8.7946353,1341.632512,3.289088,0.0,59.807,56.024,3.1870152,1226.694656,0.0,0.0,8.426,6.953,2.7312157,1185.472512,1.609728,0.0,5.855,5.677,3.1390305,1174.089728,0.0,0.0,9.148,8.08,3.1376616,1116.237824,0.0,0.0,8.259,7.082
1442604195000,2.8564054,1338.482688,0.69632,0.0,25.088,23.535,1.6376094,1274.728448,0.073728,0.0,4.585,3.632,1.1389228,1182.855168,0.724992,0.0,3.615,3.445,1.0570469,1164.623872,0.0,0.0,4.336,3.749,0.7051575,1107.030016,0.0,0.0,3.414,2.898
1442604255000,9.0817648,1337.520128,1.880064,0.0,66.787,62.836,3.6325979,1252.564992,0.0,0.0,8.337,7.26,2.7072265,1185.513472,1.32096,0.0,7.123,6.81,3.0999315,1174.3232,0.0,0.0,9.702,9.069,3.8793118,1112.506368,0.0,0.0,10.141,9.463
1442604315000,10.6055176,1341.935616,3.29728,0.0,71.938,67.12,3.6526405,1253.388288,0.0,0.0,9.381,7.661,4.0838569,1183.911936,2.111488,0.0,8.526,7.697,4.1275342,1173.36064,0.0,0.0,10.706,8.73,4.0604911,1104.838656,0.0,0.0,10.965,8.188
1442604375000,8.5039005,1344.442368,3.772416,0.0,52.361,49.187,2.3308464,1255.776256,0.0,0.0,5.806,5.072,3.0036741,1183.215616,1.540096,0.0,7.513,6.567,3.4554592,1175.277568,0.0,0.0,7.682,7.537,3.2764444,1105.67424,0.0,0.0,7.644,6.668
1442604436000,10.1960037,1344.720896,3.950592,0.0,68.682,64.363,3.802643,1256.861696,0.0,0.0,8.404,7.0,3.4578175,1182.498816,1.39264,0.0,8.782,7.546,4.4541875,1168.351232,0.0,0.0,10.499,8.913,3.9021577,1115.807744,0.0,0.0,9.76,8.406
1442604496000,12.3464841,1343.193088,2.94912,0.0,73.557,68.892,5.0260456,1257.828352,0.0,0.0,9.673,7.762,4.5456548,1188.036608,2.103296,0.0,10.594,8.61,4.1148807,1168.7936,0.0,0.0,9.029,7.695,4.7357585,1116.532736,0.0,0.0,11.199,9.395
1442604556000,9.3260824,1337.638912,2.609152,0.0,52.751,48.954,4.2075886,1247.014912,0.0,0.0,7.544,6.806,2.7703923,1179.267072,1.11616,0.0,4.146,3.783,2.048092,1157.615616,0.0,0.0,6.864,6.783,3.1537303,1108.84864,0.0,0.0,8.256,7.969
1442604616000,10.2296558,1340.33408,2.854912,0.0,65.648,62.015,3.0656882,1250.05824,0.0,0.0,5.939,6.05,3.2684961,1179.7504,1.808384,0.0,8.823,7.509,4.8942822,1159.3728,0.032768,0.0,10.69,9.221,3.8452778,1105.53088,0.0,0.0,9.826,8.93
1442604676000,9.2065529,1338.318848,2.936832,0.0,60.262,56.145,3.7762947,1253.269504,0.0,0.0,7.346,7.226,3.4683645,1181.827072,1.337344,0.0,7.531,6.37,2.7335883,1161.715712,0.0,0.0,7.042,6.364,2.547729,1110.3232,0.0,0.0,10.089,9.232
1442604736000,10.4668915,1337.417728,3.166208,0.0,56.993,53.103,3.5854954,1249.255424,0.0,0.0,7.12,6.636,4.0262687,1181.016064,1.869824,0.0,5.306,5.202,3.8413094,1160.204288,0.0,0.0,7.971,6.672,3.718534,1109.315584,0.0,0.0,8.767,8.813
1442604797000,11.1882367,1341.247488,3.248128,0.0,65.003,61.129,3.8307908,1256.275968,0.0,0.0,6.951,5.975,3.2499958,1180.336128,1.80224,0.0,5.921,6.028,4.5811154,1164.214272,0.028672,0.0,9.133,8.267,4.6470218,1109.680128,0.0,0.0,11.17,9.297
1442604857000,10.4869733,1339.65824,2.12992,0.0,50.998,47.613,2.1379589,1256.964096,0.0,0.0,5.744,4.821,2.543213,1181.814784,1.19808,0.0,6.194,5.127,3.1846732,1166.077952,0.0,0.0,7.569,6.492,2.9780235,1110.413312,0.0,0.0,9.225,7.923
1442604917000,12.4153408,1345.67936,1.95584,0.0,45.921,42.857,4.6837263,1264.357376,0.0,0.0,7.903,7.026,3.4922021,1184.288768,1.30048,0.0,6.126,5.846,3.3975645,1170.24768,0.0,0.0,5.592,4.333,2.7933977,1108.23424,0.0,0.0,6.191,5.091
1442604977000,10.137123,1345.47456,4.36224,0.0,72.804,67.598,4.5386361,1257.541632,0.0,0.0,10.545,8.75,3.0854345,1182.068736,2.103296,0.0,9.232,7.958,3.458053,1167.5648,0.0,0.0,8.933,7.411,3.313365,1105.485824,0.0,0.0,9.566,8.793
1442605037000,10.725261,1345.765376,2.060288,0.0,51.948,48.679,3.6046378,1260.17536,0.0,0.0,6.196,5.337,2.6478381,1181.22496,0.759808,0.0,6.481,5.264,3.8225872,1168.797696,0.0,0.0,6.442,5.813,4.590908,1113.153536,0.0,0.0,10.623,8.531
1442605097000,10.2703231,1346.64192,1.67936,0.0,73.359,67.897,2.4519524,1259.511808,0.0,0.0,9.782,7.818,4.2450563,1177.669632,2.377728,0.0,10.18,9.018,3.5087128,1169.338368,0.004096,0.0,9.122,7.368,3.929743,1108.033536,0.0,0.0,10.769,10.17
1442605157000,9.718065,1339.457536,4.378624,0.0,58.321,54.528,2.9739457,1243.50464,0.0,0.0,5.651,5.168,3.7066325,1174.831104,2.250752,0.0,7.535,7.036,3.8577926,1159.852032,0.0,0.0,9.39,7.353,3.3708217,1100.57472,0.0,0.0,8.511,7.052
1442605217000,9.7138304,1339.912192,3.301376,0.0,69.624,65.523,3.7148406,1243.521024,0.0,0.0,9.454,8.864,3.6682022,1173.250048,1.380352,0.0,9.982,7.841,3.5002883,1158.914048,0.0,0.0,8.815,7.886,3.1801178,1098.40384,0.0,0.0,9.203,8.672
1442605277000,8.6457692,1340.043264,4.145152,0.0,61.059,56.728,2.6406226,1249.542144,0.0,0.0,7.313,6.658,3.4093452,1173.164032,1.478656,0.0,7.246,6.009,3.1950598,1164.480512,0.0,0.0,8.927,7.615,3.5017635,1099.210752,0.0,0.0,9.127,7.828
1442605337000,10.3258674,1338.621952,2.633728,0.0,75.147,70.724,4.3778875,1248.858112,0.0,0.0,11.335,9.684,4.4853634,1175.92064,1.21856,0.0,9.417,7.029,3.5598951,1162.186752,0.0,0.0,9.299,8.805,3.7563142,1101.078528,0.0,0.0,10.799,9.266
1442605397000,9.513566,1343.823872,3.014656,0.0,61.743,57.88,4.1271165,1247.690752,0.0,0.0,7.353,6.549,3.4099453,1179.713536,1.826816,0.0,8.389,7.701,3.0815121,1161.474048,0.0,0.0,7.584,6.405,4.0745129,1102.04928,0.0,0.0,10.736,9.365
1442605457000,9.7720068,1342.431232,3.608576,0.0,69.416,65.523,2.6755594,1246.261248,0.0,0.0,8.358,7.25,3.6907621,1182.55616,1.964032,0.0,9.902,8.596,3.5420141,1166.925824,0.0,0.0,10.221,9.06,3.8586014,1107.595264,0.0,0.0,9.529,8.516
1442605517000,10.789803,1342.537728,2.936832,0.0,61.431,58.117,3.8800269,1249.640448,0.0,0.0,9.108,8.03,3.8778506,1183.96928,1.941504,0.0,7.413,6.752,3.4587665,1167.6672,0.032768,0.0,7.934,7.396,3.321424,1108.402176,0.0,0.0,8.907,7.423
1442605577000,10.4339147,1342.169088,2.060288,0.0,65.26,60.746,3.7827344,1250.340864,0.0,0.0,9.313,7.682,4.0505094,1185.542144,1.861632,0.0,8.284,7.698,4.0165672,1170.579456,0.0,0.0,8.942,8.843,3.5754005,1106.264064,0.0,0.0,8.163,7.205
1442605638000,10.7812206,1343.496192,2.51904,0.02048,70.943,66.344,3.3451104,1251.24608,0.0,0.0,8.549,7.711,4.2895586,1188.921344,1.792,0.0,8.134,8.017,4.1388251,1170.788352,0.0,0.0,8.78,7.624,3.7781894,1105.73568,0.0,0.0,11.985,10.058
1442605698000,8.7835292,1345.150976,2.920448,0.02048,58.148,55.616,2.6746587,1254.670336,0.0,0.0,6.735,5.625,2.9343741,1190.805504,1.314816,0.0,7.056,6.071,3.2935717,1169.424384,0.0,0.0,10.338,8.499,3.664988,1108.168704,0.0,0.0,8.239,8.025
1442605758000,7.5507066,1342.83264,1.732608,0.012288,40.803,38.266,2.9296123,1240.522752,0.0,0.0,6.278,5.869,2.4441675,1178.124288,1.245184,0.0,4.991,4.701,3.1196317,1166.962688,0.0,0.0,7.515,6.97,1.9527323,1097.678848,0.0,0.0,4.925,5.104
1442605818000,4.5519195,1342.570496,0.802816,0.012288,32.304,29.807,1.9691805,1245.048832,0.0,0.0,4.684,4.469,1.8983648,1180.311552,0.854016,0.0,3.924,3.767,10.0832618,1170.141184,1.183744,0.0,3.971,3.652,2.2510188,1096.654848,0.0,0.0,6.778,5.593
1442605878000,7.8969973,1341.018112,2.551808,0.016384,57.969,54.443,2.7783759,1236.156416,0.0,0.0,6.878,6.394,3.0288754,1174.544384,1.906688,0.0,8.876,8.053,2.6794396,1166.057472,0.0,0.0,8.308,7.299,2.9572801,1094.725632,0.0,0.0,8.001,6.948
1442605938000,8.1255208,1343.557632,2.531328,0.012288,51.193,48.146,2.7434774,1243.762688,0.0,0.0,7.601,7.081,2.6700968,1171.623936,0.823296,0.0,5.979,5.26,3.1748087,1167.167488,0.0,0.0,7.504,6.692,2.5817235,1098.36288,0.0,0.0,7.589,7.076
1442605998000,10.2418208,1345.970176,2.70336,0.016384,61.806,57.65,3.4636818,1242.095616,0.0,0.0,8.574,7.045,3.2219342,1175.412736,2.152448,0.0,7.47,6.732,3.2341047,1168.470016,0.0,0.0,9.413,8.374,3.7608318,1101.418496,0.0,0.0,7.423,6.477
1442606059000,10.1954285,1343.561728,1.921024,0.02048,62.998,59.075,4.6184599,1244.438528,0.0,0.0,10.062,9.317,3.0539925,1171.636224,1.120256,0.0,5.765,4.935,4.1656805,1171.226624,0.0,0.0,10.199,8.281,3.5488882,1099.603968,0.0,0.0,8.896,7.87
1442606120000,9.085885,1347.063808,2.53952,0.0,75.104,70.357,3.168649,1244.348416,0.032768,0.0,8.489,8.096,3.3639093,1172.852736,1.570816,0.0,9.372,7.413,2.9033042,1170.845696,0.0,0.0,11.633,10.294,3.5172761,1096.593408,0.0,0.0,9.668,9.389
1442606180000,10.9679993,1350.234112,3.104768,0.0,62.904,58.532,3.9814384,1243.83232,0.0,0.0,6.403,6.009,3.4757227,1172.475904,1.693696,0.0,7.748,6.571,4.0781649,1171.984384,0.0,0.0,9.999,9.359,3.8234809,1098.77248,0.0,0.0,9.389,7.154
1442606240000,9.7826971,1350.53312,1.744896,0.0,64.803,60.899,4.3433384,1243.89376,0.0,0.0,9.995,9.243,2.5565362,1173.557248,1.753088,0.0,8.005,6.885,3.4658862,1171.402752,0.0,0.0,8.567,7.181,3.1852303,1102.635008,0.0,0.0,9.693,8.508
1442606300000,9.4971542,1350.71744,3.21536,0.0,64.5,60.74,3.5391702,1241.755648,0.0,0.0,9.429,8.712,2.4150702,1179.488256,1.499136,0.0,6.192,5.791,3.3819228,1170.898944,0.0,0.0,9.292,8.543,3.3855878,1103.966208,0.0,0.0,9.492,8.183
1442606360000,9.2433276,1342.009344,3.149824,0.0,60.063,56.122,3.387235,1233.170432,0.036864,0.0,7.781,7.734,3.4537889,1176.100864,1.169408,0.0,6.824,6.232,3.513181,1169.539072,0.0,0.0,10.101,9.238,3.432072,1100.562432,0.0,0.0,6.878,5.992
1442606421000,10.3646265,1342.48448,2.834432,0.0,62.202,57.937,3.5391892,1231.2576,0.0,0.0,8.215,6.97,4.3521342,1176.51456,1.533952,0.0,7.58,6.541,3.9686206,1172.873216,0.032768,0.0,9.907,8.909,3.7812737,1100.115968,0.0,0.0,7.844,7.041
1442606482000,12.2786853,1351.573504,2.197504,0.0,68.259,63.982,3.5403347,1239.748608,0.0,0.0,7.942,7.917,4.1744109,1175.687168,1.947648,0.004096,7.839,6.449,4.115575,1175.47008,0.0,0.0,9.379,8.335,4.4270016,1102.512128,0.0,0.0,12.006,9.815
1442606542000,8.576916,1343.885312,2.064384,0.0,49.955,46.775,2.2712894,1235.345408,0.0,0.0,6.051,5.364,3.5001874,1178.730496,2.332672,0.008192,7.987,7.865,2.7709258,1173.311488,0.0,0.0,6.776,5.476,2.70018,1099.313152,0.0,0.0,6.254,5.657
1442606602000,9.8627786,1351.0656,3.653632,0.0,65.213,61.623,3.9137941,1239.699456,0.0,0.0,9.111,7.601,3.5749698,1175.781376,1.368064,0.012288,7.797,7.25,3.3916876,1173.569536,0.0,0.0,9.11,7.728,3.7588064,1101.96736,0.0,0.0,10.733,9.878
1442606662000,9.5779761,1351.84384,2.51904,0.0,63.219,59.6,3.4978872,1244.069888,0.0,0.0,8.567,7.535,3.3782638,1177.382912,1.583104,0.016384,6.327,5.688,3.9653295,1176.928256,0.0,0.0,11.031,9.91,3.736827,1099.07968,0.0,0.0,9.109,7.836
1442606722000,8.2227955,1352.384512,2.793472,0.0,64.057,59.822,3.4144567,1242.800128,0.0,0.0,7.57,6.422,3.0890712,1176.92416,1.490944,0.01024,7.979,6.93,3.3984493,1178.066944,0.0,0.0,9.262,8.129,3.3039148,1100.406784,0.0,0.0,9.691,8.438
1442606782000,7.4808999,1355.698176,2.51904,0.0,51.995,49.36,3.7604693,1250.332672,0.0,0.0,8.748,6.979,2.2560968,1175.605248,1.046528,0.01024,5.705,5.126,2.2343574,1182.703616,0.0,0.0,8.414,6.675,2.6031162,1107.492864,0.012288,0.0,7.395,6.367
1442606842000,8.2059233,1350.762496,2.650112,0.0,55.248,51.459,3.4425976,1245.138944,0.0,0.0,7.416,6.975,3.9855218,1178.17344,1.726464,0.004096,7.13,6.641,2.8787043,1181.360128,0.0,0.0,5.761,5.213,2.9292958,1105.371136,0.0,0.0,8.994,7.377
1442606903000,10.1445018,1355.22304,2.740224,0.0,57.691,54.364,3.733089,1244.430336,0.0,0.0,8.307,6.529,3.2246205,1183.78496,1.724416,0.006144,8.093,7.115,2.5235127,1180.102656,0.0,0.0,6.446,5.761,3.4260021,1111.465984,0.0,0.0,8.39,7.762
1442606963000,1.1652006,1339.35104,1.949696,0.0,22.249,20.992,0.5455985,1215.811584,0.0,0.0,3.245,2.823,0.4735846,1164.402688,0.350208,0.014336,3.814,2.947,0.3953203,1155.862528,0.0,0.0,3.185,3.068,0.3287639,1091.514368,0.0,0.0,3.19,2.493
1442607023000,11.0280172,1351.008256,2.215936,0.0,66.423,61.784,3.5956106,1225.416704,0.0,0.0,9.262,7.812,3.8141172,1175.355392,1.529856,0.012288,7.814,6.77,4.8831882,1168.896,0.0,0.0,9.307,7.156,4.1286797,1099.81696,0.02048,0.0,10.211,8.585
1442607083000,11.5792706,1351.692288,3.85024,0.0,78.348,73.215,3.8597679,1225.216,0.0,0.0,8.853,7.889,3.9700296,1173.348352,1.267712,0.008192,9.399,7.75,4.596399,1174.15936,0.0,0.0,12.844,11.149,4.1917499,1099.677696,0.0,0.0,11.333,10.137
1442607143000,11.2517136,1347.022848,3.477504,0.0,67.833,64.003,4.3234667,1227.32544,0.0,0.0,9.724,9.526,3.4838779,1174.81472,1.179648,0.006144,7.578,6.798,4.0469355,1175.01952,0.0,0.0,9.558,7.941,5.2453887,1101.123584,0.0,0.0,10.212,8.861
1442607203000,9.0642857,1352.462336,1.826816,0.0,59.415,55.189,4.2625541,1242.533888,0.0,0.0,10.796,9.276,3.1747895,1176.73984,2.033664,0.006144,6.932,6.552,2.6408403,1177.501696,0.0,0.0,7.412,6.102,3.0461566,1103.970304,0.0,0.0,7.667,7.183
1442607264000,9.6436027,1352.945664,3.395584,0.0,67.009,62.61,3.1525692,1240.846336,0.0,0.0,7.668,7.429,3.8241566,1171.959808,1.337344,0.0,8.684,7.945,3.4618763,1179.5456,0.0,0.0,9.82,8.285,4.066121,1098.678272,0.0,0.0,10.053,7.988
1442607324000,9.7942751,1354.018816,2.609152,0.0,63.635,59.195,3.5034517,1240.813568,0.0,0.0,7.523,6.309,3.6516362,1176.1664,2.33472,0.0,7.753,7.1,3.2855676,1181.55264,0.0,0.0,9.215,8.61,3.6076526,1097.617408,0.0,0.0,9.36,8.75
1442607384000,8.295071,1353.6256,2.961408,0.0,65.757,61.332,4.2162689,1243.979776,0.0,0.0,10.006,8.852,2.6748906,1173.184512,1.67936,0.0,8.672,7.392,2.9695656,1179.103232,0.0,0.0,7.333,6.284,2.4664153,1095.122944,0.0,0.0,9.343,8.124
1442607445000,11.0074752,1354.211328,2.670592,0.0,73.285,67.901,3.5710134,1243.820032,0.0,0.0,8.625,6.903,3.3715815,1175.621632,1.64864,0.0,9.293,7.905,4.3739386,1180.676096,0.0,0.0,10.583,8.645,3.5691119,1102.721024,0.0,0.0,11.278,9.903
1442607505000,7.2667852,1350.844416,2.383872,0.0,61.291,57.029,3.5129388,1243.049984,0.0,0.0,9.229,8.127,1.9652196,1173.835776,0.823296,0.0,8.214,6.31,2.7087865,1185.447936,0.0,0.0,7.146,7.14,2.6880117,1099.44832,0.0,0.0,8.701,7.904
1442607565000,12.0867906,1351.647232,1.654784,0.0,68.868,64.736,5.0789293,1247.719424,0.012288,0.0,10.62,9.311,4.330438,1177.9072,1.708032,0.0,8.726,8.102,5.1896315,1181.433856,0.0,0.0,8.82,7.839,4.9849365,1107.72224,0.0,0.0,9.29,8.646
1442607626000,10.1198146,1346.02752,2.076672,0.0,67.272,63.252,4.1015084,1226.653696,0.0,0.0,10.023,8.413,2.9440622,1171.636224,1.486848,0.0,7.601,6.555,3.6835325,1168.617472,0.0,0.0,10.699,9.243,3.8846913,1100.099584,0.0,0.0,9.13,7.63
1442607686000,11.2997679,1350.160384,4.685824,0.0,74.29,69.466,4.9527135,1227.472896,0.0,0.0,9.479,7.604,4.3016455,1173.491712,1.337344,0.0,8.937,6.861,5.0082857,1175.089152,0.0,0.0,10.286,9.215,3.8344509,1103.007744,0.0,0.0,11.605,10.161
1442607747000,13.0945369,1351.450624,3.82976,0.0,87.644,82.168,5.5048713,1230.381056,0.0,0.0,12.533,10.121,5.2767967,1171.341312,1.857536,0.0,10.605,9.243,4.6968166,1172.250624,0.0,0.0,12.587,10.463,4.8029134,1102.49984,0.0,0.0,11.347,9.321
1442607807000,10.0518768,1351.180288,3.64544,0.0,67.896,63.021,3.8410121,1231.290368,0.0,0.0,8.024,7.327,3.5814691,1172.770816,1.902592,0.0,9.848,8.397,4.2057506,1173.405696,0.0,0.0,10.142,7.855,4.0932274,1099.296768,0.008192,0.0,9.414,7.268
1442607867000,11.1619325,1353.224192,3.551232,0.0,74.993,70.205,3.6415575,1231.355904,0.0,0.0,8.789,7.778,3.6093886,1174.667264,1.91488,0.0,7.916,7.48,4.3236535,1173.020672,0.0,0.0,12.463,10.477,4.1116585,1101.14816,0.0,0.0,10.829,9.218
1442607927000,9.8789469,1354.059776,2.592768,0.0,75.466,70.907,3.7850167,1232.269312,0.0,0.0,10.223,8.778,3.1767214,1171.43552,1.544192,0.0,8.635,7.1,3.1244887,1183.301632,0.0,0.0,11.922,9.957,3.7109449,1101.881344,0.0,0.0,10.875,10.122
1442607987000,9.2511867,1350.774784,1.804288,0.0,66.402,61.741,2.8964087,1243.168768,0.0,0.0,8.993,7.09,3.8184249,1176.444928,1.685504,0.0,8.634,8.299,3.2744947,1183.883264,0.032768,0.0,9.544,8.53,3.6672938,1097.17504,0.0,0.0,9.613,8.996
1442608047000,10.8058668,1350.950912,3.207168,0.0,75.03,69.784,3.545815,1248.70656,0.0,0.0,9.351,7.653,3.1781123,1173.991424,1.478656,0.0,7.468,6.695,4.5748594,1184.759808,0.0,0.0,12.183,10.6,4.6272269,1097.879552,0.0,0.0,10.455,9.723
1442608107000,9.7325244,1350.094848,4.714496,0.0,63.983,60.567,3.5220376,1249.64864,0.032768,0.0,9.176,7.624,3.3404584,1172.611072,1.40288,0.0,6.538,6.22,3.7269246,1183.707136,0.0,0.0,10.613,8.514,3.2296033,1099.1616,0.0,0.0,8.473,7.371
1442608167000,9.2847155,1354.006528,2.629632,0.0,60.99,57.512,3.2060328,1248.41984,0.0,0.0,7.538,7.034,2.8787545,1176.256512,1.581056,0.0,8.248,6.854,3.7152986,1186.492416,0.0,0.0,8.976,8.231,3.8896339,1099.665408,0.0,0.0,10.018,8.265
1442608228000,7.8390904,1357.402112,3.52256,0.016384,55.888,52.68,3.8596745,1229.459456,0.0,0.0,9.231,8.21,2.7023066,1170.558976,0.681984,0.0,6.334,4.668,3.1815177,1170.235392,0.024576,0.0,8.05,7.952,2.623662,1087.389696,0.0,0.0,7.961,6.489
1442608288000,5.5911142,1352.43776,3.469312,0.016384,45.403,43.246,2.3003506,1236.09088,0.0,0.0,7.394,6.65,1.8658803,1173.622784,1.640448,0.0,5.346,5.199,1.9825195,1174.990848,0.0,0.0,6.394,5.56,2.3295367,1092.44416,0.065536,0.0,6.416,5.696
1442608348000,7.5560636,1353.474048,2.670592,0.016384,56.5,52.034,2.7578302,1230.729216,0.0,0.0,8.848,7.218,2.5333919,1175.293952,1.826816,0.0,6.511,6.642,2.4759095,1174.462464,0.0,0.0,6.721,5.452,2.407967,1092.947968,0.0,0.0,8.244,6.992
1442608408000,6.8421418,1352.921088,4.206592,0.024576,54.567,51.576,3.2868969,1232.384,0.0,0.0,7.49,6.145,1.9609952,1171.464192,1.714176,0.0,5.867,6.199,2.0487271,1176.420352,0.0,0.0,8.55,7.508,2.0986238,1094.807552,0.0,0.0,7.473,5.86
1442608468000,7.4682399,1353.969664,2.609152,0.028672,56.633,52.87,2.2786885,1236.873216,0.0,0.0,8.03,7.147,2.5446893,1174.208512,0.84992,0.0,5.493,4.863,3.1824147,1176.649728,0.0,0.0,9.663,9.087,2.6205456,1096.343552,0.0,0.0,7.686,6.99
1442608528000,7.4877461,1350.770688,1.503232,0.02048,50.646,46.594,1.8675136,1237.31968,0.0,0.0,5.406,4.951,2.5080067,1177.243648,2.011136,0.0,6.413,6.197,2.6355163,1179.496448,0.004096,0.0,7.675,7.692,2.645983,1097.207808,0.0,0.0,6.98,6.643
1442608589000,11.7981008,1350.647808,2.00704,0.016384,63.723,59.637,5.127509,1236.586496,0.0,0.0,7.835,7.767,3.6421758,1180.2624,1.806336,0.0,8.899,7.716,3.964178,1181.175808,0.0,0.0,8.907,7.282,3.8695558,1093.894144,0.0,0.0,10.227,8.751
1442608649000,11.2730344,1354.71104,4.366336,0.024576,65.413,61.483,4.2149869,1238.675456,0.0,0.0,7.083,6.277,4.3258853,1176.064,1.523712,0.0,8.746,7.731,3.8694936,1181.650944,0.0,0.0,6.951,5.687,4.1748514,1093.16096,0.0,0.0,10.637,9.532
1442608709000,10.7848733,1352.331264,2.789376,0.028672,65.263,60.691,4.3287894,1239.269376,0.0,0.0,9.527,7.957,3.3105082,1177.759744,1.253376,0.0,5.499,5.026,4.7961089,1190.273024,0.0,0.0,8.464,8.64,4.2969678,1088.704512,0.0,0.0,11.74,9.115
1442608770000,10.0316124,1352.912896,3.125248,0.0,70.611,65.469,4.0915065,1243.58656,0.0,0.0,9.651,8.014,3.867557,1178.927104,1.757184,0.0,8.19,7.039,3.9557369,1185.427456,0.004096,0.0,11.598,10.324,3.4158677,1085.616128,0.0,0.0,7.813,7.063
1442608830000,11.5127848,1345.4336,2.256896,0.0,73.323,68.295,4.7835075,1229.717504,0.032768,0.0,10.842,9.069,3.5049962,1176.588288,1.896448,0.0,8.032,7.105,4.6205208,1169.440768,0.0,0.0,11.734,11.132,3.6691584,1087.213568,0.0,0.0,9.793,7.736
1442608890000,11.3844439,1351.786496,3.117056,0.0,71.285,66.295,4.5518858,1229.262848,0.02048,0.0,10.029,8.165,3.6389601,1174.093824,1.538048,0.0,6.326,5.777,4.507768,1170.382848,0.004096,0.0,10.916,8.779,3.4248178,1087.942656,0.0,0.0,10.88,9.106
1442608950000,10.4977066,1351.974912,5.066752,0.0,67.357,63.536,4.3131368,1225.531392,0.0,0.0,9.85,8.264,3.3901468,1177.862144,1.714176,0.0,7.368,6.933,4.5247014,1172.04992,0.0,0.0,9.243,8.169,3.7917544,1089.830912,0.0,0.0,9.26,8.021
1442609010000,10.6085401,1350.242304,3.09248,0.0,75.199,70.176,3.0471679,1226.17856,0.004096,0.0,9.009,7.345,3.6785561,1180.803072,1.468416,0.0,8.776,8.052,3.5355591,1171.72224,0.0,0.0,10.261,8.38,4.5234111,1093.361664,0.0,0.0,10.99,10.611
1442609070000,10.2286331,1351.921664,2.953216,0.0,66.401,61.78,4.9686232,1230.5408,0.0,0.0,8.123,7.323,2.9526656,1180.413952,1.519616,0.0,8.429,6.874,4.0950102,1176.387584,0.0,0.0,10.985,9.471,3.4546823,1088.57344,0.0,0.0,8.58,7.47
1442609130000,10.1728083,1355.423744,4.231168,0.0,66.135,61.274,3.7509758,1234.464768,0.0,0.0,8.15,7.216,3.6983056,1178.087424,1.656832,0.0,7.358,7.028,3.3241009,1176.584192,0.0,0.0,9.599,8.811,3.9694083,1093.12,0.004096,0.0,9.18,8.485
1442609190000,11.4629301,1356.292096,3.309568,0.0,67.526,62.903,4.6522653,1234.665472,0.0,0.0,8.287,7.692,4.3534085,1178.087424,1.992704,0.0,8.592,7.331,4.0277195,1175.195648,0.008192,0.0,9.216,7.66,4.3212903,1089.609728,0.0,0.0,10.405,9.632
1442609250000,9.7493291,1354.641408,3.223552,0.0,57.665,54.0,4.6737834,1235.968,0.0,0.0,9.988,8.535,2.4776483,1177.706496,1.345536,0.0,6.537,5.116,3.4160458,1179.865088,0.0,0.0,7.248,7.134,3.8191963,1095.458816,0.0,0.0,8.004,7.261
1442609310000,8.7505502,1358.897152,4.497408,0.0,63.371,59.462,4.0034254,1233.801216,0.0,0.0,8.521,6.943,1.7991992,1178.238976,1.867776,0.0,6.492,6.432,3.5271829,1176.59648,0.0,0.0,9.35,8.012,3.1287094,1095.094272,0.0,0.0,9.111,7.779
1442609370000,10.4199831,1355.75552,2.973696,0.0,67.832,63.42,3.5058537,1236.80768,0.0,0.0,8.093,7.352,3.5965332,1179.2384,1.25952,0.0,8.34,6.495,4.614076,1176.440832,0.0,0.0,9.825,9.427,3.8845569,1091.588096,0.0,0.0,10.698,9.121
1442609431000,9.5264573,1352.224768,3.145728,0.0,56.224,52.655,3.8666815,1224.757248,0.0,0.0,9.402,8.718,2.7400957,1176.948736,1.183744,0.0,5.814,5.204,3.1764747,1157.05856,0.0,0.0,7.302,6.097,3.3636581,1090.367488,0.0,0.0,7.935,6.904
1442609492000,8.4451558,1354.686464,3.47136,0.0,55.069,51.011,2.7997293,1225.85088,0.0,0.0,6.044,5.349,3.8230366,1182.69952,2.367488,0.004096,8.891,8.119,2.7151737,1157.341184,0.008192,0.0,7.732,6.001,2.0527171,1096.245248,0.0,0.0,7.453,6.299
1442609552000,9.485193,1352.179712,2.756608,0.0,60.728,56.711,3.9287295,1233.891328,0.032768,0.0,9.673,8.785,3.6234588,1179.799552,1.447936,0.012288,7.31,6.322,2.7362782,1155.272704,0.0,0.0,7.361,6.176,3.9647594,1092.214784,0.0,0.0,8.924,8.084
1442609612000,9.1044902,1353.539584,2.531328,0.0,70.995,66.276,3.6643504,1231.802368,0.0,0.0,11.349,10.229,2.7331669,1179.213824,1.261568,0.014336,9.868,7.176,3.0863445,1156.616192,0.0,0.0,8.185,7.546,3.2150121,1093.279744,0.0,0.0,10.134,8.637
1442609672000,9.3995933,1354.985472,2.29376,0.0,68.77,64.467,3.4463191,1232.965632,0.0,0.0,8.936,7.564,3.1661854,1180.63104,1.878016,0.006144,9.096,7.5,3.4870264,1156.54656,0.0,0.0,9.799,7.613,3.6368222,1094.06208,0.0,0.0,11.197,8.915
1442609732000,9.9977015,1357.037568,3.817472,0.0,61.631,57.975,3.5307498,1235.918848,0.0,0.0,8.516,7.818,2.6823051,1184.555008,1.550336,0.006144,6.944,6.685,3.7479761,1158.32832,0.0,0.0,8.734,6.71,3.5575633,1096.167424,0.0,0.0,8.628,8.092
1442609792000,9.8766266,1356.210176,2.889728,0.0,66.297,61.861,3.9189719,1234.702336,0.0,0.0,8.788,7.793,3.6551425,1183.739904,1.585152,0.006144,9.173,8.103,3.6520623,1159.757824,0.004096,0.0,8.572,8.13,4.2021012,1095.737344,0.0,0.0,9.198,8.239
1442609853000,10.9551181,1358.880768,3.612672,0.0,69.021,64.44,3.8257741,1236.553728,0.0,0.0,9.666,7.877,4.0879173,1184.370688,1.97632,0.006144,9.339,8.317,3.9719584,1159.278592,0.0,0.0,9.896,8.164,3.9292093,1099.636736,0.0,0.0,8.747,8.657
1442609914000,10.7893731,1359.671296,2.6624,0.0,71.76,66.76,3.7936072,1238.687744,0.0,0.0,9.101,8.393,3.691522,1187.217408,1.101824,0.0,8.038,6.749,4.2556982,1153.957888,0.0,0.0,11.767,9.375,4.1381765,1100.73856,0.004096,0.0,10.607,8.504
1442609974000,10.1932395,1362.325504,4.62848,0.0,63.721,59.976,3.562562,1234.2272,0.0,0.0,7.568,6.769,3.9644271,1189.093376,1.191936,0.0,8.228,6.935,3.5607013,1152.004096,0.0,0.0,9.22,7.548,3.441838,1101.692928,0.0,0.0,9.483,9.096
1442610034000,9.3465924,1357.910016,3.91168,0.0,63.932,59.519,2.8763121,1219.022848,0.032768,0.0,6.739,6.404,3.1263405,1179.19744,1.419264,0.0,8.807,7.7,2.6665637,1139.818496,0.0,0.0,7.073,6.673,3.9927867,1097.654272,0.0,0.0,11.716,9.643
1442610094000,99.6270069,1373.310976,4.325376,0.0,63.349,59.061,2.9883525,1220.845568,0.0,0.0,7.772,7.351,1.9766582,1175.949312,1.00352,0.0,6.711,5.084,2.385665,1142.063104,0.0,0.0,9.858,8.608,3.1161084,1096.617984,0.0,0.0,9.186,7.945
1442610154000,9.0324708,1362.010112,3.15392,0.0,66.344,62.101,2.8960581,1231.85152,0.0,0.0,8.604,7.879,3.7009929,1197.6704,2.267136,0.0,9.509,8.92,2.9363374,1140.084736,0.0,0.0,8.087,6.9,2.8866765,1094.463488,0.032768,0.0,8.918,7.134
1442610214000,9.1144437,1355.149312,2.101248,0.0,63.827,59.71,3.6223374,1227.825152,0.0,0.0,9.144,7.995,2.5742322,1180.725248,1.615872,0.0,8.074,6.961,2.643923,1146.052608,0.0,0.0,8.034,7.397,3.2525011,1110.921216,0.0,0.0,10.34,8.852
1442610275000,8.9844184,1358.66368,2.6624,0.0,62.743,57.971,3.338527,1237.475328,0.032768,0.0,8.557,8.07,3.1073838,1182.015488,1.552384,0.0,8.222,6.613,3.6214765,1155.977216,0.008192,0.0,7.618,7.523,3.1291647,1102.282752,0.0,0.0,8.557,7.325
1442610335000,9.7457733,1355.40736,3.575808,0.0,50.08,46.694,3.9245979,1235.82464,0.0,0.0,6.628,6.123,3.097749,1180.782592,1.738752,0.0,5.782,5.087,2.7907017,1158.889472,0.0,0.0,7.178,6.746,3.3373486,1099.964416,0.0,0.0,7.247,6.599
1442610395000,8.7650312,1356.775424,2.904064,0.0,72.487,68.227,4.1838593,1239.175168,0.0,0.0,9.182,8.07,2.5504187,1183.526912,1.792,0.0,7.329,7.094,2.9989205,1157.279744,0.0,0.0,11.633,8.69,3.5294433,1100.644352,0.0,0.0,11.355,9.491
1442610455000,8.2327858,1359.540224,3.108864,0.0,51.668,48.852,3.6821768,1234.632704,0.0,0.0,9.015,7.96,2.3529557,1180.041216,1.110016,0.0,7.12,5.501,2.2637151,1158.844416,0.0,0.0,6.706,5.617,3.4426747,1101.094912,0.0,0.0,6.845,6.671
1442610515000,12.2465779,1360.932864,3.084288,0.0,78.96,73.204,4.5147961,1235.316736,0.0,0.0,9.792,8.849,3.7126159,1187.692544,1.478656,0.0,9.122,7.532,5.156903,1159.589888,0.0,0.0,9.384,7.993,3.9459276,1100.709888,0.0,0.0,13.0,10.813
1442610575000,10.7160351,1359.72864,3.657728,0.0,69.213,65.225,3.6548276,1233.297408,0.0,0.0,8.703,7.723,4.3375276,1189.482496,1.718272,0.0,8.991,8.343,4.1453427,1156.390912,0.0,0.0,10.013,7.711,4.464717,1097.449472,0.0,0.0,10.122,8.923
1442610635000,7.6623042,1361.883136,3.510272,0.0,53.596,50.114,1.9919733,1203.146752,0.032768,0.0,5.937,5.162,2.8511435,1184.034816,1.888256,0.0,7.054,6.973,2.7869306,1148.915712,0.0,0.0,8.638,7.52,2.8106515,1095.512064,0.0,0.0,7.473,5.984
1442610696000,11.115201,1360.101376,1.929216,0.0,64.186,59.449,3.5678531,1208.348672,0.0,0.0,7.113,6.299,3.4813934,1184.579584,1.646592,0.0,7.69,6.651,3.4114599,1152.765952,0.0,0.0,10.603,8.626,4.4814811,1099.051008,0.0,0.0,9.41,7.936
1442610756000,9.9899381,1363.124224,4.17792,0.0,61.922,57.82,4.152687,1213.620224,0.0,0.0,7.337,6.847,3.0988971,1178.701824,1.425408,0.0,8.268,6.901,3.9877835,1153.568768,0.0,0.0,9.506,8.302,3.4528874,1108.054016,0.032768,0.0,8.141,7.08
1442610816000,11.6963619,1368.424448,3.600384,0.0,70.314,65.519,3.9784071,1213.710336,0.0,0.0,7.071,5.928,7.0120837,1183.977472,2.093056,0.0,8.858,8.07,4.2109578,1152.487424,0.0,0.0,11.726,10.498,4.7146951,1098.866688,0.0,0.0,10.275,8.335
1442610876000,9.5554621,1368.69888,3.936256,0.0,63.596,59.885,4.3762741,1214.951424,0.0,0.0,9.011,7.448,6.371399,1180.311552,1.767424,0.0,7.708,6.449,4.1251919,1152.376832,0.0,0.0,9.365,8.206,2.5347994,1098.006528,0.0,0.0,7.762,6.327
1442610936000,10.9193675,1370.021888,3.354624,0.0,69.485,64.678,4.5326375,1218.895872,0.0,0.0,9.776,7.987,4.1998125,1181.45024,1.968128,0.0,7.224,6.977,4.18548,1154.121728,0.0,0.0,8.948,7.712,4.013351,1099.358208,0.0,0.0,10.525,8.706
1442610996000,8.5215244,1369.460736,3.723264,0.0,49.387,45.918,3.111676,1218.052096,0.0,0.0,6.797,6.12,3.8227803,1185.992704,1.60768,0.0,5.391,5.409,2.191133,1155.166208,0.0,0.0,8.028,6.635,3.7225037,1096.486912,0.0,0.0,6.917,6.277
1442611057000,11.192929,1366.376448,3.555328,0.0,71.088,66.797,3.9978156,1223.737344,0.0,0.0,9.676,8.133,3.6933989,1180.30336,1.837056,0.0,9.128,8.016,3.8538996,1165.49632,0.0,0.0,11.174,8.881,3.9523088,1097.711616,0.032768,0.0,9.141,8.118
1442611118000,8.2740143,1365.79072,2.084864,0.012288,71.187,67.227,2.822589,1226.870784,0.0,0.0,9.651,8.04,2.8763542,1183.121408,1.583104,0.0,9.427,7.358,3.1928351,1163.669504,0.0,0.0,10.758,8.542,2.6986864,1095.815168,0.0,0.0,10.592,8.72
1442611178000,8.2912895,1371.103232,3.538944,0.016384,63.993,59.566,3.3025336,1226.674176,0.0,0.0,9.052,8.292,2.9040441,1180.614656,1.939456,0.0,6.562,5.608,2.3133554,1158.483968,0.0,0.0,11.361,9.678,2.699855,1094.729728,0.0,0.0,8.073,7.829
1442611239000,10.2784953,1361.711104,2.623488,0.012288,52.029,48.273,3.577345,1206.284288,0.0,0.0,7.17,6.968,3.4269003,1173.85216,1.595392,0.0,6.33,5.344,3.6850987,1144.029184,0.0,0.0,6.937,6.684,3.8219618,1088.618496,0.0,0.0,7.879,7.148
1442611299000,11.1765459,1366.155264,2.94912,0.012288,64.457,61.425,4.3250243,1216.823296,0.0,0.0,7.647,6.159,3.7760025,1176.24832,1.347584,0.0,8.767,7.401,4.5291896,1151.668224,0.0,0.0,10.107,9.494,4.2891895,1094.67648,0.0,0.0,9.405,8.596
1442611359000,9.5942905,1367.293952,1.298432,0.012288,53.859,50.46,3.715692,1219.530752,0.0,0.0,8.42,7.503,2.8690556,1178.37824,1.302528,0.0,7.096,5.593,3.1453847,1153.04448,0.0,0.0,7.516,6.989,3.5307459,1093.091328,0.0,0.0,7.158,6.543
1442611419000,10.7708134,1367.601152,2.801664,0.012288,60.523,57.471,4.2096576,1215.24224,0.0,0.0,7.122,6.234,3.8373569,1186.24256,1.738752,0.0,7.827,7.021,4.5322068,1149.558784,0.0,0.0,8.956,8.037,4.1642567,1100.505088,0.0,0.0,9.105,8.045
1442611480000,10.5968447,1366.482944,1.88416,0.0,60.812,56.774,3.3216922,1218.822144,0.0,0.0,6.368,5.767,4.1712005,1189.25312,1.716224,0.0,7.745,6.555,7.3752032,1150.60736,0.0,0.0,10.16,8.991,4.4603654,1102.90944,0.0,0.0,9.025,8.261
1442611540000,8.1272469,1368.940544,3.52256,0.0,51.846,49.064,2.5651737,1218.854912,0.0,0.0,7.682,6.768,2.225522,1186.75456,1.251328,0.0,6.267,6.35,3.7404585,1152.708608,0.016384,0.0,6.582,5.857,2.988806,1104.453632,0.0,0.0,8.06,6.879
1442611600000,11.2612569,1371.602944,2.12992,0.004096,75.719,70.976,3.7064445,1221.705728,0.0,0.0,9.435,7.794,3.5357041,1189.384192,1.173504,0.0,7.902,6.71,4.8562673,1156.419584,0.0,0.0,12.748,11.043,4.1888823,1108.0704,0.0,0.0,11.963,10.445
1442611660000,12.8541826,1368.485888,3.05152,0.0,81.947,76.019,3.7814395,1218.420736,0.0,0.0,10.855,9.97,5.1222131,1199.214592,1.304576,0.0,9.989,7.869,4.9812964,1154.912256,0.0,0.0,11.347,9.955,4.3265291,1103.941632,0.0,0.0,11.179,9.544
1442611720000,11.8086723,1368.7808,1.871872,0.004096,78.918,72.595,3.5666304,1221.505024,0.0,0.0,8.111,7.145,4.9887881,1198.321664,1.769472,0.0,11.026,9.229,4.2575334,1154.961408,0.0,0.0,10.775,10.49,5.1064266,1106.214912,0.0,0.0,11.685,10.48
1442611780000,8.7056716,1374.478336,2.351104,0.0,54.226,50.546,3.2355248,1218.330624,0.0,0.0,6.139,6.051,2.9984255,1199.06304,1.25952,0.0,7.784,6.295,5.8388371,1158.51264,0.0,0.0,7.671,6.32,2.7726144,1103.72864,0.0,0.0,8.154,7.003
1442611840000,6.1445852,1368.305664,2.8672,0.0,63.388,59.327,4.2264082,1214.89408,0.0,0.0,9.532,8.466,2.1073482,1190.653952,1.083392,0.0,7.546,6.409,2.4889874,1158.950912,0.0,0.0,8.475,7.437,2.2730746,1093.701632,0.0,0.0,10.181,8.491
1442611901000,8.3854653,1362.157568,2.465792,0.0,52.22,48.66,3.8835961,1208.590336,0.0,0.0,6.571,6.809,2.6257775,1190.141952,1.47456,0.0,6.508,5.78,2.7850886,1150.160896,0.0,0.0,7.375,7.486,3.0392659,1094.488064,0.032768,0.0,6.824,5.709
1442611961000,8.4516232,1368.788992,3.416064,0.0,57.817,54.2,3.4127217,1208.246272,0.032768,0.0,7.825,6.652,3.0418429,1195.556864,0.935936,0.0,6.692,5.692,3.8104357,1154.08896,0.0,0.0,7.982,7.36,2.9572697,1098.133504,0.0,0.0,8.458,7.688
1442612021000,9.4090336,1369.350144,2.400256,0.0,59.085,55.262,4.1488405,1211.244544,0.0,0.0,7.02,6.143,3.5127047,1197.973504,1.789952,0.0,7.242,6.222,3.5150098,1153.732608,0.0,0.0,8.307,7.718,3.7325166,1098.67008,0.008192,0.0,9.474,7.933
1442612081000,10.2619177,1367.740416,1.339392,0.0,65.144,60.639,3.5907578,1210.433536,0.0,0.0,9.521,8.078,2.6068336,1194.213376,1.624064,0.0,7.399,6.308,4.0636057,1155.948544,0.0,0.0,9.516,8.836,3.7651952,1096.384512,0.0,0.0,9.415,9.029
1442612141000,10.2278322,1369.86624,3.547136,0.0,67.318,62.731,3.6869439,1215.041536,0.0,0.0,9.011,7.954,4.088915,1194.508288,1.447936,0.0,8.602,7.303,3.3905779,1155.756032,0.0,0.0,9.107,7.945,3.3478702,1098.952704,0.0,0.0,9.871,9.102
1442612201000,9.4007299,1369.616384,4.018176,0.0,61.593,57.889,4.0836962,1215.934464,0.0,0.0,9.087,7.844,2.9481462,1198.452736,1.568768,0.0,7.514,6.785,3.9489365,1157.640192,0.0,0.0,9.108,7.693,2.58404,1101.287424,0.0,0.0,7.001,5.448
1442612261000,8.2266702,1368.395776,3.055616,0.0,61.261,57.691,3.0504633,1219.907584,0.0,0.0,8.34,7.868,3.1558476,1201.524736,1.214464,0.0,7.526,6.47,2.6710837,1157.259264,0.0,0.0,8.455,7.458,3.1383786,1104.662528,0.0,0.0,9.706,8.006
1442612321000,7.8431341,1366.433792,1.748992,0.0,56.138,53.394,1.877441,1218.793472,0.0,0.0,7.595,6.827,3.3211516,1201.72544,2.066432,0.0,7.142,6.757,3.6872372,1159.401472,0.0,0.0,7.563,6.548,3.0337172,1104.736256,0.0,0.0,9.468,8.52
1442612381000,9.9163593,1368.20736,3.309568,0.0,62.256,58.617,3.1215909,1214.140416,0.0,0.0,9.073,7.189,3.7669493,1203.318784,1.957888,0.0,7.425,6.706,3.5153785,1160.699904,0.0,0.0,8.088,7.55,4.1549358,1105.170432,0.0,0.0,9.589,8.575
1442612441000,8.1121463,1365.684224,3.633152,0.0,60.367,57.181,2.4502541,1205.67808,0.0,0.0,7.527,6.179,2.5907558,1200.918528,0.958464,0.0,7.1,6.123,2.860918,1156.104192,0.0,0.0,9.268,8.283,3.2034394,1101.47584,0.012288,0.0,9.836,8.02
1442612501000,9.2056674,1349.185536,2.637824,0.0,54.345,50.873,2.7295603,1175.539712,0.0,0.0,6.412,5.554,3.8424716,1188.999168,1.460224,0.0,6.313,6.08,3.6853781,1141.379072,0.0,0.0,7.695,6.004,4.4226641,1094.893568,0.0,0.0,9.719,7.579
1442612561000,8.6074261,1369.305088,2.453504,0.0,53.261,50.118,3.1349023,1198.24384,0.0,0.0,5.666,5.379,4.0823135,1202.388992,1.927168,0.0,8.358,7.648,2.7352911,1155.977216,0.0,0.0,6.721,5.447,2.8725549,1103.618048,0.008192,0.0,9.032,7.493
1442612621000,9.0561283,1371.037696,3.002368,0.0,60.309,56.053,4.1966062,1195.442176,0.012288,0.0,9.088,7.439,3.7393865,1198.026752,1.3824,0.0,6.898,6.018,3.1786438,1153.236992,0.0,0.0,8.01,8.053,2.9311478,1103.609856,0.0,0.0,8.032,6.859
1442612681000,10.5632705,1366.163456,2.930688,0.0,74.508,68.383,4.4116358,1195.70432,0.0,0.0,10.307,8.132,3.1994732,1199.321088,1.908736,0.0,8.526,7.256,4.6435358,1162.047488,0.0,0.0,10.44,9.535,3.9852341,1100.468224,0.0,0.0,10.042,9.233
1442612742000,8.9034235,1369.247744,4.56704,0.0,60.177,56.833,3.611587,1205.948416,0.0,0.0,8.195,6.58,3.044789,1195.610112,1.097728,0.01024,7.298,6.044,3.2749045,1167.478784,0.0,0.0,8.63,7.613,3.1443348,1104.2816,0.0,0.0,8.51,7.591
1442612802000,9.1529203,1368.137728,3.280896,0.0,60.927,56.25,2.6699648,1203.265536,0.0,0.0,7.011,5.616,3.4997064,1197.707264,1.57696,0.01024,7.832,7.253,3.188966,1166.487552,0.0,0.0,9.729,8.587,3.2049926,1105.002496,0.0,0.0,7.892,6.633
1442612862000,10.1999918,1369.300992,2.854912,0.0,65.111,60.972,4.5222885,1207.619584,0.0,0.0,10.516,9.575,3.5252463,1201.168384,1.552384,0.01024,7.798,6.981,3.5979517,1168.400384,0.0,0.0,9.136,7.637,3.3768944,1102.565376,0.0,0.0,7.425,5.985
1442612922000,10.4377615,1371.967488,2.392064,0.0,60.313,56.361,3.7951803,1208.389632,0.0,0.0,7.136,6.212,3.7507419,1199.775744,2.004992,0.012288,7.608,6.995,3.7581243,1169.453056,0.0,0.0,8.516,7.897,3.8438461,1102.036992,0.032768,0.0,8.47,7.452
1442612982000,7.6327763,1371.521024,2.842624,0.0,54.778,51.386,2.4243276,1212.981248,0.0,0.0,7.688,6.27,2.8022497,1199.693824,1.521664,0.03072,7.05,6.525,2.8519025,1175.928832,0.0,0.0,7.674,6.717,2.9901444,1112.04352,0.016384,0.0,8.332,7.105
1442613042000,9.4616609,1375.227904,4.800512,0.0,61.774,57.836,3.3303819,1216.712704,0.004096,0.0,7.429,6.529,3.549346,1196.11392,1.667072,0.008192,8.012,7.059,3.7893841,1172.86912,0.0,0.0,9.346,7.791,3.3648619,1113.214976,0.0,0.0,8.432,7.312
1442613103000,11.0365339,1362.530304,2.793472,0.0,72.712,67.096,4.0103299,1195.33568,0.065536,0.0,9.721,8.104,3.5590995,1189.617664,1.005568,0.016384,8.005,6.608,4.466848,1166.52032,0.0,0.0,11.568,10.007,4.8506527,1111.085056,0.0,0.0,8.548,7.755
1442613164000,9.0164014,1360.474112,1.859584,0.0,62.121,57.508,3.247818,1194.708992,0.028672,0.0,8.125,6.924,3.3523807,1196.371968,1.25952,0.0,7.345,6.059,2.8034988,1165.402112,0.0,0.0,9.577,9.053,3.4280283,1105.645568,0.0,0.0,9.075,8.038
1442613224000,10.6723151,1366.454272,4.067328,0.0,70.529,66.182,4.4394151,1203.134464,0.0,0.0,11.342,9.98,3.7365758,1196.511232,0.84992,0.0,8.214,6.13,4.1638944,1166.307328,0.0,0.0,8.584,7.777,4.0560518,1111.359488,0.0,0.0,9.735,8.528
1442613284000,10.0839306,1362.41152,1.953792,0.0,65.98,61.546,3.9158954,1204.068352,0.0,0.0,9.783,8.209,3.0329789,1197.318144,1.93536,0.0,8.414,7.498,3.0238536,1163.718656,0.0,0.0,8.901,7.838,3.2554926,1110.949888,0.0,0.0,8.417,7.646
1442613344000,9.8824599,1364.43904,3.018752,0.0,65.709,60.777,3.8212024,1206.931456,0.0,0.0,10.524,8.396,2.9449871,1198.112768,1.380352,0.0,6.173,5.254,3.9970522,1162.612736,0.0,0.0,8.383,7.139,3.890617,1108.873216,0.0,0.0,10.483,9.355
1442613404000,10.2521035,1364.893696,2.105344,0.0,60.197,55.973,4.1571193,1214.44352,0.0,0.0,7.772,7.774,3.4026243,1200.963584,0.722944,0.0,7.344,5.249,3.88914,1172.41856,1.867776,0.0,9.598,8.614,3.3917535,1111.94112,0.0,0.0,9.065,7.491
1442613464000,10.1639409,1370.025984,4.952064,0.0,67.984,64.975,3.9856602,1216.139264,0.0,0.0,9.034,8.577,3.6692346,1198.10048,1.091584,0.0,6.984,5.58,3.1784809,1171.304448,0.0,0.0,10.435,8.069,3.3665955,1110.683648,0.032768,0.0,9.774,8.435
1442613525000,9.7928044,1367.482368,2.895872,0.0,62.406,59.055,3.9761248,1216.507904,0.0,0.0,9.729,8.091,3.4714503,1197.191168,1.728512,0.0,7.872,6.985,3.6576174,1169.854464,0.0,0.0,9.328,6.653,3.3069065,1109.962752,0.0,0.0,7.888,6.909
1442613586000,11.2779169,1368.461312,2.158592,0.0,60.979,57.08,5.4678319,1217.80224,0.0,0.0,10.528,9.209,3.6444638,1198.682112,1.476608,0.0,8.063,7.701,3.8234205,1174.024192,0.0,0.0,7.916,7.388,3.6100849,1110.319104,0.0,0.0,6.796,6.172
1442613647000,10.7544996,1367.805952,3.579904,0.0,72.076,67.29,3.4228877,1211.449344,0.0,0.0,9.899,8.536,3.6304314,1200.037888,1.378304,0.0,7.118,5.926,3.8842138,1175.584768,0.0,0.0,10.074,8.681,4.212636,1113.686016,0.0,0.0,10.354,9.423
1442613707000,9.2666835,1369.82528,3.960832,0.0,71.584,67.281,3.9505762,1191.501824,0.0,0.0,10.584,8.884,3.4480111,1192.513536,1.398784,0.0,8.683,6.969,3.1084327,1166.495744,0.0,0.0,10.566,8.559,2.6958695,1097.003008,0.0,0.0,8.599,7.897
1442613767000,11.1144751,1370.263552,4.984832,0.0,67.616,63.048,4.6702086,1195.95008,0.0,0.0,7.793,6.561,3.8936624,1192.603648,1.251328,0.0,8.399,6.877,3.1134429,1161.248768,0.0,0.0,9.52,8.542,3.9633563,1101.430784,0.0,0.0,9.437,9.214
1442613827000,4.3821069,1372.196864,2.772992,0.0,42.776,40.158,1.4129247,1200.20992,0.0,0.0,6.397,4.764,2.0189966,1194.233856,0.704512,0.0,4.469,4.236,2.2115512,1168.879616,0.749568,0.0,7.849,6.327,1.7939379,1107.275776,0.0,0.0,5.943,4.389
1442613887000,7.144461,1367.670784,1.61792,0.0,60.063,56.226,3.3544895,1200.7424,0.0,0.0,7.699,6.919,2.3552338,1197.973504,1.906688,0.0,7.323,6.314,2.4864378,1170.026496,0.032768,0.0,8.759,7.605,2.7001307,1107.832832,0.0,0.0,9.241,8.202
1442613947000,9.7658704,1370.345472,2.859008,0.0,58.941,55.323,3.0440062,1201.410048,0.0,0.0,6.562,6.175,3.1982587,1196.118016,1.744896,0.0,6.647,5.967,3.1092085,1169.801216,0.0,0.0,8.611,7.882,3.8793951,1104.441344,0.0,0.0,9.38,8.826
1442614007000,11.026074,1370.9312,2.697216,0.0,64.663,60.679,3.8224596,1204.711424,0.0,0.0,8.322,7.483,3.8039287,1199.407104,1.671168,0.0,7.333,6.943,4.563412,1168.756736,0.0,0.0,9.008,8.238,3.9643816,1102.852096,0.0,0.0,10.187,8.877
1442614067000,8.6195963,1371.32032,4.661248,0.0,67.013,62.98,3.4961652,1203.744768,0.02048,0.0,9.282,7.445,3.0305914,1196.376064,2.072576,0.0,8.259,7.587,2.8872377,1172.975616,0.0,0.0,9.751,8.349,2.4800588,1104.797696,0.0,0.0,9.43,8.284
1442614127000,9.873743,1369.808896,3.54304,0.0,63.23,58.508,3.8129869,1206.33344,0.0,0.0,8.015,7.666,3.2519741,1199.86176,1.419264,0.0,6.179,5.481,4.113247,1172.668416,0.0,0.0,10.427,8.888,3.7281569,1102.25408,0.0,0.0,8.869,8.211
1442614187000,10.5301501,1369.370624,3.91168,0.0,71.223,67.739,3.7090818,1217.69984,0.0,0.0,7.424,6.4,4.1657491,1198.854144,1.968128,0.0,8.851,8.335,4.8705142,1180.13952,0.0,0.0,11.698,9.763,4.0439032,1099.542528,0.0,0.0,10.399,8.813
1442614247000,10.5223332,1367.842816,4.501504,0.0,70.881,66.912,3.5807216,1215.7952,0.0,0.0,8.005,6.785,3.0319457,1195.487232,2.179072,0.0,7.71,6.992,4.0309504,1180.069888,0.0,0.0,9.461,8.253,4.3312979,1096.654848,0.0,0.0,12.222,10.399
1442614307000,10.5155216,1364.279296,4.612096,0.0,65.022,61.419,3.6882151,1193.017344,0.0,0.0,8.116,6.419,4.1899168,1195.503616,2.015232,0.0,7.879,7.328,4.8300017,1170.624512,0.0,0.0,9.796,8.58,3.7142748,1084.35456,0.0,0.0,8.613,7.973
1442614367000,11.2402232,1368.260608,3.248128,0.0,78.064,73.302,3.7770995,1198.645248,0.0,0.0,9.467,7.907,4.5831342,1192.8576,2.480128,0.0,9.044,8.014,3.8891036,1171.140608,0.0,0.0,11.305,9.573,4.371265,1084.64128,0.0,0.0,12.457,10.452
1442614427000,11.2165303,1367.662592,3.219456,0.0,73.892,68.822,4.7659038,1199.562752,0.0,0.0,10.368,8.783,4.2178337,1193.992192,2.236416,0.0,9.624,8.342,3.1775411,1169.739776,0.0,0.0,7.8,6.657,3.9523946,1086.390272,0.0,0.0,10.394,10.235
1442614487000,11.2978369,1364.975616,4.210688,0.0,77.204,73.018,3.5634057,1198.7968,0.032768,0.0,9.718,8.084,4.2458661,1193.422848,2.02752,0.0,8.916,8.594,4.7695505,1171.034112,0.028672,0.0,12.693,10.519,4.2964958,1086.480384,0.0,0.0,10.225,7.976
1442614547000,9.1496304,1366.87616,3.756032,0.0,57.742,53.717,3.1502619,1204.043776,0.0,0.0,8.42,7.813,2.4383087,1193.43104,0.518144,0.0,6.108,4.627,3.6453211,1171.316736,0.0,0.0,9.141,8.716,2.8645726,1086.365696,0.0,0.0,7.722,6.15
1442614607000,9.953719,1365.17632,2.322432,0.0,63.945,58.729,3.477875,1204.891648,0.028672,0.0,7.645,6.503,3.0974573,1191.428096,1.226752,0.0,8.173,7.088,3.1081603,1167.343616,0.0,0.0,8.573,7.167,3.231772,1094.414336,0.004096,0.0,9.942,8.834
1442614667000,11.3363754,1366.228992,3.055616,0.0,69.019,64.009,3.724492,1208.229888,0.0,0.0,8.8,7.677,3.8787098,1192.361984,1.970176,0.0,7.483,6.949,3.5104812,1166.70464,0.0,0.0,9.289,7.822,5.1135739,1096.265728,0.0,0.0,10.724,9.536
1442614727000,9.4026128,1366.061056,2.322432,0.0,66.839,62.087,3.6297203,1209.831424,0.04096,0.0,7.896,6.846,2.3921338,1193.828352,1.787904,0.0,7.909,7.208,3.2803326,1167.93344,0.0,0.0,9.819,8.411,3.7886267,1095.3728,0.0,0.0,10.324,9.145
1442614788000,8.9266728,1369.501696,4.4032,0.012288,55.6,52.371,3.4642723,1206.300672,0.0,0.0,6.559,5.348,2.7675925,1194.467328,1.722368,0.0,6.669,6.003,2.7776683,1169.403904,0.0,0.0,8.546,6.924,3.6892569,1099.554816,0.0,0.0,8.971,7.264
1442614848000,10.0600593,1369.092096,2.187264,0.012288,71.874,67.678,3.8324672,1206.206464,0.032768,0.0,10.282,8.839,3.1710617,1199.7184,1.591296,0.0,8.707,7.212,3.9753455,1171.525632,0.0,0.0,10.13,7.927,3.695216,1098.448896,0.0,0.0,11.491,9.587
1442614908000,8.3002008,1365.860352,4.03456,0.024576,64.435,60.807,2.9452091,1180.954624,0.0,0.0,10.689,8.707,3.2412608,1196.531712,1.492992,0.0,8.415,6.886,2.62,1160.192,0.0,0.0,9.511,7.742,3.3500483,1092.87424,0.0,0.0,8.274,6.796
1442614968000,9.649117,1367.392256,3.182592,0.012288,56.322,52.136,3.2164282,1194.459136,0.0,0.0,7.986,7.82,3.2763617,1193.422848,1.2288,0.0,6.525,5.339,3.4186285,1158.959104,0.0,0.0,7.448,7.682,3.5843004,1090.281472,0.0,0.0,7.053,6.652
1442615028000,8.4184518,1368.10496,3.383296,0.024576,55.068,51.342,2.5502011,1195.073536,0.032768,0.0,6.689,6.344,3.0370211,1194.10688,1.37216,0.0,7.098,6.187,3.0451169,1161.576448,0.0,0.0,8.213,8.434,3.2102236,1088.925696,0.0,0.0,7.342,6.358
1442615088000,9.4960445,1367.515136,3.203072,0.024576,68.027,63.721,3.9919631,1195.905024,0.0,0.0,9.62,7.602,3.2182874,1195.528192,1.255424,0.0,7.15,5.665,3.3384978,1163.63264,0.0,0.0,10.177,8.321,4.1456683,1089.687552,0.0,0.0,9.511,7.952
1442615148000,8.9164095,1368.690688,2.461696,0.02048,59.942,56.352,3.8129628,1193.517056,0.0,0.0,7.876,7.856,3.1226018,1195.47904,2.625536,0.0,6.679,6.899,3.4898414,1162.436608,0.0,0.0,8.105,6.873,3.5293431,1091.13344,0.0,0.0,8.269,7.166
1442615208000,10.8682786,1371.246592,1.82272,0.016384,73.536,68.266,4.1353619,1196.560384,0.0,0.0,9.712,7.867,3.6012589,1196.29824,2.236416,0.0,8.146,8.253,4.7225177,1161.293824,0.008192,0.0,9.777,7.611,4.3023491,1092.128768,0.0,0.0,11.023,10.199
1442615268000,6.5367493,1375.080448,3.4816,0.02048,55.107,52.011,2.9430016,1197.428736,0.0,0.0,10.032,7.903,2.4939204,1194.442752,1.298432,0.0,7.168,5.9,2.6159459,1164.193792,0.032768,0.0,6.319,5.918,1.6446541,1092.636672,0.0,0.0,7.983,7.003
1442615328000,8.0258626,1374.261248,2.940928,0.02048,52.814,50.069,3.2043971,1200.832512,0.0,0.0,7.043,6.804,2.5419894,1197.092864,1.763328,0.0,7.938,6.802,2.5662903,1164.353536,0.0,0.0,6.782,5.659,3.3033704,1092.440064,0.0,0.0,6.408,5.902
1442615388000,8.8448384,1374.867456,3.170304,0.012288,67.72,63.866,3.4671722,1200.586752,0.0,0.0,8.102,7.511,3.0609005,1196.269568,1.56672,0.0,8.087,6.922,2.851716,1162.502144,0.0,0.0,10.472,9.144,3.5930457,1097.330688,0.0,0.0,10.027,8.89
1442615448000,9.0226252,1372.688384,4.247552,0.028672,61.972,58.5,3.6511262,1199.570944,0.0,0.0,8.417,7.813,2.8066115,1196.048384,1.236992,0.0,6.838,5.474,3.2264724,1165.16864,0.0,0.0,9.844,8.1,3.5959016,1097.900032,0.0,0.0,9.591,7.417
1442615508000,9.7981992,1364.996096,2.510848,0.0,63.489,59.732,3.2771747,1174.245376,0.016384,0.0,8.663,7.247,2.9769091,1189.441536,1.603584,0.0,6.903,6.25,3.9229123,1149.304832,0.0,0.0,10.207,9.587,3.3135095,1092.743168,0.0,0.0,9.365,7.604
1442615569000,9.9784261,1373.319168,4.214784,0.02048,63.029,59.597,2.6563727,1182.179328,0.0,0.0,6.888,6.444,3.1457881,1191.202816,0.638976,0.0,5.415,4.722,3.6639563,1152.466944,0.0,0.0,11.323,8.794,4.1530909,1093.496832,0.0,0.0,9.796,9.109
1442615630000,11.4891017,1375.924224,2.490368,0.0,64.78,61.041,4.0307274,1188.143104,0.0,0.0,7.939,6.91,4.3965673,1194.037248,1.50528,0.0,8.263,7.356,4.2316688,1155.715072,0.0,0.0,10.964,9.912,3.8300932,1095.417856,0.0,0.0,8.326,7.477
1442615690000,9.6295017,1374.6176,2.596864,0.0,60.704,56.8,4.096498,1182.851072,0.02048,0.0,8.994,7.544,3.9432333,1197.596672,2.203648,0.0,7.954,7.264,2.4949641,1154.666496,0.0,0.0,5.937,5.247,3.6873883,1099.341824,0.004096,0.0,10.703,8.688
1442615750000,10.2483768,1374.98624,4.739072,0.0,78.899,74.006,4.3386151,1184.264192,0.0,0.0,9.398,8.561,3.4714352,1195.257856,1.056768,0.0,7.978,7.442,3.918995,1157.091328,0.0,0.0,12.287,10.238,4.2057919,1096.372224,0.0,0.0,12.286,11.305
1442615810000,11.3586081,1376.014336,5.251072,0.008192,70.549,66.552,3.953347,1183.1296,0.0,0.0,9.494,7.774,3.7625888,1195.008,1.497088,0.0,7.528,6.891,4.2269961,1162.604544,0.0,0.0,8.576,7.272,4.7786726,1097.515008,0.0,0.0,12.702,10.87
1442615870000,10.8895748,1376.11264,2.68288,0.0,61.476,56.853,3.948781,1186.443264,0.032768,0.0,6.866,6.882,3.8309924,1197.195264,1.302528,0.0,7.484,6.694,3.835954,1165.705216,0.0,0.0,8.505,7.985,3.9435967,1097.756672,0.0,0.0,9.719,8.43
1442615931000,10.1907304,1377.173504,3.985408,0.0,67.589,63.129,3.263806,1184.432128,0.0,0.0,9.338,7.964,3.8251351,1196.875776,1.646592,0.0,8.028,7.353,3.1605558,1162.67008,0.0,0.0,7.428,6.595,4.3071848,1095.630848,0.0,0.0,11.82,9.637
1442615991000,9.9826152,1376.62464,3.93216,0.0,78.443,72.984,3.9298556,1187.807232,0.0,0.0,8.854,7.098,3.5184006,1196.560384,1.630208,0.0,9.023,8.166,3.6215566,1164.689408,0.0,0.0,12.031,10.201,3.8237905,1095.3728,0.0,0.0,12.152,9.826
1442616051000,9.624732,1380.548608,5.705728,0.0,66.115,62.656,3.4531973,1188.950016,0.0,0.0,9.689,8.091,2.9769231,1198.092288,1.380352,0.0,6.817,6.291,3.4287975,1166.319616,0.0,0.0,9.536,7.623,3.4372847,1097.322496,0.0,0.0,9.121,8.509
1442616111000,5.9999698,1373.483008,3.395584,0.0,58.055,54.085,3.0160345,1178.76736,0.0,0.0,7.707,6.413,2.1215605,1196.937216,1.57696,0.0,7.012,6.272,2.3948594,1155.64544,0.0,0.0,8.873,6.685,1.9718854,1097.8304,0.0,0.0,8.523,7.687
1442616171000,8.6226916,1375.903744,4.624384,0.0,47.807,45.372,3.0150171,1171.1488,0.012288,0.0,5.335,4.925,2.6950046,1196.068864,1.282048,0.0,6.876,5.811,2.6836525,1154.10944,0.0,0.0,8.304,6.294,3.0054759,1097.478144,0.0,0.0,5.345,5.783
1442616231000,11.3780505,1374.081024,2.895872,0.0,66.163,62.215,3.6146489,1177.300992,0.0,0.0,6.729,6.449,3.644994,1196.937216,1.78176,0.0,8.736,7.07,4.1484608,1155.551232,0.0,0.0,8.492,7.711,4.5974615,1095.08608,0.0,0.0,11.877,9.917
1442616291000,9.9446189,1381.10976,3.579904,0.0,64.706,61.035,3.2741076,1175.670784,0.004096,0.0,7.627,6.219,3.2686533,1198.190592,0.894976,0.0,8.455,7.076,3.8927738,1158.20544,0.0,0.0,9.082,8.286,4.3803904,1099.030528,0.0,0.0,10.484,8.419
1442616352000,9.728118,1377.824768,2.912256,0.0,70.502,64.996,3.3836671,1179.471872,0.004096,0.0,9.482,8.239,2.905126,1196.027904,1.787904,0.01024,7.702,6.614,2.9930816,1156.5056,0.0,0.0,9.492,8.901,3.4721005,1096.851456,0.0,0.0,10.841,8.224
1442616412000,10.8517435,1378.5088,2.961408,0.0,69.699,65.185,3.8615575,1183.698944,0.0,0.0,8.145,7.048,3.7921737,1196.695552,1.138688,0.014336,7.701,6.612,3.8572642,1159.122944,0.0,0.0,10.686,8.475,4.3645284,1099.206656,0.0,0.0,11.023,9.854
1442616472000,11.197683,1384.030208,1.634304,0.0,69.86,65.164,3.9991952,1183.891456,0.004096,0.0,10.304,8.969,3.5090363,1197.62944,1.28,0.01024,9.237,7.543,4.7983341,1157.029888,0.0,0.0,10.452,9.139,3.8732681,1096.818688,0.0,0.0,8.8,7.252
1442616532000,9.6084441,1384.554496,2.699264,0.0,67.511,63.033,3.8674206,1184.09216,0.004096,0.0,9.386,8.629,2.4670902,1198.415872,1.312768,0.014336,6.809,6.24,3.8389235,1156.800512,0.0,0.0,9.314,7.513,3.6461856,1100.47232,0.0,0.0,9.914,8.746
1442616592000,10.3147214,1381.101568,2.940928,0.0,69.826,65.779,4.6546607,1187.864576,0.004096,0.0,11.008,10.066,2.8481386,1200.758784,1.409024,0.01024,7.938,6.736,3.3444338,1162.665984,0.0,0.0,7.841,6.993,4.5450923,1103.212544,0.008192,0.0,11.593,9.993
1442616652000,10.4326768,1382.641664,3.05152,0.0,61.034,56.77,3.1700256,1186.824192,0.0,0.0,7.417,6.48,2.9701447,1200.267264,1.513472,0.01024,7.045,6.103,4.3671453,1163.624448,0.0,0.0,8.098,7.633,3.7148167,1100.04224,0.0,0.0,10.539,9.478
1442616713000,11.1011176,1384.624128,3.29728,0.0,69.41,65.124,3.5873658,1183.98976,0.0,0.0,9.802,8.42,3.9348155,1199.263744,1.912832,0.012288,7.995,7.276,4.2097636,1161.322496,0.0,0.0,11.505,8.833,4.0424827,1101.709312,0.0,0.0,9.653,7.707
1442616774000,8.8924275,1380.929536,2.760704,0.0,53.449,50.422,3.0081031,1166.696448,0.004096,0.0,6.059,6.089,3.214923,1194.156032,1.439744,0.0,7.385,6.346,2.9333671,1151.81568,0.0,0.0,7.922,7.772,3.0161575,1095.094272,0.0,0.0,8.024,7.244
1442616834000,10.1004874,1382.477824,3.309568,0.0,68.762,63.601,3.8292958,1168.09728,0.008192,0.0,7.823,7.163,3.4493134,1194.266624,1.056768,0.0,6.216,5.34,3.9605883,1150.369792,0.032768,0.0,11.105,9.965,3.5571708,1098.100736,0.0,0.0,10.731,9.239
1442616894000,8.2173858,1382.068224,2.60096,0.0,67.282,63.253,3.0402254,1172.389888,0.0,0.0,9.466,8.635,2.5711925,1194.635264,2.066432,0.0,7.123,6.932,2.5212184,1153.519616,0.0,0.0,10.108,9.352,2.932222,1097.228288,0.0,0.0,9.487,8.143
1442616954000,10.152114,1381.474304,3.2768,0.0,69.402,64.085,3.2071288,1171.279872,0.0,0.0,7.964,7.84,3.4200013,1194.401792,1.62816,0.0,8.5,7.149,3.9892065,1153.76128,0.0,0.0,8.552,6.667,3.8059218,1103.335424,0.0,0.0,10.385,10.264
1442617014000,10.8249549,1383.333888,2.916352,0.0,72.484,68.175,4.2451699,1172.320256,0.0,0.0,10.421,8.652,4.3847418,1194.860544,2.035712,0.0,7.884,6.91,3.7697068,1156.431872,0.0,0.0,10.358,8.841,3.9467651,1101.606912,0.0,0.0,10.56,8.425
1442617074000,7.9275581,1384.67328,2.920448,0.0,49.392,46.426,2.9434501,1175.06048,0.0,0.0,7.081,6.183,2.6828485,1196.343296,1.536,0.0,6.699,6.088,3.1119519,1160.43776,0.0,0.0,6.741,6.52,2.7831184,1102.200832,0.0,0.0,6.847,5.593
1442617134000,8.9829107,1381.49888,3.2768,0.0,66.84,63.069,3.3427938,1177.174016,0.0,0.0,8.193,6.33,1.6442153,1197.375488,1.64864,0.0,6.819,6.149,4.5329228,1159.901184,0.0,0.0,10.344,9.156,3.5306167,1102.876672,0.0,0.0,12.097,11.236
1442617194000,10.0531578,1384.189952,3.70688,0.0,72.578,67.502,3.7822852,1180.676096,0.0,0.0,9.504,8.49,4.2058107,1198.555136,1.716224,0.0,8.879,6.904,3.671282,1159.340032,0.0,0.0,8.541,6.885,3.5064736,1098.73152,0.0,0.0,11.85,9.685
1442617254000,8.0651774,1382.465536,4.554752,0.0,61.592,57.283,2.5416101,1182.142464,0.012288,0.0,8.609,7.634,3.1772411,1200.480256,1.366016,0.0,7.661,6.273,2.9944549,1159.360512,0.0,0.0,8.52,6.908,3.0400464,1097.781248,0.0,0.0,7.836,6.423
1442617314000,10.8030851,1385.365504,2.705408,0.0,73.306,68.502,4.8324681,1187.749888,0.008192,0.0,9.29,8.333,3.1859992,1198.604288,1.82272,0.0,8.364,7.271,3.9136698,1159.622656,0.0,0.0,11.311,9.387,4.1331976,1097.220096,0.0,0.0,11.068,8.953
1442617374000,11.0055326,1380.80256,3.620864,0.0,70.93,66.575,3.4615083,1164.734464,0.0,0.0,7.76,6.399,4.1042163,1197.453312,1.427456,0.0,9.235,7.717,4.6114308,1156.800512,0.0,0.0,11.311,9.558,3.6447494,1094.483968,0.0,0.0,10.632,9.623
1442617434000,7.7527238,1379.782656,3.90144,0.0,58.673,55.358,2.1901065,1170.448384,0.0,0.0,6.931,5.875,2.6054133,1201.758208,1.581056,0.0,6.759,5.912,3.002693,1152.438272,0.0,0.0,7.393,6.88,2.6765611,1099.702272,0.0,0.0,10.825,9.518
1442617494000,10.2657496,1378.193408,4.071424,0.0,62.836,57.711,3.4252314,1172.381696,0.0,0.0,7.39,7.056,3.2315407,1204.342784,1.398784,0.0,6.219,6.09,3.6801051,1152.700416,0.0,0.0,9.804,7.89,3.7834295,1095.92576,0.0,0.0,8.298,7.755
1442617555000,8.3982212,1379.667968,2.584576,0.0,66.276,62.302,3.2696694,1175.252992,0.0,0.0,8.998,7.852,4.0720564,1204.867072,2.285568,0.0,8.675,8.096,2.6305012,1155.014656,0.0,0.0,8.886,7.457,2.7451147,1096.25344,0.0,0.0,9.666,7.814
1442617615000,9.8199119,1379.995648,2.146304,0.0,65.132,61.371,2.9220632,1173.082112,0.0,0.0,10.388,8.078,4.0153687,1201.856512,1.533952,0.0,6.481,5.795,3.8347087,1155.207168,0.032768,0.0,8.739,7.372,3.7797543,1096.032256,0.0,0.0,10.686,9.689
1442617675000,10.894197,1382.875136,3.584,0.0,58.096,54.634,4.1818186,1176.137728,0.0,0.0,8.034,7.604,3.4320113,1203.146752,1.001472,0.0,5.217,4.844,4.3588116,1154.023424,0.0,0.0,8.581,7.365,4.066867,1097.592832,0.0,0.0,8.964,8.088
1442617735000,9.4459637,1386.532864,2.224128,0.0,58.145,54.076,3.8509874,1181.642752,0.0,0.0,8.737,8.793,3.2887025,1200.795648,1.513472,0.0,5.936,5.77,2.8829998,1153.994752,0.0,0.0,7.926,7.105,3.4059835,1100.742656,0.0,0.0,8.681,7.639
1442617795000,9.3144639,1388.412928,3.91168,0.0,59.537,55.838,4.078236,1180.368896,0.0,0.0,8.741,7.807,3.0369872,1202.077696,1.488896,0.0,7.077,6.194,3.3926926,1156.030464,0.0,0.0,9.085,7.442,3.3148385,1100.263424,0.0,0.0,7.12,6.685
1442617855000,9.2835314,1387.450368,2.428928,0.0,73.009,68.355,2.682433,1183.789056,0.0,0.0,8.351,6.666,4.0157858,1204.137984,1.540096,0.0,8.626,7.717,3.3008208,1158.5536,0.0,0.0,11.0,9.003,3.9250819,1101.258752,0.0,0.0,10.963,9.155
1442617915000,10.9386284,1390.743552,3.969024,0.0,62.956,59.383,4.4956621,1185.746944,0.032768,0.0,9.173,8.694,3.3741094,1204.539392,0.927744,0.0,7.477,5.817,4.1571923,1160.708096,0.0,0.0,9.185,7.99,3.4413915,1101.176832,0.0,0.0,8.444,7.319
1442617976000,10.3446546,1381.613568,3.117056,0.0,70.937,66.176,4.033284,1164.132352,0.0,0.0,9.19,7.976,2.9941589,1198.32576,1.337344,0.0,8.673,6.943,3.822208,1152.376832,0.0,0.0,9.389,8.032,4.1142621,1095.5776,0.0,0.0,11.293,9.418
1442618037000,10.5966207,1384.22272,3.383296,0.0,69.015,64.609,4.0411566,1166.393344,0.0,0.0,9.838,7.688,2.9380111,1201.897472,1.62816,0.0,7.211,5.746,4.2604636,1154.605056,0.0,0.0,10.231,8.342,4.2197739,1097.781248,0.0,0.0,10.693,8.887
1442618097000,10.5736244,1381.822464,3.149824,0.0,65.18,60.636,3.9803225,1172.545536,0.0,0.0,8.081,7.191,3.2475145,1198.956544,2.002944,0.0,6.235,5.91,4.0205446,1150.976,0.0,0.0,9.868,8.526,4.5242063,1094.729728,0.0,0.0,10.418,9.522
1442618157000,9.4416673,1386.82368,4.534272,0.0,51.757,48.817,3.7523727,1172.525056,0.0,0.0,5.664,5.089,4.211261,1201.553408,1.452032,0.0,8.343,7.599,4.1281149,1161.375744,0.0,0.0,8.42,7.008,3.3936648,1097.580544,0.0,0.0,7.362,6.254
1442618217000,12.256151,1380.995072,2.72384,0.0,80.001,74.818,4.5035479,1171.890176,0.0,0.0,9.453,7.28,4.8006823,1203.699712,2.121728,0.0,11.518,9.498,4.8505512,1160.015872,0.0,0.0,10.991,9.772,4.8481588,1096.880128,0.0,0.0,10.849,9.375
1442618277000,8.9289856,1379.995648,2.725888,0.0,53.174,49.55,3.486178,1176.4736,0.0,0.0,6.383,5.377,2.3362282,1201.467392,1.200128,0.0,5.774,5.223,3.3148671,1157.558272,0.0,0.0,9.205,8.46,3.2704821,1099.218944,0.0,0.0,7.526,7.224
1442618338000,10.1424942,1378.95936,2.834432,0.012288,62.461,57.898,4.0522158,1171.378176,0.008192,0.0,7.939,6.22,3.2682932,1214.517248,2.144256,0.0,7.85,6.982,3.7617648,1159.274496,0.0,0.0,7.292,6.671,3.7320695,1098.981376,0.0,0.0,10.707,9.412
1442618399000,6.4230784,1381.093376,2.88768,0.016384,46.917,43.737,2.74333,1178.97216,0.0,0.0,6.031,5.448,2.338725,1213.411328,1.886208,0.0,5.128,4.95,2.2493319,1164.300288,0.0,0.0,8.269,7.945,2.7131187,1099.354112,0.0,0.0,5.93,5.588
1442618459000,5.7653159,1377.923072,3.608576,0.02048,52.898,50.197,2.0640862,1180.114944,0.0,0.0,6.956,6.054,2.4008575,1211.318272,1.335296,0.0,6.616,6.228,1.7702752,1164.161024,0.0,0.0,7.056,5.847,9.8488964,1101.934592,0.548864,0.0,8.279,7.31
1442618519000,8.4408334,1376.698368,3.284992,0.02048,64.574,60.869,4.074983,1173.602304,0.0,0.0,10.96,8.76,2.8514777,1212.90752,1.384448,0.0,7.341,6.033,3.0785874,1163.763712,0.0,0.0,9.488,7.929,3.6042044,1109.028864,0.032768,0.0,8.208,7.298
1442618579000,10.6271622,1373.691904,2.70336,0.0,61.684,57.867,4.4985974,1156.067328,0.0,0.0,8.744,7.814,3.4299853,1208.078336,1.284096,0.0,8.718,6.504,3.0074156,1152.745472,0.0,0.0,7.97,6.544,4.1839046,1104.36352,0.008192,0.0,9.433,8.287
1442618639000,10.0669747,1376.079872,3.608576,0.0,56.97,53.125,3.9824553,1156.714496,0.004096,0.0,7.067,6.5,3.4140311,1207.72608,1.452032,0.0,6.906,6.279,3.8200555,1156.870144,0.0,0.0,7.516,5.864,3.6364721,1104.13824,0.0,0.0,9.057,8.33
1442618699000,10.9656595,1375.58016,1.703936,0.0,63.903,59.278,3.6823826,1163.50976,0.0,0.0,8.047,7.359,3.296033,1209.921536,1.071104,0.0,6.662,5.758,4.5668736,1156.05504,0.0,0.0,11.069,10.768,3.3532054,1103.069184,0.0,0.0,7.488,7.023
1442618759000,8.0480297,1376.78848,2.4576,0.0,51.218,48.423,3.3146262,1165.205504,0.008192,0.0,6.3,5.581,2.2740312,1211.564032,1.163264,0.0,6.957,5.888,2.4692415,1157.81632,0.0,0.0,7.319,6.532,3.2707451,1106.087936,0.0,0.0,8.683,7.311
1442618819000,9.79967,1380.458496,3.416064,0.0,67.308,63.716,4.2847377,1163.669504,0.0,0.0,8.96,7.938,3.006733,1211.244544,1.343488,0.0,7.127,6.564,4.1402721,1160.286208,0.0,0.0,9.825,8.845,4.4376333,1105.424384,0.0,0.0,10.786,9.229
1442618879000,8.390777,1379.708928,4.079616,0.0,57.832,54.488,3.027589,1164.455936,0.032768,0.0,6.601,6.421,2.8099798,1211.817984,1.499136,0.0,7.629,7.056,2.7897214,1159.548928,0.0,0.0,8.331,6.938,2.9485734,1105.522688,0.0,0.0,7.954,7.001
1442618939000,9.1780234,1376.6656,3.047424,0.0,71.832,66.616,3.3118601,1175.048192,0.0,0.0,7.945,6.774,4.3989331,1210.228736,1.32096,0.0,8.952,7.351,3.360155,1159.41376,0.0,0.0,10.612,9.24,3.5939225,1105.219584,0.004096,0.0,10.87,9.38
1442618999000,8.151853,1377.456128,2.134016,0.0,67.902,63.412,3.141412,1174.429696,0.0,0.0,7.987,6.974,3.8168842,1211.912192,2.004992,0.0,9.779,8.646,3.038923,1169.22368,0.0,0.0,11.386,9.823,2.8845759,1106.935808,0.0,0.0,8.516,6.675
1442619059000,8.521701,1376.452608,3.584,0.0,61.007,57.679,3.7502623,1175.973888,0.0,0.0,9.549,7.983,2.6359297,1209.63072,1.216512,0.0,7.158,5.955,2.4700146,1168.338944,0.0,0.0,7.368,6.968,3.414349,1107.468288,0.024576,0.0,9.861,8.084
1442619119000,7.0632047,1376.550912,3.309568,0.0,56.138,53.176,1.9012825,1176.30976,0.0,0.0,7.829,7.701,2.6815368,1212.796928,1.918976,0.0,6.616,5.589,2.3121276,1168.232448,0.0,0.0,8.826,7.56,2.7203782,1106.825216,0.0,0.0,8.655,7.006
1442619179000,9.474589,1374.048256,3.702784,0.0,67.994,62.737,3.1944741,1146.699776,0.0,0.0,8.875,7.26,2.9809799,1210.175488,1.667072,0.0,7.426,6.03,3.9949744,1157.742592,0.0,0.0,9.751,8.666,3.1328306,1105.113088,0.0,0.0,9.928,7.59
1442619239000,10.0092594,1370.054656,2.568192,0.0,57.311,54.456,3.4505079,1155.702784,0.028672,0.0,6.918,6.744,2.9068424,1207.341056,1.107968,0.0,6.736,5.62,4.1550938,1154.10944,0.032768,0.0,9.051,8.329,3.4951183,1098.97728,0.0,0.0,8.393,7.298
1442619299000,9.78452,1373.704192,2.797568,0.0,71.976,67.384,3.395291,1152.991232,0.0,0.0,9.206,7.97,3.1881947,1208.528896,1.626112,0.0,8.63,7.318,3.8606556,1157.484544,0.0,0.0,9.015,8.525,4.2874959,1107.795968,0.0,0.0,13.22,10.896
1442619359000,9.8240696,1371.406336,2.72384,0.0,56.603,53.467,3.3131266,1151.184896,0.0,0.0,6.925,6.316,3.4115207,1207.738368,1.990656,0.0,7.391,7.016,4.5044858,1157.71392,0.0,0.0,9.058,8.547,3.1199922,1103.982592,0.0,0.0,7.965,7.635
1442619419000,9.3107315,1371.779072,4.112384,0.0,58.245,54.106,3.9608549,1152.557056,0.0,0.0,8.476,7.068,2.0857987,1208.193024,0.68608,0.0,6.286,4.647,2.8643195,1154.875392,0.008192,0.0,7.326,6.585,3.3854565,1104.121856,0.0,0.0,9.125,8.924
1442619480000,11.0287742,1373.458432,2.195456,0.0,76.035,70.773,4.7517177,1154.220032,0.008192,0.0,10.998,9.898,4.4204712,1211.490304,1.429504,0.0,11.364,9.195,3.7126454,1156.632576,0.0,0.0,9.004,7.018,4.407372,1107.714048,0.0,0.0,10.212,8.333
1442619540000,9.2668657,1374.74048,4.714496,0.0,60.3,55.987,4.3034729,1155.817472,0.008192,0.0,9.405,8.856,3.4670181,1210.351616,1.181696,0.0,7.879,5.809,3.4188297,1159.02464,0.0,0.0,7.843,6.811,3.2847953,1106.808832,0.0,0.0,7.342,6.415
1442619600000,9.0454623,1374.093312,2.625536,0.0,63.819,60.304,3.5057001,1155.85024,0.032768,0.0,9.838,8.53,3.2909361,1210.519552,1.282048,0.0,7.053,6.047,2.8166376,1161.760768,0.0,0.0,8.984,7.653,3.3116408,1108.385792,0.0,0.0,10.68,8.801
1442619660000,10.0303454,1374.920704,3.981312,0.0,62.169,58.181,4.3594045,1162.5472,0.0,0.0,9.138,7.434,3.0296936,1215.438848,1.859584,0.0,7.558,7.086,3.9345031,1165.930496,0.0,0.0,9.836,8.182,3.5583559,1107.206144,0.0,0.0,6.97,6.092
1442619720000,10.7275475,1371.87328,2.523136,0.0,59.574,56.206,4.004169,1169.657856,0.0,0.0,8.824,7.869,3.6287755,1214.91456,1.685504,0.0,9.108,7.819,3.3619097,1163.415552,0.0,0.0,7.498,6.529,3.6914998,1106.071552,0.0,0.0,7.934,6.988
1442619780000,9.07638,1368.088576,2.576384,0.0,52.969,49.099,2.8499665,1142.427648,0.0,0.0,5.514,5.204,2.4080621,1208.553472,1.153024,0.0,6.063,5.723,3.3487535,1157.894144,0.032768,0.0,6.586,6.317,3.7763715,1103.106048,0.0,0.0,9.528,8.335
1442619840000,9.6138857,1365.62688,3.035136,0.0,63.899,60.06,3.0974288,1144.09472,0.0,0.0,7.73,6.967,3.7972922,1207.119872,1.714176,0.0,8.176,7.779,3.9250903,1161.990144,0.0,0.0,9.307,8.023,2.9335345,1102.532608,0.0,0.0,9.165,8.723
1442619900000,9.713559,1370.247168,3.313664,0.0,71.905,67.231,3.7437127,1149.947904,0.032768,0.0,8.251,7.224,3.4785031,1211.949056,1.343488,0.0,9.15,7.773,3.4135351,1159.872512,0.024576,0.0,10.745,8.939,3.4479219,1105.252352,0.0,0.0,10.356,9.362
1442619961000,8.7045138,1367.49056,1.626112,0.0,60.606,56.001,3.497559,1148.383232,0.08192,0.0,8.495,8.183,3.3722756,1209.860096,1.032192,0.0,6.876,5.625,2.4627503,1161.179136,0.0,0.0,8.388,7.748,6.1957206,1103.609856,0.032768,0.0,8.654,7.697
1442620021000,6.3741393,1371.099136,2.8672,0.0,56.576,53.43,2.5266127,1145.597952,0.0,0.0,8.596,7.692,16.3982058,1214.193664,3.313664,0.0,7.534,6.179,2.9237468,1161.248768,0.0,0.0,7.964,6.654,2.277577,1119.383552,0.0,0.0,7.88,6.176
1442620081000,10.6921609,1370.89024,2.08896,0.0,66.65,62.27,3.5418421,1149.186048,0.0,0.0,9.625,7.813,3.294714,1212.788736,1.52576,0.0,6.216,5.916,5.090155,1163.10016,0.016384,0.0,11.823,9.519,4.3794408,1118.9248,0.0,0.0,9.547,7.971
1442620141000,8.3160244,1368.674304,5.193728,0.0,58.13,55.199,1.9990833,1154.752512,0.0,0.0,6.641,5.767,2.8850165,1213.70624,1.605632,0.0,9.398,7.723,3.7688598,1166.454784,0.0,0.0,8.011,7.211,2.4834989,1117.208576,0.0,0.0,7.452,7.212
1442620201000,9.7301319,1369.931776,3.551232,0.0,60.895,57.084,3.209262,1152.86016,0.0,0.0,8.573,7.617,3.7451129,1217.953792,1.718272,0.0,8.149,6.929,3.4017204,1168.7936,0.0,0.0,9.751,8.5,2.4383922,1117.257728,0.0,0.0,6.786,6.08
1442620261000,10.6764019,1368.195072,2.49856,0.0,75.119,70.272,3.0936442,1153.560576,0.0,0.0,9.06,8.047,4.24659,1216.75776,1.867776,0.0,11.613,9.152,4.562277,1169.870848,0.0,0.0,10.746,9.114,3.54606,1119.531008,0.0,0.0,10.135,8.78
1442620322000,9.2546226,1368.551424,4.452352,0.0,55.25,52.318,3.5059144,1153.732608,0.0,0.0,7.788,7.081,3.1212176,1216.331776,1.366016,0.0,5.394,5.039,3.1508115,1169.98144,0.0,0.0,8.103,7.186,3.7551013,1118.470144,0.0,0.0,8.423,7.26
1442620382000,6.9359136,1355.8784,2.039808,0.0,63.522,58.808,3.4898083,1117.052928,0.0,0.0,8.708,7.23,3.0515718,1203.2,1.441792,0.0,7.408,6.579,2.4862421,1145.810944,0.0,0.0,8.962,7.865,2.2803385,1106.214912,0.0,0.0,9.607,7.988
1442620443000,11.6415027,1362.599936,2.666496,0.0,75.218,71.048,5.0214737,1130.35264,0.0,0.0,10.582,9.835,4.6037775,1210.167296,1.615872,0.01024,8.351,7.151,4.332591,1153.622016,0.0,0.0,8.487,7.471,3.9143511,1114.955776,0.0,0.0,13.299,10.213
1442620503000,9.7256017,1362.812928,2.379776,0.0,59.669,55.822,4.0686781,1137.8688,0.065536,0.0,9.469,9.236,2.9781248,1211.232256,1.445888,0.006144,6.86,6.263,3.7893095,1156.05504,0.0,0.0,9.46,8.081,3.2648332,1112.686592,0.0,0.0,7.089,6.002
1442620563000,10.3628164,1364.353024,3.100672,0.0,56.961,52.98,3.9238788,1136.431104,0.0,0.0,7.149,6.543,4.0571885,1211.82208,1.8944,0.01024,7.246,6.768,3.6636498,1166.516224,0.0,0.0,7.199,6.456,3.689779,1113.767936,0.065536,0.0,9.657,8.195
1442620623000,8.8513154,1370.914816,3.915776,0.0,62.55,58.007,3.6927747,1141.772288,0.016384,0.0,8.178,7.56,3.2778495,1210.61376,0.878592,0.01024,6.571,5.475,4.0732902,1170.259968,0.004096,0.0,9.173,7.346,3.2991079,1112.518656,0.0,0.0,9.643,8.668
1442620683000,10.3917571,1369.812992,2.363392,0.0,73.747,69.074,3.3737935,1136.226304,0.0,0.0,8.878,6.947,4.120687,1212.383232,1.890304,0.01024,9.604,8.834,4.0464516,1169.420288,0.0,0.0,9.589,8.556,3.8457707,1113.25184,0.0,0.0,11.93,10.74
1442620744000,11.4939519,1370.2144,3.674112,0.0,71.757,66.509,4.4002766,1138.233344,0.0,0.0,9.01,8.135,2.5176375,1212.3136,1.304576,0.006144,6.94,5.908,4.6485184,1169.38752,0.0,0.0,10.529,9.899,4.2518736,1116.164096,0.0,0.0,11.081,9.578
1442620804000,12.083911,1373.650944,2.891776,0.0,73.169,68.473,4.5293546,1141.407744,0.0,0.0,8.582,8.644,4.1259235,1213.68576,1.165312,0.008192,8.339,7.03,4.3340468,1172.762624,0.0,0.0,9.543,9.222,4.7180587,1118.67904,0.0,0.0,13.217,9.969
1442620864000,10.5670277,1372.786688,4.571136,0.0,63.494,59.486,4.5518068,1144.2176,0.032768,0.0,9.469,8.158,3.4692113,1213.038592,1.069056,0.012288,6.656,5.678,4.2880808,1171.873792,0.0,0.0,8.612,7.87,4.6824621,1126.187008,0.0,0.0,9.519,8.538
1442620924000,2.8016026,1368.485888,1.216512,0.0,20.967,19.305,0.9507586,1147.027456,0.0,0.0,2.901,2.746,8.6550633,1216.47104,1.275904,0.018432,3.5,3.943,0.9726626,1173.495808,0.0,0.0,4.639,4.209,1.0146395,1127.911424,0.0,0.0,2.262,2.21
1442620984000,8.4753121,1372.983296,2.850816,0.0,51.171,48.097,3.7196214,1140.117504,0.032768,0.0,7.439,6.144,2.7904968,1216.036864,1.601536,0.006144,6.95,6.164,3.4309767,1172.615168,0.0,0.0,6.503,6.017,3.2647323,1128.423424,0.0,0.0,7.579,7.053
1442621044000,9.8306443,1366.007808,2.658304,0.0,58.099,54.329,3.4530128,1125.10976,0.049152,0.0,7.221,5.681,3.9200731,1207.627776,2.51904,0.014336,8.459,8.076,3.3778747,1150.836736,0.032768,0.0,8.929,7.357,3.5952437,1121.968128,0.0,0.0,6.078,6.201
1442621104000,9.6651457,1367.87968,2.359296,0.0,63.155,58.718,3.4865446,1130.930176,0.028672,0.0,7.519,5.959,4.1349674,1205.948416,2.009088,0.012288,9.354,8.011,3.1830967,1156.349952,0.0,0.0,8.494,8.136,3.6726292,1121.366016,0.0,0.0,9.269,8.153
1442621165000,10.4092729,1368.748032,3.024896,0.0,59.64,55.126,3.7917423,1127.759872,0.0,0.0,7.061,6.582,3.6913642,1204.191232,1.245184,0.0,7.971,6.178,3.7820289,1158.078464,0.0,0.0,9.453,7.696,3.6304029,1121.357824,0.0,0.0,7.86,7.196
1442621226000,7.3143256,1368.04352,3.239936,0.004096,56.576,52.573,3.2091282,1130.418176,0.004096,0.0,6.606,6.706,1.8763993,1207.86944,0.741376,0.0,5.379,4.359,2.6706581,1158.316032,0.0,0.0,9.049,8.146,2.8001132,1124.876288,0.0,0.0,9.339,7.195
1442621286000,11.276438,1371.901952,3.964928,0.024576,70.037,66.21,4.6846575,1132.613632,0.0,0.0,9.106,7.873,3.8937632,1211.35104,1.685504,0.0,7.894,6.155,4.3680732,1158.90176,0.0,0.0,10.344,8.504,4.3845336,1127.780352,0.0,0.0,10.532,8.33
1442621346000,9.5471448,1368.940544,3.469312,0.016384,66.904,63.383,3.6981281,1136.553984,0.0,0.0,10.958,8.751,3.4350196,1210.667008,1.626112,0.0,7.665,6.424,2.9446788,1157.771264,0.0,0.0,8.656,6.259,3.4262367,1126.83008,0.0,0.0,10.35,7.767
1442621406000,7.890411,1369.6,1.785856,0.012288,63.329,58.959,2.555195,1140.146176,0.0,0.0,8.74,7.785,3.2092222,1211.150336,2.123776,0.0,9.395,8.401,2.6418746,1167.732736,0.0,0.0,8.733,7.438,2.4581711,1123.667968,0.0,0.0,8.137,6.669
1442621466000,8.8302705,1369.694208,2.306048,0.012288,54.68,51.283,3.4361741,1133.948928,0.0,0.0,7.935,6.078,3.0233525,1209.585664,1.855488,0.0,6.715,6.597,3.1168537,1171.554304,0.0,0.0,7.117,6.817,3.2421216,1124.888576,0.0,0.0,8.086,7.553
1442621526000,8.2850259,1368.875008,1.746944,0.008192,51.269,47.458,2.5348753,1132.007424,0.0,0.0,7.431,6.635,2.277012,1210.843136,1.124352,0.0,6.189,5.701,3.5834946,1172.566016,0.0,0.0,7.069,6.852,2.9498904,1124.552704,0.0,0.0,7.464,6.786
1442621586000,8.1173448,1372.348416,4.057088,0.016384,55.769,52.028,2.9648125,1133.79328,0.032768,0.0,8.559,7.273,3.0345688,1212.35456,1.148928,0.0,6.634,5.639,2.8763861,1169.28512,0.0,0.0,8.508,6.973,3.091052,1131.687936,0.0,0.0,7.343,6.441
1442621646000,7.6841509,1358.159872,1.130496,0.024576,53.339,49.735,2.90681,1116.471296,0.032768,0.0,7.641,6.425,2.7863231,1210.068992,1.957888,0.0,6.361,6.132,2.6856391,1157.767168,0.0,0.0,7.503,5.587,2.6119374,1130.082304,0.032768,0.0,8.624,8.062
1442621707000,8.9170834,1358.147584,3.35872,0.024576,56.531,52.953,2.8820219,1120.923648,0.0,0.0,8.282,6.67,2.6176949,1209.647104,1.98656,0.0,5.456,5.331,3.6881439,1164.464128,0.0,0.0,7.794,7.013,2.8328381,1129.705472,0.0,0.0,9.598,7.824
1442621767000,10.5318744,1357.586432,3.055616,0.028672,56.992,53.16,2.8497356,1120.21504,0.0,0.0,5.672,5.428,3.744925,1213.186048,1.466368,0.0,6.635,5.892,3.5523626,1164.04224,0.0,0.0,7.411,7.135,4.6550663,1131.008,0.032768,0.0,9.778,9.651
1442621827000,11.1913532,1357.778944,2.828288,0.02048,60.465,55.508,3.8664835,1120.632832,0.0,0.0,7.145,6.659,3.4250078,1214.746624,1.482752,0.0,7.3,6.137,3.0006476,1162.108928,0.0,0.0,6.31,5.987,3.951968,1130.471424,0.0,0.0,10.559,9.655
1442621887000,13.2043913,1359.95392,2.584576,0.02048,62.092,57.87,3.0259295,1128.370176,0.0,0.0,9.151,7.169,3.835636,1212.891136,1.671168,0.0,7.233,6.363,3.9757875,1163.177984,0.0,0.0,9.133,7.979,3.8936944,1129.566208,0.004096,0.0,9.14,8.056
1442621947000,9.4833185,1359.19616,3.817472,0.02048,56.034,52.516,3.246827,1130.57792,0.004096,0.0,6.661,6.174,3.6616052,1212.297216,1.0752,0.0,6.618,5.615,4.29366,1165.090816,0.0,0.0,9.095,8.187,3.6798438,1130.586112,0.0,0.0,7.728,6.862
1442622007000,10.1382406,1360.416768,2.916352,0.008192,60.107,56.692,4.1653189,1132.199936,0.0,0.0,9.687,8.359,4.192194,1213.52192,2.199552,0.0,8.99,7.795,3.6446678,1165.766656,0.0,0.0,6.908,4.813,2.5088215,1131.88864,0.0,0.0,8.422,6.32
1442622068000,10.1434692,1363.607552,3.160064,0.0,74.663,69.381,3.8626342,1137.819648,0.032768,0.0,11.039,8.839,3.0669095,1214.410752,1.388544,0.0,8.419,6.844,3.5994182,1167.91296,0.0,0.0,9.258,8.568,4.0359015,1130.483712,0.0,0.0,11.213,9.682
1442622129000,10.3820681,1360.416768,1.605632,0.0,83.571,78.484,4.6830102,1144.406016,0.0,0.0,13.356,10.817,4.556007,1215.54944,1.8944,0.0,11.865,9.391,3.0813851,1169.010688,0.0,0.0,10.531,8.298,4.4876249,1133.248512,0.0,0.0,11.445,9.452
1442622189000,8.352385,1361.600512,2.392064,0.0,64.714,60.093,3.2484395,1145.094144,0.065536,0.0,8.894,7.439,3.1220226,1215.782912,1.564672,0.0,7.827,6.727,2.162291,1176.076288,0.0,0.0,8.489,7.705,3.3501122,1132.449792,0.0,0.0,9.568,8.146
1442622249000,9.7991382,1352.76544,3.100672,0.0,72.418,67.962,3.8126968,1122.701312,0.0,0.0,8.099,7.022,3.2026141,1210.433536,0.606208,0.0,9.557,6.131,3.631328,1165.017088,0.0,0.0,9.983,9.171,3.9783381,1127.58784,0.004096,0.0,11.831,10.525
1442622309000,8.9126154,1353.355264,2.883584,0.0,58.173,53.775,4.009917,1127.3216,0.02048,0.0,8.327,7.22,2.787992,1212.153856,1.66912,0.0,6.339,6.16,3.3460376,1164.857344,0.0,0.0,7.454,7.053,2.76019,1125.363712,0.0,0.0,7.515,6.063
1442622369000,8.9701629,1354.428416,2.076672,0.0,63.128,58.968,3.6601847,1128.312832,0.0,0.0,6.308,5.932,3.6204823,1208.758272,1.25952,0.0,8.7,7.187,3.1696096,1171.869696,0.004096,0.0,10.662,8.957,3.1293608,1128.218624,0.0,0.0,9.045,8.619
1442622429000,9.0521113,1358.905344,3.362816,0.0,64.802,61.033,4.2826319,1130.41408,0.004096,0.0,9.221,7.635,3.4448129,1211.592704,1.46432,0.0,8.65,7.268,2.9395735,1170.968576,0.0,0.0,8.665,8.086,3.1527184,1135.828992,0.0,0.0,8.786,7.069
1442622489000,10.1019542,1357.37344,2.715648,0.0,58.154,55.006,3.2441557,1128.108032,0.004096,0.0,6.805,6.891,3.7074828,1214.95552,1.65888,0.0,8.786,7.198,3.3881839,1169.813504,0.0,0.0,8.05,7.611,3.0505532,1137.516544,0.0,0.0,9.335,7.829
1442622549000,9.2223733,1359.396864,2.043904,0.0,62.27,58.2,3.6343947,1131.229184,0.032768,0.0,8.352,7.931,3.2029551,1215.410176,1.386496,0.0,7.78,5.947,3.4159987,1174.65088,0.004096,0.0,8.713,7.682,3.5376792,1138.950144,0.0,0.0,9.098,7.071
1442622609000,10.3400971,1361.473536,2.510848,0.0,62.134,57.448,3.375326,1131.307008,0.0,0.0,7.214,5.881,3.5316407,1215.0784,1.20832,0.0,7.407,6.486,4.2651083,1175.113728,0.0,0.0,9.529,8.813,3.8430885,1140.445184,0.004096,0.0,8.547,8.114
1442622669000,10.9348207,1364.918272,2.715648,0.0,64.214,59.522,3.7849888,1132.699648,0.0,0.0,7.797,6.761,3.6491803,1224.339456,1.449984,0.0,7.828,7.038,4.2789777,1180.966912,0.0,0.0,10.717,9.226,4.0852463,1147.748352,0.0,0.0,8.638,7.006
1442622729000,9.3920931,1364.291584,2.910208,0.0,55.557,52.216,3.3970538,1129.140224,0.0,0.0,8.238,7.539,2.8524335,1227.087872,1.4848,0.0,7.388,6.456,2.8438671,1179.99616,0.0,0.0,6.443,5.65,3.6360491,1146.63424,0.0,0.0,8.717,7.669
1442622789000,9.6518118,1373.261824,2.74432,0.0,37.197,34.92,2.6937818,1130.696704,0.0,0.0,3.03,3.154,3.3064118,1223.540736,0.856064,0.0,5.131,4.141,3.8469042,1178.292224,0.0,0.0,7.26,6.464,3.4149178,1147.170816,0.0,0.0,6.039,5.734
1442622849000,9.9377234,1359.785984,2.781184,0.0,64.441,60.39,4.5336367,1108.1728,0.036864,0.0,8.78,8.585,2.9681396,1218.953216,1.66912,0.0,7.833,6.789,3.8083506,1162.358784,0.0,0.0,8.708,7.809,3.6047919,1136.631808,0.0,0.0,9.951,8.387
1442622909000,9.4006831,1365.045248,3.313664,0.0,68.964,63.846,3.4639799,1114.591232,0.0,0.0,9.576,8.213,2.102351,1221.046272,0.985088,0.0,7.136,5.87,3.8810915,1167.62624,0.0,0.0,10.114,9.354,3.2925442,1140.69504,0.004096,0.0,10.032,8.075
1442622970000,9.5507104,1362.096128,3.026944,0.0,62.915,59.351,3.4688,1112.440832,0.0,0.0,7.535,6.752,3.9044882,1225.465856,1.714176,0.0,9.347,7.545,3.6069394,1166.790656,0.0,0.0,8.394,7.66,3.871268,1142.509568,0.036864,0.0,9.699,8.054
1442623030000,9.8774344,1362.526208,2.043904,0.0,64.999,59.793,4.3622926,1116.028928,0.0,0.0,8.736,7.707,3.1491567,1223.032832,1.605632,0.0,7.27,6.827,3.3228852,1171.755008,0.0,0.0,9.12,8.617,3.0173114,1139.05664,0.0,0.0,8.837,7.713
1442623090000,9.835048,1364.955136,2.543616,0.0,65.877,61.228,3.5156352,1121.54624,0.0,0.0,8.922,7.455,2.9313487,1222.250496,1.808384,0.0,6.092,6.038,4.2896179,1173.54496,0.0,0.0,11.451,11.012,2.0432185,1141.911552,0.0,0.0,8.373,7.338
1442623150000,10.5036795,1368.649728,3.547136,0.0,64.262,60.739,3.3379543,1123.479552,0.0,0.0,7.516,6.407,4.2939823,1227.96032,1.765376,0.0,9.414,8.054,4.1212167,1174.79424,0.0,0.0,9.403,8.355,3.1874755,1144.87296,0.0,0.0,8.86,8.305
1442623210000,10.4033685,1370.308608,3.473408,0.0,67.103,61.631,3.4776067,1124.421632,0.0,0.0,6.797,6.876,3.3296177,1228.787712,1.34144,0.0,10.141,8.687,3.9784695,1175.605248,0.0,0.0,8.746,7.615,4.0478716,1149.97248,0.0,0.0,8.394,8.364
1442623271000,8.977291,1369.194496,2.605056,0.0,53.444,50.262,3.5847258,1124.421632,0.0,0.0,8.594,7.781,2.9175816,1229.889536,1.026048,0.0,6.101,5.059,2.9247426,1178.492928,0.0,0.0,8.259,7.179,3.1866383,1150.898176,0.0,0.0,7.428,6.121
1442623331000,10.9810832,1369.194496,3.383296,0.0,61.853,58.366,3.2540388,1120.489472,0.0,0.0,7.526,7.11,5.1274556,1231.572992,1.370112,0.0,8.359,6.746,3.7714865,1179.365376,0.0,0.0,7.927,7.32,4.268801,1152.483328,0.0,0.0,8.551,7.735
1442623391000,8.8598066,1373.208576,2.12992,0.0,63.095,58.786,3.6272527,1121.288192,0.0,0.0,8.187,7.922,2.88997,1232.384,1.370112,0.0,7.975,6.595,2.9686623,1177.038848,0.004096,0.0,7.654,5.95,3.4402529,1152.770048,0.032768,0.0,10.976,9.165
1442623451000,7.5661387,1365.049344,2.904064,0.0,40.891,39.104,2.8293222,1103.904768,0.208896,0.0,5.455,4.554,2.2792556,1224.8064,0.866304,0.0,5.308,4.429,3.1085304,1168.87552,0.0,0.0,7.772,7.205,1.8109442,1136.840704,0.0,0.0,4.499,3.862
1442623511000,9.2661527,1365.221376,2.686976,0.0,49.733,46.845,3.9708061,1108.168704,0.077824,0.0,8.241,7.542,3.9711602,1226.059776,1.632256,0.0,6.505,6.154,2.612007,1168.50688,0.0,0.0,5.862,4.316,3.1203587,1140.584448,0.0,0.0,7.364,6.416
1442623571000,9.7761664,1365.536768,2.154496,0.0,66.06,61.458,3.6826105,1108.72576,0.0,0.0,8.095,7.449,3.2247793,1227.382784,1.59744,0.0,8.116,7.036,3.1913433,1167.54432,0.0,0.0,9.685,7.51,3.3691879,1144.336384,0.0,0.0,9.333,8.11
1442623631000,9.661289,1365.05344,1.916928,0.0,56.465,53.028,4.0029401,1110.91712,0.0,0.0,8.028,7.444,3.5438442,1223.958528,1.200128,0.0,6.246,5.416,3.9161624,1168.822272,0.0,0.0,8.36,7.365,3.7362964,1141.02272,0.0,0.0,8.898,8.567
1442623691000,11.5841461,1364.29568,3.694592,0.0,69.362,64.588,4.656398,1115.62752,0.0,0.0,8.625,7.705,3.4156027,1222.066176,1.316864,0.0,8.059,6.426,4.1684477,1171.283968,0.0,0.0,9.482,9.167,4.443197,1140.3264,0.0,0.0,9.926,8.574
1442623751000,10.0638771,1365.180416,1.323008,0.0,56.262,51.858,3.7440658,1120.305152,0.0,0.0,7.263,5.575,3.6061756,1225.859072,1.36192,0.0,6.331,5.737,3.5792676,1172.31616,0.032768,0.0,9.928,8.4,3.5685491,1144.27904,0.0,0.0,6.768,6.745
1442623811000,9.7863283,1371.078656,3.100672,0.0,57.783,54.18,2.8776152,1121.243136,0.0,0.0,6.874,5.647,3.1359806,1229.459456,1.304576,0.0,7.645,6.318,3.4629903,1177.710592,0.0,0.0,7.423,6.763,3.3964321,1146.671104,0.0,0.0,10.541,9.452
1442623872000,10.8863601,1372.176384,2.091008,0.0,64.191,59.464,4.0897906,1125.306368,0.0,0.0,10.174,9.27,3.8557282,1231.937536,1.964032,0.0,8.133,7.351,3.5946296,1178.423296,0.008192,0.0,7.013,6.515,3.3677622,1147.547648,0.0,0.0,9.034,7.178
1442623932000,9.842315,1373.069312,2.92864,0.0,51.987,48.34,3.1052186,1122.54976,0.0,0.0,6.919,7.205,2.9271669,1233.620992,1.20832,0.0,7.015,6.408,3.9976176,1180.684288,0.0,0.0,7.311,6.862,3.1855326,1147.285504,0.0,0.0,6.088,5.41
1442623992000,9.8362508,1374.375936,2.596864,0.0,69.22,64.399,3.9016521,1124.704256,0.0,0.0,8.981,7.549,3.0712642,1233.629184,1.503232,0.0,6.516,6.088,4.1723537,1181.184,0.0,0.0,11.312,9.811,2.5030555,1152.499712,0.0,0.0,9.158,8.54
1442624052000,10.2645909,1358.606336,1.662976,0.0,61.213,57.446,3.5578158,1115.086848,0.0,0.0,6.915,6.122,3.4612246,1222.098944,1.45408,0.0,7.054,5.868,3.8928314,1169.035264,0.008192,0.0,10.826,9.391,3.6515077,1136.603136,0.0,0.0,8.691,7.78
1442624112000,9.1590316,1360.408576,3.047424,0.0,61.529,57.249,4.1524929,1115.045888,0.0,0.0,9.086,7.864,3.4524616,1225.728,1.368064,0.0,7.904,6.148,3.1693051,1168.920576,0.004096,0.0,8.51,7.652,2.7066977,1133.891584,0.0,0.0,8.776,7.058
1442624172000,9.7301926,1364.066304,2.347008,0.0,66.808,61.546,3.3130376,1114.484736,0.0,0.0,8.317,7.386,2.9465336,1227.984896,1.032192,0.0,6.481,5.273,3.398718,1172.369408,0.0,0.0,8.789,8.073,4.1371211,1142.513664,0.0,0.0,10.788,9.793
1442624232000,9.9243081,1366.6304,3.09248,0.0,63.176,59.533,4.0645667,1115.684864,0.0,0.0,9.064,7.656,3.5756358,1226.952704,0.864256,0.0,7.333,5.79,4.0135786,1172.185088,0.0,0.0,9.047,7.848,3.4696482,1140.473856,0.0,0.0,9.949,8.937
1442624292000,10.208327,1368.064,3.514368,0.0,71.55,66.652,4.5291309,1117.650944,0.0,0.0,9.945,7.684,3.7071244,1231.347712,1.261568,0.0,6.798,6.622,3.9853981,1176.178688,0.004096,0.0,9.289,7.341,4.2200538,1142.566912,0.0,0.0,13.309,11.47
1442624352000,9.8974252,1370.546176,2.678784,0.0,70.866,66.188,3.8217392,1118.86336,0.0,0.0,7.734,6.735,3.1243558,1230.700544,1.247232,0.0,8.059,6.811,4.1108401,1176.682496,0.0,0.0,11.814,9.956,3.2721435,1143.529472,0.0,0.0,11.257,9.647
1442624412000,9.7970626,1371.500544,3.54304,0.0,63.355,59.518,4.2209959,1117.179904,0.0,0.0,7.894,7.752,3.4977414,1234.386944,1.021952,0.0,6.623,5.091,3.1851099,1176.379392,0.004096,0.0,10.164,8.701,4.3761738,1143.414784,0.0,0.0,9.649,8.719
1442624472000,8.0400737,1373.036544,1.605632,0.0,56.299,52.88,3.4402882,1116.962816,0.0,0.0,7.876,7.324,3.1506025,1237.725184,2.013184,0.0,6.698,6.784,2.8110915,1178.230784,0.004096,0.0,8.248,7.396,3.2982806,1145.909248,0.0,0.0,8.123,7.078
1442624532000,3.7226543,1380.012032,2.92864,0.0,39.205,36.578,68.3924358,1146.077184,0.196608,0.0,5.056,4.527,91.6439036,1243.856896,0.595968,0.0,3.998,3.628,1.3615635,1176.748032,0.0,0.0,6.228,5.201,1.4083689,1144.254464,0.0,0.0,5.4,5.349
1442624592000,9.7949039,1374.4128,2.297856,0.0,44.915,42.051,3.8718257,1140.236288,0.0,0.0,6.367,4.902,2.9627718,1235.226624,0.79872,0.0,4.877,4.311,4.1560351,1201.037312,0.0,0.0,7.459,6.759,3.547213,1142.853632,0.0,0.0,7.321,5.877
1442624652000,5.6958506,1360.441344,3.46112,0.0,51.973,48.217,2.6745162,1112.035328,0.0,0.0,7.002,6.639,1.5960984,1218.932736,0.960512,0.0,6.497,5.787,2.4299201,1165.299712,0.0,0.0,9.717,8.338,1.5095815,1126.1952,0.0,0.0,6.347,5.629
1442624713000,9.1247496,1354.3424,2.64192,0.0,52.517,49.112,4.0715854,1118.334976,0.0,0.0,8.25,7.917,3.0933089,1226.653696,0.835584,0.014336,5.632,4.813,3.4739688,1171.61984,0.004096,0.0,7.227,6.988,3.166859,1127.165952,0.0,0.0,7.812,6.901
1442624773000,9.6844497,1355.042816,3.006464,0.0,71.712,66.326,3.2320119,1120.243712,0.0,0.0,9.553,7.724,2.6735778,1228.791808,0.946176,0.014336,7.902,6.482,3.4200861,1174.716416,0.0,0.0,8.942,7.974,4.1212579,1136.377856,0.0,0.0,11.061,10.55
1442624833000,9.680867,1360.801792,2.265088,0.0,62.812,58.098,3.7626979,1117.868032,0.0,0.0,7.985,7.01,2.8906333,1231.36,1.232896,0.008192,6.023,5.168,3.354628,1172.340736,0.0,0.0,9.955,9.831,3.0516478,1139.851264,0.0,0.0,8.347,7.417
1442624893000,8.5783878,1359.314944,2.924544,0.0,49.066,46.252,3.3201327,1118.646272,0.0,0.0,6.643,5.972,1.9467339,1229.545472,1.4848,0.01024,4.157,4.13,3.4181318,1173.303296,0.0,0.0,8.215,6.979,3.4372369,1140.826112,0.0,0.0,7.77,6.695
1442624953000,9.2309241,1361.661952,3.436544,0.0,67.914,63.642,3.4759492,1124.200448,0.0,0.0,9.4,8.368,3.2561457,1230.815232,1.3312,0.008192,8.075,6.961,3.17565,1176.121344,0.0,0.0,10.815,10.054,3.4097645,1142.398976,0.0,0.0,7.76,6.706
1442625013000,10.9313409,1365.905408,3.117056,0.0,62.474,58.628,4.1307264,1125.896192,0.0,0.0,8.759,7.251,4.0430102,1235.136512,1.867776,0.01024,8.818,7.661,3.8515659,1177.526272,0.0,0.0,7.472,5.963,3.3655365,1144.07424,0.0,0.0,9.392,8.553
1442625074000,9.9703004,1366.949888,3.731456,0.0,60.944,57.134,3.5541777,1126.72768,0.0,0.0,7.694,7.214,3.1412411,1235.091456,1.347584,0.008192,7.599,6.569,3.9953272,1179.312128,0.0,0.0,7.903,7.428,3.5551165,1145.552896,0.0,0.0,9.497,8.62
1442625134000,10.8573592,1366.642688,2.813952,0.0,70.582,66.863,4.4707015,1126.965248,0.0,0.0,9.034,7.928,4.3926564,1236.23424,1.378304,0.008192,9.803,7.675,3.9080174,1179.672576,0.032768,0.0,9.932,8.603,3.7283053,1146.130432,0.0,0.0,10.811,8.927
1442625195000,8.6534405,1367.781376,2.289664,0.0,63.58,59.138,3.128825,1127.5264,0.0,0.0,8.611,7.661,3.3279158,1236.844544,1.329152,0.0,8.222,6.736,2.9951485,1180.086272,0.0,0.0,7.995,7.431,3.1906109,1146.953728,0.0,0.0,10.437,8.73
1442625256000,7.1727697,1368.203264,2.994176,0.028672,61.17,57.417,2.8370685,1131.012096,0.0,0.0,7.295,6.685,2.7871101,1234.743296,1.4336,0.0,7.203,5.937,2.9050416,1180.889088,0.0,0.0,9.886,8.226,3.1079632,1147.195392,0.0,0.0,9.017,8.016
1442625316000,8.004796,1352.445952,2.613248,0.02048,58.991,54.626,3.2174016,1123.004416,0.0,0.0,9.112,7.676,1.9990534,1225.883648,1.599488,0.0,5.693,5.238,3.1019826,1169.956864,0.02048,0.0,9.856,8.767,2.7687807,1134.235648,0.004096,0.0,7.148,6.069
1442625376000,9.6557766,1352.364032,2.330624,0.012288,58.124,54.24,4.340977,1126.1952,0.0,0.0,8.617,6.715,2.5665862,1223.319552,1.507328,0.0,5.489,5.179,3.7687582,1180.356608,0.0,0.0,8.711,7.88,4.3419754,1135.218688,0.0,0.0,9.177,8.131
1442625436000,8.2123698,1361.05984,1.45408,0.024576,53.694,50.643,3.6978619,1123.725312,0.012288,0.0,9.51,8.85,3.0431763,1229.672448,1.548288,0.0,5.701,5.311,2.6082534,1179.017216,0.0,0.0,6.043,6.486,3.4279438,1140.936704,0.0,0.0,7.885,6.195
1442625496000,8.0410497,1363.369984,2.891776,0.057344,50.015,46.766,3.6432176,1129.59488,0.0,0.0,6.91,6.36,3.131329,1229.754368,1.449984,0.0,5.59,5.347,2.6625069,1179.963392,0.0,0.0,8.02,6.67,3.3494276,1141.956608,0.0,0.0,7.479,6.971
1442625557000,11.8904876,1359.68768,1.798144,0.028672,62.168,58.781,4.3234607,1127.469056,0.0,0.0,7.186,6.607,3.7966414,1225.494528,1.742848,0.0,7.023,6.665,3.9903151,1184.088064,0.004096,0.0,10.616,9.369,3.351089,1137.762304,0.0,0.0,9.026,7.973
1442625618000,13.9236394,1362.518016,2.523136,0.0,63.838,59.578,3.4694737,1131.188224,0.0,0.0,8.942,8.006,2.9583829,1232.044032,2.164736,0.0,8.859,8.063,3.3969873,1185.50528,0.0,0.0,9.484,8.62,3.23474,1148.862464,0.0,0.0,8.139,6.409
1442625678000,10.3169886,1363.529728,2.68288,0.0,68.135,63.357,4.3034726,1128.1408,0.0,0.0,10.23,9.611,3.9058092,1232.297984,1.4336,0.0,7.25,6.248,3.9530802,1185.869824,0.0,0.0,9.202,7.836,3.7282834,1151.950848,0.0,0.0,9.849,8.14
1442625738000,9.9052507,1358.462976,2.3552,0.0,70.189,65.249,3.5301302,1128.390656,0.0,0.0,9.303,8.6,3.6329124,1232.166912,0.956416,0.0,7.42,6.487,3.7072797,1185.148928,0.004096,0.0,11.076,9.19,3.62498,1150.509056,0.0,0.0,9.959,8.417
1442625798000,7.5450464,1350.270976,3.6352,0.0,63.113,59.623,2.7704792,1133.092864,0.0,0.0,7.0,5.4,2.2141565,1230.557184,1.398784,0.0,6.681,5.717,2.5607987,1185.263616,0.0,0.0,10.841,9.478,2.7486839,1149.059072,0.004096,0.0,10.743,7.536
1442625858000,9.3600524,1348.95616,3.710976,0.0,63.207,58.613,3.8401528,1134.891008,0.0,0.0,8.04,6.973,4.3885294,1226.97728,2.091008,0.0,9.267,8.823,2.8921417,1186.63168,0.0,0.0,7.551,7.44,3.5372484,1147.731968,0.0,0.0,8.706,7.443
1442625918000,8.2649258,1333.248,3.211264,0.0,61.846,57.617,1.9523332,1116.921856,0.0,0.0,6.867,5.706,2.8444481,1215.086592,0.759808,0.0,6.485,4.863,3.0853671,1172.447232,0.0,0.0,8.639,7.57,3.2100708,1137.512448,0.0,0.0,11.774,10.199
1442625978000,8.6915265,1334.861824,3.500032,0.0,59.636,55.96,3.2412963,1122.410496,0.0,0.0,7.711,7.057,1.6702781,1215.066112,1.06496,0.0,5.732,4.854,3.1213699,1172.373504,0.0,0.0,9.357,8.799,3.2950377,1137.532928,0.032768,0.0,9.869,7.983
1442626038000,10.7050479,1337.860096,2.715648,0.0,65.155,61.242,3.194714,1121.234944,0.0,0.0,7.629,5.617,3.8572634,1218.752512,1.869824,0.0,8.322,7.481,3.7890387,1173.549056,0.0,0.0,10.992,10.722,3.6066734,1138.786304,0.0,0.0,8.21,6.788
1442626099000,6.7375873,1338.109952,2.146304,0.0,47.708,44.178,2.5737109,1121.517568,0.0,0.0,6.943,5.703,2.6396208,1217.159168,1.202176,0.0,6.52,5.471,4.8847607,1181.986816,2.306048,0.0,7.214,6.166,1.4701902,1138.077696,0.0,0.0,6.018,5.759
1442626159000,9.3955529,1341.472768,2.248704,0.0,65.808,61.802,3.8496803,1132.52352,0.0,0.0,8.199,7.0,2.8129609,1225.609216,0.86016,0.0,7.274,5.436,3.4277392,1197.2608,0.0,0.0,9.575,8.381,4.064778,1144.520704,0.0,0.0,11.489,10.342
1442626219000,8.8766758,1329.106944,3.084288,0.0,57.595,55.156,2.9385302,1129.791488,0.0,0.0,6.466,5.868,3.3801104,1241.2928,2.543616,0.0,6.908,6.535,3.5479463,1198.968832,0.008192,0.0,10.043,7.496,3.3105029,1146.740736,0.0,0.0,8.923,7.672
1442626279000,10.0066367,1336.594432,2.207744,0.0,74.371,69.675,3.9471152,1130.840064,0.0,0.0,11.846,10.327,2.8072408,1236.860928,1.3824,0.0,6.665,6.174,3.8901785,1198.747648,0.0,0.0,10.692,9.423,3.5685912,1146.646528,0.0,0.0,11.854,9.89
1442626339000,9.3792236,1359.429632,4.018176,0.0,66.451,61.915,3.6268917,1129.852928,0.004096,0.0,8.098,7.325,3.6471103,1234.8416,1.544192,0.0,8.381,7.443,3.0260191,1200.320512,0.0,0.0,8.472,6.603,3.8478705,1145.32352,0.0,0.0,10.393,8.691
1442626399000,8.8424925,1349.799936,3.448832,0.0,61.278,56.916,3.1365865,1126.453248,0.0,0.0,8.443,7.007,4.1871155,1237.446656,2.285568,0.0,8.606,8.308,3.0425944,1200.10752,0.0,0.0,8.543,7.082,3.1280382,1152.55296,0.0,0.0,7.673,6.669
1442626460000,7.8595564,1348.845568,6.160384,0.0,42.086,40.015,2.9922794,1126.518784,0.0,0.0,5.959,5.229,2.188345,1237.622784,0.878592,0.0,3.504,3.617,2.5460616,1201.393664,0.0,0.0,3.949,3.954,2.9191521,1154.3552,0.0,0.0,8.542,7.646
1442626520000,8.4752573,1331.392512,3.743744,0.0,51.789,48.898,2.0158966,1100.333056,0.02048,0.0,7.272,6.544,2.4524537,1224.286208,0.888832,0.0,5.741,4.494,2.8450345,1188.610048,0.0,0.0,6.627,6.352,3.5475365,1137.410048,0.004096,0.0,8.371,7.707
1442626580000,8.1646525,1336.020992,3.837952,0.0,41.852,39.639,3.3055115,1098.91584,0.0,0.0,6.09,5.1,2.4760894,1230.9504,1.779712,0.0,4.935,4.939,2.3931916,1194.96704,0.0,0.0,5.833,5.595,3.2316462,1139.298304,0.0,0.0,6.31,6.21
1442626640000,8.5436018,1336.999936,2.441216,0.0,55.312,50.977,3.3280342,1100.509184,0.0,0.0,6.972,6.883,2.9742922,1227.608064,0.985088,0.0,6.842,5.413,3.1506769,1195.945984,0.004096,0.0,8.575,7.569,3.3488955,1141.02272,0.0,0.0,7.218,5.696
1442626700000,7.6679124,1341.759488,5.341184,0.0,49.449,46.974,2.6483264,1098.162176,0.0,0.0,5.937,5.464,3.0869137,1228.075008,1.101824,0.0,6.946,6.384,2.1659489,1188.18816,0.0,0.0,6.85,6.828,2.792381,1139.9168,0.0,0.0,7.578,6.331
1442626760000,7.8262705,1340.833792,4.354048,0.0,51.135,47.604,2.5981315,1097.519104,0.0,0.0,7.961,6.4,2.272364,1230.708736,1.35168,0.0,5.544,4.563,3.4754873,1190.760448,0.0,0.0,8.445,7.046,2.7373659,1139.2,0.0,0.0,6.365,5.398
1442626820000,10.7616021,1343.71328,3.186688,0.0,58.955,53.966,3.0463702,1099.128832,0.004096,0.0,6.16,6.382,2.9850708,1233.506304,1.55648,0.0,8.27,7.207,3.6708061,1194.3936,0.0,0.0,6.848,5.782,4.5103,1140.18304,0.032768,0.0,9.152,8.514
1442626880000,9.1019262,1345.67936,4.063232,0.0,54.705,50.731,3.8270118,1104.105472,0.0,0.0,7.978,6.986,2.8782925,1238.708224,1.667072,0.0,6.901,6.518,3.0956263,1200.70144,0.0,0.0,8.408,7.39,2.5476997,1143.97184,0.0,0.0,6.592,6.177
1442626941000,8.5655384,1345.110016,5.16096,0.0,57.575,53.906,2.6759952,1098.313728,0.0,0.0,7.757,6.869,2.8601258,1237.250048,1.343488,0.0,7.476,6.425,3.1460313,1198.313472,0.0,0.0,6.866,5.791,3.007223,1147.428864,0.0,0.0,10.007,8.322
1442627002000,10.1040196,1342.50496,3.108864,0.0,67.782,63.528,2.9955531,1103.474688,0.0,0.0,9.234,8.392,2.2794768,1238.274048,1.441792,0.0,6.644,6.354,3.9613472,1201.680384,0.004096,0.0,10.854,9.088,3.8386203,1149.333504,0.0,0.0,10.428,8.151
1442627062000,8.8031024,1344.618496,3.842048,0.0,57.699,54.181,3.1817738,1101.631488,0.0,0.0,7.162,5.862,3.7177721,1239.10144,2.879488,0.0,9.175,8.262,2.5744494,1200.16896,0.0,0.0,6.83,6.098,2.9917592,1146.085376,0.0,0.0,8.819,7.048
1442627122000,9.8781122,1332.236288,3.428352,0.0,62.639,58.67,4.510907,1085.6448,0.0,0.0,10.888,9.098,2.9393562,1225.539584,1.492992,0.0,7.169,5.977,3.7944354,1193.238528,0.0,0.0,8.915,7.218,3.8946512,1133.580288,0.0,0.0,8.294,7.068
1442627182000,8.2473745,1331.961856,3.895296,0.0,48.706,46.162,2.8268347,1084.379136,0.0,0.0,6.551,5.559,3.1162052,1225.900032,2.215936,0.0,5.677,5.424,3.0805524,1194.63936,0.0,0.0,9.828,7.175,2.5630008,1139.847168,0.0,0.0,6.417,5.681
1442627242000,8.7735811,1340.514304,3.301376,0.0,45.225,42.558,3.2246593,1081.331712,0.0,0.0,6.556,5.611,2.6871415,1229.561856,1.036288,0.0,5.244,4.377,3.0873724,1200.627712,0.0,0.0,7.08,6.851,3.6071068,1145.114624,0.823296,0.0,6.178,6.486
1442627302000,10.0003548,1340.022784,3.35872,0.0,56.117,52.578,2.7311837,1082.372096,0.0,0.0,5.921,5.012,4.0741934,1230.774272,2.400256,0.0,7.462,7.121,3.9864228,1201.496064,0.0,0.0,11.584,10.087,3.7246087,1145.028608,0.0,0.0,6.799,6.152
1442627363000,6.2270876,1337.32352,3.028992,0.0,39.313,37.062,3.1455301,1092.018176,0.569344,0.0,5.65,5.017,1.4226993,1229.119488,0.626688,0.008192,5.895,4.911,1.9323335,1200.709632,0.0,0.0,4.549,4.291,1.9436489,1149.403136,0.0,0.0,6.944,6.719
1442627423000,7.7252794,1340.391424,4.36224,0.0,55.777,52.446,2.0369534,1094.324224,0.0,0.0,6.209,5.565,2.8948474,1232.662528,2.138112,0.008192,8.31,7.068,2.648696,1203.142656,0.0,0.0,9.334,7.79,3.0105451,1146.253312,0.0,0.0,6.325,5.089
1442627483000,9.0653938,1342.332928,4.153344,0.0,64.682,61.005,3.26286,1094.279168,0.0,0.0,8.676,7.095,2.9933458,1234.223104,1.572864,0.014336,8.619,6.74,3.5171325,1203.38432,0.0,0.0,9.467,7.657,3.9152365,1150.81216,0.0,0.0,11.024,8.639
1442627544000,9.2734732,1341.861888,4.206592,0.0,58.721,54.739,3.98773,1094.250496,0.0,0.0,8.315,6.63,3.5267277,1233.69472,1.562624,0.014336,7.208,6.67,2.477741,1200.771072,0.0,0.0,8.879,7.348,3.355841,1151.700992,0.0,0.0,6.402,6.328
1442627604000,9.5557025,1341.56288,4.44416,0.0,66.457,62.862,5.0642511,1094.746112,0.0,0.0,7.969,6.388,3.9949569,1235.816448,2.33472,0.008192,9.849,7.732,3.6970798,1202.868224,0.0,0.0,10.009,8.077,3.5691823,1151.434752,0.0,0.0,9.838,8.386
1442627664000,9.7733073,1344.987136,2.879488,0.0,53.79,50.911,3.7937188,1094.303744,0.0,0.0,8.302,6.972,4.1304614,1234.808832,2.2016,0.01024,7.365,6.869,3.0862184,1203.703808,0.0,0.0,7.868,6.573,3.233015,1151.34464,0.0,0.0,7.335,5.611
1442627724000,10.1003825,1328.398336,3.395584,0.0,58.635,54.574,3.8193159,1079.484416,0.0,0.0,6.852,6.362,3.4135384,1221.500928,1.3312,0.01024,6.679,5.568,3.3231674,1194.76224,0.0,0.0,8.668,7.734,4.5703396,1142.214656,0.004096,0.0,9.225,8.46
1442627785000,8.1121401,1329.90976,4.608,0.0,54.935,51.646,2.3302809,1080.1152,0.0,0.0,8.007,5.926,2.6090999,1225.588736,1.746944,0.0,6.604,5.259,2.6220096,1198.178304,0.0,0.0,8.42,7.721,2.8677527,1142.767616,0.0,0.0,5.898,5.768
1442627845000,8.1674796,1331.470336,3.571712,0.0,50.538,46.996,2.6928612,1090.02752,0.0,0.0,7.496,7.326,2.630149,1227.128832,1.941504,0.0,5.135,4.854,2.8132612,1201.020928,0.0,0.0,6.7,5.587,2.5009995,1143.640064,0.0,0.0,7.602,7.144
1442627905000,7.6629224,1329.90976,2.72384,0.0,63.082,58.861,2.8330732,1091.063808,0.0,0.0,7.12,6.422,2.7669408,1224.298496,1.45408,0.0,8.365,6.346,2.9959496,1200.386048,0.0,0.0,9.215,8.001,3.0774807,1146.929152,0.0,0.0,10.703,8.837
1442627965000,8.2658026,1333.170176,3.211264,0.0,59.379,55.399,2.6730963,1090.490368,0.0,0.0,7.015,6.84,2.2158386,1226.28096,1.820672,0.0,5.845,5.626,3.0989795,1202.663424,0.0,0.0,9.51,7.743,2.9265901,1144.045568,0.0,0.0,9.997,9.206
1442628025000,8.2195637,1338.24512,3.31776,0.0,59.316,55.771,3.2886228,1091.74784,0.0,0.0,8.692,6.959,3.0137032,1228.107776,1.601536,0.0,6.524,5.445,3.3162917,1204.28544,0.0,0.0,8.826,7.722,2.7960161,1142.23104,0.0,0.0,8.467,7.399
1442628085000,8.2254045,1337.901056,2.816,0.0,60.656,56.541,2.8358179,1092.93568,0.0,0.0,6.394,6.226,3.421115,1230.721024,2.158592,0.0,7.78,6.895,3.5941848,1206.267904,0.0,0.0,8.343,6.515,3.3980611,1150.926848,0.0,0.0,11.752,9.671
1442628145000,8.7297974,1334.501376,2.412544,0.0,57.999,54.758,3.8167567,1095.745536,0.0,0.0,9.481,8.945,2.5746185,1231.024128,1.015808,0.0,6.172,5.212,3.0088778,1207.238656,0.0,0.0,8.295,7.33,2.6408996,1153.245184,0.0,0.0,8.888,7.585
1442628206000,8.537673,1333.235712,3.784704,0.024576,55.018,52.227,3.1389459,1094.520832,0.0,0.0,6.362,5.11,3.3850566,1231.241216,2.244608,0.0,7.075,6.408,3.3771598,1207.574528,0.0,0.0,7.145,6.298,2.5724994,1153.4336,0.0,0.0,9.967,8.543
1442628266000,10.3710466,1332.269056,1.818624,0.02048,58.959,55.17,3.2395166,1093.189632,0.0,0.0,7.297,7.113,3.952511,1230.565376,0.96256,0.0,7.097,5.672,4.6203195,1207.980032,0.032768,0.0,9.434,8.753,3.5459759,1154.371584,0.0,0.0,8.57,7.657
1442628326000,8.4894384,1325.551616,3.444736,0.02048,57.332,53.566,2.3870289,1082.822656,0.0,0.0,6.899,5.976,3.4278578,1219.600384,1.683456,0.0,7.753,6.312,3.6632157,1197.056,0.0,0.0,9.721,8.626,2.0091624,1145.479168,0.0,0.0,7.465,6.138
1442628386000,8.5009403,1322.569728,1.3312,0.024576,56.229,52.309,2.3356152,1081.233408,0.0,0.0,6.145,5.995,3.3079726,1215.741952,1.906688,0.0,6.776,5.73,2.9817386,1194.139648,0.0,0.0,9.203,8.498,3.0275642,1145.802752,0.032768,0.0,9.014,8.603
1442628446000,8.9947473,1325.518848,2.091008,0.02048,51.314,47.183,2.1142089,1084.588032,0.0,0.0,5.479,4.918,1.9586043,1218.68288,1.644544,0.0,4.612,4.169,3.8544049,1197.928448,0.0,0.0,8.392,8.029,3.6805168,1148.133376,0.0,0.0,9.201,8.961
1442628506000,7.451234,1334.55872,3.166208,0.024576,38.039,35.683,4.6824593,1093.890048,0.274432,0.0,6.799,6.817,2.589088,1222.053888,0.622592,0.0,4.097,3.232,2.6064391,1200.76288,0.0,0.0,4.428,4.209,2.7266462,1151.967232,0.0,0.0,6.636,6.306
1442628566000,8.881132,1332.084736,3.25632,0.024576,56.239,52.312,3.5895293,1086.992384,0.0,0.0,9.096,7.617,3.2410154,1221.464064,2.349056,0.0,7.962,7.35,3.3135807,1200.90624,0.0,0.0,6.106,5.715,3.2303739,1150.697472,0.0,0.0,8.106,7.196
1442628627000,10.3254257,1333.444608,4.009984,0.032768,62.564,58.722,3.0645043,1088.073728,0.0,0.0,8.405,7.07,3.2840472,1228.181504,1.714176,0.0,8.08,6.418,4.1122294,1202.008064,0.0,0.0,7.432,6.615,3.9975126,1151.090688,0.0,0.0,9.91,8.735
1442628688000,9.6034053,1334.505472,3.969024,0.0,71.966,67.981,3.2998114,1087.54944,0.0,0.0,10.172,8.678,4.2308649,1227.739136,2.727936,0.0,10.772,9.738,3.8218492,1203.310592,0.0,0.0,10.445,8.519,3.0932367,1151.090688,0.0,0.0,9.539,8.027
1442628748000,10.4898169,1336.168448,3.129344,0.0,61.876,58.052,3.7099876,1096.445952,0.0,0.0,7.79,7.29,3.5766112,1228.333056,1.855488,0.0,7.854,6.887,3.7112651,1203.191808,0.0,0.0,9.585,7.98,3.6881426,1151.356928,0.0,0.0,8.514,7.271
1442628808000,8.9523533,1342.27968,5.779456,0.0,70.274,65.725,2.8880104,1093.287936,0.0,0.0,8.169,6.075,3.6187591,1228.898304,2.136064,0.0,8.807,7.735,3.6911059,1203.642368,0.02048,0.0,8.976,8.201,3.6517561,1153.327104,0.0,0.0,11.556,9.044
1442628868000,12.5277966,1334.5792,3.31776,0.0,58.396,54.562,5.0514358,1094.045696,0.0,0.0,9.176,8.396,3.6769555,1228.988416,1.64864,0.0,5.646,5.639,4.2815519,1208.856576,0.0,0.0,8.56,7.004,4.2991811,1155.211264,0.0,0.0,8.251,7.397
1442628928000,8.8983911,1319.620608,5.115904,0.0,57.644,53.91,3.0365365,1083.37152,0.0,0.0,5.284,4.885,2.3656718,1215.40608,2.082816,0.0,7.644,6.402,3.394946,1192.7552,0.0,0.0,8.293,7.376,3.7280781,1140.092928,0.0,0.0,9.924,7.51
1442628988000,8.7177101,1311.70304,4.702208,0.0,54.99,51.794,2.8452197,1085.407232,0.0,0.0,7.322,7.118,3.124581,1219.497984,1.841152,0.0,6.415,5.853,3.8109821,1195.700224,0.0,0.0,8.845,6.717,3.3911839,1150.980096,0.0,0.0,8.313,7.932
1442629049000,8.462778,1310.339072,2.404352,0.0,59.983,55.983,3.578229,1090.183168,0.0,0.0,8.339,7.079,2.2604021,1223.938048,1.52576,0.0,6.85,5.736,3.7219442,1199.423488,0.0,0.0,7.79,7.553,3.0324469,1152.110592,0.0,0.0,9.812,8.503
1442629110000,8.7478686,1308.315648,5.50912,0.0,58.013,54.111,2.9980229,1098.50624,0.0,0.0,6.886,6.603,2.88867,1228.865536,1.564672,0.0,5.971,5.3,3.4980308,1200.615424,0.0,0.0,8.959,7.447,3.62971,1149.288448,0.0,0.0,9.544,8.62
1442629171000,8.5743683,1311.547392,3.510272,0.0,59.997,56.61,2.8744276,1099.423744,0.0,0.0,8.534,6.729,3.7528688,1228.550144,0.856064,0.0,8.549,6.99,3.8617475,1199.22688,0.0,0.0,9.277,7.414,2.7281158,1150.992384,0.008192,0.0,8.969,7.94
1442629231000,10.1902325,1317.072896,2.936832,0.0,57.503,54.317,3.1779775,1100.4928,0.0,0.0,7.153,6.424,3.8439283,1230.430208,1.636352,0.0,8.029,6.525,3.9268989,1200.410624,0.0,0.0,7.91,7.315,3.3819415,1151.430656,0.004096,0.0,9.451,8.324
1442629291000,9.5454805,1320.824832,5.054464,0.0,60.744,57.048,4.5444389,1100.566528,0.0,0.0,9.464,8.037,3.2135694,1229.676544,1.081344,0.0,6.965,5.748,3.2221965,1201.242112,0.0,0.0,7.696,6.841,3.9179555,1151.85664,0.036864,0.0,9.791,8.161
1442629351000,9.8842021,1326.272512,3.633152,0.0,56.793,53.711,2.8565697,1104.474112,0.0,0.0,5.647,5.064,3.6872702,1229.96736,1.37216,0.0,8.256,6.655,4.2310296,1204.383744,0.008192,0.0,8.552,8.1,4.2495527,1152.765952,0.0,0.0,9.437,8.184
1442629411000,9.6458657,1322.92608,3.91168,0.0,61.46,57.683,3.5423736,1103.212544,0.0,0.0,9.128,7.451,3.2449541,1229.377536,1.773568,0.0,6.954,6.49,4.2162842,1202.724864,0.0,0.0,8.54,7.366,3.8392849,1151.012864,0.0,0.0,9.567,7.606
1442629471000,9.7462666,1317.70368,4.481024,0.0,66.596,61.979,3.9518898,1103.38048,0.0,0.0,10.469,8.546,3.2585598,1229.799424,1.90464,0.0,7.509,6.207,3.7211496,1202.8928,0.0,0.0,7.581,7.232,3.3447259,1152.684032,0.0,0.0,10.015,8.684
1442629531000,7.0203077,1309.577216,2.762752,0.0,59.588,55.498,2.2890016,1092.386816,0.0,0.0,7.296,6.394,2.9993556,1225.0112,1.368064,0.0,8.924,7.528,3.0155438,1193.496576,0.004096,0.0,7.998,7.326,2.4632918,1144.979456,0.0,0.0,8.346,8.154
1442629591000,9.2819469,1310.859264,3.006464,0.0,54.29,50.579,3.5963178,1098.059776,0.0,0.0,7.949,7.719,2.5611721,1225.445376,1.009664,0.0,5.861,4.982,3.7317376,1191.202816,0.0,0.0,7.488,7.141,3.0294025,1143.054336,0.008192,0.0,7.627,7.019
1442629651000,6.6934554,1310.14656,1.609728,0.0,42.617,39.734,2.8817912,1099.239424,0.0,0.0,6.249,5.98,2.0968616,1227.485184,1.759232,0.0,5.243,4.917,1.858208,1191.325696,0.0,0.0,5.114,5.058,2.7490025,1144.680448,0.43008,0.0,7.928,7.604
1442629711000,9.5320714,1307.435008,4.349952,0.0,61.238,57.189,3.3902136,1101.205504,0.0,0.0,9.293,8.261,3.0442231,1228.832768,1.49504,0.0,6.526,5.856,2.8991756,1203.122176,0.0,0.0,7.901,7.011,3.8191955,1150.8736,0.0,0.0,10.49,8.388
1442629771000,9.5527925,1309.339648,4.579328,0.0,64.591,61.039,3.0708292,1099.452416,0.0,0.0,8.649,7.388,3.4924464,1228.455936,1.359872,0.0,7.739,5.922,3.0729626,1204.953088,0.0,0.0,8.961,7.318,4.0473175,1154.428928,0.0,0.0,9.879,8.803
1442629831000,9.6174883,1309.036544,4.415488,0.0,61.28,57.0,3.5207269,1105.94048,0.0,0.0,7.384,6.425,2.6127215,1226.031104,0.976896,0.0,6.621,5.251,3.4965583,1204.371456,0.0,0.0,8.367,6.856,3.709857,1153.654784,0.0,0.0,9.757,8.555
1442629891000,8.5085675,1307.099136,3.690496,0.0,59.773,55.41,3.0126129,1105.993728,0.0,0.0,8.458,7.419,3.3808992,1228.886016,1.40288,0.0,6.059,5.521,3.3354502,1202.376704,0.0,0.0,8.858,7.807,3.0600338,1159.02464,0.0,0.0,8.807,7.645
1442629951000,7.5407518,1308.934144,4.538368,0.0,53.485,50.354,3.4859785,1107.861504,0.0,0.0,8.184,6.413,3.1808483,1234.059264,2.850816,0.0,8.236,7.577,2.897746,1203.216384,0.0,0.0,7.759,6.59,1.8931244,1155.493888,0.0,0.0,5.683,5.233
1442630011000,8.3879361,1305.86624,2.953216,0.0,60.478,56.378,2.0368829,1110.36416,0.0,0.0,7.047,5.219,3.3805896,1235.939328,1.845248,0.0,6.971,6.168,3.6217243,1204.207616,0.0,0.0,7.897,7.279,3.4318261,1155.11296,0.0,0.0,10.721,8.786
1442630072000,9.1173499,1305.776128,2.977792,0.0,55.956,52.178,3.3900456,1111.543808,0.0,0.0,8.076,7.337,3.2442919,1231.593472,2.37568,0.0,7.24,6.917,3.2473116,1205.133312,0.0,0.0,6.598,6.017,3.1499373,1156.521984,0.0,0.0,9.065,7.824
1442630132000,9.899194,1307.140096,5.2224,0.0,59.975,55.941,4.271844,1111.584768,0.0,0.0,8.764,7.573,4.2649683,1233.575936,1.316864,0.0,8.178,6.569,3.6666486,1203.75296,0.0,0.0,7.797,6.233,4.0115626,1153.957888,0.0,0.0,8.081,6.629
1442630192000,9.4206625,1300.852736,2.490368,0.0,56.369,52.877,3.9851651,1104.289792,0.032768,0.0,6.418,6.134,3.1046499,1225.158656,2.306048,0.0,7.532,6.752,3.8652738,1195.790336,0.0,0.0,8.732,7.392,3.0958639,1149.997056,0.0,0.0,7.803,6.831
1442630252000,9.8435663,1300.21376,1.724416,0.0,53.094,49.343,3.173516,1105.73568,0.0,0.0,6.343,5.987,4.065051,1226.133504,2.025472,0.0,7.615,6.566,3.3120143,1198.522368,0.004096,0.0,6.405,6.256,3.9147305,1148.391424,0.0,0.0,8.893,7.956
1442630312000,6.8208771,1303.994368,2.760704,0.0,54.53,51.056,2.1117554,1107.488768,0.0,0.0,6.006,5.48,2.3216949,1226.113024,1.671168,0.0,7.291,5.945,2.2440406,1196.265472,0.0,0.0,6.248,5.244,3.0256296,1151.229952,0.0,0.0,10.497,9.482
1442630372000,8.1471487,1305.288704,2.33472,0.0,55.54,52.35,4.1243952,1107.021824,0.0,0.0,8.142,6.999,2.9329717,1229.996032,2.2528,0.0,7.329,6.809,2.5788121,1197.719552,0.0,0.0,6.059,5.24,3.101242,1150.930944,0.0,0.0,8.377,7.147
1442630432000,9.886832,1306.759168,2.428928,0.0,64.151,59.991,4.4846647,1114.13248,0.0,0.0,9.389,8.224,3.1944766,1231.81056,1.662976,0.0,8.896,7.302,3.8914557,1196.765184,0.0,0.0,8.803,6.991,3.4754902,1145.909248,0.0,0.0,8.321,7.881
1442630492000,8.6437422,1306.968064,2.727936,0.0,50.715,47.652,3.347618,1114.58304,0.0,0.0,6.55,6.573,3.2386882,1230.946304,2.154496,0.0,6.603,5.926,2.841239,1197.1584,0.0,0.0,7.187,6.427,2.8409053,1147.236352,0.0,0.0,8.193,7.365
1442630552000,8.8294404,1310.666752,1.826816,0.0,51.169,47.813,4.7456953,1116.708864,0.0,0.0,8.927,8.27,3.5914559,1227.24352,1.777664,0.0,6.348,6.069,2.8727674,1198.198784,0.0,0.0,7.41,7.147,2.6877216,1147.305984,0.0,0.0,6.373,5.265
1442630612000,11.6561366,1314.107392,3.05152,0.0,64.267,59.695,3.9740718,1116.85632,0.0,0.0,7.212,5.968,4.5329738,1231.609856,1.646592,0.0,8.903,7.05,4.2827859,1202.50368,0.0,0.0,8.731,7.761,4.020612,1147.2896,0.0,0.0,9.796,9.16
1442630672000,9.339336,1312.780288,4.427776,0.0,48.771,45.785,3.0104021,1114.169344,0.0,0.0,6.02,5.303,2.6765515,1230.614528,2.03776,0.0,4.736,5.186,3.5464962,1199.644672,0.0,0.0,7.507,6.776,3.2278356,1147.113472,0.0,0.0,8.356,6.854
1442630732000,10.301995,1314.7136,2.379776,0.0,57.748,53.847,3.7343935,1114.50112,0.0,0.0,8.966,7.251,4.4822088,1228.763136,2.615296,0.0,7.91,7.6,4.1682834,1199.702016,0.0,0.0,6.89,5.869,3.2892515,1146.10176,0.004096,0.0,8.888,7.482
1442630792000,7.8119832,1301.557248,3.862528,0.0,48.839,45.965,1.7652724,1104.490496,0.0,0.0,5.313,4.92,3.503345,1224.503296,2.5088,0.0,9.534,8.419,2.130971,1186.988032,0.0,0.0,6.267,5.637,2.4599124,1146.212352,0.004096,0.0,6.204,5.509
1442630853000,8.9859092,1301.213184,3.846144,0.0,67.774,62.327,3.2013681,1107.632128,0.004096,0.0,9.618,8.08,2.6505003,1222.414336,1.236992,0.0,7.36,5.876,3.0810669,1187.9424,0.0,0.0,7.947,7.932,3.287072,1142.767616,0.0,0.0,10.02,9.894
1442630913000,8.6371123,1304.076288,3.428352,0.0,49.186,45.904,3.4558909,1111.32672,0.024576,0.0,7.338,7.117,3.5145185,1225.404416,2.039808,0.0,5.995,5.729,3.0267131,1190.285312,0.0,0.0,6.066,5.648,3.4282005,1140.482048,0.032768,0.0,7.717,6.083
1442630973000,10.7749797,1302.462464,3.444736,0.0,62.952,59.392,4.5065636,1111.175168,0.0,0.0,8.477,7.314,3.0835428,1232.498688,1.42336,0.0,6.707,5.442,4.2526005,1194.274816,0.0,0.0,9.657,8.648,3.9556427,1144.029184,0.0,0.0,10.491,9.041
1442631033000,9.5452145,1306.415104,3.608576,0.0,64.522,59.444,3.3730181,1111.298048,0.0,0.0,8.319,7.538,3.7148577,1230.778368,1.570816,0.0,6.882,6.398,3.3990036,1196.42112,0.0,0.0,9.896,8.591,2.9672216,1143.058432,0.0,0.0,9.289,7.279
1442631093000,8.374319,1306.595328,3.9936,0.0,52.979,49.137,2.1869034,1112.612864,0.0,0.0,5.849,5.996,2.3402083,1233.088512,1.994752,0.0,5.988,5.838,2.2121476,1196.244992,0.0,0.0,8.041,6.424,3.3457099,1143.382016,0.0,0.0,8.227,6.766
1442631153000,9.5618195,1306.7264,4.182016,0.0,59.643,56.338,2.180688,1113.214976,0.0,0.0,7.425,5.767,3.2108584,1233.16224,1.527808,0.0,7.67,6.255,3.4687902,1197.395968,0.0,0.0,8.564,8.156,4.1361603,1145.155584,0.0,0.0,8.522,8.021
1442631213000,10.5395024,1308.049408,2.412544,0.0,59.064,55.614,5.0877962,1115.17696,0.016384,0.0,9.307,8.214,2.9479084,1235.857408,1.71008,0.0,6.196,5.341,3.5510734,1195.307008,0.0,0.0,7.834,6.647,4.162191,1146.007552,0.0,0.0,10.72,9.702
1442631273000,11.7566997,1308.512256,2.973696,0.0,72.283,66.916,4.7933767,1121.325056,0.0,0.0,9.42,7.726,3.61032,1233.05984,2.26304,0.0,8.097,7.332,4.3494909,1194.000384,0.0,0.0,9.541,8.015,4.0969295,1145.069568,0.004096,0.0,10.148,9.752
1442631333000,8.9133125,1309.110272,2.555904,0.0,61.555,57.799,3.0432933,1121.087488,0.024576,0.0,9.188,7.342,3.5338197,1234.067456,1.806336,0.0,7.66,6.755,3.4869857,1195.360256,0.008192,0.0,8.676,8.278,3.2014432,1146.015744,0.0,0.0,9.361,7.125
1442631394000,10.0572939,1294.639104,1.101824,0.0,74.556,69.034,3.4494486,1100.607488,0.0,0.0,8.788,7.02,3.75082,1223.10656,1.982464,0.006144,9.287,7.426,3.9627122,1182.048256,0.0,0.0,10.49,9.258,3.9237318,1142.19008,0.0,0.0,11.428,9.387
1442631455000,9.1227699,1301.000192,3.096576,0.0,52.886,49.317,3.8267623,1101.082624,0.0,0.0,7.127,7.1,3.0156267,1225.09312,1.026048,0.004096,6.267,5.34,3.1219342,1187.627008,0.0,0.0,7.847,6.899,3.7658066,1140.314112,0.0,0.0,7.856,6.937
1442631515000,8.2996855,1306.361856,3.190784,0.0,47.685,44.229,2.8739123,1107.2512,0.032768,0.0,6.305,5.66,2.6909228,1222.467584,1.613824,0.008192,6.671,5.224,3.2305839,1187.790848,0.0,0.0,6.428,6.152,2.9326145,1143.31648,0.0,0.0,7.378,5.956
1442631575000,10.7883481,1310.47424,2.691072,0.0,58.37,55.216,3.2518612,1106.956288,0.032768,0.0,6.916,5.889,4.1231857,1229.856768,2.08896,0.012288,7.561,6.23,3.4192583,1191.723008,0.024576,0.0,8.049,6.096,3.5381371,1146.847232,0.0,0.0,10.108,9.006
1442631635000,9.7062635,1309.364224,2.64192,0.0,57.386,53.861,3.4986466,1111.670784,0.0,0.0,8.251,6.302,4.0775555,1231.95392,2.041856,0.004096,8.063,7.127,3.2209293,1189.507072,0.0,0.0,7.408,6.786,3.9409235,1145.581568,0.0,0.0,8.909,8.182
1442631695000,7.8757857,1308.725248,3.596288,0.0,48.865,45.834,3.4166568,1113.337856,0.0,0.0,6.257,5.27,2.1345468,1232.273408,1.318912,0.01024,4.0,3.959,3.0295348,1193.005056,0.0,0.0,9.308,8.296,3.125169,1147.973632,0.032768,0.0,7.728,6.493
1442631756000,7.2558033,1305.27232,3.170304,0.008192,55.489,52.162,2.8838799,1113.341952,0.0,0.0,8.869,7.706,2.3253543,1233.805312,0.565248,0.0,6.118,4.597,2.5352397,1193.660416,0.0,0.0,8.86,8.699,3.4378771,1146.851328,0.0,0.0,7.771,6.681
1442631817000,8.971449,1310.826496,4.501504,0.028672,57.333,53.676,2.4567914,1112.285184,0.0,0.0,6.486,5.221,2.090742,1232.5888,1.62816,0.0,7.614,6.447,4.2802853,1193.795584,0.0,0.0,8.689,7.918,4.087794,1149.29664,0.0,0.0,8.663,7.019
1442631877000,12.211454,1312.477184,2.17088,0.024576,71.072,66.24,5.1284829,1117.294592,0.0,0.0,10.838,9.564,4.4469324,1228.345344,1.703936,0.0,7.251,5.99,4.2838144,1193.914368,0.0,0.0,11.184,9.769,3.862308,1146.028032,0.0,0.0,10.423,9.483
1442631937000,10.548426,1311.350784,2.101248,0.024576,64.739,59.924,4.3653772,1118.613504,0.0,0.0,9.127,8.53,3.1733759,1230.7456,1.570816,0.0,7.581,6.239,4.3935722,1193.025536,0.0,0.0,9.003,8.217,4.0937023,1147.830272,0.0,0.0,10.0,8.071
1442631997000,10.9918572,1304.49408,4.145152,0.016384,52.968,50.279,3.9144396,1109.921792,0.0,0.0,6.484,5.87,3.4323815,1219.29728,0.866304,0.0,5.36,4.316,4.2764999,1191.489536,0.008192,0.0,7.286,6.494,4.0611048,1147.383808,0.0,0.0,10.23,9.028
1442632057000,8.4852245,1306.394624,4.28032,0.016384,55.615,51.947,2.4706971,1106.784256,0.0,0.0,6.523,5.364,3.4218064,1223.0656,2.312192,0.0,6.844,6.716,3.3028839,1186.422784,0.0,0.0,7.973,6.146,2.9917795,1146.28608,0.0,0.0,10.02,8.425
1442632117000,10.5400887,1309.683712,3.084288,0.016384,56.979,52.998,3.8551594,1108.832256,0.0,0.0,7.791,7.649,2.6755956,1224.00768,1.359872,0.0,5.49,5.125,4.2481487,1187.55328,0.0,0.0,8.277,7.509,4.0553583,1150.967808,0.0,0.0,8.154,7.407
1442632177000,10.2276687,1308.491776,3.2768,0.016384,58.337,54.232,3.6411501,1115.090944,0.0,0.0,7.152,6.336,3.8654193,1225.863168,1.603584,0.0,5.908,5.529,3.5576609,1189.789696,0.0,0.0,9.783,8.716,3.92103,1148.04736,0.012288,0.0,7.28,6.316
1442632237000,9.3190767,1306.423296,2.383872,0.008192,49.104,46.271,2.8120706,1118.191616,0.0,0.0,6.563,5.287,3.0633392,1227.726848,2.185216,0.0,7.404,6.409,3.6125496,1188.63872,0.004096,0.0,7.783,8.598,3.0583936,1148.403712,0.0,0.0,7.082,6.436
1442632298000,8.5290826,1305.718784,2.514944,0.0,58.304,54.053,3.6712617,1118.171136,0.0,0.0,8.728,7.519,2.4207663,1228.57472,1.898496,0.0,6.118,5.571,3.1273109,1194.196992,0.0,0.0,8.7,7.496,3.0089661,1146.28608,0.0,0.0,7.64,6.822
1442632358000,9.0793356,1306.0096,3.21536,0.0,69.052,64.952,2.9922687,1116.811264,0.0,0.0,7.949,6.606,4.4447452,1228.673024,2.47808,0.0,10.442,9.01,3.1252607,1193.558016,0.004096,0.0,9.789,8.679,3.6339134,1148.583936,0.0,0.0,10.13,8.619
1442632418000,9.1194506,1311.592448,5.57056,0.0,59.145,55.178,3.2896539,1120.37888,0.0,0.0,8.308,7.24,2.1341106,1224.691712,1.155072,0.0,6.406,5.338,2.9049363,1195.671552,0.0,0.0,8.144,7.681,2.9907983,1147.854848,0.0,0.0,7.541,6.677
1442632478000,9.5727699,1312.743424,3.022848,0.0,69.52,64.58,3.4639668,1121.615872,0.0,0.0,7.765,7.412,3.4266945,1224.892416,1.851392,0.0,8.947,7.067,3.3447048,1196.269568,0.0,0.0,10.792,10.209,3.8129813,1148.100608,0.0,0.0,10.655,9.399
1442632538000,9.6444745,1311.41632,3.825664,0.0,62.637,59.624,4.1558877,1116.135424,0.0,0.0,9.655,8.298,2.8584768,1224.790016,2.148352,0.0,7.704,6.771,3.1997094,1197.355008,0.0,0.0,10.317,8.601,2.9005922,1150.144512,0.0,0.0,7.781,6.878
1442632598000,10.4591811,1297.34656,3.11296,0.0,70.504,66.354,4.0850236,1101.1072,0.0,0.0,9.945,8.201,2.7080297,1217.593344,2.295808,0.0,7.379,6.988,4.007034,1194.409984,0.0,0.0,8.495,7.186,4.0894174,1144.786944,0.0,0.0,13.102,10.911
1442632658000,9.8446961,1298.956288,3.510272,0.0,55.041,51.64,3.1463595,1098.514432,0.008192,0.0,6.21,5.742,3.93689,1219.592192,2.111488,0.0,6.757,6.144,3.5965846,1191.141376,0.0,0.0,9.094,8.193,4.2703813,1143.504896,0.0,0.0,8.444,7.688
1442632718000,9.1669391,1301.356544,2.476032,0.0,56.81,52.585,3.6612944,1099.3664,0.0,0.0,7.747,6.394,2.7312371,1219.780608,1.245184,0.0,5.103,3.993,4.0514769,1193.271296,0.0,0.0,9.026,8.252,2.8985924,1143.17312,0.0,0.0,9.304,7.698
1442632778000,9.4611946,1303.461888,4.272128,0.0,64.933,61.096,3.4822878,1100.374016,0.032768,0.0,9.224,8.111,3.1801621,1221.24288,1.564672,0.0,7.394,6.286,3.5924809,1195.307008,0.032768,0.0,9.866,8.368,3.2082395,1143.123968,0.0,0.0,9.484,8.727
1442632838000,9.2714369,1304.162304,3.346432,0.0,64.264,60.367,3.8702574,1105.420288,0.004096,0.0,8.581,7.118,2.4334351,1223.000064,1.1776,0.0,5.383,4.898,3.8445916,1196.83072,0.0,0.0,11.841,10.016,3.0055653,1147.383808,0.0,0.0,9.913,8.35
1442632898000,9.7258935,1305.186304,3.284992,0.0,69.598,64.773,3.5330482,1107.664896,0.004096,0.0,7.249,6.572,3.5898209,1222.373376,1.015808,0.0,7.724,6.066,3.4202858,1199.374336,0.0,0.0,11.022,9.526,3.8251096,1145.544704,0.0,0.0,11.27,10.46
1442632958000,7.276287,1305.870336,3.166208,0.0,59.462,55.348,2.6713611,1107.308544,0.0,0.0,6.959,6.948,3.0560669,1229.893632,2.045952,0.0,7.618,6.531,2.7927103,1196.855296,0.0,0.0,7.103,6.12,2.4627429,1141.653504,0.0,0.0,10.138,9.09
1442633018000,8.6649475,1304.014848,5.09952,0.0,58.92,55.238,2.5465707,1117.585408,0.0,0.0,6.349,5.874,3.0300023,1230.004224,2.183168,0.0,7.094,6.593,3.5114313,1194.831872,0.0,0.0,10.493,8.466,2.9713917,1143.230464,0.0,0.0,8.201,6.925
1442633078000,8.4215472,1305.812992,4.194304,0.0,53.75,51.265,2.9693305,1119.485952,0.0,0.0,7.945,6.93,2.9453104,1227.956224,1.255424,0.0,7.035,5.388,4.0538648,1192.935424,0.0,0.0,6.993,6.336,3.5573761,1143.312384,0.0,0.0,7.95,6.61
1442633138000,10.5179567,1304.981504,5.206016,0.0,66.138,62.753,4.1021479,1119.379456,0.0,0.0,8.473,6.758,3.8005629,1227.538432,1.724416,0.0,5.539,4.961,3.8474005,1198.6944,0.0,0.0,12.449,10.482,3.6891259,1144.082432,0.0,0.0,9.816,7.735
1442633198000,10.6193419,1290.186752,2.43712,0.0,58.068,54.491,3.8995361,1104.03584,0.0,0.0,8.355,7.271,4.2719672,1217.880064,1.939456,0.0,6.92,6.193,3.9347516,1185.951744,0.0,0.0,7.186,6.952,2.7953792,1143.857152,0.0,0.0,8.612,7.289
1442633258000,8.9515004,1291.03872,2.076672,0.0,69.78,64.97,4.1136485,1097.347072,0.086016,0.0,9.124,7.951,3.8771958,1220.9152,1.779712,0.0,9.57,7.258,3.1138863,1192.620032,0.0,0.0,10.545,9.329,2.921071,1154.154496,0.0,0.0,9.968,8.067
1442633319000,7.568995,1294.712832,3.018752,0.0,53.906,50.754,2.5714462,1098.186752,0.065536,0.0,7.68,6.53,2.7918484,1225.121792,2.179072,0.0,7.533,6.571,2.0990194,1194.000384,0.004096,0.0,5.497,5.069,2.8770186,1155.936256,0.0,0.0,8.555,8.333
1442633379000,9.7623235,1296.003072,4.29056,0.0,61.243,57.246,3.3460008,1101.877248,0.004096,0.0,7.971,6.518,3.4574386,1225.715712,1.35168,0.0,6.554,5.813,3.6020073,1194.766336,0.0,0.0,7.723,6.611,3.7209809,1155.964928,0.0,0.0,10.001,8.571
1442633439000,8.9166582,1295.679488,3.825664,0.0,48.332,45.666,3.1920231,1106.382848,0.0,0.0,5.894,5.698,2.3682005,1226.87488,1.777664,0.0,5.689,5.619,3.6875831,1193.94304,0.0,0.0,7.332,6.298,2.7748891,1158.017024,0.0,0.0,6.881,6.377
1442633499000,8.7683382,1296.461824,2.70336,0.0,52.861,49.533,3.2414185,1110.69184,0.0,0.0,7.275,6.674,3.2363948,1228.111872,2.701312,0.0,8.137,7.393,1.87434,1195.401216,0.0,0.0,7.225,6.506,2.8820154,1155.280896,0.012288,0.0,6.046,5.199
1442633559000,8.7050756,1302.3232,3.473408,0.0,57.733,53.689,2.9796082,1107.857408,0.0,0.0,6.748,6.287,3.5412399,1227.292672,2.635776,0.0,6.55,6.375,3.4817147,1198.424064,0.0,0.0,9.257,8.414,2.9987072,1153.798144,0.0,0.0,7.467,6.501
1442633619000,9.8921317,1301.712896,2.74432,0.0,65.859,61.47,3.1136554,1110.50752,0.0,0.0,8.248,6.915,3.4817786,1226.985472,1.859584,0.0,7.621,6.49,3.6721417,1198.383104,0.0,0.0,10.487,8.109,4.0307675,1154.011136,0.0,0.0,10.178,8.717
1442633679000,9.8320727,1302.745088,3.610624,0.0,65.507,61.373,4.0709865,1110.417408,0.0,0.0,9.354,9.202,3.0250699,1229.099008,1.302528,0.0,8.865,6.501,3.6022052,1198.854144,0.0,0.0,9.406,7.84,3.4045398,1155.182592,0.0,0.0,8.79,7.353
1442633739000,6.9557489,1302.478848,4.268032,0.0,48.578,45.822,2.6717159,1110.9376,0.004096,0.0,6.906,6.456,2.4115262,1233.248256,1.31072,0.0,5.963,5.224,3.2195773,1199.173632,0.0,0.0,7.554,5.94,2.0629322,1155.313664,0.0,0.0,6.725,4.637
1442633799000,7.6339646,1295.970304,2.449408,0.0,59.754,55.303,2.7838022,1103.925248,0.0,0.0,9.796,8.25,2.4056745,1225.412608,1.961984,0.0,8.287,7.88,2.367736,1195.421696,0.0,0.0,7.786,7.268,2.0776048,1153.060864,0.0,0.0,6.083,5.342
1442633859000,10.7671035,1289.469952,4.9152,0.0,75.306,69.766,4.2735991,1091.080192,0.0,0.0,10.406,8.674,4.0954951,1223.069696,2.093056,0.0,11.612,9.474,4.5351412,1183.277056,0.0,0.0,8.075,7.194,4.319508,1148.86656,0.0,0.0,9.391,8.24
1442633919000,14.6704919,1295.249408,4.001792,0.0,46.21,43.393,3.0635112,1087.26272,0.0,0.0,6.534,6.086,2.9120036,1223.057408,1.318912,0.0,6.237,5.322,2.2024608,1187.233792,0.0,0.0,6.949,6.357,2.1764099,1153.867776,0.0,0.0,6.029,5.245
1442633979000,9.1322477,1300.00896,4.009984,0.0,66.684,61.989,3.252501,1088.475136,0.0,0.0,9.257,7.667,2.7744911,1228.652544,2.039808,0.0,6.715,6.135,3.2215437,1188.847616,0.0,0.0,9.162,8.377,3.4565273,1154.49856,0.0,0.0,10.474,8.269
1442634039000,10.1785762,1299.546112,3.948544,0.0,58.273,54.675,2.9864284,1092.902912,0.0,0.0,6.999,6.167,4.9339801,1229.160448,2.46784,0.0,9.615,8.501,3.8062117,1194.897408,0.0,0.0,6.345,5.711,3.5667741,1155.493888,0.0,0.0,8.877,7.582
1442634099000,9.6709803,1298.878464,2.625536,0.0,64.616,60.216,2.4726705,1093.20192,0.0,0.0,8.104,6.622,2.9640188,1230.848,1.687552,0.0,6.981,5.949,4.1543821,1196.843008,0.0,0.0,10.455,9.773,3.8128091,1157.4272,0.0,0.0,9.623,8.233
1442634160000,6.8135206,1301.557248,3.66592,0.0,47.132,44.529,2.5515443,1097.842688,0.0,0.0,5.919,5.162,1.8241711,1231.507456,1.265664,0.0,5.073,4.337,3.0505457,1197.010944,0.012288,0.0,6.429,5.768,2.5063992,1155.54304,0.0,0.0,7.964,6.99
1442634221000,10.5447536,1302.71232,2.994176,0.0,56.887,53.154,4.1356035,1100.959744,0.0,0.0,6.839,6.297,3.3671869,1233.42848,1.990656,0.0,7.596,6.86,3.9659096,1201.094656,0.0,0.0,8.761,6.758,3.6530392,1157.824512,0.0,0.0,8.755,7.238
1442634281000,9.7258835,1301.03296,3.03104,0.0,62.946,58.207,3.9070905,1098.248192,0.0,0.0,8.517,7.112,2.8826552,1229.860864,1.216512,0.0,5.423,4.938,3.1492623,1198.65344,0.0,0.0,7.429,7.553,3.982613,1160.679424,0.0,0.0,10.794,10.33
1442634341000,8.5967239,1303.298048,4.251648,0.0,59.579,55.857,2.1367351,1098.79296,0.0,0.0,8.267,6.717,2.8096658,1230.04928,1.6384,0.0,7.713,6.436,3.0170404,1200.04608,0.0,0.0,9.806,7.982,3.1488128,1161.555968,0.0,0.0,7.837,7.304
1442634401000,8.3296599,1304.12544,3.872768,0.0,56.9,53.456,3.6860957,1096.41728,0.0,0.0,8.204,7.063,2.2842179,1227.833344,1.742848,0.0,5.479,5.12,3.1631298,1197.326336,0.0,0.0,8.253,7.25,3.4014126,1164.288,0.0,0.0,9.179,7.283
1442634461000,7.9365928,1292.218368,3.096576,0.0,56.037,51.35,2.6692071,1078.792192,0.0,0.0,6.165,6.202,2.0444433,1219.50208,1.396736,0.0,7.84,6.959,2.854952,1190.813696,0.0,0.0,8.062,6.961,3.1086187,1156.087808,0.0,0.0,8.52,7.627
1442634521000,9.5626915,1295.470592,4.870144,0.0,53.584,50.433,3.1144424,1081.032704,0.0,0.0,6.243,5.209,4.3832674,1219.325952,1.957888,0.0,8.305,7.024,3.7847298,1193.96352,0.0,0.0,7.739,6.038,3.8296381,1159.59808,0.0,0.0,8.039,7.286
1442634581000,8.3422993,1297.444864,4.282368,0.0,66.36,62.162,2.6903217,1082.822656,0.0,0.0,9.14,7.333,3.5833618,1220.64896,1.88416,0.0,9.943,7.677,3.3471306,1194.696704,0.0,0.0,9.021,7.53,3.4041836,1161.56416,0.0,0.0,8.967,8.108
1442634642000,7.8250581,1299.460096,2.445312,0.0,30.991,29.31,2.4150326,1090.035712,0.0,0.0,5.301,4.909,2.3944397,1230.127104,1.236992,0.0,3.344,3.421,2.5510639,1192.8576,0.0,0.0,3.663,3.713,2.8351663,1159.65952,0.0,0.0,6.024,5.633
1442634703000,8.2335122,1300.135936,3.006464,0.0,56.332,53.066,2.1850029,1090.482176,0.0,0.0,5.228,4.561,3.1357572,1230.508032,1.697792,0.0,6.839,5.418,3.8030069,1192.116224,0.0,0.0,10.283,8.574,3.212192,1161.74848,0.0,0.0,9.113,8.522
1442634763000,6.7977557,1301.676032,3.698688,0.0,49.513,45.982,3.4492229,1098.121216,0.024576,0.0,8.266,7.656,2.3758545,1231.925248,1.603584,0.0,7.514,6.137,2.2057084,1195.68384,0.0,0.0,6.208,5.185,1.5769293,1161.9328,0.0,0.0,5.062,5.3
1442634823000,10.1340662,1299.922944,2.633728,0.0,53.748,50.375,3.2951986,1096.241152,0.0,0.0,6.744,6.162,4.2292104,1231.52384,1.794048,0.0,7.824,6.989,3.7098902,1195.933696,0.0,0.0,6.928,6.724,3.793637,1161.588736,0.0,0.0,8.43,7.759
1442634883000,10.2313598,1295.773696,2.68288,0.0,55.849,52.718,2.8460931,1097.142272,0.032768,0.0,5.852,5.444,3.2347664,1231.314944,1.929216,0.0,7.045,6.968,3.9318206,1198.911488,0.0,0.0,9.178,7.639,3.3880741,1165.950976,0.0,0.0,8.412,6.861
1442634943000,8.1625054,1296.97792,2.289664,0.0,41.616,38.832,2.9951298,1098.71104,0.0,0.0,6.329,6.253,2.9251095,1231.31904,1.742848,0.0,4.886,5.382,4.1392905,1201.053696,0.782336,0.0,5.305,5.025,2.940527,1166.123008,0.032768,0.0,7.393,6.574
1442635004000,9.7187994,1299.972096,3.444736,0.0,59.297,54.295,3.8302276,1100.353536,0.0,0.0,8.598,7.89,3.9840439,1230.86848,2.013184,0.0,7.919,7.424,2.9608458,1201.307648,0.0,0.0,6.187,5.97,3.3794946,1166.696448,0.0,0.0,7.625,6.727
1442635064000,8.7508545,1291.636736,2.863104,0.0,63.224,59.1,3.0693628,1086.337024,0.0,0.0,7.968,7.026,3.3533948,1213.587456,2.49856,0.0,8.353,6.915,2.7744285,1188.888576,0.0,0.0,8.162,6.749,2.8459567,1156.001792,0.0,0.0,10.07,7.713
1442635124000,8.8335077,1292.77952,3.125248,0.0,60.763,56.967,3.9072944,1085.935616,0.0,0.0,9.89,7.922,3.4551389,1209.716736,2.588672,0.0,6.735,7.47,2.9836795,1191.235584,0.04096,0.0,6.737,7.03,2.8598401,1155.649536,0.0,0.0,9.927,8.274
1442635185000,8.0327154,1296.9984,2.732032,0.02048,57.186,53.456,3.8532841,1086.377984,0.057344,0.0,9.034,7.923,2.4516138,1208.348672,1.824768,0.0,5.941,5.892,2.7283361,1191.972864,0.032768,0.0,6.731,5.784,2.6133309,1155.7888,0.0,0.0,9.975,9.103
1442635245000,9.6427424,1301.454848,3.878912,0.012288,62.856,59.109,3.2339056,1087.791104,0.0,0.0,8.028,6.725,2.4574584,1208.680448,1.538048,0.0,5.354,4.98,4.8150905,1198.14144,0.0,0.0,9.739,8.81,4.0494387,1159.057408,0.0,0.0,11.701,9.995
1442635305000,8.5994601,1306.836992,2.306048,0.02048,60.195,56.08,2.301032,1089.155072,0.0,0.0,7.356,5.967,3.1175854,1210.109952,1.67936,0.0,6.99,6.371,4.0964544,1199.833088,0.0,0.0,9.872,8.953,2.8620353,1154.658304,0.0,0.0,8.469,7.388
1442635365000,8.2040634,1307.275264,2.967552,0.024576,55.873,51.924,2.5683474,1093.537792,0.0,0.0,6.802,6.206,2.9319364,1218.121728,2.00704,0.0,6.914,6.17,2.9866916,1198.16192,0.0,0.0,7.006,6.011,3.1625073,1154.41664,0.0,0.0,9.548,8.816
1442635425000,6.9658169,1306.124288,4.743168,0.012288,57.145,54.053,2.7581053,1093.328896,0.0,0.0,8.971,7.368,2.2007804,1216.692224,1.224704,0.0,6.358,5.698,2.4497788,1197.842432,0.0,0.0,8.986,7.757,3.0729171,1155.416064,0.0,0.0,8.556,7.642
1442635485000,8.4139184,1309.659136,1.908736,0.02048,53.768,50.216,3.8699964,1095.02464,0.0,0.0,7.261,6.598,3.5663823,1221.40672,1.810432,0.0,6.89,6.456,2.9923841,1200.72192,0.0,0.0,7.682,7.244,2.6759158,1155.735552,0.0,0.0,7.966,6.874
1442635545000,8.3259199,1306.259456,3.903488,0.02048,61.131,57.241,2.9400525,1089.29024,0.0,0.0,7.399,6.125,2.334152,1221.648384,1.646592,0.0,6.023,5.42,4.0729092,1196.457984,0.0,0.0,9.419,7.146,3.7445047,1154.912256,0.0,0.0,10.631,9.577
1442635605000,6.9567244,1309.30688,2.523136,0.024576,53.193,49.786,1.8380071,1097.40032,0.0,0.0,4.011,3.626,2.4659371,1220.673536,2.064384,0.0,7.061,6.171,2.7266072,1191.788544,0.028672,0.0,9.781,8.116,2.8798775,1155.428352,0.036864,0.0,8.869,7.899
1442635665000,7.540689,1300.185088,3.088384,0.016384,51.229,48.046,2.8565406,1087.107072,0.0,0.0,6.134,5.589,3.0967162,1214.357504,2.275328,0.0,7.207,6.296,2.2978512,1182.179328,0.032768,0.0,6.725,5.869,2.6085551,1149.853696,0.0,0.0,7.928,7.041
1442635725000,7.9218323,1298.010112,2.224128,0.024576,52.051,48.636,1.97554,1087.778816,0.0,0.0,6.762,5.715,2.4250452,1210.73664,1.386496,0.0,5.797,4.999,2.8195362,1187.254272,0.0,0.0,8.507,8.098,2.5239655,1152.9216,0.0,0.0,8.326,7.286
1442635785000,8.8239786,1303.683072,4.378624,0.016384,51.961,48.328,3.5287242,1091.100672,0.0,0.0,7.272,5.86,3.5037352,1213.202432,1.353728,0.0,6.524,5.147,3.0101111,1190.699008,0.032768,0.0,6.188,5.62,3.3355027,1156.636672,0.0,0.0,7.501,6.451
1442635845000,7.9977635,1307.36128,4.378624,0.02048,54.132,50.285,2.8269202,1091.772416,0.0,0.0,6.628,6.577,2.7947218,1216.905216,0.991232,0.0,6.012,5.486,2.5312691,1193.61536,0.0,0.0,5.806,5.626,3.5002396,1158.422528,0.0,0.0,10.135,8.503
1442635905000,7.991373,1310.605312,2.555904,0.008192,49.594,46.128,1.9243839,1093.533696,0.0,0.0,6.593,5.398,2.0567422,1215.729664,1.445888,0.0,5.785,5.07,3.6654584,1193.316352,0.0,0.0,8.115,6.969,2.5946762,1158.94272,0.0,0.0,6.593,5.608
1442635966000,9.5885752,1310.375936,4.112384,0.008192,62.178,58.067,3.9873262,1095.196672,0.0,0.0,8.751,7.415,3.0169511,1216.704512,1.411072,0.0,5.133,4.888,3.1517179,1193.684992,0.0,0.0,10.836,8.344,3.6451598,1159.467008,0.0,0.0,9.731,8.422
1442636027000,1.1368183,1343.320064,2.123776,0.0,0.358,0.099,0.2089807,1093.951488,0.0,0.0,0.583,0.628,0.2885251,1212.571648,0.0,0.0,0.496,0.61,0.314622,1191.3216,0.0,0.0,1.242,1.222,0.178371,1158.008832,0.0,0.0,0.643,0.764
1442636088000,6.1877296,1526.738944,4.15744,0.0,21.915,20.316,1.0645625,1082.220544,0.0,0.0,3.361,3.72,1.0977209,1204.506624,0.155648,0.33792,3.243,2.458,1.4461019,1187.725312,0.0,0.0,4.325,3.724,0.9695107,1149.554688,0.0,0.0,2.472,2.775
1442636148000,8.3929597,1686.417408,4.542464,0.0,55.575,51.458,2.7210523,1065.627648,0.0,0.0,7.797,6.884,2.9249313,1174.736896,1.62816,0.01024,6.47,6.265,3.0107587,1168.089088,0.0,0.0,6.906,6.505,3.1892157,1132.6464,0.0,0.0,8.267,7.397
1442636208000,7.0062282,1683.80416,4.206592,0.0,51.183,47.132,2.7181254,1067.180032,0.0,0.0,7.65,6.115,1.3368125,1180.155904,2.27328,0.01024,6.823,6.134,2.156692,1165.45536,0.0,0.0,6.423,6.614,2.4447934,1130.950656,0.0,0.0,7.057,5.888
1442636268000,7.3187416,1657.266176,3.50208,0.0,42.402,39.976,2.4987186,1053.683712,0.065536,0.0,6.528,5.903,2.0999635,1168.728064,2.154496,0.01024,5.685,4.963,2.1168001,1154.633728,0.0,0.0,5.194,4.334,3.0817188,1118.015488,0.0,0.0,8.007,6.26
1442636328000,8.6759406,1666.21184,3.837952,0.0,56.524,52.721,2.6080915,1054.683136,0.0,0.0,7.948,6.975,3.3704249,1168.576512,2.607104,0.006144,7.851,7.848,2.9837553,1154.68288,0.0,0.0,7.265,5.704,3.0948648,1116.659712,0.0,0.0,8.451,7.036
1442636388000,6.211741,1668.571136,4.534272,0.0,37.172,35.174,2.2731075,1050.001408,0.032768,0.0,4.403,4.21,4.7098839,1165.041664,1.613824,0.339968,4.156,3.002,1.7912248,1156.9152,0.0,0.0,5.284,5.242,2.6871204,1117.814784,0.0,0.0,6.799,6.891
1442636448000,8.9772042,1661.034496,3.198976,0.0,57.653,53.832,2.8947135,1053.315072,0.0,0.0,6.378,5.571,3.9860317,1174.679552,1.503232,0.012288,7.41,6.361,3.4568238,1159.880704,0.0,0.0,9.029,8.436,3.8994161,1115.291648,0.0,0.0,9.634,8.179
1442636508000,6.8510321,1663.967232,4.653056,0.0,44.832,42.213,2.2177981,1058.377728,0.0,0.0,5.733,5.539,2.4011803,1180.626944,1.78176,0.01024,5.992,5.383,2.4132132,1159.4752,0.0,0.0,5.822,5.167,2.4971354,1111.232512,0.0,0.0,6.462,6.663
1442636568000,9.9652102,1664.22528,3.641344,0.0,55.671,51.458,3.514344,1071.648768,0.004096,0.0,7.981,6.724,3.7961229,1177.165824,1.896448,0.012288,6.401,6.189,4.1366551,1157.951488,0.0,0.0,8.52,7.17,3.3393569,1112.072192,0.0,0.0,6.937,6.325
1442636628000,7.039159,1661.595648,3.158016,0.0,44.348,40.594,1.537965,1072.013312,0.0,0.0,5.947,5.694,2.4246672,1179.869184,2.05824,0.01024,5.925,5.301,2.0494017,1159.340032,0.0,0.0,4.558,4.23,3.3884324,1111.49056,0.0,0.0,7.505,6.728
1442636689000,8.5706058,1661.673472,3.09248,0.0,49.355,45.715,2.3763049,1073.717248,0.0,0.0,5.561,4.759,3.1598356,1177.985024,1.536,0.014336,7.263,5.942,3.9871739,1161.515008,0.0,0.0,7.63,7.656,3.3038652,1122.377728,0.0,0.0,6.479,6.113
1442636750000,8.4645972,1664.974848,5.025792,0.0,56.345,52.879,3.6297975,1073.508352,0.0,0.0,6.503,5.433,2.9076376,1180.93824,1.50528,0.0,6.902,6.341,3.0061955,1171.554304,0.0,0.0,8.586,7.542,2.725555,1116.291072,0.0,0.0,9.812,7.701
1442636810000,8.8588317,1667.60448,7.319552,0.0,60.101,56.788,2.4493694,1073.332224,0.0,0.0,5.467,4.501,3.8790889,1179.705344,1.562624,0.0,6.215,5.264,3.5197187,1169.645568,0.0,0.0,8.642,7.948,3.7211648,1119.248384,0.0,0.0,11.476,9.161
1442636871000,8.2787279,1652.953088,4.325376,0.0,48.512,45.697,3.5693466,1058.93888,0.0,0.0,7.282,6.105,2.8904053,1158.725632,1.318912,0.0,5.243,4.269,2.6402194,1162.850304,0.0,0.0,7.797,7.077,3.0456335,1111.30624,0.0,0.0,6.758,5.946
1442636931000,7.6641137,1655.693312,2.830336,0.0,54.055,50.663,4.0999036,1068.09344,0.0,0.0,7.158,6.903,3.1895988,1157.046272,1.339392,0.0,8.374,6.497,2.5701078,1164.029952,0.0,0.0,7.892,6.379,1.5974044,1102.548992,0.1024,0.0,6.588,5.389
1442636991000,9.1410581,1657.196544,3.182592,0.0,59.643,54.974,3.4609382,1067.851776,0.0,0.0,5.823,5.327,2.5788773,1168.207872,1.11616,0.0,7.379,5.414,3.2762412,1159.675904,0.032768,0.0,8.721,8.455,3.6491126,1103.716352,0.008192,0.0,9.966,8.717
1442637051000,8.267411,1661.599744,3.362816,0.0,54.059,50.849,3.2774725,1065.869312,0.0,0.0,7.507,6.889,2.9258429,1168.764928,1.507328,0.0,5.464,4.943,3.2921434,1163.751424,0.0,0.0,8.602,7.645,3.1508701,1106.317312,0.0,0.0,8.627,7.808
1442637111000,7.4215307,1662.660608,3.97312,0.0,49.701,46.759,3.1296676,1073.672192,0.032768,0.0,7.776,6.308,2.1734945,1168.990208,1.714176,0.0,6.798,5.703,2.1398453,1161.125888,0.0,0.0,6.022,5.32,2.8852058,1104.699392,0.0,0.0,6.889,6.384
1442637172000,6.8549417,1666.711552,4.23936,0.0,42.817,40.117,2.0784046,1074.679808,0.0,0.0,5.644,4.717,2.6871956,1167.72864,0.899072,0.0,3.985,3.695,2.3635799,1160.86784,0.0,0.0,6.995,6.545,2.648136,1105.158144,0.032768,0.0,7.124,6.887
1442637233000,9.2619566,1666.830336,4.898816,0.0,51.98,48.074,3.652774,1075.490816,0.0,0.0,6.549,5.648,4.0989867,1165.705216,1.609728,0.0,6.564,5.82,3.2250196,1164.468224,0.0,0.0,7.793,7.026,3.0586906,1104.330752,0.0,0.0,6.507,5.417
1442637293000,8.6708316,1668.870144,3.821568,0.0,53.356,49.783,3.602579,1075.77344,0.0,0.0,7.84,7.107,3.1587807,1168.011264,1.746944,0.0,7.44,6.28,3.2673171,1161.781248,0.0,0.0,6.182,5.549,2.8713863,1106.456576,0.0,0.0,6.849,5.706
1442637353000,7.6571982,1670.504448,4.370432,0.0,48.712,46.638,3.2461701,1077.395456,0.0,0.0,7.356,6.21,2.8438879,1169.850368,1.656832,0.0,6.467,5.361,2.7361913,1168.478208,0.0,0.0,5.801,5.126,2.6939605,1106.599936,0.0,0.0,8.67,7.248
1442637413000,8.5053159,1668.145152,2.33472,0.0,41.95,39.823,2.8163561,1080.38144,0.0,0.0,6.194,6.386,3.3942521,1172.41856,1.55648,0.0,5.568,5.044,2.5510413,1169.559552,0.0,0.0,6.162,5.723,3.0927304,1104.748544,0.032768,0.0,5.37,5.251
1442637473000,7.7930659,1655.271424,4.128768,0.0,46.259,43.734,3.7745814,1061.896192,0.0,0.0,7.643,6.316,2.6029517,1166.794752,1.542144,0.0,4.576,4.114,3.0056482,1160.33536,0.032768,0.0,8.137,7.008,3.183664,1093.36576,0.008192,0.0,6.316,6.242
1442637534000,8.5189442,1652.985856,3.518464,0.0,51.975,48.976,3.0339471,1071.14496,0.0,0.0,7.784,6.584,2.5650786,1165.836288,1.792,0.0,5.367,4.951,2.7160868,1161.777152,0.0,0.0,7.069,6.775,3.1228324,1097.551872,0.0,0.0,8.539,7.296
1442637594000,8.0846674,1661.186048,3.698688,0.0,52.788,49.519,2.5695989,1073.106944,0.0,0.0,5.351,4.865,2.6202232,1168.654336,2.136064,0.0,5.981,5.323,2.9702142,1156.755456,0.0,0.0,7.388,7.186,3.2508408,1094.930432,0.0,0.0,8.854,8.039
1442637654000,7.5981017,1658.85952,3.35872,0.0,50.003,46.98,2.6040928,1073.614848,0.0,0.0,7.639,6.891,1.6005705,1168.908288,1.654784,0.0,4.881,4.922,2.777572,1165.524992,0.0,0.0,5.932,5.85,2.768134,1095.131136,0.0,0.0,8.702,7.88
1442637714000,8.7994963,1660.29312,3.149824,0.0,54.01,50.872,2.4669329,1073.811456,0.0,0.0,6.395,5.364,3.5160403,1167.83104,2.125824,0.0,6.529,5.678,3.2462823,1167.511552,0.0,0.0,7.886,7.328,3.6675842,1096.54016,0.0,0.0,9.097,8.524
1442637774000,7.4362225,1664.667648,3.231744,0.0,47.992,44.614,2.382181,1075.85536,0.0,0.0,7.322,7.557,2.6823776,1173.77024,2.408448,0.0,5.162,5.057,2.3897495,1166.266368,0.0,0.0,7.412,6.489,2.1359348,1094.51264,0.0,0.0,5.668,5.081
1442637834000,8.5925649,1671.430144,2.936832,0.0,53.001,49.461,3.0690039,1077.895168,0.0,0.0,5.875,5.879,3.3571082,1172.885504,1.964032,0.0,7.823,6.602,2.9303455,1167.917056,0.032768,0.0,6.862,6.486,3.3301622,1094.868992,0.0,0.0,7.805,7.029
1442637894000,10.2523213,1673.981952,4.235264,0.0,62.458,58.626,4.0981949,1076.441088,0.0,0.0,7.357,7.582,3.5467031,1176.465408,1.030144,0.0,7.031,5.446,3.5581713,1170.432,0.0,0.0,8.665,6.674,3.7414039,1095.094272,0.008192,0.0,11.151,9.363
1442637954000,8.2113735,1668.096,4.182016,0.0,47.672,45.064,2.9263763,1076.6336,0.0,0.0,7.659,6.444,2.7082489,1179.451392,1.550336,0.0,5.343,5.027,2.8155823,1172.627456,0.0,0.0,6.572,5.578,2.4617353,1094.889472,0.0,0.0,7.21,6.021
1442638014000,8.180169,1670.115328,4.99712,0.0,61.854,58.925,3.2420353,1073.963008,0.0,0.0,7.61,5.969,2.9748858,1177.452544,1.585152,0.0,7.408,6.188,3.044805,1171.57888,0.0,0.0,9.086,6.797,2.8702898,1096.8064,0.0,0.0,9.889,9.015
1442638075000,5.772501,1652.03968,0.888832,0.012288,16.661,15.914,2.2648299,1051.963392,0.0,0.0,2.875,2.382,3.0395332,1154.576384,1.077248,0.0,4.331,3.927,1.6459108,1153.232896,0.0,0.0,1.989,1.963,2.0081963,1084.452864,0.0,0.0,2.987,2.989
1442638135000,9.7254939,1660.096512,2.637824,0.024576,60.184,56.168,4.5029381,1062.621184,0.0,0.0,9.091,8.348,3.4493833,1165.484032,1.845248,0.0,6.823,6.472,3.2902539,1159.82336,0.0,0.0,7.644,7.255,3.4601735,1097.3184,0.0,0.0,9.543,8.191
1442638195000,8.3380501,1663.561728,4.415488,0.02048,49.201,46.073,2.5898169,1065.213952,0.0,0.0,7.628,6.785,2.8188856,1170.284544,1.624064,0.0,5.753,5.751,3.5059631,1158.811648,0.0,0.0,6.553,5.807,3.2440437,1091.444736,0.0,0.0,6.012,5.974
1442638255000,8.5074949,1661.362176,3.944448,0.016384,54.015,50.804,2.5776955,1064.292352,0.0,0.0,8.283,7.045,2.2989202,1170.632704,1.820672,0.0,5.738,5.24,3.2051962,1158.53312,0.0,0.0,7.367,6.161,2.937983,1091.362816,0.0,0.0,9.55,8.234
1442638315000,8.1529664,1661.89056,4.804608,0.016384,46.477,43.933,1.9333268,1064.783872,0.0,0.0,5.385,4.965,2.5148795,1178.857472,2.234368,0.0,5.549,5.635,2.9229383,1157.779456,0.0,0.0,6.794,5.609,3.0901802,1099.780096,0.0,0.0,6.986,6.236
1442638376000,9.3367666,1660.608512,3.1744,0.028672,58.826,55.185,2.9250207,1073.836032,0.0,0.0,7.232,6.722,4.370754,1174.532096,2.12992,0.0,9.003,6.741,4.3006014,1161.117696,0.0,0.0,9.117,7.562,3.0214667,1096.41728,0.0,0.0,7.929,7.146
1442638437000,8.9728739,1660.547072,3.268608,0.0,60.378,55.969,3.5940156,1073.78688,0.0,0.0,7.558,7.239,3.4606804,1179.21792,2.37568,0.0,7.88,7.528,3.3010051,1165.316096,0.0,0.0,8.692,7.148,3.0928548,1098.129408,0.0,0.0,8.299,6.517
1442638497000,8.3468394,1662.574592,4.17792,0.0,48.685,45.738,3.3704647,1071.374336,0.0,0.0,7.625,5.83,3.2187373,1183.182848,2.121728,0.0,5.189,5.022,6.2911414,1163.780096,0.0,0.0,6.135,5.568,3.4889369,1102.938112,0.032768,0.0,6.904,6.822
1442638557000,9.5860369,1663.123456,2.691072,0.0,55.988,52.264,4.3150492,1075.26144,0.0,0.0,8.244,6.969,3.5099501,1177.612288,1.640448,0.0,6.141,5.296,3.1332876,1169.14176,0.049152,0.0,8.446,7.717,3.1455425,1104.392192,0.0,0.0,7.792,6.805
1442638617000,8.1040529,1670.88128,4.952064,0.0,51.236,47.737,2.6126261,1073.00864,0.0,0.0,6.341,5.549,3.6909045,1180.008448,2.504704,0.0,7.812,7.301,3.6285773,1169.973248,0.008192,0.0,7.867,6.273,2.708278,1099.665408,0.0,0.0,5.436,4.868
1442638677000,6.5708729,1666.555904,2.535424,0.0,59.321,54.691,2.4710864,1070.956544,0.0,0.0,8.691,7.316,2.1594885,1169.698816,1.601536,0.0,6.648,5.698,3.2328845,1171.124224,0.004096,0.0,8.559,7.426,1.799686,1099.337728,0.0,0.0,8.113,7.818
1442638737000,7.7617582,1649.659904,4.100096,0.0,43.688,40.492,3.9654736,1058.779136,0.032768,0.0,7.8,6.826,2.3448129,1166.80704,1.374208,0.0,4.913,4.384,2.9812876,1164.296192,0.0,0.0,6.171,5.874,1.9398589,1092.644864,0.0,0.0,5.244,4.484
1442638797000,9.3956925,1652.240384,2.306048,0.0,62.104,57.651,3.9654792,1061.105664,0.0,0.0,8.486,7.205,3.6404837,1172.201472,2.320384,0.0,9.301,7.778,5.6607078,1160.72448,0.032768,0.0,8.867,7.263,2.8969875,1087.520768,0.04096,0.0,8.237,7.519
1442638858000,7.8458281,1649.70496,2.977792,0.0,52.18,48.869,3.2072044,1063.968768,0.0,0.0,7.615,6.481,2.3649369,1173.065728,1.759232,0.012288,6.433,5.363,3.3410416,1163.882496,0.0,0.0,8.077,7.538,2.2725325,1089.572864,0.0,0.0,7.511,6.717
1442638919000,7.722098,1665.8432,1.069056,0.0,41.746,38.884,18.1321151,1078.341632,0.167936,0.0,5.849,5.327,2.7250015,1164.1856,1.5872,0.008192,5.351,4.692,3.1001888,1162.641408,0.0,0.0,5.724,5.453,2.8697425,1089.736704,0.0,0.0,6.78,6.364
1442638980000,6.6402903,1656.844288,3.719168,0.0,58.389,54.739,2.6097097,1077.538816,0.0,0.0,8.032,7.174,2.1254104,1182.86336,2.29376,0.0,7.029,6.259,97.5373105,1181.069312,0.004096,0.0,8.142,7.415,2.2596283,1087.352832,0.0,0.0,9.226,7.348
1442639040000,9.328339,1661.251584,3.584,0.0,68.131,63.581,3.4376829,1076.240384,0.0,0.0,8.504,6.384,3.5740303,1167.388672,2.256896,0.0,8.008,7.509,3.450215,1172.324352,0.0,0.0,9.945,8.524,3.2347729,1095.68,0.0,0.0,10.572,8.927
1442639100000,7.5104421,1659.584512,2.588672,0.0,54.212,50.427,2.8081964,1080.537088,0.0,0.0,5.951,5.237,2.9524789,1167.982592,1.8944,0.0,7.2,6.555,3.2123555,1172.148224,0.0,0.0,8.742,7.56,2.8563336,1091.608576,0.0,0.0,7.628,6.878
1442639160000,8.9389552,1659.41248,4.050944,0.0,69.022,64.432,4.5333477,1077.219328,0.032768,0.0,9.855,7.474,3.6742069,1171.202048,2.142208,0.0,8.966,7.572,2.8465066,1171.43552,0.032768,0.0,9.087,7.365,2.9347195,1092.8128,0.008192,0.0,9.973,8.214
1442639220000,9.6532217,1660.178432,4.100096,0.0,66.575,63.511,3.5009166,1075.879936,0.0,0.0,7.224,5.424,2.9288699,1166.761984,1.517568,0.0,7.789,6.483,3.6177935,1170.276352,0.0,0.0,10.532,8.99,3.7850188,1100.480512,0.0,0.0,11.869,9.133
1442639281000,9.7350756,1661.435904,3.129344,0.0,57.152,53.875,3.5773264,1085.181952,0.0,0.0,6.907,7.028,4.0595342,1167.62624,2.207744,0.0,6.905,5.906,3.7431109,1171.316736,0.0,0.0,8.932,6.454,3.2169268,1101.770752,0.0,0.0,8.778,7.034
1442639342000,7.6145825,1630.756864,1.86368,0.0,50.077,46.357,1.9775129,1075.359744,0.0,0.0,5.878,5.164,2.3972537,1155.678208,1.78176,0.0,5.896,5.305,3.0775214,1163.636736,0.0,0.0,8.085,7.319,2.4832573,1099.53024,0.0,0.0,7.926,6.583
1442639402000,4.9195906,1634.963456,3.54304,0.0,38.765,36.636,2.2102383,1074.044928,0.024576,0.0,5.526,5.028,1.6028387,1155.362816,1.1776,0.0,4.64,4.488,1.6786947,1171.075072,0.0,0.0,5.186,4.584,1.9250788,1096.302592,0.0,0.0,7.577,6.24
1442639462000,9.1586169,1633.910784,2.736128,0.0,55.19,51.629,2.7325671,1077.727232,0.0,0.0,5.249,5.136,2.476073,1158.180864,1.236992,0.0,5.645,4.959,3.8731509,1171.038208,0.0,0.0,9.307,8.175,3.5214021,1095.380992,0.0,0.0,10.502,9.003
1442639522000,6.7791166,1637.502976,3.493888,0.0,47.46,44.627,2.7502724,1080.041472,0.04096,0.0,8.128,7.538,1.6060463,1152.319488,1.200128,0.0,5.491,4.856,1.3879608,1173.282816,0.0,0.0,4.221,3.716,2.5526351,1097.859072,0.0,0.0,8.102,7.726
1442639582000,5.9660567,1637.101568,3.428352,0.0,48.508,45.131,2.0929234,1079.328768,0.0,0.0,6.792,6.294,2.1970282,1153.544192,2.787328,0.0,5.821,6.146,1.3890485,1172.025344,0.0,0.0,5.877,4.489,2.6249335,1102.000128,0.0,0.0,7.028,6.293
1442639642000,7.3319752,1638.473728,2.37568,0.0,46.387,43.556,3.0693124,1084.780544,0.032768,0.0,6.578,6.207,2.6461601,1150.230528,1.564672,0.0,5.613,4.849,3.0229562,1175.011328,0.0,0.0,8.68,8.001,2.3568644,1100.345344,0.0,0.0,5.245,4.489
1442639702000,8.0177467,1640.095744,2.6624,0.0,52.408,49.376,2.1316144,1081.380864,0.0,0.0,6.925,5.706,3.0446238,1153.040384,2.076672,0.0,6.699,6.14,2.8934562,1178.570752,0.0,0.0,6.625,5.897,3.5805434,1105.199104,0.032768,0.0,9.955,8.702
1442639763000,10.5161914,1641.55392,3.21536,0.0,61.495,57.368,4.0995327,1083.826176,0.0,0.0,9.479,9.244,3.8621308,1153.26976,1.583104,0.0,5.745,5.36,3.8013169,1177.645056,0.0,0.0,8.812,7.337,4.1509219,1106.681856,0.0,0.0,8.671,7.28
1442639824000,9.1510606,1641.73824,2.912256,0.0,56.249,52.171,3.4177876,1084.928,0.0,0.0,8.542,7.021,3.385836,1152.536576,2.482176,0.0,6.312,5.793,3.4807207,1178.402816,0.0,0.0,6.703,6.242,3.6385841,1108.93056,0.0,0.0,9.69,8.498
1442639884000,9.8380961,1643.614208,3.723264,0.0,73.021,68.237,3.1024418,1083.76064,0.0,0.0,8.443,6.724,3.0458428,1156.534272,1.212416,0.0,7.58,6.205,4.3251739,1178.9312,0.0,0.0,11.405,9.197,3.3947885,1110.327296,0.0,0.0,12.923,10.75
1442639944000,11.0319265,1601.097728,2.920448,0.0,69.838,65.521,4.036197,1071.80032,0.0,0.0,9.109,7.668,4.4499329,1149.186048,2.033664,0.0,8.644,7.273,5.0551756,1166.66368,0.0,0.0,10.863,8.166,4.1805865,1095.061504,0.0,0.0,10.923,9.759
1442640004000,9.4988026,1606.909952,3.375104,0.0,58.918,54.765,3.3876111,1075.318784,0.0,0.0,9.069,7.788,3.2046888,1156.21888,1.906688,0.0,6.683,5.887,2.910987,1163.73504,0.004096,0.0,7.906,7.112,3.4225798,1095.020544,0.0,0.0,8.544,7.718
1442640064000,10.320338,1602.748416,4.481024,0.0,56.134,53.103,3.8909673,1074.188288,0.0,0.0,7.991,6.427,3.7412726,1159.118848,2.504704,0.0,6.847,6.454,3.2091005,1166.66368,0.0,0.0,7.809,6.878,3.1211273,1099.20256,0.0,0.0,8.416,6.579
1442640124000,9.6085412,1598.877696,2.37568,0.0,56.972,53.801,3.1649565,1078.181888,0.0,0.0,7.847,7.383,3.3190882,1159.528448,2.236416,0.0,6.651,6.536,4.2126098,1170.239488,0.0,0.0,8.381,7.143,3.1233251,1101.058048,0.0,0.0,8.724,7.924
1442640185000,9.1801436,1592.963072,3.371008,0.024576,54.12,50.805,3.6095681,1087.799296,0.0,0.0,9.633,7.46,2.7555475,1155.97312,1.456128,0.0,5.755,5.179,3.3887838,1171.259392,0.0,0.0,7.617,7.135,2.6336309,1102.938112,0.032768,0.0,7.362,6.275
1442640245000,7.682168,1594.814464,4.255744,0.008192,52.604,49.82,2.1909514,1090.084864,0.0,0.0,6.529,5.604,3.0964551,1169.895424,2.353152,0.0,7.144,6.808,2.2009792,1175.322624,0.0,0.0,6.899,6.351,3.0954076,1114.902528,0.0,0.0,8.404,7.639
1442640305000,9.2495118,1597.448192,3.346432,0.02048,57.801,53.704,3.6933421,1088.454656,0.0,0.0,9.232,7.176,3.0518846,1168.67072,1.900544,0.0,6.163,5.501,3.8477648,1172.66432,0.0,0.0,7.258,5.956,3.6514533,1114.804224,0.032768,0.0,9.627,8.863
1442640365000,9.909501,1594.73664,1.581056,0.02048,67.493,62.63,4.1534704,1091.067904,0.0,0.0,9.934,8.232,3.2616168,1167.601664,1.519616,0.0,7.085,6.593,3.6347853,1182.429184,0.008192,0.0,10.699,10.003,4.2139668,1111.146496,0.0,0.0,10.414,8.385
1442640425000,8.7474238,1599.729664,3.862528,0.016384,46.833,44.676,3.2441055,1085.108224,0.0,0.0,5.713,4.94,3.6699304,1163.300864,1.613824,0.0,7.185,6.203,3.0920485,1179.172864,0.0,0.0,6.41,6.373,3.3204984,1135.509504,0.0,0.0,7.958,7.107
1442640485000,8.0269207,1594.51136,2.174976,0.012288,49.306,46.449,3.4168034,1089.007616,0.0,0.0,6.74,6.481,2.6169634,1161.99424,1.318912,0.0,5.348,5.008,3.2951637,1178.128384,0.0,0.0,8.102,6.773,3.2008791,1134.440448,0.0,0.0,7.301,6.464
1442640545000,7.8092468,1548.828672,4.890624,0.024576,63.083,59.509,3.4832939,1073.905664,0.0,0.0,10.013,8.291,2.6557732,1145.974784,0.944128,0.0,6.427,4.681,3.3101209,1175.728128,0.0,0.0,8.231,7.059,2.7849369,1126.539264,0.0,0.0,9.908,8.353
1442640605000,9.1549621,1549.33248,2.51904,0.028672,58.102,54.075,4.006034,1078.534144,0.0,0.0,8.195,7.547,2.4403805,1148.37504,1.169408,0.0,6.65,5.157,2.8016566,1175.207936,0.0,0.0,7.003,6.312,3.1547408,1129.340928,0.032768,0.0,9.565,8.053
1442640665000,5.969717,1552.211968,2.043904,0.016384,44.625,41.563,1.5989398,1082.957824,0.0,0.0,6.697,5.738,2.6103535,1154.904064,2.594816,0.0,6.065,5.771,1.8244318,1176.055808,0.0,0.0,6.662,5.647,2.1066688,1132.60544,0.0,0.0,5.694,4.722
1442640725000,7.6003904,1556.062208,3.64544,0.02048,45.618,42.576,3.0978146,1085.227008,0.0,0.0,7.867,7.368,0.9792258,1146.1632,0.681984,0.0,3.323,3.101,2.2919345,1172.312064,0.0,0.0,5.784,4.662,3.7680896,1138.589696,0.0,0.0,8.655,7.868
1442640785000,8.4829882,1553.436672,2.736128,0.016384,52.19,48.749,2.4937903,1085.390848,0.032768,0.0,6.494,5.767,3.217209,1147.056128,1.49504,0.0,5.566,4.801,3.7137208,1172.217856,0.0,0.0,9.085,7.556,2.5079856,1139.027968,0.0,0.0,8.825,7.736
1442640845000,8.837587,1553.104896,3.559424,0.02048,52.206,49.471,2.8729113,1088.208896,0.0,0.0,7.588,6.421,2.4618066,1148.268544,1.574912,0.0,5.778,4.879,3.2694343,1175.437312,0.0,0.0,7.487,6.687,3.6592076,1136.88576,0.0,0.0,9.47,7.715
1442640905000,8.6918741,1555.68128,3.108864,0.02048,54.687,50.577,2.8577341,1088.512,0.008192,0.0,6.548,5.548,3.3377255,1151.217664,2.168832,0.0,6.134,5.791,3.525422,1181.261824,0.0,0.0,7.138,6.222,3.3676024,1141.71904,0.0,0.0,9.036,7.73
1442640965000,7.414982,1557.282816,3.760128,0.008192,46.91,44.22,2.9237404,1090.3552,0.032768,0.0,6.341,5.307,2.7690464,1153.343488,1.552384,0.0,5.749,5.747,2.6416806,1182.027776,0.0,0.0,5.359,4.93,2.9736004,1142.894592,0.0,0.0,8.824,7.223
1442641025000,7.1696094,1556.62336,2.953216,0.016384,46.46,43.884,2.5126059,1090.371584,0.0,0.0,5.615,4.766,2.6062209,1154.60096,1.01376,0.0,6.912,5.715,2.2382345,1181.147136,0.0,0.0,6.913,6.054,2.7875215,1147.604992,0.0,0.0,7.045,6.297
1442641085000,8.6099829,1558.67136,5.259264,0.02048,59.405,55.55,3.7340005,1092.145152,0.0,0.0,7.574,6.229,2.4642103,1155.260416,1.134592,0.0,5.584,4.979,3.3276446,1183.985664,0.0,0.0,9.276,7.646,2.9058348,1148.280832,0.0,0.0,8.774,7.526
1442641145000,8.2453547,1517.142016,2.658304,0.012288,44.085,41.131,2.9902728,1081.257984,0.0,0.0,6.673,5.915,2.4417719,1142.345728,1.882112,0.0,4.719,5.084,2.3882763,1181.437952,0.0,0.0,5.795,5.326,2.6004719,1135.988736,0.028672,0.0,7.025,6.416
1442641205000,8.0026728,1515.941888,3.25632,0.012288,50.336,47.22,3.2467172,1084.715008,0.008192,0.0,7.594,7.195,2.2521443,1154.1504,0.94208,0.0,5.147,4.155,2.70979,1184.956416,0.0,0.0,7.942,6.126,2.486649,1138.286592,0.0,0.0,7.165,6.683
1442641265000,8.5706218,1518.542848,4.13696,0.012288,57.342,53.661,2.3781075,1087.54944,0.0,0.0,6.547,5.209,3.4744846,1160.237056,2.43712,0.0,7.06,6.157,3.6300705,1184.374784,0.0,0.0,9.131,6.864,3.334945,1138.561024,0.0,0.0,9.33,7.545
1442641325000,8.5121538,1519.90272,2.588672,0.024576,47.194,43.676,2.9604807,1090.142208,0.0,0.0,6.373,5.886,2.6479458,1162.354688,1.921024,0.0,6.436,5.653,3.3181451,1184.514048,0.032768,0.0,5.081,4.941,3.4543225,1142.222848,0.0,0.0,7.651,7.17
1442641385000,7.9393238,1516.244992,2.818048,0.004096,53.364,49.727,2.5228738,1086.672896,0.0,0.0,5.022,4.694,2.4480738,1159.7824,1.574912,0.0,6.167,5.023,2.3017353,1178.558464,0.0,0.0,8.737,6.708,3.3184756,1140.71552,0.0,0.0,9.207,8.428
1442641446000,7.8192311,1520.082944,3.50208,0.012288,54.979,51.288,2.7808306,1089.404928,0.04096,0.0,7.855,6.943,2.4089935,1159.213056,1.21856,0.0,6.587,5.841,2.6786966,1179.222016,0.0,0.0,7.113,6.497,3.0707235,1140.113408,0.0,0.0,9.111,8.549
1442641506000,8.1718501,1517.744128,3.067904,0.02048,50.952,47.794,3.4060063,1092.38272,0.0,0.0,6.41,5.406,3.0705209,1167.147008,2.043904,0.0,7.13,6.533,2.8906925,1185.40288,0.0,0.0,7.084,6.787,3.3105114,1145.942016,0.0,0.0,6.786,6.524
1442641566000,9.8767467,1518.12096,4.313088,0.02048,60.419,56.464,3.3544614,1092.97664,0.0,0.0,6.682,6.723,3.6732647,1166.979072,1.183744,0.0,6.44,5.334,3.8338443,1188.82304,0.0,0.0,10.188,8.318,3.6534243,1142.74304,0.0,0.0,9.672,7.054
1442641626000,8.70861,1519.34976,4.97664,0.024576,49.214,45.827,3.5240929,1095.122944,0.0,0.0,6.105,6.358,2.3335561,1166.897152,1.60768,0.0,4.532,4.63,2.9196934,1191.538688,0.0,0.0,7.37,6.608,3.3532901,1144.479744,0.0,0.0,7.423,5.74
1442641686000,8.4942686,1513.259008,3.784704,0.024576,51.097,48.197,3.0742956,1094.959104,0.0,0.0,6.837,6.135,3.4786834,1168.723968,1.236992,0.0,5.496,4.85,3.4630757,1191.518208,0.0,0.0,8.276,6.524,3.4698386,1147.174912,0.0,0.0,8.2,7.0
1442641746000,8.8202571,1476.255744,2.244608,0.02048,45.565,42.601,2.7244449,1088.02048,0.0,0.0,5.517,4.9,2.8685311,1159.131136,2.015232,0.0,4.628,4.921,3.1118302,1190.731776,0.0,0.0,8.341,7.224,3.5576038,1131.532288,0.0,0.0,6.65,5.79
1442641806000,8.1252396,1475.002368,2.838528,0.02048,66.494,62.235,2.5136195,1087.164416,0.0,0.0,8.842,7.289,3.6843143,1160.339456,2.240512,0.0,9.344,8.266,2.8582756,1194.078208,0.0,0.0,10.739,9.027,2.8177154,1128.554496,0.0,0.0,9.164,7.05
1442641867000,8.8155116,1475.50208,2.019328,0.0,44.6,41.829,2.9205408,1095.24992,0.0,0.0,5.368,5.211,2.6433976,1165.377536,1.150976,0.0,5.695,4.936,3.0179114,1199.2064,0.0,0.0,7.435,6.592,2.7563467,1136.992256,0.0,0.0,6.619,6.153
1442641927000,8.3156465,1474.523136,5.505024,0.0,59.621,56.299,3.1275471,1093.55008,0.0,0.0,7.094,6.483,3.9615403,1164.6976,1.25952,0.0,7.899,6.104,3.0445475,1196.552192,0.0,0.0,8.373,6.499,3.3614028,1134.809088,0.0,0.0,10.163,8.831
1442641987000,8.0882165,1471.561728,2.617344,0.0,59.078,54.845,2.5048937,1098.215424,0.004096,0.0,7.487,7.394,3.1411609,1170.604032,1.712128,0.0,6.9,6.105,3.0497957,1200.525312,0.0,0.0,8.798,7.87,3.364107,1137.471488,0.0,0.0,8.329,6.863
1442642047000,8.8885763,1472.413696,2.019328,0.0,61.369,57.103,3.2671504,1100.312576,0.032768,0.0,7.811,6.165,3.4739838,1169.027072,1.308672,0.0,7.909,7.001,2.5746315,1200.508928,0.032768,0.0,8.211,7.661,3.942749,1140.072448,0.0,0.0,9.401,8.186
1442642107000,9.3333499,1470.681088,3.026944,0.0,53.686,50.495,3.9295151,1100.648448,0.0,0.0,6.826,6.532,3.6594862,1173.164032,1.421312,0.0,6.693,5.396,3.1542118,1204.0192,0.0,0.0,8.925,7.129,3.3734355,1139.257344,0.0,0.0,8.347,6.976
1442642168000,7.9949861,1466.032128,3.121152,0.0,50.429,47.143,3.8039662,1102.053376,0.0,0.0,7.474,6.92,1.9025109,1174.28224,1.122304,0.006144,6.432,5.406,2.9719401,1205.874688,0.0,0.0,7.118,5.841,3.2974681,1140.703232,0.032768,0.0,7.052,6.252
1442642228000,9.1519452,1461.940224,4.653056,0.0,57.834,54.271,3.9609547,1101.533184,0.0,0.0,9.128,8.22,2.8381904,1173.745664,1.038336,0.008192,6.586,5.427,3.2305977,1209.4464,0.0,0.0,6.922,5.505,2.9714819,1141.927936,0.0,0.0,8.051,7.447
1442642288000,8.4457173,1451.393024,3.055616,0.0,59.254,55.149,4.1300898,1102.516224,0.0,0.0,8.005,7.018,2.9235661,1174.634496,1.247232,0.01024,7.637,6.265,2.9942144,1212.338176,0.0,0.0,7.329,6.47,2.9834068,1141.293056,0.0,0.0,9.377,8.323
1442642348000,7.2654997,1427.615744,2.74432,0.0,45.471,42.453,2.842853,1093.033984,0.0,0.0,5.645,5.69,2.6179708,1162.022912,2.256896,0.006144,5.721,5.365,3.181263,1198.170112,0.032768,0.0,5.561,5.447,2.5449557,1127.276544,0.0,0.0,8.274,6.375
1442642408000,10.0979892,1422.843904,3.577856,0.0,57.395,54.25,4.3683948,1097.50272,0.0,0.0,8.174,6.766,3.7670443,1164.709888,1.396736,0.012288,7.364,6.083,3.6286145,1206.685696,0.0,0.0,7.111,6.955,3.5299136,1127.890944,0.0,0.0,9.157,7.424
1442642468000,7.4913817,1423.233024,3.006464,0.0,44.066,41.53,2.7549663,1096.794112,0.0,0.0,6.061,5.631,2.7126469,1167.806464,1.81248,0.01024,6.146,5.356,2.9906125,1211.14624,0.0,0.0,6.903,5.341,2.872254,1133.432832,0.0,0.0,6.318,5.603
1442642528000,8.0669227,1418.26048,1.931264,0.0,30.288,28.489,3.5033563,1101.84448,0.0,0.0,4.484,4.456,2.2905968,1171.324928,1.363968,0.012288,3.492,3.407,2.1168306,1210.585088,0.0,0.0,4.433,3.899,2.8884624,1132.531712,0.0,0.0,5.086,4.348
1442642588000,7.7142048,1407.67232,4.243456,0.0,53.59,50.781,3.0795141,1100.06272,0.0,0.0,8.547,7.987,3.2067661,1172.611072,2.01728,0.01024,8.161,7.192,2.5450562,1213.0304,0.0,0.0,5.938,5.071,1.9696285,1132.449792,0.0,0.0,6.915,6.975
1442642648000,8.7114738,1403.416576,4.190208,0.0,60.314,56.168,3.3001224,1101.324288,0.0,0.0,7.164,6.285,2.1167824,1169.477632,1.265664,0.01024,7.407,6.416,2.6796778,1210.646528,0.0,0.0,7.846,6.475,3.4525384,1130.553344,0.0,0.0,8.872,8.473
1442642709000,9.0538773,1397.293056,1.9456,0.0,53.646,50.417,4.134748,1099.472896,0.0,0.0,9.102,7.374,3.4888843,1171.193856,2.301952,0.01024,6.501,5.714,3.061088,1211.363328,0.032768,0.0,5.287,4.791,2.9028317,1135.69792,0.032768,0.0,9.15,8.219
1442642769000,7.790238,1390.211072,4.489216,0.0,41.848,39.855,3.6122475,1100.24704,0.0,0.0,6.186,5.211,2.8171772,1169.788928,1.71008,0.008192,6.35,5.445,2.2971459,1209.344,0.0,0.0,5.851,5.139,2.2306806,1139.007488,0.0,0.0,5.114,4.598
1442642829000,9.2951026,1383.538688,3.784704,0.0,55.942,52.577,2.6980695,1100.931072,0.008192,0.0,7.976,6.945,3.5843507,1171.632128,1.411072,0.012288,7.04,6.119,2.5958315,1210.540032,0.0,0.0,6.324,5.731,3.6512528,1141.096448,0.0,0.0,9.122,8.462
1442642889000,9.053769,1382.838272,1.806336,0.0,72.646,67.926,2.874192,1104.896,0.0,0.0,11.015,8.584,3.5229768,1171.922944,1.646592,0.008192,8.113,6.739,3.6433292,1210.75712,0.0,0.0,10.595,9.839,3.7036109,1141.682176,0.0,0.0,11.526,8.995
1442642949000,6.1277008,1373.360128,3.538944,0.0,59.224,55.603,1.7197459,1107.423232,0.0,0.0,6.906,5.784,2.564737,1166.999552,1.138688,0.006144,7.686,6.754,1.9532052,1208.369152,0.0,0.0,8.84,7.927,2.0181266,1136.00512,0.0,0.0,9.312,8.151
1442643009000,8.1294567,1365.21728,3.31776,0.0,53.565,49.369,1.8959981,1102.962688,0.0,0.0,5.357,4.284,3.055815,1167.781888,1.949696,0.012288,6.403,5.549,3.1977269,1209.212928,0.0,0.0,9.008,7.674,3.6372965,1131.864064,0.032768,0.0,8.64,7.513
1442643069000,9.1317647,1370.750976,2.994176,0.0,60.432,56.331,2.4824035,1105.391616,0.008192,0.0,7.209,6.72,2.812101,1175.199744,1.716224,0.008192,7.249,6.581,3.5634027,1208.000512,0.0,0.0,10.192,8.702,2.8487525,1130.336256,0.0,0.0,9.025,7.853
1442643129000,9.3316591,1368.911872,2.596864,0.0,60.575,56.074,2.5900757,1101.922304,0.0,0.0,6.807,5.495,3.3269441,1171.98848,2.0992,0.004096,8.379,6.691,3.8349208,1211.392,0.0,0.0,8.315,7.332,4.0003081,1132.437504,0.0,0.0,9.907,9.279
1442643189000,8.9427761,1367.031808,3.887104,0.0,60.854,56.516,2.5759984,1105.956864,0.0,0.0,7.779,6.632,3.4195735,1173.307392,2.177024,0.012288,9.201,8.739,3.1145207,1210.544128,0.0,0.0,7.571,6.684,3.4204254,1132.306432,0.0,0.0,8.09,6.84
1442643249000,8.9678195,1367.711744,3.575808,0.0,52.483,48.721,3.5553209,1107.943424,0.0,0.0,7.549,7.254,2.74499,1173.93408,1.021952,0.01024,6.168,5.262,2.1259174,1211.461632,0.0,0.0,5.853,4.392,3.2389504,1133.113344,0.0,0.0,8.401,8.078
1442643309000,9.5355593,1368.346624,3.05152,0.0,50.814,47.488,3.2499994,1107.345408,0.0,0.0,6.948,6.903,3.1999134,1176.666112,1.103872,0.006144,6.547,5.691,3.4310327,1213.546496,0.0,0.0,7.526,6.27,3.3403642,1134.276608,0.0,0.0,7.066,6.335
1442643369000,7.745645,1370.35776,4.163584,0.0,61.768,57.47,3.3000515,1110.740992,0.0,0.0,8.436,7.322,3.410914,1176.9856,1.933312,0.012288,7.557,7.222,2.6891505,1210.740736,0.0,0.0,8.433,7.21,3.3406775,1133.256704,0.0,0.0,8.98,8.571
1442643429000,8.9308784,1371.107328,3.209216,0.0,61.728,57.985,2.8742037,1113.624576,0.0,0.0,8.595,6.957,2.9498248,1178.566656,1.60768,0.01024,7.876,7.184,3.0307566,1211.20768,0.0,0.0,8.743,7.609,3.3370677,1134.952448,0.0,0.0,10.683,8.827
1442643490000,9.0326392,1369.956352,3.698688,0.0,59.928,56.542,2.4842167,1112.489984,0.0,0.0,7.972,6.544,3.059354,1177.16992,1.439744,0.0,7.96,6.537,3.9978159,1209.91744,0.0,0.0,10.086,8.106,3.9830812,1135.067136,0.0,0.0,8.443,7.972
1442643551000,9.1277745,1372.094464,2.88768,0.0,64.556,60.229,2.711707,1110.822912,0.0,0.0,8.002,7.119,2.7066801,1178.284032,1.31072,0.0,7.601,6.593,3.9113867,1213.177856,0.0,0.0,10.241,8.84,3.0636626,1143.099392,0.0,0.0,9.272,8.426
1442643611000,6.5487736,1360.314368,3.0208,0.0,47.171,44.364,2.7186827,1100.402688,0.0,0.0,6.754,5.877,1.9886754,1167.27808,0.456704,0.0,5.798,4.41,2.508132,1209.58976,0.004096,0.0,7.555,6.685,2.5875001,1133.858816,0.065536,0.0,6.557,5.933
1442643671000,7.7561253,1363.255296,3.05152,0.0,47.108,43.524,2.7508906,1102.737408,0.0,0.0,7.028,6.878,2.969542,1168.56832,1.497088,0.0,5.453,4.442,2.2577292,1209.946112,0.0,0.0,6.745,6.021,2.760518,1135.169536,0.0,0.0,6.667,5.698
1442643731000,8.1938514,1365.454848,2.363392,0.0,57.057,53.234,2.835103,1103.896576,0.0,0.0,7.51,6.65,3.1186176,1169.670144,1.406976,0.0,5.608,5.277,3.1692695,1212.145664,0.0,0.0,8.71,8.035,3.1520292,1134.55104,0.0,0.0,10.092,8.236
1442643791000,10.0100815,1366.376448,2.06848,0.0,60.907,57.135,3.1327189,1105.076224,0.0,0.0,7.516,6.469,3.8033802,1169.44896,1.705984,0.0,7.968,7.252,3.3754721,1214.619648,0.0,0.0,8.707,8.179,4.7356945,1136.041984,0.0,0.0,9.579,8.478
1442643851000,6.8736714,1366.511616,3.35872,0.0,55.446,52.112,39.7653448,1142.566912,0.0,0.0,6.562,5.2,2.2737197,1168.748544,1.701888,0.0,7.123,6.276,2.4600878,1212.383232,0.0,0.0,6.518,5.549,3.2817075,1135.702016,0.0,0.0,10.231,9.263
1442643911000,2.6081376,1337.659392,1.961984,0.0,23.969,22.341,2.3579285,1362.362368,4.624384,0.0,2.696,2.53,0.6828647,1147.510784,0.95232,0.0,3.969,3.44,0.6437588,1198.972928,0.0,0.0,3.706,3.038,0.8085551,1123.024896,0.0,0.0,4.076,3.771
1442643972000,2.4360014,1291.169792,0.929792,0.0,15.336,14.085,3.6109949,1465.266176,4.091904,0.0,2.796,2.644,1.0001293,1133.850624,0.423936,0.0,2.296,2.276,0.9593318,1189.646336,0.0,0.0,3.066,2.337,0.7585438,1105.752064,0.0,0.0,2.748,2.57
1442644032000,5.2895614,1269.379072,3.979264,0.0,36.129,34.049,2.02227,1553.240064,0.0,0.0,4.914,4.139,1.7435665,1122.824192,0.987136,0.0,4.293,3.768,1.8004473,1184.456704,0.0,0.0,4.454,4.076,2.0897656,1091.395584,0.0,0.0,7.009,5.662
1442644092000,6.2815342,1257.63584,1.708032,0.0,42.135,39.028,1.6491311,1553.215488,0.0,0.0,4.977,4.123,2.0902578,1127.563264,1.271808,0.0,4.882,4.279,1.8003465,1180.307456,0.0,0.0,6.507,5.485,2.7761187,1092.56704,0.0,0.0,7.476,7.626
1442644152000,7.6157594,1259.057152,3.923968,0.0,51.046,47.861,2.7615534,1551.396864,0.065536,0.0,8.007,6.283,2.3777625,1125.425152,0.694272,0.0,5.049,3.667,2.7870833,1186.328576,0.0,0.0,7.804,6.481,2.4918717,1089.224704,0.0,0.0,7.701,5.649
1442644213000,8.703302,1229.008896,2.752512,0.0,49.28,45.694,3.5713756,1553.825792,0.0,0.0,5.831,5.699,2.471553,1122.684928,1.271808,0.0,6.488,4.877,3.0407049,1182.142464,0.0,0.0,7.061,5.904,3.2172598,1052.991488,0.0,0.0,8.358,7.132
1442644273000,6.2268558,1228.742656,3.028992,0.0,43.639,40.893,2.2673617,1555.92704,0.0,0.0,6.23,5.549,1.9075109,1122.24256,1.468416,0.0,6.627,5.639,2.5037595,1178.595328,0.0,0.0,4.81,4.442,8.4924106,1063.960576,4.472832,0.0,7.427,6.741
1442644333000,7.9878639,1224.6016,3.837952,0.0,35.914,34.043,3.2061107,1557.139456,0.0,0.0,5.482,5.592,2.4147595,1122.586624,1.693696,0.0,4.479,4.085,2.2593939,1180.340224,0.0,0.0,5.651,5.18,2.6494993,1072.738304,0.393216,0.0,4.868,3.932
1442644393000,9.3811071,1225.826304,1.86368,0.0,48.507,45.503,3.8967072,1558.667264,0.0,0.0,6.106,6.426,2.5830702,1126.486016,1.861632,0.0,5.015,4.849,3.096658,1176.4736,0.0,0.0,6.938,5.998,2.9488455,1082.241024,0.0,0.0,7.85,6.592
1442644453000,8.6045792,1222.152192,3.780608,0.0,41.743,38.048,2.7489444,1557.946368,0.0,0.0,4.71,4.086,2.4084884,1126.002688,0.548864,0.0,4.166,3.358,3.0648637,1179.303936,0.0,0.0,7.223,6.353,2.764984,1085.218816,0.458752,0.0,5.994,5.411
1442644513000,7.9649484,1211.772928,4.038656,0.0,41.582,38.834,2.0949691,1551.85152,0.0,0.0,5.19,4.449,2.328273,1131.45856,2.048,0.0,3.761,3.982,2.938187,1184.68608,0.0,0.0,5.995,4.913,2.913299,1098.358784,0.0,0.0,7.023,6.326
1442644574000,4.1213902,1203.126272,1.572864,0.0,41.54,38.36,1.4623434,1552.822272,0.0,0.0,6.559,5.502,1.3815706,1128.59136,1.312768,0.0,4.735,4.203,1.5998173,1180.516352,0.0,0.0,5.297,5.172,6.3494566,1102.25408,0.159744,0.0,6.349,5.777
1442644635000,8.5345919,1195.78624,2.62144,0.012288,54.615,50.908,3.1614346,1557.344256,0.0,0.0,7.083,5.715,3.4156183,1130.442752,1.523712,0.0,7.446,5.761,3.1269028,1180.389376,0.0,0.0,8.556,7.571,3.435479,1105.65376,0.032768,0.0,8.217,7.11
1442644695000,7.392515,1195.106304,3.579904,0.004096,40.833,38.017,2.4485786,1558.020096,0.0,0.0,4.255,3.979,2.4124238,1134.477312,2.31424,0.0,4.556,4.708,3.2196881,1182.806016,0.028672,0.0,6.141,5.612,2.4658364,1101.447168,0.0,0.0,6.319,6.348
1442644755000,8.9452772,1193.066496,2.43712,0.02048,48.014,44.888,2.9788409,1561.460736,0.0,0.0,6.307,5.334,3.6400041,1137.512448,1.45408,0.0,6.112,5.572,3.2327466,1188.917248,0.0,0.0,7.869,7.05,2.8051675,1097.66656,0.032768,0.0,6.528,5.139
1442644815000,6.817881,1178.91072,1.51552,0.008192,43.366,39.73,2.1702405,1552.211968,0.0,0.0,5.289,4.791,2.2628573,1118.449664,1.060864,0.0,4.043,4.25,2.76587,1172.713472,0.0,0.0,6.515,6.262,2.798479,1082.933248,0.0,0.0,7.832,6.894
1442644875000,6.7051139,1171.308544,3.391488,0.02048,40.01,38.128,1.9091714,1554.0224,0.0,0.0,6.28,5.741,2.4435948,1121.824768,1.267712,0.0,4.695,3.844,2.2185768,1176.45312,0.032768,0.0,4.864,4.556,2.9729504,1084.68224,0.0,0.0,7.911,6.758
1442644935000,7.6801359,1173.528576,4.327424,0.02048,55.375,51.918,3.0668931,1564.925952,0.0,0.0,8.367,7.048,2.9864143,1129.6768,0.991232,0.0,7.52,5.776,2.6277908,1180.110848,0.0,0.0,5.81,4.999,2.9221737,1089.273856,0.004096,0.0,8.748,7.508
1442644995000,7.4836306,1167.11424,2.16064,0.012288,34.481,32.043,2.1452491,1565.51168,0.0,0.0,4.217,4.26,1.6556757,1131.900928,1.204224,0.0,3.618,3.376,2.4469486,1182.793728,0.028672,0.0,6.487,6.33,2.5710511,1092.99712,0.0,0.0,5.112,4.958
1442645055000,6.503178,1164.414976,1.6384,0.008192,36.926,34.674,2.5968257,1568.31744,0.0,0.0,6.384,5.56,1.4641668,1135.65696,1.734656,0.0,4.227,4.158,1.8140855,1181.200384,0.0,0.0,4.914,4.01,2.4786769,1096.56064,0.0,0.0,5.647,5.887
1442645115000,9.0269903,1165.02528,3.039232,0.028672,51.126,47.282,3.0397345,1573.31456,0.0,0.0,6.54,5.841,3.220195,1142.8864,1.562624,0.0,6.163,5.244,2.9644655,1185.44384,0.0,0.0,6.245,4.948,3.4894452,1100.853248,0.0,0.0,9.058,7.944
1442645175000,9.2469621,1158.201344,1.695744,0.02048,53.324,49.571,3.7268308,1571.815424,0.0,0.0,6.527,5.808,3.3238007,1143.484416,1.13664,0.0,6.934,5.529,3.306747,1184.23552,0.0,0.0,6.701,5.933,3.079856,1099.821056,0.032768,0.0,10.106,8.061
1442645235000,9.4309552,1151.13984,3.05152,0.016384,42.376,39.864,3.8128791,1571.651584,0.0,0.0,6.556,5.679,2.6975996,1143.84896,1.662976,0.0,5.938,5.595,3.3229032,1193.680896,0.0,0.0,5.962,5.251,2.9303807,1097.232384,0.208896,0.0,5.793,5.478
1442645295000,5.8906359,1145.376768,2.646016,0.016384,42.884,40.015,2.0479031,1572.298752,0.032768,0.0,4.553,4.276,2.1350054,1146.08128,1.767424,0.0,7.215,6.437,1.8878301,1195.13088,0.0,0.0,6.137,5.504,1.8442628,1101.43488,0.0,0.0,6.353,5.769
1442645355000,7.6968901,1139.85536,3.438592,0.012288,47.305,44.185,2.689757,1574.11328,0.0,0.0,6.592,6.028,1.8810793,1148.747776,1.355776,0.0,5.086,4.586,2.5463961,1197.518848,0.0,0.0,5.889,5.205,3.6500274,1105.297408,0.0,0.0,8.656,7.622
1442645415000,7.5046872,1115.283456,2.758656,0.016384,44.051,41.188,1.8875228,1562.734592,0.032768,0.0,3.983,4.41,3.0049157,1138.950144,1.554432,0.0,6.807,5.53,3.1306525,1194.098688,0.0,0.0,7.459,6.749,2.42425,1082.875904,0.069632,0.0,6.794,5.259
1442645475000,6.9754891,1109.045248,3.108864,0.004096,44.039,41.724,1.6227959,1567.133696,0.0,0.0,5.487,4.616,1.9261785,1142.472704,1.35168,0.0,5.077,4.167,2.9663835,1196.576768,0.0,0.0,6.3,5.948,5.7148308,1084.981248,0.131072,0.0,7.752,6.693
1442645536000,9.304341,1108.066304,3.366912,0.012288,42.085,39.086,3.6963758,1567.064064,0.032768,0.0,7.322,6.171,3.4494015,1144.48384,1.759232,0.0,5.699,5.087,2.7534135,1199.460352,0.0,0.0,4.364,4.265,2.9585621,1087.148032,0.004096,0.0,5.654,5.124
1442645597000,8.4182448,1102.364672,3.34848,0.0,52.168,48.136,2.0873234,1566.273536,0.0,0.0,5.74,5.324,2.5126402,1143.169024,1.41312,0.0,5.99,5.051,3.4669892,1195.196416,0.004096,0.0,8.884,7.529,3.3489381,1094.28736,0.0,0.0,7.19,6.826
1442645657000,8.314645,1095.41376,1.888256,0.0,57.031,53.368,2.6460669,1569.05472,0.0,0.0,7.644,7.191,3.1488504,1148.3136,1.912832,0.0,7.218,6.687,3.4048068,1196.814336,0.0,0.0,8.649,6.889,3.4276406,1092.435968,0.012288,0.0,8.149,8.071
1442645717000,8.6357128,1095.860224,1.656832,0.0,54.539,50.523,3.5547621,1572.139008,0.0,0.0,7.881,7.088,2.0906319,1149.251584,1.370112,0.0,5.592,4.812,3.6689657,1201.467392,0.0,0.0,8.84,7.619,3.2083536,1096.912896,0.0,0.0,9.02,8.121
1442645777000,8.6621078,1093.197824,5.287936,0.0,58.71,55.244,3.2062545,1571.811328,0.0,0.0,6.034,4.993,3.0299055,1148.78464,1.460224,0.0,8.667,6.637,3.3108842,1202.860032,0.0,0.0,9.533,8.188,3.2875607,1095.708672,0.0,0.0,8.97,7.942
1442645837000,9.7460163,1091.82976,2.74432,0.0,48.446,45.24,3.4344351,1573.593088,0.0,0.0,6.086,6.066,2.3937886,1149.792256,1.447936,0.0,4.938,4.495,4.128727,1202.884608,0.0,0.0,7.114,6.571,3.0672847,1097.920512,0.0,0.0,8.147,7.578
1442645897000,8.0834383,1092.272128,3.325952,0.0,66.517,62.092,2.8734645,1574.457344,0.0,0.0,8.963,7.548,2.8212897,1151.369216,1.1776,0.0,6.48,5.501,3.2050225,1203.593216,0.0,0.0,9.596,8.093,3.3652273,1097.9328,0.032768,0.0,11.915,10.717
1442645957000,8.391504,1091.411968,4.97664,0.0,49.005,46.595,2.7497818,1573.175296,0.0,0.0,5.932,5.363,2.2346347,1152.9216,1.253376,0.0,6.214,5.083,2.7527741,1203.273728,0.0,0.0,6.028,5.504,4.3458111,1099.833344,0.0,0.0,8.559,8.108
1442646017000,8.2836875,1072.86528,3.108864,0.0,49.328,46.338,2.748686,1565.667328,0.0,0.0,6.166,5.19,3.3227488,1145.741312,1.462272,0.0,6.935,6.22,2.8073985,1195.053056,0.032768,0.0,7.977,7.332,2.4719126,1079.844864,0.446464,0.0,6.221,4.95
1442646078000,8.1189524,1070.686208,3.383296,0.0,57.798,54.074,3.1184855,1565.372416,0.0,0.0,7.536,5.933,3.8516196,1146.32704,2.924544,0.0,7.831,7.413,2.7182953,1201.303552,0.0,0.0,7.968,6.253,2.9528949,1085.841408,0.032768,0.0,7.578,6.568
1442646138000,9.1182325,1076.006912,3.11296,0.0,55.651,52.327,3.5673135,1571.115008,0.0,0.0,8.636,7.219,3.2316788,1152.679936,2.197504,0.0,6.687,6.251,3.7511899,1202.921472,0.004096,0.0,9.181,8.102,3.2478495,1090.809856,0.0,0.0,7.062,6.027
1442646198000,8.9932811,1077.182464,1.80224,0.0,43.645,41.293,3.2011219,1573.412864,0.0,0.0,6.839,5.429,4.263678,1155.719168,1.275904,0.0,7.006,5.885,2.7802952,1211.092992,0.0,0.0,6.148,5.138,5.1882739,1100.193792,0.155648,0.0,6.503,5.906
1442646258000,8.3428471,1075.052544,3.66592,0.0,56.496,53.6,3.3175691,1569.067008,0.0,0.0,8.472,7.667,2.0437493,1151.680512,1.099776,0.0,6.072,5.569,3.1747284,1209.196544,0.0,0.0,8.706,8.062,2.9570138,1100.537856,0.008192,0.0,8.254,7.413
1442646318000,8.2370824,1077.20704,3.104768,0.0,47.579,44.964,2.4523969,1571.033088,0.0,0.0,6.565,6.439,2.9174735,1156.169728,1.56672,0.0,6.318,5.502,2.9114175,1211.572224,0.0,0.0,6.99,6.062,2.978533,1103.970304,0.0,0.0,6.608,5.754
1442646378000,11.1830479,1079.545856,3.325952,0.0,67.136,62.746,4.1377388,1572.896768,0.0,0.0,8.838,7.561,4.8917041,1156.972544,1.974272,0.0,8.301,8.264,4.572037,1215.578112,0.0,0.0,10.75,8.179,4.3015954,1103.659008,0.0,0.0,9.414,7.469
1442646439000,7.8842413,1079.88992,3.69664,0.0,48.496,45.798,3.2370726,1574.699008,0.0,0.0,6.81,6.496,3.5623251,1156.702208,0.77824,0.012288,6.838,5.427,3.2541441,1216.253952,0.0,0.0,8.149,7.6,1.7634891,1103.548416,0.0,0.0,4.838,4.17
1442646499000,9.1977328,1080.7296,3.735552,0.0,54.542,51.101,3.3629582,1575.0144,0.0,0.0,6.619,5.812,3.0449419,1157.67296,1.959936,0.012288,5.815,5.491,3.2558815,1213.669376,0.0,0.0,7.507,6.109,3.6493954,1105.44896,0.0,0.0,8.739,7.71
1442646559000,9.3977278,1082.847232,3.383296,0.0,55.763,52.583,3.7191284,1574.187008,0.0,0.0,7.81,7.057,2.9175582,1157.46816,0.980992,0.01024,6.828,5.212,3.4450496,1214.03392,0.012288,0.0,8.582,8.516,3.2919025,1105.825792,0.0,0.0,7.938,6.982
1442646619000,7.3325818,1064.542208,3.489792,0.0,41.34,38.418,3.4802314,1559.977984,0.0,0.0,6.498,5.872,2.3364079,1135.517696,1.200128,0.006144,4.857,4.596,2.7505297,1199.460352,0.0,0.0,6.004,5.474,2.6955795,1088.036864,0.032768,0.0,6.114,5.612
1442646679000,9.3838704,1063.317504,3.13344,0.0,47.158,43.949,2.5259599,1567.604736,0.0,0.0,5.681,4.873,3.7802892,1145.20064,2.377728,0.01024,6.851,6.206,3.3002175,1199.017984,0.0,0.0,6.897,5.976,3.4973748,1092.644864,0.032768,0.0,6.881,6.112
1442646739000,7.6323216,1065.30816,3.25632,0.0,53.99,50.435,2.713559,1569.517568,0.0,0.0,6.654,6.677,2.8671173,1148.3136,0.974848,0.012288,6.496,5.075,3.6299745,1199.607808,0.0,0.0,8.274,6.846,3.6504811,1095.733248,0.032768,0.0,9.268,7.687
1442646799000,7.4799395,1063.972864,3.872768,0.0,52.668,49.791,2.2834866,1570.934784,0.0,0.0,6.08,4.978,3.3962488,1148.379136,1.912832,0.008192,7.462,6.441,2.240602,1203.277824,0.012288,0.0,6.991,5.61,3.4483394,1095.684096,0.0,0.0,8.744,7.535
1442646859000,8.0621978,1063.862272,2.88768,0.0,57.864,54.047,2.2559462,1571.057664,0.0,0.0,5.965,5.241,3.0352895,1149.345792,1.564672,0.004096,8.335,6.652,2.6033129,1204.1216,0.0,0.0,8.531,6.557,3.0605324,1097.347072,0.0,0.0,8.96,8.059
1442646919000,8.5214657,1067.380736,3.448832,0.0,56.013,52.383,2.8398836,1573.646336,0.0,0.0,7.731,6.713,1.9061041,1151.082496,1.216512,0.014336,5.489,4.787,4.1132711,1207.279616,0.0,0.0,8.023,6.914,3.8713729,1098.26048,0.0,0.0,9.544,8.629
1442646980000,10.3511905,1068.07296,2.940928,0.0,65.8,61.679,3.6248411,1574.658048,0.0,0.0,11.755,9.126,2.7707834,1157.165056,1.091584,0.004096,7.378,6.052,4.2889314,1208.471552,0.0,0.0,8.786,8.596,3.9710335,1099.096064,0.0,0.0,9.479,8.228
1442647040000,10.0303756,1067.094016,2.891776,0.0,62.562,58.623,3.3578025,1575.079936,0.0,0.0,9.06,6.72,3.8973103,1158.20544,2.18112,0.01024,8.008,6.563,2.8200106,1215.184896,0.0,0.0,8.055,6.408,3.6056467,1099.431936,0.0,0.0,10.855,9.415
1442647100000,7.5909193,1066.610688,3.584,0.0,47.253,44.614,2.4444801,1573.879808,0.004096,0.0,5.741,4.696,1.8298779,1157.328896,1.480704,0.01024,5.082,4.665,2.8706615,1214.517248,0.0,0.0,7.012,5.944,3.1911339,1109.671936,0.032768,0.0,8.464,8.09
1442647160000,9.2135137,1071.599616,4.308992,0.0,57.011,53.615,3.6417107,1581.678592,0.0,0.0,6.936,5.975,2.9020092,1159.565312,1.349632,0.008192,6.433,5.384,3.776991,1215.97952,0.004096,0.0,7.946,6.92,3.7176435,1108.983808,0.0,0.0,9.525,8.163
1442647220000,6.3869848,1060.642816,1.306624,0.0,50.164,45.941,3.1153199,1569.579008,0.0,0.0,6.981,6.044,2.5085225,1149.415424,1.722368,0.008192,7.118,5.828,1.8572647,1201.90976,0.012288,0.0,7.125,6.608,2.1330968,1091.854336,0.0,0.0,6.747,5.437
1442647280000,6.4393885,1059.67616,2.650112,0.0,44.51,41.637,2.6832053,1565.782016,0.0,0.0,7.126,6.697,3.0241701,1149.145088,1.257472,0.008192,4.767,4.232,2.7300138,1194.12736,0.008192,0.0,5.196,4.422,2.8754735,1089.974272,0.032768,0.0,7.932,7.012
1442647341000,8.801389,1061.392384,3.753984,0.0,51.616,48.31,3.5816997,1566.142464,0.0,0.0,7.301,6.048,2.4241015,1148.768256,1.437696,0.0,4.901,5.012,3.669415,1192.48896,0.0,0.0,7.821,7.0,3.6123289,1089.683456,0.0,0.0,7.744,6.716
1442647401000,8.7056623,1062.350848,2.543616,0.0,53.786,50.276,2.8188095,1564.360704,0.0,0.0,6.471,5.851,2.9432586,1151.34464,1.753088,0.0,7.7,6.524,2.7374949,1196.46208,0.0,0.0,7.767,6.345,3.5759854,1092.304896,0.0,0.0,8.38,7.068
1442647461000,8.7907298,1064.226816,4.472832,0.0,55.982,53.231,3.2741364,1563.99616,0.0,0.0,8.027,6.952,3.3166304,1147.928576,1.691648,0.0,6.905,5.692,2.9037141,1197.522944,0.0,0.0,6.534,5.598,3.3579438,1092.268032,0.032768,0.0,10.42,8.769
1442647521000,10.0525251,1062.473728,2.711552,0.0,59.707,55.81,3.6138258,1567.870976,0.0,0.0,8.026,7.07,3.1388218,1150.713856,1.66912,0.0,6.956,6.966,3.5124478,1196.56448,0.0,0.0,7.111,6.149,4.2218738,1088.512,0.0,0.0,9.632,7.98
1442647581000,8.9580879,1065.877504,2.183168,0.0,59.845,56.223,3.6260017,1568.17408,0.004096,0.0,9.505,7.799,3.3021543,1152.421888,1.667072,0.0,7.639,5.933,3.2990035,1192.198144,0.0,0.0,9.284,7.535,3.5284901,1091.2768,0.0,0.0,8.235,6.882
1442647641000,3.7892661,1063.87456,1.841152,0.0,29.493,27.614,1.5658624,1566.244864,0.0,0.0,4.86,4.476,1.2310163,1151.11936,0.862208,0.0,3.378,3.008,1.3563133,1193.582592,0.004096,0.0,4.519,3.82,1.4206209,1088.4096,0.0,0.0,4.673,4.416
1442647701000,5.8370104,1050.23488,2.240512,0.0,45.925,43.499,2.2783948,1555.492864,0.0,0.0,6.835,6.122,1.7185079,1144.451072,1.476608,0.0,6.557,5.701,2.3730374,1188.651008,0.0,0.0,6.781,4.76,2.0834733,1078.82496,0.0,0.0,6.605,6.233
1442647761000,7.5397315,1050.169344,2.994176,0.0,42.901,40.229,2.8929149,1560.399872,0.0,0.0,6.616,6.639,2.5136588,1141.84192,2.183168,0.0,4.948,4.704,2.8030576,1191.071744,0.0,0.0,4.515,4.146,2.9737786,1075.376128,0.0,0.0,8.393,6.8
1442647822000,8.5056368,1049.608192,2.678784,0.0,52.469,49.036,3.1329727,1565.446144,0.0,0.0,6.724,6.064,3.0150356,1139.949568,2.013184,0.0,6.194,5.294,3.2834755,1194.262528,0.0,0.0,7.637,7.169,2.7265227,1079.123968,0.0,0.0,8.55,6.727
1442647883000,8.5313323,1038.127104,2.881536,0.0,56.763,52.702,2.7121312,1543.057408,0.008192,0.0,8.816,7.451,2.9391975,1139.216384,1.96608,0.0,7.072,6.857,2.9958565,1186.504704,0.012288,0.0,8.248,7.261,2.2496936,1066.872832,0.0,0.0,7.086,6.265
1442647944000,7.9832911,1042.407424,2.281472,0.0,50.264,47.17,3.0021953,1544.761344,0.0,0.0,7.281,6.218,3.3314399,1139.560448,1.302528,0.0,6.629,5.292,2.9005257,1186.525184,0.032768,0.0,6.397,5.39,3.276859,1070.944256,0.0,0.0,7.85,7.583
1442648004000,11.1522602,1045.581824,5.906432,0.0,51.223,47.952,2.7719687,1546.11712,0.0,0.0,5.411,4.669,2.5301791,1145.675776,1.728512,0.0,5.352,4.89,3.1551866,1196.19584,0.0,0.0,7.032,5.771,3.5326196,1078.001664,0.0,0.0,9.194,8.303
1442648064000,8.5494379,1046.659072,2.646016,0.0,49.442,46.772,2.8188646,1548.034048,0.0,0.0,8.167,6.513,2.8358922,1142.747136,1.339392,0.0,6.227,5.207,2.6114703,1199.624192,0.0,0.0,6.425,6.098,3.5249049,1080.471552,0.0,0.0,6.774,6.84
1442648124000,9.6790937,1047.7568,3.072,0.0,57.794,54.657,3.4451428,1548.435456,0.0,0.0,8.364,7.013,2.6600557,1141.948416,1.349632,0.0,6.631,5.936,3.2076391,1197.07648,0.0,0.0,7.828,6.21,3.9110624,1081.393152,0.0,0.0,9.498,8.605
1442648184000,8.1537331,1052.635136,2.834432,0.0,55.534,51.735,2.8387178,1549.979648,0.0,0.0,7.936,6.686,2.9757825,1148.12928,1.554432,0.0,6.756,6.081,3.0322712,1201.188864,0.0,0.0,7.998,7.255,3.199331,1079.300096,0.0,0.0,6.901,6.372
1442648244000,9.5454508,1054.138368,2.791424,0.0,59.559,55.512,3.4610257,1554.747392,0.0,0.0,9.203,7.764,3.5067954,1150.201856,1.916928,0.0,6.784,6.15,3.9239954,1201.299456,0.0,0.0,9.481,8.047,2.3190742,1075.765248,0.0,0.0,7.491,6.199
1442648305000,7.09527,1059.659776,3.405824,0.049152,36.086,34.235,1.0500843,1550.053376,0.004096,0.0,5.185,4.416,0.8862963,1147.625472,0.567296,0.0,4.533,4.018,1.2292366,1239.36768,0.0,0.0,7.067,5.347,1.2689343,1075.707904,0.0,0.0,5.033,4.032
1442648365000,6.9477201,1059.51232,3.026944,0.012288,42.26,40.292,1.6743134,1550.229504,0.0,0.0,4.917,3.489,2.2058829,1146.441728,1.056768,0.0,5.365,4.248,3.4515424,1232.605184,0.0,0.0,8.563,7.003,2.8733427,1076.359168,0.0,0.0,7.242,6.181
1442648426000,7.9825845,1062.785024,2.473984,0.012288,48.558,44.896,2.7515507,1549.45536,0.0,0.0,7.123,6.516,2.237521,1147.486208,1.175552,0.0,5.916,5.178,2.7146206,1229.23008,0.0,0.0,6.748,6.302,2.5724971,1080.832,0.0,0.0,7.515,6.574
1442648486000,7.134611,1055.55968,3.166208,0.012288,46.508,43.55,2.4499112,1525.334016,0.0,0.0,6.007,5.511,2.4368257,1135.39072,1.220608,0.0,4.945,4.453,3.6049791,1216.475136,0.0,0.0,7.133,6.702,1.6354071,1070.002176,0.0,0.0,6.451,6.043
1442648546000,6.6651517,1057.50528,4.243456,0.008192,41.88,39.797,1.9267038,1522.888704,0.0,0.0,5.369,4.711,2.4384068,1141.256192,1.52576,0.0,4.045,3.888,2.2543512,1219.52256,0.0,0.0,7.409,5.775,2.9278247,1072.90624,0.0,0.0,6.7,5.915
1442648606000,9.2190678,1062.264832,2.207744,0.045056,38.466,36.24,2.7348461,1529.0368,0.0,0.0,4.717,4.203,4.0936526,1146.699776,1.947648,0.0,5.892,5.25,3.9979421,1223.278592,0.0,0.0,6.045,5.526,3.4677744,1075.109888,0.0,0.0,5.481,5.358
1442648666000,8.8850337,1064.083456,2.78528,0.004096,49.393,46.644,2.7623299,1525.886976,0.0,0.0,5.829,5.426,3.2575324,1148.08832,1.363968,0.0,6.266,5.006,2.9897661,1224.27392,0.0,0.0,7.983,6.782,3.4776865,1075.474432,0.0,0.0,7.702,6.789
1442648726000,8.3461192,1063.6288,2.756608,0.024576,50.4,46.998,2.4448679,1528.487936,0.0,0.0,5.021,4.656,2.7271957,1142.677504,2.607104,0.0,6.431,5.974,3.4011567,1227.108352,0.0,0.0,8.126,7.148,3.4463696,1074.66752,0.0,0.0,8.482,7.138
1442648786000,8.7239696,1065.394176,2.301952,0.02048,49.374,46.355,4.1384924,1529.561088,0.0,0.0,7.934,7.122,2.9917935,1146.138624,1.589248,0.0,6.188,5.723,3.1028892,1230.815232,0.0,0.0,7.857,6.43,3.3944832,1073.893376,0.0,0.0,6.723,6.149
1442648846000,8.9872226,1064.669184,3.960832,0.02048,43.831,41.974,3.1466811,1527.812096,0.0,0.0,6.124,5.23,3.3660549,1145.278464,0.669696,0.0,4.919,3.916,3.8617906,1233.723392,0.0,0.0,8.122,6.816,2.9080298,1075.716096,0.004096,0.0,6.374,5.673
1442648906000,8.0942505,1066.336256,3.239936,0.069632,51.419,48.165,3.5623794,1527.390208,0.0,0.0,6.873,6.56,2.9193906,1147.654144,1.255424,0.0,5.954,4.668,2.5606195,1233.997824,0.0,0.0,8.686,7.291,4.0418917,1079.975936,0.0,0.0,8.982,7.642
1442648966000,9.3253469,1069.371392,4.231168,0.02048,52.237,48.927,2.4090974,1520.488448,0.032768,0.0,5.814,4.992,4.0145061,1148.12928,1.972224,0.0,7.203,6.191,3.0107655,1236.721664,0.0,0.0,9.313,7.986,3.3261081,1081.83552,0.0,0.0,6.854,5.406
1442649026000,9.5053986,1068.634112,2.588672,0.024576,58.394,54.494,3.0131443,1517.256704,0.004096,0.0,8.871,7.544,3.407782,1150.599168,1.865728,0.0,7.493,6.721,3.9082655,1233.453056,0.0,0.0,8.948,7.958,3.3656863,1081.495552,0.0,0.0,6.694,5.934
1442649086000,7.0364395,1060.982784,2.02752,0.02048,41.362,38.379,2.4934696,1481.05216,0.004096,0.0,5.386,5.397,2.5671217,1146.847232,1.378304,0.0,5.636,4.9,3.2107796,1223.319552,0.0,0.0,6.374,5.649,2.9456437,1076.232192,0.0,0.0,5.357,5.05
1442649146000,8.0784353,1058.004992,1.388544,0.02048,50.044,47.044,3.2976022,1477.627904,0.032768,0.0,8.271,7.463,3.0968016,1143.31648,1.775616,0.0,5.376,4.685,3.2635907,1222.79936,0.0,0.0,7.186,6.353,3.1400796,1077.080064,0.032768,0.0,8.57,7.046
1442649207000,8.6790841,1064.800256,4.304896,0.0,56.417,53.157,2.6705645,1474.428928,0.012288,0.0,7.759,6.565,2.8752648,1142.644736,1.040384,0.0,6.608,4.704,3.5556241,1227.12064,0.0,0.0,9.383,8.358,2.5732334,1077.948416,0.0,0.0,6.568,5.921
1442649267000,8.4030038,1068.404736,5.65248,0.0,50.03,47.36,3.2726896,1473.916928,0.0,0.0,8.454,7.289,2.2618552,1144.045568,1.155072,0.0,4.999,4.598,2.5435164,1224.8064,0.0,0.0,4.763,3.632,3.4254809,1080.492032,0.032768,0.0,8.242,8.371
1442649327000,9.4976714,1069.19936,3.739648,0.0,57.261,53.631,2.339424,1469.812736,0.0,0.0,6.684,5.479,3.2428539,1144.336384,1.703936,0.0,6.799,5.842,4.2685399,1224.536064,0.0,0.0,8.563,6.718,3.7954747,1081.888768,0.0,0.0,10.159,8.433
1442649387000,8.6829679,1068.023808,3.342336,0.0,50.543,47.074,3.4352184,1471.889408,0.0,0.0,7.912,7.125,3.4535587,1146.69568,0.886784,0.0,6.341,5.287,3.084831,1226.256384,0.0,0.0,5.888,5.458,3.4229605,1082.208256,0.0,0.0,7.998,6.74
1442649447000,8.7654694,1066.278912,2.17088,0.0,56.547,53.038,3.2064791,1474.654208,0.0,0.0,7.893,6.724,3.0947119,1147.809792,1.906688,0.0,7.229,6.451,2.7512784,1226.4448,0.0,0.0,7.562,7.175,4.0237525,1084.739584,0.0,0.0,8.479,7.681
1442649507000,8.454739,1073.983488,4.558848,0.0,54.879,51.252,1.8686041,1473.425408,0.0,0.0,6.197,5.3,2.8725616,1146.871808,1.804288,0.0,5.858,5.86,3.0987803,1227.444224,0.0,0.0,7.198,6.613,2.9276824,1080.979456,0.0,0.0,9.247,8.046
1442649567000,7.3941793,1071.910912,3.026944,0.0,47.993,44.532,2.9303071,1471.01696,0.0,0.0,7.914,6.276,2.5725737,1147.527168,2.080768,0.0,4.934,5.242,2.9926101,1229.103104,0.0,0.0,6.655,5.149,1.9367497,1083.326464,0.0,0.0,7.142,6.093
1442649628000,5.926597,1075.195904,3.469312,0.0,40.553,38.416,15.6145383,1467.465728,0.724992,0.0,5.361,4.095,2.1903164,1151.176704,0.925696,0.0,5.015,4.428,1.9081239,1234.00192,0.0,0.0,6.826,5.972,2.68771,1085.026304,0.0,0.0,6.998,6.053
1442649688000,8.4952357,1069.154304,2.49856,0.0,58.314,54.111,2.8003318,1430.990848,0.0,0.0,5.796,5.654,2.8911249,1142.812672,1.7408,0.0,7.064,6.459,3.4190924,1225.162752,0.0,0.0,8.134,7.411,2.9667842,1076.6336,0.0,0.0,10.634,9.062
1442649748000,8.5397881,1070.698496,4.284416,0.0,45.447,41.969,2.479322,1423.368192,0.0,0.0,6.371,5.399,1.9062509,1151.504384,0.768,0.0,4.002,3.514,3.9721366,1226.682368,0.032768,0.0,6.996,6.954,3.247321,1078.53824,0.0,0.0,7.447,6.309
1442649808000,7.6560624,1072.746496,3.31776,0.0,59.708,55.715,3.2514511,1423.273984,0.0,0.0,8.436,6.408,3.234602,1154.4576,1.779712,0.0,9.232,7.291,2.4144841,1232.187392,0.032768,0.0,7.907,7.502,2.8992645,1088.258048,0.0,0.0,8.011,6.747
1442649868000,9.7718484,1074.855936,2.596864,0.0,54.671,50.733,3.2667163,1426.78016,0.0,0.0,7.601,6.758,3.3227844,1151.73376,1.7408,0.0,5.871,5.374,4.1284268,1232.949248,0.032768,0.0,7.907,7.034,4.1244625,1089.437696,0.0,0.0,9.802,7.953
1442649928000,8.8460017,1076.34688,1.69984,0.0,57.809,53.713,3.4799403,1428.738048,0.0,0.0,7.372,6.759,1.8591291,1149.730816,1.042432,0.0,5.648,4.777,3.7242232,1234.804736,0.0,0.0,10.998,9.638,3.7038638,1091.837952,0.0,0.0,8.151,8.091
1442649988000,9.3375397,1078.325248,3.50208,0.0,44.975,42.482,3.6036515,1428.160512,0.0,0.0,7.341,6.732,2.5326055,1158.299648,1.202176,0.0,4.623,4.014,3.1671157,1238.188032,0.032768,0.0,6.545,6.157,3.6242429,1088.14336,0.0,0.0,6.409,5.353
1442650048000,10.5057209,1079.488512,3.018752,0.0,64.467,60.387,3.2794204,1426.296832,0.0,0.0,7.273,6.319,3.9508734,1159.057408,2.414592,0.0,9.852,8.228,4.0605795,1238.114304,0.0,0.0,7.542,6.786,4.1303439,1089.765376,0.0,0.0,11.363,9.332
1442650108000,6.1191961,1083.817984,2.359296,0.0,46.503,43.334,1.6470806,1430.368256,0.032768,0.0,4.765,4.499,2.2297032,1162.579968,1.978368,0.0,8.481,7.565,1.7613731,1232.596992,0.0,0.0,4.964,4.481,2.5510772,1093.947392,0.032768,0.0,7.726,7.173
1442650168000,8.2729721,1080.856576,2.277376,0.0,53.295,49.723,3.0727504,1433.57952,0.0,0.0,8.237,6.796,3.4238231,1161.838592,2.533376,0.0,7.524,6.27,2.9621705,1235.472384,0.0,0.0,8.733,8.012,2.0676791,1089.39264,0.0,0.0,5.932,5.013
1442650228000,8.3081074,1082.609664,3.05152,0.0,54.622,50.849,3.2963679,1433.6,0.0,0.0,8.173,7.242,2.0212602,1158.934528,1.521664,0.0,5.977,5.493,3.1154989,1232.654336,0.0,0.0,8.563,7.864,2.8650052,1089.683456,0.0,0.0,7.618,5.965
1442650288000,9.239292,1075.228672,2.576384,0.0,55.845,52.483,3.9328391,1422.516224,0.0,0.0,8.591,6.995,3.3669882,1156.902912,2.236416,0.0,7.255,6.326,3.0780998,1220.554752,0.0,0.0,6.899,6.071,2.8971291,1086.246912,0.0,0.0,8.897,7.578
1442650348000,9.845471,1076.064256,3.219456,0.0,51.58,47.715,4.6242602,1422.045184,0.0,0.0,8.967,8.392,3.0126371,1153.347584,0.856064,0.0,6.223,5.108,3.2649146,1220.763648,0.0,0.0,5.811,5.734,3.9134644,1086.939136,0.0,0.0,6.945,5.929
1442650408000,7.305772,1077.866496,3.428352,0.0,46.285,42.968,2.334116,1421.70112,0.032768,0.0,7.049,6.269,1.945656,1151.238144,1.173504,0.0,4.479,3.86,2.5759765,1220.513792,0.0,0.0,5.676,5.059,3.378158,1088.700416,0.229376,0.0,8.165,7.703
1442650468000,7.8665915,1079.001088,2.461696,0.0,55.853,51.999,3.3819456,1421.017088,0.0,0.0,7.795,6.88,2.5645585,1150.775296,1.523712,0.0,7.379,6.671,2.9770303,1222.578176,0.012288,0.0,7.044,5.94,2.8093462,1091.923968,0.0,0.0,7.931,7.196
1442650528000,8.2017893,1083.461632,3.821568,0.0,56.938,53.341,2.9749518,1419.8784,0.0,0.0,6.456,5.594,3.0467164,1152.55296,1.941504,0.0,6.824,6.778,2.5599536,1231.958016,0.0,0.0,8.014,7.616,3.0640412,1086.201856,0.0,0.0,9.511,7.446
1442650588000,8.9280802,1082.589184,2.506752,0.0,54.298,50.348,2.2482974,1425.948672,0.0,0.0,6.082,5.906,3.2959457,1152.438272,2.144256,0.0,6.912,6.763,2.4435279,1230.258176,0.0,0.0,7.365,6.273,3.2407773,1087.307776,0.0,0.0,8.417,7.702
1442650648000,8.6490025,1081.593856,2.2528,0.0,51.365,48.64,2.952784,1428.000768,0.0,0.0,7.002,5.947,3.3902842,1154.269184,1.632256,0.0,6.307,5.432,3.1135991,1230.688256,0.0,0.0,6.918,5.308,3.3799025,1088.47104,0.0,0.0,8.378,7.022
1442650708000,9.7393808,1082.580992,2.668544,0.0,54.501,50.781,3.4935234,1425.846272,0.0,0.0,7.081,6.295,2.7975906,1158.049792,2.082816,0.0,6.467,5.628,4.5917428,1229.709312,0.0,0.0,9.901,7.886,3.717424,1089.134592,0.0,0.0,6.881,6.24
1442650768000,10.7942858,1083.129856,1.253376,0.0,59.566,55.459,2.3999958,1424.596992,0.0,0.0,6.245,5.238,3.6894801,1160.224768,2.015232,0.0,7.815,6.869,3.9759755,1233.563648,0.0,0.0,9.448,7.86,4.5426536,1087.352832,0.0,0.0,10.673,9.751
1442650829000,7.8557559,1080.397824,1.490944,0.0,58.911,55.156,2.3509461,1427.853312,0.0,0.0,7.574,6.617,2.2290663,1159.49568,2.013184,0.006144,6.912,6.668,2.9795042,1236.877312,0.012288,0.0,8.939,8.265,3.4612091,1083.592704,0.0,0.0,9.035,7.03
1442650890000,7.12912,1076.809728,3.006464,0.0,48.516,44.907,2.84828,1421.631488,0.036864,0.0,5.46,4.492,2.6984111,1145.409536,1.13664,0.008192,5.729,4.924,2.6834784,1222.299648,0.0,0.0,5.39,5.637,2.7464509,1080.090624,0.0,0.0,9.49,8.202
1442650950000,9.5253721,1081.094144,2.748416,0.0,51.215,48.456,2.8562072,1413.976064,0.032768,0.0,6.654,5.469,3.8350451,1151.922176,2.330624,0.004096,6.862,6.734,2.9954759,1223.831552,0.0,0.0,7.286,6.52,2.8847631,1072.893952,0.0,0.0,8.063,7.654
1442651010000,8.4412722,1083.846656,3.586048,0.0,48.882,46.052,3.2462444,1411.616768,0.0,0.0,6.002,5.169,2.6759831,1147.858944,1.4336,0.006144,5.807,5.063,4.3334182,1223.622656,0.0,0.0,7.605,6.36,2.9508041,1076.105216,0.0,0.0,7.213,6.882
1442651070000,9.6284501,1082.630144,3.117056,0.0,59.526,55.435,2.6667365,1420.5952,0.0,0.0,7.877,5.775,3.112072,1152.2048,1.521664,0.004096,6.366,5.674,3.7632526,1225.03168,0.0,0.0,8.33,7.035,4.3034208,1072.402432,0.0,0.0,9.566,8.648
1442651130000,8.5765465,1085.550592,4.255744,0.0,61.486,57.068,3.7469138,1422.290944,0.0,0.0,8.948,7.404,2.841864,1154.629632,1.41312,0.004096,7.311,6.246,3.5474329,1229.930496,0.0,0.0,9.0,7.461,3.1877828,1080.782848,0.0,0.0,7.67,6.624
1442651190000,9.277455,1081.7536,2.326528,0.0,52.798,48.293,3.1389373,1417.252864,0.032768,0.0,7.376,6.806,2.9744204,1153.683456,1.85344,0.01024,5.847,5.997,3.3483301,1231.937536,0.0,0.0,6.439,6.467,3.177929,1077.440512,0.0,0.0,7.796,6.218
1442651251000,7.0218621,1084.747776,3.252224,0.0,46.097,43.164,2.6095449,1418.534912,0.0,0.0,6.254,5.711,2.506416,1160.138752,1.273856,0.0,6.078,5.223,2.7207357,1231.212544,0.0,0.0,5.868,5.704,2.3419403,1078.779904,0.0,0.0,6.821,6.152
1442651312000,8.5923527,1082.159104,2.379776,0.0,50.957,46.891,2.7086201,1418.924032,0.0,0.0,6.539,5.793,2.826019,1155.690496,1.294336,0.0,6.317,5.927,4.1665757,1233.63328,0.0,0.0,8.414,7.689,2.6012468,1081.339904,0.0,0.0,6.8,6.28
1442651373000,9.4204639,1083.060224,4.349952,0.0,54.711,51.306,3.0540625,1419.55072,0.0,0.0,6.956,5.638,3.4519623,1156.36224,0.884736,0.0,6.849,5.983,3.073039,1234.075648,0.0,0.0,8.129,7.517,3.6873328,1082.421248,0.008192,0.0,7.693,7.067
1442651433000,8.3135914,1087.557632,4.661248,0.0,49.853,46.858,2.6216026,1417.85088,0.016384,0.0,4.74,4.753,2.8690068,1166.278656,1.703936,0.0,7.317,6.786,3.2655099,1235.632128,0.0,0.0,8.114,7.317,2.2666938,1077.47328,0.008192,0.0,6.639,5.202
1442651493000,3.3193349,1069.887488,2.174976,0.0,35.029,32.588,1.5824196,1396.969472,0.0,0.0,4.786,4.172,1.2875289,1143.955456,0.643072,0.0,5.027,4.25,1.1950762,1212.444672,0.0,0.0,4.638,4.009,1.2846346,1067.401216,0.0,0.0,5.904,5.422
1442651553000,7.8340736,1077.563392,2.859008,0.0,50.494,47.225,2.8130048,1407.787008,0.0,0.0,7.432,6.576,2.4564747,1147.392,1.536,0.0,4.988,4.497,3.1578122,1222.111232,0.02048,0.0,7.309,6.262,3.5310386,1075.142656,0.0,0.0,8.49,7.511
1442651613000,8.5713734,1079.115776,2.68288,0.0,45.877,42.585,2.9850996,1409.425408,0.032768,0.0,6.017,5.411,2.5312069,1143.783424,1.570816,0.0,5.768,5.155,2.9175996,1225.596928,0.0,0.0,6.413,6.08,3.3097127,1075.24096,0.0,0.0,5.974,5.173
1442651673000,8.4396465,1078.808576,2.793472,0.0,48.582,45.08,3.6984547,1410.953216,0.0,0.0,6.335,5.988,2.6667267,1146.88,1.452032,0.0,4.792,4.277,3.0220333,1229.504512,0.0,0.0,9.508,7.832,1.9560403,1074.74944,0.0,0.0,5.817,5.197
1442651734000,9.4289774,1079.7056,3.7888,0.0,71.243,65.58,3.4561702,1410.596864,0.032768,0.0,11.552,9.141,3.1645107,1147.0848,1.118208,0.0,7.545,6.575,4.0402395,1229.062144,0.0,0.0,11.116,10.079,3.4200153,1076.36736,0.0,0.0,9.117,7.909
1442651795000,8.2248736,1075.273728,3.633152,0.0,55.339,52.317,2.6455373,1412.100096,0.0,0.0,6.298,5.468,2.6607761,1148.891136,0.835584,0.0,7.028,5.432,3.9951725,1229.53728,0.0,0.0,9.725,8.839,2.6827044,1078.861824,0.0,0.0,7.663,7.17
1442651855000,10.1779409,1075.044352,3.565568,0.0,53.149,50.029,4.6534558,1416.790016,0.0,0.0,9.057,7.413,3.9911094,1151.24224,1.634304,0.0,6.758,5.89,3.0269026,1231.515648,0.0,0.0,6.027,5.84,3.2890392,1080.75008,0.0,0.0,7.223,6.006
1442651916000,4.2403019,1079.779328,3.293184,0.06144,38.913,37.014,1.8566552,1417.596928,0.04096,0.0,4.73,4.388,1.3998978,1158.295552,1.030144,0.0,5.297,4.738,1.9095991,1230.249984,0.0,0.0,6.159,5.168,1.870246,1081.38496,0.0,0.0,6.771,5.484
1442651976000,7.3957426,1079.88992,2.668544,0.016384,65.314,61.198,2.7841384,1417.334784,0.032768,0.0,9.015,6.805,2.3022454,1158.692864,1.132544,0.0,7.414,6.435,2.7753344,1231.867904,0.0,0.0,10.34,8.894,2.2516315,1079.083008,0.0,0.0,9.605,8.024
1442652036000,9.1819648,1079.676928,2.871296,0.024576,63.7,58.904,2.4523035,1418.71104,0.0,0.0,5.682,4.967,3.9088263,1158.340608,1.722368,0.0,9.241,7.191,4.1714253,1232.674816,0.004096,0.0,10.186,9.1,3.9039071,1078.583296,0.0,0.0,9.238,8.217
1442652096000,7.6514743,1080.492032,2.113536,0.024576,64.417,60.462,1.7579046,1417.293824,0.0,0.0,5.907,4.504,3.5280716,1163.620352,2.134016,0.0,9.423,8.256,3.0853354,1231.368192,0.032768,0.0,9.347,8.576,3.2948052,1075.572736,0.0,0.0,10.822,8.883
1442652157000,8.1495586,1081.83552,5.517312,0.02048,46.943,43.94,2.6161125,1403.20768,0.0,0.0,5.468,5.57,2.3967826,1153.634304,1.36192,0.0,5.721,5.65,3.1716564,1219.170304,0.0,0.0,7.135,6.043,3.1665385,1069.248512,0.0,0.0,6.97,5.956
1442652218000,7.6641097,1074.286592,2.420736,0.0,47.068,44.299,2.5351682,1404.096512,0.0,0.0,5.593,5.039,2.7789209,1154.166784,2.013184,0.012288,6.652,5.603,2.8606951,1218.883584,0.032768,0.0,6.256,4.946,3.1023644,1072.562176,0.032768,0.0,8.157,6.488
1442652278000,7.2810789,1077.530624,1.65888,0.0,48.466,45.219,2.4182366,1405.661184,0.0,0.0,5.76,5.443,1.8324433,1158.213632,1.1776,0.004096,4.463,4.254,3.1138959,1230.311424,0.0,0.0,8.41,7.455,2.6547427,1072.054272,0.0,0.0,7.565,7.598
1442652338000,8.5684745,1077.854208,2.666496,0.0,52.254,48.705,2.5775518,1406.083072,0.032768,0.0,6.453,5.555,3.3305241,1164.603392,1.859584,0.012288,8.1,6.69,2.8082936,1230.336,0.0,0.0,7.138,6.577,3.5606883,1072.377856,0.0,0.0,7.423,6.685
1442652398000,7.9707349,1084.317696,2.609152,0.0,51.091,47.877,2.6930907,1404.186624,0.06144,0.0,7.504,6.541,2.5080669,1166.66368,1.875968,0.008192,5.027,5.027,3.4980957,1232.277504,0.0,0.0,7.08,6.744,2.7034866,1078.996992,0.0,0.0,7.798,6.754
1442652458000,9.187902,1090.822144,3.182592,0.0,56.494,52.536,3.5444395,1403.895808,0.0,0.0,6.941,6.019,3.6471543,1169.1008,1.57696,0.012288,7.304,6.645,3.381224,1229.156352,0.0,0.0,7.057,5.894,3.2543934,1080.045568,0.0,0.0,9.682,8.423
1442652518000,8.3256595,1090.43712,3.76832,0.0,43.704,41.477,3.0724496,1404.145664,0.0,0.0,5.165,4.278,2.8575847,1166.884864,1.232896,0.01024,6.056,5.399,3.1881625,1227.628544,0.0,0.0,6.515,6.139,2.9290523,1078.894592,0.0,0.0,7.777,6.96
1442652579000,11.134694,1090.686976,2.686976,0.0,55.745,51.767,4.1126624,1408.114688,0.02048,0.0,6.88,6.064,4.6447774,1166.979072,1.519616,0.004096,7.805,6.701,4.1124949,1226.735616,0.0,0.0,9.586,7.172,3.8224813,1078.39488,0.032768,0.0,6.743,6.007
1442652640000,9.6314932,1092.243456,3.23584,0.0,69.418,64.497,3.6958239,1406.021632,0.0,0.0,9.674,7.381,3.7862851,1166.360576,1.708032,0.0,9.827,8.373,3.3362503,1227.546624,0.0,0.0,9.386,8.26,3.8024853,1078.816768,0.0,0.0,9.976,8.691
1442652700000,8.3352029,1091.293184,2.715648,0.0,54.104,50.548,2.8419064,1404.534784,0.024576,0.0,8.102,7.18,3.4140943,1170.014208,1.841152,0.0,6.38,6.436,2.425982,1227.341824,0.0,0.0,7.159,6.042,3.2975035,1079.799808,0.0,0.0,8.21,6.41
1442652760000,7.5009665,1082.339328,3.039232,0.0,50.491,47.538,2.2918388,1383.591936,0.126976,0.0,6.166,5.296,1.8715227,1162.104832,0.528384,0.0,4.844,3.606,3.1203709,1221.095424,0.065536,0.0,9.359,8.373,3.2599055,1071.230976,0.0,0.0,7.627,7.038
1442652820000,8.3904549,1083.654144,2.889728,0.0,52.531,48.893,4.9152153,1385.795584,0.04096,0.0,5.649,5.378,2.8425135,1161.777152,2.154496,0.0,6.564,5.877,3.1121677,1218.523136,0.032768,0.0,8.446,6.992,3.2787812,1071.075328,0.0,0.0,7.816,6.709
1442652880000,7.1381718,1085.837312,2.576384,0.0,45.185,42.625,1.8499483,1387.39712,0.0,0.0,4.956,4.619,3.41409,1164.6976,1.693696,0.0,6.978,6.111,2.7842799,1219.325952,0.032768,0.0,5.921,5.701,2.584279,1071.210496,0.0,0.0,7.395,6.535
1442652940000,9.8229014,1089.224704,3.477504,0.0,57.504,54.64,3.7098894,1389.236224,0.04096,0.0,7.98,6.797,3.0995295,1170.976768,1.624064,0.0,6.697,5.542,3.6736471,1223.94624,0.0,0.0,7.565,6.07,3.5154572,1072.3328,0.065536,0.0,9.919,9.183
1442653000000,7.4930505,1089.052672,2.568192,0.0,52.846,49.781,3.9546072,1386.622976,0.0,0.0,7.082,6.153,2.216925,1173.753856,1.714176,0.0,5.356,5.309,3.1613019,1226.420224,0.032768,0.0,8.309,7.396,2.5044944,1072.427008,0.0,0.0,8.443,8.107
1442653060000,7.7629713,1090.850816,4.27008,0.0,52.699,49.797,4.0670643,1386.74176,0.032768,0.0,6.912,6.515,2.5145512,1169.69472,1.10592,0.0,7.295,5.779,2.4578976,1228.664832,0.032768,0.0,6.832,5.466,2.7963267,1076.330496,0.0,0.0,8.012,7.228
1442653120000,7.8565608,1088.630784,3.03104,0.0,56.558,52.896,9.4015797,1388.924928,0.032768,0.0,7.937,6.793,2.3905627,1170.3296,1.542144,0.0,6.28,5.539,2.7399275,1227.943936,0.0,0.0,7.459,6.138,2.9318786,1079.95136,0.0,0.0,10.142,9.692
1442653180000,11.0388294,1088.888832,2.162688,0.0,65.599,60.676,3.8358507,1388.032,0.0,0.0,7.705,6.411,4.1037652,1170.57536,2.813952,0.0,8.841,8.027,4.4176189,1236.660224,0.0,0.0,10.031,9.225,3.1686526,1077.293056,0.0,0.0,8.069,7.851
1442653240000,8.8539692,1089.65888,2.121728,0.0,59.656,56.358,3.6893169,1386.315776,0.032768,0.0,8.454,6.899,2.2155669,1170.952192,1.292288,0.0,5.925,5.259,3.0640469,1237.057536,0.0,0.0,9.456,8.013,3.6217267,1079.128064,0.0,0.0,9.936,9.037
1442653300000,7.4747173,1106.399232,0.790528,0.0,13.897,13.007,13.9449153,1384.882176,1.097728,0.0,2.003,1.793,0.5056916,1165.438976,0.253952,0.0,1.436,1.334,0.8815192,1235.513344,0.0,0.0,2.559,2.259,1.6704141,1084.981248,0.741376,0.0,3.793,3.062
1442653360000,0.6641026,1132.531712,0.446464,0.0,9.676,9.098,93.2923924,1392.418816,0.098304,0.0,2.904,2.345,79.1860473,1174.114304,0.13312,0.0,1.597,1.524,8.7750167,1214.992384,0.753664,0.0,1.793,1.299,0.1768129,1055.727616,0.0,0.0,1.914,2.187
1442653421000,0.6207272,1123.18464,0.671744,0.0,12.074,11.185,13.0624752,1382.678528,5.210112,0.0,1.883,1.863,0.210718,1162.932224,0.206848,0.0,1.678,1.418,0.2180974,1234.812928,0.0,0.0,2.61,2.527,35.1756217,1068.87168,1.024,0.0,2.219,2.06
1442653482000,5.8756264,1119.010816,2.994176,0.0,42.691,39.528,6.3806047,1382.322176,0.0,0.0,4.802,4.223,1.8377271,1158.086656,1.101824,0.0,6.588,5.473,2.1632067,1227.853824,0.0,0.0,6.405,5.776,1.7434287,1073.815552,0.0,0.0,6.573,5.295
1442653542000,7.5135938,1119.121408,2.174976,0.0,43.007,39.692,3.274765,1385.2672,0.032768,0.0,6.029,6.165,2.5025082,1160.482816,1.646592,0.0,4.978,4.963,2.1810324,1223.573504,0.0,0.0,5.557,5.131,2.8895666,1072.570368,0.0,0.0,6.622,5.725
1442653602000,5.6019402,1122.213888,2.568192,0.0,43.257,40.351,2.0435604,1384.062976,0.012288,0.0,5.805,4.788,2.0430564,1158.098944,1.82272,0.0,5.203,5.134,1.9579712,1224.577024,0.0,0.0,7.499,5.936,2.5127418,1071.087616,0.0,0.0,6.169,5.404
1442653662000,6.2970873,1123.291136,2.06848,0.0,50.545,47.098,2.6180311,1385.672704,0.0,0.0,7.794,6.919,2.1765943,1161.801728,1.294336,0.0,5.848,5.024,2.3848545,1225.773056,0.0,0.0,6.617,6.374,2.2956128,1071.263744,0.0,0.0,7.935,6.426
1442653722000,5.583942,1126.322176,3.371008,0.0,44.596,41.048,9.7202743,1385.934848,0.065536,0.0,7.082,6.791,1.9336385,1166.60224,1.277952,0.0,5.523,4.777,2.2338278,1223.96672,0.0,0.0,5.479,4.721,1.3420586,1073.197056,0.0,0.0,5.13,5.027
1442653782000,6.9833067,1126.383616,2.646016,0.0,50.049,46.738,2.8220236,1386.53696,0.08192,0.0,6.877,6.212,2.7651935,1164.488704,1.50528,0.0,5.269,4.946,1.8454866,1221.292032,0.0,0.0,6.298,5.859,2.4779933,1071.632384,0.0,0.0,8.896,7.828
1442653843000,3.1334616,1130.283008,2.78528,0.0,33.658,31.982,1.1630651,1388.142592,0.032768,0.0,4.844,4.866,24.6643749,1175.83872,4.05504,0.0,4.219,3.784,1.2050217,1220.73088,0.0,0.0,5.081,3.964,1.3363293,1071.538176,0.0,0.0,5.599,4.884
1442653903000,8.9660272,1128.669184,2.691072,0.0,47.033,43.682,2.7979864,1386.63936,0.0,0.0,6.463,5.563,2.5229751,1170.538496,1.677312,0.0,5.427,5.059,2.7255104,1220.071424,0.0,0.0,6.34,5.708,3.4826938,1070.649344,0.0,0.0,8.063,7.782
1442653964000,9.1769933,1121.23904,3.03104,0.0,50.06,47.308,2.4504981,1360.596992,0.0,0.0,4.415,3.947,3.1890608,1159.929856,1.585152,0.0,6.257,5.617,3.1580583,1212.604416,0.0,0.0,7.909,6.77,3.8855177,1054.318592,0.0,0.0,10.283,9.398
1442654024000,5.9782569,1124.380672,2.199552,0.0,38.604,35.666,1.3765872,1364.668416,0.0,0.0,4.665,4.525,2.3830235,1161.920512,1.447936,0.0,5.482,4.834,2.3595835,1215.44704,0.0,0.0,5.901,5.596,1.662907,1056.518144,0.0,0.0,4.967,4.203
1442654084000,9.3716483,1129.639936,2.609152,0.0,53.573,50.51,2.5300102,1362.722816,0.065536,0.0,6.479,6.056,2.8490734,1165.647872,2.052096,0.0,6.369,5.914,3.5999728,1218.306048,0.0,0.0,8.552,7.697,3.077214,1058.69312,0.0,0.0,7.58,6.943
1442654144000,7.0994133,1125.736448,1.9456,0.0,47.204,43.327,1.7409838,1362.223104,0.0,0.0,7.138,6.391,1.5910125,1163.849728,0.679936,0.0,4.508,3.456,2.0421307,1222.16448,0.0,0.0,6.574,5.96,2.5764469,1060.732928,0.55296,0.0,8.43,6.316
1442654204000,8.2987691,1126.354944,3.088384,0.0,54.275,50.639,3.1977162,1360.338944,0.032768,0.0,6.519,6.222,3.6576244,1166.168064,1.441792,0.0,6.818,5.77,2.8278534,1223.548928,0.0,0.0,7.599,6.213,3.0536786,1068.240896,0.032768,0.0,9.12,8.082
1442654264000,8.703175,1129.885696,2.23232,0.0,60.918,56.525,3.8950113,1351.995392,0.0,0.0,7.649,6.485,1.7635807,1167.50336,1.294336,0.0,6.263,5.205,3.611216,1224.15104,0.0,0.0,9.659,7.444,3.661615,1072.275456,0.0,0.0,10.87,9.689
1442654324000,7.4800879,1130.221568,3.09248,0.0,54.11,49.874,3.0891453,1353.863168,0.053248,0.0,6.726,5.823,1.9323379,1166.987264,1.009664,0.0,6.814,5.742,3.1498106,1225.588736,0.032768,0.0,7.732,6.483,2.7453953,1072.713728,0.0,0.0,8.423,7.041
1442654385000,7.6628854,1130.41408,1.847296,0.0,47.658,44.759,1.9870313,1359.085568,0.008192,0.0,5.863,5.256,2.1026138,1168.95744,1.370112,0.0,5.725,4.804,2.4551904,1225.486336,0.0,0.0,7.313,6.673,2.8957077,1069.498368,0.0,0.0,8.251,7.109
1442654445000,8.8089463,1135.480832,1.56672,0.0,59.516,54.769,3.2527251,1357.262848,0.0,0.0,9.587,7.994,3.3428541,1169.477632,1.093632,0.0,5.314,4.264,3.4319904,1231.6672,0.0,0.0,7.863,6.432,3.2479321,1072.873472,0.0,0.0,9.227,8.798
1442654505000,8.9738037,1134.845952,4.087808,0.0,54.754,51.999,3.7204616,1351.573504,0.0,0.0,8.135,7.445,2.9122342,1169.801216,1.452032,0.0,6.486,5.206,3.6282601,1226.0352,0.0,0.0,8.779,6.928,3.8900484,1068.204032,0.0,0.0,9.015,7.018
1442654565000,9.4815554,1127.09632,3.764224,0.0,52.618,49.591,3.1116119,1347.346432,0.0,0.0,5.476,5.337,3.7166649,1162.477568,1.80224,0.0,8.323,7.316,3.6474069,1212.391424,0.0,0.0,6.751,5.334,2.9535263,1056.636928,0.0,0.0,9.016,7.458
1442654625000,7.9044366,1127.944192,2.965504,0.0,46.923,44.456,2.2320816,1345.29024,0.0,0.0,7.573,6.588,3.3829941,1161.478144,1.695744,0.0,6.726,5.931,2.1915243,1213.472768,0.032768,0.0,6.287,5.389,3.145025,1057.517568,0.0,0.0,6.077,5.738
1442654685000,10.0201464,1131.753472,3.084288,0.0,52.843,49.391,3.1694526,1348.5056,0.0,0.0,6.706,6.38,3.3676714,1163.81696,2.38592,0.0,6.513,6.192,3.6592362,1214.918656,0.0,0.0,6.794,5.428,4.0162613,1057.337344,0.0,0.0,8.904,7.93
1442654745000,9.0862998,1134.546944,1.507328,0.0,52.071,48.851,2.5093376,1348.75136,0.0,0.0,7.518,6.294,3.8377258,1166.942208,1.538048,0.0,7.439,6.063,3.3634711,1221.029888,0.0,0.0,7.445,6.928,3.1183734,1055.3344,0.032768,0.0,7.21,6.244
1442654805000,8.850581,1133.48608,1.871872,0.0,52.36,48.622,3.5735462,1348.46464,0.069632,0.0,8.186,7.26,2.3646291,1166.2336,1.302528,0.0,4.602,4.279,2.8378192,1218.404352,0.0,0.0,7.383,6.136,3.3831582,1059.602432,0.0,0.0,9.329,8.679
1442654865000,10.0364747,1137.639424,3.50208,0.0,59.367,55.932,4.2529403,1345.507328,0.032768,0.0,9.765,8.104,3.0588615,1176.223744,1.697792,0.0,6.919,6.299,3.7039104,1219.8912,0.0,0.0,7.488,6.189,2.9523083,1059.397632,0.0,0.0,8.45,7.345
1442654925000,8.4610803,1138.372608,1.908736,0.0,49.961,46.413,2.8518294,1345.1264,0.004096,0.0,6.846,6.719,2.6134355,1175.658496,1.398784,0.0,6.003,5.401,2.881559,1221.787648,0.0,0.0,8.616,7.479,2.5597704,1059.0208,0.0,0.0,6.145,6.057
1442654986000,7.7426959,1137.434624,3.64544,0.02048,52.35,48.815,2.5250407,1341.62432,0.0,0.0,8.042,6.865,2.8580199,1175.924736,1.601536,0.0,6.722,6.244,2.6133795,1231.302656,0.0,0.0,6.426,5.413,2.6442518,1059.467264,0.0,0.0,7.707,6.667
1442655046000,8.2043129,1136.615424,2.183168,0.02048,64.75,61.199,2.2621799,1340.276736,0.0,0.0,7.949,6.102,2.896378,1177.43616,2.02752,0.0,8.478,6.975,3.2800035,1231.716352,0.0,0.0,10.218,9.005,3.3050801,1060.159488,0.16384,0.0,10.156,8.679
1442655106000,9.6692,1137.184768,3.95264,0.024576,63.006,59.297,2.8622085,1334.841344,0.0,0.0,7.721,6.744,3.972318,1176.53504,2.209792,0.0,8.537,7.566,4.0656216,1232.080896,0.0,0.0,8.816,6.989,3.9937716,1068.158976,0.0,0.0,9.632,8.235
1442655166000,6.3506901,1128.706048,4.141056,0.012288,46.846,44.031,2.3862304,1316.265984,0.0,0.0,5.502,4.94,2.4862709,1170.038784,0.602112,0.0,5.467,3.99,2.7617889,1226.6496,0.0,0.0,7.057,6.556,2.7878199,1056.899072,0.0,0.0,7.328,6.068
1442655226000,7.9285559,1124.339712,3.252224,0.008192,60.398,56.931,3.4912309,1310.642176,0.032768,0.0,9.12,8.033,2.8627903,1170.75968,1.83296,0.0,6.662,5.896,2.7279711,1223.22944,0.0,0.0,7.632,6.974,3.3493482,1055.277056,0.024576,0.0,9.638,8.137
1442655286000,8.5389414,1127.755776,3.772416,0.02048,50.138,46.967,2.6786029,1309.732864,0.0,0.0,6.361,5.246,3.0240263,1173.413888,2.29376,0.0,6.69,6.236,2.9300436,1225.392128,0.0,0.0,6.749,5.921,3.0029841,1056.93184,0.0,0.0,7.185,6.717
1442655346000,9.1206282,1127.77216,2.818048,0.02048,56.632,52.555,4.1015099,1314.435072,0.0,0.0,8.507,8.422,2.891089,1172.91008,1.144832,0.0,5.708,4.848,3.0306624,1225.347072,0.0,0.0,9.134,7.697,3.4984017,1059.979264,0.0,0.0,7.608,6.546
1442655406000,7.6492358,1129.701376,4.206592,0.02048,55.944,52.187,2.6942096,1312.825344,0.008192,0.0,8.045,7.082,3.0914653,1174.421504,1.24928,0.0,7.318,5.868,2.6795074,1226.71104,0.0,0.0,7.146,5.918,3.3203997,1060.315136,0.0,0.0,8.949,7.07
1442655466000,7.3600089,1130.938368,3.13344,0.012288,49.948,47.155,2.4500113,1312.82944,0.032768,0.0,6.172,5.15,2.9557126,1175.814144,2.26304,0.0,9.535,8.039,2.1738316,1226.018816,0.0,0.0,6.468,5.177,2.3843036,1061.085184,0.0,0.0,6.362,5.221
1442655526000,8.0653979,1132.183552,3.661824,0.02048,48.797,45.34,3.0385565,1312.587776,0.0,0.0,6.039,4.85,2.693338,1177.767936,1.314816,0.0,6.072,5.651,2.5901401,1229.275136,0.0,0.0,6.723,6.113,2.9591756,1063.481344,0.0,0.0,7.992,6.774
1442655586000,9.2785515,1132.05248,3.436544,0.016384,65.159,61.204,2.8026771,1306.554368,0.0,0.0,9.167,7.444,3.8517845,1175.683072,1.927168,0.0,8.168,7.472,3.7426352,1226.452992,0.0,0.0,9.254,8.827,2.9719025,1059.38944,0.0,0.0,9.082,7.016
1442655646000,8.275116,1131.851776,3.760128,0.012288,57.528,53.18,3.1394855,1305.100288,0.036864,0.0,7.529,6.093,2.1232824,1176.338432,0.417792,0.0,5.785,4.445,3.523073,1227.354112,0.0,0.0,6.731,6.004,3.7383779,1062.957056,0.0,0.0,10.886,9.645
1442655706000,8.8219839,1130.835968,4.513792,0.02048,50.971,47.225,2.9999662,1303.6544,0.0,0.0,6.441,5.352,2.9194793,1176.756224,1.308672,0.0,5.25,4.947,3.9730699,1227.108352,0.0,0.0,7.754,6.964,2.9176808,1060.835328,0.004096,0.0,6.372,5.865
1442655766000,0.7685346,1122.193408,2.045952,0.02048,17.76,16.401,0.3822367,1283.719168,0.0,0.0,3.703,3.11,0.3791564,1165.733888,0.927744,0.0,4.464,3.703,0.1968984,1213.93152,0.0,0.0,2.533,2.012,0.1904606,1048.375296,0.0,0.0,1.617,1.569
1442655826000,8.383094,1130.377216,3.416064,0.008192,61.534,57.098,3.107347,1287.745536,0.0,0.0,8.22,6.136,2.379347,1175.277568,1.380352,0.0,6.8,5.571,3.602532,1223.860224,0.02048,0.0,10.177,8.104,2.866027,1055.137792,0.0,0.0,9.438,8.277
1442655886000,8.4815102,1130.10688,1.857536,0.028672,55.53,51.459,3.475212,1283.592192,0.0,0.0,7.56,7.229,3.219217,1177.378816,1.007616,0.0,6.329,4.967,3.0376391,1228.726272,0.0,0.0,8.352,8.171,3.6083948,1053.683712,0.0,0.0,8.008,6.706
1442655946000,6.4197679,1129.709568,3.15392,0.024576,48.267,45.114,1.873655,1281.888256,0.0,0.0,6.019,4.757,2.3436267,1178.312704,2.79552,0.0,7.729,7.173,2.5400452,1230.2336,0.0,0.0,7.145,6.727,2.3132728,1060.225024,0.0,0.0,7.167,6.0
1442656006000,9.0326153,1132.969984,2.023424,0.024576,50.759,47.255,3.2280056,1282.658304,0.0,0.0,6.031,5.549,3.2653691,1178.464256,0.792576,0.0,5.265,4.301,3.3114353,1229.819904,0.0,0.0,8.361,7.496,3.2385059,1061.892096,0.0,0.0,7.622,6.786
1442656066000,8.6126349,1134.149632,2.428928,0.02048,51.01,47.078,3.2081539,1283.903488,0.004096,0.0,6.173,5.774,3.5924542,1178.78784,1.810432,0.0,6.873,6.153,2.6189717,1230.811136,0.0,0.0,6.742,6.755,2.9925696,1061.613568,0.032768,0.0,8.196,6.912
1442656126000,7.8050808,1133.924352,2.961408,0.016384,54.905,51.064,2.7853811,1283.371008,0.0,0.0,7.824,6.869,2.7280343,1179.410432,1.236992,0.0,6.471,5.942,3.0589444,1230.020608,0.0,0.0,8.526,7.139,2.66583,1061.781504,0.0,0.0,7.037,7.059
1442656186000,6.547265,1133.903872,1.777664,0.004096,49.156,46.478,2.9133959,1283.805184,0.0,0.0,6.965,5.939,2.6204467,1179.656192,1.738752,0.0,7.243,6.304,2.4211726,1230.266368,0.0,0.0,7.454,6.57,2.2725201,1064.173568,0.0,0.0,6.294,5.161
1442656247000,10.120056,1131.180032,3.743744,0.012288,62.456,58.176,4.0641759,1284.349952,0.0,0.0,8.822,7.12,3.6819494,1179.242496,2.60096,0.0,7.901,7.412,4.0369626,1228.75904,0.0,0.0,8.993,6.93,2.9624354,1062.334464,0.0,0.0,8.302,7.581
1442656308000,7.7321742,1131.802624,3.44064,0.0,55.88,52.31,2.3236126,1285.709824,0.0,0.0,7.64,6.669,2.5615204,1179.783168,2.166784,0.01024,7.13,6.709,2.632854,1229.197312,0.0,0.0,7.254,5.365,3.1600823,1062.85056,0.0,0.0,7.858,7.522
1442656369000,8.0131256,1135.644672,4.435968,0.0,44.769,42.52,2.9971702,1285.46816,0.0,0.0,5.776,5.561,2.9214681,1181.302784,1.497088,0.004096,6.891,5.753,2.1828571,1230.688256,0.0,0.0,4.912,4.568,2.6857938,1063.288832,0.0,0.0,6.618,6.122
1442656429000,10.3246063,1129.472,1.76128,0.0,56.738,53.055,4.0066538,1270.800384,0.0,0.0,10.367,8.392,3.8714995,1178.640384,1.880064,0.01024,6.565,6.078,3.0824246,1229.80352,0.0,0.0,8.136,7.021,3.6438265,1060.425728,0.0,0.0,7.253,6.44
1442656489000,8.6958038,1134.247936,3.506176,0.0,55.546,51.287,3.087622,1275.04384,0.0,0.0,6.439,5.699,2.8516149,1184.989184,1.45408,0.004096,5.78,5.587,3.1685827,1231.007744,0.0,0.0,6.849,6.609,3.7573288,1064.517632,0.0,0.0,8.886,7.809
1442656549000,8.5474179,1136.017408,4.292608,0.0,48.357,45.595,3.5006567,1270.157312,0.004096,0.0,6.5,5.941,3.5610401,1182.306304,1.335296,0.006144,6.518,5.431,3.327802,1234.034688,0.0,0.0,6.927,5.623,3.0259962,1062.105088,0.012288,0.0,7.447,6.242
1442656609000,9.7910041,1138.040832,3.125248,0.0,51.722,48.447,3.7207448,1269.968896,0.0,0.0,6.806,6.506,3.4301814,1177.350144,2.033664,0.01024,7.507,6.285,3.6031532,1234.345984,0.0,0.0,6.727,5.845,3.5256598,1063.09632,0.0,0.0,7.298,6.489
1442656670000,8.2647356,1137.631232,3.964928,0.0,61.645,57.837,3.4483756,1268.338688,0.0,0.0,6.808,5.718,3.6481261,1177.329664,1.83296,0.0,8.507,7.133,2.6452626,1237.512192,0.0,0.0,8.412,7.289,3.2990009,1065.6768,0.0,0.0,10.347,9.19
1442656731000,10.951602,1137.000448,3.072,0.0,60.534,56.159,3.2897925,1273.409536,0.0,0.0,7.231,5.931,4.1998113,1176.014848,1.460224,0.0,7.775,6.729,3.9724328,1242.927104,0.0,0.0,7.85,6.99,3.8153305,1064.738816,0.0,0.0,8.823,7.414
1442656791000,9.8596172,1133.150208,4.21888,0.0,55.883,52.299,3.7369216,1265.696768,0.0,0.0,7.077,6.735,2.536627,1180.987392,1.357824,0.0,5.822,5.083,3.3473514,1244.430336,0.0,0.0,7.201,6.538,3.8630558,1066.672128,0.0,0.0,10.286,8.803
1442656851000,9.2846955,1133.809664,1.605632,0.0,51.505,48.285,3.013926,1266.60608,0.008192,0.0,8.181,7.71,3.2695576,1181.302784,1.531904,0.0,5.376,5.143,3.9264915,1238.134784,0.0,0.0,8.55,8.153,2.9751818,1066.0864,0.0,0.0,6.108,5.156
1442656911000,10.1289808,1136.308224,3.473408,0.0,66.181,62.373,4.5595898,1256.710144,0.0,0.0,9.642,8.012,4.1492964,1180.377088,2.394112,0.0,8.387,7.49,3.949496,1234.448384,0.0,0.0,9.126,7.06,4.0963617,1065.951232,0.0,0.0,10.178,8.505
1442656971000,9.3116518,1135.325184,2.31424,0.0,59.859,55.976,3.4387823,1253.584896,0.0,0.0,7.848,7.306,3.3337957,1181.995008,1.759232,0.0,6.739,6.426,2.7228572,1237.737472,0.0,0.0,8.304,7.521,4.0537114,1064.501248,0.0,0.0,9.57,7.598
1442657031000,8.2590569,1130.938368,2.310144,0.0,48.909,45.854,3.555582,1247.4368,0.008192,0.0,7.463,6.843,2.258606,1176.911872,1.138688,0.0,6.002,5.236,3.1236561,1230.319616,0.0,0.0,6.841,5.788,3.2641995,1063.95648,0.0,0.0,7.153,7.004
1442657091000,9.548645,1134.71488,2.84672,0.0,48.602,45.309,4.347468,1248.01024,0.0,0.0,7.135,6.579,2.7762105,1180.647424,0.882688,0.0,4.423,3.861,2.9605033,1232.338944,0.0,0.0,6.335,6.061,3.5795401,1066.958848,0.0,0.0,8.044,7.709
1442657151000,9.9386229,1135.0016,2.916352,0.0,62.619,58.155,3.554038,1246.74048,0.0,0.0,8.696,7.498,3.5860201,1183.813632,0.999424,0.0,6.968,5.081,4.3578088,1236.45952,0.0,0.0,9.982,8.964,3.6550067,1065.947136,0.0,0.0,8.452,7.108
1442657211000,8.6486448,1137.369088,2.736128,0.0,50.035,46.776,2.5245979,1241.686016,0.0,0.0,5.99,5.046,3.443393,1184.264192,1.488896,0.0,6.722,5.812,3.3045182,1236.840448,0.0,0.0,6.517,6.181,3.5466828,1067.843584,0.032768,0.0,7.863,6.835
1442657271000,9.7696389,1138.09408,4.091904,0.0,54.551,51.379,4.3152402,1240.65792,0.004096,0.0,8.291,7.485,2.6776328,1184.714752,1.284096,0.0,6.159,5.367,3.795713,1238.900736,0.0,0.0,8.638,8.165,3.204811,1068.064768,0.032768,0.0,7.041,5.86
1442657331000,9.7499142,1141.088256,2.306048,0.0,53.883,49.854,3.9700469,1241.829376,0.0,0.0,8.464,7.916,3.032961,1183.690752,1.595392,0.0,5.147,4.726,3.0442254,1239.896064,0.0,0.0,7.766,6.725,3.3971722,1068.720128,0.0,0.0,7.49,6.451
1442657391000,11.6010556,1142.62016,1.994752,0.0,69.676,64.434,5.259874,1240.891392,0.0,0.0,9.766,8.682,3.8026168,1183.694848,2.023424,0.0,8.177,7.554,4.4327674,1242.013696,0.0,0.0,9.608,8.755,4.3692392,1067.606016,0.0,0.0,9.43,8.515
1442657451000,10.380116,1144.578048,2.953216,0.0,68.97,64.361,4.2776538,1244.061696,0.0,0.0,9.148,7.771,4.0149845,1185.128448,1.855488,0.0,8.564,7.809,4.1568001,1239.805952,0.0,0.0,9.047,8.142,3.8346818,1069.256704,0.0,0.0,10.436,8.359
1442657511000,10.040492,1145.663488,2.932736,0.0,62.364,58.521,3.4787665,1242.394624,0.028672,0.0,9.682,7.821,4.5937951,1185.886208,1.959936,0.0,9.245,7.647,3.8944413,1243.979776,0.0,0.0,7.202,5.622,3.5027116,1066.225664,0.0,0.0,8.427,8.186
1442657571000,9.6663871,1143.517184,3.477504,0.0,56.288,53.208,2.8430147,1241.82528,0.0,0.0,6.916,6.015,3.6144272,1186.902016,1.441792,0.0,8.132,6.916,3.5719766,1250.689024,0.0,0.0,7.842,6.638,4.1007356,1063.555072,0.0,0.0,8.892,8.248
1442657632000,6.8775378,1134.948352,2.732032,0.0,48.682,46.274,2.550376,1229.287424,0.0,0.0,6.583,4.878,2.0831518,1173.553152,1.017856,0.0,4.868,4.713,3.0468542,1242.345472,0.032768,0.0,8.392,6.626,2.339702,1062.674432,0.0,0.0,8.342,7.329
1442657692000,9.3940681,1137.102848,3.03104,0.0,59.276,55.179,4.2032156,1230.245888,0.0,0.0,7.345,6.931,2.4955917,1182.851072,1.959936,0.0,6.94,6.241,3.181655,1244.291072,0.0,0.0,8.678,7.761,3.032986,1065.320448,0.0,0.0,9.119,7.713
1442657752000,8.1456601,1141.92384,3.424256,0.0,57.838,53.777,1.5178609,1229.283328,0.0,0.0,7.19,5.634,3.414094,1185.23904,1.490944,0.0,6.465,5.443,3.3734224,1248.907264,0.0,0.0,8.641,7.604,3.3539569,1065.009152,0.0,0.0,8.97,8.372
1442657812000,8.9565128,1139.89632,2.3552,0.0,55.146,50.595,4.3096692,1233.473536,0.0,0.0,8.134,7.292,2.525421,1184.927744,1.314816,0.0,4.902,4.312,3.1929832,1245.745152,0.0,0.0,7.531,6.979,3.4682425,1063.051264,0.0,0.0,8.45,7.604
1442657872000,7.9232436,1139.48672,3.680256,0.0,45.272,42.892,3.1703216,1228.156928,0.0,0.0,6.688,5.861,2.4425367,1185.66912,1.386496,0.0,5.286,5.178,3.3853433,1248.063488,0.016384,0.0,6.649,6.101,2.9438486,1063.944192,0.032768,0.0,7.03,5.745
1442657932000,7.164556,1139.675136,3.588096,0.0,48.633,45.549,2.6839752,1225.125888,0.0,0.0,7.324,6.313,2.1594427,1183.870976,1.923072,0.0,5.71,5.816,2.0307877,1247.485952,0.0,0.0,6.356,6.211,2.6415657,1069.268992,0.0,0.0,7.395,6.02
1442657992000,7.4245317,1140.957184,4.083712,0.0,58.042,53.221,2.748943,1221.091328,0.0,0.0,7.517,6.595,2.8213265,1183.690752,0.919552,0.0,5.715,4.529,3.6485364,1249.808384,0.0,0.0,7.84,7.331,2.7238069,1069.215744,0.0,0.0,9.301,8.63
1442658053000,8.43197,1142.489088,2.711552,0.0,46.901,44.315,2.3907059,1228.099584,0.016384,0.0,5.435,5.201,2.6880884,1185.185792,1.918976,0.0,6.534,6.123,2.7263576,1248.858112,0.0,0.0,6.425,5.507,3.5280796,1067.814912,0.0,0.0,8.428,7.222
1442658113000,8.7989634,1146.04032,3.080192,0.0,52.202,48.696,2.5971679,1228.120064,0.0,0.0,5.612,4.387,3.7850455,1189.494784,1.830912,0.0,7.602,6.799,3.4566782,1254.293504,0.0,0.0,9.183,7.918,3.1286821,1069.416448,0.0,0.0,7.134,6.362
1442658173000,9.4037312,1142.951936,2.914304,0.0,58.104,54.107,3.3450478,1227.288576,0.0,0.0,8.766,7.854,2.4714004,1186.521088,1.513472,0.0,5.622,5.24,3.6618897,1251.037184,0.004096,0.0,9.275,7.579,3.5234877,1067.065344,0.0,0.0,8.092,7.543
1442658233000,8.1855326,1130.422272,2.60096,0.0,53.354,49.513,2.9947408,1219.616768,0.0,0.0,5.956,5.696,3.2170607,1180.03712,1.468416,0.0,6.258,5.397,2.6483506,1240.481792,0.0,0.0,8.03,6.421,3.060533,1057.796096,0.0,0.0,7.898,7.665
1442658293000,10.6548967,1134.94016,3.244032,0.0,57.198,53.399,4.4243658,1222.557696,0.0,0.0,7.583,6.641,3.3927255,1182.744576,1.39264,0.0,7.832,6.219,3.6100744,1243.148288,0.0,0.0,5.967,5.349,3.5893366,1063.632896,0.0,0.0,10.127,8.155
1442658353000,9.5110349,1139.044352,3.465216,0.0,49.394,46.718,4.0563268,1225.080832,0.0,0.0,7.051,6.564,3.8450884,1185.685504,1.59744,0.0,6.1,5.686,2.588594,1247.06816,0.0,0.0,6.564,5.388,3.3065902,1063.809024,0.0,0.0,7.236,6.443
1442658414000,8.5930513,1141.927936,3.813376,0.0,49.179,46.175,3.2853274,1216.499712,0.0,0.0,6.305,5.536,2.4932099,1186.213888,1.583104,0.0,5.43,4.742,3.9771391,1246.195712,0.0,0.0,9.231,7.511,3.3094041,1062.649856,0.032768,0.0,6.277,6.216
1442658475000,7.2376532,1139.4048,3.196928,0.0,50.676,47.005,2.493144,1212.203008,0.0,0.0,7.337,6.935,2.425288,1184.649216,1.435648,0.0,6.114,5.625,1.974473,1253.4784,0.0,0.0,6.079,5.415,2.6449161,1067.720704,0.0,0.0,7.0,6.155
1442658535000,7.62187,1143.369728,1.982464,0.0,49.28,46.056,2.9467911,1214.746624,0.0,0.0,7.346,6.31,2.3210833,1183.80544,1.826816,0.0,7.601,7.195,2.0488685,1252.503552,0.0,0.0,6.013,4.979,2.8369731,1067.855872,0.0,0.0,6.952,5.887
1442658595000,6.9805362,1146.986496,3.452928,0.0,46.862,43.84,2.0345811,1210.769408,0.0,0.0,5.813,4.562,2.4444332,1180.991488,1.624064,0.0,5.742,5.034,2.6429673,1255.530496,0.0,0.0,8.13,6.142,2.6687361,1068.384256,0.0,0.0,6.397,6.276
1442658655000,9.0107501,1144.922112,3.874816,0.0,46.656,43.571,3.3015253,1206.74304,0.0,0.0,6.73,6.101,2.6639124,1194.913792,0.751616,0.0,4.601,3.68,3.3951233,1252.16768,0.0,0.0,6.359,5.631,2.7502343,1065.009152,0.032768,0.0,6.612,6.044
1442658715000,7.423033,1143.672832,2.985984,0.0,52.249,48.722,2.4580619,1206.263808,0.0,0.0,7.065,6.556,2.6638795,1195.487232,1.656832,0.0,6.121,6.133,3.7842393,1254.805504,0.0,0.0,9.466,7.844,2.0903633,1060.651008,0.0,0.0,5.979,5.348
1442658775000,8.008,1140.719616,2.719744,0.0,49.957,47.276,3.6194771,1231.613952,0.0,0.0,8.238,6.623,2.5722633,1187.30752,1.202176,0.0,5.22,4.53,2.5725364,1248.366592,0.0,0.0,7.164,5.948,3.3087802,1061.920768,0.0,0.0,8.31,7.879
1442658835000,6.7990805,1135.640576,2.306048,0.0,35.35,32.819,2.0934598,1210.49088,0.0,0.0,5.465,5.651,3.2362268,1181.925376,1.605632,0.0,5.706,5.183,1.7351447,1244.299264,0.0,0.0,3.608,3.385,2.4352633,1059.172352,0.032768,0.0,4.954,4.633
1442658895000,7.4518682,1138.2784,3.713024,0.0,53.396,50.012,3.1403931,1199.529984,0.0,0.0,8.32,7.573,2.3883695,1181.560832,0.731136,0.0,6.292,5.013,3.0723301,1243.353088,0.0,0.0,8.04,6.857,2.378368,1060.716544,0.0,0.0,6.357,6.104
1442658955000,7.9452125,1137.64352,3.80928,0.0,50.064,47.202,4.3590636,1200.283648,0.0,0.0,7.523,6.866,2.0299278,1182.5152,0.735232,0.0,4.935,4.169,2.4307037,1247.019008,0.0,0.0,7.385,6.297,2.5717583,1062.895616,0.0,0.0,7.461,6.154
1442659015000,7.8802071,1139.572736,3.162112,0.0,57.227,53.845,2.7182418,1202.331648,0.0,0.0,6.138,4.811,2.6184814,1182.101504,0.919552,0.0,6.152,4.865,3.7183511,1257.75872,0.032768,0.0,9.125,8.466,3.0245984,1065.029632,0.0,0.0,9.874,8.15
1442659075000,8.1910999,1145.655296,3.8912,0.0,54.85,51.422,3.4240344,1203.331072,0.0,0.0,7.575,6.384,3.4877708,1185.44384,1.472512,0.0,6.991,6.243,3.1736266,1259.3152,0.0,0.0,8.312,6.87,3.1188794,1065.484288,0.0,0.0,7.309,7.562
1442659135000,8.269783,1148.944384,2.551808,0.0,44.933,42.026,3.1832425,1194.68032,0.0,0.0,5.49,5.087,3.0230594,1183.096832,1.656832,0.0,6.394,5.647,3.128048,1250.930688,0.0,0.0,5.955,5.519,2.8049124,1060.519936,0.0,0.0,5.905,5.219
1442659195000,8.0866674,1149.321216,1.8432,0.0,51.026,47.214,2.9879153,1194.94656,0.0,0.0,6.472,5.402,2.5870936,1190.649856,1.189888,0.0,5.948,4.766,2.8392603,1247.268864,0.0,0.0,7.192,6.17,3.5050581,1060.22912,0.0,0.0,9.183,7.73
1442659255000,6.1104249,1146.568704,2.904064,0.0,43.716,41.192,3.1019411,1191.919616,0.0,0.0,6.531,5.924,1.5937301,1188.225024,0.780288,0.0,4.707,4.455,2.4697559,1253.974016,0.0,0.0,7.856,6.967,2.0682515,1063.936,0.0,0.0,5.971,5.387
1442659316000,6.6426688,1145.48736,2.516992,0.004096,41.382,38.415,2.5420222,1186.271232,0.0,0.0,5.465,4.924,2.088144,1189.666816,1.337344,0.0,5.773,4.714,1.8097759,1259.511808,0.0,0.0,4.557,4.194,6.4911394,1064.566784,0.098304,0.0,6.362,5.956
1442659376000,7.8862913,1142.923264,1.5872,0.02048,51.887,48.147,2.7234794,1184.784384,0.0,0.0,6.59,5.989,2.8651165,1191.8336,1.896448,0.0,6.211,5.88,2.9679692,1261.088768,0.0,0.0,7.419,6.369,2.7288407,1065.967616,0.004096,0.0,8.86,7.337
1442659436000,6.8629937,1139.048448,4.608,0.012288,51.077,48.218,2.8958373,1176.465408,0.0,0.0,8.259,7.083,2.4244995,1187.454976,1.59744,0.0,6.038,5.353,2.6941428,1250.377728,0.0,0.0,7.322,5.354,2.9283391,1062.944768,0.0,0.0,7.191,6.275
1442659496000,9.0324949,1142.812672,2.203648,0.02048,57.641,53.42,3.8675414,1176.6784,0.0,0.0,7.522,6.358,3.2894674,1187.217408,1.200128,0.0,7.588,6.254,3.2641847,1252.564992,0.0,0.0,8.2,7.356,3.1728061,1061.474304,0.0,0.0,7.695,6.741
1442659556000,6.6652639,1147.654144,2.70336,0.016384,37.17,34.792,2.1490341,1176.510464,0.0,0.0,5.943,5.131,2.4425286,1187.295232,1.050624,0.0,5.332,4.772,2.6065185,1258.315776,0.0,0.0,5.235,5.103,2.8267809,1062.6048,0.0,0.0,5.138,4.55
1442659617000,8.3317207,1143.66464,3.11296,0.012288,47.256,45.06,2.9471649,1179.713536,0.0,0.0,6.921,6.548,3.1808979,1190.047744,0.95232,0.0,6.259,5.136,3.0392425,1257.49248,0.0,0.0,6.477,5.939,2.6075289,1066.766336,0.0,0.0,7.921,6.564
1442659678000,7.0942981,1142.562816,2.871296,0.0,47.916,44.452,2.2318395,1180.073984,0.0,0.0,4.926,4.674,2.7140834,1190.285312,1.716224,0.01024,8.173,6.864,2.0906262,1259.02848,0.0,0.0,6.073,5.344,2.3548102,1064.431616,0.0,0.0,6.747,6.418
1442659738000,9.2952495,1147.838464,3.25632,0.0,65.118,61.231,3.2621251,1183.70304,0.0,0.0,7.791,6.091,4.2508486,1190.83008,1.349632,0.006144,9.555,7.521,3.7982383,1259.933696,0.032768,0.0,10.273,8.919,4.0353047,1066.217472,0.0,0.0,8.011,6.258
1442659798000,5.8117663,1146.073088,3.600384,0.0,42.845,40.248,2.5609703,1181.323264,0.0,0.0,4.815,4.82,1.2922038,1191.038976,0.67584,0.014336,4.642,3.743,2.1481927,1259.372544,0.0,0.0,6.71,5.725,2.0879753,1065.242624,0.02048,0.0,7.455,5.829
1442659858000,7.0375513,1146.331136,3.182592,0.0,49.327,46.76,3.5609686,1182.65856,0.032768,0.0,10.112,9.558,1.5513149,1190.334464,0.104448,0.01024,4.756,3.43,2.3937115,1262.174208,0.0,0.0,6.738,6.022,2.4068987,1065.6768,0.032768,0.0,7.553,6.633
1442659918000,7.1170733,1146.08128,1.425408,0.0,50.571,46.854,2.388827,1183.686656,0.0,0.0,6.71,5.815,2.5445551,1192.443904,1.314816,0.012288,6.299,5.639,2.5077429,1263.435776,0.0,0.0,7.59,6.816,2.7098826,1066.156032,0.0,0.0,8.067,6.965
1442659978000,7.676825,1149.231104,2.82624,0.0,51.497,47.807,3.0179046,1182.191616,0.0,0.0,7.26,6.077,1.9755425,1191.235584,1.60768,0.01024,7.051,6.099,2.8794918,1264.586752,0.0,0.0,7.161,6.424,2.8375897,1067.008,0.0,0.0,6.679,6.349
1442660039000,9.7952504,1133.498368,1.974272,0.004096,43.803,40.78,2.8059647,1155.391488,0.0,0.0,5.173,4.861,2.8089113,1174.945792,1.005568,0.004096,4.555,3.95,4.5989646,1246.633984,0.04096,0.0,7.666,7.817,4.1449478,1052.93824,0.0,0.0,7.204,6.033
1442660100000,9.965951,1141.248,4.378624,0.0,61.546,57.995,3.4307257,1171.623936,0.0,0.0,9.939,8.976,3.0824197,1192.370176,1.726464,0.0,6.251,5.738,3.9146636,1255.460864,0.032768,0.0,9.792,7.668,4.027088,1056.923648,0.0,0.0,7.478,6.379
1442660161000,6.9124258,1146.273792,3.11296,0.0,42.871,39.47,2.9616007,1172.017152,0.0,0.0,7.505,6.859,2.096805,1189.781504,1.60768,0.0,5.604,4.742,3.0706555,1257.414656,0.0,0.0,5.442,5.15,1.821913,1060.306944,0.0,0.0,5.143,4.564
1442660221000,8.9572563,1147.20768,3.178496,0.0,53.349,49.984,3.0352623,1169.096704,0.0,0.0,6.738,6.563,4.2920243,1185.533952,2.043904,0.0,7.988,6.947,3.4764061,1260.077056,0.0,0.0,6.894,5.899,3.1548579,1064.419328,0.0,0.0,7.616,6.677
1442660281000,8.168429,1149.431808,3.469312,0.0,62.357,58.717,2.765913,1172.877312,0.0,0.0,8.222,7.217,2.5904333,1187.04128,0.946176,0.0,6.255,5.162,3.5782002,1265.045504,0.0,0.0,10.945,9.606,3.2944908,1060.00384,0.0,0.0,9.36,7.722
1442660341000,8.5229751,1144.836096,3.055616,0.0,50.499,46.995,3.3811201,1176.952832,0.0,0.0,6.936,6.079,3.0601257,1189.842944,1.255424,0.0,5.513,5.157,3.0888052,1266.839552,0.0,0.0,7.455,6.909,2.8514377,1061.879808,0.032768,0.0,7.085,6.058
1442660401000,7.7918641,1147.346944,3.690496,0.0,46.892,44.016,2.908693,1175.36768,0.0,0.0,6.989,5.989,2.765806,1191.440384,1.697792,0.0,5.311,5.109,2.8251064,1268.690944,0.0,0.0,6.025,5.327,3.3343279,1061.732352,0.0,0.0,6.686,6.211
1442660462000,8.4129334,1149.382656,2.523136,0.0,54.087,49.81,3.0398747,1176.203264,0.0,0.0,7.385,6.052,1.8269042,1190.301696,1.275904,0.0,4.864,4.006,3.1682534,1270.423552,0.0,0.0,7.414,6.336,3.0198142,1061.781504,0.0,0.0,9.416,9.214
1442660523000,8.0966879,1155.149824,1.826816,0.0,56.253,51.55,2.377952,1181.335552,0.0,0.0,6.303,5.498,4.7184997,1195.020288,2.224128,0.0,8.211,7.691,2.6984457,1271.308288,0.0,0.0,7.413,7.302,2.8365577,1064.132608,0.0,0.0,7.557,7.204
1442660583000,10.9044451,1152.04096,1.80224,0.0,68.282,63.906,4.1635566,1181.790208,0.0,0.0,9.375,7.053,4.5023821,1199.45216,1.654784,0.0,8.779,7.534,3.8467819,1266.040832,0.0,0.0,10.386,9.076,3.5695942,1067.032576,0.0,0.0,9.644,8.183
1442660643000,6.3887072,1147.404288,2.643968,0.0,51.06,48.281,2.873528,1175.855104,0.0,0.0,8.13,6.789,2.311099,1190.965248,1.335296,0.0,7.57,6.014,1.8041199,1253.085184,0.0,0.0,5.453,4.666,2.9664161,1064.828928,0.012288,0.0,8.81,6.765
1442660703000,8.4099882,1148.018688,2.70336,0.0,54.397,49.878,3.4585898,1172.127744,0.0,0.0,7.501,6.691,2.6975807,1192.448,1.49504,0.0,7.62,5.825,2.9931258,1249.857536,0.0,0.0,8.307,6.343,2.7759786,1063.38304,0.0,0.0,6.399,6.015
1442660763000,7.6051679,1152.544768,3.506176,0.0,49.707,46.489,2.6645588,1177.751552,0.0,0.0,7.395,6.332,2.5019401,1195.47904,1.015808,0.0,5.904,4.836,3.1693884,1246.334976,0.0,0.0,7.303,6.135,2.7352061,1065.639936,0.0,0.0,7.422,7.274
1442660823000,8.4860831,1151.827968,2.179072,0.0,41.831,39.045,1.9734781,1173.614592,0.004096,0.0,6.158,5.531,2.6585704,1194.51648,0.759808,0.0,4.423,3.621,3.7122651,1250.889728,0.024576,0.0,6.538,6.294,2.8157941,1058.889728,0.0,0.0,5.985,5.441
1442660883000,10.1933161,1150.128128,2.72384,0.0,57.86,54.747,3.8002733,1174.933504,0.0,0.0,7.094,5.5,4.4186571,1192.054784,2.500608,0.0,10.07,8.53,3.639373,1252.82304,0.0,0.0,7.713,6.482,3.9415729,1058.840576,0.0,0.0,8.422,7.831
1442660944000,9.4974158,1149.718528,2.912256,0.0,53.219,49.724,3.5865433,1177.726976,0.0,0.0,7.16,6.096,4.0016138,1192.165376,2.279424,0.0,7.242,7.082,3.3605393,1250.414592,0.0,0.0,7.848,6.555,2.8965459,1060.069376,0.0,0.0,7.421,6.767
1442661004000,9.1761001,1152.98304,2.830336,0.0,54.628,51.713,4.1687048,1176.936448,0.032768,0.0,10.633,7.872,2.9678114,1196.335104,1.757184,0.0,6.816,6.256,3.3033092,1252.0448,0.0,0.0,7.028,5.863,3.9031477,1062.187008,0.0,0.0,6.732,6.308
1442661064000,6.7419311,1159.7824,2.531328,0.0,56.406,53.254,2.9000004,1175.191552,0.0,0.0,8.327,6.524,2.2384887,1193.422848,1.380352,0.0,5.347,4.521,3.4285959,1249.169408,0.0,0.0,7.526,6.518,2.5088819,1064.923136,0.131072,0.0,11.089,9.488
1442661124000,6.51399,1159.503872,3.956736,0.0,43.108,40.358,2.7233757,1177.894912,0.0,0.0,7.674,7.347,1.4412202,1191.03488,1.292288,0.0,4.347,4.24,1.8194215,1252.753408,0.0,0.0,4.609,4.024,2.4468919,1067.872256,0.0,0.0,6.912,5.991
1442661184000,6.6995197,1157.402624,3.25632,0.0,38.794,36.59,2.902278,1176.715264,0.0,0.0,5.323,5.09,1.8603216,1191.600128,0.770048,0.0,5.046,4.243,2.7464627,1248.17408,0.032768,0.0,6.05,6.155,2.6285849,1068.638208,0.0,0.0,5.052,4.792
1442661244000,6.282205,1155.141632,3.690496,0.0,47.339,44.427,2.0600998,1176.51456,0.0,0.0,5.116,4.842,1.8758651,1189.072896,1.622016,0.0,5.903,5.489,1.9737157,1246.121984,0.0,0.0,6.325,6.309,2.6767833,1068.515328,0.0,0.0,7.881,6.867
1442661304000,8.0672882,1151.369216,2.424832,0.0,51.035,48.219,3.0182754,1169.719296,0.032768,0.0,7.979,6.922,2.6503471,1183.809536,1.595392,0.0,5.43,5.359,3.2463143,1242.370048,0.032768,0.0,7.58,6.54,3.2416401,1060.589568,0.0,0.0,7.921,6.554
1442661364000,8.8511221,1151.987712,3.080192,0.0,54.341,50.312,2.9880588,1180.995584,0.0,0.0,7.605,6.7,2.2000448,1184.722944,0.800768,0.0,5.258,4.498,4.2447121,1246.502912,0.0,0.0,8.672,7.82,3.2446507,1061.02784,0.0,0.0,7.583,6.488
1442661425000,7.602579,1150.705664,4.317184,0.0,53.295,49.717,2.3435503,1178.33728,0.0,0.0,7.598,6.926,2.0269056,1187.700736,1.216512,0.0,5.776,5.286,3.293043,1247.768576,0.0,0.0,7.104,6.712,3.5658014,1063.366656,0.0,0.0,7.323,6.19
1442661485000,8.4490267,1152.1024,2.392064,0.0,55.94,51.94,3.421813,1181.51168,0.0,0.0,6.863,6.168,2.8000277,1189.425152,1.118208,0.0,6.001,5.142,3.218259,1246.171136,0.0,0.0,7.343,7.426,3.4327933,1064.685568,0.0,0.0,8.849,8.141
1442661545000,7.9629338,1157.12,2.482176,0.0,52.819,48.922,2.7026271,1178.238976,0.0,0.0,6.669,6.104,2.5420372,1195.905024,1.45408,0.0,5.306,5.015,2.7509624,1246.384128,0.016384,0.0,6.809,6.166,3.1388271,1064.316928,0.0,0.0,9.744,8.596
1442661605000,10.1675638,1156.44416,2.691072,0.0,54.491,51.596,4.5637541,1180.336128,0.0,0.0,7.175,6.253,3.4212095,1199.14496,1.097728,0.0,6.554,5.228,3.212315,1247.371264,0.0,0.0,7.508,6.938,3.9673065,1061.834752,0.0,0.0,10.323,8.508
1442661666000,8.0675694,1156.784128,2.3552,0.02048,48.967,45.355,3.9560338,1178.238976,0.0,0.0,6.576,6.233,2.7962456,1199.382528,1.458176,0.0,7.943,6.108,3.3949254,1247.961088,0.0,0.0,6.793,5.814,2.0235234,1062.944768,0.0,0.0,4.64,4.483
1442661726000,8.2953083,1158.234112,2.584576,0.02048,52.35,48.352,2.5524404,1175.63392,0.012288,0.0,6.575,4.989,3.3984021,1204.236288,2.297856,0.0,7.195,6.326,3.1096834,1250.848768,0.0,0.0,7.21,6.116,3.3427996,1062.289408,0.0,0.0,8.89,7.355
1442661786000,9.5470668,1159.409664,3.551232,0.02048,49.781,47.413,3.5476117,1172.000768,0.0,0.0,6.281,4.926,3.4036933,1200.480256,1.370112,0.0,5.893,5.152,3.6212554,1252.10624,0.0,0.0,7.72,7.191,3.8270608,1059.008512,0.0,0.0,8.978,8.018
1442661847000,8.8902902,1160.495104,3.52256,0.02048,50.559,48.056,3.3556969,1168.797696,0.0,0.0,6.669,6.328,3.3290012,1200.381952,1.466368,0.0,7.282,6.302,4.6394462,1252.466688,0.0,0.0,7.974,7.164,2.8805682,1060.524032,0.0,0.0,6.658,5.72
1442661908000,7.4518214,1154.12992,3.526656,0.0,49.991,46.595,2.8081611,1165.799424,0.0,0.0,8.053,5.895,3.1640688,1194.409984,1.652736,0.0,7.024,6.02,2.6187893,1247.952896,0.0,0.0,5.164,5.02,2.6518297,1056.452608,0.0,0.0,7.736,6.791
1442661968000,9.0748087,1156.775936,3.65568,0.0,57.966,54.537,3.4522678,1165.582336,0.0,0.0,7.269,6.631,2.9511888,1195.507712,1.81248,0.0,6.337,5.875,3.9386267,1249.583104,0.0,0.0,8.733,6.31,3.7827347,1060.704256,0.0,0.0,10.218,7.854
1442662028000,7.2606457,1156.395008,2.19136,0.0,44.594,42.035,2.5031211,1164.562432,0.0,0.0,6.625,5.455,2.4432094,1196.490752,0.999424,0.0,5.402,4.774,2.7595413,1261.44512,0.0,0.0,7.701,7.356,2.8564792,1068.371968,0.0,0.0,5.664,4.502
1442662088000,6.1011441,1157.136384,3.688448,0.0,41.993,39.747,2.1192165,1167.888384,0.004096,0.0,6.036,5.784,2.1985049,1198.432256,1.619968,0.0,5.441,5.789,1.9995158,1261.187072,0.0,0.0,7.099,5.513,2.3563173,1071.091712,0.0,0.0,5.698,4.753
1442662148000,9.6308227,1158.254592,1.49504,0.0,64.287,59.335,3.3439867,1181.53216,0.0,0.0,7.849,7.159,2.6688702,1198.170112,1.445888,0.0,6.158,5.033,3.3463637,1257.705472,0.0,0.0,9.032,8.302,3.84014,1072.410624,0.0,0.0,11.144,9.971
1442662208000,7.0489319,1160.101888,2.668544,0.0,48.649,44.997,2.0170161,1179.455488,0.004096,0.0,6.344,5.425,2.2261103,1197.903872,1.562624,0.0,5.473,5.103,2.9823251,1255.886848,0.0,0.0,7.771,6.183,2.846854,1073.086464,0.0,0.0,6.512,6.688
1442662269000,7.2705409,1160.364032,2.871296,0.0,43.171,40.556,2.5343724,1187.40992,0.004096,0.0,5.724,5.301,2.8048859,1200.070656,1.486848,0.006144,6.245,5.485,2.2161783,1255.653376,0.0,0.0,5.844,4.943,2.1237707,1075.003392,0.0,0.0,5.725,5.539
1442662329000,7.8831903,1160.9088,1.548288,0.0,56.941,53.521,2.9420325,1185.222656,0.0,0.0,7.74,6.255,2.9972398,1202.864128,1.703936,0.01024,7.401,6.501,3.0756386,1256.738816,0.0,0.0,7.21,5.87,3.5433867,1078.1696,0.0,0.0,9.415,8.327
1442662389000,8.2454303,1159.18848,2.752512,0.0,57.018,53.297,3.4528326,1182.593024,0.0,0.0,9.278,7.884,3.0298963,1198.219264,1.529856,0.01024,6.579,6.092,3.443742,1255.30112,0.0,0.0,6.619,5.193,3.0346887,1075.273728,0.0,0.0,9.444,7.956
1442662449000,8.978464,1164.906496,3.407872,0.0,57.205,53.64,4.638421,1190.64576,0.0,0.0,8.541,7.651,3.1782708,1199.681536,1.118208,0.014336,6.551,5.809,3.0767543,1256.841216,0.0,0.0,8.652,6.518,3.0211887,1075.13856,0.008192,0.0,8.397,7.711
1442662509000,8.247554,1153.90464,1.417216,0.0,46.314,43.38,3.3952018,1176.772608,0.0,0.0,6.903,6.025,2.3686502,1198.825472,1.31072,0.028672,5.831,4.961,3.4617651,1248.93184,0.0,0.0,6.267,5.529,2.8274002,1064.054784,0.0,0.0,6.819,6.174
1442662569000,7.8107444,1154.064384,2.547712,0.0,44.065,41.039,3.3327367,1172.348928,0.0,0.0,6.146,5.805,2.7900967,1196.253184,1.45408,0.01024,5.462,5.279,2.7963113,1248.825344,0.004096,0.0,5.899,5.192,3.0465012,1065.017344,0.0,0.0,7.287,6.589
1442662629000,11.811209,1158.971392,3.796992,0.0,42.555,40.034,3.3032176,1177.120768,0.032768,0.0,5.848,5.682,2.2510732,1199.18592,0.698368,0.01024,4.438,4.019,2.6350494,1252.982784,0.0,0.0,6.748,5.809,3.277217,1065.63584,0.0,0.0,6.671,6.163
1442662690000,7.2425286,1160.347648,4.435968,0.0,38.039,35.843,2.3447036,1176.41216,0.0,0.0,3.999,3.751,1.5561805,1200.431104,1.415168,0.012288,4.986,4.381,3.0961726,1253.45792,0.0,0.0,6.039,5.273,2.8566574,1065.938944,0.0,0.0,6.064,5.822
1442662750000,10.1426407,1158.955008,1.589248,0.0,68.982,64.694,4.4958986,1179.877376,0.0,0.0,9.843,8.133,4.1100863,1200.791552,1.470464,0.008192,8.895,7.087,3.4908712,1253.920768,0.032768,0.0,9.156,7.837,4.3109485,1066.135552,0.049152,0.0,11.753,9.141
1442662810000,10.9357307,1160.507392,3.821568,0.0,54.213,51.483,3.5362821,1178.537984,0.012288,0.0,6.792,5.901,3.9532687,1201.594368,1.511424,0.006144,7.017,6.499,4.1241113,1253.96992,0.0,0.0,7.874,6.48,3.5045964,1068.470272,0.0,0.0,7.982,7.278
1442662870000,8.9127438,1162.051584,1.585152,0.0,55.973,52.901,3.3510203,1178.636288,0.032768,0.0,8.991,7.948,3.0135227,1200.90624,0.587776,0.012288,6.651,4.551,4.1289232,1254.887424,0.0,0.0,7.629,6.073,4.1510344,1069.60896,0.0,0.0,9.681,8.503
1442662930000,9.6729471,1161.40032,3.186688,0.0,63.135,59.446,4.664502,1180.905472,0.0,0.0,8.207,7.931,2.7956443,1201.455104,1.0752,0.01024,6.156,5.216,4.2075682,1259.143168,0.0,0.0,9.449,8.134,4.3578972,1071.80032,0.0,0.0,11.42,9.881
1442662990000,8.8150044,1162.645504,3.960832,0.0,47.967,44.905,3.3542974,1179.410432,0.008192,0.0,5.721,5.674,3.7799327,1202.74944,1.615872,0.01024,6.853,5.768,3.3475007,1258.512384,0.0,0.0,6.779,5.76,3.4028055,1071.157248,0.0,0.0,7.15,5.803
1442663051000,9.7950674,1161.85088,1.128448,0.0,58.754,55.532,3.6217352,1180.807168,0.0,0.0,8.468,7.735,3.0228515,1202.85184,2.086912,0.0,6.248,5.771,3.6866031,1259.3152,0.0,0.0,8.176,6.914,3.1283531,1070.358528,0.0,0.0,8.918,7.921
1442663111000,3.9138799,1155.018752,1.998848,0.0,41.068,38.628,11.6150348,1166.56128,3.936256,0.0,5.942,5.205,1.4857346,1193.353216,0.733184,0.0,5.458,4.584,1.1116612,1249.062912,0.0,0.0,5.525,5.246,1.6915473,1061.92896,0.0,0.0,7.107,5.753
1442663171000,7.2410361,1155.87072,3.917824,0.0,49.521,46.64,2.7246115,1166.09024,0.0,0.0,5.926,5.354,2.5215023,1195.687936,1.357824,0.0,6.664,5.326,3.0779395,1252.49536,0.0,0.0,7.647,6.542,2.431683,1062.076416,0.0,0.0,6.913,6.263
1442663231000,8.1241439,1158.189056,3.16416,0.0,47.433,44.494,2.8261988,1165.55776,0.0,0.0,6.611,6.217,3.040695,1197.072384,1.826816,0.0,6.394,5.744,2.7678023,1251.917824,0.0,0.0,6.373,6.559,2.8973791,1064.83712,0.0,0.0,6.704,5.728
1442663291000,7.9367043,1156.775936,3.76832,0.0,45.587,43.152,3.3275949,1172.45952,0.0,0.0,7.279,6.382,2.7119543,1196.310528,1.626112,0.0,6.056,5.225,2.799169,1251.344384,0.0,0.0,5.653,5.652,3.234616,1066.893312,0.0,0.0,6.373,5.572
1442663351000,7.3232942,1159.806976,1.806336,0.0,51.15,47.369,2.5449412,1170.989056,0.0,0.0,6.889,6.129,2.8386738,1198.792704,1.255424,0.0,6.36,5.885,3.0521371,1254.25664,0.0,0.0,7.38,7.279,1.9320646,1066.418176,0.0,0.0,7.12,6.543
1442663411000,8.3904373,1161.916416,1.888256,0.0,52.007,48.07,2.7931081,1167.880192,0.0,0.0,6.52,5.857,2.8213187,1200.582656,1.466368,0.0,5.749,5.294,3.4652696,1257.373696,0.0,0.0,8.532,7.006,3.0919704,1067.106304,0.0,0.0,8.276,7.25
1442663471000,8.7913636,1164.0832,3.565568,0.0,56.872,53.777,3.6574339,1166.962688,0.0,0.0,8.202,6.53,3.2833293,1200.726016,1.4848,0.0,7.907,6.509,2.6236582,1256.644608,0.0,0.0,8.184,7.265,2.7973289,1068.122112,0.0,0.0,7.195,6.873
1442663532000,9.4006213,1163.71456,2.445312,0.0,59.439,55.678,3.1620345,1174.245376,0.0,0.0,8.52,6.812,2.7988402,1204.072448,1.081344,0.0,6.97,6.084,3.8347561,1257.41056,0.0,0.0,8.577,7.82,3.5816304,1068.929024,0.0,0.0,8.797,7.437
1442663592000,8.5015217,1164.771328,2.240512,0.0,49.052,46.204,3.0552017,1172.676608,0.0,0.0,5.852,5.315,2.9181765,1204.826112,1.845248,0.0,6.301,6.207,3.6743941,1257.689088,0.0,0.0,7.649,6.403,2.6401629,1067.462656,0.0,0.0,8.061,6.788
1442663653000,7.8781157,1163.132928,3.035136,0.0,53.514,50.787,2.8067081,1175.953408,0.0,0.0,6.056,5.218,2.7011942,1204.973568,1.24928,0.0,6.82,5.424,2.5593795,1256.337408,0.0,0.0,7.584,7.663,2.8783513,1066.778624,0.0,0.0,9.714,8.109
1442663714000,7.5350344,1154.576384,2.43712,0.0,51.8,47.368,3.2827486,1167.478784,0.0,0.0,8.179,7.544,2.4661184,1197.617152,1.25952,0.0,5.325,4.41,3.0524785,1251.147776,0.004096,0.0,6.371,6.055,2.0470981,1051.93472,0.032768,0.0,7.052,6.426
1442663774000,7.0908981,1154.8672,2.981888,0.0,44.763,41.472,2.6401691,1160.052736,0.02048,0.0,4.584,3.974,2.0278572,1198.24384,1.165312,0.0,4.707,4.08,2.9130915,1252.786176,0.0,0.0,7.276,6.454,2.4459587,1057.234944,0.004096,0.0,7.253,6.363
1442663834000,7.0948766,1159.012352,2.904064,0.0,43.455,40.412,3.3740753,1166.921728,0.004096,0.0,6.742,6.549,2.2310001,1201.7664,0.93184,0.0,5.099,4.885,2.5787453,1257.45152,0.0,0.0,5.292,5.254,2.1882948,1066.975232,0.0,0.0,6.38,5.71
1442663894000,5.5282301,1160.675328,2.342912,0.0,41.49,39.157,2.2054711,1170.010112,0.0,0.0,6.112,5.608,1.9367123,1202.069504,0.90112,0.0,5.689,4.529,2.3327748,1258.213376,0.0,0.0,5.209,4.882,2.6796164,1070.399488,0.016384,0.0,6.611,6.046
1442663954000,8.6577935,1162.575872,1.859584,0.0,53.7,50.47,3.6078655,1174.822912,0.008192,0.0,6.372,6.652,3.4528638,1199.935488,2.707456,0.0,7.641,7.145,3.0968956,1259.364352,0.0,0.0,9.115,8.235,2.3992116,1068.99456,0.0,0.0,6.267,4.972
1442664014000,9.1557582,1161.09312,2.699264,0.0,57.758,53.137,3.5269686,1177.247744,0.0,0.0,8.468,7.41,2.4098006,1209.421824,1.419264,0.0,4.392,4.447,3.8607479,1262.12096,0.0,0.0,7.568,7.053,3.4975816,1069.666304,0.0,0.0,9.835,8.578
1442664074000,8.0871409,1161.875456,3.207168,0.0,51.382,48.011,3.5753197,1181.822976,0.024576,0.0,6.95,6.69,2.7669262,1207.611392,1.466368,0.0,5.672,5.251,2.5704072,1262.485504,0.0,0.0,7.373,6.738,2.7171633,1067.43808,0.0,0.0,7.657,7.17
1442664134000,9.8121693,1162.87488,2.342912,0.0,57.573,53.602,3.602512,1187.037184,0.0,0.0,8.138,7.142,3.7174528,1209.606144,1.755136,0.0,6.649,6.21,3.25547,1261.858816,0.0,0.0,8.7,7.895,3.5400841,1064.3456,0.0,0.0,8.715,8.213
1442664194000,7.059958,1164.341248,3.620864,0.0,48.662,44.939,2.5672335,1187.692544,0.012288,0.0,7.663,6.409,2.8818623,1207.332864,1.859584,0.0,5.246,5.06,2.4728751,1266.200576,0.0,0.0,6.28,5.867,1.9827764,1065.96352,0.0,0.0,6.753,6.155
1442664254000,7.7419675,1167.286272,2.953216,0.0,49.389,46.175,2.0522077,1185.5872,0.0,0.0,5.507,5.2,3.3896815,1203.83488,1.86368,0.0,7.263,6.049,2.2839413,1264.693248,0.0,0.0,6.532,6.199,2.3556049,1064.660992,0.0,0.0,7.274,6.916
1442664314000,5.4079459,1156.829184,1.04448,0.0,38.58,36.154,2.3317898,1171.5584,0.0,0.0,5.764,4.865,1.6827201,1197.52704,1.757184,0.0,4.515,4.378,1.8762476,1255.002112,0.0,0.0,6.897,5.864,2.0594344,1058.471936,0.0,0.0,5.363,5.196
1442664374000,8.0440834,1160.597504,2.093056,0.0,52.936,49.016,3.1004867,1170.292736,0.0,0.0,6.714,6.273,2.4409711,1199.726592,1.18784,0.0,5.933,5.146,2.7563559,1253.363712,0.008192,0.0,9.545,7.445,2.8372508,1058.77504,0.032768,0.0,6.758,5.627
1442664434000,8.1927845,1159.614464,2.093056,0.0,54.569,50.646,3.1867393,1169.539072,0.0,0.0,7.471,5.812,2.616808,1198.780416,1.896448,0.0,7.72,6.493,3.1028017,1251.823616,0.0,0.0,8.417,6.995,3.581789,1058.377728,0.032768,0.0,7.386,5.915
1442664495000,6.9548119,1166.434304,3.895296,0.0,45.099,42.403,2.5670522,1172.119552,0.0,0.0,5.27,4.38,2.658172,1205.612544,1.50528,0.0,5.369,4.904,3.218795,1257.140224,0.0,0.0,5.764,4.96,2.2922864,1061.7856,0.0,0.0,8.14,7.087
1442664555000,8.6603742,1163.22304,2.23232,0.0,49.397,45.429,3.3483218,1173.250048,0.0,0.0,6.872,6.324,3.0625307,1207.324672,1.173504,0.0,7.521,6.205,3.1614594,1261.58848,0.008192,0.0,6.809,5.664,3.0661517,1061.707776,0.0,0.0,5.894,5.246
1442664615000,5.9108642,1165.479936,1.462272,0.0,42.187,39.089,1.9468408,1178.046464,0.032768,0.0,5.841,5.682,2.1942098,1210.580992,1.138688,0.0,5.189,4.446,1.3407429,1262.047232,0.0,0.0,5.485,4.882,2.6686848,1064.706048,0.0,0.0,7.094,6.703
1442664675000,7.3997325,1164.36992,1.82272,0.0,49.775,46.844,2.7254611,1179.136,0.0,0.0,7.531,6.3,2.4479271,1211.580416,1.824768,0.0,5.76,5.494,3.1260598,1259.241472,0.0,0.0,7.435,6.245,2.0807811,1063.043072,0.032768,0.0,8.162,6.764
1442664735000,5.4599998,1162.911744,2.748416,0.0,42.454,39.841,2.3636265,1182.486528,0.0,0.0,6.852,5.868,1.4056647,1209.266176,1.200128,0.0,5.608,5.228,14.1142865,1262.8992,1.80224,0.0,6.391,5.229,2.5621036,1067.892736,0.0,0.0,5.529,4.807
1442664795000,8.5989066,1164.61568,2.367488,0.0,56.879,53.029,3.1647945,1184.387072,0.0,0.0,6.469,5.881,1.9405425,1209.270272,1.437696,0.0,5.808,5.354,3.535534,1262.620672,0.0,0.0,7.735,6.918,3.220628,1064.599552,0.0,0.0,10.527,9.357
1442664855000,7.6631123,1162.600448,2.220032,0.0,60.311,55.815,2.9491745,1186.689024,0.004096,0.0,8.476,7.037,2.5167361,1210.560512,1.601536,0.0,9.566,7.512,2.7530268,1264.357376,0.0,0.0,8.768,6.993,2.504029,1062.326272,0.0,0.0,7.03,6.189
1442664916000,4.030933,1150.79168,2.834432,0.008192,34.763,32.486,2.2485787,1165.201408,0.0,0.0,6.558,5.546,1.2426919,1191.309312,1.224704,0.0,4.47,4.424,1.5797931,1244.950528,0.0,0.0,4.976,4.547,1.8921625,1049.452544,0.0,0.0,5.409,4.607
1442664978000,7.6437039,1156.743168,1.82272,0.0,44.877,41.535,1.978059,1178.755072,0.0,0.0,5.355,5.087,2.7879352,1197.707264,1.384448,0.0,4.772,4.46,3.2469454,1253.90848,0.032768,0.0,6.718,6.449,3.3753452,1055.3344,0.004096,0.0,6.972,6.181
1442665038000,9.6919105,1156.38272,2.43712,0.0,58.129,55.285,3.4504593,1177.116672,0.004096,0.0,8.006,7.116,3.7025228,1198.747648,1.90464,0.0,7.697,6.145,2.9902253,1253.748736,0.0,0.0,7.177,6.085,4.3799824,1054.191616,0.032768,0.0,9.723,8.05
1442665098000,8.5436964,1158.53312,2.740224,0.0,57.322,54.158,3.0395899,1176.297472,0.0,0.0,7.763,6.248,3.3812648,1199.685632,1.847296,0.0,7.64,6.792,3.1382821,1253.0688,0.0,0.0,9.341,8.093,2.8267201,1054.838784,0.0,0.0,7.45,6.845
1442665158000,8.0596458,1160.441856,3.137536,0.0,50.031,48.017,3.0435391,1176.854528,0.008192,0.0,6.124,5.238,2.3836946,1199.566848,1.206272,0.0,4.899,4.337,2.6716037,1254.588416,0.0,0.0,8.585,7.13,3.0230696,1054.449664,0.0,0.0,9.532,8.232
1442665218000,7.9914642,1161.129984,4.366336,0.0,55.455,52.4,1.9616071,1174.867968,0.032768,0.0,8.37,6.177,2.0399705,1201.2544,0.974848,0.0,7.035,5.916,3.3837783,1255.706624,0.032768,0.0,9.276,8.145,3.4551418,1053.749248,0.0,0.0,6.851,6.865
1442665278000,6.9410616,1160.060928,3.137536,0.0,42.388,39.846,2.3574959,1177.268224,0.032768,0.0,5.567,5.472,2.1439266,1206.902784,1.787904,0.0,6.046,5.599,2.7913751,1256.2432,0.0,0.0,6.197,5.502,3.0765338,1048.989696,0.393216,0.0,6.124,5.177
1442665338000,8.2430078,1161.179136,3.674112,0.0,50.01,46.305,3.1795929,1179.721728,0.008192,0.0,5.879,5.187,3.337018,1204.768768,1.570816,0.0,7.435,6.502,3.0491361,1257.96352,0.004096,0.0,7.75,6.468,3.5714711,1051.078656,0.0,0.0,6.09,5.135
1442665399000,7.2405171,1162.60864,2.359296,0.0,45.189,41.743,2.697218,1180.217344,0.0,0.0,4.671,4.548,3.6484809,1212.071936,2.199552,0.01024,7.794,7.339,2.5405498,1261.084672,0.0,0.0,5.056,4.651,2.0552997,1049.812992,0.16384,0.0,6.69,6.195
1442665460000,9.8779281,1162.477568,1.814528,0.0,61.601,57.522,3.4096432,1178.804224,0.0,0.0,9.009,8.028,3.1819624,1208.17664,1.449984,0.01024,6.702,5.63,3.7107058,1260.78976,0.0,0.0,8.048,7.008,4.0854908,1057.398784,0.0,0.0,11.308,9.399
1442665520000,8.3981992,1157.521408,4.395008,0.0,55.664,52.211,3.2465935,1175.199744,0.004096,0.0,6.335,5.455,2.833845,1215.844352,1.486848,0.014336,6.485,6.168,2.6325168,1261.039616,0.032768,0.0,7.414,7.611,2.6116916,1056.8704,0.0,0.0,8.904,6.864
1442665580000,6.6662598,1156.13696,2.613248,0.0,37.114,35.107,2.8989486,1160.671232,0.004096,0.0,4.639,4.423,2.1833803,1203.032064,1.253376,0.008192,5.036,4.703,2.2844395,1248.5632,0.032768,0.0,4.812,4.797,2.261414,1050.259456,0.086016,0.0,6.185,5.498
1442665640000,7.839633,1150.349312,2.760704,0.0,50.196,47.008,3.0086454,1158.078464,0.032768,0.0,7.217,6.149,2.8010512,1200.246784,1.65888,0.006144,6.423,5.614,2.847125,1246.060544,0.024576,0.0,6.425,5.806,3.4525864,1061.154816,0.0,0.0,8.277,7.015
1442665700000,9.7512296,1147.088896,1.421312,0.0,49.156,45.881,3.576208,1158.5536,0.0,0.0,6.143,5.254,3.6844395,1195.23328,1.5872,0.006144,5.166,4.888,3.6979029,1257.787392,0.0,0.0,7.639,6.987,3.0868649,1059.422208,0.0,0.0,9.117,8.02
1442665760000,8.887259,1147.285504,2.428928,0.0,42.145,39.458,2.5672474,1166.413824,0.0,0.0,5.384,4.885,3.1813708,1191.677952,1.44384,0.01024,5.408,4.879,3.2579096,1261.371392,0.036864,0.0,6.462,5.406,3.0852817,1062.273024,0.0,0.0,5.898,5.656
1442665821000,9.0106973,1145.675776,3.739648,0.0,58.943,55.169,3.0673175,1181.208576,0.008192,0.0,6.994,5.919,3.828009,1192.603648,1.902592,0.0,8.434,7.277,2.9476245,1257.24672,0.0,0.0,7.863,6.286,3.9169572,1057.603584,0.0,0.0,8.839,6.912
1442665881000,7.4671029,1148.071936,2.031616,0.0,43.617,40.802,1.7626622,1181.995008,0.0,0.0,5.174,5.13,2.6929048,1193.857024,2.322432,0.0,7.08,6.474,2.4379266,1258.942464,0.0,0.0,6.368,5.936,3.1418701,1054.887936,0.0,0.0,6.036,5.346
1442665941000,8.1893732,1148.645376,3.428352,0.0,50.035,46.675,3.3388737,1183.842304,0.0,0.0,7.033,6.107,3.4444358,1194.917888,1.937408,0.0,6.418,5.612,1.9744499,1258.315776,0.0,0.0,5.378,4.811,3.7798941,1053.61408,0.0,0.0,8.113,7.379
1442666001000,10.3033473,1149.755392,2.891776,0.0,55.164,51.709,3.954738,1185.56672,0.0,0.0,8.285,7.438,3.3090564,1193.598976,2.064384,0.0,5.927,5.687,2.703262,1260.519424,0.0,0.0,5.928,4.865,4.1840682,1054.261248,0.0,0.0,10.239,9.304
1442666061000,8.2027462,1151.22176,1.667072,0.0,50.269,46.545,2.3444623,1184.821248,0.0,0.0,5.06,4.118,3.6566978,1196.048384,1.921024,0.0,7.171,6.511,3.9820399,1263.587328,0.0,0.0,9.325,8.493,2.9179566,1055.686656,0.0,0.0,5.352,5.031
1442666121000,7.305469,1152.917504,2.772992,0.0,53.598,50.743,2.8086327,1191.407616,0.0,0.0,9.14,8.244,2.8843263,1199.132672,1.347584,0.0,5.931,5.069,1.92709,1263.783936,0.0,0.0,5.845,5.131,2.9850771,1058.660352,0.0,0.0,9.801,8.227
1442666181000,7.4155138,1148.706816,2.463744,0.0,48.835,45.709,3.0829245,1169.809408,0.0,0.0,7.812,6.646,1.6783256,1191.58784,1.343488,0.0,5.148,4.369,2.6300626,1251.524608,0.032768,0.0,7.254,6.656,3.3196768,1052.225536,0.0,0.0,7.23,6.678
1442666241000,5.5459693,1147.10528,2.551808,0.0,39.399,37.146,2.6736564,1171.685376,0.032768,0.0,4.634,4.237,2.0740225,1190.580224,1.429504,0.0,4.084,3.66,2.164213,1247.535104,0.036864,0.0,7.315,6.171,1.8731882,1052.536832,0.0,0.0,6.763,6.057
1442666301000,7.965695,1151.758336,1.98656,0.0,44.852,41.508,2.3913744,1169.563648,0.0,0.0,5.109,4.186,2.9422004,1189.371904,2.390016,0.0,6.699,6.651,2.6504674,1243.01312,0.004096,0.0,5.703,5.324,3.2694812,1058.381824,0.0,0.0,7.029,6.366
1442666361000,7.6251876,1153.08544,2.076672,0.0,52.934,49.207,2.4026533,1171.341312,0.0,0.0,6.465,5.568,2.7819735,1192.996864,1.9456,0.0,7.296,6.462,3.5755952,1247.70304,0.04096,0.0,8.084,6.811,2.9665731,1060.380672,0.0,0.0,7.431,6.713
1442666421000,9.5566634,1151.705088,3.274752,0.0,62.273,58.173,2.8535384,1175.089152,0.0,0.0,7.181,5.769,3.197409,1197.93664,1.693696,0.0,6.97,6.392,4.0084831,1249.275904,0.032768,0.0,8.74,8.62,4.2325037,1063.346176,0.0,0.0,11.581,9.219
1442666481000,6.7428917,1152.69632,2.666496,0.0,49.444,45.883,2.7769013,1177.657344,0.032768,0.0,7.263,6.947,3.3956165,1199.296512,1.431552,0.0,6.847,6.252,2.0869757,1246.498816,0.0,0.0,5.307,4.895,2.3846414,1059.36896,0.0,0.0,7.785,6.39
1442666541000,7.4425711,1148.428288,4.395008,0.0,58.129,54.31,3.2415604,1179.7504,0.0,0.0,8.598,7.871,2.6015985,1199.357952,1.368064,0.0,5.143,4.71,3.241076,1247.735808,0.0,0.0,9.061,7.675,2.8454838,1060.786176,0.0,0.0,8.552,7.474
1442666601000,7.5843399,1146.601472,2.281472,0.0,51.953,47.576,2.5787832,1180.22144,0.004096,0.0,6.479,6.173,2.2509108,1199.116288,1.85344,0.0,6.921,6.853,2.0623238,1252.880384,0.0,0.0,5.957,4.662,3.0362095,1060.388864,0.0,0.0,8.482,7.538
1442666662000,7.9601019,1147.916288,2.494464,0.0,45.634,42.577,3.089861,1181.184,0.0,0.0,6.659,6.19,2.5481876,1200.500736,1.185792,0.0,5.851,4.702,2.8471021,1259.032576,0.0,0.0,6.067,5.579,3.0672838,1060.184064,0.0,0.0,7.047,6.921
1442666722000,8.5219029,1151.537152,2.564096,0.0,54.955,51.761,3.5412515,1180.504064,0.0,0.0,7.433,6.514,3.5531298,1203.675136,1.826816,0.0,7.037,6.916,3.5166053,1261.21984,0.0,0.0,8.73,7.464,3.4774944,1061.199872,0.0,0.0,7.577,6.463
1442666782000,7.6228208,1150.03392,2.801664,0.0,48.995,45.893,3.1797879,1164.255232,0.0,0.0,8.001,7.58,2.5617928,1193.45152,0.872448,0.0,5.538,4.009,3.0574965,1248.509952,0.0,0.0,7.111,6.486,2.8870225,1056.68608,0.0,0.0,6.959,6.402
1442666842000,8.3379677,1151.52896,2.615296,0.0,45.765,42.749,3.4774927,1163.976704,0.0,0.0,5.829,5.492,2.8851168,1193.1648,1.368064,0.0,5.334,5.102,2.7600896,1251.192832,0.0,0.0,8.395,7.108,3.0097924,1054.072832,0.0,0.0,5.745,4.658
1442666902000,8.4918721,1149.095936,3.366912,0.0,55.476,52.15,3.1567709,1171.656704,0.0,0.0,8.156,6.962,2.5423825,1194.622976,1.323008,0.0,7.229,6.103,2.9251441,1252.786176,0.0,0.0,7.611,6.804,3.6385357,1056.01024,0.0,0.0,8.013,7.814
1442666962000,8.1517806,1148.39552,4.608,0.0,44.388,41.704,3.5433147,1175.085056,0.0,0.0,7.532,6.621,2.6463596,1195.995136,1.366016,0.0,4.286,4.067,2.7168839,1253.736448,0.0,0.0,6.1,5.342,2.3114489,1054.470144,0.0,0.0,6.021,5.934
1442667022000,8.2470338,1149.21472,2.936832,0.0,53.991,50.111,3.5083533,1175.1424,0.012288,0.0,7.452,6.56,2.8620165,1198.915584,1.298432,0.0,6.133,5.675,2.6573958,1255.99744,0.028672,0.0,9.363,7.942,3.0553678,1056.305152,0.0,0.0,6.634,5.571
1442667082000,9.2028583,1149.947904,4.698112,0.0,60.543,57.061,3.2303562,1175.953408,0.0,0.0,6.147,4.608,3.7641843,1200.14848,1.419264,0.0,8.345,6.789,4.0748079,1256.894464,0.0,0.0,8.571,7.072,3.9415096,1056.026624,0.004096,0.0,10.583,10.383
1442667142000,9.5011009,1148.944384,2.686976,0.0,55.704,51.88,3.9892566,1176.53504,0.0,0.0,7.873,6.926,1.9837007,1202.286592,0.835584,0.0,4.927,4.114,3.8333906,1257.340928,0.004096,0.0,9.261,7.382,3.7995801,1054.978048,0.0,0.0,8.518,7.804
1442667202000,11.6693297,1157.67296,2.99008,0.0,70.134,66.145,3.9986432,1181.06112,0.0,0.0,9.613,7.691,3.9686339,1202.0736,1.644544,0.0,9.542,7.86,4.3917788,1260.548096,0.0,0.0,10.876,8.226,4.0341119,1056.714752,0.0,0.0,9.182,8.405
1442667262000,9.212294,1155.387392,3.6864,0.0,46.076,43.294,3.4154519,1176.604672,0.012288,0.0,5.117,5.283,2.9852324,1201.197056,1.406976,0.0,7.027,5.746,2.8536025,1260.150784,0.0,0.0,4.979,5.069,3.7776054,1060.429824,0.0,0.0,8.032,6.89
1442667322000,7.3991454,1155.01056,2.719744,0.0,43.734,40.866,3.1486325,1177.817088,0.0,0.0,7.628,7.363,3.3083825,1199.808512,1.4848,0.0,5.812,4.88,1.9863211,1259.8272,0.0,0.0,5.52,5.221,2.1770843,1059.033088,0.0,0.0,5.776,5.24
1442667382000,6.7446361,1150.943232,2.809856,0.0,39.441,36.99,2.8650026,1163.83744,0.0,0.0,7.65,6.491,2.3125957,1187.40992,1.447936,0.0,4.445,4.031,2.3670197,1248.825344,0.032768,0.0,5.067,5.102,1.7860717,1056.489472,0.0,0.0,4.881,3.925
1442667442000,8.8841111,1148.268544,1.583104,0.0,45.995,43.284,2.9106691,1160.68352,0.0,0.0,6.758,5.704,2.6406812,1186.615296,1.990656,0.0,4.795,5.13,2.296005,1242.177536,0.0,0.0,6.835,5.844,3.898975,1057.181696,0.0,0.0,7.726,6.584
1442667502000,8.3901144,1147.55584,1.314816,0.0,42.498,39.804,3.6628491,1157.218304,0.0,0.0,5.898,5.615,2.8635979,1192.439808,1.824768,0.0,4.894,4.549,2.7614576,1244.40576,0.0,0.0,6.423,5.963,3.2375629,1059.590144,0.0,0.0,7.814,6.751
1442667562000,6.5383909,1149.714432,3.190784,0.0,42.544,39.973,2.4559018,1160.531968,0.0,0.0,7.927,7.036,1.8165626,1193.279488,1.95584,0.0,3.859,4.063,1.8516493,1239.355392,0.0,0.0,5.697,4.173,3.2258553,1061.687296,0.0,0.0,6.724,5.689
1442667622000,9.3124115,1149.186048,3.596288,0.0,57.188,54.218,4.0970097,1168.662528,0.0,0.0,8.894,6.742,2.6683004,1191.952384,1.492992,0.0,6.06,5.143,3.5387177,1239.912448,0.0,0.0,7.608,6.395,3.9086695,1059.606528,0.0,0.0,9.708,8.957
1442667682000,14.7163164,1157.24288,1.071104,0.012288,8.445,7.592,0.5480984,1169.79712,0.0,0.0,1.61,1.55,0.3855031,1196.621824,0.19456,0.0,1.961,2.017,0.6482874,1244.090368,0.0,0.0,1.901,1.979,0.171586,1060.466688,0.0,0.0,1.134,1.116
1442667742000,1.634034,1183.608832,1.400832,0.0,15.666,14.669,99.5775032,1184.964608,0.004096,0.0,2.236,2.487,24.4107962,1206.624256,0.579584,0.0,3.264,2.797,0.6082712,1237.078016,0.065536,0.0,2.803,2.672,0.8180114,1056.313344,0.0,0.0,2.443,2.446
1442667802000,4.978782,1163.165696,1.847296,0.0,33.825,31.738,1.8474558,1172.123648,0.0,0.0,4.992,4.722,1.1430422,1197.637632,0.956416,0.0,3.142,3.064,62.2301769,1252.220928,0.036864,0.0,5.275,4.938,16.1336574,1062.162432,0.241664,0.0,6.305,5.459
1442667862000,7.9906811,1165.398016,2.379776,0.0,53.04,49.509,3.5436391,1172.938752,0.0,0.0,8.192,7.276,2.4379476,1196.077056,1.05472,0.0,5.006,4.86,3.5587335,1249.619968,0.0,0.0,8.922,7.424,2.8222318,1061.879808,0.0,0.0,5.918,5.148
1442667923000,7.2859252,1166.118912,2.256896,0.0,52.316,48.913,2.8429296,1172.455424,0.004096,0.0,6.935,5.895,2.6061805,1195.54048,1.714176,0.0,6.12,5.855,2.8239005,1241.583616,0.0,0.0,5.74,5.071,2.9279086,1057.517568,0.0,0.0,10.227,7.815
1442667983000,8.3854229,1161.588736,2.105344,0.0,50.931,47.591,3.0593803,1164.046336,0.0,0.0,6.578,5.62,2.2280309,1190.477824,1.961984,0.0,6.224,5.624,3.540307,1238.327296,0.0,0.0,7.282,7.028,3.5929919,1052.286976,0.0,0.0,8.202,8.066
1442668043000,7.8758504,1159.184384,2.830336,0.0,52.491,48.801,2.8359424,1158.283264,0.0,0.0,5.633,4.254,2.6675459,1187.639296,1.921024,0.0,4.761,4.507,3.3823091,1231.753216,0.0,0.0,9.206,8.359,3.4470491,1049.698304,0.0,0.0,8.417,6.751
1442668103000,8.3349465,1160.17152,2.84672,0.0,48.713,45.86,3.268448,1164.795904,0.0,0.0,6.201,5.062,3.2566521,1187.59424,1.830912,0.0,6.985,5.513,3.1291612,1236.7872,0.0,0.0,7.146,6.468,3.5445882,1048.35072,0.0,0.0,6.937,6.585
1442668163000,9.2947254,1166.897152,3.137536,0.0,57.213,53.154,3.4469875,1167.294464,0.0,0.0,7.757,6.12,2.7006343,1194.729472,1.933312,0.0,6.347,6.342,3.8654116,1243.140096,0.0,0.0,9.509,7.311,3.3113799,1049.296896,0.032768,0.0,8.265,6.914
1442668223000,10.094055,1164.423168,3.166208,0.0,68.799,64.916,3.7473546,1167.581184,0.0,0.0,10.066,7.747,4.1409361,1195.503616,1.595392,0.0,9.165,7.158,4.7416391,1246.191616,0.0,0.0,10.315,8.199,3.8839381,1048.551424,0.0,0.0,10.089,8.675
1442668283000,10.2621608,1165.099008,2.117632,0.0,58.208,54.531,3.0867983,1170.833408,0.0,0.0,7.862,6.638,4.0179385,1196.224512,2.183168,0.0,6.17,5.97,3.6449549,1248.452608,0.0,0.0,8.116,7.248,4.3782409,1048.154112,0.0,0.0,10.209,8.871
1442668344000,8.2946714,1166.774272,3.158016,0.0,52.772,49.169,2.3046998,1169.084416,0.0,0.0,5.41,4.679,2.9577096,1198.034944,1.6896,0.0,6.52,6.441,3.1090443,1244.573696,0.0,0.0,8.27,6.669,3.2112335,1051.56608,0.0,0.0,8.585,6.985
1442668404000,8.7176556,1167.93344,3.244032,0.0,52.57,49.192,2.4037745,1172.82816,0.004096,0.0,7.213,5.893,3.6532988,1196.7488,1.595392,0.0,5.574,5.48,3.5850629,1243.119616,0.0,0.0,7.077,5.819,2.995392,1052.672,0.0,0.0,8.566,8.619
1442668464000,7.204871,1169.36704,4.374528,0.0,44.577,41.968,2.9023953,1166.503936,0.0,0.0,5.301,4.406,1.7763678,1196.859392,1.24928,0.0,5.501,4.547,2.6350615,1256.321024,0.0,0.0,6.324,5.623,2.896468,1054.425088,0.0,0.0,6.938,7.015
1442668524000,7.5893004,1172.811776,2.629632,0.0,47.872,44.707,2.7906559,1165.070336,0.0,0.0,6.497,5.37,3.7410644,1204.559872,1.959936,0.0,7.344,6.483,2.8823131,1256.869888,0.0,0.0,6.539,5.444,2.8070244,1053.364224,0.0,0.0,6.734,5.903
1442668584000,7.8240701,1168.068608,2.338816,0.0,52.008,48.573,2.9134018,1164.050432,0.0,0.0,5.426,4.276,2.8526941,1199.525888,1.25952,0.0,6.492,5.549,3.452645,1252.364288,0.0,0.0,9.87,8.934,2.7462852,1052.34432,0.0,0.0,6.751,6.241
1442668644000,9.1197072,1163.780096,3.063808,0.0,61.494,56.976,3.4767752,1162.190848,0.0,0.0,6.937,6.587,3.5656908,1204.457472,2.686976,0.0,7.564,7.265,4.1910682,1244.790784,0.0,0.0,10.597,8.38,3.0042994,1052.704768,0.0,0.0,7.582,6.99
1442668704000,9.1489898,1163.476992,2.686976,0.0,58.901,55.154,4.531737,1162.780672,0.0,0.0,10.936,10.045,2.8668988,1197.821952,0.950272,0.0,5.588,5.358,3.6843293,1246.26944,0.0,0.0,8.964,7.673,3.5630127,1048.502272,0.0,0.0,7.91,6.392
1442668765000,7.0976987,1170.132992,2.37568,0.0,46.936,43.885,3.2338703,1166.78656,0.0,0.0,6.993,6.282,2.0078419,1197.723648,1.189888,0.0,5.459,4.675,2.4662521,1245.310976,0.0,0.0,6.232,5.67,2.5975832,1049.944064,0.0,0.0,7.273,6.68
1442668826000,6.9776324,1168.146432,1.548288,0.012288,45.508,42.576,3.392067,1164.845056,0.0,0.0,7.272,6.113,2.4811978,1202.704384,1.181696,0.0,6.022,4.922,2.1399765,1247.055872,0.0,0.0,6.942,6.521,2.942636,1052.921856,0.0,0.0,6.851,5.655
1442668886000,6.9747184,1169.997824,4.519936,0.02048,40.535,37.983,2.2806757,1164.058624,0.0,0.0,5.358,4.436,2.6412744,1202.188288,1.777664,0.0,4.818,4.205,2.8258482,1245.741056,0.0,0.0,6.169,5.117,2.4760447,1050.365952,0.0,0.0,6.273,6.134
1442668946000,7.9755216,1171.193856,3.354624,0.012288,40.721,38.736,2.5932564,1170.90304,0.0,0.0,4.566,3.868,2.2644125,1200.771072,1.697792,0.0,5.728,4.959,2.5142432,1247.55968,0.0,0.0,5.541,5.106,3.4504406,1049.747456,0.0,0.0,6.486,5.908
1442669006000,8.8132632,1167.495168,2.53952,0.016384,61.533,57.035,3.662983,1172.8896,0.0,0.0,10.164,7.605,3.9777487,1201.11104,2.410496,0.0,8.685,7.325,3.3832618,1248.718848,0.0,0.0,6.865,5.769,3.5440542,1048.256512,0.0,0.0,8.847,7.153
1442669066000,8.3596652,1172.054016,2.895872,0.0,47.316,44.144,3.0284066,1175.904256,0.0,0.0,6.902,5.927,3.4931336,1202.556928,2.091008,0.0,5.594,5.542,2.685027,1244.864512,0.0,0.0,6.099,5.423,3.5845406,1047.158784,0.0,0.0,7.44,5.815
1442669126000,7.3623452,1171.369984,4.01408,0.0,54.052,51.034,3.7509124,1177.399296,0.0,0.0,9.134,7.086,2.5120456,1197.73184,1.814528,0.0,6.076,5.445,3.1176886,1248.100352,0.0,0.0,7.456,5.953,2.6516291,1046.028288,0.0,0.0,7.792,6.988
1442669186000,5.1405244,1161.027584,1.691648,0.0,43.757,41.031,1.1955099,1156.3008,0.0,0.0,6.473,5.354,1.5249941,1189.699584,1.411072,0.0,5.268,4.741,2.3805363,1233.399808,0.0,0.0,6.918,6.333,2.0182932,1037.131776,0.0,0.0,6.169,5.808
1442669247000,6.9716595,1168.6912,2.695168,0.008192,56.804,53.215,2.4351334,1164.836864,0.0,0.0,6.411,5.514,2.1269287,1193.107456,0.899072,0.0,6.562,4.774,2.5455688,1237.860352,0.0,0.0,8.692,7.069,3.7901765,1089.388544,0.0,0.0,10.227,8.639
1442669307000,8.2547859,1172.942848,4.141056,0.02048,53.18,50.008,3.8061361,1158.483968,0.0,0.0,6.908,6.11,2.4042265,1197.91616,1.409024,0.0,7.335,6.005,3.0925388,1242.07104,0.0,0.0,8.284,7.018,3.5675588,1079.369728,0.0,0.0,7.917,6.957
1442669368000,9.083479,1174.945792,3.493888,0.024576,51.747,48.224,3.367402,1159.999488,0.0,0.0,8.415,6.492,2.6067317,1191.837696,1.009664,0.0,5.985,5.202,3.4655594,1251.991552,0.0,0.0,7.089,6.346,3.8873798,1081.454592,0.0,0.0,7.498,7.135
1442669428000,7.6206612,1176.08448,3.457024,0.024576,44.668,41.611,3.42516,1159.901184,0.0,0.0,6.856,6.755,2.2400639,1193.230336,0.827392,0.0,4.145,3.459,2.9877005,1249.202176,0.0,0.0,6.865,5.829,2.6212181,1080.262656,0.0,0.0,6.168,6.085
1442669488000,2.5721741,1173.610496,2.056192,0.02048,19.536,18.404,0.8495235,1162.0352,0.0,0.0,2.551,2.614,18.9619865,1202.098176,8.57088,0.0,2.815,2.561,1.1316795,1251.045376,0.0,0.0,2.254,2.091,1.239775,1077.817344,0.0,0.0,3.919,3.537
1442669548000,8.8908117,1173.282816,2.125824,0.016384,58.201,54.802,3.5614079,1162.502144,0.0,0.0,8.644,8.369,3.1888499,1206.173696,1.818624,0.0,7.333,5.711,2.8917943,1248.075776,0.0,0.0,7.704,6.03,3.2922483,1086.676992,0.0,0.0,8.222,7.765
1442669608000,7.3529812,1175.564288,3.960832,0.02048,37.054,34.445,2.6137903,1157.718016,0.0,0.0,4.461,4.608,1.685927,1203.666944,0.5632,0.0,3.214,2.672,2.4362699,1248.927744,0.0,0.0,6.131,5.737,2.9115571,1078.976512,0.0,0.0,6.221,5.828
1442669668000,6.5151145,1170.403328,3.91168,0.02048,39.014,36.807,2.0600918,1159.950336,0.032768,0.0,3.786,3.762,2.3082662,1202.696192,1.579008,0.0,4.762,4.797,1.522788,1247.514624,0.0,0.0,5.393,4.538,2.9513944,1078.480896,0.0,0.0,7.38,7.517
1442669729000,9.9686712,1169.989632,2.854912,0.0,57.119,52.887,4.1895464,1166.659584,0.0,0.0,7.742,6.941,3.2074017,1200.918528,1.024,0.012288,5.804,4.823,3.4281629,1248.137216,0.0,0.0,7.217,6.194,4.0491435,1077.284864,0.0,0.0,10.598,9.72
1442669789000,7.4705005,1172.504576,3.03104,0.0,48.547,45.87,2.3917717,1165.381632,0.0,0.0,4.879,4.576,1.7299523,1202.25792,0.944128,0.004096,4.681,4.038,2.7174248,1247.051776,0.0,0.0,8.163,7.098,2.6152543,1078.124544,0.0,0.0,8.987,8.353
1442669849000,8.5199691,1166.966784,3.964928,0.0,56.341,52.647,3.0800612,1165.963264,0.0,0.0,7.373,7.036,2.986698,1196.097536,0.8192,0.008192,7.839,5.984,3.4384241,1243.959296,0.0,0.0,8.086,7.68,3.2333085,1072.922624,0.0,0.0,7.518,6.76
1442669909000,8.2747206,1163.730944,1.65888,0.0,53.787,50.151,3.1106761,1169.32608,0.0,0.0,7.224,6.256,3.3090262,1199.587328,2.525184,0.006144,7.739,7.349,3.361259,1239.085056,0.0,0.0,8.067,7.441,2.3165625,1072.96768,0.0,0.0,7.008,5.722
1442669969000,8.006514,1163.558912,1.343488,0.0,49.565,45.941,2.7757512,1168.777216,0.0,0.0,5.926,5.295,2.5560243,1202.5856,1.554432,0.01024,6.243,5.884,3.0212798,1242.812416,0.0,0.0,8.264,6.974,2.5497922,1073.995776,0.0,0.0,6.988,6.499
1442670029000,6.4030274,1167.286272,4.13696,0.0,44.974,42.134,3.1805034,1166.29504,0.0,0.0,7.405,6.394,2.7483727,1198.526464,1.21856,0.004096,5.701,4.75,1.9060937,1239.502848,0.0,0.0,5.579,4.961,1.8041019,1073.17248,0.0,0.0,7.221,5.84
1442670089000,8.0734145,1166.68416,3.4816,0.0,51.411,48.891,4.0167317,1170.169856,0.0,0.0,7.228,6.025,2.4519667,1199.071232,2.019328,0.008192,6.885,6.247,2.8431039,1238.822912,0.0,0.0,7.776,7.076,3.0992684,1071.591424,0.0,0.0,6.298,5.554
1442670150000,9.7400776,1164.414976,2.998272,0.0,62.262,57.738,3.5890517,1171.951616,0.0,0.0,6.009,5.603,2.0013796,1198.239744,1.288192,0.006144,6.152,5.478,4.1532298,1240.666112,0.0,0.0,8.506,7.149,3.9650938,1077.141504,0.0,0.0,12.774,11.52
1442670210000,8.686172,1168.285696,3.01056,0.0,58.657,55.66,4.5196935,1172.832256,0.0,0.0,10.823,9.708,3.3793592,1198.30528,1.705984,0.01024,7.282,6.53,3.5451888,1239.977984,0.0,0.0,7.949,7.111,3.2341864,1075.236864,0.0,0.0,8.139,6.875
1442670270000,9.4901213,1166.389248,2.670592,0.0,53.111,48.909,2.6842242,1168.969728,0.0,0.0,7.381,5.424,3.3427033,1203.089408,2.168832,0.008192,6.968,6.168,3.0324065,1241.665536,0.0,0.0,6.334,6.613,3.5749274,1074.458624,0.0,0.0,8.953,7.736
1442670330000,7.4673351,1167.19616,3.387392,0.0,49.925,46.124,2.0745272,1170.485248,0.0,0.0,5.055,4.692,2.1186801,1200.214016,1.333248,0.006144,7.075,6.304,2.7382318,1241.14944,0.0,0.0,7.045,6.779,3.3362231,1076.03968,0.0,0.0,7.683,7.283
1442670390000,7.8025883,1168.297984,4.354048,0.0,47.853,45.214,2.6222073,1170.92352,0.0,0.0,5.947,5.214,3.1898115,1202.855936,1.984512,0.01024,7.464,6.677,3.2027756,1239.691264,0.0,0.0,6.383,5.811,2.5720612,1074.09408,0.004096,0.0,6.928,6.426
1442670450000,8.4953643,1173.024768,2.424832,0.0,44.738,41.585,3.187133,1164.898304,0.0,0.0,6.767,6.578,3.4240434,1203.228672,1.25952,0.008192,5.264,4.716,2.9577688,1232.809984,0.0,0.0,4.749,4.26,3.8001765,1079.115776,0.0,0.0,7.857,6.707
1442670511000,7.1594654,1168.75264,2.68288,0.0,55.986,52.959,3.0556378,1164.640256,0.0,0.0,6.355,6.402,3.1415081,1205.755904,0.5632,0.0,8.853,6.239,1.9685065,1233.375232,0.0,0.0,7.693,7.133,2.700631,1074.397184,0.0,0.0,8.161,6.635
1442670572000,8.7596194,1173.061632,3.907584,0.0,43.463,40.466,2.2138182,1158.98368,0.0,0.0,5.041,3.593,3.3974537,1207.713792,2.512896,0.0,6.303,6.232,3.0858807,1233.932288,0.0,0.0,5.885,5.909,3.4477968,1074.327552,0.0,0.0,5.926,5.402
1442670632000,14.057034,1171.144704,3.87072,0.0,44.617,41.362,2.9524867,1166.52032,0.0,0.0,6.432,6.045,2.8820468,1205.137408,0.831488,0.0,5.233,4.243,3.7219613,1236.209664,0.0,0.0,6.261,6.794,3.1279066,1073.770496,0.0,0.0,5.991,4.24
1442670692000,8.2167156,1171.144704,2.588672,0.0,44.675,42.163,3.5446361,1172.25472,0.0,0.0,6.963,7.159,2.4145807,1205.559296,1.798144,0.0,5.285,4.786,3.0045937,1236.283392,0.0,0.0,5.039,4.077,2.3759365,1073.754112,0.0,0.0,7.122,6.424
1442670752000,9.6524977,1176.420352,4.993024,0.0,60.355,56.759,5.1938123,1170.39104,0.0,0.0,10.041,8.281,3.6996849,1205.551104,1.390592,0.0,8.148,6.945,3.8477948,1237.110784,0.0,0.0,7.323,5.278,3.9427619,1072.869376,0.0,0.0,9.173,7.159
1442670812000,7.8287937,1173.241856,2.68288,0.0,48.745,45.591,2.8787915,1169.973248,0.0,0.0,7.16,6.713,3.4024404,1207.947264,2.433024,0.0,5.332,5.481,2.0107401,1240.154112,0.0,0.0,6.897,5.338,2.690741,1072.96768,0.0,0.0,6.609,6.21
1442670872000,10.0721469,1174.581248,2.547712,0.0,59.777,56.373,3.8618995,1169.199104,0.0,0.0,8.697,7.65,2.7785138,1207.148544,1.78176,0.0,6.353,5.894,4.191225,1240.141824,0.0,0.0,9.559,8.077,4.1158943,1074.577408,0.0,0.0,7.998,6.583
1442670933000,7.5121636,1175.048192,4.124672,0.0,54.6,51.341,3.341912,1171.529728,0.0,0.0,8.368,6.698,2.6137901,1205.92384,1.171456,0.0,6.907,5.498,3.1142139,1240.129536,0.0,0.0,7.867,7.392,2.6875437,1072.398336,0.0,0.0,8.124,6.692
1442670994000,9.3368055,1170.530304,2.080768,0.0,68.373,64.072,3.2864025,1171.668992,0.0,0.0,9.014,6.795,3.2329102,1205.731328,1.66912,0.0,8.357,7.259,3.0576588,1238.093824,0.0,0.0,10.399,8.809,3.9566724,1074.655232,0.016384,0.0,10.384,8.976
1442671054000,7.5345089,1174.454272,1.347584,0.0,48.722,45.255,3.3763332,1165.66016,0.004096,0.0,7.311,6.293,3.0097955,1212.289024,1.949696,0.0,8.008,7.17,2.6195032,1235.386368,0.0,0.0,5.994,5.594,2.3462998,1071.394816,0.0,0.0,6.047,5.386
1442671114000,7.5198541,1172.185088,2.019328,0.0,45.142,42.363,3.7531361,1162.399744,0.0,0.0,6.72,6.022,2.4664018,1208.54528,1.601536,0.0,4.967,4.82,2.7380558,1232.699392,0.0,0.0,6.983,6.115,2.1487449,1076.75648,0.0,0.0,6.157,5.741
1442671174000,7.0529472,1172.430848,2.543616,0.0,52.5,49.508,2.3055483,1164.607488,0.0,0.0,6.205,4.691,2.2222334,1204.482048,2.121728,0.0,5.966,5.629,2.7061256,1230.364672,0.024576,0.0,7.762,6.465,3.3309533,1074.106368,0.0,0.0,9.717,8.212
1442671234000,8.4852052,1172.856832,2.531328,0.0,53.162,49.694,3.8935546,1164.996608,0.0,0.0,8.343,7.001,2.9631726,1204.064256,1.953792,0.0,5.916,5.247,2.4684442,1233.543168,0.0,0.0,5.838,4.72,4.1052243,1071.726592,0.0,0.0,9.849,8.857
1442671294000,7.0314112,1174.675456,3.23584,0.0,46.852,44.202,2.012914,1165.492224,0.0,0.0,5.525,4.463,2.5690753,1206.484992,2.095104,0.0,5.773,5.163,2.8194672,1234.464768,0.0,0.0,8.08,6.663,3.1089668,1069.355008,0.0,0.0,8.345,7.589
1442671354000,6.9946897,1174.44608,2.863104,0.0,46.687,44.672,2.8371231,1172.484096,0.0,0.0,6.168,6.076,3.0557407,1205.82144,1.501184,0.0,6.868,5.691,2.5865609,1228.57472,0.0,0.0,5.114,4.702,2.5514663,1069.006848,0.0,0.0,8.041,7.138
1442671414000,7.6340316,1177.878528,3.084288,0.0,49.711,46.484,3.1246065,1171.918848,0.0,0.0,6.591,5.762,2.2039658,1206.714368,2.076672,0.0,5.767,5.291,2.8317113,1231.622144,0.0,0.0,8.174,6.959,2.5836287,1069.121536,0.0,0.0,6.659,5.192
1442671474000,7.7045119,1181.147136,2.306048,0.0,46.26,43.085,2.8973453,1173.438464,0.0,0.0,6.494,6.163,2.1224895,1205.510144,1.44384,0.0,5.36,4.124,3.4337916,1234.366464,0.0,0.0,7.389,7.296,2.8738789,1067.483136,0.0,0.0,6.539,5.842
1442671534000,6.3090186,1181.257728,3.760128,0.0,37.819,35.696,1.7817297,1170.624512,0.0,0.0,4.26,3.985,2.6186331,1214.660608,1.488896,0.0,5.188,4.672,2.1305719,1231.392768,0.0,0.0,4.455,4.042,2.8886521,1072.254976,0.0,0.0,7.165,6.6
1442671594000,7.3874639,1187.2256,3.387392,0.0,52.334,49.229,2.8949772,1173.475328,0.0,0.0,6.896,6.335,2.2581905,1217.417216,1.173504,0.0,6.324,5.777,3.8282717,1228.26752,0.0,0.0,8.005,7.458,1.7207789,1071.644672,0.0,0.0,8.271,6.31
1442671654000,9.3845885,1182.588928,1.830912,0.0,49.79,46.426,3.2038826,1159.319552,0.024576,0.0,5.21,4.441,4.1499093,1214.0544,1.978368,0.0,8.462,7.251,3.1964587,1222.074368,0.032768,0.0,7.046,5.965,3.2416226,1075.355648,0.0,0.0,7.57,6.378
1442671714000,8.1889503,1177.726976,1.835008,0.0,50.643,47.427,2.9603895,1160.482816,0.0,0.0,7.421,6.308,2.473945,1213.095936,1.507328,0.0,6.478,5.755,2.9459927,1222.250496,0.0,0.0,7.664,6.679,2.868618,1074.597888,0.0,0.0,6.904,5.748
1442671774000,8.0157373,1184.591872,3.141632,0.0,49.69,45.433,3.071838,1159.53664,0.0,0.0,6.122,5.139,2.2947573,1215.61088,1.275904,0.0,4.743,4.641,3.0929969,1224.093696,0.0,0.0,6.585,6.404,3.0855234,1074.868224,0.0,0.0,8.247,7.193
1442671835000,9.8868364,1190.981632,2.584576,0.0,64.667,60.366,3.5147401,1161.396224,0.0,0.0,8.723,6.791,3.1187024,1220.698112,2.23232,0.0,7.973,7.552,3.9817761,1222.967296,0.0,0.0,9.319,7.786,4.7120774,1073.84832,0.0,0.0,10.357,8.286
1442671895000,7.791336,1188.585472,2.412544,0.0,44.021,41.549,2.2368724,1162.21952,0.0,0.0,5.83,5.045,1.9346045,1216.249856,1.015808,0.0,4.848,4.089,2.5733656,1223.323648,0.0,0.0,7.119,6.501,6.9260507,1074.9952,0.0,0.0,7.835,6.266
1442671955000,8.1748132,1190.273024,3.076096,0.0,55.707,52.109,4.3073001,1163.190272,0.0,0.0,9.636,8.367,2.9265102,1219.416064,1.155072,0.0,5.772,4.927,2.8967314,1222.856704,0.0,0.0,7.695,6.162,2.6224569,1078.21056,0.0,0.0,8.514,7.541
1442672015000,8.5689787,1192.800256,2.158592,0.0,49.683,46.557,2.8757099,1159.499776,0.0,0.0,6.545,5.6,2.910752,1221.238784,1.531904,0.0,5.861,4.916,3.7163637,1220.718592,0.0,0.0,7.221,7.016,2.8029418,1080.963072,0.0,0.0,8.321,7.771
1442672075000,7.9722621,1191.759872,2.78528,0.0,54.518,50.685,3.653496,1159.45472,0.0,0.0,9.166,7.544,3.2732818,1219.559424,1.112064,0.0,6.614,5.399,3.2389185,1219.751936,0.0,0.0,7.221,6.473,3.2903776,1081.004032,0.0,0.0,7.189,6.051
1442672135000,6.8676636,1194.934272,4.702208,0.0,48.971,45.403,1.7237795,1158.254592,0.0,0.0,6.047,4.27,2.0080381,1227.845632,1.2288,0.0,4.775,3.984,2.8210084,1222.51264,0.0,0.0,7.319,6.669,3.4936968,1084.563456,0.0,0.0,8.47,7.527
1442672195000,8.0236714,1194.622976,4.206592,0.0,53.113,49.853,3.2882882,1163.563008,0.0,0.0,7.253,6.121,2.890437,1227.460608,1.323008,0.0,5.58,4.883,3.387426,1222.06208,0.016384,0.0,9.416,8.102,2.8348941,1078.74304,0.0,0.0,7.494,6.168
1442672255000,6.5507055,1190.05184,2.510848,0.0,45.674,43.12,3.2674384,1157.021696,0.004096,0.0,6.168,5.334,2.0021083,1220.726784,0.831488,0.0,6.473,4.585,2.9528111,1215.688704,0.0,0.0,5.908,5.247,2.1519406,1079.025664,0.0,0.0,7.679,6.897
1442672315000,6.9274516,1187.196928,1.933312,0.0,46.216,43.346,2.6360074,1161.211904,0.032768,0.0,6.416,6.004,1.9270773,1221.943296,1.265664,0.0,4.321,4.082,2.0536507,1213.005824,0.0,0.0,6.673,6.016,3.179411,1081.602048,0.0,0.0,8.327,7.392
1442672376000,7.2877015,1189.122048,2.58048,0.0,45.422,42.215,2.3466384,1156.99712,0.0,0.0,5.898,4.566,1.7178137,1217.974272,1.275904,0.0,5.75,5.007,3.4931358,1216.135168,0.0,0.0,6.973,6.461,3.4691902,1078.816768,0.0,0.0,7.541,6.157
1442672436000,8.0066911,1189.920768,2.916352,0.0,51.049,47.754,3.0999361,1159.819264,0.0,0.0,7.203,5.667,2.2280582,1222.467584,0.874496,0.0,4.852,3.812,3.1142468,1213.779968,0.0,0.0,8.966,8.096,3.4562504,1079.300096,0.0,0.0,7.387,6.573
1442672496000,9.4131632,1193.242624,3.059712,0.0,73.502,68.09,3.8841552,1158.08256,0.0,0.0,10.703,8.792,3.4616556,1228.640256,1.859584,0.0,9.712,8.125,4.0718914,1214.435328,0.0,0.0,9.051,8.367,8.0319618,1077.440512,0.0,0.0,10.433,7.41
1442672556000,8.0880417,1189.183488,2.363392,0.0,46.425,43.816,3.1741324,1159.970816,0.0,0.0,5.83,5.875,2.9791455,1231.056896,1.29024,0.0,5.543,4.73,2.5115335,1211.428864,0.0,0.0,5.168,4.935,3.5956391,1075.621888,0.0,0.0,9.505,7.442
1442672616000,7.197498,1193.025536,2.789376,0.0,43.752,41.184,2.2517035,1156.673536,0.0,0.0,5.037,5.596,2.5016831,1229.312,0.892928,0.0,5.782,4.615,2.7646868,1207.803904,0.0,0.0,6.473,6.149,2.9746226,1076.989952,0.0,0.0,7.034,5.797
1442672676000,9.3494332,1187.139584,3.52256,0.0,54.235,50.804,3.04875,1161.601024,0.0,0.0,6.738,6.271,3.8121926,1231.806464,1.650688,0.0,6.287,5.757,4.0563002,1208.369152,0.0,0.0,9.145,8.092,4.019738,1080.610816,0.0,0.0,7.219,6.869
1442672736000,8.9174092,1188.794368,4.616192,0.0,45.927,43.469,3.3038297,1163.378688,0.0,0.0,5.503,5.431,3.2346515,1229.66016,0.88064,0.0,5.72,4.929,4.0920824,1210.105856,0.0,0.0,7.306,6.425,3.2432787,1080.168448,0.0,0.0,6.775,6.043
1442672797000,7.9471835,1188.31104,3.72736,0.008192,54.021,50.983,3.4136369,1165.070336,0.0,0.0,9.516,7.236,3.0525031,1231.757312,2.37568,0.0,5.399,5.673,2.6927114,1211.940864,0.0,0.0,8.303,6.669,2.9304741,1080.827904,0.0,0.0,7.432,6.224
1442672858000,9.2521407,1184.632832,1.892352,0.016384,56.308,53.16,3.1348599,1160.675328,0.0,0.0,6.596,5.683,3.1587711,1227.595776,1.56672,0.0,7.714,6.161,3.6721489,1212.928,0.0,0.0,9.885,8.484,3.5224529,1081.253888,0.0,0.0,7.66,7.006
1442672918000,12.1703348,1186.811904,2.67264,0.008192,49.112,46.793,3.2643141,1162.305536,0.0,0.0,6.908,5.963,2.5761365,1225.588736,1.585152,0.0,5.437,4.933,3.0547428,1211.00288,0.0,0.0,6.653,6.12,4.0715596,1087.22176,0.0,0.0,8.89,7.441
1442672978000,8.264043,1186.312192,3.325952,0.008192,44.531,41.962,2.665448,1160.66304,0.024576,0.0,6.721,6.075,2.2637135,1224.282112,1.29024,0.0,5.09,4.347,3.3113898,1211.76064,0.0,0.0,7.046,6.804,2.3268822,1084.02688,0.0,0.0,6.224,5.727
1442673038000,3.5406952,1188.384768,0.876544,0.008192,9.798,9.062,1.2382061,1161.977856,0.0,0.0,2.305,2.005,1.1980399,1227.481088,0.190464,0.0,1.808,1.824,5.1397863,1233.829888,6.135808,0.0,2.219,1.975,1.1667589,1086.410752,0.0,0.0,1.462,1.655
1442673098000,1.9165329,1186.59072,1.86368,0.008192,16.823,16.44,0.7254034,1151.750144,0.0,0.0,2.389,2.424,0.8357588,1225.371648,0.477184,0.0,1.835,1.632,3.0447948,1406.005248,4.153344,0.0,3.93,3.144,0.6500719,1085.288448,0.0,0.0,4.025,3.346
1442673158000,4.203135,1178.529792,2.035712,0.012288,21.933,20.217,1.5541509,1117.929472,0.0,0.0,2.573,2.18,1.8866218,1212.387328,0.632832,0.0,3.286,2.946,4.6205974,1502.568448,7.405568,0.0,3.854,3.338,1.787413,1069.518848,0.0,0.0,3.698,3.54
1442673218000,9.4000474,1174.380544,3.555328,0.02048,41.418,38.943,2.0495333,1094.119424,0.012288,0.0,5.254,4.484,2.3001163,1207.320576,1.466368,0.0,4.28,3.938,2.5413498,1544.835072,0.0,0.0,5.928,5.448,2.7615914,1063.15776,0.0,0.0,8.472,7.074
1442673278000,7.1898339,1171.992576,3.9936,0.016384,40.962,38.01,1.9302308,1114.292224,0.0,0.0,5.015,4.502,2.3543122,1205.47328,1.437696,0.0,4.787,4.259,3.0403394,1539.493888,0.0,0.0,6.785,6.575,2.4265093,1058.832384,0.0,0.0,5.322,4.49
1442673338000,7.6237288,1171.607552,2.953216,0.012288,42.432,39.472,2.7254046,1114.144768,0.032768,0.0,4.816,4.965,2.6259322,1203.634176,1.134592,0.0,4.705,4.265,2.6565287,1539.70688,0.0,0.0,7.347,6.077,2.9069692,1058.803712,0.032768,0.0,5.948,5.887
1442673398000,5.3218458,1170.157568,4.046848,0.016384,29.731,28.391,1.9422709,1114.312704,0.008192,0.0,5.324,4.376,1.7275559,1204.662272,1.23904,0.0,3.64,3.377,2.6632323,1537.318912,0.032768,0.0,4.125,3.804,1.4919662,1058.4064,0.0,0.0,4.234,4.223
1442673459000,1.2740093,1149.239296,0.010922,0.013653,2.574,2.337,0.233835,1090.88768,0.021845,0.0,1.084,1.127,0.5372152,1187.381248,0.0,0.005461,0.77,0.727,0.3976691,1516.3392,0.0,0.0,1.118,1.208,0.3722865,1041.432576,0.0,0.0,1.103,0.953
1442673519000,3.7417185,1164.029952,0.704512,0.0,18.868,17.686,1.0152328,1110.15936,0.0,0.0,2.713,2.604,0.9375095,1198.215168,0.475136,0.006144,3.38,2.838,1.3841471,1533.349888,0.0,0.0,3.575,3.348,1.5579816,1054.109696,0.0,0.0,3.24,2.831
1442673579000,6.4502052,1163.546624,2.883584,0.0,42.829,40.01,3.2850698,1114.107904,0.0,0.0,7.801,6.268,2.0948575,1201.963008,1.595392,0.01024,4.307,4.396,2.4855435,1531.019264,0.0,0.0,5.129,5.19,2.2259724,1052.01664,0.032768,0.0,7.518,5.979
1442673639000,7.2721415,1164.750848,3.209216,0.0,40.883,38.384,2.9914925,1117.237248,0.032768,0.0,5.366,5.253,2.6562811,1207.267328,1.908736,0.008192,5.534,5.123,2.825911,1532.399616,0.0,0.0,5.936,5.243,2.3557922,1053.769728,0.0,0.0,5.786,5.113
1442673700000,7.7690516,1164.812288,2.08896,0.0,44.897,41.866,2.6715275,1115.389952,0.032768,0.0,6.212,5.395,2.9444763,1206.669312,1.368064,0.01024,6.226,4.811,2.769011,1534.30016,0.0,0.0,6.721,6.473,2.4384091,1059.229696,0.004096,0.0,6.21,5.682
1442673760000,8.427535,1168.502784,3.825664,0.0,53.658,50.438,2.3550252,1115.38176,0.0,0.0,6.921,5.279,2.9973476,1209.167872,1.800192,0.008192,6.32,5.61,3.4119852,1535.504384,0.0,0.0,7.634,6.36,3.9759741,1059.98336,0.0,0.0,9.547,7.307
1442673820000,9.6942298,1169.330176,3.375104,0.0,54.067,50.487,3.8921147,1117.057024,0.028672,0.0,7.804,6.972,3.4692684,1209.905152,1.359872,0.012288,6.684,5.619,3.1937873,1535.791104,0.0,0.0,7.079,6.151,3.4006537,1058.230272,0.0,0.0,7.901,6.991
1442673880000,6.8684906,1169.85856,4.79232,0.0,42.371,39.941,2.1871165,1115.025408,0.032768,0.0,6.761,5.183,2.264185,1209.233408,1.189888,0.004096,5.086,4.069,2.1887845,1539.026944,0.0,0.0,5.511,4.92,1.893625,1059.700736,0.0,0.0,7.664,6.793
1442673940000,5.2201341,1171.349504,3.03104,0.0,33.416,31.167,1.2456786,1110.929408,0.0,0.0,4.097,4.048,2.414321,1209.245696,1.705984,0.004096,6.052,5.315,1.5578283,1536.192512,0.0,0.0,2.815,2.978,7.3085131,1062.141952,0.249856,0.0,5.834,5.76
1442674000000,7.5218616,1169.047552,2.598912,0.0,43.315,40.158,2.9573705,1110.07744,0.024576,0.0,6.381,6.642,2.9767451,1210.34752,1.4848,0.004096,6.01,5.149,2.8560666,1536.8192,0.0,0.0,5.771,5.551,2.3814811,1063.415808,0.004096,0.0,5.908,5.221
1442674061000,6.738111,1165.35296,2.064384,0.0,57.287,53.387,2.8914578,1107.140608,0.032768,0.0,8.29,7.83,2.4211674,1206.644736,0.78848,0.0,6.242,4.515,2.7639534,1535.741952,0.0,0.0,8.871,7.165,2.4287148,1060.585472,0.0,0.0,9.244,8.562
1442674121000,5.9517519,1161.490432,2.68288,0.0,42.907,39.166,2.720011,1098.088448,0.032768,0.0,6.373,5.663,1.4622332,1201.8688,1.097728,0.0,5.105,4.365,2.6352701,1536.217088,0.0,0.0,7.707,7.077,1.3144771,1047.703552,0.0,0.0,4.431,4.308
1442674182000,6.5228629,1160.564736,2.240512,0.0,41.35,38.401,2.1752306,1102.401536,0.036864,0.0,5.758,4.973,2.4259954,1202.393088,2.34496,0.0,5.87,5.581,2.0448778,1531.715584,0.0,0.0,5.172,4.756,2.3072237,1047.769088,0.016384,0.0,6.015,4.609
1442674242000,7.9884272,1161.78944,2.789376,0.0,43.185,40.019,2.6211655,1106.345984,0.049152,0.0,6.338,5.517,1.9407103,1201.3568,1.06496,0.0,5.096,4.552,3.0860467,1531.727872,0.0,0.0,5.524,4.977,3.0383501,1050.517504,0.0,0.0,7.746,7.738
1442674302000,6.8604003,1159.774208,3.371008,0.0,47.507,44.051,2.7632644,1109.082112,0.0,0.0,4.859,4.443,2.6935907,1202.569216,2.08896,0.0,5.803,5.44,2.9430547,1538.609152,0.0,0.0,8.436,7.074,2.4091194,1048.956928,0.0,0.0,7.074,6.709
1442674362000,7.205702,1157.267456,2.392064,0.0,46.384,43.157,2.8983151,1115.549696,0.028672,0.0,7.0,6.213,2.4778863,1204.502528,1.789952,0.0,4.314,4.308,3.5757552,1535.95904,0.0,0.0,7.995,6.275,1.8551076,1050.29632,0.004096,0.0,6.525,5.968
1442674422000,7.0846258,1158.709248,1.767424,0.0,37.943,35.302,2.9511562,1109.901312,0.008192,0.0,6.431,5.263,2.5813753,1202.31936,1.662976,0.0,4.424,4.642,2.2887555,1535.463424,0.0,0.0,5.598,4.962,2.9018199,1047.814144,0.024576,0.0,5.429,5.196
1442674482000,6.5396279,1158.823936,3.09248,0.0,44.612,41.649,3.164286,1110.482944,0.0,0.0,5.786,5.402,2.0177401,1201.487872,1.386496,0.0,5.625,4.942,2.1702317,1537.814528,0.0,0.0,5.569,4.666,2.2316583,1043.451904,0.0,0.0,8.113,6.821
1442674542000,7.1902457,1156.62848,1.595392,0.0,40.88,38.524,3.1974484,1105.481728,0.012288,0.0,6.391,6.113,2.2579482,1204.989952,1.746944,0.0,5.292,5.063,1.7817053,1535.459328,0.0,0.0,4.752,3.632,2.166141,1044.91008,0.0,0.0,6.714,6.114
1442674603000,7.8001242,1155.371008,4.8128,0.0,43.395,41.012,3.0005903,1103.9744,0.0,0.0,5.961,5.332,2.5732256,1204.977664,1.091584,0.0,5.641,4.612,2.9718444,1535.246336,0.0,0.0,6.943,6.281,3.46645,1047.30624,0.0,0.0,6.318,5.792
1442674664000,6.5525389,1159.33184,3.13344,0.0,38.099,35.179,2.8617011,1105.813504,0.0,0.0,4.974,4.264,2.4486041,1210.687488,0.589824,0.0,4.426,3.755,2.1929473,1535.803392,0.04096,0.0,5.798,5.739,2.1031649,1044.922368,0.0,0.0,5.981,5.497
1442674725000,7.2330471,1158.340608,3.457024,0.0,40.677,37.754,3.4056387,1091.198976,0.0,0.0,6.306,5.79,1.4570132,1199.955968,1.255424,0.0,4.557,4.081,3.5895427,1528.336384,0.0,0.0,8.106,6.801,1.9004244,1024.360448,0.0,0.0,3.488,3.312
1442674785000,7.5644114,1157.648384,2.62144,0.0,41.068,38.16,3.2812765,1095.995392,0.032768,0.0,5.774,5.514,1.5512116,1202.737152,0.83968,0.0,4.894,3.988,2.503196,1536.671744,0.0,0.0,5.758,4.742,3.2843601,1029.65248,0.004096,0.0,7.179,7.181
1442674845000,5.2420337,1161.060352,3.526656,0.0,35.522,33.266,2.7115492,1102.528512,0.032768,0.0,5.974,5.327,1.3465534,1204.973568,0.704512,0.0,3.748,3.07,1.8303903,1543.2704,0.0,0.0,5.912,5.848,1.2609734,1024.585728,0.0,0.0,4.879,4.363
1442674905000,6.7395976,1164.709888,3.411968,0.0,41.09,38.612,2.5109674,1100.423168,0.012288,0.0,5.943,5.56,2.4348792,1202.01216,0.915456,0.0,5.299,4.09,2.5029722,1539.497984,0.0,0.0,6.544,5.201,2.8051483,1039.9744,0.0,0.0,6.217,5.322
1442674965000,6.6654657,1159.274496,2.625536,0.0,42.374,39.542,2.6162147,1096.921088,0.0,0.0,4.682,4.579,2.556682,1205.190656,2.031616,0.0,5.517,4.993,2.3021016,1543.92576,0.0,0.0,6.587,6.46,2.1702245,1035.014144,0.0,0.0,7.088,5.278
1442675025000,7.3034058,1161.547776,3.629056,0.0,45.689,42.544,3.6144948,1095.294976,0.0,0.0,7.601,6.52,3.3449088,1205.497856,1.220608,0.0,6.115,5.474,2.5437472,1539.452928,0.0,0.0,7.105,6.845,2.1629931,1038.778368,0.0,0.0,5.238,4.33
1442675085000,8.9371671,1162.01472,3.854336,0.0,52.967,49.733,3.354325,1093.65248,0.0,0.0,7.308,6.171,3.1157714,1205.293056,1.165312,0.0,6.663,5.067,3.3428588,1542.643712,0.008192,0.0,7.894,7.331,3.7892849,1040.32256,0.0,0.0,7.736,5.865
1442675145000,7.7246226,1165.295616,3.526656,0.0,48.323,45.634,3.3963897,1094.5536,0.0,0.0,6.829,6.212,3.1632431,1210.568704,1.486848,0.0,6.099,5.465,2.9873041,1537.261568,0.0,0.0,6.244,5.592,2.4804618,1039.384576,0.0,0.0,7.766,6.592
1442675205000,6.9407964,1165.512704,2.695168,0.0,38.19,35.963,2.4202467,1094.664192,0.04096,0.0,6.038,5.674,2.5599044,1209.389056,1.257472,0.0,5.746,5.051,2.4371745,1534.021632,0.0,0.0,5.205,4.955,1.7192313,1042.993152,0.0,0.0,4.943,4.83
1442675265000,7.926132,1166.241792,2.2528,0.0,48.228,45.24,2.8396044,1095.8848,0.0,0.0,7.036,6.423,2.3218933,1207.906304,0.837632,0.0,5.795,4.81,2.9727884,1539.207168,0.0,0.0,7.329,6.348,3.1686685,1042.735104,0.0,0.0,8.29,6.821
1442675325000,7.6264748,1162.846208,2.195456,0.0,36.128,33.936,3.5648718,1087.184896,0.0,0.0,5.605,5.336,2.7060031,1205.6576,1.476608,0.0,5.553,4.885,2.8561234,1506.029568,0.0,0.0,5.632,5.103,2.3951463,1033.596928,0.0,0.0,4.37,4.149
1442675385000,7.0364412,1165.02528,2.813952,0.0,41.392,38.055,2.3584431,1090.965504,0.032768,0.0,5.189,4.844,2.578238,1198.03904,1.441792,0.0,4.749,4.494,2.9160368,1506.873344,0.0,0.0,6.557,6.237,2.838815,1035.677696,0.024576,0.0,5.215,5.291
1442675446000,7.9788305,1166.180352,3.080192,0.0,46.393,43.746,2.6245727,1094.545408,0.0,0.0,7.105,5.864,1.9077109,1204.645888,0.753664,0.0,4.114,3.581,3.6099166,1509.625856,0.032768,0.0,7.866,7.017,3.0805604,1037.88544,0.024576,0.0,8.074,7.205
1442675506000,8.2764618,1169.608704,2.850816,0.0,49.906,46.518,3.4187954,1094.582272,0.036864,0.0,6.921,5.803,2.6906641,1201.401856,1.865728,0.0,6.574,5.992,2.6983446,1510.227968,0.0,0.0,6.406,6.341,3.0395092,1038.31552,0.0,0.0,7.648,6.395
1442675566000,7.6453518,1168.658432,3.080192,0.0,43.919,41.32,1.7097144,1095.774208,0.0,0.0,4.548,3.51,2.9288597,1201.836032,0.73728,0.0,5.979,4.901,3.1915015,1512.517632,0.012288,0.0,6.37,5.578,2.9718158,1039.577088,0.0,0.0,7.457,7.563
1442675626000,8.85335,1175.379968,1.912832,0.0,44.554,41.581,3.4420784,1096.04864,0.0,0.0,6.025,6.056,2.9833612,1203.249152,1.605632,0.0,5.707,5.097,3.5955659,1512.439808,0.0,0.0,7.976,6.749,3.3159932,1043.320832,0.0,0.0,6.027,4.927
1442675686000,6.2606254,1175.293952,3.2768,0.0,36.191,33.673,1.8808685,1097.44128,0.004096,0.0,3.993,3.667,1.9169265,1205.174272,1.81248,0.0,4.454,4.145,2.5919634,1511.886848,0.0,0.0,5.991,5.245,2.3454081,1041.28512,0.032768,0.0,5.552,5.704
1442675746000,8.60109,1174.50752,2.363392,0.0,38.153,35.876,3.1048491,1092.800512,0.0,0.0,5.158,4.644,3.9556308,1218.859008,3.66592,0.0,6.345,5.618,2.7493844,1509.76512,0.0,0.0,4.61,3.819,2.565755,1041.821696,0.0,0.0,5.151,3.902
1442675806000,7.3730828,1173.01248,2.695168,0.0,45.174,42.082,2.4290839,1090.789376,0.016384,0.0,6.22,5.249,1.8337143,1217.72032,0.550912,0.0,5.795,4.409,3.3642093,1505.255424,0.0,0.0,6.091,5.097,3.4214193,1044.76672,0.032768,0.0,7.934,7.027
1442675866000,8.4241783,1173.221376,3.854336,0.0,43.517,41.372,3.2839094,1094.946816,0.0,0.0,4.057,3.894,2.6249394,1216.479232,1.308672,0.0,5.817,4.32,3.7239485,1503.977472,0.008192,0.0,7.331,6.202,3.0258506,1044.3776,0.0,0.0,6.781,5.681
1442675926000,6.8904564,1163.771904,2.551808,0.0,43.144,40.228,1.9652263,1081.352192,0.032768,0.0,5.216,4.955,2.2507177,1211.31008,0.968704,0.0,4.543,3.888,3.2516415,1465.008128,0.0,0.0,7.631,7.56,2.5510899,1038.962688,0.032768,0.0,7.02,5.693
1442675986000,7.3739961,1165.938688,1.982464,0.0,41.207,38.597,3.0194255,1088.65536,0.0,0.0,4.987,4.737,3.1054983,1215.193088,1.220608,0.0,5.733,5.157,3.2017163,1460.064256,0.065536,0.0,6.705,5.871,2.3498079,1039.33952,0.0,0.0,6.321,5.697
1442676046000,8.0866099,1169.395712,2.453504,0.0,47.012,44.182,3.7005461,1091.97312,0.0,0.0,5.951,6.26,3.6061091,1212.219392,2.367488,0.0,8.009,6.637,2.818142,1460.760576,0.0,0.0,7.634,5.929,2.4838869,1040.75264,0.0,0.0,5.816,4.887
1442676106000,8.5123541,1174.392832,3.022848,0.0,40.183,37.607,3.996197,1089.826816,0.065536,0.0,7.11,6.855,3.0126527,1214.943232,1.09568,0.0,5.149,4.525,2.7232286,1456.934912,0.0,0.0,5.73,4.827,2.4805419,1043.259392,0.065536,0.0,5.006,4.866
1442676167000,7.2451774,1179.50464,1.708032,0.0,45.303,41.728,2.5997742,1094.795264,0.0,0.0,5.957,5.149,1.9032098,1219.600384,1.277952,0.0,3.534,3.401,2.8409558,1466.929152,0.032768,0.0,6.903,5.795,2.7856596,1049.952256,0.0,0.0,7.642,7.053
1442676228000,8.0933004,1180.332032,3.19488,0.0,42.522,39.587,2.6108041,1114.607616,0.0,0.0,4.094,3.645,4.0856317,1223.622656,2.588672,0.0,7.087,7.565,2.6488547,1463.066624,0.0,0.0,5.564,4.499,2.8499306,1048.24832,0.0,0.0,6.889,5.616
1442676288000,7.2569015,1178.406912,3.301376,0.0,43.068,39.706,3.037735,1118.14656,0.004096,0.0,7.138,5.546,2.4987104,1215.83616,0.892928,0.0,3.843,3.262,3.0463305,1464.373248,0.032768,0.0,5.868,5.354,2.7359518,1046.839296,0.008192,0.0,6.909,5.872
1442676348000,6.8639187,1179.942912,3.776512,0.0,42.835,40.298,2.0847133,1116.966912,0.032768,0.0,6.614,5.59,2.9159515,1220.56704,1.163264,0.0,5.585,5.118,3.1371022,1463.304192,0.0,0.0,6.259,5.378,1.3756473,1044.246528,0.0,0.0,5.963,4.517
1442676408000,10.0884712,1176.891392,1.72032,0.0,57.902,53.531,4.1586748,1118.408704,0.016384,0.0,8.904,7.61,2.6693376,1218.981888,1.245184,0.0,6.996,5.573,4.3181201,1470.13632,0.032768,0.0,7.915,6.877,3.7629153,1046.982656,0.0,0.0,8.007,6.988
1442676468000,9.2024089,1178.312704,4.902912,0.0,51.007,47.859,4.2052902,1117.360128,0.0,0.0,6.636,5.852,4.0186987,1219.350528,1.511424,0.0,6.563,5.347,3.4228871,1466.732544,0.0,0.0,6.988,6.231,3.9699365,1045.315584,0.032768,0.0,8.366,6.914
1442676528000,7.5070972,1171.030016,2.912256,0.0,43.291,40.236,2.38118,1106.239488,0.0,0.0,4.982,4.488,2.5643395,1213.78816,1.517568,0.0,5.349,5.036,3.2213941,1427.341312,0.0,0.0,6.192,5.752,2.6120532,1033.244672,0.012288,0.0,7.801,6.458
1442676588000,7.7758703,1169.362944,2.06848,0.0,51.303,47.031,3.9870119,1111.871488,0.036864,0.0,7.892,7.395,2.0539085,1214.148608,1.202176,0.0,5.362,4.405,2.8272347,1430.667264,0.065536,0.0,8.487,6.615,2.555021,1038.987264,0.065536,0.0,5.943,4.935
1442676648000,7.5417303,1168.248832,3.719168,0.0,47.411,44.451,1.8818623,1108.62336,0.0,0.0,6.168,4.615,2.4625988,1212.628992,1.073152,0.0,5.652,4.654,2.931491,1425.252352,0.0,0.0,8.904,7.79,3.0331539,1048.858624,0.045056,0.0,6.897,6.941
1442676709000,6.2384451,1168.883712,3.616768,0.02048,41.238,38.455,2.7140029,1104.556032,0.065536,0.0,6.934,5.848,2.4652597,1218.891776,1.685504,0.0,4.079,4.188,2.1426027,1426.075648,0.0,0.0,7.169,5.764,2.0035135,1048.24832,0.008192,0.0,4.868,3.888
1442676769000,8.3812687,1167.863808,2.7648,0.02048,56.01,51.911,3.5390955,1108.987904,0.024576,0.0,7.516,6.351,2.786027,1216.929792,1.132544,0.0,5.949,4.927,3.0941497,1435.959296,0.0,0.0,9.109,7.435,3.263054,1043.816448,0.0,0.0,8.429,7.317
1442676829000,7.9914452,1171.29216,3.121152,0.016384,44.74,41.636,3.4182937,1110.2208,0.0,0.0,6.336,5.197,2.7356077,1222.729728,2.684928,0.0,5.626,5.804,2.5896905,1431.216128,0.0,0.0,4.379,4.315,2.3598656,1047.482368,0.032768,0.0,7.355,6.903
1442676889000,7.4580748,1170.788352,2.10944,0.02048,50.05,46.884,3.0160415,1104.510976,0.0,0.0,6.671,5.759,1.9732039,1232.30208,1.779712,0.0,5.64,5.292,2.5728348,1432.563712,0.032768,0.0,9.077,7.723,2.6625177,1056.190464,0.032768,0.0,7.458,6.278
1442676949000,7.2882017,1173.671936,3.915776,0.012288,44.978,41.918,2.6788108,1104.437248,0.032768,0.0,5.515,4.614,2.31632,1228.300288,1.355776,0.0,5.674,4.836,2.9221529,1433.952256,0.065536,0.0,7.058,6.447,2.570841,1054.281728,0.0,0.0,6.405,5.689
1442677009000,7.6640122,1172.484096,1.26976,0.012288,46.083,43.165,2.9801678,1104.408576,0.0,0.0,6.572,5.744,2.8449517,1228.509184,1.529856,0.0,5.947,5.402,2.5158471,1433.956352,0.065536,0.0,5.504,5.306,2.9868868,1061.625856,0.0,0.0,8.095,7.679
1442677070000,6.790334,1172.852736,2.39616,0.008192,43.113,40.779,2.2891004,1104.891904,0.053248,0.0,5.688,5.073,2.2942105,1230.016512,1.736704,0.0,5.597,5.633,2.3449259,1430.904832,0.036864,0.0,6.19,5.37,2.5801753,1058.504704,0.0,0.0,8.126,7.314
1442677131000,6.7398554,1169.035264,3.284992,0.0,41.692,39.206,3.0734297,1089.306624,0.0,0.0,5.642,6.038,2.2955676,1222.73792,1.155072,0.004096,4.895,4.572,2.4520673,1393.815552,0.049152,0.0,6.65,5.475,2.277103,1050.451968,0.0,0.0,6.028,5.156
1442677191000,8.1343271,1166.0288,3.03104,0.0,51.15,48.173,2.6329959,1109.651456,0.036864,0.0,6.195,5.132,3.8464986,1217.10592,1.800192,0.004096,8.04,7.126,2.9867946,1396.0192,0.004096,0.0,6.431,4.92,3.4206152,1047.130112,0.0,0.0,8.532,6.707
1442677251000,7.7663723,1165.778944,3.129344,0.0,48.972,45.463,3.2579295,1102.675968,0.0,0.0,7.684,5.941,2.4620197,1222.81984,2.054144,0.008192,5.148,5.073,2.4988922,1397.215232,0.0,0.0,7.348,6.942,3.1363573,1047.40864,0.012288,0.0,7.337,6.251
1442677311000,6.7519058,1164.353536,2.854912,0.0,39.653,37.196,2.1826333,1109.164032,0.0,0.0,4.289,4.122,2.1821727,1225.703424,1.378304,0.004096,5.816,4.87,2.8478966,1400.475648,0.0,0.0,6.034,5.237,2.0666777,1049.165824,0.0,0.0,7.452,6.396
1442677371000,7.3512339,1166.036992,3.715072,0.0,42.333,40.103,2.7218191,1111.248896,0.0,0.0,5.108,5.026,2.6894798,1223.090176,1.103872,0.01024,5.573,4.554,2.0323803,1401.085952,0.0,0.0,6.728,5.738,3.1001186,1047.932928,0.0,0.0,6.379,5.424
1442677432000,8.4216574,1168.11776,3.371008,0.0,48.07,44.801,3.1974778,1112.469504,0.032768,0.0,6.04,5.486,3.3304947,1227.259904,1.497088,0.004096,6.076,5.433,2.718559,1403.420672,0.0,0.0,5.64,5.102,3.8231772,1046.843392,0.0,0.0,9.145,7.865
1442677493000,7.1408166,1168.826368,3.91168,0.0,45.894,43.136,2.0218744,1112.584192,0.0,0.0,5.524,4.981,2.3762457,1227.411456,1.263616,0.0,5.121,4.682,2.469826,1403.10528,0.032768,0.0,7.016,6.382,2.9650089,1049.8048,0.0,0.0,7.192,6.353
1442677553000,7.8923111,1168.805888,4.030464,0.0,55.464,52.03,4.3109276,1114.128384,0.0,0.0,8.932,7.517,1.7151448,1226.469376,0.888832,0.0,5.602,4.681,2.7751234,1407.479808,0.036864,0.0,8.5,7.265,3.8081903,1048.563712,0.0,0.0,7.972,7.081
1442677614000,9.1972271,1171.726336,2.535424,0.0,49.944,46.463,3.4704753,1116.065792,0.049152,0.0,8.174,6.501,2.9471633,1227.534336,1.816576,0.0,5.253,5.374,3.2069839,1404.624896,0.0,0.0,6.181,5.692,3.2248558,1048.027136,0.0,0.0,7.301,6.871
1442677674000,8.7422288,1168.257024,2.809856,0.0,60.783,56.924,2.3823502,1115.0336,0.0,0.0,9.065,7.298,2.8089979,1229.602816,1.52576,0.0,6.999,6.137,2.4698736,1404.461056,0.024576,0.0,7.008,6.512,4.0658293,1050.271744,0.0,0.0,10.149,8.649
1442677734000,8.0567915,1162.809344,3.424256,0.0,51.174,48.809,5.7011022,1106.751488,0.032768,0.0,8.555,7.654,3.3321437,1225.87136,1.398784,0.0,6.048,5.012,3.1636057,1374.273536,0.0,0.0,7.652,6.801,2.9067369,1046.4256,0.0,0.0,7.574,6.9
1442677794000,8.6288814,1168.777216,4.5056,0.0,52.288,49.57,3.9501367,1108.942848,0.0,0.0,8.377,6.91,2.8912763,1230.278656,1.08544,0.0,6.041,4.795,3.2022281,1376.534528,0.0,0.0,6.742,6.591,3.8754488,1045.241856,0.0,0.0,7.785,6.359
1442677854000,9.2291498,1172.484096,3.780608,0.0,59.219,55.495,3.5655561,1108.156416,0.0,0.0,7.384,6.039,3.1316626,1226.104832,1.275904,0.0,7.857,6.369,3.3161632,1373.691904,0.0,0.0,9.162,7.508,3.2289039,1048.993792,0.0,0.0,7.471,6.75
1442677914000,7.6046366,1171.054592,3.682304,0.0,51.45,48.348,2.6292926,1109.159936,0.0,0.0,7.239,6.526,2.0021215,1232.633856,1.314816,0.0,4.671,4.27,3.1919304,1370.33728,0.016384,0.0,8.203,7.172,2.6849804,1049.133056,0.0,0.0,8.37,6.791
1442677975000,9.7552544,1166.237696,1.998848,0.0,55.513,51.967,5.1639706,1113.616384,0.0,0.0,8.169,6.757,3.3361351,1231.859712,1.824768,0.0,7.295,6.068,3.2407852,1374.973952,0.032768,0.0,8.595,7.406,3.5986019,1050.210304,0.0,0.0,7.264,6.446
1442678036000,7.3906027,1169.7152,2.584576,0.0,52.989,49.382,2.1063893,1113.10848,0.008192,0.0,7.169,5.951,3.5789247,1231.736832,0.991232,0.0,7.226,6.135,2.6569722,1370.374144,0.032768,0.0,8.127,7.899,2.6108313,1050.68544,0.0,0.0,6.981,5.74
1442678096000,6.9336998,1169.235968,2.21184,0.0,51.488,47.373,1.5915532,1112.215552,0.0,0.0,5.469,4.8,3.1667046,1231.695872,2.568192,0.0,7.328,7.477,2.5985584,1371.99616,0.0,0.0,6.358,5.779,1.931514,1049.915392,0.0,0.0,7.696,7.574
1442678156000,7.846385,1168.75264,3.510272,0.0,52.673,49.584,3.9959899,1114.099712,0.032768,0.0,8.878,7.379,2.941618,1231.970304,1.404928,0.0,6.108,5.369,2.6660983,1376.526336,0.0,0.0,7.849,6.876,3.4338011,1049.862144,0.008192,0.0,7.43,6.034`;
