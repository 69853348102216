/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
***/

/**
 * Convert the metric unit string into unit. The unit string is like  (Memory MB)
 */
const metricUnitParser = str => {
  let unit = str || '';

  const sidx = unit.indexOf('(');
  const eidx = unit.indexOf(')');
  if (sidx >= 0 && eidx > 0) {
    unit = unit.substring(sidx + 1, eidx);
  }
  return unit;
};

export default metricUnitParser;
