import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { isFinite, floor } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Progress, Row, Table } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { calcColorOfHealthScore } from '../../../common/utils';
import { Popover, Tooltip } from '../../../lib/fui/react';
import { State } from '../../../common/types';
import { DashboardMessages } from '../../../common/dashboard/messages';

const { Column } = Table;

function PublicBuildCizizenCore({
  summaryObj,
  systemList,
  systemId,
  handleSystemIdChange,
  isServiceMap,
  userInfo,
  favorites: favoriteList = [],
  intl,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    systemInfoList: [],
    visible: false,
    systemInfo: {},
  });
  const { systemInfoList, visible, systemInfo } = state;

  const parseData = () => {
    systemList = R.sort((systemA, systemB) => systemA.name.localeCompare(systemB.name), systemList);
    if (!isServiceMap) {
      systemList = R.filter((item) => item.hasData, systemList);
    }
    let favorites = R.filter((item) => favoriteList.includes(item.id), systemList);
    let restSystems = R.filter((item) => !favoriteList.includes(item.id), systemList);
    favorites = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(favorites);
    restSystems = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(restSystems);
    // 把hasData = false 放在后面
    let notHasDataSystems = [];
    if (isServiceMap) {
      notHasDataSystems = R.filter((item) => !item.hasData, restSystems);
      restSystems = R.filter((item) => item.hasData, restSystems);
    }

    systemList = [...favorites, ...restSystems, ...notHasDataSystems];

    const systemInfoList = systemList;

    R.forEach((item) => {
      const { id } = item;
      const findItem = R.find((_item) => _item.systemKey === id, summaryObj) || {};
      item.avgHealthScore = findItem.avgHealthScore;
      const { color } = calcColorOfHealthScore(isFinite(findItem.avgHealthScore) ? findItem.avgHealthScore : 100);
      item.color = color;
    }, systemInfoList);

    const systemInfo = R.find((_item) => _item.id === systemId, systemInfoList) || {};
    setState({ systemInfoList, systemInfo });
  };

  useEffect(() => {
    parseData();
  }, [summaryObj, systemList]);

  useEffect(() => {
    if (systemInfo.id || systemId) {
      handleSystemIdChange(systemInfo.id || systemId);
    }
  }, [systemInfo.id]);

  return (
    <Popover
      placement="bottomRight"
      content={
        <DashCitizenList
          systemInfoList={systemInfoList}
          handleSystemClick={(systemInfo) => setState({ systemInfo, visible: false })}
          isServiceMap={isServiceMap}
          userInfo={userInfo}
          systemId={systemId}
        />
      }
      trigger="click"
      visible={visible}
      onVisibleChange={(visible) => {
        if (!visible) {
          setState({ visible: false });
        }
      }}
    >
      <Tooltip
        placement="rightBottom"
        mouseEnterDelay={0.3}
        overlayStyle={{ maxWidth: 400 }}
        arrowPointAtCenter
        title={
          <div className="flex-col" style={{ maxWidth: 400, maxHeight: 400, overflowY: 'auto' }}>
            <span>{`${intl.formatMessage(DashboardMessages.system)}:`}</span>
            <span style={{ paddingLeft: 20 }}>{systemInfo.name}</span>
            <span>{`${intl.formatMessage(DashboardMessages.projectList)}:`}</span>
            {R.addIndex(R.map)((project, idx) => {
              return (
                <span key={`row${project}${idx}`} style={{ paddingLeft: 20 }}>
                  {R.includes('@', project?.projectDisplayName || '')
                    ? project?.projectDisplayName
                    : `${project?.projectDisplayName}@${project?.userName}`}
                </span>
              );
            }, systemInfo?.projectNameSet || [])}
          </div>
        }
      >
        <div
          className="flex-row"
          style={{ height: 50, alignItems: 'self-end', marginRight: 20 }}
          onClick={() => setState({ visible: !visible })}
        >
          <div style={{ height: 'inherit', paddingTop: 5 }}>
            <Progress
              type="dashboard"
              width={48}
              strokeColor={systemInfo.color}
              strokeWidth={8}
              percent={Math.abs(isFinite(systemInfo.avgHealthScore) ? systemInfo.avgHealthScore : 0)}
              format={(percent2) => {
                return (
                  <div
                    style={{
                      color:
                        systemInfo.avgHealthScore === 0 || systemInfo.avgHealthScore === undefined
                          ? '#ccc'
                          : systemInfo.color,
                    }}
                  >
                    {isFinite(systemInfo.avgHealthScore) && systemInfo.avgHealthScore !== 0
                      ? floor(systemInfo.avgHealthScore)
                      : 'NA'}
                  </div>
                );
              }}
            />
          </div>
          <div style={{ maxWidth: 170, cursor: 'pointer', marginLeft: 10 }}>
            <div style={{ fontSize: 12, marginBottom: 5 }}>System</div>
            <div className="flex-row" style={{ alignItems: 'flex-end' }}>
              <span
                className="hidden-line-with-ellipsis"
                style={{ wordWrap: 'break-word', fontWeight: 700, fontSize: 16 }}
              >
                {systemInfo.name}
              </span>
              <CaretDownOutlined style={{ fontSize: 10, marginLeft: 10, paddingBottom: 2 }} />
            </div>
          </div>
        </div>
      </Tooltip>
    </Popover>
  );
}

const PublicBuildCizizen = injectIntl(PublicBuildCizizenCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { defaultTimezone, currentLocale, userList, systemsMap, currentTheme } = state.app;
  const { credentials } = state.auth;
  const { isAdmin } = state.auth.userInfo;
  return {
    location,
    defaultTimezone,
    currentLocale,
    userList,
    systemsMap,
    credentials,
    isAdmin,
    currentTheme,
    userInfo: state.auth.userInfo,
  };
}, {})(PublicBuildCizizen);

const DashCitizenList = ({ systemInfoList, handleSystemClick, isServiceMap, userInfo, systemId }: Object) => {
  const getProgressColor = (record) => {
    const { color } = calcColorOfHealthScore(isFinite(record.avgHealthScore) ? record.avgHealthScore : 100);
    return color;
  };

  const getProgressPercent = (record) => {
    return Math.abs(isFinite(record.avgHealthScore) ? record.avgHealthScore : 0);
  };

  const getProgressFormat = (record) => {
    const { color } = calcColorOfHealthScore(isFinite(record.avgHealthScore) ? record.avgHealthScore : 100);
    return (
      <div
        style={{ color: record.avgHealthScore === 0 || record.avgHealthScore === undefined ? '#ccc' : color }}
        className="clickable"
      >
        {isFinite(record.avgHealthScore) && record.avgHealthScore !== 0 ? floor(record.avgHealthScore) : 'NA'}
      </div>
    );
  };

  return (
    <div style={{ width: 500 }}>
      <Table
        dataSource={systemInfoList}
        size="small"
        pagination={false}
        scroll={{ y: 400 }}
        rowKey="id"
        onRow={(record) => {
          return {
            onClick: (event) => {
              handleSystemClick(record);
            },
          };
        }}
        rowClassName={(record, index) => {
          const { id } = record;
          return id === systemId ? 'ant-table-active-indicate' : '';
        }}
      >
        <Column
          title="Health"
          dataIndex="name"
          width={70}
          className="clickable"
          render={(text, record) => (
            <Row align="middle" gutter={10}>
              <Col>
                <Progress
                  type="dashboard"
                  width={35}
                  strokeColor={getProgressColor(record)}
                  strokeWidth={8}
                  percent={getProgressPercent(record)}
                  format={(percent2) => getProgressFormat(record)}
                />
              </Col>
            </Row>
          )}
        />
        <Column title="System" dataIndex="name" className="clickable" />
        <Column title="Owner" width={100} dataIndex="ownerUserName" className="clickable" />
      </Table>
    </div>
  );
};
