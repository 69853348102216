/* @flow */

import { defineMessages } from 'react-intl';

const DashboardMessages = defineMessages({
  priority: {
    defaultMessage: 'Priority',
    id: 'dashboard.priority',
  },
  ignored: {
    defaultMessage: 'Ignored',
    id: 'dashboard.ignored',
  },
  low: {
    defaultMessage: 'Low',
    id: 'dashboard.low',
  },
  mediumLow: {
    defaultMessage: 'Medium Low',
    id: 'dashboard.mediumLow',
  },
  medium: {
    defaultMessage: 'Medium',
    id: 'dashboard.medium',
  },
  mediumHigh: {
    defaultMessage: 'Medium High',
    id: 'dashboard.mediumHigh',
  },
  high: {
    defaultMessage: 'High',
    id: 'dashboard.high',
  },
  filter: {
    defaultMessage: 'Filter',
    id: 'dashboard.filter',
  },
  aggregationMode: {
    defaultMessage: 'Aggregation mode',
    id: 'dashboard.aggregationMode',
  },
  groupByComponent: {
    defaultMessage: 'Group by component',
    id: 'dashboard.groupByComponent',
  },
  derivedValueModel: {
    defaultMessage: 'Derived value model',
    id: 'dashboard.derivedValueModel',
  },
  previousDay: {
    defaultMessage: 'Previous Day',
    id: 'dashboard.previousDay',
  },
  previousDayAvg: {
    defaultMessage: 'Previous Day Avg',
    id: 'dashboard.previousDayAvg',
  },
  hourlyAvgAnomalyScore: {
    defaultMessage: 'Hourly Avg Anomaly Score',
    id: 'dashboard.hourlyAvgAnomalyScore',
  },
  anomalyScoreCharts: {
    defaultMessage: 'Anomaly Score Charts',
    id: 'dashboard.anomalyScoreCharts',
  },
  healthScore: {
    defaultMessage: 'Health Score',
    id: 'dashboard.healthScore',
  },
  healthScoreCharts: {
    defaultMessage: 'Health Score Charts',
    id: 'dashboard.healthScoreCharts',
  },
  anomalyScoreChartsEndTime: {
    defaultMessage: 'Current Time: {nowTime} GMT',
    id: 'dashboard.anomalyScoreChartsEndTime',
  },
  treeMap: {
    defaultMessage: 'Tree Map',
    id: 'dashboard.treeMap',
  },
  latestLogs: {
    defaultMessage: 'Latest Logs',
    id: 'dashboard.latestLogs',
  },
  anomalyDetails: {
    defaultMessage: 'Anomaly Details',
    id: 'dashboard.anomalyDetails',
  },
  anomalyHistory: {
    defaultMessage: 'Anomaly History',
    id: 'dashboard.anomalyHistory',
  },
  showHistoricalPredictions: {
    defaultMessage: 'Show historical predictions',
    id: 'dashboard.showHistoricalPredictions',
  },
  showDetectedIncidents: {
    defaultMessage: 'Show detected incidents',
    id: 'dashboard.showDetectedIncidents',
  },
  zoomTimelineTipTitle: {
    defaultMessage: 'How to zoom in on the timeline',
    id: 'dashboard.zoomTimelineTipTitle',
  },
  zoomTimelineTipText: {
    defaultMessage: 'To zoom into a specific range of time, click and drag on the timeline with the cursor',
    id: 'dashboard.zoomTimelineTipText',
  },
  zoomTimelineTipCheckboxText: {
    defaultMessage: 'Thanks, don’t show me this again',
    id: 'dashboard.zoomTimelineTipCheckboxText',
  },
  healthChart: {
    defaultMessage: 'Health Chart',
    id: 'dashboard.healthChart',
  },
  healthChartAnswer: {
    defaultMessage: 'Please click and zoom in on the health chart to view likely root causes and anomalies',
    id: 'dashboard.healthChartAnswer',
  },
  showPredictedIncidents: {
    defaultMessage: 'Show all predicted incidents',
    id: 'dashboard.showPredictedIncidents',
  },
  showDetectedAnomalies: {
    defaultMessage: 'Show all detected anomalies',
    id: 'dashboard.showDetectedAnomalies',
  },
  currentHourDayPredicted: {
    defaultMessage: 'Current Hour, Current Day, Predicted (4 hrs)',
    id: 'dashboard.currentHourDayPredicted',
  },
  currentSystemStatus: {
    defaultMessage: 'Current System Status',
    id: 'dashboard.currentSystemStatus',
  },
  predictSystemStatus: {
    defaultMessage: 'Predicted System Status',
    id: 'dashboard.predictSystemStatus',
  },
  metricAndLogAnomalys: {
    defaultMessage: 'Anomaly Events',
    id: 'dashboard.metricAndLogAnomalys',
  },
  metricLogAnomalies: {
    defaultMessage: 'Metric Anomalies, Log Anomalies, Predicted',
    id: 'dashboard.metricLogAnomalies',
  },
  metricAnomalies: {
    defaultMessage: 'Metric Anomalies',
    id: 'dashboard.metricAnomalies',
  },
  logAnomalies: {
    defaultMessage: 'Log Anomalies',
    id: 'dashboard.logAnomalies',
  },
  traceAnomalies: {
    defaultMessage: 'Trace Anomalies',
    id: 'dashboard.traceAnomalies',
  },
  metricAnomaliesEvents: {
    defaultMessage: 'Number of Consolidated Metric Anomaly Events',
    id: 'dashboard.metricAnomaliesEvents',
  },
  logAnomaliesEvents: {
    defaultMessage: 'Number of Anomalous Log Events',
    id: 'dashboard.logAnomaliesEvents',
  },
  followOnIncident: {
    defaultMessage: 'Followed By Incident',
    id: 'dashboard.followOnIncident',
  },
  predictedIncident: {
    defaultMessage: 'Predicted Incident',
    id: 'dashboard.predictedIncident',
  },
  predictedIncidents: {
    defaultMessage: 'Predicted Incidents',
    id: 'dashboard.predictedIncidents',
  },
  detectedIncidents: {
    defaultMessage: 'Detected Incidents',
    id: 'dashboard.detectedIncidents',
  },
  detectedAlerts: {
    defaultMessage: 'Alerts/Changes',
    id: 'dashboard.detectedAlerts',
  },
  detectedIncident: {
    defaultMessage: 'Detected Incident',
    id: 'dashboard.detectedIncident',
  },
  detectedTrace: {
    defaultMessage: 'Trace',
    id: 'dashboard.detectedTrace',
  },
  incidentCountPagerDuty: {
    defaultMessage: 'Current day count from PagerDuty',
    id: 'dashboard.incidentCountPagerDuty',
  },
  incidentCountServiceNow: {
    defaultMessage: 'Current day count from ServiceNow',
    id: 'dashboard.incidentCountServiceNow',
  },
  incidentCount: {
    defaultMessage: 'Current day incident count',
    id: 'dashboard.incidentCount',
  },
  matchedDetectedIncident: {
    defaultMessage: 'Matched Detected Incident',
    id: 'dashboard.matchedDetectedIncident',
  },
  detectedAnomalies: {
    defaultMessage: 'Detected Anomalies',
    id: 'dashboard.detectedAnomalies',
  },
  fixedPredictedIncidentCount: {
    defaultMessage: 'Auto-fixed Incidents',
    id: 'dashboard.fixedPredictedIncidentCount',
  },
  fixedDetectedIncidentCount: {
    defaultMessage: 'Auto-fixed Incidents',
    id: 'dashboard.fixedDetectedIncidentCount',
  },
  detectedDeployments: {
    defaultMessage: 'Change Events',
    id: 'dashboard.detectedDeployments',
  },
  softwareUpdates: {
    defaultMessage: 'Software Updates',
    id: 'dashboard.softwareUpdates',
  },
  fixed: {
    defaultMessage: 'Fixed',
    id: 'dashboard.fixed',
  },
  detected: {
    defaultMessage: 'Detected',
    id: 'dashboard.detected',
  },
  predicted: {
    defaultMessage: 'Predicted',
    id: 'dashboard.predicted',
  },
  predictedDayAvg: {
    defaultMessage: 'Predicted Day Avg',
    id: 'dashboard.predictedDayAvg',
  },
  lineChart: {
    defaultMessage: 'Line Chart',
    id: 'dashboard.lineChart',
  },
  capacityScore: {
    defaultMessage: 'Capacity Score',
    id: 'dashboard.capacityScore',
  },
  capacityPlanning: {
    defaultMessage: 'Capacity Planning',
    id: 'dashboard.capacityPlanning',
  },
  switchToTimelines: {
    defaultMessage: 'Switch to Timelines',
    id: 'dashboard.switchToTimelines',
  },
  switchToEvents: {
    defaultMessage: 'Switch to Events',
    id: 'dashboard.switchToEvents',
  },
  switchToCharts: {
    defaultMessage: 'Switch to Charts',
    id: 'dashboard.switchToCharts',
  },
  showPartEvents: {
    defaultMessage: 'Show Last Hour Events',
    id: 'dashboard.showPartEvents',
  },
  showAllEvents: {
    defaultMessage: 'Show All Events',
    id: 'dashboard.showAllEvents',
  },
  projectList: {
    defaultMessage: 'Project List',
    id: 'dashboard.projectList',
  },
  system: {
    defaultMessage: 'System',
    id: 'dashboard.system',
  },
  region: {
    defaultMessage: 'Region',
    id: 'dashboard.region',
  },
  component: {
    defaultMessage: 'Component',
    id: 'dashboard.component',
  },
  instance: {
    defaultMessage: 'Instance',
    id: 'dashboard.instance',
  },
  instances: {
    defaultMessage: 'Instances',
    id: 'dashboard.instances',
  },
  container: {
    defaultMessage: 'Container',
    id: 'dashboard.container',
  },
  containers: {
    defaultMessage: 'Containers',
    id: 'dashboard.containers',
  },
  reduceInstance: {
    defaultMessage: 'Reduce instance',
    id: 'dashboard.reduceInstance',
  },
  addInstance: {
    defaultMessage: 'Add instance',
    id: 'dashboard.addInstance',
  },
  reduceContainer: {
    defaultMessage: 'Reduce container',
    id: 'dashboard.reduceContainer',
  },
  addContainer: {
    defaultMessage: 'Add container',
    id: 'dashboard.addContainer',
  },
  currentHour: {
    defaultMessage: 'Current Hour',
    id: 'dashboard.currentHour',
  },
  now: {
    defaultMessage: 'Now',
    id: 'dashboard.now',
  },
  currentDay: {
    defaultMessage: 'Current Day',
    id: 'dashboard.currentDay',
  },
  currentDayAvg: {
    defaultMessage: 'Current Day Avg',
    id: 'dashboard.currentDayAvg',
  },
  predicatedHours: {
    defaultMessage: 'Predicted(4hr)',
    id: 'dashboard.predicatedHours',
  },
  clickForDetails: {
    defaultMessage: 'Click For Details',
    id: 'dashboard.clickForDetails',
  },
  clickBalloonForDetails: {
    defaultMessage: 'Click the {name} for details',
    id: 'dashboard.clickBalloonForDetails',
  },
  balloon: {
    defaultMessage: 'Balloon',
    id: 'dashboard.balloon',
  },
  triangle: {
    defaultMessage: 'Triangle',
    id: 'dashboard.triangle',
  },
  clickForLineChart: {
    defaultMessage: 'Click for linechart',
    id: 'dashboard.clickForLineChart',
  },
  incidentHasRootCause: {
    defaultMessage: 'This incident has root cause results.',
    id: 'dashboard.incidentHasRootCause',
  },
  incidentHasTrailing: {
    defaultMessage: 'This incident has trailing results.',
    id: 'dashboard.incidentHasTrailing',
  },
  leadToIncident: {
    defaultMessage: 'Lead to incident',
    id: 'dashboard.leadToIncident',
  },
  causedByChangeAnomaly: {
    defaultMessage: 'This anomaly is caused by a change event.',
    id: 'dashboard.causedByChangeAnomaly',
  },
  causedByChangeAlert: {
    defaultMessage: 'This alert is caused by a change event.',
    id: 'dashboard.causedByChangeAlert',
  },
  causedByChangeIncident: {
    defaultMessage: 'This incident is caused by a change event.',
    id: 'dashboard.causedByChangeIncident',
  },
  changeHasTrailing: {
    defaultMessage: 'This change event has trailing results.',
    id: 'dashboard.changeHasTrailing',
  },
  changeHasNoTrailing: {
    defaultMessage: 'This change event does not have trailing results.',
    id: 'dashboard.changeHasNoTrailing',
  },
  correlatedAnomalies: {
    defaultMessage: 'Correlated anomalies',
    id: 'dashboard.correlatedAnomalies',
  },
  topRankedRootCause: {
    defaultMessage: 'Number of top ranked root causes to display',
    id: 'dashboard.topRankedRootCause',
  },
  topLogAnomaly: {
    defaultMessage: 'Top Log Anomaly Keywords & Fields',
    id: 'dashboard.topLogAnomaly',
  },
  capacityPlanningAnomaly: {
    defaultMessage: 'Predicted System Usage Score',
    id: 'dashboard.capacityPlanningAnomaly',
  },
  machineOutage: {
    defaultMessage: 'Machine Outage',
    id: 'dashboard.machineOutage',
  },
  kpiViolation: {
    defaultMessage: 'KPI Violation',
    id: 'dashboard.kpiViolation',
  },
  metricThresholdViolations: {
    defaultMessage: 'Metric Threshold Violations',
    id: 'dashboard.metricThresholdViolations',
  },
  anomalyTimeline: {
    defaultMessage: 'Anomalous Event Timelines',
    id: 'dashboard.anomalyTimeline',
  },
  anomalyTimelineWithTime: {
    defaultMessage: 'Anomalous Event Timelines (detection time: {time})',
    id: 'dashboard.anomalyTimelineWithTime',
  },
  anomalyStatistics: {
    defaultMessage: 'Summary',
    id: 'dashboard.anomalyStatistics',
  },
  anomalyEventsDetected: {
    defaultMessage: 'Detected Anomalous Events',
    id: 'dashboard.anomalyEventsDetected',
  },
  anomalyEventsPredicted: {
    defaultMessage: 'Predicted Anomalous Events',
    id: 'dashboard.anomalyEventsPredicted',
  },
  anomalyInstanceStatistics: {
    defaultMessage: 'Anomaly Statistics',
    id: 'dashboard.anomalyInstanceStatistics',
  },
  anomalyChartsLogPercentage: {
    defaultMessage: 'Top 10 Log Types',
    id: 'dashboard.anomalyChartsLogPercentage',
  },
  predictionStatisticsByType: {
    defaultMessage: 'Prediction Statistics',
    id: 'dashboard.predictionStatisticsByType',
  },
  predictionStatisticsByInstance: {
    defaultMessage: 'Prediction Statistics By Components',
    id: 'dashboard.predictionStatisticsByInstance',
  },
  anomalyStatisticsByType: {
    defaultMessage: 'Anomaly Statistics By Type',
    id: 'dashboard.anomalyStatisticsByType',
  },
  anomalyStatisticsByInstance: {
    defaultMessage: 'Anomaly Statistics By Components',
    id: 'dashboard.anomalyStatisticsByInstance',
  },
  alertStatistics: {
    defaultMessage: 'Alert Statistics',
    id: 'event.alertStatistics',
  },
  alertPatternCountByType: {
    defaultMessage: 'Alert Pattern Count By Type',
    id: 'dashboard.alertPatternCountByType',
  },
  alertPatternCountByInstance: {
    defaultMessage: 'Alert Pattern Count By Components',
    id: 'dashboard.alertPatternCountByInstance',
  },
  anomalyChartsMetricPercentage: {
    defaultMessage: 'Top 10 Metrics',
    id: 'dashboard.anomalyChartsMetricPercentage',
  },
  anomalyChartsInstancePercentage: {
    defaultMessage: 'Top 10 Instances',
    id: 'dashboard.anomalyChartsInstancePercentage',
  },
  anomalyChartsFeatureKeyword: {
    defaultMessage: 'Log Feature',
    id: 'dashboard.anomalyChartsFeatureKeyword',
  },
  causalAnalysis: {
    defaultMessage: 'Causal Analysis',
    id: 'dashboard.causalAnalysis',
  },
  causalGroup: {
    defaultMessage: 'Causal',
    id: 'dashboard.causalGroup',
  },
  current: {
    defaultMessage: 'Current',
    id: 'dashboard.current',
  },
  noCapacitySet: {
    defaultMessage: 'For Capacity Planning capabilities, please set up Metric Kpi for monitored Systems.',
    id: 'dashboard.noCapacitySet',
  },
  score: {
    defaultMessage: 'Score',
    id: 'dashboard.score',
  },
  bottleneckComponent: {
    defaultMessage: 'Bottleneck Component',
    id: 'dashboard.bottleneckComponent',
  },
  bottleneckContainer: {
    defaultMessage: 'Bottleneck Container',
    id: 'dashboard.bottleneckContainer',
  },
  difference: {
    defaultMessage: 'Difference',
    id: 'dashboard.difference',
  },
  threshold: {
    defaultMessage: 'Threshold',
    id: 'dashboard.threshold',
  },
  causalGraph: {
    defaultMessage: 'Causal Graph',
    id: 'dashboard.causalGraph',
  },
  rootCauses: {
    defaultMessage: 'Root Causes',
    id: 'dashboard.rootCauses',
  },
  selectedRank: {
    defaultMessage: 'Selected rank',
    id: 'dashboard.selectedRank',
  },
  updateRelevance: {
    defaultMessage: 'Update Relevance',
    id: 'dashboard.updateRelevance',
  },
  suggestedActions: {
    defaultMessage: 'Suggested Actions',
    id: 'dashboard.suggestedActions',
  },
  logAnalysis: {
    defaultMessage: 'Log Analysis',
    id: 'dashboard.logAnalysis',
  },
  clickToSeeDetails: {
    defaultMessage: 'Click to see details',
    id: 'dashboard.clickToSeeDetails',
  },
  logEntries: {
    defaultMessage: 'Log Entries',
    id: 'dashboard.logEntries',
  },
  updateTotalMax: {
    defaultMessage: 'Update Total Max',
    id: 'dashboard.updateTotalMax',
  },
  limitFlag: {
    defaultMessage: 'Limit Flag',
    id: 'dashboard.limitFlag',
  },
  intervalHours: {
    defaultMessage: 'Interval(hours)',
    id: 'dashboard.intervalHours',
  },
  insightdWelcome: {
    defaultMessage: 'Welcome {name}! Here are your latest insights as of {week}, {month} {day} at {time}.',
    id: 'dashboard.insightdWelcome',
  },
  insightsHealthTrending: {
    defaultMessage: 'How is my system health trending in the past 30 days?',
    id: 'dashboard.insightsHealthTrending',
  },
  insightsPredictedIncidents: {
    defaultMessage: 'What is the average prediction accuracy in the past 30 days?',
    id: 'dashboard.insightsPredictedIncidents',
  },
  insightsIncidentPatterns: {
    defaultMessage: 'What are my top incident patterns in the past 30 days?',
    id: 'dashboard.insightsIncidentPatterns',
  },
  insightsIncidentVolume: {
    defaultMessage: 'How is my system incidents trending in the past 30 days?',
    id: 'dashboard.insightsIncidentVolume',
  },
  insightsAlertPatterns: {
    defaultMessage: 'What are my top alert patterns in the past 30 days?',
    id: 'dashboard.insightsAlertPatterns',
  },
  insightsAlertVolume: {
    defaultMessage: 'How is my system alerts trending in the past 30 days?',
    id: 'dashboard.insightsAlertVolume',
  },
  insightsAlertsSuppressed: {
    defaultMessage: 'How many alerts can be suppressed per day on average in the past 30 days?',
    id: 'dashboard.insightsAlertsSuppressed',
  },
  insightsAnomalousComponents: {
    defaultMessage: 'What are my top anomalous components in the current day?',
    id: 'dashboard.insightsAnomalousComponents',
  },
  insightsAnomalousInstances: {
    defaultMessage: 'What are my top anomalous instances in the current day?',
    id: 'dashboard.insightsAnomalousInstances',
  },
  insightsRootCauseInstances: {
    defaultMessage: 'What are my top root cause instances in the current day?',
    id: 'dashboard.insightsRootCauseInstances',
  },
  insightsRootCausePatterns: {
    defaultMessage: 'What are my top root cause patterns in the current day?',
    id: 'dashboard.insightsRootCausePatterns',
  },
  insightsRootCauseCategories: {
    defaultMessage: 'What are my top root cause categories in the current day?',
    id: 'dashboard.insightsRootCauseCategories',
  },
  insightsInvestigated: {
    defaultMessage: 'Who on my team has investigated the most incidents?',
    id: 'dashboard.insightsInvestigated',
  },
  insightsPreventIncidents: {
    defaultMessage: 'How else can I prevent incidents?',
    id: 'dashboard.insightsPreventIncidents',
  },
  insightsDowntimeSavings: {
    defaultMessage: 'Downtime savings',
    id: 'dashboard.insightsDowntimeSavings',
  },
  insightsMTTR: {
    defaultMessage: 'MTTR',
    id: 'dashboard.insightsMTTR',
  },
  insightsMTTRDescription: {
    defaultMessage:
      'Your mean time to resolve incidents based on total incidents from the past 30 days  divided by total time from detection to resolution.',
    id: 'dashboard.insightsMTTRDescription',
  },
  insightsMTTRTrendVs: {
    defaultMessage: 'MTTR trend vs. previous 30 days',
    id: 'dashboard.insightsMTTRTrendVs',
  },
  insightsLaborCostSavings: {
    defaultMessage: 'Total opportunity cost saving',
    id: 'dashboard.insightsLaborCostSavings',
  },
  insightsSavingsTrendVs: {
    defaultMessage: 'Savings trend vs. previous 30 days',
    id: 'dashboard.insightsSavingsTrendVs',
  },
  insightsStatisticsIncidentVolume: {
    defaultMessage: 'Incident volume',
    id: 'dashboard.insightsStatisticsIncidentVolume',
  },
  insightsStatisticsIncidentVolumeTrendVs: {
    defaultMessage: 'Incidents trend vs. previous 30 days',
    id: 'dashboard.insightsStatisticsIncidentVolumeTrendVs',
  },
  insightsStatisticsTotalMatched: {
    defaultMessage: 'Total correctly predicted incidents',
    id: 'dashboard.insightsStatisticsTotalMatched',
  },
  insightsStatisticsAverageLeadTime: {
    defaultMessage: 'Average prediction lead time',
    id: 'dashboard.insightsStatisticsAverageLeadTime',
  },
  incidents: {
    defaultMessage: 'incidents',
    id: 'dashboard.incidents',
  },
  metricIncidents: {
    defaultMessage: 'metric incidents',
    id: 'dashboard.metricIncidents',
  },
  logIncidents: {
    defaultMessage: 'log incidents',
    id: 'dashboard.logIncidents',
  },
  systemPreferences: {
    defaultMessage: 'system preferences',
    id: 'dashboard.systemPreferences',
  },
  dependencyMaps: {
    defaultMessage: 'dependency maps',
    id: 'dashboard.dependencyMaps',
  },
  exportSystemHealthScore: {
    defaultMessage: 'Export system health score?',
    id: 'dashboard.exportSystemHealthScore',
  },
  ticketNumber: {
    defaultMessage: 'Ticket number',
    id: 'dashboard.ticketNumber',
  },
  requestedBy: {
    defaultMessage: 'Requested by',
    id: 'dashboard.requestedBy',
  },
  assignedTo: {
    defaultMessage: 'Assigned to',
    id: 'dashboard.assignedTo',
  },
  originalNormalPattern: {
    defaultMessage: 'Original normal pattern',
    id: 'dashboard.originalNormalPattern',
  },
  manualIncidentCreation: {
    defaultMessage: 'Manual incident creation',
    id: 'dashboard.manualIncidentCreation',
  },
  systemNameAsc: {
    defaultMessage: 'System Name Ascending',
    id: 'dashboard.systemNameAsc',
  },
  systemNameDesc: {
    defaultMessage: 'System Name Descending',
    id: 'dashboard.systemNameDesc',
  },
  systemHealthScoreAsc: {
    defaultMessage: 'System Health Score Ascending',
    id: 'dashboard.systemHealthScoreAsc',
  },
  systemHealthScoreDesc: {
    defaultMessage: 'System Health Score Descending',
    id: 'dashboard.systemHealthScoreDesc',
  },
  moveToTop: {
    defaultMessage: 'Move to top',
    id: 'dashboard.moveToTop',
  },
  predictionStatistics: {
    defaultMessage: 'Prediction statistics',
    id: 'dashboard.predictionStatistics',
  },
  logCompressionRatio: {
    defaultMessage: 'Log compression ratio',
    id: 'dashboard.logCompressionRatio',
  },
  dataCompressionRatio: {
    defaultMessage: 'Overall compression ratio',
    id: 'dashboard.dataCompressionRatio',
  },
  anomalyCompressionRatio: {
    defaultMessage: 'Anomaly compression ratio',
    id: 'dashboard.anomalyCompressionRatio',
  },
  anomalousLogSize: {
    defaultMessage: 'Anomalous log size',
    id: 'dashboard.anomalousLogSize',
  },
  originalLogSize: {
    defaultMessage: 'Original log size',
    id: 'dashboard.originalLogSize',
  },
  compressedLogSize: {
    defaultMessage: 'Compressed log size',
    id: 'dashboard.compressedLogSize',
  },
  projectDataCompressionRatio: {
    defaultMessage: 'Project data compression ratio',
    id: 'dashboard.projectDataCompressionRatio',
  },
  autoFixedIncidents: {
    defaultMessage: 'Auto-fixed incidents',
    id: 'dashboard.autoFixedIncidents',
  },
  fixedDetection: {
    defaultMessage: 'Fixed detection',
    id: 'dashboard.fixedDetection',
  },
  fixedPrediction: {
    defaultMessage: 'Fixed prediction',
    id: 'dashboard.fixedPrediction',
  },
  truePositives: {
    defaultMessage: 'True positives',
    id: 'dashboard.truePositives',
  },
  falseNegativeRate: {
    defaultMessage: 'False negative rate',
    id: 'dashboard.falseNegativeRate',
  },
  falsePositives: {
    defaultMessage: 'False positives',
    id: 'dashboard.falsePositives',
  },
  falsePositiveRate: {
    defaultMessage: 'False positive rate',
    id: 'dashboard.falsePositiveRate',
  },
  predictionLeadTime: {
    defaultMessage: 'Preventive lead time',
    id: 'dashboard.predictionLeadTime',
  },
  predictionAccuracy: {
    defaultMessage: 'Prediction accuracy',
    id: 'dashboard.predictionAccuracy',
  },
  truePositiveRate: {
    defaultMessage: 'True positive rate',
    id: 'dashboard.truePositiveRate',
  },
  accuracy: {
    defaultMessage: 'Accuracy',
    id: 'dashboard.accuracy',
  },
  dashboardName: {
    defaultMessage: 'Dashboard name',
    id: 'dashboard.dashboardName',
  },
  myDashboards: {
    defaultMessage: 'My dashboards',
    id: 'dashboard.myDashboards',
  },
  sharedDashboards: {
    defaultMessage: 'Shared dashboards',
    id: 'dashboard.sharedDashboards',
  },
  health: {
    defaultMessage: 'Health',
    id: 'dashboard.health',
  },
  author: {
    defaultMessage: 'Author',
    id: 'dashboard.author',
  },
  popularity: {
    defaultMessage: 'Popularity',
    id: 'dashboard.popularity',
  },
});

export default DashboardMessages;
