import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import {
  EyeInvisibleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { Spin, Switch, message, Button, Checkbox } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { Defaults, EventRenderers, LogRenderers, buildUrl, sleep } from '../../../common/utils';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer, Popover } from '../../../lib/fui/react';
import { updateLastActionInfo, createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/dashboard/actions';
import {
  PinLegendIcon,
  RootCauseIcon,
  IncidentPredictIcon,
  ChangeEventIcon,
  FlagNewIcon,
} from '../../../lib/fui/icons';

import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';

import IncidentListModal from './IncidentListModal';
import EventShortListModal from './EventShortListModal';
import ProjectInsertIncidentModal from './ProjectInsertIncidentModal';

type Props = {
  style: Object,
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refreshTime: Number,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  zoomStartTime: Number,
  // eslint-disable-next-line
  zoomEndTime: Number,
  // eslint-disable-next-line
  selectAnomalyInstance: String,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoading: Boolean,
  // eslint-disable-next-line
  reloadSystem: Number,
  // eslint-disable-next-line
  onReloadSystemIncidentTimelines: Function,
  handleUpdateSystemRankMap: Function,
  handleUpdateSystemTimelines: Function,
  // eslint-disable-next-line
  updateState: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createLoadAction: Function,

  // eslint-disable-next-line
  globalSystemDetectIncidentTimelines: Array<Object>,

  forceRefreshTime: String,

  dataTotalCount: Array,
  getReloadDataTotalCount: Function,
  currentTheme: String,
};

class GlobalPanelIncidentsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {
      isLoading: false,
      isAutoReload: false,
      systemTrending: { current: 0, trending: 0 },
      // events: [],
      eventList: [],
      patternNameMap: {},
      activeEvent: null,

      ignoreFilter: true,

      showDetailsModal: false,
      showInsertIncidentModal: false,
      summarySettingsMap: {},
    };
    this.patternCountMap = {};
    this.patternCountProjectMap = {};
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.startTime !== this.props.startTime ||
      nextProps.endTime !== this.props.endTime ||
      nextProps.customerName !== this.props.customerName ||
      nextProps.environmentId !== this.props.environmentId ||
      nextProps.refreshTime !== this.props.refreshTime ||
      nextProps.refresh !== this.props.refresh ||
      nextProps.systemInfo !== this.props.systemInfo ||
      nextProps.reloadSystem !== this.props.reloadSystem
    ) {
      const isAutoReload = nextProps.refreshTime !== this.props.refreshTime;
      if (!isAutoReload || (isAutoReload && !nextProps.zoomStartTime && !nextProps.zoomEndTime)) {
        this.reloadData(nextProps, { isAutoReload });
      }
    } else if (nextProps.selectAnomalyInstance !== this.props.selectAnomalyInstance) {
      if (nextProps.systemInfo) {
        this.parseData(nextProps);
      }
    } else if (nextProps.forceRefreshTime !== this.props.forceRefreshTime) {
      const isAutoReload = nextProps.refreshTime !== this.props.refreshTime;
      if (!isAutoReload || (isAutoReload && !nextProps.zoomStartTime && !nextProps.zoomEndTime)) {
        this.reloadData(nextProps, { isAutoReload });
      }
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData(props, params) {
    const { environmentId, startTime, endTime, customerName, systemInfo } = props;
    const { createLoadAction, userInfo } = props;
    const { isAutoReload } = params || {};

    if (
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      startTime &&
      endTime &&
      systemInfo &&
      systemInfo.hasAllInstanceInfo
    ) {
      this.setState({ isLoading: !isAutoReload, isAutoReload, patternNameMap: {}, summarySettingsMap: {} });
      this.props.updateState({ isLoadingPanelAlert: !isAutoReload });
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
      createLoadAction(
        ActionTypes.LOAD_GLOBAL_SYSTEM_DETECT_INCIDENT_TIMELINES,
        {
          isAutoReload,
          level: 'system',
          environmentId,
          systemIds: [systemInfo.id],
          startTime: startTimestamp,
          endTime: endTimestamp,
        },
        false,
        false,
        this.callbackSetAnomalyTimelineState(),
      );
    }
  }

  @autobind
  callbackSetAnomalyTimelineState() {
    return () => {
      this.reloadDataTotalCount();
    };
  }

  // @autobind
  // reloadDataTotalCount() {
  //   const { intl, credentials } = this.props;
  //   const { environmentId, startTime, endTime, systemInfo } = this.props;
  //   const { isAutoReload, ignoreFilter } = this.state;

  //   this.setState({ isLoading: !isAutoReload });
  //   this.props.updateState({ isLoadingPanelIncident: !isAutoReload });
  //   const startTimestampPrev = moment.utc(startTime, Defaults.DateFormat).subtract(1, 'days').startOf('day').valueOf();
  //   const endTimestampPrev = moment.utc(startTime, Defaults.DateFormat).subtract(1, 'days').endOf('day').valueOf();
  //   const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
  //   const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
  //   Promise.all([
  //     fetchGet(getEndpoint('insightsIncidentPatternCount'), {
  //       ...credentials,
  //       environmentName: environmentId,
  //       customerName: systemInfo.ownerUserName,
  //       systemName: systemInfo.id,
  //       startTime: startTimestampPrev,
  //       endTime: endTimestampPrev,
  //       isIncludeIgnored: false,
  //     }),
  //     fetchGet(getEndpoint('insightsIncidentPatternCount'), {
  //       ...credentials,
  //       environmentName: environmentId,
  //       customerName: systemInfo.ownerUserName,
  //       systemName: systemInfo.id,
  //       startTime: startTimestamp,
  //       endTime: endTimestamp,
  //       isIncludeIgnored: !ignoreFilter,
  //     }),
  //   ])
  //     .then((results) => {
  //       const incidentPatternStatsMapPrev = get(results[0], 'incidentPatternStatsMap', []);
  //       let totalCountPrev = 0;
  //       R.forEach((item) => {
  //         const { patternCount } = item[0];
  //         totalCountPrev += patternCount;
  //       }, incidentPatternStatsMapPrev);

  //       const incidentPatternStatsMap = get(results[1], 'incidentPatternStatsMap', []);
  //       let totalCount = 0;
  //       const patternCountMap = {};
  //       const patternCountProjectMap = {};
  //       R.forEach((item) => {
  //         const { projectName, instanceName, patternId, patternCount } = item[0];
  //         const key = `${projectName}-${instanceName}-${patternId}`;
  //         if (!R.has(key, patternCountMap)) {
  //           patternCountMap[key] = 0;
  //         }
  //         patternCountMap[key] += patternCount;

  //         const keyProject = `${projectName}-${patternId}`;
  //         if (!R.has(keyProject, patternCountProjectMap)) {
  //           patternCountProjectMap[keyProject] = 0;
  //         }
  //         patternCountProjectMap[keyProject] += patternCount;

  //         totalCount += patternCount;
  //       }, incidentPatternStatsMap);
  //       const systemTrending = {
  //         current: totalCount || 0,
  //         trending: (totalCount || 0) - (totalCountPrev || 0),
  //       };

  //       this.patternCountMap = patternCountMap;
  //       this.patternCountProjectMap = patternCountProjectMap;
  //       this.setState({ systemTrending });
  //       this.parseData(this.props);
  //     })
  //     .catch((err) => {
  //       message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
  //       this.patternCountMap = {};
  //       this.patternCountProjectMap = {};
  //       this.setState({ isLoading: false, systemTrending: { current: 0, trending: 0 } });
  //       this.props.updateState({ isLoadingPanelIncident: false });
  //     });
  // }

  @autobind
  reloadDataTotalCount() {
    const { dataTotalCount } = this.props;
    const { isAutoReload } = this.state;

    this.setState({ isLoading: !isAutoReload });
    this.props.updateState({ isLoadingPanelIncident: !isAutoReload });
    if ((dataTotalCount || []).length > 0) {
      const incidentPatternStatsMapPrev = get(dataTotalCount[0], 'result', []);
      let totalCountPrev = 0;
      R.forEach((item) => {
        const { totalIncidents } = item;
        totalCountPrev += totalIncidents;
      }, incidentPatternStatsMapPrev);

      const incidentPatternStatsMap = get(dataTotalCount[1], 'result', []);
      let totalCount = 0;
      R.forEach((item) => {
        const { totalIncidents } = item;
        totalCount += totalIncidents;
      }, incidentPatternStatsMap);

      const systemTrending = {
        current: totalCount || 0,
        trending: (totalCount || 0) - (totalCountPrev || 0),
      };
      this.setState({ systemTrending });
      this.parseData(this.props);
    } else {
      this.setState({ isLoading: false, systemTrending: { current: 0, trending: 0 } });
      this.props.updateState({ isLoadingPanelIncident: false });
    }
  }

  @autobind
  async parseData(props) {
    const { selectAnomalyInstance, globalSystemDetectIncidentTimelines, systemInfo } = props;
    const { isAutoReload, sortBy, sortDirection, activeEvent } = this.state;

    this.setState({ isLoading: !isAutoReload, isAutoReload: false });
    this.props.updateState({ isLoadingPanelIncident: !isAutoReload });
    await sleep(300);

    // parse anomaly timelines
    const systemIncidentTimelines = (
      R.find((item) => item.id === systemInfo.id, globalSystemDetectIncidentTimelines) || {}
    ).anomalyTimelines;

    let events = R.filter(
      (event) => R.toLower(event.type) === 'incident' && event.timeLineType !== 'future',
      systemIncidentTimelines || [],
    );

    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];

      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          const instanceName = pair.instanceId || pair.instanceName;
          const componentName = get(instanceComponentMap, instanceName, instanceName);
          componentList.push(componentName);
          const appName =
            componentName && componentName.indexOf(instanceName) === -1
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        const { realInstanceName } = item;
        const componentName = get(instanceComponentMap, realInstanceName, item.componentName);
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== realInstanceName
            ? `${item.instanceName} (${componentName})`
            : item.instanceName;
        instanceAppNames.push(appName);
      }
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      const appName = R.join(', ', instanceAppNames);
      const componentNameString = R.join(', ', componentList);

      let typeList = [];
      if (item.category === 'metric' && item.type !== 'Incident') {
        typeList = ['metricanomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', item.type).split('&'));
      }

      // get status
      const { rootCauseResultInfo } = item;
      const hasRootCause = get(rootCauseResultInfo, 'hasPrecedingEvent');
      const hasTrailing = get(rootCauseResultInfo, 'hasTrailingEvent');
      const causedByChange = get(rootCauseResultInfo, 'causedByChangeEvent');

      return {
        ...item,

        rootCauseJson: { rootCauseDetailsArr },
        appName,
        instanceAppNames,
        componentNameString,
        componentList,
        typeList,
        hasRootCause,
        hasTrailing,
        causedByChange,
        eventsCount: R.sum(R.values(item.timestampMap || {})) || 1,
        lastSeenTime: item.endTimestamp,
      };
    }, events);

    // filter
    if (selectAnomalyInstance) {
      events = R.filter((anomaly) => {
        return anomaly.instanceList.includes(selectAnomalyInstance);
      }, events);
    }
    let eventList = this.filterData(events);
    // not merge
    eventList = R.map((item) => {
      return { ...item, timelines: [item] };
    }, eventList);
    // merge
    eventList = this.mergeAnomalyEvents(props, eventList);
    // add rank number
    eventList = R.sortWith(
      [
        R.descend(R.prop('isNewAlert')),
        R.descend(R.prop('causedByChange')),
        R.descend(R.prop('hasRootCause')),
        R.descend(R.prop('eventsCount')),
        R.ascend(R.prop('patternId')),
      ],
      eventList,
    );
    const rankMap = {};
    const labelKeyMap = {};
    let rank = 1;
    eventList = R.addIndex(R.map)((event, index) => {
      if (!R.has(event.labelKey, labelKeyMap)) {
        labelKeyMap[event.labelKey] = rank;
        rank += 1;
      }
      R.forEach((item) => {
        rankMap[item.id] = labelKeyMap[event.labelKey];
      }, event.timelines);
      return { ...event, rank: labelKeyMap[event.labelKey] };
    }, eventList);

    // default display alerts
    const incidents = [];
    eventList = R.addIndex(R.map)((event, index) => {
      const { timelines } = event;
      R.forEach((item) => {
        incidents.push(item);
      }, timelines || []);
      return { ...event, isDisplayInChart: true };
    }, eventList);
    this.props.handleUpdateSystemRankMap(systemInfo.id, { rankMap, incidents });

    // sort
    eventList = this.sortData(eventList, sortBy, sortDirection);

    // get new incident
    let newActiveIncident;
    if (activeEvent) {
      newActiveIncident = R.find((item) => item.id === activeEvent.id, eventList);
    }

    if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
    this.setState({
      isLoading: false,
      // events,
      eventList,
      activeEvent: newActiveIncident,
    });
    this.props.updateState({ isLoadingPanelIncident: false });
  }

  @autobind
  filterData(eventList) {
    const { ignoreFilter } = this.state;
    let filterList = eventList || [];

    if (ignoreFilter) {
      filterList = R.filter((event) => !event.isIgnored, filterList);
    }
    return filterList;
  }

  @autobind
  mergeAnomalyEvents(props, eventList) {
    let newEvents = [];

    const eventsMap = {};
    R.forEach((event) => {
      const { projectName, instanceNameString, patternId, isImportant, isIgnored, isNewAlert, rootCauseResultInfo } =
        event;
      const hasRootCause = get(rootCauseResultInfo, 'hasPrecedingEvent');
      const hasTrailing = get(rootCauseResultInfo, 'hasTrailingEvent');
      const causedByChange = get(rootCauseResultInfo, 'causedByChangeEvent');

      const key = `${projectName}-${instanceNameString}-${patternId}`;
      const newEvent = { ...event, key, labelKey: key, hasRootCause, hasTrailing, causedByChange };
      if (!R.has(key, eventsMap)) {
        eventsMap[key] = {
          ...newEvent,

          timelines: [newEvent],
        };
      } else {
        eventsMap[key] = {
          ...eventsMap[key],
          isImportant: eventsMap[key].isImportant || isImportant,
          isIgnored: eventsMap[key].isIgnored || isIgnored,
          isNewAlert: eventsMap[key].isNewAlert || isNewAlert,
          hasRootCause: eventsMap[key].hasRootCause || hasRootCause,
          hasTrailing: eventsMap[key].hasTrailing || hasTrailing,
          causedByChange: eventsMap[key].causedByChange || causedByChange,

          timelines: [...eventsMap[key].timelines, newEvent],
        };
      }
    }, eventList || []);
    newEvents = R.values(eventsMap);

    // get overall info
    newEvents = R.map((event) => {
      let lastSeenTime = 0;
      R.forEach((item) => {
        const { endTimestamp } = item;
        lastSeenTime = R.max(lastSeenTime, endTimestamp);
      }, event.timelines || []);

      // get totle count from api: insightsIncidentPatternCount
      // const eventsCount = get(this.patternCountMap, event.key);
      let eventsCount = 0;
      R.forEach((item) => {
        eventsCount += R.sum(R.values(item.timestampMap || {})) || 1;
      }, event.timelines);

      return { ...event, eventsCount, lastSeenTime };
    }, newEvents);

    return newEvents;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [
      R.descend(R.prop('isNewAlert')),
      R.descend(R.prop('causedByChange')),
      R.descend(R.prop('hasRootCause')),
      R.descend(R.prop('eventsCount')),
      R.ascend(R.prop('patternId')),
    ];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  onChangeFilterIgnore(ignoreFilter) {
    this.setState({ ignoreFilter }, async () => {
      await this.props.getReloadDataTotalCount();
      await this.reloadData(this.props);
    });
  }

  @autobind
  handleIncidentDisplayClick(event, rowIndex, item) {
    const { systemInfo } = this.props;
    const { eventList } = this.state;
    const { labelKey, timelines } = item;

    const isDisplayInChart = event.target.checked;
    eventList[rowIndex].isDisplayInChart = isDisplayInChart;
    this.setState({ eventList }, () => {
      this.props.handleUpdateSystemTimelines(systemInfo.id, {
        hideIncidentsLabelKey: !isDisplayInChart ? labelKey : undefined,
        addIncidents: isDisplayInChart ? timelines : undefined,
      });
    });
  }

  @autobind
  handleIncidentDisplayAllClick(event) {
    const { systemInfo } = this.props;
    const { eventList } = this.state;

    const isDisplayInChart = event.target.checked;
    this.setState({ eventList: R.map((event) => ({ ...event, isDisplayInChart }), eventList) }, () => {
      let allIncidents = [];
      if (isDisplayInChart) {
        R.forEach((event) => {
          allIncidents = [...allIncidents, ...event.timelines];
        }, eventList);
      }
      this.props.handleUpdateSystemTimelines(systemInfo.id, { allIncidents });
    });
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }, { absoluteWidth }) {
    const { intl } = this.props;
    const { eventList } = this.state;
    const item = eventList[rowIndex];
    if (!item) return null;

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div
          className={`event-list-row clickable ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          style={{ ...style }}
          onClick={() => this.handleDetailsClick(item)}
        >
          <div className="row-column flex-row flex-center-justify" style={{ minWidth: 50, width: 50 }}>
            <PinLegendIcon style={{ color: '#FF5142', fontSize: 20 }} />
            <span className="text-center bold" style={{ width: 20, marginLeft: -20, marginTop: -2, color: 'white' }}>
              {item.rank}
            </span>
          </div>
          <div className="row-column" style={{ minWidth: 80, flex: 1 }}>
            <Popover
              title={null}
              content={
                <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 180 }}>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.projectName)}:
                    </div>
                    <div className="flex-grow">{item.projectDisplayName}</div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.componentName)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {item.componentNameString}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.instanceName)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {item.instanceNameString}
                    </div>
                  </div>
                  {item.containerName && (
                    <div className="flex-row">
                      <div style={{ fontWeight: 'bold', width: 120 }}>
                        {intl.formatMessage(appFieldsMessages.container)}:
                      </div>
                      <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                        {item.containerName}
                      </div>
                    </div>
                  )}
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.lastSeenTime)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {moment.utc(item.lastSeenTime).format(Defaults.ShortTimeFormat)}
                    </div>
                  </div>
                </div>
              }
              placement="right"
              mouseEnterDelay={0.3}
            >
              <div className="max-width hidden-line-with-ellipsis">{item.instanceNameString}</div>
            </Popover>
          </div>
          <div className="row-column" style={{ minWidth: 60, flex: 1 }}>
            {this.renderPattern(item)}
          </div>
          <div className="row-column" style={{ width: 60 }}>
            {item.eventsCount}
          </div>
          {absoluteWidth >= 400 && (
            <div className="row-column" style={{ minWidth: 84, flex: 1 }}>
              {moment.utc(item.lastSeenTime).format(Defaults.ShortTimeFormat)}
            </div>
          )}
          <div className="row-column" style={{ minWidth: 70 }}>
            {this.statusRender(item)}
          </div>
          <div className="row-column" style={{ minWidth: 30, width: 30 }}>
            <Popover
              placement="right"
              content={
                item.isDisplayInChart
                  ? `Click to hide this incident in health chart.`
                  : `Click to display this incident in health chart.`
              }
              mouseEnterDelay={0.3}
            >
              <Checkbox
                size="small"
                checked={item.isDisplayInChart}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => this.handleIncidentDisplayClick(event, rowIndex, item)}
              />
            </Popover>
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { intl, credentials, systemInfo } = this.props;
    const ownerUserName = get(systemInfo, 'ownerUserName');

    const { instanceDown, projectDisplayName, componentNameString, instanceNameString, containerName, lastSeenTime } =
      rowData;
    const { category, patternId, anomalyLogInstance, projectOwner, startTimestamp } = rowData;
    let { projectName } = rowData;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;

    const RendererPatternTitle = ({ rowData }) => {
      const { patternNameMap, summarySettingsMap } = this.state;

      const [patternName, setPatternName] = useState(get(patternNameMap, [key, patternId]));
      const [loading, setLoading] = useState(false);
      useEffect(() => {
        if (patternName === undefined) {
          setLoading(true);
          this.props.updateLastActionInfo();
          if (!R.includes(category, ['metric'])) {
            fetchGet(getEndpoint('logsummarysettings'), {
              ...credentials,
              projectName,
            })
              .then((summarySettings) => {
                if (!R.has(projectName, summarySettingsMap)) {
                  summarySettingsMap[projectName] = summarySettings;
                  this.setState({ summarySettingsMap });
                  this.forceUpdate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          fetchGet(getEndpoint('getpatternname', 1), {
            ...credentials,
            customerName: ownerUserName,
            projectName,
            instanceName: category === 'log' ? anomalyLogInstance : null,
            patternId,
            startTime: startTimestamp,
          })
            .then((data) => {
              const { patternName } = data;
              if (!patternNameMap[key]) patternNameMap[key] = {};
              patternNameMap[key][patternId] = patternName || '';

              this.setState({ patternNameMap });
              setLoading(false);
              setPatternName(patternName || '');
              this.forceUpdate();
            })
            .catch((err) => {
              message.error(intl.formatMessage(appMessages.apiFaild));
              console.log(err);
            });
        }
      }, []);
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName, patternId },
        { hasFullName: true, hasPrefix: true },
      );
      return (
        <div>
          {loading && <Spin size="small" />}
          {!loading && (
            <div className="flex-col overflow-y-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
              <div className="flex-row font-14">
                <div style={{ fontWeight: 'bold', width: 120 }}>
                  {intl.formatMessage(appFieldsMessages.patternIdName)}:
                </div>
                <div className="flex-grow">{patternNameStr}</div>
              </div>
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.projectName)}:</div>
                <div className="flex-grow">{projectDisplayName}</div>
              </div>
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.componentName)}:</div>
                <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                  {componentNameString}
                </div>
              </div>
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.instanceName)}:</div>
                <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                  {instanceNameString}
                </div>
              </div>
              {containerName && (
                <div className="flex-row">
                  <div style={{ fontWeight: 'bold', width: 120 }}>
                    {intl.formatMessage(appFieldsMessages.container)}:
                  </div>
                  <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                    {containerName}
                  </div>
                </div>
              )}
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.lastSeenTime)}:</div>
                <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                  {moment.utc(lastSeenTime).format(Defaults.ShortTimeFormat)}
                </div>
              </div>
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', minWidth: 120 }}>
                  {intl.formatMessage(eventMessages.shortDescription)}:
                </div>
                <div className="flex-grow">{this.renderDetails(rowData, projectName)}</div>
              </div>
            </div>
          )}
        </div>
      );
    };

    return (
      <Popover
        title={null}
        content={<RendererPatternTitle rowData={rowData} />}
        mouseEnterDelay={0.3}
        placement="right"
      >
        <div className="hidden-line-with-ellipsis max-width">
          {instanceDown ? intl.formatMessage(appFieldsMessages.missingData) : patternId}
        </div>
      </Popover>
    );
  }

  @autobind
  statusRender(rowData) {
    const { intl } = this.props;
    const { hasTrailing, causedByChange, hasRootCause, isIgnored, isNewAlert } = rowData;

    return (
      <div className="flex-row">
        {false && hasTrailing && (
          <Popover
            content={
              <div>
                <div>{intl.formatMessage(DashboardMessages.incidentHasTrailing)}</div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="topRight"
          >
            <IncidentPredictIcon
              style={{ color: '#FF5142', fontSize: 14, marginLeft: 2 }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAJumpClick(rowData);
              }}
            />
          </Popover>
        )}
        {causedByChange && (
          <Popover
            content={
              <div>
                <div>{intl.formatMessage(DashboardMessages.causedByChangeIncident)}</div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="topRight"
          >
            <ChangeEventIcon
              style={{ color: 'orange', fontSize: 14, marginLeft: 2 }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAJumpClick(rowData);
              }}
            />
          </Popover>
        )}
        {hasRootCause && (
          <Popover
            content={
              <div>
                <div>{intl.formatMessage(DashboardMessages.incidentHasRootCause)}</div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="topRight"
          >
            <RootCauseIcon
              style={{ color: '#FF5142', fontSize: 14, marginLeft: 2 }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAJumpClick(rowData);
              }}
            />
          </Popover>
        )}
        {isIgnored && (
          <Popover content={intl.formatMessage(eventMessages.ignored)} mouseEnterDelay={0.3} placement="topRight">
            <EyeInvisibleOutlined style={{ fontSize: 14, marginLeft: 2, color: 'red' }} />
          </Popover>
        )}
        {isNewAlert && (
          <Popover placement="topRight" mouseEnterDelay={0.3} content={intl.formatMessage(eventMessages.newIncident)}>
            <FlagNewIcon style={{ color: 'red', fontSize: 14, marginLeft: 2 }} />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  handleRCAJumpClick(event) {
    const { systemInfo, environmentId, customerName } = this.props;
    const { day, startTimestamp, projectName, patternId, instanceList, metricList } = event || {};
    const startTime = day || moment.utc(startTimestamp).format(Defaults.DateFormat);

    const query = {
      environmentId,
      customerName,
      systemId: systemInfo.id,
      startTime,
      endTime: startTime,

      eventCategory: 'incident',
      eventPatternType: 'incident',
      eventProjectName: projectName,
      eventPatternId: patternId,
      eventInstanceName: instanceList[0],
      eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
      eventTimestamp: startTimestamp,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  }

  @autobind
  renderDetails(rowData, projectName) {
    const { intl, currentTheme } = this.props;
    const { rawData, rootCauseJson } = rowData;
    const { summarySettingsMap } = this.state;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error);
    }
    const rootCauseDetailsArr = get(rootCauseJson, ['rootCauseDetailsArr'], []);

    return (
      <div>
        {rawData && (
          <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
            {!rawDataJson &&
              R.join(
                '\n',
                R.filter((x) => Boolean(x), (rawData || '').split('\n')),
              )}
            {/* {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} />} */}
            {rawDataJson && (
              <div className="flex-grow flex-min-width">
                <LogRenderers.RenderLogContent
                  intl={intl}
                  rawData={rawData}
                  rawDataJson={rawDataJson}
                  owner={rowData}
                  summarySettings={summarySettingsMap[projectName] || []}
                  currentTheme={currentTheme}
                />
              </div>
            )}
          </div>
        )}
        {rootCauseDetailsArr.length > 0 && (
          <div style={{ wordBreak: 'break-all' }}>
            {R.addIndex(R.map)(
              (event, index) => EventRenderers.RenderMetricAnomalySummary({ intl, event, index }),
              rootCauseDetailsArr || [],
            )}
          </div>
        )}
      </div>
    );
  }

  @autobind
  handleDetailsClick(rowData) {
    // use incident list to dsiplay
    this.setState({ showDetailsModal: true, activeEvent: rowData });

    // use event short list to display
    // const { systemHealth } = this.props;
    // const { timelines } = rowData;
    // const incident = R.find((item) => item.hasRootCause, timelines) || timelines[0];
    // const { startTimestamp, endTimestamp } = incident || {};
    // const intervalInMinutes = get(systemHealth, 'intervalInMinutes', 1);
    // this.setState({
    //   showSystemEventModel: true,
    //   queryStartTime: Number(startTimestamp) - (intervalInMinutes + 10) * 60 * 1000,
    //   queryEndTime: Number(endTimestamp) + (intervalInMinutes + 10) * 60 * 1000,
    //   timelines: [incident],
    // });
  }

  @autobind
  handleDetailsModalClose(reload, notCloseModal) {
    this.setState({ showDetailsModal: Boolean(notCloseModal) }, () => {
      if (reload) {
        this.reloadData(this.props);
      }
    });
  }

  @autobind
  handleSystemInsertIncident() {
    this.setState({ showInsertIncidentModal: true });
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection, eventList } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const newEventList = this.sortData(eventList, name, sortDir);
          this.setState({ eventList: newEventList });
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const { intl, style } = this.props;
    const { isLoading, systemInfo, systemHealth, customerName, environmentId, startTime, endTime } = this.props;
    const { sortBy, sortDirection } = this.state;
    const { isLoading: isLoadingPatternCount, systemTrending, eventList, activeEvent, ignoreFilter } = this.state;
    const trendingHouts =
      ((moment.utc(endTime, Defaults.DateFormat).valueOf() - moment.utc(startTime, Defaults.DateFormat).valueOf()) /
        86400000 +
        1) *
      24;
    const isDisplayInChartAll = !R.find((item) => !item.isDisplayInChart, eventList);

    return (
      <div className="flex-grow flex-col" style={{ ...style, minWidth: 150, padding: 8 }}>
        <Popover
          content="The increase or decrease in the number of detected incidents compared to the previous day."
          mouseEnterDelay={0.3}
        >
          <div className="flex-row flex-center-align flex-center-justify font-16">
            {systemTrending.trending >= 0 ? (
              <ArrowUpOutlined style={{ color: '#FF5143' }} />
            ) : (
              <ArrowDownOutlined style={{ color: '#32C880' }} />
            )}
            <span className="light-label" style={{ marginRight: 8 }}>{`Trending(${trendingHouts}h)`}</span>
            <span className="bold">{` ${numeral(systemTrending.current).format('0,0')}(${numeral(
              systemTrending.trending,
            ).format('+0,0')})`}</span>
          </div>
        </Popover>
        <div className="flex-row flex-center-align font-14" style={{ height: 24, marginBottom: 19 }}>
          <div className="flex-grow hidden-line-with-ellipsis">
            <PinLegendIcon style={{ color: '#FF5142', marginRight: 4 }} />
            <span style={{ marginRight: 8 }}>{intl.formatMessage(DashboardMessages.detectedIncidents)}</span>
          </div>

          <Button type="link" style={{ padding: 0, marginLeft: 8 }} onClick={this.handleSystemInsertIncident}>
            {intl.formatMessage(DashboardMessages.manualIncidentCreation)}
          </Button>
          <Popover placement="top" content={intl.formatMessage(logMessages.hideIgnoredEvents)} mouseEnterDelay={0.3}>
            <Switch
              size="small"
              style={{ marginLeft: 8 }}
              checked={ignoreFilter}
              onChange={this.onChangeFilterIgnore}
            />
          </Popover>
        </div>
        <div className="flex-grow">
          <Spin
            wrapperClassName="full-width full-height spin-full-height"
            spinning={isLoading || isLoadingPatternCount}
          >
            <AutoSizer>
              {({ width, height }) => (
                <div className="event-list">
                  <div
                    className="event-list-header"
                    style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                  >
                    <div
                      className="header-column"
                      style={{ minWidth: 50, width: 50 }}
                      onClick={this.headerClick('rank')}
                    >
                      <span>{intl.formatMessage(appButtonsMessages.label)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'rank')}
                    </div>
                    <div
                      className="header-column"
                      style={{ minWidth: 80, flex: 1 }}
                      onClick={this.headerClick('instanceNameString')}
                    >
                      <span>{intl.formatMessage(appFieldsMessages.instance)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'instanceNameString')}
                    </div>
                    <div
                      className="header-column"
                      style={{ minWidth: 60, flex: 1 }}
                      onClick={this.headerClick('patternId')}
                    >
                      <span>{intl.formatMessage(appFieldsMessages.pattern)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'patternId')}
                    </div>
                    <div className="header-column" style={{ width: 60 }} onClick={this.headerClick('eventsCount')}>
                      <span>{intl.formatMessage(appFieldsMessages.count)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'eventsCount')}
                    </div>
                    {width >= 400 && (
                      <div
                        className="header-column"
                        style={{ minWidth: 84, flex: 1 }}
                        onClick={this.headerClick('lastSeenTime')}
                      >
                        <span>{intl.formatMessage(eventMessages.lastSeenTime)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'lastSeenTime')}
                      </div>
                    )}
                    <div className="header-column" style={{ minWidth: 70 }} />
                    <div
                      className="header-column"
                      style={{ width: 30, minWidth: 30 }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox
                        size="small"
                        checked={isDisplayInChartAll}
                        onChange={this.handleIncidentDisplayAllClick}
                      />
                    </div>
                  </div>
                  <List
                    className="event-list-grid"
                    ref={(listNode) => {
                      this.listNode = listNode;
                    }}
                    width={width}
                    height={height - 30}
                    rowCount={eventList.length}
                    overscanRowCount={4}
                    deferredMeasurementCache={this.cellMeasureCache}
                    rowHeight={this.cellMeasureCache.rowHeight}
                    rowRenderer={(props) => this.renderListItem(props, { absoluteWidth: width })}
                    onScrollbarPresenceChange={({ horizontal, vertical }) => {
                      if (vertical) {
                        this.listNodeHeaderScrollbar = true;
                      } else {
                        this.listNodeHeaderScrollbar = false;
                      }
                      if (this.listNode) this.listNode.forceUpdateGrid();
                    }}
                  />
                </div>
              )}
            </AutoSizer>
          </Spin>
        </div>

        {this.state.showDetailsModal && (
          <IncidentListModal
            title={intl.formatMessage(DashboardMessages.detectedIncidents)}
            tabName="detectedIncidents"
            systemInfo={systemInfo}
            events={get(activeEvent, 'timelines', [])}
            activeEvent={activeEvent}
            // events={events}
            isLoading={isLoading || isLoadingPatternCount}
            onClose={this.handleDetailsModalClose}
          />
        )}
        {this.state.showSystemEventModel && (
          <EventShortListModal
            customerName={customerName}
            environmentId={environmentId}
            systemId={get(systemHealth, 'systemId')}
            startTime={startTime}
            endTime={endTime}
            intervalInMinutes={get(systemHealth, 'intervalInMinutes', 1)}
            queryStartTime={this.state.queryStartTime}
            queryEndTime={this.state.queryEndTime}
            seriesId="incidentLine"
            timelines={this.state.timelines}
            onClose={(systemIds) => {
              this.setState({ showSystemEventModel: false });
              if (systemIds) this.props.onReloadSystemIncidentTimelines(systemIds);
            }}
          />
        )}
        {this.state.showInsertIncidentModal && (
          <ProjectInsertIncidentModal
            projectNameSet={get(systemInfo, 'projectNameSet', [])}
            onClose={() => this.setState({ showInsertIncidentModal: false })}
          />
        )}
      </div>
    );
  }
}

const GlobalPanelIncidents = injectIntl(GlobalPanelIncidentsCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    const { globalSystemDetectIncidentTimelines } = state.dashboard;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,

      globalSystemDetectIncidentTimelines,
      currentTheme,
    };
  },
  { updateLastActionInfo, createLoadAction },
)(GlobalPanelIncidents);
