/* @flow */
import React from 'react';
import { Route as ReactRouterRoute, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import type { State } from '../../types';

type Props = {
  loggedIn: boolean,
  appInited: boolean,
  isFirstLogin: boolean,
  loginUrl: string,
  component: Function,
  render: Function,
  children: Function,
};

export const PrivateRouteCore = ({
  loggedIn = false,
  appInited = false,
  loginUrl = '/auth/login2',
  render,
  children,
  isFirstLogin,
  component: Component,
  ...props
}: Props) => (
  <ReactRouterRoute
    {...props}
    render={(renderProps) => {
      if (loggedIn) {
        // If application is not inited, return null to not render private component.
        if (!appInited) {
          return null;
        }

        if (Component) {
          return <Component {...renderProps} isFirstLogin={isFirstLogin} />;
        } else if (render) {
          return render(renderProps);
        }
        return children(renderProps);
      }

      const urlRex = 'https://sandbox.insightfinder.com';
      const url = window.BASE_URL || window.location.origin || '';
      const { pathname, search } = renderProps.location;
      const redirectUrl = pathname.startsWith('/auth/login2') ? null : `${pathname}${search}`;
      const isVirtualLoginPage = pathname.startsWith('/auth/virtual_login');
      const redirectTo = {
        pathname: url === urlRex ? (isVirtualLoginPage ? '/auth/virtual_login' : '/auth/sandbox-signup') : loginUrl,
        state: { from: renderProps.location },
      };
      if (redirectUrl) {
        redirectTo.search = `?redirectUrl=${encodeURIComponent(redirectUrl)}`;
      }

      return <Redirect to={redirectTo} />;
    }}
  />
);

export default connect((state: State) => ({
  appInited: state.app.inited,
  loggedIn: state.auth.loggedIn,
  isFirstLogin: !!state.auth?.userInfo?.isFirstLogin,
}))(PrivateRouteCore);
