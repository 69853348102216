/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isArray, isEmpty } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';

import { Container, AutoSizer, Tooltip } from '../../../lib/fui/react';
import { loadCausalIncident, resetCausalIncidentData } from '../../../common/causal/actions';
import { ifIn, CausalParser, CausalRenderers } from '../../../common/utils';
import { appMenusMessages, appButtonsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

type Props = {
  view: String,
  projectName: String,
  instanceName: String,
  hasLogProject: Boolean,
  hasMetricProject: Boolean,
  relationTimeThreshold: String,
  relationProbability: String,
  relationCount: Number,
  incidentParams: Object,
  causalIncidentInfo: Object,
  onInstanceChange: Function,

  intl: Object,
  match: Object,
  location: Object,
  push: Function,
  replace: Function,
  loadCausalIncident: Function,
  resetCausalIncidentData: Function,
  currentLoadingComponents: Object,

  causalIncidentProperty: Object,
  causalIncident: Object,
  causalInstanceIncident: Object,
};

class CausalAnomalyInfoCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.incidentLoader = 'causal_incident_relation_loader';

    this.instanceMapping = {};
    this.intraInstanceList = [];

    this.state = {
      relationList: [],
      hasRelation: false,
    };
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextIncidentParams = nextProps.incidentParams || {};
    const incidentParams = this.props.incidentParams || {};
    if (
      nextIncidentParams.causalKey !== incidentParams.causalKey ||
      nextIncidentParams.causalName !== incidentParams.causalName ||
      nextIncidentParams.customerName !== incidentParams.customerName ||
      nextIncidentParams.startTimestamp !== incidentParams.startTimestamp ||
      nextIncidentParams.endTimestamp !== incidentParams.endTimestamp ||
      nextIncidentParams.fileName !== incidentParams.fileName ||
      nextIncidentParams.joinDependency !== incidentParams.joinDependency ||
      nextProps.causalIncidentInfo !== this.props.causalIncidentInfo ||
      nextProps.relationTimeThreshold !== this.props.relationTimeThreshold ||
      nextProps.instanceName !== this.props.instanceName ||
      nextProps.view !== this.props.view
    ) {
      this.reloadData(nextProps);
    } else if (
      nextProps.causalIncident !== this.props.causalIncident ||
      nextProps.causalInstanceIncident !== this.props.causalInstanceIncident
    ) {
      this.renderChart(nextProps);
    } else if (
      nextProps.relationCount !== this.props.relationCount ||
      nextProps.relationProbability !== this.props.relationProbability
    ) {
      this.renderChart(nextProps);
    }
  }

  @autobind
  reloadData(props, force = false) {
    const { causalIncidentInfo } = props;
    const { view, loadCausalIncident, incidentParams, instanceName, relationTimeThreshold } = props;
    const { causalKey, relationKey, customerName, causalName, startTimestamp, endTimestamp, fileName, joinDependency } =
      incidentParams;

    let postFixStr = 'inter';
    if (instanceName) {
      postFixStr = `intra_${instanceName}`;
    }
    let causalType = 'causal';
    let postFix = `_${postFixStr}_${causalType}_${relationTimeThreshold || '2160.0'}`;
    switch (view) {
      case 'correlation':
        causalType = 'correlation';
        postFix = `_${postFixStr}_${causalType}`;
        break;
      default:
        break;
    }
    if (
      causalIncidentInfo &&
      relationTimeThreshold &&
      causalKey &&
      causalName &&
      fileName &&
      customerName &&
      relationKey
    ) {
      const timeThreshold = Number(relationTimeThreshold) * 60 * 1000;
      loadCausalIncident(
        {
          causalType,
          causalKey,
          relationKey,
          customerName,
          causalName,
          instanceName,
          startTimestamp,
          endTimestamp,
          fileName,
          postFix,
          joinDependency,

          // filter params
          timeThreshold,
        },
        force,
        { [this.incidentLoader]: true },
      );
    }
  }

  @autobind
  getRelationData(props) {
    const { causalIncidentProperty, causalIncident, causalInstanceIncident } = props;
    const { instanceName, relationCount, relationProbability } = props;

    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;
    let relationList = get(incident, ['relation'], []);
    const hasRelation = !isEmpty(relationList);

    // set instance map and intra instance list
    const { instancePropertyMap } = causalIncidentProperty || {};
    const instanceMapping = {};
    const intraInstanceList = [];
    R.forEachObjIndexed((value, instance) => {
      const appName = get(value, ['projectInstanceMetadata', 'componentName']);
      if (appName) {
        instanceMapping[instance] = appName;
      }
      if (value.possibleIntraCausal) {
        intraInstanceList.push(instance);
      }
    }, instancePropertyMap);

    // Get the node pattern map for instance relations.
    const nodePatternMap = {};
    if (hasInstance) {
      const patternSetting = (relation, type, contentPath) => {
        if (!nodePatternMap[relation[type]]) {
          const content = get(relation, contentPath, []);
          if (content && content.length > 0) {
            if (content[0].patternName && content[0].nid) {
              nodePatternMap[relation[type]] = `${content[0].patternName}(${content[0].nid})`;
            }
          }
        }
      };
      R.forEach((relation) => {
        patternSetting(relation, 'elem1', 'fromContents');
        patternSetting(relation, 'elem2', 'toContents');
      }, relationList);
    }

    // get app name for inter relationList
    relationList = R.map((relation) => {
      const { elem1, elem2 } = relation;
      let appName1 = elem1;
      let appName2 = elem2;
      if (!hasInstance) {
        appName1 =
          instanceMapping[elem1] && instanceMapping[elem1] !== appName1
            ? `${appName1}(${instanceMapping[elem1]})`
            : appName1;
        appName2 =
          instanceMapping[elem2] && instanceMapping[elem2] !== appName2
            ? `${appName2}(${instanceMapping[elem2]})`
            : appName2;
      } else {
        appName1 = nodePatternMap[elem1] || elem1;
        appName2 = nodePatternMap[elem2] || elem2;
      }
      return { ...relation, appName1, appName2 };
    }, relationList);

    if (relationCount) {
      relationList = R.filter((relation) => relation.count >= Number(relationCount), relationList);
    }
    if (relationProbability) {
      relationList = R.filter((relation) => relation.probability >= parseFloat(relationProbability), relationList);
    }

    this.instanceMapping = instanceMapping;
    this.intraInstanceList = intraInstanceList;
    return { hasRelation, relationList };
  }

  renderChart(props) {
    // get data
    const { hasRelation, relationList } = this.getRelationData(props);
    this.setState({ hasRelation, relationList });
  }

  @autobind
  handleInstanceClick(instanceName) {
    return (event) => {
      event.stopPropagation();
      this.props.onInstanceChange(instanceName);
    };
  }

  @autobind
  handleClearInstance() {
    this.props.onInstanceChange(null);
  }

  render() {
    const { intl, view, relationTimeThreshold, currentLoadingComponents, instanceName } = this.props;
    const { hasRelation, relationList } = this.state;
    const instanceMapping = this.instanceMapping;
    const intraInstanceList = this.intraInstanceList;
    const hasInstance = Boolean(instanceName);
    const appName = this.instanceMapping[instanceName] || instanceName;
    const isIncidentLoading = get(currentLoadingComponents, this.incidentLoader, false);
    const getGraphInfo = (reverse) => {
      if (reverse) {
        return view === 'relation' ? 'Concurrent Anomalies' : 'Casual Anomalies';
      }
      return view === 'relation' ? 'Casual Anomalies' : 'Concurrent Anomalies';
    };
    return (
      <Container className={`flex-grow flex-col ${isIncidentLoading ? 'loading' : ''}`}>
        {!hasRelation && (
          <Container className="chart message flex-grow">
            {!hasInstance && (
              <div className="ui mini warning message" style={{ wordBreak: 'break-all' }}>
                No {getGraphInfo()} found for this time period, view <b>{getGraphInfo(true)}</b>. Or view the relation
                for instance:
                {intraInstanceList.length > 0 &&
                  R.map(
                    (instanceName) => (
                      <span className="link" key={instanceName} onClick={this.handleInstanceClick(instanceName)}>
                        {instanceMapping[instanceName] && instanceMapping[instanceName] !== instanceName
                          ? `${instanceMapping[instanceName]}(${instanceName})`
                          : instanceName}
                      </span>
                    ),
                    intraInstanceList,
                  )}
              </div>
            )}
            {hasInstance && (
              <div className="ui mini warning message" style={{ wordBreak: 'break-all' }}>
                No {getGraphInfo()} found for&nbsp;
                <span className="instance">{appName}</span>
                ,&nbsp;
                <span className="link" onClick={this.handleClearInstance}>
                  {intl.formatMessage(causalMessages.backToInstances)}
                </span>
              </div>
            )}
          </Container>
        )}
        {hasRelation && !hasInstance && intraInstanceList.length > 0 && (
          <Container
            className="chart"
            style={{
              width: '100%',
              border: 'none',
              overflowX: 'auto',
            }}
          >
            <div className="ui mini warning message" style={{ wordBreak: 'break-all' }}>
              {intl.formatMessage(causalMessages.viewRelationForInstance)}:
              {intraInstanceList.length > 0 &&
                R.map(
                  (instanceName) => (
                    <span className="link" key={instanceName} onClick={this.handleInstanceClick(instanceName)}>
                      {instanceMapping[instanceName] && instanceMapping[instanceName] !== instanceName
                        ? `${instanceMapping[instanceName]}(${instanceName})`
                        : instanceName}
                    </span>
                  ),
                  intraInstanceList,
                )}
            </div>
          </Container>
        )}
        {hasRelation && (
          <Container className={`flex-col flex-grow`} style={{}}>
            {hasInstance && (
              <div className="toolbar" style={{ height: 30 }}>
                <div className="title">
                  <h4>
                    <Tooltip
                      title={intl.formatMessage(causalMessages.backToInstances)}
                      placement="top"
                      style={{ display: 'inline-block' }}
                    >
                      <i className="arrow left icon" style={{ cursor: 'pointer' }} onClick={this.handleClearInstance} />
                    </Tooltip>
                    {intl.formatMessage(causalMessages.eventsRelationsForInstance)}:
                    <span className="instance">
                      <i className="circle icon" />
                      {appName}
                    </span>
                  </h4>
                </div>
              </div>
            )}
            <div className="flex-grow">
              <AutoSizer>
                {({ width, height }) => (
                  <div style={{ width, height, overflowY: 'auto' }}>
                    {R.addIndex(R.map)((relationInfo, index) => {
                      if (view === 'correlation') {
                        return CausalRenderers.RenderConcurrentInfo({
                          intl,
                          relationInfo,
                          index,
                          intraInstanceList: this.intraInstanceList,
                          handleInstanceClick: this.handleInstanceClick,
                        });
                      }
                      return CausalRenderers.RenderRelationInfo({
                        intl,
                        relationInfo,
                        index,
                        relationTimeThreshold,
                        intraInstanceList: this.intraInstanceList,
                        handleInstanceClick: this.handleInstanceClick,
                      });
                    }, R.sortWith([R.descend(R.prop('count')), R.descend(R.prop('probability'))], relationList))}
                  </div>
                )}
              </AutoSizer>
            </div>
          </Container>
        )}
      </Container>
    );
  }
}

const CausalAnomalyInfo = injectIntl(CausalAnomalyInfoCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { userName } = state.auth.userInfo;
    const { currentLoadingComponents, projects } = state.app;
    const { incidentData, instanceIncidentData, incidentCausalProperty } = state.causal;
    return {
      location,
      userName,
      currentLoadingComponents,
      projects,
      causalIncident: get(incidentData, 'data', null),
      causalInstanceIncident: get(instanceIncidentData, 'data', null),
      causalIncidentProperty: incidentCausalProperty || {},
    };
  },
  {
    push,
    replace,
    loadCausalIncident,
    resetCausalIncidentData,
  },
)(CausalAnomalyInfo);
