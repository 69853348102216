/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2022
 * *****************************************************************************
 * */

// import * as R from 'ramda';
import { isBoolean } from 'lodash';

import getEndpoint from '../getEndpoint';
import fetchPost from '../fetchPost';

const apiCallAfterCreateProject = ({
  credentials,
  projectName,
  systemKey,
  systemDisplayName,
  labelsWhitelist,
  labelsIncident,
  projectOwner,
}) => {
  const requests = [
    fetchPost(getEndpoint('systemframework', 2), {
      ...credentials,
      operation: 'addNewProject',
      projectName,
      customerName: projectOwner || credentials.userName,
      systemKey,
      systemDisplayName,
    }),
  ];

  // need call keywords set api one by one
  if (labelsWhitelist && labelsWhitelist.length > 0) {
    requests.push(
      fetchPost(getEndpoint('projectkeywords'), {
        ...credentials,
        projectName,
        type: 'whitelist',
        keywords: JSON.stringify(labelsWhitelist),
      }).then((d) => {
        if (isBoolean(d.success) && d.success === false) {
          return d;
        }

        if (labelsIncident && labelsIncident.length > 0) {
          return fetchPost(getEndpoint('projectkeywords'), {
            ...credentials,
            projectName,
            type: 'incidentlist',
            keywords: JSON.stringify(labelsIncident),
          });
        }

        return d;
      }),
    );
  } else if (labelsIncident && labelsIncident.length > 0) {
    requests.push(
      fetchPost(getEndpoint('projectkeywords'), {
        ...credentials,
        projectName,
        type: 'incidentlist',
        keywords: JSON.stringify(labelsIncident),
      }),
    );
  }

  return Promise.all(requests).then((results) => {
    const data1 = results[0] || {};
    return data1;
  });
};

export default apiCallAfterCreateProject;
