/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { DeleteOutlined } from '@ant-design/icons';
import { message, Select, Popconfirm, Tooltip, Button } from 'antd';

import { State } from '../../../common/types';
import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, getUIMenus } from '../../../common/utils';
import { hideAppLoader, updateLastActionInfo } from '../../../common/app/actions';
import { loginSuccess } from '../../../common/auth/actions';
import { Container, AutoSizer, Table, Column } from '../../../lib/fui/react';
import { appMenusMessages, appButtonsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import InterfaceSettingsModal from './InterfaceSettingsModal';

type Props = {
  intl: Object,
  refresh: Number,
  credentials: Object,
  userInfo: Object,
  hideAppLoader: Function,
  updateLastActionInfo: Function,
  loginSuccess: Function,
  appLoaderVisible: Boolean,
};

class RoleManagementCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cmp = (userX, userY) => userX.roleName === userY.roleName;

    this.localRoleList = [];
    this.state = {
      isLoading: false,
      isSubmitting: false,

      roleList: [],

      showInterfaceSettings: false,
      role: null,
    };

    this.ruleStrMap = {
      ReadOnlyUser: 'ReadOnlyUser',
      LocalAdmin: 'Administrator',
      NormalUser: 'NormalUser',
      Admin: 'Global Administrator',
    };
    this.sortByMap = {
      ReadOnlyUser: 0,
      NormalUser: 1,
      LocalAdmin: 2,
      Admin: 3,
    };
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.refresh !== this.props.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { credentials } = props;

    this.setState({ isLoading: true });
    props.updateLastActionInfo();
    fetchGet(getEndpoint('userroleconfig'), {
      ...credentials,
    })
      .then((data) => {
        let roleList = data.updateRoles || [];
        roleList = R.map((item) => ({ ...item, order: this.sortByMap[item.roleName] }), roleList);
        roleList = R.sortWith([R.ascend(R.prop('order'))], roleList);
        this.localRoleList = R.clone(roleList);
        this.setState(
          {
            isLoading: false,
            roleList,
          },
          () => {
            this.table.forceUpdateGrid();
            this.forceUpdate();
          },
        );
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  @autobind
  selectRoleRender({ dataKey, rowData, cellData }) {
    const { userName } = rowData;
    if (userName === 'admin') {
      return <div>{cellData}</div>;
    }
    return (
      <Select
        size="small"
        value={cellData}
        onChange={this.handleRoleChange(rowData, dataKey)}
        dropdownMatchSelectWidth={false}
      >
        {this.roleListOption.map((item) => (
          <Select.Option key={item.value} title={item.value}>
            {this.ruleStrMap[item.label]}
          </Select.Option>
        ))}
      </Select>
    );
  }

  @autobind
  handleRoleChange(rowData, dataKey) {
    return (value) => {
      // Save the data and force update.
      rowData[dataKey] = value || '';
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  configRenderer({ rowData }) {
    const { intl } = this.props;
    return (
      <div className="flex-row" style={{ justifyContent: 'space-between' }}>
        <Tooltip title={intl.formatMessage(eventMessages.userInterfaceSettings)} mouseEnterDelay={0.3}>
          {/* <Icon type="setting" style={{ fontSize: 16, margin: '0 4px' }} onClick={this.handleSettingClick(rowData)} /> */}
          <Button size="small" type="primary" onClick={this.handleSettingClick(rowData)}>
            {intl.formatMessage(eventMessages.menuConfig)}
          </Button>
        </Tooltip>

        {false && (
          <Popconfirm
            placement="topRight"
            title={
              <div>
                This action will permanently remove this role and all associated data.
                <br />
                Are you sure you would like to continue?
              </div>
            }
            okText={intl.formatMessage(appButtonsMessages.yes)}
            cancelText={intl.formatMessage(appButtonsMessages.no)}
            onConfirm={() => this.handleUserRemove(rowData)}
            onCancel={(event) => event.stopPropagation()}
          >
            <DeleteOutlined style={{ fontSize: 16, margin: '0 4px' }} onClick={(event) => event.stopPropagation()} />
          </Popconfirm>
        )}
      </div>
    );
  }

  @autobind
  handleSaveClick() {
    const { intl, credentials } = this.props;
    const { roleList } = this.state;
    const diff = R.differenceWith(this.cmp, this.localRoleList, roleList);

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('userroleconfig'), {
      ...credentials,
      updateRoles: JSON.stringify(diff),
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData(this.props);
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  handleUserRemove(rowData) {
    const { intl, credentials } = this.props;
    const { roleName } = rowData;

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchDelete(getEndpoint('userroleconfig'), { ...credentials, roleName }, {}, false)
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData(this.props);
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  handleSettingClick(rowData) {
    return (event) => {
      const { intl } = this.props;
      const menus = getUIMenus(intl, appMenusMessages);
      const displayedMenus = rowData.displayedMenus || Defaults.DisplayedMenus;

      const menuConfigs = [];
      R.forEach((menu) => {
        const index = displayedMenus.indexOf(menu.id);
        let display = false;
        if (index !== -1) {
          display = true;
        }
        menuConfigs.push({
          ...menu,
          display,
          order: index,
        });
      }, menus);

      this.setState({ showInterfaceSettings: true, role: { ...rowData, menuConfigs } });
    };
  }

  @autobind
  handleInterfaceConfigSubmit(role, cards) {
    const { intl, credentials, userInfo, loginSuccess } = this.props;

    const { roleName } = role;
    const { menuConfigs, ...rest } = role;
    const displayedMenus = R.map(
      (item) => item.id,
      R.filter((item) => item.display, cards),
    );
    const updateRoles = [{ ...rest, displayedMenus }];

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('userroleconfig'), {
      ...credentials,
      updateRoles: JSON.stringify(updateRoles),
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData(this.props);
        this.setState({ isSubmitting: false, showInterfaceSettings: false });

        // update current user info
        if (roleName === userInfo.role) {
          // create new userInfo
          const newUserInfo = { ...userInfo, displayedMenus };
          loginSuccess(credentials, newUserInfo);
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  roleNameRender({ cellData }) {
    return <>{this.ruleStrMap[cellData]}</>;
  }

  render() {
    const { intl } = this.props;
    const { isLoading, isSubmitting, roleList, role } = this.state;

    const diff = R.differenceWith(this.cmp, this.localRoleList, roleList);
    const hasError =
      diff.length === 0 ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => Boolean(item.roleName), diff),
      );

    return (
      <Container fullHeight className={`${isLoading ? 'loading' : ''}`} style={{ width: 800 }}>
        <form
          className={`ui ${hasError ? 'error' : ''} form full-height flex-col`}
          style={{ fontSize: 12, width: '100%' }}
        >
          <Container className={`flex-grow field ${isSubmitting ? 'disabled' : ''}`}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  rowCount={this.localRoleList.length}
                  rowGetter={({ index }) => this.localRoleList[index]}
                  ref={(table) => {
                    this.table = table;
                  }}
                >
                  <Column
                    width={150}
                    label={intl.formatMessage(eventMessages.roleName)}
                    dataKey="roleName"
                    flexGrow={1}
                    cellRenderer={this.roleNameRender}
                  />
                  <Column width={120} label="" cellRenderer={this.configRenderer} dataKey="roleName" />
                </Table>
              )}
            </AutoSizer>
          </Container>
          <div className="flex-row">
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              loading={isSubmitting}
              disabled={hasError}
              onClick={this.handleSaveClick}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </div>
        </form>

        {this.state.showInterfaceSettings && (
          <InterfaceSettingsModal
            intl={intl}
            onOk={this.handleInterfaceConfigSubmit}
            onCancel={() => this.setState({ showInterfaceSettings: false })}
            rowData={role}
            isSubmitting={isSubmitting}
          />
        )}
      </Container>
    );
  }
}

const RoleManagement = injectIntl(RoleManagementCore);
export default connect(
  (state: State) => {
    const { credentials, userInfo } = state.auth;
    const { appLoaderVisible } = state.app;
    return { credentials, userInfo, appLoaderVisible };
  },
  { hideAppLoader, loginSuccess, updateLastActionInfo },
)(RoleManagement);
