/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Credentials } from '../types';
import fetchGet from './fetchGet';
import fetchPost from './fetchPost';

const postProjectDetectionStatus = (credentials: Credentials, params: Object) => {
  return fetchGet(`${window.BASE_URL || ''}/updateDetection`, {
    ...credentials,
    ...params,
  })
    .then(d => {
      return d;
    });
};

export default postProjectDetectionStatus;
