import * as R from 'ramda';
import { get } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseTimelines } from './parsers';

const getAlertCauseTimelines = (credentials: Credentials, params: Object) => {
  const {
    level,
    customerName,
    environmentName,
    startTimestamps,
    startTime,
    fromJobAnalysis,
    ignoreFirstDayData,
    systemId,
    systemInfoMap,
  } = params;

  const requests = [];
  R.forEach((startTimestamp) => {
    requests.push(
      fetchGet(getEndpoint('alertsummarytimelines', 2), {
        ...credentials,
        environmentName,
        systemIds: JSON.stringify([{ id: systemId }]),
        customerName,
        startTime: fromJobAnalysis ? startTime : startTimestamp,
      }).catch((e) => {
        console.warn('[IF_API] failed to get all system timelines', e);
        return [];
      }),
    );
  }, startTimestamps);

  return Promise.all(requests).then((results) => {
    const timelinesMap = {};
    R.addIndex(R.forEach)((result, idx) => {
      const timeline = result[0];
      const { projectNameSet, projectDisplayMap } = get(systemInfoMap, [timeline.id], {});
      parseTimelines({
        timeline,
        timelinesMap,
        projectNameSet,
        projectDisplayMap,
        notInclude: ignoreFirstDayData && idx === 0,
      });
    }, R.slice(0, Infinity, results));

    // build timelines
    const systemTimelinesMap = {};
    R.forEachObjIndexed((valList, id) => {
      const newValList = R.filter((item) => !item.notInclude, valList);
      R.forEach((item) => {
        const { systemTimeline } = item;
        if (!R.has(id, systemTimelinesMap)) {
          systemTimelinesMap[id] = systemTimeline;
        } else {
          systemTimelinesMap[id] = {
            ...systemTimeline,
            anomalyTimelines: [...systemTimelinesMap[id].anomalyTimelines, ...systemTimeline.anomalyTimelines],
          };
        }
      }, newValList);
    }, timelinesMap);
    const timelines = R.map((timeline) => ({ ...timeline, level }), R.values(systemTimelinesMap));

    return {
      globalSystemInfo: timelines[0],
    };
  });
};

export default getAlertCauseTimelines;
