/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import moment from 'moment';
import { get, isNumber, isString } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseJSON } from '../utils';

const getProjectModel = (credentials: Credentials, params: Object) => {
  const { projectName, instanceGroup, instanceName, startTime, endTime, isHoliday } = params;
  const dateFormat = 'YYYY-MM-DD';

  // The start time & end time is in logical format, like 2017-06-26, convert it into GMT time.
  const modelStartTime = isHoliday
    ? undefined
    : moment(startTime, dateFormat).subtract(1, 'day').endOf('day').valueOf();
  const modelEndTime = isHoliday ? undefined : moment(endTime, dateFormat).endOf('day').valueOf();

  return fetchGet(getEndpoint(isHoliday ? 'modelPickingHoliday' : 'modelPicking'), {
    ...credentials,
    projectName,
    operation: 'list',
    instanceName,
    instanceGroup,
    modelStartTime,
    modelEndTime,
  }).then((d) => {
    const rawData = d.data;
    const rawModels = get(rawData, 'modelKeys', []);

    // Convert
    let models = R.map((model) => {
      const {
        mapData,
        pickableFlag,
        maxValues,
        metricNameList,
        minValues,
        pickedFlag,
        metricModelStatsOrganizer,
        ...rest
      } = model;

      // build metrics
      const names = (metricNameList || '[]').slice(1, -1).split(',');
      const maxs = JSON.parse((maxValues || '[]').replace(/\bNaN\b/g, 'null'));
      const mins = JSON.parse((minValues || '[]').replace(/\bNaN\b/g, 'null'));
      let metrics = [];
      if (metricModelStatsOrganizer) {
        const metricOrganizer = JSON.parse(metricModelStatsOrganizer || '{}');
        const { ms, mm, im } = metricOrganizer;
        R.forEach((item) => {
          const { m, i, ma, mi, g } = item;
          metrics.push({ name: mm[m], instanceName: im[i], max: ma, min: mi, group: g });
        }, ms || []);
      } else {
        metrics = R.addIndex(R.map)((val, idx) => {
          return {
            name: val,
            max: isNumber(maxs[idx]) ? maxs[idx].toFixed(2) : NaN,
            min: isNumber(mins[idx]) ? mins[idx].toFixed(2) : NaN,
          };
        }, names);
      }

      // build heat map
      const heatmap = isString(mapData)
        ? parseJSON(mapData) || []
        : get(mapData[0], 'NASValues', []).map((item) => parseFloat(item.split(',')[1]));

      return {
        ...rest,
        heatmap,
        metrics,
        pickable: Boolean(pickableFlag),
        picked: Boolean(pickedFlag),
        hasMetricOrganizer: Boolean(metricModelStatsOrganizer),
      };
    }, rawModels);
    models = R.sortWith([R.descend(R.prop('endTimestamp'))], models);

    const data = {
      models,
    };
    return {
      rawData,
      data,
    };
  });
};

export default getProjectModel;
