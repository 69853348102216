/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import Papa from 'papaparse';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { get, throttle } from 'lodash';
import {
  AppstoreOutlined,
  CloseOutlined,
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  ReloadOutlined,
  RightSquareOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Progress,
  Switch,
  Button,
  Radio,
  Select,
  DatePicker,
  Divider,
  Popover,
  Tooltip,
  Empty,
  notification,
  Card,
  Spin,
} from 'antd';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';

import momenttz from 'moment-timezone';
import { State } from '../../common/types';
import { createLoadAction } from '../../common/app/actions';
import { ActionTypes } from '../../common/metric/actions';
import {
  Defaults,
  parseLocation,
  getLoadStatus,
  buildLocation,
  ifIn,
  buildUrl,
  MetricRenderers,
  sleep,
  parseJSON,
  downloadPdf,
} from '../../common/utils';
import { Container, CellMeasurerCache, AutoSizer } from '../../lib/fui/react';
import { BaseUrls } from '../app/Constants';
import { PinLegendIcon, ChangeEventIcon, PDFIcon } from '../../lib/fui/icons';

import { LineChart } from '../../lib/fui/react/charts';
import LineChartTimeSelectModal from './components/LineChartTimeSelectModal';
import SeparateInstanceChartModal from './components/SeparateInstanceChartModal';
import EventPublishModal from './components/EventPublishModal';

import { appFieldsMessages } from '../../common/app/messages';
import { eventMessages } from '../../common/metric/messages';
import { InstanceFilter, applyInstanceList } from '../share/InstanceFilter';
import { MetricFilter } from '../share/MetricFilter';
import getInstanceDisplayName from '../../common/utils/getInstanceDisplayName';
import { calculationWithBaseLineLogic } from '../../common/metric/logic/newMetricChartDataLogic';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import fetchPost from '../../common/apis/fetchPost';
import { BuildSelectEntityList } from '../share/EntityListView';

const RED_COLOR = '#F2495C';

type Props = {
  // Common props
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  // eslint-disable-next-line
  userName: String,
  projects: Array<Object>,
  projectInstanceComponentMap: Object,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  defaultTimezone: String,
  // eslint-disable-next-line
  timezoneOffset: Number,

  project: Object,
  eventLineChartData: Object,
  eventLineChartBaselineData: Object,
  eventPercentage: Number,
  baseLinePercentage: Number,
  allInstanceInfoList: Array<Object>,
  columnInfoMap: Object,
  instanceList: Array<Object>,
  metricList: Array<Object>,
  selectInstanceList: Array<Object>,
  selectMetricList: Array<Object>,
  // eslint-disable-next-line
  metricDatasetMap: Object,
  metricEventListMap: Object,
  // eslint-disable-next-line
  datasetMetricList: Array<Object>,
  // eslint-disable-next-line
  datasetInstanceList: Array<Object>,
  hasDataMetricList: Array<Object>,
  notExistInstances: Array<Object>,
  metricListWithNoData: Array<Object>,
  anomalyInstanceList: Array<Object>,
  anomalyInstanceMetricMap: Object,
  // eslint-disable-next-line
  anomalyMetricByInstances: Array<Object>,
  instancePageIndex: Number,
  metricPageIndex: Number,
  latestDataTime: Number,
  currentTimestamp: Number,

  // eslint-disable-next-line
  appNameMapping: Object,
  metricObj: Object,
  // eslint-disable-next-line
  allMetricInfoList: Array<Object>,
  filterMetricInfoList: Array<Object>,
  k8CoverageMap: Object,
  userInfo: Object,
  instanceDisplayNameMap: Object,
};

const DragHandle = sortableHandle(({ intl }) => (
  <Tooltip title={intl.formatMessage(appFieldsMessages.dragAndDrop)} placement="top" mouseEnterDelay={0.3}>
    <div
      className="hover-display-item flex-row flex-center-align flex-center-justify"
      style={{ width: 28, height: 28, cursor: 'move' }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div
        style={{
          minWidth: 18,
          height: 11,
          opacity: 0.35,
          background:
            '-webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)',
        }}
      />
    </div>
  </Tooltip>
));
const SortableList = SortableContainer(({ children }) => {
  return <>{children}</>;
});

const SortableItem = SortableElement(({ children }) => {
  return <>{children}</>;
});

class MetricLineChartsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.initView = 'grid';
    this.lineChartsLoader = 'metric_linecharts';
    this.instanceInfoLoader = 'metric_linecharts_instance_info';
    this.columnSize = 2;
    this.chartHeight = 212;
    this.metricPageSize = 20;
    this.instancePageSize = 10;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: this.chartHeight,
    });
    this.refInstanceSelection = React.createRef();
    this.refMetricSelection = React.createRef();

    let { selectInstanceList } = props;
    let { selectMetricList } = props;
    const { location } = props;
    const query = parseLocation(location);
    const { startTimeWindow, endTimeWindow, justSelectMetric, justInstanceList, withBaseline } = query;
    let chartDateWindow = [];
    if (startTimeWindow && endTimeWindow) {
      chartDateWindow = [Number(startTimeWindow), Number(endTimeWindow)];
    }
    if (justSelectMetric) {
      selectMetricList = justSelectMetric.split(',');
    }
    if (justInstanceList) {
      selectInstanceList = justInstanceList.split(',');
    }

    const instanceSelectionMap = R.reduce((acc, elem) => ({ ...acc, [elem]: true }), {}, selectInstanceList);
    const metricSelectionMap = R.reduce((acc, elem) => ({ ...acc, [elem]: true }), {}, selectMetricList);

    // handle incident info
    let { incidentInfo, metricAnomalyMap } = query;
    const { showRCAFlag } = query;
    if (incidentInfo) {
      incidentInfo = parseJSON(incidentInfo) || {};
    }
    if (metricAnomalyMap) {
      metricAnomalyMap = parseJSON(metricAnomalyMap) || {};
    }

    this.isSetJustInstancePage = false;
    this.isSetJustMetricPage = false;
    this.hideReloadTip = false;

    this.state = {
      // params
      view: this.initView,
      withBaseline: withBaseline === 'true' || !withBaseline,
      isCustomData: false,
      onlyFetchBaseline: false,
      datasetStartTime: null,
      datasetEndTime: null,
      timeChange: false,

      // progress
      showProgressBar: true,
      eventPercentage: 0,
      baseLinePercentage: 0,

      // metric data info
      chartDateWindow,
      metricDatasetMap: {},

      // current disply info
      instanceList: selectInstanceList,
      metricList: selectMetricList,
      anomalyMetricByInstances: [],
      sortMetricList: [],

      // selected m/i value
      selectInstanceList,
      selectMetricList,
      checkedMetricList: [],
      removedMetricList: [],

      currentAllMetricList: props.filterMetricInfoList || [],

      // pagging and dropdown config
      metricPageIndex: 1,
      instancePageIndex: 1,

      // For RCA jump, auto display incident info
      incidentInfo,
      metricAnomalyMap,
      showRCAFlag: showRCAFlag?.toLowerCase() === 'true',

      // open modal control
      showPublishModal: false,

      showSeparateInstanceChartModal: false,
      activeMetricData: undefined,

      // jump time range
      showTimeSelectModal: false,
      activeIncident: undefined,
      selectInstance: undefined,
      selectStartTimestamp: undefined,
      selectEndTimestamp: undefined,

      instanceSelectionMap,
      metricSelectionMap,
      metricReloading: false,
      hasJustList: !!justInstanceList || !!justSelectMetric,
      showFilterPanel: true,
      componentMapLoading: false,
      globaInstaceMappingProject: {},
      globaInstaceMappingProjectLoading: false,
      exportPDFing: false,
    };
  }

  componentDidMount() {
    const { location, projects, replace, credentials } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime, ...params } = query;
    let { projectName, startTimestamp, endTimestamp } = query;
    const [pName, pOwer] = (projectName || '').split('@');
    if (pOwer && pOwer === credentials.userName) {
      projectName = pName;
    }

    let changed = false;

    if (!startTimestamp) {
      if (startTime) {
        startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      } else {
        startTimestamp = moment.utc().startOf('day').valueOf();
      }
      changed = true;
    }

    if (!endTimestamp) {
      if (endTime) {
        endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
      } else {
        endTimestamp = moment.utc().endOf('day').valueOf();
      }
      changed = true;
    }

    let currentProject = R.find((project) => project.projectName === projectName, projects);
    if (!currentProject) {
      currentProject = projects[0];
      if (currentProject) {
        // eslint-disable-next-line prefer-destructuring
        projectName = currentProject.projectName;
        changed = true;
      }
    }

    let timezoneOffset = 0;
    if (currentProject?.timezone) {
      const zone = momenttz.tz(currentProject?.timezone);
      timezoneOffset = zone.utcOffset();
    }
    const now = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;

    let start = startTimestamp;
    let end = endTimestamp;

    if (now < startTimestamp) {
      start = startTimestamp - timezoneOffset;
      end = endTimestamp - timezoneOffset;
      changed = true;
    }
    if (changed) {
      replace(
        buildLocation(
          location.pathname,
          {},
          {
            ...params,
            projectName,
            startTimestamp: start,
            endTimestamp: end,
          },
        ),
      );
    } else {
      this.reloadData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      intl,
      location,
      replace,
      instanceList,
      metricList,
      selectInstanceList,
      selectMetricList,
      allInstanceInfoList,
      notExistInstances,

      instancePageIndex,
      metricPageIndex,
      filterMetricInfoList,
      metricEventListMap,
    } = nextProps;
    const { eventLineChartData, eventLineChartBaselineData, eventPercentage, baseLinePercentage } = nextProps;
    const { withBaseline, onlyFetchBaseline } = this.state;
    if (this.props.eventPercentage !== eventPercentage) {
      this.setState({ eventPercentage });
      if (!withBaseline && eventPercentage === 100) {
        setTimeout(() => this.setState({ showProgressBar: false }), 1000);
      }
    }
    if (this.props.baseLinePercentage !== baseLinePercentage) {
      this.setState({ baseLinePercentage });
      if (withBaseline && onlyFetchBaseline && baseLinePercentage === 100) {
        setTimeout(() => this.setState({ showProgressBar: false }), 1000);
      } else if (withBaseline && !onlyFetchBaseline && eventPercentage + baseLinePercentage === 200) {
        setTimeout(() => this.setState({ showProgressBar: false }), 1000);
      }
    }
    if (intl.locale !== this.props.intl.locale) {
      moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    }

    const query = parseLocation(this.props.location);
    const nextQuery = parseLocation(location);
    if (
      query.projectName !== nextQuery.projectName ||
      query.instanceGroup !== nextQuery.instanceGroup ||
      query.predictedFlag !== nextQuery.predictedFlag ||
      query.detectedEventType !== nextQuery.detectedEventType
    ) {
      this.setState({ globaInstaceMappingProject: {} }, () => {
        this.reloadData(nextProps, true);
      });
    } else if (this.props.eventLineChartData !== eventLineChartData) {
      // replace system info by project
      const projectInfo = get(eventLineChartData, 'projectInfo');
      replace(
        buildLocation(
          location.pathname,
          {},
          {
            ...nextQuery,
            customerName: query.customerName || projectInfo?.owner,
            environmentId: projectInfo?.groupList[0] || 'All',
            systemId: projectInfo?.systemName,
          },
        ),
      );

      const metricDatasetMap = get(eventLineChartData, 'metricDatasetMap', {});
      const anomalyMetricByInstances = get(eventLineChartData, 'anomalyMetricByInstances', []);

      // set default instance page by auto selected instance/metric
      let instancePage = instancePageIndex;
      if (!this.isSetJustInstancePage && nextQuery.justInstanceList) {
        let justInstances = nextQuery.justInstanceList.split(',');
        justInstances = R.sort((a, b) => a.localeCompare(b), justInstances);
        const firstInstanceIndex = R.findIndex((item) => item.id === justInstances[0], allInstanceInfoList);
        if (firstInstanceIndex !== -1) instancePage = Math.ceil((firstInstanceIndex + 1) / this.instancePageSize);
        this.isSetJustInstancePage = true;
      }
      let metricPage = metricPageIndex;
      if (!this.isSetJustMetricPage && nextQuery.justSelectMetric) {
        let justMetrics = nextQuery.justSelectMetric.split(',');
        justMetrics = R.sort((a, b) => a.localeCompare(b), justMetrics);
        const firstMetricIndex = R.findIndex((item) => item.id === justMetrics[0], filterMetricInfoList);
        if (firstMetricIndex !== -1) metricPage = Math.ceil((firstMetricIndex + 1) / this.metricPageSize);
        this.isSetJustMetricPage = true;
      }

      const sortedMetricList = R.sort((a, b) => {
        const aval = R.reduce(
          R.min,
          Number.MAX_SAFE_INTEGER,
          R.map(
            (x) => x.startTimestamp,
            R.filter((x) => x.metric === a, (metricEventListMap[a] || [])[0]?.rootCauseList || []),
          ),
        );
        const bval = R.reduce(
          R.min,
          Number.MAX_SAFE_INTEGER,
          R.map(
            (x) => x.startTimestamp,
            R.filter((x) => x.metric === b, (metricEventListMap[b] || [])[0]?.rootCauseList || []),
          ),
        );
        return aval - bval;
      }, metricList);

      this.setState(
        {
          metricDatasetMap,
          anomalyMetricByInstances,
          instanceList,
          metricList: sortedMetricList,
          selectInstanceList,
          instanceSelectionMap: R.reduce((acc, elem) => ({ ...acc, [elem]: true }), {}, selectInstanceList),
          selectMetricList,
          metricSelectionMap: R.reduce((acc, elem) => ({ ...acc, [elem]: true }), {}, selectMetricList),
          checkedMetricList: [],
          removedMetricList: [],
          currentAllMetricList: filterMetricInfoList,
          instancePageIndex: instancePage,
          metricPageIndex: metricPage,
        },
        () => {
          if (this.listNode) this.listNode.forceUpdateGrid();

          // alert not exist instances only once
          if (!this.alertNotExistInstances && notExistInstances && notExistInstances.length > 0) {
            notification.warn({
              message: 'Notification',
              description: `The instances ${R.join(
                ', ',
                notExistInstances,
              )} do not exist in the selected metric project.`,
              duration: null,
            });
            this.alertNotExistInstances = true;
          }
        },
      );
    } else if (this.props.eventLineChartBaselineData !== eventLineChartBaselineData) {
      this.parseBaselineData(nextProps);
    }
  }

  @autobind
  async getInstanceProjectMapping() {
    try {
      const { location, selectInstanceList } = this.props;
      const query = parseLocation(location);
      const { projectName, startTimestamp, endTimestamp } = query;
      const { globaInstaceMappingProject } = this.state;
      const realSelectInstanceList = [];
      this.setState({
        globaInstaceMappingProjectLoading: true,
      });
      R.forEach((item) => {
        const instanceName = item.indexOf('_') > 0 ? item.split('_')[1] : item;
        if (instanceName) {
          realSelectInstanceList.push(instanceName);
        }
      }, selectInstanceList);
      const result = await fetchPost(getEndpoint('relatedInstanceProperty', 2), {
        projectName,
        instanceSet: JSON.stringify(R.uniq(realSelectInstanceList)),
        startTime: startTimestamp,
        endTime: endTimestamp,
        operation: 'display',
      });
      const newGlobaInstaceMappingProject = R.clone(globaInstaceMappingProject || {});
      R.forEachObjIndexed((value, key) => {
        const instanceProperty = value || {};
        R.forEach((item) => {
          const instanceInfo = {
            instanceName: item.in || key,
            componentName: item.cn || item.in || key,
            isMetric: item.im,
            isContainer: item.ic,
            zone: item.z || '',
            i: item.i,
            projectName: item.p,
            userName: item.u,
            dataType: item.d,
          };
          if (!newGlobaInstaceMappingProject[instanceInfo.instanceName]) {
            newGlobaInstaceMappingProject[instanceInfo.instanceName] = [instanceInfo];
          } else {
            newGlobaInstaceMappingProject[instanceInfo.instanceName].push(instanceInfo);
          }
        }, instanceProperty.i || []);
      }, result || {});
      this.setState({
        globaInstaceMappingProject: newGlobaInstaceMappingProject,
        globaInstaceMappingProjectLoading: false,
      });
    } catch (e) {
      this.setState({ globaInstaceMappingProject: {}, globaInstaceMappingProjectLoading: false });
    }
  }

  @autobind
  handleCustomerNameChange(customerName) {
    const { location, showAppLoader } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    setTimeout(() => {
      const query = parseLocation(location);
      const { startTime, endTime } = query;
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { redirect: undefined, customerName, environmentId: undefined, systemId: undefined, startTime, endTime },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  reloadData(props, force, notLoadComponent = false) {
    const { location, createLoadAction } = props;
    const query = parseLocation(location);
    const { projectName, instanceGroup, predictedFlag, detectedEventType } = query;
    let { startTimestamp, endTimestamp } = query;
    startTimestamp = parseInt(startTimestamp, 10);
    endTimestamp = parseInt(endTimestamp, 10);

    const { hasJustList } = this.state;

    if (projectName && startTimestamp && endTimestamp) {
      // If there is select metric and instance in state, use it, otherwise get from url
      const { metricAnomalyMap, selectInstanceList, selectMetricList, instancePageIndex, metricPageIndex } = this.state;
      // If force reload, reset some fields
      let { chartDateWindow } = this.state;
      if (force || chartDateWindow.length === 0) {
        chartDateWindow = [startTimestamp, endTimestamp];
      }

      this.setState(
        {
          chartDateWindow,
          datasetStartTime: startTimestamp,
          datasetEndTime: endTimestamp,
          isCustomData: false,
          showProgressBar: true,
          eventPercentage: 0,
          baseLinePercentage: 0,
          metricAnomalyMap,
        },
        () => {
          createLoadAction(
            ActionTypes.LOAD_METRIC_EVENT_LINECHARTS,
            {
              filterPredict: true,
              projectName,
              instanceGroup: instanceGroup || 'All',
              instanceList: selectInstanceList,
              metricList: selectMetricList,
              startTimeObj: moment.utc(startTimestamp).startOf('day'),
              endTimeObj: moment.utc(endTimestamp).endOf('day'),
              eventType:
                detectedEventType === 'true' ? 'detected' : predictedFlag === 'true' ? 'predicted' : 'detected',
              metricPageSize: this.metricPageSize,
              instancePageSize: this.instancePageSize,
              instancePageIndex,
              metricPageIndex,
              metricAnomalyMap,
              hasJustList,
              notLoadComponent,
            },
            true,
            false,
            this.callbackHandle,
          );
        },
      );
    }
  }

  @autobind
  callbackHandle() {
    const { withBaseline } = this.state;
    if (withBaseline) {
      this.reloadDataBaseline(this.props);
    } else {
      const { selectInstanceList } = this.props;
      if (selectInstanceList.length > 0) {
        this.getInstanceProjectMapping();
      }
    }
    this.hideReloadTip = false;
  }

  @autobind
  baselineCallbackHandle() {
    const { selectInstanceList } = this.props;
    if (selectInstanceList.length > 0) {
      this.getInstanceProjectMapping();
    }
  }

  @autobind
  reloadDataBaseline(props) {
    const { location, createLoadAction } = props;
    const { instanceList, metricList } = this.state;
    const query = parseLocation(location);
    const { projectName, instanceGroup } = query;
    let { startTimestamp, endTimestamp } = query;
    startTimestamp = parseInt(startTimestamp, 10);
    endTimestamp = parseInt(endTimestamp, 10);
    this.setState({ showProgressBar: true });
    if (projectName && startTimestamp && endTimestamp && instanceList.length > 0 && metricList.length > 0) {
      const realSelectInstanceList = [];
      R.forEach((item) => {
        const instanceName = item.indexOf('_') > 0 ? item.split('_')[1] : item;
        if (instanceName) {
          realSelectInstanceList.push(instanceName);
        }
      }, instanceList);
      let newInstanceList = [...realSelectInstanceList, ...instanceList];
      newInstanceList = R.uniq(newInstanceList);
      createLoadAction(
        ActionTypes.LOAD_METRIC_EVENT_LINECHARTS_BASELINE,
        {
          projectName,
          instanceGroup: instanceGroup || 'All',
          startTimeObj: moment.utc(startTimestamp),
          endTimeObj: moment.utc(endTimestamp),
          instanceList: newInstanceList,
          metricList,
        },
        this.lineChartsLoader,
        false,
        this.baselineCallbackHandle,
      );
    } else {
      this.setState({ showProgressBar: false });
      this.baselineCallbackHandle();
    }
  }

  @autobind
  parseBaselineData(props, clearBaselineData) {
    const { eventLineChartBaselineData, metricDatasetMap, columnInfoMap } = props;
    let newMetricDatasetMap = metricDatasetMap;
    if (!clearBaselineData) {
      newMetricDatasetMap = calculationWithBaseLineLogic(eventLineChartBaselineData, metricDatasetMap, columnInfoMap);
    }
    this.setState({ isCustomData: !clearBaselineData, metricDatasetMap: newMetricDatasetMap });
  }

  @autobind
  handleDateWindowSync(dateWindow) {
    this.setState({ chartDateWindow: dateWindow }, () => {
      if (this.listNode) this.listNode.forceUpdateGrid();
    });
  }

  @autobind
  handleCheckMetric(metric, checked) {
    const { checkedMetricList, selectMetricList } = this.state;
    let newCheckedMetricList = checkedMetricList;
    let newSelectMetricList = selectMetricList;

    if (checked) {
      // reset select metric list if first check metric
      if (newCheckedMetricList.length === 0) {
        newSelectMetricList = [];
      }
      newCheckedMetricList = R.uniq([...newCheckedMetricList, metric]);
      newSelectMetricList = R.uniq([...newSelectMetricList, metric]);
      this.setState({ checkedMetricList: newCheckedMetricList, selectMetricList: newSelectMetricList });
    } else {
      newCheckedMetricList = R.filter((m) => m !== metric, newCheckedMetricList);
      newSelectMetricList = R.filter((m) => m !== metric, newSelectMetricList);
      this.setState({ checkedMetricList: newCheckedMetricList, selectMetricList: newSelectMetricList });
    }
  }

  @autobind
  handleRemoveMetric(metric) {
    const { removedMetricList, metricSelectionMap } = this.state;
    const selectionMap = { ...metricSelectionMap };
    delete selectionMap[metric];
    const selectMetricList = R.keys(selectionMap);

    this.setState({
      metricSelectionMap: selectionMap,
      selectMetricList,
      metricList: selectMetricList,
      removedMetricList: [...removedMetricList, metric],
    });
  }

  @autobind
  renderProgressBar(percent, successPercent) {
    const { intl } = this.props;
    if (percent === 100) {
      return (
        <span style={{ fontSize: 14 }}>
          {intl.formatMessage(appFieldsMessages.waiting)}
          <SyncOutlined style={{ marginLeft: 2 }} size="small" spin />
        </span>
      );
    }
    return `${percent}%`;
  }

  @autobind
  handleProjectChange(projectName) {
    const { location, push } = this.props;
    const query = parseLocation(location);

    this.setState(
      {
        eventPercentage: 0,
        baseLinePercentage: 0,
        instanceList: [],
        metricList: [],
        selectMetricList: [],
        selectInstanceList: [],
        instancePageIndex: 1,
        metricPageIndex: 1,
        withBaseline: true,
      },
      () => {
        push(
          buildLocation(
            location.pathname,
            {},
            {
              ...query,
              projectName,
              justSelectMetric: null,
              justInstanceList: null,
              withBaseline: true,
              incidentInfo: null,
              metricAnomalyMap: null,
            },
          ),
        );
      },
    );
  }

  @autobind
  handleStartTimeChange(startTimeObj) {
    const { location, push } = this.props;
    const query = parseLocation(location);
    const { endTimestamp } = query;
    const { datasetStartTime, datasetEndTime } = this.state;

    const startTimestamp = startTimeObj.startOf('day').valueOf();
    push(buildLocation(location.pathname, {}, { ...query, startTimestamp }));
    this.setState({ timeChange: datasetStartTime !== startTimestamp || datasetEndTime !== parseInt(endTimestamp, 10) });
  }

  @autobind
  handleEndTimeChange(endTimeObj) {
    const { location, push } = this.props;
    const query = parseLocation(location);
    const { startTimestamp } = query;
    const { datasetStartTime, datasetEndTime } = this.state;

    const endTimestamp = endTimeObj.endOf('day').valueOf();
    push(buildLocation(location.pathname, {}, { ...query, endTimestamp }));
    this.setState({ timeChange: datasetStartTime !== parseInt(startTimestamp, 10) || datasetEndTime !== endTimestamp });
  }

  @autobind
  handleDateRangeChange(dates, dateStrings) {
    const { location, push } = this.props;
    const query = parseLocation(location);
    const { datasetStartTime, datasetEndTime } = this.state;

    const startTimestamp = dates[0].startOf('day').valueOf();
    const endTimestamp = dates[1].endOf('day').valueOf();
    push(buildLocation(location.pathname, {}, { ...query, startTimestamp, endTimestamp }));
    this.setState({ timeChange: datasetStartTime !== startTimestamp || datasetEndTime !== endTimestamp });
  }

  @autobind
  handlePreviousDayClick() {
    const { location, push } = this.props;
    const { datasetStartTime, datasetEndTime } = this.state;
    const query = parseLocation(location);
    let { startTimestamp, endTimestamp } = query;
    startTimestamp = parseInt(startTimestamp, 10);
    endTimestamp = parseInt(endTimestamp, 10);
    const startTimeObj = moment.utc(startTimestamp).startOf('day').subtract(1, 'day');
    const endTimeObj = moment.utc(endTimestamp).endOf('day').subtract(1, 'day');
    push(
      buildLocation(
        location.pathname,
        {},
        { ...query, startTimestamp: startTimeObj.valueOf(), endTimestamp: endTimeObj.valueOf() },
      ),
    );
    this.setState({
      timeChange: datasetStartTime !== startTimeObj.valueOf() || datasetEndTime !== endTimeObj.valueOf(),
    });
  }

  @autobind
  handleNextDayClick() {
    const { location, push } = this.props;
    const { datasetStartTime, datasetEndTime } = this.state;
    const query = parseLocation(location);
    let { startTimestamp, endTimestamp } = query;
    startTimestamp = parseInt(startTimestamp, 10);
    endTimestamp = parseInt(endTimestamp, 10);
    const startTimeObj = moment.utc(startTimestamp).startOf('day').add(1, 'day');
    const endTimeObj = moment.utc(endTimestamp).endOf('day').add(1, 'day');
    push(
      buildLocation(
        location.pathname,
        {},
        { ...query, startTimestamp: startTimeObj.valueOf(), endTimestamp: endTimeObj.valueOf() },
      ),
    );
    this.setState({
      timeChange: datasetStartTime !== startTimeObj.valueOf() || datasetEndTime !== endTimeObj.valueOf(),
    });
  }

  @autobind
  setListNode(n) {
    this.listNode = n;
  }

  @autobind
  exportData() {
    const { columnInfoMap } = this.props;
    const { metricDatasetMap, metricList, instanceList } = this.state;
    if (metricDatasetMap) {
      const allCsvData = {};
      const metricAllowLabel = {};
      R.forEachObjIndexed((ds, key) => {
        if (metricList.indexOf(ds.name) >= 0) {
          allCsvData[key] = ds.csvData;
          R.forEach(
            (l) => {
              const info = columnInfoMap[l];
              const hasInstance = info ? (instanceList || []).includes(info.instanceId) : true;
              if (hasInstance) {
                if (metricAllowLabel[key]) {
                  metricAllowLabel[key].push(l);
                } else {
                  metricAllowLabel[key] = [l];
                }
              }
            },
            R.filter((l) => l !== 'timestamp', ds.labels),
          );
        }
      }, metricDatasetMap);

      let data = [];
      if (!R.isEmpty(allCsvData)) {
        R.forEachObjIndexed((val, key) => {
          data = data.length === 0 ? R.map((i) => ({ timestamp: i.timestamp.valueOf() }), val) : data;
          data = R.zipWith(
            (a, b) => {
              return {
                ...a,
                ...R.pick(get(metricAllowLabel, key, []), b),
                timestamp: b.timestamp.valueOf(),
              };
            },
            data,
            val,
          );
        }, allCsvData);

        data = R.filter((item) => R.keys(item).length > 1, data);

        const fname = 'data.csv';
        const csvString = Papa.unparse(data, { newline: '\r\n' });
        const csvData = new Blob([csvString], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.innerHTML = 'Click here';
        a.href = csvUrl;
        a.target = '_blank';
        a.method = 'POST';
        a.download = fname;
        a.click();
        document.body.removeChild(a);
      }
    }
  }

  @autobind
  async exportPDF(displayMetricList = []) {
    try {
      if (this.exportPDFing) {
        return;
      }
      this.exportPDFing = true;
      this.setState({ exportPDFing: true });
      const { view, instanceSelectionMap, metricSelectionMap } = this.state;
      const { location, project, allInstanceInfoList, projectInstanceComponentMap, intl } = this.props;
      const { projectName, startTimestamp, endTimestamp } = parseLocation(location);
      const pdfFilename = `${projectName}-${moment
        .utc(+startTimestamp)
        .startOf('day')
        .format(Defaults.DateFormat)}-${moment.utc(+endTimestamp).format(Defaults.DateFormat)} linechart.pdf`;
      const pdfContents = document.createElement('div');
      pdfContents.setAttribute('id', 'divToPrint');
      pdfContents.setAttribute('class', 'metric-linecharts');

      // try to get the height of the chart
      const chartHeight = displayMetricList.length * (view === 'list' ? 300 : Math.ceil(300 / 2));
      const isPod = project?.cloudType?.toLowerCase() === 'kubernetespod';
      const entityType = isPod ? 'Pod' : intl.formatMessage(appFieldsMessages.instance);

      const allInstanceList = allInstanceInfoList || [];
      const instanceComponentMap = projectInstanceComponentMap?.[projectName] || {};
      // anomalyInstanceList={anomalyInstanceList || []}
      const selectionMap = instanceSelectionMap || {};

      const { allEntityTreeMap } = applyInstanceList(isPod, allInstanceList, {}, instanceComponentMap);
      const { allSelectedEntityList } = BuildSelectEntityList(entityType, allEntityTreeMap, selectionMap);
      const collapseMap = {};
      const showInstanceList = R.filter((e) => {
        const parents = e.parents || [];
        for (let i = 0; i < parents.length; i += 1) {
          if (collapseMap[parents[i]]) {
            return false;
          }
        }
        return true;
      }, allSelectedEntityList || []);
      const instanceHeight = showInstanceList.length * 32 + 70;
      const metricHeight = R.keys(metricSelectionMap || {}).length * 32 + 70;
      const realHeight = Math.max(chartHeight, Math.max(metricHeight, instanceHeight) * 2);
      document.body.appendChild(pdfContents);
      pdfContents.setAttribute(
        'style',
        `min-width: 210mm; 
        width: 100%; 
        height: 100%; 
        overflow: auto; 
        margin-left: auto; 
        margin-right: auto; 
        background-color: white;
        position: absolute;
        top: -100000px;
      `,
      );

      const linechart = document.getElementById('linechart-container');
      const clientContent = document.createElement('div');
      clientContent.setAttribute(
        'style',
        `width: 100%; 
        min-height: 100%; 
        height: ${realHeight + 84}px; 
        margin-left: auto; 
        margin-right: auto; 
        background-color: white;
      `,
      );

      const containerHeight = getComputedStyle(linechart).height;
      linechart.style.height = `${realHeight + 84}px`;
      await clientContent.appendChild(linechart);
      await pdfContents.appendChild(clientContent);

      await sleep(600);
      downloadPdf(pdfFilename, [pdfContents], () => {
        this.setState({ exportPDFing: false }, () => {
          linechart.style.height = containerHeight;
          document.getElementById('linechart-container-parent').appendChild(linechart);
          document.body.removeChild(pdfContents);
        });
        this.exportPDFing = false;
      });
    } catch (e) {
      console.log(e);
      this.setState({ exportPDFing: false });
      this.exportPDFing = false;
    }
  }

  @autobind
  handleOnAnnotationClick(annos) {
    const { startTimestamp, endTimestamp, instanceId } = annos[0] || {};

    let selectStartTimestamp = Infinity;
    let selectEndTimestamp = 0;
    R.forEach((anno) => {
      const { timeRangeList } = anno;
      R.forEach((tr) => {
        const { startTimestamp: s, endTimestamp: e } = tr;
        selectStartTimestamp = R.min(selectStartTimestamp, s);
        selectEndTimestamp = R.max(selectEndTimestamp, e);
      }, timeRangeList);
    }, annos);
    const startTimetamp = selectStartTimestamp !== Infinity ? selectStartTimestamp : startTimestamp;
    selectEndTimestamp = startTimetamp - 600000;

    this.setState({
      showTimeSelectModal: true,
      activeIncident: annos || [],
      selectInstance: instanceId,
      selectStartTimestamp: selectEndTimestamp,
      selectEndTimestamp: startTimetamp,
    });
  }

  @autobind
  onCloseTimeSelect(props) {
    const {
      projectName: selectProject,
      instanceName: selectInstance,
      startTimestamp: selectStartTimestamp,
      endTimestamp: selectEndTimestamp,
    } = props || {};
    this.setState({ showTimeSelectModal: false }, () => {
      if (selectProject && selectInstance && selectStartTimestamp && selectEndTimestamp) {
        const { location } = this.props;
        const { projectName, instanceGroup } = parseLocation(location);
        const { activeIncident } = this.state;

        const instances = [];
        const metrics = [];
        R.forEach((item) => {
          const { instanceId, rootCauseMetric } = item;
          instances.push(instanceId);
          metrics.push(rootCauseMetric);
        }, activeIncident || []);
        const { isPrediction, startTimestamp, endTimestamp, patternId } = activeIncident[0] || {};
        const params = {
          // line chart used
          projectName,
          instanceGroup: instanceGroup || 'All',
          startTimestamp: moment.utc(startTimestamp).startOf('day').valueOf(),
          endTimestamp: moment.utc(endTimestamp).endOf('day').valueOf(),
          predictedFlag: isPrediction,
          justSelectMetric: R.join(',', R.uniq(metrics)),
          justInstanceList: R.join(',', R.uniq(instances)),

          // may not used
          patternId,

          // log context used
          selectProject,
          selectInstance,
          selectStartTimestamp,
          selectEndTimestamp,
        };

        window.open(buildUrl(BaseUrls.EventsDetails, {}, params), '_blank');
      }
    });
  }

  @autobind
  handleOnCreateAnnotation({ x, timestamp, annos, isCurrent, isRootAnomaly, ...rest }) {
    const { intl, project, k8CoverageMap, instanceDisplayNameMap } = this.props;
    if (isCurrent) {
      return (
        <div key="isCurrent" className="anno" style={{ left: x, height: 0, width: 0, marginTop: -6 }}>
          <div
            style={{
              position: 'absolute',
              whiteSpace: 'nowrap',
              color: '--light-label',
              transform: 'translateX(-50%)',
              marginTop: -16,
            }}
          >
            {`${intl.formatMessage(appFieldsMessages.currentTime)}: ${moment
              .utc(timestamp)
              .format(Defaults.TimeOnlyFormat)}`}
          </div>
          <div
            style={{
              position: 'absolute',
              height: 0,
              width: 0,
              borderTop: '4px solid var(--linechart-now-arrow-color)',
              borderLeft: '4px solid transparent',
              borderRight: '4px solid transparent',
              transform: 'translateX(-50%)',
            }}
          />
        </div>
      );
    } else if (isRootAnomaly) {
      const { type, patternId, isPrediction, startTimestamp, endTimestamp } = rest;
      return (
        <Popover
          key="isRootAnomaly"
          overlayClassName="dark"
          placement="right"
          title={null}
          content={
            <div className="linechart-anno-content tooltip-dark flex-col flex-min-height">
              <div>
                <span className="bold inline-block">
                  {type === 'incident'
                    ? endTimestamp
                      ? `${moment.utc(startTimestamp || timestamp).format(Defaults.TimeFormat)} ~ ${moment
                          .utc(endTimestamp + 1)
                          .format(Defaults.TimeFormat)}`
                      : `${moment.utc(startTimestamp || timestamp).format(Defaults.TimeFormat)}`
                    : `${moment.utc(timestamp).format(Defaults.TimeFormat)}`}
                </span>
              </div>
              <div>
                <span className="bold inline-block tooltip-label" style={{ marginRight: 8, width: 120 }}>
                  {intl.formatMessage(eventMessages.patternId)}:{' '}
                </span>
                <span>{patternId}</span>
              </div>
            </div>
          }
        >
          <div style={{ position: 'absolute', left: x, lineHeight: '12px', height: 12 }}>
            {type === 'incident' && (
              <PinLegendIcon style={{ color: isPrediction ? '#ffad66' : '#FF5142', marginLeft: -6 }} />
            )}
            {type === 'deployment' && <ChangeEventIcon style={{ color: 'orange', marginLeft: -6 }} />}
            {type === 'normal' && (
              <div
                className="anno"
                style={{
                  borderColor: '#FF8961',
                  borderRadius: '50%',
                  position: 'fixed',
                  top: 'auto',
                  width: 10,
                  height: 10,
                }}
              />
            )}
          </div>
        </Popover>
      );
    }

    const maxPct = R.reduce(
      R.max,
      0,
      R.map((anno) => parseFloat(anno.pct), annos),
    );

    let isHigher = false;
    R.forEach((a) => {
      if (a.isHigher) {
        isHigher = true;
      }
    }, annos);

    const backgroundColor =
      maxPct >= 200 ? RED_COLOR : maxPct > 0 ? 'orange' : maxPct === 0 ? (isHigher ? 'orange' : '#2185d0') : '#2185d0';
    const hasInstanceDown = R.find((anno) => anno.isInstanceDown || anno.instanceDown, annos || []);
    return (
      <Popover
        key={`${timestamp}`}
        overlayClassName="dark"
        placement="right"
        title={null}
        color="rgba(15,26,30,1)"
        zIndex={1001}
        content={
          <>
            {MetricRenderers.LineChartAnnotation(
              timestamp,
              annos,
              intl,
              project,
              k8CoverageMap,
              instanceDisplayNameMap,
            )}
            <div>
              Click{' '}
              <span
                onClick={() => this.handleOnAnnotationClick(annos)}
                style={{ color: 'var(--blue)', cursor: 'pointer', textDecoration: 'underline' }}
              >
                here
              </span>{' '}
              to see log entries around this anomaly.
            </div>
          </>
        }
      >
        <div
          className={!hasInstanceDown ? 'anno' : ''}
          style={{ position: 'absolute', left: x, lineHeight: '12px', height: 12, borderColor: backgroundColor }}
        >
          {!hasInstanceDown && <div className="indicator" />}
          {!!hasInstanceDown && (
            <WarningOutlined
              style={{ fontSize: 12, fontWeight: 900, color: '#FF5142', stroke: '#FF5142', strokeWidth: '36px' }}
            />
          )}
        </div>
      </Popover>
    );
  }

  @autobind
  handleSelectMetricChange(selectMetricList) {
    // put added metrics to the top
    const { selectMetricList: prevMetricList } = this.state;
    const addMetrics = R.difference(selectMetricList, prevMetricList);
    const newMetricList = [
      ...R.filter((metric) => addMetrics.includes(metric), selectMetricList),
      ...R.filter((metric) => !addMetrics.includes(metric), selectMetricList),
    ];

    this.setState({
      selectMetricList: newMetricList,
    });
  }

  @autobind
  reloadInstanceMetaInfo() {
    const { metricObj } = this.props;
    const { selectMetricList, selectInstanceList, instancePageIndex, metricDatasetMap } = this.state;
    const needReloadMetrics = R.difference(selectInstanceList, R.keys(metricObj)).length > 0;

    if (needReloadMetrics) {
      this.hideReloadTip = true;
      this.setState({ selectInstanceList });
      this.handleReloadInstanceMetaInfo(selectInstanceList, selectMetricList, instancePageIndex, metricDatasetMap);
    } else {
      this.setState({ metricReloading: true }, () => {
        setTimeout(() => {
          this.setState({ metricReloading: false });
        }, 500);
      });
    }
  }

  @autobind
  handleSelectInstanceChange(selectInstanceList) {
    const { anomalyInstanceMetricMap, metricObj, allMetricInfoList } = this.props;
    const { selectMetricList } = this.state;

    // set needReloadMetrics=True if some instance is not in instance meta infos
    const needReloadMetrics = R.difference(selectInstanceList, R.keys(metricObj)).length > 0;
    if (needReloadMetrics) {
      this.hideReloadTip = true;
      this.setState({ selectInstanceList });
    } else {
      const anomalyMetricByInstances = R.uniq(
        R.reduce(R.concat, [], R.values(R.pick(selectInstanceList, anomalyInstanceMetricMap))),
      );
      const filterMetricList = R.uniq(
        R.reduce(
          R.concat,
          [],
          R.map((ci) => get(metricObj, ci, []), selectInstanceList),
        ),
      );
      let filterMetricInfoList = R.filter((m) => filterMetricList.indexOf(m.id) >= 0, allMetricInfoList);
      filterMetricInfoList = [
        ...R.filter((item) => ifIn(item.id, anomalyMetricByInstances), filterMetricInfoList),
        ...R.filter((item) => !ifIn(item.id, anomalyMetricByInstances), filterMetricInfoList),
      ];
      const newMetricList = R.filter((m) => filterMetricList.includes(m), selectMetricList);
      this.setState({
        selectInstanceList,
        currentAllMetricList: filterMetricInfoList,
        metricPageIndex: 1,
        selectMetricList: newMetricList,
      });
    }
  }

  @autobind
  handleReloadInstanceMetaInfo(selectInstanceList, selectMetricList, instancePageIndex, metricDatasetMap) {
    const { location, project, createLoadAction } = this.props;
    const params = parseLocation(location);
    const { projectName, instanceGroup } = params;

    createLoadAction(
      ActionTypes.LOAD_METRIC_EVENT_LINECHARTS_INSTANCE_META_DATA,
      {
        projectName,
        instanceGroup: instanceGroup || 'All',
        isContainerProj: get(project, ['isContainer'], false),

        metricDatasetMap,
        instancePageIndex,
        selectInstanceList: R.filter((i) => !i.startsWith('____'), selectInstanceList),
        selectMetricList,
        metricPageSize: this.metricPageSize,
      },
      this.instanceInfoLoader,
      false,
      this.callbackHandleInstancePagging,
    );
  }

  @autobind
  callbackHandleInstancePagging() {
    this.hideReloadTip = false;
  }

  @autobind
  handleRefreshClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { selectMetricList, selectInstanceList, withBaseline } = this.state;
    this.setState(
      {
        withBaseline,
        timeChange: false,
        metricList: selectMetricList || [],
        instanceList: selectInstanceList || [],
        onlyFetchBaseline: false,
      },
      () => {
        this.hideReloadTip = true;
        this.reloadData(this.props, true, true);
      },
    );
  }

  @autobind
  renderListItem({ displayMetricList, isCustomData, customerName, width, height }) {
    const {
      intl,
      projectInstanceComponentMap,
      columnInfoMap,
      metricEventListMap,
      latestDataTime,
      currentTimestamp,
      project,
      k8CoverageMap,
      instanceDisplayNameMap,
    } = this.props;
    const cardWidth = Math.floor((width - 17) / 2);
    const cardHeight = 255;
    const { metricDatasetMap, instanceList } = this.state;
    const { chartDateWindow, view, incidentInfo } = this.state;
    const { datasetStartTime, datasetEndTime } = this.state;

    const isListView = view === 'list';
    const { projectName, samplingIntervalInSecond, owner, systemId } = project;

    // reset cahrt data window by latestDataTime
    const currentStartTime = moment.utc().startOf('day').valueOf();
    if (latestDataTime > currentStartTime && latestDataTime < chartDateWindow[1]) {
      chartDateWindow[1] = latestDataTime + 2 * 60 * 1000;
    }

    const renderMetric = (metric, lineChartWidth) => {
      const mds = get(metricDatasetMap, metric);
      if (mds) {
        const { name, unit, labels, data, rootCauseMap, predictionlabelMap } = mds;
        const labelsVisibility = R.map(
          (label) => {
            const info = columnInfoMap[label];
            return info ? (instanceList || []).includes(info.instanceId) : true;
          },
          R.filter((label) => label !== 'timestamp', labels),
        );

        // merge metric annos
        const annotationTimeMap = {};
        const elist = metricEventListMap[metric] || [];
        R.forEach((event) => {
          const { isPrediction, startTimestamp, endTimestamp } = event;
          const { rootCauseJson } = event;
          let { rootCauseDetailsArr } = rootCauseJson;
          rootCauseDetailsArr = R.filter(
            (item) => item.rootCauseMetric === metric && R.indexOf(item.instanceId, instanceList) >= 0,
            rootCauseDetailsArr || [],
          );

          // push root cause to annotations
          R.forEach((rc) => {
            const timeRangeList = rc.timeRangeList || [];
            if (timeRangeList.length > 0) {
              const rootCauseStartTime =
                timeRangeList[0].startTimestamp - (rc.needHighlightPading ? samplingIntervalInSecond * 1000 : 0);

              if (!R.has(rootCauseStartTime, annotationTimeMap)) {
                annotationTimeMap[rootCauseStartTime] = [];
              }
              annotationTimeMap[rootCauseStartTime].push({
                ...rc,

                // event info
                isPrediction,
                startTimestamp,
                endTimestamp,
              });
            }
          }, rootCauseDetailsArr);
        }, elist);
        const annotations = [];
        R.forEachObjIndexed((annos, timestamp) => {
          annotations.push({
            timestamp: Number(timestamp),
            annos,
          });
        }, annotationTimeMap);
        if (latestDataTime > currentTimestamp) {
          annotations.push({
            timestamp: Number(currentTimestamp),
            isCurrent: true,
          });
        }
        if (incidentInfo) {
          annotations.push({
            timestamp: Number(incidentInfo.startTimestamp),
            isRootAnomaly: true,
            ...incidentInfo,
          });
        }

        // detaisl click
        const onClickSeparateInstance = () => {
          this.setState({
            showSeparateInstanceChartModal: true,
            activeMetricData: {
              currentTimestamp,
              columnInfoMap,
              samplingIntervalInSecond,
              name,
              unit,
              labels,
              data,
              predictionlabelMap,
              annotations,
              labelsVisibility,
              rootCauseMap,
              chartDateWindow,
              isCustomData,
              handleOnCreateAnnotation: this.handleOnCreateAnnotation,
            },
          });
        };

        return (
          <LineChart
            width={lineChartWidth}
            height={this.chartHeight}
            intl={intl}
            currentTimestamp={currentTimestamp}
            labelMapping={(node, x) => {
              const { instanceId } = get(columnInfoMap, [node], {});
              const component = get(projectInstanceComponentMap, [projectName, instanceId], '');

              const k8Coverage = k8CoverageMap[instanceId] || [];
              const findCoverage = R.find((item) => item[1]?.s <= x && x <= item[1]?.e, k8Coverage);
              let hostID = null;
              let podID = null;
              if (findCoverage) {
                hostID = findCoverage[0]?.h;
                podID = findCoverage[0]?.p;
              }
              return { instanceId, component, hostID, podID };
            }}
            samplingInterval={samplingIntervalInSecond * 1000}
            title={name}
            titlePostfix={unit}
            labels={labels}
            data={data}
            predictionlabelMap={predictionlabelMap}
            annotations={annotations}
            labelsVisibility={labelsVisibility}
            highlightMap={rootCauseMap}
            onCreateAnnotation={this.handleOnCreateAnnotation}
            dateWindow={chartDateWindow}
            datasetStartTime={datasetStartTime}
            datasetEndTime={datasetEndTime}
            onDateWindowChange={this.handleDateWindowSync}
            isCustomData={isCustomData}
            showSeparateInstance
            onClickSeparateInstance={onClickSeparateInstance}
            onCheckMetric={(checked) => this.handleCheckMetric(metric, checked)}
            projectName={projectName}
            owner={owner}
            systemId={systemId}
            instanceDisplayNameMap={instanceDisplayNameMap}
          />
        );
      }
      return (
        <div className="full-height flex-col" style={{ height: cardHeight }}>
          <div className="font-14 bold">{metric}</div>
          <div className="flex-grow flex-row flex-center-align flex-center-justify">
            <Empty />
          </div>
        </div>
      );
    };

    return (
      <div
        className="flex-row flex-wrap"
        style={{ width, minHeight: 240, position: 'relative', margin: '8px 8px 16px 8px' }}
      >
        {displayMetricList.map((metricKey, index) => {
          const metric = displayMetricList[index];
          const gridWidth = isListView ? width - 32 : cardWidth - 16;
          return metric && metricDatasetMap[metric] ? (
            <SortableItem index={index} key={metricKey}>
              <div
                style={{
                  width: gridWidth,
                  maxWidth: gridWidth,
                  minWidth: 300,
                  position: 'relative',
                  margin: 8,
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                  if (this.refInstanceSelection.current) this.refInstanceSelection.current.blur();
                  if (this.refMetricSelection.current) this.refMetricSelection.current.blur();
                }}
              >
                <Card
                  style={{
                    width: '100%',
                    paddingTop: 5,
                    height: cardHeight,
                    overflow: 'hidden',
                  }}
                  hoverable
                >
                  <div style={{ position: 'absolute', left: 4, top: 0 }}>
                    <DragHandle intl={intl} />
                  </div>
                  <div className="hover-visible" style={{ position: 'absolute', right: 0, top: 0 }}>
                    <Button type="link" icon={<CloseOutlined />} onClick={() => this.handleRemoveMetric(metric)} />
                  </div>
                  {renderMetric(metric, gridWidth - 50)}
                </Card>
              </div>
            </SortableItem>
          ) : (
            <div
              key={metricKey}
              className="full-height flex-col"
              style={{ height: cardHeight, width: gridWidth, margin: 8, background: 'white', padding: 20 }}
            >
              <div className="font-14 bold">{metric}</div>
              <div className="flex-grow flex-row flex-center-align flex-center-justify">
                <Empty />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  @autobind
  onSortEnd({ oldIndex, newIndex, displayMetricList }) {
    if (R.equals(oldIndex, newIndex)) return;
    this.setState({
      sortMetricList: R.move(oldIndex, newIndex, displayMetricList),
    });
  }

  @autobind
  onSwitch(checked, event) {
    const { withBaseline } = this.state;
    const newWithBaseline = !withBaseline;
    if (newWithBaseline) this.setState({ showProgressBar: true, onlyFetchBaseline: true, baseLinePercentage: 0 });
    this.setState({ withBaseline: newWithBaseline }, async () => {
      await sleep(800);
      this.handleWithBaselineClick(checked, event, newWithBaseline);
    });
  }

  @autobind
  handleWithBaselineClick(checked, event, newWithBaseline) {
    if (newWithBaseline) {
      this.reloadDataBaseline(this.props);
    } else {
      // clear baseline data
      this.parseBaselineData(this.props, true);
    }
  }

  @autobind
  getEntityTitle(type, title, instanceDisplayNameMap = {}) {
    const { instanceDisplayName } = getInstanceDisplayName(
      R.includes(type, ['instance', 'pod']) ? instanceDisplayNameMap : {},
      title,
    );

    if (!instanceDisplayName) {
      return title;
    }

    const instanceDisplayNameRender = (
      <div className="flex-row">
        <div className="bold" style={{ width: 140, flexShrink: 0 }}>
          Instance display name:
        </div>
        {instanceDisplayName}
      </div>
    );

    switch (type) {
      case 'pod':
        return (
          <div className="flex-col" style={{ maxHeight: 400, overflowY: 'auto' }}>
            <div className="flex-row">
              <div className="bold" style={{ width: 140, flexShrink: 0 }}>
                Pod name:
              </div>
              {title}
            </div>
            {instanceDisplayNameRender}
          </div>
        );
      case 'instance':
        return (
          <div className="flex-col" style={{ maxHeight: 400, overflowY: 'auto' }}>
            <div className="flex-row">
              <div className="bold" style={{ width: 140, flexShrink: 0 }}>
                Instance name:
              </div>
              {title}
            </div>
            {instanceDisplayNameRender}
          </div>
        );
      default:
        return title;
    }
  }

  render() {
    const { intl, location, loadStatus, projects, userList, isAdmin, isLocalAdmin, credentials, project, userInfo } =
      this.props;
    const { projectInstanceComponentMap, allInstanceInfoList } = this.props;

    const { anomalyInstanceList, hasDataMetricList, metricListWithNoData, k8CoverageMap, instanceDisplayNameMap } =
      this.props;
    const { instanceSelectionMap, metricSelectionMap, showRCAFlag } = this.state;
    const { anomalyMetricByInstances, currentAllMetricList, metricReloading, metricAnomalyMap } = this.state;

    const {
      withBaseline,
      timeChange,
      selectMetricList,
      selectInstanceList,
      metricList,
      instanceList,
      removedMetricList,
      sortMetricList,
      exportPDFing,
    } = this.state;
    const { isCustomData } = this.state;
    const { customerName, projectName, startTimestamp, endTimestamp } = parseLocation(location);
    let { defaultTimezone } = this.props;
    // use customer time zone to display data for admin
    const projectOwner = (projectName || '').split('@')[1];
    if (isAdmin && projectOwner) {
      const customerUserInfo = R.find((user) => user.userName === projectOwner, userList || []);
      defaultTimezone = customerUserInfo ? customerUserInfo.zone : defaultTimezone;
    } else if (projectOwner !== credentials.userName) {
      const timezone = get(project, ['timezone']);
      defaultTimezone = timezone || defaultTimezone;
    }

    const { view, eventPercentage, showProgressBar, onlyFetchBaseline, baseLinePercentage } = this.state;
    const totalLoadingPercentage = onlyFetchBaseline
      ? baseLinePercentage
      : withBaseline
      ? (eventPercentage + baseLinePercentage) / 2
      : eventPercentage;

    const displayMetricList = R.filter(
      (metric) => hasDataMetricList.includes(metric) && !removedMetricList.includes(metric),
      R.isEmpty(sortMetricList) ? metricList : sortMetricList,
    );

    // if has selected instance and metric, and new metric or instance select or still loading, then not allow reload
    const startTimeObj = moment.utc(parseInt(startTimestamp, 10));
    const endTimeObj = moment.utc(parseInt(endTimestamp, 10));
    const disableReload = endTimeObj < startTimeObj;
    const showReloadTip =
      !this.hideReloadTip &&
      !disableReload &&
      (timeChange ||
        R.symmetricDifference(selectInstanceList, instanceList).length > 0 ||
        R.symmetricDifference(selectMetricList, metricList).length > 0);

    const { isLoading } = getLoadStatus(get(loadStatus, this.lineChartsLoader), intl);
    const { isLoading: isLoadingInstanceInfo } = getLoadStatus(get(loadStatus, this.instanceInfoLoader), intl);

    const { hasJustList, showFilterPanel, componentMapLoading } = this.state;
    const isPod = project?.cloudType?.toLowerCase() === 'kubernetespod';
    const entityType = isPod ? 'Pod' : intl.formatMessage(appFieldsMessages.instance);
    return (
      <Container
        fullHeight
        withGutter
        className={`flex-col  metric-linecharts ${exportPDFing ? 'loading' : ''}`}
        id="linechart-container-parent"
      >
        <div id="linechart-container" className="flex-col full-height with-gutter metric-linecharts">
          <Container breadcrumb className="flex-row overflow-x-hidden" style={{ height: 70, paddingBottom: 0 }}>
            <div className="flex-grow flex-row">
              <div style={{ marginRight: 24 }}>
                <div style={{ lineHeight: '20px', fontSize: 14 }}>{`${intl.formatMessage(
                  appFieldsMessages.project,
                )} (${defaultTimezone})`}</div>
                <Select
                  showArrow={false}
                  showSearch
                  size="small"
                  style={{ width: 200 }}
                  value={projectName}
                  optionFilterProp="children"
                  onChange={this.handleProjectChange}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  dropdownMatchSelectWidth={false}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Select.Option key={item.projectName} value={item.projectName} title={item.projectName}>
                        {`${item.projectDisplayName}${
                          userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName
                            ? `@${item.owner}`
                            : ''
                        }${item.systemName ? ` (${item.systemName})` : ''}`}
                      </Select.Option>
                    );
                  }, projects)}
                </Select>
              </div>
              <div style={{ marginRight: 24 }}>
                <div
                  style={{
                    lineHeight: '20px',
                    fontSize: 14,
                  }}
                >
                  {intl.formatMessage(appFieldsMessages.date)}
                </div>
                <div className="flex-row">
                  <Tooltip mouseEnterDelay={0.3} placement="top" title="Previous Day">
                    <Button size="small" icon={<LeftOutlined />} onClick={this.handlePreviousDayClick} />
                  </Tooltip>
                  <DatePicker
                    size="small"
                    allowClear={false}
                    style={{ width: 110, margin: '0 2px' }}
                    value={startTimeObj}
                    placeholder="Start"
                    onChange={this.handleStartTimeChange}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                  />
                  <span style={{ margin: 'auto 4px', fontSize: 16 }}>~</span>
                  <DatePicker
                    size="small"
                    allowClear={false}
                    style={{ width: 110, margin: '0 2px' }}
                    value={endTimeObj}
                    placeholder="End"
                    onChange={this.handleEndTimeChange}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                  />
                  <Tooltip mouseEnterDelay={0.3} placement="top" title="Next Day">
                    <Button size="small" icon={<RightOutlined />} onClick={this.handleNextDayClick} />
                  </Tooltip>
                </div>
              </div>

              {(isAdmin || isLocalAdmin) && (
                <div style={{ marginRight: 24 }}>
                  <div
                    style={{
                      lineHeight: '20px',
                      fontSize: 14,
                    }}
                  >
                    {intl.formatMessage(appFieldsMessages.user)}
                  </div>
                  <div className="flex-row">
                    <Select
                      showSearch
                      size="small"
                      value={customerName}
                      style={{ width: 100 }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={this.handleCustomerNameChange}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.map(
                        (item) => (
                          <Select.Option key={item.userName} value={item.userName}>
                            {item.userName}
                          </Select.Option>
                        ),
                        userList || [],
                      )}
                    </Select>
                  </div>
                </div>
              )}
            </div>
            <div className="flex-row">
              <div style={{ width: 100 }}>
                <div style={{ lineHeight: '20px', fontSize: 14 }}>Show baseline</div>
                <div className="flex-row flex-center-align flex-center-justify" style={{ height: 24 }}>
                  <Popover placement="top" title={null} content="Show baseline requirements">
                    <Switch
                      size="middle"
                      checked={withBaseline}
                      style={{
                        background: withBaseline ? null : 'gray',
                        marginRight: 8,
                      }}
                      onChange={throttle(this.onSwitch, 300, { trailing: false, leading: true })}
                      disabled={disableReload || showProgressBar}
                    />
                  </Popover>
                </div>
              </div>
              <div style={{ marginRight: 24 }}>
                <div style={{ height: 20, fontSize: 14 }} />
                <div className="flex-row flex-center-align">
                  <Button.Group style={{ marginRight: 8 }}>
                    <Tooltip title="Export" placement="topRight">
                      <Button
                        icon={<DownloadOutlined />}
                        size="small"
                        disabled={disableReload}
                        onClick={this.exportData}
                      />
                    </Tooltip>
                  </Button.Group>
                  <div className="flex-row flex-center-align">
                    <Button.Group style={{ marginRight: 8 }}>
                      <Tooltip title="Export PDF" placement="topRight">
                        <Button
                          size="small"
                          icon={<PDFIcon style={{ color: 'white', fontSize: 18 }} />}
                          onClick={() => this.exportPDF(displayMetricList)}
                          Tooltip="Export PDF"
                          disabled={disableReload || showProgressBar}
                        />
                      </Tooltip>
                    </Button.Group>
                  </div>
                  <Popover
                    mouseEnterDelay={0.3}
                    placement="bottom"
                    visible={showReloadTip}
                    content={
                      showReloadTip ? (
                        <div style={{ width: 240 }}>Please click this button to reload metric line chart data</div>
                      ) : null
                    }
                  >
                    <Button
                      size="small"
                      type="primary"
                      icon={<ReloadOutlined />}
                      disabled={disableReload}
                      onClick={this.handleRefreshClick}
                    >
                      Reload
                    </Button>
                  </Popover>
                </div>
              </div>

              <div style={{ minWidth: 60 }}>
                <Radio.Group
                  value={view}
                  size="small"
                  style={{ marginTop: 20 }}
                  onChange={(event) => {
                    this.setState({ view: event.target.value }, () => {
                      this.cellMeasureCache.clearAll();
                      if (this.listNode) this.listNode.forceUpdateGrid();
                    });
                  }}
                >
                  <Tooltip title="List View" placement="top">
                    <Radio.Button value="list">
                      <UnorderedListOutlined />
                    </Radio.Button>
                  </Tooltip>
                  <Tooltip title="Grid View" placement="top">
                    <Radio.Button value="grid">
                      <AppstoreOutlined />
                    </Radio.Button>
                  </Tooltip>
                </Radio.Group>
              </div>
            </div>
          </Container>
          {showProgressBar && (
            <Container
              fullHeight
              className="flex-grow flex-col flex-center-align flex-center-justify"
              style={{ padding: 0 }}
            >
              <Progress type="circle" percent={totalLoadingPercentage} format={this.renderProgressBar} />
            </Container>
          )}

          {!showProgressBar && (
            <div className="flex-row full-height overflow-y-auto">
              <div
                className="flex-col full-height"
                style={{ width: showFilterPanel ? 330 : 0, padding: '0 0 16px 16px', position: 'relative' }}
              >
                <div
                  className="clickable"
                  style={{ position: 'absolute', right: -17, top: -3, fontSize: 18, color: 'gray', zIndex: 100 }}
                  onClick={() => {
                    if (!showFilterPanel) {
                      if (hasJustList) {
                        this.setState({ hasJustList: false, showFilterPanel: true }, () => {
                          this.reloadData(this.props, true);
                        });
                      } else {
                        this.setState({ showFilterPanel: true });
                      }
                    } else {
                      this.setState({ showFilterPanel: false });
                    }
                  }}
                >
                  <RightSquareOutlined rotate={showFilterPanel ? 180 : 0} />
                </div>
                <Card
                  style={{ width: '100%', height: '100%', display: showFilterPanel ? 'unset' : 'none' }}
                  bodyStyle={{ height: '100%', padding: 8, overflowY: 'auto' }}
                >
                  <div className="flex-col full-height">
                    <InstanceFilter
                      readonlyView
                      intl={intl}
                      cloudType={project?.cloudType}
                      allInstanceList={allInstanceInfoList || []}
                      instanceComponentMap={projectInstanceComponentMap?.[projectName] || {}}
                      anomalyInstanceList={anomalyInstanceList || []}
                      selectionMap={instanceSelectionMap || {}}
                      onChange={(selectionMap) => {
                        this.setState(
                          {
                            instanceSelectionMap: { ...selectionMap },
                            selectInstanceList: R.keys(selectionMap),
                          },
                          () => {
                            setTimeout(() => {
                              this.reloadInstanceMetaInfo();
                            }, 50);
                          },
                        );
                      }}
                      instanceDisplayNameMap={instanceDisplayNameMap}
                      getEntityTitle={this.getEntityTitle}
                    />
                    <Divider style={{ margin: '16px 0 8px 0' }} />
                    <MetricFilter
                      intl={intl}
                      cloudType={project?.cloudType}
                      allMetricList={currentAllMetricList}
                      anomalyList={anomalyMetricByInstances}
                      noDataList={metricListWithNoData}
                      selectionMap={metricSelectionMap}
                      loading={isLoadingInstanceInfo || metricReloading}
                      onChange={(selectionMap) => {
                        this.setState({
                          metricSelectionMap: { ...selectionMap },
                          selectMetricList: R.keys(selectionMap),
                        });
                      }}
                      onRefresh={() => {
                        this.reloadInstanceMetaInfo();
                      }}
                      getEntityTitle={this.getEntityTitle}
                    />
                    <Divider style={{ margin: '8px 0' }} />
                  </div>
                </Card>
              </div>
              <div className="flex-grow flex-col" style={{ marginTop: -16, marginLeft: 8 }}>
                <Container
                  fullHeight
                  className={`flex-grow flex-row overflow-y-auto overflow-x-hidden ${isLoading ? ' loading' : ''}`}
                >
                  <AutoSizer>
                    {({ width, height }) => {
                      return (
                        <SortableList
                          axis="xy"
                          helperClass="sortableHelperAntModal"
                          useDragHandle
                          onSortEnd={(params) => this.onSortEnd({ ...params, displayMetricList })}
                        >
                          {this.renderListItem({
                            displayMetricList,
                            isCustomData,
                            customerName,
                            width: width - 10,
                            height,
                          })}
                        </SortableList>
                      );
                    }}
                  </AutoSizer>
                </Container>
              </div>
            </div>
          )}
        </div>

        {this.state.showTimeSelectModal && (
          <LineChartTimeSelectModal
            projectName={projectName}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
            timeIntervals={1}
            noMetricProject
            globaInstaceMappingProject={this.state.globaInstaceMappingProject}
            loading={this.state.globaInstaceMappingProjectLoading}
          />
        )}
        {this.state.showPublishModal && (
          <EventPublishModal
            onClose={() => this.setState({ showPublishModal: false })}
            eventsNeurons={[]}
            instanceList={instanceList}
            metricList={metricList}
          />
        )}
        {this.state.showSeparateInstanceChartModal && (
          <SeparateInstanceChartModal
            projectName={projectName}
            activeMetricData={this.state.activeMetricData}
            k8CoverageMap={k8CoverageMap}
            onClose={() =>
              this.setState({
                showSeparateInstanceChartModal: false,
                activeMetricData: undefined,
              })
            }
            instanceDisplayNameMap={instanceDisplayNameMap}
            metricAnomalyMap={metricAnomalyMap}
            showRCAFlag={showRCAFlag}
            incidentInfo={this.state.incidentInfo}
          />
        )}
      </Container>
    );
  }
}

const MetricLineCharts = injectIntl(MetricLineChartsCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isLocalAdmin, isReadUser, userName } = state.auth.userInfo;
    const { loadStatus, projects, projectInstanceComponentMap, defaultTimezone, timezoneOffset } = state.app;
    const { eventLineChartData, eventLineChartBaselineData, eventLoadingPercentage, baseLineLoadingPercentage } =
      state.metric;
    const eventPercentage = eventLoadingPercentage.eventLoadingPercentage;
    const baseLinePercentage = baseLineLoadingPercentage.baseLineLoadingPercentage;
    const project = get(eventLineChartData, 'projectInfo', {});

    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);

    const appNameMapping = get(eventLineChartData, 'appNameMapping', {});
    const metricObj = get(eventLineChartData, 'metricObj', {});
    const allMetricInfoList = get(eventLineChartData, 'allMetricInfoList', []);
    const filterMetricInfoList = get(eventLineChartData, 'filterMetricInfoList', []);
    const allInstanceInfoList = get(eventLineChartData, 'allInstanceInfoList', []);
    const instanceList = get(eventLineChartData, 'instanceList', []);
    const metricList = get(eventLineChartData, 'metricList', []);
    const selectInstanceList = get(eventLineChartData, 'selectInstanceList', []);
    const selectMetricList = get(eventLineChartData, 'selectMetricList', []);
    const metricDatasetMap = get(eventLineChartData, 'metricDatasetMap', {});
    const metricEventListMap = get(eventLineChartData, 'metricEventListMap', {});
    const columnInfoMap = get(eventLineChartData, 'columnInfoMap', {});
    const datasetMetricList = get(eventLineChartData, 'datasetMetricList', []);
    const datasetInstanceList = get(eventLineChartData, 'datasetInstanceList', []);
    const hasDataMetricList = get(eventLineChartData, 'hasDataMetricList', []);
    const notExistInstances = get(eventLineChartData, 'notExistInstances', []);
    const metricListWithNoData = get(eventLineChartData, 'metricListWithNoData', []);
    const instancePageIndex = get(eventLineChartData, 'instancePageIndex', 1);
    const metricPageIndex = get(eventLineChartData, 'metricPageIndex', 1);
    const latestDataTime = get(eventLineChartData, 'latestDataTime', 0);
    const currentTimestamp = get(eventLineChartData, 'currentTimestamp');

    const anomalyInstanceList = R.uniq(R.filter((x) => Boolean(x), get(eventLineChartData, 'anomalyInstanceList', [])));
    const anomalyInstanceMetricMap = get(eventLineChartData, 'anomalyInstanceMetricMap', {});
    const anomalyMetricByInstances = R.uniq(
      R.filter((x) => Boolean(x), get(eventLineChartData, 'anomalyMetricByInstances', [])),
    );

    const k8CoverageMap = get(eventLineChartData, 'k8CoverageMap', {});
    const instanceDisplayNameMap = get(eventLineChartData, 'instanceDisplayNameMap', {});

    return {
      location,
      credentials,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      userName,
      loadStatus,
      userList,
      projects: R.filter((project) => project.isMetric, projects || []),
      projectInstanceComponentMap,
      defaultTimezone,
      timezoneOffset,
      project,
      eventLineChartData,
      eventLineChartBaselineData,
      eventPercentage,
      baseLinePercentage,
      allInstanceInfoList,
      selectInstanceList,
      columnInfoMap,
      instanceList,
      metricList,
      selectMetricList,
      metricDatasetMap,
      metricEventListMap,
      anomalyInstanceList,
      anomalyInstanceMetricMap,
      anomalyMetricByInstances,
      datasetMetricList,
      datasetInstanceList,
      hasDataMetricList,
      notExistInstances,
      metricListWithNoData,
      instancePageIndex,
      metricPageIndex,
      latestDataTime,
      currentTimestamp,

      appNameMapping,
      metricObj,
      allMetricInfoList,
      filterMetricInfoList,
      k8CoverageMap,
      userInfo,
      instanceDisplayNameMap,
    };
  },
  { push, replace, createLoadAction },
)(MetricLineCharts);
