/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import * as R from 'ramda';
import { createLogic } from 'redux-logic';

import { createLoadAction, updateLastActionInfo } from '../../app/actions';
import { ActionTypes } from '../actions';
import { ActionTypes as EventActionTypes } from '../../metric/actions';
import { getLoadStatusActions } from '../../utils';
import { postProjectActionsSetting } from '../../apis';
import { appMessages } from '../../app/messages';

const saveProjectActionsSettingLogic = createLogic({
  type: [ActionTypes.SAVE_PROJECT_ACTIONS],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    const {
      projectName,
      projectNameList,
      loadProjectSettingActions,
      loadEventActions,
      neuronId,
      needRecipients,
      rootCauseKey,
      startTime,
      endTime,
      systemId,
      customerName,
    } = params;
    postProjectActionsSetting(credentials, params)
      .then(() => {
        if (loadProjectSettingActions) {
          dispatch(createLoadAction(ActionTypes.LOAD_PROJECT_ACTIONS, { projectName, projectNameList }, loader));
        } else if (loadEventActions) {
          // callback function
          if (R.type(callback) === 'Function') {
            callback();
          }

          dispatch(
            createLoadAction(
              EventActionTypes.LOAD_EVENT_ACTION,
              {
                projectName,
                projectNameList,
                neuronId,
                operation: 'getAction',
                needRecipients,
                rootCauseKey,
                startTime,
                endTime,
                systemId,
                customerName,
              },
              loader,
            ),
          );
        } else {
          dispatch(hideLoading);
        }
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed save custom actions, ignored', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default saveProjectActionsSettingLogic;
