import React from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import { isFunction } from 'lodash';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Button, message, Popconfirm, Spin } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
// import { Defaults } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { SavedActionIcon } from '../../../lib/fui/icons';
import { Modal } from '../../../lib/fui/react';

import { appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';

type Props = {
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  credentials: Object,

  // eslint-disable-next-line
  allRootCauseAndPredictList: Array<Object>,
  // eslint-disable-next-line
  rootCauseKey: String,
  incident: Object,
  isLoadingRootCause: Boolean,
  reloadRootCause: Function,
  onConfirm: Function,
  onClose: Function,
};

class RootCauseChainSelectModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { rootCauseKey, allRootCauseAndPredictList } = props;

    this.state = {
      isSubmitting: false,
      allRootCauseAndPredictList: allRootCauseAndPredictList || [],

      activeKeys: rootCauseKey ? [rootCauseKey] : [],
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.allRootCauseAndPredictList !== nextProps.allRootCauseAndPredictList) {
      this.setState({ allRootCauseAndPredictList: nextProps.allRootCauseAndPredictList || [] });
    }
  }

  @autobind
  handleConfirm() {
    const { onConfirm } = this.props;
    const { allRootCauseAndPredictList, activeKeys } = this.state;

    const chianslist = R.filter((item) => activeKeys.includes(item.rootCauseKey), allRootCauseAndPredictList);
    const actionRootCauseKey = JSON.stringify(R.map((item) => item.actionRootCauseKey, chianslist));
    onConfirm(actionRootCauseKey);
  }

  @autobind
  handleClearAction() {
    const { intl, credentials, reloadRootCause, incident } = this.props;

    this.setState({ isSubmitting: true });
    const { projectName, anomalyLogInstance, instanceName } = incident;
    const nid = incident.nid || incident.neuronId || incident.patternId;
    const eventType = incident.type
      ? incident.type.charAt(0).toLowerCase() + incident.type.substring(1)
      : incident.eventType;

    this.props.updateLastActionInfo();
    fetchPost(
      getEndpoint('userAction', 1),
      {
        ...credentials,
        operation: 'removeAllAction',
        projectName,
        neuronId: nid,
        instanceName: anomalyLogInstance || instanceName,
        eventType,
      },
      {},
      false,
    )
      .then((d) => {
        if (isFunction(reloadRootCause)) {
          reloadRootCause();
        }
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  renderRootCauseChains(rowData, index) {
    const { intl, incident, rootCauseKey: hightLightKey } = this.props;
    const { activeKeys } = this.state;
    const { operation, rootCauseKey, hasAction, probability, count, relevance, rootCausePatterns } = rowData;

    const isSelect = hightLightKey === rootCauseKey;
    const isRootCause = operation === 'rootCauseEvents';
    return (
      <div
        key={index}
        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''} ${isSelect ? ' active' : ''}`}
        style={{ minHeight: 40 }}
      >
        <div className="row-column flex-center-justify" style={{ width: 40 }}>
          <Checkbox
            checked={activeKeys.indexOf(rootCauseKey) !== -1}
            onChange={(e) => {
              const checked = e.target.checked || false;
              let newActiveKeys = R.clone(activeKeys);
              if (checked) {
                newActiveKeys.push(rootCauseKey);
              } else {
                newActiveKeys = R.filter((item) => item !== rootCauseKey, newActiveKeys);
              }
              this.setState({ activeKeys: newActiveKeys });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 120, flex: 1, padding: '8px 6px', lineHeight: '12px' }}>
          <div className="full-width flex-col">
            <div>
              <span>
                {isRootCause ? `Root cause chain, rank: ${index + 1}` : `Predicted chain, rank: ${index + 1}`}
              </span>
            </div>
            <div className="flex-row" style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold">{intl.formatMessage(appFieldsMessages.prob)}:</span>
              <span style={{ margin: '0 8px 0 4px' }}>{numeral(probability).format('0.0%')}</span>
              <span className="light-label bold">{intl.formatMessage(appFieldsMessages.count)}:</span>
              <span style={{ margin: '0 8px 0 4px' }}>{count}</span>
              <span className="light-label bold">{intl.formatMessage(appFieldsMessages.relevance)}:</span>
              <span style={{ margin: '0 8px 0 4px' }}>{relevance}</span>
            </div>
            <div className="flex-row" style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold" style={{ wordBreak: 'keep-all' }}>
                {intl.formatMessage(appMenusMessages.patterns)}:
              </span>
              <span style={{ margin: '0 8px 0 4px' }}>
                {R.join(
                  '->',
                  isRootCause
                    ? [...rootCausePatterns, `${incident.patternId} (Project: ${incident.projectDisplayName})`]
                    : [`${incident.patternId} (Project: ${incident.projectDisplayName})`, ...rootCausePatterns],
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="row-column" style={{ width: 100 }}>
          {hasAction && <SavedActionIcon style={{ color: '#037AEF', fontSize: 16 }} />}
        </div>
      </div>
    );
  }

  render() {
    const { intl, onClose, allRootCauseAndPredictList, isLoadingRootCause } = this.props;
    const { isSubmitting, activeKeys } = this.state;
    const allKeys = R.map((item) => item.rootCauseKey, allRootCauseAndPredictList);
    const checkAll = R.difference(allKeys, activeKeys).length === 0;

    return (
      <Modal
        title="Select root cause chains"
        width={850}
        visible
        onCancel={() => onClose()}
        onOk={this.handleConfirm}
        okButtonProps={{ disabled: activeKeys.length === 0 }}
      >
        <div className="flex-row" style={{ marginBottom: 12 }}>
          <div className="flex-grow" />

          <Popconfirm
            placement="topRight"
            title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
            onConfirm={() => this.handleClearAction()}
            onCancel={(event) => event.stopPropagation()}
          >
            <Button
              size="small"
              className="button-color-grey"
              icon={<DeleteOutlined />}
              loading={isSubmitting}
              onClick={(event) => event.stopPropagation()}
            >
              Clear actions
            </Button>
          </Popconfirm>
        </div>

        <Spin spinning={isLoadingRootCause} wrapperClassName="">
          <div className="event-list overflow-y-auto" style={{ height: 260 }}>
            <div className="event-list-header" style={{ height: 30 }}>
              <div className="header-column flex-center-justify" style={{ width: 40 }}>
                <Checkbox
                  style={{ marginLeft: 6 }}
                  checked={checkAll}
                  onChange={(e) => {
                    const checked = e.target.checked || false;
                    if (checked) {
                      this.setState({ activeKeys: allKeys });
                    } else {
                      this.setState({ activeKeys: [] });
                    }
                  }}
                />
              </div>
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(appFieldsMessages.details)}
              </div>
              <div className="header-column" style={{ width: 100 }}>
                Saved action
              </div>
            </div>
            <div className="event-list-grid" style={{ borderTopWidth: 1 }}>
              {R.addIndex(R.map)(
                (rowData, index) => this.renderRootCauseChains(rowData, index),
                R.filter((item) => item.operation === 'rootCauseEvents', allRootCauseAndPredictList || []),
              )}
              {R.addIndex(R.map)(
                (rowData, index) => this.renderRootCauseChains(rowData, index),
                R.filter((item) => item.operation === 'predictedEvents', allRootCauseAndPredictList || []),
              )}
            </div>
          </div>
        </Spin>
      </Modal>
    );
  }
}

const RootCauseChainSelectModal = injectIntl(RootCauseChainSelectModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials } = state.auth;
    return {
      location,
      loadStatus,
      credentials,
    };
  },
  { push, replace, updateLastActionInfo },
)(RootCauseChainSelectModal);
