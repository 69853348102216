/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line max-classes-per-file
import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { debounce, escapeRegExp, get, isEmpty, isNumber, isObject, isString } from 'lodash';
import { autobind } from 'core-decorators';
import {
  Menu,
  Pagination,
  Skeleton,
  Spin,
  Tooltip as AntdTooltip,
  Input,
  Button,
  Tag,
  Popover,
  Select,
  message,
  Divider,
} from 'antd';
import {
  RightOutlined,
  DownOutlined,
  UpOutlined,
  LoadingOutlined,
  UnorderedListOutlined,
  PlusOutlined,
  MinusOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  DoubleLeftOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { InfiniteLoader } from 'react-virtualized';

import momenttz from 'moment-timezone';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Dropdown,
  Scrollbars,
  SortDirection,
  Table,
  Tooltip,
} from '../../../src/lib/fui/react';
import {
  buildUrl,
  CellRenderers,
  compareJSON,
  Defaults,
  highlightContent,
  LogParser,
  LogRenderers,
  parseJSON,
} from '../../../src/common/utils';
import { BaseUrls } from '../../../src/web/app/Constants';
import { logMessages } from '../../../src/common/log/messages';
import { eventMessages } from '../../../src/common/metric/messages';
import { appFieldsMessages, appMessages } from '../../../src/common/app/messages';

// eslint-disable-next-line import/no-cycle
import EventContextModal from './EventContextModal';
import SummarySettingsMode from './SummarySettingsMode';
import TimeSelectModal from '../../../src/web/metric/components/TimeSelectModal';
import TakeEventTriageModal from '../../incidents/TakeEventTriageModal';
import EventEmailAlertsModal from '../../../src/web/metric/components/EventEmailAlertsModal';
import EventActionModal from '../../../src/web/metric/components/EventActionModal';
import IgnoreModal from '../../../src/web/metric/components/IgnoreModal';
import ExternalSystemJumpModal from './ExternalSystemJumpModal';
import {
  DEFAULT_EXPAND_DURATION,
  durationRender,
  getDurationMs,
  getExpandDurations,
  getDurationText,
} from '../../../src/common/utils/DurationUtils';
import getEndpoint from '../../../apis/get-endpoint';
import fetchGet from '../../../src/common/apis/fetchGet';
import fetchPostJson from '../../../src/common/apis/fetchPostJson';
import fetchPost from '../../../src/common/apis/fetchPost';

import { WebSocketContext } from '../../../src/web/app/WebSocketMiddleware';
import { Root } from '../../../src/protos/js/Root_pb';
import getInstanceDisplayName from '../../../src/common/utils/getInstanceDisplayName';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

const isJsonMessage = (msg) => {
  let isJson = false;
  try {
    const rawDataJson = JSON.parse(msg);
    if (isObject(rawDataJson)) {
      isJson = true;
    }
  } catch (ex) {
    // ignore error
  }
  return isJson;
};

type Props = {
  credentials: Object,
  projectName?: String,
  minHeight: Number,
  refeshTime: Number,
  highlightWord: String,
  eventDataset: Array<Object>,
  totalCount: Number,
  pageSize: Number,
  pageNo: Number,
  onPageChanged: Function,
  onNameChanged: Function,
  combineLabels: Boolean,
  combineLabelsHiddenOverflow: Boolean,
  hasRankNumber: Boolean,
  hasDatetime: Boolean,
  hasIgnored: Boolean,
  hasInstance: Boolean,
  hasType: Boolean,
  hasInstanceName: Boolean,
  hasCategory: Boolean,
  hasTag: Boolean,
  hasNid: Boolean,
  hasCount: Boolean,
  hasProbability: Boolean,
  hasDelay: Boolean,
  hasPatternAction: Boolean,
  hasContext: Boolean,
  hasDecompress: Boolean,
  clusterSampleMsgs: Object,
  highlightPages: Array<Number>,
  highlightRow: Object,
  isContext: Boolean,
  showSimpleRawData: Boolean,
  showHighlightCategory: Boolean,
  showRawDataFrequency: Boolean,
  showRangeTime: Boolean,
  disableSortDateTime: Boolean,
  showRCAJump: Boolean,
  showLinkedJump: Boolean,
  showExternalJump: Boolean,
  showLogAnalysisJump: Boolean,
  isCritical: Boolean,
  filterWord: String,

  loadMoreRows?: Function,
  isInfiniteLoad: Boolean,
  startTime?: Number,
  endTime?: Number,
  initExpandDuration?: String,

  intl: Object,
  projects: Array<Object>,
  isReadUser: Boolean,

  summarySettings: Array,
  enableJsonSummary?: Boolean,
  currentTheme: String,
  instanceList: Array,
  extra: Boolean,
  useTimeRange: Boolean,
  initLoad: Boolean,
  getLogTailData: Function,
  isLiveTail: Boolean,
  isManualLoad: Boolean,
  handleChangeTableKey: Function,
  unSubscribeLogTailData: Function,
};

class EventTableCore extends React.PureComponent {
  props: Props;

  static contextType = WebSocketContext;

  constructor(props) {
    super(props);

    const { minHeight, highlightWord, eventDataset, intl, clusterSampleMsgs, instanceList, extra } = props;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: minHeight || 40,
    });

    this.extendDurations = getExpandDurations(intl);
    this.expandedStateKey = '__expanded_state__';
    this.LOADING_ROW = {
      datetime: 0,
      timestamp: 0,
      instanceName: '',
      neuronId: null,
      patternName: null,
      patternId: null,
      length: 0,
      nid: null,
      index: 999999999999,
      rawData: '',
      eventType: '',
      logDiffOperation: {},
      rawDataBackup: '',
      oldRawData: '',
      diffMap: {},
      decompress: true,
      typeAndColor: [],
      isLastRow: true,
    };
    this.LOADING_FIRST_ROW = {
      datetime: 0,
      timestamp: 0,
      instanceName: '',
      neuronId: null,
      patternName: null,
      patternId: null,
      length: 0,
      nid: null,
      index: 999999999999,
      rawData: '',
      eventType: '',
      logDiffOperation: {},
      rawDataBackup: '',
      oldRawData: '',
      diffMap: {},
      decompress: true,
      typeAndColor: [],
      isFirstRow: true,
    };

    this.state = {
      showContextModal: false,
      showTakeLogActionModal: false,
      actionName: null,
      showEmailAlertsModal: false,
      showEventActionModal: false,
      showTimeSelectModal: false,
      showIgnoreModal: false,
      showExternalSystemJumpModal: false,

      selectStartTimestamp: null,
      selectEndTimestamp: null,
      contextTime: 60 * 1000,
      contextKeywordFilter: '', // eslint-disable-next-line react/no-unused-state
      highlightWord,

      sortBy: null,
      sortDirection: null,

      infiniteLoading: false,
      currentExpandDurationStart: props.initExpandDuration || DEFAULT_EXPAND_DURATION,
      currentExpandDurationEnd: props.initExpandDuration || DEFAULT_EXPAND_DURATION,
      currentStartTime: props?.endTime,
      currentEndTime: props?.endTime,
      summarySettingsModelFlag: false,
      summarySettings: [],
      jsonFields: [],
      keywordCurrentIndex: 0,
      keywordMatchCount: 0,
      keywordFilter: null,
      instanceName: extra ? instanceList[1] : instanceList[0],
      instanceInComponentMap: {},
      instanceDisplayNameMap: {},
      expandAll: true,
      isLoadingPrevious: false,
      initLoad: true,
      isPause: false,

      selectedItem: null,

      summaryLoading: false,
    };
    this.legendHeight = 16;
    this.orangeColor = '#f2711c';
    this.redColor = '#db2828';
    this.greyColor = '#9d9d9d';
    this.eventList = eventDataset || [];
    this.clusterSampleMsgs = clusterSampleMsgs;
    this.parseData(props);
    this.allParsedEventList = this.eventList;
    this.showActions =
      props.hasPatternAction ||
      props.hasContext ||
      props.showRCAJump ||
      props.showLinkedJump ||
      props.showExternalJump ||
      props.showLogAnalysisJump;
    this.labelsWidth = 270;
    this.delayTimeId = null;
    this.delayShowData = [];
    this.pauseTime = null;
    this.pauseUnSubscribeTime = null;

    this.contentWidthOffset = 0;
    this.contentWidthOffset = props.hasRankNumber ? this.contentWidthOffset + 50 : this.contentWidthOffset;
    this.contentWidthOffset = props.hasDatetime ? this.contentWidthOffset + 130 : this.contentWidthOffset;
    this.contentWidthOffset =
      props.hasTag || props.hasDecompress ? this.contentWidthOffset + 100 : this.contentWidthOffset;
    if (props.combineLabels) {
      this.contentWidthOffset += this.labelsWidth;
    } else {
      this.contentWidthOffset = props.hasInstance ? this.contentWidthOffset + 150 : this.contentWidthOffset;
      this.contentWidthOffset = props.hasType ? this.contentWidthOffset + 100 : this.contentWidthOffset;
      this.contentWidthOffset = props.hasInstanceName ? this.contentWidthOffset + 120 : this.contentWidthOffset;
      this.contentWidthOffset = props.hasCategory ? this.contentWidthOffset + 100 : this.contentWidthOffset;
      this.contentWidthOffset = props.hasNid ? this.contentWidthOffset + 100 : this.contentWidthOffset;
      this.contentWidthOffset = props.hasCount ? this.contentWidthOffset + 70 : this.contentWidthOffset;
      this.contentWidthOffset = props.hasProbability ? this.contentWidthOffset + 80 : this.contentWidthOffset;
      this.contentWidthOffset = props.hasDelay ? this.contentWidthOffset + 80 : this.contentWidthOffset;
    }
    this.contentWidthOffset = this.showActions ? this.contentWidthOffset + 120 : this.contentWidthOffset;
    this.loadMoreRowsGuard = false;
    this.keywordMatchIndics = [];
  }

  async componentDidMount() {
    this.getLogTailData();
    if (this.props.isInfiniteLoad && this.props.projectName) {
      this.loadProjectSettings(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Load summary settings for infinite loading.
    if (nextProps.isInfiniteLoad && this.props?.projectName !== nextProps?.projectName) {
      this.loadProjectSettings(nextProps);
    }

    if (
      this.props.eventDataset !== nextProps.eventDataset ||
      this.props.highlightWord !== nextProps.highlightWord ||
      this.props.filterWord !== nextProps.filterWord ||
      this.props.showHighlightCategory !== nextProps.showHighlightCategory
    ) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ highlightWord: nextProps.highlightWord });
      this.eventList = nextProps.eventDataset || [];
      this.parseData(nextProps);
      this.allParsedEventList = this.eventList;

      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdateGrid();
      }
    }
    if (this.props.refeshTime !== nextProps.refeshTime) {
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdateGrid();
      }
    }
    if (this.props.endTime !== nextProps.endTime) {
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdateGrid();
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        this.allParsedEventList = R.sortWith([R.ascend(R.prop(sortBy))])(this.allParsedEventList);
        if (sortDirection === SortDirection.DESC) {
          this.allParsedEventList = R.sortWith([R.descend(R.prop(sortBy))])(this.allParsedEventList);
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.delayTimeId);
    clearTimeout(this.pauseUnSubscribeTime);
    this.delayTimeId = null;
    this.delayShowData = [];
    this.pauseTime = null;
    this.pauseUnSubscribeTime = null;
  }

  @autobind
  async getLogTailData() {
    const { projectName, projects } = this.props;
    const { instanceName } = this.state;
    const { subscribeTopic } = this.context;
    const { projectShortName } = R.find((project) => projectName === project.projectName, projects || []) || {};

    if (this.props.getLogTailData) {
      const root = await this.props.getLogTailData({ instanceName });
      subscribeTopic(`${Root.InnerMessageCase.LOGTAIL}-${projectShortName}-${instanceName}`, root, (data) =>
        this.parseLogTail(data),
      );
    }
  }

  @autobind
  parseLogTail(data) {
    const { expandAll, keywordCurrent } = this.state;
    const { totalMatched } = data || {};
    let { events } = data || {};
    events = R.map((event) => {
      const { timestamp, patternId, ...rest } = event;
      return {
        datetime: timestamp,
        timestamp,
        ...rest,
        nid: patternId,
      };
    }, events || []);
    if (totalMatched > events.length) {
      console.warn('[IF] page size is too small to retrieve all log entries in time range');
    }
    if (events.length === 0) {
      return;
    }
    this.setState({ isLoadingPrevious: true }, () => {
      this.eventList = R.addIndex(R.map)((e, idx) => {
        e[this.expandedStateKey] = expandAll;
        return e;
      }, events || []);
      this.parseData(this.props);
      if (keywordCurrent) {
        const matches = this.matchKeywordInEvents(this.eventList, keywordCurrent);
        if (matches.length > 0) {
          this.keywordMatchIndics = [...this.keywordMatchIndics, ...matches];
          this.setState({ keywordMatchCount: this.keywordMatchIndics.length });
        }
      }

      this.delayShowData = [...this.delayShowData, ...this.eventList];
      this.handleDelayData();

      this.setState({ isLoadingPrevious: false, initLoad: false }, () => {
        this.loadMoreRowsGuard = false;

        if (this.dataTable) {
          const tableDom = document.querySelector('#table-dom');
          const reactVirtualizedGrid = tableDom.querySelector('.ReactVirtualized__Grid');
          setTimeout(() => {
            this.dataTable.scrollToRow(this.allParsedEventList.length);
            if (reactVirtualizedGrid && reactVirtualizedGrid.scrollHeight > reactVirtualizedGrid.clientHeight) {
              reactVirtualizedGrid.scrollTop = reactVirtualizedGrid.scrollHeight;
            }
          }, 200);
        }
      });
    });
  }

  @autobind
  handleDelayData() {
    const { isPause } = this.state;
    if (!this.delayTimeId && !isPause) {
      this.delayTimeId = setInterval(() => {
        if (this.delayShowData.length === 0) {
          clearInterval(this.delayTimeId);
          this.delayTimeId = null;
          return;
        }
        let takeOutList = this.delayShowData.splice(0, 5);
        takeOutList = R.addIndex(R.map)((item, idx) => {
          if (idx === takeOutList.length - 1) {
            return { ...item, newLiveTail: true, [this.expandedStateKey]: this.state.expandAll };
          } else {
            return { ...item, [this.expandedStateKey]: this.state.expandAll };
          }
        }, takeOutList);

        if (this.dataTable && takeOutList.length > 0) {
          this.allParsedEventList = [
            ...R.map(
              (item) => ({ ...item, newLiveTail: false, [this.expandedStateKey]: this.state.expandAll }),
              this.allParsedEventList || [],
            ),
            ...takeOutList,
          ];

          this.cellMeasureCache.clearAll();
          this.dataTable.forceUpdateGrid();
          this.forceUpdate();
          const tableDom = document.querySelector('#table-dom');
          const reactVirtualizedGrid = tableDom.querySelector('.ReactVirtualized__Grid');
          this.dataTable.scrollToRow(this.allParsedEventList.length);
          if (reactVirtualizedGrid && reactVirtualizedGrid.scrollHeight > reactVirtualizedGrid.clientHeight) {
            reactVirtualizedGrid.scrollTop = reactVirtualizedGrid.scrollHeight;
          }
        }
      }, 600);
    }
  }

  @autobind
  handleSummarySettingsChanged(settings) {
    const { projectName, credentials } = this.props;
    this.setState({ summaryLoading: true });
    fetchPostJson(getEndpoint('logsummarysettings'), { ...credentials, projectName }, settings)
      .then((res) => res.json())
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          message.success(msg);
          this.setState({ summarySettings: settings, summaryLoading: false }, () => {
            this.cellMeasureCache.clearAll();
            if (this.dataTable) {
              this.dataTable.forceUpdateGrid();
            }
          });
        } else {
          this.setState({ summaryLoading: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        this.setState({ summaryLoading: false });
        message.error(err.message || String(err));
      });
  }

  @autobind async loadProjectSettings(props) {
    const { projectName, isInfiniteLoad, credentials, instanceList, intl, projects } = props;
    const { currentStartTime, currentEndTime } = this.state;
    if (isInfiniteLoad && projectName) {
      const projectInfo = R.find((project) => projectName === project.projectName, projects || []) || {};
      const instanceInComponentMap = {};
      if (currentStartTime && currentEndTime) {
        await fetchGet(getEndpoint('fetchloginstancewithdata'), {
          ...credentials,
          projectName,
          startTime: currentStartTime,
          endTime: currentEndTime,
        })
          .then((d) => {
            const { success } = d || {};
            if (success || success === undefined) {
              R.forEachObjIndexed((c, i) => {
                instanceInComponentMap[i] = c || '';
              }, d || {});
            }
          })
          .catch((err) => {
            message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          });
      }

      const instanceDisplayNameMap = {};
      await fetchGet(getEndpoint('instance-display-name'), {
        ...credentials,
        instanceDisplayNameRequestList: JSON.stringify([
          { projectName: projectInfo?.projectShortName, customerName: projectInfo?.owner },
        ]),
      })
        .then((d1) => {
          R.forEach((item) => {
            const [pInfo, iList] = item || [];
            const { projectName, customerName } = pInfo || {};
            R.forEach((instanceInfo) => {
              const { instanceSet, instanceDisplayName } = instanceInfo || {};
              R.forEach((instance) => {
                instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
                instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
              }, instanceSet || []);
            }, iList || []);
          }, d1 || []);
        })
        .catch((err) => {
          message.error(err.message || String(err));
        });

      const summarySettings = await fetchGet(getEndpoint('logsummarysettings'), {
        ...credentials,
        projectName,
      });
      const fieldNameOptions = await fetchGet(getEndpoint('logjsontype'), {
        ...credentials,
        projectName,
      });

      const jsonFields = R.uniq(R.map((f) => f.jsonKey, fieldNameOptions));

      this.setState({ summarySettings, jsonFields, instanceInComponentMap, instanceDisplayNameMap });
    }
  }

  @autobind handleExtendDurationClick(duration, key) {
    this.setState({ [key]: duration }, async () => {
      await this.loadMoreRows({ isUpLoader: key === 'currentExpandDurationStart' });
    });
  }

  @autobind
  async handleDurationClick(key) {
    await this.loadMoreRows({ isUpLoader: key === 'currentExpandDurationStart' });
  }

  @autobind async loadMoreRows({ isUpLoader = false }) {
    const { isInfiniteLoad, loadMoreRows, projectName, useTimeRange, isLiveTail, isManualLoad } = this.props;
    const { instanceName, expandAll, selectedItem } = this.state;

    if (!isInfiniteLoad || !loadMoreRows || this.loadMoreRowsGuard) {
      return Promise.resolve();
    }

    this.loadMoreRowsGuard = true;

    const { currentStartTime, currentExpandDurationStart, currentExpandDurationEnd, currentEndTime, initLoad } =
      this.state;
    const newState = { infiniteLoading: true, currentStartTime, isLoadingPrevious: isUpLoader };
    let startTime = null;
    let endTime = null;
    if (isUpLoader) {
      startTime = currentStartTime - getDurationMs(currentExpandDurationStart);
      endTime = currentStartTime;
      newState.currentStartTime = startTime;
    } else {
      startTime = currentEndTime;
      endTime = currentEndTime + getDurationMs(currentExpandDurationEnd);
      const endTimeNow = this.getLocalEndTime(this.props, { projectName }, null).valueOf();
      endTime = endTime < endTimeNow ? endTime : endTimeNow;
      newState.currentEndTime = endTime;
    }
    if ((initLoad || this.props.initLoad) && useTimeRange && this.props?.startTime && this.props?.endTime) {
      startTime = this.props?.startTime;
      endTime = this.props?.endTime;
      newState.currentStartTime = startTime;
      newState.currentEndTime = endTime;
    }

    this.setState(newState, async () => {
      await this.loadProjectSettings(this.props);
      let scrollTopIndex = null;
      try {
        const { succeed, events, clusterSampleMsgs, scrollIndex } = await loadMoreRows(
          startTime,
          endTime,
          instanceName,
          isUpLoader,
        );
        // If infinite load, append the new event list to previous ones.
        if (succeed) {
          this.eventList = R.map((e) => {
            e[this.expandedStateKey] = expandAll;
            e.isExpand = expandAll;
            return e;
          }, events || []);
          this.clusterSampleMsgs = clusterSampleMsgs;
          this.parseData(this.props);
          const { keywordCurrent } = this.state;
          if (keywordCurrent) {
            const matches = this.matchKeywordInEvents(this.eventList, keywordCurrent);
            if (matches.length > 0) {
              this.keywordMatchIndics = [...this.keywordMatchIndics, ...matches];
              this.setState({ keywordMatchCount: this.keywordMatchIndics.length });
            }
          }
          this.allParsedEventList = [...this.allParsedEventList, ...this.eventList];
          const { sortBy, sortDirection } = this.state;
          if (sortBy) {
            this.allParsedEventList = R.sortWith([R.ascend(R.prop(sortBy))])(this.allParsedEventList);
            if (sortDirection === SortDirection.DESC) {
              this.allParsedEventList = R.sortWith([R.descend(R.prop(sortBy))])(this.allParsedEventList);
            }
          } else {
            this.allParsedEventList = R.sortWith([R.ascend(R.prop('datetime'))])(this.allParsedEventList);
          }

          this.cellMeasureCache.clearAll();
          if (this.dataTable && !isLiveTail) {
            this.dataTable.forceUpdateGrid();
            if (isUpLoader) {
              setTimeout(() => {
                if (selectedItem && isManualLoad) {
                  const idx = R.findIndex((item) => this.getItemKey(item, selectedItem), this.allParsedEventList);
                  if (idx) {
                    this.dataTable.scrollToRow(idx);
                  }
                } else if (events.length > 0) {
                  this.dataTable.scrollToRow(events.length);
                }
                this.dataTable.forceUpdateGrid();
              }, 200);
            }
            if (scrollIndex) {
              scrollTopIndex = scrollIndex;
              setTimeout(() => {
                this.dataTable.scrollToRow(scrollIndex);
              }, 200);
            }
          }
        }
      } catch (e) {
        // ignore errors
      }
      this.setState({ infiniteLoading: false, isLoadingPrevious: false, initLoad: false }, () => {
        this.loadMoreRowsGuard = false;
        this.cellMeasureCache.clearAll();
        this.dataTable.forceUpdateGrid();
        if (!R.isNil(scrollTopIndex)) this.dataTable.scrollToRow(scrollTopIndex);
      });
    });

    return null;
  }

  @autobind
  getItemKey(data, selectedItem) {
    const dataKey = `${data?.datetime || data?.timestamp}-${data?.eventType}-${data?.nid || data?.patternId}-${
      data?.rawData
    }-${data?.index}`;
    const selectedKey = `${selectedItem?.datetime || selectedItem?.timestamp}-${selectedItem?.eventType}-${
      selectedItem?.nid || selectedItem?.patternId
    }-${selectedItem?.rawData}-${selectedItem?.index}`;
    return dataKey === selectedKey;
  }

  @autobind isRowLoaded({ index }) {
    const { isInfiniteLoad } = this.props;
    // If infinite load, check the loaded event length with the index, otherwise just return loaded.
    return isInfiniteLoad ? this.allParsedEventList && this.allParsedEventList.length > index : true;
  }

  @autobind parseData(props) {
    const { hasDecompress, intl, isContext, filterWord, hasRankNumber, showRangeTime, pageNo, pageSize } = props;
    if (hasDecompress) {
      this.eventList = R.map((e) => {
        const { rawData } = e;
        const rawDataBackup = rawData;
        let newRawData = null;
        try {
          const json = JSON.parse(rawData);
          const { Cluster, Diff } = json;
          const patternName = Cluster.split('[')[0];
          const labelIdx = Cluster.split('[')[1].split(']')[0];
          const DiffList = JSON.parse(Diff);
          if (DiffList.length === 0) {
            newRawData =
              `<span style='font-weight: 500; color: blue;'>Cluster</span>: ` +
              `${intl.formatMessage(logMessages.patternSampleLog, {
                patternName,
                labelIdx,
              })}\r\n` +
              `<span style='font-weight: 500; color: blue;'>Diff</span>: None`;
          } else {
            newRawData =
              `<span style='font-weight: 500; color: blue;'>Cluster</span>: ` +
              `${intl.formatMessage(logMessages.patternSampleLog, {
                patternName,
                labelIdx,
              })}\r\n` +
              `<span style='font-weight: 500; color: blue;'>Diff</span>: {${R.join(',', DiffList)}}`;
          }
        } catch (err) {
          newRawData = rawData;
        }
        return {
          ...e,
          rawData: newRawData,
          rawDataBackup,
        };
      }, this.eventList);
      this.eventList = R.map((e) => {
        return this.deCompress(props, e, null);
      }, this.eventList);

      // replace special tag in log content, After deCompress
      this.eventList = this.replaceLogHtmlTag(this.eventList);
    } else {
      // replace special tag in log content, Before rare highlight
      this.eventList = this.replaceLogHtmlTag(this.eventList);

      // Highlight rare event
      this.eventList = R.map((e) => {
        const { anomalyWords, rareEventFlag } = e;
        let rawData = e.rawData || [];
        const rawDataBackup = rawData;
        let json;
        try {
          json = JSON.parse(rawDataBackup);
        } catch (e) {
          // console.error(e);
        }
        if (!json && rareEventFlag && !isEmpty(anomalyWords)) {
          let anomalyWordsList = [];
          R.forEachObjIndexed((val, word) => {
            const { ratio, isJSON } = val;
            if (!isJSON) {
              const startIndex = get(val, ['startIndex'], []);
              const endIndex = get(val, ['endIndex'], []);
              const len = Math.min(startIndex.length, endIndex.length);
              for (let i = 0; i < len; i += 1) {
                anomalyWordsList.push({ startIndex: startIndex[i], endIndex: endIndex[i], ratio, word });
              }
            }
          }, anomalyWords);
          anomalyWordsList = R.sort((a, b) => a.startIndex - b.startIndex, anomalyWordsList);

          let start = 0;
          const parts = [];
          R.forEach((aw) => {
            const { startIndex, endIndex } = aw;
            if (start < startIndex && rawData.length - 1 >= startIndex) {
              parts.push(rawData.substr(start, startIndex - start));
            }
            parts.push(
              `${isContext ? '' : `\u2002`}${rawData.substr(startIndex, endIndex - startIndex)}${
                isContext ? '' : `\u2003`
              }`,
            );
            start = endIndex;
          }, anomalyWordsList);

          if (start < rawData.length) {
            parts.push(rawData.substr(start));
          }
          rawData = parts.join('');
          return {
            ...e,
            rawData,
            rawDataBackup,
          };
        }
        return e;
      }, this.eventList);
    }

    // set rank number
    if (hasRankNumber) {
      const paggingOffset = isNumber(pageNo) && isNumber(pageSize) ? (pageNo - 1) * pageSize : 0;
      this.eventList = R.addIndex(R.map)(
        (event, index) => ({
          ...event,
          rankNumber: paggingOffset + index + 1,
        }),
        this.eventList,
      );
    }
    this.eventList = R.map((event) => {
      const { startTime, endTime, timePair, timestampMap } = event;
      let datetime = event.datetime || event.timestamp;
      if (showRangeTime) {
        if (!R.isEmpty(timestampMap)) {
          const timestamps = R.sort((a, b) => a - b, R.keys(timestampMap));
          datetime = timestamps[0];
        } else if (timePair && timePair.length > 0) {
          datetime = timePair[0][0];
        } else if (startTime && endTime) {
          datetime = startTime;
        }
      }
      return { ...event, datetime };
    }, this.eventList);
    this.eventList = R.map((event) => {
      return {
        ...event,
        typeAndColor: LogParser.CalculateLogType(event),
      };
    }, this.eventList);

    // filter by keyword
    if (filterWord) {
      this.eventList = R.filter((event) => {
        return R.toLower(event.rawData).indexOf(R.toLower(filterWord)) >= 0;
      }, this.eventList);
    }
  }

  @autobind replaceLogHtmlTag(eventList) {
    return R.map((log) => {
      let newRawData = get(log, 'rawData');
      if (newRawData.indexOf('<') >= 0) {
        newRawData = R.replace(/</g, '&lt;', newRawData);
      } else if (newRawData.indexOf('>') >= 0) {
        newRawData = R.replace(/>/g, '&gt;', newRawData);
      }
      return { ...log, rawData: newRawData };
    }, eventList || []);
  }

  @autobind handlePageClick(pageNo, pageSize) {
    const { onPageChanged } = this.props;
    if (onPageChanged) {
      onPageChanged(pageNo, pageSize);
    }
  }

  @autobind datetimeRenderer({ rowData, rowIndex }) {
    const { intl, showRangeTime, hasIgnored } = this.props;
    const { datetime, startTime, endTime, timePair, timestampMap, isIgnored } = rowData;

    let content = <div>{moment.utc(datetime).format(Defaults.TimeFormat)}</div>;
    if (showRangeTime) {
      if (timestampMap && !R.isEmpty(timestampMap)) {
        content = CellRenderers.timeCountMap({ cellData: timestampMap, rowData });
      } else if (timePair) {
        content = CellRenderers.timePair({ intl, cellData: timePair, rowData });
      } else if (startTime && endTime) {
        if (startTime !== endTime) {
          content = (
            <div className="flex-col">
              <div>{moment.utc(startTime).format(Defaults.TimeFormat)}</div>
              <div>{moment.utc(endTime).format(Defaults.TimeFormat)}</div>
            </div>
          );
        } else {
          content = (
            <div className="flex-col">
              <div>{moment.utc(startTime).format(Defaults.TimeFormat)}</div>
            </div>
          );
        }
      }
    }
    return (
      <div style={{ lineHeight: '40px' }}>
        {content}
        {hasIgnored && isIgnored && (
          <div
            className="anomaly-event-legend"
            style={{
              border: `1px solid gray`,
              background: 'gray',
              color: 'white',
            }}
          >
            {intl.formatMessage(logMessages.ignored)}
          </div>
        )}
      </div>
    );
  }

  @autobind labelsRenderer({ rowData }) {
    const { combineLabelsHiddenOverflow } = this.props;
    const { hasInstance, hasType, hasCategory, hasCount, hasNid, hasProbability, hasDelay } = this.props;

    const itemStyle = { width: 120, marginRight: 4 };
    const content = (
      <div className="flex-col" style={{ margin: '4px 0', lineHeight: '16px' }}>
        <div className="flex-col">
          {hasInstance && this.logInstanceRenderer({ rowData, isLabels: true, itemStyle: {} })}
          {hasNid && this.logPatternNameRenderer({ rowData, isLabels: true, itemStyle: {} })}
        </div>
        <div className="flex-row" style={{ flexWrap: 'wrap' }}>
          {hasNid && this.logPatternIdRenderer({ rowData, isLabels: true, itemStyle })}
          {hasType && this.logTypeRenderer({ rowData, isLabels: true, itemStyle })}
          {hasCount && this.logCountRenderer({ rowData, isLabels: true, itemStyle })}
          {hasCategory && this.logCategoryRenderer({ rowData, isLabels: true, itemStyle })}
          {hasProbability && this.logProbabilityRenderer({ rowData, isLabels: true, itemStyle })}
          {hasDelay && this.logDelayRenderer({ rowData, isLabels: true, itemStyle })}
        </div>
      </div>
    );

    if (combineLabelsHiddenOverflow) {
      return (
        <div
          style={{
            height: '100%',
            width: this.labelsWidth - 12,
            overflow: 'hidden',
          }}
        >
          {content}
        </div>
      );
    }
    return (
      <Scrollbars
        style={{
          height: '100%',
          width: this.labelsWidth - 12,
        }}
      >
        {content}
      </Scrollbars>
    );
  }

  @autobind logCategoryRenderer({ rowData, isLabels, itemStyle }) {
    const { intl } = this.props;
    const { typeAndColor, category } = rowData || {};
    const msseage = category || 'NA';
    const msgs = R.split('&', msseage);
    const { color } = typeAndColor.length > 0 ? typeAndColor[0] : 'gray';
    return (
      <div className="flex-row" style={itemStyle || {}}>
        {isLabels && <div style={{ width: 85, fontWeight: 'bold' }}>{intl.formatMessage(logMessages.category)}:</div>}
        <div className="flex-grow flex-col">
          {R.addIndex(R.map)(
            (msg, idx) => (
              <Tooltip
                title={<div className="flex-col">{msg}</div>}
                placement="top"
                style={{ maxWidth: 350 }}
                key={idx}
              >
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    maxWidth: '100%',
                  }}
                >
                  {isLabels && <span>{msg}</span>}
                  {!isLabels && (
                    <span
                      className="anomaly-event-legend"
                      style={{
                        border: `1px solid ${color}`,
                        background: color,
                        color: 'white',
                      }}
                    >
                      {msg}
                    </span>
                  )}
                </div>
              </Tooltip>
            ),
            msgs,
          )}
        </div>
      </div>
    );
  }

  @autobind logInstanceRenderer({ rowData, isLabels, itemStyle }) {
    const { intl, projectName, projects } = this.props;
    const { instanceDisplayNameMap } = this.state;
    const projectInfo = R.find((project) => projectName === project.projectName, projects || []) || {};

    const { typeAndColor, instanceName } = rowData || {};
    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName, {
      pn: projectInfo?.projectShortName,
      owner: projectInfo?.owner,
    });
    const msg = instanceStr || 'NA';
    const { color } = typeAndColor.length > 0 ? typeAndColor[0] : 'gray';
    return (
      <div className="flex-row" style={itemStyle || {}}>
        {isLabels && <div style={{ width: 85, fontWeight: 'bold' }}>{intl.formatMessage(logMessages.instance)}:</div>}
        <Tooltip title={<div>{msg}</div>} placement="top" style={{ maxWidth: 350 }} className="flex-grow">
          {isLabels && (
            <AutoSizer>
              {({ width }) => (
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    width,
                  }}
                >
                  <span
                    style={{
                      width,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {msg}
                  </span>
                </div>
              )}
            </AutoSizer>
          )}
          {!isLabels && (
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'inline-block',
                verticalAlign: 'middle',
                maxWidth: '100%',
              }}
            >
              <span
                className="anomaly-event-legend"
                style={{
                  maxWidth: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  border: `1px solid ${color}`,
                  background: color,
                  color: 'white',
                }}
              >
                {msg}
              </span>
            </div>
          )}
        </Tooltip>
      </div>
    );
  }

  @autobind logPatternNameRenderer({ rowData, isLabels, itemStyle }) {
    const { intl } = this.props;
    const { typeAndColor, nid, patternName } = rowData || {};
    const msg = patternName || (isNumber(nid) ? `Pattern ${nid}` : 'NA');
    const { color } = typeAndColor.length > 0 ? typeAndColor[0] : 'gray';
    return (
      <div className="flex-row" style={itemStyle || {}}>
        {isLabels && (
          <div style={{ width: 85, fontWeight: 'bold' }}>{intl.formatMessage(logMessages.patternName)}:</div>
        )}
        <Tooltip title={<div>{msg}</div>} placement="top" style={{ maxWidth: 350 }} className="flex-grow">
          {isLabels && (
            <AutoSizer>
              {({ width }) => (
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    width,
                  }}
                >
                  <span
                    style={{
                      width,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {msg}
                  </span>
                </div>
              )}
              {!isLabels && (
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    maxWidth: '100%',
                  }}
                >
                  <span
                    className="anomaly-event-legend"
                    style={{
                      maxWidth: '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      border: `1px solid ${color}`,
                      background: color,
                      color: 'white',
                    }}
                  >
                    {msg}
                  </span>
                </div>
              )}
            </AutoSizer>
          )}
        </Tooltip>
      </div>
    );
  }

  @autobind logPatternIdRenderer({ rowData, isLabels, itemStyle }) {
    const { intl } = this.props;
    const { typeAndColor, nid, patternId } = rowData || {};
    const pid = nid || nid === 0 ? nid : patternId;
    const { patternIdStr: msg } = Defaults.PatternIdNameStr({ patternId: pid }, {});
    const { color } = (typeAndColor && typeAndColor.length) > 0 ? typeAndColor[0] : 'gray';
    return (
      <div className="flex-row" style={{ ...itemStyle, height: '40px', alignItems: 'center' }}>
        {isLabels && <div style={{ width: 85, fontWeight: 'bold' }}>{intl.formatMessage(logMessages.patternId)}:</div>}
        <div className="flex-grow flex-min-width flex-row">
          <AntdTooltip placement="top" mouseEnterDelay={0.3} title={msg}>
            <span
              style={{
                color,
                lineHeight: '14px',
                height: 14,
                minWidth: 10,
                textAlign: 'center',
                border: `1px solid ${color}`,
                borderRadius: '20%',
                padding: '0 2px',
                cursor: 'default',
              }}
            >
              {pid}
            </span>
          </AntdTooltip>
        </div>
      </div>
    );
  }

  @autobind logCountRenderer({ rowData, isLabels, itemStyle }) {
    const { intl } = this.props;
    const { count } = rowData || {};
    const msg = isNumber(count) ? count : '';
    return (
      <div className="flex-row" style={itemStyle || {}}>
        {isLabels && <div style={{ width: 85, fontWeight: 'bold' }}>{intl.formatMessage(logMessages.count)}:</div>}
        {msg}
      </div>
    );
  }

  @autobind logProbabilityRenderer({ rowData, isLabels, itemStyle }) {
    const { intl } = this.props;
    const { probability } = rowData;
    return (
      <div className="flex-row" style={itemStyle || {}}>
        {isLabels && (
          <span style={{ width: 85, fontWeight: 'bold' }}>{intl.formatMessage(logMessages.probability)}:</span>
        )}
        <span className="flex-grow">{CellRenderers.probability({ cellData: probability })}</span>
      </div>
    );
  }

  @autobind logDelayRenderer({ rowData, isLabels, itemStyle }) {
    const { intl } = this.props;
    const { delay } = rowData;
    return (
      <div className="flex-row" style={itemStyle || {}}>
        {isLabels && <span style={{ width: 85, fontWeight: 'bold' }}>{intl.formatMessage(logMessages.maxDelay)}:</span>}
        <span className="flex-grow">{CellRenderers.humanizeDuration({ period: delay, intl })}</span>
      </div>
    );
  }

  @autobind logTypeRenderer({ rowData, isLabels, itemStyle }) {
    const { intl, isContext } = this.props;
    const { typeAndColor, rawDataBackup } = rowData;

    if (typeAndColor.length > 0) {
      return (
        <div className="flex-row" style={{ ...itemStyle, height: '40px', alignItems: 'center' }}>
          {isLabels && <div style={{ width: 85, fontWeight: 'bold' }}>{intl.formatMessage(logMessages.type)}:</div>}
          <div className="flex-grow flex-min-width flex-row flex-wrap">
            {R.map(
              (type) => {
                return CellRenderers.logShortTypeRenderer({ intl, type });
              },
              R.map((item) => item.type, typeAndColor),
            )}
          </div>
        </div>
      );
    }

    try {
      const json = JSON.parse(rawDataBackup);
      const { Cluster } = json;
      const patternName = Cluster.split('[')[0];
      const labelIdx = Cluster.split('[')[1].split(']')[0];
      return (
        <div className="flex-col">
          <span className="flex-grow" style={{ color: 'blue' }}>
            {isContext ? `Pattern ${patternName}` : `P${patternName}:S${labelIdx}`}
          </span>
        </div>
      );
    } catch (err) {
      return <div className="flex-col">{intl.formatMessage(logMessages.normal)}</div>;
    }
  }

  @autobind handleContentChanged() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind contentRender(props) {
    const { intl, showSimpleRawData, showRawDataFrequency, isCritical, isInfiniteLoad, currentTheme } = this.props;
    const { dataKey, parent, rowIndex, rowData } = props;
    const { summarySettings, keywordCurrent } = this.state;
    const { rawData, isExpand } = rowData;
    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
      if (!isObject(rawDataJson)) rawDataJson = undefined;
    } catch (error) {
      // ignore
    }
    const containerProps =
      isInfiniteLoad && rawDataJson
        ? {
            style: { cursor: 'pointer' },
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              this.expansionHandle({ rowData, rowIndex });
              this.setState({ selectedItem: rowData });
            },
          }
        : {};

    if (showSimpleRawData) {
      return (
        <div {...containerProps}>
          <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
            {rawDataJson ? (
              <LogRenderers.ExpandLogJSONContent
                intl={intl}
                rawData={rawData}
                rawDataJson={rawDataJson}
                currentTheme={currentTheme}
              />
            ) : (
              <LogRenderers.ExpandLogContent
                intl={intl}
                rawData={rawData}
                rawDataJson={rawDataJson}
                style={{ whiteSpace: 'normal' }}
                isExpand={isExpand}
                onExpand={this.handleExpandRow(rowIndex)}
              />
            )}
          </CellMeasurer>
        </div>
      );
    } else {
      const { rowData } = props;
      const { highlightWords, frequencyStr } = rowData;
      const {
        highlightWord,
        isInfiniteLoad,
        intl,
        isContext,
        enableJsonSummary,
        summarySettings: propSummarySettings,
      } = this.props;
      return (
        <div {...containerProps}>
          {CellRenderers.logContent(props, this.cellMeasureCache, {
            width: this.tableWidth - this.contentWidthOffset - 16,
            highlightWord:
              keywordCurrent || (highlightWords && highlightWords.length > 0 ? highlightWords : highlightWord),
            isCritical,
            onChanged: this.handleContentChanged,
            showRawDataFrequency,
            frequencyStr,
            isInfiniteLoad,
            allParsedEventList: this.allParsedEventList,
            intl,
            isContext,
            summarySettings: summarySettings.length > 0 ? summarySettings : propSummarySettings,
            enableJsonSummary,
            currentTheme,
          })}
        </div>
      );
    }
  }

  @autobind handleExpandRow(rowIndex) {
    return (isExpand) => {
      this.allParsedEventList[rowIndex].isExpand = isExpand;
      setTimeout(() => {
        this.cellMeasureCache.clear(rowIndex);
        if (this.dataTable) {
          this.dataTable.forceUpdateGrid();
        }
      }, 100);
    };
  }

  @autobind handleLogContextOneMinClick(rowData) {
    const { instanceName, timestamp, startTime, endTime } = rowData;
    this.setState({
      showTimeSelectModal: true,
      selectInstance: instanceName,
      selectStartTimestamp: startTime ? startTime - 60 * 1000 : timestamp - 60 * 1000,
      selectEndTimestamp: endTime ? endTime + 60 * 1000 : timestamp + 60 * 1000,
      activeIncident: rowData,
    });
  }

  @autobind onCloseTimeSelect(props) {
    const { projectName, instanceName, startTimestamp, endTimestamp, keywordFilter } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState({
        showTimeSelectModal: false,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp: startTimestamp,
        selectEndTimestamp: endTimestamp,
        showContextModal: true,
        contextKeywordFilter: keywordFilter,
        contextTime: 60 * 1000,
      });
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind handleRCAJumpClick(rowData) {
    const { project, timestamp } = rowData;
    const day = moment.utc(timestamp).format(Defaults.DateFormat);
    const { systemName, owner, systemId } = project || {};
    const query = {
      environmentId: 'All',
      startTime: day,
      endTime: day,
      customerName: owner,
      systemId,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  }

  @autobind handleLogAnalysisJumpClick(rowData) {
    const { projects } = this.props;
    const { projectName, instanceName, timestamp, nid, eventType } = rowData;
    const day = moment.utc(timestamp).format(Defaults.DateFormat);
    if (projectName && instanceName && day && nid) {
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const query = {
        projectName,
        instanceName,
        startTime: day,
        endTime: day,
        activeTab: eventType === 'rare' ? 'important' : 'clusters',
        activePatternId: eventType === 'rare' ? undefined : nid,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind handleExternalSystemJumpClick(rowData) {
    this.setState({ activeIncident: rowData, showExternalSystemJumpModal: true });
  }

  @autobind handleActionClick(rowData, actionName) {
    this.setState({ activeIncident: rowData, showTakeLogActionModal: true, actionName });
  }

  @autobind handleEmailAlertsClick(incident) {
    this.setState({ activeIncident: incident, showEmailAlertsModal: true });
  }

  @autobind handleEventActionClick(incident) {
    this.setState({ activeIncident: incident, showEventActionModal: true });
  }

  @autobind handlePatternNameChanged(newPatternName, patternId) {
    const { onNameChanged } = this.props;
    this.setState({ showTakeLogActionModal: false }, () => {
      onNameChanged({ newPatternName, patternId });
    });
  }

  @autobind handleIgnoreConfirm(isConfirmed) {
    const { onNameChanged } = this.props;
    this.setState({ showIgnoreModal: false }, () => {
      if (isConfirmed) {
        onNameChanged();
      }
    });
  }

  @autobind handleIgnoreClick(incident) {
    this.setState({ activeIncident: incident, showIgnoreModal: true });
  }

  @autobind actionsRenderer({ rowData }) {
    const { intl, hasPatternAction, hasContext, showRCAJump, showExternalJump, showLogAnalysisJump, isReadUser } =
      this.props;
    const { ignoreFlag, isIgnored } = rowData;
    const ignored = ignoreFlag === true || isIgnored === true;

    const handleMenuClick = ({ key }) => {
      switch (key) {
        case 'setPatternName':
          this.handleActionClick(rowData, 'setPatternName');
          break;
        case 'emailAlerts':
          this.handleEmailAlertsClick(rowData);
          break;
        case 'takeAction':
          this.handleEventActionClick(rowData);
          break;
        case 'context':
          this.handleLogContextOneMinClick(rowData);
          break;
        case 'jumpToRCA':
          this.handleRCAJumpClick(rowData);
          break;
        case 'jumpToLogAnalysis':
          this.handleLogAnalysisJumpClick(rowData);
          break;
        case 'jumpToExternalSystem':
          this.handleExternalSystemJumpClick(rowData);
          break;
        case 'ignore':
          this.handleIgnoreClick(rowData);
          break;
        default:
          break;
      }
    };
    return (
      <div className="flex-row flex-end-justify">
        <Dropdown name={intl.formatMessage(logMessages.investigate)} itemClick={handleMenuClick}>
          <>
            {hasPatternAction && !isReadUser && (
              <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item>
            )}
            {hasPatternAction && !isReadUser && (
              <Menu.Item key="emailAlerts">{intl.formatMessage(eventMessages.emailAlerts)}</Menu.Item>
            )}
            {hasPatternAction && !isReadUser && (
              <Menu.Item key="takeAction">{intl.formatMessage(eventMessages.takeAction)}</Menu.Item>
            )}
            {hasContext && <Menu.Item key="context">{intl.formatMessage(eventMessages.context)}</Menu.Item>}
            {showRCAJump && <Menu.Item key="jumpToRCA">{intl.formatMessage(appFieldsMessages.jumpToRCA)}</Menu.Item>}
            {showLogAnalysisJump && (
              <Menu.Item key="jumpToLogAnalysis">{intl.formatMessage(appFieldsMessages.jumpToLogAnalysis)}</Menu.Item>
            )}
            {showExternalJump && (
              <Menu.Item key="jumpToExternalSystem">
                {intl.formatMessage(appFieldsMessages.jumpToExternalSystem)}
              </Menu.Item>
            )}
            {hasPatternAction && !isReadUser && (
              <Menu.Item key="ignore">
                {ignored ? intl.formatMessage(eventMessages.unignore) : intl.formatMessage(eventMessages.ignore)}
              </Menu.Item>
            )}
          </>
        </Dropdown>
      </div>
    );
  }

  @autobind loadingRendererWrapper(renderer) {
    return (params) => {
      const { rowIndex, rowData } = params;
      const { isLastRow, isFirstRow } = rowData;
      // In infinite load, show empty content when loading
      const { isInfiniteLoad, isManualLoad } = this.props;
      const { infiniteLoading } = this.state;
      const isLoading = isInfiniteLoad && infiniteLoading && (isLastRow || isFirstRow);
      if (isLoading) {
        return (
          <div style={{ lineHeight: '40px', alignItems: 'center' }}>
            <Skeleton.Button size="small" active />
          </div>
        );
      } else if (isInfiniteLoad && isLastRow) {
        return (
          <a
            style={{ lineHeight: '40px' }}
            onClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();
              await this.loadMoreRows({ isUpLoader: false });
            }}
          >
            Load more: +10 min
          </a>
        );
      } else if (isFirstRow) {
        return (
          <a
            style={{ lineHeight: '40px' }}
            onClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();
              await this.loadMoreRows({ isUpLoader: true });
            }}
          >
            Load more: -10 min
          </a>
        );
      } else {
        return isLastRow || isFirstRow ? <div /> : renderer(params);
      }
    };
  }

  @autobind
  logInstanceNameRenderer({ rowData, rowIndex }) {
    const { projectName, projects } = this.props;
    const { instanceDisplayNameMap } = this.state;
    const projectInfo = R.find((project) => projectName === project.projectName, projects || []) || {};
    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, rowData.instanceName, {
      pn: projectInfo?.projectShortName,
      owner: projectInfo?.owner,
    });
    return (
      <Popover title={null} content={instanceStr}>
        <div className="hidden-line-with-ellipsis clickable">{instanceStr}</div>
      </Popover>
    );
  }

  @autobind compressBaseRenderer({ rowData, rowIndex }) {
    const { intl, isInfiniteLoad } = this.props;
    const { rawDataBackup } = rowData;
    const { eventType } = rowData;

    if (eventType) {
      return this.logTypeRenderer({ rowData });
    }

    try {
      const json = JSON.parse(rawDataBackup);
      const { Cluster } = json;
      const patternName = Cluster.split('[')[0];
      const labelIdx = Cluster.split('[')[1].split(']')[0];
      return (
        <div className="flex-col" style={{ lineHeight: '40px', alignItems: 'center' }}>
          <span className="flex-grow" style={{ color: 'blue' }}>
            {`P${patternName}:S${labelIdx}`}
          </span>
        </div>
      );
    } catch (err) {
      if (isInfiniteLoad) {
        return <div />;
      } else {
        return <div className="flex-col">{intl.formatMessage(logMessages.normal)}</div>;
      }
    }
  }

  @autobind contextBaseRenderer({ rowData, rowIndex }) {
    const { rawDataBackup } = rowData;
    try {
      const json = JSON.parse(rawDataBackup);
      const { Cluster } = json;
      const patternId = Cluster.split('[')[0];
      return (
        <div className="flex-col">
          <span className="flex-grow" style={{ color: 'blue' }}>
            {patternId}
          </span>
        </div>
      );
    } catch (err) {
      return <div className="flex-col" />;
    }
  }

  @autobind onClickCompress(rowIndex, decompress) {
    const self = this;
    return (e) => {
      e.stopPropagation();

      self.allParsedEventList = R.addIndex(R.map)((e, idx) => {
        if (idx === rowIndex) {
          return this.deCompress(this.props, e, decompress);
        }
        return e;
      }, self.allParsedEventList);
      self.cellMeasureCache.clearAll();
      if (self.dataTable) {
        self.dataTable.forceUpdateGrid();
      }
    };
  }

  @autobind deCompress(props, e, decompress) {
    const { isContext, showHighlightCategory } = props;
    const { logDiffOperation, rawData, rawDataBackup, oldRawData } = e;

    const prefixSame = !isContext && showHighlightCategory === 'common' ? '\u2002' : '';
    const postfixSame = !isContext && showHighlightCategory === 'common' ? '\u2003' : '';
    const prefixDiff = !isContext && showHighlightCategory === 'difference' ? '\u2002' : '';
    const postfixDiff = !isContext && showHighlightCategory === 'difference' ? '\u2003' : '';

    let isJsonMsg = false;
    let newRawData = '';
    let diffSampleMsg = '';
    let diffMap = {};
    if (!decompress) {
      try {
        const json = JSON.parse(rawDataBackup);
        const { Cluster, Diff } = json;
        const DiffList = JSON.parse(Diff);
        diffSampleMsg = get(this.clusterSampleMsgs, [Cluster], null);
        try {
          const sampleMsgJson = JSON.parse(diffSampleMsg);
          if (sampleMsgJson) {
            isJsonMsg = true;
          }
        } catch (e) {
          // It's not json
        }
        const { operationMap } = logDiffOperation || {};
        const diff = get(operationMap, ['diff'], []);

        if (diff.length > 0) {
          let DiffListIndex = 0;
          let sampleMsgIndex = 0;
          R.forEach((regStr) => {
            const [tp, idx] = R.split(',', regStr);
            if (tp === 'E') {
              if (isJsonMsg) {
                newRawData = `${newRawData}${R.slice(
                  sampleMsgIndex,
                  sampleMsgIndex + Number(idx),
                  diffSampleMsg || '',
                )}`;
              } else {
                newRawData = `${newRawData}${prefixSame}${R.slice(
                  sampleMsgIndex,
                  sampleMsgIndex + Number(idx),
                  diffSampleMsg || '',
                )}${postfixSame}`;
              }
              sampleMsgIndex += Number(idx);
            } else if (tp === 'D') {
              sampleMsgIndex += Number(idx);
            } else if (tp === 'I') {
              if (isJsonMsg) {
                newRawData = `${newRawData}${DiffList[DiffListIndex]}`;
              } else {
                newRawData = `${newRawData}${prefixDiff}${DiffList[DiffListIndex]}${postfixDiff}`;
              }
              DiffListIndex += 1;
            }
          }, diff);
        } else {
          newRawData = diffSampleMsg || rawData;
        }
      } catch (err) {
        newRawData = rawData;
      }
      if (isJsonMsg) {
        if (showHighlightCategory === 'common') {
          diffMap = compareJSON(parseJSON(diffSampleMsg || ''), parseJSON(newRawData), 'same');
        } else if (showHighlightCategory === 'difference') {
          diffMap = compareJSON(parseJSON(diffSampleMsg || ''), parseJSON(newRawData), 'diff');
        }
      }
      return {
        ...e,
        rawData: newRawData,
        oldRawData: rawData,
        diffMap,
        decompress: !decompress,
      };
    }

    return {
      ...e,
      rawData: oldRawData,
      oldRawData: rawData,
      diffMap,
      decompress: !decompress,
    };
  }

  @autobind
  onClickExpandAll(expandAll) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.allParsedEventList = R.map((x) => {
        const y = { ...x, isExpand: !expandAll };
        y[this.expandedStateKey] = !expandAll;
        return y;
      }, this.allParsedEventList || []);
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdateGrid();
      }
      this.setState({ expandAll: !expandAll });
    };
  }

  @autobind
  headerContentRenderer({ label }) {
    const { expandAll } = this.state;
    return (
      <div className="flex-row" style={{ marginRight: -4 }}>
        <span>{label}</span>
        <span className="flex-grow" />
        <Tooltip title={`${expandAll ? 'Collapse' : 'Expand'} all`} placement="top">
          <div style={{ cursor: 'pointer' }} onClick={this.onClickExpandAll(expandAll)}>
            <DoubleLeftOutlined rotate={expandAll ? 90 : -90} />
          </div>
        </Tooltip>
      </div>
    );
  }

  @autobind headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection }, () => {
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdateGrid();
      }
    });
  }

  @autobind
  expansionRender(props) {
    const { rowData, rowIndex } = props;
    const { rawData } = rowData || {};
    let isJson = false;
    try {
      const rawDataJson = JSON.parse(rawData);
      if (isObject(rawDataJson)) {
        isJson = true;
      }
    } catch (ex) {
      // ignore error
    }
    return isJson ? (
      <div
        className="clickable"
        style={{ lineHeight: '40px' }}
        onClick={() => this.expansionHandle({ rowData, rowIndex })}
      >
        {rowData.isExpand ? <DownOutlined /> : <RightOutlined />}
      </div>
    ) : (
      <div />
    );
  }

  @autobind
  minusOneIndexIsLiveTail() {
    const { isLiveTail } = this.props;
    return isLiveTail ? 0 : 1;
  }

  @autobind
  expansionHandle({ rowData, rowIndex }) {
    if (this.props.isInfiniteLoad) {
      const { isExpand } = rowData;
      this.allParsedEventList[rowIndex - this.minusOneIndexIsLiveTail()].isExpand = !isExpand;
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdateGrid();
      }
    }
  }

  @autobind
  matchKeywordInEvents(eventList, keyword) {
    const { summarySettings } = this.state;
    const isJsonLog = eventList.length > 0 && isJsonMessage(eventList[0]?.rawData);
    const regex = new RegExp(keyword.trim(), 'mgi');

    const matches = [];
    // Convert json path -> to .
    const settings = R.map((s) => s.replaceAll('->', '.'), summarySettings);
    if (isJsonLog && summarySettings?.length > 0) {
      R.addIndex(R.forEach)((e, ridx) => {
        if (e.rawData && isString(e.rawData)) {
          let rawDataJson;
          try {
            rawDataJson = JSON.parse(e.rawData);
            if (!isObject(rawDataJson)) rawDataJson = undefined;
          } catch (error) {
            // ignore
          }
          if (rawDataJson) {
            R.forEach((s) => {
              const str = get(rawDataJson, s);
              const match = str.match(regex);
              // Count all matches in the same row.
              if (match && match.length > 0) {
                R.forEach(() => {
                  matches.push(ridx);
                }, match);
              }
            }, settings);
          }
        }
      }, eventList);
    } else {
      R.addIndex(R.forEach)((e, ridx) => {
        if (e.rawData && isString(e.rawData)) {
          const match = e.rawData.match(regex);
          // Count all matches in the same row.
          if (match && match.length > 0) {
            R.forEach(() => {
              matches.push(ridx);
            }, match);
          }
        }
      }, eventList);
    }
    return matches;
  }

  @autobind
  handleKeywordNavigate(increase) {
    const { keywordCurrentIndex } = this.state;
    let cidx = keywordCurrentIndex;
    if (increase) {
      cidx += 1;
      if (cidx >= this.keywordMatchIndics.length) cidx = this.keywordMatchIndics.length - 1;
    } else {
      cidx -= 1;
      if (cidx < 0) cidx = 0;
    }
    const rowIndex = this.keywordMatchIndics[cidx];
    if (rowIndex > 0) {
      this.dataTable.scrollToRow(rowIndex);
    }
    this.setState({ keywordCurrentIndex: cidx });
  }

  @autobind
  getLocalEndTime(props, state, endTimeObj) {
    const { projects } = props;
    const { projectName } = state;
    const currentProject = R.find((project) => project.projectName === projectName, projects);

    let timezoneOffset = 0;
    if (currentProject?.timezone) {
      const zone = momenttz.tz(currentProject?.timezone);
      timezoneOffset = zone.utcOffset();
    }
    const now = moment.utc(moment.utc().valueOf() + (timezoneOffset || 0) * 60000);
    if (endTimeObj) {
      // add back endtime check
      if (endTimeObj.clone().startOf('day').diff(now, 'days') === 0) {
        return now;
      } else {
        return endTimeObj;
      }
    } else {
      return now;
    }
  }

  @autobind
  handleKeywordsSearchChange(args, evt) {
    const { keywordFilter, keywordCurrent } = this.state;
    let newState = { keywordCurrent: keywordFilter };
    if (keywordFilter) {
      if (keywordFilter === keywordCurrent) {
        this.handleKeywordNavigate(!evt.shiftKey);
      } else {
        const matches = this.matchKeywordInEvents(this.allParsedEventList, keywordFilter);
        this.keywordMatchIndics = matches;
        newState = { ...newState, keywordCurrentIndex: 0, keywordMatchCount: matches.length };
      }
    } else {
      newState = { ...newState, keywordCurrentIndex: 0, keywordMatchCount: 0 };
    }
    this.setState(newState);
  }

  @autobind
  async handleScroll({ scrollTop }) {
    if (this.props.isLiveTail || this.props.isManualLoad) {
      return;
    }
    if (scrollTop > 10 || this.loadMoreRowsGuard || this.state.initLoad) {
      return;
    }
    await this.loadMoreRows({ isUpLoader: true });
  }

  @autobind
  newLiveTailRender({ rowData, rowIndex }) {
    const { newLiveTail } = rowData;
    return (
      <>{newLiveTail ? <div style={{ width: 4, height: '100%', background: 'var(--primary-color)' }} /> : <div />}</>
    );
  }

  render() {
    const {
      intl,
      pageNo,
      pageSize,
      totalCount,
      combineLabels,
      hasRankNumber,
      showRangeTime,
      disableSortDateTime,
      hasDatetime,
      hasInstance,
      hasCategory,
      hasTag,
      hasType,
      hasInstanceName,
      hasNid,
      hasCount,
      hasProbability,
      hasDelay,
      hasDecompress,
      highlightRow,
      isInfiniteLoad,
      isContext,
      isLiveTail,
      isManualLoad,
      handleChangeTableKey,
      unSubscribeLogTailData,
      projects,
      projectName,
    } = this.props;
    const {
      sortBy,
      sortDirection,
      infiniteLoading,
      summarySettingsModelFlag,
      summarySettings,
      jsonFields,
      instanceName,
      summaryLoading,
      selectedItem,
    } = this.state;
    const {
      selectStartTimestamp,
      selectEndTimestamp,
      contextTime,
      contextKeywordFilter,
      showIgnoreModal,
      currentExpandDurationStart,
      currentExpandDurationEnd,
      currentStartTime,
      currentEndTime,
      keywordFilter,
      keywordCurrent,
      keywordCurrentIndex,
      keywordMatchCount,
      instanceInComponentMap,
      instanceDisplayNameMap,
      isLoadingPrevious,
      isPause,
    } = this.state;
    // const showPaging = !isInfiniteLoad && totalCount > pageSize;
    const showPaging = !isInfiniteLoad;
    const hasJsonLog = this.allParsedEventList.length > 0 && isJsonMessage(this.allParsedEventList[0]?.rawData);

    const projectInfo = R.find((project) => projectName === project.projectName, projects || []) || {};
    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName, {
      pn: projectInfo?.projectShortName,
      owner: projectInfo?.owner,
    });

    // remove the load more row which cause issue with render contents
    const allParsedEventList = isInfiniteLoad
      ? [
          ...(isLiveTail ? [] : [this.LOADING_FIRST_ROW]),
          ...this.allParsedEventList,
          ...(isLiveTail ? [] : [this.LOADING_ROW]),
        ]
      : this.allParsedEventList;

    const { s: startTimeStr, e: endTimeStr } = durationRender(currentStartTime, currentEndTime);
    const KEY_DURATION_START = 'currentExpandDurationStart';
    const KEY_DURATION_END = 'currentExpandDurationEnd';
    return (
      <Container className="full-width full-height flex-col">
        {showPaging && (
          <div className="flex-row" style={{ paddingBottom: 2 }}>
            <div className="flex-grow" />
            <Pagination
              size="small"
              current={pageNo}
              total={totalCount}
              pageSize={pageSize}
              onChange={(page, pageSize) => {
                this.handlePageClick(page, pageSize);
              }}
              showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
              showSizeChanger
              pageSizeOptions={['20', '40', '60', '100', '500', '1000']}
              onShowSizeChange={(page, pageSize) => {
                this.handlePageClick(page, pageSize);
              }}
            />
          </div>
        )}
        {isInfiniteLoad && !isContext && (
          <>
            <div style={{ marginBottom: 8 }}>
              {!isLiveTail && (
                <>
                  From
                  <div
                    className="flex-center-align"
                    style={{
                      border: '1px solid var(--border-color-base)',
                      padding: '0 4px',
                      display: 'inline-flex',
                      borderRadius: 6,
                      margin: '0 6px',
                    }}
                    onClick={() => this.handleDurationClick(KEY_DURATION_START)}
                  >
                    <span className="font-14 bold flex-grow">{startTimeStr}</span>
                    <MinusOutlined
                      style={{
                        fontSize: 18,
                        cursor: 'pointer',
                        borderLeft: '1px solid var(--border-color-split)',
                        paddingLeft: 4,
                        marginLeft: 4,
                      }}
                    />
                  </div>
                  <Popover
                    title={`${intl.formatMessage(logMessages.extendTimeBy)} :`}
                    placement="bottom"
                    overlayInnerStyle={{ width: 130 }}
                    content={
                      <div className="flex-col">
                        {this.extendDurations.map((i) => (
                          <div
                            onClick={() => this.handleExtendDurationClick(i.id, KEY_DURATION_START)}
                            className="flex-row full-width flex-center-align"
                            key={`startDuration${i.id}`}
                            style={{
                              background: currentExpandDurationStart === i.id ? 'var(--primary-1)' : 'none',
                              padding: 6,
                              borderBottom: '1px solid var(--border-color-base)',
                              cursor: 'pointer',
                            }}
                          >
                            {i.text}
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span style={{ marginRight: 4, fontSize: 14, fontWeight: 'bold', cursor: 'pointer' }}>
                      {getDurationText(intl, currentExpandDurationStart)}
                    </span>
                  </Popover>
                  to
                  <div
                    className="flex-center-align"
                    style={{
                      border: '1px solid var(--border-color-base)',
                      padding: '0 4px',
                      display: 'inline-flex',
                      borderRadius: 6,
                      margin: '0 6px',
                    }}
                    onClick={() => this.handleDurationClick(KEY_DURATION_END)}
                  >
                    <span className="font-14 bold flex-grow">{endTimeStr}</span>
                    <PlusOutlined
                      style={{
                        fontSize: 18,
                        cursor: 'pointer',
                        borderLeft: '1px solid var(--border-color-split)',
                        paddingLeft: 4,
                        marginLeft: 4,
                      }}
                    />
                  </div>
                  <Popover
                    title={`${intl.formatMessage(logMessages.extendTimeBy)} :`}
                    placement="bottom"
                    overlayInnerStyle={{ width: 130 }}
                    content={
                      <div className="flex-col">
                        {this.extendDurations.map((i) => (
                          <div
                            onClick={() => this.handleExtendDurationClick(i.id, KEY_DURATION_END)}
                            className="flex-row full-width flex-center-align"
                            key={`endDuration${i.id}`}
                            style={{
                              background: currentExpandDurationEnd === i.id ? 'var(--primary-1)' : 'none',
                              padding: 6,
                              borderBottom: '1px solid var(--border-color-base)',
                              cursor: 'pointer',
                            }}
                          >
                            {i.text}
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span style={{ marginRight: 4, fontSize: 14, fontWeight: 'bold', cursor: 'pointer' }}>
                      {getDurationText(intl, currentExpandDurationEnd)}
                    </span>
                  </Popover>
                </>
              )}
              found {this.allParsedEventList.length} logs.
              {!isLiveTail && <Spin style={{ marginLeft: 10 }} spinning={infiniteLoading} indicator={antIcon} />}
            </div>

            <div className="flex-row" style={{ paddingBottom: 4, alignItems: 'center' }}>
              <div className="flex-row" style={{ paddingBottom: 2, alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold', paddingRight: 16, flexShrink: 0 }}>
                  {`${intl.formatMessage(appFieldsMessages.instanceName)}:`}
                </span>
                <span style={{ paddingRight: 8 }}>{`${instanceStr}${
                  instanceInComponentMap[instanceName] ? ` (${instanceInComponentMap[instanceName]})` : ''
                }`}</span>
              </div>
              <div className="flex-grow" />
              <Input.Search
                size="small"
                placeholder="Keyword search"
                style={{ width: 220, marginRight: hasJsonLog ? '8px' : '0px', flexShrink: 0 }}
                value={keywordFilter}
                allowClear
                enterButton
                suffix={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {keywordCurrent && keywordMatchCount ? (
                      <div>{`${keywordCurrentIndex + 1}/${keywordMatchCount}`}</div>
                    ) : (
                      <div />
                    )}
                    <DownOutlined
                      style={{ margin: '0 4px', cursor: 'pointer' }}
                      onClick={() => this.handleKeywordNavigate(true)}
                    />
                    <UpOutlined
                      style={{ margin: '0 4px', cursor: 'pointer' }}
                      onClick={() => this.handleKeywordNavigate(false)}
                    />
                  </div>
                }
                onSearch={(value, e) => this.handleKeywordsSearchChange(value, e)}
                onChange={(e) => this.setState({ keywordFilter: e.target.value })}
              />
              {hasJsonLog && (
                <Button
                  key="1"
                  icon={<UnorderedListOutlined />}
                  size="small"
                  onClick={() => this.setState({ summarySettingsModelFlag: true })}
                >
                  Summary settings
                </Button>
              )}
              {isLiveTail && (
                <Button
                  size="small"
                  style={{ width: 70, marginLeft: 8 }}
                  icon={isPause ? <PlayCircleOutlined /> : <PauseOutlined />}
                  onClick={() => {
                    this.setState({ isPause: !isPause }, () => {
                      if (this.state.isPause) {
                        clearInterval(this.delayTimeId);
                        this.delayTimeId = null;
                        this.pauseTime = moment();
                        this.pauseUnSubscribeTime = setTimeout(() => {
                          unSubscribeLogTailData({ instanceName });
                        }, 1000 * 125);
                      } else {
                        const diffPauseTime = this.pauseTime ? moment().diff(this.pauseTime, 'seconds') : 0;
                        if (diffPauseTime < 120) {
                          this.pauseTime = null;
                          clearTimeout(this.pauseUnSubscribeTime);
                          this.handleDelayData();
                        } else {
                          unSubscribeLogTailData({ instanceName });
                          handleChangeTableKey();
                        }
                      }
                    });
                  }}
                >
                  {intl.formatMessage(isPause ? appFieldsMessages.start : appFieldsMessages.pause)}
                </Button>
              )}
            </div>
          </>
        )}

        <Container className="flex-grow flex-min-height">
          {isManualLoad && false && (
            <div style={{ position: 'absolute', right: 0, top: -32 }}>
              <Button
                size="small"
                onClick={async () => {
                  await this.loadMoreRows({ isUpLoader: true });
                }}
              >
                Load more: -10 min
              </Button>
            </div>
          )}
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            rowCount={isInfiniteLoad ? Number.MAX_SAFE_INTEGER : allParsedEventList.length}
            loadMoreRows={() => {
              if (isLiveTail) return;
              // The allParsedEventList contains the loading row, so we need to > 1
              if (isManualLoad && allParsedEventList?.length > 2) return;

              this.loadMoreRows({ isUpLoader: false });
            }}
            threshold={2}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => {
                  this.tableWidth = width - 2;
                  return (
                    <Spin spinning={isLoadingPrevious} style={{ width: width - 2, height }} delay={0}>
                      <Table
                        ref={(c) => {
                          this.dataTable = c;
                          registerChild(c);
                        }}
                        className={`event-table with-border${isInfiniteLoad ? ' infinite-load' : ''}`}
                        width={width - 2}
                        height={height}
                        id="table-dom"
                        deferredMeasurementCache={this.cellMeasureCache}
                        headerHeight={30}
                        rowClassName={({ index: idx }) => {
                          // const name = idx >= 0 && idx % 2 === 1 ? 'event-table-row odd-row' : 'event-table-row';
                          const name = idx >= 0 && idx % 2 === 1 ? 'event-table-row' : 'event-table-row';
                          if (idx >= 0 && allParsedEventList.length > idx && allParsedEventList[idx]) {
                            const data = allParsedEventList[idx];

                            return this.getItemKey(data, selectedItem) ? `${name} selected` : name;
                          }
                          return name;
                        }}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowCount={allParsedEventList.length}
                        onRowsRendered={onRowsRendered}
                        onRowClick={({ rowData }) => {
                          this.setState({
                            selectedItem: rowData,
                          });
                        }}
                        rowGetter={({ index }) => (allParsedEventList.length > index ? allParsedEventList[index] : {})}
                        rowStyle={({ index: number }) => {
                          if (number >= 0 && allParsedEventList.length > number && allParsedEventList[number]) {
                            const data = allParsedEventList[number];
                            if (data.highlightFlag) {
                              return { backgroundColor: 'var(--virtualized-table-row-finish-bg)' };
                            } else if (
                              highlightRow &&
                              highlightRow.timestamp === data.timestamp &&
                              highlightRow.rawData === data.rawData
                            ) {
                              return { backgroundColor: 'var(--virtualized-table-row-finish-bg)' };
                            }
                          }
                          return {};
                        }}
                        sort={this.sortTable}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        scrollToAlignment={isLiveTail ? 'start' : 'end'}
                        scrollToIndex={isLiveTail ? Number.MAX_SAFE_INTEGER : -1}
                        onScroll={debounce(this.handleScroll, 300, { trailing: true, leading: false })}
                        overscanRowCount={2}
                      >
                        <Column
                          label=""
                          width={14}
                          flexShrink={0}
                          dataKey="newLiveTail"
                          style={{ height: '100%', padding: '8px 0' }}
                          cellRenderer={this.newLiveTailRender}
                        />
                        {isInfiniteLoad && (
                          <Column width={40} label="" dataKey="expansion" cellRenderer={this.expansionRender} />
                        )}
                        {hasRankNumber && (
                          <Column width={50} label={intl.formatMessage(logMessages.rankNumber)} dataKey="rankNumber" />
                        )}
                        {hasDatetime && (
                          <Column
                            width={130}
                            label={intl.formatMessage(showRangeTime ? logMessages.dateTimes : logMessages.dateTime)}
                            dataKey="datetime"
                            cellRenderer={this.loadingRendererWrapper(this.datetimeRenderer)}
                            headerRenderer={this.headerRenderer}
                            disableSort={disableSortDateTime}
                          />
                        )}
                        {hasInstanceName && (
                          <Column
                            width={120}
                            label={intl.formatMessage(appFieldsMessages.instanceName)}
                            dataKey="instanceName"
                            cellRenderer={this.logInstanceNameRenderer}
                            headerRenderer={this.headerRenderer}
                            disableSort
                          />
                        )}
                        {(hasTag || hasDecompress) && (
                          <Column
                            width={50}
                            label={intl.formatMessage(logMessages.tag)}
                            dataKey="eventType"
                            cellRenderer={this.compressBaseRenderer}
                            headerRenderer={this.headerRenderer}
                          />
                        )}
                        {combineLabels && (
                          <Column
                            width={this.labelsWidth}
                            style={{ height: '100%' }}
                            label=""
                            dataKey="datetime"
                            cellRenderer={this.labelsRenderer}
                            disableSort
                          />
                        )}
                        {!combineLabels && hasInstance && (
                          <Column
                            width={150}
                            label={intl.formatMessage(logMessages.instance)}
                            dataKey="instance"
                            cellRenderer={this.logInstanceRenderer}
                            headerRenderer={this.headerRenderer}
                          />
                        )}
                        {!combineLabels && hasType && (
                          <Column
                            width={86}
                            label={intl.formatMessage(logMessages.type)}
                            dataKey="type"
                            cellRenderer={this.logTypeRenderer}
                            disableSort
                            headerRenderer={this.headerRenderer}
                          />
                        )}
                        {!combineLabels && hasCategory && (
                          <Column
                            width={100}
                            label={intl.formatMessage(logMessages.category)}
                            dataKey="category"
                            cellRenderer={this.logCategoryRenderer}
                            disableSort
                          />
                        )}
                        {!combineLabels && hasCount && (
                          <Column
                            width={70}
                            label={intl.formatMessage(logMessages.count)}
                            dataKey="count"
                            cellRenderer={this.logCountRenderer}
                            headerRenderer={this.headerRenderer}
                          />
                        )}
                        {!combineLabels && hasNid && (
                          <Column
                            width={88}
                            label={intl.formatMessage(logMessages.patternId)}
                            dataKey="nid"
                            cellRenderer={this.logPatternIdRenderer}
                            headerRenderer={this.headerRenderer}
                          />
                        )}
                        {!combineLabels && hasProbability && (
                          <Column
                            width={80}
                            label="Probability"
                            dataKey="probability"
                            cellRenderer={CellRenderers.probability}
                            headerRenderer={this.headerRenderer}
                          />
                        )}
                        {!combineLabels && hasDelay && (
                          <Column
                            width={80}
                            label="Delay"
                            dataKey="delay"
                            cellRenderer={({ cellData }) => CellRenderers.humanizeDuration({ period: cellData, intl })}
                            headerRenderer={this.headerRenderer}
                          />
                        )}
                        <Column
                          width={this.tableWidth - this.contentWidthOffset}
                          label={intl.formatMessage(logMessages.logEntry)}
                          flexGrow={1}
                          dataKey="rawData"
                          cellRenderer={this.contentRender}
                          headerRenderer={this.headerContentRenderer}
                          disableSort
                          headerStyle={{ paddingLeft: 0 }}
                          style={{ paddingLeft: 0 }}
                        />
                        {this.showActions && (
                          <Column
                            width={120}
                            // width={140}
                            label=""
                            dataKey="datetime"
                            cellRenderer={this.actionsRenderer}
                            disableSort
                            // style={{ lineHeight: '40px', hasShowActions: true }}
                          />
                        )}
                      </Table>
                    </Spin>
                  );
                }}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </Container>

        {this.state.showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={this.state.actionName}
            incident={this.state.activeIncident}
            projectName={get(this.state.activeIncident, 'projectName')}
            eventType={get(this.state.activeIncident, 'eventType')}
            onClose={() => this.setState({ showTakeLogActionModal: false })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {this.state.showEmailAlertsModal && (
          <EventEmailAlertsModal
            incident={this.state.activeIncident}
            projectName={get(this.state.activeIncident, 'projectName')}
            onClose={() => this.setState({ showEmailAlertsModal: false })}
          />
        )}
        {this.state.showEventActionModal && (
          <EventActionModal
            incident={this.state.activeIncident}
            projectName={get(this.state.activeIncident, 'projectName')}
            onClose={() => this.setState({ showEventActionModal: false })}
          />
        )}
        {this.state.showTimeSelectModal && (
          <TimeSelectModal
            projectName={get(this.state.activeIncident, 'projectName')}
            instanceName={this.state.selectInstance}
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
            timeIntervals={1}
            showKeywordSearch
          />
        )}
        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeIncident}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            contextTime={contextTime}
            keywordFilter={contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
          />
        )}
        {showIgnoreModal && (
          <IgnoreModal
            incident={this.state.activeIncident}
            projectName={get(this.state.activeIncident, 'projectName')}
            onClose={(isConfirmed) => this.handleIgnoreConfirm(isConfirmed)}
          />
        )}
        {this.state.showExternalSystemJumpModal && (
          <ExternalSystemJumpModal
            incident={this.state.activeIncident}
            onClose={() => this.setState({ showExternalSystemJumpModal: false })}
          />
        )}

        {summarySettingsModelFlag && (
          <SummarySettingsMode
            intl={intl}
            settings={summarySettings}
            jsonFields={jsonFields}
            summaryLoading={summaryLoading}
            onClose={() => this.setState({ summarySettingsModelFlag: false })}
            onSettingChanged={this.handleSummarySettingsChanged}
          />
        )}
      </Container>
    );
  }
}

const EventTable = injectIntl(EventTableCore);

export default connect((state) => {
  const { projects, currentTheme } = state.app;
  const { credentials } = state.auth;
  const { isReadUser } = state.auth.userInfo;
  return {
    projects,
    credentials,
    isReadUser,
    currentTheme,
  };
}, {})(EventTable);
