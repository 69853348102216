import React from 'react';
import { RightSquareOutlined } from '@ant-design/icons';

const DashCellTitle = ({
  title,
  subTitle,
  type,
  fillListFlag = false,
  setShowDetail,
  reversalBold = false,
  style,
}: Object) => {
  return (
    <div className="flex-row flex-space-between" style={{ ...style, paddingBottom: 8 }}>
      <div className="flex-col">
        <div className={reversalBold ? 'font-12 light-label' : 'font-14 bold'}>{subTitle || <span>&nbsp;</span>}</div>
        <div className={reversalBold ? 'font-14 bold' : 'font-12 light-label'}>{title || <span>&nbsp;</span>}</div>
      </div>
      {fillListFlag && (
        <div
          className="full-list clickable"
          onClick={() => {
            setShowDetail(true);
          }}
          style={{ lineHeight: '20px' }}
        >
          View all <RightSquareOutlined style={{ color: 'var(--info-color)' }} />
        </div>
      )}
    </div>
  );
};

export default DashCellTitle;
