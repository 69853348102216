/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
import $ from 'jquery';
import * as R from 'ramda';

import DygraphCore from './dygraph';

const Dygraph = DygraphCore;

// Needed for dashed lines.
const calculateEmWidthInDiv = function calculateEmWidthInDiv(div) {
  const sizeSpan = document.createElement('span');
  sizeSpan.setAttribute('style', 'margin: 0; padding: 0 0 0 1em; border: 0;');
  div.appendChild(sizeSpan);
  const oneEmWidth = sizeSpan.offsetWidth;
  div.removeChild(sizeSpan);
  return oneEmWidth;
};

Dygraph.Plugins.Legend.prototype.select = function (e) {
  const xValue = e.selectedX;
  const points = e.selectedPoints;
  const row = e.selectedRow;

  const legendMode = e.dygraph.getOption('legend');
  if (legendMode === 'never' || points.length === 0) {
    this.legend_div_.style.display = 'none';
    return;
  }

  if (legendMode === 'follow') {
    // create floating legend div
    const { area } = e.dygraph.plotter_;
    const labelsDivWidth = this.legend_div_.offsetWidth;
    const labelsDivHeight = this.legend_div_.offsetHeight;

    const serieName = e.dygraph.getHighlightSeries();
    const point = R.find((p) => p.name === serieName, points) || points[0];
    const leftLegend = point.x * area.w + 50 + 10;
    const topLegend = point.y * area.h + 10;
    let itemX = $(e.dygraph.canvas_).offset().left + leftLegend;
    let itemY = $(e.dygraph.canvas_).offset().top + topLegend;

    // reset offset
    if (itemX + labelsDivWidth + 1 > $('body').width()) {
      itemX = itemX - 2 * 10 - labelsDivWidth;
    }
    if (itemY + labelsDivHeight + 1 > $('body').height()) {
      itemY = itemY - 2 * 10 - labelsDivHeight;
    }

    $('body').append(this.legend_div_);
    $(this.legend_div_).css('left', itemX);
    $(this.legend_div_).css('top', itemY);
  }

  const html = Dygraph.Plugins.Legend.generateLegendHTML(e.dygraph, xValue, points, this.one_em_width_, row);
  this.legend_div_.innerHTML = html;
  this.legend_div_.style.display = '';
};

Dygraph.Plugins.Legend.prototype.deselect = function (e) {
  const legendMode = e.dygraph.getOption('legend');
  if (legendMode !== 'always') {
    this.legend_div_.style.display = 'none';
    $('body .legend-bar').remove();
  }

  // Have to do this every time, since styles might have changed.
  const oneEmWidth = calculateEmWidthInDiv(this.legend_div_);
  this.one_em_width_ = oneEmWidth;

  const html = Dygraph.Plugins.Legend.generateLegendHTML(e.dygraph, undefined, undefined, oneEmWidth, null);
  this.legend_div_.innerHTML = html;
};

export default Dygraph;
