/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { HomeOutlined } from '@ant-design/icons';
import { Button, Select, DatePicker, Tooltip, Tabs, Breadcrumb, message } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { parseLocation, Defaults, buildUrl, getLoadStatus, GlobalParse, timeScopeControl } from '../../../common/utils';
import {
  showAppAlert,
  createLoadAction,
  createSetAction,
  updateLastActionInfo,
  ActionTypes as AppActionTypes,
} from '../../../common/app/actions';
import { State } from '../../../common/types';
import { ActionTypes } from '../../../common/dashboard/actions';
import { BaseUrls } from '../../app/Constants';
import { Container } from '../../../lib/fui/react';

import { appFieldsMessages, appButtonsMessages, appMessages, appMenusMessages } from '../../../common/app/messages';

import SystemHealthRow from './SystemHealthRow';
import GlobalCronSelectModal from './GlobalCronSelectModal';
import RecentLogsSelectorModal from './RecentLogsSelectorModal';

import GlobalZoomTimelins from './GlobalZoomTimelins';
import GlobalPanelAnomalies from './GlobalPanelAnomalies';
import GlobalPanelAlerts from './GlobalPanelAlerts';
import GlobalPanelIncidents from './GlobalPanelIncidents';
import GlobalPanelPredictions from './GlobalPanelPredictions';
import GlobalPanelDeployments from './GlobalPanelDeployments';
import GlobalPanelFixed from './GlobalPanelFixed';
import GlobalPanelActions from './GlobalPanelActions';

import GlobalPanelLineChart from './GlobalPanelLineChart';
import GlobalPanelLogQuery from './GlobalPanelLogQuery';
import GlobalPanelHistoricalMaxScores from './GlobalPanelHistoricalMaxScores';

type Props = {
  // eslint-disable-next-line
  refresh: Number,
  handleRefresh: Function,
  handleCustomerNameChange: Function,
  // eslint-disable-next-line
  handleEnvironmentChange: Function,
  handleSystemIdChange: Function,

  intl: Object,
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  credentials: Object,
  isAdmin: Boolean,
  // eslint-disable-next-line
  isReadUser: Boolean,
  userName: String,
  userList: Array<Object>,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  createSetAction: Function,
  updateLastActionInfo: Function,

  globalInfo: Array<Object>,
  globalHealthSummary: Array<Object>,
  globalHealthByInstance: Array<Object>,
  globalSystemIncidentTimelines: Array<Object>,
};

class GlobalSystemHealthViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'dashboard_global_system_health_view';

    const { location } = props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;

    this.state = {
      startTimeObj: moment.utc(startTime, Defaults.DateFormat),
      endTimeObj: moment.utc(endTime, Defaults.DateFormat),
      endTimeOpen: false,
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      view: 'list',
      currentSystem: null,
      showLatestLogsSelector: false,
      showTimeSelectModal: false,
      selectStartTimestamp: null,
      selectEndTimestamp: null,

      isLoadingZoomIn: false,
      zoomStartTime: null,
      zoomEndTime: null,

      selectSystemHealthInfo: null,
      systemLoading: {},
      systemAnomalyInstance: {},
      reloadSystem: null,
      systemRankMap: {},
      systemAlertTimelinesMap: {},
      systemIncidentTimelinesMap: {},
      systemPredictionControl: {},

      isForceReload: false,
      isAutoReload: false,

      globalHealthSummary: props.globalHealthSummary,

      activeKey: 'Insights',

      summarySettingsMap: {},
      logsummaryLoading: false,

      updateModelLoading: false,

      dataTotalCount: [],
    };
  }

  componentDidMount() {
    this.reloadData(this.props, { isForceReload: true });
    this.getReloadDataTotalCount();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, globalHealthByInstance } = this.props;
    const { zoomStartTime, zoomEndTime } = this.state;
    if (nextProps.location !== location) {
      const nextQuery = parseLocation(nextProps.location);
      const query = parseLocation(location);
      if (
        nextProps.refresh !== this.props.refresh ||
        nextQuery.refreshTime !== query.refreshTime ||
        nextQuery.environmentId !== query.environmentId ||
        nextQuery.customerName !== query.customerName ||
        nextQuery.systemId !== query.systemId
      ) {
        const isForceReload = nextProps.refresh !== this.props.refresh;
        const isAutoReload = nextQuery.refreshTime !== query.refreshTime;
        // not reload data when zoom in
        if (!isAutoReload || (isAutoReload && !zoomStartTime && !zoomEndTime)) {
          this.reloadData(nextProps, { isForceReload, isAutoReload });
        }
        this.getReloadDataTotalCount();
      }
    } else if (nextProps.globalHealthByInstance !== globalHealthByInstance) {
      this.parserSystemInstanceHealth(nextProps);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { globalHealthSummary } = this.props;
    const { reloadSystem } = this.state;
    if (reloadSystem !== prevState.reloadSystem || globalHealthSummary !== prevProps.globalHealthSummary) {
      // reload incident timelins if needed
      this.reorderDisplay(this.props);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData(props, params) {
    const { createLoadAction, location, isAdmin } = props;
    const query = parseLocation(location);
    const { environmentId, startTime, endTime, customerName, systemId } = query;
    const { isForceReload, isAutoReload } = params;
    const { systemLoading } = this.state;

    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId) {
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      const newSystemLoading = R.clone(systemLoading);
      if (!isAutoReload) newSystemLoading[systemId] = true;
      this.setState({ isForceReload, isAutoReload, systemLoading: newSystemLoading }, () => {
        createLoadAction(
          ActionTypes.LOAD_GLOBAL_HEALTH,
          {
            isAutoReload,
            environmentId,
            customerName,
            systemIds: [systemId],
            startTimestamp,
            endTimestamp,
          },
          false,
          false,
          isAutoReload ? null : this.callbackSetHealthScoreState(),
        );
      });
    }
  }

  @autobind
  callbackSetHealthScoreState() {
    return () => {
      const { location, globalInfo } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo && !systemInfo.hasAllInstanceInfo) {
        this.loadSystemData();
      }
    };
  }

  @autobind
  loadSystemData() {
    const { location, createLoadAction } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime, environmentId, systemId } = query;

    createLoadAction(
      AppActionTypes.LOAD_INFO_SYSTEM,
      {
        startTime: moment.utc(startTime, Defaults.DateFormat).startOf('day').format(Defaults.DateFormat),
        endTime: moment.utc(endTime, Defaults.DateFormat).endOf('day').format(Defaults.DateFormat),
        environmentId,
        systemName: systemId,
        // customerName,
        anomalyInstanceOnly: false,
      },
      false,
      true,
      this.callbackHandleSystemUpdate,
    );
  }

  @autobind
  callbackHandleSystemUpdate() {
    // reset reloadSystem
    this.setState({ reloadSystem: moment.utc().valueOf() });
  }

  @autobind
  reorderDisplay(props) {
    const { location, globalInfo, globalHealthSummary, globalSystemIncidentTimelines } = props;
    const { isForceReload, isAutoReload, systemLoading } = this.state;

    // get system meta data info
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo && systemInfo.hasAllInstanceInfo) {
      // load incident timelinse if need
      const needLoadIncidentIds =
        isForceReload || isAutoReload
          ? [systemId]
          : R.difference(
              [systemId],
              R.map((item) => item.id, globalSystemIncidentTimelines),
            );
      const newSystemLoading = R.clone(systemLoading);
      if (!isAutoReload) {
        if (needLoadIncidentIds.length > 0) {
          R.forEach((systemId) => {
            newSystemLoading[systemId] = true;
          }, needLoadIncidentIds);
        } else {
          newSystemLoading[systemId] = false;
        }
      }

      this.setState(
        {
          isForceReload: false,
          globalHealthSummary,
          systemLoading: newSystemLoading,
        },
        () => {
          if (needLoadIncidentIds.length > 0) {
            this.loadSystemIncidentTimelines(props, needLoadIncidentIds, isAutoReload);
          } else {
            this.setState({ isAutoReload: false });
          }
        },
      );
    }
  }

  @autobind
  loadSystemIncidentTimelines(props, systemIds, isAutoReload) {
    const { createLoadAction, location, isAdmin } = props;
    const { environmentId, startTime, endTime, customerName } = parseLocation(location);

    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime) {
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
      createLoadAction(
        ActionTypes.LOAD_GLOBAL_SYSTEM_INCIDENT_TIMELINES,
        {
          isAutoReload,
          level: 'system',
          // customerName,
          environmentId,
          systemIds,
          startTime: startTimestamp,
          endTime: endTimestamp,
        },
        false,
        false,
        this.callbackSetIncidentTimelineState(systemIds, isAutoReload),
      );
    }
  }

  @autobind
  callbackSetIncidentTimelineState(systemIds, isAutoReload) {
    return () => {
      if (isAutoReload) {
        this.setState({ isAutoReload: false });
      } else {
        const { systemLoading } = this.state;
        const newSystemLoading = R.clone(systemLoading);
        R.forEach((systemId) => {
          newSystemLoading[systemId] = false;
        }, systemIds);
        this.setState({
          systemLoading: newSystemLoading,
        });
      }
    };
  }

  @autobind
  updateState(state) {
    this.setState({ ...(state || {}) });
  }

  @autobind
  handleUpdateSystemRankMap(systemId, { rankMap, alerts, incidents }) {
    const { systemRankMap, systemAlertTimelinesMap, systemIncidentTimelinesMap } = this.state;
    const oldRankMap = systemRankMap[systemId] || {};
    const state = {
      systemRankMap: {
        ...systemRankMap,
        [systemId]: {
          ...oldRankMap,
          ...(rankMap || {}),
        },
      },
    };
    if (alerts !== undefined) {
      state.systemAlertTimelinesMap = {
        ...systemAlertTimelinesMap,
        [systemId]: alerts,
      };
    }
    if (incidents !== undefined) {
      state.systemIncidentTimelinesMap = {
        ...systemIncidentTimelinesMap,
        [systemId]: incidents,
      };
    }
    const alertTimelinesMap = state.systemAlertTimelinesMap ? state.systemAlertTimelinesMap : systemAlertTimelinesMap;
    const incidentTimelinesMap = state.systemIncidentTimelinesMap
      ? state.systemIncidentTimelinesMap
      : systemIncidentTimelinesMap;
    this.getAllProjectName(alertTimelinesMap, incidentTimelinesMap);
    this.setState(state);
  }

  @autobind
  handleUpdateSystemTimelines(systemId, params) {
    const { hideAlertsLabelKey, addAlerts, allAlerts } = params || {};
    const { hideIncidentsLabelKey, addIncidents, allIncidents } = params || {};
    const { systemAlertTimelinesMap, systemIncidentTimelinesMap } = this.state;

    const newState = {};
    if (allAlerts !== undefined) {
      // replace all
      newState.systemAlertTimelinesMap = { ...systemAlertTimelinesMap, [systemId]: allAlerts };
    } else if (hideAlertsLabelKey || addAlerts) {
      // hide
      let alerts = systemAlertTimelinesMap[systemId] || [];
      if (hideAlertsLabelKey) {
        alerts = R.filter((item) => item.labelKey !== hideAlertsLabelKey, alerts);
      }
      // add
      if (addAlerts) {
        alerts = [...alerts, ...(addAlerts || [])];
      }

      newState.systemAlertTimelinesMap = { ...systemAlertTimelinesMap, [systemId]: alerts };
    }

    if (allIncidents !== undefined) {
      // replace all
      newState.systemIncidentTimelinesMap = { ...systemIncidentTimelinesMap, [systemId]: allIncidents };
    } else if (hideIncidentsLabelKey || addIncidents) {
      // hide
      let incidents = systemIncidentTimelinesMap[systemId] || [];
      if (hideIncidentsLabelKey) {
        incidents = R.filter((item) => item.labelKey !== hideIncidentsLabelKey, incidents);
      }
      // add
      if (addIncidents) {
        incidents = [...incidents, ...(addIncidents || [])];
      }

      newState.systemIncidentTimelinesMap = { ...systemIncidentTimelinesMap, [systemId]: incidents };
    }

    const alertTimelinesMap = newState.systemAlertTimelinesMap
      ? newState.systemAlertTimelinesMap
      : systemAlertTimelinesMap;
    const incidentTimelinesMap = newState.systemIncidentTimelinesMap
      ? newState.systemIncidentTimelinesMap
      : systemIncidentTimelinesMap;
    this.getAllProjectName(alertTimelinesMap, incidentTimelinesMap);
    this.setState(newState);
  }

  @autobind
  onReloadSystemIncidentTimelines(systemIds) {
    const { systemLoading } = this.state;
    const newSystemLoading = R.clone(systemLoading);
    R.forEach((systemId) => {
      newSystemLoading[systemId] = true;
    }, systemIds);
    this.setState({ systemLoading: newSystemLoading }, () => {
      this.loadSystemIncidentTimelines(this.props, systemIds);
    });
  }

  @autobind
  parserSystemInstanceHealth(props) {
    const { selectSystemHealthInfo, systemLoading } = this.state;
    const systemId = get(selectSystemHealthInfo, 'id');
    this.setState({
      systemLoading: { ...systemLoading, [systemId]: false },
    });
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        startTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        endTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'add'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        endTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleStartOpenChange(open) {
    if (!open) {
      this.setState({ endTimeOpen: true });
    }
  }

  @autobind
  handleEndOpenChange(open) {
    this.setState({ endTimeOpen: open });
  }

  @autobind
  handleUpdateGlobalViewClick() {
    const { location } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;
    if (startTime && endTime) {
      const selectStartTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      const selectEndTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      this.setState({ showTimeSelectModal: true, selectStartTimestamp, selectEndTimestamp });
    }
  }

  @autobind
  onCloseTimeSelect(props) {
    const { intl, location, credentials, updateLastActionInfo } = this.props;
    const { startTimestamp, endTimestamp, ...rest } = props || {};
    this.setState({ updateModelLoading: true });
    if (startTimestamp && endTimestamp) {
      updateLastActionInfo();
      GlobalParse.updateGlobalView({
        intl,
        location,
        state: {
          selectStartTimestamp: startTimestamp,
          selectEndTimestamp: endTimestamp,
          ...rest,
        },
        credentials,
        callback: ({ loading, model }) => {
          if (loading) {
            this.setState({ updateModelLoading: false });
          }
          if (model) {
            this.setState({ showTimeSelectModal: false });
          }
        },
      });
    } else {
      this.setState({ showTimeSelectModal: false, updateModelLoading: false });
    }
  }

  @autobind
  handleLatestLogsClick(systemInfo) {
    return (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.setState({ showLatestLogsSelector: true, currentSystem: systemInfo });
    };
  }

  @autobind
  onChangeSystemDatazoomTime({ zoomStartTime, zoomEndTime }) {
    this.setState({ zoomStartTime, zoomEndTime });
  }

  @autobind
  onChangeSystemDropdown({ systemInfo }) {
    return (open) => {};
  }

  @autobind
  onChangeSystemAnomalyInstance(systemHealth, value) {
    const { systemLoading, systemAnomalyInstance } = this.state;
    const { location, createLoadAction, globalInfo } = this.props;
    const params = parseLocation(location);
    const { startTime, endTime, environmentId } = params;
    const systemId = systemHealth.id;
    const newState = {
      selectSystemHealthInfo: value ? systemHealth : null,
      systemAnomalyInstance: { ...systemAnomalyInstance, [systemId]: value },
    };

    const needReloadInstanceHealth = Boolean(value);

    // set loading info
    if (needReloadInstanceHealth) {
      newState.systemLoading = { ...systemLoading, [systemId]: true };
    }

    this.setState(newState, () => {
      if (needReloadInstanceHealth) {
        const environment = R.find((e) => e.id === environmentId, globalInfo || []);
        const systemList = get(environment, 'systemList', []);
        const systemInfo = R.find((system) => system.id === systemId, systemList);
        const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
        const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
        createLoadAction(
          ActionTypes.LOAD_GLOBAL_SYSTEM_INSTANCE_HEALTH,
          {
            level: 'system',
            environmentId,
            customerName: systemInfo.ownerUserName,
            systemIds: [systemId],
            startTime: startTimestamp,
            endTime: endTimestamp,
            instanceName: value,
            systemHealth,
          },
          false,
        );
      }
    });
  }

  @autobind
  onChangeSystemPredictions({ systemInfo }) {
    return (event) => {
      const { systemPredictionControl } = this.state;
      const systemId = systemInfo.id;
      const showHistoricalPredictions = event;

      this.setState({ systemPredictionControl: { ...systemPredictionControl, [systemId]: showHistoricalPredictions } });
    };
  }

  @autobind
  // eslint-disable-next-line consistent-return
  getLogsummarysettings(project) {
    const { credentials } = this.props;
    const { projectName, category } = project;
    if (!R.includes(category, ['metric'])) {
      return new Promise((resolve, reject) => {
        fetchGet(getEndpoint('logsummarysettings'), {
          ...credentials,
          projectName,
        })
          .then((summarySettings) => {
            resolve(summarySettings);
          })
          .catch((err) => {
            resolve([]);
          });
      });
    }
  }

  @autobind
  async getAllProjectName(systemAlertTimelinesMap, systemIncidentTimelinesMap) {
    const { location, globalSystemIncidentTimelines, credentials } = this.props;
    const { summarySettingsMap } = this.state;
    const query = parseLocation(location);
    const { systemId } = query;

    this.setState({ logsummaryLoading: true });

    const predictionIncidentTimelines =
      (R.find((item) => item.id === systemId, globalSystemIncidentTimelines) || {}).anomalyTimelines || [];
    const alertTimelines = get(systemAlertTimelinesMap, [systemId], []);
    const incidentTimelines = get(systemIncidentTimelinesMap, [systemId], []);

    let allProjectNameList = [];
    R.forEach(
      (item) => {
        const { projectOwner, category } = item;
        let { projectName } = item;
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
        allProjectNameList = [...allProjectNameList, { projectName, category }];
      },
      [...predictionIncidentTimelines, ...alertTimelines, ...incidentTimelines],
    );
    allProjectNameList = R.filter((item) => Boolean(item), R.uniq(allProjectNameList));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < allProjectNameList.length; i++) {
      if (!R.has(allProjectNameList[i].projectName, summarySettingsMap)) {
        // eslint-disable-next-line no-await-in-loop
        summarySettingsMap[allProjectNameList[i].projectName] = await this.getLogsummarysettings(allProjectNameList[i]);
      }
    }
    this.setState({ summarySettingsMap, logsummaryLoading: false });
  }

  @autobind
  getReloadDataTotalCount() {
    const { intl, credentials, location, globalInfo } = this.props;
    const params = parseLocation(location);
    const { startTime, endTime, environmentId, systemId } = params;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (!systemInfo) return;

    const startTimeObj = moment.utc(startTime, Defaults.DateFormat);
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat);
    const diffTimeObj = endTimeObj.diff(startTimeObj, 'days') + 1;
    const startTimestampPrev = startTimeObj.subtract(diffTimeObj, 'days').startOf('days').valueOf();
    const endTimestampPrev = endTimeObj.subtract(diffTimeObj, 'days').endOf('days').valueOf();
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
    Promise.all([
      fetchGet(getEndpoint('system-daily-info'), {
        ...credentials,
        customerName: systemInfo.ownerUserName,
        systemName: systemInfo.id,
        startTime: startTimestampPrev,
        endTime: endTimestampPrev,
      }),
      fetchGet(getEndpoint('system-daily-info'), {
        ...credentials,
        customerName: systemInfo.ownerUserName,
        systemName: systemInfo.id,
        startTime: startTimestamp,
        endTime: endTimestamp,
      }),
    ])
      .then((dataTotalCount) => {
        this.setState({ dataTotalCount });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  render() {
    const { location, push } = this.props;
    const query = parseLocation(location);
    const {
      isAdmin,
      userList,
      globalInfo,
      intl,
      loadStatus,
      userName,
      globalSystemIncidentTimelines,
      globalHealthByInstance,
      isLocalAdmin,
    } = this.props;
    const {
      startTimeObj,
      endTimeObj,
      endTimeOpen,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
      view,
      showTimeSelectModal,
      selectStartTimestamp,
      selectEndTimestamp,

      isLoadingZoomIn,
      zoomStartTime,
      zoomEndTime,

      reloadSystem,
      globalHealthSummary,
      isAutoReload,
    } = this.state;
    const { currentSystem, showLatestLogsSelector } = this.state;
    // render chart states
    const {
      systemLoading,
      systemAnomalyInstance,
      systemRankMap,
      systemAlertTimelinesMap,
      systemIncidentTimelinesMap,
      systemPredictionControl,
      activeKey,
      summarySettingsMap,
      logsummaryLoading,
      updateModelLoading,
      dataTotalCount,
    } = this.state;

    // get system list
    const { startTime, endTime, customerName, environmentId, systemId, refresh, refreshTime, forceRefreshTime } = query;
    let globalInfoFilter = globalInfo || [];
    let environment = R.find((e) => e.id === environmentId, globalInfoFilter);
    const systemList = get(environment, 'systemList', []);
    if (globalHealthSummary && globalHealthSummary.length === 0) {
      globalInfoFilter = R.filter((e) => e.id !== environmentId, globalInfoFilter);
      environment = null;
    }

    // get system health chart data
    const systemHealth = R.find((item) => item.systemId === systemId, globalHealthSummary);
    const systemInfo = R.find((system) => system.id === systemId, systemList || []);
    const showHistoricalPredictions = get(systemPredictionControl, [systemId], false);
    const isLoadingSystemChart = get(systemLoading, [systemId], false);
    const selectAnomalyInstance = get(systemAnomalyInstance, [systemId]);
    const predictionIncidentTimelines = (R.find((item) => item.id === systemId, globalSystemIncidentTimelines) || {})
      .anomalyTimelines;
    const systemHealthByInstance = R.find((item) => item.id === systemId, globalHealthByInstance) || {};
    const rankMap = get(systemRankMap, [systemId]);
    const alertTimelines = get(systemAlertTimelinesMap, [systemId], []);
    const incidentTimelines = get(systemIncidentTimelinesMap, [systemId], []);

    const { isLoading, errorMessage, errorInfo } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    return (
      <Container fullHeight withGutter className="flex-col flex-min-height">
        <Container breadcrumb className="flex-row">
          <div className="flex-grow flex-row flex-center-align">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, {}))}>
                  <HomeOutlined />
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a
                  onClick={() => push(buildUrl(BaseUrls.GlobalHealthSystemNew, {}, { ...query, systemId: undefined }))}
                >
                  {intl.formatMessage(appMenusMessages.globalHealthView)}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Select
                  showSearch
                  size="small"
                  value={systemId}
                  style={{ width: 120 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleSystemIdChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ),
                    systemList || [],
                  )}
                </Select>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Button size="small" type="primary" style={{ marginLeft: 16 }} onClick={this.handleUpdateGlobalViewClick}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </div>
          <div className="flex-row flex-center-align">
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.startDate)}</span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={startTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleStartTimeChange}
              onOpenChange={this.handleStartOpenChange}
            />
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.endDate)}</span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={endTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleEndTimeChange}
              open={endTimeOpen}
              onOpenChange={this.handleEndOpenChange}
            />

            {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.environment)}
              </span>
              <Select
                size="small"
                value={environmentId}
                style={{ width: 140 }}
                onChange={this.props.handleEnvironmentChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.name} value={item.name} title={item.name}>
                      {item.id}
                    </Select.Option>
                  ),
                  globalInfo || [],
                )}
              </Select> */}
            {(isAdmin || isLocalAdmin) && (
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
            )}
            {(isAdmin || isLocalAdmin) && (
              <Select
                showSearch
                size="small"
                value={customerName}
                style={{ width: 100 }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={this.props.handleCustomerNameChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            )}

            <div
              style={{ marginLeft: 8 }}
              onMouseEnter={() => {
                if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                  this.setState({ tooltipVisibleReloadMouseOver: true });
              }}
              onMouseLeave={() => {
                if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
              }}
            >
              <Tooltip
                mouseEnterDelay={0.3}
                placement="bottomRight"
                open={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                title={
                  disableRefresh
                    ? 'Range of days <= 31'
                    : timeChange
                    ? intl.formatMessage(appMessages.clickToReload)
                    : null
                }
              >
                <Button
                  size="small"
                  disabled={disableRefresh}
                  onClick={() => this.props.handleRefresh({ startTimeObj, endTimeObj })}
                >
                  {intl.formatMessage(appButtonsMessages.refresh)}
                </Button>
              </Tooltip>
            </div>
          </div>
        </Container>

        <Container className={`${isLoading ? 'loading ' : ''}global-view flex-grow flex-row flex-min-height`}>
          {errorMessage && (
            <Container style={{ margin: 8 }}>
              <div className={`ui mini ${errorInfo} message`}>{errorMessage}</div>
            </Container>
          )}

          {!errorMessage && systemId && (systemHealthByInstance || systemHealth) && (
            <div className="flex-grow flex-col flex-min-width flex-min-height">
              <div className="flex-row">
                <SystemHealthRow
                  userList={userList}
                  userName={userName}
                  startTime={startTime}
                  endTime={endTime}
                  customerName={customerName}
                  environmentId={environmentId}
                  view={view}
                  systemIncidentTimelines={predictionIncidentTimelines}
                  systemHealth={selectAnomalyInstance ? systemHealthByInstance : systemHealth}
                  systemInfo={systemInfo}
                  showHistoricalPredictions={showHistoricalPredictions}
                  isLoading={isLoadingSystemChart}
                  selectAnomalyInstance={selectAnomalyInstance}
                  rankMap={rankMap}
                  alertTimelines={alertTimelines}
                  incidentTimelines={incidentTimelines}
                  isLoadingZoomIn={isLoadingZoomIn}
                  summarySettingsMap={summarySettingsMap}
                  logsummaryLoading={logsummaryLoading}
                  onChangeDatazoomTime={this.onChangeSystemDatazoomTime}
                  onChangeSystemDropdown={this.onChangeSystemDropdown}
                  onChangeSystemAnomalyInstance={this.onChangeSystemAnomalyInstance}
                  onChangeSystemPredictions={this.onChangeSystemPredictions}
                  onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                />
              </div>

              {zoomStartTime && zoomEndTime && (
                <div className="flex-grow flex-col flex-min-height" style={{ margin: '0px 8px 8px' }}>
                  <GlobalZoomTimelins
                    startTime={startTime}
                    endTime={endTime}
                    customerName={customerName}
                    environmentId={environmentId}
                    refreshTime={refreshTime}
                    refresh={refresh}
                    zoomStartTime={zoomStartTime}
                    zoomEndTime={zoomEndTime}
                    selectAnomalyInstance={selectAnomalyInstance}
                    rankMap={rankMap}
                    systemHealth={systemHealth}
                    systemInfo={systemInfo}
                    summarySettingsMap={summarySettingsMap}
                    logsummaryLoading={logsummaryLoading}
                    isLoadingPanelAnomalies={this.state.isLoadingPanelAnomalies}
                    isLoadingPanelAlert={this.state.isLoadingPanelAlert}
                    isLoadingPanelIncident={this.state.isLoadingPanelIncident}
                    updateState={this.updateState}
                  />
                </div>
              )}

              <div
                className={`flex-grow flex-col flex-min-height ${zoomStartTime && zoomEndTime ? ' display-none' : ''}`}
                style={{ margin: '0px 8px 8px' }}
              >
                <Tabs
                  // size="small"
                  type="card"
                  className="full-height ant-tabs-content-full-height"
                  activeKey={activeKey}
                  onChange={(activeKey) => this.setState({ activeKey })}
                >
                  <Tabs.TabPane tab={intl.formatMessage(appMenusMessages.insights)} key="Insights">
                    <div className="full-height flex-row" style={{ minHeight: 100, paddingTop: 8 }}>
                      <GlobalPanelAnomalies
                        activeKey={activeKey}
                        startTime={startTime}
                        endTime={endTime}
                        customerName={customerName}
                        environmentId={environmentId}
                        refreshTime={refreshTime}
                        refresh={refresh}
                        zoomStartTime={zoomStartTime}
                        zoomEndTime={zoomEndTime}
                        selectAnomalyInstance={selectAnomalyInstance}
                        systemHealth={systemHealth}
                        systemInfo={systemInfo}
                        isLoading={isLoadingSystemChart}
                        isAutoReload={isAutoReload}
                        reloadSystem={reloadSystem}
                        forceRefreshTime={forceRefreshTime}
                        onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                        updateState={this.updateState}
                      />
                      <GlobalPanelAlerts
                        style={{ marginLeft: 16 }}
                        activeKey={activeKey}
                        startTime={startTime}
                        endTime={endTime}
                        customerName={customerName}
                        environmentId={environmentId}
                        refreshTime={refreshTime}
                        refresh={refresh}
                        zoomStartTime={zoomStartTime}
                        zoomEndTime={zoomEndTime}
                        selectAnomalyInstance={selectAnomalyInstance}
                        systemHealth={systemHealth}
                        systemInfo={systemInfo}
                        isLoading={isLoadingSystemChart}
                        isAutoReload={isAutoReload}
                        reloadSystem={reloadSystem}
                        forceRefreshTime={forceRefreshTime}
                        onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                        handleUpdateSystemRankMap={this.handleUpdateSystemRankMap}
                        handleUpdateSystemTimelines={this.handleUpdateSystemTimelines}
                        updateState={this.updateState}
                        dataTotalCount={dataTotalCount}
                        getReloadDataTotalCount={this.getReloadDataTotalCount}
                      />
                      <GlobalPanelIncidents
                        style={{ marginLeft: 16 }}
                        activeKey={activeKey}
                        startTime={startTime}
                        endTime={endTime}
                        customerName={customerName}
                        environmentId={environmentId}
                        refresh={refresh}
                        zoomStartTime={zoomStartTime}
                        zoomEndTime={zoomEndTime}
                        selectAnomalyInstance={selectAnomalyInstance}
                        systemHealth={systemHealth}
                        systemInfo={systemInfo}
                        isLoading={isLoadingSystemChart}
                        isAutoReload={isAutoReload}
                        reloadSystem={reloadSystem}
                        forceRefreshTime={forceRefreshTime}
                        onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                        handleUpdateSystemRankMap={this.handleUpdateSystemRankMap}
                        handleUpdateSystemTimelines={this.handleUpdateSystemTimelines}
                        updateState={this.updateState}
                        dataTotalCount={dataTotalCount}
                        getReloadDataTotalCount={this.getReloadDataTotalCount}
                      />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={intl.formatMessage(appMenusMessages.automation)} key="Prediction">
                    <div className="full-height flex-row" style={{ minHeight: 100, paddingTop: 8 }}>
                      {false && (
                        <GlobalPanelDeployments
                          activeKey={activeKey}
                          startTime={startTime}
                          endTime={endTime}
                          customerName={customerName}
                          environmentId={environmentId}
                          refresh={refresh}
                          zoomStartTime={zoomStartTime}
                          zoomEndTime={zoomEndTime}
                          selectAnomalyInstance={selectAnomalyInstance}
                          systemHealth={systemHealth}
                          systemInfo={systemInfo}
                          isLoading={isLoadingSystemChart}
                          isAutoReload={isAutoReload}
                          reloadSystem={reloadSystem}
                          onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                        />
                      )}
                      <GlobalPanelPredictions
                        activeKey={activeKey}
                        startTime={startTime}
                        endTime={endTime}
                        customerName={customerName}
                        environmentId={environmentId}
                        refresh={refresh}
                        zoomStartTime={zoomStartTime}
                        zoomEndTime={zoomEndTime}
                        selectAnomalyInstance={selectAnomalyInstance}
                        systemIncidentTimelines={predictionIncidentTimelines}
                        systemHealth={systemHealth}
                        systemInfo={systemInfo}
                        isLoading={isLoadingSystemChart}
                        isAutoReload={isAutoReload}
                        reloadSystem={reloadSystem}
                        onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                      />
                      <GlobalPanelActions
                        style={{ marginLeft: 16 }}
                        activeKey={activeKey}
                        startTime={startTime}
                        endTime={endTime}
                        customerName={customerName}
                        environmentId={environmentId}
                        refresh={refresh}
                        zoomStartTime={zoomStartTime}
                        zoomEndTime={zoomEndTime}
                        selectAnomalyInstance={selectAnomalyInstance}
                        systemIncidentTimelines={predictionIncidentTimelines}
                        incidentTimelines={incidentTimelines}
                        systemHealth={systemHealth}
                        systemInfo={systemInfo}
                        isLoading={isLoadingSystemChart}
                        isAutoReload={isAutoReload}
                        reloadSystem={reloadSystem}
                        onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                      />
                      <GlobalPanelFixed
                        style={{ marginLeft: 16 }}
                        activeKey={activeKey}
                        startTime={startTime}
                        endTime={endTime}
                        customerName={customerName}
                        environmentId={environmentId}
                        refresh={refresh}
                        zoomStartTime={zoomStartTime}
                        zoomEndTime={zoomEndTime}
                        selectAnomalyInstance={selectAnomalyInstance}
                        systemIncidentTimelines={predictionIncidentTimelines}
                        systemHealth={systemHealth}
                        systemInfo={systemInfo}
                        isLoading={isLoadingSystemChart}
                        isAutoReload={isAutoReload}
                        reloadSystem={reloadSystem}
                        onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                      />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={intl.formatMessage(appMenusMessages.linechart)} key="linechart">
                    <div className="full-height flex-row" style={{ minHeight: 100, paddingTop: 8 }}>
                      <GlobalPanelLineChart
                        activeKey={activeKey}
                        startTime={startTime}
                        endTime={endTime}
                        customerName={customerName}
                        environmentId={environmentId}
                        refresh={refresh}
                        systemHealth={systemHealth}
                        systemInfo={systemInfo}
                      />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={intl.formatMessage(appMenusMessages.logQuery)} key="logQuery">
                    <div className="full-height flex-row" style={{ minHeight: 100, paddingTop: 8 }}>
                      <GlobalPanelLogQuery
                        activeKey={activeKey}
                        startTime={startTime}
                        endTime={endTime}
                        customerName={customerName}
                        environmentId={environmentId}
                        refresh={refresh}
                        systemHealth={systemHealth}
                        systemInfo={systemInfo}
                      />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={intl.formatMessage(appMenusMessages.historicalMaxScores)}
                    key="historicalMaxScores"
                  >
                    <div className="full-height flex-row" style={{ minHeight: 100, paddingTop: 8 }}>
                      <GlobalPanelHistoricalMaxScores
                        activeKey={activeKey}
                        startTime={startTime}
                        endTime={endTime}
                        customerName={customerName}
                        environmentId={environmentId}
                        refresh={refresh}
                        systemHealth={systemHealth}
                        systemInfo={systemInfo}
                        isLoading={isLoadingSystemChart}
                        isAutoReload={isAutoReload}
                      />
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          )}
        </Container>

        {showTimeSelectModal && (
          <GlobalCronSelectModal
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            updateModelLoading={updateModelLoading}
            onClose={this.onCloseTimeSelect}
          />
        )}
        {showLatestLogsSelector && (
          <RecentLogsSelectorModal
            system={currentSystem}
            startTime={startTime}
            endTime={endTime}
            environmentId={environmentId}
            onClose={() => this.setState({ showLatestLogsSelector: false })}
          />
        )}
      </Container>
    );
  }
}

const GlobalSystemHealthView = injectIntl(GlobalSystemHealthViewCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { isAdmin, isReadUser, userName, isLocalAdmin } = state.auth.userInfo;
    const { loadStatus, globalInfo } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { globalHealthSummary, globalHealthByInstance, globalSystemIncidentTimelines } = state.dashboard;
    return {
      loadStatus,
      credentials,
      globalInfo,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      userName,
      location,
      userList,
      globalHealthSummary,
      globalHealthByInstance,
      globalSystemIncidentTimelines,
    };
  },
  { push, replace, showAppAlert, createLoadAction, createSetAction, updateLastActionInfo },
)(GlobalSystemHealthView);
