/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import * as R from 'ramda';
import { createLogic } from 'redux-logic';
import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getFeatureKeyword } from '../../apis';
import { appMessages } from '../../app/messages';

const loadFeatureKeywordLogic = createLogic({
  type: [ActionTypes.LOAD_FEATURE_KEYWORD],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getFeatureKeyword(credentials, params)
      .then((d) => {
        dispatch(createSetAction(ActionTypes.SER_FEATURE_KEYWORD, params, d));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get event Triage', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }
        done();
      });
  },
});

export default loadFeatureKeywordLogic;
