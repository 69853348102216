import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import { maxBy } from 'lodash';
import grid from 'hex-grid';
import { message, Popover } from 'antd';
import { buildUrl, parseLocation } from '../../../common/utils';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import BaseUrls from '../../app/BaseUrls';
import { PinLegendIcon } from '../../../lib/fui/icons';
import { settingsMessages } from '../../../common/settings/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

const CellSize = 40;
const CellSpacing = 2;
const CellOffsetTop = 40;
const CellTypeSpacing = 6;
const CellTypeOffsetTop = 30;

const ScorllBar = 12;

const normalColor = '#c9f074';

const RenderHonetcombItemTitle = ({
  intl,
  width,
  height,
  data = [],
  loyoutInfo = {},
  jumpToInvestigation,
  title,
  totalCount = 0,
  anomalyTotalCount = 0,
  addOffsetTop = 0,
  instanceDisplayNameMap,
}: Object) => {
  return (
    <div style={{ position: 'relative', width, height: height + CellOffsetTop, marginTop: addOffsetTop }}>
      <div className="flex-row flex-center-align" style={{ fontSize: 12 }}>
        <div className="hidden-line-with-ellipsis">{title}</div>
        <div style={{ marginLeft: 4, flexShrink: 0 }}>{` (${intl.formatMessage(
          eventMessages.totalLabel,
        )}: ${totalCount}, ${intl.formatMessage(eventMessages.anomalous)}: ${
          R.isNil(anomalyTotalCount) ? data.length : anomalyTotalCount
        })`}</div>
      </div>
      <RenderHonetcombItem
        intl={intl}
        layoutInfo={loyoutInfo}
        jumpToInvestigation={jumpToInvestigation}
        data={data}
        instanceDisplayNameMap={instanceDisplayNameMap}
      />
    </div>
  );
};

const RenderHonetcombItem = (props: Object) => {
  const { intl, layoutInfo, jumpToInvestigation, data, instanceDisplayNameMap = {} } = props;
  const { grid } = layoutInfo;

  return (
    <>
      {R.addIndex(R.map)((item, index) => {
        const { x, y } = grid[index] || {};
        const {
          value,
          color,
          count,
          instanceName,
          componentName,
          projectDisplayNameList,
          hasDetectedIncident,
          hasPredictedIncident,
          isOmitItem,
          omitNum,
          projectInfos,
        } = item;
        const total = count;

        let container = null;
        let instance = instanceName;
        if (R.includes('_', instanceName || '')) {
          const [c, i] = R.split('_', instanceName);
          container = c;
          instance = i;
        }

        if (isOmitItem) {
          return (
            <div
              key={x + y}
              style={{
                position: 'absolute',
                left: x,
                top: y + CellOffsetTop,
                width: CellSize,
                height: CellSize,
                fontSize: 12,
                color: '#1b1a1a',
              }}
            >
              <div
                className="flex-row flex-center-align flex-center-justify full-width full-height clickable omitNum"
                style={{ position: 'absolute', zIndex: 100 }}
              >{`+${omitNum}`}</div>
              <div className="honeycomb-item" style={{ width: CellSize, height: CellSize }}>
                <div className="honeycomb-bg" style={{ background: normalColor }} />
              </div>
            </div>
          );
        }

        let instanceDisplayName;
        R.forEach((p) => {
          if (!instanceDisplayName) {
            instanceDisplayName = getInstanceDisplayName(instanceDisplayNameMap, instance, {
              pn: p.projectName,
              owner: p.projectOwner,
            })?.instanceDisplayName;
          }
        }, projectInfos || []);

        return (
          grid[index] && (
            <Popover
              key={x + y}
              content={
                <div className="flex-col">
                  <div className="flex-row flex-center-align">
                    <div style={{ width: 140 }} className="light-label bold">
                      {intl.formatMessage(eventMessages.anomalyScore)}:
                    </div>
                    {Number(value.toFixed(2))}
                  </div>
                  {!!total && (
                    <div className="flex-row flex-center-align">
                      <div style={{ width: 140 }} className="light-label bold">
                        {intl.formatMessage(eventMessages.incidentCount)}:
                      </div>
                      {total}
                    </div>
                  )}
                  {container && (
                    <div className="flex-row flex-center-align">
                      <div style={{ width: 140 }} className="light-label bold">
                        {intl.formatMessage(eventMessages.containerName)}:
                      </div>
                      {container}
                    </div>
                  )}
                  <div className="flex-row flex-center-align">
                    <div style={{ width: 140 }} className="light-label bold">
                      {intl.formatMessage(eventActionMessages.instanceName)}:
                    </div>
                    {instance}
                  </div>
                  {instanceDisplayName && (
                    <div className="flex-row flex-center-align">
                      <div style={{ width: 140 }} className="light-label bold">
                        {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                      </div>
                      {instanceDisplayName}
                    </div>
                  )}

                  <div className="flex-row flex-center-align">
                    <div style={{ width: 140 }} className="light-label bold">
                      {intl.formatMessage(eventMessages.componentName)}:
                    </div>
                    {componentName}
                  </div>

                  <div className="flex-row">
                    <div style={{ width: 140 }} className="light-label bold">
                      {intl.formatMessage(eventMessages.projectName)}:
                    </div>
                    <div>
                      {projectDisplayNameList.length > 0
                        ? R.map((project) => <div key={project}>{project}</div>, projectDisplayNameList)
                        : 'unknown'}
                    </div>
                  </div>
                </div>
              }
            >
              <div
                className={`${hasPredictedIncident ? 'white-ripple' : ''} honeycomb-item`}
                style={{
                  left: x,
                  top: y + CellOffsetTop,
                  width: CellSize,
                  height: CellSize,
                }}
                onClick={() => jumpToInvestigation(item)}
              >
                {(hasPredictedIncident || hasDetectedIncident) && (
                  <PinLegendIcon
                    style={{
                      color: 'white',
                      fontSize: 20,
                      position: 'absolute',
                      left: 10,
                      top: 10,
                    }}
                  />
                )}
                <div className="honeycomb-bg" style={{ background: color }} />
                {hasPredictedIncident && <div className="white-ripple" />}
              </div>
            </Popover>
          )
        );
      }, data)}
    </>
  );
};

const RenderHonetcombChart = (props: Object) => {
  const {
    intl,
    data = [],
    layoutInfo = {},
    jumpToInvestigation,
    groupName,
    containerLayoutInfo,
    podLayoutInfo,
    hostLayoutInfo,
    containerGroup = [],
    podGroup = [],
    hostGroup = [],
    hasKubernetes,
    componentCountMap = {},
    addStyle,
    instanceDisplayNameMap,
  } = props;

  const { maxX: pmaxX = 0, maxY: pmaxY = 0 } = podLayoutInfo || {};
  const { maxX: cmaxX = 0, maxY: cmaxY = 0 } = containerLayoutInfo || {};
  const { maxX: hmaxX = 0, maxY: hmaxY = 0 } = hostLayoutInfo || {};

  let { maxX, maxY } = layoutInfo;
  maxX += CellTypeSpacing;
  maxX = maxX < 220 ? 220 : maxX;
  maxY += CellOffsetTop;
  if (hasKubernetes) {
    let multipleNum = 0;
    let multipleTop = 0;
    if (pmaxY > 0) multipleNum += 1;
    if (cmaxY > 0) multipleNum += 1;
    if (hmaxY > 0) multipleNum += 1;
    if (multipleNum > 1) multipleTop += (CellTypeOffsetTop / 2) * (multipleNum - 1);
    maxY = pmaxY + cmaxY + hmaxY + CellOffsetTop * multipleNum + multipleTop;
  }
  const countMap = componentCountMap[groupName] || {};
  const { anomalyContainerCount, anomalyInstanceCount, anomalyPodCount, containerCount, instanceCount, podCount } =
    countMap;

  return (
    <div
      className="honeycomb-group"
      style={{ position: 'relative', width: maxX, height: maxY + CellOffsetTop, ...addStyle }}
    >
      <div className="flex-row flex-center-align">
        <Popover content={`${groupName}`} placement="topLeft">
          <div className="honeycomb-title hidden-line-with-ellipsis">{`${groupName}`}</div>
        </Popover>
      </div>
      <div style={{ position: 'absolute', top: CellOffsetTop }}>
        {!hasKubernetes && (
          <RenderHonetcombItemTitle
            intl={intl}
            width={maxX}
            height={maxY}
            data={data}
            loyoutInfo={layoutInfo}
            jumpToInvestigation={jumpToInvestigation}
            title={intl.formatMessage(eventMessages.hosts)}
            totalCount={instanceCount}
            anomalyTotalCount={anomalyInstanceCount}
            instanceDisplayNameMap={instanceDisplayNameMap}
          />
        )}
        {hasKubernetes && hostGroup.length > 0 && (
          <RenderHonetcombItemTitle
            intl={intl}
            width={hmaxX}
            height={hmaxY}
            data={hostGroup}
            loyoutInfo={hostLayoutInfo}
            jumpToInvestigation={jumpToInvestigation}
            title={intl.formatMessage(eventMessages.hosts)}
            totalCount={instanceCount}
            anomalyTotalCount={anomalyInstanceCount}
            instanceDisplayNameMap={instanceDisplayNameMap}
          />
        )}
        {hasKubernetes && podGroup.length > 0 && (
          <RenderHonetcombItemTitle
            intl={intl}
            width={pmaxX}
            height={pmaxY}
            data={podGroup}
            loyoutInfo={podLayoutInfo}
            jumpToInvestigation={jumpToInvestigation}
            title={intl.formatMessage(settingsMessages.pods)}
            totalCount={podCount}
            anomalyTotalCount={anomalyPodCount}
            addOffsetTop={hostGroup.length > 0 ? CellTypeOffsetTop / 2 : 0}
            instanceDisplayNameMap={instanceDisplayNameMap}
          />
        )}
        {hasKubernetes && containerGroup.length > 0 && (
          <RenderHonetcombItemTitle
            intl={intl}
            width={cmaxX}
            height={cmaxY}
            data={containerGroup}
            loyoutInfo={containerLayoutInfo}
            jumpToInvestigation={jumpToInvestigation}
            title={intl.formatMessage(DashboardMessages.containers)}
            totalCount={containerCount}
            anomalyTotalCount={anomalyContainerCount}
            addOffsetTop={podGroup.length > 0 ? CellTypeOffsetTop / 2 : 0}
            instanceDisplayNameMap={instanceDisplayNameMap}
          />
        )}
      </div>
    </div>
  );
};

const HoneycombMapView = ({
  intl,
  honeycombByComponent,
  componentCountMap,
  systemInfo,
  location,
  hasKubernetes,
  environmentId,
  startTime,
  endTime,
  customerName,
  systemId,
  disableJumpToInvestigation,
  instanceDisplayNameMap,
}: Object) => {
  const query = parseLocation(location);
  const containerRef = useRef(null);
  const [maxScoreList, setMaxScoreList] = useState([]);
  const [componentMetaData, setComponentMetaData] = useState({});
  const [componentMap, setComponentMap] = useState({});

  const parseData = (data, cCountMap = {}) => {
    const containerEl = containerRef?.current;
    const containerWidth = containerEl ? containerEl.offsetWidth - ScorllBar : null;

    if (!containerWidth) return;

    const componentGroup = {};
    const componentOrder = {};
    R.addIndex(R.forEach)((items, idx) => {
      if (items && items.length > 0) {
        const { componentName } = items[0];
        componentGroup[componentName] = items;
        componentOrder[componentName] = idx;
      }
    }, data);

    const componentMap = R.mapObjIndexed((value, key) => {
      return R.sortWith([R.descend(R.prop('score'))])(value);
    }, componentGroup);

    let maxScoreList = [];
    R.forEachObjIndexed((value, key) => {
      const countMap = cCountMap[key] || {};
      const { containerCount, instanceCount, podCount } = countMap;
      if (!hasKubernetes && value.length > 0 && instanceCount > value.length) {
        value = [...value, { isOmitItem: true, omitNum: instanceCount - value.length }];
        componentMap[key] = value;
      }
      const anomaliesCount = value.length;
      if (anomaliesCount === 0) return;

      const maxScoreRow = maxBy(value, (cmp) => cmp.score);
      const column = Math.floor(containerWidth / (CellSize + CellSpacing * 2));
      // column = anomaliesCount < column ? anomaliesCount : column;

      const layoutInfo = grid(
        { width: (CellSize + CellSpacing * 2) * column, spacing: CellSpacing },
        { width: CellSize, height: CellSize, n: anomaliesCount },
      );
      const { grid: g } = layoutInfo;
      let { x: maxX } = maxBy(g, (g) => g.x);
      let { y: maxY } = maxBy(g, (g) => g.y);
      maxX += CellSize + CellSpacing * 2;
      maxY += CellSize + CellSpacing * 2;
      layoutInfo.maxX = maxX <= containerWidth ? containerWidth : maxX;
      layoutInfo.maxY = maxY;

      // 计算container和pod的布局
      let containerLayoutInfo = null;
      let podLayoutInfo = null;
      let hostLayoutInfo = null;
      const containerGroup = [];
      const podGroup = [];
      const hostGroup = [];
      if (hasKubernetes) {
        R.forEach((item) => {
          if (R.includes('_', item.instanceName || '')) {
            containerGroup.push(item);
          } else if (!item.k8sType) {
            hostGroup.push(item);
          } else {
            podGroup.push(item);
          }
        }, value || []);

        if (containerGroup.length > 0 && containerCount > containerGroup.length) {
          containerGroup.push({ isOmitItem: true, omitNum: containerCount - containerGroup.length });
        }
        if (podGroup.length > 0 && podCount > podGroup.length) {
          podGroup.push({ isOmitItem: true, omitNum: podCount - podGroup.length });
        }
        if (hostGroup.length > 0 && instanceCount > hostGroup.length) {
          hostGroup.push({ isOmitItem: true, omitNum: instanceCount - hostGroup.length });
        }

        const containerGroupCount = containerGroup.length;
        const podGroupCount = podGroup.length;
        const hostGroupCount = hostGroup.length;

        containerLayoutInfo =
          containerGroupCount > 0
            ? grid(
                { width: (CellSize + CellSpacing * 2) * column, spacing: CellSpacing },
                { width: CellSize, height: CellSize, n: containerGroupCount },
              )
            : null;
        podLayoutInfo =
          podGroupCount > 0
            ? grid(
                { width: (CellSize + CellSpacing * 2) * column, spacing: CellSpacing },
                { width: CellSize, height: CellSize, n: podGroupCount },
              )
            : null;
        hostLayoutInfo =
          hostGroupCount > 0
            ? grid(
                { width: (CellSize + CellSpacing * 2) * column, spacing: CellSpacing },
                { width: CellSize, height: CellSize, n: hostGroupCount },
              )
            : null;

        if (containerGroupCount > 0) {
          const { grid: cg } = containerLayoutInfo;
          let { x: cmaxX } = maxBy(cg, (g) => g.x);
          let { y: cmaxY } = maxBy(cg, (g) => g.y);
          cmaxX += CellSize + CellSpacing * 2;
          cmaxY += CellSize + CellSpacing * 2;
          containerLayoutInfo.maxX = cmaxX <= containerWidth ? containerWidth : cmaxX;
          containerLayoutInfo.maxY = cmaxY;
        }

        if (podGroupCount > 0) {
          const { grid: pg } = podLayoutInfo;
          let { x: pmaxX } = maxBy(pg, (g) => g.x);
          let { y: pmaxY } = maxBy(pg, (g) => g.y);
          pmaxX += CellSize + CellSpacing * 2;
          pmaxY += CellSize + CellSpacing * 2;
          podLayoutInfo.maxX = pmaxX <= containerWidth ? containerWidth : pmaxX;
          podLayoutInfo.maxY = pmaxY;
        }

        if (hostGroupCount > 0) {
          const { grid: pg } = hostLayoutInfo;
          let { x: hmaxX } = maxBy(pg, (g) => g.x);
          let { y: hmaxY } = maxBy(pg, (g) => g.y);
          hmaxX += CellSize + CellSpacing * 2;
          hmaxY += CellSize + CellSpacing * 2;
          hostLayoutInfo.maxX = hmaxX <= containerWidth ? containerWidth : hmaxX;
          hostLayoutInfo.maxY = hmaxY;
        }
      }

      maxScoreList.push({
        ...maxScoreRow,
        belongsKey: key,
        layoutInfo,
        containerLayoutInfo,
        podLayoutInfo,
        hostLayoutInfo,
        containerGroup,
        podGroup,
        hostGroup,
      });
    }, componentMap);

    maxScoreList = R.sort((a, b) => {
      return (componentOrder[a.belongsKey] || 0) - (componentOrder[b.belongsKey] || 0);
    }, maxScoreList);

    setComponentMap(componentMap);
    setComponentMetaData(componentMetaData);
    setMaxScoreList(maxScoreList);
  };

  useEffect(() => {
    parseData(honeycombByComponent, componentCountMap);
  }, [honeycombByComponent, componentCountMap]);

  const jumpToInvestigation = (rowData) => {
    if (disableJumpToInvestigation) return;
    const { componentName, instanceName, hasPredictedIncident } = rowData;
    if (query?.saveFlag === 'true') {
      message.warning(intl.formatMessage(eventMessages.notSaveDashboardMessage));
      return;
    }

    if (hasPredictedIncident) {
      const jumpParams = {
        environmentId,
        startTime,
        endTime,
        customerName,
        systemId,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemPrediction, {}, jumpParams), '_blank');
    } else {
      const jumpParams = {
        // set redirect flag
        redirect: true,
        environmentId,
        startTime,
        endTime,
        customerName,
        systemId,

        jumpComponentName: componentName,
        jumpInstanceName: instanceName,
      };

      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, jumpParams), '_blank');
    }
  };

  return (
    <div className="honeycomb-page full-height" ref={containerRef}>
      <div className="bold font-14 content-bg" style={{ position: 'sticky', top: 0, zIndex: 99 }}>
        Anomalous component and instance heat map
      </div>

      {maxScoreList.length > 0 ? (
        R.addIndex(R.map)((group, index) => {
          const { belongsKey, layoutInfo, containerLayoutInfo, podLayoutInfo, containerGroup, podGroup } = group;
          const { hostLayoutInfo, hostGroup } = group;
          return (
            <RenderHonetcombChart
              intl={intl}
              data={componentMap[belongsKey]}
              layoutInfo={layoutInfo}
              systemInfo={systemInfo}
              key={group.componentName + index}
              jumpToInvestigation={jumpToInvestigation}
              groupName={belongsKey}
              containerLayoutInfo={containerLayoutInfo}
              podLayoutInfo={podLayoutInfo}
              hostLayoutInfo={hostLayoutInfo}
              containerGroup={containerGroup}
              podGroup={podGroup}
              hostGroup={hostGroup}
              hasKubernetes={hasKubernetes}
              componentCountMap={componentCountMap}
              addStyle={index === 0 ? { borderTop: 'none' } : {}}
              instanceDisplayNameMap={instanceDisplayNameMap}
            />
          );
        }, maxScoreList)
      ) : (
        <div
          className="bold font-14 content-bg full-width text-center"
          style={{
            color: 'var(--text-color-secondary)',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          No data for this time period
        </div>
      )}
    </div>
  );
};

export default HoneycombMapView;
