/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Button, Col, Form, Input as AntInput, Row } from 'antd';
import * as R from 'ramda';
import _ from 'lodash';

import { CopyOutlined } from '@ant-design/icons';
import { appButtonsMessages } from '../../../../../common/app/messages';
import { CodeMirror, Container } from '../../../../../lib/fui/react';
import ElasticIni from './ElasticIni';

const ProjectAgentSettingElastic = ({
  intl,
  userInfo,
  currentProject,
  agentSystemData,
  agentData,
  onSubmit,
  currentTheme,
}) => {
  const [errMsg, setErrMsg] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [configContent, setConfigContent] = useState('');
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    esUris: agentData?.esUris || '',
    port: agentData?.port || '',
    httpAuth: agentData?.httpAuth || '',
    queryJson: agentData?.queryJson || '',
    queryJsonFile: agentData?.queryJsonFile || '',
    queryChunkSize: agentData?.queryChunkSize || 5000,
    caCerts: agentData?.caCerts || '',
    clientCert: agentData?.clientCert || '',
    clientKey: agentData?.clientKey || '',
    indeces: agentData?.indeces || 'filebeat*',
    hisTimeRange: agentData?.hisTimeRange || '',
    timestampField: agentData?.timestampField || '',
    timestampFormat: agentData?.timestampFormat || '',
    documentRootField: agentData?.documentRootField || '',
    componentField: agentData?.componentField || '',
    defaultComponentName: agentData?.defaultComponentName || '',
    instanceField: agentData?.instanceField || '',
    defaultInstanceName: agentData?.defaultInstanceName || '',
    instanceFieldRegex: agentData?.instanceFieldRegex || `message::group=\"(.*?)\",message::deployment=\"(.*?)\"`,
    instanceWhitelist: agentData?.instanceWhitelist || '',
    deviceField: agentData?.deviceField || '',
    deviceFieldRegex: agentData?.deviceFieldRegex || '',
    dataFields: agentData?.dataFields || '',
    aggregationDataFields: agentData?.aggregationDataFields || '',
  });
  const { esUris, port, httpAuth } = state;
  const { queryJson, queryJsonFile, queryChunkSize, indeces, hisTimeRange } = state;
  const { caCerts, clientCert, clientKey } = state;
  const { timestampField, timestampFormat, documentRootField } = state;
  const {
    componentField,
    defaultComponentName,
    instanceField,
    defaultInstanceName,
    instanceFieldRegex,
    instanceWhitelist,
  } = state;
  const { deviceField, deviceFieldRegex, dataFields, aggregationDataFields } = state;

  const hasError = !!errMsg;
  const isValid = !!esUris && !!queryJson && !!timestampField && !!instanceField && !!indeces;
  const hasChange = !_.isEqual(state, agentData);

  useEffect(() => {
    const defaultState = R.mapObjIndexed(() => '', state || {});
    setState({
      ...defaultState,
      ...{
        ...(agentData || {}),
        queryChunkSize: agentData?.queryChunkSize || 5000,
        indeces: agentData?.indeces || 'filebeat*',
        instanceFieldRegex: agentData?.instanceFieldRegex || `message::group=\"(.*?)\",message::deployment=\"(.*?)\"`,
      },
    });
  }, [agentData]);

  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit({ ...state }, (err) => {
      if (err) {
        setErrMsg(err);
      }
      setSubmitting(false);
    });
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(configContent);
  };

  const isDark = currentTheme === 'dark';
  const handleGenerate = () => {
    let content = ElasticIni;
    R.forEachObjIndexed((val, key) => {
      content = content.replace(`{{${key}}}`, val);
    })(state || {});

    content = content.replace('{{containerize}}', state?.deviceField ? 'YES' : 'NO');

    content = content.replace('{{userName}}', userInfo?.userName || '');
    content = content.replace('{{licenseKey}}', userInfo?.licenseKey || '');
    content = content.replace('{{systemName}}', currentProject?.systemName || '');
    content = content.replace('{{projectName}}', currentProject?.projectShortName || '');
    content = content.replace('{{projectType}}', currentProject?.isMetric ? 'metric' : 'log');
    content = content.replaceAll('{{samplingInterval}}', currentProject?.samplingInterval || 10);
    content = content.replaceAll('{{timezone}}', currentProject?.timezone || 'UTC');
    content = content.replaceAll('{{ifUrl}}', window.location.origin || '');

    setConfigContent(content);
  };

  return (
    <Container
      fullHeight
      className="flex-col overflow-y-auto"
      style={{ fontSize: 14, paddingRight: 8, rowGap: 8, width: '100%' }}
    >
      {errMsg && <Alert message="Error" description={errMsg} type="error" showIcon style={{ marginBottom: 16 }} />}
      <div>
        <Form size="small">
          <Row gutter={16}>
            <Col span={24}>
              <div style={{ marginBottom: 8 }}>
                <span className="font-14 bold" style={{ marginRight: 8 }}>
                  Elastic README:
                </span>
                <a
                  href="https://github.com/insightfinder/InsightAgent/blob/master/elasticsearch_collector/README.md"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://github.com/insightfinder/InsightAgent/blob/master/elasticsearch_collector/README.md
                </a>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="font-14 bold" style={{ marginBottom: 8 }}>
                Required Settings:
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required
                tooltip="A comma delimited list of RFC-1738 formatted urls <scheme>://[<username>:<password>@]hostname:port"
                label="Elastic uri"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={esUris}
                  onChange={(e) => setState({ esUris: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="Query"
                tooltip="Json to add to the query body"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={queryJson}
                  onChange={(e) => setState({ queryJson: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required
                label="Timestamp"
                tooltip='Field to get timestamp. If document_root_field is "", need to set the full path. For example _source.@timestamp'
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={timestampField}
                  onChange={(e) => setState({ timestampField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="Instance"
                tooltip="Field used to get instance name. If no instance given, the agent server name will be used."
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={instanceField}
                  onChange={(e) => setState({ instanceField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Indices"
                tooltip="Indexes to search over, example indeces = metricbeat*"
                style={{ marginBottom: 8 }}
                labelCol={2}
                required
              >
                <AntInput
                  value={indeces}
                  onChange={(e) => setState({ indeces: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <div className="font-14 bold" style={{ marginBottom: 8, marginTop: 8 }}>
                Optional Settings:
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Port"
                tooltip="Port to connect to (Can be parsed from url)"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput value={port} onChange={(e) => setState({ port: e.target.value })} visibilityToggle={false} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Username and password"
                tooltip="username:password (Can be parsed from url)"
                style={{ marginBottom: 8 }}
                labelCol={4}
              >
                <AntInput
                  value={httpAuth}
                  onChange={(e) => setState({ httpAuth: e.target.value })}
                  placeholder="user:password"
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Ca bundle" tooltip="Path to ca bundle" style={{ marginBottom: 8 }} labelCol={2}>
                <AntInput
                  value={caCerts}
                  onChange={(e) => setState({ caCerts: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Client cert" tooltip="Path to client cert" style={{ marginBottom: 8 }} labelCol={4}>
                <AntInput
                  value={clientCert}
                  onChange={(e) => setState({ clientCert: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Client key" tooltip="Path to client key" style={{ marginBottom: 8 }} labelCol={2}>
                <AntInput
                  value={clientKey}
                  onChange={(e) => setState({ clientKey: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Query json file"
                tooltip="Json file to add to the query body"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={queryJsonFile}
                  onChange={(e) => setState({ queryJsonFile: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Query chunk size"
                tooltip="The maximum messages number of each query, default is 5000, and max value id 10000."
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={queryChunkSize}
                  onChange={(e) => setState({ queryChunkSize: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Document root"
                tooltip='Document root field, default is "_source". To use the whole document as the root, use ""'
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={documentRootField}
                  onChange={(e) => setState({ documentRootField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Time range"
                tooltip="History data time config. If these options is set, the agent will query results by time range time range, Example: 2020-04-14 00:00:00,2020-04-15 00:00:00"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={hisTimeRange}
                  onChange={(e) => setState({ hisTimeRange: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Timestamp format"
                tooltip="Timestamp format used to parse timestamp string"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={timestampFormat}
                  onChange={(e) => setState({ timestampFormat: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Component" tooltip="Field to get the component name" style={{ marginBottom: 8 }}>
                <AntInput
                  value={componentField}
                  onChange={(e) => setState({ componentField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Default component"
                tooltip="Default component name if cannot get from component field"
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={defaultComponentName}
                  onChange={(e) => setState({ defaultComponentName: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Instance regex"
                tooltip="A field name and a regex string used to get instance. Syntax <field1>::<regex1>,<field2>::<regex2>"
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={instanceFieldRegex}
                  onChange={(e) => setState({ instanceFieldRegex: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Instance whitelist"
                tooltip="A regex string used to define which instances will be filtered"
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={instanceWhitelist}
                  onChange={(e) => setState({ instanceWhitelist: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Device"
                tooltip="Field to used to get device info, Ex: device_name_part1,device_name_part2"
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={deviceField}
                  onChange={(e) => setState({ deviceField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Device regex"
                tooltip="Optional regex to retrieve the device name using a capture group named 'device' (eg: '(?P<device>.*)')"
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={deviceFieldRegex}
                  onChange={(e) => setState({ deviceFieldRegex: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Data"
                tooltip="Multiple fields are separated by commas, a field can be <name> or regex. Example: data_fields = /^system\.filesystem.*/,system.process.cgroup.memory.memsw.events.max. If it is empty, the whole document at the document root will be sent."
                style={{ marginBottom: 8 }}
              >
                {' '}
                <AntInput
                  value={dataFields}
                  onChange={(e) => setState({ dataFields: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Aggregation data"
                tooltip="Multiple fields with string or regex, are separated by commas, like: /0-metric\.values\.99.0/,value,doc_count"
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={aggregationDataFields}
                  onChange={(e) => setState({ aggregationDataFields: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Default instance"
                tooltip="Default instance name if cannot get from instance field"
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={defaultInstanceName}
                  onChange={(e) => setState({ defaultInstanceName: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="flex-row">
        <Button type="primary" disabled={hasError || !isValid} onClick={handleGenerate}>
          Generate config
        </Button>
        <div className="flex-grow" />
        <Button
          type="primary"
          disabled={hasError || !hasChange || !isValid}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          {intl.formatMessage(appButtonsMessages.update)}
        </Button>
      </div>
      <div className="flex-grow overflow-y-auto overflow-x-auto" style={{ minHeight: 480 }}>
        <div style={{ position: 'absolute', right: 20, fontSize: 18, zIndex: 10 }}>
          <CopyOutlined onClick={handleCopy} />
        </div>
        <CodeMirror
          className="codeMirror_auto"
          options={{
            lineNumbers: false,
            theme: isDark ? 'material' : 'xq-light',
            readOnly: false,
          }}
          value={configContent}
          onBeforeChange={(editor, data, value) => {
            setConfigContent(value);
          }}
        />
      </div>
    </Container>
  );
};

export default ProjectAgentSettingElastic;
