/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { updateLastActionInfo } from '../../app/actions';
import { postLogStructure } from '../../apis';
import { apiEpicErrorHandle } from '../../errors';
import { getLoaderEpicAction } from '../../utils';

const saveLogStructureEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('SAVE_LOGSTRUCTURE_SETTINGS').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(postLogStructure(credentials, params))
      .concatMap(() => hideLoader)
      .catch((err) => {
        return Observable.concat(apiEpicErrorHandle(err), hideLoader);
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default saveLogStructureEpic;
