/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { message, Alert, Form, Input, Select, Button, Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { settingsButtonMessages } from '../../../common/settings/messages';
import { appButtonsMessages, appMessages } from '../../../common/app/messages';

type Props = {
  activeIncident: Object,
  companies: Array,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  isAdmin: boolean,
};

class AddIDPLDAPSModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { activeIncident } = props;
    this.state = {
      isSubmiting: false,
      errMsg: null,
      isVerify: false,
      verified: undefined,

      idpName: get(activeIncident, 'idpName', ''),
      clientId: get(activeIncident, 'clientId', ''),
      bindUserID: get(activeIncident, 'bindUserID', ''),
      groupList: get(activeIncident, 'groupList', []),
      clientSecret: get(activeIncident, 'clientSecret', ''),
      domainName: get(activeIncident, 'domainName', ''),
      serverAddress: get(activeIncident, 'serverAddress', ''),
      loginAttribute: get(activeIncident, 'loginAttribute', ''),
      emailDomain: get(activeIncident, 'emailDomain', ''),
    };
    this.roleOptions = [];
    this.ruleStrMap = {
      ReadOnlyUser: 'ReadOnlyUser',
      LocalAdmin: 'Administrator',
      NormalUser: 'NormalUser',
    };
    this.sortByMap = {
      ReadOnlyUser: 0,
      NormalUser: 1,
      LocalAdmin: 2,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  reloadData(props) {
    const { intl, credentials } = props;

    this.setState({ isLoading: true });
    props.updateLastActionInfo();
    fetchGet(getEndpoint('userroleconfig'), {
      ...credentials,
    })
      .then((data) => {
        let roleList = data.updateRoles || [];
        roleList = R.filter((item) => item.roleName !== 'Admin', roleList);
        roleList = R.map((item) => ({ ...item, order: this.sortByMap[item.roleName] }), roleList);
        this.roleOptions = R.sortWith([R.ascend(R.prop('order'))], roleList);

        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.roleOptions = [];
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false });
      });
  }

  @autobind
  handleSumbit() {
    const { intl, credentials, onClose } = this.props;
    const {
      idpName,
      clientId,
      bindUserID,
      groupList,
      clientSecret,
      domainName,
      serverAddress,
      loginAttribute,
      company,
      emailDomain,
    } = this.state;
    const settings = [
      {
        key: {
          identityProviderType: 'LDAPS',
          clientId,
        },
        idpName,
        bindUserID,
        groupListString: JSON.stringify(groupList),
        clientSecret,
        domainName,
        serverAddress,
        loginAttribute,
        company,
        emailDomain,
      },
    ];

    this.setState({ isSubmiting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('idpsetting'), {
      ...credentials,
      identityProviderType: 'LDAPS',
      settings: JSON.stringify(settings),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmiting: false });
          onClose(true);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  @autobind
  handleVerify() {
    const { intl, credentials } = this.props;
    const {
      idpName,
      clientId,
      bindUserID,
      groupList,
      clientSecret,
      domainName,
      serverAddress,
      loginAttribute,
      emailDomain,
    } = this.state;
    const settings = [
      {
        key: {
          identityProviderType: 'LDAPS',
          clientId,
        },
        idpName,
        bindUserID,
        groupListString: JSON.stringify(groupList),
        clientSecret,
        domainName,
        serverAddress,
        loginAttribute,
        emailDomain,
      },
    ];

    this.setState({ isVerify: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('idpSettingVerify'), {
      ...credentials,
      identityProviderType: 'LDAPS',
      settings: JSON.stringify(settings),
    })
      .then((data) => {
        const { success } = data || {};
        if (success) {
          this.setState({ isVerify: false, verified: true });
        } else {
          this.setState({ isVerify: false, verified: false });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isVerify: false, verified: false });
      });
  }

  @autobind
  handleAddGroupClick() {
    const { groupList } = this.state;
    this.setState({ groupList: [...(groupList || []), { groupName: '', roleName: '' }], verified: undefined }, () => {
      if (this.listNode) {
        this.listNode.scrollTop = this.listNode.scrollHeight;
      }
    });
  }

  @autobind
  handleRemoveGroupClick(index) {
    const { groupList } = this.state;
    this.setState({ groupList: R.remove(index, 1, groupList || []), verified: undefined });
  }

  render() {
    const { intl, activeIncident, onClose, isAdmin, companies } = this.props;
    const {
      isSubmiting,
      errMsg,
      isVerify,
      verified,
      isLoading,
      idpName,
      bindUserID,
      groupList,
      clientSecret,
      domainName,
      serverAddress,
      loginAttribute,
      company,
      emailDomain,
    } = this.state;
    const hasError =
      !idpName ||
      !bindUserID ||
      !(
        groupList.length > 0 &&
        R.reduce(
          R.and,
          true,
          R.map((item) => item.groupName && item.roleName, groupList),
        )
      ) ||
      !clientSecret ||
      !domainName ||
      !serverAddress ||
      !loginAttribute ||
      (!company && isAdmin) ||
      (!emailDomain && isAdmin) ||
      !verified;
    return (
      <Modal
        width={850}
        title={activeIncident ? activeIncident.idpName : intl.formatMessage(settingsButtonMessages.addIdentityProvider)}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSumbit}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
            {isAdmin && (
              <Form.Item
                label="Company"
                validateStatus={!company ? 'error' : 'success'}
                help={!company ? 'This input is required!' : undefined}
                required
              >
                <Select
                  showSearch
                  value={company}
                  onChange={(val) => {
                    this.setState({ company: val });
                  }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item} value={item} title={item}>
                        {item}
                      </Select.Option>
                    ),
                    companies || [],
                  )}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="Global External Service Provider Name"
              validateStatus={!idpName ? 'error' : 'success'}
              help={!idpName ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={idpName}
                onChange={(e) => this.setState({ idpName: e.target.value, verified: undefined })}
              />
            </Form.Item>
            <Form.Item
              label="Bind User Id"
              validateStatus={!bindUserID ? 'error' : 'success'}
              help={!bindUserID ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={bindUserID}
                onChange={(e) => this.setState({ bindUserID: e.target.value, verified: undefined })}
              />
            </Form.Item>
            <Form.Item label="Group List" required>
              <div style={{ marginBottom: 8 }}>
                <Button size="small" type="primary" onClick={this.handleAddGroupClick}>
                  {intl.formatMessage(appButtonsMessages.add)}
                </Button>
              </div>
              <div className="event-list">
                <div className="event-list-header" style={{ height: 30 }}>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    Group name
                  </div>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    Role name
                  </div>
                  <div className="header-column" style={{ width: 110 }} />
                </div>
                <div
                  className="event-list-grid overflow-y-auto"
                  style={{ maxHeight: 100 }}
                  ref={(el) => {
                    this.listNode = el;
                  }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                        style={{ minHeight: 30 }}
                      >
                        <div className="row-column" style={{ width: 100, flex: 1 }}>
                          <Input
                            size="small"
                            value={item.groupName}
                            onChange={(e) => {
                              const { groupList } = this.state;
                              this.setState({
                                groupList: update(groupList, {
                                  [index]: { $set: { ...(item || {}), groupName: e.target.value } },
                                }),
                              });
                            }}
                          />
                        </div>
                        <div className="row-column" style={{ width: 100, flex: 1 }}>
                          <Select
                            size="small"
                            value={item.roleName}
                            onChange={(roleName) => {
                              const { groupList } = this.state;
                              this.setState({
                                groupList: update(groupList, {
                                  [index]: { $set: { ...(item || {}), roleName } },
                                }),
                              });
                            }}
                          >
                            {R.map((option) => {
                              return (
                                <Select.Option key={option.roleName} value={option.roleName}>
                                  {this.ruleStrMap[option.roleName]}
                                </Select.Option>
                              );
                            }, this.roleOptions)}
                          </Select>
                        </div>
                        <div className="row-column" style={{ width: 110 }}>
                          <Button
                            size="small"
                            className="button-color-grey"
                            onClick={() => this.handleRemoveGroupClick(index)}
                          >
                            {intl.formatMessage(appButtonsMessages.remove)}
                          </Button>
                        </div>
                      </div>
                    );
                  }, groupList || [])}
                </div>
              </div>
            </Form.Item>
            <Form.Item
              label="Client Secret"
              validateStatus={!clientSecret ? 'error' : 'success'}
              help={!clientSecret ? 'This input is required!' : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={clientSecret}
                onChange={(e) => this.setState({ clientSecret: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Domain Name"
              validateStatus={!domainName ? 'error' : 'success'}
              help={!domainName ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={domainName}
                onChange={(e) => this.setState({ domainName: e.target.value, verified: undefined })}
              />
            </Form.Item>
            <Form.Item
              label="Server Address"
              validateStatus={!serverAddress ? 'error' : 'success'}
              help={!serverAddress ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={serverAddress}
                onChange={(e) => this.setState({ serverAddress: e.target.value, verified: undefined })}
              />
            </Form.Item>
            <Form.Item
              label="Login Attribute"
              validateStatus={!loginAttribute ? 'error' : 'success'}
              help={!loginAttribute ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={loginAttribute}
                onChange={(e) => this.setState({ loginAttribute: e.target.value, verified: undefined })}
              />
            </Form.Item>
            {isAdmin && (
              <Form.Item
                label="Email Domain"
                validateStatus={!emailDomain ? 'error' : 'success'}
                help={!emailDomain ? 'This input is required!' : undefined}
                required
              >
                <Input
                  value={emailDomain}
                  onChange={(e) => this.setState({ emailDomain: e.target.value, verified: undefined })}
                />
              </Form.Item>
            )}
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 },
              }}
            >
              <div className="flex-row flex-center-align">
                <Button
                  size="small"
                  type="primary"
                  style={{ marginRight: 16 }}
                  loading={isVerify}
                  onClick={this.handleVerify}
                >
                  {intl.formatMessage(appButtonsMessages.verify)}
                </Button>
                {verified === true && <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 16 }} />}
                {verified === false && <CloseCircleOutlined style={{ color: '#f5222d', fontSize: 16 }} />}
              </div>
            </Form.Item>

            {errMsg && (
              <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 8 },
                }}
              >
                <Alert message={errMsg} type="error" showIcon />
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const AddIDPLDAPSModal = injectIntl(AddIDPLDAPSModalCore);
export default connect(
  (state) => {
    const { userInfo, credentials } = state.auth;
    const { isAdmin } = state.auth.userInfo;
    return { userInfo, credentials, isAdmin };
  },
  { updateLastActionInfo },
)(AddIDPLDAPSModal);
