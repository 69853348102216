import React, { useCallback } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { Popover as PopoverCore } from 'antd';

// eslint-disable-next-line react/prop-types
const Popover = ({ children, ...rest }) => {
  const modifyChildren = useCallback((element) => {
    if (!element || ['Number', 'String', 'Boolean', 'Array'].includes(R.type(element))) {
      return <span className="children-element">{element}</span>;
    }
    const className = classNames(element?.props?.className, { 'children-element': true });
    const props = {
      className,
    };
    return <element.type {...element.props} {...props} />;
  }, []);

  return <PopoverCore {...rest}>{modifyChildren(children)}</PopoverCore>;
};

export default Popover;
