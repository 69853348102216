import React from 'react';
import { Alert, Button, DatePicker, Layout, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../common/app/messages';
import { State } from '../../common/types';
import { Defaults, parseJSON, parseLocation, timeScopeControl } from '../../common/utils';
import { Container } from '../../lib/fui/react';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import HoneycombMapView from './widgets/HoneycombMapView';
import { scoreLevel } from '../serviceMap/component/ServiceMapCanvas';
import { CodeSandboxOutlined, DatabaseOutlined, ReloadOutlined } from '@ant-design/icons';
import { isArray, isObject } from 'lodash';

const colors = ['#c9f074', '#fed36f', '#feb04c', '#fd903d', '#fa4a29', '#eb2c21', '#a40126', '#920025'];

type Props = {
  intl: Object,
  location: Object,
  isDark: boolean,
};

class EmbedSystemInsightsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const query = parseLocation(props.location);
    const { systemId, tenant } = query;
    const zoneName = !tenant || tenant === `all_zone_${systemId}` ? '__all__' : tenant;

    this.state = {
      isLoading: false,
      startTimeObj: null,
      endTimeObj: null,
      isInvalidParams: false,
      endTimeOpen: false,
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,
      honeycombByComponent: [],
      componentCountMap: {},
      hasKubernetes: true,
      systemInfo: {},
      userInfo: {},
      selectedZone: zoneName,
    };
  }

  componentDidMount() {
    this.reloadAll(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.systemId !== query.systemId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.zoneName !== query.zoneName
    ) {
      this.reloadAll(nextProps);
    }
  }

  @autobind
  handleRefreshClick() {
    this.reloadData(this.props);
  }

  @autobind
  getZoneOptions(zoneSet, systemId) {
    let zoneOptions = R.filter((x) => x !== `zone_${systemId}`, zoneSet).map((item) => ({
      value: item,
      label: item,
    }));
    zoneOptions = [
      { value: '__all__', label: 'All zones' },
      {
        value: `zone_${systemId}`,
        label: 'No zones',
      },
      ...zoneOptions,
    ];
    return zoneOptions;
  }

  @autobind
  reloadAll(props) {
    const { location } = props;
    const query = parseLocation(location);
    const { systemId, customerName, tenant, jwtToken } = query;
    if (!systemId || !customerName || !jwtToken) {
      this.setState({ isInvalidParams: true });
      return;
    }
    const zoneName = tenant || `all_zone_${systemId}`;

    const { startTime, endTime } = query;
    const startTimestamp = moment
      .utc(startTime || moment.utc(), Defaults.DateFormat)
      .startOf('day')
      .valueOf();
    const endTimestamp = moment
      .utc(endTime || moment.utc(), Defaults.DateFormat)
      .endOf('day')
      .valueOf();

    this.setState({ isLoading: true }, () => {
      // Use current time to get the timezone
      fetchGet(getEndpoint('systemframework-jwt', 2), {
        systemName: systemId,
        customerName,
        zoneName,
        jwt: jwtToken,
        startTime: startTimestamp,
        endTime: endTimestamp,
      })
        .then((data) => {
          let { ownSystemArr, shareSystemArr } = data;
          ownSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system };
          }, ownSystemArr || []);
          shareSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system, isShared: true };
          }, shareSystemArr || []);
          const systemsMap = {};
          R.forEach(
            (system) => {
              const { systemDisplayName, systemKey, projectDetailsList, basicInfoArr = [], isShared, ...rest } = system;
              const { environmentName, systemName: systemId, userName } = systemKey || {};
              let zoneSet = [];
              if (basicInfoArr.length > 0) {
                const basicInfo = parseJSON(basicInfoArr[0]?.basicInfo) || {};
                const zoneSetJson = parseJSON(basicInfo?.zoneSet);
                if (isArray(zoneSetJson)) {
                  zoneSet = zoneSetJson;
                } else if (isObject(zoneSetJson)) {
                  zoneSet = R.keys(zoneSetJson);
                }
              }
              const zoneOptions = this.getZoneOptions(zoneSet, systemId);

              let newProjects = parseJSON(projectDetailsList) || [];
              newProjects = R.map((item) => {
                const { userName, projectClassType, ...restItem } = item;
                return {
                  ...restItem,
                  isShared,
                  customerName: userName,
                  projectType: projectClassType,
                };
              }, newProjects);
              if (!systemsMap[systemId]) {
                systemsMap[systemId] = {
                  ...rest,
                  isShared,
                  environmentName,
                  systemId,
                  systemName: systemDisplayName || systemId,
                  owner: userName,
                  projectDetailsList: newProjects,
                  zoneOptions,
                };
              }
            },
            [...ownSystemArr, ...shareSystemArr],
          );

          const systemInfo = systemsMap?.[systemId] || {};
          const timezone = systemInfo?.timezone || 'UTC';
          const zone = momenttz.tz(timezone);
          const timezoneOffset = zone.utcOffset();
          const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;

          let { startTime, endTime } = query;
          if (!startTime) {
            startTime = moment.utc(nowTimestamp).subtract(6, 'days').format(Defaults.DateFormat);
          }
          if (!endTime) {
            endTime = moment.utc(nowTimestamp).format(Defaults.DateFormat);
          }

          const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
          const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

          const userInfo = { userName: customerName };
          const startTimeObj = moment.utc(startTimestamp);
          const endTimeObj = moment.utc(endTimestamp);
          // const hasKubernetes = !!R.find(
          //   (item) => R.includes('Kubernetes', item?.cloudType || ''),
          //   systemInfo?.projectDetailsList || [],
          // );
          const hasKubernetes = true;

          this.setState({ startTimeObj, endTimeObj, systemInfo, userInfo, hasKubernetes }, () => {
            this.reloadData(props);
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false, isInvalidParams: false });
        });
    });
  }

  @autobind
  reloadData(props) {
    const { location } = props;
    const { startTimeObj, endTimeObj, systemInfo, userInfo, selectedZone } = this.state;
    const query = parseLocation(location);
    const { systemId, customerName, tenant, jwtToken } = query;

    const zoneName = tenant || `all_zone_${systemId}`;
    const zoneList = selectedZone
      ? selectedZone === '__all__'
        ? JSON.stringify([])
        : JSON.stringify([selectedZone])
      : undefined;

    this.setState({ isLoading: true }, () => {
      const requests = [];
      requests.push(
        fetchGet(getEndpoint('honeycomb-data-jwt'), {
          systemName: systemId,
          customerName,
          jwt: jwtToken,
          zoneName,
          zoneList,
          startTime: startTimeObj.valueOf(),
          endTime: endTimeObj.valueOf(),
        }),
      );

      Promise.all(requests)
        .then((results) => {
          const projects = systemInfo?.projectDetailsList || [];
          const { componentCountMap: cCountMap, honeycombByComponent } = results[0];
          R.forEach((items) => {
            R.forEach((item) => {
              const { incidentCount, projectSet } = item;
              const projectDisplayNameList = [];
              R.forEach((project) => {
                const projectInfo = R.find((item) => item.projectShortName === project, projects || []);
                projectDisplayNameList.push(projectInfo?.projectDisplayName || project);
              }, projectSet || []);
              item.projectDisplayNameList = projectDisplayNameList;
              item.value = item.score < 1 ? item.score : Math.floor(item.score);
              item.count = incidentCount;
              item.color = colors[scoreLevel(item.score, colors)];
            }, items);
          }, honeycombByComponent);

          this.setState({
            startTimeObj,
            endTimeObj,
            systemInfo,
            userInfo,
            isLoading: false,
            isInvalidParams: false,
            honeycombByComponent,
            componentCountMap: cCountMap,
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false, isInvalidParams: false, honeycombByComponent: {}, componentCountMap: {} });
        });
    });
  }

  @autobind
  handleStartOpenChange(open) {
    if (!open) {
      this.setState({ endTimeOpen: true });
    }
  }

  @autobind
  handleEndOpenChange(open) {
    this.setState({ endTimeOpen: open });
  }
  @autobind
  handleStartTimeChange(timeObj) {
    const { endTimeObj } = this.state;
    const startTimeObj = moment.utc(timeObj.valueOf());
    const timeChange = startTimeObj.format(Defaults.DateFormat) !== this.state.startTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    disableRefresh = false;
    this.setState({
      startTimeObj,
      endTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'add'),
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
    });
    if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
  }

  @autobind
  handleEndTimeChange(timeObj) {
    if (!timeObj) return;
    const { startTimeObj } = this.state;
    const endTimeObj = moment.utc(timeObj.valueOf());
    const timeChange = endTimeObj.format(Defaults.DateFormat) !== this.state.endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    disableRefresh = false;

    this.setState({
      startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
      endTimeObj,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
    });

    if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
  }
  @autobind
  onChangeFilterZone(zone) {
    this.setState(
      {
        selectedZone: zone,
      },
      () => {
        this.handleRefreshClick();
      },
    );
  }

  render() {
    const { intl, location } = this.props;
    const { isInvalidParams } = this.state;
    const { isLoading, startTimeObj, endTimeObj } = this.state;
    const { endTimeOpen, tooltipVisibleReloadMouseOver, disableRefresh, timeChange, tooltipVisibleReload } = this.state;
    const { honeycombByComponent, componentCountMap, hasKubernetes } = this.state;
    const { systemInfo, userInfo, selectedZone } = this.state;
    const query = parseLocation(location);
    const { tenant, systemId } = query;
    const showZoneOptions = !tenant || tenant === `all_zone_${systemId}`;
    const zoneOptions = systemInfo?.zoneOptions || [];

    return (
      <Layout className="app-centric-page" style={{ minHeight: '100vh' }}>
        <Container fullHeight withGutter className="flex-col corner-10" style={{ borderRadius: 10 }}>
          <div className="flex-row flex-space-between" style={{ margin: '8px 16px 16px 16px', alignItems: 'end' }}>
            <div className="flex-grow" />
            {showZoneOptions && (
              <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                <span style={{ fontWeight: 700, padding: '0 1em' }}>Zone</span>
                <Select
                  showArrow={false}
                  showSearch
                  size="small"
                  style={{ width: 360, marginLeft: 8 }}
                  optionFilterProp="children"
                  value={selectedZone}
                  onChange={this.onChangeFilterZone}
                  filterOption={(input, option) => {
                    return option && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  dropdownMatchSelectWidth={false}
                >
                  {zoneOptions.map((item) => (
                    <Select.Option key={item.value} title={item.label}>
                      <div className="flex-row">
                        <span style={{ width: 180 }}>{item.label}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            <div className="flex-row flex-end-justify flex-center-align" style={{ fontSize: 12 }}>
              {startTimeObj && endTimeObj && (
                <>
                  <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                    <span style={{ fontWeight: 700, padding: '0 1em' }}>
                      {intl.formatMessage(appFieldsMessages.startDate)}
                    </span>
                    <DatePicker
                      size="small"
                      allowClear={false}
                      showToday
                      value={startTimeObj}
                      disabledDate={(current) => {
                        return current && current > moment.utc().add(1, 'days').endOf('day');
                      }}
                      onChange={this.handleStartTimeChange}
                      onOpenChange={this.handleStartOpenChange}
                    />
                  </div>
                  <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
                    <span style={{ fontWeight: 700, padding: '0 1em' }}>
                      {intl.formatMessage(appFieldsMessages.endDate)}
                    </span>
                    <DatePicker
                      size="small"
                      allowClear={false}
                      showToday
                      value={endTimeObj}
                      disabledDate={(current) => {
                        return current && current > moment.utc().add(1, 'days').endOf('day');
                      }}
                      onChange={this.handleEndTimeChange}
                      open={endTimeOpen}
                      onOpenChange={this.handleEndOpenChange}
                    />
                  </div>
                </>
              )}
              <div
                className="header-operation"
                onMouseEnter={() => {
                  if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                    this.setState({ tooltipVisibleReloadMouseOver: true });
                }}
                onMouseLeave={() => {
                  if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
                }}
              >
                <Tooltip
                  mouseEnterDelay={0.3}
                  placement="bottomRight"
                  visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                  title={
                    disableRefresh
                      ? 'Range of days <= 31'
                      : timeChange
                      ? intl.formatMessage(appMessages.clickToReload)
                      : null
                  }
                >
                  <Button
                    icon={<ReloadOutlined />}
                    size="small"
                    disabled={disableRefresh}
                    onClick={this.handleRefreshClick}
                    style={{ marginLeft: 8 }}
                  >
                    {intl.formatMessage(appButtonsMessages.refresh)}
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
          <Container
            fullHeight
            className="flex-grow flex-col flex-min-height content-bg corner-10"
            style={{ padding: 8, margin: '0px 16px 8px' }}
          >
            {isInvalidParams && (
              <Alert message="Warning" description="Missing or invalid parameters" type="warning" showIcon />
            )}
            {!isInvalidParams && startTimeObj && endTimeObj && (
              <Spin
                spinning={isLoading}
                wrapperClassName="full-height overflow-x-hidden overflow-y-auto full-width spin-base"
              >
                <HoneycombMapView
                  hasKubernetes={hasKubernetes}
                  intl={intl}
                  honeycombByComponent={honeycombByComponent}
                  componentCountMap={componentCountMap}
                  systemInfo={systemInfo}
                  systemId={systemInfo?.systemId || systemId}
                  environmentId="All"
                  startTime={startTimeObj}
                  endTime={endTimeObj}
                  customerName={userInfo?.customerName}
                  disableJumpToInvestigation
                />
              </Spin>
            )}
          </Container>
        </Container>
      </Layout>
    );
  }
}

const EmbedSystemInsights = injectIntl(EmbedSystemInsightsCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { dark } = parseLocation(location);
  if (dark) {
    state.app.currentTheme = 'dark';
  } else {
    state.app.currentTheme = 'light';
  }

  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return { location, isDark };
}, {})(EmbedSystemInsights);
