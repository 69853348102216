/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Button, Col, Form, Input as AntInput, Row } from 'antd';
import * as R from 'ramda';
import _ from 'lodash';

import { CopyOutlined } from '@ant-design/icons';
import { appButtonsMessages } from '../../../../../common/app/messages';
import { CodeMirror, Container, Tooltip } from '../../../../../lib/fui/react';
import KafkaIni from './KafkaIni';

const ProjectAgentSettingKafka = ({
  intl,
  userInfo,
  currentProject,
  agentSystemData,
  agentData,
  onSubmit,
  currentTheme,
}) => {
  const [errMsg, setErrMsg] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [configContent, setConfigContent] = useState('');
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    bootstrapServers: agentData?.bootstrapServers || '',
    topics: agentData?.topics || '',
    groupId: agentData?.groupId || '',
    clientId: agentData?.clientId || '',
    sslCheckHostname: agentData?.sslCheckHostname || '',
    sslCafile: agentData?.sslCafile || '',
    sslCertfile: agentData?.sslCertfile || '',
    sslKeyfile: agentData?.sslKeyfile || '',
    sslPassword: agentData?.sslPassword || '',
    sslCrlfile: agentData?.sslCrlfile || '',
    sslCiphers: agentData?.sslCiphers || '',
    initialFilter: agentData?.initialFilter || '',
    rawRegex: agentData?.rawRegex || '',
    logContentField: agentData?.logContentField || '',
    timestampField: agentData?.timestampField || '',
    componentField: agentData?.componentField || '',
    instanceField: agentData?.instanceField || '',
    instanceWhitelist: agentData?.instanceWhitelist || '',
    deviceField: agentData?.deviceField || '',
  });
  const { bootstrapServers, topics, groupId, clientId } = state;
  const { initialFilter, rawRegex } = state;
  const { logContentField, timestampField, componentField } = state;
  const { instanceField, instanceWhitelist, deviceField } = state;
  const { sslCheckHostname, sslCafile, sslCertfile, sslKeyfile, sslPassword, sslCrlfile, sslCiphers } = state;

  const hasError = !!errMsg;
  const isValid = !!bootstrapServers && !!topics && !!groupId && !!instanceField && !!timestampField;
  const hasChange = !_.isEqual(state, agentData);

  useEffect(() => {
    const defaultState = R.mapObjIndexed(() => '', state || {});
    setState({ ...defaultState, ...(agentData || {}) });
  }, [agentData]);

  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit({ ...state }, (err) => {
      if (err) {
        setErrMsg(err);
      }
      setSubmitting(false);
    });
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(configContent);
  };

  const isDark = currentTheme === 'dark';
  const handleGenerate = () => {
    let content = KafkaIni;
    R.forEachObjIndexed((val, key) => {
      content = content.replace(`{{${key}}}`, val);
    })(state || {});

    content = content.replace('{{containerize}}', state?.deviceField ? 'YES' : 'NO');

    content = content.replace('{{userName}}', userInfo?.userName || '');
    content = content.replace('{{licenseKey}}', userInfo?.licenseKey || '');
    content = content.replace('{{systemName}}', currentProject?.systemName || '');
    content = content.replace('{{projectName}}', currentProject?.projectShortName || '');
    content = content.replace('{{projectType}}', currentProject?.isMetric ? 'metric' : 'log');
    content = content.replaceAll('{{samplingInterval}}', currentProject?.samplingInterval || 10);
    content = content.replaceAll('{{timezone}}', currentProject?.timezone || 'UTC');
    content = content.replaceAll('{{ifUrl}}', window.location.origin || '');

    setConfigContent(content);
  };

  return (
    <Container
      fullHeight
      className="flex-col overflow-y-auto"
      style={{ fontSize: 14, rowGap: 8, paddingRight: 8, width: '100%' }}
    >
      {errMsg && <Alert message="Error" description={errMsg} type="error" showIcon style={{ marginBottom: 16 }} />}
      <div>
        <Form size="small">
          <Row gutter={16}>
            <Col span={24}>
              <div style={{ marginBottom: 8 }}>
                <span className="font-14 bold" style={{ marginRight: 8 }}>
                  Kafka README:
                </span>
                <a
                  href="https://github.com/insightfinder/InsightAgent/blob/master/kafka_log/README.md"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://github.com/insightfinder/InsightAgent/blob/master/kafka_log/README.md
                </a>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="font-14 bold" style={{ marginBottom: 8 }}>
                Required Settings:
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required
                label="Kafka bootstrap server"
                tooltip="Kafka server url"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={bootstrapServers}
                  onChange={(e) => setState({ bootstrapServers: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="Kafka topics"
                tooltip="Kafka topics to query data"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={topics}
                  onChange={(e) => setState({ topics: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item required label="Group id" tooltip="Kafka group id" style={{ marginBottom: 8 }} labelCol={2}>
                <AntInput
                  value={groupId}
                  onChange={(e) => setState({ groupId: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="Instance"
                tooltip="If no instance given, the local hostname will be used. Can also use {field} formatting or a priority list."
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={instanceField}
                  onChange={(e) => setState({ instanceField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Timestamp"
                required
                tooltip="Field that contains the timestamp"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={timestampField}
                  onChange={(e) => setState({ timestampField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <div className="font-14 bold" style={{ marginBottom: 8, marginTop: 8 }}>
                Optional Settings:
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Client id" tooltip="Kafka Client id" style={{ marginBottom: 8 }} labelCol={4}>
                <AntInput
                  value={clientId}
                  onChange={(e) => setState({ clientId: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Host SSL check"
                tooltip="Whether check SSL, true or false"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={sslCheckHostname}
                  onChange={(e) => setState({ sslCheckHostname: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="SSL ca file" tooltip="Path of the SSL ca file" style={{ marginBottom: 8 }} labelCol={2}>
                <AntInput
                  value={sslCafile}
                  onChange={(e) => setState({ sslCafile: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="SSL cert file"
                tooltip="Path of the SSL cert file"
                style={{ marginBottom: 8 }}
                labelCol={4}
              >
                <AntInput
                  value={sslCertfile}
                  onChange={(e) => setState({ sslCertfile: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="SSL key file"
                tooltip="Path of the SSL key file"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={sslKeyfile}
                  onChange={(e) => setState({ sslKeyfile: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="SSL password" tooltip="SSL password" style={{ marginBottom: 8 }} labelCol={4}>
                <AntInput.Password
                  value={sslPassword}
                  onChange={(e) => setState({ sslPassword: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="SSL crl file" tooltip="Path of SSL crl file" style={{ marginBottom: 8 }} labelCol={2}>
                <AntInput
                  value={sslCrlfile}
                  onChange={(e) => setState({ sslCrlfile: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="SSL ciphers" tooltip="SSL ciphers" style={{ marginBottom: 8 }} labelCol={4}>
                <AntInput.Password
                  value={sslCiphers}
                  onChange={(e) => setState({ sslCiphers: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Initial filter"
                tooltip="Optional preprocessing filter (regex) to eliminate raw data from being parsed. Data must match filter to be parsed if set"
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={initialFilter}
                  onChange={(e) => setState({ initialFilter: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Raw regex"
                tooltip="If raw data, the regex used to parse the log. It must use named capture groups (?<name>.*) That correspond to the *_field config variables below (ie  (?<timestamp>.*),  (?<host>.*),  (?<device>.*), (?<etc>.*)."
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={rawRegex}
                  onChange={(e) => setState({ rawRegex: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Log content"
                tooltip="Field that contains the log message. If this field is empty, agent will use whole message from kafka."
                style={{ marginBottom: 8 }}
                labelCol={2}
              >
                <AntInput
                  value={logContentField}
                  onChange={(e) => setState({ logContentField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Component" tooltip="Field that contains the component name" style={{ marginBottom: 8 }}>
                <AntInput
                  value={componentField}
                  onChange={(e) => setState({ componentField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Instance whitelist"
                tooltip="A regex string used to define which instances will be filtered."
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={instanceWhitelist}
                  onChange={(e) => setState({ instanceWhitelist: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Device"
                tooltip="Field name of device. Can also set device name from multiple fields which separated by commas. Ex: device_name_part1,device_name_part2."
                style={{ marginBottom: 8 }}
              >
                <AntInput
                  value={deviceField}
                  onChange={(e) => setState({ deviceField: e.target.value })}
                  visibilityToggle={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="flex-row">
        <Button type="primary" disabled={hasError || !isValid} onClick={handleGenerate}>
          Generate config
        </Button>
        <div className="flex-grow" />
        <Button
          type="primary"
          disabled={hasError || !hasChange || !isValid}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          {intl.formatMessage(appButtonsMessages.update)}
        </Button>
      </div>
      <div className="flex-grow overflow-y-auto overflow-x-auto" style={{ minHeight: 480 }}>
        <div style={{ position: 'absolute', right: 20, fontSize: 18, zIndex: 10 }}>
          <CopyOutlined onClick={handleCopy} />
        </div>
        <CodeMirror
          className="codeMirror_auto"
          options={{
            lineNumbers: false,
            readOnly: false,
            theme: isDark ? 'material' : 'xq-light',
          }}
          value={configContent}
          onBeforeChange={(editor, data, value) => {
            setConfigContent(value);
          }}
        />
      </div>
    </Container>
  );
};

export default ProjectAgentSettingKafka;
