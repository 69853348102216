/* @flow */

import { defineMessages } from 'react-intl';

const eventMessages = defineMessages({
  eventLabel: {
    defaultMessage: 'Events',
    id: 'event.eventLabel',
  },
  metricAnomaly: {
    defaultMessage: 'Metrics',
    id: 'event.metricAnomaly',
  },
  logAnomaly: {
    defaultMessage: 'Logs',
    id: 'event.logAnomaly',
  },
  alertAnomaly: {
    defaultMessage: 'Alerts',
    id: 'event.alertAnomaly',
  },
  timeLabel: {
    defaultMessage: 'Time',
    id: 'event.timeLabel',
  },
  predictedOccurrenceTime: {
    defaultMessage: 'Predicted occurrence time',
    id: 'event.predictedOccurrenceTime',
  },
  intervalLabel: {
    defaultMessage: 'Interval',
    id: 'event.intervalLabel',
  },
  totalLabel: {
    defaultMessage: 'Total',
    id: 'event.totalLabel',
  },
  anomalous: {
    defaultMessage: 'Anomalous',
    id: 'event.anomalous',
  },
  incidentCount: {
    defaultMessage: 'Incident count',
    id: 'event.incidentCount',
  },
  exportEvents: {
    defaultMessage: 'Export Events',
    id: 'event.exportEvents',
  },
  downloadAnomalyLogEntries: {
    defaultMessage: 'Download anomaly log entries',
    id: 'event.downloadAnomalyLogEntries',
  },
  fieldName: {
    defaultMessage: 'Name',
    id: 'event.fieldName',
  },
  truePositiveCount: {
    defaultMessage: 'True positive count',
    id: 'event.truePositiveCount',
  },
  truePositive: {
    defaultMessage: 'True positive',
    id: 'event.truePositive',
  },
  falsePositive: {
    defaultMessage: 'False positive',
    id: 'event.falsePositive',
  },
  rootCauseProject: {
    defaultMessage: 'Root cause project',
    id: 'event.rootCauseProject',
  },
  rootCauseLevel: {
    defaultMessage: 'Root cause level',
    id: 'event.rootCauseLevel',
  },
  rootCausePattern: {
    defaultMessage: 'Root cause pattern',
    id: 'event.rootCausePattern',
  },
  incidentPattern: {
    defaultMessage: 'Incident pattern',
    id: 'event.incidentPattern',
  },
  knowledgeBaseType: {
    defaultMessage: 'Knowledge base type',
    id: 'event.knowledgeBaseType',
  },
  incidentDescription: {
    defaultMessage: 'Incident Description',
    id: 'event.incidentDescription',
  },
  rootCauseComponentName: {
    defaultMessage: 'Root Cause Component Name',
    id: 'event.rootCauseComponentName',
  },
  rootCauseMetricName: {
    defaultMessage: 'Root Cause Metric Name',
    id: 'event.rootCauseMetricName',
  },
  rootCauseMetricValue: {
    defaultMessage: 'Root Cause Metric Value',
    id: 'event.rootCauseMetricValue',
  },
  rootCauseMetricAnomalyDegree: {
    defaultMessage: 'Root Cause Metric Anomaly Degree',
    id: 'event.rootCauseMetricAnomalyDegree',
  },
  rootCauseLogMessage: {
    defaultMessage: 'Root Cause Log Message',
    id: 'event.rootCauseLogMessage',
  },
  rootCauseLeadTime: {
    defaultMessage: 'Root Cause Lead Time',
    id: 'event.rootCauseLeadTime',
  },
  lowerThanNormal: {
    defaultMessage: 'lower than normal',
    id: 'event.lowerThanNormal',
  },
  higherThanNormal: {
    defaultMessage: 'higher than normal',
    id: 'event.higherThanNormal',
  },
  enableHealthAlert: {
    defaultMessage: 'Enable health alert',
    id: 'event.enableHealthAlert',
  },
  enableAlertNotification: {
    defaultMessage: 'Enable alert notification',
    id: 'event.enableAlertNotification',
  },
  alertNotificationDampeningPeriod: {
    defaultMessage: 'Alert notification dampening period(min)',
    id: 'event.alertNotificationDampeningPeriod',
  },
  alertHealthScore: {
    defaultMessage: 'Alert health score',
    id: 'event.alertHealthScore',
  },
  detectedIncidentCountThreshold: {
    defaultMessage: 'Detected incident count threshold',
    id: 'event.detectedIncidentCountThreshold',
  },
  notificationType: {
    defaultMessage: 'Notification type',
    id: 'event.notificationType',
  },
  enableSystemDownDetection: {
    defaultMessage: 'Enable system down detection',
    id: 'event.enableSystemDownDetection',
  },
  enableDailyReport: {
    defaultMessage: 'Enable daily report',
    id: 'event.enableDailyReport',
  },
  enableWeeklyReport: {
    defaultMessage: 'Enable weekly report',
    id: 'event.enableWeeklyReport',
  },
  deleteAllProjects: {
    defaultMessage: 'Delete all projects in {systemName} system',
    id: 'event.deleteAllProjects',
  },
  deleteBayerSystemNameOnly: {
    defaultMessage: 'Delete system {systemName} only',
    id: 'event.deleteBayerSystemNameOnly',
  },
  logMaxScore: {
    defaultMessage: 'Log Max Score',
    id: 'event.logMaxScore',
  },
  metricMaxScore: {
    defaultMessage: 'Metric Max Score',
    id: 'event.metricMaxScore',
  },
  roleManagement: {
    defaultMessage: 'Role Management',
    id: 'event.roleManagement',
  },
  userManagement: {
    defaultMessage: 'User Management',
    id: 'event.userManagement',
  },
  signupManagement: {
    defaultMessage: 'Signup Management',
    id: 'event.signupManagement',
  },
  rabbitMQStatus: {
    defaultMessage: 'RabbitMQ Status',
    id: 'event.rabbitMQStatus',
  },
  cronStatus: {
    defaultMessage: 'Cron Status',
    id: 'event.cronStatus',
  },
  taskStatus: {
    defaultMessage: 'Task Status',
    id: 'event.taskStatus',
  },
  emailSettings: {
    defaultMessage: 'Email Settings',
    id: 'event.emailSettings',
  },
  componentVersion: {
    defaultMessage: 'Component Version',
    id: 'event.componentVersion',
  },
  coreEngine: {
    defaultMessage: 'Core Engine',
    id: 'event.coreEngine',
  },
  webUI: {
    defaultMessage: 'Web UI',
    id: 'event.webUI',
  },
  license: {
    defaultMessage: 'License',
    id: 'event.license',
  },
  effective: {
    defaultMessage: 'Effective',
    id: 'event.effective',
  },
  nodeLimit: {
    defaultMessage: 'Node Limit',
    id: 'event.nodeLimit',
  },
  expirationDate: {
    defaultMessage: 'Expiration Date',
    id: 'event.expirationDate',
  },
  supportContact: {
    defaultMessage: 'Support Contact',
    id: 'event.supportContact',
  },
  roleName: {
    defaultMessage: 'Role name',
    id: 'event.roleName',
  },
  menuConfig: {
    defaultMessage: 'Menu Config',
    id: 'event.menuConfig',
  },
  userInterfaceSettings: {
    defaultMessage: 'User Interface Settings',
    id: 'event.userInterfaceSettings',
  },
  display: {
    defaultMessage: 'Display',
    id: 'event.display',
  },
  userName: {
    defaultMessage: 'User name',
    id: 'event.userName',
  },
  account: {
    defaultMessage: 'Account',
    id: 'event.account',
  },
  serviceHost: {
    defaultMessage: 'Service host',
    id: 'event.serviceHost',
  },
  firstName: {
    defaultMessage: 'First name',
    id: 'event.firstName',
  },
  lastName: {
    defaultMessage: 'Last name',
    id: 'event.lastName',
  },
  company: {
    defaultMessage: 'Company',
    id: 'event.company',
  },
  accountGroup: {
    defaultMessage: 'Account group',
    id: 'event.accountGroup',
  },
  homeAccountGroup: {
    defaultMessage: 'Home account group',
    id: 'event.homeAccountGroup',
  },
  additional: {
    defaultMessage: 'Additional',
    id: 'event.additional',
  },
  managedAccountGroup: {
    defaultMessage: 'Managed account group',
    id: 'event.managedAccountGroup',
  },
  accountGroupName: {
    defaultMessage: 'Account Group Name',
    id: 'event.accountGroupName',
  },
  signupDate: {
    defaultMessage: 'Signup date',
    id: 'event.signupDate',
  },
  lastLoginTime: {
    defaultMessage: 'Last login time',
    id: 'event.lastLoginTime',
  },
  role: {
    defaultMessage: 'Role',
    id: 'event.role',
  },
  suspended: {
    defaultMessage: 'Suspended',
    id: 'event.suspended',
  },
  totalUsers: {
    defaultMessage: 'Total users',
    id: 'event.totalUsers',
  },
  userSearch: {
    defaultMessage: 'User search',
    id: 'event.userSearch',
  },
  accountGroupSearch: {
    defaultMessage: 'Account group search',
    id: 'event.accountGroupSearch',
  },
  thisUserWillRemoveLockStatus: {
    defaultMessage: 'This user will remove lock status.',
    id: 'event.thisUserWillRemoveLockStatus',
  },
  areYouSureDeleteThisUser: {
    defaultMessage: 'Are you sure delete this user?',
    id: 'event.areYouSureDeleteThisUser',
  },
  theseProjectsWillBeDeleted: {
    defaultMessage: 'These projects will be deleted:',
    id: 'event.theseProjectsWillBeDeleted',
  },
  thereIsNoProjectsBelongToThisUser: {
    defaultMessage: 'There is no projects belong to this user.',
    id: 'event.thereIsNoProjectsBelongToThisUser',
  },
  signupCode: {
    defaultMessage: 'Signup code',
    id: 'event.signupCode',
  },
  freeTrialRequestDate: {
    defaultMessage: 'Free trial request date',
    id: 'event.freeTrialRequestDate',
  },
  rabbitmqServerStatus: {
    defaultMessage: 'Rabbitmq Server Status',
    id: 'event.rabbitmqServerStatus',
  },
  running: {
    defaultMessage: 'Running',
    id: 'event.running',
  },
  queue: {
    defaultMessage: 'Queue',
    id: 'event.queue',
  },
  host: {
    defaultMessage: 'Host',
    id: 'event.host',
  },
  hosts: {
    defaultMessage: 'Hosts',
    id: 'event.hosts',
  },
  consumers: {
    defaultMessage: 'Consumers',
    id: 'event.consumers',
  },
  messages: {
    defaultMessage: 'Messages',
    id: 'event.messages',
  },
  purge: {
    defaultMessage: 'Purge',
    id: 'event.purge',
  },
  clearQueueData: {
    defaultMessage:
      'Purging this queue({queueName}) will cause a loss of data. Are you sure you would like to continue?',
    id: 'event.clearQueueData',
  },
  lastUpdatedGMT: {
    defaultMessage: 'Last updated (GMT)',
    id: 'event.lastUpdatedGMT',
  },
  execute: {
    defaultMessage: 'Execute',
    id: 'event.execute',
  },
  permanentlyDeleteThisCronJob: {
    defaultMessage: 'This action will permanently remove this cron job({operation}) and all associated data.',
    id: 'event.permanentlyDeleteThisCronJob',
  },
  triggerCron: {
    defaultMessage: 'Are you sure that you would like to trigger cron {operation}?',
    id: 'event.triggerCron',
  },
  taskId: {
    defaultMessage: 'Task id',
    id: 'event.taskId',
  },
  originator: {
    defaultMessage: 'Originator',
    id: 'event.originator',
  },
  cronType: {
    defaultMessage: 'Cron type',
    id: 'event.cronType',
  },
  enqueueTime: {
    defaultMessage: 'Enqueue time',
    id: 'event.enqueueTime',
  },
  taskStartTime: {
    defaultMessage: 'Task start time',
    id: 'event.taskStartTime',
  },
  taskCompletionDeadline: {
    defaultMessage: 'Task completion deadline',
    id: 'event.taskCompletionDeadline',
  },
  terminateThisJob: {
    defaultMessage: 'Are you sure that you would like to kill this job?',
    id: 'event.terminateThisJob',
  },
  killTask: {
    defaultMessage: 'Kill Task',
    id: 'event.killTask',
  },
  retryCount: {
    defaultMessage: 'Retry Count',
    id: 'event.retryCount',
  },
  maxRetryCount: {
    defaultMessage: 'Max Retry Count',
    id: 'event.maxRetryCount',
  },
  timeout: {
    defaultMessage: 'Timeout',
    id: 'event.timeout',
  },
  taskParametersStartTime: {
    defaultMessage: 'Task parameters start time',
    id: 'event.taskParametersStartTime',
  },
  taskParametersEndTime: {
    defaultMessage: 'Task parameters end time',
    id: 'event.taskParametersEndTime',
  },
  generalEmailSettings: {
    defaultMessage: 'General Email Settings',
    id: 'event.generalEmailSettings',
  },
  emailServiceSwitch: {
    defaultMessage: 'Email Service Switch (On/Off)',
    id: 'event.emailServiceSwitch',
  },
  defaultEmailAlertRecipient: {
    defaultMessage: 'Default Email Alert Recipient',
    id: 'event.defaultEmailAlertRecipient',
  },
  emailServerSettings: {
    defaultMessage: 'Email Server Settings',
    id: 'event.emailServerSettings',
  },
  SMTPServer: {
    defaultMessage: 'SMTP Server',
    id: 'event.SMTPServer',
  },
  SMTPPort: {
    defaultMessage: 'SMTP Port',
    id: 'event.SMTPPort',
  },
  SMTPEmailAddress: {
    defaultMessage: 'SMTP Email Address',
    id: 'event.SMTPEmailAddress',
  },
  encryption: {
    defaultMessage: 'Encryption',
    id: 'event.encryption',
  },
  authenticationSwitch: {
    defaultMessage: 'Authentication Switch (On/Off)',
    id: 'event.authenticationSwitch',
  },
  SMTPUsername: {
    defaultMessage: 'SMTP Username',
    id: 'event.SMTPUsername',
  },
  SMTPPassword: {
    defaultMessage: 'SMTP Password',
    id: 'event.SMTPPassword',
  },
  testEmailSettings: {
    defaultMessage: 'Test Email Settings',
    id: 'event.testEmailSettings',
  },
  updateEmailSettings: {
    defaultMessage: 'Update Email Settings',
    id: 'event.updateEmailSettings',
  },
  metrics: {
    defaultMessage: 'Metrics',
    id: 'event.metrics',
  },
  automaticallyCreatedRules: {
    defaultMessage: 'Automatically created rules',
    id: 'event.automaticallyCreatedRules',
  },
  manuallyCreatedRules: {
    defaultMessage: 'Manually created rules',
    id: 'event.manuallyCreatedRules',
  },
  incidentInstance: {
    defaultMessage: 'Incident instance',
    id: 'event.incidentInstance',
  },
  incidentComponent: {
    defaultMessage: 'Incident component',
    id: 'event.incidentComponent',
  },
  fieldRootCauseInstance: {
    defaultMessage: 'Root cause instances',
    id: 'event.fieldRootCauseInstance',
  },
  fieldCauseProject: {
    defaultMessage: 'Source project',
    id: 'event.fieldCauseProject',
  },
  fieldCauseComponent: {
    defaultMessage: 'Source component',
    id: 'event.fieldCauseComponent',
  },
  fieldCauseInstance: {
    defaultMessage: 'Source instance',
    id: 'event.fieldCauseInstance',
  },
  sourceInstanceName: {
    defaultMessage: 'Source instance name',
    id: 'event.sourceInstanceName',
  },
  sourceComponentName: {
    defaultMessage: 'Source component name',
    id: 'event.sourceComponentName',
  },
  targetInstanceName: {
    defaultMessage: 'Target instance name',
    id: 'event.targetInstanceName',
  },
  targetComponentName: {
    defaultMessage: 'Target component name',
    id: 'event.targetComponentName',
  },
  fieldCauseContainer: {
    defaultMessage: 'Source container',
    id: 'event.fieldCauseContainer',
  },
  fieldAnomalyPattern: {
    defaultMessage: 'Anomaly pattern',
    id: 'event.fieldAnomalyPattern',
  },
  fieldIncidentType: {
    defaultMessage: 'Incident type',
    id: 'event.fieldIncidentType',
  },
  predictionAccuracy: {
    defaultMessage: 'Prediction accuracy',
    id: 'event.predictionAccuracy',
  },
  predictionStatus: {
    defaultMessage: 'Prediction status',
    id: 'event.predictionStatus',
  },
  predictionCategory: {
    defaultMessage: 'Prediction category',
    id: 'event.predictionCategory',
  },
  incidentStatus: {
    defaultMessage: 'Incident status',
    id: 'event.incidentStatus',
  },
  incidentCategory: {
    defaultMessage: 'Incident category',
    id: 'event.incidentCategory',
  },
  alertCategory: {
    defaultMessage: 'Alert category',
    id: 'event.alertCategory',
  },
  metricCategory: {
    defaultMessage: 'Metric anomaly category',
    id: 'event.metricCategory',
  },
  logCategory: {
    defaultMessage: 'Log anomaly category',
    id: 'event.logCategory',
  },
  traceCategory: {
    defaultMessage: 'Trace anomaly category',
    id: 'event.traceCategory',
  },
  changeCategory: {
    defaultMessage: 'Change category',
    id: 'event.changeCategory',
  },
  shortDescriptionKeywordSearch: {
    defaultMessage: 'Short description keyword search',
    id: 'event.shortDescriptionKeywordSearch',
  },
  importanceFlag: {
    defaultMessage: 'Importance flag',
    id: 'event.importanceFlag',
  },
  incidentsRootCauses: {
    defaultMessage: 'Incidents w/wo root causes',
    id: 'event.incidentsRootCauses',
  },
  predictionSourceCategory: {
    defaultMessage: 'Prediction source category',
    id: 'event.predictionSourceCategory',
  },
  aggregationInterval: {
    defaultMessage: 'Aggregation Interval',
    id: 'event.aggregationInterval',
  },
  fieldAnomalyType: {
    defaultMessage: 'Anomaly type',
    id: 'event.fieldAnomalyType',
  },
  fieldRootCauseMetric: {
    defaultMessage: 'Root cause metric types',
    id: 'event.fieldRootCauseMetric',
  },
  fieldAnomalyScore: {
    defaultMessage: 'Anomaly Scores',
    id: 'event.fieldAnomalyScore',
  },
  fieldAnomalyDuration: {
    defaultMessage: 'Anomaly Duration',
    id: 'event.fieldAnomalyDuration',
  },
  avgDuration: {
    defaultMessage: 'Avg duration',
    id: 'event.avgDuration',
  },
  maxAnomalyScore: {
    defaultMessage: 'Max anomalyScore',
    id: 'event.maxAnomalyScore',
  },
  minAnomalyScore: {
    defaultMessage: 'Min AnomalyScore',
    id: 'event.minAnomalyScore',
  },
  fieldStartTime: {
    defaultMessage: 'Start time',
    id: 'event.fieldStartTime',
  },
  fieldDuration: {
    defaultMessage: 'Duration',
    id: 'event.fieldDuration',
  },
  fieldStatus: {
    defaultMessage: 'Status',
    id: 'event.fieldStatus',
  },
  fieldDetails: {
    defaultMessage: 'Details',
    id: 'event.fieldDetails',
  },
  fieldControl: {
    defaultMessage: 'Control',
    id: 'event.fieldControl',
  },
  rerunTrainingOrDetection: {
    defaultMessage: 'Rerun Training or Detection',
    id: 'event.rerunTrainingOrDetection',
  },
  rerunTrainingCur: {
    defaultMessage: 'Rerun Training(Current Day)',
    id: 'event.rerunTrainingCur',
  },
  rerunTrainingAll: {
    defaultMessage: 'Rerun Training(All Days)',
    id: 'event.rerunTrainingAll',
  },
  rerunTraining: {
    defaultMessage: 'Rerun Training',
    id: 'event.rerunTraining',
  },
  rerunDetectionCur: {
    defaultMessage: 'Rerun Detection(Current Day)',
    id: 'event.rerunDetectionCur',
  },
  rerunDetectionAll: {
    defaultMessage: 'Rerun Detection(All Days)',
    id: 'event.rerunDetectionAll',
  },
  rerunDetection: {
    defaultMessage: 'Rerun Detection',
    id: 'event.rerunDetection',
  },
  causalGraph: {
    defaultMessage: 'Causal Graph',
    id: 'event.causalGraph',
  },
  overallTreemap: {
    defaultMessage: 'Overall Treemap',
    id: 'event.overallTreemap',
  },
  overallLineChart: {
    defaultMessage: 'Overall Line Chart',
    id: 'event.overallLineChart',
  },
  anomalyLineChart: {
    defaultMessage: 'Anomaly Line Chart',
    id: 'event.anomalyLineChart',
  },
  lineChart: {
    defaultMessage: 'Line Chart',
    id: 'event.lineChart',
  },
  investigationPatternConfig: {
    defaultMessage: 'Configure Action',
    id: 'event.investigationPatternConfig',
  },
  timeline: {
    defaultMessage: 'Timeline',
    id: 'event.timeline',
  },
  eventSummary: {
    defaultMessage: 'Event summary',
    id: 'event.eventSummary',
  },
  detectionTimeInfo: {
    defaultMessage: 'Detection time details',
    id: 'event.detectionTimeInfo',
  },
  predictionTimeInfo: {
    defaultMessage: 'Prediction time details',
    id: 'event.predictionTimeInfo',
  },
  topLikelyRootCauses: {
    defaultMessage: 'Top likely root causes',
    id: 'event.topLikelyRootCauses',
  },
  likelyRootCause: {
    defaultMessage: 'Likely root cause',
    id: 'event.likelyRootCause',
  },
  likelyRootCauses: {
    defaultMessage: 'Likely root causes',
    id: 'event.likelyRootCauses',
  },
  logToMetricContext: {
    defaultMessage: 'Log to metric context',
    id: 'event.logToMetricContext',
  },
  metricType: {
    defaultMessage: 'Metric type',
    id: 'event.metricType',
  },
  logType: {
    defaultMessage: 'Log type',
    id: 'event.logType',
  },
  predictionTriggeringEvents: {
    defaultMessage: 'Prediction triggering events',
    id: 'event.predictionTriggeringEvents',
  },
  predictionTime: {
    defaultMessage: 'Prediction time',
    id: 'event.predictionTime',
  },
  detectionTime: {
    defaultMessage: 'Detection time',
    id: 'event.detectionTime',
  },
  rootCauseHop: {
    defaultMessage: 'Root Cause',
    id: 'event.rootCauseHop',
  },
  predictionHop: {
    defaultMessage: 'Prediction Hop',
    id: 'event.predictionHop',
  },
  avgHealthScore: {
    defaultMessage: 'Avg Health Score',
    id: 'event.avgHealthScore',
  },
  predictionProbability: {
    defaultMessage: 'Probability',
    id: 'event.predictionProbability',
  },
  count: {
    defaultMessage: 'Count',
    id: 'event.count',
  },
  uniqueLogCount: {
    defaultMessage: 'Unique anomalous log count',
    id: 'event.uniqueLogCount',
  },
  totalLogCount: {
    defaultMessage: 'Total anomalous log count',
    id: 'event.totalLogCount',
  },
  predictionCount: {
    defaultMessage: 'Count',
    id: 'event.predictionCount',
  },
  shortDescriptionOfPredicted: {
    defaultMessage: 'Short description of predicted incidents',
    id: 'event.shortDescriptionOfPredicted',
  },
  description: {
    defaultMessage: 'Description',
    id: 'event.description',
  },
  shortDescription: {
    defaultMessage: 'Short description',
    id: 'event.shortDescription',
  },
  anomalousEventCount: {
    defaultMessage: 'Anomalous event count',
    id: 'event.anomalousEventCount',
  },
  emailSent: {
    defaultMessage: 'Email Sent',
    id: 'event.emailSent',
  },
  recurrent: {
    defaultMessage: 'Recurrent',
    id: 'event.recurrent',
  },
  futureEvents: {
    defaultMessage: 'Future Events',
    id: 'event.futureEvents',
  },
  recurrentEvents: {
    defaultMessage: 'Recurrent Events',
    id: 'event.recurrentEvents',
  },
  newIncident: {
    defaultMessage: 'New incident',
    id: 'event.newIncident',
  },
  newAlert: {
    defaultMessage: 'New alert',
    id: 'event.newAlert',
  },
  ignored: {
    defaultMessage: 'Ignored',
    id: 'event.ignored',
  },
  unIgnored: {
    defaultMessage: 'Unignored',
    id: 'event.unIgnored',
  },
  notInUse: {
    defaultMessage: 'Not in use',
    id: 'event.notInUse',
  },
  inUse: {
    defaultMessage: 'In use',
    id: 'event.inUse',
  },
  confirmedByOperator: {
    defaultMessage: 'Confirmed by operator',
    id: 'event.confirmedByOperator',
  },
  important: {
    defaultMessage: 'Important',
    id: 'event.important',
  },
  others: {
    defaultMessage: 'Others',
    id: 'event.others',
  },
  anomalousLogs: {
    defaultMessage: 'Anomalous logs',
    id: 'event.anomalousLogs',
  },
  normalStatus: {
    defaultMessage: 'Normal',
    id: 'event.normalStatus',
  },
  markAsImportant: {
    defaultMessage: 'Mark as important',
    id: 'event.markAsImportant',
  },
  markAsLike: {
    defaultMessage: 'Like it',
    id: 'event.markAsLike',
  },
  removeLikeFlag: {
    defaultMessage: 'Unlike it',
    id: 'event.RmoveLikeFlag',
  },
  removeImportantFlag: {
    defaultMessage: 'Remove importance flag',
    id: 'event.removeImportantFlag',
  },
  markAsUnimportant: {
    defaultMessage: 'Mark as unimportant',
    id: 'event.markAsUnimportant',
  },
  markAsIgnored: {
    defaultMessage: 'Mark as ignored',
    id: 'event.markAsIgnored',
  },
  markAsUnignored: {
    defaultMessage: 'Mark as unignored',
    id: 'event.markAsUnignored',
  },
  markedAsImportant: {
    defaultMessage: 'Marked as important',
    id: 'event.markedAsImportant',
  },
  markedAsUnimportant: {
    defaultMessage: 'Marked as unimportant',
    id: 'event.markedAsUnimportant',
  },
  markedAsIgnored: {
    defaultMessage: 'Marked as ignored',
    id: 'event.markedAsIgnored',
  },
  markedAsUnignored: {
    defaultMessage: 'Marked as unignored',
    id: 'event.markedAsUnignored',
  },
  enableRunRCA: {
    defaultMessage: 'Enable RCA',
    id: 'event.enableRunRCA',
  },
  disableRunRCA: {
    defaultMessage: 'Disable RCA',
    id: 'event.disableRunRCA',
  },
  sendEmailAlert: {
    defaultMessage: 'Send email alert',
    id: 'event.sendEmailAlert',
  },
  escalateIncident: {
    defaultMessage: 'Escalate incident',
    id: 'event.escalateIncident',
  },
  createChannelOnTeams: {
    defaultMessage: 'Go to Teams channel',
    id: 'event.createChannelOnTeams',
  },
  createChannelOnSlack: {
    defaultMessage: 'Go to Slack channel',
    id: 'event.createChannelOnSlack',
  },
  sendExportAlert: {
    defaultMessage: 'Export alert',
    id: 'event.sendExportAlert',
  },
  actions: {
    defaultMessage: 'Actions',
    id: 'event.actions',
  },
  details: {
    defaultMessage: 'Details',
    id: 'event.details',
  },
  context: {
    defaultMessage: 'Context',
    id: 'event.context',
  },
  showContext: {
    defaultMessage: 'Show context',
    id: 'event.showContext',
  },
  isReadUserDisable: {
    defaultMessage: 'The read only user is not authorized to perform the function',
    id: 'event.isReadUserDisable',
  },
  contextShort: {
    defaultMessage: 'Context (1mins)',
    id: 'event.contextShort',
  },
  contextLong: {
    defaultMessage: 'Context (5mins)',
    id: 'event.contextLong',
  },
  causalPrediction: {
    defaultMessage: 'Causal Prediction',
    id: 'event.causalPrediction',
  },
  anomalyDetails: {
    defaultMessage: 'Anomaly Details',
    id: 'event.anomalyDetails',
  },
  syscall: {
    defaultMessage: 'Syscall',
    id: 'event.syscall',
  },
  setAnnotation: {
    defaultMessage: 'Annotate incident',
    id: 'event.setAnnotation',
  },
  setPatternName: {
    defaultMessage: 'Set pattern name',
    id: 'event.setPatternName',
  },
  configPatternName: {
    defaultMessage: 'Config pattern name',
    id: 'event.configPatternName',
  },
  setComponentName: {
    defaultMessage: 'Set component name',
    id: 'event.setComponentName',
  },
  suggestedAction: {
    defaultMessage: 'Suggested action',
    id: 'event.suggestedAction',
  },
  groupTakeAction: {
    defaultMessage: 'Group Configure Action',
    id: 'event.groupTakeAction',
  },
  alertReductionRatio: {
    defaultMessage: 'Alert reduction ratio',
    id: 'event.alertReductionRatio',
  },
  alertNotificationCoverage: {
    defaultMessage: 'Alert notification coverage',
    id: 'event.alertNotificationCoverage',
  },
  alertstatistics: {
    defaultMessage: 'Alert statistics',
    id: 'event.alertstatistics',
  },
  noDataForThisTimePeriod: {
    defaultMessage: 'No data for this time period',
    id: 'event.noDataForThisTimePeriod',
  },
  takeAction: {
    defaultMessage: 'Configure Action',
    id: 'event.takeAction',
  },
  addRecommendation: {
    defaultMessage: 'Add Recommendation',
    id: 'event.addRecommendation',
  },
  recommendationList: {
    defaultMessage: 'Recommendation List',
    id: 'event.recommendationList',
  },
  recommendation: {
    defaultMessage: 'Recommendation',
    id: 'event.recommendation',
  },
  manuallyInputRecommendations: {
    defaultMessage: 'Manually input recommendations',
    id: 'event.manuallyInputRecommendations',
  },
  emailAlerts: {
    defaultMessage: 'Email Alerts',
    id: 'event.emailAlerts',
  },
  editTriageReport: {
    defaultMessage: 'Edit Summary Notes',
    id: 'event.editTriageReport',
  },
  jumpKeywordSearch: {
    defaultMessage: 'Jump To Keyword Search',
    id: 'event.jumpKeywordSearch',
  },
  jumpPatternTrend: {
    defaultMessage: 'Jump To Pattern Trend',
    id: 'event.jumpPatternTrend',
  },
  publish: {
    defaultMessage: 'Publish',
    id: 'event.publish',
  },
  ignore: {
    defaultMessage: 'Ignore',
    id: 'event.ignore',
  },
  ignorePattern: {
    defaultMessage: 'Ignore pattern',
    id: 'event.ignorePattern',
  },
  noIgnorePattern: {
    defaultMessage: 'Unignore the pattern',
    id: 'event.noIgnorePattern',
  },
  unignore: {
    defaultMessage: 'Unignore',
    id: 'event.unignore',
  },
  reportJira: {
    defaultMessage: 'Report to Jira',
    id: 'event.reportJira',
  },
  reportServiceNow: {
    defaultMessage: 'Report to ServiceNow',
    id: 'event.reportServiceNow',
  },
  rerunRCA: {
    defaultMessage: 'Rerun root cause',
    id: 'event.rerunRCA',
  },
  rerunRCAForAnomaly: {
    defaultMessage: 'Rerun root cause for this anomaly event',
    id: 'event.rerunRCAForAnomaly',
  },
  deleteIncident: {
    defaultMessage: 'Delete this incident',
    id: 'event.deleteIncident',
  },
  export: {
    defaultMessage: 'Export',
    id: 'event.export',
  },
  isPredictedEvent: {
    defaultMessage: 'This is a predicted event',
    id: 'event.isPredictedEvent',
  },
  componentFilter: {
    defaultMessage: 'Component Filter',
    id: 'event.componentFilter',
  },
  instanceFilter: {
    defaultMessage: 'Instance Filter',
    id: 'event.instanceFilter',
  },
  allInstances: {
    defaultMessage: 'All instances',
    id: 'event.allInstances',
  },
  anomalyInstances: {
    defaultMessage: 'Anomalous instances',
    id: 'event.anomalyInstances',
  },
  anomalyComponent: {
    defaultMessage: 'Anomaly component',
    id: 'event.anomalyComponent',
  },
  anomalyInstance: {
    defaultMessage: 'Anomaly instance',
    id: 'event.anomalyInstance',
  },
  anomalyType: {
    defaultMessage: 'Anomaly Type',
    id: 'event.anomalyType',
  },
  anomalyMetric: {
    defaultMessage: 'Anomaly metric',
    id: 'event.anomalyMetric',
  },
  metricFilter: {
    defaultMessage: 'Metric Filter',
    id: 'event.metricFilter',
  },
  patternIdSearch: {
    defaultMessage: 'Pattern Search',
    id: 'event.patternIdSearch',
  },
  all: {
    defaultMessage: 'All',
    id: 'event.all',
  },
  hasRootCauses: {
    defaultMessage: 'Has root causes',
    id: 'event.hasRootCauses',
  },
  noRootCauses: {
    defaultMessage: 'No root causes',
    id: 'event.noRootCauses',
  },
  fixedIncidents: {
    defaultMessage: 'Fixed incidents',
    id: 'event.fixedIncidents',
  },
  actionsTriggered: {
    defaultMessage: 'Actions triggered',
    id: 'event.actionsTriggered',
  },
  notificationsSent: {
    defaultMessage: 'Notifications sent',
    id: 'event.notificationsSent',
  },
  allLogs: {
    defaultMessage: 'All logs',
    id: 'event.allLogs',
  },
  hideIgnore: {
    defaultMessage: 'Hide Ignore',
    id: 'event.hideIgnore',
  },
  detectedAndPredicted: {
    defaultMessage: 'Detected And Predicted anomaly',
    id: 'event.detectedAndPredicted',
  },
  onlyDetected: {
    defaultMessage: 'Detected anomaly',
    id: 'event.onlyDetected',
  },
  onlyPredicted: {
    defaultMessage: 'Predicted anomaly',
    id: 'event.onlyPredicted',
  },
  historicalPredictions: {
    defaultMessage: 'Historical Predictions',
    id: 'event.historicalPredictions',
  },
  normal: {
    defaultMessage: 'Normal',
    id: 'event.normal',
  },
  rare: {
    defaultMessage: 'Rare',
    id: 'event.rare',
  },
  hot: {
    defaultMessage: 'Hot',
    id: 'event.hot',
  },
  cold: {
    defaultMessage: 'Cold',
    id: 'event.cold',
  },
  critical: {
    defaultMessage: 'Critical',
    id: 'event.critical',
  },
  hotEventOnly: {
    defaultMessage: 'Hot event only',
    id: 'event.hotEventOnly',
  },
  whiteList: {
    defaultMessage: 'WhiteList',
    id: 'event.whiteList',
  },
  newPattern: {
    defaultMessage: 'New',
    id: 'event.newPattern',
  },
  incident: {
    defaultMessage: 'Incident',
    id: 'event.incident',
  },
  incidents: {
    defaultMessage: 'Incidents',
    id: 'event.incidents',
  },
  totalAlerts: {
    defaultMessage: 'Total Alerts',
    id: 'event.totalAlerts',
  },
  patternTrend: {
    defaultMessage: 'Pattern Trend',
    id: 'event.patternTrend',
  },
  pastIncidentRC: {
    defaultMessage: 'Past Incident Root Causes',
    id: 'event.pastIncidentRC',
  },
  totalPatterns: {
    defaultMessage: 'Total Patterns',
    id: 'event.totalPatterns',
  },
  uniqueAlertPatterns: {
    defaultMessage: 'Unique Alert Patterns',
    id: 'event.uniqueAlertPatterns',
  },
  metricAnomalies: {
    defaultMessage: 'Metric Anomalies',
    id: 'event.metricAnomalies',
  },
  min: {
    defaultMessage: 'Min',
    id: 'event.min',
  },
  max: {
    defaultMessage: 'Max',
    id: 'event.max',
  },
  frequencyHigh: {
    defaultMessage: 'Frequency(count {count}) is {percent} higher than normal.',
    id: 'event.frequencyHigh',
  },
  frequencyLow: {
    defaultMessage: 'Frequency(count {count}) is {percent} lower than normal.',
    id: 'event.frequencyLow',
  },
  startTime: {
    defaultMessage: 'Start Time',
    id: 'event.startTime',
  },
  endTime: {
    defaultMessage: 'End Time',
    id: 'event.endTime',
  },
  projectDisplayName: {
    defaultMessage: 'Display Name',
    id: 'event.projectDisplayName',
  },
  projectName: {
    defaultMessage: 'Project name',
    id: 'event.projectName',
  },
  displayProjectName: {
    defaultMessage: 'Project display name',
    id: 'event.displayProjectName',
  },
  realProjectName: {
    defaultMessage: 'Project real name',
    id: 'event.realProjectName',
  },
  impacted: {
    defaultMessage: 'Impacted',
    id: 'event.impacted',
  },
  componentName: {
    defaultMessage: 'Component name',
    id: 'event.componentName',
  },
  instanceName: {
    defaultMessage: 'Instance name',
    id: 'event.instanceName',
  },
  containerName: {
    defaultMessage: 'Container name',
    id: 'event.containerName',
  },
  incidentProject: {
    defaultMessage: 'Incident project',
    id: 'event.incidentProject',
  },
  ruleType: {
    defaultMessage: 'Rule type',
    id: 'event.ruleType',
  },
  projectInstanceName: {
    defaultMessage: 'Project/component/instance name',
    id: 'event.projectInstanceName',
  },
  metricInstanceName: {
    defaultMessage: 'Metric instance name',
    id: 'event.metricInstanceName',
  },
  compositeIncident: {
    defaultMessage: 'Composite incident',
    id: 'event.compositeIncident',
  },
  recurrentIncidents: {
    defaultMessage: 'Recurrent incidents',
    id: 'event.recurrentIncidents',
  },
  compositeIncidents: {
    defaultMessage: 'Composite incidents',
    id: 'event.compositeIncidents',
  },
  actionTriggered: {
    defaultMessage: 'Action triggered',
    id: 'event.actionTriggered',
  },
  isFixedIncident: {
    defaultMessage: 'Is fixed incident',
    id: 'event.isFixedIncident',
  },
  realTimeNotificationWasSent: {
    defaultMessage: 'Real time notification was sent',
    id: 'event.realTimeNotificationWasSent',
  },
  viewIndividualIncidents: {
    defaultMessage: 'View individual incidents',
    id: 'event.viewIndividualIncidents',
  },
  viewIndividualIncidentsDetails: {
    defaultMessage: 'Please click on “view individual incidents” to see the details',
    id: 'event.viewIndividualIncidentsDetails',
  },
  individualIncidents: {
    defaultMessage: 'Individual incidents',
    id: 'event.individualIncidents',
  },
  incidentPatternId: {
    defaultMessage: 'Incident Pattern ID',
    id: 'event.incidentPatternId',
  },
  patternId: {
    defaultMessage: 'Pattern id',
    id: 'event.patternId',
  },
  patternName: {
    defaultMessage: 'Pattern Name',
    id: 'event.patternName',
  },
  info: {
    defaultMessage: 'Info',
    id: 'event.info',
  },
  log: {
    defaultMessage: 'Log',
    id: 'event.log',
  },
  metric: {
    defaultMessage: 'Metric',
    id: 'event.metric',
  },
  selectTimeRange: {
    defaultMessage: 'Select the time range to export events of',
    id: 'event.selectTimeRange',
  },
  eventsHistory: {
    defaultMessage: 'Events History',
    id: 'event.eventsHistory',
  },
  quarter1: {
    defaultMessage: 'Quarter 1',
    id: 'event.quarter1',
  },
  quarter2: {
    defaultMessage: 'Quarter 2',
    id: 'event.quarter2',
  },
  quarter3: {
    defaultMessage: 'Quarter 3',
    id: 'event.quarter3',
  },
  quarter4: {
    defaultMessage: 'Quarter 4',
    id: 'event.quarter4',
  },
  previousQuarter: {
    defaultMessage: 'Previous Quarter',
    id: 'event.previousQuarter',
  },
  nextQuarter: {
    defaultMessage: 'Next Quarter',
    id: 'event.nextQuarter',
  },
  noHistoryFound: {
    defaultMessage: 'No history anomalies for this time period could be found.',
    id: 'event.noHistoryFound',
  },
  clickForDetails: {
    defaultMessage: 'Click for Details ({startTime})',
    id: 'event.clickForDetails',
  },
  date: {
    defaultMessage: 'Date',
    id: 'event.date',
  },
  anomalyScore: {
    defaultMessage: 'Anomaly score',
    id: 'event.anomalyScore',
  },
  lastSeenTime: {
    defaultMessage: 'Last seen time',
    id: 'event.lastSeenTime',
  },
  pendingTime: {
    defaultMessage: 'Pending time',
    id: 'event.pendingTime',
  },
  healthScore: {
    defaultMessage: 'Health Score',
    id: 'event.healthScore',
  },
  leadTime: {
    defaultMessage: 'Lead time',
    id: 'event.leadTime',
  },
  estimatedLeadTime: {
    defaultMessage: 'Estimated lead time',
    id: 'event.estimatedLeadTime',
  },
  sendEmailToAddress: {
    defaultMessage: 'Send email alert to these email address',
    id: 'event.sendEmailToAddress',
  },
  goTeamsChannelWithCreateAutomatically: {
    defaultMessage: 'Go to Teams channel, select an existing channel or create a new channel.',
    id: 'event.goTeamsChannelWithCreateAutomatically',
  },
  deleteTeamsChannelConfirm: {
    defaultMessage: 'Delete channel?',
    id: 'event.deleteTeamsChannelConfirm',
  },
  escalateThisIncident: {
    defaultMessage: 'Escalate this incident',
    id: 'event.escalateThisIncident',
  },
  goSlackChannelWithCreateAutomatically: {
    defaultMessage: 'Go to Slack channel, select an existing channel or create a new channel.',
    id: 'event.goSlackChannelWithCreateAutomatically',
  },
  markIgnoreStatus: {
    defaultMessage: 'Events of the pattern: {pattern} will be marked with "{status}" status',
    id: 'event.markIgnoreStatus',
  },
  resetIgnoreStatus: {
    defaultMessage: 'Pattern: {pattern} is marked with "{status}" status, submit to reset the status',
    id: 'event.resetIgnoreStatus',
  },
  patternNotFound: {
    defaultMessage: 'This pattern({pattern}) was not found.',
    id: 'event.patternNotFound',
  },
  currentPage: {
    defaultMessage: 'Current Page',
    id: 'event.currentPage',
  },
  otherPage: {
    defaultMessage: 'Other Page',
    id: 'event.otherPage',
  },
  rootCausePatternIDList: {
    defaultMessage: 'Root cause pattern ID list',
    id: 'event.rootCausePatternIDList',
  },
  rootCauseMetricOrLog: {
    defaultMessage: 'Root cause metric / Log list',
    id: 'event.rootCauseMetricOrLog',
  },
  rootCauseDetails: {
    defaultMessage: 'Root cause details',
    id: 'event.rootCauseDetails',
  },
  trailingEventsDetails: {
    defaultMessage: 'Trailing events details',
    id: 'event.trailingEventsDetails',
  },
  predictedIncidentPatternID: {
    defaultMessage: 'Predicted incident pattern ID',
    id: 'event.predictedIncidentPatternID',
  },
  predictedIncidentDetails: {
    defaultMessage: 'Predicted incident details',
    id: 'event.predictedIncidentDetails',
  },
  matchedIncidentPatternID: {
    defaultMessage: 'Matched incident pattern ID',
    id: 'event.matchedIncidentPatternID',
  },
  matchedIncidentDetails: {
    defaultMessage: 'Matched incident details',
    id: 'event.matchedIncidentDetails',
  },
  perInstanceChart: {
    defaultMessage: 'View per instance chart',
    id: 'event.perInstanceChart',
  },
  logSourceRootCauses: {
    defaultMessage: 'Log source root causes',
    id: 'event.logSourceRootCauses',
  },
  metricSourceRootCauses: {
    defaultMessage: 'Metric source root causes',
    id: 'event.metricSourceRootCauses',
  },
  logSourceTrailingEvents: {
    defaultMessage: 'Log trailing events',
    id: 'event.logSourceTrailingEvents',
  },
  metricSourceTrailingEvents: {
    defaultMessage: 'Metric trailing events',
    id: 'event.metricSourceTrailingEvents',
  },
  commonRootCauses: {
    defaultMessage: 'Common root causes',
    id: 'event.commonRootCauses',
  },
  differentRootCauses: {
    defaultMessage: 'Different root causes',
    id: 'event.differentRootCauses',
  },
  slope: {
    defaultMessage: 'Slope',
    id: 'event.slope',
  },
  historicalIncients: {
    defaultMessage: 'Historical incidents',
    id: 'event.historicalIncients',
  },
  addToCompareList: {
    defaultMessage: 'Add to compare list / delete list',
    id: 'event.addToCompareList',
  },
  incidentEmpty: {
    defaultMessage: 'Congratulations! You have zero detected or impending incident.',
    id: 'event.incidentEmpty',
  },
  changesEmpty: {
    defaultMessage: 'Your team did not deploy any changes between {startTime} and {endTime}',
    id: 'event.changesEmpty',
  },
  anomaliesEmpty: {
    defaultMessage: 'Congratulations! You have zero detected or impending anomalies.',
    id: 'event.anomaliesEmpty',
  },
  fileAlreadyExistsError: {
    defaultMessage: 'The file already exists, no need to upload it again',
    id: 'event.fileAlreadyExistsError',
  },
  areYouSureToUploadTheFile: {
    defaultMessage: 'Are you sure to upload the file?',
    id: 'event.areYouSureToUploadTheFile',
  },
  dateWithDuplicateData: {
    defaultMessage: 'Data already exist on the following dates',
    id: 'event.dateWithDuplicateData',
  },
  fileUploadError: {
    defaultMessage: 'File upload error, please upload again',
    id: 'event.fileUploadError',
  },
  fileNameHasNoDuplicateMetricAndInstance: {
    defaultMessage: '"{fileName}" has no duplicate metric and instance',
    id: 'event.fileNameHasNoDuplicateMetricAndInstance',
  },
  uploadToolTipContent: {
    defaultMessage: 'Click or drag CSV files to this area to upload',
    id: 'event.uploadToolTipContent',
  },
  showTheFirstNumLines: {
    defaultMessage: 'show the first {num} lines',
    id: 'event.showTheFirstNumLines',
  },
  inQueue: {
    defaultMessage: 'In queue',
    id: 'event.inQueue',
  },
  fileParsing: {
    defaultMessage: 'File parsing',
    id: 'event.fileParsing',
  },
  uploading: {
    defaultMessage: 'Uploading',
    id: 'event.uploading',
  },
  success: {
    defaultMessage: 'Success',
    id: 'event.success',
  },
  fail: {
    defaultMessage: 'Fail',
    id: 'event.fail',
  },
  uploaded: {
    defaultMessage: 'Uploaded',
    id: 'event.uploaded',
  },
  totalSize: {
    defaultMessage: 'Total size',
    id: 'event.totalSize',
  },
  rerun: {
    defaultMessage: 'Rerun',
    id: 'event.rerun',
  },
  jumpToLinechart: {
    defaultMessage: 'Jump to linechart',
    id: 'event.jumpToLinechart',
  },
  fileName: {
    defaultMessage: 'File name',
    id: 'event.fileName',
  },
  trainingStatus: {
    defaultMessage: 'Training status',
    id: 'event.trainingStatus',
  },
  baselineStatus: {
    defaultMessage: 'Baseline status',
    id: 'event.baselineStatus',
  },
  detectionStatus: {
    defaultMessage: 'Detection status',
    id: 'event.detectionStatus',
  },
  fileFormatError: {
    defaultMessage: '{fileName} the file needs to be in csv format',
    id: 'event.fileFormatError',
  },
  fileUploadMaxError: {
    defaultMessage: '{fileName} exceeds the maximum 2G upload size!',
    id: 'event.fileUploadMaxError',
  },
  uploadMaxSizeTitle: {
    defaultMessage: 'Upload a single file up to 2G!',
    id: 'event.uploadMaxSizeTitle',
  },
  projectCannotBeEmpty: {
    defaultMessage: 'Project cannot be empty!',
    id: 'event.projectCannotBeEmpty',
  },
  viewFileContent: {
    defaultMessage: 'View file content',
    id: 'event.viewFileContent',
  },
  deleteFile: {
    defaultMessage: 'Delete file',
    id: 'event.deleteFile',
  },
  failReason: {
    defaultMessage: 'Fail reason',
    id: 'event.failReason',
  },
  theFileAlreadyExists: {
    defaultMessage: 'The file already exists',
    id: 'event.theFileAlreadyExists',
  },
  cancelUpload: {
    defaultMessage: 'Cancel upload',
    id: 'event.cancelUpload',
  },
  projectSource: {
    defaultMessage: 'Project source',
    id: 'event.projectSource',
  },
  selectFromExistingProjects: {
    defaultMessage: 'Select from the existing projects',
    id: 'event.selectFromExistingProjects',
  },
  createANewProject: {
    defaultMessage: 'Create a new project',
    id: 'event.createANewProject',
  },
  projectAlreadyExistsError: {
    defaultMessage: 'The project already exists, please do not create it again',
    id: 'event.projectAlreadyExistsError',
  },
  rerunBaseline: {
    defaultMessage: 'Rerun Baseline',
    id: 'event.rerunBaseline',
  },
  data: {
    defaultMessage: 'Data',
    id: 'event.data',
  },
  viewData: {
    defaultMessage: 'View data',
    id: 'event.viewData',
  },
  anomalyDetection: {
    defaultMessage: 'Anomaly detection',
    id: 'event.anomalyDetection',
  },
  inProgress: {
    defaultMessage: 'In progress',
    id: 'event.inProgress',
  },
  toBeDetermined: {
    defaultMessage: 'To be determined',
    id: 'event.toBeDetermined',
  },
  metricsWithDuplicateData: {
    defaultMessage: 'Metrics with duplicate data',
    id: 'event.metricsWithDuplicateData',
  },
  instancesWithDuplicateData: {
    defaultMessage: 'Instances with duplicate data',
    id: 'event.instancesWithDuplicateData',
  },
  alreadyExistInTheSelectedProject: {
    defaultMessage: 'Instances/Metrics already exist in the selected project',
    id: 'event.alreadyExistInTheSelectedProject',
  },
  analysisGroup: {
    defaultMessage: 'Analysis Group',
    id: 'event.analysisGroup',
  },
  createNewGlobalKB: {
    defaultMessage: 'Create new global knowledge base',
    id: 'event.createNewGlobalKB',
  },
  KPIIncidents: {
    defaultMessage: 'KPI Incidents',
    id: 'event.KPIIncidents',
  },
  logIncidents: {
    defaultMessage: 'Log Incidents',
    id: 'event.logIncidents',
  },
  incidentPatternName: {
    defaultMessage: 'Incident pattern name',
    id: 'event.incidentPatternName',
  },
  incidentShortDescription: {
    defaultMessage: 'Incident short description',
    id: 'event.incidentShortDescription',
  },
  rootCauseShortDescription: {
    defaultMessage: 'Root cause short description',
    id: 'event.rootCauseShortDescription',
  },
  noPreviewYet: {
    defaultMessage: 'NO PREVIEW YET',
    id: 'event.noPreviewYet',
  },
  seeMatchingInstance: {
    defaultMessage: 'Fill out the form to see values from matching instance',
    id: 'event.seeMatchingInstance',
  },
  notSaveDashboardMessage: {
    defaultMessage: 'Please click the Save button to save the current settings!',
    id: 'event.notSaveDashboardMessage',
  },
});

export default eventMessages;
