/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { autobind } from 'core-decorators';
import DatePickerCore from 'react-datepicker';
import moment from 'moment';

type Props = {
  className: string,
  todayButton: Object,
  dateFormat: String,
  maxDate: Object,
  disableErrorHint: boolean,

  selected: Object,
  onChange: Function,
  selectedLink: Object,
};

const utcObjToLocalDate = (utcObj) => {
  if (!utcObj) return null;

  const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const ts = utcObj.valueOf();
  return new Date(ts + localOffset);
};

const localDateToUtcObj = (localDate) => {
  if (!localDate) return null;

  const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const ts = localDate.valueOf();
  return moment.utc(ts - localOffset);
};

class DatePicker extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  @autobind
  handleChangeWithValueLink(obj) {
    const { selectedLink } = this.props;
    selectedLink.set(localDateToUtcObj(obj));
  }

  @autobind
  handleBlur() {
    this.setState({ blurred: true });
  }

  @autobind
  handleFocus() {
    this.setState({ blurred: false });
  }

  render() {
    const {
      todayButton,
      dateFormat,
      displayDateFormat,
      maxDate,
      selected,
      onChange,
      className,
      selectedLink,
      disableErrorHint,
      ...rest
    } = this.props;
    const timeFormat = 'HH:mm';
    const handleChange = (val) => {
      onChange(localDateToUtcObj(val));
    };

    if (selectedLink) {
      const { blurred } = this.state;
      const hasError = Boolean(selectedLink.error);
      const showErrorHints = blurred && hasError && !disableErrorHint;
      return (
        <div className={`fui${showErrorHints ? ' invalid' : ''} ${className || ''} datepicker`}>
          <DatePickerCore
            todayButton={todayButton}
            dateFormat={displayDateFormat || 'yyyy-MM-dd'}
            maxDate={utcObjToLocalDate(maxDate)}
            selected={utcObjToLocalDate(selectedLink.value)}
            onChange={this.handleChangeWithValueLink}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            timeFormat={timeFormat}
            showYearDropdown
            showMonthDropdown
            preventOpenOnFocus
            {...rest}
          />
          {showErrorHints && <div className="error-text">{selectedLink.error}</div>}
        </div>
      );
    }

    return (
      <div className={`fui ${className || ''} datepicker`}>
        <DatePickerCore
          todayButton={todayButton}
          dateFormat={displayDateFormat || 'yyyy-MM-dd'}
          maxDate={utcObjToLocalDate(maxDate)}
          selected={utcObjToLocalDate(selected)}
          onChange={handleChange}
          timeFormat={timeFormat}
          showYearDropdown
          showMonthDropdown
          preventOpenOnFocus
          {...rest}
        />
      </div>
    );
  }
}

export default DatePicker;
