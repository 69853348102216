/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';

import type { Credentials } from '../types';
import fetchPost from './fetchPost';
import getEndpoint from './getEndpoint';

const postEventAction = (credentials: Credentials, params: Object) => {
  const { needRecipients, recipients, ...rest } = params;
  const { projectName, instanceName, eventType, neuronId, actionId } = params;
  const promiseQuery = [
    fetchPost(getEndpoint('userAction', 1), {
      ...credentials,
      ...rest,
    }).then((d) => {
      return d;
    }),
  ];
  promiseQuery.push(
    fetchPost(getEndpoint('actionemailsetting', 1), {
      ...credentials,
      frequency: 'always',
      projectName,
      instanceName,
      type: eventType,
      patternId: neuronId,
      actionId,
      emailList: needRecipients
        ? JSON.stringify(R.map((em) => R.trim(em), R.split(',', recipients)))
        : JSON.stringify([]),
    }).then((d) => {
      return d;
    }),
  );

  return Promise.all(promiseQuery).then((results) => {
    const d0 = results[0];
    const d1 = results[1];
    return d0 && d1;
  });
};

export default postEventAction;
