import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { replace } from 'react-router-redux';
import { Alert, Button, Form, Input, Popconfirm, Select, Spin, message } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { AutoSizer, Column, Container, Modal, Popover, Table } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';

import { settingsButtonMessages } from '../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { parseJSON } from '../../../common/utils';

const AddBigPandaModal = (props: Object) => {
  const { intl, userInfo, activeIncident, onClose, userList, credentials, systemsMap } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isSubmiting: false,
    errMsg: null,
    isVerify: false,
    // verified: undefined,
    verified: true,

    userName:
      activeIncident?.userName || (!userInfo?.isAdmin && !userInfo?.isLocalAdmin ? userInfo?.userName : undefined),
    systemId: activeIncident?.systemId || null,
    appKey: activeIncident?.appKey || null,
    apiKey: activeIncident?.apiKey || '',
    options: parseJSON(activeIncident?.options) || [],

    setGetSystemListLoading: false,
  });
  const { isSubmiting, errMsg, setGetSystemListLoading, userName, systemId, appKey, apiKey, options } = state;
  const { isVerify, verified } = state;

  const systemList = useRef(
    R.sortWith(
      [R.ascend(R.prop('systemName'))],
      R.filter((system) => !system.isShared, R.values(systemsMap || {})),
    ),
  );
  const anomalyOptions = useRef([
    { value: 'New Pattern Alert', label: 'New Pattern Alert' },
    { value: 'Detected Incident', label: 'Detected Incident' },
    { value: 'Predicted Incident', label: 'Predicted Incident' },
  ]);

  const changeUserNameGetSystemList = (customerName, flag = false) => {
    if (userInfo?.isAdmin || userInfo?.isLocalAdmin) {
      // setState({ setGetSystemListLoading: true, verified: undefined });
      setState({ setGetSystemListLoading: true });
      fetchGet(getEndpoint('systemframework', 2), {
        ...credentials,
        customerName,
      })
        .then((data) => {
          let { ownSystemArr } = data;
          ownSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system };
          }, ownSystemArr || []);
          const systemsMap = {};
          R.forEach(
            (system) => {
              const { systemDisplayName, systemKey, projectDetailsList, isShared, ...rest } = system;
              const { environmentName, systemName: systemId, userName } = systemKey || {};
              const newProjects = parseJSON(projectDetailsList) || [];
              systemsMap[systemId] = {
                ...rest,
                isShared,
                environmentName,
                systemId,
                systemName: systemDisplayName || systemId,
                owner: userName,
                projectDetailsList: newProjects,
              };
            },
            [...ownSystemArr],
          );
          let newSystemList = R.values(systemsMap);
          newSystemList = R.sortWith([R.ascend(R.prop('systemName'))], newSystemList);
          systemList.current = newSystemList;
          setState({ setGetSystemListLoading: false });
        })
        .catch((e) => {
          setState({ setGetSystemListLoading: false });
        });
    }

    if (flag) {
      setState({ userName: customerName });
    } else {
      setState({ userName: customerName, systemId: null });
    }
  };

  useEffect(() => {
    if (userName) changeUserNameGetSystemList(userName, true);
  }, []);

  const handleUpdate = () => {
    setState({ isSubmiting: true });
    updateLastActionInfo();

    const systemInfo = R.find((system) => system.systemId === systemId, systemList.current);
    const { owner } = systemInfo || {};

    fetchPost(getEndpoint('update-ext-service'), {
      ...credentials,
      operation: 'BigPanda',
      appKey,
      UserName: owner,
      apiKey,
      options: JSON.stringify(options || []),
      systemKey: JSON.stringify({ userName: owner, systemName: systemId, envName: 'All' }),
      account: activeIncident?.account,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          setState({ isSubmiting: false });
          onClose(true);
        } else {
          setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        setState({ isSubmiting: false, errMsg: err.message || String(err) });
      });
  };

  const handleSumbit = () => {
    setState({ isSubmiting: true });
    updateLastActionInfo();

    const systemInfo = R.find((system) => system.systemId === systemId, systemList.current);
    const { owner } = systemInfo || {};

    fetchPost(getEndpoint('service-integration'), {
      ...credentials,
      operation: 'BigPanda',
      appKey,
      apiKey,
      options: JSON.stringify(options || []),
      systemKey: JSON.stringify({ userName: owner, systemName: systemId, envName: 'All' }),
      customerName: userName,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          setState({ isSubmiting: false });
          onClose(true);
        } else {
          setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        setState({ isSubmiting: false, errMsg: err.message || String(err) });
      });
  };

  const handleVerify = () => {
    setState({ isVerify: true, errMsg: null });
    updateLastActionInfo();

    const systemInfo = R.find((system) => system.systemId === systemId, systemList.current);
    const { owner } = systemInfo || {};

    fetchPost(getEndpoint('service-integration'), {
      ...credentials,
      verify: true,
      operation: 'BigPanda',
      appKey,
      apiKey,
      options: JSON.stringify(options || []),
      systemKey: JSON.stringify({ userName: owner, systemName: systemId, envName: 'All' }),
      customerName: userName,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success || success === undefined) {
          setState({ isVerify: false, verified: true });
        } else {
          setState({ isVerify: false, verified: false, errMsg });
        }
      })
      .catch((err) => {
        setState({ isVerify: false, verified: false, errMsg: err.message || String(err) });
      });
  };

  const hasError = !userName || !systemId || !apiKey || !appKey || !verified;
  return (
    <Modal
      width={650}
      title={intl.formatMessage(settingsButtonMessages.addWebHook)}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={activeIncident ? handleUpdate : handleSumbit}
      okButtonProps={{ disabled: hasError, loading: isSubmiting }}
    >
      <Spin spinning={setGetSystemListLoading} wrapperClassName="full-height spin-full-height">
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          {(userInfo?.isAdmin || userInfo?.isLocalAdmin) && (
            <Form.Item
              label="User name"
              validateStatus={!userName ? 'error' : 'success'}
              help={!userName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                disabled={Boolean(activeIncident)}
                allowClear={false}
                showSearch
                filterOption
                value={userName}
                onChange={changeUserNameGetSystemList}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.systemName)}
            validateStatus={!systemId ? 'error' : 'success'}
            help={!systemId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={systemId}
              // onChange={(systemId) => setState({ systemId, verified: undefined })}
              onChange={(systemId) => setState({ systemId })}
            >
              {R.map(
                (system) => (
                  <Select.Option key={system.systemId}>{system.systemName}</Select.Option>
                ),
                systemList.current,
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <div className="flex-row flex-center-align">
                <div style={{ marginRight: 4 }}>App key</div>
                <Popover
                  content="The application key from BigPanda is a unique identifier associated with the application."
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </div>
            }
            validateStatus={!appKey ? 'error' : 'success'}
            help={!appKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            {/* <Input value={appKey} onChange={(e) => setState({ appKey: e.target.value, verified: undefined })} /> */}
            <Input value={appKey} onChange={(e) => setState({ appKey: e.target.value })} />
          </Form.Item>
          <Form.Item
            label={
              <div className="flex-row flex-center-align">
                <div style={{ marginRight: 4 }}>API key</div>
                <Popover
                  content="The API key from BigPanda is used to authenticate API requests."
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </div>
            }
            validateStatus={!apiKey ? 'error' : 'success'}
            help={!apiKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            {/* <Input value={apiKey} onChange={(e) => setState({ apiKey: e.target.value, verified: undefined })} /> */}
            <Input value={apiKey} onChange={(e) => setState({ apiKey: e.target.value })} />
          </Form.Item>
          <Form.Item label={intl.formatMessage(appFieldsMessages.options)}>
            <Select
              showSearch
              mode="tags"
              filterOption
              options={anomalyOptions.current}
              value={options}
              // onChange={(options) => setState({ options, verified: undefined })}
              onChange={(options) => setState({ options })}
            />
          </Form.Item>

          {false && (
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 },
              }}
            >
              <div className="flex-row flex-center-align">
                <Button
                  size="small"
                  type="primary"
                  style={{ marginRight: 16 }}
                  loading={isVerify}
                  onClick={handleVerify}
                >
                  Verify
                </Button>
                {verified === true && <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 16 }} />}
                {verified === false && <CloseCircleOutlined style={{ color: '#f5222d', fontSize: 16 }} />}
              </div>
            </Form.Item>
          )}

          {errMsg && (
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 },
              }}
            >
              <Alert message={errMsg} type="error" showIcon />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

function ServiceBigPandaModalCore(props: Object) {
  const { intl, userInfo, refresh, onClose, updateLastActionInfo, credentials } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isLoading: false,
    isDeletingMap: {},
    eventList: [],

    errMsg: undefined,

    showAddServiceModal: false,
    activeIncident: null,
    systemsMap: props?.systemsMap || {},
  });
  const { isLoading, errMsg, showAddServiceModal, activeIncident, eventList, systemsMap, isDeletingMap } = state;

  const getUserSystemMap = (userNameList) => {
    const requests = [];
    R.forEach((customerName) => {
      requests.push(
        fetchGet(getEndpoint('systemframework', 2), {
          ...credentials,
          customerName,
        }),
      );
    }, userNameList);
    return Promise.all(requests)
      .then((data) => {
        let allSystemMap = {};
        R.forEach((item) => {
          let { ownSystemArr } = item;
          ownSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system };
          }, ownSystemArr || []);
          const systemsMap = {};
          R.forEach(
            (system) => {
              const { systemDisplayName, systemKey, projectDetailsList, isShared, ...rest } = system;
              const { environmentName, systemName: systemId, userName } = systemKey || {};
              const newProjects = parseJSON(projectDetailsList) || [];
              systemsMap[systemId] = {
                ...rest,
                isShared,
                environmentName,
                systemId,
                systemName: systemDisplayName || systemId,
                owner: userName,
                projectDetailsList: newProjects,
              };
            },
            [...ownSystemArr],
          );
          allSystemMap = { ...allSystemMap, ...systemsMap };
        }, data || []);
        return allSystemMap;
      })
      .catch((e) => {
        console.log(String(e));
      });
  };

  const reloadData = (flag) => {
    setState({ isLoading: true });
    updateLastActionInfo();
    fetchGet(getEndpoint('system/externalServlies/list'), {
      ...credentials,
      serviceProvider: 'BigPanda',
    })
      .then(async (data) => {
        if (flag) return;
        const { success, message, extServiceAllInfo } = data || {};

        let eventList = [];
        let userNameList = [];
        let allSystem = {};
        if (success) {
          eventList = extServiceAllInfo || [];
          eventList = R.filter((item) => item.serviceProvider === 'BigPanda', eventList);
          allSystem = props.systemsMap;
          if (userInfo?.isAdmin || userInfo?.isLocalAdmin) {
            userNameList = R.map((item) => item.userName, eventList);
            userNameList = R.uniq(userNameList);
            allSystem = await getUserSystemMap(userNameList);
          }
        }

        setState({ isLoading: false, errMsg: !success ? message : undefined, eventList, systemsMap: allSystem });
      })
      .catch((err) => {
        setState({ isLoading: false, errMsg: err.message || String(err), eventList: [] });
      });
  };

  useEffect(() => {
    reloadData();
  }, [refresh]);

  const handleAddNewClick = () => {
    setState({ showAddServiceModal: true });
  };

  const systemRenderer = ({ rowData }) => {
    const { systemId } = rowData;
    const viewSystemName = get(systemsMap, [systemId, 'systemName'], systemId);
    return (
      <Popover content={viewSystemName} mouseEnterDelay={0.3} placement="left">
        <div className="max-width hidden-line-with-ellipsis">{viewSystemName}</div>
      </Popover>
    );
  };

  const optionsRenderer = ({ rowData }) => {
    const { options } = rowData;
    const viewOption = R.join(', ', parseJSON(options) || []);
    return (
      <Popover content={viewOption} mouseEnterDelay={0.3} placement="left">
        <div className="max-width hidden-line-with-ellipsis">{viewOption}</div>
      </Popover>
    );
  };

  const handleEventClick = ({ rowData }) => {
    setState({ showAddServiceModal: true, activeIncident: rowData });
  };

  const handleExtsvcRemove = (rowData) => {
    const { id, userName } = rowData;
    const key = `${id}-${userName}`;

    setState({ isDeletingMap: { ...isDeletingMap, [key]: true } });
    updateLastActionInfo();
    fetchPost(getEndpoint('service-integration'), {
      ...credentials,
      serviceProvider: 'BigPanda',
      operation: 'delete',
      // eslint-disable-next-line
      service_id: id,
      serviceOwner: userName,
      customerName: userName,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          setState({ isDeletingMap: { ...isDeletingMap, [key]: false } });
          reloadData();
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          setState({ isDeletingMap: { ...isDeletingMap, [key]: false }, errMsg });
        }
      })
      .catch((err) => {
        setState({ isDeletingMap: { ...isDeletingMap, [key]: false }, errMsg: err.message || String(err) });
      });
  };

  const controlRenderer = ({ rowData }) => {
    const { id, userName } = rowData;
    const key = `${id}-${userName}`;
    return (
      <div className="flex-row">
        <Button size="small" type="primary" style={{ marginLeft: 8 }} onClick={() => handleEventClick({ rowData })}>
          <EditOutlined /> {intl.formatMessage(appButtonsMessages.edit)}
        </Button>
        <Popconfirm
          placement="topRight"
          title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
          onConfirm={() => handleExtsvcRemove(rowData)}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button
            size="small"
            className="button-color-grey"
            loading={get(isDeletingMap, key, false)}
            style={{ marginLeft: 8 }}
            onClick={(event) => event.stopPropagation()}
          >
            <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.delete)}
          </Button>
        </Popconfirm>
      </div>
    );
  };

  return (
    <Modal width={1300} title="BigPanda" visible maskClosable={false} onCancel={() => onClose()} footer={null}>
      <Container className="flex-col" style={{ height: 500 }}>
        <div style={{ marginBottom: 12 }}>
          <Button size="small" type="primary" onClick={handleAddNewClick}>
            Add New
          </Button>
        </div>
        <div className="flex-grow flex-min-height">
          <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
            {errMsg && <Alert message={errMsg} type="error" showIcon />}
            {!errMsg && (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    className="with-border"
                    width={width}
                    height={height}
                    headerHeight={40}
                    rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                    rowHeight={40}
                    rowCount={eventList.length}
                    rowGetter={({ index }) => eventList[index]}
                  >
                    <Column width={100} label="Create by" dataKey="createdBy" />
                    <Column width={100} label="User name" dataKey="userName" />
                    <Column
                      width={150}
                      label="System name"
                      dataKey="systemId"
                      flexGrow={1}
                      cellRenderer={systemRenderer}
                    />
                    <Column
                      width={150}
                      label={
                        <div className="flex-row flex-center-align">
                          <div style={{ marginRight: 4 }}>App key</div>
                          <Popover
                            content="The application key from BigPanda is a unique identifier associated with the application."
                            mouseEnterDelay={0.3}
                            placement="top"
                          >
                            <QuestionCircleOutlined />
                          </Popover>
                        </div>
                      }
                      dataKey="appKey"
                    />
                    <Column
                      width={150}
                      label={
                        <div className="flex-row flex-center-align">
                          <div style={{ marginRight: 4 }}>API key</div>
                          <Popover
                            content="The API key from BigPanda is used to authenticate API requests."
                            mouseEnterDelay={0.3}
                            placement="top"
                          >
                            <QuestionCircleOutlined />
                          </Popover>
                        </div>
                      }
                      dataKey="apiKey"
                      flexGrow={1}
                    />
                    <Column width={150} label="Options" dataKey="options" cellRenderer={optionsRenderer} flexGrow={1} />
                    <Column width={190} label="" dataKey="id" className="text-right" cellRenderer={controlRenderer} />
                  </Table>
                )}
              </AutoSizer>
            )}
          </Spin>
        </div>

        {showAddServiceModal && (
          <AddBigPandaModal
            {...props}
            activeIncident={activeIncident}
            onClose={(reload) => {
              setState({ showAddServiceModal: false, activeIncident: null });
              if (reload) reloadData();
            }}
          />
        )}
      </Container>
    </Modal>
  );
}

const ServiceBigPandaModal = injectIntl(ServiceBigPandaModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;

    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return { location, systemsMap, userInfo, credentials, userList };
  },
  { replace, updateLastActionInfo },
)(ServiceBigPandaModal);
