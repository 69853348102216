import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { Button, Spin } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

import BlackRightArrow from '../assets/blackRightArrow.svg';
import WhiteRightArrow from '../assets/whiteRightArrow.svg';

import { Tooltip } from '../../../lib/fui/react';
import { buildLocation, buildUrl, Defaults, parseLocation } from '../../../common/utils';
import BaseUrls from '../../app/BaseUrls';

import { eventMessages } from '../../../common/metric/messages';

const getTrendRotate = (currentValue, perviousValue) => {
  let angleNum = 0;
  const trend = perviousValue < currentValue ? 'up' : perviousValue > currentValue ? 'down' : 'stable';
  if (R.equals('up', trend)) {
    const num = perviousValue / currentValue === Infinity ? 0 : perviousValue / currentValue;
    const angle = -((1 - num) * 90);
    angleNum = angle > -90 ? angle : -90;
  } else if (R.equals('down', trend)) {
    const num = currentValue / perviousValue === Infinity ? 0 : currentValue / perviousValue;
    const angle = (1 - num) * 90;
    angleNum = angle < 90 ? angle : 90;
  }
  return angleNum;
};
const getViewData = (view) => (view || view === 0 ? view : 'NA');
const millsToMins = (ms) => (ms ? (Number(ms) / (1000 * 60)).toFixed(1) : ms);
const floatFixed = (num) => (num ? Number(Number.parseFloat(num).toFixed(3)) : num);

export function BuildBaseInfo({
  summaryObj,
  pastTimesSummaryObj,
  loading,
  startTimeObj,
  endTimeObj,
  pastStartTimesList,
  currentViewDataStatistics,
  perviousViewDataStatistics,
  push,
  location,
  currentTheme,
  systemId,
  isCloudCost,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    currentViewData: {},
    perviousViewData: {},
  });
  const { currentViewData, perviousViewData } = state;
  const params = parseLocation(location);

  useEffect(() => {
    setState({
      currentViewData: {
        ...currentViewData,
        totalIncidents: (R.find((item) => item.systemKey === systemId, summaryObj || {}) || {}).totalIncidents,
        totalAnomalies: currentViewDataStatistics.totalAnomalies,
        avgCost: currentViewDataStatistics.avgCost,
        daily: currentViewDataStatistics.daily,
      },
      perviousViewData: {
        ...perviousViewData,
        totalIncidents: (R.find((item) => item.systemKey === systemId, pastTimesSummaryObj || {}) || {}).totalIncidents,
        totalAnomalies: perviousViewDataStatistics.totalAnomalies,
        avgCost: perviousViewDataStatistics.avgCost,
        daily: perviousViewDataStatistics.daily,
      },
    });
  }, [summaryObj, pastTimesSummaryObj, currentViewDataStatistics, perviousViewDataStatistics]);

  const tooltipTimeRender = (key) => {
    const styleObj = {
      fontWeight: 'bold',
      margin: '0 3px 0 5px',
      wordBreak: 'break-all',
      flexShrink: 0,
    };
    return (
      <div style={{ color: '#000', fontSize: 12 }}>
        <div className="flex-row flex-center-align">
          <span style={{ ...styleObj }}>
            {moment.utc(pastStartTimesList[0]).format(Defaults.DateFormat)} ~{' '}
            {moment.utc(pastStartTimesList[1]).format(Defaults.DateFormat)}
          </span>
          <span>:</span>
          <span style={{ ...styleObj, flexShrink: 1, fontSize: 14, margin: '0px 5px 0 8px' }}>
            {key === 'avgCost' ? `$ ${getViewData(floatFixed(perviousViewData[key]))}` : perviousViewData[key] || 0}
          </span>
        </div>
        <div className="flex-row flex-center-align">
          <span style={{ ...styleObj }}>
            {moment.utc(startTimeObj).format(Defaults.DateFormat)} ~{' '}
            {moment.utc(endTimeObj).format(Defaults.DateFormat)}
          </span>
          <span>:</span>
          <span style={{ ...styleObj, flexShrink: 1, fontSize: 14, margin: '0px 5px 0 8px' }}>
            {key === 'avgCost' ? `$ ${getViewData(floatFixed(currentViewData[key]))}` : currentViewData[key] || 0}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="flex-col content-bg corner-10 overflow-x-hidden overflow-y-auto"
      style={{ flexShrink: 0, height: '100%' }}
    >
      <div className="font-14 bold flex-row flex-space-between flex-center-align">
        {isCloudCost ? 'Cost statistics' : 'Incident statistics'}
      </div>
      <div>
        <Spin spinning={loading} wrapperClassName="width-auto">
          {!loading && (
            <div className="insight-baseinfo" style={{ paddingRight: 0, marginTop: 8 }}>
              {isCloudCost && (
                <>
                  {currentViewData.avgCost !== 0 && currentViewData.avgCost ? (
                    <div className="insight-baseinfo-group">
                      <Tooltip
                        color="#f5f7f9"
                        title={
                          !R.equals('NA', getViewData(currentViewData.avgCost)) ? tooltipTimeRender('avgCost') : null
                        }
                        overlayStyle={{ minWidth: 230, maxWidth: 350, color: '#000' }}
                      >
                        <div className="insight-baseinfo">
                          {!R.equals('NA', getViewData(currentViewData.avgCost)) && (
                            <img
                              src={currentTheme === 'dark' ? WhiteRightArrow : BlackRightArrow}
                              style={{
                                width: 25,
                                height: 25,
                                transform: `rotate(${getTrendRotate(
                                  currentViewData.avgCost,
                                  perviousViewData.avgCost,
                                )}deg)`,
                              }}
                              className="group-icon"
                              alt=""
                            />
                          )}
                          <div className="clickable">
                            <div className="" style={{ color: 'var(--text-color)' }}>
                              {currentViewData.daily ? 'Avg daily cost' : 'Avg hourly cost'}
                            </div>
                            <div className="group-desc">{`$ ${getViewData(floatFixed(currentViewData.avgCost))}`}</div>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div style={{ color: 'var(--text-color-secondary)', marginTop: 8 }}>
                      No data for this time period
                    </div>
                  )}
                </>
              )}
              {!isCloudCost && (
                <>
                  <div className="insight-baseinfo-group">
                    <Tooltip
                      color="#f5f7f9"
                      title={
                        !R.equals('NA', getViewData(currentViewData.totalAnomalies))
                          ? tooltipTimeRender('totalAnomalies')
                          : null
                      }
                      overlayStyle={{ minWidth: 230, maxWidth: 350, color: '#000' }}
                    >
                      <div className="insight-baseinfo-group">
                        {!R.equals('NA', getViewData(currentViewData.totalAnomalies)) && (
                          <img
                            src={currentTheme === 'dark' ? WhiteRightArrow : BlackRightArrow}
                            style={{
                              width: 25,
                              height: 25,
                              transform: `rotate(${getTrendRotate(
                                currentViewData.totalAnomalies,
                                perviousViewData.totalAnomalies,
                              )}deg)`,
                            }}
                            className="group-icon"
                            alt=""
                          />
                        )}
                        <div className="clickable">
                          <div className="" style={{ color: 'var(--text-color)' }}>
                            Anomaly patterns
                          </div>
                          <div className="group-desc">{`${getViewData(currentViewData.totalAnomalies)} Detected`}</div>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="insight-baseinfo-group">
                    <Tooltip
                      color="#f5f7f9"
                      title={
                        !R.equals('NA', getViewData(currentViewData.totalIncidents))
                          ? tooltipTimeRender('totalIncidents')
                          : null
                      }
                      overlayStyle={{ minWidth: 230, maxWidth: 350, color: '#000' }}
                    >
                      <div className="insight-baseinfo-group">
                        {!R.equals('NA', getViewData(currentViewData.totalIncidents)) && (
                          <img
                            src={currentTheme === 'dark' ? WhiteRightArrow : BlackRightArrow}
                            style={{
                              width: 25,
                              height: 25,
                              transform: `rotate(${getTrendRotate(
                                currentViewData.totalIncidents,
                                perviousViewData.totalIncidents,
                              )}deg)`,
                            }}
                            className="group-icon"
                            alt=""
                          />
                        )}
                        <div className="clickable">
                          <div className="" style={{ color: 'var(--text-color)' }}>
                            Incidents
                          </div>
                          <div className="group-desc">{`${getViewData(currentViewData.totalIncidents)} Detected`}</div>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
}

export function BuildBaseInfoTop({
  summaryObj,
  loading,
  location,
  intl,
  systemId,
  customerName,
  environmentId,
  startTime,
  endTime,
}: Object) {
  const viewData = useMemo(() => {
    return R.find((item) => item.systemKey === systemId, summaryObj || []) || {};
  }, [summaryObj]);

  const handleMatchedIncidentClick = () => {
    const params = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemPredictionMatched, {}, params), '_blank');
  };

  return (
    <Spin spinning={loading}>
      <div className="insight-baseinfo">
        <div className="insight-baseinfo-group">
          <div>
            <div className="grouo-title">
              <span style={{ color: 'var(--text-color)' }}>Correct predictions</span>
            </div>
            <div className="flex-row flex-center-align flex-space-between">
              <div className="group-desc">{`${getViewData(viewData.incidentsPredicted)}`}</div>
              <Button
                className="dashButton"
                size="small"
                icon={<UnorderedListOutlined />}
                style={{ marginLeft: 10 }}
                onClick={() => handleMatchedIncidentClick()}
              >
                {intl.formatMessage(eventMessages.details)}
              </Button>
            </div>
          </div>
        </div>
        <div className="insight-baseinfo-group">
          <div>
            <div className="grouo-title" style={{ color: 'var(--text-color)' }}>
              Avg lead time
            </div>
            <div className="group-desc">{`${getViewData(millsToMins(viewData.avgLeadTime))} Min`}</div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
