import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import moment from 'moment';
import RcDatePicker from 'react-datepicker';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Select, Spin, message } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { buildUrl, BackgroundCall, Defaults, utcObjToLocalDate, localDateToUtcObj } from '../../../common/utils';
import { Input, Modal } from '../../../lib/fui/react';
import { TimeInput } from '../../share/TimeInput';
import { loadProjectInfo, updateLastActionInfo, createSetAction } from '../../../common/app/actions';

import { appFieldsMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  projectList: Array<Object>,
  // eslint-disable-next-line
  projectInstanceComponentMap: Object,
  loadProjectInfo: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createSetAction: Function,

  projectName: String,
  instanceName: String,
  startTimestamp: Number,
  endTimestamp: Number,
  showKeywordSearch: Boolean,
  noMetricProject: Boolean,
  onClose: Function,
  // eslint-disable-next-line
  globalInfo: Object,
};

class TimeSelectModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { projectList } = props;
    const { projectName, instanceName, startTimestamp, endTimestamp, noMetricProject } = props;
    const currentProject = R.find((project) => project.projectName === projectName, projectList || []);
    let systemProjectList = currentProject
      ? currentProject.systemId
        ? R.filter((project) => project.systemId === currentProject.systemId, projectList || [])
        : [currentProject]
      : [];
    if (noMetricProject) {
      systemProjectList = R.filter((p) => !p.isMetric, systemProjectList);
    }
    const projectNames = R.map((item) => item.projectName, systemProjectList);

    // reset project
    let newProject = projectName;
    if (!newProject || !projectNames.includes(newProject)) {
      newProject = projectNames[0];
    }

    this.state = {
      isLoading: false,

      projectName: newProject,
      instanceName,
      startTimestamp: startTimestamp || moment.utc().valueOf(),
      endTimestamp: endTimestamp || moment.utc().valueOf(),
      keywordFilter: '',
    };
    this.projectListOptions = R.map(
      (item) => ({
        value: item.projectName,
        label: `${item.projectDisplayName}${item.owner ? `@${item.owner}` : ''}${
          item.systemName ? ` (${item.systemName})` : ''
        }`,
      }),
      systemProjectList,
    );
    this.instanceListOptions = [];
  }

  componentDidMount() {
    const { projectList } = this.props;
    const { projectName } = this.state;

    // relaod project meta data
    const currentProject = R.find((project) => project.projectName === projectName, projectList || []);
    if (currentProject && !currentProject.hasAllInstanceInfo) {
      this.reloadInstance();
    } else {
      this.parseData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projectList !== this.props.projectList) {
      this.parseData(nextProps);
    }
  }

  @autobind
  reloadInstance() {
    const { loadProjectInfo } = this.props;
    const { projectName, startTimestamp, endTimestamp } = this.state;

    if (projectName && startTimestamp && endTimestamp) {
      this.setState({ isLoading: true });
      const startTimeObj = moment.utc(startTimestamp).startOf('day');
      const endTimeObj = moment.utc(endTimestamp).endOf('day');
      loadProjectInfo(
        {
          projectName,
          includeInstance: true,
          startTimestamp: startTimeObj.valueOf(),
          endTimestamp: endTimeObj.valueOf(),
        },
        false,
      );
    }
  }

  @autobind
  async parseData(props) {
    const { projectList, projectInstanceComponentMap, credentials, updateLastActionInfo, createSetAction } = props;
    this.setState({ isLoading: true });

    const { projectName, instanceName } = this.state;

    // reset instance
    const currentProject = R.find((project) => project.projectName === projectName, projectList || []);
    const instanceList = currentProject ? currentProject.instanceList || [] : [];

    // get all components from instances
    const prevInstanceComponentMap = get(projectInstanceComponentMap, projectName, {});
    const { allInstanceComponentMap } = await BackgroundCall.GetSetComponent({
      updateLastActionInfo,
      createSetAction,
      credentials,
      prevInstanceComponentMap,
      projectName,
      instanceNameList: instanceList,
    });
    const appNameList = !R.isEmpty(allInstanceComponentMap)
      ? { ...prevInstanceComponentMap, ...allInstanceComponentMap }
      : prevInstanceComponentMap;

    let newInstance = instanceName;
    if (!instanceName || !instanceList.includes(instanceName)) {
      newInstance = instanceList[0];
    }

    const instanceDisplayNameMap = {};
    await fetchGet(getEndpoint('instance-display-name'), {
      ...credentials,
      instanceDisplayNameRequestList: JSON.stringify([
        { projectName: currentProject?.projectShortName, customerName: currentProject?.owner },
      ]),
    })
      .then((d1) => {
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, d1 || []);
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });

    this.instanceListOptions = R.map((i) => {
      const component = get(appNameList, i);
      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, i, {
        pn: currentProject?.projectShortName,
        owner: currentProject?.owner,
      });
      return { value: i, label: component && component !== i ? `${instanceStr} (${component})` : instanceStr };
    }, instanceList || []);
    this.setState({ isLoading: false, instanceName: newInstance });
  }

  @autobind
  handleConfirm() {
    const { projectList, onClose } = this.props;
    if (onClose) {
      const { projectName, instanceName, startTimestamp, endTimestamp, ...rest } = this.state;
      const currentProject = R.find((project) => project.projectName === projectName, projectList);
      if (currentProject && currentProject.isMetric) {
        const startTimeObj = moment.utc(startTimestamp);
        const endTimeObj = moment.utc(endTimestamp);
        const query = {
          projectName,
          instanceGroup: 'All',
          startTimestamp: startTimeObj.startOf('day').valueOf(),
          endTimestamp: endTimeObj.endOf('day').valueOf(),
          justInstanceList: instanceName,
        };
        window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
        onClose();
      } else {
        onClose({ projectName, instanceName, startTimestamp, endTimestamp, ...rest });
      }
    }
  }

  render() {
    const { intl, projectList } = this.props;
    const { onClose, showKeywordSearch } = this.props;
    const { isLoading, projectName, instanceName, startTimestamp, endTimestamp } = this.state;

    const currentProject = R.find((project) => project.projectName === projectName, projectList || []);
    const isMetric = currentProject && currentProject.isMetric;
    const startTimeObj = moment.utc(startTimestamp);
    const endTimeObj = moment.utc(endTimestamp);
    const keywordFilterLink = VLink.state(this, 'keywordFilter').check(
      (value) => !value || (Boolean(value) && R.test(/^"[^\n\r]+"$/, value)),
      'keyword must be in the right format',
    );

    const hasError =
      !projectName ||
      !instanceName ||
      !startTimestamp ||
      !endTimestamp ||
      startTimestamp > endTimestamp ||
      (showKeywordSearch && keywordFilterLink.error);
    return (
      <Modal
        width={650}
        title={intl.formatMessage(logMessages.timeRange)}
        onCancel={() => onClose()}
        onOk={this.handleConfirm}
        okButtonProps={{ disabled: hasError }}
        visible
        maskClosable={false}
        zIndex={1002}
      >
        <Spin spinning={isLoading}>
          <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
            <span className="label bold" style={{ minWidth: 120 }}>
              {intl.formatMessage(appFieldsMessages.project)}:
            </span>
            <div className="flex-grow flex-min-width">
              <Select
                className="full-width"
                showSearch
                filterOption
                options={this.projectListOptions}
                value={projectName}
                onChange={(projectName) =>
                  this.setState({ projectName, instanceName: undefined }, () => {
                    this.reloadInstance();
                  })
                }
              />
            </div>
          </div>
          <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
            <span className="label bold" style={{ minWidth: 120 }}>
              {intl.formatMessage(appFieldsMessages.instance)}:
            </span>
            <div className="flex-grow flex-min-width">
              <Select
                className="full-width"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                  (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.instanceListOptions}
                value={instanceName}
                onChange={(instanceName) => this.setState({ instanceName })}
              />
            </div>
          </div>
          <div className="flex-row flex-center-align rc-date-picker" style={{ marginBottom: 16 }}>
            <span className="label bold" style={{ width: 120 }}>
              Start Time:
            </span>
            <RcDatePicker
              showTimeInput
              timeIntervals={15}
              showPopperArrow={false}
              timeFormat={Defaults.DatePickerTime}
              dateFormat={Defaults.DateTimeFormat1}
              selected={utcObjToLocalDate(startTimeObj)}
              customTimeInput={<TimeInput />}
              maxDate={utcObjToLocalDate(moment.utc().add(1, 'days').endOf('day'))}
              onChange={(startTime) => {
                this.setState({ startTimestamp: localDateToUtcObj(startTime).valueOf() });
              }}
            />
            <span className="label bold" style={{ width: 80, marginLeft: 20 }}>
              End Time:
            </span>
            <RcDatePicker
              showTimeInput
              timeIntervals={15}
              showPopperArrow={false}
              timeFormat={Defaults.DatePickerTime}
              dateFormat={Defaults.DateTimeFormat1}
              selected={utcObjToLocalDate(endTimeObj)}
              customTimeInput={<TimeInput />}
              maxDate={utcObjToLocalDate(moment.utc().add(1, 'days').endOf('day'))}
              onChange={(endTime) => {
                this.setState({ endTimestamp: localDateToUtcObj(endTime).valueOf() });
              }}
            />
          </div>

          {showKeywordSearch && !isMetric && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
              <span className="label bold" style={{ width: 120 }}>
                Keyword Filter:
              </span>
              <div className="flex-grow flex-min-width">
                <Input valueLink={keywordFilterLink} placeholder={`"keyword"`} fullWidth style={{ height: 24 }} />
              </div>
            </div>
          )}
        </Spin>
      </Modal>
    );
  }
}

const TimeSelectModal = injectIntl(TimeSelectModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { loadStatus, projects: projectList, projectInstanceComponentMap, globalInfo } = state.app;
    return {
      location,
      loadStatus,
      credentials,
      projectList,
      projectInstanceComponentMap,
      globalInfo,
    };
  },
  { push, replace, loadProjectInfo, updateLastActionInfo, createSetAction },
)(TimeSelectModal);
