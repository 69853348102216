/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import {
  QuestionCircleOutlined,
  FileAddOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import {
  Input,
  Button,
  Select,
  Alert,
  Form,
  Popover,
  Popconfirm,
  message,
  AutoComplete,
  TreeSelect,
  Select as AntSelect,
  DatePicker,
  Checkbox,
} from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { parseJSON, pickNotNil, sleep } from '../../../../common/utils';
import { Container, CodeMirror } from '../../../../lib/fui/react';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

class EndpointSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmitting: false,
      isVerifying: false,
      hasError: false,
      errorMessage: '',
      optionsTable: [],
      millisecondUnit: 3600000,
      componentName: [],
      useHostName: 'Host',
    };

    this.millisecondUnitOption = [{ label: 'hour', value: 3600000 }];
    this.dataDogfields = [];
    this.dataDogTreeData = [];
    this.allDataDogTags = [];
    this.allDataDogComponentTags = [];
    this.additonalFieldsOptions = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName, currentProject, dataType } = props;
    const cloudType = get(currentProject, ['cloudType']);

    this.setState({ isLoading: true });
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const { success, message, ...fields } = data || {};

        if (success === undefined || success) {
          // pre parse data
          const newFields = this.parseData(props, fields);
          if (cloudType === 'DataDog') {
            const { apiKey, appKey } = newFields;
            fetchGet(getEndpoint('datadog-setting'), {
              ...credentials,
              projectName: currentProject?.projectShortName || projectName,
              customerName: currentProject?.owner,
              apiKey,
              appKey,
              dataType,
            })
              .then((data) => {
                const { tags } = data;
                this.handleDataDogData(data);
                this.setState({ isLoading: false, hasError: false, ...newFields, componentOptions: tags || [] });
              })
              .catch((err) => {
                this.setState({ isLoading: false, hasError: true, errorMessage: String(err) });
              });
          } else {
            this.setState({ isLoading: false, hasError: false, ...newFields });
          }
        } else {
          this.setState({ isLoading: false, hasError: true, errorMessage: message });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false, hasError: true, errorMessage: String(err) });
      });
  }

  @autobind
  handleTreeData(metrics) {
    const treeData = [];
    R.forEach((metric) => {
      const metricArr = metric.split('.');
      const nameLen = metricArr.length;
      let nodes = treeData;
      let metricName = '';
      for (let i = 0; i < nameLen; i += 1) {
        const prefix = metricArr[i];
        const nodesNum = nodes.length;
        metricName = metricName === '' ? prefix : `${metricName}.${prefix}`;
        if (nodesNum === 0 || nodes[nodesNum - 1].label !== prefix) {
          nodes.push({
            title: metricName,
            value: metricName,
            key: metricName,
            label: prefix,
            children: [],
          });
        }
        nodes = nodes[nodes.length - 1].children;
      }
    }, metrics);
    this.dataDogTreeData = treeData;
  }

  @autobind
  handleTagsData(tags) {
    const allTags = [];
    const allComponentTags = [];

    R.forEach(
      (name) => {
        const children = tags[name];
        allTags.push(name);
        allComponentTags.push(name);
        R.forEach(
          (tag) => {
            allTags.push(`${name}=${tag}`);
          },
          R.sort((a, b) => a.localeCompare(b), children),
        );
      },
      R.sort((a, b) => a.localeCompare(b), R.keys(tags)),
    );
    this.allDataDogTags = allTags;
    this.allDataDogComponentTags = allComponentTags;
  }

  @autobind
  handleDataDogMetricChange(metrics) {
    let kpiMetrics = [];
    R.forEach((metricName) => {
      R.forEach((metric) => {
        if (metric.indexOf(metricName) === 0) {
          kpiMetrics.push(metric);
        }
      }, this.state.kpiMetrics);
    }, metrics);
    kpiMetrics = R.uniq(kpiMetrics);
    this.setState({ metrics, kpiMetrics });
  }

  @autobind
  handleDataDogKPIMetricChange(metrics) {
    this.setState({ kpiMetrics: metrics });
  }

  @autobind
  handleDataDogComponentChange(componentName) {
    this.setState({ componentName });
  }

  @autobind
  handleDataDogAndTagChange(tag) {
    const { tagsExp } = this.state;
    if (tag) {
      this.setState({ tagsExp: tagsExp ? `${tagsExp} AND ${tag}` : tag });
    }
  }

  @autobind
  handleDataDogOrTagChange(tag) {
    const { tagsExp } = this.state;
    if (tag) {
      this.setState({ tagsExp: tagsExp ? `${tagsExp} OR ${tag}` : tag });
    }
  }

  @autobind
  handleDataDogData(data, isMetric) {
    let metrics = [];
    const { success, results } = data;

    if (success && results) {
      metrics = R.uniq(results.metrics || []);
      metrics = metrics.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      this.handleTagsData(data?.tags || []);
      this.handleTreeData(metrics);
    }
  }

  @autobind
  dataDogVerify() {
    const { intl, credentials, projectName, currentProject, dataType } = this.props;
    const cloudType = get(currentProject, ['cloudType']);
    const { apiKey, appKey } = this.state;
    this.setState({ isVerifying: true });
    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
      apikey: apiKey,
      appkey: appKey,
      dataType,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          fetchGet(getEndpoint('datadog-setting'), {
            ...credentials,
            projectName: currentProject?.projectShortName || projectName,
            customerName: currentProject?.owner,
            dataType,
          })
            .then((data) => {
              this.handleDataDogData(data);
              this.setState({ isVerifying: false, hasError: false });
            })
            .catch((err) => {
              message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
              this.setState({ isVerifying: false, hasError: true, errorMessage: err.message || String(err) });
            });
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
          this.setState({ isVerifying: false, hasError: true, errorMessage: String(errMsg) });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isVerifying: false, hasError: true, errorMessage: err.message || String(err) });
      });
  }

  @autobind
  submit() {
    const { intl, credentials, projectName, currentProject } = this.props;
    const { collectionFrequency, millisecondUnit, additionalFilterList, startDate, endDate, useHostName } = this.state;
    const { defaultFields } = this.state;
    const cloudType = get(currentProject, ['cloudType']);
    const isMetric = get(currentProject, ['isMetric']);
    this.setState({ isSubmitting: true });

    // pick fields by diff project type
    const feilds = this.pickFields(this.props);
    let newParams = R.pick(feilds, this.state);
    newParams = this.convertFields(newParams);
    newParams = pickNotNil(newParams);
    if (additionalFilterList) {
      const additionalFilter = [];
      R.forEach((item) => {
        if (item) {
          const addtionList = R.map((n) => n.trim(), item.split('AND'));
          additionalFilter.push(addtionList);
        }
      }, additionalFilterList);
      newParams.additionalFilter = JSON.stringify(additionalFilter);
    }
    if (cloudType === 'ServiceNow') {
      newParams.additionalFields = JSON.stringify(R.difference(newParams.additionalFields, defaultFields) || []);
    }

    if (cloudType === 'GoogleCloudCost') {
      newParams.collectionFrequency = Number(collectionFrequency) * millisecondUnit;
    }

    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
      ...newParams,
      additionalQuery: newParams.additionalQuery || undefined,
      useHostName: useHostName !== 'Host',
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          if (cloudType === 'DataDog') {
            fetchPost(getEndpoint('datadog-update-components'), {
              ...credentials,
              startDate: startDate?.startOf('day')?.valueOf(),
              endDate: endDate?.startOf('day')?.valueOf(),
              projectName: currentProject?.projectShortName || projectName,
              customerName: currentProject?.owner,
              componentName: isMetric ? newParams?.componentName : newParams?.componentFields,
              useHostName: useHostName !== 'Host',
            })
              .then((res) => {
                const { success, message: errMsg } = data || {};
                if (success === undefined || success) {
                  this.setState({ isSubmitting: false, hasError: false });
                  this.reloadData(this.props);
                } else {
                  message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
                  this.setState({ isSubmitting: false, hasError: true, errorMessage: String(errMsg) });
                }
              })
              .catch((err) => {
                message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
                this.setState({ isSubmitting: false, hasError: true, errorMessage: err.message || String(err) });
              });

            fetchPost(getEndpoint('datadog-setting'), {
              ...credentials,
              projectName: currentProject?.projectShortName || projectName,
              customerName: currentProject?.owner,
              cloudType,
              ...newParams,
              additionalQuery: newParams.additionalQuery || undefined,
              useHostName: useHostName !== 'Host',
            })
              .then((data) => {
                const { success, message: errMsg } = data || {};
                if (success === undefined || success) {
                  message.success(intl.formatMessage(appMessages.apiSuccess));
                  this.setState({ isSubmitting: false, hasError: false });
                  this.reloadData(this.props);
                } else {
                  message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
                  this.setState({ isSubmitting: false, hasError: true, errorMessage: String(errMsg) });
                }
              })
              .catch((err) => {
                message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
                this.setState({ isSubmitting: false, hasError: true, errorMessage: err.message || String(err) });
              });
          } else {
            message.success(intl.formatMessage(appMessages.apiSuccess));
            this.setState({ isSubmitting: false, hasError: false });
            this.reloadData(this.props);
          }
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
          this.setState({ isSubmitting: false, hasError: true, errorMessage: String(errMsg) });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isSubmitting: false, hasError: true, errorMessage: err.message || String(err) });
      });
  }

  @autobind
  parseData(props, fields) {
    const { currentProject } = props;
    const { millisecondUnit } = this.state;
    const cloudType = get(currentProject, ['cloudType']);

    let newFields = fields || {};

    switch (cloudType) {
      case 'ServiceNow': {
        let fieldsList = get(newFields, 'fields', []);
        const defaultFields = get(newFields, 'defaultFields', []);
        fieldsList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), fieldsList);
        this.additonalFieldsOptions = R.map((item) => {
          return { value: item, label: item, disabled: defaultFields.includes(item) };
        }, fieldsList);
        newFields.defaultFields = defaultFields;
        newFields.additionalFields = [...defaultFields, ...newFields.additionalFields];
        break;
      }
      case 'DataDog': {
        newFields.fields = parseJSON(newFields.fields) || [];
        this.dataDogfields = get(newFields, ['fieldSet', 'myArrayList'], []);
        if (newFields.dataType !== 'Metric') {
          newFields.additionalFilterList = R.map((item) => {
            return item.join(' AND ');
          }, newFields.additionalFilter || []);
        }
        if (newFields.componentName && !R.isEmpty(newFields.componentName)) {
          newFields.componentName = newFields.componentName.split(',');
        } else {
          newFields.componentName = [];
        }
        if (newFields.componentFields) {
          newFields.componentName = newFields.componentFields;
        }
        newFields.useHostName = newFields.useHostName ? 'Host name' : 'Host';
        break;
      }
      case 'CloudWatch': {
        break;
      }
      case 'GoogleBigQuery': {
        const credentialData = parseJSON(newFields.credentialData) || [];
        newFields = {
          ...newFields,
          type: credentialData.type,
          projectId: credentialData.project_id,
          privateKeyId: credentialData.private_key_id,
          privateKey: credentialData.private_key,
          clientEmail: credentialData.client_email,
          clientId: credentialData.client_id,
          authUri: credentialData.auth_uri,
          tokenUri: credentialData.token_uri,
          authProviderCertUrl: credentialData.auth_provider_x509_cert_url,
          clientCertUrl: credentialData.client_x509_cert_url,
          queries: R.map((query) => ({ query }), newFields.queries || []),
        };
        break;
      }
      case 'GoogleCloudCost': {
        const credentialData = parseJSON(newFields.credentialData) || [];
        newFields = {
          ...newFields,
          type: credentialData.type,
          projectId: credentialData.project_id,
          privateKeyId: credentialData.private_key_id,
          privateKey: credentialData.private_key,
          clientEmail: credentialData.client_email,
          clientId: credentialData.client_id,
          authUri: credentialData.auth_uri,
          tokenUri: credentialData.token_uri,
          authProviderCertUrl: credentialData.auth_provider_x509_cert_url,
          clientCertUrl: credentialData.client_x509_cert_url,
          table: newFields.table,
          optionsTable: R.map((item) => ({ value: item, label: item }), newFields.tableList || []),
          collectionFrequency: newFields.collectionFrequency / millisecondUnit,
        };
        break;
      }
      case 'GooglePubsub': {
        const credentialData = parseJSON(newFields.serviceAccountKey) || [];
        newFields = {
          ...newFields,
          type: credentialData.type,
          projectId: credentialData.project_id,
          privateKeyId: credentialData.private_key_id,
          privateKey: credentialData.private_key,
          clientEmail: credentialData.client_email,
          clientId: credentialData.client_id,
          authUri: credentialData.auth_uri,
          tokenUri: credentialData.token_uri,
          authProviderCertUrl: credentialData.auth_provider_x509_cert_url,
          clientCertUrl: credentialData.client_x509_cert_url,
        };
        break;
      }
      case 'SnowFlake': {
        newFields = {
          ...newFields,
          sqlQuery: newFields?.sqlQuery || '',
        };
        break;
      }
      default:
        break;
    }

    return newFields;
  }

  @autobind
  pickFields(props) {
    const { currentProject } = props;
    const cloudType = get(currentProject, ['cloudType']);

    let fields = [];

    switch (cloudType) {
      case 'ServiceNow': {
        fields = [
          'host',
          'proxy',
          'serviceNowUser',
          'serviceNowPassword',
          'clientId',
          'clientSecret',
          'sysparmQuery',
          'timestampFormat',
          'instanceField',
          'instanceFieldRegex',
          'additionalFields',
        ];
        break;
      }
      case 'DataDog': {
        fields = [
          'appKey',
          'apiKey',
          'instanceKey',
          'fields',
          'metrics',
          'kpiMetrics',
          'componentName',
          'tagsExp',
          'additionalQuery',
        ];
        break;
      }
      case 'CloudWatch': {
        fields = ['accessKey', 'secretKey'];
        break;
      }
      case 'GoogleBigQuery': {
        fields = [
          'type',
          'projectId',
          'privateKeyId',
          'privateKey',
          'clientEmail',
          'clientId',
          'authUri',
          'tokenUri',
          'authProviderCertUrl',
          'clientCertUrl',
          'queries',
        ];
        break;
      }
      case 'GoogleCloudCost': {
        fields = [
          'type',
          'projectId',
          'privateKeyId',
          'privateKey',
          'clientEmail',
          'clientId',
          'authUri',
          'tokenUri',
          'authProviderCertUrl',
          'clientCertUrl',
          'table',
        ];
        break;
      }
      case 'GooglePubsub': {
        fields = [
          'subscriptionId',
          'type',
          'projectId',
          'privateKeyId',
          'privateKey',
          'clientEmail',
          'clientId',
          'authUri',
          'tokenUri',
          'authProviderCertUrl',
          'clientCertUrl',
          'instanceField',
          'dataFields',
        ];
        break;
      }
      case 'SnowFlake': {
        fields = ['database', 'passwd', 'snowflakeUserName', 'dbAddress', 'warehouse', 'sqlQuery'];
        break;
      }
      default:
        break;
    }

    return fields;
  }

  @autobind
  convertFields(fields) {
    const { currentProject } = this.props;
    const cloudType = get(currentProject, ['cloudType']);
    const isMetric = get(currentProject, ['isMetric']);

    let newParams = fields || {};

    switch (cloudType) {
      case 'ServiceNow': {
        break;
      }
      case 'DataDog': {
        newParams.fields = JSON.stringify(newParams.fields || []);
        newParams.appkey = newParams.appKey;
        delete newParams.appKey;
        newParams.apikey = newParams.apiKey;
        delete newParams.apiKey;
        newParams.metrics = JSON.stringify(newParams.metrics);
        newParams.kpiMetrics = JSON.stringify(newParams.kpiMetrics);
        newParams.tagsExp = newParams.tagsExp ? newParams.tagsExp.replaceAll('=', ':') : newParams.tagsExp;
        newParams = {
          ...newParams,
          ...(isMetric
            ? { componentName: newParams.componentName.join(',') }
            : { componentFields: JSON.stringify(newParams.componentName) }),
        };
        break;
      }
      case 'CloudWatch': {
        break;
      }
      case 'GoogleBigQuery': {
        const content = {
          [`type`]: newParams.type,
          [`project_id`]: newParams.projectId,
          [`private_key_id`]: newParams.privateKeyId,
          [`private_key`]: (newParams.privateKey || '').replaceAll('\\n', '\n'),
          [`client_email`]: newParams.clientEmail,
          [`client_id`]: newParams.clientId,
          [`auth_uri`]: newParams.authUri,
          [`token_uri`]: newParams.tokenUri,
          [`auth_provider_x509_cert_url`]: newParams.authProviderCertUrl,
          [`client_x509_cert_url`]: newParams.clientCertUrl,
        };
        newParams = {
          queries: JSON.stringify(
            R.filter(
              (query) => Boolean(query),
              R.map((item) => item.query, newParams.queries || []),
            ),
          ),
          content: btoa(JSON.stringify(content)),
        };
        break;
      }
      case 'GoogleCloudCost': {
        const content = {
          [`type`]: newParams.type,
          [`project_id`]: newParams.projectId,
          [`private_key_id`]: newParams.privateKeyId,
          [`private_key`]: (newParams.privateKey || '').replaceAll('\\n', '\n'),
          [`client_email`]: newParams.clientEmail,
          [`client_id`]: newParams.clientId,
          [`auth_uri`]: newParams.authUri,
          [`token_uri`]: newParams.tokenUri,
          [`auth_provider_x509_cert_url`]: newParams.authProviderCertUrl,
          [`client_x509_cert_url`]: newParams.clientCertUrl,
        };
        newParams = {
          table: newParams.table,
          content: btoa(JSON.stringify(content)),
        };
        break;
      }
      case 'GooglePubsub': {
        const content = {
          [`type`]: newParams.type,
          [`project_id`]: newParams.projectId,
          [`private_key_id`]: newParams.privateKeyId,
          [`private_key`]: (newParams.privateKey || '').replaceAll('\\n', '\n'),
          [`client_email`]: newParams.clientEmail,
          [`client_id`]: newParams.clientId,
          [`auth_uri`]: newParams.authUri,
          [`token_uri`]: newParams.tokenUri,
          [`auth_provider_x509_cert_url`]: newParams.authProviderCertUrl,
          [`client_x509_cert_url`]: newParams.clientCertUrl,
        };
        newParams = {
          projectId: newParams.projectId,
          subscriptionId: newParams.subscriptionId,
          serviceAccountKey: JSON.stringify(content),
          instanceField: newParams.instanceField,
          dataFields: JSON.stringify(newParams.dataFields),
        };
        break;
      }
      case 'SnowFlake': {
        newParams = {
          database: newParams.database,
          snowflakeUserName: newParams.snowflakeUserName,
          dbAddress: newParams.dbAddress,
          warehouse: newParams.warehouse,
          sqlQuery: newParams.sqlQuery,
          password: newParams.passwd,
          queryStr: newParams.sqlQuery,
        };
        break;
      }
      default:
        break;
    }

    return newParams;
  }

  @autobind
  getFormErr() {
    const { currentProject } = this.props;
    const cloudType = get(currentProject, ['cloudType']);
    const isMetric = get(currentProject, ['isMetric']);

    let hasErr = false;

    switch (cloudType) {
      case 'ServiceNow': {
        hasErr =
          !this.state.host || !this.state.serviceNowUser || !this.state.serviceNowPassword || !this.state.instanceField;
        break;
      }
      case 'DataDog': {
        hasErr = !this.state.appKey || !this.state.apiKey;
        if (!isMetric) {
          hasErr = hasErr || !this.state.instanceKey || (this.state.fields && this.state.fields.length === 0);
        }
        break;
      }
      case 'CloudWatch': {
        hasErr = !this.state.accessKey || !this.state.secretKey;
        break;
      }
      case 'GoogleBigQuery': {
        hasErr =
          !this.state.type ||
          !this.state.projectId ||
          !this.state.privateKeyId ||
          !this.state.privateKey ||
          !this.state.clientEmail ||
          !this.state.clientId ||
          !this.state.authUri ||
          !this.state.tokenUri ||
          !this.state.authProviderCertUrl ||
          !this.state.clientCertUrl;
        break;
      }
      case 'GoogleCloudCost': {
        hasErr =
          !this.state.type ||
          !this.state.projectId ||
          !this.state.privateKeyId ||
          !this.state.privateKey ||
          !this.state.clientEmail ||
          !this.state.clientId ||
          !this.state.authUri ||
          !this.state.tokenUri ||
          !this.state.authProviderCertUrl ||
          !this.state.clientCertUrl;
        break;
      }
      case 'GooglePubsub': {
        hasErr =
          !this.state.subscriptionId ||
          !this.state.type ||
          !this.state.projectId ||
          !this.state.privateKeyId ||
          !this.state.privateKey ||
          !this.state.clientEmail ||
          !this.state.clientId ||
          !this.state.authUri ||
          !this.state.tokenUri ||
          !this.state.authProviderCertUrl ||
          !this.state.clientCertUrl;
        break;
      }
      default:
        break;
    }

    return hasErr;
  }

  @autobind
  renderFormServiceNow({ isSubmitting, hasFormErr }) {
    const { intl } = this.props;

    return (
      <Form layout="vertical" style={{ paddingBottom: 250 }}>
        <Form.Item
          label="Host"
          required
          validateStatus={!this.state.host ? 'error' : 'success'}
          help={!this.state.host ? 'This input is required.' : undefined}
        >
          <Input value={this.state.host} onChange={(e) => this.setState({ host: e.target.value })} />
        </Form.Item>
        <Form.Item label="Proxy">
          <Input value={this.state.proxy} onChange={(e) => this.setState({ proxy: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="ServiceNow user"
          required
          validateStatus={!this.state.serviceNowUser ? 'error' : 'success'}
          help={!this.state.serviceNowUser ? 'This input is required.' : undefined}
        >
          <Input
            value={this.state.serviceNowUser || ''}
            onChange={(e) => this.setState({ serviceNowUser: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="ServiceNow password"
          required
          validateStatus={!this.state.serviceNowPassword ? 'error' : 'success'}
          help={!this.state.serviceNowPassword ? 'This input is required.' : undefined}
        >
          <Input.Password
            autoComplete="new-password"
            value={this.state.serviceNowPassword || ''}
            onChange={(e) => this.setState({ serviceNowPassword: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Client Id">
          <Input.Password
            autoComplete="new-password"
            value={this.state.clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Client Secret">
          <Input.Password
            autoComplete="new-password"
            value={this.state.clientSecret}
            onChange={(e) => this.setState({ clientSecret: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Filter query">
          <Input value={this.state.sysparmQuery} onChange={(e) => this.setState({ sysparmQuery: e.target.value })} />
        </Form.Item>
        <Form.Item label="Special timestamp format">
          <Input
            placeholder="yyyy-MM-dd HH:mm:ss"
            value={this.state.timestampFormat}
            onChange={(e) => this.setState({ timestampFormat: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label={
            <Popover
              title={null}
              content={
                <div style={{ width: 320 }}>
                  This instance in InsightFinder represents the ticket grouping granularity for pattern learning.
                </div>
              }
              placement="right"
            >
              <span>
                InsightFinder Analysis Instance in ServiceNow
                <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
              </span>
            </Popover>
          }
          required
          validateStatus={!this.state.instanceField ? 'error' : 'success'}
          help={!this.state.instanceField ? 'This input is required.' : undefined}
        >
          <Input value={this.state.instanceField} onChange={(e) => this.setState({ instanceField: e.target.value })} />
        </Form.Item>
        <Form.Item label="Regular expression for instance name extraction">
          <Input
            value={this.state.instanceFieldRegex}
            onChange={(e) => this.setState({ instanceFieldRegex: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="InsightFinder Analysis Additonal Fields in ServiceNow">
          <Checkbox.Group
            options={this.additonalFieldsOptions}
            value={this.state.additionalFields}
            onChange={(additionalFields) => this.setState({ additionalFields })}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 12 }}>
          <Button type="primary" disabled={hasFormErr} loading={isSubmitting} onClick={this.submit}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  @autobind
  handleAddFilter() {
    const { additionalFilterList } = this.state;
    this.setState({
      additionalFilterList: [...additionalFilterList, ''],
    });
  }

  @autobind
  handleDeleteFilter(index) {
    const { additionalFilterList } = this.state;
    this.setState({
      additionalFilterList: R.remove(index, 1, additionalFilterList),
    });
  }

  @autobind
  handleCollectionAndTagChange(value, index) {
    const { additionalFilterList } = this.state;
    const tagsExp = additionalFilterList[index];
    additionalFilterList[index] = tagsExp ? `${tagsExp} AND ${value}=unknown` : `${value}=unknown`;
    this.setState({
      additionalFilterList,
    });
    this.forceUpdate();
  }

  @autobind
  handleCollectionInputChange(e, index) {
    const { additionalFilterList } = this.state;
    additionalFilterList[index] = e.target.value;
    this.setState({
      additionalFilterList,
    });
    this.forceUpdate();
  }

  @autobind
  handleDateChange(day, key) {
    this.setState({ [key]: day });
  }

  @autobind
  renderFormDataDog({ isSubmitting, hasFormErr }) {
    const { intl, currentProject } = this.props;
    const isMetric = get(currentProject, ['isMetric']);
    const { isVerifying, metrics, kpiMetrics, tagsExp, componentName, useHostName } = this.state;

    const tProps = {
      treeData: this.dataDogTreeData,
      value: metrics,
      onChange: this.handleDataDogMetricChange,
      treeCheckable: true,
      showCheckedStrategy: TreeSelect.SHOW_CHILD,
      placeholder: 'Please select metrics',
      style: {
        width: '100%',
      },
    };

    return (
      <Form layout="vertical" style={{ paddingBottom: 250 }}>
        <Form.Item
          label="API Key"
          required
          validateStatus={!this.state.apiKey ? 'error' : 'success'}
          help={!this.state.apiKey ? 'This input is required.' : undefined}
        >
          <Input.Password value={this.state.apiKey} onChange={(e) => this.setState({ apiKey: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Application Key"
          required
          validateStatus={!this.state.appKey ? 'error' : 'success'}
          help={!this.state.appKey ? 'This input is required.' : undefined}
        >
          <Input.Password value={this.state.appKey} onChange={(e) => this.setState({ appKey: e.target.value })} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 12 }}>
          <Button type="primary" disabled={hasFormErr} loading={isVerifying} onClick={this.dataDogVerify}>
            {intl.formatMessage(appButtonsMessages.verify)}
          </Button>
        </Form.Item>
        {isMetric && (
          <>
            <Form.Item
              label="Metrics"
              required
              validateStatus={!this.state.appKey ? 'error' : 'success'}
              help={!this.state.appKey ? 'This input is required.' : undefined}
            >
              <TreeSelect {...tProps} />
            </Form.Item>
            <Form.Item
              label="KPI Metrics"
              required
              validateStatus={!this.state.appKey ? 'error' : 'success'}
              help={!this.state.appKey ? 'This input is required.' : undefined}
            >
              <AntSelect
                mode="multiple"
                value={kpiMetrics}
                onChange={this.handleDataDogKPIMetricChange}
                style={{ width: '100%' }}
              >
                {R.map((metric) => {
                  return <AntSelect.Option key={metric}>{metric}</AntSelect.Option>;
                }, metrics || [])}
              </AntSelect>
            </Form.Item>
            <Form.Item label="Component name">
              <AntSelect
                showSearch
                mode="multiple"
                value={componentName}
                onChange={this.handleDataDogComponentChange}
                style={{ width: '100%' }}
              >
                {R.map(
                  (t) => (
                    <AntSelect.Option key={t} value={t}>
                      {t.value}
                    </AntSelect.Option>
                  ),
                  this.allDataDogComponentTags,
                )}
              </AntSelect>
            </Form.Item>
            <div className="flex-row" style={{ padding: '4px 0', alignItems: 'center' }}>
              <label>Instance filters</label>
              <AntSelect
                value="AND"
                showSearch
                dropdownMatchSelectWidth={280}
                onChange={this.handleDataDogAndTagChange}
                style={{ width: 86, padding: '0 8px' }}
              >
                {R.map(
                  (t) => (
                    <AntSelect.Option key={t} value={t}>
                      {t.value}
                    </AntSelect.Option>
                  ),
                  this.allDataDogTags,
                )}
              </AntSelect>
              <AntSelect
                showSearch
                value="OR"
                dropdownMatchSelectWidth={280}
                onChange={this.handleDataDogOrTagChange}
                style={{ width: 70 }}
              >
                {R.map(
                  (t) => (
                    <AntSelect.Option key={t} value={t}>
                      {t.value}
                    </AntSelect.Option>
                  ),
                  this.allDataDogTags,
                )}
              </AntSelect>
            </div>
            <div className="flex-row">
              <Input
                placeholder="(gcp AND zone=us)"
                value={tagsExp}
                onChange={(e) => this.setState({ tagsExp: e.target.value })}
              />
            </div>
            <Form.Item
              label="Instance Name"
              required
              validateStatus={!this.state.useHostName ? 'error' : 'success'}
              help={!this.state.useHostName ? 'This input is required.' : undefined}
              style={{ marginTop: 20 }}
            >
              <AntSelect
                showSearch
                value={useHostName}
                onChange={(useHostName) => this.setState({ useHostName })}
                style={{ width: 110 }}
                dropdownMatchSelectWidth={false}
              >
                <AntSelect.Option key="Host" value="Host">
                  Host
                </AntSelect.Option>
                {/* eslint-disable-next-line react/jsx-boolean-value */}
                <AntSelect.Option key="Host name" value="Host name">
                  Host name
                </AntSelect.Option>
              </AntSelect>
            </Form.Item>
          </>
        )}
        {!isMetric && (
          <>
            <Form.Item
              label="Instance Key"
              required
              validateStatus={!this.state.instanceKey ? 'error' : 'success'}
              help={!this.state.instanceKey ? 'This input is required.' : undefined}
            >
              <Input.Password
                value={this.state.instanceKey}
                onChange={(e) => this.setState({ instanceKey: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="Instance filter query">
              <Input
                value={this.state.additionalQuery}
                onChange={(e) => this.setState({ additionalQuery: e.target.value })}
              />
            </Form.Item>
          </>
        )}

        {!isMetric && (
          <Form.Item label="Component Name">
            <AntSelect
              showSearch
              onChange={this.handleDataDogComponentChange}
              style={{ width: '100%' }}
              mode="multiple"
              value={componentName}
            >
              {R.map(
                (t) => (
                  <AntSelect.Option key={t} value={t}>
                    {t.replace('tags=', '')}
                  </AntSelect.Option>
                ),
                this.state?.componentOptions || [],
              )}
            </AntSelect>
          </Form.Item>
        )}
        {!isMetric && (
          <Form.Item
            label="Fields to analyze"
            required
            validateStatus={this.state.fields && this.state.fields.length === 0 ? 'error' : 'success'}
            help={this.state.fields && this.state.fields.length === 0 ? 'This input is required.' : undefined}
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              value={this.state.fields}
              onChange={(fields) => this.setState({ fields })}
            >
              {R.map((field) => {
                return <Select.Option key={field}>{field}</Select.Option>;
              }, this.dataDogfields || [])}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Start time">
          <DatePicker onChange={(day) => this.handleDateChange(day, 'startDate')} />
        </Form.Item>
        <Form.Item label="End time">
          <DatePicker
            onChange={(day) => this.handleDateChange(day, 'endDate')}
            disabledDate={(current) => {
              return current && current > moment.utc().add(1, 'days').endOf('day');
            }}
          />
        </Form.Item>
        {!isMetric && (
          <Form.Item
            label={
              <div>
                Data collection filter
                <Button
                  size="small"
                  icon={<PlusCircleOutlined />}
                  onClick={this.handleAddFilter}
                  style={{ marginLeft: 10 }}
                >
                  Add filters
                </Button>
              </div>
            }
            required
            validateStatus={this.state.fields && this.state.fields.length === 0 ? 'error' : 'success'}
            help={this.state.fields && this.state.fields.length === 0 ? 'This input is required.' : undefined}
          >
            <div className="flex-col flex-grow">
              {R.addIndex(R.map)((item, index) => {
                return (
                  <div
                    key={`${index}filterRow`}
                    className="flex-row flex-center-justify flex-center-align"
                    style={{
                      border: '2px dashed var(--border-color-base)',
                      borderRadius: 16,
                      padding: 12,
                      margin: '6px 0',
                      position: 'relative',
                    }}
                  >
                    {index !== 0 && <span style={{ marginRight: 10 }}>OR</span>}
                    <Input value={item} onChange={(e) => this.handleCollectionInputChange(e, index)} />
                    <AntSelect
                      value="AND"
                      showSearch
                      dropdownMatchSelectWidth={280}
                      disabled={item.indexOf('unknown') >= 0}
                      onChange={(value) => this.handleCollectionAndTagChange(value, index)}
                      style={{ width: 86, padding: '0 8px' }}
                    >
                      {R.map(
                        (t) => (
                          <AntSelect.Option key={t} value={t}>
                            {t.value}
                          </AntSelect.Option>
                        ),
                        this.state.fields,
                      )}
                    </AntSelect>
                    <div
                      style={{ position: 'absolute', top: -15, right: -15, height: 30, width: 30, cursor: 'pointer' }}
                      className="flex-row flex-center-align flex-center-justify content-bg"
                      onClick={(e) => this.handleDeleteFilter(index)}
                    >
                      <MinusCircleOutlined style={{ fontSize: 18 }} />
                    </div>
                  </div>
                );
              }, this.state.additionalFilterList || [])}
            </div>
          </Form.Item>
        )}
        <Form.Item style={{ marginBottom: 12, marginTop: 8 }}>
          <Button type="primary" disabled={hasFormErr} loading={isSubmitting} onClick={this.submit}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  @autobind
  renderFormCloudWatch({ isSubmitting, hasFormErr }) {
    const { intl } = this.props;

    return (
      <Form layout="vertical" style={{ paddingBottom: 250 }}>
        <Form.Item
          label="Access Key"
          required
          validateStatus={!this.state.accessKey ? 'error' : 'success'}
          help={!this.state.accessKey ? 'This input is required.' : undefined}
        >
          <Input.Password value={this.state.accessKey} onChange={(e) => this.setState({ accessKey: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Secret Key"
          required
          validateStatus={!this.state.secretKey ? 'error' : 'success'}
          help={!this.state.secretKey ? 'This input is required.' : undefined}
        >
          <Input.Password value={this.state.secretKey} onChange={(e) => this.setState({ secretKey: e.target.value })} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 12 }}>
          <Button type="primary" disabled={hasFormErr} loading={isSubmitting} onClick={this.submit}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  @autobind
  handleNewRelicInstanceChange(hosts) {
    this.setState({ hosts, isLoadingNewRelicMetric: true }, async () => {
      await sleep(300);

      // build tree selections
      this.buildNewRelicTreeData(hosts);

      // reset metrics
      const { metrics } = this.state;
      const newSelectedMetrics = R.intersection(metrics, this.treeDataKeys);

      this.setState({ isLoadingNewRelicMetric: false, metrics: newSelectedMetrics });
    });
  }

  @autobind
  renderFormGoogleBigQuery({ isSubmitting, hasFormErr }) {
    const { intl } = this.props;
    const {
      type,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      authUri,
      tokenUri,
      authProviderCertUrl,
      clientCertUrl,
      queries,
    } = this.state;

    return (
      <Form layout="vertical" style={{ paddingBottom: 250 }}>
        <Form.Item
          label="Type"
          validateStatus={!type ? 'error' : 'success'}
          help={!type ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={type} onChange={(e) => this.setState({ type: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Project Id"
          validateStatus={!projectId ? 'error' : 'success'}
          help={!projectId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={projectId} onChange={(e) => this.setState({ projectId: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Private Key Id"
          validateStatus={!privateKeyId ? 'error' : 'success'}
          help={!privateKeyId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password
            autoComplete="new-password"
            value={privateKeyId}
            onChange={(e) => this.setState({ privateKeyId: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="Private Key"
          validateStatus={!privateKey ? 'error' : 'success'}
          help={!privateKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password
            autoComplete="new-password"
            value={privateKey}
            onChange={(e) => this.setState({ privateKey: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="Client Email"
          validateStatus={!clientEmail ? 'error' : 'success'}
          help={!clientEmail ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={clientEmail} onChange={(e) => this.setState({ clientEmail: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Client Id"
          validateStatus={!clientId ? 'error' : 'success'}
          help={!clientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password
            autoComplete="new-password"
            value={clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="Auth Uri"
          validateStatus={!authUri ? 'error' : 'success'}
          help={!authUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={authUri} onChange={(e) => this.setState({ authUri: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Token Uri"
          validateStatus={!tokenUri ? 'error' : 'success'}
          help={!tokenUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={tokenUri} onChange={(e) => this.setState({ tokenUri: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Auth Provider Cert Url"
          validateStatus={!authProviderCertUrl ? 'error' : 'success'}
          help={!authProviderCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={authProviderCertUrl} onChange={(e) => this.setState({ authProviderCertUrl: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Client Cert Url"
          validateStatus={!clientCertUrl ? 'error' : 'success'}
          help={!clientCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={clientCertUrl} onChange={(e) => this.setState({ clientCertUrl: e.target.value })} />
        </Form.Item>
        <Form.Item label="Filter Query List">
          <Button
            size="small"
            type="primary"
            style={{ marginBottom: 8 }}
            onClick={(e) => {
              const { queries } = this.state;
              this.setState({
                queries: [...queries, { query: '' }],
              });
            }}
          >
            <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          <div className="event-list">
            <div className="event-list-header" style={{ height: 30, width: '100%' }}>
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                Query
              </div>
              <div className="header-column" style={{ width: 110 }} />
            </div>
            <div className="event-list-grid" style={{ height: 120, overflowY: 'auto' }}>
              {R.addIndex(R.map)((item, index) => {
                return (
                  <div
                    key={index}
                    className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                    style={{ minHeight: 40 }}
                  >
                    <div className="row-column" style={{ width: 120, flex: 1 }}>
                      <Input
                        size="small"
                        value={item.query}
                        onChange={(e) => {
                          const { queries } = this.state;
                          this.setState({
                            queries: update(queries, {
                              [index]: { $set: { ...item, query: e.target.value } },
                            }),
                          });
                        }}
                      />
                    </div>
                    <div className="row-column flex-end-justify" style={{ width: 110 }}>
                      <Popconfirm
                        placement="topRight"
                        title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                        onConfirm={() => {
                          const { queries } = this.state;
                          this.setState({ queries: R.remove(index, 1, queries) });
                        }}
                        onCancel={(event) => event.stopPropagation()}
                      >
                        <Button size="small" className="button-color-grey" onClick={(event) => event.stopPropagation()}>
                          <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                );
              }, queries || [])}
            </div>
          </div>
        </Form.Item>
        <Form.Item style={{ marginBottom: 12 }}>
          <Button type="primary" disabled={hasFormErr} loading={isSubmitting} onClick={this.submit}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  @autobind
  renderFormGoogleCloudCost({ isSubmitting, hasFormErr }) {
    const { intl } = this.props;
    const {
      type,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      authUri,
      tokenUri,
      authProviderCertUrl,
      clientCertUrl,
      table,
      optionsTable,
      collectionFrequency,
      millisecondUnit,
    } = this.state;

    return (
      <Form layout="vertical" style={{ paddingBottom: 250 }}>
        <Form.Item
          label="Type"
          validateStatus={!type ? 'error' : 'success'}
          help={!type ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={type} onChange={(e) => this.setState({ type: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Project Id"
          validateStatus={!projectId ? 'error' : 'success'}
          help={!projectId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={projectId} onChange={(e) => this.setState({ projectId: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Private Key Id"
          validateStatus={!privateKeyId ? 'error' : 'success'}
          help={!privateKeyId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password
            autoComplete="new-password"
            value={privateKeyId}
            onChange={(e) => this.setState({ privateKeyId: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="Private Key"
          validateStatus={!privateKey ? 'error' : 'success'}
          help={!privateKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password
            autoComplete="new-password"
            value={privateKey}
            onChange={(e) => this.setState({ privateKey: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="Client Email"
          validateStatus={!clientEmail ? 'error' : 'success'}
          help={!clientEmail ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={clientEmail} onChange={(e) => this.setState({ clientEmail: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Client Id"
          validateStatus={!clientId ? 'error' : 'success'}
          help={!clientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password
            autoComplete="new-password"
            value={clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="Auth Uri"
          validateStatus={!authUri ? 'error' : 'success'}
          help={!authUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password value={authUri} onChange={(e) => this.setState({ authUri: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Token Uri"
          validateStatus={!tokenUri ? 'error' : 'success'}
          help={!tokenUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password value={tokenUri} onChange={(e) => this.setState({ tokenUri: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Auth Provider Cert Url"
          validateStatus={!authProviderCertUrl ? 'error' : 'success'}
          help={!authProviderCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={authProviderCertUrl} onChange={(e) => this.setState({ authProviderCertUrl: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Client Cert Url"
          validateStatus={!clientCertUrl ? 'error' : 'success'}
          help={!clientCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={clientCertUrl} onChange={(e) => this.setState({ clientCertUrl: e.target.value })} />
        </Form.Item>
        <Form.Item label="Table">
          {/* <Input value={table} onChange={(e) => this.setState({ table: e.target.value })} /> */}
          <AutoComplete
            options={optionsTable}
            allowClear
            value={table}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf((table || '').toUpperCase()) !== -1
            }
            onChange={(table) => this.setState({ table })}
            placeholder=""
          />
        </Form.Item>
        <Form.Item
          label="Collection Frequency"
          validateStatus={!collectionFrequency ? 'error' : 'success'}
          help={!collectionFrequency ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
        >
          <div className="flex-row flex-center-align">
            <Input
              style={{ width: 200 }}
              value={collectionFrequency}
              onChange={(e) => {
                const val = e.target.value;
                if (/^\d+$/.test(val) || R.isEmpty(val)) {
                  this.setState({ collectionFrequency: Number(val) });
                }
              }}
            />
            <Select
              style={{ width: 120, marginLeft: 4 }}
              options={this.millisecondUnitOption}
              value={millisecondUnit}
              onChange={(millisecondUnit) => this.setState({ millisecondUnit })}
            />
          </div>
        </Form.Item>
        <Form.Item style={{ marginBottom: 12 }}>
          <Button type="primary" disabled={hasFormErr} loading={isSubmitting} onClick={this.submit}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  @autobind
  renderFormGooglePubSub({ isSubmitting, hasFormErr }) {
    const { intl } = this.props;
    const {
      subscriptionId,
      type,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,
      authUri,
      tokenUri,
      authProviderCertUrl,
      clientCertUrl,
      instanceField,
      originalDataFields,
      dataFields,
    } = this.state;

    return (
      <Form layout="vertical" style={{ paddingBottom: 250 }}>
        <Form.Item
          label="Subscription Id"
          validateStatus={!subscriptionId ? 'error' : 'success'}
          help={!subscriptionId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={subscriptionId} onChange={(e) => this.setState({ subscriptionId: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Type"
          validateStatus={!type ? 'error' : 'success'}
          help={!type ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={type} onChange={(e) => this.setState({ type: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Project Id"
          validateStatus={!projectId ? 'error' : 'success'}
          help={!projectId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={projectId} onChange={(e) => this.setState({ projectId: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Private Key Id"
          validateStatus={!privateKeyId ? 'error' : 'success'}
          help={!privateKeyId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password
            autoComplete="new-password"
            value={privateKeyId}
            onChange={(e) => this.setState({ privateKeyId: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="Private Key"
          validateStatus={!privateKey ? 'error' : 'success'}
          help={!privateKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password
            autoComplete="new-password"
            value={privateKey}
            onChange={(e) => this.setState({ privateKey: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="Client Email"
          validateStatus={!clientEmail ? 'error' : 'success'}
          help={!clientEmail ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={clientEmail} onChange={(e) => this.setState({ clientEmail: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Client Id"
          validateStatus={!clientId ? 'error' : 'success'}
          help={!clientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password
            autoComplete="new-password"
            value={clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="Auth Uri"
          validateStatus={!authUri ? 'error' : 'success'}
          help={!authUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password value={authUri} onChange={(e) => this.setState({ authUri: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Token Uri"
          validateStatus={!tokenUri ? 'error' : 'success'}
          help={!tokenUri ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input.Password value={tokenUri} onChange={(e) => this.setState({ tokenUri: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Auth Provider Cert Url"
          validateStatus={!authProviderCertUrl ? 'error' : 'success'}
          help={!authProviderCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={authProviderCertUrl} onChange={(e) => this.setState({ authProviderCertUrl: e.target.value })} />
        </Form.Item>
        <Form.Item
          label="Client Cert Url"
          validateStatus={!clientCertUrl ? 'error' : 'success'}
          help={!clientCertUrl ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Input value={clientCertUrl} onChange={(e) => this.setState({ clientCertUrl: e.target.value })} />
        </Form.Item>

        <Form.Item label="Instance Field">
          <Select
            allowClear
            filterOption
            showSearch
            optionFilterProp="label"
            filterSort={({ label: a }, { label: b }) => a.localeCompare(b)}
            options={R.map((item) => ({ value: item, label: item }), originalDataFields || [])}
            value={instanceField}
            onChange={(instanceField) => this.setState({ instanceField })}
          />
        </Form.Item>
        <Form.Item label="Data Field">
          <Select
            mode="multiple"
            allowClear
            filterOption
            options={R.map((item) => ({ value: item, label: item }), originalDataFields || [])}
            value={dataFields}
            onChange={(dataFields) => this.setState({ dataFields })}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 12 }}>
          <Button type="primary" disabled={hasFormErr} loading={isSubmitting} onClick={this.submit}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  @autobind
  renderFormSnowflake({ isSubmitting, hasFormErr }) {
    const { intl } = this.props;
    const { sqlQuery } = this.state;
    return (
      <Form layout="vertical" style={{ paddingBottom: 250 }}>
        <Form.Item label="Query">
          <CodeMirror
            options={{ lineNumbers: true, mode: 'sql' }}
            value={sqlQuery}
            onBeforeChange={(editor, data, value) => this.setState({ sqlQuery: value })}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 12 }}>
          <Button type="primary" disabled={hasFormErr} loading={isSubmitting} onClick={this.submit}>
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { currentProject } = this.props;
    const cloudType = get(currentProject, ['cloudType']);
    const { isLoading, isSubmitting, hasError, errorMessage } = this.state;

    const hasFormErr = this.getFormErr();
    return (
      <Container fullHeight className={`overflow-y-auto ${isLoading ? ' loading' : ''}`}>
        <div className="full-height" style={{ width: 800 }}>
          {hasError && (
            <Alert message="Error" description={errorMessage} type="error" showIcon style={{ marginBottom: 16 }} />
          )}

          {cloudType === 'ServiceNow' && this.renderFormServiceNow({ isSubmitting, hasFormErr })}
          {cloudType === 'DataDog' && this.renderFormDataDog({ isSubmitting, hasFormErr })}
          {cloudType === 'CloudWatch' && this.renderFormCloudWatch({ isSubmitting, hasFormErr })}
          {cloudType === 'GoogleBigQuery' && this.renderFormGoogleBigQuery({ isSubmitting, hasFormErr })}
          {cloudType === 'GoogleCloudCost' && this.renderFormGoogleCloudCost({ isSubmitting, hasFormErr })}
          {cloudType === 'GooglePubsub' && this.renderFormGooglePubSub({ isSubmitting, hasFormErr })}
          {cloudType === 'SnowFlake' && this.renderFormSnowflake({ isSubmitting, hasFormErr })}
        </div>
      </Container>
    );
  }
}

const EndpointSetting = injectIntl(EndpointSettingCore);
export default connect((state) => {
  return {};
}, {})(EndpointSetting);
