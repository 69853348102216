import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isNumber, isObject } from 'lodash';
import { AutoComplete, Button, message, Select, Tabs } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { Modal } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';

import { eventMessages } from '../../../common/metric/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';

import { ComputingTimeNew, LogInputContentInfo, LogInputJsonInfo, MetricInputRuleInfo } from './UpdateGlobalRuleInfo';

const initDetail = {
  content: '',
  avgValue: 0,
  percentage: 0,
  metricDirection: 'higher',
  avgNormalValue: 0,
  avgScore: 0,
  count: 0,
  duration: 0,
  instanceName: '-1880685610',
  nid: 0,
  overAllAvgValue: 0,
  probability: 0,
  projectName: '-1880685610',
  realValue: 0,
  slope: 0,
  timeStamp: 0,
  type: 'Metric',
};
const initLogDetail = { content: '', type: 'Log' };
const bodyHeight = 700;

const hasNilAndEmpty = (value) => R.isNil(value.content) || R.isEmpty(value.content);
const isObjectReturnString = (value) => {
  return isObject(value) ? JSON.stringify(value) : value;
};

function UpdataGlobalKnowledgeBaseModal({
  incident,
  incidentData: incidentDataList,
  metricList,
  title,
  onClose,
  ...props
}: Object) {
  const { intl, currentTheme, credentials } = props;
  const { isKpiIncidents } = incident;

  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    ruleInfo: {},
    btnLoading: false,
    activeTab: 'KPIIncidents',

    incidentInfo: {},
    predictionSourceInfo: [],

    addIncidentType: 'Metric',
  });
  const { ruleInfo, btnLoading, activeTab, incidentInfo, predictionSourceInfo, addIncidentType } = state;
  const isMetricTab = activeTab === 'KPIIncidents';
  const addIsMetric = addIncidentType === 'Metric';

  useEffect(() => {
    const { isKpiIncidents, incidentRule, ruleType } = incident || {};
    const { delay, sourceDetailSet, rulePrimaryKey, patternName, incidentData } = incidentRule || {};
    const { rulePartitionKey, uniqueKey } = rulePrimaryKey || {};
    const { patternId, projectName, userName, instanceName } = rulePartitionKey || {};

    const newAddIncidentType = isKpiIncidents ? 'Metric' : 'Log';
    const newActiveTab = isKpiIncidents ? 'KPIIncidents' : 'LogIncidents';
    const newRuleInfo = { projectName, userName, instanceName, patternId, uniqueKey, ruleType };

    let incidentDataJson = null;
    try {
      incidentDataJson = !isNumber(JSON.parse(incidentData)) ? JSON.parse(incidentData) : undefined;
    } catch (error) {
      // console.debug(error)
    }
    const newIncidentInfo = { delay, patternName, incidentData: incidentDataJson || incidentData };

    let newPredictionSourceInfo = R.sortWith([R.descend(R.prop('matchedCount'))], sourceDetailSet || []);
    const noHasOldTimeStamp = R.find((item) => item.timeStamp !== 0, newPredictionSourceInfo || []);
    if (noHasOldTimeStamp) {
      newPredictionSourceInfo = R.sortWith([R.ascend(R.prop('timeStamp'))], sourceDetailSet);
    }
    newPredictionSourceInfo = R.map((item) => {
      let dataJson = null;
      try {
        dataJson = !isNumber(JSON.parse(item.content)) ? JSON.parse(item.content) : undefined;
      } catch (error) {
        // console.debug(error)
      }
      return { ...item, ...(dataJson ? { content: dataJson } : {}) };
    }, newPredictionSourceInfo);

    setState({
      activeTab: newActiveTab,
      ruleInfo: newRuleInfo,
      incidentInfo: newIncidentInfo,
      predictionSourceInfo: newPredictionSourceInfo,
      addIncidentType: newAddIncidentType,
    });
  }, [incident]);

  const updataGlobalRules = () => {
    const { delay, patternName, incidentData } = incidentInfo || {};
    const ruleIncident = {
      patternName,
      delay,
      ...(isMetricTab ? { incidentRootCause: incidentData } : { incidentData: isObjectReturnString(incidentData) }),
    };
    const srcDetails = R.map((item) => {
      const { type, content } = item || {};
      const isMetric = type === 'Metric';
      if (isMetric) {
        return item;
      } else {
        const dataIsJson = isObject(content);
        return {
          ...item,
          ...(dataIsJson
            ? { content: JSON.stringify(content), rawData: JSON.stringify(content) }
            : { content, rawData: content }),
        };
      }
    }, predictionSourceInfo || []);
    const lackSrcDetails = (srcDetails || []).length === 0;

    setState({ btnLoading: true });
    updateLastActionInfo();

    fetchPost(getEndpoint('globallevelrule'), {
      ...credentials,
      ruleIncident: JSON.stringify(ruleIncident),
      ...(lackSrcDetails ? {} : { srcDetails: JSON.stringify(srcDetails) }),
      predictionRuleInfo: JSON.stringify(ruleInfo),
      operation: 1, // 'update'
    })
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          message.success(msg);
          setState({ btnLoading: false });
          onClose(true);
        } else {
          message.error(msg);
          setState({ btnLoading: false });
        }
      })
      .catch((err) => {
        setState({ btnLoading: false });
        message.error(err.message || String(err));
      });
  };

  const handleAdd = () => {
    const addData = addIsMetric ? initDetail : initLogDetail;
    setState({ predictionSourceInfo: [addData, ...predictionSourceInfo] });
  };

  const handleDelete = (index) => {
    setState({ predictionSourceInfo: R.addIndex(R.filter)((item, idx) => idx !== index, predictionSourceInfo) });
  };

  const incidentsRender = () => {
    const incidentDataJson = isObject(incidentInfo.incidentData) ? incidentInfo.incidentData : null;
    const isMetricIncident = Boolean(
      R.has('metricName', incidentDataJson) && R.has('sign', incidentDataJson) && R.has('percentage', incidentDataJson),
    );

    let metricNameList = metricList[incidentInfo.patternName];
    metricNameList = R.map((item) => ({ label: item, value: item }), metricNameList || []);
    metricNameList = R.sortWith([R.ascend(R.prop('label'))], metricNameList);
    return (
      <>
        <div className="flex-row flex-center-align">
          <div className="light-label bold" style={{ minWidth: 172, flexShrink: 0 }}>
            {intl.formatMessage(appFieldsMessages.delay)}:
          </div>
          <ComputingTimeNew
            event={incidentInfo}
            handleChange={(delay) => setState({ incidentInfo: { ...incidentInfo, delay } })}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
          <div className="light-label bold" style={{ width: 164, flexShrink: 0 }}>
            {intl.formatMessage(eventMessages.incidentPatternName)} <span style={{ color: 'red' }}>*</span>:
          </div>
          <AutoComplete
            allowClear
            size="small"
            value={incidentInfo.patternName}
            options={incidentDataList}
            style={{ width: 500, marginLeft: 8 }}
            onChange={(patternName) => {
              setState({
                incidentInfo: {
                  ...incidentInfo,
                  patternName,
                  ...(isMetricTab ? { incidentData: { ...incidentInfo.incidentData, metricName: '' } } : {}),
                },
                predictionSourceInfo: R.map((item) => {
                  const isMetric = item.type === 'Metric';
                  if (isMetric) {
                    return { ...item, content: '' };
                  } else {
                    return item;
                  }
                }, predictionSourceInfo || []),
              });
            }}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
            }
            className={`${incidentInfo.patternName ? '' : 'jsonKeyNoneError'}`}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
          <div className="light-label bold" style={{ width: 164, flexShrink: 0 }}>
            {intl.formatMessage(eventMessages.incidentShortDescription)} <span style={{ color: 'red' }}>*</span>:
          </div>
          {isMetricTab && (
            <div style={{ marginLeft: 8 }}>
              <MetricInputRuleInfo
                key={incidentInfo.patternName}
                event={incidentInfo.incidentData}
                metricList={metricNameList}
                handleChange={(data) => {
                  setState({
                    incidentInfo: { ...incidentInfo, incidentData: { ...incidentInfo.incidentData, ...data } },
                  });
                }}
              />
            </div>
          )}
          {!isMetricTab && (
            <>
              {isMetricIncident && (
                <div style={{ marginLeft: 8 }}>
                  <MetricInputRuleInfo
                    key={incidentInfo.patternName}
                    event={incidentInfo.incidentData}
                    metricList={metricNameList}
                    handleChange={(data) => {
                      setState({
                        incidentInfo: { ...incidentInfo, incidentData: { ...incidentInfo.incidentData, ...data } },
                      });
                    }}
                  />
                </div>
              )}
              {!isMetricIncident && (
                <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', marginLeft: 8, width: 500 }}>
                  {!incidentDataJson && (
                    <LogInputContentInfo
                      event={incidentInfo}
                      handleChange={(data) => {
                        setState({ incidentInfo: { ...incidentInfo, ...data } });
                      }}
                    />
                  )}
                  {incidentDataJson && (
                    <div
                      className="corner-8"
                      style={{ border: '1px solid var(--border-color-base)', maxHeight: 200, overflowY: 'auto' }}
                    >
                      <LogInputJsonInfo
                        event={incidentInfo.incidentData}
                        currentTheme={currentTheme}
                        handleChange={(data) => {
                          setState({ incidentInfo: { ...incidentInfo, incidentData: data.content } });
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        <div className="flex-row flex-center-align" style={{ marginTop: 28 }}>
          <div className="text-right">{intl.formatMessage(eventMessages.rootCauseShortDescription)}:</div>
          <Select
            size="small"
            style={{ width: 100, marginLeft: 8 }}
            value={addIncidentType}
            onChange={(addIncidentType) => setState({ addIncidentType })}
            options={[
              { value: 'Metric', label: 'Metric' },
              { value: 'Log', label: 'Log' },
            ]}
          />
          <Button size="small" type="primary" style={{ marginLeft: 8 }} onClick={handleAdd}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>

        <div>
          {R.addIndex(R.map)((item, index) => {
            const { type } = item;
            const isMetric = type === 'Metric';
            const rawDataJson = isObject(item.content) ? item.content : null;
            if (isMetric) {
              return (
                <div
                  style={{ margin: '8px 0 0 0' }}
                  className="flex-row flex-center-align"
                  key={`${index}${incidentInfo.patternName}`}
                >
                  <div style={{ width: 615 }}>
                    <MetricInputRuleInfo
                      event={item}
                      metricList={metricNameList}
                      handleChange={(data) => {
                        setState({
                          predictionSourceInfo: update(predictionSourceInfo, {
                            [index]: { $set: { ...item, ...data } },
                          }),
                        });
                      }}
                    />
                  </div>
                  {(isMetricTab || predictionSourceInfo.length > 1) && (
                    <Button size="small" style={{ marginLeft: 34 }} onClick={() => handleDelete(index)}>
                      {intl.formatMessage(appButtonsMessages.delete)}
                    </Button>
                  )}
                </div>
              );
            } else {
              return (
                <div style={{ margin: '8px 0 0 0' }} className="flex-row flex-center-align" key={index}>
                  <div style={{ width: 615 }}>
                    {!rawDataJson ? (
                      <LogInputContentInfo
                        event={item}
                        handleChange={(data) => {
                          setState({
                            predictionSourceInfo: update(predictionSourceInfo, {
                              [index]: { $set: { ...item, ...data } },
                            }),
                          });
                        }}
                      />
                    ) : (
                      <div
                        className="corner-8"
                        style={{ border: '1px solid var(--border-color-base)', maxHeight: 200, overflowY: 'auto' }}
                      >
                        <LogInputJsonInfo
                          event={rawDataJson}
                          currentTheme={currentTheme}
                          handleChange={(data) => {
                            setState({
                              predictionSourceInfo: update(predictionSourceInfo, {
                                [index]: { $set: { ...item, ...data } },
                              }),
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {(isMetricTab || predictionSourceInfo.length > 1) && (
                    <Button size="small" style={{ marginLeft: 34 }} onClick={() => handleDelete(index)}>
                      {intl.formatMessage(appButtonsMessages.delete)}
                    </Button>
                  )}
                </div>
              );
            }
          }, predictionSourceInfo)}
        </div>
      </>
    );
  };

  const incidentPatternError = incidentInfo?.patternName;
  const incidentDataError = isMetricTab ? incidentInfo?.incidentData?.metricName : incidentInfo?.incidentData;
  const hasEmptyDetail = R.filter((item) => hasNilAndEmpty(item), predictionSourceInfo).length > 0;
  const hasError = !incidentPatternError || !incidentDataError || hasEmptyDetail;

  return (
    <Modal
      title={title}
      width={900}
      visible
      maskClosable={false}
      bodyStyle={{ maxHeight: bodyHeight, overflowY: 'auto' }}
      onCancel={() => onClose()}
      onOk={updataGlobalRules}
      okButtonProps={{ disabled: hasError, loading: btnLoading }}
      okText={intl.formatMessage(appButtonsMessages.submit)}
    >
      <Tabs
        type="card"
        className="full-height ant-tabs-content-full-height flex-col ant-tabs-content-y"
        activeKey={activeTab}
        style={{ maxHeight: bodyHeight - 48 }}
      >
        <Tabs.TabPane
          tab={intl.formatMessage(eventMessages.KPIIncidents)}
          key="KPIIncidents"
          style={{ padding: 16 }}
          disabled={!isKpiIncidents}
        >
          {incidentsRender()}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={intl.formatMessage(eventMessages.logIncidents)}
          key="LogIncidents"
          style={{ padding: 16 }}
          disabled={isKpiIncidents}
        >
          {incidentsRender()}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}

const UpdataGlobalKnowledgeBaseModalBase = injectIntl(UpdataGlobalKnowledgeBaseModal);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { currentTheme } = state.app;
    return { credentials, currentTheme };
  },
  { updateLastActionInfo },
)(UpdataGlobalKnowledgeBaseModalBase);
