/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import * as R from 'ramda';
import { createLogic } from 'redux-logic';

import { ActionTypes, createSetAction, updateLastActionInfo } from '../actions';
import { getLoadStatusActions } from '../../utils';
import { getInfoGlobalV2 } from '../../apis';

const loadInfoGlobalLogic = createLogic({
  type: [ActionTypes.LOAD_INFO_GLOBAL],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action, callback }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { systemsMap, projects, shareSystemArr, ownSystemArr, allProjects } = state.app;
    const { credentials, userInfo } = state.auth;
    const { userName } = state.auth.userInfo;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);
    const { needsGlobalInfo } = params;

    dispatch(showLoading);
    dispatch(createSetAction(ActionTypes.RESET_INFO_GLOBAL));
    dispatch(updateLastActionInfo());
    getInfoGlobalV2(credentials, {
      ...params,
      userName,
      systemsMap,
      projects,
      needsGlobalInfo,
      shareSystemArr,
      ownSystemArr,
      allProjects,
      userInfo,
    })
      .then((data) => {
        const { globalInfo, favorites } = data;

        // get system configs for all environment
        let allSystemConfigs = [];
        R.forEach((item) => {
          const { id: environmentId, systemList } = item;
          R.forEach((system) => {
            const { id, name, order, hideFlag, ownerUserName, systemInfo, hasData } = system;
            allSystemConfigs.push({
              environmentId,
              id,
              systemId: id,
              systemName: name,
              order,
              hideFlag,
              owner: ownerUserName,
              showInGV: systemInfo?.showInGV,
              hasData,
            });
          }, systemList);
        }, globalInfo);
        allSystemConfigs = R.sortWith([R.descend(R.prop('order'))], R.uniq(allSystemConfigs));

        dispatch(
          createSetAction(ActionTypes.SET_INFO_GLOBAL, params, {
            ...data,
            allSystemConfigs,
          }),
        );

        dispatch(
          createSetAction(ActionTypes.SET_SYSTEM_FAVORITES, params, {
            favorites,
          }),
        );
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed get global info, ignored', err);
        dispatch(createSetAction(ActionTypes.SET_INFO_GLOBAL, params, {}));
        dispatch(
          createSetAction(ActionTypes.SET_SYSTEM_FAVORITES, params, {
            favorites: [],
          }),
        );
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        } else {
          dispatch(hideLoading);
        }
        done();
      });
  },
});

export default loadInfoGlobalLogic;
