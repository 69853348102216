/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Select, DatePicker, Popover, Button, Spin, message } from 'antd';

import { Container } from '../../lib/fui/react';
import { parseLocation, Defaults } from '../../common/utils';
import { createLoadAction, hideAppLoader, loadProjectInfo } from '../../common/app/actions';

import EventsDetailsLineChart from './components/EventsDetailsLineChart';
import { eventMessages } from '../../common/metric/messages';
import { appButtonsMessages, appMessages } from '../../common/app/messages';
import { logMessages } from '../../common/log/messages';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import getInstanceDisplayName from '../../common/utils/getInstanceDisplayName';
import MetricLineChartToLogPage from './components/MetricLineChartToLogPage';

type Props = {
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  hideAppLoader: Function,
  // eslint-disable-next-line
  loadProjectInfo: Function,
  // eslint-disable-next-line
  appLoaderVisible: Boolean,
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
};

class EventsDetailsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location, projects } = props;
    const params = parseLocation(location);
    const { selectProject, selectInstance, selectStartTimestamp, selectEndTimestamp } = params;

    const currentProject = R.find((project) => project.projectName === selectProject, projects || []);
    const systemProjectList = currentProject
      ? currentProject.systemId
        ? R.filter((p) => p.systemId === currentProject.systemId && !p.isMetric, projects || [])
        : [currentProject]
      : [];

    this.state = {
      isLoading: false,
      refresh: null,

      selectProjectName: selectProject,
      selectInstanceName: selectInstance,
      selectStartTimestamp: selectStartTimestamp ? Number(selectStartTimestamp) : undefined,
      selectEndTimestamp: selectEndTimestamp ? Number(selectEndTimestamp) : undefined,
      startTimeObj: selectStartTimestamp ? moment.utc(Number(selectStartTimestamp)) : undefined,
      endTimeObj: selectEndTimestamp ? moment.utc(Number(selectEndTimestamp)) : undefined,
      instanceDisplayNameMap: {},
    };
    this.projectListOptions = R.map(
      (item) => ({
        value: item.projectName,
        label: `${item.projectDisplayName}${item.owner ? `@${item.owner}` : ''}${
          item.systemName ? ` (${item.systemName})` : ''
        }`,
      }),
      systemProjectList,
    );
    this.instanceListOptions = [];
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }

    // relaod project meta data
    const { projects } = this.props;
    const { selectProjectName } = this.state;
    const currentProject = R.find((project) => project.projectName === selectProjectName, projects || []);
    if (currentProject && !currentProject.hasAllInstanceInfo) {
      this.reloadInstance();
    } else {
      this.parseData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projects !== this.props.projects) {
      this.parseData(nextProps);
    }
  }

  @autobind
  reloadInstance() {
    const { loadProjectInfo } = this.props;
    const { selectProjectName, startTimeObj, endTimeObj } = this.state;

    if (selectProjectName && startTimeObj && endTimeObj) {
      this.setState({ isLoading: true });
      loadProjectInfo(
        {
          projectName: selectProjectName,
          includeInstance: true,
          startTimestamp: startTimeObj.valueOf(),
          endTimestamp: endTimeObj.valueOf(),
        },
        false,
      );
    }
  }

  @autobind
  async parseData(props) {
    const { projects, credentials } = props;
    const { selectProjectName, selectInstanceName } = this.state;

    // reset instance
    const currentProject = R.find((project) => project.projectName === selectProjectName, projects || []);

    const instanceDisplayNameMap = {};
    await fetchGet(getEndpoint('instance-display-name'), {
      ...credentials,
      instanceDisplayNameRequestList: JSON.stringify([
        { projectName: currentProject?.projectShortName, customerName: currentProject?.owner },
      ]),
    })
      .then((d1) => {
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, d1 || []);
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });

    const instanceList = currentProject ? currentProject.instanceList || [] : [];
    this.instanceListOptions = R.map((i) => {
      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, i, {
        pn: currentProject?.projectShortName,
        owner: currentProject?.owner,
      });
      return { value: i, label: instanceStr };
    }, instanceList || []);

    let newInstance = selectInstanceName;
    if (!selectInstanceName || !instanceList.includes(selectInstanceName)) {
      newInstance = instanceList[0];
    }
    this.setState({ isLoading: false, selectInstanceName: newInstance, instanceDisplayNameMap });
  }

  @autobind
  onChangeProject(selectProjectName) {
    this.setState({ selectProjectName, selectInstanceName: null }, () => {
      const { projects } = this.props;
      const currentProject = R.find((project) => project.projectName === selectProjectName, projects || []);
      if (currentProject && !currentProject.hasAllInstanceInfo) {
        this.reloadInstance();
      } else {
        this.parseData(this.props);
      }
    });
  }

  @autobind
  onChangeInstance(selectInstanceName) {
    this.setState({ selectInstanceName });
  }

  @autobind
  clickAbnormalTip({ timestamp, annos, project }) {
    const startTimestamp = timestamp;
    let metricDuration = 0;
    const samplingInterval = (project?.samplingIntervalInSecond || 0) * 1000;
    R.forEach((item) => {
      let itemDuration = 0;
      R.forEach((timeRange) => {
        const duration = timeRange.duration || timeRange.endTimestamp - timeRange.startTimestamp || 0;
        itemDuration += duration;
      }, get(item, ['timeRangeList'], []));
      metricDuration = R.max(R.max(metricDuration, itemDuration), 0);
    }, annos);
    metricDuration = metricDuration < samplingInterval ? samplingInterval : metricDuration + samplingInterval;
    const endTimestamp = startTimestamp - 600000;
    this.setState({
      startTimeObj: moment.utc(endTimestamp),
      endTimeObj: moment.utc(startTimestamp),
      selectStartTimestamp: endTimestamp,
      selectEndTimestamp: startTimestamp,
    });
  }

  render() {
    const { intl, location, projects } = this.props;
    const {
      isLoading,
      refresh,
      selectProjectName,
      selectInstanceName,
      selectStartTimestamp,
      selectEndTimestamp,
      startTimeObj,
      endTimeObj,
      instanceDisplayNameMap,
    } = this.state;
    const params = parseLocation(location);
    const { projectName, startTimestamp, endTimestamp, patternId } = params;
    const project = R.find(
      (p) => p.projectName === projectName,
      R.filter((p) => !p.isLog, projects),
    );

    const timeChange =
      startTimeObj &&
      endTimeObj &&
      (startTimeObj.valueOf() !== selectStartTimestamp || endTimeObj.valueOf() !== selectEndTimestamp);
    const hasError = !selectProjectName || !selectInstanceName || !startTimeObj || !endTimeObj;
    return (
      <Container fullHeight withGutter className="flex-col">
        <Container breadcrumb className="flex-row">
          <div className="flex-grow flex-row flex-center-align">
            {intl.formatMessage(eventMessages.patternId)}: {patternId}
          </div>
          <div className="flex-grow flex-row flex-center-align" style={{ justifyContent: 'center' }}>
            {`${moment.utc(Number(startTimestamp)).format(Defaults.ShortTimeFormat)} ~ ${moment
              .utc(Number(endTimestamp))
              .format(Defaults.ShortTimeFormat)}`}
          </div>
          <div className="flex-grow flex-row flex-center-align" />
        </Container>

        <Container className="flex-grow flex-min-height flex-col" style={{ margin: '0 16px 8px 16px' }}>
          <div
            className="flex-row"
            style={{
              marginBottom: 16,
              padding: '8px 8px 0 8px',
              background: 'var(--card-background)',
              borderRadius: 4,
            }}
          >
            <EventsDetailsLineChart project={project} clickAbnormalTip={this.clickAbnormalTip} />
          </div>

          <div
            className="flex-grow flex-min-height flex-col"
            style={{ padding: 8, background: 'var(--card-background)', borderRadius: 4 }}
          >
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <div style={{ fontWeight: 'bold', marginRight: 8 }}>{intl.formatMessage(eventMessages.projectName)}:</div>
              <Select
                showSearch
                placeholder={intl.formatMessage(eventMessages.projectName)}
                style={{ width: 200 }}
                options={this.projectListOptions}
                value={selectProjectName}
                onChange={(selectProjectName) => this.onChangeProject(selectProjectName)}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              />
              <div style={{ fontWeight: 'bold', marginLeft: 16, marginRight: 8 }}>
                {intl.formatMessage(eventMessages.instanceName)}:
              </div>
              <Spin spinning={isLoading}>
                <Select
                  showSearch
                  placeholder={intl.formatMessage(eventMessages.instanceName)}
                  style={{ width: 200 }}
                  options={this.instanceListOptions}
                  value={selectInstanceName}
                  onChange={(selectInstanceName) => this.onChangeInstance(selectInstanceName)}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                />
              </Spin>

              <div style={{ fontWeight: 'bold', marginLeft: 16, marginRight: 8 }}>
                {intl.formatMessage(logMessages.timeRange)}:
              </div>
              <DatePicker
                allowClear={false}
                showTime
                value={startTimeObj}
                onSelect={(timeObj) => this.setState({ startTimeObj: timeObj })}
                popupClassName="antd-timepicker"
              />
              <div style={{ margin: '0 4px' }}>~</div>
              <DatePicker
                allowClear={false}
                showTime
                value={endTimeObj}
                onSelect={(timeObj) => this.setState({ endTimeObj: timeObj })}
                popupClassName="antd-timepicker"
              />
              <Popover
                mouseEnterDelay={0.3}
                visible={timeChange}
                title={null}
                content={timeChange ? intl.formatMessage(appMessages.clickToReload) : null}
              >
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                  disabled={hasError}
                  onClick={() => {
                    this.setState({
                      refresh: moment.utc().valueOf(),
                      selectStartTimestamp: startTimeObj.valueOf(),
                      selectEndTimestamp: endTimeObj.valueOf(),
                    });
                  }}
                >
                  {intl.formatMessage(appButtonsMessages.reload)}
                </Button>
              </Popover>
            </div>
            <div className="flex-grow flex-min-height">
              <MetricLineChartToLogPage
                refresh={refresh}
                project={project}
                selectProjectName={selectProjectName}
                selectInstanceName={selectInstanceName}
                selectStartTimestamp={selectStartTimestamp}
                selectEndTimestamp={selectEndTimestamp}
                instanceDisplayNameMap={instanceDisplayNameMap}
              />
            </div>
          </div>
        </Container>
      </Container>
    );
  }
}

const EventsDetails = injectIntl(EventsDetailsCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { loadStatus, projects, appLoaderVisible } = state.app;

    return { location, loadStatus, projects, appLoaderVisible, credentials };
  },
  { push, replace, createLoadAction, hideAppLoader, loadProjectInfo },
)(EventsDetails);
