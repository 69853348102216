import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { Button, Input, message, Spin } from 'antd';

import { Modal } from '../../src/lib/fui/react';
import fetchPost from '../../src/common/apis/fetchPost';
import getEndpoint from '../../src/common/apis/getEndpoint';

import { eventActionMessages, eventMessages } from '../../src/common/metric/messages';
import { appFieldsMessages } from '../../src/common/app/messages';

export default function ConfigPatterName({ onClose, intl, credentials, instanceGroup, activeMerges = [] }: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    btnLoading: false,
    patternName: '',
  });
  const { btnLoading, patternName } = state;

  useEffect(() => {
    if (activeMerges.length === 0) return;
    setState({ patternName: activeMerges[0]?.patternName || '' });
  }, [activeMerges]);

  const handlePatternNameClick = () => {
    setState({ btnLoading: true });
    const eventList = R.map((event) => {
      const fullProjectName = `${event.projectName}@${event.projectOwner}`;
      return {
        ...event,
        fullProjectName,
        instanceName: event.anomalyLogInstance || event.instanceName,
        isLog: event.category === 'log',
        timestamp: event.startTimestamp,
        predictFlag: event.isPrediction,
      };
    }, activeMerges || []);

    const request = [];
    R.forEach((incident) => {
      const projectName = get(incident, ['fullProjectName']);
      const eventType = get(incident, ['type']);
      const timestamp = get(incident, ['key', 'timestamp']) || get(incident, ['timestamp']);
      const instanceName = get(incident, ['key', 'instanceName']) || get(incident, ['instanceName']);
      const logProjectParams = { instanceName, instanceGroup };
      const { rootCauseJson, predictFlag } = incident;
      const nid = incident.nid || incident.neuronId || incident.patternId;
      const anomalyRatio = incident.anomalyRatio || incident.anomalyScore;
      request.push(
        fetchPost(getEndpoint('events'), {
          ...credentials,
          projectName,
          ...logProjectParams,
          nid,
          patternName,
          timestamp,
          eventType,
          eventData: JSON.stringify([
            { rootCauseJson: JSON.stringify(rootCauseJson), neuronId: nid, anomalyRatio, predictFlag },
          ]),
          operation: 'setPatternName',
        }),
      );
    }, eventList || []);

    Promise.all(request)
      .then((data) => {
        setState({ btnLoading: false });
        onClose(true);
      })
      .catch((err) => {
        setState({ btnLoading: false });
        message.error(err.message || String(err));
      });
  };

  return (
    <Modal
      width={700}
      title={intl.formatMessage(eventMessages.configPatternName)}
      visible
      footer={null}
      onCancel={() => onClose()}
      maskClosable={false}
    >
      <Spin spinning={false}>
        <h5 style={{ display: 'inline-block', width: 80 }}>{`${intl.formatMessage(
          appFieldsMessages.patternName,
        )}:`}</h5>
        <Input
          value={patternName}
          allowClear
          onChange={(e) => setState({ patternName: e.target.value })}
          style={{ width: 340 }}
        />
        <Button
          type="primary"
          size="small"
          style={{ float: 'right' }}
          loading={btnLoading}
          disabled={!patternName}
          onClick={handlePatternNameClick}
        >
          {intl.formatMessage(eventActionMessages.setPatternName)}
        </Button>
      </Spin>
    </Modal>
  );
}
