/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import { autobind } from 'core-decorators';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import VLink from 'valuelink';
import { CaretDownOutlined, CaretUpOutlined, DownOutlined } from '@ant-design/icons';
import { Menu, Button } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { createLoadAction } from '../../../common/app/actions';
import { Modal } from '../../../../artui/react';
import {
  Defaults,
  parseLocation,
  getLoadStatus,
  CausalParser,
  CausalRenderers,
  buildUrl,
  CellRenderers,
} from '../../../common/utils';
import { State } from '../../../common/types';
import {
  Input,
  Select,
  Table,
  Column,
  CellMeasurer,
  CellMeasurerCache,
  Box,
  Container,
  SortDirection,
  Dropdown,
} from '../../../lib/fui/react';
import { loadEventMetricMap, ActionTypes } from '../../../common/causal/actions';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import { appFieldsMessages, appMenusMessages, appButtonsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';
import { logMessages } from '../../../common/log/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,
  onClose: Function,
  createLoadAction: Function,
  currentLoadingComponents: Object,
  loadEventMetricMap: Function,

  eventRelationList: Array<Object>,
  relationTimeThreshold: String,
  operation: String,
  edgeRelation: Object,
  isAdmin: Boolean,

  relationProbability: String,
  isCorrelation: Boolean,
  isIntra: Boolean,
  incidentParams: Object,
  projectName: String,
  instancePropertyMap: Object,
  intraInstanceName: String,
  relationType: String,
  srcInstance: String,
  leftLabel: String,
  rightLabel: String,
  targetInstance: String,
  instanceMapping: Object,
  filterModality: String,

  causalLogEvents: Object,
  currentTheme: String,
};

class EventRelationModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'causal_relation_modal_loader';
    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 100 });
    this.contentWidth = 220;

    const { intl, filterModality } = props;

    this.modalityFilterOptions = [
      { label: 'All', value: 'all' },
      { label: intl.formatMessage(causalMessages.metricToMetric), value: 'metric-metric' },
      { label: intl.formatMessage(causalMessages.logToLog), value: 'log-log' },
      { label: intl.formatMessage(causalMessages.metricToLog), value: 'log-metric' },
      { label: intl.formatMessage(causalMessages.metricToIncident), value: 'incident-metric' },
      { label: intl.formatMessage(causalMessages.logToIncident), value: 'incident-log' },
    ];
    this.state = {
      filterModality: filterModality || 'all',
      keywordFilter: '',
      keyword: '',
      showContextModal: false,
      contextTime: 1 * 60 * 1000,
      sortBy: null,
      sortDirection: null,
    };
    this.eventRelationList = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdate();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isIntra !== this.props.isIntra ||
      nextProps.edgeRelation !== this.props.edgeRelation ||
      nextProps.causalLogEvents !== this.props.causalLogEvents
    ) {
      this.preprocessData(nextProps, this.state);
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdate();
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection, filterModality, keyword } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;

      if (sortBy) {
        this.eventRelationList = R.sortWith([R.ascend(R.prop(sortBy))])(this.eventRelationList);
        if (sortDirection === SortDirection.DESC) {
          this.eventRelationList = R.sortWith([R.descend(R.prop(sortBy))])(this.eventRelationList);
        }
      }
    } else if (nextState.filterModality !== filterModality || nextState.keyword !== keyword) {
      this.preprocessData(nextProps, nextState);
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdate();
      }
    }
  }

  @autobind
  reloadData(props) {
    const { createLoadAction, instancePropertyMap, intraInstanceName, isIntra, incidentParams, leftLabel, rightLabel } =
      props;

    const customerName = get(incidentParams, 'customerName');
    const edgeRelation = props.edgeRelation || {};
    const contentTimeDifference = edgeRelation.contentTimeDifference || [];
    const fromContents = edgeRelation.fromContents || [];
    const toContents = edgeRelation.toContents || [];
    const srcInstanceMap = get(instancePropertyMap, leftLabel, {});
    const targetInstanceMap = get(instancePropertyMap, rightLabel, {});
    const intraInstanceMap = get(instancePropertyMap, intraInstanceName, {});

    let logEventQueryStr = [];
    R.forEach((diff) => {
      const fromTimestampList = R.map((itemStr) => Number(R.split(',', itemStr)[0]) * 1000, diff.timePair || []);
      const toTimestampList = R.map((itemStr) => Number(R.split(',', itemStr)[1]) * 1000, diff.timePair || []);
      const fromContent = fromContents[diff.sP] || fromContents[diff.srcPosition];
      const toContent = toContents[diff.tP] || toContents[diff.targetPosition];
      const { nid: snid, type: stype } = fromContent;
      const { nid: tnid, type: ttype } = toContent;
      const { typeOnly: stypeOnly } = CausalParser.getRelationLogType(stype);
      const { typeOnly: ttypeOnly } = CausalParser.getRelationLogType(ttype);

      let logProjectName = isIntra ? intraInstanceMap.logProjectName : srcInstanceMap.logProjectName;
      let logInstanceName = isIntra ? intraInstanceMap.logInstanceName : srcInstanceMap.logInstanceName;
      if (fromContent && stype !== 'Metric') {
        R.forEach((timestamp) => {
          logEventQueryStr.push({
            id: `${logProjectName}-${logInstanceName}-${snid}-${stypeOnly}-${timestamp}`,
            logProjectName,
            logInstanceName,
            nid: String(snid),
            timestamp,
            type: stype,
          });
        }, fromTimestampList);
      }
      logProjectName = isIntra ? intraInstanceMap.logProjectName : targetInstanceMap.logProjectName;
      logInstanceName = isIntra ? intraInstanceMap.logInstanceName : targetInstanceMap.logInstanceName;
      if (toContent && ttype !== 'Metric') {
        R.forEach((timestamp) => {
          logEventQueryStr.push({
            id: `${logProjectName}-${logInstanceName}-${tnid}-${ttypeOnly}-${timestamp}`,
            logProjectName,
            logInstanceName,
            nid: String(tnid),
            timestamp,
            type: ttype,
          });
        }, toTimestampList);
      }
    }, contentTimeDifference);
    logEventQueryStr = R.uniqWith(R.eqBy(R.prop('id')), logEventQueryStr);

    createLoadAction(
      ActionTypes.LOAD_CAUSAL_LOG_EVENTS,
      {
        customerName,
        logEventQueryStr: JSON.stringify(logEventQueryStr),
      },
      this.dataLoader,
    );
  }

  @autobind
  handleLineChartClick(metric) {
    const { location, leftLabel, rightLabel, instancePropertyMap, isAdmin, isIntra, intraInstanceName } = this.props;
    const instanceProperty = isIntra
      ? get(instancePropertyMap, [intraInstanceName], {})
      : get(instancePropertyMap, [rightLabel], {});
    const { metricProjectName } = instanceProperty;
    let projectName = metricProjectName;
    const userName = get(instanceProperty, ['projectInstanceMetadata', 'key', 'userName']);
    if (isAdmin && projectName && projectName.indexOf('@') < 0) {
      projectName = `${projectName}@${userName}`;
    }
    const params = parseLocation(location);
    const { startTimestamp, endTimestamp } = params;
    const instanceGroup = 'All';
    const startTs = moment.utc(Number(startTimestamp)).startOf('day').valueOf();
    const endTs = moment.utc(Number(endTimestamp)).endOf('day').valueOf();
    console.log(projectName);
    const query = {
      projectName,
      instanceGroup,
      modelType: 'Holistic',
      startTimestamp: startTs,
      endTimestamp: endTs,
      predictedFlag: false,
      justSelectMetric: metric,
      justInstanceList: isIntra ? intraInstanceName : R.join(',', [leftLabel, rightLabel]),
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  preprocessData(props, state) {
    const { filterModality, keyword } = state;
    const { isIntra, intraInstanceName, instancePropertyMap, leftLabel, rightLabel, relationProbability } = props;
    const causalLogEvents = props.causalLogEvents || {};
    const edgeRelation = props.edgeRelation || {};
    const contentTimeDifference = edgeRelation.contentTimeDifference || [];
    const fromContents = edgeRelation.fromContents || [];
    const toContents = edgeRelation.toContents || [];

    const srcInstanceLog = get(instancePropertyMap, [leftLabel, 'logInstanceName']);
    const targetInstanceLog = get(instancePropertyMap, [rightLabel, 'logInstanceName']);
    const intraInstanceLog = get(instancePropertyMap, [intraInstanceName, 'logInstanceName']);
    const srcLogEvents = srcInstanceLog
      ? get(causalLogEvents, srcInstanceLog, [])
      : get(causalLogEvents, leftLabel, []);
    const targetLogEvents = targetInstanceLog
      ? get(causalLogEvents, targetInstanceLog, [])
      : get(causalLogEvents, rightLabel, []);
    const intraLogEvents = intraInstanceLog
      ? get(causalLogEvents, intraInstanceLog, [])
      : get(causalLogEvents, intraInstanceName, []);

    let eventRelationList = [];
    R.forEach((diff) => {
      const fromTimestampList = R.map((itemStr) => Number(R.split(',', itemStr)[0]) * 1000, diff.timePair || []);
      const toTimestampList = R.map((itemStr) => Number(R.split(',', itemStr)[1]) * 1000, diff.timePair || []);
      const f = R.clone(fromContents[diff.sP] || fromContents[diff.srcPosition]);
      const t = R.clone(toContents[diff.tP] || toContents[diff.targetPosition]);
      const fLogs = R.filter(
        (s) =>
          s.patternId === String(f.nid) &&
          f.type.indexOf(s.logType) >= 0 &&
          fromTimestampList.indexOf(s.timestamp) !== -1,
        isIntra ? intraLogEvents : srcLogEvents,
      );
      if (fLogs.length > 0) {
        f.logContentList = R.map((item) => [item.timestamp, item.logContent || ''], fLogs);
      }
      const tLogs = R.filter(
        (s) =>
          s.patternId === String(t.nid) &&
          t.type.indexOf(s.logType) >= 0 &&
          toTimestampList.indexOf(s.timestamp) !== -1,
        isIntra ? intraLogEvents : targetLogEvents,
      );
      if (tLogs.length > 0) {
        t.logContentList = R.map((item) => [item.timestamp, item.logContent || ''], tLogs);
      }

      let timePair = diff.timePair || [];
      timePair = R.map((item) => item.split(','), timePair);
      timePair = R.map((item) => [Number(item[0]) * 1000, Number(item[1]) * 1000], timePair);
      const delay =
        timePair.length > 0
          ? R.reduce(
              R.max,
              0,
              R.map((item) => Number(item[1]) - Number(item[0]), timePair),
            )
          : diff.delay || 0;
      const count = timePair.length;
      eventRelationList.push({
        fromContents: f,
        toContents: t,
        delay,
        count,
        probability: diff.probability,
        modality: diff.modality,
        timestamp: f.timeStamp,
        timePair,
      });
    }, contentTimeDifference);

    if (filterModality && filterModality !== 'all') {
      eventRelationList = R.filter((relation) => relation.modality === filterModality, eventRelationList);
    }
    if (keyword) {
      eventRelationList = R.filter((relation) => {
        const { type: type1, content: content1, logContentList: logContentList1 } = get(relation, 'fromContents', {});
        const { type: type2, content: content2, logContentList: logContentList2 } = get(relation, 'toContents', {});
        let result = false;
        if (
          type1 !== 'Metric' &&
          R.toLower(
            logContentList1 && logContentList1.length > 0
              ? R.join(
                  '\n',
                  R.map((item) => item[1], logContentList1),
                )
              : content1 || '',
          ).indexOf(R.toLower(keyword)) >= 0
        ) {
          result = true;
        }
        if (
          !result &&
          type2 !== 'Metric' &&
          R.toLower(
            logContentList2 && logContentList2.length > 0
              ? R.join(
                  '\n',
                  R.map((item) => item[1], logContentList2),
                )
              : content2 || '',
          ).indexOf(R.toLower(keyword)) >= 0
        ) {
          result = true;
        }
        return result;
      }, eventRelationList);
    }

    if (relationProbability) {
      eventRelationList = R.filter(
        (relation) => relation.probability >= parseFloat(relationProbability),
        eventRelationList,
      );
    }

    this.eventRelationList = eventRelationList;
  }

  @autobind
  eventContentAutoHeightRenderer(props) {
    const { intl, currentTheme } = this.props;
    const { dataKey, parent, rowIndex } = props;
    const { keyword } = this.state;
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        {CausalRenderers.EventContentRenderer({ intl, props, options: { keyword }, currentTheme })}
      </CellMeasurer>
    );
  }

  @autobind
  eventContentScrollingRenderer(props) {
    const { intl, currentTheme } = this.props;
    const { keyword } = this.state;
    return (
      <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        {CausalRenderers.EventContentRenderer({ intl, props, options: { keyword, currentTheme } })}
      </div>
    );
  }

  @autobind
  handleLogContextClick(rowData, flag) {
    const { isIntra, intraInstanceName, instancePropertyMap, leftLabel, rightLabel, isAdmin } = this.props;
    const label = isIntra ? intraInstanceName : flag === 'from' ? leftLabel : rightLabel;
    const incident = flag === 'from' ? get(rowData, ['fromContents']) : get(rowData, ['toContents']);
    const { timeStamp, content: rawData, logContentList } = incident;
    const instanceProperty = get(instancePropertyMap, [label], {});
    const { logProjectName, logInstanceName } = instanceProperty;
    if (logProjectName && logInstanceName) {
      const userName = get(instanceProperty, ['projectInstanceMetadata', 'key', 'userName']);
      let projectName = logProjectName.indexOf('@') >= 0 ? logProjectName : `${logProjectName}@${userName}`;
      if (!isAdmin && projectName && projectName.indexOf('@') >= 0) {
        projectName = projectName.split('@')[0] || projectName;
      }
      this.setState({
        activeIncident: {
          ...incident,
          rawData,
          rawDataList: R.map((item) => item[1], logContentList),
          timestamp: timeStamp,
          projectName,
          user: userName,
          instanceName: logInstanceName,
        },
        showContextModal: true,
        contextTime: 1 * 60 * 1000,
      });
    }
  }

  @autobind
  handleLogDetailsClick(rowData, flag, instanceProperty) {
    const { isAdmin } = this.props;
    const incident = flag === 'from' ? get(rowData, ['fromContents']) : get(rowData, ['toContents']);
    const { timeStamp, nid, type } = incident;
    const { logProjectName, logInstanceName } = instanceProperty;
    if (logProjectName && logInstanceName) {
      const userName = get(instanceProperty, ['projectInstanceMetadata', 'key', 'userName']);
      let projectName = logProjectName.indexOf('@') >= 0 ? logProjectName : `${logProjectName}@${userName}`;
      if (!isAdmin && projectName && projectName.indexOf('@') >= 0) {
        projectName = projectName.split('@')[0] || projectName;
      }

      const day = moment.utc(timeStamp).format(Defaults.DateFormat);
      const query = {
        projectName,
        instanceName: logInstanceName,

        startTime: day,
        endTime: day,
        activeTab: 'clusters',
        activePatternId: nid,
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleLogEventsClick(rowData, flag, instanceProperty) {
    const { isAdmin } = this.props;
    const incident = flag === 'from' ? get(rowData, ['fromContents']) : get(rowData, ['toContents']);
    const { timeStamp } = incident;
    let projectName = get(instanceProperty, ['projectInstanceMetadata', 'key', 'projectName']);
    if (projectName) {
      const instanceName = get(instanceProperty, ['projectInstanceMetadata', 'key', 'id']);
      const userName = get(instanceProperty, ['projectInstanceMetadata', 'key', 'userName']);
      projectName = projectName.indexOf('@') >= 0 ? projectName : `${projectName}@${userName}`;
      if (!isAdmin && projectName && projectName.indexOf('@') >= 0) {
        projectName = projectName.split('@')[0] || projectName;
      }

      const query = {
        projectName,
        instanceName,
        startTime: moment.utc(timeStamp).format(Defaults.DateFormat),
        endTime: moment.utc(timeStamp).format(Defaults.DateFormat),
      };
      window.open(buildUrl(BaseUrls.MetricEvents, {}, query), '_blank');
    }
  }

  @autobind
  detailsRenderer(flag) {
    return ({ rowData }) => {
      const { intl, isIntra, intraInstanceName, instancePropertyMap, leftLabel, rightLabel } = this.props;
      const label = isIntra ? intraInstanceName : flag === 'from' ? leftLabel : rightLabel;
      const instanceProperty = get(instancePropertyMap, [label], {});
      const { logProjectName, logInstanceName } = instanceProperty;
      const incident = flag === 'from' ? get(rowData, ['fromContents']) : get(rowData, ['toContents']);
      const { type, metricDirection } = incident;
      const isMetric = type === 'Metric';
      const isMetricIncident = type === 'Incident' && metricDirection;

      const isIncidentTypeProject = type === 'Incident' && !logProjectName && !logInstanceName;
      const handleMenuClick = ({ key }) => {
        if (key === 'context') {
          this.handleLogContextClick(rowData, flag);
        } else if (key === 'details') {
          if (isIncidentTypeProject) {
            this.handleLogEventsClick(rowData, flag, instanceProperty);
          } else {
            this.handleLogDetailsClick(rowData, flag, instanceProperty);
          }
        } else if (key === 'lineChart') {
          let metric = rowData.toContents.content;
          if (flag === 'from') metric = rowData.fromContents.content;
          this.handleLineChartClick(metric);
        }
      };
      return (
        <Dropdown name={intl.formatMessage(logMessages.investigate)} itemClick={handleMenuClick}>
          <>
            {!isMetric && !isMetricIncident && !isIncidentTypeProject && (
              <Menu.Item key="context">{intl.formatMessage(eventMessages.context)}</Menu.Item>
            )}
            {!isMetric && !isMetricIncident && (
              <Menu.Item key="details">{intl.formatMessage(eventMessages.details)}</Menu.Item>
            )}
            {(isMetric || isMetricIncident) && (
              <Menu.Item key="lineChart">{intl.formatMessage(eventMessages.lineChart)}</Menu.Item>
            )}
          </>
        </Dropdown>
      );
    };
  }

  @autobind
  iconSearchClick(e) {
    e.stopPropagation();
    e.preventDefault();
    const { keywordFilter } = this.state;
    const keyword = R.trim(R.replace(/"/g, '', keywordFilter));
    this.setState({ keyword });
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  render() {
    const { intl, loadStatus, instanceMapping, leftLabel, rightLabel, isCorrelation } = this.props;
    const { contextTime, sortBy, sortDirection } = this.state;
    const sourceLabel = get(instanceMapping, leftLabel, leftLabel);
    const targetLabel = get(instanceMapping, rightLabel, rightLabel);
    const eventRelationList = this.eventRelationList || [];
    const filterModalityLink = VLink.state(this, 'filterModality');
    const keywordFilterLink = VLink.state(this, 'keywordFilter').check(
      (value) => !value || (Boolean(value) && R.test(/^"[^\n\r]+"$/, value)),
      'keyword must be in the right format',
    );

    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const hasError = Boolean(errorMessage);
    const hasSearchError = keywordFilterLink.error;
    const sort = ({ sortBy, sortDirection }) => {
      const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
      this.setState({ sortBy, sortDirection });
    };
    const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
      const sortIcon = () => {
        if (sortBy !== dataKey) {
          return null;
        }
        if (sortDirection === 'ASC') {
          return <CaretUpOutlined />;
        }
        return <CaretDownOutlined />;
      };
      return (
        <div>
          {label}
          {!disableSort && sortIcon()}
        </div>
      );
    };
    return (
      <Modal size="big" onClose={this.handleClose} style={{ width: 1020 }}>
        <Container className={isLoading ? 'loading' : ''}>
          <Box className="flex-col" style={{ height: '100%', paddingTop: 0 }}>
            <div className="flex-row" style={{ alignItems: 'center', padding: '8px 0 14px 0' }}>
              <label style={{ width: 120, fontWeight: 500 }}>
                {intl.formatMessage(causalMessages.modalityFilter)}:
              </label>
              <Select
                name="modality"
                style={{ width: 200 }}
                options={this.modalityFilterOptions}
                valueLink={filterModalityLink}
              />
              <span className="label" style={{ width: 120, fontWeight: 500, marginLeft: 20 }}>
                {intl.formatMessage(causalMessages.keywordFilter)}:
              </span>
              <div style={{ width: 200 }}>
                <Input
                  valueLink={keywordFilterLink}
                  placeholder={`"keyword"`}
                  icon="icon search"
                  fullWidth
                  style={{ height: 24 }}
                  iconSearch
                  iconSearchClick={hasSearchError ? () => {} : this.iconSearchClick}
                />
              </div>
              <div className="flex-grow" />
            </div>
            <div className="flex-grow" style={{ overflow: 'hidden' }}>
              <div className="content flex-col causal-log" style={{ paddingBottom: 8, height: 500, width: '100%' }}>
                <div className="flex-grow" style={{ overflow: 'auto' }}>
                  <Table
                    className="with-border"
                    width={998}
                    height={460}
                    deferredMeasurementCache={this.cellMeasureCache}
                    headerHeight={40}
                    rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                    rowHeight={this.cellMeasureCache.rowHeight}
                    rowCount={eventRelationList.length}
                    rowGetter={({ index }) => eventRelationList[index]}
                    ref={(c) => {
                      this.dataTable = c;
                    }}
                    sort={sort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                  >
                    <Column
                      width={130}
                      label={intl.formatMessage(logMessages.dateTimes)}
                      dataKey="timePair"
                      cellRenderer={CellRenderers.timePair}
                      disableSort
                    />
                    <Column
                      headerStyle={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      width={this.contentWidth}
                      label={`${intl.formatMessage(causalMessages.source)} (${sourceLabel})`}
                      flexGrow={1}
                      dataKey="fromContents"
                      cellRenderer={this.eventContentAutoHeightRenderer}
                      disableSort
                    />
                    <Column
                      width={120}
                      label={intl.formatMessage(logMessages.investigate)}
                      dataKey="fromContents"
                      cellRenderer={this.detailsRenderer('from')}
                      disableSort
                    />
                    <Column
                      width={this.contentWidth}
                      style={{ height: '100%' }}
                      label={`${intl.formatMessage(causalMessages.target)} (${targetLabel})`}
                      flexGrow={1}
                      dataKey="toContents"
                      cellRenderer={this.eventContentScrollingRenderer}
                      disableSort
                    />
                    <Column
                      width={120}
                      label={intl.formatMessage(logMessages.investigate)}
                      dataKey="toContents"
                      cellRenderer={this.detailsRenderer('to')}
                      disableSort
                    />
                    <Column
                      width={80}
                      label={intl.formatMessage(logMessages.count)}
                      dataKey="count"
                      headerRenderer={headerRenderer}
                    />
                    {!isCorrelation && (
                      <Column
                        width={100}
                        label={intl.formatMessage(logMessages.maxDelay)}
                        dataKey="delay"
                        headerRenderer={headerRenderer}
                        cellRenderer={({ cellData }) => CellRenderers.humanizeDuration({ period: cellData, intl })}
                      />
                    )}
                    <Column
                      width={80}
                      label={intl.formatMessage(logMessages.probability)}
                      dataKey="probability"
                      headerRenderer={headerRenderer}
                      cellRenderer={CellRenderers.probability}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </Box>
        </Container>
        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeIncident}
            contextTime={contextTime}
            onClose={() => this.setState({ showContextModal: false })}
          />
        )}
      </Modal>
    );
  }
}

const EventRelationModal = injectIntl(EventRelationModalCore);

export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, currentTheme } = state.app;
    const { isAdmin } = state.auth.userInfo;
    const { causalLogEvents } = state.causal;
    return { isAdmin, location, loadStatus, causalLogEvents, currentTheme };
  },
  { loadEventMetricMap, createLoadAction },
)(EventRelationModal);
