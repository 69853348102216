/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isEmpty, debounce } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Icon, { SearchOutlined } from '@ant-design/icons';
import { Tooltip, Radio, Empty, Input } from 'antd';

import { State } from '../../../../../src/common/types';
import { createLoadAction } from '../../../../../src/common/app/actions';
import { getLoadStatus, parseJSON } from '../../../../../src/common/utils';
import { ActionTypes } from '../../../../../src/common/causal/actions';
import { Tree } from '../../../../../src/lib/fui/icons';
import { Container, AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../../../src/lib/fui/react';
import { D3Tree } from '../../../../../src/web/share';

import { appFieldsMessages } from '../../../../../src/common/app/messages';
import { causalMessages } from '../../../../../src/common/causal/messages';
import getInstanceDisplayName from '../../../../../src/common/utils/getInstanceDisplayName';

type Props = {
  view: String,
  refresh: Number,
  dynamicChange: Number,
  causalIncidentInfo: Object,
  incidentMetaData: Object,
  graphView: String,

  intl: Object,
  loadStatus: Object,
  projectDisplayMap: Object,
  credentials: Object,
  dependencyGroupData: Object,
  createLoadAction: Function,
};

class CausalDependencyTreeCore extends D3Tree {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'causal_dependency_graph';

    // update tree orientation
    this.orientation = props.graphView === 'target' ? 'right-to-left' : 'left-to-right';

    // local data
    this.componentCausalInfo = {};
    this.relationElemInfoMap = {};
    this.relationInfoMap = {};
    this.allRootNodes = [];
    this.filterNodeList = [];

    this.nodeRelationMap = {};

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 30,
    });

    this.state = {
      hasRelation: false,
      relationList: [],

      rootnode: null,
      nodeSearchVal: null,
    };
    this.incidentMetaData = props.incidentMetaData;
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const incidentParams = this.props.incidentParams || {};
    const nextIncidentParams = nextProps.incidentParams || {};
    if (
      nextIncidentParams.causalKey !== incidentParams.causalKey ||
      nextIncidentParams.customerName !== incidentParams.customerName ||
      nextIncidentParams.startTimestamp !== incidentParams.startTimestamp ||
      nextIncidentParams.endTimestamp !== incidentParams.endTimestamp ||
      nextIncidentParams.fileName !== incidentParams.fileName ||
      nextProps.refresh !== this.props.refresh ||
      nextProps.causalIncidentInfo !== this.props.causalIncidentInfo
    ) {
      this.reloadData(nextProps, {});
    } else if (nextProps.incidentMetaData !== this.props.incidentMetaData && nextProps.incidentMetaData) {
      this.reloadData(nextProps, {});
    } else if (nextProps.graphView !== this.props.graphView) {
      // update tree orientation
      this.orientation = nextProps.graphView === 'target' ? 'right-to-left' : 'left-to-right';
      this.renderChart(nextProps);
    } else if (nextProps.dependencyGroupData !== this.props.dependencyGroupData) {
      this.renderChart(nextProps);
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData(props) {
    const { createLoadAction, view, incidentMetaData, incidentParams } = props;
    const { causalKey, customerName, startTimestamp, endTimestamp } = incidentParams;
    if (view === 'dependency' && incidentMetaData && causalKey && customerName && startTimestamp && endTimestamp) {
      const { causalKey, customerName, startTimestamp, endTimestamp } = incidentParams;
      createLoadAction(
        ActionTypes.LOAD_CAUSAL_DEPENDENCY_GRAPH,
        {
          causalKey,
          customerName,
          startTimestamp,
          endTimestamp,
        },
        this.dataLoader,
      );
    }
  }

  @autobind
  getChartData(props) {
    const { credentials, dependencyGroupData, graphView } = props;
    let { rootnode, nodeSearchVal } = this.state;

    const componentCausalInfo = {};
    const metaData = get(dependencyGroupData, ['metaData'], {});

    // parse metadata
    R.forEachObjIndexed((val) => {
      const { componentName, instanceListStr } = val;
      const instanceInfoList = parseJSON(instanceListStr) || [];
      R.forEach((item) => {
        const { projectName, userName } = item;
        const projectNameReal = userName !== credentials.userName ? `${projectName}@${userName}` : projectName;

        if (!R.has(componentName, componentCausalInfo)) {
          componentCausalInfo[componentName] = {
            id: componentName,
            ownProjectNames: [projectNameReal],
          };
        } else if (!componentCausalInfo[componentName].ownProjectNames.includes(projectNameReal)) {
          componentCausalInfo[componentName].ownProjectNames.push(projectNameReal);
        }
      }, instanceInfoList);
    }, metaData);

    const relationList = [];
    const relationKeys = [];
    R.forEach((relation) => {
      const key = `${relation.elem1}-${relation.elem2}`;
      if (relationKeys.indexOf(key) === -1) {
        relationList.push(relation);
        relationKeys.push(key);
      }
    }, get(dependencyGroupData, ['relations'], []));
    const hasRelation = !isEmpty(relationList);

    // create tree data
    if (relationList.length >= 100) {
      this.autoDisplayLevel = 2;
    } else {
      this.autoDisplayLevel = this.defaultMaxLevel;
    }

    const relationInfoMap = {};
    const nodeRelationMap = {};
    let fromNodeNames = [];
    let toNodeNames = [];

    // create node map
    R.forEach((relation) => {
      const { elem1, elem2 } = relation;

      // set relationInfoMap
      relationInfoMap[`${elem1}-${elem2}`] = relation;

      // reduce relation infos
      fromNodeNames = [...fromNodeNames, elem1];
      toNodeNames = [...toNodeNames, elem2];

      if (!R.has(elem1, nodeRelationMap))
        nodeRelationMap[elem1] = { nextNodes: [], previousNodes: [], nextNodeRelations: [], previousNodeRelations: [] };
      if (!R.has(elem2, nodeRelationMap))
        nodeRelationMap[elem2] = { nextNodes: [], previousNodes: [], nextNodeRelations: [], previousNodeRelations: [] };

      nodeRelationMap[elem1].nextNodes.push(elem2);
      nodeRelationMap[elem2].previousNodes.push(elem1);
      nodeRelationMap[elem1].nextNodeRelations.push(relation);
      nodeRelationMap[elem2].previousNodeRelations.push(relation);
    }, relationList);

    fromNodeNames = R.uniq(fromNodeNames);
    toNodeNames = R.uniq(toNodeNames);
    const allRootNodes = graphView === 'target' ? toNodeNames : fromNodeNames;

    this.relationInfoMap = relationInfoMap;
    this.componentCausalInfo = componentCausalInfo;
    this.allRootNodes = allRootNodes;
    this.nodeRelationMap = nodeRelationMap;

    // filter node list
    this.handleNodeFilter(nodeSearchVal);
    if (!rootnode || (rootnode && this.filterNodeList.indexOf(rootnode) === -1)) {
      if (this.filterNodeList.length > 0) rootnode = this.filterNodeList[0];
    }

    const startTs = moment.utc().valueOf();
    const treeData = this.createTreeData({
      nodeRelationMap: this.nodeRelationMap,
      parentAllNodeNameMap: {},
      nodeNames: rootnode ? [rootnode] : [],
      parentNode: null,
      parentPath: null,
      level: 1,
    });
    console.debug(`Create tree duration: ${(moment.utc().valueOf() - startTs) / 1000} sec`);

    this.setState({
      hasRelation,
      relationList,
      rootnode,
    });
    return {
      treeData: treeData.length > 0 ? treeData[0] : {},
    };
  }

  @autobind
  renderTip({ operation, target, d }) {
    if (operation === 'node') {
      const { node } = d;
      return node;
    }
    return '';
  }

  @autobind
  onChangeRootnode(event) {
    const rootnodeVal = event.target.value;
    this.setState({ rootnode: rootnodeVal }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  renderListItemInter(rootnodeVal, filterNodeList) {
    return ({ key, index: rowIndex, style, parent }) => {
      const { intl, projectDisplayMap, incidentMetaData } = this.props;

      const node = filterNodeList[rowIndex];
      if (!node) return null;

      const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, node);
      const label = instanceStr;

      const ownProjectNames = get(this.componentCausalInfo, [node, 'ownProjectNames'], []);
      const title =
        ownProjectNames.length > 0 ? (
          <div>
            <div>{instanceStr}</div>
            <div style={{ marginTop: 4 }}>{intl.formatMessage(appFieldsMessages.project)}:</div>
            {R.addIndex(R.map)((projectNameReal, idx) => {
              const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
              return <div key={idx}>{projectDisplayName}</div>;
            }, ownProjectNames)}
          </div>
        ) : (
          instanceStr
        );

      const content = (
        <div className="flex-row flex-center-align" style={{ ...style, width: 'auto', maxWidth: '100%' }}>
          <Tooltip title={title} placement="top" mouseEnterDelay={0.3}>
            <Radio
              className="hidden-line-with-ellipsis inline-block max-width"
              value={node}
              checked={rootnodeVal === node}
            >
              {label}
            </Radio>
          </Tooltip>
        </div>
      );

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          {content}
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleNodeFilter(nodeSearchVal) {
    const { incidentMetaData } = this.props;
    const filterNodeList = this.allRootNodes;
    this.filterNodeList = nodeSearchVal
      ? R.filter((node) => {
          const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, node);
          return (
            R.toLower(node).indexOf(R.toLower(nodeSearchVal)) !== -1 ||
            R.toLower(instanceStr).indexOf(R.toLower(nodeSearchVal)) !== -1
          );
        }, filterNodeList)
      : filterNodeList;
  }

  render() {
    const { intl, loadStatus, onChangeGraphView, graphView } = this.props;
    const { hasRelation, rootnode, nodeSearchVal } = this.state;
    const { filterNodeList } = this;

    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    return (
      <Container className={`full-height flex-col ${isLoading ? 'loading' : ''}`} style={{ paddingTop: 8 }}>
        {errorMessage && <div className="ui mini error message">{errorMessage}</div>}
        {!errorMessage && (
          <div
            className={`${hasRelation ? '' : 'invisible'} flex-row flex-grow corner-8`}
            style={{ border: '1px solid var(--border-color-base)' }}
          >
            <div
              className="flex-col flex-min-height"
              style={{ width: 300, padding: '0 8px', borderRight: '1px solid var(--border-color-base)' }}
            >
              <Radio.Group
                className="flex-grow flex-col flex-min-height"
                onChange={this.onChangeRootnode}
                value={rootnode}
              >
                <div className="flex-row" style={{ marginTop: 8 }}>
                  <div style={{ fontWeight: 'bold', fontSize: 16 }}>{intl.formatMessage(causalMessages.nodeList)}</div>
                  <div className="flex-grow flex-row flex-end-justify">
                    <Radio.Group value={graphView} size="small" onChange={onChangeGraphView}>
                      <Tooltip title="Prediction Graph" placement="top">
                        <Radio.Button value="source">
                          <Icon size="small" component={() => <Tree color="currentColor" size="12px" rotate={-90} />} />
                        </Radio.Button>
                      </Tooltip>
                      <Tooltip title="Root Cause Graph" placement="top">
                        <Radio.Button value="target">
                          <Icon size="small" component={() => <Tree color="currentColor" size="12px" rotate={90} />} />
                        </Radio.Button>
                      </Tooltip>
                    </Radio.Group>
                  </div>
                </div>

                <div className="flex-row" style={{ marginTop: 8 }}>
                  <Input
                    allowClear
                    size="small"
                    placeholder="input search node"
                    value={nodeSearchVal}
                    onChange={({ target: { value } }) => {
                      this.setState(
                        { nodeSearchVal: value },
                        debounce(() => {
                          this.handleNodeFilter(value);
                          this.cellMeasureCache.clearAll();
                          this.forceUpdate();
                        }, 600),
                      );
                    }}
                    style={{ width: '100%' }}
                    prefix={<SearchOutlined style={{ color: 'var(--text-color-secondary)' }} />}
                  />
                </div>

                <div className="flex-grow">
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        ref={(listNode) => (this.listNode = listNode)}
                        width={width}
                        height={height}
                        rowCount={filterNodeList.length}
                        deferredMeasurementCache={this.cellMeasureCache}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowRenderer={this.renderListItemInter(rootnode, filterNodeList)}
                      />
                    )}
                  </AutoSizer>
                </div>
              </Radio.Group>
            </div>
            <div className="flex-grow">
              <AutoSizer>
                {({ width, height }) => (
                  <div style={{ width, height }}>
                    <div
                      className="d3-tree-container"
                      style={hasRelation ? {} : { display: 'none' }}
                      ref={(container) => {
                        this.container = container;
                      }}
                    />
                  </div>
                )}
              </AutoSizer>

              {!hasRelation && (
                <div className="full-height flex-row flex-center-align flex-center-justify">
                  <Empty description={intl.formatMessage(causalMessages.noDependencyRelationFuond)} />
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const CausalDependencyTree = injectIntl(CausalDependencyTreeCore);
export default connect(
  (state: State) => {
    const { loadStatus, projectDisplayMap } = state.app;
    const { credentials } = state.auth;
    const { dependencyGroupData } = state.causal;
    return { loadStatus, projectDisplayMap, credentials, dependencyGroupData };
  },
  { createLoadAction },
)(CausalDependencyTree);
