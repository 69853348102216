/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import VLink from 'valuelink';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isInteger } from 'lodash';
import { autobind } from 'core-decorators';
import {
  Button,
  Alert,
  Select as AntSelect,
  DatePicker,
  Checkbox,
  TreeSelect,
  Divider,
  Spin,
  Card,
  Input as AntInput,
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Input, Popover, Select } from '../../../../lib/fui/react';

import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  credentials: Object,
  componentState: Object,
};

export const filterTreeNodes = (inputValue = '', treeNode = {}, treeNodeFilterProp = 'titleFilter') => {
  return treeNode[treeNodeFilterProp].toLowerCase().includes(inputValue.toLowerCase());
};

export const compactJson = (input) => {
  // 去除回车和空格
  const compacted = (input || '').replace(/[\r\n\s]+/g, '');
  return compacted;
};

export const validateAndFormatJson = (input) => {
  if (!input) {
    return true;
  }
  let validate = false;
  try {
    // 尝试解析 JSON 字符串
    const parsedData = JSON.parse(input);
    // 格式化 JSON 数据并显示
    validate = !!parsedData;
  } catch (error) {
    // 如果 JSON 无效，显示错误消息
    validate = false;
  }
  return validate;
};

export const tagFilterJSONFormat = `{
  "l": [
    {
      "k": "Team",
      "v": [
        "Alpha"
      ]
    },
    {
      "k": "Project",
      "v": [
        "Django"
      ]
    },
    {
      "k": "Environments",
      "v": [
        "Production",
        "Staging",
        "Dev",
        "Development"
      ]
    }
  ]
}`;

const initData = {
  treeDataInstance: [],
  selectedInstances: [],
  metrics: [],
  selectedMetrics: [],
  possibleAutoScalingGroup: [],
  autoScalingGroup: [],
  possibleExtraNamespaces: [],
  extraNamespace: [],
  samplingInterval: 5,
  samplingUnit: 60,
  collectionInterval: 5,
  collectionUnit: 60,
  startTime: null,
  endTime: null,
  processPauseFlag: false,
  logGroups: [],
  logGroupOptions: [],
  verified: false,
};

class AWSCloudWatchSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;

    const state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: undefined,

      instanceTypes: [],
      region: [],
      iamAccessKey: '',
      secretAccessKey: '',
      dataType: 'Metric',
      samplingInterval: 5,
      samplingUnit: 60,
      collectionInterval: 5,
      collectionUnit: 60,
      treeDataInstance: [],
      selectedInstances: [],
      metrics: [],
      selectedMetrics: [],
      kpiMetrics: [],
      possibleAutoScalingGroup: [],
      autoScalingGroup: [],
      possibleExtraNamespaces: [],
      extraNamespace: [],
      startTime: null,
      endTime: null,
      processPauseFlag: '',
      regionOption: [],
      isLoading: false,
      logGroups: [],
      logGroupOptions: [],
      tagFilter: '',
    };
    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);

    this.state = {
      ...state,

      ...(componentState || {}),
    };
    this.samplingUnit = [
      { label: 'second', value: 1 },
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
    this.dataTypeOption = [
      { label: 'Metric', value: 'Metric' },
      { label: 'Log', value: 'Log' },
      { label: 'Alert', value: 'Alert' },
    ];
    this.componentInsMap = {};
    this.filterLogGroupOptions = [];
    this.filterTreeData = [];
  }

  componentDidMount() {
    this.getRegionOptionList();
  }

  @autobind
  getRegionOptionList() {
    const { credentials } = this.props;
    this.setState({ isLoading: true });
    fetchGet(getEndpoint('aws-all-regions'), {
      ...credentials,
    })
      .then((data) => {
        const { regions } = data;
        if (regions) {
          let regionOption = R.map((item) => ({ label: item.name, value: item.name }), regions);
          regionOption = R.sortWith([R.ascend(R.prop('label'))], regionOption);
          this.setState({ regionOption });
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(String(err));
      });
  }

  @autobind
  handleInstanceChange(selectedInstances) {
    this.setState({ selectedInstances });
  }

  @autobind
  handleMetricChange(selectedMetrics) {
    this.setState({ selectedMetrics });
  }

  @autobind
  handleKPIMetricChange(kpiMetrics) {
    this.setState({ kpiMetrics });
  }

  @autobind
  handleAutoScalingGroupChange(autoScalingGroup) {
    this.setState({ autoScalingGroup });
  }

  @autobind
  handleExtraNamespaceChange(extraNamespace) {
    this.setState({ extraNamespace });
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { credentials } = this.props;
    const { iamAccessKey, secretAccessKey, dataType, region, instanceTypes, tagFilter } = this.state;
    const isMetric = dataType === 'Metric';
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      accessKey: iamAccessKey,
      secretKey: secretAccessKey,
      region: (region || []).join(','),
      instanceType: instanceTypes.toString(),
      projectCreationType: isMetric ? 'AMSMetric' : 'AMSLog',
      dataType,
      ...(tagFilter ? { awsTagFilter: compactJson(tagFilter) } : {}),
    })
      .then((data) => {
        const { success, message: errMsg, info } = data;
        if (success) {
          this.parseData(info);
        } else {
          this.componentInsMap = {};
          this.setState(
            {
              treeDataInstance: [],
              metrics: [],
              possibleAutoScalingGroup: [],
              possibleExtraNamespaces: [],
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg || 'error',
            },
            () => {
              this.props.saveProjectInfo('AWSCloudWatch', { dataType }, this.state);
            },
          );
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.componentInsMap = {};
        this.setState(
          {
            treeDataInstance: [],
            metrics: [],
            possibleAutoScalingGroup: [],
            possibleExtraNamespaces: [],
            isVerifying: false,
            verified: false,
            verifiedMessage: errMsg || 'No metric data is found under the selected instance type.',
          },
          () => {
            this.props.saveProjectInfo('AWSCloudWatch', { dataType }, this.state);
          },
        );
      });
  }

  @autobind
  parseData(info) {
    const { tagFilter } = this.state;
    let { instances, metrics, possibleAutoScalingGroup, possibleExtraNamespaces } = info || {};
    this.apiInfo = info;

    instances = R.map((item) => {
      const { componentName, instanceId } = item;
      return { ...item, componentName: componentName || instanceId };
    }, instances);
    instances = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('componentName')))], instances);
    metrics = R.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }, metrics || []);
    possibleAutoScalingGroup = possibleAutoScalingGroup || [];
    possibleExtraNamespaces = possibleExtraNamespaces || [];

    // build tree list
    const componentInsMap = {};
    R.forEach((item) => {
      const { componentName } = item;
      if (!R.has(componentName, componentInsMap)) componentInsMap[componentName] = [];
      componentInsMap[componentName].push(item);
    }, instances);

    let treeData = [];
    R.forEachObjIndexed((insList, cmp) => {
      const children = R.map((item) => {
        const { instanceId, instanceType, publicIp } = item;
        const key = `${cmp}$${instanceId}`;

        const titleFilter = `Id: ${instanceId} Type: ${instanceType} Public IP: ${publicIp}`;
        const title = (
          <span>
            <span className="light-label bold">Id:</span>
            <span style={{ margin: '0 8px 0 4px' }}>{instanceId}</span>
            <span className="light-label bold">Type:</span>
            <span style={{ margin: '0 8px 0 4px' }}>{instanceType}</span>
            <span className="light-label bold">Public IP:</span>
            <span style={{ margin: '0 8px 0 4px' }}>{publicIp}</span>
          </span>
        );
        return {
          titleFilter,
          title,
          value: key,
          key,
          checkable: false,
        };
      }, insList);
      const key = cmp;
      treeData.push({
        titleFilter: cmp,
        title: cmp,
        value: key,
        key,
        children,
      });
    }, componentInsMap);
    treeData = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], treeData);
    const logGroupOptions = R.map((item) => ({ label: item, value: item }), info?.logGroups || []);
    this.filterLogGroupOptions = logGroupOptions;
    this.filterTreeData = treeData;

    this.componentInsMap = componentInsMap;
    this.setState(
      {
        treeDataInstance: treeData,
        metrics,
        possibleAutoScalingGroup,
        possibleExtraNamespaces,
        isVerifying: false,
        verified: true,
        verifiedMessage: undefined,
        logGroupOptions,
        logGroups: [],
        ...(tagFilter ? { selectedInstances: R.keys(componentInsMap || {}) } : {}),
      },
      () => {
        this.props.saveProjectInfo('AWSCloudWatch', { dataType: this.state.dataType }, this.state);
      },
    );
  }

  @autobind
  handleDateRangeChange(timeRange) {
    const [startTime, endTime] = timeRange;
    this.setState({ startTime, endTime });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      instanceTypes,
      region,
      iamAccessKey,
      secretAccessKey,
      samplingInterval,
      samplingUnit,
      collectionInterval,
      collectionUnit,
      selectedInstances,
      selectedMetrics,
      kpiMetrics,
      autoScalingGroup,
      extraNamespace,
      startTime,
      endTime,
      processPauseFlag,
      dataType,
      logGroups,
      tagFilter,
    } = this.state;

    const newInstances = [];
    R.forEach((item) => {
      if (this.componentInsMap[item]) {
        R.forEach((i) => {
          newInstances.push({ c: i.componentName, i: i.rawInstanceId, r: i.region });
        }, this.componentInsMap[item] || []);
      }
    }, selectedInstances || []);

    this.props.createProject(
      'AWSCloudWatch',
      {
        instanceTypes,
        region: (region || []).join(','),
        iamAccessKey,
        secretAccessKey,
        samplingInterval: Number(samplingInterval) * samplingUnit,
        collectionInterval: Number(collectionInterval) * collectionUnit,
        newInstances: JSON.stringify(newInstances),
        metrics: JSON.stringify(selectedMetrics),
        kpiMetrics: JSON.stringify(kpiMetrics),
        autoScalingGroup: JSON.stringify(autoScalingGroup),
        extraNamespace: JSON.stringify(extraNamespace),
        startTime: startTime ? moment(startTime).utc().startOf('day').valueOf() : undefined,
        endTime: endTime ? moment(endTime).utc().endOf('day').valueOf() : undefined,
        processPauseFlag,
        dataType,
        ...(dataType === 'Log' ? { logGroups: JSON.stringify(logGroups) } : {}),
        ...(tagFilter ? { awsTagFilter: compactJson(tagFilter) } : {}),
      },
      this.state,
    );
  }

  render() {
    const { intl, hasError } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,
      treeDataInstance,
      selectedInstances,
      metrics,
      selectedMetrics,
      kpiMetrics,
      possibleAutoScalingGroup,
      autoScalingGroup,
      possibleExtraNamespaces,
      extraNamespace,
      startTime,
      endTime,
      processPauseFlag,
      dataType,
      regionOption,
      isLoading,
      region,
      logGroups,
      logGroupOptions,
      tagFilter,
      instanceTypes,
      iamAccessKey,
      secretAccessKey,
    } = this.state;
    const isMetric = dataType === 'Metric';

    const samplingIntervalLink = VLink.state(this, 'samplingInterval').check((word) => {
      return Number(word) > 0 && isInteger(Number(word));
    }, 'The sampling interal is required and it has to be an integer');
    const samplingUnitLink = VLink.state(this, 'samplingUnit').check((word) => word, 'The sampling unit is required');
    const collectionIntervalLink = VLink.state(this, 'collectionInterval').check((word) => {
      return Number(word) > 0 && isInteger(Number(word));
    }, 'The collection interal is required and it has to be an integer');
    const collectionUnitLink = VLink.state(this, 'collectionUnit').check(
      (word) => word,
      'The collection unit is required',
    );
    const tagFilterValidate = validateAndFormatJson(compactJson(tagFilter));

    const hasVerifyError =
      !region ||
      region.length === 0 ||
      !instanceTypes.length ||
      !iamAccessKey ||
      !secretAccessKey ||
      !dataType ||
      !tagFilterValidate;

    const hasMetricError =
      !region ||
      region.length === 0 ||
      !dataType ||
      !instanceTypes.length ||
      !iamAccessKey ||
      !secretAccessKey ||
      samplingIntervalLink.error ||
      samplingUnitLink.error ||
      collectionIntervalLink.error ||
      collectionUnitLink.error ||
      !metrics ||
      metrics.length === 0 ||
      !selectedMetrics ||
      selectedMetrics.length === 0 ||
      !treeDataInstance ||
      treeDataInstance.length === 0 ||
      !selectedInstances ||
      selectedInstances.length === 0 ||
      !tagFilterValidate ||
      hasError;
    const hasLogError =
      !region ||
      region.length === 0 ||
      !dataType ||
      !instanceTypes.length ||
      !iamAccessKey ||
      !secretAccessKey ||
      hasError;

    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.AWSCloudWatchIntro),
          }}
        />

        <div style={{ marginBottom: 2 }}>{intl.formatMessage(projectWizardMessages.followingPermissions)}:</div>
        <div style={{ paddingBottom: '1em' }}>
          “ec2:DescribeInstances”, “logs:DescribeLogGroups”,”cloudwatch:GetMetricData”,”logs:DescribeLogStreams”,
          “servicediscovery:ListNamespaces”,”cloudwatch:GetMetricStatistics”,
          “cloudwatch:ListMetrics”,”ec2:DescribeSecurityGroups”,”cloudwatch:ListMetricStreams”,
          “autoscaling:DescribeAutoScalingGroups”,”rds:DescribeDBInstances”,”logs:GetLogEvents”,
          “rds:DescribeDBProxies”,”cloudwatch:GetMetricStream”,”ec2:DescribeInstanceStatus”
        </div>

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Spin spinning={isLoading}>
            <div>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  Data Type<span style={{ color: 'red', marginLeft: 4 }}>*</span>
                </div>
                <AntSelect
                  value={dataType}
                  options={this.dataTypeOption}
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    const { instanceTypes } = this.state;
                    this.setState(
                      {
                        dataType: value,
                        instanceTypes: value !== 'Metric' ? 'EC2' : instanceTypes,
                        tagFilter: '',
                        ...initData,
                      },
                      () => {
                        this.props.saveProjectInfo('AWSCloudWatch', { dataType: value }, this.state);
                      },
                    );
                  }}
                  className={`${dataType ? '' : 'jsonKeyNoneError'}`}
                />
              </div>
              {isMetric && (
                <div style={{ marginBottom: 16 }}>
                  <div style={{ marginBottom: 4 }}>
                    Instance Type<span style={{ color: 'red', marginLeft: 4 }}>*</span>
                  </div>
                  <AntSelect
                    value={instanceTypes}
                    style={{ width: '100%' }}
                    options={[
                      { label: 'EC2', value: 'EC2' },
                      { label: 'RDS', value: 'RDS' },
                      { label: 'RDS Proxy', value: 'RDS Proxy' },
                      { label: 'DynamoDB', value: 'DynamoDB' },
                      { label: 'Lambda', value: 'Lambda' },
                      { label: 'ECS', value: 'ECS' },
                      { label: 'EBS', value: 'EBS' },
                      { label: 'ELB', value: 'ELB' },
                      { label: 'ApplicationELB', value: 'ApplicationELB' },
                    ]}
                    onChange={(value) =>
                      this.setState({ instanceTypes: value, ...initData }, () => {
                        this.props.saveProjectInfo('AWSCloudWatch', { dataType }, this.state);
                      })
                    }
                    className={`${instanceTypes?.length ? '' : 'jsonKeyNoneError'}`}
                  />
                </div>
              )}

              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  Region<span style={{ color: 'red', marginLeft: 4 }}>*</span>
                </div>
                <AntSelect
                  style={{ width: '100%' }}
                  mode="multiple"
                  value={region}
                  options={regionOption}
                  onChange={(region) =>
                    this.setState({ region, ...initData }, () => {
                      this.props.saveProjectInfo('AWSCloudWatch', { dataType }, this.state);
                    })
                  }
                  autoClearSearchValue={false}
                  className={`${region?.length ? '' : 'jsonKeyNoneError'}`}
                />
              </div>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  Access Key ID<span style={{ color: 'red', marginLeft: 4 }}>*</span>
                </div>
                <AntInput
                  value={iamAccessKey}
                  type="password"
                  onChange={(e) =>
                    this.setState({ iamAccessKey: e.target.value, ...initData }, () => {
                      this.props.saveProjectInfo('AWSCloudWatch', { dataType }, this.state);
                    })
                  }
                  className={`full-width ${iamAccessKey ? '' : 'inputIsNil'}`}
                />
              </div>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  Secret Key<span style={{ color: 'red', marginLeft: 4 }}>*</span>
                </div>
                <AntInput
                  value={secretAccessKey}
                  type="password"
                  onChange={(e) =>
                    this.setState({ secretAccessKey: e.target.value, ...initData }, () => {
                      this.props.saveProjectInfo('AWSCloudWatch', { dataType }, this.state);
                    })
                  }
                  className={`full-width ${secretAccessKey ? '' : 'inputIsNil'}`}
                />
              </div>
              {isMetric && (
                <div style={{ marginBottom: 16 }}>
                  <div style={{ marginBottom: 4 }}>
                    Tag filter in JSON format
                    <Popover
                      content={
                        <div style={{ maxWidth: 400, maxHeight: 400, overflowY: 'auto', display: 'flex' }}>
                          <div style={{ marginRight: 8 }}>Example:</div>
                          <div style={{ flex: 1, wordBreak: 'break-word' }}>
                            <pre
                              style={{
                                height: '100%',
                                paddingRight: 8,
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                              }}
                            >
                              {tagFilterJSONFormat}
                            </pre>
                          </div>
                        </div>
                      }
                      mouseEnterDelay={0.3}
                      placement="right"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                    </Popover>
                  </div>
                  <AntInput.TextArea
                    allowClear
                    autoSize={{ minRows: 2, maxRows: 8 }}
                    value={tagFilter}
                    style={{ resize: 'none', ...(!tagFilterValidate ? { borderColor: 'var(--primary-5)' } : {}) }}
                    onChange={(e) =>
                      this.setState({ tagFilter: e.target.value, ...initData }, () => {
                        this.props.saveProjectInfo('AWSCloudWatch', { dataType }, this.state);
                      })
                    }
                  />
                  {!tagFilterValidate ? <div style={{ color: 'red', marginTop: 4 }}>JSON format error</div> : ''}
                </div>
              )}
            </div>
          </Spin>
          <div className="inline text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError || verified}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>

        <Card className={`${verified ? 'block' : 'display-none'}`}>
          {isMetric && (
            <div>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  Instances<span style={{ color: 'red', marginLeft: 4 }}>*</span>
                </div>
                <div className="flex-row">
                  <TreeSelect
                    className="no-count-num-row"
                    style={{ width: '100%' }}
                    showSearch
                    autoClearSearchValue={false}
                    treeCheckable
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    treeNodeFilterProp="titleFilter"
                    treeNodeLabelProp="title"
                    treeData={treeDataInstance}
                    value={selectedInstances}
                    onChange={this.handleInstanceChange}
                    dropdownRender={(menu) => {
                      const allInstanceKey = [];
                      this.filterTreeData.forEach((item) => allInstanceKey.push(item.value));
                      const selectAllInstanceKey = selectedInstances;
                      return (
                        <div>
                          <div
                            className="flex-row"
                            style={{ padding: '5px 24px' }}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={R.difference(allInstanceKey, selectAllInstanceKey).length === 0}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const diff = R.difference(selectAllInstanceKey, allInstanceKey);
                                this.setState({ selectedInstances: checked ? [...diff, ...allInstanceKey] : diff });
                              }}
                            />
                            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                          </div>
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                    onSearch={(searchValue) => {
                      if (!searchValue) {
                        this.filterTreeData = treeDataInstance;
                      } else {
                        const filterNodes = (nodes) => {
                          return nodes
                            .map((node) => {
                              const { children, ...restNode } = node;
                              const filteredChildren = children ? filterNodes(children) : [];

                              if (filterTreeNodes(searchValue, node, 'titleFilter')) {
                                return {
                                  ...restNode,
                                  children: filteredChildren.length > 0 ? filteredChildren : children,
                                };
                              } else if (filteredChildren.length > 0) {
                                return { ...restNode, children: filteredChildren };
                              }

                              return null;
                            })
                            .filter((node) => node !== null);
                        };
                        this.filterTreeData = filterNodes(treeDataInstance);
                      }
                    }}
                    disabled={tagFilter}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  Metrics<span style={{ color: 'red', marginLeft: 4 }}>*</span>
                </div>
                <div className="flex-row">
                  <AntSelect
                    className="no-count-num-row"
                    style={{ width: '100%' }}
                    mode="multiple"
                    autoClearSearchValue={false}
                    value={selectedMetrics}
                    onChange={this.handleMetricChange}
                    showSearch
                    optionFilterProp="title"
                    optionLabelProp="title"
                    dropdownRender={(menu) => {
                      const selectAll = R.difference(metrics, selectedMetrics).length === 0;
                      return (
                        <div>
                          <div
                            className="flex-row"
                            style={{ padding: '5px 12px' }}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selectAll}
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (checked) {
                                  this.setState({ selectedMetrics: metrics });
                                } else {
                                  this.setState({ selectedMetrics: [] });
                                }
                              }}
                            />
                            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                          </div>
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                  >
                    {R.map((metric) => {
                      return (
                        <AntSelect.Option className="hide-icon" key={metric} title={metric}>
                          <Checkbox
                            checked={selectedMetrics.indexOf(metric) !== -1}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {metric}
                        </AntSelect.Option>
                      );
                    }, metrics)}
                  </AntSelect>
                </div>
              </div>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>Auto scaling group</div>
                <div className="flex-row">
                  <AntSelect
                    style={{ width: '100%' }}
                    mode="multiple"
                    autoClearSearchValue={false}
                    maxTagCount={1}
                    value={autoScalingGroup}
                    onChange={this.handleAutoScalingGroupChange}
                    showSearch
                    optionFilterProp="title"
                    optionLabelProp="title"
                  >
                    {R.map((item) => {
                      return (
                        <AntSelect.Option className="hide-icon" key={item} title={item}>
                          <Checkbox
                            checked={autoScalingGroup.indexOf(item) !== -1}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {item}
                        </AntSelect.Option>
                      );
                    }, possibleAutoScalingGroup)}
                  </AntSelect>
                </div>
              </div>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>Custom metric namespace</div>
                <div className="flex-row">
                  <AntSelect
                    style={{ width: '100%' }}
                    mode="multiple"
                    autoClearSearchValue={false}
                    maxTagCount={1}
                    value={extraNamespace}
                    onChange={this.handleExtraNamespaceChange}
                    showSearch
                    optionFilterProp="title"
                    optionLabelProp="title"
                  >
                    {R.map((item) => {
                      return (
                        <AntSelect.Option className="hide-icon" key={item} title={item}>
                          <Checkbox
                            checked={extraNamespace.indexOf(item) !== -1}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {item}
                        </AntSelect.Option>
                      );
                    }, possibleExtraNamespaces)}
                  </AntSelect>
                </div>
              </div>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  Sampling Interval<span style={{ color: 'red', marginLeft: 4 }}>*</span>
                </div>
                <div className="flex-row">
                  <Input type="number" valueLink={samplingIntervalLink} style={{ width: 200 }} />
                  <Select valueLink={samplingUnitLink} style={{ width: 130 }} options={this.samplingUnit} />
                </div>
              </div>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  Collection Interval<span style={{ color: 'red', marginLeft: 4 }}>*</span>
                </div>
                <div className="flex-row">
                  <Input type="number" valueLink={collectionIntervalLink} style={{ width: 200 }} />
                  <Select valueLink={collectionUnitLink} style={{ width: 130 }} options={this.samplingUnit} />
                </div>
              </div>
            </div>
          )}

          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 4 }}>Historical Date Range</div>
            <div className="flex-row flex-grow flex-center-align">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={[startTime, endTime]}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleDateRangeChange}
              />
            </div>
          </div>
          {dataType !== 'Metric' && (
            <div style={{ marginBottom: 16 }} className="flex-row flex-center-align">
              <div style={{ marginBottom: 4, marginRight: 16 }}>
                Enable initial processing pause<span style={{ color: 'red', marginLeft: 4 }}>*</span>
              </div>
              <Checkbox
                checked={processPauseFlag}
                onChange={(e) => {
                  this.setState({ processPauseFlag: e.target.checked });
                }}
              />
            </div>
          )}
          {dataType === 'Log' && (
            <div style={{ marginBottom: 16 }}>
              <div style={{ marginBottom: 4 }}>
                Log groups<span style={{ color: 'red', marginLeft: 4 }}>*</span>
              </div>
              <AntSelect
                className="no-count-num"
                style={{ width: '100%' }}
                showSearch
                mode="multiple"
                value={logGroups}
                autoClearSearchValue={false}
                onChange={(logGroups) => this.setState({ logGroups })}
                onDropdownVisibleChange={(open) => {
                  if (!open) {
                    this.filterLogGroupOptions = logGroupOptions;
                  }
                }}
                dropdownRender={(menu) => {
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={logGroups.length === this.filterLogGroupOptions.length}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              this.setState({
                                logGroups: R.map((item) => item.value, this.filterLogGroupOptions),
                              });
                            } else {
                              this.setState({ logGroups: [] });
                            }
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
                onSearch={(searchValue) => {
                  const newComponent = searchValue
                    ? R.filter(
                        (item) => item.value.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
                        logGroupOptions,
                      )
                    : logGroupOptions;
                  this.filterLogGroupOptions = newComponent;
                }}
              >
                {R.map((item) => {
                  return (
                    <AntSelect.Option className="hide-icon" key={item.value} value={item.value}>
                      <Checkbox
                        checked={logGroups.includes(item.value)}
                        onChange={(e) => null}
                        style={{ marginRight: 8 }}
                      />
                      {item.label}
                    </AntSelect.Option>
                  );
                }, logGroupOptions)}
              </AntSelect>
            </div>
          )}
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={isMetric ? hasMetricError : hasLogError}
            onClick={this.handleConfirmClick}
            loading={isLoading || isVerifying || this.props.isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const AWSCloudWatchSetting = injectIntl(AWSCloudWatchSettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(AWSCloudWatchSetting);
