/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';

import { State } from '../../../../common/types';
import { Container, Table, Column, AutoSizer, Tooltip, Select } from '../../../../lib/fui/react';
import {
  loadLogSequenceScopeList,
  saveLogSequenceScopeList,
  removeLogSequenceScopeList,
} from '../../../../common/settings/actions';

import { appMenusMessages, appFieldsMessages, appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  intl: Object,
  currentLoadingComponents: Object,
  projectName: String,
  logSequenceStructureList: Array,
  logSequenceScopeList: Array,

  loaderStatus: Object,
  loadLogSequenceScopeList: Function,
  saveLogSequenceScopeList: Function,
  removeLogSequenceScopeList: Function,
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

class LogSequenceScopeSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { logSequenceStructureList, logSequenceScopeList } = props;

    this.submitLoader = 'setting_log_sequence_scope_submit';

    // Make a full copy of the data to avoid side affect with other components.
    this.localLogSequenceScopeList = R.clone(logSequenceScopeList || []);
    this.logSequenceStructureList = [];
    this.state = {};
    this.matchTypeList = [
      { label: 'Json', value: 'json' },
      // { label: 'Regex', value: 'regex' },
      // { label: 'String', value: 'string' },
    ];

    R.forEach((p) => {
      this.logSequenceStructureList.push({ label: p, value: p });
    }, logSequenceStructureList || []);

    this.selectCellRender = ({ dataKey, rowData, cellData }) => (
      <Tooltip title={<div>{cellData}</div>} placement="top" style={{ display: 'inline-block' }}>
        <div>{cellData}</div>
      </Tooltip>
    );
    this.checkboxCellRender = ({ dataKey, rowData, cellData }) => (
      <input
        className="fui input"
        type="checkbox"
        checked={cellData || false}
        onChange={this.handleInputChanged(rowData, dataKey)}
      />
    );
  }

  componentDidMount() {
    this.loadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { logSequenceStructureList, logSequenceScopeList } = nextProps;

    if (nextProps.projectName !== this.props.projectName) {
      this.loadData(nextProps);
    } else if (!R.identical(logSequenceScopeList, get(this.props, 'logSequenceScopeList'))) {
      this.localLogSequenceScopeList = R.clone(logSequenceScopeList);

      const newOptions = [];
      R.forEach((p) => {
        newOptions.push({ label: p, value: p });
      }, logSequenceStructureList || []);
      this.logSequenceStructureList = newOptions;
    }
  }

  @autobind
  loadData(props) {
    const { projectName, loadLogSequenceScopeList } = props;
    if (projectName) {
      loadLogSequenceScopeList({ projectName });
    }
  }

  @autobind
  handleRoleChange(rowData, dataKey) {
    return (e) => {
      // Save the data and force update.
      rowData[dataKey] = e ? e.value || '' : '';
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  saveDataTableNode(node) {
    this.table = node;
  }

  @autobind
  handleTypeChange(rowData, dataKey) {
    return (e) => {
      const newVal = e ? e.value : '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }
  @autobind
  renderTypeSelect({ dataKey, rowData, cellData }) {
    return (
      <Select
        withPortal
        autosize
        clearable
        style={{}}
        options={this.matchTypeList}
        value={cellData}
        onChange={this.handleTypeChange(rowData, dataKey)}
      />
    );
  }
  @autobind
  handleFilterChanged(rowData, dataKey) {
    return (e) => {
      const target = e.target;
      const newVal = target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleFilterSelectChanged(rowData, dataKey) {
    return (e) => {
      // Save the data and force update.
      rowData[dataKey] = e ? e.value || '' : '';
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }
  @autobind
  renderFilterInput({ dataKey, rowData, cellData, ...other }) {
    const { matchType } = rowData;
    if (matchType === 'json') {
      return (
        <Select
          withPortal
          autosize
          clearable
          style={{}}
          options={this.logSequenceStructureList}
          value={cellData}
          onChange={this.handleFilterSelectChanged(rowData, dataKey)}
        />
      );
    }
    return <input className="fui input" value={cellData} onChange={this.handleFilterChanged(rowData, dataKey)} />;
  }
  @autobind
  renderOperationInput({ dataKey, rowData, cellData }) {
    return <div className="flex-grow">=</div>;
  }
  @autobind
  handleValueChanged(rowData, dataKey) {
    return (e) => {
      const target = e.target;
      const newVal = target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }
  @autobind
  renderValueInput({ dataKey, rowData, cellData }) {
    return <input className="fui input" value={cellData} onChange={this.handleValueChanged(rowData, dataKey)} />;
  }
  @autobind
  handleNameChanged(rowData, dataKey) {
    return (e) => {
      const target = e.target;
      const newVal = target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleGroupRemove({ rowData, rowIndex }) {
    const { projectName, logSequenceScopeList } = this.props;
    return () => {
      const self = this;
      const diff = R.difference(self.localLogSequenceScopeList, logSequenceScopeList || []);
      if (window.confirm(`delete this structure:${rowData.structurePath}?`)) {
        const data = R.find(R.propEq('structurePath', rowData.structurePath))(diff);
        if (data) {
          self.localLogSequenceScopeList = R.remove(rowIndex, 1, self.localLogSequenceScopeList);
          this.table.forceUpdateGrid();
          this.forceUpdate();
        } else {
          this.props.removeLogSequenceScopeList({ projectName, entries: [rowData] });
        }
      }
    };
  }

  @autobind
  handleInputChanged(rowData, dataKey) {
    return (e) => {
      const target = e.target;
      const newVal = target.type === 'checkbox' ? target.checked : target.value || '';

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  renderRemoveButton(data) {
    const { intl } = this.props;
    return (
      <div className="hover-show ui grey button" onClick={this.handleGroupRemove(data)}>
        {intl.formatMessage(appButtonsMessages.remove)}
      </div>
    );
  }

  @autobind
  handleAddClick(e) {
    e.preventDefault();
    this.localLogSequenceScopeList.push({
      name: uuidv4(),
      structurePath: '',
      value: '',
      isSequenceScope: false,
      isContent: false,
    });
    this.table.forceUpdateGrid();
    this.forceUpdate();
    this.addIndex = this.localLogSequenceScopeList.length - 1;
  }

  @autobind
  handleSaveClick() {
    const { saveLogSequenceScopeList } = this.props;
    let { projectName } = this.props;

    if (projectName && projectName.indexOf('@') >= 0) {
      projectName = projectName.split('@')[0] || projectName;
    }

    const localLogSequenceScopeList = R.filter((log) => !R.isEmpty(log.structurePath), this.localLogSequenceScopeList);
    const entries = R.map((m) => {
      return {
        matchType: m.matchType,
        structurePath: m.structurePath,
        structureValue: m.structureValue || '',
        name: m.name,
        isSequenceScope: m.isSequenceScope || false,
        isContent: m.isContent || false,
      };
    }, localLogSequenceScopeList);
    saveLogSequenceScopeList({ projectName, entries }, { [this.submitLoader]: true });
  }

  render() {
    const { intl, logSequenceScopeList } = this.props;

    const diff = R.difference(this.localLogSequenceScopeList, logSequenceScopeList || []);

    const addIndex = this.addIndex;
    if (this.addIndex >= 0) {
      this.addIndex = -1;
    }

    const hasError = diff.length === 0;
    const isSubmitting = get(this.props.currentLoadingComponents, this.submitLoader, false);

    return (
      <Container fullHeight className="overflow-y-auto">
        <form
          className={`ui ${hasError ? 'error' : ''} form full-height flex-col`}
          style={{ fontSize: 12, width: 1048 }}
        >
          <p>{intl.formatMessage(settingsMessages.sequenceScopeDesc)}</p>
          <div className="field">
            <Button type="primary" onClick={this.handleAddClick}>
              {intl.formatMessage(appButtonsMessages.add)}
            </Button>
          </div>
          <Container className={`flex-grow field${isSubmitting ? ' disabled' : ''}`}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  rowCount={this.localLogSequenceScopeList.length}
                  rowGetter={({ index }) => this.localLogSequenceScopeList[index]}
                  ref={this.saveDataTableNode}
                  scrollToIndex={addIndex}
                >
                  <Column
                    width={120}
                    label={intl.formatMessage(appFieldsMessages.type)}
                    dataKey="matchType"
                    className="white-pre"
                    cellRenderer={this.renderTypeSelect}
                  />
                  <Column
                    width={200}
                    flexGrow={1}
                    label={intl.formatMessage(appButtonsMessages.filter)}
                    dataKey="structurePath"
                    cellRenderer={this.renderFilterInput}
                  />
                  <Column
                    width={80}
                    label={intl.formatMessage(appFieldsMessages.operation)}
                    dataKey="operation"
                    className="white-pre"
                    cellRenderer={this.renderOperationInput}
                  />
                  <Column
                    width={120}
                    label={intl.formatMessage(appFieldsMessages.value)}
                    dataKey="structureValue"
                    className="white-pre"
                    cellRenderer={this.renderValueInput}
                  />
                  <Column
                    width={100}
                    label={intl.formatMessage(settingsMessages.sequenceScope)}
                    dataKey="isSequenceScope"
                    className="text-center"
                    headerClassName="text-center"
                    cellRenderer={this.checkboxCellRender}
                  />
                  <Column
                    width={80}
                    label=""
                    className="text-right"
                    cellRenderer={this.renderRemoveButton}
                    dataKey="name"
                  />
                </Table>
              )}
            </AutoSizer>
          </Container>
          <Container className="field flex-row">
            <div className="flex-grow" />
            <Button type="primary" loading={isSubmitting} disabled={hasError} onClick={this.handleSaveClick}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        </form>
      </Container>
    );
  }
}

const LogSequenceScopeSetting = injectIntl(LogSequenceScopeSettingCore);

export default connect(
  (state: State) => {
    const { loaderStatus } = state.app;
    const { logSequenceStructureList, logSequenceScopeList } = state.settings;
    return { loaderStatus, logSequenceStructureList, logSequenceScopeList };
  },
  { loadLogSequenceScopeList, saveLogSequenceScopeList, removeLogSequenceScopeList },
)(LogSequenceScopeSetting);
