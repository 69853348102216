/* @flow */
import React from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { SmileOutlined, DeleteOutlined, HomeOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import {
  Empty,
  Select,
  Spin,
  Button,
  notification,
  Pagination,
  Tooltip,
  Menu,
  DatePicker,
  Popconfirm,
  Checkbox,
  message,
  Breadcrumb,
} from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import {
  Defaults,
  parseLocation,
  // getLoadStatus,
  parseJSON,
  LogRenderers,
  CellRenderers,
  CausalParser,
  buildUrl,
  sleep,
  downloadFile,
  EventRenderers,
  ExportModal,
} from '../../../common/utils';
import { BaseUrls } from '../../app/Constants';
import { Container, AutoSizer, List, CellMeasurerCache, CellMeasurer, Dropdown, Popover } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo, ActionTypes as AppActionTypes } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/dashboard/actions';
import { colorMap } from '../../share';

import { appFieldsMessages, appButtonsMessages, appMessages, appMenusMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';

type Props = {
  refresh: Number,
  handleRefresh: Function,
  handleCustomerNameChange: Function,
  // eslint-disable-next-line
  handleEnvironmentChange: Function,
  handleSystemIdChange: Function,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: String,
  userList: Array<Object>,
  // eslint-disable-next-line
  projects: Array<Object>,
  projectDisplayMap: Object,
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  globalInfo: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,

  globalSystemMatchedPredictions: Array<Object>,
  currentTheme: String,
};

class GlobalSystemIncidentPredictionMatchedViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;

    this.listHeaderHeight = 50;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 50,
    });

    this.state = {
      startTimeObj: moment.utc(startTime, Defaults.DateFormat),
      endTimeObj: moment.utc(endTime, Defaults.DateFormat),
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      reloadSystem: null,
      isLoading: true,
      isDeleting: false,
      isLoaded: false,
      page: 1,
      pageSize: 20,
      componentFilter: undefined,
      instanceFilter: undefined,
      patternIdFilter: undefined,

      eventList: [],

      showTakeLogActionModal: false,
      actionName: null,
      actionIncident: null,
    };
    this.localEventList = [];
    this.componentListOptions = [];
    this.instanceListOptions = [];
    this.patternListOptions = [];

    this.eventListExport = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { reloadSystem } = this.state;
    const { location, globalSystemMatchedPredictions } = this.props;
    const prevQuery = parseLocation(prevProps.location);
    const query = parseLocation(location);
    if (
      prevProps.refresh !== this.props.refresh ||
      prevState.reloadSystem !== reloadSystem ||
      prevQuery.environmentId !== query.environmentId ||
      prevQuery.customerName !== query.customerName ||
      prevQuery.systemId !== query.systemId
    ) {
      this.reloadData(this.props);
    } else if (prevProps.globalSystemMatchedPredictions !== globalSystemMatchedPredictions) {
      this.parseData(this.props);
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData(props) {
    const { createLoadAction, location, isAdmin, globalInfo } = props;
    const { startTime, endTime, environmentId, customerName, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId && systemInfo) {
      this.setState({ isLoading: true, isLoaded: false }, () => {
        if (!systemInfo.hasAllInstanceInfo) {
          createLoadAction(
            AppActionTypes.LOAD_INFO_SYSTEM,
            {
              startTime: endTime,
              endTime,
              environmentId,
              systemName: systemId,
              anomalyInstanceOnly: false,
            },
            false,
            true,
            this.callbackHandleSystemUpdate,
          );
        } else {
          createLoadAction(
            ActionTypes.LOAD_MATCHED_INCIDENT_PREDICTIONS,
            {
              environmentId,
              customerName: systemInfo.ownerUserName,
              systemId,
              operation: 'content',
              chainReq: JSON.stringify({
                startTime: moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf(),
                endTime: moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf(),
              }),
            },
            false,
            false,
            this.callbackSetState,
          );
        }
      });
    }
  }

  @autobind
  callbackSetState() {
    this.setState({ isLoading: false, isLoaded: true });
  }

  @autobind
  callbackHandleSystemUpdate() {
    // reset reloadSystem
    this.setState({ reloadSystem: moment.utc().valueOf() });
  }

  @autobind
  async parseData(props) {
    const { location, projects, globalInfo, globalSystemMatchedPredictions } = props;
    const { sortBy, sortDirection } = this.state;

    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    let eventList = globalSystemMatchedPredictions || [];
    let allInstances = [];
    let patternIds = [];
    eventList = R.map((event) => {
      const { predictionMatchTime, matchedIncident, predictedIncident, matchedCount } = event;

      // get time info
      const { predictionTime, matchedTime, leadTime } = predictionMatchTime || {};

      // prediction
      const {
        incidentData: predictionIncidentData,
        incidentKey,
        patternId: predictionPatternId,
        patternName: predictionPatternName,
        rootCause: predictionRootCause,
      } = predictedIncident;
      const { timestamp, incidentCompositeKey } = incidentKey || {};
      const predictedOccurrenceTime = moment.utc(timestamp).valueOf();
      const {
        id: predictionInstance,
        projectName: predictionProjectName,
        userName: predictionOwner,
      } = incidentCompositeKey || {};
      let predictionRootCauseInfo = null;
      if (predictionRootCause) {
        predictionRootCauseInfo = parseJSON(predictionRootCause);
      }
      const predictionComponent = get(instanceComponentMap, predictionInstance, predictionInstance);

      let { predictionSourceInfo } = predictedIncident;
      predictionSourceInfo = parseJSON(predictionSourceInfo) || [];
      const rootCausePatternIds = [];
      predictionSourceInfo = R.map((item) => {
        const { sourceProjectName } = item;
        const project = R.find((project) => project.projectShortName === sourceProjectName, projects || []);

        const sourceDetail = parseJSON(item.sourceDetail) || {};
        const { nid } = sourceDetail;
        rootCausePatternIds.push(nid);
        let { type } = sourceDetail;
        let { isLogType, eventType } = CausalParser.getRelationLogType(type);
        if (project && project.isDeployment) {
          type = 'deployment';
          isLogType = true;
          eventType = 'deployment';
        }
        return { ...item, sourceDetail: { ...sourceDetail, type, isLogType, eventType }, patternId: nid };
      }, predictionSourceInfo);

      // matched
      const {
        incidentData: matchedIncidentData,
        incidentKey: matchedIncidentKey,
        patternId: matchedPatternId,
        patternName: matchedPatternName,
        rootCause: matchedRootCause,
      } = matchedIncident;
      const {
        id: matchedInstance,
        projectName: matchedProjectName,
        userName: matchedOwner,
      } = get(matchedIncidentKey, 'incidentCompositeKey', {});
      let matchedRootCauseInfo = null;
      if (matchedRootCause) {
        matchedRootCauseInfo = parseJSON(matchedRootCause);
      }
      const matchedComponent = get(instanceComponentMap, matchedInstance, matchedInstance);

      const instanceList = [predictionInstance, matchedInstance];
      allInstances = [...allInstances, ...instanceList];
      const paternList = [predictionPatternId, matchedPatternId];
      patternIds = [...patternIds, ...paternList];

      const { patternNameStr: predictionPatternNameStr } = Defaults.PatternIdNameStr(
        { patternName: predictionPatternName, patternId: predictionPatternId },
        { hasFullName: true },
      );
      const { patternNameStr: matchedPatternNameStr } = Defaults.PatternIdNameStr(
        { patternName: matchedPatternName, patternId: matchedPatternId },
        { hasFullName: true },
      );

      return {
        predictedIncident,
        matchedIncident,
        instanceList,
        paternList,
        rootCausePatternIds,
        predictionTime,
        predictedOccurrenceTime,
        matchedTime,
        leadTime,
        matchedCount,

        predictionPatternNameStr,
        predictionPatternId,
        predictionPatternName,
        predictionIncidentData,
        predictionProjectName,
        predictionOwner,
        predictionComponent,
        predictionInstance,
        predictionRootCauseInfo,
        predictionSourceInfo,

        matchedPatternNameStr,
        matchedPatternId,
        matchedPatternName,
        matchedIncidentData,
        matchedProjectName,
        matchedOwner,
        matchedComponent,
        matchedInstance,
        matchedRootCauseInfo,
      };
    }, eventList);
    this.eventListExport = eventList;

    allInstances = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(allInstances));
    this.instanceListOptions = R.map((item) => ({ value: item, label: item }), allInstances);
    patternIds = R.sort((a, b) => a - b, R.uniq(patternIds));
    this.patternListOptions = R.map((patternId) => ({ value: patternId, label: patternId }), patternIds);

    let localEventList = this.filterData(eventList, this.state);
    localEventList = this.sortData(localEventList, sortBy, sortDirection);

    this.localEventList = localEventList;
    this.setState({ eventList });
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('predictionInstance')), R.ascend(R.prop('predictionPatternId'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  filterData(eventList, state) {
    const { instanceFilter, patternIdFilter } = state;
    let filterList = eventList || [];

    if (instanceFilter) {
      filterList = R.filter((event) => event.instanceList.includes(instanceFilter), filterList);
    }
    if (patternIdFilter) {
      filterList = R.filter((event) => event.paternList.includes(patternIdFilter), filterList);
    }

    return filterList;
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    const disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    this.setState({ startTimeObj, timeChange, disableRefresh, tooltipVisibleReload }, () => {
      if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
    });
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    const disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    this.setState({ endTimeObj, timeChange, disableRefresh, tooltipVisibleReload }, () => {
      if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
    });
  }

  @autobind
  onChangeFilterComponent(componentFilter) {
    this.setState({ componentFilter }, () => {
      const { eventList, sortBy, sortDirection } = this.state;
      let localEventList = this.filterData(eventList, this.state);
      localEventList = this.sortData(localEventList, sortBy, sortDirection);

      this.localEventList = localEventList;
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  onChangeFilterInstance(instanceFilter) {
    this.setState({ instanceFilter, page: 1 }, () => {
      const { eventList, sortBy, sortDirection } = this.state;
      let localEventList = this.filterData(eventList, this.state);
      localEventList = this.sortData(localEventList, sortBy, sortDirection);

      this.localEventList = localEventList;
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  onChangeFilterPatternId(patternIdFilter) {
    this.setState({ patternIdFilter, page: 1 }, () => {
      const { eventList, sortBy, sortDirection } = this.state;
      let localEventList = this.filterData(eventList, this.state);
      localEventList = this.sortData(localEventList, sortBy, sortDirection);

      this.localEventList = localEventList;
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  handleEventClick(rowData) {}

  @autobind
  renderListItem(events) {
    return ({ key, index: rowIndex, style, parent }) => {
      const rowData = events[rowIndex];
      if (!rowData) return null;

      const { intl } = this.props;
      const {
        predictionTime,
        matchedTime,
        leadTime,
        matchedCount,

        rootCausePatternIds,
        predictionPatternNameStr,
        matchedPatternNameStr,
      } = rowData;

      const active = false;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div
            className={`event-list-row ${active ? 'active' : ''}${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
            style={{ ...style, minHeight: 40, cursor: 'pointer' }}
            onClick={() => this.handleEventClick(rowData)}
          >
            <div className="row-column" style={{ width: 40 }}>
              {this.renderCheckbox(rowData)}
            </div>
            <div className="row-column" style={{ width: 120 }}>
              {moment.utc(predictionTime).format(Defaults.ShortTimeFormat)}
            </div>
            <div className="row-column" style={{ width: 120 }}>
              {moment.utc(matchedTime).format(Defaults.ShortTimeFormat)}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {CellRenderers.humanizeDuration({
                period: leadTime,
                intl,
                showZero: true,
                showSeconds: true,
                showSecondsWithZeroMin: true,
              })}
            </div>
            <div className="row-column" style={{ width: 70 }}>
              {matchedCount}
            </div>

            <div className="row-column" style={{ width: 90, flex: 1 }}>
              {this.renderPattern(R.join(', ', rootCausePatternIds || []))}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {this.renderRootCauseDetails(rowData)}
            </div>
            <div className="row-column" style={{ width: 90, flex: 1 }}>
              {this.renderPattern(predictionPatternNameStr)}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {this.renderDetails(rowData)}
            </div>
            <div className="row-column" style={{ width: 90, flex: 1 }}>
              {this.renderPattern(matchedPatternNameStr)}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {this.renderMatchedIncidentDetails(rowData)}
            </div>
            <div className="row-column" style={{ width: 95 }}>
              {this.renderControl(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderCheckbox(rowData) {
    const { selected } = rowData;
    return (
      <Checkbox
        checked={selected}
        onChange={(e) => {
          rowData.selected = e.target.checked;
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        }}
      />
    );
  }

  @autobind
  renderPattern(patternNameStr) {
    return (
      <Popover content={patternNameStr} placement="right" mouseEnterDelay={0.3}>
        <div className="hidden-line-with-ellipsis inline-block max-width">{patternNameStr}</div>
      </Popover>
    );
  }

  @autobind
  renderDetails(rowData) {
    const { intl, credentials, projectDisplayMap, currentTheme } = this.props;
    const {
      predictionPatternNameStr,
      predictionProjectName,
      predictionOwner,
      predictionComponent,
      predictionInstance,
      predictionIncidentData: content,
      predictionRootCauseInfo,
    } = rowData;
    const projectName =
      credentials.userName !== predictionOwner ? `${predictionProjectName}@${predictionOwner}` : predictionProjectName;
    const { metricName, sign } = predictionRootCauseInfo || {};
    const isHigher = sign ? sign === 'higher' || sign === 'positive' : null;
    let rawDataJson;
    try {
      rawDataJson = JSON.parse(content);
    } catch (error) {
      // console.debug(error)
    }

    return (
      <Popover
        placement="right"
        content={
          <div
            className="overflow-y-auto"
            style={{
              maxWidth: 480,
              maxHeight: 350,
              padding: 0,
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            }}
          >
            <div className="full-width">
              <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </div>
              {get(projectDisplayMap, projectName, projectName)}
            </div>
            <div className="full-width">
              <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </div>
              {predictionComponent}
            </div>
            <div className="full-width">
              <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.instance)}:
              </div>
              {predictionInstance}
            </div>
            <div className="full-width">
              <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.pattern)}:
              </div>
              {predictionPatternNameStr}
            </div>
            <div className="light-label bold" style={{ minWidth: 120 }}>
              {intl.formatMessage(eventMessages.shortDescription)}:
            </div>
            {metricName && (
              <div className="flex-row flex-center-align" style={{ wordBreak: 'break-all' }}>
                <span className="light-label bold" style={{ marginRight: 8, wordBreak: 'normal' }}>
                  {intl.formatMessage(appFieldsMessages.metric)}:
                </span>
                <span>{metricName}</span>
                {isHigher && <i className="icon up arrow" style={{ color: 'red' }} />}
                {!isHigher && <i className="icon down arrow" style={{ color: 'blue' }} />}
              </div>
            )}
            {!metricName && (
              <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                {!rawDataJson &&
                  R.join(
                    '\n',
                    R.filter((x) => Boolean(x), (content || '').split('\n')),
                  )}
                {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
              </div>
            )}
          </div>
        }
        trigger="click"
      >
        <Button size="small">{intl.formatMessage(appFieldsMessages.details)}</Button>
      </Popover>
    );
  }

  @autobind
  renderRootCauseDetails(rowData) {
    const { intl, location, credentials, projectDisplayMap, globalInfo, currentTheme } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    const { predictionSourceInfo } = rowData;

    return (
      <Popover
        placement="right"
        content={
          <div
            className="overflow-y-auto"
            style={{
              maxWidth: 480,
              maxHeight: 350,
              padding: 0,
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            }}
          >
            {R.addIndex(R.map)((item, index) => {
              const {
                sourceDetail,
                sourceProjectName,
                sourceProjectOwner,
                sourceInstanceName,
                patternId,
                metricInstanceName,
              } = item;
              const { type, content, metricDirection, sign, isLogType, eventType } = sourceDetail || {};
              const projectName =
                credentials.userName !== sourceProjectOwner
                  ? `${sourceProjectName}@${sourceProjectOwner}`
                  : sourceProjectName;
              let rawDataJson;
              try {
                rawDataJson = JSON.parse(content);
              } catch (error) {
                // console.debug(error)
              }

              let direction = metricDirection;
              if (R.isNil(direction) && sign) direction = sign;
              if (direction === 'positive') {
                direction = 'higher';
              } else if (direction === 'negative') {
                direction = 'lower';
              }
              const isHigher = direction ? direction.toLowerCase() === 'higher' : null;

              return (
                <div key={index} className="flex-col" style={{ marginBottom: 16 }}>
                  <div className="full-width">
                    <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                      {intl.formatMessage(eventMessages.rootCauseHop)}:
                    </div>
                    {index + 1}
                  </div>
                  <div className="hidden-line-with-ellipsis" style={{ display: 'inline-block', maxWidth: '100%' }}>
                    <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.project)}:
                    </div>
                    {get(projectDisplayMap, projectName, projectName)}
                  </div>
                  <div className="hidden-line-with-ellipsis" style={{ display: 'inline-block', maxWidth: '100%' }}>
                    <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.component)}:
                    </div>
                    {get(instanceComponentMap, metricInstanceName || sourceInstanceName, sourceInstanceName)}
                  </div>
                  <div className="hidden-line-with-ellipsis" style={{ display: 'inline-block', maxWidth: '100%' }}>
                    <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.instance)}:
                    </div>
                    {sourceInstanceName}
                  </div>
                  <div className="full-width">
                    <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.pattern)}:
                    </div>
                    {patternId}
                  </div>
                  <div className="full-width">
                    <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.type)}:
                    </div>
                    {isLogType
                      ? CellRenderers.logTypeRenderer({ intl, rowData: { type: eventType } })
                      : intl.formatMessage(appFieldsMessages.metric)}
                  </div>

                  <div className="light-label bold" style={{ minWidth: 120 }}>
                    {intl.formatMessage(eventMessages.shortDescription)}:
                  </div>
                  {type === 'Metric' && (
                    <div className="flex-row flex-center-align" style={{ wordBreak: 'break-all' }}>
                      <span className="light-label bold" style={{ marginRight: 8, wordBreak: 'normal' }}>
                        {intl.formatMessage(appFieldsMessages.metric)}:
                      </span>
                      <span>{content}</span>
                      {isHigher && <i className="icon up arrow" style={{ color: 'red' }} />}
                      {!isHigher && <i className="icon down arrow" style={{ color: 'blue' }} />}
                    </div>
                  )}

                  {type !== 'Metric' && (
                    <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                      {!rawDataJson &&
                        R.join(
                          '\n',
                          R.filter((x) => Boolean(x), (content || '').split('\n')),
                        )}
                      {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                    </div>
                  )}
                </div>
              );
            }, predictionSourceInfo)}
          </div>
        }
        trigger="click"
      >
        <Button size="small">{intl.formatMessage(appFieldsMessages.details)}</Button>
      </Popover>
    );
  }

  @autobind
  renderMatchedIncidentDetails(rowData) {
    const { intl, credentials, projectDisplayMap, currentTheme } = this.props;
    const {
      matchedPatternNameStr,
      matchedIncidentData: content,
      matchedProjectName,
      matchedOwner,
      matchedComponent,
      matchedInstance,
      matchedRootCauseInfo,
    } = rowData;
    const projectName =
      credentials.userName !== matchedOwner ? `${matchedProjectName}@${matchedOwner}` : matchedProjectName;
    const { metricName, sign } = matchedRootCauseInfo || {};
    const isHigher = sign ? sign === 'higher' || sign === 'positive' : null;
    let rawDataJson;
    try {
      rawDataJson = JSON.parse(content);
    } catch (error) {
      // console.debug(error)
    }

    return (
      <Popover
        placement="right"
        content={
          <div
            className="overflow-y-auto"
            style={{
              maxWidth: 480,
              maxHeight: 350,
              overflow: 'auto',
              padding: 0,
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            }}
          >
            <div className="full-width">
              <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </div>
              {get(projectDisplayMap, projectName, projectName)}
            </div>
            <div className="full-width">
              <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </div>
              {matchedComponent}
            </div>
            <div className="full-width">
              <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.instance)}:
              </div>
              {matchedInstance}
            </div>
            <div className="full-width">
              <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.pattern)}:
              </div>
              {matchedPatternNameStr}
            </div>
            <div className="light-label bold" style={{ minWidth: 120 }}>
              {intl.formatMessage(eventMessages.shortDescription)}:
            </div>
            {metricName && (
              <div className="flex-row flex-center-align" style={{ wordBreak: 'break-all' }}>
                <span className="light-label bold" style={{ marginRight: 8, wordBreak: 'normal' }}>
                  {intl.formatMessage(appFieldsMessages.metric)}:
                </span>
                <span>{metricName}</span>
                {isHigher && <i className="icon up arrow" style={{ color: 'red' }} />}
                {!isHigher && <i className="icon down arrow" style={{ color: 'blue' }} />}
              </div>
            )}
            {!metricName && (
              <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                {!rawDataJson &&
                  R.join(
                    '\n',
                    R.filter((x) => Boolean(x), (content || '').split('\n')),
                  )}
                {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
              </div>
            )}
          </div>
        }
        trigger="click"
      >
        <Button size="small">{intl.formatMessage(appFieldsMessages.details)}</Button>
      </Popover>
    );
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;
    return (
      <Dropdown
        name={intl.formatMessage(eventMessages.actions)}
        itemClick={({ key }) => {
          if (key === 'details') {
            this.handleJumpClick(rowData);
          } else if (key === 'setPatternName') {
            this.handleChangePatternNameClick(rowData);
          }
        }}
      >
        <>
          <Menu.Item key="details">{intl.formatMessage(appFieldsMessages.details)}</Menu.Item>
          <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item>
        </>
      </Dropdown>
    );
  }

  @autobind
  handleJumpClick(rowData) {
    const { location } = this.props;
    const { environmentId, customerName, systemId } = parseLocation(location);
    const {
      predictionTime,
      predictionPatternId,
      predictionProjectName,
      predictionInstance,
      predictionRootCauseInfo,
      predictionOwner,
    } = rowData;
    const startTime = moment.utc(predictionTime).format(Defaults.DateFormat);
    const { metricName } = predictionRootCauseInfo || {};
    const eventTimestamp = rowData?.predictedIncident?.incidentKey?.timestamp
      ? moment.utc(rowData?.predictedIncident?.incidentKey?.timestamp).valueOf()
      : predictionTime;

    const query = {
      environmentId,
      customerName,
      systemId,
      startTime,
      endTime: startTime,

      // hideIgnore: false,
      typeFilter: 'matchedIncident',

      eventProjectName: predictionProjectName,
      eventInstanceName: predictionInstance,
      eventPatternId: predictionPatternId,
      eventRootCauseMetric: metricName,
      eventPredictionTime: predictionTime,
      eventTimestamp,
      eventOwner: predictionOwner,
    };
    return window.open(buildUrl(BaseUrls.GlobalSystemPrediction, {}, query), '_blank');
  }

  @autobind
  handleChangePatternNameClick(event) {
    const {
      predictionProjectName,
      predictionOwner,
      predictionInstance,
      predictionPatternId,
      predictionPatternName,
      predictedOccurrenceTime,
      predictionRootCauseInfo,
    } = event;
    const fullProjectName = `${predictionProjectName}@${predictionOwner}`;
    const actionIncident = {
      ...event,
      fullProjectName,
      instanceName: predictionInstance,
      patternId: predictionPatternId,
      patternName: predictionPatternName,
      isLog: true,
      type: 'Incident',
      timestamp: predictedOccurrenceTime,
      predictFlag: true,
    };

    // metric
    if (predictionRootCauseInfo) {
      actionIncident.isLog = false;
      actionIncident.rootCauseJson = {
        rootCauseDetailsArr: [predictionRootCauseInfo],
      };
    }

    this.setState({ showTakeLogActionModal: true, actionName: 'setPatternName', actionIncident });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    this.setState({ showTakeLogActionModal: false, actionName: null, actionIncident: null }, () => {
      this.reloadData(this.props);
    });
  }

  @autobind
  handleIncidentRemove(events) {
    const { intl, credentials, location, globalInfo } = this.props;

    this.setState({ isDeleting: true });

    const deleteIncidentList = R.filter((item) => item.selected, events);
    const incidentList = [];
    R.forEach((item) => {
      const patternId = get(item, ['predictedIncident', 'patternId']);
      const incidentCompositeKey = get(item, ['predictedIncident', 'incidentKey', 'incidentCompositeKey'], {});
      const predictionSourceInfo = get(item, ['predictionSourceInfo'], []);
      const sourceKeyList = [];
      R.forEach((sourceInfo) => {
        const { sourceKey } = sourceInfo || {};
        sourceKeyList.push(sourceKey);
      }, predictionSourceInfo);
      const { sourceProjectName, sourceProjectOwner } = predictionSourceInfo[0] || {};

      incidentList.push({
        ...incidentCompositeKey,
        patternId,
        sourceKeyList,
        sourceProjectName,
        sourceProjectOwner,
      });
    }, deleteIncidentList);

    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    this.props.updateLastActionInfo();
    fetchDelete(getEndpoint('insightIncidents'), {
      ...credentials,
      customerName: systemInfo.ownerUserName,
      environmentName: environmentId,
      systemName: systemId,
      incidentList: JSON.stringify(incidentList),
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.reloadData(this.props);
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${msg}`);
        }
        this.setState({ isDeleting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isDeleting: false });
      });
  }

  @autobind
  handleExport() {
    this.setState({ showExportModal: true });
  }

  @autobind
  handleExportOk({ setLoading, exportCategory, email }) {
    if (exportCategory === 'CSV') {
      return this.handleExportCSV(setLoading);
    } else {
      return this.handleExportEmail(setLoading, email);
    }
  }

  @autobind
  async handleExportCSV(setLoading) {
    // start download data
    setLoading(true);
    await sleep(300);

    const { intl, location, globalInfo } = this.props;
    const { startTime, endTime, systemId, environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    const systemName = systemInfo ? systemInfo.name : systemId;
    const fname = `${systemName} ${startTime}~${endTime} correctly predicted incidents.csv`;
    let csvString = `${R.join(',', [
      'Prediction time',
      'Detection time',
      'Lead Time',
      'Count',
      'Root cause pattern ID list',
      'Root cause details',
      'Predicted incident pattern ID',
      'Predicted incident details',
      'Matched incident pattern ID',
      'Matched incident details',
    ])}\r\n`;

    const csvData = [];

    let eventListExport = [...(this.eventListExport || [])];
    eventListExport = R.sortWith(
      [R.ascend(R.prop('predictionInstance')), R.ascend(R.prop('predictionPatternId'))],
      eventListExport,
    );

    // root causes
    R.forEach((rowData) => {
      // get incident pattern name
      const {
        predictionTime,
        matchedTime,
        leadTime,
        matchedCount,
        rootCausePatternIds,
        predictionSourceInfo,
        predictionPatternNameStr,
        matchedPatternNameStr,
      } = rowData;

      const rootCauseList = [];
      R.addIndex(R.forEach)((item, idx) => {
        const { sourceDetail, sourceProjectName, sourceInstanceName, patternId, metricInstanceName } = item;
        const { type, content, avgValue, percentage, metricDirection, eventType } = sourceDetail || {};
        let data = '';
        if (type === 'Metric') {
          data = EventRenderers.BuildMetricAnomalySummary({
            event: { rootCauseMetric: content, metricValue: avgValue, pct: percentage, direction: metricDirection },
          });
        } else {
          data = content || '';
        }
        rootCauseList.push({
          'Root Cause Hop': idx + 1,
          'Source project': sourceProjectName,
          'Source component': get(instanceComponentMap, metricInstanceName || sourceInstanceName, sourceInstanceName),
          'Source instance': sourceInstanceName,
          'Pattern ID': patternId,
          'Pattern type': eventType,
          'Short description': data,
        });
      }, predictionSourceInfo || []);

      let data = '';
      // predciton
      const {
        predictionIncidentData,
        predictionProjectName,
        predictionComponent,
        predictionInstance,
        predictionRootCauseInfo,
      } = rowData;
      const { metricName: pm, sign: ps } = predictionRootCauseInfo || {};
      data = '';
      if (pm) {
        data = EventRenderers.BuildMetricAnomalySummary({
          event: { metricName: pm, sign: ps },
        });
      } else {
        data = predictionIncidentData || '';
      }
      const predictionDetails = {
        Project: predictionProjectName,
        Component: predictionComponent,
        Instance: predictionInstance,
        Pattern: predictionPatternNameStr,
        'Short description': data,
      };

      // matched
      const { matchedIncidentData, matchedProjectName, matchedComponent, matchedInstance, matchedRootCauseInfo } =
        rowData;
      const { metricName: mm, sign: ms } = matchedRootCauseInfo || {};
      data = '';
      if (mm) {
        data = EventRenderers.BuildMetricAnomalySummary({
          event: { metricName: mm, sign: ms },
        });
      } else {
        data = matchedIncidentData || '';
      }
      const matchedDetails = {
        Project: matchedProjectName,
        Component: matchedComponent,
        Instance: matchedInstance,
        Pattern: matchedPatternNameStr,
        'Short description': data,
      };

      csvData.push(
        R.join(',', [
          moment.utc(predictionTime).format(Defaults.ShortTimeFormat),
          moment.utc(matchedTime).format(Defaults.ShortTimeFormat),
          `"${R.replace(
            /"/g,
            '""',
            CellRenderers.humanizeDuration({
              period: leadTime,
              intl,
              showZero: true,
              showSeconds: true,
              showSecondsWithZeroMin: true,
            }),
          )}"`,
          matchedCount,
          `"${R.replace(/"/g, '""', R.join(', ', rootCausePatternIds || []))}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(rootCauseList, null, 4))}"`,
          `"${R.replace(/"/g, '""', predictionPatternNameStr)}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(predictionDetails, null, 4))}"`,
          `"${R.replace(/"/g, '""', matchedPatternNameStr)}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(matchedDetails, null, 4))}"`,
        ]),
      );
    }, eventListExport);
    csvString += R.join('\r\n', csvData);

    // download csv file
    downloadFile(csvString, fname);

    // close modal after
    setLoading(false);
    this.setState({ showExportModal: false });
  }

  @autobind
  handleExportEmail(setLoading, email) {
    const { intl, location, credentials, globalInfo } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime, systemId, environmentId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();

    setLoading(true);
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('insightIncidents'), {
      ...credentials,
      environmentName: environmentId,
      customerName: systemInfo.ownerUserName,
      systemName: systemId,
      startTime: startTimestamp,
      endTime: endTimestamp,
      operation: 'export',
      email: JSON.stringify(R.map((e) => R.trim(e), R.split(',', email || ''))),
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ showExportModal: false });
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${msg}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        setLoading(false);
      });
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          this.localEventList = this.sortData(this.localEventList, name, sortDir);

          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const { intl, location, userList, userInfo, globalInfo } = this.props;
    const query = parseLocation(location);
    const { customerName, environmentId, systemId } = query;
    const {
      startTimeObj,
      endTimeObj,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
    } = this.state;
    const { isLoading, isDeleting, page, pageSize, instanceFilter, patternIdFilter } = this.state;
    const { actionIncident } = this.state;

    // get system list
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);

    const { sortBy, sortDirection } = this.state;
    const { isLoaded, eventList } = this.state;

    const hasResult = eventList.length > 0;
    const events = R.slice((page - 1) * pageSize, page * pageSize, this.localEventList);
    const hasErrorDelete = R.filter((item) => item.selected, events).length === 0;
    return (
      <Container
        fullHeight
        withGutter
        className={`global-view flex-col flex-min-height flex-min-width ${isLoaded ? ' loaded' : ''}`}
      >
        <Container breadcrumb className="flex-row">
          <div className="flex-grow flex-row flex-center-align">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, {}))}>
                  <HomeOutlined />
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalSystemPrediction, {}, { ...query }))}>
                  {intl.formatMessage(appMenusMessages.globalSystemPrediction)}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(appMenusMessages.globalSystemCorrectlyPredicted)}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Select
                  showSearch
                  size="small"
                  value={systemId}
                  style={{ width: 120 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleSystemIdChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ),
                    systemList || [],
                  )}
                </Select>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Button
              size="small"
              onClick={this.handleExport}
              style={{ marginLeft: 16 }}
              disabled={this.eventListExport.length === 0}
            >
              {intl.formatMessage(appButtonsMessages.export)}
            </Button>
          </div>
          <div className="flex-row">
            <Container className="flex-row" style={{ alignItems: 'center' }}>
              <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.startDate)}
              </span>
              <DatePicker
                size="small"
                allowClear={false}
                showToday={false}
                value={startTimeObj}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleStartTimeChange}
              />
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.endDate)}</span>
              <DatePicker
                size="small"
                allowClear={false}
                showToday={false}
                value={endTimeObj}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleEndTimeChange}
              />

              {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.environment)}
              </span>
              <Select
                size="small"
                value={environmentId}
                style={{ width: 140 }}
                onChange={this.props.handleEnvironmentChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.name} value={item.name} title={item.name}>
                      {item.id}
                    </Select.Option>
                  ),
                  globalInfo || [],
                )}
              </Select> */}
              {userInfo.isAdmin && (
                <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
              )}
              {userInfo.isAdmin && (
                <Select
                  showSearch
                  size="small"
                  value={customerName}
                  style={{ width: 100 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleCustomerNameChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              )}

              <div
                style={{ marginLeft: 8 }}
                onMouseEnter={() => {
                  if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                    this.setState({ tooltipVisibleReloadMouseOver: true });
                }}
                onMouseLeave={() => {
                  if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
                }}
              >
                <Tooltip
                  mouseEnterDelay={0.3}
                  placement="bottomRight"
                  visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                  title={
                    disableRefresh
                      ? 'Range of days <= 31'
                      : timeChange
                      ? intl.formatMessage(appMessages.clickToReload)
                      : null
                  }
                >
                  <Button
                    size="small"
                    disabled={disableRefresh}
                    onClick={() => this.props.handleRefresh({ startTimeObj, endTimeObj })}
                  >
                    {intl.formatMessage(appButtonsMessages.refresh)}
                  </Button>
                </Tooltip>
              </div>
            </Container>
          </div>
        </Container>

        <Container
          className="flex-grow flex-col flex-min-height content-bg"
          style={{ margin: '0 16px 8px 16px', borderRadius: 4 }}
        >
          <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
            {isLoaded && !hasResult && (
              <div className="flex-grow flex-row flex-center-align flex-center-justify">
                <Empty
                  image={<SmileOutlined style={{ fontSize: 100, color: colorMap.Health }} />}
                  description={
                    <span className="flex-col">
                      <span
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          lineHeight: '36px',
                        }}
                      >
                        There are currently no matched predicted incidents for this system
                      </span>
                      <span>Select a different system to view results</span>
                    </span>
                  }
                >
                  <a herf={null} onClick={() => this.props.handleRefresh()}>
                    {intl.formatMessage(appButtonsMessages.refresh)}
                  </a>
                </Empty>
              </div>
            )}
            {isLoaded && hasResult && (
              <div className="flex-grow flex-col flex-min-height">
                <div className="flex-row" style={{ padding: 8 }}>
                  <div className="flex-grow flex-row">
                    <Select
                      allowClear
                      showArrow={false}
                      showSearch
                      size="small"
                      style={{ width: 200, marginRight: 16 }}
                      placeholder={intl.formatMessage(appFieldsMessages.instance)}
                      value={instanceFilter}
                      optionFilterProp="value"
                      filterOption
                      onChange={this.onChangeFilterInstance}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <Select.Option key={item.value} label={item.label}>
                            {item.label}
                          </Select.Option>
                        );
                      }, this.instanceListOptions)}
                    </Select>
                    <Select
                      allowClear
                      showArrow={false}
                      showSearch
                      size="small"
                      style={{ width: 150, marginRight: 16 }}
                      placeholder={intl.formatMessage(appFieldsMessages.pattern)}
                      value={patternIdFilter}
                      optionFilterProp="value"
                      filterOption
                      onChange={this.onChangeFilterPatternId}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        );
                      }, this.patternListOptions)}
                    </Select>
                  </div>
                  <Pagination
                    size="small"
                    current={page}
                    total={this.localEventList.length}
                    pageSize={pageSize}
                    onChange={(page) =>
                      this.setState({ page }, () => {
                        this.cellMeasureCache.clearAll();
                        if (this.listNode) this.listNode.forceUpdateGrid();
                      })
                    }
                    showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                    showSizeChanger
                    pageSizeOptions={['20', '40', '60', '100', '500', '1000']}
                    onShowSizeChange={(page, pageSize) =>
                      this.setState({ page, pageSize }, () => {
                        this.cellMeasureCache.clearAll();
                        if (this.listNode) this.listNode.forceUpdateGrid();
                      })
                    }
                  />
                </div>
                <div className="flex-grow flex-min-height" style={{ padding: 8 }}>
                  <AutoSizer>
                    {({ width, height }) => (
                      <div className="event-list">
                        <div
                          className="event-list-header"
                          style={{
                            height: this.listHeaderHeight,
                            width,
                            paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                          }}
                        >
                          <div className="header-column" style={{ width: 40 }} />
                          <div
                            className="header-column"
                            style={{ width: 120 }}
                            onClick={this.headerClick('predictionTime')}
                          >
                            <span>{intl.formatMessage(eventMessages.predictionTime)}</span>
                            {this.sortIcon(sortBy, sortDirection, 'predictionTime')}
                          </div>
                          <div
                            className="header-column"
                            style={{ width: 120 }}
                            onClick={this.headerClick('matchedTime')}
                          >
                            <span>{intl.formatMessage(eventMessages.detectionTime)}</span>
                            {this.sortIcon(sortBy, sortDirection, 'matchedTime')}
                          </div>
                          <div className="header-column" style={{ width: 100 }} onClick={this.headerClick('leadTime')}>
                            <span>{intl.formatMessage(eventMessages.leadTime)}</span>
                            {this.sortIcon(sortBy, sortDirection, 'leadTime')}
                          </div>
                          <div
                            className="header-column"
                            style={{ width: 70 }}
                            onClick={this.headerClick('matchedCount')}
                          >
                            <span>{intl.formatMessage(eventMessages.count)}</span>
                            {this.sortIcon(sortBy, sortDirection, 'matchedCount')}
                          </div>

                          <div className="header-column break-word" style={{ width: 90, flex: 1 }}>
                            {intl.formatMessage(eventMessages.rootCausePatternIDList)}
                          </div>
                          <div className="header-column" style={{ width: 100 }}>
                            {intl.formatMessage(eventMessages.rootCauseDetails)}
                          </div>
                          <div
                            className="header-column break-word"
                            style={{ width: 90, flex: 1 }}
                            onClick={this.headerClick('predictionPatternId')}
                          >
                            <span>{intl.formatMessage(eventMessages.predictedIncidentPatternID)}</span>
                            {this.sortIcon(sortBy, sortDirection, 'predictionPatternId')}
                          </div>
                          <div className="header-column" style={{ width: 100 }}>
                            {intl.formatMessage(eventMessages.predictedIncidentDetails)}
                          </div>
                          <div
                            className="header-column break-word"
                            style={{ width: 90, flex: 1 }}
                            onClick={this.headerClick('matchedPatternId')}
                          >
                            <span>{intl.formatMessage(eventMessages.matchedIncidentPatternID)}</span>
                            {this.sortIcon(sortBy, sortDirection, 'matchedPatternId')}
                          </div>
                          <div className="header-column" style={{ width: 100 }}>
                            {intl.formatMessage(eventMessages.matchedIncidentDetails)}
                          </div>
                          <div className="header-column" style={{ width: 95 }} />
                        </div>
                        <List
                          className="event-list-grid"
                          ref={(listNode) => {
                            this.listNode = listNode;
                          }}
                          width={width}
                          height={height - this.listHeaderHeight}
                          rowCount={events.length}
                          overscanRowCount={10}
                          deferredMeasurementCache={this.cellMeasureCache}
                          rowHeight={this.cellMeasureCache.rowHeight}
                          rowRenderer={this.renderListItem(events)}
                          onScrollbarPresenceChange={({ horizontal, vertical }) => {
                            if (vertical) {
                              this.listNodeHeaderScrollbar = true;
                            } else {
                              this.listNodeHeaderScrollbar = false;
                            }
                            this.forceUpdate();
                          }}
                        />
                      </div>
                    )}
                  </AutoSizer>
                </div>
                <div className="flex-row flex-end-justify" style={{ padding: 8 }}>
                  <Popconfirm
                    placement="topRight"
                    title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                    onConfirm={() => this.handleIncidentRemove(events)}
                    onCancel={(event) => event.stopPropagation()}
                  >
                    <Button
                      size="small"
                      className="button-color-grey"
                      icon={<DeleteOutlined />}
                      loading={isDeleting}
                      disabled={hasErrorDelete}
                      onClick={(event) => event.stopPropagation()}
                    >
                      {intl.formatMessage(appButtonsMessages.remove)}
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            )}
          </Spin>
        </Container>

        {this.state.showExportModal && (
          <ExportModal
            intl={intl}
            onOk={this.handleExportOk}
            onClose={() => this.setState({ showExportModal: false })}
          />
        )}
        {this.state.showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={this.state.actionName}
            incident={actionIncident}
            projectName={actionIncident.fullProjectName}
            instanceGroup={environmentId}
            eventType={actionIncident.type}
            onClose={() => this.setState({ showTakeLogActionModal: false, actionName: null, actionIncident: null })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
      </Container>
    );
  }
}

const GlobalSystemIncidentPredictionMatchedView = injectIntl(GlobalSystemIncidentPredictionMatchedViewCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, projects, projectDisplayMap, globalInfo, currentTheme } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { credentials, userInfo } = state.auth;
    const { globalSystemMatchedPredictions } = state.dashboard;
    return {
      location,
      loadStatus,
      currentLocale,
      userList,
      projects,
      projectDisplayMap,
      credentials,
      userInfo,

      globalInfo,
      globalSystemMatchedPredictions,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(GlobalSystemIncidentPredictionMatchedView);
