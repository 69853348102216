/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import VLink from 'valuelink';
import { get, isFinite, isObject } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Tooltip as AntdTooltip, Button, Input as AntdInput } from 'antd';

import { Container, Select, Table, Column, CellMeasurerCache, Tooltip, AutoSizer } from '../../../lib/fui/react';
import {
  sortByString,
  ifIn,
  parseJSON,
  filterJsonByKeyValue,
  buildUrl,
  LogRenderers,
  calcAnomalyLevel,
  calcColorOfAnomalyLevel,
  Defaults,
  CellRenderers,
} from '../../../common/utils';
import { EChart } from '../../share';
import { eventMessages } from '../../../common/metric/messages';
import DateSelectModal from '../../metric/components/DateSelectModal';

type Props = {
  intl: Object,
  width: Number,
  height: Number,
  queryResult: Object,
  queryParams: Object,
  metadata: Object,
  currentTheme: String,
};

class RareLogEventCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.contentWidth = 770;
    this.gridOffsetY = 40;
    this.pieSize = 180;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.statsList = [];
    this.logEntryList = [];

    this.anomalyWordsOptions = [];
    this.timeInterval = [
      { label: '10 minutes', value: 10 * 60 * 1000 },
      { label: '30 minutes', value: 30 * 60 * 1000 },
      { label: '1 hour', value: 60 * 60 * 1000 },
      { label: '2 hours', value: 2 * 60 * 60 * 1000 },
      { label: '6 hours', value: 6 * 60 * 60 * 1000 },
      { label: '1 day', value: 24 * 60 * 60 * 1000 },
      { label: '1 week', value: 7 * 24 * 60 * 60 * 1000 },
    ];

    this.timeRender = ({ cellData }) => (cellData ? moment.utc(parseInt(cellData, 10)).format('YYYY-MM-DD HH:mm') : '');

    const { queryResult } = props;
    const instanceName = sortByString(R.keys(queryResult.data || {}))[0];

    this.state = {
      instanceName,
      anomalyKeyword: '',
      selectedStartTs: null,
      barExpand: true,
      barData: null,
      primaryKey: null,

      filterCount: null,
      filterStatsMap: {},
      selectStat: null,
      statsPieCharts: [],

      showTimeSelectModal: false,
    };
  }

  componentDidMount() {
    const { queryResult, queryParams } = this.props;
    this.reloadData(queryResult, queryParams, this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      const queryResult = nextProps.queryResult || {};

      let { instanceName } = this.state;
      const names = sortByString(R.keys(queryResult.data));
      if (!ifIn(instanceName, names)) {
        instanceName = names[0];
      }
      this.setState({ instanceName });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { queryResult, queryParams } = nextProps;
    if (
      this.props.queryResult !== nextProps.queryResult ||
      this.state.instanceName !== nextState.instanceName ||
      this.state.anomalyKeyword !== nextState.anomalyKeyword ||
      this.state.selectStat !== nextState.selectStat
    ) {
      this.reloadData(queryResult, queryParams, nextState);
    }
  }

  @autobind
  reloadData(queryResult, queryParams, state) {
    const { logEntryList, anomalyWordsOptions, primaryKey } = this.getAllLogEntryList(queryResult, state);
    const { statsPieCharts } = this.getStatsChart(queryResult, state);
    const { barData, selectedStartTs } = this.getBarData(queryParams, logEntryList);

    this.logEntryList = logEntryList;
    this.anomalyWordsOptions = anomalyWordsOptions;

    this.setState({ barData, selectedStartTs, statsPieCharts, primaryKey });
  }

  @autobind
  handleContentChanged() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind
  contentRender(props) {
    const { queryParams, currentTheme } = this.props;
    const { selectStat } = this.state;
    const { keyword } = queryParams || {};
    const keywords = R.map(
      (k) => ({ keyword: R.replace(/"/g, '', R.trim(k)) }),
      R.split(' ', R.replace(/(AND|OR)/g, ' ', keyword || '')),
    );

    if (selectStat) {
      keywords.push({ keyword: selectStat.name });
    }
    return CellRenderers.logContent(props, this.cellMeasureCache, {
      width: this.contentWidth,
      highlightWord: keywords,
      onChanged: this.handleContentChanged,
      currentTheme,
    });
  }

  @autobind
  handleClearCache() {
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind
  getAllLogEntryList(queryResult, { instanceName, anomalyKeyword, selectStat }) {
    let logEntryList = get(queryResult, ['data', instanceName], []);
    logEntryList = R.sort((a, b) => a.timestamp - b.timestamp, logEntryList);

    const primaryKeyList = get(queryResult, ['primaryKey'], []);
    const primaryKey = primaryKeyList.length > 0 ? primaryKeyList[0] : null;

    // get anomaly word options
    let anomalyWordsList = [];
    R.forEach((item) => {
      const { anomalyWords } = item;
      anomalyWordsList = [...anomalyWordsList, ...R.keys(anomalyWords)];
    }, logEntryList);
    anomalyWordsList = R.sort((a, b) => a.localeCompare(b), R.uniq(anomalyWordsList));
    const anomalyWordsOptions = R.map((keyword) => ({ value: keyword, label: keyword }), anomalyWordsList);

    // filter log entry
    if (anomalyKeyword) {
      logEntryList = R.filter((item) => R.keys(item.anomalyWords).indexOf(anomalyKeyword) >= 0, logEntryList);
    }
    if (selectStat) {
      logEntryList = R.filter((item) => {
        const content = parseJSON(R.toLower(item.rawData));
        if (isObject(content)) {
          return filterJsonByKeyValue(content, selectStat.key, selectStat.name);
        }
        return content.indexOf(selectStat.name) >= 0;
      }, logEntryList);
    }
    logEntryList = R.map((event) => {
      let { anomalyWords } = event;
      anomalyWords = R.mapObjIndexed((val) => {
        let { color, fontColor } = calcColorOfAnomalyLevel(calcAnomalyLevel(val.ratio));
        if (val.direction !== 'positive') {
          color = 'blue';
          fontColor = 'white';
        }
        return { ...val, title: `Deviation: ${val.ratio}%`, color, fontColor };
      }, anomalyWords);
      const dayStartTs = moment.utc(event.timestamp).startOf('day').valueOf();
      return { ...event, anomalyWords, dayStartTs, data: event.rawData };
    }, logEntryList);

    return { logEntryList, anomalyWordsOptions, primaryKey };
  }

  @autobind
  getStatsChart(queryResult, { instanceName, filterCount, filterStatsMap }) {
    const statsObj = get(queryResult, ['stats', instanceName], {});
    // get stats info
    const statsPieCharts = [];
    R.forEachObjIndexed((val, key) => {
      let showPie = true;
      let data = [];
      R.forEachObjIndexed((count, name) => data.push({ key, name, count }), val);
      data = R.sortWith([R.descend(R.prop('count')), R.ascend(R.prop('name'))], data);

      // label filter item
      if (filterStatsMap[key]) {
        const keywordFilterStr = R.toLower(filterStatsMap[key]);
        showPie = false;
        data = R.map((item) => {
          if (R.toLower(item.name).indexOf(keywordFilterStr) >= 0) {
            if (!showPie) showPie = true;
            return item;
          }
          return { ...item, hide: true };
        }, data);
      }

      if (showPie) {
        if (filterCount) data = R.take(filterCount, data);

        // sepicel number value
        let hasNumber = false;
        let maxData = null;
        let minData = null;
        data = R.map((item) => {
          const value = item.name ? Number(item.name) : item.name;
          const newItem = { ...item, name: item.name, nameNumber: value };
          if (isFinite(value)) {
            hasNumber = true;
            maxData = maxData ? (value > maxData.nameNumber ? newItem : maxData) : newItem;
            minData = minData ? (value < minData.nameNumber ? newItem : minData) : newItem;
          }
          return newItem;
        }, data);

        let option = {};
        if (hasNumber) {
          option = LogRenderers.GetEffectScatterOption({
            key,
            data,
            maxData,
            minData,
            isMapping: false,
          });
        } else {
          option = {
            backgroundColor: 'transparent',
            tooltip: {
              backgroundColor: 'var(--component-background)',
              borderColor: 'transparent',
              textStyle: {
                color: 'var(--text-color)',
              },
              trigger: 'item',
              confine: true,
              transitionDuration: 0,
            },
            series: [
              {
                animation: false,
                minAngle: 1,
                name: 'Value',
                type: 'pie',
                radius: ['40%', '90%'],
                // selectedMode: 'single',
                hoverAnimation: false,
                data: R.map((item) => {
                  const { count, hide } = item;
                  const itemStyle = {};
                  if (hide) {
                    itemStyle.opacity = 0.1;
                    itemStyle.color = 'gray';
                  }
                  return { ...item, value: count, itemStyle };
                }, data),
                itemStyle: {
                  emphasis: {
                    shadowBlur: 4,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                  normal: {
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
            ],
          };
        }

        statsPieCharts.push({
          key,
          option,
          hasNumber,
          maxData,
          minData,
        });
      }
    }, statsObj);
    return { statsPieCharts };
  }

  @autobind
  getBarData(queryParams, logEntryList) {
    const { startTimeObj, endTimeObj } = queryParams;
    const startT = startTimeObj.valueOf();
    const endT = endTimeObj.valueOf();
    if (!startT || !endT) {
      return undefined;
    }

    const dataTimeMap = {};
    R.forEach((num) => {
      dataTimeMap[startT + num * 86400000] = 0;
    }, R.range(0, (endT - startT) / 86400000));
    R.forEach((log) => {
      const { dayStartTs } = log;
      dataTimeMap[dayStartTs] += 1;
    }, logEntryList);
    let data = R.toPairs(dataTimeMap);
    data = R.sortBy(
      R.prop(0),
      R.map((item) => [Number(item[0]), item[1]], data),
    );
    data = R.map(
      (d) => ({
        name: moment.utc(d[0]).format(Defaults.DateFormat),
        timestamp: d[0],
        value: d[1],
      }),
      data,
    );
    const option = {
      backgroundColor: 'transparent',
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        textStyle: {
          color: 'var(--text-color)',
        },
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          label: {
            show: true,
          },
        },
        formatter: (params, ticket, callback) => {
          const { name, seriesName, color, value } = params[0];
          return `${name}</br><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${color}"></span>${seriesName}: ${value}</br>click to filter rare event`;
        },
      },
      title: {
        show: false,
      },
      legend: {
        show: false,
      },
      grid: {
        left: 40,
        right: 20,
        top: 20,
        bottom: 25,
      },
      xAxis: {
        type: 'category',
        data: R.map((d) => d.name, data),
      },
      yAxis: {
        type: 'value',
        name: 'Count',
        nameGap: 8,
      },
      dataZoom: [
        {
          show: false,
          height: 20,
          bottom: 5,
        },
        {
          type: 'inside',
        },
      ],
      series: [
        {
          name: 'Count',
          data,
          type: 'bar',
          itemStyle: {
            color: '#f2711c',
          },
        },
      ],
    };

    let selectedStartTs = null;
    const hasDataList = R.filter((item) => item.value > 0, data);
    if (hasDataList.length > 0) {
      selectedStartTs = hasDataList[0].timestamp;
    }

    return { barData: { option }, selectedStartTs };
  }

  @autobind
  handleStatFilter({ key, event, value, isSearch }) {
    if (isSearch || event.type !== 'change') {
      const { queryResult } = this.props;
      const { filterStatsMap } = this.state;
      filterStatsMap[key] = value;
      const { statsPieCharts } = this.getStatsChart(queryResult, { ...this.state, filterStatsMap });
      this.setState({ filterStatsMap, statsPieCharts });
    }
  }

  @autobind
  actionsRenderer({ rowData }) {
    const { intl } = this.props;
    const { neuronId } = rowData;
    if (!isFinite(neuronId)) {
      return null;
    }
    return (
      <Button style={{ fontSize: 12 }} onClick={() => this.handleTrendAnalysisClick(rowData)}>
        {intl.formatMessage(eventMessages.context)}
      </Button>
    );
  }

  @autobind
  handleTrendAnalysisClick(rowData) {
    this.setState({
      showTimeSelectModal: true,
      activeIncident: rowData,
    });
  }

  @autobind
  onCloseTimeSelect(props) {
    const { startTimestamp, endTimestamp } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState({ showTimeSelectModal: false }, () => {
        const { queryParams } = this.props;
        const { projectName } = queryParams;
        const { instanceName, activeIncident, primaryKey } = this.state;
        const { neuronId, rawData } = activeIncident || {};

        let primaryValue;
        const content = parseJSON(rawData);
        if (isObject(content) && primaryKey) {
          primaryValue = content[primaryKey];
        }
        const query = {
          t: '953de6a33d8a4b96ac9c100bf69ba3fc',
          projectName,
          instanceName,
          startTime: moment.utc(startTimestamp).startOf('day').valueOf(),
          endTime: moment.utc(endTimestamp).endOf('day').valueOf(),
          keyword: primaryValue ? `"${primaryValue}"` : '',
          pattern: primaryValue ? '' : neuronId,
          primaryKey: primaryKey ? R.toLower(primaryKey) : null,
          primaryValue: primaryValue ? R.toLower(primaryValue) : null,
        };
        window.open(buildUrl('/query', {}, query), '_blank');
      });
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  onClickBarChartArrow(barExpand) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ barExpand: !barExpand });
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdateGrid();
      }
    };
  }

  @autobind
  onChartClick(item) {
    const { data } = item;
    const { timestamp } = data;
    this.setState({ selectedStartTs: timestamp });
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind
  renderBarChart({ barData, logEntryList, barExpand, currentTheme }) {
    return (
      <div className="flex-grow flex-col">
        <Container className="flex-row" style={{ padding: '0 0 6px 0' }}>
          <Tooltip title={`Click for ${barExpand ? 'hidden' : 'show'} bar chart`} placement="top">
            <div style={{ cursor: 'pointer' }} onClick={this.onClickBarChartArrow(barExpand)}>
              {barExpand && <i className="icon angle double down" style={{ fontSize: 14 }} />}
              {!barExpand && <i className="icon angle double right" style={{ fontSize: 14 }} />}
              {`Click for ${barExpand ? 'hidden' : 'show'} bar chart`}
            </div>
          </Tooltip>
        </Container>
        {barExpand && (
          <Container className="flex-col">
            {barData && (
              <EChart
                style={{ height: 120 }}
                option={barData.option}
                onClick={this.onChartClick}
                theme={currentTheme}
              />
            )}
          </Container>
        )}
        <div className="flex-grow flex-col content log-event-group" style={{ fontSize: 12, padding: '0 0 0 10px' }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCache.rowHeight}
                rowCount={logEntryList.length}
                rowGetter={({ index }) => logEntryList[index]}
                ref={(c) => (this.dataTable = c)}
              >
                <Column width={130} label="DateTime" dataKey="timestamp" cellRenderer={this.timeRender} />
                <Column
                  width={800}
                  flexGrow={1}
                  label="Message"
                  dataKey="data"
                  cellRenderer={this.contentRender}
                  className="raw-data"
                />
                <Column width={120} label="Investigate" dataKey="timestamp" cellRenderer={this.actionsRenderer} />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }

  @autobind
  renderStatsChart({ statsPieCharts, currentTheme }) {
    const filterCountLink = VLink.state(this, 'filterCount').onChange(this.handleFilterChange);
    statsPieCharts = R.sortWith([R.ascend(R.prop('key'))], statsPieCharts);
    return (
      <div
        className="flex-col"
        style={{
          width: 200,
          marginRight: 8,
          borderRight: '1px solid lightgray',
        }}
      >
        <h4 style={{ textAlign: 'center' }}>Statistics</h4>
        <div className="flex-row" style={{ padding: '0 8px 8px 8px' }}>
          <div className="flex-row" style={{ width: 60, alignItems: 'center', fontWeight: 500 }}>
            Top K:
          </div>
          <div className="flex-grow">
            <Select
              options={[
                { value: 10, label: '10' },
                { value: 100, label: '100' },
              ]}
              valueLink={filterCountLink}
              clearable
            />
          </div>
        </div>

        <div className="flex-grow">
          <AutoSizer>
            {({ width, height }) => (
              <div
                className="flex-col"
                style={{
                  width,
                  height,
                  overflowY: 'auto',
                }}
              >
                {R.addIndex(R.map)((pie, index) => {
                  return (
                    <div key={index} style={{ width: this.pieSize, marginBottom: 16 }}>
                      <AntdTooltip title={pie.key} placement="left">
                        <div
                          style={{
                            margin: '0 8px',
                            textAlign: 'center',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {pie.key}
                          {pie.hasNumber && <br />}
                          {pie.hasNumber && (
                            <span>
                              Max:{pie.maxData.nameNumber}, Min:{pie.minData.nameNumber}
                            </span>
                          )}
                        </div>
                      </AntdTooltip>
                      <div style={{ margin: '0 8px' }}>
                        <AntdInput.Search
                          placeholder="input search text"
                          size="small"
                          allowClear
                          onSearch={(value, event) =>
                            this.handleStatFilter({ key: pie.key, event, value, isSearch: true })
                          }
                          onChange={(event) => this.handleStatFilter({ key: pie.key, event })}
                        />
                      </div>
                      <EChart
                        height={this.pieSize}
                        option={pie.option}
                        onClick={this.onStatsClick}
                        theme={currentTheme}
                      />
                      <hr style={{ borderTop: '1px solid #ccc' }} />
                    </div>
                  );
                }, statsPieCharts)}
              </div>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }

  @autobind
  handleFilterChange(filterCount) {
    const { queryResult } = this.props;
    this.setState({ filterCount }, () => {
      const { statsPieCharts } = this.getStatsChart(queryResult, { ...this.state, filterCount });
      this.setState({ statsPieCharts });
    });
  }

  @autobind
  onStatsClick(item) {
    const { key, name } = item.data;
    this.setState({
      selectStat: { key: R.toLower(key), name: R.toLower(name) },
    });
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  @autobind
  onClearStat() {
    this.setState({ selectStat: null });
    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdateGrid();
    }
  }

  render() {
    const { width, height, queryResult, currentTheme } = this.props;
    const { barData, selectedStartTs, barExpand, statsPieCharts, selectStat, showTimeSelectModal, activeIncident } =
      this.state;

    const instanceNameLink = VLink.state(this, 'instanceName').onChange(this.handleClearCache);
    const instanceNameListOptions = R.map((i) => ({ label: i, value: i }), sortByString(R.keys(queryResult.data)));
    const anomalyKeywordLink = VLink.state(this, 'anomalyKeyword').onChange(this.handleClearCache);
    const startTs = selectedStartTs;
    let { logEntryList } = this;
    logEntryList = R.filter((x) => x.dayStartTs === startTs, logEntryList);
    const { timestamp } = activeIncident || {};

    return (
      <Container className="flex-col flex-min-height" style={{ width, height }}>
        <Container className="toolbar flex-row" style={{ paddingTop: 12 }}>
          <div className="flex-grow" />
          {selectStat && (
            <div className="flex-row" style={{ fontSize: 13, alignItems: 'center' }}>
              <div className="flex-row" style={{ fontWeight: 500, width: 80 }}>
                Stat Value:
              </div>
              <span style={{ color: 'blue' }}>{`${selectStat.key}->${selectStat.name}`}</span>
              <i className="window close icon" style={{ cursor: 'pointer' }} onClick={this.onClearStat} />
            </div>
          )}
          <Container className="section">
            <span className="label">Instance</span>
            <Select options={instanceNameListOptions} valueLink={instanceNameLink} style={{ width: 220 }} />
            <span className="label">Anomaly Keyword</span>
            <Select
              options={this.anomalyWordsOptions}
              valueLink={anomalyKeywordLink}
              style={{ width: 150 }}
              clearable
            />
          </Container>
        </Container>
        <div className="flex-grow flex-row">
          {this.renderStatsChart({ statsPieCharts, instanceNameListOptions, instanceNameLink, currentTheme })}
          {this.renderBarChart({
            barData,
            logEntryList,
            barExpand,
            instanceNameListOptions,
            instanceNameLink,
            anomalyKeywordLink,
            currentTheme,
          })}
        </div>

        {showTimeSelectModal && (
          <DateSelectModal startTimestamp={timestamp} endTimestamp={timestamp} onClose={this.onCloseTimeSelect} />
        )}
      </Container>
    );
  }
}

const RareLogEvent = injectIntl(RareLogEventCore);
export default connect((state: State) => {
  const { currentTheme } = state.app;
  return { currentTheme };
}, {})(RareLogEvent);
