/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { postBugRepository } from '../../apis';
import { apiEpicErrorHandle } from '../../errors';
import { getLoaderEpicAction } from '../../utils';
import { updateLastActionInfo } from '../../app/actions';
import { updateBugRepository } from '../actions';

const saveBugRepositoryEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('SAVE_BUG_REPOSITORY').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(postBugRepository(credentials, params))
      .concatMap(() => Observable.concat(Observable.of(updateBugRepository(params)), hideLoader))
      .catch((err) => {
        return Observable.concat(apiEpicErrorHandle(err), hideLoader);
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default saveBugRepositoryEpic;
