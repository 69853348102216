/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { Button, TreeSelect, Alert, Form, Checkbox, Divider, Input, Select } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Container, AutoSizer, Table, Column } from '../../../../lib/fui/react';
import { MetricParser } from '../../../../common/utils';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

class EndpointSettingAzureCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoadingKey: false,
      isLoading: false,
      isUpdateKey: false,
      isSubmitting: false,
      errorMessageKey: '',
      errorMessage: '',

      azureClientId: '',
      azureClientSecret: '',
      azureTenantId: '',
      subscriptionId: '',

      resources: [],
      selectedMetrics: [],
      workspaceId: null,
      logTables: [],

      // options
      resourceOptions: [],
      resourceList: [],
      resourceMetricMap: {},
      worksapceOptions: [],
      worksapceTableMap: {},
      worksapceTableFieldMap: {},
    };
    this.azureClientId = '';
    this.azureClientSecret = '';
    this.azureTenantId = '';
    this.subscriptionId = '';
  }

  componentDidMount() {
    this.reloadKey(this.props);
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadKey(nextProps);
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadKey(props) {
    const { credentials, projectName, currentProject } = props;

    this.setState({ isLoadingKey: true });
    const cloudType = get(currentProject, ['cloudType']);
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const { success, message, azureClientId, azureClientSecret, azureTenantId, subscriptionId } = data || {};
        if (success === undefined || success) {
          this.azureClientId = azureClientId;
          this.azureClientSecret = azureClientSecret;
          this.azureTenantId = azureTenantId;
          this.subscriptionId = subscriptionId;
          this.setState({
            isLoadingKey: false,
            errorMessageKey: undefined,

            azureClientId: azureClientId || '',
            azureClientSecret: azureClientSecret || '',
            azureTenantId: azureTenantId || '',
            subscriptionId: subscriptionId || '',
          });
        } else {
          this.setState({
            isLoadingKey: false,
            errorMessageKey: message,

            azureClientId: '',
            azureClientSecret: '',
            azureTenantId: '',
            subscriptionId: '',
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoadingKey: false,
          errorMessageKey: errMsg || String(err),

          azureClientId: '',
          azureClientSecret: '',
          azureTenantId: '',
          subscriptionId: '',
        });
      });
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName, currentProject } = props;
    const isMetric = get(currentProject, ['isMetric']);

    this.setState({ isLoading: true });
    return fetchGet(getEndpoint('azure-project-setting'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const {
          success,
          message: errMsg,
          allAzureLogInfos: logInfos,
          allAzureMetricInfos: metricInfos,
          azureMetricInfos,
          workspaceId,
          logTables,
        } = data || {};
        if (success === undefined || success) {
          if (isMetric) {
            // parse metric
            this.parseDataMetric(metricInfos, azureMetricInfos);
          } else {
            // parselog
            this.parseDataLog(logInfos, workspaceId, logTables);
          }

          this.setState({
            isLoading: false,
            errorMessage: undefined,
          });
        } else {
          this.setState({
            isLoading: false,
            errorMessage: errMsg,
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoading: false,
          errorMessage: errMsg || String(err),
        });
      });
  }

  @autobind
  parseDataMetric(metricInfos, azureMetricInfos) {
    const resourceOptions = [];
    const resourceList = [];
    const resourceMetricMap = {};
    const metricResourceMap = {};
    R.forEach((metricInfo) => {
      const { resourceName, resourceId, metrics } = metricInfo;
      resourceOptions.push({ value: resourceId, label: resourceName });
      resourceList.push(resourceId);
      resourceMetricMap[resourceId] = metrics;
    }, metricInfos || []);
    R.forEachObjIndexed((metrics, resourceId) => {
      R.forEach((metric) => {
        metricResourceMap[metric] = resourceId;
      }, metrics);
    }, resourceMetricMap);

    const resources = [];
    let selectedMetrics = [];
    R.forEach((item) => {
      const { resourceId, metrics } = item || {};
      resources.push(resourceId);
      selectedMetrics = [...selectedMetrics, ...(metrics || [])];
    }, azureMetricInfos || []);

    // build tree data
    let allMetrics = [];
    R.forEachObjIndexed((metrics) => {
      allMetrics = [...allMetrics, ...metrics];
    }, R.pick(resources, resourceMetricMap));
    const { treeData, treeDataKeys } = MetricParser.getMetricTreeStructure(allMetrics, false);

    this.setState({
      resources,
      selectedMetrics,
      treeData,
      // eslint-disable-next-line react/no-unused-state
      treeDataKeys,
      resourceOptions,
      resourceList,
      resourceMetricMap,
      metricResourceMap,
    });
  }

  @autobind
  parseDataLog(logInfos, workspaceId, logTables) {
    const worksapceOptions = [];
    const worksapceTableMap = {};
    const worksapceTableFieldMap = {};
    R.forEach((logInfo) => {
      const { logDetailMap, tables, workspaceName, workspaceId } = logInfo;
      worksapceOptions.push({ value: workspaceId, label: workspaceName });
      worksapceTableMap[workspaceId] = tables;
      worksapceTableFieldMap[workspaceId] = {};

      R.forEachObjIndexed((val) => {
        const { logDetail } = val || {};
        R.forEach((item) => {
          const { Type, ...rest } = item || {};
          const fields = R.keys(rest);
          if (!R.has(Type, worksapceTableFieldMap[workspaceId])) {
            worksapceTableFieldMap[workspaceId][Type] = fields;
          }
        }, logDetail || []);
      }, logDetailMap);
    }, logInfos || []);

    const tableOptions = R.map((value) => ({ value, label: value }), worksapceTableMap[workspaceId] || []);

    this.setState({
      workspaceId,
      logTables,

      worksapceOptions,
      worksapceTableMap,
      worksapceTableFieldMap,
      tableOptions,
    });
  }

  @autobind
  handleUpdateKey() {
    const { credentials, projectName } = this.props;

    this.setState({ isUpdateKey: true });
    const { azureClientId, azureClientSecret, azureTenantId, subscriptionId } = this.state;
    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      azureClientId,
      azureClientSecret,
      azureTenantId,
      subscriptionId,
    })
      .then((data) => {
        this.setState({ isUpdateKey: false, errorMessageKey: undefined });
        this.reloadKey(this.props);
        this.reloadData(this.props);
      })
      .catch((err) => {
        this.setState({ isUpdateKey: false, errorMessageKey: String(err) });
      });
  }

  @autobind
  handleResourcesChange(resources) {
    this.setState({ resources }, () => {
      const { resourceMetricMap } = this.state;

      // build tree data
      let allMetrics = [];
      R.forEachObjIndexed((metrics) => {
        allMetrics = [...allMetrics, ...metrics];
      }, R.pick(resources, resourceMetricMap));
      const { treeData, treeDataKeys } = MetricParser.getMetricTreeStructure(allMetrics, false);
      // eslint-disable-next-line react/no-unused-state
      this.setState({ treeData, treeDataKeys, selectedMetrics: [] });
    });
  }

  @autobind
  handleSubmit() {
    const { credentials, projectName, currentProject } = this.props;
    const isMetric = get(currentProject, ['isMetric']);

    this.setState({ isSubmitting: true });
    const { selectedMetrics, workspaceId, logTables } = this.state;
    const { resourceOptions, metricResourceMap } = this.state;
    // parse metricInfos
    const metricInfos = [];
    if (isMetric) {
      const metricInfosMap = {};
      R.forEach((metric) => {
        const resourceId = metricResourceMap[metric];
        if (resourceId) {
          if (!R.has(resourceId, metricInfosMap)) metricInfosMap[resourceId] = [];
          metricInfosMap[resourceId].push(metric);
        }
      }, selectedMetrics);
      R.forEachObjIndexed((metrics, resourceId) => {
        const { label: resourceName } = R.find((item) => item.value === resourceId, resourceOptions) || {};
        metricInfos.push({ resourceId, resourceName, metrics });
      }, metricInfosMap);
    }

    return fetchPost(getEndpoint('azure-project-setting'), {
      ...credentials,
      projectName,
      ...(isMetric
        ? { azureMetricInfoList: JSON.stringify(metricInfos || []) }
        : { workspaceId, logTables: JSON.stringify(logTables) }),
    })
      .then((data) => {
        this.setState({ isSubmitting: false, errorMessage: undefined });
        this.reloadData(this.props);
      })
      .catch((err) => {
        this.setState({ isSubmitting: false, errorMessage: String(err) });
      });
  }

  @autobind
  handleAddLogTables() {
    const { logTables } = this.state;
    const newLogTables = [...logTables, { tableName: null, instanceField: null, timestampField: null }];
    this.setState({ logTables: newLogTables }, () => {
      if (this.tableNode) {
        this.tableNode.scrollToRow(newLogTables.length - 1);
      }
    });
  }

  @autobind
  renderLogTableName({ rowData, cellData, rowIndex }) {
    const { tableOptions } = this.state;
    return (
      <Select
        allowClear={false}
        showSearch
        options={tableOptions || []}
        value={cellData}
        onChange={(tableName) => {
          this.setState((prevState) => ({
            logTables: update(prevState.logTables, {
              [rowIndex]: {
                $set: {
                  ...(prevState.logTables[rowIndex] || {}),
                  tableName,
                  instanceField: null,
                  timestampField: null,
                },
              },
            }),
          }));
        }}
      />
    );
  }

  @autobind
  renderLogInstance({ rowData, cellData, rowIndex }) {
    const { workspaceId, worksapceTableFieldMap } = this.state;
    const { tableName } = rowData;
    const fieldOptions = R.map(
      (value) => ({ value, label: value }),
      get(worksapceTableFieldMap, [workspaceId, tableName], []),
    );
    return (
      <Select
        allowClear={false}
        showSearch
        options={fieldOptions || []}
        value={cellData}
        onChange={(instanceField) => {
          this.setState((prevState) => ({
            logTables: update(prevState.logTables, {
              [rowIndex]: {
                $set: {
                  ...(prevState.logTables[rowIndex] || {}),
                  instanceField,
                },
              },
            }),
          }));
        }}
      />
    );
  }

  @autobind
  renderLogTimestamp({ rowData, cellData, rowIndex }) {
    const { workspaceId, worksapceTableFieldMap } = this.state;
    const { tableName } = rowData;
    const fieldOptions = R.map(
      (value) => ({ value, label: value }),
      get(worksapceTableFieldMap, [workspaceId, tableName], []),
    );
    return (
      <Select
        allowClear={false}
        showSearch
        options={fieldOptions || []}
        value={cellData}
        onChange={(timestampField) => {
          this.setState((prevState) => ({
            logTables: update(prevState.logTables, {
              [rowIndex]: {
                $set: {
                  ...(prevState.logTables[rowIndex] || {}),
                  timestampField,
                },
              },
            }),
          }));
        }}
      />
    );
  }

  @autobind
  controlRenderer({ rowIndex }) {
    const { intl } = this.props;

    return (
      <Button
        size="small"
        className="button-color-grey"
        onClick={() => {
          const { logTables } = this.state;
          const newLogTables = R.remove(rowIndex, 1, logTables);
          this.setState({ logTables: newLogTables });
        }}
      >
        {intl.formatMessage(appButtonsMessages.remove)}
      </Button>
    );
  }

  render() {
    const { intl, currentProject } = this.props;
    const { isLoadingKey, isLoading, isUpdateKey, isSubmitting, errorMessageKey, errorMessage } = this.state;
    const {
      azureClientId,
      azureClientSecret,
      azureTenantId,
      subscriptionId,

      resources,
      selectedMetrics,
      workspaceId,
      logTables,
    } = this.state;
    const { treeData, resourceOptions, resourceList, worksapceOptions, worksapceTableMap } = this.state;
    const isMetric = get(currentProject, ['isMetric']);

    const hasErrKey = !azureClientId || !azureClientSecret || !azureTenantId || !subscriptionId;
    const hasChangeKey =
      this.azureClientId !== azureClientId ||
      this.azureClientSecret !== azureClientSecret ||
      this.azureTenantId !== azureTenantId ||
      this.subscriptionId !== subscriptionId;
    const hasFormErr = isMetric
      ? selectedMetrics.length === 0
      : !workspaceId ||
        logTables.length === 0 ||
        R.reduce(
          R.or,
          false,
          R.map((item) => {
            return !item.tableName || !item.instanceField || !item.timestampField;
          }, logTables),
        );
    return (
      <Container fullHeight className={`overflow-y-auto ${isLoadingKey || isLoading ? ' loading' : ''}`}>
        <div className="full-height" style={{ width: 800 }}>
          <Form layout="vertical">
            <Form.Item
              label="Client Id"
              validateStatus={!azureClientId ? 'error' : 'success'}
              help={!azureClientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={azureClientId}
                onChange={(e) => this.setState({ azureClientId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client Secret"
              validateStatus={!azureClientSecret ? 'error' : 'success'}
              help={!azureClientSecret ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={azureClientSecret}
                onChange={(e) => this.setState({ azureClientSecret: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Tenant Id"
              validateStatus={!azureTenantId ? 'error' : 'success'}
              help={!azureTenantId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={azureTenantId}
                onChange={(e) => this.setState({ azureTenantId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Subscription Id"
              validateStatus={!subscriptionId ? 'error' : 'success'}
              help={!subscriptionId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={subscriptionId}
                onChange={(e) => this.setState({ subscriptionId: e.target.value })}
              />
            </Form.Item>

            {errorMessageKey && (
              <Alert message="Error" description={errorMessageKey} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasErrKey || !hasChangeKey}
                loading={isUpdateKey}
                onClick={this.handleUpdateKey}
              >
                Update private key
              </Button>
            </Form.Item>

            {isMetric && (
              <>
                <Form.Item
                  label="Resources"
                  validateStatus={R.isEmpty(resources) ? 'error' : 'success'}
                  help={R.isEmpty(resources) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    mode="multiple"
                    allowClear
                    autoClearSearchValue={false}
                    maxTagCount={1}
                    value={resources}
                    onChange={this.handleResourcesChange}
                    showSearch
                    dropdownRender={(menu) => {
                      const selectAll = R.difference(resourceList, resources).length === 0;
                      return (
                        <div>
                          <div
                            className="flex-row"
                            style={{ padding: '5px 12px' }}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selectAll}
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (checked) {
                                  this.handleResourcesChange(resourceList);
                                } else {
                                  this.handleResourcesChange([]);
                                }
                              }}
                            />
                            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                          </div>
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                  >
                    {R.map((item) => {
                      return (
                        <Select.Option className="hide-icon" key={item.value}>
                          <Checkbox
                            checked={resources.includes(item.value)}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {item.label}
                        </Select.Option>
                      );
                    }, resourceOptions)}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Metrics"
                  required
                  validateStatus={selectedMetrics.length === 0 ? 'error' : 'success'}
                  help={selectedMetrics.length === 0 ? 'This input is required.' : undefined}
                >
                  <TreeSelect
                    style={{ width: '100%' }}
                    allowClear
                    maxTagCount={1}
                    showSearch
                    autoClearSearchValue={false}
                    treeDefaultExpandAll={false}
                    treeCheckable
                    treeNodeLabelProp="title"
                    treeData={treeData || []}
                    value={selectedMetrics}
                    onChange={(selectedMetrics) => this.setState({ selectedMetrics })}
                  />
                </Form.Item>
              </>
            )}

            {!isMetric && (
              <>
                <Form.Item
                  label="Workspace"
                  validateStatus={!workspaceId ? 'error' : 'success'}
                  help={!workspaceId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    allowClear={false}
                    showSearch
                    options={worksapceOptions}
                    value={workspaceId}
                    onChange={(workspaceId) =>
                      this.setState({ workspaceId }, () => {
                        const tableOptions = R.map(
                          (value) => ({ value, label: value }),
                          worksapceTableMap[workspaceId] || [],
                        );
                        this.setState({ tableOptions, logTables: [] });
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label="Log Tables">
                  <div style={{ marginBottom: 8 }}>
                    <Button size="small" type="primary" icon={<PlusOutlined />} onClick={this.handleAddLogTables}>
                      {intl.formatMessage(appButtonsMessages.add)}
                    </Button>
                  </div>
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <Table
                        className="with-border"
                        width={width}
                        height={150}
                        headerHeight={30}
                        rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                        rowHeight={40}
                        rowCount={logTables.length}
                        rowGetter={({ index }) => logTables[index]}
                        ref={(tableNode) => {
                          this.tableNode = tableNode;
                        }}
                      >
                        <Column
                          width={120}
                          label="Table"
                          dataKey="tableName"
                          cellRenderer={this.renderLogTableName}
                          flexGrow={1}
                        />
                        <Column
                          width={120}
                          label="Instance Field"
                          dataKey="instanceField"
                          cellRenderer={this.renderLogInstance}
                          flexGrow={1}
                        />
                        <Column
                          width={120}
                          label="Timestamp Field"
                          dataKey="timestampField"
                          cellRenderer={this.renderLogTimestamp}
                          flexGrow={1}
                        />
                        <Column width={110} label="" dataKey="tableName" cellRenderer={this.controlRenderer} />
                      </Table>
                    )}
                  </AutoSizer>
                </Form.Item>
              </>
            )}

            {errorMessage && (
              <Alert message="Error" description={errorMessage} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasChangeKey || hasFormErr}
                loading={isSubmitting}
                onClick={this.handleSubmit}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    );
  }
}

const EndpointSettingAzure = injectIntl(EndpointSettingAzureCore);
export default connect((state) => {
  return {};
}, {})(EndpointSettingAzure);
