import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Alert, Button, Card, Divider, Input, Popover, Radio, Slider, Tooltip, Comment, Popconfirm } from 'antd';
import * as R from 'ramda';
import moment from 'moment';
import {
  AimOutlined,
  CaretDownOutlined,
  CloseOutlined,
  CodeSandboxOutlined,
  DatabaseOutlined,
  FileAddOutlined,
  FormOutlined,
  InstagramOutlined,
  RightSquareOutlined,
} from '@ant-design/icons';
import { parseInt, isNumber } from 'lodash';
import { Defaults } from '../../../common/utils';
import { Container, Modal } from '../../../lib/fui/react';
import { appFieldsMessages } from '../../../common/app/messages';
import { BuildEntityList, ServiceMapEntityFilter } from './ServiceMapEntityFilter';
import ServiceMapCanvas, { colorsRGB, formatScore, getIncidentCounts, scoreLevel } from './ServiceMapCanvas';
import { PinLegendIcon, RobotIcon } from '../../../lib/fui/icons';
import { getCoverageTitle } from './utils';

const k8sHostPrefix = 'k8s Hosts';
const timeInterval = 60; // minutes

const OneDay = 24 * 60 * 60 * 1000;

const NewNoteModal = ({ onClose, onSubmit }: Object) => {
  const [note, setNote] = useState();

  return (
    <Modal
      title="Enter note"
      width={480}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={() => onSubmit(note)}
    >
      <Input.TextArea allowClear showCount rows={6} value={note} onChange={(e) => setNote(e.target.value)} />
    </Modal>
  );
};

const ScoreContent = ({
  isK8s,
  selectedTime,
  entities,
  startTime,
  val,
  score,
  timeInterval,
  maxHeight,
  onAddNote,
  onDeleteNote,
  notes,
  userId,
  metricEntities,
  predictedMetricEntities,
  logEntities,
  predictedLogEntities,
  onEntityClick = () => {},
  activeEntity,
  nodeRootCauseMap,
  onChangeShowGPT = () => {},
}) => {
  const showNote = (entities || []).length > 0;
  const [newNote, setNewNote] = useState(false);
  return (
    <div className="flex-col" style={{ maxHeight: maxHeight - 8 }}>
      <div className="flex-row flex-center-align">
        <div style={{ marginRight: 4, width: 244 }} className="inline-block bold">
          <span className="p-r-4 font-18 m-b-12">Top anomalies</span>
          <Popover mouseEnterDelay={0.3} placement="top" content="Service map summary">
            <Button
              size="small"
              icon={<RobotIcon style={{ fontSize: 20, color: 'var(--primary-color)' }} />}
              style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}
              onClick={() => onChangeShowGPT(selectedTime.valueOf())}
            />
          </Popover>
          <Popover mouseEnterDelay={0.3} placement="top" content="Focus">
            <Button
              size="small"
              icon={<AimOutlined style={{ fontSize: 16, color: 'var(--primary-color)' }} />}
              style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)', marginLeft: 4 }}
              onClick={() => {
                if (activeEntity) {
                  const el = document.getElementById(activeEntity);
                  if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                }
              }}
            />
          </Popover>
        </div>
        <span className="text-right" />
      </div>
      <Divider style={{ margin: '4px 0 4px 0' }} />
      <div className="overflow-y-auto flex-grow" style={{ overflowY: 'auto' }}>
        {R.map((e) => {
          const { scoreRaw, id, parents } = e;
          const name = getCoverageTitle(e, isK8s, selectedTime);
          const metricSet = metricEntities[id] || [];
          const predictedMetricSet = predictedMetricEntities[id] || [];
          const logSet = logEntities[id] || [];
          const predictedLogSet = predictedLogEntities[id] || [];
          const isActive = id === activeEntity;
          const nameParts = name?.split('_') || [];
          const parts = activeEntity?.split('_') || [];
          const nodeName = parts[parts.length - 1] || activeEntity;
          const instanceDisplayName = parents[1]?.instanceDisplayName || e.instanceDisplayName;
          const activeMetrics = nodeRootCauseMap?.[nodeName]?.metricNames || {};
          const realTitle =
            nameParts.length > 1
              ? `${instanceDisplayName || nameParts[1]}/${nameParts[0]}`
              : instanceDisplayName || nameParts[0] || name;
          return (
            <Fragment key={id}>
              <div key="name" className="flex-row flex-center-align" style={{ fontSize: 14 }} id={id}>
                <div className="flex-grow flex-col clickable" onClick={() => onEntityClick(isActive ? null : e)}>
                  <Tooltip
                    title={realTitle}
                    mouseEnterDelay={0.3}
                    placement="left"
                    overlayStyle={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}
                  >
                    <div
                      className="inline-block bold flex-row flex-center-align"
                      style={{
                        marginRight: 4,
                        width: 180,
                        fontWeight: isActive ? 'bold' : 'normal',
                        ...(isActive ? { color: 'var(--primary-color)', fontSize: 15 } : {}),
                      }}
                    >
                      {isActive && (
                        <div
                          style={{
                            width: 4,
                            height: 18,
                            flexShrink: 0,
                            backgroundColor: 'var(--primary-color)',
                            marginRight: 4,
                          }}
                        />
                      )}
                      <div className="hidden-line-with-ellipsis flex-grow">{realTitle}</div>
                    </div>
                  </Tooltip>
                </div>
                <div className="text-right" style={{ width: 80 }}>
                  <span>{formatScore(scoreRaw)}</span>
                </div>
              </div>
              {metricSet.length > 0 &&
                R.map((m) => {
                  const isActiveMetric = activeMetrics[m];
                  return (
                    <div key={m} className="flex-row flex-center-align">
                      {isActiveMetric && <PinLegendIcon style={{ color: '#FF5142', fontSize: 12 }} />}
                      {!isActiveMetric && <span className="short-tag-blue">M</span>}
                      <div
                        className="flex-grow"
                        style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                      >
                        {m}
                      </div>
                    </div>
                  );
                }, metricSet)}
              {predictedMetricSet.length > 0 &&
                R.map(
                  (m) => (
                    <div key={m} className="flex-row flex-center-align">
                      <span className="short-tag-blue">M</span>
                      <div
                        className="flex-grow"
                        style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                      >
                        {m}
                      </div>
                    </div>
                  ),
                  predictedMetricSet,
                )}
              {logSet.length > 0 &&
                R.map(
                  (l) => (
                    <div key={l} className="flex-row flex-center-align">
                      <span className="short-tag-blue">L</span>
                      <div
                        className="flex-grow"
                        style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                      >
                        {l}
                      </div>
                    </div>
                  ),
                  logSet,
                )}
              {predictedLogSet.length > 0 &&
                R.map(
                  (l) => (
                    <div key={l} className="flex-row flex-center-align">
                      <span className="short-tag-blue">L</span>
                      <div
                        className="flex-grow"
                        style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                      >
                        {l}
                      </div>
                    </div>
                  ),
                  predictedLogSet,
                )}
            </Fragment>
          );
        }, entities || [])}
      </div>
      {showNote && (
        <div className="flex-grow flow-col pt-05" style={{ width: '100%', minHeight: maxHeight / 4 }}>
          <div className="font-18" style={{ marginTop: 12 }}>
            <span>Operator notes</span>
            <Tooltip title="Enter note" mouseEnterDelay={0.3}>
              <Button
                size="small"
                icon={<FileAddOutlined style={{ fontSize: 16 }} />}
                style={{
                  border: 'none',
                  backgroundColor: 'rgba(0,0,0,0)',
                }}
                onClick={() => {
                  setNewNote(true);
                }}
              />
            </Tooltip>
          </div>
          <Divider style={{ margin: '4px 0 4px 0' }} />
          <NoteList
            notes={notes}
            userId={userId}
            allowDelete
            onDelete={(userId) => {
              onDeleteNote(userId);
            }}
          />
        </div>
      )}
      {newNote && (
        <NewNoteModal
          onClose={() => setNewNote(false)}
          onSubmit={(note) => {
            onAddNote(note);
            setNewNote(false);
          }}
        />
      )}
    </div>
  );
};

const NoteList = ({ notes, userId, allowDelete, onDelete }) => {
  const [deleting, setDeleting] = useState(false);
  return (
    <div className="overflow-y-auto">
      {R.map((n) => {
        return (
          <Comment
            className="compact-comment"
            author={
              <span
                style={{
                  width: 84,
                  paddingRight: 4,
                  lineHeight: '24px',
                  verticalAlign: 'middle',
                  color: 'var(--text-color-secondary)',
                }}
                className="hidden-line-with-ellipsis inline-block"
              >
                {n?.userId}
              </span>
            }
            key={n?.noteAddTimestamp}
            content={n?.note}
            datetime={
              <div className="flex-row" style={{ alignItems: 'center' }}>
                <span style={{ color: 'var(--text-color-secondary)' }}>
                  {moment.utc(n?.noteAddTimestamp).format(Defaults.DateTimeFormat)}
                </span>
                {n?.userId === userId && allowDelete && (
                  <Popconfirm
                    placement="topLeft"
                    title={<div>Are you sure you would like to delete?</div>}
                    onConfirm={() => {
                      setDeleting(true);
                      onDelete(n?.userId);
                    }}
                    onCancel={(event) => event.stopPropagation()}
                  >
                    <Button
                      disabled={deleting}
                      size="small"
                      type="link"
                      style={{}}
                      onClick={(event) => event.stopPropagation()}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                )}
              </div>
            }
          />
        );
      }, R.sortWith([R.ascend(R.prop('noteAddTimestamp'))], notes || []))}
    </div>
  );
};
const getSelectIndex = (selectedTime, startTime) => {
  return Math.floor(((selectedTime || startTime).valueOf() - startTime.valueOf()) / (1000 * 60 * timeInterval));
};
const getSelectStartHour = (selectedTime, startTime) => {
  const h = Math.floor(((selectedTime || startTime).valueOf() - startTime.valueOf()) / (1000 * 60 * 60));
  return h < 0 ? 0 : h > 23 ? 23 : h;
};
const buildEntityTree = (
  isK8s,
  isK8sProject,
  allData,
  allDataMap,
  selectMap,
  startTime,
  selectedTime,
  userCurrentTime,
  predictionEndTime,
) => {
  const isToday =
    userCurrentTime &&
    startTime &&
    userCurrentTime > startTime.valueOf() &&
    userCurrentTime <= startTime.valueOf() + OneDay;

  const timeRangeCount = isToday
    ? Math.ceil((predictionEndTime - startTime.valueOf()) / (1000 * 60 * timeInterval * 12)) * 12
    : 24;

  const totalScores = R.range(0, (timeRangeCount * 60) / timeInterval).map(() => 0);
  const scoreEntities = R.range(0, (timeRangeCount * 60) / timeInterval).map(() => []);
  const incidentCounts = R.range(0, (timeRangeCount * 60) / timeInterval).map(() => []);
  const metricEntities = R.range(0, (timeRangeCount * 60) / timeInterval).map(() => []);
  const predictedMetricEntities = R.range(0, (timeRangeCount * 60) / timeInterval).map(() => []);
  const logEntities = R.range(0, (timeRangeCount * 60) / timeInterval).map(() => []);
  const predictedLogEntities = R.range(0, (timeRangeCount * 60) / timeInterval).map(() => []);

  const selectIndex = getSelectIndex(selectedTime || startTime.clone(), startTime);

  const getPredictedScoreMap = (en) => {
    const scoreMap = {};

    const predictedScoreMap = R.values(en?.predictedScoreMap || {})[0] || {};
    R.forEachObjIndexed((val, key) => {
      if (key > userCurrentTime) {
        scoreMap[key] = val;
      }
    }, predictedScoreMap);

    return predictedScoreMap;
  };
  const applyEntityStatus = (entity, isInstanceRoot) => {
    entity.scoreRaw = 0;
    entity.totalScoreRaw = 0;

    entity.isSelected = selectMap[entity.id];

    const isActive = (time, en) => {
      const { type } = en;
      const coverageMap = en?.k8CoverageMap?.coverageMap?.[en.name] || [];
      const scoreMap = R.values(en?.scoreMap || {})[0] || {};

      const predictedScoreMap = getPredictedScoreMap(en);

      const hasScore = !!R.find((s) => {
        const { timeStamp } = s;
        return timeStamp && timeStamp >= time && timeStamp <= time + timeInterval * 60 * 1000;
      }, R.values(scoreMap));

      const hasPredictedScore = !!R.find((s) => {
        const { timeStamp } = s;
        return (
          // timeStamp && timeStamp > userCurrentTime && timeStamp >= time && timeStamp <= time + timeInterval * 60 * 1000
          // remove prediction filter
          timeStamp && timeStamp >= time && timeStamp <= time + timeInterval * 60 * 1000
        );
      }, R.values(predictedScoreMap));

      const coverage = R.find((cov) => {
        const { s, e } = cov[1] || {};
        return s <= time && e >= time;
      }, coverageMap);
      const hasCoverage = !!coverage;

      if (type === 'component') {
        return true;
      } else if (type === 'instance') {
        return isInstanceRoot && !en?.isK8s ? true : isK8sProject ? hasScore || hasPredictedScore || hasCoverage : true;
      } else if (type === 'pod') {
        if (isInstanceRoot && !en?.isK8s) {
          return true;
        }

        if (coverage) {
          const { h } = coverage[0] || {};
          const hostInstance = allDataMap[`${k8sHostPrefix}_${h}`];
          if (!h || !hostInstance) {
            // console.info(`[IF] Pod ${en.name} has no host instance info.`, coverage);
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else if (type === 'container') {
        const parents = en.parents || [];
        if (parents.length > 0) {
          const pod = parents[parents.length - 1];
          return isActive(time, pod);
        } else {
          return true;
        }
      }
      return false;
    };

    entity.inactive = !isActive(selectedTime || startTime.clone(), entity);

    R.forEach((c) => {
      applyEntityStatus(c, isInstanceRoot);
    }, entity.children || []);

    const scoreMap = R.values(entity?.scoreMap || {})[0] || {};
    const predictedScoreMap = getPredictedScoreMap(entity);
    if (!entity.isSelected) {
      // Change whether any child is selected
      entity.isSelected = !!R.find((c) => c.isSelected, entity.allChildren || []);
    }

    const allScoreMap = { ...scoreMap, ...predictedScoreMap };
    const scoreMapValues = R.sortWith([R.ascend(R.prop('timeStamp'))], R.values(allScoreMap || {}));
    const { detectedIncidentCount, predictedIncidentCount } = getIncidentCounts(entity, selectedTime);
    for (let i = 0; i < scoreMapValues.length; i += 1) {
      const { timeStamp, score } = scoreMapValues[i];
      if (timeStamp) {
        const ts = moment.utc(timeStamp);
        const index = getSelectIndex(ts, startTime);
        if (index === selectIndex) {
          entity.scoreRaw = score || 0;
          entity.totalScoreRaw = entity.scoreRaw;
          break;
        } else if (index > selectIndex) {
          if (detectedIncidentCount > 0 || predictedIncidentCount > 0) {
            entity.scoreRaw = score || scoreMapValues[i - 1]?.score || 0;
            entity.totalScoreRaw = entity.scoreRaw;
          }
          break;
        }
      }
    }

    for (let i = 0; i < scoreMapValues.length; i += 1) {
      const { timeStamp, score } = scoreMapValues[i];
      if (timeStamp) {
        const ts = moment.utc(timeStamp);
        const index = getSelectIndex(ts, startTime);
        if (index >= 0 && index < scoreEntities.length) {
          const inactive = !isActive(moment.utc(timeStamp), entity);
          if (!inactive) {
            totalScores[index] += Number(score);
            if (Number(score) > 0) {
              scoreEntities[index].push({ ...entity, scoreRaw: score, totalScoreRaw: score });
            }
          }
        }
      }
    }

    const topMetricSet = entity?.topMetricSet || {};
    R.forEachObjIndexed((currentTopMetricSet = [], timeStamp) => {
      if (timeStamp) {
        const ts = moment.utc(parseInt(timeStamp, 10));
        const index = getSelectIndex(ts, startTime);
        if (index >= 0 && index < metricEntities.length) {
          metricEntities[index][entity.id] = R.filter((item) => item, currentTopMetricSet || []);
        }
      }
    }, topMetricSet || {});

    const predictedTopMetricSet = entity?.predictedTopMetricSet || {};
    R.forEachObjIndexed((currentTopMetricSet = [], timeStamp) => {
      if (timeStamp) {
        const ts = moment.utc(parseInt(timeStamp, 10));
        const index = getSelectIndex(ts, startTime);
        if (index >= 0 && index < predictedMetricEntities.length) {
          predictedMetricEntities[index][entity.id] = currentTopMetricSet;
        }
      }
    }, predictedTopMetricSet || {});

    const topLogSet = entity?.topLogSet || {};
    R.forEachObjIndexed((currentTopLogSet = [], timeStamp) => {
      if (timeStamp) {
        const ts = moment.utc(parseInt(timeStamp, 10));
        const index = getSelectIndex(ts, startTime);
        if (index >= 0 && index < logEntities.length) {
          logEntities[index][entity.id] = currentTopLogSet;
        }
      }
    }, topLogSet || {});

    const predictedTopLogSet = entity?.predictedTopLogSet || {};
    R.forEachObjIndexed((currentTopLogSet = [], timeStamp) => {
      if (timeStamp) {
        const ts = moment.utc(parseInt(timeStamp, 10));
        const index = getSelectIndex(ts, startTime);
        if (index >= 0 && index < predictedLogEntities.length) {
          predictedLogEntities[index][entity.id] = currentTopLogSet;
        }
      }
    }, predictedTopLogSet || {});
  };

  R.forEach((e) => {
    applyEntityStatus(e, e.isInstanceRoot);
  }, allData || []);

  // sort entities in scoreEntities
  R.forEach((entities) => {
    entities.sort((a, b) => {
      return b.scoreRaw - a.scoreRaw;
    });
  }, scoreEntities);

  const allEntityTree = [];

  const applyInactiveFilter = (e) => {
    if (e.inactive) return null;

    const entity = { ...e, children: [], allChildren: [] };

    R.forEach((c) => {
      const child = applyInactiveFilter(c);
      if (child) {
        entity.children.push(child);
        entity.allChildren.push(child);
        entity.allChildren = R.concat(entity.allChildren, child.allChildren || []);
      }
    }, e.children || []);

    return entity;
  };

  R.forEach((e) => {
    const entity = applyInactiveFilter(e);
    if (entity && entity.children.length > 0) {
      allEntityTree.push(entity);
    }
  }, allData || []);

  R.addIndex(R.forEach)((se, idx) => {
    let predictedIncidentCount = 0;
    let detectedIncidentCount = 0;

    R.forEach((e) => {
      const time = startTime.clone().add(idx * timeInterval, 'minutes');
      const { predictedIncidentCount: pCount, detectedIncidentCount: dCount } = getIncidentCounts(e, time);
      predictedIncidentCount += pCount || 0;
      detectedIncidentCount += dCount || 0;
    }, se || []);

    incidentCounts[idx] = { predictedIncidentCount, detectedIncidentCount };
  }, scoreEntities);

  return {
    allEntityTree,
    totalScores,
    scoreEntities,
    incidentCounts,
    metricEntities,
    predictedMetricEntities,
    logEntities,
    predictedLogEntities,
    timeRangeCount,
  };
};

const buildNodeTree = (
  isK8s,
  isK8sProject,
  allData,
  allDataMap,
  selectMap,
  startTime,
  selectedTime,
  groupBy,
  userCurrentTime,
  predictionEndTime,
) => {
  let allNodeData = [];
  let nodeTree = [];

  const {
    allEntityTree,
    totalScores,
    scoreEntities,
    incidentCounts,
    metricEntities,
    predictedMetricEntities,
    logEntities,
    predictedLogEntities,
    timeRangeCount,
  } = buildEntityTree(
    isK8s,
    isK8sProject,
    allData,
    allDataMap,
    selectMap,
    startTime,
    selectedTime,
    userCurrentTime,
    predictionEndTime,
  );

  if (isK8s && groupBy === 'instance') {
    const podInstanceMap = {};
    R.forEach((comp) => {
      R.forEach((pod) => {
        const { name: podName } = pod;
        const coverageMap = pod?.k8CoverageMap?.coverageMap?.[podName] || [];
        if (coverageMap.length > 0) {
          const currentCov = R.find((cov) => {
            const { s, e } = cov[1] || {};
            return s <= selectedTime && e >= selectedTime;
          }, coverageMap);
          if (currentCov) {
            const { h } = currentCov[0] || {};
            let podInstance = podInstanceMap[h];

            if (!podInstance) {
              const hostInstance = allDataMap[`${k8sHostPrefix}_${h}`];
              if (hostInstance) {
                podInstance = { ...hostInstance, children: [], parents: [], scoreRaw: 0 };
                podInstanceMap[h] = podInstance;
              }
            }

            if (podInstance) {
              pod.instanceName = h;
              podInstance.children.push(pod);
            }
          }
        }
      }, comp.children || []);
    }, allEntityTree || []);
    allNodeData = R.values(podInstanceMap);
  } else {
    allNodeData = allEntityTree;
  }

  const buildNode = (entity) => {
    const newNode = { ...entity };
    const isLeaf = (newNode.children || []).length === 0;

    if (isLeaf) {
      const isSelected = selectMap[newNode.id];
      if (!isSelected || entity.inactive) {
        return null;
      } else {
        return newNode;
      }
    }

    const nodeChildren = [];
    R.forEach((c) => {
      const child = buildNode(c);
      if (child) {
        nodeChildren.push(child);
      }
    }, entity.children || []);

    if (nodeChildren.length > 0) {
      newNode.children = R.sortWith([R.descend(R.prop('totalScoreRaw')), R.ascend(R.prop('name'))], nodeChildren);
      newNode.totalScoreRaw =
        (newNode.scoreRaw || 0) + R.reduce((acc, val) => acc + (val.totalScoreRaw || 0), 0, newNode.children);
      return newNode;
    } else {
      return null;
    }
  };

  R.forEach((e) => {
    const n = buildNode(e);
    if (n) {
      nodeTree.push(n);
    }
  }, allNodeData);

  nodeTree = R.sortWith([R.descend(R.prop('totalScoreRaw')), R.ascend(R.prop('name'))], nodeTree);

  let podCount = 0;
  let instanceCount = 0;
  let containerCount = 0;
  let allPodCount = 0;
  let allInstanceCount = 0;
  let allContainerCount = 0;

  const countEntity = (entity, isAll) => {
    if (entity.type === 'pod' || entity.type === 'instance') {
      if (isAll) {
        allPodCount += 1;
        allInstanceCount += 1;
      } else {
        podCount += 1;
        instanceCount += 1;
      }
    } else if (entity.type === 'container') {
      if (isAll) {
        allContainerCount += 1;
      } else {
        containerCount += 1;
      }
    }
    R.forEach((c) => {
      countEntity(c, isAll);
    }, entity.children || []);
  };

  R.forEach((c) => {
    countEntity(c, false);
  }, nodeTree);
  R.forEach((c) => {
    countEntity(c, true);
  }, allEntityTree);

  const allEntityTreeNew = [];
  const buildEntity = (entity) => {
    const newNode = { ...entity };

    const nodeChildren = [];
    R.forEach((c) => {
      const child = buildEntity(c);
      if (child) {
        nodeChildren.push(child);
      }
    }, entity.children || []);

    newNode.children = R.sortWith(
      [R.ascend(R.prop('inactive')), R.descend(R.prop('totalScoreRaw')), R.ascend(R.prop('name'))],
      nodeChildren,
    );
    newNode.totalScoreRaw =
      (newNode.scoreRaw || 0) + R.reduce((acc, val) => acc + (val.totalScoreRaw || 0), 0, newNode.children);

    const { detectedIncidentCount } = getIncidentCounts(newNode, selectedTime);
    newNode.detectedIncidentCount = detectedIncidentCount;
    return newNode;
  };
  R.forEach((e) => {
    const n = buildEntity(e);
    if (n) {
      allEntityTreeNew.push(n);
    }
  }, allEntityTree);

  return {
    nodeTree,
    allEntityTree: R.sortWith(
      [
        R.descend(R.prop('detectedIncidentCount')),
        R.ascend(R.prop('inactive')),
        R.descend(R.prop('totalScoreRaw')),
        R.ascend(R.prop('name')),
      ],
      allEntityTreeNew,
    ),
    totalScores,
    scoreEntities,
    incidentCounts,
    metricEntities,
    predictedMetricEntities,
    logEntities,
    predictedLogEntities,
    allInstanceCount,
    instanceCount,
    allPodCount,
    podCount,
    allContainerCount,
    containerCount,
    timeRangeCount,
  };
};

const pageSize = 100;
const ServiceMap3DVisualizer = ({
  isJwtMode,
  intl,
  isK8sProject,
  isK8s,
  width: canvasWidth,
  height: canvasHeight,
  data: allData = [],
  dataMap: allDataMap = {},
  startTime,
  selectedTime,
  onChangeTime,
  isDark = false,
  dataEndTime,
  serviceMapNote = {},
  onAddNote = () => {},
  onDeleteNote = () => {},
  onActiveNodeChange = () => {},
  nodeRootCauseMap,
  userId,
  userCurrentTime,
  predictionEndTime,
  onChangeShowGPT = () => {},
  selectedZone,
  onFilterShowChange,
}) => {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    allEntityTree: [],
    nodeTree: [],
    totalScores: [],
    scoreEntities: [],
    incidentCounts: [],
    metricEntities: [],
    predictedMetricEntities: [],
    logEntities: [],
    predictedLogEntities: [],
    selectionMap: {},
    showFilterPanel: true,
    podCount: 0,
    instanceCount: 0,
    containerCount: 0,
    allPodCount: 0,
    allInstanceCount: 0,
    allContainerCount: 0,
    autoSelected: false,
    groupBy: 'component',
    showDetail: true,
    activeEntity: null,
    timeRangeCount: 24,
    filterPageIndex: 0,
    collapseMap: {},
  });

  const { showFilterPanel, selectionMap, autoSelected, groupBy, showDetail, collapseMap } = state;
  const { instanceCount, podCount, containerCount } = state;
  const { allInstanceCount, allPodCount, allContainerCount } = state;
  const changeTime = (val) => {
    let time = startTime.clone().add(val * timeInterval, 'minutes');
    const endTime = predictionEndTime || dataEndTime;
    time = endTime && time.valueOf() > endTime ? moment.utc(endTime) : time;
    setState({ activeEntity: null });
    onActiveNodeChange(null);
    onChangeTime?.(time);
  };

  useEffect(() => {
    setState({ autoSelected: false, selectionMap: {} });
  }, [allData, selectedTime]);

  useEffect(() => {
    const {
      nodeTree,
      totalScores,
      scoreEntities,
      incidentCounts,
      metricEntities,
      predictedMetricEntities,
      logEntities,
      predictedLogEntities,
      allEntityTree,
      allInstanceCount,
      instanceCount,
      allPodCount,
      podCount,
      allContainerCount,
      containerCount,
      timeRangeCount,
    } = buildNodeTree(
      isK8s,
      isK8sProject,
      allData,
      allDataMap,
      selectionMap,
      startTime,
      selectedTime,
      groupBy,
      userCurrentTime,
      predictionEndTime,
    );
    setState({
      allEntityTree,
      nodeTree,
      totalScores,
      scoreEntities,
      incidentCounts,
      metricEntities,
      predictedMetricEntities,
      logEntities,
      predictedLogEntities,
      instanceCount,
      allInstanceCount,
      allPodCount,
      podCount,
      containerCount,
      allContainerCount,
      timeRangeCount,
    });
    if (!selectedTime) {
      setTimeout(() => {
        let idx = 0;
        let max = 0;
        for (let i = 0; i < totalScores.length; i += 1) {
          if (totalScores[i] > max) {
            max = totalScores[i];
            idx = i;
          }
        }
        changeTime(idx);
      }, 100);
    }
  }, [allData, selectionMap, selectedTime, groupBy]);

  useEffect(() => {
    const collapseMap = {};
    R.forEach((x) => {
      collapseMap[x.id] = true;
    }, allData || []);
    setState({ collapseMap });
  }, [allData, selectedTime, groupBy]);

  const {
    allEntityTree,
    nodeTree,
    totalScores,
    scoreEntities,
    incidentCounts,
    metricEntities,
    predictedMetricEntities,
    logEntities,
    predictedLogEntities,
    timeRangeCount,
    filterPageIndex,
  } = state;

  const sliderMarks = {};

  const formatHour = (t) => (t < 10 ? `0${t}:00` : t < 24 ? `${t}:00` : '00:00');
  R.forEach((i) => {
    const t = i % 24;
    const z = (i - 1) % 24;
    sliderMarks[i] = {
      label: i === 0 ? '' : `${formatHour(z)}-${formatHour(t)}`,
      style: {
        fontSize: 12,
        width: 74,
        textAlign: 'right',
        transformOrigin: 'top left',
        transform: 'rotate(-45deg) translateX(-100%)',
        color: isDark ? 'var(--text-color)' : 'inherit',
      },
    };
  }, R.range(0, timeRangeCount + 1));

  const calcScore = (idx) => {
    return totalScores[idx] || 0;
  };
  const getScoreEntities = (idx, selectionMap) => {
    const { allEntityList } = BuildEntityList(allEntityTree || []);

    const filterInstanceMap = {};
    R.forEach((e) => {
      if (e.type === 'instance' || e.type === 'pod') filterInstanceMap[e.id] = e;
    }, allEntityList || []);
    const pushScoreEntities = [];
    R.forEachObjIndexed((val, key) => {
      if (filterInstanceMap[key]) pushScoreEntities.push(filterInstanceMap[key]);
    }, selectionMap || {});

    const newScoreEntities = R.filter((item) => {
      const { componentName, id } = item || {};
      return !!R.find((_item) => {
        return (
          _item.componentName === componentName &&
          (_item.id === id || !!R.find((__item) => __item.id === id, _item?.allChildren || []))
        );
      }, pushScoreEntities || []);
    }, scoreEntities[idx] || []);

    return newScoreEntities;
  };
  const getMetricEntities = (idx) => {
    return metricEntities[idx] || [];
  };
  const getPredictedMetricEntities = (idx) => {
    return predictedMetricEntities[idx] || [];
  };

  const getLogEntities = (idx) => {
    return logEntities[idx] || [];
  };
  const getPredictedLogEntities = (idx) => {
    return predictedLogEntities[idx] || [];
  };
  const changeFilterPageIndex = (idx) => {
    setState({ filterPageIndex: idx });
  };
  const selectIndex = getSelectIndex(selectedTime, startTime);

  useEffect(() => {
    setState({ filterPageIndex: 0 });
  }, [selectIndex, selectedZone]);

  useEffect(() => {
    setState({ activeEntity: null });
  }, [filterPageIndex, selectedZone]);

  const selectStartHour = getSelectStartHour(selectedTime, startTime);
  const selectScore = calcScore(selectIndex);
  const selectEntities = getScoreEntities(selectIndex, selectionMap);
  const selectMetricEntities = getMetricEntities(selectIndex);
  const selectPredictedMetricEntities = getPredictedMetricEntities(selectIndex);
  const selectLogEntities = getLogEntities(selectIndex);
  const selectPredictedLogEntities = getPredictedLogEntities(selectIndex);
  const showCurrentTime =
    userCurrentTime &&
    startTime &&
    userCurrentTime > startTime.valueOf() &&
    userCurrentTime <= startTime.valueOf() + OneDay;

  const canvasPanelWidth = !showFilterPanel ? canvasWidth - 24 : canvasWidth - 360 - 24;
  const currentTimeLeft = showCurrentTime
    ? (userCurrentTime - startTime.valueOf()) / (timeRangeCount * 60 * 60 * 1000)
    : 0;
  const currentTimeLabelLeft =
    (currentTimeLeft * canvasPanelWidth - 144 < 0 ? 0 : currentTimeLeft * canvasPanelWidth - 144) / canvasPanelWidth;

  const predictedTimeLeft = showCurrentTime
    ? (predictionEndTime - startTime.valueOf()) / (timeRangeCount * 60 * 60 * 1000)
    : 0;
  const predictedTimeLabelLeft =
    (predictedTimeLeft * canvasPanelWidth - 144 < 0 ? 0 : predictedTimeLeft * canvasPanelWidth - 144) /
    canvasPanelWidth;

  return (
    <Container className="flex-grow flex-col flex-min-height flex-min-width">
      <div className="flex-row" style={{ width: canvasWidth, height: canvasHeight }}>
        <div
          className="flex-col full-height"
          style={{ width: showFilterPanel ? 360 : 0, padding: '0 0 10px 0px', position: 'relative' }}
        >
          <div
            className="clickable"
            style={{ position: 'absolute', right: -17, top: -3, fontSize: 18, color: 'gray', zIndex: 100 }}
            onClick={() => {
              setState({ showFilterPanel: !showFilterPanel });
            }}
          >
            <RightSquareOutlined rotate={showFilterPanel ? 180 : 0} />
          </div>
          <Card
            style={{ width: '100%', height: '100%', display: showFilterPanel ? 'unset' : 'none' }}
            bodyStyle={{ height: '100%', padding: 8, overflowY: 'auto' }}
          >
            <div className="flex-col full-height">
              <ServiceMapEntityFilter
                isK8s={isK8s}
                entityType={isK8s ? 'Pod' : intl.formatMessage(appFieldsMessages.instance)}
                allRawEntityTree={allEntityTree || []}
                selectionMap={selectionMap}
                entityMap={allDataMap}
                pageSize={pageSize}
                autoSelected={autoSelected}
                selectedTime={selectedTime}
                onChange={(selectionMap) => {
                  setState({ selectionMap: { ...selectionMap }, autoSelected: true });
                }}
                selectedZone={selectedZone}
                pageIndex={filterPageIndex}
                changeFilterPageIndex={changeFilterPageIndex}
                collapseMap={collapseMap}
                onFilterShowChange={onFilterShowChange}
              />
            </div>
          </Card>
        </div>
        <div className="flex-grow flex-col flex-center-align" style={{ paddingLeft: 24 }}>
          <div className="flex-col full-width flex-center-align" style={{ marginTop: -12, height: 180 }}>
            <div className="flex-row full-width" style={{ fontSize: 14, height: 28 }}>
              <div style={{ width: 300 }}>
                {isK8s && (
                  <div className="flex-row">
                    <div style={{ paddingRight: 16 }}>Group by</div>
                    <Radio.Group
                      onChange={() => {
                        setState({ groupBy: groupBy === 'instance' ? 'component' : 'instance' });
                      }}
                      value={groupBy}
                      style={{ fontSize: 14 }}
                    >
                      <Radio value="component">Components</Radio>
                      <Radio value="instance">Hosts</Radio>
                    </Radio.Group>
                  </div>
                )}
              </div>
              <div className="flex-grow text-center">
                <span>{`Time: ${(selectedTime || startTime).clone().format(Defaults.TimeOnlyFormat)} ~ ${(
                  selectedTime || startTime
                )
                  .clone()
                  .add(timeInterval, 'minutes')
                  .format(Defaults.TimeOnlyFormat)}`}</span>
                <span style={{ paddingLeft: 16 }}>
                  {`Anomaly score: ${formatScore(
                    calcScore(
                      Math.floor((selectedTime || startTime).valueOf() - startTime.valueOf()) /
                        (1000 * 60 * timeInterval),
                    ),
                  )}`}
                </span>
              </div>
              <div style={{ width: 300, textAlign: 'right' }}>
                {isK8s && (
                  <>
                    <Tooltip title="Pod" mouseEnterDelay={0.3} placement="top" style={{ paddingRight: 16 }}>
                      <CodeSandboxOutlined style={{ paddingRight: 8 }} />
                      <span>{podCount}</span>
                      <span>/</span>
                      <span style={{ paddingRight: 16 }}>{allPodCount}</span>
                    </Tooltip>
                    <Tooltip title="Container" mouseEnterDelay={0.3} placement="top">
                      <InstagramOutlined style={{ paddingRight: 8 }} />
                      <span>{containerCount}</span>
                      <span>/</span>
                      <span>{allContainerCount}</span>
                    </Tooltip>
                  </>
                )}
                {!isK8s && (
                  <>
                    <Tooltip title="Instance" mouseEnterDelay={0.3} placement="top">
                      <DatabaseOutlined style={{ paddingRight: 8 }} />
                      <span>{instanceCount}</span>
                      <span>/</span>
                      <span>{allInstanceCount}</span>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
            <div className="flex-col full-width service-map" style={{ position: 'relative', paddingTop: 58 }}>
              <Slider
                value={selectIndex}
                min={0}
                max={timeRangeCount}
                step={0.1}
                style={{ margin: '3px 0px' }}
                included={false}
                onChange={(value) => {
                  const val = isNumber(value) ? Math.floor(value) : 0;
                  changeTime(val);
                }}
                marks={sliderMarks}
                handleStyle={{ marginLeft: canvasWidth / timeRangeCount / 2 - 8 }}
                railStyle={{ display: 'none' }}
                tooltip={{
                  formatter: (val) => {
                    const score = calcScore(val);
                    return (
                      <div className="flex-col">
                        <div className="flex-row flex-center-align">
                          <span style={{ marginRight: 4, width: 110 }} className="inline-block bold">
                            Time:
                          </span>
                          <span
                            className="inline-block"
                            style={{
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'break-word',
                              maxWidth: 300,
                            }}
                          >
                            {`${startTime
                              .clone()
                              .add(val * timeInterval, 'minutes')
                              .format(Defaults.TimeOnlyFormat)} ~ ${startTime
                              .clone()
                              .add((val + 1) * timeInterval, 'minutes')
                              .format(Defaults.TimeOnlyFormat)}`}
                          </span>
                        </div>
                        <div className="flex-row flex-center-align">
                          <span className="inline-block bold" style={{ marginRight: 4, width: 110 }}>
                            Anomaly score:
                          </span>
                          <span style={{ maxWidth: 140 }}>{formatScore(score)}</span>
                        </div>
                      </div>
                    );
                  },
                }}
              />
              <div
                className="flex-row flex-center-align"
                style={{
                  width: '100%',
                  position: 'relative',
                  background: colorsRGB[0],
                  height: 4,
                  top: -11,
                  zIndex: -1,
                }}
              >
                {R.map((val) => {
                  const score = calcScore(val);

                  const time = startTime.clone().add(val * timeInterval, 'minutes');
                  const endTime = predictionEndTime || dataEndTime;
                  const color =
                    time.valueOf() > (endTime ? moment.utc(endTime) : moment.utc()).valueOf()
                      ? 'grey'
                      : colorsRGB[scoreLevel(score)];

                  return (
                    <div
                      key={val}
                      style={{
                        position: 'absolute',
                        width: `${(1 / timeRangeCount) * 100}%`,
                        height: 4,
                        background: color,
                        left: `${(val / timeRangeCount) * 100}%`,
                      }}
                    />
                  );
                }, R.range(0, timeRangeCount))}
              </div>
              <div className="full-width" style={{ fontSize: 12, position: 'absolute', top: -4, height: 16 }}>
                {showCurrentTime && (
                  <>
                    <div style={{ position: 'absolute', top: 12, left: `${currentTimeLeft * 100}%` }}>
                      <CaretDownOutlined style={{ position: 'absolute', left: -4, fontSize: 12 }} />
                    </div>
                    <div style={{ position: 'absolute', top: 0, left: `${currentTimeLabelLeft * 100}%` }}>
                      <span>{`Current time: ${moment.utc(userCurrentTime).format(Defaults.ShortTimeFormat)}`} </span>
                    </div>
                    <div style={{ position: 'absolute', top: 12, left: `${predictedTimeLeft * 100}%` }}>
                      <CaretDownOutlined style={{ position: 'absolute', left: -4, fontSize: 12 }} />
                    </div>
                    <div style={{ position: 'absolute', top: 0, left: `${predictedTimeLabelLeft * 100}%` }}>
                      <span>
                        {`Prediction End time: ${moment.utc(predictionEndTime).format(Defaults.ShortTimeFormat)}`}{' '}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="full-width" style={{ fontSize: 12, position: 'absolute', top: 22, height: 43 }}>
                {R.addIndex(R.map)((counts, idx) => {
                  const score = calcScore(idx);

                  const time = startTime.clone().add(idx * timeInterval, 'minutes');
                  const endTime = predictionEndTime || dataEndTime;
                  const color =
                    time.valueOf() > (endTime ? moment.utc(endTime) : moment.utc()).valueOf()
                      ? 'inherit'
                      : colorsRGB[scoreLevel(score)];
                  const { predictedIncidentCount = 0, detectedIncidentCount = 0 } = counts || {};
                  if (predictedIncidentCount > 0 || detectedIncidentCount > 0) {
                    return (
                      <div
                        key={idx}
                        className="clickable flex-row flex-center-align"
                        style={{
                          position: 'absolute',
                          marginLeft: 0,
                          width: `${(1 / ((timeRangeCount * 60) / timeInterval)) * 100}%`,
                          height: '100%',
                          verticalAlign: 'top',
                          left: `${(idx / ((timeRangeCount * 60) / timeInterval)) * 100}%`,
                          zIndex: 100,
                          fontSize: 12,
                          background: `linear-gradient(to top, ${color}33 0%, ${color}00 100%)`,
                        }}
                        onClick={() => {
                          changeTime(idx);
                        }}
                      >
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          {predictedIncidentCount > 0 && (
                            <div>
                              <PinLegendIcon style={{ color: '#ffad66', fontSize: 12 }} />
                              <span style={{ paddingLeft: 4 }}>{predictedIncidentCount}</span>
                            </div>
                          )}
                          {predictedIncidentCount === 0 && (
                            <div>
                              <PinLegendIcon style={{ visibility: 'hidden' }} />
                            </div>
                          )}
                          {detectedIncidentCount > 0 && (
                            <div>
                              <PinLegendIcon style={{ color: '#FF5142', fontSize: 12 }} />
                              <span style={{ paddingLeft: 4 }}>{detectedIncidentCount}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                  return null;
                }, incidentCounts || [])}
              </div>
              <div className="full-width" style={{ fontSize: 12, position: 'absolute', top: 132, height: 16 }}>
                {R.map((key) => {
                  const val = serviceMapNote[key];
                  const idx = Math.floor((Number(key) - startTime.valueOf()) / (1000 * 60 * timeInterval));
                  if (idx < 0 || idx >= timeRangeCount) {
                    return null;
                  }

                  return (
                    <Popover
                      key={idx}
                      placement="top"
                      overlayStyle={{ paddingBottom: 8, paddingTop: 0 }}
                      destroyTooltipOnHide
                      content={
                        <div>
                          <span className="font-14">Historical operator nodes</span>
                          <Divider style={{ margin: '4px 0 4px 0' }} />
                          <NoteList notes={val} userId={userId} />
                        </div>
                      }
                    >
                      <div
                        key={idx}
                        className="clickable"
                        style={{
                          position: 'absolute',
                          width: 18,
                          height: 14,
                          left: `${(idx / ((timeRangeCount * 60) / timeInterval)) * 100}%`,
                          zIndex: 100,
                        }}
                        onClick={() => {
                          changeTime(idx);
                        }}
                      >
                        <FormOutlined style={{ verticalAlign: 'top', paddingLeft: 1, fontSize: 16 }} />
                      </div>
                    </Popover>
                  );
                }, R.keys(serviceMapNote || {}))}
              </div>
            </div>
          </div>
          <div className="flex-grow" style={{ zIndex: 10, position: 'relative' }}>
            <div style={{ zIndex: 10, position: 'relative' }}>
              <ServiceMapCanvas
                isJwtMode={isJwtMode}
                isK8s={isK8s}
                containerWidth={!showFilterPanel ? canvasWidth - 24 : canvasWidth - 360 - 24}
                containerHeight={canvasHeight - 188}
                nodeTree={nodeTree}
                isDark={isDark}
                selectStartHour={selectStartHour}
                selectedTime={selectedTime}
                showBoxText={false}
                activeEntity={state.activeEntity}
                metricEntities={selectMetricEntities}
                predictedMetricEntities={selectPredictedMetricEntities}
                logEntities={selectLogEntities}
                predictedLogEntities={selectPredictedLogEntities}
                nodeRootCauseMap={nodeRootCauseMap}
                selectedZone={selectedZone}
                selectionMap={selectionMap}
              />
            </div>
            {showDetail && selectEntities.length > 0 && (
              <Card
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  width: 290,
                  zIndex: 100,
                  minHeight: 128,
                }}
                bodyStyle={{ height: '100%', padding: 8, overflowY: 'auto' }}
              >
                <ScoreContent
                  key={selectIndex}
                  isK8s={isK8s}
                  selectedTime={selectedTime}
                  score={selectScore}
                  entities={selectEntities}
                  metricEntities={selectMetricEntities}
                  predictedMetricEntities={selectPredictedMetricEntities}
                  logEntities={selectLogEntities}
                  predictedLogEntities={selectPredictedLogEntities}
                  startTime={startTime}
                  timeInterval={timeInterval}
                  maxHeight={canvasHeight - 206}
                  val={selectIndex}
                  notes={serviceMapNote[String(startTime.valueOf() + selectIndex * 1000 * 60 * timeInterval)] || []}
                  onAddNote={(note) => {
                    onAddNote(note);
                  }}
                  onDeleteNote={(userId) => {
                    onDeleteNote(userId);
                  }}
                  userId={userId}
                  activeEntity={state.activeEntity}
                  onEntityClick={(entity) => {
                    if (entity && !selectionMap[entity?.id]) {
                      const selectChild = (e) => {
                        selectionMap[e.id] = true;
                        R.forEach(selectChild, e.children || []);
                      };
                      selectChild(entity);
                      setState({ selectionMap: { ...selectionMap }, autoSelected: true });
                    }
                    onActiveNodeChange(entity?.id);
                    setState({ activeEntity: entity?.id });
                  }}
                  nodeRootCauseMap={nodeRootCauseMap}
                  onChangeShowGPT={onChangeShowGPT}
                />
                <div style={{ position: 'absolute', top: 0, right: 0 }}>
                  <Tooltip title="Close" mouseEnterDelay={0.3}>
                    <Button
                      size="small"
                      icon={<CloseOutlined style={{ fontSize: 12 }} />}
                      style={{
                        border: 'none',
                        backgroundColor: 'rgba(0,0,0,0)',
                      }}
                      onClick={() => setState({ showDetail: !showDetail })}
                    />
                  </Tooltip>
                </div>
              </Card>
            )}
            {selectEntities.length > 0 && !showDetail && (
              <div
                style={{
                  position: 'absolute',
                  bottom: 16,
                  right: 4,
                  zIndex: 100,
                }}
              >
                <Tooltip title="Note" mouseEnterDelay={0.3}>
                  <Button
                    type={selectEntities.length > 0 ? 'primary' : 'default'}
                    size="small"
                    icon={<FormOutlined style={{ fontSize: 14 }} />}
                    style={{ border: 'none' }}
                    onClick={() => setState({ showDetail: !showDetail })}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ServiceMap3DVisualizer;
