/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { LinkedComponent } from 'valuelink';
import { Button, Alert, Form, Input, Row, Col, Checkbox, Select } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { hideAppLoader, updateLastActionInfo } from '../../../common/app/actions';
import { Container } from '../../../lib/fui/react';
import { State } from '../../../common/types';

import { eventMessages } from '../../../common/metric/messages';

type Props = {
  refresh: Number,
  credentials: Object,
  hideAppLoader: Function,
  updateLastActionInfo: Function,
  appLoaderVisible: Boolean,
};

class EmailSettings extends LinkedComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.optionsEncryption = [
      { value: 'None', text: 'None' },
      { value: 'STARTTLS', text: 'STARTTLS' },
      { value: 'SMPTS', text: 'SMPTS' },
    ];

    this.state = {
      isLoading: false,
      emailSettings: {
        emailKey: '',
        emailServiceSwitch: false,
        emailRecipient: '',
        emailSMTPServer: '',
        emailSMTPPort: '',
        emailSMTPEmailAddress: '',
        emailEncryption: 'None',
        emailAuthSwitch: false,
        emailSMTPUsername: '',
        emailSMTPPassword: '',
      },
      emailTest: null,
      hasError: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.refresh !== this.props.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const self = this;
    const { credentials } = props;
    self.setState({ isLoading: true });
    props.updateLastActionInfo();
    fetchGet(getEndpoint('systemsetting'), {
      ...credentials,
    }).then((data) => {
      const emailSettings = this.getStateMap('fromData', data, {});
      this.formRef.current.setFieldsValue(emailSettings);
      self.setState({
        isLoading: false,
        emailSettings,
      });
    });
  }

  @autobind
  onUpdateClick() {
    const self = this;
    const { credentials } = self.props;
    const { emailSettings } = this.state;
    const systemConfig = this.getStateMap('fromState', {}, emailSettings);
    self.setState({
      isLoading: true,
      emailTest: null,
    });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('systemsetting'), {
      ...credentials,
      systemConfig: JSON.stringify(systemConfig),
    })
      .then((data) => {
        self.reloadData(self.props);
      })
      .catch((err) => {
        return true;
      });
  }

  @autobind
  onTestClick() {
    const self = this;
    const { credentials } = self.props;
    const { emailSettings } = this.state;
    const systemConfig = this.getStateMap('fromState', {}, emailSettings);
    self.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('emailtest'), {
      ...credentials,
      systemConfig: JSON.stringify(systemConfig),
    })
      .then((data) => {
        const success = get(data || {}, 'success', false);
        self.setState({ isLoading: false, emailTest: success });
      })
      .catch((err) => {
        self.setState({ isLoading: false, emailTest: false });
      });
  }

  @autobind
  getStateMap(option, data = {}, emailSettings = {}) {
    if (option === 'fromData') {
      const systemConfig = JSON.parse(get(data, 'systemConfig', '{}'));
      const emailKey = get(systemConfig, 'key', '');
      const emailServiceSwitch = get(systemConfig, 'mailOnoffSwitch') === 'ON';
      const emailRecipient = get(systemConfig, 'mailInformUser', '');
      const emailSMTPServer = get(systemConfig, 'mailSmtpServer', '');
      const emailSMTPPort = get(systemConfig, 'mailSmtpPort', '');
      const emailSMTPEmailAddress = get(systemConfig, 'mailSendUser', '');
      const emailEncryption = get(systemConfig, 'mailSendOverSecurity', 'None');
      const emailAuthSwitch = get(systemConfig, 'mailSmtpAuth') === 'true';
      const emailSMTPUsername = get(systemConfig, 'mailAuthUser', '');
      const emailSMTPPassword = get(systemConfig, 'mailSmtpPw', '');
      return {
        emailKey,
        emailServiceSwitch,
        emailRecipient,
        emailSMTPServer,
        emailSMTPPort,
        emailSMTPEmailAddress,
        emailEncryption,
        emailAuthSwitch,
        emailSMTPUsername,
        emailSMTPPassword,
      };
    }
    const {
      emailKey,
      emailServiceSwitch,
      emailRecipient,
      emailSMTPServer,
      emailSMTPPort,
      emailSMTPEmailAddress,
      emailEncryption,
      emailAuthSwitch,
      emailSMTPUsername,
      emailSMTPPassword,
    } = emailSettings;
    return {
      key: emailKey,
      mailOnoffSwitch: emailServiceSwitch ? 'ON' : 'OFF',
      mailSendUser: emailSMTPEmailAddress,
      mailSmtpServer: emailSMTPServer,
      mailSmtpPort: emailSMTPPort,
      mailInformUser: emailRecipient,
      mailSendOverSecurity: emailEncryption,
      mailSmtpAuth: emailAuthSwitch ? 'true' : false,
      mailAuthUser: emailSMTPUsername,
      mailSmtpPw: emailSMTPPassword,
    };
  }

  @autobind
  handleInputChange(field, value) {
    const { emailSettings } = this.state;
    this.setState({
      emailSettings: {
        ...emailSettings,
        [field]: value,
      },
    });
  }

  @autobind
  onValueChange(value, values) {
    setTimeout(async () => {
      const validateResult = await this.formRef.current
        .validateFields()
        .then(() => false)
        .catch(() => true);
      this.setState({
        hasError: validateResult,
      });
    }, 0);
  }

  render() {
    const { intl } = this.props;
    const { isLoading, emailSettings, emailTest, hasError } = this.state;
    const {
      emailServiceSwitch,
      emailEncryption,
      emailAuthSwitch,
      emailRecipient,
      emailSMTPServer,
      emailSMTPPort,
      emailSMTPEmailAddress,
      emailSMTPUsername,
      emailSMTPPassword,
    } = emailSettings;

    return (
      <Container className={`flex-col ${isLoading ? ' loading' : ''}`} style={{ fontSize: 12, width: 1024 }} fullHeight>
        <Container className="flex-grow overflow-y-auto" style={{ overflowX: 'hidden' }}>
          <Form ref={this.formRef} layout="vertical" onValuesChange={this.onValueChange} initialValues={emailSettings}>
            <h3>{intl.formatMessage(eventMessages.generalEmailSettings)}</h3>
            <Form.Item name="emailServiceSwitch">
              <Checkbox
                name="emailServiceSwitch"
                checked={emailServiceSwitch}
                onChange={({ target: { name, checked } }) => this.handleInputChange(name, checked)}
              >
                {intl.formatMessage(eventMessages.emailServiceSwitch)}
              </Checkbox>
            </Form.Item>
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item
                  label={intl.formatMessage(eventMessages.defaultEmailAlertRecipient)}
                  rules={[{ required: emailServiceSwitch }]}
                  name="emailRecipient"
                  required
                >
                  <Input
                    disabled={!emailServiceSwitch}
                    placeholder="user"
                    value={emailRecipient}
                    onChange={(e) => this.handleInputChange('emailRecipient', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24} style={{ marginBottom: 20 }}>
                <h3>{intl.formatMessage(eventMessages.emailServerSettings)}</h3>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={intl.formatMessage(eventMessages.SMTPServer)}
                  rules={[{ required: emailServiceSwitch }]}
                  required
                  name="emailSMTPServer"
                >
                  <Input
                    disabled={!emailServiceSwitch}
                    placeholder=""
                    value={emailSMTPServer}
                    onChange={(e) => this.handleInputChange('emailSMTPServer', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={intl.formatMessage(eventMessages.SMTPPort)}
                  rules={[{ required: emailServiceSwitch }]}
                  required
                  name="emailSMTPPort"
                >
                  <Input
                    disabled={!emailServiceSwitch}
                    placeholder=""
                    value={emailSMTPPort}
                    onChange={(e) => this.handleInputChange('emailSMTPPort', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={intl.formatMessage(eventMessages.SMTPEmailAddress)}
                  rules={[{ required: emailServiceSwitch }]}
                  required
                  name="emailSMTPEmailAddress"
                >
                  <Input
                    disabled={!emailServiceSwitch}
                    placeholder=""
                    onChange={(e) => this.handleInputChange('emailSMTPEmailAddress', e.target.value)}
                    value={emailSMTPEmailAddress}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={intl.formatMessage(eventMessages.encryption)} required name="emailEncryption">
                  <Select
                    disabled={!emailServiceSwitch}
                    placeholder=""
                    name="emailEncryption"
                    value={emailEncryption}
                    onChange={(value) => this.handleInputChange('emailEncryption', value)}
                  >
                    {R.map(
                      (op) => (
                        <Select.Option value={op.value} key={op.value}>
                          {op.text}
                        </Select.Option>
                      ),
                      this.optionsEncryption,
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="emailAuthSwitch">
              <Checkbox
                name="emailAuthSwitch"
                checked={emailAuthSwitch}
                onChange={({ target: { name, checked } }) => this.handleInputChange(name, checked)}
                disabled={!emailServiceSwitch}
              >
                {intl.formatMessage(eventMessages.authenticationSwitch)}
              </Checkbox>
            </Form.Item>
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item
                  label={intl.formatMessage(eventMessages.SMTPUsername)}
                  rules={[{ required: emailAuthSwitch }]}
                  name="emailSMTPUsername"
                  required={emailAuthSwitch}
                >
                  <Input
                    disabled={!emailServiceSwitch || !emailAuthSwitch}
                    required={emailAuthSwitch}
                    value={emailSMTPUsername}
                    onChange={(e) => this.handleInputChange('emailSMTPUsername', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={intl.formatMessage(eventMessages.SMTPPassword)}
                  rules={[{ required: emailAuthSwitch }]}
                  name="emailSMTPPassword"
                  required={emailAuthSwitch}
                >
                  <Input
                    type="password"
                    disabled={!emailServiceSwitch || !emailAuthSwitch}
                    required={emailAuthSwitch}
                    value={emailSMTPPassword}
                    onChange={(e) => this.handleInputChange('emailSMTPPassword', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Container>
        <Container>
          {emailTest && <Alert message="Settings have been validated." type="success" showIcon />}
          {emailTest === false && (
            <Alert
              message="Settings could not be validated. Please check your settings and try again."
              type="error"
              showIcon
            />
          )}
        </Container>
        <Container className="flex-row" style={{ marginTop: 10 }}>
          <div className="flex-grow" />
          <Button
            size="small"
            type="primary"
            disabled={Boolean(hasError) || !emailServiceSwitch}
            onClick={this.onTestClick}
          >
            {intl.formatMessage(eventMessages.testEmailSettings)}
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ marginLeft: 8 }}
            disabled={Boolean(hasError)}
            onClick={this.onUpdateClick}
          >
            {intl.formatMessage(eventMessages.updateEmailSettings)}
          </Button>
        </Container>
      </Container>
    );
  }
}

export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { appLoaderVisible } = state.app;
    return { credentials, appLoaderVisible };
  },
  { hideAppLoader, updateLastActionInfo },
)(EmailSettings);
