/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import { isInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { Button, Card, Switch, Checkbox } from 'antd';

import { Input, Select } from '../../../../lib/fui/react';
import { settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  configureAllowOptions: Object,
  componentState: Object,
};

class InsightAgentSetting extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl } = props;
    const { componentState } = props;

    const state = {
      instanceType: 'PrivateCloud',
      projectCloudType: '',
      dataType: 'Log',
      insightAgentType: '',
      samplingInterval: 10,
      samplingUnit: 60,
      isGroupingByInstance: false,

      // aws
      region: '',
      iamAccessKey: '',
      secretAccessKey: '',
      verified: true,
    };
    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);
    this.state = {
      ...state,

      ...(componentState || {}),
    };

    const configureAllowOptions = props.configureAllowOptions || {};
    this.instanceTypeOptions = [
      { label: 'Private Cloud', value: 'PrivateCloud' },
      { label: 'AWS', value: 'AWS' },
      { label: 'GCE', value: 'GCE' },
      { label: 'MapReduce', value: 'MapReduce' },
      { label: 'Spark', value: 'Spark' },
      { label: 'Jenkins', value: 'Jenkins' },
      { label: 'HBase', value: 'HBase' },
      { label: 'Elastic Search', value: 'ElasticSearch' },
      { label: 'Prometheus', value: 'Prometheus' },
    ];
    if (configureAllowOptions.instanceTypeOptions) {
      this.instanceTypeOptions = R.filter(
        (item) => configureAllowOptions.instanceTypeOptions.includes(item.value),
        this.instanceTypeOptions,
      );
    }
    this.dataTypeOptions = [
      { label: intl.formatMessage(settingsMessages.Metric), value: 'Metric' },
      { label: intl.formatMessage(settingsMessages.Log), value: 'Log' },
      { label: intl.formatMessage(settingsMessages.Alert), value: 'Alert' },
      { label: intl.formatMessage(settingsMessages.Incident), value: 'Incident' },
      { label: intl.formatMessage(settingsMessages.Deployment), value: 'Deployment' },
      { label: intl.formatMessage(settingsMessages.Trace), value: 'Trace' },
    ];
    if (configureAllowOptions.dataTypeOptions) {
      this.dataTypeOptions = R.filter(
        (item) => configureAllowOptions.dataTypeOptions.includes(item.value),
        this.dataTypeOptions,
      );
    }
    this.metricAgentOptions = [
      { label: 'Streaming', value: 'Custom' },
      { label: 'Metric File Replay', value: 'MetricFile' },
      { label: 'Container Streaming', value: 'containerStreaming' },
      { label: 'Container Replay', value: 'containerReplay' },
      { label: 'hypervisor', value: 'hypervisor' },
      { label: 'Splunk Streaming', value: 'SplunkStreaming' },
      { label: 'Splunk Replay', value: 'SplunkReplay' },
    ];
    if (configureAllowOptions.metricAgentOptions) {
      this.metricAgentOptions = R.filter(
        (item) => configureAllowOptions.metricAgentOptions.includes(item.value),
        this.metricAgentOptions,
      );
    }
    this.logAgentOptions = [
      { label: 'Live Streaming', value: 'Custom' },
      { label: 'File Replay', value: 'LogFile' },
      { label: 'Live Streaming Container', value: 'ContainerCustom' },
      { label: 'File Replay Container', value: 'ContainerHistorical' },
    ];
    if (configureAllowOptions.logAgentOptions) {
      this.logAgentOptions = R.filter(
        (item) => configureAllowOptions.logAgentOptions.includes(item.value),
        this.logAgentOptions,
      );
    }
    this.samplingUnit = [
      { label: 'second', value: 1 },
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
  }

  componentDidMount() {
    this.props.saveProjectInfo('InsightAgent', { dataType: this.state.dataType }, this.state);
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      instanceType,
      projectCloudType,
      dataType,
      samplingInterval,
      samplingUnit,
      insightAgentType,
      processPauseFlag,
      largeProject,
      isGroupingByInstance,
    } = this.state;

    const isMetric = dataType === 'Metric';

    const { region, iamAccessKey, secretAccessKey } = this.state;
    const {
      mapreducerMasterIPAddress,
      mapreduceHistoryServerPort,
      mapreduceResourcePort,
      mapreduceNodeManagerPort,
      sparkMasterIPAddress,
      sparkHistoryServerPort,
      sparkContextPort,
      jenkinsJob,
      jenkinsIPAddress,
      jenkinsPort,
      hbaseMasterNodesList,
      // hbaseMasterIPAddress,
      // hbaseManagementPort,
      hbaseRegionServerList,
      nameNodeList,
      dataNodeList,
      resourceManagerList,
      elasticsearchNodeList,
      logstashNodeList,
      elasticSearchUserName,
      elasticSearchPassword,
    } = this.state;
    this.props.createProject(
      'InsightAgent',
      {
        instanceType,
        projectCloudType,
        dataType,
        samplingInterval: Number(samplingInterval) * samplingUnit,
        region: (region || []).join(','),
        processPauseFlag,
        largeProject: !!largeProject,
        iamAccessKey,
        secretAccessKey,
        insightAgentType,
        mapreducerMasterIPAddress,
        mapreduceHistoryServerPort,
        mapreduceResourcePort,
        mapreduceNodeManagerPort,
        sparkMasterIPAddress,
        sparkHistoryServerPort,
        sparkContextPort,
        jenkinsJob,
        jenkinsIPAddress,
        jenkinsPort,
        hbaseMasterNodesList,
        // hbaseMasterIPAddress,
        // hbaseManagementPort,
        hbaseRegionServerList,
        nameNodeList,
        dataNodeList,
        resourceManagerList,
        elasticsearchNodeList,
        logstashNodeList,
        elasticSearchUserName,
        elasticSearchPassword,
        ...(isMetric ? {} : { isGroupingByInstance }),
      },
      this.state,
    );
  }

  @autobind
  handleDataTypeChange({ value: dataType }) {
    let samplingInterval = 5;
    const samplingUnit = 60;
    if (dataType !== 'Metric') {
      samplingInterval = 10;
    }
    this.setState(
      { samplingInterval, samplingUnit, insightAgentType: null, dataType, isGroupingByInstance: false },
      () => {
        this.props.saveProjectInfo('InsightAgent', { dataType }, this.state);
      },
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { intl, isLoading } = this.props;
    const { dataType, instanceType, insightAgentType, processPauseFlag, largeProject, isGroupingByInstance } =
      this.state;

    const isMetricAgent = dataType === 'Metric';
    const isAWS = instanceType === 'AWS';
    const isMapReducer = instanceType === 'MapReduce';
    const isSpark = instanceType === 'Spark';
    const isJenkins = instanceType === 'Jenkins';
    const isHBase = instanceType === 'HBase';
    const isElasticSearch = instanceType === 'ElasticSearch';
    const isSplunk = insightAgentType === 'Splunk' || insightAgentType === 'SplunkStreaming';

    let agentOptions = [];
    if (isMetricAgent) {
      agentOptions = this.metricAgentOptions;
    } else {
      agentOptions = this.logAgentOptions;
    }

    const instanceTypeLink = VLink.state(this, 'instanceType').check(
      (x) => Boolean(x),
      'The instance type is required',
    );
    const dataTypeLink = VLink.state(this, 'dataType')
      .check((x) => Boolean(x), 'The data type is required')
      .onChange(this.handleDataTypeChange);
    const insightAgentTypeLink = VLink.state(this, 'insightAgentType').check(
      (x) => Boolean(x),
      'The agent type is needed',
    );
    const samplingIntervalLink = VLink.state(this, 'samplingInterval').check((x) => {
      return Number(x) > 0 && isInteger(Number(x));
    }, 'The sampling interal is required and it has to be an integer');
    const samplingUnitLink = VLink.state(this, 'samplingUnit').check((x) => x, 'The sampling unit is required');
    const regionLink = VLink.state(this, 'region').check((x) => x && x.length > 0, 'The region is required');
    const iamAccessKeyLink = VLink.state(this, 'iamAccessKey').check((x) => Boolean(x), 'IAM Access Key is required');
    const secretAccessKeyLink = VLink.state(this, 'secretAccessKey').check((x) => Boolean(x), 'Secret Key is required');
    const mapreduceMasterIPAddressLink = VLink.state(this, 'mapreducerMasterIPAddress')
      .check((x) => Boolean(x), 'MapReduce Master IP Address is required')
      .check((x) => {
        const groups = R.match(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/g, x);
        return groups.length > 0;
      }, 'Incorrect format');
    const mapreduceHistoryServerPortLink = VLink.state(this, 'mapreduceHistoryServerPort');
    const mapreduceResourcePortLink = VLink.state(this, 'mapreduceResourcePort');
    const mapreduceNodeManagerPortLink = VLink.state(this, 'mapreduceNodeManagerPort');
    const sparkMasterIPAddressLink = VLink.state(this, 'sparkMasterIPAddress').check(
      (x) => Boolean(x),
      'Spark Master IP Address is required',
    );
    const sparkHistoryServerPortLink = VLink.state(this, 'sparkHistoryServerPort');
    const sparkContextPortLink = VLink.state(this, 'sparkContextPort');
    const jenkinsJobLink = VLink.state(this, 'jenkinsJob').check((x) => Boolean(x), 'Jenkins Job is required');
    const jenkinsIPAddressLink = VLink.state(this, 'jenkinsIPAddress').check(
      (x) => Boolean(x),
      'Jenkins IP Address is required',
    );
    const jenkinsPortLink = VLink.state(this, 'jenkinsPort');
    const hbaseMasterNodesLink = VLink.state(this, 'hbaseMasterNodesList').check(
      (x) => Boolean(x),
      'Hbase Master Nodes is required',
    );
    // const hbaseMasterIPAddressLink = VLink.state(this, 'hbaseMasterIPAddress')
    //   .check((x) => Boolean(x), 'HBase Master IP Address is required')
    //   .check((x) => {
    //     const groups = R.match(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/g, x);
    //     return groups.length > 0;
    //   }, 'Incorrect format');
    // const hbaseManagementPortLink = VLink.state(this, 'hbaseManagementPort');
    const hbaseRegionServerListLink = VLink.state(this, 'hbaseRegionServerList').check(
      (x) => Boolean(x),
      'IP Address is required',
    );
    const nameNodeListLink = VLink.state(this, 'nameNodeList').check((x) => Boolean(x), 'IP Address is required');
    const dataNodeListLink = VLink.state(this, 'dataNodeList').check((x) => Boolean(x), 'IP Address is required');
    const resourceManagerListLink = VLink.state(this, 'resourceManagerList').check(
      (x) => Boolean(x),
      'IP Address is required',
    );
    const elasticsearchNodeListLink = VLink.state(this, 'elasticsearchNodeList').check(
      (x) => Boolean(x),
      'IP Address is required',
    );
    const logstashNodeListLink = VLink.state(this, 'logstashNodeList');
    const elasticSearchUserNameLink = VLink.state(this, 'elasticSearchUserName');
    const elasticSearchPasswordLink = VLink.state(this, 'elasticSearchPassword');

    let hasError = dataTypeLink.error || instanceTypeLink.error || insightAgentTypeLink.error || this.props.hasError;
    if (!hasError) {
      hasError = samplingIntervalLink.error || samplingUnitLink.error;

      if (!hasError && isAWS) {
        hasError = regionLink.error || iamAccessKeyLink.error || secretAccessKeyLink.error;
      }

      if (!hasError && isMapReducer) {
        hasError =
          mapreduceMasterIPAddressLink.error ||
          nameNodeListLink.error ||
          dataNodeListLink.error ||
          resourceManagerListLink.error;
      }
      if (!hasError && isSpark) {
        hasError = sparkMasterIPAddressLink.error;
      }
      if (!hasError && isJenkins) {
        hasError = jenkinsIPAddressLink.error || jenkinsJobLink.error;
      }
      if (!hasError && isHBase) {
        hasError = hbaseMasterNodesLink.error || hbaseRegionServerListLink.error;
      }

      if (!hasError && isElasticSearch) {
        hasError = elasticsearchNodeListLink.error;
      }
    }

    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <Card className="ui form">
          <div className="field required">
            <label>Instance Type</label>
            <Select valueLink={instanceTypeLink} options={this.instanceTypeOptions} />
          </div>
          <div className="field required">
            <label>Data Type</label>
            <Select valueLink={dataTypeLink} options={this.dataTypeOptions} onChange={this.handleDataTypeChange} />
          </div>
          <div className="field required">
            <label>Agent Type</label>
            {isSplunk && (
              <div style={{ fontSize: 12 }}>
                InsightFinder's App for Splunk:&nbsp;
                <a href="https://splunkbase.splunk.com/app/3281/" target="_blank" rel="noopener noreferrer">
                  https://splunkbase.splunk.com/app/3281/
                </a>
              </div>
            )}
            <Select valueLink={insightAgentTypeLink} options={agentOptions} />
          </div>
          <div className="field required">
            <label>{isMetricAgent ? 'Sampling interval' : 'Hot/Cold event sampling interval'}</label>
            <span className="hint">
              <i className="info icon" />
              The sampling interval just needs an estimation. InsightFinder figures out the sampling rate dynamically
              from your data.
            </span>
            <div className="flex-row">
              <Input
                type="number"
                min={isMetricAgent ? 1 : 10}
                valueLink={samplingIntervalLink}
                style={{ width: 200 }}
              />
              <Select
                valueLink={samplingUnitLink}
                style={{ width: 130, padding: '2px 0' }}
                options={isMetricAgent ? this.samplingUnit : R.filter((item) => item.value !== 1, this.samplingUnit)}
              />
            </div>
          </div>
          <div className="field">
            <label>Large project</label>
            <span className="hint">
              <i className="info icon" />
              If the instance count for the project exceeds 100,000, it is recommended to enable this flag to enhance
              performance.
            </span>
            <div className="flex-row">
              <Switch
                size="small"
                checked={largeProject}
                onChange={(largeProject) => {
                  this.setState({ largeProject });
                }}
              />
            </div>
          </div>
          {!isMetricAgent && (
            <div>
              <div>{intl.formatMessage(settingsMessages.groupingByInstance)}</div>
              <Checkbox
                checked={isGroupingByInstance}
                onChange={(e) => this.setState({ isGroupingByInstance: e.target.checked })}
              />
            </div>
          )}

          {['LogFile', 'ContainerHistorical'].includes(insightAgentType) && !isMetricAgent && (
            <div className="flex-row flex-center-align" style={{ marginTop: 16 }}>
              <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                Enable initial processing pause
              </div>
              <Switch
                size="small"
                checked={processPauseFlag}
                onChange={(processPauseFlag) => {
                  this.setState({ processPauseFlag });
                }}
              />
            </div>
          )}

          {isAWS && (
            <div className="field">
              <label>Region</label>
              <Select
                multi
                valueLink={regionLink}
                options={[
                  { label: 'us-east-1', value: 'us-east-1' },
                  { label: 'us-west-2', value: 'us-west-2' },
                  { label: 'eu-west-1', value: 'eu-west-1' },
                  { label: 'eu-central-1', value: 'eu-central-1' },
                  { label: 'ap-northeast-1', value: 'ap-northeast-1' },
                  { label: 'ap-northeast-2', value: 'ap-northeast-2' },
                  { label: 'ap-southeast-1', value: 'ap-southeast-1' },
                  { label: 'ap-southeast-2', value: 'ap-southeast-2' },
                  { label: 'sa-east-1', value: 'sa-east-1' },
                ]}
              />
            </div>
          )}
          {isAWS && (
            <div className="field">
              <label>Access Key ID</label>
              <Input valueLink={iamAccessKeyLink} />
            </div>
          )}
          {isAWS && (
            <div className="field">
              <label>Secret Key</label>
              <Input valueLink={secretAccessKeyLink} />
            </div>
          )}
          {isMapReducer && (
            <div className="required field">
              <label>MapReduce Master IP Address</label>
              <Input valueLink={mapreduceMasterIPAddressLink} />
            </div>
          )}
          {isMapReducer && (
            <div className="field">
              <label>MapReduce History Server Port</label>
              <Input valueLink={mapreduceHistoryServerPortLink} placeholder="19888" />
            </div>
          )}
          {isMapReducer && (
            <div className="field">
              <label>MapReduce Resource Port</label>
              <Input valueLink={mapreduceResourcePortLink} placeholder="8088" />
            </div>
          )}
          {isMapReducer && (
            <div className="field">
              <label>MapReduce Node Manager Port</label>
              <Input valueLink={mapreduceNodeManagerPortLink} placeholder="8042" />
            </div>
          )}
          {isSpark && (
            <div className="required field">
              <label>Spark Master IP Address</label>
              <Input valueLink={sparkMasterIPAddressLink} />
            </div>
          )}
          {isSpark && (
            <div className="field">
              <label>Spark History Server Port</label>
              <Input valueLink={sparkHistoryServerPortLink} placeholder="18080" />
            </div>
          )}
          {isSpark && (
            <div className="field">
              <label>Spark Context Port</label>
              <Input valueLink={sparkContextPortLink} placeholder="4040" />
            </div>
          )}
          {isJenkins && (
            <div className="required field">
              <label>Jenkins Job</label>
              <Input valueLink={jenkinsJobLink} />
            </div>
          )}
          {isJenkins && (
            <div className="required field">
              <label>Jenkins Host</label>
              <Input valueLink={jenkinsIPAddressLink} />
            </div>
          )}
          {isJenkins && (
            <div className="field">
              <label>Jenkins Port</label>
              <Input valueLink={jenkinsPortLink} placeholder="8080" />
            </div>
          )}
          {isHBase && (
            <div className="required field">
              <label>
                HBase MasterNode(s)
                <span className="hint" style={{ paddingLeft: 16 }}>
                  list of IP addresses and ports, seperated by ';'
                </span>
              </label>
              <Input valueLink={hbaseMasterNodesLink} placeholder="ip:port;" />
            </div>
          )}
          {/* {isHBase && (
          <div className="required field">
            <label>HBase Master IP Address</label>
            <Input valueLink={hbaseMasterIPAddressLink} />
          </div>
        )}
        {isHBase && (
          <div className="field">
            <label>HBase Management Port</label>
            <Input valueLink={hbaseManagementPortLink} placeholder="16010" />
          </div>
        )} */}
          {(isHBase || isMapReducer) && (
            <div className="required field">
              <label>
                NameNode(s)
                <span className="hint" style={{ paddingLeft: 16 }}>
                  list of IP addresses and ports, seperated by ';'
                </span>
              </label>
              <Input valueLink={nameNodeListLink} placeholder="ip:port;" />
            </div>
          )}
          {(isHBase || isMapReducer) && (
            <div className="required field">
              <label>
                DataNode(s)
                <span className="hint" style={{ paddingLeft: 16 }}>
                  list of IP addresses and ports, seperated by ';'
                </span>
              </label>
              <Input valueLink={dataNodeListLink} placeholder="ip:port;" />
            </div>
          )}
          {(isHBase || isMapReducer) && (
            <div className="required field">
              <label>
                Resource Manager (YARN)
                <span className="hint" style={{ paddingLeft: 16 }}>
                  list of IP addresses and ports, seperated by ';'
                </span>
              </label>
              <Input valueLink={resourceManagerListLink} placeholder="ip:port;" />
            </div>
          )}
          {isHBase && (
            <div className="required field">
              <label>
                RegionServer(s)
                <span className="hint" style={{ paddingLeft: 16 }}>
                  list of IP addresses and ports, seperated by ';'
                </span>
              </label>
              <Input valueLink={hbaseRegionServerListLink} placeholder="ip:port;" />
            </div>
          )}

          {isElasticSearch && (
            <div className="required field">
              <label>
                Elasticsearch Node(s)
                <span className="hint" style={{ paddingLeft: 16 }}>
                  list of IP addresses and ports, seperated by ';'
                </span>
              </label>
              <Input valueLink={elasticsearchNodeListLink} placeholder="ip:port;" />
            </div>
          )}
          {isElasticSearch && (
            <div className="field">
              <label>
                Logstash Node(s)
                <span className="hint" style={{ paddingLeft: 16 }}>
                  list of IP addresses and ports, seperated by ';'
                </span>
              </label>
              <Input valueLink={logstashNodeListLink} placeholder="ip:port;" />
            </div>
          )}
          {isElasticSearch && (
            <div className="field">
              <label>User Name</label>
              <Input valueLink={elasticSearchUserNameLink} />
            </div>
          )}
          {isElasticSearch && (
            <div className="field">
              <label>Password</label>
              <Input valueLink={elasticSearchPasswordLink} />
            </div>
          )}
        </Card>

        <div style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}>
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasError}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

export default InsightAgentSetting;
