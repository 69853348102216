/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { get, isNumber } from 'lodash';
import moment from 'moment';
import * as R from 'ramda';

import { State } from '../../../common/types';
import { Defaults } from '../../../common/app';
import { Container, Tooltip } from '../../../lib/fui/react';
import { createLoadAction } from '../../../common/app/actions';
import { parseLocation, buildLocation } from '../../../common/utils';
import { DotChart, BarChart } from '../../../lib/fui/react/charts';
import { CollapsibleLogContent } from '../../share';
import LogEntryListModal from './LogEntryListModal';
import LogEntryContentModal from './LogEntryContentModal';
import { logMessages } from '../../../common/log/messages';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  push: Function,
  replace: Function,
  width: Number,
  height: Number,

  detailInfos: Object,
  logSeqEntryList: Array<Object>,
  clusterFeatureKeywords: Object,
};

class LogBarChartsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.legendWidth = 128;
    this.legendHeight = 14;
    this.orangeColor = '#f2711c';
    this.redColor = '#db2828';
    this.totalColor = '#cdcdcd';
    this.clusterColor = '#d0f3f8'; // '#9d9d9d';
    this.commonSeqColor = '#666666';
    this.otherColor = '#d0f3f8';
    this.hotColor = 'orange';
    this.coldColor = Defaults.Colorbrewer[9];

    this.barWidthOffset = 0;
    this.barHeightOffset = 150;
    this.state = {
      showRareEvent: false,
      showCriticalEvent: false,
      showEntryEvent: false,
      showAllEntries: false,
      showCurrent: true,
      showTopEntries: false,
      featureKey: null,
      featureKeyword: null,
      showClusterSimpleMsg: false,
      clusterInfo: null,
    };

    this.interval = 10 * 60 * 1000;
    this.xPadding = this.interval;
    this.featureWordFreqVectorAll = {};
    this.featureKeywordMapping = [];

    this.pieWidth = 0;

    this.rarePeriods = [];
    this.criticalKeywordPeriods = [];
    this.hotPeriods = [];
    this.coldPeriods = [];
    this.logEntries = [];
    this.seqPeriods = [];
    this.cListIds = [];
    this.cList = [];
    this.simpleEntries = [];

    this.iconSort = (a, b) => {
      const aWeight = a.hasHot && a.hasMappingInfo ? 3 : a.hasHot ? 2 : a.hasMappingInfo ? 1 : 0;
      const bWeight = b.hasHot && b.hasMappingInfo ? 3 : b.hasHot ? 2 : b.hasMappingInfo ? 1 : 0;
      return bWeight < aWeight ? -1 : bWeight > aWeight ? 1 : 0;
    };
  }

  componentDidMount() {
    this.preprocessData(this.props, this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { replace } = nextProps;
    const params = parseLocation(this.props.location);
    let { showCurrent, showAllEntries, showTopEntries } = this.state;
    const { view, nid, seqId, anomalySeqId, day, featureKey, featureKeyword } = params;
    const nextParams = parseLocation(nextProps.location);
    const dt = moment.utc(day, Defaults.DateFormat);
    const startTs = dt.clone().startOf('day').valueOf();
    const endTs = dt.clone().add(1, 'day').startOf('day').valueOf();

    // Reset the start/end time to auto-select the entries to view.
    const resetTimestamp = () => {
      const { startTimestamp, endTimestamp, view } = nextParams;
      if (!startTimestamp && !endTimestamp) {
        let st = startTs;
        let entries = this.logEntries;

        const isSeq = view === 'seq';
        const isAnomalySeq = view === 'anomalySeq';
        if (isSeq || isAnomalySeq) entries = this.seqPeriods;

        const entry = R.find((s) => Boolean(s[1]), entries);
        if (entry) {
          st = entry[0] ? entry[0].valueOf() : startTs;
        }

        nextParams.startTimestamp = st;
        nextParams.endTimestamp = st + this.interval;
        replace(buildLocation(nextProps.location.pathname, {}, nextParams));
      }

      if (params.startTimestamp !== nextParams.startTimestamp && Boolean(nextParams.startTimestamp)) {
        this.setHighlight(this.getGraphNode(), parseInt(nextParams.startTimestamp, 10));
      }
    };

    if (
      nextProps.detailInfos !== this.props.detailInfos ||
      nid !== nextParams.nid ||
      seqId !== nextParams.seqId ||
      anomalySeqId !== nextParams.anomalySeqId ||
      (view !== nextParams.view && nextParams.view !== 'keywords') ||
      featureKey !== nextParams.featureKey ||
      featureKeyword !== nextParams.featureKeyword ||
      nextProps.logSeqEntryList !== this.props.logSeqEntryList
    ) {
      if (featureKey !== nextParams.featureKey) {
        showCurrent = !nextParams.featureKey;
        showAllEntries = false;
        showTopEntries = false;
      }

      this.preprocessData(nextProps, {
        ...this.state,
        featureKey: nextParams.featureKey,
        featureKeyword: nextParams.featureKeyword,
        showCurrent: nextParams.nid === 'All' ? false : nid !== nextParams.nid ? true : showCurrent,
        showAllEntries:
          nextParams.nid === 'All' ? !nextParams.featureKey : nid !== nextParams.nid ? false : showAllEntries,
        showTopEntries: nid !== nextParams.nid ? false : showTopEntries,
      });
      this.setState(
        {
          chartDateWindow: [startTs - this.xPadding, endTs + this.xPadding],
          showCurrent: nextParams.nid === 'All' ? false : nid !== nextParams.nid ? true : showCurrent,
          showAllEntries:
            nextParams.nid === 'All' ? !nextParams.featureKey : nid !== nextParams.nid ? false : showAllEntries,
          showTopEntries: nid !== nextParams.nid ? false : showTopEntries,
        },
        () => {
          resetTimestamp();
        },
      );
    } else {
      resetTimestamp();
    }
  }

  @autobind
  setHighlight(g, x) {
    if (g) {
      const interval = this.interval;
      const dw = g.xAxisRange();
      const left = g.toDomXCoord(x);
      const rightEdge = g.toDomXCoord(dw[1]);
      const barWidth = g.toDomXCoord(interval) - g.toDomXCoord(0);
      const highlightLeft = left - barWidth / 2;
      const highlightTime = moment.utc(x).format(Defaults.TimeFormat);
      let legendLeft = left + barWidth / 2 - this.legendWidth / 2;
      if (left < this.legendWidth / 2) {
        legendLeft = 0;
      } else if (left > rightEdge - this.legendWidth / 2) {
        legendLeft = rightEdge - this.legendWidth;
      }
      this.setState({
        legendLeft,
        highlightTimestamp: x,
        showHighlight: true,
        highlightLeft,
        highlightWidth: barWidth,
        highlightTime,
      });
    }
  }

  @autobind
  handleDateWindowSync(dateWindow) {
    this.setState({ chartDateWindow: dateWindow, showHighlight: false, showLegend: false });
  }

  @autobind
  preprocessData(props, state) {
    const { detailInfos, location, logSeqEntryList, clusterFeatureKeywords } = props;
    const { day, nid, view, featureKey, featureKeyword, seqId, anomalySeqId } = parseLocation(location);
    const frequencyMap = get(detailInfos, 'frequencyMap', {});
    const totalFreqVector = get(detailInfos, 'totalFreqVector', {});
    const patternFreqVector = get(detailInfos, 'patternFreqVector', {});
    const rareEntries = get(detailInfos, 'rareEntries', []);
    const criticalKeywordEntries = get(detailInfos, 'criticalKeywordEntries', []);
    let clusterInfoList = get(detailInfos, 'clusterInfoList', []);
    if (view === 'newCluster') {
      clusterInfoList = R.filter((i) => i.newPatternFlag, clusterInfoList);
    }

    const sobj = moment.utc(day, Defaults.DateFormat).startOf('day');
    const eobj = sobj.clone().add(1, 'day');
    const isSeq = view === 'seq';
    const isCluster = view === 'cluster' || view === 'newCluster';
    const isAnomalySeq = view === 'anomalySeq';
    const showAllEntries = get(state, ['showAllEntries'], false);
    const showCurrent = get(state, ['showCurrent'], true);
    const showTopEntries = get(state, ['showTopEntries'], false);

    const startTs = sobj.valueOf() - this.xPadding;
    const endTs = eobj.valueOf() + this.xPadding;
    const xstart = new Date(startTs);
    const xend = new Date(endTs);

    let hotPeriods = [];
    let coldPeriods = [];
    let logEntries = [];
    let topClusterLogEntries = [];
    let rarePeriods = [];
    let seqPeriods = [];
    let criticalKeywordPeriods = [];
    let freqVector = {};
    let cListIds = [];
    let topFreqVectorTs = [];
    const topFreqVector = {};
    let featureWordFreqVector = {};

    this.labels = ['timestamp', 'Y1'];
    const labelsTotal = showAllEntries ? ['Y1'] : [];
    if (isCluster) {
      const labelsCur = showCurrent ? ['Pattern Entries'] : [];
      const labelsFK = featureKey ? ['Pattern Feature Keyword'] : [];
      this.labels = ['timestamp', ...labelsTotal, ...labelsCur, ...labelsFK];
    }

    if (isCluster) {
      let inid = parseInt(nid, 10);
      if (nid === 'MISC') inid = -2;

      // featureKeyword data parse
      if (featureKey && (!isNaN(inid) || nid === 'All')) {
        let featureKeywords = get(clusterFeatureKeywords, nid);
        const topFeatureWord = get(featureKeywords, 'topFeatureWord', []);
        const topMappingInfo = get(featureKeywords, 'topMappingInfo', {});
        featureKeywords = topFeatureWord || featureKeywords || [];

        // TODO:need format
        this.featureWordFreqVectorAll = {};
        let featureKeywordsObj = R.find((f) => f.matchWord === featureKeyword, featureKeywords);
        if (featureKeywordsObj) {
          featureWordFreqVector = get(featureKeywordsObj, ['info', 'freqVector'], {});
        } else {
          const [map, key] = R.split('[', featureKeyword);
          const keyList = get(topMappingInfo, map);
          featureKeywordsObj = get(keyList, R.replace(']', '', key || ''));
          this.featureWordFreqVectorAll = get(featureKeywordsObj, 'freqVector', {});
          featureWordFreqVector = R.mapObjIndexed((num, key, obj) => num.count, this.featureWordFreqVectorAll);
        }
      }

      // top10 cluster  data parse
      if (showTopEntries) {
        const cList = R.sortWith([R.descend(R.prop('eventsCount'))])(clusterInfoList);
        cListIds = R.map((cluster) => cluster.nid, R.slice(0, 10, cList));
        this.cList = R.slice(0, 10, cList);
        this.cListIds = cListIds;
        const cListLabels = R.map((cid) => `Cluster-${cid}`, cListIds);
        this.labels = ['timestamp', ...labelsTotal, ...cListLabels];
        const topFreqVectorList = R.map(
          (clusterNid) => [parseInt(clusterNid, 10), get(patternFreqVector, parseInt(clusterNid, 10), {})],
          cListIds,
        );
        R.forEach((item) => {
          const [nid, value] = item;
          R.forEachObjIndexed((count, timestamp) => {
            if (topFreqVectorTs.indexOf(timestamp) < 0) {
              topFreqVector[timestamp] = { [nid]: count };
              topFreqVectorTs.push(timestamp);
            } else {
              topFreqVector[timestamp][nid] = count;
            }
          }, value);
        }, topFreqVectorList);
        topFreqVectorTs = R.sort(R.ascend, topFreqVectorTs);
      }

      if (!isNaN(inid)) {
        const frequencyByNid = get(frequencyMap, inid, {});
        this.patternFreqVector = frequencyByNid;
        freqVector = get(patternFreqVector, inid, {});

        R.forEachObjIndexed((value, timestamp) => {
          if (!R.has(timestamp, freqVector)) {
            freqVector[timestamp] = Math.max(value.val, 1);
          }
          timestamp = Math.floor(timestamp / this.interval) * this.interval;
          if (value.isHot) hotPeriods.push(timestamp);
          if (value.isCold) coldPeriods.push(timestamp);
        }, frequencyByNid);
      } else {
        R.forEachObjIndexed((frequencyByNid) => {
          R.forEachObjIndexed((value, timestamp) => {
            timestamp = Math.floor(timestamp / this.interval) * this.interval;
            if (value.isHot) hotPeriods.push(timestamp);
            if (value.isCold) coldPeriods.push(timestamp);
          }, frequencyByNid);
        }, frequencyMap);
        this.patternFreqVector = {};
      }
    }

    if (isCluster) {
      rarePeriods = R.map(
        (t) => [new Date(t), 1],
        R.sort(
          (a, b) => a - b,
          R.uniq(
            R.filter(
              (a) => a && a >= startTs && a <= endTs,
              R.map((e) => Math.floor(e.timestamp / this.interval) * this.interval, rareEntries),
            ),
          ),
        ),
      );
      criticalKeywordPeriods = R.map(
        (t) => [new Date(t), 1],
        R.sort(
          (a, b) => a - b,
          R.uniq(
            R.filter(
              (a) => a && a >= startTs && a <= endTs,
              R.map((e) => Math.floor(e.timestamp / this.interval) * this.interval, criticalKeywordEntries),
            ),
          ),
        ),
      );
    }

    if (isCluster) {
      hotPeriods = R.map(
        (t) => [new Date(t), 1],
        R.sort((a, b) => a - b, R.uniq(R.filter((a) => Boolean(a) && a >= startTs && a <= endTs, hotPeriods))),
      );
      coldPeriods = R.map(
        (t) => [new Date(t), 1],
        R.sort((a, b) => a - b, R.uniq(R.filter((a) => Boolean(a) && a >= startTs && a <= endTs, coldPeriods))),
      );
    }

    if (isCluster) {
      if (showAllEntries) {
        R.forEachObjIndexed((total, ts) => {
          const count = get(freqVector, ts, 0);
          let countData = showCurrent ? [count] : [];
          if (featureKey) {
            const countFeatureWord = get(featureWordFreqVector, ts, 0);
            countData = [...countData, countFeatureWord];
          }
          logEntries.push([parseInt(ts, 10), total, ...countData]);
        }, totalFreqVector);
      } else if (showCurrent) {
        R.forEachObjIndexed((count, ts) => {
          let countData = showCurrent ? [count] : [];
          if (featureKey) {
            const countFeatureWord = get(featureWordFreqVector, ts, 0);
            countData = [...countData, countFeatureWord];
          }
          logEntries.push([parseInt(ts, 10), ...countData]);
        }, freqVector);
      } else if (featureKey) {
        R.forEachObjIndexed((count, timestamp) => {
          logEntries.push([parseInt(timestamp, 10), count]);
        }, featureWordFreqVector);
      }

      // topClusterLogEntries
      if (showTopEntries) {
        if (showAllEntries) {
          R.forEachObjIndexed((total, ts) => {
            // add sum by reverse
            const countData = [];
            let sum = 0;
            R.forEach((cid) => {
              const count = get(topFreqVector, [ts, cid], null);
              sum = count ? sum + count : sum;
              countData.push(count ? sum : count);
            }, R.reverse(cListIds));
            topClusterLogEntries.push([parseInt(ts, 10), total, ...R.reverse(countData)]);
          }, totalFreqVector);
        } else {
          R.forEachObjIndexed((ts) => {
            // add sum by reverse
            const countData = [];
            let sum = 0;
            R.forEach((cid) => {
              const count = get(topFreqVector, [ts, cid], null);
              sum = count ? sum + count : sum;
              countData.push(count ? sum : count);
            }, R.reverse(cListIds));
            topClusterLogEntries.push([parseInt(ts, 10), ...R.reverse(countData)]);
          }, topFreqVectorTs);
        }
      }
    } else {
      R.forEachObjIndexed((total, ts) => {
        logEntries.push([parseInt(ts, 10), total]);
      }, totalFreqVector);
    }

    // logEntries 处理, 过滤掉没有数据的
    if (!showCurrent) {
      logEntries = R.filter((l) => {
        const [date, ...counts] = l;
        return R.sum(counts) > 0;
      }, logEntries);
    }
    // sort by timestamp
    logEntries = R.map(
      (item) => [new Date(Number(item[0])), ...R.slice(1, Infinity, item)],
      R.sortBy(R.prop(0), logEntries),
    );
    topClusterLogEntries = R.map(
      (item) => [new Date(Number(item[0])), ...R.slice(1, Infinity, item)],
      R.sortBy(R.prop(0), topClusterLogEntries),
    );

    if (((isSeq && seqId) || (isAnomalySeq && anomalySeqId)) && logSeqEntryList) {
      const tsMap = {};
      R.forEach((elist) => {
        if (elist.length > 0) {
          let ts = elist[0].timestamp;
          if (ts) {
            ts = Math.floor(ts / this.interval) * this.interval;
            tsMap[ts] = (tsMap[ts] || 0) + 1;
          }
        }
      }, logSeqEntryList);

      seqPeriods = R.map(
        (ts) => [new Date(Number(ts)), tsMap[ts]],
        R.sort((a, b) => a - b, R.keys(tsMap)),
      );
    }

    // Append start & end if missing
    if (rarePeriods.length > 0) {
      if (rarePeriods[0][0].valueOf() > startTs) {
        rarePeriods = [[xstart, null], ...rarePeriods];
      }
      if (rarePeriods[rarePeriods.length - 1][0].valueOf() < endTs) {
        rarePeriods = [...rarePeriods, [xend, null]];
      }
    } else {
      rarePeriods = [
        [xstart, null],
        [xend, null],
      ];
    }

    if (seqPeriods.length > 0) {
      if (seqPeriods[0][0].valueOf() > startTs) {
        seqPeriods = [[xstart, null], ...seqPeriods];
      }
      if (seqPeriods[seqPeriods.length - 1][0].valueOf() < endTs) {
        seqPeriods = [...seqPeriods, [xend, null]];
      }
    } else {
      seqPeriods = [
        [xstart, null],
        [xend, null],
      ];
    }

    if (criticalKeywordPeriods.length > 0) {
      if (criticalKeywordPeriods[0][0].valueOf() > startTs) {
        criticalKeywordPeriods = [[xstart, null], ...criticalKeywordPeriods];
      }
      if (criticalKeywordPeriods[criticalKeywordPeriods.length - 1][0].valueOf() < endTs) {
        criticalKeywordPeriods = [...criticalKeywordPeriods, [xend, null]];
      }
    } else {
      criticalKeywordPeriods = [
        [xstart, null],
        [xend, null],
      ];
    }

    let addNull = showCurrent ? [null] : [];
    addNull = featureKey ? [...addNull, null] : addNull;
    if (isCluster && showAllEntries) {
      if (logEntries.length > 0) {
        if (logEntries[0][0].valueOf() > startTs) {
          logEntries = [[xstart, null, ...addNull], ...logEntries];
        }
        if (logEntries[logEntries.length - 1][0].valueOf() < endTs) {
          logEntries = [...logEntries, [xend, null, ...addNull]];
        }
      } else {
        logEntries = [
          [xstart, null, ...addNull],
          [xend, null, ...addNull],
        ];
      }
    } else if (logEntries.length > 0) {
      if (logEntries[0][0].valueOf() > startTs) {
        logEntries = [[xstart, ...addNull], ...logEntries];
      }
      if (logEntries[logEntries.length - 1][0].valueOf() < endTs) {
        logEntries = [...logEntries, [xend, ...addNull]];
      }
    } else {
      logEntries = [
        [xstart, ...addNull],
        [xend, ...addNull],
      ];
    }

    // parse top cluster log entries
    addNull = R.map((c) => null, cListIds);
    addNull = showAllEntries ? [...addNull, null] : addNull;
    if (topClusterLogEntries.length > 0) {
      if (topClusterLogEntries[0][0].valueOf() > startTs) {
        topClusterLogEntries = [[xstart, ...addNull], ...topClusterLogEntries];
      }
      if (topClusterLogEntries[topClusterLogEntries.length - 1][0].valueOf() < endTs) {
        topClusterLogEntries = [...topClusterLogEntries, [xend, ...addNull]];
      }
    }

    this.rarePeriods = rarePeriods;
    this.criticalKeywordPeriods = criticalKeywordPeriods;
    this.hotPeriods = hotPeriods;
    this.coldPeriods = coldPeriods;
    this.logEntries = showTopEntries ? topClusterLogEntries : logEntries;
    this.seqPeriods = seqPeriods;
  }

  @autobind
  handleHighlightCallback(e, x, points, row, seriesName, g) {
    if (g) {
      this.setHighlight(g, x);
    }
  }

  @autobind
  handleUnhighlightCallback(e, g) {
    const { location } = this.props;
    const params = parseLocation(location);
    const { startTimestamp } = params;
    if (startTimestamp) {
      this.setHighlight(this.getGraphNode(), parseInt(startTimestamp, 10));
    } else {
      this.setState({ showHighlight: false });
    }
  }

  @autobind
  handleBarColorCallback(x, sidx, name, y) {
    // Only set for cluster entry
    if (name === 'Pattern Entries') {
      const info = get(this.patternFreqVector, x);
      if (info) {
        if (info.isHot) return 'orange';
        if (info.isCold) return Defaults.Colorbrewer[9];
      } else {
        return this.clusterColor;
      }
    } else if (name === 'Pattern Feature Keyword') {
      return Defaults.Colorbrewer[4];
    } else if (name === 'Top-Y1') {
      return this.otherColor;
    } else if (name.indexOf('Cluster-') >= 0) {
      const cid = R.split('-', name)[1];
      return this.getClusterColor(cid, sidx, this.state.showAllEntries);
    }
    return null;
  }

  @autobind
  getClusterColor(nid, index, showAllEntries) {
    let idx = index;
    idx = showAllEntries ? idx - 1 : idx;
    return Defaults.ColorbrewerMore[idx];
  }

  @autobind
  handleClickCallback(e, x, points, g) {
    if (isNumber(x)) {
      const { push, location } = this.props;
      const params = parseLocation(location);
      params.startTimestamp = x;
      params.endTimestamp = x + this.interval;

      push(buildLocation(location.pathname, {}, params));

      // // get mapping info keywords
      // if (this.featureWordFreqVectorAll) {
      //   const featureKeywordMapping = [];
      //   const values = get(this.featureWordFreqVectorAll, [x, 'values'], {});
      //   R.forEachObjIndexed((v, k) => {
      //     featureKeywordMapping.push(k);
      //   }, values);
      //   this.featureKeywordMapping = featureKeywordMapping;
      // }

      // let showEntryEvent = true;
      // const info = get(this.patternFreqVector, x);
      // if (info && info.val === 0) {
      //   showEntryEvent = false;
      // }

      // const isCluster = params.view === 'cluster' || params.view === 'newCluster';
      // if (isCluster) {
      //   this.setState({ showEntryEvent });
      // }
    }
  }
  @autobind
  handleCriticalEventClick(e, x, points, g) {
    if (x) {
      this.setState({ showCriticalEvent: true, selectedTimestamp: x });
    }
  }

  @autobind
  handleRarePeroidClick(e, x, pointer, g) {
    if (x) {
      this.setState({ showRareEvent: true, selectedTimestamp: x });
    }
  }

  @autobind
  handleSeqPeroidClick(e, x, pointer, g) {
    if (x) {
      this.setState({ selectedTimestamp: x }, () => {
        const { push, location } = this.props;
        const params = parseLocation(location);
        params.startTimestamp = x;
        params.endTimestamp = x + this.interval;
        push(buildLocation(location.pathname, {}, params));
      });
    }
  }

  @autobind
  getGraphNode() {
    if (this.chartNode) {
      return this.chartNode.getGraphNode();
    }
    return null;
  }

  @autobind
  toggleShowAllEntries() {
    const showAllEntries = !this.state.showAllEntries;
    this.preprocessData(this.props, { ...this.state, showAllEntries });
    this.setState({ showAllEntries });
  }
  @autobind
  toggleShowCurrent() {
    const { push, location } = this.props;
    const params = parseLocation(location);
    const showCurrent = !this.state.showCurrent;
    const showTopEntries = false;
    this.preprocessData(this.props, { ...this.state, showCurrent, showTopEntries });
    this.setState({ showCurrent, showTopEntries }, () => {
      push(buildLocation(location.pathname, {}, { ...params, showTopEntries: null, cListIds: null }));
    });
  }
  @autobind
  toggleShowTop() {
    const { push, location, detailInfos } = this.props;
    const params = parseLocation(location);
    const showTopEntries = !this.state.showTopEntries;
    const showCurrent = false;
    this.preprocessData(this.props, { ...this.state, showTopEntries, showCurrent });
    this.setState({ showTopEntries, showCurrent }, () => {
      let cListIds = [];
      if (showTopEntries) {
        let clusterInfoList = get(detailInfos, 'clusterInfoList', []);
        clusterInfoList = R.sortWith([R.descend(R.prop('eventsCount'))])(clusterInfoList);
        cListIds = R.map((cluster) => String(cluster.nid), R.slice(0, 10, clusterInfoList));
      }
      push(
        buildLocation(
          location.pathname,
          {},
          { ...params, showTopEntries: showTopEntries ? 'true' : '', cListIds: JSON.stringify(cListIds) },
        ),
      );
    });
  }

  onTopClusterClick(c) {
    const params = parseLocation(this.props.location);
    const { day } = params;
    let msgs = get(c, 'sampleMsg', []);
    msgs = R.type(msgs) !== 'Array' ? [msgs] : msgs;
    return (e) => {
      this.simpleEntries = R.map(
        (m) => ({
          datetime: day,
          rawData: m,
        }),
        msgs,
      );
      this.setState({
        showClusterSimpleMsg: true,
        clusterInfo: c,
      });
    };
  }

  render() {
    const { intl, location, detailInfos, clusterInfo } = this.props;
    const { width, height } = this.props;
    const { chartDateWindow, showAllEntries, showCurrent, showTopEntries } = this.state;
    const { highlightLeft, highlightWidth, legendLeft, highlightTime, selectedTimestamp } = this.state;
    const clusterKeywords = get(clusterInfo, ['keywords'], []);

    let { showHighlight } = this.state;
    const { highlightTimestamp } = this.state;
    const { projectName, startTimestamp, view, featureKey, featureKeyword, nid } = parseLocation(location);
    if (startTimestamp) {
      showHighlight = true;
    }
    const isSeq = view === 'seq';
    const isCluster = view === 'cluster' || view === 'newCluster';
    const isAnomalySeq = view === 'anomalySeq';
    const infoBarCount = 4;
    const barWidthOffset = showTopEntries ? this.barWidthOffset : 0;
    let barHeightOffset = showTopEntries ? this.barHeightOffset : 0;
    const clusterCount = Math.min(10, this.cList ? this.cList.length : 0);
    barHeightOffset -= showTopEntries ? (10 - clusterCount) * 14 : 0;

    let ainfo = null;
    if (showHighlight && highlightTimestamp) {
      ainfo = get(this.patternFreqVector, highlightTimestamp);
    }
    let criticalKeywordEntries = get(detailInfos, 'criticalKeywordEntries', []);
    let rareEntries = get(detailInfos, 'rareEntries', []);
    if (selectedTimestamp) {
      criticalKeywordEntries = R.filter(
        (e) => e.timestamp >= selectedTimestamp && e.timestamp <= selectedTimestamp + this.interval,
        criticalKeywordEntries,
      );
      criticalKeywordEntries = R.uniqWith(R.allPass([R.eqProps('timestamp'), R.eqProps('rawData')]))(
        criticalKeywordEntries,
      );
      rareEntries = R.filter(
        (e) => e.timestamp >= selectedTimestamp && e.timestamp <= selectedTimestamp + this.interval,
        rareEntries,
      );
    }

    return (
      <Container className="flex-row log-barcharts" style={{ width, height }}>
        <div className="flex-col" style={{ width: width - this.pieWidth }}>
          <div className="legend-box" style={{ height: this.legendHeight }}>
            <div className="flex-grow flex-row" style={{ padding: '0 8px' }}>
              {isCluster && !featureKey && nid !== 'All' && (
                <div className="flex-row flex-center-align" style={{ height: this.legendHeight, marginRight: 16 }}>
                  <input type="checkbox" checked={showCurrent} value={showCurrent} onChange={this.toggleShowCurrent} />
                  <div
                    style={{
                      width: 8,
                      height: 10,
                      marginLeft: 6,
                      display: 'inline-block',
                      backgroundColor: this.clusterColor,
                    }}
                  />
                  <div style={{ marginLeft: 2 }}>{intl.formatMessage(logMessages.currentCluster)}</div>
                </div>
              )}
              {isCluster && !featureKey && (
                <div className="flex-row flex-center-align" style={{ height: this.legendHeight, marginRight: 16 }}>
                  <input
                    type="checkbox"
                    checked={showAllEntries}
                    value={showAllEntries}
                    onChange={this.toggleShowAllEntries}
                  />
                  <div
                    style={{
                      width: 8,
                      height: 10,
                      marginLeft: 6,
                      display: 'inline-block',
                      backgroundColor: this.totalColor,
                    }}
                  />
                  <div style={{ marginLeft: 2 }}>{intl.formatMessage(logMessages.total)}</div>
                </div>
              )}
              {isCluster && !featureKey && nid === 'All' && (
                <div className="flex-row flex-center-align" style={{ height: this.legendHeight, marginRight: 16 }}>
                  <input
                    type="checkbox"
                    checked={showTopEntries}
                    value={showTopEntries}
                    onChange={this.toggleShowTop}
                  />
                  <div style={{ marginLeft: 6 }}>{intl.formatMessage(logMessages.top10Patterns)}</div>
                </div>
              )}
            </div>

            <div className="flex-row">
              {isSeq && (
                <div className="legend" style={{ height: this.legendHeight }}>
                  <span className="legend-icon critical" style={{ backgroundColor: this.clusterColor }} />
                  <span className="legend-text">{intl.formatMessage(logMessages.commonSequence)}</span>
                </div>
              )}
              {isAnomalySeq && (
                <div className="legend" style={{ height: this.legendHeight }}>
                  <span className="legend-icon critical" style={{ backgroundColor: this.clusterColor }} />
                  <span className="legend-text">{intl.formatMessage(logMessages.anomalySequence)}</span>
                </div>
              )}
              {isCluster && (
                <div className="legend" style={{ height: this.legendHeight }}>
                  <span className="legend-icon critical" style={{ backgroundColor: Defaults.Colorbrewer[4] }} />
                  <span className="legend-text">{intl.formatMessage(logMessages.selectFeature)}</span>
                </div>
              )}
              {isCluster && (
                <div className="legend" style={{ height: this.legendHeight }}>
                  <span className="legend-icon rare" style={{ backgroundColor: this.coldColor }} />
                  <span className="legend-text">{intl.formatMessage(logMessages.cold)}</span>
                </div>
              )}
              {isCluster && (
                <div className="legend" style={{ height: this.legendHeight }}>
                  <span className="legend-icon rare" style={{ backgroundColor: this.hotColor }} />
                  <span className="legend-text">{intl.formatMessage(logMessages.hot)}</span>
                </div>
              )}
              {isCluster && (
                <div className="legend" style={{ height: this.legendHeight }}>
                  <span className="legend-icon rare" style={{ backgroundColor: this.orangeColor }} />
                  <span className="legend-text">{intl.formatMessage(logMessages.rare)}</span>
                </div>
              )}
              {isCluster && (
                <div className="legend" style={{ height: this.legendHeight }}>
                  <span className="legend-icon critical" style={{ backgroundColor: this.redColor }} />
                  <span className="legend-text">{intl.formatMessage(logMessages.critical)}</span>
                </div>
              )}
            </div>
          </div>

          <Container className="chart-box" style={{ height: height - this.legendHeight, width: width - this.pieWidth }}>
            {showTopEntries && (
              <div
                className="legend-box"
                style={{
                  paddingTop: 10,
                  paddingLeft: 52,
                  paddingRight: 10,
                  height: barHeightOffset,
                  width: width - this.pieWidth - 8,
                  display: 'block',
                }}
              >
                {R.addIndex(R.map)((c, idx) => {
                  return (
                    <div
                      className="legend"
                      style={{ height: this.legendHeight, textAlign: 'left', paddingRight: 0 }}
                      key={idx}
                    >
                      <Tooltip
                        title={intl.formatMessage(logMessages.clickForSample)}
                        placement="top"
                        className="flex-col log-event-group"
                        style={{ width: 'auto', height: '100%', display: 'inline-block' }}
                      >
                        <div style={{ height: '100%' }}>
                          <span
                            className="legend-icon critical"
                            style={{ backgroundColor: this.getClusterColor(c.nid, idx), cursor: 'pointer' }}
                            onClick={this.onTopClusterClick(c)}
                          />
                        </div>
                      </Tooltip>
                      <span
                        className="legend-text"
                        style={{
                          width: width - this.pieWidth - (52 + 10) - 24,
                          wordBreak: 'break-all',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span style={{ paddingRight: 4 }}>{`[Pattern ${c.nid}]`}</span>
                        <span style={{ paddingRight: 4 }}>{c.keywordsStr}</span>
                      </span>
                    </div>
                  );
                }, this.cList)}
              </div>
            )}
            <div
              className="highlight-box"
              style={{
                top: showTopEntries ? 42 + barHeightOffset : 42,
                display: showHighlight ? 'inherit' : 'none',
                left: highlightLeft ? highlightLeft - 1 : undefined,
                width: highlightWidth ? highlightWidth + 2 : undefined,
              }}
            />
            <div
              className="legend-bar"
              style={{
                left: showHighlight ? legendLeft || 0 : undefined,
                display: showHighlight ? 'inherit' : 'none',
              }}
              ref={(c) => (this.legendNode = c)}
            >
              {highlightTime}
            </div>
            {(isSeq || isAnomalySeq) && <div style={{ flex: `0 0 ${this.legendHeight * 1.5}px` }} />}
            {(isSeq || isAnomalySeq) && <div style={{ flex: `0 0 ${this.legendHeight * 1.5}px` }} />}
            {isCluster && (
              <div style={{ height: this.legendHeight * 1.5 }}>
                {this.criticalKeywordPeriods.length > 0 && (
                  <DotChart
                    style={{ cursor: 'pointer' }}
                    width={width - this.pieWidth - barWidthOffset}
                    height={this.legendHeight * 1.5}
                    interval={this.interval}
                    color={this.redColor}
                    data={this.criticalKeywordPeriods}
                    dateWindow={chartDateWindow}
                    onDateWindowChange={this.handleDateWindowSync}
                    highlightCallback={this.handleHighlightCallback}
                    unhighlightCallback={this.handleUnhighlightCallback}
                    clickCallback={this.handleCriticalEventClick}
                  />
                )}
              </div>
            )}
            {isCluster && (
              <div style={{ height: this.legendHeight * 1.5 }}>
                {this.rarePeriods.length > 0 && (
                  <DotChart
                    width={width - this.pieWidth - barWidthOffset}
                    height={this.legendHeight * 1.5}
                    style={{ cursor: 'pointer' }}
                    interval={this.interval}
                    color={this.orangeColor}
                    data={this.rarePeriods}
                    dateWindow={chartDateWindow}
                    onDateWindowChange={this.handleDateWindowSync}
                    highlightCallback={this.handleHighlightCallback}
                    unhighlightCallback={this.handleUnhighlightCallback}
                    clickCallback={this.handleRarePeroidClick}
                  />
                )}
              </div>
            )}
            <div className="flex-grow" style={{ position: 'relative' }}>
              {((isCluster && this.logEntries.length > 0) || (!isCluster && this.seqPeriods.length > 0)) && (
                <BarChart
                  ref={(c) => (this.chartNode = c)}
                  height={height - this.legendHeight * infoBarCount - this.legendHeight - barHeightOffset}
                  width={width - this.pieWidth - barWidthOffset}
                  colors={isCluster ? [this.totalColor, this.clusterColor] : [this.clusterColor]}
                  interval={this.interval}
                  data={isCluster ? this.logEntries : this.seqPeriods}
                  labels={this.labels}
                  dateWindow={chartDateWindow}
                  onDateWindowChange={this.handleDateWindowSync}
                  highlightCallback={this.handleHighlightCallback}
                  unhighlightCallback={this.handleUnhighlightCallback}
                  clickCallback={this.handleClickCallback}
                  barColorCallback={this.handleBarColorCallback}
                  featureKeyword={featureKeyword}
                />
              )}
            </div>
            <div style={{ height: this.legendHeight, color: 'red', fontSize: 14 }}>
              {isCluster && ainfo && showCurrent
                ? ainfo.isCold
                  ? intl.formatMessage(logMessages.clusterFrequencyLower, {
                      percent: Math.abs(ainfo.pct).toFixed(2),
                      count: Math.abs(ainfo.val),
                    })
                  : intl.formatMessage(logMessages.clusterFrequencyHigher, {
                      percent: Math.abs(ainfo.pct).toFixed(2),
                      count: Math.abs(ainfo.val),
                    })
                : ''}
            </div>
          </Container>
        </div>
        {this.state.showRareEvent && rareEntries.length > 0 && (
          <LogEntryListModal
            entryList={rareEntries}
            projectName={projectName}
            onClose={() => this.setState({ showRareEvent: false })}
            hasContext
            showLinkedJump
          />
        )}
        {this.state.showCriticalEvent && criticalKeywordEntries.length > 0 && (
          <LogEntryListModal
            entryList={criticalKeywordEntries}
            projectName={projectName}
            highlightWord={R.uniq(R.map((e) => e.keyword, criticalKeywordEntries))}
            onClose={() => this.setState({ showCriticalEvent: false })}
            isCritical
            hasContext
            showLinkedJump
          />
        )}
        {this.state.showClusterSimpleMsg && this.simpleEntries.length > 0 && (
          <LogEntryListModal
            hasType={false}
            showTimeRange={false}
            titleMsg={`Count: ${get(this.state.clusterInfo, 'eventsCount', 0)}`}
            entryList={this.simpleEntries}
            projectName={projectName}
            onClose={() => this.setState({ showClusterSimpleMsg: false })}
            hasContext
            showLinkedJump
          />
        )}
        {this.state.showEntryEvent && (
          <LogEntryContentModal
            clusterKeywords={clusterKeywords}
            showTopEntries={showTopEntries}
            cListIds={R.map((cid) => String(cid), this.cListIds)}
            featureKeywordMapping={this.featureKeywordMapping}
            onClose={() => this.setState({ showEntryEvent: false })}
          />
        )}
      </Container>
    );
  }
}

const LogBarCharts = injectIntl(LogBarChartsCore);
export default connect(
  (state: State, props) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { detailInfos: propsDetailInfos } = props;
    const logSeqEntryList = get(state.log, 'logSeqEntryList', []);
    const { detailInfos, clusterFeatureKeywords } = state.log;
    return {
      location,
      loadStatus,
      detailInfos: propsDetailInfos || detailInfos,
      logSeqEntryList,
      clusterFeatureKeywords,
    };
  },
  { push, replace, createLoadAction },
)(LogBarCharts);
