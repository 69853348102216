/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import appLogic from './app/logic';
import authLogic from './auth/logic';
import metricLogic from './metric/logic';
import logLogic from './log/logic';
import jobLogic from './job/logic';
import queryLogic from './query/logic';
import settingsLogic from './settings/logic';
import dashboardLogic from './dashboard/logic';
import causalLogic from './causal/logic';
import reportLogic from './report/logic';
import usecaseLogic from './usecase/logic';

const logic = [
  ...appLogic,
  ...authLogic,
  ...queryLogic,
  ...metricLogic,
  ...logLogic,
  ...jobLogic,
  ...settingsLogic,
  ...dashboardLogic,
  ...causalLogic,
  ...reportLogic,
  ...usecaseLogic,
];

const configureLogic = () => {
  return logic;
};

export default configureLogic;
