/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { showAppAlert, updateLastActionInfo } from '../../app/actions';
import { ActionTypes } from '../actions';
import { getLoadStatusActions } from '../../utils';
import { changePassword } from '../../apis';
import { apiErrorHandle } from '../../errors';

const changePasswordLogic = createLogic({
  type: [ActionTypes.CHANGE_PASSWORD],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ action }, dispatch, done) => {
    const { params, loader } = action.payload;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    changePassword(params)
      .then((data) => {
        if (data.success) {
          dispatch(
            showAppAlert('info', {
              defaultMessage: data.message,
              id: 'auth.info.changePassword',
            }),
          );
        } else {
          dispatch(
            showAppAlert('error', {
              defaultMessage: data.message,
              id: 'auto.error.changePassword',
            }),
          );
        }
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed reset new password, ignored', err);
        dispatch(apiErrorHandle(err));
        dispatch(hideLoading);
      })
      .then(() => {
        done();
      });
  },
});

export default changePasswordLogic;
