/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import { createLogic } from 'redux-logic';
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { get, round, toInteger, isArray } from 'lodash';

import {
  getProjectInfo,
  getMetricEventList,
  getMetricLineCharts,
  getInstanceMetaData,
  getActiveInstance,
  getAnomalyInstance,
  getLineChartSystemBasicInfo,
  getLineChartInstanceDisplayName,
} from '../../apis';
import { ActionTypes } from '../actions';
import { ActionTypes as AppActionTypes, createSetAction, updateLastActionInfo } from '../../app/actions';
import { apiEpicErrorHandle, apiErrorHandle } from '../../errors';

import { calculationLogic, calculationEventLogic } from './newMetricChartDataLogic';
import { getLoadStatusActions, ifIn, BackgroundCall, parseJSON } from '../../utils';
import fetchGet from '../../apis/fetchGet';
import getEndpoint from '../../apis/getEndpoint';
import fetchPost from '../../apis/fetchPost';

const getProjectAllInfo = async (projectName, allProjects, startTimeObj, endTimeObj, credentials) => {
  // Get the project information
  let pname = projectName;
  let projectOwner;

  if (projectName && projectName.indexOf('@') >= 0) {
    pname = projectName.split('@')[0];
    projectOwner = projectName.split('@')[1];
  }
  const proj = R.find((p) => p.projectName === projectName, allProjects);
  let projectInfo = proj;

  let projectList = [];
  if (projectInfo) {
    projectOwner = projectOwner || projectInfo.owner;
  }
  if (!projectInfo || (proj && !proj.hasAllInstanceInfo) || (proj && !proj.instanceStructureSet)) {
    try {
      projectList = await getProjectInfo(credentials, {
        projectList: [{ projectName: pname, customerName: projectOwner }],
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        includeInstance: true,
      });
      projectInfo = { ...(projectInfo || {}), ...projectList[0] };
    } catch (e) {
      // console.debug(e);
    }
  }

  return { pname, projectInfo, projectOwner, projectList };
};

const getIncidentList = (projectName, startTimestamp, endTimestamp, instanceList, credentials) => {
  return fetchPost(getEndpoint('incident'), {
    ...credentials,
    projectName,
    startTime: startTimestamp,
    endTime: endTimestamp,
    instanceWithAnomalyList: JSON.stringify(instanceList || []),
    operation: 'display',
  });
};

const loadMetricEventLineChartsLogic = createLogic({
  type: [ActionTypes.LOAD_METRIC_EVENT_LINECHARTS],
  cancelType: AppActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials, userInfo } = state.auth;
    const { allProjects, projectInstanceComponentMap, userList } = state.app;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);
    const {
      filterPredict,
      projectName,
      instanceGroup,
      startTimeObj,
      endTimeObj,
      eventType,
      metricPageSize,
      metricPageIndex,
      instancePageSize,
      instancePageIndex,
      metricAnomalyMap,
      hasJustList = false,
      notLoadComponent = false,
    } = params;
    const topestRank = 100;

    let instanceList = params.instanceList || [];
    let metricList = params.metricList || [];

    const apiParams = { projectName, instanceGroup, startTimeObj, endTimeObj, eventType };

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    const { projectInfo, projectOwner, pname, projectList } = await getProjectAllInfo(
      projectName,
      allProjects,
      startTimeObj,
      endTimeObj,
      credentials,
    );
    // Get the project information
    // fix instanceList with instanceStructureSet
    const instanceStructureSet = get(projectInfo, ['instanceStructureSet'], []);
    let allInstanceList = [];
    R.forEach((inc) => {
      const { i, c = [] } = inc || {};
      if (i && c?.length > 0) {
        R.forEach((item) => {
          allInstanceList = [...allInstanceList, `${item}_${i}`];
        }, c);
      } else if (i) {
        allInstanceList = [...allInstanceList, i];
      }
    }, instanceStructureSet || []);

    const newInsList = [];
    // if instanceid has container, then add container instance to instanceList
    R.forEach((instanceId) => {
      const instanceInfo = R.find((item) => item.i === instanceId, instanceStructureSet);
      if (instanceInfo && instanceInfo.c?.length > 0) {
        const curNewInsList = R.map((c) => {
          return `${c}_${instanceInfo.i}`;
        }, instanceInfo.c);
        newInsList.push(...curNewInsList);
      } else {
        newInsList.push(instanceId);
      }
    }, instanceList);
    instanceList = newInsList;

    const samplingInterval = get(projectInfo, ['samplingInterval'], 1) * 60 * 1000;
    const isContainerProj = get(projectInfo, ['isContainer'], false);

    // parse all instance list
    allInstanceList = R.sort((a, b) => {
      return a.localeCompare(b);
    }, R.uniq(R.filter((x) => Boolean(x), allInstanceList)));

    // instances not exist in project
    const notExistInstances = R.difference(instanceList, allInstanceList);
    // instances exist in project
    instanceList = R.difference(instanceList, notExistInstances);
    try {
      // use user/customer time zone to set isCurrentDay
      let { timezoneOffset } = state.app;
      if (userInfo.isAdmin && projectOwner) {
        const customerUserInfo = R.find((user) => user.userName === projectOwner, userList || []);
        timezoneOffset = customerUserInfo ? customerUserInfo.userZoneOffset : timezoneOffset;
      }
      // should use project timezone
      {
        const projectTimeZone = get(projectInfo, 'timezone');
        if (projectTimeZone) {
          const zone = momenttz.tz(projectTimeZone);
          timezoneOffset = zone.utcOffset();
        }
      }
      const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;

      // Promise to get events list with api call per day, If samplingInterval >= 1 hour, then not split api call.
      const timeRangeList = [];
      if (samplingInterval >= 3600000) {
        timeRangeList.push({
          startTimeObj: startTimeObj.clone(),
          endTimeObj: endTimeObj.clone().endOf('day'),
        });
      } else {
        const daysNumber = toInteger((endTimeObj.valueOf() + 1 - startTimeObj.valueOf()) / 86400000);
        R.forEach((num) => {
          timeRangeList.push({
            startTimeObj: startTimeObj.clone().add(num, 'days'),
            endTimeObj: startTimeObj.clone().add(num, 'days').endOf('day'),
          });
        }, R.range(0, daysNumber));
      }

      const allProgressCount = 7 + timeRangeList.length;
      let progressCount = 0;
      const allProgress = (proms, progressCB) => {
        if (progressCount === 0) progressCB(0);
        R.forEach((request) => {
          request.then(() => {
            progressCount += 1;
            progressCB((progressCount * 100) / allProgressCount);
          });
        }, proms);
        return Promise.all(proms);
      };

      const instanceDisplayNameMap = {};
      await allProgress(
        [
          getLineChartInstanceDisplayName(credentials, {
            instanceDisplayNameRequestList: JSON.stringify([{ projectName: pname, customerName: projectOwner }]),
          }),
        ],
        (progress) => {
          const eventLoadingPercentage = round(progress, 2);
          dispatch(hideLoading);
          dispatch(
            createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_PROGRESS, params, {
              eventLoadingPercentage,
            }),
          );
        },
      ).then((results) => {
        const data = results[0] || [];
        // here maybe have a bug, but now not sure @YueYong
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, data || []);
      });

      // get anomaly instance and metric
      let anomalyInstanceList = [];
      const anomalyInstanceMetricMap = {};
      await allProgress(
        [
          getAnomalyInstance(credentials, {
            projectName,
            UserName: projectOwner,
            startTime: startTimeObj.valueOf(),
            endTime: endTimeObj.valueOf(),
          }),
        ],
        (progress) => {
          const eventLoadingPercentage = round(progress, 2);
          dispatch(hideLoading);
          dispatch(
            createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_PROGRESS, params, {
              eventLoadingPercentage,
            }),
          );
        },
      ).then((results) => {
        const anomalyInstanceMap = results[0] || {};
        R.forEachObjIndexed((val) => {
          const { instanceName, metricWithAnomalyMap } = val;
          anomalyInstanceList.push(instanceName);
          if (!R.has(instanceName, anomalyInstanceMetricMap)) anomalyInstanceMetricMap[instanceName] = [];

          R.forEach((item) => {
            const { metricName, detectionHasAnomaly } = item;
            if (detectionHasAnomaly) {
              anomalyInstanceMetricMap[instanceName].push(metricName);
            }
          }, R.values(metricWithAnomalyMap));
        }, anomalyInstanceMap);
      });
      anomalyInstanceList = R.filter((i) => Boolean(i), R.uniq(anomalyInstanceList));

      // Get selected instance's active info
      let activeInstanceList = [];
      const activeContainerFullIds = [];
      await allProgress(
        [
          getActiveInstance(credentials, {
            customerName: projectOwner,
            startTime: startTimeObj.valueOf(),
            endTime: endTimeObj.valueOf(),
            projectNameList: JSON.stringify([{ projectName: pname, projectType: projectInfo.dataType }]),
          }),
        ],
        (progress) => {
          const eventLoadingPercentage = round(progress, 2);
          dispatch(hideLoading);
          dispatch(
            createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_PROGRESS, params, {
              eventLoadingPercentage,
            }),
          );
        },
      ).then((results) => {
        const activeMap = results[0] || {};
        activeInstanceList = R.map((item) => item.id, get(activeMap[pname], 'activeInstanceList', []));
        R.forEachObjIndexed((val, key) => {
          R.forEach((item) => {
            const containerFullId = `${item}_${key}`;
            if (activeContainerFullIds.indexOf(containerFullId) === -1) {
              activeContainerFullIds.push(containerFullId);
            }
          }, val);
        }, get(activeMap[pname], 'activeContainerList', {}));
      });
      // get inactive instance/container_instance list
      activeInstanceList = [...activeInstanceList, ...activeContainerFullIds];

      // resort all instance info
      allInstanceList = [
        ...R.filter((name) => ifIn(name, anomalyInstanceList), allInstanceList),
        ...R.filter((name) => !ifIn(name, anomalyInstanceList), allInstanceList),
      ];
      allInstanceList = [
        ...R.filter((name) => ifIn(name, activeInstanceList), allInstanceList),
        ...R.filter((name) => !ifIn(name, activeInstanceList), allInstanceList),
      ];

      // reset instanceList if instanceList is empty
      const currentInstanceList = R.slice(
        (instancePageIndex - 1) * instancePageSize,
        instancePageIndex * instancePageSize,
        allInstanceList,
      );
      if (instanceList.length === 0) {
        instanceList = currentInstanceList;
      }

      const realSelectInstanceList = [];
      R.forEach((item) => {
        const instanceName = item.indexOf('_') > 0 ? item.split('_')[1] : item;
        if (instanceName) {
          realSelectInstanceList.push(instanceName);
        }
      }, instanceList);
      instanceList = [...realSelectInstanceList, ...instanceList];
      instanceList = R.uniq(instanceList);

      // get all components from instances
      const prevInstanceComponentMap = get(projectInstanceComponentMap, projectName, {});
      if ((!hasJustList || true) && !notLoadComponent) {
        const { hasError } = await BackgroundCall.GetSetComponent({
          dispatch,
          createSetAction,
          credentials,
          prevInstanceComponentMap,
          projectName,
        });

        if (hasError) {
          dispatch(apiErrorHandle());
        }
      }
      // add progress
      progressCount += 1;
      dispatch(
        createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_PROGRESS, params, {
          eventLoadingPercentage: round((progressCount * 100) / allProgressCount, 2),
        }),
      );

      // get anomaly events info from anomaly instance
      let eventList = [];
      await allProgress(
        [
          anomalyInstanceList.length > 0
            ? getMetricEventList(credentials, {
                ...apiParams,
                withPredict: endTimeObj.valueOf() >= nowTimestamp,
                filterPredict,
                nowTimestamp,
                instanceList,
                onlyLastDayPrediction: true,
              })
            : Promise.resolve(),
        ],
        (progress) => {
          const eventLoadingPercentage = round(progress, 2);
          dispatch(hideLoading);
          dispatch(
            createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_PROGRESS, params, {
              eventLoadingPercentage,
            }),
          );
        },
      ).then((results) => {
        R.forEach((eventsData) => {
          eventList = [...eventList, ...get(eventsData, ['eventListMap', 'eventList'], [])];
        }, results);
      });

      await allProgress(
        [
          anomalyInstanceList.length > 0
            ? getIncidentList(projectName, startTimeObj.valueOf(), endTimeObj.valueOf(), instanceList, credentials)
            : Promise.resolve(),
        ],
        (progress) => {
          const eventLoadingPercentage = round(progress, 2);
          dispatch(hideLoading);
          dispatch(
            createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_PROGRESS, params, {
              eventLoadingPercentage,
            }),
          );
        },
      ).then(([result]) => {
        if (!result || !!result.status || !isArray(result)) {
          return;
        }
        // copy from metric eventList
        let rootCauseJson = R.map((item) => {
          try {
            return {
              ...JSON.parse(item.rootCause || '{}'),
            };
          } catch (err) {
            return undefined;
          }
        }, result || []);
        rootCauseJson = rootCauseJson.filter((item) => item && item.instanceDown);
        let rootCauseDetailsArr = rootCauseJson;
        rootCauseDetailsArr = R.map(
          (rc) => ({
            ...rc,
            appName: rc.componentName,
            direction: rc.sign,
            instanceId: rc.instanceName,
            rootCauseSource: rc.instanceDown ? 'missing data' : undefined,
            metricValue: rc.anomalyValue,
            pct: parseFloat(rc.percentage),
            rootCauseMetric: rc.metricName,
            timePairArr: R.map(
              (val) => ({ startTimestamp: val.s, endTimestamp: val.e, duration: val.e - val.s }),
              rc.timePairList,
            ),
          }),
          rootCauseDetailsArr,
        );
        // sort by pct
        rootCauseDetailsArr = R.sort((a, b) => {
          return Math.abs(b.pct) - Math.abs(a.pct);
        }, rootCauseDetailsArr);

        rootCauseJson = { ...rootCauseJson, rootCauseDetailsArr };
        const detecteds = [{ rootCauseJson }];
        eventList = [...eventList, ...detecteds];
      });

      // Get the metric and instance list in the event
      const { eventMetricList, metricRootCauseMap, instanceDownRootCauseList, newEventList } = calculationEventLogic(
        eventList,
        instanceList,
        samplingInterval,
        topestRank,
      );
      eventList = newEventList;

      // Get selected instance's metric info and app name
      let metricObj = {};
      let allMetricList = [];
      await allProgress(
        [
          getInstanceMetaData(credentials, {
            projectName,
            instanceGroup,
            isContainerProj,
            idList: JSON.stringify(instanceList),
          }),
        ],
        (progress) => {
          const eventLoadingPercentage = round(progress, 2);
          dispatch(hideLoading);
          dispatch(
            createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_PROGRESS, params, {
              eventLoadingPercentage,
            }),
          );
        },
      ).then((results) => {
        metricObj = get(results[0], ['metricObj'], {});
        allMetricList = get(results[0], ['metricList'], []);
      });

      // Use the selected metric if exists, other use the metric have anomalies, then top metricPageSize metrics
      const anomalyMetricByInstances = R.uniq(
        R.reduce(R.concat, [], R.values(R.pick(instanceList, anomalyInstanceMetricMap))),
      );
      allMetricList = R.sort((a, b) => {
        return a.localeCompare(b);
      }, R.uniq(R.filter((x) => Boolean(x), allMetricList)));
      allMetricList = [
        ...R.filter((name) => ifIn(name, anomalyMetricByInstances), allMetricList),
        ...R.filter((name) => !ifIn(name, anomalyMetricByInstances), allMetricList),
      ];
      const filterMetricList = R.uniq(
        R.reduce(
          R.concat,
          [],
          R.map((ci) => get(metricObj, ci, []), instanceList),
          // R.map((ci) => get(metricObj, ci, []), R.map((i) => GlobalParse.getContainerInstanceId(i), instanceList)),
        ),
      );
      if (metricList.length === 0) {
        metricList = R.slice(
          (metricPageIndex - 1) * metricPageSize,
          metricPageIndex * metricPageSize,
          R.filter((m) => filterMetricList.indexOf(m) >= 0, allMetricList),
        );
      }

      //  Set all instances info, and active info
      const allInstanceInfoList = R.map((instance) => {
        return {
          id: instance,
          inactive: !activeInstanceList.includes(instance),
        };
      }, allInstanceList);

      // Handle instance down root cause list
      if (instanceDownRootCauseList.length > 0 && eventMetricList) {
        R.forEach((metric) => {
          const rcList = metricRootCauseMap[metric] || [];
          metricRootCauseMap[metric] = [...rcList, ...instanceDownRootCauseList];
        }, eventMetricList);
      }

      // Get the linecharts data
      await allProgress(
        R.addIndex(R.map)((timeRange, index) => {
          return getMetricLineCharts(credentials, {
            ...apiParams,
            startTimeObj: timeRange.startTimeObj,
            endTimeObj: timeRange.endTimeObj,
            instanceList,
            metricList,
            ...(timeRange.endTimeObj.valueOf() < nowTimestamp ? {} : { predictedFlag: true }),
          });
        }, timeRangeList),
        (progress) => {
          const eventLoadingPercentage = round(progress, 2);
          dispatch(hideLoading);
          dispatch(
            createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS_PROGRESS, params, {
              eventLoadingPercentage,
            }),
          );
        },
      )
        .then((data) => {
          const {
            metricEventListMap, // csv 数据计算出来的 metricName : eventList 的映射
            metricDatasetMap, // csv 数据计算出来的 metricName : chartdata 的映射
            metricUnitMapping, // csv 数据计算出来的 metricName : unit 的映射
            filterMetricInfoList, // 存在的metric id 的 metric信息
            datasetInstanceList,
            allMetricInfoList, // 所有metric项的信息
            currentTimestamp, // 当前时间 用于分割预测数据
            datasetMetricList,
            hasDataMetricList, // 有数据的统计项
            metricListWithNoData, // 没有数据的统计项
            columnInfoMap, // csv 数据计算出来的column metricName : columes 的映射
            latestDataTime, // 所有csv数据里的最新数据的时间
            k8CoverageMap, // 由 linechart 数据的 k8Coverage 信息计算出来的
          } = calculationLogic(
            data,
            startTimeObj,
            metricAnomalyMap,
            metricList,
            allMetricList,
            metricRootCauseMap,
            instanceList,
            anomalyMetricByInstances,
            filterMetricList,
            topestRank,
            eventList,
          );
          dispatch(createSetAction(AppActionTypes.SET_PROJECT_INFO, params, projectList));
          dispatch(
            createSetAction(ActionTypes.SET_METRIC_EVENT_LINECHARTS, params, {
              projectInfo,
              eventList,
              instanceList,
              metricList,
              selectInstanceList: instanceList,
              selectMetricList: metricList,
              anomalyInstanceList,
              anomalyInstanceMetricMap,
              anomalyMetricByInstances,
              allInstanceInfoList,
              allInstanceList,
              notExistInstances, // Instances where the project does not exist

              //= ============ The information part that requires data calculation start ===============
              datasetMetricList,
              datasetInstanceList,
              hasDataMetricList,
              metricListWithNoData,
              columnInfoMap,
              metricEventListMap,
              metricDatasetMap,
              metricUnitMapping,
              latestDataTime,
              currentTimestamp,
              allMetricInfoList,
              filterMetricInfoList,
              k8CoverageMap,
              //= ============= The information part that requires data calculation end ===============
              instancePageIndex,
              metricPageIndex,
              metricObj,
              allMetricList,
              activeInstanceList,

              instanceDisplayNameMap,
            }),
          );
        })
        .catch((err) => {
          console.error(err);
          dispatch(apiEpicErrorHandle(err));
        })
        .then(() => {
          // callback function
          if (R.type(callback) === 'Function') {
            callback();
          }

          dispatch(hideLoading);
          done();
        });
    } catch (err) {
      console.log(err);
      dispatch(apiErrorHandle(err));
      dispatch(hideLoading);
      done();
    }
  },
});

export default loadMetricEventLineChartsLogic;
