import React from 'react';
import { connect } from 'react-redux';

import { parseLocation } from '../../../src/common/utils';
import { hideAppLoader } from '../../../src/common/app/actions';
import { Container } from '../../../src/lib/fui/react';
import apis from '../../../apis';
import LiveAnalysisCharts from '../liveanalysis';

const FileDetailsCore = class extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.updateLiveAnalysis();
  }

  componentWillUnmount() {}

  updateLiveAnalysis() {
    const { credentials } = this.props;
    const query = parseLocation(this.props.location);
    const { filename } = query;

    this.setState({ loading: true });
    apis
      .postUploadVisualization(credentials, filename)
      .then((resp) => {
        const update = {};
        if (resp.success) {
          update.data = resp.data;
        } else {
          alert(resp.message);
        }
        update.loading = false;
        this.setState(update);
        this.props.hideAppLoader();
      })
      .catch((msg) => {
        this.setState({ loading: false });
        this.props.hideAppLoader();
        console.error(msg);
      });
  }

  render() {
    const query = parseLocation(this.props.location);
    const { loading, data } = this.state;
    const debugData = undefined;
    return (
      <Container fullHeight withGutter className="global-view flex-col flex-min-height flex-min-width full-width">
        <LiveAnalysisCharts
          {...query}
          data={data}
          loading={loading}
          debugData={debugData}
          onRefresh={() => this.updateLiveAnalysis()}
        />
      </Container>
    );
  }
};

const FileDetails = connect(
  (state) => {
    const { credentials } = state.auth;
    return { credentials };
  },
  { hideAppLoader },
)(FileDetailsCore);
export default FileDetails;
