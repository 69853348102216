/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postProjectActionsSetting = (credentials: Credentials, params: Object) => {
  const { actionsList, ...rest } = params;
  return fetchPost(getEndpoint('projectactions'), {
    ...credentials,
    ...rest,
    actionsList: JSON.stringify(actionsList),
  }).then((d) => {
    return d;
  });
};

export default postProjectActionsSetting;
