import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { AutoComplete, Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Modal } from '../../../lib/fui/react';

import { eventMessages } from '../../../common/metric/messages';
import { appButtonsMessages } from '../../../common/app/messages';

export default function EscalateAddModal({ intl, credentials, onClose }: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    patternName: '',
    patterns: [],
  });
  const { loading, patternName, patterns } = state;

  useEffect(() => {
    setState({ loading: true });
    fetchGet(getEndpoint('global-kb-param', 1), {
      ...credentials,
    })
      .then((data) => {
        const { success, message: msg, incidentList } = data || {};
        if (success || success === undefined) {
          let incidentListData = R.map((item) => ({ label: item, value: item }), incidentList || []);
          incidentListData = R.sortWith([R.ascend(R.prop('label'))], incidentListData);
          setState({ patterns: incidentListData, loading: false });
        } else {
          setState({ loading: false });
          console.error(msg);
        }
      })
      .catch((err) => {
        setState({ loading: false });
        console.error(err.message || String(err));
      });
  }, []);

  return (
    <Modal
      title={intl.formatMessage(eventMessages.incidentPatternName)}
      width={650}
      visible
      onCancel={() => onClose()}
      onOk={() => onClose(patternName)}
      okButtonProps={{ disabled: !patternName }}
      okText={intl.formatMessage(appButtonsMessages.submit)}
    >
      <Spin spinning={loading}>
        <div className="flex-row flex-center-align">
          <div className="light-label bold" style={{ width: 164, flexShrink: 0 }}>
            {intl.formatMessage(eventMessages.incidentPatternName)} <span style={{ color: 'red' }}>*</span>:
          </div>
          <AutoComplete
            allowClear
            size="small"
            value={patternName}
            options={patterns}
            style={{ width: '100%', marginLeft: 8 }}
            onChange={(patternName) => setState({ patternName })}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
            }
            className={`${patternName ? '' : 'jsonKeyNoneError'}`}
          />
        </div>
      </Spin>
    </Modal>
  );
}
