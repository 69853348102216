import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { Tabs } from 'antd';
import { autobind } from 'core-decorators';

import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { eventActionMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import { appFieldsMessages } from '../../../common/app/messages';

import TriageReport from './TriageReport';
import MicrosoftTeamsNotes from './MicrosoftTeamsNotes';
import SlackNotes from './SlackNotes';
import RecommendationsReport from './RecommendationsReport';
import ServiceNowNotes from './ServiceNowNotes';

type Props = {
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  // eslint-disable-next-line
  incident: Object,
  onClose: Function,
  onReload: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  timezoneOffset: Number,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  tabName: String,
  projectName: String,
};

class TriageReportModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      activeKey: 'insightFinderNotes',
      updateFlag: false,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  handleUpdate(updateFlag) {
    this.setState({ updateFlag });
  }

  render() {
    const { intl, environmentId, systemId, incident, onClose, onReload, tabName, projectName, userInfo } = this.props;
    const { activeKey } = this.state;
    const { isIncident } = incident || {};

    return (
      <Modal
        visible
        title={intl.formatMessage(appFieldsMessages.Notes)}
        onOk={() => onClose(this.state.updateFlag)}
        onCancel={() => onClose(this.state.updateFlag)}
        width={1300}
        bodyStyle={{ height: 500 }}
      >
        <Tabs
          type="card"
          className="full-height ant-tabs-content-full-height flex-col"
          activeKey={activeKey}
          onChange={(activeKey) => this.setState({ activeKey })}
        >
          {/* <Tabs.TabPane tab={intl.formatMessage(logMessages.recommendations)} key="recommendations">
            <RecommendationsReport incident={incident} projectName={projectName} />
          </Tabs.TabPane> */}
          <Tabs.TabPane tab={intl.formatMessage(eventActionMessages.summaryNotes)} key="insightFinderNotes">
            <TriageReport incident={incident} tabName={tabName} handleUpdate={this.handleUpdate} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={intl.formatMessage(eventActionMessages.serviceNowNotes)} key="serviceNowNotes">
            <ServiceNowNotes incident={incident} tabName={tabName} />
          </Tabs.TabPane>
          {isIncident && !userInfo.isReadUser && (
            <>
              <Tabs.TabPane tab={intl.formatMessage(eventActionMessages.microsoftTeamsNotes)} key="microsoftTeamsNotes">
                <MicrosoftTeamsNotes
                  environmentId={environmentId}
                  systemId={systemId}
                  incident={incident}
                  onReload={onReload}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={intl.formatMessage(eventActionMessages.slackNotes)} key="slackNotes">
                <SlackNotes environmentId={environmentId} systemId={systemId} incident={incident} onReload={onReload} />
              </Tabs.TabPane>
            </>
          )}
        </Tabs>
      </Modal>
    );
  }
}

const TriageReportModal = injectIntl(TriageReportModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      timezoneOffset,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(TriageReportModal);
