/* @flow */

import { defineMessages } from 'react-intl';

const settingsMessages = defineMessages({
  alertNoProject: {
    defaultMessage: 'There is no project, please create new one.',
    id: 'settings.alert.noProject',
  },
  errorEmptySelection: {
    defaultMessage: 'This setting cannot be empty, please select from options.',
    id: 'settings.error.emptySelection',
  },
  errorEmptyInput: {
    defaultMessage: 'This setting cannot be empty, please input value.',
    id: 'settings.error.emptyInput',
  },
  errorNotNumberInput: {
    defaultMessage: 'This setting value is invalid, please input number value.',
    id: 'settings.error.notNumberInput',
  },
  errorNoProjectModel: {
    defaultMessage: 'There is no model found for {projectName}, please change start/end date or choose other groups.',
    id: 'settings.error.noProjectModel',
  },
  errorNoProjectHolidayModel: {
    defaultMessage: 'There is no holiday period specified. Please setup the holiday period under General tab.',
    id: 'settings.error.noProjectHolidayModel',
  },
  errorNoIncident: {
    defaultMessage: 'The incident cannot find, please refresh and try again.',
    id: 'settings.error.errorNoIncident',
  },
  warnProjectModelNotExists: {
    defaultMessage: 'The project model not exists, please select another one',
    id: 'settings.warn.projectModelNotExists',
  },
  userRoleSaved: {
    defaultMessage: 'User role is saved',
    id: 'settings.info.userRoleSaved',
  },
  infoProjectSettingSavedSystemName: {
    defaultMessage:
      'Please rerun globalview using the update button in Unified Health View under admin account after you are done with changing the system name of all related projects.',
    id: 'settings.info.infoProjectSettingSavedSystemName',
  },
  infoProjectSettingSaved: {
    defaultMessage: 'The setting is saved.',
    id: 'settings.info.projectSettingSaved',
  },
  infoComponentChange: {
    defaultMessage:
      'Please update the dependency graph under the causal analysis page since the component name is changed.',
    id: 'settings.info.infoComponentChange',
  },
  infoProjectSettingSavedWithExclusion: {
    defaultMessage: 'The setting is saved. Exclude following metrics: {excludedMetrics}',
    id: 'settings.info.projectSettingSavedWithExclusion',
  },
  infoProjectModelPicked: {
    defaultMessage: 'The project model is picked',
    id: 'settings.info.projectModelPicked',
  },
  infoProjectlRemoved: {
    defaultMessage: 'Project deletion request has succeeded',
    id: 'settings.info.projectlRemoved',
  },
  infoProjectlRemovedFail: {
    defaultMessage: 'Project deletion request has failed',
    id: 'settings.info.projectlRemovedFail',
  },
  infoProjectlRecovered: {
    defaultMessage: 'The project is recovered',
    id: 'settings.info.infoProjectlRecovered',
  },
  infoProjectlMoveToTrash: {
    defaultMessage: 'The project is move to trash',
    id: 'settings.info.infoProjectlMoveToTrash',
  },
  infoProjectModelRemoved: {
    defaultMessage: 'The project model is removed',
    id: 'settings.info.projectModelRemoved',
  },
  infoExternalServcieAdded: {
    defaultMessage: 'The external service is added',
    id: 'settings.info.externalServiceAdded',
  },
  systemComposition: {
    defaultMessage: 'System composition',
    id: 'settings.systemComposition',
  },
  systemEmailSetting: {
    defaultMessage: 'Email',
    id: 'settings.systemEmailSetting',
  },
  systemEmailNotificationSetting: {
    defaultMessage: 'Notification settings',
    id: 'settings.systemEmailNotificationSetting',
  },
  systemScoresSetting: {
    defaultMessage: 'Max anomaly scores',
    id: 'settings.systemScoresSetting',
  },
  systemCapacitySetting: {
    defaultMessage: 'Capacity Planning Setting',
    id: 'settings.systemCapacitySetting',
  },
  knowledgeBase: {
    defaultMessage: 'Knowledge Base',
    id: 'settings.knowledgeBase',
  },
  addNewSystem: {
    defaultMessage: 'Add New System',
    id: 'settings.addNewSystem',
  },
  addNewProject: {
    defaultMessage: 'Add New Project',
    id: 'settings.addNewProject',
  },
  copyExistingProject: {
    defaultMessage: 'Copy Existing Project',
    id: 'settings.copyExistingProject',
  },
  showTrashProjects: {
    defaultMessage: 'Show projects in trash',
    id: 'settings.showTrashProjects',
  },
  noSystem: {
    defaultMessage: 'No System',
    id: 'settings.noSystem',
  },
  projectsNoSystem: {
    defaultMessage: 'Projects with no system',
    id: 'settings.projectsNoSystem',
  },
  cloudType: {
    defaultMessage: 'Integration type',
    id: 'settings.cloudType',
  },
  agentType: {
    defaultMessage: 'Agent type',
    id: 'settings.agentType',
  },
  dataType: {
    defaultMessage: 'Data type',
    id: 'settings.dataType',
  },
  samplingInterval: {
    defaultMessage: 'Sampling interval',
    id: 'settings.samplingInterval',
  },
  specialSamplingInterval: {
    defaultMessage: 'Special sampling interval',
    id: 'settings.specialSamplingInterval',
  },
  statisticsTime: {
    defaultMessage: 'Statistics time',
    id: 'settings.statisticsTime',
  },
  creationTime: {
    defaultMessage: 'Creation time',
    id: 'settings.creationTime',
  },
  confirmRecoverProject: {
    defaultMessage: 'This action will recover project ({project}).',
    id: 'settings.confirmRecoverProject',
  },
  confirmRemoveProject: {
    defaultMessage: 'This action will permanently remove this project ({project}) and all associated data.',
    id: 'settings.confirmRemoveProject',
  },
  confirmPurgeProject: {
    defaultMessage: 'This action will permanently purge all data about this project ({project}).',
    id: 'settings.confirmPurgeProject',
  },
  confirmTrashProject: {
    defaultMessage: 'This action will move project ({project}) to trash.',
    id: 'settings.confirmTrashProject',
  },
  general: {
    defaultMessage: 'General',
    id: 'settings.general',
  },
  miscellaneous: {
    defaultMessage: 'Miscellaneous',
    id: 'settings.miscellaneous',
  },
  incidentValidationSetting: {
    defaultMessage: 'Incident validation setting',
    id: 'settings.incidentValidationSetting',
  },
  agentSetting: {
    defaultMessage: 'Data Collection',
    id: 'settings.agentSetting',
  },
  incidentKeywords: {
    defaultMessage: 'Incident keywords',
    id: 'settings.incidentKeywords',
  },
  metricProjectName: {
    defaultMessage: 'Metric project name',
    id: 'settings.metricProjectName',
  },
  systemSharing: {
    defaultMessage: 'System sharing',
    id: 'settings.systemSharing',
  },
  endpoint: {
    defaultMessage: 'Endpoint',
    id: 'settings.endpoint',
  },
  sensitivity: {
    defaultMessage: 'Sensitivity',
    id: 'settings.sensitivity',
  },
  featureOutlierDetectionSensitivity: {
    defaultMessage: 'Feature outlier detection sensitivity',
    id: 'settings.featureOutlierDetectionSensitivity',
  },
  rareSensitivityDesc: {
    defaultMessage: 'Higher sensitivity produces more rare events',
    id: 'settings.rareSensitivityDesc',
  },
  similarityDegreeDesc: {
    defaultMessage: 'Higher similarity degree produces more patterns.',
    id: 'settings.similarityDegreeDesc',
  },
  hotColdSensitivityDesc: {
    defaultMessage: 'Higher sensitivity produces more hot/cold events',
    id: 'settings.hotColdSensitivityDesc',
  },
  grouping: {
    defaultMessage: 'Grouping',
    id: 'settings.grouping',
  },
  metricConfiguration: {
    defaultMessage: 'Metric Configuration',
    id: 'settings.metricConfiguration',
  },
  capacityPlanningSetting: {
    defaultMessage: 'Capacity Planning Setting',
    id: 'settings.capacityPlanningSetting',
  },
  actions: {
    defaultMessage: 'Actions',
    id: 'settings.actions',
  },
  modelList: {
    defaultMessage: 'Model List',
    id: 'settings.modelList',
  },
  regularModelList: {
    defaultMessage: 'Regular Model List',
    id: 'settings.regularModelList',
  },
  holidayModelList: {
    defaultMessage: 'Holiday Model List',
    id: 'settings.holidayModelList',
  },
  replayStatus: {
    defaultMessage: 'Replay Status',
    id: 'settings.replayStatus',
  },
  autoScalingConfiguration: {
    defaultMessage: 'Auto-scaling Configuration',
    id: 'settings.autoScalingConfiguration',
  },
  labels: {
    defaultMessage: 'Labels',
    id: 'settings.labels',
  },
  sequenceScope: {
    defaultMessage: 'Sequence scope',
    id: 'settings.sequenceScope',
  },
  sequenceScopeDesc: {
    defaultMessage: 'Defines the sequence scope for the log entires.',
    id: 'settings.sequenceScopeDesc',
  },
  detectionStatus: {
    defaultMessage: 'Detection Status',
    id: 'settings.detectionStatus',
  },
  logToMetric: {
    defaultMessage: 'Log To Metric',
    id: 'settings.logToMetric',
  },
  logToMetricBaseValue: {
    defaultMessage: 'Log To Metric Base Value',
    id: 'settings.logToMetricBaseValue',
  },
  cumulativeDistributionFunction: {
    defaultMessage: 'Cumulative Distribution Function',
    id: 'settings.cumulativeDistributionFunction',
  },
  groupNameKey: {
    defaultMessage: 'Group name key',
    id: 'settings.groupNameKey',
  },
  scoreKey: {
    defaultMessage: 'Score key',
    id: 'settings.scoreKey',
  },
  maxValue: {
    defaultMessage: 'Max value',
    id: 'settings.maxValue',
  },
  minValue: {
    defaultMessage: 'Min value',
    id: 'settings.minValue',
  },
  numberOfBuckets: {
    defaultMessage: 'Number of buckets',
    id: 'settings.numberOfBuckets',
  },
  fieldSetting: {
    defaultMessage: 'Field',
    id: 'settings.fieldSetting',
  },
  retention: {
    defaultMessage: 'Retention',
    id: 'settings.retention',
  },
  SystemName: {
    defaultMessage: 'System Name',
    id: 'settings.SystemName',
  },
  retentionTime: {
    defaultMessage: 'Retention Time',
    id: 'settings.retentionTime',
  },
  dataRetention: {
    defaultMessage: 'Data retention',
    id: 'settings.dataRetention',
  },
  modelRetention: {
    defaultMessage: 'Model retention',
    id: 'settings.modelRetention',
  },
  largeProject: {
    defaultMessage: 'Large project',
    id: 'settings.largeProject',
  },
  modelTraining: {
    defaultMessage: 'Model Training',
    id: 'settings.modelTraining',
  },
  enableMissingDataFilling: {
    defaultMessage: 'Zero filling',
    id: 'settings.enableMissingDataFilling',
  },
  enableAnomalyDataFiltering: {
    defaultMessage: 'Enable anomaly data filtering in training',
    id: 'settings.enableAnomalyDataFiltering',
  },
  minValidModelSpan: {
    defaultMessage: 'Min valid model span (min)',
    id: 'settings.minValidModelSpan',
  },
  enableJSONPreprocess: {
    defaultMessage: 'Enable JSON preprocess',
    id: 'settings.enableJSONPreprocess',
  },
  enableJSONPreprocessToolTip: {
    defaultMessage: 'Convert some unstructured JSON data to structured one',
    id: 'settings.enableJSONPreprocessToolTip',
  },
  enableRegexMultiline: {
    defaultMessage: 'Enable regex multiline',
    id: 'settings.enableRegexMultiline',
  },
  metricModelSpan: {
    defaultMessage: 'Model training span',
    id: 'settings.metricModelSpan',
  },
  trainingModelSpan: {
    defaultMessage: 'Training model span range',
    id: 'settings.trainingModelSpan',
  },
  holidays: {
    defaultMessage: 'Holidays',
    id: 'settings.holidays',
  },
  period: {
    defaultMessage: 'Period',
    id: 'settings.period',
  },
  maintenanceDay: {
    defaultMessage: 'Maintenance window',
    id: 'settings.maintenanceDay',
  },
  timeWindowType: {
    defaultMessage: 'Time window type',
    id: 'settings.timeWindowType',
  },
  systemLevelMaintenanceWindows: {
    defaultMessage: 'System level maintenance windows',
    id: 'settings.systemLevelMaintenanceWindows',
  },
  projectLevelMaintenanceWindows: {
    defaultMessage: 'Project level maintenance windows',
    id: 'settings.projectLevelMaintenanceWindows',
  },
  projectSharing: {
    defaultMessage: 'Project Sharing',
    id: 'settings.projectSharing',
  },
  projectSharingDesc1: {
    defaultMessage:
      'If you are collaborating with other users, you may invite them to view data associated with your Projects.',
    id: 'settings.projectSharingDesc1',
  },
  projectSharingDesc2: {
    defaultMessage:
      'To share your project, enter their User ID(s) in the field below and click Update button. Those users will be able to view your project on their next login.',
    id: 'settings.projectSharingDesc2',
  },
  linkingProjects: {
    defaultMessage: 'Linking Projects',
    id: 'settings.linkingProjects',
  },
  linkingLogProjects: {
    defaultMessage: 'Linking Log Projects',
    id: 'settings.linkingLogProjects',
  },
  linkingLogProjectsDesc: {
    defaultMessage:
      'To update component name automatically for multiple log projects, link this project with others log projects.',
    id: 'settings.linkingLogProjectsDesc',
  },
  webhook: {
    defaultMessage: 'Webhook',
    id: 'settings.webhook',
  },
  proxy: {
    defaultMessage: 'Proxy',
    id: 'settings.proxy',
  },
  webhookUrl: {
    defaultMessage: 'Webhook url',
    id: 'settings.webhookUrl',
  },
  webhookSecretKey: {
    defaultMessage: 'Webhook secret key',
    id: 'settings.webhookSecretKey',
  },
  webhookHeaders: {
    defaultMessage: 'Webhook headers',
    id: 'settings.webhookHeaders',
  },
  webhookDampeningPeriod: {
    defaultMessage: 'Webhook dampening period (min)',
    id: 'settings.webhookDampeningPeriod',
  },
  maximumPayloadSize: {
    defaultMessage: 'Maximum payload size (MB)',
    id: 'settings.maximumPayloadSize',
  },
  characterBlacklistSetting: {
    defaultMessage: 'Character blacklist setting',
    id: 'settings.characterBlacklistSetting',
  },
  criticalKeywordSetting: {
    defaultMessage: 'Critical keyword setting',
    id: 'settings.criticalKeywordSetting',
  },
  manualEntryToAddNewContent: {
    defaultMessage: 'Manual entry to add new content',
    id: 'settings.manualEntryToAddNewContent',
  },
  IPAddress: {
    defaultMessage: 'IP address',
    id: 'settings.IPAddress',
  },
  serverName: {
    defaultMessage: 'Server name',
    id: 'settings.serverName',
  },
  hostName: {
    defaultMessage: 'Host name',
    id: 'settings.hostName',
  },
  zone: {
    defaultMessage: 'Zone',
    id: 'settings.zone',
  },
  capacityPriority: {
    defaultMessage: 'Capacity priority',
    id: 'settings.capacityPriority',
  },
  healthPriority: {
    defaultMessage: 'Health priority',
    id: 'settings.healthPriority',
  },
  instanceSettingDesc1: {
    defaultMessage:
      'An Instance is used to define logical views on the data for this project. When a grouping is defined, the engine will analyze the data in each group individually and concurrently. You can create different views for the collected data.',
    id: 'settings.instanceSettingDesc1',
  },
  instanceSettingDesc2: {
    defaultMessage:
      'When metrics or log data from different instances are collected, by default they will be in the All group. To group instances, set the same name for those instances. Or select json or csv file to update grouping in batch.',
    id: 'settings.instanceSettingDesc2',
  },
  instanceSelectAllDay: {
    defaultMessage: 'Show All Day Instances',
    id: 'settings.instanceSelectAllDay',
  },
  instanceSelectCurrentDay: {
    defaultMessage: 'Show Current Day Instances',
    id: 'settings.instanceSelectCurrentDay',
  },
  whitelist: {
    defaultMessage: 'Detection keywords',
    id: 'settings.whitelist',
  },
  whitelistDesc: {
    defaultMessage: 'Keywords/regular expressions that are used to detect which log entries will produce alerts.',
    id: 'settings.whitelistDesc',
  },
  regexIsCaseSensitive: {
    defaultMessage: 'Regex is case sensitive.',
    id: 'settings.regexIsCaseSensitive',
  },
  incidentPatternOrder: {
    defaultMessage: 'The incident pattern is matched based on the listed order.',
    id: 'settings.incidentPatternOrder',
  },
  patternNameLabelsOrder: {
    defaultMessage: 'The pattern is matched based on the listed order.',
    id: 'settings.patternNameLabelsOrder',
  },
  whitelistLabelsOrder: {
    defaultMessage: 'The detection keywords is matched based on the listed order.',
    id: 'settings.whitelistLabelsOrder',
  },
  trainingWhitelist: {
    defaultMessage: 'Training allowedlist',
    id: 'settings.trainingWhitelist',
  },
  knownSystemList: {
    defaultMessage: 'Known system list',
    id: 'settings.knownSystemList',
  },
  trainingWhitelistDesc: {
    defaultMessage: 'Keywords/regex that are always included during model training.',
    id: 'settings.trainingWhitelistDesc',
  },
  instanceNameLabels: {
    defaultMessage: 'Instance name',
    id: 'settings.instanceNameLabels',
  },
  instanceNameLabelsDesc: {
    defaultMessage: 'Keywords/regex that are used to get instance name.',
    id: 'settings.instanceNameLabelsDesc',
  },
  trainingBlacklist: {
    defaultMessage: 'Training blocklist',
    id: 'settings.trainingBlacklist',
  },
  trainingBlacklistDesc: {
    defaultMessage: 'Keywords/regex that are always excluded during model training.',
    id: 'settings.trainingBlacklistDesc',
  },
  detectionBlacklist: {
    defaultMessage: 'Detection blocklist',
    id: 'settings.detectionBlacklist',
  },
  detectionBlacklistDesc: {
    defaultMessage: 'Log entries that include the specified keywords/regex are always filtered out.',
    id: 'settings.detectionBlacklistDesc',
  },
  feature: {
    defaultMessage: 'Feature',
    id: 'settings.feature',
  },
  featureDesc: {
    defaultMessage: 'Keywords/regex (e.g., IP address) that are used to extract log entry count distributions.',
    id: 'settings.featureDesc',
  },
  anomalyFeature: {
    defaultMessage: 'Anomaly Feature',
    id: 'settings.anomalyFeature',
  },
  category: {
    defaultMessage: 'Category',
    id: 'settings.category',
  },
  categoryDesc: {
    defaultMessage: 'Keywords/regex that are used to create category labels for log entries.',
    id: 'settings.categoryDesc',
  },
  incident: {
    defaultMessage: 'Incident labels',
    id: 'settings.incident',
  },
  incidentDesc: {
    defaultMessage: 'Keywords/regex that are used to identify which log entries indicate incidents.',
    id: 'settings.incidentDesc',
  },
  patternNameLabels: {
    defaultMessage: 'Pattern name labels',
    id: 'settings.patternNameLabels',
  },
  normalPatternName: {
    defaultMessage: 'Normal log pattern name',
    id: 'settings.normalPatternName',
  },
  patternNameLabelsDesc: {
    defaultMessage: 'Keywords/regex that are used to identify pattern names.',
    id: 'settings.patternNameLabelsDesc',
  },
  sensitiveDataFilter: {
    defaultMessage: 'Sensitive data filter',
    id: 'settings.sensitiveDataFilter',
  },
  sensitiveDataFilterDesc: {
    defaultMessage: 'Keywords/regex that are used to identify which log entries sensitive data filter.',
    id: 'settings.sensitiveDataFilterDesc',
  },
  triageReport: {
    defaultMessage: 'Operator notes',
    id: 'settings.triageReport',
  },
  triageReportDesc: {
    defaultMessage: 'Keywords/regex that are used to identify which log entries indicate summary notes.',
    id: 'settings.triageReportDesc',
  },
  groupingPriority: {
    defaultMessage: 'Grouping priority',
    id: 'settings.groupingPriority',
  },
  metricProject: {
    defaultMessage: 'Metric project',
    id: 'settings.metricProject',
  },
  fieldName: {
    defaultMessage: 'Field name',
    id: 'settings.fieldName',
  },
  jsonFieldName: {
    defaultMessage: 'Json field name',
    id: 'settings.jsonFieldName',
  },
  content: {
    defaultMessage: 'Content',
    id: 'settings.content',
  },
  stringContent: {
    defaultMessage: 'String content',
    id: 'settings.stringContent',
  },
  projectDetectionStatus: {
    defaultMessage: 'Project Detection Status',
    id: 'settings.projectDetectionStatus',
  },
  resetDetectionIndex: {
    defaultMessage: 'Reset Detection Index',
    id: 'settings.resetDetectionIndex',
  },
  restoreLogRawData: {
    defaultMessage: 'Restore log raw data',
    id: 'settings.restoreLogRawData',
  },
  totalChunk: {
    defaultMessage: 'Total chunk',
    id: 'settings.totalChunk',
  },
  totalEntry: {
    defaultMessage: 'Total entry',
    id: 'settings.totalEntry',
  },
  detectionIndex: {
    defaultMessage: 'Detection index',
    id: 'settings.detectionIndex',
  },
  trainingLock: {
    defaultMessage: 'Training lock',
    id: 'settings.trainingLock',
  },
  lockDuration: {
    defaultMessage: 'Lock duration',
    id: 'settings.lockDuration',
  },
  blockedIndex: {
    defaultMessage: 'Blocked index',
    id: 'settings.blockedIndex',
  },
  clearLock: {
    defaultMessage: 'Clear Lock',
    id: 'settings.clearLock',
  },
  dispatchDetection: {
    defaultMessage: 'Dispatch Detection',
    id: 'settings.dispatchDetection',
  },
  progressDetectionInfo: {
    defaultMessage: 'Progress Detection Info',
    id: 'settings.progressDetectionInfo',
  },

  samples: {
    defaultMessage: 'Samples',
    id: 'settings.samples',
  },
  modelDesc: {
    defaultMessage:
      'Each heat map models the behavior of one instance. Red areas represent frequent behaviors (i.e. normal states) and the size of the red areas indicates the ranges of different metric values.',
    id: 'settings.modelDesc',
  },
  noMetricConfigurationFound: {
    defaultMessage: 'No metric configuration found for this component.',
    id: 'settings.noMetricConfigurationFound',
  },
  noCapacityPlanningFound: {
    defaultMessage: 'No capacity planning configuration found for this component.',
    id: 'settings.noCapacityPlanningFound',
  },
  metricSettingDesc1: {
    defaultMessage: 'Note: these settings are not required for basic alerting and notifications.',
    id: 'settings.metricSettingDesc1',
  },
  addMultipleToSplit: {
    defaultMessage: 'Add multiple to "," split',
    id: 'settings.addMultipleToSplit',
  },
  clickToDisplayDeletedCheckbox: {
    defaultMessage: 'Click to display the deleted checkbox',
    id: 'settings.clickToDisplayDeletedCheckbox',
  },
  allMetricSettingReset: {
    defaultMessage: 'All the metric setting use this type will be reset!',
    id: 'settings.allMetricSettingReset',
  },
  editMetricType: {
    defaultMessage: 'Edit Metric Type',
    id: 'settings.editMetricType',
  },
  metricSettingDesc2: {
    defaultMessage: 'Use these settings to be notified before specific SLAs are breached.',
    id: 'settings.metricSettingDesc2',
  },
  patternIDGenerationRule: {
    defaultMessage: 'Pattern ID generation rule',
    id: 'settings.patternIDGenerationRule',
  },
  patternIDGenerationRuleMessage: {
    defaultMessage: 'Generate unique pattern id for metrics based on their metric name or metric type.',
    id: 'settings.patternIDGenerationRuleMessage',
  },
  instanceMessage: {
    defaultMessage: 'The instances that this metric configuration is associated with.',
    id: 'settings.instanceMessage',
  },
  statisticsMessage: {
    defaultMessage: 'For the time period chosen above, the average value of this metric.',
    id: 'settings.statisticsMessage',
  },
  alertThresholdsAndDetectionTypeMessage: {
    defaultMessage: 'Detailed configuration for alerting and generating anomalies.',
    id: 'settings.alertThresholdsAndDetectionTypeMessage',
  },
  detectionTypeMessage: {
    defaultMessage:
      'Positive - higher than normal detection, Negative - lower than normal detection, can choose either one or both.',
    id: 'settings.detectionTypeMessage',
  },
  positiveAlertThresholdTypeMessage: {
    defaultMessage: 'Generating anomaly when metric value is higher than this threshold.',
    id: 'settings.positiveAlertThresholdTypeMessage',
  },
  positiveNoAlertMinMessage: {
    defaultMessage: 'No anomaly will be generated when metric value is higher than this value.',
    id: 'settings.positiveNoAlertMinMessage',
  },
  positivenNoAlertMaxMessage: {
    defaultMessage: 'No anomaly will be generated when metric value is lower than this value.',
    id: 'settings.positivenNoAlertMaxMessage',
  },
  negativeAlertThresholdMessage: {
    defaultMessage: 'Generating anomaly when metric value is lower than this threshold.',
    id: 'settings.negativeAlertThresholdMessage',
  },
  negativeNoAlertMinMessage: {
    defaultMessage: 'No anomaly will be generated when metric value is higher than this value.',
    id: 'settings.negativeNoAlertMinMessage',
  },
  negativeNoAlertMaxMessage: {
    defaultMessage: 'No anomaly will be generated when metric value is lower than this value.',
    id: 'settings.negativeNoAlertMaxMessage',
  },
  ignoreThresholds: {
    defaultMessage: 'Ignore thresholds',
    id: 'settings.ignoreThresholds',
  },
  ignoreThresholdsMessage: {
    defaultMessage:
      'Used for ignoring out of bound metric values. Example, for disk usage %, any value below 0 or above 100 can be ignored.',
    id: 'settings.ignoreThresholdsMessage',
  },
  KPIMessage: {
    defaultMessage:
      'Set metric to KPI to generate an alert. If this is not checked it will appear only as a metric anomaly.',
    id: 'settings.KPIMessage',
  },
  escalateIncidentMessage: {
    defaultMessage: 'When an anomaly occurs, escalate to an incident. KPI needs to be selected.',
    id: 'settings.escalateIncidentMessage',
  },
  kpiThresholdMessage: {
    defaultMessage: 'Metric anomaly needs to last at least this duration threshold to become a KPI metric alert.',
    id: 'settings.kpiThresholdMessage',
  },
  dampeningPeriodMessage: {
    defaultMessage:
      'Generate anomalies only for this period. Any anomalies beyond the dampening period will not be generated.',
    id: 'settings.dampeningPeriodMessage',
  },
  nearConstantDetectionMessage: {
    defaultMessage:
      'For metrics that are typically at a constant value, this detect any slight changes in those values.',
    id: 'settings.nearConstantDetectionMessage',
  },
  ignoreMessage: {
    defaultMessage: 'Ignore any anomaly on chosen components.',
    id: 'settings.ignoreMessage',
  },
  computeDifferenceMessage: {
    defaultMessage: 'For metrics that is counter type and continue increases.',
    id: 'settings.computeDifferenceMessage',
  },
  enableMissingDataFillingMessage: {
    defaultMessage: 'Use the value zero for any missing data points.',
    id: 'settings.enableMissingDataFillingMessage',
  },
  metricTypeMessage: {
    defaultMessage: 'The category this metric belongs to. ',
    id: 'settings.metricTypeMessage',
  },
  metricType: {
    defaultMessage: 'Metric Type',
    id: 'settings.metricType',
  },
  alertThreshold: {
    defaultMessage: 'Alert range',
    id: 'settings.alertThreshold',
  },
  noAlertThreshold: {
    defaultMessage: 'No alert range',
    id: 'settings.noAlertThreshold',
  },
  incidentThreshold: {
    defaultMessage: 'Incident range',
    id: 'settings.incidentThreshold',
  },
  noIncidentThreshold: {
    defaultMessage: 'No incident range',
    id: 'settings.noIncidentThreshold',
  },
  detectionType: {
    defaultMessage: 'Detection type',
    id: 'settings.detectionType',
  },
  KPI: {
    defaultMessage: 'KPI',
    id: 'settings.KPI',
  },
  kpiThreshold: {
    defaultMessage: 'KPI duration threshold (min)',
    id: 'settings.kpiThreshold',
  },
  computeDifference: {
    defaultMessage: 'Cumulative',
    id: 'settings.computeDifference',
  },
  modified: {
    defaultMessage: 'Modified',
    id: 'settings.modified',
  },
  threshold: {
    defaultMessage: 'Threshold',
    id: 'settings.threshold',
  },
  triggeringMode: {
    defaultMessage: 'Triggering mode',
    id: 'settings.triggeringMode',
  },
  logProcessing: {
    defaultMessage: 'Log Processing',
    id: 'settings.logProcessing',
  },
  logReceiving: {
    defaultMessage: 'Log Receiving',
    id: 'settings.logReceiving',
  },
  logCacheSize: {
    defaultMessage: 'Log cache size',
    id: 'settings.logCacheSize',
  },
  logTraining: {
    defaultMessage: 'Log Training',
    id: 'settings.logTraining',
  },
  modelSpan: {
    defaultMessage: 'Model span (Days)',
    id: 'settings.modelSpan',
  },
  featureVectorLength: {
    defaultMessage: 'Feature vector length',
    id: 'settings.featureVectorLength',
  },
  maximumTrainingDataSample: {
    defaultMessage: 'Maximum training data sample value per model',
    id: 'settings.maximumTrainingDataSample',
  },
  modelKeywordSetting: {
    defaultMessage: 'Model keyword setting',
    id: 'settings.modelKeywordSetting',
  },
  holisticModel: {
    defaultMessage: 'Holistic model',
    id: 'settings.holisticModel',
  },
  groupingByInstance: {
    defaultMessage: 'Grouping by instance',
    id: 'settings.groupingByInstance',
  },
  zoneNameKey: {
    defaultMessage: 'Zone name key',
    id: 'settings.zoneNameKey',
  },
  disableLogCompressEvent: {
    defaultMessage: 'Disable normal log saving in edge-brain',
    id: 'settings.disableLogCompressEvent',
  },
  enableNLP: {
    defaultMessage: 'Enable NLP',
    id: 'settings.enableNLP',
  },
  disableModelKeywordStatsCollection: {
    defaultMessage: 'Disable model keyword stats collection',
    id: 'settings.disableModelKeywordStatsCollection',
  },
  ignoreInstanceForKB: {
    defaultMessage: 'Ignore Instance For KB',
    id: 'settings.ignoreInstanceForKB',
  },
  logDetection: {
    defaultMessage: 'Log Detection',
    id: 'settings.logDetection',
  },
  maximumLogDetectionThread: {
    defaultMessage: 'Maximum log detection threads',
    id: 'settings.maximumLogDetectionThread',
  },
  minimumLogEntryValue: {
    defaultMessage: 'Minimum log entry value for one analysis batch',
    id: 'settings.minimumLogEntryValue',
  },
  maximumLogEntryValue: {
    defaultMessage: 'Maximum log entry value in one analysis batch',
    id: 'settings.maximumLogEntryValue',
  },
  maximumWaitTimeForTriggering: {
    defaultMessage: 'Maximum wait time for triggering one analysis batch (min)',
    id: 'settings.maximumWaitTimeForTriggering',
  },
  keywordSetting: {
    defaultMessage: 'Keyword setting',
    id: 'settings.keywordSetting',
  },
  similarityDegree: {
    defaultMessage: 'Similarity degree',
    id: 'settings.similarityDegree',
  },
  patternLimitSize: {
    defaultMessage: 'Pattern limit size',
    id: 'settings.patternLimitSize',
  },
  patternLimitSizeDesc: {
    defaultMessage: 'Typical limit range is 10 - 1024',
    id: 'settings.patternLimitSizeDesc',
  },
  numberLimit: {
    defaultMessage: 'Number limit',
    id: 'settings.numberLimit',
  },
  numberLimitDesc: {
    defaultMessage: 'The maximum number of anomaly events detect per day',
    id: 'settings.numberLimitDesc',
  },
  maximumPredictionInterval: {
    defaultMessage: 'Maximum prediction interval (Hours)',
    id: 'settings.maximumPredictionInterval',
  },
  minimumPredictionInterval: {
    defaultMessage: 'Minimum prediction interval (min)',
    id: 'settings.minimumPredictionInterval',
  },
  incidentPredictionValidationWindow: {
    defaultMessage: 'Incident prediction validation window (Hours)',
    id: 'settings.incidentPredictionValidationWindow',
  },
  predictedIncidentCountThreshold: {
    defaultMessage: 'Predicted incident count threshold',
    id: 'settings.predictedIncidentCountThreshold',
  },
  predictedIncidentProbabilityThreshold: {
    defaultMessage: 'Predicted incident probability threshold (%)',
    id: 'settings.predictedIncidentProbabilityThreshold',
  },
  averageIncidentDowntimeCost: {
    defaultMessage: 'Average per incident downtime cost (Dollars)',
    id: 'settings.averageIncidentDowntimeCost',
  },
  rootCauseAnalysisSetting: {
    defaultMessage: 'Root Cause Analysis Setting',
    id: 'settings.rootCauseAnalysisSetting',
  },
  maximumRootCauseDelay: {
    defaultMessage: 'Maximum root cause delay (min)',
    id: 'settings.maximumRootCauseDelay',
  },
  compositeRCALimit: {
    defaultMessage: 'Composite RCA limit',
    id: 'settings.compositeRCALimit',
  },
  rootCauseNumberLimitIncident: {
    defaultMessage: 'Root cause number limit per incident',
    id: 'settings.rootCauseNumberLimitIncident',
  },
  rootCauseCountThreshold: {
    defaultMessage: 'Root cause count threshold',
    id: 'settings.rootCauseCountThreshold',
  },
  rootCauseProbabilityThreshold: {
    defaultMessage: 'Root cause probability threshold (%)',
    id: 'settings.rootCauseProbabilityThreshold',
  },
  rootCauseLogSearchWindow: {
    defaultMessage: 'Root cause log message search window (min)',
    id: 'settings.rootCauseLogSearchWindow',
  },
  causalAnalysisSetting: {
    defaultMessage: 'Causal Analysis Setting',
    id: 'settings.causalAnalysisSetting',
  },
  IPAndRCARelationSelection: {
    defaultMessage: 'Incident prediction/RCA relation selection',
    id: 'settings.IPAndRCARelationSelection',
  },
  rootCauseRank: {
    defaultMessage: 'Root cause rank',
    id: 'settings.rootCauseRank',
  },
  maximumRootCauseHop: {
    defaultMessage: 'Maximum root cause hop count',
    id: 'settings.maximumRootCauseHop',
  },
  anomalyNumberLimitRootCauseSearch: {
    defaultMessage: 'Anomaly number limit in root cause search',
    id: 'settings.anomalyNumberLimitRootCauseSearch',
  },
  causalPredictionCacheTimeout: {
    defaultMessage: 'Causal prediction cache timeout (min)',
    id: 'settings.causalPredictionCacheTimeout',
  },
  enableKnowledgeBasedPrediction: {
    defaultMessage: 'Enable knowledge base driven incident prediction',
    id: 'settings.enableKnowledgeBasedPrediction',
  },
  enableKPIPrediction: {
    defaultMessage: 'Enable KPI incident prediction',
    id: 'settings.enableKPIPrediction',
  },
  predictionRuleActiveThreshold: {
    defaultMessage:
      'Prediction rule enabling probability threshold (%) for predicting incidents produced in the current project',
    id: 'settings.predictionRuleActiveThreshold',
  },
  predictionRuleInactiveThreshold: {
    defaultMessage:
      'Prediction rule disabling probability threshold (%) for predicting incidents produced in the current project',
    id: 'settings.predictionRuleInactiveThreshold',
  },
  predictionRuleEnablingStatus: {
    defaultMessage: 'Prediction rule enabling status for incident project',
    id: 'settings.predictionRuleEnablingStatus',
  },
  predictionRuleFalsePositiveThreshold: {
    defaultMessage: 'False positive threshold',
    id: 'settings.predictionRuleFalsePositiveThreshold',
  },
  kbTrainingLength: {
    defaultMessage: 'Knowledge base training length',
    id: 'settings.kbTrainingLength',
  },
  normalLogPattern: {
    defaultMessage: 'Normal log pattern',
    id: 'settings.normalLogPattern',
  },
  includeNormalPattern: {
    defaultMessage: 'Include normal pattern in causal analysis',
    id: 'settings.includeNormalPattern',
  },
  normalLogPatternDesc: {
    defaultMessage:
      'This setting allows you to include or exclude Normal type log patterns from Causal Analysis. All other Log types will always be included in Causal Analysis, including, Critical, Hot, Cold, Whitelist, Rare and New Log pattens.',
    id: 'settings.normalLogPatternDesc',
  },
  logAnomalyDetection: {
    defaultMessage: 'Log Anomaly Detection',
    id: 'settings.logAnomalyDetection',
  },
  rareEventDetection: {
    defaultMessage: 'Rare Event Detection',
    id: 'settings.rareEventDetection',
  },
  incidentEventDetection: {
    defaultMessage: 'Incident Event Detection',
    id: 'settings.incidentEventDetection',
  },
  rareEventDetectionDesc: {
    defaultMessage:
      'This setting controls the sensitivity with which InsightFinder will cluster logs and detect anomalous logs. With higher sensitivity, system detects more anomalies.',
    id: 'settings.rareEventDetectionDesc',
  },
  enableAllRareEventDetection: {
    defaultMessage: 'Enable all rare event detection',
    id: 'settings.enableAllRareEventDetection',
  },
  rareEventClusterSize: {
    defaultMessage: 'Rare event cluster size',
    id: 'settings.rareEventClusterSize',
  },
  rareEventClusterSizeDesc: {
    defaultMessage: 'Larger rare event cluster size produces more rare events',
    id: 'settings.rareEventClusterSizeDesc',
  },
  rareEventBaseScore: {
    defaultMessage: 'Rare event base score',
    id: 'settings.rareEventBaseScore',
  },
  rareEventBaseScoreDesc: {
    defaultMessage: 'The unit anomaly score assigned to each rare event',
    id: 'settings.rareEventBaseScoreDesc',
  },
  rareEventNumberLimit: {
    defaultMessage: 'Rare event number limit',
    id: 'settings.rareEventNumberLimit',
  },
  rareEventNumberLimitDesc: {
    defaultMessage: 'The maximum number of rare events detect per day',
    id: 'settings.rareEventNumberLimitDesc',
  },
  rareAnomalyType: {
    defaultMessage: 'Rare anomaly type',
    id: 'settings.rareAnomalyType',
  },
  whitelistEventDetection: {
    defaultMessage: 'Whitelist Event Detection',
    id: 'settings.whitelistEventDetection',
  },
  baseScore: {
    defaultMessage: 'Base score',
    id: 'settings.baseScore',
  },
  baseScoreDesc: {
    defaultMessage: 'The unit anomaly score assigned to each anomaly event',
    id: 'settings.baseScoreDesc',
  },
  newPatternDetection: {
    defaultMessage: 'New Pattern Detection',
    id: 'settings.newPatternDetection',
  },
  newPatternDetectionPeriod: {
    defaultMessage: 'New pattern detection period (days)',
    id: 'settings.newPatternDetectionPeriod',
  },
  newPatternDetectionPeriodDesc: {
    defaultMessage:
      'The time window for checking whether a pattern has appeared before. If the pattern has not appeared before in the specified time window, the pattern is labelled as a new pattern',
    id: 'settings.newPatternDetectionPeriodDesc',
  },
  criticalEventDetection: {
    defaultMessage: 'Critical Event Detection',
    id: 'settings.criticalEventDetection',
  },
  hotColdEventDetection: {
    defaultMessage: 'Hot/Cold Event Detection',
    id: 'settings.hotColdEventDetection',
  },
  hotColdEventDetectionDesc: {
    defaultMessage:
      'Sensitivity: This setting controls sensitivity InsightFinder will alert on frequency anomaly in given a time window. With higher sensitivity, system detects more anomalies.',
    id: 'settings.hotColdEventDetectionDesc',
  },
  incidentPredictionDesc: {
    defaultMessage:
      'The following settings provide configurations for those incidents that are predicted from the current project (i.e., prediction source project).',
    id: 'settings.incidentPredictionDesc',
  },
  knowledgeBaseDesc: {
    defaultMessage:
      'The following settings provide configurations for those incidents that are defined in the current project (e.g., prediction target project).',
    id: 'settings.knowledgeBaseDesc',
  },
  rootCauseAnalysisDesc: {
    defaultMessage:
      'This page provides configurations for the root causes that lead to any incident produced by this project "{projectName}".',
    id: 'settings.rootCauseAnalysisDesc',
  },
  minimumFrequencyHotDetection: {
    defaultMessage: 'Minimum log frequency for hot event detection',
    id: 'settings.minimumFrequencyHotDetection',
  },
  hotEventCalmDownPeriod: {
    defaultMessage: 'Hot/Cold event detection dampening period (days)',
    id: 'settings.hotEventCalmDownPeriod',
  },
  hotEventUnitScore: {
    defaultMessage: 'Hot event unit score',
    id: 'settings.hotEventUnitScore',
  },
  coldEventUnitScore: {
    defaultMessage: 'Cold event unit score',
    id: 'settings.coldEventUnitScore',
  },
  hotEventNumberLimit: {
    defaultMessage: 'Hot event number limit',
    id: 'settings.hotEventNumberLimit',
  },
  hotEventNumberLimitDesc: {
    defaultMessage: 'The maximum number of hot events detect per day',
    id: 'settings.hotEventNumberLimitDesc',
  },
  coldEventNumberLimit: {
    defaultMessage: 'Cold event number limit',
    id: 'settings.coldEventNumberLimit',
  },
  coldEventNumberLimitDesc: {
    defaultMessage: 'The maximum number of cold events detect per day',
    id: 'settings.coldEventNumberLimitDesc',
  },
  coldEventThreshold: {
    defaultMessage: 'Cold event threshold',
    id: 'settings.coldEventThreshold',
  },
  coldEventThresholdDesc: {
    defaultMessage: 'When the number of events is smaller than the threshold, the log analysis detects the cold events',
    id: 'settings.coldEventThresholdDesc',
  },
  hotEventThreshold: {
    defaultMessage: 'Hot event threshold',
    id: 'settings.hotEventThreshold',
  },
  hotEventThresholdDesc: {
    defaultMessage: 'When the number of events is larger than the threshold, the log analysis detects the hot events',
    id: 'settings.hotEventThresholdDesc',
  },
  hotEventDetectionMode: {
    defaultMessage: 'All patterns / Whitelist patterns only',
    id: 'settings.hotEventDetectionMode',
  },
  enableHotEvent: {
    defaultMessage: 'Enable Hot/Cold Event Detection',
    id: 'settings.enableHotEvent',
  },
  hotEventDetectionModeAll: {
    defaultMessage: 'All patterns',
    id: 'settings.hotEventDetectionModeAll',
  },
  hotEventDetectionModeWhitelist: {
    defaultMessage: 'Whitelist patterns only',
    id: 'settings.hotEventDetectionModeWhitelist',
  },
  hotSamplingInterval: {
    defaultMessage: 'Hot event detection sampling interval',
    id: 'settings.hotSamplingInterval',
  },
  incidentConsolidationInterval: {
    defaultMessage: 'Incident consolidation interval (min)',
    id: 'settings.incidentConsolidationInterval',
  },
  incidentCountThreshold: {
    defaultMessage: 'Incident count filtering threshold (<)',
    id: 'settings.incidentCountThreshold',
  },
  incidentDurationThreshold: {
    defaultMessage: 'Incident duration filtering threshold (<) (min)',
    id: 'settings.incidentDurationThreshold',
  },
  incidentDetection: {
    defaultMessage: 'Incident Detection',
    id: 'settings.incidentDetection',
  },
  instanceDownThreshold: {
    defaultMessage: 'Instance down duration threshold (min)',
    id: 'settings.instanceDownThreshold',
  },
  instanceDownDampening: {
    defaultMessage: 'Instance down dampening (min)',
    id: 'settings.instanceDownDampening',
  },
  maximumDownInstanceReportingNumber: {
    defaultMessage: 'Maximum down instance reporting number',
    id: 'settings.maximumDownInstanceReportingNumber',
  },
  laborCost: {
    defaultMessage: 'Downtime/Labor cost',
    id: 'settings.laborCost',
  },
  hourlyLaborCost: {
    defaultMessage: 'Hourly labor cost (Dollars)',
    id: 'settings.hourlyLaborCost',
  },
  averageTriageTime: {
    defaultMessage: 'Average triage time (min)',
    id: 'settings.averageTriageTime',
  },
  emailAlertSetting: {
    defaultMessage: 'New pattern notification',
    id: 'settings.emailAlertSetting',
  },
  alertBufferTime: {
    defaultMessage: 'Alert buffer time (min)',
    id: 'settings.alertBufferTime',
  },
  dampeningPeriod: {
    defaultMessage: 'Dampening period (min)',
    id: 'settings.dampeningPeriod',
  },
  nearConstantDetection: {
    defaultMessage: 'Near constant detection',
    id: 'settings.nearConstantDetection',
  },
  incidentPredictionMinimumLeadTime: {
    defaultMessage: 'Incident prediction minimum lead time (min)',
    id: 'settings.incidentPredictionMinimumLeadTime',
  },
  incidentPredictionLookWindow: {
    defaultMessage: 'Incident prediction look ahead window (Hours)',
    id: 'settings.incidentPredictionLookWindow',
  },
  enableNewAlertEmail: {
    defaultMessage: 'Enable new pattern alert email',
    id: 'settings.enableNewAlertEmail',
  },
  instanceDownDetection: {
    defaultMessage: 'Instance down notification',
    id: 'settings.instanceDownDetection',
  },
  enableInstanceDownDetection: {
    defaultMessage: 'Enable instance down detection',
    id: 'settings.enableInstanceDownDetection',
  },
  FFTMetricPrediction: {
    defaultMessage: 'FFT Metric Prediction',
    id: 'settings.FFTMetricPrediction',
  },
  anomalyDetectionSetting: {
    defaultMessage: 'Anomaly Detection Setting',
    id: 'settings.anomalyDetectionSetting',
  },
  anomalyDetectionSettingDesc: {
    defaultMessage:
      'This setting controls the sensitivity with which InsightFinder will detect and create anomaly events and display them in the Dashboard. With higher sensitivity, system detects more anomalies.',
    id: 'settings.anomalyDetectionSettingDesc',
  },
  lowAnomalyRatioDurationThreshold: {
    defaultMessage: 'Low anomaly ratio duration threshold',
    id: 'settings.lowAnomalyRatioDurationThreshold',
  },
  highAnomalyRatioDurationThreshold: {
    defaultMessage: 'High anomaly ratio duration threshold',
    id: 'settings.highAnomalyRatioDurationThreshold',
  },
  anomalyGapToleranceDuration: {
    defaultMessage: 'Anomaly gap tolerance duration',
    id: 'settings.anomalyGapToleranceDuration',
  },
  anomalyGapToleranceDurationTooltip: {
    defaultMessage: 'If value is 0 it means disable the anomaly gap tolerance duration',
    id: 'settings.anomalyGapToleranceDurationTooltip',
  },
  maximumHintNumber: {
    defaultMessage: 'Maximum Hint Number',
    id: 'settings.maximumHintNumber',
  },
  metricDataPredictionTrainingDataLength: {
    defaultMessage: 'Metric Data Prediction Training Data Length(Min)',
    id: 'settings.metricDataPredictionTrainingDataLength',
  },
  enableDynamicBaselineDetection: {
    defaultMessage: 'Enable dynamic baseline detection',
    id: 'settings.enableDynamicBaselineDetection',
  },
  enableBaselineValidation: {
    defaultMessage: 'Enable Baseline Validation',
    id: 'settings.enableBaselineValidation',
  },
  enableAnomalyFilterForBaseline: {
    defaultMessage: 'Enable anomaly filter for baseline',
    id: 'settings.enableAnomalyFilterForBaseline',
  },
  baselineSpanHours: {
    defaultMessage: 'Baseline span (hours)',
    id: 'settings.baselineSpanHours',
  },
  dynamicBaselineViolationFactor: {
    defaultMessage: 'Dynamic Baseline Violation Factor',
    id: 'settings.dynamicBaselineViolationFactor',
  },
  positiveAnomalyDetection: {
    defaultMessage: 'Positive anomaly detection',
    id: 'settings.positiveAnomalyDetection',
  },
  negativeAnomalyDetection: {
    defaultMessage: 'Negative anomaly detection',
    id: 'settings.negativeAnomalyDetection',
  },
  enablePeriodAnomalyFilter: {
    defaultMessage: 'Enable period detection filter',
    id: 'settings.enablePeriodAnomalyFilter',
  },
  enableCumulativeDetect: {
    defaultMessage: 'Enable automatic cumulative metric detection',
    id: 'settings.enableCumulativeDetect',
  },
  enableUBLDetect: {
    defaultMessage: 'Enable UBL detection',
    id: 'settings.enableUBLDetect',
  },
  enableMetricPrediction: {
    defaultMessage: 'Enable metric prediction',
    id: 'settings.enableMetricPrediction',
  },
  none: {
    defaultMessage: 'None',
    id: 'settings.none',
  },
  lettersOnly: {
    defaultMessage: 'Letters only',
    id: 'settings.lettersOnly',
  },
  lettersAndNumbers: {
    defaultMessage: 'Letters and Numbers',
    id: 'settings.lettersAndNumbers',
  },
  allRelationships: {
    defaultMessage: 'All relationships',
    id: 'settings.allRelationships',
  },
  interInstanceRelationships: {
    defaultMessage: 'Inter-instance relationships',
    id: 'settings.interInstanceRelationships',
  },
  intraInstanceRelationships: {
    defaultMessage: 'Intra-instance relationships',
    id: 'settings.intraInstanceRelationships',
  },
  noPreference: {
    defaultMessage: 'No preference',
    id: 'settings.noPreference',
  },
  interInstanceFirst: {
    defaultMessage: 'Inter-instance first',
    id: 'settings.interInstanceFirst',
  },
  intraInstanceFirst: {
    defaultMessage: 'Intra-instance first',
    id: 'settings.intraInstanceFirst',
  },
  editSystem: {
    defaultMessage: 'Edit system',
    id: 'settings.editSystem',
  },
  matchedCount: {
    defaultMessage: 'Matched count',
    id: 'settings.matchedCount',
  },
  unmatchedCount: {
    defaultMessage: 'Unmatched count',
    id: 'settings.unmatchedCount',
  },
  truePositiveInfo: {
    defaultMessage: 'True positive info',
    id: 'settings.truePositiveInfo',
  },
  falsePositiveInfo: {
    defaultMessage: 'False positive info',
    id: 'settings.falsePositiveInfo',
  },
  rootCauseInfo: {
    defaultMessage: 'Root cause stats',
    id: 'settings.rootCauseInfo',
  },
  systemSharedUsers: {
    defaultMessage: 'System shared users',
    id: 'settings.systemSharedUsers',
  },
  addShareUser: {
    defaultMessage: 'Add share user',
    id: 'settings.addShareUser',
  },
  systemProxy: {
    defaultMessage: 'Action server list',
    id: 'settings.systemProxy',
  },
  addProxy: {
    defaultMessage: 'Add proxy',
    id: 'settings.addProxy',
  },
  editTableMapping: {
    defaultMessage: 'Edit table mapping',
    id: 'settings.editTableMapping',
  },
  editComponentMap: {
    defaultMessage: 'Edit component mapping',
    id: 'settings.editComponentMap',
  },
  projectSetting: {
    defaultMessage: 'Project setting',
    id: 'settings.projectSetting',
  },
  setting: {
    defaultMessage: 'Setting',
    id: 'settings.setting',
  },
  metricName: {
    defaultMessage: 'Metric name',
    id: 'settings.metricName',
  },
  metricNameRegex: {
    defaultMessage: 'Metric name regex',
    id: 'settings.metricNameRegex',
  },
  metricValueRegex: {
    defaultMessage: 'Metric value regex',
    id: 'settings.metricValueRegex',
  },
  instanceNameRegex: {
    defaultMessage: 'Instance name regex',
    id: 'settings.instanceNameRegex',
  },
  containerNameRegex: {
    defaultMessage: 'Container name regex',
    id: 'settings.containerNameRegex',
  },
  timestampRegex: {
    defaultMessage: 'Timestamp regex',
    id: 'settings.timestampRegex',
  },
  jsonFlag: {
    defaultMessage: 'Json flag',
    id: 'settings.jsonFlag',
  },
  updateBaseValueMapping: {
    defaultMessage: 'Update base value mapping',
    id: 'settings.updateBaseValueMapping',
  },
  enableAndDisableMapping: {
    defaultMessage: 'Enable/Disable mapping',
    id: 'settings.enableAndDisableMapping',
  },
  baseValueName: {
    defaultMessage: 'Baseline key name',
    id: 'settings.baseValueName',
  },
  metricValueKey: {
    defaultMessage: 'Metric value key name',
    id: 'settings.metricValueKey',
  },
  additionalMetricNameKey: {
    defaultMessage: 'Additional metric name key',
    id: 'settings.additionalMetricNameKey',
  },
  instanceNameKey: {
    defaultMessage: 'Instance name key',
    id: 'settings.instanceNameKey',
  },
  containerNameKey: {
    defaultMessage: 'Container name key',
    id: 'settings.containerNameKey',
  },
  timestampKey: {
    defaultMessage: 'Timestamp key',
    id: 'settings.timestampKey',
  },
  timestampFormat: {
    defaultMessage: 'Timestamp format',
    id: 'settings.timestampFormat',
  },
  importInstanceMetaData: {
    defaultMessage: 'Import instance meta data',
    id: 'settings.importInstanceMetaData',
  },
  metricInstanceNameSetting: {
    defaultMessage: 'Metric instance name setting',
    id: 'settings.metricInstanceNameSetting',
  },
  groupSettingsUpdate: {
    defaultMessage: 'Group settings update',
    id: 'settings.groupSettingsUpdate',
  },
  addBaseValueMapping: {
    defaultMessage: 'Add base value mapping',
    id: 'settings.addBaseValueMapping',
  },
  Log: {
    defaultMessage: 'Log',
    id: 'settings.Log',
  },
  Metric: {
    defaultMessage: 'Metric',
    id: 'settings.Metric',
  },
  Alert: {
    defaultMessage: 'Alert',
    id: 'settings.Alert',
  },
  Incident: {
    defaultMessage: 'Incident',
    id: 'settings.Incident',
  },
  Deployment: {
    defaultMessage: 'Change Events',
    id: 'settings.Deployment',
  },
  Trace: {
    defaultMessage: 'Trace',
    id: 'settings.Trace',
  },
  healthAlert: {
    defaultMessage: 'Incident detection/health alert notification',
    id: 'settings.healthAlert',
  },
  healthEmail: {
    defaultMessage: 'Health alert notification',
    id: 'settings.healthEmail',
  },
  incidentEmail: {
    defaultMessage: 'Incident detection notification',
    id: 'settings.incidentEmail',
  },
  enableVerticalScaling: {
    defaultMessage: 'Enable vertical scaling',
    id: 'settings.enableVerticalScaling',
  },
  enableHorizontalScaling: {
    defaultMessage: 'Enable horizontal scaling',
    id: 'settings.enableHorizontalScaling',
  },
  autoScalingSetting: {
    defaultMessage: 'Auto-scaling setting',
    id: 'settings.autoScalingSetting',
  },
  kubernetesEndpoint: {
    defaultMessage: 'Kubernetes endpoint',
    id: 'settings.kubernetesEndpoint',
  },
  zoneSecreteForJWT: {
    defaultMessage: 'Zone secret for JWT',
    id: 'settings.zoneSecreteForJWT',
  },
  zoneName: {
    defaultMessage: 'Zone name',
    id: 'settings.zoneName',
  },
  zoneKey: {
    defaultMessage: 'Zone key',
    id: 'settings.zoneKey',
  },
  level: {
    defaultMessage: 'Level',
    id: 'settings.level',
  },
  zoneLevel: {
    defaultMessage: 'Zone level',
    id: 'settings.zoneLevel',
  },
  systemLevel: {
    defaultMessage: 'System level',
    id: 'settings.systemLevel',
  },
  addZoneSecrete: {
    defaultMessage: 'Add zone secrete',
    id: 'settings.addZoneSecrete',
  },
  editZoneSecrete: {
    defaultMessage: 'Edit zone secrete',
    id: 'settings.editZoneSecrete',
  },
  predictionLookSheadWindow: {
    defaultMessage: 'Prediction look ahead window (min)',
    id: 'settings.predictionLookSheadWindow',
  },
  additionalMetricName: {
    defaultMessage: 'Additional metric name',
    id: 'settings.additionalMetricName',
  },
  scaleUpThreshold: {
    defaultMessage: 'Scale up threshold',
    id: 'settings.scaleUpThreshold',
  },
  scaleDownThreshold: {
    defaultMessage: 'Scale down threshold',
    id: 'settings.scaleDownThreshold',
  },
  actionTriggeringDampeningPeriod: {
    defaultMessage: 'Action triggering dampening period (min)',
    id: 'settings.actionTriggeringDampeningPeriod',
  },
  notificationDampeningPeriod: {
    defaultMessage: 'Notification dampening period (min)',
    id: 'settings.notificationDampeningPeriod',
  },
  ignoredDeployments: {
    defaultMessage: 'Deployments that have scaling disabled',
    id: 'settings.ignoredDeployments',
  },
  enableEmailNotification: {
    defaultMessage: 'Enable email notification',
    id: 'settings.enableEmailNotification',
  },
  alertEmail: {
    defaultMessage: 'Alert notification',
    id: 'settings.alertEmail',
  },
  systemDown: {
    defaultMessage: 'System down notification',
    id: 'settings.systemDown',
  },
  dailyReport: {
    defaultMessage: 'Daily report notification',
    id: 'settings.dailyReport',
  },
  weeklyReport: {
    defaultMessage: 'Weekly report notification',
    id: 'settings.weeklyReport',
  },
  predictedIncidentNotification: {
    defaultMessage: 'Incident prediction notification',
    id: 'settings.predictedIncidentNotification',
  },
  inUseConfirmed: {
    defaultMessage: 'In use/Confirmed',
    id: 'settings.inUseConfirmed',
  },
  confirmed: {
    defaultMessage: 'Confirmed',
    id: 'settings.confirmed',
  },
  rareAnomalyTypeAll: {
    defaultMessage: 'All',
    id: 'settings.rareAnomalyTypeAll',
  },
  rareAnomalyTypeWordsOnly: {
    defaultMessage: 'Words only',
    id: 'settings.rareAnomalyTypeWordsOnly',
  },
  rareAnomalyTypeNumericOnly: {
    defaultMessage: 'Numerical value only',
    id: 'settings.rareAnomalyTypeNumericOnly',
  },
  valueShouldLargeThan: {
    defaultMessage: 'This field must be greater than <a>0</a>.',
    id: 'settings.valueShouldLargeThan',
  },
  enableGlobalKnowledgeBase: {
    defaultMessage: 'Enable global knowledge base prediction',
    id: 'settings.enableGlobalKnowledgeBase',
  },
  filterSelfToSelfRule: {
    defaultMessage: 'Enable self-to-self rule filtering',
    id: 'settings.filterSelfToSelfRule',
  },
  timeInsensitiveRuleMatching: {
    defaultMessage: 'Enable time insensitive rule matching',
    id: 'settings.timeInsensitiveRuleMatching',
  },
  enableIgnoreInstancePrediction: {
    defaultMessage: 'Enable instance ignoring prediction',
    id: 'settings.enableIgnoreInstancePrediction',
  },
  predictionSource: {
    defaultMessage: 'Prediction source',
    id: 'settings.predictionSource',
  },
  anomalyString: {
    defaultMessage: 'Anomaly string',
    id: 'settings.anomalyString',
  },
  healthView: {
    defaultMessage: 'Health view',
    id: 'settings.healthView',
  },
  compositeValidThreshold: {
    defaultMessage: 'Composite rule valid threshold',
    id: 'settings.compositeValidThreshold',
  },
  confidenceLevel: {
    defaultMessage: 'Confidence level (%)',
    id: 'settings.confidenceLevel',
  },
  TopAnomalousInstanceNumberLimit: {
    defaultMessage: 'Top anomalous instance number limit',
    id: 'settings.TopAnomalousInstanceNumberLimit',
  },
  createNewUser: {
    defaultMessage: 'Create new user',
    id: 'settings.createNewUser',
  },
  jsonKey: {
    defaultMessage: 'Json key',
    id: 'settings.jsonKey',
  },
  JsonKey: {
    defaultMessage: 'Json Key',
    id: 'settings.JsonKey',
  },
  summarySettings: {
    defaultMessage: 'Summary settings',
    id: 'settings.summarySettings',
  },
  totalChunkNumber: {
    defaultMessage: 'Total chunk number',
    id: 'settings.totalChunkNumber',
  },
  podName: {
    defaultMessage: 'Pod name',
    id: 'settings.podName',
  },
  pod: {
    defaultMessage: 'Pod',
    id: 'settings.pod',
  },
  pods: {
    defaultMessage: 'Pods',
    id: 'settings.pods',
  },
  minimumRequestMemory: {
    defaultMessage: 'Minimum request memory',
    id: 'settings.minimumRequestMemory',
  },
  maximumLimitMemory: {
    defaultMessage: 'Maximum limit memory',
    id: 'settings.maximumLimitMemory',
  },
  projectNameToNamespaceMapping: {
    defaultMessage: 'Project name to namespace mapping',
    id: 'settings.projectNameToNamespaceMapping',
  },
  namespaces: {
    defaultMessage: 'Namespaces',
    id: 'settings.namespaces',
  },
  enableAutoScaling: {
    defaultMessage: 'Enable auto-scaling',
    id: 'settings.enableAutoScaling',
  },
  currentFinishedChunkNumber: {
    defaultMessage: 'Current finished chunk number',
    id: 'settings.currentFinishedChunkNumber',
  },
  healthViewUpdatePeriod: {
    defaultMessage: 'Health View Update Period',
    id: 'settings.healthViewUpdatePeriod',
  },
  dataStartTime: {
    defaultMessage: 'Data start time',
    id: 'settings.dataStartTime',
  },
  dataEndTime: {
    defaultMessage: 'Data end time',
    id: 'settings.dataEndTime',
  },
  replayTriggerTimestamp: {
    defaultMessage: 'Replay trigger time',
    id: 'settings.replayTriggerTimestamp',
  },
  userDefinedExplain: {
    defaultMessage:
      'To plot the value of the metric defined by regex grouped by the instance name regex in each sampling interval',
    id: 'settings.userDefinedExplain',
  },
  countByLogEntriesExplain: {
    defaultMessage: 'The number of all log entries that match the instance name regex in each sampling interval',
    id: 'settings.countByLogEntriesExplain',
  },
  countByMetricValueExplain: {
    defaultMessage:
      'The count of all the values of the metrics defined in Metric field grouped by the instance name regex in each sampling interval',
    id: 'settings.countByMetricValueExplain',
  },
  uniqueCountByMetricValueExplain: {
    defaultMessage:
      'The count of all the unique values of the metrics defined in Metric field grouped by the instance name regex in each sampling interval',
    id: 'settings.uniqueCountByMetricValueExplain',
  },
  timestampRegexExplain: {
    defaultMessage:
      'The timestamp field from the log entry using regex. If not provided, it will use the the log entry timestamp',
    id: 'settings.timestampRegexExplain',
  },
  timestampFormatExplain: {
    defaultMessage: 'It needs to be defined if the timestamp key is not in epoch format',
    id: 'settings.timestampFormatExplain',
  },
  missingDataIncidentEscalation: {
    defaultMessage: 'Enable missing data incident escalation',
    id: 'settings.missingDataIncidentEscalation',
  },
  anomalyMetricProjects: {
    defaultMessage: 'Anomaly metric projects',
    id: 'settings.anomalyMetricProjects',
  },
  OtherMeticProjects: {
    defaultMessage: 'Other metic projects',
    id: 'settings.OtherMeticProjects',
  },
  actionExecutionTime: {
    defaultMessage: 'Action execution time(Min)',
    id: 'settings.actionExecutionTime',
  },
  autoFixValidationWindow: {
    defaultMessage: 'Auto-fix validation window(Hour)',
    id: 'settings.autoFixValidationWindow',
  },
  bacthConfigurations: {
    defaultMessage: 'Batch Configurations',
    id: 'settings.bacthConfigurations',
  },
  batchName: {
    defaultMessage: 'Batch name',
    id: 'settings.batchName',
  },
  lastDateRun: {
    defaultMessage: 'Last date run',
    id: 'settings.lastDateRun',
  },
  knowledgeBaseSetting: {
    defaultMessage: 'Knowledge base setting',
    id: 'settings.knowledgeBaseSetting',
  },
  isSourceProject: {
    defaultMessage: 'Is source project',
    id: 'settings.isSourceProject',
  },
  mappingKeys: {
    defaultMessage: 'Mapping keys',
    id: 'settings.mappingKeys',
  },
  baseValueKeys: {
    defaultMessage: 'Base value keys',
    id: 'settings.baseValueKeys',
  },
  baseValue: {
    defaultMessage: 'Base value',
    id: 'settings.baseValue',
  },
  actualValue: {
    defaultMessage: 'Actual value',
    id: 'settings.actualValue',
  },
  mappingIds: {
    defaultMessage: 'Mapping ids',
    id: 'settings.mappingIds',
  },
  targetProject: {
    defaultMessage: 'Target project',
    id: 'settings.targetProject',
  },
  metricProjects: {
    defaultMessage: 'Metric projects',
    id: 'settings.metricProjects',
  },
  namespace: {
    defaultMessage: 'Namespace',
    id: 'settings.namespace',
  },
  dataTypesToCollect: {
    defaultMessage: 'Data types to collect',
    id: 'settings.dataTypesToCollect',
  },
  authentication: {
    defaultMessage: 'Authentication',
    id: 'settings.authentication',
  },
  exploreMoreHere: {
    defaultMessage: 'Explore more here',
    id: 'settings.exploreMoreHere',
  },
  prerequsites: {
    defaultMessage: 'Prerequsites',
    id: 'settings.prerequsites',
  },
  prometheus: {
    defaultMessage: 'Prometheus',
    id: 'settings.prometheus',
  },
  loki: {
    defaultMessage: 'Loki',
    id: 'settings.loki',
  },
  addTheHelmChartRepo: {
    defaultMessage: 'Add the helm chart repo',
    id: 'settings.addTheHelmChartRepo',
  },
  helmRepoAddInsightfinder: {
    defaultMessage: 'helm repo add insightfinder',
    id: 'settings.helmRepoAddInsightfinder',
  },
  copyOrDownloadTheValueYaml: {
    defaultMessage: 'Copy / Download the values.yaml',
    id: 'settings.copyOrDownloadTheValueYaml',
  },
  installTheHelmChart: {
    defaultMessage: 'Install the helm chart',
    id: 'settings.installTheHelmChart',
  },
  kubernetesStepInstructions: {
    defaultMessage:
      'You have filled all the infomation we need to install the InsightFinder Kubernetes Collector to your cluster.',
    id: 'settings.kubernetesStepInstructions',
  },
  kubernetesInstallInstructions: {
    defaultMessage: 'Please walkthrough the following instructions to install it.',
    id: 'settings.kubernetesInstallInstructions',
  },
  copy: {
    defaultMessage: 'Copy',
    id: 'settings.copy',
  },
  installationMode: {
    defaultMessage: 'Installation mode',
    id: 'settings.installationMode',
  },
  installationModeExisting: {
    defaultMessage: 'Use existing {type} system',
    id: 'settings.installationModeExisting',
  },
  installationModeInstall: {
    defaultMessage: 'Install new {type} system for me',
    id: 'settings.installationModeInstall',
  },
});

export default settingsMessages;
