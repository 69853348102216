import React from 'react';
// import * as R from 'ramda';
import VLink from 'valuelink';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { Modal, Input, DatePicker } from '../../../../lib/fui/react';
import { ifIn } from '../../../../common/utils';

import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  intl: Object,
  currentLoadingComponents: Object,

  projectName: String,
  holidayList: Array<Object>,

  onClose: Function,
  saveProjectHoliday: Function,
  activeHoliday: Object,
};

class HolidayModal extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { activeHoliday } = props;
    this.submitLoader = 'settings_holiday_modal_submit';
    this.sumbitting = false;
    this.created = false;

    this.state = {
      holidayName: activeHoliday?.name || '',
      startTime: activeHoliday?.startTime || null,
      endTime: activeHoliday?.endTime || null,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If already submit and loading back to false, close this modal.
    const { currentLoadingComponents, onClose } = nextProps;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      this.created = true;
      onClose(this.created);
    }
  }

  @autobind
  handleSumbit() {
    const { saveProjectHoliday, projectName } = this.props;
    const { holidayName, startTime, endTime } = this.state;
    this.sumbitting = true;
    saveProjectHoliday({ projectName, holidayName, startTime, endTime }, { [this.submitLoader]: true });
  }

  render() {
    const { intl, currentLoadingComponents, holidayList, onClose, activeHoliday } = this.props;
    const holidayNameLink = VLink.state(this, 'holidayName')
      .check((n) => Boolean(n), 'input holiday name')
      .check((n) => !ifIn(n, holidayList), 'the name already exists');
    const startTimeLink = VLink.state(this, 'startTime');
    const endTimeLink = VLink.state(this, 'endTime');

    const hasError = holidayNameLink.error;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    return (
      <Modal
        title={intl.formatMessage(settingsMessages.holidays)}
        width={650}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSumbit}
        okButtonProps={{ disabled: hasError, loading: isLoading }}
      >
        <div className={`ui ${hasError ? 'error' : ''} form`} style={{ fontSize: 12 }}>
          <div className="input inline field flex-row flex-center-align">
            <label style={{ width: 120 }}>{intl.formatMessage(appFieldsMessages.name)}:</label>
            <Input valueLink={holidayNameLink} size="small" disabled={!!activeHoliday} />
            <div className="flex-grow" />
          </div>
          <div className="input inline field flex-row flex-center-align">
            <label style={{ width: 120 }}>{intl.formatMessage(appFieldsMessages.startDate)}:</label>
            <div className="ui input">
              <DatePicker dateFormat="MMMM DD" selectedLink={startTimeLink} />
            </div>
            <label style={{ width: 80, marginLeft: 24 }}>{intl.formatMessage(appFieldsMessages.endDate)}:</label>
            <div className="ui input">
              <DatePicker dateFormat="MMMM DD" selectedLink={endTimeLink} />
            </div>
            <div className="flex-grow" />
          </div>
        </div>
      </Modal>
    );
  }
}

export default HolidayModal;
