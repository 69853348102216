/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
// import * as R from 'ramda';
import moment from 'moment';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Button, Card } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { Container } from '../../../lib/fui/react';
import { buildUrl, parseLocation } from '../../../common/utils';
import { hideAppLoader } from '../../../common/app/actions';

import { appButtonsMessages, appMenusMessages } from '../../../common/app/messages';
import { settingsMenusMessages } from '../../../common/settings/messages';

import GoogleImg from '../../../../images/identity-providers/google.svg';
import LDAPSImg from '../../../../images/identity-providers/ldaps.svg';
import MSImg from '../../../../images/identity-providers/microsoft-icon.svg';
import AzureImg from '../../../../images/identity-providers/microsoft-azure-icon.svg';
import TeamsImg from '../../../../images/identity-providers/microsoft-teams-icon.svg';
import SlackImg from '../../../../images/identity-providers/slack.svg';

import IDPGoogleModal from './IDPGoogleModal';
import IDPLDAPSModal from './IDPLDAPSModal';
import IDPMSModal from './IDPMSModal';
import IDPAzureModal from './IDPAzureModal';
import IDPMSTeamsModal from './IDPMSTeamsModal';
import IDPSlackModal from './IDPSlackModal';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  replace: Function,
  hideAppLoader: Function,
  isAdmin: string,
};

class IdentityProvidersSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const params = parseLocation(location);
    const { defaultActiveModal } = params;

    this.state = {
      refresh: null,

      showIDPGoogleModal: defaultActiveModal === 'google',
      showIDPLDAPSModal: defaultActiveModal === 'ldaps',
      showIDPMSModal: defaultActiveModal === 'ms',
      showIDPAzureModal: defaultActiveModal === 'azure',
      showIDPMSTeamsModal: defaultActiveModal === 'teams',
      showIDPSlackModal: defaultActiveModal === 'slack',
    };
  }

  componentDidMount() {
    const { hideAppLoader } = this.props;
    hideAppLoader();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleRefresh() {
    this.setState({ refresh: moment.utc().valueOf() });
  }

  render() {
    const { intl, location, isAdmin } = this.props;
    const query = parseLocation(location);

    return (
      <Container fullHeight withGutter className="flex-col">
        <Container className="flex-row flex-center-align" breadcrumb>
          <div className="section">
            <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
            <span className="divider">/</span>
            <NavLink to={buildUrl(BaseUrls.IdentityProvidersSetting, {}, query)}>
              <span className="label">{intl.formatMessage(settingsMenusMessages.identityProvider)}</span>
            </NavLink>
          </div>
          <div className="flex-grow" />
          <div>
            <Button size="small" onClick={this.handleRefresh}>
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </div>
        </Container>
        <Container
          className="flex-grow flex-min-height overflow-y-auto content-bg corner-10"
          style={{
            margin: '0 16px 8px 16px',
            borderRadius: 4,
            padding: '8px 16px',
          }}
        >
          <Container className="flex-row flex-wrap overflow-y-auto">
            {isAdmin && (
              <Card
                hoverable
                style={{ width: 180, margin: 8 }}
                cover={<img alt="GoogleImg" src={GoogleImg} />}
                onClick={() => this.setState({ showIDPGoogleModal: true })}
              >
                <Card.Meta
                  style={{ wordBreak: 'break-all' }}
                  title="Google"
                  description="https://cloud.google.com/architecture/identity/single-sign-on"
                />
              </Card>
            )}
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={<img alt="LDAPSImg" src={LDAPSImg} style={{ padding: 8 }} />}
              onClick={() => this.setState({ showIDPLDAPSModal: true })}
            >
              <Card.Meta
                style={{ wordBreak: 'break-all' }}
                title="OpenLDAP"
                // eslint-disable-next-line max-len
                description="https://www.openldap.org"
              />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={<img alt="MSImg" src={MSImg} />}
              onClick={() => this.setState({ showIDPMSModal: true })}
            >
              <Card.Meta
                style={{ wordBreak: 'break-all' }}
                title="Microsoft AD"
                // eslint-disable-next-line max-len
                description="https://www.microsoft.com/en-us/security/business/identity-access-management/single-sign-on"
              />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={<img alt="AzureImg" src={AzureImg} style={{ height: 180 }} />}
              onClick={() => this.setState({ showIDPAzureModal: true })}
            >
              <Card.Meta
                style={{ wordBreak: 'break-all' }}
                title="Azure AD"
                description="https://azure.microsoft.com/en-us/solutions/active-directory-sso"
              />
            </Card>
            {isAdmin && (
              <Card
                hoverable
                style={{ width: 180, margin: 8 }}
                cover={<img alt="TeamsImg" src={TeamsImg} style={{ height: 180, padding: 8 }} />}
                onClick={() => this.setState({ showIDPMSTeamsModal: true })}
              >
                <Card.Meta
                  style={{ wordBreak: 'break-all' }}
                  title="Microsoft Teams"
                  description="https://www.microsoft.com/en-us/microsoft-teams/group-chat-software"
                />
              </Card>
            )}
            {isAdmin && (
              <Card
                hoverable
                style={{ width: 180, margin: 8 }}
                cover={<img alt="SlackImg" src={SlackImg} style={{ height: 180, padding: 8 }} />}
                onClick={() => this.setState({ showIDPSlackModal: true })}
              >
                <Card.Meta style={{ wordBreak: 'break-all' }} title="Slack" description="https://slack.com" />
              </Card>
            )}
          </Container>
        </Container>

        {this.state.showIDPGoogleModal && (
          <IDPGoogleModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showIDPGoogleModal: false });
            }}
          />
        )}
        {this.state.showIDPLDAPSModal && (
          <IDPLDAPSModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showIDPLDAPSModal: false });
            }}
          />
        )}
        {this.state.showIDPMSModal && (
          <IDPMSModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showIDPMSModal: false });
            }}
          />
        )}
        {this.state.showIDPAzureModal && (
          <IDPAzureModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showIDPAzureModal: false });
            }}
          />
        )}
        {this.state.showIDPMSTeamsModal && (
          <IDPMSTeamsModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showIDPMSTeamsModal: false });
            }}
          />
        )}
        {this.state.showIDPSlackModal && (
          <IDPSlackModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showIDPSlackModal: false });
            }}
          />
        )}
      </Container>
    );
  }
}

const IdentityProvidersSetting = injectIntl(IdentityProvidersSettingCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { isAdmin } = state.auth.userInfo;

    return { location, isAdmin };
  },
  { replace, hideAppLoader },
)(IdentityProvidersSetting);
