/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getLogAnomalySequenceInfoList } from '../../apis';
import { appMessages } from '../../app/messages';

const loadLogAnomalySequenceInfoLogic = createLogic({
  type: [ActionTypes.LOAD_LOG_ANOMALY_SEQUENCEINFO_LIST],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { projectName, instanceName, day } = params;

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getLogAnomalySequenceInfoList(credentials, { projectName, day, instanceName })
      .then((d) => {
        const { anomalySequenceInfoList } = d;
        dispatch(createSetAction(ActionTypes.SET_LOG_ANOMALY_SEQUENCEINFO_LIST, params, { anomalySequenceInfoList }));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get cluster info list', err);
        dispatch(
          createSetAction(ActionTypes.SET_LOG_ANOMALY_SEQUENCEINFO_LIST, params, { anomalySequenceInfoList: null }),
        );
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadLogAnomalySequenceInfoLogic;
