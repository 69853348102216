import * as R from 'ramda';

const getInstanceDisplayName = (instanceDisplayNameMap = {}, instance = '', info = {}) => {
  let instanceName;
  let containerName;
  const { pn, owner } = info;
  const isContainer = R.includes('_', instance || '');

  if (isContainer) {
    const [c, i] = R.split('_', instance || '');
    containerName = c;
    instanceName = i;
  } else {
    instanceName = instance || '';
  }

  let key = '';
  if (pn && owner) key = `${pn}-${owner}-`;
  key += instanceName;

  let instanceDisplayName = instanceDisplayNameMap[key];

  if (pn && owner && !instanceDisplayName) {
    instanceDisplayName = instanceDisplayNameMap[instanceName];
  }

  return {
    instanceDisplayName,
    instanceName,
    containerName,
    instanceStr: `${isContainer ? `${containerName}_` : ''}${instanceDisplayName || instanceName}`,
  };
};

export default getInstanceDisplayName;
