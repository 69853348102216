/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { REHYDRATE } from 'redux-persist/constants';
import { createLogic } from 'redux-logic';
import { get } from 'lodash';

import { isValidCredentials } from '../../auth';
import { PermissionError, NoAuthError } from '../../errors';
import { loginFailure, loginSuccess, sessionInvalid } from '../../auth/actions';
import { setInitData, appError, ActionTypes, showAppAlert, updateLastActionInfo } from '../actions';
import { appMessages } from '../messages';
import { getInitData, getDisplayedMenus } from '../../apis';
import { Defaults } from '../../utils';

const appStartLogic = createLogic({
  type: [ActionTypes.APP_START, REHYDRATE, ActionTypes.UPDATAPROJECT],
  cancelType: ActionTypes.APP_STOP,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { starting, rehydrated, started } = state.app;
    sessionStorage.removeItem('dashboardCustomerName');

    // The local storage REHYDRATE and APP_START might happens in random order, uses
    // flag states to make sure state rehydrated before starting app.
    let canStart = false;
    if (action.type === REHYDRATE) {
      dispatch({ type: ActionTypes.APP_REHYDRATED });
      if (starting) {
        canStart = true;
      }
    } else if (action.type === ActionTypes.APP_START) {
      if (rehydrated && !started) {
        canStart = true;
      }
    } else if (action.type === ActionTypes.UPDATAPROJECT) {
      canStart = true;
    }

    if (canStart) {
      const { credentials, userInfo } = state.auth;
      const valid = isValidCredentials(credentials, userInfo);

      // If token not exists, dispatch login failure to redirect to login page.
      if (!valid) {
        dispatch(loginFailure());
        dispatch({ type: ActionTypes.APP_STARTED });
        done();
      } else {
        dispatch(updateLastActionInfo());
        getInitData(credentials, userInfo)
          .then(async (data) => {
            const possibleMenu = await getDisplayedMenus(credentials);
            const displayedMenus = get(possibleMenu, [credentials.userName], Defaults.DisplayedMenus);
            dispatch(setInitData(data));
            dispatch(loginSuccess(credentials, { ...userInfo, displayedMenus }));
            if (data.licenseValid === false) {
              dispatch(showAppAlert('error', appMessages.licenseValidFaild));
            }
          })
          .catch((err) => {
            if (err instanceof PermissionError || err instanceof NoAuthError) {
              dispatch(sessionInvalid(err));
            } else {
              dispatch(appError(appMessages.errorsServer, err));
            }
          })
          .then(() => {
            dispatch({ type: ActionTypes.APP_STARTED });
            done();
          });
      }
    } else {
      done();
    }
  },
});

export default appStartLogic;
