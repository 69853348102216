/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getEventMetricMap = (credentials: Credentials, params: Object) => {
  const {
    relationKey,
    customerName,
    operation,
    intraInstanceName,
    relationType,
    srcInstance,
    targetInstance,
    interval,
  } = params;
  return fetchGet(getEndpoint('eventsrelationmetricmap'), {
    ...credentials,
    relationKey,
    customerName,
    operation,
    intraInstanceName,
    relationType,
    srcInstance,
    targetInstance,
    interval,
  }).then(d => {
    const rawData = d;
    const data = d.data || [];
    return {
      rawData,
      data,
    };
  });
};

export default getEventMetricMap;
