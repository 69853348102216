import React from 'react';
import * as R from 'ramda';
import { round, get } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { CaretDownOutlined, CaretUpOutlined, DownOutlined } from '@ant-design/icons';
import { Menu, Button } from 'antd';

import { Defaults } from '../../../common/app';
import { numberHumanize, GlobalParse } from '../../../common/utils';
import {
  Tooltip,
  Table,
  Column,
  CellMeasurer,
  CellMeasurerCache,
  Scrollbars,
  SortDirection,
  Dropdown,
} from '../../../lib/fui/react';
import { logMessages } from '../../../common/log/messages';
import EventJobModal from './EventJobModal';
import JobStagesModal from './JobStagesModal';
import EventShortListModal from '../../dashboard/components/EventShortListModal';

type Props = {
  intl: Object,
  width: Number,
  height: Number,
  jobAnalysis: Array<Object>,
  etlName: String,
};

class JobAnalysisTable extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.barHeight = 88;

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 110,
    });
    this.jobAnalysis = [];
    this.state = {
      showEventModal: false,
      selectedItemId: null,
      showStagesModal: false,

      showSystemEventModel: false,
      customerName: null,
      environmentId: null,
      systemId: null,
      intervalInMinutes: 1,
      systemTimestamp: null,
      startTime: null,
      endTime: null,
      finishedTime: null,
      startedTime: null,

      sortBy: null,
      sortDirection: null,
    };

    this.getContentInfo = (jobs, name, { isNano }) => {
      return (
        R.sum(
          R.map((j) => {
            // const accumulatorUpdates = get(j, 'accumulatorUpdates', []);
            // const item = R.find((a) => a.name === `internal.metrics.${name}`, accumulatorUpdates);
            // const number = item ? (isNano ? Number(item.value) / 1000 : Number(item.value)) : 0;
            let number = get(j, [name], 0);
            number = isNano ? number / 1000 : number;
            return number;
          }, jobs),
        ) / jobs.length
      );
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.jobAnalysis !== this.props.jobAnalysis) {
      this.reloadData(nextProps);
    }
    if (nextProps.width !== this.props.width || nextProps.jobAnalysis !== this.props.jobAnalysis) {
      this.cellMeasureCache.clearAll();
      if (this.dataTable) {
        this.dataTable.forceUpdate();
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;

      if (sortBy) {
        this.jobAnalysis = R.sortWith([R.ascend(R.prop(sortBy))])(this.jobAnalysis);
        if (sortDirection === SortDirection.DESC) {
          this.jobAnalysis = R.sortWith([R.descend(R.prop(sortBy))])(this.jobAnalysis);
        }
      }
    }
  }

  reloadData(props) {
    let { jobAnalysis } = props;
    jobAnalysis = R.sort((a, b) => b.startTime - a.startTime, jobAnalysis || []);
    this.jobAnalysis = R.clone(jobAnalysis || []);
  }

  @autobind
  timeRenderer({ cellData }) {
    const time = cellData ? moment.utc(cellData).format(Defaults.ShortTimeFormat) : '';
    return <div>{time}</div>;
  }

  @autobind
  timeDurationRenderer({ cellData }) {
    const duration = this.handleDurationTime(cellData);
    return <div className="text-right">{duration}</div>;
  }

  @autobind
  handleDurationTime(timestamp) {
    let duration = '';
    if (timestamp) {
      if (Number(timestamp) < 60 * 1000) {
        duration = `${round(Number(timestamp) / 1000)} sec`;
      } else if (Number(timestamp) < 60 * 1000 * 60) {
        duration = `${round(Number(timestamp) / 1000 / 60)} min`;
      } else {
        duration = moment.duration(Number(timestamp)).humanize();
      }
    } else {
      duration = 'NA';
    }
    return duration;
  }

  @autobind
  mapReduceContentRenderer(props) {
    const { width } = this.props;
    const { style, rowData } = props;
    const { mapsTotal, mapsCompleted, successfulMapAttempts, failedMapAttempts, killedMapAttempts } = rowData;

    return (
      <Scrollbars style={{ ...style, height: this.barHeight, width: width - 960 }}>
        <div>
          <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>mapsTotal:</span>
          <span style={{ display: 'inline-block', width: 40, textAlign: 'right' }}>{mapsTotal}</span>
        </div>
        <div>
          <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>mapsCompleted:</span>
          <span style={{ display: 'inline-block', width: 40, textAlign: 'right' }}>{mapsCompleted}</span>
        </div>
        <div>
          <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>mapsCompleted:</span>
          <span style={{ display: 'inline-block', width: 40, textAlign: 'right' }}>{successfulMapAttempts}</span>
        </div>
        <div>
          <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>failedMapAttempts:</span>
          <span style={{ display: 'inline-block', width: 40, textAlign: 'right' }}>{failedMapAttempts}</span>
        </div>
        <div>
          <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>killedMapAttempts:</span>
          <span style={{ display: 'inline-block', width: 40, textAlign: 'right' }}>{killedMapAttempts}</span>
        </div>
      </Scrollbars>
    );
  }

  @autobind
  sparkContentRenderer(props) {
    const { width } = this.props;
    const { style, rowData } = props;
    const jobs = get(rowData, 'stages', []);
    const numCompleteTasks = this.getContentInfo(jobs, 'numCompleteTasks', { isNano: false });
    const numFailedTasks = this.getContentInfo(jobs, 'numFailedTasks', { isNano: false });
    const inputBytes = this.getContentInfo(jobs, 'inputBytes', { isNano: false });
    const outputBytes = this.getContentInfo(jobs, 'outputBytes', { isNano: false });

    return (
      <Scrollbars style={{ ...style, height: this.barHeight, width: width - 960 }}>
        <div>
          <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>Avg numCompleteTasks:</span>
          <span style={{ display: 'inline-block', width: 30, textAlign: 'right' }}>{numCompleteTasks}</span>
        </div>
        <div>
          <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>Avg numFailedTasks:</span>
          <span style={{ display: 'inline-block', width: 40, textAlign: 'right' }}>{numFailedTasks}</span>
        </div>
        <div>
          <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>Avg inputBytes:</span>
          <span style={{ display: 'inline-block', width: 40, textAlign: 'right' }}>{inputBytes}</span>
        </div>
        <div>
          <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>Avg outputBytes:</span>
          <span style={{ display: 'inline-block', width: 40, textAlign: 'right' }}>{outputBytes}</span>
        </div>
      </Scrollbars>
    );
  }

  @autobind
  performanceMetricRenderer(props) {
    const { rowData, parent, dataKey, rowIndex, style } = props;
    const { type, executionTime, avgMapTime, avgShuffleTime } = rowData;

    let content = null;
    let points = [];
    if (type === 'MapReduce') {
      const ratioMapTime = avgMapTime / executionTime;
      const ratioShuffleTime = avgShuffleTime / executionTime;
      points = [
        {
          label: 'MapTime',
          value: this.handleDurationTime(avgMapTime),
          percent: ratioMapTime,
        },
        {
          label: 'ShuffleTime',
          value: this.handleDurationTime(avgShuffleTime),
          percent: ratioShuffleTime,
        },
      ];
    } else if (type === 'Spark') {
      const jobs = get(rowData, 'stages', []);
      const executorRunTime = this.getContentInfo(jobs, 'executorRunTime', { isNano: false });
      const ratioExecutorTime = executorRunTime / 10000;
      points = [
        {
          label: 'Avg executorRunTime',
          value: this.handleDurationTime(executorRunTime),
          percent: ratioExecutorTime,
        },
      ];
    } else if (type === 'Jenkins') {
      points = [
        {
          label: 'Test_Count',
          value: get(rowData, 'Test_Count', 'NA'),
        },
        {
          label: 'Failure',
          value: get(rowData, 'Failure', 'NA'),
        },
        {
          label: 'Failure_Count',
          value: get(rowData, 'Failure_Count', 'NA'),
        },
        {
          label: 'Details',
          value: get(rowData, 'Details', 'NA'),
        },
      ];
    }

    if (type === 'MapReduce' || type === 'Spark') {
      content = (
        <div className="flex-col" style={{ justifyContent: 'center', padding: '6px 0' }}>
          {R.addIndex(R.map)((p, index) => {
            return (
              <div key={index} style={{ margin: '4px 0 ' }}>
                <div style={{ margin: '0 0' }}>
                  {p.label}: {p.value}
                </div>
                <Tooltip
                  title={
                    <div className="flex-col">
                      <div>
                        {p.label}:{p.value}
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      width: '100%',
                      height: 10,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                        height: 10,
                        background: 'lightgray',
                      }}
                    >
                      <div
                        style={{
                          width: `${p.percent * 100}%`,
                          height: '100%',
                          padding: 0,
                          background: '#7cb7ff',
                        }}
                      />
                    </div>
                  </div>
                </Tooltip>
              </div>
            );
          }, points)}
        </div>
      );
    } else if (type === 'Jenkins') {
      content = (
        <div style={{ ...style, width: '100%', padding: '6px 0' }}>
          {R.addIndex(R.map)((p, idx) => {
            return (
              <div>
                {/* <span style={{ minWidth: 120, color: 'green', display: 'inline-block' }}>{p.label}:</span> */}
                <Tooltip
                  title={
                    <div className="flex-col">
                      <div>
                        {p.label}:{p.value}
                      </div>
                    </div>
                  }
                  placement="top"
                >
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      paddingRight: 6,
                      width: 120,
                      color: 'green',
                      display: 'inline-block',
                    }}
                  >
                    {p.label}:
                  </div>
                </Tooltip>
                <span style={{ display: 'inline-block', width: 40, textAlign: 'right' }}>{p.value}</span>
              </div>
            );
          }, points)}
        </div>
      );
    }

    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        {content}
      </CellMeasurer>
    );
  }

  @autobind
  jobSizeRenderer(props) {
    const { rowData } = props;
    const jobs = get(rowData, 'stages', []);
    return <div>{numberHumanize(this.getContentInfo(jobs, 'resultSize', { isNano: false, isDuration: false }))}</div>;
  }

  @autobind
  handleAnomaliesClick(incident) {
    const { customerName, environment, systemId, finishedTime, startedTime } = incident;
    let { startTime, finishTime } = incident;
    const environmentId = GlobalParse.getInstanceGroupShort(environment);
    if (finishedTime && startedTime) {
      startTime = startedTime;
      finishTime = finishedTime;
    }
    const intervalInMinutes = Math.ceil((finishTime - startTime) / 60 / 1000) || 5;
    startTime = moment.utc(startTime).startOf('minute').valueOf();
    finishTime = moment.utc(finishTime).endOf('minute').valueOf();
    this.setState({
      customerName: customerName || 'user',
      environmentId,
      systemId,
      intervalInMinutes,
      showSystemEventModel: true,
      systemTimestamp: Number(startTime),
      startTime,
      endTime: finishTime,
    });
  }

  @autobind
  handleEventClick(incident) {
    const { event } = incident;
    this.setState({ activeIncident: event, showEventModal: true });
  }

  @autobind
  handleStagesClick(incident) {
    const { stages } = incident;
    this.setState({ activeStages: stages, showStagesModal: true });
  }

  @autobind
  actionsRenderer({ rowData }) {
    const { intl } = this.props;
    const { type, event } = rowData;

    const handleMenuClick = ({ key }) => {
      switch (key) {
        case 'anomalies':
          this.handleAnomaliesClick(rowData);
          break;
        case 'event':
          this.handleEventClick(rowData);
          break;
        case 'stagesInfo':
          this.handleStagesClick(rowData);
          break;
        default:
          break;
      }
    };
    return (
      <Dropdown name={intl.formatMessage(logMessages.investigate)} itemClick={handleMenuClick}>
        <>
          <Menu.Item key="anomalies">Anomalies</Menu.Item>
          {event && event.length > 0 && <Menu.Item key="event">Event</Menu.Item>}
          {type === 'Spark' && <Menu.Item key="stagesInfo">Stages Info</Menu.Item>}
        </>
      </Dropdown>
    );
  }

  @autobind
  recommendationsRenderer(props) {
    const { cellData } = props;
    const { width } = this.props;
    const rc = cellData || [];
    return (
      <Scrollbars
        style={{
          height: '100%',
          // width: 260,
        }}
      >
        {R.addIndex(R.map)(
          (r, idx) => (
            <div style={{ whiteSpace: 'normal', wordBreak: 'break-all' }} key={idx}>
              {r}
            </div>
          ),
          rc,
        )}
      </Scrollbars>
    );
  }

  @autobind
  handleActionClick(incident) {
    return (e) => {
      e.stopPropagation();
    };
  }

  @autobind
  controlRenderer({ rowData }) {
    return (
      <div>
        <div className="ui orange mini button" onClick={this.handleActionClick(rowData)}>
          Action
        </div>
      </div>
    );
  }

  @autobind
  render() {
    const { width, height, etlName } = this.props;
    const { selectedItemId, sortBy, sortDirection } = this.state;

    let { jobAnalysis } = this;
    jobAnalysis = R.filter((j) => j.type === etlName, jobAnalysis || []);

    const sort = ({ sortBy, sortDirection }) => {
      const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
      this.setState({ sortBy, sortDirection });
    };
    const headerRenderer = ({ dataKey, disableSort, label, sortBy, sortDirection }) => {
      const sortIcon = () => {
        if (sortBy !== dataKey) {
          return null;
        }
        if (sortDirection === 'ASC') {
          return <CaretUpOutlined />;
        }
        return <CaretDownOutlined />;
      };
      return (
        <div>
          {label}
          {!disableSort && sortIcon()}
        </div>
      );
    };

    const stateRender = ({ rowData }) => {
      const { etlName } = this.props;
      const { jobStatus, state, healthScore } = rowData;
      const { failedMapAttempts, killedMapAttempts } = rowData;

      const statusInfoMap = {
        Success: {
          color: '#42d12d',
          iconClass: 'smile',
        },
        Fail: {
          color: '#a50026',
          iconClass: 'frown',
        },
      };
      const showState = state === 'SUCCEEDED' && jobStatus === 'Success' ? 'Normal' : 'Abnormal';
      return (
        <Tooltip
          title={
            <div className="flex-col">
              <div style={{ marginBottom: 8 }}>State: {showState}</div>
              <div>Job Status: {state}</div>
              <div>Average Health Score: {healthScore}</div>
              {etlName === 'MapReduce' && <div>Failed Map Attempts: {failedMapAttempts}</div>}
              {etlName === 'MapReduce' && <div>Killed Map Attempts: {killedMapAttempts}</div>}
            </div>
          }
          placement="right"
        >
          <div style={{ width: 24 }}>
            <i
              className={`icon ${statusInfoMap[jobStatus].iconClass} outline`}
              style={{ color: statusInfoMap[jobStatus].color, fontSize: 20 }}
            />
          </div>
        </Tooltip>
      );
    };

    return (
      <div className="job-table">
        <Table
          className="with-border"
          width={width}
          height={height}
          deferredMeasurementCache={this.cellMeasureCache}
          headerHeight={50}
          rowHeight={this.cellMeasureCache.rowHeight}
          rowCount={jobAnalysis.length}
          rowGetter={({ index }) => jobAnalysis[index]}
          onRowClick={({ rowData }) => {
            this.setState({
              selectedItemId: rowData.id,
            });
          }}
          rowClassName={({ index }) => {
            let className = index >= 0 && index % 2 === 1 ? 'odd-row' : '';
            // Ignore header row.
            if (index >= 0) {
              const item = jobAnalysis[index];
              if (item.id === selectedItemId) {
                className += ' active';
              }
            }
            return className;
          }}
          ref={(c) => (this.dataTable = c)}
          sort={sort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        >
          <Column width={120} label="Systems" dataKey="systemId" headerRenderer={headerRenderer} />
          <Column width={110} label="Environment" dataKey="environment" headerRenderer={headerRenderer} />
          <Column width={100} label="Name" dataKey="name" headerRenderer={headerRenderer} />
          <Column
            width={80}
            label="Anomaly status"
            dataKey="jobStatus"
            headerRenderer={headerRenderer}
            cellRenderer={stateRender}
          />
          <Column
            width={100}
            label="Start time"
            dataKey="startTime"
            headerRenderer={headerRenderer}
            cellRenderer={this.timeRenderer}
          />
          <Column
            width={80}
            label="Duration time"
            dataKey="executionTime"
            headerRenderer={headerRenderer}
            cellRenderer={this.timeDurationRenderer}
          />
          <Column
            width={200}
            label="Performance metric"
            dataKey="id"
            disableSort
            cellRenderer={this.performanceMetricRenderer}
          />
          {etlName === 'MapReduce' && (
            <Column
              width={200}
              flexGrow={1}
              label="Job info"
              dataKey="id"
              disableSort
              cellRenderer={this.mapReduceContentRenderer}
              className="raw-data"
            />
          )}
          {etlName === 'Spark' && (
            <Column
              width={200}
              flexGrow={1}
              label="Job info"
              dataKey="id"
              disableSort
              cellRenderer={this.sparkContentRenderer}
              className="raw-data"
            />
          )}
          <Column width={120} label="" dataKey="id" disableSort cellRenderer={this.actionsRenderer} />
        </Table>

        {this.state.showSystemEventModel && (
          <EventShortListModal
            customerName={this.state.customerName}
            environmentId={this.state.environmentId}
            systemId={this.state.systemId}
            intervalInMinutes={this.state.intervalInMinutes}
            systemTimestamp={this.state.systemTimestamp}
            seriesId={null}
            startTime={this.state.startTime}
            endTime={this.state.endTime}
            onClose={() => this.setState({ showSystemEventModel: false })}
          />
        )}
        {this.state.showEventModal && (
          <EventJobModal
            project={null}
            projectName={null}
            instanceGroup={null}
            incident={this.state.activeIncident}
            onClose={() => this.setState({ showEventModal: false })}
          />
        )}
        {this.state.showStagesModal && (
          <JobStagesModal stages={this.state.activeStages} onClose={() => this.setState({ showStagesModal: false })} />
        )}
      </div>
    );
  }
}

export default injectIntl(JobAnalysisTable);
