/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { CausalParser } from '../../common/utils';
import parseCausalIncident from './parsers/parseCausalIncident';

const getMetricEventCausalResult = (credentials: Credentials, params: Object) => {
  const { causalRelationKey: relationKey, operation, customerName, ...rest } = params;

  return fetchGet(getEndpoint('eventsrelationprocessforevents'), {
    ...credentials,
    ...rest,
    customerName,
    relationKey,
    operation,
  }).then((d) => {
    const rawData = d;
    const data = d.data || {};

    const { kpiPrediction, kpi } = CausalParser.getKpiPrediction(data);
    const instanceMapping = JSON.parse(data.instanceMapping || '{}');
    const incident = parseCausalIncident(data, {}, kpiPrediction, instanceMapping, d.mapping, kpi);
    return {
      rawData,
      data: incident,
    };
  });
};
export default getMetricEventCausalResult;
