/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { message, Alert, Form, Input, Select, Spin } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { settingsButtonMessages } from '../../../common/settings/messages';
import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { parseJSON } from '../../../common/utils';

type Props = {
  activeIncident: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  userList: Array<Object>,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class AddTeamsWebhookModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { userInfo, systemsMap } = props;
    const activeIncident = props.activeIncident || {};
    let systemList = R.filter((system) => !system.isShared, R.values(systemsMap));
    systemList = R.sortWith([R.ascend(R.prop('systemName'))], systemList);

    this.state = {
      isSubmiting: false,
      errMsg: null,

      userName:
        activeIncident.userName || (!userInfo.isAdmin && !userInfo.isLocalAdmin ? userInfo.userName : undefined),
      systemId: activeIncident.systemId || null,
      webHook: activeIncident.webHook || '',
      // channelName: activeIncident.channelName || '',
      alertTypes: parseJSON(activeIncident.options) || [],
      setGetSystemListLoading: false,
    };
    this.systemList = systemList || [];
    this.anomalyOptions = [
      { value: 'New Pattern Alert', label: 'New Pattern Alert' },
      { value: 'Detected Incident', label: 'Detected Incident' },
      { value: 'Predicted Incident', label: 'Predicted Incident' },
    ];
  }

  componentDidMount() {
    const { userName } = this.state;
    if (userName) {
      this.changeUserNameGetSystemList(userName, true);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleUpdate() {
    const { intl, credentials, onClose, activeIncident } = this.props;
    const { systemId, webHook, alertTypes } = this.state;

    this.setState({ isSubmiting: true });
    const systemInfo = R.find((system) => system.systemId === systemId, this.systemList);
    const { owner } = systemInfo || {};
    const { account } = activeIncident;

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('update-ext-service'), {
      ...credentials,
      operation: 'Teams',
      account,
      UserName: owner,
      webHook,
      // channelName,
      options: JSON.stringify(alertTypes || []),
      systemKey: JSON.stringify({ userName: owner, systemName: systemId, envName: 'All' }),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmiting: false });
          onClose(true);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  @autobind
  handleSumbit() {
    const { intl, credentials, onClose } = this.props;
    const { account, webHook, alertTypes, systemId, userName } = this.state;

    this.setState({ isSubmiting: true });
    const systemInfo = R.find((system) => system.systemId === systemId, this.systemList);
    const { owner } = systemInfo || {};

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('service-integration'), {
      ...credentials,
      operation: 'Teams',
      account,
      webHook,
      // channelName,
      options: JSON.stringify(alertTypes || []),
      systemKey: JSON.stringify({ userName: owner, systemName: systemId, envName: 'All' }),
      customerName: userName,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmiting: false });
          onClose(true);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  @autobind
  changeUserNameGetSystemList(customerName, flag = false) {
    const { credentials, userInfo } = this.props;
    if (userInfo.isAdmin || userInfo.isLocalAdmin) {
      this.setState({ setGetSystemListLoading: true });
      fetchGet(getEndpoint('systemframework', 2), {
        ...credentials,
        customerName,
      })
        .then((data) => {
          let { ownSystemArr } = data;
          ownSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system };
          }, ownSystemArr || []);
          const systemsMap = {};
          R.forEach(
            (system) => {
              const { systemDisplayName, systemKey, projectDetailsList, isShared, ...rest } = system;
              const { environmentName, systemName: systemId, userName } = systemKey || {};
              const newProjects = parseJSON(projectDetailsList) || [];
              systemsMap[systemId] = {
                ...rest,
                isShared,
                environmentName,
                systemId,
                systemName: systemDisplayName || systemId,
                owner: userName,
                projectDetailsList: newProjects,
              };
            },
            [...ownSystemArr],
          );
          let systemList = R.values(systemsMap);
          systemList = R.sortWith([R.ascend(R.prop('systemName'))], systemList);
          this.systemList = systemList;
          this.setState({ setGetSystemListLoading: false });
        })
        .catch((e) => {
          this.setState({ setGetSystemListLoading: false });
        });
    }

    if (flag) {
      this.setState({ userName: customerName });
    } else {
      this.setState({ userName: customerName, systemId: null });
    }
  }

  render() {
    const { intl, onClose, userInfo, userList, activeIncident } = this.props;
    const { isSubmiting, errMsg, userName, systemId, webHook, alertTypes, setGetSystemListLoading } = this.state;
    const systemList = R.filter((item) => item.owner === userName, this.systemList);

    const hasError = !userName || !systemId || !webHook || alertTypes.length < 1;
    return (
      <Modal
        width={650}
        title={intl.formatMessage(settingsButtonMessages.addWebHook)}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={activeIncident ? this.handleUpdate : this.handleSumbit}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
        bodyStyle={{ maxHeight: 500, overflowY: 'auto' }}
      >
        <Spin spinning={setGetSystemListLoading} wrapperClassName="full-height spin-full-height">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
            {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
              <Form.Item
                label={intl.formatMessage(appFieldsMessages.userName)}
                validateStatus={!userName ? 'error' : 'success'}
                help={!userName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <Select
                  disabled={Boolean(activeIncident)}
                  allowClear={false}
                  showSearch
                  filterOption
                  value={userName}
                  onChange={this.changeUserNameGetSystemList}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.systemName)}
              validateStatus={!systemId ? 'error' : 'success'}
              help={!systemId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={systemId}
                onChange={(systemId) => this.setState({ systemId })}
              >
                {R.map(
                  (system) => (
                    <Select.Option key={system.systemId}>{system.systemName}</Select.Option>
                  ),
                  systemList,
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Webhook"
              validateStatus={!webHook ? 'error' : 'success'}
              help={!webHook ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={webHook} onChange={(e) => this.setState({ webHook: e.target.value })} />
            </Form.Item>
            {/* <Form.Item label="Channel Name">
            <Input value={channelName} onChange={(e) => this.setState({ channelName: e.target.value })} />
          </Form.Item> */}
            <Form.Item
              label="Alert types"
              validateStatus={alertTypes.length < 1 ? 'error' : 'success'}
              help={alertTypes.length < 1 ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                mode="tags"
                filterOption
                options={this.anomalyOptions}
                value={alertTypes}
                onChange={(options) => this.setState({ alertTypes: options })}
              />
            </Form.Item>

            {errMsg && (
              <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 8 },
                }}
              >
                <Alert message={errMsg} type="error" showIcon />
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const AddTeamsWebhookModal = injectIntl(AddTeamsWebhookModalCore);
export default connect(
  (state) => {
    const { systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return { systemsMap, userInfo, credentials, userList };
  },
  { updateLastActionInfo },
)(AddTeamsWebhookModal);
