import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Form, message, DatePicker, Input } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { GlobalRenderers } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages } from '../../../common/metric/messages';

type Props = {
  triageReportIncident: Object,
  triageReportList: Array<Object>,
  activeEvent: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  timezoneOffset: Number,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
  tabName: String,
  handleReader: Function,
  localServiceNowList: Array,
};

class NewTriageReportModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { triageReportIncident, activeEvent } = props;
    const { startTimestamp } = triageReportIncident;
    let timeObj = startTimestamp ? moment.utc(startTimestamp) : moment.utc();
    let fieldName = '';
    let report = '';
    if (activeEvent) {
      timeObj = moment.utc(get(activeEvent, 'timestamp'));
      fieldName = get(activeEvent, 'fieldName', '');
      report = get(activeEvent, 'report', '');
    }

    this.state = {
      isSubmitting: false,

      timeObj,
      fieldName,
      report,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  handleConfirmClick() {
    const { intl, onClose, credentials, triageReportIncident, triageReportList, activeEvent } = this.props;
    const { localServiceNowList } = this.props;
    const { timeObj, fieldName, report } = this.state;
    const { projectName, projectOwner, anomalyLogInstance, instanceList, patternId } = triageReportIncident;

    this.setState({ isSubmitting: true });

    // update or create triageReport with same instance
    let instanceName;
    let newReportList = [];
    if (activeEvent) {
      instanceName = activeEvent.instanceName || '';
      newReportList = R.filter((item) => item.instanceName === instanceName, triageReportList);
      const rowIndex = R.findIndex((item) => item.key === activeEvent.key)(newReportList);
      const rowData = newReportList[rowIndex];
      newReportList = update(newReportList, {
        [rowIndex]: { $set: { ...rowData, timestamp: timeObj.valueOf(), fieldName, report, haveChange: true } },
      });
    } else {
      instanceName = anomalyLogInstance || instanceList[0];
      newReportList = R.filter((item) => item.instanceName === instanceName, triageReportList);
      newReportList.push({
        timestamp: timeObj.valueOf(),
        fieldName,
        report,
        newCreated: true,
      });
    }
    newReportList = R.map((item) => {
      const { timestamp, fieldName, report, triageReporterManagement, haveChange, newCreated } = item;
      return { timestamp, fieldName, report, triageReporterManagement, haveChange, newCreated };
    }, newReportList);
    newReportList = JSON.stringify([...newReportList, ...(localServiceNowList || [])]);

    this.props.updateLastActionInfo();
    fetchPost(
      getEndpoint('logpatterntriage', 1),
      {
        ...credentials,
        customerName: projectOwner,
        projectName,
        patternId,
        instanceName,
        triageReportList: newReportList,
      },
      {},
      false,
    )
      .then((d) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmitting: false });
        onClose(true);
        if (typeof handleReader === 'function') {
          this.props.handleReader();
        }
      })
      .catch((err) => {
        // message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
        console.log(String(err));
      });
  }

  render() {
    const { intl, activeEvent, onClose, timezoneOffset, tabName } = this.props;
    const { isSubmitting, timeObj, fieldName, report } = this.state;

    const hasError = !timeObj || !fieldName || !report;

    let tabNameStr = 'Incident';
    // eslint-disable-next-line default-case
    switch (tabName) {
      case 'incident':
        tabNameStr = 'Incident';
        break;
      case 'metric':
        tabNameStr = 'Metric anomaly';
        break;
      case 'log':
        tabNameStr = 'Log anomaly';
        break;
      case 'trace':
        tabNameStr = 'Trace event';
        break;
      case 'deployment':
        tabNameStr = 'Change event';
        break;
    }
    return (
      <Modal
        visible
        width={650}
        title={activeEvent ? 'Edit summary notes' : 'Add summary notes'}
        onOk={this.handleConfirmClick}
        onCancel={() => onClose()}
        okButtonProps={{ disabled: hasError, loading: isSubmitting }}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item label={`${tabNameStr} ${intl.formatMessage(eventActionMessages.occurrenceTime)}`} required>
            <DatePicker showTime value={timeObj} onChange={(timeObj) => this.setState({ timeObj })} />
          </Form.Item>
          {activeEvent && (
            <Form.Item label={intl.formatMessage(eventActionMessages.reporter)} required>
              <div className="flex-row flex-wrap">
                <GlobalRenderers.RenderReporter
                  intl={intl}
                  timezoneOffset={timezoneOffset}
                  reporterRecordSet={activeEvent.reporterRecordSet || []}
                />
              </div>
            </Form.Item>
          )}
          <Form.Item label={intl.formatMessage(appFieldsMessages.title)} required>
            <Input value={fieldName} onChange={(e) => this.setState({ fieldName: e.target.value })} />
          </Form.Item>
          <Form.Item label={intl.formatMessage(eventActionMessages.triageReport)} required>
            <Input.TextArea rows={4} value={report} onChange={(e) => this.setState({ report: e.target.value })} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const NewTriageReportModal = injectIntl(NewTriageReportModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      timezoneOffset,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(NewTriageReportModal);
