/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { get, isString } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { CheckCircleOutlined, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Alert, Form, Input, Button, Spin, Select, Divider, Space, InputRef } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { settingsButtonMessages } from '../../../common/settings/messages';
import { appButtonsMessages, appMessages } from '../../../common/app/messages';

type Props = {
  activeIncident: Object,
  companies: Array,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  isAdmin: boolean,
};

class AddIDPMSModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { activeIncident } = props;
    this.inputRef = null;

    this.state = {
      isLoading: false,
      isSubmiting: false,
      errMsg: null,
      isVerify: false,
      verified: undefined,
      groupName: '',

      idpName: get(activeIncident, 'idpName', ''),
      clientId: get(activeIncident, 'clientId', ''),
      groupList: get(activeIncident, 'groupList', []),
      domainName: get(activeIncident, 'domainName', ''),
      serverAddress: get(activeIncident, 'serverAddress', ''),
      bindUserID: get(activeIncident, 'bindUserID', ''),
      clientSecret: get(activeIncident, 'clientSecret', ''),
      company: get(activeIncident, 'company', ''),
      emailDomain: get(activeIncident, 'emailDomain', ''),
    };
    this.roleOptions = [];
    this.groupOptions = R.map((i) => i.groupName, this.state.groupList || []);
    this.systemOptions = [];
    this.systemNameMap = {};
    this.ruleStrMap = {
      ReadOnlyUser: 'ReadOnlyUser',
      LocalAdmin: 'Administrator',
      NormalUser: 'NormalUser',
    };
    this.sortByMap = {
      ReadOnlyUser: 0,
      NormalUser: 1,
      LocalAdmin: 2,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  async reloadData(props) {
    const { intl, credentials } = props;

    this.setState({ isLoading: true });
    props.updateLastActionInfo();
    await fetchGet(getEndpoint('userroleconfig'), {
      ...credentials,
    })
      .then((data) => {
        let roleList = data.updateRoles || [];
        roleList = R.filter((item) => item.roleName !== 'Admin', roleList);
        roleList = R.map((item) => ({ ...item, order: this.sortByMap[item.roleName] }), roleList);
        this.roleOptions = R.sortWith([R.ascend(R.prop('order'))], roleList);
      })
      .catch((err) => {
        this.roleOptions = [];
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false });
      });

    await fetchGet(getEndpoint('idpSettingVerify'), {
      ...credentials,
    })
      .then((data) => {
        const options = [];
        R.forEach((i) => {
          options.push(i);
          this.systemNameMap[i.systemKey.systemName] = i.systemKey;
        }, data?.systems || []);
        this.systemOptions = options;
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.systemOptions = [];
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false });
      });
  }

  @autobind
  handleSumbit() {
    const { intl, credentials, onClose } = this.props;
    const { idpName, clientId, groupList, domainName, serverAddress, bindUserID, clientSecret, company, emailDomain } =
      this.state;
    const settings = [
      {
        key: {
          identityProviderType: 'MS',
          clientId,
        },
        idpName,
        groupListString: JSON.stringify(groupList),
        domainName,
        serverAddress,
        bindUserID,
        clientSecret,
        company,
        emailDomain,
      },
    ];

    this.setState({ isSubmiting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('idpsetting'), {
      ...credentials,
      identityProviderType: 'MS',
      settings: JSON.stringify(settings),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmiting: false });
          onClose(true);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  @autobind
  handleVerify() {
    const { intl, credentials } = this.props;
    const { idpName, clientId, domainName, serverAddress, bindUserID, clientSecret, emailDomain } = this.state;
    const settings = [
      {
        key: {
          identityProviderType: 'MS',
          clientId,
        },
        idpName,
        groupListString: JSON.stringify([]),
        domainName,
        serverAddress,
        bindUserID,
        clientSecret,
        emailDomain,
      },
    ];

    this.setState({ isVerify: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('idpSettingVerify'), {
      ...credentials,
      identityProviderType: 'MS',
      settings: JSON.stringify(settings),
    })
      .then((data) => {
        const { success, groupList } = data || {};
        if (success) {
          if (groupList) {
            const options = [...this.groupOptions, ...(isString(groupList) ? JSON.parse(groupList) : groupList)];
            this.groupOptions = options;
          }
          this.setState({ isVerify: false, verified: true });
        } else {
          this.setState({ isVerify: false, verified: false });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isVerify: false, verified: false });
      });
  }

  @autobind
  handleAddGroupClick() {
    const { groupList } = this.state;
    this.setState(
      {
        groupList: [...(groupList || []), { groupName: '', roleName: '', systemKeys: [] }],
        verified: undefined,
      },
      () => {
        if (this.listNode) {
          this.listNode.scrollTop = this.listNode.scrollHeight;
        }
      },
    );
  }

  @autobind
  handleRemoveGroupClick(index) {
    const { groupList } = this.state;
    this.setState({
      groupList: R.remove(index, 1, groupList || []),
      verified: undefined,
    });
  }

  render() {
    const { intl, activeIncident, onClose, isAdmin, companies } = this.props;
    const {
      isSubmiting,
      errMsg,
      isVerify,
      verified,
      isLoading,
      idpName,
      clientId,
      groupList,
      domainName,
      serverAddress,
      bindUserID,
      clientSecret,
      groupName,
      company,
      emailDomain,
    } = this.state;

    const hasError =
      !idpName ||
      !clientId ||
      !(
        groupList.length > 0 &&
        R.reduce(
          R.and,
          true,
          R.map((item) => item.groupName && item.roleName, groupList),
        )
      ) ||
      !domainName ||
      !serverAddress ||
      (!company && isAdmin) ||
      (!emailDomain && isAdmin) ||
      !verified;

    return (
      <Modal
        width={850}
        title={activeIncident ? activeIncident.idpName : intl.formatMessage(settingsButtonMessages.addIdentityProvider)}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSumbit}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            {isAdmin && (
              <Form.Item
                label="Company"
                validateStatus={!company ? 'error' : 'success'}
                help={!company ? 'This input is required!' : undefined}
                required
              >
                <Select
                  showSearch
                  value={company}
                  onChange={(val) => {
                    this.setState({ company: val });
                  }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item} value={item} title={item}>
                        {item}
                      </Select.Option>
                    ),
                    companies || [],
                  )}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="Global External Service Provider Name"
              validateStatus={!idpName ? 'error' : 'success'}
              help={!idpName ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={idpName}
                onChange={(e) => this.setState({ idpName: e.target.value, verified: undefined })}
              />
            </Form.Item>
            <Form.Item
              label="Client Id"
              validateStatus={!clientId ? 'error' : 'success'}
              help={!clientId ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={clientId}
                onChange={(e) => this.setState({ clientId: e.target.value, verified: undefined })}
                disabled={Boolean(activeIncident)}
              />
            </Form.Item>
            <Form.Item
              label="Domain Name"
              validateStatus={!domainName ? 'error' : 'success'}
              help={!domainName ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={domainName}
                onChange={(e) => this.setState({ domainName: e.target.value, verified: undefined })}
              />
            </Form.Item>
            <Form.Item
              label="Server Address"
              validateStatus={!serverAddress ? 'error' : 'success'}
              help={!serverAddress ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={serverAddress}
                onChange={(e) => this.setState({ serverAddress: e.target.value, verified: undefined })}
              />
              {serverAddress && (
                <div style={{ color: 'var(--gray)', fontSize: 12 }}>
                  Please use “host:port” for LDAPS connection; Otherwise please use “host” for LDAP connection.
                </div>
              )}
            </Form.Item>
            <Form.Item label="Bind User Id">
              <Input
                id="bindUserID"
                name="bindUserID"
                value={bindUserID}
                onChange={(e) => this.setState({ bindUserID: e.target.value, verified: undefined })}
              />
            </Form.Item>
            <Form.Item label="Client Secret">
              <Input.Password
                id="clientSecret"
                name="clientSecret"
                value={clientSecret}
                onChange={(e) => this.setState({ clientSecret: e.target.value, verified: undefined })}
              />
            </Form.Item>
            {isAdmin && (
              <Form.Item
                label="Email Domain"
                validateStatus={!emailDomain ? 'error' : 'success'}
                help={!emailDomain ? 'This input is required!' : undefined}
                required
              >
                <Input
                  value={emailDomain}
                  onChange={(e) => this.setState({ emailDomain: e.target.value, verified: undefined })}
                />
              </Form.Item>
            )}
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 },
              }}
            >
              <div className="flex-row flex-center-align">
                <Button
                  size="small"
                  type="primary"
                  style={{ marginRight: 16 }}
                  loading={isVerify}
                  onClick={this.handleVerify}
                >
                  {intl.formatMessage(appButtonsMessages.verify)}
                </Button>
                {verified === true && <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 16 }} />}
                {verified === false && <CloseCircleOutlined style={{ color: '#f5222d', fontSize: 16 }} />}
              </div>
            </Form.Item>
            <Form.Item label="Group List" required>
              <div style={{ marginBottom: 8 }} className="flex-row flex-center-align">
                <Button size="small" type="primary" onClick={this.handleAddGroupClick}>
                  {intl.formatMessage(appButtonsMessages.add)}
                </Button>
              </div>
              <div className="event-list">
                <div className="event-list-header" style={{ height: 30 }}>
                  <div className="header-column" style={{ width: 160, flex: 1 }}>
                    Group name
                  </div>
                  <div className="header-column" style={{ width: 120 }}>
                    Role name
                  </div>
                  <div className="header-column" style={{ width: 160 }}>
                    System
                  </div>
                  <div className="header-column" style={{ width: 80 }} />
                </div>
                <div
                  className="event-list-grid overflow-y-auto"
                  style={{ maxHeight: 100 }}
                  ref={(el) => {
                    this.listNode = el;
                  }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                        style={{ minHeight: 30 }}
                      >
                        <div className="row-column" style={{ width: 160, flex: 1 }}>
                          <Select
                            showSearch
                            size="small"
                            optionFilterProp="children"
                            value={item.groupName}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                  <Input
                                    ref={(r) => (this.inputRef = r)}
                                    value={groupName}
                                    onChange={(evt) => this.setState({ groupName: evt.target.value })}
                                  />
                                  <Button
                                    type="text"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (groupName) {
                                        this.groupOptions = R.uniq([groupName, ...this.groupOptions]);
                                        this.setState({ groupName: '' });
                                      }
                                      setTimeout(() => {
                                        if (this.inputRef) {
                                          this.inputRef.current?.focus();
                                        }
                                      }, 0);
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Space>
                              </>
                            )}
                            onChange={(groupName) => {
                              const { groupList } = this.state;
                              this.setState({
                                groupList: update(groupList, {
                                  [index]: { $set: { ...(item || {}), groupName } },
                                }),
                              });
                            }}
                          >
                            {R.map((groupName) => {
                              return (
                                <Select.Option key={groupName} value={groupName}>
                                  {groupName}
                                </Select.Option>
                              );
                            }, this.groupOptions)}
                          </Select>
                        </div>
                        <div className="row-column" style={{ width: 120 }}>
                          <Select
                            size="small"
                            value={item.roleName}
                            dropdownMatchSelectWidth={false}
                            onChange={(roleName) => {
                              const { groupList } = this.state;
                              this.setState({
                                groupList: update(groupList, {
                                  [index]: { $set: { ...(item || {}), roleName } },
                                }),
                              });
                            }}
                          >
                            {R.map((option) => {
                              return (
                                <Select.Option key={option.roleName} value={option.roleName}>
                                  {this.ruleStrMap[option.roleName]}
                                </Select.Option>
                              );
                            }, this.roleOptions)}
                          </Select>
                        </div>
                        <div className="row-column" style={{ width: 160 }}>
                          <Select
                            size="small"
                            mode="multiple"
                            showSearch
                            optionFilterProp="children"
                            value={R.map((i) => i.systemName, item.systemKeys)}
                            onChange={(systemNames) => {
                              const systemKeys = R.map((i) => this.systemNameMap[i], systemNames || []);
                              const { groupList } = this.state;
                              this.setState({
                                groupList: update(groupList, {
                                  [index]: { $set: { ...(item || {}), systemKeys } },
                                }),
                              });
                            }}
                          >
                            {R.map((i) => {
                              return (
                                <Select.Option key={i.systemKey.systemName} value={i.systemKey.systemName}>
                                  {i.systemDisplayName}
                                </Select.Option>
                              );
                            }, this.systemOptions)}
                          </Select>
                        </div>
                        <div className="row-column" style={{ width: 80 }}>
                          <Button
                            size="small"
                            className="button-color-grey"
                            onClick={() => this.handleRemoveGroupClick(index)}
                          >
                            {intl.formatMessage(appButtonsMessages.remove)}
                          </Button>
                        </div>
                      </div>
                    );
                  }, groupList || [])}
                </div>
              </div>
            </Form.Item>

            {errMsg && (
              <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 8 },
                }}
              >
                <Alert message={errMsg} type="error" showIcon />
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const AddIDPMSModal = injectIntl(AddIDPMSModalCore);
export default connect(
  (state) => {
    const { userInfo, credentials } = state.auth;
    const { isAdmin } = state.auth.userInfo;
    return { userInfo, credentials, isAdmin };
  },
  { updateLastActionInfo },
)(AddIDPMSModal);
