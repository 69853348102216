/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { get, isFinite } from 'lodash';
import VLink from 'valuelink';
import moment from 'moment';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Select as AntdSelect, Tooltip, DatePicker as AntdDatePicker, message, Checkbox, Divider } from 'antd';

import momenttz from 'moment-timezone';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import RcDatePicker from 'react-datepicker';
import { Container, DatePicker, Input, Select } from '../../../lib/fui/react';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import {
  CamelCase,
  Defaults,
  FilterQueryList,
  ifIn,
  localDateToUtcObj,
  LogTypes,
  Params,
  utcObjToLocalDate,
} from '../../../common/utils';
import { queryFieldMessages, queryMessages } from '../../../common/query/messages';
import { DEFAULT_EXPAND_DURATION, getDurationMs, getExpandDurations } from '../../../common/utils/DurationUtils';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { TimeInput } from '../../share/TimeInput';
import fetchGet from '../../../common/apis/fetchGet';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  intl: Object,
  credentials: Object,

  projects: Array<Object>,
  templateList: Array<Object>,
  templateMap: Object,

  templateParams: Object,
  onQueryClear: Function,
  onQuerySubmit: Function,
  isParamsLoading: Boolean,
  isResultLoading: Boolean,
  onQueryCancel: Function,
  onProjectChange: Function,
  pn: String,
};

class QueryBoxCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      endTimeChanged: false,
      isLoadingInstance: false,
      duration: DEFAULT_EXPAND_DURATION,
      ...props.templateParams,
      ...this.normalizeParams(props),
    };

    this.forecastIntervalOptions = [
      { label: '3 hours', value: 3 * 60 * 60 * 1000 },
      { label: '12 hours', value: 12 * 60 * 60 * 1000 },
      { label: '1 day', value: 24 * 60 * 60 * 1000 },
      { label: '1 week', value: 168 * 60 * 60 * 1000 },
    ];
    this.modelTypeOptions = [
      { label: 'Holistic', value: 'Holistic' },
      { label: 'DBScan', value: 'DBScan' },
      { label: 'Threshold', value: 'Threshold' },
      { label: 'splitByEnv', value: 'splitByEnv' },
    ];
    this.similarityLevelOptions = [
      { label: 'High(95%)', value: 'high' },
      { label: 'MediumHigh(85%)', value: 'mediumHigh' },
      { label: 'Medium(75%)', value: 'medium' },
      { label: 'MediumLow(60%)', value: 'mediumLow' },
      { label: 'Low(50%)', value: 'low' },
    ];
    this.maximumPredictionDuration = 7;
    this.durationOptions = getExpandDurations(props.intl);
  }

  async componentDidMount() {
    const { pn, templateParams } = this.props;
    const { projectName } = templateParams;
    this.setInstanceOption(pn || projectName);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (!R.equals(nextProps.templateParams, this.props.templateParams)) {
      const newParams = this.normalizeParams(nextProps);
      this.setState({
        ...nextProps.templateParams,
        ...newParams,
      });
    }
    if (nextProps.pn !== this.props.pn) {
      this.setInstanceOption(nextProps.pn);
    }
  }

  @autobind
  async setInstanceOption(projectName) {
    // Reset the template parameters from props to state.
    const { projects } = this.props;
    const { instanceName } = this.state;
    const project = R.find((p) => p.projectName === projectName || p.projectShortName === projectName, projects) || {};
    let instanceList = [];
    if (project.isLog) {
      instanceList = R.map((x) => x.value, project.instanceInfoList || []);
    } else {
      instanceList = project.instanceList || [];
    }

    const { instanceGroupList, instanceDisplayNameMap } = await this.reloadInstanceData(
      projectName,
      instanceList,
      project,
    );

    const newInstanceName = [];
    R.forEach((item) => {
      const findI = R.find((i) => item === i.instanceName, instanceGroupList || []);
      if (findI) newInstanceName.push(item);
    }, instanceName || []);

    const allInstanceListOptions = R.map((item) => {
      const { instanceName, appName } = item;
      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName, {
        pn: project?.projectShortName,
        owner: project?.owner,
      });
      return { value: instanceName, label: `${instanceStr}${appName ? ` (${appName})` : ''}` };
    }, instanceGroupList || []);

    this.setState({
      isLoadingInstance: false,
      allInstanceListOptions,
      instanceName: newInstanceName,
    });
  }

  @autobind
  getLocalEndTime(props, state, endTimeObj) {
    const { projects } = props;
    const { projectName, endTimeChanged } = state;
    const currentProject = R.find((project) => project.projectName === projectName, projects);

    let timezoneOffset = 0;
    if (currentProject?.timezone) {
      const zone = momenttz.tz(currentProject?.timezone);
      timezoneOffset = zone.utcOffset();
    }
    const now = moment.utc(moment.utc().valueOf() + (timezoneOffset || 0) * 60000);
    if (endTimeObj) {
      // add back endtime check
      if (!endTimeChanged && endTimeObj.clone().startOf('day').diff(now, 'days') === 0) {
        return now;
      } else {
        return endTimeObj;
      }
    } else {
      return now;
    }
  }

  @autobind
  normalizeParams(props) {
    const { templateParams: params, projects } = props;
    const { projectName } = params;
    let { instanceName } = params;
    instanceName = JSON.parse(instanceName);
    const project = R.find((p) => p.projectName === projectName, projects) || {};
    let allInstanceListOptions = [];
    if (project.isLog) {
      allInstanceListOptions = project.instanceInfoList || [];
    } else {
      allInstanceListOptions = R.map((g) => ({ label: g, value: g }), project.instanceList || []);
    }

    return {
      timeObj: params?.timeObj || moment.utc(),
      interval: isFinite(params?.interval) ? params.interval / 60 / 1000 : 15,
      trainingWindow: isFinite(params?.trainingWindow) ? params.trainingWindow / 86400000 : 1,
      startTimeObj: params?.startTimeObj || moment.utc().startOf('day'),
      endTimeObj: this.getLocalEndTime(props, this.state || { projectName }, params?.endTimeObj),
      startTimestampObj: params?.startTimestampObj || moment.utc().startOf('day'),
      endTimestampObj: this.getLocalEndTime(props, this.state || { projectName }, params?.endTimestampObj),
      instanceName:
        R.isNil(instanceName) || R.isEmpty(instanceName) || instanceName.length === allInstanceListOptions.length
          ? R.map((item) => item.value, allInstanceListOptions)
          : instanceName,
      checkAllInstance:
        R.isNil(instanceName) || R.isEmpty(instanceName) || instanceName.length === allInstanceListOptions.length,
    };
  }

  @autobind
  handleTemplateChange(templateId) {
    const { pn, templateParams } = this.props;
    const startTimeObj = templateParams.startTimeObj || moment.utc().startOf('day');
    const endTimeObj = this.getLocalEndTime(this.props, this.state, templateParams.endTimeObj);
    this.setState(
      {
        templateId,
        startTimeObj,
        endTimeObj,
        startTimestampObj: startTimeObj,
        endTimestampObj: endTimeObj,
        instanceName: [],
        checkAllInstance: false,
        isLoadingInstance: true,
      },
      () => {
        this.setInstanceOption(pn || templateParams.projectName);
      },
    );
  }

  @autobind
  async reloadInstanceData(projectName, localInstanceList, project) {
    const { intl, credentials } = this.props;
    const { startTimeObj, startTimestampObj, templateId, duration, endTimeObj, endTimestampObj } = this.state;
    const isEntries = templateId === 'search_all_log_entries' || templateId === 'live_tail_log_entries';

    const projectendTime = this.getLocalEndTime(this.props, { projectName, endTimeChanged: true }, null).valueOf();
    let startTime =
      startTimeObj?.clone().startOf('day').valueOf() || startTimestampObj?.clone().startOf('day').valueOf();
    let endTime = this.getLocalEndTime(
      this.props,
      { projectName, endTimeChanged: true },
      isEntries ? endTimestampObj : endTimeObj,
    ).valueOf();

    if (endTime > projectendTime) {
      endTime = projectendTime;
    }

    if (isEntries) {
      startTime = endTime - getDurationMs(duration);
    }

    if (startTime > endTime) {
      startTime = endTime;
    }

    let instanceGroupList = [];
    const instanceDisplayNameMap = {};
    if (startTime && endTime) {
      const request = [
        fetchGet(getEndpoint('fetchloginstancewithdata'), {
          ...credentials,
          projectName,
          startTime,
          endTime,
        }),
        fetchGet(getEndpoint('instance-display-name'), {
          ...credentials,
          instanceDisplayNameRequestList: JSON.stringify([
            { projectName: project?.projectShortName, customerName: project?.owner },
          ]),
        }),
      ];

      await Promise.all(request)
        .then((res) => {
          const [d, d1] = res || [];
          const { success } = d || {};
          if (success || success === undefined) {
            R.forEachObjIndexed((c, i) => {
              instanceGroupList.push({
                instanceName: i,
                appName: c || '',
              });
            }, d || {});
            instanceGroupList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('instanceName')))], instanceGroupList);
          }

          R.forEach((item) => {
            const [pInfo, iList] = item || [];
            const { projectName, customerName } = pInfo || {};
            R.forEach((instanceInfo) => {
              const { instanceSet, instanceDisplayName } = instanceInfo || {};
              R.forEach((instance) => {
                instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
                instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
              }, instanceSet || []);
            }, iList || []);
          }, d1 || []);
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        });
    }

    return { instanceGroupList, instanceDisplayNameMap };
  }

  @autobind
  handleProjectChange(projectName) {
    const { startTimeObj, startTimestampObj, endTimestampObj } = this.state;
    const endTimeObj = this.getLocalEndTime(this.props, { projectName }, this.state.endTimeObj);
    this.setState({ projectName, endTimeObj, instanceGroup: null, instanceName: [], isLoadingInstance: true }, () => {
      let params = {
        projectName,
      };
      if ((startTimeObj && endTimeObj) || (startTimestampObj && endTimestampObj)) {
        params = {
          ...params,
          startTime: startTimeObj?.valueOf() || null,
          endTime: endTimeObj?.valueOf() || null,
          startTimestamp: startTimestampObj?.valueOf() || null,
          endTimestamp: endTimeObj?.valueOf() || null,
        };
      }
      this.props.onProjectChange(params);
    });
  }

  @autobind
  handleStartTimeChange(field, fieldType) {
    return (timeObj) => {
      const { projectName, templateId } = this.state;
      // const startField = fieldType === 'date' ? 'startTimeObj' : 'startTimestampObj';
      const endField = fieldType === 'date' ? 'endTimeObj' : 'endTimestampObj';
      // const startTimeObj = get(this.state, [startField]);
      const endTimeObj = get(this.state, [endField]);
      const newState = {};

      if (timeObj) {
        // reset start time
        if (templateId === '8f8f61ab21c44eb3aafa6805685eb8ff') {
          const newEndTime = timeObj.clone().endOf('day').add(this.maximumPredictionDuration, 'days');
          if (newEndTime.valueOf() < endTimeObj.valueOf()) {
            newState[endField] = newEndTime;
          }
        }
      }
      newState[field] = timeObj;

      this.setState(newState, () => {
        if (projectName && timeObj) {
          const timestamp = timeObj.valueOf();
          this.props.onProjectChange({
            projectName,
            [fieldType === 'date' ? 'startTime' : 'startTimestamp']: timestamp,
            [fieldType === 'date' ? 'endTime' : 'endTimestamp']: endTimeObj ? endTimeObj.valueOf() : null,
          });
          const onlyStartTime = templateId === 'e3c3c5e8172f4a1396988747e6dc09be';
          if (onlyStartTime) this.props.onQuerySubmit(this.state);
        }
      });
    };
  }

  @autobind
  handleEndTimeChange(field, fieldType) {
    return (timeObj) => {
      const { projectName, templateId } = this.state;
      const startField = fieldType === 'date' ? 'startTimeObj' : 'startTimestampObj';
      const startTimeObj = get(this.state, [startField]);
      const newState = { endTimeChanged: true };

      if (timeObj) {
        if (fieldType === 'date') {
          timeObj = timeObj.endOf('day');
        }

        // reset start time
        if (templateId === '8f8f61ab21c44eb3aafa6805685eb8ff') {
          const newStartTime = timeObj.clone().startOf('day').subtract(this.maximumPredictionDuration, 'days');
          if (newStartTime.valueOf() >= startTimeObj.valueOf()) {
            newState[startField] = newStartTime;
          }
        }
      }
      newState[field] = timeObj;

      this.setState(newState, () => {
        if (projectName && timeObj) {
          const timestamp = timeObj.valueOf();
          this.props.onProjectChange({
            projectName,
            [fieldType === 'date' ? 'startTime' : 'startTimestamp']: startTimeObj ? startTimeObj.valueOf() : null,
            [fieldType === 'date' ? 'endTime' : 'endTimestamp']: timestamp,
          });
        }
      });
    };
  }

  @autobind
  handleDateChange(timeObj) {
    const { projectName, interval } = this.state;
    this.setState({ timeObj }, () => {
      if (projectName && timeObj && interval) {
        const timestamp = timeObj.valueOf();
        this.props.onProjectChange({
          projectName,
          startTimestamp: timestamp - interval * 60 * 1000,
          endTimestamp: timestamp + interval * 60 * 1000,
        });
      }
    });
  }

  @autobind
  handleIntervalChange(interval) {
    const { projectName, timeObj } = this.state;
    this.setState({ interval }, () => {
      if (projectName && timeObj && interval) {
        const timestamp = timeObj.valueOf();
        this.props.onProjectChange({
          projectName,
          startTimestamp: timestamp - interval * 60 * 1000,
          endTimestamp: timestamp + interval * 60 * 1000,
        });
      }
    });
  }

  @autobind
  onChangeProjectList(value) {
    this.setState({ projectList: value });
  }

  @autobind
  onChangeDuration(value) {
    const { pn, templateParams } = this.props;
    this.setState({ duration: value, isLoadingInstance: true }, () => {
      this.setInstanceOption(pn || templateParams.projectName);
    });
  }

  @autobind
  handleTrainingWindowChange(trainingWindow) {
    this.setState({ trainingWindow });
  }

  @autobind
  handleSubmit() {
    const { onQuerySubmit, templateParams } = this.props;
    const interval = parseInt(this.state.interval, 10);
    const trainingWindow = parseInt(this.state.trainingWindow, 10);
    const { templateId, duration, endTimestampObj, instanceName, checkAllInstance } = this.state;
    let state = {
      ...this.state,
      interval: isFinite(interval) ? interval * 60 * 1000 : null,
      trainingWindow: isFinite(trainingWindow) ? trainingWindow * 86400000 : null,
      instanceName: JSON.stringify(instanceName),
    };

    if (templateId === 'search_all_log_entries' || templateId === 'live_tail_log_entries') {
      const endTime = this.getLocalEndTime(this.props, this.state, endTimestampObj).valueOf();
      const startTime = endTime - getDurationMs(duration);
      state = {
        ...state,
        startTimestampObj: moment.utc(Number(startTime)),
        endTimeObj: moment.utc(Number(endTime)),
        startTimeObj: moment.utc(Number(startTime)),
        endTimestampObj: moment.utc(Number(endTime)),
        instanceName: JSON.stringify(instanceName),
      };
    }

    onQuerySubmit(state);
  }

  @autobind
  handleCancel() {
    const { onQueryCancel } = this.props;
    onQueryCancel(this.state);
  }

  render() {
    const { intl, templateList, templateMap, isParamsLoading, isResultLoading } = this.props;
    const queryOptions = R.map(
      (item) => ({ value: item.id, label: item.desc }),
      R.filter((item) => FilterQueryList.includes(item.id), templateList),
    );

    let { projects } = this.props;
    const {
      templateId,
      projectList,
      projectName,
      instanceName,
      duration,
      endTimestampObj,
      checkAllInstance,
      allInstanceListOptions,
      isLoadingInstance,
    } = this.state;
    const template = get(templateMap, templateId);

    const validTemplate = Boolean(template);
    const requiredParams = get(template || {}, 'requiredParams', []);
    const optionalParams = get(template || {}, 'optionalParams', []);

    // Generate the parameters components based on the requiredParams, and also set
    // the parameter validation. If parameters are not valid, submit is disabled.
    const needParams = requiredParams.length > 0;
    const showParams = needParams || optionalParams.length > 0;
    const needsProjectName =
      ifIn(Params.ProjectName, requiredParams) ||
      ifIn(Params.MetricProjectName, requiredParams) ||
      ifIn(Params.LogProjectName, requiredParams);

    // Filter the project
    if (ifIn(Params.MetricProjectName, requiredParams)) {
      projects = R.filter((project) => project.isMetric, projects);
    }
    if (ifIn(Params.LogProjectName, requiredParams)) {
      projects = R.filter((project) => !project.isMetric, projects);
    }
    if (templateId === '9076db738dc24cf38e4f9db53ba1deb3') {
      projects = R.filter((project) => !project.isMetric, projects);
    }
    const onlyStartTime =
      templateId === 'e3c3c5e8172f4a1396988747e6dc09be' || templateId === '6f0627d11427480b95e8d012f83c83f0';
    const onlyEndTime = templateId === 'search_all_log_entries' || templateId === 'live_tail_log_entries';

    const needsProjectList = ifIn(Params.ProjectList, requiredParams);
    const needsInstanceGroup = ifIn(Params.InstanceGroup, requiredParams);
    const needsInstanceName = ifIn(Params.InstanceName, requiredParams);
    const needsLogEventType = ifIn(Params.LogEventType, requiredParams);
    const needsStartTime = ifIn(Params.StartTime, requiredParams);
    const needsStartTimestamp = ifIn(Params.StartTimestamp, requiredParams);
    const needsEndTime = ifIn(Params.EndTime, requiredParams);
    const needsEndTimestamp = ifIn(Params.EndTimestamp, requiredParams);
    const needsDuration = ifIn(Params.Duration, requiredParams);
    const needsTime = ifIn(Params.Time, requiredParams);
    const needsInterval = ifIn(Params.Interval, requiredParams);
    const needsTrainingWindow = ifIn(Params.TrainingWindow, requiredParams);
    const needsForecastInterval = ifIn(Params.ForecastInterval, requiredParams);
    const needsModelType = ifIn(Params.ModelType, requiredParams);
    const needsKeyword = ifIn(Params.Keyword, requiredParams);
    const needsExcludingKeyword = ifIn(Params.ExcludingKeyword, requiredParams);
    const needsSimilarityLevel = ifIn(Params.SimilarityLevel, requiredParams);
    const needsNumOfCluster = ifIn(Params.NumOfCluster, requiredParams);
    const needsIncidentId = ifIn(Params.IncidentId, requiredParams);
    const needsPattern = ifIn(Params.Pattern, requiredParams);

    const showProjectName =
      needsProjectName ||
      ifIn(Params.ProjectName, optionalParams) ||
      ifIn(Params.MetricProjectName, optionalParams) ||
      ifIn(Params.LogProjectName, optionalParams);
    const showProjectList = needsProjectList || ifIn(Params.ProjectList, optionalParams);
    const showInstanceGroup = needsInstanceGroup || ifIn(Params.InstanceGroup, optionalParams);
    const showInstanceName = needsInstanceName || ifIn(Params.InstanceName, optionalParams);
    const showLogEventType = needsLogEventType || ifIn(Params.LogEventType, optionalParams);
    const showStartTime = (needsStartTime || ifIn(Params.StartTime, optionalParams)) && !onlyEndTime;
    const showStartTimestamp = (needsStartTimestamp || ifIn(Params.StartTimestamp, optionalParams)) && !onlyEndTime;
    const showEndTime = (needsEndTime || ifIn(Params.EndTime, optionalParams)) && !onlyStartTime;
    const showEndTimestamp = (needsEndTimestamp || ifIn(Params.EndTimestamp, optionalParams)) && !onlyStartTime;
    const showTime = needsTime || ifIn(Params.Time, optionalParams);
    const showInterval = needsInterval || ifIn(Params.Interval, optionalParams);
    const showDuration = needsDuration || ifIn(Params.Duration, optionalParams);
    const showTrainingWindow = needsTrainingWindow || ifIn(Params.TrainingWindow, optionalParams);
    const showForecastInterval = needsForecastInterval || ifIn(Params.ForecastInterval, optionalParams);
    const showModelType = needsModelType || ifIn(Params.ModelType, optionalParams);
    const showKeyword = needsKeyword || ifIn(Params.Keyword, optionalParams);
    const showExcludingKeyword = needsExcludingKeyword || ifIn(Params.ExcludingKeyword, optionalParams);
    const showSimilarityLevel = needsSimilarityLevel || ifIn(Params.SimilarityLevel, optionalParams);
    const showNumOfCluster = needsNumOfCluster || ifIn(Params.NumOfCluster, optionalParams);
    const showIncidentId = needsIncidentId || ifIn(Params.IncidentId, optionalParams);
    const showPattern = needsPattern || ifIn(Params.Pattern, optionalParams);

    const projectListError = needsProjectList && (!projectList || projectList.length === 0);
    const instanceGroupLink = VLink.state(this, 'instanceGroup').check(
      (value) => !needsInstanceGroup || Boolean(value),
      'group is required',
    );
    const logEventTypeLink = VLink.state(this, 'logEventType').check(
      (value) => !needsLogEventType || Boolean(value),
      'log event type is required',
    );
    const startTimeObjLink = VLink.state(this, 'startTimeObj')
      .check((value) => !needsStartTime || Boolean(value), 'start time is required')
      .onChange(this.handleStartTimeChange('startTimeObj', 'date'));
    const endTimeObjLink = VLink.state(this, 'endTimeObj')
      .check((value) => {
        return !needsEndTime || Boolean(value);
      }, 'end time is required')
      .onChange(this.handleEndTimeChange('endTimeObj', 'date'));
    const startTimestampObjLink = VLink.state(this, 'startTimestampObj')
      .check((value) => !needsStartTimestamp || Boolean(value), 'start time is required')
      .onChange(this.handleStartTimeChange('startTimestampObj', 'time'));
    const endTimestampObjLink = VLink.state(this, 'endTimestampObj')
      .check((value) => {
        return !needsEndTimestamp || Boolean(value);
      }, 'end time is required')
      .onChange(this.handleEndTimeChange('endTimestampObj', 'time'));
    const durationError = needsDuration && !duration;
    const timeObjLink = VLink.state(this, 'timeObj')
      .check((value) => !needsTime || Boolean(value), 'date is required')
      .onChange(this.handleDateChange);
    const intervalLink = VLink.state(this, 'interval')
      .check((value) => !needsInterval || (Boolean(value) && parseInt(value, 10) > 0), 'interval is required')
      .onChange(this.handleIntervalChange);
    const trainingWindowLink = VLink.state(this, 'trainingWindow')
      .check(
        (value) => !needsTrainingWindow || (Boolean(value) && parseInt(value, 10) >= 1 && parseInt(value, 10) <= 10),
        'training window is required and has to be between 1 and 10',
      )
      .onChange(this.handleTrainingWindowChange);
    const forecastIntervalLink = VLink.state(this, 'forecastInterval').check(
      (value) => !needsForecastInterval || Boolean(value),
      'forecast interval is required',
    );
    const modelTypeLink = VLink.state(this, 'modelType').check(
      (value) => !needsModelType || Boolean(value),
      'model type is required',
    );
    const keywordLink = VLink.state(this, 'keyword').check(
      (value) => !needsKeyword || (Boolean(value) && R.test(/^"[^\n\r]+"(\s*(AND|OR)\s*"[^\n\r]+")*$/, value)),
      'keyword is required and must be in the right format',
    );
    const excludingKeywordLink = VLink.state(this, 'excludingKeyword').check(
      (value) => !needsExcludingKeyword || (Boolean(value) && R.test(/^"[^\n\r]+"(\s*(AND|OR)\s*"[^\n\r]+")*$/, value)),
      'excluding keyword is required and must be in the right format',
    );
    const similarityLevelLink = VLink.state(this, 'similarityLevel').check(
      (value) => !needsSimilarityLevel || Boolean(value),
      'similarity level is required',
    );
    const numOfClusterLink = VLink.state(this, 'numOfCluster').check(
      (value) => !needsNumOfCluster || (Boolean(value) && value.indexOf('.') < 0 && parseInt(value, 10) > 0),
      'input a positive integer number',
    );
    const incidentIdLink = VLink.state(this, 'incidentId').check(
      (value) => !needsIncidentId || Boolean(value),
      'incidentId is required',
    );
    const patternLink = VLink.state(this, 'pattern');
    // Ignore the parameter check and simplify it with empty value.
    const project = R.find((p) => p.projectName === projectName, projects) || {};
    const groupList = get(project, 'groupList', []);
    const maxObj =
      templateId === '8f8f61ab21c44eb3aafa6805685eb8ff'
        ? moment.utc().add(this.maximumPredictionDuration, 'days')
        : moment.utc();

    const isValid =
      validTemplate &&
      (!needParams ||
        ((!needsProjectName || Boolean(projectName)) &&
          !projectListError &&
          !instanceGroupLink.error &&
          (!needsInstanceName || Boolean(instanceName)) &&
          !logEventTypeLink.error &&
          !startTimeObjLink.error &&
          !endTimeObjLink.error &&
          !startTimestampObjLink.error &&
          !endTimestampObjLink.error &&
          !durationError &&
          !timeObjLink.error &&
          !intervalLink.error &&
          !trainingWindowLink.error &&
          !forecastIntervalLink.error &&
          !modelTypeLink.error &&
          !keywordLink.error &&
          !excludingKeywordLink.error &&
          !similarityLevelLink.error &&
          !incidentIdLink.error &&
          !numOfClusterLink.error &&
          !patternLink.error));

    // const instanceValid = R.isEmpty(instanceName) && !checkAllInstance;
    const instanceValid = R.isEmpty(instanceName);
    const needAll = ['953de6a33d8a4b96ac9c100bf69ba3fc', 'b81364228d574502b7f6c3e454b9a21b'];
    const showCheckAll = needAll.includes(templateId);
    return (
      <Container className={`flex-col query-box ${isParamsLoading ? 'loading ' : ''}`}>
        <div className="flex-row template">
          <AntdSelect
            className="flex-grow"
            showSearch
            value={(queryOptions || []).length > 0 ? templateId : null}
            onChange={this.handleTemplateChange}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {R.map(
              (item) => (
                <AntdSelect.Option key={item.value} value={item.value}>
                  {R.has(item.value, queryMessages)
                    ? intl.formatMessage(queryMessages[item.value], { displayName: item.label })
                    : item.label}
                </AntdSelect.Option>
              ),
              queryOptions,
            )}
          </AntdSelect>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginLeft: 8 }}
            disabled={!isValid || instanceValid}
            onClick={isValid ? this.handleSubmit : () => {}}
          >
            {intl.formatMessage(appFieldsMessages.query)}
          </Button>
          {isResultLoading && (
            <Button type="primary" icon={<CloseOutlined />} style={{ marginLeft: 8 }} onClick={this.handleCancel}>
              {intl.formatMessage(appButtonsMessages.cancel)}
            </Button>
          )}
        </div>
        {validTemplate && showParams && (
          <Container className="flex-row parameters">
            <Container toolbar>
              <div className="parameters-option">
                {showProjectName && (
                  <div className="option">
                    <div className={`error-container ${needsProjectName ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.project)}</label>
                    </div>
                    <AntdSelect
                      allowClear
                      showSearch
                      size="small"
                      style={{ width: 180 }}
                      placeholder="Project"
                      optionFilterProp="value"
                      filterOption
                      value={projectName}
                      onChange={this.handleProjectChange}
                      dropdownMatchSelectWidth={false}
                    >
                      {R.map(
                        (item) => (
                          <AntdSelect.Option key={item.projectName} value={item.projectName}>
                            {`${item.projectDisplayName}${item.owner ? `@${item.owner}` : ''}${
                              item.systemName ? ` (${item.systemName})` : ''
                            }`}
                          </AntdSelect.Option>
                        ),
                        projects || [],
                      )}
                    </AntdSelect>
                  </div>
                )}
                {showProjectName && showInstanceGroup && (
                  <div className="option">
                    <div className={`error-container ${needsInstanceGroup ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.group)}</label>
                    </div>
                    <Select
                      name="group"
                      style={{ width: 180 }}
                      valueLink={instanceGroupLink}
                      placeholder="group"
                      options={R.map((g) => ({ label: g, value: g }), groupList)}
                    />
                  </div>
                )}
                {showProjectList && (
                  <div className="option">
                    <div className={`error-container ${needsProjectList ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.projects)}</label>
                    </div>
                    <AntdSelect
                      className="inline"
                      style={{ width: 300 }}
                      mode="multiple"
                      maxTagCount={1}
                      allowClear
                      showArrow={false}
                      showSearch
                      placeholder="Projects"
                      value={projectList}
                      optionFilterProp="children"
                      onChange={this.onChangeProjectList}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <AntdSelect.Option key={item.projectName} value={item.projectName}>
                            {`${item.projectDisplayName}${item.owner ? `@${item.owner}` : ''}${
                              item.systemName ? ` (${item.systemName})` : ''
                            }`}
                          </AntdSelect.Option>
                        );
                      }, projects || [])}
                    </AntdSelect>
                  </div>
                )}
                {showTime && (
                  <div className="option">
                    <div className={`error-container ${needsTime ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.time)}</label>
                    </div>
                    <DatePicker
                      utcOffset={0}
                      className="time"
                      todayButton={intl.formatMessage(appFieldsMessages.today)}
                      dateFormat={Defaults.TimeFormat}
                      maxDate={maxObj}
                      showMonthDropdown
                      showTimeSelect
                      timeIntervals={1}
                      selectedLink={timeObjLink}
                    />
                  </div>
                )}
                {showInterval && (
                  <div className="option">
                    <div className={`error-container ${needsInterval ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.intervalMins)}</label>
                    </div>
                    <Input type="number" min="0" name="interval" valueLink={intervalLink} style={{ width: 100 }} />
                  </div>
                )}
                {showForecastInterval && (
                  <div className="option">
                    <div className={`error-container ${needsForecastInterval ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.interval)}</label>
                    </div>
                    <Select
                      name="forecastInterval"
                      style={{ width: 100 }}
                      valueLink={forecastIntervalLink}
                      options={this.forecastIntervalOptions}
                    />
                  </div>
                )}
                {showStartTime && (
                  <div className="option">
                    <div className={`error-container ${needsStartTime ? ' required' : ''}`}>
                      <label>
                        {onlyStartTime
                          ? intl.formatMessage(appFieldsMessages.date)
                          : intl.formatMessage(appFieldsMessages.startDate)}
                      </label>
                    </div>
                    <Tooltip
                      title={
                        templateId === '8f8f61ab21c44eb3aafa6805685eb8ff'
                          ? intl.formatMessage(queryFieldMessages.maximumPredictionDuration, {
                              day: this.maximumPredictionDuration,
                            })
                          : null
                      }
                      mouseEnterDelay={0.3}
                      trigger="focus"
                      overlayStyle={templateId === '8f8f61ab21c44eb3aafa6805685eb8ff' ? {} : { display: 'none' }}
                    >
                      <DatePicker
                        utcOffset={0}
                        todayButton={intl.formatMessage(appFieldsMessages.today)}
                        dateFormat={Defaults.DateFormat}
                        maxDate={maxObj}
                        showMonthDropdown
                        selectedLink={startTimeObjLink}
                        onCalendarClose={() => {
                          const { pn, templateParams } = this.props;
                          this.setState({ isLoadingInstance: true }, () => {
                            this.setInstanceOption(pn || templateParams.projectName);
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
                {showEndTime && (
                  <div className="option">
                    <div className={`error-container ${needsEndTime ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.endDate)}</label>
                    </div>
                    <Tooltip
                      title={
                        templateId === '8f8f61ab21c44eb3aafa6805685eb8ff'
                          ? intl.formatMessage(queryFieldMessages.maximumPredictionDuration, {
                              day: this.maximumPredictionDuration,
                            })
                          : null
                      }
                      mouseEnterDelay={0.3}
                      trigger="focus"
                      overlayStyle={templateId === '8f8f61ab21c44eb3aafa6805685eb8ff' ? {} : { display: 'none' }}
                    >
                      <DatePicker
                        utcOffset={0}
                        todayButton={intl.formatMessage(appFieldsMessages.today)}
                        dateFormat={Defaults.DateFormat}
                        showMonthDropdown
                        maxDate={maxObj}
                        selectedLink={endTimeObjLink}
                        onCalendarClose={() => {
                          const { pn, templateParams } = this.props;
                          this.setState({ isLoadingInstance: true }, () => {
                            this.setInstanceOption(pn || templateParams.projectName);
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
                {showStartTimestamp && (
                  <div className="option">
                    <div className={`error-container ${needsStartTimestamp ? ' required' : ''}`}>
                      <label>
                        {onlyStartTime
                          ? intl.formatMessage(appFieldsMessages.date)
                          : intl.formatMessage(appFieldsMessages.startDate)}
                      </label>
                    </div>
                    <DatePicker
                      className="time"
                      utcOffset={0}
                      todayButton={intl.formatMessage(appFieldsMessages.today)}
                      dateFormat={Defaults.TimeFormat}
                      maxDate={maxObj}
                      showYearDropdown
                      showMonthDropdown
                      showTimeSelect
                      timeIntervals={1}
                      selectedLink={startTimestampObjLink}
                      onCalendarClose={() => {
                        const { pn, templateParams } = this.props;
                        this.setState({ isLoadingInstance: true }, () => {
                          this.setInstanceOption(pn || templateParams.projectName);
                        });
                      }}
                    />
                  </div>
                )}
                {showEndTimestamp && (
                  <div className="option">
                    <div className={`error-container ${needsEndTimestamp ? ' required' : ''}`}>
                      <label style={{ width: 72, display: 'inline-block' }}>
                        {intl.formatMessage(appFieldsMessages.endTime)}
                      </label>
                    </div>
                    <RcDatePicker
                      showTimeSelect
                      showTimeInput
                      timeIntervals={15}
                      showPopperArrow={false}
                      timeFormat={Defaults.DatePickerTime}
                      dateFormat={needsEndTimestamp ? Defaults.DateTimeFormat1 : Defaults.DateFormat1}
                      selected={utcObjToLocalDate(endTimestampObj)}
                      customTimeInput={<TimeInput />}
                      onChange={(endTime) => {
                        this.handleEndTimeChange('endTimestampObj', 'time')(localDateToUtcObj(endTime));
                      }}
                      onCalendarClose={() => {
                        const { pn, templateParams } = this.props;
                        this.setState({ isLoadingInstance: true }, () => {
                          this.setInstanceOption(pn || templateParams.projectName);
                        });
                      }}
                    />
                  </div>
                )}
                {showDuration && (
                  <div className="option">
                    <div className={`error-container ${needsDuration ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.duration)}</label>
                    </div>
                    <AntdSelect
                      className="inline"
                      style={{ width: 140 }}
                      allowClear
                      showArrow={false}
                      showSearch
                      value={duration}
                      optionFilterProp="children"
                      onChange={this.onChangeDuration}
                      filterOption={(input, option) =>
                        this.durationOptions.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {R.map(
                        (item) => (
                          <AntdSelect.Option key={item.id} value={item.id}>
                            {item.text}
                          </AntdSelect.Option>
                        ),
                        this.durationOptions || [],
                      )}
                    </AntdSelect>
                  </div>
                )}
                {showTrainingWindow && (
                  <div className="option">
                    <div className={`error-container ${needsTrainingWindow ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.trainingWindow)}</label>
                    </div>
                    <Input
                      type="number"
                      min="0"
                      name="trainingWindow"
                      valueLink={trainingWindowLink}
                      style={{ width: 100 }}
                    />
                  </div>
                )}
                {showProjectName && showInstanceName && (
                  <div className="option">
                    <div className="error-container required">
                      <label>{intl.formatMessage(appFieldsMessages.instance)}</label>
                    </div>
                    <AntdSelect
                      allowClear
                      showSearch
                      mode="multiple"
                      style={{ minWidth: 230, maxWidth: 650 }}
                      placeholder="Instance"
                      optionFilterProp="value"
                      filterOption={(input, option) => {
                        return (
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                        );
                      }}
                      value={instanceName}
                      onChange={(instanceNameValue) => {
                        if (instanceNameValue.length > 2 && !needAll.includes(templateId)) {
                          instanceNameValue.shift();
                        }
                        this.setState({
                          instanceName: instanceNameValue,
                          checkAllInstance: allInstanceListOptions.length === instanceNameValue.length,
                        });
                      }}
                      disabled={isLoadingInstance}
                      loading={isLoadingInstance}
                      dropdownStyle={{ maxWidth: 650 }}
                      dropdownMatchSelectWidth={false}
                      maxTagCount={1}
                      dropdownRender={(menu) => (
                        <>
                          {showCheckAll && (
                            <>
                              <Checkbox
                                style={{ paddingLeft: 12 }}
                                checked={checkAllInstance}
                                onChange={(e) => {
                                  this.setState({
                                    checkAllInstance: e.target.checked,
                                    instanceName: e.target.checked
                                      ? R.map((item) => item.value, allInstanceListOptions)
                                      : [],
                                  });
                                }}
                              >
                                {intl.formatMessage(appFieldsMessages.selectAll)}
                              </Checkbox>
                              <Divider style={{ margin: '4px 0' }} />
                            </>
                          )}
                          {menu}
                        </>
                      )}
                    >
                      {R.map((item) => {
                        return (
                          <AntdSelect.Option
                            className="hide-icon"
                            key={item.value}
                            value={item.value}
                            label={item.label}
                          >
                            <Checkbox
                              checked={(instanceName || []).includes(item.value)}
                              onChange={(e) => null}
                              style={{ marginRight: 8 }}
                            />
                            {item.label}
                          </AntdSelect.Option>
                        );
                      }, allInstanceListOptions || [])}
                    </AntdSelect>
                  </div>
                )}
                {showLogEventType && (
                  <div className="option">
                    <div className={`error-container ${needsLogEventType ? ' required' : ''}`}>
                      <label>Anomaly Type</label>
                    </div>
                    <Select
                      name="logEventType"
                      style={{ width: 120 }}
                      valueLink={logEventTypeLink}
                      placeholder="Anomaly Type"
                      options={R.map((name) => ({ value: name, label: CamelCase(name) }), LogTypes)}
                      clearable
                    />
                  </div>
                )}
                {showModelType && (
                  <div className="option">
                    <div className={`error-container ${needsModelType ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.modelType)}</label>
                    </div>
                    <Select name="modeltype" valueLink={modelTypeLink} options={this.modelTypeOptions} />
                  </div>
                )}
                {showKeyword && (
                  <div className="option">
                    <div className={`error-container ${needsKeyword ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.keywords)}</label>
                    </div>
                    <Input
                      type="text"
                      placeholder={`"keyword1" AND "keyword2" OR "keyword3"`}
                      valueLink={keywordLink}
                      style={{ width: 800 }}
                    />
                  </div>
                )}
                {showExcludingKeyword && (
                  <div className="option">
                    <div className={`error-container ${needsExcludingKeyword ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.excludingKeywords)}</label>
                    </div>
                    <Input
                      type="text"
                      placeholder={`"keyword1" AND "keyword2" OR "keyword3"`}
                      valueLink={excludingKeywordLink}
                      style={{ width: 1014 }}
                    />
                  </div>
                )}
                {showSimilarityLevel && (
                  <div className="option">
                    <div className={`error-container ${needsSimilarityLevel ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.similarityLevel)}</label>
                    </div>
                    <Select
                      name="similarityLevel"
                      style={{ width: 140 }}
                      valueLink={similarityLevelLink}
                      options={this.similarityLevelOptions}
                    />
                  </div>
                )}
                {showNumOfCluster && (
                  <div className="option">
                    <div className={`error-container ${needsNumOfCluster ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.topKPatterns)}</label>
                    </div>
                    <Input
                      name="numOfCluster"
                      style={{ width: 80 }}
                      type="number"
                      min="0"
                      valueLink={numOfClusterLink}
                    />
                  </div>
                )}
                {showIncidentId && (
                  <div className="option">
                    <div className={`error-container ${needsIncidentId ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.incidentId)}</label>
                    </div>
                    <Input type="text" placeholder="incident id" valueLink={incidentIdLink} style={{ width: 200 }} />
                  </div>
                )}
                {showPattern && (
                  <div className="option">
                    <div className={`error-container ${needsPattern ? ' required' : ''}`}>
                      <label>{intl.formatMessage(appFieldsMessages.pattern)}</label>
                    </div>
                    <Input name="pattern" style={{ width: 100 }} type="text" valueLink={patternLink} />
                  </div>
                )}
              </div>
            </Container>
          </Container>
        )}
      </Container>
    );
  }
}

const QueryBox = injectIntl(QueryBoxCore);
export default connect((state: State) => {
  const { credentials } = state.auth;
  return {
    credentials,
  };
}, {})(QueryBox);
