import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, isNumber, round } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  EditOutlined,
  DownOutlined,
  UpOutlined,
  ExclamationCircleFilled,
  DoubleLeftOutlined,
  AimOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { Spin, Menu, message, Tag, Select, Space } from 'antd';
import ThundraTraceChart from 'thundra-trace-chart';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Modal, List, CellMeasurerCache, CellMeasurer, Dropdown, Popover } from '../../../lib/fui/react';
import {
  Defaults,
  EventRenderers,
  CellRenderers,
  buildUrl,
  GlobalParse,
  LogRenderers,
  parseLocation,
} from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { BaseUrls } from '../../app/Constants';
import { ChangeEventIcon } from '../../../lib/fui/icons';
import { EChart } from '../../share';

import { appMessages, appButtonsMessages, appFieldsMessages, appMenusMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

import EventShortListModal from './EventShortListModal';
import TriageReportModal from './TriageReportModal';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import EventActionModal from '../../metric/components/EventActionModal';
import ProjectSelectorModal from './ProjectSelectorModal';
import ReportJiraModal from '../../metric/components/ReportJiraModal';
import ReportServiceNowModal from '../../metric/components/ReportServiceNowModal';
import { UHV_LIST_MAX_SHOW_COUNT } from './GlobalConstants';
import CopyTextContent from '../../app/components/CopyContent';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  title: String,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  timezoneOffset: Number,
  projects: Array<Object>,
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  events: Array,
  projectName: String,
  isMetric: Boolean,
  currentTheme: String,
  summarySettingsMap: Object,
  height: Number,
  width: Number,
  activeLineIconKey: String,
  changeActiveLineIconKey: Function,
  onRefreshData: Function,
  globalLoading: Boolean,
  location: Object,
  globalInfo: Object,
};

const returnColor = (num) => {
  let anomalyColor = '#ffc107';
  if (num >= 500) {
    anomalyColor = '#ff0014';
  } else if (num >= 100) {
    anomalyColor = '#ff5722';
  } else if (num >= 50) {
    anomalyColor = '#ff9800';
  }
  return anomalyColor;
};

class GlobalDetectedAnomaliesTableCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.listHeaderHeight = 40;
    this.listRowHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      fixedHeight: true,
      defaultHeight: this.listRowHeight,
    });

    this.state = {
      // eslint-disable-next-line
      ignoreFilter: true,
      optionTimelineRange: undefined,
      patternNameMap: {},

      activeEvent: null,
      showSystemEventModel: false,
      showTriageReportModal: false,
      showTakeLogActionModal: false,
      actionName: null,
      showEventActionModal: false,
      showReportJiraModal: false,
      showReportServiceNowModal: false,
      showProjectSelector: false,
      onConfirmProjectSelect: () => {},

      groupEventList: [],
      filterGroupEventList: [],
      expandIds: [],
      listHeight: this.listRowHeight * UHV_LIST_MAX_SHOW_COUNT,
      sortBy: null,
      sortDirection: null,
      allExpand: true,

      isLoading: false,
    };
    this.anomalyEvents = [];
    this.projectOptions = [];
    this.instanceOptions = [];
    this.patternOptions = [];
    this.metricOptions = [];
    this.logOption = [
      { label: 'Hot', value: 'hot' },
      { label: 'New', value: 'newpattern' },
      { label: 'Cold', value: 'cold' },
      { label: intl.formatMessage(logMessages.keywordAlerts), value: 'whitelist' },
      { label: 'Rare', value: 'rare' },
    ];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.events !== this.props.events || nextProps.projectName !== this.props.projectName) {
      this.reloadData(nextProps);
    }
    if (nextProps.currentTheme !== this.props.currentTheme) {
      const { events } = nextProps;
      const { expandIds } = this.state;
      const groupEventList = this.handleSetEvents(events, expandIds, nextProps);
      this.setState({ groupEventList, filterGroupEventList: groupEventList }, () => {
        this.cellMeasureCache.clearAll();
        if (this.listNode) this.listNode.forceUpdateGrid();
        this.forceUpdate();
      });
    }
  }

  @autobind
  reloadData(props) {
    const { events, projectName } = props;
    const { sortBy, sortDirection, allExpand } = this.state;
    const expandIds = [];
    let allTimeline = [];
    let groupEventList = [];

    this.setState({ isLoading: true });

    R.addIndex(R.forEach)((item, index) => {
      const { patternId } = item;
      const anomalyEvents = get(item, 'timelines', []);
      const {
        anomalyEvents: newAnomalyEvents,
        optionTimelineRange,
        mergeOptionTimeline,
        allStartTimestamp,
        allEndTimestamp,
      } = this.parseData({
        ...props,
        anomalyEvents,
        groupId: index,
        patternId,
        projectName,
      });
      groupEventList = [
        ...groupEventList,
        {
          ...item,
          expand: allExpand,
          isParent: true,
          isChildren: false,
          groupId: index,
          count: newAnomalyEvents.length,
          optionTimelineRange,
          children: newAnomalyEvents,
          mergeOptionTimeline,
          allStartTimestamp,
          allEndTimestamp,
          scoreTotal: R.pipe(R.map(R.prop('avgAnomalyScore')), R.sum)(newAnomalyEvents),
        },
        ...(allExpand ? newAnomalyEvents : []),
      ];
      allTimeline = [...allTimeline, ...newAnomalyEvents];
      if (allExpand) {
        expandIds.push(index);
      }
    }, events);

    // build optons
    this.buildFilterOption(allTimeline);

    groupEventList = this.sortData(groupEventList, sortBy, sortDirection);
    const filterGroupEventList = R.map((item) => {
      return { ...item, expand: false };
    }, groupEventList);
    const listHeight = R.min(groupEventList.length, UHV_LIST_MAX_SHOW_COUNT) * this.listRowHeight;

    this.setState(
      {
        groupEventList,
        filterGroupEventList,
        optionTimelineRange: groupEventList[0] ? groupEventList[0].optionTimelineRange : {},
        expandIds,
        listHeight,
        isLoading: false,
      },
      () => {
        if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
        if (this.listNode) this.listNode.forceUpdateGrid();
        this.forceUpdate();
      },
    );
  }

  @autobind
  parseData(props) {
    const { intl, anomalyEvents: events, groupId, patternId, projectName, summarySettingsMap, currentTheme } = props;
    // get events
    let eventList = events || [];

    eventList = this.mergeAnomalyEvents(eventList);

    // build timeline chart option
    const { startTime, endTime } = props;
    const rangeStartTime = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const rangeEndTime = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
    const optionTimelineRange = GlobalParse.buildOptionTimelineRange({
      rangeStartTime,
      rangeEndTime,
      isDark: currentTheme !== 'light',
    });
    let groupTimelines = [];
    eventList = R.addIndex(R.map)((item, index) => {
      const optionTimeline = GlobalParse.buildOptionTimeline({
        intl,
        rangeStartTime,
        rangeEndTime,
        item,
        summarySettingsMap,
        projectName,
        currentTheme,
      });
      groupTimelines = [...groupTimelines, ...(item.timelines ? item.timelines : [])];
      return {
        ...item,
        optionTimeline,
        isParent: false,
        isChildren: true,
        groupId,
      };
    }, eventList);

    let allStartTimestamp = Infinity;
    let allEndTimestamp = 0;

    R.forEach((event) => {
      allStartTimestamp = R.min(allStartTimestamp, event.allStartTimestamp);
      allEndTimestamp = R.max(allEndTimestamp, event.allEndTimestamp);
    }, eventList);

    // sort data
    let anomalyEvents = this.filterData(eventList);
    anomalyEvents = this.filterProject(anomalyEvents, projectName);
    anomalyEvents = this.filterPattern(anomalyEvents, patternId);

    let groupData = {};
    if (eventList[0]) {
      groupData = {
        ...eventList[0],
        timelines: groupTimelines,
      };
    }

    const mergeOptionTimeline = GlobalParse.buildOptionTimeline({
      intl,
      rangeStartTime,
      rangeEndTime,
      item: groupData,
      summarySettingsMap,
      projectName,
      currentTheme,
    });
    return { anomalyEvents, optionTimelineRange, mergeOptionTimeline, allStartTimestamp, allEndTimestamp };
  }

  @autobind
  mergeAnomalyEvents(events) {
    let newEvents = [];

    // get durationTimePair info
    R.forEach((event) => {
      const { category, rootCausesDetailsList } = event;
      if (category === 'metric') {
        R.forEach((mm) => {
          const { timePairArr } = mm;
          let durationTimePair = [];
          if (timePairArr) {
            durationTimePair = R.map((tr) => {
              const { startTimestamp, endTimestamp, duration } = tr.map || {};
              return [startTimestamp, endTimestamp, duration];
            }, get(timePairArr, 'myArrayList', []));
          }
          newEvents.push({
            ...event,
            durationTimePair,
          });
        }, rootCausesDetailsList || []);
      } else {
        newEvents.push(event);
      }
    }, events);

    // merge with mergeKey
    const eventsMap = {};
    R.forEach((event) => {
      const {
        mergeKey,
        isNewAlert,
        isImportant,
        isIgnored,
        hasRootCause,
        hasTrailing,
        leadToIncident,
        causedByChange,
        avgAnomalyScore,
        type,
        typeList,
        durationTimePair,
      } = event;
      if (!R.has(mergeKey, eventsMap)) {
        eventsMap[mergeKey] = {
          ...event,
          durationTimePair: durationTimePair || [],
          timelines: [event],
          typeList: typeList || [type],
        };
      } else {
        const newScore = (eventsMap[mergeKey]?.avgAnomalyScore || 0) + avgAnomalyScore || 0;
        eventsMap[mergeKey] = {
          ...eventsMap[mergeKey],
          isNewAlert: eventsMap[mergeKey].isNewAlert || isNewAlert,
          isImportant: eventsMap[mergeKey].isImportant || isImportant,
          isIgnored: eventsMap[mergeKey].isIgnored || isIgnored,
          hasRootCause: eventsMap[mergeKey].hasRootCause || hasRootCause,
          hasTrailing: eventsMap[mergeKey].hasTrailing || hasTrailing,
          leadToIncident: eventsMap[mergeKey].leadToIncident || leadToIncident,
          causedByChange: eventsMap[mergeKey].causedByChange || causedByChange,
          typeList: [...eventsMap[mergeKey].typeList, type],
          avgAnomalyScore: newScore,
          anomalyColor: returnColor(newScore),

          durationTimePair: [...eventsMap[mergeKey].durationTimePair, ...(durationTimePair || [])],
          timelines: [...eventsMap[mergeKey].timelines, event],
        };
      }
    }, newEvents || []);
    newEvents = R.values(eventsMap);

    // merge timelines and get time/duration info
    newEvents = R.map((event) => {
      const { durationTimePair, timelines } = event;

      let duration = 0;
      let allDuration = 0;
      if (durationTimePair && durationTimePair.length > 0) {
        R.forEach((tr) => {
          const [st, et, dur] = tr;
          allDuration += dur || et - st;
        }, durationTimePair);
        duration = round(allDuration / durationTimePair.length);
      } else {
        duration = R.reduce(
          R.add,
          0,
          R.map((item) => item.endTimestamp - item.startTimestamp, timelines),
        );
      }

      let allStartTimestamp = Infinity;
      let allEndTimestamp = 0;
      R.forEach((event) => {
        allStartTimestamp = R.min(allStartTimestamp, event.startTimestamp);
        allEndTimestamp = R.max(allEndTimestamp, event.endTimestamp);
      }, timelines);

      return {
        ...event,
        timelines: R.sortWith([R.ascend(R.prop('startTimestamp'))], timelines),
        duration,
        allStartTimestamp,
        allEndTimestamp,
      };
    }, newEvents);

    return newEvents;
  }

  @autobind
  buildFilterOption(eventList) {
    const { location, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};
    const instanceProjectMap = {};
    R.forEach((item) => {
      const { onlyInstanceString, projectName, projectOwner } = item;
      if (!R.has(onlyInstanceString, instanceProjectMap)) {
        const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, onlyInstanceString, {
          pn: projectName,
          owner: projectOwner,
        });
        instanceProjectMap[onlyInstanceString] = {
          value: onlyInstanceString,
          label: instanceDisplayName || onlyInstanceString,
          projectNames: [],
        };
      }
      instanceProjectMap[onlyInstanceString].projectNames.push(projectName);
    }, eventList);
    let instanceOptions = R.values(instanceProjectMap);
    instanceOptions = R.uniqWith(R.eqBy(R.prop('label')), instanceOptions);

    const patternProjectMap = {};
    R.forEach((item) => {
      const { patternId, onlyInstanceString, projectName } = item;
      if (!R.has(patternId, patternProjectMap)) {
        patternProjectMap[patternId] = {
          value: patternId,
          projectNames: [],
          instances: [],
        };
      }
      patternProjectMap[patternId].projectNames.push(projectName);
      patternProjectMap[patternId].instances.push(onlyInstanceString);
    }, eventList);
    let patternOptions = R.values(patternProjectMap);
    patternOptions = R.uniqWith(R.eqBy(R.prop('value')), patternOptions);

    const metricProjectMap = {};
    R.forEach((item) => {
      const { category, metricNameString, onlyInstanceString, projectName } = item;
      if (category === 'metric') {
        if (!R.has(metricNameString, metricProjectMap)) {
          metricProjectMap[metricNameString] = {
            value: metricNameString,
            projectNames: [],
            instances: [],
          };
          metricProjectMap[metricNameString].projectNames.push(projectName);
          metricProjectMap[metricNameString].instances.push(onlyInstanceString);
        }
      }
    }, eventList);
    let metricOptions = R.values(metricProjectMap);
    metricOptions = R.map((item) => {
      return { ...item, projectNames: R.uniq(item.projectNames), instances: R.uniq(item.instances) };
    }, metricOptions);
    metricOptions = R.uniqWith(R.eqBy(R.prop('value')), metricOptions);
    metricOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], metricOptions);

    this.instanceOptions = instanceOptions;
    this.patternOptions = patternOptions;
    this.metricOptions = metricOptions;
  }

  @autobind
  filterData(eventList) {
    const { filterInstance, filterPattern, filterMetric, filterLog } = this.state;
    let filterList = eventList || [];
    if (filterInstance) {
      filterList = R.filter((item) => {
        return item.onlyInstanceString.includes(filterInstance);
      }, filterList);
    }
    if (!R.isNil(filterPattern)) {
      filterList = R.filter((item) => item.patternId === Number(filterPattern), filterList);
    }
    if (filterMetric) {
      filterList = R.filter(
        (item) => item.category === 'metric' && item.metricNameString.includes(filterMetric),
        filterList,
      );
    }
    if (filterLog) {
      filterList = R.filter((item) => {
        return item.typeList && item.typeList.includes(filterLog);
      }, filterList);
    }
    return filterList;
  }

  @autobind
  filterProject(eventList, projectName) {
    return R.filter((item) => item.projectName === projectName, eventList);
  }

  @autobind
  filterPattern(eventList, patternId) {
    return R.filter((item) => item.patternId === patternId, eventList);
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    const sortList = [];

    // sort by
    let sortFunctions = [
      R.descend(R.prop('isImportant')),
      R.descend(R.prop('scoreTotal')),
      R.descend(R.prop('avgAnomalyScore')),
    ];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    const parentData = R.filter((item) => item.isParent, eventList);
    const childrenData = R.filter((item) => item.isChildren, eventList);
    let sortListParent = [];
    if (sortBy === 'avgAnomalyScore' && sortDirection && sortDirection !== 'NA') {
      sortListParent = R.sortWith(
        sortDirection === 'DESC' ? [R.descend(R.prop('scoreTotal'))] : [R.ascend(R.prop('scoreTotal'))],
      )(parentData);
    } else {
      sortListParent = R.sortWith(sortFunctions)(parentData);
    }

    const sortListChildren = R.sortWith(sortFunctions)(childrenData);
    R.forEach((item) => {
      const { groupId, children } = item;
      const relationChildren = R.filter((item) => item.groupId === groupId, sortListChildren);
      sortList.push({ ...item, children: R.sortWith(sortFunctions)(children) });
      R.forEach((child) => {
        sortList.push(child);
      }, relationChildren);
    }, sortListParent);
    return sortList;
  }

  @autobind
  onChangeFilterField(fieldName, fieldValue) {
    const newState = { page: 1 };
    if (fieldName === 'filterInstance') {
      newState.filterPattern = null;
    }
    const { events } = this.props;
    const { expandIds } = this.state;

    // eslint-disable-next-line
    this.setState({ [fieldName]: fieldValue, ...newState }, () => {
      const groupEventList = this.handleSetEvents(events, expandIds, this.props);
      this.setState({ groupEventList, filterGroupEventList: groupEventList }, () => {
        this.cellMeasureCache.clearAll();
        if (this.listNode) this.listNode.forceUpdateGrid();
        this.forceUpdate();
      });
    });
  }

  @autobind
  handleSetEvents(events, expandIds, props) {
    const { sortBy, sortDirection } = this.state;
    let groupEventList = [];
    R.addIndex(R.forEach)((item, index) => {
      const anomalyEvents = get(item, 'timelines', []);
      const { patternId } = item;
      const {
        anomalyEvents: newAnomalyEvents,
        optionTimelineRange,
        mergeOptionTimeline,
        allStartTimestamp,
        allEndTimestamp,
      } = this.parseData({
        ...props,
        anomalyEvents,
        groupId: index,
        patternId,
      });
      if (newAnomalyEvents.length > 0) {
        groupEventList = [
          ...groupEventList,
          {
            ...item,
            expand: expandIds.includes(index),
            isParent: true,
            isChildren: false,
            groupId: index,
            count: newAnomalyEvents.length,
            optionTimelineRange,
            children: newAnomalyEvents,
            mergeOptionTimeline,
            allStartTimestamp,
            allEndTimestamp,
            scoreTotal: R.pipe(R.map(R.prop('avgAnomalyScore')), R.sum)(newAnomalyEvents),
          },
        ];
        if (expandIds.includes(index)) {
          groupEventList = [...groupEventList, ...newAnomalyEvents];
        }
      }
    }, events);
    groupEventList = this.sortData(groupEventList, sortBy, sortDirection);
    return groupEventList;
  }

  @autobind
  renderMetricType(rowData) {
    const metricType = rowData?.metricRootCauseJson?.metricType || '';
    return (
      <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        {metricType.toLowerCase() === 'unknown' || metricType === '' ? '--' : metricType}
      </div>
    );
  }

  @autobind
  renderAnomalyListItem(events) {
    const { currentTheme, isMetric, activeLineIconKey, changeActiveLineIconKey } = this.props;
    return ({ key, index: rowIndex, style, parent }) => {
      const item = events[rowIndex];
      const { isParent: nextIsParent } = events[rowIndex + 1] || {};
      if (!item) return null;
      const { isParent, isChildren, expand } = item;
      const { mergeKey } = item;

      const themeBorderColor = 'var(--virtualized-table-parent-row-border)';
      const parentBorder = `1px solid ${themeBorderColor}`;

      const childrenBorder = {
        borderBottom: nextIsParent ? 'none' : 'var(--virtualized-table-border-color) 1px solid',
        height: '100%',
      };

      const active = mergeKey === activeLineIconKey;

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          {isParent && (
            <div
              className="event-list-row clear-bold"
              style={{
                ...style,
                minHeight: this.listRowHeight,
                borderTop: parentBorder,
                borderBottom: expand
                  ? 'var(--virtualized-table-border-color) 1px solid'
                  : events.length - 1 === rowIndex
                  ? parentBorder
                  : 'none',
              }}
              onClick={() => this.handleParentHeaderClick(item)}
            >
              <div className="row-column" style={{ width: 40 }} />
              <div className="row-column" style={{ width: 90, padding: 0 }}>
                {this.scoreTotalRender(item)}
              </div>
              <div className="row-column" style={{ width: 100 }}>
                {this.renderPatternId(item)}
              </div>
              <div className="row-column" style={{ width: 220, paddingRight: 16 }}>
                {this.renderInstanceContent(item, isParent)}
              </div>
              <div className="row-column" style={{ width: 300, paddingRight: 16 }} />
              <div className="row-column" style={{ width: 100 }} />
              <div
                className="row-column"
                style={{ width: 90, padding: '0 6px', display: 'inline-block', textAlign: 'left' }}
              />
              <div className="row-column" style={{ width: 100, flex: 1, height: '100%' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                  {item.mergeOptionTimeline && (
                    <EChart
                      width="100%"
                      height={this.listRowHeight}
                      option={item.mergeOptionTimeline}
                      onEvents={{}}
                      theme={currentTheme}
                      style={{ position: 'relative', zIndex: 2 }}
                      key={currentTheme}
                    />
                  )}
                  <div
                    style={{
                      background: 'var(--virtualized-table-row-chart-bg)',
                      position: 'absolute',
                      left: 20,
                      right: 20,
                      top: 0,
                      bottom: 0,
                    }}
                  />
                </div>
              </div>
              <div className="row-column" style={{ width: 80 }} />
              <div className="row-column" style={{ width: 60, padding: 0, justifyContent: 'center' }}>
                {this.renderCount(item)}
              </div>
              <div className="row-column" style={{ width: 24, padding: 0, justifyContent: 'center' }}>
                {this.renderParentControl(item)}
              </div>
            </div>
          )}
          {isChildren && (
            <div
              className={`event-list-row clear-bold ${active ? 'active' : ''}`}
              style={{ ...style, borderBottom: 'none', minHeight: this.listRowHeight }}
              onClick={() => changeActiveLineIconKey(mergeKey)}
            >
              <div className="row-column" style={{ width: 40 }}>
                {this.renderImportantIcon(item, rowIndex)}
              </div>
              <div className="row-column" style={{ width: 90, padding: 0, ...childrenBorder }}>
                {this.scoreRender(item)}
              </div>
              <div className="row-column" style={{ width: 100 }}>
                {this.renderPredcition(item, rowIndex)}
              </div>
              <div className="row-column" style={{ width: 220, ...childrenBorder, paddingRight: 16 }}>
                {this.renderInstanceContent(item)}
              </div>
              <div className="row-column" style={{ width: 300, ...childrenBorder, paddingRight: 16 }}>
                {this.renderLogContent(item)}
              </div>
              <div className="row-column" style={{ width: 100, ...childrenBorder }}>
                {this.renderDuration(item)}
              </div>
              <div
                className="row-column"
                style={{ width: 90, padding: '0 6px', display: 'inline-block', textAlign: 'left', ...childrenBorder }}
              >
                {!isMetric ? this.renderCategory(item) : this.renderMetricType(item)}
              </div>

              <div
                className="row-column"
                style={{
                  width: 100,
                  flex: 1,
                  height: '100%',
                  ...childrenBorder,
                }}
              >
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                  {item.optionTimeline && (
                    <EChart
                      width="100%"
                      height={this.listRowHeight}
                      option={item.optionTimeline}
                      onEvents={{}}
                      theme={currentTheme}
                      style={{ position: 'relative', zIndex: 2 }}
                      key={currentTheme}
                    />
                  )}
                  <div
                    style={{
                      background: 'var(--virtualized-table-row-chart-bg)',
                      position: 'absolute',
                      left: 20,
                      right: 20,
                      top: 0,
                      bottom: 0,
                    }}
                  />
                </div>
              </div>
              <div
                className="row-column"
                style={{ width: 80, display: 'inline-flex', justifyContent: 'end', ...childrenBorder }}
              >
                {this.renderControl(item)}
              </div>
              <div className="row-column" style={{ width: 60, padding: 0, justifyContent: 'center' }}>
                {this.statusRender(item)}
              </div>
              <div className="row-column" style={{ width: 24, padding: 0, justifyContent: 'center' }} />
            </div>
          )}
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderCount(rowData) {
    const { count } = rowData;
    return <div style={{ marginRight: 10, fontWeight: 'bold', fontSize: 14 }}>{count}</div>;
  }

  renderParentControl(rowData) {
    const { expand } = rowData;
    return <div style={{ fontWeight: 'bold', fontSize: 14 }}>{expand ? <UpOutlined /> : <DownOutlined />}</div>;
  }

  @autobind
  renderPatternId(rowData) {
    const { intl } = this.props;
    const { patternId, patternName } = rowData;
    return (
      <Popover
        title={null}
        mouseEnterDelay={0.3}
        placement="right"
        content={
          <div style={{ minWidth: 100, maxHeight: 350, overflow: 'overlay', wordBreak: 'break-all', paddingRight: 10 }}>
            <div className="flex-row">
              <div className="light-label bold" style={{ width: 60, flexShrink: 0 }}>
                {intl.formatMessage(appFieldsMessages.pattern)}:
              </div>
              {`[${patternId}]${String(patternId || '') !== patternName ? ` ${patternName}` : ''}`}
            </div>
          </div>
        }
      >
        <div className="flex-grow hidden-line-with-ellipsis">
          <div className="hidden-line-with-ellipsis">{`[${patternId}]`}</div>
          {String(patternId || '') !== patternName && <div className="hidden-line-with-ellipsis">{patternName}</div>}
        </div>
      </Popover>
    );
  }

  @autobind
  renderImportantIcon(rowData, rowIndex) {
    const { intl, userInfo } = this.props;
    const { isImportant } = rowData;
    return (
      <Popover
        title={null}
        content={
          userInfo.isReadUser
            ? intl.formatMessage(eventMessages.isReadUserDisable)
            : intl.formatMessage(isImportant ? eventMessages.removeImportantFlag : eventMessages.markAsImportant)
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <ExclamationCircleFilled
          className={`${isImportant ? 'isImportantColor' : 'hover-color'}`}
          style={{ color: '#e6e6e6', fontSize: 16, marginLeft: 5 }}
          onClick={(event) => {
            // event.stopPropagation();
            if (!userInfo.isReadUser) this.handleIgnoreClick({ event: rowData, category: 'important', rowIndex });
          }}
        />
      </Popover>
    );
  }

  @autobind
  renderPredcition(rowData, rowIndex) {
    const { isPredictedAnomalies } = rowData;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {isPredictedAnomalies && (
          <span
            style={{
              background: '#808080',
              color: 'white',
              display: 'inline-block',
              width: 17,
              height: 17,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            P
          </span>
        )}
      </div>
    );
  }

  @autobind
  handleImportantSumbit(requestData) {
    const { intl } = this.props;
    return fetchPost(getEndpoint('events', 1), requestData, {}, false)
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  }

  @autobind
  statusRender(rowData) {
    const { intl, timezoneOffset, userInfo } = this.props;
    const { isIgnored, rootCauseResultInfo, reporterRecordMap } = rowData;
    const causedByChange = get(rootCauseResultInfo, 'causedByChangeEvent');
    let reporterRecordSet = [...get(reporterRecordMap, 'ignore', []), ...get(reporterRecordMap, 'unIgnore', [])];
    reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))], reporterRecordSet);

    return (
      <div className="flex-row flex-wrap">
        <EventRenderers.RenderPatternReporterTooltip
          intl={intl}
          placement="left"
          isReadUser={userInfo.isReadUser}
          actionName={
            isIgnored
              ? intl.formatMessage(eventMessages.markedAsIgnored)
              : intl.formatMessage(eventMessages.markedAsUnignored)
          }
          timezoneOffset={timezoneOffset}
          reporterRecordSet={reporterRecordSet}
        >
          {isIgnored ? (
            <EyeInvisibleOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }}
              onClick={(event) => {
                // event.stopPropagation();
                if (!userInfo.isReadUser) this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          ) : (
            <EyeOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                // event.stopPropagation();
                if (!userInfo.isReadUser) this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          )}
        </EventRenderers.RenderPatternReporterTooltip>
        {causedByChange && (
          <Popover
            content={
              <div>
                <div>{intl.formatMessage(DashboardMessages.causedByChangeAnomaly)}</div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="top"
          >
            <ChangeEventIcon
              style={{ color: 'orange', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                // event.stopPropagation();
                this.handleRCAJumpClick(rowData);
              }}
            />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  scoreTotalRender(rowData) {
    const { scoreTotal } = rowData;

    return (
      <Tag color={returnColor(scoreTotal)}>{numeral(scoreTotal).format(scoreTotal > 1 ? '0,0' : '0,0.[0000]')}</Tag>
    );
  }

  @autobind
  scoreRender(rowData) {
    const { avgAnomalyScore, anomalyColor } = rowData;
    return (
      <Tag color={anomalyColor}>{numeral(avgAnomalyScore).format(avgAnomalyScore > 1 ? '0,0' : '0,0.[0000]')}</Tag>
    );
  }

  @autobind
  renderInstanceContent(rowData, isParent) {
    const { intl, location, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};
    const {
      componentName,
      onlyInstanceString,
      containerName,
      projectOwner,
      projectDisplayName,
      projectName,
      metricRootCauseJson,
    } = rowData;

    const instanceListStr = onlyInstanceString || metricRootCauseJson?.instanceName;

    const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, instanceListStr, {
      pn: projectName,
      owner: projectOwner,
    });
    const width = instanceDisplayName ? 140 : 80;

    return (
      <Popover
        title={null}
        content={
          <div style={{ maxWidth: 350, maxHeight: 300, overflowY: 'auto' }}>
            {isParent && (
              <>
                <div style={{ wordBreak: 'break-all' }}>
                  <span className="light-label bold inline-block" style={{ width }}>
                    {intl.formatMessage(appFieldsMessages.project)}:
                  </span>
                  <span>{projectDisplayName || projectName}</span>
                </div>
                <div style={{ wordBreak: 'break-all' }}>
                  <span className="light-label bold inline-block" style={{ width }}>
                    {intl.formatMessage(appFieldsMessages.component)}:
                  </span>
                  <span>{componentName}</span>
                </div>
              </>
            )}
            {!isParent && (
              <>
                {containerName && (
                  <div style={{ wordBreak: 'break-all' }}>
                    <span className="light-label bold inline-block" style={{ width }}>
                      {intl.formatMessage(appFieldsMessages.container)}:
                    </span>
                    <span>{containerName}</span>
                  </div>
                )}
                <div style={{ wordBreak: 'break-all' }}>
                  <span className="light-label bold inline-block" style={{ width }}>
                    {intl.formatMessage(appFieldsMessages.instance)}:
                  </span>
                  <span>{instanceListStr}</span>
                </div>
                {instanceDisplayName && (
                  <div style={{ wordBreak: 'break-all' }}>
                    <span className="light-label bold inline-block" style={{ width: 140 }}>
                      {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                    </span>
                    <span>{instanceDisplayName}</span>
                  </div>
                )}
              </>
            )}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <div className="overflow-hidden">
          {!isParent && containerName && <div className="hidden-line-with-ellipsis">{containerName}</div>}
          <div className="hidden-line-with-ellipsis">
            {isParent ? componentName : instanceDisplayName || instanceListStr}
          </div>
        </div>
      </Popover>
    );
  }

  @autobind
  renderLogContent(rowData) {
    const { intl, currentTheme, summarySettingsMap = {} } = this.props;
    const {
      category,
      containerName,
      rawData,
      anomalyWords,
      outlierValue,
      rootCauseJson,
      allStartTimestamp,
      allEndTimestamp,
      isParent,
      projectName,
    } = rowData;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error);
    }
    const rootCauseDetailsArr = get(rootCauseJson, ['rootCauseDetailsArr'], []);
    const settings = summarySettingsMap[projectName] || [];
    return (
      <div className="full-width flex-col">
        {category === 'metric' && (
          <Popover
            title={null}
            content={
              <div style={{ maxWidth: 350, maxHeight: 300, overflowY: 'auto', wordBreak: 'break-all' }}>
                {isParent && (
                  <div style={{ wordBreak: 'break-all', display: 'flex', alignItems: 'center' }}>
                    <span className="light-label bold inline-block" style={{ width: 80 }}>
                      {intl.formatMessage(appFieldsMessages.type)}:
                    </span>
                    {CellRenderers.logTypeRenderer({
                      intl,
                      rowData,
                      isPrediction: rowData.isPrediction,
                      showAbbr: false,
                    })}
                  </div>
                )}
                {containerName && (
                  <div style={{ wordBreak: 'break-all' }}>
                    <span className="light-label bold inline-block" style={{ width: 80 }}>
                      {intl.formatMessage(appFieldsMessages.container)}:
                    </span>
                    <span>{containerName}</span>
                  </div>
                )}
                <div style={{ wordBreak: 'break-all' }}>
                  <span className="light-label bold inline-block" style={{ width: 80 }}>
                    {intl.formatMessage(appFieldsMessages.startTime)}:
                  </span>
                  <span>{moment.utc(allStartTimestamp).format(Defaults.ShortTimeFormat)}</span>
                </div>
                <div style={{ wordBreak: 'break-all' }}>
                  <span className="light-label bold inline-block" style={{ width: 80 }}>
                    {intl.formatMessage(appFieldsMessages.endTime)}:
                  </span>
                  <span>{moment.utc(allEndTimestamp).format(Defaults.ShortTimeFormat)}</span>
                </div>
                {R.addIndex(R.map)(
                  (event, index) =>
                    EventRenderers.RenderMetricAnomalySummary({
                      intl,
                      // category: 'shortDisplay',
                      event,
                      index,
                      labelMinWidth: 70,
                    }),
                  rootCauseDetailsArr || [],
                )}
              </div>
            }
            mouseEnterDelay={0.3}
            placement="right"
          >
            {/* {containerName && (
              <div className="hidden-line-with-ellipsis">
                <span className="bold light-label inline-block" style={{ minWidth: 70 }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </span>
                <span>{containerName}</span>
              </div>
            )} */}
            {EventRenderers.RenderMetricAnomalySummary({
              intl,
              // category: 'shortDisplay',
              event: rootCauseDetailsArr[0],
              withEllipsis: true,
              labelMinWidth: 70,
            })}
          </Popover>
        )}
        {category === 'log' && (
          <Popover
            title={null}
            content={
              <div className="flex-col overflow-y-auto" style={{ maxWidth: 480, maxHeight: 350, paddingRight: 8 }}>
                {isParent && (
                  <div style={{ wordBreak: 'break-all', marginBottom: 12, display: 'flex', alignItems: 'center' }}>
                    <span className="light-label bold inline-block" style={{ marginRight: 10 }}>
                      {intl.formatMessage(appFieldsMessages.type)}:
                    </span>
                    {CellRenderers.logTypeRenderer({
                      intl,
                      rowData,
                      isPrediction: rowData.isPrediction,
                      showAbbr: false,
                    })}
                  </div>
                )}
                {containerName && (
                  <div style={{ wordBreak: 'break-all', marginBottom: 12 }}>
                    <span className="light-label bold inline-block" style={{ width: 70 }}>
                      {intl.formatMessage(appFieldsMessages.container)}:
                    </span>
                    <span>{containerName}</span>
                  </div>
                )}
                {anomalyWords && anomalyWords.length > 0 && (
                  <LogRenderers.RenderAnomalyWords
                    style={{ wordBreak: 'break-all', marginBottom: 12 }}
                    anomalyWordList={anomalyWords}
                  />
                )}
                {outlierValue && !R.isEmpty(outlierValue) && (
                  <LogRenderers.RenderOutlierValue
                    style={{ wordBreak: 'break-all', marginBottom: 12 }}
                    outlierValue={outlierValue}
                  />
                )}
                {settings.length > 0 &&
                  R.addIndex(R.map)((s, idx) => {
                    const v = get(rawDataJson, s.replaceAll('->', '.'));
                    if (!v) return null;
                    return (
                      <div
                        key={s}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 4,
                        }}
                      >
                        <Tag color="processing" style={{ marginRight: 2, padding: '0 2px' }}>
                          {s}
                        </Tag>
                        <div
                          className="hidden-line-with-ellipsis"
                          style={{ paddingRight: 8, display: 'inline', overflow: 'hidden', textOverflow: 'ellipsis' }}
                          dangerouslySetInnerHTML={{ __html: v }}
                        />
                      </div>
                    );
                  }, settings)}
                <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                  {!rawDataJson && (
                    <CopyTextContent
                      content={R.join(
                        '\n',
                        R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                      )}
                    />
                  )}
                  {!rawDataJson &&
                    R.join(
                      '\n',
                      R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                    )}
                  {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                </div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="right"
          >
            {/* {containerName && (
              <div className="hidden-line-with-ellipsis">
                <span className="bold light-label inline-block" style={{ minWidth: 70 }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </span>
                <span>{containerName}</span>
              </div>
            )} */}
            <>
              {rawDataJson && summarySettingsMap[projectName] && summarySettingsMap[projectName].length > 0 && (
                <LogRenderers.RenderLogContent
                  intl={intl}
                  rawData={rawData}
                  rawDataJson={rawDataJson}
                  owner={rowData}
                  summarySettings={summarySettingsMap[projectName] || []}
                  enableExpansion={false}
                  summarySettingsOnlyRow
                />
              )}
              {!(rawDataJson && summarySettingsMap[projectName] && summarySettingsMap[projectName].length > 0) && (
                <span className="max-width inline-block hidden-line-with-ellipsis">{rawData}</span>
              )}
            </>
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  renderDuration(rowData) {
    const { intl } = this.props;
    const { duration } = rowData;
    return (
      <Popover
        content={CellRenderers.humanizeDuration({
          period: duration,
          intl,
          showSeconds: true,
          showSecondsWithZeroMin: true,
        })}
      >
        <div className="full-height hidden-line-with-ellipsis" style={{ lineHeight: 3 }}>
          {CellRenderers.humanizeDuration({
            period: duration,
            intl,
            showSeconds: true,
            showSecondsWithZeroMin: true,
          })}
        </div>
      </Popover>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { intl, credentials, systemInfo } = this.props;
    const ownerUserName = get(systemInfo, 'ownerUserName');

    const { category, patternName, patternId, anomalyLogInstance, projectOwner, startTimestamp } = rowData;
    let { projectName } = rowData;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;

    const RendererPatternTitle = ({ rowData }) => {
      const { patternNameMap } = this.state;

      const [patternName, setPatternName] = useState(get(patternNameMap, [key, patternId]));
      const [loading, setLoading] = useState(false);
      useEffect(() => {
        if (patternName === undefined) {
          setLoading(true);
          this.props.updateLastActionInfo();
          fetchGet(getEndpoint('getpatternname', 1), {
            ...credentials,
            customerName: ownerUserName,
            projectName,
            instanceName: category === 'log' ? anomalyLogInstance : null,
            patternId,
            startTime: startTimestamp,
          })
            .then((data) => {
              const { patternName } = data;
              if (!patternNameMap[key]) patternNameMap[key] = {};
              patternNameMap[key][patternId] = patternName || '';

              this.setState({ patternNameMap });
              setLoading(false);
              setPatternName(patternName || '');
              this.forceUpdate();
            })
            .catch((err) => {
              message.error(intl.formatMessage(appMessages.apiFaild));
              console.log(err);
            });
        }
      }, []);
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName, patternId },
        { hasFullName: true, hasPrefix: true },
      );
      return (
        <div>
          {loading && <Spin size="small" />}
          {!loading && <div>{patternNameStr}</div>}
        </div>
      );
    };

    return (
      <div className="max-width flex-row flex-center-align">
        <Popover content={<RendererPatternTitle rowData={rowData} />} mouseEnterDelay={0.3} placement="top">
          <div className="hidden-line-with-ellipsis">
            {
              Defaults.PatternIdNameStr(
                { patternName: get(this.state.patternNameMap, [key, patternId]) || patternName, patternId },
                { hasFullName: true, hasPrefix: true },
              ).patternNameStr
            }
          </div>
        </Popover>
        <Popover content={intl.formatMessage(eventMessages.setPatternName)} mouseEnterDelay={0.3} placement="top">
          <EditOutlined
            className="primary-color"
            style={{ marginLeft: 2 }}
            onClick={() => this.handleActionClick(rowData, 'setPatternName')}
          />
        </Popover>
      </div>
    );
  }

  @autobind
  renderTrace(rawData, rawDataJson) {
    const { outlierValue, componentName: componentListStr, id } = rawData;
    const traceId = get(rawDataJson, 'trace_id');
    const parentId = get(rawDataJson, 'parent_span_id');
    const spanId = get(rawDataJson, 'span_id');
    const serviceName = get(rawDataJson, ['attributes', 'http.server_name']) || componentListStr;
    const name = get(rawDataJson, 'method_name');
    const startTime = get(rawDataJson, 'start_time');
    const duration = Number(get(rawDataJson, 'duration', 0)) * 1000;
    const timestamp = moment.utc(startTime).valueOf() * 1000;
    const traceInfo = [
      {
        traceId: id,
        parentId,
        id: spanId,
        name,
        timestamp,
        duration,
        serviceName,
        color: '#ffccc7',
        tags: {},
      },
    ];

    return (
      <div className="flex-col" style={{ width: 650, flexShrink: 0 }} onClick={(e) => e.stopPropagation()}>
        <div className="trace-timelines">
          <ThundraTraceChart
            traceId={id}
            traceSummary={traceInfo}
            showHeader={false}
            showMiniTrace={false}
            showSpanDetailTitle={false}
            showSpanDetail={false}
          />
        </div>
        <div className="flex-col">
          <div className="flex-col" style={{ marginBottom: 8 }}>
            <div className="flex-row" style={{ wordBreak: 'break-word' }}>
              <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
                Service Name:
              </span>
              <span style={{ marginLeft: 8 }}>{serviceName}</span>
            </div>
            <div className="flex-row" style={{ wordBreak: 'break-word' }}>
              <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
                Span Info:
              </span>
              <span style={{ marginLeft: 8 }}>{name}</span>
            </div>
            {outlierValue && (
              <LogRenderers.RenderOutlierValue
                className="flex-row"
                style={{ wordBreak: 'break-word' }}
                outlierValue={outlierValue}
                isTrace
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  traceRenderer(rowData) {
    const { category, rawData, isTrace } = rowData;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error)
    }

    if (category !== 'log' || !isTrace || !rawDataJson) {
      return <div />;
    }

    return (
      <Popover
        placement="left"
        content={
          <div className="overflow-y-auto overflow-x-auto" style={{ maxWidth: 650, maxHeight: 350 }}>
            {category === 'log' && isTrace && rawDataJson && this.renderTrace(rowData, rawDataJson)}
          </div>
        }
        trigger={['click', 'hover']}
      >
        <div className="flex-row flex-center-align" style={{ minHeight: '100%' }}>
          <AimOutlined style={{ fontSize: 18 }} />
        </div>
      </Popover>
    );
  }

  @autobind
  renderCategory(rowData) {
    const { intl } = this.props;
    return (
      <div className="full-width flex-row" style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        {CellRenderers.logTypeRenderer({ intl, rowData, isPrediction: rowData.isPrediction, showAbbr: true })}
      </div>
    );
  }

  @autobind
  renderControl(event) {
    const { intl, userInfo } = this.props;
    const { category, typeList, neuronId } = event;
    return (
      <Dropdown
        name="Actions"
        itemClick={({ key }) => {
          switch (key) {
            case 'setPatternName':
              this.handleActionClick(event, 'setPatternName');
              break;
            case 'takeAction':
              this.handleEventActionClick(event);
              break;
            case 'editTriageReport':
              this.handleTriageReportClick({ event });
              break;
            case 'rootCauseAnalysis':
              this.handleRCAJumpClick(event);
              break;
            case 'lineChart':
              this.handleDetailsClick(event);
              break;
            case 'logAnalysis':
              this.handleDetailsClick(event);
              break;
            case 'originalNormalPattern':
              this.handleOriginalNormalPatternClick(event);
              break;
            case 'important':
              this.handleIgnoreClick({ event, category: key });
              break;
            case 'ignore':
              this.handleIgnoreClick({ event, category: key });
              break;
            case 'reportJira':
              this.handleReportJiraClick(event);
              break;
            case 'reportServiceNow':
              this.handleReportServiceNowClick(event);
              break;
            default:
              break;
          }
        }}
      >
        <>
          {category === 'metric' && (
            <Menu.Item key="lineChart">{intl.formatMessage(DashboardMessages.lineChart)}</Menu.Item>
          )}
          {category !== 'metric' && (
            <Menu.Item key="logAnalysis">{intl.formatMessage(appMenusMessages.logAnalysis)}</Menu.Item>
          )}
          {category !== 'metric' && typeList.includes('rare') && isNumber(neuronId) && (
            <Menu.Item key="originalNormalPattern">
              {intl.formatMessage(DashboardMessages.originalNormalPattern)}
            </Menu.Item>
          )}
          <Menu.Item key="rootCauseAnalysis">{intl.formatMessage(appMenusMessages.globalSystemRootCause)}</Menu.Item>
          {/* <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item> */}
          {/* <Menu.Item key="takeAction">{intl.formatMessage(eventMessages.takeAction)}</Menu.Item> */}
          <Menu.Item key="editTriageReport">{intl.formatMessage(eventMessages.editTriageReport)}</Menu.Item>
          <Menu.Item key="reportJira" disabled={userInfo.isReadUser}>
            <Popover
              content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
              zIndex={10001}
            >
              {intl.formatMessage(eventMessages.reportJira)}
            </Popover>
          </Menu.Item>
          <Menu.Item key="reportServiceNow" disabled={userInfo.isReadUser}>
            <Popover
              content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
              zIndex={10001}
            >
              {intl.formatMessage(eventMessages.reportServiceNow)}
            </Popover>
          </Menu.Item>
        </>
      </Dropdown>
    );
  }

  @autobind
  handleParentHeaderClick(rowData) {
    const { groupId: currentGroupId } = rowData;
    const { events } = this.props;

    let { expandIds = [] } = this.state;
    if (expandIds.includes(currentGroupId)) {
      expandIds = R.filter((item) => item !== currentGroupId, expandIds);
    } else {
      expandIds.push(currentGroupId);
    }
    const allExpand = expandIds.length === events.length;
    this.updateEventList(expandIds, { allExpand });
  }

  @autobind
  handleActionClick(event, actionName) {
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const activeEvent = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    this.setState({ showTakeLogActionModal: true, actionName, activeEvent });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    const { systemInfo } = this.props;
    this.setState({ showTakeLogActionModal: false, actionName: null, activeEvent: null, patternNameMap: {} }, () => {
      this.props.onClose([systemInfo.id], true);
    });
  }

  @autobind
  handleEventActionClick(incident) {
    this.setState({ activeEvent: incident, showEventActionModal: true });
  }

  @autobind
  handleTriageReportClick({ event }) {
    this.setState({ showTriageReportModal: true, activeEvent: event });
  }

  @autobind
  getProjectNameList() {
    const { systemsMap, systemInfo: globalSystemInfo } = this.props;
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === globalSystemInfo.id, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  handleRCAJumpClick(event) {
    const { environmentId, customerName, systemInfo } = this.props;
    const { isIncident, isTrace, category, type, projectName, patternId, startTimestamp, instanceList, metricList } =
      event;
    const startTime = moment.utc(startTimestamp).format(Defaults.DateFormat);

    const query = {
      environmentId,
      customerName,
      systemId: systemInfo.id,
      startTime,
      endTime: startTime,

      eventCategory: isIncident ? 'incident' : category === 'metric' ? 'metric' : isTrace ? 'trace' : 'log',
      eventPatternType: isIncident ? 'incident' : category === 'metric' ? 'metric' : type,
      eventProjectName: projectName,
      eventPatternId: patternId,
      eventInstanceName: instanceList[0],
      eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
      eventTimestamp: startTimestamp,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  }

  @autobind
  handleDetailsClick(event) {
    const { userInfo, projects, environmentId, customerName, systemInfo } = this.props;
    const {
      projectOwner,
      category,
      startTimestamp,
      endTimestamp,
      anomalyLogInstance,
      instanceList,
      metricList,
      type,
      patternId,
    } = event;

    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const startTimeObj = moment.utc(startTimestamp).startOf('day');
    const endTimeObj = moment.utc(endTimestamp).endOf('day');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    if (category === 'metric') {
      const query = {
        startTime: startTimeObj.format(Defaults.DateFormat),
        endTime: endTimeObj.format(Defaults.DateFormat),
        customerName,
        environmentId,
        systemId: systemInfo.id,

        projectName,
        instanceGroup,
        modelType,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        justInstanceList: R.join(',', instanceList),
        justSelectMetric: R.join(',', metricList),
        // withBaseline: true,
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    } else {
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const query = {
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        activeTab: type === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: project?.owner || projectOwner,
        anomalyType: type.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleOriginalNormalPatternClick(event) {
    const { userInfo, projects } = this.props;
    const { projectOwner, startTimestamp, anomalyLogInstance, instanceList, neuronId, type } = event;

    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const query = {
      projectName,
      instanceName: anomalyLogInstance || instanceList[0],
      startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      activeTab: 'clusters',
      activePatternId: neuronId,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project?.owner || projectOwner,
      anomalyType: type.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  handleIgnoreClick({ event, category, rowIndex }) {
    const { intl } = this.props;
    const { isIgnored, isImportant, patternName, patternId } = event;
    const flag = (category === 'ignore' && isIgnored) || (category === 'important' && isImportant);
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: flag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          }),
      onOk: this.handleIgnoreSumbit(event, category, rowIndex),
    });
  }

  @autobind
  handleIgnoreSumbit(incident, category, rowIndex) {
    return () => {
      const { intl, credentials, userInfo, systemInfo } = this.props;
      const { groupEventList } = this.state;
      const { isIgnored, isImportant } = incident;
      const { projectOwner, anomalyLogInstance, instanceName, patternId, type } = incident;
      let { projectName } = incident;
      projectName = projectOwner !== userInfo.userName ? `${projectName}@${projectOwner}` : projectName;

      if (this.ignoreModal) {
        this.ignoreModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      let content;
      if (category === 'ignore' && !isIgnored) {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, incident.rootCausesDetailsList || []);
        content = R.join('\n', summaryList);
      }

      let operation;
      switch (category) {
        case 'ignore':
          operation = isIgnored ? 'cancelIgnoreFlag' : 'setIgnoreFlag';
          break;
        case 'important':
          operation = isImportant ? 'cancelImportantFlag' : 'setImportantFlag';
          break;
        default:
          break;
      }

      this.props.updateLastActionInfo();
      return fetchPost(
        getEndpoint('events', 1),
        {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceName,
          operation,
          nid: patternId,
          type: type === 'Incident' ? 'incident' : undefined,
          content,
        },
        {},
        false,
      )
        .then((data) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.ignoreModal) this.ignoreModal.destroy();
          this.props.onRefreshData(true);
          if (this.listNode) this.listNode.forceUpdateGrid();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.ignoreModal) {
            this.ignoreModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  handleReportJiraClick(incident) {
    this.setState({ activeEvent: incident, showReportJiraModal: true });
  }

  @autobind
  handleReportServiceNowClick(incident) {
    this.setState({ activeEvent: incident, showReportServiceNowModal: true });
  }

  @autobind
  handleOverallLineChartClick() {
    this.setState({ showProjectSelector: true, onConfirmProjectSelect: this.handleOverallLineChartJump });
  }

  @autobind
  handleOverallLineChartJump(projectName) {
    const { systemInfo, environmentId, customerName, startTime, endTime } = this.props;

    const startTimeObj = moment.utc(endTime, Defaults.DateFormat).startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    // get instanceList
    const shortProjectName = projectName.split('@')[0];
    let instanceList = [];
    R.forEach(
      (item) => {
        instanceList = [...instanceList, ...item.instanceList];
      },
      R.filter((event) => event.category === 'metric' && event.projectName === shortProjectName, this.anomalyEvents),
    );
    R.forEach(
      (item) => {
        instanceList.push(item.realInstanceName);
      },
      R.filter((event) => event.category !== 'metric' && event.projectName === shortProjectName, this.anomalyEvents),
    );
    instanceList = R.take(10, R.uniq(instanceList));

    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId: systemInfo.id,

      projectName,
      instanceGroup,
      modelType,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      justInstanceList: R.join(',', instanceList),
      withBaseline: false,
    };
    this.setState({
      showProjectSelector: false,
    });
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection, groupEventList } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const sortList = this.sortData(groupEventList, name, sortDir);
          this.setState({ groupEventList: sortList });

          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        });
      }
    };
  }

  @autobind
  updateEventList(expandIds, otherState = {}) {
    const { filterGroupEventList, sortBy, sortDirection } = this.state;
    let groupEventList = [];

    R.forEach((item) => {
      const { groupId, children, isParent } = item;
      const needOpen = expandIds.includes(groupId);
      if (needOpen) {
        if (isParent) {
          item.expand = true;
          groupEventList.push(item);
          R.forEach((n) => groupEventList.push(n), children);
        }
      } else if (isParent) {
        item.expand = false;
        groupEventList.push(item);
      }
    }, filterGroupEventList);

    groupEventList = this.sortData(groupEventList, sortBy, sortDirection);
    const listHeight = R.min(groupEventList.length, UHV_LIST_MAX_SHOW_COUNT) * this.listRowHeight;
    this.setState({ groupEventList, listHeight, expandIds, ...otherState }, () => {
      if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  headerExpandClick(e) {
    e.stopPropagation();
    e.preventDefault();

    const { allExpand } = this.state;
    const { events } = this.props;
    const expandIds = allExpand ? [] : R.range(0, events.length);
    this.updateEventList(expandIds, { allExpand: !allExpand });
  }

  @autobind
  headerCountClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection, groupEventList } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const sortList = [];

          // sort by
          let sortFunctions = [
            R.descend(R.prop('isImportant')),
            R.descend(R.prop('scoreTotal')),
            R.descend(R.prop('avgAnomalyScore')),
          ];
          if (sortBy && sortDir && sortDir !== 'NA') {
            sortFunctions = sortDir === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
          }
          const parentData = R.sortWith([R.descend(R.prop('scoreTotal'))])(
            R.filter((item) => item.isParent, groupEventList),
          );
          const sortListParent = R.sortWith(sortFunctions)(parentData);
          R.forEach((item) => {
            const { expand, children } = item;
            sortList.push(item);
            if (expand) {
              R.forEach((child) => sortList.push(child), children);
            }
          }, sortListParent);

          this.setState({ groupEventList: sortList, filterGroupEventList: groupEventList });
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const {
      intl,
      projects,
      userInfo,
      startTime,
      endTime,
      customerName,
      environmentId,
      systemHealth,
      systemInfo,
      width,
      height,
      isMetric,
      currentTheme,
      globalLoading,
    } = this.props;
    const {
      filterInstance,
      filterPattern,
      filterMetric,
      sortBy,
      sortDirection,
      optionTimelineRange,
      patternNameMap,
      groupEventList,
      listHeight,
      allExpand,
      isLoading,
    } = this.state;
    const { activeEvent, showTakeLogActionModal, actionName, showEventActionModal } = this.state;

    // build action params
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let incidentPatternName = null;
    let projectName;
    let project;
    if (activeEvent) {
      const { category, patternId, anomalyLogInstance, projectOwner } = activeEvent;
      projectName =
        userInfo.userName !== projectOwner ? `${activeEvent.projectName}@${projectOwner}` : activeEvent.projectName;
      project = R.find((project) => projectName === project.projectName, projects);
      const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
      incidentPatternName = get(patternNameMap, [key, patternId]);
    }

    let { instanceOptions, patternOptions, metricOptions, logOption } = this;
    if (filterInstance) {
      patternOptions = R.filter((item) => item.instances.includes(filterInstance), patternOptions);
      metricOptions = R.filter((item) => item.instances.includes(filterInstance), metricOptions);
    }
    const events = groupEventList;

    return (
      <Spin spinning={!globalLoading && isLoading} className="full-width full-height spin-full-height">
        <Space style={{ padding: '8px 0' }} className="full-width">
          <Select
            allowClear
            showArrow={false}
            showSearch
            size="small"
            placeholder={intl.formatMessage(eventMessages.instanceName)}
            style={{ width: 120 }}
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
              (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={filterInstance}
            onChange={(filterInstance) => this.onChangeFilterField('filterInstance', filterInstance)}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          >
            {instanceOptions.map((item) => (
              <Select.Option key={item.value} value={item.value} label={item.label}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
          <Select
            allowClear
            showArrow={false}
            showSearch
            size="small"
            placeholder={intl.formatMessage(eventMessages.patternId)}
            style={{ width: 120, marginLeft: 8 }}
            optionFilterProp="value"
            value={filterPattern}
            onChange={(filterPattern) => this.onChangeFilterField('filterPattern', filterPattern)}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          >
            {patternOptions.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.value}
              </Select.Option>
            ))}
          </Select>
          {isMetric && (
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              placeholder={intl.formatMessage(eventMessages.metricFilter)}
              style={{ width: 120, marginLeft: 8 }}
              optionFilterProp="value"
              value={filterMetric}
              onChange={(filterMetric) => this.onChangeFilterField('filterMetric', filterMetric)}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {metricOptions.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          )}
          {!isMetric && (
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              placeholder={intl.formatMessage(appFieldsMessages.type)}
              style={{ width: 120, marginLeft: 8 }}
              optionFilterProp="value"
              value={filterMetric}
              onChange={(filterMetric) => this.onChangeFilterField('filterLog', filterMetric)}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {logOption.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}
        </Space>
        <div className="event-list">
          <div
            className="event-list-header"
            style={{
              height: this.listHeaderHeight,
              width,
              paddingRight: this.listNodeHeaderScrollbar ? 9 : 1,
            }}
          >
            <div className="header-column" style={{ width: 40 }} />
            <div className="header-column" style={{ width: 90 }} onClick={this.headerClick('avgAnomalyScore')}>
              <span>{intl.formatMessage(eventMessages.anomalyScore)}</span>
              {this.sortIcon(sortBy, sortDirection, 'avgAnomalyScore')}
            </div>
            <div className="header-column" style={{ width: 100 }} onClick={this.headerClick('patternId')}>
              <span>{intl.formatMessage(appFieldsMessages.pattern)}</span>
              {this.sortIcon(sortBy, sortDirection, 'patternId')}
            </div>
            <div className="header-column" style={{ width: 220 }} onClick={this.headerClick('componentName')}>
              <span>{intl.formatMessage(eventMessages.impacted)}</span>
              {this.sortIcon(sortBy, sortDirection, 'componentName')}
            </div>
            <div className="header-column" style={{ width: 300 }}>
              {intl.formatMessage(eventMessages.shortDescription)}
            </div>
            <div className="header-column" style={{ width: 100 }} onClick={this.headerClick('duration')}>
              <span>{intl.formatMessage(eventMessages.avgDuration)}</span>
              {this.sortIcon(sortBy, sortDirection, 'duration')}
            </div>
            <div className="header-column flex-center-justify" style={{ width: 90 }}>
              {isMetric ? intl.formatMessage(eventMessages.metricType) : intl.formatMessage(eventMessages.logType)}
            </div>
            <div className="header-column" style={{ width: 100, flex: 1 }}>
              {optionTimelineRange && (
                <EChart theme={currentTheme} width="100%" height={40} option={optionTimelineRange} onEvents={{}} />
              )}
            </div>
            <div className="header-column" style={{ width: 80 }} />
            <div className="header-column" style={{ width: 60, padding: 0 }} onClick={this.headerCountClick('count')}>
              <span>{intl.formatMessage(appFieldsMessages.count)}</span>
              {this.sortIcon(sortBy, sortDirection, 'count')}
            </div>
            <div
              className="header-column"
              style={{ width: 24, padding: 0, justifyContent: 'center' }}
              onClick={this.headerExpandClick}
            >
              <DoubleLeftOutlined rotate={allExpand ? 90 : -90} />
            </div>
          </div>
          <List
            className="event-list-grid"
            ref={(listNode) => {
              this.listNode = listNode;
            }}
            width={width}
            height={height}
            rowCount={events.length}
            overscanRowCount={4}
            deferredMeasurementCache={this.cellMeasureCache}
            rowHeight={this.cellMeasureCache.rowHeight}
            rowRenderer={this.renderAnomalyListItem(events)}
            onScrollbarPresenceChange={({ horizontal, vertical }) => {
              if (vertical) {
                this.listNodeHeaderScrollbar = true;
              } else {
                this.listNodeHeaderScrollbar = false;
              }
              this.forceUpdate();
            }}
          />
        </div>

        {this.state.showSystemEventModel && (
          <EventShortListModal
            customerName={customerName}
            environmentId={environmentId}
            systemId={get(systemHealth, 'systemId')}
            startTime={startTime}
            endTime={endTime}
            intervalInMinutes={get(systemHealth, 'intervalInMinutes', 1)}
            selectInstance={null}
            timelines={activeEvent.timelines}
            onClose={(systemIds) => {
              this.setState({ showSystemEventModel: false, activeEvent: null });
              if (systemIds) this.props.onClose(systemIds);
            }}
          />
        )}
        {this.state.showTriageReportModal && (
          <TriageReportModal
            environmentId={environmentId}
            systemId={get(systemHealth, 'systemId')}
            incident={activeEvent}
            onClose={() => this.setState({ showTriageReportModal: false, activeEvent: null })}
          />
        )}
        {showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={actionName}
            incident={activeEvent}
            incidentPatternName={incidentPatternName}
            project={project}
            projectName={activeEvent.fullProjectName}
            instanceGroup={instanceGroup}
            eventType={activeEvent.type}
            onClose={() => this.setState({ showTakeLogActionModal: false })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {showEventActionModal && (
          <EventActionModal
            incident={activeEvent}
            project={project}
            projectName={projectName}
            projectNameList={this.getProjectNameList()}
            instanceGroup={instanceGroup}
            onClose={() => this.setState({ showEventActionModal: false })}
          />
        )}
        {this.state.showProjectSelector && (
          <ProjectSelectorModal
            system={systemInfo}
            events={events}
            onConfirm={this.state.onConfirmProjectSelect}
            onClose={() => this.setState({ showProjectSelector: false })}
          />
        )}
        {this.state.showReportJiraModal && (
          <ReportJiraModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showReportJiraModal: false })}
          />
        )}
        {this.state.showReportServiceNowModal && (
          <ReportServiceNowModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showReportServiceNowModal: false })}
          />
        )}
      </Spin>
    );
  }
}

const GlobalDetectedAnomaliesTable = injectIntl(GlobalDetectedAnomaliesTableCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset, projects, systemsMap, currentTheme, globalInfo } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      loadStatus,
      timezoneOffset,
      projects,
      systemsMap,
      credentials,
      userInfo,
      currentTheme,
      location,
      globalInfo,
    };
  },
  { updateLastActionInfo },
)(GlobalDetectedAnomaliesTable);
