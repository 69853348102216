/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import moment from 'moment';
import { get, isBoolean } from 'lodash';

import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const sandboxLoginApi = (params) => {
  return fetchPost(getEndpoint('sandbox-login'), {
    ...params,
  }).then((data) => {
    const { success } = data || {};
    if (isBoolean(success) && success === false) {
      throw data;
    }

    // Set tokenExpiredTime in localStorage
    const tokenExpiredTime = get(data, ['tokenExpiredTime']);
    window.localStorage.setItem('tokenExpiredTime', tokenExpiredTime);
    console.debug(`Token Expired Time: ${moment.utc(tokenExpiredTime).format('HH:mm:ss')}`);

    // Set X-CSRF-Token in localStorage
    const token = get(data, ['token']);
    window.localStorage.setItem('X-CSRF-Token', token);

    const isFirstLogin = get(data, ['data', 'isFirstLogin'], false);
    const avatarId = get(data, ['avatarId']);
    return {
      credentials: {
        userName: get(data, ['data', 'userName']),
      },
      userInfo: { ...data.data, isFirstLogin, avatarId },
    };
  });
};

export default sandboxLoginApi;
