import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { get } from 'lodash';
import {
  Button,
  Select,
  Input,
  InputNumber,
  Checkbox,
  Form,
  Alert,
  Tabs,
  Spin,
  message,
  Row,
  Col,
  Tooltip,
  AutoComplete,
} from 'antd';

import moment from 'moment';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { buildLocation, parseLocation, Regex } from '../../../../common/utils';
// import { BaseUrls } from '../../app/Constants';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Modal, List } from '../../../../lib/fui/react';

import { appMessages, appFieldsMessages, appButtonsMessages } from '../../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../../common/metric/messages';
import { settingsMessages } from '../../../../common/settings/messages';

const RenderEmailComponent = forwardRef(
  ({ intl, credentials, alertEmail, deliveryEmail, removeChilrenEmail, systemsMap, systemId }: Object, ref) => {
    const cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

    const [emailList, setEmailList] = useState([]);
    const [emailHeader, setEmailHeader] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const [emailSelectOpen, setEmailSelectOpen] = useState(false);
    const [hasError, setHasError] = useState(true);
    const emailListRef = useRef();

    useImperativeHandle(ref, () => ({
      setEmailHeader,
      setEmailContent,
    }));
    useEffect(() => {
      const systemInfo = systemsMap[systemId] || {};
      const localShareUserList = R.concat(
        [{ userName: systemInfo.owner }],
        R.map((user) => {
          return { userName: user };
        }, get(systemInfo, 'shareUserSet', [])),
      );
      setEmailHeader('');
      setEmailContent('');
      fetchGet(getEndpoint('customer', 2), {
        ...credentials,
      })
        .then((data) => {
          let setLocalShareUserList = [];
          const testObj = {};
          R.addIndex(R.map)((item, index) => {
            if (typeof item.userName === 'string' && item.userName) {
              setLocalShareUserList.push({ value: item.userName, content: '' });
              R.forEach((userItem) => {
                if (item.userName === userItem.userName) {
                  setLocalShareUserList[index].value = Regex.email.test(userItem.email)
                    ? userItem.email.split('@')[0]
                    : Regex.email.test(item.userName)
                    ? item.userName.split('@')[0]
                    : Regex.email.test(`${item.userName}@qq.com`)
                    ? item.userName
                    : '';
                  setLocalShareUserList[index].content = Regex.email.test(userItem.email)
                    ? userItem.email.match(/(?<=\@)[\S\s]*/)[0]
                    : Regex.email.test(item.userName)
                    ? `${item.userName.match(/@(\S*)\./)[1]}.com`
                    : '';
                }
              }, R.sortWith([R.ascend(R.prop('userName'))], data || []));
            }
          }, localShareUserList);
          setLocalShareUserList = setLocalShareUserList.reduce((preVal, curVal) => {
            // eslint-disable-next-line no-unused-expressions
            testObj[curVal.value] ? '' : (testObj[curVal.value] = curVal.value && preVal.push(curVal));
            return preVal;
          }, []);
          setEmailList(setLocalShareUserList);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    }, [systemId]);

    const onEmailHeaderSelect = (data) => {
      const emailContent = emailList.filter((item) => item.value === data)[0]?.content;
      setEmailHeader(data);
      setEmailContent(emailContent);
      setHasError(
        emailContent ? !(Regex.email.test(`${data}@${emailContent}`) && /.\../.test(R.trim(emailContent))) : true,
      );
    };

    const renderEmailListItem = ({ key, index: rowIndex, style, parent }) => {
      const removeEmail = (rowIndex) => {
        const newAlertEmail = alertEmail.split(',');
        newAlertEmail.splice(rowIndex, 1);
        removeChilrenEmail(newAlertEmail.join(','));
      };

      return (
        <CellMeasurer key={key} cache={cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
            <div className="row-column" style={{ flex: 1 }}>
              {alertEmail.split(',')[rowIndex]}
            </div>
            <div className="row-column" style={{ width: 80 }}>
              <Button size="small" className="button-color-grey" onClick={() => removeEmail(rowIndex)}>
                {intl.formatMessage(eventActionMessages.remove)}
              </Button>
            </div>
          </div>
        </CellMeasurer>
      );
    };

    const addEmail = () => {
      if (
        emailHeader &&
        emailContent &&
        Regex.email.test(`${emailHeader}@${emailContent}`) &&
        !alertEmail?.includes(`${emailHeader}@${emailContent}`)
      ) {
        deliveryEmail(`${emailHeader}@${emailContent}`);
        emailListRef.current.scrollToRow(0);
        setEmailHeader('');
        setEmailContent('');
        setHasError(true);
      }
    };

    const handleEmailSuffixChange = (e) => {
      const content = e.target.value;
      setEmailContent(content);
      setHasError(content ? !(Regex.email.test(`${emailHeader}@${content}`) && /.\../.test(R.trim(content))) : true);
    };

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Please enter the name set by the user of the mailbox" placement="left" trigger="focus">
            <AutoComplete
              value={emailHeader}
              style={{ width: 200 }}
              options={emailList}
              open={emailSelectOpen}
              onBlur={() => setEmailSelectOpen(false)}
              onFocus={() => setEmailSelectOpen(true)}
              onSelect={onEmailHeaderSelect}
              onChange={onEmailHeaderSelect}
              filterOption={(inputValue, option) => option.value.indexOf(emailHeader) !== -1}
            />
          </Tooltip>
          <span style={{ margin: '0 10px' }}>@</span>
          <Tooltip title="Please enter the email domain name" placement="top" trigger="focus">
            <Input value={emailContent} style={{ width: 150 }} onChange={handleEmailSuffixChange} />
          </Tooltip>
          <Button style={{ marginLeft: 10 }} type="primary" onClick={addEmail} disabled={hasError}>
            {intl.formatMessage(eventActionMessages.addEmail)}
          </Button>
        </div>

        <div className="flex-grow flex-min-height" style={{ marginTop: 10, height: 200 }}>
          <AutoSizer>
            {({ width, height }) => (
              <div className="event-list">
                <div className="event-list-header" style={{ height: 30, width }}>
                  <div className="header-column" style={{ flex: 1 }}>
                    {intl.formatMessage(eventActionMessages.email)}
                  </div>
                  <div className="header-column" style={{ width: 60 }} />
                </div>
                <List
                  className="event-list-grid"
                  ref={emailListRef}
                  width={width}
                  height={160}
                  rowCount={alertEmail ? alertEmail.split(',').length : 0}
                  overscanRowCount={4}
                  rowHeight={cellMeasureCache.rowHeight}
                  rowRenderer={(props) => renderEmailListItem(props)}
                />
              </div>
            )}
          </AutoSizer>
        </div>
      </>
    );
  },
);

const useHealthEmail = ({ intl, credentials, systemId, projectDetailsList, owner, environmentId }) => {
  const [errMsg, setErrMsg] = useState(null);
  const [reload, setReload] = useState(moment.utc().valueOf());
  const [isLoading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [thresholdProject, setThresholdProject] = useState(null);
  const [thresholdMap, setThresholdMap] = useState({});
  const [selectSystem, setSelectSystem] = useState({});
  useEffect(() => {
    setProjectOptions(
      R.map((item) => {
        const { projectName, customerName, projectDisplayName } = item || {};
        return {
          label: `${projectDisplayName || projectName}@${customerName}`,
          value: `${projectName}@${customerName}`,
        };
      }, projectDetailsList),
    );
    setThresholdProject(null);
    setLoading(true);
    fetchGet(getEndpoint('healthviewsetting', 2), {
      ...credentials,
      customerName: owner,
      environmentName: environmentId,
    })
      .then((data) => {
        const { success, message: msg, ...rest } = data;
        if (success || success === undefined) {
          const systemConfigs = [];
          R.forEachObjIndexed((system, systemId) => {
            const { environmentName } = system.key || {};
            systemConfigs.push({ ...system, systemId, id: systemId, environmentId: environmentName });
          }, rest || {});
          setSettings(systemConfigs);
          const selectSystem = get(data, systemId);
          const incidentCountThreshold = get(selectSystem, 'incidentCountThreshold', {});
          setThresholdMap(incidentCountThreshold);
          setSelectSystem({ ...selectSystem });
          setErrMsg(null);
        } else {
          setErrMsg(msg);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setLoading(false);
      });
  }, [reload, systemId]);

  return {
    errMsg,
    isLoading,
    settings,
    projectOptions,
    thresholdProject,
    thresholdMap,
    selectSystem,
    setErrMsg,
    setReload,
    setThresholdProject,
    setSelectSystem,
  };
};

const RenderHealthEmail = ({ activeKey, intl, credentials, system, systemsMap, healthEmailData = {} }: Object) => {
  const { environmentId, owner, systemId } = system;
  const {
    errMsg,
    isLoading,
    settings,
    projectOptions,
    thresholdProject,
    thresholdMap,
    selectSystem,
    setErrMsg,
    setReload,
    setThresholdProject,
    setSelectSystem,
  } = healthEmailData;

  const [isSubmitting, setSubmitting] = useState(false);
  const emailRef = useRef();
  const [thresholdCount, setThresholdCount] = useState(0);

  const RCAOptions = [
    { label: 'All incidents', value: false },
    { label: 'Incidents with root causes', value: true },
  ];
  const isHealthEmail = R.includes(activeKey, ['healthEmail']);
  const isIncidentEmail = R.includes(activeKey, ['incidentEmail']);
  const isAlertEmail = R.includes(activeKey, ['alertEmail']);

  const handleSubmit = () => {
    // setSubmitting(true);
    emailRef.current.setEmailHeader('');
    emailRef.current.setEmailContent('');
    const index = R.findIndex(R.propEq('id', systemId))(settings);
    const card = settings[index] || {};
    const incidentCountThreshold = R.clone(thresholdMap);
    if (thresholdProject) {
      incidentCountThreshold[thresholdProject] = thresholdCount;
    }

    let updateSetting = update(settings, {
      [index]: { $set: { ...card, ...(selectSystem || {}), incidentCountThreshold } },
    });

    if (isHealthEmail) {
      updateSetting = update(settings, {
        [index]: {
          $set: {
            ...card,
            incidentCountThreshold,
            enableHealthEmailAlert: selectSystem?.enableHealthEmailAlert,
            emailDampeningPeriod: selectSystem?.emailDampeningPeriod,
            alertHealthScore: selectSystem?.alertHealthScore,
            healthAlertEmail: selectSystem?.healthAlertEmail,
          },
        },
      });
    } else if (isIncidentEmail) {
      updateSetting = update(settings, {
        [index]: {
          $set: {
            ...card,
            incidentCountThreshold,
            enableIncidentDetectionEmailAlert: selectSystem?.enableIncidentDetectionEmailAlert,
            emailDampeningPeriod: selectSystem?.emailDampeningPeriod,
            onlySendWithRCA: selectSystem?.onlySendWithRCA,
            incidentDetectionEmail: selectSystem?.incidentDetectionEmail,
          },
        },
      });
    } else if (isAlertEmail) {
      updateSetting = update(settings, {
        [index]: {
          $set: {
            ...card,
            incidentCountThreshold,
            enableAlertsEmail: selectSystem?.enableAlertsEmail,
            alertsEmailDampeningPeriod: selectSystem?.alertsEmailDampeningPeriod,
            alertEmail: selectSystem?.alertEmail,
          },
        },
      });
    }

    fetchPost(getEndpoint('healthviewsetting', 2), {
      ...credentials,
      customerName: owner,
      environmentName: environmentId,
      settings: JSON.stringify(updateSetting),
    })
      .then((data) => {
        if (data && data.success) {
          setErrMsg(null);
          setSubmitting(false);
          setReload(moment.utc().valueOf());
        } else {
          if (data.message) setErrMsg(data.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setSubmitting(false);
      });
  };

  const deliveryEmail = (value) => {
    setSelectSystem({
      ...selectSystem,
      ...(isHealthEmail
        ? { healthAlertEmail: `${value}${selectSystem.healthAlertEmail ? `,${selectSystem.healthAlertEmail}` : ''}` }
        : {}),
      ...(isIncidentEmail
        ? {
            incidentDetectionEmail: `${value}${
              selectSystem.incidentDetectionEmail ? `,${selectSystem.incidentDetectionEmail}` : ''
            }`,
          }
        : {}),
      ...(isAlertEmail
        ? { alertEmail: `${value}${selectSystem.alertEmail ? `,${selectSystem.alertEmail}` : ''}` }
        : {}),
    });
  };

  const removeChilrenEmail = (value) => {
    setSelectSystem({
      ...selectSystem,
      ...(isHealthEmail ? { healthAlertEmail: value } : {}),
      ...(isIncidentEmail ? { incidentDetectionEmail: value } : {}),
      ...(isAlertEmail ? { alertEmail: value } : {}),
    });
  };

  const { enableHealthEmailAlert, enableIncidentDetectionEmailAlert, enableAlertsEmail } = selectSystem || {};
  const isRequired = enableHealthEmailAlert && isHealthEmail;
  const hasError =
    isRequired &&
    (!selectSystem.emailDampeningPeriod ||
      !selectSystem.alertHealthScore ||
      !selectSystem.healthAlertEmail ||
      !R.reduce(
        R.and,
        true,
        R.map((e) => Regex.email.test(R.trim(e)), selectSystem.healthAlertEmail.split(',')),
      ));

  return (
    <Spin spinning={isLoading}>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} style={{ paddingTop: 16 }}>
        {errMsg && (
          <Form.Item wrapperCol={{ offset: 8, span: 14 }} style={{ margin: 0 }}>
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}
        {isHealthEmail && (
          <Form.Item label={intl.formatMessage(eventMessages.enableHealthAlert)}>
            <Checkbox
              checked={enableHealthEmailAlert}
              onChange={(e) => setSelectSystem({ ...selectSystem, enableHealthEmailAlert: e.target.checked })}
            />
          </Form.Item>
        )}
        {isIncidentEmail && (
          <Form.Item label="Enable incident detection notification">
            <Checkbox
              checked={enableIncidentDetectionEmailAlert}
              onChange={(e) =>
                setSelectSystem({ ...selectSystem, enableIncidentDetectionEmailAlert: e.target.checked })
              }
            />
          </Form.Item>
        )}
        {R.includes(activeKey, ['healthEmail', 'incidentEmail']) && (
          <Form.Item
            label={intl.formatMessage(settingsMessages.dampeningPeriod)}
            validateStatus={isRequired && !selectSystem.emailDampeningPeriod ? 'error' : 'success'}
            help={
              isRequired && !selectSystem.emailDampeningPeriod
                ? intl.formatMessage(appFieldsMessages.inputRequired)
                : undefined
            }
            required={isRequired}
          >
            <InputNumber
              min={0}
              value={(selectSystem.emailDampeningPeriod || 0) / 60 / 1000}
              onChange={(emailDampeningPeriod) =>
                setSelectSystem({ ...selectSystem, emailDampeningPeriod: (emailDampeningPeriod || 0) * 60 * 1000 })
              }
            />
          </Form.Item>
        )}
        {isHealthEmail && (
          <Form.Item
            label={intl.formatMessage(eventMessages.alertHealthScore)}
            validateStatus={isRequired && !selectSystem.alertHealthScore ? 'error' : 'success'}
            help={
              isRequired && !selectSystem.alertHealthScore
                ? intl.formatMessage(appFieldsMessages.inputRequired)
                : undefined
            }
            required={isRequired}
          >
            <InputNumber
              max={100}
              min={0}
              value={selectSystem.alertHealthScore || 0}
              onChange={(alertHealthScore) => setSelectSystem({ ...selectSystem, alertHealthScore })}
            />
          </Form.Item>
        )}
        {isIncidentEmail && (
          <Form.Item label={intl.formatMessage(eventMessages.detectedIncidentCountThreshold)}>
            <Row gutter={10}>
              <Col flex={1}>
                <Select
                  allowClear
                  showSearch
                  options={projectOptions}
                  value={thresholdProject}
                  onChange={(thresholdProject) => {
                    setThresholdCount(0);
                    setThresholdProject(thresholdProject);
                  }}
                />
              </Col>
              <Col>
                <InputNumber
                  style={{ width: 100 }}
                  value={thresholdMap[thresholdProject] || thresholdCount}
                  onChange={(thresholdCount) => setThresholdCount(thresholdCount)}
                />
              </Col>
            </Row>
          </Form.Item>
        )}
        {isIncidentEmail && (
          <Form.Item label={intl.formatMessage(eventMessages.notificationType)}>
            <Select
              options={RCAOptions}
              style={{ maxWidth: 200 }}
              value={selectSystem.onlySendWithRCA}
              onChange={(onlySendWithRCA) => setSelectSystem({ ...selectSystem, onlySendWithRCA })}
            />
          </Form.Item>
        )}
        {isAlertEmail && (
          <Form.Item label={intl.formatMessage(eventMessages.enableAlertNotification)}>
            <Checkbox
              checked={enableAlertsEmail}
              onChange={(e) => setSelectSystem({ ...selectSystem, enableAlertsEmail: e.target.checked })}
            />
          </Form.Item>
        )}
        {isAlertEmail && (
          <Form.Item label={intl.formatMessage(eventMessages.alertNotificationDampeningPeriod)}>
            <InputNumber
              min={0}
              value={(selectSystem.alertsEmailDampeningPeriod || 0) / 60 / 1000}
              onChange={(alertsEmailDampeningPeriod) =>
                setSelectSystem({
                  ...selectSystem,
                  alertsEmailDampeningPeriod: (alertsEmailDampeningPeriod || 0) * 60 * 1000,
                })
              }
            />
          </Form.Item>
        )}

        {/* email */}
        {isHealthEmail ? (
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.email)}
            validateStatus={isRequired && !selectSystem.healthAlertEmail ? 'error' : 'success'}
            help={
              isRequired && !selectSystem.healthAlertEmail
                ? intl.formatMessage(appFieldsMessages.inputRequired)
                : undefined
            }
            required={isRequired}
          >
            <RenderEmailComponent
              ref={emailRef}
              intl={intl}
              credentials={credentials}
              alertEmail={selectSystem.healthAlertEmail}
              systemsMap={systemsMap}
              systemId={systemId}
              deliveryEmail={deliveryEmail}
              removeChilrenEmail={removeChilrenEmail}
            />
          </Form.Item>
        ) : isIncidentEmail ? (
          <Form.Item label={intl.formatMessage(appFieldsMessages.email)}>
            <RenderEmailComponent
              ref={emailRef}
              intl={intl}
              credentials={credentials}
              alertEmail={selectSystem.incidentDetectionEmail}
              systemsMap={systemsMap}
              systemId={systemId}
              deliveryEmail={deliveryEmail}
              removeChilrenEmail={removeChilrenEmail}
            />
          </Form.Item>
        ) : (
          <Form.Item label={intl.formatMessage(appFieldsMessages.email)}>
            <RenderEmailComponent
              ref={emailRef}
              intl={intl}
              credentials={credentials}
              alertEmail={selectSystem.alertEmail}
              systemsMap={systemsMap}
              systemId={systemId}
              deliveryEmail={deliveryEmail}
              removeChilrenEmail={removeChilrenEmail}
            />
          </Form.Item>
        )}
      </Form>

      <Row>
        <Col span={24} style={{ textAlign: 'right', paddingRight: '8%' }}>
          <Button type="primary" disabled={hasError} loading={isSubmitting} onClick={handleSubmit}>
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

const RenderInstanceDownEmail = ({ intl, credentials, system, systemsMap }: Object) => {
  const { owner, systemId } = system;
  const [errMsg, setErrMsg] = useState(null);
  const [reload, setReload] = useState(moment.utc().valueOf());
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [settings, setSettings] = useState([]);
  const [selectSystem, setSelectSystem] = useState({});
  const emailRef = useRef();

  useEffect(() => {
    setLoading(true);
    fetchGet(getEndpoint('systemdownsetting', 2), {
      ...credentials,
      customerName: owner,
      systemIds: JSON.stringify([systemId]),
    })
      .then((data) => {
        const systemConfigs = [];
        R.forEach((item) => {
          const { emailSet, ...rest } = item || {};
          const alertEmail = R.join(', ', emailSet || []);
          systemConfigs.push({ ...rest, systemId, alertEmail });
        }, data);
        // for empty system
        if (systemConfigs.length === 0) {
          systemConfigs.push({
            systemId,
          });
        }
        setSettings(systemConfigs);

        const selectSystem = systemConfigs[0] || {};
        setSelectSystem(selectSystem);
        setErrMsg(null);
        setLoading(false);
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setLoading(false);
      });
  }, [reload, systemId]);

  const handleSubmit = () => {
    setSubmitting(true);
    emailRef.current.setEmailHeader('');
    emailRef.current.setEmailContent('');
    const index = R.findIndex(R.propEq('systemId', systemId))(settings);
    const card = settings[index] || {};
    const { alertEmail } = selectSystem || {};
    const emailSet = R.filter(
      (e) => e,
      R.map((e) => R.trim(e), R.split(',', alertEmail || '')),
    );
    fetchPost(getEndpoint('systemdownsetting', 2), {
      ...credentials,
      customerName: owner,
      settingModels: JSON.stringify(
        update(settings, {
          [index]: { $set: { ...card, ...(selectSystem || {}), alertEmail: undefined, emailSet } },
        }),
      ),
    })
      .then((data) => {
        if (data && data.success) {
          setErrMsg(null);
          setSubmitting(false);
          setReload(moment.utc().valueOf());
        } else {
          if (data.message) setErrMsg(data.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setSubmitting(false);
      });
  };

  const deliveryEmail = (value) => {
    setSelectSystem({
      ...selectSystem,
      alertEmail: `${value}${selectSystem.alertEmail ? `,${selectSystem.alertEmail}` : ''}`,
    });
  };

  const removeChilrenEmail = (value) => {
    setSelectSystem({ ...selectSystem, alertEmail: value });
  };

  const { enableSystemDownEmailAlert } = selectSystem || {};
  const hasError =
    enableSystemDownEmailAlert &&
    (!selectSystem.emailDampeningPeriod ||
      !selectSystem.alertEmail ||
      !R.reduce(
        R.and,
        true,
        R.map((e) => Regex.email.test(R.trim(e)), selectSystem.alertEmail.split(',')),
      ));
  return (
    <Spin spinning={isLoading}>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} style={{ paddingTop: 16 }}>
        {errMsg && (
          <Form.Item wrapperCol={{ offset: 8, span: 14 }} style={{ margin: 0 }}>
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}

        <Form.Item label={intl.formatMessage(eventMessages.enableSystemDownDetection)}>
          <Checkbox
            checked={enableSystemDownEmailAlert}
            onChange={(e) => setSelectSystem({ ...selectSystem, enableSystemDownEmailAlert: e.target.checked })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(settingsMessages.dampeningPeriod)}
          validateStatus={enableSystemDownEmailAlert && !selectSystem.emailDampeningPeriod ? 'error' : 'success'}
          help={
            enableSystemDownEmailAlert && !selectSystem.emailDampeningPeriod
              ? intl.formatMessage(appFieldsMessages.inputRequired)
              : undefined
          }
          required={enableSystemDownEmailAlert}
        >
          <InputNumber
            value={(selectSystem.emailDampeningPeriod || 0) / 60 / 1000}
            onChange={(emailDampeningPeriod) =>
              setSelectSystem({ ...selectSystem, emailDampeningPeriod: (emailDampeningPeriod || 0) * 60 * 1000 })
            }
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(appFieldsMessages.email)}
          validateStatus={enableSystemDownEmailAlert && !selectSystem.alertEmail ? 'error' : 'success'}
          help={
            enableSystemDownEmailAlert && !selectSystem.alertEmail
              ? intl.formatMessage(appFieldsMessages.inputRequired)
              : undefined
          }
          required={enableSystemDownEmailAlert}
        >
          <RenderEmailComponent
            ref={emailRef}
            intl={intl}
            credentials={credentials}
            alertEmail={selectSystem.alertEmail}
            systemsMap={systemsMap}
            systemId={systemId}
            deliveryEmail={deliveryEmail}
            removeChilrenEmail={removeChilrenEmail}
          />
        </Form.Item>
      </Form>

      <Row>
        <Col span={24} style={{ textAlign: 'right', paddingRight: '8%' }}>
          <Button type="primary" disabled={hasError} loading={isSubmitting} onClick={handleSubmit}>
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

const RenderEmailSetting = ({ intl, credentials, system, systemsMap }: Object) => {
  const [errMsg, setErrMsg] = useState(null);
  const [reload, setReload] = useState(moment.utc().valueOf());
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  // get project list
  let projectNameSet = get(system, 'projectNameSet', []);
  projectNameSet = R.filter((item) => item.ps !== 'Deleting', projectNameSet);
  let projectList = R.map((item) => ({ value: item.projectNameReal, label: item.projectDisplayName }), projectNameSet);
  const [projectName, setProjectName] = useState(projectList.length > 0 ? projectList[0].value : undefined);
  const [enableNewAlertEmail, setEnableNewAlertEmail] = useState(false);
  const [alertEmail, setAlertEmail] = useState('');
  const emailRef = useRef();

  useEffect(() => {
    setProjectName((prevState) => {
      projectNameSet = get(system, 'projectNameSet', []);
      projectNameSet = R.filter((item) => item.ps !== 'Deleting', projectNameSet);
      projectList = R.map((item) => ({ value: item.projectNameReal, label: item.projectDisplayName }), projectNameSet);
      return projectList.length > 0 ? projectList[0].value : undefined;
    });
  }, [system]);

  useEffect(() => {
    if (projectName) {
      setLoading(true);
      fetchGet(getEndpoint('projects/update'), {
        ...credentials,
        operation: 'display',
        projectName,
      })
        .then((result) => {
          const { success, message: errMsg, data } = result;
          if (success || success === undefined) {
            const enableNewAlertEmail = get(data, 'projectModelAllJSON.enableNewAlertEmail', false);
            const alertEmails = get(data, 'projectModelAllJSON.alertEmails', []);
            setEnableNewAlertEmail(enableNewAlertEmail);
            setAlertEmail(R.join(', ', alertEmails));
          }
          setErrMsg(errMsg);
          setLoading(false);
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          setErrMsg(err.message || String(err));
          setLoading(false);
        });
    }
  }, [reload, projectName]);

  const handleSubmit = () => {
    setSubmitting(true);
    emailRef.current.setEmailHeader('');
    emailRef.current.setEmailContent('');
    fetchPost(getEndpoint('projects/update'), {
      operation: 'updateprojsettings',
      projectName,
      enableNewAlertEmail,
      alertEmails: JSON.stringify(R.map((e) => R.trim(e), alertEmail.split(','))),
    })
      .then((data) => {
        setErrMsg(null);
        setSubmitting(false);
        setReload(moment.utc().valueOf());
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setSubmitting(false);
      });
  };

  const deliveryEmail = (value) => {
    setAlertEmail(`${value}${alertEmail ? `,${alertEmail}` : ''}`);
  };

  const removeChilrenEmail = (value) => {
    setAlertEmail(value);
  };

  const hasError =
    !projectName ||
    (enableNewAlertEmail && !alertEmail) ||
    (alertEmail &&
      !R.reduce(
        R.and,
        true,
        R.map((e) => Regex.email.test(R.trim(e)), alertEmail.split(',')),
      ));

  return (
    <Spin spinning={isLoading}>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} style={{ paddingTop: 16 }}>
        {errMsg && (
          <Form.Item wrapperCol={{ offset: 8, span: 14 }} style={{ margin: 0 }}>
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}

        <Form.Item
          label={intl.formatMessage(eventMessages.projectName)}
          validateStatus={!projectName ? 'error' : 'success'}
          help={!projectName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Select
            allowClear={false}
            showSearch
            filterOption
            options={projectList}
            value={projectName}
            onChange={(val) => setProjectName(val)}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage(settingsMessages.enableNewAlertEmail)}>
          <Checkbox checked={enableNewAlertEmail} onChange={(e) => setEnableNewAlertEmail(e.target.checked)} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(appFieldsMessages.email)}
          validateStatus={enableNewAlertEmail && !alertEmail ? 'error' : 'success'}
          help={enableNewAlertEmail && !alertEmail ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required={enableNewAlertEmail}
        >
          <RenderEmailComponent
            ref={emailRef}
            intl={intl}
            credentials={credentials}
            alertEmail={alertEmail}
            systemsMap={systemsMap}
            systemId={system.systemId}
            deliveryEmail={deliveryEmail}
            removeChilrenEmail={removeChilrenEmail}
          />
        </Form.Item>
      </Form>

      <Row>
        <Col span={24} style={{ textAlign: 'right', paddingRight: '8%' }}>
          <Button type="primary" disabled={hasError} loading={isSubmitting} onClick={handleSubmit}>
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

const RenderInstanceDownSetting = ({ intl, credentials, system, systemsMap }: Object) => {
  const [errMsg, setErrMsg] = useState(null);
  const [reload, setReload] = useState(moment.utc().valueOf());
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  // get project list
  let projectNameSet = get(system, 'projectNameSet', []);
  projectNameSet = R.filter((item) => item.ps !== 'Deleting', projectNameSet);
  let projectList = R.map((item) => ({ value: item.projectNameReal, label: item.projectDisplayName }), projectNameSet);
  const [projectName, setProjectName] = useState(
    projectNameSet.length > 0 ? projectNameSet[0].projectNameReal : undefined,
  );
  const [projectIsMetric, setProjectIsMetric] = useState(
    projectNameSet.length > 0 ? projectNameSet[0].dataType === 'Metric' : false,
  );
  const [instanceDownEnable, setEnableInstanceDownEmail] = useState(false);
  const [instanceDownDampening, setInstanceDownDampening] = useState(0);
  const [instanceDownThreshold, setInstanceDownThreshold] = useState(0);
  const [instanceDownReportNumber, setInstanceDownReportNumber] = useState(1);
  const [instanceDownEmails, setAlertEmail] = useState('');
  const emailRef = useRef();

  useEffect(() => {
    if (projectName) {
      setLoading(true);
      fetchGet(getEndpoint('projects/update'), {
        ...credentials,
        operation: 'display',
        projectName,
      })
        .then((result) => {
          const { success, message: errMsg, data } = result;
          if (success || success === undefined) {
            const instanceDownEnable = get(data, 'projectModelAllJSON.instanceDownEnable', false);
            const instanceDownDampening = get(data, 'projectModelAllJSON.instanceDownDampening');
            const instanceDownThreshold = get(data, 'projectModelAllJSON.instanceDownThreshold');
            const instanceDownReportNumber = get(data, 'projectModelAllJSON.instanceDownReportNumber');
            const instanceDownEmails = get(data, 'projectModelAllJSON.instanceDownEmails', []);
            setEnableInstanceDownEmail(instanceDownEnable);
            setInstanceDownDampening((instanceDownDampening || 0) / 60 / 1000);
            setInstanceDownThreshold((instanceDownThreshold || 0) / 60 / 1000);
            setInstanceDownReportNumber(instanceDownReportNumber);
            setAlertEmail(R.join(', ', instanceDownEmails));
          }
          setErrMsg(errMsg);
          setLoading(false);
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          setErrMsg(err.message || String(err));
          setLoading(false);
        });
    }
  }, [reload, projectName]);
  useEffect(() => {
    projectNameSet = get(system, 'projectNameSet', []);
    projectNameSet = R.filter((item) => item.ps !== 'Deleting', projectNameSet);
    projectList = R.map((item) => ({ value: item.projectNameReal, label: item.projectDisplayName }), projectNameSet);
    setProjectName(projectNameSet.length > 0 ? projectNameSet[0].projectNameReal : undefined);
    setProjectIsMetric(projectNameSet.length > 0 ? projectNameSet[0].dataType === 'Metric' : false);
  }, [system]);

  const handleSubmit = () => {
    setSubmitting(true);
    emailRef.current.setEmailHeader('');
    emailRef.current.setEmailContent('');
    fetchPost(getEndpoint('projects/update'), {
      operation: 'updateprojsettings',
      projectName,
      instanceDownEnable,
      instanceDownDampening: instanceDownDampening * 60 * 1000,
      instanceDownThreshold: instanceDownThreshold * 60 * 1000,
      instanceDownReportNumber,
      instanceDownEmails: JSON.stringify(R.map((e) => R.trim(e), instanceDownEmails.split(','))),
    })
      .then((data) => {
        setErrMsg(null);
        setSubmitting(false);
        setReload(moment.utc().valueOf());
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setSubmitting(false);
      });
  };

  const deliveryEmail = (value) => {
    setAlertEmail(`${value}${instanceDownEmails ? `,${instanceDownEmails}` : ''}`);
  };

  const removeChilrenEmail = (value) => {
    setAlertEmail(value);
  };

  const hasError =
    !projectName ||
    (instanceDownEnable && !instanceDownEmails) ||
    (instanceDownEmails &&
      !R.reduce(
        R.and,
        true,
        R.map((e) => Regex.email.test(R.trim(e)), instanceDownEmails.split(',')),
      ));

  return (
    <Spin spinning={isLoading}>
      <Form labelWrap labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} style={{ paddingTop: 16 }}>
        {errMsg && (
          <Form.Item wrapperCol={{ offset: 8, span: 14 }} style={{ margin: 0 }}>
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}

        <Form.Item
          label={intl.formatMessage(eventMessages.projectName)}
          validateStatus={!projectName ? 'error' : 'success'}
          help={!projectName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
          required
        >
          <Select
            allowClear={false}
            showSearch
            filterOption
            options={projectList}
            value={projectName}
            onChange={(val) => {
              const isMetric =
                (R.find((item) => item.projectNameReal === val, projectNameSet) || {}).dataType === 'Metric';
              setProjectName(val);
              setProjectIsMetric(isMetric);
            }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage(settingsMessages.enableInstanceDownDetection)}>
          <Checkbox checked={instanceDownEnable} onChange={(e) => setEnableInstanceDownEmail(e.target.checked)} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(settingsMessages.instanceDownDampening)}
          validateStatus={instanceDownEnable && !instanceDownDampening ? 'error' : 'success'}
          help={
            instanceDownEnable && !instanceDownDampening
              ? intl.formatMessage(appFieldsMessages.inputRequired)
              : undefined
          }
          required={instanceDownEnable}
        >
          <InputNumber min={0} value={instanceDownDampening} onChange={(value) => setInstanceDownDampening(value)} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(settingsMessages.maximumDownInstanceReportingNumber)}
          validateStatus={instanceDownEnable && !instanceDownReportNumber ? 'error' : 'success'}
          help={
            instanceDownEnable && !instanceDownReportNumber
              ? intl.formatMessage(appFieldsMessages.inputRequired)
              : undefined
          }
          required={instanceDownEnable}
        >
          <InputNumber
            min={1}
            value={instanceDownReportNumber}
            onChange={(value) => setInstanceDownReportNumber(value)}
          />
        </Form.Item>
        {projectIsMetric && (
          <>
            <Form.Item
              label={intl.formatMessage(settingsMessages.instanceDownThreshold)}
              validateStatus={instanceDownEnable && !instanceDownThreshold ? 'error' : 'success'}
              help={
                instanceDownEnable && !instanceDownThreshold
                  ? intl.formatMessage(appFieldsMessages.inputRequired)
                  : undefined
              }
              required={instanceDownEnable}
            >
              <InputNumber
                min={0}
                value={instanceDownThreshold}
                onChange={(value) => setInstanceDownThreshold(value)}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          label={intl.formatMessage(appFieldsMessages.email)}
          validateStatus={instanceDownEnable && !instanceDownEmails ? 'error' : 'success'}
          help={
            instanceDownEnable && !instanceDownEmails ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined
          }
          required={instanceDownEnable}
        >
          <RenderEmailComponent
            ref={emailRef}
            intl={intl}
            credentials={credentials}
            alertEmail={instanceDownEmails}
            systemsMap={systemsMap}
            systemId={system.systemId}
            deliveryEmail={deliveryEmail}
            removeChilrenEmail={removeChilrenEmail}
          />
        </Form.Item>
      </Form>

      <div span={24} style={{ textAlign: 'right', paddingRight: '8%' }}>
        <Button type="primary" disabled={hasError} loading={isSubmitting} onClick={handleSubmit}>
          {intl.formatMessage(appButtonsMessages.submit)}
        </Button>
      </div>
    </Spin>
  );
};

const RenderDailyReportEmail = ({ intl, credentials, system, systemsMap }: Object) => {
  const { owner, systemId } = system;
  const [errMsg, setErrMsg] = useState(null);
  const [reload, setReload] = useState(moment.utc().valueOf());
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [settings, setSettings] = useState([]);
  const [selectSystem, setSelectSystem] = useState({});
  const emailRef = useRef();

  useEffect(() => {
    setLoading(true);
    fetchGet(getEndpoint('insightsreportsetting'), {
      ...credentials,
      customerName: owner,
      systemIds: JSON.stringify([systemId]),
    })
      .then((data) => {
        const systemConfigs = [];
        R.forEach((item) => {
          const { systemKey, emailSet, ...rest } = item || {};
          const { systemPartitionKey } = systemKey || {};
          const { systemName } = systemPartitionKey || {};
          const alertEmail = R.join(', ', emailSet || []);
          systemConfigs.push({ ...rest, systemId: systemName || systemId, alertEmail });
        }, data);
        // for empty system
        if (systemConfigs.length === 0) {
          systemConfigs.push({
            systemId,
          });
        }
        setSettings(systemConfigs);

        const selectSystem = systemConfigs[0] || {};
        setSelectSystem(selectSystem);
        setErrMsg(null);
        setLoading(false);
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setLoading(false);
      });
  }, [reload, systemId]);

  const handleSubmit = () => {
    setSubmitting(true);
    emailRef.current.setEmailHeader('');
    emailRef.current.setEmailContent('');
    const index = R.findIndex(R.propEq('systemId', systemId))(settings);
    const card = settings[index] || {};
    const { alertEmail } = selectSystem || {};
    const emailSet = R.filter(
      (e) => e,
      R.map((e) => R.trim(e), R.split(',', alertEmail || '')),
    );
    fetchPost(getEndpoint('insightsreportsetting'), {
      ...credentials,
      customerName: owner,
      isDaily: true,
      settingModels: JSON.stringify(
        update(settings, {
          [index]: {
            $set: {
              ...card,
              ...(selectSystem || {}),
              alertEmail: undefined,
              emailSet,
              weeklyEmailSet: undefined,
              enableDailyInsightsReport: undefined,
              enableWeeklyInsightsReport: undefined,
              lastDailyReportTimestamp: undefined,
              enableInsightsReport: selectSystem.enableDailyInsightsReport,
            },
          },
        }),
      ),
    })
      .then((data) => {
        if (data && data.success) {
          setErrMsg(null);
          setSubmitting(false);
          setReload(moment.utc().valueOf());
        } else {
          if (data.message) setErrMsg(data.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setSubmitting(false);
      });
  };

  const deliveryEmail = (value) => {
    setSelectSystem({
      ...selectSystem,
      alertEmail: `${value}${selectSystem.alertEmail ? `,${selectSystem.alertEmail}` : ''}`,
    });
  };

  const removeChilrenEmail = (value) => {
    setSelectSystem({ ...selectSystem, alertEmail: value });
  };

  const { enableDailyInsightsReport } = selectSystem || {};
  const hasError =
    (enableDailyInsightsReport && !selectSystem.alertEmail) ||
    (selectSystem.alertEmail &&
      !R.reduce(
        R.and,
        true,
        R.map((e) => Regex.email.test(R.trim(e)), selectSystem.alertEmail.split(',')),
      ));
  return (
    <Spin spinning={isLoading}>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} style={{ paddingTop: 16 }}>
        {errMsg && (
          <Form.Item wrapperCol={{ offset: 8, span: 14 }} style={{ margin: 0 }}>
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}

        <Form.Item label={intl.formatMessage(eventMessages.enableDailyReport)}>
          <Checkbox
            checked={enableDailyInsightsReport}
            onChange={(e) => setSelectSystem({ ...selectSystem, enableDailyInsightsReport: e.target.checked })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(appFieldsMessages.email)}
          validateStatus={enableDailyInsightsReport && !selectSystem.alertEmail ? 'error' : 'success'}
          help={
            enableDailyInsightsReport && !selectSystem.alertEmail
              ? intl.formatMessage(appFieldsMessages.inputRequired)
              : undefined
          }
          required={enableDailyInsightsReport}
        >
          <RenderEmailComponent
            ref={emailRef}
            intl={intl}
            credentials={credentials}
            alertEmail={selectSystem.alertEmail}
            systemsMap={systemsMap}
            systemId={systemId}
            deliveryEmail={deliveryEmail}
            removeChilrenEmail={removeChilrenEmail}
          />
        </Form.Item>
      </Form>

      <Row>
        <Col span={24} style={{ textAlign: 'right', paddingRight: '8%' }}>
          <Button type="primary" disabled={hasError} loading={isSubmitting} onClick={handleSubmit}>
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

const RenderWeeklyReportEmail = ({ intl, credentials, system, systemsMap }: Object) => {
  const { owner, systemId } = system;
  const [errMsg, setErrMsg] = useState(null);
  const [reload, setReload] = useState(moment.utc().valueOf());
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [settings, setSettings] = useState([]);
  const [selectSystem, setSelectSystem] = useState({});
  const emailRef = useRef();

  useEffect(() => {
    setLoading(true);
    fetchGet(getEndpoint('insightsreportsetting'), {
      ...credentials,
      customerName: owner,
      systemIds: JSON.stringify([systemId]),
    })
      .then((data) => {
        const systemConfigs = [];
        R.forEach((item) => {
          const { systemKey, weeklyEmailSet, ...rest } = item || {};
          const { systemPartitionKey } = systemKey || {};
          const { systemName } = systemPartitionKey || {};
          const alertEmail = R.join(', ', weeklyEmailSet || []);
          systemConfigs.push({ ...rest, systemId: systemName || systemId, alertEmail });
        }, data);
        // for empty system
        if (systemConfigs.length === 0) {
          systemConfigs.push({
            systemId,
          });
        }
        setSettings(systemConfigs);

        const selectSystem = systemConfigs[0] || {};
        setSelectSystem(selectSystem);
        setErrMsg(null);
        setLoading(false);
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setLoading(false);
      });
  }, [reload, systemId]);

  const handleSubmit = () => {
    setSubmitting(true);
    emailRef.current.setEmailHeader('');
    emailRef.current.setEmailContent('');
    const index = R.findIndex(R.propEq('systemId', systemId))(settings);
    const card = settings[index] || {};
    const { alertEmail } = selectSystem || {};
    const emailSet = R.filter(
      (e) => e,
      R.map((e) => R.trim(e), R.split(',', alertEmail || '')),
    );

    fetchPost(getEndpoint('insightsreportsetting'), {
      ...credentials,
      customerName: owner,
      isDaily: false,
      settingModels: JSON.stringify(
        update(settings, {
          [index]: {
            $set: {
              ...card,
              ...(selectSystem || {}),
              alertEmail: undefined,
              emailSet,
              enableDailyInsightsReport: undefined,
              enableWeeklyInsightsReport: undefined,
              lastDailyReportTimestamp: undefined,
              enableInsightsReport: selectSystem.enableWeeklyInsightsReport,
            },
          },
        }),
      ),
    })
      .then((data) => {
        if (data && data.success) {
          setErrMsg(null);
          setSubmitting(false);
          setReload(moment.utc().valueOf());
        } else {
          if (data.message) setErrMsg(data.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setSubmitting(false);
      });
  };

  const deliveryEmail = (value) => {
    setSelectSystem({
      ...selectSystem,
      alertEmail: `${value}${selectSystem.alertEmail ? `,${selectSystem.alertEmail}` : ''}`,
    });
  };

  const removeChilrenEmail = (value) => {
    setSelectSystem({ ...selectSystem, alertEmail: value });
  };

  const { enableWeeklyInsightsReport } = selectSystem || {};
  const hasError =
    (enableWeeklyInsightsReport && !selectSystem.alertEmail) ||
    (selectSystem.alertEmail &&
      !R.reduce(
        R.and,
        true,
        R.map((e) => Regex.email.test(R.trim(e)), selectSystem.alertEmail.split(',')),
      ));
  return (
    <Spin spinning={isLoading}>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} style={{ paddingTop: 16 }}>
        {errMsg && (
          <Form.Item wrapperCol={{ offset: 8, span: 14 }} style={{ margin: 0 }}>
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}

        <Form.Item label={intl.formatMessage(eventMessages.enableWeeklyReport)}>
          <Checkbox
            checked={enableWeeklyInsightsReport}
            onChange={(e) => setSelectSystem({ ...selectSystem, enableWeeklyInsightsReport: e.target.checked })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(appFieldsMessages.email)}
          validateStatus={enableWeeklyInsightsReport && !selectSystem.alertEmail ? 'error' : 'success'}
          help={
            enableWeeklyInsightsReport && !selectSystem.alertEmail
              ? intl.formatMessage(appFieldsMessages.inputRequired)
              : undefined
          }
          required={enableWeeklyInsightsReport}
        >
          <RenderEmailComponent
            ref={emailRef}
            intl={intl}
            credentials={credentials}
            alertEmail={selectSystem.alertEmail}
            systemsMap={systemsMap}
            systemId={systemId}
            deliveryEmail={deliveryEmail}
            removeChilrenEmail={removeChilrenEmail}
          />
        </Form.Item>
      </Form>

      <Row>
        <Col span={24} style={{ textAlign: 'right', paddingRight: '8%' }}>
          <Button type="primary" disabled={hasError} loading={isSubmitting} onClick={handleSubmit}>
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

const RenderPredictedIncident = ({ intl, credentials, system, systemsMap }: Object) => {
  const { environmentId, owner, systemId } = system;
  const [errMsg, setErrMsg] = useState(null);
  const [reload, setReload] = useState(moment.utc().valueOf());
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [settings, setSettings] = useState([]);
  const [selectSystem, setSelectSystem] = useState({});
  const emailRef = useRef();
  useEffect(() => {
    setLoading(true);
    fetchGet(getEndpoint('healthviewsetting', 2), {
      ...credentials,
      customerName: owner,
      environmentName: environmentId,
    })
      .then((data) => {
        const systemConfigs = [];
        R.forEachObjIndexed((system, systemId) => {
          const { environmentName } = system.key || {};
          systemConfigs.push({ ...system, systemId, id: systemId, environmentId: environmentName });
        }, data);
        setSettings(systemConfigs);

        const selectSystem = get(data, systemId);
        setSelectSystem(selectSystem);
        setErrMsg(null);
        setLoading(false);
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setLoading(false);
      });
  }, [reload, systemId]);

  const handleSubmit = () => {
    emailRef.current.setEmailHeader('');
    emailRef.current.setEmailContent('');
    const index = R.findIndex(R.propEq('id', systemId))(settings);
    const card = settings[index] || {};

    setSubmitting(false);
    fetchPost(getEndpoint('healthviewsetting', 2), {
      ...credentials,
      customerName: owner,
      settings: JSON.stringify([{ ...card, ...(selectSystem || {}) }]),
    })
      .then((data) => {
        if (data && data.success) {
          setErrMsg(null);
          setSubmitting(false);
          setReload(moment.utc().valueOf());
        } else {
          if (data.message) setErrMsg(data.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setErrMsg(err.message || String(err));
        setSubmitting(false);
      });
  };

  const deliveryEmail = (value) => {
    setSelectSystem({
      ...selectSystem,
      predictionEmail: `${value}${selectSystem.predictionEmail ? `,${selectSystem.predictionEmail}` : ''}`,
    });
  };

  const removeChilrenEmail = (value) => {
    setSelectSystem({ ...selectSystem, predictionEmail: value });
  };

  const { enableIncidentPredictionEmailAlert } = selectSystem || {};
  return (
    <Spin spinning={isLoading}>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} style={{ paddingTop: 16 }}>
        {errMsg && (
          <Form.Item wrapperCol={{ offset: 8, span: 14 }} style={{ margin: 0 }}>
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}

        <Form.Item label="Enable incident prediction notification">
          <Checkbox
            checked={enableIncidentPredictionEmailAlert}
            onChange={(e) => setSelectSystem({ ...selectSystem, enableIncidentPredictionEmailAlert: e.target.checked })}
          />
        </Form.Item>

        <Form.Item label={intl.formatMessage(settingsMessages.dampeningPeriod)}>
          <InputNumber
            value={(selectSystem.predictionEmailDampeningPeriod || 0) / 60 / 1000}
            onChange={(predictionEmailDampeningPeriod) =>
              setSelectSystem({
                ...selectSystem,
                predictionEmailDampeningPeriod: (predictionEmailDampeningPeriod || 0) * 60 * 1000,
              })
            }
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage(appFieldsMessages.email)}>
          <RenderEmailComponent
            ref={emailRef}
            intl={intl}
            credentials={credentials}
            alertEmail={selectSystem.predictionEmail}
            systemsMap={systemsMap}
            systemId={systemId}
            deliveryEmail={deliveryEmail}
            removeChilrenEmail={removeChilrenEmail}
          />
        </Form.Item>
      </Form>

      <Row>
        <Col span={24} style={{ textAlign: 'right', paddingRight: '8%' }}>
          <Button type="primary" loading={isSubmitting} onClick={handleSubmit}>
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};
// eslint-disable-next-line
const SystemEmailSetting = ({ intl, credentials, isAdmin, system, systemsMap, location, replace }: Object) => {
  const { owner: customerName, systemId, projectDetailsList = [], owner, environmentId } = system;
  const [activeKey, setActiveKey] = useState('incidentEmail');
  const healthEmailData = useHealthEmail({ intl, credentials, systemId, projectDetailsList, owner, environmentId });

  useEffect(() => {
    const params = parseLocation(location);
    const { activeLeftTab } = params;
    if (activeLeftTab) {
      setActiveKey(activeLeftTab);
      replace(buildLocation(location.pathname, {}, { ...params, activeLeftTab: undefined }));
    }
  }, []);

  return (
    <div className="full-height" style={{ overflowY: 'auto' }}>
      <Tabs
        type="line"
        className="full-height ant-tabs-content-full-height"
        activeKey={activeKey}
        onChange={(activeKey) => setActiveKey(activeKey)}
        tabPosition="left"
      >
        <Tabs.TabPane tab={intl.formatMessage(settingsMessages.incidentEmail)} key="incidentEmail">
          <RenderHealthEmail
            activeKey={activeKey}
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={system}
            systemsMap={systemsMap}
            healthEmailData={healthEmailData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage(settingsMessages.predictedIncidentNotification)} key="predictedIncident">
          <RenderPredictedIncident
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={system}
            systemsMap={systemsMap}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage(settingsMessages.alertEmail)} key="alertEmail">
          <RenderHealthEmail
            activeKey={activeKey}
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={system}
            systemsMap={systemsMap}
            healthEmailData={healthEmailData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage(settingsMessages.healthEmail)} key="healthEmail">
          <RenderHealthEmail
            activeKey={activeKey}
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={system}
            systemsMap={systemsMap}
            healthEmailData={healthEmailData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage(settingsMessages.systemDown)} key="systemDownEmail">
          <RenderInstanceDownEmail
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={system}
            systemsMap={systemsMap}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage(settingsMessages.emailAlertSetting)} key="emailAlertSetting">
          <RenderEmailSetting
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={system}
            systemsMap={systemsMap}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage(settingsMessages.instanceDownDetection)} key="instanceDownDetection">
          <RenderInstanceDownSetting
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={system}
            systemsMap={systemsMap}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage(settingsMessages.dailyReport)} key="dailyReport">
          <RenderDailyReportEmail
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={system}
            systemsMap={systemsMap}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage(settingsMessages.weeklyReport)} key="weeklyReport">
          <RenderWeeklyReportEmail
            intl={intl}
            credentials={credentials}
            isAdmin={isAdmin}
            customerName={customerName}
            system={system}
            systemsMap={systemsMap}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default SystemEmailSetting;
