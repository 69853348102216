/**
 * This is the data processing logic of metic linchart
 */
import Papa from 'papaparse';
import { get, isEmpty, floor, ceil, isNumber } from 'lodash';
import * as R from 'ramda';
import moment from 'moment';
import Defaults from '../../app/Defaults';
import { DataFrame } from '../../../lib/fui/react';
import { calcAnomalyLevel, calcColorOfAnomalyLevel } from '../../utils';

/**
 * calculationLogic is a function that takes in a lot of data and returns a lot of data
 * @param {Array} data - original data [{userZonePredictionStartTime, metricUnitMapping, splitCsvData, k8CoverageMap}]
 * @param {Object} startTimeObj - start time point of search condition
 * @param {Object} metricAnomalyMap - metricName: anomalyInstances mapping relationship If it exists, it will only take the data of the instance in anomalyInstances
 * @param {Array} metricList - metric of search condition
 * @param {Array} allMetricList - all metrics
 * @param {Object} metricRootCauseMap - metricName: rootCauseList mapping relationship
 * @param {Array} instanceList - temporarily no effect
 * @param {Array} anomalyMetricByInstances - anomaly metrics
 * @param {Array} filterMetricList - filtered metrics
 * @param {Number} topestRank - default 100
 * @param {Array} eventList - event list
 * @returns {Object} - returns a lot of data
 */

const calculationLogic = (
  data,
  startTimeObj,
  metricAnomalyMap,
  metricList,
  allMetricList,
  metricRootCauseMap,
  instanceList,
  anomalyMetricByInstances,
  filterMetricList,
  topestRank,
  eventList,
) => {
  const metricUnitMapping = {};
  let splitCsvData = {};
  const k8CoverageMap = {};
  const startTimestamp = startTimeObj.valueOf();
  // get current time from backend
  let currentTimestamp;
  let needsSplitPrediction = false;
  const removeGroupId = (inst) => {
    // use last : to remove group id
    const parts = (inst || '').split(':');
    if (parts.length > 1) parts.pop();
    return parts.join(':');
  };
  const removeDataGroupId = (d) => {
    const ret = {};
    R.forEachObjIndexed((val, key) => {
      ret[removeGroupId(key)] = val;
    }, d || {});
    return ret;
  };

  // Merge response
  R.forEach((eventsData) => {
    const userZonePredictionStartTime = get(eventsData, 'userZonePredictionStartTime');
    currentTimestamp = userZonePredictionStartTime || currentTimestamp;

    // If not userZonePredictionStartTime, just not split prediction part
    if (userZonePredictionStartTime && !needsSplitPrediction) {
      needsSplitPrediction = true;
    }

    R.forEach((m) => {
      let { unit } = m;
      unit = (unit || '').replace('(', '').replace(')', '');
      metricUnitMapping[m.metric] = { ...m, unit };
    }, get(eventsData, 'metricUnitMapping', []));

    // change csv data to json and merged by metric name
    R.forEachObjIndexed((csvData, mname) => {
      // Need change csv header to remove [][]7005. Not allow same metric with diff group data
      // const changeHeaderCsvData = R.replace(/\[\]\[\]:-?\d+/g, '', csvData);
      const paJson = Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        // dynamicTyping: true,
        transform: (value) => Number(value),
      });

      const data = R.map((d) => removeDataGroupId(d), paJson.data || []);
      const fields = R.map((x) => removeGroupId(x), paJson.meta.fields);

      if (!R.has(mname, splitCsvData)) {
        splitCsvData[mname] = paJson;
        // uniq columns if backend return multi data with same metric and instance
        splitCsvData[mname].data = data;
        splitCsvData[mname].meta.fields = R.uniq(fields);
      } else {
        splitCsvData[mname].data = [...splitCsvData[mname].data, ...data];
        const fullFields = splitCsvData[mname].meta.fields;
        R.forEach((field) => {
          if (R.indexOf(field, fullFields) < 0) {
            fullFields.push(field);
          }
        }, fields);
        splitCsvData[mname].meta.fields = fullFields;
      }
    }, get(eventsData, 'splitCsvData', {}));

    const k8Coverage = JSON.parse(eventsData.k8CoverageMap || '{}');
    R.forEachObjIndexed((coverage, key) => {
      if (!R.has(key, k8CoverageMap)) {
        k8CoverageMap[key] = coverage;
      } else {
        k8CoverageMap[key] = [...k8CoverageMap[key], ...coverage];
      }
    }, get(k8Coverage, 'coverageMap', {}));
  }, data);

  let datasetMetricList = [];
  let datasetInstanceList = [];
  let hasDataMetricList = [];

  // sort pa data, get all metric data latestTime, filter instances
  let latestDataTime = 0;
  splitCsvData = R.mapObjIndexed((pa, mname) => {
    let sortData = pa.data || [];
    if (sortData.length > 0) {
      sortData = R.sortWith([R.ascend(R.prop('timestamp'))], sortData);
      latestDataTime = R.max(latestDataTime, sortData[sortData.length - 1].timestamp);
    }

    // filter fields if metricAnomalyMap is set
    let fields = pa.meta.fields || [];
    if (metricAnomalyMap) {
      const anomalyInstances = get(metricAnomalyMap, mname, []);
      fields = R.filter((item) => {
        const keys = item.match(/.*\[(.*)\]:.*/);
        if (keys && keys.length > 1) {
          const instanceName = keys[1];
          if (!anomalyInstances.includes(instanceName)) {
            return false;
          }
        }
        return true;
      }, fields);
    }

    return { ...pa, meta: { ...pa.meta, fields }, data: sortData };
  }, splitCsvData);

  const columnInfoMap = {};
  const metricDatasetMap = {};
  R.forEachObjIndexed((pa, mname) => {
    const dsResult = pa.data || [];
    // Add empty data for start/end timestamp, and ignore data out of the time range.
    let dataset = [];
    if (dsResult.length === 0 || dsResult[0].timestamp > startTimestamp) {
      dataset.push({ timestamp: startTimestamp });
    }

    R.forEach((d) => {
      const { timestamp, ...others } = d;
      if (timestamp >= startTimestamp) {
        // others = R.mapObjIndexed((num, key, obj) => (R.type(num) !== 'Number' ? undefined : num), others);
        const metricInstanceKeys = [];
        const metricInstanceVals = {};
        R.forEachObjIndexed((value, key) => {
          const uniqKey = key;
          // const uniqKey = R.replace(/\[\]/g, '', key);
          // if (R.type(value) === 'Number' && metricInstanceKeys.indexOf(uniqKey) >= 0) {
          //   console.log(key, uniqKey);
          // }
          if (metricInstanceKeys.indexOf(uniqKey) < 0 && R.type(value) === 'Number') {
            metricInstanceKeys.push(uniqKey);
            metricInstanceVals[uniqKey] = value;
          }
        }, others);

        dataset.push({
          timestamp,
          ...metricInstanceVals,
        });
      }
    }, dsResult);

    // uniq by timestamp reverse per minit
    // use the latest day's data cover the old day's data
    dataset = R.uniqBy((d) => moment.utc(d.timestamp).format(Defaults.TimeFormat), R.reverse(dataset));
    // reverse back
    dataset = R.map((d) => ({ ...d, timestamp: new Date(d.timestamp) }), R.reverse(dataset));

    const df = new DataFrame(dataset, pa.meta.fields);
    const data = df.toArray();
    const colnames = df.listColumns();

    // Create a mapping between the column name to metric/instance
    R.forEach(
      (cn) => {
        // The metric name is like: LoadAvg5[ip-172-31-7-224][][]:7007
        const idx = cn.indexOf(']');
        if (idx > 0) {
          const name = cn.substr(0, idx + 1);
          const nidx = name.indexOf('[');
          if (nidx > 0) {
            const metric = name.substr(0, nidx);
            const instanceId = name.substr(nidx + 1, name.length - nidx - 2);
            datasetMetricList.push(metric);
            hasDataMetricList.push(metric);
            datasetInstanceList.push(instanceId);
            columnInfoMap[cn] = { name: cn, metric, instanceId };
          }
        }
      },
      R.filter((n) => Boolean(n), colnames),
    );

    const rootCauseMap = {};
    const rootCauseList = metricRootCauseMap[mname] || [];
    // Handle multi colnames with same instance and metric, but diff group id
    R.forEach((r) => {
      const { instanceId, metric, isInstanceDown } = r;
      R.forEach(
        (i) => {
          const colname = i.name;
          if (colname) {
            const rclist = get(rootCauseMap, colname, []);
            rclist.push(r);
            rootCauseMap[colname] = rclist;
          }
        },
        R.filter((c) => {
          return isInstanceDown ? c?.instanceId === instanceId : c?.metric === metric && c?.instanceId === instanceId;
        }, R.values(columnInfoMap)),
      );
    }, rootCauseList);

    // split prediction part to dash line
    let hasPredictionData = false;
    const currentStartMin = moment.utc(currentTimestamp).startOf('minute').valueOf();
    if (data.length > 0 && data[data.length - 1][0].valueOf() >= currentStartMin) hasPredictionData = true;
    let newData = data;
    let newLabels = colnames;
    let predictionlabelMap;
    if (hasPredictionData) {
      let connectNextPoint = false;
      const nullData = R.map((i) => null, R.range(0, colnames.length - 1));
      newData = R.map((item) => {
        if (needsSplitPrediction && item[0].valueOf() >= currentStartMin) {
          if (connectNextPoint) {
            return [item[0], ...nullData, ...R.slice(1, Infinity, item)];
          }
          connectNextPoint = true;
          return [...item, ...R.slice(1, Infinity, item)];
        }
        return [...item, ...nullData];
      }, data);
      predictionlabelMap = {};
      R.forEach((col) => {
        const predictionLabel = `Prediction: ${col}`;
        predictionlabelMap[col] = predictionLabel;
        rootCauseMap[predictionLabel] = rootCauseMap[col];
        columnInfoMap[predictionLabel] = columnInfoMap[col];
      }, R.slice(1, Infinity, colnames));
      newLabels = [...colnames, ...R.values(predictionlabelMap)];
    }

    metricDatasetMap[mname] = {
      name: mname,
      unit: (metricUnitMapping[mname] || {}).unit,
      data: newData,
      labels: newLabels,
      predictionlabelMap,
      rootCauseMap,
      // used for download
      csvData: dsResult,
    };
  }, splitCsvData);

  hasDataMetricList = R.uniq(hasDataMetricList);
  datasetInstanceList = isEmpty(instanceList) ? R.uniq(datasetInstanceList) : instanceList;
  datasetMetricList = isEmpty(metricList) ? R.uniq(datasetMetricList) : metricList;
  instanceList = isEmpty(instanceList) ? datasetInstanceList : instanceList;
  metricList = isEmpty(metricList) ? datasetMetricList : metricList;
  // put anomalous metrics in the top
  const metricListHasAnomaly = R.filter((metric) => anomalyMetricByInstances.includes(metric), metricList);
  const metricListWithNoAnomaly = R.filter((metric) => !anomalyMetricByInstances.includes(metric), metricList);
  metricList = [...metricListHasAnomaly, ...metricListWithNoAnomaly];
  // put no data metrics in the bottom
  const metricListHasData = R.filter((metric) => hasDataMetricList.includes(metric), metricList);
  const metricListWithNoData = R.filter((metric) => !hasDataMetricList.includes(metric), metricList);
  metricList = [...metricListHasData, ...metricListWithNoData];

  // set allMetricInfoList
  const allMetricInfoList = R.map((metric) => {
    const minfo = metricUnitMapping[metric] || {};
    let shortValue = metric;
    if (metric.indexOf('/') >= 0) {
      shortValue = R.takeLast(1, R.split('/', metric));
    }
    shortValue = `${R.take(8, shortValue)}${shortValue.length > 8 ? '..' : ''}`;
    return { id: metric, name: metric, shortValue, shortName: minfo.shortMetric, unit: minfo.unit || '' };
  }, allMetricList);
  const filterMetricInfoList = R.filter((m) => filterMetricList.indexOf(m.id) >= 0, allMetricInfoList);

  const metricEventListMap = {};
  R.forEach((ev) => {
    // If has instance down, add the event to all metric
    const isDown = Boolean(R.find((rc) => rc.isInstanceDown, ev.rootCauseList));
    if (isDown) {
      R.forEach((m) => {
        // fill rootCauseMetric field
        const curEvent = { ...ev };
        const rootCauseDetailsArr = get(curEvent, ['rootCauseJson', 'rootCauseDetailsArr'], []);
        R.forEach((rootCauseDetail) => (rootCauseDetail.rootCauseMetric = m), rootCauseDetailsArr);

        const elist = metricEventListMap[m] || [];
        curEvent.isDown = true;
        curEvent.color = calcColorOfAnomalyLevel(calcAnomalyLevel(topestRank)).color;
        elist.push(curEvent);
        metricEventListMap[m] = elist;
      }, metricList);
    } else {
      const mlist = R.uniq(
        R.filter(
          (m) => m,
          R.map((rc) => rc.metric, ev.rootCauseList || []),
        ),
      );
      R.forEach((m) => {
        const elist = metricEventListMap[m] || [];
        elist.push(ev);
        metricEventListMap[m] = elist;
      }, mlist);
    }
  }, eventList);

  return {
    metricEventListMap,
    metricList,
    currentTimestamp,
    instanceList,
    datasetMetricList,
    hasDataMetricList,
    filterMetricInfoList,
    datasetInstanceList,
    columnInfoMap,
    metricDatasetMap,
    latestDataTime,
    allMetricInfoList,
    k8CoverageMap,
  };
};

/**
 * calculationEventLogic is a function that make event data tranform to a new metric event data
 * @param {Array} eventList - original event list
 * @param {Array} instanceList - instanceId list
 * @param {Number} samplingInterval - project sampling interval time
 * @param {Number} topestRank - default 100
 * @returns {Object} - returns a lot of data
 */
const calculationEventLogic = (eventList, instanceList, samplingInterval, topestRank) => {
  // Get the metric and instance list in the event
  const metricRootCauseMap = {};
  const instanceDownRootCauseList = [];
  let eventMetricList = [];
  const blueColor = '#2185d0';
  const newEventList = R.filter(
    (e) => Boolean(e),
    R.addIndex(R.map)((e, idx) => {
      const { startTimestamp: eventStartTimestamp, duration, neuronId } = e;
      const rootCauseDetailsArr = get(e, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      const eventEndTimestamp = eventStartTimestamp + duration * 1000 * 60;

      let patternIds = [];
      const rootCauseList = R.filter(
        (r) => Boolean(r),
        R.map((rc) => {
          patternIds.push(rc.patternId);

          const timePairArr = rc.timePairArr || [];
          const { rootCauseMetric: metric, rootCauseSource: source, instanceId, appName, direction } = rc;
          // Ignore the event not in the instance list
          if (instanceList.includes(instanceId)) {
            const { metricValue, pct: percent } = rc;
            const isInstanceDown = source === 'missing data';
            const isHigher = direction === 'higher';
            const isLower = direction === 'lower';
            eventMetricList.push(metric);
            // don't '#a50026'，replace with '#ff5142'
            let color = isLower
              ? blueColor
              : calcColorOfAnomalyLevel(calcAnomalyLevel(Math.abs(percent))).color === '#a50026'
              ? '#ff5142'
              : calcColorOfAnomalyLevel(calcAnomalyLevel(Math.abs(percent))).color;
            if (isInstanceDown) {
              color = calcColorOfAnomalyLevel(calcAnomalyLevel(topestRank)).color || color;
            }

            const timeRangeList = R.map((item) => {
              const { startTimestamp, endTimestamp, duration } = item;
              return {
                startTimestamp: floor(startTimestamp / samplingInterval) * samplingInterval,
                endTimestamp: ceil(endTimestamp / samplingInterval) * samplingInterval,
                duration,
              };
            }, timePairArr || []);
            const nrc = {
              ...rc,
              startTimestamp: timePairArr.length > 0 ? timePairArr[0].startTimestamp : eventStartTimestamp,
              endTimestamp: timePairArr.length > 0 ? timePairArr[0].endTimestamp : eventEndTimestamp,
              needHighlightPading: false,
              timeRangeList,
              appName,
              instanceId,
              metric,
              source,
              metricValue,
              percent,
              pct: Math.abs(percent),
              rootCauseSource: source,
              rootCauseMetric: metric,
              direction,
              isInstanceDown,
              isHigher,
              isLower,
              color,
            };
            const rcList = metricRootCauseMap[metric] || [];
            rcList.push(nrc);
            metricRootCauseMap[metric] = rcList;
            if (isInstanceDown) {
              instanceDownRootCauseList.push(nrc);
            }
            return nrc;
          }
          return null;
        }, rootCauseDetailsArr),
      );
      patternIds = R.uniq(patternIds);
      if (rootCauseList.length > 0) {
        return {
          ...e,
          index: eventList.length - idx,
          timestamp: eventStartTimestamp,
          startTimestamp: eventStartTimestamp,
          endTimestamp: eventEndTimestamp,
          duration,
          neuronId,
          patternIds,
          rootCauseList,
          rootCauseJson: { rootCauseDetailsArr: rootCauseList },
        };
      }
      return null;
    }, eventList),
  );
  eventMetricList = R.filter((m) => Boolean(m), R.uniq(eventMetricList));
  return { eventMetricList, metricRootCauseMap, instanceDownRootCauseList, newEventList };
};

/**
 * calculationWithBaseLineLogic is function that mixs the baseline data with the original data
 * @param {Object} eventLineChartBaselineData - baseline data
 * @param {Object} metricDatasetMap - calculationLogic func return metric data
 * @param {Object} columnInfoMap - column info map
 * @returns {Object} - returns a lot of data
 */
const calculationWithBaseLineLogic = (eventLineChartBaselineData, metricDatasetMap, columnInfoMap) => {
  let newMetricDatasetMap = { ...metricDatasetMap };
  newMetricDatasetMap = R.mapObjIndexed((val, key) => {
    const baselineData = get(eventLineChartBaselineData, key, {});
    let { data } = val;
    const { labels } = val;
    data = R.map((item) => {
      const [timeObj, ...instanceVals] = item;
      const timestamp = timeObj.valueOf();

      const newInstanceVals = R.addIndex(R.map)((instanceVal, idx) => {
        const label = labels[idx + 1];
        const instanceId = get(columnInfoMap, [label, 'instanceId'], label);

        if (get(baselineData, [timestamp, instanceId])) {
          const info = get(baselineData, [timestamp, instanceId]);
          const upperBound = isNumber(info.upperBound) ? info.upperBound : instanceVal;
          const lowerBound = isNumber(info.lowerBound) ? info.lowerBound : instanceVal;
          let drawUpperBound = upperBound;
          let drawLowerBound = lowerBound;

          if (isNumber(upperBound) && isNumber(lowerBound)) {
            const diff = Math.abs(upperBound - lowerBound);
            if (diff === 0 || diff < Math.abs(upperBound) * 0.04) {
              drawUpperBound = upperBound + upperBound * 0.02;
              drawLowerBound = upperBound - upperBound * 0.02;
            }
          }

          return [drawLowerBound, instanceVal, drawUpperBound, lowerBound, upperBound];
        }
        return Number.isNaN(instanceVal) ? null : [NaN, instanceVal, NaN];
      }, instanceVals);
      return [timeObj, ...newInstanceVals];
    }, data || []);
    return { ...val, data };
  }, metricDatasetMap);
  return newMetricDatasetMap;
};
export { calculationLogic, calculationEventLogic, calculationWithBaseLineLogic };
