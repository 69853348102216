/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import momenttz from 'moment-timezone';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Alert, Descriptions } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { Defaults, buildUrl, LogRenderers } from '../../../common/utils';

import { CollapsibleLogContent } from '../../share';
import { Container, List, CellMeasurerCache, CellMeasurer, AutoSizer, Popover } from '../../../lib/fui/react';
import { appFieldsMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { queryFieldMessages } from '../../../common/query/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

type Props = {
  intl: Object,
  width: Number,
  height: Number,
  // eslint-disable-next-line
  queryResult: Object,
  // eslint-disable-next-line
  queryParams: Object,

  userInfo: Object,
  currentTheme: String,
};

class CommonlyLogPatternsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = 32;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.state = {
      sampleMsgList: [],
      incident: null,

      activeItem: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return null;
  }

  componentDidMount() {
    this.convertData(this.props.queryResult);
  }

  // In between before real DOM updates (pre-commit)
  // has access of 'this'
  // return object will be captured in componentDidUpdate
  getSnapshotBeforeUpdate(prevProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Typical usage (don't forget to compare props):
    if (this.props.queryResult !== prevProps.queryResult) {
      this.convertData(this.props.queryResult);
    }
  }

  @autobind
  convertData(queryResult) {
    let sampleMsgList = queryResult || [];

    sampleMsgList = R.addIndex(R.map)((item, index) => {
      return {
        ...item,
        id: index + 1,
      };
    }, sampleMsgList);
    const incident = sampleMsgList.length > 0 ? sampleMsgList[0] : null;

    this.setState({ sampleMsgList, incident });
  }

  @autobind
  handleEventClick(rowData) {
    this.setState({ incident: rowData }, () => {
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
    });
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }) {
    const { sampleMsgList, incident } = this.state;
    const rowData = sampleMsgList[rowIndex];
    if (!rowData) return null;

    const active = incident && incident.id === rowData.id;
    const content = (
      <div
        className={`event-list-row ${active ? ' active' : ''}${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
        style={{ ...style, minHeight: 40, cursor: 'pointer' }}
        onClick={() => this.handleEventClick(rowData)}
      >
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {this.contentRenderer(rowData)}
        </div>
      </div>
    );

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        {content}
      </CellMeasurer>
    );
  }

  @autobind
  contentRenderer(rowData) {
    const { patternIdList } = rowData;
    const patternList = R.map((item) => {
      const component = item.split('[')[0];
      const patternId = item.split('[')[1].split(']')[0];
      return {
        component,
        patternId,
      };
    }, patternIdList || []);

    return (
      <Popover
        placement="right"
        content={
          <div className="flex-col" style={{ maxWidth: 350, maxHeight: 300, overflowY: 'auto', fontSize: 12 }}>
            {R.addIndex(R.map)(
              (item, idx) => (
                <div key={idx}>
                  <span style={{ color: Defaults.ColorStatusFont.info }}>{item.component}</span>
                  <span style={{ padding: '0 2px', color: 'green' }}>[{item.patternId}]</span>
                </div>
              ),
              patternList,
            )}
          </div>
        }
      >
        <div className="hidden-line-with-ellipsis">
          {R.addIndex(R.map)(
            (item, index) => (
              <span key={index} style={{ padding: '0 4px' }}>
                <span style={{ color: Defaults.ColorStatusFont.info }}>{item.component}</span>
                <span style={{ padding: '0 2px', color: 'green' }}>[{item.patternId}]</span>
              </span>
            ),
            patternList,
          )}
        </div>
      </Popover>
    );
  }

  @autobind
  renderCommonPatternInstance(idx, item) {
    const { intl, currentTheme } = this.props;
    const { projectName, componentName, patternId, instanceNameList, sampleMsg: content } = item;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(content);
    } catch (error) {
      // console.error(error);
    }

    return (
      <div key={idx} className="ant-descriptions-table" style={{ padding: 0, margin: '0 0 16px 0' }}>
        <Descriptions bordered size="small" title="Component Pattern Info">
          <Descriptions.Item label={intl.formatMessage(eventMessages.projectName)} span={1}>
            {projectName}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(eventMessages.componentName)} span={1}>
            {componentName}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(eventMessages.patternId)} span={1}>
            {patternId}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(DashboardMessages.instances)} span={3}>
            {R.addIndex(R.map)(
              (item, index) => (
                <span key={index} style={{ padding: '0 4px', color: 'green' }}>
                  {`${item}${index < R.min(instanceNameList.length, 3) - 1 ? ',' : ''}`}
                </span>
              ),
              R.take(3, instanceNameList),
            )}
            {instanceNameList.length > 3 && (
              <Popover
                placement="right"
                content={
                  <div className="flex-col" style={{ width: 350, maxHeight: 300, overflowY: 'auto', fontSize: 12 }}>
                    {R.addIndex(R.map)(
                      (item, idx) => (
                        <div key={idx}>
                          <span style={{ paddingRight: 8, fontWeight: 'bold' }}>
                            {intl.formatMessage(eventMessages.instanceName)}:
                          </span>
                          <span>{item}</span>
                        </div>
                      ),
                      instanceNameList,
                    )}
                  </div>
                }
                trigger="click"
              >
                <Button type="link" style={{ padding: 0, height: 'auto', fontSize: 12 }}>
                  {intl.formatMessage(appFieldsMessages.expand)}
                </Button>
              </Popover>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(logMessages.sampleLog)} span={3}>
            <div style={{ padding: 4, wordBreak: 'break-all' }}>
              {`${content.substring(0, 300)}...`}
              <Popover
                placement="right"
                content={
                  <div
                    className="log-event-group flex-col"
                    style={{
                      width: 450,
                      maxHeight: 300,
                      overflow: 'auto',
                      fontSize: 12,
                      padding: '0 0',
                    }}
                  >
                    {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                    {!rawDataJson && (
                      <CollapsibleLogContent ownerObject={item} message={content} width={450} hideExpanded />
                    )}
                  </div>
                }
                trigger="click"
              >
                <Button
                  type="link"
                  style={{
                    padding: 0,
                    height: 'auto',
                    fontSize: 12,
                  }}
                >
                  {intl.formatMessage(appFieldsMessages.expand)}
                </Button>
              </Popover>
            </div>
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }

  @autobind
  handlePatternJumpClick(fields) {
    const { userInfo } = this.props;
    const { isAdmin, isReadUser, isLocalAdmin, userName } = userInfo;
    const { activeItem } = this.state;
    const { userName: projectOwner, patternId } = activeItem;
    let { projectName } = activeItem;
    const isSharedUser = projectOwner !== userName;
    if (isAdmin || isReadUser || isLocalAdmin || isSharedUser) {
      projectName = `${projectName}@${projectOwner}`;
    }

    const instance = fields.instance.value;
    const dateRange = fields.dateRange.value;
    // antd datepick need reset offset if it's auto control
    const zone = momenttz.tz(momenttz.tz.guess());
    const offset = zone.utcOffset() * 60 * 1000;

    const query = {
      t: '953de6a33d8a4b96ac9c100bf69ba3fc',
      projectName,
      instanceName: instance,
      startTime: dateRange[0].utcOffset(-offset).startOf('day').valueOf(),
      endTime: dateRange[1].utcOffset(-offset).endOf('day').valueOf(),
      pattern: patternId,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    this.setState({ showPatternJumpModal: false });
  }

  render() {
    const { intl, width, height } = this.props;
    const { sampleMsgList, incident } = this.state;
    return (
      <Container className="content flex-col" style={{ height, width }}>
        <Container className="flex-grow flex-col flex-min-height overflow-y-auto" style={{ padding: '8px 16px' }}>
          {R.isEmpty(sampleMsgList) && (
            <Alert message={intl.formatMessage(queryFieldMessages.noResult)} type="info" showIcon />
          )}
          {sampleMsgList.length > 0 && (
            <div className="flex-grow flex-row flex-min-height">
              <div className="flex-grow">
                <AutoSizer>
                  {({ width, height }) => (
                    <div className="event-list">
                      <div className="event-list-header" style={{ height: this.listHeaderHeight, width }}>
                        <div className="header-column" style={{ width: 100, flex: 1 }}>
                          Common pattern list
                        </div>
                      </div>
                      <List
                        className="event-list-grid"
                        ref={(listNode) => {
                          this.listNode = listNode;
                        }}
                        width={width}
                        height={height - this.listHeaderHeight}
                        rowCount={sampleMsgList.length}
                        overscanRowCount={10}
                        deferredMeasurementCache={this.cellMeasureCache}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowRenderer={this.renderListItem}
                      />
                    </div>
                  )}
                </AutoSizer>
              </div>
              <div className="flex-col overflow-y-auto" style={{ flex: 2, padding: '0 16px' }}>
                {!incident && (
                  <Alert message="Warning" description="No sample message selected." type="warning" showIcon />
                )}
                {incident &&
                  R.addIndex(R.map)(
                    (item, idx) => this.renderCommonPatternInstance(idx, item),
                    incident.commonPatternInstances || [],
                  )}
              </div>
            </div>
          )}
        </Container>
      </Container>
    );
  }
}

const CommonlyLogPatterns = injectIntl(CommonlyLogPatternsCore);
export default connect((state) => {
  const { userInfo } = state.auth;
  const { currentTheme } = state.app;
  return {
    userInfo,
    currentTheme,
  };
}, {})(CommonlyLogPatterns);
