import React, { useEffect, useReducer, useState } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { message } from 'antd';
import { Defaults } from '../../../common/utils';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import fetchGet from '../../../common/apis/fetchGet';
import { BuildBaseInfo } from '../components/BuildBaseInfo';

export default function InsightsHeader(props: Object) {
  const {
    summaryObj,
    startTime,
    endTime,
    credentials,
    systemInfo,
    systemId,
    currentViewDataStatistics,
    customerName,
    systemIdsWithShare,
    currentTheme,
  } = props;
  const [state, setState] = useReducer((oldVla, newVal) => ({ ...oldVla, ...newVal }), {
    pastTimesSummaryObj: {},
    perviousViewDataStatistics: {},
    isLoading: false,
  });
  const { pastTimesSummaryObj, isLoading, perviousViewDataStatistics } = state;
  const startTimeObjValueOf = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
  const endTimeObjValueOf = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();

  const [pastStartTimesList] = useState(() => {
    const startTimeObj = moment.utc(startTime, Defaults.DateFormat);
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat);
    const diffTimeObj = endTimeObj.diff(startTimeObj, 'days') + 1;
    const startDate = startTimeObj.subtract(diffTimeObj, 'days').startOf('days').valueOf();
    const endDate = endTimeObj.subtract(diffTimeObj, 'days').endOf('days').valueOf();
    return [startDate, endDate];
  });

  useEffect(() => {
    const fetchStatistics = () => {
      const filterSystem = R.filter((item) => item.id === systemId, systemIdsWithShare || []);
      return fetchPost(getEndpoint('dashboard-summary'), {
        ...credentials,
        systemIdsWithShare: JSON.stringify(filterSystem),
        startTime: pastStartTimesList[0],
        endTime: pastStartTimesList[1],
      })
        .then((data) => {
          return data;
        })
        .catch((e) => {
          if (e?.code >= 500) {
            console.error(e.message || String(e));
          } else {
            message.error(e.message || String(e));
          }
        });
    };

    const fetchSummary = () => {
      return fetchGet(getEndpoint('dashboard-statistics'), {
        ...credentials,
        customerName: systemInfo?.customerName || customerName,
        systemName: systemId,
        startTime: pastStartTimesList[0],
        endTime: pastStartTimesList[1],
        cacheKey: 'InsightsHeader',
      })
        .then((data) => {
          return data;
        })
        .catch((e) => {
          if (e?.code >= 500) {
            console.error(e.message || String(e));
          } else {
            message.error(e.message || String(e));
          }
        });
    };
    setState({ isLoading: true });
    Promise.all([fetchStatistics(), fetchSummary()])
      .then((results) => {
        setState({ pastTimesSummaryObj: results[0] || {} });
        setState({ perviousViewDataStatistics: results[1] || {} });
        setState({ isLoading: false });
      })
      .catch((e) => {
        setState({ isLoading: false });
      });
  }, [systemId]);

  return (
    <>
      {currentViewDataStatistics && perviousViewDataStatistics && pastStartTimesList && (
        <BuildBaseInfo
          {...props}
          summaryObj={summaryObj}
          pastTimesSummaryObj={pastTimesSummaryObj}
          loading={isLoading}
          startTimeObj={startTimeObjValueOf}
          endTimeObj={endTimeObjValueOf}
          pastStartTimesList={pastStartTimesList}
          currentViewDataStatistics={currentViewDataStatistics}
          perviousViewDataStatistics={perviousViewDataStatistics}
          systemId={systemId}
          currentTheme={currentTheme}
        />
      )}
    </>
  );
}
