/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { get, isArray } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getLogCausalEventList = (credentials: Credentials, params: Object) => {
  const {
    customerName,
    operation,
    causalFetchedInfoFileName,
    causalKey,
    relationKey,
    startTimestamp,
    endTimestamp,
    fileName,
  } = params;

  const requests = [];
  requests.push(
    fetchGet(getEndpoint('eventsrelationprocessforevents'), {
      ...credentials,
      customerName,
      operation,
      causalFetchedInfoFileName,
    }).then((data) => {
      let logEntryCausalList = [];
      R.forEachObjIndexed((rclist, instanceId) => {
        if (isArray(rclist)) {
          R.forEach((relation) => {
            const { src, target, srcDetails, targetDetails, ...others } = relation;
            logEntryCausalList.push({
              ...others,
              srcDetails: { ...srcDetails, instanceId: src },
              targetDetails: { ...targetDetails, instanceId: target },
            });
          }, rclist);
        } else {
          R.forEachObjIndexed((rlist, scope) => {
            R.forEach((relation) => {
              const { src, target, srcDetails, targetDetails, ...others } = relation;
              logEntryCausalList.push({
                scope,
                ...others,
                srcDetails: { ...srcDetails, instanceId: scope === 'intra' ? instanceId : src },
                targetDetails: { ...targetDetails, instanceId: scope === 'intra' ? instanceId : target },
              });
            }, rlist);
          }, rclist);
        }
      }, get(data, ['data'], {}));
      logEntryCausalList = R.sort((a, b) => b.probability - a.probability, logEntryCausalList);
      return logEntryCausalList;
    }),
  );
  requests.push(
    fetchGet(getEndpoint('eventsrelationprocess'), {
      ...credentials,
      causalKey,
      relationKey,
      customerName,
      // causalName,
      startTimestamp,
      endTimestamp,
      fileName: `${fileName}_CausalProperty`,
      // joinDependency,
    }).then((d) => {
      const dataResult = JSON.parse(d.data || '{}');
      const { possibleIntraFlag, instancePropertyMap } = dataResult;
      const incidentCausalProperty = {
        possibleIntraFlag,
        instancePropertyMap: JSON.parse(instancePropertyMap || '{}'),
      };
      return incidentCausalProperty;
    }),
  );

  return Promise.all(requests).then((results) => {
    return {
      logEntryCausalList: results[0],
      incidentCausalProperty: results[1],
    };
  });
};

export default getLogCausalEventList;
