/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import { get } from 'lodash';
import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseJSON } from '../utils';

const getCausalGroupList = (credentials: Credentials, params) => {
  return fetchGet(getEndpoint('causalgroup'), {
    ...credentials,
  }).then((data) => {
    const groupMap = parseJSON(data.data) || {};
    let causalGroupList = [];

    R.forEachObjIndexed((val, key) => {
      const projectList = parseJSON(get(val, ['projectList'])) || [];
      const projectLevelSetting = parseJSON(get(val, ['projectLevelSetting'])) || [];
      const causalName = val.causalName || R.map((p) => `${p.projectName}-${p.grouping}`, projectList).join('-');
      const interval = Math.round(val.interval / (1000 * 60 * 60 * 24));
      const retentionTime = Math.round(val.retentionTime / (1000 * 60 * 60 * 24));
      const eventsRelationLookBackWindow = Number(val.eventsRelationLookBackWindow) / 60 / 60 / 24 / 1000;

      causalGroupList.push({
        ...val,
        causalName,
        causalKey: key,
        interval,
        retentionTime,
        customerName: val.owner,
        eventsRelationLookBackWindow,
        projectList,
        projectLevelSetting,
      });
    }, groupMap);

    causalGroupList = R.sort((a, b) => (a.causalName || '').localeCompare(b.causalName), causalGroupList);

    return {
      causalGroupList,
      defaultCausalMetricPairingThreshold: data?.defaultCausalMetricPairingThreshold || 43200000,
    };
  });
};

export default getCausalGroupList;
