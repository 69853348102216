import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import { get } from 'lodash';
import { sortableHandle } from 'react-sortable-hoc';
import { Empty, Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';

import { BaseUrls } from '../../app/Constants';
import { parseJSON, buildUrl } from '../../../common/utils';
import { MoveIcon } from '../../../lib/fui/icons';
import { EChart } from '../../../web/share';
import { appFieldsMessages } from '../../../common/app/messages';

const DragHandle = sortableHandle(() => (
  <span className="hover-display-item" style={{ cursor: 'move', position: 'absolute', right: 8, top: 8 }}>
    <MoveIcon style={{ fontSize: 16 }} />
  </span>
));

const InsightsRootCauseStats = (props) => {
  // eslint-disable-next-line
  const { intl, name, category } = props;
  const {
    // eslint-disable-next-line
    push,
    // eslint-disable-next-line
    updateLastActionInfo,
    // eslint-disable-next-line
    credentials,
    // eslint-disable-next-line
    userInfo,

    // eslint-disable-next-line
    refresh,
    // eslint-disable-next-line
    refreshTime,
    // eslint-disable-next-line
    environmentId,
    // eslint-disable-next-line
    systemId,
    // eslint-disable-next-line
    customerName,
    // eslint-disable-next-line
    startTime,
    // eslint-disable-next-line
    globalInfo,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [option, setOption] = useState(undefined);

  const buildChartOption = (category, dataList) => {
    return {
      tooltip: {
        trigger: 'item',
        confine: true,
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          const { name, color, value, data } = params;
          const { instanceName } = data || {};
          return ReactDOMServer.renderToStaticMarkup(
            <div className="flex-col flex-center-justify" style={{ maxWidth: 220 }}>
              <div className="flex-grow" style={{ whiteSpace: 'normal', wordBreak: 'break-all', marginRight: 16 }}>
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: 5,
                    borderRadius: 10,
                    width: 9,
                    height: 9,
                    backgroundColor: color,
                  }}
                />
                {name}
              </div>
              {category === 'instance/pattern' &&
                instanceName && (
                  <div style={{ paddingLeft: 14 }}>
                    {intl.formatMessage(appFieldsMessages.component)}: {instanceName}
                  </div>
                )}
              <div style={{ paddingLeft: 14 }}>
                {intl.formatMessage(appFieldsMessages.count)}: {value}
              </div>
            </div>,
          );
        },
      },
      series: [
        {
          animation: false,
          minAngle: 1,
          name: null,
          type: 'pie',
          radius: [0, '60%'],
          // selectedMode: 'single',
          hoverAnimation: false,
          data: R.map((item) => {
            if (category === 'instance/pattern') {
              const { instanceName, patternId, count } = item;
              return { name: patternId, value: count, instanceName, patternId };
            }
            return { name: item[0], value: item[1] };
          }, dataList),
          itemStyle: {
            emphasis: { shadowBlur: 4, shadowOffsetX: 0, shadowColor: 'rgba(0, 0, 0, 0.5)' },
            normal: { label: { show: true }, labelLine: { show: true } },
          },
        },
      ],
    };
  };

  useEffect(
    () => {
      let cancel = false;

      // UNSAFE_componentWillReceiveProps
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (
        ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
        environmentId &&
        systemId &&
        systemInfo &&
        startTime
      ) {
        setLoading(true);
        updateLastActionInfo();
        fetchGet(getEndpoint('insights/RootCauseStats'), {
          ...credentials,
          environmentName: environmentId,
          systemIds: JSON.stringify([{ id: systemId }]),
          customerName: systemInfo.ownerUserName,
          startTime,
          categories: JSON.stringify(category === 'category' ? ['metric category', 'log category'] : [category]),
        })
          .then((data) => {
            if (cancel) return;
            const rootCauseMap = get(parseJSON(get(data, 'RootCauseMap')) || {}, systemId, {});
            let dataList = [];
            switch (category) {
              case 'instance/pattern': {
                dataList = get(rootCauseMap, 'counterInstancePatternIdList', []);
                dataList = R.take(10, R.sortWith([R.descend(R.prop('count'))], dataList));
                break;
              }
              case 'type': {
                const counterTypeMap = get(rootCauseMap, 'counterTypeMap', {});
                dataList = R.toPairs(counterTypeMap);
                dataList = R.take(10, R.sortWith([R.descend(R.prop(1))], dataList));
                break;
              }
              case 'category': {
                const metricCounterCategoryMap = get(rootCauseMap, 'counterMetricCategoryMap', {});
                const logCounterCategoryMap = get(rootCauseMap, 'counterLogCategoryMap', {});
                let metricList = R.toPairs(metricCounterCategoryMap);
                metricList = R.take(5, R.sortWith([R.descend(R.prop(1))], metricList));
                let logList = R.toPairs(logCounterCategoryMap);
                logList = R.take(5, R.sortWith([R.descend(R.prop(1))], logList));
                dataList = [...metricList, ...logList];
                break;
              }
              case 'details': {
                const counterDetailsMap = get(rootCauseMap, 'counterDetailsMap', []);
                dataList = R.map((item) => {
                  const { content } = item[0] || {};
                  return [content, item[1]];
                }, counterDetailsMap);
                dataList = R.take(10, R.sortWith([R.descend(R.prop(1))], dataList));
                break;
              }
              default:
                break;
            }

            const option = buildChartOption(category, dataList);
            setOption(option);
            setHasData(dataList.length > 0);
            setLoading(false);
          })
          .catch((err) => {
            if (cancel) return;
            console.error(err);
            setHasData(false);
            setLoading(false);
          });
      }

      return () => (cancel = true);
    },
    [refresh, refreshTime, environmentId, systemId, customerName, category],
  );

  const onPieChartClick = (category, item) => {
    if (category === 'instance/pattern') {
      const { name } = item;
      const patternIdMatch = R.match(/^Pattern\[(\d+)\]/, name);
      const patternId = patternIdMatch.length > 0 ? patternIdMatch[1] : undefined;

      const query = {
        environmentId,
        systemId,
        customerName,
        startTime,
        endTime: startTime,

        patternIdFilter: patternId,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    }
  };

  return (
    <div
      className="full-height full-width flex-col health-card hover-display"
      style={{ backgroundColor: 'white', borderRadius: '2em', padding: '8px 8px', position: 'relative' }}
    >
      <div
        className="flex-row flex-center-justify bold link"
        style={{ textAlign: 'center', margin: '0 14px 8px 14px', height: 34 }}
        onClick={() => {
          push(buildUrl(BaseUrls.GlobalSystemRootCause, {}, { startTime, customerName, environmentId, systemId }));
        }}
      >
        {name}
      </div>
      <DragHandle />

      <Spin
        spinning={isLoading}
        wrapperClassName="spin-full-height flex-grow flex-center-align flex-center-justify overflow-hidden"
      >
        {!hasData && (
          <div className="full-height flex-row flex-center-align flex-center-justify">
            <Empty imageStyle={{ height: 60 }} />
          </div>
        )}
        {hasData && (
          <EChart width={'100%'} height={'100%'} option={option} onClick={(item) => onPieChartClick(category, item)} />
        )}
      </Spin>
    </div>
  );
};

const InsightsRootCauseInstancePattern = (props) => {
  return <InsightsRootCauseStats {...props} category={'instance/pattern'} />;
};
const InsightsRootCauseType = (props) => {
  return <InsightsRootCauseStats {...props} category={'type'} />;
};
const InsightsRootCauseCategory = (props) => {
  return <InsightsRootCauseStats {...props} category={'category'} />;
};

export { InsightsRootCauseInstancePattern, InsightsRootCauseType, InsightsRootCauseCategory };
