import React from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import ThundraTraceChart from 'thundra-trace-chart';

import { EventRenderers, LogRenderers } from '../../../common/utils';
import { Modal } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';

// import { appFieldsMessages } from '../../../common/app/messages';

type Props = {
  // eslint-disable-next-line
  title: String,
  // eslint-disable-next-line
  tabName: String,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  events: Array<Object>,
  isLoading: Boolean,
  // eslint-disable-next-line
  onClose: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  currentTheme: String,
};

class TraceListModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      traceListAll: [],
    };
    this.spanDetails = {};
    this.spanEventMap = {};
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.events !== this.props.events) {
      this.parseData(nextProps);
    }
  }

  @autobind
  parseData(props) {
    this.setState({ isLoading: true });

    const { intl, userInfo, projects, currentTheme } = props;
    const { events } = props;

    // parse data
    const traceInfoMap = {};
    const spanDetails = {};
    const spanEventMap = {};
    R.forEach((item) => {
      const { traceInfo } = item;
      try {
        const { traceId, id } = traceInfo || {};
        if (!R.has(traceId, traceInfoMap)) traceInfoMap[traceId] = [];
        if (!R.has(id, spanDetails)) {
          spanDetails[id] = (
            <EventRenderers.RenderTraceDetails
              intl={intl}
              userInfo={userInfo}
              projects={projects}
              incident={item}
              currentTheme={currentTheme}
            />
          );
        }
        traceInfoMap[traceId].push(traceInfo);

        spanEventMap[id] = item;
      } catch (err) {
        console.error(err);
      }
    }, events || []);

    // build traceListAll
    const traceListAll = [];
    R.forEachObjIndexed((traceList, traceId) => {
      traceListAll.push({
        traceId,
        traceList,
      });
    }, traceInfoMap);

    this.spanDetails = spanDetails;
    this.spanEventMap = spanEventMap;
    this.setState({ isLoading: false, traceListAll }, () => {});
  }

  render() {
    const { onClose, title, isLoading: isLoadingData } = this.props;
    const { isLoading, traceListAll } = this.state;

    return (
      <Modal
        title={title}
        width={1200}
        visible
        maskClosable={false}
        footer={null}
        onCancel={() => onClose()}
        bodyStyle={{ height: 560 }}
      >
        <Spin wrapperClassName="full-width full-height spin-full-height" spinning={isLoadingData || isLoading}>
          <div className="full-height full-width flex-col overflow-y-auto">
            {R.map((item) => {
              const { traceId, traceList } = item;
              return (
                <div key={traceId} className="flex-row">
                  <div className="flex-col" style={{ width: 400, padding: '10px 16px 10px 0' }}>
                    {R.map((item) => {
                      const { id, serviceName, name } = item;
                      const rowData = this.spanEventMap[id];
                      const { outlierValue } = rowData || {};
                      return (
                        <div key={id} className="flex-col" style={{ marginBottom: 8 }}>
                          <div className="flex-row" style={{ wordBreak: 'break-word' }}>
                            <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
                              Service Name:
                            </span>
                            <span style={{ marginLeft: 8 }}>{serviceName}</span>
                          </div>
                          <div className="flex-row" style={{ wordBreak: 'break-word' }}>
                            <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
                              Span Info:
                            </span>
                            <span style={{ marginLeft: 8 }}>{name}</span>
                          </div>
                          <LogRenderers.RenderOutlierValue
                            className="flex-row"
                            style={{ wordBreak: 'break-word' }}
                            outlierValue={outlierValue}
                            isTrace
                          />
                        </div>
                      );
                    }, traceList)}
                  </div>

                  <div key={traceId} className="flex-grow trace-timelines">
                    <ThundraTraceChart
                      traceId={traceId}
                      traceSummary={traceList}
                      spanDetails={this.spanDetails}
                      showHeader={false}
                      showMiniTrace={false}
                      showSpanDetailTitle={false}
                      serviceNameColumnTitle="Service Name (Click rows for details)"
                      spanInfoColumnTitle="Span Info"
                    />
                  </div>
                </div>
              );
            }, traceListAll)}
          </div>
        </Spin>
      </Modal>
    );
  }
}

const TraceListModal = injectIntl(TraceListModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, projects, systemsMap, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      location,
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,
      currentTheme,
    };
  },
  { updateLastActionInfo },
)(TraceListModal);
