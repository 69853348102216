/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { get } from 'lodash';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Spin, message, Alert, Popconfirm } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Container, Table, Column, AutoSizer } from '../../../lib/fui/react';
// import { Defaults } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appButtonsMessages, appMessages } from '../../../common/app/messages';
import { parseJSON } from '../../../common/utils';

type Props = {
  refresh: Number,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  replace: Function,
  updateLastActionInfo: Function,
};

class ServiceSlackWorkspaceModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      eventList: [],
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refresh !== nextProps.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials, userInfo } = props;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('authenticate-slack'), {
      ...credentials,
      list: true,
    })
      .then(async (data) => {
        const systemTeamsMap = {};
        R.forEach((item) => {
          const { systemName } = item;
          systemTeamsMap[systemName] = item;
        }, data || []);

        let { systemsMap } = props;
        if (userInfo.isAdmin || userInfo.isLocalAdmin) {
          let userNameList = R.values(systemTeamsMap);
          userNameList = R.map((item) => item.userName, userNameList);
          userNameList = R.uniq(userNameList);
          systemsMap = await this.getUserSystemMap(userNameList);
        }

        // get all system list
        let systemList = R.filter((system) => !system.isShared, R.values(systemsMap));
        systemList = R.sortWith(
          [R.ascend(R.compose(R.toLower, R.prop('owner'))), R.ascend(R.compose(R.toLower, R.prop('systemName')))],
          systemList,
        );

        // merge info
        const eventList = [];
        R.forEach((item) => {
          const systemTeamsInfo = get(systemTeamsMap, item.systemId);
          if (systemTeamsInfo) {
            eventList.push({ ...systemTeamsInfo, ...item });
          } else {
            eventList.push(item);
          }
        }, systemList);

        this.setState({ isLoading: false, errMsg: null, eventList });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, errMsg: err.message || String(err), eventList: [] });
      });
  }

  @autobind
  getUserSystemMap(userNameList) {
    const { credentials } = this.props;
    const requests = [];
    R.forEach((customerName) => {
      requests.push(
        fetchGet(getEndpoint('systemframework', 2), {
          ...credentials,
          customerName,
        }),
      );
    }, userNameList);
    return Promise.all(requests)
      .then((data) => {
        let allSystemMap = {};
        R.forEach((item) => {
          let { ownSystemArr } = item;
          ownSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system };
          }, ownSystemArr || []);
          const systemsMap = {};
          R.forEach(
            (system) => {
              const { systemDisplayName, systemKey, projectDetailsList, isShared, ...rest } = system;
              const { environmentName, systemName: systemId, userName } = systemKey || {};
              const newProjects = parseJSON(projectDetailsList) || [];
              systemsMap[systemId] = {
                ...rest,
                isShared,
                environmentName,
                systemId,
                systemName: systemDisplayName || systemId,
                owner: userName,
                projectDetailsList: newProjects,
              };
            },
            [...ownSystemArr],
          );
          allSystemMap = { ...allSystemMap, ...systemsMap };
        }, data || []);
        return allSystemMap;
      })
      .catch((e) => {
        console.log(String(e));
      });
  }

  @autobind
  handleBindTeamClick({ rowData, rowIndex }) {
    const { intl, credentials } = this.props;

    this.setState((prevState) => ({
      eventList: update(prevState.eventList, {
        [rowIndex]: { $set: { ...(prevState.eventList[rowIndex] || {}), isLoading: true } },
      }),
    }));
    const { owner, systemId, environmentName } = rowData;
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('authenticate-slack'), {
      ...credentials,
      environmentName,
      customerName: owner,
      systemName: systemId,
      list: false,
    })
      .then((data) => {
        const { success, message: errMsg, redirectUrl } = data || {};
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState((prevState) => ({
            errMsg: null,
            eventList: update(prevState.eventList, {
              [rowIndex]: { $set: { ...(prevState.eventList[rowIndex] || {}), isLoading: false } },
            }),
          }));
          window.open(redirectUrl, '_blank');
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState((prevState) => ({
            errMsg,
            eventList: update(prevState.eventList, {
              [rowIndex]: { $set: { ...(prevState.eventList[rowIndex] || {}), isLoading: false } },
            }),
          }));
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState((prevState) => ({
          errMsg: err.message || String(err),
          eventList: update(prevState.eventList, {
            [rowIndex]: { $set: { ...(prevState.eventList[rowIndex] || {}), isLoading: false } },
          }),
        }));
      });
  }

  @autobind
  teamNameRenderer({ cellData }) {
    return <div>{cellData || '--'}</div>;
  }

  @autobind
  controlRenderer({ rowData, rowIndex }) {
    const { intl } = this.props;
    const { isLoading, isDdeleteLoading } = rowData;
    return (
      <div className="flex-row flex-end-justify">
        <Button
          size="small"
          type="primary"
          style={{ marginLeft: 8 }}
          loading={isLoading}
          onClick={() => this.handleBindTeamClick({ rowData, rowIndex })}
        >
          <EditOutlined /> Go to Slack
        </Button>
        <Popconfirm
          placement="topRight"
          title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
          onConfirm={() => this.handleRemove({ rowData, rowIndex })}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button
            size="small"
            className="button-color-grey"
            loading={isDdeleteLoading}
            style={{ marginLeft: 8 }}
            onClick={(event) => event.stopPropagation()}
          >
            <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.delete)}
          </Button>
        </Popconfirm>
      </div>
    );
  }

  @autobind
  handleRemove({ rowData, rowIndex }) {
    const { intl, credentials } = this.props;
    const { owner, systemId } = rowData;

    this.setState((prevState) => ({
      eventList: update(prevState.eventList, {
        [rowIndex]: { $set: { ...(prevState.eventList[rowIndex] || {}), isDdeleteLoading: true } },
      }),
    }));

    this.props.updateLastActionInfo();
    fetchDelete(getEndpoint('authenticate-slack'), {
      ...credentials,
      customerName: owner,
      systemName: systemId,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState(
            (prevState) => ({
              errMsg: null,
              eventList: update(prevState.eventList, {
                [rowIndex]: { $set: { ...(prevState.eventList[rowIndex] || {}), isDdeleteLoading: false } },
              }),
            }),
            () => {
              this.reloadData(this.props);
            },
          );
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState((prevState) => ({
            errMsg,
            eventList: update(prevState.eventList, {
              [rowIndex]: { $set: { ...(prevState.eventList[rowIndex] || {}), isDdeleteLoading: false } },
            }),
          }));
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState((prevState) => ({
          errMsg: err.message || String(err),
          eventList: update(prevState.eventList, {
            [rowIndex]: { $set: { ...(prevState.eventList[rowIndex] || {}), isDdeleteLoading: false } },
          }),
        }));
      });
  }

  render() {
    const { isLoading, errMsg, eventList } = this.state;
    return (
      <Container className="full-width full-height flex-col">
        {errMsg && <Alert message={errMsg} type="error" showIcon style={{ marginBottom: 8 }} />}
        <div className="flex-grow flex-min-height">
          <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  rowCount={eventList.length}
                  rowGetter={({ index }) => eventList[index]}
                >
                  <Column width={100} label="User name" dataKey="owner" />
                  <Column width={180} label="System name" dataKey="systemName" flexGrow={1} />
                  <Column
                    width={180}
                    label="Workspace name"
                    dataKey="teamName"
                    flexGrow={1}
                    cellRenderer={this.teamNameRenderer}
                  />
                  <Column width={230} label="" dataKey="systemName" cellRenderer={this.controlRenderer} />
                </Table>
              )}
            </AutoSizer>
          </Spin>
        </div>
      </Container>
    );
  }
}

const ServiceSlackWorkspaceModal = injectIntl(ServiceSlackWorkspaceModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return { location, systemsMap, userInfo, credentials };
  },
  { replace, updateLastActionInfo },
)(ServiceSlackWorkspaceModal);
