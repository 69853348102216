import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';
import { Button, message, Space, Table, Dropdown, Menu, Tabs, Input } from 'antd';
import { PlusCircleOutlined, CloseCircleOutlined, FormOutlined, DownOutlined } from '@ant-design/icons';
import { AutoSizer } from 'react-virtualized';
import moment from 'moment';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
// eslint-disable-next-line import/no-cycle
import { BatchContext } from '../pageContext';
import fetchDelete from '../../../../common/apis/fetchDelete';
import { Defaults } from '../../../../common/app';
import { appButtonsMessages, appMessages } from '../../../../common/app/messages';
import { eventActionMessages } from '../../../../common/metric/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { logMessages } from '../../../../common/log/messages';
import { parseLocation } from '../../../../common/utils';

const { Column } = Table;

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

const menu = (intl, handleDelete, handleClick) => {
  const iconStyle = { fontSize: 12, marginRight: 5 };
  return (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <div onClick={handleClick}>
              <FormOutlined style={iconStyle} />
              {intl.formatMessage(appButtonsMessages.edit)}
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div onClick={handleDelete}>
              <CloseCircleOutlined style={iconStyle} />
              {intl.formatMessage(appButtonsMessages.remove)}
            </div>
          ),
        },
      ]}
    />
  );
};

type Props = {
  onSetPageData: Function,
  onShowLoading: Function,
  onHideLoading: Function,
  onNext: Function,
  refresh: String,
};

const Step1 = ({ onNext, onSetPageData, onShowLoading, onHideLoading, refresh, ...props }: Props) => {
  const { intl, isAdmin, credentials, hideAppLoader, showAppLoader, location, userInfo, userList } =
    useContext(BatchContext);
  const { userName } = credentials;
  const [tableData, setTableData] = useState([]);
  const [localTableData, setLocalTableData] = useState([]);

  const parseTableData = (data) => {
    const tableData = R.map((n) => {
      const { config, customerDefinedProjectConfigKey, lastUpdateTimestamp, projectListStr } = n;
      const {
        configName,
        customerDefinedProjectConfigPartitionKey: { dataType },
      } = customerDefinedProjectConfigKey;
      const projectList = JSON.parse(projectListStr || '[]');
      return {
        config: JSON.parse(config),
        batchName: configName,
        dataType,
        lastUpdateTimestamp,
        key: uuidv4(),
        customerDefinedProjectConfigKey,
        customerName: projectList[0] ? projectList[0].customerName : null,
        projectList,
      };
    }, data);
    setLocalTableData(tableData);
    setTableData(tableData);
  };

  const reloadData = () => {
    showAppLoader();
    fetchPost(getEndpoint('customer-define-config', 2), {
      ...credentials,
      customerName: userName,
    })
      .then((res) => {
        parseTableData(res);
        hideAppLoader();
      })
      .catch((e) => {
        message.error(e.message);
        hideAppLoader();
      });
  };

  useEffect(() => {
    reloadData();
    onSetPageData({}, true);
  }, [refresh]);

  const handleClick = (type, rowData = {}) => {
    const { customerName } = parseLocation(location);
    let initPageData = {
      pageType: type,
      owner: isAdmin ? customerName || userList[0]?.userName || 'user' : userInfo.userName,
      selectedProjects: null,
      showModal: true,
      batchName: '',
      dataType: '',
    };
    if (type === 'edit') {
      const { dataType, batchName, config, customerName, projectList } = rowData;
      initPageData = {
        ...initPageData,
        batchName,
        dataType,
        owner: isAdmin ? customerName : userInfo.userName,
        config: { ...config },
        selectedProjects: projectList,
      };
    }
    onSetPageData(initPageData);
  };

  const handleDelete = (rowData) => {
    const { customerDefinedProjectConfigKey } = rowData;
    onShowLoading();
    fetchDelete(getEndpoint('customer-define-config', 2), {
      CustomerDefinedProjectConfigKeyList: JSON.stringify([customerDefinedProjectConfigKey]),
      customerName: userName,
    })
      .then((res) => {
        reloadData();
        onHideLoading();
        message.success(intl.formatMessage(appMessages.apiSuccess));
      })
      .catch((e) => {
        message.error(e.message);
        onHideLoading();
      });
  };

  const handleChangeSearch = (e, field) => {
    const { value } = e.target;
    const newVal = value.toString().toLowerCase();
    const result = R.filter((n) => n[field].toLowerCase().indexOf(newVal) >= 0, localTableData);
    setTableData(R.isEmpty(value) ? localTableData : result);
  };

  const BuildTableFooter = () => {
    return (
      <div className="batch-table-footer" style={{ width: 'calc(100% - 8px)' }}>
        <Button icon={<PlusCircleOutlined />} type="primary" onClick={() => handleClick('create')}>
          Add new configuration
        </Button>
      </div>
    );
  };

  return (
    <div className="step-project-list corner-10">
      <Tabs defaultActiveKey="1" type="card">
        <Tabs.TabPane
          tab={`${intl.formatMessage(settingsMessages.bacthConfigurations)} ${tableData.length} ${intl.formatMessage(
            logMessages.total,
          )}`}
          key="projectList"
          style={{ maxHeight: 0, minHeight: 0 }}
        />
      </Tabs>
      <Space style={{ marginBottom: 15 }}>
        <Input
          allowClear
          size="small"
          placeholder={intl.formatMessage(settingsMessages.batchName)}
          onChange={(e) => handleChangeSearch(e, 'batchName')}
        />
        <Input
          allowClear
          size="small"
          placeholder={intl.formatMessage(settingsMessages.dataType)}
          onChange={(e) => handleChangeSearch(e, 'dataType')}
        />
      </Space>
      <AutoSizer disableWidth>
        {({ height }) => {
          return (
            <>
              <Table size="small" scroll={{ y: height - 200 }} dataSource={tableData} pagination={false}>
                <Column dataIndex="batchName" title={intl.formatMessage(settingsMessages.batchName)} />
                <Column width={90} dataIndex="dataType" title={intl.formatMessage(settingsMessages.dataType)} />
                <Column
                  dataIndex="lastUpdateTimestamp"
                  width={150}
                  title={intl.formatMessage(settingsMessages.lastDateRun)}
                  sorter={(a, b) =>
                    moment.utc(a.lastUpdateTimestamp).valueOf() - moment.utc(b.lastUpdateTimestamp).valueOf()
                  }
                  render={(value) => {
                    return value === 0 || !value ? '' : moment.utc(value).format(Defaults.TimeFormat);
                  }}
                />
                <Column
                  width={150}
                  dataIndex="config"
                  style={{ textAlign: 'right' }}
                  render={(_, record) => {
                    return (
                      <Dropdown
                        trigger="click"
                        overlay={menu(
                          intl,
                          () => handleDelete(record),
                          () => handleClick('edit', record),
                        )}
                        placement="bottom"
                        size="small"
                      >
                        <Button size="small" style={{ width: 85 }}>
                          {intl.formatMessage(eventActionMessages.action)}
                          <DownOutlined style={{ verticalAlign: 'middle', fontSize: 11 }} />
                        </Button>
                      </Dropdown>
                    );
                  }}
                />
              </Table>
              <BuildTableFooter />
            </>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default Step1;
