/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import { isArray, isObject } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseJSON } from '../utils';
import fetchPost from './fetchPost';

function baseLineDataListFormat(results) {
  let mergeData = {};
  R.forEach((data) => {
    R.forEachObjIndexed((value, instance) => {
      if (!R.has(instance, mergeData)) mergeData[instance] = [];
      const baseData = parseJSON(value);
      if (isArray(baseData)) {
        mergeData[instance] = [...mergeData[instance], ...baseData];
      } else if (isObject(baseData)) {
        mergeData[instance] = [...mergeData[instance], baseData];
      }
    }, data || {});
  }, results);

  mergeData = R.mapObjIndexed((val) => JSON.stringify(val), mergeData);

  return mergeData;
}

const getMetricLineChartsBaseLine = (credentials: Credentials, params: Object) => {
  const { projectName, instanceGroup, startTimeObj, endTimeObj, instanceList, metricList, withOnlyPredict } = params;

  const promiseQuery = [];
  if (withOnlyPredict) {
    promiseQuery.push(
      fetchPost(getEndpoint('baseline'), {
        ...credentials,
        projectName,
        instanceGroup,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        instancesStr: JSON.stringify(instanceList || []),
        metricsStr: JSON.stringify(metricList || []),
        prediction: true,
      })
        .then((data) => {
          let { predictionBaseLine } = data;
          predictionBaseLine = parseJSON(predictionBaseLine) || {};

          const predictionData = {};
          R.forEachObjIndexed((vals, timestamp) => {
            R.forEachObjIndexed((baseData, instance) => {
              if (!R.has(instance, predictionData)) {
                predictionData[instance] = [];
              }
              predictionData[instance].push(baseData);
            }, vals);
          }, predictionBaseLine);

          return predictionData;
        })
        .catch((err) => {
          console.error('[IF_API] Failed get linechart baseline', err);
          return {};
        }),
    );
  } else {
    R.forEach((instance) => {
      promiseQuery.push(
        fetchPost(getEndpoint('baseline'), {
          ...credentials,
          projectName,
          instanceGroup,
          startTimestamp: startTimeObj.valueOf(),
          endTimestamp: endTimeObj.valueOf(),
          instancesStr: JSON.stringify([instance]),
          metricsStr: JSON.stringify(metricList || []),
        })
          .then((data) => {
            return data;
          })
          .catch((err) => {
            console.error('[IF_API] Failed get linechart baseline', err);
            return {};
          }),
      );
    }, instanceList);
  }

  return Promise.all(promiseQuery).then((results) => {
    const mergeData = baseLineDataListFormat(results);
    return mergeData;
  });
};

export default getMetricLineChartsBaseLine;
export { baseLineDataListFormat };
