import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Popover, Spin } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Defaults, sleep } from '../../../common/utils';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { ActionIcon } from '../../../lib/fui/icons';

import { eventActionMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

type Props = {
  style: Object,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  zoomStartTime: Number,
  // eslint-disable-next-line
  zoomEndTime: Number,
  // eslint-disable-next-line
  selectAnomalyInstance: String,
  // eslint-disable-next-line
  systemIncidentTimelines: Array<Object>,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoading: Boolean,
  // eslint-disable-next-line
  isAutoReload: Boolean,
  // eslint-disable-next-line
  reloadSystem: Number,
  // eslint-disable-next-line
  onReloadSystemIncidentTimelines: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,

  // eslint-disable-next-line
  incidentTimelines: Array<Object>,
};

class GlobalPanelActionsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {
      isLoading: false,

      eventList: [],
    };
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectAnomalyInstance !== this.props.selectAnomalyInstance ||
      nextProps.systemIncidentTimelines !== this.props.systemIncidentTimelines ||
      nextProps.incidentTimelines !== this.props.incidentTimelines
    ) {
      if (nextProps.systemInfo) {
        this.parseData(nextProps);
      }
    }
  }

  @autobind
  async parseData(props) {
    const {
      selectAnomalyInstance,
      systemIncidentTimelines,
      systemInfo,
      systemHealth,
      isAutoReload,
      incidentTimelines,
    } = props;
    const { sortBy, sortDirection } = this.state;

    this.setState({ isLoading: !isAutoReload });
    await sleep(300);

    let events = [
      ...R.filter((event) => R.toLower(event.type) === 'incident', systemIncidentTimelines || []),
      ...R.filter((item) => !item.isIgnored, incidentTimelines),
    ];

    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];
      let instanceList = [];
      let metricList = [];

      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          metricList.push(pair.rootCauseMetric || pair.metricName);

          const instanceName = pair.instanceId || pair.instanceName;
          instanceList.push(instanceName);
          const componentName = get(instanceComponentMap, instanceName, instanceName);
          componentList.push(componentName);
          const appName =
            componentName && componentName.indexOf(instanceName) === -1
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        instanceList.push(item.instanceName);
        const { realInstanceName } = item;
        const componentName = get(instanceComponentMap, realInstanceName, item.componentName);
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== realInstanceName
            ? `${item.instanceName} (${componentName})`
            : item.instanceName;
        instanceAppNames.push(appName);
      }
      metricList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(metricList));
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      const appName = R.join(', ', instanceAppNames);
      const componentNameString = R.join(', ', componentList);
      const instanceNameString = R.join(', ', instanceList);

      let typeList = [];
      if (item.category === 'metric' && item.type !== 'Incident') {
        typeList = ['metricanomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', item.type).split('&'));
      }

      return {
        ...item,
        rootCauseJson: { rootCauseDetailsArr },
        appName,
        componentNameString,
        instanceNameString,
        instanceAppNames,
        componentList,
        instanceList,
        metricList,
        typeList,
      };
    }, events);

    // filter
    if (selectAnomalyInstance) {
      events = R.filter((anomaly) => {
        return anomaly.instanceList.includes(selectAnomalyInstance);
      }, events);
    }

    // build actions
    let eventList = [];
    const currentTimestamp = get(systemHealth, 'currentTimestamp', 0);
    R.forEach((item) => {
      const { timeLineType, isFixedIncident, triggeredTimeMap, validationTimeList } = item;

      let triggeredActionList = [];
      R.forEachObjIndexed((val, actionName) => {
        R.forEach((timestamp) => {
          triggeredActionList.push({
            actionName,
            timestamp,
          });
        }, val);
      }, triggeredTimeMap);
      triggeredActionList = R.sortWith([R.ascend(R.prop('timestamp'))], triggeredActionList);
      const triggeredActionTime = triggeredActionList.length > 0 ? triggeredActionList[0].timestamp : undefined;

      // display actions if has triggered action, and not out of
      let inValidationTimeWindow = true;
      if (timeLineType === 'future' && !isFixedIncident && validationTimeList && validationTimeList.length > 0) {
        inValidationTimeWindow = validationTimeList[0].endTime >= currentTimestamp;
      }
      if (triggeredActionTime && inValidationTimeWindow) {
        eventList.push({ ...triggeredActionList[0], timelines: [item] });
      }
    }, events || []);

    // sort
    eventList = this.sortData(eventList, sortBy, sortDirection);

    if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
    this.setState({
      isLoading: false,

      eventList,
    });
  }

  @autobind
  filterData(eventList) {
    let filterList = eventList || [];
    filterList = R.filter((event) => !event.isIgnored, filterList);
    return filterList;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.descend(R.prop('timestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }) {
    const { intl } = this.props;
    const { eventList } = this.state;
    const item = eventList[rowIndex];
    if (!item) return null;

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
          <div className="row-column" style={{ width: 120 }}>
            <div style={{ paddingRight: 4 }}>{moment.utc(item.timestamp).format(Defaults.ShortTimeFormat)}</div>
          </div>
          <div className="row-column" style={{ width: 100, flex: 1 }}>
            <Popover
              title={null}
              content={
                <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 180 }}>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventActionMessages.triggeredTime)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {moment.utc(item.timestamp).format(Defaults.ShortTimeFormat)}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventActionMessages.actionName)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {item.actionName}
                    </div>
                  </div>
                </div>
              }
              placement="right"
              mouseEnterDelay={0.3}
            >
              <div className="max-width hidden-line-with-ellipsis">{item.actionName}</div>
            </Popover>
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection, eventList } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const newEventList = this.sortData(eventList, name, sortDir);
          this.setState({ eventList: newEventList });
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const { intl, style, isLoading: isLoadingTimelines } = this.props;
    const { sortBy, sortDirection } = this.state;
    const { isLoading, eventList } = this.state;

    return (
      <div className="flex-grow flex-col" style={{ ...style, minWidth: 150, padding: 8 }}>
        <div className="flex-row flex-center-align font-14" style={{ height: 24, marginBottom: 8 }}>
          <div className="flex-grow">
            <ActionIcon style={{ color: '#037AEF', marginRight: 4 }} />
            {intl.formatMessage(logMessages.triggeredActions)}
          </div>
        </div>
        <div className="flex-grow">
          <Spin wrapperClassName="full-width full-height spin-full-height" spinning={isLoading || isLoadingTimelines}>
            <AutoSizer>
              {({ width, height }) => (
                <div className="event-list">
                  <div
                    className="event-list-header"
                    style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                  >
                    <div className="header-column" style={{ width: 120 }} onClick={this.headerClick('timestamp')}>
                      <span>{intl.formatMessage(eventActionMessages.triggeredTime)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'timestamp')}
                    </div>
                    <div
                      className="header-column"
                      style={{ width: 100, flex: 1 }}
                      onClick={this.headerClick('actionName')}
                    >
                      <span>{intl.formatMessage(eventActionMessages.actionName)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'actionName')}
                    </div>
                  </div>
                  <List
                    className="event-list-grid"
                    ref={(listNode) => {
                      this.listNode = listNode;
                    }}
                    width={width}
                    height={height - 30}
                    rowCount={eventList.length}
                    overscanRowCount={4}
                    deferredMeasurementCache={this.cellMeasureCache}
                    rowHeight={this.cellMeasureCache.rowHeight}
                    rowRenderer={this.renderListItem}
                    onScrollbarPresenceChange={({ horizontal, vertical }) => {
                      if (vertical) {
                        this.listNodeHeaderScrollbar = true;
                      } else {
                        this.listNodeHeaderScrollbar = false;
                      }
                      if (this.listNode) this.listNode.forceUpdateGrid();
                    }}
                  />
                </div>
              )}
            </AutoSizer>
          </Spin>
        </div>
      </div>
    );
  }
}

const GlobalPanelActions = injectIntl(GlobalPanelActionsCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,
    };
  },
  { updateLastActionInfo },
)(GlobalPanelActions);
