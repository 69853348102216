/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { get } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getLogFeatureEntryInfo = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, ...rest } = params;
  const requests = [];
  requests.push(
    fetchGet(getEndpoint('query/logprimaryeventsfeature'), {
      ...credentials,
      ...rest,
      projectName,
      instanceName,
    }),
  );

  return Promise.all(requests).then((results) => {
    const logFeatureMap = get(results[0], ['featureMap'], {});
    const logFeatureEvents = get(results[0], ['events'], []);
    return {
      logFeatureMap,
      logFeatureEvents,
    };
  });
};

export default getLogFeatureEntryInfo;
