import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import {
  CaretDownOutlined,
  ClusterOutlined,
  CodeSandboxOutlined,
  DatabaseOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import { Checkbox, Input, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { AutoSizer, List } from '../../../lib/fui/react';
import { getIncidentCounts } from './ServiceMapCanvas';
import { getCoverageTitle } from './utils';

const ROW_HEIGHT = 28;
const DynamicVM = 1;

export const getEntityTitle = (type, title, instanceDisplayName) => {
  if (!instanceDisplayName) {
    return title;
  }

  const instanceDisplayNameRender = (
    <div className="flex-row">
      <div className="bold" style={{ width: 140, flexShrink: 0 }}>
        Instance display name:
      </div>
      {instanceDisplayName}
    </div>
  );

  switch (type) {
    case 'pod':
      return (
        <div className="flex-col" style={{ maxHeight: 400, overflowY: 'auto' }}>
          <div className="flex-row">
            <div className="bold" style={{ width: 140, flexShrink: 0 }}>
              Pod name:
            </div>
            {title}
          </div>
          {instanceDisplayNameRender}
        </div>
      );
    case 'instance':
      return (
        <div className="flex-col" style={{ maxHeight: 400, overflowY: 'auto' }}>
          <div className="flex-row">
            <div className="bold" style={{ width: 140, flexShrink: 0 }}>
              Instance name:
            </div>
            {title}
          </div>
          {instanceDisplayNameRender}
        </div>
      );
    default:
      return title;
  }
};

export const getEntityIcon = (type, dynamicType) => {
  switch (type) {
    case 'component':
      return (
        <Tooltip title="Component" mouseEnterDelay={0.3} placement="top">
          <ClusterOutlined />
        </Tooltip>
      );
    case 'pod':
      return (
        <Tooltip title="Pod" mouseEnterDelay={0.3} placement="top">
          <CodeSandboxOutlined />
        </Tooltip>
      );
    case 'container':
      return (
        <Tooltip title={dynamicType === DynamicVM ? 'VM' : 'Container'} mouseEnterDelay={0.3} placement="top">
          <InstagramOutlined />
        </Tooltip>
      );
    case 'instance':
      return (
        <Tooltip title="Instance" mouseEnterDelay={0.3} placement="top">
          <DatabaseOutlined />
        </Tooltip>
      );
    default:
      return null;
  }
};
export const BuildEntityList = (allRawEntityTree) => {
  const allEntityList = [];

  const processEntity = (entity) => {
    allEntityList.push(entity);
    R.forEach(
      (x) => processEntity(x),
      R.sortWith(
        [R.ascend(R.prop('inactive')), R.descend(R.prop('totalScoreRaw')), R.ascend(R.prop('name'))],
        entity.children || [],
      ),
    );
  };

  R.forEach(
    (x) => processEntity(x),
    R.sortWith(
      [
        R.descend(R.prop('detectedIncidentCount')),
        R.ascend(R.prop('inactive')),
        R.descend(R.prop('totalScoreRaw')),
        R.ascend(R.prop('name')),
      ],
      allRawEntityTree || [],
    ),
  );

  return { allEntityList };
};

export const ServiceMapEntityFilter = ({
  isK8s,
  entityType,
  allRawEntityTree,
  selectionMap = {},
  entityMap = {},
  onChange,
  showSelectAll = true,
  pageSize,
  autoSelected,
  selectedTime,
  selectedZone,
  pageIndex,
  changeFilterPageIndex,
  collapseMap: localCollapseMap,
  onFilterShowChange,
}) => {
  const listNode = useRef(null);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    allEntityList: [],
    search: null,
    collapseMap: {},
    filterInstanceList: [],
    filterCollapseEntityList: [],
  });
  const { search, collapseMap } = state;
  const { allEntityList, filterCollapseEntityList, filterInstanceList } = state;
  const fouceChangePage = useRef(false);
  const pageNum = useRef(0);

  useEffect(() => {
    setState({ collapseMap: localCollapseMap });
  }, [localCollapseMap]);

  useEffect(() => {
    const { allEntityList } = BuildEntityList(allRawEntityTree, search);
    setState({ allEntityList });
  }, [allRawEntityTree]);

  useEffect(() => {
    setState({ search: null });
  }, [selectedZone, selectedTime]);

  useEffect(() => {
    let filterEntityList = [...allEntityList];
    if (search) {
      filterEntityList = R.filter((e) => {
        const { type } = e;
        if (type === 'component') {
          const children = R.filter(
            (c) => {
              const title = getCoverageTitle(c, isK8s, selectedTime);
              return (
                title.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                (c?.instanceDisplayName || title).toLowerCase().indexOf(search.toLowerCase()) >= 0
              );
            },

            e.children || [],
          );
          return children.length > 0;
        }
        if (type === 'instance' || type === 'pod') {
          const title = getCoverageTitle(e, isK8s, selectedTime);
          return (
            title.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            (e?.instanceDisplayName || title).toLowerCase().indexOf(search.toLowerCase()) >= 0
          );
        }

        if (type === 'container') {
          const parents = e.parents || [];
          for (let i = 0; i < parents.length; i += 1) {
            const title = getCoverageTitle(parents[i], isK8s, selectedTime);
            if (
              title.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
              (parents[i]?.instanceDisplayName || title).toLowerCase().indexOf(search.toLowerCase()) >= 0
            ) {
              return true;
            }
          }
          return false;
        }
        return false;
      }, allEntityList);
    }

    const filterInstanceList = R.filter((e) => {
      return e.type === 'instance' || e.type === 'pod';
    }, filterEntityList);

    const filterCollapseEntityList = R.filter((e) => {
      const parents = e.parents || [];
      for (let i = 0; i < parents.length; i += 1) {
        if (collapseMap[parents[i].id]) {
          return false;
        }
      }
      return true;
    }, filterEntityList);

    if (fouceChangePage.current) {
      if (listNode.current) {
        const nidx = R.findIndex(
          (e) => e?.id === filterInstanceList[pageNum.current * pageSize]?.id,
          filterCollapseEntityList,
        );
        listNode.current.scrollToRow(nidx);
      }
      fouceChangePage.current = false;
    }

    setState({ filterInstanceList, filterCollapseEntityList });
  }, [allEntityList, search, collapseMap]);

  useEffect(() => {
    if (!autoSelected && isEmpty(selectionMap) && filterInstanceList.length > 0) {
      setTimeout(() => {
        const incidentList = R.filter((f) => {
          const { detectedIncidentCount, predictedIncidentCount } = getIncidentCounts(f, selectedTime);
          return detectedIncidentCount > 0 || predictedIncidentCount > 0;
        }, filterInstanceList);

        const smap = {};
        let count = 0;
        R.forEach((c) => {
          R.forEach((e) => {
            smap[e.id] = true;
          }, c.children);
          smap[c.id] = true;
          count += 1;
        }, R.take(pageSize, incidentList));

        if (count < pageSize) {
          const anomalyList = R.filter((f) => {
            return f.totalScoreRaw > 0;
          }, filterInstanceList);

          R.forEach((c) => {
            R.forEach((e) => {
              smap[e.id] = true;
            }, c.children);
            smap[c.id] = true;
            count += 1;
          }, R.take(pageSize - count, anomalyList));
        }

        if (count < pageSize) {
          for (let i = 0; i < filterInstanceList.length; i += 1) {
            const c = filterInstanceList[i];
            if (!smap[c.id]) {
              R.forEach((e) => {
                smap[e.id] = true;
              }, c.children);
              smap[c.id] = true;
              count += 1;
            }
            if (count >= pageSize) {
              break;
            }
          }
        }
        onChange(smap);

        if (listNode.current) {
          listNode.current.scrollToRow(0);
        }
      }, 10);
    }
  }, [filterInstanceList, autoSelected]);

  const totalCount = filterInstanceList.length || 0;
  const lastPageIndex = Math.ceil(totalCount / pageSize) - 1;
  if (onFilterShowChange) {
    onFilterShowChange(filterCollapseEntityList);
  }
  return (
    <div className="flex-col flex-grow">
      <div className="flex-row flex-center-alignt" style={{ marginBottom: 12, alignItems: 'center' }}>
        <span className="label" style={{ fontSize: 18 }}>
          {entityType}
        </span>
        <div className="flex-grow" />
      </div>
      <div className="flex-row flex-center-align" style={{ height: 24 }}>
        <Input.Search
          allowClear
          style={{ flex: 1 }}
          size="small"
          placeholder={`${entityType} search`}
          value={search}
          onSearch={(search) => setState({ search })}
          onChange={(e) => {
            setState({ search: e.target.value });
          }}
        />
      </div>
      {showSelectAll && (
        <div className="flex-row" style={{ height: 24 }}>
          <div className="flex-row flex-center-align" style={{ paddingRight: 8 }}>
            <div
              className="clickable"
              style={{ color: 'var(--link-color)' }}
              onClick={() => {
                const selectionMap = R.reduce((acc, x) => ({ ...acc, [x]: true }), {}, R.keys(entityMap));
                onChange(selectionMap);
              }}
            >
              Select all
            </div>
          </div>
          <div className="flex-row flex-center-align">
            <div
              className="clickable"
              style={{ color: 'var(--link-color)' }}
              onClick={() => {
                onChange({});
              }}
            >
              Unselect all
            </div>
          </div>
          <div className="flex-grow" />
          {totalCount > pageSize && (
            <>
              {pageIndex !== 0 && (
                <div className="flex-row flex-center-align" style={{ paddingRight: 4 }}>
                  <div
                    className="clickable"
                    style={{ color: 'var(--link-color)', userSelect: 'none' }}
                    onClick={() => {
                      const selectionMap = {};
                      let collapse = [];
                      const idx = pageIndex === 0 ? 0 : pageIndex - 1;

                      R.forEach((c) => {
                        R.forEach((e) => {
                          selectionMap[e.id] = true;
                        }, c.children);
                        selectionMap[c.id] = true;
                        if (c.parents[0]?.id) collapse.push(c.parents[0]?.id);
                      }, R.take(pageSize, R.drop(idx * pageSize, filterInstanceList)));

                      collapse = R.uniq(collapse);
                      R.forEach((id) => {
                        if (collapseMap[id]) {
                          delete collapseMap[id];
                        }
                      }, collapse || []);

                      pageNum.current = idx;
                      fouceChangePage.current = true;
                      setState({ collapseMap: { ...collapseMap } });
                      changeFilterPageIndex(idx);
                      onChange(selectionMap);
                    }}
                  >
                    {`<Prev ${pageSize}`}
                  </div>
                </div>
              )}
              <div className="flex-row flex-center-align" style={{ paddingRight: 4 }}>
                <div style={{ color: 'var(--text-color)' }}>{`${pageIndex * pageSize + 1}~${
                  pageIndex === lastPageIndex ? totalCount : (pageIndex + 1) * pageSize
                }`}</div>
              </div>
              {pageIndex < lastPageIndex && (
                <div className="flex-row flex-center-align" style={{}}>
                  <div
                    className="clickable"
                    style={{ color: 'var(--link-color)' }}
                    onClick={() => {
                      const selectionMap = {};
                      let collapse = [];
                      const idx =
                        pageIndex === Math.ceil(filterInstanceList.length / pageSize) - 1
                          ? Math.ceil(filterInstanceList.length / pageSize) - 1
                          : pageIndex + 1;

                      R.forEach((c) => {
                        R.forEach((e) => {
                          selectionMap[e.id] = true;
                        }, c.children);
                        selectionMap[c.id] = true;
                        if (c.parents[0]?.id) collapse.push(c.parents[0]?.id);
                      }, R.take(pageSize, R.drop(idx * pageSize, filterInstanceList)));

                      collapse = R.uniq(collapse);
                      R.forEach((id) => {
                        if (collapseMap[id]) {
                          delete collapseMap[id];
                        }
                      }, collapse || []);

                      pageNum.current = idx;
                      fouceChangePage.current = true;
                      setState({ collapseMap: { ...collapseMap } });
                      changeFilterPageIndex(idx);
                      onChange(selectionMap);
                    }}
                  >
                    {`Next ${pageIndex < lastPageIndex - 1 ? pageSize : totalCount - (pageIndex + 1) * pageSize}>`}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      <div className="flex-grow">
        <AutoSizer>
          {({ width, height }) => (
            <List
              ref={listNode}
              width={width}
              height={height}
              rowCount={filterCollapseEntityList.length}
              rowHeight={ROW_HEIGHT}
              rowRenderer={({ index: idx, style }) => {
                const entity = filterCollapseEntityList[idx];
                const { id, children = [], allChildren = [], level = 0, type, dynamicType, inactive = false } = entity;
                const { instanceDisplayName } = entity;
                const title = getCoverageTitle(entity, isK8s, selectedTime);
                const hasAnomaly = entity.totalScoreRaw > 0;
                const color = hasAnomaly ? 'var(--primary-color)' : inactive ? 'gray' : 'var(--black)';
                const checkedChildren = R.filter((c) => selectionMap[c.id], allChildren);
                const hasChildren = children.length > 0;

                const checked =
                  (!hasChildren && selectionMap[id]) || (hasChildren && allChildren.length === checkedChildren.length);
                const indeterminate =
                  hasChildren && checkedChildren.length > 0 && checkedChildren.length < allChildren.length;
                const icon = getEntityIcon(type, dynamicType);
                const realTitle = type !== 'container' ? instanceDisplayName || title : title.split('_')[0] || title;

                return (
                  <div key={id} className="flex-row clickable" style={{ ...style, alignItems: 'center' }}>
                    {level > 0 && <div style={{ width: 16 * level }} />}
                    {children.length === 0 && <div style={{ width: 16 }} />}
                    {children.length > 0 && (
                      <div
                        style={{ width: 16 }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (collapseMap[id]) {
                            delete collapseMap[id];
                          } else {
                            collapseMap[id] = true;
                          }
                          setState({ collapseMap: { ...collapseMap } });
                        }}
                      >
                        <CaretDownOutlined rotate={collapseMap[id] ? -90 : 0} />
                      </div>
                    )}
                    <div
                      onClick={() => {
                        if (inactive) return;
                        if (checked) {
                          const removeChild = (e) => {
                            delete selectionMap[e.id];
                            R.forEach(removeChild, e.children || []);
                          };
                          removeChild(entity);
                          R.forEach(removeChild, children);
                        } else {
                          const selectChild = (e) => {
                            selectionMap[e.id] = true;
                            R.forEach(selectChild, e.children || []);
                          };
                          selectChild(entity);
                          R.forEach(selectChild, children);
                        }
                        onChange(selectionMap);
                      }}
                    >
                      <Checkbox
                        checked={checked}
                        disabled={inactive}
                        indeterminate={indeterminate}
                        style={{ paddingRight: 8 }}
                      />
                    </div>
                    {!!icon && <div style={{ width: 20, fontSize: 14, color }}>{icon}</div>}
                    <Tooltip
                      title={getEntityTitle(type, title, instanceDisplayName)}
                      mouseEnterDelay={0.3}
                      placement="right"
                      overlayStyle={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}
                    >
                      <div className="flex-grow hidden-line-with-ellipsis" style={{ color }}>
                        {realTitle}
                      </div>
                    </Tooltip>
                  </div>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
