/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Button, Input, Popconfirm } from 'antd';
import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import fetchDelete from '../../../../common/apis/fetchDelete';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { Container, Modal } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import PreviewInstanceModal from './PreviewInstanceModal';

type Props = {
  projectName: String,
  // eslint-disable-next-line
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  localInstanceGroupList: Array<Object>,
};

class GroupEditComponentMapModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmitting: false,
      activePreview: null,
      showPreviewModal: false,
    };
    this.relationList = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projectName !== this.props.projectName) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName } = props;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('componentmapping'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        let relationList = data || [];
        relationList = R.map((item) => {
          const { instanceRegex } = item;
          return { ...item, oldInstanceRegex: instanceRegex };
        }, relationList);

        this.relationList = relationList;
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.relationList = [];
        this.setState({ isLoading: false });
      });
  }

  @autobind
  handleSaveClick() {
    const { credentials, projectName, onClose } = this.props;

    this.setState({ isSubmitting: true });

    let relationList = R.clone(this.relationList);
    relationList = R.map((item) => {
      const { oldInstanceRegex, instanceRegex, componentName } = item;
      if (oldInstanceRegex === instanceRegex) {
        return { instanceRegex, componentName };
      }
      return item;
    }, relationList);

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('componentmapping'), {
      ...credentials,
      projectName,
      mapping: JSON.stringify(relationList),
    })
      .then((data) => {
        this.setState({ isSubmitting: false });
        onClose(true);
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  handleAddRelationClick() {
    this.relationList = [
      ...this.relationList,
      {
        instanceRegex: null,
        componentName: null,
      },
    ];
    this.forceUpdate();
  }

  @autobind
  renderListView(rowData, index) {
    const { intl } = this.props;
    const { instanceRegex, componentName } = rowData;

    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          <Input
            size="small"
            value={instanceRegex}
            onChange={(e) => {
              const val = e.target.value;
              rowData.instanceRegex = val;
              this.forceUpdate();
            }}
          />
          <Button
            size="small"
            disabled={!rowData.instanceRegex}
            style={{ marginLeft: 8 }}
            onClick={() => this.setState({ activePreview: rowData, showPreviewModal: true })}
          >
            {intl.formatMessage(appButtonsMessages.preview)}
          </Button>
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          <Input
            size="small"
            value={componentName}
            onChange={(e) => {
              const val = e.target.value;
              rowData.componentName = val;
              this.forceUpdate();
            }}
          />
        </div>
        <div className="row-column" style={{ width: 120 }}>
          <Popconfirm
            placement="topRight"
            title={intl.formatMessage(appMessages.continueConfirm)}
            onConfirm={() => this.handleRemoveClick(rowData, index)}
            onCancel={(event) => event.stopPropagation()}
          >
            <Button size="small" className="button-color-grey" onClick={(event) => event.stopPropagation()}>
              <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  }

  @autobind
  handleRemoveClick(rowData, index) {
    const { oldInstanceRegex } = rowData;

    if (oldInstanceRegex) {
      const { credentials, projectName } = this.props;

      this.setState({ isLoading: true });
      this.props.updateLastActionInfo();
      fetchDelete(getEndpoint('componentmapping'), {
        ...credentials,
        projectName,
        mapping: JSON.stringify([rowData]),
      })
        .then((data) => {
          this.reloadData(this.props);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    } else {
      this.relationList = R.remove(index, 1, this.relationList);
      this.forceUpdate();
    }
  }

  render() {
    const { intl, onClose, localInstanceGroupList } = this.props;
    const { isLoading, isSubmitting, showPreviewModal, activePreview } = this.state;

    const hasError =
      this.relationList.length === 0 ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => item.instanceRegex && item.componentName, this.relationList),
      );
    return (
      <>
        <Modal
          title={intl.formatMessage(settingsMessages.editComponentMap)}
          width={850}
          visible
          maskClosable={false}
          onCancel={() => onClose()}
          onOk={() => this.handleSaveClick()}
          okButtonProps={{ loading: isSubmitting, disabled: isLoading || hasError }}
        >
          <Container className={`${isLoading ? 'loading ' : ''} flex-col flex-min-height`} style={{ height: 400 }}>
            <div className="flex-row" style={{ marginBottom: 8 }}>
              <Button type="primary" onClick={this.handleAddRelationClick}>
                <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
              </Button>
              <div className="flex-grow" />
            </div>

            <div className="flex-grow flex-col flex-min-height">
              <div className="event-list flex-grow flex-col flex-min-height">
                <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(appFieldsMessages.instanceRegex)}
                  </div>
                  <div className="header-column  flex-center-justify" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(appFieldsMessages.component)}
                  </div>
                  <div className="header-column" style={{ width: 120 }} />
                </div>
                <div className="event-list-grid flex-grow overflow-y-auto">
                  {R.addIndex(R.map)((rowData, index) => this.renderListView(rowData, index), this.relationList)}
                </div>
              </div>
            </div>
          </Container>
        </Modal>
        {showPreviewModal && (
          <PreviewInstanceModal
            intl={intl}
            event={activePreview}
            localInstanceGroupList={localInstanceGroupList}
            onClose={() => this.setState({ activePreview: null, showPreviewModal: false })}
          />
        )}
      </>
    );
  }
}

const GroupEditComponentMapModal = injectIntl(GroupEditComponentMapModalCore);
export default connect(
  (state) => {
    const { loadStatus, projects } = state.app;
    const { credentials } = state.auth;
    return {
      loadStatus,
      projects,
      credentials,
    };
  },
  { updateLastActionInfo },
)(GroupEditComponentMapModal);
