import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, message } from 'antd';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../Constants';
import { ActionTypes as AppActionTypes, createSetAction } from '../../../common/app/actions';
import { buildUrl, Defaults, parseUrl, sleep } from '../../../common/utils';
import { appMessages } from '../../../common/app/messages';

import { MediaPlayer } from '../../share';

import SreImg from '../../../../images/tour/sre.png';
import ReleaseImg from '../../../../images/tour/release.png';
import DevImg from '../../../../images/tour/dev.png';

type Props = {
  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  userInfo: Object,
  credentials: Object,
  createSetAction: Function,

  runAwesomeTours: Boolean,
  toursState: Object,
  isDark: Boolean,
};

class AwesomeToursCore extends React.PureComponent {
  props: Props;

  @autobind
  handleRoleSelect(index, role) {
    return () => {
      this.handleStepChange({ action: ACTIONS.NEXT, index });
    };
  }

  constructor(props) {
    super(props);

    const { userInfo } = props;

    const steps = [];

    steps.push({
      target: 'body',
      placement: 'center',
      title: `Welcome to InsightFinder! We’re glad you’re here.`,
      content: (
        <div style={{ textAlign: 'start', display: 'flex' }}>
          <Card
            hoverable
            style={{ width: 240, margin: 8 }}
            bodyStyle={{ padding: 12 }}
            cover={
              <div style={{ textAlign: 'center', paddingTop: 8 }}>
                <img alt="IT operators, Devops, SREs" src={SreImg} style={{ height: 80 }} />
              </div>
            }
            onClick={this.handleRoleSelect(0, 'sre')}
          >
            <Card.Meta
              title={<div style={{ textAlign: 'center' }}>IT operators, DevOps, SREs</div>}
              description={
                <div>
                  <span>
                    You can ingest infrastructure metric data, network data, system log data, change events, and
                    incident ticket data via our{' '}
                  </span>
                  <Button type="link" style={{ padding: 0, fontSize: 14 }} onClick={this.handleClickIntegrations}>
                    integrations
                  </Button>
                  <span>
                    . You will receive real time alerts and predictions via our notification service (e.g., email,
                    slack, or Service Now auto-ticketing).
                  </span>
                  <div>
                    Incident data is required if you would like to use incident prediction feature from InsightFinder.
                  </div>
                </div>
              }
            />
          </Card>
          <Card
            hoverable
            style={{ width: 240, margin: 8 }}
            bodyStyle={{ padding: 12 }}
            cover={
              <div style={{ textAlign: 'center', paddingTop: 8 }}>
                <img alt="Release Engineers" src={ReleaseImg} style={{ height: 80 }} />
              </div>
            }
            onClick={this.handleRoleSelect(0, 'release')}
          >
            <Card.Meta
              title={<div style={{ textAlign: 'center' }}>Release Engineers</div>}
              description={
                <div>
                  <span>You can ingest your release build events, your application error logs</span>
                  <span>, your infrastructure data via our </span>
                  <Button type="link" style={{ padding: 0, fontSize: 14 }} onClick={this.handleClickIntegrations}>
                    integrations
                  </Button>
                  <span>
                    . If a new release causes any problem, InsightFinder will notify you before your customers are
                    affected via our notification service (e.g., email or slack).
                  </span>
                </div>
              }
            />
          </Card>
          <Card
            hoverable
            style={{ width: 240, margin: 8 }}
            bodyStyle={{ padding: 12 }}
            cover={
              <div style={{ textAlign: 'center', paddingTop: 8 }}>
                <img alt="developers" src={DevImg} style={{ height: 80 }} />
              </div>
            }
            onClick={this.handleRoleSelect(0, 'dev')}
          >
            <Card.Meta
              title={<div style={{ textAlign: 'center' }}>Application developers</div>}
              description={
                <div>
                  <span>You can ingest your application logs and your application metrics via our </span>
                  <Button type="link" style={{ padding: 0, fontSize: 14 }} onClick={this.handleClickIntegrations}>
                    integrations
                  </Button>
                  <span>
                    . If your application experiences any problem, InsightFinder will notify you of the most important
                    log entries in real time via our notification service (e.g., email or slack).
                  </span>
                </div>
              }
            />
          </Card>
        </div>
      ),
      url: buildUrl(
        BaseUrls.GlobalHealth,
        {},
        { startTime: Defaults.demoDay(), endTime: Defaults.demoDay(), customerName: 'demoUser' },
      ),
    });
    if (!userInfo.isAdmin) {
      steps.push({
        target: '.tour-timezone',
        placement: 'right',
        title: 'Configuration: Timezone',
        content: (
          <div style={{ width: 380, textAlign: 'start' }}>Please change the timezone based on your local time.</div>
        ),
        url: buildUrl(BaseUrls.AccountInfo, {}, {}),
      });
    }
    if (!userInfo.isReadUser) {
      steps.push({
        target: '.tour-insight-discovery',
        placement: 'right',
        title: 'Data ingestion: create project',
        content: (
          <div style={{ width: 380, textAlign: 'start' }}>
            Add a new project in InsightFinder to monitor your applications or infrastructures.
          </div>
        ),
        url: buildUrl(BaseUrls.GlobalHealth, {}, {}),
      });
    }
    // if (!userInfo.isReadUser) {
    //   steps.push({
    //     target: '.tour-system-add',
    //     placement: 'right',
    //     title: 'Data ingestion: create system',
    //     content: (
    //       <div style={{ width: 380, textAlign: 'start' }}>
    //         Add a new system in InsightFinder to monitor your applications or infrastructures.
    //       </div>
    //     ),
    //     url: buildUrl(BaseUrls.SystemSetting, {}, {}),
    //   });
    // }
    if (!userInfo.isAdmin && !userInfo.isLocalAdmin && !userInfo.isReadUser) {
      // steps.push({
      //   target: '.tour-project-add',
      //   placement: 'right',
      //   title: 'Data ingestion: create project',
      //   content: (
      //     <div style={{ width: 380, textAlign: 'start' }}>
      //       Add a new project in InsightFinder to integrate with different data sources.
      //     </div>
      //   ),
      //   url: buildUrl(BaseUrls.SystemSetting, {}, {}),
      // });
      steps.push({
        target: '.tour-integrations-amazon',
        placement: 'right',
        title: 'Data ingestion: select integration',
        content: <div style={{ width: 380, textAlign: 'start' }}>Select integration which you want to used.</div>,
        url: buildUrl(BaseUrls.SettingsIntegrations, {}, {}),
      });
    }
    if (userInfo.isAdmin) {
      steps.push({
        target: '.tour-setting-metric-kpi',
        placement: 'left',
        title: 'Configuration: incident definitions',
        content: (
          <div style={{ width: 380, textAlign: 'start' }}>
            Your investigation process is unique. Your key performance indicators (KPIs) and critical incidents are
            unique. Only get alerted when something you care about happens by defining KPIs and associated thresholds or
            critical events from incident ticket database or error logs.
          </div>
        ),
        url: buildUrl(
          BaseUrls.SettingsProject,
          { projectName: 'TD_metric@demoUser' },
          { setting: 'threshold', instanceId: 'core server' },
        ),
      });
    }
    if (userInfo.isAdmin) {
      steps.push({
        target: '.tour-setting-keywords-incident',
        placement: 'right',
        title: 'Configuration: incident definitions',
        content: (
          <div style={{ width: 380, textAlign: 'start' }}>
            Your investigation process is unique. Your key performance indicators (KPIs) and critical incidents are
            unique. Only get alerted when something you care about happens by defining KPIs and associated thresholds or
            critical events from incident ticket database or error logs.
          </div>
        ),
        url: buildUrl(BaseUrls.SettingsProject, { projectName: 'TD_web@demoUser' }, { setting: 'keywords' }),
      });
    }
    steps.push({
      target: '.menu-GHV',
      placement: 'right',
      title: 'Unified Health View',
      content: (
        <div style={{ width: 480, textAlign: 'start' }}>
          Once you set up your project, here is where you can see the unified health views of different systems. The
          InsightFinder Unified Health View visualizes how your services are performing by aggregating all of your data
          - logs, metrics, traces, changes, incidents - into a single, simple timeline that instantly answers your most
          important questions: Is everything working as expected? If not, what action can I take to ensure no users are
          impacted? With a single click, see what anomalies are impacting health scores, what incidents are likely to
          occur in the future, and, most important, what you should do now.
        </div>
      ),
      url: buildUrl(
        BaseUrls.GlobalHealth,
        {},
        {
          startTime: Defaults.demoDay(),
          endTime: Defaults.demoDay(),
          customerName: 'demoUser',
          environmentId: 'All',
        },
      ),
    });
    steps.push({
      target: '.menu-IP',
      placement: 'right',
      title: 'Incident Prediction',
      content: (
        <div style={{ width: 480, textAlign: 'start' }}>
          Anomalies associated with specific patterns from the InsightFinder pattern library indicate what’s likely to
          happen in the future. That’s the power of unsupervised machine learning. View a summary of all patterns,
          detected, and predicted incidents to understand where to begin the investigation process.
        </div>
      ),
      url: buildUrl(
        BaseUrls.GlobalSystemPrediction,
        {},
        {
          startTime: Defaults.demoDay(),
          customerName: 'demoUser',
          environmentId: 'All',
          systemId: 'Trial Demo',
        },
      ),
    });
    steps.push({
      target: '.menu-RCA',
      placement: 'right',
      title: 'Incident Investigation',
      content: (
        <div style={{ width: 480, textAlign: 'start' }}>
          Go from insight to action with the most accurate list of probable root causes for every incident. For most
          organizations, identifying and learning from root causes is time-consuming. Often, post-mortems aren’t
          conducted and when they are they focus on who caused the problem rather than what learning can be used to
          prevent the same issue from happening again. With InsightFinder, use the power of machine learning to see what
          caused the issue from the first anomalous event. You’ll be amazed at how easy it is to conduct post-mortems
          when AI tells you what caused the problem.
        </div>
      ),
      url: buildUrl(
        BaseUrls.GlobalSystemRootCause,
        {},
        {
          startTime: Defaults.demoDay(),
          customerName: 'demoUser',
          environmentId: 'All',
          systemId: 'Trial Demo',
        },
      ),
    });
    steps.push({
      target: '.menu-analysis-metrics',
      placement: 'right',
      title: 'Investigating metrics',
      content: (
        <div style={{ width: 380, textAlign: 'start' }}>
          See intuitive insights extracted from thousands of rows of machine data that indicate what went wrong and
          when, who is required to fix it, and how much lead time you have before users are impacted. Analyze logs using
          simple keywords or complex regular expressions and see line trends with clear explanations of what events
          caused each detect and predicted incident.
        </div>
      ),
      url: buildUrl(
        BaseUrls.MetricEvents,
        {},
        {
          startTime: Defaults.demoDay(),
          customerName: 'demoUser',
          environmentId: 'All',
          systemId: 'Trial Demo',

          projectName: 'TD_metric@demoUser',
          instanceGroup: 'All',
          modelType: 'Holistic',
          startTimestamp: moment.utc(Defaults.demoDay(), Defaults.DateFormat).valueOf(),
          endTimestamp: moment.utc(Defaults.demoDay(), Defaults.DateFormat).endOf('day').valueOf(),
        },
      ),
    });
    steps.push({
      target: '.menu-analysis',
      placement: 'right',
      title: 'Investigating logs',
      content: (
        <div style={{ width: 380, textAlign: 'start' }}>
          See intuitive insights extracted from thousands of rows of machine data that indicate what went wrong and
          when, who is required to fix it, and how much lead time you have before users are impacted. Analyze logs using
          simple keywords or complex regular expressions and see line trends with clear explanations of what events
          caused each detect and predicted incident.
        </div>
      ),
      url: buildUrl(
        BaseUrls.LogCalendar,
        {},
        {
          category: 'monthly',
          month: moment.utc(Defaults.demoDay(), Defaults.DateFormat).format(Defaults.MonthFormat),
          hasLog: true,
          projectName: 'TD_web@demoUser',
        },
      ),
    });
    steps.push({
      target: 'body',
      placement: 'center',
      title: 'InsightFinder vs. DBSCAN',
      content: <MediaPlayer url="https://www.youtube.com/watch?v=KcT-oLYcZYc&t=30s" />,
      url: buildUrl(BaseUrls.SettingsIntegrations, {}, {}),
    });
    steps.push({
      target: 'body',
      placement: 'center',
      title: 'Incident prediction',
      content: <MediaPlayer url="https://www.youtube.com/watch?v=cnXaAAlZNEE&t=16s" />,
      url: buildUrl(BaseUrls.SettingsIntegrations, {}, {}),
    });
    steps.push({
      target: 'body',
      placement: 'center',
      title: `Congrats InsightFinder user!`,
      content: (
        <div style={{ width: 480, textAlign: 'start' }}>
          {`You’ve now experienced the most advanced AI-first system of intelligence. ` +
            `Hard to ever go back to legacy monitoring tools, right? ` +
            `To continue your journey, click “I’m ready!” and we’ll follow up with next steps. ` +
            `Contact us via support at `}
          <Button
            type="link"
            style={{
              margin: 0,
              padding: 0,
              height: 'auto',
              fontSize: 13,
            }}
            onClick={() => window.open('https://insightfinder.com', '_blank')}
          >
            InsightFinder.com
          </Button>
          . Welcome to InsightFinder!
          <div>If you need to see this again, simply click under your User Name.</div>
        </div>
      ),
      url: buildUrl(BaseUrls.SettingsIntegrations, {}, {}),
    });

    this.steps = steps;
    this.stepsCount = this.steps.length;

    this.state = {
      joyrideFinished: window.localStorage.getItem('joyrideFinished') || false,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  getHelpers(helpers) {
    this.helpers = helpers;
  }

  @autobind
  handleJoyrideCallback(data) {
    const { createSetAction } = this.props;
    const { action, index, status, type } = data;

    if (action === ACTIONS.CLOSE) {
      // close the tour
      createSetAction(AppActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { runAwesomeTours: false });
    } else if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      if (status === STATUS.FINISHED) {
        window.localStorage.setItem('joyrideFinished', true);
      }
      // finished
      createSetAction(
        AppActionTypes.SET_INFO_SYSTEM_CONFIGS,
        {},
        {
          runAwesomeTours: false,
          toursState: {
            stepIndex: 0,
            url: this.steps[0].url,
          },
        },
      );
      // send the email
      this.handleSendEmail();
    } else if (type === EVENTS.STEP_AFTER) {
      // jump to next tour
      this.handleStepChange({ action, index });
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      createSetAction(AppActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { runAwesomeTours: false });

      // find the target element
      const findTarget = async () => {
        let hasTarget = false;
        for (let i = 0; i < 10; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await sleep(600);

          const { target } = this.steps[index];
          let targetDoms = [];
          if (target.startsWith('.')) {
            targetDoms = document.getElementsByClassName(R.slice(1, Infinity, target));
          } else {
            targetDoms = document.getElementsByTagName(target);
          }
          hasTarget = targetDoms.length > 0;
          if (hasTarget) {
            break;
          }
        }
        return hasTarget;
      };
      findTarget().then((hasTarget) => {
        if (hasTarget) {
          // rerun the tour
          createSetAction(AppActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { runAwesomeTours: true });
        } else {
          // jump to next step
          this.handleStepChange({ action, index });
        }
      });
    }
  }

  @autobind
  handleStepChange({ action, index }) {
    const { push, createSetAction, location } = this.props;

    // Update state to advance the tour
    const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    const step = this.steps[stepIndex];
    const { url } = step || {};
    let needAwait = false;

    if (url) {
      const { pathname } = parseUrl(url);
      if (pathname !== location.pathname) {
        needAwait = true;
        push(url);
      }
    }

    setTimeout(
      () =>
        createSetAction(
          AppActionTypes.SET_INFO_SYSTEM_CONFIGS,
          {},
          { runAwesomeTours: true, toursState: { stepIndex, url } },
        ),
      needAwait ? 600 : 0,
    );
  }

  @autobind
  handleSendEmail() {
    const { intl, credentials } = this.props;
    fetchPost(getEndpoint('get-tour-finish'), {
      ...credentials,
    })
      .then((data) => {
        if (data && data.SUCCESS) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  }

  @autobind
  handleClickIntegrations(e) {
    e.preventDefault();
    e.stopPropagation();
    window.open(buildUrl(BaseUrls.SettingsIntegrations, {}, {}), '_blank');
  }

  @autobind
  renerTooltip({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps, skipProps }) {
    const { userInfo, toursState } = this.props;
    const { steps } = this;
    const { showProgress } = step || {};
    const { joyrideFinished } = this.state;
    const { stepIndex } = toursState;
    return (
      <div className="tour-tooltip" {...tooltipProps}>
        {step.title && (
          <div className="tooltip-header">
            {step.title}

            {(joyrideFinished || stepIndex >= 2) && (
              <Button type="link" icon={<CloseOutlined />} className="tooltip-close" {...closeProps} />
            )}
          </div>
        )}
        <div className="tooltip-content">{step.content}</div>
        <div className="tooltip-footer flex-row flex-space-between">
          {index > 0 && (
            <div className="flex-row flex-space-between flex-grow">
              <Button style={{ minWidth: 80 }} shape="round" {...backProps}>
                Previous
              </Button>
              {index !== steps.length - 1 &&
                (!userInfo.isAdmin && !userInfo.isLocalAdmin && !userInfo.isReadUser ? index > 3 : index > 1) &&
                joyrideFinished && (
                  <Button style={{ minWidth: 80, marginRight: 10 }} shape="round" {...skipProps}>
                    Skip tour
                  </Button>
                )}
            </div>
          )}
          {index <= 0 && <div />}

          {continuous && (
            <Button type="primary" style={{ minWidth: 80 }} shape="round" {...primaryProps}>
              {`${index + 1 < this.stepsCount ? 'Next' : 'I’m ready!'}${
                showProgress ? ` ${index + 1} / ${this.stepsCount}` : ''
              }`}
            </Button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { runAwesomeTours, toursState, isDark } = this.props;
    const { stepIndex } = toursState;

    return (
      <Joyride
        run={runAwesomeTours}
        stepIndex={stepIndex}
        steps={this.steps}
        tooltipComponent={this.renerTooltip}
        callback={this.handleJoyrideCallback}
        getHelpers={this.getHelpers}
        continuous
        showProgress
        disableCloseOnEsc
        disableOverlayClose
        // disableScrolling
        // disableScrollParentFix
        styles={{
          options: {
            zIndex: 10000,
            overlayColor: isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.7)',
            arrowColor: '#ff5142',
          },
        }}
      />
    );
  }
}

const AwesomeTours = injectIntl(AwesomeToursCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { runAwesomeTours, toursState, currentTheme } = state.app;
    const isDark = currentTheme === 'dark';

    return {
      location,
      userInfo,
      credentials,
      runAwesomeTours,
      isDark,
      toursState,
    };
  },
  { push, replace, createSetAction },
)(AwesomeTours);
