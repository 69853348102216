import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { message, Descriptions } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';

import LikelyRootCauses from './LikelyRootCauses';
import { GlobalParse } from '../../../common/utils';

type Props = {
  // eslint-disable-next-line
  incident: Object,
  environmentId: String,
  systemId: String,
  needRC: String,
  needPT: String,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
};

class LikelyRootCausesModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData() {
    const { intl, incident, needRC, needPT, credentials } = this.props;
    const {
      category,
      projectOwner,
      anomalyLogInstance,
      instanceList,
      startTimestamp,
      endTimestamp,
      patternId,
      type,
      rootCauseTableKey,
    } = incident;

    this.setState({ isLoading: true });

    // parse params to get root cause
    let { projectName } = incident;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
    const event = {
      nid: patternId,
      eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
    };

    const request = [];
    if (needRC) {
      request.push(
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceList[0],
          startTime,
          endTime,
          operation: 'rootCauseEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
      );
    }
    if (needPT) {
      request.push(
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceList[0],
          startTime,
          endTime,
          operation: 'predictedEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
      );
    }
    this.props.updateLastActionInfo();
    Promise.all(request)
      .then((results) => {
        const rootCausesData = needRC ? results[0] : undefined;
        const predictedData = needPT ? results[needRC ? 1 : 0] : undefined;

        this.setState({
          isLoading: false,

          rootCausesData,
          predictedData,
        });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({
          isLoading: false,

          rootCausesData: undefined,
          predictedData: undefined,
        });
      });
  }

  @autobind
  renderDescription(rootCausesData) {
    const { credentials, projectDisplayMap } = this.props;
    // RC
    const logRootCauseEvents = get(rootCausesData, ['logRootCauseEvents'], []);
    const metricRootCauseEvents = get(rootCausesData, ['metricRootCauseEvents'], []);
    const { relatedEventList: allRootCauseListLog } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: logRootCauseEvents,
      credentials,
      projectDisplayMap,
    });
    const { relatedEventList: allRootCauseListMetric } = GlobalParse.parseRootCauseInfo({
      operation: 'rootCauseEvents',
      chains: metricRootCauseEvents,
      credentials,
      projectDisplayMap,
    });

    const sourceInfoListLog = R.map((n) => n.sourceInfoList, allRootCauseListLog);
    const sourceInfoListMetric = R.map((n) => n.sourceInfoList, allRootCauseListMetric);
    const allSourceInfoList = R.flatten(R.concat(sourceInfoListLog, sourceInfoListMetric));
    const projectNames = [];
    const instanceTypeMap = {};
    R.forEach((sourceInfo) => {
      const { instanceName, sourceDetail, sourceProjectName } = sourceInfo;
      const { eventType, metricType, type, content } = sourceDetail;

      if (!projectNames.includes(sourceProjectName)) {
        projectNames.push(sourceProjectName);
      }
      const category = type === 'Metric' ? metricType || content : eventType;
      if (instanceTypeMap[instanceName]) {
        if (!instanceTypeMap[instanceName].includes(category)) {
          instanceTypeMap[instanceName].push(category);
        }
        return;
      }
      instanceTypeMap[instanceName] = [category];
    }, allSourceInfoList);

    const hasErrorSummary = R.isEmpty(instanceTypeMap) || R.isEmpty(projectNames);

    return (
      <Descriptions bordered size="small" column={2} style={{ marginBottom: 10 }}>
        <Descriptions.Item label="Root cause summary" span={2} labelStyle={{ width: 166 }}>
          {hasErrorSummary && <div>No causally related anomalies are found</div>}
          {!hasErrorSummary && (
            <div>
              <span style={{ marginRight: 4 }}>This incident has strong causality with this causal chain:</span>
              {R.addIndex(R.map)((item, index) => {
                const isLast = index === R.toPairs(instanceTypeMap).length - 1;
                const [instance, types] = item;
                return (
                  <span style={{ marginRight: 4, color: 'var(--blue)' }} key={instance + index}>
                    {instance}
                    <span style={{ margin: '0 2px' }}>({types.join(', ')})</span>
                    {isLast ? '' : <ArrowRightOutlined style={{ marginLeft: 4 }} />}
                  </span>
                );
              }, R.toPairs(instanceTypeMap))}
              from projects
              {R.addIndex(R.map)(
                (projectName, index) => (
                  <span style={{ marginLeft: 4, color: 'var(--blue)' }} key={projectName + index}>
                    {projectName}
                    {index === projectNames.length - 1 ? '' : ','}
                  </span>
                ),
                projectNames,
              )}
            </div>
          )}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  render() {
    const { intl, onClose, incident, environmentId, systemId } = this.props;
    const { isLoading, rootCausesData, predictedData } = this.state;
    return (
      <Modal
        visible
        title={intl.formatMessage(logMessages.relatedAnomalies)}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={1200}
        bodyStyle={{ height: 560 }}
      >
        <div className="flex-col" style={{ height: '100%' }}>
          {this.renderDescription(rootCausesData)}
          <div style={{ flex: 1, width: '100%' }}>
            <LikelyRootCauses
              environmentId={environmentId}
              systemId={systemId}
              isLoading={isLoading}
              incident={incident}
              rootCausesData={rootCausesData}
              predictedData={predictedData}
              onReload={this.reloadData}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const LikelyRootCausesModal = injectIntl(LikelyRootCausesModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, projectDisplayMap, systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      currentLocale,
      projectDisplayMap,
      systemsMap,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(LikelyRootCausesModal);
