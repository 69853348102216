/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { isNumber } from 'util';
import { Select } from 'antd';

import { State } from '../../../common/types';
import { Container } from '../../../lib/fui/react';
import { Modal } from '../../../../artui/react';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/log/actions';
import { parseLocation, getLoadStatus, LogRenderers } from '../../../common/utils';
import { logMessages } from '../../../common/log/messages';

import EventGroup from '../../../../components/log/loganalysis/event-group';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,
  projects: Array<Object>,
  onClose: Function,
  clusterKeywords: Array<Object>,
  showTopEntries: Boolean,
  cListIds: Array<Object>,
  featureKeywordMapping: Array<Object>,
  logEntryList: Array<Object>,
  logEntryListTotal: Number,
  originSize: ?Number,
  compressedSize: ?Number,
  decreaseRatio: ?Number,
  clusterFeatureKeywords: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  clusterInfoList: Array<Object>,
  totalFreqVectorNidMap: Object,
};

class LogEntryContentModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.dataLoader = 'log_data_content';
    this.logEntryList = [];
    this.pageSize = 100;

    const params = parseLocation(props.location);
    const { featureKey, featureKeyword } = params;
    const { featureWord } = this.getFeatureInfo({ featureKey, featureKeyword });
    this.state = {
      pageNo: 1,
      featureKey,
      featureKeyword: featureWord,
      showHighlightCategory: 'none',
    };
    this.highlightOptions = [
      { label: intl.formatMessage(logMessages.none), value: 'none' },
      { label: intl.formatMessage(logMessages.common), value: 'common' },
      { label: intl.formatMessage(logMessages.difference), value: 'difference' },
    ];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, logEntryList } = nextProps;
    const nextParams = parseLocation(location);
    const params = parseLocation(this.props.location);

    if (
      params.startTimestamp !== nextParams.startTimestamp ||
      params.endTimestamp !== nextParams.endTimestamp ||
      params.nid !== nextParams.nid ||
      params.featureKey !== nextParams.featureKey ||
      params.featureKeyword !== nextParams.featureKeyword
    ) {
      if (
        (params.startTimestamp !== nextParams.startTimestamp && nextParams.startTimestamp) ||
        (params.endTimestamp !== nextParams.endTimestamp && nextParams.endTimestamp) ||
        params.nid !== nextParams.nid
      ) {
        this.setState(
          { pageNo: 1, featureKey: nextParams.featureKey, featureKeyword: nextParams.featureKeyword },
          () => {
            this.reloadData(nextProps);
          },
        );
      }
    } else if (logEntryList !== this.props.logEntryList) {
      this.preprocessData(nextProps);
    }
  }

  @autobind
  getFeatureInfo({ featureKey, featureKeyword }) {
    let featureType = null;
    let featureKeyOnly = featureKey;
    if (featureKey) {
      featureType = R.split('@', featureKey)[0];
      featureKeyOnly = R.join('@', R.slice(1, Infinity, R.split('@', featureKey)));
    }
    let featureWord = featureKeyword;
    if (featureType === 'fieldName') {
      featureWord = R.join(':', R.slice(1, Infinity, R.split(':', featureKeyword)));
    }
    return { featureType, featureWord, featureKeyOnly };
  }

  @autobind
  reloadData(props) {
    const {
      location,
      createLoadAction,
      showTopEntries,
      cListIds,
      totalFreqVectorNidMap,
      clusterFeatureKeywords,
    } = props;
    const params = parseLocation(location);
    const { projectName, instanceName, day, startTimestamp, endTimestamp, nid } = params;
    const { featureKey } = params;
    let { featureKeyword } = params;
    const { featureType, featureWord, featureKeyOnly } = this.getFeatureInfo({ featureKey, featureKeyword });
    if (featureKeyword) {
      const [map, key] = R.split('[', featureKeyword);
      if (key) {
        featureKeyword = R.split(']', key)[0] || featureKeyword;
      }
    }
    const { pageNo } = this.state;

    let allPatternNidMapList = [];
    if (nid === 'All') {
      const featureToNidMap = get(clusterFeatureKeywords, [nid, 'featureToNidMap'], {});
      const featureToNidMapList = featureKeyword ? get(featureToNidMap, [featureKeyword, startTimestamp], []) : [];
      allPatternNidMapList =
        featureToNidMapList.length > 0
          ? R.map((nid) => String(nid), featureToNidMapList)
          : get(totalFreqVectorNidMap, startTimestamp, []);
    }
    const query = {
      projectName,
      instanceName,
      day,
      nid: showTopEntries ? cListIds : nid === 'All' ? allPatternNidMapList : nid === 'MISC' ? '-2' : nid,
      startTimestamp,
      isFetchAll: true,
      endTimestamp,
      pageNo,
      pageSize: this.pageSize,
      featureWord,
    };
    if (featureType === 'fieldName') {
      query.jsonPath = featureKeyOnly;
    }
    createLoadAction(ActionTypes.LOAD_LOG_HOTCOLD_ENTRY_LIST, query, this.dataLoader);
  }

  @autobind
  preprocessData(props) {
    const { location, projects, featureKeywordMapping, logEntryList, clusterKeywords } = props;
    const params = parseLocation(location);
    const { projectName } = params;
    let { featureKeyword } = params;

    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects,
    );
    const projectLinkInfo = get(project, 'projectLinkInfo', []);

    this.logEntryList = logEntryList;
    if (projectLinkInfo.length > 0) {
      this.logEntryList = R.map((e) => {
        return { ...e, projectLinkInfo };
      }, this.logEntryList);
    }

    if (featureKeyword) {
      const [map, key] = R.split('[', featureKeyword);
      if (key) {
        featureKeyword = R.split(']', key)[0] || featureKeyword;
      }
      // this.logKeywordList = featureKeyword;
      this.logKeywordList = [featureKeyword, ...(featureKeywordMapping || [])];
    } else {
      this.logKeywordList = R.uniq(R.filter((e) => Boolean(e), R.map((e) => e.keyword, this.logEntryList)));
    }
    this.logKeywordList = [...this.logKeywordList, ...clusterKeywords];
  }

  @autobind
  handlePageChanged(pageNo) {
    this.setState({ pageNo }, () => {
      this.reloadData(this.props);
    });
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  render() {
    const {
      intl,
      loadStatus,
      location,
      showTopEntries,
      cListIds,
      clusterInfoList,
      logEntryListTotal,
      originSize,
      compressedSize,
      decreaseRatio,
    } = this.props;
    const params = parseLocation(location);
    const { nid, startTimestamp, endTimestamp, featureKeyword } = params;
    const { pageNo, showHighlightCategory } = this.state;

    const clusterInfos =
      nid === 'All'
        ? clusterInfoList
        : R.filter((c) => {
            if (showTopEntries) {
              return cListIds.indexOf(String(c.nid)) >= 0;
            }
            return String(c.nid) === nid;
          }, clusterInfoList);
    const clusterInfo = clusterInfos.length > 0 ? clusterInfos[0] : null;
    const clusterSampleMsgs = {};
    R.forEach((c) => {
      const sampleMsgs = get(c, ['sampleMsg'], []);
      R.addIndex(R.forEach)((s, idx) => {
        clusterSampleMsgs[`${c.nid}[${idx}]`] = s;
      }, sampleMsgs);
    }, clusterInfos);

    const hasResult = Boolean(startTimestamp) && Boolean(endTimestamp);

    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const hasDecreaseInfo = isNumber(decreaseRatio);
    const logOptions = { width: 1000 - 20, highlightWord: null, onChanged: () => {}, defaultExpanded: true };

    return (
      <Modal size="big" onClose={this.handleClose}>
        <Container
          style={{ height: 640, padding: '8px 0px', fontSize: 13 }}
          className={`flex-col  ${isLoading && !errorMessage ? ' loading' : ''}`}
        >
          {nid !== 'MISC' &&
            hasDecreaseInfo &&
            LogRenderers.CompressTitle({ originSize, compressedSize, decreaseRatio }, intl)}
          {false &&
            hasDecreaseInfo &&
            clusterInfos.length > 0 &&
            LogRenderers.CompressSimpleMsg({ clusterInfos, logOptions }, intl)}
          {nid !== 'MISC' && (
            <div className="flex-row flex-center-align">
              <span style={{ fontWeight: 500, paddingRight: 8 }}>
                {intl.formatMessage(logMessages.highlightOption)}:
              </span>
              <Select
                size="small"
                style={{ width: 100 }}
                value={showHighlightCategory}
                onChange={(value) => this.setState({ showHighlightCategory: value })}
              >
                {R.map(
                  (item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ),
                  this.highlightOptions,
                )}
              </Select>
            </div>
          )}
          <Container className="flex-grow" style={{ overflowY: 'hidden' }}>
            {hasResult && (
              <EventGroup
                name=""
                pageNo={pageNo}
                pageSize={this.pageSize}
                totalCount={logEntryListTotal}
                onPageChanged={this.handlePageChanged}
                eventDataset={this.logEntryList}
                showFE
                hasType={false}
                hasNid
                hasContext
                keywords={showTopEntries ? [] : get(clusterInfo, 'keywords', [])}
                featureKeywords={showTopEntries ? [] : get(clusterInfo, 'featureKeywords', [])}
                highlightWord={featureKeyword || null}
                showHighlightCategory={showHighlightCategory}
                hasDecompress
                clusterSampleMsgs={clusterSampleMsgs}
                showLinkedJump
              />
            )}
          </Container>
        </Container>
      </Modal>
    );
  }
}

const LogEntryContentModal = injectIntl(LogEntryContentModalCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    const { detailInfos, clusterFeatureKeywords } = state.log;
    const logEntryList = get(state.log, 'logEntryList', []);
    const logEntryListTotal = get(state.log, 'logEntryListTotal', 0);
    const clusterInfoList = get(detailInfos, 'clusterInfoList', []);
    const totalFreqVectorNidMap = get(detailInfos, 'totalFreqVectorNidMap', {});
    const originSize = get(state.log, 'originSize');
    const compressedSize = get(state.log, 'compressedSize');
    const decreaseRatio = get(state.log, 'decreaseRatio');

    return {
      location,
      loadStatus,
      projects,
      clusterFeatureKeywords,
      logEntryList,
      clusterInfoList,
      totalFreqVectorNidMap,
      logEntryListTotal,
      originSize,
      compressedSize,
      decreaseRatio,
    };
  },
  { createLoadAction },
)(LogEntryContentModal);
