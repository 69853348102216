import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Input, Popconfirm, Spin, Tabs, message } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import fetchGet from '../../../../common/apis/fetchGet';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { Container } from '../../../../lib/fui/react';

import CumulativeDistributionFunction from './CumulativeDistributionFunction';

import { appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

const submitLoader = 'project_base_setting';

function CumulativeDistributionFunctionTabsCore(props: Object) {
  const { intl, data, credentials, projectName, saveProjectSettings, currentProject } = props || {};
  const { currentLoadingComponents } = props || {};
  const { cdfSetting: localCdfSetting = [] } = data || {};
  const cdfSettingKey = JSON.stringify(localCdfSetting);

  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isLoading: false,
    fieldNameOptions: [],
    scoreKeyActive: null,
    cdfSetting: [],
    addScoreKeyVal: '',
  });
  const { fieldNameOptions, isLoading, scoreKeyActive, cdfSetting, addScoreKeyVal } = state;

  const getLogJsonType = () => {
    updateLastActionInfo();
    return fetchGet(getEndpoint('logjsontype'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          const fieldNameOptions = R.map((item) => ({ value: item.jsonKey, label: item.jsonKey }), data || []);
          return fieldNameOptions;
        } else {
          message.error(msg);
          return [];
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        return [];
      });
  };

  const reloadData = async () => {
    setState({ isLoading: true });

    const fieldNameOptions = await getLogJsonType();

    const cdfSetting = R.clone(localCdfSetting || []);

    setState({
      fieldNameOptions,
      isLoading: false,
      scoreKeyActive: scoreKeyActive || cdfSetting[0]?.scoreKey,
      cdfSetting,
    });
  };

  useEffect(() => {
    reloadData();
  }, [cdfSettingKey]);

  const saveCFD = (projectName, settings) => {
    const newCdfSetting = R.map((item) => {
      if (item.scoreKey === settings.scoreKey) {
        return settings;
      }
      return item;
    }, cdfSetting || []);

    saveProjectSettings(
      projectName,
      { cdfSetting: newCdfSetting, notPostHasSetData: true },
      {
        [submitLoader]: true,
      },
    );
  };

  const addscoreKey = () => {
    const findScoreKey = R.find((item) => item.scoreKey === addScoreKeyVal, cdfSetting || []);
    const { projectShortName, owner } = currentProject || {};
    if (findScoreKey) {
      message.warning('Do not add duplicate score keys!');
      setState({ addScoreKeyVal: '' });
      return;
    }
    const newCdfSetting = [
      ...cdfSetting,
      { scoreKey: addScoreKeyVal, modelSpan: 86400000, metricProjectName: null, buckets: {}, selectedGroups: [] },
    ];

    saveProjectSettings(
      projectName.indexOf('@') >= 0 ? projectName : `${projectShortName}@${owner}`,
      { cdfSetting: newCdfSetting, notPostHasSetData: true },
      {
        [submitLoader]: true,
      },
    );

    setState({ addScoreKeyVal: '', cdfSetting: newCdfSetting, scoreKeyActive: addScoreKeyVal });
  };

  const scoreAddRender = (flag) => {
    return (
      <Popconfirm
        title={
          <div className="flex-row flex-center-align">
            <div style={{ marginRight: 8, width: 70, flexShrink: 0 }}>
              {intl.formatMessage(settingsMessages.scoreKey)}:
            </div>
            <Input
              size="small"
              allowClear
              value={addScoreKeyVal}
              onChange={(e) => setState({ addScoreKeyVal: e.target.value })}
            />
          </div>
        }
        overlayStyle={{ width: 300 }}
        arrowPointAtCenter
        overlayClassName="clear_padding"
        icon={null}
        okText={intl.formatMessage(appButtonsMessages.create)}
        cancelText={intl.formatMessage(appButtonsMessages.cancel)}
        onConfirm={addscoreKey}
        onCancel={(event) => {
          setState({ addScoreKeyVal: '' });
          event.stopPropagation();
        }}
        okButtonProps={{ disabled: !addScoreKeyVal }}
      >
        {flag ? (
          <Button
            size="small"
            className="full-height full-width"
            icon={<PlusOutlined />}
            style={{ width: 140, marginBottom: 8, border: 'none', borderRadius: '4px 4px 0 0' }}
            onClick={(e) => e.stopPropagation()}
          >
            Add CDF setting
          </Button>
        ) : (
          <Button
            size="small"
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 140, marginBottom: 8 }}
            onClick={(e) => e.stopPropagation()}
          >
            Add CDF setting
          </Button>
        )}
      </Popconfirm>
    );
  };

  const onEdit = (targetKey, action) => {
    if (action === 'remove') {
      if (window.confirm(`Are you sure you want to delete score key: ${targetKey} ?`)) {
        const { projectShortName, owner } = currentProject || {};
        const newCdfSetting = R.filter((item) => item.scoreKey !== targetKey, cdfSetting || []);

        saveProjectSettings(
          projectName.indexOf('@') >= 0 ? projectName : `${projectShortName}@${owner}`,
          { cdfSetting: newCdfSetting, notPostHasSetData: true },
          {
            remove: true,
          },
        );

        setState({
          cdfSetting: newCdfSetting,
          ...(targetKey === scoreKeyActive ? { scoreKeyActive: newCdfSetting[0]?.scoreKey } : {}),
        });
      }
    }
  };

  const isSubmitting = get(currentLoadingComponents, 'remove', false);

  return (
    <Container className="full-height flex-min-width flex-min-height flex-col">
      <Spin spinning={isSubmitting || isLoading} wrapperClassName="full-width full-height spin-full-height">
        {cdfSetting.length > 0 ? (
          <Tabs
            className="flex-grow flex-min-height ant-tabs-content-full-height clear_padding"
            tabBarStyle={{ width: '100%' }}
            tabPosition="top"
            type="editable-card"
            tabBarGutter={6}
            activeKey={scoreKeyActive}
            onChange={(scoreKeyActive) => setState({ scoreKeyActive })}
            onEdit={onEdit}
            addIcon={scoreAddRender(true)}
          >
            {R.map((item) => {
              return (
                <Tabs.TabPane tab={item.scoreKey} key={item.scoreKey}>
                  <CumulativeDistributionFunction
                    fieldNameOptions={fieldNameOptions}
                    cdfSetting={item}
                    {...props}
                    saveCFD={saveCFD}
                    submitLoader={submitLoader}
                    cdfSettings={cdfSetting}
                  />
                </Tabs.TabPane>
              );
            }, cdfSetting || [])}
          </Tabs>
        ) : (
          scoreAddRender()
        )}
      </Spin>
    </Container>
  );
}

const CumulativeDistributionFunctionTabs = injectIntl(CumulativeDistributionFunctionTabsCore);
export default connect(
  (state: State) => {
    const { projects } = state.app;
    const { credentials, userInfo } = state.auth;

    return { credentials, userInfo, projects };
  },
  {
    updateLastActionInfo,
  },
)(CumulativeDistributionFunctionTabs);
