/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Button, Spin, Popconfirm, message, Alert } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Container, Table, Column, AutoSizer, Modal } from '../../../lib/fui/react';
import { parseJSON } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appButtonsMessages, appMessages } from '../../../common/app/messages';

import AddIDPAzureModal from './AddIDPAzureModal';

type Props = {
  // eslint-disable-next-line
  onClose: Function,
  refresh: Number,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  replace: Function,
  updateLastActionInfo: Function,
  isAdmin: boolean,
};

class IDPAzureModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isDeletingMap: {},
      eventList: [],
      companies: [],

      showAddIDPModal: false,
      activeIncident: undefined,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refresh !== nextProps.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials } = props;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('idpsetting'), {
      ...credentials,
      identityProviderType: 'Azure',
    })
      .then((data) => {
        const { companies, idpSettings } = data || {};
        let eventList = idpSettings;
        eventList = R.filter((item) => {
          return get(item, ['key', 'identityProviderType']) === 'Azure';
        }, eventList);
        eventList = R.map((item) => {
          const { key, userListString, groupListString } = item;
          const groupList = parseJSON(groupListString) || [];
          const userList = parseJSON(userListString) || [];
          return { ...item, ...key, userListString: R.join(', ', userList), groupList, rawData: item };
        }, eventList);

        this.setState({ isLoading: false, errMsg: null, eventList, companies });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, errMsg: err.message || String(err), eventList: [], companies: [] });
      });
  }

  @autobind
  handleAddNewClick() {
    this.setState({ showAddIDPModal: true, activeIncident: undefined });
  }

  @autobind
  handleEditClick({ rowData }) {
    this.setState({ showAddIDPModal: true, activeIncident: rowData });
  }

  @autobind
  handleIDPSettingRemove(rowData) {
    const { intl, credentials } = this.props;
    const { isDeletingMap } = this.state;
    const { identityProviderType, clientId, rawData } = rowData;
    const key = `${identityProviderType}-${clientId}`;

    this.setState({ isDeletingMap: { ...isDeletingMap, [key]: true } });
    this.props.updateLastActionInfo();
    fetchDelete(getEndpoint('idpsetting'), {
      ...credentials,
      identityProviderType: 'Azure',
      settings: JSON.stringify([rawData]),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isDeletingMap: { ...isDeletingMap, [key]: false } });
          this.reloadData(this.props);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isDeletingMap: { ...isDeletingMap, [key]: false }, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isDeletingMap: { ...isDeletingMap, [key]: false }, errMsg: String(err) });
      });
  }

  @autobind
  removeRenderer({ rowData }) {
    const { intl } = this.props;
    const { isDeletingMap } = this.state;
    const { identityProviderType, clientId } = rowData;
    const key = `${identityProviderType}-${clientId}`;

    return (
      <div className="flex-row">
        <Popconfirm
          placement="topRight"
          title={intl.formatMessage(appMessages.continueConfirm)}
          onConfirm={(event) => {
            event.stopPropagation();
            this.handleIDPSettingRemove(rowData);
          }}
          onCancel={(event) => event.stopPropagation()}
        >
          <Button
            size="small"
            className="button-color-grey"
            loading={get(isDeletingMap, key, false)}
            onClick={(event) => event.stopPropagation()}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </Popconfirm>
      </div>
    );
  }

  render() {
    const { onClose, isAdmin } = this.props;
    const { isLoading, errMsg, eventList } = this.state;
    return (
      <Modal width={1024} title="Azure AD" visible maskClosable={false} onCancel={() => onClose()} footer={null}>
        <Container className="flex-col" style={{ height: 500 }}>
          <div style={{ marginBottom: 12 }}>
            <Button size="small" type="primary" onClick={this.handleAddNewClick}>
              Add New
            </Button>
          </div>
          <div className="flex-grow flex-min-height">
            <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
              {errMsg && <Alert message={errMsg} type="error" showIcon />}
              {!errMsg && (
                <AutoSizer>
                  {({ height, width }) => (
                    <Table
                      className="with-border"
                      width={width}
                      height={height}
                      headerHeight={40}
                      rowClassName={({ index }) => `${index >= 0 && index % 2 === 1 ? 'odd-row' : ''} clickable`}
                      rowHeight={40}
                      rowCount={eventList.length}
                      rowGetter={({ index }) => eventList[index]}
                      onRowClick={this.handleEditClick}
                    >
                      {isAdmin && <Column width={150} label="Company" dataKey="company" flexGrow={1} />}
                      <Column width={150} label="Service name" dataKey="idpName" flexGrow={1} />
                      <Column width={150} label="Client id" dataKey="clientId" flexGrow={1} />
                      <Column width={150} label="Tenant" dataKey="tenant" flexGrow={1} />
                      <Column
                        width={110}
                        label=""
                        dataKey="id"
                        className="text-right"
                        cellRenderer={this.removeRenderer}
                      />
                    </Table>
                  )}
                </AutoSizer>
              )}
            </Spin>
          </div>

          {this.state.showAddIDPModal && (
            <AddIDPAzureModal
              companies={this.state.companies}
              activeIncident={this.state.activeIncident}
              onClose={(reload) =>
                this.setState({ showAddIDPModal: false }, () => {
                  if (reload) {
                    this.reloadData(this.props);
                  }
                })
              }
            />
          )}
        </Container>
      </Modal>
    );
  }
}

const IDPAzureModal = injectIntl(IDPAzureModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { isAdmin } = state.auth.userInfo;
    const { systemsMap } = state.app;
    const { userInfo, credentials } = state.auth;
    return { location, systemsMap, userInfo, credentials, isAdmin };
  },
  { replace, updateLastActionInfo },
)(IDPAzureModal);
