import * as R from 'ramda';
import qs from 'qs';
import { isBoolean, ceil } from 'lodash';
import { message } from 'antd';

import fetchGet from '../apis/fetchGet';
import fetchPost from '../apis/fetchPost';
import getEndpoint from '../apis/getEndpoint';
import { ActionTypes } from '../app/actions';
import { buildUrl } from '.';

import { appMessages } from '../app/messages';

const BackgroundCall = {
  CallUrlJob: ({
    intl,
    method,
    url,
    params,
    data,
    headers,
    isJsonResponse,
    apiType,
    readMessage = false,
    callback = () => {},
  }) => {
    const successMsg = apiType === 'submit' ? appMessages.apiSubmit : appMessages.apiSuccess;
    if (method === 'GET') {
      return fetchGet(url, params, headers || {}, '', isBoolean(isJsonResponse) ? isJsonResponse : true)
        .then((d) => {
          message.success(intl.formatMessage(successMsg));
          callback({ loading: true, model: true });
        })
        .catch((err) => {
          callback({ loading: true, model: false });
          console.warn('[IF_API] api call failed, ignored', err);
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    } else if (method === 'POST') {
      return fetchPost(`${url}?${qs.stringify(params)}`, data, {}, isBoolean(isJsonResponse) ? isJsonResponse : true)
        .then((d) => {
          const { success, message: messAge } = d;
          if (readMessage) {
            if (success === undefined || success) {
              callback({ loading: true, model: true });
              message.success(intl.formatMessage(successMsg));
            } else {
              callback({ loading: true, model: false });
              message.error(messAge || intl.formatMessage(appMessages.apiFaild));
            }
          } else {
            callback({ loading: true, model: true });
            message.success(intl.formatMessage(successMsg));
          }
        })
        .catch((err) => {
          callback({ loading: true, model: false });
          console.warn('[IF_API] api call failed, ignored', err);
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    }
    return window.open(buildUrl(url, {}, params), '_blank');
  },
  GetSetComponent: async ({
    dispatch,
    updateLastActionInfo,
    createSetAction,
    credentials,
    prevInstanceComponentMap,
    projectName,
    instanceNameList,
  }) => {
    // get all component/instance map from instances, if this map not exists in redux
    const requests = [];
    if (!instanceNameList || instanceNameList.length === 0) {
      requests.push(
        fetchPost(getEndpoint('projects/component'), {
          ...credentials,
          projectName,
        }),
      );
    } else {
      R.forEach((idx) => {
        let instanceList = R.slice(10000 * idx, 10000 * (idx + 1), instanceNameList);
        instanceList = R.filter((i) => !R.has(i, prevInstanceComponentMap), instanceList);

        if (instanceList.length > 0) {
          requests.push(
            fetchPost(getEndpoint('projects/component'), {
              ...credentials,
              projectName,
              instanceList: JSON.stringify(instanceList),
            }),
          );
        }
      }, R.range(0, ceil(instanceNameList.length / 10000)));
    }

    // call api
    let hasError = false;
    let allInstanceComponentMap = {};
    if (requests.length > 0) {
      if (updateLastActionInfo) {
        if (dispatch) {
          dispatch(updateLastActionInfo());
        } else {
          updateLastActionInfo();
        }
      }
      const result = await Promise.all(requests)
        .then((results) => {
          let instanceComponentMap = {};
          R.forEach((result) => {
            const data = R.fromPairs(R.map((item) => [item.instanceName, item.componentName], result || []));
            instanceComponentMap = { ...instanceComponentMap, ...data };
          }, results);
          return instanceComponentMap;
        })
        .catch((err) => {
          return false;
        });
      if (!result) {
        hasError = true;
      } else {
        allInstanceComponentMap = result;
      }

      // update redux info
      if (!R.isEmpty(allInstanceComponentMap)) {
        if (dispatch) {
          dispatch(
            createSetAction(
              ActionTypes.SET_PROJECT_INSTANCE_COMPONENT_MAP,
              {},
              { projectName, instanceComponentMap: allInstanceComponentMap },
            ),
          );
        } else {
          createSetAction(
            ActionTypes.SET_PROJECT_INSTANCE_COMPONENT_MAP,
            {},
            { projectName, instanceComponentMap: allInstanceComponentMap },
          );
        }
      }
    }

    return { hasError, allInstanceComponentMap };
  },
};

export { BackgroundCall };
