/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 * */

/**
 * Convert ratio to level
 */
const calcAnomalyLevel = (ratio) => {
  // The ratio normaly will be [0, 100], convert to [0, 5].
  const level = Math.min(Math.ceil(ratio / (100 / 5), 5));
  return level;
};

export default calcAnomalyLevel;
