import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { CheckCircleFilled } from '@ant-design/icons';
import { message } from 'antd';
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import { settingsMessages } from '../../../../common/settings/messages';
import OperationButtons from './OperationButtons';
import fetchPut from '../../../../common/apis/fetchPut';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { appMessages } from '../../../../common/app/messages';

type Props = {
  onCancel: Function,
  pageData: Object,
  onShowLoading: Function,
  onHideLoading: Function,
  context: Object,
  setShowModal: Function,
  setRefresh: Function,
};

const Step5 = (props: Props) => {
  const { pageData, onShowLoading, onHideLoading, onCancel, context, setShowModal, setRefresh } = props;
  const { dataType, selectedProjects, owner, batchName } = pageData;
  const { intl, credentials } = context;

  const [settingInfo, setSettingInfo] = useState({});

  const retureFieldNameKeyWord = (key, typeStr = 'fieldName') => {
    return `${key.type === typeStr && key.jsonKey ? `${key.jsonKey}` : ''}${
      key.type === typeStr && key.jsonKey && key.keyword ? '=' : ''
    }${key.keyword}`;
  };

  const parseKeywordTriageReport = (diff) => {
    const keywords = [];
    if (R.isEmpty(diff) || !diff) return [];
    const filterJsonkeyHasNone = R.filter((item) => item.type === 'fieldName' && !item.jsonKey, diff);
    if (filterJsonkeyHasNone.length > 0) return [];
    R.forEach((k) => {
      if (!R.isEmpty(retureFieldNameKeyWord(k)) && !R.isEmpty(k.type)) {
        keywords.push({
          type: k.type,
          keyword: retureFieldNameKeyWord(k),
        });
      }
    }, diff);
    return keywords;
  };

  const parseKeywordsByList = (trainingList) => {
    const keywords = {};
    R.forEachObjIndexed((diff, key) => {
      if (R.isEmpty(diff) || !diff) {
        return {};
      }
      const filterJsonkeyHasNone = R.filter((item) => item.type === 'fieldName' && !item.keyword, diff);
      if (filterJsonkeyHasNone.length > 0) return {};
      keywords[key] = [];
      R.forEach((k) => {
        if (!R.isEmpty(retureFieldNameKeyWord(k)) && !R.isEmpty(k.type)) {
          keywords[key].push({
            type: k.type,
            keyword: retureFieldNameKeyWord(k),
          });
        }
      }, diff);
    }, trainingList);
    return keywords;
  };

  const fieldIsNullConfig = (n) => {
    if (!n.type) return true;
    if (n.type === 'fieldName') {
      const [jsonKey, keyword] = R.split('=', n.keyword);
      return R.isEmpty(jsonKey) || R.isNil(keyword);
    } else {
      return R.isEmpty(n.keyword);
    }
  };

  const parseKetwords = (diff) => {
    const keywords = [];
    if (R.isEmpty(diff) || !diff) return [];
    // const filterJsonkeyHasNone = R.filter((item) => fieldIsNullConfig(item), diff);
    // if (filterJsonkeyHasNone.length > 0) return [];
    R.forEach((k) => {
      if (!R.isEmpty(retureFieldNameKeyWord(k)) && !R.isEmpty(k.type) && !fieldIsNullConfig(k)) {
        keywords.push({
          type: k.type,
          keyword: retureFieldNameKeyWord(k),
          isCritical: k.isCritical,
          isHotEventOnly: k.isHotEventOnly,
          systemTag: k.systemTag,
          patternNameLabels: k.patternNameLabels,
          order: k.order,
        });
      }
    }, diff);
    return keywords;
  };

  const parseKeywordsByCategory = (categoryList) => {
    const diff = R.filter((n) => !R.isEmpty(n.category), categoryList || []);

    const keywords = R.map((n) => {
      const { category, keyword } = n;
      return { category, keywordsSet: keyword.length === 0 ? [] : keyword.split(',') };
    }, diff);
    return keywords;
  };

  const findSelectSystem = (systemList) => {
    const selectedProjectNames = R.map((p) => {
      return p.projectShortName
        ? p.projectName
        : p.customerName !== credentials.userName
        ? `${p.projectName}@${p.customerName}`
        : p.projectName;
    }, selectedProjects);
    const systemListByProject = R.reduce(
      (prev, cur) => {
        const { projectDetailsList } = cur;
        const projects = R.filter(
          (p) =>
            selectedProjectNames.includes(
              p.customerName !== credentials.userName ? `${p.projectName}@${p.customerName}` : p.projectName,
            ),
          projectDetailsList,
        );
        if (R.isEmpty(projects)) {
          return prev;
        }
        return [...prev, { ...cur, projectDetailsList: projects }];
      },
      [],
      systemList,
    );

    setSettingInfo({
      systemNumber: systemListByProject.length,
      projectNumber: selectedProjectNames.length,
    });
  };

  const parseSystemList = (context, owner) => {
    const { userInfo, systemsMap } = context;
    const projectOwner = owner;
    let systemList = R.values(systemsMap);
    systemList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('systemName')))], systemList);
    if (projectOwner && false) {
      systemList = R.filter((item) => item.owner === projectOwner, systemList);
    }
    systemList.push({
      systemId: 'emptySystem',
      systemName: intl.formatMessage(settingsMessages.projectsNoSystem),
      environmentName: 'All',
      owner: userInfo.userName,
    });

    systemList = R.reduce(
      (prev, { projectDetailsList = [], ...system }) => {
        const projectList = R.filter((n) => {
          return n.dataType === dataType;
        }, projectDetailsList);
        return R.isEmpty(projectList) ? prev : [...prev, { projectDetailsList: projectList, ...system }];
      },
      [],
      systemList,
    );
    findSelectSystem(systemList);
  };

  useEffect(() => {
    parseSystemList(context, owner);
  }, []);

  const onFetchDefineBacth = (data) => {
    data.configName = batchName;
    delete data.touchedSensitivitySetting;
    delete data.touchedComponentMetricSetting;
    delete data.touchedLogLable;
    fetchPut(getEndpoint('customer-define-config', 2), data)
      .then((res) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        setRefresh(moment.utc().valueOf());
        onCancel();
        onHideLoading();
      })
      .catch((e) => {
        message.error(e.message);
        onHideLoading();
      });
  };

  const handleSaveClick = () => {
    const {
      dataType,
      selectedProjects,
      sensitivityData,
      metricConfiguration,
      logLabels = {},
      needRemoveCategory,
      touchedSensitivitySetting,
      touchedComponentMetricSetting,
      touchedLogLable,
    } = pageData;
    let requestData = {
      ...credentials,
      projectList: R.trim(
        JSON.stringify(
          R.map(
            ({ customerName, projectName, projectShortName }) => ({
              customerName,
              projectName: projectShortName || projectName,
            }),
            selectedProjects,
          ),
        ),
      ),
      dataType,
    };

    const setting = R.filter((n) => {
      return !R.isNil(n);
    }, sensitivityData);

    if (dataType === 'Metric') {
      requestData = {
        ...requestData,
        toSetComponentMetricSettingList: JSON.stringify(metricConfiguration || []),
        setting: JSON.stringify(setting),
        touchedSensitivitySetting,
        touchedComponentMetricSetting,
      };
    } else {
      const {
        blacklistLabels,
        trainingWhitelistLabels,
        extractionBlacklistLabels,
        whitelistLabels,
        featureLabels,
        categoryList = [],
        logTriageLabelList,
      } = R.filter((n) => !R.isEmpty(n), logLabels || {});

      const keywordLabel = parseKetwords(whitelistLabels);
      const keywordFeature = R.filter((n) => !R.isEmpty(n.keyword), featureLabels || []);
      const keywordsTriageReport = parseKeywordTriageReport(logTriageLabelList);

      const keywordLabelList = parseKeywordsByList({
        blacklistLabels,
        trainingWhitelistLabels,
        extractionBlacklistLabels,
      });

      const toSetLogLabels = {
        ...keywordLabelList,
        whitelistLabels: R.isEmpty(keywordLabel) ? undefined : keywordLabel,
        featureLabels: R.isEmpty(keywordFeature) ? undefined : keywordFeature,
        logTriageLabelList: R.isEmpty(keywordsTriageReport) ? undefined : keywordsTriageReport,
      };

      const mergeCategory = needRemoveCategory
        ? R.uniqBy((n) => n.category, [...categoryList, ...needRemoveCategory])
        : categoryList;

      const toSetLogCategoryModelList = parseKeywordsByCategory(mergeCategory);

      const {
        modelKeywordSetting,
        coldNumberLimit,
        keywordSetting,
        causalPredictionSetting,
        rootCauseRankSetting,
        rareAnomalyType,
        logAnomalyEventBaseScore,
      } = setting;

      const logSetting = {
        ...setting,
        causalPredictionSetting: Number(causalPredictionSetting),
        rootCauseRankSetting: Number(rootCauseRankSetting),
        modelKeywordSetting: Number(modelKeywordSetting),
        keywordSetting: Number(keywordSetting),
        rareAnomalyType: Number(rareAnomalyType),
        coldNumberLimit: Number(coldNumberLimit),
        logAnomalyEventBaseScore: JSON.stringify(logAnomalyEventBaseScore),
      };

      requestData = {
        ...requestData,
        toSetLogLabels: JSON.stringify(toSetLogLabels || {}),
        toSetLogCategoryModelList: JSON.stringify(toSetLogCategoryModelList || []),
        setting: JSON.stringify(logSetting),
        touchedSensitivitySetting,
        touchedLogLable,
      };
    }

    onShowLoading();
    fetchPut(getEndpoint('batch-project-setting', 2), requestData)
      .then((res) => {
        if (res.success) {
          onFetchDefineBacth(requestData);
        }
      })
      .catch((e) => {
        message.error(e.message);
        onHideLoading();
      });
  };

  return (
    <div className="batch-step-content step-preview">
      <div className="preview-wrap">
        <h3>This will overwrite the configuration for</h3>
        <div className="preview-circle-tag">
          <span />
          {settingInfo.systemNumber}
          <p>{settingInfo.systemNumber === 1 ? 'System' : 'Systems'}</p>
        </div>
        <div className="preview-circle-tag">
          <span />
          {settingInfo.projectNumber}
          <p>{settingInfo.projectNumber === 1 ? 'Project' : 'Projects'}</p>
        </div>
        <div style={{ marginTop: 30 }}>And cannot be undone</div>
        <div>Select Finish to apply</div>
      </div>
      <OperationButtons
        style={{ marginTop: 20 }}
        onNext={handleSaveClick}
        onCancel={() => setShowModal(false)}
        nextText="Finish"
        hidePrev
        nextIcon={() => <CheckCircleFilled style={{ color: 'white', fontSize: 14, verticalAlign: 'bottom' }} />}
      />
    </div>
  );
};

export default Step5;
