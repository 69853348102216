/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
/*  @flow */
/* eslint-disable no-console */

import { get } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getExternalServiceList = (credentials: Credentials) => {
  return fetchGet(getEndpoint('system/externalServlies/list'), {
    ...credentials,
  }).then((d) => {
    const extServiceAllInfo = get(d, 'extServiceAllInfo', []);
    return {
      rawData: d.data,
      data: extServiceAllInfo,
    };
  });
};

export default getExternalServiceList;
