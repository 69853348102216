import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { SmileOutlined, EyeInvisibleOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Spin, Switch } from 'antd';

import { Defaults, EventRenderers, LogRenderers } from '../../../common/utils';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer, Popover } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { ChangeEventIcon, IncidentPredictIcon } from '../../../lib/fui/icons';

import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { appFieldsMessages } from '../../../common/app/messages';

import IncidentListModal from './IncidentListModal';
import { logMessages } from '../../../common/log/messages';

type Props = {
  style: Object,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  selectAnomalyInstance: String,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoading: Boolean,
  // eslint-disable-next-line
  reloadSystem: Number,
  // eslint-disable-next-line
  onReloadSystemIncidentTimelines: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  currentTheme: String,
};

class GlobalPanelDeploymentsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {
      events: [],
      eventList: [],
      activeEvent: null,

      ignoreFilter: true,

      showDetailsModal: false,
    };
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.systemIncidentTimelines !== this.props.systemIncidentTimelines) {
      this.parseData(nextProps);
    }
  }

  @autobind
  parseData(props) {
    const { systemIncidentTimelines, systemInfo } = props;
    const { sortBy, sortDirection, activeEvent } = this.state;

    let events = R.filter((event) => R.toLower(event.type) === 'deployment', systemIncidentTimelines || []);

    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];
      let instanceList = [];
      let metricList = [];

      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          metricList.push(pair.rootCauseMetric || pair.metricName);

          const instanceName = pair.instanceId || pair.instanceName;
          instanceList.push(instanceName);
          const componentName = get(instanceComponentMap, instanceName, instanceName);
          componentList.push(componentName);
          const appName =
            componentName && componentName.indexOf(instanceName) === -1
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        instanceList.push(item.instanceName);
        const { realInstanceName } = item;
        const componentName = get(instanceComponentMap, realInstanceName, item.componentName);
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== realInstanceName
            ? `${item.instanceName} (${componentName})`
            : item.instanceName;
        instanceAppNames.push(appName);
      }
      metricList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(metricList));
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      const appName = R.join(', ', instanceAppNames);
      const componentNameString = R.join(', ', componentList);
      const instanceNameString = R.join(', ', instanceList);

      let typeList = [];
      if (item.category === 'metric' && item.type !== 'Incident') {
        typeList = ['metricanomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', item.type).split('&'));
      }

      return {
        ...item,
        rootCauseJson: { rootCauseDetailsArr },
        appName,
        componentNameString,
        instanceNameString,
        instanceAppNames,
        componentList,
        instanceList,
        metricList,
        typeList,
      };
    }, events);

    // sort
    let eventList = this.filterData(events);
    // sort
    eventList = this.sortData(eventList, sortBy, sortDirection);

    // get new incident
    let newActiveIncident;
    if (activeEvent) {
      newActiveIncident = R.find((item) => item.id === activeEvent.id, eventList);
    }

    if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
    this.setState({
      events,
      eventList,
      activeEvent: newActiveIncident,
    });
  }

  @autobind
  filterData(eventList) {
    const { ignoreFilter } = this.state;
    let filterList = eventList || [];

    if (ignoreFilter) {
      filterList = R.filter((event) => !event.isIgnored, filterList);
    }
    return filterList;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.descend(R.prop('startTimestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  onChangeFilterIgnore(ignoreFilter) {
    this.setState({ ignoreFilter }, () => {
      const { sortBy, sortDirection } = this.state;
      const events = this.state.events || [];

      // sort
      let eventList = this.filterData(events);
      // sort
      eventList = this.sortData(eventList, sortBy, sortDirection);

      if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.setState({ eventList });
    });
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }) {
    const { intl } = this.props;
    const { eventList } = this.state;
    const item = eventList[rowIndex];
    if (!item) return null;

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div
          className={`event-list-row clickable ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          style={{ ...style }}
          onClick={() => this.handleDetailsClick(item)}
        >
          <div className="row-column" style={{ width: 60 }}>
            {this.statusRender(item)}
          </div>
          <div className="row-column" style={{ minWidth: 80 }}>
            {moment.utc(item.startTimestamp).format(Defaults.ShortTimeFormat)}
          </div>
          <div className="row-column" style={{ width: 100, flex: 1 }}>
            <Popover
              title={null}
              content={
                <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 180 }}>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.projectName)}:
                    </div>
                    <div className="flex-grow">{item.projectName}</div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.componentName)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {item.componentNameString}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.instanceName)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {item.instanceNameString}
                    </div>
                  </div>
                </div>
              }
              placement="right"
              mouseEnterDelay={0.3}
            >
              <div className="max-width hidden-line-with-ellipsis">{item.componentNameString}</div>
            </Popover>
          </div>
          <div className="row-column" style={{ width: 80 }}>
            <Popover
              title={null}
              content={
                <div className="flex-col overflow-y-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.projectName)}:
                    </div>
                    <div className="flex-grow">{item.projectName}</div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.componentName)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {item.componentNameString}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.instanceName)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {item.instanceNameString}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.patternId)}:</div>
                    <div className="flex-grow">{item.patternId}</div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.startTime)}:</div>
                    <div className="flex-grow">{moment.utc(item.startTimestamp).format(Defaults.ShortTimeFormat)}</div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', minWidth: 120 }}>
                      {intl.formatMessage(eventMessages.shortDescription)}:
                    </div>
                    <div className="flex-grow">{this.renderDetails(item)}</div>
                  </div>
                </div>
              }
              placement="right"
              mouseEnterDelay={0.3}
            >
              {item.patternId}
            </Popover>
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  statusRender(rowData) {
    const { intl } = this.props;
    const { rootCauseResultInfo, isIgnored } = rowData;
    const hasTrailing = get(rootCauseResultInfo, 'hasTrailingEvent');

    return (
      <div className="flex-row">
        <Popover
          content={
            hasTrailing
              ? intl.formatMessage(DashboardMessages.changeHasTrailing)
              : intl.formatMessage(DashboardMessages.changeHasNoTrailing)
          }
          mouseEnterDelay={0.3}
          placement="top"
        >
          {hasTrailing ? (
            <IncidentPredictIcon style={{ color: '#FF5142', fontSize: 16, marginLeft: 4 }} />
          ) : (
            <SmileOutlined style={{ color: '#52c41a', fontSize: 16, marginLeft: 4 }} />
          )}
        </Popover>
        {isIgnored && (
          <Popover content={intl.formatMessage(eventMessages.ignored)} mouseEnterDelay={0.3} placement="top">
            <EyeInvisibleOutlined style={{ fontSize: 16, marginLeft: 4, color: 'red' }} />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  renderDetails(rowData) {
    const { intl, currentTheme } = this.props;
    const { rawData, rootCauseJson } = rowData;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error);
    }
    const rootCauseDetailsArr = get(rootCauseJson, ['rootCauseDetailsArr'], []);

    return (
      <div>
        {rawData && (
          <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
            {!rawDataJson &&
              R.join(
                '\n',
                R.filter((x) => Boolean(x), (rawData || '').split('\n')),
              )}
            {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
          </div>
        )}
        {rootCauseDetailsArr.length > 0 && (
          <div style={{ wordBreak: 'break-all' }}>
            {R.addIndex(R.map)(
              (event, index) =>
                EventRenderers.RenderMetricAnomalySummary({
                  intl,
                  category: 'shortDisplay',
                  event,
                  index,
                  metricUnitMap: {},
                }),
              rootCauseDetailsArr || [],
            )}
          </div>
        )}
      </div>
    );
  }

  @autobind
  handleDetailsClick(rowData) {
    this.setState({ showDetailsModal: true, activeEvent: rowData });
  }

  @autobind
  handleDetailsModalClose(reload, notCloseModal) {
    this.setState({ showDetailsModal: Boolean(notCloseModal) }, () => {
      if (reload) {
        const { systemInfo } = this.props;
        this.props.onReloadSystemIncidentTimelines([systemInfo.id]);
      }
    });
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection, eventList } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const newEventList = this.sortData(eventList, name, sortDir);
          this.setState({ eventList: newEventList });
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const { intl, style, isLoading, systemInfo } = this.props;
    const { sortBy, sortDirection } = this.state;
    const { eventList, activeEvent, ignoreFilter } = this.state;

    return (
      <div className="flex-grow flex-col" style={{ ...style, minWidth: 150, padding: 8 }}>
        <div
          className="flex-row flex-center-align font-14"
          style={{ height: 24, marginBottom: 8, position: 'relative' }}
        >
          <div className="flex-grow">
            <ChangeEventIcon style={{ color: 'orange', marginRight: 4 }} />
            {intl.formatMessage(DashboardMessages.detectedDeployments)}
          </div>

          <Popover placement="top" content={intl.formatMessage(logMessages.hideIgnoredEvents)} mouseEnterDelay={0.3}>
            <Switch size="small" checked={ignoreFilter} onChange={this.onChangeFilterIgnore} />
          </Popover>
        </div>
        <div className="flex-grow">
          <Spin wrapperClassName="full-width full-height spin-full-height" spinning={isLoading}>
            <AutoSizer>
              {({ width, height }) => (
                <div className="event-list">
                  <div
                    className="event-list-header"
                    style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                  >
                    <div className="header-column" style={{ width: 60 }} />
                    <div
                      className="header-column"
                      style={{ minWidth: 80 }}
                      onClick={this.headerClick('startTimestamp')}
                    >
                      <span>{intl.formatMessage(appFieldsMessages.time)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'startTimestamp')}
                    </div>
                    <div
                      className="header-column"
                      style={{ width: 100, flex: 1 }}
                      onClick={this.headerClick('componentNameString')}
                    >
                      <span>{intl.formatMessage(appFieldsMessages.component)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'componentNameString')}
                    </div>
                    <div className="header-column" style={{ width: 80 }} onClick={this.headerClick('patternId')}>
                      <span>{intl.formatMessage(appFieldsMessages.pattern)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'patternId')}
                    </div>
                  </div>
                  <List
                    className="event-list-grid"
                    ref={(listNode) => {
                      this.listNode = listNode;
                    }}
                    width={width}
                    height={height - 30}
                    rowCount={eventList.length}
                    overscanRowCount={4}
                    deferredMeasurementCache={this.cellMeasureCache}
                    rowHeight={this.cellMeasureCache.rowHeight}
                    rowRenderer={this.renderListItem}
                    onScrollbarPresenceChange={({ horizontal, vertical }) => {
                      if (vertical) {
                        this.listNodeHeaderScrollbar = true;
                      } else {
                        this.listNodeHeaderScrollbar = false;
                      }
                      if (this.listNode) this.listNode.forceUpdateGrid();
                    }}
                  />
                </div>
              )}
            </AutoSizer>
          </Spin>
        </div>

        {this.state.showDetailsModal && (
          <IncidentListModal
            title={intl.formatMessage(DashboardMessages.detectedDeployments)}
            tabName="changeEvents"
            systemInfo={systemInfo}
            events={[activeEvent]}
            // events={events}
            isLoading={isLoading}
            onClose={this.handleDetailsModalClose}
          />
        )}
      </div>
    );
  }
}

const GlobalPanelDeployments = injectIntl(GlobalPanelDeploymentsCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,
      currentTheme,
    };
  },
  { updateLastActionInfo },
)(GlobalPanelDeployments);
