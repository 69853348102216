/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  LineChartOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Timeline, Button, Empty, Spin, message } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { TrendIcon, QeuryIcon } from '../../../lib/fui/icons';
import { Popover } from '../../../lib/fui/react';
import {
  Defaults,
  GlobalRenderers,
  CellRenderers,
  LogRenderers,
  EventRenderers,
  GlobalParse,
  buildUrl,
  parseLocation,
} from '../../../common/utils';
import { State } from '../../../common/types';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

import InsightQueryBoxModal from './InsightQueryBoxModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  incident: Object,
  // eslint-disable-next-line
  selectChain: Object,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  // eslint-disable-next-line
  onToggleCollapse: Function,

  intl: Object,
  // eslint-disable-next-line
  match: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  currentLocale: String,
  projects: Array<Object>,
  projectDisplayMap: Object,
  globalInfo: Object,
  credentials: Object,
  summarySettingsMap: Object,
  currentTheme: String,
};

class RootCauseTimelineListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { summarySettingsMap } = props;

    this.state = {
      timelines: [],
      eventList: [],
      patternNameMap: {},

      activeKey: null,

      showInsightQueryBox: false,
      rootCauseEvent: null,
    };
    this.eventList = [];
    this.summarySettingsMap = summarySettingsMap || {};
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectChain !== this.props.selectChain) {
      this.parseData(nextProps);
    }
  }

  componentWillUnmount() {}

  @autobind
  parseData(props) {
    const { incident, selectChain } = props;
    const { operation } = selectChain || {};

    const incidentKey = incident.id || R.join('-', incident.rootCauseTableKey || []);
    let eventList = [];
    R.forEach((item) => {
      const { sourceKey } = item;
      eventList.push({ ...item, key: sourceKey });
    }, selectChain.sourceInfoList || []);

    // build timelines with sorted
    let timelines = R.map((item) => {
      const { sourceKey, patternId, displayTimestamp } = item;
      return { ...item, timestamp: displayTimestamp, patternIds: [patternId], key: sourceKey };
    }, eventList);
    // add root
    if (operation === 'rootCauseEvents') {
      timelines = [
        ...timelines,
        {
          key: incidentKey,
          timestamp: incident.displayTimestamp,
          isRoot: true,
          event: incident,
        },
      ];
    } else {
      timelines = [
        {
          key: incidentKey,
          timestamp: incident.displayTimestamp,
          isRoot: true,
          event: incident,
        },
        ...timelines,
      ];
    }

    // append incident event to timelineInfos
    if (operation === 'rootCauseEvents') {
      eventList = [
        ...eventList,
        {
          ...incident,
          key: incidentKey,
          displayTimestamp: incident.displayTimestamp,
          isRoot: true,
        },
      ];
    } else {
      eventList = [
        {
          ...incident,
          key: incidentKey,
          displayTimestamp: incident.displayTimestamp,
          isRoot: true,
        },
        ...eventList,
      ];
    }

    const activeKey = null;
    this.eventList = this.filterData(eventList, { ...this.state, activeKey });
    this.setState({ timelines, eventList, activeKey }, () => {
      if (this.props.onToggleCollapse) this.props.onToggleCollapse();
    });
  }

  @autobind
  filterData(events, state) {
    const { activeKey } = state;

    let eventList = events || [];
    if (activeKey) {
      eventList = R.filter((event) => event.key === activeKey, eventList);
    }

    return eventList;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('displayTimestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  handleTimelineClick(item) {
    const { eventList, activeKey } = this.state;
    const { key } = item;
    const clear = key && key === activeKey;
    this.setState({ activeKey: clear ? null : key }, () => {
      this.eventList = this.filterData(eventList, this.state);
      this.forceUpdate();
      if (this.props.onToggleCollapse) this.props.onToggleCollapse();
    });
  }

  @autobind
  patternRenderer(rowData) {
    const { intl, credentials, environmentId, systemId, globalInfo } = this.props;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const ownerUserName = get(systemInfo, 'ownerUserName');

    const {
      type,
      category,
      patternName,
      patternId,
      anomalyLogInstance,
      sourceInstanceName,
      owner,
      projectOwner,
      startTimestamp,
      eventTimestamp,
    } = rowData;
    let { projectName } = rowData;
    projectName =
      (owner || projectOwner) !== credentials.userName ? `${projectName}@${owner || projectOwner}` : projectName;
    const isLog = category ? category === 'log' : type !== 'Metric';
    const key = `${projectName}-${isLog ? anomalyLogInstance : null}`;

    const RendererPatternTitle = ({ rowData }) => {
      const { patternNameMap } = this.state;

      const [patternName, setPatternName] = useState(get(patternNameMap, [key, patternId]));
      const [loading, setLoading] = useState(false);
      useEffect(() => {
        if (patternName === undefined) {
          setLoading(true);
          this.props.updateLastActionInfo();
          fetchGet(getEndpoint('getpatternname', 1), {
            ...credentials,
            customerName: ownerUserName,
            projectName,
            instanceName: isLog ? anomalyLogInstance || sourceInstanceName : null,
            patternId,
            startTime: startTimestamp || eventTimestamp,
          })
            .then((data) => {
              const { patternName } = data;
              if (!patternNameMap[key]) patternNameMap[key] = {};
              patternNameMap[key][patternId] = patternName || '';

              this.setState({ patternNameMap });
              setLoading(false);
              setPatternName(patternName || '');
              this.forceUpdate();
            })
            .catch((err) => {
              message.error(intl.formatMessage(appMessages.apiFaild));
              console.log(err);
            });
        }
      }, []);
      const { patternNameStr } = Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true });
      return (
        <div>
          {loading && <Spin size="small" />}
          {!loading && <div>{patternNameStr}</div>}
        </div>
      );
    };

    return (
      <div className="max-width flex-row flex-center-align">
        <Popover content={<RendererPatternTitle rowData={rowData} />} mouseEnterDelay={0.3} placement="top">
          <div className="hidden-line-with-ellipsis">
            {
              Defaults.PatternIdNameStr(
                { patternName: get(this.state.patternNameMap, [key, patternId]) || patternName, patternId },
                { hasFullName: true },
              ).patternNameStr
            }
          </div>
        </Popover>
      </div>
    );
  }

  @autobind
  renderIncidentDetails(rowData, summarySettings) {
    const { intl, credentials, projects, currentTheme } = this.props;
    const { category, type, projectOwner } = rowData;
    let { projectName } = rowData;
    if (projectOwner !== credentials.userName) {
      projectName = `${projectName}@${projectOwner}`;
    }
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);

    const LineChartButton = () => (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={intl.formatMessage(DashboardMessages.lineChart)}
      >
        <Button
          size="small"
          icon={<LineChartOutlined />}
          style={{ height: 22, marginRight: 8 }}
          onClick={() => this.handleIncidentChartClick(rowData)}
        />
      </Popover>
    );
    const PatternsJumpButton = () => (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={
          isAlert || isIncident
            ? intl.formatMessage(appMenusMessages.alertAnalysis)
            : intl.formatMessage(appMenusMessages.logAnalysis)
        }
      >
        <Button
          size="small"
          style={{ height: 22, marginRight: 8 }}
          onClick={() => this.handleIncidentJumpClick(rowData)}
        >
          <UnorderedListOutlined />
        </Button>
      </Popover>
    );

    return (
      <div className="full-width flex-col" style={{ padding: '4px 0' }}>
        <div className="flex-row flex-center-align">
          <div>{CellRenderers.logTypeRenderer({ intl, rowData: { type } })}</div>
          <div className="flex-grow" />
          {category !== 'metric' && <PatternsJumpButton />}
          {category === 'metric' && <LineChartButton />}
        </div>
        <EventRenderers.RenderShortDescription
          intl={intl}
          event={rowData}
          shouldExpandLimit={80}
          onToggleCollapse={this.props.onToggleCollapse}
          summarySettings={summarySettings}
          currentTheme={currentTheme}
        />
      </div>
    );
  }

  @autobind
  renderRootCauseDetails(rowData, summarySettings) {
    const { intl, credentials, projects, currentTheme } = this.props;
    const { sourceDetail, owner } = rowData;
    const { isMetric, eventType, content } = sourceDetail;
    let { projectName } = rowData;
    projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(content);
    } catch (error) {
      // console.debug(error);
    }

    const LineChartButton = () => (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={intl.formatMessage(DashboardMessages.lineChart)}
      >
        <Button
          size="small"
          icon={<LineChartOutlined />}
          style={{ height: 22, marginRight: 8 }}
          onClick={() => this.handleRootCauseChartClick(rowData)}
        />
      </Popover>
    );
    const PatternsJumpButton = () => (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={
          isAlert || isIncident
            ? intl.formatMessage(appMenusMessages.alertAnalysis)
            : intl.formatMessage(appMenusMessages.logAnalysis)
        }
      >
        <Button size="small" style={{ height: 22, marginRight: 8 }} onClick={() => this.handleJumpClick(rowData)}>
          <UnorderedListOutlined />
        </Button>
      </Popover>
    );
    const TrendPatternsButton = () => (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={intl.formatMessage(eventMessages.patternTrend)}
      >
        <Button
          size="small"
          style={{ height: 22, marginRight: 8 }}
          onClick={() => this.handleTrendPatternsClick(rowData)}
        >
          <TrendIcon />
        </Button>
      </Popover>
    );
    const LogQueryButton = () => (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={intl.formatMessage(appMenusMessages.logQuery)}
      >
        <Button
          type="primary"
          size="small"
          style={{ height: 22, marginRight: 8 }}
          onClick={() =>
            this.setState({
              showInsightQueryBox: true,
              rootCauseEvent: rowData,
            })
          }
        >
          <QeuryIcon />
        </Button>
      </Popover>
    );

    return (
      <div className="full-width flex-col" style={{ padding: '4px 0' }}>
        <div className="flex-row flex-center-align">
          <div>{CellRenderers.logTypeRenderer({ intl, rowData: { type: eventType } })}</div>
          <div className="flex-grow" />
          {!isMetric && <PatternsJumpButton />}
          {!isMetric && <TrendPatternsButton />}
          {!isMetric && <LogQueryButton />}
          {isMetric && <LineChartButton />}
        </div>

        <div className="flex-row">
          {!isMetric && (
            <LogRenderers.RenderLogContent
              intl={intl}
              rawData={content}
              rawDataJson={rawDataJson}
              onToggleCollapse={this.props.onToggleCollapse}
              owner={rowData}
              summarySettings={summarySettings}
              currentTheme={currentTheme}
            />
          )}
          {isMetric && (
            <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
              {EventRenderers.RenderMetricAnomalySummary({ intl, event: sourceDetail })}
            </div>
          )}
        </div>
      </div>
    );
  }

  @autobind
  handleIncidentChartClick(rowData) {
    const { credentials, environmentId } = this.props;
    const { projectOwner, startTimestamp, endTimestamp } = rowData;
    let { projectName } = rowData;
    if (projectOwner !== credentials.userName) {
      projectName = `${projectName}@${projectOwner}`;
    }
    const startTs = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTs = moment.utc(endTimestamp).endOf('day').valueOf();
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    let metricList = [];
    let instanceList = [];
    R.forEach((item) => {
      metricList.push(item.rootCauseMetric || item.metricName);
      instanceList.push(item.instanceId || item.instanceName);
    }, get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []));
    metricList = R.filter((item) => item, R.uniq(metricList));
    instanceList = R.filter((item) => item, R.uniq(instanceList));

    const query = {
      projectName,
      instanceGroup,
      modelType,
      startTimestamp: startTs,
      endTimestamp: endTs,
      justSelectMetric: R.join(',', metricList),
      justInstanceList: R.join(',', instanceList),
      withBaseline: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleIncidentJumpClick(rowData) {
    const { credentials, projects } = this.props;
    const {
      projectOwner,
      startTimestamp,
      endTimestamp,
      anomalyLogInstance,
      instanceName,
      patternId,
      type: eventType,
    } = rowData;
    let { projectName } = rowData;
    if (projectOwner !== credentials.userName) {
      projectName = `${projectName}@${projectOwner}`;
    }
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const startTime = moment.utc(startTimestamp).format(Defaults.DateFormat);
    const endTime = moment.utc(endTimestamp).format(Defaults.DateFormat);

    const query = {
      projectName,
      instanceName: anomalyLogInstance || instanceName,
      startTime,
      endTime,
      activeTab: eventType.toLowerCase() === 'rare' ? 'important' : 'clusters',
      activePatternId: patternId,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project?.owner || projectOwner,
      anomalyType: eventType.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  handleRootCauseChartClick(rowData) {
    const { credentials, incident } = this.props;
    const { sourceDetail, instanceName, owner, displayTimestamp } = rowData;
    const { startTimestamp: incidentStart, endTimestamp: incidentEnd, patternId, isIncident, isDeployment } = incident;
    const { content } = sourceDetail;
    let { projectName } = rowData;
    projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
    const startTimestamp = moment.utc(displayTimestamp).subtract(1, 'days').startOf('day').valueOf();
    const endTimestamp = moment.utc(displayTimestamp).endOf('day').valueOf();

    const incidentInfo = {
      startTimestamp: incidentStart,
      endTimestamp: incidentEnd,
      patternId,
      type: isIncident ? 'incident' : isDeployment ? 'deployment' : 'normal',
    };
    const metricAnomalyMap = { [content]: [instanceName] };

    const query = {
      projectName,
      instanceGroup: 'All',
      startTimestamp,
      endTimestamp,
      justSelectMetric: R.join(',', [content]),
      justInstanceList: R.join(',', [instanceName]),
      // withBaseline: true,
      incidentInfo: JSON.stringify(incidentInfo),
      metricAnomalyMap: JSON.stringify(metricAnomalyMap),
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleJumpClick(rowData) {
    const { credentials, environmentId, projects } = this.props;

    const { sourceDetail, instanceName, owner, patternId, displayTimestamp } = rowData;
    const { isMetric, eventType } = sourceDetail;
    let { projectName } = rowData;
    projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const startTime = moment.utc(displayTimestamp).format(Defaults.DateFormat);
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    if (isMetric) {
      const query = {
        projectName,
        instanceGroup,
        startTime,
        endTime: startTime,

        patternSearch: patternId,
      };
      window.open(buildUrl(BaseUrls.MetricEvents, {}, query), '_blank');
    } else {
      const query = {
        projectName,
        instanceName,
        startTime,
        endTime: startTime,
        activeTab: eventType.toLowerCase() === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: project?.owner || owner,
        anomalyType: eventType.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleTrendPatternsClick(rowData) {
    const { credentials } = this.props;

    const { instanceName, owner, patternId, displayTimestamp } = rowData;
    let { projectName } = rowData;
    projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;

    const startTimeObj = moment.utc(displayTimestamp).subtract(7, 'days').startOf('day');
    const endTimeObj = moment.utc(displayTimestamp).endOf('day');

    const query = {
      t: '953de6a33d8a4b96ac9c100bf69ba3fc',
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      pattern: patternId,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
  }

  @autobind
  onConfirmInsightQueryProjectSelect(params) {
    const {
      templateId,
      projectName,
      instanceName,
      startTimeObj,
      endTimeObj,
      keyword,
      numOfCluster,
      pattern,
      duration,
    } = params;
    const query = {
      t: templateId,
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      keyword,
      numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
      pattern,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      duration,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    this.setState({ showInsightQueryBox: false });
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          this.eventList = this.sortData(this.eventList, name, sortDir);

          // this.cellMeasureCache.clearAll();
          // if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const { intl, credentials, projectDisplayMap, location, globalInfo } = this.props;

    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    const { timelines, activeKey, showInsightQueryBox, rootCauseEvent } = this.state;

    const logProjects = [];
    if (rootCauseEvent) {
      const { projectName, owner } = rootCauseEvent;
      const projectNameReal = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
      const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
      logProjects.push({
        projectNameReal,
        projectDisplayName,
      });
    }

    const RenderRootCause = ({ rowData, index, intl, credentials, projectDisplayMap }) => {
      const { isRoot, owner, projectOwner, metricInstanceName, instanceName, projectName, category } = rowData;
      const projectNameReal =
        (owner || projectOwner) !== credentials.userName ? `${projectName}@${owner || projectOwner}` : projectName;
      const [summarySettings, setSummarySettings] = useState([]);

      useEffect(() => {
        const settings = this.summarySettingsMap[projectNameReal];
        if (!settings && !R.includes(category, ['metric'])) {
          fetchGet(getEndpoint('logsummarysettings'), {
            ...credentials,
            projectName: projectNameReal,
          })
            .then((x) => {
              this.summarySettingsMap[projectNameReal] = x;
              setSummarySettings(x);
            })
            .catch((x) => {
              setSummarySettings([]);
            });
        } else {
          setSummarySettings(settings);
        }
      });

      const {
        instanceDisplayName,
        instanceName: iName,
        containerName,
        instanceStr,
      } = getInstanceDisplayName(instanceDisplayNameMap, metricInstanceName || instanceName, {
        pn: projectName,
        owner: projectOwner,
      });

      const width = instanceDisplayName ? 140 : 120;

      return (
        <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
          <div className="row-column" style={{ width: 150 }}>
            {this.patternRenderer(rowData)}
          </div>
          <div className="row-column" style={{ width: 150 }}>
            <Popover
              content={
                <div>
                  <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                    <div className="bold" style={{ width }}>
                      {intl.formatMessage(appFieldsMessages.project)}:
                    </div>
                    {get(projectDisplayMap, projectNameReal, projectNameReal)}
                  </div>
                  {containerName && (
                    <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                      <div className="bold" style={{ width }}>
                        {intl.formatMessage(appFieldsMessages.container)}:
                      </div>
                      {containerName}
                    </div>
                  )}
                  <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                    <div className="bold" style={{ width }}>
                      {intl.formatMessage(appFieldsMessages.instance)}:
                    </div>
                    {iName}
                  </div>
                  {instanceDisplayName && (
                    <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                      <div className="bold" style={{ width }}>
                        {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                      </div>
                      {instanceDisplayName}
                    </div>
                  )}
                </div>
              }
              mouseEnterDelay={0.3}
              placement="top"
            >
              <div className="hidden-line-with-ellipsis inline-block max-width">{instanceStr}</div>
            </Popover>
          </div>
          <div className="row-column" style={{ width: 100, flex: 1 }}>
            {isRoot && this.renderIncidentDetails(rowData, summarySettings)}
            {!isRoot && this.renderRootCauseDetails(rowData, summarySettings)}
          </div>
        </div>
      );
    };

    const eventList = this.eventList || [];
    return (
      <div className="flex-row" style={{ marginBottom: 16 }}>
        <Timeline style={{ width: 184, minWidth: 184, margin: 8 }}>
          {R.map((item) => {
            const { key, isRoot, timestamp, patternIds, event, timePairs } = item;
            const isActive = activeKey === key;

            if (isRoot) {
              const { patternId } = event || {};
              return (
                <Timeline.Item key={key}>
                  <div
                    className="flex-col"
                    style={{ paddingLeft: 8, cursor: 'pointer', color: isActive ? '#ff5142' : 'currentColor' }}
                    onClick={() => this.handleTimelineClick(item)}
                  >
                    <div className="flex-row flex-center-align font-14 bold">
                      <span>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</span>
                      {isActive && <CheckOutlined style={{ marginLeft: 6 }} />}
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>
                      <span className="light-label" style={{ marginRight: 8 }}>
                        {intl.formatMessage(appFieldsMessages.pattern)}:
                      </span>
                      <span>{patternId}</span>
                    </div>
                  </div>
                </Timeline.Item>
              );
            }
            return (
              <Timeline.Item key={key} color="green" dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                <Popover
                  placement="left"
                  title={`Historical causal relations: ${timePairs.length}`}
                  mouseEnterDelay={0.3}
                  content={
                    <div className="flex-col">
                      {(timePairs || []).length === 0 && (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: 0 }} />
                      )}
                      {(timePairs || []).length > 0 && (
                        <GlobalRenderers.TimePairs intl={intl} width={360} height={250} timePairs={timePairs || []} />
                      )}
                    </div>
                  }
                >
                  <div
                    className="flex-col"
                    style={{ paddingLeft: 8, cursor: 'pointer', color: isActive ? '#ff5142' : 'currentColor' }}
                    onClick={() => this.handleTimelineClick(item)}
                  >
                    <div className="flex-row flex-center-align font-14 bold">
                      <span>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</span>
                      {isActive && <CheckOutlined style={{ marginLeft: 6 }} />}
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>
                      <span className="light-label" style={{ marginRight: 8 }}>
                        {intl.formatMessage(appFieldsMessages.pattern)}:
                      </span>
                      <span>{R.join(', ', patternIds || [])}</span>
                    </div>
                  </div>
                </Popover>
              </Timeline.Item>
            );
          }, timelines)}
        </Timeline>

        <div className="flex-grow flex-min-width event-list">
          <div className="event-list-header" style={{ height: 40, width: '100%' }}>
            <div className="header-column" style={{ width: 150 }}>
              {intl.formatMessage(appFieldsMessages.patternIdName)}
            </div>
            <div className="header-column" style={{ width: 150 }}>
              {intl.formatMessage(logMessages.instance)}
            </div>
            <div className="header-column" style={{ width: 100, flex: 1 }}>
              {intl.formatMessage(eventMessages.fieldDetails)}
            </div>
          </div>
          <div className="event-list-grid">
            {R.addIndex(R.map)(
              (rowData, index) => (
                <RenderRootCause
                  key={index}
                  intl={intl}
                  rowData={rowData}
                  index={index}
                  credentials={credentials}
                  projectDisplayMap={projectDisplayMap}
                />
              ),
              eventList,
            )}
          </div>
        </div>

        {showInsightQueryBox && (
          <InsightQueryBoxModal
            projects={logProjects}
            onConfirm={this.onConfirmInsightQueryProjectSelect}
            onClose={() => this.setState({ showInsightQueryBox: false })}
          />
        )}
      </div>
    );
  }
}

const RootCauseTimelineList = injectIntl(RootCauseTimelineListCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { currentLocale, projects, projectDisplayMap, globalInfo, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      location,
      currentLocale,
      projects,
      projectDisplayMap,
      globalInfo,
      credentials,
      userInfo,
      currentTheme,
    };
  },
  {
    push,
    replace,
    updateLastActionInfo,
  },
)(RootCauseTimelineList);
