import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { debounce, get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Alert, Button, Checkbox, Divider, Radio, Select, Spin, TreeSelect, message } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import fetchPostForm from '../../../../common/apis/fetchPostForm';
import getEndpoint from '../../../../common/apis/getEndpoint';
import {
  AutoSizer,
  Column,
  Container,
  DatePicker,
  Modal,
  Popover,
  SortDirection,
  Table,
} from '../../../../lib/fui/react';
import { BackgroundCall, Options } from '../../../../common/utils';
import { State } from '../../../../common/types';
import { updateLastActionInfo, createSetAction } from '../../../../common/app/actions';
import { Defaults } from '../../../../common/app';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { eventMessages } from '../../../../common/metric/messages';
import getInstanceDisplayName from '../../../../common/utils/getInstanceDisplayName';

const periodOptions = [
  { label: 'Range', value: 'range' },
  { label: 'Every day', value: 'day' },
  { label: 'Every Week', value: 'week' },
  { label: 'Every Month', value: 'month' },
];

const localDayOptions = [
  { label: 'First', value: 'first' },
  { label: 'Last', value: 'last' },
];

const DebounceTreeSelect = ({ debounceTimeout = 800, fetchOptions, compoenntRef, ...props }: Object) => {
  return (
    <TreeSelect
      ref={compoenntRef}
      onSearch={useMemo(() => {
        const loadOptions = (value) => {
          fetchOptions(value);
        };
        return debounce(loadOptions, debounceTimeout);
      }, [fetchOptions, debounceTimeout])}
      {...props}
    />
  );
};

const SystemMaintenanceDayModal = (props: Object) => {
  const { intl, credentials, onClose, systemsMap, systemId, userInfo, projectInstanceMap } = props || {};
  const { changeProjectInstanceMap, projectInstanceComponentMap, createSetAction } = props || {};
  const { projectInstaceDisplayNameMap, activeEvent } = props || {};
  const { handleMaintenanceDayRemove, handleMaintenanceDayRemoveProject } = props || {};
  const [dateKeyStartTime, dateKeyEndTime] = (activeEvent?.dateKey || '').split(',');

  const componentInsMap = useRef({});
  const componentAndInsMap = useRef({});
  const valueMap = useRef({});
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isSubmitting: false,
    isLoading: false,

    period: activeEvent ? activeEvent?.key || 'range' : 'range',
    dayInPeriod: activeEvent?.dateKey || '',
    startHour: R.isNil(activeEvent?.startTime?.hour) ? null : String(activeEvent?.startTime?.hour),
    startMinute: R.isNil(activeEvent?.startTime?.minute) ? null : String(activeEvent?.startTime?.minute),
    endHour: R.isNil(activeEvent?.endTime?.hour) ? null : String(activeEvent?.endTime?.hour),
    endMinute: R.isNil(activeEvent?.endTime?.minute) ? null : String(activeEvent?.endTime?.minute),

    startTime: activeEvent
      ? Number(dateKeyStartTime) || moment.utc().startOf('day').valueOf()
      : moment.utc().startOf('day').valueOf(),
    endTime: activeEvent
      ? Number(dateKeyEndTime) || moment.utc().startOf('day').valueOf()
      : moment.utc().startOf('day').valueOf(),

    projectValue: activeEvent?.projectValue || undefined,
    levelType: activeEvent ? (activeEvent?.isSystemLevel ? 'system' : 'project') : 'system',

    treeData: [],
    instanceComponentList: activeEvent
      ? R.map(
          (item) => `${item.componentName ? `${item.componentName}-` : ''}${item.instanceName}`,
          activeEvent?.instances || [],
        )
      : [],
    fetchingInstanceValue: '',
    fetchingTreeData: [],
    instanceDropdownOpen: false,
    instanceOnBlurDisable: false,
  });
  const { isSubmitting, isLoading, period, dayInPeriod, startHour, startMinute, endHour, endMinute } = state;
  const { startTime, endTime, projectValue, treeData, instanceComponentList, fetchingInstanceValue } = state;
  const { fetchingTreeData, instanceDropdownOpen, instanceOnBlurDisable, levelType } = state;
  const systemInfo = systemsMap[systemId];
  const isProjectLevel = levelType === 'project';

  const reloadData = (cancel) => {
    if (!projectValue) return;
    const { projectDetailsList } = systemInfo || {};
    const projectInfo = R.find((p) => p.projectKey === projectValue, projectDetailsList || []);
    if (!projectInfo) return;

    setState({ isLoading: true });

    const projectInstance = projectInstanceMap[projectInfo.projectKey];
    if (projectInstance) {
      componentInsMap.current = projectInstance.componentInsMap;
      componentAndInsMap.current = projectInstance.componentAndInsMap;
      valueMap.current = projectInstance.valueMap;
      setState({ isLoading: false, treeData: projectInstance.treeData });
      return;
    }

    const { projectName: projectShortName, customerName, projectKey } = projectInfo;
    const projectName = userInfo.userName === customerName ? projectShortName : `${projectShortName}@${customerName}`;

    updateLastActionInfo();
    const request = [
      fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
        ...credentials,
        projectList: JSON.stringify([{ projectName: projectShortName, customerName }]),
        startTime: moment.utc().startOf('day').subtract(1, 'days').valueOf(),
        endTime: moment.utc().endOf('day').valueOf(),
        includeInstance: true,
      }),
    ];
    if (!projectInstaceDisplayNameMap[projectKey]) {
      request.push(
        fetchGet(getEndpoint('instance-display-name'), {
          ...credentials,
          instanceDisplayNameRequestList: JSON.stringify([{ projectName: projectShortName, customerName }]),
        }),
      );
    }
    Promise.all(request)
      .then(async (res) => {
        if (cancel) return;
        const [data, d1] = res || [];

        let instanceDisplayNameMap = {};
        if (!projectInstaceDisplayNameMap[projectKey]) {
          R.forEach((item) => {
            const [pInfo, iList] = item || [];
            const { projectName, customerName } = pInfo || {};
            R.forEach((instanceInfo) => {
              const { instanceSet, instanceDisplayName } = instanceInfo || {};
              R.forEach((instance) => {
                instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
                instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
              }, instanceSet || []);
            }, iList || []);
          }, d1 || []);
        } else {
          instanceDisplayNameMap = projectInstaceDisplayNameMap[projectKey];
        }

        const projectMetaData = get(data, 'data', [])[0];
        const instanceStructureSet = get(projectMetaData, ['instanceStructureSet'], []);
        let instanceNameList = [];
        R.forEach((inc) => {
          const { i, c } = inc || {};
          if (i) {
            instanceNameList = [...instanceNameList, i];
            R.forEach((item) => {
              instanceNameList = [...instanceNameList, `${item}_${i}`];
            }, c || []);
          }
        }, instanceStructureSet || []);

        const prevInstanceComponentMap = get(projectInstanceComponentMap, projectName, {});
        const { hasError, allInstanceComponentMap } = await BackgroundCall.GetSetComponent({
          updateLastActionInfo,
          createSetAction,
          credentials,
          prevInstanceComponentMap,
          projectName,
          instanceNameList,
        });

        // build appNameList
        if (hasError) {
          throw new Error('Get components faild.');
        }
        const appNameList = !R.isEmpty(allInstanceComponentMap)
          ? { ...prevInstanceComponentMap, ...allInstanceComponentMap }
          : prevInstanceComponentMap;

        R.forEach((inst) => {
          if (!appNameList?.[inst]) {
            appNameList[inst] = inst;
          }
        }, instanceNameList);

        // build tree list
        const componentInstanceMap = {};
        const componentAndInstanceMap = {};
        R.forEachObjIndexed((cmp, ins) => {
          if (!R.has(cmp, componentInstanceMap)) componentInstanceMap[cmp] = [];
          componentInstanceMap[cmp].push(ins);
          if (!R.has(cmp, componentAndInstanceMap)) componentAndInstanceMap[cmp] = [];
          componentAndInstanceMap[cmp].push(`${cmp}-${ins}`);
        }, appNameList || {});

        const valueMapData = {};
        let treeData = [];
        R.forEachObjIndexed((insList, cmp) => {
          const children = R.map((ins) => {
            const key = `${cmp}-${ins}`;
            valueMapData[key] = { isInstance: true, value: ins };
            const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, ins, {
              pn: projectShortName,
              owner: customerName,
            });
            return {
              title: instanceStr,
              value: key,
              key,
            };
          }, insList);
          const key = cmp;
          valueMapData[key] = { isComponent: true, value: cmp };
          treeData.push({
            title: cmp,
            value: key,
            key,
            children,
          });
        }, componentInstanceMap);
        treeData = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('title')))], treeData);

        changeProjectInstanceMap(
          {
            [projectInfo.projectKey]: {
              componentInsMap: componentInstanceMap,
              componentAndInsMap: componentAndInstanceMap,
              valueMap: valueMapData,
              treeData,
            },
          },
          { [projectInfo.projectKey]: instanceDisplayNameMap },
        );

        componentInsMap.current = componentInstanceMap;
        componentAndInsMap.current = componentAndInstanceMap;
        valueMap.current = valueMapData;

        setState({ isLoading: false, treeData });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        setState({ isLoading: false });
      });
  };

  useEffect(() => {
    let cancel = false;
    reloadData(cancel);
    return () => {
      cancel = true;
    };
  }, [projectValue]);

  const handleSumbit = async () => {
    if (period) {
      setState({ isSubmitting: true });

      if (activeEvent) {
        let success = true;
        if (!projectValue) {
          success = await handleMaintenanceDayRemove([activeEvent], true);
        } else {
          success = await handleMaintenanceDayRemoveProject([activeEvent], true);
        }
        if (!success) {
          message.error(intl.formatMessage(appMessages.apiFaild));
          setState({ isSubmitting: false });
          return;
        }
      }

      let dateKey = '';
      let params = {};
      if (['day', 'week', 'month'].includes(period)) {
        dateKey = dayInPeriod;
        if (startHour && startMinute && endHour && endMinute) {
          const startTimeObj = { hour: startHour, minute: startMinute };
          const endTimeObj = { hour: endHour, minute: endMinute };
          params = { ...params, startTimeObj: JSON.stringify(startTimeObj), endTimeObj: JSON.stringify(endTimeObj) };
        }
      } else if (period === 'range' && startTime && endTime) {
        dateKey = `${startTime},${endTime}`;
      }

      params = {
        ...credentials,
        ...params,
        key: period,
        dateKey,
      };

      if (projectValue) {
        const { projectDetailsList } = systemInfo || {};
        const projectInfo = R.find((p) => p.projectKey === projectValue, projectDetailsList || []);
        const { projectName: projectShortName, customerName } = projectInfo || {};
        const projectName =
          userInfo.userName === customerName ? projectShortName : `${projectShortName}@${customerName}`;

        let instanceNameList = [];
        R.forEach((item) => {
          const data = valueMap.current[item];
          if (data) {
            const { value, isInstance } = data;
            if (isInstance) {
              instanceNameList.push(value);
            } else {
              const insList = componentInsMap.current[value] || [];
              instanceNameList = [...instanceNameList, ...insList];
            }
          }
        }, instanceComponentList);
        params = {
          ...params,
          projectName,
          ...(instanceNameList && instanceNameList.length > 0
            ? { instanceNameList: JSON.stringify(instanceNameList || []) }
            : {}),
        };
      } else {
        params = { ...params, systemName: systemInfo?.systemId, customerName: systemInfo?.owner };
      }

      updateLastActionInfo();
      fetchPost(getEndpoint('specialday'), { ...params, operation: 'add' }, {}, false)
        .then((d) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          setState({ isSubmitting: false });
          onClose(true);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          setState({ isSubmitting: false });
        });
    }
  };

  const handleTimeChange = ({ time }) => {
    return (dateObj) => {
      if (dateObj) {
        const selectTime = dateObj.valueOf();
        setState({ [time]: selectTime });
      }
    };
  };

  const changeProject = (projectValue) => {
    setState({ projectValue, instanceComponentList: [], treeData: [] });
  };

  const levelTypeChange = (value) => {
    setState({ levelType: value, projectValue: undefined, instanceComponentList: [], treeData: [] });
  };

  const handleInstanceSearch = (fetchingInstanceValue) => {
    const fetchingTreeData = [];
    if (fetchingInstanceValue) {
      R.forEach((item) => {
        if (item.title.toLowerCase().indexOf(fetchingInstanceValue.toLowerCase()) >= 0) {
          // filter component
          fetchingTreeData.push(item);
        } else {
          // filter instance
          let children = item.children || [];
          children = R.filter((c) => c.title.toLowerCase().indexOf(fetchingInstanceValue.toLowerCase()) >= 0, children);
          if (children.length > 0) {
            fetchingTreeData.push({
              ...item,
              children,
            });
          }
        }
      }, treeData);
    }

    setState({ fetchingInstanceValue, fetchingTreeData });
  };

  const changeInstace = (instanceComponentList, displayTreeData) => {
    let instanceNameList = [];
    R.forEach((item) => {
      const data = valueMap.current[item];
      if (data) {
        const { value, isInstance } = data;
        if (isInstance) {
          instanceNameList.push(item);
        } else {
          const findInfo = R.find((item) => item.value === value, displayTreeData || []);
          instanceNameList = [...instanceNameList, ...R.map((item) => item.value, findInfo.children || [])];
        }
      }
    }, instanceComponentList);
    setState({ instanceComponentList: instanceNameList });
  };

  const changeDropdown = (open) => {
    if (!instanceOnBlurDisable) {
      const newState = { instanceDropdownOpen: open };
      if (!open) {
        newState.fetchingInstanceValue = '';
        newState.fetchingTreeData = [];
      }
      setState(newState);
    }
  };

  let dayOptions = [];
  if (period === 'week') {
    dayOptions = Options.WeekOptions;
  } else if (period === 'month') {
    dayOptions = R.concat(
      localDayOptions,
      R.map((d) => ({ label: d.toString(), value: d.toString() }), R.range(1, 31)),
    );
  }

  const startTimeObj = moment.utc(startTime);
  const endTimeObj = moment.utc(endTime);

  const projectError = isProjectLevel && !projectValue;
  const instanceError = projectValue && instanceComponentList.length === 0;
  let hasError = false;
  if (period === 'range') {
    hasError = !startTime || !endTime || instanceError || projectError;
  } else if (period === 'day') {
    hasError = !startHour || !startMinute || !endHour || !endMinute || instanceError || projectError;
  } else {
    hasError = !dayInPeriod || !startHour || !startMinute || !endHour || !endMinute || instanceError || projectError;
  }

  const displayTreeData = fetchingInstanceValue ? fetchingTreeData : treeData;
  return (
    <Modal
      title={intl.formatMessage(appButtonsMessages.add)}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={handleSumbit}
      okButtonProps={{ disabled: hasError, loading: isSubmitting }}
    >
      <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
        <Container className="flex-col">
          <Alert
            description="All maintenance windows are described in the UTC timezone."
            type="info"
            showIcon
            style={{ marginBottom: 12 }}
          />

          <div className="flex-row flex-center-align" style={{ marginBottom: 18 }}>
            <Radio.Group disabled={activeEvent} onChange={(e) => levelTypeChange(e.target.value)} value={levelType}>
              <Radio value="system">System level</Radio>
              <Radio value="project">Project level</Radio>
            </Radio.Group>
          </div>
          {isProjectLevel && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
              <div style={{ width: 100, textAlign: 'left', flexShrink: 0 }}>
                {intl.formatMessage(appFieldsMessages.project)}
                <span style={{ color: 'red' }}>*</span>:
              </div>
              <Select
                allowClear
                showSearch
                disabled={activeEvent}
                size="small"
                style={{ width: '100%' }}
                value={projectValue}
                onChange={changeProject}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.projectKey} label={item.projectDisplayName}>
                      {item.projectDisplayName}
                    </Select.Option>
                  ),
                  R.sortWith(
                    [R.ascend(R.compose(R.toLower, R.prop('projectDisplayName')))],
                    systemInfo?.projectDetailsList || [],
                  ),
                )}
              </Select>
            </div>
          )}
          <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
            <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.mode)}:</div>
            <Select
              size="small"
              disabled={activeEvent}
              style={{ width: 200 }}
              value={period}
              onChange={(period) => setState({ period })}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value}>{item.label}</Select.Option>
                ),
                periodOptions || [],
              )}
            </Select>
            <div className="flex-grow" />
          </div>
          {['week', 'month'].includes(period) && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
              <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.on)}:</div>
              <Select
                size="small"
                style={{ width: 100 }}
                value={dayInPeriod}
                onChange={(dayInPeriod) => setState({ dayInPeriod })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ),
                  dayOptions || [],
                )}
              </Select>
              <div style={{ width: 30, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.day)}</div>
              <div className="flex-grow" />
            </div>
          )}
          {period !== 'range' && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
              <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.from)}:</div>
              <Select
                size="small"
                style={{ width: 100 }}
                value={startHour}
                onChange={(startHour) => setState({ startHour })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ),
                  Options.HourOptions || [],
                )}
              </Select>
              <div style={{ width: 50, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.hour)}</div>
              <Select
                size="small"
                style={{ width: 100 }}
                value={startMinute}
                onChange={(startMinute) => setState({ startMinute })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ),
                  Options.MinuteOptions || [],
                )}
              </Select>
              <div style={{ width: 50, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.minute)}</div>
            </div>
          )}
          {period !== 'range' && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
              <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.to)}:</div>
              <Select size="small" style={{ width: 100 }} value={endHour} onChange={(endHour) => setState({ endHour })}>
                {R.map(
                  (item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ),
                  Options.HourOptions || [],
                )}
              </Select>
              <div style={{ width: 50, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.hour)}</div>
              <Select
                size="small"
                style={{ width: 100 }}
                value={endMinute}
                onChange={(endMinute) => setState({ endMinute })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ),
                  Options.MinuteOptions || [],
                )}
              </Select>
              <div style={{ width: 50, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.minute)}</div>
            </div>
          )}

          {period === 'range' && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
              <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.from)}:</div>
              <div className="ui input datePicker">
                <DatePicker
                  utcOffset={0}
                  className="time"
                  style={{ width: 130 }}
                  displayDateFormat="yyyy-MM-dd HH:mm"
                  dateFormat={Defaults.TimeFormat}
                  todayButton={intl.formatMessage(appFieldsMessages.today)}
                  showYearDropdown
                  showMonthDropdown
                  showTimeSelect
                  selected={startTimeObj}
                  onChange={handleTimeChange({ time: 'startTime' })}
                />
              </div>
              <div style={{ width: 50, textAlign: 'left', marginLeft: 16 }}>
                {intl.formatMessage(appFieldsMessages.to)}:
              </div>
              <div className="ui input datePicker">
                <DatePicker
                  utcOffset={0}
                  className="time"
                  style={{ width: 130 }}
                  displayDateFormat="yyyy-MM-dd HH:mm"
                  dateFormat={Defaults.TimeFormat}
                  todayButton={intl.formatMessage(appFieldsMessages.today)}
                  showYearDropdown
                  showMonthDropdown
                  showTimeSelect
                  selected={endTimeObj}
                  onChange={handleTimeChange({ time: 'endTime' })}
                />
              </div>
            </div>
          )}

          {projectValue && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
              <div style={{ width: 100, textAlign: 'left', flexShrink: 0 }}>
                {intl.formatMessage(appFieldsMessages.instance)}
                <span style={{ color: 'red' }}>*</span>:
              </div>
              <DebounceTreeSelect
                key={projectValue}
                showSearch
                allowClear
                treeCheckable
                maxTagCount={10}
                treeDefaultExpandAll
                filterTreeNode={false}
                style={{ flex: 1, minWidth: 0 }}
                treeNodeFilterProp="title"
                treeData={displayTreeData}
                open={instanceDropdownOpen}
                autoClearSearchValue={false}
                value={instanceComponentList}
                dropdownMatchSelectWidth={450}
                fetchOptions={handleInstanceSearch}
                onDropdownVisibleChange={changeDropdown}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                onChange={(instanceComponentList) => changeInstace(instanceComponentList, displayTreeData)}
                dropdownRender={(menu) => {
                  let allInstances = [];
                  let selectAll = [];
                  let instanceNameList = [];
                  R.forEach((item) => {
                    const data = valueMap.current[item];
                    if (data) {
                      const { value, isInstance } = data;
                      if (isInstance) {
                        instanceNameList.push(item);
                      } else {
                        const insList = componentAndInsMap.current[value] || [];
                        instanceNameList = [...instanceNameList, ...insList];
                      }
                    }
                  }, instanceComponentList);
                  allInstances = R.unnest(
                    R.map((item) => R.map((_item) => _item.value, item.children || []), displayTreeData || []),
                  );
                  selectAll = R.difference(allInstances, instanceNameList).length === 0;

                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 24px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selectAll}
                          onChange={(e) => {
                            const { checked } = e.target;
                            const filterList = R.difference(instanceNameList, allInstances);
                            if (checked) {
                              setState({ instanceComponentList: [...filterList, ...allInstances] });
                            } else {
                              setState({ instanceComponentList: filterList });
                            }
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
              />
            </div>
          )}
        </Container>
      </Spin>
    </Modal>
  );
};

function SystemMaintenanceDayCore(props: Object) {
  const { updateLastActionInfo, intl, credentials, systemsMap, systemId, projectInstanceComponentMap } = props || {};
  const { createSetAction, userInfo } = props || {};
  const systemInfo = systemsMap[systemId];
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    showMaintenanceDayModal: false,
    maintenanceDays: [],

    sortBy: 'key',
    sortDirection: 'ASC',
    isRemoveCheckedAll: false,

    projectInstanceMap: {},
    projectInstaceDisplayNameMap: {},

    projectValue: undefined,

    activeEvent: null,
  });
  const { loading, showMaintenanceDayModal, maintenanceDays, sortBy, sortDirection, projectInstanceMap } = state;
  const { projectValue, projectInstaceDisplayNameMap, activeEvent } = state;

  const sortData = (eventList, sortBy, sortDirection) => {
    let sortList = eventList || [];
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        sortList = R.sortWith([R.descend(R.prop(sortBy))])(sortList);
      } else {
        sortList = R.sortWith([R.ascend(R.prop(sortBy))])(sortList);
      }
    }
    return sortList;
  };

  const reloadSystem = (cancel) => {
    if (!systemInfo) {
      setState({ loading: false, maintenanceDays: [] });
      return;
    }
    fetchGet(getEndpoint('specialday'), {
      ...credentials,
      systemName: systemInfo?.systemId,
      customerName: systemInfo?.owner,
    })
      .then((d) => {
        if (cancel) return;
        const { systemLevelSpecialList } = d || {};

        let maintenanceDays = [];
        try {
          maintenanceDays = JSON.parse(systemLevelSpecialList || '[]');
        } catch (e) {
          console.log(e);
        }

        const maintenanceDaysMap = {};
        R.forEach((item) => {
          const { key, dateKey, startTime, endTime, uuid } = item;
          const timeKey = `${startTime?.hour}_${startTime?.minute}_${endTime?.hour}_${endTime?.minute}`;
          const itemKey = `${key}_${dateKey}_${timeKey}`;
          if (maintenanceDaysMap[itemKey]) {
            maintenanceDaysMap[itemKey].uuids = [...maintenanceDaysMap[itemKey].uuids, uuid];
          } else {
            maintenanceDaysMap[itemKey] = { ...item, isSystemLevel: true, uuid: undefined, uuids: [uuid] };
          }
        }, maintenanceDays);
        maintenanceDays = R.values(maintenanceDaysMap);

        setState({
          loading: false,
          maintenanceDays: sortData(maintenanceDays, sortBy, sortDirection),
          isRemoveCheckedAll: false,
        });
      })
      .catch((err) => {
        setState({ loading: false });
        message.error(err.message || String(err));
      });
  };

  const reloadProject = (cancel) => {
    const { projectDetailsList } = systemInfo || {};
    const projectInfo = R.find((p) => p.projectKey === projectValue, projectDetailsList || []);
    if (!projectInfo) {
      setState({ loading: false, maintenanceDays: [] });
      return;
    }
    const { projectName: projectShortName, customerName, projectKey } = projectInfo;
    const projectName = userInfo.userName === customerName ? projectShortName : `${projectShortName}@${customerName}`;

    const request = [
      fetchGet(getEndpoint('specialday'), {
        ...credentials,
        projectName,
      }),
    ];
    if (!projectInstaceDisplayNameMap[projectKey]) {
      request.push(
        fetchGet(getEndpoint('instance-display-name'), {
          ...credentials,
          instanceDisplayNameRequestList: JSON.stringify([{ projectName: projectShortName, customerName }]),
        }),
      );
    }
    Promise.all(request)
      .then((res) => {
        if (cancel) return;
        const [d, d1] = res || [];
        const { specialList, instanceLevelSpecialList } = d || {};

        let maintenanceDays = [];
        const maintenanceDaysMap = {};
        try {
          maintenanceDays = JSON.parse(specialList || '[]');
          R.forEach((item) => {
            const { key, dateKey, startTime, endTime, uuid } = item;
            const timeKey = `${startTime?.hour}_${startTime?.minute}_${endTime?.hour}_${endTime?.minute}`;
            const itemKey = `${key}_${dateKey}_${timeKey}`;
            if (maintenanceDaysMap[itemKey]) {
              maintenanceDaysMap[itemKey].uuids = [...maintenanceDaysMap[itemKey].uuids, uuid];
            } else {
              maintenanceDaysMap[itemKey] = { ...item, isProjectLevel: true, uuid: undefined, uuids: [uuid] };
            }
          }, maintenanceDays);
        } catch (e) {
          console.log(e);
        }
        maintenanceDays = R.values(maintenanceDaysMap);

        let maintenanceDays2 = [];
        try {
          maintenanceDays2 = JSON.parse(instanceLevelSpecialList || '[]');
        } catch (e) {
          console.log(e);
        }
        const maintenanceDays2Map = {};
        let maintenanceDaysIns = [];
        R.forEach((item) => {
          const { componentName, instanceName, specialDayList } = item;
          R.forEach((item2) => {
            const { key, dateKey, startTime, endTime, uuid } = item2;
            const timeKey = `${startTime?.hour}_${startTime?.minute}_${endTime?.hour}_${endTime?.minute}`;
            const itemKey = `${key}_${dateKey}_${timeKey}`;

            if (maintenanceDays2Map[itemKey]) {
              maintenanceDays2Map[itemKey].instances = [
                ...maintenanceDays2Map[itemKey].instances,
                { instanceName, componentName, uuid },
              ];
            } else {
              maintenanceDays2Map[itemKey] = {
                ...item2,
                instances: [{ instanceName, componentName, uuid }],
              };
            }
          }, specialDayList || []);
        }, maintenanceDays2);
        maintenanceDaysIns = R.values(maintenanceDays2Map);

        let allMaintenanceDays = [...maintenanceDays, ...maintenanceDaysIns];
        allMaintenanceDays = sortData(allMaintenanceDays, sortBy, sortDirection);

        let newState = {};
        if (!projectInstaceDisplayNameMap[projectKey]) {
          const instanceDisplayNameMap = {};
          R.forEach((item) => {
            const [pInfo, iList] = item || [];
            const { projectName, customerName } = pInfo || {};
            R.forEach((instanceInfo) => {
              const { instanceSet, instanceDisplayName } = instanceInfo || {};
              R.forEach((instance) => {
                instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
                instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
              }, instanceSet || []);
            }, iList || []);
          }, d1 || []);
          newState = {
            projectInstaceDisplayNameMap: { ...projectInstaceDisplayNameMap, [projectKey]: instanceDisplayNameMap },
          };
        }

        setState({ loading: false, maintenanceDays: allMaintenanceDays, isRemoveCheckedAll: false, ...newState });
      })
      .catch((err) => {
        setState({ loading: false });
        message.error(err.message || String(err));
      });
  };

  const reloadData = (cancel) => {
    setState({ loading: true });
    updateLastActionInfo();

    if (projectValue) {
      reloadProject(cancel);
    } else {
      reloadSystem(cancel);
    }
  };

  useEffect(() => {
    let cancel = false;
    reloadData(cancel);
    return () => {
      cancel = true;
    };
  }, [systemId, projectValue]);

  useEffect(() => {
    return () => {
      setState({ projectInstanceMap: {}, projectValue: undefined });
    };
  }, [systemId]);

  const handleAddMaintenanceDayClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({ showMaintenanceDayModal: true });
  };

  const dateKeyRender = ({ cellData, rowData }) => {
    const { key, dateKey, startTime, endTime } = rowData;
    let content = cellData;
    if (key === 'week') {
      const option = R.find((sp) => sp.value === dateKey, Options.WeekOptions);
      content = option ? option.label : content;
    }
    if (key === 'range') {
      const [startTime, endTime] = dateKey.split(',');
      const startTimeStr = moment.utc(Number(startTime)).format(Defaults.TimeFormat);
      const endTimeStr = moment.utc(Number(endTime)).format(Defaults.TimeFormat);
      content = `From:${startTimeStr} To:${endTimeStr}`;
    } else if (startTime && endTime) {
      content = `${content ? `Day: ${content}, ` : ''}From Time ${startTime.hour}:${
        startTime.minute < 10 ? `0${startTime.minute}` : startTime.minute
      } To Time ${endTime.hour}:${endTime.minute < 10 ? `0${endTime.minute}` : endTime.minute}`;
    }
    return <div className="flex-row">{content}</div>;
  };

  const handleIsAllChecked = (fieldName) => {
    return ({ target: { checked } }) => {
      R.forEach((item) => {
        if (fieldName === 'isRemoveCheckedAll') {
          item.isChecked = checked;
        }
      }, maintenanceDays || []);
      setState({ [fieldName]: checked });
    };
  };

  const removeCheckAllHeaderRender = (fieldName) => {
    return () => {
      return (
        <>
          {fieldName === 'isRemoveCheckedAll' && (
            <Checkbox checked={state[fieldName]} onChange={handleIsAllChecked(fieldName)} />
          )}
        </>
      );
    };
  };

  const removeCheckBoxRender = ({ rowData, rowIndex }) => {
    const { isChecked } = rowData;

    return (
      <Checkbox
        checked={isChecked}
        onChange={(e) => {
          const newMaintenanceDays = R.addIndex(R.map)((item, idx) => {
            if (idx === rowIndex) {
              return { ...item, isChecked: e.target.checked };
            }
            return item;
          }, maintenanceDays);
          const isRemoveCheckedAll = !(
            R.find(R.propEq('isChecked', false), newMaintenanceDays) ||
            R.find(R.propEq('isChecked', undefined), newMaintenanceDays)
          );
          setState({ maintenanceDays: newMaintenanceDays, isRemoveCheckedAll });
        }}
      />
    );
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  useEffect(() => {
    setState({ maintenanceDays: sortData(maintenanceDays, sortBy, sortDirection) });
  }, [sortBy, sortDirection]);

  const handleMaintenanceDayRemove = (deleteMaintenanceList, editRemove) => {
    if (!editRemove) {
      setState({ loading: true });
    }

    const requests = [];
    let uuidList = [];
    R.forEach((item) => {
      const { uuids = [] } = item;
      uuidList = [...uuidList, ...uuids];
    }, deleteMaintenanceList || []);

    if (uuidList.length > 0) {
      const formData = new FormData();
      formData.append('operation', 'delete');
      if (uuidList.length > 0) {
        formData.append('uuidList', JSON.stringify(uuidList));
        formData.append('systemName', systemInfo?.systemId);
        formData.append('customerName', systemInfo?.owner);
      }
      requests.push(fetchPostForm(getEndpoint('specialday'), formData));
    }

    updateLastActionInfo();
    return Promise.all(requests)
      .then((results) => {
        if (editRemove) {
          return true;
        }
        message.success(intl.formatMessage(appMessages.apiSuccess));
        reloadData();
        return false;
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        return false;
      });
  };

  const handleMaintenanceDayRemoveProject = (deleteMaintenanceList, editRemove) => {
    const { projectDetailsList } = systemInfo || {};
    const projectInfo = R.find((p) => p.projectKey === projectValue, projectDetailsList || []);
    if (!projectInfo) {
      return false;
    }
    const { projectName: projectShortName, customerName } = projectInfo;
    const projectName = userInfo.userName === customerName ? projectShortName : `${projectShortName}@${customerName}`;

    if (!editRemove) {
      setState({ loading: true });
    }

    const requests = [];
    let uuidList = [];
    let instanceList = [];
    R.forEach((item) => {
      const { instances = [], uuids = [], uuid } = item;
      uuidList = [...uuidList, ...uuids, uuid];

      R.forEach((inst) => {
        const { uuid, instanceName } = inst;
        uuidList = [...uuidList, uuid];
        instanceList = [...instanceList, instanceName];
      }, instances);
    }, deleteMaintenanceList || []);
    uuidList = R.uniq(uuidList);
    instanceList = R.uniq(instanceList);

    if (uuidList.length > 0) {
      const formData = new FormData();
      formData.append('projectName', projectName);
      formData.append('operation', 'delete');
      if (uuidList.length > 0) {
        formData.append('uuidList', JSON.stringify(uuidList));
      }
      if (instanceList.length > 0) {
        formData.append('instanceNameList', JSON.stringify(instanceList));
      }
      requests.push(fetchPostForm(getEndpoint('specialday'), formData));
    }

    updateLastActionInfo();
    return Promise.all(requests)
      .then((results) => {
        if (editRemove) {
          return true;
        }
        message.success(intl.formatMessage(appMessages.apiSuccess));
        reloadData();
        return false;
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        return false;
      });
  };

  const changeProject = (projectValue) => {
    setState({ projectValue });
  };

  const renderMaintenanceInstance = ({ rowData, dataKey }) => {
    const { isProjectLevel, instances = [] } = rowData;
    const { projectDetailsList } = systemInfo || {};
    const projectInfo = R.find((p) => p.projectKey === projectValue, projectDetailsList || []);
    const { projectName: projectShortName, customerName, projectKey } = projectInfo || {};
    const instanceDisplayNameMap = projectInstaceDisplayNameMap[projectKey];

    if (isProjectLevel) {
      return dataKey === 'instanceNameList' ? 'All instances' : 'All components';
    }

    const content =
      dataKey === 'instanceNameList'
        ? R.join(
            ', ',
            R.map((i) => {
              const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, i, {
                pn: projectShortName,
                owner: customerName,
              });
              return instanceStr;
            }, R.uniq(R.map(R.prop('instanceName'), instances))),
          )
        : R.join(', ', R.uniq(R.map(R.prop('componentName'), instances)));

    return (
      <Popover
        title={null}
        content={
          <div style={{ maxWidth: 300, maxHeight: 150, overflowY: 'auto', wordBreak: 'break-all' }}>{content}</div>
        }
        placement="top"
        mouseEnterDelay={0.3}
      >
        <div className="hidden-line-with-ellipsis inline-block max-width">{content}</div>
      </Popover>
    );
  };

  const renderEdit = ({ rowData }) => {
    return (
      <Button
        size="small"
        onClick={() => {
          setState({ activeEvent: { ...rowData, projectValue }, showMaintenanceDayModal: true });
        }}
      >
        {intl.formatMessage(appButtonsMessages.edit)}
      </Button>
    );
  };

  const deleteMaintenanceList = R.filter((item) => item.isChecked, maintenanceDays);

  return (
    <>
      <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-height">
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <Button type="primary" size="small" onClick={handleAddMaintenanceDayClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          <div className="flex-row flex-center-align" style={{ marginLeft: 16 }}>
            <div style={{ width: 86, flexShrink: 0 }}>{intl.formatMessage(eventMessages.projectName)}:</div>
            <Select
              allowClear
              showSearch
              size="small"
              style={{ width: 200 }}
              value={projectValue}
              onChange={changeProject}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.projectKey} label={item.projectDisplayName}>
                    {item.projectDisplayName}
                  </Select.Option>
                ),
                R.sortWith(
                  [R.ascend(R.compose(R.toLower, R.prop('projectDisplayName')))],
                  systemInfo?.projectDetailsList || [],
                ),
              )}
            </Select>
          </div>
          <div className="flex-grow" />
          <Button
            type="primary"
            disabled={deleteMaintenanceList.length === 0}
            onClick={() =>
              projectValue
                ? handleMaintenanceDayRemoveProject(deleteMaintenanceList)
                : handleMaintenanceDayRemove(deleteMaintenanceList)
            }
          >
            {intl.formatMessage(appButtonsMessages.delete)}
          </Button>
        </div>
        <div style={{ marginBottom: 4 }}>
          {intl.formatMessage(
            projectValue
              ? settingsMessages.projectLevelMaintenanceWindows
              : settingsMessages.systemLevelMaintenanceWindows,
          )}
          :
        </div>
        <Container className="flex-grow flex-col">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={40}
                rowCount={maintenanceDays.length}
                rowGetter={({ index }) => maintenanceDays[index]}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
              >
                <Column
                  width={40}
                  label=""
                  dataKey="uuid"
                  disableSort
                  headerRenderer={removeCheckAllHeaderRender('isRemoveCheckedAll')}
                  cellRenderer={removeCheckBoxRender}
                />
                <Column
                  width={150}
                  label={intl.formatMessage(settingsMessages.timeWindowType)}
                  dataKey="key"
                  headerRenderer={headerRenderer}
                />
                <Column
                  width={280}
                  label={<span>{intl.formatMessage(settingsMessages.maintenanceDay)} (UTC)</span>}
                  dataKey="dateKey"
                  cellRenderer={dateKeyRender}
                  flexGrow={1}
                  headerRenderer={headerRenderer}
                />
                {projectValue && (
                  <Column
                    width={320}
                    disableSort
                    label={intl.formatMessage(appFieldsMessages.component)}
                    dataKey="componentNameList"
                    cellRenderer={renderMaintenanceInstance}
                  />
                )}
                {projectValue && (
                  <Column
                    width={320}
                    disableSort
                    label={intl.formatMessage(appFieldsMessages.instance)}
                    dataKey="instanceNameList"
                    cellRenderer={renderMaintenanceInstance}
                  />
                )}
                <Column width={80} disableSort label={null} dataKey="edit" cellRenderer={renderEdit} />
              </Table>
            )}
          </AutoSizer>
        </Container>
      </Spin>

      {showMaintenanceDayModal && (
        <SystemMaintenanceDayModal
          intl={intl}
          credentials={credentials}
          systemsMap={systemsMap}
          systemId={systemId}
          userInfo={userInfo}
          createSetAction={createSetAction}
          projectInstanceComponentMap={projectInstanceComponentMap}
          projectInstanceMap={projectInstanceMap}
          projectInstaceDisplayNameMap={projectInstaceDisplayNameMap}
          changeProjectInstanceMap={(projectInstacen, instanceDisplayNameMap) => {
            setState({
              projectInstanceMap: { ...projectInstanceMap, ...projectInstacen },
              projectInstaceDisplayNameMap: { ...projectInstaceDisplayNameMap, ...instanceDisplayNameMap },
            });
          }}
          onClose={(flag) => {
            setState({ showMaintenanceDayModal: false, activeEvent: null });
            if (flag) reloadData();
          }}
          activeEvent={activeEvent}
          handleMaintenanceDayRemove={handleMaintenanceDayRemove}
          handleMaintenanceDayRemoveProject={handleMaintenanceDayRemoveProject}
        />
      )}
    </>
  );
}

const SystemMaintenanceDay = injectIntl(SystemMaintenanceDayCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { systemsMap, projectInstanceComponentMap } = state.app;
    const { userInfo } = state.auth;

    return {
      credentials,
      systemsMap,
      userInfo,
      projectInstanceComponentMap,
    };
  },
  { updateLastActionInfo, createSetAction },
)(SystemMaintenanceDay);
