/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';

import { Defaults as DefaultsApp } from '../app';

const LogParser = {
  CalculateLogType: ({ eventType, typeList }) => {
    let types = [];
    if (eventType) {
      types = R.split('&', (eventType || '').toLowerCase());
    } else if (typeList && typeList.length > 0) {
      types = R.map((item) => R.toLower(item), typeList);
    }
    const eventTypeList = R.filter((item) => Boolean(item), types);

    // Convert types to type and colors
    let typeAndColor = [];
    if (eventTypeList.length === 0) {
      typeAndColor = [
        {
          type: 'normal',
          shortType: 'N',
          color: 'gray',
        },
      ];
    } else {
      R.forEach((perType) => {
        let color = 'orange';
        let shortType = R.toUpper(perType[0]);
        switch (perType) {
          case 'rare':
            color = '#f2711c';
            break;
          case 'normal':
            color = 'gray';
            break;
          case 'cold':
            color = DefaultsApp.Colorbrewer[9];
            break;
          case 'critical':
            color = DefaultsApp.Colorbrewer[1];
            break;
          case 'incident':
            color = 'red';
            break;
          case 'deployment':
            color = 'orange';
            shortType = 'C';
            break;
          default:
            break;
        }
        typeAndColor.push({
          type: perType,
          shortType,
          color,
        });
      }, eventTypeList);
    }

    return typeAndColor;
  },
};

export default LogParser;
