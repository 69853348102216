import React, { useState, useCallback } from 'react';
import * as R from 'ramda';
import moment from 'moment';
// import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Spin, message, Alert, Button, Comment } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, EventActionHandle } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';

import { appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';

type Props = {
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  // eslint-disable-next-line
  incident: Object,
  onReload: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  timezoneOffset: Number,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
};

class MicrosoftTeamsNotesCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      eventList: [],
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData() {
    const { intl, environmentId, systemId, incident, credentials } = this.props;
    const { projectName, projectOwner, instanceName, incidentTimestamp } = incident;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('get-teams'), {
      ...credentials,
      environmentName: environmentId,
      systemName: systemId,
      customerName: projectOwner,
      projectName,
      instanceName,
      incidentTimestamp,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          fetchGet(getEndpoint('summarize-teams'), {
            ...credentials,
            environmentName: environmentId,
            systemName: systemId,
            customerName: projectOwner,
            projectName,
            instanceName,
            incidentTimestamp,
            amount: 'full',
          })
            .then((data) => {
              const { success, message: errMsg, responseItems } = data || {};
              if (success) {
                const eventList = responseItems || [];
                this.setState({ isLoading: false, errMsg: null, eventList });
              } else {
                this.setState({ isLoading: false, errMsg });
              }
            })
            .catch((err) => {
              message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
              this.setState({ isLoading: false, errMsg: err.message || String(err) });
            });
        } else {
          this.setState({ isLoading: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, errMsg: err.message || String(err) });
      });
  }

  render() {
    const { intl, credentials, environmentId, systemId, incident, onReload, updateLastActionInfo } = this.props;
    const { isLoading, errMsg, eventList } = this.state;

    return (
      <>
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <div className="full-height flex-col" style={{ paddingTop: 8 }}>
            <div style={{ marginBottom: 8 }}>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  EventActionHandle.handleCreateChannelOnTeamsClick({
                    intl,
                    credentials,
                    updateLastActionInfo,
                    environmentId,
                    systemId,
                    event: incident,
                    onReload,
                  });
                }}
              >
                {intl.formatMessage(eventMessages.createChannelOnTeams)}
              </Button>
            </div>

            {errMsg && <Alert style={{ marginBottom: 8 }} message="" description={errMsg} type="info" showIcon />}

            <div className="flex-grow flex-min-height overflow-y-auto">
              {R.addIndex(R.map)((item, idx) => {
                return <TeamsComment key={idx} intl={intl} level={0} item={item} />;
              }, eventList)}
            </div>
          </div>
        </Spin>
      </>
    );
  }
}

const TeamsComment = ({ intl, level = 0, item }: Object) => {
  const { user, dateTime, message: msg, replies } = item || {};
  const eventList = replies || [];
  const [expand, setExpand] = useState(level !== 0);
  const onClickChangeExpand = useCallback((flag) => {
    setExpand(!flag);
  }, []);

  return (
    <Comment
      actions={
        level === 0 && eventList.length > 0
          ? [
              <span key="comment-nested-reply-to" onClick={() => onClickChangeExpand(expand)}>
                {intl.formatMessage(eventActionMessages.replies)}
              </span>,
            ]
          : []
      }
      author={<a className="text-secondary">{user}</a>}
      content={<p className="teams-message" dangerouslySetInnerHTML={{ __html: msg }} />}
      datetime={<span>{moment.utc(dateTime).format(Defaults.DateTimeFormat)}</span>}
    >
      {expand && (
        <>
          {R.addIndex(R.map)((item, idx) => {
            return <TeamsComment key={idx} intl={intl} level={level + 1} item={item} />;
          }, eventList)}
        </>
      )}
    </Comment>
  );
};

const MicrosoftTeamsNotes = injectIntl(MicrosoftTeamsNotesCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      timezoneOffset,
      userInfo,
      credentials,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(MicrosoftTeamsNotes);
