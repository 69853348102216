/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import VLink from 'valuelink';
import { get } from 'lodash';
import * as d3 from 'd3';
import dagre from 'dagre';
import * as R from 'ramda';

import dagreD3 from '../../../../components/ui/dagre-d3';
import { State } from '../../../common/types';
import { createLoadAction } from '../../../common/app/actions';
import { Defaults, getLoadStatus, ifIn, CausalParser } from '../../../common/utils';
import { ActionTypes } from '../../../common/causal/actions';
import { Container, AutoSizer, Select } from '../../../lib/fui/react';
import { appMenusMessages, appButtonsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

const d3tip = require('d3-tip');

type Props = {
  intl: Object,
  loadStatus: Object,

  projectName: String,
  instanceGroup: String,
  causalIncident: Object,
  instanceName: String,
  hasLogProject: Boolean,
  hasMetricProject: Boolean,
  causalInstanceIncident: Object,
  incidentParams: Object,
  dependencyGroupData: Object,
  causalIncidentProperty: Object,

  createLoadAction: Function,
};

class DependencyGraphCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'causal_dependency_graph';
    this.chartNode = null;
    this.maxLines = 100;
    this.defaultZoom = 5;
    this.baseSep = 5;
    this.allNodeOptions = [];
    this.instanceMapping = {};
    this.intraInstanceList = [];

    this.state = {
      currentZoom: this.defaultZoom,
      filterNodes: null,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const incidentParams = this.props.incidentParams || {};
    const nextIncidentParams = nextProps.incidentParams || {};
    if (
      incidentParams.causalKey !== nextIncidentParams.causalKey ||
      incidentParams.customerName !== nextIncidentParams.customerName ||
      incidentParams.startTimestamp !== nextIncidentParams.startTimestamp ||
      incidentParams.endTimestamp !== nextIncidentParams.endTimestamp
    ) {
      this.reloadData(nextProps);
    } else if (nextProps.dependencyGroupData !== this.props.dependencyGroupData) {
      const { names, relations } = nextProps.dependencyGroupData;
      this.allNodeOptions = R.map((n) => ({ label: n, value: n }), names);
      if (relations.length > this.maxLines && names.length > 0) {
        this.setState({ filterNodes: names[0] }, () => {
          this.renderChart(nextProps);
        });
      } else {
        this.renderChart(nextProps);
      }
    }
  }

  UNSAFE_componentWillMount() {
    this.clearChart();
  }

  @autobind
  reloadData(props) {
    const { createLoadAction, incidentParams, projectName, instanceGroup } = props;
    if (incidentParams) {
      const {
        causalKey,
        relationKey,
        causalName,
        customerName,
        startTimestamp,
        endTimestamp,
        fileName,
        joinDependency,
      } = incidentParams;
      createLoadAction(
        ActionTypes.LOAD_CAUSAL_DEPENDENCY_GRAPH,
        {
          causalKey,
          relationKey,
          causalName,
          customerName,
          startTimestamp,
          endTimestamp,
          projectName,
          instanceGroup,
          fileName,
          joinDependency,
        },
        this.dataLoader,
      );
    }
  }

  @autobind
  renderChart(props) {
    this.clearChart();

    if (!this.chartNode) return;

    const { currentZoom } = this.state;
    const filterNodes = this.state.filterNodes || [];
    const { names, relations } = props.dependencyGroupData;
    const dependencyData = relations || [];

    // set instance map and intra instance list
    const { instancePropertyMap } = props.causalIncidentProperty || {};
    const instanceMapping = {};
    const intraInstanceList = [];
    R.forEachObjIndexed((value, instance) => {
      const appName = get(value, ['projectInstanceMetadata', 'componentName']);
      if (appName) {
        instanceMapping[instance] = appName;
      }
      if (value.possibleIntraCausal) {
        intraInstanceList.push(instance);
      }
    }, instancePropertyMap);
    this.instanceMapping = instanceMapping;
    this.intraInstanceList = intraInstanceList;

    const g = new dagre.graphlib.Graph({ directed: true, multigraph: true });
    const ranksep = this.baseSep + currentZoom * 30;
    const nodesep = this.baseSep + currentZoom * 20;
    const edgesep = this.baseSep + currentZoom * 10;
    g.setGraph({ rankdir: 'LR', align: 'DR', ranker: 'tight-tree', ranksep, nodesep, edgesep });

    let selectedNodes = names;
    if (filterNodes.length > 0) {
      selectedNodes = [];
      R.forEach((n) => {
        if (ifIn(n.elem1, filterNodes) || ifIn(n.elem2, filterNodes)) {
          selectedNodes.push(n.elem1);
          selectedNodes.push(n.elem2);
        }
      }, dependencyData);
      selectedNodes = R.sort((a, b) => a.localeCompare(b), R.uniq(R.filter((n) => Boolean(n), selectedNodes)));
    }

    R.forEach((n) => {
      const name = instanceMapping[n] || n;
      const label = CausalParser.trimString(name, 18);
      g.setNode(n, {
        id: n,
        shape: 'circle',
        type: '',
        class: '',
        label,
        name,
        width: -8,
        height: -8,
      });
    }, selectedNodes);

    // Add lines
    const arrowhead = 'vee';
    R.addIndex(R.forEach)((rel, idx) => {
      const { elem1, elem2 } = rel;
      if (elem1 && elem2 && ifIn(elem1, selectedNodes) && ifIn(elem2, selectedNodes)) {
        const meta = {
          id: `rel-edge-${idx + 1}`,
          relation: rel,
          type: 'relation',
          label: '',
          class: '',
          lineInterpolate: 'monotone',
          arrowhead,
          labelpos: 'l',
          labeloffset: 2,
        };
        g.setEdge(elem1, elem2, meta, 'dependency');
      }
    }, dependencyData);

    const container = d3.select(this.chartNode);
    const svg = container.append('svg');
    const inner = svg.append('g');
    const render = dagreD3.render();
    render(inner, g);

    this.tip = d3tip()
      .attr('class', 'd3-tip')
      .direction('e')
      .offset([0, 10])
      .html(({ d, n, e, g, instanceMapping }) => {
        // node
        if (n) {
          const { id, type } = n;
          return id;
        }
        return d;
      });
    const tip = this.tip;
    svg.call(tip);

    // The callback cannot use lambda, since d3tip needs 'this'.
    svg
      .selectAll('.node>g')
      .on('mouseover', function(d) {
        const n = g.node(d);
        if (n) {
          tip.show({ d, n, g, instanceMapping }, this);
        }
      })
      .on('mouseout', function(d) {
        const n = g.node(d);
        tip.hide(n, this);
      });

    svg
      .selectAll(`path`)
      .style('stroke', (d) => {
        return Defaults.Colorbrewer[0];
      })
      .style('stroke-width', (d) => {
        return `2px`;
      });

    const gbox = inner.node().getBBox();
    // Add some spaces for tooltip
    const width = gbox.width + 500;
    const height = gbox.height + 100;
    svg.attr({ width, height, viewBox: `${gbox.x} ${gbox.y} ${width} ${height}` });

    // center the graph
    if (filterNodes && filterNodes.length > 0) {
      // use the first filter node to center
      const filterNode = filterNodes[0];
      const { id, x, y } = g.node(filterNode) || {};
      if (id) {
        container.property('scrollLeft', x - container.property('clientWidth') / 2 + 100);
        container.property('scrollTop', y - container.property('clientHeight') / 2 + 60);
      }
    } else if (selectedNodes.length > 0) {
      const { id, x, y } = g.node(selectedNodes[0]) || {};
      if (id) {
        container.property('scrollLeft', x - container.property('clientWidth') / 2 + 100);
        container.property('scrollTop', y - container.property('clientHeight') / 2 + 60);
      }
    }
  }

  @autobind
  clearChart() {
    if (this.chartNode) {
      d3
        .select(this.chartNode)
        .select('svg')
        .remove();
    }
  }

  @autobind
  setChartNode(n) {
    this.chartNode = n;
  }

  @autobind
  handleFilterNodeChange() {
    this.setState({ currentZoom: this.defaultZoom }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  handleZoomInClick() {
    let { currentZoom } = this.state;
    currentZoom += 1;
    this.setState({ currentZoom }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  handleZoomOutClick() {
    let { currentZoom } = this.state;
    currentZoom = Math.max(currentZoom - 1, 1);
    this.setState({ currentZoom }, () => {
      this.renderChart(this.props);
    });
  }

  render() {
    const { intl, loadStatus } = this.props;
    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const showChart = true;
    const filterNodesLink = VLink.state(this, 'filterNodes').onChange(this.handleFilterNodeChange);

    return (
      <Container fullHeight className={`${isLoading && !errorMessage ? 'loading ' : ''}flex-grow`}>
        {errorMessage && <div className="ui mini error message">{errorMessage}</div>}
        {!errorMessage && (
          <Container fullHeight className={`${showChart ? '' : 'invisible '}chart flex-col flex-grow`}>
            <div className="toolbar">
              <div className="setting">
                <label>{intl.formatMessage(causalMessages.instanceFilter)}:</label>
                <Select name="nodes" multi autosize options={this.allNodeOptions} valueLink={filterNodesLink} />
              </div>
              <div className="zoom">
                <i className="plus icon" onClick={this.handleZoomInClick} />
                <i className="minus icon" onClick={this.handleZoomOutClick} />
              </div>
            </div>
            <div className="flex-grow">
              <AutoSizer>
                {({ width, height }) => (
                  <div style={{ width, height }}>
                    <div className="d3-container" ref={this.setChartNode} />
                  </div>
                )}
              </AutoSizer>
            </div>
          </Container>
        )}
      </Container>
    );
  }
}

const DependencyGraph = injectIntl(DependencyGraphCore);
export default connect(
  (state: State) => {
    const { loadStatus } = state.app;
    const { dependencyGroupData, incidentCausalProperty } = state.causal;
    return { loadStatus, dependencyGroupData, causalIncidentProperty: incidentCausalProperty || {} };
  },
  { createLoadAction },
)(DependencyGraph);
