import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import * as R from 'ramda';
import moment from 'moment';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Divider, Input, Spin, message } from 'antd';
import {
  ArrowUpOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  DislikeFilled,
  DislikeOutlined,
  EditOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  LikeFilled,
  LikeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { updateLastActionInfo } from '../../../common/app/actions';
import { EventRenderers, parseLocation, sleep } from '../../../common/utils';
import { Popover, Tooltip } from '../../../lib/fui/react';
import { LogoIcon } from '../../../lib/fui/icons';

import { logMessages } from '../../../common/log/messages';
import { settingsMessages } from '../../../common/settings/messages';
import { appButtonsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import EncapsulationMarkdown from './EncapsulationMarkdown';

const pageType = {
  Incident: 0,
  Prediction: 1,
  ServiceMap: 2,
};

const chatBox = {
  width: 700,
  height: 600,
  position: 'fixed',
  padding: '0px 16px 16px 16px',
  boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.3)',
  border: '2px solid var(--virtualized-table-border-color)',
  background: 'var(--content-background)',
  zIndex: 1001,
};

const userHeaderBox = {
  width: 30,
  height: 30,
  lineHeight: '30px',
  borderRadius: '50%',
  color: '#fff',
  flexShrink: 0,
  marginRight: 8,
};

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};

const handleCopyClick = (content) => {
  if (!navigator.clipboard) {
    try {
      const input = document.createElement('input');
      input.setAttribute('value', content);
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
      message.success('Copy success!');
    } catch (error) {
      message.error('Copy error!');
    }
  } else {
    navigator.clipboard.writeText(content).then(
      () => message.success('Copy success!'),
      () => message.error('Copy error!'),
    );
  }
};

const DividerRender = () => (
  <Divider style={{ margin: '16px 0', borderColor: 'var(--virtualized-table-border-color)' }} />
);

const UserInfoRender = (props: Object) => {
  const { isDark, rowData } = props || {};
  const { isGPT } = rowData || {};
  return (
    <div className="flex-row flex-center-align">
      {isGPT ? (
        <div className="text-center font-24" style={userHeaderBox}>
          <LogoIcon style={{ color: 'var(--black)' }} />
        </div>
      ) : (
        <div
          className="text-center font-12"
          style={{ ...userHeaderBox, background: isDark ? 'rgb(178 138 211)' : 'rgb(221 217 125)' }}
        >
          You
        </div>
      )}
      <div className="font-16 bold">{isGPT ? 'InsightFinder' : 'You'}</div>
    </div>
  );
};

const MessageRibbon = (props: Object) => {
  const { rowData, intl, style = {}, type, incident, location, globalInfo, credentials } = props || {};
  const { setRowStatus, rootSetState, updateLastActionInfo, message, updateRowMessage, isJWT } = props || {};
  const { isRowLoading, isGPT, id } = rowData || {};
  const [content, setContent] = useState(message);

  const hasMessage = rowData[type]?.response;

  const { environmentId, systemId } = parseLocation(location);
  const environment = R.find((e) => e.id === environmentId, globalInfo);
  const systemList = environment ? environment.systemList : [];
  let systemInfo = R.find((system) => system.id === systemId, systemList);
  if (isJWT) systemInfo = { id: systemId, ownerUserName: credentials?.userName };

  // prompt是问题, response 是答案
  const handleLikeStatus = (status) => {
    let params = {
      systemName: systemInfo?.id,
      timestamp: incident?.startTimestamp,
      UserName: systemInfo?.ownerUserName,
    };

    const messageMap = { response: rowData[type]?.response, prompt: rowData[type]?.prompt };
    const feedback = { status, ...messageMap };

    params = { ...params, feedback: JSON.stringify(feedback) };

    rootSetState({ settingLoading: true });
    updateLastActionInfo();
    fetchPost(getEndpoint('llama2-feedback', 1), {
      ...credentials,
      ...params,
    })
      .then((data) => {
        const messageList = setRowStatus(id, 'status', status, type);
        rootSetState({ messageList, settingLoading: false });
      })
      .catch((err) => {
        rootSetState({ settingLoading: false });
        message.error(err.message || String(err));
      });
  };

  return (
    <>
      {rowData[type]?.edit ? (
        <Input.TextArea
          allowClear
          autoSize
          value={content}
          className="auto_height_text_area"
          onChange={({ target: { value } }) => setContent(value)}
        />
      ) : (
        <EncapsulationMarkdown message={message} />
      )}

      {hasMessage && !isRowLoading && isGPT && !isJWT && (
        <div style={{ paddingTop: 14, ...style }}>
          {rowData[type]?.edit ? (
            <div className="flex-row flex-end-justify">
              <Button
                size="small"
                onClick={() => {
                  setContent(message);
                  const messageList = updateRowMessage(id, message, type);
                  rootSetState({ messageList });
                }}
              >
                {intl.formatMessage(appButtonsMessages.cancel)}
              </Button>
              <Button
                size="small"
                type="primary"
                style={{ marginLeft: 8 }}
                onClick={() => {
                  const messageList = updateRowMessage(id, content, type, -1);
                  rootSetState({ messageList });
                }}
              >
                {intl.formatMessage(appButtonsMessages.save)}
              </Button>
            </div>
          ) : (
            <>
              <Popover content={intl.formatMessage(settingsMessages.copy)} mouseEnterDelay={0.3} placement="top">
                <CopyOutlined onClick={() => handleCopyClick(rowData[type]?.response)} className="clickable" />
              </Popover>
              <Popover content={intl.formatMessage(appButtonsMessages.edit)} mouseEnterDelay={0.3} placement="top">
                <EditOutlined
                  className="clickable"
                  style={{ marginLeft: 8 }}
                  onClick={() => {
                    const messageList = setRowStatus(id, 'edit', true, type);
                    rootSetState({ messageList });
                  }}
                />
              </Popover>
              <Popover content={intl.formatMessage(eventMessages.markAsLike)} mouseEnterDelay={0.3} placement="top">
                {rowData[type].status === 0 ? (
                  <LikeFilled style={{ marginLeft: 8, color: 'green' }} />
                ) : (
                  <LikeOutlined className="clickable" style={{ marginLeft: 8 }} onClick={() => handleLikeStatus(0)} />
                )}
              </Popover>
              <Popover content={intl.formatMessage(eventMessages.removeLikeFlag)} mouseEnterDelay={0.3} placement="top">
                {rowData[type].status === 1 ? (
                  <DislikeFilled style={{ marginLeft: 8, color: 'red' }} />
                ) : (
                  <DislikeOutlined
                    className="clickable"
                    style={{ marginLeft: 8 }}
                    onClick={() => handleLikeStatus(1)}
                  />
                )}
              </Popover>
            </>
          )}
        </div>
      )}
    </>
  );
};

const ConversationContent = (props: Object) => {
  const { rowData, intl, hasRCA } = props || {};
  const { isRowLoading, summary, recommendation, chat, userMessage } = rowData || {};

  let content;
  if (summary && recommendation) {
    content = (
      <>
        <div className="font-14 bold" style={{ marginBottom: 4, marginTop: 8 }}>
          {intl.formatMessage(logMessages.systemHealthSummary)}:
        </div>
        <MessageRibbon
          message={summary?.response || ''}
          rowData={rowData}
          style={{ marginLeft: 16 }}
          {...props}
          type="summary"
        />
        <div className="font-14 bold" style={{ marginBottom: 4, marginTop: 16 }}>
          {intl.formatMessage(logMessages.recommendedSteps)}:
        </div>
        <MessageRibbon
          message={recommendation?.response || ''}
          rowData={rowData}
          style={{ marginLeft: 16 }}
          {...props}
          type="recommendation"
        />
      </>
    );
  } else if (summary) {
    content = (
      <>
        <div className="font-14 bold" style={{ marginBottom: 4, marginTop: 8 }}>
          {intl.formatMessage(logMessages.systemHealthSummary)}:
        </div>
        <MessageRibbon
          message={summary?.response || ''}
          rowData={rowData}
          style={{ marginLeft: 16 }}
          {...props}
          type="summary"
        />
        {hasRCA && !recommendation && (
          <>
            <div className="font-14 bold" style={{ marginBottom: 4, marginTop: 8 }}>
              {intl.formatMessage(logMessages.recommendedSteps)}:
            </div>
            <div style={{ width: 30, height: 30, textAlign: 'center', lineHeight: '30px', marginTop: 8 }}>
              <LoadingOutlined style={{ color: 'red', fontSize: 20, fontWeight: 'bold' }} />
            </div>
          </>
        )}
      </>
    );
  } else if (recommendation) {
    content = (
      <>
        <div className="font-14 bold" style={{ marginBottom: 4, marginTop: 8 }}>
          {intl.formatMessage(logMessages.recommendedSteps)}:
        </div>
        <MessageRibbon
          message={recommendation?.response || ''}
          rowData={rowData}
          style={{ marginLeft: 16 }}
          {...props}
          type="recommendation"
        />
      </>
    );
  } else if (chat) {
    content = (
      <MessageRibbon
        message={chat?.response || ''}
        rowData={rowData}
        style={{ minHeight: 30 }}
        {...props}
        type="chat"
      />
    );
  } else if (userMessage) {
    content = (
      <MessageRibbon
        message={userMessage || ''}
        rowData={rowData}
        style={{ minHeight: 30 }}
        {...props}
        type="userMessage"
      />
    );
  } else {
    content = 'Empty';
  }

  if (isRowLoading && hasRCA && summary && !recommendation) {
    return <div style={{ paddingLeft: 38 }}>{content}</div>;
  }

  return (
    <div style={{ paddingLeft: 38 }}>
      {isRowLoading ? (
        <div style={{ width: 30, height: 30, textAlign: 'center', lineHeight: '30px' }}>
          <LoadingOutlined style={{ color: 'red', fontSize: 20, fontWeight: 'bold' }} />
        </div>
      ) : (
        content
      )}
    </div>
  );
};

const DialogueItem = (props: Object) => {
  const { rowIndex, rowData } = props;
  return (
    <div style={{ marginTop: rowIndex === 0 ? 0 : 16 }} id={rowData.id}>
      <UserInfoRender {...props} />
      <ConversationContent {...props} />
    </div>
  );
};

function RecommendationsGPTCore(props: Object) {
  const { intl, onCancel, incident, credentials, updateLastActionInfo, incidentType, title, location } = props || {};
  const { globalInfo, isJWT, jwtToken, zoneName } = props || {};
  const { id, patternName, rawData, projectName, projectOwner, anomalyLogInstance, instanceList } = incident || {};
  const { startTimestamp, endTimestamp, rootCauseTableKey, patternId, type, category } = incident || {};
  const { predictionSourceInfoList, hasRootCause, selectedZone, level, rootCauseInfoKey } = incident || {};
  const { predictionTime } = incident || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    dialogueMessage: '',
    dialogueLoading: false,
    messageList: [],

    refreshTime: null,
    isMinimization: false,

    open: false,
    disabled: true,
    position: { x: 0, y: 0 },
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    width: 700,
    height: 600,
    settingLoading: false,

    context: undefined,
  });
  const draggleRef = useRef(null);
  const events = useRef({});
  const summarySettingsMap = useRef({});
  const { dialogueMessage, dialogueLoading, refreshTime, messageList, isMinimization, open, disabled, bounds } = state;
  const { position, width, height, settingLoading, context } = state;
  const { environmentId, systemId } = parseLocation(location);
  const environment = R.find((e) => e.id === environmentId, globalInfo);
  const systemList = environment ? environment.systemList : [];
  let systemInfo = R.find((system) => system.id === systemId, systemList);
  if (isJWT) systemInfo = { id: systemId, ownerUserName: credentials?.userName };
  const hasRCA = hasRootCause || pageType[incidentType] === 1;

  const changeMinimization = () => {
    setState({ isMinimization: !isMinimization });
  };

  const removeCarriageReturn = (str) => {
    return (str || '').replace(/\n/g, ' ');
  };

  const handleSetScrollBotoom = () => {
    const element = document.getElementById('Dalog_Box');
    element.scrollTop = element.scrollHeight;
  };

  const getInitRowMessage = ({ isGPT = false, loading = false, userMessage = '' }) => {
    return { isGPT, isRowLoading: loading, userMessage, id: uuidv4() };
  };

  const setRowMessage = ({ newMessageList, rest = {}, hasRCA, noJSON }) => {
    return R.map((item) => {
      if (item.isRowLoading) {
        const { recommendation, summary, chat } = rest;
        let newRest = {};
        if (recommendation)
          newRest = { ...newRest, recommendation: noJSON ? recommendation : JSON.parse(recommendation) };
        if (summary) newRest = { ...newRest, summary: noJSON ? summary : JSON.parse(summary) };
        if (chat) newRest = { ...newRest, chat: noJSON ? chat : JSON.parse(chat) };
        let data = { ...item, isRowLoading: false, ...newRest };

        if (hasRCA && (!data.summary || !data.recommendation)) {
          data = { ...data, isRowLoading: true };
        }
        return data;
      }

      return item;
    }, newMessageList || []);
  };

  const setRowStatus = (id, field, status, type) => {
    return R.map((item) => {
      if (item.id === id) {
        return { ...item, [type]: { ...item[type], [field]: status } };
      }
      return item;
    }, messageList || []);
  };

  const updateRowMessage = (id, message, type, status) => {
    return R.map((item) => {
      if (item.id === id) {
        return { ...item, [type]: { ...item[type], response: message, edit: false, ...(status ? { status } : {}) } };
      }
      return item;
    }, messageList || []);
  };

  const getLogsummarysettings = (list) => {
    const projectMap = {};
    if (pageType[incidentType] === 0) {
      const { logRootCauseEvents = [], metricRootCauseEvents = [] } = list || {};
      R.forEach(
        (item) => {
          const { rootCauseChain } = item || {};
          const { predictionSourceInfoList } = rootCauseChain || {};
          R.forEach((_item) => {
            const { sourceProjectName, sourceProjectOwner, type } = _item || {};
            const key = `${sourceProjectName}-${sourceProjectOwner}-${type}`;
            if (!projectMap[key]) {
              projectMap[key] = {
                projectName:
                  sourceProjectOwner !== credentials.userName
                    ? `${sourceProjectName}@${sourceProjectOwner}`
                    : sourceProjectName,
                category: type,
              };
            }
          }, predictionSourceInfoList || []);
        },
        [...logRootCauseEvents, ...metricRootCauseEvents],
      );
    } else if (pageType[incidentType] === 1) {
      R.forEach((item) => {
        const { sourceProjectName, sourceProjectOwner, type } = item || {};
        const key = `${sourceProjectName}-${sourceProjectOwner}-${type}`;
        if (!projectMap[key]) {
          projectMap[key] = {
            projectName:
              sourceProjectOwner !== credentials.userName
                ? `${sourceProjectName}@${sourceProjectOwner}`
                : sourceProjectName,
            category: type,
          };
        }
      }, list || []);
    }

    const incidentParam = {
      projectName: projectName !== credentials.userName ? `${projectName}@${projectOwner}` : projectName,
      category,
    };
    const requestParams = R.uniqBy((item) => item.projectName, [...R.values(projectMap), incidentParam]);

    const projects = [];
    R.forEach((item) => {
      const { projectName, category } = item || {};
      if (!R.includes(category, ['metric', 'Metric'])) projects.push(projectName);
    }, requestParams);

    const request = [];
    R.forEach((projectName) => {
      request.push(
        fetchGet(getEndpoint('logsummarysettings'), {
          ...credentials,
          projectName,
        }),
      );
    }, projects);

    return Promise.all(request)
      .then((res) => {
        const summarySettingsMap = {};
        R.addIndex(R.forEach)((projectName, idx) => {
          summarySettingsMap[projectName] = res[idx];
        }, projects || []);
        return summarySettingsMap;
      })
      .catch((err) => {
        message.error(err.message || String(err));
        return {};
      });
  };

  const getSummarysettingValue = (pname, powner, dataJson, dataString) => {
    const summaryKey = powner !== credentials.userName ? `${pname}@${powner}` : pname;
    const summarySettings = summarySettingsMap.current[summaryKey] || [];
    if (summarySettings.length === 0) {
      return dataString;
    }

    const jsonStringMap = {};
    R.forEach((s) => {
      const v = get(dataJson, s.replaceAll('->', '.'));
      if (v) jsonStringMap[s] = v;
    }, summarySettings || []);
    return JSON.stringify(jsonStringMap);
  };

  const handlePredictionSourceInfo = (data) => {
    const list = [];
    R.forEach((item) => {
      const { sourceDetail, sourceProjectName, sourceProjectOwner } = item || {};
      const { type, content } = sourceDetail || {};
      if (type === 'Metric') {
        list.push({ ...item, sourceDetail: JSON.stringify(sourceDetail) });
      } else {
        let rawDataJson;
        try {
          rawDataJson = JSON.parse(content);
        } catch (error) {
          // console.debug(error)
        }
        if (rawDataJson) {
          const newSourceDetail = getSummarysettingValue(sourceProjectName, sourceProjectOwner, rawDataJson, content);
          list.push({ ...item, sourceDetail: JSON.stringify({ ...sourceDetail, content: newSourceDetail }) });
        } else {
          list.push({ ...item, sourceDetail: JSON.stringify(sourceDetail) });
        }
      }
    }, data || []);
    return JSON.stringify(list);
  };

  const handleIncidentSourceInfo = (data) => {
    const list = [];
    R.forEach((item) => {
      const { rootCauseChain } = item || {};
      const { predictionSourceInfoList } = rootCauseChain || {};
      const newPredictionSourceInfoList = [];
      R.forEach((_item) => {
        const { sourceDetail, sourceProjectName, sourceProjectOwner } = _item || {};
        let parseSourceDetail;
        try {
          parseSourceDetail = JSON.parse(sourceDetail);
          const { type, content } = parseSourceDetail || {};
          if (type === 'Metric') {
            newPredictionSourceInfoList.push(_item);
          } else {
            let rawDataJson;
            try {
              rawDataJson = JSON.parse(content);
            } catch (error) {
              // console.debug(error)
            }
            if (rawDataJson) {
              const newSourceDetail = getSummarysettingValue(
                sourceProjectName,
                sourceProjectOwner,
                rawDataJson,
                content,
              );
              newPredictionSourceInfoList.push({
                ..._item,
                sourceDetail: JSON.stringify({ ...parseSourceDetail, content: newSourceDetail }),
              });
            } else {
              newPredictionSourceInfoList.push(_item);
            }
          }
        } catch (error) {
          // console.debug(error)
        }
      }, predictionSourceInfoList || []);
      list.push({ ...item, rootCauseChain: { predictionSourceInfoList: newPredictionSourceInfoList } });
    }, data || []);
    return JSON.stringify(list);
  };

  // modeType: 1代表RCA，0代表用户输入
  const getLLMMessage = (flag, newMessageList, modeType = 1) => {
    let incidentData = '';
    if (category === 'metric') {
      const rootCauseDetailsArr = get(incident, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      const summaryList = R.map((event) => {
        return EventRenderers.BuildMetricAnomalySummary({ event });
      }, rootCauseDetailsArr);
      incidentData = R.join('\n', summaryList);
    } else {
      let rawDataJson;
      try {
        rawDataJson = JSON.parse(rawData);
      } catch (error) {
        // console.debug(error)
      }
      if (rawDataJson) {
        incidentData = getSummarysettingValue(projectName, projectOwner, rawDataJson, rawData);
      } else {
        incidentData = rawData;
      }
    }

    const manual = dialogueMessage;
    if (modeType === 0) {
      setState({ dialogueMessage: '' });
      handleSetScrollBotoom();
      updateLastActionInfo();
    }

    const { logRootCauseEvents = [], metricRootCauseEvents = [], predictionSourceInfoList = [] } = events.current || {};
    let params = {};

    if (modeType !== 0) {
      params = {
        patternName: patternName ? String(patternName) : undefined,
      };

      if (pageType[incidentType] === 0) {
        params = {
          ...params,
          logRCAList: handleIncidentSourceInfo(logRootCauseEvents),
          metricRCAList: handleIncidentSourceInfo(metricRootCauseEvents),
        };
      } else if (pageType[incidentType] === 1) {
        params = { ...params, metricRCAList: JSON.stringify([]), logRCAList: JSON.stringify([]) };
        if (category === 'metric') {
          params = { ...params, metricRCAList: handlePredictionSourceInfo(predictionSourceInfoList) };
        } else {
          params = { ...params, logRCAList: handlePredictionSourceInfo(predictionSourceInfoList) };
        }
      }
    }

    if (isJWT) params = { ...params, jwt: jwtToken, zoneName };

    if (modeType === 1) {
      if (hasRCA && false) {
        fetchPost(getEndpoint(isJWT ? 'rca-recommendation-jwt' : 'llama2-recommendations', 1), {
          ...credentials,
          ...params,
          incidentData: removeCarriageReturn(incidentData),
          mode: 2, // 2代表API返回的summary // 1代表API返回的recommendation
          type: pageType[incidentType], // 0代表Incident Investigation， 1代表Incident Prediction
          systemName: systemInfo?.id,
          customerName: systemInfo?.ownerUserName,
          predictionTime: predictionTime || undefined,
          occurrenceTime: startTimestamp,
        })
          .then((res) => {
            const { success, message: msg, ...rest } = res || {};
            if (success || success === undefined) {
              newMessageList = setRowMessage({ newMessageList, rest, hasRCA });
              setState({ dialogueLoading: false, messageList: newMessageList });
            } else {
              newMessageList = setRowMessage({ newMessageList });
              message.error(msg);
              setState({ dialogueLoading: false, messageList: newMessageList });
            }
          })
          .catch((err) => {
            newMessageList = setRowMessage({ newMessageList });
            message.error(err.message || String(err));
            setState({ dialogueLoading: false, messageList: newMessageList });
          });
      }

      fetchPost(getEndpoint(isJWT ? 'rca-recommendation-jwt' : 'llama2-recommendations', 1), {
        ...credentials,
        ...params,
        incidentData: removeCarriageReturn(incidentData),
        mode: 3, // 3代表API返回summary和recommendation
        type: pageType[incidentType], // 0代表Incident Investigation， 1代表Incident Prediction
        systemName: systemInfo?.id,
        customerName: systemInfo?.ownerUserName,
        predictionTime: predictionTime || undefined,
        occurrenceTime: startTimestamp,
      })
        .then((res) => {
          const { success, message: msg, context, ...rest } = res || {};
          if (success || success === undefined) {
            newMessageList = setRowMessage({ newMessageList, rest, hasRCA });
            setState({ dialogueLoading: false, messageList: newMessageList, context });
          } else {
            newMessageList = setRowMessage({ newMessageList });
            message.error(msg);
            setState({ dialogueLoading: false, messageList: newMessageList });
          }
        })
        .catch((err) => {
          newMessageList = setRowMessage({ newMessageList });
          message.error(err.message || String(err));
          setState({ dialogueLoading: false, messageList: newMessageList });
        });
    } else {
      const request = [];
      request.push(
        fetchPost(getEndpoint(isJWT ? 'rca-recommendation-jwt' : 'llama2-recommendations', 1), {
          ...credentials,
          ...params,
          incidentData: removeCarriageReturn(manual),
          mode: 0, // 0代表用户聊天API返回chat
          type: pageType[incidentType], // 0代表Incident Investigation， 1代表Incident Prediction
          context,
          systemName: systemInfo?.id,
          customerName: systemInfo?.ownerUserName,
          predictionTime: predictionTime || undefined,
          occurrenceTime: startTimestamp,
        }),
      );
      Promise.all(request)
        .then((res) => {
          let data = {};
          let hasErrorData = null;
          R.forEach((item) => {
            const { success, message, ...rest } = item || {};
            if (success || success === undefined) {
              data = { ...data, ...rest };
            } else {
              hasErrorData = item;
            }
          }, res || []);

          if (!hasErrorData) {
            newMessageList = setRowMessage({ newMessageList, rest: data });
            const lastId = newMessageList[newMessageList.length - 1]?.id;
            const el = document.getElementById(lastId);
            setState({ dialogueLoading: false, messageList: newMessageList });
            if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          } else {
            message.error(hasErrorData?.msg);
            setState({ dialogueLoading: false, messageList: setRowMessage({ newMessageList }) });
          }
        })
        .catch((err) => {
          message.error(err.message || String(err));
          setState({ dialogueLoading: false, messageList: setRowMessage({ newMessageList }) });
        });
    }
  };

  const reloadData = (flag) => {
    const newProjectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
    const event = {
      nid: patternId,
      eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
    };

    const newMessageList = [getInitRowMessage({ isGPT: true, loading: true })];

    setState({ dialogueLoading: true, messageList: newMessageList });

    const request = [];

    if (isJWT) {
      request.push(
        fetchPost(getEndpoint('logCausalInfoJWTServlet', 1), {
          queryString: JSON.stringify(rootCauseInfoKey),
          customerName: credentials?.userName,
          systemName: systemId,
          jwt: jwtToken,
          zoneName,
        }),
      );
    } else {
      request.push(
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName: newProjectName,
          instanceName: anomalyLogInstance || instanceList[0],
          startTime,
          endTime,
          operation: 'rootCauseEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
      );
    }

    updateLastActionInfo();
    Promise.all(request)
      .then(async (results) => {
        if (flag) return;
        const { success, message: msg, ...rest } = results[0];
        if (success || success === undefined) {
          events.current = rest;
          if (!isJWT) summarySettingsMap.current = await getLogsummarysettings(rest);
          getLLMMessage(flag, newMessageList);
        } else {
          message.error(msg);
          setState({ dialogueLoading: false, messageList: setRowMessage({ newMessageList }) });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        setState({ dialogueLoading: false, messageList: setRowMessage({ newMessageList }) });
      });
  };

  const predictionReloadData = async (flag) => {
    const newMessageList = [getInitRowMessage({ isGPT: true, loading: true })];

    setState({ dialogueLoading: true, messageList: newMessageList });

    await sleep(500);
    if (!isJWT) summarySettingsMap.current = await getLogsummarysettings(predictionSourceInfoList);
    events.current = { predictionSourceInfoList };

    getLLMMessage(flag, newMessageList);
  };

  // modeType: 1代表RCA，0代表用户输入
  const serviceMapReloadData = (modeType = 1) => {
    if (!systemInfo) return;

    let zone = selectedZone || '';
    if (selectedZone === '__all__') {
      zone = 'all_zone';
    } else if (R.startsWith('zone_', selectedZone || '')) {
      zone = 'No zones';
    }

    const manual = dialogueMessage;
    let newMessageList = [];
    if (modeType === 1) {
      newMessageList = [getInitRowMessage({ isGPT: true, loading: true })];
      setState({ dialogueLoading: true, messageList: newMessageList });
    } else {
      newMessageList = messageList;
      setState({ dialogueMessage: '' });
      handleSetScrollBotoom();
      updateLastActionInfo();
    }

    const chartParams = {
      systemName: systemInfo?.id,
      customerName: systemInfo?.ownerUserName,
      startTime: String(startTimestamp),
      level,
    };

    const request = [
      fetchGet(getEndpoint(isJWT ? 'servicemap-llm-recommendation-jwt' : 'servicemap-llm-recommendation', 1), {
        ...credentials,
        ...(modeType === 0
          ? { chat: removeCarriageReturn(manual), context, ...(isJWT ? chartParams : {}) }
          : chartParams),
        ...(isJWT ? { jwt: jwtToken, zoneName } : { Zone: zone }),
      }),
    ];

    updateLastActionInfo();
    Promise.all(request)
      .then((res) => {
        const { success, message: msg, context, ...rest } = res[0] || {};
        if (success || success === undefined) {
          newMessageList = setRowMessage({ newMessageList, rest, noJSON: true });
          setState({ dialogueLoading: false, messageList: newMessageList, ...(modeType === 0 ? {} : { context }) });
          if (modeType === 0) {
            const lastId = newMessageList[newMessageList.length - 1]?.id;
            const el = document.getElementById(lastId);
            if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        } else {
          newMessageList = setRowMessage({ newMessageList });
          message.error(msg);
          setState({ dialogueLoading: false, messageList: newMessageList });
        }
      })
      .catch((err) => {
        newMessageList = setRowMessage({ newMessageList });
        message.error(err.message || String(err));
        setState({ dialogueLoading: false, messageList: newMessageList });
      });
  };

  useEffect(() => {
    let flag = false;
    if (isMinimization) setState({ isMinimization: false, position: { x: 0, y: 0 }, width: 700, height: 600 });
    if (pageType[incidentType] === 0) {
      reloadData(flag);
    } else if (pageType[incidentType] === 1) {
      predictionReloadData(flag);
    } else if (pageType[incidentType] === 2) {
      serviceMapReloadData();
    }
    return () => {
      flag = true;
      events.current = [];
    };
  }, [id]);

  useEffect(() => {
    let flag = false;
    if (refreshTime) {
      if (pageType[incidentType] !== 2) {
        getLLMMessage(flag, messageList, 0);
      } else if (pageType[incidentType] === 2) {
        serviceMapReloadData(0);
      }
    }
    return () => {
      flag = true;
    };
  }, [refreshTime]);

  const onStart = useCallback((event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setState({
      bounds: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      },
    });
  }, []);

  const onResize = useCallback((event, { size }) => {
    setState({ width: size?.width || 700, height: size?.height || 600 });
  }, []);

  const hasLoadingItem = R.find((item) => item.isRowLoading, messageList || []);

  return createPortal(
    <Draggable
      position={position}
      disabled={disabled}
      bounds={bounds}
      onStart={(event, uiData) => onStart(event, uiData)}
      onDrag={(e, { x, y }) => {
        if (!isMinimization) setState({ position: { x, y } });
      }}
    >
      <Resizable
        width={width}
        height={height}
        resizeHandles={['se']}
        minConstraints={isMinimization ? [270, 56] : [700, 600]}
        maxConstraints={isMinimization ? [270, 56] : [1200, 900]}
        onResize={onResize}
      >
        <div
          ref={draggleRef}
          className={`corner-8 flex-col ${isMinimization ? 'hidden-resizable-handle' : ''}`}
          style={{
            ...chatBox,
            width: `${width}px`,
            height: `${height}px`,
            top: document.body.clientHeight - 608,
            left: document.body.clientWidth - 708,
          }}
        >
          <div
            className="flex-row flex-center-align font-14 bold"
            style={{ paddingTop: 16, ...(isMinimization ? {} : { cursor: 'move' }) }}
            onMouseOver={() => {
              if (disabled && !isMinimization) setState({ disabled: false });
            }}
            onMouseOut={() => setState({ disabled: true })}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            <div>{title}</div>
            <div className="flex-grow" />
            <Tooltip open={open} title={`${isMinimization ? 'Expansion' : 'Minimization'}`}>
              {isMinimization ? (
                <FullscreenOutlined
                  className="clickable"
                  style={{ marginRight: 8 }}
                  onMouseEnter={() => setState({ open: true })}
                  onMouseLeave={() => setState({ open: false })}
                  onClick={() => {
                    setState({ open: false, position: { x: 0, y: 0 }, width: 700, height: 600 });
                    changeMinimization();
                  }}
                />
              ) : (
                <FullscreenExitOutlined
                  className="clickable"
                  style={{ marginRight: 8 }}
                  onMouseEnter={() => setState({ open: true })}
                  onMouseLeave={() => setState({ open: false })}
                  onClick={() => {
                    setState({ open: false, position: { x: 700 - 270 - 8, y: 608 - 56 - 8 }, width: 270, height: 56 });
                    changeMinimization();
                  }}
                />
              )}
            </Tooltip>
            <Tooltip title={intl.formatMessage(appButtonsMessages.close)}>
              <CloseCircleOutlined className="clickable" onClick={onCancel} />
            </Tooltip>
          </div>

          {!isMinimization && (
            <Spin spinning={settingLoading} wrapperClassName="flex-grow flex-min-height spin-full-height">
              <DividerRender />

              <div className="flex-grow overflow-y-auto" id="Dalog_Box">
                {R.addIndex(R.map)(
                  (item, idx) => (
                    <DialogueItem
                      key={item.id}
                      {...props}
                      rowData={item}
                      rowIndex={idx}
                      setRowStatus={setRowStatus}
                      rootSetState={setState}
                      updateRowMessage={updateRowMessage}
                      hasRCA={hasRCA}
                    />
                  ),
                  messageList || [],
                )}
              </div>

              <DividerRender />

              <div className="flex-row" style={{ alignItems: 'flex-end' }}>
                <Input.TextArea
                  allowClear
                  value={dialogueMessage}
                  placeholder="Message..."
                  disabled={dialogueLoading || hasLoadingItem}
                  style={{ resize: 'none' }}
                  autoSize={{ maxRows: 3 }}
                  onChange={(e) => setState({ dialogueMessage: e.target.value })}
                  onPressEnter={async (e) => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      setState({
                        dialogueLoading: true,
                        refreshTime: +new Date(),
                        messageList: [
                          ...messageList,
                          getInitRowMessage({ userMessage: dialogueMessage }),
                          getInitRowMessage({ isGPT: true, loading: true }),
                        ],
                      });
                    }
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  icon={<ArrowUpOutlined />}
                  disabled={dialogueLoading || !dialogueMessage || hasLoadingItem}
                  style={{ width: 40, marginLeft: 8 }}
                  onClick={async () => {
                    setState({
                      dialogueLoading: true,
                      refreshTime: +new Date(),
                      messageList: [
                        ...messageList,
                        getInitRowMessage({ userMessage: dialogueMessage }),
                        getInitRowMessage({ isGPT: true, loading: true }),
                      ],
                    });
                  }}
                />
              </div>
            </Spin>
          )}
        </div>
      </Resizable>
    </Draggable>,
    document.body,
    id,
  );
}

const RecommendationsGPT = injectIntl(RecommendationsGPTCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { globalInfo } = state.app;

    const { dark, jwtToken, customerName } = parseLocation(location);
    let credentials = {};
    if (jwtToken) {
      if (dark) {
        state.app.currentTheme = 'dark';
      } else {
        state.app.currentTheme = 'light';
      }
      credentials = { userName: customerName };
    } else {
      credentials = state.auth?.credentials;
    }

    const isDark = state.app.currentTheme === 'dark';
    return {
      credentials,
      location,
      isDark,
      globalInfo: globalInfo || {},
    };
  },
  { updateLastActionInfo },
)(RecommendationsGPT);
