/* @flow */

import { defineMessages } from 'react-intl';

const logDescMessages = defineMessages({
  newpattern: {
    defaultMessage: 'New log patterns which have never seen before within the past 7 days.',
    id: 'log.desc.newpattern',
  },
  rare: {
    defaultMessage: 'Rare log patterns which are rarely seen today.',
    id: 'log.desc.rare',
  },
  cold: {
    defaultMessage: 'Cold log patterns which appear less frequently today than before.',
    id: 'log.desc.cold',
  },
  hot: {
    defaultMessage: 'Hot log patterns which appear more frequently today than before.',
    id: 'log.desc.hot',
  },
  critical: {
    defaultMessage: 'Critical log patterns which include critical keywords such as "fail, fatal".',
    id: 'log.desc.critical',
  },
  whitelist: {
    defaultMessage: 'Log patterns which include user-defined important keywords such as "timeout exception".',
    id: 'log.desc.whitelist',
  },
});

export default logDescMessages;
