/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */
import React from 'react';
import * as R from 'ramda';
import { get, isObject } from 'lodash';
import { Tag } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import { highlightContent, LogRenderers } from '../../common/utils';
import { CellMeasurer } from '../../lib/fui/react';

import CollapsibleLogContent from './CollapsibleLogContent';

const LogContent = (cellProps, cache, options) => {
  const { dataKey, parent, rowIndex, cellData, style, rowData } = cellProps;
  const { isExpand, rawData } = rowData;
  const {
    showRawDataFrequency,
    frequencyStr,
    isInfiniteLoad,
    onChanged,
    summarySettings,
    enableJsonSummary,
    highlightWord,
    intl,
    currentTheme,
    isCacheColumn,
    width,
  } = options;

  const message = cellData || '';
  let rawDataJson;
  try {
    rawDataJson = JSON.parse(rawData);
    if (!isObject(rawDataJson)) rawDataJson = undefined;
  } catch (error) {
    // ignore
  }

  const onChangedHandler = () => {
    if (onChanged) {
      onChanged();
    }
  };

  if ((isInfiniteLoad || enableJsonSummary) && rawDataJson) {
    // If json and infinite load
    const settings = summarySettings || [];
    const message = rawData;
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        {isInfiniteLoad && (
          <div style={{ width }}>
            <div className="flex-row" style={{ minHeight: '40px', alignItems: 'center' }}>
              <span style={{ marginRight: 2 }}>{isExpand ? <DownOutlined /> : <RightOutlined />}</span>
              {settings.length === 0 && (
                <div style={{ ...style }} className="hidden-line-with-ellipsis">
                  {highlightContent(message, highlightWord)}
                </div>
              )}
              {settings.length > 0 && (
                <div style={{ ...style, overflow: 'hidden', display: 'flex' }}>
                  {R.addIndex(R.map)((s, idx) => {
                    const v = get(rawDataJson, s.replaceAll('->', '.'));
                    if (!v) return null;

                    return (
                      <div
                        key={s}
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          overflow: 'hidden',
                          flex: idx === settings.length - 1 ? '1' : 'initial',
                        }}
                      >
                        <Tag color="processing" style={{ marginRight: 2, padding: '0 2px' }}>
                          {s}
                        </Tag>
                        <div
                          style={{
                            paddingRight: 8,
                            display: 'inline-block',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          dangerouslySetInnerHTML={{ __html: highlightContent(v, highlightWord) }}
                        />
                      </div>
                    );
                  }, settings)}
                </div>
              )}
            </div>
            {isExpand && (
              <LogRenderers.JsonTree
                data={rawDataJson}
                onToggleCollapse={onChangedHandler}
                currentTheme={currentTheme}
              />
            )}
          </div>
        )}
        {enableJsonSummary && (
          <div className="flex-grow flex-min-height flex-min-width">
            <LogRenderers.RenderLogContent
              intl={intl}
              rawData={rawData}
              rawDataJson={rawDataJson}
              highlightWord={highlightWord}
              owner={rowData}
              summarySettings={summarySettings}
              onToggleCollapse={onChangedHandler}
              currentTheme={currentTheme}
            />
          </div>
        )}
      </CellMeasurer>
    );
  } else {
    return (
      <>
        {!isCacheColumn && (
          <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
            <div>
              {showRawDataFrequency && frequencyStr && <span style={{ color: 'blue' }}>{`${frequencyStr}`}</span>}
              <CollapsibleLogContent ownerObject={rowData} message={message} style={style} {...options} />
            </div>
          </CellMeasurer>
        )}
        {isCacheColumn && (
          <CollapsibleLogContent key={dataKey} ownerObject={rowData} message={message} style={style} {...options} />
        )}
      </>
    );
  }
};

export default LogContent;
