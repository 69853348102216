/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { State } from '../../../../common/types';
import { Container, Table, Column, AutoSizer, Tooltip, Select } from '../../../../lib/fui/react';
import {
  loadProjectLogStructure,
  saveLogEntryStructure,
  removeLogEntryStructure,
} from '../../../../common/settings/actions';

type Props = {
  intl: Object,
  currentLoadingComponents: Object,
  projectName: String,
  logStructureList: Array,
  logEntryStructure: Array,

  loaderStatus: Object,
  loadProjectLogStructure: Function,
  saveLogEntryStructure: Function,
  removeLogEntryStructure: Function,
};

class LogStructureSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { logStructureList, logEntryStructure } = props;

    this.submitLoader = 'setting_log_structure_submit';

    // Make a full copy of the data to avoid side affect with other components.
    this.localLogEntryStructure = R.clone(logEntryStructure || []);
    this.logStructureList = [];
    this.state = {};
    this.matchTypeList = [
      { label: 'Json', value: 'json' },
      { label: 'Field Name', value: 'fieldName' },
    ];

    R.forEach((p) => {
      this.logStructureList.push({ label: p, value: p });
    }, logStructureList || []);

    this.selectCellRender = ({ dataKey, rowData, cellData }) => (
      <Tooltip title={<div>{cellData}</div>} placement="top" style={{ display: 'inline-block' }}>
        <div>{cellData}</div>
      </Tooltip>
    );
    this.checkboxCellRender = ({ dataKey, rowData, cellData }) => (
      <input
        className="fui input"
        type="checkbox"
        checked={cellData || false}
        onChange={this.handleInputChanged(rowData, dataKey)}
      />
    );
  }

  componentDidMount() {
    this.loadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { logStructureList, logEntryStructure } = nextProps;

    if (nextProps.projectName !== this.props.projectName) {
      this.loadData(nextProps);
    } else if (!R.identical(logEntryStructure, get(this.props, 'logEntryStructure'))) {
      this.localLogEntryStructure = R.clone(logEntryStructure);

      const newOptions = [];
      R.forEach((p) => {
        newOptions.push({ label: p, value: p });
      }, logStructureList || []);
      this.logStructureList = newOptions;
    }
  }

  @autobind
  loadData(props) {
    const { projectName, loadProjectLogStructure } = props;
    if (projectName) {
      loadProjectLogStructure({ projectName });
    }
  }

  @autobind
  getLogEntryStructureAll(logEntryStructure, logStructureList) {
    const hasPaths = R.map((log) => {
      return log.structurePath;
    }, logEntryStructure || []);
    const logEntryStructureAll = [];
    R.forEach((path) => {
      if (R.indexOf(path, hasPaths) < 0) {
        logEntryStructureAll.push({
          structurePath: path,
          name: '',
          isContent: false,
          isSequenceScope: false,
          value: '',
        });
      }
    }, logStructureList || []);
    return R.concat(logEntryStructure || [], logEntryStructureAll);
  }

  @autobind
  handleRoleChange(rowData, dataKey) {
    return (e) => {
      // Save the data and force update.
      rowData[dataKey] = e ? e.value || '' : '';
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  saveDataTableNode(node) {
    this.table = node;
  }

  @autobind
  handleTypeChange(rowData, dataKey) {
    return (e) => {
      const newVal = e ? e.value : '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }
  @autobind
  renderTypeSelect({ dataKey, rowData, cellData }) {
    return (
      <Select
        withPortal
        autosize
        clearable
        style={{}}
        options={this.matchTypeList}
        value={cellData}
        onChange={this.handleTypeChange(rowData, dataKey)}
      />
    );
  }
  @autobind
  handleFilterChanged(rowData, dataKey) {
    return (e) => {
      const target = e.target;
      const newVal = target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleFilterSelectChanged(rowData, dataKey) {
    return (e) => {
      // Save the data and force update.
      rowData[dataKey] = e ? e.value || '' : '';
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }
  @autobind
  renderFilterInput({ dataKey, rowData, cellData, ...other }) {
    const { matchType } = rowData;
    if (matchType === 'json') {
      return (
        <Select
          withPortal
          autosize
          clearable
          style={{}}
          options={this.logStructureList}
          value={cellData}
          onChange={this.handleFilterSelectChanged(rowData, dataKey)}
        />
      );
    }
    return <input className="fui input" value={cellData} onChange={this.handleFilterChanged(rowData, dataKey)} />;
  }
  @autobind
  renderOperationInput({ dataKey, rowData, cellData }) {
    return <div className="flex-grow">=</div>;
  }
  @autobind
  handleValueChanged(rowData, dataKey) {
    return (e) => {
      const target = e.target;
      const newVal = target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }
  @autobind
  renderValueInput({ dataKey, rowData, cellData }) {
    return <input className="fui input" value={cellData} onChange={this.handleValueChanged(rowData, dataKey)} />;
  }
  @autobind
  handleNameChanged(rowData, dataKey) {
    return (e) => {
      const target = e.target;
      const newVal = target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }
  @autobind
  renderNameInput({ dataKey, rowData, cellData }) {
    return <input className="fui input" value={cellData} onChange={this.handleNameChanged(rowData, dataKey)} />;
  }

  @autobind
  handleGroupRemove({ rowData, rowIndex }) {
    const { projectName, logEntryStructure } = this.props;
    return () => {
      const self = this;
      const diff = R.difference(self.localLogEntryStructure, logEntryStructure || []);
      if (window.confirm(`delete this structure:${rowData.structurePath}?`)) {
        const data = R.find(R.propEq('structurePath', rowData.structurePath))(diff);
        if (data) {
          // self.localLogEntryStructure = R.filter(n => n.structurePath !== data.structurePath, self.localLogEntryStructure);
          self.localLogEntryStructure = R.remove(rowIndex, 1, self.localLogEntryStructure);
          this.table.forceUpdateGrid();
          this.forceUpdate();
        } else {
          this.props.removeLogEntryStructure({ projectName, entries: [rowData] });
        }
      }
    };
  }

  @autobind
  handleInputChanged(rowData, dataKey) {
    return (e) => {
      const target = e.target;
      const newVal = target.type === 'checkbox' ? target.checked : target.value || '';

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  renderRemoveButton(data) {
    return (
      <div className="hover-show ui grey button" onClick={this.handleGroupRemove(data)}>
        Remove
      </div>
    );
  }

  @autobind
  handleAddClick(e) {
    e.preventDefault();
    this.localLogEntryStructure.push({
      name: '',
      structurePath: '',
      value: '',
      isSequenceScope: false,
      isContent: false,
    });
    this.table.forceUpdateGrid();
    this.forceUpdate();
    this.addIndex = this.localLogEntryStructure.length - 1;
  }

  @autobind
  handleSaveClick() {
    const { logEntryStructure } = this.props;
    const { saveLogEntryStructure } = this.props;
    let { projectName } = this.props;

    if (projectName && projectName.indexOf('@') >= 0) {
      projectName = projectName.split('@')[0] || projectName;
    }

    // const diff = R.difference(this.localLogEntryStructure, logEntryStructure || []);
    const localLogEntryStructure = R.filter((log) => !R.isEmpty(log.structurePath), this.localLogEntryStructure);
    const entries = R.map((m) => {
      return {
        matchType: m.matchType,
        structurePath: m.structurePath,
        structureValue: m.structureValue || '',
        name: m.name,
        isSequenceScope: m.isSequenceScope || false,
        isContent: m.isContent || false,
      };
    }, localLogEntryStructure);
    saveLogEntryStructure({ projectName, entries }, { [this.submitLoader]: true });
  }

  render() {
    const { logEntryStructure } = this.props;

    const diff = R.difference(this.localLogEntryStructure, logEntryStructure || []);

    const addIndex = this.addIndex;
    if (this.addIndex >= 0) {
      this.addIndex = -1;
    }

    const hasError = diff.length === 0;
    const isSubmitting = get(this.props.currentLoadingComponents, this.submitLoader, false);

    return (
      <Container fullHeight className="overflow-y-auto">
        <form
          className={`ui ${hasError ? 'error' : ''} form full-height flex-col`}
          style={{ fontSize: 12, width: 1048 }}
        >
          <h3>Log Filter Tags</h3>
          <p>Defines the tags used to group log entries. A tag contains a name and log entry filters.</p>
          <div className="field">
            <div className="ui button blue" onClick={this.handleAddClick}>
              Add
            </div>
          </div>
          <Container className={`flex-grow field${isSubmitting ? ' disabled' : ''}`}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  rowCount={this.localLogEntryStructure.length}
                  rowGetter={({ index }) => this.localLogEntryStructure[index]}
                  ref={this.saveDataTableNode}
                  scrollToIndex={addIndex}
                >
                  <Column
                    width={150}
                    label="Type"
                    dataKey="matchType"
                    className="white-pre"
                    cellRenderer={this.renderTypeSelect}
                  />
                  <Column
                    width={200}
                    flexGrow={1}
                    label="Filter"
                    dataKey="structurePath"
                    cellRenderer={this.renderFilterInput}
                  />
                  <Column
                    width={80}
                    label="Operation"
                    dataKey="operation"
                    className="white-pre"
                    cellRenderer={this.renderOperationInput}
                  />
                  <Column
                    width={120}
                    label="Value"
                    dataKey="structureValue"
                    className="white-pre"
                    cellRenderer={this.renderValueInput}
                  />
                  <Column
                    width={200}
                    label="Tag name"
                    dataKey="name"
                    className="white-pre"
                    cellRenderer={this.renderNameInput}
                  />
                  {false && (
                    <Column
                      width={100}
                      label="Sequence scope"
                      dataKey="isSequenceScope"
                      className="text-center"
                      headerClassName="text-center"
                      cellRenderer={this.checkboxCellRender}
                    />
                  )}
                  {false && (
                    <Column
                      width={100}
                      label="Content"
                      dataKey="isContent"
                      className="text-center"
                      headerClassName="text-center"
                      cellRenderer={this.checkboxCellRender}
                    />
                  )}
                  <Column
                    width={80}
                    label=""
                    className="text-right"
                    cellRenderer={this.renderRemoveButton}
                    dataKey="name"
                  />
                </Table>
              )}
            </AutoSizer>
          </Container>
          <Container className="field flex-row">
            <div className="flex-grow" />
            <div
              className={`ui button ${isSubmitting ? 'loading' : ''} ${hasError ? 'disabled' : ''} blue`}
              {...(isSubmitting || hasError ? {} : { onClick: this.handleSaveClick })}
            >
              Update
            </div>
          </Container>
        </form>
      </Container>
    );
  }
}

const LogStructureSetting = injectIntl(LogStructureSettingCore);

export default connect(
  (state: State) => {
    const { loaderStatus } = state.app;
    const { logStructureList, logEntryStructure } = state.settings;
    return { loaderStatus, logStructureList, logEntryStructure };
  },
  { loadProjectLogStructure, saveLogEntryStructure, removeLogEntryStructure },
)(LogStructureSetting);
