/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { get } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getSystemGeneralInfo = (credentials: Credentials, params: Object) => {
  const requests = [];
  requests.push(
    fetchGet(getEndpoint('getversion'), {
      ...credentials,
    }).then((data) => {
      const engine = R.replace(/\n/, '', get(data, 'version', ''));
      return {
        engine,
      };
    }),
  );
  requests.push(
    fetchGet(getEndpoint('license'), {
      ...credentials,
    }).then((data) => {
      const { success, licenseInfo } = data || {};
      let licenseMap = {};
      try {
        licenseMap = JSON.parse(licenseInfo);
      } catch (err) {}
      const isEffective = Boolean(success && licenseMap.licensee);
      licenseMap = { ...licenseMap, success, isEffective };
      return {
        licenseInfo: licenseMap,
      };
    }),
  );

  return Promise.all(requests).then((results) => {
    const { engine } = results[0] || {};
    const { licenseInfo } = results[1] || {};
    return {
      engine,
      licenseInfo,
    };
  });
};

export default getSystemGeneralInfo;
