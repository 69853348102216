/* @flow */
import { get } from 'lodash';

/* eslint-disable no-console */
import {
  ContentTypeError,
  HttpError,
  NetworkError,
  BadRequestError,
  NoAuthError,
  PermissionError,
  NotFoundError,
} from '../errors';

const methods = ['POST', 'PUT'];

const getErrMsg = async (resp) => {
  let errMsg;
  let responseJson;

  const response = await resp.text();
  try {
    responseJson = JSON.parse(response);
    errMsg = get(responseJson, 'message', resp.statusText);
  } catch {
    errMsg = response || resp.statusText;
  }

  return { errMsg, responseJson };
};

/**
 * General response handler for fetch command.
 * If isJsonResponse is <c>true</c>, the forat should be:
 * {
 *   success: true|false
 *   data: object,  // Data object if success is true.
 *   error: string // Error messages if success is false.
 * }
 *
 * @param {Promise} command The promise of the fetch command.
 */
const fetchHandler = (command: Object, isJsonResponse: Boolean = true, option, num = 0) => {
  const cmd = command
    .catch((err) => {
      throw new NetworkError(err);
    })
    .then(async (resp) => {
      if (resp.ok || (resp.status >= 200 && resp.status <= 300)) {
        try {
          if (resp.status === 204) return '';

          const res = resp.clone();
          const { message } = await res.json();
          if ((message || '').includes('Invalid token')) {
            window.localStorage.removeItem('tokenExpiredTime');
            window.localStorage.removeItem('refreshTokenFnTime');
          }
        } catch (error) {
          return resp;
        }
        return resp;
      } else if (resp.status === 422) {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new HttpError(errMsg, resp.status, resp, responseJson);
      } else if (resp.status === 400) {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new BadRequestError(errMsg, resp.status, resp, responseJson);
      } else if (resp.status === 401) {
        const { url } = resp;
        if (num === 3 || (url && url.indexOf('/api/v1/login-check') >= 0)) {
          const { errMsg, responseJson } = await getErrMsg(resp);
          throw new NoAuthError(errMsg, resp.status, resp, responseJson);
        } else {
          if (methods.includes(option.method)) {
            option.headers['X-CSRF-Token'] = window.localStorage.getItem('X-CSRF-Token');
          }
          return fetchHandler(fetch(resp.url, option), isJsonResponse, option, num + 1);
        }
      } else if (resp.status === 403) {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new PermissionError(errMsg, resp.status, resp, responseJson);
      } else if (resp.status === 404) {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new NotFoundError(errMsg, resp.status, resp, responseJson);
      } else {
        const { errMsg, responseJson } = await getErrMsg(resp);
        throw new HttpError(errMsg, resp.status, resp, responseJson);
      }
    });

  if (isJsonResponse) {
    return cmd.then((resp) => {
      if (resp && !resp?.url && !resp?.headers && !resp?.status) {
        return resp;
      }

      if (resp.redirected && (resp.url.indexOf('redirect_uri') > 0 || resp.url.indexOf('state=') > 0)) {
        return { url: resp.url };
      }

      if (resp.headers && resp.url.includes('/projects/groupMapping') && resp.headers.get('Mappingfilename')) {
        const mappingfilename = resp.headers.get('Mappingfilename');
        const instancename = resp.headers.get('Instancename');
        const componentname = resp.headers.get('Componentname');
        const instanceAndComponentname = resp.headers.get('Instanceandcomponentname');

        const data = {};
        let json = {};
        try {
          json = resp.json();
        } catch (e) {
          console.warn(e);
        }
        if (mappingfilename) data.mappingfilename = mappingfilename;
        if (instancename) data.instancename = instancename;
        if (componentname) data.componentname = componentname;
        if (instanceAndComponentname) data.instanceAndComponentname = instanceAndComponentname;
        return { ...data, status: resp.status, statusText: resp.statusText, jsonData: json };
      }

      const contentType = resp.headers ? resp.headers.get('Content-Type') : undefined;
      const ctype = contentType ? contentType.split(';')[0] : undefined;
      const expect = 'application/json';
      if (ctype !== expect) {
        return {
          status: resp.status || 204,
          statusText: String(new ContentTypeError(expect, ctype, resp), resp.status),
          success: false,
        };
      }
      if (resp.status === 204) {
        return { status: resp.status, statusText: resp.statusText };
      }

      let json = {};
      try {
        if (resp?.url && resp?.url?.includes('/uploadmetricfile')) {
          json = resp.text();
        } else {
          json = resp.json();
        }
      } catch (e) {
        console.warn(e);
      }
      return json;
    });
  }

  return cmd;
};

export default fetchHandler;
