import React, { useReducer, useRef } from 'react';
import * as R from 'ramda';
import { Alert, Button, message, Select, Spin, Switch } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Modal, Popover } from '../../../lib/fui/react';
import { EventRenderers, parseLocation } from '../../../common/utils';

import { appButtonsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

const channelNameMap = {
  Teams: 'Teams channel',
  Slack: 'Slack channel',
};

export default function ModalRenderContent({ title, onClose, props, rowData, statusColorMap, statusOptions }: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isLoading: false,
    incidentStatus: rowData?.incidentStatus,
  });
  const deleteIncidentCheckedMap = useRef({});
  const deleteTeamsChannelModal = useRef(null);
  const { isLoading, incidentStatus } = state;
  const { intl, credentials, timezoneOffset, isReadUser, updateLastActionInfo, location } = props || {};
  const { environmentId, systemId } = parseLocation(location);
  const { projectOwner, investigationReporterRecordStatus, anomalyLogInstance, instanceName } = rowData || {};
  const { incidentTimestamp } = rowData || {};
  let { projectName } = rowData || {};
  projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;

  const handleTeamsDeteleConfirm = (rowData, close, channels) => {
    if (deleteTeamsChannelModal.current) {
      deleteTeamsChannelModal.current.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }

    const requests = [];
    R.forEachObjIndexed((val, channelType) => {
      if (val) {
        if (channelType === 'Teams') {
          requests.push(
            fetchPost(getEndpoint('delete-teams'), {
              ...credentials,
              environmentName: environmentId,
              systemName: systemId,
              customerName: projectOwner,
              projectName,
              instanceName,
              incidentTimestamp,
            }),
          );
        } else if (channelType === 'Slack') {
          requests.push(
            fetchPost(getEndpoint('delete-slack'), {
              ...credentials,
              environmentName: environmentId,
              systemName: systemId,
              customerName: projectOwner,
              projectName,
              instanceName,
              incidentTimestamp,
            }),
          );
        }
      }
    }, deleteIncidentCheckedMap.current);

    updateLastActionInfo();
    Promise.all(requests)
      .then((results) => {
        const success = R.reduce(
          R.and,
          true,
          R.map((item) => item.success, results),
        );
        const errMsg = R.reduce(
          R.or,
          '',
          R.map((item) => item.message, results),
        );
        if (success) {
          close();
          setState({ isLoading: false });
          onClose(true);
        } else if (deleteTeamsChannelModal.current) {
          deleteTeamsChannelModal.current.update({
            content: (
              <div>
                <div>
                  <div>
                    {R.map((channel) => {
                      const { channelType, channelName } = channel || {};
                      return (
                        <div key={channelType} className="flex-row">
                          <div className="flex-grow flex-min-width">
                            <span className="light-label bold" style={{ marginRight: 4 }}>
                              {channelNameMap[channelType] || channelType}:
                            </span>{' '}
                            {channelName}
                          </div>
                          <Switch
                            size="small"
                            style={{ width: 50 }}
                            checkedChildren={intl.formatMessage(appButtonsMessages.yes)}
                            unCheckedChildren={intl.formatMessage(appButtonsMessages.no)}
                            defaultChecked={deleteIncidentCheckedMap.current[channelType] || false}
                            onChange={(checked) => {
                              deleteIncidentCheckedMap.current[channelType] = checked;
                              if (deleteTeamsChannelModal.current) {
                                const hasChecked = R.reduce(R.or, false, R.values(deleteIncidentCheckedMap.current));
                                deleteTeamsChannelModal.current.update({
                                  okButtonProps: { disabled: !hasChecked },
                                });
                              }
                            }}
                          />
                        </div>
                      );
                    }, channels)}
                  </div>
                </div>
                <Alert message={errMsg} type="error" style={{ marginTop: 8 }} />
              </div>
            ),
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      })
      .catch((error) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        if (deleteTeamsChannelModal.current) {
          deleteTeamsChannelModal.current.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      });
  };

  const handleTeamsDetele = (rowData, channels) => {
    deleteTeamsChannelModal.current = Modal.confirm({
      title: intl.formatMessage(eventMessages.deleteTeamsChannelConfirm),
      content: (
        <div>
          {R.map((channel) => {
            const { channelType, channelName } = channel || {};
            return (
              <div key={channelType} className="flex-row">
                <div className="flex-grow flex-min-width">
                  <span className="light-label bold" style={{ marginRight: 4 }}>
                    {channelNameMap[channelType] || channelType}:
                  </span>{' '}
                  {channelName}
                </div>
                <Switch
                  size="small"
                  style={{ width: 50 }}
                  checkedChildren={intl.formatMessage(appButtonsMessages.yes)}
                  unCheckedChildren={intl.formatMessage(appButtonsMessages.no)}
                  defaultChecked={deleteIncidentCheckedMap.current[channelType] || false}
                  onChange={(checked) => {
                    deleteIncidentCheckedMap.current[channelType] = checked;
                    if (deleteTeamsChannelModal.current) {
                      const hasChecked = R.reduce(R.or, false, R.values(deleteIncidentCheckedMap.current));
                      deleteTeamsChannelModal.current.update({
                        okButtonProps: { disabled: !hasChecked },
                      });
                    }
                  }}
                />
              </div>
            );
          }, channels)}
        </div>
      ),
      okButtonProps: { disabled: true },
      onCancel: () => {
        if (deleteTeamsChannelModal.current) deleteTeamsChannelModal.current.destroy();
        setState({ isLoading: false });
        onClose(true);
      },
      onOk: (close) => {
        const hasChecked = R.reduce(R.or, false, R.values(deleteIncidentCheckedMap.current));
        if (hasChecked) {
          handleTeamsDeteleConfirm(rowData, close, channels);
        } else {
          close();
          setState({ isLoading: false });
          onClose(true);
        }
      },
    });
  };

  const handleTeamsCheckAndDetele = async (rowData) => {
    updateLastActionInfo();
    const channels = await fetchGet(getEndpoint('incident-channel-info'), {
      ...credentials,
      customerName: projectOwner,
      projectName,
      instanceName,
      incidentTimestamp,
    })
      .then((data) => {
        return data || [];
      })
      .catch((error) => {
        return [];
      });
    if (channels.length > 0) {
      // delete teams channel
      handleTeamsDetele(rowData, channels);
    } else {
      setState({ isLoading: false });
      onClose(true);
    }
  };

  const onChangeIncidentStatus = (value) => {
    setState({ incidentStatus: value, isLoading: true });

    updateLastActionInfo();
    fetchPost(getEndpoint('incidentInvestigation'), {
      ...credentials,
      projectName,
      instanceName: anomalyLogInstance || instanceName,
      timestamp: incidentTimestamp,
      status: value,
    })
      .then((data) => {
        // delete teams channel if exist
        if (value === 'closed') {
          handleTeamsCheckAndDetele(rowData);
        } else {
          setState({ isLoading: false });
          onClose(true);
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        setState({ incidentStatus: rowData?.incidentStatus, isLoading: false });
      });
  };

  return (
    <Modal
      visible
      title={title}
      onCancel={() => onClose()}
      footer={
        <div>
          <Button size="small" onClick={() => onClose()}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>
        </div>
      }
    >
      <div className="flex-row flex-center-align">
        <div style={{ marginRight: 8 }}>{title}:</div>
        <Popover
          title={null}
          content={
            isReadUser ? (
              intl.formatMessage(eventMessages.isReadUserDisable)
            ) : (
              <EventRenderers.RenderIncidentStatusTooltipContent
                intl={intl}
                credentials={credentials}
                timezoneOffset={timezoneOffset}
                reporterRecordSet={investigationReporterRecordStatus}
                statusColorMap={statusColorMap}
                incident={rowData}
                updateLastActionInfo={updateLastActionInfo}
              />
            )
          }
          mouseEnterDelay={0.3}
          placement="right"
        >
          <Spin size="small" spinning={!!isLoading} wrapperClassName="spin-full-width full-width">
            <Select
              showArrow={false}
              size="small"
              style={{ width: '100%' }}
              value={incidentStatus}
              onChange={onChangeIncidentStatus}
              disabled={isReadUser}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
              className={
                incidentStatus === 'in progress'
                  ? 'select-progress'
                  : incidentStatus === 'closed'
                  ? 'select-closed'
                  : ''
              }
            >
              {R.map((item) => {
                return (
                  <Select.Option key={item.value}>
                    <span style={{ color: 'var(--text-color)' }}>{item.label}</span>
                  </Select.Option>
                );
              }, statusOptions)}
            </Select>
          </Spin>
        </Popover>
      </div>
    </Modal>
  );
}
