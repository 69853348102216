import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { getLoaderEpicAction, pickNotNil } from '../../utils';
import { postPatternFlag } from '../../apis';
import { setLogPatternFlag } from '../actions';
import { updateLastActionInfo } from '../../app/actions';
import { setModalErrorMessage } from '../../metric/actions';
import { appMessages } from '../../app/messages';
import { Defaults } from '../../app';

const setLogPatternFlagEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('UPDATE_LOG_PATTERN_FLAG').concatMap((action) => {
    const { loader } = action.payload;
    const params = pickNotNil(action.payload.params || {});
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(postPatternFlag(credentials, params))
      .concatMap(() => {
        return Observable.concat(Observable.of(setLogPatternFlag(params)), hideLoader);
      })
      .catch((err) => {
        const message = err.message || {};
        return Observable.concat(
          Observable.of(setModalErrorMessage(appMessages.errorsMessage, { message })),
          hideLoader,
          Observable.of(setModalErrorMessage(null)).delay(Defaults.ErrorHideDelay),
        );
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default setLogPatternFlagEpic;
