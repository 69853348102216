import React from 'react';
import * as R from 'ramda';
import { floor } from 'lodash';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SystemWidgetsSortableGrid = SortableContainer(({ items, width, height }) => {
  const columnCount = width > 1300 ? 4 : 3;
  const cardWidth = floor((width - 17) / columnCount);
  return (
    <div className="flex-row flex-wrap" style={{ width, minHeight: 280 }}>
      {R.addIndex(R.map)((item, index) => {
        return <SortableItemGrid key={index} index={index} item={item} cardWidth={cardWidth} />;
      }, items)}
    </div>
  );
});

const SortableItemGrid = SortableElement(({ item, cardWidth }) => {
  return <div style={{ width: cardWidth - 48, minWidth: 260, height: 230, margin: '16px 24px' }}>{item}</div>;
});

export default SystemWidgetsSortableGrid;
