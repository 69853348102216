/* @flow */
/* eslint-disable no-console */
import * as R from 'ramda';
import type { MetricState, Action } from '../types';
import { ActionTypes } from './actions';

const initialState: MetricState = {
  currentHourlyEvents: null,
  currentHourlyEventsLoading: false,
  currentWeeklyAnomalies: null,
  currentErrorMessage: null,

  eventListMap: {},
  eventLogListMap: {},
  eventCausalResult: {},

  primaryError: null,
  secondaryError: null,
  modalErrorMessage: null,

  eventSummary: {},
  eventSummaryParams: {},

  currentDateWindow: undefined,
  appForecastStats: {},
  appForecastData: {},

  lineChartsSummary: null,
  lineChartsSelectedMetricList: [],
  lineChartsSelectedInstanceList: [],
  lineChartsMetricDataList: [],
  eventLineChartData: null,
  datasetInstanceBaselineList: [],
  eventLineChartBaselineData: null,

  eventLoadingPercentage: 0,
  baseLineLoadingPercentage: 0,
};

const reducer = (state: MetricState = initialState, action: Action): MetricState => {
  if (action.type === ActionTypes.SET_METRIC_LINECHARTS_SUMMARY) {
    return { ...state, ...action.payload.data };
  } else if (action.type === ActionTypes.SET_METRIC_LINECHARTS_SELECTION) {
    return { ...state, ...action.payload.data };
  } else if (action.type === ActionTypes.SET_METRIC_LINECHARTS) {
    return { ...state, ...action.payload.data };
  } else if (action.type === ActionTypes.SET_METRIC_LINECHARTS_DETAILS) {
    return { ...state, ...action.payload.data };
  } else if (action.type === ActionTypes.SET_METRIC_EVENT_LINECHARTS) {
    const eventLineChartData = action.payload.data;
    return { ...state, eventLineChartData };
  } else if (action.type === ActionTypes.SET_METRIC_EVENT_LINECHARTS_PROGRESS) {
    const eventLoadingPercentage = action.payload.data;
    return { ...state, eventLoadingPercentage };
  } else if (action.type === ActionTypes.SET_METRIC_EVENT_LINECHARTS_BASELINE_PROGRESS) {
    const baseLineLoadingPercentage = action.payload.data;
    return { ...state, baseLineLoadingPercentage };
  } else if (action.type === ActionTypes.SET_METRIC_EVENT_LINECHARTS_BASELINE) {
    const { datasetInstanceBaselineList, eventLineChartBaselineData } = action.payload.data || {};
    return { ...state, datasetInstanceBaselineList, eventLineChartBaselineData };
  } else if (action.type === 'SET_EVENTLIST') {
    const { eventListMap } = action.payload.data || {};
    return {
      ...state,
      eventListMap: eventListMap || {},
    };
  } else if (action.type === 'SET_METRIC_EVENT_LOGLIST') {
    return {
      ...state,
      eventLogListMap: action.payload,
    };
  } else if (action.type === 'SET_ANOMALY_LOG_EVENTS') {
    const { data } = action.payload;
    return {
      ...state,
      eventLogListMap: data,
    };
  } else if (action.type === 'SET_METRIC_EVENT_CAUSALRESULT') {
    return {
      ...state,
      eventCausalResult: action.payload.data || {},
    };
  } else if (action.type === 'SET_METRIC_PATTERN_FLAG') {
    const { params } = action.payload;
    const { nid, ignoreFlag } = params;
    const { eventListMap } = state;
    const { eventList } = eventListMap;

    if (nid) {
      if (eventList) {
        R.forEach((e) => {
          if (e.neuronId === nid) {
            e.ignoreFlag = ignoreFlag;
          }
        }, eventList);
      }
      const newEventListMap = { ...eventListMap, eventList };

      return {
        ...state,
        eventListMap: newEventListMap,
      };
    }

    return state;
  } else if (action.type === 'SET_ERROR_MESSAGE') {
    const { isPrimary, message, params } = action.payload;
    if (isPrimary) {
      return { ...state, primaryError: message ? { message, params } : null };
    }

    return { ...state, secondaryError: message ? { message, params } : null };
  } else if (action.type === 'SET_MODAL_ERROR_MESSAGE') {
    const { message, params } = action.payload;
    const modalErrorMessage = message ? { message, params } : null;
    return { ...state, modalErrorMessage };
  } else if (action.type === 'SET_METRIC_APP_FORECAST_DATE_WINDOW') {
    const { dateWindow } = action.payload;
    return {
      ...state,
      currentDateWindow: dateWindow,
    };
  } else if (action.type === 'SET_METRIC_APP_FORECAST_DATA') {
    const { params, data } = action.payload;
    return {
      ...state,
      appForecastData: { params, data },
    };
  } else if (action.type === 'SET_METRIC_EVENT_SUMMARY') {
    const { view, params, info } = action.payload;
    const { eventSummaryParams, eventSummary } = state;

    return {
      ...state,
      eventSummaryParams: {
        ...eventSummaryParams,
        [view]: params,
      },
      eventSummary: {
        ...eventSummary,
        [view]: info,
      },
    };
  } else if (action.type === 'SET_METRIC_ANOMALY') {
    const { data } = action.payload;
    const { anomalyInfo } = data || {};
    return {
      ...state,
      anomalyInfo,
    };
  } else if (action.type === 'SET_METRIC_REAL_CAPACITY') {
    const { data } = action.payload;
    const { anomalyInfo } = data || {};
    return {
      ...state,
      anomalyInfoRealTime: anomalyInfo,
    };
  } else if (action.type === 'SET_METRIC_CAPACITY_PLANNING') {
    const { data } = action.payload;
    const { capacityPlanningCalendarInfo, capacityPlanningCalendarRealInfo } = data || {};
    return {
      ...state,
      capacityPlanningCalendarInfo,
      capacityPlanningCalendarRealInfo,
    };
  } else if (action.type === 'SET_EVENTS_HISTORY') {
    const { data } = action.payload;
    const { eventsHistory } = data || {};
    return {
      ...state,
      eventsHistory,
    };
  } else if (action.type === 'SET_JIRA_PROJECT_LISTS') {
    const { data } = action.payload;
    const { jiraProjects } = data || {};
    return {
      ...state,
      jiraProjects,
    };
  } else if (action.type === 'SET_JIRA_PROJECT_ASSIGNER_LISTS') {
    const { data } = action.payload;
    const { jiraProjectAssigners } = data || {};
    return {
      ...state,
      jiraProjectAssigners,
    };
  } else if (action.type === ActionTypes.SET_EVENT_ALERT) {
    const { data } = action.payload;
    const { emailAlert } = data || {};
    return { ...state, emailAlert };
  } else if (action.type === ActionTypes.SET_EVENT_ACTION) {
    const { data } = action.payload;
    const { certainPatternList, actionList, emailAlert, projectEmailSettingMap, actionProxyServers } = data || {};
    return {
      ...state,
      certainPatternList,
      eventActionList: actionList,
      emailAlert,
      projectEmailSettingMap,
      actionProxyServers,
    };
  }
  return { ...initialState, ...state };
};

export default reducer;
