/* @flow */

import { defineMessages } from 'react-intl';

const appMessages = defineMessages({
  errorsMessage: {
    defaultMessage: '{message}',
    id: 'app.errors.message',
  },
  errorsServer: {
    defaultMessage: "Sorry, we've had a server error, Please refresh and try again.",
    id: 'app.errors.server',
  },
  errorsNetwork: {
    defaultMessage: 'Connection timeout, please network connection.',
    id: 'app.errors.network',
  },
  errorsNotFound: {
    defaultMessage: 'The request resource does not exist',
    id: 'app.errors.notFound',
  },
  errorsBadRequest: {
    defaultMessage: 'The request parameters are incorrect, please check your input.',
    id: 'app.errors.badRequest',
  },
  errorsInvalidData: {
    defaultMessage: 'The data is invalid, please try again.',
    id: 'app.errors.invalidData',
  },
  errorsGeneral: {
    defaultMessage: 'There is something wrong, please try again.',
    id: 'app.errors.general',
  },
  errorsProjectNotFound: {
    defaultMessage: "The project {projectName} doesn't exist. Select another project.",
    id: 'app.errors.projectNotFound',
  },
  errorsInstanceGroupNotFound: {
    defaultMessage: "The group {instanceGroup} doesn't exist. Select another project or group.",
    id: 'app.errors.instanceGroupNotFound',
  },
  errorsNoLogProject: {
    defaultMessage: 'There is no log type project, please go to <a>Settings</a> to add a new one.',
    id: 'app.errors.noLogProject',
  },
  errorsNoLogInstance: {
    defaultMessage: 'There is no instance has log entries during the time period, please choose another time period',
    id: 'app.errors.noInstance',
  },
  errorsAPIMessage: {
    defaultMessage: '{message}',
    id: 'app.errors.apiMessage',
  },
  timelineDetectedEvents: {
    defaultMessage: 'events are detected during {start} ~ {end}',
    id: 'app.timelineDetectedEvents',
  },
  timelinePredictedEvents: {
    defaultMessage: 'events are predicted during {start} ~ {end}',
    id: 'app.timelinePredictedEvents',
  },
  userAccountInformation: {
    defaultMessage: 'User Account Information',
    id: 'app.userAccountInformation',
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'app.submit',
  },
  apiSuccess: {
    defaultMessage: 'Action completed',
    id: 'app.apiSuccess',
  },
  apiSubmit: {
    defaultMessage: 'Actions have been submitted',
    id: 'app.apiSubmit',
  },
  apiFaild: {
    defaultMessage: 'An error has occurred, please try again',
    id: 'app.apiFaild',
  },
  dynatraceVerificationSuccessful: {
    defaultMessage:
      'Congratulations the key verification is successful, we will send you an email when the data is available to ingest',
    id: 'app.dynatraceVerificationSuccessful',
  },
  dynatraceVerificationError: {
    defaultMessage: 'Please validate your envrionment ID and token.',
    id: 'app.dynatraceVerificationError',
  },
  licenseValidFaild: {
    defaultMessage:
      'Your InsightFinder license is expired. Please contact your InsightFinder representative to renew or extend your license.',
    id: 'app.licenseValidFaild',
  },
  licenseWillExpired: {
    defaultMessage:
      'Your InsightFinder license will expire at {date}. Please contact your InsightFinder representative to renew or extend your license.',
    id: 'app.licenseWillExpired',
  },
  userNotFound: {
    defaultMessage: 'User does not exist.',
    id: 'app.userNotFound',
  },
  messageTitle: {
    defaultMessage: `This is a {type} message`,
    id: 'app.messageTitle',
  },
  continueConfirm: {
    defaultMessage: `Are you sure you would like to continue?`,
    id: 'app.continueConfirm',
  },
  previousConfirm: {
    defaultMessage: `Are you sure you want to overwrite the previous file?`,
    id: 'app.previousConfirm',
  },
  waitingDownload: {
    defaultMessage: `In the process of retrieving data...`,
    id: 'app.waitingDownload',
  },
  goodMorning: {
    defaultMessage: `Good morning {userName}!`,
    id: 'app.goodMorning',
  },
  goodAfternoon: {
    defaultMessage: `Good afternoon {userName}!`,
    id: 'app.goodAfternoon',
  },
  goodEvening: {
    defaultMessage: `Good evening {userName}!`,
    id: 'app.goodEvening',
  },
  uploadAvatar: {
    defaultMessage: 'Upload Avatar',
    id: 'app.uploadAvatar',
  },
  uploadAvatarWithJPG: {
    defaultMessage: 'You can only upload JPG/PNG file!',
    id: 'app.uploadAvatarWithJPG',
  },
  uploadAvatarWithLimit: {
    defaultMessage: 'Image must smaller than 100KB!',
    id: 'app.uploadAvatarWithLimit',
  },
  clickToReload: {
    defaultMessage: `Please click this button to reload data!`,
    id: 'app.clickToReload',
  },
  projectNotBelongUser: {
    defaultMessage: `The project {projectName} does not belong to the user {userName}!`,
    id: 'app.projectNotBelongUser',
  },
  removeProjectAllRule: {
    defaultMessage: `This action will delete rules belong to this project: {projectName}.`,
    id: 'app.removeProjectAllRule',
  },
  updateUserInfo: {
    defaultMessage: `Hi {userName}, please update your user information.`,
    id: 'app.updateUserInfo',
  },
  dashBoardWidgetSame: {
    defaultMessage: `The same widgets exist and their systems are also the same.`,
    id: 'app.dashBoardWidgetSame',
  },
});

export default appMessages;
