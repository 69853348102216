import React, { useContext, useEffect, useState, useRef } from 'react';
import * as R from 'ramda';
import { get, round } from 'lodash';
import { Checkbox, Select, Space, Spin, Tabs } from 'antd';
import { AutoSizer } from 'react-virtualized';
import { CheckCircleFilled } from '@ant-design/icons';

import {
  LogSensitivitySetting,
  AlertSensitivitySetting,
  MetricSetting,
  KeywordsSetting,
} from '../../project/components';
import { settingsMessages } from '../../../../common/settings/messages';
import OperationButtons from './OperationButtons';
// eslint-disable-next-line import/no-cycle
import { BatchContext } from '../pageContext';
import Step5 from './Step5';
import { Modal } from '../../../../lib/fui/react';
import fetchGet from '../../../../common/apis/fetchGet';
import getEndpoint from '../../../../common/apis/getEndpoint';

type Props = {
  onCancel: Function,
  onPrev: Function,
  pageData: Object,
  onSetPageData: Function,
};
/**
 * updateStatus {String}
 * tab: children component no update
 * projectChange: children component to update
 */

const handleNaNConvert = (data) => {
  const replaceStr = R.replace(/NaN/g, null, data || '{}');
  return JSON.parse(replaceStr, (key, value) => {
    if (value === null) return 'None';
    if (R.is(String, value) && value === 'null') return 'None';
    return value;
  });
};

const Step4 = (props: Props) => {
  const { onPrev, pageData, onSetPageData, onCancel } = props;
  const {
    pageType,
    dataType,
    metricData,
    logData,
    logLabels,
    needRemoveCategory,
    selectedProjects,
    applyProject,
    updateStatus,
    loading,
  } = pageData;
  const context = useContext(BatchContext);
  const [data, setData] = useState({});
  const [metricList, setMetricList] = useState([]);
  const [logKeywordslist, setLogKeywordsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [componentMetricData, setComponentMetricData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasNotInteger, setHasNotInteger] = useState(false);

  const [currentTab, setCurrentTab] = useState('sensitivity');
  const cmpRef = useRef({});
  const isEdit = R.equals(pageType, 'edit');
  const { intl, allProjects, credentials } = context;
  const projectOptions = [];
  R.forEach(({ projectName, projectShortName }) => {
    const findProjectInfo = R.find((p) => p.projectShortName === (projectShortName || projectName), allProjects || []);
    if (findProjectInfo)
      projectOptions.push({
        label: findProjectInfo?.projectDisplayName || projectShortName || projectName,
        value: projectShortName || projectName,
        samplingInterval: findProjectInfo?.samplingInterval || 0,
      });
  }, selectedProjects);
  if (isEdit) {
    projectOptions.unshift({ label: 'Previous Data', value: 'previousData' });
  }

  const keyFormatter = (threshold) => {
    const data = R.is(String, threshold)
      ? threshold === 'None'
        ? { upperBound: 'None', lowerBound: 'None' }
        : handleNaNConvert(threshold)
      : threshold;
    const { upperBound, lowerBound } = data;
    return {
      upperBound: upperBound.toString().toLowerCase() === 'nan' ? 'None' : upperBound,
      lowerBound: lowerBound.toString().toLowerCase() === 'nan' ? 'None' : lowerBound,
    };
  };

  const formatLabels = (diff) => {
    const labelList = R.map((n) => {
      const { type, keyword } = n;
      if (type === 'fieldName') {
        const keywordSet = keyword.split('=');
        return { ...n, jsonKey: keywordSet[0], keyword: keywordSet[1] };
      }
      return n;
    }, diff);
    return labelList;
  };

  const parseMetricList = ({ commonMetricListSet, commonMetricModelSet }) => {
    const metricList = R.map(({ metricName, metricType: mType }) => {
      const data = R.find((item) => item.key.metricName === metricName, commonMetricListSet) || {};
      const dataKey = get(data, 'key', { metricName });
      const computeDifference = get(data, 'computeDifference', false);
      const enableBaselineNearConstance = get(data, 'enableBaselineNearConstance', false);
      const detectionType = get(data, 'detectionType', '');
      const isKPI = get(data, 'kpi', false);
      const kpiDurationThreshold = get(data, 'kpiDurationThreshold', 0);
      const metricType = get(data, 'metricType', mType);
      const modified = get(data, 'modified', false);
      const smetric = get(data, 'smetric', metricName);
      const fillZero = get(data, 'fillZero', false);

      // const specialInterval = get(data, 'specialInterval', 0);
      const anomalyDampening = get(data, 'anomalyDampening', 0);
      const thresholdAlertBound = get(data, 'thresholdAlertBound', { upperBound: 'None', lowerBound: 'None' });
      const thresholdNoAlertBound = get(data, 'thresholdNoAlertBound', { upperBound: 'None', lowerBound: 'None' });
      const thresholdAlertBoundNegative = get(data, 'thresholdAlertBoundNegative', {
        upperBound: 'None',
        lowerBound: 'None',
      });
      const thresholdNoAlertBoundNegative = get(data, 'thresholdNoAlertBoundNegative', {
        upperBound: 'None',
        lowerBound: 'None',
      });

      const thresholdAlertLowerBound = get(keyFormatter(thresholdAlertBound), 'lowerBound');
      const thresholdAlertUpperBound = get(keyFormatter(thresholdAlertBound), 'upperBound');
      const thresholdNoAlertLowerBound = get(keyFormatter(thresholdNoAlertBound), 'lowerBound');
      const thresholdNoAlertUpperBound = get(keyFormatter(thresholdNoAlertBound), 'upperBound');
      const thresholdAlertLowerBoundNegative = get(keyFormatter(thresholdAlertBoundNegative), 'lowerBound');
      const thresholdAlertUpperBoundNegative = get(keyFormatter(thresholdAlertBoundNegative), 'upperBound');
      const thresholdNoAlertLowerBoundNegative = get(keyFormatter(thresholdNoAlertBoundNegative), 'lowerBound');
      const thresholdNoAlertUpperBoundNegative = get(keyFormatter(thresholdNoAlertBoundNegative), 'upperBound');
      return {
        key: dataKey,
        computeDifference,
        enableBaselineNearConstance,
        detectionType,
        isKPI,
        fillZero,
        kpiDurationThreshold: kpiDurationThreshold / 60000,
        metricType,
        modified,
        smetric,
        // specialInterval,
        anomalyDampening: anomalyDampening / 60000,
        thresholdAlertLowerBound,
        thresholdAlertUpperBound,
        thresholdNoAlertLowerBound,
        thresholdNoAlertUpperBound,
        thresholdAlertLowerBoundViewVal: thresholdAlertLowerBound,
        thresholdAlertUpperBoundViewVal: thresholdAlertUpperBound,
        thresholdNoAlertLowerBoundViewVal: thresholdNoAlertLowerBound,
        thresholdNoAlertUpperBoundViewVal: thresholdNoAlertUpperBound,
        thresholdAlertUpperBoundNegative,
        thresholdAlertLowerBoundNegative,
        thresholdNoAlertUpperBoundNegative,
        thresholdNoAlertLowerBoundNegative,
        thresholdAlertLowerBoundNegativeViewVal: thresholdAlertLowerBoundNegative,
        thresholdAlertUpperBoundNegativeViewVal: thresholdAlertUpperBoundNegative,
        thresholdNoAlertLowerBoundNegativeViewVal: thresholdNoAlertLowerBoundNegative,
        thresholdNoAlertUpperBoundNegativeViewVal: thresholdNoAlertUpperBoundNegative,
      };
    }, commonMetricModelSet);
    return metricList;
  };

  const getComponentmetricupdate = () => {
    const selectProjectInfo = R.find((item) => {
      let flag = item.projectShortName === applyProject;
      if (applyProject === 'previousData') {
        flag =
          item.projectShortName === R.filter((p) => p.value !== 'previousData', projectOptions || [])[0]?.value || '';
      }
      return flag;
    }, allProjects || []);
    if (!selectProjectInfo) return;

    // eslint-disable-next-line consistent-return
    return fetchGet(getEndpoint('projects/componentmetricupdate'), {
      ...credentials,
      projectName:
        selectProjectInfo.projectName.indexOf('@') >= 0
          ? selectProjectInfo.projectName
          : `${selectProjectInfo.projectName}@${selectProjectInfo.owner}`,
      start: 0,
      limit: 1000000,
    })
      .then((data) => {
        const possibleMetricType = get(data, 'possibleMetricType', []);
        return { possibleMetricType };
      })
      .catch((err) => {
        console.error(err.message || String(err));
        return {};
      });
  };

  const parseMetricData = async (raw) => {
    const { commonMetricModelSet, componentMetricSettingSortByProject, settingList } = raw;
    setIsLoading(true);

    const commonMetricListSet = R.flatten(R.values(componentMetricSettingSortByProject[applyProject] || {}));
    const defaultSettingValue = settingList[applyProject] || {};
    const { cValue, pValue, causalPredictionSetting, rootCauseRankSetting, samplingInterval } = defaultSettingValue;
    defaultSettingValue.cValue = cValue;
    defaultSettingValue.pValue = pValue;
    defaultSettingValue.causalPredictionSetting = causalPredictionSetting || 0;
    defaultSettingValue.rootCauseRankSetting = rootCauseRankSetting || 0;
    defaultSettingValue.ignoreInstanceForKB = get(defaultSettingValue, 'ignoreInstanceForKB', false);
    defaultSettingValue.samplingInterval = parseInt(samplingInterval || 0, 10);
    defaultSettingValue.predictionCorrelationSensitivity = String(
      get(defaultSettingValue, 'predictionCorrelationSensitivity', 0.5),
    );
    defaultSettingValue.causalPredictionSetting = String(get(defaultSettingValue, 'causalPredictionSetting', 0));
    defaultSettingValue.rootCauseRankSetting = String(get(defaultSettingValue, 'rootCauseRankSetting', 0));
    // metric list
    const metricList = parseMetricList({
      commonMetricListSet,
      commonMetricModelSet: commonMetricModelSet[R.equals(applyProject, 'previousData') ? applyProject : 'current'],
    });

    const componentMetricData = await getComponentmetricupdate();
    setComponentMetricData(componentMetricData);
    setMetricList(R.sortWith([R.ascend(R.prop('smetric'))], metricList || []));
    setData(defaultSettingValue);
    setIsLoading(false);
  };

  const parseLogLabels = (logLabels, formatCategory = true) => {
    const {
      blacklistLabels,
      trainingWhitelistLabels,
      extractionBlacklistLabels,
      whitelistLabels,
      featureLabels,
      categoryList,
      logTriageLabelList,
    } = logLabels;

    const keywordsList = {
      categorylist: categoryList,
      extractionBlacklist: extractionBlacklistLabels,
      featurelist: featureLabels,
      trainingBlacklist: blacklistLabels,
      trainingWhitelist: trainingWhitelistLabels,
      triagelist: logTriageLabelList,
      whitelist: whitelistLabels,
    };
    setLogKeywordsList(keywordsList);
  };

  const parseLogData = (raw) => {
    const { settingList, logCategorySortByProject, logLabelsMapSortByProject } = raw;
    const defaultCategoryList = logCategorySortByProject[applyProject] || [];
    const defaultLabels = logLabelsMapSortByProject[applyProject] || {};
    let defaultSettingValue = settingList[applyProject] || {};
    setIsLoading(true);

    const {
      cValue,
      pValue,
      causalPredictionSetting,
      rootCauseRankSetting,
      modelKeywordSetting,
      keywordSetting,
      logAnomalyEventBaseScore,
      rareAnomalyType,
      coldNumberLimit,
      samplingInterval,
    } = defaultSettingValue;

    defaultSettingValue = {
      ...defaultSettingValue,
      cValue,
      pValue,
      causalPredictionSetting: String(get(defaultSettingValue, 'causalPredictionSetting', 0)),
      rootCauseRankSetting: String(get(defaultSettingValue, 'rootCauseRankSetting', 0)),
      modelKeywordSetting: String(get(defaultSettingValue, 'modelKeywordSetting', 0)),
      keywordSetting: (keywordSetting || '').toString(),
      logAnomalyEventBaseScore: JSON.parse(logAnomalyEventBaseScore || '[]'),
      rareAnomalyType: (rareAnomalyType || '').toString(),
      coldNumberLimit: (coldNumberLimit || '').toString(),
      samplingInterval: parseInt(samplingInterval || 0, 10),
      hotEventDetectionMode: String(get(defaultSettingValue, 'hotEventDetectionMode', 1)),
    };

    setData(defaultSettingValue);
    const {
      whitelistLabels = [],
      trainingWhitelistLabels = [],
      blacklistLabels = [],
      extractionBlacklistLabels = [],
      featureLabels = [],
      logTriageLabelList = [],
    } = defaultLabels;

    let logCategories = R.map((n) => {
      const { category, keywordsSet } = n;
      return { category, keyword: keywordsSet.join(',') };
    }, defaultCategoryList);

    logCategories = R.filter((n) => !R.isEmpty(n.keyword), logCategories);

    parseLogLabels(
      {
        categoryList: logCategories,
        blacklistLabels: formatLabels(blacklistLabels),
        trainingWhitelistLabels: formatLabels(trainingWhitelistLabels),
        extractionBlacklistLabels: formatLabels(extractionBlacklistLabels),
        whitelistLabels,
        featureLabels,
        logTriageLabelList: formatLabels(logTriageLabelList),
      },
      false,
    );
    setIsLoading(false);
  };

  const handleMetricSensitivity = (settingData) => {
    const sensitivityData = {
      ...settingData,
      cValue: get(settingData, 'cValue'),
      pValue: get(settingData, 'pValue'),
    };
    onSetPageData({
      sensitivityData,
    });
  };

  const handleMetricList = (data) => {
    const isNone = (str = 'none') => {
      const lowerStr = str.toString().toLowerCase();
      return lowerStr === 'none' || lowerStr === 'nan' || lowerStr === 'null' || R.isEmpty(str) || R.isNil(str)
        ? 'NaN'
        : str;
    };

    const metricList = R.map((n) => {
      const {
        isKPI,
        kpiDurationThreshold,
        anomalyDampening,
        thresholdAlertLowerBound,
        thresholdAlertUpperBound,
        thresholdNoAlertLowerBound,
        thresholdNoAlertUpperBound,
        thresholdAlertLowerBoundViewVal,
        thresholdAlertUpperBoundViewVal,
        thresholdNoAlertLowerBoundViewVal,
        thresholdNoAlertUpperBoundViewVal,
        thresholdAlertUpperBoundNegative,
        thresholdAlertLowerBoundNegative,
        thresholdNoAlertUpperBoundNegative,
        thresholdNoAlertLowerBoundNegative,
        thresholdAlertLowerBoundNegativeViewVal,
        thresholdAlertUpperBoundNegativeViewVal,
        thresholdNoAlertLowerBoundNegativeViewVal,
        thresholdNoAlertUpperBoundNegativeViewVal,
        key,
        ...metricListRest
      } = n;
      const toJson = (obj) => JSON.stringify(obj);
      const thresholdAlertBound = toJson({
        upperBound: isNone(thresholdAlertUpperBound),
        lowerBound: isNone(thresholdAlertLowerBound),
      });

      const thresholdNoAlertBound = toJson({
        upperBound: isNone(thresholdNoAlertUpperBound),
        lowerBound: isNone(thresholdNoAlertLowerBound),
      });

      const thresholdAlertBoundNegative = toJson({
        upperBound: isNone(thresholdAlertUpperBoundNegative),
        lowerBound: isNone(thresholdAlertLowerBoundNegative),
      });
      const thresholdNoAlertBoundNegative = toJson({
        upperBound: isNone(thresholdNoAlertUpperBoundNegative),
        lowerBound: isNone(thresholdNoAlertLowerBoundNegative),
      });

      delete metricListRest.escalateIncident;
      return {
        key,
        componentMetricSettingModelStr: JSON.stringify({
          ...metricListRest,
          kpi: isKPI,
          kpiDurationThreshold: round(kpiDurationThreshold * 60000),
          anomalyDampening: round(anomalyDampening * 60000),
          thresholdAlertBound,
          thresholdNoAlertBound,
          thresholdAlertBoundNegative,
          thresholdNoAlertBoundNegative,
        }),
      };
    }, data);

    onSetPageData({
      metricConfiguration: metricList,
    });
  };

  const handleLogSensitivity = (settingData) => {
    const {
      pValue,
      causalPredictionSetting,
      rootCauseRankSetting,
      modelKeywordSetting,
      keywordSetting,
      logAnomalyEventBaseScore,
      rareAnomalyType,
      coldNumberLimit,
    } = settingData;

    const logSensitvity = {
      ...settingData,
      pValue,
      causalPredictionSetting: causalPredictionSetting || 0,
      rootCauseRankSetting: rootCauseRankSetting || 0,
      modelKeywordSetting: (modelKeywordSetting || '').toString(),
      keywordSetting: (keywordSetting || '').toString(),
      logAnomalyEventBaseScore: JSON.parse(logAnomalyEventBaseScore || '[]'),
      rareAnomalyType: (rareAnomalyType || '').toString(),
      coldNumberLimit: (coldNumberLimit || '').toString(),
    };
    onSetPageData({
      sensitivityData: logSensitvity,
    });
  };

  const handleLogLabels = (labels) => {
    const {
      localKeywordsList,
      localKeywordsTrainingWhitelist,
      localKeywordsTrainingBlacklist,
      localKeywordsExtractionBlacklist,
      localKeywordsFeature,
      localKeywordsCategory,
      localKeywordsTriageReport,
    } = labels;

    onSetPageData({
      logLabels: {
        blacklistLabels: localKeywordsTrainingBlacklist,
        trainingWhitelistLabels: localKeywordsTrainingWhitelist,
        extractionBlacklistLabels: localKeywordsExtractionBlacklist,
        whitelistLabels: localKeywordsList,
        featureLabels: localKeywordsFeature,
        categoryList: localKeywordsCategory,
        logTriageLabelList: localKeywordsTriageReport,
      },
    });
  };

  const handleNext = (next = true) => {
    const {
      current: { sensitivity, metricSetting, kerwordSetting },
    } = cmpRef;
    const events = [sensitivity, metricSetting];
    R.forEach((componentInstance) => {
      if (componentInstance && componentInstance.handleSaveClick) {
        const { handleSaveClick } = componentInstance;
        // trigger children submiit
        handleSaveClick();
      }
    }, events);

    // trigger children submiit
    if (dataType === 'Metric') {
      if (!metricSetting) {
        handleMetricList(metricList);
      }
    } else if (kerwordSetting) {
      handleLogLabels(kerwordSetting);
    } else {
      const { logCategorySortByProject, logLabelsMapSortByProject } = logData;
      const defaultCategoryList = logCategorySortByProject[applyProject] || [];
      const defaultLabels = logLabelsMapSortByProject[applyProject] || {};

      const {
        whitelistLabels = [],
        trainingWhitelistLabels = [],
        blacklistLabels = [],
        extractionBlacklistLabels = [],
        featureLabels = [],
        logTriageLabelList = [],
      } = defaultLabels;

      let logCategories = R.map((n) => {
        const { category, keywordsSet } = n;
        return { category, keyword: keywordsSet.join(',') };
      }, defaultCategoryList);
      logCategories = R.filter((n) => !R.isEmpty(n.keyword), logCategories);

      const labels = {
        localKeywordsExtractionBlacklist: logLabels
          ? get(logLabels, 'extractionBlacklistLabels', [])
          : formatLabels(extractionBlacklistLabels),
        localKeywordsList: logLabels ? get(logLabels, 'whitelistLabels', []) : whitelistLabels,
        localKeywordsFeature: logLabels ? get(logLabels, 'featureLabels', []) : featureLabels,
        localKeywordsTriageReport: logLabels
          ? get(logLabels, 'logTriageLabelList', [])
          : formatLabels(logTriageLabelList),
        localKeywordsCategory: logLabels ? get(logLabels, 'categoryList', []) : logCategories,
        localKeywordsTrainingBlacklist: logLabels
          ? get(logLabels, 'blacklistLabels', [])
          : formatLabels(blacklistLabels),
        localKeywordsTrainingWhitelist: logLabels
          ? get(logLabels, 'trainingWhitelistLabels', [])
          : formatLabels(trainingWhitelistLabels),
      };
      handleLogLabels(labels);
    }
    if (next) {
      setShowModal(true);
      onSetPageData({
        updateStatus: 'tab',
      });
    }
  };

  const handlePrev = () => {
    onPrev();
  };

  const handleDeleteCategory = (waitRemoveList, needRemoveCategory) => {
    onSetPageData({
      needRemoveCategory: R.uniqBy((n) => n.category, [...needRemoveCategory, ...waitRemoveList]),
    });
  };

  const handleTabsChange = (key) => {
    setCurrentTab((prevValue) => {
      handleNext(false);
      return key;
    });
    onSetPageData({
      updateStatus: 'tab',
    });
  };

  const handleApplySetting = (e) => {
    const { checked } = e.target;
    onSetPageData({
      touchedSensitivitySetting: checked,
      updateStatus: 'tab',
    });
  };

  const handleApplyExtra = (e) => {
    const { checked } = e.target;
    onSetPageData({
      touchedLogLable: checked,
      touchedComponentMetricSetting: checked,
      updateStatus: 'tab',
    });
  };

  const handleChangeProject = (value) => {
    onSetPageData({ applyProject: value, updateStatus: 'projectChange' });
  };

  useEffect(() => {
    onSetPageData({
      touchedSensitivitySetting: false,
      touchedLogLable: false,
      touchedComponentMetricSetting: false,
    });
  }, []);

  useEffect(() => {
    if (dataType === 'Metric') {
      parseMetricData(metricData);
    } else {
      parseLogData(logData);
    }
  }, [applyProject]);

  const finishProps = { ...props, context };

  const samplingInterval = projectOptions[projectOptions.length - 1]?.samplingInterval || 0;
  return (
    <div className="batch-step-content step-project-setting">
      <AutoSizer disableWidth>
        {({ height }) => {
          const paneStyle = {
            maxHeight: height - 100,
            minHeight: height - 101,
            position: 'absolute',
            width: '100%',
          };
          return (
            <>
              <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
                <div className="flex-grow flex-min-height project-setting-container">
                  <Tabs activeKey={currentTab} onChange={handleTabsChange} type="card">
                    {dataType === 'Metric' && (
                      <>
                        <Tabs.TabPane
                          tab={intl.formatMessage(settingsMessages.sensitivity)}
                          key="sensitivity"
                          style={paneStyle}
                        >
                          <AlertSensitivitySetting
                            intl={intl}
                            entry="batch"
                            data={data}
                            handleMetricSensitivity={handleMetricSensitivity}
                            refs={(c) => {
                              cmpRef.current.sensitivity = c;
                            }}
                            updateStatus={updateStatus}
                          />
                        </Tabs.TabPane>

                        <Tabs.TabPane
                          tab={intl.formatMessage(settingsMessages.metricConfiguration)}
                          key="metricSetting"
                          style={paneStyle}
                        >
                          <MetricSetting
                            intl={intl}
                            entry="batch"
                            batchMetrics={metricList}
                            currentProject={{}}
                            handleMetricList={handleMetricList}
                            refs={(c) => {
                              cmpRef.current.metricSetting = c;
                            }}
                            updateStatus={updateStatus}
                            batchPossibleMetricType={componentMetricData?.possibleMetricType || []}
                            batchSamplingInterval={samplingInterval}
                            setHasNotInteger={setHasNotInteger}
                          />
                        </Tabs.TabPane>
                      </>
                    )}
                    {dataType !== 'Metric' && (
                      <>
                        <Tabs.TabPane
                          tab={intl.formatMessage(settingsMessages.sensitivity)}
                          key="sensitivity"
                          style={paneStyle}
                        >
                          <LogSensitivitySetting
                            intl={intl}
                            entry="batch"
                            data={data}
                            handleLogSensitivity={handleLogSensitivity}
                            refs={(c) => {
                              cmpRef.current.sensitivity = c;
                            }}
                            updateStatus={updateStatus}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab={intl.formatMessage(settingsMessages.labels)}
                          key="kerwordSetting"
                          style={paneStyle}
                        >
                          <KeywordsSetting
                            intl={intl}
                            entry="batch"
                            data={data}
                            keywordsList={logKeywordslist}
                            handleDeleteCategory={(cates) => handleDeleteCategory(cates, needRemoveCategory)}
                            refs={(c) => {
                              cmpRef.current.kerwordSetting = c;
                            }}
                          />
                        </Tabs.TabPane>
                      </>
                    )}
                  </Tabs>
                  <div className="control-setting">
                    <Space size={10} align="center">
                      <Select
                        style={{ width: 180 }}
                        size="small"
                        value={applyProject}
                        defaultActiveFirstOption
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Please select project"
                        options={projectOptions}
                        onChange={handleChangeProject}
                      />
                    </Space>
                  </div>
                </div>
              </Spin>
            </>
          );
        }}
      </AutoSizer>
      <OperationButtons
        needConfirm
        confirContent={
          <div>
            <p>Please confirm which project settings you would like to apply?</p>
            <Checkbox onChange={(e) => handleApplySetting(e)}>Sensitivity</Checkbox>
            <Checkbox onChange={(e) => handleApplyExtra(e)}>
              {dataType === 'Metric' ? 'Metric configurations' : 'Labels'}
            </Checkbox>
          </div>
        }
        onNext={handleNext}
        onPrev={handlePrev}
        onCancel={onCancel}
        nextText="Finish"
        nextIcon={() => <CheckCircleFilled style={{ color: 'white', fontSize: 14, verticalAlign: 'bottom' }} />}
        style={{ position: 'absolute', bottom: 0, width: '100%' }}
        disabled={hasNotInteger && dataType === 'Metric'}
      />

      {showModal && (
        <Modal
          width={440}
          bodyStyle={{ height: 280 }}
          title="Finish configuration"
          visible
          footer={null}
          maskClosable={false}
          onCancel={() => {
            setShowModal(false);
          }}
          className="batch-review-model"
        >
          <Spin spinning={loading}>
            <Step5 {...finishProps} setShowModal={setShowModal} />
          </Spin>
        </Modal>
      )}
    </div>
  );
};

export default Step4;
