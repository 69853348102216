/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get, isNumber, range } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Button, Select } from 'antd';

import { State } from '../../common/types';
import { appFieldsMessages, appButtonsMessages } from '../../common/app/messages';
import { eventMessages } from '../../common/metric/messages';
import { parseLocation, buildLocation, pickNotNil, CalcColorOfAnomalyScore, buildUrl } from '../../common/utils';
import { Defaults as AppDefaults } from '../../common/app';
import { Container } from '../../lib/fui/react';
import { EChart } from '../share';
import { createLoadAction, showAppLoader, ActionTypes as AppActionTypes } from '../../common/app/actions';
import { BaseUrls } from '../app/Constants';
import { ActionTypes } from '../../common/metric/actions';

type Props = {
  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  showAppLoader: Function,
  // eslint-disable-next-line
  userName: String,
  isAdmin: Boolean,
  // eslint-disable-next-line
  userList: Array<Object>,
  globalInfoLoaded: Boolean,
  globalInfo: Array<Object>,

  eventsHistory: Array<Object>,
};

class EventsHistoryCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl } = props;

    this.customerChange = false;
    this.healthScore = 99;
    this.dayTime = 3600 * 24 * 1000;

    this.state = {
      systemHistory: null,
    };
    this.quarterOptions = [
      { value: 1, label: intl.formatMessage(eventMessages.quarter1) },
      { value: 2, label: intl.formatMessage(eventMessages.quarter2) },
      { value: 3, label: intl.formatMessage(eventMessages.quarter3) },
      { value: 4, label: intl.formatMessage(eventMessages.quarter4) },
    ];
    this.years = [];
  }

  componentDidMount() {
    if (!this.applyParamsAndRedirect(this.props)) {
      this.reloadData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.location !== this.props.location ||
      R.difference(nextProps.globalInfo, this.props.globalInfo).length > 0
    ) {
      if (!this.applyParamsAndRedirect(nextProps)) {
        this.reloadData(nextProps);
      }
    } else if (this.props.eventsHistory !== nextProps.eventsHistory) {
      this.parseData(nextProps);
    }
  }

  @autobind
  applyParamsAndRedirect(props) {
    const { location, replace, isAdmin, userList, globalInfo } = props;
    const params = parseLocation(location);

    let { startTime, customerName, environmentId, systemId, year } = params;
    let redirect = false;

    if (!startTime) {
      startTime = moment().utc().startOf('day').format(AppDefaults.DateFormat);
    }

    this.selectDay = moment.utc(startTime, AppDefaults.DateFormat);
    this.year = this.selectDay.year();
    this.years = R.range(2015, moment().utc().year() + 1);
    const envIds = R.map((item) => item.id, globalInfo);

    if (isAdmin && userList.length > 0 && !customerName) {
      const user = userList[0] || R.find((u) => u.userName === 'user' || u.userName === 'guest', userList);
      if (user) {
        customerName = user.userName;
      }
    }
    if (!environmentId || envIds.indexOf(environmentId) < 0) {
      if (globalInfo && globalInfo.length > 0) {
        environmentId = globalInfo[0].id;
        systemId = globalInfo[0].systemList[0].id;
      }
    }
    // Auto select the first system, if no system id or the system id not in the list
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = environment ? environment.systemList : [];
    if (systemList.length > 0 && (!systemId || !R.find((s) => s.id === systemId, systemList))) {
      systemId = systemList[0].id;
    }

    if (!year) {
      year = this.year;
    }

    // Change number to string to avoid false comparation
    const newParams = pickNotNil({
      startTime,
      customerName,
      environmentId,
      systemId,
      year,
    });

    if (!R.equals(newParams, params)) {
      redirect = true;
      replace(buildLocation(location.pathname, {}, newParams));
    }

    return redirect;
  }

  @autobind
  reloadData(props, force = false) {
    const { location, isAdmin, createLoadAction, globalInfoLoaded, globalInfo } = props;
    const params = parseLocation(location);
    const { startTime, customerName, environmentId, systemId, year } = params;

    const startTimestamp = moment.utc().year(Number(year)).startOf('year').valueOf();
    const endTimestamp = moment.utc().year(Number(year)).endOf('year').valueOf();
    const selectDay = startTime;
    const endTime = startTime;

    if ((force || !globalInfoLoaded || this.customerChange) && selectDay && (!isAdmin || (isAdmin && customerName))) {
      this.customerChange = false;
      props.createLoadAction(AppActionTypes.LOAD_INFO_GLOBAL, {
        startTime: selectDay,
        endTime,
        customerName,
        environmentOnly: false,
      });
    } else {
      const environment = R.find((e) => e.id === environmentId, globalInfo);
      const environmentName = get(environment, 'name', environmentId);
      if (startTimestamp && endTimestamp) {
        createLoadAction(ActionTypes.LOAD_EVENTS_HISTORY, {
          environmentName,
          startTimestamp,
          endTimestamp,
          customerName,
          systemIds: [systemId],
          isHistorical: true,
        });
      }
    }
  }

  @autobind
  parseData(props) {
    const { eventsHistory } = props;
    const systemHistory = (eventsHistory || []).length > 0 ? (eventsHistory || [])[0] : null;
    this.setState({ systemHistory });
  }

  @autobind
  renderRow({ quarter }) {
    const { location } = this.props;
    const { systemHistory } = this.state;
    const { year } = parseLocation(location);
    if (!systemHistory || !year) return null;

    const { anomalyScoress, systemId } = systemHistory;
    const startTimestamp = moment().utc().year(Number(year)).quarter(quarter).startOf('quarter').valueOf();
    const endTimestamp = moment().utc().year(Number(year)).quarter(quarter).endOf('quarter').valueOf();
    const times = range(
      startTimestamp,
      endTimestamp,
      // R.min(
      //   endTimestamp,
      //   moment
      //     .utc()
      //     .endOf('day')
      //     .valueOf(),
      // ),
      this.dayTime,
    );
    const months = R.uniq(R.map((time) => moment.utc(time).format('MMM'), times));
    const dateList = R.map((t) => moment.utc(t).format('YYYY-MM-DD'), times);
    // const options = this.getEventsHistoryOptions(anomalyScoress, dateList, year, quarter, systemId);
    const options = this.getEventsHistoryOptionsLineChart(anomalyScoress, dateList, year, quarter, systemId);

    return (
      <div className="flex-grow flex-col flex-min-height" style={{ padding: 8 }}>
        <div className="flex-row flex-center-justify bold" style={{ margin: '0 16px 0 16px', fontSize: 14 }}>
          Month: {R.join(', ', months || [])}
        </div>
        <div className="flex-grow">
          <EChart width="100%" height="100%" option={options} onClick={this.onChartClick()} />
        </div>
      </div>
    );
  }

  @autobind
  getEventsHistoryOptionsLineChart(anomalyScores, dateList, year, quarter, systemId) {
    const { intl } = this.props;
    const data = R.map((date) => {
      const anomalyScore = get(anomalyScores, [date, 'score']);
      const color = CalcColorOfAnomalyScore.calcColorOfScoreRange(anomalyScore, this.healthScore, 50);
      return {
        value: anomalyScore,
        systemId,
        color,
        itemStyle: {},
      };
    }, dateList);

    return {
      tooltip: {
        trigger: 'item',
        confine: true,
      },
      xAxis: {
        type: 'category',
        data: dateList,
      },
      yAxis: {
        type: 'value',
      },
      grid: { left: '10%', right: '10%', top: '15%', bottom: '15%' },
      series: [
        {
          type: 'line',
          name: intl.formatMessage(eventMessages.healthScore),
          data,
          showAllSymbol: true,
          symbolSize: 3,
        },
      ],
    };
  }

  @autobind
  getEventsHistoryOptions(anomalyScores, dateList, year, quarter, systemId) {
    const { intl } = this.props;
    const nameMap = moment.weekdaysMin();
    const calendarData = R.map((date) => {
      const anomalyScore = get(anomalyScores, [date, 'score']);
      const color = CalcColorOfAnomalyScore.calcColorOfScoreRange(anomalyScore, this.healthScore, 50);
      return {
        value: [date, anomalyScore],
        itemStyle: {
          color,
        },
        emphasis: {
          itemStyle: {
            color,
          },
        },
        systemId,
      };
    }, dateList);

    const quarterTime = moment().utc().year(Number(year)).quarter(quarter);
    const rangeTime = [
      quarterTime.startOf('quarter').format(AppDefaults.DateFormat),
      quarterTime.endOf('quarter').format(AppDefaults.DateFormat),
    ];

    return {
      tooltip: {
        confine: true,
        transitionDuration: 0,
        formatter: (params) => {
          const data = get(params, 'data');
          const html = `<div class="flex-col"><div>${intl.formatMessage(eventMessages.date)}: ${
            data.value[0]
          }</div><div>${intl.formatMessage(eventMessages.healthScore)}: ${data.value[1]}</div></div>`;
          return `<div style="font-size: 12px">${html}</div>`;
        },
      },
      calendar: [
        {
          left: 50,
          right: 5,
          top: 'middle',
          // range: year,
          range: rangeTime,
          cellSize: ['auto', 20],
          yearLabel: {
            show: false,
          },
          monthLabel: {
            formatter: (d) => {
              const { M, MM, nameMap } = d;
              return intl.locale === 'zh' ? moment.months()[M - 1] : `${MM}-${nameMap}`;
            },
            fontSize: 12,
            fontWeight: 400,
          },
          dayLabel: {
            align: 'left',
            nameMap,
            fontSize: 12,
            fontWeight: 500,
          },
        },
      ],
      series: [
        {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          calendarIndex: 0,
          data: calendarData,
        },
      ],
    };
  }

  @autobind
  onChartClick() {
    return (e) => {
      const { location } = this.props;
      const params = parseLocation(location);
      const startTime = get(e, ['name']);
      const systemId = get(e, ['data', 'systemId']);
      const { customerName, environmentId } = params;
      const query = {
        startTime,
        endTime: startTime,
        customerName,
        environmentId,
        systemId,
      };
      window.open(buildUrl(BaseUrls.GlobalHealthSystem, {}, query), '_self');
    };
  }

  @autobind
  handleCustomerNameChange(customerName) {
    const { location, showAppLoader } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    setTimeout(() => {
      const query = parseLocation(location);
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { ...query, customerName, environmentId: undefined, systemId: undefined },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  handleEnvironmentChange(environmentId) {
    const { location, push } = this.props;
    const query = parseLocation(location);
    push(buildLocation(location.pathname, {}, { ...query, environmentId, systemId: undefined }));
  }

  @autobind
  handleSystemIdChange(systemId) {
    const { push, location } = this.props;
    const query = parseLocation(location);
    push(buildLocation(location.pathname, {}, { ...query, systemId }));
  }

  @autobind
  handleYearChange(year) {
    const { push, location } = this.props;
    const params = parseLocation(location);
    push(buildLocation(location.pathname, {}, { ...params, year }));
  }

  render() {
    const { intl, isAdmin, userList, globalInfo } = this.props;
    const params = parseLocation(location);
    const { customerName, year, environmentId, systemId } = params;
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const { systemHistory } = this.state;

    return (
      <Container fullHeight withGutter className="flex-col log-live">
        <Container className="flex-row" breadcrumb style={{ fontSize: 12 }}>
          <div className="flex-grow section" style={{ height: 28 }}>
            <span>{intl.formatMessage(eventMessages.eventsHistory)}</span>
            <span className="divider">/</span>
            {Number(year) && (
              <Select
                showSearch
                size="small"
                value={Number(year)}
                style={{ width: 140 }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={this.handleYearChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ),
                  this.years || [],
                )}
              </Select>
            )}
          </div>
          <div
            className="flex-grow flex-row flex-center-align section"
            style={{ justifyContent: 'center', height: 28 }}
          />
          <div className="flex-grow flex-row flex-center-align" style={{ justifyContent: 'flex-end', height: 28 }}>
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.system)}</span>
            <Select
              showSearch
              size="small"
              value={systemId}
              style={{ width: 140 }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={this.handleSystemIdChange}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ),
                systemList || [],
              )}
            </Select>

            {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
              {intl.formatMessage(appFieldsMessages.environment)}
            </span>
            <Select size="small" value={environmentId} style={{ width: 140 }} onChange={this.handleEnvironmentChange}>
              {R.map(
                (item) => (
                  <Select.Option key={item.name} value={item.name} title={item.name}>
                    {item.id}
                  </Select.Option>
                ),
                globalInfo || [],
              )}
            </Select> */}
            {isAdmin && (
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
            )}
            {isAdmin && (
              <Select
                showSearch
                size="small"
                value={customerName}
                style={{ width: 100 }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={this.handleCustomerNameChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            )}

            <Button
              size="small"
              style={{ marginLeft: 8 }}
              onClick={() => {
                this.reloadData(this.props, true);
              }}
            >
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </div>
        </Container>
        <Container className="events-view flex-col flex-grow flex-min-height">
          <div
            className="flex-grow flex-col flex-min-height"
            style={{
              overflowY: 'auto',
              background: 'white',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: `${2 / 14}rem`,
            }}
          >
            {!systemHistory && (
              <div
                className="ui info message"
                style={{
                  margin: 8,
                  height: 50,
                }}
              >
                {intl.formatMessage(eventMessages.noHistoryFound)}
              </div>
            )}
            {systemHistory && (
              <div className="flex-grow flex-col flex-min-height">
                <div className="flex-grow flex-row">
                  {this.renderRow({ quarter: 1 })}
                  {this.renderRow({ quarter: 2 })}
                </div>
                <div className="flex-grow flex-row">
                  {this.renderRow({ quarter: 3 })}
                  {this.renderRow({ quarter: 4 })}
                </div>
              </div>
            )}
          </div>
        </Container>
      </Container>
    );
  }
}

const EventsHistory = injectIntl(EventsHistoryCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, userList, globalInfoLoaded, globalInfo } = state.app;
    const { isAdmin, userName } = state.auth.userInfo;

    const { eventsHistory } = state.metric;
    return {
      location,
      loadStatus,
      isAdmin,
      userName,
      userList,
      globalInfoLoaded,
      globalInfo,
      eventsHistory,
    };
  },
  { push, replace, showAppLoader, createLoadAction },
)(EventsHistory);
