/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { get } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import update from 'immutability-helper';
import { Select, Button, DatePicker } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { parseLocation, getLoadStatus, Defaults, buildLocation } from '../../../common/utils';
import { State } from '../../../common/types';
import { Modal, Container, AutoSizer } from '../../../lib/fui/react';
import BaseUrls from '../../app/BaseUrls';
import {
  createLoadAction,
  createSetAction,
  updateLastActionInfo,
  ActionTypes as AppActionTypes,
} from '../../../common/app/actions';
import { ActionTypes } from '../../../common/dashboard/actions';
import { appFieldsMessages, appMenusMessages, appButtonsMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';

import SystemWidgetsSortableGrid from './SystemWidgetsSortableGrid';
import InsightsStatistics from './InsightsStatistics';
import InsightsRootCauseInstancePattern from './InsightsRootCauseInstancePattern';
import { InsightsRootCauseCategory } from './InsightsRootCauseStats';
import InsightsHealthScoreTrend from './InsightsHealthScoreTrend';
import InsightsPredictionAccuracy from './InsightsPredictionAccuracy';
import InsightsIncidentPatternStats from './InsightsIncidentPatternStats';
import InsightsIncidentVolumeStats from './InsightsIncidentVolumeStats';
import InsightsAlertPatternStats from './InsightsAlertPatternStats';
import InsightsAlertVolumeStats from './InsightsAlertVolumeStats';
// import InsightsAlertsSuppressedStats from './InsightsAlertsSuppressedStats';
import InsightsAnomalousComponentsStats from './InsightsAnomalousComponentsStats';
import InsightsAnomalousInstancesStats from './InsightsAnomalousInstancesStats';
import InsightsTips from './InsightsTips';

type Props = {
  refresh: Number,
  handleRefresh: Function,
  handleDateTimeChange: Function,
  handleCustomerNameChange: Function,
  // eslint-disable-next-line
  handleEnvironmentChange: Function,
  handleSystemIdChange: Function,

  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  location: Object,

  loadStatus: Object,
  currentLocale: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  globalInfo: Object,

  userList: Array<Object>,
  credentials: Object,
  userInfo: Object,
  insightWidgets: Array<Object>,

  // eslint-disable-next-line
  createLoadAction: Function,
  createSetAction: Function,
  updateLastActionInfo: Function,
  currentTheme: String,
};

class GlobalSystemInsightsViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl, insightWidgets } = props;

    this.dataLoader = 'dashboard_global_system_incident_prediction';
    this.breakAutoLoad = false;

    this.state = {
      isLoading: true,
      reloadSystem: null,
      insightWidgets,

      isLoadingOverall: true,
      overallInfo: {},
      alertCountMap: {},
      isLoadingStats: true,
      incidentPatternStats: [],
      isLoadingAlertStats: true,
      alertPatternStats: [],
      isLoadingHealthScore: false,
      avgHealthScore: undefined,
      isLoadingAnomalyInstance: true,
      anomalyInstanceCountInfo: [],
    };
    this.systemInsightsWidgets = [
      {
        key: 1,
        name: intl.formatMessage(DashboardMessages.insightsHealthTrending),
        component: InsightsHealthScoreTrend,
      },
      {
        key: 2,
        name: intl.formatMessage(DashboardMessages.insightsPredictedIncidents),
        component: InsightsPredictionAccuracy,
      },
      {
        key: 3,
        name: intl.formatMessage(DashboardMessages.insightsIncidentPatterns),
        component: InsightsIncidentPatternStats,
      },
      {
        key: 4,
        name: intl.formatMessage(DashboardMessages.insightsIncidentVolume),
        component: InsightsIncidentVolumeStats,
      },
      {
        key: 5,
        name: intl.formatMessage(DashboardMessages.insightsAlertPatterns),
        component: InsightsAlertPatternStats,
      },
      {
        key: 6,
        name: intl.formatMessage(DashboardMessages.insightsAlertVolume),
        component: InsightsAlertVolumeStats,
      },
      // {
      //   key: 7,
      //   name: intl.formatMessage(DashboardMessages.insightsAlertsSuppressed),
      //   component: InsightsAlertsSuppressedStats,
      // },
      {
        key: 7,
        name: intl.formatMessage(DashboardMessages.insightsAnomalousComponents),
        component: InsightsAnomalousComponentsStats,
      },
      {
        key: 8,
        name: intl.formatMessage(DashboardMessages.insightsAnomalousInstances),
        component: InsightsAnomalousInstancesStats,
      },
      {
        key: 9,
        name: intl.formatMessage(DashboardMessages.insightsRootCausePatterns),
        component: InsightsRootCauseInstancePattern,
      },
      {
        key: 10,
        name: intl.formatMessage(DashboardMessages.insightsRootCauseCategories),
        component: InsightsRootCauseCategory,
      },
      { key: 11, name: intl.formatMessage(DashboardMessages.insightsPreventIncidents), component: InsightsTips },
    ];
  }

  componentDidMount() {
    const { location, globalInfo } = this.props;
    const params = parseLocation(location);
    const { environmentId, systemId } = params;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo && !systemInfo.hasAllInstanceInfo) {
      this.loadSystemData(this.props);
    } else {
      this.reloadData(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.reloadSystem !== prevState.reloadSystem) {
      this.reloadData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // const nextQuery = parseLocation(nextProps.location);
    // const query = parseLocation(this.props.location);
    if (nextProps.globalInfo !== this.props.globalInfo) {
      this.loadSystemData(nextProps);
    } else if (nextProps.refresh !== this.props.refresh) {
      this.reloadData(nextProps);
    } else if (nextProps.insightWidgets !== this.props.insightWidgets) {
      this.setState({ insightWidgets: nextProps.insightWidgets });
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  loadSystemData(props) {
    const { location, userInfo, createLoadAction } = props;
    const params = parseLocation(location);
    const { customerName, environmentId, systemId, startTime } = params;

    if (((userInfo.isAdmin && customerName) || !userInfo.isAdmin) && environmentId && systemId && startTime) {
      this.setState({ isLoading: true });
      createLoadAction(
        AppActionTypes.LOAD_INFO_SYSTEM,
        {
          startTime,
          endTime: startTime,
          customerName: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : userInfo.userName,
          systemName: systemId,
          environmentId,
          anomalyInstanceOnly: false,
        },
        this.dataLoader,
        true,
        () => this.callbackReloadSystem(props),
      );
    }
  }

  @autobind
  callbackReloadSystem(props) {
    this.setState({ reloadSystem: moment.utc().valueOf() });
  }

  @autobind
  reloadData(props) {
    const { createLoadAction, credentials } = props;

    this.setState({ isLoading: true });
    createLoadAction(
      ActionTypes.LOAD_INSIGHTS_WIDGETS_CONFIG,
      {
        customerName: credentials.userName,
      },
      this.dataLoader,
      true,
      this.callbackReloadData,
    );
  }

  @autobind
  callbackReloadData() {
    this.setState({ isLoading: false });
  }

  @autobind
  updateState(state) {
    this.setState(state);
  }

  @autobind
  onSortOver({ index, oldIndex, newIndex, collection, isKeySorting }) {
    // used for debug
    console.debug(index, oldIndex, newIndex);
  }

  @autobind
  onSortEnd({ oldIndex, newIndex, collection, isKeySorting }) {
    const { insightWidgets } = this.state;
    const card = insightWidgets[oldIndex];
    const newInsightWidgets = update(insightWidgets, {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, card],
      ],
    });

    this.setState({ insightWidgets: newInsightWidgets }, () => {
      const { createSetAction, credentials } = this.props;
      const errContent = <div>Unfortunately, an error has occurred. Please try again!</div>;
      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('insightWidgets', 1), {
        ...credentials,
        customerName: credentials.userName,
        updateInsightWidgets: JSON.stringify(newInsightWidgets),
      })
        .then((data) => {
          if (data.success) {
            // Save to redux, so reload this page without refresh is still work
            createSetAction(
              ActionTypes.SET_INSIGHTS_WIDGETS_CONFIG,
              {},
              {
                insightWidgets: newInsightWidgets,
              },
            );
          } else {
            Modal.error({ content: errContent });
          }
        })
        .catch((err) => {
          Modal.error({ content: errContent });
        });
    });
  }

  render() {
    const {
      intl,
      loadStatus,
      location,
      push,
      updateLastActionInfo,
      refresh,
      currentLocale,
      projects,
      globalInfo,
      userList,
      credentials,
      userInfo,
      currentTheme,
    } = this.props;
    const {
      isLoading,
      reloadSystem,
      insightWidgets,
      isLoadingOverall,
      overallInfo,
      alertCountMap,
      isLoadingStats,
      incidentPatternStats,
      isLoadingAlertStats,
      alertPatternStats,
      isLoadingHealthScore,
      avgHealthScore,
      isLoadingAnomalyInstance,
      anomalyInstanceCountInfo,
    } = this.state;
    const params = parseLocation(location);
    const { refreshTime, environmentId, systemId, customerName, startTime } = params;
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];

    const { errorMessage, errorInfo } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    return (
      <Container className={`flex-grow flex-col flex-min-height flex-min-width ${isLoading ? 'loading ' : ''}`}>
        <Container breadcrumb className="flex-row">
          <div className="flex-grow">
            <div
              className="flex-row flex-center-align bold"
              style={{ fontSize: 18 }}
              onClick={() => {
                // push(buildLocation(BaseUrls.GlobalNewSystemInsights, {}, { ...params }));
              }}
            >
              {intl.formatMessage(appMenusMessages.globalSystemInsights)}
            </div>
          </div>
          <div className="flex-row">
            <Container className="flex-row" style={{ alignItems: 'center' }}>
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.system)}</span>
              <Select
                showSearch
                size="small"
                value={systemId}
                style={{ width: 140 }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={this.props.handleSystemIdChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ),
                  systemList || [],
                )}
              </Select>
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.date)}</span>
              <DatePicker
                size="small"
                allowClear={false}
                showToday={false}
                value={moment.utc(startTime, Defaults.DateFormat)}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(newDate) => this.props.handleDateTimeChange(newDate)}
              />

              {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.environment)}
              </span>
              <Select
                size="small"
                value={environmentId}
                style={{ width: 140 }}
                onChange={this.props.handleEnvironmentChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.name} value={item.name} title={item.name}>
                      {item.id}
                    </Select.Option>
                  ),
                  globalInfo || [],
                )}
              </Select> */}
              {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
                <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
              )}
              {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
                <Select
                  showSearch
                  size="small"
                  value={customerName}
                  style={{ width: 100 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleCustomerNameChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              )}

              <Button size="small" onClick={() => this.props.handleRefresh()} style={{ marginLeft: 8 }}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </Container>
          </div>
        </Container>

        <Container className="flex-grow flex-col flex-min-height flex-min-width" style={{ margin: '0 16px 8px 16px' }}>
          {errorMessage && (
            <Container style={{ margin: 8 }}>
              <div className={`ui mini ${errorInfo} message`}>{errorMessage}</div>
            </Container>
          )}

          {!errorMessage && (
            <InsightsStatistics
              intl={intl}
              push={push}
              updateLastActionInfo={updateLastActionInfo}
              credentials={credentials}
              userInfo={userInfo}
              reloadSystem={reloadSystem}
              refresh={refresh}
              refreshTime={refreshTime}
              environmentId={environmentId}
              systemId={systemId}
              customerName={customerName}
              startTime={startTime}
              currentLocale={currentLocale}
              globalInfo={globalInfo}
              updateState={this.updateState}
              isLoadingOverall={isLoadingOverall}
              overallInfo={overallInfo}
              isLoadingAlertStats={isLoadingAlertStats}
              alertCountMap={alertCountMap}
              isLoadingHealthScore={isLoadingHealthScore}
              avgHealthScore={avgHealthScore}
            />
          )}

          {!errorMessage && (
            <div
              className="flex-grow flex-col flex-min-height"
              style={{ background: 'white', borderRadius: 6, padding: 8 }}
            >
              <div className="flex-grow overflow-y-auto overflow-x-hidden">
                <AutoSizer>
                  {({ width, height }) => (
                    <SystemWidgetsSortableGrid
                      width={width}
                      height={height}
                      helperClass="sortableHelperAntModal"
                      axis="xy"
                      // onSortOver={this.onSortOver}
                      onSortEnd={this.onSortEnd}
                      useDragHandle
                      items={R.map((widget) => {
                        const widgetInfo = R.find((item) => item.key === widget, this.systemInsightsWidgets);
                        if (!widgetInfo) return null;

                        return React.createElement(widgetInfo.component, {
                          intl,
                          widget,
                          name: widgetInfo.name,

                          push,
                          updateLastActionInfo,
                          currentLocale,
                          projects,
                          globalInfo,
                          credentials,
                          userInfo,

                          reloadSystem,
                          refresh,
                          refreshTime,
                          environmentId,
                          systemId,
                          customerName,
                          startTime,

                          ...(widget === 3 ? { isLoadingStats, incidentPatternStats } : {}),
                          ...(widget === 5 ? { isLoadingAlertStats, alertPatternStats } : {}),
                          ...(widget === 8 ? { isLoadingAnomalyInstance, anomalyInstanceCountInfo } : {}),

                          updateState: this.updateState,
                          currentTheme,
                        });
                      }, insightWidgets || [])}
                    />
                  )}
                </AutoSizer>
              </div>
            </div>
          )}
        </Container>
      </Container>
    );
  }
}

const GlobalSystemInsightsView = injectIntl(GlobalSystemInsightsViewCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, projects, globalInfo, currentTheme } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { credentials } = state.auth;
    const { userInfo } = state.auth;
    const { insightWidgets } = state.dashboard;
    return {
      location,

      loadStatus,
      currentLocale,
      projects,
      globalInfo,

      userList,
      credentials,
      userInfo,

      insightWidgets,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, createSetAction, updateLastActionInfo },
)(GlobalSystemInsightsView);
