import React from 'react';
import * as R from 'ramda';
import {
  CPUHighIcon,
  CPUHighPath,
  LowMemoryIcon,
  LowMemoryPath,
  MistakeIcon,
  MistakePath,
  PinLegendIcon,
  PinPath,
  ProhibitIcon,
  ProhibitPath,
  SlowSnailIcon,
  SlowSnailPath,
} from '../../lib/fui/icons';

export const getPatternNameIcon = ({ patternIdAndName, patternStyle }) => {
  let patternIcon = <PinLegendIcon style={patternStyle} />;
  if (
    R.includes('slow', R.toLower(patternIdAndName)) ||
    R.includes('backlog', R.toLower(patternIdAndName)) ||
    R.includes('time', R.toLower(patternIdAndName))
  ) {
    patternIcon = <SlowSnailIcon style={patternStyle} />;
  } else if (R.includes('error', R.toLower(patternIdAndName))) {
    patternIcon = <MistakeIcon style={patternStyle} />;
  } else if (R.includes('down', R.toLower(patternIdAndName))) {
    patternIcon = <ProhibitIcon style={patternStyle} />;
  } else if (R.includes('cpu', R.toLower(patternIdAndName)) || R.includes('memory', R.toLower(patternIdAndName))) {
    patternIcon = <CPUHighIcon style={patternStyle} />;
  } else if (R.includes('disk', R.toLower(patternIdAndName)) || R.includes('database', R.toLower(patternIdAndName))) {
    patternIcon = <LowMemoryIcon style={patternStyle} />;
  }
  return patternIcon;
};

export const getPatternNameIconPath = ({ patternName }) => {
  let iconPath = { path: PinPath, symbolSize: [16, 18] };
  if (R.includes('slow', R.toLower(patternName)) || R.includes('backlog', R.toLower(patternName))) {
    iconPath = { path: SlowSnailPath, symbolSize: [18, 18] };
  } else if (R.includes('error', R.toLower(patternName))) {
    iconPath = { path: MistakePath, symbolSize: [16, 16] };
  } else if (R.includes('down', R.toLower(patternName))) {
    iconPath = { path: ProhibitPath, symbolSize: [18, 18] };
  } else if (R.includes('cpu', R.toLower(patternName)) || R.includes('memory', R.toLower(patternName))) {
    iconPath = { path: CPUHighPath, symbolSize: [20, 18] };
  } else if (R.includes('disk', R.toLower(patternName)) || R.includes('database', R.toLower(patternName))) {
    iconPath = { path: LowMemoryPath, symbolSize: [18, 18] };
  }
  return iconPath;
};
