/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
/* eslint-disable no-console */

import * as R from 'ramda';
import { isArray } from 'lodash';
import { createLogic } from 'redux-logic';
import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getAnomalyLogEvents } from '../../apis';
import { appMessages } from '../../app/messages';

const loadAnomalyLogEventsLogic = createLogic({
  type: [ActionTypes.LOAD_ANOMALY_LOG_EVENTS],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,

  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getAnomalyLogEvents(credentials, params)
      .then((d) => {
        const { data } = d;

        // Ignore the empty log entry for instance
        const eventLogListMap = {};
        R.forEachObjIndexed((elist, inst) => {
          if (isArray(elist) && elist.length > 0) {
            eventLogListMap[inst] = elist;
          }
        }, data || {});
        dispatch(createSetAction(ActionTypes.SET_ANOMALY_LOG_EVENTS, params, eventLogListMap));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get anomaly log event list', err);
        dispatch(createSetAction(ActionTypes.SET_ANOMALY_LOG_EVENTS, params, { eventLogListMap: {} }));
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadAnomalyLogEventsLogic;
