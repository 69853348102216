import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { Button, Spin } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, DoubleLeftOutlined } from '@ant-design/icons';

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Modal,
  Popover,
  SortDirection,
  Table,
  Tooltip,
} from '../../../lib/fui/react';
import { buildUrl, Defaults, EventRenderers, parseLocation } from '../../../common/utils';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

import { eventMessages } from '../../../common/metric/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { logMessages } from '../../../common/log/messages';
import { appFieldsMessages } from '../../../common/app/messages';

import RenderLogContent from './RenderLogContent';
import BaseUrls from '../../app/BaseUrls';

const expandedStateKey = 'isExpand';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

export default function LogToMetricContextRCA(props: Object) {
  const { intl, activeIncident, credentials, currentTheme, location, globalInfo, onClose } = props || {};
  const { summarySettingsMap, projects } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    dataList: [],

    sortBy: null,
    sortDirection: null,

    metricName: '',
    allExpand: false,
  });
  const dataTableNode = useRef(null);

  const { loading, dataList, sortBy, sortDirection, metricName, allExpand } = state;
  const correspondingLogProjectName =
    activeIncident?.sourceProjectOwner !== credentials.userName
      ? `${activeIncident?.correspondingLogProjectName}@${activeIncident?.sourceProjectOwner}`
      : activeIncident?.correspondingLogProjectName;
  const summarySettings =
    summarySettingsMap && activeIncident ? summarySettingsMap[correspondingLogProjectName] || [] : [];

  const onToggleCollapse = useCallback(() => {
    cellMeasureCache.clearAll();
    if (dataTableNode.current) {
      dataTableNode.current.forceUpdateGrid();
    }
  }, []);

  const getDataList = () => {
    const { logToMetricEvents, sourceDetail } = activeIncident || {};
    const { content } = sourceDetail || {};
    setState({ loading: true, metricName: content });
    let newEvent = R.map(
      (item) => ({ ...item, [expandedStateKey]: allExpand, instanceName: item.i, logToMetricValue: item.lmv }),
      logToMetricEvents || [],
    );
    newEvent = R.sortWith([R.ascend(R.prop('timestamp'))])(newEvent);
    setState({ loading: false, dataList: newEvent });
    setTimeout(() => {
      onToggleCollapse();
    }, 0);
  };

  useEffect(() => {
    getDataList();
    return () => {};
  }, []);

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    const allExpandRender = () => {
      return (
        <>
          <span className="flex-grow" />
          <DoubleLeftOutlined
            rotate={allExpand ? 90 : -90}
            style={{ marginRight: 3, cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const newDataList = R.map((item) => {
                return { ...item, [expandedStateKey]: !allExpand };
              }, dataList);
              setState({ dataList: newDataList, allExpand: !allExpand });
            }}
          />
        </>
      );
    };
    if (dataKey === 'logToMetricValue') {
      return (
        <>
          <Tooltip title={metricName} mouseEnterDelay={0.3} placement="top">
            <span className="hidden-line-with-ellipsis" style={{ maxWidth: 90 }}>
              {metricName}
            </span>
          </Tooltip>
          <div>{!disableSort && sortIcon()}</div>
        </>
      );
    }
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
        {dataKey === 'rawData' && allExpandRender()}
      </div>
    );
  };

  useEffect(() => {
    onToggleCollapse();
  }, [dataList]);

  useEffect(() => {
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        setState({ dataList: R.sortWith([R.descend(R.prop(sortBy))])(dataList) });
      } else {
        setState({ dataList: R.sortWith([R.ascend(R.prop(sortBy))])(dataList) });
      }
      onToggleCollapse();
    }
  }, [sortBy, sortDirection]);

  const datetimeRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
    const { timestamp } = rowData;

    const content = <div>{moment.utc(timestamp).format(Defaults.TimeFormat)}</div>;
    return <div>{content}</div>;
  };

  const compressBaseRenderer = ({ rowData: data }) => {
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};
    const { instanceName } = data;

    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName, {
      pn: activeIncident?.sourceProjectName,
      owner: activeIncident?.sourceProjectOwner,
    });

    return (
      <Popover title={null} content={instanceStr} mouseEnterDelay={0.3}>
        <div className="hidden-line-with-ellipsis" style={{ wordWrap: 'break-word' }}>
          {instanceStr}
        </div>
      </Popover>
    );
  };

  const logPatternIdRenderer = ({ rowData }) => {
    const { logToMetricValue } = rowData || {};
    return <div>{logToMetricValue}</div>;
  };

  const contentRender = ({ dataKey, parent, rowIndex, rowData }) => {
    const { rawData } = rowData;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error)
    }
    const isMetric = Boolean(
      rawDataJson?.rootCauseMetric && rawDataJson?.direction && (rawDataJson?.pct || rawDataJson?.pct === 0),
    );
    return (
      <CellMeasurer cache={cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ padding: '8px 0' }}>
          {isMetric && (
            <div className="flex-grow flex-min-width" style={{ wordBreak: 'break-word' }}>
              {EventRenderers.RenderMetricAnomalySummary({
                intl,
                event: rawDataJson,
              })}
            </div>
          )}
          {!isMetric && (
            <>
              {!rawDataJson && (
                <Popover
                  title={null}
                  content={
                    <div className="overflow-y-auto overflow-x-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
                      <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word' }}>
                        {R.join(
                          '\n',
                          R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                        )}
                      </div>
                    </div>
                  }
                  mouseEnterDelay={0.3}
                  placement="right"
                >
                  <div className="hidden-line-with-ellipsis">{rawData}</div>
                </Popover>
              )}
              {rawDataJson && (
                <div className="flex-grow flex-min-width" style={{ wordBreak: 'break-word' }}>
                  <RenderLogContent
                    rowData={rowData}
                    summarySettings={summarySettings}
                    currentTheme={currentTheme}
                    onToggleCollapse={onToggleCollapse}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </CellMeasurer>
    );
  };

  const handleJumpToLogAnalysis = () => {
    const { sourceProjectOwner, instanceName, displayTimestamp } = activeIncident;

    const project = R.find((project) => {
      return correspondingLogProjectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);

    const query = {
      projectName: correspondingLogProjectName,
      instanceName: dataList[0]?.instanceName || instanceName,
      startTime: moment.utc(displayTimestamp).format(Defaults.DateFormat),
      endTime: moment.utc(displayTimestamp).format(Defaults.DateFormat),
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      isJump: true,
      customerName: sourceProjectOwner,
      activePatternId: dataList[0]?.patternId || undefined,
    };

    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  };

  const newDataList = [...dataList];

  return (
    <Modal
      visible
      title={
        <div className="flex-row flex-center-align">
          <span className="flex-grow">{intl.formatMessage(eventMessages.logToMetricContext)}</span>
          {activeIncident?.correspondingLogProjectName && (
            <div className="flex-row flex-center-align" style={{ marginRight: 40 }}>
              <span>{intl.formatMessage(DashboardMessages.clickToSeeDetails)}:</span>
              <Button
                size="small"
                type="primary"
                onClick={handleJumpToLogAnalysis}
                style={{ marginLeft: 8 }}
                disabled={dataList.length === 0}
              >
                {intl.formatMessage(DashboardMessages.logAnalysis)}
              </Button>
            </div>
          )}
        </div>
      }
      onCancel={() => onClose()}
      width={950}
      bodyStyle={{ height: 600, overflowY: 'auto' }}
      footer={null}
    >
      <Container className="full-height">
        <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-width">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={cellMeasureCache.rowHeight}
                rowCount={newDataList.length}
                rowGetter={({ index }) => newDataList[index]}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                headerClassName="flex-row flex-center-align"
                ref={(c) => {
                  dataTableNode.current = c;
                }}
              >
                <Column
                  width={130}
                  label={intl.formatMessage(logMessages.dateTime)}
                  dataKey="timestamp"
                  headerRenderer={headerRenderer}
                  cellRenderer={datetimeRenderer}
                />
                <Column
                  width={120}
                  label={intl.formatMessage(appFieldsMessages.instanceName)}
                  dataKey="instanceName"
                  headerRenderer={headerRenderer}
                  cellRenderer={compressBaseRenderer}
                />
                {!['Count'].includes(metricName) && (
                  <Column
                    width={120}
                    label={null}
                    dataKey="logToMetricValue"
                    headerRenderer={headerRenderer}
                    cellRenderer={logPatternIdRenderer}
                    flexShrink={0}
                  />
                )}
                <Column
                  width={110}
                  flexGrow={1}
                  label={intl.formatMessage(logMessages.logEntry)}
                  dataKey="rawData"
                  disableSort
                  headerRenderer={headerRenderer}
                  cellRenderer={contentRender}
                  style={{ whiteSpace: 'pre-wrap' }}
                />
              </Table>
            )}
          </AutoSizer>
        </Spin>
      </Container>
    </Modal>
  );
}
