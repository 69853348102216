/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import numeral from 'numeral';
import { get, isArray, isNumber, isEmpty, round, toInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import VLink from 'valuelink';
import { DoubleLeftOutlined, DoubleRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin, Popover } from 'antd';
import $ from 'jquery';
import * as d3 from 'd3';
import dagre from 'dagre';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Container, AutoSizer, Select, Input } from '../../../lib/fui/react';
import { Defaults, CausalParser, ifIn, CausalRenderers } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { loadCausalIncident, resetCausalIncidentData } from '../../../common/causal/actions';
import dagreD3 from '../../../../components/ui/dagre-d3';
import { appFieldsMessages, appMenusMessages, appButtonsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

import EventRelationModal from './EventRelationModal';

const d3tip = require('d3-tip');

type Props = {
  hasLogProject: Boolean,
  hasMetricProject: Boolean,
  instanceName: String,
  iniInstanceName: String,
  needCausalProperty: Boolean,
  relationProbability: String,
  relationCount: Number,
  filterModality: String,
  pattern: Number,
  incidentParams: Object,
  filterParams: Object,
  causalIncidentInfo: Object,
  fixedCountAndProb: Boolean,
  onInstanceChange: Function,
  onFilterChange: Function,

  intl: Object,
  match: Object,
  location: Object,
  push: Function,
  replace: Function,
  credentials: Object,
  updateLastActionInfo: Function,
  loadCausalIncident: Function,
  resetCausalIncidentData: Function,
  currentLoadingComponents: Object,

  causalIncidentProperty: Object,
  causalIncident: Object,
  causalInstanceIncident: Object,
};

class ComponentCorrelationCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl, pattern } = this.props;
    this.container = null;
    this.defaultZoom = 5;
    this.baseSep = 5;
    this.incidentLoader = 'causal_incident_correlation_loader';

    this.relationElemInfoMap = {};
    this.relationInfoMap = {};
    this.instanceMapping = {};
    this.intraInstanceList = [];
    this.nodeLogContent = {};

    this.modalityFilterOptions = [
      { label: 'All', value: 'all' },
      { label: intl.formatMessage(causalMessages.metricToMetric), value: 'metric-metric' },
      { label: intl.formatMessage(causalMessages.logToLog), value: 'log-log' },
      { label: intl.formatMessage(causalMessages.metricToLog), value: 'log-metric' },
      { label: intl.formatMessage(causalMessages.metricToIncident), value: 'incident-metric' },
      { label: intl.formatMessage(causalMessages.logToIncident), value: 'incident-log' },
    ];
    this.state = {
      isSelectInstance: false,
      currentZoom: this.defaultZoom,
      filterNodes: [],
      pattern: pattern || '',
      filterPattern: pattern ? Number(pattern) : '',
      showLogModal: false,
      showLeftContent: true,
      showRightArrowIcon: false,
      leftContents: [],
      leftLabel: null,
      rightContents: [],
      rightLabel: null,

      edgeRelation: null,
      relationList: [],
      allNodeOptions: [],
      hasRelation: false,
    };
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { onFilterChange, filterParams, iniInstanceName, instanceName, fixedCountAndProb } = nextProps;
    const nextIncidentParams = nextProps.incidentParams || {};
    const incidentParams = this.props.incidentParams || {};
    if (
      nextIncidentParams.causalKey !== incidentParams.causalKey ||
      nextIncidentParams.causalName !== incidentParams.causalName ||
      nextIncidentParams.customerName !== incidentParams.customerName ||
      nextIncidentParams.startTimestamp !== incidentParams.startTimestamp ||
      nextIncidentParams.endTimestamp !== incidentParams.endTimestamp ||
      nextIncidentParams.fileName !== incidentParams.fileName ||
      nextIncidentParams.joinDependency !== incidentParams.joinDependency ||
      nextProps.causalIncidentInfo !== this.props.causalIncidentInfo ||
      nextProps.instanceName !== this.props.instanceName ||
      nextProps.needCausalProperty !== this.props.needCausalProperty
    ) {
      this.reloadData(nextProps, true);
    } else if (
      nextProps.causalIncident !== this.props.causalIncident ||
      nextProps.causalInstanceIncident !== this.props.causalInstanceIncident
    ) {
      if (filterParams && !fixedCountAndProb) {
        const { allNodeList, filterNodes, hasRelation, metric } = this.parseInitNode(nextProps);
        let newMetric = metric;
        if (!newMetric) newMetric = 'all';
        let newFilterNodes = filterNodes;
        if (iniInstanceName && !instanceName) newFilterNodes = [iniInstanceName];
        newFilterNodes = R.filter((item) => allNodeList.indexOf(item) >= 0, newFilterNodes || []);

        this.setState(
          { filterNodes: newFilterNodes, hasRelation, filterMetrics: newMetric, hasSelectMetricRelation: true },
          () => {
            if (newFilterNodes && newFilterNodes.length > 0) {
              const parentState = this.getNodeChangeParams(nextProps, newFilterNodes);
              onFilterChange(parentState);
            } else {
              this.renderChart(nextProps);
            }
          },
        );
      } else {
        this.renderChart(nextProps);
      }
    } else if (
      nextProps.relationCount !== this.props.relationCount ||
      nextProps.relationProbability !== this.props.relationProbability ||
      nextProps.filterModality !== this.props.filterModality
    ) {
      this.renderChart(nextProps);
    }
  }

  UNSAFE_componentWillMount() {
    this.clearChart();
  }

  @autobind
  reloadData(props, force = false) {
    const { causalIncidentInfo } = props;
    const { loadCausalIncident, incidentParams, instanceName, needCausalProperty } = props;
    const { causalKey, relationKey, customerName, causalName, startTimestamp, endTimestamp, fileName, joinDependency } =
      incidentParams;
    const causalType = 'correlation';
    let postFixStr = 'inter';
    if (instanceName) {
      postFixStr = `intra_${instanceName}`;
    }
    const postFix = `_${postFixStr}_${causalType}`;
    if (causalIncidentInfo) {
      loadCausalIncident(
        {
          causalType,
          causalKey,
          relationKey,
          customerName,
          causalName,
          instanceName,
          needCausalProperty,
          startTimestamp,
          endTimestamp,
          fileName,
          postFix,
          joinDependency,
        },
        force,
        { [this.incidentLoader]: true },
      );
    }
  }

  @autobind
  parseInitNode(props) {
    const { filterParams, causalIncident, causalInstanceIncident, instanceName } = props;

    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;
    const relationList = get(incident, ['relation'], []);
    const hasRelation = !isEmpty(relationList);

    let { filterNodes } = this.state;
    let allNodeList = [];
    let metric;
    let hasMetricRelation = true;
    // filter by ini params
    if (filterParams) {
      const { type, nid } = filterParams;
      metric = filterParams.metric;
      if (metric) {
        R.forEach((relation) => {
          allNodeList = [...allNodeList, relation.elem1, relation.elem2];
          if (relation.elem1.indexOf(metric) >= 0) {
            filterNodes = [relation.elem1];
          } else if (relation.elem2.indexOf(metric) >= 0) {
            filterNodes = [relation.elem2];
          }
        }, relationList);
      } else if (type && nid) {
        hasMetricRelation = false;
        const typeList = [];
        R.forEach((val) => {
          let onlyType = R.toLower(val);
          onlyType = onlyType.indexOf('(') > 0 ? onlyType.split('(')[0] : onlyType;
          typeList.push(onlyType);
        }, type.split('&'));

        R.forEach((relation) => {
          allNodeList = [...allNodeList, relation.elem1, relation.elem2];
          const { eventType: eventType1, postFix: elem1Nid } = relation.elem1Info;
          const { eventType: eventType2, postFix: elem2Nid } = relation.elem2Info;
          if (eventType1 && typeList.indexOf(R.toLower(eventType1)) >= 0 && elem1Nid === String(nid)) {
            filterNodes = [relation.elem1];
          } else if (eventType2 && typeList.indexOf(R.toLower(eventType2)) >= 0 && elem2Nid === String(nid)) {
            filterNodes = [relation.elem2];
          }
        }, relationList);
      }

      allNodeList = R.uniq(allNodeList);
    }
    return { allNodeList, filterNodes, hasRelation, metric, hasMetricRelation };
  }
  @autobind
  getRelationData(props) {
    const { causalIncidentProperty, causalIncident, causalInstanceIncident } = props;
    const { instanceName, relationCount, relationProbability, filterModality } = props;
    const { filterPattern } = this.state;
    const { filterNodes } = this.state;

    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;

    const causalNodeDegreeMap = get(incident, ['causalNodeDegreeMap'], {});
    let relationList = get(incident, ['relation'], []);
    const hasRelation = !isEmpty(relationList);

    // set all log node info
    this.relationElemInfoMap = get(incident, ['relationElemInfoMap'], {});

    // set instance map and intra instance list
    const { instancePropertyMap } = causalIncidentProperty || {};
    const instanceMapping = {};
    const intraInstanceList = [];
    R.forEachObjIndexed((value, instance) => {
      const appName = get(value, ['projectInstanceMetadata', 'componentName']);
      if (appName) {
        instanceMapping[instance] = appName;
      }
      if (value.possibleIntraCorrelation) {
        intraInstanceList.push(instance);
      }
    }, instancePropertyMap);

    // Get the node pattern map for instance relations.
    const nodePatternMap = {};
    if (hasInstance) {
      const patternSetting = (relation, type, contentPath) => {
        if (!nodePatternMap[relation[type]]) {
          const content = get(relation, contentPath, []);
          if (content && content.length > 0) {
            if (content[0].patternName) {
              nodePatternMap[relation[type]] = content[0].patternName;
            }
          }
        }
      };
      R.forEach((relation) => {
        patternSetting(relation, 'elem1', 'fromContents');
        patternSetting(relation, 'elem2', 'toContents');
      }, relationList);
    }

    // get app name for inter relationList
    relationList = R.map((relation) => {
      const { elem1, elem2 } = relation;
      let appName1 = elem1;
      let appName2 = elem2;
      if (!hasInstance) {
        appName1 =
          instanceMapping[elem1] && instanceMapping[elem1] !== appName1
            ? `${appName1}(${instanceMapping[elem1]})`
            : appName1;
        appName2 =
          instanceMapping[elem2] && instanceMapping[elem2] !== appName2
            ? `${appName2}(${instanceMapping[elem2]})`
            : appName2;
      } else {
        appName1 = nodePatternMap[elem1] || elem1;
        appName2 = nodePatternMap[elem2] || elem2;
      }
      return { ...relation, appName1, appName2 };
    }, relationList);

    // get filter options
    let allNodeOptions = R.uniqWith(
      R.eqBy(R.prop('value')),
      R.concat(
        R.map((relation) => {
          let label = relation.appName1;
          const { isLogType, eventType, postFix } = relation.elem1Info;
          if (hasInstance && isLogType) {
            label = `${postFix ? `[${postFix}] ` : ''}${eventType}:${label}`;
          }
          const outboundDegree = get(causalNodeDegreeMap, [relation.elem1, 'outboundDegree']);
          if (isNumber(outboundDegree)) {
            label = `${label}, outbound links: ${outboundDegree}`;
          }
          return { label, value: relation.elem1, outboundDegree };
        }, relationList),
        R.map((relation) => {
          let label = relation.appName2;
          const { isLogType, eventType, postFix } = relation.elem2Info;
          if (hasInstance && isLogType) {
            label = `${postFix ? `[${postFix}] ` : ''}${eventType}:${label}`;
          }
          const outboundDegree = get(causalNodeDegreeMap, [relation.elem2, 'outboundDegree']);
          if (isNumber(outboundDegree)) {
            label = `${label}, outbound links: ${outboundDegree}`;
          }
          return { label, value: relation.elem2, outboundDegree };
        }, relationList),
      ),
    );
    allNodeOptions = R.sortWith([R.descend(R.prop('outboundDegree'))], allNodeOptions);

    // filter
    if (relationProbability) {
      relationList = R.filter((relation) => relation.probability >= parseFloat(relationProbability), relationList);
      relationList = R.map((relation) => {
        const { probability, count } = relation;
        const newCount = get(relation, ['probabilityCountMap', relationProbability], count);
        return {
          ...relation,
          count: newCount,
          realtionColor: CausalParser.getColorByProbability(probability),
          relationWidth: CausalParser.getWidthByCount(newCount),
        };
      }, relationList);
    }
    if (relationCount) {
      relationList = R.filter((relation) => relation.count >= Number(relationCount), relationList);
    }
    if (filterNodes && isArray(filterNodes) && filterNodes.length > 0) {
      relationList = CausalParser.getAllRelatedList(relationList, filterNodes, 2);
    }
    if (filterPattern) {
      relationList = R.filter((relation) => {
        let result = false;
        if (R.find(R.propEq('nid', Number(filterPattern)))(relation.fromContents)) {
          result = true;
        } else if (R.find(R.propEq('nid', Number(filterPattern)))(relation.toContents)) {
          result = true;
        }
        return result;
      }, relationList);
    }
    if (filterModality && filterModality !== 'all') {
      relationList = R.filter((relation) => {
        let timePairs = relation.contentTimeDifference || [];
        if (relationProbability) {
          timePairs = R.filter((pair) => pair.probability >= parseFloat(relationProbability), timePairs);
        }
        timePairs = R.filter((pair) => pair.modality === filterModality, timePairs);
        return timePairs.length > 0;
      }, relationList);
    }

    // set relationInfoMap
    const relationInfoMap = {};
    R.forEach((relation) => {
      relationInfoMap[`${relation.elem1}-${relation.elem2}`] = relation;
    }, relationList);

    this.relationInfoMap = relationInfoMap;
    this.instanceMapping = instanceMapping;
    this.intraInstanceList = intraInstanceList;
    this.setState({
      hasRelation,
      relationList,
      allNodeOptions,
      filterNodes,
    });
    return { hasRelation, relationList, allNodeOptions, nodePatternMap, relationInfoMap };
  }

  clearChart() {
    if (this.tip) {
      this.tip.destroy();
    }

    if (this.container) {
      d3.select(this.container).select('svg').remove();
    }
  }

  renderChart(props) {
    this.clearChart();

    if (!this.container) return;

    // get data
    const { relationList, allNodeOptions, nodePatternMap, relationInfoMap } = this.getRelationData(props);
    const { intl, instanceName } = props;
    const { currentZoom, filterNodes, edgeRelation } = this.state;
    const instanceMapping = this.instanceMapping;
    const intraInstanceList = this.intraInstanceList;
    const hasInstance = Boolean(instanceName);

    // Get the node name and the content type for instance relations.
    const nodeMap = {};
    const typeSetting = (relation, type, contentPath) => {
      if (!nodeMap[relation[type]]) {
        if (!hasInstance) {
          nodeMap[relation[type]] = 'Instance';
        } else {
          const content = get(relation, contentPath, []);
          if (content && content.length > 0) {
            nodeMap[relation[type]] = content[0].type;
          }
        }
      }
    };
    R.forEach((relation) => {
      typeSetting(relation, 'elem1', 'fromContents');
      typeSetting(relation, 'elem2', 'toContents');
    }, relationList);

    const g = new dagre.graphlib.Graph({ directed: true, multigraph: true });
    const ranksep = this.baseSep + currentZoom * 30;
    const nodesep = this.baseSep + currentZoom * 20;
    const edgesep = this.baseSep + currentZoom * 10;
    g.setGraph({ rankdir: 'LR', align: 'DR', ranker: 'tight-tree', ranksep, nodesep, edgesep });

    const trimString = (str, n) => {
      if (str && str.length > n) {
        return `${str.substr(0, n)}..`;
      }
      return str;
    };
    const nodeNames = R.keys(nodeMap);
    R.forEach((id) => {
      const name = instanceMapping[id] || id;
      const hasIntra = ifIn(id, intraInstanceList);
      let label = `${hasIntra ? '*' : ''}${trimString(name, 18)}`;

      let nodeInfo = {};
      let typeOnly;
      if (hasInstance) {
        nodeInfo = this.relationElemInfoMap[id];
        const { isLogType, eventType, postFix } = nodeInfo;
        typeOnly = nodeInfo.typeOnly;
        if (isLogType) {
          label = `${postFix ? `[${postFix}] ` : ''}${eventType}`;
        }
      }
      return g.setNode(id, {
        id,
        shape: 'circle',
        hasIntra,
        nodeInfo,
        type: nodeMap[id],
        class: `${(typeOnly || 'instance').toLowerCase()} ${hasIntra ? 'clickable' : ''}`,
        label,
        name,
        width: -8,
        height: -8,
      });
    }, nodeNames);

    // Add lines
    const arrowhead = 'undirected';
    R.addIndex(R.forEach)((rel, idx) => {
      const { elem1, elem2, probability, count } = rel;
      const label = `${intl.formatMessage(appFieldsMessages.maxProb)} ${(probability * 100).toFixed(
        1,
      )}%, ${intl.formatMessage(appFieldsMessages.maxCount)} ${count}`;
      const meta = {
        id: `rel-edge-${idx + 1}`,
        relation: rel,
        type: 'relation',
        label: '',
        class: '',
        lineInterpolate: 'monotone',
        arrowhead,
        labelpos: 'l',
        labeloffset: 2,
      };
      g.setEdge(elem1, elem2, meta, 'correlation');
    }, relationList);

    const container = d3.select(this.container);
    const svg = container.append('svg');
    const inner = svg.append('g').attr('transform', `translate(${20},${40})`);
    const render = dagreD3.render();
    render(inner, g);

    this.tip = d3tip()
      .attr('class', 'd3-tip')
      .direction('e')
      .offset([0, 10])
      .html(({ d, n, e, g, instanceMapping, target }) => {
        if (n) {
          // node
          const { id, type, nodeInfo } = n;
          const { isLogType, contentInfo } = nodeInfo || {};

          let isLoading = false;
          const content = get(contentInfo, 'content', id);
          const patternName = get(contentInfo, 'patternName', id);
          const projectName = get(contentInfo, 'projectName', '');
          let renderMetricContent = null;
          let inRelations = [];
          let outRelations = [];
          let inRelationsCount = 0;
          let outRelationsCount = 0;

          if (type === 'Instance') {
            const edges = g.nodeEdges(d);
            const nodeRelations = R.filter(
              (relation) => relation,
              R.map((edge) => get(relationInfoMap, `${edge.v}-${edge.w}`), edges),
            );
            inRelations = R.filter((relation) => relation.elem2 === d, nodeRelations);
            outRelations = R.filter((relation) => relation.elem1 === d, nodeRelations);
            inRelationsCount = inRelations.length;
            outRelationsCount = outRelations.length;
            inRelations = R.take(3, inRelations);
            outRelations = R.take(3, outRelations);
          } else if (isLogType && !R.has(id, this.nodeLogContent)) {
            isLoading = true;
            // call api to get log content
            this.getNodeLogContent({ d, n, g, instanceMapping, target });
          } else if (!isLogType) {
            const avgValue = contentInfo.avgValue;
            const isHigher = ['positive', 'higher'].indexOf((contentInfo.metricDirection || '').toLowerCase()) >= 0;
            const isLower = ['negative'].indexOf((contentInfo.metricDirection || '').toLowerCase()) >= 0;
            renderMetricContent = (
              <div className="flex-row" style={{ marginTop: 8 }}>
                <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                  {intl.formatMessage(appFieldsMessages.value)}:
                </div>
                <div>{round(avgValue, 2)}</div>
                {isHigher && <i className="icon up arrow" />}
                {isLower && <i className="icon down arrow" />}
              </div>
            );
          }
          if (isLoading) {
            return ReactDOMServer.renderToStaticMarkup(
              <div className="flex-row flex-center-justify flex-center-align" style={{ minHeight: 60, minWidth: 160 }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
              </div>,
            );
          }
          return ReactDOMServer.renderToStaticMarkup(
            <div
              className={`flex-col overflow-y-auto`}
              style={{ fontSize: 12, paddingRight: 8, minHeight: 60, minWidth: 160, maxHeight: 300, maxWidth: 400 }}
            >
              <div className="flex-row" style={{ borderBottom: '1px solid #ccc', paddingBottom: 4 }}>
                <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                  {!hasInstance
                    ? intl.formatMessage(appFieldsMessages.instance)
                    : isLogType
                    ? intl.formatMessage(appFieldsMessages.pattern)
                    : intl.formatMessage(appFieldsMessages.metric)}
                  :
                </div>
                <div>{patternName}</div>
              </div>

              {!hasInstance && (
                <div className="flex-row" style={{ borderBottom: '1px solid #ccc', paddingBottom: 4 }}>
                  <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                    {intl.formatMessage(appFieldsMessages.project)}:
                  </div>
                  <div>{projectName}</div>
                </div>
              )}

              {hasInstance && isLogType && (
                <div className="flex-row" style={{ marginTop: 4, wordBreak: 'break-word' }}>
                  {this.nodeLogContent[id] || content}
                </div>
              )}
              {hasInstance && !isLogType && renderMetricContent}
              {!hasInstance && inRelations.length > 0 && (
                <div className="flex-row" style={{ marginTop: 4 }}>
                  <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                    {intl.formatMessage(causalMessages.inboundNodeTop, { top: '(Top3)' })}:
                  </div>
                  <div className="flex-col">
                    {R.addIndex(R.map)((relation, idx) => {
                      return (
                        <div key={idx}>
                          <div
                            style={{
                              fontWeight: 500,
                              display: 'inline-block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >{`${relation.elem1}`}</div>
                          <div>{`${intl.formatMessage(appFieldsMessages.probability)}: ${round(
                            relation.probability * 100,
                            1,
                          )}%, ${intl.formatMessage(appFieldsMessages.count)}: ${relation.count}`}</div>
                        </div>
                      );
                    }, inRelations)}
                    {inRelationsCount > 3 && <div>......</div>}
                  </div>
                </div>
              )}
              {!hasInstance && outRelations.length > 0 && (
                <div className="flex-row" style={{ marginTop: 4 }}>
                  <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                    {intl.formatMessage(causalMessages.outboundNodeTop, { top: '(Top3)' })}:
                  </div>
                  <div className="flex-col">
                    {R.addIndex(R.map)((relation, idx) => {
                      return (
                        <div key={idx}>
                          <div
                            style={{
                              fontWeight: 500,
                              display: 'inline-block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >{`${relation.elem2}`}</div>
                          <div>{`${intl.formatMessage(appFieldsMessages.probability)}: ${round(
                            relation.probability * 100,
                            1,
                          )}%, ${intl.formatMessage(appFieldsMessages.count)}: ${relation.count}`}</div>
                        </div>
                      );
                    }, outRelations)}
                    {outRelationsCount > 3 && <div>......</div>}
                  </div>
                </div>
              )}
            </div>,
          );
        } else if (e) {
          // edge
          const { relation } = e;
          return '';
        }
        return d;
      });
    const tip = this.tip;
    svg.call(tip);

    // The callback cannot use lambda, since d3tip needs 'this'.
    svg
      .selectAll('.node>g')
      .on('mouseover', function (d) {
        const n = g.node(d);
        if (n) {
          setTimeout(() => {
            tip.show({ d, n, g, instanceMapping, target: this }, this);
            // add tip control, allow mouse into tip and hold the tip.
            $('div.d3-tip')
              .unbind('mouseenter mouseleave')
              .bind('mouseenter', () => {
                tip.hover = true;
                // console.log('tip mouseenter');
              })
              .bind('mouseleave', () => {
                tip.hover = false;
                tip.hide(d);
                // console.log('tip mouseleave');
              });
          }, 300);
        }
      })
      .on('mouseout', function (d) {
        const n = g.node(d);
        setTimeout(() => {
          if (!tip.hover) {
            $('div.d3-tip').unbind('mouseenter mouseleave');
            tip.hide(d);
          }
        }, 300);
      });
    svg
      .selectAll(`path`)
      .style('stroke', (d) => {
        const relation = relationInfoMap[`${d.v}-${d.w}`];
        return get(relation, ['realtionColor'], '#ccc');
      })
      .style('stroke-width', (d) => {
        const relation = relationInfoMap[`${d.v}-${d.w}`];
        return `${get(relation, ['relationWidth'], 2)}px`;
      })
      .on('mouseover', function (d) {
        const e = g.edge(d);
        if (e) {
          const relation = relationInfoMap[`${d.v}-${d.w}`];
          const { id } = e;
          svg
            .select(`path#${id}`)
            .classed('hover', true)
            .style({
              'stroke-width': `${get(relation, ['relationWidth'], 2) + 0.5}px`,
            });
        }
      })
      .on('mouseout', function (d) {
        const e = g.edge(d);
        if (e) {
          const relation = relationInfoMap[`${d.v}-${d.w}`];
          const { id } = e;
          svg
            .select(`path#${id}`)
            .classed('hover', false)
            .style({
              'stroke-width': `${get(relation, ['relationWidth'], 2)}px`,
            });
        }
      });

    // Add click event for instance
    svg.selectAll('.instance.node').on('click', (d) => {
      const n = g.node(d);
      if (n.hasIntra) {
        this.handleInstanceClick(n.id);
      }
    });

    svg.selectAll('path').on('click', (d) => {
      const e = g.edge(d);
      this.handleEdgeClick(e);
    });

    const gbox = inner.node().getBBox();
    // Add some spaces for tooltip
    const width = gbox.width + 500;
    const height = gbox.height + 100;
    svg.attr({ width, height, viewBox: `${gbox.x} ${gbox.y} ${width} ${height}` });

    // center the graph
    if (filterNodes && filterNodes.length > 0) {
      // use the first filter node to center
      const filterNode = filterNodes[0];
      const { id, x, y } = g.node(filterNode) || {};
      if (id) {
        container.property('scrollLeft', x - container.property('clientWidth') / 2 + 100);
        container.property('scrollTop', y - container.property('clientHeight') / 2 + 60);
      }
    } else if (edgeRelation) {
      const { id, x, y } = g.node(edgeRelation.elem1) || {};
      if (id) {
        container.property('scrollLeft', x - container.property('clientWidth') / 2 + 100);
        container.property('scrollTop', y - container.property('clientHeight') / 2 + 60);
      }
      const selectEdge = g.edge(edgeRelation.elem1, edgeRelation.elem2, 'correlation');
      if (selectEdge) {
        svg
          .select(`path#${selectEdge.id}`)
          .style('stroke', (d) => {
            return 'red';
          })
          .style('stroke-width', (d) => {
            return `3px`;
          });
      }
    } else if (allNodeOptions.length > 0) {
      const { id, x, y } = g.node(allNodeOptions[0].value) || {};
      if (id) {
        container.property('scrollLeft', x - container.property('clientWidth') / 2 + 100);
        container.property('scrollTop', y - container.property('clientHeight') / 2 + 60);
      }
    }
  }

  @autobind
  getNodeLogContent({ d, n, g, instanceMapping, target }) {
    const { id, nodeInfo } = n;
    const { typeOnly, contentInfo } = nodeInfo || {};
    const { nid, timeStamp, type } = contentInfo || {};

    const { credentials, causalIncidentProperty, incidentParams, instanceName } = this.props;
    const customerName = get(incidentParams, 'customerName');
    const { instancePropertyMap } = causalIncidentProperty || {};
    const intraInstanceMap = get(instancePropertyMap, instanceName, {});
    const logProjectName = intraInstanceMap.logProjectName;
    const logInstanceName = intraInstanceMap.logInstanceName;
    if (logProjectName && logInstanceName && nid && timeStamp && type) {
      const logEventQueryStr = [
        {
          id: `${logProjectName}-${logInstanceName}-${nid}-${typeOnly}`,
          logProjectName,
          logInstanceName,
          nid: String(nid),
          timestamp: timeStamp,
          type,
        },
      ];
      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('loadlogeventsforcausal'), {
        ...credentials,
        customerName,
        logEventQueryStr: JSON.stringify(logEventQueryStr),
      }).then((data) => {
        const logContents = get(data, [logInstanceName, typeOnly], []);
        const logContent = logContents.length > 0 ? logContents[0].logContent : '';
        this.nodeLogContent[id] = logContent;
        setTimeout(() => this.tip.show({ d, n, g, instanceMapping, target }, target), 600);
      });
    }
  }

  @autobind
  handleZoomInClick() {
    let { currentZoom } = this.state;
    currentZoom += 1;
    this.setState({ currentZoom }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  handleZoomOutClick() {
    let { currentZoom } = this.state;
    currentZoom = Math.max(currentZoom - 1, 1);
    this.setState({ currentZoom }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  handleInstanceClick(instanceName) {
    this.setState(
      {
        filterNodes: null,
        edgeRelation: null,
        pattern: '',
        filterPattern: '',
      },
      () => {
        this.clearChart();
        this.props.onInstanceChange(instanceName);
      },
    );
  }

  @autobind
  handleInstanceIdClick(instanceName) {
    return () => {
      this.handleInstanceClick(instanceName);
    };
  }

  @autobind
  handleBackToInter() {
    const { iniInstanceName } = this.props;
    const filterNodes = iniInstanceName ? [iniInstanceName] : [];
    this.setState(
      {
        filterNodes,
        edgeRelation: null,
        pattern: '',
        filterPattern: '',
      },
      () => {
        if (iniInstanceName) {
          this.props.onInstanceChange(null);
          this.handleFilterNodeChange(filterNodes);
        } else {
          this.props.onInstanceChange(null);
        }
      },
    );
  }

  @autobind
  handleEdgeClick(e) {
    const logFilter = (item) => {
      const { isLogType } = CausalParser.getRelationLogType(item.type);
      return isLogType;
    };
    const relation = get(e, 'relation', {});
    const leftContents = R.filter(logFilter, get(relation, 'fromContents', []));
    const rightContents = R.filter(logFilter, get(relation, 'toContents', []));
    const leftLabel = relation.elem1;
    const rightLabel = relation.elem2;

    this.setState({ showLogModal: true, edgeRelation: relation, leftContents, rightContents, leftLabel, rightLabel });
  }

  @autobind
  handleLogModalClose() {
    this.setState({ showLogModal: false, edgeRelation: null, leftContents: [], rightContents: [] });
  }

  @autobind
  getNodeChangeParams(props, filterNodes) {
    const { instanceName, causalIncident, causalInstanceIncident } = props;
    const hasInstance = Boolean(instanceName);
    const incident = hasInstance ? causalInstanceIncident : causalIncident;
    const causalNodeDegreeMap = get(incident, ['causalNodeDegreeMap'], {});

    let relationCount = null;
    let relationProbability = null;
    R.forEach((item) => {
      relationCount = isNumber(relationCount)
        ? R.min(relationCount, get(causalNodeDegreeMap, [item, 'maximumCount'], 1))
        : get(causalNodeDegreeMap, [item, 'maximumCount'], 1);
      relationProbability = isNumber(relationProbability)
        ? R.min(relationProbability, get(causalNodeDegreeMap, [item, 'maximumProbability'], 0))
        : get(causalNodeDegreeMap, [item, 'maximumProbability'], 0);
    }, filterNodes || []);

    const parentState = {};
    if (isNumber(relationCount)) parentState.relationCount = relationCount;
    if (isNumber(relationProbability))
      parentState.relationProbability = numeral(toInteger(relationProbability * 10) / 10).format('0.0');
    return parentState;
  }

  @autobind
  handleFilterNodeChange(filterNodes) {
    const parentState = this.getNodeChangeParams(this.props, filterNodes);
    const { onFilterChange } = this.props;
    onFilterChange(parentState, () => {
      this.setState({ currentZoom: this.defaultZoom, filterNodes }, () => {
        this.renderChart(this.props);
      });
    });
  }

  @autobind
  handleFilterChange() {
    this.setState({ currentZoom: this.defaultZoom }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  iconSearchClick(event) {
    event.stopPropagation();
    event.preventDefault();

    const { pattern } = this.state;
    this.setState({ filterPattern: pattern }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  handleRelationSelect(edgeRelation) {
    this.setState({ edgeRelation }, () => {
      this.renderChart(this.props);
    });
  }

  @autobind
  hideLeftContent() {
    this.setState({ showLeftContent: false, showRightArrowIcon: true });
  }

  @autobind
  showLeftContent() {
    this.setState({ showLeftContent: true, showRightArrowIcon: false });
  }

  render() {
    const {
      intl,
      causalIncidentProperty,
      instanceName,
      incidentParams,
      currentLoadingComponents,
      relationProbability,
      filterModality,
    } = this.props;
    const { showLogModal, edgeRelation, leftContents, rightContents, leftLabel, rightLabel } = this.state;
    const { hasRelation, relationList, allNodeOptions, showLeftContent, showRightArrowIcon } = this.state;

    const instanceMapping = this.instanceMapping;
    const intraInstanceList = this.intraInstanceList;
    const { instancePropertyMap } = causalIncidentProperty || {};
    const appName = instanceMapping[instanceName] || instanceName;
    const hasInstance = Boolean(instanceName);

    const filterNodesLink = VLink.state(this, 'filterNodes').onChange(this.handleFilterNodeChange);
    const filterPatternLink = VLink.state(this, 'pattern');

    const isIncidentLoading = get(currentLoadingComponents, this.incidentLoader, false);
    return (
      <Container className={`flex-grow flex-col ${isIncidentLoading ? 'loading' : ''}`}>
        {!hasRelation && (
          <Container className="chart message flex-grow">
            {!hasInstance && (
              <div
                className="ui mini warning message"
                style={{
                  maxHeight: 80,
                  overflowY: 'auto',
                  wordBreak: 'break-all',
                }}
              >
                {intl.formatMessage(causalMessages.noConcurrentAnomaliesFoundLongInfo)}:
                {intraInstanceList.length > 0 &&
                  R.map(
                    (instanceMapping) => (
                      <span
                        className="link"
                        key={instanceMapping}
                        onClick={this.handleInstanceIdClick(instanceMapping)}
                      >
                        {instanceMapping[instanceName] && instanceMapping[instanceName] !== instanceName
                          ? `${instanceMapping[instanceName]}(${instanceName})`
                          : instanceName}
                      </span>
                    ),
                    intraInstanceList,
                  )}
              </div>
            )}
            {hasInstance && (
              <div className="ui mini warning message" style={{ wordBreak: 'break-all' }}>
                {intl.formatMessage(causalMessages.noConcurrentAnomaliesFound)}&nbsp;
                <span className="instance">{appName}</span>
                ,&nbsp;
                <span className="link" onClick={this.handleBackToInter}>
                  {intl.formatMessage(causalMessages.backToInstances)}
                </span>
              </div>
            )}
          </Container>
        )}
        {hasRelation && !hasInstance && intraInstanceList.length > 0 && (
          <Container
            className="chart"
            style={{
              width: '100%',
              border: 'none',
            }}
          >
            <div
              className="ui mini warning message"
              style={{
                maxHeight: 80,
                overflowY: 'auto',
                wordBreak: 'break-all',
              }}
            >
              {intl.formatMessage(causalMessages.viewRelationForInstance)}:
              {intraInstanceList.length > 0 &&
                R.map(
                  (instanceName) => (
                    <span className="link" key={instanceName} onClick={this.handleInstanceIdClick(instanceName)}>
                      {instanceMapping[instanceName] && instanceMapping[instanceName] !== instanceName
                        ? `${instanceMapping[instanceName]}(${instanceName})`
                        : instanceName}
                    </span>
                  ),
                  intraInstanceList,
                )}
            </div>
          </Container>
        )}
        <Container className={`${hasRelation ? '' : 'invisible '}chart flex-col flex-grow`}>
          <div className="toolbar" style={{ height: 30 }}>
            {!hasInstance && (
              <div className="title">
                <h4>{intl.formatMessage(causalMessages.instanceRelations)}</h4>
              </div>
            )}
            {hasInstance && (
              <div className="title">
                <h4>
                  <Popover
                    title={null}
                    content={intl.formatMessage(causalMessages.backToInstances)}
                    placement="top"
                    mouseEnterDelay={0.3}
                  >
                    <i className="arrow left icon" onClick={this.handleBackToInter} />
                  </Popover>
                  {intl.formatMessage(causalMessages.eventsRelationsForInstance)}:
                  <span className="instance">
                    <i className="circle icon" />
                    {appName}
                  </span>
                </h4>
              </div>
            )}
          </div>
          <div className="flex-grow flex-row">
            {showRightArrowIcon && <DoubleRightOutlined style={{ float: 'left' }} onClick={this.showLeftContent} />}
            {showLeftContent && (
              <div
                className="flex-col"
                style={{
                  width: 260,
                  padding: '0 4px',
                  borderRight: '1px solid #0000001f',
                  marginBottom: 8,
                }}
              >
                <div style={{ fontWeight: 'bold', height: 24 }}>
                  {intl.formatMessage(causalMessages.concurrentAnomaliesInfo)}:
                  <DoubleLeftOutlined style={{ float: 'right' }} onClick={this.hideLeftContent} />
                </div>
                <div className="flex-grow">
                  <AutoSizer>
                    {({ width, height }) => (
                      <div style={{ width, height, overflowY: 'auto' }}>
                        {R.addIndex(R.map)((relationInfo, index) => {
                          return CausalRenderers.RenderConcurrentInfo({
                            intl,
                            relationInfo,
                            index,
                            showRelationClick: true,
                            onRelationClick: this.handleRelationSelect,
                          });
                        }, R.sortWith([R.descend(R.prop('count')), R.descend(R.prop('probability'))], relationList))}
                      </div>
                    )}
                  </AutoSizer>
                </div>
              </div>
            )}
            <div className="flex-grow flex-col">
              <div className="flex-row" style={{ flexWrap: 'wrap', padding: '0 8px 14px 8px' }}>
                <div className="flex-row" style={{ alignItems: 'center', marginBottom: 4, paddingRight: 8 }}>
                  <label style={{ width: 100, fontWeight: 500 }}>
                    {hasInstance
                      ? intl.formatMessage(causalMessages.nodeFilter)
                      : intl.formatMessage(causalMessages.instanceFilter)}
                    :
                  </label>
                  <Select
                    name="nodes"
                    style={hasInstance ? { width: 450 } : { width: 200 }}
                    multi
                    autosize
                    options={allNodeOptions}
                    valueLink={filterNodesLink}
                  />
                </div>
                {!hasInstance && (
                  <div className="flex-row" style={{ alignItems: 'center', marginBottom: 4, paddingRight: 8 }}>
                    <label style={{ width: 100, fontWeight: 500 }}>
                      {intl.formatMessage(causalMessages.patternFilter)}:
                    </label>
                    <div style={{ width: 200 }}>
                      <Input
                        type="number"
                        valueLink={filterPatternLink}
                        icon="icon search"
                        fullWidth
                        placeholder=""
                        iconSearch
                        iconSearchClick={this.iconSearchClick}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-grow">
                <AutoSizer>
                  {({ width, height }) => (
                    <div style={{ width, height }}>
                      <div
                        className="d3-container"
                        ref={(c) => {
                          this.container = c;
                        }}
                      />
                    </div>
                  )}
                </AutoSizer>

                <div className="zoom">
                  <i className="plus icon" onClick={this.handleZoomInClick} />
                  <i className="minus icon" onClick={this.handleZoomOutClick} />
                </div>

                <div className="flex-col" style={{ position: 'absolute', top: 8, right: 12, background: 'white' }}>
                  <div className="flex-row">
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[0] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=90%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[1] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=70%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[2] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=50%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[3] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=30%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: '#2cb37d' }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'<30%'}
                      </span>
                    </div>
                  </div>
                  <div className="flex-row" style={{ marginTop: 4 }}>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{ width: 14, border: 'none', borderTop: '4px solid gray', margin: 'auto 4px auto 2px' }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=500'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{
                          width: 14,
                          border: 'none',
                          borderTop: '3.5px solid gray',
                          margin: 'auto 4px auto 2px',
                        }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=100'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{ width: 14, border: 'none', borderTop: '3px solid gray', margin: 'auto 4px auto 2px' }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=50'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{
                          width: 14,
                          border: 'none',
                          borderTop: '2.5px solid gray',
                          margin: 'auto 4px auto 2px',
                        }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=10'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{ width: 14, border: 'none', borderTop: '2px solid gray', margin: 'auto 4px auto 2px' }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'<10'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {showLogModal && (
          <EventRelationModal
            isIntra={hasInstance}
            leftContents={leftContents}
            leftLabel={leftLabel}
            rightLabel={rightLabel}
            edgeRelation={edgeRelation}
            rightContents={rightContents}
            instanceMapping={instanceMapping}
            incidentParams={incidentParams}
            operation={hasInstance ? 'intra' : 'inter'}
            instancePropertyMap={instancePropertyMap}
            intraInstanceName={instanceName || ''}
            relationType="correlation"
            isCorrelation
            srcInstance={leftLabel}
            targetInstance={rightLabel}
            filterModality={filterModality}
            relationProbability={relationProbability}
            onClose={this.handleLogModalClose}
          />
        )}
      </Container>
    );
  }
}

const ComponentCorrelation = injectIntl(ComponentCorrelationCore);
export default connect(
  (state: State) => {
    const { credentials } = state.auth;
    const { location } = state.router;
    const { userName } = state.auth.userInfo;
    const { currentLoadingComponents, projects } = state.app;
    const { incidentData, instanceIncidentData, incidentCausalProperty } = state.causal;
    return {
      location,
      userName,
      credentials,
      currentLoadingComponents,
      projects,
      causalIncident: get(incidentData, 'data', null),
      causalInstanceIncident: get(instanceIncidentData, 'data', null),
      causalIncidentProperty: incidentCausalProperty || {},
    };
  },
  {
    push,
    replace,
    updateLastActionInfo,
    loadCausalIncident,
    resetCausalIncidentData,
  },
)(ComponentCorrelation);
