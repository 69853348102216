/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get, round } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { State } from '../../common/types';
import { calcAnomalyLevel, calcColorOfAnomalyLevel, buildUrl, Defaults } from '../../common/utils';
import { Container, Tooltip } from '../../lib/fui/react';
import { createLoadAction } from '../../common/app/actions';
import './EventTimelineBar.scss';
import { DefaultRenderer } from 'react-tagcloud';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,

  eventTimelines: Array<Object>,
  startTimestamp: Number,
  currentTimestamp: Number,
  endTimestamp: Number,
  onBarItemClick: Function,
  onBarItemTooltipRender: Function,
  interval: Number,

  showNumberTooltip: Boolean,
  showHasPredictionSourceInfo: Boolean,
};

class EventTimelineBarCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.barItemWidth = 5;
    this.barItemInterval = 10 * 60 * 1000;
    this.barItemHeight = 14;
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  getItemInterval() {
    const { interval } = this.props;
    if (interval > 24 * 60 * 60 * 1000) {
      return this.barItemInterval * 3; // 30 mins
    } else if (interval > 2 * 60 * 60 * 1000) {
      return this.barItemInterval; // 10 mins
    } else if (interval > 30 * 60 * 1000) {
      return this.barItemInterval / 2; // 5 mins
    }
    return this.barItemInterval / 10; // 1mins
  }

  @autobind
  reloadData(props) {}

  @autobind
  handleBarClick(item) {
    return (event) => {
      event.preventDefault();
      event.stopPropagation();

      const ownEvents = get(item, ['ownEvents'], []);
      if (ownEvents.length > 0) {
        this.props.onBarItemClick(ownEvents);
      }
    };
  }

  @autobind
  handleBarItemClick(item) {
    return (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.props.onBarItemClick([item]);
    };
  }

  @autobind
  renderBarItems({ width, height }) {
    const {
      onBarItemClick,
      project,
      onBarItemTooltipRender,
      startTimestamp,
      currentTimestamp,
      endTimestamp,
      showNumberTooltip,
      showHasPredictionSourceInfo,
    } = this.props;
    let { eventTimelines } = this.props;
    eventTimelines = R.sortWith([R.ascend(R.prop('startTimestamp'))], eventTimelines);
    const isLog = get(project, 'isLog', false);
    const currentTime = moment.utc().valueOf();

    const barItemInterval = this.getItemInterval();

    let barItems = [];
    if (startTimestamp && endTimestamp) {
      const barItemTotal = round((endTimestamp - startTimestamp) / barItemInterval);
      barItems = R.map((i) => {
        return {
          barWidth: `${100 / barItemTotal}%`,
          id: i,
          index: i,
        };
      }, R.range(0, barItemTotal));
    }

    let barItemsAll = [];
    if (startTimestamp && endTimestamp && barItems.length > 0) {
      R.forEach((bar) => {
        const { index } = bar;
        const item = {
          ...bar,
          duration: barItemInterval,
          startTimestamp: startTimestamp + index * barItemInterval,
          endTimestamp: startTimestamp + (index + 1) * barItemInterval - 1,
        };
        item.isCurrent = item.startTimestamp <= currentTime && currentTime < item.endTimestamp;
        barItemsAll.push(item);
      }, barItems);
    }
    barItemsAll = R.map((bar) => {
      const scores = [];
      const ownEvents = [];
      let hasPredictionSourceInfo = false;
      const startEvents = [];
      let metricList = [];
      R.forEach((event) => {
        const anomalyScoreList = get(event, ['anomalyScoreList'], []);
        const { score: eventScore } = event;

        let isEventBucket = false;
        if (
          (event.startTimestamp >= bar.startTimestamp && event.startTimestamp < bar.endTimestamp) ||
          (event.startTimestamp < bar.endTimestamp && event.endTimestamp >= bar.startTimestamp)
        ) {
          isEventBucket = true;
          ownEvents.push(event);
          if (event.predictionSourceInfo) {
            hasPredictionSourceInfo = true;
          }
        }

        if (event.startTimestamp >= bar.startTimestamp && event.startTimestamp < bar.endTimestamp) {
          isEventBucket = true;
          startEvents.push(event);
        }

        // If we have anomaly score list, use the list, otherwise use event score
        let hasAnomalyScore = false;
        if (isEventBucket && anomalyScoreList.length > 0) {
          R.forEach((anomaly) => {
            if (!hasAnomalyScore && anomaly.timestamp < bar.endTimestamp) {
              const metricSet = get(anomaly, ['metricSet'], []);
              metricList = R.concat(metricList, metricSet);
              hasAnomalyScore = true;
              const score = anomaly.anomalyScore;
              scores.push(isLog ? score / 10 : score);
            }
          }, R.reverse(event.anomalyScoreList || []));
        }

        if (
          !hasAnomalyScore &&
          ((event.startTimestamp >= bar.startTimestamp && event.startTimestamp < bar.endTimestamp) ||
            (event.startTimestamp < bar.endTimestamp && event.endTimestamp >= bar.startTimestamp))
        ) {
          scores.push(isLog ? eventScore / 10 : eventScore);
        }
      }, eventTimelines);
      const maxScore = R.reduce(R.max, 0, scores);
      return {
        ...bar,
        score: maxScore,
        metricList: R.uniq(metricList),
        ownEvents,
        hasPredictionSourceInfo,
        startEvents,
      };
    }, barItemsAll);

    return (
      <div className="flex-row" style={{ height }}>
        {R.addIndex(R.map)((bar, index) => {
          const color = calcColorOfAnomalyLevel(calcAnomalyLevel(bar.score)).color;
          const barStyle = {
            height: '100%',
            width: '100%',
            backgroundColor: color,
            cursor: bar.ownEvents.length > 0 ? 'pointer' : undefined,
          };
          if (showHasPredictionSourceInfo && bar.hasPredictionSourceInfo) {
            barStyle.border = '1px solid red';
          }
          return (
            <div key={index} style={{ width: bar.barWidth }}>
              <Tooltip title={onBarItemTooltipRender(bar)} placement="top" style={{ height: '100%', width: '100%' }}>
                <div style={{ height: '100%', width: '100%', borderLeft: bar.isCurrent ? '1px solid black' : '' }}>
                  <div className="bar-item" id={bar.id} style={barStyle} onClick={this.handleBarClick(bar)} />
                </div>
              </Tooltip>
              {bar.isCurrent && <div className="current-time-bar" style={{}} />}
              {showNumberTooltip && bar.startEvents.length > 0 && (
                <div>
                  {R.addIndex(R.map)(
                    (event, idx) => (
                      <div
                        key={idx}
                        className="bar-item-tooltip"
                        style={{ cursor: 'pointer' }}
                        onClick={this.handleBarItemClick(event)}
                      >
                        {event.neuronId}
                      </div>
                    ),
                    bar.startEvents,
                  )}
                </div>
              )}
            </div>
          );
        }, barItemsAll)}
      </div>
    );
  }

  render() {
    const { width, eventTimelines, startTimestamp, endTimestamp } = this.props;

    const middleTimestamp = round((startTimestamp + endTimestamp) / 2, 0);

    return (
      <Container className="event-timeline-bar">
        <div style={{ width, height: this.barItemHeight + 2 }}>
          {this.renderBarItems({ width, height: this.barItemHeight })}
        </div>
        <div>
          <div className="time-start axis" style={{ marginTop: 6 }}>
            {moment.utc(startTimestamp).format(Defaults.ShortTimeFormat)}
          </div>
          <div className="time-middle axis" style={{ marginTop: 6 }}>
            {moment.utc(middleTimestamp).format(Defaults.ShortTimeFormat)}
          </div>
          <div className="time-end axis" style={{ marginTop: 6 }}>
            {moment.utc(endTimestamp).format(Defaults.ShortTimeFormat)}
          </div>
        </div>
      </Container>
    );
  }
}

const EventTimelineBar = injectIntl(EventTimelineBarCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    return { location, loadStatus };
  },
  { createLoadAction },
)(EventTimelineBar);
