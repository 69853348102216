// import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getLineChartInstanceDisplayName = (credentials: Credentials, params: Object) => {
  return fetchGet(getEndpoint('instance-display-name'), {
    ...credentials,
    ...params,
  }).then((data) => {
    return data;
  });
};

export default getLineChartInstanceDisplayName;
