/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';

const getLogKeywordEntryList = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, startTimestamp, endTimestamp, day, keyword, pageNo, pageSize } = params;
  const dayObj = moment.utc(day, Defaults.DateFormat).startOf('day');
  const requests = [];
  requests.push(
    fetchGet(getEndpoint('logstreamingevent'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: dayObj.valueOf(),
      keyword,
      pageNo,
      pageSize,
      ...(startTimestamp && endTimestamp ? { startTimestamp, endTimestamp } : {}),
    }),
  );

  return Promise.all(requests).then((results) => {
    const logEntryList = R.filter((e) => e.timestamp, get(results[0], 'eventArray', []));
    const totalMatched = get(results[0], 'totalMatched', 0);
    return { logEntryList, totalMatched };
  });
};

export default getLogKeywordEntryList;
