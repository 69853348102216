/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get, debounce } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, TreeSelect, Alert, Form, Checkbox, Divider, Select, message, Upload, Input } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Container } from '../../../../lib/fui/react';
import { MetricParser } from '../../../../common/utils';

import { appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

class EndpointSettingGoogleCloudCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoadingKey: false,
      isLoading: false,
      isUpdateKey: false,
      isSubmitting: false,
      errorMessageKey: '',
      errorMessage: '',

      isLoadingFile: false,
      configFileList: [],

      privateKeyId: '',
      privateKey: '',
      clientId: '',
      clientEmail: '',
      projectId: '',

      instanceTypeMetricsMap: {},
      instanceTypeList: [],
      preSelectedMetrics: [],
      instanceTypes: [],
      treeData: [],
      treeDataKeys: [],
      selectedMetrics: [],
      treeDataKpi: [],
      treeDataKeysKpi: [],
      kpiMetrics: [],
    };
    this.privateKeyId = '';
    this.privateKey = '';
    this.clientId = '';
    this.clientEmail = '';
    this.projectId = '';

    this.onBuildKPIMetricsThrottled = debounce(this.onBuildKPIMetrics, 600);
  }

  componentDidMount() {
    this.reloadKey(this.props);
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadKey(nextProps);
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadKey(props) {
    const { credentials, projectName, currentProject } = props;

    this.setState({ isLoadingKey: true });
    const cloudType = get(currentProject, ['cloudType']);
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const { success, message, privateKeyId, privateKey, clientId, clientEmail, projectId } = data || {};
        if (success === undefined || success) {
          this.privateKeyId = privateKeyId;
          this.privateKey = privateKey;
          this.clientId = clientId;
          this.clientEmail = clientEmail;
          this.projectId = projectId;
          this.setState({
            isLoadingKey: false,
            errorMessageKey: undefined,

            privateKeyId: privateKeyId || '',
            privateKey: privateKey || '',
            clientId: clientId || '',
            clientEmail: clientEmail || '',
            projectId: projectId || '',
          });
        } else {
          this.setState({
            isLoadingKey: false,
            errorMessageKey: message,

            privateKeyId: '',
            privateKey: '',
            clientId: '',
            clientEmail: '',
            projectId: '',
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoadingKey: false,
          errorMessageKey: errMsg || String(err),

          privateKeyId: '',
          privateKey: '',
          clientId: '',
          clientEmail: '',
          projectId: '',
        });
      });
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName } = props;

    this.setState({ isLoading: true });
    return fetchGet(getEndpoint('googlecloud-metrics-setting'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const { success, message, metricsList, metrics, preSelected, kpiMetrics } = data || {};
        if (success === undefined || success) {
          // build instanceType Metrics Map
          const instanceTypeMetricsMap = {};
          R.forEach((val) => {
            R.forEach((item) => {
              const { instanceType, metrics } = item;
              if (!R.has(instanceType, instanceTypeMetricsMap)) instanceTypeMetricsMap[instanceType] = [];

              instanceTypeMetricsMap[instanceType] = [...instanceTypeMetricsMap[instanceType], ...(metrics || [])];
            }, val || []);
          }, metricsList);
          const instanceTypeList = R.keys(instanceTypeMetricsMap);

          // set selected metrics
          let selectedMetrics = [];
          R.forEach((item) => {
            selectedMetrics = [...selectedMetrics, ...(item || [])];
          }, metrics);

          // set default instanceTypes
          const instanceTypes = [];
          R.forEachObjIndexed((allMetrics, instanceType) => {
            if (!R.isEmpty(R.intersection(selectedMetrics, allMetrics))) {
              instanceTypes.push(instanceType);
            }
          }, instanceTypeMetricsMap);

          // build tree data
          let allMetrics = [];
          R.forEachObjIndexed((metrics) => {
            allMetrics = [...allMetrics, ...metrics];
          }, R.pick(instanceTypes, instanceTypeMetricsMap));
          const { treeData, treeDataKeys } = MetricParser.getMetricTreeStructure(allMetrics);

          // reset selectedMetrics
          selectedMetrics = R.intersection(selectedMetrics, treeDataKeys);

          // build select metric tree data
          const { treeData: treeDataKpi, treeDataKeys: treeDataKeysKpi } =
            MetricParser.getMetricTreeStructure(selectedMetrics);
          let kpiMetricsNew = kpiMetrics || [];
          if (R.type(kpiMetricsNew[0]) === 'Array') {
            kpiMetricsNew = kpiMetricsNew[0];
          }

          this.setState({
            isLoading: false,
            errorMessage: undefined,
            instanceTypeMetricsMap,
            instanceTypeList,
            preSelectedMetrics: preSelected,
            instanceTypes,
            treeData,
            treeDataKeys,
            selectedMetrics,
            treeDataKpi,
            treeDataKeysKpi,
            kpiMetrics: kpiMetricsNew,
          });
        } else {
          this.setState({
            isLoading: false,
            errorMessage: message,
            instanceTypeMetricsMap: {},
            instanceTypeList: [],
            preSelectedMetrics: [],
            instanceTypes: [],
            treeData: [],
            treeDataKeys: [],
            selectedMetrics: [],
            treeDataKpi: [],
            treeDataKeysKpi: [],
            kpiMetrics: [],
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoading: false,
          errorMessage: errMsg || String(err),
          instanceTypeMetricsMap: {},
          instanceTypeList: [],
          preSelectedMetrics: [],
          instanceTypes: [],
          treeData: [],
          treeDataKeys: [],
          selectedMetrics: [],
          treeDataKpi: [],
          treeDataKeysKpi: [],
          kpiMetrics: [],
        });
      });
  }

  @autobind
  beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('File must smaller than 2MB!');
      return false;
    }

    this.setState({ isLoadingFile: true, configFileList: [file] });
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const fileContent = reader.result;
        let state = { isLoadingFile: false };

        // parse fileContent info
        let ret;
        try {
          ret = JSON.parse(fileContent);
        } catch (e) {
          console.debug(fileContent);
        }
        if (ret) {
          const {
            private_key_id: privateKeyId,
            private_key: privateKey,
            client_id: clientId,
            client_email: clientEmail,
            project_id: projectId,
            client_Cert_Url: clientCertUrl,
          } = ret;
          state = { ...state, privateKeyId, privateKey, clientId, clientEmail, projectId, clientCertUrl };
        }

        this.setState(state);
      };
    });
  }

  @autobind
  handleUpdateKey() {
    const { credentials, projectName } = this.props;

    this.setState({ isUpdateKey: true });
    const { privateKeyId, privateKey, clientId, clientEmail, projectId } = this.state;
    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      privateKeyId,
      privateKey,
      clientId,
      clientEmail,
      projectId,
    })
      .then((data) => {
        this.setState({ isUpdateKey: false, errorMessageKey: undefined });
        this.reloadKey(this.props);
        this.reloadData(this.props);
      })
      .catch((err) => {
        this.setState({ isUpdateKey: false, errorMessageKey: String(err) });
      });
  }

  @autobind
  handleInstanceTypeChange(instanceTypes) {
    this.setState({ instanceTypes }, () => {
      const { instanceTypeMetricsMap, preSelectedMetrics, selectedMetrics } = this.state;
      // build tree data
      let allMetrics = [];
      R.forEachObjIndexed((metrics) => {
        allMetrics = [...allMetrics, ...metrics];
      }, R.pick(instanceTypes, instanceTypeMetricsMap));
      const { treeData, treeDataKeys } = MetricParser.getMetricTreeStructure(allMetrics);

      // set pre selected metrics
      let newMetrics = selectedMetrics;
      R.forEach((val) => {
        const { metrics } = val;
        newMetrics = [...newMetrics, ...(metrics || [])];
      }, preSelectedMetrics);
      newMetrics = R.intersection(newMetrics, treeDataKeys);

      // build select metric tree data
      const { treeData: treeDataKpi, treeDataKeys: treeDataKeysKpi } =
        MetricParser.getMetricTreeStructure(selectedMetrics);

      this.setState({
        treeData,
        treeDataKeys,
        selectedMetrics: newMetrics,
        treeDataKpi,
        treeDataKeysKpi,
        kpiMetrics: [],
      });
    });
  }

  @autobind
  handleSubmit() {
    const { credentials, projectName } = this.props;

    this.setState({ isSubmitting: true });
    const { selectedMetrics, kpiMetrics } = this.state;
    return fetchPost(getEndpoint('googlecloud-metrics-setting'), {
      ...credentials,
      projectName,
      metrics: JSON.stringify(selectedMetrics),
      kpiMetrics: JSON.stringify(kpiMetrics),
    })
      .then((data) => {
        this.setState({ isSubmitting: false, errorMessage: undefined });
        this.reloadData(this.props);
      })
      .catch((err) => {
        this.setState({ isSubmitting: false, errorMessage: String(err) });
      });
  }

  @autobind
  onBuildKPIMetrics(selectedMetrics) {
    // build select metric tree data
    const { treeData: treeDataKpi, treeDataKeys: treeDataKeysKpi } =
      MetricParser.getMetricTreeStructure(selectedMetrics);
    this.setState({ treeDataKpi, treeDataKeysKpi, kpiMetrics: [] });
  }

  render() {
    const { intl } = this.props;
    const { isLoadingKey, isLoading, isUpdateKey, isSubmitting, errorMessageKey, errorMessage } = this.state;
    const {
      isLoadingFile,
      configFileList,

      privateKeyId,
      privateKey,
      clientId,
      clientEmail,
      projectId,

      instanceTypeList,
      instanceTypes,
      treeData,
      // eslint-disable-next-line no-unused-vars
      treeDataKeys,
      selectedMetrics,
      treeDataKpi,
      // eslint-disable-next-line no-unused-vars
      treeDataKeysKpi,
      kpiMetrics,
    } = this.state;

    const hasErrKey = !privateKeyId || !privateKey || !clientId || !clientEmail || !projectId;
    const hasChangeKey =
      this.privateKeyId !== privateKeyId ||
      this.privateKey !== privateKey ||
      this.clientId !== clientId ||
      this.clientEmail !== clientEmail ||
      this.projectId !== projectId;
    const hasFormErr = selectedMetrics.length === 0;
    return (
      <Container fullHeight className={`overflow-y-auto ${isLoadingKey || isLoading ? ' loading' : ''}`}>
        <div className="full-height" style={{ width: 800 }}>
          <Form layout="vertical">
            <Form.Item label="JSON Key File">
              <Upload
                onRemove={(file) => this.setState({ configFileList: [] })}
                beforeUpload={this.beforeUpload}
                fileList={configFileList}
              >
                <Button>{isLoadingFile ? <LoadingOutlined /> : <UploadOutlined />} Select File</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="Private key Id"
              required
              validateStatus={!privateKeyId ? 'error' : 'success'}
              help={!privateKeyId ? 'This input is required.' : undefined}
            >
              <Input.Password value={privateKeyId} onChange={(e) => this.setState({ privateKeyId: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Private key"
              required
              validateStatus={!privateKey ? 'error' : 'success'}
              help={!privateKey ? 'This input is required.' : undefined}
            >
              <Input.Password value={privateKey} onChange={(e) => this.setState({ privateKey: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Client Id"
              required
              validateStatus={!clientId ? 'error' : 'success'}
              help={!clientId ? 'This input is required.' : undefined}
            >
              <Input.Password value={clientId} onChange={(e) => this.setState({ clientId: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Client email"
              required
              validateStatus={!clientEmail ? 'error' : 'success'}
              help={!clientEmail ? 'This input is required.' : undefined}
            >
              <Input value={clientEmail} onChange={(e) => this.setState({ clientEmail: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Project Id"
              required
              validateStatus={!projectId ? 'error' : 'success'}
              help={!projectId ? 'This input is required.' : undefined}
            >
              <Input value={projectId} onChange={(e) => this.setState({ projectId: e.target.value })} />
            </Form.Item>

            {errorMessageKey && (
              <Alert message="Error" description={errorMessageKey} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasErrKey || !hasChangeKey}
                loading={isUpdateKey}
                onClick={this.handleUpdateKey}
              >
                Update private key
              </Button>
            </Form.Item>

            <Form.Item
              label="Instance Type"
              validateStatus={R.isEmpty(instanceTypes) ? 'error' : 'success'}
              help={R.isEmpty(instanceTypes) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                disabled={hasChangeKey}
                mode="multiple"
                allowClear
                autoClearSearchValue={false}
                maxTagCount={1}
                value={instanceTypes}
                onChange={this.handleInstanceTypeChange}
                showSearch
                optionFilterProp="key"
                optionLabelProp="key"
                dropdownRender={(menu) => {
                  const selectAll = R.difference(instanceTypeList, instanceTypes).length === 0;
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selectAll}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              this.handleInstanceTypeChange(instanceTypeList);
                            } else {
                              this.handleInstanceTypeChange([]);
                            }
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
              >
                {R.map((item) => {
                  return (
                    <Select.Option className="hide-icon" key={item}>
                      <Checkbox
                        checked={instanceTypes.includes(item)}
                        onChange={(e) => null}
                        style={{ marginRight: 8 }}
                      />
                      {item}
                    </Select.Option>
                  );
                }, instanceTypeList)}
              </Select>
            </Form.Item>
            <Form.Item
              label="Metrics"
              required
              validateStatus={selectedMetrics.length === 0 ? 'error' : 'success'}
              help={selectedMetrics.length === 0 ? 'This input is required.' : undefined}
            >
              <TreeSelect
                disabled={hasChangeKey}
                style={{ width: '100%' }}
                allowClear
                maxTagCount={1}
                showSearch
                autoClearSearchValue={false}
                treeDefaultExpandAll={false}
                treeCheckable
                treeNodeLabelProp="title"
                treeData={treeData}
                value={selectedMetrics}
                onChange={(selectedMetrics) =>
                  this.setState({ selectedMetrics }, () => {
                    this.onBuildKPIMetricsThrottled(selectedMetrics);
                  })
                }
              />
            </Form.Item>
            <Form.Item label="KPI Metrics">
              <TreeSelect
                disabled={hasChangeKey}
                style={{ width: '100%' }}
                allowClear
                maxTagCount={1}
                showSearch
                autoClearSearchValue={false}
                treeDefaultExpandAll={false}
                treeCheckable
                treeNodeLabelProp="title"
                treeData={treeDataKpi}
                value={kpiMetrics}
                onChange={(kpiMetrics) => this.setState({ kpiMetrics })}
              />
            </Form.Item>
            {errorMessage && (
              <Alert message="Error" description={errorMessage} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasChangeKey || hasFormErr}
                loading={isSubmitting}
                onClick={this.handleSubmit}
              >
                Update metrics
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    );
  }
}

const EndpointSettingGoogleCloud = injectIntl(EndpointSettingGoogleCloudCore);
export default connect((state) => {
  return {};
}, {})(EndpointSettingGoogleCloud);
