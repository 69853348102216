import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Popover } from 'antd';
import { Canvas } from '@react-three/fiber';
import * as R from 'ramda';
import * as THREE from 'three';
import { isNumber } from 'lodash';
import { OrthographicCamera } from '@react-three/drei/core/OrthographicCamera';
import { MapControls } from '@react-three/drei/core/MapControls';
import { Html } from '@react-three/drei/web/Html';
import moment from 'moment/moment';
import { buildUrl, Defaults, parseLocation } from '../../../common/utils';
import BaseUrls from '../../app/BaseUrls';
import { PinLegendIcon } from '../../../lib/fui/icons';
import { getCoverageTitle } from './utils';

// References:
// https://stackoverflow.com/questions/23450588/isometric-camera-with-three-js
// https://codesandbox.io/s/orthographic-camera-u4lfi?file=/src/index.js
// https://jsfiddle.net/vkjew52q/

const BOX_SIZE = 32;
const GEOMETRY_SIZE = 26;
const GROUP_BOX_SIZE = 22;
const GROUP_BOX_HEIGHT = 8;
const BOX_HEIGHT = (BOX_SIZE / 4) * 5;
const BOX_HEIGHT_PADDING = 3;
const GROUP_SIZE = 1;
const MAX_GROUP_SIZE_Y = 8;
const MAX_GROUP_SIZE_X = 2;
const PADDING_SIZE = BOX_SIZE * 2.5;
const PADDING_SIZE_X = BOX_SIZE * 2.5;
const GROUP_PADDING_SIZE_X = BOX_SIZE * 1;
const GROUP_PADDING_SIZE_Y = BOX_SIZE * 4;
const MAX_BOX_GROUP_COUNT = 20;
const DynamicVM = 1;

export const colorsRGB = [
  // green
  '#3af570',
  // '#2cdd55',
  // '#1dc639',
  // '#0fae1e',
  // yellow
  '#d0e552',
  // '#efe747',
  '#eec82c',
  '#e1a700',
  // red
  '#fa7a00',
  // '#FFA58B',
  // '#f19000',
  // '#fe6400',
  '#FF4C28',
  '#fc2b00',
];

export const getIncidentCounts = (entity, selectTime) => {
  let { detectedIncidentCount = 0, predictedIncidentCount = 0 } = entity;
  const { detectedIncidentMap, predictedIncidentMap } = entity;

  if (detectedIncidentMap && selectTime) {
    const ts = selectTime.valueOf();
    for (let i = 0; i < detectedIncidentMap.length; i += 1) {
      const [pair, count] = detectedIncidentMap[i];
      if (pair?.s <= ts && pair?.e > ts) {
        detectedIncidentCount = count;
        break;
      }
    }
  }

  if (predictedIncidentMap && selectTime) {
    const ts = selectTime.valueOf();
    for (let i = 0; i < predictedIncidentMap.length; i += 1) {
      const [pair, count] = predictedIncidentMap[i];
      if (pair?.s <= ts && pair?.e > ts) {
        predictedIncidentCount = count;
        break;
      }
    }
  }

  return { detectedIncidentCount, predictedIncidentCount };
};

export const scoreLevel = (num, colors = colorsRGB) => {
  let ret = num ? (Math.floor(Math.log10(num) + 1) <= 0 ? 0 : Math.floor(Math.log10(num) + 1)) : 0;
  ret = R.min(colors.length - 1, ret);
  if (ret === 0 && num > 0) {
    ret = 1;
  }
  return ret;
};

export const formatScore = (n) => {
  const ret = isNumber(n) ? n.toFixed(2) : n;
  return ret;
};

function jumpToInvestigation(data, selectStartHour) {
  const query = parseLocation(window.location);
  const params = {
    ...query,
    forceRefreshTime: undefined,
    jumpStartHour: selectStartHour,
  };

  if (data.type === 'component') {
    params.jumpComponentName = data?.overwriteComponentName || data.name;
  } else if (data.type === 'instance') {
    params.jumpInstanceName = data.name;
    params.jumpComponentName = data?.overwriteComponentName || data.componentName;
  } else if (data.type === 'pod') {
    params.jumpInstanceName = data.name;
    params.jumpComponentName = data?.overwriteComponentName || data.componentName;
    // params.jumpContainer = data.componentName;
  } else if (data.type === 'container') {
    params.jumpInstanceName = data.name;
    params.jumpComponentName = data?.overwriteComponentName || data.componentName;
    params.jumpContainer = data.name.split('_')[0] || data.componentName;
  }
  window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, params), '_blank');
}

function jumpToPrediction(data, selectStartHour) {
  const query = parseLocation(window.location);
  const params = {
    ...query,
    jumpStartHour: selectStartHour,
    typeFilter: 'all',
  };

  if (data.type === 'component') {
    params.componentFilter = data?.overwriteComponentName || data.name;
  } else if (data.type === 'instance') {
    params.eventInstanceName = data.name;
    params.componentFilter = data?.overwriteComponentName || data.componentName;
  } else if (data.type === 'pod') {
    params.eventInstanceName = data.name;
    params.componentFilter = data?.overwriteComponentName || data.componentName;
    // params.jumpContainer = data.componentName;
  } else if (data.type === 'container') {
    params.eventInstanceName = data.name;
    params.componentFilter = data?.overwriteComponentName || data.componentName;
    params.containerFilter = data.name.split('_')[0] || data.componentName;
  }
  window.open(buildUrl(BaseUrls.GlobalSystemPrediction, {}, params), '_blank');
}

const BoxGroup = ({
  isJwtMode,
  isK8s,
  x,
  y,
  z,
  name,
  instanceDisplayName,
  originOffsetX,
  originOffsetY,
  blockName,
  boxes,
  isDark,
  data,
  selectStartHour,
  selectedTime,
  activeEntity,
  nodeRootCauseMap,
  metricEntities,
  predictedMetricEntities,
  logEntities,
  predictedLogEntities,
}) => {
  const [hovered, hover] = useState(null);

  return (
    <>
      {R.addIndex(R.map)((container, idx) => {
        const height = GROUP_BOX_HEIGHT;
        const { scoreRaw, id, dynamicType } = container;
        const { detectedIncidentCount, predictedIncidentCount } = getIncidentCounts(container, selectedTime);
        const color = new THREE.Color(colorsRGB[scoreLevel(scoreRaw)]);
        const metricSet = metricEntities?.[id] || [];
        const predictedMetricSet = predictedMetricEntities?.[id] || [];
        const logSet = logEntities?.[id] || [];
        const predictedLogSet = predictedLogEntities?.[id] || [];

        const item = (
          <mesh
            key={`${name}-${idx}`}
            position={[-(y - originOffsetY) - BOX_SIZE / 2, z + height / 2, -(x - originOffsetX) - BOX_SIZE / 2]}
            onPointerOver={(e) => {
              e.stopPropagation();
              hover(idx);
            }}
            onPointerOut={(e) => {
              e.stopPropagation();
              hover(null);
            }}
          >
            <cylinderGeometry args={[GROUP_BOX_SIZE / 2, GROUP_BOX_SIZE / 2, height, 32]} />
            <meshStandardMaterial
              color={hovered === idx ? (isDark ? 'white' : '#e0e0e0') : color.getHex()}
              opacity={hovered !== idx ? 0.8 : 1}
              transparent={hovered !== idx}
            />
            <Html>
              <Popover
                overlayStyle={{ paddingBottom: 0, paddingTop: 0 }}
                content={
                  <div className="flex-col" style={{ width: 360 }}>
                    <div className="flex-row flex-center-align">
                      <span className="inline-block light-label bold" style={{ marginRight: 4, width: 150 }}>
                        Anomaly score:
                      </span>
                      <span style={{ maxWidth: 300 }}>{formatScore(scoreRaw)}</span>
                    </div>
                    <div className="flex-row flex-center-align">
                      <span className="inline-block light-label bold" style={{ marginRight: 4, width: 150 }}>
                        Detected incident count:
                      </span>
                      <span style={{ maxWidth: 300 }}>
                        {detectedIncidentCount || 0}
                        {!isJwtMode && (
                          <Button
                            size="small"
                            type="primary"
                            style={{ marginLeft: 4 }}
                            onClick={(e) => jumpToInvestigation(container, selectStartHour)}
                          >
                            Detail
                          </Button>
                        )}
                      </span>
                    </div>
                    {metricSet.length > 0 &&
                      R.map(
                        (m) => (
                          <div key={m} className="flex-row flex-center-align">
                            <span
                              className="short-tag-blue"
                              style={{ borderRadius: '50%', color: 'white', background: 'var(--blue)' }}
                            >
                              M
                            </span>
                            <div
                              className="flex-grow"
                              style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                            >
                              {m}
                            </div>
                          </div>
                        ),
                        metricSet,
                      )}
                    {logSet.length > 0 &&
                      R.map(
                        (l) => (
                          <div key={l} className="flex-row flex-center-align">
                            <span className="short-tag-blue">L</span>
                            <div
                              className="flex-grow"
                              style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                            >
                              {l}
                            </div>
                          </div>
                        ),
                        logSet,
                      )}
                    <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                      <span className="inline-block light-label bold" style={{ marginRight: 4, width: 150 }}>
                        Predicted incident count:
                      </span>
                      <span style={{ maxWidth: 300 }}>
                        {predictedIncidentCount || 0}
                        {!isJwtMode && (
                          <Button
                            size="small"
                            type="primary"
                            style={{ marginLeft: 4 }}
                            onClick={(e) => jumpToPrediction(data, selectStartHour)}
                          >
                            Detail
                          </Button>
                        )}
                      </span>
                    </div>
                    {predictedMetricSet.length > 0 &&
                      R.map(
                        (m) => (
                          <div key={m} className="flex-row flex-center-align">
                            <span className="short-tag-blue">M</span>
                            <div
                              className="flex-grow"
                              style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                            >
                              {m}
                            </div>
                          </div>
                        ),
                        predictedMetricSet,
                      )}
                    {predictedLogSet.length > 0 &&
                      R.map(
                        (l) => (
                          <div key={l} className="flex-row flex-center-align">
                            <span className="short-tag-blue">L</span>
                            <div
                              className="flex-grow"
                              style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                            >
                              {l}
                            </div>
                          </div>
                        ),
                        predictedLogSet,
                      )}
                    <div className="flex-row flex-center-align" style={{ marginTop: 10 }}>
                      <span style={{ marginRight: 4, width: 150 }} className="inline-block light-label bold">
                        {isK8s ? 'Pod name:' : 'Instance name:'}
                      </span>
                      <span
                        className="inline-block"
                        style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 300 }}
                      >
                        {name}
                      </span>
                    </div>
                    {instanceDisplayName && (
                      <div className="flex-row flex-center-align" style={{ marginTop: 10 }}>
                        <span style={{ marginRight: 4, width: 150 }} className="inline-block light-label bold">
                          Instance display name:
                        </span>
                        <span
                          className="inline-block"
                          style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 300 }}
                        >
                          {instanceDisplayName}
                        </span>
                      </div>
                    )}
                    <div className="flex-row flex-center-align" style={{ marginTop: 10 }}>
                      <span style={{ marginRight: 4, flex: '0 0 150px' }} className="inline-block light-label bold">
                        <span>{`${dynamicType === DynamicVM ? 'VM' : 'Container'}:`}</span>
                        <br />
                        {`Count: ${boxes.length}`}
                      </span>
                      <div className="flex-col" style={{ maxHeight: 320, overflowY: 'auto', width: '100%' }}>
                        {R.addIndex(R.map)(
                          (c, idx) => (
                            <div
                              key={container.name + c.name}
                              className="flex-row"
                              style={{ color: c === container ? '#1890ff' : null }}
                            >
                              <span
                                style={{ display: 'inline-block', minWidth: 20, textAlign: 'left', marginRight: 4 }}
                              >
                                {idx + 1}.
                              </span>
                              <div className="full-width flex-col">
                                <span
                                  className="inline-block"
                                  style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 300 }}
                                >
                                  {c.name.split('_')[0] || c.name}
                                </span>
                                {c.startTime && c.endTime && (
                                  <span>{`${moment.utc(c.startTime).format(Defaults.DateTimeFormat)} ~ ${moment
                                    .utc(c.endTime)
                                    .format(Defaults.DateTimeFormat)}`}</span>
                                )}
                              </div>
                            </div>
                          ),
                          boxes,
                        )}
                      </div>
                    </div>
                  </div>
                }
              >
                <div
                  style={{
                    width: GROUP_BOX_SIZE,
                    height: GROUP_BOX_SIZE,
                    position: 'absolute',
                    left: -GROUP_BOX_SIZE / 2,
                    top: -GROUP_BOX_SIZE / 2,
                  }}
                  onPointerOver={(e) => {
                    e.stopPropagation();
                    hover(idx);
                  }}
                  onPointerOut={(e) => {
                    e.stopPropagation();
                    hover(null);
                  }}
                />
              </Popover>
              <div className="flex-row" style={{ position: 'absolute', left: 10, top: -6 }}>
                {detectedIncidentCount > 0 && <PinLegendIcon style={{ color: '#FF5142', fontSize: 12 }} />}
                {predictedIncidentCount > 0 && <PinLegendIcon style={{ color: '#ffad66', fontSize: 12 }} />}
              </div>
            </Html>
          </mesh>
        );
        z += BOX_HEIGHT_PADDING + height;
        return item;
      }, R.reverse(R.take(MAX_BOX_GROUP_COUNT, boxes || [])))}
    </>
  );
};

const Box = ({
  isJwtMode,
  isK8s,
  name,
  instanceDisplayName,
  x = 0,
  y = 0,
  z = 0,
  isDark,
  data,
  dataChildren,
  componentName,
  isSingleNode,
  selectedTime,
  selectStartHour,
  activeEntity,
  nodeRootCauseMap,
  metricEntities,
  predictedMetricEntities,
  logEntities,
  predictedLogEntities,
}) => {
  const meshRef = useRef();
  const [hovered, hover] = useState(null);
  const { type, scoreRaw, id } = data;
  const { detectedIncidentCount, predictedIncidentCount } = getIncidentCounts(data, selectedTime);
  const isPod = type === 'pod';
  const color = colorsRGB[scoreLevel(scoreRaw)];
  const isActive = id === activeEntity;
  const parts = activeEntity?.split('_') || [];
  const idParts = id?.split('_') || [];
  const nodeName = parts[parts.length - 1] || activeEntity;
  const idName = idParts[idParts.length - 1] || id;
  const isRootCause = nodeRootCauseMap?.[nodeName]?.rootCauseMap?.[idName] || false;
  const metricSet = metricEntities?.[id] || [];
  const predictedMetricSet = predictedMetricEntities?.[id] || [];
  const logSet = logEntities?.[id] || [];
  const predictedLogSet = predictedLogEntities?.[id] || [];
  const hasVM = !!R.find((c) => c.dynamicType === DynamicVM, dataChildren || []);

  // Convert x, y and height to 3d coordinates
  const popoverContent = (
    <div className="flex-col">
      <div className="flex-row flex-center-align">
        <span className="inline-block light-label bold" style={{ marginRight: 4, width: 150 }}>
          Anomaly score:
        </span>
        <span>{formatScore(data?.scoreRaw)}</span>
      </div>
      <div className="flex-row flex-center-align">
        <span className="inline-block light-label bold" style={{ marginRight: 4, width: 150 }}>
          Detected incident count:
        </span>
        <span style={{ maxWidth: 300 }}>
          {detectedIncidentCount || 0}
          {!isJwtMode && (
            <Button
              size="small"
              type="primary"
              style={{ marginLeft: 4 }}
              onClick={(e) => jumpToInvestigation(data, selectStartHour)}
            >
              Detail
            </Button>
          )}
        </span>
      </div>
      {metricSet.length > 0 &&
        R.map(
          (m) => (
            <div key={m} className="flex-row flex-center-align">
              <span className="short-tag-blue">M</span>
              <div className="flex-grow" style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}>
                {m}
              </div>
            </div>
          ),
          metricSet,
        )}
      {logSet.length > 0 &&
        R.map(
          (l) => (
            <div key={l} className="flex-row flex-center-align">
              <span className="short-tag-blue">L</span>
              <div className="flex-grow" style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}>
                {l}
              </div>
            </div>
          ),
          logSet,
        )}
      <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
        <span className="inline-block light-label bold" style={{ marginRight: 4, width: 150 }}>
          Predicted incident count:
        </span>
        <span style={{ maxWidth: 300 }}>
          {predictedIncidentCount || 0}
          {!isJwtMode && (
            <Button
              size="small"
              type="primary"
              style={{ marginLeft: 4 }}
              onClick={(e) => jumpToPrediction(data, selectStartHour)}
            >
              Detail
            </Button>
          )}
        </span>
      </div>
      {predictedMetricSet.length > 0 &&
        R.map(
          (m) => (
            <div key={m} className="flex-row flex-center-align">
              <span className="short-tag-blue">M</span>
              <div className="flex-grow" style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}>
                {m}
              </div>
            </div>
          ),
          predictedMetricSet,
        )}
      {predictedLogSet.length > 0 &&
        R.map(
          (l) => (
            <div key={l} className="flex-row flex-center-align">
              <span className="short-tag-blue">L</span>
              <div className="flex-grow" style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}>
                {l}
              </div>
            </div>
          ),
          predictedLogSet,
        )}
      {!isPod && (
        <div className="flex-row flex-center-align" style={{ marginTop: 10 }}>
          <span style={{ marginRight: 4, width: 150 }} className="inline-block light-label bold">
            Component name:
          </span>
          <span className="inline-block" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 200 }}>
            {componentName}
          </span>
        </div>
      )}
      <div className="flex-row flex-center-align" style={{ marginTop: 10 }}>
        <span style={{ marginRight: 4, width: 150 }} className="inline-block light-label bold">
          {`${isPod && isK8s ? 'Pod name:' : 'Instance name:'}`}
        </span>
        <span className="inline-block" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 200 }}>
          {name}
        </span>
      </div>
      {instanceDisplayName && (
        <div className="flex-row flex-center-align" style={{ marginTop: 10 }}>
          <span style={{ marginRight: 4, width: 150 }} className="inline-block light-label bold">
            Instance display name:
          </span>
          <span className="inline-block" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 200 }}>
            {instanceDisplayName}
          </span>
        </div>
      )}
      {dataChildren?.length > 0 && (
        <div className="flex-row flex-center-align" style={{ marginTop: 4 }}>
          <span style={{ marginRight: 4, width: 150 }} className="inline-block light-label bold">
            {`${hasVM ? 'VM' : 'Container'} count:`}
          </span>
          <span className="inline-block" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 200 }}>
            {dataChildren?.length}
          </span>
        </div>
      )}
    </div>
  );

  const xStart = -y - 1;
  const zStart = -x - 4;
  const padding = BOX_SIZE / 2;
  const points = [];
  const width = 0;
  const height = BOX_SIZE - 16;
  points.push(new THREE.Vector3(xStart + padding, 0, zStart + padding));
  points.push(new THREE.Vector3(xStart + padding, 0, zStart - width - padding - BOX_SIZE * 2));
  points.push(new THREE.Vector3(xStart - height - padding, 0, zStart - width - padding - BOX_SIZE * 2));
  points.push(new THREE.Vector3(xStart - height - padding, 0, zStart + padding));
  points.push(new THREE.Vector3(xStart + padding, 0, zStart + padding));
  const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
  return (
    <>
      {false && isActive && (
        <line geometry={lineGeometry}>
          <lineBasicMaterial color="blue" fog={false} />
        </line>
      )}
      {false && isRootCause && !isActive && (
        <line geometry={lineGeometry}>
          <lineBasicMaterial color="lightblue" fog={false} />
        </line>
      )}
      <mesh
        position={[-y - BOX_SIZE / 2, z + BOX_SIZE / 2, -x - BOX_SIZE / 2]}
        ref={meshRef}
        onPointerOver={() => hover(true)}
        onPointerOut={() => hover(false)}
      >
        <boxGeometry args={[isPod ? GEOMETRY_SIZE : GEOMETRY_SIZE / 2, BOX_SIZE, GEOMETRY_SIZE]} />
        <meshStandardMaterial color={hovered ? (isDark ? 'white' : '#e0e0e0') : color} />
        <Html>
          <div className="flex-col" style={{ position: 'absolute', left: -BOX_SIZE, top: -12 }}>
            {detectedIncidentCount > 0 && <PinLegendIcon style={{ color: '#FF5142', fontSize: 14 }} />}
            {predictedIncidentCount > 0 && <PinLegendIcon style={{ color: '#ffad66', fontSize: 14 }} />}
          </div>
          <Popover overlayStyle={{ paddingBottom: 0, paddingTop: 0 }} content={popoverContent}>
            <div
              style={{
                width: BOX_SIZE,
                height: BOX_SIZE,
                position: 'absolute',
                left: -BOX_SIZE / 2,
                top: -BOX_SIZE / 8,
              }}
              onPointerOver={() => hover(true)}
              onPointerOut={() => hover(false)}
            />
          </Popover>
          <Popover overlayStyle={{ paddingBottom: 0, paddingTop: 0 }} content={popoverContent}>
            <div
              className="hidden-line-with-ellipsis"
              style={{
                cursor: 'pointer',
                maxWidth: isSingleNode ? 200 : 100,
                transform: 'rotate(-6.5deg) translateX(-16px) translateY(18px)',
                transformOrigin: 'top left',
                userSelect: 'none',
                color: isActive ? 'var(--blue)' : isRootCause ? 'var(--lightblue)' : 'inherit',
              }}
            >
              {`${isActive ? '* ' : ''}${instanceDisplayName || name}`}
            </div>
          </Popover>
        </Html>
      </mesh>
    </>
  );
};

const Block = ({
  isJwtMode,
  isK8s,
  name,
  x,
  y,
  xSize,
  width = 0,
  height = 0,
  color = '#3af570',
  data,
  collapse,
  setCollapse,
  selectStartHour,
  selectedTime,
  metricEntities,
  predictedMetricEntities,
  logEntities,
  predictedLogEntities,
}) => {
  const { type, id } = data;
  const { detectedIncidentCount, predictedIncidentCount } = getIncidentCounts(data, selectedTime);
  const padding = BOX_SIZE / 2;
  const points = [];
  const xStart = -y;
  const zStart = -x;

  height += BOX_SIZE / 2 - 5;

  points.push(new THREE.Vector3(xStart + padding, 0, zStart + padding));
  points.push(new THREE.Vector3(xStart + padding, 0, zStart - width - padding - BOX_SIZE * 2));
  points.push(new THREE.Vector3(xStart - height - padding, 0, zStart - width - padding - BOX_SIZE * 2));
  points.push(new THREE.Vector3(xStart - height - padding, 0, zStart + padding));
  points.push(new THREE.Vector3(xStart + padding, 0, zStart + padding));
  const metricSet = metricEntities?.[id] || [];
  const predictedMetricSet = predictedMetricEntities?.[id] || [];
  const logSet = logEntities?.[id] || [];
  const predictedLogSet = predictedLogEntities?.[id] || [];

  const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
  // add some padding for the text
  return (
    <>
      <line geometry={lineGeometry}>
        <lineBasicMaterial color={color} fog={false} />
      </line>
      <mesh position={[xStart + padding, 0, zStart]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
        <Html>
          <Popover
            overlayStyle={{ paddingBottom: 0, paddingTop: 0 }}
            content={
              <div className="flex-col">
                {type !== 'component' && (
                  <div className="flex-row flex-center-align" style={{ marginBottom: 10 }}>
                    <span className="inline-block light-label bold" style={{ marginRight: 4, width: 150 }}>
                      Anomaly score:
                    </span>
                    <span>{formatScore(data?.scoreRaw)}</span>
                  </div>
                )}
                <div className="flex-row flex-center-align">
                  <span className="inline-block light-label bold" style={{ marginRight: 4, width: 150 }}>
                    Detected incident count:
                  </span>
                  <span style={{ maxWidth: 300 }}>
                    {detectedIncidentCount || 0}
                    {!isJwtMode && (
                      <Button
                        size="small"
                        type="primary"
                        style={{ marginLeft: 4 }}
                        onClick={(e) => jumpToInvestigation(data, selectStartHour)}
                      >
                        Detail
                      </Button>
                    )}
                  </span>
                </div>
                {metricSet.length > 0 &&
                  R.map(
                    (m) => (
                      <div key={m} className="flex-row flex-center-align">
                        <span className="short-tag-blue">M</span>
                        <div
                          className="flex-grow"
                          style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                        >
                          {m}
                        </div>
                      </div>
                    ),
                    metricSet,
                  )}
                {logSet.length > 0 &&
                  R.map(
                    (l) => (
                      <div key={l} className="flex-row flex-center-align">
                        <span className="short-tag-blue">L</span>
                        <div
                          className="flex-grow"
                          style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                        >
                          {l}
                        </div>
                      </div>
                    ),
                    logSet,
                  )}
                <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                  <span className="inline-block light-label bold" style={{ marginRight: 4, width: 150 }}>
                    Predicted incident count:
                  </span>
                  <span style={{ maxWidth: 300 }}>
                    {predictedIncidentCount || 0}
                    {!isJwtMode && (
                      <Button
                        size="small"
                        type="primary"
                        style={{ marginLeft: 4 }}
                        onClick={(e) => jumpToPrediction(data, selectStartHour)}
                      >
                        Detail
                      </Button>
                    )}
                  </span>
                </div>
                {predictedMetricSet.length > 0 &&
                  R.map(
                    (m) => (
                      <div key={m} className="flex-row flex-center-align">
                        <span className="short-tag-blue">M</span>
                        <div
                          className="flex-grow"
                          style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                        >
                          {m}
                        </div>
                      </div>
                    ),
                    predictedMetricSet,
                  )}
                {predictedLogSet.length > 0 &&
                  R.map(
                    (l) => (
                      <div key={l} className="flex-row flex-center-align">
                        <span className="short-tag-blue">L</span>
                        <div
                          className="flex-grow"
                          style={{ fontSize: 12, color: 'var(--blue)', wordBreak: 'break-word' }}
                        >
                          {l}
                        </div>
                      </div>
                    ),
                    predictedLogSet,
                  )}
                <div className="flex-row flex-center-align" style={{}}>
                  <span style={{ marginRight: 4, width: 150 }} className="inline-block light-label bold">
                    {`${type === 'component' ? 'Component' : 'Instance'} name:`}
                  </span>
                  <span
                    className="inline-block"
                    style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 200 }}
                  >
                    {name}
                  </span>
                  {type === 'component' && !isJwtMode && (
                    <span>
                      <Button
                        size="small"
                        type="primary"
                        style={{ marginLeft: 4 }}
                        onClick={(e) => jumpToInvestigation({ ...data, type: 'component' }, selectStartHour)}
                      >
                        Detail
                      </Button>
                    </span>
                  )}
                </div>
                <div className="flex-row flex-center-align" style={{ marginTop: 10 }}>
                  <span style={{ marginRight: 4, width: 150 }} className="inline-block light-label bold">
                    {`${type === 'component' && !isK8s ? 'Instance' : 'Pod'} count:`}
                  </span>
                  <span
                    className="inline-block"
                    style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 200 }}
                  >
                    {data?.children?.length || 0}
                  </span>
                </div>
              </div>
            }
          >
            <div
              className="flex-row"
              style={{
                cursor: 'pointer',
                transform: 'rotate(-6.5deg) translateX(-8px)',
                transformOrigin: 'top left',
                userSelect: 'none',
                maxWidth: 220,
              }}
              onClick={() => setCollapse(!collapse)}
            >
              <span
                className="flex-grow"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {name}
              </span>
              <span style={{ paddingLeft: 4 }}>{`(${data?.children?.length || 0})`}</span>
            </div>
          </Popover>
          <div className="flex-row" style={{ position: 'absolute', left: -36, top: 4 }}>
            {detectedIncidentCount > 0 && <PinLegendIcon style={{ color: '#FF5142', fontSize: 14 }} />}
            {predictedIncidentCount > 0 && <PinLegendIcon style={{ color: '#ffad66', fontSize: 14 }} />}
          </div>
        </Html>
      </mesh>
    </>
  );
};

const Camera = () => {
  const cameraRef = useRef();

  useEffect(() => {
    const camera = cameraRef.current;
    const distance = 900;

    const x = distance;
    const y = distance;
    const z = distance * Math.tan((Math.PI * 10) / 180);

    camera.position.set(x, y, z);
    camera.rotation.order = 'YXZ';
    camera.rotation.y = -Math.PI / 4;
    camera.rotation.x = Math.atan(-1 / Math.sqrt(2));
  }, []);

  return <OrthographicCamera makeDefault ref={cameraRef} />;
};
const ServiceMapCanvas = ({
  isJwtMode,
  isK8s = false,
  nodeTree: allNodeTree = [],
  containerWidth,
  containerHeight,
  isDark,
  globalOffsetY = 0,
  selectedTime,
  selectStartHour,
  activeEntity,
  nodeRootCauseMap,
  metricEntities,
  predictedMetricEntities,
  logEntities,
  predictedLogEntities,
  selectedZone,
  selectionMap,
}) => {
  const [groupStatus, setGroupStatus] = useState({});
  const [nodeTree, setNodeTree] = useState(allNodeTree);

  useEffect(() => {
    if (activeEntity && nodeRootCauseMap) {
      const newNodeTree = [];
      const parts = activeEntity?.split('_') || [];
      const nodeName = parts.length === 6 ? parts[2] : parts[parts.length - 1] || activeEntity;
      R.forEach((comp) => {
        const newComp = { ...comp };
        newComp.children = [];
        newComp.allChildren = [];

        R.forEach((inst) => {
          const { id, name } = inst;
          const isActive = name === nodeName || id === activeEntity;
          // const idParts = id?.split('_') || [];
          // const idName = idParts.length === 5 ? idParts[2] : idParts[idParts.length - 1] || id;
          const isRootCause = nodeRootCauseMap?.[nodeName]?.rootCauseMap?.[name] || false;
          if (isActive || isRootCause) {
            newComp.children.push(inst);
            newComp.allChildren = [...newComp.allChildren, ...(inst.allChildren || [])];
          }
        }, comp.children);
        if (newComp.children.length > 0) {
          newNodeTree.push(newComp);
        }
      }, allNodeTree);
      setNodeTree(newNodeTree);
    } else {
      setNodeTree(allNodeTree);
    }
  }, [allNodeTree, nodeRootCauseMap, activeEntity]);

  const long = Math.sqrt(Math.pow(containerHeight / 2, 2) + Math.pow(containerWidth / 4, 2));
  const originOffsetX = long * Math.cos((Math.PI * 20) / 180);
  const originOffsetY = long * Math.sin((Math.PI * 70) / 180) + globalOffsetY;

  let xStart = 0;
  let yStart = 0;

  const maxGroupSizeX = nodeTree.length === 1 ? 8 : nodeTree.length === 2 ? 4 : MAX_GROUP_SIZE_X;
  const selectedTimestamp = selectedTime ? selectedTime.valueOf() : +new Date();
  const selectionMapKey = selectionMap ? JSON.stringify(R.keys(selectionMap)) : '';

  return (
    <div
      style={{
        height: containerHeight,
        width: containerWidth,
        border: '1px solid var(--border-color-base)',
        background: 'var(--react-flow-bg)',
        zIndex: 10,
      }}
    >
      <Canvas gl={{ preserveDrawingBuffer: true }}>
        <ambientLight intensity={1} />
        <directionalLight position={[-2, 3, 6]} color="white" intensity={1} />
        <Camera canvasWidth={containerWidth} canvasHeight={containerHeight} />
        <MapControls maxZoom={1.0} minZoom={1.0} enableRotate={false} />
        {R.addIndex(R.map)((block, cidx) => {
          const blockChildren = block?.children || [];
          const { name: blockName } = block;
          const score = scoreLevel(block.totalScoreRaw);
          const collapse = groupStatus[blockName];

          const groupSize = collapse ? 1 : Math.ceil(blockChildren.length / maxGroupSizeX);

          const xSize = maxGroupSizeX;
          const ySize = groupSize;
          const groupStartX =
            xStart * (BOX_SIZE + PADDING_SIZE_X) + PADDING_SIZE_X + (xStart - 1) * GROUP_PADDING_SIZE_X;
          const groupStartY = yStart * (BOX_SIZE + PADDING_SIZE) + PADDING_SIZE + GROUP_PADDING_SIZE_Y;
          yStart += ySize;

          if (yStart > MAX_GROUP_SIZE_Y) {
            yStart = 0;
            xStart += xSize;
          }

          const childCount = block?.children?.length || 0;
          const isSingleNode = childCount === 1;
          // const showBlock = isSingleNode ? (block.children[0].displayName || block.children[0].name) !== blockName : true;
          const showBlock = true;
          return (
            <Fragment key={`${selectionMapKey}-${selectedZone}-${selectedTimestamp}-${block.id}-${cidx}`}>
              {showBlock && (
                <Block
                  isJwtMode={isJwtMode}
                  isK8s={isK8s}
                  key={blockName}
                  x={groupStartX - originOffsetX}
                  y={groupStartY - originOffsetY}
                  xSize={xSize}
                  width={xSize * (BOX_SIZE + PADDING_SIZE_X) - PADDING_SIZE_X}
                  height={groupSize * (BOX_SIZE + PADDING_SIZE) - PADDING_SIZE}
                  color={colorsRGB[score]}
                  name={blockName}
                  data={block}
                  collapse={collapse}
                  setCollapse={(state) => setGroupStatus({ ...groupStatus, [blockName]: state })}
                  selectedTime={selectedTime}
                  selectStartHour={selectStartHour}
                  activeEntity={activeEntity}
                  nodeRootCauseMap={nodeRootCauseMap}
                  metricEntities={metricEntities}
                  predictedMetricEntities={predictedMetricEntities}
                  logEntities={logEntities}
                  predictedLogEntities={predictedLogEntities}
                />
              )}
              {!collapse &&
                R.addIndex(R.map)((box, iidx) => {
                  const { instanceDisplayName } = box;
                  const name = getCoverageTitle(box, isK8s, selectedTime);
                  const boxChildren = box.children || [];

                  const xidx = Math.floor(iidx / groupSize);
                  const yidx = iidx % groupSize;

                  const offsetX = xidx * (BOX_SIZE + PADDING_SIZE_X);
                  const offsetY = yidx * (BOX_SIZE + PADDING_SIZE);

                  const x = groupStartX + offsetX;
                  const y = groupStartY + offsetY;
                  if (boxChildren.length === 0) {
                    return (
                      <Box
                        isJwtMode={isJwtMode}
                        key={name}
                        name={name}
                        instanceDisplayName={instanceDisplayName}
                        x={x - originOffsetX}
                        y={y - originOffsetY}
                        isDark={isDark}
                        data={box}
                        dataChildren={boxChildren}
                        componentName={blockName}
                        isSingleNode={childCount === 1}
                        selectStartHour={selectStartHour}
                        selectedTime={selectedTime}
                        activeEntity={activeEntity}
                        nodeRootCauseMap={nodeRootCauseMap}
                        metricEntities={metricEntities}
                        predictedMetricEntities={predictedMetricEntities}
                        logEntities={logEntities}
                        predictedLogEntities={predictedLogEntities}
                      />
                    );
                  } else {
                    return (
                      <>
                        <Box
                          isJwtMode={isJwtMode}
                          isK8s={isK8s}
                          key={name}
                          name={name}
                          instanceDisplayName={instanceDisplayName}
                          x={x - originOffsetX}
                          y={y - originOffsetY}
                          isDark={isDark}
                          componentName={blockName}
                          data={box}
                          dataChildren={boxChildren}
                          isSingleNode={childCount === 1}
                          selectStartHour={selectStartHour}
                          selectedTime={selectedTime}
                          activeEntity={activeEntity}
                          nodeRootCauseMap={nodeRootCauseMap}
                          metricEntities={metricEntities}
                          predictedMetricEntities={predictedMetricEntities}
                          logEntities={logEntities}
                          predictedLogEntities={predictedLogEntities}
                        />
                        <BoxGroup
                          isJwtMode={isJwtMode}
                          isK8s={isK8s}
                          key={`${name}-group`}
                          x={x}
                          y={y}
                          z={BOX_HEIGHT}
                          name={name}
                          instanceDisplayName={instanceDisplayName}
                          blockName={blockName}
                          isDark={isDark}
                          boxes={boxChildren}
                          originOffsetX={originOffsetX}
                          originOffsetY={originOffsetY}
                          data={box}
                          selectStartHour={selectStartHour}
                          selectedTime={selectedTime}
                          activeEntity={activeEntity}
                          nodeRootCauseMap={nodeRootCauseMap}
                          metricEntities={metricEntities}
                          predictedMetricEntities={predictedMetricEntities}
                          logEntities={logEntities}
                          predictedLogEntities={predictedLogEntities}
                        />
                      </>
                    );
                  }
                }, blockChildren)}
            </Fragment>
          );
        }, nodeTree)}
      </Canvas>
    </div>
  );
};

export default ServiceMapCanvas;
