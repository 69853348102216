/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';

const getGlobalSystemCausalGroup = (credentials: Credentials, params: Object) => {
  const { startTime, customerName, projectInfoList } = params;
  const startTimestamp = moment
    .utc(startTime, Defaults.DateFormat)
    .startOf('day')
    .valueOf();
  const endTimestamp = moment
    .utc(startTime, Defaults.DateFormat)
    .endOf('day')
    .valueOf();

  const requests = [];
  requests.push(
    fetchGet(getEndpoint('relatedcausalgroup', 1), {
      ...credentials,
      customerName,
      projectInfoList: JSON.stringify(projectInfoList),
      startTime: startTimestamp,
      endTime: endTimestamp,
    }).catch((err) => {
      console.warn('[IF_API] failed to get all system causal group', err);
      return [];
    }),
  );

  return Promise.all(requests).then((results) => {
    const causalResult = get(results[0], ['data']);
    return { globalSystemCausalGroup: causalResult };
  });
};

export default getGlobalSystemCausalGroup;
