/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import React from 'react';
import * as R from 'ramda';
// import { get } from 'lodash';
import { message, Spin, Alert, Select } from 'antd';

import fetchGet from '../apis/fetchGet';
import fetchPost from '../apis/fetchPost';
import getEndpoint from '../apis/getEndpoint';
import { Modal } from '../../lib/fui/react';
// import { buildUrl } from '.';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../app/messages';
import { eventMessages } from '../metric/messages';

const EventActionHandle = {
  handleCreateChannelOnTeamsClick: async ({
    intl,
    credentials,
    updateLastActionInfo,
    environmentId,
    systemId,
    event,
    onReload,
  }) => {
    const { projectName, projectOwner, instanceName, patternId, incidentTimestamp, startTimestamp } = event;

    const openConfirmModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <div className="flex-row flex-center-align flex-center-justify">
          <Spin spinning />
        </div>
      ),
      okButtonProps: { disabled: true },
      onOk: (close) => close(),
    });

    const result = await EventActionHandle.handleGetFindTeams({
      credentials,
      environmentId,
      systemId,
      projectOwner,
      projectName,
      instanceName,
      patternId,
      incidentTimestamp,
      startTimestamp,
    });

    const AlterErrContent = (errMsg) => {
      return (
        <div>
          <Alert message={errMsg} type="error" style={{ marginTop: 8 }} />
        </div>
      );
    };

    const { success, message: errMsg, channelExists, url, channels, channelLoadError } = result;
    if (success) {
      if (channelExists) {
        window.open(url, '_blank');
        if (onReload) onReload();
        openConfirmModal.destroy();
      } else {
        const teamsChannelOptions = [{ value: 'newChannel', label: 'New channel' }];
        R.forEach((item) => {
          teamsChannelOptions.push({ value: item.id, label: item.displayName });
        }, channels || []);
        EventActionHandle.handleCreateChannelOnTeamsModal({
          intl,
          credentials,
          updateLastActionInfo,
          environmentId,
          systemId,
          event,
          onReload,
          openConfirmModal,
          teamsChannelOptions,
        });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (channelLoadError) {
        fetchPost(getEndpoint('delete-teams'), {
          customerName: projectOwner,
          systemName: systemId,
          environmentName: environmentId,
          projectName,
          instanceName,
          incidentTimestamp,
          deleteChannel: false,
        })
          .then(async (data) => {
            const { success, message: errMsg } = data || {};
            if (success) {
              const result = await EventActionHandle.handleGetFindTeams({
                credentials,
                environmentId,
                systemId,
                projectOwner,
                projectName,
                instanceName,
                patternId,
                incidentTimestamp,
                startTimestamp,
              });
              const { success, message: errMsg, channelExists, url, channels } = result;
              EventActionHandle.handleReturnFindTeamsFalse({
                success,
                errMsg,
                channelExists,
                url,
                channels,
                onReload,
                openConfirmModal,
                intl,
                credentials,
                updateLastActionInfo,
                environmentId,
                systemId,
                event,
              });
            } else {
              openConfirmModal.update({
                content: AlterErrContent(errMsg),
              });
            }
          })
          .catch((err) => {
            openConfirmModal.update({
              content: AlterErrContent(errMsg),
            });
          });
      } else {
        openConfirmModal.update({
          content: AlterErrContent(errMsg),
        });
      }
    }
  },
  handleGetFindTeams: ({
    credentials,
    environmentId,
    systemId,
    projectOwner,
    projectName,
    instanceName,
    patternId,
    incidentTimestamp,
    startTimestamp,
  }) => {
    const result = fetchGet(getEndpoint('find-teams'), {
      ...credentials,
      environmentName: environmentId,
      systemName: systemId,
      customerName: projectOwner,
      projectName,
      instanceName,
      patternId,
      incidentTimestamp,
      startTime: startTimestamp,
    })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('[IF_API] ', error);
        return { success: false, message: error.message || String(error) };
      });
    return result;
  },
  handleReturnFindTeamsFalse: ({
    success,
    errMsg,
    channelExists,
    url,
    channels,
    onReload,
    openConfirmModal,
    intl,
    credentials,
    updateLastActionInfo,
    environmentId,
    systemId,
    event,
  }) => {
    if (success) {
      if (channelExists) {
        window.open(url, '_blank');
        if (onReload) onReload();
        openConfirmModal.destroy();
      } else {
        const teamsChannelOptions = [{ value: 'newChannel', label: 'New channel' }];
        R.forEach((item) => {
          teamsChannelOptions.push({ value: item.id, label: item.displayName });
        }, channels || []);
        EventActionHandle.handleCreateChannelOnTeamsModal({
          intl,
          credentials,
          updateLastActionInfo,
          environmentId,
          systemId,
          event,
          onReload,
          openConfirmModal,
          teamsChannelOptions,
        });
      }
    } else {
      openConfirmModal.update({
        content: (
          <div>
            <Alert message={errMsg} type="error" style={{ marginTop: 8 }} />
          </div>
        ),
      });
    }
  },
  handleCreateChannelOnTeamsModal: ({
    intl,
    credentials,
    updateLastActionInfo,
    environmentId,
    systemId,
    event,
    onReload,
    openConfirmModal,
    teamsChannelOptions,
  }) => {
    let channelId = 'newChannel';
    // handle channel change
    const onChangeChannel = (channel) => {
      channelId = channel;
    };
    openConfirmModal.update({
      content: (
        <div>
          <div style={{ marginBottom: 16 }}>
            {intl.formatMessage(eventMessages.goTeamsChannelWithCreateAutomatically)}
          </div>
          <div className="flex-row flex-center-align">
            <div className="light-label bold" style={{ marginRight: 8 }}>{`${intl.formatMessage(
              appFieldsMessages.channel,
            )}:`}</div>
            <div className="flex-grow flex-min-width">
              <Select
                style={{ width: '100%' }}
                allowClear={false}
                showArrow={false}
                showSearch
                filterOption
                optionFilterProp="label"
                options={teamsChannelOptions}
                defaultValue={channelId}
                onChange={onChangeChannel}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              />
            </div>
          </div>
        </div>
      ),
      okButtonProps: { disabled: false },
      onOk: (close) => {
        EventActionHandle.handleCreateChannelOnTeamsSumbit(close, {
          intl,
          credentials,
          updateLastActionInfo,
          environmentId,
          systemId,
          event,
          openConfirmModal,
          onReload,
          channelId,
        });
      },
    });
  },
  handleCreateChannelOnTeamsSumbit: (
    close,
    { intl, credentials, updateLastActionInfo, environmentId, systemId, event, openConfirmModal, onReload, channelId },
  ) => {
    const {
      projectName,
      projectOwner,
      incidentTimestamp,
      startTimestamp,
      endTimestamp,
      type,
      instanceName,
      patternId,
    } = event;

    if (openConfirmModal) {
      openConfirmModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }

    if (updateLastActionInfo) updateLastActionInfo();
    Promise.all([
      fetchPost(getEndpoint('create-teams', 2), {
        ...credentials,
        environmentName: environmentId,
        systemName: systemId,
        projectName,
        customerName: projectOwner,
        incidentTimestamp,
        startTime: startTimestamp,
        endTime: endTimestamp,
        type,
        instanceName,
        patternId,
        operation: 'Email',
        channelId: channelId !== 'newChannel' ? channelId : null,
      }),
    ])
      .then((results) => {
        const { success, message: errMsg, url } = results[0] || {};
        if (success) {
          window.open(url, '_blank');
          if (onReload) onReload();
          close();
        } else if (openConfirmModal) {
          openConfirmModal.update({
            content: (
              <div>
                <div>{intl.formatMessage(eventMessages.goTeamsChannelWithCreateAutomatically)}</div>
                <Alert message={errMsg} type="error" style={{ marginTop: 8 }} />
              </div>
            ),
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        if (openConfirmModal) {
          openConfirmModal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      });
  },
  handleCreateChannelOnSlackClick: async ({
    intl,
    credentials,
    updateLastActionInfo,
    environmentId,
    systemId,
    event,
    onReload,
  }) => {
    const { projectName, projectOwner, instanceName, patternId, incidentTimestamp, startTimestamp } = event;

    const openConfirmModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <div className="flex-row flex-center-align flex-center-justify">
          <Spin spinning />
        </div>
      ),
      okButtonProps: { disabled: true },
      onOk: (close) => close(),
    });

    const result = await fetchGet(getEndpoint('find-slack'), {
      ...credentials,
      environmentName: environmentId,
      systemName: systemId,
      customerName: projectOwner,
      projectName,
      instanceName,
      patternId,
      incidentTimestamp,
      startTime: startTimestamp,
    })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('[IF_API] ', error);
        return { success: false, message: error.message || String(error) };
      });

    const { success, message: errMsg, channelExists, url, channels } = result;
    if (success) {
      if (channelExists) {
        window.open(url, '_blank');
        if (onReload) onReload();
        openConfirmModal.destroy();
      } else {
        const teamsChannelOptions = [{ value: 'newChannel', label: 'New channel' }];
        R.forEach((item) => {
          teamsChannelOptions.push({ value: item.id, label: item.displayName });
        }, channels || []);
        EventActionHandle.handleCreateChannelOnSlackModal({
          intl,
          credentials,
          updateLastActionInfo,
          environmentId,
          systemId,
          event,
          onReload,
          openConfirmModal,
          teamsChannelOptions,
        });
      }
    } else {
      openConfirmModal.update({
        content: (
          <div>
            <Alert message={errMsg} type="error" style={{ marginTop: 8 }} />
          </div>
        ),
      });
    }
  },
  handleCreateChannelOnSlackModal: ({
    intl,
    credentials,
    updateLastActionInfo,
    environmentId,
    systemId,
    event,
    onReload,
    openConfirmModal,
    teamsChannelOptions,
  }) => {
    let channelId = 'newChannel';
    // handle channel change
    const onChangeChannel = (channel) => {
      channelId = channel;
    };
    openConfirmModal.update({
      content: (
        <div>
          <div style={{ marginBottom: 16 }}>
            {intl.formatMessage(eventMessages.goSlackChannelWithCreateAutomatically)}
          </div>
          <div className="flex-row flex-center-align">
            <div className="light-label bold" style={{ marginRight: 8 }}>{`${intl.formatMessage(
              appFieldsMessages.channel,
            )}:`}</div>
            <div className="flex-grow flex-min-width">
              <Select
                style={{ width: '100%' }}
                allowClear={false}
                showArrow={false}
                showSearch
                filterOption
                optionFilterProp="label"
                options={teamsChannelOptions}
                defaultValue={channelId}
                onChange={onChangeChannel}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              />
            </div>
          </div>
        </div>
      ),
      okButtonProps: { disabled: false },
      onOk: (close) => {
        EventActionHandle.handleCreateChannelOnSlackSumbit(close, {
          intl,
          credentials,
          updateLastActionInfo,
          environmentId,
          systemId,
          event,
          openConfirmModal,
          onReload,
          channelId,
        });
      },
    });
  },
  handleCreateChannelOnSlackSumbit: (
    close,
    { intl, credentials, updateLastActionInfo, environmentId, systemId, event, openConfirmModal, onReload, channelId },
  ) => {
    const {
      projectName,
      projectOwner,
      incidentTimestamp,
      startTimestamp,
      endTimestamp,
      type,
      instanceName,
      patternId,
    } = event;

    if (openConfirmModal) {
      openConfirmModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }

    if (updateLastActionInfo) updateLastActionInfo();
    Promise.all([
      fetchPost(getEndpoint('create-slack'), {
        ...credentials,
        environmentName: environmentId,
        systemName: systemId,
        projectName,
        customerName: projectOwner,
        incidentTimestamp,
        startTime: startTimestamp,
        endTime: endTimestamp,
        type,
        instanceName,
        patternId,
        operation: 'Email',
        channelId: channelId !== 'newChannel' ? channelId : null,
        incidentScope: 'thread',
      }),
    ])
      .then((results) => {
        const { success, message: errMsg, url } = results[0] || {};
        if (success) {
          window.open(url, '_blank');
          if (onReload) onReload();
          close();
        } else if (openConfirmModal) {
          openConfirmModal.update({
            content: (
              <div>
                <div>{intl.formatMessage(eventMessages.goSlackChannelWithCreateAutomatically)}</div>
                <Alert message={errMsg} type="error" style={{ marginTop: 8 }} />
              </div>
            ),
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        if (openConfirmModal) {
          openConfirmModal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
      });
  },
};

export default EventActionHandle;
