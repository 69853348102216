import React from 'react';
import { connect } from 'react-redux';
import { get, forEach, find, keysIn, each } from 'lodash';
import * as R from 'ramda';
import { autobind } from 'core-decorators';

import DataParser from '../dataparser';
import { State } from '../../../src/common/types';
import { ButtonGroup, Button } from '../../../artui/react';
import { Container, Select } from '../../../src/lib/fui/react';

import { DataSummaryChart, DataGroupCharts } from '../../share/charts';

const getSelectedGroup = (value, appGroups) => {
  let selectedGroups = appGroups;
  const newNames = [];

  if (appGroups && value) {
    const names = value.split(',');
    const groups = [];
    forEach(names, (n) => {
      const gp = find(appGroups, (g) => g.metrics === n);
      if (gp) {
        groups.push(gp);
        newNames.push(n);
      }
    });
    selectedGroups = groups;
  }

  return {
    names: newNames,
    selectedGroups,
  };
};

type Props = {
  data: Object,
  loading: Boolean,
  isFileDetection: Boolean,
  enablePublish: Boolean,
  onRefresh: Function,
  predictedFlag: String,
  isForecast: Boolean,
  chartType: String,
};

class LiveAnalysisCharts extends React.PureComponent {
  props: Props;

  static defaultProps = {
    data: {},
    loading: true,
    isFileDetection: false,
    enablePublish: false,
    chartType: 'line',
    onRefresh: () => {},
    predictedFlag: '',
    isForecast: false,
  };

  constructor(props) {
    super(props);
    this.dp = null;

    const { predictedFlag } = props;
    const initView = predictedFlag === 'true' ? 'list' : 'grid';

    this.state = {
      instanceName: false,
      view: initView,
      columns: 'four',
      showSummaryFlag: 'yes',
      selectedGroupId: undefined,
      selectedAnnotation: null,
      showAnomalySummary: false,
      showDebug: false,
      startTimestamp: undefined,
      endTimestamp: undefined,
      isForecast: false,
      selectedMetricList: [],
      selectedInstances: [],
      selectedGroups: null,
      hideGroupSelector: false,
    };

    if (props.isForecast && !!props.isForecast) {
      this.state.isForecast = true;
      this.state.view = 'list';
    }
    if (props.isEmailAert) {
      this.state.view = 'list';
    }
  }

  @autobind
  calculateData() {
    // Cache the data, and recalculate it if changed.
    const {
      data,
      loading,
      onRefresh,
      projectName,
      instanceName,
      metricName,
      startTimestamp,
      detectSuccess,
      eventsGroupsData,
      eventsSummaryData,
    } = this.props;
    if (this.latestData !== data && !!data) {
      this.dp = new DataParser(data, instanceName);
      this.dp.getSummaryData();
      this.dp.getMetricsData();

      // Sort the grouped data
      this.summary = this.dp.summaryData;
      this.causalDataArray = this.dp.causalDataArray;
      this.causalTypes = this.dp.causalTypes;
      this.groups = this.dp.groupsData || [];

      // The summary sdata might not have the first or latest time, get the timestamp from group sdata
      if (this.summary && this.groups && this.groups.length > 0) {
        let { sdata } = this.summary;
        const groupSData = this.groups[0].sdata;
        if (sdata && groupSData) {
          const firstData = sdata[0];
          const firstGData = groupSData[0];
          const latestData = sdata[sdata.length - 1];
          const latestGData = groupSData[groupSData.length - 1];
          if (firstData && firstGData) {
            const t = firstData[0];
            const gt = firstGData[0];
            if (gt < t) {
              sdata = [[new Date(gt), 0], ...sdata];
            }
          }

          if (latestData && latestGData) {
            const t = latestData[0];
            const gt = latestGData[0];
            if (gt > t) {
              sdata.push([new Date(gt), 0]);
            }
          }
          this.summary.sdata = sdata;
        }
      }

      this.instanceList = R.reduce(
        (acc, g) => {
          const instanceList = R.keys(g.instanceMetricMap || {});
          return R.uniq(acc.concat(instanceList));
        },
        [],
        this.groups,
      );
      this.groupMetrics = this.dp.groupmetrics || null;

      // Add highlight for the groups
      if (eventsGroupsData) {
        R.forEach((group) => {
          group.highlights = get(eventsGroupsData, [group.metrics, 'highlights'], []);
        }, this.groups);
      }
      if (eventsSummaryData && this.groups.length > 0) {
        // Get the first/last time of group and prepend into summary data
        const groupSData = this.groups[0].sdata || [];
        let sdata = eventsSummaryData.sdata || [];
        let changed = false;
        if (groupSData.length > 0) {
          if (sdata.length > 0) {
            if (groupSData[0][0].valueOf() < sdata[0][0].valueOf()) {
              sdata = [[groupSData[0][0], null], ...sdata];
              changed = true;
            }
            if (groupSData[groupSData.length - 1][0].valueOf() > sdata[sdata.length - 1][0].valueOf()) {
              sdata = [...sdata, [groupSData[groupSData.length - 1][0], null]];
              changed = true;
            }
          } else {
            sdata = [
              [groupSData[0][0], null],
              [groupSData[groupSData.length - 1][0], null],
            ];
            changed = true;
          }
        }
        if (changed) {
          this.eventsSummaryData = { ...eventsSummaryData, sdata };
        } else {
          this.eventsSummaryData = eventsSummaryData;
        }
      } else {
        this.eventsSummaryData = eventsSummaryData;
      }

      this.latestData = data;
    } else if (!this.summary && (!this.groups || this.groups.length === 0)) {
      if (detectSuccess !== undefined) {
        // failed detection
        this.errorMsg = 'Detection failed';
        if (projectName) {
          this.errorMsg += ` for project ${projectName}`;
        }
      } else {
        // display project data
        this.errorMsg = 'Empty data';
        if (instanceName) {
          this.errorMsg += ` for instance ${instanceName}`;
          if (metricName) {
            this.errorMsg += `, metric ${metricName}`;
          }
        }
        if (startTimestamp) {
          this.errorMsg += ' during requested time period';
        }
      }
    }
    if (instanceName && data) {
      const metricAvgRaw = get(data.instanceMetricJson, ['instanceStatsJson', instanceName, 'statsByMetricJson'], {});
      const avgNumberOfDays = data.instanceMetricJson && data.instanceMetricJson.avgNumberOfDays;
      if (metricAvgRaw && avgNumberOfDays) {
        const metricAvg = {};
        Object.keys(metricAvgRaw).forEach((key) => {
          if (metricAvgRaw[key] && metricAvgRaw[key].avg !== undefined) {
            metricAvg[key] = ` (${avgNumberOfDays}d avg: ${metricAvgRaw[key].avg.toPrecision(3)})`;
          }
        });
        this.metricAvg = metricAvg;
      }
    }
  }

  exportData() {
    const { data } = this.dp.data;
    const fname = 'data.csv';
    const csvString = data.split('\\n').join('\r\n');
    const csvData = new Blob([csvString], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.innerHTML = 'Click here';
    a.href = csvUrl;
    a.target = '_blank';
    a.method = 'POST';
    a.download = fname;
    a.click();
    document.body.removeChild(a);
  }

  @autobind
  handleDateWindowSync(dateWindow) {
    this.setState({ chartDateWindow: dateWindow });
  }

  @autobind
  handleAnnotationClick(anno) {
    if (anno && anno.text && this.groups) {
      const re = /(?:metric[: ])(.*)(?:[,(])/gi;
      let metrics = [];
      let match = re.exec(anno.text);
      while (match) {
        metrics.push(match[1]);
        match = re.exec(anno.text);
      }
      metrics = R.uniq(metrics);
      if (metrics.length > 0) {
        const { selectedGroups } = getSelectedGroup(metrics.join(','), this.groups);
        this.setState(
          {
            hideGroupSelector: true,
          },
          () => {
            this.setState({
              selectedMetricList: metrics,
              selectedGroups,
              hideGroupSelector: false,
            });
          },
        );
      }
    }
  }

  @autobind
  handleMetricSelectionChange(newValue) {
    if (this.groups) {
      const metricList = R.map((v) => v.value, newValue);
      const { names, selectedGroups } = getSelectedGroup(metricList.join(','), this.groups);
      this.setState({
        selectedMetricList: R.map((n) => ({ label: n, value: n }), names || []),
        selectedGroups,
        hideGroupSelector: false,
      });
    }
  }

  @autobind
  handleInstanceSelectionChange(newValue) {
    this.setState({ selectedInstances: newValue });
  }

  @autobind
  filterGroupsByInstanceList(groups, instanceList) {
    if (!groups || instanceList.length === this.instanceList.length) {
      return groups;
    }

    const filteredGroups = [];
    R.forEach((g) => {
      const sname = g.sname || [];
      const instanceMetricMap = g.instanceMetricMap || {};
      const metricList = R.uniq(
        R.reduce(
          (acc, i) => {
            return R.uniq(acc.concat(instanceMetricMap[i] || []));
          },
          [],
          instanceList,
        ),
      );
      // If only timestamp left, ignore this group
      const diff = R.difference(sname, metricList);
      if (diff.length > 1) {
        filteredGroups.push(g);
      }
    }, groups);
    return filteredGroups;
  }

  render() {
    const { projectName, projects, userName } = this.props;

    const { loading, onRefresh, enableComments, periodMap } = this.props;
    const { isFileDetection, data, chartType, alertMissingData, bugId } = this.props;
    const { eventsGroupsData, isUseCaseDetails, eventsGroupHighlights, isEmailAert } = this.props;
    const { eventsNeurons } = this.props;
    let { enablePublish, debugData, timeRanking, freqRanking } = this.props;

    const project = R.find((p) => p.projectName === projectName, projects);
    const { view, columns, showSummaryFlag, isForecast } = this.state;

    const { selectedMetricList, selectedInstances, hideGroupSelector } = this.state;
    let { selectedGroups } = this.state;

    debugData = debugData || [];
    timeRanking = timeRanking || [];
    freqRanking = freqRanking || [];
    this.calculateData();

    const { summary } = this;
    const dataArray = this.causalDataArray;
    const types = this.causalTypes;
    const { groups } = this;
    const { eventsSummaryData } = this;
    if (!selectedGroups || selectedGroups.length === 0) {
      selectedGroups = groups;
    }
    const { errorMsg } = this;
    const { metricAvg } = this;
    const settingData = keysIn(debugData).length !== 0 || timeRanking.length !== 0 || freqRanking !== 0;
    const propsData = this.props.data ? this.props.data.instanceMetricJson : {};
    const latestDataTimestamp = propsData ? propsData.latestDataTimestamp : '';
    const dataChunkName = data && data.dataChunkName;
    const metricTags = {};
    if (data && data.anomalyMetrics) {
      const anomalyMetrics = data.anomalyMetrics.split(',');
      each(anomalyMetrics, (am) => {
        metricTags[am] = ' (Anomaly Detected) ';
      });
    }
    if (projectName && userName !== 'admin' && projectName.indexOf('@') === -1) {
      enablePublish = true;
    }

    const hasInstanceList = Boolean(this.instanceList) && this.instanceList.length > 0;
    const selectedInstanceList = R.map((r) => r.value, selectedInstances);
    selectedGroups = this.filterGroupsByInstanceList(selectedGroups, selectedInstanceList);
    if (eventsGroupHighlights) {
      selectedGroups = R.map((g) => {
        const highlights = eventsGroupHighlights[g.metrics] || [];
        return { ...g, highlights };
      }, selectedGroups);
    }

    return (
      <Container className={`full-height ui form ${loading ? 'loading' : ''} full-width`} style={{ width: '100%' }}>
        <div
          className="ui main tiny container full-height flex-col flex-min-height "
          style={{ display: loading && 'none', width: '100%' }}
        >
          <Container breadcrumb className="flex-row">
            <div className="flex-grow">
              {!bugId && !isForecast && (
                <Button size="small" className="labeled icon" onClick={() => onRefresh()}>
                  <i className="icon refresh" />
                  <span>Refresh</span>
                </Button>
              )}
              {!isForecast && (
                <Button size="small" className="labeled icon" onClick={() => this.exportData()}>
                  <i className="icon download" />
                  <span>Export</span>
                </Button>
              )}
            </div>
            <Button
              size="small"
              className="orange labeled icon"
              onClick={() => this.setState({ showDebug: true })}
              style={{ display: 'none' }}
            >
              <i className="zoom icon" />
              <span>Faulty Localization Result</span>
            </Button>
            {!isForecast && (
              <ButtonGroup className="right floated basic icon">
                <Button size="small" active={view === 'list'} onClick={() => this.setState({ view: 'list' })}>
                  <i className="align justify icon" />
                </Button>
                <Button size="small" active={view === 'grid'} onClick={() => this.setState({ view: 'grid' })}>
                  <i className="grid layout icon" />
                </Button>
              </ButtonGroup>
            )}
          </Container>

          <Container className="block flex-grow flex-col flex-min-height" style={{ padding: '16px 16px 0 16px' }}>
            {!summary && (!groups || groups.length === 0) && <h3>{errorMsg}</h3>}
            {showSummaryFlag.toLowerCase() === 'yes' &&
              !isEmailAert &&
              (Boolean(summary) || (isUseCaseDetails && !isEmailAert && Boolean(eventsSummaryData))) && (
                <DataSummaryChart
                  key="summary_chart"
                  summary={isUseCaseDetails && !isEmailAert ? eventsSummaryData : eventsSummaryData || summary}
                  latestDataTimestamp={latestDataTimestamp}
                  isEmailAert={isEmailAert}
                  project={project}
                  onDateWindowChange={this.handleDateWindowSync}
                  dateWindow={this.state.chartDateWindow}
                  onAnnotationClick={this.handleAnnotationClick}
                />
              )}
            {!!groups && groups.length > 0 && (
              <div className="flex-row" style={{ paddingTop: 8, paddingBottom: 8 }}>
                <label style={{ fontWeight: 'bold', paddingRight: 10, paddingTop: 4 }}>Metric Filters:</label>
                {!hideGroupSelector && (
                  <Select
                    multi
                    autosize
                    value={selectedMetricList}
                    onChange={this.handleMetricSelectionChange}
                    style={{ minWidth: 200 }}
                    options={R.map((g) => ({ label: g.metrics, value: g.metrics }), groups)}
                  />
                )}
                {hasInstanceList && (
                  <label style={{ fontWeight: 'bold', paddingRight: 10, paddingLeft: 10, paddingTop: 4 }}>
                    Instances Filters:
                  </label>
                )}
                {hasInstanceList && (
                  <Select
                    name="instance"
                    multi
                    autosize
                    value={selectedInstances}
                    onChange={this.handleInstanceSelectionChange}
                    style={{ minWidth: 200 }}
                    options={R.map((i) => ({ label: i, value: i }), this.instanceList)}
                  />
                )}
              </div>
            )}
            {!!selectedGroups && selectedGroups.length > 0 && (
              <DataGroupCharts
                chartType={chartType}
                key={`${view}_group_charts`}
                metricTags={metricTags}
                groups={selectedGroups}
                project={project}
                view={view}
                columns={columns}
                latestDataTimestamp={latestDataTimestamp}
                isEmailAert={isEmailAert}
                alertMissingData={alertMissingData}
                periodMap={periodMap}
                metricAvg={metricAvg}
                selectedInstanceList={selectedInstanceList}
                onDateWindowChange={this.handleDateWindowSync}
                dateWindow={this.state.chartDateWindow}
              />
            )}
          </Container>
        </div>
      </Container>
    );
  }
}

export default connect((state: State) => {
  const { projects } = state.app;
  const { userName } = state.auth.userInfo;
  return { projects, userName };
}, {})(LiveAnalysisCharts);
