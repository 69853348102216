/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import { get, isArray } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getEventAction = (credentials: Credentials, params: Object) => {
  const {
    projectName,
    projectNameList,
    neuronId,
    operation,
    grouping,
    instanceName,
    eventType,
    rootCauseKey,
    startTime,
    endTime,
    systemId,
    projectDisplayMap,
    customerName,
    ...rest
  } = params;

  const promiseQuery = [
    fetchGet(getEndpoint('patternsetting'), {
      ...credentials,
      projectName,
      instanceName,
      eventType,
      grouping,
      patternId: neuronId,
      rootCauseKey,
      startTime,
      endTime,
    }).then((d) => {
      let patternList = get(d, 'data', []);
      patternList = R.map((p) => {
        const { actionIdList, actionIdToProxyServerMap, ...rest } = p;

        let savedActions = [];
        if (isArray(actionIdList)) {
          R.forEach((actionId) => {
            savedActions.push({
              actionId,
              proxyServerId: get(actionIdToProxyServerMap, actionId, [])[0],
            });
          }, actionIdList || []);
        } else {
          R.forEachObjIndexed((val) => {
            R.forEach((actionId) => {
              savedActions.push({
                actionId,
                proxyServerId: get(actionIdToProxyServerMap, actionId, [])[0],
              });
            }, val);
          }, actionIdList || {});
        }
        savedActions = R.uniqWith(R.eqBy(R.prop('actionId')), savedActions);

        return {
          ...rest,
          savedActions,
        };
      }, patternList);
      return patternList;
    }),
  ];

  if (projectNameList) {
    promiseQuery.push(
      fetchGet(getEndpoint('projectactions'), {
        ...credentials,
        ...rest,
        projectNameList,
        operation,
      }).then((d) => {
        const { projectActionMap } = d;
        const actionList = [];
        let actionIdList = [];
        R.forEachObjIndexed((actions, projectFullName) => {
          R.forEach((action) => {
            actionList.push({ name: action.name, actionId: action.actionId, projectFullName });
            actionIdList.push(action.actionId);
          }, actions);
        }, projectActionMap || []);
        actionIdList = R.uniq(actionIdList);
        return fetchGet(getEndpoint('actionemailsetting'), {
          ...credentials,
          actionIdList: JSON.stringify(actionIdList),
          projectNameList,
          instanceName,
          patternId: neuronId,
        }).then((d) => {
          const { projectEmailSettingMap } = d || {};
          return { actionsList: actionList || [], projectEmailSettingMap };
        });
      }),
    );
  } else {
    promiseQuery.push(
      fetchGet(getEndpoint('projectactions'), {
        ...credentials,
        ...rest,
        projectName,
        neuronId,
      }).then((d) => {
        const { actionsList } = d;
        const actionNameList = [];
        const actionIdList = [];
        R.forEach((action) => {
          actionNameList.push({ name: action.name, id: action.actionId });
          actionIdList.push(action.actionId);
        }, actionsList || []);
        return fetchGet(getEndpoint('actionemailsetting'), {
          ...credentials,
          actionIdList: JSON.stringify(actionIdList),
          projectName,
          instanceName,
          patternId: neuronId,
        }).then((d) => {
          const { data } = d || {};
          let emailAlert = JSON.parse(data || '[]');
          emailAlert = R.map((ea) => {
            const { patternId, projectName, userName } = ea.key || {};
            return { ...ea, patternId, projectName, userName };
          }, emailAlert);
          return { actionsList: actionsList || [], emailAlert };
        });
      }),
    );
  }

  if (systemId) {
    promiseQuery.push(
      fetchGet(getEndpoint('actionproxyserver'), {
        ...credentials,
        systemName: systemId,
        customerName,
      }),
    );
  }

  return Promise.all(promiseQuery).then((results) => {
    // saved actions
    const d1 = results[0];

    // actions
    const { actionsList, emailAlert, projectEmailSettingMap } = results[1];
    const otherActions = R.map((d) => {
      if (projectNameList) {
        const [projectName, owner] = d.projectFullName.split('@');
        const projectNameReal = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
        const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
        return {
          label: `${d.name} (${projectDisplayName})`,
          value: d.actionId,
          operation: 'custom',
          projectName: projectNameReal,
        };
      }
      const projectDisplayName = get(projectDisplayMap, projectName, projectName);
      return { label: `${d.name} (${projectDisplayName})`, value: d.actionId, operation: 'custom' };
    }, R.uniqWith(R.eqBy(R.prop('actionId')), actionsList));

    // proxy
    const { actionProxyServers } = results[2] || {};

    const result = {
      certainPatternList: d1,
      actionList: otherActions,
      emailAlert,
      projectEmailSettingMap,
      actionProxyServers: actionProxyServers || [],
    };
    return result;
  });
};

export default getEventAction;
