/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import * as R from 'ramda';
import { get } from 'lodash';
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getRootCauseTimelines } from '../../apis';
import { appMessages } from '../../app/messages';

const loadRootCauseTimelinesLogic = createLogic({
  type: [ActionTypes.LOAD_ROOT_CAUSE_TIMELINES],
  cancelType: ActionTypes.APP_STOP,
  // debounce: 300,
  // latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { globalInfo } = state.app;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { isAutoReload, environmentId, environmentName } = params;

    // Get the system ids for the environment
    const environment = R.find((item) => item.id === environmentId || environmentName || 'All', globalInfo);
    const systemInfoMap = {};
    R.forEach((system) => {
      const projectDisplayMap = {};
      const projectNameSet = system.projectNameSet || [];
      R.forEach((item) => {
        projectDisplayMap[item.projectName] = item.projectDisplayName;
      }, projectNameSet);
      systemInfoMap[system.id] = { ...(system.systemInfo || {}), projectNameSet, projectDisplayMap };
    }, get(environment, 'systemList', []));

    dispatch(showLoading);
    if (!isAutoReload) dispatch(updateLastActionInfo());
    getRootCauseTimelines(credentials, { ...params, systemInfoMap })
      .then((d) => {
        dispatch(createSetAction(ActionTypes.SET_GLOBAL_TIMELINE_INFO, params, d));
      })
      .catch((err) => {
        console.error('[IF_API] Failed get root cause timelines', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }
        dispatch(hideLoading);
        done();
      });
  },
});

export default loadRootCauseTimelinesLogic;
