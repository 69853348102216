import React, { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import L from 'lodash';
import moment from 'moment';

import { Col, Input, message, notification, Row, Select, Spin, Table } from 'antd';
import { settingsMessages } from '../../../../common/settings/messages';
import OperationButtons from './OperationButtons';
// eslint-disable-next-line import/no-cycle
import { BatchContext } from '../pageContext';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { appMessages } from '../../../../common/app/messages';
import TransferTree from './TransferTree';
import { eventMessages } from '../../../../common/metric/messages';

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

type Props = {
  onCancel: Function,
  onNext: Function,
  onPrev: Function,
  onSetPageData: Function,
  onShowLoading: Function,
  onHideLoading: Function,
  pageData: Object,
};
const Step3 = (props: Props) => {
  const { onNext, onPrev, onSetPageData, pageData, onCancel, onShowLoading, onHideLoading } = props;
  const { dataType, selectedProjects = [], owner, pageType, config, cloudType, agentType, projectFilter } = pageData;
  const { intl, isAdmin, allProjects, credentials, userList, requestData, getSystemLoading, systemsMap, ...context } =
    useContext(BatchContext);
  const [systemList, setSystemList] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [targetData, setTargetData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);

  const [cloudTypeOptions, setCloudTypeOptions] = useState([]);
  const [agentTypeOptions, setAgentTypeOptions] = useState([]);

  const [reload, setReload] = useState(moment.utc().valueOf());

  const reloadData = async () => {
    const projectOwner = owner;
    await requestData(projectOwner);
  };

  const parseSystemList = async () => {
    const { userInfo } = context;
    const projectOwner = owner;
    let systemList = R.values(systemsMap);
    systemList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('systemName')))], systemList);

    if (projectOwner && false) {
      systemList = R.filter((item) => item.owner === projectOwner, systemList);
    }
    systemList.push({
      systemId: 'emptySystem',
      systemName: intl.formatMessage(settingsMessages.projectsNoSystem),
      environmentName: 'All',
      owner: userInfo.userName,
    });

    const currentSystemAllProjectMeta = [];
    R.forEach(({ projectDetailsList = [] }) => {
      R.forEach(({ projectName, customerName, ...n }) => {
        if (n.dataType === dataType) {
          currentSystemAllProjectMeta.push({ projectName, customerName });
        }
      }, projectDetailsList);
    }, systemList);

    systemList = R.reduce(
      (prev, { projectDetailsList = [], ...system }) => {
        const projectList = [];
        R.forEach((n) => {
          const projectInfo = R.find((p) => p.projectKey === n.projectKey, allProjects || []);
          n = { ...n, ...projectInfo };
          if (n.dataType === dataType) {
            if (projectInfo) {
              if (projectInfo.ps !== 'Deleting') {
                projectList.push(n);
              }
              return;
            }
            projectList.push(n);
          }
        }, projectDetailsList);
        return R.isEmpty(projectList) ? prev : [...prev, { ...system, projectDetailsList: projectList }];
      },
      [],
      systemList,
    );

    const cloudTypes = R.map(
      (cloudType) => ({ value: cloudType, label: cloudType }),
      R.sort((a, b) => a.localeCompare(b), R.uniq(R.map((item) => item.cloudType, allProjects || []))),
    );
    const agentTypes = R.map(
      (insightAgentType) => ({ value: insightAgentType, label: insightAgentType }),
      R.sort((a, b) => a.localeCompare(b), R.uniq(R.map((item) => item.insightAgentType, allProjects || []))),
    );

    setCloudTypeOptions(cloudTypes);
    setAgentTypeOptions(agentTypes);
    setSystemList(systemList);
  };

  const parseTreeData = (systemSource) => {
    const treeData = systemSource.map((system) => {
      const { projectDetailsList, systemId, systemName } = system;
      return {
        ...system,
        key: systemId,
        title: systemName,
        checked: false,
        expand: true,
        show: true,
        children: L.uniq(
          L.map(projectDetailsList || [], (project) => ({
            ...project,
            checked: false,
            title: project.projectShortName,
            key: `${project.projectKey}-${uuidv4()}`,
            show: true,
          })),
        ),
      };
    });
    if (!L.isEmpty(selectedProjects)) {
      setDefaultTransferValue(selectedProjects, treeData);
    } else {
      setTreeData(treeData);
    }
  };

  const setDefaultTransferValue = (projects, treeData) => {
    const keys = [];

    const projectNames = R.map((p) => p.projectShortName || p.projectName, projects || []);
    const defaultTargetSource = L.reduce(
      L.cloneDeep(treeData),
      (prev, cur) => {
        const children = L.filter(cur.children, (p) => {
          if (projectNames.includes(p.projectShortName)) {
            keys.push(p.key);
            p.checked = false;
            return true;
          }
          return false;
        });
        if (L.isEmpty(children)) {
          return prev;
        } else {
          return [...prev, { ...cur, checked: false, children }];
        }
      },
      [],
    );

    L.forEach(treeData, (s) => {
      const { children } = s;
      let selectProjectNum = 0;
      L.forEach(children, (p) => {
        if (keys.includes(p.key)) {
          selectProjectNum += 1;
          p.checked = true;
        }
      });
      if (children.length === selectProjectNum) {
        keys.push(s.key);
        s.checked = true;
      }
    });
    setTargetData(defaultTargetSource);
    setTargetKeys(keys);
    setTreeData(treeData);
  };

  const getEditMetricData = () => {
    const { DATA } = config;
    const {
      toSetComponentMetricSettingList = [],
      setting: { DATA: editSetitngData },
    } = DATA;
    const MetricModelSet = [];
    const MetricSettingSortByProject = {
      previousData: [],
    };

    const metricSetting = R.map((item) => {
      return {
        key: item.key,
        ...JSON.parse(item.componentMetricSettingModelStr || '{}'),
      };
    }, toSetComponentMetricSettingList);

    R.forEach((item) => {
      MetricModelSet.push({
        metricName: item.key.metricName,
        metricType: item.metricType,
      });
      MetricSettingSortByProject.previousData.push(item);
    }, metricSetting);

    return {
      componentMetricSettingSortByProject: {
        previousData: metricSetting,
      },
      commonMetricModelSet: {
        previousData: MetricModelSet,
      },
      settingList: {
        previousData: editSetitngData,
      },
    };
  };

  const getEditLogData = () => {
    const { DATA } = config;
    const {
      toSetLogLabels,
      toSetLogCategoryModelList,
      setting: { DATA: editSetitngData },
    } = DATA;

    return {
      logCategorySortByProject: {
        previousData: toSetLogCategoryModelList,
      },
      logLabelsMapSortByProject: {
        previousData: toSetLogLabels,
      },
      settingList: {
        previousData: editSetitngData,
      },
    };
  };

  const getMetricData = (raw) => {
    const { componentMetricSettingSortByProject = '[]', commonMetricModelSet = '[]', settingList = '{}' } = raw;

    const settingMap = R.map((s) => {
      return JSON.parse(s).DATA;
    }, settingList);

    let metricSetting = JSON.parse(componentMetricSettingSortByProject);
    metricSetting = R.mapObjIndexed((val, key) => {
      return R.map(
        (item) => ({ key: item.key, ...JSON.parse(item.componentMetricSettingModelStr || '{}') }),
        val || [],
      );
    }, metricSetting || {});

    return {
      componentMetricSettingSortByProject: metricSetting,
      commonMetricModelSet: { current: JSON.parse(commonMetricModelSet) },
      settingList: settingMap,
    };
  };

  const getLogData = (raw) => {
    const { logCategorySortByProject = '[]', logLabelsMapSortByProject = '[]', settingList = '{}' } = raw;

    const settingMap = R.map((s) => {
      return JSON.parse(s).DATA;
    }, settingList);

    return {
      logCategorySortByProject: JSON.parse(logCategorySortByProject),
      logLabelsMapSortByProject: JSON.parse(logLabelsMapSortByProject),
      settingList: settingMap,
    };
  };

  const handleNext = async () => {
    if (R.isEmpty(selectedProjects) || R.isNil(selectedProjects)) {
      message.error('Please select project');
      return;
    }
    onShowLoading();
    let projects = L.cloneDeep(selectedProjects);
    const selectProjectMeta = [];

    projects = R.filter((item) => {
      return !!R.find(
        (p) =>
          p.projectName ===
          (item.projectShortName
            ? item.projectName
            : item.customerName !== credentials.userName
            ? `${item.projectName}@${item.customerName}`
            : item.projectName),
        allProjects,
      );
    }, projects);

    projects = R.map((item) => {
      const meta = R.find(
        (p) =>
          p.projectName ===
          (item.projectShortName
            ? item.projectName
            : item.customerName !== credentials.userName
            ? `${item.projectName}@${item.customerName}`
            : item.projectName),
        allProjects,
      );
      if (meta) {
        selectProjectMeta.push(meta);
        return { ...item, ...meta };
      } else {
        return item;
      }
    }, projects);

    const samplingInterval = L.get(selectProjectMeta, [0, 'samplingInterval']);
    const isEqSamplingInterval = selectProjectMeta.every((item) => item?.samplingInterval === samplingInterval);

    if (!isEqSamplingInterval) {
      notification.open({
        message: 'Batch setting error',
        description: (
          <div>
            <p>All projects in the batch configuration must have the same sampling interval.</p>
            <Table size="small" dataSource={selectProjectMeta} rowKey="projectKey" pagination={false}>
              <Table.Column title={intl.formatMessage(eventMessages.projectName)} dataIndex="projectDisplayName" />
              <Table.Column
                title={intl.formatMessage(settingsMessages.samplingInterval)}
                dataIndex="samplingInterval"
                sorter={(a, b) => a.samplingInterval - b.samplingInterval}
              />
            </Table>
          </div>
        ),
        duration: 0,
      });
      onHideLoading();
      return;
    }

    const res = await fetchPost(getEndpoint('batch-project-setting', 2), {
      ...credentials,
      projectList: JSON.stringify(
        R.map((p) => ({ projectName: p.projectShortName || p.projectName, customerName: p.customerName }), projects),
      ),
      dataType,
      detail: true,
    })
      .then((res) => {
        onHideLoading();
        return res;
      })
      .catch((e) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        onHideLoading();
      });

    const isEdit = pageType === 'edit';
    const applyProject = isEdit ? 'previousData' : projects[0].projectShortName;
    const data = {};

    if (dataType === 'Metric') {
      const metricData = getMetricData(res);
      data.metricData = metricData;
      if (isEdit) {
        const editMetricData = getEditMetricData();
        data.metricData = L.merge(metricData, editMetricData);
      }
    } else {
      const logData = getLogData(res);
      data.logData = logData;
      if (isEdit) {
        const editLogData = getEditLogData();
        data.logData = L.merge(logData, editLogData);
      }
    }

    onSetPageData({
      ...data,
      applyProject,
      sensitivityData: null,
      metricConfiguration: null,
      needRemoveCategory: [],
      updateStatus: null,
    });
    onNext();
  };

  const handleFilterUser = (value, key) => {
    onSetPageData({ [key]: value });
    if (key !== 'owner') {
      setReload(moment.utc().valueOf());
    } else {
      onSetPageData({
        selectedProjects: null,
        cloudType: null,
        agentType: null,
        projectFilter: null,
      });
      setTargetData([]);
      setTargetKeys([]);
      setTreeData([]);
    }
  };

  const handlePrev = () => {
    onSetPageData({
      selectedProjects: null,
    });
    onPrev();
  };

  const handleChange = ({ targetKeys, targetSource }) => {
    const selectedProjects = L.reduce(
      targetSource,
      (prev, { children }) => {
        return [...prev, ...children];
      },
      [],
    );
    onSetPageData({
      selectedProjects,
      targetSource,
    });
  };

  useEffect(() => {
    return () => {
      onSetPageData({
        cloudType: null,
        agentType: null,
        projectFilter: null,
      });
    };
  }, []);

  useEffect(() => {
    reloadData();
  }, [owner]);

  useEffect(() => {
    parseSystemList();
  }, [systemsMap, allProjects]);

  useEffect(() => {
    parseTreeData(systemList);
  }, [systemList]);

  useEffect(() => {
    setReload(moment.utc().valueOf());
  }, [treeData]);

  return (
    <div className="batch-step-content step-project">
      <Spin spinning={getSystemLoading}>
        <Row style={{ marginBottom: 20 }} align="middle">
          <Col>
            {isAdmin && (
              <Select
                showSearch
                size="small"
                placeholder="Owner"
                style={{ width: 180, marginRight: 16 }}
                optionFilterProp="children"
                value={owner}
                onChange={(val) => handleFilterUser(val, 'owner')}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {userList.map((item) => (
                  <Select.Option key={item.userName} title={item.userName}>
                    {item.userName}
                  </Select.Option>
                ))}
              </Select>
            )}
            <Input
              size="small"
              allowClear
              value={projectFilter}
              style={{ width: 180, marginRight: 16 }}
              placeholder={intl.formatMessage(eventMessages.projectName)}
              onChange={(e) => handleFilterUser(e.target.value, 'projectFilter')}
            />
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              placeholder="Cloud Type"
              value={cloudType}
              style={{ width: 180, marginRight: 16 }}
              optionFilterProp="children"
              onChange={(val) => handleFilterUser(val, 'cloudType')}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {cloudTypeOptions.map((item) => (
                <Select.Option key={item.value} title={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Select
              allowClear
              showArrow={false}
              showSearch
              size="small"
              placeholder="Agent Type"
              value={agentType}
              style={{ width: 180, marginRight: 16 }}
              optionFilterProp="children"
              onChange={(val) => handleFilterUser(val, 'agentType')}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {agentTypeOptions.map((item) => (
                <Select.Option key={item.value} title={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <TransferTree
          intl={intl}
          data={treeData}
          targetData={targetData}
          keys={targetKeys}
          onChange={handleChange}
          reload={reload}
          cloudType={cloudType}
          agentType={agentType}
          projectFilter={projectFilter}
          allProjects={allProjects}
        />
        <OperationButtons onNext={handleNext} onPrev={handlePrev} onCancel={onCancel} style={{ marginTop: 15 }} />
      </Spin>
    </div>
  );
};

export default Step3;
