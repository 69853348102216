import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Spin, message, Alert } from 'antd';

// import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { GlobalParse, parseLocation } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Popover } from '../../../lib/fui/react';

import { appMessages } from '../../../common/app/messages';
import { eventActionMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  incident: Object,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
  globalInfo: Object,
};

class RecommendationsModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      allSuggestActions: [],
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData() {
    const { intl, incident, credentials, currentLocale, projectDisplayMap } = this.props;
    const {
      category,
      projectOwner,
      anomalyLogInstance,
      instanceList,
      startTimestamp,
      endTimestamp,
      patternId,
      type,
      rootCauseTableKey,
      suggestActionList,
    } = incident;

    this.setState({ isLoading: true });

    // parse params to get root cause
    let { projectName } = incident;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
    const event = {
      nid: patternId,
      eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
    };

    const request = [];
    request.push(
      fetchPost(getEndpoint('logCausalInfoServlet', 1), {
        ...credentials,
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime,
        endTime,
        operation: 'rootCauseEvents',
        rootCauseTableKey: JSON.stringify(rootCauseTableKey),
        event: JSON.stringify(event),
      }),
    );
    this.props.updateLastActionInfo();
    Promise.all(request)
      .then((results) => {
        const data = results[0] || {};
        const data1 = results[1] || {};

        const logRootCauseEvents = get(data, ['logRootCauseEvents'], []);
        const metricRootCauseEvents = get(data, ['metricRootCauseEvents'], []);
        const { allSuggestActions: suggestActions1 } = GlobalParse.parseRootCauseInfo({
          operation: 'rootCauseEvents',
          chains: [...(logRootCauseEvents || []), ...(metricRootCauseEvents || [])],
          credentials,
          projectDisplayMap,
        });

        const logPredictedEvents = get(data1, ['logPredictedEvents'], []);
        const metricPredictedEvents = get(data1, ['metricPredictedEvents'], []);
        const { allSuggestActions: suggestActions2 } = GlobalParse.parseRootCauseInfo({
          operation: 'predictedEvents',
          chains: [...(logPredictedEvents || []), ...(metricPredictedEvents || [])],
          credentials,
          projectDisplayMap,
        });

        const allSuggestActions = GlobalParse.parseSuggestActions({
          currentLocale,
          rootCauseSuggestActions: [...suggestActions1, ...suggestActions2],
          suggestActionListFromTimeLine: suggestActionList || [],
        });

        this.setState({ isLoading: false, allSuggestActions });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isLoading: false, allSuggestActions: [] });
      });
  }

  @autobind
  renderSuggestActions(rowData, index) {
    const { currentLocale, location, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};
    const { action, actionMap, componentName, instanceName } = rowData;
    const isEnglish = currentLocale === 'en';
    const actionString =
      isObject(actionMap) && !R.isEmpty(actionMap) ? (isEnglish ? actionMap.English : actionMap.Chinese) : action;

    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName);
    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {actionString}
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          <Popover content={componentName} mouseEnterDelay={0.3} placement="top">
            <div className="hidden-line-with-ellipsis">{componentName}</div>
          </Popover>
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          <Popover content={instanceStr} mouseEnterDelay={0.3} placement="top">
            <div className="hidden-line-with-ellipsis">{instanceStr}</div>
          </Popover>
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { isLoading, allSuggestActions } = this.state;

    return (
      <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
        {allSuggestActions.length === 0 && (
          <div style={{ marginBottom: 16 }}>
            <Alert
              message=""
              description={intl.formatMessage(logMessages.noRecommendationsFound)}
              type="info"
              showIcon
            />
          </div>
        )}
        {allSuggestActions.length > 0 && (
          <div className="event-list flex-grow flex-min-height flex-col" style={{ marginBottom: 16 }}>
            <div className="event-list-header" style={{ height: 40, width: '100%' }}>
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(eventActionMessages.suggestedAction)}
              </div>
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(eventActionMessages.componentName)}
              </div>
              <div className="header-column" style={{ width: 120, flex: 1 }}>
                {intl.formatMessage(eventActionMessages.instanceName)}
              </div>
            </div>
            <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
              {R.addIndex(R.map)((rowData, index) => this.renderSuggestActions(rowData, index), allSuggestActions)}
            </div>
          </div>
        )}
      </Spin>
    );
  }
}

const RecommendationsModal = injectIntl(RecommendationsModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, projectDisplayMap, globalInfo } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      currentLocale,
      projectDisplayMap,
      userInfo,
      credentials,
      globalInfo,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(RecommendationsModal);
