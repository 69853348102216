/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/**
 * Build the url string.
 */
const parseUrl = (url: String) => {
  let pathname = '';
  let search = '';
  if (url.indexOf('?') !== -1) {
    [pathname, search] = url.split('?');
    search = `?${search}`;
  } else {
    pathname = url;
  }
  return {
    pathname,
    search,
  };
};

export default parseUrl;
