/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import { DataFrame } from 'dataframe-js';

export default DataFrame;
