import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { get, isArray } from 'lodash';
import { Alert, Button, Card, Divider, Empty, Spin, notification } from 'antd';
import Icon, {
  ApartmentOutlined,
  CloseCircleOutlined,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  RightSquareOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import {
  CellRenderers,
  Defaults,
  EventRenderers,
  GlobalRenderers,
  LogRenderers,
  getPatternNameIcon,
  parseLocation,
  sleep,
} from '../../../common/utils';
import { parseTimelines } from '../../../common/apis/parsers';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Container, Popover, Tooltip } from '../../../lib/fui/react';

import RootCauseRenderSearchBar from './RootCauseRenderSearchBar';

import {
  buildMergeData,
  customizeRestore,
  dateHeight,
  filterEventListTechnology,
  getChartOption,
  getChartTimeMap,
  handleCategoryClick,
  handleChartFinish,
  handleChartRestore,
  handleChartZoom,
  handleClickDateRow,
  handleComposite,
  impactedRender,
  listHeaderHeight,
  onSystemChartClick,
  renderCategory,
  rendererExpand,
  rowMinHeight,
  setChartRef,
  sortFunction,
  statusRenderer,
  tableViewList,
  tidyEventList,
  timeRenderer,
  traceRenderer,
  getEventInAnomalyTimelines,
  recurrentIncidentsViewList,
  getJumpInfo,
  positionEvent,
  LLMTitleMap,
} from '../utils/rootCauseTimeLine';
import { EChart } from '../../share';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { BarChartIcon, RecurrentIncidentsIcon, RobotIcon } from '../../../lib/fui/icons';
import EmbedIndividualIncidentsModal from './EmbedIndividualIncidentsModal';

import RootCausesPopover from './RootCausesPopover';
import { logMessages } from '../../../common/log/messages';
import RecommendationsGPT from './RecommendationsGPT';

type Props = {
  intl: Object,
  location: Object,
  activeKey: String,
  selectedZone: String,
  forceRefreshTime: Number,
  userInfo: Object,
  jwtToken: String,
  systemId: String,
  startTimeObj: Object,
  endTimeObj: Object,
  environmentName: String,
  systemInfo: Object,
  tabName: String,
  currentTheme: String,
  handleChangeRCAModal: Function,
  handleJumpBySelf: Function,
  clearJumpBySelf: Function,
  eventCategory: String,
  eventPatternType: String,
  eventPatternId: String,
  eventProjectName: String,
  eventInstanceName: String,
  eventTimestamp: String,
};

const operationMap = {
  incident: 'Incident',
  metric: 'Metric',
  deployment: 'Deployment',
  trace: 'Trace',
  logAlert: 'logAlert',
  logAnomaly: 'logAnomaly',
};

class EmbedRootcausetimelineCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { tabName } = props;

    this.listHeaderHeight = listHeaderHeight;
    this.rowMinHeight = rowMinHeight;
    this.dateHeight = dateHeight;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      fixedHeight: false,
      minHeight: this.dateHeight,
    });

    this.state = {
      isLoadingParserData: false,
      errMessage: undefined,

      events: [],
      filterList: [],
      eventList: [],

      rootCauseInfo: {},
      actionComposite: null,
      showIndividualIncidentsModal: false,
      individualIncident: null,
      chartOption: {},
      isResetDateZoom: true,
      zoomStartTime: undefined,
      zoomEndTime: undefined,

      jumpIncident: undefined,
      sortBy: null,
      sortDirection: null,
      allExpand: tabName === 'incident',
      dateAllExpand: true,
      showFilterPanel: false,
      resetPatternFilter: +new Date(),
      isAllChecked: false,
      showRecommendationsGPT: false,
      showGPTType: 'incident',
      activeRecommendation: null,

      // filter
      projectFilter: undefined,
      componentFilter: undefined,
      instanceFilter: undefined,
      logicPodID: undefined,
      filterContainer: undefined,
      metricFilter: undefined,
      typeFilter: undefined,
      patternIdFilter: undefined,
      timeFilterStart: undefined,
      timeFilterEnd: undefined,
      keywordSearch: undefined,
      showEnableRCA: false,
      rootCauseFilter: null,
      incidentStatusFilter: undefined,

      // count
      filterEventsTotal: 0,
      likelyRootCauseCount: 0,
      noRootCauseCount: 0,
      fixedIncidentCount: 0,
      triggeredCount: 0,
      importantCount: 0,
      notificationCount: 0,
      notValidSrcCount: 0,
      compositeAnomalyCount: 0,
      validSrcCount: 0,
      ignoredSrcCount: 0,
      recurrentIncidentCount: 0,
    };

    this.chartTimeMap = {};
    this.systemChartRef = {};
    this.sourceRootCausesDataMap = {};

    this.projectListOptions = [];
    this.componentListOptions = [];
    this.instanceListOptions = [];
    this.podListOptions = [];
    this.containerListOptions = [];
    this.anomalyMetricOptions = [];
    this.patternIdFilterOptions = [];
    this.ignoredPatternIdFilterOptions = [];
    this.incidentStatusCountMap = [];

    this.fatherEvent = null;
    this.childEvent = null;
    this.individualRootFatherEvent = null;
    this.individualRootChildEvent = null;
  }

  componentDidMount() {
    if (this.props.forceRefreshTime) this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.forceRefreshTime !== this.props.forceRefreshTime ||
      nextProps.selectedZone !== this.props.selectedZone
    ) {
      this.fatherEvent = null;
      this.childEvent = null;
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { environmentName, tabName, jwtToken, systemId, userInfo, selectedZone } = props || {};
    const { startTimeObj, endTimeObj, systemInfo } = props || {};
    this.setState({ isLoadingParserData: true, errMessage: undefined });

    const request = [];
    const startTimestamp = startTimeObj.clone().startOf('days').valueOf();
    const endTimestamp = endTimeObj.clone().endOf('days').valueOf();
    const startTimestamps = [];
    R.forEach((ts) => {
      const timestamp = ts * 86400000;
      startTimestamps.push(timestamp);
    }, R.range(startTimestamp / 86400000, endTimestamp / 86400000));
    R.forEach((startTimestamp) => {
      request.push(
        fetchGet(getEndpoint('rootcausetimelinesJWT', 2), {
          environmentName: environmentName || 'All',
          operation: operationMap[tabName],
          jwt: jwtToken,
          startTime: startTimestamp,
          systemName: systemId,
          customerName: userInfo?.userName || 'user',
          zoneName: selectedZone === '__all__' ? `all_zone_${systemId}` : selectedZone,
          ...(operationMap[tabName] === 'Incident' ? { includePastOccurrence: true } : {}),
        }),
      );
    }, startTimestamps);

    Promise.all(request)
      .then((results) => {
        const { success, message: msg } = results || {};
        if (success || success === undefined) {
          const timelinesMap = {};
          R.addIndex(R.forEach)((result, idx) => {
            const timeline = isArray(result) && result.length > 0 ? result[0] : {};
            const { projectDetailsList: projectNameSet, projectDisplayMap = {} } = systemInfo || {};
            parseTimelines({
              timeline,
              timelinesMap,
              projectNameSet,
              projectDisplayMap,
              notInclude: false,
              systemId,
            });
          }, results);

          // build timelines
          const systemTimelinesMap = {};
          R.forEachObjIndexed((valList, id) => {
            const newValList = R.filter((item) => !item.notInclude, valList);
            R.forEach((item) => {
              const { systemTimeline } = item;
              const anomalyTimelines = systemTimeline.anomalyTimelines || [];
              const compositeAnomalyTimeLines = systemTimeline.compositeAnomalyTimeLines || [];

              if (!R.has(id, systemTimelinesMap)) {
                systemTimelinesMap[id] = { ...systemTimeline, anomalyTimelines, compositeAnomalyTimeLines };
              } else {
                systemTimelinesMap[id] = {
                  ...systemTimeline,
                  anomalyTimelines: [...systemTimelinesMap[id].anomalyTimelines, ...anomalyTimelines],
                  compositeAnomalyTimeLines: [
                    ...systemTimelinesMap[id].compositeAnomalyTimeLines,
                    ...compositeAnomalyTimeLines,
                  ],
                };
              }
            }, newValList);
          }, timelinesMap);
          const rootCauseInfo = R.map(
            (timeline) => ({ ...timeline, level: 'system' }),
            R.values(systemTimelinesMap),
          )[0];

          this.setState({ errMessage: undefined, rootCauseInfo }, () => {
            this.parseData(props);
          });
        } else {
          this.setState({ isLoadingParserData: false, errMessage: msg });
        }
      })
      .catch((err) => {
        this.setState({ isLoadingParserData: false, errMessage: err.meesage || String(err) });
      });
  }

  @autobind
  handleCompositeAnomalyTimelines(eventList, props) {
    const { systemInfo, tabName } = props;
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    eventList = R.filter((event) => event.timeLineType !== 'future', eventList);

    eventList = handleComposite(eventList, [], instanceComponentMap, tabName, () => {
      return {};
    });
    return eventList;
  }

  @autobind
  parseData(props) {
    const { startTimeObj, endTimeObj, systemInfo, userInfo } = props || {};
    const { eventPatternId, eventProjectName, eventTimestamp, clearJumpBySelf } = props || {};
    const { rootCauseInfo, sortBy, sortDirection } = this.state;

    const startTime = startTimeObj.format(Defaults.DateFormat);
    const endTime = endTimeObj.format(Defaults.DateFormat);

    getChartTimeMap(this.chartTimeMap, startTime, endTime);

    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    const anomalyEventList = get(rootCauseInfo, ['anomalyTimelines'], []);
    const compositeEventList = get(rootCauseInfo, ['compositeAnomalyTimeLines'], []);

    // 因为默认是IF Prod system,里面是有Kubernetes项目
    // 所以默认是true,如果需要动态数据判断,那么需要请求loadProjectsMetaDataInfo API,获取project的cloudType
    const hasKubernetes = true;
    const {
      eventList: events,
      hasTableData,
      incidentStatusCountMap,
      projectListOptions,
      componentListOptions,
      instanceListOptions,
      podListOptions,
      containerListOptions,
      anomalyMetricOptions,
      patternIdFilterOptions,
      ignoredPatternIdFilterOptions,
      filterEventsTotal,
      likelyRootCauseCount,
      noRootCauseCount,
      fixedIncidentCount,
      triggeredCount,
      importantCount,
      notificationCount,
      notValidSrcCount,
      compositeAnomalyCount,
      validSrcCount,
      ignoredSrcCount,
      recurrentIncidentCount,
    } = tidyEventList(
      true,
      this,
      anomalyEventList,
      compositeEventList,
      instanceComponentMap,
      userInfo,
      () => {
        return {};
      },
      hasKubernetes,
    );
    let eventList = events;

    this.projectListOptions = projectListOptions;

    this.componentListOptions = componentListOptions;

    this.instanceListOptions = instanceListOptions;
    this.podListOptions = podListOptions;
    this.containerListOptions = containerListOptions;

    this.anomalyMetricOptions = anomalyMetricOptions;

    this.patternIdFilterOptions = patternIdFilterOptions;

    this.ignoredPatternIdFilterOptions = ignoredPatternIdFilterOptions;

    this.incidentStatusCountMap = incidentStatusCountMap;

    eventList = sortFunction(eventList, null, null);
    R.addIndex(R.forEach)((item, index) => {
      item.sortNum = index + 1;
    }, eventList || []);

    const actionComposite = this.getActiveIncident(eventList);
    const filterList = this.filterData(eventList);
    const chartOption = this.handleChartOption(filterList);
    const mergedList = buildMergeData(filterList, sortBy, sortDirection, this);

    const { jumpFatherIncident, jumpIncident, composite } = getJumpInfo({
      props,
      mergedList,
      eventProjectName,
      eventPatternId,
      eventTimestamp,
      isJWT: true,
    });

    this.setState(
      {
        isLoadingParserData: false,
        events: eventList,
        filterList,
        eventList: mergedList,
        actionComposite,
        hasTableData,
        jumpIncident,

        filterEventsTotal,
        likelyRootCauseCount,
        noRootCauseCount,
        fixedIncidentCount,
        triggeredCount,
        importantCount,
        notificationCount,
        notValidSrcCount,
        compositeAnomalyCount,
        validSrcCount,
        ignoredSrcCount,
        recurrentIncidentCount,

        chartOption,
        isResetDateZoom: true,
        zoomStartTime: undefined,
        zoomEndTime: undefined,
      },
      () => {
        if (eventProjectName && eventPatternId && eventTimestamp && !composite) {
          clearJumpBySelf();
        }

        if (jumpFatherIncident && jumpIncident) {
          this.fatherEvent = jumpFatherIncident;
          this.childEvent = jumpIncident;
          positionEvent({ composite, self: this });
        } else {
          this.fatherEvent = this.individualRootFatherEvent || this.fatherEvent;
          this.childEvent = this.individualRootChildEvent || this.childEvent;
          positionEvent({ self: this });
        }
      },
    );
  }

  @autobind
  getActiveIncident(eventList) {
    const { showIndividualIncidentsModal, actionComposite } = this.state;
    const findEvent = R.find((event) => event.id === actionComposite?.id, eventList || []);

    if (showIndividualIncidentsModal && actionComposite && findEvent) {
      return findEvent;
    }

    return actionComposite;
  }

  @autobind
  filterData(eventList) {
    let filterList = eventList || [];

    filterList = R.map((event) => {
      if (event.isCompositeAnomaly) {
        return { ...event, anomalyTimelines: filterEventListTechnology(this.state, event.anomalyTimelines) };
      }
      return event;
    }, filterList);

    filterList = filterEventListTechnology(this.state, filterList);

    this.cellMeasureCache.clearAll();
    if (this.refList) this.refList.forceUpdateGrid();
    this.forceUpdate();

    return filterList;
  }

  @autobind
  handleChartOption(eventList) {
    const { startTimeObj, endTimeObj } = this.props || {};
    const startTime = startTimeObj.format(Defaults.DateFormat);
    const endTime = endTimeObj.format(Defaults.DateFormat);
    const { allDayAnomalyList = [], filterTimestamps = [] } = this.chartTimeMap[`${startTime}-${endTime}`] || {};
    return getChartOption(this, eventList, allDayAnomalyList, filterTimestamps, {});
  }

  @autobind
  onChangeFilter(value, type) {
    this.setState({ [type]: value, isLoadingParserData: true }, async () => {
      await sleep(100);
      const { sortBy, sortDirection, events } = this.state;
      const filterList = this.filterData(events);
      const chartOption = this.handleChartOption(filterList);
      const mergedList = buildMergeData(filterList, sortBy, sortDirection, this);
      this.setState(
        {
          filterList,
          eventList: mergedList,
          isLoadingParserData: false,
          chartOption,
          isResetDateZoom: true,
          zoomStartTime: undefined,
          zoomEndTime: undefined,
        },
        async () => {
          if (this.refList) await this.refList.scrollToPosition(1);
          if (this.refList) this.refList.scrollToPosition(1);
        },
      );
    });
  }

  @autobind
  renderTopBar() {
    const { systemId } = this.props;
    const { zoomStartTime, zoomEndTime } = this.state;
    return (
      <div className="flex-col" style={{ paddingBottom: 8, paddingLeft: 8 }}>
        <div className="flex-row flex-space-between flex-center-align flex-wrap">
          <div className="flex-row flex-center-align">
            {zoomStartTime && zoomEndTime && (
              <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                <div style={{ marginRight: 8 }}>{`${moment
                  .utc(zoomStartTime)
                  .format(Defaults.ShortDateTimeFormat)} ~ ${moment
                  .utc(zoomEndTime - 10 * 60 * 1000)
                  .format(Defaults.ShortDateTimeFormat)}`}</div>
                <div className="font-14 clickable" onClick={() => customizeRestore(this, systemId)}>
                  <CloseCircleOutlined />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  fatherInstanceRenderer(rowData, rootEventIdx, dateIdx, callback) {
    const { activeKey, intl, currentTheme } = this.props;
    const { containerName, patternId, patternName, lastChildren } = rowData;
    const { componentName, projectDisplayName, projectName } = rowData;

    const { category: chCategory, rawData: chRawData, isCompositeAnomaly } = lastChildren;
    const { anomalyWords: chAnomalyWords, outlierValue: chOutlierValue } = lastChildren;
    const rootCauseDetailsArr = get(lastChildren, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    let rawDataJson;
    if (chCategory !== 'metric') {
      try {
        rawDataJson = JSON.parse(chRawData);
      } catch (error) {
        // console.debug(error)
      }
    }

    const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
    const patternIdAndName = Defaults.PatternIdNameStr({ patternName, patternId }, {}).patternNameStr;
    const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });
    return (
      <div className="max-width flex-row flex-center-align">
        {activeKey === 'incident' && (
          <Popover title={null} content={intl.formatMessage(DashboardMessages.detectedIncident)} mouseEnterDelay={0.3}>
            {patternIcon}
          </Popover>
        )}
        <Popover
          title={null}
          content={
            <div style={{ width: 480, maxHeight: 350, overflow: 'overlay', wordBreak: 'break-all', paddingRight: 10 }}>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80, flexShrink: 0 }}>
                  {intl.formatMessage(appFieldsMessages.project)}:
                </div>
                {projectDisplayName || projectName}
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80, flexShrink: 0 }}>
                  {intl.formatMessage(appFieldsMessages.component)}:
                </div>
                {componentName}
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.pattern)}:
                </div>
                {`[${patternId}]${String(patternId || '') !== patternIdAndName ? ` ${patternIdAndName}` : ''}`}
              </div>
              {containerName && (
                <div className="flex-row">
                  <div className="light-label bold" style={{ width: 80 }}>
                    {intl.formatMessage(appFieldsMessages.container)}:
                  </div>
                  {containerName}
                </div>
              )}
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 110, flexShrink: 0 }}>
                  {intl.formatMessage(eventMessages.shortDescription)}:
                </div>
                {chCategory === 'metric' && (
                  <div>
                    {R.addIndex(R.map)(
                      (event, index) =>
                        EventRenderers.RenderMetricAnomalySummary({ intl, event, index, hideAvg: isCompositeAnomaly }),
                      rootCauseDetailsArr,
                    )}
                  </div>
                )}
                {chCategory === 'log' && (
                  <>
                    {chAnomalyWords && chAnomalyWords.length > 0 && (
                      <LogRenderers.RenderAnomalyWords
                        style={{ wordBreak: 'break-all', marginBottom: 12 }}
                        anomalyWordList={chAnomalyWords}
                      />
                    )}
                    {chOutlierValue && !R.isEmpty(chOutlierValue) && (
                      <LogRenderers.RenderOutlierValue
                        style={{ wordBreak: 'break-all', marginBottom: 12 }}
                        outlierValue={chOutlierValue}
                      />
                    )}
                    <div style={{ wordWrap: 'break-word', width: 350 }}>
                      {!rawDataJson && (
                        <div style={{ whiteSpace: 'break-spaces' }}>
                          {R.join(
                            '\n',
                            R.filter((x) => Boolean(x), (chRawData || '').split('\n')),
                          )}
                        </div>
                      )}
                      {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                    </div>
                  </>
                )}
              </div>
            </div>
          }
          mouseEnterDelay={0.3}
          placement="right"
        >
          <div className="flex-grow hidden-line-with-ellipsis">
            <div className="hidden-line-with-ellipsis">{`[${patternId}]`}</div>
            {String(patternId || '') !== patternIdAndName && (
              <div className="hidden-line-with-ellipsis">{patternIdAndName}</div>
            )}
          </div>
        </Popover>
      </div>
    );
  }

  @autobind
  impactInstanceRenderer(rowData, rootEvent) {
    const { intl, userInfo } = this.props;
    const { componentName, hasMetricProject, reporterRecordMap, isCompositeAnomaly } = rowData;
    let { containerInfo, instanceListStr } = rowData;
    if (isCompositeAnomaly) {
      return (
        <Button
          size="small"
          onClick={() => {
            this.setState({ showIndividualIncidentsModal: true, actionComposite: rowData });
          }}
        >
          {intl.formatMessage(eventMessages.viewIndividualIncidents)}
        </Button>
      );
    }
    if (!instanceListStr) {
      return <div />;
    }

    if (!containerInfo && R.includes('_', instanceListStr)) {
      containerInfo = {
        containerName: R.split('_', instanceListStr)[0],
        instanceName: R.split('_', instanceListStr)[1],
      };
      instanceListStr = R.split('_', instanceListStr)[1];
    }

    let reporterRecordSet = [
      ...get(reporterRecordMap, 'ignore', []),
      ...get(reporterRecordMap, 'unIgnore', []),
      ...get(reporterRecordMap, 'importance', []),
      ...get(reporterRecordMap, 'unImportance', []),
    ];
    reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))])(reporterRecordSet);
    const width = 110;

    return (
      <div className="max-width flex-row flex-center-align">
        {/* <Tooltip title="Metric anomalies" mouseEnterDelay={0.3} placement="top">
          <Button
            size="small"
            disabled={!hasMetricProject}
            icon={<LineChartOutlined style={{ fontSize: 16 }} />}
            style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)', marginRight: 4 }}
          />
        </Tooltip> */}
        <div className="flex-grow overflow-hidden">
          <div className="flex-row">
            <Popover
              content={
                <div className="flex-col" style={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                      {intl.formatMessage(eventMessages.componentName)}:&nbsp;
                    </div>
                    {componentName}
                  </div>
                  {containerInfo && (
                    <div className="flex-row">
                      <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                        {intl.formatMessage(eventMessages.containerName)}:&nbsp;
                      </div>
                      {containerInfo.containerName}
                    </div>
                  )}
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                      {intl.formatMessage(appFieldsMessages.logicPodID)}
                      :&nbsp;
                    </div>
                    {instanceListStr}
                  </div>
                  {/* {reporterRecordSet.length > 0 && <Divider style={{ margin: '5px 0' }} />}
                  <div className="flex-col overflow-y-auto">
                    {R.addIndex(R.map)((item, idx) => {
                      const { timestamp, reporterName, action } = item;
                      const customerTimestamp =
                        moment.utc(timestamp).valueOf() + (userInfo?.timezoneOffset || 0) * 60000;
                      return (
                        <div key={idx} className="flex-row flex-center-align">
                          <div className="light-label bold" style={{ marginRight: 5 }}>
                            {intl.formatMessage(appFieldsMessages.time)}:
                          </div>
                          <div style={{ marginRight: 5 }}>
                            {moment.utc(customerTimestamp).format(Defaults.ShortTimeOnlyFormat)}
                          </div>
                          <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                            {intl.formatMessage(eventActionMessages.reporter)}:
                          </div>
                          <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                          <span>{reporterName}</span>
                          <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                            {intl.formatMessage(eventActionMessages.action)}:
                          </div>
                          <div>{action}</div>
                        </div>
                      );
                    }, reporterRecordSet)}
                  </div> */}
                </div>
              }
              mouseEnterDelay={0.3}
              placement="right"
              align={{ offset: [12, 0] }}
            >
              <div className="overflow-hidden">
                <div className="hidden-line-with-ellipsis">{containerInfo ? containerInfo.containerName : ''}</div>
              </div>
            </Popover>
          </div>
          <Popover
            content={
              <div className="flex-col" style={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}>
                <div className="flex-row">
                  <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                    {intl.formatMessage(eventMessages.componentName)}:&nbsp;
                  </div>
                  {componentName}
                </div>
                {containerInfo && (
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                      {intl.formatMessage(eventMessages.containerName)}:&nbsp;
                    </div>
                    {containerInfo.containerName}
                  </div>
                )}
                <div className="flex-row">
                  <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                    {intl.formatMessage(appFieldsMessages.logicPodID)}
                    :&nbsp;
                  </div>
                  {instanceListStr}
                </div>
                {/* {reporterRecordSet.length > 0 && <Divider style={{ margin: '5px 0' }} />}
                <div className="flex-col overflow-y-auto">
                  {R.addIndex(R.map)((item, idx) => {
                    const { timestamp, reporterName, action } = item;
                    const customerTimestamp = moment.utc(timestamp).valueOf() + (userInfo?.timezoneOffset || 0) * 60000;
                    return (
                      <div key={idx} className="flex-row flex-center-align">
                        <div className="light-label bold" style={{ marginRight: 5 }}>
                          {intl.formatMessage(appFieldsMessages.time)}:
                        </div>
                        <div style={{ marginRight: 5 }}>
                          {moment.utc(customerTimestamp).format(Defaults.ShortTimeOnlyFormat)}
                        </div>
                        <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                          {intl.formatMessage(eventActionMessages.reporter)}:
                        </div>
                        <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                        <span>{reporterName}</span>
                        <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                          {intl.formatMessage(eventActionMessages.action)}:
                        </div>
                        <div>{action}</div>
                      </div>
                    );
                  }, reporterRecordSet)}
                </div> */}
              </div>
            }
            mouseEnterDelay={0.3}
            placement="right"
            align={{ offset: [12, 0] }}
          >
            <div className="overflow-hidden">
              <div className="hidden-line-with-ellipsis">{instanceListStr}</div>
            </div>
          </Popover>
        </div>
      </div>
    );
  }

  @autobind
  shortDescriptionInfo(rowData) {
    const { intl, currentTheme } = this.props;
    const { category, rawData, anomalyWords, outlierValue, frequencyStr, anomalyFeatureStr } = rowData;
    const { count, isCompositeAnomaly, generateFromGenericRule, predictionSourceInfoList, projectName } = rowData;

    const summarySettings = [];

    const rootCauseDetailsArr = get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
      if (rawDataJson) {
        R.forEach((s) => {
          const v = get(rawDataJson, s.replaceAll('->', '.'));
          if (v) summarySettings.push(s);
        }, this.summarySettingsMap[projectName] || []);
      }
    } catch (error) {
      // console.debug(error)
    }

    let content = (
      <>
        {category === 'metric' && (
          <div>
            {R.addIndex(R.map)(
              (event, index) =>
                EventRenderers.RenderMetricAnomalySummary({ intl, event, index, hideAvg: isCompositeAnomaly }),
              rootCauseDetailsArr,
            )}
          </div>
        )}
        {category === 'log' && (
          <>
            {count && count > 1 && (
              <div className="flex-row flex-center-align" style={{ color: 'var(--blue)' }}>
                <span style={{ marginRight: 4 }}>{intl.formatMessage(appFieldsMessages.count)}:</span>
                <span style={{ wordBreak: 'break-all' }}>{count}</span>
              </div>
            )}
            {anomalyWords && anomalyWords.length > 0 && (
              <LogRenderers.RenderAnomalyWords
                style={{ wordBreak: 'break-all', marginBottom: 12 }}
                anomalyWordList={anomalyWords}
              />
            )}
            {outlierValue && !R.isEmpty(outlierValue) && (
              <LogRenderers.RenderOutlierValue
                style={{ wordBreak: 'break-all', marginBottom: 12 }}
                outlierValue={outlierValue}
              />
            )}
            <div style={{ wordWrap: 'break-word' }}>
              {!rawDataJson && !R.isEmpty(frequencyStr) && <div style={{ color: 'var(--blue)' }}>{frequencyStr}</div>}
              {!rawDataJson && anomalyFeatureStr && (
                <div className="max-width flex-col" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
                  {R.addIndex(R.map)((item, index) => {
                    const [label, values] = item;
                    const content = R.addIndex(R.map)(
                      ({ value }, i) => (
                        <span style={{ margin: '0 4px' }} key={`featureStr${index}${i}`}>
                          {value}
                          {values.length - 1 === i ? '' : ','}
                        </span>
                      ),
                      values,
                    );
                    return (
                      <div className="flex-row" key={`${index}+${label}`}>
                        <div style={{ whiteSpace: 'nowrap' }}>{label} :</div>
                        <div className="flex-row flex-wrap">{content}</div>
                      </div>
                    );
                  }, anomalyFeatureStr)}
                </div>
              )}
              {!rawDataJson && (
                <div style={{ whiteSpace: 'break-spaces' }}>
                  {R.join(
                    '\n',
                    R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                  )}
                </div>
              )}
              {rawDataJson && (
                <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                  <LogRenderers.RenderLogContent
                    intl={intl}
                    rawData={rawData}
                    rawDataJson={rawDataJson}
                    owner={rowData}
                    summarySettings={summarySettings}
                    enableExpansion={false}
                    currentTheme={currentTheme}
                    frequencyStr={frequencyStr}
                    anomalyFeatureStr={anomalyFeatureStr}
                    clearStyle
                    noExpand
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    );

    if (generateFromGenericRule) {
      content = (
        <div className="flex-row flex-wrap">
          {category === 'log' && (
            <>
              {count && count > 1 && (
                <div className="flex-row flex-center-align" style={{ color: 'var(--blue)' }}>
                  <span style={{ marginRight: 4 }}>{intl.formatMessage(appFieldsMessages.count)}:</span>
                  <span style={{ wordBreak: 'break-all' }}>{count}</span>
                </div>
              )}
              {anomalyWords && anomalyWords.length > 0 && (
                <LogRenderers.RenderAnomalyWords
                  style={{ wordBreak: 'break-all', marginBottom: 12 }}
                  anomalyWordList={anomalyWords}
                />
              )}
              {outlierValue && !R.isEmpty(outlierValue) && (
                <LogRenderers.RenderOutlierValue
                  style={{ wordBreak: 'break-all', marginBottom: 12 }}
                  outlierValue={outlierValue}
                />
              )}
              {!R.isEmpty(frequencyStr) && (
                <div style={{ color: 'var(--blue)', wordBreak: 'break-all' }}>{frequencyStr}</div>
              )}
              {anomalyFeatureStr && (
                <div className="max-width flex-col" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
                  {R.addIndex(R.map)((item, index) => {
                    const [label, values] = item;
                    const content = R.addIndex(R.map)(
                      ({ value }, i) => (
                        <span style={{ margin: '0 4px' }} key={`featureStr${index}${i}`}>
                          {value}
                          {values.length - 1 === i ? '' : ','}
                        </span>
                      ),
                      values,
                    );
                    return (
                      <div className="flex-row" key={`${index}+${label}`}>
                        <div style={{ whiteSpace: 'nowrap' }}>{label} :</div>
                        <div className="flex-row flex-wrap">{content}</div>
                      </div>
                    );
                  }, anomalyFeatureStr)}
                </div>
              )}
            </>
          )}
          {R.addIndex(R.map)((event, index) => {
            const { sourceDetail } = event || {};
            const { type, content } = sourceDetail || {};
            const needAnd = index !== predictionSourceInfoList.length - 1;
            let rawDataJson;
            try {
              rawDataJson = JSON.parse(content);
            } catch (error) {
              // console.debug(error)
            }
            return (
              <div className={`flex-row ${rawDataJson ? 'full-width' : ''}`}>
                {type === 'Metric' &&
                  EventRenderers.RenderMetricAnomalySummary({
                    intl,
                    event: sourceDetail || {},
                    index,
                    hideAvg: isCompositeAnomaly,
                    needAnd,
                  })}
                {type !== 'Metric' && (
                  <>
                    {!rawDataJson && (
                      <div style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
                        {R.join(
                          '\n',
                          R.filter((x) => Boolean(x), (content || '').split('\n')),
                        )}
                        {needAnd && <span style={{ margin: '0px 4px' }}>and</span>}
                      </div>
                    )}
                    {rawDataJson && (
                      <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align flex-wrap">
                        <LogRenderers.RenderLogContent
                          intl={intl}
                          rawData={content}
                          rawDataJson={rawDataJson}
                          owner={sourceDetail}
                          summarySettings={summarySettings}
                          enableExpansion={false}
                          currentTheme={currentTheme}
                          clearStyle
                          noExpand
                        />
                        {needAnd && <span style={{ margin: '0px 4px' }}>and</span>}
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          }, predictionSourceInfoList || [])}
        </div>
      );
    }

    return (
      <Popover
        placement="right"
        content={
          <div className="overflow-y-auto overflow-x-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
            {content}
          </div>
        }
        trigger={['click', 'hover']}
      >
        <div className="flex-row flex-center-align" style={{ minHeight: 20 }}>
          <InfoCircleOutlined className="primary-color" style={{ marginLeft: 2 }} />
        </div>
      </Popover>
    );
  }

  @autobind
  rendererContent(rowData, flag = true) {
    const { intl, currentTheme } = this.props;
    const { category, rawData, frequencyStr, anomalyFeatureStr } = rowData;
    const { count, projectCouldType, isCompositeAnomaly } = rowData;
    const { generateFromGenericRule, predictionSourceInfoList, projectName } = rowData;

    const summarySettings = [];

    const rootCauseDetailsArr = get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    let content;
    let rawDataJson;
    if (category === 'metric') {
      const summaryList = R.map((event) => {
        return EventRenderers.BuildMetricAnomalySummary({ event, hideAvg: isCompositeAnomaly });
      }, rootCauseDetailsArr);
      content = R.join('\n', summaryList);
    } else {
      try {
        rawDataJson = JSON.parse(rawData);
        if (rawDataJson) {
          R.forEach((s) => {
            const v = get(rawDataJson, s.replaceAll('->', '.'));
            if (v) summarySettings.push(s);
          }, this.summarySettingsMap[projectName] || []);
        }
      } catch (error) {
        // console.debug(error)
      }
    }

    let compositeSourceContent;
    if (generateFromGenericRule) {
      const summaryList = R.map((event) => {
        const { sourceDetail } = event || {};
        const { type, content } = sourceDetail || {};
        if (type === 'Metric') {
          return EventRenderers.BuildMetricAnomalySummary({
            event: sourceDetail || {},
            hideAvg: isCompositeAnomaly,
          });
        } else {
          return content;
        }
      }, predictionSourceInfoList || []);
      compositeSourceContent = R.join(' and ', summaryList);
    }

    return (
      <div className="max-width flex-row hover-display flex-center-align" style={{ height: '100%' }}>
        {/* {projectCouldType !== 'LogToMetric' && category === 'log' && (
          <Tooltip title="Log anomalies" mouseEnterDelay={0.3} placement="top">
            <Button
              size="small"
              disabled={category !== 'log'}
              icon={<Icon component={BarChartIcon} style={{ fontSize: 20, color: 'var(--icon-color)' }} />}
              style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)', marginRight: 2, flexShrink: 0 }}
            />
          </Tooltip>
        )}
        {projectCouldType === 'LogToMetric' && (
          <Tooltip title={intl.formatMessage(eventMessages.logToMetricContext)} mouseEnterDelay={0.3} placement="top">
            <Button
              size="small"
              disabled={false}
              icon={<UnorderedListOutlined style={{ fontSize: 16 }} />}
              style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)', marginRight: 2, flexShrink: 0 }}
            />
          </Tooltip>
        )} */}
        <div
          className={`${
            flag ? 'hidden-line-with-ellipsis' : 'hidden-line-with-ellipsis-multiline'
          } flex-col flex-center-justify`}
        >
          {generateFromGenericRule && <div className="hidden-line-with-ellipsis">{compositeSourceContent}</div>}
          {!generateFromGenericRule && (
            <>
              {category === 'metric' ? (
                <div className="hidden-line-with-ellipsis">{content}</div>
              ) : (
                <>
                  {count && count > 1 && false && (
                    <div className="flex-row flex-center-align" style={{ color: 'var(--blue)' }}>
                      <span style={{ marginRight: 4 }}>{intl.formatMessage(appFieldsMessages.count)}:</span>
                      <span className="hidden-line-with-ellipsis">{count}</span>
                    </div>
                  )}
                  {!R.isEmpty(frequencyStr) && !rawDataJson && false && (
                    <div style={{ color: 'var(--blue)' }} className="hidden-line-with-ellipsis">
                      {frequencyStr}
                    </div>
                  )}
                  {!rawDataJson && anomalyFeatureStr && false && (
                    <div style={{ color: 'var(--blue)' }} className="hidden-line-with-ellipsis">
                      {R.addIndex(R.map)((item, index) => {
                        const [label] = item;
                        const values = R.addIndex(R.map)(
                          (v, i) => (
                            <span style={{ margin: '0 4px' }}>
                              {v.value}
                              {item[1].length - 1 === i ? '' : ','}
                            </span>
                          ),
                          item[1],
                        );
                        return (
                          <span key={label + index}>
                            {label}:{values}
                          </span>
                        );
                      }, anomalyFeatureStr)}
                    </div>
                  )}
                  {!rawDataJson && <div className="hidden-line-with-ellipsis">{rawData}</div>}
                  {rawDataJson && (
                    <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                      <LogRenderers.RenderLogContent
                        intl={intl}
                        rawData={rawData}
                        rawDataJson={rawDataJson}
                        owner={rowData}
                        summarySettings={summarySettings || []}
                        enableExpansion={false}
                        currentTheme={currentTheme}
                        clearStyle
                        infoRender={this.shortDescriptionInfo(rowData)}
                        takeNum={2}
                        inLine
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {(!rawDataJson || (summarySettings.length === 0 && rawDataJson) || generateFromGenericRule) &&
          this.shortDescriptionInfo(rowData)}
      </div>
    );
  }

  @autobind
  handleRCClick({ event, needRC, needPT, handleCallBack }) {
    this.props.handleChangeRCAModal({
      activeEvent: { ...event, summarySettingsMap: {} },
      needRC,
      needPT,
      ...(handleCallBack ? { handleCallBack } : {}),
    });
  }

  @autobind
  rootCauseChainRender({ rowData, onlyCloseModal }) {
    const { intl, userInfo, systemId, jwtToken, selectedZone, activeKey } = this.props;
    const { hasRootCause, isCompositeAnomaly } = rowData;
    const event = getEventInAnomalyTimelines(rowData);
    return (
      <div className="flex-row full-width flex-center-justify flex-center-align">
        <Popover
          title={hasRootCause && !isCompositeAnomaly ? intl.formatMessage(eventMessages.likelyRootCauses) : null}
          content={
            hasRootCause && !isCompositeAnomaly ? (
              <RootCausesPopover
                intl={intl}
                incident={event}
                credentials={userInfo}
                sourceRootCausesDataMap={this.sourceRootCausesDataMap}
                handleChangeMap={(dataMap) => {
                  this.sourceRootCausesDataMap = dataMap;
                }}
                needRC
                isJWT
                systemId={systemId}
                jwtToken={jwtToken}
                zoneName={selectedZone === '__all__' ? `all_zone_${systemId}` : selectedZone}
              />
            ) : hasRootCause && isCompositeAnomaly ? (
              intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
            ) : (
              intl.formatMessage(eventMessages.likelyRootCauses)
            )
          }
          mouseEnterDelay={0.3}
          placement="left"
        >
          <Button
            size="small"
            disabled={!hasRootCause}
            icon={
              <SearchOutlined style={{ fontSize: 16, ...(!hasRootCause ? {} : { color: 'var(--primary-color)' }) }} />
            }
            style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}
            onClick={() => {
              if (!isCompositeAnomaly) {
                if (onlyCloseModal) {
                  this.setState({ showIndividualIncidentsModal: false }, () => {
                    this.handleRCClick({
                      event,
                      needRC: true,
                      handleCallBack: (activeEvent) => {
                        this.setState({ showIndividualIncidentsModal: true, individualIncident: activeEvent });
                      },
                    });
                  });
                } else {
                  this.handleRCClick({ event, needRC: true });
                }
              }
            }}
          />
        </Popover>
        <Popover
          mouseEnterDelay={0.3}
          placement="left"
          content={
            isCompositeAnomaly
              ? intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
              : intl.formatMessage(logMessages[LLMTitleMap[activeKey]])
          }
        >
          <Button
            size="small"
            icon={<RobotIcon style={{ fontSize: 20, color: 'var(--primary-color)' }} />}
            style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}
            onClick={() => {
              if (!isCompositeAnomaly)
                this.setState({ showRecommendationsGPT: true, activeRecommendation: rowData, showGPTType: activeKey });
            }}
          />
        </Popover>
      </div>
    );
  }

  @autobind
  rendererIncidentStatus(rowData) {
    const { isCompositeAnomaly, incidentStatus } = rowData;
    const colorMap = { 'in progress': 'orange', closed: 'green', created: '' };
    const textMap = { 'in progress': 'Active', closed: 'Closed', created: 'Created' };
    if (isCompositeAnomaly) {
      return (
        <div>
          {R.map((item) => {
            const text = R.trim(item || '');
            return <div style={{ color: colorMap[text] }}>{textMap[text] || 'Created'}</div>;
          }, R.split(',', incidentStatus || ''))}
        </div>
      );
    }

    return <div style={{ color: colorMap[incidentStatus] }}>{textMap[incidentStatus] || 'Created'}</div>;
  }

  @autobind
  peersListItem(tabName, events, jumpIncident) {
    return ({ key, index: rowIndex, style, parent, columnIndex, ...rest }) => {
      const { eventList } = this.state;
      const rowData = events[rowIndex];
      if (!rowData) return null;
      const { intl, activeKey, currentTheme, systemId } = this.props;
      const { isImportant, hasImportant, id, isRootEvent, hasRootCause, mergeKey, isCompositeAnomaly } = rowData;
      const { isAllCompositeAnomaly, isIgnored, isTimeRoot, startTimestamp, recurringIncidentTimes } = rowData;
      const { hasCompositeRecurringIncident } = rowData;
      const active = jumpIncident && jumpIncident.id === id;

      const dateData = R.find(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(eventList);
      const dateIdx = R.findIndex(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(eventList);
      const rootEvent = R.find(R.propEq('mergeKey', mergeKey))(dateData?.children || []);
      const rootEventIdx = R.findIndex(R.propEq('mergeKey', mergeKey))(dateData?.children || []);

      return (
        <CellMeasurer
          key={key}
          cache={this.cellMeasureCache}
          parent={parent}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
        >
          <div style={{ ...style }}>
            {isTimeRoot && (
              <div
                className="event-list-category-row clickable"
                style={{ height: this.dateHeight - 1, background: currentTheme === 'dark' ? '#4b4a4a' : '#d0d0d0' }}
                onClick={() => handleClickDateRow(dateIdx, rowData, this)}
              >
                <div className="row-column flex-center-justify" style={{ minWidth: 56 }} />
                <div
                  className="row-column font-14 bold"
                  style={{ minWidth: 70 + 180 + 210, maxWidth: 70 + 180 + 210, paddingRight: 16 }}
                >
                  {rowData.dateEnFormat}
                </div>
                <div className="row-column flex-grow" />
                <div className="row-column text-center" style={{ minWidth: 130, maxWidth: 130 }}>
                  <span className="full-width">{rowData.count}</span>
                </div>
                <div className="row-column" style={{ width: 30 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
                <div className="row-column flex-center-justify" style={{ width: 60 }} />
              </div>
            )}
            {!isTimeRoot && isRootEvent && (
              <div
                className="event-list-category-row clickable"
                style={{ height: this.rowMinHeight - 1, backgroundColor: 'var(--item-active-bg2)' }}
                onClick={() => handleCategoryClick({ dateIdx, rootEventIdx, rowData, self: this })}
              >
                <div className="row-column flex-center-justify" style={{ minWidth: 56 }} />
                <div className="row-column" style={{ minWidth: 70, maxWidth: 70, paddingRight: 16 }} />
                <div className="row-column" style={{ minWidth: 180, maxWidth: 180, paddingRight: 16 }}>
                  {this.fatherInstanceRenderer(rowData, rootEventIdx, dateIdx)}
                </div>
                <div className="row-column" style={{ minWidth: 210, maxWidth: 210, paddingRight: 16 }}>
                  {impactedRender(rowData, this)}
                </div>
                <div className="row-column" style={{ flex: 1, minWidth: 140 }} />
                <div className="row-column" style={{ minWidth: 130, maxWidth: 130, paddingRight: 16 }}>
                  {hasRootCause && (
                    <SearchOutlined style={{ fontSize: 16, width: '100%', color: 'var(--primary-color)' }} />
                  )}
                </div>
                <div className="row-column" style={{ minWidth: 90, maxWidth: 90 }} />
                {activeKey === 'trace' && <div className="row-column" style={{ minWidth: 40 }} />}
                {activeKey !== 'incident' && <div className="row-column" style={{ minWidth: 65 }} />}
                <div className="row-column text-center" style={{ minWidth: 70, maxWidth: 70 }} />
                {tabName === 'incident' && <div className="row-column" style={{ minWidth: 80, maxWidth: 80 }} />}
                <div className="row-column text-center" style={{ minWidth: 130, maxWidth: 130 }}>
                  <span className="full-width">{rowData.count}</span>
                </div>
                <div className="row-column" style={{ width: 30 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
                <div className="row-column flex-center-justify" style={{ width: 60 }}>
                  {hasImportant ? (
                    <ExclamationCircleFilled
                      className={`${hasImportant ? 'isImportantColor' : 'hover-color'}`}
                      style={{ color: currentTheme === 'dark' ? '#e6e6e6' : '#cacaca', fontSize: 16 }}
                    />
                  ) : (
                    <div style={{ width: 16 }} />
                  )}
                  {!isAllCompositeAnomaly ? (
                    <Popover
                      title={null}
                      content={intl.formatMessage(
                        isIgnored ? eventMessages.noIgnorePattern : eventMessages.ignorePattern,
                      )}
                      mouseEnterDelay={0.3}
                      placement="left"
                    >
                      {isIgnored ? (
                        <EyeInvisibleOutlined
                          style={{
                            color: currentTheme === 'dark' ? '#e6e6e6' : '#949393',
                            fontSize: 14,
                            marginLeft: 8,
                          }}
                        />
                      ) : (
                        <EyeOutlined
                          style={{
                            color: currentTheme === 'dark' ? '#e6e6e6' : '#949393',
                            fontSize: 14,
                            marginLeft: 8,
                          }}
                        />
                      )}
                    </Popover>
                  ) : (
                    <div style={{ width: 14, marginLeft: 8 }} />
                  )}
                </div>
              </div>
            )}
            {!isTimeRoot && !isRootEvent && (
              <div className="full-width">
                <div className="flex-grow flex-min-width event-list">
                  <div
                    className={`incident-event-list-row${active ? ' active' : ''}`}
                    onClick={() => this.setState({ jumpIncident: rowData })}
                    style={{
                      borderBottom: '1px solid var(--virtualized-table-border-color)',
                      height: this.rowMinHeight - 1,
                    }}
                  >
                    <div className="row-column flex-center-justify" style={{ minWidth: 56 }}>
                      {isCompositeAnomaly && (
                        <Popover
                          placement="right"
                          title={null}
                          content={intl.formatMessage(eventMessages.compositeIncident)}
                          trigger="hover"
                        >
                          <ApartmentOutlined style={{ fontSize: 14, marginRight: 8, color: 'var(--primary-color)' }} />
                        </Popover>
                      )}
                      {(recurringIncidentTimes.length > 0 || hasCompositeRecurringIncident) && (
                        <Popover
                          placement="right"
                          title={isCompositeAnomaly ? null : intl.formatMessage(eventMessages.recurrentIncidents)}
                          content={
                            isCompositeAnomaly
                              ? intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
                              : recurrentIncidentsViewList(rowData, intl, systemId, true, this)
                          }
                          trigger="hover"
                        >
                          <RecurrentIncidentsIcon style={{ fontSize: 17, color: 'var(--primary-color)' }} />
                        </Popover>
                      )}
                    </div>
                    <div className="row-column" style={{ minWidth: 70, maxWidth: 70, paddingRight: 16 }}>
                      {timeRenderer(rowData)}
                    </div>
                    <div className="row-column" style={{ minWidth: 180, maxWidth: 180, paddingRight: 16 }} />
                    <div className="row-column" style={{ minWidth: 210, maxWidth: 210, paddingRight: 16 }}>
                      {this.impactInstanceRenderer(rowData, rootEvent)}
                    </div>
                    <div
                      className="row-column"
                      style={{ flex: 1, paddingRight: 10, minWidth: 140, minHeight: this.rowMinHeight }}
                    >
                      {this.rendererContent(rowData, false)}
                    </div>
                    <div className="row-column" style={{ minWidth: 130, maxWidth: 130, paddingRight: 16 }}>
                      {this.rootCauseChainRender({ rowData })}
                    </div>
                    <div className="row-column" style={{ minWidth: 90, maxWidth: 90 }}>
                      <Popover
                        content={`Duration: ${CellRenderers.humanizeDuration({
                          period: rowData.duration + 1,
                          intl,
                          showSeconds: true,
                          showZero: true,
                          showZeroToOne: true,
                        })}`}
                        mouseEnterDelay={0.3}
                      >
                        {CellRenderers.humanizeDuration({
                          period: rowData.duration + 1,
                          intl,
                          showSeconds: true,
                          showZero: true,
                          shortDisplay: true,
                          showZeroToOne: true,
                        })}
                      </Popover>
                    </div>
                    {activeKey === 'trace' && (
                      <div className="row-column flex-center-justify flex-wrap" style={{ minWidth: 40 }}>
                        {traceRenderer(rowData)}
                      </div>
                    )}
                    {activeKey !== 'incident' && (
                      <div className="row-column flex-center-justify flex-wrap" style={{ minWidth: 65 }}>
                        {renderCategory(rowData, this)}
                      </div>
                    )}
                    <div className="row-column flex-wrap" style={{ minWidth: 70, maxWidth: 70 }}>
                      {statusRenderer(rowData, this)}
                    </div>
                    {tabName === 'incident' && (
                      <>
                        <div className="row-column" style={{ minWidth: 80, maxWidth: 80 }}>
                          {this.rendererIncidentStatus(rowData)}
                        </div>
                      </>
                    )}
                    <div
                      className="row-column"
                      style={{ minWidth: 130 + 30, maxWidth: 130 + 30, justifyContent: 'center' }}
                    />
                    <div
                      className="row-column flex-center-justify"
                      style={{ minWidth: 60, height: 39, position: 'relative' }}
                    >
                      <Popover
                        title={null}
                        content={
                          isCompositeAnomaly
                            ? intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
                            : intl.formatMessage(
                                isImportant ? eventMessages.removeImportantFlag : eventMessages.markAsImportant,
                              )
                        }
                        mouseEnterDelay={0.3}
                        placement="right"
                      >
                        <ExclamationCircleFilled
                          className={`${isImportant ? 'isImportantColor' : 'hover-color'}`}
                          style={{ color: currentTheme === 'dark' ? '#e6e6e6' : '#cacaca', fontSize: 16 }}
                        />
                      </Popover>
                      {tabName === 'incident' ? (
                        <div style={{ marginLeft: 8 }}>
                          <div style={{ width: 16 }} />
                        </div>
                      ) : (
                        <div style={{ marginLeft: 8 }}>
                          <div style={{ width: 14, height: 14 }} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CellMeasurer>
      );
    };
  }

  render() {
    const { intl, location, activeKey, tabName, systemId, jwtToken, selectedZone } = this.props;
    const { errMessage, showFilterPanel, filterEventsTotal, importantCount, notificationCount } = this.state;
    const { likelyRootCauseCount, noRootCauseCount, fixedIncidentCount, triggeredCount, notValidSrcCount } = this.state;
    const { validSrcCount, ignoredSrcCount, compositeAnomalyCount, resetPatternFilter, patternIdFilter } = this.state;
    const { componentFilter, instanceFilter, logicPodID, filterContainer, hasTableData } = this.state;
    const { chartOption, isLoadingParserData, showIndividualIncidentsModal, actionComposite } = this.state;
    const { individualIncident, recurrentIncidentCount } = this.state;

    return (
      <Spin spinning={isLoadingParserData} wrapperClassName="full-width full-height spin-full-height">
        {errMessage && <Alert message="Warning" description={errMessage} type="warning" showIcon />}
        {!errMessage && (
          <div className="full-width full-height flex-row">
            <div
              className="flex-col full-height"
              style={{ width: showFilterPanel ? 270 : 0, padding: '8px 0 0 8px', position: 'relative', flexShrink: 0 }}
            >
              <Tooltip title={showFilterPanel ? 'Close filters' : 'Show filters'} mouseEnterDelay={0.3} placement="top">
                <div
                  className="clickable"
                  style={{ position: 'absolute', right: -17, top: 5, fontSize: 18, color: 'gray', zIndex: 99 }}
                  onClick={() => {
                    this.setState({ showFilterPanel: !showFilterPanel });
                  }}
                >
                  <RightSquareOutlined rotate={showFilterPanel ? 180 : 0} />
                </div>
              </Tooltip>
              <Card
                style={{ width: '100%', height: '100%', display: showFilterPanel ? 'unset' : 'none' }}
                bodyStyle={{ height: '100%', padding: 8, overflowY: 'auto' }}
              >
                <Container className="flex-col flex-min-height flex-min-width full-height">
                  <RootCauseRenderSearchBar
                    intl={intl}
                    location={location}
                    activeKey={activeKey}
                    tabName={tabName}
                    filterEventsTotal={filterEventsTotal}
                    importantCount={importantCount}
                    recurrentIncidentCount={recurrentIncidentCount}
                    likelyRootCauseCount={likelyRootCauseCount}
                    noRootCauseCount={noRootCauseCount}
                    fixedIncidentCount={fixedIncidentCount}
                    triggeredCount={triggeredCount}
                    notificationCount={notificationCount}
                    notValidSrcCount={notValidSrcCount}
                    validSrcCount={validSrcCount}
                    ignoredSrcCount={ignoredSrcCount}
                    compositeAnomalyCount={compositeAnomalyCount}
                    resetPatternFilter={resetPatternFilter}
                    patternIdFilter={patternIdFilter}
                    componentFilter={componentFilter}
                    instanceFilter={instanceFilter}
                    logicPodID={logicPodID}
                    filterContainer={filterContainer}
                    // 因为默认是IF Prod system,里面是有Kubernetes项目
                    // 所以默认是true,如果需要动态数据判断,那么需要请求loadProjectsMetaDataInfo API,获取project的cloudType
                    hasKubernetes
                    patternIdFilterOptions={this.patternIdFilterOptions}
                    ignoredPatternIdFilterOptions={this.ignoredPatternIdFilterOptions}
                    projectListOptions={this.projectListOptions}
                    anomalyMetricOptions={this.anomalyMetricOptions}
                    incidentStatusCountMap={this.incidentStatusCountMap}
                    componentListOptions={this.componentListOptions}
                    instanceListOptions={this.instanceListOptions}
                    podListOptions={this.podListOptions}
                    containerListOptions={this.containerListOptions}
                    onChange={({ type, value }) => {
                      if (type === 'keywordFilter') {
                        this.onChangeFilter(value, 'keywordSearch');
                      } else if (type === 'incidentStatus') {
                        this.onChangeFilter(value, 'incidentStatusFilter');
                      } else {
                        this.onChangeFilter(value, type);
                      }
                    }}
                  />
                </Container>
              </Card>
            </div>
            {!hasTableData && (
              <div className="flex-grow flex-row flex-center-align flex-center-justify content-bg">
                <Empty
                  imageStyle={{ height: 200 }}
                  description={
                    <span className="flex-col">
                      <span style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 8 }}>No results found</span>
                      <span style={{ fontSize: 14 }}>Try adjusting the date range</span>
                    </span>
                  }
                />
              </div>
            )}
            {hasTableData && (
              <div className="full-width full-height flex-col">
                <div style={{ height: 130 }} className="log-entries-echart">
                  <AutoSizer>
                    {({ width, height }) => (
                      <EChart
                        setRef={(chart) => setChartRef(this.systemChartRef, systemId, chart)}
                        width={width}
                        height={height}
                        option={chartOption}
                        renderer="svg"
                        onEvents={{
                          click: onSystemChartClick(this, systemId),
                          datazoom: handleChartZoom(this, systemId),
                          restore: handleChartRestore(this, systemId),
                          rendered: handleChartFinish(this, systemId),
                        }}
                      />
                    )}
                  </AutoSizer>
                </div>
                {this.renderTopBar()}
                {/* 布局在peersListItem */}
                {tableViewList(true, this, intl, this.peersListItem)}
              </div>
            )}
          </div>
        )}

        {showIndividualIncidentsModal && (
          <EmbedIndividualIncidentsModal
            systemId={systemId}
            activeKey={activeKey}
            tabName={tabName}
            incident={actionComposite}
            refresh={() => this.reloadData(this.props)}
            refreshLoading={isLoadingParserData}
            individualIncident={individualIncident}
            fatherInstanceRenderer={this.fatherInstanceRenderer}
            impactInstanceRenderer={this.impactInstanceRenderer}
            rendererContent={this.rendererContent}
            rootCauseChainRender={this.rootCauseChainRender}
            rendererIncidentStatus={this.rendererIncidentStatus}
            onClose={() => {
              this.setState({ showIndividualIncidentsModal: false, actionComposite: null, individualIncident: null });
            }}
            onChangeIndividualIncident={() => this.setState({ individualIncident: null })}
            handleJumpBySelf={this.props.handleJumpBySelf}
            clearJumpBySelf={this.props.clearJumpBySelf}
            eventCategory={this.props.eventCategory}
            eventPatternType={this.props.eventPatternType}
            eventPatternId={this.props.eventPatternId}
            eventProjectName={this.props.eventProjectName}
            eventInstanceName={this.props.eventInstanceName}
            eventTimestamp={this.props.eventTimestamp}
          />
        )}

        {this.state.showRecommendationsGPT && (
          <RecommendationsGPT
            incident={this.state.activeRecommendation}
            incidentType="Incident"
            title={intl.formatMessage(logMessages[LLMTitleMap[this.state.showGPTType]])}
            onCancel={() => this.setState({ showRecommendationsGPT: false, activeRecommendation: null })}
            isJWT
            jwtToken={jwtToken}
            zoneName={selectedZone === '__all__' ? `all_zone_${systemId}` : selectedZone}
          />
        )}
      </Spin>
    );
  }
}

const EmbedRootcausetimeline = injectIntl(EmbedRootcausetimelineCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { dark } = parseLocation(location);
  if (dark) {
    state.app.currentTheme = 'dark';
  } else {
    state.app.currentTheme = 'light';
  }

  const { currentTheme } = state.app;
  return { location, currentTheme };
}, {})(EmbedRootcausetimeline);
