/* @flow */
import qs from 'qs';
import momenttz from 'moment-timezone';
import fetchHandler from './fetchHandler';

/**
 * Use fetch to send put method.
 * The content type of the sending data is application/x-www-from-urlencode.
 * The response json format is:
 * {
 *   success: true|false
 *   data: object,  // Data object if success is true.
 *   error: string // Error messages if success is false.
 * }
 *
 * @param {string} url The url of the api
 * @param {object} data The data to put.
 * @param {boolean} isJsonResponse whether response is in json format.
 */
const fetchPut = (url: string, data: Object, extraHeaders: Object = {}, isJsonResponse: Boolean = true) => {
  // parse time zone
  const zone = momenttz.tz(momenttz.tz.guess());
  const tzOffset = zone.utcOffset() * 60 * 1000;

  // remove CSRF token
  if (data.token) {
    data.token = undefined;
  }
  // remove userName
  if (data.userName) {
    data.userName = undefined;
  }
  // set userName by UserName if it is necessary
  if (data.UserName) {
    data.userName = data.UserName;
    data.UserName = undefined;
  }
  url = url.indexOf('?') >= 0 ? `${url}&tzOffset=${tzOffset}` : `${url}?tzOffset=${tzOffset}`;

  const option = {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      Environment: process.env.NODE_ENV,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      ...extraHeaders,
    },
    body: qs.stringify(data),
  };

  return fetchHandler(fetch(url, option), isJsonResponse, option);
};

export default fetchPut;
