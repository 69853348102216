import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import { isNumber, round, toNumber } from 'lodash';
import { Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';
import { getCacheData, setCacheData } from '../utils';

import DashCellTitle from '../components/DashCellTitle';
import { EChart } from '../../share';

export default function AverageKubernetesMemoryUsage(props: Object) {
  const { startTime, endTime, systemInfo, credentials, systemId, currentTheme } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    chartOption: {},
  });
  const { loading, chartOption } = state;

  const buildChartOption = (dataList, baseColor = '#6abbf7', highColor = '#043e7d') => {
    return {
      backgroundColor: 'transparent',
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        confine: true,
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          const { name, color, data } = params[0];
          const { realValue } = data;
          return ReactDOMServer.renderToStaticMarkup(
            <div>
              {name}
              <br />
              <span
                style={{
                  display: 'inline-block',
                  marginRight: 5,
                  borderRadius: 10,
                  width: 9,
                  height: 9,
                  backgroundColor: color,
                }}
              />
              {`Average usage: ${realValue || 0}%`}
            </div>,
          );
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      grid:
        dataList.length > 0
          ? { top: 10, left: 50, right: 30, bottom: 20 }
          : { top: 30, left: 10, right: 10, bottom: 10 },
      xAxis: {
        type: 'category',
        data: R.map((item) => item.name, dataList),
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: {
        type: 'value',
        axisLine: { show: true },
        axisLabel: {
          fontSize: 10,
          width: '42',
          overflow: 'break',
          formatter: (value, index) => {
            return `${value}%`;
          },
        },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [
        {
          name: 'Average kubernetes memory usage',
          type: 'bar',
          barMaxWidth: '25px',
          data: R.map((item) => {
            return {
              value: R.max(0, item.value),
              realValue: item.value,
              flag: item.flag,
            };
          }, dataList),
          emphasis: {
            itemStyle: {
              color: '#097fd7',
              borderWidth: 0,
            },
          },
          itemStyle: {
            color: (item) => {
              const { data } = item;
              const { flag } = data;
              const highLightColor = flag ? baseColor : highColor;
              return highLightColor;
            },
            borderWidth: 0,
            borderRadius: [2, 2, 0, 0],
          },
        },
      ],
    };
  };

  const parseData = (data, startTimestamp, endTimestamp) => {
    let dataList = [];
    R.forEachObjIndexed((num, time) => {
      dataList.push({
        timestamp: Number(time),
        value: isNumber(num) ? round(num, 2) : num === 'NaN' ? 0 : round(toNumber(num || 0), 2),
        name: moment.utc(Number(time)).format(Defaults.ShortDayFormat),
        flag: time < startTimestamp || time > endTimestamp,
      });
    }, data || {});
    dataList = R.sortWith([R.ascend(R.prop('timestamp'))], dataList);
    dataList = R.find((item) => item.value > 0, dataList || []) ? dataList : [];
    const option = buildChartOption(dataList);
    setState({ chartOption: option });
  };

  const reloadData = async (cancel) => {
    const newTimesTamp = moment.utc(moment.utc(), Defaults.DateFormat).endOf('days').valueOf();
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    const newStartTimestamp = moment.utc(startTimestamp).subtract(7, 'days').valueOf();
    let newEndTimestamp = moment.utc(endTimestamp).add(7, 'days').valueOf();
    if (newTimesTamp < newEndTimestamp) {
      newEndTimestamp = newTimesTamp;
    }
    const params = {
      ...credentials,
      customerName: systemInfo.customerName,
      systemName: systemId,
      startTime: newStartTimestamp,
      endTime: newEndTimestamp,
    };

    const apiData = getCacheData(params);
    if (apiData) {
      parseData(apiData, startTimestamp, endTimestamp);
      return;
    }

    setState({ loading: true });
    fetchGet(getEndpoint('k8smemoryusage'), R.clone(params))
      .then((res) => {
        if (cancel) return;
        const { success, message, data } = res || {};
        if (success || success === undefined) {
          setCacheData(params, data);
          parseData(data, startTimestamp, endTimestamp);
          setState({ loading: false });
        } else {
          parseData({}, startTimestamp, endTimestamp);
          setState({ loading: false });
          console.error(message);
        }
      })
      .catch((e) => {
        if (cancel) return;
        setState({ loading: false });
        parseData({}, startTimestamp, endTimestamp);
        console.error(e.message || String(e));
      });
  };

  useEffect(() => {
    let cancel = false;
    reloadData(cancel);
    return () => {
      cancel = true;
    };
  }, [systemInfo, systemId, currentTheme]);

  const hasOption = ((chartOption?.series || [])[0]?.data || []).length < 1;

  return (
    <Spin
      spinning={loading}
      wrapperClassName="spin-base dash-grid-cell content-bg corner-10 overflow-x-hidden overflow-y-auto"
    >
      <DashCellTitle title="" subTitle="Avg Host Memory Usage" />
      <div style={{ position: 'relative', height: 'calc(100% - 48px)' }}>
        <EChart width="100%" height="100%" option={chartOption} onEvents={{}} theme={currentTheme} />
        {hasOption && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-55%, -30%)',
              color: 'var(--text-color-secondary)',
              width: 160,
            }}
          >
            No data for this time period
          </div>
        )}
      </div>
    </Spin>
  );
}
