import React, { useEffect, useReducer, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import { Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';

import DashCellTitle from '../components/DashCellTitle';
import { EChart } from '../../share';
import { getCacheData, setCacheData } from '../utils';

export default function TotalLogCount({
  credentials,
  location,
  globalInfo,
  userInfo,
  intl,
  currentTheme,
  environmentId,
  startTime,
  endTime,
  systemId,
  systemInfo,
  customerName,
}: Object) {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    healthOption: {},
  });
  const { healthOption } = state;

  const buildChartOption = (dataList, baseColor = '#6abbf7', highColor = '#043e7d') => {
    const option = {
      backgroundColor: 'transparent',
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        confine: true,
        enterable: true,
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          const { name, color, data } = params[0];
          const { realValue, title } = data;
          return ReactDOMServer.renderToStaticMarkup(
            <div style={{ maxWidth: 260, overflowX: 'auto' }}>
              {name}
              <br />
              <span
                style={{
                  display: 'inline-block',
                  marginRight: 5,
                  borderRadius: 10,
                  width: 9,
                  height: 9,
                  backgroundColor: color,
                }}
              />
              {`${title}: ${realValue || 0}`}
            </div>,
          );
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      grid:
        dataList.length > 0
          ? { top: 10, left: 50, right: 30, bottom: 20 }
          : { top: 30, left: 10, right: 10, bottom: 10 },
      xAxis: {
        type: 'category',
        data: R.map((item) => item.name, dataList),
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: {
        type: 'value',
        axisLine: { show: true },
        axisLabel: {
          fontSize: 10,
          width: '42',
          overflow: 'break',
          formatter: (value, index) => {
            let scoreVla = value;
            if (scoreVla >= 1000000) {
              scoreVla = `${Number(Number.parseFloat(scoreVla / 1000000).toFixed(2))}M`;
            }
            if (scoreVla >= 1000) {
              scoreVla = `${Number(Number.parseFloat(scoreVla / 1000).toFixed(2))}K`;
            }
            return scoreVla;
          },
        },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [
        {
          name: 'Total log count(System)',
          type: 'bar',
          barMaxWidth: '25px',
          data: R.map((item) => {
            return {
              value: R.max(0, item.value),
              realValue: item.value,
              flag: item.flag,
              title: item.title,
            };
          }, dataList),
          emphasis: {
            itemStyle: {
              color: '#097fd7',
              borderWidth: 0,
            },
          },
          itemStyle: {
            color: (item) => {
              const { data } = item;
              const { flag } = data;
              const highLightColor = flag ? baseColor : highColor;
              return highLightColor;
            },
            borderWidth: 0,
            borderRadius: [2, 2, 0, 0],
          },
        },
      ],
    };
    setState({ healthOption: option });
  };

  const parseData = (data, startTimestamp, endTimestamp) => {
    let dataList = [];
    R.forEachObjIndexed((val, key) => {
      dataList.push({
        timestamp: key,
        value: val[0]?.logEventCount || 0,
        name: moment.utc(Number(key)).format(Defaults.ShortDayFormat),
        flag: key < startTimestamp || key > endTimestamp,
        title: val[0]?.systemName || systemInfo.name,
      });
    }, data || []);
    dataList = R.find((item) => item.value, dataList || []) ? dataList : [];
    buildChartOption(dataList);
  };
  useEffect(() => {
    let cancel = false;

    if (systemInfo) {
      const newTimesTamp = moment.utc(moment.utc(), Defaults.DateFormat).endOf('days').valueOf();
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      const newStartTimestamp = moment.utc(startTimestamp).subtract(7, 'days').valueOf();
      let newEndTimestamp = moment.utc(endTimestamp).add(7, 'days').valueOf();
      if (newTimesTamp < newEndTimestamp) {
        newEndTimestamp = newTimesTamp;
      }
      const params = {
        ...credentials,
        customerName: systemInfo.customerName,
        startTime: newStartTimestamp,
        endTime: newEndTimestamp,
        systemName: systemId,
        url: 'logoverviewstatus',
      };

      const apiData = getCacheData(params);
      if (apiData) {
        parseData(apiData, startTimestamp, endTimestamp);
        return;
      }

      setIsLoading(true);
      fetchGet(getEndpoint('logoverviewstatus'), R.clone(params))
        .then((data) => {
          if (cancel) return;
          parseData(data, startTimestamp, endTimestamp);
          setCacheData(params, data);
          setIsLoading(false);
        })
        .catch((err) => {
          if (cancel) return;
          setIsLoading(false);
          buildChartOption([]);
          console.error(err);
        });
    } else {
      buildChartOption([]);
    }
    return () => {
      cancel = true;
    };
  }, [systemId, currentTheme]);

  const hasHealthOption = ((healthOption?.series || [])[0]?.data || []).length < 1;
  return (
    <Spin
      spinning={isLoading}
      wrapperClassName="spin-base dash-grid-cell content-bg corner-10 overflow-x-hidden overflow-y-auto"
    >
      <DashCellTitle title="Total log count" subTitle="System" />
      <div style={{ position: 'relative', height: 'calc(100% - 48px)' }}>
        <EChart width="100%" height="100%" option={healthOption} onEvents={{}} theme={currentTheme} />
        {hasHealthOption && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-55%, -30%)',
              color: 'var(--text-color-secondary)',
              width: 160,
            }}
          >
            No data for this time period
          </div>
        )}
      </div>
    </Spin>
  );
}
