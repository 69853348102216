/* @flow */
import qs from 'qs';
import momenttz from 'moment-timezone';
import fetchHandler from './fetchHandler';

/**
 * Use fetch to send DELETE method.
 * The content type of the sending data is application/x-www-from-urlencode.
 * The response json format is:
 * {
 *   success: true|false
 *   data: object,  // Data object if success is true.
 *   error: string // Error messages if success is false.
 * }
 *
 * @param {string} url The url of the api
 * @param {object} query The query string object
 * @param {bool} isJsonResponse Whether the response is in json format.
 */
const fetchDelete = (url: string, query: Object, extraHeaders: Object = {}, isJsonResponse: Boolean = true) => {
  // parse time zone
  const zone = momenttz.tz(momenttz.tz.guess());
  const tzOffset = zone.utcOffset() * 60 * 1000;
  query.tzOffset = tzOffset;

  // Get X-CSRF-Token in localStorage
  const token = window.localStorage.getItem('X-CSRF-Token');

  // remove CSRF token
  if (query.token) {
    query.token = undefined;
  }
  // remove userName
  if (query.userName) {
    query.userName = undefined;
  }
  // set userName by UserName if it is necessary
  if (query.UserName) {
    query.userName = query.UserName;
    query.UserName = undefined;
  }

  const option = {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      Environment: process.env.NODE_ENV,
      Accept: 'application/json',
      'X-CSRF-Token': token,
      ...extraHeaders,
    },
  };

  return fetchHandler(fetch(`${url}?${qs.stringify(query)}`, option), isJsonResponse, option);
};

export default fetchDelete;
