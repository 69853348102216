/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import moment from 'moment';

import { Container } from '../../../lib/fui/react';
import EventListTable from '../../metric/components/EventListTable';

type Props = {
  width: Number,
  height: Number,
  project: Object,
  queryParams: Object,
  queryResult: Object,
};

class AnomalyEvent extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.gridOffset = 40;
    this.appNameMapping = {};
    this.metricUnitMap = {};
    this.filterEventList = [];
    this.maxDuration = null;
    this.convertData(props, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps, {});
    }
  }

  @autobind
  convertData(props, params = {}) {
    const { queryResult } = props;
    const { eventList, appNameMapping, maxDuration, metricUnitMap } = get(queryResult, ['data'], {});
    this.appNameMapping = appNameMapping;
    this.metricUnitMap = metricUnitMap;
    this.filterEventList = eventList;
    this.maxDuration = maxDuration;
  }

  render() {
    const { width, height, queryParams, project } = this.props;
    const { instanceGroup, timeObj, interval, keyword } = queryParams;
    const startTime = timeObj && interval ? timeObj.valueOf() - interval : '';
    const endTime = timeObj && interval ? timeObj.valueOf() + interval : '';
    // get keywords
    let keywords = R.map(
      (word) => R.trim(R.replace(/"/g, '', word)),
      R.split(' ', R.replace(/AND/g, ' ', R.replace(/OR/g, ' ', keyword || ''))),
    );
    keywords = R.filter((word) => Boolean(word), keywords);

    return (
      <Container className="events-view" style={{ padding: 0, backgroundColor: 'white' }}>
        <Container className="toolbar">
          <Container className="section">
            <h4>Anomaly Events</h4>
          </Container>
          <Container className="section float-right" />
        </Container>
        <EventListTable
          width={width}
          height={height - this.gridOffset}
          project={project}
          instanceGroup={instanceGroup}
          startTime={startTime}
          endTime={endTime}
          highlightWord={keywords.length > 0 ? keywords : null}
          filterEventList={this.filterEventList}
          // scrollingToRow={scrollingToRow}
          metricUnitMap={this.metricUnitMap}
          appNameMapping={this.appNameMapping}
          maxDuration={this.maxDuration}
        />
      </Container>
    );
  }
}

export default AnomalyEvent;
