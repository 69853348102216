import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { Pagination, Spin } from 'antd';

import { Controls } from '../../../lib/reactflow/controls';
import {
  ConnectionMode,
  MarkerType,
  Panel,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from '../../../lib/reactflow/core';

import LikelyRootCausesCustomNode from './LikelyRootCausesCustomNode';
import LikelyRootCausesContent from './LikelyRootCausesContent';

const nodeWidth = 200;
const nodeHeight = 105;
const ranksep = 200;
const nodesep = 40;
const gapTime = 5 * 60 * 1000;

const nodeTypes = {
  custom: LikelyRootCausesCustomNode,
};

export default function LikelyRootCausesVisualizer({
  currentTheme,
  incident,
  systemId,
  data: rootCauseEvents,
  environmentId,
  onReload,
  summarySettingsMap = {},
  isPT,
  handleActionsClick,
  initDataFlag,
  handleChangeInitDataFlag,
  anomalyLineChartRedner,
  ownRank,
  maxZoom,
  useScroll,
  isJWT,
}: Object) {
  const [forceUpdateData, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    activeNode: null,
    edgeData: {},
    active: null,
    isLoading: false,
    page: 1,
    total: 0,
    pageSize: 5,
    edgesMap: {},
    selectedEvents: rootCauseEvents[0] ? [rootCauseEvents[0]] : rootCauseEvents,
  });
  const flowRef = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { edgeData, activeNode, isLoading, total, pageSize, page, selectedEvents } = state;
  const incidentData = { ...incident, isRoot: true };

  const incidentNode = {
    id: incidentData.id,
    type: 'custom',
    isIncident: true,
    data: {
      label: incidentData.instanceName,
      environmentId,
      summarySettingsMap,
      incidentData,
      own: incidentData,
      isJWT,
    },
    position: {},
  };

  const onNodeClick = (event, node) => {
    if (node) {
      const { isIncident, isGroup } = node;
      if (!isIncident && !isGroup) {
        setState({ activeNode: node });

        const { edgesMap = {} } = state;
        const findTargetMap = edgesMap[node.id];

        setEdges(
          R.map((e) => {
            const selected = findTargetMap[e.id];
            const darkColor = selected ? '#ccc' : '#555';
            const lightColor = selected ? '#f1d9d6' : '#b1b1b7';
            return {
              ...e,
              selected,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                color: currentTheme === 'dark' ? darkColor : lightColor,
              },
            };
          }, edges),
        );
      }
    }
  };

  const processEvents_old = (events) => {
    const nodesMap = {};
    const edgesMap = {};

    // First, merge the events with the same instanceName and eventTimestamp into one node,
    // Meanwhile, build the edges between the nodes
    R.forEach((e) => {
      const sourceInfoList = e?.sourceInfoList || [];

      let source = null;
      let sourceKey = null;

      for (let i = 0; i < sourceInfoList.length; i += 1) {
        const sourceInfo = sourceInfoList[i];

        const { metricInstanceName, instanceName, eventTimestamp } = sourceInfo;
        const instName = metricInstanceName || instanceName;
        const key = `${instName}__${eventTimestamp}`;

        if (!sourceKey) {
          source = sourceInfo;
          sourceKey = key;
        } else {
          const targetKey = key;
          if (!edgesMap?.[sourceKey]?.[targetKey]) {
            edgesMap[sourceKey] = {
              ...edgesMap[sourceKey],
              [targetKey]: {
                source,
                target: sourceInfo,
              },
            };
          }

          source = sourceInfo;
          sourceKey = key;
        }

        if (!nodesMap[key]) {
          nodesMap[key] = {
            id: key,
            instanceName: instName,
            type: 'custom',
            time: eventTimestamp,
            position: { x: 0, y: 0 },
            style: {},
            draggable: false,
            zIndex: 10,
            data: {
              label: key,
              time: eventTimestamp,
              incident: incidentData,
              environmentId,
              summarySettingsMap,
              own: sourceInfo,
              ownList: [sourceInfo],
              isJWT,
            },
          };
        } else {
          nodesMap[key].data.ownList.push(sourceInfo);
        }
      }

      // Add edges for the incident node
      const incidentKey = incidentNode.id;
      const refNode = isPT ? sourceInfoList[0] : sourceInfoList[sourceInfoList.length - 1];
      if (refNode) {
        const { metricInstanceName, instanceName, eventTimestamp } = refNode;
        const instName = metricInstanceName || instanceName;
        const key = `${instName}__${eventTimestamp}`;

        const sourceKey = isPT ? incidentKey : key;
        const source = isPT ? incidentNode : refNode;
        const targetKey = isPT ? key : incidentKey;
        const target = isPT ? refNode : incidentNode;

        if (!edgesMap?.[sourceKey]?.[targetKey]) {
          edgesMap[sourceKey] = {
            ...edgesMap[sourceKey],
            [targetKey]: { source, target },
          };
        }
      }
    }, events);

    // Next, split the nodes into groups based on the time gap and instance name
    const allEventsNodes = R.sortWith([R.ascend(R.prop('time'))], R.values(nodesMap));

    let allNodeRows = [];
    const instanceGroups = {};

    if (allEventsNodes.length > 0) {
      let startTs = null;
      let ridx = 0;

      R.forEach((n) => {
        const { instanceName, time: eventTimestamp } = n;
        if (!startTs) {
          startTs = eventTimestamp;
        }

        if (eventTimestamp - startTs > gapTime) {
          startTs = eventTimestamp;
          ridx += 1;
        }

        const row = allNodeRows[ridx];
        const gid = `${instanceName}_${ridx}`;
        let newGroup = null;

        if (row) {
          const group = R.find((g) => g.instanceName === instanceName, row?.groups || []);
          if (group) {
            group.endTime = eventTimestamp;
            group.nodes.push(n);
          } else {
            newGroup = { id: gid, startTime: eventTimestamp, endTime: eventTimestamp, nodes: [n], instanceName };
            row.groups.push(newGroup);
          }
          row.totalCount += 1;
        } else {
          newGroup = { id: gid, startTime: eventTimestamp, endTime: eventTimestamp, nodes: [n], instanceName };
          allNodeRows.push({
            totalCount: 1,
            groups: [newGroup],
          });
        }

        if (newGroup) {
          if (instanceGroups[instanceName]) {
            instanceGroups[instanceName].push(newGroup);
          } else {
            instanceGroups[instanceName] = [newGroup];
          }
        }
      }, allEventsNodes);

      // For groups in row by end time
      R.forEach((row) => {
        const { groups = [] } = row;
        row.groups = R.sortWith([R.ascend(R.prop('endTime'))], groups);
      }, allNodeRows);
    }

    // Mark the first node in first row as active
    let firstNode = null;
    if (allNodeRows.length > 0) {
      const { nodes: groupNodes = [] } = allNodeRows[0]?.groups?.[0] || {};
      if (groupNodes.length > 0) {
        firstNode = groupNodes[0];
        firstNode.selected = true;
      }
    }

    // Append the incident node to the first row if it's trailing events, otherwise, append it to the last row
    const incidentRow = {
      totalCount: 1,
      groups: [{ id: incidentNode?.id, isIncident: true, nodes: [incidentNode] }],
    };
    if (isPT) {
      allNodeRows = [incidentRow, ...allNodeRows];
    } else {
      allNodeRows.push(incidentRow);
    }
    const maxNodeCount = R.reduce(
      R.max,
      -Infinity,
      R.map((r) => r.totalCount, allNodeRows),
    );

    // Adjust position and style for the nodes
    const nodes = [];
    const maxWidth = maxNodeCount * (nodeWidth + nodesep) - nodesep;

    if (allNodeRows.length > 0) {
      let ridx = 0;
      R.forEach((row) => {
        const { totalCount: count } = row;
        const offset = (maxNodeCount - count) / 2;
        const y = ridx * ranksep;

        let cidx = 0;
        R.forEach((group) => {
          const { id: groupId, nodes: groupNodes = [], isIncident } = group;
          const padding = ridx === 0 || count > 1 ? 0 : ridx % 2 === 1 ? nodeWidth / 3 : -nodeWidth / 3;

          // Add grouping node
          if (!isIncident) {
            nodes.push({
              id: groupId,
              data: { label: '', group },
              time: '',
              isGroup: true,
              position: { x: 0, y: y - 8 },
              // position: { x: offset * (nodeWidth + nodesep) - maxWidth / 2 + padding, y: y - 8 },
              style: {
                // width: count * (nodeWidth + nodesep) - nodesep,
                width: nodeWidth + nodesep - nodesep,
                height: nodeHeight + 16,
                borderRadius: 8,
                backgroundColor: 'transparent',
                // border:
                //   groupNodes.length > 1 ? '2px dashed var(--virtualized-table-border-color)' : '2px dashed transparent',
                border: '2px dashed transparent',
              },
            });
          }

          R.forEach((n) => {
            // n.position.x = (cidx + offset) * (nodeWidth + nodesep) - maxWidth / 2 + padding;
            n.position.x = 0;
            n.position.y = y;
            n.data.groups = row?.groups || [];
            nodes.push(n);
            cidx += 1;
          }, groupNodes);
        }, row?.groups || []);
        ridx += 1;
      }, allNodeRows);
    }

    // edges
    const instanceTargetLinks = {};
    const instanceEdgesMap = {};

    // Merge lines start from top to bottom
    R.forEach((row) => {
      R.forEach((group) => {
        const { nodes: groupNodes = [] } = group;
        R.forEach((n) => {
          const { id: sourceKey } = n;
          const targetMap = edgesMap[sourceKey] || {};
          R.forEach(
            (targetKey) => {
              const link = targetMap[targetKey];
              let ignored = false;

              const { source, target } = link;
              const { instanceName: sourceInstance } = source;
              const { instanceName: targetInstance } = target;

              // Remove links between the same instance
              if (sourceInstance === targetInstance) {
                ignored = true;
              }

              // Remove links already exist in the first level
              if (!ignored) {
                const groups = instanceGroups[targetInstance] || [];

                let hasLink = false;
                if (instanceTargetLinks?.[sourceKey]?.[targetInstance]) {
                  hasLink = true;
                }

                if (hasLink && groups.length > 1) {
                  let found = false;
                  for (let i = 1; i < groups.length; i += 1) {
                    found = !!R.find((n) => n.id === targetKey, groups[i]?.nodes || []);
                    if (found) break;
                  }
                  ignored = found;
                }
              }

              if (!ignored) {
                if (instanceTargetLinks[sourceKey]) {
                  instanceTargetLinks[sourceKey][targetInstance] = true;
                } else {
                  instanceTargetLinks[sourceKey] = { [targetInstance]: true };
                }

                if (instanceEdgesMap[sourceKey]) {
                  instanceEdgesMap[sourceKey][targetKey] = link;
                } else {
                  instanceEdgesMap[sourceKey] = { [targetKey]: link };
                }
              }
            },
            R.sort((a, b) => a.localeCompare(b), R.keys(targetMap)),
          );
        }, groupNodes);
      }, row.groups || []);
    }, allNodeRows);

    // Remove links with instance as source
    const edges = [];
    const instanceSourceLinks = {};
    R.forEach((row) => {
      R.forEach((group) => {
        const { nodes: groupNodes = [] } = group;
        R.forEach((n) => {
          const { id: sourceKey } = n;
          const targetMap = instanceEdgesMap[sourceKey] || {};
          R.forEachObjIndexed((link, targetKey) => {
            let ignored = false;

            const { source, target } = link;
            const { instanceName: sourceInstance } = source;

            // Remove links already exist in the first level
            const groups = instanceGroups[sourceInstance] || [];

            let hasLink = false;
            if (instanceSourceLinks?.[sourceInstance]?.[targetKey]) {
              hasLink = true;
            }

            if (hasLink && groups.length > 1) {
              let found = false;
              for (let i = groups.length - 1; i >= 0; i -= 1) {
                found = R.find((n) => n.id === sourceKey, groups[i]?.nodes || []);
                if (found) break;
              }
              ignored = found;
            }

            if (!ignored) {
              const edgeId = `${sourceKey}->${targetKey}`;
              if (instanceSourceLinks[sourceInstance]) {
                instanceSourceLinks[sourceInstance][targetKey] = true;
              } else {
                instanceSourceLinks[sourceInstance] = { [targetKey]: true };
              }

              edges.push({
                id: edgeId,
                environmentId,
                systemId,
                summarySettingsMap,
                data: { source, target },
                source: sourceKey,
                target: targetKey,
                style: { strokeWidth: 2.4 },
                markerEnd: { type: MarkerType.ArrowClosed },
                isJWT,
              });
            }
          }, targetMap);
        }, groupNodes);
      }, row.groups || []);
    }, R.reverse(allNodeRows));

    // Add intra links between the same instance
    R.forEachObjIndexed((groups, instName) => {
      if (groups.length > 1) {
        let s = groups[0];
        for (let i = 1; i < groups.length; i += 1) {
          const t = groups[i];
          const edgeId = `${s.id}->${t.id}`;
          edges.push({
            id: edgeId,
            environmentId,
            systemId,
            summarySettingsMap,
            data: { source: s, target: t },
            source: s.id,
            target: t.id,
            style: { strokeWidth: 2.4 },
            markerEnd: { type: MarkerType.ArrowClosed },
            isJWT,
          });
          s = t;
        }
      }
    }, instanceGroups);

    return { nodes, edges, firstNode: firstNode || {} };
  };

  const processEvents = (events) => {
    const nodesMap = {};
    const edgesMap = {};

    // First, merge the events with the same instanceName and eventTimestamp into one node,
    // Meanwhile, build the edges between the nodes
    R.forEach((e) => {
      const sourceInfoList = e?.sourceInfoList || [];

      let source = null;
      let sourceKey = null;

      for (let i = 0; i < sourceInfoList.length; i += 1) {
        const sourceInfo = sourceInfoList[i];

        const { metricInstanceName, instanceName, eventTimestamp } = sourceInfo;
        const instName = metricInstanceName || instanceName;
        const key = `${instName}__${eventTimestamp}`;

        if (!sourceKey) {
          source = sourceInfo;
          sourceKey = key;
        } else {
          const targetKey = key;
          if (!edgesMap?.[sourceKey]?.[targetKey]) {
            edgesMap[sourceKey] = {
              ...edgesMap[sourceKey],
              [targetKey]: {
                source,
                target: sourceInfo,
              },
            };
          }

          source = sourceInfo;
          sourceKey = key;
        }

        if (!nodesMap[key]) {
          nodesMap[key] = {
            id: key,
            instanceName: instName,
            type: 'custom',
            time: eventTimestamp,
            position: { x: 0, y: 0 },
            style: {},
            draggable: false,
            zIndex: 10,
            data: {
              label: key,
              time: eventTimestamp,
              incident: incidentData,
              environmentId,
              summarySettingsMap,
              own: sourceInfo,
              ownList: [sourceInfo],
              isJWT,
            },
          };
        } else {
          nodesMap[key].data.ownList.push(sourceInfo);
        }
      }

      // Add edges for the incident node
      const incidentKey = incidentNode.id;
      const refNode = isPT ? sourceInfoList[0] : sourceInfoList[sourceInfoList.length - 1];
      if (refNode) {
        const { metricInstanceName, instanceName, eventTimestamp } = refNode;
        const instName = metricInstanceName || instanceName;
        const key = `${instName}__${eventTimestamp}`;

        const sourceKey = isPT ? incidentKey : key;
        const source = isPT ? incidentNode : refNode;
        const targetKey = isPT ? key : incidentKey;
        const target = isPT ? refNode : incidentNode;

        if (!edgesMap?.[sourceKey]?.[targetKey]) {
          edgesMap[sourceKey] = {
            ...edgesMap[sourceKey],
            [targetKey]: { source, target },
          };
        }
      }
    }, events);

    // Next, split the nodes into groups based on the time gap and instance name
    const allEventsNodes = R.sortWith([R.ascend(R.prop('time'))], R.values(nodesMap));

    let allNodeRows = [];
    const instanceGroups = {};

    if (allEventsNodes.length > 0) {
      let startTs = null;
      let ridx = 0;

      R.forEach((n) => {
        const { instanceName, time: eventTimestamp } = n;
        if (!startTs) {
          startTs = eventTimestamp;
        }

        if (eventTimestamp - startTs > gapTime) {
          startTs = eventTimestamp;
          ridx += 1;
        }

        const row = allNodeRows[ridx];
        const gid = `${instanceName}_${ridx}`;
        let newGroup = null;

        if (row) {
          const group = R.find((g) => g.instanceName === instanceName, row?.groups || []);
          if (group) {
            group.endTime = eventTimestamp;
            group.nodes.push(n);
          } else {
            newGroup = { id: gid, startTime: eventTimestamp, endTime: eventTimestamp, nodes: [n], instanceName };
            row.groups.push(newGroup);
          }
          row.totalCount += 1;
        } else {
          newGroup = { id: gid, startTime: eventTimestamp, endTime: eventTimestamp, nodes: [n], instanceName };
          allNodeRows.push({
            totalCount: 1,
            groups: [newGroup],
          });
        }

        if (newGroup) {
          if (instanceGroups[instanceName]) {
            instanceGroups[instanceName].push(newGroup);
          } else {
            instanceGroups[instanceName] = [newGroup];
          }
        }
      }, allEventsNodes);

      // For groups in row by end time
      R.forEach((row) => {
        const { groups = [] } = row;
        row.groups = R.sortWith([R.ascend(R.prop('endTime'))], groups);
      }, allNodeRows);
    }

    // Mark the first node in first row as active
    let firstNode = null;
    if (allNodeRows.length > 0) {
      const { nodes: groupNodes = [] } = allNodeRows[0]?.groups?.[0] || {};
      if (groupNodes.length > 0) {
        firstNode = groupNodes[0];
        firstNode.selected = true;
      }
    }

    // Append the incident node to the first row if it's trailing events, otherwise, append it to the last row
    const incidentRow = {
      totalCount: 1,
      groups: [{ id: incidentNode?.id, isIncident: true, nodes: [incidentNode] }],
    };
    if (isPT) {
      allNodeRows = [incidentRow, ...allNodeRows];
    } else {
      allNodeRows.push(incidentRow);
    }

    // Adjust position and style for the nodes
    const nodes = [];
    if (allNodeRows.length > 0) {
      let ridx = 0;
      R.forEach((row) => {
        const y = ridx * ranksep;
        const groupsLength = (row?.groups || []).length;

        if (groupsLength > 1) {
          const padding = 16;
          const width = groupsLength * (nodeWidth + nodesep) - nodesep + padding;
          const offset = width / 2;
          let id = '';
          R.forEach((group) => {
            id += group.id;
          }, row?.groups || []);
          nodes.push({
            id,
            data: { label: null },
            isGroup: true,
            position: { x: 0 - offset + nodeWidth / 2, y },
            style: {
              width,
              height: nodeHeight + padding,
              borderRadius: 8,
              backgroundColor: 'transparent',
              border: '2px dashed var(--virtualized-table-border-color)',
            },
          });

          let cidx = 0;
          R.forEach((group) => {
            const { nodes: groupNodes = [], instanceName } = group;
            groupNodes[0].position.x = cidx * (nodeWidth + nodesep) + padding / 2;
            groupNodes[0].position.y = 0 + padding / 2;
            groupNodes[0].data.groups = R.filter((item) => item.instanceName === instanceName, row?.groups || []);
            groupNodes[0].id = group.id;
            groupNodes[0].parentNode = id;
            nodes.push(groupNodes[0]);
            cidx += 1;
          }, row?.groups || []);
        } else {
          R.forEach((group) => {
            const { nodes: groupNodes = [], instanceName } = group;
            groupNodes[0].position.x = 0;
            groupNodes[0].position.y = y;
            groupNodes[0].data.groups = R.filter((item) => item.instanceName === instanceName, row?.groups || []);
            groupNodes[0].id = group.id;
            nodes.push(groupNodes[0]);
          }, row?.groups || []);
        }
        ridx += 1;
      }, allNodeRows);
    }

    // edges
    const edges = [];
    if (allNodeRows.length > 0) {
      R.addIndex(R.forEach)((row, rowIdx) => {
        const nextRow = allNodeRows[rowIdx + 1];
        if (nextRow) {
          const source = row?.groups || [];
          const target = nextRow?.groups || [];
          let sourceKey = '';
          let targetKey = '';

          R.forEach((group) => {
            sourceKey += group.id;
          }, source);

          R.forEach((group) => {
            targetKey += group.id;
          }, target);

          const edgeId = `${sourceKey}->${targetKey}`;

          edges.push({
            id: edgeId,
            environmentId,
            systemId,
            summarySettingsMap,
            data: { source, target },
            source: sourceKey,
            target: targetKey,
            isJWT,
          });
        }
      }, allNodeRows);
    }

    return { nodes, edges, firstNode };
  };

  useEffect(() => {
    setState({ selectedEvents: rootCauseEvents[0] ? [rootCauseEvents[0]] : rootCauseEvents });
  }, [rootCauseEvents]);

  useEffect(() => {
    if (!selectedEvents || selectedEvents.length < 1) return;

    const { nodes, edges, firstNode } = processEvents(selectedEvents);

    setNodes(nodes);

    const edgesMap = {};
    let idx = 0;
    while (idx !== edges.length) {
      const targetsEdgeMap = {};
      for (let i = idx; i < edges.length; i += 1) {
        const { source, target } = edges[i]?.data || {};
        let sourceKey = '';
        let targetKey = '';
        R.forEach((group) => {
          sourceKey += group.id;
        }, source);
        R.forEach((group) => {
          targetKey += group.id;
        }, target);
        const edgeId = `${sourceKey}->${targetKey}`;
        targetsEdgeMap[edgeId] = true;
      }
      const { source } = edges[idx]?.data || {};
      R.forEach((item) => {
        edgesMap[item.id] = targetsEdgeMap;
      }, source || []);
      idx += 1;
    }

    const findTargetMap = edgesMap[firstNode.id];
    setEdges(
      R.map((e) => {
        const selected = findTargetMap[e.id];
        const darkColor = selected ? '#ccc' : '#555';
        const lightColor = selected ? '#f1d9d6' : '#b1b1b7';
        return {
          ...e,
          selected,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: currentTheme === 'dark' ? darkColor : lightColor,
          },
        };
      }, edges),
    );

    setState({ activeNode: firstNode, edgesMap });
    forceUpdate();
  }, [selectedEvents, page, currentTheme]);

  useEffect(() => {
    if (flowRef.current) {
      setTimeout(() => {
        flowRef.current.fitView();
      }, 200);
    }
  }, [forceUpdateData]);

  const onInit = (reactFlowInstance) => {
    flowRef.current = reactFlowInstance;
  };

  return (
    <div className="flex-row flex-grow overflow-hidden rca-flow-container">
      <div
        id="react-flow-id"
        className="corner-8 rca-flow-container"
        style={{ flex: 3, marginRight: 12, border: '1px solid var(--virtualized-table-border-color)' }}
      >
        <ReactFlow
          fitView
          maxZoom={maxZoom || 1}
          minZoom={0.2}
          nodes={nodes}
          edges={edges}
          onInit={onInit}
          nodeTypes={nodeTypes}
          selectionKeyCode={false}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          proOptions={{ hideAttribution: true }}
          connectionMode={ConnectionMode.Loose}
          defaultMarkerColor="var(--react-flow-edge-path-color)"
          onNodeClick={onNodeClick}
          defaultEdgeOptions={{
            style: { strokeWidth: 2.4 },
            type: 'smoothstep',
            markerEnd: { type: MarkerType.ArrowClosed, color: currentTheme === 'dark' ? '#555' : '#b1b1b7' },
          }}
        >
          <Panel position="bottom-right">
            {false && (
              <Pagination
                size="small"
                current={page}
                pageSize={pageSize}
                total={total}
                onChange={(page) => setState({ page, active: null })}
                showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
              />
            )}
          </Panel>
          <Controls showInteractive={false} position="top-right" />
        </ReactFlow>
      </div>
      <div className="corner-8" style={{ flex: 5, minWidth: 600 }}>
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          {anomalyLineChartRedner ? anomalyLineChartRedner() : ''}
          <LikelyRootCausesContent
            isPT={isPT}
            rootCauseEvents={rootCauseEvents}
            edgeData={edgeData}
            activeNode={activeNode}
            incident={incidentData}
            onReload={onReload}
            handleActionsClick={handleActionsClick}
            handleChange={(data) => {
              setState(data);
            }}
            selectedEvents={selectedEvents}
            selectEvents={(events) => {
              setState({ selectedEvents: events || rootCauseEvents });
            }}
            ownRank={ownRank}
            summarySettingsMap={summarySettingsMap}
            useScroll
            isJWT={isJWT}
          />
        </Spin>
      </div>
    </div>
  );
}
