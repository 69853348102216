/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { LeftOutlined } from '@ant-design/icons';
import { Select, Button, DatePicker, Popover, Empty, message, Spin, notification } from 'antd';

import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import { BaseUrls } from '../app/Constants';
import { Defaults, parseLocation, buildLocation, pickNotNil, getReaderStream, downloadFile } from '../../common/utils';
import { hideAppLoader, createLoadAction, loadProjectInfo } from '../../common/app/actions';
import { ActionTypes } from '../../common/log/actions';
import { Container, Modal } from '../../lib/fui/react';

import { appMessages, appMenusMessages, appFieldsMessages, appButtonsMessages } from '../../common/app/messages';

import ReportSelectModal from '../report/components/ReportSelectModal';
import LogEntries from './components/LogEntries';

type Props = {
  // Common props
  intl: Object,
  location: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  instanceInfoList: Array<Object>,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  hideAppLoader: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  loadProjectInfo: Function,

  // eslint-disable-next-line
  globalInfo: Object,
  userInfo: Object,
};

class LogAnalysisCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;

    this.state = {
      isLoadingProjectInfo: true,
      showReportSelectModal: false,

      startTimeObj: startTime ? moment.utc(startTime, Defaults.DateFormat) : null,
      endTimeObj: endTime ? moment.utc(endTime, Defaults.DateFormat) : null,
    };
    this.instanceContainerOptions = [];
    this.instancePageSize = 10;
    this.refInstanceSelection = React.createRef();
    this.instanceDisplayNameMap = {};
  }

  componentDidMount() {
    const { hideAppLoader } = this.props;
    hideAppLoader();
    if (!this.applyParamsAndRedirect(this.props)) {
      this.reloadData(this.props, true);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    const nextParams = parseLocation(location);
    const params = parseLocation(this.props.location);
    if (!this.applyParamsAndRedirect(nextProps)) {
      if (
        nextParams.hasLog !== params.hasLog ||
        nextParams.hasAlert !== params.hasAlert ||
        nextParams.projectName !== params.projectName
      ) {
        this.reloadData(nextProps);
      } else if (
        nextParams.startTime !== params.startTime ||
        nextParams.endTime !== params.endTime ||
        nextParams.projecTime !== params.projecTime
      ) {
        this.reloadInstanceInfo(nextProps);
      } else if (nextProps.instanceInfoList !== this.props.instanceInfoList) {
        this.parseInstanceInfo(nextProps);
      }
    }
  }

  @autobind
  parseInstanceInfo(nextProps) {
    const { replace, location } = nextProps;
    const nextParams = parseLocation(location);
    const { projectName, instanceName, isJump, ...rest } = nextParams;
    const instanceInfoList = nextProps.instanceInfoList || [];
    this.totalInstanceSort(instanceInfoList);
    const instanceContainerOptions = this.classifyComponent(instanceInfoList);
    // replace instance name in url
    let iname = instanceName;
    // ignore instance name check
    if (!(isJump && instanceName)) {
      if (!instanceName || !R.find((i) => i.id === instanceName, instanceInfoList || [])) {
        iname =
          instanceContainerOptions.length > 0
            ? instanceContainerOptions[0].instanceContainers[0].instanceName
            : undefined;
      }
    }
    // if (!R.find((i) => i.id === instanceName, instanceInfoList || []) && instanceName && isJump) {
    //   notification.warn({
    //     message: 'Notification',
    //     description: `log data do not exist for this ${instanceName} instance.`,
    //     duration: null,
    //   });
    // }
    replace(
      buildLocation(
        location.pathname,
        {},
        { ...rest, isJump, projectName, instanceName: iname, instanceTime: moment.utc().valueOf() },
      ),
    );
  }

  @autobind
  classifyComponent(instanceInfoList) {
    const componentInstanceMap = {};
    let instanceContainerOptions = [];
    R.forEach((item) => {
      if (!R.has(item.appName, componentInstanceMap)) {
        componentInstanceMap[item.appName] = [];
      }
      componentInstanceMap[item.appName].push(item);
    }, instanceInfoList);
    const componentList = R.uniq(R.keys(componentInstanceMap));

    // build instanceContainerOptions
    R.forEachObjIndexed((componentId) => {
      let instanceContainers = componentInstanceMap[componentId] || [];
      instanceContainers = R.sortWith(
        [
          R.descend((item) => {
            return item.total + item.anomalyMap.entryCount;
          }),
        ],
        instanceContainers,
      );
      instanceContainerOptions.push({
        type: 'component',
        id: componentId,
        instanceContainers,
        hasAnomaly: R.reduce(
          R.or,
          false,
          R.map((item) => item.hasAnomaly, instanceContainers),
        ),
        total: R.sum(R.map((item) => item.total + item.anomalyMap.entryCount, instanceContainers)),
        incidentEventCount: R.sum(R.map((item) => item.anomalyMap.incidentEventCount || 0, instanceContainers)),
      });
    }, componentList);
    instanceContainerOptions = R.sortWith(
      [R.descend(R.prop('incidentEventCount')), R.descend(R.prop('total'))],
      instanceContainerOptions,
    );
    return instanceContainerOptions;
  }

  @autobind
  totalInstanceSort(instanceInfoList) {
    let normalInstancesTotal = 0;
    let abnormalInstancesTotal = 0;
    R.forEach((item) => {
      const {
        entryCount,
        coldEventCount,
        hotEventCount,
        incidentEventCount,
        newPatternCount,
        rareEventCount,
        whitelistEventCount,
      } = item?.anomalyMap || {};
      item.total = 0;

      if (
        entryCount &&
        !coldEventCount &&
        !hotEventCount &&
        !incidentEventCount &&
        !newPatternCount &&
        !rareEventCount &&
        !whitelistEventCount
      ) {
        normalInstancesTotal += 1;
      } else if (
        coldEventCount ||
        hotEventCount ||
        incidentEventCount ||
        newPatternCount ||
        rareEventCount ||
        whitelistEventCount
      ) {
        abnormalInstancesTotal += 1;
      }

      item.total =
        item.total +
        (coldEventCount || 0) +
        (hotEventCount || 0) +
        (incidentEventCount || 0) +
        (newPatternCount || 0) +
        (rareEventCount || 0) +
        (whitelistEventCount || 0);
    }, instanceInfoList);
    const sortInstanceInfoList = R.sortWith([R.descend(R.prop('total'))], instanceInfoList);
    return { sortInstanceInfoList, normalInstancesTotal, abnormalInstancesTotal };
  }

  applyParamsAndRedirect(props) {
    // Apply the default params if missing and redirect to new location if params changes.
    // When redirection happens, we should not need to reload the data. The return value
    // indicates whethre rediction happens.
    let { projects } = props;
    const { location, replace, globalInfo } = props;
    const params = parseLocation(location);
    const { hasAlert } = params;
    let { projectName, hasLog, startTime, endTime, activeTab } = params;
    let redirect = false;

    if (!hasLog && !hasAlert) {
      hasLog = 'true';
    }
    if (!projectName) {
      // filter projects
      projects = R.filter(
        (project) =>
          (!hasLog && !hasAlert) ||
          (hasLog === 'true' && (project.isLog || project.isDeployment)) ||
          (hasAlert === 'true' && (project.isAlert || project.isIncident)),
        projects,
      );
      projects = R.filter((project) => project.status !== 'Deleting', projects);
      if (projects.length > 0) {
        projectName = projects[0].projectName || '';
      }
    }

    if (!startTime || !endTime) {
      const timeObj = moment.utc();
      startTime = timeObj.format(Defaults.DateFormat);
      endTime = startTime;
      this.setState({
        startTimeObj: timeObj,
        endTimeObj: timeObj,
      });
    }

    if (!activeTab) {
      activeTab = 'important';
    }

    // Remove the empty params, otherwise it will affect the comparation.
    const newParams = pickNotNil({
      ...params,
      projectName,
      hasLog,
      hasAlert,
      startTime,
      endTime,
      activeTab,
      environmentId: globalInfo[0]?.id || 'All',
    });

    // Compare the new params with the origin params, if changed, redirect to new location.
    if (!R.equals(newParams, params)) {
      redirect = true;
      replace(buildLocation(location.pathname, {}, newParams));
    }

    return redirect;
  }

  @autobind
  reloadData(props, forece) {
    const { replace, location, loadProjectInfo, projects } = props;
    const params = parseLocation(location);
    const { projectName } = params;
    const project = R.find((project) => project.projectName === projectName, projects);

    // reload when start component or project info incomplete
    if (forece || (project && !project.hasAllInfo)) {
      this.setState({ isLoadingProjectInfo: true });
      loadProjectInfo({ projectName }, false, this.callbackHandle);
    } else {
      // reload instance info if no need reload project info
      const params = parseLocation(location);
      replace(buildLocation(location.pathname, {}, { ...params, projecTime: moment.utc().valueOf() }));
    }
  }

  @autobind
  callbackHandle() {
    const { replace, location } = this.props;
    const params = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...params, projecTime: moment.utc().valueOf() }));
  }

  @autobind
  getInstanceDisplayNameData(props, project) {
    const { credentials } = props;
    this.instanceDisplayNameMap = {};
    return fetchGet(getEndpoint('instance-display-name'), {
      ...credentials,
      instanceDisplayNameRequestList: JSON.stringify([
        { projectName: project?.projectShortName, customerName: project?.owner },
      ]),
    })
      .then((d1) => {
        const instanceDisplayNameMap = {};
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, d1 || []);
        this.instanceDisplayNameMap = instanceDisplayNameMap;
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });
  }

  @autobind
  reloadInstanceInfo(props) {
    const { location, createLoadAction, projects, replace } = props;
    const params = parseLocation(location);
    const { projectName, startTime, endTime } = params;
    const project = R.find((project) => project.projectName === projectName, projects);
    if (project && project.hasAllInfo) {
      this.setState({ isLoadingProjectInfo: true });

      this.getInstanceDisplayNameData(props, project);

      createLoadAction(
        ActionTypes.LOAD_LOG_INSTANCEINFO_LIST,
        { projectName, day: endTime, startTime, endTime, pageNo: 0, pageSize: 100 },
        false,
        false,
        this.callbackHandleInstanceInfos,
      );
    } else {
      replace(
        buildLocation(
          location.pathname,
          {},
          { ...params, instanceName: undefined, instanceTime: moment.utc().valueOf() },
        ),
      );
      this.setState({ isLoadingProjectInfo: false });
    }
  }

  @autobind
  callbackHandleInstanceInfos() {
    this.setState({ isLoadingProjectInfo: false });
  }

  @autobind
  handleReturnLogCalendar() {
    const { push, location } = this.props;
    const params = parseLocation(location);
    delete params.instanceName;
    push(buildLocation(BaseUrls.LogCalendar, {}, { ...params, isJump: undefined }));
  }

  @autobind
  handleRefreshClick(params) {
    const { location, replace } = this.props;
    let query = parseLocation(location);
    let needReload = true;

    const { startTimeObj, endTimeObj } = params || {};
    if (startTimeObj && endTimeObj) {
      const startTime = startTimeObj.format(Defaults.DateFormat);
      const endTime = endTimeObj.format(Defaults.DateFormat);
      // update start/end time if changed
      if (startTime !== query.startTime || endTime !== query.endTime) {
        query = { ...query, startTime, endTime };
        needReload = false;
      }
    }

    if (needReload) {
      query = { ...query, forceRefreshTime: moment.utc().valueOf() };
    }

    // force refresh
    replace(buildLocation(location.pathname, {}, query));
  }

  @autobind
  handleExportImportantClick() {
    this.setState({ showReportSelectModal: true });
  }

  @autobind
  onCloseReportSelect(props) {
    const { category, reportType, startTimestamp, endTimestamp } = props || {};
    this.setState(
      {
        showReportSelectModal: false,
      },
      async () => {
        const { intl, location } = this.props;
        const params = parseLocation(location);
        const { projectName } = params;

        if (category === 'allEvents') {
          if (reportType === 'csv') {
            const apiParams = {
              projectName,
              startTime: moment.utc(startTimestamp).startOf('day').valueOf(),
              endTime: moment.utc(endTimestamp).endOf('day').valueOf(),
            };

            // show waiting download modal
            const progressModal = Modal.info({
              title: null,
              footer: null,
              icon: null,
              centered: true,
              // maskClosable: true,
              className: 'hide-confirm-btns',
              okButtonProps: { display: 'none' },
              content: (
                <div className="flex-row flex-center-align flex-center-justify">
                  <Spin tip={intl.formatMessage(appMessages.waitingDownload)} />
                </div>
              ),
            });

            let headers;
            const data = await fetchGet(getEndpoint('logExportAllEvents'), apiParams, {}, '', false)
              .then((response) => {
                headers = response.headers || null;
                const reader = response.body.getReader();
                return getReaderStream(reader);
              })
              .then((stream) => new Response(stream))
              .then((response) => response.blob())
              .catch((err) => {
                console.debug(err);
                message.error(intl.formatMessage(appMessages.apiFaild));
              });
            if (data) {
              let filename = headers && headers.get('Content-Disposition');
              filename = filename && filename.split(';')[1].split('filename=')[1].replace(/"/g, '');
              const type = headers && headers.get('Content-Type');

              downloadFile(data, filename, type);
            }
            progressModal.destroy();
          }
        }
      },
    );
  }

  @autobind
  handleProjectChange(projectName) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, projectName }));
  }

  @autobind
  handleStartDateChange(startTimeObj) {
    this.setState({ startTimeObj });
  }

  @autobind
  handleEndDateChange(endTimeObj) {
    this.setState({ endTimeObj });
  }

  render() {
    const { intl, location, projects, userInfo } = this.props;
    const params = parseLocation(location);
    const { projectName, hasLog, hasAlert, startTime, endTime, instanceName } = params;
    const { isLoadingProjectInfo, startTimeObj, endTimeObj } = this.state;

    let filterProjects = R.filter(
      (project) =>
        (!hasLog && !hasAlert) ||
        (hasLog === 'true' && (project.isLog || project.isDeployment)) ||
        (hasAlert === 'true' && (project.isAlert || project.isIncident)),
      projects,
    );
    filterProjects = R.filter((project) => project.status !== 'Deleting', filterProjects);

    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    const hasErr = startTimeObj > endTimeObj;
    const hasInstance = Boolean(instanceName);

    return (
      <Container fullHeight withGutter className={`flex-col log-live ${isLoadingProjectInfo ? 'loading' : ''}`}>
        <Container breadcrumb className="flex-row">
          <div className="flex-grow flex-row">
            <Button type="link" style={{ padding: 0 }} onClick={this.handleReturnLogCalendar}>
              <LeftOutlined size="small" /> {intl.formatMessage(appMenusMessages.calendar)}
            </Button>
          </div>
          <div className="flex-row flex-center-align">
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.project)}</span>
            <Select
              showSearch
              size="small"
              style={{ width: 150 }}
              filterOption
              optionFilterProp="value"
              value={projectName}
              onChange={this.handleProjectChange}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.projectName} value={item.projectName} title={item.projectName}>
                    {`${item.projectDisplayName}${
                      userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName
                        ? `@${item.owner}`
                        : ''
                    }`}
                  </Select.Option>
                ),
                filterProjects || [],
              )}
            </Select>
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.startDate)}</span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday={false}
              value={startTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleStartDateChange}
            />
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.endDate)}</span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday={false}
              value={endTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleEndDateChange}
            />

            <Popover
              mouseEnterDelay={0.3}
              placement="bottomRight"
              visible={timeChange}
              title={null}
              content={timeChange ? intl.formatMessage(appMessages.clickToReload) : null}
            >
              <Button
                size="small"
                disabled={hasErr}
                onClick={() => this.handleRefreshClick({ startTimeObj, endTimeObj })}
                style={{ marginLeft: 8 }}
              >
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </Popover>
          </div>
        </Container>

        <Container
          className="flex-grow flex-col flex-min-height content-bg corner-10"
          style={{ margin: '0 16px 8px 16px', padding: 8 }}
        >
          {!hasInstance && (
            <div className="full-height full-width flex-row flex-center-align flex-center-justify">
              <Empty />
            </div>
          )}

          {hasInstance && (
            <LogEntries
              handleExportImportantClick={this.handleExportImportantClick}
              instanceDisplayNameMap={this.instanceDisplayNameMap}
            />
          )}
        </Container>

        {this.state.showReportSelectModal && (
          <ReportSelectModal
            categoryOptions={[{ label: 'All Events', value: 'allEvents', hasCSV: true, needDateCSV: true }]}
            startTimestamp={moment.utc(startTime, Defaults.DateFormat).valueOf()}
            endTimestamp={moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf()}
            onClose={this.onCloseReportSelect}
          />
        )}
      </Container>
    );
  }
}

const LogAnalysis = injectIntl(LogAnalysisCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials, userInfo } = state.auth;
    const { projects, loadStatus, globalInfo } = state.app;
    const { instanceInfoList, instanceInfoTotal } = state.log;
    return {
      location,
      credentials,
      loadStatus,
      projects: R.filter((project) => !project.isMetric, projects),
      instanceInfoList,
      instanceInfoTotal,
      globalInfo,
      userInfo,
    };
  },
  {
    push,
    replace,
    hideAppLoader,
    createLoadAction,
    loadProjectInfo,
  },
)(LogAnalysis);
