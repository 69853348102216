/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { updateLastActionInfo } from '../../app/actions';
import { ActionTypes } from '../actions';
import { getLoadStatusActions } from '../../utils';
import { postEventAlert } from '../../apis';
import { appMessages } from '../../app/messages';

const saveEventAlertLogic = createLogic({
  type: [ActionTypes.SAVE_EVENT_ALERT],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    postEventAlert(credentials, params)
      .then(() => {
        dispatch(hideLoading);
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed save alert iussue, ignored', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default saveEventAlertLogic;
