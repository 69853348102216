/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */
import { get } from 'lodash';
import type { CausalState, Action } from '../types';
import { ActionTypes } from './actions';

const initialState: CausalState = {
  errorMessage: null,

  causalGroupListData: {},
  incidentListData: {},
  incidentCausalProperty: {},
  incidentData: {},
  instanceIncidentData: {},
  eventMetricMapData: {},
  dependencyGroupData: {},
};

const reducer = (state: CausalState = initialState, action: Action): CausalState => {
  if (action.type === 'SET_CAUSAL_GROUPLIST_DATA') {
    const { params, data } = action.payload;
    const { causalGroupList, defaultCausalMetricPairingThreshold } = data || {};
    return {
      ...state,
      causalGroupListData: { params, data: causalGroupList || [], defaultCausalMetricPairingThreshold },
    };
  } else if (action.type === ActionTypes.SET_CAUSAL_DEPENDENCY_GRAPH) {
    const { data } = action.payload;
    const { dependencyGroupData } = data || {};
    return {
      ...state,
      dependencyGroupData: dependencyGroupData || get(state, ['dependencyGroupData']),
    };
  } else if (action.type === ActionTypes.SET_CAUSAL_LOG_EVENTS) {
    const { data } = action.payload;
    return {
      ...state,
      causalLogEvents: data || [],
    };
  } else if (action.type === 'SET_CAUSAL_INCIDENTLIST_DATA') {
    const { params, data } = action.payload;
    const { incidentList } = data || {};
    // also reset causal incident data if list changed.
    return {
      ...state,
      incidentListData: { params, data: incidentList || [] },
      incidentData: {},
      incidentCausalProperty: {},
    };
  } else if (action.type === 'SET_CAUSAL_INCIDENT_META_DATA') {
    const { params, data } = action.payload;
    const { incidentMetaData } = data || {};
    return {
      ...state,
      incidentMetaData: { params, data: incidentMetaData },
    };
  } else if (action.type === 'SET_CAUSAL_INCIDENT_DATA') {
    const { params, data } = action.payload;
    const { instanceName, needCausalProperty } = params;
    const { incidentCausalProperty, incident } = data || {};

    if (!instanceName) {
      return {
        ...state,
        incidentData: { params, data: incident || get(state, ['incidentData', 'data']) },
        incidentCausalProperty: incidentCausalProperty || get(state, ['incidentCausalProperty']),
        instanceIncidentData: {},
      };
    }
    return {
      ...state,
      incidentCausalProperty: needCausalProperty ? incidentCausalProperty : get(state, ['incidentCausalProperty']),
      instanceIncidentData: { params, data: incident || get(state, ['instanceIncidentData', 'data']) },
    };
  } else if (action.type === 'RESET_CAUSAL_INCIDENT_DATA') {
    const { isInstance } = action.payload;
    if (isInstance) {
      return { ...state, instanceIncidentData: {} };
    }
    return { ...state, incidentCausalProperty: {}, incidentData: {}, instanceIncidentData: {} };
  } else if (action.type === 'SET_CAUSAL_ERROR_MESSAGE') {
    const { message, params } = action.payload;
    const errorMessage = message ? { message, params } : null;
    return { ...state, errorMessage };
  } else if (action.type === 'SET_EVENT_METRIC_MAP_DATA') {
    const { params, data } = action.payload;
    return {
      ...state,
      eventMetricMapData: { params, data: data || [] },
    };
  } else if (action.type === ActionTypes.SET_CAUSAL_GROUP_ANALYSIS) {
    const { data } = action.payload;
    const { globalSystemCausalGroup } = data || {};
    return { ...state, causalGroupAnalysis: globalSystemCausalGroup };
  }
  return { ...initialState, ...state };
};

export default reducer;
