import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import * as R from 'ramda';
import tinygradient from 'tinygradient';
import { isArray } from 'lodash';
import { Spin, Tag } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { EChart } from '../../share';
import { sleep } from '../../../common/utils';
import { PinLegendIcon } from '../../../lib/fui/icons';

import { eventMessages } from '../../../common/metric/messages';

const titleMap = { prediction: 'Predictions', incident: 'Incidents', anomolie: 'Anomalies' };

const SystemDailyContext = createContext(null);
const TypeItemContentWrapContext = createContext(null);

const reducerLogic = (oldVal, newVal) => ({ ...oldVal, ...newVal });

const initState = { loading: false, projectInfoMap: { prediction: [], incident: [], anomolie: [] }, activeItem: null };

const TitleWrapContent = (props: Object) => {
  const { label } = props;
  return (
    <div className="flex-row flex-space-between clickable" style={{ fontSize: 14 }}>
      <div style={{ fontWeight: 'bold' }}>{titleMap[label]}</div>
    </div>
  );
};

const SystemInfoChart = (props: Object) => {
  const { projectInfoMap } = useContext(SystemDailyContext);
  const { echartRef, optionAll, legendColor } = useContext(TypeItemContentWrapContext);
  const { label } = props;

  const getPieOption = (data, gradientColorsList) => {
    const gradient = tinygradient(gradientColorsList);
    const echartData = R.filter((item) => item.value > 0, data);
    const colorsRgb = gradient.rgb(echartData.length < 2 ? 2 : echartData.length);
    const colors = R.map((x) => {
      return x.toHexString();
    }, colorsRgb);
    legendColor.current = colors;
    const option = {
      backgroundColor: 'transparent',
      color: colors,
      legend: { top: -1000, type: 'scroll', width: 1, height: 1 },
      series: [
        {
          name: titleMap[label],
          type: 'pie',
          cursor: 'auto',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          percentPrecision: 1,
          itemStyle: {
            emphasis: {
              shadowBlur: 4,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            show: true,
            position: 'inner',
            formatter: (params) => {
              const { percent } = params;
              return percent > 5 ? `${percent}%` : '';
            },
            color: 'white',
            fontWeight: 'bold',
            fontSize: 10,
          },
          emphasis: {
            disabled: true,
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          showEmptyCircle: true,
          emptyCircleStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderColor: '#ccc',
          },
          data: echartData,
        },
      ],
    };
    optionAll.current = option;
    return option;
  };

  const option = useMemo(
    () =>
      getPieOption(
        R.map((item) => ({ ...item, value: item.t, name: item.p }), projectInfoMap[label]),
        ['#043e7d', '#6abbf7'],
      ),
    [projectInfoMap[label]],
  );

  return (
    <div style={{ height: '100%', minWidth: '40%', maxWidth: '40%' }}>
      <div style={{ height: '100%' }} className="echarts-cancel-hover">
        <EChart
          setRef={(chart) => {
            echartRef.current = chart;
          }}
          option={option}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
};

const SystemItem = ({ rowData, index, label }: Object) => {
  const { activeItem, projectSetState, changeProjectActive, zoomStartTime, zoomEndTime, activeLineIconItem } =
    useContext(SystemDailyContext);
  const { echartRef, optionAll, legendColor } = useContext(TypeItemContentWrapContext);
  const { p, t, key, projectDisplayName } = rowData;

  const mouseLinkageCharts = ({ item, mouse }) => {
    if (echartRef.current) {
      const detailKey = `${item.p}-${item.t}`;
      const { data } = optionAll?.current?.series[0] || {};
      const dataIndex = R.findIndex((_item) => `${_item?.p}-${_item?.t}` === detailKey, data);
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.dispatchAction({ type: mouse === 'over' ? 'highlight' : 'downplay', seriesIndex: 0, dataIndex });
    }
  };

  const handleClickLegend = (systemName) => {
    if (echartRef.current) {
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.dispatchAction({ type: 'legendToggleSelect', name: systemName });
    }
  };

  const clickJump = () => {
    if (!zoomStartTime && !zoomEndTime) {
      changeProjectActive(key, true);
      projectSetState({ activeItem: key });
    }
  };

  const activeStyle = { color: 'var(--primary-color)', fontSize: 13, fontWeight: 'bold' };

  return (
    <div className="flex-row flex-center-align" style={{ marginBottom: 10 }}>
      {false && R.includes(label, ['prediction', 'incident']) && (
        <div style={{ marginRight: 4 }}>
          <PinLegendIcon style={{ color: label === 'prediction' ? '#ffad66' : '#FF5142', fontSize: 20 }} />
        </div>
      )}

      <div
        className="flex-row flex-center-align flex-space-between flex-grow"
        style={{ ...(activeLineIconItem ? {} : activeItem === key ? activeStyle : {}) }}
      >
        <div className="flex-row flex-center-align clickable">
          <span
            style={{
              display: 'inline-block',
              width: 14,
              height: 10,
              borderRadius: 4,
              flexShrink: 0,
              background: `${t > 0 ? legendColor.current[index] : 'var(--green)'}`,
            }}
            onClick={() => handleClickLegend(p)}
          />
          <span
            className="hidden-line-with-ellipsis-multiline"
            style={{ wordBreak: 'break-word', padding: '0 8px', width: 'fit-content' }}
            // onMouseEnter={(e) => {
            //   e.stopPropagation();
            //   e.preventDefault();
            //   mouseLinkageCharts({ item: rowData, mouse: 'over' });
            // }}
            // onMouseLeave={(e) => {
            //   e.stopPropagation();
            //   e.preventDefault();
            //   mouseLinkageCharts({ item: rowData, mouse: 'out' });
            // }}
            onClick={clickJump}
          >
            {projectDisplayName}
          </span>
        </div>
        <Tag
          size="small"
          style={{
            borderRadius: 100,
            marginLeft: 4,
            fontWeight: 'bold',
            flexShrink: 0,
            cursor: 'default',
            ...(activeLineIconItem ? {} : activeItem === key ? activeStyle : {}),
          }}
        >
          {t}
        </Tag>
      </div>
    </div>
  );
};

const SystemInfoList = (props: Object) => {
  const { intl, projectInfoMap } = useContext(SystemDailyContext);
  const { label } = props;

  let content = (
    <div
      className="flex-grow flex-col flex-center-align flex-center-justify"
      style={{ color: 'var(--text-color-secondary)' }}
    >{`No ${label}s for this time period`}</div>
  );

  if (projectInfoMap[label].length > 0) {
    content = (
      <div style={{ height: 'calc(100% - 30px)', overflowY: 'auto' }}>
        {R.addIndex(R.map)(
          (item, index) => (
            <SystemItem key={item.p} rowData={item} index={index} label={label} />
          ),
          projectInfoMap[label],
        )}
      </div>
    );
  }

  return (
    <div className="flex-col" style={{ height: '100%', minWidth: '60%', maxWidth: '60%', paddingLeft: 14 }}>
      <div style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 10 }}>
        {intl.formatMessage(eventMessages.projectName)}
      </div>
      {content}
    </div>
  );
};

const TypeItemContentWrap = (props: Object) => {
  const { label } = props;
  const echartRef = useRef(null);
  const optionAll = useRef({});
  const legendColor = useRef([]);

  const dataInfoValue = useMemo(
    () => ({ echartRef, optionAll, legendColor }),
    [echartRef.current, optionAll.current, legendColor.current],
  );

  return (
    <TypeItemContentWrapContext.Provider value={dataInfoValue}>
      <div
        className="content-bg flex-col"
        style={{ padding: 20, height: 'inherit', borderRadius: '8px 8px 0 0', paddingBottom: 10 }}
      >
        <TitleWrapContent label={label} />
        <div className="flex-row flex-grow" style={{ marginTop: 14, height: 'calc(100% - 34px)' }}>
          <SystemInfoChart label={label} />
          <SystemInfoList label={label} />
        </div>
      </div>
    </TypeItemContentWrapContext.Provider>
  );
};

export default function GlobalSystemChartView(props: Object) {
  const { systemInfo, changeProjectActive, credentials, projects } = props;
  const [state, setState] = useReducer(reducerLogic, initState);
  const { loading, projectInfoMap } = state;
  let { activeItem } = state;

  const getItemDisplayName = (projectName, list) =>
    R.find((item) => item.projectShortName === projectName, list || [])?.projectDisplayName || projectName;

  const getSystemInfoList = async (cancel) => {
    if (cancel) return;
    const { tabCounter } = systemInfo || {};
    const projectDisplayList = projects;
    activeItem = null;

    setState({ loading: true });
    await sleep(300);
    const { PREDICTED_INCIDENT, DETECTED_INCIDENT, LOG_ANOMALY, METRIC_ANOMALY, TRACE } = tabCounter || {};
    let predictionList = PREDICTED_INCIDENT?.projectLevelCountSet || [];
    let incidentList = DETECTED_INCIDENT?.projectLevelCountSet || [];
    const logAnomaly = LOG_ANOMALY?.projectLevelCountSet || [];
    const metricAnomaly = METRIC_ANOMALY?.projectLevelCountSet || [];
    const traceAnomaly = TRACE?.projectLevelCountSet || [];
    let anomolieList = [...logAnomaly, ...metricAnomaly, ...traceAnomaly];
    predictionList = R.sortWith([R.descend(R.prop('t'))], predictionList);
    incidentList = R.sortWith([R.descend(R.prop('t'))], incidentList);
    anomolieList = R.sortWith([R.descend(R.prop('t'))], anomolieList);
    predictionList = R.map(
      (item) => ({
        ...item,
        key: `prediction-${item.p}`,
        projectDisplayName: getItemDisplayName(item.p, projectDisplayList),
      }),
      predictionList,
    );
    incidentList = R.map(
      (item) => ({
        ...item,
        key: `incident-${item.p}`,
        projectDisplayName: getItemDisplayName(item.p, projectDisplayList),
      }),
      incidentList,
    );
    anomolieList = R.map(
      (item) => ({
        ...item,
        key: `anomolie-${item.p}`,
        projectDisplayName: getItemDisplayName(item.p, projectDisplayList),
      }),
      anomolieList,
    );
    const projectInfoMap = { prediction: predictionList, incident: incidentList, anomolie: anomolieList };
    R.forEachObjIndexed((item) => {
      if (!activeItem && item.length > 0) activeItem = item[0]?.key;
    }, projectInfoMap);
    changeProjectActive(activeItem || 'prediction', false);
    setState({ projectInfoMap, activeItem, loading: false });
  };

  useEffect(() => {
    let cancel = false;
    getSystemInfoList(cancel);
    return () => {
      cancel = true;
    };
  }, [systemInfo]);

  const dataInfoValue = useMemo(
    () => ({ ...props, projectInfoMap, activeItem, projectSetState: setState }),
    [props, projectInfoMap, activeItem],
  );

  return (
    <SystemDailyContext.Provider value={dataInfoValue}>
      <div className="flex-row flex-center-align corner-10" style={{ margin: '0 8px' }}>
        <Spin spinning={loading} wrapperClassName="gv-daily-loading">
          <div
            style={{
              width: '100%',
              height: 200,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridColumnGap: 12,
              overflow: 'hidden',
            }}
          >
            {R.map(
              (item) => (
                <TypeItemContentWrap key={item} label={item} />
              ),
              R.keys(projectInfoMap),
            )}
          </div>
        </Spin>
      </div>
    </SystemDailyContext.Provider>
  );
}
