/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2022
 * *****************************************************************************
 * */

import React, { useState } from 'react';
import * as R from 'ramda';
import { Modal, Form, Select, Input } from 'antd';

import { Regex } from '.';

import { appFieldsMessages, appButtonsMessages } from '../app/messages';
import { authMessages } from '../auth/messages';

const ExportModal = ({ intl, onOk, onClose }: Object) => {
  const [isLoading, setLoading] = useState(false);
  const [exportCategory, setExportCategory] = useState('CSV');
  const [email, setEmail] = useState('');

  const hasErrorEmailAddress = !R.reduce(
    (a, b) => a && b,
    true,
    R.map((email) => Regex.email.test(R.trim(email)), email.split(',')),
  );
  let disabled = !exportCategory;
  if (exportCategory === 'Email') {
    disabled = disabled || !email || hasErrorEmailAddress;
  }
  return (
    <Modal
      title={intl.formatMessage(appButtonsMessages.export)}
      width={540}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={() => onOk({ setLoading, exportCategory, email })}
      okButtonProps={{ disabled, loading: isLoading }}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left">
        <Form.Item label={intl.formatMessage(appFieldsMessages.exportAs)} required>
          <Select
            style={{ width: '100%' }}
            allowClear={false}
            options={[
              { value: 'CSV', label: 'CSV' },
              { value: 'Email', label: 'Email' },
            ]}
            defaultValue={exportCategory}
            onChange={(exportCategory) => setExportCategory(exportCategory)}
          />
        </Form.Item>
        {exportCategory === 'Email' && (
          <Form.Item
            label={intl.formatMessage(appFieldsMessages.email)}
            validateStatus={hasErrorEmailAddress ? 'error' : 'success'}
            help={hasErrorEmailAddress ? intl.formatMessage(authMessages.errorsEmailIncorrect) : undefined}
            required
          >
            <Input.TextArea rows={4} value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export { ExportModal };
