/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */
import * as R from 'ramda';
/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getLogStats } from '../../apis';

const loadLogInstancesStatsLogic = createLogic({
  type: [ActionTypes.LOAD_LOG_INSTANCES_STATS],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);
    const { projectName, day, instanceList } = params;

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    const requests = [];
    R.forEach((instanceName) => {
      requests.push(getLogStats(credentials, { projectName, instanceName, day }));
    }, instanceList);

    Promise.all(requests)
      .then((results) => {
        dispatch(createSetAction(ActionTypes.SET_LOG_INSTANCES_STATS, params, { instancesDetailInfos: results }));
      })
      .then(() => {
        dispatch(hideLoading);
        done();
      });
  },
});

export default loadLogInstancesStatsLogic;
