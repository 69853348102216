import React from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { autobind } from 'core-decorators';
import SelectCore from 'react-select';
import { Portal } from 'react-portal';
import Icon from './Icon';

type Props = {
  className: string,
  autosize: boolean,
  withPortal: boolean,
  clearable: boolean,
  valueLink: Object,
  dark: boolean,
  disableErrorHint: boolean,
  inline: boolean,
  multi: boolean,
  disabled: boolean,
  size: string,
  valueRenderer: Function,
  onChange: Function,
};

type States = {
  blurred: boolean,
};

class SelectCoreWithPortal extends SelectCore {
  renderOuter(options, valueArray, focusedOption) {
    const dimensions = this.wrapper ? this.wrapper.getBoundingClientRect() : null;
    const menu = super.renderMenu(options, valueArray, focusedOption);

    if (!menu || !dimensions) return null;

    const maxHeight = document.body.offsetHeight - (dimensions.top + dimensions.height);
    return (
      <Portal>
        <div
          ref={(ref) => {
            this.menuContainer = ref;
          }}
          className="Select-menu-outer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            ...this.props.menuContainerStyle,
            zIndex: 9999,
            position: 'absolute',
            width: dimensions.width,
            top: dimensions.top + dimensions.height,
            left: dimensions.left,
            maxHeight: Math.min(maxHeight, 140),
            overflow: 'hidden',
          }}
        >
          <div
            ref={(ref) => {
              this.menu = ref;
            }}
            role="listbox"
            tabIndex={-1}
            className="Select-menu"
            id={`${this._instancePrefix}-list`}
            style={{
              ...this.props.menuStyle,
              maxHeight: Math.min(maxHeight, 140),
            }}
            onScroll={this.handleMenuScroll}
            onMouseDown={this.handleMouseDownOnMenu}
          >
            {menu}
          </div>
        </div>
      </Portal>
    );
  }
}

class Select extends React.PureComponent {
  props: Props;

  state: States = {
    blurred: false,
  };

  @autobind
  handleChangeWithValueLink(obj) {
    const { valueLink, multi } = this.props;
    if (!multi && Array.isArray(obj) && obj.length === 0) {
      valueLink.set(null);
    } else if (Array.isArray(obj)) {
      valueLink.set(R.map((o) => o.value, obj));
    } else {
      valueLink.set(obj ? obj.value : null);
    }
  }

  @autobind
  handleBlur() {
    this.setState({ blurred: true });
  }

  @autobind
  handleFocus() {
    this.setState({ blurred: false });
  }

  render() {
    const {
      className,
      inline,
      dark,
      valueLink,
      disableErrorHint,
      autosize,
      clearable,
      multi,
      size,
      disabled,
      valueRenderer,
      withPortal,
      style,
      ...rest
    } = this.props;

    const arrowRenderer = ({ onMouseDown, isOpen }: any) => {
      return isOpen ? (
        <Icon name="angle up" fitted onMouseDown={onMouseDown} />
      ) : (
        <Icon name="angle down" fitted onMouseDown={onMouseDown} />
      );
    };

    const CoreComponent = withPortal ? SelectCoreWithPortal : SelectCore;

    // If we use valuelink, the valuelink contains the value of the options.
    if (valueLink) {
      const { blurred } = this.state;
      const hasError = Boolean(valueLink.error);
      const showErrorHints = blurred && hasError && !disableErrorHint;
      const classes = cx('fui', size, { invalid: showErrorHints, dark, inline }, 'select', className);
      return (
        <div className="fui select-wrapper">
          <CoreComponent
            multi={multi}
            autosize={!!autosize}
            clearable={!!clearable}
            disabled={disabled}
            tabIndex={-1}
            arrowRenderer={arrowRenderer}
            valueRenderer={valueRenderer}
            value={valueLink.value || (multi ? [] : undefined)}
            onChange={this.handleChangeWithValueLink}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            className={classes}
            style={{ ...(style || {}), borderRadius: 6 }}
            {...rest}
          />
          {showErrorHints && <div className="error-text">{valueLink.error}</div>}
        </div>
      );
    }

    const classes = cx('fui', size, { inline, dark }, 'select', className);

    return (
      <CoreComponent
        multi={multi}
        autosize={!!autosize}
        clearable={!!clearable}
        disabled={disabled}
        arrowRenderer={arrowRenderer}
        valueRenderer={valueRenderer}
        className={classes}
        tabIndex={-1}
        style={{ ...(style || {}), borderRadius: 6 }}
        {...rest}
      />
    );
  }
}

const SelectLink = ({ children, ...props }: Object) => {
  return (
    <Link
      {...props}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </Link>
  );
};

Select.Link = SelectLink;

export default Select;
