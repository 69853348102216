/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, TreeSelect, Alert, Form, Checkbox, Divider, Input, Select, Spin, Popover, message } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Container } from '../../../../lib/fui/react';
import { parseJSON, sleep } from '../../../../common/utils';

import { appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

class EndpointSettingNewRelicCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoadingKey: false,
      isLoading: false,
      isUpdateKey: false,
      isSubmitting: false,
      errorMessage: '',

      apiKey: '',

      // log
      accountOptions: [],
      fieldsMap: {},
      fields: [],
      account: null,
      instanceField: null,

      // metric
      hostMap: {},
      allHostKeys: [],
      allMetricMap: {},
      treeDataMetric: [],
      treeDataKeys: [],
      isLoadingMetric: false,
      selectedInstances: [],
      selectedMetrics: [],
    };
    this.apikey = '';
  }

  componentDidMount() {
    this.reloadKey(this.props);
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadKey(nextProps);
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadKey(props) {
    const { credentials, projectName, currentProject } = props;

    this.setState({ isLoadingKey: true });
    const cloudType = get(currentProject, ['cloudType']);
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const { success, apiKey } = data || {};
        if (success === undefined || success) {
          this.apiKey = apiKey;
          this.setState({
            isLoadingKey: false,
            apiKey: apiKey || '',
          });
        } else {
          this.setState({
            isLoadingKey: false,
            apiKey: '',
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        message.error(errMsg || String(err));
        this.setState({
          isLoadingKey: false,
          apiKey: '',
        });
      });
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName, currentProject } = props;
    const isMetric = get(currentProject, ['isMetric']);

    this.setState({ isLoading: true, isUpdateKey: false });
    return fetchGet(getEndpoint('newrelic-project-setting'), {
      ...credentials,
      projectName,
    })
      .then((d) => {
        const {
          success,
          message: errMsg,
          accounts,
          data,

          account,
          instanceField,

          hosts,
          metrics,
        } = d || {};
        if (success === undefined || success) {
          if (isMetric) {
            // parse metric
            this.parseDataMetric(data, hosts, metrics);
          } else {
            // parselog
            this.parseDataLog(accounts, data, account, instanceField);
          }

          this.setState({
            isLoading: false,
            errorMessage: undefined,
          });
        } else {
          this.setState({
            isLoading: false,
            errorMessage: errMsg,
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoading: false,
          errorMessage: errMsg || String(err),
        });
      });
  }

  @autobind
  parseDataMetric(data, hosts, metrics) {
    const hostMap = {};
    const allHostKeys = [];
    const allMetricMap = {};
    R.forEach((item) => {
      const { hostName, hostId, metrics, accountId } = item;
      allHostKeys.push(hostId);

      const allMetricList = [];
      R.forEach((item) => {
        const { name: metricNameNewRelic, values } = item;
        R.forEach((value) => {
          const key = `${metricNameNewRelic}-${value}-${hostId}`;
          allMetricMap[key] = { metricNameNewRelic, valueToFetch: value, accountId, hostId, hostName };
          allMetricList.push({ metricNameNewRelic, valueToFetch: value, key, accountId, hostId, hostName });
        }, values);
      }, parseJSON(metrics) || []);

      // build host map
      hostMap[hostId] = {
        hostName,
        hostId,
        metrics: allMetricList,
      };
    }, data);

    // build treeDataMetric,treeDataKeys by default instance list
    const selectedInstances = R.map((hostId) => String(hostId), parseJSON(hosts) || []);
    const metricValueMap = {};
    R.forEach((hostId) => {
      const { metrics } = hostMap[hostId] || {};
      R.forEach((metric) => {
        if (!R.has(metric.metricNameNewRelic, metricValueMap)) {
          metricValueMap[metric.metricNameNewRelic] = {};
        }
        metricValueMap[metric.metricNameNewRelic][metric.key] = metric.valueToFetch;
      }, metrics || []);
    }, selectedInstances || []);

    // build tree data
    let treeData = [];
    const treeDataKeys = [];
    R.forEachObjIndexed((val, metricNameNewRelic) => {
      const children = [];
      R.forEachObjIndexed((value, key) => {
        treeDataKeys.push(key);
        const parseKey = key.split('-');
        const hostId = parseKey[parseKey.length - 1];
        children.push({
          title: `${value}(${hostMap[hostId].hostName})`,
          value: key,
          key,
        });
      }, val);
      treeData.push({
        title: metricNameNewRelic,
        value: metricNameNewRelic,
        key: metricNameNewRelic,
        children,
      });
    }, metricValueMap);
    treeData = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], treeData);

    const selectedMetrics = R.map((item) => {
      const { metricNameNewRelic, valueToFetch, hostId } = item;
      const key = `${metricNameNewRelic}-${valueToFetch}-${hostId}`;
      return key;
    }, parseJSON(metrics) || []);

    this.setState({
      hostMap,
      allHostKeys,
      allMetricMap,
      treeDataMetric: treeData,
      treeDataKeys,

      selectedInstances,
      selectedMetrics,
    });
  }

  @autobind
  parseDataLog(accounts, data, account, instanceField) {
    const accountOptions = R.map((item) => ({ value: item.id, label: item.name }), accounts || []);

    const fieldsMap = {};
    R.addIndex(R.forEach)((item, idx) => {
      const dataObj = (data || [])[idx] || data[0] || {};
      fieldsMap[item.id] = R.keys(dataObj);
    }, accounts || []);

    // build fields by default account
    const accountInt = parseInt(account, 10);
    const fields = fieldsMap[accountInt] || [];

    this.setState({ accountOptions, fieldsMap, fields, account: accountInt, instanceField });
  }

  @autobind
  handleUpdateKey() {
    const { credentials, projectName } = this.props;

    this.setState({ isUpdateKey: true });
    const { apiKey } = this.state;
    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      apiKey,
    })
      .then((data) => {
        const { message: msg, success } = data;
        if (!success) {
          this.setState({ isUpdateKey: false });
          message.error(String(msg));
          return;
        }
        this.setState({ isUpdateKey: false });
        this.reloadKey(this.props);
        this.reloadData(this.props);
      })
      .catch((err) => {
        message.error(String(err));
        this.setState({ isUpdateKey: false });
      });
  }

  @autobind
  handleSubmit() {
    const { credentials, projectName, currentProject } = this.props;
    const isMetric = get(currentProject, ['isMetric']);

    this.setState({ isSubmitting: true });
    const { allMetricMap, selectedInstances, selectedMetrics } = this.state;
    const { account, instanceField } = this.state;

    // build metrics params
    const hosts = R.map((item) => item, selectedInstances);
    let metrics = R.map((key) => {
      return allMetricMap[key] ? allMetricMap[key] : null;
    }, selectedMetrics);
    metrics = R.filter((item) => Boolean(item), metrics);

    return fetchPost(getEndpoint('newrelic-project-setting'), {
      ...credentials,
      projectName,
      ...(isMetric ? { hosts: JSON.stringify(hosts), metrics: JSON.stringify(metrics) } : { account, instanceField }),
    })
      .then((data) => {
        this.setState({ isSubmitting: false, errorMessage: undefined });
        this.reloadData(this.props);
      })
      .catch((err) => {
        this.setState({ isSubmitting: false, errorMessage: String(err) });
      });
  }

  @autobind
  handleInstanceChange(selectedInstances) {
    this.setState({ selectedInstances, isLoadingMetric: true }, async () => {
      await sleep(300);

      const metricValueMap = {};
      R.forEach((hostId) => {
        const { metrics } = this.state.hostMap[hostId] || {};
        R.forEach((metric) => {
          if (!R.has(metric.metricNameNewRelic, metricValueMap)) {
            metricValueMap[metric.metricNameNewRelic] = {};
          }
          metricValueMap[metric.metricNameNewRelic][metric.key] = metric.valueToFetch;
        }, metrics || []);
      }, selectedInstances || []);

      // build tree data
      let treeData = [];
      const treeDataKeys = [];
      R.forEachObjIndexed((val, metricNameNewRelic) => {
        const children = [];
        R.forEachObjIndexed((value, key) => {
          treeDataKeys.push(key);
          const parseKey = key.split('-');
          const hostId = parseKey[parseKey.length - 1];
          children.push({
            title: `${value}(${this.state.hostMap[hostId].hostName})`,
            value: key,
            key,
          });
        }, val);
        treeData.push({
          title: metricNameNewRelic,
          value: metricNameNewRelic,
          key: metricNameNewRelic,
          children,
        });
      }, metricValueMap);
      treeData = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], treeData);

      // reset selectedMetrics
      const { selectedMetrics } = this.state;
      const newSelectedMetrics = R.intersection(selectedMetrics, treeDataKeys);

      this.setState({
        isLoadingMetric: false,
        treeDataMetric: treeData,
        treeDataKeys,
        selectedMetrics: newSelectedMetrics,
      });
    });
  }

  render() {
    const { intl, currentProject } = this.props;
    const { isLoadingKey, isLoading, isUpdateKey, isSubmitting, errorMessage } = this.state;
    const {
      apiKey,

      accountOptions,
      fields,
      account,
      instanceField,

      hostMap,
      allHostKeys,
      treeDataMetric,
      treeDataKeys,
      isLoadingMetric,
      selectedInstances,
      selectedMetrics,
    } = this.state;
    const isMetric = get(currentProject, ['isMetric']);

    const hasErrKey = !apiKey;
    const hasChangeKey = this.apiKey !== apiKey;
    const hasFormErr = isMetric
      ? selectedInstances.length === 0 || selectedMetrics.length === 0
      : !account || !instanceField;
    return (
      <Container fullHeight className={`overflow-y-auto ${isLoadingKey || isLoading ? ' loading' : ''}`}>
        <div className="full-height" style={{ width: 800 }}>
          <Form layout="vertical">
            <Form.Item
              label="Api Key"
              validateStatus={!apiKey ? 'error' : 'success'}
              help={!apiKey ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password value={apiKey} onChange={(e) => this.setState({ apiKey: e.target.value })} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                disabled={hasErrKey || !hasChangeKey}
                loading={isUpdateKey}
                onClick={this.handleUpdateKey}
              >
                Update private key
              </Button>
            </Form.Item>

            {isMetric && (
              <>
                <Form.Item
                  label="Instances"
                  validateStatus={R.isEmpty(selectedInstances) ? 'error' : 'success'}
                  help={R.isEmpty(selectedInstances) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    style={{ width: '100%' }}
                    allowClear
                    mode="multiple"
                    autoClearSearchValue={false}
                    maxTagCount={1}
                    value={selectedInstances}
                    onChange={this.handleInstanceChange}
                    showSearch
                    optionLabelProp="label"
                    dropdownRender={(menu) => {
                      const selectAll = R.difference(allHostKeys, selectedInstances).length === 0;
                      return (
                        <div>
                          <div
                            className="flex-row"
                            style={{ padding: '5px 12px' }}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selectAll}
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (checked) {
                                  this.handleInstanceChange(allHostKeys);
                                } else {
                                  this.handleInstanceChange([]);
                                }
                              }}
                            />
                            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                          </div>
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                  >
                    {R.map((item) => {
                      return (
                        <Select.Option className="hide-icon" key={item.hostId} label={item.hostName}>
                          <Checkbox
                            checked={selectedInstances.indexOf(item.hostId) !== -1}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {item.hostName}
                        </Select.Option>
                      );
                    }, R.values(hostMap))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Metrics"
                  validateStatus={R.isEmpty(selectedMetrics) ? 'error' : 'success'}
                  help={R.isEmpty(selectedMetrics) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Spin spinning={isLoadingMetric} wrapperClassName="full-width">
                    <TreeSelect
                      style={{ width: '100%' }}
                      allowClear
                      maxTagCount={1}
                      showSearch
                      autoClearSearchValue={false}
                      treeDefaultExpandAll
                      treeCheckable
                      treeNodeLabelProp="title"
                      treeData={treeDataMetric || []}
                      value={selectedMetrics}
                      onChange={(selectedMetrics) => this.setState({ selectedMetrics })}
                      dropdownRender={(menu) => {
                        const selectAll = R.difference(treeDataKeys, selectedMetrics).length === 0;
                        return (
                          <div>
                            <div
                              className="flex-row"
                              style={{ padding: '5px 24px' }}
                              onMouseDown={(event) => event.preventDefault()}
                            >
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selectAll}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  if (checked) {
                                    this.setState({ selectedMetrics: treeDataKeys });
                                  } else {
                                    this.setState({ selectedMetrics: [] });
                                  }
                                }}
                              />
                              <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                            </div>
                            <Divider style={{ margin: '4px 0' }} />
                            {menu}
                          </div>
                        );
                      }}
                    />
                  </Spin>
                </Form.Item>
              </>
            )}

            {!isMetric && (
              <>
                <Form.Item
                  label="Account"
                  validateStatus={!account ? 'error' : 'success'}
                  help={!account ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    filterOption
                    options={accountOptions}
                    value={account}
                    onChange={(account) => {
                      this.setState((prevState) => ({ account, fields: prevState.fieldsMap[account] || [] }));
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Popover
                      title={null}
                      content={
                        <div style={{ width: 320 }}>
                          This instance in InsightFinder represents the ticket grouping granularity for pattern
                          learning.
                        </div>
                      }
                      placement="right"
                    >
                      <span>
                        InsightFinder Analysis Instance in NewRelic
                        <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
                      </span>
                    </Popover>
                  }
                  validateStatus={!instanceField ? 'error' : 'success'}
                  help={!instanceField ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    filterOption
                    value={instanceField}
                    onChange={(instanceField) => {
                      this.setState({ instanceField });
                    }}
                  >
                    {R.map((field) => {
                      return <Select.Option key={field}>{field}</Select.Option>;
                    }, fields)}
                  </Select>
                </Form.Item>
              </>
            )}

            {errorMessage && (
              <Alert message="Error" description={errorMessage} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasChangeKey || hasFormErr}
                loading={isSubmitting}
                onClick={this.handleSubmit}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    );
  }
}

const EndpointSettingNewRelic = injectIntl(EndpointSettingNewRelicCore);
export default connect((state) => {
  return {};
}, {})(EndpointSettingNewRelic);
