/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPostJson from './fetchPostJson';

const MAX_FATCH_NUM = 1000;

const postProjectInstanceGroup = (credentials: Credentials, params: Object) => {
  const { projectName, instanceGroupList, isMetric } = params;
  const grouping = [];

  // Convert the list with group as the key and instance list as the value.
  R.forEach((i) => {
    // const instanceGroup = i.instanceGroup || 'All';
    grouping.push({
      instanceName: i.instanceName,
      metricInstanceName: i.metricInstanceName,
      appName: i.appName,
      ipAddress: i.ipAddress,
      instanceGroupPriority: i.instanceGroupPriority,
      healthPriority: i.healthPriority,
      ignoreFlag: i.ignoreFlag,
      zone: i.zone,
      lossRatio: i.lossRatio,
      ...(isMetric ? { coreCount: i.coreCount, instanceType: i.instanceType } : {}),
      ...(i.containerName ? { containerName: i.containerName } : { instanceDisplayName: i.instanceDisplayName }),
      ...(i.analysisGroup ? { analysisGroup: i.analysisGroup } : {}),
    });
  }, instanceGroupList);

  if (grouping.length <= MAX_FATCH_NUM) {
    return fetchPostJson(getEndpoint('customgrouping'), { ...credentials, projectName }, grouping).then((d) => {
      return d;
    });
  } else {
    const splitEveryTakes = R.splitEvery(MAX_FATCH_NUM, grouping);
    const requests = [];
    R.forEach((group) => {
      if (group.length > 0) {
        requests.push(fetchPostJson(getEndpoint('customgrouping'), { ...credentials, projectName }, group));
      }
    }, splitEveryTakes || []);
    return Promise.all(requests).then((d) => {
      let allD = [];
      R.forEach((item) => {
        allD = [...allD, ...item];
      }, d || []);
      return allD;
    });
  }
};

export default postProjectInstanceGroup;
