/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React, { useState, useCallback } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { Button, Switch } from 'antd';

import { Container, Modal } from '../../../lib/fui/react';
import { DragIcon } from '../../../lib/fui/icons';
import { appButtonsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

const InterfaceSettingsModal = ({ intl, onOk, onCancel, rowData, isSubmitting }) => {
  let cardList = rowData.menuConfigs || [];
  cardList = R.sortWith([R.descend(R.prop('display')), R.ascend(R.prop('order'))], cardList);
  const [cards, setCards] = useState(cardList);

  const findCard = (id) => {
    const card = R.find((card) => card.id === id, cards);
    return {
      card,
      index: cards.indexOf(card),
    };
  };
  const onSortOver = useCallback(
    ({ index, oldIndex, newIndex, collection, isKeySorting }) => {
      // used for debug
      const card = cards[oldIndex];
    },
    [cards],
  );
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex, collection, isKeySorting }) => {
      const card = cards[oldIndex];
      setCards(
        update(cards, {
          $splice: [
            [oldIndex, 1],
            [newIndex, 0, card],
          ],
        }),
      );
    },
    [cards],
  );
  const onChangeCardVal = useCallback(
    (id, fieldValues) => {
      const { card, index } = findCard(id);
      setCards(update(cards, { [index]: { $set: { ...card, ...fieldValues } } }));
    },
    [cards],
  );

  return (
    <Modal
      title={intl.formatMessage(eventMessages.menuConfig)}
      width={600}
      visible
      onCancel={onCancel}
      footer={[
        <Button size="small" key="cancel" onClick={onCancel}>
          {intl.formatMessage(appButtonsMessages.cancel)}
        </Button>,
        <Button size="small" key="submit" type="primary" loading={isSubmitting} onClick={() => onOk(rowData, cards)}>
          {intl.formatMessage(appButtonsMessages.submit)}
        </Button>,
      ]}
      bodyStyle={{ backgroundColor: 'var(--content-background)' }}
      maskClosable={false}
    >
      <Container className={`flex-col overflow-y-auto content ${isSubmitting ? ' disabled' : ''}`}>
        <SortableList
          intl={intl}
          useDragHandle
          helperClass="sortableHelperAntModal"
          items={cards}
          onSortOver={onSortOver}
          onSortEnd={onSortEnd}
          onChangeCardVal={onChangeCardVal}
        />
      </Container>
    </Modal>
  );
};

const SortableList = SortableContainer(({ items, onChangeCardVal, intl }) => {
  return (
    <div
      className="full-height"
      style={{
        border: '1px solid var(--border-color-base)',
        borderRadius: 3,
        backgroundColor: 'var(--content-background)',
        outline: 'none',
      }}
    >
      {items.map((item, index) => (
        <SortableItemList
          intl={intl}
          key={`item-${item.id}`}
          index={index}
          id={item.id}
          item={item}
          onChangeCardVal={onChangeCardVal}
        />
      ))}
    </div>
  );
});

const DragHandle = sortableHandle(() => <DragIcon style={{ fontSize: 18 }} />);

const SortableItemList = SortableElement(({ id, item, onChangeCardVal, intl }) => {
  return (
    <div
      className="flex-row flex-center-align"
      style={{
        height: 50,
        backgroundColor: 'var(--content-background)',
        borderBottom: '1px solid var(--border-color-base)',
        padding: '0 16px',
      }}
    >
      <div style={{ marginRight: 16, cursor: 'row-resize' }}>
        <DragHandle />
      </div>
      <div className="flex-grow hidden-line-with-ellipsis">{item.name}</div>
      <div
        className="flex-row flex-center-align"
        style={{ justifyContent: 'space-between', paddingLeft: 16, width: 120 }}
      >
        {item.allowHide && (
          <div style={{ fontSize: 14, fontWeight: 500 }}>{intl.formatMessage(eventMessages.display)}:</div>
        )}
        {item.allowHide && (
          <Switch
            size="small"
            checked={item.display}
            onChange={(checked) =>
              onChangeCardVal(id, {
                display: checked,
              })
            }
          />
        )}
      </div>
    </div>
  );
});

export default InterfaceSettingsModal;
