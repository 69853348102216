/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */

/* eslint-disable no-console */
import moment from 'moment';
import { createLogic } from 'redux-logic';
import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions, Defaults } from '../../utils';
import { getJiraProjects } from '../../apis';
import { appMessages } from '../../app/messages';

const loadJiraProjectsLogic = createLogic({
  type: [ActionTypes.LOAD_JIRA_PROJECT_LISTS],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,

  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getJiraProjects(credentials, params)
      .then((d) => {
        dispatch(createSetAction(ActionTypes.SET_JIRA_PROJECT_LISTS, params, d));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get jira projects', err);
        dispatch(createSetAction(ActionTypes.SET_JIRA_PROJECT_LISTS, params, { jiraProjects: [] }));
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadJiraProjectsLogic;
