import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { Spin, message } from 'antd';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { eventMessages } from '../../../common/metric/messages';

const AlertReductionRatio = (props: Object) => {
  const { systemId, startTime, endTime, systemInfo, credentials, intl } = props;

  const [loading, setLoading] = useState(false);
  const [ratio, setRatio] = useState(null);

  const fetchData = () => {
    const { customerName, id: systemName } = systemInfo;
    const startTimestamp = moment.utc(startTime).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime).endOf('day').valueOf();
    setLoading(true);
    fetchGet(getEndpoint('logalertpatterndashboard', 2), {
      ...credentials,
      systemName,
      startTime: startTimestamp,
      endTime: endTimestamp,
      customerName,
    })
      .then((res) => {
        setRatio(res?.reduction_rate || null);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err?.message || err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (systemInfo) {
      fetchData(systemInfo);
    }
  }, [systemId]);

  return (
    <Spin spinning={loading} wrapperClassName="overflow-x-hidden overflow-y-auto full-width full-height">
      <div className="font-14 bold flex-row flex-space-between flex-center-align">
        <span className="hidden-line-with-ellipsis">{intl.formatMessage(eventMessages.alertReductionRatio)}</span>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginTop: 8,
          gap: 24,
        }}
      >
        <div className="flex-col">
          {R.isNil(ratio) ? (
            <div style={{ color: 'var(--text-color-secondary)' }}>
              {intl.formatMessage(eventMessages.noDataForThisTimePeriod)}
            </div>
          ) : (
            <div style={{ fontSize: 18 }}>{ratio}</div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default AlertReductionRatio;
