/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { get, round } from 'lodash';

const parseMetricCapacityPlanning = ({
  projectName,
  env,
  instanceObj,
  dayAllResult,
  dayAllMetricsScore,
  metricGroupingPriorityMap,
}) => {
  const componentList = [];
  const metricList = [];
  const systemInfos = {};

  const allMetricStr = 'All Metrics';
  const componentMetricMap = {};
  const componentMaxTargetInstances = {};
  const { aggregatedWeightedScore: allMetricsScore, ...dayComponentResult } = dayAllResult;
  const aggregatedWeightedScore = allMetricsScore || dayAllMetricsScore;
  R.forEachObjIndexed((vals, component) => {
    if (componentList.indexOf(component) < 0) {
      componentList.push(component);
    }
    R.forEach((v) => {
      const { MetricName } = v;
      if (metricList.indexOf(MetricName) < 0) {
        metricList.push(MetricName);
        systemInfos[MetricName] = [];
        componentMetricMap[MetricName] = [];
      }
    }, vals);
    componentMaxTargetInstances[component] = {
      targetAvgInstancesL1: 0,
      targetAvgInstancesL2: 0,
      targetMaxInstancesL1: 0,
      targetMaxInstancesL2: 0,
    };
  }, dayComponentResult);

  let index = 1;
  const envObj = get(instanceObj, env, {});
  R.forEachObjIndexed((vals, component) => {
    const actualInstances = get(envObj, component, []).length;

    R.forEach((v) => {
      const {
        MetricName,
        capacityScore,
        realAvg,
        realMax,
        predictAvg,
        predictMax,
        predictError,
        targetAvgInstancesL1,
        targetAvgInstancesL2,
        targetMaxInstancesL1,
        targetMaxInstancesL2,
        thresholdL1,
        thresholdL2,
        capacityThreshold,
      } = v;
      componentMetricMap[MetricName].push({
        id: index,
        name: component,
        priority: get(metricGroupingPriorityMap, component, 0),
        projectName,
        actualInstances,
        capacityScore: round(capacityScore * 100, 2),
        realAvg: round(realAvg, 2),
        realMax: round(realMax, 2),
        predictAvg: round(predictAvg, 2),
        predictMax: round(predictMax, 2),
        predictError: round(predictError, 2),
        targetAvgInstancesL1,
        targetAvgInstancesL2,
        targetMaxInstancesL1,
        targetMaxInstancesL2,
        thresholdL1,
        thresholdL2,
        capacityThreshold,
      });
      if (capacityScore > 0) {
        componentMaxTargetInstances[component].targetAvgInstancesL1 = R.max(
          componentMaxTargetInstances[component].targetAvgInstancesL1,
          targetAvgInstancesL1,
        );
        componentMaxTargetInstances[component].targetAvgInstancesL2 = R.max(
          componentMaxTargetInstances[component].targetAvgInstancesL2,
          targetAvgInstancesL2,
        );
        componentMaxTargetInstances[component].targetMaxInstancesL1 = R.max(
          componentMaxTargetInstances[component].targetMaxInstancesL1,
          targetMaxInstancesL1,
        );
        componentMaxTargetInstances[component].targetMaxInstancesL2 = R.max(
          componentMaxTargetInstances[component].targetMaxInstancesL2,
          targetMaxInstancesL2,
        );
      }
      index += 1;
    }, vals);
  }, dayComponentResult);
  if (R.has(allMetricStr, componentMetricMap)) {
    componentMetricMap[allMetricStr] = R.map((c) => {
      const componentMaxTargetInfo = get(componentMaxTargetInstances, [c.name]);
      if (componentMaxTargetInfo) {
        return { ...c, ...componentMaxTargetInfo };
      }
      return c;
    }, componentMetricMap[allMetricStr]);
  }

  const addTotal = (a, b, field) => {
    return get(a, field, 0) + get(b, field, 0);
  };
  R.forEach((m) => {
    const systemInfo = R.reduce(
      (a, b) => {
        return {
          actualInstances: addTotal(a, b, 'actualInstances'),
          capacityScore: get(a, 'capacityScore', 0) + get(b, 'capacityScore', 0) * get(b, 'actualInstances', 0),
          realAvg: get(a, 'realAvg', 0) + get(b, 'realAvg', 0) * get(b, 'actualInstances', 0),
          realMax: 0,
          predictAvg: get(a, 'predictAvg', 0) + get(b, 'predictAvg', 0) * get(b, 'actualInstances', 0),
          predictMax: 0,
          predictError: get(a, 'predictError', 0) + get(b, 'predictError', 0) * get(b, 'actualInstances', 0),
          targetAvgInstancesL1: addTotal(a, b, 'targetAvgInstancesL1'),
          targetAvgInstancesL2: addTotal(a, b, 'targetAvgInstancesL2'),
          targetMaxInstancesL1: addTotal(a, b, 'targetMaxInstancesL1'),
          targetMaxInstancesL2: addTotal(a, b, 'targetMaxInstancesL2'),
        };
      },
      {},
      componentMetricMap[m],
    );

    const { capacityScore, realAvg, predictAvg, predictError, actualInstances } = systemInfo;
    let componentInfos = componentMetricMap[m];
    componentInfos = R.sortWith([R.descend(R.prop('priority'))], componentInfos);
    systemInfos[m].push({
      ...systemInfo,
      capacityScore:
        m === allMetricStr ? round(aggregatedWeightedScore * 100, 2) : round(capacityScore / (actualInstances || 1), 2),
      realAvg: round(realAvg / (actualInstances || 1), 2),
      predictAvg: round(predictAvg / (actualInstances || 1), 2),
      predictError: round(predictError / (actualInstances || 1), 2),
      id: 1,
      name: projectName,
      projectName,
      componentInfos,
    });
  }, metricList);

  return {
    projectName,
    systemInfos,
    componentList,
    metricList,
    componentInstanceMap: envObj,
  };
};

export default parseMetricCapacityPlanning;
