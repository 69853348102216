/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import { connect } from 'react-redux';
import { get, isString } from 'lodash';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';

import { State } from '../../../common/types';
import { Container } from '../../../lib/fui/react';
import { parseLocation, getLoadStatus, parseJSON } from '../../../common/utils';
import CausalIncident from '../../causal/components/CausalIncident';
import { loadCausalIncident } from '../../../common/causal/actions';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,

  width: Number,
  height: Number,
  queryResult: Object,
  queryParams: Object,
  loadCausalIncident: Function,
};

class ProjectCausalGraphCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.defaultkpiPredictionProbability = '1.0';

    this.state = {
      causalGroupList: [],
      selectedCausalGroup: '',
      causalGroupInfo: {},

      view: 'relation',
      relationTimeThreshold: '15.0',
      relationProbability: '0.5',
      relationCount: undefined,
      kpiPredictionProbability: this.defaultkpiPredictionProbability,
      joinDependency: false,
    };
  }

  componentDidMount() {
    this.convertData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps);
    }
  }

  convertData(props) {
    const { queryParams, queryResult } = props;
    const { causalResult } = queryResult;
    const causalGroupList = [];
    R.forEachObjIndexed((val, key) => {
      let causalInfo = val.causalInfo || {};
      if (isString(causalInfo)) {
        causalInfo = JSON.parse(causalInfo);
      }
      const casualStartPoint = parseJSON(causalInfo.casualStartPoint) || [];
      causalGroupList.push({ name: key, causalInfo, casualStartPoint });
    }, causalResult || {});

    const { joinDependency } = this.state;
    const causal = causalGroupList.length > 0 ? causalGroupList[0] : null;
    if (causal) {
      this.setState({
        causalGroupList,
        selectedCausalGroup: causal.name,
        causalGroupInfo: this.getCausalGroupInfo({ queryParams, joinDependency, causal }),
      });
    }
  }

  @autobind
  getCausalGroupInfo({ queryParams, joinDependency, causal }) {
    const causalGroup = get(causal, 'causalInfo', {});
    const { name: causalName } = causal || {};
    const { causalGroupKey: causalKey, relationKey, fileName, userName } = causalGroup;
    const { startTimeObj, endTimeObj } = queryParams;
    const incidentParams = {
      causalName,
      causalKey,
      relationKey,
      fileName,
      customerName: userName,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.endOf('day').valueOf(),
      joinDependency,
    };
    const causalIncidentList = [
      {
        ...causal,
        ...incidentParams,
      },
    ];
    return {
      causalGroup,
      causal,
      incidentParams,
      causalIncidentList,
    };
  }

  @autobind
  handleListItemClick(selectedCausalGroup) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();

      const { queryParams } = this.props;
      const { causalGroupList } = this.state;
      const causal = R.find((causalGroup) => causalGroup.name === selectedCausalGroup, causalGroupList) || {};

      this.setState({
        selectedCausalGroup,
        causalGroupInfo: this.getCausalGroupInfo({ queryParams, joinDependency: false, causal }),
        view: 'relation',
        relationTimeThreshold: '15.0',
        relationProbability: '0.5',
        relationCount: undefined,
        kpiPredictionProbability: this.defaultkpiPredictionProbability,
        joinDependency: false,
      });
    };
  }

  @autobind
  handleViewChange(view) {
    this.setState({ view });
  }

  @autobind
  handleRelationTimeThresholdChanged(newValue) {
    this.setState({ relationTimeThreshold: newValue });
  }

  @autobind
  handleRelationProbabilityChanged(newValue) {
    this.setState({ relationProbability: newValue });
  }

  @autobind
  handleRelationCountChanged(newValue) {
    this.setState({ relationCount: newValue });
  }

  @autobind
  handleJoinDependencyChanged(newValue) {
    this.setState({ joinDependency: Boolean(newValue) });
  }

  @autobind
  handleKpiPredictionProbabilityChanged(newValue) {
    this.setState({ kpiPredictionProbability: newValue });
  }

  render() {
    const { height, width, loadCausalIncident, location } = this.props;
    const { causalGroupList, selectedCausalGroup, causalGroupInfo, view } = this.state;
    const {
      relationTimeThreshold,
      relationProbability,
      kpiPredictionProbability,
      relationCount,
      joinDependency,
    } = this.state;
    const params = parseLocation(location);
    const { pattern } = params;
    const { causalGroup, causal, incidentParams, causalIncidentList } = causalGroupInfo;

    return (
      <Container className="flex-col causal" style={{ height, width }}>
        <div className="ui pointing secondary menu" style={{ marginBottom: 0 }}>
          <span className="label" style={{ marginTop: 10 }}>
            Groups:
          </span>
          {R.map(
            (c) => (
              <a
                key={c.name}
                className={`${c.name === selectedCausalGroup ? 'active' : ''} item`}
                onClick={this.handleListItemClick(c.name)}
              >
                {c.name}
              </a>
            ),
            causalGroupList,
          )}
        </div>
        {!causal && (
          <div
            className="ui info message"
            style={{
              marginTop: 24,
            }}
          >
            {`No Causal Group Found.`}
          </div>
        )}
        {causal && (
          <CausalIncident
            className="flex-grow"
            causalIncidentList={causalIncidentList}
            incidentParams={incidentParams}
            causalGroup={causalGroup}
            view={view}
            relationTimeThreshold={relationTimeThreshold}
            relationProbability={relationProbability}
            kpiPredictionProbability={kpiPredictionProbability}
            relationCount={relationCount}
            joinDependency={joinDependency}
            pattern={pattern}
            onViewChange={this.handleViewChange}
            onRelationTimeThresholdChanged={this.handleRelationTimeThresholdChanged}
            onRelationProbabilityChanged={this.handleRelationProbabilityChanged}
            onRelationCountChanged={this.handleRelationCountChanged}
            onJoinDependencyChanged={this.handleJoinDependencyChanged}
            onKpiPredictionProbabilityChanged={this.handleKpiPredictionProbabilityChanged}
            loadCausalIncident={loadCausalIncident}
            hideKpiPrediction
          />
        )}
      </Container>
    );
  }
}

const ProjectCausalGraph = injectIntl(ProjectCausalGraphCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    return {
      location,
      loadStatus,
    };
  },
  { loadCausalIncident },
)(ProjectCausalGraph);
