/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import moment from 'moment';
import { isString } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getProjectHoliday = (credentials: Credentials, params: Object) => {
  const { projectName } = params;

  return fetchGet(getEndpoint('holiday'), {
    ...credentials,
    projectName,
  }).then((d) => {
    const rowData = d;
    let holidayList = [];

    R.forEachObjIndexed((val, key) => {
      let startTime;
      let endTime;
      let priority;
      let period;
      let dayInPeriod;

      if (isString(val)) {
        const times = val.split(',');
        startTime = moment.utc(times[0], 'MM-DD');
        endTime = moment.utc(times[1], 'MM-DD');
      } else {
        startTime = moment.utc(val.startTime, 'MM-DD');
        endTime = moment.utc(val.endTime, 'MM-DD');
        priority = val.priority;
        period = val.period;
        dayInPeriod = val.dayInPeriod;
      }
      holidayList.push({ name: key, startTime, endTime, priority, period, dayInPeriod });
    }, d || {});

    holidayList = R.sort((a, b) => a.startTime.valueOf() - b.startTime.valueOf(), holidayList);
    holidayList = R.sort((a, b) => parseInt(b.priority || '0', 10) - parseInt(a.priority || '0', 10), holidayList);
    return {
      rowData,
      data: {
        holidayList,
      },
    };
  });
};

export default getProjectHoliday;
