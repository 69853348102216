import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { Button, message, Skeleton, Spin } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, DoubleLeftOutlined } from '@ant-design/icons';

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Table,
  SortDirection,
  Popover,
  Modal,
  Tooltip,
} from '../../../lib/fui/react';
import { buildUrl, Defaults, EventRenderers, parseLocation } from '../../../common/utils';
import { BaseUrls } from '../../app/Constants';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';

import RenderLogContent from './RenderLogContent';

import { logMessages } from '../../../common/log/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

const expandedStateKey = 'isExpand';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

const reducer = (oldVal, newVal) => ({ ...oldVal, ...newVal });

const initState = {
  loading: false,
  dataList: [],
  sortBy: null,
  sortDirection: null,
  pageNo: 1,
  pageSize: 200,
  total: 0,
  metricName: '',
  allExpand: false,
};

export default function LogToMetricContext({
  intl,
  rowData,
  credentials,
  isAdmin,
  userName,
  currentTheme,
  summarySettingsMap,
  projects,
  onClose,
  location,
  globalInfo,
}: Object) {
  const dataTableNode = useRef(null);
  const loadFlag = useRef(false);
  const [state, setState] = useReducer(reducer, initState);
  const { dataList, loading, sortBy, sortDirection, pageSize, pageNo, total, metricName, allExpand } = state;
  const correspondingLogProjectName =
    isAdmin || rowData?.projectOwner !== userName
      ? `${rowData?.correspondingLogProjectName}@${rowData?.projectOwner}`
      : rowData?.correspondingLogProjectName;
  const projectName =
    isAdmin || rowData?.projectOwner !== userName
      ? `${rowData?.projectName}@${rowData?.projectOwner}`
      : rowData?.projectName;
  const summarySettings = summarySettingsMap && rowData ? summarySettingsMap[correspondingLogProjectName] || [] : [];

  const onToggleCollapse = useCallback(() => {
    cellMeasureCache.clearAll();
    if (dataTableNode.current) {
      dataTableNode.current.forceUpdateGrid();
    }
  }, []);

  const getDataList = ({ pageNo = 1 }) => {
    if (loadFlag.current) return;
    const { instanceListStr, startTimestamp, endTimestamp, metricRootCauseJson } = rowData;
    const metricName = (metricRootCauseJson.metricName || '').split('=')[0] || undefined;
    loadFlag.current = true;
    setState({ loading: true, metricName });
    fetchGet(getEndpoint('logtometriccontext'), {
      ...credentials,
      projectName,
      instanceName: instanceListStr,
      startTime: startTimestamp,
      endTime: endTimestamp,
      metric: metricName,
      pageNo,
      pageSize,
    })
      .then((data) => {
        const { events, totalMatched } = data || {};
        let newEvent = [
          ...dataList,
          ...R.map(
            (item) => ({ ...item, [expandedStateKey]: allExpand, instanceName: item.i, logToMetricValue: item.lmv }),
            events || [],
          ),
        ];
        newEvent = R.sortWith([R.ascend(R.prop('timestamp'))])(newEvent);
        setState({ pageNo, total: totalMatched || 0, loading: false, dataList: newEvent });
        setTimeout(() => {
          cellMeasureCache.clearAll();
          if (dataTableNode.current) dataTableNode.current.forceUpdateGrid();
        }, 0);
        loadFlag.current = false;
      })
      .catch((err) => {
        message.error(String(err));
        setState({ loading: false });
        loadFlag.current = false;
      });
  };

  useEffect(() => {
    let cancel = false;
    if (cancel) return;
    getDataList({ pageNo });
    // eslint-disable-next-line consistent-return
    return () => {
      cancel = true;
    };
  }, []);

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    const allExpandRender = () => {
      return (
        <>
          <span className="flex-grow" />
          <DoubleLeftOutlined
            rotate={allExpand ? 90 : -90}
            style={{ marginRight: 3, cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const newDataList = R.map((item) => {
                return { ...item, [expandedStateKey]: !allExpand };
              }, dataList);
              setState({ dataList: newDataList, allExpand: !allExpand });
            }}
          />
        </>
      );
    };
    if (dataKey === 'logToMetricValue') {
      return (
        <>
          <Tooltip title={metricName} mouseEnterDelay={0.3} placement="top">
            <span className="hidden-line-with-ellipsis" style={{ maxWidth: 90 }}>
              {metricName}
            </span>
          </Tooltip>
          <div>{!disableSort && sortIcon()}</div>
        </>
      );
    }
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
        {dataKey === 'rawData' && allExpandRender()}
      </div>
    );
  };

  useEffect(() => {
    onToggleCollapse();
  }, [dataList]);

  useEffect(() => {
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        setState({ dataList: R.sortWith([R.descend(R.prop(sortBy))])(dataList) });
      } else {
        setState({ dataList: R.sortWith([R.ascend(R.prop(sortBy))])(dataList) });
      }
      onToggleCollapse();
    }
  }, [sortBy, sortDirection]);

  const datetimeRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
    const { timestamp, isLastLoadMore } = rowData;
    if (loading) {
      return (
        <div style={{ lineHeight: '40px', alignItems: 'center' }}>
          <Skeleton.Button size="small" active />
        </div>
      );
    } else if (isLastLoadMore) {
      return (
        <a
          style={{ lineHeight: '40px' }}
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            getDataList({ pageNo: pageNo + 1 });
          }}
        >
          Load more: +1 page
        </a>
      );
    }
    const content = <div>{moment.utc(timestamp).format(Defaults.TimeFormat)}</div>;
    return <div>{content}</div>;
  };

  const compressBaseRenderer = ({ rowData: data }) => {
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};
    const { instanceName, isLastLoadMore } = data;

    if (isLastLoadMore) return <div />;

    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName, {
      pn: rowData.projectName,
      owner: rowData.projectOwner,
    });

    return (
      <Popover title={null} content={instanceStr} mouseEnterDelay={0.3}>
        <div className="hidden-line-with-ellipsis" style={{ wordWrap: 'break-word' }}>
          {instanceStr}
        </div>
      </Popover>
    );
  };

  const logPatternIdRenderer = ({ rowData }) => {
    const { logToMetricValue, isLastLoadMore } = rowData || {};
    if (isLastLoadMore) return <div />;
    return <div>{logToMetricValue}</div>;
  };

  const contentRender = ({ dataKey, parent, rowIndex, rowData }) => {
    const { rawData, isLastLoadMore } = rowData;
    if (isLastLoadMore) {
      return (
        <CellMeasurer cache={cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
          <div style={{ width: 100, height: 40 }} />
        </CellMeasurer>
      );
    }

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error)
    }
    const isMetric = Boolean(
      rawDataJson?.rootCauseMetric && rawDataJson?.direction && (rawDataJson?.pct || rawDataJson?.pct === 0),
    );
    return (
      <CellMeasurer cache={cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ padding: '8px 0' }}>
          {isMetric && (
            <div className="flex-grow flex-min-width" style={{ wordBreak: 'break-word' }}>
              {EventRenderers.RenderMetricAnomalySummary({
                intl,
                event: rawDataJson,
              })}
            </div>
          )}
          {!isMetric && (
            <>
              {!rawDataJson && (
                <Popover
                  title={null}
                  content={
                    <div className="overflow-y-auto overflow-x-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
                      <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word' }}>
                        {R.join(
                          '\n',
                          R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                        )}
                      </div>
                    </div>
                  }
                  mouseEnterDelay={0.3}
                  placement="right"
                >
                  <div className="hidden-line-with-ellipsis">{rawData}</div>
                </Popover>
              )}
              {rawDataJson && (
                <div className="flex-grow flex-min-width" style={{ wordBreak: 'break-word' }}>
                  <RenderLogContent
                    rowData={rowData}
                    summarySettings={summarySettings}
                    currentTheme={currentTheme}
                    onToggleCollapse={onToggleCollapse}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </CellMeasurer>
    );
  };

  const handleJumpToLogAnalysis = () => {
    const { projectOwner, instanceListStr, startTimestamp, endTimestamp } = rowData;

    const project = R.find((project) => {
      return correspondingLogProjectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);

    const query = {
      projectName: correspondingLogProjectName,
      instanceName: dataList[0]?.instanceName || instanceListStr,
      startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      endTime: moment.utc(endTimestamp).format(Defaults.DateFormat),
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      isJump: true,
      customerName: projectOwner,
      activePatternId: dataList[0]?.patternId || undefined,
    };

    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  };

  const newDataList = [...dataList, { isLastLoadMore: true }];

  return (
    <Modal
      visible
      title={
        <div className="flex-row flex-center-align">
          <span className="flex-grow">{intl.formatMessage(eventMessages.logToMetricContext)}</span>
          <div className="flex-row flex-center-align" style={{ marginRight: 40 }}>
            <span>{intl.formatMessage(DashboardMessages.clickToSeeDetails)}:</span>
            <Button
              size="small"
              type="primary"
              onClick={handleJumpToLogAnalysis}
              style={{ marginLeft: 8 }}
              disabled={dataList.length === 0}
            >
              {intl.formatMessage(DashboardMessages.logAnalysis)}
            </Button>
          </div>
        </div>
      }
      onCancel={() => onClose()}
      width={950}
      bodyStyle={{ height: 600, overflowY: 'auto' }}
      footer={null}
    >
      <Container className="full-height">
        <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-width">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={cellMeasureCache.rowHeight}
                rowCount={newDataList.length}
                rowGetter={({ index }) => newDataList[index]}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                headerClassName="flex-row flex-center-align"
                ref={(c) => {
                  dataTableNode.current = c;
                }}
              >
                <Column
                  width={130}
                  label={intl.formatMessage(logMessages.dateTime)}
                  dataKey="timestamp"
                  headerRenderer={headerRenderer}
                  cellRenderer={datetimeRenderer}
                />
                <Column
                  width={120}
                  label={intl.formatMessage(appFieldsMessages.instanceName)}
                  dataKey="instanceName"
                  headerRenderer={headerRenderer}
                  cellRenderer={compressBaseRenderer}
                />
                {!['Count'].includes(metricName) && (
                  <Column
                    width={120}
                    label={null}
                    dataKey="logToMetricValue"
                    headerRenderer={headerRenderer}
                    cellRenderer={logPatternIdRenderer}
                    flexShrink={0}
                  />
                )}
                <Column
                  width={110}
                  flexGrow={1}
                  label={intl.formatMessage(logMessages.logEntry)}
                  dataKey="rawData"
                  disableSort
                  headerRenderer={headerRenderer}
                  cellRenderer={contentRender}
                  style={{ whiteSpace: 'pre-wrap' }}
                />
              </Table>
            )}
          </AutoSizer>
        </Spin>
      </Container>
    </Modal>
  );
}
