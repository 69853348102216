import React, { memo } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { InfoCircleOutlined, LaptopOutlined } from '@ant-design/icons';

import { Defaults, EventRenderers, parseLocation } from '../../../common/utils';
import { Handle, Position } from '../../../lib/reactflow/core';
import { Popover } from '../../../lib/fui/react';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

const PredictionFlowCustomNodeCore = memo(({ data, intl, currentTheme, projects }: Object) => {
  const {
    own,
    summarySettings,
    ownList,
    event,
    incidentPatternName,
    metricUnitMap,
    predictionSourceInfoList,
    onLineChartClick,
    onLineChartJump,
    onPredictionJumpLogClick,
    onPredictionSourceLineChartClick,
    onPredictionSourceJumpClick,
    onPredictionSourceTrendPatternsClick,
    onPredictionSourceLogQueryClick,
    groups,
    instanceDisplayNameMap,
    isJWT,
  } = data || {};

  let allOwnList = [];
  const allOwn = [];
  R.forEach((g) => {
    R.forEach((n) => {
      const ownList = n?.data?.ownList || [];
      const own = n?.data?.own;
      allOwnList = [...allOwnList, ...ownList];
      if (own) {
        allOwn.push(own);
      }
    }, g.nodes || []);
  }, groups || []);

  const list = (R.isEmpty(allOwnList) ? ownList : allOwnList) || [];
  let eventList = R.map((item) => {
    const { sourceInstanceName, predictionTime, sourceDetail } = item;
    const { nid, type, content } = sourceDetail || {};
    const isMetric = type === 'Metric';
    const mergerKey = `${sourceInstanceName}-${predictionTime}-${nid}${isMetric ? `-${content}` : ''}`;
    return { ...item, mergerKey };
  }, list);
  eventList = R.uniqBy((item) => item.mergerKey, eventList);

  const lastOwn = list[list?.length - 1] || own;
  const { sourceInstanceName, predictionTime, isRoot, startTimestamp, realInstanceName, containerInfo } = own || {};
  const { projectName, projectOwner, sourceProjectName, sourceProjectOwner } = own || {};
  const allInstances = R.uniq(R.map((o) => o.sourceInstanceName, allOwn));
  const predictionEndTimestamp = moment.utc(lastOwn?.predictionTime || predictionTime).format(Defaults.TimeOnlyFormat);
  const predictionEndTimestampFlag =
    predictionEndTimestamp === moment.utc(predictionTime).format(Defaults.TimeOnlyFormat) || isRoot;

  if (isRoot) {
    eventList = [own];
  }

  let viewInstanceName = isRoot
    ? containerInfo
      ? `${containerInfo?.containerName}_${containerInfo?.instanceName}`
      : realInstanceName
    : sourceInstanceName;
  const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, viewInstanceName, {
    pn: projectName || sourceProjectName,
    owner: projectOwner || sourceProjectOwner,
  });
  viewInstanceName = `${allInstances.length > 1 ? '...' : ''}${instanceStr}`;

  return (
    <>
      <div className="visibility-display" style={{ position: 'relative' }}>
        <Handle type="target" position={Position.Top} id="a" style={{ visibility: 'hidden' }} />
        <Popover
          mouseEnterDelay={0.3}
          forceRender
          content={
            <div style={{ minWidth: 200, maxWidth: 400, maxHeight: 400, overflowY: 'auto', paddingRight: 6 }}>
              {R.addIndex(R.map)((own, idx) => {
                const { isRoot, mergerKey } = own;
                return (
                  <div
                    key={mergerKey}
                    style={{
                      ...(idx > 0
                        ? {
                            marginTop: 14,
                            paddingTop: 14,
                            borderTop: '2px dashed var(--virtualized-table-border-color)',
                          }
                        : {}),
                    }}
                  >
                    {isRoot && (
                      <EventRenderers.RenderPredictionDetails
                        key={idx}
                        intl={intl}
                        index={idx}
                        event={event}
                        incidentPatternName={incidentPatternName}
                        summarySettings={summarySettings}
                        currentTheme={currentTheme}
                        predictionSourceInfo={own}
                        onLineChartClick={onLineChartClick}
                        onLineChartJump={onLineChartJump}
                        onPredictionJumpLogClick={onPredictionJumpLogClick}
                        instanceDisplayNameMap={instanceDisplayNameMap}
                        isJWT={isJWT}
                      />
                    )}
                    {!isRoot && (
                      <EventRenderers.RenderLogPredictionCauseDetails
                        key={idx}
                        intl={intl}
                        index={idx}
                        event={event}
                        projects={projects || []}
                        metricUnitMap={metricUnitMap}
                        predictionSourceInfo={own}
                        showHop={predictionSourceInfoList.length > 1}
                        summarySettings={summarySettings}
                        currentTheme={currentTheme}
                        onLineChartJump={onLineChartJump}
                        onPredictionSourceLineChartClick={onPredictionSourceLineChartClick}
                        onPredictionSourceJumpClick={onPredictionSourceJumpClick}
                        onPredictionSourceTrendPatternsClick={onPredictionSourceTrendPatternsClick}
                        onPredictionSourceLogQueryClick={onPredictionSourceLogQueryClick}
                        instanceDisplayNameMap={instanceDisplayNameMap}
                        isJWT={isJWT}
                      />
                    )}
                  </div>
                );
              }, eventList || [])}
            </div>
          }
        >
          <div className="hover-display-item" style={{ position: 'absolute', right: 48, top: 14, fontSize: 20 }}>
            <InfoCircleOutlined />
          </div>
        </Popover>
        <div className="corner-8 nodrag" style={{ padding: 8, maxWidth: 200, minWidth: 200, fontSize: 16 }}>
          <div className="flex-row flex-center-align flex-center-justify" style={{ margin: '6px 0', fontSize: 28 }}>
            {isRoot ? (
              <LaptopOutlined style={{ color: 'var(--primary-color)' }} />
            ) : (
              <LaptopOutlined style={{ color: 'var(--success-color)' }} />
            )}
          </div>
          <div className="flex-row flex-center-align">
            <span className="flex-grow hidden-line-with-ellipsis text-center">
              {moment.utc(isRoot ? startTimestamp : predictionTime).format(Defaults.ShortTimeFormat)}
              {predictionEndTimestampFlag ? '' : `-${predictionEndTimestamp}`}
            </span>
          </div>
          <div className="flex-row flex-center-align">
            <span className="flex-grow hidden-line-with-ellipsis text-center">{viewInstanceName}</span>
          </div>
        </div>
        <Handle type="source" position={Position.Bottom} id="c" style={{ visibility: 'hidden' }} />
      </div>
    </>
  );
});

const PredictionFlowCustomNode = injectIntl(PredictionFlowCustomNodeCore);
export default connect((state: State) => {
  const { projects, location } = state.app;
  const { dark, jwtToken } = parseLocation(location);
  if (jwtToken) {
    if (dark) {
      state.app.currentTheme = 'dark';
    } else {
      state.app.currentTheme = 'light';
    }
  }

  const { currentTheme } = state.app;
  return { currentTheme, projects };
})(PredictionFlowCustomNode);
