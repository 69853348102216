/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/concat';
import 'rxjs/add/operator/delay';

import { combineEpics } from 'redux-observable';
import appEpics from './app/epics';
import metricEpics from './metric/epics';
import causalEpics from './causal/epics';
import logEpics from './log/epics';
import settingsEpics from './settings/epics';
import usecaseEpics from './usecase/epics';

const epics = [...appEpics, ...metricEpics, ...causalEpics, ...logEpics, ...settingsEpics, ...usecaseEpics];

const configureEpics = (deps) => (action$, { getState }) =>
  combineEpics(...epics)(action$, {
    ...deps,
    getState,
  });

export default configureEpics;
