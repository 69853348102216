import React, { useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { AutoComplete, Button, Checkbox, Input, InputNumber, message, Spin, Tabs, Upload } from 'antd';

import {
  CaretDownOutlined,
  CaretUpOutlined,
  DoubleLeftOutlined,
  DownOutlined,
  UploadOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Modal,
  SortDirection,
  Table,
} from '../../../../lib/fui/react';

import { settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { eventActionMessages } from '../../../../common/metric/messages';
import getEndpoint from '../../../../common/apis/getEndpoint';
import fetchPutForm from '../../../../common/apis/fetchPutForm';
import fetchPostForm from '../../../../common/apis/fetchPostForm';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

export default function UpdateBaseValeMappingModal({
  intl,
  incident,
  projectName,
  metricProjectName,
  baseValueMappingMap,
  onClose,
  getBasevaluemapping,
  isLoading,
  changeMappingFlag,
}: Object) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    activeKey: 'instance',
    allInstanceExpand: false,
    allComponetExpand: false,
    allMappingKeysExpand: false,
    instanceCheckedAll: false,
    componetCheckedAll: false,
    mappingKeysCheckedAll: false,
    instanceMappings: baseValueMappingMap.instance || [],
    componetMappings: baseValueMappingMap.component || [],
    mappingKeys: baseValueMappingMap.mappingKey || [],
    sortBy: null,
    sortDirection: null,

    showGroupSetting: false,
    showAddBaseValueMapping: false,
  });
  const {
    activeKey,
    allInstanceExpand,
    allComponetExpand,
    allMappingKeysExpand,
    instanceCheckedAll,
    componetCheckedAll,
    mappingKeysCheckedAll,
    instanceMappings,
    componetMappings,
    mappingKeys,
    sortBy,
    sortDirection,

    showGroupSetting,
    showAddBaseValueMapping,
  } = state;
  const dataTableNode = useRef(null);
  const localInstanceMappings = useRef(baseValueMappingMap.instance || []);
  const localComponetMappings = useRef(baseValueMappingMap.component || []);
  const localMappingKeys = useRef(baseValueMappingMap.mappingKey || []);

  const isInstance = activeKey === 'instance';
  const isComponent = activeKey === 'component';
  const isMappingKey = activeKey === 'mappingKey';

  useEffect(() => {
    localInstanceMappings.current = baseValueMappingMap.instance || [];
    localComponetMappings.current = baseValueMappingMap.component || [];
    localMappingKeys.current = baseValueMappingMap.mappingKey || [];
    setState({
      instanceMappings: baseValueMappingMap.instance || [],
      componetMappings: baseValueMappingMap.component || [],
      mappingKeys: baseValueMappingMap.mappingKey || [],
    });
  }, [baseValueMappingMap]);

  const getEvents = (flag) => {
    if (flag) {
      if (isInstance) {
        return 'instanceMappings';
      } else if (isComponent) {
        return 'componetMappings';
      } else if (isMappingKey) {
        return 'mappingKeys';
      }
    }
    if (isInstance) {
      return instanceMappings;
    } else if (isComponent) {
      return componetMappings;
    } else if (isMappingKey) {
      return mappingKeys;
    }
  };

  const getAllExpand = (flag) => {
    if (flag) {
      if (isInstance) {
        return 'allInstanceExpand';
      } else if (isComponent) {
        return 'allComponetExpand';
      } else if (isMappingKey) {
        return 'allMappingKeysExpand';
      }
    }
    if (isInstance) {
      return allInstanceExpand;
    } else if (isComponent) {
      return allComponetExpand;
    } else if (isMappingKey) {
      return allMappingKeysExpand;
    }
  };

  const getCheckedAll = (flag) => {
    if (flag) {
      if (isInstance) {
        return 'instanceCheckedAll';
      } else if (isComponent) {
        return 'componetCheckedAll';
      } else if (isMappingKey) {
        return 'mappingKeysCheckedAll';
      }
    }
    if (isInstance) {
      return instanceCheckedAll;
    } else if (isComponent) {
      return componetCheckedAll;
    } else if (isMappingKey) {
      return mappingKeysCheckedAll;
    }
  };

  const handleUpload = (options) => {
    const { file } = options || {};
    const { name } = file || {};
    const fetchUrl = `logtometricbasevaluemapping?projectName=${projectName}&type=${activeKey}&metricProjectName=${metricProjectName}&uploadFileName=${name}`;
    const fd = new FormData();
    fd.append('data', file);
    fetchPostForm(getEndpoint(fetchUrl), fd)
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          setState({ showGroupSetting: false });
          changeMappingFlag();
          getBasevaluemapping();
        } else {
          message.error(msg);
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });
  };

  const topEditRender = () => {
    return (
      <div style={{ marginBottom: 8, marginTop: 8 }}>
        <Button
          size="small"
          type="primary"
          style={{ marginRight: 8 }}
          onClick={() => setState({ showAddBaseValueMapping: true })}
        >
          {intl.formatMessage(appButtonsMessages.add)}
        </Button>
        <Upload showUploadList={false} maxCount={1} accept=".json" customRequest={handleUpload}>
          <Button icon={<UploadOutlined />} size="small" type="primary">
            {intl.formatMessage(appButtonsMessages.upload)}
          </Button>
        </Upload>
      </div>
    );
  };

  const cellNameRender = ({ rowData, columnIndex, dataKey, parent, rowIndex }) => {
    return (
      <CellMeasurer cache={cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <span>{rowData.isRoot ? rowData[dataKey] : ''}</span>
      </CellMeasurer>
    );
  };

  const cellRender = ({ rowData, dataKey }) => {
    return <span>{rowData[dataKey]}</span>;
  };

  const allExpandRender = () => {
    const isAllExpand = getAllExpand();
    return (
      <div
        className="full-width flex-row flex-center-align flex-center-justify clickable"
        onClick={() => {
          const newEvents = R.map((item) => ({ ...item, isExpand: !isAllExpand }), getEvents());
          setState({ [getAllExpand(true)]: !isAllExpand, [getEvents(true)]: newEvents });
        }}
      >
        <DoubleLeftOutlined rotate={isAllExpand ? 90 : -90} />
      </div>
    );
  };

  const cellExpandRender = ({ rowData }) => {
    const { isRoot, isExpand } = rowData;
    return (
      isRoot && (
        <div
          className="full-width flex-row flex-center-align flex-center-justify clickable"
          style={{ height: 40 }}
          onClick={() => {
            rowData.isExpand = !rowData.isExpand;
            cellMeasureCache.clearAll();
            if (dataTableNode.current) dataTableNode.current.forceUpdateGrid();
            forceUpdate();
          }}
        >
          {isExpand ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
        </div>
      )
    );
  };

  const cellAllCheckBox = () => {
    return (
      <Checkbox
        size="small"
        checked={getCheckedAll()}
        onChange={(e) => {
          const { checked } = e.target;
          const newEvents = R.map(
            (item) => ({
              ...item,
              instanceGroup: R.map((_item) => ({ ..._item, checked }), item.instanceGroup || []),
            }),
            getEvents(),
          );
          setState({ [getCheckedAll(true)]: checked, [getEvents(true)]: newEvents });
        }}
      />
    );
  };

  const cellCheckBox = ({ cellData, rowData, dataKey }) => {
    return !rowData.isRoot ? (
      <Checkbox
        size="small"
        checked={cellData}
        onChange={(e) => {
          rowData[dataKey] = e.target.checked;

          const instanceGroups = [];
          R.forEach((item) => {
            instanceGroups.push(...(item.instanceGroup || []));
          }, getEvents());
          const newAllChecked = !R.find((item) => !item.checked, instanceGroups);
          setState({ [getCheckedAll(true)]: newAllChecked });

          cellMeasureCache.clearAll();
          if (dataTableNode.current) dataTableNode.current.forceUpdateGrid();
          forceUpdate();
        }}
      />
    ) : (
      <div />
    );
  };

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const sortData = (eventList, sortBy, sortDirection) => {
    let sortList = eventList || [];
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        if (['name'].includes(sortBy)) {
          sortList = R.sortWith([R.descend(R.prop(sortBy))])(sortList);
        } else {
          sortList = R.map(
            (item) => ({ ...item, instanceGroup: R.sortWith([R.descend(R.prop(sortBy))])(item.instanceGroup) }),
            sortList,
          );
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (['name'].includes(sortBy)) {
          sortList = R.sortWith([R.ascend(R.prop(sortBy))])(sortList);
        } else {
          sortList = R.map(
            (item) => ({ ...item, instanceGroup: R.sortWith([R.ascend(R.prop(sortBy))])(item.instanceGroup) }),
            sortList,
          );
        }
      }
    }
    return sortList;
  };

  useEffect(() => {
    setState({ [getEvents(true)]: sortData(getEvents(), sortBy, sortDirection) });
    cellMeasureCache.clearAll();
    if (dataTableNode.current) dataTableNode.current.forceUpdateGrid();
    forceUpdate();
  }, [sortBy, sortDirection, activeKey]);

  const viewMappingRender = () => {
    let dataList = [];
    R.forEach((item) => {
      if (item.isExpand && item.instanceGroup && item.instanceGroup.length > 0) {
        dataList = [...dataList, item, ...item.instanceGroup];
      } else {
        dataList = [...dataList, item];
      }
    }, getEvents());

    let rootTitle = appFieldsMessages.instanceName;
    if (isComponent) {
      rootTitle = eventActionMessages.componentName;
    } else if (isMappingKey) {
      rootTitle = appFieldsMessages.mappingKey;
    }

    return (
      <div className="flex-col full-height" key={activeKey}>
        {!isMappingKey && topEditRender()}
        {isMappingKey && <div style={{ margin: '8px 0' }}>{R.join(', ', incident?.mappingKeysList || [])}</div>}
        <div className="flex-grow">
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={cellMeasureCache.rowHeight}
                rowCount={dataList.length}
                rowGetter={({ index }) => dataList[index]}
                headerClassName="flex-row flex-center-align"
                ref={(c) => {
                  dataTableNode.current = c;
                }}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
              >
                {!isMappingKey && (
                  <Column
                    width={40}
                    label={null}
                    dataKey="checked"
                    disableSort
                    headerRenderer={cellAllCheckBox}
                    cellRenderer={cellCheckBox}
                  />
                )}
                <Column
                  width={100}
                  flexGrow={1}
                  label={intl.formatMessage(rootTitle)}
                  dataKey="name"
                  headerRenderer={headerRenderer}
                  cellRenderer={cellNameRender}
                />
                <Column
                  width={240}
                  label={intl.formatMessage(settingsMessages.baseValueName)}
                  dataKey="metric"
                  headerRenderer={headerRenderer}
                  cellRenderer={cellRender}
                />
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.value)}
                  dataKey="value"
                  headerRenderer={headerRenderer}
                  cellRenderer={cellRender}
                />
                <Column
                  width={40}
                  label={null}
                  dataKey="isExpand"
                  disableSort
                  headerRenderer={allExpandRender}
                  cellRenderer={cellExpandRender}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  };

  const handleGroupSettings = (metricValue) => {
    let list = [];
    R.forEach((item) => {
      list.push(...item.instanceGroup);
    }, getEvents());
    list = R.filter((item) => item.checked, list);

    const data = {};
    R.forEach((item) => {
      const { name, metric } = item;
      if (!data[name]) {
        data[name] = { [metric]: metricValue };
      } else {
        data[name] = { ...data[name], [metric]: metricValue };
      }
    }, list);

    const fetchUrl = `logtometricbasevaluemapping?projectName=${projectName}&type=${activeKey}&metricProjectName=${metricProjectName}`;
    const fd = new FormData();
    fd.append('data', JSON.stringify(data));
    fetchPutForm(getEndpoint(fetchUrl), fd)
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          setState({ showGroupSetting: false });
          getBasevaluemapping();
        } else {
          message.error(msg);
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });
  };

  const handleAddBaseValueMapping = ({ name, baseValues }) => {
    const baseValueMap = {};
    R.forEach((item) => {
      const { metric, value } = item;
      baseValueMap[metric] = value;
    }, baseValues || []);

    const data = { [name]: baseValueMap };

    const fetchUrl = `logtometricbasevaluemapping?projectName=${projectName}&type=${activeKey}&metricProjectName=${metricProjectName}`;
    const fd = new FormData();
    fd.append('data', JSON.stringify(data));
    fetchPutForm(getEndpoint(fetchUrl), fd)
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          setState({ showAddBaseValueMapping: false });
          changeMappingFlag();
          getBasevaluemapping();
        } else {
          message.error(msg);
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });
  };

  const instanceGroups = [];
  R.forEach((item) => {
    instanceGroups.push(...(item.instanceGroup || []));
  }, getEvents());
  const findCheckedDisabled = R.find((item) => item.checked, instanceGroups || []);

  return (
    <>
      <Modal
        title={intl.formatMessage(settingsMessages.updateBaseValueMapping)}
        width={800}
        visible
        bodyStyle={{ height: 600 }}
        onCancel={() => onClose()}
        footer={
          <div>
            {!isMappingKey && (
              <Button
                size="small"
                type="primary"
                disabled={!findCheckedDisabled}
                onClick={() => setState({ showGroupSetting: true })}
              >
                {intl.formatMessage(settingsMessages.groupSettingsUpdate)}
              </Button>
            )}
            <Button size="small" onClick={onClose}>
              {intl.formatMessage(appButtonsMessages.cancel)}
            </Button>
            {/* <Button size="small" type="primary" onClick={handleSumbit}>
            {intl.formatMessage(appButtonsMessages.save)}
          </Button> */}
          </div>
        }
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
          <Tabs
            type="card"
            className="full-width full-height ant-tabs-content-full-height"
            activeKey={activeKey}
            onChange={(activeKey) => setState({ activeKey })}
          >
            <Tabs.TabPane tab={intl.formatMessage(appFieldsMessages.instance)} key="instance">
              {viewMappingRender()}
            </Tabs.TabPane>
            <Tabs.TabPane tab={intl.formatMessage(appFieldsMessages.component)} key="component">
              {viewMappingRender()}
            </Tabs.TabPane>
            <Tabs.TabPane tab={intl.formatMessage(appFieldsMessages.mappingKey)} key="mappingKey">
              {viewMappingRender()}
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </Modal>

      {showGroupSetting && (
        <GroupSettings
          intl={intl}
          onClose={() => setState({ showGroupSetting: false })}
          onSubmit={handleGroupSettings}
        />
      )}

      {showAddBaseValueMapping && (
        <AddBaseValueMapping
          intl={intl}
          activeKey={activeKey}
          events={getEvents()}
          onClose={() => setState({ showAddBaseValueMapping: false })}
          onSubmit={handleAddBaseValueMapping}
        />
      )}
    </>
  );
}

const GroupSettings = ({ onClose, intl, onSubmit }: Object) => {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    baseValue: 0,
  });
  const { baseValue } = state;

  return (
    <Modal
      visible
      width={700}
      onCancel={onClose}
      onOk={() => onSubmit(baseValue)}
      okText={intl.formatMessage(appButtonsMessages.update)}
      title={intl.formatMessage(settingsMessages.groupSettingsUpdate)}
    >
      <div className="flex-row flex-center-align">
        <div style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.value)}:</div>
        <div className="flex-row flex-center-align">
          <InputNumber
            size="small"
            style={{ width: 140 }}
            min={0}
            value={baseValue}
            onChange={(value) => setState({ baseValue: value || 0 })}
          />
        </div>
      </div>
    </Modal>
  );
};

const AddBaseValueMapping = ({ onClose, intl, activeKey, events, onSubmit }: Object) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    name: '',
    baseValues: [],
    nameOptions: [],
  });
  const { name, baseValues, nameOptions } = state;
  const dataTableNode = useRef(null);

  const isInstance = activeKey === 'instance';

  useEffect(() => {
    const names = [];
    R.forEach((item) => {
      names.push({ label: item.name, value: item.name });
    }, events || []);
    setState({ nameOptions: names });
  }, [events]);

  const handleAdd = () => {
    setState({ baseValues: [{ metric: '', value: 0 }, ...baseValues] });
  };

  const handleDelete = (rowIndex) => {
    const newBaseValues = R.addIndex(R.filter)((item, idx) => idx !== rowIndex, baseValues || []);
    setState({ baseValues: newBaseValues });
  };

  const hasEmptyMetric = R.filter((item) => !item.metric, baseValues).length > 0;
  const disabled = !name || baseValues.length === 0 || hasEmptyMetric;

  return (
    <Modal
      visible
      width={700}
      onCancel={onClose}
      onOk={() => onSubmit({ name, baseValues })}
      okText={intl.formatMessage(appButtonsMessages.submit)}
      title={intl.formatMessage(settingsMessages.addBaseValueMapping)}
      okButtonProps={{ disabled }}
    >
      <div className="flex-row flex-center-align">
        <div style={{ marginRight: 8 }}>
          {intl.formatMessage(isInstance ? appFieldsMessages.instanceName : eventActionMessages.componentName)}:
        </div>
        <div className="flex-row flex-center-align">
          <AutoComplete
            size="small"
            allowClear
            options={nameOptions}
            value={name}
            filterOption={(inputValue, option) => option.value.toUpperCase().indexOf((name || '').toUpperCase()) !== -1}
            onChange={(value) => setState({ name: value })}
            style={{ width: 200 }}
            className={`${name ? '' : 'jsonKeyNoneError'}`}
          />
        </div>
      </div>
      <div className="flex-row" style={{ marginTop: 16, marginBottom: 8 }}>
        <Button type="primary" size="small" onClick={handleAdd}>
          {intl.formatMessage(appButtonsMessages.add)}
        </Button>
      </div>
      <Container className="flex-grow flex-col">
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              className="with-border"
              width={width}
              height={300}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={40}
              rowCount={baseValues.length}
              rowGetter={({ index }) => baseValues[index]}
              ref={(c) => {
                dataTableNode.current = c;
              }}
            >
              <Column
                width={150}
                label={intl.formatMessage(settingsMessages.baseValueName)}
                dataKey="metric"
                flexGrow={1}
                cellRenderer={({ cellData, rowData, dataKey }) => {
                  return (
                    <Input
                      size="small"
                      value={cellData}
                      onChange={(e) => {
                        rowData[dataKey] = e.target.value;
                        if (dataTableNode.current) dataTableNode.current.forceUpdateGrid();
                        forceUpdate();
                      }}
                    />
                  );
                }}
              />
              <Column
                width={180}
                label={intl.formatMessage(appFieldsMessages.value)}
                dataKey="value"
                cellRenderer={({ cellData, rowData, dataKey }) => {
                  return (
                    <InputNumber
                      size="small"
                      min={0}
                      value={cellData}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        rowData[dataKey] = value;
                        if (dataTableNode.current) dataTableNode.current.forceUpdateGrid();
                        forceUpdate();
                      }}
                    />
                  );
                }}
              />
              <Column
                width={84}
                label={null}
                dataKey="delete"
                cellRenderer={({ rowIndex }) => {
                  return (
                    <Button
                      size="small"
                      style={{ backgroundColor: 'var(--gray)', color: 'white' }}
                      onClick={() => handleDelete(rowIndex)}
                    >
                      {intl.formatMessage(appButtonsMessages.remove)}
                    </Button>
                  );
                }}
              />
            </Table>
          )}
        </AutoSizer>
      </Container>
    </Modal>
  );
};
