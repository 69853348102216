import React from 'react';
import VLink from 'valuelink';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { Button, message, Popover } from 'antd';

import { buildLocation, buildUrl, parseLocation, Regex } from '../../common/utils';
import { hideAppLoader } from '../../common/app/actions';
import { Input } from '../../lib/fui/react';
import { CenterPage } from '../app/components';
import fetchPost from '../../common/apis/fetchPost';
import getEndpoint from '../../common/apis/getEndpoint';

import { appFieldsMessages } from '../../common/app/messages';
import { authMessages } from '../../common/auth/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  appLoaderVisible: Boolean,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  hideAppLoader: Function,
  currentTheme: String,
};

class AwsLandingPageCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const { amzmrkt } = parseLocation(location);

    this.state = {
      isLoading: false,
      hasError: false,
      errorMessage: null,

      token: R.replace(/\s/g, '%2B', amzmrkt || '') || undefined,
      companyName: '',
      firstName: '',
      lastName: '',
      contactPhone: '',
      appusername: '',
      password: '',
      confirmpassword: '',
      contactEmail: '',

      successLogin: false,

      phoneFocus: false,
      userNameFocus: false,
      passwordFocus: false,
      emailFocus: false,
    };
  }

  componentDidMount() {
    const { replace, location } = this.props;
    const query = parseLocation(location);
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
      replace(
        buildLocation(
          location.pathname,
          {},
          {
            ...query,
            amzmrkt: undefined,
          },
        ),
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleSignUp(event) {
    event.preventDefault();
    const {
      token,
      companyName,
      firstName,
      lastName,
      contactPhone,
      appusername,
      password,
      confirmpassword,
      contactEmail,
    } = this.state;
    this.setState({ isLoading: true, hasError: false, errorMessage: undefined });
    fetchPost(getEndpoint('awssignup', 1), {
      token,
      companyName,
      firstName,
      lastName,
      contactPhone,
      appusername,
      password,
      confirmpassword,
      contactEmail,
    })
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          message.success('Registration successful, please click the login button to jump to the login page');
          this.setState({ isLoading: false, successLogin: true });
        } else {
          this.setState({ isLoading: false, hasError: true, errorMessage: msg });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false, hasError: true, errorMessage: err.message || String(err) });
      });
  }

  render() {
    const { intl, push, currentTheme } = this.props;
    const { isLoading, hasError, errorMessage, successLogin } = this.state;

    const companyNameLink = VLink.state(this, 'companyName').check(
      (value) => value,
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const fnameLink = VLink.state(this, 'firstName').check(
      (value) => value,
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const lnameLink = VLink.state(this, 'lastName').check(
      (value) => value,
      intl.formatMessage(appFieldsMessages.inputRequired),
    );
    const phoneLink = VLink.state(this, 'contactPhone')
      .check((value) => value, intl.formatMessage(appFieldsMessages.inputRequired))
      .check((value) => Regex.phone.test(value), intl.formatMessage(authMessages.errorsPhoneIncorrect));
    const userNameLink = VLink.state(this, 'appusername')
      .check((value) => value, intl.formatMessage(authMessages.errorsUserNameRequired))
      .check((value) => Regex.userName.test(value), intl.formatMessage(authMessages.errorsUserNameContain));
    const password1Link = VLink.state(this, 'password')
      .check((value) => value, intl.formatMessage(authMessages.errorsPasswordRequired))
      .check((value) => value.length >= 8, intl.formatMessage(authMessages.errorsPasswordLength))
      .check((value) => value.match(/[0-9]/g), intl.formatMessage(authMessages.errorsPasswordNumber))
      .check((value) => value.match(/[A-Z]/g), intl.formatMessage(authMessages.errorsPasswordUppercase))
      .check((value) => value.match(/[a-z]/g), intl.formatMessage(authMessages.errorsPasswordLowercase))
      .check(
        (value) => value.match(/[~!@#$%^&*_+?:]/g),
        intl.formatMessage(authMessages.errorsPasswordSpecialCharacters),
      );
    const password2Link = VLink.state(this, 'confirmpassword')
      .check((value) => value, intl.formatMessage(authMessages.errorsPasswordRequired))
      .check((value) => value === this.state.password, intl.formatMessage(authMessages.errorsPasswordNotMatch));
    const emailLink = VLink.state(this, 'contactEmail')
      .check((value) => value, intl.formatMessage(appFieldsMessages.inputRequired))
      .check((value) => Regex.email.test(value), intl.formatMessage(authMessages.errorsEmailIncorrect));
    // .check(
    //   (value) => Regex.emailExcludePerson.test(value),
    //   intl.formatMessage(authMessages.errorsEmailIncorrectPerson),
    // );

    const disabled =
      companyNameLink.error ||
      fnameLink.error ||
      lnameLink.error ||
      phoneLink.error ||
      userNameLink.error ||
      password2Link.error ||
      password1Link.error ||
      password1Link.value !== password2Link.value ||
      emailLink.error;

    return (
      <CenterPage intl={intl} push={push} className="auth" currentTheme={currentTheme}>
        <h3 style={{ textAlign: 'center', marginBottom: 25 }}>Please enter your contact details</h3>

        <form className={`ui ${hasError ? 'error' : ''} form`} style={{ width: 360 }}>
          {hasError && <div className="ui error message">{errorMessage}</div>}

          <div className="input inline field required flex-row flex-center-align">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.companyName)}</label>
            <Input className="flex-grow" valueLink={companyNameLink} style={{ minWidth: 200 }} />
          </div>

          <div className="input inline field required flex-row flex-center-align">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.fname)}</label>
            <Input className="flex-grow" valueLink={fnameLink} style={{ minWidth: 200 }} />
          </div>

          <div className="input inline field required flex-row flex-center-align">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.lname)}</label>
            <Input className="flex-grow" valueLink={lnameLink} style={{ minWidth: 200 }} />
          </div>

          <div className="input inline field required flex-row flex-center-align">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.contactPhone)}</label>
            <Popover
              visible={this.state.phoneFocus}
              placement="right"
              title={null}
              content={
                <div className="flex-col" style={{ maxWidth: 200 }}>
                  Please pick a phone number only contains numbers.
                </div>
              }
            >
              <Input
                className="flex-grow"
                valueLink={phoneLink}
                style={{ minWidth: 200 }}
                onFocus={() => this.setState({ phoneFocus: true })}
                onBlur={() => this.setState({ phoneFocus: false })}
              />
            </Popover>
          </div>

          <div className="input inline field required flex-row flex-center-align aws-input">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.userName)}</label>
            <Popover
              visible={this.state.userNameFocus}
              placement="right"
              title={null}
              content={
                <div className="flex-col" style={{ maxWidth: 360 }}>
                  <div>Please pick a user name that is easy for you to remember.</div>
                  <div>User name should only contain letters and numbers.</div>
                </div>
              }
            >
              <Input
                className="flex-grow"
                valueLink={userNameLink}
                style={{ minWidth: 200 }}
                onFocus={() => this.setState({ userNameFocus: true })}
                onBlur={() => this.setState({ userNameFocus: false })}
              />
            </Popover>
          </div>

          <div className="input inline field required flex-row flex-center-align aws-input">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.password)}</label>
            <Popover
              visible={this.state.passwordFocus}
              placement="right"
              title="Password requirements"
              content={
                <div className="flex-col" style={{ maxWidth: 360 }}>
                  <div>{'Length >=8.'}</div>
                  <div>Contains at least one lower case and one upper case.</div>
                  <div>Contains at least one number and one special symbol (Examples: ~!@#$%^&*_+?:).</div>
                </div>
              }
            >
              <Input
                className="flex-grow"
                type="password"
                valueLink={password1Link}
                style={{ minWidth: 200 }}
                onFocus={() => this.setState({ passwordFocus: true })}
                onBlur={() => this.setState({ passwordFocus: false })}
              />
            </Popover>
          </div>

          <div className="input inline field required flex-row flex-center-align">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.confirmPassword)}</label>
            <Input className="flex-grow" type="password" valueLink={password2Link} style={{ minWidth: 200 }} />
          </div>

          <div className="input inline field required flex-row flex-center-align aws-input">
            <label style={{ width: 120, margin: 0 }}>{intl.formatMessage(appFieldsMessages.emailAddress)}</label>
            <Popover
              visible={this.state.emailFocus}
              placement="right"
              title={null}
              content={
                <div className="flex-col" style={{ maxWidth: 200 }}>
                  Please use your company email address in your trial request.
                </div>
              }
            >
              <Input
                className="flex-grow"
                valueLink={emailLink}
                style={{ minWidth: 200 }}
                onFocus={() => this.setState({ emailFocus: true })}
                onBlur={() => this.setState({ emailFocus: false })}
              />
            </Popover>
          </div>

          {!successLogin && (
            <div className="field flex-row">
              <Button
                type="primary"
                loading={isLoading}
                disabled={disabled}
                onClick={this.handleSignUp}
                style={{ width: '100%' }}
              >
                {intl.formatMessage(authMessages.register)}
              </Button>
            </div>
          )}

          {successLogin && (
            <div className="field flex-row">
              <Button
                type="primary"
                loading={isLoading}
                disabled={disabled}
                onClick={() => {
                  push(buildUrl('/auth/login2', {}, {}));
                }}
                style={{ width: '100%' }}
              >
                {intl.formatMessage(authMessages.login)}
              </Button>
            </div>
          )}
        </form>
      </CenterPage>
    );
  }
}

const AwsLandingPage = injectIntl(AwsLandingPageCore);
export default connect(
  (state) => {
    const { location } = state.router;
    return {
      location,
      appLoaderVisible: state.app.appLoaderVisible,
      currentTheme: state.app.currentTheme,
    };
  },
  { push, replace, hideAppLoader },
)(AwsLandingPage);
