/* @flow */
import { BaseError } from 'make-error';

class BadRequestError extends BaseError {
  constructor(message?: string, code?: number, response?: Object, responseJson?: Object) {
    super(message);
    this.code = code;
    this.response = response;
    this.responseJson = responseJson;
  }
}

export default BadRequestError;
