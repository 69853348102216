/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import { ActionTypes } from './actions';

const initialState = {
  logReportData: {},
};

const reducer = (state = initialState, action) => {
  if (action.type === ActionTypes.SET_REPORT_LOG) {
    const { data } = action.payload;
    return { ...state, logReportData: data };
  } else if (action.type === ActionTypes.SET_REPORT_METRIC) {
    const { data } = action.payload;
    return { ...state, metricReportData: data };
  }
  return { ...initialState, ...state };
};

export default reducer;
