/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { updateLastActionInfo, showAppAlert, setInitData } from '../../app/actions';
import { ActionTypes } from '../actions';
import { getLoadStatusActions } from '../../utils';
import { deleteProject, getInitData } from '../../apis';
import { appMessages } from '../../app/messages';
import { settingsMessages } from '../messages';

const removeProjectLogic = createLogic({
  type: [ActionTypes.REMOVE_PROJECT],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials, userInfo } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    const { operation } = params;
    let message;
    switch (operation) {
      case 'Trash':
        message = settingsMessages.infoProjectlMoveToTrash;
        break;
      case 'Recover':
        message = settingsMessages.infoProjectlRecovered;
        break;
      default:
        message = settingsMessages.infoProjectlRemoved;
        break;
    }

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    deleteProject(credentials, params)
      .then(() => {
        getInitData(credentials, userInfo)
          .then((data) => {
            dispatch(setInitData(data));
            dispatch(showAppAlert('info', message));
          })
          .catch((err) => {
            dispatch(errorLoading(message, { message: err.message }));
          })
          .then(() => {
            dispatch(hideLoading);
            done();
          });
      })
      .catch((err) => {
        // Ignore the error, and use empty environment list
        console.error('[IF_API] Failed delete projecr, ignored', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
        dispatch(hideLoading);
        done();
      });
  },
});

export default removeProjectLogic;
