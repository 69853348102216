/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get, isNumber } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';

import { State } from '../../common/types';
import { Defaults } from '../../common/utils';
import { Defaults as DefaultsApp } from '../../common/app';
import { createLoadAction } from '../../common/app/actions';
import { appFieldsMessages } from '../../common/app/messages';
import { DashboardMessages } from '../../common/dashboard/messages';
import { capacityPlanningMessages } from '../../common/metric/messages';

type Props = {
  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line

  width: Number,
  height: Number,
  rowIndex: Number,
  metric: String,
  capacityInfos: Array<Object>,
  isAllMetric: Boolean,
  selectComponent: String,
};

class CapacityPlanningTableCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = 100;
    this.listHeaderHeightPercent = '90%';
    this.headerBorder = '1px solid #a5c2ce';
    this.expandKey = '__event_expand_key';
    this.expandKeyDefault = true;

    this.state = {
      eventList: [],
      expandRows: {},
    };
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.capacityInfos !== nextProps.capacityInfos) {
      this.parseData(nextProps);
    }
  }

  @autobind
  parseData(props) {
    const eventList = get(props, 'capacityInfos', []);
    this.setState({ eventList });
  }

  @autobind
  renderRow({ key, index: rowIndex, ev: rowData }) {
    const { selectComponent } = this.props;
    if (!rowData) return null;
    const { intl, isAllMetric } = this.props;
    const isExpand = get(rowData, this.expandKey, this.expandKeyDefault);
    const componentInfos = get(rowData, 'componentInfos', []);
    const rowProps = ({ dataKey, ...rest }) => {
      return { rowData, rowIndex, dataKey, cellData: get(rowData, dataKey), ...rest };
    };

    let expandContent = null;
    if (isExpand) {
      expandContent = this.getExpandContent({ events: componentInfos });
    }

    return (
      <div key={key}>
        {!selectComponent && (
          <div
            className={`event-list-row${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
            style={{ borderColor: 'rgba(0, 0, 0, 0.12)', cursor: 'pointer' }}
            onClick={this.handleExpand({ rowData })}
          >
            <div className="row-column" style={{ width: 24 }}>
              <i
                className={`icon angle ${isExpand ? 'down' : 'right'}`}
                style={{ cursor: 'pointer' }}
                onClick={this.handleExpand({ rowData }, true)}
              />
            </div>
            <div className="row-column" style={{ width: 200 }}>
              {this.nameContent({ ...rowProps({ dataKey: 'id', type: 'system' }) })}
            </div>
            <div className="row-column" style={{ width: 80 }}>
              {this.textContent({ ...rowProps({ dataKey: 'actualInstances' }) })}
            </div>
            <div className="row-column" style={{ width: 90 }}>
              {this.capacityScoreContent({ ...rowProps({ dataKey: 'capacityScore' }) })}
            </div>
            {!isAllMetric && (
              <div className="row-column" style={{ width: 92 }}>
                {this.textContent({ ...rowProps({ dataKey: 'realAvg' }) })}
              </div>
            )}
            {!isAllMetric && (
              <div className="row-column" style={{ width: 80 }}>
                {this.textContent({ ...rowProps({ dataKey: 'capacityThreshold' }) })}
              </div>
            )}
            {!isAllMetric && (
              <div className="row-column" style={{ width: 184 }}>
                {this.textContent({ ...rowProps({ dataKey: 'predictAvg' }) })}
              </div>
            )}
            <div className="row-column" style={{ flex: 1 }}>
              {this.instancesChangeContent({ ...rowProps({ dataKey: 'targetAvgInstancesL1' }) })}
            </div>
            <div className="row-column" style={{ flex: 1 }}>
              {this.instancesChangeContent({ ...rowProps({ dataKey: 'targetAvgInstancesL2' }) })}
            </div>
            <div className="row-column" style={{ flex: 1 }}>
              {this.instancesChangeContent({ ...rowProps({ dataKey: 'targetMaxInstancesL1' }) })}
            </div>
            <div className="row-column" style={{ flex: 1 }}>
              {this.instancesChangeContent({ ...rowProps({ dataKey: 'targetMaxInstancesL2' }) })}
            </div>
          </div>
        )}
        {expandContent}
      </div>
    );
  }
  @autobind
  handleExpand({ rowData }, stop) {
    return (e) => {
      e.preventDefault();
      if (stop) {
        e.stopPropagation();
      }

      const { expandRows } = this.state;
      if (rowData) {
        const isExpand = get(rowData, this.expandKey, this.expandKeyDefault);
        rowData[this.expandKey] = !isExpand;
        this.setState({ expandRows: { ...expandRows, [rowData.id]: !isExpand } });
      }
    };
  }
  @autobind
  getExpandContent({ events }) {
    const { intl, isAllMetric, metric, selectComponent } = this.props;
    if (isAllMetric) {
      const prioritySort = R.sortWith([R.descend(R.prop('priority')), R.descend(R.prop('capacityScore'))]);
      events = prioritySort(events);
    }
    return (
      <div>
        {R.addIndex(R.map)((t, idx) => {
          const rowData = t;
          const rowProps = ({ dataKey, ...rest }) => {
            return { rowData, rowIndex: idx, dataKey, cellData: get(rowData, dataKey), ...rest };
          };
          return (
            <div
              key={idx}
              className={`event-list-row${idx % 2 === 1 ? ' odd-row' : ''}`}
              style={{ borderColor: 'rgba(0, 0, 0, 0.12)' }}
              onClick={this.handleExpand({ rowData })}
            >
              <div className="row-column" style={{ width: 24 }} />
              <div className="row-column" style={{ width: 200 }}>
                {!selectComponent && this.nameContent({ ...rowProps({ dataKey: 'id', spaces: 4, type: 'component' }) })}
                {selectComponent && (
                  <div>
                    <span>{metric}</span>
                  </div>
                )}
              </div>
              <div className="row-column" style={{ width: 80 }}>
                {this.textContent({ ...rowProps({ dataKey: 'actualInstances' }) })}
              </div>
              <div className="row-column" style={{ width: 90 }}>
                {this.capacityScoreContent({ ...rowProps({ dataKey: 'capacityScore' }) })}
              </div>
              {!isAllMetric && (
                <div className="row-column" style={{ width: 92 }}>
                  {this.textContent({ ...rowProps({ dataKey: 'realAvg' }) })}
                </div>
              )}
              {!isAllMetric && (
                <div className="row-column" style={{ width: 80 }}>
                  {this.textContent({ ...rowProps({ dataKey: 'capacityThreshold' }) })}
                </div>
              )}
              {!isAllMetric && (
                <div className="row-column" style={{ width: 184 }}>
                  {this.textContent({ ...rowProps({ dataKey: 'predictAvg' }) })}
                </div>
              )}
              <div className="row-column" style={{ flex: 1 }}>
                {this.instancesChangeContent({ ...rowProps({ dataKey: 'targetAvgInstancesL1' }) })}
              </div>
              <div className="row-column" style={{ flex: 1 }}>
                {this.instancesChangeContent({ ...rowProps({ dataKey: 'targetAvgInstancesL2' }) })}
              </div>
              <div className="row-column" style={{ flex: 1 }}>
                {this.instancesChangeContent({ ...rowProps({ dataKey: 'targetMaxInstancesL1' }) })}
              </div>
              <div className="row-column" style={{ flex: 1 }}>
                {this.instancesChangeContent({ ...rowProps({ dataKey: 'targetMaxInstancesL2' }) })}
              </div>
            </div>
          );
        }, events)}
      </div>
    );
  }
  @autobind
  textContent({ cellData }) {
    const message = isNumber(cellData) ? cellData : cellData || '';
    return <div style={{}}>{message}</div>;
  }
  @autobind
  capacityScoreContent({ cellData }) {
    const { intl } = this.props;
    let message = cellData || '';
    let color = 'black';
    if (isNumber(cellData)) {
      if (cellData >= 50) {
        message = `${cellData}% ${intl.formatMessage(capacityPlanningMessages.full)}`;
        color = 'red';
      } else {
        message = `${cellData}%`;
      }
    }
    return <div style={{ color }}>{message}</div>;
  }
  @autobind
  nameContent({ rowData, type, spaces }) {
    const { name, priority, metric } = rowData;
    const { intl } = this.props;
    const priorityMap = {
      '0': intl.formatMessage(DashboardMessages.ignored),
      '1': intl.formatMessage(DashboardMessages.low),
      '2': intl.formatMessage(DashboardMessages.mediumLow),
      '3': intl.formatMessage(DashboardMessages.medium),
      '4': intl.formatMessage(DashboardMessages.mediumHigh),
      '5': intl.formatMessage(DashboardMessages.high),
    };
    if (type === 'metric') {
      return (
        <div>
          <span>{metric}</span>
        </div>
      );
    } else if (type === 'component') {
      return (
        <div className="flex-col">
          <span style={{ color: 'blue' }}>
            {intl.formatMessage(DashboardMessages.priority)}: {priorityMap[priority]}
          </span>
          <div className="flex-row">
            <span
              style={{
                minWidth: 14,
                display: 'inline-block',
                background: 'grey',
                color: 'white',
                textAlign: 'center',
                marginRight: 4,
              }}
            >
              C
            </span>
            <span>{name}</span>
          </div>
        </div>
      );
    }

    return (
      <div style={{}}>
        {spaces ? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : ''}
        {name}
      </div>
    );
  }
  @autobind
  instancesChangeContent({ cellData, rowData }) {
    const { actualInstances } = rowData;
    const instances = cellData ? Number(cellData) : 0;
    const oldInstances = R.min(instances, actualInstances);

    const needItem = instances - actualInstances;
    const needItemType = needItem >= 0 ? 'add' : 'remove';
    const fontColor = needItem < 0 ? '#6EA657' : needItem > 0 ? '#C7192B' : 'black';
    return (
      <div className="flex-col" style={{ padding: '6px 0', width: '100%' }}>
        <div className="flex-grow flex-row" style={{ minHeight: 32, flexWrap: 'wrap' }}>
          {oldInstances > 0 &&
            R.map((idx) => {
              return this.getItemContent({ type: 'normal', idx });
            }, R.range(0, oldInstances))}
          {Math.abs(needItem) > 0 &&
            R.map((idx) => {
              return this.getItemContent({ type: needItemType, idx });
            }, R.range(0, Math.abs(needItem)))}
        </div>
        <div
          className="flex-row"
          style={{
            justifyContent: 'space-between',
            fontWeight: 500,
            fontSize: 14,
          }}
        >
          <div>{instances}</div>
          <div style={{ color: fontColor }}>
            {needItem > 0 ? '+' : ''}
            {needItem}
          </div>
        </div>
      </div>
    );
  }
  @autobind
  getItemContent({ type, idx }) {
    const itemSize = 14;
    if (type === 'add') {
      return (
        <div
          key={idx}
          style={{
            width: itemSize,
            height: itemSize,
            margin: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#F06E50',
            borderRadius: '0.14rem',
            color: 'white',
          }}
        >
          +
        </div>
      );
    } else if (type === 'remove') {
      return (
        <div
          key={idx}
          style={{
            width: itemSize,
            height: itemSize,
            margin: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#B4DD92',
            borderRadius: '0.14rem',
          }}
        >
          -
        </div>
      );
    }
    return (
      <div
        key={idx}
        style={{
          width: itemSize,
          height: itemSize,
          margin: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: DefaultsApp.Colorbrewer[9],
          borderRadius: '0.14rem',
        }}
      />
    );
  }

  @autobind
  filterComponents(eventList) {
    const { selectComponent } = this.props;
    if (selectComponent === 'All') return eventList;
    const newEventList = R.clone(eventList);
    const selectInfos = [];
    const componentInfos = get(eventList[0], 'componentInfos', []);
    R.forEach((component) => {
      if (selectComponent && component.name === selectComponent) {
        selectInfos.push(component);
      }
    }, componentInfos);
    if (newEventList.length > 0 && newEventList[0].componentInfos) newEventList[0].componentInfos = selectInfos;
    return newEventList;
  }

  render() {
    const { rowIndex, selectComponent } = this.props;
    let { eventList } = this.state;
    if (selectComponent) eventList = this.filterComponents(eventList);

    return (
      <div className="event-list">
        <div className={`event-list-grid flex-col${rowIndex % 2 === 1 ? ' list-row-striped' : ''}`}>
          {R.addIndex(R.map)((ev, idx) => this.renderRow({ key: idx, index: idx, ev }), eventList)}
        </div>
      </div>
    );
  }
}

const CapacityPlanningTable = injectIntl(CapacityPlanningTableCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;

    return { location, loadStatus };
  },
  { push, replace, createLoadAction },
)(CapacityPlanningTable);
