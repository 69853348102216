import React from 'react';
import { Form, Input, Radio, Space } from 'antd';
import {} from '@ant-design/icons';
import OperationButtons from './OperationButtons';
// eslint-disable-next-line import/no-cycle

const { Item } = Form;

type Props = {
  onCancel: Function,
  onNext: Function,
  onPrev: Function,
  onSetPageData: Function,
  pageData: Object,
};

const Step2 = (props: Props) => {
  const { onNext, onPrev, onSetPageData, pageData, onCancel } = props;

  const [form] = Form.useForm();

  const messageVariables = (msg) => `${msg} is required`;

  const handleNext = async () => {
    const requestData = await form.validateFields();
    onSetPageData({ ...requestData });
    onNext();
  };

  return (
    <div className="batch-step-content batch-properties">
      <Form form={form} size="small" layout="vertical" initialValues={pageData} style={{ flex: 1 }}>
        <Item
          label="Batch configuration name"
          required
          name="batchName"
          wrapperCol={{ span: 6 }}
          validateFirst
          rules={[
            {
              required: true,
              message: messageVariables('Batch configuration name'),
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          label="Data type"
          required
          name="dataType"
          validateFirst
          rules={[
            {
              required: true,
              message: messageVariables('Data type'),
            },
          ]}
        >
          <Radio.Group value={2}>
            <Space direction="vertical" size={20}>
              <Radio value="Alert">Alert</Radio>
              <Radio value="Deployment">Change Event</Radio>
              <Radio value="Incident">Incident</Radio>
              <Radio value="Log">Log</Radio>
              <Radio value="Metric">Metric</Radio>
            </Space>
          </Radio.Group>
        </Item>
      </Form>
      <OperationButtons onNext={handleNext} onPrev={onPrev} onCancel={onCancel} hidePrev />
    </div>
  );
};

export default Step2;
