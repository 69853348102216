/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Alert, Form, Select, Input, TreeSelect, Checkbox, Divider, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Container, Popover } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

function parseArrayToTree(arr) {
  const root = {};
  for (const str of arr) {
    const parts = str?.split('.') || [];
    let node = root;
    for (const part of parts) {
      if (!node[part]) {
        node[part] = [];
      }
      node = node[part];
    }
  }
  return root;
}

function treeToJSON(tree, parentKey, isMounte) {
  const keys = Object.keys(tree);
  if (keys.length === 0) {
    return null;
  }
  const arr = [];
  for (const key of keys) {
    parentKey = `${parentKey}${isMounte ? ':' : '.'}${key}`;

    const children = treeToJSON(tree[key], parentKey);
    arr.push({ label: key, value: parentKey, children });
  }
  return arr;
}

const recursiveUpdateValue = (node, parentLabel, isMounte) => {
  const { label } = node;
  const updatedValue = parentLabel && isMounte ? `${parentLabel}:${label}` : `${parentLabel}.${label}`;
  node.value = updatedValue;
  if (node.children) {
    node.children.forEach((child) => recursiveUpdateValue(child, updatedValue));
  } else {
    node.label = updatedValue;
  }
};

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

class EndpointSettingDynatraceCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { dataType } = props;
    this.state = {
      isVerifying: false,
      verified: true,
      isLoading: false,
      hasError: false,
      errorMessage: '',

      envId: '',
      token: '',
      zoneId: undefined,
      dataType,

      // metric
      metrics: [],
      kpiMetrics: [],

      instanceKey: '',
      timestampKey: '',

      metricOptions: [],
      dynatraceKeysOptions: [],

      hosts: [],
      hostsOption: [],
      zoneOption: [],

      applicationId: undefined,
      applicationOption: [],

      searchValue: '',
    };

    this.samplingUnitOption = [
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
    this.localMetrics = [];
    this.filterMetrics = [];

    this.localKpiMetrics = [];

    this.filterZoneOption = [];

    this.filterApplicationOption = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName, currentProject, dataType } = props;
    const cloudType = get(currentProject, ['cloudType']);

    this.setState({ isLoading: true });

    const request = [
      fetchGet(getEndpoint('thirdpartysetting', 2), {
        ...credentials,
        projectName,
        cloudType,
      }).then((data) => {
        const { success } = data || {};
        if (success === undefined || success) {
          return data;
        } else {
          return {};
        }
      }),

      fetchGet(getEndpoint('dynatrace-setting'), {
        ...credentials,
        projectName,
      }).then((data) => {
        const { success } = data || {};
        if (success === undefined || success) {
          return data;
        } else {
          return {};
        }
      }),
    ];
    Promise.all(request)
      .then((results) => {
        let data1 = results[0] || {};
        const data2 = results[1] || {};
        const { metrics, hosts } = data2;

        data1 = R.mapObjIndexed((value, key) => {
          try {
            if (key !== 'zoneId') {
              value = JSON.parse(value);
            }
          } catch (error) {}
          return value;
        }, data1);

        if (dataType === 'metric') {
          this.localMetrics = metrics || [];
          this.parseDataMetric(this.localMetrics);
        } else {
          this.parseDataLog(data2);
        }

        const hostsOption = R.map((item) => ({ label: item.hostName, value: item.hostId }), hosts || []);

        const zoneOption = R.map((item) => ({ label: item.name, value: String(item.id) }), data1?.zones || []);
        this.filterZoneOption = zoneOption;

        const applicationOption = R.map(
          (item) => ({ label: item.entityName, value: item.entityId }),
          data1?.applicationIds || [],
        );
        this.filterApplicationOption = applicationOption;

        this.localEnvId = data1.envId;
        this.localToken = data1.token;

        this.setState({
          ...data1,
          instanceKey: data1?.instanceKey ? data1?.instanceKey[0] : '',
          timestampKey: data1?.timestampKey ? data1?.timestampKey[0] : '',
          hostsOption,
          zoneOption,
          applicationOption,
          isLoading: false,
          hasError: false,
          errorMessage: '',
        });
      })
      .catch((err) => {
        console.error(err);
        this.filterZoneOption = [];
        this.filterApplicationOption = [];
        this.setState({
          hosts: [],
          hostsOption: [],
          zoneOption: [],
          applicationOption: [],
          isLoading: false,
          hasError: true,
          errorMessage: String(err),
        });
      });

    // dynatrace-setting?projectName=APPLICATION-EA7C4B59F27D43EB-Metric-kuPk@xiangkui
  }

  @autobind
  parseDataLog(data) {
    this.setState({ dynatraceKeysOptions: R.map((item) => ({ label: item, value: item }), data?.info || []) || [] });
  }

  @autobind
  parseDataMetric(data) {
    const treeData = R.groupBy((item) => {
      if (item.metricId.indexOf(':') >= 0) {
        return item.metricId.split(':')[0];
      } else {
        return 'unknow';
      }
    }, data);

    const treeDataNew = [];
    R.addIndex(R.forEachObjIndexed)((value, metricName, obj, idx) => {
      value = R.addIndex(R.map)((item, index) => ({ label: item.metricId, value: item.metricId }), value);
      const metricNames = R.map((item) => {
        return item.value?.indexOf(':') >= 0 ? item.value.split(':')[1] : item.value;
      }, value);
      const tree = parseArrayToTree(metricNames);
      const JSONtree = treeToJSON(tree);

      R.forEach((node) => {
        recursiveUpdateValue(node, metricName, true);
      }, JSONtree);
      treeDataNew.push({ label: metricName, value: metricName, children: JSONtree });
    }, treeData);
    this.filterMetrics = data;
    this.setState({
      metricOptions: treeDataNew,
    });
  }

  @autobind
  handleConfirmClick() {
    const { intl, credentials, currentProject } = this.props;
    const { envId, token, zoneId, dataType, metrics, kpiMetrics, instanceKey, timestampKey, applicationId, hosts } =
      this.state;

    const { projectName } = currentProject;

    Promise.all([
      fetchPost(getEndpoint('dynatrace-setting', 1), {
        ...credentials,
        envId: String(envId),
        token,
        zoneId: String(zoneId),
        projectName,
        hosts: JSON.stringify(hosts),
        metrics: JSON.stringify(metrics),
        kpiMetrics: JSON.stringify(kpiMetrics),
        instanceKey: JSON.stringify(!instanceKey ? [] : [instanceKey]),
        timestampKey: JSON.stringify(!timestampKey ? [] : [timestampKey]),
        applicationId,
      }),
    ])
      .then((data) => {
        this.setState({
          isLoading: false,
          hasError: false,
          errorMessage: '',
        });
        message.success(intl.formatMessage(appMessages.apiSuccess));
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          hasError: true,
          errorMessage: String(err),
        });
      });
  }

  @autobind
  handleVerifyClick() {
    const { credentials, currentProject, dataType } = this.props;
    const { envId, token, zoneId, applicationId } = this.state;

    const { projectName, cloudType } = currentProject;

    this.setState({ isVerifying: true });
    fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      envId: String(envId),
      token,
      zoneId: String(zoneId),
      projectName,
      cloudType,
      applicationId,
    })
      .then((res) => {
        const { success, message } = res;
        if (success || success === undefined) {
          Promise.all([
            fetchGet(getEndpoint('thirdpartysetting', 2), {
              ...credentials,
              projectName,
              cloudType,
            }),
            fetchGet(getEndpoint('dynatrace-setting'), {
              ...credentials,
              projectName,
              dataType,
            }),
          ])
            .then((data) => {
              const [data1, data2] = data;
              const { zones, applicationIds } = data1 || {};
              const { hosts } = data2 || {};
              const success =
                data1.success === undefined || data1.success || data2.success === undefined || data2.success;
              if (success) {
                if (dataType === 'metric') {
                  this.localMetrics = data2?.metrics || [];
                  this.parseDataMetric(this.localMetrics);
                } else {
                  this.parseDataLog(data2);
                }

                const hostsOption = R.map((item) => ({ label: item.hostName, value: item.hostId }), hosts || []);

                const zoneOption = R.map((item) => ({ label: item.name, value: String(item.id) }), zones || []);
                this.filterZoneOption = zoneOption;

                const applicationOption = R.map(
                  (item) => ({ label: item.entityName, value: item.entityId }),
                  applicationIds || [],
                );
                this.filterApplicationOption = applicationOption;

                this.setState({
                  hostsOption,
                  zoneOption,
                  applicationOption,
                  isVerifying: false,
                  verified: true,
                });
              } else {
                this.filterZoneOption = [];
                this.filterApplicationOption = [];
                this.setState({
                  isVerifying: false,
                  verified: false,
                  hasError: true,
                  errorMessage: data1.message || data2.message,
                });
              }
            })
            .catch((err) => {
              this.setState({ isVerifying: false });
            });
          this.localEnvId = envId;
          this.localToken = token;
          this.setState({ verified: true, hasError: false });
        } else {
          this.setState({
            isVerifying: false,
            verified: false,
            hasError: true,
            errorMessage: message,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isVerifying: false,
          hasError: true,
          verified: false,
          errorMessage: err,
        });
      });
  }

  @autobind
  getListOptionValues(option) {
    return R.map((item) => item.value, option);
  }

  @autobind
  getFilterValue({ searchValue, options, type = 'label' }) {
    return R.filter((item) => item[type].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1, options);
  }

  @autobind
  isCheckedAll({ searchValue, options, valueList }) {
    const filterValue = this.getListOptionValues(this.getFilterValue({ searchValue, options }));
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  }

  render() {
    const { intl, currentProject, isBatch } = this.props;
    const {
      isVerifying,
      verified,
      isLoading,
      hasError,
      errorMessage,
      envId,
      token,
      zoneId,

      metrics,
      kpiMetrics,

      metricOptions,
      instanceKey,
      timestampKey,
      dynatraceKeysOptions,

      hostsOption,
      zoneOption,
      hosts,

      applicationOption,
      applicationId,
      searchValue,
    } = this.state;

    const { isMetric } = currentProject;

    const hasVerifyError = !envId || !token;
    const hasErrorRegister = hasVerifyError || !verified || this.localEnvId !== envId || this.localToken !== token;
    return (
      <Container fullHeight className={`overflow-y-auto ${isLoading ? ' loading' : ''}`}>
        {hasError && (
          <Alert message="Error" description={errorMessage} type="error" showIcon style={{ marginBottom: 16 }} />
        )}

        <Form layout="vertical">
          <Form.Item
            label="Environment id"
            validateStatus={!envId ? 'error' : 'success'}
            help={!envId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input.Password
              value={envId}
              onChange={(e) => this.setState({ envId: e.target.value })}
              visibilityToggle={false}
            />
          </Form.Item>
          <Form.Item
            label="Token"
            validateStatus={!token ? 'error' : 'success'}
            help={!token ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input.Password
              value={token}
              onChange={(e) => this.setState({ token: e.target.value })}
              visibilityToggle={false}
            />
          </Form.Item>
          <Form.Item label="Zone">
            <Select
              showSearch
              allowClear
              value={zoneId}
              optionFilterProp="children"
              style={{ width: '100%' }}
              onChange={(zoneId) => this.setState({ zoneId, applicationId: undefined })}
              disabled={applicationId}
            >
              {R.map((item) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              }, zoneOption)}
            </Select>
          </Form.Item>
          <Form.Item label="Application">
            <Select
              showSearch
              allowClear
              value={applicationId}
              optionFilterProp="children"
              style={{ width: '100%' }}
              onChange={(applicationId) => this.setState({ applicationId, zoneId: undefined })}
              disabled={zoneId}
            >
              {R.map((item) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              }, applicationOption)}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.handleVerifyClick} loading={isVerifying} disabled={hasVerifyError}>
              Verify
            </Button>
          </Form.Item>

          {isMetric && (
            <>
              <Form.Item label="Metrics">
                <TreeSelect
                  className="no-count-num"
                  treeDefaultExpandAll
                  autoClearSearchValue={false}
                  treeData={metricOptions}
                  showSearch
                  multiple
                  treeCheckable
                  value={metrics}
                  onChange={(metrics) => this.setState({ metrics })}
                  showCheckedStrategy={TreeSelect.SHOW_CHILD}
                  style={{ width: '100%' }}
                  onSearch={(searchValue) => {
                    const newMetrics = searchValue
                      ? this.getFilterValue({ searchValue, options: this.localMetrics, type: 'metricId' })
                      : this.localMetrics;
                    this.parseDataMetric(newMetrics);
                  }}
                  dropdownRender={(menu) => {
                    return (
                      <div>
                        <div
                          className="flex-row"
                          style={{ padding: '5px 12px' }}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              R.difference(
                                R.map((item) => item.metricId, this.filterMetrics),
                                metrics,
                              ).length === 0
                            }
                            onChange={(e) => {
                              const { checked } = e.target;
                              const filterValue = R.map((item) => item.metricId, this.filterMetrics);
                              const diff = R.difference(metrics, filterValue);
                              this.setState({ metrics: checked ? [...diff, ...filterValue] : diff });
                            }}
                          />
                          Select all
                        </div>
                        <Divider style={{ margin: '4px 0' }} />
                        {menu}
                      </div>
                    );
                  }}
                />
              </Form.Item>

              <Form.Item label="Kpi metrics">
                <TreeSelect
                  className="no-count-num"
                  treeDefaultExpandAll
                  autoClearSearchValue={false}
                  treeData={metricOptions}
                  showSearch
                  multiple
                  treeCheckable
                  value={kpiMetrics}
                  onChange={(kpiMetrics) => this.setState({ kpiMetrics })}
                  showCheckedStrategy={TreeSelect.SHOW_CHILD}
                  style={{ width: '100%' }}
                  onSearch={(searchValue) => {
                    const newMetrics = searchValue
                      ? this.getFilterValue({ searchValue, options: this.localMetrics, type: 'metricId' })
                      : this.localMetrics;
                    this.parseDataMetric(newMetrics);
                  }}
                  dropdownRender={(menu) => {
                    return (
                      <div>
                        <div
                          className="flex-row"
                          style={{ padding: '5px 12px' }}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              R.difference(
                                R.map((item) => item.metricId, this.filterMetrics),
                                kpiMetrics,
                              ).length === 0
                            }
                            onChange={(e) => {
                              const { checked } = e.target;
                              const filterValue = R.map((item) => item.metricId, this.filterMetrics);
                              const diff = R.difference(kpiMetrics, filterValue);
                              this.setState({ kpiMetrics: checked ? [...diff, ...filterValue] : diff });
                            }}
                          />
                          Select all
                        </div>
                        <Divider style={{ margin: '4px 0' }} />
                        {menu}
                      </div>
                    );
                  }}
                />
              </Form.Item>
            </>
          )}

          {!isMetric && (
            <>
              <Form.Item label="Instance key">
                <Select
                  allowClear
                  showSearch
                  value={instanceKey}
                  onChange={(instanceKey) => this.setState({ instanceKey })}
                  options={dynatraceKeysOptions}
                />
              </Form.Item>
              <Form.Item label="Timestamp key">
                <Select
                  allowClear
                  showSearch
                  value={timestampKey}
                  onChange={(timestampKey) => this.setState({ timestampKey })}
                  options={dynatraceKeysOptions}
                />
              </Form.Item>
            </>
          )}

          <Form.Item label="Hosts">
            <Select
              className="no-count-num"
              style={{ width: '100%' }}
              showSearch
              mode="multiple"
              value={hosts}
              autoClearSearchValue={false}
              optionFilterProp="children"
              onChange={(hosts) => this.setState({ hosts })}
              onDropdownVisibleChange={(open) => {
                if (!open) {
                  this.setState({ searchValue: '' });
                }
              }}
              dropdownRender={(menu) => {
                return (
                  <div>
                    <div
                      className="flex-row"
                      style={{ padding: '5px 12px' }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={this.isCheckedAll({ searchValue, options: hostsOption, valueList: hosts })}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const filterValue = this.getListOptionValues(
                            this.getFilterValue({ searchValue, options: hostsOption }),
                          );
                          const diff = R.difference(hosts, filterValue);
                          this.setState({ hosts: checked ? [...diff, ...filterValue] : diff });
                        }}
                      />
                      <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                    </div>
                    <Divider style={{ margin: '4px 0' }} />
                    {menu}
                  </div>
                );
              }}
              onSearch={(searchValue) => this.setState({ searchValue })}
            >
              {R.map((item) => {
                return (
                  <Select.Option className="hide-icon" key={item.value} value={item.value}>
                    <Checkbox checked={hosts.includes(item.value)} onChange={(e) => null} style={{ marginRight: 8 }} />
                    {item.label}
                  </Select.Option>
                );
              }, hostsOption)}
            </Select>
          </Form.Item>
        </Form>
        <Button type="primary" disabled={hasErrorRegister} onClick={this.handleConfirmClick} loading={isLoading}>
          {intl.formatMessage(appButtonsMessages.update)}
        </Button>
      </Container>
    );
  }
}

const EndpointSettingDynatrace = injectIntl(EndpointSettingDynatraceCore);
export default connect((state) => {
  return {};
}, {})(EndpointSettingDynatrace);
