/* @flow */
import fetchPostJsonHandler from './fetchPostJsonHandler';
import momenttz from 'moment-timezone';
import { buildUrl } from '../utils';

/**
 * Use fetch to send POST method with JSON object as body
 * The content type of the sending data is application/x-www-from-urlencode.
 *
 * @param {string} url The url of the api
 * @param {object} params The url parameters
 * @param {object} data The data to post.
 */
const fetchPostJson = (url: string, params: Object, data: Object, extraHeaders: Object = {}) => {
  // parse time zone
  const zone = momenttz.tz(momenttz.tz.guess());
  const tzOffset = zone.utcOffset() * 60 * 1000;
  params.tzOffset = tzOffset;

  // Get X-CSRF-Token in localStorage
  const token = window.localStorage.getItem('X-CSRF-Token');

  // remove userName
  if (params.userName) {
    params.userName = undefined;
  }
  // set userName by UserName if it is necessary
  if (params.UserName) {
    params.userName = params.UserName;
    params.UserName = undefined;
  }

  return fetchPostJsonHandler(
    fetch(buildUrl(url, {}, params), {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Environment: process.env.NODE_ENV,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
        ...extraHeaders,
      },
      body: JSON.stringify(data),
    }),
  );
};

export default fetchPostJson;
