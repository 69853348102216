/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */

import { createLogic } from 'redux-logic';

import { getQueryTemplateList } from '../../apis';
import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { apiErrorHandle } from '../../errors';

const loadQueryTemplateListLogic = createLogic({
  type: [ActionTypes.LOAD_QUERY_TEMPLATE_LIST],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getQueryTemplateList(credentials)
      .then((data) => {
        dispatch(createSetAction(ActionTypes.SET_QUERY_TEMPLATE_LIST, params, data));
      })
      .catch((err) => {
        dispatch(apiErrorHandle(err));
      })
      .then(() => {
        dispatch(hideLoading);
        done();
      });
  },
});

export default loadQueryTemplateListLogic;
