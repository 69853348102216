/*  @flow */
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postBugRepository = (credentials: Credentials, params: Object) => {
  const { pdKey, desc } = params;
  return fetchPost(getEndpoint('dashboard-benchmark'), {
    ...credentials,
    operation: 'changedesc',
    pdkey: pdKey,
    desc,
  }).then(d => {
    return d;
  });
};

export default postBugRepository;
