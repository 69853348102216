import React, { useState } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { isArray, isPlainObject } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FileAddOutlined, UploadOutlined, QuestionOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Button, Tooltip, Select, Alert, TreeSelect, AutoComplete, Spin, Checkbox } from 'antd';

// import { Defaults } from '../../../../common/utils';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { Modal, Popover } from '../../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMenusMessages } from '../../../../common/app/messages';
import { DashboardMessages } from '../../../../common/dashboard/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import fetchGet from '../../../../common/apis/fetchGet';
import getEndpoint from '../../../../common/apis/getEndpoint';
import UpdateBaseValeMappingModal from './UpdateBaseValeMappingModal';
import DerivedValueModel from './DerivedValueModel';

type Props = {
  // eslint-disable-next-line
  projectName: String,
  incident: Object,
  isSubmitting: Boolean,
  onSave: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  userList: Array<Object>,
  fieldNameOptions: Array<Object>,
  userInfo: Object,
  credentials: Object,
};

class LogToMetricModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { projectName, incident, projects, userInfo } = props;

    let jsonFlag = false;
    let metricProjectName = '';
    let regexs = [];
    let jsonParsers = [];
    let sampleJsonString;
    let sampleJsonTreeData = [];
    let mappingFlag = false;
    if (incident) {
      jsonFlag = incident.jsonFlag || false;
      metricProjectName = incident.metricProjectName || '';
      regexs = incident.regexs || [];
      mappingFlag = incident.enableMapping;
      jsonParsers = R.addIndex(R.map)(
        (item, idx) => ({ ...item, id: `${idx}${+new Date()}` }),
        incident.jsonParsers || [],
      );
      sampleJsonString = incident.sampleJsonString || undefined;
      if (sampleJsonString) {
        try {
          const jsonData = JSON.parse(sampleJsonString);
          sampleJsonTreeData = this.buildJsonTree(jsonData || {});

          // cover metricValueKey => metricValueKeyList
          jsonParsers = R.map((item) => {
            const metricValueKeyList = item.metricValueKey ? [item.metricValueKey] : [];
            return { ...item, metricValueKeyList };
          }, jsonParsers);
        } catch (err) {
          // console.error(String(err));
        }
      }
    }

    const logProject = R.find((item) => item.projectName === projectName, projects || []);
    const { owner } = logProject || {};
    let metricProjects = R.filter((item) => item.isMetric, projects || []);
    if (owner) {
      metricProjects = R.filter((item) => item.owner === owner, metricProjects || []);
    }
    const projectOptions = R.map(
      (item) => ({
        value: item.projectShortName,
        label: `${item.projectDisplayName}${
          userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName ? `@${item.owner}` : ''
        }`,
      }),
      metricProjects,
    );

    const isAllChecked = !R.find((item) => !item.groupingByComponent, jsonFlag ? jsonParsers : regexs);

    this.state = {
      jsonFlag,
      metricProjectName,
      regexs,
      jsonParsers,

      showUploadSampleJsonModal: false,
      sampleJsonString,
      sampleJsonTreeData,

      isLoading: false,
      baseValueMappingMap: {},
      showBaseValeMappingModal: false,
      mappingFlag,
      disabledMapping: false,
      baseValueKeys: [],
      showDerivedValueModel: false,
      activeEvent: null,

      groupingByComponentCheckedAll: isAllChecked,
    };
    this.operationOptions = [
      { value: 0, label: 'User-defined' },
      { value: 1, label: 'Count by log entries' },
      { value: 2, label: 'Count by metric value' },
      { value: 3, label: 'Unique count by metric value' },
    ];
    this.aggregationModeOption = [
      { value: 0, label: 'Sum' },
      { value: 1, label: 'Average' },
      { value: 2, label: 'Median' },
    ];
    this.projectOptions = projectOptions;
    this.getMappingTime = null;
  }

  componentDidMount() {
    this.getBasevaluemapping();
  }

  @autobind
  getBasevaluemapping() {
    const { credentials, projectName } = this.props;
    const { metricProjectName } = this.state;
    if (!metricProjectName) {
      this.setState({ baseValueMappingMap: {} });
      return;
    }
    this.setState({ isLoading: true });
    fetchGet(getEndpoint('logtometricbasevaluemapping'), {
      ...credentials,
      projectName,
      metricProjectName,
    })
      .then((data) => {
        const { success, message } = data;
        const baseValueMappingMap = {};
        let baseValueKeys = [];
        let newState = { isLoading: false, disabledMapping: false };
        if ((success || success === undefined) && isArray(data)) {
          if (data.length === 0) newState = { ...newState, mappingFlag: false, disabledMapping: true };
          R.forEach((item) => {
            const { type, mapping } = item;
            const mappings = [];
            R.forEachObjIndexed((baseVals, instance) => {
              const instanceGroup = [];
              R.forEachObjIndexed((value, metric) => {
                instanceGroup.push({ type, name: instance, metric, value });
                baseValueKeys.push({ label: metric, value: metric });
              }, baseVals || {});
              mappings.push({ type, name: instance, instanceGroup, isRoot: true });
            }, mapping || {});
            baseValueMappingMap[type] = mappings;
          }, data || []);
        } else {
          console.error(message);
        }
        baseValueKeys = R.uniqBy((item) => item.value, baseValueKeys);
        newState = { ...newState, baseValueMappingMap, baseValueKeys };
        this.setState(newState);
      })
      .catch((err) => {
        console.err(err.message || String(err));
        this.setState({ isLoading: false });
      });
  }

  @autobind
  handleSubmit(stopCloseFlag = false) {
    const { onSave } = this.props;
    const { jsonFlag, metricProjectName, regexs, jsonParsers, sampleJsonString, mappingFlag } = this.state;
    const newData = { jsonFlag, metricProjectName, sampleJsonString, enableMapping: mappingFlag };
    if (jsonFlag) {
      const newJsonParsers = [];
      R.forEach((item) => {
        const {
          operation,
          metricValueKeyList,
          metricValueKey,
          baseValueKey,
          additionalMetricName,
          instanceNameKey,
          containerNameKey,
          timestampKey,
          timestampFormat,
          dataFilter,
          metricName,
          aggregationMode,
          groupingByComponent,
          derivedValueModel,
        } = item;
        if (metricValueKeyList && metricValueKeyList.length > 0) {
          R.forEach((key) => {
            newJsonParsers.push({
              operation,
              metricValueKey: key,
              baseValueKey,
              additionalMetricName,
              instanceNameKey,
              containerNameKey,
              timestampKey,
              timestampFormat,
              dataFilter,
              metricName,
              aggregationMode,
              groupingByComponent,
              derivedValueModel: R.keys(derivedValueModel || {}).length > 0 ? derivedValueModel : undefined,
            });
          }, metricValueKeyList);
        } else {
          newJsonParsers.push({
            operation,
            metricValueKey,
            baseValueKey,
            additionalMetricName,
            instanceNameKey,
            containerNameKey,
            timestampKey,
            timestampFormat,
            dataFilter,
            metricName,
            aggregationMode,
            groupingByComponent,
            derivedValueModel: R.keys(derivedValueModel || {}).length > 0 ? derivedValueModel : undefined,
          });
        }
      }, jsonParsers);

      newData.jsonParsers = newJsonParsers;
    } else {
      newData.regexs = regexs;
    }
    onSave(newData, stopCloseFlag);
  }

  @autobind
  renderHeaderCheckedAll({ type, flag, data }) {
    const { intl } = this.props;
    let title;
    let stateKey;
    if (type === 'groupingByComponent') {
      title = DashboardMessages.groupByComponent;
      stateKey = 'groupingByComponentCheckedAll';
    }

    let stateDataListKey;
    if (flag === 'json') {
      stateDataListKey = 'jsonParsers';
    } else if (flag === 'string') {
      stateDataListKey = 'regexs';
    }
    return (
      <div className="flex-col flex-center-align">
        <div>{intl.formatMessage(title)}</div>
        <Checkbox
          style={{ paddingRight: 4 }}
          checked={this.state[stateKey]}
          onChange={(e) => {
            const { checked } = e.target;
            const newData = R.map((item) => ({ ...item, [type]: checked }), data || []);
            this.setState({ [stateKey]: checked, [stateDataListKey]: newData });
          }}
        />
      </div>
    );
  }

  @autobind
  renderListViewJSON(rowData, index) {
    if (!rowData) return null;

    const { intl, fieldNameOptions } = this.props;
    const { operation, metricValueKey, instanceNameKey, timestampKey, timestampFormat, dataFilter } = rowData;
    const { metricValueKeyList, aggregationMode, id, additionalMetricName, baseValueKey, containerNameKey } = rowData;
    const { metricName, groupingByComponent } = rowData;
    const { sampleJsonString, sampleJsonTreeData, baseValueKeys, mappingFlag } = this.state;

    return (
      <div key={id} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          <Select
            allowClear={false}
            size="small"
            style={{ width: '100%' }}
            options={this.operationOptions}
            value={operation}
            onChange={(operation) => {
              const { jsonParsers } = this.state;
              this.setState({
                jsonParsers: update(jsonParsers, {
                  [index]: {
                    $set: {
                      ...rowData,
                      operation,
                      ...(operation === 1 ? { metricValueKey: '', metricValueKeyList: [] } : {}),
                    },
                  },
                }),
              });
            }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          />
        </div>
        <div className="row-column" style={{ width: 155, minWidth: 155, flex: 1 }}>
          {!sampleJsonString && (
            <AutoComplete
              size="small"
              allowClear
              options={fieldNameOptions}
              value={metricValueKey}
              placeholder="key1->key2"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf((metricValueKey || '').toUpperCase()) !== -1
              }
              onChange={(value) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, {
                    [index]: { $set: { ...rowData, metricValueKey: value } },
                  }),
                });
              }}
              style={{ width: '100%' }}
              disabled={operation === 1}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            />
          )}
          {sampleJsonString && (
            <TreeSelect
              size="small"
              style={{ width: '100%' }}
              maxTagCount={1}
              showSearch
              autoClearSearchValue={false}
              treeDefaultExpandAll
              treeCheckable
              treeNodeLabelProp="title"
              treeData={sampleJsonTreeData}
              value={metricValueKeyList}
              onChange={(metricValueKeyList) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, { [index]: { $set: { ...rowData, metricValueKeyList } } }),
                });
              }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650, maxHeight: 400 }}
              disabled={operation === 1}
            />
          )}
        </div>
        <div className="row-column" style={{ width: 155, minWidth: 155, flex: 1 }}>
          {mappingFlag ? (
            <Select
              showSearch
              allowClear
              size="small"
              style={{ width: '100%' }}
              placeholder="key1->key2"
              value={baseValueKey}
              onChange={(value) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, {
                    [index]: { $set: { ...rowData, baseValueKey: value } },
                  }),
                });
              }}
              options={baseValueKeys}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            />
          ) : (
            <>
              {!sampleJsonString && (
                <AutoComplete
                  allowClear
                  size="small"
                  placeholder="key1->key2"
                  value={baseValueKey}
                  options={fieldNameOptions}
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf((baseValueKey || '').toUpperCase()) !== -1
                  }
                  onChange={(value) => {
                    const { jsonParsers } = this.state;
                    this.setState({
                      jsonParsers: update(jsonParsers, {
                        [index]: { $set: { ...rowData, baseValueKey: value } },
                      }),
                    });
                  }}
                  style={{ width: '100%' }}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                />
              )}
              {sampleJsonString && (
                <TreeSelect
                  size="small"
                  style={{ width: '100%' }}
                  multiple={false}
                  showSearch
                  autoClearSearchValue={false}
                  treeDefaultExpandAll
                  treeNodeLabelProp="title"
                  treeData={sampleJsonTreeData}
                  value={baseValueKey}
                  onChange={(baseValueKey) => {
                    const { jsonParsers } = this.state;
                    this.setState({
                      jsonParsers: update(jsonParsers, { [index]: { $set: { ...rowData, baseValueKey } } }),
                    });
                  }}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650, maxHeight: 400 }}
                />
              )}
            </>
          )}
        </div>
        <div className="row-column" style={{ width: 175, minWidth: 175, flex: 1 }}>
          {!sampleJsonString && (
            <AutoComplete
              allowClear
              size="small"
              placeholder="key1->key2"
              value={additionalMetricName}
              options={fieldNameOptions}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf((additionalMetricName || '').toUpperCase()) !== -1
              }
              onChange={(value) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, {
                    [index]: { $set: { ...rowData, additionalMetricName: value } },
                  }),
                });
              }}
              style={{ width: '100%' }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            />
          )}
          {sampleJsonString && (
            <TreeSelect
              size="small"
              style={{ width: '100%' }}
              multiple={false}
              showSearch
              autoClearSearchValue={false}
              treeDefaultExpandAll
              treeNodeLabelProp="title"
              treeData={sampleJsonTreeData}
              value={additionalMetricName}
              onChange={(additionalMetricName) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, { [index]: { $set: { ...rowData, additionalMetricName } } }),
                });
              }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650, maxHeight: 400 }}
            />
          )}
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {!sampleJsonString && (
            <AutoComplete
              allowClear
              size="small"
              placeholder="key1->key2"
              value={instanceNameKey}
              options={fieldNameOptions}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf((instanceNameKey || '').toUpperCase()) !== -1
              }
              onChange={(value) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, {
                    [index]: { $set: { ...rowData, instanceNameKey: value } },
                  }),
                });
              }}
              style={{ width: '100%' }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            />
          )}
          {sampleJsonString && (
            <TreeSelect
              size="small"
              style={{ width: '100%' }}
              multiple={false}
              showSearch
              autoClearSearchValue={false}
              treeDefaultExpandAll
              treeNodeLabelProp="title"
              treeData={sampleJsonTreeData}
              value={instanceNameKey}
              onChange={(instanceNameKey) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, { [index]: { $set: { ...rowData, instanceNameKey } } }),
                });
              }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650, maxHeight: 400 }}
            />
          )}
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {!sampleJsonString && (
            <AutoComplete
              allowClear
              size="small"
              placeholder="key1->key2"
              value={containerNameKey}
              options={fieldNameOptions}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf((containerNameKey || '').toUpperCase()) !== -1
              }
              onChange={(value) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, {
                    [index]: { $set: { ...rowData, containerNameKey: value } },
                  }),
                });
              }}
              style={{ width: '100%' }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            />
          )}
          {sampleJsonString && (
            <TreeSelect
              size="small"
              style={{ width: '100%' }}
              multiple={false}
              showSearch
              autoClearSearchValue={false}
              treeDefaultExpandAll
              treeNodeLabelProp="title"
              treeData={sampleJsonTreeData}
              value={containerNameKey}
              onChange={(containerNameKey) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, { [index]: { $set: { ...rowData, containerNameKey } } }),
                });
              }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650, maxHeight: 400 }}
            />
          )}
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          {!sampleJsonString && (
            <AutoComplete
              allowClear
              size="small"
              placeholder="key1->key2"
              value={timestampKey}
              options={fieldNameOptions}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf((timestampKey || '').toUpperCase()) !== -1
              }
              onChange={(value) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, { [index]: { $set: { ...rowData, timestampKey: value } } }),
                });
              }}
              style={{ width: '100%' }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            />
          )}
          {sampleJsonString && (
            <TreeSelect
              size="small"
              style={{ width: '100%' }}
              multiple={false}
              showSearch
              autoClearSearchValue={false}
              treeDefaultExpandAll
              treeNodeLabelProp="title"
              treeData={sampleJsonTreeData}
              value={timestampKey}
              onChange={(timestampKey) => {
                const { jsonParsers } = this.state;
                this.setState({
                  jsonParsers: update(jsonParsers, { [index]: { $set: { ...rowData, timestampKey } } }),
                });
              }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650, maxHeight: 400 }}
            />
          )}
        </div>
        <div className="row-column" style={{ width: 170 }}>
          <Input
            size="small"
            placeholder="yyy-MM-dd'T'HH:mm:ss.SSS'Z'"
            value={timestampFormat}
            onChange={(e) => {
              const { jsonParsers } = this.state;
              this.setState({
                jsonParsers: update(jsonParsers, {
                  [index]: { $set: { ...rowData, timestampFormat: e.target.value } },
                }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          <Input
            size="small"
            value={metricName}
            onChange={(e) => {
              const { jsonParsers } = this.state;
              this.setState({
                jsonParsers: update(jsonParsers, {
                  [index]: { $set: { ...rowData, metricName: e.target.value } },
                }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          <Input
            size="small"
            value={dataFilter}
            onChange={(e) => {
              const { jsonParsers } = this.state;
              this.setState({
                jsonParsers: update(jsonParsers, {
                  [index]: { $set: { ...rowData, dataFilter: e.target.value } },
                }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 125 }}>
          <Select
            allowClear={false}
            size="small"
            style={{ width: '100%' }}
            options={this.aggregationModeOption}
            value={aggregationMode}
            onChange={(aggregationMode) => {
              const { jsonParsers } = this.state;
              this.setState({
                jsonParsers: update(jsonParsers, {
                  [index]: { $set: { ...rowData, aggregationMode } },
                }),
              });
            }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          />
        </div>
        <div className="row-column flex-center-justify" style={{ width: 140 }}>
          <Checkbox
            checked={groupingByComponent}
            onChange={(e) => {
              const { jsonParsers } = this.state;
              this.setState(
                {
                  jsonParsers: update(jsonParsers, {
                    [index]: { $set: { ...rowData, groupingByComponent: e.target.checked } },
                  }),
                },
                () => {
                  const { jsonParsers } = this.state;
                  const isAllChecked = !R.find((item) => !item.groupingByComponent, jsonParsers || []);
                  this.setState({ groupingByComponentCheckedAll: isAllChecked });
                },
              );
            }}
          />
        </div>
        <div className="row-column flex-center-justify" style={{ width: 130 }}>
          <Button
            size="small"
            onClick={() => {
              this.setState({ activeEvent: R.clone({ ...rowData, index }), showDerivedValueModel: true });
            }}
          >
            {intl.formatMessage(appFieldsMessages.config)}
          </Button>
        </div>
        <div className="row-column flex-end-justify" style={{ width: 100 }}>
          <Button
            size="small"
            className="button-color-grey"
            onClick={() => this.handleRemoveClick(true, rowData, index)}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderListViewString(rowData, index) {
    if (!rowData) return null;

    const { intl } = this.props;
    const { mappingFlag, baseValueKeys } = this.state;
    const {
      operation,
      metricNameRegex,
      metricValueRegex,
      baseValueKey,
      instanceNameRegex,
      containerNameRegex,
      timestampRegex,
      timestampFormat,
      dataFilter,
      metricName,
      aggregationMode,
      groupingByComponent,
    } = rowData;

    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          <Select
            allowClear={false}
            size="small"
            style={{ width: '100%' }}
            options={this.operationOptions}
            value={operation}
            onChange={(operation) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, {
                  [index]: {
                    $set: {
                      ...rowData,
                      operation,
                      ...(operation === 1 ? { metricNameRegex: '', metricValueRegex: '' } : {}),
                    },
                  },
                }),
              });
            }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          />
        </div>
        <div className="row-column" style={{ width: 135, minWidth: 135, flex: 1 }}>
          <Input
            size="small"
            placeholder="transaction_type=(.*?)}"
            value={metricNameRegex}
            onChange={(e) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, { [index]: { $set: { ...rowData, metricNameRegex: e.target.value } } }),
              });
            }}
            disabled={operation === 1}
          />
        </div>
        <div className="row-column" style={{ width: 135, minWidth: 135, flex: 1 }}>
          <Input
            size="small"
            placeholder="duration=(.*?),"
            value={metricValueRegex}
            onChange={(e) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, { [index]: { $set: { ...rowData, metricValueRegex: e.target.value } } }),
              });
            }}
            disabled={operation === 1}
          />
        </div>
        <div className="row-column" style={{ width: 155, minWidth: 155, flex: 1 }}>
          {mappingFlag ? (
            <Select
              showSearch
              allowClear
              size="small"
              style={{ width: '100%' }}
              placeholder="key1->key2"
              value={baseValueKey}
              onChange={(value) => {
                const { regexs } = this.state;
                this.setState({
                  regexs: update(regexs, {
                    [index]: { $set: { ...rowData, baseValueKey: value } },
                  }),
                });
              }}
              options={baseValueKeys}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            />
          ) : (
            <Input
              size="small"
              placeholder="duration=(.*?),"
              value={baseValueKey}
              onChange={(e) => {
                const { regexs } = this.state;
                this.setState({
                  regexs: update(regexs, { [index]: { $set: { ...rowData, baseValueKey: e.target.value } } }),
                });
              }}
              disabled={operation === 1}
            />
          )}
        </div>
        <div className="row-column" style={{ width: 135, minWidth: 135, flex: 1 }}>
          <Input
            size="small"
            placeholder="tag=(.*?),"
            value={instanceNameRegex}
            onChange={(e) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, { [index]: { $set: { ...rowData, instanceNameRegex: e.target.value } } }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 135, minWidth: 135, flex: 1 }}>
          <Input
            size="small"
            placeholder="tag=(.*?),"
            value={containerNameRegex}
            onChange={(e) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, { [index]: { $set: { ...rowData, containerNameRegex: e.target.value } } }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 130, minWidth: 130, flex: 1 }}>
          <Input
            size="small"
            placeholder="end=(.*?),"
            value={timestampRegex}
            onChange={(e) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, { [index]: { $set: { ...rowData, timestampRegex: e.target.value } } }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 170 }}>
          <Input
            size="small"
            placeholder="yyy-MM-dd'T'HH:mm:ss.SSS'Z'"
            value={timestampFormat}
            onChange={(e) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, { [index]: { $set: { ...rowData, timestampFormat: e.target.value } } }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          <Input
            size="small"
            value={metricName}
            onChange={(e) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, { [index]: { $set: { ...rowData, metricName: e.target.value } } }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 100, flex: 1 }}>
          <Input
            size="small"
            value={dataFilter}
            onChange={(e) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, { [index]: { $set: { ...rowData, dataFilter: e.target.value } } }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 125 }}>
          <Select
            allowClear={false}
            size="small"
            style={{ width: '100%' }}
            options={this.aggregationModeOption}
            value={aggregationMode}
            onChange={(aggregationMode) => {
              const { regexs } = this.state;
              this.setState({
                regexs: update(regexs, {
                  [index]: { $set: { ...rowData, aggregationMode } },
                }),
              });
            }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          />
        </div>
        <div className="row-column flex-center-justify" style={{ width: 140 }}>
          <Checkbox
            checked={groupingByComponent}
            onChange={(e) => {
              const { regexs } = this.state;
              this.setState(
                {
                  regexs: update(regexs, {
                    [index]: { $set: { ...rowData, groupingByComponent: e.target.checked } },
                  }),
                },
                () => {
                  const { regexs } = this.state;
                  const isAllChecked = !R.find((item) => !item.groupingByComponent, regexs || []);
                  this.setState({ groupingByComponentCheckedAll: isAllChecked });
                },
              );
            }}
          />
        </div>
        <div className="row-column flex-end-justify" style={{ width: 100 }}>
          <Button
            size="small"
            className="button-color-grey"
            onClick={() => this.handleRemoveClick(false, rowData, index)}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  handleAddClick(jsonFlag) {
    const { jsonParsers, regexs } = this.state;
    if (jsonFlag) {
      this.setState(
        {
          jsonParsers: [
            {
              id: +new Date(),
              operation: 0,
              metricValueKeyList: [],
              metricValueKey: undefined,
              baseValueKey: undefined,
              additionalMetricName: undefined,
              instanceNameKey: undefined,
              containerNameKey: undefined,
              timestampKey: undefined,
              timestampFormat: undefined,
              dataFilter: undefined,
              metricName: undefined,
              aggregationMode: 0,
              derivedValueModel: undefined,
              groupingByComponent: false,
            },
            ...jsonParsers,
          ],
        },
        () => {
          const { jsonParsers } = this.state;
          const isAllChecked = !R.find((item) => !item.groupingByComponent, jsonParsers || []);
          this.setState({ groupingByComponentCheckedAll: isAllChecked });
        },
      );
    } else {
      this.setState(
        {
          regexs: [
            {
              operation: 0,
              metricNameRegex: undefined,
              metricValueRegex: undefined,
              baseValueKey: undefined,
              instanceNameRegex: undefined,
              containerNameRegex: undefined,
              timestampRegex: undefined,
              timestampFormat: undefined,
              metricName: undefined,
              dataFilter: undefined,
              aggregationMode: 0,
              groupingByComponent: false,
            },
            ...regexs,
          ],
        },
        () => {
          const { regexs } = this.state;
          const isAllChecked = !R.find((item) => !item.groupingByComponent, regexs || []);
          this.setState({ groupingByComponentCheckedAll: isAllChecked });
        },
      );
    }
  }

  @autobind
  handleRemoveClick(jsonFlag, rowData, index) {
    const { jsonParsers, regexs } = this.state;
    if (jsonFlag) {
      this.setState({ jsonParsers: R.remove(index, 1, jsonParsers) }, () => {
        const { jsonParsers } = this.state;
        const isAllChecked = !R.find((item) => !item.groupingByComponent, jsonParsers || []);
        this.setState({ groupingByComponentCheckedAll: isAllChecked });
      });
    } else {
      this.setState({ regexs: R.remove(index, 1, regexs) }, () => {
        const { regexs } = this.state;
        const isAllChecked = !R.find((item) => !item.groupingByComponent, regexs || []);
        this.setState({ groupingByComponentCheckedAll: isAllChecked });
      });
    }
  }

  @autobind
  handleHelpClick() {
    const { intl } = this.props;
    const { jsonFlag } = this.state;

    if (jsonFlag) {
      Modal.info({
        title: intl.formatMessage(appMenusMessages.help),
        width: 650,
        content: (
          <div>
            <p className="bold font-16">Sample regex:</p>
            <p>1. "metricValueKey": "key1" || "key1->key2" || "key1->key2->..."</p>
            <p>2. "instanceNameKey": "key1" || "key1->key2" || "key1->key2->..."</p>
            <p>3. "timestampKey": "key1" || "key1->key2" || "key1->key2->..."</p>
            <p>4. "timestampFormat": "yyy-MM-dd'T'HH:mm:ss.SSS'Z'"</p>
            <p>5. "dataFilter": "AGENTID=(.*?),</p>

            <br />
            <p className="bold font-16">Hint:</p>
            <p>1. metricValueKey is required.</p>
            <p>
              2. instanceNameKey and timestampKey are optional. If those json key are not specified, the system will use
              the timestamp and instanceName from the log data as default.
            </p>
            <p>
              3. timestampFormat is optional even you specified the timestampKey. If it is not set, the system will
              assume the extract the timestamp string is in the format of long like 1629409536946. Otherwise, the system
              will convert the extracted timestamp string to the format of long based on the timestamp format.
            </p>
          </div>
        ),
        onOk() {},
      });
    } else {
      Modal.info({
        title: intl.formatMessage(appMenusMessages.help),
        width: 650,
        content: (
          <div>
            <p className="bold font-16">Sample regex:</p>
            <p>1. "metricNameRegex": "transaction_type=(.*?)}"</p>
            <p>2. "metricValueRegex": "duration=(.*?),"</p>
            <p>3. "instanceNameRegex": "tag=(.*?),"</p>
            <p>4. "timestampRegex": "end=(.*?),"</p>
            <p>5. "timestampFormat": "yyy-MM-dd'T'HH:mm:ss.SSS'Z'"</p>
            <p>6. "dataFilter": "AGENTID=(.*?),</p>

            <br />
            <p className="bold font-16">Hint:</p>
            <p>1. metricNameRegex and metricValueRegex are required.</p>
            <p>
              2. timestmpRegex and instanceNameRegex are optional. If those regular expressions are not specified, the
              system will use the timestamp and instanceName from the log data as default.
            </p>
            <p>
              3. timestampFormat is optional even you specified the timestampRegex. If it is not set, the system will
              assume the extract the timestamp string is in the format of long like 1629409536946. Otherwise, the system
              will convert the extracted timestamp string to the format of long based on the timestamp format.
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }

  @autobind
  handleSubmitSampleJson(sampleJsonString, jsonData) {
    let { jsonParsers } = this.state;
    let sampleJsonTreeData = [];

    if (sampleJsonString) {
      sampleJsonTreeData = this.buildJsonTree(jsonData || {});

      // cover metricValueKey => metricValueKeyList
      jsonParsers = R.map((item) => {
        const metricValueKeyList = item.metricValueKey ? [item.metricValueKey] : [];
        return { ...item, metricValueKeyList };
      }, jsonParsers);
    } else {
      jsonParsers = R.map((item) => {
        return { ...item, metricValueKeyList: [] };
      }, jsonParsers);
    }

    this.setState({ showUploadSampleJsonModal: false, jsonParsers, sampleJsonString, sampleJsonTreeData });
  }

  @autobind
  buildJsonTree(jsonData, parentPath = '') {
    const treeData = [];
    R.forEachObjIndexed((val, label) => {
      const key = parentPath ? `${parentPath}->${label}` : label;
      const node = {
        title: label,
        value: key,
        key,
      };
      if (isPlainObject(val)) {
        node.children = this.buildJsonTree(val, key);
      }
      treeData.push(node);
    }, jsonData);
    return treeData;
  }

  @autobind
  operationExplainRender({ intl }) {
    return (
      <Popover
        title={null}
        placement="right"
        content={
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', maxWidth: 400 }}>
            <div style={{ textAlign: 'right' }}>User defined:</div>
            <div style={{ marginLeft: 5 }}>{intl.formatMessage(settingsMessages.userDefinedExplain)}</div>
            <div style={{ textAlign: 'right' }}>Count by log entries:</div>
            <div style={{ marginLeft: 5 }}>{intl.formatMessage(settingsMessages.countByLogEntriesExplain)}</div>
            <div style={{ textAlign: 'right' }}>Count by metric value:</div>
            <div style={{ marginLeft: 5 }}>{intl.formatMessage(settingsMessages.countByMetricValueExplain)}</div>
            <div style={{ textAlign: 'right' }}>Unique count by metric value:</div>
            <div style={{ marginLeft: 5 }}>{intl.formatMessage(settingsMessages.uniqueCountByMetricValueExplain)}</div>
          </div>
        }
        mouseEnterDelay={0.3}
      >
        <span style={{ marginLeft: 5 }}>
          <QuestionCircleOutlined />
        </span>
      </Popover>
    );
  }

  @autobind
  timestampRegexExplainRender({ intl }) {
    return (
      <Popover
        title={null}
        content={<div style={{ maxWidth: 400 }}>{intl.formatMessage(settingsMessages.timestampRegexExplain)}</div>}
        mouseEnterDelay={0.3}
      >
        <span style={{ marginLeft: 5 }}>
          <QuestionCircleOutlined />
        </span>
      </Popover>
    );
  }

  @autobind
  timestampFormatExplainRender({ intl }) {
    return (
      <Popover
        title={null}
        content={<div style={{ maxWidth: 400 }}>{intl.formatMessage(settingsMessages.timestampFormatExplain)}</div>}
        mouseEnterDelay={0.3}
      >
        <span style={{ marginLeft: 5 }}>
          <QuestionCircleOutlined />
        </span>
      </Popover>
    );
  }

  render() {
    const { intl, incident, isSubmitting, projectName, fieldNameOptions } = this.props;
    const {
      jsonFlag,
      metricProjectName,
      regexs,
      jsonParsers,
      sampleJsonString,
      isLoading,
      showBaseValeMappingModal,
      baseValueMappingMap,
      mappingFlag,
      disabledMapping,
      activeEvent,
    } = this.state;

    let hasError = !metricProjectName;
    hasError =
      hasError || jsonFlag
        ? jsonParsers.length === 0 ||
          !metricProjectName ||
          !R.reduce(
            R.and,
            true,
            R.map((item) => {
              return item.operation === 1
                ? true
                : sampleJsonString
                ? item.metricValueKeyList &&
                  item.metricValueKeyList.length > 0 &&
                  (mappingFlag ? Boolean(item.baseValueKey) : true)
                : Boolean(item.metricValueKey) && (mappingFlag ? Boolean(item.baseValueKey) : true);
            }, jsonParsers),
          )
        : regexs.length === 0 ||
          !R.reduce(
            R.and,
            true,
            R.map((item) => {
              return item.operation === 1
                ? true
                : item.metricNameRegex && item.metricValueRegex && (mappingFlag ? Boolean(item.baseValueKey) : true);
            }, regexs),
          );
    return (
      <div className="full-width full-height">
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
            <div className="flex-row">
              <Button size="small" type="primary" onClick={() => this.handleAddClick(jsonFlag)}>
                <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
              </Button>
              {jsonFlag && (
                <Button
                  size="small"
                  type="primary"
                  style={{ marginLeft: 8 }}
                  onClick={() => this.setState({ showUploadSampleJsonModal: true })}
                >
                  <UploadOutlined /> Upload sample json
                </Button>
              )}
              <Button
                size="small"
                type="primary"
                style={{ marginLeft: 8 }}
                disabled={!metricProjectName}
                onClick={() => this.setState({ showBaseValeMappingModal: true })}
              >
                {intl.formatMessage(settingsMessages.updateBaseValueMapping)}
              </Button>
              <div className="flex-row flex-center-align">
                <div className="light-label bold" style={{ marginRight: 8, marginLeft: 8 }}>
                  {intl.formatMessage(settingsMessages.enableAndDisableMapping)}:
                </div>
                <Checkbox
                  checked={mappingFlag}
                  disabled={disabledMapping}
                  onChange={(e) => this.setState({ mappingFlag: e.target.checked })}
                />
              </div>
            </div>

            <div className="flex-grow flex-row flex-center-align flex-end-justify">
              <div className="light-label bold" style={{ marginRight: 8 }}>
                {intl.formatMessage(settingsMessages.jsonFlag)}:
              </div>
              <Select
                size="small"
                style={{ width: 100 }}
                disabled={Boolean(incident)}
                optionFilterProp="value"
                filterOption
                value={jsonFlag ? 'true' : 'false'}
                onChange={(jsonFlag) => {
                  this.setState({ jsonFlag: jsonFlag === 'true', groupingByComponentCheckedAll: false });
                }}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                <Select.Option value="false">String</Select.Option>
                <Select.Option value="true">JSON</Select.Option>
              </Select>
              <div className="light-label bold flex-row flex-center-align" style={{ marginLeft: 16, marginRight: 8 }}>
                {intl.formatMessage(settingsMessages.metricProject)}:
                <span style={{ color: 'red', marginLeft: 4, fontSize: 14 }}>*</span>
              </div>
              <AutoComplete
                size="small"
                style={{ width: 150 }}
                disabled={Boolean(incident)}
                allowClear
                placeholder="Please type in metric project name or select from the existing metric project list"
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                options={this.projectOptions}
                value={metricProjectName}
                onChange={(metricProjectName) => {
                  if (this.getMappingTime) {
                    clearTimeout(this.getMappingTime);
                  }
                  this.setState({ metricProjectName, mappingFlag: false, disabledMapping: false }, () => {
                    this.getMappingTime = setTimeout(() => {
                      this.getBasevaluemapping();
                    }, 600);
                  });
                }}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              />
              {/* <Select
              size="small"
              style={{ width: 100 }}
              disabled={Boolean(project)}
              allowClear={false}
              showSearch
              filterOption
              value={projectOwner}
              onChange={(projectOwner) => this.setState({ projectOwner })}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.userName} value={item.userName}>
                    {item.userName}
                  </Select.Option>
                ),
                userList || [],
              )}
            </Select> */}
              <Tooltip title={intl.formatMessage(appMenusMessages.help)}>
                <Button
                  size="small"
                  type="primary"
                  shape="circle"
                  style={{ marginLeft: 16 }}
                  icon={<QuestionOutlined />}
                  onClick={this.handleHelpClick}
                />
              </Tooltip>
            </div>
          </div>

          {jsonFlag && (
            <div className="flex-grow overflow-x-auto">
              <div className="event-list flex-col flex-min-height full-height" style={{ minWidth: 1920 }}>
                <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    {intl.formatMessage(appFieldsMessages.operation)}{' '}
                    <span style={{ color: 'red', marginLeft: 4, textDecoration: 'none', fontSize: 14 }}>*</span>
                    {this.operationExplainRender({ intl })}
                  </div>
                  <div className="header-column" style={{ width: 155, minWidth: 155, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.metricValueKey)}{' '}
                    <span style={{ color: 'red', marginLeft: 4, textDecoration: 'none', fontSize: 14 }}>*</span>
                  </div>
                  <div className="header-column" style={{ width: 155, minWidth: 155, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.baseValueName)}{' '}
                    {mappingFlag && (
                      <span style={{ color: 'red', marginLeft: 4, textDecoration: 'none', fontSize: 14 }}>*</span>
                    )}
                  </div>
                  <div className="header-column" style={{ width: 175, minWidth: 175, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.additionalMetricNameKey)}
                  </div>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.instanceNameKey)}
                  </div>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.containerNameKey)}
                  </div>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.timestampKey)}
                    {this.timestampRegexExplainRender({ intl })}
                  </div>
                  <div className="header-column" style={{ width: 170 }}>
                    {intl.formatMessage(settingsMessages.timestampFormat)}
                    {this.timestampFormatExplainRender({ intl })}
                  </div>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.metricName)}
                  </div>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    {intl.formatMessage(DashboardMessages.filter)}
                  </div>
                  <div className="header-column" style={{ width: 125 }}>
                    {intl.formatMessage(DashboardMessages.aggregationMode)}
                  </div>
                  <div className="header-column flex-center-justify" style={{ width: 140 }}>
                    {this.renderHeaderCheckedAll({ type: 'groupingByComponent', flag: 'json', data: jsonParsers })}
                  </div>
                  <div className="header-column" style={{ width: 130 }}>
                    {intl.formatMessage(DashboardMessages.derivedValueModel)}
                  </div>
                  <div className="header-column" style={{ width: 100 }} />
                </div>
                <div className="event-list-grid flex-grow overflow-y-auto">
                  {R.addIndex(R.map)((rowData, index) => this.renderListViewJSON(rowData, index), jsonParsers || [])}
                </div>
              </div>
            </div>
          )}
          {!jsonFlag && (
            <div className="flex-grow overflow-x-auto">
              <div className="event-list flex-col flex-min-height full-height" style={{ minWidth: 1920 }}>
                <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    {intl.formatMessage(appFieldsMessages.operation)}{' '}
                    <span style={{ color: 'red', marginLeft: 4, textDecoration: 'none', fontSize: 14 }}>*</span>
                    {this.operationExplainRender({ intl })}
                  </div>
                  <div className="header-column" style={{ width: 135, minWidth: 135, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.metricNameRegex)}{' '}
                    <span style={{ color: 'red', marginLeft: 4, textDecoration: 'none', fontSize: 14 }}>*</span>
                  </div>
                  <div className="header-column" style={{ width: 135, minWidth: 135, flex: 1 }}>
                    <span style={{ textDecoration: 'none' }}>
                      {intl.formatMessage(settingsMessages.metricValueRegex)}
                    </span>
                    <span style={{ color: 'red', marginLeft: 4, textDecoration: 'none', fontSize: 14 }}>*</span>
                  </div>
                  <div className="header-column" style={{ width: 155, minWidth: 155, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.baseValueName)}{' '}
                    {mappingFlag && (
                      <span style={{ color: 'red', marginLeft: 4, textDecoration: 'none', fontSize: 14 }}>*</span>
                    )}
                  </div>
                  <div className="header-column" style={{ width: 135, minWidth: 135, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.instanceNameRegex)}
                  </div>
                  <div className="header-column" style={{ width: 135, minWidth: 135, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.containerNameRegex)}
                  </div>
                  <div className="header-column" style={{ width: 130, minWidth: 130, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.timestampRegex)}
                    {this.timestampRegexExplainRender({ intl })}
                  </div>
                  <div className="header-column" style={{ width: 170 }}>
                    {intl.formatMessage(settingsMessages.timestampFormat)}
                    {this.timestampFormatExplainRender({ intl })}
                  </div>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    {intl.formatMessage(settingsMessages.metricName)}
                  </div>
                  <div className="header-column" style={{ width: 100, flex: 1 }}>
                    {intl.formatMessage(DashboardMessages.filter)}
                  </div>
                  <div className="header-column" style={{ width: 125 }}>
                    {intl.formatMessage(DashboardMessages.aggregationMode)}
                  </div>
                  <div className="header-column flex-center-justify" style={{ width: 140 }}>
                    {this.renderHeaderCheckedAll({ type: 'groupingByComponent', flag: 'string', data: regexs })}
                  </div>
                  <div className="header-column" style={{ width: 100 }} />
                </div>
                <div className="event-list-grid flex-grow overflow-y-auto">
                  {R.addIndex(R.map)((rowData, index) => this.renderListViewString(rowData, index), regexs || [])}
                </div>
              </div>
            </div>
          )}

          <div style={{ marginTop: 8, textAlign: 'right' }}>
            <Button
              size="small"
              type="primary"
              onClick={() => this.handleSubmit()}
              disabled={hasError}
              loading={isSubmitting}
            >
              {intl.formatMessage(appButtonsMessages.submit)}
            </Button>
          </div>

          {showBaseValeMappingModal && (
            <UpdateBaseValeMappingModal
              intl={intl}
              incident={incident}
              projectName={projectName}
              metricProjectName={metricProjectName}
              baseValueMappingMap={baseValueMappingMap}
              onClose={() => this.setState({ showBaseValeMappingModal: false })}
              getBasevaluemapping={this.getBasevaluemapping}
              isLoading={isLoading}
              changeMappingFlag={() => this.setState({ mappingFlag: true, disabledMapping: false })}
            />
          )}
        </Spin>

        {this.state.showUploadSampleJsonModal && (
          <UploadSampleJsonModal
            sampleJsonString={this.state.sampleJsonString}
            onClose={() => this.setState({ showUploadSampleJsonModal: false })}
            onSubmit={this.handleSubmitSampleJson}
          />
        )}

        {this.state.showDerivedValueModel && (
          <DerivedValueModel
            activeEvent={activeEvent}
            fieldNameOptions={fieldNameOptions}
            onClose={(data) => {
              if (data) {
                const { index, ...rest } = data;
                const { jsonParsers } = this.state;
                this.setState(
                  {
                    jsonParsers: update(jsonParsers, {
                      [index]: { $set: { ...jsonParsers[index], derivedValueModel: rest } },
                    }),
                    showDerivedValueModel: false,
                    activeEvent: null,
                  },
                  () => {
                    if (!hasError) this.handleSubmit(true);
                  },
                );
              } else {
                this.setState({ showDerivedValueModel: false, activeEvent: null });
              }
            }}
          />
        )}
      </div>
    );
  }
}

const UploadSampleJsonModal = ({ sampleJsonString: jsonString, onClose, onSubmit }: Object) => {
  const [sampleJsonString, setSampleJson] = useState(jsonString);
  const [errMsg, setErrMsg] = useState(null);
  const handleSubmit = () => {
    if (sampleJsonString) {
      try {
        const jsonData = JSON.parse(sampleJsonString);
        setErrMsg(null);
        onSubmit(sampleJsonString, jsonData);
      } catch (err) {
        setErrMsg(String(err));
      }
    } else {
      onSubmit(sampleJsonString, {});
    }
  };

  return (
    <Modal
      title="Upload sample json"
      width={650}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={handleSubmit}
    >
      <Input.TextArea
        allowClear
        showCount
        rows={10}
        value={sampleJsonString}
        onChange={(e) => setSampleJson(e.target.value)}
      />

      {Boolean(errMsg) && <Alert message={errMsg} type="error" showIcon style={{ marginTop: 8 }} />}
    </Modal>
  );
};

const LogToMetricModal = injectIntl(LogToMetricModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    const { userInfo, credentials } = state.auth;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return { location, loadStatus, projects, userList, userInfo, credentials };
  },
  {
    updateLastActionInfo,
  },
)(LogToMetricModal);
