import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';

import { State } from '../../../common/types';
import { Modal } from '../../../../artui/react';
import { Container } from '../../../lib/fui/react';
import EventListTable from '../../metric/components/EventListTable';

type Props = {
  project: Object,
  projectName: String,
  instanceGroup: String,
  incident: Array<Object>,
  onClose: Function,
};

class EventJobModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      eventList: [],
    };
  }

  componentDidMount() {
    this.convertData(this.props, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.incident !== nextProps.incident) {
      this.convertData(nextProps, {});
    }
  }

  @autobind
  convertData(props, params = {}) {
    const { incident } = props;
    this.setState({ eventList: incident });
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  render() {
    const { project, instanceGroup } = this.props;
    const { eventList } = this.state;
    const width = 1100;
    const height = 500;
    const { predictionWindow } = project || {};
    const startTime = moment().valueOf();
    const endTime = startTime + predictionWindow * 60 * 60 * 1000;
    return (
      <Modal closable size="huge" onClose={this.handleClose} style={{ height: height + 62 }}>
        <Container className="events-view" style={{ paddingLeft: 10 }}>
          <Container className="toolbar" style={{ width: 200 }}>
            <Container className="section">
              <h4>Anomaly Events</h4>
            </Container>
            <Container className="section float-right" />
          </Container>
          <EventListTable
            width={width}
            height={height}
            project={project}
            instanceGroup={instanceGroup}
            startTime={startTime}
            endTime={endTime}
            filterEventList={eventList}
            // metricUnitMap={metricUnitMap}
            // appNameMapping={appNameMapping}
          />
        </Container>
      </Modal>
    );
  }
}

const EventJobModal = injectIntl(EventJobModalCore);
export default connect((state: State) => ({}), {})(EventJobModal);
