/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Button, Spin, Tooltip } from 'antd';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';

import { State } from '../../../common/types';
import { Modal, AutoSizer } from '../../../lib/fui/react';
import { sleep, parseLocation, downloadPdf } from '../../../common/utils';
import { appFieldsMessages } from '../../../common/app/messages';
import { LineChart } from '../../../lib/fui/react/charts';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import { PDFIcon } from '../../../lib/fui/icons';
import { Defaults } from '../../../common/utils';

type Props = {
  projectName: String,
  // eslint-disable-next-line
  activeMetricData: Object,
  // eslint-disable-next-line
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  projectInstanceComponentMap: Object,
  k8CoverageMap: Object,
  instanceDisplayNameMap: Object,
  metricAnomalyMap: Object,
  showRCAFlag: Boolean,
  incidentInfo: Object,
};

const DragHandle = sortableHandle(({ intl }) => (
  <Tooltip title={intl.formatMessage(appFieldsMessages.dragAndDrop)} placement="top" mouseEnterDelay={0.3}>
    <div
      className="hover-display-item flex-row flex-center-align flex-center-justify"
      style={{ width: 28, height: 28, cursor: 'move' }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div
        style={{
          minWidth: 18,
          height: 11,
          opacity: 0.35,
          background:
            '-webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)',
        }}
      />
    </div>
  </Tooltip>
));
const SortableList = SortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const SortableItem = SortableElement(({ children }) => {
  return <>{children}</>;
});

class SeparateInstanceChartModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.chartHeight = 212;

    this.state = {
      isLoading: false,
      eventList: [],

      name: undefined,
      unit: undefined,
      currentTimestamp: undefined,
      columnInfoMap: {},
      samplingIntervalInSecond: 60,
      rootCauseMap: {},
      chartDateWindow: undefined,
      valueRange: undefined,
      isCustomData: false,
      handleOnCreateAnnotation: () => {},
    };
  }

  componentDidMount() {
    setTimeout(() => this.parseData(), 600);
  }

  @autobind
  async parseData() {
    const { projectName, projectInstanceComponentMap, activeMetricData, metricAnomalyMap, incidentInfo } = this.props;
    if (!activeMetricData) return;

    this.setState({ isLoading: true });
    await sleep(300);

    const {
      name: metricName,
      unit,
      currentTimestamp,
      columnInfoMap,
      samplingIntervalInSecond,
      predictionlabelMap,
      rootCauseMap,
      chartDateWindow,
      isCustomData,
      handleOnCreateAnnotation,
    } = activeMetricData || {};
    const { labels, data, annotations, labelsVisibility } = activeMetricData || {};
    const anomalyInstanceList = metricAnomalyMap?.[metricName] || [];

    // start parse data
    const parserStartTime = moment.utc().valueOf();

    // Separate chart by instance
    let eventList = [];
    R.addIndex(R.forEach)((label, idx) => {
      if (label === 'timestamp') return;
      if (R.values(predictionlabelMap || {}).includes(label)) return;

      const info = columnInfoMap[label];
      if (!info) return;

      // build chart name by instance
      const { instanceId } = info;
      const component = get(projectInstanceComponentMap, [projectName, instanceId], '');
      const name = component && component !== instanceId ? `${instanceId} (${component})` : instanceId;

      // filter chart data
      const predictionLabel = get(predictionlabelMap, label);
      const predictionLabelIdx = predictionLabel ? R.findIndex((l) => l === predictionLabel, labels) : -1;
      const newLabels = ['timestamp', label];
      let newData = [];
      const newLabelsVisibility = [[true, ...labelsVisibility][idx]];
      let newPredictionlabelMap;
      if (predictionLabel && predictionLabelIdx !== -1) {
        newLabels.push(predictionLabel);
        newData = R.map((item) => [item[0], item[idx], item[predictionLabelIdx]], data || []);
        newLabelsVisibility.push([true, ...labelsVisibility][predictionLabelIdx]);
        newPredictionlabelMap = { [label]: predictionLabel };
      } else {
        newData = R.map((item) => [item[0], item[idx]], data || []);
      }

      // filter annotations
      let newAnnotations = R.map((item) => {
        const { isCurrent, isRootAnomaly, annos, ...rest } = item;
        if (isCurrent || isRootAnomaly) {
          return item;
        }
        const newAnnos = R.filter((anno) => anno.instanceId === instanceId, annos || []);
        return { ...rest, annos: newAnnos };
      }, annotations || []);
      newAnnotations = R.filter((item) => (item.annos || []).length > 0, newAnnotations);
      const hasAnno = R.filter((item) => (item.annos || []).length > 0, newAnnotations).length > 0;
      const hasAnomaly = !!R.find((i) => i === instanceId, anomalyInstanceList);

      if (incidentInfo && !R.find((item) => item.isRootAnomaly, newAnnotations || {})) {
        newAnnotations.push({
          timestamp: Number(incidentInfo.startTimestamp),
          isRootAnomaly: true,
          ...incidentInfo,
        });
      }

      eventList.push({
        hasAnomaly,
        hasAnno,
        name,
        component,
        instanceId,
        labels: newLabels,
        data: newData,
        predictionlabelMap: newPredictionlabelMap,
        annotations: newAnnotations,
        labelsVisibility: newLabelsVisibility,
      });
    }, labels || []);
    eventList = R.sortWith(
      [
        R.descend(R.prop('hasAnomaly')),
        R.descend(R.prop('hasAnno')),
        R.ascend(R.prop('component')),
        R.ascend(R.prop('instanceId')),
      ],
      eventList,
    );
    console.debug(`Parser data duration: ${moment.utc().valueOf() - parserStartTime} ms`);

    this.setState(
      {
        isLoading: false,
        eventList,
        name: metricName,
        unit,
        currentTimestamp,
        columnInfoMap,
        samplingIntervalInSecond,
        rootCauseMap,
        chartDateWindow,
        // valueRange,
        isCustomData,
        handleOnCreateAnnotation,
      },
      () => {
        this.forceUpdate();
      },
    );
  }

  @autobind
  handleDateWindowSync(dateWindow) {
    this.setState({ chartDateWindow: dateWindow }, () => {
      if (this.listNode) this.listNode.forceUpdateGrid();
    });
  }

  @autobind
  renderListItem({ width, height }) {
    const { eventList, name } = this.state;
    if (R.isEmpty(eventList)) return null;

    const {
      intl,
      projectInstanceComponentMap,
      projectName,
      k8CoverageMap,
      metricAnomalyMap,
      instanceDisplayNameMap = {},
      showRCAFlag,
    } = this.props;
    const {
      currentTimestamp,
      columnInfoMap,
      samplingIntervalInSecond,
      rootCauseMap,
      chartDateWindow,
      valueRange,
      isCustomData,
      handleOnCreateAnnotation,
    } = this.state;

    return (
      <SortableList
        axis="y"
        helperClass="sortableHelperAntModal"
        useDragHandle
        onSortEnd={(params) => this.onSortEnd({ ...params })}
        className="linechart-container-parent"
      >
        {eventList.map((item, index) => {
          const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item.instanceId);
          const viewTitle = (
            <div className="flex-row flex-center-align" style={{ paddingRight: 70 }}>
              <div className="flex-grow flex-min-width hidden-line-with-ellipsis">{`${instanceStr}${
                item.component ? ` (${item.component})` : ''
              }`}</div>
              <div style={{ color: 'red', marginLeft: 4 }}>{`${showRCAFlag && item.hasAnomaly ? ' [RC]' : ''}`}</div>
            </div>
          );

          return (
            <SortableItem index={index} key={`separate-${index + 1}`}>
              <div
                style={{
                  position: 'relative',
                  width,
                  height: this.chartHeight,
                  background: 'var(--component-background)',
                  boxShadow: 'none',
                }}
                className="linechart-container"
              >
                <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 9999 }}>
                  <DragHandle intl={intl} />
                </div>
                <LineChart
                  intl={intl}
                  currentTimestamp={currentTimestamp}
                  labelMapping={(node, x) => {
                    const { instanceId } = get(columnInfoMap, [node], {});
                    const component = get(projectInstanceComponentMap, [projectName, instanceId], '');
                    const k8Coverage = k8CoverageMap[instanceId] || [];
                    const findCoverage = R.find((item) => item[1]?.s <= x && x <= item[1]?.e, k8Coverage);
                    let hostID = null;
                    let podID = null;
                    if (findCoverage) {
                      hostID = findCoverage[0]?.h;
                      podID = findCoverage[0]?.p;
                    }
                    return { instanceId, component, hostID, podID };
                  }}
                  samplingInterval={samplingIntervalInSecond * 1000}
                  title={item.name}
                  viewTitle={viewTitle}
                  labels={item.labels}
                  data={item.data}
                  predictionlabelMap={item.predictionlabelMap}
                  annotations={item.annotations}
                  labelsVisibility={item.labelsVisibility}
                  highlightMap={rootCauseMap}
                  onCreateAnnotation={(params) => handleOnCreateAnnotation({ ...params })}
                  dateWindow={chartDateWindow}
                  valueRange={valueRange}
                  onDateWindowChange={this.handleDateWindowSync}
                  isCustomData={isCustomData}
                  instanceDisplayNameMap={instanceDisplayNameMap}
                />
              </div>
            </SortableItem>
          );
        })}
      </SortableList>
    );
  }

  @autobind
  onSortEnd({ oldIndex, newIndex }) {
    const { eventList } = this.state;

    if (R.equals(oldIndex, newIndex)) return;
    this.setState({
      eventList: R.move(oldIndex, newIndex, eventList),
    });
  }

  @autobind
  exportPDF() {
    this.setState({ isLoading: true });
    const { location } = this.props;
    const { projectName, startTimestamp, endTimestamp } = parseLocation(location);
    const { name, unit } = this.state;
    const pdfFilename = `${projectName}-${`${name || ''}${unit ? ` (${unit})` : ''}`}-${moment
      .utc(+startTimestamp)
      .format(Defaults.DateFormat)}-${moment.utc(+endTimestamp).format(Defaults.DateFormat)} linechart.pdf`;
    const pdfContent = document.createElement('div');
    pdfContent.setAttribute('id', 'divToPrint');
    pdfContent.setAttribute(
      'style',
      `min-width: 210mm; 
      width: 100%; 
      min-height: 297mm; 
      height: auto; 
      margin-left: auto; 
      margin-right: auto; 
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      `,
    );

    const linecharts = Array.from(document.getElementsByClassName('linechart-container') || []);
    if (R.isEmpty(linecharts)) return;
    const parentNode = linecharts[0]?.parentNode;
    R.forEach((linechart) => {
      if (linechart) {
        pdfContent.appendChild(linechart);
      }
    }, linecharts);
    const pdfContents = [];
    if (linecharts.length > 10) {
      const length = Math.ceil(linecharts.length / 10);
      for (let i = 0; i < length; i++) {
        pdfContents.push(pdfContent);
      }
    } else {
      pdfContents.push(pdfContent);
    }
    document.body.appendChild(pdfContent);
    downloadPdf(pdfFilename, pdfContents, () => {
      R.forEach((linechart) => {
        if (linechart) {
          parentNode.appendChild(linechart);
        }
      }, linecharts);
      document.body.removeChild(pdfContent);
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { onClose } = this.props;
    const { isLoading } = this.state;
    const { name, unit } = this.state;

    return (
      <Modal
        title={
          <>
            {`${name || ''}${unit ? ` (${unit})` : ''}`}
            <Button
              icon={<PDFIcon style={{ color: 'white', fontSize: 18 }} />}
              Tooltip="Export PDF"
              style={{ marginLeft: 12 }}
              onClick={() => this.exportPDF()}
            />
          </>
        }
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        footer={null}
        width={1200}
        zIndex={999}
        bodyStyle={{ height: 560, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <Spin wrapperClassName="full-width full-height spin-full-height" spinning={isLoading}>
          <AutoSizer>{({ width, height }) => <>{this.renderListItem({ width, height })}</>}</AutoSizer>
        </Spin>
      </Modal>
    );
  }
}

const SeparateInstanceChartModal = injectIntl(SeparateInstanceChartModalCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { projectInstanceComponentMap } = state.app;
  return {
    location,
    projectInstanceComponentMap,
  };
}, {})(SeparateInstanceChartModal);
