/* @flow */
import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import { autobind } from 'core-decorators';
import { get, isNumber } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Select, Spin, Button, notification, Pagination, Popconfirm, Checkbox, message } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import {
  Defaults,
  parseLocation,
  parseJSON,
  LogRenderers,
  CellRenderers,
  CausalParser,
  buildUrl,
  sleep,
  EventRenderers,
  buildLocation,
} from '../../../common/utils';
import { IncidentPredictIcon, RootCauseIcon } from '../../../lib/fui/icons';
import {
  Modal,
  Container,
  AutoSizer,
  Table,
  Column,
  List,
  CellMeasurerCache,
  CellMeasurer,
  Popover,
  SortDirection,
} from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';

import GlobalSystemKnowledgeBaseActionModal from './GlobalSystemKnowledgeBaseActionModal';
import GlobalSystemKnowledgeBaseRecommendationModal from './GlobalSystemKnowledgeBaseRecommendationModal';

type Props = {
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  tabName: String,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  isLoadingSystem: Boolean,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: String,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  globalInfo: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  currentTheme: String,
};

class KnowledgeBaseRuleListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl, location } = props;
    const params = parseLocation(location);
    const { jumpProject, jumpCauseLevel, jumpMergeKey, jumpKey } = params;

    const rootCauseProject = jumpProject;
    const rootCauseLevel = jumpCauseLevel || 'instance';

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

    this.state = {
      isLoaded: false,
      isLoading: false,
      isLoadingLocalList: false,
      isSubmitting: false,

      page: 1,
      pageSize: 20,
      checkAll: false,
      rootCauseProject,
      rootCauseLevel,
      filterIncidentComponent: undefined,
      filterIncidentInstance: undefined,
      filterSourcePatternIds: [],
      filterPatternId: null,
      filterStatus: null,

      predictionRuleList: [],
      filterPredictionRuleList: [],
      incidentList: [],
      activeIncidentKey: undefined,
      incidentPredictionRuleList: [],
      localPredictionRuleList: [],

      showSystemPredictionRuleActionModal: false,
      actionProjectNameList: [],
      actionKeySet: [],
      actionIncidentPredictionLibKeys: [],
      systemProjectNameList: [],
      sortBy: null,
      sortDirection: null,
      addRecommendationModalFlag: false,
      addRecommendationActive: {},
    };
    this.projectList = [];
    this.cmp = (a, b) => a.key === b.key && a.statusFlag === b.statusFlag;
    this.incidentComponentListOptions = [];
    this.incidentInstanceListOptions = [];
    this.sourcePatternListOptions = [];
    this.patternListOptions = [];
    this.statusOptions = [
      { value: -1, label: intl.formatMessage(eventMessages.ignored) },
      { value: 0, label: intl.formatMessage(eventMessages.notInUse) },
      { value: 1, label: intl.formatMessage(settingsMessages.confirmed) },
      { value: 2, label: intl.formatMessage(eventMessages.inUse) },
    ];
    this.jumpMergeKey = jumpMergeKey;
    this.jumpKey = jumpKey;
  }

  componentDidMount() {
    this.resetProject();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const prevQuery = parseLocation(prevProps.location);
    const query = parseLocation(location);
    if (
      prevProps.refresh !== this.props.refresh ||
      prevQuery.refreshTime !== query.refreshTime ||
      prevQuery.environmentId !== query.environmentId ||
      prevQuery.customerName !== query.customerName ||
      prevQuery.systemId !== query.systemId ||
      prevQuery.reloadSystem !== query.reloadSystem
    ) {
      this.resetProject();
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
    let { incidentList } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        incidentList = R.sortWith([R.ascend(R.prop(sortBy))])(incidentList);
        if (sortDirection === SortDirection.DESC) {
          incidentList = R.sortWith([R.descend(R.prop(sortBy))])(incidentList);
        }
        this.setState({ incidentList });
      }
    }
  }

  @autobind
  resetProject() {
    const { location, globalInfo } = this.props;
    const { rootCauseProject } = this.state;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const projectNames = R.map((p) => p.projectNameReal, projectNameSet);
    this.projectList = projectNameSet;

    if (!rootCauseProject || (rootCauseProject && !projectNames.includes(rootCauseProject))) {
      const rootCauseProject = this.projectList.length > 0 ? this.projectList[0].projectNameReal : undefined;
      this.setState({ rootCauseProject }, () => {
        this.reloadData();
      });
    } else {
      this.reloadData();
    }
  }

  @autobind
  reloadData() {
    const { location, userInfo, credentials, globalInfo, projects } = this.props;
    const {
      environmentId,
      startTime,
      endTime,
      customerName,
      systemId,
      jumpProject,
      jumpCauseLevel,
      jumpMergeKey,
      jumpKey,
    } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const { rootCauseProject, rootCauseLevel } = this.state;

    if (
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      startTime &&
      endTime &&
      systemId &&
      systemInfo &&
      systemInfo.hasAllInstanceInfo &&
      rootCauseProject
    ) {
      this.setState({ isLoading: true, page: 1 });
      this.props.updateLastActionInfo();
      Promise.all([
        fetchGet(getEndpoint('predictionrule'), {
          ...credentials,
          projectName: rootCauseProject,
          isComponentLevel: rootCauseLevel === 'component',
        }),
      ])
        .then((results) => {
          // get all instance info and component info
          const environment = R.find((e) => e.id === environmentId, globalInfo);
          const systemList = get(environment, 'systemList', []);
          const systemInfo = R.find((system) => system.id === systemId, systemList);
          const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

          let predictionRuleList = [];
          let incidentComponents = [];
          let incidentInstances = [];
          let patternIdsSource = [];
          let patternIds = [];

          // parse data
          R.forEach((result) => {
            R.addIndex(R.forEach)((item, idx) => {
              const { incidentPredictionLibPrimaryKey, projectActionKeySet, predictionRules } = item;
              const { contentMatchKey, key } = incidentPredictionLibPrimaryKey;
              const { projectName: srcProjectName, instanceName, userName } = key;
              const sourceProjectName = `${srcProjectName}@${userName}`;
              const sourceInstanceName = instanceName;
              R.forEach((rule) => {
                const { statusFlag, incidentRule, ...rest } = rule;
                const { componentName, metricInstanceName, patternId, patternName, incidentData } = incidentRule;
                const incidentCompositeKey = get(incidentRule, ['incidentKey', 'incidentCompositeKey'], {});
                const { projectName, userName, id } = incidentCompositeKey;
                const incidentProjectName = `${projectName}@${userName}`;
                const incidentInstanceName = id;
                const incidentComponent =
                  get(instanceComponentMap, metricInstanceName) ||
                  get(instanceComponentMap, incidentInstanceName) ||
                  componentName;
                const incidentPatternId = patternId;
                const incidentPatternName = patternName;
                const { patternNameStr: incidentPatternNameStr } = Defaults.PatternIdNameStr(
                  { patternName: incidentPatternName, patternId: incidentPatternId },
                  { hasFullName: true },
                );
                const mergeKey = `${projectName}@${userName}-${
                  rootCauseLevel === 'component' ? incidentComponent : incidentInstanceName
                }-${patternId}`;

                // parse predictionSourceInfo
                const sourcePatternIds = [];
                let predictionSourceInfo = incidentRule.predictionSourceInfo || [];
                predictionSourceInfo = R.map((item) => {
                  const { sourceProjectName } = item;
                  const project = R.find((project) => project.projectShortName === sourceProjectName, projects || []);

                  const sourceDetail = parseJSON(item.sourceDetail) || {};
                  const { nid, patternName } = sourceDetail;
                  const { patternNameStr: sourcePatternNameStr } = Defaults.PatternIdNameStr(
                    { patternName, patternId: nid },
                    { hasFullName: true },
                  );

                  let { type } = sourceDetail;
                  let { isLogType, eventType } = CausalParser.getRelationLogType(type);
                  if (project && project.isDeployment) {
                    type = 'deployment';
                    isLogType = true;
                    eventType = 'deployment';
                  }

                  sourcePatternIds.push(nid);

                  return {
                    ...item,
                    sourceDetail: { ...sourceDetail, type, isLogType, eventType },
                    patternId: nid,
                    patternName,
                    sourcePatternNameStr,
                  };
                }, predictionSourceInfo);
                const sourceInfo = predictionSourceInfo[0] || {};
                const sourceDetail = sourceInfo.sourceDetail || {};

                let allSourcePatternIds = sourcePatternIds;
                allSourcePatternIds = R.sort((a, b) => a - b, R.uniq(allSourcePatternIds));
                let allPatternIds = [incidentPatternId];
                allPatternIds = R.sort((a, b) => a - b, R.uniq(allPatternIds));

                incidentComponents = [...incidentComponents, incidentComponent];
                incidentInstances = [...incidentInstances, incidentInstanceName];
                patternIdsSource = [...patternIdsSource, ...allSourcePatternIds];
                patternIds = [...patternIds, ...allPatternIds];

                const key =
                  `${sourceProjectName}-${sourceInstanceName}-${contentMatchKey}` +
                  `-${incidentProjectName}-${incidentInstanceName}-${incidentPatternId}`;
                predictionRuleList.push({
                  rawIndex: idx,
                  key,
                  mergeKey,

                  incidentPredictionLibPrimaryKey,
                  projectActionKeySet,
                  ...rest,
                  // incident info
                  incidentCompositeKey,
                  incidentProjectName,
                  incidentComponent,
                  incidentInstanceName,
                  incidentPatternId,
                  incidentPatternName,
                  incidentPatternNameStr,
                  incidentData,

                  // prediction source info
                  projectName: sourceProjectName,
                  componentName: get(
                    instanceComponentMap,
                    sourceInfo.sourceInstanceName,
                    sourceInfo.sourceInstanceName,
                  ),
                  instanceName: sourceInfo.sourceInstanceName,
                  metricInstanceName: sourceInfo.metricInstanceName,
                  contentMatchKey,
                  sourcePatternIds,
                  statusFlag,
                  sourceDetail,
                  predictionSourceInfo,

                  // filter used info
                  allSourcePatternIds,
                  allPatternIds,
                });
              }, predictionRules || []);
            }, result);
          }, results);
          predictionRuleList = R.sortWith([R.descend(R.prop('matchedCount'))], predictionRuleList);
          incidentComponents = R.sort(
            (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
            R.uniq(incidentComponents),
          );
          incidentInstances = R.sort(
            (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
            R.uniq(incidentInstances),
          );
          patternIdsSource = R.sort((a, b) => a - b, R.uniq(patternIdsSource));
          patternIds = R.sort((a, b) => a - b, R.uniq(patternIds));

          // build root cause list
          const filterPredictionRuleList = this.filterData(predictionRuleList);

          // build incident list
          const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
            this.buildIncidentList(filterPredictionRuleList);

          this.incidentComponentListOptions = R.map(
            (component) => ({ value: component, label: component }),
            incidentComponents,
          );
          this.incidentInstanceListOptions = R.map(
            (instance) => ({ value: instance, label: instance }),
            incidentInstances,
          );
          this.sourcePatternListOptions = R.map(
            (patternId) => ({ value: patternId, label: patternId }),
            patternIdsSource,
          );
          this.patternListOptions = R.map((patternId) => ({ value: patternId, label: patternId }), patternIds);
          this.setState(
            {
              isLoaded: true,
              isLoading: false,
              predictionRuleList,

              filterPredictionRuleList,
              incidentList,
              activeIncidentKey: this.jumpMergeKey || activeIncidentKey,
              incidentPredictionRuleList,
              localPredictionRuleList,
            },
            () => {
              this.jumpMergeKey = undefined;
              if (jumpProject && jumpCauseLevel && jumpMergeKey && jumpKey) {
                this.clearJumpData();
              }
            },
          );
        })
        .catch((err) => {
          this.incidentComponentListOptions = [];
          this.incidentInstanceListOptions = [];
          this.sourcePatternListOptions = [];
          this.patternListOptions = [];
          this.setState(
            {
              isLoaded: true,
              isLoading: false,
              predictionRuleList: [],
              filterPredictionRuleList: [],
              incidentList: [],
              activeIncidentKey: undefined,
              incidentPredictionRuleList: [],
              localPredictionRuleList: [],
            },
            () => {
              if (jumpProject && jumpCauseLevel && jumpMergeKey && jumpKey) {
                this.clearJumpData();
              }
            },
          );
        });
    } else {
      this.incidentComponentListOptions = [];
      this.incidentInstanceListOptions = [];
      this.sourcePatternListOptions = [];
      this.patternListOptions = [];
      this.setState(
        {
          isLoaded: true,
          isLoading: false,
          predictionRuleList: [],
          filterPredictionRuleList: [],
          incidentList: [],
          activeIncidentKey: undefined,
          incidentPredictionRuleList: [],
          localPredictionRuleList: [],
        },
        () => {
          if (jumpProject && jumpCauseLevel && jumpMergeKey && jumpKey) {
            this.clearJumpData();
          }
        },
      );
    }
  }

  @autobind
  clearJumpData() {
    const { location, replace } = this.props;
    const params = parseLocation(location);
    replace(
      buildLocation(
        location.pathname,
        {},
        {
          ...params,
          jumpProject: undefined,
          jumpCauseLevel: undefined,
          jumpMergeKey: undefined,
          jumpKey: undefined,
        },
      ),
    );
  }

  @autobind
  handleSaveClick() {
    const { intl, credentials } = this.props;
    const { localPredictionRuleList, filterPredictionRuleList } = this.state;

    this.setState({ isSubmitting: true });
    let diff = R.differenceWith(this.cmp, localPredictionRuleList, filterPredictionRuleList);
    diff = R.map((item) => {
      const {
        projectName,
        instanceName,
        contentMatchKey,
        incidentProjectName,
        incidentInstanceName,
        incidentPatternId,
        statusFlag,
      } = item;
      return {
        projectName,
        instanceName,
        contentMatchKey,
        incidentProjectName,
        incidentInstanceName,
        incidentPatternId,
        statusFlag,
      };
    }, diff);

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('predictionrule'), {
      ...credentials,
      rules: JSON.stringify(diff),
      operation: 'update',
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData();
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  handleRuleRemove() {
    const { intl, credentials } = this.props;
    const { localPredictionRuleList } = this.state;

    this.setState({ isSubmitting: true });
    let diff = R.filter((item) => item.checked, localPredictionRuleList);
    diff = R.map((item) => {
      const {
        projectName,
        instanceName,
        contentMatchKey,
        incidentProjectName,
        incidentInstanceName,
        incidentPatternId,
      } = item;
      return {
        projectName,
        instanceName,
        contentMatchKey,
        incidentProjectName,
        incidentInstanceName,
        incidentPatternId,
      };
    }, diff);

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('predictionrule'), {
      ...credentials,
      rules: JSON.stringify(diff),
      operation: 'delete',
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData();
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  handleRuleRemoveAll() {
    const { intl } = this.props;
    const { rootCauseProject } = this.state;
    Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <>
          <div>{intl.formatMessage(appMessages.removeProjectAllRule, { projectName: rootCauseProject })}</div>
          <div>{intl.formatMessage(appMessages.continueConfirm)}</div>
          <br />
          <div className="flex-row flex-center-align">
            <div style={{ marginRight: 16 }}>Operation:</div>
            <Select
              size="small"
              style={{ width: '100%' }}
              defaultValue="instance"
              onChange={(operation) => {
                this.removeRuleOperation = operation;
              }}
            >
              <Select.Option key="instance">Instance</Select.Option>
              <Select.Option key="component">Component</Select.Option>
            </Select>
          </div>
        </>
      ),
      onOk: this.handleRuleRemoveAllConfirm,
    });
  }

  @autobind
  handleRuleRemoveAllConfirm(close) {
    const { intl, credentials } = this.props;
    const { rootCauseProject } = this.state;
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('predictionrule'), {
      ...credentials,
      projectName: rootCauseProject,
      operation: 'deleteAll',
      isComponentLevel: this.removeRuleOperation === 'component',
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData();
        close();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        close();
      });
  }

  @autobind
  filterData(predictionRuleList) {
    const { filterIncidentComponent, filterIncidentInstance, filterSourcePatternIds, filterPatternId, filterStatus } =
      this.state;
    let filterPredictionRuleList = predictionRuleList || [];

    if (filterIncidentComponent) {
      filterPredictionRuleList = R.filter(
        (rule) => rule.incidentComponent === filterIncidentComponent,
        filterPredictionRuleList,
      );
    }
    if (filterIncidentInstance) {
      filterPredictionRuleList = R.filter(
        (rule) => rule.incidentInstanceName === filterIncidentInstance,
        filterPredictionRuleList,
      );
    }
    if (filterSourcePatternIds && filterSourcePatternIds.length > 0) {
      filterPredictionRuleList = R.filter((rule) => {
        // either of filterSourcePatternIds is include
        // const hasSearchVal = R.find((item) => filterSourcePatternIds.includes(item), rule.allSourcePatternIds);
        // need both include filterSourcePatternIds
        const hasSearchVal = R.difference(filterSourcePatternIds, rule.allSourcePatternIds).length === 0;
        return hasSearchVal;
      }, filterPredictionRuleList);
    }
    if (filterPatternId) {
      filterPredictionRuleList = R.filter(
        (rule) => rule.allPatternIds.includes(Number(filterPatternId)),
        filterPredictionRuleList,
      );
    }
    if (isNumber(filterStatus)) {
      filterPredictionRuleList = R.filter((rule) => rule.statusFlag === filterStatus, filterPredictionRuleList);
    }

    return filterPredictionRuleList;
  }

  @autobind
  buildIncidentList(filterPredictionRuleList) {
    const { credentials, intl } = this.props;
    const incidentListMap = {};
    if (!this.jumpMergeKey) {
      this.jumpKey = undefined;
    }
    R.forEach((item) => {
      const {
        mergeKey,
        incidentCompositeKey,
        incidentProjectName,
        incidentComponent,
        incidentInstanceName,
        incidentPatternId,
        incidentPatternName,
        incidentPatternNameStr,
        incidentData,
        matchedCount,
      } = item;
      if (!R.has(mergeKey, incidentListMap)) {
        incidentListMap[mergeKey] = [];
      }
      incidentListMap[mergeKey].push({
        mergeKey,
        incidentCompositeKey,
        incidentProjectName,
        incidentComponent,
        incidentInstanceName,
        incidentPatternId,
        incidentPatternName,
        incidentPatternNameStr,
        incidentData,

        matchedCount,
      });
    }, filterPredictionRuleList);

    let incidentList = [];
    R.forEachObjIndexed((val) => {
      const info = val[0] || {};
      const matchedCount = R.sum(R.map((item) => item.matchedCount, val));
      const rootCauseCount = val.length;
      incidentList.push({
        ...info,

        matchedCount,
        rootCauseCount,
      });
    }, incidentListMap);

    incidentList = R.sortWith([R.descend(R.prop('matchedCount')), R.ascend(R.prop('incidentPatternId'))], incidentList);
    // reset incident key
    const incidentListKeys = R.map((item) => item.mergeKey, incidentList);
    let { activeIncidentKey } = this.state;
    if (incidentListKeys.indexOf(activeIncidentKey) === -1) {
      activeIncidentKey = incidentListKeys[0];
    }
    activeIncidentKey = this.jumpMergeKey || activeIncidentKey;

    // get prediction rules by incident
    const incidentPredictionRuleList = R.filter(
      (item) => item.mergeKey === activeIncidentKey,
      filterPredictionRuleList,
    );

    // get local prediciton rules by page
    const { page, pageSize } = this.state;
    const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);

    const payloads = R.map((item) => {
      const { incidentCompositeKey, incidentPatternId, mergeKey } = item;
      return {
        projectName: incidentCompositeKey?.projectName,
        customerName: incidentCompositeKey?.userName,
        instanceName: incidentCompositeKey?.id,
        patternId: incidentPatternId,
        mergeKey,
      };
    }, incidentList);
    fetchPost(getEndpoint('kb-ignore-flag-state'), {
      ...credentials,
      projectList: JSON.stringify(payloads),
    })
      .then((data) => {
        const { success, payload } = data;
        if (success) {
          R.forEach((item) => {
            const { mergeKey, kbIgnoreFlag } = item;
            const index = R.findIndex(R.propEq('mergeKey', mergeKey))(incidentList);
            incidentList[index].kbIgnoreFlag = kbIgnoreFlag;
          }, payload);

          this.cellMeasureCache.clearAll();
          if (this.dataTable) {
            this.dataTable.forceUpdate();
            this.dataTable.forceUpdateGrid();
          }
          this.forceUpdate();
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isLoading: false,
        });
      });

    return { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList };
  }

  @autobind
  onChangeRootCauseProject(rootCauseProject) {
    this.setState({ rootCauseProject }, () => {
      this.reloadData();
    });
  }

  @autobind
  onChangeRootCauseLevel(rootCauseLevel) {
    this.setState({ rootCauseLevel }, () => {
      this.reloadData();
    });
  }

  @autobind
  onChangeFilterIncidentComponent(filterIncidentComponent) {
    const { predictionRuleList } = this.state;
    this.setState({ filterIncidentComponent, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  onChangeFilterIncidentInstance(filterIncidentInstance) {
    const { predictionRuleList } = this.state;
    this.setState({ filterIncidentInstance, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  onChangeFilterSourcePatternId(filterSourcePatternIds) {
    const { predictionRuleList } = this.state;
    this.setState({ filterSourcePatternIds, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  onChangeFilterPatternId(filterPatternId) {
    const { predictionRuleList } = this.state;
    this.setState({ filterPatternId, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  onChangeFilterStatus(filterStatus) {
    const { predictionRuleList } = this.state;
    this.setState({ filterStatus, page: 1, checkAll: false, isLoading: true }, async () => {
      await sleep(300);

      const filterPredictionRuleList = this.filterData(predictionRuleList);
      const { incidentList, activeIncidentKey, incidentPredictionRuleList, localPredictionRuleList } =
        this.buildIncidentList(filterPredictionRuleList);

      this.setState({
        isLoading: false,
        filterPredictionRuleList,
        incidentList,
        activeIncidentKey,
        incidentPredictionRuleList,
        localPredictionRuleList,
      });
    });
  }

  @autobind
  handlePaginationChange(page, pageSize) {
    this.setState({ page, pageSize, checkAll: false, isLoadingLocalList: true }, async () => {
      await sleep(300);

      const { incidentPredictionRuleList } = this.state;
      const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      this.setState({ isLoadingLocalList: false, localPredictionRuleList });
    });
  }

  @autobind
  handlePaginationSizeChange(oldPage, pageSize) {
    const page = 1;
    this.setState({ page, pageSize, checkAll: false, isLoadingLocalList: true }, async () => {
      await sleep(300);

      const { incidentPredictionRuleList } = this.state;
      const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      this.setState({ isLoadingLocalList: false, localPredictionRuleList });
    });
  }

  @autobind
  handleIncidentClick(rowData) {
    const activeIncidentKey = rowData.mergeKey;
    const { filterPredictionRuleList } = this.state;
    this.setState({ activeIncidentKey, page: 1, checkAll: false, isLoadingLocalList: true }, async () => {
      await sleep(300);

      // get prediction rules by incident
      const incidentPredictionRuleList = R.filter(
        (item) => item.mergeKey === activeIncidentKey,
        filterPredictionRuleList,
      );

      // get local prediciton rules by page
      const { page, pageSize } = this.state;
      const localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      this.jumpKey = undefined;
      this.setState({ isLoadingLocalList: false, incidentPredictionRuleList, localPredictionRuleList });
    });
  }

  @autobind
  renderIncidentPattern({ cellData, rowData }) {
    const { intl, credentials, projectDisplayMap, currentTheme } = this.props;
    const { rootCauseLevel } = this.state;
    const {
      incidentCompositeKey,
      incidentComponent,
      incidentInstanceName,
      incidentPatternNameStr,
      incidentData: content,
    } = rowData;
    const { projectName, userName } = incidentCompositeKey;
    const projectNameReal = userName !== credentials.userName ? `${projectName}@${userName}` : projectName;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(content);
    } catch (error) {
      // console.debug(error)
    }

    return (
      <Popover
        placement="right"
        mouseEnterDelay={0.3}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 350, paddingRight: 8 }}>
            <div className="flex-row">
              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </div>
              <div style={{ wordBreak: 'break-all' }}>{get(projectDisplayMap, projectNameReal, projectNameReal)}</div>
            </div>
            <div className="flex-row">
              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </div>
              <div style={{ wordBreak: 'break-all' }}>{incidentComponent}</div>
            </div>
            {rootCauseLevel === 'instance' && (
              <div className="flex-row">
                <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                  {intl.formatMessage(appFieldsMessages.instance)}:
                </div>
                <div style={{ wordBreak: 'break-all' }}>{incidentInstanceName}</div>
              </div>
            )}
            <div className="flex-row">
              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.pattern)}:
              </div>
              <div style={{ wordBreak: 'break-all' }}>{incidentPatternNameStr}</div>
            </div>

            <div className="full-width flex-row">
              <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                {intl.formatMessage(eventMessages.shortDescription)}:
              </div>
            </div>
            <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
              {!rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (content || '').split('\n')),
                )}
              {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
            </div>
          </div>
        }
      >
        <div className="hidden-line-with-ellipsis inline-block max-width">{cellData}</div>
      </Popover>
    );
  }

  @autobind
  renderKBIgnoreFlag({ cellData, rowData }) {
    const { intl } = this.props;
    return (
      <>
        {cellData ? (
          <Popover
            title={null}
            content={intl.formatMessage(eventMessages.noIgnorePattern)}
            mouseEnterDelay={0.3}
            placement="left"
          >
            <EyeInvisibleOutlined
              style={{ color: '#c3c3c3' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick(rowData);
              }}
            />
          </Popover>
        ) : (
          <Popover
            title={null}
            content={intl.formatMessage(eventMessages.ignorePattern)}
            mouseEnterDelay={0.3}
            placement="left"
          >
            <EyeOutlined
              style={{ color: '#c3c3c3' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick(rowData);
              }}
            />
          </Popover>
        )}
      </>
    );
  }

  @autobind
  handleIgnoreClick(rowData) {
    const { credentials, intl } = this.props;
    const { incidentPatternName, incidentPatternId, kbIgnoreFlag, incidentInstanceName, incidentCompositeKey } =
      rowData;
    const content = kbIgnoreFlag
      ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
          pattern: incidentPatternName || incidentPatternId,
          status: 'Unignore the pattern',
        })
      : intl.formatMessage(eventMessages.markIgnoreStatus, {
          pattern: incidentPatternName || incidentPatternId,
          status: 'Ignore pattern',
        });
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content,
      okText: intl.formatMessage(appButtonsMessages.submit),
      cancelText: intl.formatMessage(appButtonsMessages.cancel),
      onOk: () => {
        this.setState({ isLoading: true }, () => {
          fetchPost(getEndpoint('events'), {
            ...credentials,
            operation: kbIgnoreFlag ? 'cancelKBIgnoreFlag' : 'setKBIgnoreFlag',
            projectName: incidentCompositeKey?.projectName,
            instanceName: incidentInstanceName,
            customerName: incidentCompositeKey?.userName,
            nid: incidentPatternId,
          })
            .then((data) => {
              if (data.success) {
                message.success(intl.formatMessage(appMessages.apiSuccess));
                this.reloadData();
              } else {
                message.error(intl.formatMessage(appMessages.apiFaild));
              }
            })
            .catch((err) => {
              message.error(intl.formatMessage(appMessages.apiFaild));
              this.setState({
                isLoading: false,
              });
            });
        });
      },
    });
  }

  @autobind
  handleCheckAllChange(e) {
    const checkAll = e.target.checked;
    const { localPredictionRuleList } = this.state;
    const newLocalPredictionRuleList = R.map((item) => {
      return { ...item, checked: checkAll };
    }, localPredictionRuleList);
    this.setState({ checkAll, localPredictionRuleList: newLocalPredictionRuleList });
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }) {
    const rowData = this.state.localPredictionRuleList[rowIndex];
    if (!rowData) return null;

    const {
      checked,

      statusFlag,
      matchedCount,
    } = rowData;

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div
          className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''} ${
            this.jumpKey === rowData.key ? ' active' : ''
          }`}
          style={{ ...style }}
        >
          <div className="row-column flex-center-justify" style={{ width: 40 }}>
            <Checkbox
              checked={checked}
              onChange={(e) => {
                // Save the data and force update.
                const checked = e.target.checked || false;
                const { localPredictionRuleList } = this.state;
                this.setState({
                  localPredictionRuleList: update(localPredictionRuleList, {
                    [rowIndex]: { $set: { ...rowData, checked } },
                  }),
                });
              }}
            />
          </div>

          <div className="row-column" style={{ width: 140 }}>
            {matchedCount}
          </div>
          <div className="row-column" style={{ width: 160, flex: 1 }}>
            {this.renderSourcePatterns(rowData)}
          </div>
          <div className="row-column flex-row flex-end-justify" style={{ width: 120 }}>
            <Select
              style={{ width: '100%' }}
              value={statusFlag}
              onChange={(statusFlag) => this.handleStatusChange(statusFlag, rowData, rowIndex)}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ),
                this.statusOptions || [],
              )}
            </Select>
          </div>
          <div className="row-column flex-col" style={{ width: 360 }}>
            {this.renderControl(rowData)}
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  handleStatusChange(statusFlag, rowData, rowIndex) {
    const { localPredictionRuleList } = this.state;
    this.setState({
      localPredictionRuleList: update(localPredictionRuleList, {
        [rowIndex]: { $set: { ...rowData, statusFlag } },
      }),
    });
  }

  @autobind
  renderSourcePatterns(rowData) {
    const { intl, credentials, projectDisplayMap, currentTheme } = this.props;
    const { rootCauseLevel } = this.state;
    const { predictionSourceInfo } = rowData;
    const contents = R.addIndex(R.map)((item, index) => {
      const content = R.equals(item.dataType, 'Metric')
        ? item.sourceDetail.content
        : item.sourceDetail.content.substr(0, 50);
      return (
        <div
          key={`rootCauseContentKey${index}`}
          className="hidden-line-with-ellipsis inline-block max-width"
          style={{ display: 'block' }}
        >
          {content}
        </div>
      );
    }, predictionSourceInfo);

    return (
      <Popover
        placement="right"
        mouseEnterDelay={0.3}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 350, paddingRight: 8 }}>
            {R.addIndex(R.map)((item, index) => {
              const {
                sourceDetail,
                sourceProjectName,
                sourceProjectOwner,
                sourceInstanceName,
                sourcePatternNameStr,
                componentName,
              } = item;
              const { isLogType, eventType } = sourceDetail || {};
              const { content, metricDirection } = sourceDetail || {};
              const projectNameReal =
                sourceProjectOwner !== credentials.userName
                  ? `${sourceProjectName}@${sourceProjectOwner}`
                  : sourceProjectName;
              let rawDataJson;
              try {
                rawDataJson = JSON.parse(content);
              } catch (error) {
                // console.debug(error)
              }

              return (
                <div key={index} className="flex-col" style={{ marginBottom: 16 }}>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(eventMessages.rootCauseHop)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>{index + 1}</div>
                  </div>
                  <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.project)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>
                      {get(projectDisplayMap, projectNameReal, projectNameReal)}
                    </div>
                  </div>
                  <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.component)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>{componentName}</div>
                  </div>
                  {rootCauseLevel === 'instance' && (
                    <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                      <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                        {intl.formatMessage(appFieldsMessages.instance)}:
                      </div>
                      <div style={{ wordBreak: 'break-all' }}>{sourceInstanceName}</div>
                    </div>
                  )}
                  <div className="flex-row">
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.pattern)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>{sourcePatternNameStr}</div>
                  </div>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.type)}:
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>
                      {isLogType
                        ? CellRenderers.logTypeRenderer({ intl, rowData: { type: eventType }, isHorizontal: true })
                        : intl.formatMessage(appFieldsMessages.metric)}
                    </div>
                  </div>

                  <div className="full-width flex-row">
                    <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                      {intl.formatMessage(eventMessages.shortDescription)}:
                    </div>
                    {metricDirection && (
                      <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                        {EventRenderers.RenderMetricAnomalySummary({ intl, event: sourceDetail })}
                      </div>
                    )}
                  </div>

                  {!metricDirection && (
                    <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                      {!rawDataJson &&
                        R.join(
                          '\n',
                          R.filter((x) => Boolean(x), (content || '').split('\n')),
                        )}
                      {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                    </div>
                  )}
                </div>
              );
            }, predictionSourceInfo)}
          </div>
        }
      >
        <div style={{ overflow: 'hidden' }} className="max-width ">
          {contents}
        </div>
      </Popover>
    );
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;
    const { matchedCount, unmatchedCount, falsePositiveInfo, truePositiveInfo, rootCauseInfo } = rowData;

    return (
      <div className="flex-row">
        <Popover
          placement="left"
          trigger="click"
          content={
            <div
              className="flex-col"
              style={{
                maxWidth: 450,
                maxHeight: 300,
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120 }}>
                  {intl.formatMessage(settingsMessages.matchedCount)}:
                </div>
                <div>{matchedCount}</div>
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120 }}>
                  {intl.formatMessage(settingsMessages.unmatchedCount)}:
                </div>
                <div>{unmatchedCount}</div>
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120 }}>
                  {intl.formatMessage(settingsMessages.truePositiveInfo)}:
                </div>
                <div className="flex-col">
                  {R.map(
                    (item) => (
                      <div key={item.timestamp} className="flex-row flex-center-align" style={{ lineHeight: '24px' }}>
                        <span style={{ width: 126 }}>{moment.utc(item.timestamp).format(Defaults.DateTimeFormat)}</span>
                        {item.sourceFlag !== 2 && (
                          <>
                            {item.sourceFlag !== undefined && (
                              <Button
                                size="small"
                                style={{ paddingTop: 0, paddingBottom: 0, height: 20 }}
                                onClick={() => this.handleIncidentJump(rowData, item)}
                              >
                                {intl.formatMessage(appFieldsMessages.details)}
                              </Button>
                            )}
                            {item.sourceFlag === 0 && <RootCauseIcon style={{ color: '#FF5142', margin: '0 8px' }} />}
                            {item.sourceFlag === 1 && (
                              <IncidentPredictIcon style={{ color: '#FF5142', margin: '0 8px' }} />
                            )}
                          </>
                        )}
                      </div>
                    ),
                    truePositiveInfo || [],
                  )}
                </div>
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120 }}>
                  {intl.formatMessage(settingsMessages.falsePositiveInfo)}:
                </div>
                <div className="flex-col">
                  {R.map(
                    (item) => (
                      <div key={item.timestamp} className="flex-row flex-center-align" style={{ lineHeight: '24px' }}>
                        <span style={{ width: 126 }}>{moment.utc(item.timestamp).format(Defaults.DateTimeFormat)}</span>
                        {item.sourceFlag !== 2 && (
                          <>
                            {item.sourceFlag !== undefined && (
                              <Button
                                size="small"
                                style={{ paddingTop: 0, paddingBottom: 0, height: 20 }}
                                onClick={() => this.handleIncidentJump(rowData, item)}
                              >
                                {intl.formatMessage(appFieldsMessages.details)}
                              </Button>
                            )}
                            {item.sourceFlag === 0 && <RootCauseIcon style={{ color: '#FF5142', margin: '0 8px' }} />}
                            {item.sourceFlag === 1 && (
                              <IncidentPredictIcon style={{ color: '#FF5142', margin: '0 8px' }} />
                            )}
                          </>
                        )}
                      </div>
                    ),
                    falsePositiveInfo || [],
                  )}
                </div>
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120 }}>
                  {intl.formatMessage(settingsMessages.rootCauseInfo)}:
                </div>
                <div className="flex-col">
                  {R.map(
                    (item) => (
                      <div key={item.timestamp} className="flex-row flex-center-align" style={{ lineHeight: '24px' }}>
                        <span style={{ width: 126 }}>{moment.utc(item.timestamp).format(Defaults.DateTimeFormat)}</span>
                        <Button
                          size="small"
                          style={{ paddingTop: 0, paddingBottom: 0, height: 20 }}
                          onClick={() => this.handleIncidentJump(rowData, { ...item, sourceFlag: 0 })}
                        >
                          {intl.formatMessage(appFieldsMessages.details)}
                        </Button>
                      </div>
                    ),
                    rootCauseInfo || [],
                  )}
                </div>
              </div>
            </div>
          }
        >
          <Button size="small">{intl.formatMessage(appFieldsMessages.statistics)}</Button>
        </Popover>

        <Button
          type="primary"
          size="small"
          style={{ marginLeft: 8 }}
          onClick={() => this.handleConfigActionsClick(rowData)}
        >
          {intl.formatMessage(eventMessages.takeAction)}
        </Button>

        <Button
          type="primary"
          size="small"
          style={{ marginLeft: 8 }}
          onClick={() => this.handleAddRecommendationClick(rowData)}
        >
          {intl.formatMessage(eventMessages.addRecommendation)}
        </Button>
      </div>
    );
  }

  @autobind
  handleAddRecommendationClick(rowData) {
    this.setState({ addRecommendationModalFlag: true, addRecommendationActive: rowData });
  }

  @autobind
  handleConfigActionsClick(rowData) {
    const { location, credentials, systemsMap, projectDisplayMap } = this.props;
    const { systemId } = parseLocation(location);
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const systemProjectNameList = R.map((item) => {
      const projectNameReal =
        item.customerName !== credentials.userName ? `${item.projectName}@${item.customerName}` : item.projectName;
      return {
        projectNameReal,
        projectDisplayName: get(projectDisplayMap, projectNameReal, projectNameReal),
      };
    }, get(systemInfo, 'projectDetailsList', []));

    let actionProjectNameList = [];
    let actionKeySet = [];
    const actionIncidentPredictionLibKeys = [];

    const { incidentPredictionLibPrimaryKey, predictionSourceInfo, projectActionKeySet } = rowData;
    R.forEach((item) => {
      const { sourceProjectName, sourceProjectOwner } = item;
      actionProjectNameList.push(`${sourceProjectName}@${sourceProjectOwner}`);
    }, predictionSourceInfo);
    actionProjectNameList = R.uniq(actionProjectNameList);
    actionKeySet = [...actionKeySet, ...(projectActionKeySet || [])];
    actionKeySet = R.uniqWith(R.eqBy(R.prop('actionId')), R.uniq(actionKeySet));
    actionIncidentPredictionLibKeys.push(incidentPredictionLibPrimaryKey);

    this.setState({
      showSystemPredictionRuleActionModal: true,
      actionProjectNameList,
      actionKeySet,
      actionIncidentPredictionLibKeys,
      systemProjectNameList,
    });
  }

  @autobind
  handleMultipleConfigActionsClick() {
    const { location, credentials, systemsMap, projectDisplayMap } = this.props;
    const { systemId } = parseLocation(location);
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const systemProjectNameList = R.map((item) => {
      const projectNameReal =
        item.customerName !== credentials.userName ? `${item.projectName}@${item.customerName}` : item.projectName;
      return {
        projectNameReal,
        projectDisplayName: get(projectDisplayMap, projectNameReal, projectNameReal),
      };
    }, get(systemInfo, 'projectDetailsList', []));

    let actionProjectNameList = [];
    let actionKeySet = [];
    const actionIncidentPredictionLibKeys = [];

    const { localPredictionRuleList } = this.state;
    const diffChecked = R.filter((item) => item.checked, localPredictionRuleList);
    R.forEach((item) => {
      const { incidentPredictionLibPrimaryKey, predictionSourceInfo, projectActionKeySet } = item;
      R.forEach((item) => {
        const { sourceProjectName, sourceProjectOwner } = item;
        actionProjectNameList.push(`${sourceProjectName}@${sourceProjectOwner}`);
      }, predictionSourceInfo);
      actionKeySet = [...actionKeySet, ...(projectActionKeySet || [])];
      actionIncidentPredictionLibKeys.push(incidentPredictionLibPrimaryKey);
    }, diffChecked);
    actionProjectNameList = R.uniq(actionProjectNameList);
    actionKeySet = R.uniqWith(R.eqBy(R.prop('actionId')), R.uniq(actionKeySet));

    this.setState({
      showSystemPredictionRuleActionModal: true,
      actionProjectNameList,
      actionKeySet,
      actionIncidentPredictionLibKeys,
      systemProjectNameList,
    });
  }

  @autobind
  handleIncidentJump(rowData, positiveInfo) {
    const { projects } = this.props;
    const { timestamp, sourceFlag, libRootCause } = positiveInfo;
    const { incidentCompositeKey, incidentInstanceName, incidentPatternId } = rowData;
    const { projectName } = incidentCompositeKey || {};
    const project = R.find((project) => project.projectShortName === projectName, projects || []);
    const { systemId, owner } = project;
    const { incidentInstanceName: matchedInstanceName, rootCauseKey } = libRootCause || {};

    const startTime = moment.utc(timestamp).format(Defaults.DateFormat);
    if (sourceFlag === 1) {
      const query = {
        environmentId: 'All',
        startTime,
        endTime: startTime,
        customerName: owner,
        systemId,

        hideIgnore: false,
        typeFilter: 'all',
        eventPatternId: incidentPatternId,
        eventProjectName: projectName,
        eventInstanceName: matchedInstanceName || incidentInstanceName,
        eventTimestamp: timestamp,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemPrediction, {}, query), '_blank');
    } else {
      const query = {
        environmentId: 'All',
        startTime,
        endTime: startTime,
        customerName: owner,
        systemId,

        eventCategory: 'incident',
        eventPatternType: 'incident',
        eventPatternId: incidentPatternId,
        eventProjectName: projectName,
        eventInstanceName: matchedInstanceName || incidentInstanceName,
        eventTimestamp: timestamp,
        eventRootCauseKey: rootCauseKey,
        hideIgnore: false,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    }
  }

  @autobind
  sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.descend(R.prop('matchedCount'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortByList, sortDirectionList, localPredictionRuleList } = this.state;
      let sortDir = sortDirectionList === 'ASC' ? 'DESC' : sortDirectionList === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortByList) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortByList: name, sortDirectionList: sortDir }, () => {
          const newEventList = this.sortData(localPredictionRuleList, name, sortDir);
          this.setState({ localPredictionRuleList: newEventList });
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  render() {
    const { intl, location, isLoadingSystem, credentials } = this.props;
    const {
      isLoaded,
      isLoading,
      isLoadingLocalList,
      isSubmitting,

      rootCauseProject,
      rootCauseLevel,
      filterIncidentComponent,
      filterIncidentInstance,
      filterSourcePatternIds,
      filterPatternId,
      filterStatus,

      incidentList,
      activeIncidentKey,
      incidentPredictionRuleList,
      localPredictionRuleList,

      page,
      pageSize,
      checkAll,

      sortBy,
      sortDirection,

      sortByList,
      sortDirectionList,
      addRecommendationModalFlag,
      addRecommendationActive,
    } = this.state;
    const { systemId } = parseLocation(location);

    const diff = R.differenceWith(this.cmp, localPredictionRuleList, incidentPredictionRuleList);
    const hasError = diff.length === 0;
    const diffChecked = R.filter((item) => item.checked, localPredictionRuleList);
    const hasErrorRemove = diffChecked.length === 0;
    return (
      <div
        className={`full-height flex-row ${isLoading || isLoadingSystem ? 'loading ' : ''} ${
          isLoaded ? ' loaded' : ''
        }`}
        style={{ paddingTop: 8 }}
      >
        <Container className="flex-grow flex-min-height flex-col">
          <Spin spinning={isLoadingSystem} wrapperClassName="full-width full-height spin-full-width">
            <div className="full-width full-height flex-col">
              <div className="flex-row flex-center-align flex-wrap" style={{ paddingBottom: 8 }}>
                <Select
                  showSearch
                  size="small"
                  style={{ width: 150, margin: '8px 16px 0 0' }}
                  placeholder={intl.formatMessage(eventMessages.rootCauseProject)}
                  value={rootCauseProject}
                  optionFilterProp="value"
                  onChange={this.onChangeRootCauseProject}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Select.Option key={item.projectNameReal} value={item.projectNameReal}>
                        {item.projectDisplayName || item.projectNameReal}
                      </Select.Option>
                    );
                  }, this.projectList || [])}
                </Select>
                <Select
                  showSearch
                  size="small"
                  style={{ width: 150, margin: '8px 16px 0 0' }}
                  placeholder={intl.formatMessage(eventMessages.rootCauseLevel)}
                  value={rootCauseLevel}
                  optionFilterProp="value"
                  onChange={this.onChangeRootCauseLevel}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  <Select.Option value="component">{intl.formatMessage(appFieldsMessages.component)}</Select.Option>
                  <Select.Option value="instance">{intl.formatMessage(appFieldsMessages.instance)}</Select.Option>
                </Select>

                {rootCauseLevel === 'component' && (
                  <Select
                    allowClear
                    showSearch
                    size="small"
                    style={{ width: 150, margin: '8px 16px 0 0' }}
                    placeholder={intl.formatMessage(eventMessages.incidentComponent)}
                    options={this.incidentComponentListOptions}
                    value={filterIncidentComponent}
                    onChange={this.onChangeFilterIncidentComponent}
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  />
                )}
                {rootCauseLevel === 'instance' && (
                  <Select
                    allowClear
                    showSearch
                    size="small"
                    style={{ width: 150, margin: '8px 16px 0 0' }}
                    placeholder={intl.formatMessage(eventMessages.incidentInstance)}
                    options={this.incidentInstanceListOptions}
                    value={filterIncidentInstance}
                    onChange={this.onChangeFilterIncidentInstance}
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  />
                )}
                <Select
                  allowClear
                  showSearch
                  size="small"
                  style={{ width: 130, margin: '8px 16px 0 0' }}
                  placeholder={intl.formatMessage(eventMessages.incidentPattern)}
                  value={filterPatternId}
                  optionFilterProp="value"
                  onChange={this.onChangeFilterPatternId}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  }, this.patternListOptions)}
                </Select>
                <Select
                  allowClear
                  showSearch
                  size="small"
                  style={{ width: 140, margin: '8px 16px 0 0' }}
                  placeholder={intl.formatMessage(eventMessages.rootCausePattern)}
                  mode="multiple"
                  value={filterSourcePatternIds}
                  optionFilterProp="value"
                  onChange={this.onChangeFilterSourcePatternId}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  }, this.sourcePatternListOptions)}
                </Select>
                <Select
                  allowClear
                  showSearch
                  size="small"
                  style={{ width: 120, margin: '8px 16px 0 0' }}
                  placeholder={intl.formatMessage(appFieldsMessages.status)}
                  value={filterStatus}
                  optionFilterProp="value"
                  onChange={this.onChangeFilterStatus}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  }, this.statusOptions)}
                </Select>
              </div>

              <div className="flex-row flex-center-align" style={{ padding: '0 8px' }}>
                <div className="flex-grow flex-row flex-center-align" />
                <Pagination
                  size="small"
                  current={page}
                  total={incidentPredictionRuleList.length}
                  pageSize={pageSize}
                  onChange={this.handlePaginationChange}
                  showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                  showSizeChanger
                  pageSizeOptions={['10', '20', '40', '60', '100', '500', '1000']}
                  onShowSizeChange={this.handlePaginationSizeChange}
                />
              </div>

              <div className="flex-grow flex-min-width flex-min-height flex-row" style={{ padding: 8 }}>
                <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
                  <div style={{ width: '30%', maxWidth: 320, marginRight: 16 }}>
                    <AutoSizer>
                      {({ width, height }) => (
                        <Table
                          className="with-border"
                          ref={(c) => {
                            this.dataTable = c;
                          }}
                          width={width}
                          height={height}
                          headerHeight={30}
                          rowHeight={40}
                          rowCount={incidentList.length}
                          rowGetter={({ index }) => incidentList[index]}
                          onRowClick={({ rowData }) => {
                            this.handleIncidentClick(rowData);
                          }}
                          rowClassName={({ index }) => {
                            let className = 'clickable';
                            className += index >= 0 && index % 2 === 1 ? ' odd-row' : '';
                            // Ignore header row.
                            if (index >= 0) {
                              if (incidentList[index].mergeKey === activeIncidentKey) {
                                className += ' active';
                              }
                            }
                            return className;
                          }}
                          sort={this.sortTable}
                          sortBy={sortBy}
                          sortDirection={sortDirection}
                        >
                          <Column
                            width={100}
                            flexGrow={1}
                            label={intl.formatMessage(eventMessages.incidentPattern)}
                            dataKey="incidentPatternNameStr"
                            cellRenderer={this.renderIncidentPattern}
                            headerRenderer={this.headerRenderer}
                          />
                          {rootCauseLevel === 'component' && (
                            <Column
                              width={120}
                              flexGrow={1}
                              label={intl.formatMessage(eventMessages.incidentComponent)}
                              dataKey="incidentComponent"
                              cellRenderer={this.renderIncidentPattern}
                              headerRenderer={this.headerRenderer}
                            />
                          )}
                          {rootCauseLevel === 'instance' && (
                            <Column
                              width={120}
                              flexGrow={1}
                              label={intl.formatMessage(eventMessages.incidentInstance)}
                              dataKey="incidentInstanceName"
                              cellRenderer={this.renderIncidentPattern}
                              headerRenderer={this.headerRenderer}
                            />
                          )}
                          <Column
                            width={40}
                            label={null}
                            dataKey="kbIgnoreFlag"
                            cellRenderer={this.renderKBIgnoreFlag}
                            disableSort
                          />
                        </Table>
                      )}
                    </AutoSizer>
                  </div>

                  <div className="flex-grow flex-min-width flex-col">
                    <div className="flex-grow flex-min-height">
                      <Spin spinning={isLoadingLocalList} wrapperClassName="full-width full-height spin-full-height">
                        <AutoSizer>
                          {({ width, height }) => (
                            <div className="event-list">
                              <div
                                className="event-list-header"
                                style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                              >
                                <div className="header-column flex-center-justify" style={{ width: 40 }}>
                                  <Checkbox checked={checkAll} onChange={this.handleCheckAllChange} />
                                </div>

                                <div
                                  className="header-column"
                                  style={{ width: 140 }}
                                  onClick={this.headerClick('matchedCount')}
                                >
                                  <span>{intl.formatMessage(eventMessages.truePositiveCount)}</span>
                                  {this.sortIcon(sortByList, sortDirectionList, 'matchedCount')}
                                </div>
                                <div className="header-column" style={{ width: 160, flex: 1 }}>
                                  {intl.formatMessage(eventMessages.rootCauseMetricOrLog)}
                                </div>
                                <div className="header-column" style={{ width: 120 }}>
                                  {intl.formatMessage(appFieldsMessages.status)}
                                </div>
                                <div className="header-column" style={{ width: 350 }} />
                              </div>
                              <List
                                className="event-list-grid"
                                ref={(listNode) => {
                                  this.listNode = listNode;
                                }}
                                width={width}
                                height={height - 40}
                                rowCount={localPredictionRuleList.length}
                                overscanRowCount={4}
                                deferredMeasurementCache={this.cellMeasureCache}
                                rowHeight={this.cellMeasureCache.rowHeight}
                                rowRenderer={this.renderListItem}
                                onScrollbarPresenceChange={({ horizontal, vertical }) => {
                                  if (vertical) {
                                    this.listNodeHeaderScrollbar = true;
                                  } else {
                                    this.listNodeHeaderScrollbar = false;
                                  }
                                  this.forceUpdate();
                                }}
                              />
                            </div>
                          )}
                        </AutoSizer>
                      </Spin>
                    </div>

                    <div className="flex-row" style={{ padding: 8 }}>
                      <div className="flex-grow" />
                      <Button
                        size="small"
                        type="primary"
                        loading={isSubmitting}
                        disabled={hasError}
                        onClick={this.handleSaveClick}
                      >
                        {intl.formatMessage(appButtonsMessages.update)}
                      </Button>

                      <Button
                        type="primary"
                        size="small"
                        style={{ marginLeft: 8 }}
                        loading={isSubmitting}
                        disabled={hasErrorRemove}
                        onClick={() => this.handleMultipleConfigActionsClick()}
                      >
                        {intl.formatMessage(eventMessages.takeAction)}
                      </Button>

                      <Popconfirm
                        placement="topRight"
                        title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                        okText={intl.formatMessage(appButtonsMessages.yes)}
                        cancelText={intl.formatMessage(appButtonsMessages.no)}
                        onConfirm={() => this.handleRuleRemove()}
                        onCancel={(event) => event.stopPropagation()}
                      >
                        <Button
                          type="primary"
                          size="small"
                          className="button-color-grey"
                          style={{ marginLeft: 8 }}
                          loading={isSubmitting}
                          disabled={hasErrorRemove}
                          onClick={(event) => event.stopPropagation()}
                        >
                          <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                        </Button>
                      </Popconfirm>
                      <Button
                        type="primary"
                        size="small"
                        className="button-color-grey"
                        style={{ marginLeft: 8 }}
                        onClick={this.handleRuleRemoveAll}
                      >
                        <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.removeAll)}
                      </Button>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </Spin>
        </Container>

        {this.state.showSystemPredictionRuleActionModal && (
          <GlobalSystemKnowledgeBaseActionModal
            systemId={systemId}
            actionProjectNameList={this.state.actionProjectNameList}
            actionKeySet={this.state.actionKeySet}
            actionIncidentPredictionLibKeys={this.state.actionIncidentPredictionLibKeys}
            systemProjectNameList={this.state.systemProjectNameList}
            onClose={(reload) => {
              this.setState(
                {
                  showSystemPredictionRuleActionModal: false,
                  actionProjectNameList: [],
                  actionKeySet: [],
                  actionIncidentPredictionLibKeys: [],
                },
                () => {
                  if (reload) this.reloadData();
                },
              );
            }}
          />
        )}

        {addRecommendationModalFlag && (
          <GlobalSystemKnowledgeBaseRecommendationModal
            intl={intl}
            activeIncidentKey={activeIncidentKey}
            addRecommendationActive={addRecommendationActive}
            rootCauseLevel={rootCauseLevel}
            incidentList={incidentList}
            credentials={credentials}
            onClose={() => {
              this.setState({ addRecommendationModalFlag: false });
            }}
          />
        )}
      </div>
    );
  }
}

const KnowledgeBaseRuleList = injectIntl(KnowledgeBaseRuleListCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, systemsMap, projects, projectDisplayMap, globalInfo, currentTheme } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { credentials, userInfo } = state.auth;
    const { globalSystemMatchedPredictions } = state.dashboard;
    return {
      location,
      loadStatus,
      currentLocale,
      userList,
      systemsMap,
      projects,
      projectDisplayMap,
      credentials,
      userInfo,

      globalInfo,
      globalSystemMatchedPredictions,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(KnowledgeBaseRuleList);
