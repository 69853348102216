/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React, { useReducer } from 'react';
import * as R from 'ramda';
import Papa from 'papaparse';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import {
  SaveOutlined,
  ExclamationCircleFilled,
  LineChartOutlined,
  DoubleLeftOutlined,
  UpOutlined,
  DownOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  Select,
  Input,
  Checkbox,
  Pagination,
  Button,
  message,
  Modal,
  Menu,
  Form,
  Spin,
  DatePicker,
  Tooltip,
  InputNumber,
  Popconfirm,
} from 'antd';
import moment from 'moment';

import fetchPost from '../../../../common/apis/fetchPost';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchPostForm from '../../../../common/apis/fetchPostForm';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { BaseUrls } from '../../../app/Constants';
import {
  getReaderStream,
  downloadFile,
  buildUrl,
  parseLocation,
  timeScopeControl,
  buildLocation,
  Defaults,
  GlobalParse,
} from '../../../../common/utils';
import { Container, Table, Column, AutoSizer, Dropdown, Popover, SortDirection } from '../../../../lib/fui/react';
import { updateLastActionInfo, loadProjectInfo } from '../../../../common/app/actions';

import { appMessages, appFieldsMessages, appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { DashboardMessages } from '../../../../common/dashboard/messages';
import { eventMessages } from '../../../../common/metric/messages';

import GroupEditComponentMapModal from './GroupEditComponentMapModal';
import GroupFileModal from './GroupFileModal';
import GroupImportInstanceMetaModal from './GroupImportInstanceMetaModal';
import GroupMetricInstanceNameSettingModal from './GroupMetricInstanceNameSettingModal';
import GroupTypeFileModal from './GroupTypeFileModal';

const hasDuplicateInstanceDisplayName = (arr = []) => {
  const instanceDisplayNames = new Set();
  for (let i = 0; i < arr.length; i += 1) {
    const obj = arr[i];
    if (instanceDisplayNames.has(obj.instanceDisplayName)) {
      return obj.instanceDisplayName;
    }
    if (obj.instanceDisplayName) instanceDisplayNames.add(obj.instanceDisplayName);
  }
  return undefined;
};

const submitLoader = 'project_base_setting';

type Props = {
  projectName: String,
  refreshTime: Number,
  projects: Array<Object>,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  credentials: Object,
  currentProject: Object,
  currentLoadingComponents: Object,
  saveProjectInstanceGroup: Function,

  updateLastActionInfo: Function,
  // eslint-disable-next-line
  loadProjectInfo: Function,
  replace: Function,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  userName: String,
  onChangeShowEditComponent: Function,
  getInstaceDisplayNameData: Function,
  data: Object,
  saveProjectSettings: Function,
};

class InstanceSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl, location } = props;
    const params = parseLocation(location);

    this.state = {
      isLoading: false,
      instanceListAll: [],
      instanceListCount: 0,
      instanceList: [],

      isLoadingInstanceMeta: false,
      page: 1,
      pageSize: 100,
      instanceGroupList: [],

      // eslint-disable-next-line
      isCheckedAll: false,
      // eslint-disable-next-line
      isIgnoreAll: false,

      showEditComponentMap: false,
      showmportInstanceMetaData: false,
      showGroupFileModal: false,
      showGroupingUpdateModal: false,
      showGroupTypeFileModal: false,
      groupTypeFile: 'instanceName',

      sortBy: null,
      sortDirection: null,

      searchComponentName: '',
      searchContainerName: '',
      showMetricInstanceNameSetting: false,
      activeInstanceMap: {},
      activeContainerMap: {},

      startTimeObj: moment.utc().subtract(1, 'days').startOf('day'),
      endTimeObj: moment.utc().endOf('day'),
      endTimeOpen: false,
      timeChange: false,

      statusValue: '',

      allExpand: false,
    };
    this.localInstanceGroupList = [];
    this.instanceGroupPriorityOptions = [
      { label: intl.formatMessage(DashboardMessages.low), value: '1' },
      { label: intl.formatMessage(DashboardMessages.mediumLow), value: '2' },
      { label: intl.formatMessage(DashboardMessages.medium), value: '3' },
      { label: intl.formatMessage(DashboardMessages.mediumHigh), value: '4' },
      { label: intl.formatMessage(DashboardMessages.high), value: '5' },
    ];
    this.groupSelectOptions = [
      { label: intl.formatMessage(settingsMessages.instanceSelectAllDay), value: 'all' },
      { label: intl.formatMessage(settingsMessages.instanceSelectCurrentDay), value: 'current' },
    ];
    this.instanceListJumpList = R.filter((item) => item, (params?.instance || '').split(',') || []);
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const params = parseLocation(this.props.location);
    const nextParams = parseLocation(nextProps.location);
    if (
      this.props.projectName !== nextProps.projectName ||
      this.props.refreshTime !== nextProps.refreshTime ||
      params.reloadProject !== nextParams.reloadProject
    ) {
      this.reloadData(nextProps);
    } else if (this.props.projects !== nextProps.projects) {
      this.parseData(nextProps);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        this.localInstanceGroupList = R.sortWith([R.ascend(R.prop(sortBy))])(this.localInstanceGroupList);
        R.forEach((item) => {
          item.children = R.sortWith([R.ascend(R.prop(sortBy))])(item.children || []);
        }, this.localInstanceGroupList);
        if (sortDirection === SortDirection.DESC) {
          this.localInstanceGroupList = R.sortWith([R.descend(R.prop(sortBy))])(this.localInstanceGroupList);
          R.forEach((item) => {
            item.children = R.sortWith([R.descend(R.prop(sortBy))])(item.children || []);
          }, this.localInstanceGroupList);
        }
      }
    }
  }

  @autobind
  reloadData(props) {
    const { projectName, projects, loadProjectInfo } = props;
    const project = R.find((p) => p.projectName === projectName, projects);

    this.setState({ isLoading: true });
    if (project && project.hasAllInfo && !project.hasAllInstanceInfo) {
      loadProjectInfo(
        {
          projectName,
          includeInstance: true,
        },
        false,
        this.callbackHandle,
      );
    } else {
      this.parseData(props);
    }
  }

  @autobind
  getActiveMap(list, dataType, times) {
    const activeMap = {};
    const lostMap = {};
    R.forEach((item) => {
      const { hasData, id, dailyTimestamp } = item;
      let { instanceLost = [] } = item;
      try {
        instanceLost = JSON.parse(instanceLost);
      } catch (err) {
        // console.debug(err)
      }
      if (!R.isEmpty(instanceLost)) {
        R.forEach((l) => {
          const { s, e } = l;
          if (!id) return;
          if (lostMap[id]) {
            lostMap[id].push({
              startTime: moment.utc(s).format(Defaults.DateTimeFormat),
              endTime: moment.utc(e).format(Defaults.DateTimeFormat),
            });
          } else {
            lostMap[id] = [
              {
                startTime: moment.utc(s).format(Defaults.DateTimeFormat),
                endTime: moment.utc(e).format(Defaults.DateTimeFormat),
              },
            ];
          }
        }, instanceLost);
      }

      if (id) {
        if (activeMap[id] && !hasData && false) {
          if (lostMap[id]) {
            lostMap[id].push({
              startTime: moment.utc(dailyTimestamp).format(Defaults.DateTimeFormat),
              endTime: moment.utc(dailyTimestamp).endOf('day').format(Defaults.DateTimeFormat),
            });
          } else {
            lostMap[id] = [
              {
                startTime: moment.utc(dailyTimestamp).format(Defaults.DateTimeFormat),
                endTime: moment.utc(dailyTimestamp).endOf('day').format(Defaults.DateTimeFormat),
              },
            ];
          }
        }
        activeMap[id] = dataType === 'Metric' ? activeMap[id] || hasData : true;
      }
    }, list || []);

    if (times.length > 1) {
      const listMap = {};
      R.forEach((item) => {
        const { id, dailyTimestamp } = item;
        const key = `${id}-${dailyTimestamp}`;
        listMap[key] = true;
      }, list || []);

      R.forEach((instance) => {
        let missDataDuration = R.clone(lostMap[instance]) || [];
        R.forEach((time) => {
          const key = `${instance}-${time}`;
          const findTime = !!listMap[key];
          if (!findTime) {
            missDataDuration.push({
              startTime: moment.utc(time).format(Defaults.DateFormat),
              endTime: moment.utc(time).endOf('day').format(Defaults.DateTimeFormat),
              noData: true,
            });
          }
        }, times || []);
        missDataDuration = R.uniqBy((item) => `${item.startTime}-${item.endTime}`, missDataDuration);
        missDataDuration = R.sortWith([R.ascend(R.prop('startTime'))])(missDataDuration || []);
        const noDatas = R.filter((item) => item.noData, missDataDuration || []);
        if (noDatas.length > 0) {
          lostMap[instance] = missDataDuration;
        }
      }, R.keys(activeMap || {}));
    }

    return { activeMap, lostMap };
  }

  @autobind
  async reloadActiveInstancce(props) {
    const { intl, projectName, projects, credentials } = props;
    const { startTimeObj, endTimeObj } = this.state;
    const project = R.find((p) => p.projectName === projectName, projects);

    const { owner, projectShortName, dataType } = project;
    const startTime = startTimeObj.valueOf();
    const endTime = endTimeObj.valueOf();
    return new Promise((resolve, reject) => {
      fetchGet(getEndpoint('activeinstance'), {
        ...credentials,
        daily: true,
        customerName: owner,
        startTime,
        endTime,
        projectNameList: JSON.stringify([{ projectName: projectShortName, projectType: dataType }]),
      })
        .then((res) => {
          const times = [];
          const activeInstanceList = [];
          const activeContainerList = [];
          R.forEach((item) => {
            times.push(item.dailyTimestamp);
            R.forEach((n) => {
              activeInstanceList.push({ ...n, dailyTimestamp: item.dailyTimestamp });
            }, item?.data?.activeInstanceList || []);
            R.forEachObjIndexed((value, key) => {
              R.forEach((n) => {
                activeContainerList.push({ ...n, dailyTimestamp: item.dailyTimestamp, key });
              }, value || []);
            }, item?.data?.activeContainerList || {});
          }, res);
          const { activeMap: activeInstanceMap, lostMap: instanceLostMap } = this.getActiveMap(
            activeInstanceList,
            dataType,
            times,
          );
          const { activeMap: activeContainerMap, lostMap: containerLostMap } = this.getActiveMap(
            activeContainerList,
            dataType,
            times,
          );

          this.setState({ activeInstanceMap, instanceLostMap, activeContainerMap, containerLostMap }, () => {
            resolve({ activeInstanceMap, instanceLostMap, activeContainerMap, containerLostMap });
          });
        })
        .catch((err) => {
          resolve({});
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        });
    });
  }

  @autobind
  callbackHandle() {}

  @autobind
  parseData(props) {
    const { projectName, projects, location } = props;
    const { page, pageSize } = this.state;
    const params = parseLocation(location);
    this.setState({ isLoading: true });

    const currentProject = R.find((project) => project.projectName === projectName, projects) || {};
    const { isMetric, isContainer, instanceList: instanceContainerList } = currentProject;
    let instanceList = [];
    if (isContainer) {
      R.forEach((item) => {
        if (item.includes('_')) {
          instanceList.push(item.split('_')[1]);
        } else {
          instanceList.push(item);
        }
      }, instanceContainerList || []);
    } else {
      instanceList = instanceContainerList || [];
    }
    instanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceList));
    let instanceListCount = instanceList.length;

    // get local instance list by page
    let localinstanceList = R.slice((page - 1) * pageSize, page * pageSize, instanceList);

    if (params?.isJump && this.instanceListJumpList.length > 0) {
      let instanceListJumpList = [];
      let filterInstanceList = instanceList;
      R.forEach((item) => {
        if (instanceList.includes(item)) {
          instanceListJumpList.push(item);
          filterInstanceList = R.filter((_item) => _item !== item, filterInstanceList || []);
        }
      }, this.instanceListJumpList || []);
      instanceListJumpList = [...instanceListJumpList, ...filterInstanceList];

      localinstanceList = R.slice((page - 1) * pageSize, page * pageSize, instanceListJumpList);
      instanceListCount = instanceListJumpList.length;
    }

    this.setState({ isLoading: false, instanceListAll: instanceList, instanceListCount, instanceList }, () => {
      this.reloadInstanceData(localinstanceList);
    });
  }

  @autobind
  async reloadInstanceData(localinstanceList, isPageChange = false) {
    const { intl, credentials, projectName, location, currentProject } = this.props;
    const { allExpand } = this.state;
    const isContainer = get(currentProject, 'isContainer');
    this.setState({ isLoadingInstanceMeta: true });
    const params = parseLocation(location);

    if (localinstanceList.length > 0) {
      this.props.updateLastActionInfo();

      let activeState = this.state;
      if (!isPageChange) {
        activeState = await this.reloadActiveInstancce(this.props);
      }

      const { activeInstanceMap, instanceLostMap, activeContainerMap, containerLostMap } = activeState;
      await fetchPost(getEndpoint('groupingstorage'), {
        ...credentials,
        projectName,
        instanceGroup: 'All',
        instanceList: JSON.stringify(localinstanceList || []),
      })
        .then((d) => {
          const instanceGroupingObj = d.data || {};
          const zoneData = d.zoneData || {};
          const metricInstanceMap = d.metricInstanceMap || {};
          const appResult = d.appResult || {};
          const ipAddress = d.ipAddress || {};
          const ignoreFlagMap = d.ignoreFlagMap || {};
          const containerMap = d.containerMap || {};
          const metaDataMap = d.metaDataMap || {};
          const containerMetaDataMap = d.containerMetaDataMap || {};

          let instanceGroupList = [];
          R.forEachObjIndexed((val, key) => {
            const { coreCount = '', instanceType = '', lossRatio = 0, instanceDisplayName } = metaDataMap[key] || {};

            let containerMetaDataList = [];
            if (isContainer) {
              R.forEach((item) => {
                containerMetaDataList.push({
                  instanceName: item.instanceName || '',
                  metricInstanceName: metricInstanceMap[item.instanceName] || '',
                  instanceGroup: val || '',
                  appName: item.appName || '',
                  ipAddress: item.ipAddress || '',
                  ignoreFlag: item.ignoreFlag || false,
                  zone: item.zone || '',
                  containers: [],
                  coreCount: item.coreCount || '',
                  instanceType: item.instanceType || '',
                  containerName: item.containerName || '',
                  key: `${item.containerName}_${item.instanceName}`,
                  analysisGroup: item.analysisGroup || '',
                  lossRatio: item.lossRatio || 0,
                  instanceDisplayName: item.instanceDisplayName || '',
                });
              }, containerMetaDataMap[key]);
              containerMetaDataList = R.uniqBy((item) => item.key, containerMetaDataList);
              containerMetaDataList = R.sortWith([R.ascend(R.prop('key'))], containerMetaDataList);
            }

            instanceGroupList.push({
              instanceName: key || '',
              metricInstanceName: metricInstanceMap[key] || '',
              instanceGroup: val || '',
              appName: appResult[key] || '',
              ipAddress: ipAddress[key] || '',
              ignoreFlag: ignoreFlagMap[key] || false,
              zone: zoneData[key] || '',
              containers: containerMap[key] || [],
              coreCount: coreCount || '',
              instanceType: instanceType || '',
              key: `containerName_id_${key}`,
              isRoot: true,
              isExpand: allExpand,
              children: containerMetaDataList,
              lossRatio: lossRatio || 0,
              instanceDisplayName: instanceDisplayName || '',
            });
          }, instanceGroupingObj);
          instanceGroupList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('instanceName')))], instanceGroupList);

          if (params?.isJump && this.instanceListJumpList.length > 0) {
            this.localInstanceGroupList = R.map((item) => {
              return {
                ...item,
                status: null,
                statusStr: this.statusStr({ rowData: item, activeInstanceMap, instanceLostMap }),
                checked: this.instanceListJumpList.includes(item.instanceName),
                children: R.map(
                  (_item) => ({
                    ..._item,
                    status: null,
                    statusStr: this.statusStr({
                      rowData: _item,
                      activeInstanceMap: activeContainerMap,
                      instanceLostMap: containerLostMap,
                      containerFlag: true,
                    }),
                  }),
                  item.children || [],
                ),
              };
            }, R.clone(instanceGroupList));
            this.localInstanceGroupList = R.sortWith([R.descend(R.prop('checked'))])(this.localInstanceGroupList);
          } else {
            this.localInstanceGroupList = R.map((item) => {
              return {
                ...item,
                status: null,
                statusStr: this.statusStr({ rowData: item, activeInstanceMap, instanceLostMap }),
                children: R.map(
                  (_item) => ({
                    ..._item,
                    status: null,
                    statusStr: this.statusStr({
                      rowData: _item,
                      activeInstanceMap: activeContainerMap,
                      instanceLostMap: containerLostMap,
                      containerFlag: true,
                    }),
                  }),
                  item.children || [],
                ),
              };
            }, R.clone(instanceGroupList));
          }

          this.setState({
            isLoadingInstanceMeta: false,
            instanceGroupList,
            isCheckedAll: false,
            isIgnoreAll: false,
          });
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          this.localInstanceGroupList = [];
          this.setState({ isLoadingInstanceMeta: false, instanceGroupList: [] });
        });
    } else {
      this.localInstanceGroupList = [];
      this.setState({ isLoadingInstanceMeta: false, instanceGroupList: [] });
    }
  }

  @autobind
  clearInstance() {
    const { location, replace } = this.props;
    const params = parseLocation(location);
    this.instanceListJumpList = [];
    replace(
      buildLocation(
        location.pathname,
        {},
        {
          ...params,
          instance: undefined,
          isJump: undefined,
        },
      ),
    );
  }

  @autobind
  inputCellRender({ dataKey, rowData, cellData }, instanceGroupList) {
    const { projectName, projects } = this.props;
    const currentProject = R.find((project) => project.projectName === projectName, projects) || {};
    const { isContainer } = currentProject;
    if (!rowData.isRoot && isContainer && dataKey !== 'appName') {
      return <div />;
    }

    let hasRepeatInstanceDisplayName = false;
    if (dataKey === 'instanceDisplayName' && cellData) {
      hasRepeatInstanceDisplayName = R.filter((item) => item[dataKey] === cellData, instanceGroupList || []).length > 1;
    }

    return (
      <>
        {R.includes(dataKey, ['analysisGroup']) && rowData.isRoot ? (
          <div />
        ) : (
          <>
            {R.includes(dataKey, ['appName', 'metricInstanceName', 'instanceDisplayName']) ? (
              <Popover mouseEnterDelay={0.3} placement="right" title={null} content={cellData}>
                <Input
                  size="small"
                  value={cellData || ''}
                  disabled={R.includes(dataKey, ['instanceType'])}
                  onChange={this.handleInputChanged(rowData, dataKey)}
                  className={hasRepeatInstanceDisplayName ? 'inputIsNil' : ''}
                />
              </Popover>
            ) : (
              <Input
                size="small"
                value={cellData || ''}
                disabled={R.includes(dataKey, ['instanceType'])}
                onChange={this.handleInputChanged(rowData, dataKey)}
                className={hasRepeatInstanceDisplayName ? 'inputIsNil' : ''}
              />
            )}
          </>
        )}
      </>
    );
  }

  @autobind
  coreCountRender({ dataKey, rowData, cellData }) {
    return <Input size="small" value={cellData || ''} onChange={this.handleInputNumberChanged(rowData, dataKey)} />;
  }

  @autobind
  lossRatioRender({ rowData, dataKey }) {
    const { lossRatio } = rowData;
    return (
      <InputNumber
        size="small"
        value={lossRatio}
        min={0}
        max={0.9}
        onChange={this.handleInputNumberChanged(rowData, dataKey)}
        style={{ width: '80%' }}
        step={0.1}
      />
    );
  }

  @autobind
  statusCellRender({ rowData }) {
    const { currentProject } = this.props;
    const { activeInstanceMap, instanceLostMap, activeContainerMap, containerLostMap } = this.state;
    const { instanceName, isRoot, containerName } = rowData;
    const isMetric = get(currentProject, 'isMetric');

    let missDataDuration = [];
    let hasData = null;
    if (isRoot) {
      missDataDuration = (instanceLostMap || {})[instanceName] || [];
      hasData = (activeInstanceMap || {})[instanceName];
    } else {
      missDataDuration = (containerLostMap || {})[containerName] || [];
      hasData = (activeContainerMap || {})[containerName];
    }
    let content = '';
    if (hasData && R.isEmpty(missDataDuration)) {
      content = 'Complete data';
    }
    if (hasData && !R.isEmpty(missDataDuration)) {
      content = (
        <Popover
          title="Missing data duration"
          content={
            <div
              style={{ minWidth: 170, maxWidth: 380, maxHeight: 240 }}
              className="flex-col flex-center-align overflow-y-auto"
            >
              {!R.isEmpty(missDataDuration) &&
                R.map((item) => {
                  const { startTime, endTime, noData } = item;
                  return (
                    <div
                      key={startTime + endTime}
                      className="flex-row"
                      style={{ borderBottom: '1px solid var(--border-color-base)', padding: '4px 0' }}
                    >
                      <div style={{ marginRight: 8 }}>{noData ? 'No data' : 'Missing data'}:</div>
                      <div style={{ textAlign: 'right' }}>{startTime}</div>
                      {!noData && (
                        <>
                          <div style={{ margin: '0 5px' }}>-</div>
                          <div>{endTime}</div>
                        </>
                      )}
                    </div>
                  );
                }, missDataDuration)}
            </div>
          }
        >
          Missing data
        </Popover>
      );
    }

    if (hasData && !isMetric && false) {
      content = 'Complete data';
    }

    if (!hasData) {
      content = 'No data';
    }

    return <>{content}</>;
  }

  @autobind
  statusStr({ rowData, activeInstanceMap, instanceLostMap, containerFlag }) {
    const { currentProject } = this.props;
    const { instanceName, containerName } = rowData;
    const isMetric = get(currentProject, 'isMetric');
    const missDataDuration = (instanceLostMap || {})[containerFlag ? containerName : instanceName] || [];
    const hasData = (activeInstanceMap || {})[containerFlag ? containerName : instanceName];
    let content = '';
    if (hasData && R.isEmpty(missDataDuration)) {
      content = 'Complete data';
    }
    if (hasData && !R.isEmpty(missDataDuration)) {
      content = 'Missing data';
    }

    if (hasData && !isMetric && false) {
      content = 'Complete data';
    }

    if (!hasData) {
      content = 'No data';
    }

    return content;
  }

  @autobind
  handleInputChanged(rowData, dataKey) {
    return (e) => {
      const { searchComponentName, searchContainerName, statusValue } = this.state;
      const { target } = e;
      const newVal = target.type === 'checkbox' ? target.checked : target.value || '';

      // Save the data and force update.
      this.localInstanceGroupList = R.map((item) => {
        if (item.key === rowData.key) {
          item[dataKey] = newVal;
        }
        R.forEach((c) => {
          if (c.key === rowData.key) {
            c[dataKey] = newVal;
          }
        }, item.children);
        return item;
      }, this.localInstanceGroupList);

      let newLocalInstanceGroupList = this.localInstanceGroupList;

      const isCheckedAll =
        newLocalInstanceGroupList.length > 0 &&
        !R.find((m) => !m.checked, newLocalInstanceGroupList) &&
        !R.find((m) => !m.checked, R.unnest(R.map((item) => item.children, newLocalInstanceGroupList)));
      const isIgnoreAll =
        newLocalInstanceGroupList.length > 0 &&
        !R.find((m) => !m.ignoreFlag, newLocalInstanceGroupList) &&
        !R.find((m) => !m.ignoreFlag, R.unnest(R.map((item) => item.children, newLocalInstanceGroupList)));

      if (searchComponentName) {
        newLocalInstanceGroupList = R.filter(
          (event) => R.toLower(event.appName).includes(R.toLower(searchComponentName)),
          newLocalInstanceGroupList,
        );
      }
      if (searchContainerName) {
        newLocalInstanceGroupList = R.filter((event) => {
          event.children = R.filter(
            (c) => R.toLower(c.containerName).includes(R.toLower(searchContainerName)),
            event.children,
          );
          event.containers = R.map((item) => item.containerName, event.children);
          return event.children.length > 0;
        }, R.clone(newLocalInstanceGroupList));
      }

      if (statusValue) {
        newLocalInstanceGroupList = R.filter((event) => event.statusStr === statusValue, newLocalInstanceGroupList);
      }

      this.setState({ isCheckedAll, isIgnoreAll });
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleInputNumberChanged(rowData, dataKey) {
    return (e) => {
      const { searchComponentName, searchContainerName, statusValue } = this.state;
      let newLocalInstanceGroupList = this.localInstanceGroupList;
      const exclude = ['lossRatio'];
      const regexNumber = /^([1-9]\d*|[1-9]\d{2}\|\d{3})$/;
      let newVal = e?.target?.value || '';

      if (dataKey === 'lossRatio') {
        newVal = e || 0;
      } else {
        newVal = R.trim(newVal);
      }

      newLocalInstanceGroupList = R.map((item) => {
        if (item.key === rowData.key) {
          item[dataKey] = newVal;
        }
        R.forEach((c) => {
          if (c.key === rowData.key) {
            c[dataKey] = newVal;
          }
        }, item.children);
        return item;
      }, newLocalInstanceGroupList);

      if ((newVal === 0 || !newVal) && !exclude.includes(dataKey)) {
        rowData[dataKey] = '';
      }

      if (regexNumber.test(newVal) && !exclude.includes(dataKey)) {
        rowData[dataKey] = newVal;
      }

      if (searchComponentName) {
        newLocalInstanceGroupList = R.filter(
          (event) => R.toLower(event.appName).includes(R.toLower(searchComponentName)),
          newLocalInstanceGroupList,
        );
      }

      if (searchContainerName) {
        newLocalInstanceGroupList = R.filter((event) => {
          event.children = R.filter(
            (c) => R.toLower(c.containerName).includes(R.toLower(searchContainerName)),
            event.children,
          );
          event.containers = R.map((item) => item.containerName, event.children);
          return event.children.length > 0;
        }, R.clone(newLocalInstanceGroupList));
      }

      if (statusValue) {
        newLocalInstanceGroupList = R.filter((event) => event.statusStr === statusValue, newLocalInstanceGroupList);
      }

      const isCheckedAll =
        newLocalInstanceGroupList.length > 0 && !R.find((m) => !m.checked, newLocalInstanceGroupList);
      this.setState({ isCheckedAll });
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  zoneCellRender({ dataKey, rowData, cellData }) {
    return <Input disabled size="small" value={cellData || ''} onChange={this.handleZoneChanged(rowData, dataKey)} />;
  }

  @autobind
  handleZoneChanged(rowData, dataKey) {
    return (e) => {
      const { searchComponentName, searchContainerName, statusValue } = this.state;
      const { target } = e;
      const newVal = target.value || '';

      // Save the data and force update.
      let newLocalInstanceGroupList = this.localInstanceGroupList;
      newLocalInstanceGroupList = R.map((item) => {
        if (item.key === rowData.key) {
          item[dataKey] = newVal;
        }
        R.forEach((c) => {
          if (c.key === rowData.key) {
            c[dataKey] = newVal;
          }
        }, item.children);
        return item;
      }, newLocalInstanceGroupList);

      const isCheckedAll =
        newLocalInstanceGroupList.length > 0 &&
        !R.find((m) => !m.checked, newLocalInstanceGroupList) &&
        !R.find((m) => !m.checked, R.unnest(R.map((item) => item.children, newLocalInstanceGroupList)));
      const isIgnoreAll =
        newLocalInstanceGroupList.length > 0 &&
        !R.find((m) => !m.ignoreFlag, newLocalInstanceGroupList) &&
        !R.find((m) => !m.ignoreFlag, R.unnest(R.map((item) => item.children, newLocalInstanceGroupList)));

      if (searchComponentName) {
        newLocalInstanceGroupList = R.filter(
          (event) => R.toLower(event.appName).includes(R.toLower(searchComponentName)),
          newLocalInstanceGroupList,
        );
      }
      if (searchContainerName) {
        newLocalInstanceGroupList = R.filter((event) => {
          event.children = R.filter(
            (c) => R.toLower(c.containerName).includes(R.toLower(searchContainerName)),
            event.children,
          );
          event.containers = R.map((item) => item.containerName, event.children);
          return event.children.length > 0;
        }, R.clone(newLocalInstanceGroupList));
      }

      if (statusValue) {
        newLocalInstanceGroupList = R.filter((event) => event.statusStr === statusValue, newLocalInstanceGroupList);
      }

      this.setState({ isCheckedAll, isIgnoreAll });
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleGroupingUpdateClick() {
    this.setState({ showGroupingUpdateModal: true });
  }

  @autobind
  handleGroupingUpdateSave({ componentName, zone, lossRatio }) {
    const { projectName, saveProjectInstanceGroup, currentProject } = this.props;
    const { isMetric } = currentProject;

    let diffGroupingUpdate = R.filter((item) => item.checked, this.localInstanceGroupList);
    const localInstanceGroupListChildren = R.filter(
      (item) => item.checked,
      R.unnest(R.map((item) => item.children, this.localInstanceGroupList)),
    );
    diffGroupingUpdate = [...diffGroupingUpdate, ...localInstanceGroupListChildren];
    const instanceList = R.map(
      (item) => ({
        ...item,
        instanceDisplayName: undefined, // Will not be modified if instanceDisplayName is undefined
        ...(!R.isNil(componentName) ? { appName: componentName } : {}),
        ...(!R.isNil(zone) ? { zone } : {}),
        ...(!R.isNil(lossRatio) ? { lossRatio } : {}),
      }),
      diffGroupingUpdate,
    );

    saveProjectInstanceGroup(
      {
        projectName,
        instanceGroupList: instanceList,
        componentChange: !R.isNil(componentName),
        zoneChange: !R.isNil(zone),
        isMetric,
      },
      { [this.submitGroupUpdateLoadingKey]: true },
      this.callbackHandleUpdate,
    );
  }

  @autobind
  handleUpdateInstanceGroupClick() {
    const { saveProjectInstanceGroup, projectName, currentProject } = this.props;
    const { isMetric } = currentProject;

    const duplicateInstanceDisplayName = hasDuplicateInstanceDisplayName(this.localInstanceGroupList);
    if (duplicateInstanceDisplayName) {
      const findIdx = R.findIndex(
        (item) =>
          item.instanceDisplayName && item.instanceDisplayName === duplicateInstanceDisplayName && !item.containerName,
        this.newLocalInstanceGroupList || [],
      );
      if (findIdx !== -1) this.table.scrollToRow(findIdx);
      message.error('Instance display name must be unique.');
      return;
    }

    const { instanceGroupList } = this.state;
    const diff = R.differenceWith(
      (a, b) => a.instanceName === b.instanceName && a.appName === b.appName,
      this.localInstanceGroupList,
      instanceGroupList,
    );
    const componentChange = diff.length > 0;

    const cmp = (a, b) =>
      a.appName === b.appName &&
      a.ipAddress === b.ipAddress &&
      a.zone === b.zone &&
      a.instanceGroup === b.instanceGroup &&
      a.instanceName === b.instanceName &&
      a.instanceDisplayName === b.instanceDisplayName &&
      a.instanceType === b.instanceType &&
      a.coreCount === b.coreCount &&
      a.ignoreFlag === b.ignoreFlag &&
      a.metricInstanceName === b.metricInstanceName &&
      a.key === b.key &&
      a.analysisGroup === b.analysisGroup &&
      a.lossRatio === b.lossRatio;
    let diffList = R.differenceWith(cmp, this.localInstanceGroupList, instanceGroupList);
    const localInstanceGroupListChildren = R.unnest(
      R.filter(
        (x) => !!x,
        R.map((item) => item.children, this.localInstanceGroupList),
      ),
    );
    const instanceGroupListChildren = R.unnest(
      R.filter(
        (x) => !!x,
        R.map((item) => item.children, instanceGroupList),
      ),
    );

    diffList = [...diffList, ...R.differenceWith(cmp, localInstanceGroupListChildren, instanceGroupListChildren)];
    if (diffList.length === 0) return;
    const { callbackHandleUpdate } = this;
    const sendRestData = (restList) => () => {
      const list = restList.splice(0, 1000);
      saveProjectInstanceGroup(
        {
          projectName,
          instanceGroupList: list,
          componentChange,
          isMetric,
        },
        { [this.submitLoadingKey]: true },
        restList.length <= 0 ? callbackHandleUpdate : sendRestData(restList),
      );
    };

    sendRestData(diffList)();
  }

  @autobind
  callbackHandleUpdate() {
    if (this.props.getInstaceDisplayNameData) this.props.getInstaceDisplayNameData();
    this.setState({ showGroupingUpdateModal: false });
    this.reloadData(this.props);
  }

  @autobind
  handleResetLocalChangeClick() {
    const { instanceGroupList } = this.state;
    this.localInstanceGroupList = R.clone(instanceGroupList);
    this.setState({ isCheckedAll: false, isIgnoreAll: false }, () => {
      this.table.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  handleInvestigateMenuClick({ key }) {
    switch (key) {
      case 'editComponentMap':
        this.handleEditComponentMapClick();
        break;
      case 'importInstanceMetaData':
        this.handleImportInstanceMetaClick();
        break;
      case 'upload':
        this.handleGroupFileClick();
        break;
      case 'download':
        this.handleDownloadClick();
        break;
      case 'metricInstanceNameSetting':
        this.handleMetricInstanceNameSettingClick();
        break;
      case 'instanceName':
        this.handleGroupTypeFileClick('instanceName');
        break;
      case 'componentName':
        this.handleGroupTypeFileClick('componentName');
        break;
      case 'instanceAndComponentName':
        this.handleGroupTypeFileClick('instanceAndComponentName');
        break;
      default:
        break;
    }
  }

  @autobind
  handleEditComponentMapClick() {
    // this.setState({ showEditComponentMap: true });
    this.props.onChangeShowEditComponent({
      showEditComponent: true,
      localInstanceGroupList: this.localInstanceGroupList,
      childReloadData: () => this.reloadData(this.props),
    });
  }

  @autobind
  handleImportInstanceMetaClick() {
    this.setState({ showmportInstanceMetaData: true });
  }

  @autobind
  handleMetricInstanceNameSettingClick() {
    this.setState({ showMetricInstanceNameSetting: true });
  }

  @autobind
  handleGroupEditComponentMapModalClose(reload) {
    const { location } = this.props;
    const params = parseLocation(location);
    this.setState({ showEditComponentMap: false }, () => {
      if (reload) {
        this.reloadData(this.props);

        // prompt
        this.promptModal = Modal.confirm({
          title: (
            <span>
              Please update the dependency graph under the{' '}
              <Button
                type="link"
                style={{ padding: 0, margin: 0, fontSize: 14 }}
                onClick={() =>
                  window.open(buildUrl(BaseUrls.CausalAnalysis, {}, { customerName: params?.customerName }), '_blank')
                }
              >
                causal analysis
              </Button>{' '}
              page since the component name is changed.
            </span>
          ),
          content: null,
          onOk: () => {
            if (this.promptModal) this.promptModal.destroy();
          },
        });
      }
    });
  }

  @autobind
  handleGroupFileClick() {
    this.setState({ showGroupFileModal: true });
  }

  @autobind
  handleGroupTypeFileClick(groupTypeFile) {
    this.setState({ showGroupTypeFileModal: true, groupTypeFile });
  }

  @autobind
  handleGroupFileModalClose(updated, instanceGroupList, isReplace) {
    const updateState = {
      showGroupFileModal: false,
    };

    if (updated) {
      if (isReplace) {
        this.localInstanceGroupList = instanceGroupList;
        this.localInstanceGroupList = R.map((item) => {
          return {
            ...item,
          };
        }, this.localInstanceGroupList);
      } else {
        // Merge the list with local list, use the item from the file if has the same instanceName.
        instanceGroupList = R.concat(instanceGroupList, this.localInstanceGroupList);
        instanceGroupList = R.uniqWith((a, b) => a.instanceName === b.instanceName)(instanceGroupList);
        this.localInstanceGroupList = instanceGroupList;
      }
    }
    this.setState(updateState);
  }

  @autobind
  handleGroupTypeFileModalClose() {
    this.setState({ showGroupTypeFileModal: false, groupTypeFile: null });
  }

  @autobind
  handlePaginationChange(page, pageSize) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ page, isCheckedAll: false, isIgnoreAll: false }, () => {
      const { location } = this.props;
      const { instanceList } = this.state;
      const params = parseLocation(location);
      // get local instance list by page
      let localinstanceList = R.slice((page - 1) * pageSize, page * pageSize, instanceList);
      if (params?.isJump && this.instanceListJumpList.length > 0) {
        let instanceListJumpList = [];
        let filterInstanceList = instanceList;
        R.forEach((item) => {
          if (instanceList.includes(item)) {
            instanceListJumpList.push(item);
            filterInstanceList = R.filter((_item) => _item !== item, filterInstanceList || []);
          }
        }, this.instanceListJumpList || []);
        instanceListJumpList = [...instanceListJumpList, ...filterInstanceList];
        localinstanceList = R.slice((page - 1) * pageSize, page * pageSize, instanceListJumpList);
      }
      this.reloadInstanceData(localinstanceList, true);
    });
  }

  @autobind
  handlePaginationSizeChange(oldPage, pageSize) {
    this.setState({ page: 1, pageSize });
  }

  @autobind
  handleInstanceSearch(searchInstance) {
    this.clearInstance();
    this.setState({ page: 1 }, () => {
      const { page, pageSize } = this.state;
      this.setState({ isLoadingInstanceMeta: true });

      let { instanceListAll: instanceList } = this.state;

      // filter data
      if (searchInstance) {
        instanceList = R.filter(
          (item) => item.toLowerCase().indexOf(searchInstance.toLowerCase()) !== -1,
          instanceList,
        );
      }

      instanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), instanceList);
      const instanceListCount = instanceList.length;

      // get local instance list by page
      const localinstanceList = R.slice((page - 1) * pageSize, page * pageSize, instanceList);

      this.setState({ instanceListCount, instanceList }, () => {
        this.reloadInstanceData(localinstanceList);
      });
    });
  }

  @autobind
  handleContainerSearch(searchContainerName) {
    let { isCheckedAll, isIgnoreAll } = this.state;
    let newLocalInstanceGroupList = this.localInstanceGroupList;

    this.setState({ searchContainerName }, () => {
      newLocalInstanceGroupList = R.filter((event) => {
        event.children = R.filter(
          (c) => R.toLower(c.containerName).includes(R.toLower(searchContainerName)),
          event.children,
        );
        event.containers = R.map((item) => item.containerName, event.children);
        return event.children.length > 0;
      }, R.clone(newLocalInstanceGroupList));
      isCheckedAll =
        newLocalInstanceGroupList.length > 0 &&
        !R.find(
          (m) => !m.checked,
          [...newLocalInstanceGroupList, ...R.unnest(R.map((item) => item.children, newLocalInstanceGroupList))],
        );
      isIgnoreAll =
        newLocalInstanceGroupList.length > 0 &&
        !R.find(
          (m) => !m.ignoreFlag,
          [...newLocalInstanceGroupList, ...R.unnest(R.map((item) => item.children, newLocalInstanceGroupList))],
        );
      this.setState({ isCheckedAll, isIgnoreAll });
    });
  }

  @autobind
  handleComponentNameSearch(searchComponentName) {
    const { currentProject } = this.props;
    const isContainer = get(currentProject, 'isContainer');
    let { isCheckedAll, isIgnoreAll } = this.state;
    let newLocalInstanceGroupList = this.localInstanceGroupList;
    this.setState({ searchComponentName }, () => {
      if (isContainer) {
        newLocalInstanceGroupList = R.filter((event) => {
          event.children = R.filter(
            (c) => R.toLower(c.appName).includes(R.toLower(searchComponentName)),
            event.children,
          );
          return event.children.length > 0 || R.toLower(event.appName).includes(R.toLower(searchComponentName));
        }, R.clone(newLocalInstanceGroupList));
      } else {
        newLocalInstanceGroupList = R.filter(
          (event) => R.toLower(event.appName).includes(R.toLower(searchComponentName)),
          newLocalInstanceGroupList,
        );
      }

      isCheckedAll =
        newLocalInstanceGroupList.length > 0 &&
        !R.find(
          (m) => !m.checked,
          [...newLocalInstanceGroupList, ...R.unnest(R.map((item) => item.children, newLocalInstanceGroupList))],
        );
      isIgnoreAll =
        newLocalInstanceGroupList.length > 0 &&
        !R.find(
          (m) => !m.ignoreFlag,
          [...newLocalInstanceGroupList, ...R.unnest(R.map((item) => item.children, newLocalInstanceGroupList))],
        );
      this.setState({ isCheckedAll, isIgnoreAll });
    });
  }

  @autobind
  instanceGroupPriorityRender({ dataKey, rowData, cellData }) {
    return (
      <Select
        size="small"
        style={{ width: '100%' }}
        value={String(cellData)}
        onChange={this.handleSelectionChange(rowData, dataKey)}
      >
        {R.map(
          (item) => (
            <Select.Option key={item.value}>{item.label}</Select.Option>
          ),
          this.instanceGroupPriorityOptions || [],
        )}
      </Select>
    );
  }

  @autobind
  checkboxCellRender({ dataKey, rowData, cellData }) {
    return <Checkbox size="small" checked={cellData || false} onChange={this.handleInputChanged(rowData, dataKey)} />;
  }

  @autobind
  handleSelectionChange(rowData, dataKey) {
    return (newVal) => {
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  headerAllExpandClick(e) {
    e.stopPropagation();
    e.preventDefault();
    const { allExpand } = this.state;

    R.forEach((item) => {
      item.isExpand = !allExpand;
    }, this.localInstanceGroupList);

    this.setState({ allExpand: !allExpand }, () => {
      if (this.table) this.table.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  allExpandRender() {
    const { allExpand } = this.state;
    return (
      <div
        className="header-column clickable"
        style={{ width: 30, display: 'inline-block', paddingRight: 6 }}
        onClick={this.headerAllExpandClick}
      >
        <DoubleLeftOutlined rotate={allExpand ? 90 : -90} />
      </div>
    );
  }

  @autobind
  itemExpandlRender(e, rowData) {
    e.stopPropagation();
    e.preventDefault();

    const { isExpand } = rowData;
    this.localInstanceGroupList = R.map((item) => {
      if (item.key === rowData.key) {
        item.isExpand = !isExpand;
      }
      return item;
    }, this.localInstanceGroupList);

    if (this.table) this.table.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  expandlRender({ rowData }) {
    const { isExpand, isRoot } = rowData;
    return (
      isRoot && (
        <div
          className="full-width flex-row flex-center-align flex-center-justify clickable"
          style={{ height: 40 }}
          onClick={(e) => this.itemExpandlRender(e, rowData)}
        >
          {isExpand ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
        </div>
      )
    );
  }

  @autobind
  checkAllHeaderRender(fieldName) {
    return () => {
      const { intl } = this.props;
      return (
        <div className="flex-col flex-center-align">
          {fieldName === 'isIgnoreAll' && <span>{intl.formatMessage(appFieldsMessages.ignore)}</span>}
          <Checkbox size="small" checked={this.state[fieldName]} onChange={this.handleAllCheckedChange(fieldName)} />
        </div>
      );
    };
  }

  @autobind
  handleAllCheckedChange(fieldName) {
    const { searchComponentName, searchContainerName, statusValue } = this.state;
    let newLocalInstanceGroupList = this.localInstanceGroupList;
    return (e) => {
      const { checked } = e.target;

      if (searchComponentName) {
        newLocalInstanceGroupList = R.filter(
          (event) => R.toLower(event.appName).includes(R.toLower(searchComponentName)),
          newLocalInstanceGroupList,
        );
      }
      if (searchContainerName) {
        newLocalInstanceGroupList = R.filter((event) => {
          event.children = R.filter(
            (c) => R.toLower(c.containerName).includes(R.toLower(searchContainerName)),
            event.children,
          );
          event.containers = R.map((item) => item.containerName, event.children);
          return event.children.length > 0;
        }, R.clone(newLocalInstanceGroupList));
      }
      if (statusValue) {
        newLocalInstanceGroupList = R.filter((event) => event.statusStr === statusValue, newLocalInstanceGroupList);
      }

      R.forEach((m) => {
        if (fieldName === 'isCheckedAll') {
          m.checked = checked;
          R.forEach((item) => {
            item.checked = checked;
          }, m.children || []);
        } else if (fieldName === 'isIgnoreAll') {
          m.ignoreFlag = checked;
          R.forEach((item) => {
            item.ignoreFlag = checked;
          }, m.children || []);
        }
      }, newLocalInstanceGroupList);

      this.setState({ [fieldName]: checked });
    };
  }

  @autobind
  handleDownloadClick(systemInfo, systemHealth) {
    const { intl } = this.props;
    this.downloadCategory = 'csv';
    const Content = () => (
      <div>
        <div className="flex-row flex-center-align">
          <span className="light-label bold" style={{ width: 100 }}>
            {intl.formatMessage(appFieldsMessages.category)}:
          </span>
          <Select
            style={{ width: 200 }}
            showSearch
            filterOption
            optionFilterProp="value"
            defaultValue={this.downloadCategory}
            onChange={(downloadCategory) => {
              this.downloadCategory = downloadCategory;
              if (this.exportModal) {
                this.exportModal.update({ content: <Content /> });
              }
            }}
          >
            <Select.Option value="csv">CSV</Select.Option>
            <Select.Option value="json">JSON</Select.Option>
          </Select>
        </div>
      </div>
    );

    this.exportModal = Modal.confirm({
      title: 'Export instances',
      content: <Content />,
      okButtonProps: {},
      onOk: this.handleDownloadConfirm,
    });
  }

  @autobind
  async handleDownloadConfirm(close) {
    const { intl, credentials, projectName } = this.props;

    const apiParams = {
      ...credentials,
      projectName,
      instanceGroup: 'All',
      dateStr: 'All',
      operation: 'download',
    };
    this.props.updateLastActionInfo();
    const data = await fetchPost(getEndpoint('groupingstorage'), apiParams, {}, '', false)
      .then((response) => {
        const reader = response.body.getReader();
        return getReaderStream(reader);
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .catch((err) => {
        console.debug(err);
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
    if (data) {
      if (this.downloadCategory === 'csv') {
        const text = await data.text();
        const textJSON = JSON.parse(text) || {};
        const csvData = [];
        R.forEachObjIndexed((val, instanceGroup) => {
          R.forEach((item) => {
            csvData.push({ ...item, instanceGroup });
          }, val);
        }, textJSON);
        const csvString = Papa.unparse(csvData);
        downloadFile(csvString, `${projectName}-instanceGroup.csv`);
      } else {
        downloadFile(data, `${projectName}-instanceGroup.json`, 'text/json');
      }
    }
    close();
  }

  @autobind
  convertToJsonData(data) {
    return `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data || {}, null, '  '))}`;
  }

  @autobind
  renderContainerName({ rowData }) {
    const { intl } = this.props;
    const { containerName } = rowData;
    return (
      <Popover
        mouseEnterDelay={0.3}
        placement="right"
        title={null}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxHeight: 300, maxWidth: 350 }}>
            <div className="flex-row">
              <div className="light-label bold" style={{ minWidth: 70 }}>
                {intl.formatMessage(DashboardMessages.container)}:
              </div>
              <div style={{ wordBreak: 'break-all' }}>{containerName}</div>
            </div>
          </div>
        }
      >
        <div style={{ maxWidth: 160 }} className="hidden-line-with-ellipsis inline-block">
          {containerName}
        </div>
      </Popover>
    );
  }

  @autobind
  renderInstanceName({ rowData }) {
    const { intl, projectName, projects } = this.props;
    const currentProject = R.find((project) => project.projectName === projectName, projects) || {};
    const { isMetric, isContainer } = currentProject;
    const { instanceName, isRoot, isExpand } = rowData;
    const { containers } = rowData;

    // get instance/container name for log/metric container project
    const instanceNameOnly = instanceName;

    return (
      <div className="full-width flex-row flex-min-width flex-center-align">
        <Popover
          mouseEnterDelay={0.3}
          placement="right"
          title={null}
          content={
            <div className="flex-col overflow-y-auto" style={{ maxHeight: 300, maxWidth: 350 }}>
              <div className="flex-row">
                <div className="light-label bold" style={{ minWidth: 120 }}>
                  {intl.formatMessage(appFieldsMessages.instanceName)}:
                </div>
                <div style={{ wordBreak: 'break-all' }}>{instanceNameOnly}</div>
              </div>
              {isContainer && containers.length > 0 && (
                <>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ minWidth: 120 }}>
                      {intl.formatMessage(DashboardMessages.container)} count:
                    </div>
                    <div className="flex-col" style={{ wordBreak: 'break-all' }}>
                      {containers.length}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ minWidth: 120 }}>
                      {intl.formatMessage(DashboardMessages.container)}:
                    </div>
                    <div className="flex-col" style={{ wordBreak: 'break-all' }}>
                      {containers &&
                        containers.length > 0 &&
                        R.map((c) => {
                          return <div key={c}>{c}</div>;
                        }, containers || [])}
                      {!containers || (containers.length === 0 && 'N/A')}
                    </div>
                  </div>
                </>
              )}
            </div>
          }
        >
          <div className="full-width flex-row flex-center-align">
            <div className="hidden-line-with-ellipsis inline-block" style={{ maxWidth: 'calc(100% - 17px)' }}>
              {instanceName}
            </div>
            {isRoot && isContainer && (
              <div className="clickable" style={{ marginLeft: 6 }} onClick={(e) => this.itemExpandlRender(e, rowData)}>
                {isExpand ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
              </div>
            )}
          </div>
        </Popover>
      </div>
    );
  }

  @autobind
  sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const { allExpand } = this.state;
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div style={{ padding: '0 6px' }}>
        {label}
        {!disableSort && sortIcon()}
        {dataKey === 'instanceName' && this.props?.currentProject?.isContainer && (
          <span onClick={this.headerAllExpandClick}>
            <DoubleLeftOutlined rotate={allExpand ? 90 : -90} />
          </span>
        )}
      </div>
    );
  }

  @autobind
  handlePodNameChange(e) {
    let { isCheckedAll } = this.state;
    const newLocalInstanceGroupList = this.localInstanceGroupList;
    const { value } = e.target;
    if (!value && e.type !== 'change') {
      isCheckedAll = newLocalInstanceGroupList.length > 0 && !R.find((m) => !m.checked, newLocalInstanceGroupList);
    }
    this.setState({ isCheckedAll });
  }

  @autobind
  handleDelete() {
    const { projectName, credentials, currentProject } = this.props;
    const isContainer = get(currentProject, 'isContainer');
    let diffGroupingUpdate = R.filter((item) => item.checked, this.localInstanceGroupList);
    const localInstanceGroupListChildren = R.filter(
      (item) => item.checked,
      R.unnest(R.map((item) => item.children, this.localInstanceGroupList)),
    );
    diffGroupingUpdate = [...diffGroupingUpdate, ...localInstanceGroupListChildren];
    let instanceList = R.map((item) => {
      return isContainer
        ? `${item.containerName ? `${item.containerName}_` : ''}${item.instanceName}`
        : item.instanceName;
    }, diffGroupingUpdate);
    instanceList = R.uniq(instanceList);

    const self = this;
    Modal.confirm({
      title: 'Do you Want to delete these items?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        self.setState({
          isSubmitting: true,
        });
        const fetchUrl = `groupingstorage?operation=delete&projectName=${projectName}`;
        const fd = new FormData();
        fd.append('data', JSON.stringify(instanceList));
        fetchPostForm(getEndpoint(fetchUrl), fd)
          .then((res) => {
            window.location.reload();
            this.setState({
              isSubmitting: false,
            });
          })
          .catch((e) => {
            self.setState({
              isSubmitting: false,
            });
          });
      },
    });
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());
    const { endTimeObj } = this.state;
    this.setState({
      startTimeObj,
      endTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'add'),
    });
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());
    const { startTimeObj } = this.state;

    this.setState({
      endTimeObj,
      startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
    });
  }

  @autobind
  handleStartOpenChange(open) {
    if (!open) {
      this.setState({ endTimeOpen: true });
    }
  }

  @autobind
  handleEndOpenChange(open) {
    this.setState({ endTimeOpen: open });
  }

  @autobind
  async handleRefreshDataStatus() {
    this.setState({
      isLoadingInstanceMeta: true,
    });
    const { activeInstanceMap, instanceLostMap, activeContainerMap, containerLostMap } =
      await this.reloadActiveInstancce(this.props);
    this.localInstanceGroupList = R.map((item) => {
      return {
        ...item,
        status: null,
        statusStr: this.statusStr({ rowData: item, activeInstanceMap, instanceLostMap }),
        children: R.map(
          (_item) => ({
            ..._item,
            status: null,
            statusStr: this.statusStr({
              rowData: _item,
              activeInstanceMap: activeContainerMap,
              instanceLostMap: containerLostMap,
              containerFlag: true,
            }),
          }),
          item.children || [],
        ),
      };
    }, R.clone(this.localInstanceGroupList));

    this.setState({
      isLoadingInstanceMeta: false,
    });
    this.table.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  metricCellRender({ rowData }) {
    return (
      <Tooltip title="Metric anomalies" mouseEnterDelay={0.3} placement="top">
        <Button
          size="small"
          icon={
            <LineChartOutlined
              style={{ fontSize: 14, color: 'var(--black)' }}
              onClick={() => this.handleLineChartClick(rowData)}
            />
          }
        />
      </Tooltip>
    );
  }

  @autobind
  handleLineChartClick(rowData) {
    const { startTimeObj, endTimeObj } = this.state;
    const { location, currentProject } = this.props;
    const { customerName, systemSearch: systemId } = parseLocation(location);
    const { projectName, environmentName: environmentId } = currentProject;
    const { instanceName } = rowData;
    const startTimestamp = moment.utc(startTimeObj, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTimeObj, Defaults.DateFormat).endOf('day').valueOf();
    const startTime = startTimeObj.format(Defaults.DateFormat);
    const endTime = endTimeObj.format(Defaults.DateFormat);
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';
    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,
      projectName,
      instanceGroup,
      modelType,
      startTimestamp,
      endTimestamp,
      justSelectMetric: R.join(',', []),
      justInstanceList: instanceName,
      withBaseline: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleStatusSearch(statusValue) {
    const { currentProject } = this.props;
    const isContainer = get(currentProject, 'isContainer');
    let { isCheckedAll, isIgnoreAll } = this.state;
    let newLocalInstanceGroupList = this.localInstanceGroupList;
    this.setState({ statusValue }, () => {
      if (statusValue) {
        if (isContainer) {
          newLocalInstanceGroupList = R.filter((event) => {
            event.children = R.filter((c) => c.statusStr === statusValue, event.children);
            return event.children.length > 0 || event.statusStr === statusValue;
          }, R.clone(newLocalInstanceGroupList));
        } else {
          newLocalInstanceGroupList = R.filter((event) => event.statusStr === statusValue, newLocalInstanceGroupList);
        }
        isCheckedAll =
          newLocalInstanceGroupList.length > 0 &&
          !R.find(
            (m) => !m.checked,
            [...newLocalInstanceGroupList, ...R.unnest(R.map((item) => item.children, newLocalInstanceGroupList))],
          );
        isIgnoreAll =
          newLocalInstanceGroupList.length > 0 &&
          !R.find(
            (m) => !m.ignoreFlag,
            [...newLocalInstanceGroupList, ...R.unnest(R.map((item) => item.children, newLocalInstanceGroupList))],
          );
      } else {
        isCheckedAll =
          newLocalInstanceGroupList.length > 0 &&
          !R.find(
            (m) => !m.checked,
            [...newLocalInstanceGroupList, ...R.unnest(R.map((item) => item.children, newLocalInstanceGroupList))],
          );
        isIgnoreAll =
          newLocalInstanceGroupList.length > 0 &&
          !R.find(
            (m) => !m.ignoreFlag,
            [...newLocalInstanceGroupList, ...R.unnest(R.map((item) => item.children, newLocalInstanceGroupList))],
          );
      }
      this.setState({ isCheckedAll, isIgnoreAll });
    });
  }

  @autobind
  async changeInstanceMapping() {
    const { projectName, saveProjectSettings, data } = this.props;
    saveProjectSettings(
      projectName,
      { instanceConvertFlag: !data?.instanceConvertFlag, notPostHasSetData: true },
      {
        [submitLoader]: true,
      },
    );
  }

  render() {
    const { intl, projectName, currentProject, credentials } = this.props;
    const {
      isLoading,
      instanceListCount,
      isLoadingInstanceMeta,
      sortBy,
      sortDirection,
      searchComponentName,
      searchContainerName,
      groupTypeFile,
    } = this.state;
    const {
      showEditComponentMap,
      showmportInstanceMetaData,
      showGroupFileModal,
      showMetricInstanceNameSetting,
      showGroupTypeFileModal,
    } = this.state;
    const { page, pageSize, statusValue } = this.state;

    const { startTimeObj, endTimeObj, endTimeOpen } = this.state;

    const isMetric = get(currentProject, 'isMetric');
    const isContainer = get(currentProject, 'isContainer');

    const diffGroupingUpdate = R.filter(
      (item) => item.checked || R.find((_item) => _item.checked, item.children || []),
      this.localInstanceGroupList,
    );

    let newLocalInstanceGroupList = this.localInstanceGroupList;
    if (searchComponentName) {
      if (isContainer) {
        newLocalInstanceGroupList = R.filter((event) => {
          event.children = R.filter(
            (c) => R.toLower(c.appName).includes(R.toLower(searchComponentName)),
            event.children,
          );
          return event.children.length > 0 || R.toLower(event.appName).includes(R.toLower(searchComponentName));
        }, R.clone(newLocalInstanceGroupList));
      } else {
        newLocalInstanceGroupList = R.filter(
          (event) => R.toLower(event.appName).includes(R.toLower(searchComponentName)),
          newLocalInstanceGroupList,
        );
      }
    }

    if (searchContainerName) {
      newLocalInstanceGroupList = R.filter((event) => {
        event.children = R.filter(
          (c) => R.toLower(c.containerName).includes(R.toLower(searchContainerName)),
          event.children,
        );
        event.containers = R.map((item) => item.containerName, event.children);
        return event.children.length > 0;
      }, R.clone(newLocalInstanceGroupList));
    }

    if (statusValue) {
      if (isContainer) {
        newLocalInstanceGroupList = R.filter((event) => {
          event.children = R.filter((c) => c.statusStr === statusValue, event.children);
          return event.children.length > 0 || event.statusStr === statusValue;
        }, R.clone(newLocalInstanceGroupList));
      } else {
        newLocalInstanceGroupList = R.filter((item) => item.statusStr === statusValue, newLocalInstanceGroupList);
      }
    }

    let expandLocalInstanceGroupList = [];
    let instanceCount = 0;
    let containerTotal = 0;
    R.forEach((item) => {
      const { isExpand, children, isRoot } = item;
      if (isExpand && (children || []).length > 0) {
        expandLocalInstanceGroupList = [...expandLocalInstanceGroupList, item, ...children];
      } else {
        expandLocalInstanceGroupList = [...expandLocalInstanceGroupList, item];
      }
      instanceCount += isRoot ? 1 : 0;
      containerTotal += (children || []).length;
    }, newLocalInstanceGroupList);
    newLocalInstanceGroupList = expandLocalInstanceGroupList;
    this.newLocalInstanceGroupList = expandLocalInstanceGroupList;

    const hasErrorGroupingUpdate = diffGroupingUpdate.length === 0;
    const isSubmitting = get(this.props.currentLoadingComponents, this.submitLoadingKey, false);
    const isSubmittingGroupUpdate = get(this.props.currentLoadingComponents, this.submitGroupUpdateLoadingKey, false);
    const isBaseSettingting = get(this.props.currentLoadingComponents, submitLoader, false);

    return (
      <Container className={`full-width full-height flex-col flex-min-height ${isLoading ? ' loading' : ''}`}>
        <p>{intl.formatMessage(settingsMessages.instanceSettingDesc1)}</p>
        <p>{intl.formatMessage(settingsMessages.instanceSettingDesc2)}</p>

        <Container className="field flex-row flex-center-align">
          <div className="flex-grow flex-row" />

          <Dropdown
            name={intl.formatMessage(appFieldsMessages.configuration)}
            itemClick={this.handleInvestigateMenuClick}
          >
            <>
              <Menu.Item key="editComponentMap">{intl.formatMessage(settingsMessages.editComponentMap)}</Menu.Item>
              <Menu.Item key="importInstanceMetaData">
                {intl.formatMessage(settingsMessages.importInstanceMetaData)}
              </Menu.Item>
              <Menu.Item key="upload">Upload instance component name file</Menu.Item>
              <Menu.Item key="instanceName">Upload instance name mapping file</Menu.Item>
              <Menu.Item key="componentName">Upload component name mapping file</Menu.Item>
              <Menu.Item key="instanceAndComponentName">Upload instance component name mapping file</Menu.Item>
              <Menu.Item key="download">{intl.formatMessage(appButtonsMessages.download)}</Menu.Item>
              {!currentProject.isMetric && (
                <Menu.Item key="metricInstanceNameSetting">
                  {intl.formatMessage(settingsMessages.metricInstanceNameSetting)}
                </Menu.Item>
              )}
            </>
          </Dropdown>
        </Container>

        <Container className="flex-row" style={{ margin: '8px 0' }}>
          <div className="flex-grow flex-row flex-center-align">
            <div className="flex-row flex-center-align">
              <div style={{ marginRight: 8, fontWeight: 'bold' }}>
                {intl.formatMessage(appFieldsMessages.instance)}:
              </div>
              <Input.Search size="small" allowClear onSearch={this.handleInstanceSearch} style={{ maxWidth: 150 }} />
            </div>
            {isContainer && (
              <div className="flex-row flex-center-align" style={{ marginLeft: 6 }}>
                <div style={{ marginRight: 8, fontWeight: 'bold' }}>
                  {intl.formatMessage(DashboardMessages.container)}:
                </div>
                <Input.Search
                  size="small"
                  allowClear
                  onSearch={this.handleContainerSearch}
                  onChange={(e) => this.handlePodNameChange(e)}
                  style={{ maxWidth: 150 }}
                />
              </div>
            )}
            <div className="flex-row flex-center-align" style={{ marginLeft: 6 }}>
              <div style={{ marginRight: 8, fontWeight: 'bold' }}>
                {intl.formatMessage(eventMessages.componentName)}:
              </div>
              <Input.Search
                size="small"
                allowClear
                onSearch={this.handleComponentNameSearch}
                onChange={(e) => this.handlePodNameChange(e)}
                style={{ maxWidth: 150 }}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ marginLeft: 6 }}>
              <div style={{ marginRight: 8, fontWeight: 'bold' }}>{intl.formatMessage(appFieldsMessages.status)}:</div>
              <Select
                showSearch
                allowClear
                size="small"
                placeholder=""
                value={statusValue}
                onChange={this.handleStatusSearch}
                className="full-width"
                style={{ width: 150 }}
              >
                <Select.Option value="Missing data">Missing data</Select.Option>
                <Select.Option value="Complete data">Complete data</Select.Option>
                <Select.Option value="No data">No data</Select.Option>
              </Select>
            </div>
          </div>

          <div className="flex-row flex-center-align">
            <Pagination
              current={page}
              total={instanceListCount}
              pageSize={pageSize}
              onChange={this.handlePaginationChange}
              showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
              showSizeChanger
              pageSizeOptions={['50', '100', '500', '1000']}
              onShowSizeChange={this.handlePaginationSizeChange}
            />
          </div>
        </Container>

        <Container className="flex-row flex-center-align" style={{ margin: '8px 0' }}>
          <div className="flex-row flex-center-align">
            <span style={{ fontWeight: 700, marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.startDate)}:</span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={startTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleStartTimeChange}
              onOpenChange={this.handleStartOpenChange}
            />
            <span style={{ fontWeight: 700, marginLeft: 6, marginRight: 8 }}>
              {intl.formatMessage(appFieldsMessages.endDate)}:
            </span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={endTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleEndTimeChange}
              open={endTimeOpen}
              onOpenChange={this.handleEndOpenChange}
            />
            <Button size="small" onClick={this.handleRefreshDataStatus} style={{ marginLeft: 6 }}>
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </div>
          <div className="flex-row flex-center-align" style={{ marginLeft: 8 }}>
            <div style={{ marginRight: 4 }}>Instance mapping:</div>
            <Spin spinning={isBaseSettingting}>
              <Popconfirm
                title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                okText={intl.formatMessage(appButtonsMessages.yes)}
                cancelText={intl.formatMessage(appButtonsMessages.no)}
                onConfirm={this.changeInstanceMapping}
                onCancel={(event) => event.stopPropagation()}
                okButtonProps={{ loading: isBaseSettingting }}
              >
                <Checkbox
                  checked={this?.props?.data?.instanceConvertFlag}
                  onChange={(event) => event.stopPropagation()}
                />
              </Popconfirm>
            </Spin>
          </div>
          <div className="flex-grow" />
          {!isLoadingInstanceMeta && (
            <>
              <div style={{ marginRight: 16 }}>
                {intl.formatMessage(appFieldsMessages.instanceCount)}:{' '}
                <span style={{ fontSize: 14, fontWeight: 'bold' }}>{instanceCount}</span>
              </div>
              {isContainer && (
                <div style={{ marginRight: 16 }}>
                  {intl.formatMessage(appFieldsMessages.containerCount)}:{' '}
                  <span style={{ fontSize: 14, fontWeight: 'bold' }}>{containerTotal}</span>
                </div>
              )}
            </>
          )}
        </Container>

        <Container className="flex-grow flex-min-height">
          <Spin spinning={isLoadingInstanceMeta} wrapperClassName="full-width full-height spin-full-width">
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowClassName={({ index }) => {
                    let name = '';
                    if (isContainer) {
                      const data = newLocalInstanceGroupList[index];
                      if (data?.isRoot) name = 'instance-row';
                    } else {
                      name = index >= 0 && index % 2 === 1 ? 'odd-row' : '';
                    }
                    return name;
                  }}
                  rowHeight={40}
                  rowCount={newLocalInstanceGroupList.length}
                  rowGetter={({ index }) => newLocalInstanceGroupList[index]}
                  ref={(c) => {
                    this.table = c;
                  }}
                  sort={this.sortTable}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  <Column
                    width={40}
                    dataKey="checked"
                    className="text-center"
                    headerClassName="text-center"
                    headerRenderer={this.checkAllHeaderRender('isCheckedAll')}
                    cellRenderer={this.checkboxCellRender}
                  />
                  {isContainer && (
                    <Column
                      width={140}
                      label={intl.formatMessage(DashboardMessages.container)}
                      dataKey="containerName"
                      className="white-pre"
                      flexShrink={0}
                      headerStyle={{ padding: 0 }}
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.renderContainerName}
                    />
                  )}
                  <Column
                    width={160}
                    label={intl.formatMessage(appFieldsMessages.instance)}
                    dataKey="instanceName"
                    className="white-pre"
                    flexGrow={1}
                    headerStyle={{ padding: 0 }}
                    headerRenderer={this.headerRenderer}
                    cellRenderer={this.renderInstanceName}
                  />
                  <Column
                    width={160}
                    label={intl.formatMessage(appFieldsMessages.instanceDisplayName)}
                    dataKey="instanceDisplayName"
                    className="white-pre"
                    flexShrink={0}
                    headerRenderer={this.headerRenderer}
                    cellRenderer={(cellProps) => this.inputCellRender(cellProps, this.localInstanceGroupList)}
                  />
                  {/* {isContainer && (
                    <Column
                      width={30}
                      flexShrink={0}
                      dataKey="allExpand"
                      className="text-center"
                      headerClassName="text-center"
                      headerStyle={{ padding: 0 }}
                      headerRenderer={this.allExpandRender}
                      cellRenderer={this.expandlRender}
                    />
                  )} */}
                  {!currentProject.isMetric && (
                    <Column
                      width={160}
                      label={intl.formatMessage(eventMessages.metricInstanceName)}
                      dataKey="metricInstanceName"
                      className="white-pre"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.inputCellRender}
                      flexGrow={1}
                      disableSort
                    />
                  )}
                  <Column
                    width={160}
                    label={intl.formatMessage(eventMessages.componentName)}
                    dataKey="appName"
                    className="white-pre"
                    headerRenderer={this.headerRenderer}
                    cellRenderer={this.inputCellRender}
                  />
                  {!isMetric && isContainer && (
                    <Column
                      width={160}
                      label={intl.formatMessage(eventMessages.analysisGroup)}
                      dataKey="analysisGroup"
                      className="white-pre"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.inputCellRender}
                    />
                  )}
                  <Column
                    width={160}
                    label={intl.formatMessage(settingsMessages.IPAddress)}
                    dataKey="ipAddress"
                    headerRenderer={this.headerRenderer}
                    className="white-pre"
                    cellRenderer={this.inputCellRender}
                  />
                  <Column
                    width={120}
                    label={intl.formatMessage(settingsMessages.zone)}
                    dataKey="zone"
                    className="white-pre"
                    headerRenderer={this.headerRenderer}
                    cellRenderer={this.zoneCellRender}
                  />
                  {isMetric && (
                    <Column
                      width={120}
                      label="Instance type"
                      dataKey="instanceType"
                      className="white-pre"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.inputCellRender}
                    />
                  )}
                  {isMetric && (
                    <Column
                      width={100}
                      label="Number of cores"
                      dataKey="coreCount"
                      className="white-pre"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.coreCountRender}
                    />
                  )}

                  {isMetric && (
                    <Column
                      width={170}
                      label={
                        <>
                          Instance down disabling threshold
                          <Popover
                            overlayInnerStyle={{ width: 300 }}
                            content="The threshold defines the loss ratio lower bound for disabling instance down detection. The loss ratio needs to be larger than or equal to 0 and less than 1. If loss ratio is set to be 0, the loss ratio check is disabled."
                          >
                            <InfoCircleOutlined style={{ fontSize: 14, marginLeft: 4 }} />
                          </Popover>
                        </>
                      }
                      dataKey="lossRatio"
                      className="text-center"
                      headerClassName="text-center"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={this.lossRatioRender}
                    />
                  )}
                  <Column
                    width={100}
                    label={intl.formatMessage(appFieldsMessages.status)}
                    dataKey="statusStr"
                    className="text-center"
                    flexShrink={0}
                    headerClassName="text-center"
                    headerRenderer={this.headerRenderer}
                    cellRenderer={this.statusCellRender}
                  />
                  <Column
                    width={90}
                    label={intl.formatMessage(appFieldsMessages.ignore)}
                    dataKey="ignoreFlag"
                    className="text-center"
                    headerStyle={{ ...(isContainer ? { paddingLeft: 8 } : {}) }}
                    headerClassName="text-center tour-setting-metric-kpi"
                    headerRenderer={this.checkAllHeaderRender('isIgnoreAll')}
                    cellRenderer={this.checkboxCellRender}
                  />
                  {isMetric && (
                    <Column
                      width={86}
                      label="Line charts"
                      dataKey="action"
                      className="text-center white-pre"
                      headerClassName="text-center"
                      flexShrink={0}
                      cellRenderer={this.metricCellRender}
                    />
                  )}
                </Table>
              )}
            </AutoSizer>
          </Spin>
        </Container>

        <Container className="flex-row" style={{ marginTop: 8 }}>
          <div className="flex-grow" />
          <Button
            size="small"
            type="primary"
            icon={<SaveOutlined />}
            loading={isSubmitting}
            disabled={hasErrorGroupingUpdate}
            onClick={this.handleDelete}
          >
            {intl.formatMessage(appButtonsMessages.delete)}
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ marginLeft: 8 }}
            loading={isSubmittingGroupUpdate}
            disabled={hasErrorGroupingUpdate}
            onClick={this.handleGroupingUpdateClick}
          >
            {intl.formatMessage(settingsMessages.groupSettingsUpdate)}
          </Button>
          <Button
            size="small"
            style={{ marginLeft: 8 }}
            loading={isSubmitting}
            onClick={this.handleResetLocalChangeClick}
          >
            {intl.formatMessage(appButtonsMessages.reset)}
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<SaveOutlined />}
            style={{ marginLeft: 8 }}
            loading={isSubmitting}
            onClick={this.handleUpdateInstanceGroupClick}
          >
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </Container>

        {showEditComponentMap && (
          <GroupEditComponentMapModal
            projectName={projectName}
            localInstanceGroupList={this.localInstanceGroupList}
            onClose={this.handleGroupEditComponentMapModalClose}
          />
        )}
        {showmportInstanceMetaData && (
          <GroupImportInstanceMetaModal
            projectName={projectName}
            currentProject={currentProject}
            onClose={(reload) =>
              this.setState({ showmportInstanceMetaData: false }, () => {
                if (reload) this.reloadData(this.props);
              })
            }
          />
        )}
        {showGroupFileModal && <GroupFileModal intl={intl} onClose={this.handleGroupFileModalClose} />}
        {showGroupTypeFileModal && (
          <GroupTypeFileModal
            intl={intl}
            credentials={credentials}
            groupTypeFile={groupTypeFile}
            project={currentProject}
            projectName={projectName}
            onClose={this.handleGroupTypeFileModalClose}
          />
        )}
        {this.state.showGroupingUpdateModal && (
          <GroupingUpdateModal
            intl={intl}
            isMetric={isMetric}
            isSubmitting={isSubmittingGroupUpdate}
            onOk={this.handleGroupingUpdateSave}
            onClose={() => this.setState({ showGroupingUpdateModal: false })}
          />
        )}
        {showMetricInstanceNameSetting && (
          <GroupMetricInstanceNameSettingModal
            intl={intl}
            project={currentProject}
            projectName={projectName}
            credentials={credentials}
            onClose={(reload) =>
              this.setState({ showMetricInstanceNameSetting: false }, () => {
                if (reload) this.reloadData(this.props);
              })
            }
          />
        )}
      </Container>
    );
  }
}

const GroupingUpdateModal = ({ intl, isSubmitting, onOk, onClose, isMetric }: Object) => {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    componentName: '',
    componentNameChecked: false,
    zone: '',
    zoneChecked: false,
    lossRatio: null,
    lossRatioChecked: false,
  });
  const { componentName, componentNameChecked, lossRatio, lossRatioChecked, zoneChecked, zone } = state;

  const allowSave = Boolean(componentName) || Boolean(zone) || (isMetric && !R.isNil(lossRatio));

  const renderLabel = (label, checked, resetHandler) => {
    return (
      <div className="flex-row">
        {checked && (
          <div style={{ margin: ' 0 0.5em 0 0' }}>
            <Tooltip placement="top" title="Click to reset">
              <CheckOutlined onClick={resetHandler} style={{ fontSize: 16, color: '#f85042' }} />
            </Tooltip>
          </div>
        )}
        {label}
      </div>
    );
  };

  return (
    <Modal
      title={intl.formatMessage(settingsMessages.groupSettingsUpdate)}
      width={700}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={() =>
        onOk({
          componentName: componentNameChecked ? componentName : undefined,
          zone: zoneChecked ? zone : undefined,
          lossRatio: lossRatioChecked ? lossRatio : undefined,
        })
      }
      okButtonProps={{ disabled: !allowSave, loading: isSubmitting }}
    >
      <Form labelCol={{ style: { width: 250 } }} wrapperCol={{ span: 12 }}>
        <Form.Item
          label={renderLabel(intl.formatMessage(eventMessages.componentName), componentNameChecked, () =>
            setState({ componentName: '', componentNameChecked: false }),
          )}
        >
          <Input
            value={componentName}
            onChange={(e) => setState({ componentName: e.target.value, componentNameChecked: true })}
          />
        </Form.Item>
        {/* <Form.Item label={renderLabel('Zone', zoneChecked, () => setState({ zone: '', zoneChecked: false }))}>
          <Input value={zone} onChange={(e) => setState({ zone: e.target.value, zoneChecked: true })} />
        </Form.Item> */}
        {isMetric && (
          <Form.Item
            label={renderLabel(
              <>
                Instance down disabling threshold
                <Popover
                  overlayInnerStyle={{ width: 300 }}
                  content="The threshold defines the loss ratio lower bound for disabling instance down detection. The loss ratio needs to be larger than or equal to 0 and less than 1. If loss ratio is set to be 0, the loss ratio check is disabled."
                >
                  <InfoCircleOutlined style={{ fontSize: 14, marginLeft: 4 }} />
                </Popover>
              </>,
              lossRatioChecked,
              () => setState({ lossRatio: null, lossRatioChecked: false }),
            )}
          >
            <InputNumber
              min={0}
              max={0.9}
              step={0.1}
              size="small"
              value={lossRatio}
              style={{ width: '100%' }}
              onChange={(value) => setState({ lossRatio: value || 0, lossRatioChecked: true })}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

const InstanceSetting = injectIntl(InstanceSettingCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { isAdmin, isReadUser, isLocalAdmin, userName } = state.auth.userInfo;

    return {
      location,
      credentials,
      isAdmin,
      isReadUser,
      isLocalAdmin,
      userName,
    };
  },
  { updateLastActionInfo, loadProjectInfo, replace },
)(InstanceSetting);
