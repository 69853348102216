/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Alert, Form, Checkbox, Divider, Input, Select } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Container } from '../../../../lib/fui/react';
// import { MetricParser } from '../../../../common/utils';

import { appFieldsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

class EndpointSettingPagerDutyCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoadingKey: false,
      isLoading: false,
      isUpdateKey: false,
      isSubmitting: false,
      errorMessageKey: '',
      errorMessage: '',

      apiToken: '',

      fields: [],
      services: [],
      selectServices: [],
      timestampField: null,
      instanceField: null,
    };
    this.apiToken = '';
  }

  componentDidMount() {
    this.reloadKey(this.props);
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadKey(nextProps);
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadKey(props) {
    const { credentials, projectName, currentProject } = props;

    this.setState({ isLoadingKey: true });
    const cloudType = get(currentProject, ['cloudType']);
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const { success, message: errMsg, apiToken } = data || {};
        if (success === undefined || success) {
          this.apiToken = apiToken;
          this.setState({
            isLoadingKey: false,
            errorMessageKey: undefined,

            apiToken: apiToken || '',
          });
        } else {
          this.setState({
            isLoadingKey: false,
            errorMessageKey: errMsg,

            apiToken: '',
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoadingKey: false,
          errorMessageKey: errMsg || String(err),

          apiToken: '',
        });
      });
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName } = props;

    this.setState({ isLoading: true });
    return fetchGet(getEndpoint('pagerduty-project-setting'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const { success, message: errMsg, timestampField, instanceField, services: selectServices } = data || {};
        if (success === undefined || success) {
          let fields = get(data, 'fields', []);
          let services = get(data, 'allServices', []);

          fields = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), fields);
          services = R.map((item) => ({ value: item.id, label: item.name }), services);

          this.setState({
            isLoading: false,
            errorMessage: undefined,
            fields,
            services,
            timestampField,
            instanceField,
            selectServices,
          });
        } else {
          this.setState({
            isLoading: false,
            errorMessage: errMsg,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          errorMessage: err.errMsg || String(err),
        });
      });
  }

  @autobind
  handleUpdateKey() {
    const { credentials, projectName } = this.props;

    this.setState({ isUpdateKey: true });
    const { apiToken } = this.state;
    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      apiToken,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          this.setState({ isUpdateKey: false, errorMessageKey: undefined });
          this.reloadKey(this.props);
          this.reloadData(this.props);
        } else {
          this.setState({ isUpdateKey: false, errorMessageKey: errMsg });
        }
      })
      .catch((err) => {
        this.setState({ isUpdateKey: false, errorMessageKey: String(err) });
      });
  }

  @autobind
  handleSubmit() {
    const { credentials, projectName } = this.props;

    this.setState({ isSubmitting: true });
    const { instanceField, timestampField, selectServices } = this.state;
    return fetchPost(getEndpoint('pagerduty-project-setting'), {
      ...credentials,
      projectName,
      instanceField,
      timestampField,
      services: JSON.stringify(selectServices || []),
    })
      .then((data) => {
        this.setState({ isSubmitting: false, errorMessage: undefined });
        this.reloadData(this.props);
      })
      .catch((err) => {
        this.setState({ isSubmitting: false, errorMessage: String(err) });
      });
  }

  render() {
    const { intl } = this.props;
    const { isLoadingKey, isLoading, isUpdateKey, isSubmitting, errorMessageKey, errorMessage } = this.state;
    const {
      apiToken,

      fields,
      services,
      selectServices,
      timestampField,
      instanceField,
    } = this.state;
    const servicesIds = R.map((item) => item.value, services);

    const hasErrKey = !apiToken;
    const hasChangeKey = this.apiToken !== apiToken;
    const hasFormErr = !timestampField || !instanceField || selectServices.length === 0;
    return (
      <Container fullHeight className={`overflow-y-auto ${isLoadingKey || isLoading ? ' loading' : ''}`}>
        <div className="full-height" style={{ width: 800 }}>
          <Form layout="vertical">
            <Form.Item
              label="Api Token"
              required
              validateStatus={!apiToken ? 'error' : 'success'}
              help={!apiToken ? 'This input is required.' : undefined}
            >
              <Input.Password value={apiToken} onChange={(e) => this.setState({ apiToken: e.target.value })} />
            </Form.Item>
            {errorMessageKey && (
              <Alert message="Error" description={errorMessageKey} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasErrKey || !hasChangeKey}
                loading={isUpdateKey}
                onClick={this.handleUpdateKey}
              >
                Update private key
              </Button>
            </Form.Item>

            <Form.Item label="InsightFinder Analysis Services in PagerDuty" required>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                allowClear
                autoClearSearchValue={false}
                maxTagCount={1}
                showSearch
                value={selectServices}
                onChange={(selectServices) => {
                  this.setState({ selectServices });
                }}
                dropdownRender={(menu) => {
                  const selectAll = R.difference(servicesIds, selectServices).length === 0;
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selectAll}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              this.setState({ selectServices: servicesIds });
                            } else {
                              this.setState({ selectServices: [] });
                            }
                          }}
                        />
                        <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
              >
                {R.map((item) => {
                  return (
                    <Select.Option className="hide-icon" key={item.value}>
                      <Checkbox
                        checked={selectServices.includes(item.value)}
                        onChange={(e) => null}
                        style={{ marginRight: 8 }}
                      />
                      {item.label}
                    </Select.Option>
                  );
                }, services)}
              </Select>
            </Form.Item>
            <Form.Item label="InsightFinder Analysis Timestamp in PagerDuty" required>
              <Select
                value={timestampField}
                onChange={(timestampField) => {
                  this.setState({ timestampField });
                }}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {R.map((field) => {
                  return <Select.Option key={field}>{field}</Select.Option>;
                }, fields)}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <Popover
                  title={null}
                  content={
                    <div style={{ width: 320 }}>
                      This instance in InsightFinder represents the ticket grouping granularity for pattern learning.
                    </div>
                  }
                  placement="right"
                >
                  <span>
                    InsightFinder Analysis Instance in PagerDuty
                    <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
                  </span>
                </Popover>
              }
              required
            >
              <Select
                value={instanceField}
                onChange={(instanceField) => {
                  this.setState({ instanceField });
                }}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {R.map((field) => {
                  return <Select.Option key={field}>{field}</Select.Option>;
                }, fields)}
              </Select>
            </Form.Item>
            {errorMessage && (
              <Alert message="Error" description={errorMessage} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasChangeKey || hasFormErr}
                loading={isSubmitting}
                onClick={this.handleSubmit}
              >
                Update metrics
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    );
  }
}

const EndpointSettingPagerDuty = injectIntl(EndpointSettingPagerDutyCore);
export default connect((state) => {
  return {};
}, {})(EndpointSettingPagerDuty);
