import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { get, round } from 'lodash';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import VLink from 'valuelink';
import Papa from 'papaparse';
import * as R from 'ramda';
import numeral from 'numeral';
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { Modal } from '../../../../artui/react';
import { DatePicker } from '../../../lib/fui/react';
import { Defaults } from '../../../common/app';
import { getMetricEventList } from '../../../common/apis';
import { buildUrl, CellRenderers, Defaults as utilsDefaults } from '../../../common/utils';
import { BaseUrls } from '../../app/Constants';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  projectName: String,
  instanceGroup: String,
  startTime: String,
  endTime: String,
  onClose: Function,

  intl: Object,
  location: Object,
  credentials: Object,
  // eslint-disable-next-line
  instanceList: Array<Object>,
  project: Object,
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

class EventExportModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { startTime, endTime } = props;

    const startTimeObj = moment.utc(startTime, Defaults.DateFormat).startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
    this.state = { startTimeObj, endTimeObj, loading: false };
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  @autobind
  handleExport() {
    const { projectName, instanceGroup, credentials, instanceList: allInstanceList, intl } = this.props;
    let { startTimeObj, endTimeObj } = this.state;
    startTimeObj = startTimeObj.clone().startOf('day');
    endTimeObj = endTimeObj.clone().endOf('day');
    this.setState({ isLoading: true }, async () => {
      try {
        const resp = await getMetricEventList(credentials, {
          withPredict: true,
          projectName,
          instanceGroup,
          startTimeObj,
          endTimeObj,
          instanceList: allInstanceList,
        });

        const allEventList = get(resp, ['eventListMap', 'eventList'], []);
        const appNameMapping = {};
        // get metric and instance filter options
        let metricList = [];
        let instanceList = [];
        let componentList = [];
        R.forEach((e) => {
          R.forEach((mm) => {
            const { rootCauseMetric, instanceId, appName } = mm;
            metricList.push(rootCauseMetric);
            instanceList.push(instanceId);
            componentList.push(appName);
            appNameMapping[instanceId] = appName;
          }, get(e, ['rootCauseJson', 'rootCauseDetailsArr'], []));
        }, allEventList);
        metricList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(metricList));
        instanceList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceList));
        componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));

        // split event
        let eventList = [];
        R.forEach((event) => {
          const { isPrediction, isEmailSent, ignoreFlag, isIncidentEvent, isDeploymentEvent, fromStorage } = event;
          R.forEach((mm) => {
            const {
              isIgnored,
              patternId,
              patternName,
              instanceId,
              appName: component,
              rootCauseMetric,
              direction,
              pct,
              timePairArr,
              totalScore,
              isDetectedIncident,
              isDetectedAnomaly,
            } = mm;
            const appName =
              appNameMapping[instanceId] && appNameMapping[instanceId] !== instanceId
                ? `${instanceId} (${appNameMapping[instanceId]})`
                : instanceId;

            // get score
            const score = totalScore || Math.abs(pct);

            // split time pair by day
            const timePairMap = {};
            R.forEach((tr) => {
              const { startTimestamp, endTimestamp } = tr;
              const day = moment.utc(startTimestamp).format(Defaults.DateFormat);
              if (!R.has(day, timePairMap)) {
                timePairMap[day] = [];
              }
              timePairMap[day].push({ day, startTimestamp, endTimestamp });
            }, timePairArr);

            R.forEachObjIndexed((val, day) => {
              const key = `${uuidv4()}-${instanceId}-${rootCauseMetric}-${patternId}-${day}`;
              eventList.push({
                key,
                event,
                // not used, only for track
                rootCauseDetails: mm,

                isPrediction,
                isEmailSent,
                ignoreFlag,
                isIncidentEvent,
                isDeploymentEvent,
                fromStorage,

                isIgnored,

                patternId,
                patternName,
                appName,
                component,
                instanceId,
                rootCauseMetric,
                direction,
                day,
                score,

                timePairArr: val,
                isDetectedIncident,
                isDetectedAnomaly,
              });
            }, timePairMap);
          }, get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []));
        }, allEventList);

        // merge events
        eventList = this.mergeEventList(eventList);
        // sort events
        eventList = this.sortData(eventList, undefined, undefined);

        eventList = R.map(
          (item) => ({
            'Anomaly component': item.component,
            'Anomaly instance': item.instanceId,
            'Amaly metric': item.rootCauseMetric,
            Direction: item.direction,
            'Anomaly pattern': item.patternId,
            Day: item.day,
            Time: this.setTimeList(item.timePairList),
            'Avg duration': CellRenderers.humanizeDuration({ period: item.duration, intl }),
            'Anomaly score': numeral(item.score).format(item.score > 1 ? '0,0' : '0,0.[0000]'),
          }),
          eventList,
        );

        const csv = Papa.unparse(eventList);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
        hiddenElement.target = '_blank';
        hiddenElement.download = `if_${projectName}_${startTimeObj.format(Defaults.TimeFormat)}~${endTimeObj.format(
          Defaults.TimeFormat,
        )}.csv`;
        hiddenElement.click();
      } catch (e) {
        console.error(e);
        window.alert('Failed to load event list');
      }
      this.setState({ isLoading: false }, () => {
        this.props.onClose();
      });
    });
  }

  @autobind
  setTimeList(timePairList) {
    let str = '';
    R.forEach((item) => {
      str += `Start: ${moment.utc(Number(item[0])).format(utilsDefaults.ShortTimeOnlyFormat)}
End: ${moment.utc(Number(item[1])).format(utilsDefaults.ShortTimeOnlyFormat)}`;
    }, timePairList || []);
    return str;
  }

  @autobind
  mergeEventList(eventList) {
    const { project } = this.props;
    const eventListMap = {};

    R.forEach((event) => {
      if (!R.has(event.key, eventListMap)) {
        eventListMap[event.key] = event;
      } else {
        eventListMap[event.key] = {
          ...eventListMap[event.key],

          isPrediction: eventListMap[event.key].isPrediction || event.isPrediction,
          isEmailSent: eventListMap[event.key].isEmailSent || event.isEmailSent,
          ignoreFlag: eventListMap[event.key].ignoreFlag || event.ignoreFlag,
          isIncidentEvent: eventListMap[event.key].isIncidentEvent || event.isIncidentEvent,
          isDeploymentEvent: eventListMap[event.key].isDeploymentEvent || event.isDeploymentEvent,
          fromStorage: eventListMap[event.key].fromStorage || event.fromStorage,

          isIgnored: eventListMap[event.key].isIgnored || event.isIgnored,

          score: R.max(eventListMap[event.key].score, event.score),
          timePairArr: [...eventListMap[event.key].timePairArr, ...event.timePairArr],
        };
      }
    }, eventList);

    // build merge data
    let newEventList = R.values(eventListMap);
    newEventList = R.map((event) => {
      const { timePairArr } = event;

      let timePairList = [];
      let allDuration = 0;
      let startTimestamp;
      let endTimestamp;
      R.forEach((tr) => {
        const { startTimestamp: st, endTimestamp: et, duration } = tr;
        timePairList.push([st, et, duration]);
        allDuration += duration || et - st;
        startTimestamp = startTimestamp ? R.min(startTimestamp, st) : st;
        endTimestamp = endTimestamp ? R.max(endTimestamp, et) : et;
      }, timePairArr);
      const duration =
        timePairArr.length > 0 ? round(allDuration / timePairArr.length) + (project.samplingInterval || 0) * 60000 : 0;
      timePairList = R.sortBy(R.prop(0), timePairList);

      return {
        ...event,

        timePairList,
        duration,
        startTimestamp,
        endTimestamp,
      };
    }, newEventList);

    return newEventList;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.compose(R.toLower, R.prop('instanceId'))), R.descend(R.prop('startTimestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  handlePdfExport() {
    const { projectName } = this.props;
    let { startTimeObj, endTimeObj } = this.state;
    startTimeObj = startTimeObj.clone().startOf('day');
    endTimeObj = endTimeObj.clone().endOf('day');
    const url = buildUrl(
      BaseUrls.ReportMetric,
      {},
      { projectName, startTimestamp: startTimeObj.valueOf(), endTimestamp: endTimeObj.valueOf() },
    );
    window.open(url, '_blank');
    this.props.onClose();
  }

  render() {
    const { projectName, intl } = this.props;
    const { isLoading } = this.state;
    const startTimeObjLink = VLink.state(this, 'startTimeObj').check((x) => Boolean(x), 'start time is required');
    const endTimeObjLink = VLink.state(this, 'endTimeObj').check((x) => Boolean(x), 'end time is required');
    const hasError = startTimeObjLink.error || endTimeObjLink.error;
    const nowObj = moment.utc();

    return (
      <Modal size="tiny" closable onClose={this.handleClose}>
        <div style={{ padding: '16px 10px 8px' }}>
          <div style={{ paddingBottom: 8 }}>
            <span style={{ paddingRight: 8 }}>{intl.formatMessage(eventMessages.selectTimeRange)}</span>
            <span style={{ color: 'blue' }}>{projectName}</span>
          </div>
          <div className="flex-row flex-center-align" style={{ fontSize: 12, paddingBottom: 8 }}>
            <div style={{ fontWeight: 500, paddingRight: 8 }}>{`${intl.formatMessage(eventMessages.startTime)}:`}</div>
            <div style={{ paddingRight: 16 }}>
              <DatePicker
                todayButton="Today"
                selectedLink={startTimeObjLink}
                dateFormat={Defaults.DateFormat}
                showMonthDropdown
                maxDate={nowObj}
              />
            </div>
            <div style={{ fontWeight: 500, paddingRight: 8 }}>{`${intl.formatMessage(eventMessages.endTime)}:`}</div>
            <DatePicker
              todayButton="Today"
              dateFormat={Defaults.DateFormat}
              showMonthDropdown
              selectedLink={endTimeObjLink}
              maxDate={nowObj}
            />
          </div>
          <div className="flex-row">
            <div className="flex-grow" />
            <Button
              type="primary"
              size="small"
              icon={<FileExcelOutlined />}
              loading={isLoading}
              disabled={hasError}
              onClick={this.handleExport}
            >
              CSV
            </Button>
            {/* <Button
              type="primary"
              size="small"
              icon={<FilePdfOutlined />}
              onClick={this.handlePdfExport}
              style={{ marginLeft: 8 }}
            >
              PDF
            </Button> */}
          </div>
        </div>
      </Modal>
    );
  }
}

const EventExportModal = injectIntl(EventExportModalCore);
export default connect((state) => {
  const { credentials } = state.auth;
  const { location } = state.router;
  return { credentials, location };
}, {})(EventExportModal);
