/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import { replace } from 'react-router-redux';
import { get, floor, isFunction } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { PauseOutlined } from '@ant-design/icons';
import { Spin, Alert, Input, Tooltip, Card, Empty } from 'antd';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { Container, AutoSizer } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { LogRenderers } from '../../../common/utils';

import { EChart } from '../../share';
import { appFieldsMessages } from '../../../common/app/messages';

type Props = {
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  instanceName: String,
  // eslint-disable-next-line
  startTimestamp: Number,
  // eslint-disable-next-line
  endTimestamp: Number,
  // eslint-disable-next-line
  clusterInfo: Object,
  // eslint-disable-next-line
  clusterActivePatternChange: Number,
  // eslint-disable-next-line
  handleFeatureKeywordChange: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  replace: Function,
  isAllInstance: Boolean,
};

const DragHandle = sortableHandle(({ intl }) => (
  <Tooltip title={intl.formatMessage(appFieldsMessages.dragAndDrop)} placement="top" mouseEnterDelay={0.3}>
    <div
      style={{ width: 20, height: 20, cursor: 'move', display: 'inline-block' }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <PauseOutlined style={{ transform: 'rotate(90deg)', fontSize: 16, cursor: 'move' }} />
    </div>
  </Tooltip>
));

const SortableList = SortableContainer(({ children }) => {
  return <>{children}</>;
});

const SortableItem = SortableElement(({ children }) => {
  return <>{children}</>;
});

class ClusterFeatureChartCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      errorMessage: null,

      featureToNidMapInfo: {},
      keyEntry: {},
      fieldFilter: '',
      fieldSearch: '',
      keywordFilter: '',
      keywordSearch: '',
      pieInfos: [],
      numericChartInfos: [],
      allPieChart: [],
    };
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.clusterActivePatternChange !== nextProps.clusterActivePatternChange ||
      this.props.activeKey !== nextProps.activeKey
    ) {
      this.reloadData(nextProps, true);
    }
  }

  @autobind
  reloadData(props, force) {
    const {
      credentials,
      activeKey,
      projectName,
      instanceName,
      startTimestamp,
      endTimestamp,
      clusterInfo,
      isAllInstance,
    } = props;

    if (activeKey === 'featureKeyword' && projectName && instanceName && clusterInfo) {
      this.setState({ isLoading: true });
      const { isAllPattern, patternId } = clusterInfo || {};
      const startTimestampList = R.map(
        (i) => startTimestamp + i * 86400000,
        R.range(0, (endTimestamp + 1 - startTimestamp) / 86400000),
      );

      const requests = [];
      R.forEach((dayTimeMillis) => {
        requests.push(
          fetchPost(getEndpoint('fetchfeaturekeyword'), {
            ...credentials,
            projectName,
            instanceName: isAllInstance ? 'All-Instances' : instanceName,
            dayTimeMillis,
            nid: isAllInstance ? undefined : isAllPattern ? null : patternId,
          }),
        );
      }, startTimestampList);
      props.updateLastActionInfo();
      Promise.all(requests)
        .then((results) => {
          // merge values from multiple days
          const featureToNidMapInfo = {};
          const mergeMap = {};
          R.forEach((data) => {
            R.forEachObjIndexed((val) => {
              R.forEach((item) => {
                // merge featureToNidMapInfo: featureLabel -> featureValue -> timestamp -> nids
                const { patternIdMap, featureLabel, featureValue, histogram, numericHistogram, numericInfo, ...rest } =
                  item;
                if (!R.has(featureLabel, featureToNidMapInfo)) {
                  featureToNidMapInfo[featureLabel] = {};
                }
                if (!R.has(featureValue, featureToNidMapInfo[featureLabel])) {
                  featureToNidMapInfo[featureLabel][featureValue] = {};
                }
                featureToNidMapInfo[featureLabel][featureValue] = {
                  ...featureToNidMapInfo[featureLabel][featureValue],
                  ...(patternIdMap || {}),
                };

                // merge multiple days data by featureLabel
                const { distribution, interval, max, min } = numericInfo || {};
                if (!R.has(featureLabel, mergeMap)) {
                  mergeMap[featureLabel] = {};
                }
                if (!R.has(featureValue, mergeMap[featureLabel])) {
                  mergeMap[featureLabel][featureValue] = {
                    featureLabel,
                    featureValue,
                    histogram,
                    numericHistogram,
                    numericInfo: item.isNumeric ? { distribution, interval, max, min } : undefined,
                    ...rest,
                  };
                } else {
                  mergeMap[featureLabel][featureValue].histogram = {
                    ...(mergeMap[featureLabel][featureValue].histogram || {}),
                    ...(histogram || {}),
                  };
                  if (item.isNumeric) {
                    mergeMap[featureLabel][featureValue].numericHistogram = R.mergeWith(
                      R.mergeRight,
                      mergeMap[featureLabel][featureValue].numericHistogram || {},
                      numericHistogram || {},
                    );
                    mergeMap[featureLabel][featureValue].numericInfo = {
                      distribution: R.mergeWith(
                        R.add,
                        distribution,
                        get(mergeMap[featureLabel][featureValue].numericInfo, 'distribution', {}),
                      ),
                      interval,
                      max: R.max(max, get(mergeMap[featureLabel][featureValue].numericInfo, 'max', 0)),
                      min: R.min(min, get(mergeMap[featureLabel][featureValue].numericInfo, 'min', 0)),
                    };
                  }
                }
              }, val);
            }, data);
          }, results);

          // build keyword map
          let keyEntry = {};
          R.forEachObjIndexed((valLabel, featureLabel) => {
            R.forEachObjIndexed((valValue) => {
              const { isNumeric } = valValue;
              const key = `${featureLabel}-${isNumeric}`;
              if (!R.has(key, keyEntry)) {
                keyEntry[key] = [];
              }
              keyEntry[key].push(valValue);
            }, valLabel);
          }, mergeMap);
          keyEntry = R.mapObjIndexed((val) => {
            const newVal = R.map((item) => {
              const { isNumeric, histogram, numericInfo } = item;
              const { distribution } = numericInfo || {};
              const count = isNumeric ? R.sum(R.values(distribution || {})) : R.sum(R.values(histogram || {}));
              return {
                ...item,
                count,
              };
            }, val);
            return newVal;
          }, keyEntry);

          // parse chart
          const { pieInfos, numericChartInfos } = this.parsePieData(keyEntry);

          this.setState({
            isLoading: false,
            errorMessage: null,
            featureToNidMapInfo,
            keyEntry,
            allPieChart: [...numericChartInfos, ...pieInfos],
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            errorMessage: err,
            featureToNidMapInfo: {},
            keyEntry: {},
            allPieChart: [],
          });
        });
    }
  }

  @autobind
  parsePieData(keyEntry) {
    const { fieldSearch, keywordSearch } = this.state;

    // build chart options
    let pieInfos = [];
    let numericChartInfos = [];
    R.forEachObjIndexed((data) => {
      const { featureLabel, name } = data[0] || {};

      let showPie = true;
      // label filter item
      if (fieldSearch) {
        if (R.toLower(featureLabel).indexOf(R.toLower(fieldSearch)) === -1) {
          showPie = false;
        }
      }
      if (keywordSearch) {
        showPie = false;
        data = R.map((item) => {
          if (R.toLower(item.featureValue).indexOf(R.toLower(keywordSearch)) >= 0) {
            if (!showPie) showPie = true;
            return item;
          }
          return { ...item, hide: true };
        }, data);
      }

      if (showPie) {
        // sepicel number value
        let isNumber = false;
        R.forEach((item) => {
          isNumber = isNumber || item.isNumeric;
        }, data);

        if (isNumber) {
          R.forEach((item) => {
            const { numericInfo } = item;
            const { max, min } = numericInfo;
            const option = LogRenderers.GetNumericChartOption(featureLabel, item);
            numericChartInfos.push({
              featureLabel,
              option,
              max,
              min,
              name: name && !R.isEmpty(name) ? name : undefined,
              type: 'numberIc',
            });
          }, data);
        } else {
          const option = LogRenderers.GetPieOption(featureLabel, data);
          pieInfos.push({
            featureLabel,
            option,
            name: name && !R.isEmpty(name) ? name : undefined,
            type: 'pieInfo',
          });
        }
      }
    }, keyEntry);

    pieInfos = R.sortWith([R.ascend(R.prop('featureLabel'))], pieInfos);
    numericChartInfos = R.sortWith([R.ascend(R.prop('featureLabel'))], numericChartInfos);
    return { pieInfos, numericChartInfos };
  }

  @autobind
  handleFieldSearchChange(fieldSearch) {
    this.setState({ fieldSearch }, () => {
      const { keyEntry } = this.state;
      // parse chart
      const { pieInfos, numericChartInfos } = this.parsePieData(keyEntry);
      this.setState({ allPieChart: [...numericChartInfos, ...pieInfos] });
    });
  }

  @autobind
  handleKeywordSearchChange(keywordSearch) {
    this.setState({ keywordSearch }, () => {
      const { keyEntry } = this.state;
      // parse chart
      const { pieInfos, numericChartInfos } = this.parsePieData(keyEntry);
      this.setState({ allPieChart: [...numericChartInfos, ...pieInfos] });
    });
  }

  @autobind
  onChartClick() {
    return (item, pie) => {
      if (pie.dispatchAction) {
        const { seriesIndex, dataIndex } = item;
        pie.dispatchAction({ type: 'downplay' });
        pie.dispatchAction({ type: 'highlight', seriesIndex, dataIndex });
      }
      const { handleFeatureKeywordChange } = this.props;
      if (isFunction(handleFeatureKeywordChange)) {
        const { featureToNidMapInfo, keyEntry } = this.state;
        const { data } = item;
        const { isJson, isNumeric, name, interval, featureLabel, featureValue } = data;
        const key = `${featureLabel}-${isNumeric || false}`;
        const valList = get(keyEntry, key, []);
        const valObj = R.find((item) => item.featureValue === featureValue, valList);
        const featureFreqVector = isNumeric
          ? get(valObj, ['numericHistogram', name], {})
          : get(valObj, 'histogram', {});
        handleFeatureKeywordChange({
          featureFreqVector,
          featureToNidMapInfo,
          featureInfo: {
            featureIsJson: isJson,
            featureLabel,
            featureValue,
            featureIsNumber: isNumeric,
            featureInterval: interval,
            featurePosition: name,
          },
        });
      }
    };
  }

  @autobind
  onSortEnd({ oldIndex, newIndex, collection, isKeySorting }) {
    const { allPieChart } = this.state;
    this.setState({
      allPieChart: R.move(oldIndex, newIndex, R.clone(allPieChart)),
    });
  }

  @autobind
  renderNumericChartInfos(pie, width) {
    return (
      <Card
        size="small"
        title={
          <div className="flex-row flex-center-align">
            <Tooltip title={pie.featureLabel} placement="top" mouseEnterDelay={0.3}>
              <span className="max-width hidden-line-with-ellipsis inline-block font-14 bold">
                {pie.name || pie.featureLabel}
              </span>
            </Tooltip>
          </div>
        }
        style={{ width: '100%', cursor: 'default' }}
        hoverable
        extra={<DragHandle intl={this.props.intl} />}
      >
        <div>Max: {numeral(pie.max).format(pie.max > 1 ? '0,0' : '0.[000000]')}</div>
        <div>Min: {numeral(pie.min).format(pie.min > 1 ? '0,0' : '0.[000000]')}</div>
        <EChart height={180} option={pie.option} onClick={this.onChartClick(pie.featureWord)} />
      </Card>
    );
  }

  @autobind
  renderPieInfos(pie, width) {
    return (
      <Card
        size="small"
        title={
          <div className="flex-row flex-center-align">
            <Tooltip title={pie.featureLabel} placement="top" mouseEnterDelay={0.3}>
              <span className="max-width hidden-line-with-ellipsis inline-block font-14 bold">
                {pie.name || pie.featureLabel}
              </span>
            </Tooltip>
          </div>
        }
        style={{ width: '100%', cursor: 'default' }}
        hoverable
        extra={<DragHandle intl={this.props.intl} />}
      >
        <div style={{ height: 18 * 2 }} />
        <EChart height={180} option={pie.option} onClick={this.onChartClick(pie.featureWord)} />
      </Card>
    );
  }

  @autobind
  renderListItem({ allPieChart, width, height }) {
    const cardWidth = floor((width - 17) / 3);

    return (
      <div className="flex-row flex-wrap" style={{ width, height }}>
        {R.addIndex(R.map)((item, index) => {
          const { type } = item;
          const isNumberIc = type === 'numberIc';
          return (
            <SortableItem key={`pirChart${index}`} index={index}>
              <div style={{ width: cardWidth - 16, maxWidth: cardWidth - 16, minWidth: 200, margin: 8, height: 280 }}>
                {isNumberIc && this.renderNumericChartInfos(item, width)}
                {!isNumberIc && this.renderPieInfos(item, width)}
              </div>
            </SortableItem>
          );
        }, allPieChart)}
      </div>
    );
  }

  render() {
    const { isLoading, errorMessage, fieldFilter, keywordFilter, allPieChart } = this.state;
    return (
      <Container className="full-width full-height flex-col">
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          {errorMessage && (
            <div className="full-width full-height" style={{ padding: 16 }}>
              <Alert type="error" message={String(errorMessage)} showIcon />
            </div>
          )}

          {!errorMessage && (
            <div className="full-width full-height flex-col">
              <div className="flex-row" style={{ padding: '0 8px 8px 8px' }}>
                <Input.Search
                  size="small"
                  placeholder="Field search"
                  style={{ width: 200 }}
                  value={fieldFilter}
                  allowClear
                  onSearch={(value) => this.handleFieldSearchChange(value)}
                  onChange={(e) => this.setState({ fieldFilter: e.target.value })}
                />
                <Input.Search
                  size="small"
                  placeholder="Value search"
                  style={{ width: 200, marginLeft: 16, marginTop: 0 }}
                  value={keywordFilter}
                  allowClear
                  onSearch={(value) => this.handleKeywordSearchChange(value)}
                  onChange={(e) => this.setState({ keywordFilter: e.target.value })}
                />
              </div>
              {R.isEmpty(allPieChart) && (
                <div className="flex-row flex-center-align flex-center-jutify full-height full-width">
                  <Empty style={{ width: '100%' }} />
                </div>
              )}

              {!R.isEmpty(allPieChart) && (
                <div className="flex-row flex-min-height overflow-y-auto overflow-x-hidden full-width full-height">
                  <AutoSizer>
                    {({ width, height }) => (
                      <SortableList
                        axis="xy"
                        useDragHandle
                        helperClass="sortableHelperAntModal"
                        onSortEnd={(params) => this.onSortEnd(params)}
                      >
                        {this.renderListItem({ allPieChart, width, height })}
                      </SortableList>
                    )}
                  </AutoSizer>
                </div>
              )}
            </div>
          )}
        </Spin>
      </Container>
    );
  }
}

const ClusterFeatureChart = injectIntl(ClusterFeatureChartCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects } = state.app;
    const { credentials } = state.auth;

    return {
      location,
      loadStatus,
      projects,
      credentials,
    };
  },
  { updateLastActionInfo, replace },
)(ClusterFeatureChart);
