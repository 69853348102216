import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { isObject } from 'lodash';
import { Button, message, Popconfirm, Spin } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Modal } from '../../../lib/fui/react';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';

import RecommendationContentModal from './RecommendationContentModal';

import { eventMessages } from '../../../common/metric/messages';
import { appButtonsMessages, appMessages } from '../../../common/app/messages';
import fetchPost from '../../../common/apis/fetchPost';

export default function RecommendationModal({
  onClose,
  intl,
  activeIncidentKey,
  addRecommendationActive,
  rootCauseLevel,
  incidentList,
  credentials,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    showAddRecommendation: false,
    isLoading: false,
    recommendationList: [],
    activeEvent: '',
    ruleInfo: '[]',
  });
  const { showAddRecommendation, isLoading, recommendationList, activeEvent, ruleInfo } = state;

  const getRecommendation = () => {
    setState({ isLoading: true });
    const { incidentRule, ruleType } = addRecommendationActive;
    const { rulePrimaryKey } = incidentRule;
    const { uniqueKey, rulePartitionKey } = rulePrimaryKey;
    const { projectName, userName, instanceName, patternId } = rulePartitionKey;
    const rulekey = { projectName, userName, instanceName, patternId, ruleType, uniqueKey };
    const ruleInfo = JSON.stringify([rulekey]);
    fetchGet(getEndpoint('getrecommendations'), {
      ...credentials,
      predictionRuleInfo: ruleInfo,
    })
      .then((data) => {
        const { success, ...recommendationObj } = data;
        if (success || (success === undefined && isObject(recommendationObj))) {
          const recommendations = R.uniq(R.flatten(R.values(recommendationObj)) || []);
          const recommendationList = R.map((item) => ({ recommendation: item }), recommendations || []);
          setState({
            recommendationList,
            ruleInfo,
          });
        }
        setState({
          isLoading: false,
          ruleInfo,
        });
      })
      .catch((e) => {
        setState({ isLoading: false });
        console.log(String(e));
      });
  };

  useEffect(() => {
    getRecommendation();
  }, []);

  const handleAddRecommendationList = () => {
    setState({ showAddRecommendation: true, activeEvent: '' });
  };

  const handleRecommendationRemove = (recommendation) => {
    setState({ isLoading: true });
    let mapRecommendationList = R.map((item) => item.recommendation, recommendationList || []);
    mapRecommendationList = R.filter((item) => item !== recommendation, mapRecommendationList);
    fetchPost(getEndpoint('getrecommendations'), {
      ...credentials,
      predictionRuleInfo: ruleInfo,
      suggestions: JSON.stringify(mapRecommendationList),
    })
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          message.success(msg);
          getRecommendation();
        } else {
          setState({ isLoading: false });
        }
      })
      .catch((e) => {
        setState({ isLoading: false });
      });
  };

  const handleRecommendationEdit = (recommendation) => {
    setState({ showAddRecommendation: true, activeEvent: recommendation });
  };

  return (
    <>
      <Modal
        title={intl.formatMessage(eventMessages.recommendationList)}
        width={750}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={() => onClose()}
        bodyStyle={{ height: 500 }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-height spin-full-height">
          <div className="flex-row flex-center-align" style={{ margin: '8px 0' }}>
            <Button size="small" type="primary" style={{ width: 120 }} onClick={handleAddRecommendationList}>
              {intl.formatMessage(appButtonsMessages.add)}
            </Button>
          </div>

          <div className="event-list flex-grow flex-col flex-min-height">
            <div className="event-list-header" style={{ height: 40, width: '100%' }}>
              <div className="header-column" style={{ width: 140, flex: 1 }}>
                {intl.formatMessage(eventMessages.recommendation)}
              </div>
              <div className="header-column" style={{ width: 180 }} />
            </div>
            {recommendationList.length > 0 && (
              <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                {R.addIndex(R.map)((rowData, index) => {
                  const { recommendation } = rowData;
                  return (
                    <div
                      key={index}
                      className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                      style={{ minHeight: 40 }}
                    >
                      <div className="row-column" style={{ width: 120, flex: 1, wordBreak: 'break-all' }}>
                        {recommendation}
                      </div>
                      <div className="row-column" style={{ width: 180 }}>
                        <Button size="small" type="primary" onClick={() => handleRecommendationEdit(recommendation)}>
                          <EditOutlined /> {intl.formatMessage(appButtonsMessages.edit)}
                        </Button>
                        <Popconfirm
                          placement="topRight"
                          title={intl.formatMessage(appMessages.continueConfirm)}
                          onConfirm={() => handleRecommendationRemove(recommendation)}
                          onCancel={(event) => event.stopPropagation()}
                        >
                          <Button
                            size="small"
                            className="button-color-grey"
                            style={{ marginLeft: 8 }}
                            onClick={(event) => event.stopPropagation()}
                          >
                            <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  );
                }, recommendationList)}
              </div>
            )}
          </div>
        </Spin>
      </Modal>
      {showAddRecommendation && (
        <RecommendationContentModal
          intl={intl}
          credentials={credentials}
          rootCauseLevel={rootCauseLevel}
          addRecommendationActive={addRecommendationActive}
          getRecommendation={getRecommendation}
          activeEvent={activeEvent}
          recommendationList={recommendationList}
          onClose={() => {
            setState({ showAddRecommendation: false });
          }}
        />
      )}
    </>
  );
}
