import React, { useEffect, useReducer, useState } from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { get, isNumber } from 'lodash';
import { Button, message, Select, Spin, Tabs, Tag } from 'antd';

import { MessageOutlined } from '@ant-design/icons';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { Popover } from '../../../lib/fui/react';
import { Defaults, GlobalRenderers, parseLocation } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';

import { logMessages } from '../../../common/log/messages';
import { appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';

import RootCauseTimelineRCAList from './RootCauseTimelineRCAList';
import { GoodIcon, GoodOutlinedIcon } from '../../../lib/fui/icons';

const relevanceOptions = [
  { label: 'Unspecified', value: 'none', color: 'currentColor' },
  { label: 'High', value: 'high', color: 'red' },
  { label: 'Medium', value: 'medium', color: 'orange' },
  { label: 'Low', value: 'low', color: 'gray' },
];
const relevanceColorMap = R.fromPairs(R.map((item) => [item.value, item.color], relevanceOptions));

const actionType = {
  low: 'Low',
  high: 'High',
  none: 'Unspecified',
};

const actionColor = {
  low: 'var(--yellow)',
  high: 'var(--green)',
  none: 'currentColor',
};

const getHighlightNode = (activeNode) => {
  let allOwnList = [];
  R.forEach((g) => {
    R.forEach((n) => {
      allOwnList = [...allOwnList, ...(n?.data?.ownList || [])];
    }, g.nodes || []);
  }, activeNode?.data?.groups || []);
  allOwnList = R.sortWith([R.ascend(R.prop('displayTimestamp'))], allOwnList);

  return {
    highlightNode: allOwnList[0] || activeNode || {},
    highlightEndNode: (allOwnList ? allOwnList[allOwnList.length - 1] : null) || activeNode || {},
  };
};

function LikelyRootCausesContentCore({
  isPT,
  edgeData,
  activeNode,
  rootCauseEvents,
  incident,
  onReload,
  handleChange,
  intl,
  timezoneOffset,
  credentials,
  updateLastActionInfo,
  handleActionsClick,
  selectEvents,
  userInfo,
  ownRank,
  summarySettingsMap = {},
  useScroll,
  isJWT,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    activeKey: '1',
    highActive: null,
    highlightNode: getHighlightNode(activeNode).highlightNode,
    highlightEndNode: getHighlightNode(activeNode).highlightEndNode,
    overallProcessId: null,
  });
  const [events, setEvents] = useState([]);
  const [aggregatedChain, setAggregatedChain] = useState({});
  const { activeKey, highActive, highlightNode, highlightEndNode, overallProcessId } = state;
  const { data, environmentId, systemId } = edgeData || {};
  const { overallProcess = [] } = data || {};

  // useEffect(() => {
  //   const { data } = edgeData || {};
  //   const { overallProcess = [] } = data || {};
  //   setState({ activeKey: overallProcess[0]?.rank });
  // }, [edgeData]);

  useEffect(() => {
    const { id } = activeNode || {};
    const aggregatedChain = { ...rootCauseEvents[0], sourceInfoList: [] };

    const events = R.map((e) => {
      const { sourceInfoList = [] } = e;
      const active = !!R.find((s) => {
        const { metricInstanceName, instanceName, eventTimestamp } = s;
        const instName = metricInstanceName || instanceName;
        const key = `${instName}__${eventTimestamp}`;
        return id === key;
      }, sourceInfoList);
      aggregatedChain.sourceInfoList = [...aggregatedChain.sourceInfoList, ...sourceInfoList];
      return { ...e, active };
    }, rootCauseEvents);
    setEvents(events);

    aggregatedChain.sourceInfoList = R.sortWith([R.ascend(R.prop('displayTimestamp'))], aggregatedChain.sourceInfoList);
    setAggregatedChain(aggregatedChain);

    const idx = R.findIndex((e) => e.overallProcessId === overallProcessId, rootCauseEvents);
    if (idx >= 0) {
      const activeKey = String(idx + 1);
      setState({ activeKey });
    }
  }, [activeNode, rootCauseEvents, overallProcessId]);

  useEffect(() => {
    const { highlightNode, highlightEndNode } = getHighlightNode(activeNode);
    setState({ highActive: null, highlightNode, highlightEndNode });
  }, [activeNode]);

  const contentRenderer = (sourceInfoList) => {
    return (
      <div className="full-width">
        {R.addIndex(R.map)((sourceInfo, idx) => {
          const { isMetric, isDeployment, sourceDetail, componentName } = sourceInfo || {};
          const { content } = sourceDetail || {};
          return (
            <div key={idx} className="flex-row flex-center-align" style={{ margin: idx !== 0 ? '2px 0' : '2px 0 0 0' }}>
              <span style={{ minWidth: 20 }}>{idx + 1}.</span>
              <span style={{ minWidth: 80 }}>
                {isMetric ? (
                  <Tag className="inline-block" color="red" style={{ lineHeight: '18px' }}>
                    {intl.formatMessage(appFieldsMessages.metric)}
                  </Tag>
                ) : isDeployment ? (
                  <Tag className="inline-block" color="orange" style={{ lineHeight: '18px' }}>
                    {intl.formatMessage(logMessages.deployment)}
                  </Tag>
                ) : (
                  <Tag className="inline-block" style={{ lineHeight: '18px' }}>
                    {intl.formatMessage(appFieldsMessages.log)}
                  </Tag>
                )}
              </span>
              <span className="light-label bold" style={{ minWidth: 80 }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </span>
              <span className="hidden-line-with-ellipsis inline-block" style={{ marginRight: 8 }}>
                {componentName}
              </span>
              {isMetric && (
                <>
                  <span className="light-label bold" style={{ minWidth: 50 }}>
                    {intl.formatMessage(appFieldsMessages.metric)}:
                  </span>
                  <span className="hidden-line-with-ellipsis inline-block" style={{ minWidth: 100 }}>
                    {content}
                  </span>
                </>
              )}
            </div>
          );
        }, sourceInfoList || [])}
      </div>
    );
  };

  const handleParentChange = ({ relevance, isLoading, idx }) => {
    const newOverallProcessData = R.addIndex(R.map)((item, index) => {
      if (index === idx) {
        return { ...item, relevance, isLoading };
      } else {
        return item;
      }
    }, overallProcess || []);
    handleChange({ edgeData: { ...edgeData, overallProcess: newOverallProcessData } });
  };

  const onChangeRelevance = ({ rowData, relevance, idx }) => {
    const { rootCauseKey, operation } = rowData;
    const {
      category,
      projectOwner,
      anomalyLogInstance,
      instanceList,
      startTimestamp,
      endTimestamp,
      patternId,
      type,
      rootCauseTableKey,
      incidentTimestamp,
    } = incident;
    let { projectName } = incident;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
    const event = {
      nid: patternId,
      eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
    };
    const oldValue = get(rowData, 'relevance');

    handleParentChange({ relevance, isLoading: true, idx });

    updateLastActionInfo();
    fetchPost(
      getEndpoint('updateRelevance', 1),
      {
        ...credentials,
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime,
        endTime,
        operation,
        rootCauseTableKey: JSON.stringify(rootCauseTableKey),
        event: JSON.stringify(event),
        rootCauseKey,
        relevance,
        incidentTimestamp,
      },
      {},
      false,
    )
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        handleParentChange({ relevance, isLoading: false, idx });
        onReload();
        setTimeout(() => {
          setState({ overallProcessId: rowData.overallProcessId });
        }, 500);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        // rollback
        handleParentChange({ relevance: oldValue, isLoading: false, idx });
      });
  };

  const relevanceRenderer = (rowData, idx) => {
    const { isLoading, relevance, reporterRecordSet } = rowData;
    return (
      <Popover
        title={intl.formatMessage(eventActionMessages.reporter)}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 350, maxHeight: 100 }}>
            {R.addIndex(R.map)((item, idx) => {
              const { timestamp, reporterName, action } = item;
              const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
              return (
                <div key={idx} className="flex-row flex-center-align">
                  <div style={{ width: 80 }}>{moment.utc(customerTimestamp).format(Defaults.ShortTimeFormat)}</div>
                  <div className="bold light-label" style={{ marginRight: 4 }}>
                    {intl.formatMessage(eventActionMessages.reporter)}:
                  </div>
                  <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                  <span style={{ marginLeft: 2 }}>{reporterName}</span>
                  <div className="bold light-label" style={{ marginLeft: 8, marginRight: 4 }}>
                    {intl.formatMessage(eventActionMessages.action)}:
                  </div>
                  <span style={{ margin: '0 4px', color: relevanceColorMap[action] }}>{action}</span>
                </div>
              );
            }, reporterRecordSet || [])}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="top"
      >
        <Spin size="small" spinning={!!isLoading} wrapperClassName="spin-full-width full-width">
          <Select
            showArrow={false}
            size="small"
            style={{ width: 150 }}
            value={relevance}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(relevance) => onChangeRelevance({ rowData, relevance, idx })}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          >
            {R.map((item) => {
              return (
                <Select.Option key={item.value}>
                  <span style={{ color: item.color }}>{item.label}</span>
                </Select.Option>
              );
            }, relevanceOptions)}
          </Select>
        </Spin>
      </Popover>
    );
  };

  const infoRender = (rowData, idx) => {
    const { probability, count, sourceInfoList, hasAction, isMetric } = rowData || {};
    return (
      <Popover
        content={
          <div style={{ maxWidth: 600, maxHeight: 400, padding: 8, overflowY: 'scroll' }}>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.probability)}
              </span>
              <span className="flex-grow hidden-line-with-ellipsis">{numeral(probability).format('0.0%')}</span>
            </div>
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.count)}
              </span>
              <span className="flex-grow hidden-line-with-ellipsis">{count}</span>
            </div>
            {isMetric && (
              <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                  {intl.formatMessage(eventMessages.slope)}
                </span>
                <span className="flex-grow hidden-line-with-ellipsis">
                  {R.addIndex(R.map)((sourceInfo, idx) => {
                    const { sourceDetail } = sourceInfo;
                    const { isMetric, slope } = sourceDetail || {};
                    if (!isMetric || !isNumber(slope)) return null;
                    return (
                      <span key={idx}>{numeral(slope).format(Math.abs(slope) >= 1 ? '0,0' : '0,0.[000000]')}</span>
                    );
                  }, sourceInfoList || [])}
                </span>
              </div>
            )}
            {/* <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appMenusMessages.patterns)}
              </span>
              <span className="flex-grow hidden-line-with-ellipsis">{contentRenderer(sourceInfoList)}</span>
            </div> */}
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.actionStatus)}
              </span>
              <span className="flex-grow hidden-line-with-ellipsis">{hasAction ? 'Action created' : '--'}</span>
            </div>
            {/* <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                {intl.formatMessage(appFieldsMessages.relevance)}
              </span>
              <span className="flex-grow hidden-line-with-ellipsis">{relevanceRenderer(rowData, idx)}</span>
            </div> */}
          </div>
        }
        placement="left"
        title={null}
        mouseEnterDelay={0.3}
      >
        <Button size="small" style={{ height: 22, marginRight: 8 }}>
          <MessageOutlined />
        </Button>
      </Popover>
    );
  };

  const relevanceRender = (rowData, idx) => {
    const { relevance, reporterRecordSet } = rowData;
    const highReporters = R.filter((item) => item.action === 'high' || item.action === 'none', reporterRecordSet || []);
    const lowReporters = R.filter((item) => item.action === 'low' || item.action === 'none', reporterRecordSet || []);

    if (isJWT) return null;
    if (activeKey === 'all') return null;

    return (
      <div style={{ marginRight: 8 }}>
        <Popover
          title={userInfo.isReadUser ? null : intl.formatMessage(eventActionMessages.reporter)}
          mouseEnterDelay={0.3}
          placement="left"
          content={
            userInfo.isReadUser ? (
              intl.formatMessage(eventMessages.isReadUserDisable)
            ) : (
              <div className="flex-col overflow-y-auto" style={{ maxWidth: 350, maxHeight: 100 }}>
                {R.addIndex(R.map)((item, idx) => {
                  const { timestamp, reporterName, action } = item;
                  const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
                  return (
                    <div key={idx} className="flex-row flex-center-align">
                      <div style={{ width: 80 }}>{moment.utc(customerTimestamp).format(Defaults.ShortTimeFormat)}</div>
                      <div className="bold light-label" style={{ marginRight: 4 }}>
                        {intl.formatMessage(eventActionMessages.reporter)}:
                      </div>
                      <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                      <span style={{ marginLeft: 2 }}>{reporterName}</span>
                      <div className="bold light-label" style={{ marginLeft: 8, marginRight: 4 }}>
                        {intl.formatMessage(eventActionMessages.action)}:
                      </div>
                      <span
                        style={{
                          margin: '0 4px',
                          color: actionColor[action],
                        }}
                      >
                        {actionType[action]}
                      </span>
                    </div>
                  );
                }, highReporters || [])}
              </div>
            )
          }
        >
          <Button
            size="small"
            style={{ marginRight: 8 }}
            disabled={userInfo.isReadUser}
            onClick={() => {
              setState({ highActive: rowData });
              onChangeRelevance({ rowData, relevance: relevance === 'high' ? 'none' : 'high', idx });
            }}
          >
            {relevance === 'high' ? <GoodIcon style={{ color: 'var(--green)' }} /> : <GoodOutlinedIcon />}
          </Button>
        </Popover>

        <Popover
          title={userInfo.isReadUser ? null : intl.formatMessage(eventActionMessages.reporter)}
          mouseEnterDelay={0.3}
          placement="left"
          content={
            userInfo.isReadUser ? (
              intl.formatMessage(eventMessages.isReadUserDisable)
            ) : (
              <div className="flex-col overflow-y-auto" style={{ maxWidth: 350, maxHeight: 100 }}>
                {R.addIndex(R.map)((item, idx) => {
                  const { timestamp, reporterName, action } = item;
                  const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
                  return (
                    <div key={idx} className="flex-row flex-center-align">
                      <div style={{ width: 80 }}>{moment.utc(customerTimestamp).format(Defaults.ShortTimeFormat)}</div>
                      <div className="bold light-label" style={{ marginRight: 4 }}>
                        {intl.formatMessage(eventActionMessages.reporter)}:
                      </div>
                      <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                      <span style={{ marginLeft: 2 }}>{reporterName}</span>
                      <div className="bold light-label" style={{ marginLeft: 8, marginRight: 4 }}>
                        {intl.formatMessage(eventActionMessages.action)}:
                      </div>
                      <span
                        style={{
                          margin: '0 4px',
                          color: actionColor[action],
                        }}
                      >
                        {actionType[action]}
                      </span>
                    </div>
                  );
                }, lowReporters || [])}
              </div>
            )
          }
        >
          <Button
            size="small"
            disabled={userInfo.isReadUser}
            onClick={() => {
              setState({ highActive: rowData });
              onChangeRelevance({ rowData, relevance: relevance === 'low' ? 'none' : 'low', idx });
            }}
          >
            {relevance === 'low' ? (
              <GoodIcon style={{ color: 'var(--yellow)', transform: 'rotateX(180deg)' }} />
            ) : (
              <GoodOutlinedIcon style={{ transform: 'rotateX(180deg)' }} />
            )}
          </Button>
        </Popover>
      </div>
    );
  };

  return (
    <Tabs
      type="card"
      activeKey={activeKey}
      destroyInactiveTabPane
      onChange={(activeKey) => {
        setState({ overallProcessId: null, activeKey, highActive: null });
        selectEvents(activeKey === 'all' ? null : [events[activeKey - 1]]);
      }}
      className="ant-tabs-content-full-height flex-col full-width flex-grow flex-min-height"
      tabBarExtraContent={
        <>
          {events.length > 0 && (
            <div
              className={`ant-tabs-tab ${activeKey === 'all' ? 'ant-tabs-tab-active' : ''}`}
              onClick={() => {
                setState({ overallProcessId: null, activeKey: 'all' });
                selectEvents(null);
              }}
            >
              Aggregated causal chains
            </div>
          )}
        </>
      }
    >
      {R.addIndex(R.map)((rowData, idx) => {
        const rank = idx + 1;
        const { active, rank: rowDataRank } = rowData || {};
        return (
          <Tabs.TabPane
            key={String(rank)}
            tab={
              <div className="flex-row flex-center-align">
                {active && (
                  <div
                    style={{
                      background: 'var(--react-flow-node-selected-bg)',
                      width: 12,
                      height: 12,
                      marginRight: 6,
                    }}
                  />
                )}
                <span>{`${intl.formatMessage(logMessages.rankNumber)}`}</span>
                <span style={{ marginLeft: 4 }}>{ownRank ? rowDataRank : rank} causal chain</span>
                {rank === activeKey && <span style={{ marginLeft: 4 }}>{isPT ? 'trailing event' : 'root cause'}</span>}
              </div>
            }
            style={{
              borderRadius: '0 0 8px 8px',
              border: '1px solid var(--virtualized-table-border-color)',
              borderTop: 'none',
            }}
          >
            <div className="flex-col full-width full-height">
              <div className="flex-col full-height" style={{ padding: 8 }}>
                <RootCauseTimelineRCAList
                  incident={incident}
                  selectChain={rowData}
                  environmentId={environmentId}
                  highlightTimestamp={highlightNode?.displayTimestamp || highlightNode?.time}
                  highlightEndTimestamp={highlightEndNode?.displayTimestamp || highlightEndNode?.time}
                  systemId={systemId}
                  summarySettingsMap={summarySettingsMap}
                  infoRender={() => infoRender(rowData, idx)}
                  relevanceRender={() => relevanceRender(rowData, idx)}
                  useScroll
                  isJWT={isJWT}
                />
              </div>
            </div>
          </Tabs.TabPane>
        );
      }, events)}
      <Tabs.TabPane
        key="all"
        className="fsfd"
        tab={
          <div
            className="flex-row flex-center-align force-hidden"
            style={{
              position: 'absolute',
              top: -1,
              left: -1,
              width: 36,
              height: 42,
              background: 'var(--content-background)',
              borderBottom: '2px solid var(--border-color-split)',
            }}
          />
        }
        style={{
          border: '1px solid var(--virtualized-table-border-color)',
          borderTop: 'none',
        }}
      >
        <div className="flex-col full-width full-height">
          <div className="flex-col full-height" style={{ padding: 8 }}>
            <RootCauseTimelineRCAList
              incident={incident}
              selectChain={aggregatedChain}
              environmentId={environmentId}
              highlightTimestamp={highlightNode?.displayTimestamp || highlightNode?.time}
              highlightEndTimestamp={highlightEndNode?.displayTimestamp || highlightEndNode?.time}
              systemId={systemId}
              summarySettingsMap={summarySettingsMap}
              infoRender={() => infoRender(aggregatedChain, 0)}
              relevanceRender={() => relevanceRender(aggregatedChain, 0)}
              useScroll
              isJWT={isJWT}
            />
          </div>
        </div>
      </Tabs.TabPane>
    </Tabs>
  );
}

const LikelyRootCausesContent = injectIntl(LikelyRootCausesContentCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { timezoneOffset } = state.app;
    const { userInfo } = state.auth;

    const { jwtToken, customerName } = parseLocation(location);
    let credentials = {};
    if (jwtToken) {
      credentials = { userName: customerName };
    } else {
      credentials = state.auth?.credentials;
    }

    return { timezoneOffset, credentials, userInfo: userInfo || {} };
  },
  { updateLastActionInfo },
)(LikelyRootCausesContent);
