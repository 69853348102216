import moment from 'moment';
import { get } from 'lodash';
import { createLogic } from 'redux-logic';

import { ActionTypes, logoff } from '../actions';
import { postUserToken } from '../../apis';
import { createSetAction } from '../../app/actions';
import { Defaults, parseJSON } from '../../utils';

const loadAuthInfoLogic = createLogic({
  type: [ActionTypes.UPDATE_USER_AUTH_INFO],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params } = action.payload;
    const { credentials } = state.auth;
    const { needRefreshToken } = params;

    // update token if needed
    const currtentExpiredTimestamp = moment
      .utc()
      .add(3, 'minutes')
      .valueOf();
    // Get tokenExpiredTime in localStorage
    const tokenExpiredTime = window.localStorage.getItem('tokenExpiredTime');
    // Get lastActionTime in localStorage
    const lastActionInfo = window.localStorage.getItem('lastActionInfo');
    const { lastActionTime } = parseJSON(lastActionInfo);

    // check if token already expired
    if (!tokenExpiredTime || moment.utc().valueOf() > Number(tokenExpiredTime)) {
      // log off
      window.alert("User's token expired, please login again.");
      const { pathname, search } = window.location;
      const loginRedirectURL = pathname + search;
      dispatch(logoff(credentials, loginRedirectURL));
    }
    // check if the token will expired
    else if (needRefreshToken && tokenExpiredTime && currtentExpiredTimestamp >= tokenExpiredTime) {
      // if last api call is more than 15mins ago, then not refresh the token
      if (lastActionTime >= moment.utc().valueOf() - 15 * 60 * 1000) {
        postUserToken(credentials)
          .then((data) => {
            if (data) {
              dispatch(createSetAction(ActionTypes.SET_USER_AUTH_INFO, params, data));
              console.info('[IF_API] Successed refresh user token');
            } else {
              console.error('[IF_API] Failed refresh user token');
            }
          })
          .catch((err) => {
            // Ignore the error, and use empty environment list
            console.error('[IF_API] Failed refresh user token, ignored', err);
          })
          .then(() => {
            done();
          });
      } else {
        console.info('[IF_API] User is inactive, will not refresh the token');
      }
    }

    // console.debug(
    //   `Last Action Time: ${moment
    //     .utc(lastActionTime)
    //     .format(Defaults.ShortTimeOnlyFormat)}, Token Expired Time: ${moment
    //     .utc(tokenExpiredTime)
    //     .format(Defaults.ShortTimeOnlyFormat)}`,
    // );
  },
});

export default loadAuthInfoLogic;
