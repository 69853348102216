/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import VLink from 'valuelink';
import update from 'immutability-helper';
import { autobind } from 'core-decorators';
import { get, isArray, isFinite } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FileAddOutlined } from '@ant-design/icons';
import { Select as AntdSelect, Button, InputNumber, Switch, Spin } from 'antd';

import { ifIn, Defaults, parseJSON } from '../../../common/utils';
import { Modal, Select, Input, Container, Tooltip } from '../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';
import { eventMessages } from '../../../common/metric/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { logMessages } from '../../../common/log/messages';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import fetchPost from '../../../common/apis/fetchPost';

import EditPairingDurationModal from './EditPairingDurationModal';

type Props = {
  // eslint-disable-next-line
  loadProjectInfo: Function,
  causalGroupList: Array<Object>,
  createCausalGroup: Function,
  onClose: Function,

  intl: Object,
  loaderStatus: Object,
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  projects: Array<Object>,
  // eslint-disable-next-line
  userInfo: Object,
  credentials: Object,
  errorMessage: ?Object,
  userList: Array<Object>,
  defaultCausalMetricPairingThreshold: Number,
};

const MAX_FATCH_NUM = 30;

class CreateGroupModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.submitLoader = 'causal_create_group_submit';
    this.paramsLoader = 'causal_create_group_params';
    this.sumbitting = false;

    this.state = {
      isLoading: false,
      selectSystem: null,

      causalName: '',
      cronInterval: Defaults.CausalCronInterval,
      correlationInterval: 1,
      retentionTime: 31,
      correlationUnit: 60 * 1000,
      enableInterRelation: false,
      enableComponentIntra: false,
      enableIncidentOnly: false,
      includeTargetAnomalyInPossibility: false,
      selectProjects: [],
      enableCorrelation: false,
      eventsRelationLookBackWindow: '',
      userNameValue: '',
      systemListData: [],
      isGetLoading: false,
      userCompany: '',
      showEditPairingDurationModal: false,
      activeEvent: null,
    };
    this.intervalUnitOptions = [
      { value: 60 * 1000, label: 'Mins' },
      { value: 60 * 60 * 1000, label: 'Hours' },
    ];
    this.projectList = [];
    this.systemProjectMap = {};
    this.metricOptions = [
      { label: 'Incident', value: 'Incident' },
      { label: 'Alert', value: 'MetricAlert' },
      { label: 'Metric Anomaly', value: 'MetricAnomaly' },
    ];
    this.logOptions = [
      { label: 'Incident', value: 'Incident' },
      { label: 'Alert', value: 'alert' },
      { label: 'Hot event', value: 'hot' },
      { label: 'Cold event', value: 'cold' },
      { label: 'New Pattern', value: 'newPattern' },
      { label: intl.formatMessage(logMessages.keywordAlerts), value: 'whiteList' },
      { label: 'Rare Event', value: 'rare' },
      { label: 'Change', value: 'change' },
      { label: 'Normal', value: 'normal' },
    ];
  }

  componentDidMount() {
    this.reloadUserData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLoadingComponents, errorMessage } = nextProps;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      if (!errorMessage) {
        this.props.onClose(true);
      }
    }
  }

  @autobind
  async reloadUserData() {
    const { userList, userInfo, projects, systemsMap } = this.props;
    if (userInfo.isAdmin || userInfo.isLocalAdmin) {
      const reloadUser = (userList ?? [])[0];
      if (reloadUser) {
        const { company } = reloadUser;
        this.setState({ userNameValue: reloadUser.userName, userCompany: company }, () => {
          this.getSystemList(reloadUser);
        });
      }
    } else {
      let systemListData = R.sortWith([R.ascend(R.prop('systemName'))], R.values(systemsMap) || {});
      systemListData = R.filter((s) => s.owner === userInfo.userName, systemListData);
      const systemProjectMap = {};
      R.forEach((system) => {
        systemProjectMap[system.systemId] = R.map((item) => {
          return userInfo.userName !== item.customerName
            ? `${item.projectName}@${item.customerName}`
            : item.projectName;
        }, system.projectDetailsList);
      }, systemListData);
      this.systemProjectMap = systemProjectMap;

      let projectList = R.sort((a, b) => (a.projectName || '').localeCompare(b.projectName), projects);
      projectList = R.filter((p) => p.owner === userInfo.userName, projectList);
      projectList = R.filter((project) => project.ps !== 'Deleting' && project.ps !== 'In trash', projectList);
      projectList = R.filter((item) => item.cloudType !== 'LogFrequency', projectList || []);
      this.projectList = projectList;
      this.setState({ systemListData, selectSystem: systemListData[0]?.systemId });
    }
  }

  @autobind
  getSystemList(userNameList) {
    this.setState({ isGetLoading: true });
    const { credentials, userInfo } = this.props;
    const { userName } = userNameList;
    fetchGet(getEndpoint('systemframework', 2), {
      ...credentials,
      customerName: userName,
      loadSameCompany: true,
    })
      .then(async (results) => {
        const { success } = results;
        if (success === false) {
          this.setState({ isGetLoading: false });
          return;
        }
        let { ownSystemArr, sameCompanySystemArray } = results || {};

        ownSystemArr = R.map((item) => {
          const system = parseJSON(item) || {};
          return { ...system };
        }, ownSystemArr || []);
        sameCompanySystemArray = R.map((item) => {
          const system = parseJSON(item) || {};
          return { ...system };
        }, sameCompanySystemArray || []);

        const systemsMap = {};
        const projectSystemKeyMap = {};
        let allProjects = [];
        R.forEach(
          (system) => {
            const { systemDisplayName, systemKey, projectDetailsList, ...rest } = system;
            const { environmentName, systemName: systemId, userName } = systemKey || {};
            let newProjects = parseJSON(projectDetailsList) || [];
            newProjects = R.map((item) => {
              const { userName, projectClassType, ...restItem } = item;
              return {
                ...restItem,
                customerName: userName,
                projectType: projectClassType,
              };
            }, newProjects);

            systemsMap[systemId] = {
              ...rest,
              environmentName,
              systemId,
              systemName: systemDisplayName || systemId,
              owner: userName,
              projectDetailsList: newProjects,
            };
            R.forEach((item) => {
              const { projectName, customerName } = item;
              const projectFullName = `${projectName}@${customerName}`;
              projectSystemKeyMap[projectFullName] = systemId;
            }, newProjects);

            allProjects = [...allProjects, ...newProjects];
          },
          // [...ownSystemArr, ...sameCompanySystemArray],
          [...ownSystemArr],
        );

        const projectList = R.map((item) => {
          const { customerName, projectName, projectType } = item;
          return { customerName, projectName, projectType };
        }, allProjects);
        const metaSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, projectList || []);
        const metaRequests = [];
        R.forEach((group) => {
          if (group.length > 0) {
            metaRequests.push(
              fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
                ...credentials,
                projectList: JSON.stringify(group),
              }),
            );
          }
        }, metaSplitEveryTakes || []);
        await Promise.all(metaRequests)
          .then((res) => {
            let groupMetaProject = [];
            R.forEach((data) => {
              groupMetaProject = [...groupMetaProject, ...get(data, 'data', [])];
            }, res || []);
            R.forEach((item) => {
              const findInfo = R.find((_item) => _item.projectKey === item.projectKey, groupMetaProject);
              if (findInfo) {
                item.projectDisplayName = findInfo.projectDisplayName;
                item.cloudType = findInfo.cloudType;
                item.projectKey = findInfo.projectKey;
              }
            }, allProjects);
          })
          .catch((err) => {
            console.error('[IF] Failed to get project stats', err);
            return {};
          });

        const uniqProjects = {};
        R.forEach((project) => {
          const { projectName } = project;
          if (!R.has(projectName, uniqProjects)) {
            uniqProjects[projectName] = project;
          }
        }, allProjects);

        let projectStats = {};
        const statusSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, R.values(uniqProjects) || []);
        const statusRequests = [];
        R.forEach((group) => {
          if (group.length > 0) {
            statusRequests.push(
              fetchPost(getEndpoint('getprojectstatus'), {
                ...credentials,
                projectList: JSON.stringify(group),
              }),
            );
          }
        }, statusSplitEveryTakes || []);
        await Promise.all(statusRequests)
          .then((res) => {
            let groupProjectStats = {};
            R.forEach((data) => {
              groupProjectStats = { ...groupProjectStats, ...get(data, 'data', {}) };
            }, res || []);
            projectStats = R.mapObjIndexed((item) => {
              return { ...item, projectStatus: parseJSON(item.projectStatus) };
            }, groupProjectStats);
          })
          .catch((err) => {
            console.error('[IF] Failed to get project stats', err);
            return {};
          });

        let projects = R.map((project) => {
          const { customerName, dataType, projectName, projectType } = project;
          let newProjectName = projectName;
          if (customerName !== credentials.userName) {
            newProjectName = `${projectName}@${customerName}`;
          }

          const isMetric = dataType && dataType.toLowerCase() === 'metric';
          const isLog = dataType && ['log', 'trace'].includes(dataType.toLowerCase());
          const isTrace = dataType && dataType.toLowerCase() === 'trace';
          const isDeployment = dataType && dataType.toLowerCase() === 'deployment';
          const isAlert = dataType && dataType.toLowerCase() === 'alert';
          const isIncident = dataType && dataType.toLowerCase() === 'incident';

          // systemInfo
          const projectFullName = `${projectName}@${customerName}`;
          const systemKey = projectSystemKeyMap[projectFullName];
          const { environmentName, systemId, systemName, timezone } = get(systemsMap, [systemKey], {});

          const { ps } = projectStats[project.projectKey]?.projectStatus || {};

          return {
            ...project,
            // system info
            systemKey,
            environmentName,
            systemId,
            systemName,
            timezone,

            // project meta info
            hasAllInfo: false,
            hasAllInstanceInfo: false,
            projectName: newProjectName,
            projectShortName: projectName,
            projectType: projectType.toLowerCase() === 'custom' ? 'Agent' : projectType,
            projectTypeRaw: projectType,
            dataType,
            owner: customerName,

            // project type
            isMetric,
            isLog,
            isTrace,
            isDeployment,
            isAlert,
            isIncident,

            ps,
          };
        }, R.values(uniqProjects));
        projects = R.sort((a, b) => (a.projectName || '').localeCompare(b.projectName), projects);
        projects = R.filter((project) => project.ps !== 'Deleting' && project.ps !== 'In trash', projects);

        projects = R.filter((item) => item.cloudType !== 'LogFrequency', projects || []);

        const systemListData = R.sortWith([R.ascend(R.prop('systemName'))], R.values(systemsMap) || {});
        const systemProjectMap = {};
        R.forEach((system) => {
          systemProjectMap[system.systemId] = R.map((item) => {
            return userInfo.userName !== item.customerName
              ? `${item.projectName}@${item.customerName}`
              : item.projectName;
          }, system.projectDetailsList);
        }, systemListData);

        this.systemProjectMap = systemProjectMap;
        this.projectList = projects;
        this.setState({ systemListData, selectSystem: systemListData[0]?.systemId, isGetLoading: false });
      })
      .catch((err) => {
        this.setState({ isGetLoading: false });
        console.error(String(err));
      });
  }

  @autobind
  handleOnClose() {
    this.props.onClose(false);
  }

  @autobind
  handleSumbit() {
    const { createCausalGroup, userInfo, credentials } = this.props;
    const {
      causalName,
      cronInterval,
      correlationInterval,
      correlationUnit,
      retentionTime,
      enableInterRelation,
      enableComponentIntra,
      enableIncidentOnly,
      includeTargetAnomalyInPossibility,
      selectProjects,
      enableCorrelation,
      eventsRelationLookBackWindow,
      userNameValue,
    } = this.state;

    const projectNames = R.map(
      (c) => (c.projectName.includes('@') ? c.projectName : `${c.projectName}@${credentials.userName}`),
      selectProjects,
    );
    const groupings = R.map((c) => 'All', selectProjects);
    const projectLevelSetting = R.map((c) => {
      return {
        projectName: c.projectName.includes('@') ? c.projectName.split('@')[0] : c.projectName,
        durationThreshold: c.durationThreshold || 0,
        considerAnomalyAfterIncident: c.considerAnomalyAfterIncident,
        enableLastComponentCausalValidation: c.enableLastComponentCausalValidation,
        anomalyTypeForSrc:
          isArray(c.anomalyTypeForSrc) && c.anomalyTypeForSrc?.length > 0 ? c.anomalyTypeForSrc : undefined,
        ...(c.type === 'Metric'
          ? {
              enableIncidentDurationLimitation: c.enableIncidentDurationLimitation,
              metricDuration: c.metricDuration * 60 * 1000,
              metricLevelValidPairingSet: R.map(
                (item) => ({ m: item.metricName, d: item.duration }),
                c.metricLevelValidPairingSet || [],
              ),
            }
          : {}),
      };
    }, selectProjects);
    const validateProjectList = R.filter((c) => {
      return c.enableLastComponentCausalValidation;
    }, selectProjects);
    const validateProjectNameList = R.map((c) => {
      return c.projectName.includes('@') ? c.projectName.split('@')[0] : c.projectName;
    }, validateProjectList);
    this.sumbitting = true;
    createCausalGroup(
      {
        causalName: causalName.trim(),
        intervalInDay: cronInterval,
        correlationInterval: correlationInterval * correlationUnit,
        retentionTime: retentionTime * 24 * 60 * 60 * 1000 || '',
        enableInterRelation,
        enableComponentIntra,
        enableIncidentOnly,
        includeTargetAnomalyInPossibility,
        projectNameArr: JSON.stringify(projectNames),
        grouping: JSON.stringify(groupings),
        projectLevelSetting: JSON.stringify(projectLevelSetting),
        enableCorrelation,
        eventsRelationLookBackWindow: eventsRelationLookBackWindow * 24 * 60 * 60 * 1000,
        owner: userNameValue || userInfo.userName,
        validateProjectList: JSON.stringify(validateProjectNameList),
      },
      { [this.submitLoader]: true },
    );
  }

  @autobind
  handleSystemAddClick() {
    const { selectSystem, selectProjects } = this.state;
    const systemProjectNames = this.systemProjectMap[selectSystem];

    let newProjectList = R.clone(selectProjects || []);
    newProjectList = R.filter((item) => Boolean(item.projectName), newProjectList);
    const newProjectNames = R.map((item) => item.projectName, newProjectList);

    const needAddProjects = R.filter(
      (project) =>
        systemProjectNames.includes(project.projectName) && newProjectNames.indexOf(project.projectName) === -1,
      this.projectList,
    );

    R.forEach((item) => {
      newProjectList.push({
        ...item,
        projectName: item.projectName,
        durationThreshold: 14400000,
        days: 0,
        hours: '4',
        minutes: '0',
        considerAnomalyAfterIncident: false,
        type: item.dataType,
        grouping: item.isMetric ? 'All' : undefined,
        anomalyTypeForSrc: [],
        enableIncidentDurationLimitation: false,
        metricDuration: 0,
        metricLevelValidPairingSet: [],
      });
    }, needAddProjects);

    this.setState({ selectProjects: newProjectList });
  }

  @autobind
  handleAddProjectClick() {
    const { selectProjects } = this.state;
    this.setState(
      {
        selectProjects: [
          {
            projectName: undefined,
            durationThreshold: 14400000,
            days: 0,
            hours: '4',
            minutes: '0',
            considerAnomalyAfterIncident: false,
            anomalyTypeForSrc: [],
            enableIncidentDurationLimitation: false,
            metricDuration: 0,
            metricLevelValidPairingSet: [],
          },
          ...selectProjects,
        ],
      },
      () => {
        if (this.listNode) this.listNode.scrollTo(0, 0);
      },
    );
  }

  @autobind
  handleRemoveProjectClick(rowData, index) {
    const { selectProjects } = this.state;
    this.setState({ selectProjects: R.remove(index, 1, selectProjects) });
  }

  @autobind
  handleEditPairingDuration(rowData, index) {
    this.setState({ showEditPairingDurationModal: true, activeEvent: { ...rowData, index } });
  }

  @autobind
  renderListViewProjects(rowData, index) {
    if (!rowData) return null;

    const { intl, userInfo } = this.props;
    const {
      projectName,
      days,
      hours,
      minutes,
      considerAnomalyAfterIncident,
      anomalyTypeForSrc,
      type,
      enableIncidentDurationLimitation,
      metricDuration,
      enableLastComponentCausalValidation,
    } = rowData;

    return (
      <div
        key={index}
        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
        style={{ minHeight: 40, padding: '6px 0' }}
      >
        <div className="row-column" style={{ width: 240 }}>
          <AntdSelect
            size="small"
            style={{ width: '100%' }}
            showSearch
            value={projectName}
            onChange={(projectName) => {
              const { selectProjects } = this.state;
              const project = R.find((item) => item.projectName === projectName, this.projectList || []);
              const { isMetric, dataType, projectTypeRaw, projectShortName, owner } = project || {};
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: {
                    $set: {
                      ...rowData,
                      projectName,
                      grouping: isMetric ? 'All' : undefined,
                      type: dataType,
                      projectType: projectTypeRaw,
                      anomalyTypeForSrc: [],
                      projectShortName,
                      owner,
                      metricLevelValidPairingSet: [],
                      enableIncidentDurationLimitation: false,
                      metricDuration: 0,
                    },
                  },
                }),
              });
            }}
            filterOption
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          >
            {R.map((item) => {
              return (
                <AntdSelect.Option key={item.projectName}>{`${item.projectDisplayName}${
                  userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName ? `@${item.owner}` : ''
                }`}</AntdSelect.Option>
              );
            }, this.projectList || [])}
          </AntdSelect>
        </div>
        <div className="row-column" style={{ width: 282 }}>
          <InputNumber
            size="small"
            style={{ width: 120 }}
            addonAfter="days"
            min={0}
            value={days}
            onChange={(days) => {
              const { selectProjects } = this.state;
              const durationThreshold =
                (days ? Number(days) : 0) * 86400000 +
                (hours ? Number(hours) : 0) * 3600000 +
                (minutes ? Number(minutes) : 0) * 60000;
              this.setState({
                selectProjects: update(selectProjects, { [index]: { $set: { ...rowData, days, durationThreshold } } }),
              });
            }}
          />
          <AntdSelect
            size="small"
            style={{ width: 100, marginLeft: 4 }}
            placeholder="Hour"
            value={hours || '0'}
            onChange={(hours) => {
              const { selectProjects } = this.state;
              const durationThreshold =
                (days ? Number(days) : 0) * 86400000 +
                (hours ? Number(hours) : 0) * 3600000 +
                (minutes ? Number(minutes) : 0) * 60000;
              this.setState({
                selectProjects: update(selectProjects, { [index]: { $set: { ...rowData, hours, durationThreshold } } }),
              });
            }}
          >
            {R.map((item) => {
              return <AntdSelect.Option key={String(item)}>{`${item} hours`}</AntdSelect.Option>;
            }, R.range(0, 24))}
          </AntdSelect>
          <AntdSelect
            size="small"
            style={{ width: 100, marginLeft: 4 }}
            placeholder="Minute"
            value={minutes || '0'}
            onChange={(minutes) => {
              const { selectProjects } = this.state;
              const durationThreshold =
                (days ? Number(days) : 0) * 86400000 +
                (hours ? Number(hours) : 0) * 3600000 +
                (minutes ? Number(minutes) : 0) * 60000;
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: { $set: { ...rowData, minutes, durationThreshold } },
                }),
              });
            }}
          >
            <AntdSelect.Option key="0">0 minutes</AntdSelect.Option>
            <AntdSelect.Option key="5">5 minutes</AntdSelect.Option>
            <AntdSelect.Option key="15">15 minutes</AntdSelect.Option>
            <AntdSelect.Option key="30">30 minutes</AntdSelect.Option>
            <AntdSelect.Option key="45">45 minutes</AntdSelect.Option>
          </AntdSelect>
        </div>
        <div className="row-column" style={{ width: 100 }}>
          <Switch
            size="small"
            checkedChildren={intl.formatMessage(appFieldsMessages.checkedOn)}
            unCheckedChildren={intl.formatMessage(appFieldsMessages.checkedOff)}
            checked={considerAnomalyAfterIncident}
            onChange={(considerAnomalyAfterIncident) => {
              const { selectProjects } = this.state;
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: { $set: { ...rowData, considerAnomalyAfterIncident } },
                }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 120 }}>
          <AntdSelect
            mode="multiple"
            size="small"
            allowClear
            maxTagCount={1}
            value={anomalyTypeForSrc}
            placeholder=""
            onChange={(anomalyTypeForSrc) => {
              const { selectProjects } = this.state;
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: { $set: { ...rowData, anomalyTypeForSrc } },
                }),
              });
            }}
            options={type === 'Metric' ? this.metricOptions : this.logOptions}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 250 }}
          />
        </div>
        <div className="row-column" style={{ width: 185 }}>
          {type === 'Metric' && (
            <>
              <Switch
                size="small"
                checkedChildren={intl.formatMessage(appFieldsMessages.checkedOn)}
                unCheckedChildren={intl.formatMessage(appFieldsMessages.checkedOff)}
                checked={enableIncidentDurationLimitation}
                onChange={(enableIncidentDurationLimitation) => {
                  const { selectProjects } = this.state;
                  this.setState({
                    selectProjects: update(selectProjects, {
                      [index]: { $set: { ...rowData, enableIncidentDurationLimitation } },
                    }),
                  });
                }}
              />
              <div className="flex-row flex-center-align">
                <span style={{ margin: '0 4px' }}>Minutes:</span>
                <InputNumber
                  size="small"
                  min={0}
                  style={{ width: 75 }}
                  value={metricDuration}
                  onChange={(metricDuration) => {
                    const { selectProjects } = this.state;
                    this.setState({
                      selectProjects: update(selectProjects, {
                        [index]: {
                          $set: { ...rowData, metricDuration: metricDuration || 0 },
                        },
                      }),
                    });
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="row-column" style={{ width: 100 }}>
          <Switch
            size="small"
            checkedChildren={intl.formatMessage(appFieldsMessages.checkedOn)}
            unCheckedChildren={intl.formatMessage(appFieldsMessages.checkedOff)}
            checked={enableLastComponentCausalValidation}
            onChange={(enableLastComponentCausalValidation) => {
              const { selectProjects } = this.state;
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: { $set: { ...rowData, enableLastComponentCausalValidation } },
                }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 80 }}>
          {type === 'Metric' && (
            <div>
              <Button size="small" onClick={() => this.handleEditPairingDuration(rowData, index)}>
                {intl.formatMessage(appButtonsMessages.edit)}
              </Button>
            </div>
          )}
        </div>
        <div className="row-column flex-end-justify" style={{ width: 70 }}>
          <Button
            size="small"
            className="button-color-grey"
            onClick={() => this.handleRemoveProjectClick(rowData, index)}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  changeUser(userNameValue) {
    const { userList } = this.props;
    const { userCompany } = this.state;
    const userName = R.find((item) => item.userName === userNameValue, userList || []) || {};
    if (userName) {
      const { company } = userName;
      if (userCompany === company) {
        this.setState({ userNameValue }, () => {
          this.getSystemList(userName);
        });
      } else {
        this.setState({ userNameValue, selectProjects: [], userCompany: company }, () => {
          this.getSystemList(userName);
        });
      }
    }
  }

  render() {
    const { intl, errorMessage, causalGroupList, currentLoadingComponents, loaderStatus, userList, userInfo } =
      this.props;
    const {
      isLoading,
      selectSystem,
      selectProjects,
      enableCorrelation,
      enableComponentIntra,
      userNameValue,
      systemListData,
      isGetLoading,
    } = this.state;
    const { credentials, defaultCausalMetricPairingThreshold } = this.props;
    const { showEditPairingDurationModal, activeEvent } = this.state;

    const causalNameLink = VLink.state(this, 'causalName')
      .check((value) => Boolean(value), 'Input Causal Group name')
      .check(
        (value) =>
          !ifIn(
            value,
            R.map((causalGroup) => causalGroup.causalName, causalGroupList),
          ),
        'The name already exists',
      );
    const cronIntervalLink = VLink.state(this, 'cronInterval').check(
      (value) => parseInt(value, 10) > 0,
      'Interval must larger than 0',
    );
    const correlationIntervalLink = VLink.state(this, 'correlationInterval').check(
      (value) => parseInt(value, 10) > 0,
      'Interval must larger than 0',
    );
    const correlationUnitLink = VLink.state(this, 'correlationUnit').check(
      (value) => Boolean(value),
      'select interval unit',
    );
    const retentionTimeLink = VLink.state(this, 'retentionTime').check(
      (value) => !value || parseInt(value, 10) > 0,
      'Interval must larger than 0',
    );
    const eventsRelationLookBackWindowLink = VLink.state(this, 'eventsRelationLookBackWindow').check(
      (value) => !value || parseInt(value, 10) > 0,
      'Range must larger than 0',
    );

    const hasError =
      causalNameLink.error ||
      cronIntervalLink.error ||
      correlationIntervalLink.error ||
      correlationUnitLink.error ||
      retentionTimeLink.error ||
      selectProjects.length === 0 ||
      eventsRelationLookBackWindowLink.error ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => item.projectName && item.durationThreshold >= 0, selectProjects),
      );
    const isSubmitting = get(currentLoadingComponents, this.submitLoader, false);
    const isParamsLoading = get(loaderStatus, this.paramsLoader, false);
    return (
      <>
        <Modal
          width={1420}
          title={intl.formatMessage(causalMessages.createCausalGroupTip)}
          visible
          onCancel={this.handleOnClose}
          okButtonProps={{ loading: isSubmitting, disabled: hasError }}
          onOk={this.handleSumbit}
          okText={intl.formatMessage(appButtonsMessages.create)}
        >
          <Container className={`create-group ${isLoading ? ' loading' : ''}`}>
            <Spin spinning={isGetLoading}>
              <form className={`ui ${hasError ? 'error' : ''} ${isParamsLoading ? 'loading ' : ''} form`}>
                <div className="flex-row flex-center-align" style={{ margin: '0 0 16px' }}>
                  <div className="light-label bold" style={{ width: 170 }}>
                    {intl.formatMessage(causalMessages.causalName)}:
                  </div>
                  <div className="flex-grow">
                    <Input className="full-width" valueLink={causalNameLink} />
                  </div>
                </div>
                <div className="flex-row flex-center-align" style={{ margin: '0 0 16px' }}>
                  <div className="light-label bold" style={{ width: 170 }}>
                    {intl.formatMessage(causalMessages.groupInterval)}:
                  </div>
                  <Input type="number" valueLink={cronIntervalLink} style={{ width: 100, height: 28 }} />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    {intl.formatMessage(causalMessages.correlationInterval)}:
                  </div>
                  <Input type="number" valueLink={correlationIntervalLink} style={{ width: 80, height: 28 }} />
                  <Select
                    name="correlationUnit"
                    valueLink={correlationUnitLink}
                    options={this.intervalUnitOptions}
                    style={{ width: 60 }}
                  />
                  <Tooltip title="Enable correlation" mouseEnterDelay={0.3} placement="top">
                    <Switch
                      size="small"
                      style={{ marginLeft: 8 }}
                      checkedChildren={intl.formatMessage(appFieldsMessages.checkedOn)}
                      unCheckedChildren={intl.formatMessage(appFieldsMessages.checkedOff)}
                      checked={enableCorrelation}
                      onChange={(enableCorrelation) => this.setState({ enableCorrelation })}
                    />
                  </Tooltip>
                </div>
                <div className="flex-row flex-center-align" style={{ margin: '0 0 16px' }}>
                  <div className="light-label bold" style={{ width: 170 }}>
                    {intl.formatMessage(causalMessages.retentionTime)}:
                  </div>
                  <Input type="number" valueLink={retentionTimeLink} style={{ width: 100, height: 28 }} />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    {intl.formatMessage(causalMessages.enableInterRelation)}:
                  </div>
                  <input
                    type="checkbox"
                    checked={this.state.enableInterRelation}
                    onChange={() => {
                      const { enableInterRelation } = this.state;
                      this.setState({ enableInterRelation: !enableInterRelation });
                    }}
                  />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    Enable component intra relation:
                  </div>
                  <input
                    type="checkbox"
                    checked={enableComponentIntra}
                    onChange={() => {
                      const { enableComponentIntra } = this.state;
                      this.setState({ enableComponentIntra: !enableComponentIntra });
                    }}
                  />
                </div>
                <div className="flex-row flex-center-align" style={{ margin: '0 0 16px' }}>
                  <div className="light-label bold" style={{ width: 170 }}>
                    {intl.formatMessage(causalMessages.eventsRelationLookBackTime)}:
                  </div>
                  <Input type="number" valueLink={eventsRelationLookBackWindowLink} style={{ width: 100 }} />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    {intl.formatMessage(causalMessages.enableIncidentOnly)}:
                  </div>
                  <input
                    type="checkbox"
                    checked={this.state.enableIncidentOnly}
                    onChange={() => {
                      const { enableIncidentOnly } = this.state;
                      this.setState({ enableIncidentOnly: !enableIncidentOnly });
                    }}
                  />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    {intl.formatMessage(causalMessages.enableOrphanIncidentParing)}:
                  </div>
                  <input
                    type="checkbox"
                    checked={this.state.includeTargetAnomalyInPossibility}
                    onChange={() => {
                      const { includeTargetAnomalyInPossibility } = this.state;
                      this.setState({ includeTargetAnomalyInPossibility: !includeTargetAnomalyInPossibility });
                    }}
                  />
                </div>
                {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
                  <div className="flex-row flex-center-align" style={{ margin: '0px 0px 16px' }}>
                    <div className="light-label bold" style={{ width: 170 }}>
                      Causal group owner:
                    </div>
                    <AntdSelect
                      showSearch
                      size="small"
                      value={userNameValue}
                      style={{ width: 200 }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={this.changeUser}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.map(
                        (item) => (
                          <AntdSelect.Option key={item.userName} value={item.userName}>
                            {item.userName}
                          </AntdSelect.Option>
                        ),
                        userList || [],
                      )}
                    </AntdSelect>
                  </div>
                )}

                <div className="flex-row flex-center-align" style={{ margin: '0px 0px 16px' }}>
                  <div className="light-label bold" style={{ width: 170 }}>
                    Adding projects by system:
                  </div>
                  <AntdSelect
                    style={{ width: 200 }}
                    showSearch
                    optionFilterProp="value"
                    value={selectSystem}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    onChange={(selectSystem) => this.setState({ selectSystem })}
                    dropdownMatchSelectWidth={false}
                  >
                    {R.map(
                      (system) => (
                        <AntdSelect.Option key={system.systemId} value={system.systemId}>
                          {`${system.systemName}${system.owner ? ` (${system.owner})` : ''}`}
                        </AntdSelect.Option>
                      ),
                      systemListData,
                    )}
                  </AntdSelect>
                  <Button
                    type="primary"
                    size="small"
                    style={{ marginLeft: 8 }}
                    disabled={!selectSystem}
                    onClick={this.handleSystemAddClick}
                  >
                    {intl.formatMessage(appButtonsMessages.add)}
                  </Button>
                </div>
              </form>
              <div className="flex-row flex-center-align">
                <div className="light-label bold" style={{ width: 170 }}>
                  {intl.formatMessage(DashboardMessages.projectList)}:
                </div>
                <div className="flex-grow flex-col" style={{ width: 0 }}>
                  <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
                    <div className="flex-row">
                      <Button size="small" type="primary" onClick={this.handleAddProjectClick}>
                        <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
                      </Button>
                    </div>
                  </div>

                  <div className="event-list flex-grow flex-col flex-min-height overflow-x-auto">
                    <div className="event-list-header" style={{ height: 'fit-content', width: 'fit-content' }}>
                      <div className="header-column" style={{ width: 240 }}>
                        {intl.formatMessage(eventMessages.projectName)}
                        <span style={{ color: 'red', textDecoration: 'none', marginLeft: 5 }}>*</span>
                      </div>
                      <div className="header-column" style={{ width: 282 }}>
                        {intl.formatMessage(causalMessages.intervalLimit)}
                        <span style={{ color: 'red', textDecoration: 'none', marginLeft: 5 }}>*</span>
                      </div>
                      <div className="header-column" style={{ width: 100 }}>
                        {intl.formatMessage(causalMessages.considerAnomalyAfterIncident)}
                      </div>
                      <div className="header-column" style={{ width: 120 }}>
                        {intl.formatMessage(causalMessages.sourceCandidateAnomalyTypes)}
                      </div>
                      <div className="header-column" style={{ width: 185 }}>
                        {intl.formatMessage(causalMessages.enableAnomalyToIncidentGapCheck)}
                      </div>
                      <div className="header-column" style={{ width: 100 }}>
                        {intl.formatMessage(causalMessages.enableLatestComponentLevelCausalValidations)}
                      </div>
                      <div className="header-column" style={{ width: 80 }}>
                        {intl.formatMessage(causalMessages.pairingDuration)}
                      </div>
                      <div className="header-column" style={{ width: 70 }} />
                    </div>
                    <div
                      className="event-list-grid flex-grow overflow-y-auto"
                      ref={(el) => {
                        this.listNode = el;
                      }}
                      style={{ maxHeight: 160, width: 'fit-content' }}
                    >
                      {R.addIndex(R.map)(
                        (rowData, index) => this.renderListViewProjects(rowData, index),
                        selectProjects || [],
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Spin>

            {Boolean(errorMessage) && (
              <div className="error content">
                <div className="ui error mini message">
                  <i className="icon warning sign" />
                  <span>{intl.formatMessage(errorMessage.message, errorMessage.params)}</span>
                </div>
              </div>
            )}
          </Container>
        </Modal>

        {showEditPairingDurationModal && (
          <EditPairingDurationModal
            intl={intl}
            credentials={credentials}
            activeEvent={activeEvent}
            defaultCausalMetricPairingThreshold={defaultCausalMetricPairingThreshold}
            onClose={(saveItem) => {
              if (!R.isEmpty(saveItem)) {
                R.forEachObjIndexed((val, key) => {
                  const { selectProjects } = this.state;
                  this.setState({
                    selectProjects: update(selectProjects, {
                      [key]: { $set: { ...selectProjects[key], metricLevelValidPairingSet: val } },
                    }),
                  });
                }, saveItem || {});
              }
              this.setState({ showEditPairingDurationModal: false, activeEvent: null });
            }}
          />
        )}
      </>
    );
  }
}

const CreateGroupModal = injectIntl(CreateGroupModalCore);
export default connect((state) => {
  const { currentLoadingComponents, loadStatus, loaderStatus, systemsMap } = state.app;
  let { projects, userList } = state.app;
  projects = R.filter((project) => project.ps !== 'Deleting', projects);
  userList = R.filter((user) => user.role !== 'Admin', userList || []);
  const { userInfo, credentials } = state.auth;
  const { errorMessage } = state.causal;

  return {
    loadStatus,
    loaderStatus,
    currentLoadingComponents,
    systemsMap,
    projects,
    userInfo,
    errorMessage,
    credentials,
    userList,
  };
}, {})(CreateGroupModal);
