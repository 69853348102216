import React from 'react';
import * as R from 'ramda';

import { autobind } from 'core-decorators';
import { get } from 'lodash';
import VLink from 'valuelink';

import { Modal } from '../../../../../artui/react';
import { Select } from '../../../../lib/fui/react';

type Props = {
  intl: Object,
  currentLoadingComponents: Object,
  modelKey: String,
  seasonType: String,
  projects: Array<Object>,
  onClose: Function,
  exportProjectModel: Function,
};

class ModelExportModal extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.submitLoader = 'setting_model_export';
    this.sumbitting = false;
    this.created = false;
    this.ifIn = (i, items) => items.indexOf(i) !== -1;

    this.state = {
      projectName: '',
      instanceGroup: '',
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If already submit and loading back to false, close this modal.
    const { currentLoadingComponents } = nextProps;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      this.created = true;
      this.modal.close();
    }
  }

  @autobind
  handleOnClose() {
    const { onClose } = this.props;
    if (onClose) {
      onClose(this.created);
    }
  }

  @autobind
  handleSumbit() {
    const { modelKey, seasonType, exportProjectModel, projects } = this.props;
    const { projectName, instanceGroup } = this.state;
    const project = R.find((p) => p.projectName === projectName, projects);
    this.sumbitting = true;
    exportProjectModel(
      {
        projectName,
        instanceGroup,
        modelKey,
        seasonType,
        customerName: project.owner,
      },
      { [this.submitLoader]: true },
    );
  }

  render() {
    const { intl } = this.props;
    const { projects, currentLoadingComponents } = this.props;

    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    const projectNameLink = VLink.state(this, 'projectName').check((x) => Boolean(x), 'select project');
    const projectName = projectNameLink.value;
    const project = R.find((p) => p.projectName === projectName, projects);
    const groupList = get(project, 'groupList', []);
    const groupOptions = R.map((g) => ({ label: g, value: g }), groupList);
    const instanceGroupLink = VLink.state(this, 'instanceGroup').check((x) => Boolean(x), 'select group');
    const hasError = projectNameLink.error || instanceGroupLink.error;

    return (
      <div>
        <Modal ref={(c) => (this.modal = c)} size="small" closable={false} onClose={this.handleOnClose}>
          <div className="create-group content">
            <div className="ui warning mini message">
              <i className="icon info" />
              <span>Select the target project and instance group to export the select model.</span>
            </div>
            <form className={`ui ${hasError ? 'error' : ''} form`}>
              <div className="input inline field flex-row no-error">
                <label>Project:</label>
                <Select
                  name="project"
                  options={R.map((p) => ({ label: p.projectName, value: p.projectName }), projects)}
                  style={{ width: 210 }}
                  valueLink={projectNameLink}
                />
                <label>Group:</label>
                <Select name="group" options={groupOptions} valueLink={instanceGroupLink} style={{ width: 210 }} />
              </div>
            </form>
          </div>
          <div className="actions">
            <div className="ui button deny" onClick={this.handleOnClose}>
              Cancel
            </div>
            <div className={`ui button labeled ${hasError ? 'disabled' : ''}`}>
              <div className={`ui button orange ${isLoading ? 'loading' : ''}`} onClick={this.handleSumbit}>
                <i className="save icon" />
                <span>Submit</span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModelExportModal;
