import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { LineChartOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { Defaults, GlobalParse, buildUrl } from '../../../common/utils';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appMenusMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  style: Object,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  projectDisplayMap: Object,
};

class GlobalPanelLineChartCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    let { systemInfo, projectDisplayMap } = props;

    if (projectDisplayMap && systemInfo) {
      const newSystemInfo = R.clone(systemInfo);
      newSystemInfo.projectNameSet = R.map(
        (item) => ({
          ...item,
          ...(projectDisplayMap[item.projectName] ? { projectDisplayName: projectDisplayMap[item.projectName] } : {}),
        }),
        newSystemInfo?.projectNameSet || [],
      );
      systemInfo = newSystemInfo;
    }

    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const metricProjects = R.filter(
      (project) => project.dataType === 'Metric' && project.ps !== 'Deleting',
      projectNameSet,
    );

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {
      eventList: metricProjects || [],
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.systemInfo !== this.props.systemInfo) {
      this.parseData(nextProps);
    }
  }

  @autobind
  parseData(props) {
    let { systemInfo, projectDisplayMap } = props;

    if (!systemInfo || R.isEmpty(systemInfo)) {
      return;
    }

    if (projectDisplayMap) {
      const newSystemInfo = R.clone(systemInfo);
      newSystemInfo.projectNameSet = R.map(
        (item) => ({
          ...item,
          ...(projectDisplayMap[item.projectName] ? { projectDisplayName: projectDisplayMap[item.projectName] } : {}),
        }),
        newSystemInfo?.projectNameSet || [],
      );
      systemInfo = newSystemInfo;
    }

    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const metricProjects = R.filter(
      (project) => project.dataType === 'Metric' && project.ps !== 'Deleting',
      projectNameSet,
    );

    this.setState({ eventList: metricProjects || [] });
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }) {
    const { intl } = this.props;
    const { eventList } = this.state;
    const item = eventList[rowIndex];
    if (!item) return null;

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
          <div className="row-column" style={{ width: 120, flex: 1 }}>
            {item.projectDisplayName}
          </div>
          <div className="row-column" style={{ width: 120 }}>
            <Button size="small" icon={<LineChartOutlined />} onClick={() => this.handleLineChartClick(item)}>
              {intl.formatMessage(appMenusMessages.linechart)}
            </Button>
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  handleLineChartClick({ item, domEvent }) {
    domEvent.stopPropagation();
    const { customerName, startTime, endTime, environmentId, systemInfo } = this.props;
    const {
      props: {
        data: { projectNameReal },
      },
    } = item;
    const systemId = get(systemInfo, ['id']);
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    const startTimeObj = moment.utc(startTime, Defaults.DateFormat).startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
    if (instanceGroup !== 'All') modelType = 'splitByEnv';
    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,
      projectName: projectNameReal,
      instanceGroup,
      modelType,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      predictedFlag: false,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  render() {
    const { intl } = this.props;
    const { eventList } = this.state;

    return (
      <Dropdown
        overlay={
          <Menu onClick={this.handleLineChartClick}>
            {R.addIndex(R.map)(
              (item, index) => (
                <Menu.Item key={`linechartLIst-${index}`} title={item.projectDisplayName} data={item}>
                  {item.projectDisplayName}
                </Menu.Item>
              ),
              eventList || [],
            )}
          </Menu>
        }
        placement="bottomLeft"
        arrow
        size="small"
      >
        <Button size="small">
          <LineChartOutlined />
          {intl.formatMessage(appMenusMessages.linechart)}
        </Button>
      </Dropdown>
    );
  }
}

const GlobalPanelLineChart = injectIntl(GlobalPanelLineChartCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,
    };
  },
  { updateLastActionInfo },
)(GlobalPanelLineChart);
