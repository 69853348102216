/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';
import { logPatternTopKToArray } from './magicParsers';

const getLogClusterInfoList = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, day } = params;
  const startTimeObj = moment.utc(day, Defaults.DateFormat).startOf('day');

  // Use the time as the incident id.
  return fetchGet(getEndpoint('logstreaminglistpatterns'), {
    ...credentials,
    projectName,
    instanceName,
    dayTimeMillis: startTimeObj.valueOf(),
  })
    .then((d) => {
      let patterns = get(d, 'patternArray', []);
      let maxEventsCount = 0;
      patterns = R.map((p) => {
        // Convert the topK string into array.
        const featureKeywords = p.topFeatureKeyword || [];
        const kwords = R.take(3, logPatternTopKToArray(p.topK));
        // const keywords = R.map(k => `${k.matchWord}`, featureKeywords || []);
        const keywords = kwords;

        maxEventsCount = Math.max(maxEventsCount, p.count);

        const sampleMsgContent = get(p, ['sampleMsg'], [])[0] || '';
        let msgName = sampleMsgContent;
        let mjson;
        try {
          mjson = JSON.parse(sampleMsgContent);
        } catch (e) {}

        if (mjson && mjson.msg) {
          msgName = mjson.msg;
        }

        // const name = p.patternName === `Pattern ${p.nid}` ? keywords.join('-') || kwords.join('-') : p.patternName;
        const name = p.patternName === `Pattern ${p.nid}` ? '' : p.patternName;
        return {
          ...p,
          keywords,
          keywordsStr: R.join('-', keywords),
          featureKeywords,
          name,
          msgName: msgName || name,
          sampleMsgContent,
          patternName: p.patternName,
          eventsCount: p.count,
        };
      }, patterns);
      patterns = R.sort((a, b) => b.eventsCount - a.eventsCount, patterns);

      // const totalCount = R.reduce((acc, p) => acc + p.eventsCount, 0, patterns);
      R.forEach((p) => (p.countRatio = p.eventsCount / maxEventsCount), patterns);

      return { clusterInfoList: patterns };
    })
    .catch((err) => {
      console.warn('[IF_API] get pattern list failed, ignored', err);
      return [];
    });
};

export default getLogClusterInfoList;
