import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { ceil, get, round, isFinite, isNumber, isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import {
  InfoCircleOutlined,
  EditOutlined,
  SearchOutlined,
  UpOutlined,
  DownOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { Menu, message, Spin } from 'antd';
import { replace } from 'react-router-redux';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import {
  Defaults,
  EventRenderers,
  LogRenderers,
  CellRenderers,
  GlobalParse,
  CausalParser,
  buildUrl,
  parseLocation,
  buildLocation,
  getPatternNameIcon,
} from '../../../common/utils';
import { Modal, List, CellMeasurerCache, CellMeasurer, Dropdown, Popover } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';

import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { appButtonsMessages, appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';

import LikelyRootCausesModal from './LikelyRootCausesModal';
import TriageReportModal from './TriageReportModal';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import EventActionModal from '../../metric/components/EventActionModal';
import TimeSelectModal from '../../metric/components/TimeSelectModal';
import ProjectSelectorModal from './ProjectSelectorModal';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import ReportJiraModal from '../../metric/components/ReportJiraModal';
import ReportServiceNowModal from '../../metric/components/ReportServiceNowModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  title: String,
  // eslint-disable-next-line
  tabName: String,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  events: Array<Object>,
  isLoading: Boolean,
  // eslint-disable-next-line
  onClose: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  globalInfo: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  timezoneOffset: Number,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,

  currentTheme: String,
  summarySettingsMap: Object,
  onRefreshData: Function,
  replace: Function,
  rankMap: rankMap,
  height: Number,
  width: Number,
  activeLineIconKey: String,
  changeActiveLineIconKey: Function,
  changeLikelyRootCausesRCA: Function,
};

class GlobalDetectedIncidentTableCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = 40;
    this.listRowHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      fixedHeight: false,
      defaultHeight: this.listRowHeight,
    });

    this.state = {
      isLoading: true,
      // eslint-disable-next-line
      ignoreFilter: true,
      eventList: [],
      patternNameMap: {},

      activeEvent: null,
      showTakeLogActionModal: false,
      actionName: null,
      showTriageReportModal: false,
      showEventActionModal: false,
      showReportJiraModal: false,
      showReportServiceNowModal: false,

      showRCModal: false,
      needRC: false,

      showTimeSelectModal: false,
      showContextModal: false,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
      contextKeywordFilter: '',
      showProjectSelector: false,
      onConfirmProjectSelect: () => {},

      instanceNameSearch: '',
      groupByDay: [],
      listHeight: 280,
      collapsedRowKeys: [],
    };
    this.localEventList = [];
    this.leadIncidentPatternNameStrMap = {};
    this.groupByDay = [];
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.events !== this.props.events) {
      this.parseData(nextProps);
    }
    if (
      nextProps.activeLineIconKey !== this.props.activeLineIconKey &&
      R.includes(`${nextProps.tabName}-`, nextProps.activeLineIconKey || '')
    ) {
      let findIdx = R.findIndex(
        (item) => `${nextProps.tabName}-${item.startTimestamp}-${item.projectName}` === nextProps.activeLineIconKey,
        this.localEventList || [],
      );
      findIdx = findIdx === -1 ? 0 : findIdx;
      setTimeout(() => {
        if (this.listNode) {
          this.cellMeasureCache.clearAll();
          this.listNode.scrollToRow(findIdx);
          this.listNode.forceUpdateGrid();
        }
      }, 500);
    }
  }

  @autobind
  async parseData(props) {
    const { events, tabName, activeLineIconKey } = props;
    const { sortBy, sortDirection, groupByDay } = this.state;

    this.setState({ isLoading: true });

    // build timePairArr and duration
    const eventList = R.map((event) => {
      const { startTimestamp, metricRootCause, category, instanceNameString, containerInfo } = event;
      let { anomalyFeatureStr = [], containerName } = event;
      const day = moment.utc(startTimestamp).format(Defaults.DateFormat);
      const timePairArr = [[event.startTimestamp, event.endTimestamp]];

      let allDuration = 0;
      R.forEach((tr) => {
        const [st, et, dur] = tr;
        allDuration += dur || et - st;
      }, timePairArr);
      const duration = timePairArr.length > 0 ? round(allDuration / timePairArr.length) : 0;

      let metricRootCauseObj;
      let frequencyStr = '';
      try {
        metricRootCauseObj = JSON.parse(metricRootCause);
      } catch (e) {
        // console.debug(error)
      }
      if (metricRootCauseObj && category.toLowerCase() === 'log') {
        const { anomalyValue, percentage, sign } = metricRootCauseObj;
        const percent = `${Math.abs(percentage).toFixed(2)}%`;
        frequencyStr = `${
          isNumber(anomalyValue) ? `Count: ${anomalyValue}. ` : ''
        }Frequency is ${percent} ${sign} than normal.`;
      }

      anomalyFeatureStr = R.map((item) => {
        const [label] = item.label.split(':');
        const name = get(item, 'name');
        return { label: name || label, value: item.value };
      }, anomalyFeatureStr);
      anomalyFeatureStr = R.sort(R.ascend(R.prop('label')), anomalyFeatureStr);
      const featureGroup = R.groupBy((item) => item.label)(anomalyFeatureStr);

      anomalyFeatureStr = R.map((item) => item, R.toPairs(featureGroup));

      let instanceListStr = instanceNameString;
      if (containerInfo) {
        instanceListStr = containerInfo.instanceName;
        containerName = containerInfo.containerName;
      }

      return {
        ...event,
        day,
        timePairArr,
        duration,
        frequencyStr,
        anomalyFeatureStr,
        instanceListStr,
        containerName,
      };
    }, events || []);

    // filter and sort
    let localEventList = this.filterData(eventList);
    localEventList = this.sortData(localEventList, sortBy, sortDirection);

    localEventList = R.map((item) => {
      const { startTimestamp } = item;
      const predictionTimeFromat = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
      const hasDay = groupByDay.includes(predictionTimeFromat);
      if (!hasDay) {
        groupByDay.push(predictionTimeFromat);
      }
      return { ...item, showDay: !hasDay };
    }, localEventList);

    const dayEventMap = {};
    R.forEach((item) => {
      const { day, patternId, projectName, instanceName } = item;
      const key = `${day}-${projectName}-${instanceName}-${patternId}`;
      if (!dayEventMap[key]) {
        dayEventMap[key] = { ...item, isParent: true, dailyEvents: [item] };
      } else {
        const current = dayEventMap[key];
        current.dailyEvents = [...(current.dailyEvents || []), item];
      }
    }, localEventList);

    this.localEventList = R.values(dayEventMap);

    let findIdx = R.findIndex(
      (item) => `${tabName}-${item.startTimestamp}-${item.projectName}` === activeLineIconKey,
      this.localEventList || [],
    );
    findIdx = findIdx === -1 ? 0 : findIdx;
    setTimeout(() => {
      if (this.listNode) {
        this.cellMeasureCache.clearAll();
        this.listNode.scrollToRow(findIdx);
        this.listNode.forceUpdateGrid();
      }
    }, 500);

    const listHeight =
      this.localEventList.length >= 8 ? 8 * this.listRowHeight : this.localEventList.length * this.listRowHeight;
    this.setState({ isLoading: false, eventList, groupByDay, listHeight }, () => {
      if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  getLogsummarysettings(projectName) {
    const { credentials } = this.props;
    return new Promise((resolve, reject) => {
      fetchGet(getEndpoint('logsummarysettings'), {
        ...credentials,
        projectName,
      })
        .then((summarySettings) => {
          resolve(summarySettings);
        })
        .catch((err) => {
          resolve([]);
        });
    });
  }

  @autobind
  filterData(eventList) {
    const filterList = R.filter((e) => !e.isIgnored, eventList || []);
    return filterList;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    const { tabName } = this.props;
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('startTimestamp'))];
    if (['predictedIncidents'].includes(tabName)) {
      sortFunctions = [R.ascend(R.prop('predictionTime'))];
    }

    if (sortBy && sortDirection && sortDirection !== 'NA') {
      if (sortBy === 'likelyRootCauses') {
        sortFunctions =
          sortDirection === 'DESC'
            ? [R.descend(R.compose(R.prop('hasPrecedingEvent'), R.prop('rootCauseResultInfo')))]
            : [R.ascend(R.compose(R.prop('hasPrecedingEvent'), R.prop('rootCauseResultInfo')))];
      } else {
        sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
      }
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  onChangeFilterIgnore(ignoreFilter) {
    // eslint-disable-next-line
    this.setState({ ignoreFilter }, () => {
      const { sortBy, sortDirection } = this.state;
      const eventList = this.state.eventList || [];
      let localEventList = this.filterData(eventList);
      localEventList = this.sortData(localEventList, sortBy, sortDirection);

      this.localEventList = localEventList;
      if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  renderAnomalyListItem(events) {
    return ({ key, index: rowIndex, style, parent, columnIndex }) => {
      const rowData = events[rowIndex];
      if (!rowData) return null;
      const { collapsedRowKeys } = this.state;

      const { tabName, activeLineIconKey, changeActiveLineIconKey } = this.props;
      const { componentName, instanceNameString, predictionTime, predictionLeadDuration, startTimestamp } = rowData;
      const { projectName, patternId, isParent, id } = rowData;

      const activeRowKey = `${startTimestamp}-${projectName}-${componentName}-${instanceNameString}-${patternId}`;
      const active = R.includes(`${componentName}-${instanceNameString}-${patternId}`, activeLineIconKey || '')
        ? activeRowKey === activeLineIconKey
        : `${tabName}-${startTimestamp}-${projectName}` === activeLineIconKey;

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div
            className={`event-list-row ${rowData.rowIndex % 2 === 1 ? ' odd-row' : ''} ${
              active && isParent ? ' active' : ''
            } clear-bold`}
            style={{ ...style, minHeight: this.listRowHeight }}
            onClick={() => changeActiveLineIconKey(activeRowKey)}
          >
            <div className="row-column" style={{ width: 160 }}>
              {tabName !== 'predictedIncidents' && isParent && this.renderPattern(rowData)}
              {tabName === 'predictedIncidents' && this.renderPattern(rowData)}
            </div>
            {tabName === 'predictedIncidents' && (
              <div className="row-column" style={{ width: 110 }}>
                {moment.utc(predictionTime).format(Defaults.ShortTimeFormat)}
              </div>
            )}
            {tabName !== 'predictedIncidents' && (
              <div className="row-column" style={{ width: 110 }}>
                {this.renderTimePair(rowData)}
              </div>
            )}
            <div className="row-column" style={{ width: 140 }}>
              {tabName !== 'predictedIncidents' && isParent && this.renderInstance(rowData, componentName)}
              {tabName === 'predictedIncidents' && this.renderInstance(rowData, componentName)}
            </div>
            <div className="row-column" style={{ width: 160 }}>
              {tabName !== 'predictedIncidents' && isParent && this.renderInstance(rowData)}
              {tabName === 'predictedIncidents' && this.renderInstance(rowData)}
            </div>
            <div className="row-column" style={{ width: 120, flex: 1, padding: '0 20px' }}>
              {tabName !== 'predictedIncidents' && !isParent && this.rendererContent(rowData)}
              {tabName === 'predictedIncidents' && this.rendererContent(rowData)}
            </div>
            {tabName === 'predictedIncidents' && (
              <div className="row-column" style={{ width: 150 }}>
                {isFinite(predictionLeadDuration) ? `${ceil(predictionLeadDuration / 60000)} minutes` : 'N/A'}
              </div>
            )}
            {isParent && <div className="row-column" />}
            {isParent && (
              <div
                className="row-column"
                style={{ height: 35 }}
                onClick={() => {
                  this.setState(
                    {
                      collapsedRowKeys: {
                        ...collapsedRowKeys,
                        [id]: !collapsedRowKeys[id],
                      },
                    },
                    () => {
                      setTimeout(() => {
                        if (this.listNode) {
                          this.cellMeasureCache.clearAll();
                          this.listNode.forceUpdateGrid();
                        }
                      }, 200);
                    },
                  );
                }}
              >
                {collapsedRowKeys[id] ? (
                  <UpOutlined style={{ fontSize: 14 }} />
                ) : (
                  <DownOutlined style={{ fontSize: 14 }} />
                )}
              </div>
            )}
            {!isParent && (
              <div className="row-column" style={{ width: 140 }}>
                {this.statusRender(rowData)}
              </div>
            )}
            {!isParent && (
              <div className="row-column" style={{ width: 95 }}>
                {this.renderControl(rowData)}
              </div>
            )}
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  statusRender(rowData) {
    const { rootCauseResultInfo } = rowData;
    const hasRootCause = get(rootCauseResultInfo, 'hasPrecedingEvent');
    return (
      <div className="flex-row flex-wrap full-width flex-center-justify">
        {hasRootCause && (
          <SearchOutlined
            style={{ fontSize: 16, fontWeight: 'bold' }}
            className="primary-color"
            onClick={() => this.handleRCClick({ event: rowData, needRC: true })}
          />
        )}
      </div>
    );
  }

  @autobind
  renderInstance(rowData, cellData) {
    const { intl, location, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};
    const { projectDisplayName, componentName, componentNameString, instanceListStr, containerName } = rowData;
    const { projectName, projectOwner } = rowData;

    const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, instanceListStr, {
      pn: projectName,
      owner: projectOwner,
    });
    const width = instanceDisplayName ? 140 : 120;
    return (
      <Popover
        title={null}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 180 }}>
            <div className="flex-row">
              <div style={{ fontWeight: 'bold', width }}>{intl.formatMessage(eventMessages.projectName)}:</div>
              <div className="flex-grow">{projectDisplayName}</div>
            </div>
            <div className="flex-row">
              <div style={{ fontWeight: 'bold', width }}>{intl.formatMessage(eventMessages.componentName)}:</div>
              <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                {componentName || componentNameString}
              </div>
            </div>
            <div className="flex-row">
              <div style={{ fontWeight: 'bold', width }}>{intl.formatMessage(eventMessages.instanceName)}:</div>
              <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                {instanceListStr}
              </div>
            </div>
            {instanceDisplayName && (
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 140 }}>
                  {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                </div>
                <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                  {instanceDisplayName}
                </div>
              </div>
            )}
            {containerName && (
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width }}>{intl.formatMessage(appFieldsMessages.container)}:</div>
                <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                  {containerName}
                </div>
              </div>
            )}
          </div>
        }
        placement="right"
      >
        <div className="max-width hidden-line-with-ellipsis inline-block">
          {cellData || instanceDisplayName || instanceListStr}
        </div>
      </Popover>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { intl, rankMap } = this.props;

    const { patternName, patternId, id } = rowData;
    const rank = (rankMap || {})[id];

    const patternIdAndName = Defaults.PatternIdNameStr({ patternName, patternId }, {}).patternNameStr;

    const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
    const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });

    return (
      <div className="max-width flex-row flex-center-align">
        {rank && (
          <div style={{ position: 'relative' }}>
            {/* <PinLegendIcon style={{ color: '#FF5142', fontSize: 25, marginTop: -5 }} /> */}
            <Popover
              title={null}
              content={intl.formatMessage(DashboardMessages.detectedIncident)}
              mouseEnterDelay={0.3}
            >
              {patternIcon}
            </Popover>
            <span
              style={{
                position: 'absolute',
                left: '50%',
                color: 'white',
                top: '50%',
                transform: 'translate(-85%, -50%)',
              }}
            >
              {rank}
            </span>
          </div>
        )}
        <Popover
          content={`[${patternId}]${String(patternId || '') !== patternIdAndName ? ` ${patternIdAndName}` : ''}`}
          mouseEnterDelay={0.3}
          placement="top"
        >
          <div className="hidden-line-with-ellipsis">{`[${patternId}]${
            String(patternId || '') !== patternIdAndName ? ` ${patternIdAndName}` : ''
          }`}</div>
        </Popover>
        <Popover content={intl.formatMessage(eventMessages.setPatternName)} mouseEnterDelay={0.3} placement="top">
          <EditOutlined
            className="primary-color hover-show"
            style={{ marginLeft: 2 }}
            onClick={() => this.handleActionClick(rowData, 'setPatternName')}
          />
        </Popover>
      </div>
    );
  }

  @autobind
  renderTimePair(rowData) {
    const { intl, tabName } = this.props;
    const {
      isParent,
      startTimestamp,
      endTimestamp,
      timestampMap,
      timePairArr,
      triggeredActionList,
      showDay,
      dailyEvents,
    } = rowData;

    const startDayTamp = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
    const startTimeStrDay = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
    const startTimeStrTime = moment.utc(startTimestamp).format(Defaults.ShortTimeOnlyFormat);

    let endDayTamp = moment.utc(endTimestamp).format(Defaults.ShortDayFormat);
    let endTimeStr = moment.utc(endTimestamp).format(Defaults.ShortTimeOnlyFormat);
    if (dailyEvents && dailyEvents.length > 0) {
      endDayTamp = moment.utc(dailyEvents[dailyEvents.length - 1].endTimestamp).format(Defaults.ShortDayFormat);
      endTimeStr = moment.utc(dailyEvents[dailyEvents.length - 1].endTimestamp).format(Defaults.ShortTimeOnlyFormat);
    }
    const isSameDay = startDayTamp === endDayTamp;

    let endTimeStrDay = null;
    let endTimeStrTime = null;

    if (!isSameDay) {
      endTimeStrDay = moment.utc(endTimestamp).format(Defaults.ShortDayFormat);
      endTimeStrTime = moment.utc(endTimestamp).format(Defaults.ShortTimeOnlyFormat);
    }

    if (['detectedIncidents', 'detectedAlerts'].includes(tabName)) {
      let trs = [];
      R.forEachObjIndexed((count, time) => {
        trs.push([time, count]);
      }, timestampMap);
      trs = R.sortBy(R.prop(0), trs);

      // use trs if timestampMap is not empty
      if (trs.length > 0) {
        return (
          <Popover
            mouseEnterDelay={0.3}
            placement="right"
            title={null}
            content={
              <div className="flex-col" style={{ maxWidth: 320, maxHeight: 240, overflowY: 'auto' }}>
                {R.addIndex(R.map)((itemList, idx) => {
                  return (
                    <div key={idx}>{`${CellRenderers.time({ cellData: itemList[0] })} Count: ${itemList[1]}`}</div>
                  );
                }, trs)}
              </div>
            }
          >
            <div className="flex-col" style={{ width: 90 }}>
              <div className="flex-row flex-center-align">
                <span className="flex-grow" style={{ textAlign: 'right' }}>
                  {isParent ? startTimeStrDay : ''}
                </span>
                <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{startTimeStrTime}</span>
              </div>

              {isSameDay && (
                <div className="flex-row flex-center-align">
                  <span className="flex-grow" style={{ textAlign: 'right' }} />
                  <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStr}</span>
                </div>
              )}

              {!isSameDay && (
                <div className="flex-row flex-center-align">
                  <span className="flex-grow" style={{ textAlign: 'right' }}>
                    {endTimeStrDay}
                  </span>
                  <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStrTime}</span>
                </div>
              )}
            </div>
          </Popover>
        );
      }
      return (
        <Popover
          mouseEnterDelay={0.3}
          placement="right"
          title={null}
          content={
            <div className="flex-col overflow-y-auto" style={{ maxHeight: 240 }}>
              {R.addIndex(R.map)((itemList, idx) => {
                return (
                  <div key={idx}>
                    <span className="light-label bold" style={{ marginRight: 4 }}>
                      {intl.formatMessage(appFieldsMessages.startTime)}:
                    </span>
                    <span>{CellRenderers.time({ cellData: itemList[0] })}</span>
                    <span className="light-label bold" style={{ marginRight: 4, marginLeft: 8 }}>
                      {intl.formatMessage(appFieldsMessages.endTime)}:
                    </span>
                    <span>{CellRenderers.time({ cellData: itemList[1] })}</span>
                  </div>
                );
              }, timePairArr || [])}
            </div>
          }
        >
          <div className="flex-col" style={{ width: 90 }}>
            <div className="flex-row flex-center-align">
              <span className="flex-grow" style={{ textAlign: 'right' }}>
                {startTimeStrDay}
              </span>
              <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{startTimeStrTime}</span>
            </div>

            {isSameDay && (
              <div className="flex-row flex-center-align">
                <span className="flex-grow" style={{ textAlign: 'right' }} />
                <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStr}</span>
              </div>
            )}

            {!isSameDay && (
              <div className="flex-row flex-center-align">
                <span className="flex-grow" style={{ textAlign: 'right' }}>
                  {endTimeStrDay}
                </span>
                <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStrTime}</span>
              </div>
            )}
          </div>
        </Popover>
      );
    }

    return (
      <Popover
        mouseEnterDelay={0.3}
        placement="right"
        title={null}
        content={
          <div>
            <span className="light-label bold" style={{ marginRight: 4 }}>
              {intl.formatMessage(appFieldsMessages.startTime)}:
            </span>
            <span>
              {startTimeStrDay} {startTimeStrTime}
            </span>
            <span className="light-label bold" style={{ marginRight: 4, marginLeft: 8 }}>
              {intl.formatMessage(appFieldsMessages.endTime)}:
            </span>
            <span>
              {endDayTamp} {endTimeStr}
            </span>
          </div>
        }
      >
        <div className="flex-col" style={{ width: 90 }}>
          <div className="flex-row flex-center-align">
            <span className="flex-grow" style={{ textAlign: 'right' }}>
              {startTimeStrDay}
            </span>
            <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{startTimeStrTime}</span>
          </div>

          {isSameDay && (
            <div className="flex-row flex-center-align">
              <span className="flex-grow" style={{ textAlign: 'right' }} />
              <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStr}</span>
            </div>
          )}

          {!isSameDay && (
            <div className="flex-row flex-center-align">
              <span className="flex-grow" style={{ textAlign: 'right' }}>
                {endTimeStrDay}
              </span>
              <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStrTime}</span>
            </div>
          )}
        </div>
      </Popover>
    );
  }

  @autobind
  renderDetails(rowData) {
    const { intl, currentTheme } = this.props;
    const { rawData, anomalyWords, outlierValue, rootCauseJson } = rowData;

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
      if (!isObject(rawDataJson)) rawDataJson = undefined;
    } catch (error) {
      // console.debug(error);
    }
    const rootCauseDetailsArr = get(rootCauseJson, ['rootCauseDetailsArr'], []);

    return (
      <div>
        {rawData && (
          <LogRenderers.ExpandLogJSONContent
            intl={intl}
            rawData={rawData}
            rawDataJson={rawDataJson}
            anomalyWordList={anomalyWords}
            outlierValue={outlierValue}
            currentTheme={currentTheme}
          />
        )}
        {rootCauseDetailsArr.length > 0 && (
          <div style={{}}>
            {R.addIndex(R.map)(
              (event, index) =>
                EventRenderers.RenderMetricAnomalySummary({
                  intl,
                  event,
                  index,
                  metricUnitMap: {},
                  hideName: true,
                  hideTime: true,
                }),
              R.take(3, rootCauseDetailsArr),
            )}
            {rootCauseDetailsArr.length > 3 && <div>...</div>}
          </div>
        )}
      </div>
    );
  }

  @autobind
  rendererContent(rowData) {
    const { intl, currentTheme, summarySettingsMap = {} } = this.props;
    const { category, rawData, anomalyWords, outlierValue, projectName, frequencyStr, anomalyFeatureStr } = rowData;
    const rootCauseDetailsArr = get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []);

    let content;
    let rawDataJson;
    if (category === 'metric') {
      const summaryList = R.map((event) => {
        return EventRenderers.BuildMetricAnomalySummary({ event });
      }, rootCauseDetailsArr);
      content = R.join('\n', summaryList);
    } else {
      try {
        rawDataJson = JSON.parse(rawData);
      } catch (error) {
        // console.debug(error)
      }
    }

    const hasExtra = (!R.isEmpty(anomalyFeatureStr) && anomalyFeatureStr) || !R.isEmpty(frequencyStr);

    return (
      <div className="max-width flex-row hover-display" style={{ height: '100%' }}>
        <div className="max-width flex-row" style={{ alignItems: hasExtra ? 'flex-start' : 'center' }}>
          <div
            className="hidden-line-with-ellipsis flex-row overflow-hidden"
            style={{ minHeight: this.listRowHeight, alignItems: hasExtra ? 'flex-start' : 'center', padding: '4px 0' }}
          >
            {category === 'metric' ? (
              <span className="hidden-line-with-ellipsis">{content}</span>
            ) : (
              <>
                {rawDataJson && (
                  <LogRenderers.RenderLogContent
                    intl={intl}
                    rawData={rawData}
                    rawDataJson={rawDataJson}
                    owner={rowData}
                    summarySettings={summarySettingsMap[projectName] || []}
                    enableExpansion={false}
                    currentTheme={currentTheme}
                    frequencyStr={frequencyStr}
                    anomalyFeatureStr={anomalyFeatureStr}
                    clearStyle
                    inLine
                  />
                )}
                {!rawDataJson && (
                  <div className="flex-col full-width">
                    {!R.isEmpty(frequencyStr) && (
                      <div style={{ color: 'var(--blue)' }} className="hidden-line-with-ellipsis">
                        {frequencyStr}
                      </div>
                    )}
                    {!R.isEmpty(anomalyFeatureStr) && (
                      <div className="max-width flex-col" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
                        {R.addIndex(R.map)((item, index) => {
                          const [label, values] = item;
                          const acontent = R.addIndex(R.map)(
                            ({ value }, i) => (
                              <span style={{ margin: '0 4px' }} key={`featureStr${index}${i}`}>
                                {value}
                                {values.length - 1 === i ? '' : ','}
                              </span>
                            ),
                            values,
                          );
                          return (
                            <div className="flex-row" key={`${index}+${label}`}>
                              <div style={{ whiteSpace: 'nowrap' }}>{label} :</div>
                              <div className="hidden-line-with-ellipsis">{acontent}</div>
                            </div>
                          );
                        }, anomalyFeatureStr)}
                      </div>
                    )}
                    <div className="hidden-line-with-ellipsis">{rawData}</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Popover
          placement="right"
          content={
            <div
              className="overflow-y-auto"
              style={{
                maxWidth: 480,
                maxHeight: 350,
                padding: 0,
                wordBreak: 'break-all',
                whiteSpace: 'pre-wrap',
              }}
            >
              {category === 'metric' && (
                <div>
                  {R.addIndex(R.map)(
                    (event, index) => EventRenderers.RenderMetricAnomalySummary({ intl, event, index }),
                    rootCauseDetailsArr,
                  )}
                </div>
              )}
              {category === 'log' && (
                <>
                  {rawDataJson && !R.isEmpty(frequencyStr) && (
                    <div style={{ color: 'var(--blue)', wordBreak: 'break-word' }}>{frequencyStr}</div>
                  )}
                  {rawDataJson && !R.isEmpty(anomalyFeatureStr) && anomalyFeatureStr && (
                    <div className="max-width flex-col" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
                      {R.addIndex(R.map)((item, index) => {
                        const [label, values] = item;
                        const content = R.addIndex(R.map)(
                          ({ value }, i) => (
                            <span style={{ margin: '0 4px' }} key={`featureStr${index}${i}`}>
                              {value}
                              {values.length - 1 === i ? '' : ','}
                            </span>
                          ),
                          values,
                        );
                        return (
                          <div className="flex-row" key={`${index}+${label}`}>
                            <div style={{ whiteSpace: 'nowrap' }}>{label} :</div>
                            <div className="flex-row flex-wrap">{content}</div>
                          </div>
                        );
                      }, anomalyFeatureStr)}
                    </div>
                  )}
                  {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                  {!rawDataJson && (
                    <LogRenderers.RenderLogContent
                      intl={intl}
                      rawData={rawData}
                      rawDataJson={rawDataJson}
                      anomalyWordList={anomalyWords}
                      outlierValue={outlierValue}
                      owner={rowData}
                      currentTheme={currentTheme}
                      frequencyStr={frequencyStr}
                      anomalyFeatureStr={anomalyFeatureStr}
                    />
                  )}
                </>
              )}
            </div>
          }
        >
          <div className="flex-row flex-center-align hover-visible-item" style={{ minHeight: this.listRowHeight }}>
            <InfoCircleOutlined className="primary-color" style={{ marginLeft: 2 }} />
          </div>
        </Popover>
      </div>
    );
  }

  @autobind
  renderControl(event) {
    const { intl, tabName, userInfo } = this.props;

    const { category, typeList, neuronId } = event;
    let hasPrecedingEvent = false;
    if (tabName === 'detectedIncidents') {
      hasPrecedingEvent = get(event, ['rootCauseResultInfo', 'hasPrecedingEvent']);
    }
    return (
      <Dropdown
        name={intl.formatMessage(eventMessages.actions)}
        itemClick={({ key }) => {
          switch (key) {
            case 'likelyRootCauses':
              this.handleRootCauseClick(event);
              break;
            case 'details':
              this.handleDetailsClick(tabName, event);
              break;
            case 'originalNormalPattern':
              this.handleOriginalNormalPatternClick(event);
              break;
            case 'lineChart':
              this.handleLineChartClick(event);
              break;
            case 'context':
              this.handleLogContextClick(event);
              break;
            case 'setPatternName':
              this.handleActionClick(event, 'setPatternName');
              break;
            case 'takeAction':
              this.handleEventActionClick(event);
              break;
            case 'editTriageReport':
              this.handleTriageReportClick({ event });
              break;
            case 'reportJira':
              this.handleReportJiraClick(event);
              break;
            case 'reportServiceNow':
              this.handleReportServiceNowClick(event);
              break;
            default:
              break;
          }
        }}
      >
        <>
          <Menu.Item key="details">
            {['detectedAlerts', 'predictedIncidents', 'fixedIncidents'].includes(tabName)
              ? intl.formatMessage(appFieldsMessages.details)
              : tabName === 'detectedIncidents'
              ? intl.formatMessage(hasPrecedingEvent ? appMenusMessages.rootCauses : appFieldsMessages.details)
              : intl.formatMessage(DashboardMessages.correlatedAnomalies)}
          </Menu.Item>
          {category === 'metric' && (
            <Menu.Item key="lineChart">{intl.formatMessage(DashboardMessages.lineChart)}</Menu.Item>
          )}
          {category !== 'metric' && typeList.includes('rare') && isNumber(neuronId) && (
            <Menu.Item key="originalNormalPattern">
              {intl.formatMessage(DashboardMessages.originalNormalPattern)}
            </Menu.Item>
          )}
          {['detectedAlerts'].includes(tabName) && event.category === 'log' && (
            <Menu.Item key="context">{intl.formatMessage(eventMessages.context)}</Menu.Item>
          )}

          {/* <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item> */}
          {/* <Menu.Item key="takeAction">{intl.formatMessage(eventMessages.takeAction)}</Menu.Item> */}
          <Menu.Item key="editTriageReport">{intl.formatMessage(eventMessages.editTriageReport)}</Menu.Item>
          {['detectedIncidents', 'detectedAlerts'].includes(tabName) && (
            <Menu.Item key="reportJira" disabled={userInfo.isReadUser}>
              <Popover
                content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.reportJira)}
              </Popover>
            </Menu.Item>
          )}
          {['detectedIncidents', 'detectedAlerts'].includes(tabName) && (
            <Menu.Item key="reportServiceNow" disabled={userInfo.isReadUser}>
              <Popover
                content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.reportServiceNow)}
              </Popover>
            </Menu.Item>
          )}
        </>
      </Dropdown>
    );
  }

  @autobind
  handleRCClick({ event, needRC }) {
    this.props.changeLikelyRootCausesRCA({
      activeEvent: { ...event, summarySettingsMap: this.props.summarySettingsMap },
      needRC,
    });
    // this.setState({ showRCModal: true, activeEvent: event, needRC });
  }

  @autobind
  handleRootCauseClick(rowData) {
    const { intl, projects, systemInfo, currentTheme } = this.props;
    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    let { predictionSourceInfoList } = rowData;
    predictionSourceInfoList = R.map((item) => {
      const { sourceProjectName, sourceDetail } = item;
      const project = R.find((project) => project.projectShortName === sourceProjectName, projects || []);

      const { nid } = sourceDetail;
      let { type } = sourceDetail;
      let { isLogType, eventType } = CausalParser.getRelationLogType(type);
      if (project && project.isDeployment) {
        type = 'deployment';
        isLogType = true;
        eventType = 'deployment';
      }
      return { ...item, sourceDetail: { ...sourceDetail, type, isLogType, eventType }, patternId: nid };
    }, predictionSourceInfoList);

    Modal.info({
      width: 650,
      title: intl.formatMessage(eventMessages.likelyRootCauses),
      content: (
        <div
          className="overflow-y-auto"
          style={{
            maxHeight: 380,
          }}
        >
          {R.addIndex(R.map)((item, index) => {
            const {
              sourceDetail,
              sourceProjectName,
              sourceProjectOwner,
              sourceInstanceName,
              patternId,
              metricInstanceName,
            } = item;
            const { type, content, metricDirection, isLogType, eventType } = sourceDetail || {};
            let rawDataJson;
            try {
              rawDataJson = JSON.parse(content);
            } catch (error) {
              // console.debug(error)
            }
            const isHigher = metricDirection ? metricDirection.toLowerCase() === 'positive' : null;

            return (
              <div key={index} className="flex-col" style={{ marginBottom: 16 }}>
                <div className="full-width">
                  <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(eventMessages.rootCauseHop)}:
                  </div>
                  {index + 1}
                </div>
                <div className="hidden-line-with-ellipsis" style={{ display: 'inline-block', maxWidth: '100%' }}>
                  <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(appFieldsMessages.project)}:
                  </div>
                  {`${sourceProjectName}@${sourceProjectOwner}`}
                </div>
                <div className="hidden-line-with-ellipsis" style={{ display: 'inline-block', maxWidth: '100%' }}>
                  <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(appFieldsMessages.component)}:
                  </div>
                  {get(instanceComponentMap, metricInstanceName || sourceInstanceName, sourceInstanceName)}
                </div>
                <div className="hidden-line-with-ellipsis" style={{ display: 'inline-block', maxWidth: '100%' }}>
                  <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(appFieldsMessages.instance)}:
                  </div>
                  {sourceInstanceName}
                </div>
                <div className="full-width">
                  <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(appFieldsMessages.pattern)}:
                  </div>
                  {patternId}
                </div>
                <div className="full-width flex-row flex-center-align">
                  <div className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(appFieldsMessages.type)}:
                  </div>
                  {isLogType
                    ? CellRenderers.logTypeRenderer({ intl, rowData: { type: eventType } })
                    : intl.formatMessage(appFieldsMessages.metric)}
                </div>

                <div className="light-label bold" style={{ minWidth: 120 }}>
                  {intl.formatMessage(eventMessages.shortDescription)}:
                </div>
                {type === 'Metric' && (
                  <div className="flex-row flex-center-align" style={{ wordBreak: 'break-all' }}>
                    <span className="light-label bold" style={{ marginRight: 8, wordBreak: 'normal' }}>
                      {intl.formatMessage(appFieldsMessages.metric)}:
                    </span>
                    <span>{content}</span>
                    {isHigher && <i className="icon up arrow" style={{ color: 'red' }} />}
                    {!isHigher && <i className="icon down arrow" style={{ color: 'blue' }} />}
                  </div>
                )}

                {type !== 'Metric' && (
                  <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                    {!rawDataJson &&
                      R.join(
                        '\n',
                        R.filter((x) => Boolean(x), (content || '').split('\n')),
                      )}
                    {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                  </div>
                )}
              </div>
            );
          }, predictionSourceInfoList)}
        </div>
      ),
      okText: intl.formatMessage(appButtonsMessages.close),
      onOk() {},
    });
  }

  @autobind
  handleActionClick(event, actionName) {
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const activeEvent = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    this.setState({ showTakeLogActionModal: true, actionName, activeEvent });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    this.setState({ showTakeLogActionModal: false, actionName: null, activeEvent: null, patternNameMap: {} }, () => {
      this.props.onRefreshData();
    });
  }

  @autobind
  handleEventActionClick(incident) {
    const { patternId, startTimestamp } = incident;
    const eventType = incident.type
      ? incident.type.charAt(0).toLowerCase() + incident.type.substring(1)
      : incident.eventType;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const causalRootCause = {
      operation: eventType === 'deployment' ? 'predictedEvents' : 'rootCauseEvents',
      function: eventType === 'deployment' ? 'alert' : 'root cause',
      startTime,
      nid: patternId,
      eventType,
    };
    this.setState({ activeEvent: { ...incident, causalRootCause }, showEventActionModal: true });
  }

  @autobind
  handleTriageReportClick({ event }) {
    this.setState({ showTriageReportModal: true, activeEvent: event });
  }

  @autobind
  getProjectNameList() {
    const { systemsMap, systemInfo: globalSystemInfo } = this.props;
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === globalSystemInfo.id, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  handleDetailsClick(tabName, event) {
    const { systemInfo, location } = this.props;
    const { environmentId, customerName } = parseLocation(location);
    const {
      isIncident,
      isTrace,
      category,
      type,
      day,
      startTimestamp,
      projectName,
      patternId,
      instanceList,
      metricList,
      isIgnored,
      projectOwner,
    } = event;
    const startTime = day || moment.utc(startTimestamp).format(Defaults.DateFormat);

    if (['predictedIncidents', 'fixedIncidents'].includes(tabName)) {
      const query = {
        environmentId,
        customerName: projectOwner,
        systemId: systemInfo.id,
        startTime,
        endTime: startTime,

        hideIgnore: !isIgnored,
        typeFilter: 'all',

        eventProjectName: projectName,
        eventInstanceName: instanceList[0],
        eventPatternId: patternId,
        eventTimestamp: startTimestamp,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemPrediction, {}, query), '_blank');
    } else if (['detectedIncidents'].includes(tabName)) {
      const query = {
        environmentId,
        customerName: projectOwner,
        systemId: systemInfo.id,
        startTime,
        endTime: startTime,

        eventCategory: 'incident',
        eventPatternType: 'incident',
        eventProjectName: projectName,
        eventPatternId: patternId,
        eventInstanceName: instanceList[0],
        eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
        eventTimestamp: startTimestamp,
        hideIgnore: !isIgnored,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    } else if (['detectedAlerts'].includes(tabName)) {
      const query = {
        environmentId,
        customerName: projectOwner,
        systemId: systemInfo.id,
        startTime,
        endTime: startTime,

        hideIgnore: !isIgnored,

        eventCategory: isIncident ? 'incident' : category === 'metric' ? 'metric' : isTrace ? 'trace' : 'log',
        eventPatternType: isIncident ? 'incident' : category === 'metric' ? 'metric' : type,
        eventProjectName: projectName,
        eventPatternId: patternId,
        eventInstanceName: instanceList[0],
        eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
        eventTimestamp: startTimestamp,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    }
  }

  @autobind
  handleOriginalNormalPatternClick(event) {
    const { userInfo, projects } = this.props;
    const { projectOwner, startTimestamp, anomalyLogInstance, instanceList, neuronId, type } = event;
    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const query = {
      projectName,
      instanceName: anomalyLogInstance || instanceList[0],
      startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      activeTab: 'clusters',
      activePatternId: neuronId,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project?.owner || projectOwner,
      anomalyType: type.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  handleLineChartClick(event) {
    const { userInfo, location } = this.props;
    const { environmentId } = parseLocation(location);
    const { projectOwner, startTimestamp, endTimestamp, instanceList, metricList } = event;

    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const startTimeObj = moment.utc(startTimestamp).startOf('day');
    const endTimeObj = moment.utc(endTimestamp).endOf('day');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    const query = {
      projectName,
      instanceGroup,
      modelType,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      justInstanceList: R.join(',', instanceList),
      justSelectMetric: R.join(',', metricList),
      // withBaseline: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleLogContextClick(event) {
    const { startTimestamp, endTimestamp } = event;
    const activeEvent = {
      ...event,
      instanceName: event.anomalyLogInstance || event.instanceName,
      timestamp: event.startTimestamp,
    };

    this.setState({
      showTimeSelectModal: true,
      activeEvent,
      selectInstance: activeEvent.instanceName,
      selectStartTimestamp: startTimestamp - 60 * 1000,
      selectEndTimestamp: endTimestamp + 60 * 1000,
    });
  }

  @autobind
  onCloseTimeSelect(props) {
    const { projectName, instanceName, startTimestamp, endTimestamp, keywordFilter } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState({
        showTimeSelectModal: false,
        showContextModal: true,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp: startTimestamp,
        selectEndTimestamp: endTimestamp,
        contextKeywordFilter: keywordFilter,
      });
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  handleIgnoreClick({ event, category }) {
    const { intl } = this.props;
    const { isIgnored, isImportant, patternName, patternId } = event;
    const flag = (category === 'ignore' && isIgnored) || (category === 'important' && isImportant);
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: flag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          }),
      onOk: this.handleIgnoreSumbit(event, category),
    });
  }

  @autobind
  handleIgnoreSumbit(incident, category) {
    return () => {
      const { intl, credentials, userInfo } = this.props;
      const { isIgnored, isImportant } = incident;
      const { projectOwner, anomalyLogInstance, instanceName, patternId, type } = incident;
      let { projectName } = incident;
      projectName = projectOwner !== userInfo.userName ? `${projectName}@${projectOwner}` : projectName;

      if (this.ignoreModal) {
        this.ignoreModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      let content;
      if (category === 'ignore' && !isIgnored) {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, incident.rootCausesDetailsList || []);
        content = R.join('\n', summaryList);
      }

      let operation;
      switch (category) {
        case 'ignore':
          operation = isIgnored ? 'cancelIgnoreFlag' : 'setIgnoreFlag';
          break;
        case 'important':
          operation = isImportant ? 'cancelImportantFlag' : 'setImportantFlag';
          break;
        default:
          break;
      }

      this.props.updateLastActionInfo();
      return fetchPost(
        getEndpoint('events', 1),
        {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceName,
          operation,
          nid: patternId,
          type: type === 'Incident' ? 'incident' : undefined,
          content,
        },
        {},
        false,
      )
        .then((data) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.ignoreModal) this.ignoreModal.destroy();

          this.props.onClose(true, true);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.ignoreModal) {
            this.ignoreModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  handleReportJiraClick(incident) {
    this.setState({ activeEvent: incident, showReportJiraModal: true });
  }

  @autobind
  handleReportServiceNowClick(incident) {
    this.setState({ activeEvent: incident, showReportServiceNowModal: true });
  }

  @autobind
  handleOverallLineChartClick() {
    this.setState({ showProjectSelector: true, onConfirmProjectSelect: this.handleOverallLineChartJump });
  }

  @autobind
  handleOverallLineChartJump(projectName) {
    const { location, systemInfo } = this.props;
    const { environmentId, customerName, startTime, endTime } = parseLocation(location);

    const startTimeObj = moment.utc(endTime, Defaults.DateFormat).startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    // get instanceList
    let instanceList = [];
    R.forEach(
      (item) => {
        instanceList = [...instanceList, ...item.instanceList];
      },
      R.filter((event) => event.category === 'metric', this.localEventList),
    );
    R.forEach(
      (item) => {
        instanceList.push(item.realInstanceName);
      },
      R.filter((event) => event.category !== 'metric', this.localEventList),
    );
    instanceList = R.take(10, R.uniq(instanceList));

    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId: systemInfo.id,

      projectName,
      instanceGroup,
      modelType,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      justInstanceList: R.join(',', instanceList),
      withBaseline: false,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          this.localEventList = this.sortData(this.localEventList, name, sortDir);
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  handleInstanceNameSearchChange(instanceNameSearch) {
    this.setState({ instanceNameSearch });
  }

  @autobind
  handleRefresh() {
    this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
    this.forceUpdate();
  }

  render() {
    const { intl, location, userInfo, projects, tabName, systemInfo, isLoading: isLoadingData, width } = this.props;
    const { height } = this.props;
    const { listHeight, sortBy, sortDirection, patternNameMap, instanceNameSearch, collapsedRowKeys } = this.state;
    const { activeEvent, actionName } = this.state;

    // build action params
    const { environmentId } = parseLocation(location);
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let incidentPatternName = null;
    let projectName;
    let project;
    if (activeEvent) {
      const { category, patternId, anomalyLogInstance, projectOwner, patternName } = activeEvent;
      projectName =
        userInfo.userName !== projectOwner ? `${activeEvent.projectName}@${projectOwner}` : activeEvent.projectName;
      project = R.find((project) => projectName === project.projectName, projects);
      const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
      incidentPatternName = get(patternNameMap, [key, patternId]) || patternName;
    }

    let events = [];
    R.forEach((item) => {
      const { id } = item;
      if (!collapsedRowKeys[id]) {
        events = [...events, item, ...(item.dailyEvents || [])];
      } else {
        events = [...events, item];
      }
    }, this.localEventList);

    if (instanceNameSearch) {
      events = R.filter((event) => R.toLower(event.instanceNameString).includes(R.toLower(instanceNameSearch)), events);
    }
    return (
      <div className="full-width full-height spin-full-height">
        <div className="flex-grow flex-min-height">
          <div className="event-list">
            <div
              className="event-list-header"
              style={{
                height: this.listHeaderHeight,
                width,
                paddingRight: this.listNodeHeaderScrollbar ? 8 : 0,
              }}
            >
              <div className="header-column" style={{ width: 160 }} onClick={this.headerClick('patternId')}>
                <span>{intl.formatMessage(appFieldsMessages.patternIdName)}</span>
                {this.sortIcon(sortBy, sortDirection, 'patternId')}
              </div>
              {tabName === 'predictedIncidents' && (
                <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('predictionTime')}>
                  <span>{intl.formatMessage(eventMessages.predictionTime)}</span>
                  {this.sortIcon(sortBy, sortDirection, 'predictionTime')}
                </div>
              )}
              {tabName !== 'predictedIncidents' && (
                <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('startTimestamp')}>
                  <span>{intl.formatMessage(eventMessages.timeLabel)}</span>
                  {this.sortIcon(sortBy, sortDirection, 'startTimestamp')}
                </div>
              )}

              <div className="header-column" style={{ width: 140 }} onClick={this.headerClick('componentName')}>
                <span>{intl.formatMessage(eventMessages.componentName)}</span>
                {this.sortIcon(sortBy, sortDirection, 'componentName')}
              </div>
              <div className="header-column" style={{ width: 160 }} onClick={this.headerClick('instanceListStr')}>
                <span>{intl.formatMessage(eventMessages.instanceName)}</span>
                {this.sortIcon(sortBy, sortDirection, 'instanceListStr')}
              </div>
              <div className="header-column" style={{ width: 120, flex: 1, padding: '0 20px' }}>
                {intl.formatMessage(eventMessages.shortDescription)}
              </div>
              {tabName === 'predictedIncidents' && (
                <div
                  className="header-column"
                  style={{ width: 150 }}
                  onClick={this.headerClick('predictionLeadDuration')}
                >
                  <span>{intl.formatMessage(eventMessages.leadTime)}</span>
                  {this.sortIcon(sortBy, sortDirection, 'predictionLeadDuration')}
                </div>
              )}
              <div className="header-column" style={{ width: 140 }} onClick={this.headerClick('likelyRootCauses')}>
                <span style={{ display: 'inline-block', margin: '0 auto' }}>
                  {intl.formatMessage(eventMessages.likelyRootCauses)}
                </span>
                {this.sortIcon(sortBy, sortDirection, 'likelyRootCauses')}
              </div>
              <div className="header-column" style={{ width: 95 }} />
            </div>
            <List
              className="event-list-grid"
              ref={(listNode) => {
                this.listNode = listNode;
              }}
              onScrollbarPresenceChange={({ horizontal, vertical }) => {
                if (vertical) {
                  this.listNodeHeaderScrollbar = true;
                } else {
                  this.listNodeHeaderScrollbar = false;
                }
                if (this.listNode) this.listNode.forceUpdateGrid();
              }}
              width={width}
              height={height}
              rowCount={events.length}
              overscanRowCount={4}
              deferredMeasurementCache={this.cellMeasureCache}
              rowHeight={this.cellMeasureCache.rowHeight}
              rowRenderer={this.renderAnomalyListItem(events)}
            />
          </div>
        </div>
        {/* General modals */}
        {this.state.showRCModal && (
          <LikelyRootCausesModal
            incident={activeEvent}
            environmentId={environmentId}
            systemId={systemInfo.id}
            projectName={projectName}
            functionRC="root cause"
            needRC={this.state.needRC}
            onClose={() => this.setState({ showRCModal: false, activeEvent: null, needRC: false })}
          />
        )}

        {this.state.showTriageReportModal && (
          <TriageReportModal
            environmentId={environmentId}
            systemId={systemInfo.id}
            incident={activeEvent}
            onClose={() => this.setState({ showTriageReportModal: false, activeEvent: null })}
          />
        )}
        {this.state.showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={actionName}
            incident={activeEvent}
            incidentPatternName={incidentPatternName}
            project={project}
            projectName={activeEvent.fullProjectName}
            instanceGroup={instanceGroup}
            eventType={activeEvent.type}
            onClose={() => this.setState({ showTakeLogActionModal: false })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {this.state.showEventActionModal && (
          <EventActionModal
            incident={activeEvent}
            project={project}
            projectName={projectName}
            projectNameList={this.getProjectNameList()}
            instanceGroup={instanceGroup}
            onClose={() => this.setState({ showEventActionModal: false })}
          />
        )}
        {this.state.showProjectSelector && (
          <ProjectSelectorModal
            system={systemInfo}
            onConfirm={this.state.onConfirmProjectSelect}
            onClose={() => this.setState({ showProjectSelector: false })}
          />
        )}
        {this.state.showTimeSelectModal && (
          <TimeSelectModal
            projectName={projectName}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
            timeIntervals={1}
            showKeywordSearch
          />
        )}
        {this.state.showContextModal && (
          <EventContextModal
            incident={activeEvent}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            keywordFilter={this.state.contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
          />
        )}
        {this.state.showReportJiraModal && (
          <ReportJiraModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showReportJiraModal: false })}
          />
        )}
        {this.state.showReportServiceNowModal && (
          <ReportServiceNowModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showReportServiceNowModal: false })}
          />
        )}
      </div>
    );
  }
}

const GlobalDetectedIncidentTable = injectIntl(GlobalDetectedIncidentTableCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset, projects, systemsMap, globalInfo } = state.app;
    const { credentials, userInfo } = state.auth;
    const { currentTheme } = state.app;
    return {
      location,
      globalInfo,
      loadStatus,
      timezoneOffset,
      projects,
      systemsMap,
      credentials,
      userInfo,
      currentTheme,
    };
  },
  { updateLastActionInfo, replace },
)(GlobalDetectedIncidentTable);
