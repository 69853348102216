import React, { createContext } from 'react';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';

import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';

import { message } from 'antd';
import { State } from '../../../common/types';
import { hideAppLoader, showAppLoader } from '../../../common/app/actions';
// eslint-disable-next-line
import BatchPage from './main';
import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { buildLocation, parseJSON, parseLocation } from '../../../common/utils';

type Props = {
  // eslint-disable-next-line
  match: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  isAdmin: Boolean,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  hideAppLoader: Function,
  // eslint-disable-next-line
  showAppLoader: Function,
  // eslint-disable-next-line
  userList: Array<Object>,
};

const BatchContext = createContext('batch');

const MAX_FATCH_NUM = 30;
class BatchCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      allLocalSystemMap: {},
      allLocalProjectMap: {},
      systemsMap: {},
      allProjects: [],
      getSystemLoading: false,
    };
  }

  componentDidMount() {
    this.resetUserName(this.props);
  }

  resetUserName(props) {
    const { isAdmin, isLocalAdmin, location, replace, userList } = props;
    let redirect = false;
    const query = parseLocation(location);
    let { customerName } = query;
    if ((isAdmin || isLocalAdmin) && !customerName && userList && userList.length > 0) {
      const user = userList[0] || R.find((u) => u.userName === 'user' || u.userName === 'guest', userList);
      if (user) {
        customerName = user.userName;
        redirect = true;
      }
    }

    if (redirect) {
      replace(buildLocation(location.pathname, {}, { ...query, customerName }));
    }
  }

  @autobind
  requestData(owner) {
    const { credentials } = this.props;
    const { allLocalSystemMap, allLocalProjectMap } = this.state;
    if (allLocalSystemMap[owner] && allLocalProjectMap[owner]) {
      this.setState({ systemsMap: allLocalSystemMap[owner], allProjects: allLocalProjectMap[owner] });
    } else {
      this.setState({ getSystemLoading: true });
      const requests = [
        fetchGet(getEndpoint('systemframework', 2), {
          ...credentials,
          customerName: owner || credentials.userName,
        }),
        fetchGet(getEndpoint('projectWithNoSystem', 2), {
          ...credentials,
          customerName: owner || credentials.userName,
        }),
      ];

      return Promise.all(requests)
        .then(async (results) => {
          let { ownSystemArr, shareSystemArr } = results[0];
          ownSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system };
          }, ownSystemArr || []);
          shareSystemArr = R.map((item) => {
            const system = parseJSON(item) || {};
            return { ...system, isShared: true };
          }, shareSystemArr || []);

          const systemsMap = {};
          const projectSystemKeyMap = {};
          let systemProjects = [];
          R.forEach(
            (system) => {
              const { systemDisplayName, systemKey, projectDetailsList, isShared, ...rest } = system;
              const { environmentName, systemName: systemId, userName } = systemKey || {};
              let newProjects = parseJSON(projectDetailsList) || [];
              newProjects = R.map((item) => {
                const { userName, projectClassType, ...restItem } = item;
                return {
                  ...restItem,
                  isShared,
                  customerName: userName,
                  projectType: projectClassType,
                };
              }, newProjects);
              systemProjects = systemProjects.concat(newProjects);
              if (!systemsMap[systemId]) {
                systemsMap[systemId] = {
                  ...rest,
                  isShared,
                  environmentName,
                  systemId,
                  systemName: systemDisplayName || systemId,
                  owner: userName,
                  projectDetailsList: newProjects,
                };
              }

              R.forEach((item) => {
                const { projectName, customerName } = item;
                const projectFullName = `${projectName}@${customerName}`;
                projectSystemKeyMap[projectFullName] = systemId;
              }, newProjects);
            },
            [...ownSystemArr, ...shareSystemArr],
          );

          let noSystemProjects = get(results, [2, 'noSystemProjectList'], '[]');
          noSystemProjects = JSON.parse(noSystemProjects);
          noSystemProjects = R.map((item) => parseJSON(item), noSystemProjects);

          let projects = systemProjects.concat(noSystemProjects);
          projects = R.uniqBy(
            (n) => `${n.projectName}-${n.projectDisplayName}-${n.customerName}-${n.dataType}`,
            projects,
          );
          projects = R.filter((item) => item.projectName, projects);

          let projectStats = {};
          const statusSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, projects || []);
          const statusRequests = [];
          R.forEach((group) => {
            if (group.length > 0) {
              statusRequests.push(
                fetchPost(getEndpoint('getprojectstatus'), {
                  ...credentials,
                  projectList: JSON.stringify(group),
                }),
              );
            }
          }, statusSplitEveryTakes || []);
          await Promise.all(statusRequests)
            .then((res) => {
              let groupProjectStats = {};
              R.forEach((data) => {
                groupProjectStats = { ...groupProjectStats, ...get(data, 'data', {}) };
              }, res || []);
              projectStats = R.mapObjIndexed((item) => {
                return { ...item, projectStatus: parseJSON(item.projectStatus) };
              }, groupProjectStats);
            })
            .catch((err) => {
              console.error('[IF] Failed to get project stats', err);
              return {};
            });

          R.forEach((item) => {
            R.forEachObjIndexed((_item) => {
              if (`${item.projectName}-${item.customerName}` === `${_item.projectName}-${_item.customerName}`) {
                item.ps = _item.projectStatus.ps;
              }
            }, projectStats);
          }, projects || []);

          const projectList = R.map((item) => {
            const { customerName, projectName, projectClassType } = item;
            return { customerName, projectName, projectType: projectClassType };
          }, projects);

          const metaSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, projectList || []);
          const metaRequests = [];
          R.forEach((group) => {
            if (group.length > 0) {
              metaRequests.push(
                fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
                  ...credentials,
                  projectList: JSON.stringify(group),
                }),
              );
            }
          }, metaSplitEveryTakes || []);
          await Promise.all(metaRequests)
            .then((res) => {
              let groupMetaProject = [];
              R.forEach((data) => {
                groupMetaProject = [...groupMetaProject, ...get(data, 'data', [])];
              }, res || []);
              projects = R.map((item) => {
                const findInfo = R.find((_item) => _item.projectKey === item.projectKey, groupMetaProject);
                if (findInfo) {
                  return { ...item, ...findInfo };
                } else {
                  return item;
                }
              }, projects);
            })
            .catch((err) => {
              console.error('[IF] Failed to get project stats', err);
              return {};
            });
          projects = R.map((project) => {
            const { projectDisplayName, projectName, projectType, customerName, dataType, status, isContainer, ps } =
              project;

            // The project full name is used for the API calls. It includes the customerName if the current
            // user is not the owner of the project
            let newProjectName = projectName;
            if (customerName !== credentials.userName) {
              newProjectName = `${projectName}@${customerName}`;
            }

            const isMetric = dataType && dataType.toLowerCase() === 'metric';
            const isLog = dataType && ['log', 'trace'].includes(dataType.toLowerCase());
            const isTrace = dataType && dataType.toLowerCase() === 'trace';
            const isDeployment = dataType && dataType.toLowerCase() === 'deployment';
            const isAlert = dataType && dataType.toLowerCase() === 'alert';
            const isIncident = dataType && dataType.toLowerCase() === 'incident';

            // systemInfo
            const projectFullName = `${projectName}@${customerName}`;
            const systemKey = projectSystemKeyMap[projectFullName];
            const { environmentName, systemId, systemName, isShared, timezone } = get(systemsMap, [systemKey], {});

            return {
              ...project,
              // system info
              systemKey,
              environmentName,
              systemId,
              systemName,
              isShared,
              timezone,

              // project meta info
              hasAllInfo: false,
              hasAllInstanceInfo: false,
              isContainer,
              projectDisplayName: projectDisplayName || projectName,
              projectName: newProjectName,
              projectShortName: projectName,
              projectType: projectType.toLowerCase() === 'custom' ? 'Agent' : projectType,
              projectTypeRaw: projectType,
              dataType,
              owner: customerName,
              // project status
              status,

              // project type
              isMetric,
              isLog,
              isTrace,
              isDeployment,
              isAlert,
              isIncident,
              ps,
              cloudType: project.cloudType,
              projectKey: project.projectKey,
              insightAgentType: project.insightAgentType,
            };
          }, projects);
          projects = R.sort((a, b) => (a.projectName || '').localeCompare(b.projectName), projects);

          this.setState({
            systemsMap,
            allProjects: projects,
            getSystemLoading: false,
            allLocalSystemMap: { ...allLocalSystemMap, [owner]: systemsMap },
            allLocalProjectMap: { ...allLocalProjectMap, [owner]: projects },
          });
        })
        .catch((err) => {
          this.setState({ getSystemLoading: false });
          message.error(err.message || String(err));
        });
    }
  }

  render() {
    const { systemsMap, allProjects, getSystemLoading } = this.state;
    return (
      <BatchContext.Provider
        value={{ ...this.props, requestData: this.requestData, systemsMap, allProjects, getSystemLoading }}
      >
        <BatchPage />
      </BatchContext.Provider>
    );
  }
}

export { BatchContext };
const Batch = injectIntl(BatchCore);

export default connect(
  (state: State) => {
    const { userInfo, credentials } = state.auth;

    const { isAdmin, isLocalAdmin } = state.auth.userInfo;

    const { location } = state.router;

    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);

    return {
      isAdmin,
      isLocalAdmin,
      credentials,
      location,
      userInfo,
      userList,
    };
  },
  {
    push,
    replace,
    hideAppLoader,
    showAppLoader,
  },
)(Batch);
