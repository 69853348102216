/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import moment from 'moment';
import * as R from 'ramda';
import { get } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';
import { Defaults } from '../app';

const getGlobalSystemHealth = (credentials: Credentials, params: Object) => {
  const { startTime, systemIds, customerName, environmentName } = params;
  const startTimestamp = moment
    .utc(startTime, Defaults.DateFormat)
    .startOf('day')
    .valueOf();
  const endTimestamp = moment
    .utc(startTime, Defaults.DateFormat)
    .endOf('day')
    .valueOf();
  const dayKey = moment.utc(startTime, Defaults.DateFormat).format(Defaults.DateShortFormat);
  return fetchPost(getEndpoint('stats/global/health', 2), {
    ...credentials,
    startTime: startTimestamp,
    endTime: endTimestamp,
    customerName,
    environmentName,
    systemIds: JSON.stringify(systemIds),
  }).then((d) => {
    const data = get(d, [dayKey], []);
    let healthList = R.sort((a, b) => {
      const aCurrent = get(a, ['healthScores', 'current'], 0);
      const aFuture = get(a, ['healthScores', 'future'], 0);
      const aPast = get(a, ['healthScores', 'past'], 0);
      const bCurrent = get(b, ['healthScores', 'current'], 0);
      const bFuture = get(b, ['healthScores', 'future'], 0);
      const bPast = get(b, ['healthScores', 'past'], 0);
      if (aCurrent !== bCurrent) {
        return bCurrent - aCurrent;
      } else if (aFuture !== bFuture) {
        return bFuture - aFuture;
      }
      return bPast - aPast;
    }, data || []);
    healthList = R.filter((hd) => Boolean(hd), healthList);
    return healthList;
  });
};

export default getGlobalSystemHealth;
