/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import { Tooltip } from '../../lib/fui/react';

const LimitedLengthText = ({
  text,
  maxLength,
  postfix,
  onClick,
  width,
  height,
  isHighlightKey,
  title,
  color,
  fontColor,
}: Object) => {
  const byDefault = !!width;
  const addStyle = width ? { width, overflow: 'hidden' } : {};
  const message = byDefault ? text : `${text.slice(0, maxLength)}..${postfix || ''}`;
  return (
    <Tooltip
      title={<div style={{ maxWidth: 400, wordBreak: 'break-all' }}>{text}</div>}
      placement="top"
      style={{ display: 'inline-block', cursor: !onClick ? 'default' : 'pointer', ...addStyle }}
      onClick={onClick}
      mouseEnterDelay={300}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {isHighlightKey ? (
          <span style={{ background: color || 'yellow', color: fontColor || 'black', padding: '0 2px' }} title={title}>
            {message}
          </span>
        ) : (
          message
        )}
      </div>
    </Tooltip>
  );
};

export default LimitedLengthText;
