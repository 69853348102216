/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { get, isString, isArray, isObject, isNumber, isBoolean, isUndefined } from 'lodash';

import CollapsibleContent from './CollapsibleContent';
import { ifIn } from '../../common/utils';
import { LimitedLengthText } from '../share';
import { AutoSizer } from '../../lib/fui/react';
import colorMap from './colorMap';
import './CollapsibleJsonContent.scss';

type Props = {
  ownerObject: Object,
  json: Object,
  width: Number,
  highlightWord: Object,
  style: Object,
  onChanged: Function,
  hideExpanded: Boolean,
  defaultExpanded: Boolean,
  inLine: Boolean,
  ignoreFields: Array,
  isCritical: Boolean,
};

const stackTraceNames = ['stacktrace', 'stack-trace'];
export const ifLike = (i, items) =>
  R.filter((item) => {
    return item.toLowerCase() === String(i).toLowerCase() || String(i).toLowerCase().indexOf(item.toLowerCase()) >= 0;
  }, items || []).length > 0;

class CollapsibleJsonContent extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.expandedStateKey = '__expanded_state__';
    this.minFields = 14;

    const hideExpanded = props.hideExpanded;
    let isExpanded = props.defaultExpanded;
    if (props.ownerObject && !isUndefined(props.ownerObject[this.expandedStateKey])) {
      isExpanded = Boolean(props.ownerObject[this.expandedStateKey]);
    }
    this.state = { hideExpanded, isExpanded };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.ownerObject !== this.props.ownerObject) {
      let isExpanded = nextProps.defaultExpanded;
      if (nextProps.ownerObject && !isUndefined(nextProps.ownerObject[this.expandedStateKey])) {
        isExpanded = Boolean(nextProps.ownerObject[this.expandedStateKey]);
      }
      this.setState({ isExpanded });
    }
  }

  @autobind
  handleEllipsisClicked() {
    this.setState({ isExpanded: !this.state.isExpanded }, () => {
      const { ownerObject, onChanged } = this.props;
      const { isExpanded } = this.state;
      if (ownerObject) {
        ownerObject[this.expandedStateKey] = isExpanded;
      }
      if (onChanged) {
        onChanged(isExpanded);
      }
    });
  }

  render() {
    const { style, width, onChanged, highlightWord, defaultExpanded, ownerObject, inLine, ignoreFields } = this.props;
    const { hideExpanded, isExpanded } = this.state;
    const anomalyWordsMap = get(ownerObject, ['anomalyWords'], {});
    const diffMap = get(ownerObject, ['diffMap'], {});

    let { json } = this.props;
    let cluster;
    let hasDiff = false;
    if (json.Diff && json.Cluster) {
      cluster = json.Cluster;
      hasDiff = true;
      try {
        json = JSON.parse(json.Diff);
      } catch (e) {}
    }

    // Auto display the keys of the json
    const names = R.sort(
      (a, b) => a.localeCompare(b),
      R.filter((n) => !ifIn(n.toLowerCase(), stackTraceNames) && !ifIn(n, ignoreFields || []), R.keys(json)),
    );

    const stname = R.find((n) => ifIn(n.toLowerCase(), stackTraceNames), R.keys(json));
    const stacktrace = get(json, stname);

    // Highlight json content by json path and all words
    const highlightWords = [];
    let allHighlightWords = [];

    let highlightWordList = [];
    if (isString(highlightWord)) {
      highlightWordList = [highlightWord];
    } else if (isArray(highlightWord)) {
      highlightWordList = highlightWord;
    }
    R.forEach((item) => {
      if (isString(item)) {
        if (item.indexOf(':') >= 0) {
          const [highlighPath, highlighValue] = R.split(':', item);
          const highlighPathList = R.split('->', highlighPath);
          highlightWords.push({
            path: highlighPathList,
            value: highlighValue,
          });
        } else {
          allHighlightWords.push({
            keyword: R.toLower(item),
          });
        }
      } else {
        const { keyword } = item || {};
        allHighlightWords.push({
          keyword: R.toLower(keyword),
        });
      }
    }, highlightWordList);
    R.forEachObjIndexed((val, anomalyWord) => {
      const { isKey, title, color, fontColor } = val;
      allHighlightWords.push({
        keyword: R.toLower(anomalyWord),
        isKey, // allow null
        title, // allow null
        color, // allow null
        fontColor, // allow null
      });
    }, anomalyWordsMap);
    allHighlightWords = R.filter((item) => item.keyword !== '', allHighlightWords);

    const jsonField = (val, diffMap, highlightWords, allHighlightWords, deep) => {
      if (isObject(val)) {
        // rebuild path
        const isArrayVal = isArray(val);
        const nextHighlightWords = isArrayVal
          ? highlightWords
          : R.map((filterHighlightWord) => {
              return {
                path: R.slice(1, Infinity, filterHighlightWord.path),
                value: filterHighlightWord.value,
              };
            }, highlightWords);

        let currentDeep = deep;
        if (isNumber(deep)) {
          currentDeep -= 1;
        } else {
          currentDeep = 2;
        }
        const isDeep = isNumber(deep) && currentDeep < 2;
        const deepHtmlPrefix = isDeep ? R.join('', R.repeat('&nbsp;', (2 - currentDeep) * 4)) : '';
        let html = '';
        R.forEachObjIndexed((value, key) => {
          let isHighlightKey = false;
          let title = null;
          let color = null;
          let fontColor = null;
          const keyHtml = isHighlightKey
            ? `<span style="background:${color || 'yellow'};color:${fontColor || 'black'};padding: 0 2px" ${
                title ? `title="${title}"` : ''
              }>${key}</span>`
            : key;

          // filter only matched path
          const filterHighlightWords =
            isArray(value) || isArrayVal
              ? nextHighlightWords
              : R.filter((highlightWord) => {
                  if (highlightWord.path.length > 0 && highlightWord.path[0] === key) {
                    return true;
                  }
                  return false;
                }, nextHighlightWords);
          const needDeepIn = isObject(value) && (!currentDeep || currentDeep !== 0);
          const keyHtmlPostfix = isArray(value) ? '[]' : isObject(value) ? '{}' : '';
          if (get(diffMap, [key])) {
            html += needDeepIn
              ? `${deepHtmlPrefix}${keyHtml}${keyHtmlPostfix}:&nbsp;<span style="background:yellow;padding: 0 2px">${jsonField(
                  value,
                  diffMap,
                  filterHighlightWords,
                  allHighlightWords,
                  currentDeep,
                )}</span><br>`
              : `${deepHtmlPrefix}${keyHtml}${keyHtmlPostfix}:&nbsp;<span style="background:yellow;padding: 0 2px">${
                  isObject(value) ? '...' : value
                }</span><br>`;
          } else if (isBoolean(value) || isString(value) || isNumber(value)) {
            let isHighlight = false;
            R.forEach((highlightWord) => {
              if (
                highlightWord.path.length > 0 &&
                highlightWord.path[0] === key &&
                highlightWord.value === String(value)
              ) {
                isHighlight = true;
              }
            }, filterHighlightWords);
            let title = null;
            let color = null;
            let fontColor = null;
            R.forEach((highlightWord) => {
              if (highlightWord.isKey) {
                if (R.toLower(key) === highlightWord.keyword) {
                  isHighlight = true;
                  title = highlightWord.title;
                  color = highlightWord.color;
                  fontColor = highlightWord.fontColor;
                }
              } else if (R.toLower(String(value)).indexOf(highlightWord.keyword) >= 0) {
                isHighlight = true;
                title = highlightWord.title;
                color = highlightWord.color;
                fontColor = highlightWord.fontColor;
              }
            }, allHighlightWords);
            html += isHighlight
              ? `${deepHtmlPrefix}${keyHtml}${keyHtmlPostfix}:&nbsp;<span style="background:${
                  color || 'yellow'
                };color:${fontColor || 'black'};padding: 0 2px" ${title ? `title="${title}"` : ''}>${value}</span><br>`
              : `${deepHtmlPrefix}${keyHtml}${keyHtmlPostfix}:&nbsp;${value}<br>`;
          } else {
            html += needDeepIn
              ? `${deepHtmlPrefix}${keyHtml}${keyHtmlPostfix}:&nbsp;<br>${jsonField(
                  value,
                  diffMap,
                  filterHighlightWords,
                  allHighlightWords,
                  currentDeep,
                )}`
              : `${deepHtmlPrefix}${keyHtml}${keyHtmlPostfix}:&nbsp;${isObject(value) ? '...' : value}<br>`;
          }
        }, val);
        return html;
      }
      return val;
    };

    return (
      <div
        className={`json-content ${hideExpanded || isExpanded ? 'expanded-json-content' : 'fold-json-content'}`}
        style={{ ...style, width }}
      >
        <div className={'content-main'} style={{ width: width - 24 }}>
          {cluster && (
            <div style={{ width: '100%', paddingTop: 4, paddingRight: 4, display: 'block', color: 'blue' }}>
              <span className="dlabel">Cluster:</span>
              <span className="log-meta" dangerouslySetInnerHTML={{ __html: cluster }} />
            </div>
          )}
          {hasDiff && (
            <div
              style={{
                width: '100%',
                paddingTop: 4,
                paddingRight: 4,
                display: 'block',
                borderBottom: '1px dashed gray',
              }}
            >
              <span className="dlabel">Diff</span>
            </div>
          )}
          <div className="fields-wrapper">
            {R.addIndex(R.map)(
              (n, idx) => {
                // Handle null in special way
                if (!R.isNil(json[n]) || (json[n] === null && highlightWord === 'null')) {
                  // filter only matched path
                  const filterHighlightWords = R.filter((highlightWord) => {
                    if (highlightWord.path.length > 0 && highlightWord.path[0] === n) {
                      return true;
                    }
                    return false;
                  }, highlightWords);

                  let html;
                  if (diffMap[n]) {
                    if (isObject(json[n])) {
                      html = jsonField(json[n], diffMap[n] || {}, filterHighlightWords, allHighlightWords);
                    } else {
                      html = `<span style="background:yellow;padding: 0 2px">${json[n]}</span>`;
                    }
                  } else if (isBoolean(json[n]) || isString(json[n]) || isNumber(json[n])) {
                    let isHighlight = false;
                    R.forEach((highlightWord) => {
                      if (
                        highlightWord.path.length > 0 &&
                        highlightWord.path[0] === n &&
                        highlightWord.value === String(json[n])
                      ) {
                        isHighlight = true;
                      }
                    }, filterHighlightWords);
                    let title = null;
                    let color = null;
                    let fontColor = null;
                    R.forEach((highlightWord) => {
                      if (highlightWord.isKey) {
                        if (R.toLower(n) === highlightWord.keyword) {
                          isHighlight = true;
                          title = highlightWord.title;
                          color = highlightWord.color;
                          fontColor = highlightWord.fontColor;
                        }
                      } else if (R.toLower(String(json[n])).indexOf(highlightWord.keyword) >= 0) {
                        isHighlight = true;
                        title = highlightWord.title;
                        color = highlightWord.color;
                        fontColor = highlightWord.fontColor;
                      }
                    }, allHighlightWords);
                    html = isHighlight
                      ? `<span style="background:${color || 'yellow'};color:${fontColor || 'black'};padding: 0 2px" ${
                          title ? `title="${title}"` : ''
                        }>${json[n]}</span>`
                      : jsonField(json[n], {}, filterHighlightWords, allHighlightWords);
                  } else {
                    html = jsonField(json[n], {}, filterHighlightWords, allHighlightWords);
                  }

                  let isHighlightKey = false;
                  let title = null;
                  let color = null;
                  let fontColor = null;
                  return (
                    <div
                      style={{
                        width: inLine ? width - 24 : (width - 24) / 2,
                        paddingRight: 4,
                        display: inLine ? 'block' : 'inline-block',
                      }}
                      key={idx}
                    >
                      <span className="dlabel" style={{ paddingRight: 4 }}>
                        <AutoSizer>
                          {({ height, width }) => (
                            <LimitedLengthText
                              text={n || ''}
                              maxLength={20}
                              height={height}
                              width={width}
                              isHighlightKey={isHighlightKey}
                              title={title}
                              color={color}
                              fontColor={fontColor}
                            />
                          )}
                        </AutoSizer>
                      </span>
                      <span className="log-meta" dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                  );
                }
                return null;
              },
              hideExpanded || isExpanded ? names : R.take(this.minFields, names),
            )}
          </div>
          {stacktrace && (
            <div>
              <span className="dlabel">StackTrace:</span>
            </div>
          )}
          {stacktrace && (
            <CollapsibleContent
              hideExpanded={hideExpanded}
              defaultExpanded={defaultExpanded}
              style={style}
              highlightWord={highlightWord}
              width={'100%'}
              ownerObject={ownerObject}
              content={stacktrace}
              onChanged={onChanged}
            />
          )}
        </div>
        {!hideExpanded && names.length > this.minFields && (
          <i
            className={`${isExpanded ? 'angle up' : 'angle down'} icon clickable`}
            style={{ verticalAlign: 'top', color: 'var(--text-color)', fontSize: 14, right: 16 }}
            onClick={this.handleEllipsisClicked}
          />
        )}
      </div>
    );
  }
}

export default CollapsibleJsonContent;
