/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import loadReportMetricLogic from './loadReportMetricLogic';

export default [loadReportMetricLogic];
