/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import moment from 'moment';
import { isFunction } from 'lodash';

import ReactECharts from 'echarts-for-react';

import '../../lib/echarts/theme/macarons';

type Props = {
  setRef: Function,
  width: ?Object,
  height: ?Object,
  style: Object,
  option: Object,
  renderer: String,
  locale: String,

  onClick: Function,
  onEvents: Function,

  theme: String,
};

class EChart extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {
      option: props.option,
      onEvents: {
        click: props.onClick,

        ...(props.onEvents || {}),
      },
    };
  }

  async componentDidMount() {
    if (this.echartsRef) {
      const echartsInstance = await this.echartsRef.getEchartsInstance();
      echartsInstance.resize();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.option !== this.props.option && nextProps.option) {
      if (this.echartsRef) {
        const echartsInstance = this.echartsRef.getEchartsInstance();
        // hide tip
        echartsInstance.dispatchAction({ type: 'hideTip' });

        // update option
        const startTsRender = moment.utc().valueOf();
        try {
          // TODO: use debounce to setOption
          echartsInstance.setOption(nextProps.option, { notMerge: true, lazyUpdate: true });
        } catch (err) {
          // console.error(String(err));
        }
        console.debug(`Echart render duration: ${(moment.utc().valueOf() - startTsRender) / 1000} sec`);
      }
    }
  }

  componentWillUnmount() {
    if (this.echartsRef) {
      const echartsInstance = this.echartsRef.getEchartsInstance();
      echartsInstance.clear();
    }
  }

  render() {
    const { setRef, width, height, style, renderer, locale, theme = 'light' } = this.props;
    const { option, onEvents } = this.state;
    const chartStyle = style || {};
    if (height) {
      chartStyle.height = height;
    }
    if (width) {
      chartStyle.width = width;
    }
    return (
      <ReactECharts
        ref={(chart) => {
          // store ref
          this.echartsRef = chart;

          // pass ref
          if (chart && isFunction(setRef)) setRef(chart);
        }}
        option={option}
        notMerge
        lazyUpdate
        theme={theme === 'light' ? 'macarons' : 'dark'}
        style={chartStyle}
        // onChartReady={this.onChartReadyCallback}
        onEvents={onEvents}
        opts={{ renderer: renderer || 'canvas', locale: locale || 'en' }}
      />
    );
  }
}

export default EChart;
