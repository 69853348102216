/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const parseData = (data, isMetric) => {
  let eventsAll = [];
  let clusterInfoList = [];
  let totalCount = 0;
  let highlightPages = [];
  if (isMetric) {
    const { projectNameList, ...metricInstanceObjs } = data;
    R.forEachObjIndexed((val, key) => {
      R.forEachObjIndexed((val1, key1) => {
        const { totalMatched, highlightPageSet, ...rest } = val1;
        totalCount += totalMatched || 0;
        highlightPages = R.concat(highlightPages, highlightPageSet || []);
        R.forEachObjIndexed((val2, key2) => {
          const { clusterInfo, events } = val2;
          clusterInfoList = R.concat(clusterInfoList, clusterInfo);
          eventsAll = R.concat(eventsAll, events);
        }, rest || {});
      }, val || {});
    }, metricInstanceObjs);
  } else {
    const { clusterInfo, events, totalMatched, highlightPageSet } = data;
    clusterInfoList = clusterInfo || [];
    eventsAll = events || [];
    totalCount = totalMatched || 0;
    highlightPages = highlightPageSet || [];

    eventsAll = R.map((event) => {
      const { timestamp, patternId, ...rest } = event;
      return {
        datetime: timestamp,
        timestamp,
        ...rest,
        nid: patternId,
      };
    }, eventsAll);
  }
  return {
    clusterInfoList,
    events: eventsAll,
    totalCount,
    highlightPages,
  };
};

const getLogContextEventList = (credentials: Credentials, params: Object) => {
  const {
    isMetric,
    projectName,
    instanceName,
    instanceNameList,
    consolidateFlag,
    timestamp,
    startTimestamp,
    endTimestamp,
    patternIds,
    nidToHighlight,
    pageNo,
    pageSize,
    keyword,
    reloadHighlight,
  } = params;

  const requests = [];
  if (reloadHighlight) {
    requests.push(
      fetchPost(getEndpoint('logfetchallevents'), {
        ...credentials,
        projectName,
        instanceName,
        instanceNameList,
        consolidateFlag,
        timestamp,
        startTime: startTimestamp,
        endTime: endTimestamp,
        nidList: patternIds,
        nidToHighlight,
        keyword,
        pageNo: 1,
        pageSize,
        onlyHighlightPage: true,
      }).then((data1) => {
        const { highlightPageSet } = data1;
        const highlightPageNo = (highlightPageSet || []).length > 0 ? highlightPageSet[0] : pageNo;
        return fetchPost(getEndpoint('logfetchallevents'), {
          ...credentials,
          projectName,
          instanceName,
          instanceNameList,
          consolidateFlag,
          timestamp,
          startTime: startTimestamp,
          endTime: endTimestamp,
          keyword,
          nidList: patternIds,
          nidToHighlight,
          pageNo: highlightPageNo,
          pageSize,
        }).then((data2) => {
          const result = parseData(data2, isMetric);
          return { ...result, resetPage: highlightPageNo };
        });
      }),
    );
  } else {
    requests.push(
      fetchPost(getEndpoint('logfetchallevents'), {
        ...credentials,
        projectName,
        instanceName,
        instanceNameList,
        consolidateFlag,
        timestamp,
        startTime: startTimestamp,
        endTime: endTimestamp,
        keyword,
        nidList: patternIds,
        nidToHighlight,
        pageNo,
        pageSize,
      }).then((data2) => {
        return parseData(data2, isMetric);
      }),
    );
  }

  return Promise.all(requests).then((results) => {
    const { events, clusterInfoList, totalCount, highlightPages, resetPage } = results[0];

    return {
      clusterInfoList,
      data: {
        events,
        totalCount,
        highlightPages,
        resetPage,
      },
    };
  });
};

export default getLogContextEventList;
