import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import { Checkbox, Divider, Popover, Select } from 'antd';
import moment from 'moment';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { AutoSizer, Column, Container, Modal, SortDirection, Table } from '../../../lib/fui/react';
import { EChart } from '../../share';
import { Defaults } from '../../../common/utils';

import { appFieldsMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';
import { getInstanceDisplayNameFn } from '../utils';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

const typeMap = {
  CPUav: 'Average CPU',
  MEMav: 'Average MEM',
  Storageav: 'Average Storage',
  CPUminv: 'Minimum CPU',
  MEMminv: 'Minimum MEM',
  Storageminv: 'Minimum Storage',
  CPUmaxv: 'Maximum CPU',
  MEMmaxv: 'Maximum MEM',
  Storagemaxv: 'Maximum Storage',
};

export default function OverandunderDetail({
  intl,
  barData,
  typeList,
  getViewData,
  floatFixed,
  dailyProvision,
  currentTheme,
  onCancel,
  instanceDisplayNameMap,
}: Object) {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    list: [],
    sortBy: null,
    sortDirection: null,
    allInstanceOptions: [],
    allComponentOptions: [],
    searchInstance: [],
    searchComponent: [],
    searchValue: '',
  });
  const {
    list,
    sortBy,
    sortDirection,
    allInstanceOptions,
    allComponentOptions,
    searchInstance,
    searchComponent,
    searchValue,
  } = state;
  const dataTableNode = useRef(null);

  const buildChartOption = ({ instanceName, type, dataKey }) => {
    const dailys = R.keys(dailyProvision || {});
    const key = `${instanceName}_${type}`;

    let dailyTypeData = [];
    let columnMaxValue = 0;
    R.forEach((daily) => {
      const time = Number(daily);
      const findItem = R.find((only) => `${only.in}_${only.pm}` === key, dailyProvision[daily]) || {};
      dailyTypeData.push({
        ...findItem,
        pm: findItem.pm || type,
        dailyTamp: time,
        daily: moment.utc(time).format(Defaults.ShortDayFormat),
        dataKey,
      });
      dailyTypeData = R.sortWith([R.ascend(R.prop('dailyTamp'))], dailyTypeData);
      columnMaxValue = R.max(columnMaxValue, findItem[dataKey] || 0);
    }, dailys || []);

    const option = {
      backgroundColor: 'transparent',
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          const { data, name } = params[0] || {};
          const { type, dataKey, value } = data || {};
          return ReactDOMServer.renderToStaticMarkup(
            <div style={{ fontSize: 12, fontWeight: 'bold', maxWidth: 500 }}>
              <div>
                <span style={{ color: 'var(--light-label)', fontSize: 14 }}>{name}</span>
              </div>
              <div>
                <span style={{ color: 'var(--light-label)' }}>{`${typeMap[`${type}${dataKey}`]} Usage: `}</span>
                <span
                  style={{
                    fontSize: 16,
                    color: value >= 0.9 ? '#9e2b0e' : value < 0.9 && value > 0.2 ? '#b7b7b7' : '#6abbf7',
                  }}
                >{`${getViewData(floatFixed(value * 100))}%`}</span>
              </div>
            </div>,
          );
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      grid: {
        top: 24,
        left: 0,
        right: 0,
        bottom: 24,
      },
      xAxis: {
        show: false,
        type: 'category',
        data: R.map((item) => item.daily, dailyTypeData),
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: {
          fontSize: 10,
          overflow: 'break',
          formatter: () => null,
        },
      },
      yAxis: {
        show: false,
        type: 'value',
        max: 1,
        axisLabel: {
          fontSize: 10,
          overflow: 'break',
          formatter: (value) => `${getViewData(floatFixed(value * 100))}%`,
        },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [
        {
          type: 'bar',
          name: 'OverandunderDetail',
          barWidth: '95%',
          barCategoryGap: '0%',
          data: R.map((item) => {
            return {
              value: item[dataKey],
              type: item.pm,
              dataKey: item.dataKey,
            };
          }, dailyTypeData),
          itemStyle: {
            color: (item) => {
              return item.value >= 0.9 ? '#9e2b0e' : item.value < 0.9 && item.value > 0.2 ? '#b7b7b7' : '#6abbf7';
            },
            borderWidth: 0,
            borderRadius: [2, 2, 0, 0],
          },
        },
      ],
    };
    return { option, columnMaxValue };
  };

  const parseData = (provisioning) => {
    const data = provisioning || [];

    // 取出所有的 instanceName
    const instanceNameList = [];
    R.forEach((item) => {
      const { instanceName } = item;
      if (!R.includes(instanceName, instanceNameList)) {
        instanceNameList.push(instanceName);
      }
    }, data);

    // 根据instanceName创建对象，把type插入进去
    const instanceNameObj = {};
    R.forEach((item) => {
      const itemList = [];
      R.forEach((_item) => {
        const { type } = _item;
        itemList.push({ type });
      }, typeList);
      instanceNameObj[item] = itemList;
    }, instanceNameList);

    // 把每个instanceName合成一个
    const instanceNameData = [];
    R.forEachObjIndexed((val, key) => {
      const obj = {};
      R.forEach((item) => {
        const { type } = item;
        const findItem = R.find((only) => only.instanceName === key && only.provisionMetric === type, data) || {};
        const { componentName, instanceName, projectSet } = findItem;
        if (!obj.componentName) {
          obj.componentName = componentName;
        }
        if (!obj.instanceName) {
          obj.instanceName = instanceName;
        }
        if (!obj.projectSet) {
          obj.projectSet = projectSet;
        }
        if (type === 'CPU') {
          const avgValueCPU = buildChartOption({ instanceName: key, type, dataKey: 'av' });
          obj.avgValueCPUOption = avgValueCPU.option;
          obj.avgValueCPUMax = avgValueCPU.columnMaxValue;
          const minValueCPU = buildChartOption({ instanceName: key, type, dataKey: 'minv' });
          obj.minValueCPUOption = minValueCPU.option;
          obj.minValueCPUMax = minValueCPU.columnMaxValue;
          const maxValueCPU = buildChartOption({ instanceName: key, type, dataKey: 'maxv' });
          obj.maxValueCPUOption = maxValueCPU.option;
          obj.maxValueCPUMax = maxValueCPU.columnMaxValue;
        }
        if (type === 'MEM') {
          const avgValueMEM = buildChartOption({ instanceName: key, type, dataKey: 'av' });
          obj.avgValueMEMOption = avgValueMEM.option;
          obj.avgValueMEMMax = avgValueMEM.columnMaxValue;
          const minValueMEM = buildChartOption({ instanceName: key, type, dataKey: 'minv' });
          obj.minValueMEMOption = minValueMEM.option;
          obj.minValueMEMMax = minValueMEM.columnMaxValue;
          const maxValueMEM = buildChartOption({ instanceName: key, type, dataKey: 'maxv' });
          obj.maxValueMEMOption = maxValueMEM.option;
          obj.maxValueMEMMax = maxValueMEM.columnMaxValue;
        }
        if (type === 'Storage') {
          const avgValueStorage = buildChartOption({ instanceName: key, type, dataKey: 'av' });
          obj.avgValueStorageOption = avgValueStorage.option;
          obj.avgValueStorageMax = avgValueStorage.columnMaxValue;
          const minValueStorage = buildChartOption({ instanceName: key, type, dataKey: 'minv' });
          obj.minValueStorageOption = minValueStorage.option;
          obj.minValueStorageMax = minValueStorage.columnMaxValue;
          const maxValueStorage = buildChartOption({ instanceName: key, type, dataKey: 'maxv' });
          obj.maxValueStorageOption = maxValueStorage.option;
          obj.maxValueStorageMax = maxValueStorage.columnMaxValue;
        }
      }, val || []);
      instanceNameData.push(obj);
    }, instanceNameObj);

    setState({ list: instanceNameData });
  };

  const getInstanceOptions = (provisioning) => {
    const data = provisioning || [];
    let instanceOptions = [];
    let componentOptions = [];
    R.forEach((item) => {
      if (item.instanceName) {
        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item.instanceName);
        instanceOptions.push({
          label: instanceStr,
          value: item.instanceName,
        });
      }
      if (item.componentName) componentOptions.push({ label: item.componentName, value: item.componentName });
    }, data);
    instanceOptions = R.uniqBy((item) => item.value, instanceOptions);
    componentOptions = R.uniqBy((item) => item.value, componentOptions);
    instanceOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('label')))], instanceOptions);
    componentOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('label')))], componentOptions);
    setState({ allInstanceOptions: instanceOptions, allComponentOptions: componentOptions });
  };

  useEffect(() => {
    getInstanceOptions(barData);
    parseData(barData);
  }, []);

  const filterBarData = (filterInstances, filterComponents) => {
    let data = barData || [];

    if (filterInstances.length > 0) {
      data = R.filter((item) => R.includes(item.instanceName || '', filterInstances), data);
    }

    if (filterComponents.length > 0) {
      data = R.filter((item) => R.includes(item.componentName || '', filterComponents), data);
    }

    return data;
  };

  const handleSearchInstace = (searchInstance) => {
    setState({ searchInstance, sortBy: null, sortDirection: null });
    const data = filterBarData(searchInstance, searchComponent);
    parseData(data);
  };

  const handleSearchComponent = (searchComponent) => {
    setState({ searchComponent, sortBy: null, sortDirection: null });
    const data = filterBarData(searchInstance, searchComponent);
    parseData(data);
  };

  useEffect(() => {
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        setState({ list: R.sortWith([R.descend(R.prop(sortBy))])(list) });
      } else {
        setState({ list: R.sortWith([R.ascend(R.prop(sortBy))])(list) });
      }
      if (dataTableNode.current) {
        dataTableNode.current.forceUpdate();
      }
    }
  }, [sortBy, sortDirection]);

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ dataKey, disableSort, label, sortBy, sortDirection, customize }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className="flex-row flex-center-align" style={{ ...(customize ? {} : { marginTop: 20 }) }}>
        <span>{label}</span>
        <span>{!disableSort && sortIcon()}</span>
      </div>
    );
  };

  const handleLabel = ({ label }) => {
    let columnKeys = [
      { dataKey: 'minValueCPUMax', label: 'Min' },
      { dataKey: 'avgValueCPUMax', label: 'Avg' },
      { dataKey: 'maxValueCPUMax', label: 'Max' },
    ];
    if (label === 'Memory') {
      columnKeys = [
        { dataKey: 'minValueMEMMax', label: 'Min' },
        { dataKey: 'avgValueMEMMax', label: 'Avg' },
        { dataKey: 'maxValueMEMMax', label: 'Max' },
      ];
    } else if (label === 'Storage') {
      columnKeys = [
        { dataKey: 'minValueStorageMax', label: 'Min' },
        { dataKey: 'avgValueStorageMax', label: 'Avg' },
        { dataKey: 'maxValueStorageMax', label: 'Max' },
      ];
    }
    return (
      <div>
        <div
          className="text-center"
          style={{ background: currentTheme === 'dark' ? '#3e3e3e' : '#e3e3e5', marginBottom: 4, padding: '2px 0' }}
        >
          {label}
        </div>
        <div className="flex-row flex-space-around">
          {R.map(
            (item) => (
              <div
                key={item.dataKey}
                className="clickable"
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setState({ sortBy: item.dataKey, sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC' });
                }}
              >
                {headerRenderer({ label: item.label, dataKey: item.dataKey, sortBy, sortDirection, customize: true })}
              </div>
            ),
            columnKeys,
          )}
        </div>
      </div>
    );
  };

  const utilizedNumRender = ({ dataKey, rowData }) => {
    let columnKeys = ['minValueCPUOption', 'avgValueCPUOption', 'maxValueCPUOption'];
    if (dataKey === 'MEM') {
      columnKeys = ['minValueMEMOption', 'avgValueMEMOption', 'maxValueMEMOption'];
    } else if (dataKey === 'Storage') {
      columnKeys = ['minValueStorageOption', 'avgValueStorageOption', 'maxValueStorageOption'];
    }
    return (
      <div className="flex-row flex-space-around">
        {R.map((item) => {
          const dataLength = (rowData[item]?.series || [])[0]?.data?.length || 0;
          const echartW = dataLength * 15 < 80 ? `${dataLength * 15}%` : '80%';
          return (
            <div className="flex-grow flex-row flex-center-justify">
              <EChart
                key={item}
                width={echartW}
                height="100%"
                option={rowData[item]}
                onEvents={{}}
                theme={currentTheme}
              />
            </div>
          );
        }, columnKeys)}
      </div>
    );
  };

  const instanceNameRender = ({ rowData }) => {
    const { instanceName, projectSet, componentName } = rowData;

    const { instanceDisplayName, width, viewContent, onlyInstanceName } = getInstanceDisplayNameFn({
      instanceDisplayNameMap,
      instanceName,
      intl,
      style: { color: 'var(--light-label)', display: 'inline-block' },
    });

    return (
      <Popover
        title={null}
        content={
          <div style={{ fontSize: 12, fontWeight: 'bold', maxWidth: 500 }}>
            <div>
              <span style={{ color: 'var(--light-label)', width, display: 'inline-block' }}>
                {intl.formatMessage(eventActionMessages.componentName)}:{' '}
              </span>
              <span>{componentName}</span>
            </div>
            {viewContent}
            <div>
              <span style={{ color: 'var(--light-label)', width, display: 'inline-block' }}>
                {intl.formatMessage(eventMessages.projectName)}:{' '}
              </span>
              <span>{(projectSet || []).join()}</span>
            </div>
          </div>
        }
        mouseEnterDelay={0.3}
        arrowPointAtCenter={false}
      >
        <div>
          <div className="hidden-line-with-ellipsis clickable" style={{ wordWrap: 'break-word' }}>
            {componentName}
          </div>
          <div className="hidden-line-with-ellipsis clickable" style={{ wordWrap: 'break-word', marginTop: 4 }}>
            {instanceDisplayName || onlyInstanceName}
          </div>
        </div>
      </Popover>
    );
  };

  const componentNameRender = ({ rowData }) => {
    const { componentName } = rowData;
    return (
      <Popover
        title={null}
        content={
          <div style={{ fontSize: 12, fontWeight: 'bold', maxWidth: 500 }}>
            <div>
              <span style={{ color: 'var(--light-label)' }}>
                {intl.formatMessage(eventActionMessages.componentName)}:{' '}
              </span>
              <span>{componentName}</span>
            </div>
          </div>
        }
        mouseEnterDelay={0.3}
        arrowPointAtCenter={false}
      >
        <span className="hidden-line-with-ellipsis clickable" style={{ wordWrap: 'break-word' }}>
          {componentName}
        </span>
      </Popover>
    );
  };

  const getListOptionValues = (option) => {
    return R.map((item) => item.value, option);
  };

  const getFilterValue = ({ searchValue, options, type = 'label' }) => {
    return R.filter(
      (item) =>
        (item[type] || '').toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1 ||
        (item.value || '').toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1,
      options,
    );
  };

  const isCheckedAll = ({ searchValue, options, valueList }) => {
    const filterValue = getListOptionValues(getFilterValue({ searchValue, options }));
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  };

  return (
    <Modal
      title="Utilized hosts(Over and under)"
      width={1300}
      visible
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      className="dash-cell-detail-modal"
    >
      <Container className="full-width full-height flex-space-between">
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <div className="flex-row flex-center-align">
            <span style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.component)}:</span>
            <Select
              allowClear
              showSearch
              mode="multiple"
              maxTagCount={1}
              value={searchComponent}
              style={{ width: 250 }}
              autoClearSearchValue={false}
              onChange={handleSearchComponent}
              dropdownMatchSelectWidth={250}
              optionFilterProp="children"
              onSearch={(searchValue) => setState({ searchValue })}
              onDropdownVisibleChange={(open) => {
                if (!open) {
                  setState({ searchValue: '' });
                }
              }}
              dropdownRender={(menu) => {
                return (
                  <div>
                    <div
                      className="flex-row"
                      style={{ padding: '5px 12px' }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox
                        size="small"
                        style={{ marginRight: 8 }}
                        checked={isCheckedAll({
                          searchValue,
                          options: allComponentOptions,
                          valueList: searchComponent,
                        })}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const filterValue = getListOptionValues(
                            getFilterValue({ searchValue, options: allComponentOptions }),
                          );
                          const diff = R.difference(searchComponent, filterValue);
                          const newSearchComponent = checked ? [...diff, ...filterValue] : diff;
                          setState({ searchComponent: newSearchComponent, sortBy: null, sortDirection: null });
                          const data = filterBarData(searchInstance, newSearchComponent);
                          parseData(data);
                        }}
                      />
                      <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                    </div>
                    <Divider style={{ margin: '4px 0' }} />
                    {menu}
                  </div>
                );
              }}
            >
              {R.map((item) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    <Checkbox
                      size="small"
                      checked={searchComponent.includes(item.value)}
                      onChange={(e) => null}
                      style={{ marginRight: 8 }}
                    />
                    {item.label}
                  </Select.Option>
                );
              }, allComponentOptions)}
            </Select>
          </div>
          <div className="flex-row flex-center-align" style={{ marginLeft: 8 }}>
            <span style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.instance)}:</span>
            <Select
              allowClear
              showSearch
              mode="multiple"
              maxTagCount={1}
              value={searchInstance}
              style={{ width: 250 }}
              autoClearSearchValue={false}
              onChange={handleSearchInstace}
              dropdownMatchSelectWidth={250}
              optionFilterProp="children"
              onSearch={(searchValue) => setState({ searchValue })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onDropdownVisibleChange={(open) => {
                if (!open) {
                  setState({ searchValue: '' });
                }
              }}
              dropdownRender={(menu) => {
                return (
                  <div>
                    <div
                      className="flex-row"
                      style={{ padding: '5px 12px' }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox
                        size="small"
                        style={{ marginRight: 8 }}
                        checked={isCheckedAll({ searchValue, options: allInstanceOptions, valueList: searchInstance })}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const filterValue = getListOptionValues(
                            getFilterValue({ searchValue, options: allInstanceOptions }),
                          );
                          const diff = R.difference(searchInstance, filterValue);
                          const newSearchInstance = checked ? [...diff, ...filterValue] : diff;
                          setState({ searchInstance: newSearchInstance, sortBy: null, sortDirection: null });
                          const data = filterBarData(newSearchInstance, searchComponent);
                          parseData(data);
                        }}
                      />
                      <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                    </div>
                    <Divider style={{ margin: '4px 0' }} />
                    {menu}
                  </div>
                );
              }}
            >
              {R.map((item) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    <Checkbox
                      size="small"
                      checked={searchInstance.includes(item.value)}
                      onChange={(e) => null}
                      style={{ marginRight: 8 }}
                    />
                    {item.label}
                  </Select.Option>
                );
              }, allInstanceOptions)}
            </Select>
          </div>
        </div>
        <div className="cell-detail-wrapper flex-row flex-space-between" style={{ width: '100%', height: 600 }}>
          <AutoSizer>
            {({ height, width }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                headerHeight={60}
                overscanRowCount={2}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={80}
                rowCount={list.length}
                rowGetter={({ index }) => list[index]}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                ref={dataTableNode}
              >
                <Column
                  width={130}
                  label={`${intl.formatMessage(eventActionMessages.componentName)}/${intl.formatMessage(
                    appFieldsMessages.instance,
                  )}`}
                  dataKey="instanceName"
                  headerRenderer={headerRenderer}
                  cellRenderer={instanceNameRender}
                />
                {false && (
                  <Column
                    width={120}
                    label={intl.formatMessage(eventActionMessages.componentName)}
                    dataKey="componentName"
                    headerRenderer={headerRenderer}
                    cellRenderer={componentNameRender}
                  />
                )}
                <Column
                  width={56}
                  disableSort
                  label="CPU"
                  flexGrow={1}
                  dataKey="CPU"
                  cellRenderer={utilizedNumRender}
                  headerRenderer={handleLabel}
                  headerClassName="full-width"
                />
                <Column
                  width={56}
                  disableSort
                  label="Memory"
                  flexGrow={1}
                  dataKey="MEM"
                  cellRenderer={utilizedNumRender}
                  headerRenderer={handleLabel}
                  headerClassName="full-width"
                />
                <Column
                  width={56}
                  disableSort
                  label="Storage"
                  flexGrow={1}
                  dataKey="Storage"
                  cellRenderer={utilizedNumRender}
                  headerRenderer={handleLabel}
                  headerClassName="full-width"
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </Container>
    </Modal>
  );
}
