import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isNumber } from 'lodash';
import { SelectOutlined } from '@ant-design/icons';
import { Button, Popover, Divider } from 'antd';

import { ifIn, Defaults, CausalParser, highlightContent, LogRenderers, EventRenderers } from '.';
import { Tooltip } from '../../lib/fui/react';
import { CollapsibleLogContent } from '../../web/share';
import { appFieldsMessages } from '../app/messages';
import { eventMessages } from '../metric/messages';
import causalMessages from '../causal/messages/causalMessages';

const CausalGetMetricLegend = (content, isIncident, isAlert) => {
  let label = 'M';
  if (isIncident) {
    label = 'Incident';
  } else if (isAlert) {
    label = 'Alert';
  }

  const color = isIncident || isAlert ? 'red' : '#767676';
  return (
    <Tooltip
      title={
        <div style={{ maxWidth: 200, wordBreak: 'break-all', display: 'inline-block' }}>
          {isIncident || isAlert ? label : content}
        </div>
      }
      placement="top"
    >
      <span
        className="anomaly-event-legend"
        style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
      >
        {label}
      </span>
    </Tooltip>
  );
};

const CausalGetLogTypeLegend = (type) => {
  const { typeOnly, typeShort } = CausalParser.getRelationLogType(type);
  return (
    <Tooltip
      title={
        <div style={{ maxWidth: 200, wordBreak: 'break-all', display: 'inline-block', marginRight: 4 }}>{typeOnly}</div>
      }
      placement="top"
    >
      <div className={`legend-item ${(typeOnly || '').toLowerCase()}`}>{typeShort}</div>
    </Tooltip>
  );
};

// Causal component renderers
const CausalRenderers = {
  RenderRelationInfo: ({
    intl,
    relationInfo,
    index,
    relationTimeThreshold,
    intraInstanceList,
    handleInstanceClick,
    showRelationClick,
    onRelationClick,
  }: Object) => {
    const { probability, count, appName1, appName2, elem1, elem2 } = relationInfo;
    const fromHasIntra = ifIn(elem1, intraInstanceList || []);
    const toHasIntra = ifIn(elem2, intraInstanceList || []);
    const prob = (probability * 100).toFixed(1);
    return (
      <div key={index} style={{ padding: '4px 0', fontSize: 12, borderBottom: '1px dashed gray' }}>
        {showRelationClick && (
          <Tooltip
            title={intl.formatMessage(causalMessages.clickHightlight)}
            placement="top"
            style={{
              display: 'inline-block',
              padding: '0 4px 0 0',
              cursor: 'pointer',
              color: 'blue',
            }}
          >
            <div onClick={() => onRelationClick(relationInfo)}>
              <SelectOutlined />
            </div>
          </Tooltip>
        )}
        {count}
        {` ${intl.formatMessage(causalMessages.relationInfoStep1)} `}
        <b
          style={fromHasIntra ? { cursor: 'pointer', color: 'blue' } : {}}
          onClick={fromHasIntra ? handleInstanceClick(elem1) : null}
        >{`${fromHasIntra ? '*' : ''}${appName1}`}</b>
        {` ${intl.formatMessage(causalMessages.relationInfoStep2, { relationTimeThreshold })} `}
        <b
          style={toHasIntra ? { cursor: 'pointer', color: 'blue' } : {}}
          onClick={toHasIntra ? handleInstanceClick(elem2) : null}
        >{`${toHasIntra ? '*' : ''}${appName2}`}</b>
        {` ${intl.formatMessage(causalMessages.relationInfoStep4)} `}
        {`${prob}%`}
      </div>
    );
  },
  RenderConcurrentInfo: ({
    intl,
    relationInfo,
    index,
    intraInstanceList,
    handleInstanceClick,
    showRelationClick,
    onRelationClick,
  }: Object) => {
    const { probability, count, appName1, appName2, elem1, elem2 } = relationInfo;
    const fromHasIntra = ifIn(elem1, intraInstanceList || []);
    const toHasIntra = ifIn(elem2, intraInstanceList || []);
    const prob = (probability * 100).toFixed(1);
    return (
      <div key={index} style={{ padding: '4px 0', fontSize: 12, borderBottom: '1px dashed gray' }}>
        {showRelationClick && (
          <Tooltip
            title={intl.formatMessage(causalMessages.clickHightlight)}
            placement="top"
            style={{
              display: 'inline-block',
              padding: '0 4px 0 0',
              cursor: 'pointer',
              color: 'blue',
            }}
          >
            <div onClick={() => onRelationClick(relationInfo)}>
              <SelectOutlined />
            </div>
          </Tooltip>
        )}
        {count}
        {` ${intl.formatMessage(causalMessages.relationInfoStep1)} `}
        <b
          style={fromHasIntra ? { cursor: 'pointer', color: 'blue' } : {}}
          onClick={fromHasIntra ? handleInstanceClick(elem1) : null}
        >{`${fromHasIntra ? '*' : ''}${appName1}`}</b>
        {` ${intl.formatMessage(causalMessages.relationInfoStep3)} `}
        <b
          style={toHasIntra ? { cursor: 'pointer', color: 'blue' } : {}}
          onClick={toHasIntra ? handleInstanceClick(elem2) : null}
        >{`${toHasIntra ? '*' : ''}${appName2}`}</b>
        {` ${intl.formatMessage(causalMessages.relationInfoStep4)} `}
        {`${prob}%`}
      </div>
    );
  },
  EventContentRenderer: ({
    intl,
    props,
    options,
    customerName,
    credentials,
    projectDisplayMap,
    currentTheme,
    metricUnitMap,
  }: Object) => {
    const { style, cellData } = props;
    const { keyword } = options;
    const {
      instanceDown,
      isAlert,
      projectName,
      containerId,
      nid,
      type,
      timeStamp,
      content,
      logContentList,
      metricDirection,
      hostName,
    } = cellData;
    let { patternName } = cellData;
    if (patternName && patternName.length > 25) patternName = `${R.take(60, patternName)}...`;
    const { typeOnly: dtype } = CausalParser.getRelationLogType(type);
    const projectNameReal = customerName !== credentials.userName ? `${projectName}@${customerName}` : projectName;
    const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);

    // render instance down
    if (instanceDown) {
      return (
        <div className="flex-row flex-center-align" style={{ ...style, width: '100%' }}>
          <div style={{ marginRight: 4 }}>{CausalGetMetricLegend(content, dtype === 'Incident', isAlert)}</div>
          <div className="flex-grow flex-min-width" style={{ padding: '0px 0' }}>
            <span>{intl.formatMessage(appFieldsMessages.missingData)}</span>
            <i className="icon down arrow" style={{ color: 'red' }} />
          </div>
        </div>
      );
    }

    // render metric
    if (dtype === 'Metric' || (dtype === 'Incident' && Boolean(metricDirection))) {
      return (
        <div className="flex-row flex-center-align" style={{ ...style, width: '100%' }}>
          <div style={{ marginRight: 4 }}>{CausalGetMetricLegend(content, dtype === 'Incident', isAlert)}</div>
          <div className="flex-grow flex-min-width white-pre" style={{ padding: '4px 0' }}>
            <div className="flex-col">
              <div className="flex-row flex-center-align">
                <div className="light-label bold" style={{ width: 65 }}>
                  {intl.formatMessage(eventMessages.patternId)}:
                </div>
                <div className="flex-grow hidden-line-with-ellipsis">{nid}</div>
              </div>
              <div className="flex-row flex-center-align">
                <div className="light-label bold" style={{ width: 65 }}>
                  {intl.formatMessage(appFieldsMessages.project)}:
                </div>
                <Popover title={null} content={projectDisplayName} placement="right" mouseEnterDelay={0.3}>
                  <div className="flex-grow hidden-line-with-ellipsis">{projectDisplayName}</div>
                </Popover>
              </div>
              {hostName && (
                <div className="flex-row flex-center-align">
                  <div className="light-label bold" style={{ width: 65 }}>
                    {intl.formatMessage(appFieldsMessages.instance)}:
                  </div>
                  <Popover title={null} content={hostName} placement="right" mouseEnterDelay={0.3}>
                    <div className="flex-grow hidden-line-with-ellipsis">{hostName}</div>
                  </Popover>
                </div>
              )}
              {containerId && (
                <div className="flex-row flex-center-align">
                  <div className="light-label bold" style={{ width: 65 }}>
                    {intl.formatMessage(appFieldsMessages.container)}:
                  </div>
                  <Popover title={null} content={containerId} placement="right" mouseEnterDelay={0.3}>
                    <div className="flex-grow hidden-line-with-ellipsis">{containerId}</div>
                  </Popover>
                </div>
              )}
              <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                {EventRenderers.RenderMetricAnomalySummary({ intl, event: cellData, metricUnitMap })}
              </div>
            </div>
          </div>
        </div>
      );
    }

    // render log
    const eventList = logContentList || [[timeStamp, content || '']];
    return (
      <div style={{ ...style, width: '100%' }}>
        <div className="white-pre" style={{ padding: '4px 0px' }}>
          <div className="flex-row" style={{ marginBottom: 4, alignItems: 'center' }}>
            <div style={{ marginRight: 4 }}>{CausalGetLogTypeLegend(type)}</div>
            <div className="flex-grow">
              {isNumber(nid) && (
                <span className="light-label bold">{`${intl.formatMessage(eventMessages.patternId)}: ${nid}`}</span>
              )}
              {hostName && (
                <div className="flex-row flex-center-align">
                  <div className="light-label bold" style={{ width: 65 }}>
                    {intl.formatMessage(appFieldsMessages.instance)}:
                  </div>
                  <Popover title={null} content={hostName} placement="right" mouseEnterDelay={0.3}>
                    <div className="flex-grow hidden-line-with-ellipsis">{hostName}</div>
                  </Popover>
                </div>
              )}
              <div style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                {highlightContent(`${R.take(60, eventList[0][1])}...`, keyword)}
                <Popover
                  placement="right"
                  content={
                    <div className="flex-col overflow-y-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
                      {isNumber(nid) && (
                        <span style={{ color: 'lightcoral' }}>{`${intl.formatMessage(
                          eventMessages.patternId,
                        )}: ${nid}`}</span>
                      )}
                      {patternName && (
                        <span style={{ color: 'lightcoral' }}>{`${intl.formatMessage(
                          eventMessages.patternName,
                        )}: ${patternName}`}</span>
                      )}
                      {projectDisplayName && (
                        <span style={{ color: 'lightcoral' }}>{`${intl.formatMessage(
                          eventMessages.projectName,
                        )}: ${projectDisplayName}`}</span>
                      )}
                      <Divider style={{ margin: '12px 0' }} />

                      {R.addIndex(R.map)((rowData, index) => {
                        let rawDataJson;
                        try {
                          rawDataJson = JSON.parse(rowData[1]);
                        } catch (error) {
                          // console.debug(error);
                        }
                        return (
                          <div key={index} style={{ marginBottom: 16, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                            <div className="light-label bold">
                              {moment.utc(rowData[0]).format(Defaults.DateTimeFormat)}:
                            </div>
                            {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                            {!rawDataJson && (
                              <CollapsibleLogContent
                                ownerObject={cellData}
                                message={rowData[1]}
                                width={400}
                                highlightWord={keyword}
                              />
                            )}
                          </div>
                        );
                      }, eventList)}
                    </div>
                  }
                  trigger="click"
                >
                  <Button
                    type="link"
                    style={{
                      padding: 0,
                      height: 'auto',
                    }}
                  >
                    {intl.formatMessage(appFieldsMessages.expand)}
                  </Button>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export { CausalRenderers };
