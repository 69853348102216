import React, { useEffect, useReducer, useRef, useState } from 'react';
import * as R from 'ramda';
import tinygradient from 'tinygradient';

import { Button, message } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import BaseUrls from '../../app/BaseUrls';
import { Column, Container, Modal, Popover, SortDirection, Table } from '../../../lib/fui/react';
import { EChart } from '../../share';
import { buildUrl, parseLocation } from '../../../common/utils';
import { anomalousOptionHtml, anomalousPopoverHtml } from './OptionHtml';

import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

export default function HorizontalchartDetail({
  intl,
  barData,
  onCancel,
  typeName,
  titleDistinguish,
  nameDistinguish,
  isCost = false,
  currentTheme,
  environmentId,
  startTime,
  endTime,
  customerName,
  systemId,
  location,
  instanceDisplayNameMap,
}: Object) {
  const query = parseLocation(location);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    list: barData || [],
    categoryList: R.map(
      (item) =>
        nameDistinguish[typeName] === 'projectName'
          ? item.projectDisplayName || item[nameDistinguish[typeName]]
          : item[nameDistinguish[typeName]],
      barData || [],
    ),
    viewTypeName:
      nameDistinguish[typeName] === 'componentName'
        ? isCost
          ? 'Accounts name'
          : intl.formatMessage(eventActionMessages.componentName)
        : nameDistinguish[typeName] === 'instanceName'
        ? isCost
          ? 'Services name'
          : intl.formatMessage(appFieldsMessages.instanceName)
        : intl.formatMessage(eventMessages.projectName),
    sortBy: null,
    sortDirection: null,
  });
  const { list, categoryList, viewTypeName, sortBy, sortDirection } = state;
  const dataTableNode = useRef(null);
  const echartRef = useRef(null);

  useEffect(() => {
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        setState({ list: R.sortWith([R.descend(R.prop(sortBy))])(list) });
      } else {
        setState({ list: R.sortWith([R.ascend(R.prop(sortBy))])(list) });
      }
      if (dataTableNode.current) {
        dataTableNode.current.forceUpdate();
      }
    }
  }, [sortBy, sortDirection]);

  const jumpToInvestigation = (rowData) => {
    if (query?.saveFlag === 'true') {
      message.warning(intl.formatMessage(eventMessages.notSaveDashboardMessage));
      return;
    }

    let data = {};
    if (R.includes('-QvQ-', rowData)) {
      const [componentName, instanceName] = R.split('-QvQ-', rowData);
      data = { componentName, instanceName };
    } else {
      data = rowData;
    }

    const { componentName, instanceName } = data;
    const jumpParams = {
      // set redirect flag
      redirect: true,

      environmentId,
      startTime,
      endTime,
      customerName,
      systemId,

      jumpComponentName: componentName,
      jumpInstanceName: instanceName,
    };

    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, jumpParams), '_blank');
  };

  useEffect(() => {
    window.dashJumpToInvestigation = jumpToInvestigation;
    return () => {
      window.dashJumpToInvestigation = undefined;
    };
  }, []);

  const getPieOption = (data, category, gradientColorsList) => {
    const gradient = tinygradient(gradientColorsList);
    const colorsRgb = gradient.rgb(data.length < 2 ? 2 : data.length);
    const colors = R.map((x) => {
      return x.toHexString();
    }, colorsRgb);
    const option = {
      backgroundColor: 'transparent',
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'item',
        enterable: true,
        appendToBody: true,
        position: (pos, params, dom, rect, size) => {
          const boxHeight = size.contentSize[1];
          const pointX = pos[0] + 10;
          const pointY = pos[1] - boxHeight - 10;
          return [pointX, pointY];
        },
        formatter: (params, ticket, callback) => {
          const { data } = params;
          return anomalousOptionHtml({
            data,
            intl,
            typeName: nameDistinguish[typeName],
            isCost,
            viewBotton: true,
            instanceDisplayNameMap,
          });
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      xAxis: {
        type: 'value',
        splitArea: {
          show: false,
        },
        axisTick: {
          show: false,
          inside: true,
        },
        axisLabel: {
          show: false,
        },
        splitLine: { show: false },
      },
      yAxis: {
        type: 'category',
        inverse: true,
        data: category,
        axisTick: {
          show: false,
          inside: true,
        },
        axisLabel: {
          fontSize: 10,
          width: '240',
          overflow: 'truncate',
          formatter: (value) => {
            const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, value);
            return instanceStr;
          },
        },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [
        {
          name: titleDistinguish[typeName],
          type: 'bar',
          data,
          barMaxWidth: '30px',
          itemStyle: {
            color: (item) => {
              const { dataIndex } = item;
              return colors[dataIndex];
            },
          },
          emphasis: {
            itemStyle: {
              color: '#097fd7',
              borderWidth: 0,
            },
          },
        },
      ],
      grid: {
        top: 30,
        left: 248,
        right: '12%',
        bottom: 10,
      },
      label: {
        show: true,
        position: 'right',
        formatter: (params) => {
          const { data } = params;
          let scoreVla = data.value;
          if (isCost) {
            return `$ ${Number(Number.parseFloat(scoreVla).toFixed(2))}`;
          }
          if (scoreVla >= 1000000) {
            scoreVla = `${Number(Number.parseFloat(scoreVla / 1000000).toFixed(2))}M`;
          } else if (scoreVla >= 1000) {
            scoreVla = `${Number(Number.parseFloat(scoreVla / 1000).toFixed(2))}K`;
          } else {
            scoreVla = `${Number(Number.parseFloat(scoreVla).toFixed(2))}`;
          }
          return scoreVla;
        },
      },
      itemStyle: {
        borderRadius: [0, 5, 5, 0],
      },
    };
    return option;
  };
  const [option] = useState(() => getPieOption(list, categoryList, ['#043e7d', '#6abbf7']));

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const columnRender = ({ cellData }) => {
    return (
      <Popover title={null} content={cellData} mouseEnterDelay={0.3}>
        <div className="hidden-line-with-ellipsis">{cellData}</div>
      </Popover>
    );
  };

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const columnPopoverRender = ({ rowData, rowIndex, cellData, dataKey }) => {
    return (
      <Popover
        title={null}
        content={anomalousPopoverHtml({ rowData, intl, typeName: nameDistinguish[typeName], isCost })}
        placement="rightTop"
      >
        <div className="hidden-line-with-ellipsis">
          {dataKey === 'projectName' ? rowData.projectDisplayName || cellData : cellData}
        </div>
      </Popover>
    );
  };

  const mouseOverTableRow = ({ event, index, rowData }, type) => {
    if (echartRef.current) {
      const detailKey = `${rowData[nameDistinguish[typeName]]}-${rowData.score}`;
      const { data } = option?.series[0] || [];
      const dataIndex = R.findIndex((item) => `${item[nameDistinguish[typeName]]}-${item.score}` === detailKey, data);
      const echartsInstance = echartRef.current.getEchartsInstance();
      echartsInstance.dispatchAction({ type: type === 'over' ? 'highlight' : 'downplay', seriesIndex: 0, dataIndex });
    }
  };

  const setChartsHeight = () => {
    const listLength = list.length;
    const chartsHeight = listLength * 30 + 30;
    return chartsHeight > 480 ? 480 : chartsHeight < 150 ? 150 : chartsHeight;
  };

  const detailsRender = ({ rowData }) => {
    return (
      <Button size="small" onClick={() => jumpToInvestigation(rowData)}>
        {intl.formatMessage(appFieldsMessages.details)}
      </Button>
    );
  };

  return (
    <Modal
      title={titleDistinguish[typeName]}
      width={780}
      visible
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      className="dash-cell-detail-modal"
    >
      <Container className="full-width full-height flex-row flex-space-between">
        <div className="cell-detail-wrapper flex-row flex-space-between" style={{ width: '100%', height: 500 }}>
          <div className="flex-grow" style={{ height: setChartsHeight(), marginRight: 10, position: 'relative' }}>
            <div style={{ position: 'absolute', left: '50%', transform: 'translate(-25%, 0)', fontWeight: 'bold' }}>
              {isCost ? 'Cost' : 'Anomaly score'}
            </div>
            <EChart
              setRef={(chart) => {
                echartRef.current = chart;
              }}
              option={option}
              style={{ width: '100%', height: '100%' }}
              className="chart-pie"
              theme={currentTheme}
            />
          </div>
          {false && (
            <Table
              className="with-border"
              width={450}
              height={480}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={40}
              rowCount={list.length}
              rowGetter={({ index }) => list[index]}
              sort={sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              ref={dataTableNode}
              onRowMouseOver={({ event, index, rowData }) => {
                mouseOverTableRow({ event, index, rowData }, 'over');
              }}
              onRowMouseOut={({ event, index, rowData }) => {
                mouseOverTableRow({ event, index, rowData }, 'out');
              }}
            >
              {nameDistinguish[typeName] === 'instanceName' && !isCost && (
                <Column
                  width={135}
                  label={intl.formatMessage(eventActionMessages.componentName)}
                  dataKey="componentName"
                  headerRenderer={headerRenderer}
                  cellRenderer={columnRender}
                />
              )}
              <Column
                width={100}
                flexGrow={1}
                label={viewTypeName}
                dataKey={nameDistinguish[typeName]}
                headerRenderer={headerRenderer}
                cellRenderer={columnPopoverRender}
              />
              <Column
                width={85}
                label={isCost ? 'Cost ($)' : intl.formatMessage(DashboardMessages.score)}
                dataKey="value"
                headerRenderer={headerRenderer}
                cellRenderer={({ cellData }) => {
                  return (
                    <div className="hidden-line-with-ellipsis">{Number(Number.parseFloat(cellData).toFixed(2))}</div>
                  );
                }}
              />
              {!isCost && <Column width={80} label="" dataKey="button" cellRenderer={detailsRender} />}
            </Table>
          )}
        </div>
      </Container>
    </Modal>
  );
}
