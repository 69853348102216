/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getCausalGroupList } from '../../apis';
import { appMessages } from '../../app/messages';

const loadCausalGroupListLogic = createLogic({
  type: [ActionTypes.LOAD_CAUSAL_GROUPLIST],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getCausalGroupList(credentials, params)
      .then((data) => {
        dispatch(createSetAction(ActionTypes.SET_CAUSAL_GROUPLIST_DATA, params, data));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get causal incident list', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadCausalGroupListLogic;
