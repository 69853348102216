import moment from 'moment';

import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postUserToken = (credentials) => {
  return fetchPost(getEndpoint('refreshusertoken'), {
    ...credentials,
  }).then((data) => {
    const { token, tokenExpiredTime } = data || {};

    // Set tokenExpiredTime in localStorage
    window.localStorage.setItem('tokenExpiredTime', tokenExpiredTime);
    console.debug(`Token Expired Time: ${moment.utc(tokenExpiredTime).format('HH:mm:ss')}`);

    // Set X-CSRF-Token in localStorage
    window.localStorage.setItem('X-CSRF-Token', token);

    return {
      credentials: {},
      userInfo: {},
    };
  });
};

export default postUserToken;
