/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import userTypeCheckLogic from './userTypeCheckLogic';
import loginLogic from './loginLogic';
import sandboxLoginLogic from './sandboxLoginLogic';
import logoffLogic from './logoffLogic';
import idpUserLoginLogic from './idpUserLoginLogic';
import singleSignInLoginLogic from './singleSignInLoginLogic';
import passportLoginLogic from './passportLoginLogic';
import newIdpUserLogic from './newIdpUserLogic';
import resetPasswordByTempLogic from './resetPasswordByTempLogic';
import changePasswordLogic from './changePasswordLogic';
import loadAuthInfoLogic from './loadAuthInfoLogic';

export default [
  userTypeCheckLogic,
  loginLogic,
  sandboxLoginLogic,
  logoffLogic,
  idpUserLoginLogic,
  singleSignInLoginLogic,
  passportLoginLogic,
  newIdpUserLogic,
  resetPasswordByTempLogic,
  changePasswordLogic,
  loadAuthInfoLogic,
];
