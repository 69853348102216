/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import moment from 'moment';
import { get, random } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';

const getGlobalTopAnomalyKeywords = (credentials: Credentials, params: Object) => {
  const { startTime, systemIds, customerName } = params;
  const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
  return fetchGet(getEndpoint('stats/global/topanomalykeywords', 2), {
    ...credentials,
    startTime: startTimestamp,
    customerName,
    systemIds: JSON.stringify(systemIds),
  }).then((d) => {
    // Merge the keywords from all the groups.
    let keywords = [];
    let idx = 0;
    R.forEach((r) => {
      const { id: systemId, name: systemName } = r;
      R.forEachObjIndexed((val, pname) => {
        R.forEachObjIndexed((obj, name) => {
          const count = obj.count || obj['count '];
          const isNew = Boolean(obj.newWordFlag || obj['newWordFlag ']);
          const isJsonField = (obj.type || obj['type']) === 'jsonFieldKeyword';
          const instances = obj.instances || {};

          keywords.push({
            systemId,
            systemName,
            isJsonField,
            projectName: pname,
            fieldName: get(obj, ['fieldName'], ''),
            id: name,
            key: idx,
            value: name,
            ratio: random(40, 100), // generate random ratio for the color.
            count,
            isNew,
            instances,
          });
          idx += 1;
        }, val);
      }, get(r, 'AnomalyKeywordMap', {}));
    }, d || []);

    keywords = R.sort(
      (a, b) => b.count - a.count,
      R.filter((k) => Boolean(k.count), keywords),
    );
    const newKeywords = R.filter((k) => k.isNew, keywords);
    keywords = [...newKeywords, ...R.filter((k) => !k.isNew, keywords)];
    return keywords;
  });
};

export default getGlobalTopAnomalyKeywords;
