/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getEventTriageReprotList = (credentials: Credentials, params: Object) => {
  const { customerName, projectName, instanceName, patternId } = params;
  const promiseQuery = [
    fetchGet(getEndpoint('logpatterntriage'), {
      ...credentials,
      customerName,
      projectName,
      instanceName,
      patternId,
    }).then((d) => {
      return d;
    }),
  ];

  return Promise.all(promiseQuery).then((results) => {
    let { triageReportList } = results[0] || {};
    triageReportList = R.map((item) => {
      const { instanceName, triageReport } = item;
      const { timestamp, fieldName, report, triageReporterManagement } = triageReport;
      const key = `${instanceName}-${timestamp}-${fieldName}-${report}`;

      let reporterRecordSet = [];
      const { reporterMap } = triageReporterManagement || {};
      R.forEachObjIndexed((val, action) => {
        const { reporterDetailMap } = val || {};
        R.forEach((item) => {
          const { reporterName, reportTimestampSet } = item;
          R.forEach((timestamp) => {
            reporterRecordSet.push({
              timestamp,
              reporterName,
              action,
            });
          }, reportTimestampSet || []);
        }, R.values(reporterDetailMap || {}));
      }, reporterMap);
      reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))], reporterRecordSet);

      return {
        instanceName,

        key,
        timestamp,
        fieldName,
        report,
        triageReporterManagement,
        reporterRecordSet,
      };
    }, triageReportList);
    triageReportList = R.sortWith([R.descend(R.prop('timestamp'))], triageReportList);

    return {
      triageReportList,
    };
  });
};

export default getEventTriageReprotList;
