/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

// Used package
import React from 'react';
import { AutoSizer as AutoSizerCore } from 'react-virtualized';

export { default as Modal } from './elements/Modal';
export { default as Box } from './elements/Box';
export { default as Icon } from './elements/Icon';
export { default as Image } from './elements/Image';
export { default as Input } from './elements/Input';
export { default as DatePicker } from './elements/DatePicker';
export { default as Loader } from './elements/Loader';
export { default as Dropdown } from './elements/Dropdown';
export { default as TitledText } from './elements/TitledText';
export { default as Popover } from './elements/Popover';
export { default as Tooltip } from './elements/Tooltip';
export { default as Tile } from './grid/Tile';

export { default as Container } from './layouts/Container';
export { default as DataFrame } from './DataFrame';
export { default as CodeMirror } from './CodeMirror';
export { default as Scrollbars } from './Scrollbars';

// TODO: need rewrite
export { default as Select } from './elements/Select';
export { default as Heatmap } from './charts/Heatmap';

const AutoSizer = (props) => {
  return <AutoSizerCore nonce={window.cspNonce} {...props} />;
};

export { AutoSizer };
// Thirt part package
export {
  List,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Table,
  defaultTableRowRenderer,
  MultiGrid,
  createMasonryCellPositioner,
  WindowScroller,
  Masonry,
  SortIndicator,
  SortDirection,
} from 'react-virtualized';
export { Portal } from 'react-portal';
export { Accordion, AccordionItem } from 'react-sanfona';
