/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 **/

/**
 * Calculate the color of the anomaly score. The anomaly score is normalized to under 100.
 */
const calcColorOfAnomalyScore = (val, waterline = 10) => {
  const gcolorMax = 205;
  const bcolor = 0;
  let rcolor = 0;
  let gcolor = 0;

  if (!val) return '#70d696';
  val = Math.abs(val);

  if (val > waterline) {
    val = waterline;
  }
  rcolor = 255;
  gcolor = Math.floor(gcolorMax - val / waterline * gcolorMax);

  return `rgb(${rcolor},${gcolor},${bcolor})`;
};

export default calcColorOfAnomalyScore;
