/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import { get, round } from 'lodash';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { Progress, Button, DatePicker, Select, Empty } from 'antd';

import GlobalCronSelectModal from './components/GlobalCronSelectModal';
import { buildLocation, parseLocation, Defaults, buildUrl, getLoadStatus, GlobalParse } from '../../common/utils';
import { showAppAlert, showAppLoader, createLoadAction, updateLastActionInfo } from '../../common/app/actions';
import { appFieldsMessages, appButtonsMessages } from '../../common/app/messages';
import { DashboardMessages } from '../../common/dashboard/messages';
import { State } from '../../common/types';
import { ActionTypes } from '../../common/dashboard/actions';
import { BaseUrls } from '../app/Constants';
import { Container, AutoSizer } from '../../lib/fui/react';

type Props = {
  location: Object,
  loadStatus: Object,
  intl: Object,
  push: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  showAppLoader: Function,
  credentials: Object,
  isAdmin: Boolean,
  isReadUser: Boolean,
  isLocalAdmin: Boolean,
  userName: String,
  userList: Array<Object>,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,

  globalInfo: Array<Object>,
  globalCapacitySummary: Array<Object>,
};

class GlobalCapacityPlanningViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'dashboard_global_capacity_planning_view';

    this.state = {
      showTimeSelectModal: false,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);

    // this.timer = setInterval(() => {
    //   this.refreshTime();
    // }, 5 * 60 * 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      const nextQuery = parseLocation(nextProps.location);
      const query = parseLocation(this.props.location);
      if (
        nextQuery.refreshTime !== query.refreshTime ||
        nextQuery.environmentId !== query.environmentId ||
        nextQuery.customerName !== query.customerName
      ) {
        const isAutoReload = query.refreshTime !== nextQuery.refreshTime;
        this.reloadData(nextProps, isAutoReload);
      }
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  @autobind
  refreshTime() {
    const { location, push } = this.props;
    const query = parseLocation(location);
    const { startTime } = query;
    const selectStartTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    if (selectStartTimestamp >= moment.utc().startOf('day').valueOf()) {
      const refreshTime = moment.utc().valueOf();
      push(buildLocation(location.pathname, {}, { ...query, refreshTime }));
    }
  }

  @autobind
  reloadData(props, isAutoReload) {
    const { createLoadAction, location, isAdmin } = props;
    const { environmentId, startTime, customerName } = parseLocation(location);

    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime) {
      const endTimestamp = moment.utc(startTime, Defaults.DateFormat).endOf('days').valueOf();
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      const evn = GlobalParse.getEnvByInstanceGroup(instanceGroup);
      createLoadAction(
        ActionTypes.LOAD_GLOBAL_CAPACITY_PLANNING,
        {
          isAutoReload,
          environmentId,
          customerName,
          requestLevel: 'user',
          instanceGroup,
          evn,
          endTimestamp,
        },
        this.dataLoader,
      );
    }
  }

  @autobind
  handleEnvironmentChange(environmentId) {
    const { location, push } = this.props;
    const query = parseLocation(location);
    push(buildLocation(location.pathname, {}, { ...query, environmentId }));
  }

  @autobind
  handleStartTimeChange(newDate) {
    const { location, push } = this.props;
    const query = parseLocation(location);
    const { environmentId } = query;
    const startTimeObj = newDate.clone().startOf('day');
    const startTime = startTimeObj.format(Defaults.DateFormat);
    push(buildLocation(location.pathname, {}, { ...query, startTime, environmentId }));
  }

  @autobind
  handleCustomerNameChange(customerName) {
    const { location, showAppLoader } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    setTimeout(() => {
      const query = parseLocation(location);
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { ...query, customerName, environmentId: undefined },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  renderSystemHealth({ system, systemList }) {
    const { intl } = this.props;
    const { capacityVal } = system;
    const systemInfo = R.find((item) => item.id === system.id, systemList || []);
    return (
      <div key={system.id} className="health-panel flex-grow flex-col" style={{ height: 270 }}>
        <div className="flex-row flex-center-align" style={{ height: 40, fontWeight: 500, fontSize: 16 }}>
          <div className="flex-grow" style={{ padding: '0 8px' }}>
            <i className="icon sitemap" style={{ color: 'grey', fontSize: 14 }} />
            {system.id}
          </div>
          <div className="flex-grow flex-row" style={{ justifyContent: 'center' }}>
            {intl.formatMessage(DashboardMessages.capacityScore)}
          </div>
          <div className="flex-grow" />
        </div>
        <div className="flex-grow" style={{ overflowY: 'auto' }}>
          {R.addIndex(R.map)((projectCapacityInfo, index) => {
            return this.renderCapacityProgress({ index, systemInfo, projectCapacityInfo });
          }, capacityVal)}
        </div>
      </div>
    );
  }

  @autobind
  renderCapacityProgress({ index, systemInfo, projectCapacityInfo }) {
    const { intl, isAdmin, isLocalAdmin, isReadUser, userName } = this.props;
    const { projectName, customerName, aggregatedWeightedScore, aggregatedPredictedWeightedScore } =
      projectCapacityInfo || {};
    const projectOwner = systemInfo.systemInfo.owner;
    const isSharedUser = projectOwner !== userName;
    const newProjectName =
      isAdmin || isLocalAdmin || isReadUser || isSharedUser
        ? `${projectName}@${projectOwner || customerName}`
        : projectName;
    const capacityScore = aggregatedWeightedScore;
    const capacityScorePredict = aggregatedPredictedWeightedScore;
    let color = '#B4DD92';
    let colorPredict = '#B4DD92';
    if (capacityScore > 50) {
      color = '#F06E50';
    }
    if (capacityScorePredict > 50) {
      colorPredict = '#F06E50';
    }
    return (
      <div className="flex-col" key={index} style={{ height: 230 }}>
        <div className="flex-grow flex-row" style={{ padding: '4px' }}>
          <div className="flex-grow" />
          <div className="flex-col" style={{ width: 200 }}>
            <div className="flex-row" style={{ padding: '10px 0', fontSize: 12, justifyContent: 'center' }}>
              {intl.formatMessage(DashboardMessages.current)}
            </div>
            <div className="flex-row" style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Progress
                type="dashboard"
                width={140}
                percent={capacityScore}
                strokeWidth={16}
                gapDegree={90}
                strokeLinecap="square"
                strokeColor={color}
                status={capacityScore > 50 ? 'exception' : 'normal'}
                format={(percent, successPercent) => `${capacityScore}%`}
              />
            </div>
          </div>
          <div className="flex-col" style={{ width: 200 }}>
            <div className="flex-row" style={{ padding: '10px 0', fontSize: 12, justifyContent: 'center' }}>
              {intl.formatMessage(DashboardMessages.predicted)}
            </div>
            <div className="flex-row" style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Progress
                type="dashboard"
                width={140}
                percent={capacityScorePredict}
                strokeWidth={16}
                gapDegree={90}
                strokeLinecap="square"
                strokeColor={colorPredict}
                status={capacityScorePredict > 50 ? 'exception' : 'normal'}
                format={(percent, successPercent) => `${capacityScorePredict}%`}
              />
            </div>
          </div>
          <div className="flex-grow" />
        </div>
        <div className="flex-row" style={{ height: 44 }}>
          <div className="flex-grow" />
          <div className="flex-row flex-center-align" style={{ padding: '0 8px' }}>
            <a style={{ marginRight: 0 }} onClick={this.handleCapacityPlanningClick(systemInfo, newProjectName)}>
              <i className="icon calculator" />
              <span>{intl.formatMessage(DashboardMessages.clickForDetails)}</span>
            </a>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  handleCapacityPlanningClick(systemInfo, projectName) {
    return (event) => {
      event.stopPropagation();
      event.preventDefault();
      const { location } = this.props;
      const { customerName, environmentId, startTime } = parseLocation(location);
      const instanceGroup = GlobalParse.getInstanceGroupShort(environmentId);
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).valueOf();
      const endTimestamp = startTimestamp + 7 * 24 * 60 * 60 * 1000;
      const query = {
        customerName,
        environmentId,
        startTime,
        systemId: systemInfo.id,

        projectName,
        instanceGroup,
        dateTimestamp: startTimestamp,
        startTimestamp,
        endTimestamp,
        componentsStr: `[]`,
      };
      window.open(buildUrl(BaseUrls.CapacityPlanningCalendar, {}, query), '_blank');
    };
  }

  @autobind
  handleUpdateGlobalViewClick() {
    const { location } = this.props;
    const query = parseLocation(location);
    const { startTime } = query;
    if (startTime) {
      const selectStartTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      const selectEndTimestamp = selectStartTimestamp;
      this.setState({ showTimeSelectModal: true, selectStartTimestamp, selectEndTimestamp });
    }
  }

  @autobind
  onCloseTimeSelect(props) {
    const { intl, location, credentials, isAdmin } = this.props;
    const { startTimestamp, endTimestamp, ...rest } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState(
        {
          showTimeSelectModal: false,
        },
        () => {
          this.props.updateLastActionInfo();
          GlobalParse.updateGlobalView({
            intl,
            location,
            state: {
              selectStartTimestamp: startTimestamp,
              selectEndTimestamp: endTimestamp,
              ...rest,
            },
            credentials,
            isAdmin,
          });
        },
      );
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  render() {
    const { location, isAdmin, userList, globalInfo, globalCapacitySummary, intl, loadStatus } = this.props;
    const query = parseLocation(location);
    const { showTimeSelectModal, selectStartTimestamp, selectEndTimestamp } = this.state;
    const { startTime, customerName, environmentId } = query;
    const globalInfoFilter = globalInfo || [];
    const environment = R.find((e) => e.id === environmentId, globalInfoFilter);
    const systemList = get(environment, 'systemList', []);

    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const startTimeObj = moment.utc(startTime, Defaults.DateFormat);
    return (
      <Container fullHeight withGutter className="flex-col">
        <Container breadcrumb className="flex-row">
          <div className="flex-grow flex-row" />
          <div className="flex-row">
            <Container className="flex-row" style={{ alignItems: 'center' }}>
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.date)}</span>
              <DatePicker
                size="small"
                allowClear={false}
                value={startTimeObj}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleStartTimeChange}
              />
              {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.environment)}
              </span>
              <Select size="small" value={environmentId} style={{ width: 140 }} onChange={this.handleEnvironmentChange}>
                {R.map(
                  (item) => (
                    <Select.Option key={item.name} value={item.name} title={item.name}>
                      {item.id}
                    </Select.Option>
                  ),
                  globalInfo || [],
                )}
              </Select> */}
              {isAdmin && (
                <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
              )}
              {isAdmin && (
                <Select
                  showSearch
                  size="small"
                  value={customerName}
                  style={{ width: 100 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.handleCustomerNameChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              )}

              {isAdmin && (
                <Button
                  size="small"
                  type="primary"
                  onClick={this.handleUpdateGlobalViewClick}
                  style={{ marginLeft: 8 }}
                >
                  {intl.formatMessage(appButtonsMessages.update)}
                </Button>
              )}
              <Button size="small" onClick={this.refreshTime} style={{ marginLeft: 8 }}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </Container>
          </div>
        </Container>
        <Container
          className={`global-view flex-grow flex-row  ${isLoading ? 'loading ' : ''}`}
          style={{ margin: '0 8px' }}
        >
          <AutoSizer>
            {({ height, width }) => (
              <Container style={{ width, height }} className="flex-col">
                {errorMessage && (
                  <Container style={{ margin: 8 }}>
                    <div className="ui mini error message">{errorMessage}</div>
                  </Container>
                )}
                {!errorMessage && globalCapacitySummary.length === 0 && (
                  <div className="full-height flex-row flex-center-align flex-center-justify">
                    <Empty />
                  </div>
                )}
                {!errorMessage && systemList.length > 0 && globalCapacitySummary.length > 0 && (
                  <div className="flex-col" style={{ width, height, overflowY: 'auto' }}>
                    {R.addIndex(R.map)(
                      (systemCapacityList, index) => {
                        return (
                          <div key={index} className="flex-row" style={{ width: '100%' }}>
                            {this.renderSystemHealth({ system: systemCapacityList[0], systemList })}
                            {systemCapacityList.length === 2 ? (
                              this.renderSystemHealth({ system: systemCapacityList[1], systemList })
                            ) : (
                              <div className="flex-grow" style={{ margin: '0 8px' }} />
                            )}
                          </div>
                        );
                      },
                      R.map((index) => {
                        return R.slice(index * 2, index * 2 + 2, globalCapacitySummary);
                      }, R.range(0, round((globalCapacitySummary || []).length / 2))),
                    )}
                  </div>
                )}
              </Container>
            )}
          </AutoSizer>
        </Container>
        {showTimeSelectModal && (
          <GlobalCronSelectModal
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
          />
        )}
      </Container>
    );
  }
}

const GlobalCapacityPlanningView = injectIntl(GlobalCapacityPlanningViewCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { isAdmin, isReadUser, isLocalAdmin, userName } = state.auth.userInfo;
    const { loadStatus, globalInfo } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { globalCapacitySummary } = state.dashboard;
    return {
      loadStatus,
      credentials,
      globalInfo,
      isAdmin,
      isReadUser,
      isLocalAdmin,
      userName,
      location,
      userList,
      globalCapacitySummary,
    };
  },
  { push, showAppAlert, showAppLoader, createLoadAction, updateLastActionInfo },
)(GlobalCapacityPlanningView);
