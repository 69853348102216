const KafkaIni = `[agent]
# kafka info
# required
bootstrap_servers = {{bootstrapServers}}
# required
topics = {{topics}}
# required
group_id = {{groupId}}
client_id = {{clientId}}

## SSL
# set to SSL to use SSL
security_protocol =
ssl_context =
# true or false
ssl_check_hostname = {{sslCheckHostname}}
ssl_cafile = {{sslCafile}}
ssl_certfile = {{sslCertfile}}
ssl_keyfile = {{sslKeyfile}}
ssl_password = {{sslPassword}}
ssl_crlfile = {{sslCrlfile}}
ssl_ciphers = {{sslCiphers}}

## SASL
# valid options are PLAIN GSSAPI OAUTHBEARER
sasl_mechanism =
sasl_plain_username =
sasl_plain_password =
sasl_kerberos_service_name =
sasl_kerberos_domain_name =
sasl_oauth_token_provider =

# Optional preprocessing filter (regex) to eliminate raw data from being parsed. 
# Data must match filter to be parsed if set
initial_filter = {{initialFilter}}

# if raw data, the regex used to parse the log. It must use named capture groups (?<name>.*) 
# that correspond to the *_field config variables below (ie  (?<timestamp>.*),  (?<host>.*),  (?<device>.*),
# (?<etc>.*).
raw_regex = {{rawRegex}}

# Field that contains the project name. If this field is empty, agent will use project_name in insightfinder section
project_field =
# project_whitelist is a regex string used to define which projects form project_field will be filtered.
project_whitelist =

# Field that contains the log message. If this field is empty, agent will use whole message from kafka.
log_content_field = {{logContentField}}

## message parsing
# timezone, as per pytz
timezone =
# Field that contains the timestamp - Required
timestamp_field = {{timestampField}}
# Timezone of the timestamp data to be sent and stored in target DB (InsightFinder). Default is UTC. 
# Only if you wish the data to be stored in other time zones in InsightFinder, this field 
# should be specified to be the desired time zone.
target_timestamp_timezone = {{timezone}}
component_field = {{componentField}}
# if no instance given, the local hostname will be used. Can also use {field} formatting or a priority 
# list.- Required
instance_field = {{instanceField}}
# instance_whitelist is a regex string used to define which instances will be filtered.
instance_whitelist = {{instanceWhitelist}}
device_field = {{deviceField}}

## proxy
agent_http_proxy =
agent_https_proxy =

[insightfinder]
user_name = {{userName}}
license_key = {{licenseKey}}
token =
# Name of system owned by project. If project_name is not exist in InsightFinder, agent will create a 
# new system automatically from this field or project_name.
system_name = {{systemName}}
# Name of the project created in the InsightFinder UI, If this project is not exist, agent will create 
# it automatically.
project_name = {{projectName}}
# metric, metricreplay, log, logreplay, alert, alertreplay, incident, incidentreplay, deployment, 
# deploymentreplay, trace, tracereplay
project_type = {{projectType}}
# Set to \`YES\` if project is container.
containerize = {{containerize}}
sampling_interval = {{samplingInterval}}
run_interval = {{samplingInterval}}
# what size to limit chunks sent to IF to, as kb
chunk_size_kb = 2048
if_url = {{ifUrl}}
if_http_proxy =
if_https_proxy =
`;

export default KafkaIni;
