/* @flow */
import React, { useReducer, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import { autobind } from 'core-decorators';
import { get, isNumber } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CommentOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FileOutlined,
  ToolFilled,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Select,
  Spin,
  Button,
  notification,
  Pagination,
  Popconfirm,
  Checkbox,
  message,
  Menu,
  DatePicker,
  Tag,
  Tooltip,
  Alert,
  Upload,
} from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import {
  Defaults,
  parseLocation,
  parseJSON,
  LogRenderers,
  CellRenderers,
  CausalParser,
  buildUrl,
  sleep,
  EventRenderers,
  buildLocation,
  timeScopeControl,
} from '../../../common/utils';
import { IncidentPredictIcon, RootCauseIcon } from '../../../lib/fui/icons';
import {
  Modal,
  Container,
  AutoSizer,
  Table,
  Column,
  List,
  CellMeasurerCache,
  CellMeasurer,
  Popover,
  SortDirection,
  Dropdown,
} from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';

import GlobalSystemKnowledgeBaseActionModal from './GlobalSystemKnowledgeBaseActionModal';
import RecommendationModal from './RecommendationModal';
import AddSavedActionModal from './AddSavedActionModal';
import fetchPut from '../../../common/apis/fetchPut';
import EscalateAddModal from './EscalateAddModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import fetchPostForm from '../../../common/apis/fetchPostForm';

type Props = {
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  tabName: String,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  isLoadingSystem: Boolean,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: String,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  globalInfo: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  currentTheme: String,
  instanceDisplayNameMap: Object,
};

class KnowledgeBaseRuleListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl, location } = props;
    const params = parseLocation(location);
    const { jumpProject, jumpCauseLevel, jumpMergeKey, jumpKey, startTime, endTime } = params;

    const rootCauseProject = jumpProject;
    const rootCauseLevel = jumpCauseLevel || 'component';

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

    this.state = {
      isLoaded: false,
      isLoading: false,
      isLoadingLocalList: false,
      isSubmitting: false,

      page: 1,
      pageSize: 100,
      checkAll: false,
      rootCauseProject,
      rootCauseLevel,
      filterIncidentComponent: undefined,
      filterIncidentInstance: undefined,
      filterSourcePatternIds: [],
      filterPatternId: null,
      filterKBType: 'all',
      filterStatus: null,

      predictionRuleList: [],
      filterPredictionRuleList: [],
      incidentList: [],
      activeIncidentKey: undefined,
      incidentPredictionRuleList: [],
      localPredictionRuleList: [],

      showSystemPredictionRuleActionModal: false,
      actionProjectNameList: [],
      actionKeySet: [],
      actionIncidentPredictionLibKeys: [],
      systemProjectNameList: [],
      sortBy: null,
      sortDirection: null,

      currentIncident: {},
      addRecommendationModalFlag: false,
      addRecommendationActive: {},
      allIncidentList: [],
      sourcePatternListOptions: [],
      showSavedActionModal: false,
      addSavedActionRow: {},

      statisticsFilterType: 'all',
      statisticsStartTimeObj: moment.utc(startTime, Defaults.DateFormat),
      statisticsEndTimeObj: moment.utc(endTime, Defaults.DateFormat),

      escalateAddModal: false,

      downloadFileLoading: false,
      uploadFlagModal: false,
    };
    this.projectList = [];
    this.cmp = (a, b) => a.key === b.key && a.statusFlag === b.statusFlag;
    this.incidentComponentListOptions = [];
    this.incidentInstanceListOptions = [];
    this.patternListOptions = [];
    this.statusOptions = [
      { value: -1, label: intl.formatMessage(eventMessages.ignored) },
      { value: 0, label: intl.formatMessage(eventMessages.notInUse) },
      { value: 1, label: intl.formatMessage(settingsMessages.confirmed) },
      { value: 2, label: intl.formatMessage(eventMessages.inUse) },
      { value: 10, label: intl.formatMessage(eventMessages.confirmedByOperator) },
      { value: 3, label: 'To be Validated' },
    ];
    this.causeMap = {};
    this.jumpMergeKey = jumpMergeKey;
    this.jumpKey = jumpKey;
    this.removeRuleOperation = 'InstanceLevelRuleType';
  }

  componentDidMount() {
    this.resetProject();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const prevQuery = parseLocation(prevProps.location);
    const query = parseLocation(location);
    if (
      prevProps.refresh !== this.props.refresh ||
      prevQuery.refreshTime !== query.refreshTime ||
      prevQuery.environmentId !== query.environmentId ||
      prevQuery.customerName !== query.customerName ||
      prevQuery.systemId !== query.systemId ||
      prevQuery.reloadSystem !== query.reloadSystem
    ) {
      this.resetProject();
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;
    let { incidentList } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        if (sortBy === 'patternId') {
          incidentList = R.sortWith([R.ascend(R.compose(R.head, R.prop(sortBy)))])(incidentList);
        } else {
          incidentList = R.sortWith([R.ascend(R.prop(sortBy))])(incidentList);
        }
        if (sortDirection === SortDirection.DESC) {
          if (sortBy === 'patternId') {
            incidentList = R.sortWith([R.descend(R.compose(R.head, R.prop(sortBy)))])(incidentList);
          } else {
            incidentList = R.sortWith([R.descend(R.prop(sortBy))])(incidentList);
          }
        }
        this.setState({ incidentList });
      }
    }
  }

  @autobind
  resetProject() {
    const { location, globalInfo } = this.props;
    const { rootCauseProject } = this.state;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const projectNames = R.map((p) => p.projectNameReal, projectNameSet);
    this.projectList = projectNameSet;

    if (!rootCauseProject || (rootCauseProject && !projectNames.includes(rootCauseProject))) {
      const rootCauseProject = this.projectList.length > 0 ? this.projectList[0].projectNameReal : undefined;
      this.setState({ rootCauseProject }, () => {
        this.reloadData();
      });
    } else {
      this.reloadData();
    }
  }

  @autobind
  async reloadData(isClear = true) {
    const { location, userInfo, credentials, globalInfo, projects, instanceDisplayNameMap } = this.props;
    const {
      environmentId,
      startTime,
      endTime,
      customerName,
      systemId,
      jumpProject,
      jumpCauseLevel,
      jumpMergeKey,
      jumpKey,
    } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const { rootCauseProject, rootCauseLevel, activeIncidentKey } = this.state;

    if (
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      startTime &&
      endTime &&
      systemId &&
      systemInfo &&
      systemInfo.hasAllInstanceInfo &&
      rootCauseProject
    ) {
      if (isClear) {
        this.causeMap = {};
      }

      this.setState({ isLoading: true, page: 1, filterKBType: 'all', filterSourcePatternIds: [], checkAll: false });
      this.props.updateLastActionInfo();
      // get all instance info and component info
      const environment = R.find((e) => e.id === environmentId, globalInfo);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
      const [projectName, projectOwner] = rootCauseProject.split('@');
      const project = R.find((item) => {
        return item.projectShortName === projectName || item.projectNameReal === projectName;
      }, projects);
      const { owner } = project || {};
      fetchGet(getEndpoint('instance-with-incident', 1), {
        ...credentials,
        projectName,
        customerName: owner || projectOwner || customerName,
        ruleType: rootCauseLevel === 'instance' ? 'InstanceLevelRuleType' : 'ComponentLevelRuleType',
      })
        .then((res) => {
          const { msg, success } = res;
          if (msg || success === false) {
            this.setState(
              {
                incidentList: [],
                allIncidentList: [],
                isLoading: false,
                localPredictionRuleList: [],
                incidentPredictionRuleList: [],
                filterPredictionRuleList: [],
              },
              () => {
                if (jumpProject && jumpCauseLevel && jumpMergeKey && jumpKey) {
                  this.clearJumpData();
                }
              },
            );
            return;
          }
          let incidentInstanceListOptions = [];
          let incidentComponentListOptions = [];
          let patternListOptions = [];
          let incidentList = R.map((item) => {
            const dataInfo = item[0] || {};
            const PatternIdList = item[1] || [];
            incidentInstanceListOptions.push(dataInfo.instanceName);
            incidentComponentListOptions.push(dataInfo.componentName);
            return R.map((_item) => {
              patternListOptions.push(_item.patternId);
              return {
                ...dataInfo,
                patternId: [_item.patternId],
                patternName: _item.patternName,
                key: `${rootCauseLevel === 'instance' ? dataInfo.instanceName : dataInfo.componentName}-${
                  _item.patternId
                }`,
              };
            }, PatternIdList);
          }, res || []);
          incidentList = R.unnest(incidentList || []);

          // 通过Key一样的，进行整合
          const filterConcordanceKeyObj = {};
          const filterinstanceNameObj = {};
          R.forEach((item) => {
            const flagnName = `${rootCauseLevel === 'instance' ? item.instanceName : item.componentName}-${
              item.patternId[0]
            }`;
            if (!R.has(item.key, filterConcordanceKeyObj)) {
              filterConcordanceKeyObj[item.key] = [flagnName];
            } else {
              filterConcordanceKeyObj[item.key].push(flagnName);
            }
            if (!R.has(item.key, filterinstanceNameObj)) {
              filterinstanceNameObj[item.key] = [item.instanceName];
            } else {
              filterinstanceNameObj[item.key].push(item.instanceName);
            }
          }, incidentList || []);
          incidentList = R.uniqBy((item) => item.key, incidentList || []);
          incidentList = R.map(
            (item) => ({
              ...item,
              instanceNameList: R.uniq(filterinstanceNameObj[item.key] || []),
              instanceNameByPatternIdList: R.uniq(filterConcordanceKeyObj[item.key] || []),
            }),
            incidentList || [],
          );

          incidentInstanceListOptions = R.uniq(incidentInstanceListOptions);
          incidentComponentListOptions = R.uniq(incidentComponentListOptions);
          patternListOptions = R.uniq(patternListOptions);
          this.incidentInstanceListOptions = R.map((item) => {
            const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item, {
              pn: project?.projectShortName,
              owner: project?.owner,
            });
            return { value: item, label: instanceStr };
          }, incidentInstanceListOptions);
          this.incidentComponentListOptions = R.map(
            (item) => ({ value: item, label: item }),
            incidentComponentListOptions,
          );
          this.patternListOptions = R.map((item) => ({ value: item, label: item }), patternListOptions);

          const filterIncidentList = this.filterDataList(incidentList);
          if (!R.isEmpty(incidentList)) {
            const findItem = R.find(
              (item) => item.key === (this.getActiveIncidentKey(incidentList) || activeIncidentKey),
              incidentList,
            );
            this.handleIncidentClick(findItem || incidentList[0]);
          } else {
            this.clearCauseData();
          }
          this.setState({ incidentList: filterIncidentList, allIncidentList: incidentList, isLoading: false }, () => {
            if (jumpProject && jumpCauseLevel && jumpMergeKey && jumpKey) {
              this.jumpToItem({
                incidentList,
                activeKey: this.getActiveIncidentKey(incidentList),
                node: this.dataTable,
              });
              this.clearJumpData();
            }
            this.jumpMergeKey = undefined;
          });
          this.dataTable.forceUpdate();
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.error(String(err));
        });
    }
  }

  @autobind
  jumpToItem({ incidentList, activeKey, node, isPage }) {
    const { pageSize, page } = this.state;
    // 定位
    const findItemIdx = R.findIndex((item) => item.key === activeKey, incidentList);
    let index = findItemIdx;
    if (isPage && findItemIdx >= pageSize) {
      index = findItemIdx - (page - 1) * pageSize;
    }
    if (node) {
      node.scrollToRow(index);
      node.forceUpdateGrid();
    }
  }

  @autobind
  getActiveIncidentKey(incidentList) {
    const findItem =
      R.find((item) => R.includes(this.jumpMergeKey, item.instanceNameByPatternIdList || []), incidentList || []) || {};
    return findItem.key;
  }

  @autobind
  clearJumpData() {
    const { location, replace } = this.props;
    const params = parseLocation(location);
    replace(
      buildLocation(
        location.pathname,
        {},
        {
          ...params,
          jumpProject: undefined,
          jumpCauseLevel: undefined,
          jumpMergeKey: undefined,
          jumpKey: undefined,
        },
      ),
    );
  }

  @autobind
  clearCauseData() {
    this.setState({
      localPredictionRuleList: [],
      incidentPredictionRuleList: [],
      filterPredictionRuleList: [],
      currentIncident: {},
    });
  }

  @autobind
  handleEscalateAddClick(patternName) {
    const { intl, credentials } = this.props;
    const { localPredictionRuleList, filterPredictionRuleList } = this.state;
    let diff = R.difference(localPredictionRuleList, filterPredictionRuleList);
    diff = R.map((item) => {
      const { incidentRule, ruleType } = item;
      const { rulePrimaryKey } = incidentRule;
      const { rulePartitionKey, uniqueKey } = rulePrimaryKey;
      const { projectName, instanceName, userName, patternId } = rulePartitionKey;
      return {
        projectName,
        userName,
        instanceName,
        patternId,
        uniqueKey,
        ruleType,
      };
    }, diff);
    fetchPost(getEndpoint('globallevelrule'), {
      ...credentials,
      rules: JSON.stringify(diff),
      operation: 0, // 'add'
      patternName,
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  }

  @autobind
  handleSaveClick() {
    const { intl, credentials } = this.props;
    const { localPredictionRuleList, filterPredictionRuleList, rootCauseProject, currentIncident } = this.state;

    this.setState({ isSubmitting: true });
    let diff = R.difference(localPredictionRuleList, filterPredictionRuleList);
    diff = R.map((item) => {
      const { statusFlag, incidentRule, ruleType } = item;
      const { rulePrimaryKey } = incidentRule;
      const { rulePartitionKey, uniqueKey } = rulePrimaryKey;
      const { projectName, instanceName, userName, patternId } = rulePartitionKey;

      return [
        {
          projectName,
          userName,
          instanceName,
          patternId,
          uniqueKey,
          ruleType,
        },
        statusFlag,
      ];
    }, diff);
    this.props.updateLastActionInfo();
    fetchPut(getEndpoint('knowledge-base'), {
      ...credentials,
      operation: 'update',
      rules: JSON.stringify(diff),
    })
      .then((data) => {
        // this.reloadData();
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState(
          { isSubmitting: false, filterPredictionRuleList: R.map((x) => ({ ...x }), localPredictionRuleList || []) },
          () => {
            this.dataTable.forceUpdate();
          },
        );
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });

    // fetchPost(getEndpoint('predictionrule'), {
    //   ...credentials,
    //   rules: JSON.stringify(diff),
    //   operation: 'update',
    // })
    //   .then((data) => {
    //     message.success(intl.formatMessage(appMessages.apiSuccess));
    //     this.reloadData();
    //     this.setState({ isSubmitting: false });
    //   })
    //   .catch((err) => {
    //     message.error(intl.formatMessage(appMessages.apiFaild));
    //     this.setState({
    //       isSubmitting: false,
    //     });
    //   });
  }

  @autobind
  handleRuleRemove() {
    const { intl, credentials } = this.props;
    const { localPredictionRuleList } = this.state;

    this.setState({ isSubmitting: true });
    let diff = R.filter((item) => item.checked, localPredictionRuleList);
    diff = R.map((item) => {
      const { ruleType, incidentRule } = item;
      const {
        rulePrimaryKey: { rulePartitionKey, uniqueKey },
      } = incidentRule;
      const { projectName, instanceName, userName, patternId } = rulePartitionKey;
      return {
        projectName,
        instanceName,
        userName,
        patternId,
        uniqueKey,
        ruleType,
      };
    }, diff);

    this.props.updateLastActionInfo();

    fetchPost(getEndpoint('knowledge-base'), {
      ...credentials,
      rules: JSON.stringify(diff),
      operation: 'delete',
    })
      .then((data) => {
        const { success, message: msg } = data;
        this.setState({ isSubmitting: false });
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.reloadData();
          return;
        }
        message.error(`Error: ${msg}`);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  handleRuleRemoveAll() {
    const { intl } = this.props;
    const { rootCauseProject } = this.state;
    Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <>
          <div>{intl.formatMessage(appMessages.removeProjectAllRule, { projectName: rootCauseProject })}</div>
          <div>{intl.formatMessage(appMessages.continueConfirm)}</div>
          <br />
          <div className="flex-row flex-center-align">
            <div style={{ marginRight: 16 }}>Operation:</div>
            <Select
              size="small"
              style={{ width: '100%' }}
              defaultValue="InstanceLevelRuleType"
              onChange={(operation) => {
                this.removeRuleOperation = operation;
              }}
            >
              <Select.Option key="InstanceLevelRuleType">Instance</Select.Option>
              <Select.Option key="ComponentLevelRuleType">Component</Select.Option>
            </Select>
          </div>
        </>
      ),
      onOk: this.handleRuleRemoveAllConfirm,
      onCancel: (close) => {
        this.removeRuleOperation = 'InstanceLevelRuleType';
        close();
      },
    });
  }

  @autobind
  handleRuleRemoveAllConfirm(close) {
    const { intl, credentials } = this.props;
    const { rootCauseProject } = this.state;

    this.setState({ isSubmitting: true });
    const findItem = R.find((item) => rootCauseProject === item.projectNameReal, this.projectList);
    const { projectName, userName } = findItem;

    this.props.updateLastActionInfo();
    fetchDelete(getEndpoint('knowledge-base'), {
      ...credentials,
      projectName,
      UserName: userName,
      ruleType: this.removeRuleOperation,
    })
      .then((data) => {
        const { success, message: msg } = data;
        this.setState({ isSubmitting: false });
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.removeRuleOperation = 'InstanceLevelRuleType';
          this.reloadData();
          close();
          return;
        }
        message.error(`Error: ${msg}`);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  onChangeRootCauseProject(rootCauseProject) {
    this.setState(
      {
        rootCauseProject,
        rootCauseLevel: 'component',
        filterIncidentInstance: undefined,
        filterIncidentComponent: undefined,
        filterPatternId: null,
        filterKBType: 'all',
        filterSourcePatternIds: [],
      },
      () => {
        this.reloadData();
      },
    );
  }

  @autobind
  onChangeRootCauseLevel(rootCauseLevel) {
    this.setState(
      {
        rootCauseLevel,
        localPredictionRuleList: [],
        incidentPredictionRuleList: [],
        filterPredictionRuleList: [],
        currentIncident: {},
        incidentList: [],
        allIncidentList: [],
        filterIncidentInstance: undefined,
        filterIncidentComponent: undefined,
        filterPatternId: null,
        filterKBType: 'all',
        filterSourcePatternIds: [],
      },
      () => {
        this.reloadData();
      },
    );
  }

  @autobind
  onChangeFilterIncidentComponent(filterIncidentComponent) {
    const { allIncidentList, activeIncidentKey } = this.state;
    this.setState(
      {
        filterIncidentComponent,
        page: 1,
        checkAll: false,
        isLoading: true,
        filterPatternId: null,
        filterKBType: 'all',
        filterSourcePatternIds: [],
      },
      async () => {
        await sleep(300);
        const filterIncidentList = this.filterDataList(allIncidentList);
        this.setState({ isLoading: false, incidentList: filterIncidentList }, () => {
          if (!R.isEmpty(filterIncidentList)) {
            const fintItem = R.find((item) => item.key === activeIncidentKey, filterIncidentList);
            const incident = fintItem || filterIncidentList[0];
            this.jumpToItem({ incidentList: filterIncidentList, activeKey: incident?.key, node: this.dataTable });
            this.handleIncidentClick(incident);
          } else {
            this.clearCauseData();
          }
        });
      },
    );
  }

  @autobind
  onChangeFilterIncidentInstance(filterIncidentInstance) {
    const { allIncidentList, activeIncidentKey } = this.state;
    this.setState(
      {
        filterIncidentInstance,
        page: 1,
        checkAll: false,
        isLoading: true,
        filterPatternId: null,
        filterKBType: 'all',
        filterSourcePatternIds: [],
      },
      async () => {
        await sleep(300);
        const filterIncidentList = this.filterDataList(allIncidentList);
        this.setState({ isLoading: false, incidentList: filterIncidentList }, () => {
          if (!R.isEmpty(filterIncidentList)) {
            const fintItem = R.find((item) => item.key === activeIncidentKey, filterIncidentList);
            const incident = fintItem || filterIncidentList[0];
            this.jumpToItem({ incidentList: filterIncidentList, activeKey: incident?.key, node: this.dataTable });
            this.handleIncidentClick(incident);
          } else {
            this.clearCauseData();
          }
        });
      },
    );
  }

  @autobind
  onChangeFilterPatternId(filterPatternId) {
    const { allIncidentList, activeIncidentKey } = this.state;
    this.setState(
      {
        filterPatternId,
        page: 1,
        checkAll: false,
        isLoading: true,
        filterKBType: 'all',
        filterSourcePatternIds: [],
      },
      async () => {
        await sleep(300);
        const filterIncidentList = this.filterDataList(allIncidentList);
        this.setState({ isLoading: false, incidentList: filterIncidentList }, () => {
          if (!R.isEmpty(filterIncidentList)) {
            const fintItem = R.find((item) => item.key === activeIncidentKey, filterIncidentList);
            const incident = fintItem || filterIncidentList[0];
            this.jumpToItem({ incidentList: filterIncidentList, activeKey: incident?.key, node: this.dataTable });
            this.handleIncidentClick(incident);
          } else {
            this.clearCauseData();
          }
        });
      },
    );
  }

  @autobind
  onChangeFilterKBType(filterKBType) {
    this.setState({ filterKBType, page: 1 }, () => {
      this.changeFilter({});
    });
  }

  @autobind
  filterDataList(incidentList) {
    const { filterIncidentInstance, filterIncidentComponent, filterPatternId } = this.state;
    let filterIncidentList = incidentList || [];

    if (filterIncidentInstance) {
      filterIncidentList = R.filter((item) => item.instanceName === filterIncidentInstance, filterIncidentList);
    }

    if (filterIncidentComponent) {
      filterIncidentList = R.filter((item) => item.componentName === filterIncidentComponent, filterIncidentList);
    }

    if (filterPatternId) {
      filterIncidentList = R.filter((item) => R.includes(filterPatternId, item.patternId || []), filterIncidentList);
    }

    return filterIncidentList;
  }

  @autobind
  handlePaginationChange(page, pageSize) {
    this.setState({ page, pageSize, checkAll: false, isLoadingLocalList: true }, async () => {
      await sleep(300);

      const { incidentPredictionRuleList, sortDirectionList } = this.state;
      let localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      localPredictionRuleList = this.sortData(localPredictionRuleList, 'matchedCount', sortDirectionList);
      this.setState({ isLoadingLocalList: false, localPredictionRuleList }, () => {
        this.cellMeasureCache.clearAll();
        if (this.listNode) {
          this.listNode.forceUpdateGrid();
          this.forceUpdate();
        }
      });
    });
  }

  @autobind
  handlePaginationSizeChange(oldPage, pageSize) {
    const page = 1;
    this.setState({ page, pageSize, checkAll: false, isLoadingLocalList: true }, async () => {
      await sleep(300);

      const { incidentPredictionRuleList, sortDirectionList } = this.state;
      let localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, incidentPredictionRuleList);
      localPredictionRuleList = this.sortData(localPredictionRuleList, 'matchedCount', sortDirectionList);
      this.setState({ isLoadingLocalList: false, localPredictionRuleList }, () => {
        this.cellMeasureCache.clearAll();
        if (this.listNode) {
          this.listNode.forceUpdateGrid();
          this.forceUpdate();
        }
      });
    });
  }

  @autobind
  renderKBIgnoreFlag({ cellData, rowData }) {
    const { intl } = this.props;
    return (
      <>
        {cellData ? (
          <Popover
            title={null}
            content={intl.formatMessage(eventMessages.noIgnorePattern)}
            mouseEnterDelay={0.3}
            placement="left"
          >
            <EyeInvisibleOutlined
              style={{ color: '#c3c3c3' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick(rowData);
              }}
            />
          </Popover>
        ) : (
          <Popover
            title={null}
            content={intl.formatMessage(eventMessages.ignorePattern)}
            mouseEnterDelay={0.3}
            placement="left"
          >
            <EyeOutlined
              style={{ color: '#c3c3c3' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick(rowData);
              }}
            />
          </Popover>
        )}
      </>
    );
  }

  @autobind
  handleIgnoreClick(rowData) {
    const { credentials, intl } = this.props;
    const { incidentPatternName, incidentPatternId, kbIgnoreFlag, incidentInstanceName, incidentCompositeKey } =
      rowData;
    const content = kbIgnoreFlag
      ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
          pattern: incidentPatternName || incidentPatternId,
          status: 'Unignore the pattern',
        })
      : intl.formatMessage(eventMessages.markIgnoreStatus, {
          pattern: incidentPatternName || incidentPatternId,
          status: 'Ignore pattern',
        });
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content,
      okText: intl.formatMessage(appButtonsMessages.submit),
      cancelText: intl.formatMessage(appButtonsMessages.cancel),
      onOk: () => {
        this.setState({ isLoading: true }, () => {
          fetchPost(getEndpoint('events'), {
            ...credentials,
            operation: kbIgnoreFlag ? 'cancelKBIgnoreFlag' : 'setKBIgnoreFlag',
            projectName: incidentCompositeKey?.projectName,
            instanceName: incidentInstanceName,
            customerName: incidentCompositeKey?.userName,
            nid: incidentPatternId,
          })
            .then((data) => {
              if (data.success) {
                message.success(intl.formatMessage(appMessages.apiSuccess));
                this.reloadData(false);
              } else {
                message.error(intl.formatMessage(appMessages.apiFaild));
                this.setState({
                  isLoading: false,
                });
              }
            })
            .catch((err) => {
              message.error(intl.formatMessage(appMessages.apiFaild));
              this.setState({
                isLoading: false,
              });
            });
        });
      },
    });
  }

  @autobind
  handleCheckAllChange(e) {
    const checkAll = e.target.checked;
    const {
      localPredictionRuleList,
      filterSourcePatternIds,
      filterStatus,
      statisticsFilterType,
      statisticsStartTimeObj,
      statisticsEndTimeObj,
      filterKBType,
    } = this.state;
    const startTime = statisticsStartTimeObj ? statisticsStartTimeObj.clone().startOf('days').valueOf() : 0;
    const endTime = statisticsEndTimeObj ? statisticsEndTimeObj.clone().endOf('days').valueOf() : 0;

    const newLocalPredictionRuleList = R.map((item) => {
      const projectName = item?.incidentRule?.rulePrimaryKey?.rulePartitionKey?.projectName;
      const isDistributed = R.filter((item) => item.p && projectName !== item.p, item.rootCauseInfo || []).length > 0;

      const flagPatternIds = R.difference(filterSourcePatternIds, item.sourcePatternIds).length === 0;
      const flagStatusFlag = item.statusFlag === filterStatus;
      const rootCauseFlag =
        R.filter((_item) => startTime <= _item.t && _item.t <= endTime, item.rootCauseInfo || []).length > 0;
      let checked = checkAll;

      const KBTypeBool = filterKBType !== 'all';
      if (KBTypeBool && filterKBType === 'distributed') {
        checked = isDistributed ? checked : item.checked;
      } else if (KBTypeBool && filterKBType === 'local') {
        checked = !isDistributed ? checked : item.checked;
      }

      const patternBool = filterSourcePatternIds && filterSourcePatternIds.length > 0;
      if (patternBool) {
        checked = flagPatternIds ? checked : item.checked;
      }

      const statusBool = filterStatus || filterStatus === 0;
      if (statusBool) {
        checked = flagStatusFlag ? checked : item.checked;
      }

      const statisticsBool = statisticsFilterType !== 'all' && statisticsStartTimeObj && statisticsEndTimeObj;
      if (statisticsBool) {
        checked = rootCauseFlag ? checked : item.checked;
      }

      if (KBTypeBool && patternBool && statusBool && statisticsBool) {
        checked = KBTypeBool && flagPatternIds && flagStatusFlag && rootCauseFlag ? checked : item.checked;
      }
      return { ...item, checked };
    }, localPredictionRuleList);
    this.setState({ checkAll, localPredictionRuleList: newLocalPredictionRuleList });
  }

  @autobind
  renderListItem({ index: rowIndex, style, parent }, { newLocalPredictionRuleList }) {
    const { intl, userInfo } = this.props;
    const rowData = newLocalPredictionRuleList[rowIndex];
    if (!rowData) return null;

    const { checked, statusFlag, matchedCount, unmatchedCount, incidentRule, rootCauseInfo } = rowData;
    const key = incidentRule.rulePrimaryKey.uniqueKey;
    const projectName = incidentRule.rulePrimaryKey?.rulePartitionKey?.projectName;
    const isDistributed = R.filter((item) => item.p && projectName !== item.p, rootCauseInfo || []).length > 0;

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div className={`event-list-row${this.jumpKey === rowData.key ? ' active' : ''}`} style={{ ...style }}>
          <div className="row-column flex-center-justify" style={{ width: 40, minWidth: 40 }}>
            <Checkbox checked={checked} onChange={(e) => this.handleCheckbox(e, rowData, rowIndex)} />
          </div>
          <div className="row-column flex-center-justify" style={{ width: 40, minWidth: 40 }}>
            {isDistributed && (
              <Tooltip title="Distributed KB" placement="top" mouseEnterDelay={0.3}>
                <Tag size="small" style={{ fontWeight: 'bold', flexShrink: 0 }}>
                  D
                </Tag>
              </Tooltip>
            )}
          </div>
          <div className="row-column" style={{ width: 110, minWidth: 110 }}>
            {matchedCount}
          </div>
          <div className="row-column" style={{ width: 110, minWidth: 110 }}>
            {unmatchedCount}
          </div>
          <div className="row-column" style={{ width: 160, flex: 1 }}>
            {this.renderSourcePatterns(rowData)}
          </div>
          <div className="row-column" style={{ width: 140 }}>
            {CellRenderers.humanizeDuration({
              period: rowData.incidentRule.delay,
              intl,
              showZero: true,
              showSeconds: true,
            })}
          </div>
          <div className="row-column flex-row flex-end-justify" style={{ width: 190 }}>
            <Popover
              content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
            >
              <Select
                style={{ width: '100%' }}
                value={statusFlag}
                disabled={userInfo.isReadUser}
                onChange={(statusFlag) => this.handleStatusChange(statusFlag, rowData, rowIndex)}
                dropdownMatchSelectWidth={false}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ),
                  this.statusOptions || [],
                )}
              </Select>
            </Popover>
          </div>
          <div className="row-column flex-col" style={{ width: userInfo.isReadUser ? 100 : 200 }}>
            {this.renderControl(rowData)}
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  handleCheckbox(e, rowData, rowIndex) {
    // Save the data and force update.
    const checked = e.target.checked || false;
    const { localPredictionRuleList } = this.state;
    const fontIndex = R.findIndex((item) => item.key === rowData.key, localPredictionRuleList);
    this.setState(
      {
        localPredictionRuleList: update(localPredictionRuleList, {
          [fontIndex]: { $set: { ...rowData, checked } },
        }),
      },
      () => {
        this.changeFilter({ patternBool: true, statusBool: true, statisticsBool: true });
      },
    );
  }

  @autobind
  handleStatusChange(statusFlag, rowData, rowIndex) {
    const { localPredictionRuleList } = this.state;
    const fontIndex = R.findIndex((item) => item.key === rowData.key, localPredictionRuleList);
    this.setState({
      localPredictionRuleList: update(localPredictionRuleList, {
        [fontIndex]: { $set: { ...rowData, statusFlag } },
      }),
    });
  }

  @autobind
  renderSourcePatterns(rowData) {
    const { intl, credentials, projectDisplayMap, projects, currentTheme, instanceDisplayNameMap } = this.props;
    const { rootCauseLevel } = this.state;
    const {
      incidentRule: {
        sourceDetailSet,
        incidentData,
        patternName,
        rulePrimaryKey: {
          rulePartitionKey: { userName, projectName, patternId = '' },
        },
      },
    } = rowData;
    let predictionSourceInfo = R.sortWith([R.descend(R.prop('matchedCount'))], sourceDetailSet);
    const noHasOldTimeStamp = R.find((item) => item.timeStamp !== 0, predictionSourceInfo || []);
    if (noHasOldTimeStamp) {
      predictionSourceInfo = R.sortWith([R.ascend(R.prop('timeStamp'))], sourceDetailSet);
    }
    let contents = R.addIndex(R.map)((item, index) => {
      const content = R.equals(item.type, 'Metric') ? item.content : `${item.content.substr(0, 50)}...`;
      return (
        <div
          key={`rootCauseContentKey${index}`}
          className="hidden-line-with-ellipsis inline-block max-width"
          style={{ display: 'block' }}
        >
          {`${predictionSourceInfo.length > 1 ? `• ` : ''}${content}`}
        </div>
      );
    }, predictionSourceInfo.slice(0, 2));

    if (predictionSourceInfo.length > 2) {
      const otherDom = (
        <div
          key="rootCauseContentKey..."
          className="hidden-line-with-ellipsis inline-block max-width"
          style={{ display: 'block' }}
        >
          <div
            className="flex-row flex-center-align"
            style={{ lineHeight: 1, fontSize: 14, fontWeight: 'bold', paddingBottom: 8 }}
          >
            ...
          </div>
        </div>
      );
      contents = [...contents, otherDom];
    }

    let incidentDataJson;
    try {
      incidentDataJson = JSON.parse(incidentData);
    } catch (error) {
      // console.debug(error)
    }
    const isMetricIncident = Boolean(
      incidentDataJson?.metricName &&
        incidentDataJson?.sign &&
        (incidentDataJson?.percentage || incidentDataJson?.percentage === 0),
    );

    return (
      <Popover
        placement="right"
        mouseEnterDelay={0.3}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 350, paddingRight: 8 }}>
            <div style={{ borderBottom: '1px dashed var(--virtualized-table-border-color)' }}>
              {R.addIndex(R.map)((item, index) => {
                const { projectName, instanceName, nid = '', componentName, content, metricDirection, duration } = item;
                const { patternName } = item;
                let { type } = item;
                const project = R.find((project) => project.projectShortName === projectName, projects || []);
                let { isLogType, eventType } = CausalParser.getRelationLogType(type);
                if (project && project.isDeployment) {
                  type = 'deployment';
                  isLogType = true;
                  eventType = 'deployment';
                }
                const sourceDetail = { ...item, isLogType, eventType, type };

                const projectNameReal = userName !== credentials.userName ? `${projectName}@${userName}` : projectName;
                let rawDataJson;
                try {
                  rawDataJson = JSON.parse(content);
                } catch (error) {
                  // console.debug(error)
                }

                const {
                  instanceDisplayName,
                  instanceName: iName,
                  containerName,
                } = getInstanceDisplayName(instanceDisplayNameMap, instanceName, {
                  pn: projectName,
                  owner: userName,
                });

                return (
                  <div key={index} className="flex-col" style={{ marginBottom: 16 }}>
                    <div className="flex-row">
                      <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                        {intl.formatMessage(eventMessages.rootCauseHop)}:
                      </div>
                      <div style={{ wordBreak: 'break-all' }}>{index + 1}</div>
                    </div>
                    <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                      <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                        {intl.formatMessage(appFieldsMessages.project)}:
                      </div>
                      <div style={{ wordBreak: 'break-all' }}>
                        {get(projectDisplayMap, projectNameReal, projectNameReal)}
                      </div>
                    </div>
                    {componentName && (
                      <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                        <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                          {intl.formatMessage(appFieldsMessages.component)}:
                        </div>
                        <div style={{ wordBreak: 'break-all' }}>{componentName}</div>
                      </div>
                    )}

                    {rootCauseLevel === 'instance' && (
                      <>
                        {containerName && (
                          <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                            <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                              {intl.formatMessage(appFieldsMessages.container)}:
                            </div>
                            <div style={{ wordBreak: 'break-all' }}>{containerName}</div>
                          </div>
                        )}
                        <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                          <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                            {intl.formatMessage(appFieldsMessages.instance)}:
                          </div>
                          <div style={{ wordBreak: 'break-all' }}>{iName}</div>
                        </div>
                        {instanceDisplayName && (
                          <div className="flex-row" style={{ display: 'inline-block', maxWidth: '100%' }}>
                            <div
                              className="light-label bold"
                              style={{ minWidth: 120, width: 120, display: 'inline-block' }}
                            >
                              {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                            </div>
                            <div style={{ wordBreak: 'break-all' }}>{instanceDisplayName}</div>
                          </div>
                        )}
                      </>
                    )}
                    <div className="flex-row">
                      <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                        {intl.formatMessage(appFieldsMessages.pattern)}:
                      </div>
                      <div style={{ wordBreak: 'break-all' }}>{`[${nid}]${
                        String(nid) !== patternName ? ` ${patternName || ''}` : ''
                      }`}</div>
                    </div>
                    {Boolean(duration) && (
                      <div className="flex-row">
                        <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                          {intl.formatMessage(appFieldsMessages.duration)}:
                        </div>
                        <div style={{ wordBreak: 'break-all' }}>
                          {CellRenderers.humanizeDuration({
                            period: duration,
                            intl,
                            showZero: true,
                            showSeconds: true,
                          })}
                        </div>
                      </div>
                    )}
                    <div className="flex-row">
                      <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                        {intl.formatMessage(appFieldsMessages.type)}:
                      </div>
                      <div style={{ wordBreak: 'break-all' }}>
                        {isLogType
                          ? CellRenderers.logTypeRenderer({ intl, rowData: { type: eventType }, isHorizontal: true })
                          : intl.formatMessage(appFieldsMessages.metric)}
                      </div>
                    </div>

                    <div className="full-width flex-row">
                      <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                        {intl.formatMessage(eventMessages.shortDescription)}:
                      </div>
                      {metricDirection && (
                        <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                          {EventRenderers.RenderMetricAnomalySummary({ intl, event: sourceDetail })}
                        </div>
                      )}
                    </div>

                    {!metricDirection && (
                      <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                        {!rawDataJson &&
                          R.join(
                            '\n',
                            R.filter((x) => Boolean(x), (content || '').split('\n')),
                          )}
                        {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                      </div>
                    )}
                  </div>
                );
              }, predictionSourceInfo)}
            </div>
            <div className="flex-col" style={{ marginBottom: 8, paddingBottom: 8, marginTop: 8 }}>
              <div className="full-width flex-row">
                <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                  {intl.formatMessage(appFieldsMessages.project)}:
                </div>
                <div style={{ wordBreak: 'break-all' }}>{projectName}</div>
              </div>
              <div className="full-width flex-row">
                <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                  {intl.formatMessage(appFieldsMessages.pattern)}:
                </div>
                <div style={{ wordBreak: 'break-all' }}>{`[${patternId}] ${
                  String(patternId) !== patternName ? ` ${patternName || ''}` : ''
                }`}</div>
              </div>
              <div className="full-width flex-row">
                <div className="light-label bold" style={{ minWidth: 120, display: 'inline-block' }}>
                  {intl.formatMessage(eventMessages.shortDescription)}:
                </div>
                {isMetricIncident && (
                  <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                    {EventRenderers.RenderMetricAnomalySummary({ intl, event: incidentDataJson })}
                  </div>
                )}
                {!isMetricIncident && (
                  <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                    {!incidentDataJson &&
                      R.join(
                        '\n',
                        R.filter((x) => Boolean(x), (incidentData || '').split('\n')),
                      )}
                    {incidentDataJson && <LogRenderers.JsonTree data={incidentDataJson} currentTheme={currentTheme} />}
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      >
        <div
          style={{
            overflow: 'hidden',
            height: contents.length === 1 ? '40px' : contents.length === 2 ? ' 50px' : '66px',
          }}
          className="max-width flex-col flex-center-justify"
        >
          {contents}
        </div>
      </Popover>
    );
  }

  @autobind
  renderControl(rowData) {
    const { intl, userInfo } = this.props;
    const { matchedCount, unmatchedCount, falsePositiveInfo, truePositiveInfo, rootCauseInfo } = rowData;

    return (
      <div className="flex-row">
        <Popover
          placement="left"
          trigger="click"
          content={
            <div
              className="flex-col"
              style={{
                maxWidth: 450,
                maxHeight: 300,
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120 }}>
                  {intl.formatMessage(settingsMessages.matchedCount)}:
                </div>
                <div>{matchedCount}</div>
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120 }}>
                  {intl.formatMessage(settingsMessages.unmatchedCount)}:
                </div>
                <div>{unmatchedCount}</div>
              </div>
              <div
                className="flex-row"
                style={
                  truePositiveInfo.length > 0
                    ? {
                        marginBottom: 8,
                        paddingBottom: 8,
                        borderBottom: '1px dashed var(--virtualized-table-border-color)',
                      }
                    : {}
                }
              >
                <div className="light-label bold" style={{ width: 120, lineHeight: '22px' }}>
                  {intl.formatMessage(settingsMessages.truePositiveInfo)}:
                </div>
                <div className="flex-col">
                  {R.map(
                    (item) => (
                      <div key={item.t} className="flex-row flex-center-align" style={{ lineHeight: '24px' }}>
                        <span style={{ width: 126 }}>{moment.utc(item.t).format(Defaults.DateTimeFormat)}</span>
                        {item.s !== 2 && (
                          <>
                            {item.s !== undefined && (
                              <Button
                                size="small"
                                style={{ paddingTop: 0, paddingBottom: 0, height: 20 }}
                                onClick={() => this.handleIncidentJump(rowData, item)}
                              >
                                {intl.formatMessage(appFieldsMessages.details)}
                              </Button>
                            )}
                            {item.s === 0 && <RootCauseIcon style={{ color: '#FF5142', margin: '0 8px' }} />}
                            {item.s === 1 && <IncidentPredictIcon style={{ color: '#FF5142', margin: '0 8px' }} />}
                          </>
                        )}
                      </div>
                    ),
                    truePositiveInfo || [],
                  )}
                </div>
              </div>
              <div
                className="flex-row"
                style={
                  falsePositiveInfo.length > 0
                    ? {
                        marginBottom: 8,
                        paddingBottom: 8,
                        borderBottom: '1px dashed var(--virtualized-table-border-color)',
                      }
                    : {}
                }
              >
                <div className="light-label bold" style={{ width: 120, lineHeight: '22px' }}>
                  {intl.formatMessage(settingsMessages.falsePositiveInfo)}:
                </div>
                <div className="flex-col">
                  {R.map(
                    (item) => (
                      <div key={item.t} className="flex-row flex-center-align" style={{ lineHeight: '24px' }}>
                        <span style={{ width: 126 }}>{moment.utc(item.t).format(Defaults.DateTimeFormat)}</span>
                        {item.s !== 2 && (
                          <>
                            {item.s !== undefined && (
                              <Button
                                size="small"
                                style={{ paddingTop: 0, paddingBottom: 0, height: 20 }}
                                onClick={() => this.handleIncidentJump(rowData, item)}
                              >
                                {intl.formatMessage(appFieldsMessages.details)}
                              </Button>
                            )}
                            {item.s === 0 && <RootCauseIcon style={{ color: '#FF5142', margin: '0 8px' }} />}
                            {item.s === 1 && <IncidentPredictIcon style={{ color: '#FF5142', margin: '0 8px' }} />}
                          </>
                        )}
                      </div>
                    ),
                    falsePositiveInfo || [],
                  )}
                </div>
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 120, lineHeight: '22px' }}>
                  {intl.formatMessage(settingsMessages.rootCauseInfo)}:
                </div>
                <div className="flex-col">
                  {R.map(
                    (item) => (
                      <div key={item.t} className="flex-row flex-center-align" style={{ lineHeight: '24px' }}>
                        <span style={{ width: 126 }}>{moment.utc(item.t).format(Defaults.DateTimeFormat)}</span>
                        <Button
                          size="small"
                          style={{ paddingTop: 0, paddingBottom: 0, height: 20 }}
                          onClick={() => this.handleIncidentJump(rowData, { ...item, s: 0 })}
                        >
                          {intl.formatMessage(appFieldsMessages.details)}
                        </Button>
                      </div>
                    ),
                    rootCauseInfo || [],
                  )}
                </div>
              </div>
            </div>
          }
        >
          <Button size="small" style={{ marginRight: 8 }}>
            {intl.formatMessage(appFieldsMessages.statistics)}
          </Button>
        </Popover>

        {/* <Button
          type="primary"
          size="small"
          style={{ marginLeft: 8 }}
          onClick={() => this.handleConfigActionsClick(rowData)}
        >
          {intl.formatMessage(eventMessages.takeAction)}
        </Button> */}

        {!userInfo.isReadUser && (
          <Dropdown
            name={intl.formatMessage(eventMessages.actions)}
            align={{ offset: [80, 0] }}
            butStyle={{ width: 90 }}
            itemClick={({ key }) => {
              switch (key) {
                case 'recommendations':
                  this.handleReCommendation(rowData);
                  break;
                case 'savedAction':
                  this.setState({ showSavedActionModal: true, addSavedActionRow: rowData });
                  break;
                default:
                  break;
              }
            }}
          >
            <>
              <Menu.Item key="recommendations" icon={<CommentOutlined />}>
                {intl.formatMessage(eventMessages.addRecommendation)}
              </Menu.Item>
              <Menu.Item key="savedAction" icon={<ToolFilled />}>
                {intl.formatMessage(eventActionMessages.addSavedAction)}
              </Menu.Item>
            </>
          </Dropdown>
        )}
      </div>
    );
  }

  @autobind
  handleReCommendation(rowData) {
    this.setState({ addRecommendationModalFlag: true, addRecommendationActive: rowData });
  }

  @autobind
  handleConfigActionsClick(rowData) {
    const { location, credentials, systemsMap, projectDisplayMap } = this.props;
    const { systemId } = parseLocation(location);
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const systemProjectNameList = R.map((item) => {
      const projectNameReal =
        item.customerName !== credentials.userName ? `${item.projectName}@${item.customerName}` : item.projectName;
      return {
        projectNameReal,
        projectDisplayName: get(projectDisplayMap, projectNameReal, projectNameReal),
      };
    }, get(systemInfo, 'projectDetailsList', []));

    let actionProjectNameList = [];
    let actionKeySet = [];
    const actionIncidentPredictionLibKeys = [];

    const {
      projectActionKeySet,
      incidentRule: {
        sourceDetailSet,
        rulePrimaryKey: { rulePartitionKey },
      },
    } = rowData;
    const { projectName, userName } = rulePartitionKey;
    const predictionSourceInfo = sourceDetailSet;
    R.forEach((item) => {
      actionProjectNameList.push(`${projectName}@${userName}`);
    }, predictionSourceInfo);

    actionProjectNameList = R.uniq(actionProjectNameList);
    actionKeySet = [...actionKeySet, ...(projectActionKeySet || [])];
    actionKeySet = R.uniqWith(R.eqBy(R.prop('actionId')), R.uniq(actionKeySet));
    actionIncidentPredictionLibKeys.push({ key: rulePartitionKey });

    this.setState({
      showSystemPredictionRuleActionModal: true,
      actionProjectNameList,
      actionKeySet,
      actionIncidentPredictionLibKeys,
      systemProjectNameList,
    });
  }

  @autobind
  handleMultipleConfigActionsClick() {
    const { location, credentials, systemsMap, projectDisplayMap } = this.props;
    const { systemId } = parseLocation(location);
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const systemProjectNameList = R.map((item) => {
      const projectNameReal =
        item.customerName !== credentials.userName ? `${item.projectName}@${item.customerName}` : item.projectName;
      return {
        projectNameReal,
        projectDisplayName: get(projectDisplayMap, projectNameReal, projectNameReal),
      };
    }, get(systemInfo, 'projectDetailsList', []));

    let actionProjectNameList = [];
    let actionKeySet = [];
    const actionIncidentPredictionLibKeys = [];

    const { localPredictionRuleList } = this.state;
    const diffChecked = R.filter((item) => item.checked, localPredictionRuleList);
    R.forEach((item) => {
      const { projectActionKeySet, incidentRule } = item;
      const { sourceDetailSet: predictionSourceInfo, rulePrimaryKey } = incidentRule;
      const { rulePartitionKey } = rulePrimaryKey;
      const { userName } = rulePartitionKey;

      R.forEach((item) => {
        const { projectName } = item;
        actionProjectNameList.push(`${projectName}@${userName}`);
      }, predictionSourceInfo);
      actionKeySet = [...actionKeySet, ...(projectActionKeySet || [])];
      actionIncidentPredictionLibKeys.push({ key: rulePartitionKey });
    }, diffChecked);
    actionProjectNameList = R.uniq(actionProjectNameList);
    actionKeySet = R.uniqWith(R.eqBy(R.prop('actionId')), R.uniq(actionKeySet));
    this.setState({
      showSystemPredictionRuleActionModal: true,
      actionProjectNameList,
      actionKeySet,
      actionIncidentPredictionLibKeys,
      systemProjectNameList,
    });
  }

  @autobind
  handleIncidentJump(rowData, positiveInfo) {
    const { projects } = this.props;
    const { incidentList, activeIncidentKey } = this.state;
    const incident = R.find((item) => item.key === activeIncidentKey, incidentList || []);
    const isExternal = (incident?.patternId || [])[0] >= 1000000;
    const { t: timestamp, s: sourceFlag, libRootCause, i: matchedInstanceName, p: matchedProject, pid } = positiveInfo;
    const {
      incidentRule: {
        rulePrimaryKey: { rulePartitionKey },
      },
    } = rowData;
    const { projectName, instanceName: incidentInstanceName, patternId: incidentPatternId } = rulePartitionKey || {};
    const project = R.find((project) => project.projectShortName === (matchedProject || projectName), projects || []);
    const { systemId, owner } = project || {};
    const { rootCauseKey } = libRootCause || {};

    const startTime = moment.utc(timestamp).format(Defaults.DateFormat);
    if (sourceFlag === 1) {
      const query = {
        redirect: true,
        environmentId: 'All',
        startTime,
        endTime: startTime,
        customerName: owner,
        systemId,

        hideIgnore: false,
        typeFilter: 'all',
        eventPatternId: isExternal ? pid : incidentPatternId,
        eventProjectName: matchedProject || projectName,
        eventInstanceName: matchedInstanceName || incidentInstanceName,
        eventTimestamp: timestamp,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemPrediction, {}, query), '_blank');
    } else {
      const query = {
        environmentId: 'All',
        startTime,
        endTime: startTime,
        customerName: owner,
        systemId,

        eventCategory: 'incident',
        eventPatternType: 'incident',
        eventPatternId: isExternal ? pid : incidentPatternId,
        eventProjectName: matchedProject || projectName,
        eventInstanceName: matchedInstanceName || incidentInstanceName,
        eventTimestamp: timestamp,
        eventRootCauseKey: rootCauseKey,
        hideIgnore: false,
      };
      window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    }
  }

  @autobind
  sortTable({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.descend(R.prop('matchedCount'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortByList, sortDirectionList, localPredictionRuleList } = this.state;
      let sortDir = sortDirectionList === 'ASC' ? 'DESC' : sortDirectionList === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortByList) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortByList: name, sortDirectionList: sortDir }, () => {
          const newEventList = this.sortData(localPredictionRuleList, name, sortDir);
          this.setState({ localPredictionRuleList: newEventList });
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  renderPatternId(props) {
    const { intl, currentTheme } = this.props;
    const { rowData, cellData } = props;
    const { patternName, key } = rowData;
    return (
      <Popover
        placement="right"
        content={
          <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 350, paddingRight: 8 }}>
            <div
              className="flex-row"
              style={{
                marginBottom: 8,
                paddingBottom: 8,
                borderBottom: '1px dashed var(--virtualized-table-border-color)',
              }}
            >
              <div className="light-label bold" style={{ minWidth: 110, display: 'inline-block' }}>
                {intl.formatMessage(eventMessages.patternName)}:
              </div>
              <div style={{ wordBreak: 'break-all' }}>{patternName}</div>
            </div>
            {this.causeMap[key] && (
              <>
                {R.addIndex(R.map)((item, index) => {
                  const { incidentData, rulePrimaryKey, patternName } = item || {};
                  const { rulePartitionKey } = rulePrimaryKey || {};
                  const { projectName, patternId = '' } = rulePartitionKey || {};
                  let incidentDataJson;
                  try {
                    incidentDataJson = JSON.parse(incidentData);
                  } catch (error) {
                    // console.debug(error)
                  }
                  const isMetricIncident = Boolean(
                    incidentDataJson?.metricName &&
                      incidentDataJson?.sign &&
                      (incidentDataJson?.percentage || incidentDataJson?.percentage === 0),
                  );
                  return (
                    <div key={index} className="flex-col">
                      <div className="full-width flex-row">
                        <div className="light-label bold" style={{ minWidth: 110, display: 'inline-block' }}>
                          {intl.formatMessage(appFieldsMessages.project)}:
                        </div>
                        <div style={{ wordBreak: 'break-all' }}>{projectName}</div>
                      </div>
                      <div className="full-width flex-row">
                        <div className="light-label bold" style={{ minWidth: 110, display: 'inline-block' }}>
                          {intl.formatMessage(appFieldsMessages.pattern)}:
                        </div>
                        <div style={{ wordBreak: 'break-all' }}>{`[${patternId}] ${
                          String(patternId) !== patternName ? ` ${patternName || ''}` : ''
                        }`}</div>
                      </div>
                      <div className="full-width flex-row">
                        <div className="light-label bold" style={{ minWidth: 110, display: 'inline-block' }}>
                          {intl.formatMessage(eventMessages.shortDescription)}:
                        </div>
                        {isMetricIncident && (
                          <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
                            {EventRenderers.RenderMetricAnomalySummary({ intl, event: incidentDataJson })}
                          </div>
                        )}
                        {!isMetricIncident && (
                          <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                            {!incidentDataJson &&
                              R.join(
                                '\n',
                                R.filter((x) => Boolean(x), (incidentData || '').split('\n')),
                              )}
                            {incidentDataJson && (
                              <LogRenderers.JsonTree data={incidentDataJson} currentTheme={currentTheme} />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }, this.causeMap[key])}
              </>
            )}
          </div>
        }
      >{`[${cellData}]${cellData.toString() === patternName ? '' : ` ${patternName || ''}`}`}</Popover>
    );
  }

  @autobind
  renderContent({ cellData, dataKey }) {
    const { projects, instanceDisplayNameMap } = this.props;
    const { rootCauseProject } = this.state;

    let viewContent = cellData;
    if (dataKey === 'instanceName') {
      const [projectName] = rootCauseProject.split('@');
      const project = R.find((item) => {
        return item.projectShortName === projectName || item.projectNameReal === projectName;
      }, projects);
      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, cellData, {
        pn: project?.projectShortName,
        owner: project?.owner,
      });
      viewContent = instanceStr;
    }

    return (
      <Popover content={dataKey === 'patternId' ? `[${cellData}]` : viewContent} mouseEnterDelay={0.3}>
        <div className="full-width hidden-line-with-ellipsis">
          {dataKey === 'patternId' ? `[${cellData}]` : viewContent}
        </div>
      </Popover>
    );
  }

  @autobind
  handleIncidentClick(rowData) {
    const { rootCauseProject, rootCauseLevel, pageSize, sortDirectionList } = this.state;
    let { page } = this.state;
    const [projectName, customerName] = (rootCauseProject || '').split('@');
    const { instanceName, patternId, key: keyId, instanceNameList, componentName } = rowData;
    this.setState({
      isLoadingLocalList: true,
      activeIncidentKey: keyId,
      isLoading: true,
      filterKBType: 'all',
      filterSourcePatternIds: [],
      checkAll: false,
    });
    if (!this.jumpMergeKey) {
      this.jumpKey = undefined;
    }
    const requests = [];
    R.forEach((item) => {
      requests.push(
        fetchGet(getEndpoint('knowledge-base', 1), {
          projectName,
          customerName: customerName || this.props?.userInfo?.userName,
          ...(rootCauseLevel === 'instance' ? { instanceName: item } : { componentName }),
          patternId: JSON.stringify(patternId),
          ruleType: rootCauseLevel === 'instance' ? 'InstanceLevelRuleType' : 'ComponentLevelRuleType',
        }),
      );
    }, instanceNameList || []);
    Promise.all(requests)
      .then((results) => {
        const res = [];
        R.forEach((item) => {
          if (!R.isEmpty(item)) {
            if (item) {
              res.push(...item);
            }
          }
        }, results);
        let predictionRuleList = R.map((item) => {
          const { incidentRule, ruleType } = item;
          const {
            sourceDetailSet,
            rulePrimaryKey: {
              rulePartitionKey: { instanceName, patternId, projectName, userName },
              uniqueKey,
            },
          } = incidentRule;

          const sourcePatternIds = R.sort((a, b) => a - b, R.uniq(R.map((_item) => _item.nid, sourceDetailSet || [])));

          const key = `${projectName}-${instanceName}-${patternId}-${userName}-${ruleType}-${uniqueKey}`;
          return { ...item, key, sourcePatternIds, checked: false };
        }, res || []);
        predictionRuleList = R.sortWith([R.descend(R.prop('matchedCount'))])(predictionRuleList);

        if (!R.has(keyId, this.causeMap)) {
          this.causeMap[keyId] = this.setCauseMap(predictionRuleList);
        }

        let sourcePatternListOptions = [];
        R.forEach((item) => {
          sourcePatternListOptions.push(...(item.sourcePatternIds || []));
        }, predictionRuleList || []);
        sourcePatternListOptions = R.map(
          (item) => ({ value: item, label: item }),
          R.sort((a, b) => a - b, R.uniq(sourcePatternListOptions)),
        );

        const findItemIdx = R.findIndex((item) => item.key === this.jumpKey, predictionRuleList);
        if (findItemIdx >= pageSize) {
          page = Math.ceil((findItemIdx + 1) / pageSize);
        }
        let localPredictionRuleList = R.slice((page - 1) * pageSize, page * pageSize, predictionRuleList);
        localPredictionRuleList = this.sortData(localPredictionRuleList, 'matchedCount', sortDirectionList);

        this.setState(
          {
            page,
            localPredictionRuleList,
            incidentPredictionRuleList: predictionRuleList,
            filterPredictionRuleList: predictionRuleList,
            currentIncident: rowData,
            isLoadingLocalList: false,
            isLoading: false,
            sourcePatternListOptions,
          },
          () => {
            if (this.jumpKey) {
              this.jumpToItem({
                incidentList: predictionRuleList,
                activeKey: this.jumpKey,
                node: this.listNode,
                isPage: true,
              });
            }
            this.cellMeasureCache.clearAll();
            if (this.listNode) {
              this.listNode.forceUpdateGrid();
              this.forceUpdate();
            }
          },
        );
      })
      .catch((err) => {
        this.setState({
          isLoadingLocalList: false,
          localPredictionRuleList: [],
          incidentPredictionRuleList: [],
          filterPredictionRuleList: [],
          isLoading: false,
          sourcePatternListOptions: [],
        });
      });
  }

  @autobind
  setCauseMap(predictionRuleList) {
    const ruleList = [predictionRuleList[0].incidentRule];
    return ruleList;
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const statisticsStartTimeObj = moment.utc(timeObj.valueOf());
    const { statisticsEndTimeObj } = this.state;

    statisticsEndTimeObj.startOf('day');
    statisticsStartTimeObj.startOf('day');

    this.setState(
      {
        statisticsStartTimeObj,
        statisticsEndTimeObj: timeScopeControl(statisticsStartTimeObj, statisticsEndTimeObj, timeObj, 'add'),
        page: 1,
      },
      () => {
        this.changeFilter({ patternBool: true, statusBool: true, statisticsBool: true });
      },
    );
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const statisticsEndTimeObj = moment.utc(timeObj.valueOf());
    const { statisticsStartTimeObj } = this.state;

    statisticsEndTimeObj.startOf('day');
    statisticsStartTimeObj.startOf('day');

    this.setState(
      {
        statisticsEndTimeObj,
        statisticsStartTimeObj: timeScopeControl(statisticsStartTimeObj, statisticsEndTimeObj, timeObj, 'subtract'),
        page: 1,
      },
      () => {
        this.changeFilter({ patternBool: true, statusBool: true, statisticsBool: true });
      },
    );
  }

  @autobind
  changeFilter({ patternBool, statusBool, statisticsBool }) {
    const {
      localPredictionRuleList,
      filterSourcePatternIds,
      filterStatus,
      statisticsFilterType,
      statisticsStartTimeObj,
      statisticsEndTimeObj,
      filterKBType,
    } = this.state;
    let newLocalPredictionRuleList = localPredictionRuleList;

    if (filterKBType === 'distributed') {
      newLocalPredictionRuleList = R.filter((item) => {
        const projectName = item?.incidentRule?.rulePrimaryKey?.rulePartitionKey?.projectName;
        const isDistributed = R.filter((item) => item.p && projectName !== item.p, item.rootCauseInfo || []).length > 0;
        return isDistributed;
      }, newLocalPredictionRuleList);
    } else if (filterKBType === 'local') {
      newLocalPredictionRuleList = R.filter((item) => {
        const projectName = item?.incidentRule?.rulePrimaryKey?.rulePartitionKey?.projectName;
        const isDistributed = R.filter((item) => item.p && projectName !== item.p, item.rootCauseInfo || []).length > 0;
        return !isDistributed;
      }, newLocalPredictionRuleList);
    }

    if (patternBool && filterSourcePatternIds && filterSourcePatternIds.length > 0) {
      newLocalPredictionRuleList = R.filter((item) => {
        return R.difference(filterSourcePatternIds, item.sourcePatternIds).length === 0;
      }, newLocalPredictionRuleList);
    }

    if (statusBool && (filterStatus || filterStatus === 0)) {
      newLocalPredictionRuleList = R.filter((item) => item.statusFlag === filterStatus, newLocalPredictionRuleList);
    }

    if (statisticsBool && statisticsFilterType !== 'all' && statisticsStartTimeObj && statisticsEndTimeObj) {
      const startTime = statisticsStartTimeObj.clone().startOf('days').valueOf();
      const endTime = statisticsEndTimeObj.clone().endOf('days').valueOf();
      newLocalPredictionRuleList = R.filter((item) => {
        item.rootCauseInfo = R.filter((_item) => startTime <= _item.t && _item.t <= endTime, item.rootCauseInfo || []);
        return item.rootCauseInfo && item.rootCauseInfo.length > 0;
      }, R.clone(newLocalPredictionRuleList) || []);
    }

    const checkAll = newLocalPredictionRuleList.length > 0 && !R.find((m) => !m.checked, newLocalPredictionRuleList);
    this.setState({ checkAll }, () => {
      this.cellMeasureCache.clearAll();
      if (this.listNode) {
        this.listNode.forceUpdateGrid();
        this.forceUpdate();
      }
    });
  }

  @autobind
  handleDownload() {
    const { credentials, userInfo } = this.props;
    const { rootCauseProject, rootCauseLevel } = this.state;
    const [projectName, customerName] = (rootCauseProject || '').split('@');

    this.setState({ downloadFileLoading: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('kb-file'), {
      ...credentials,
      operation: 'download',
      projectName,
      UserName: customerName || userInfo?.userName,
      ruleType: rootCauseLevel === 'instance' ? 'InstanceLevelRuleType' : 'ComponentLevelRuleType',
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          message.success('The file information link will be sent to your email in 1 to 20 minutes!');
        } else {
          message.error(msg);
        }
        this.setState({ downloadFileLoading: false });
      })
      .catch((err) => {
        message.error(err.message || String(err));
        this.setState({ downloadFileLoading: false });
      });
  }

  render() {
    const { intl, location, isLoadingSystem, credentials, userInfo } = this.props;
    const {
      isLoaded,
      isLoading,
      isLoadingLocalList,
      isSubmitting,

      rootCauseProject,
      rootCauseLevel,
      filterIncidentComponent,
      filterIncidentInstance,
      filterSourcePatternIds,
      filterPatternId,
      filterStatus,

      incidentList,
      activeIncidentKey,
      incidentPredictionRuleList,
      localPredictionRuleList,
      filterPredictionRuleList,

      page,
      pageSize,
      checkAll,

      sortBy,
      sortDirection,

      sortByList,
      sortDirectionList,

      addRecommendationModalFlag,
      addRecommendationActive,
      addSavedActionRow,

      statisticsFilterType,
      statisticsStartTimeObj,
      statisticsEndTimeObj,
      filterKBType,
    } = this.state;
    const { systemId } = parseLocation(location);

    const diff = R.difference(localPredictionRuleList, filterPredictionRuleList);
    const hasError = diff.length === 0;
    const diffChecked = R.filter((item) => item.checked, localPredictionRuleList);
    const hasErrorRemove = diffChecked.length === 0;

    let newLocalPredictionRuleList = localPredictionRuleList || [];

    if (filterKBType === 'distributed') {
      newLocalPredictionRuleList = R.filter((item) => {
        const projectName = item?.incidentRule?.rulePrimaryKey?.rulePartitionKey?.projectName;
        const isDistributed = R.filter((item) => item.p && projectName !== item.p, item.rootCauseInfo || []).length > 0;
        return isDistributed;
      }, newLocalPredictionRuleList);
    } else if (filterKBType === 'local') {
      newLocalPredictionRuleList = R.filter((item) => {
        const projectName = item?.incidentRule?.rulePrimaryKey?.rulePartitionKey?.projectName;
        const isDistributed = R.filter((item) => item.p && projectName !== item.p, item.rootCauseInfo || []).length > 0;
        return !isDistributed;
      }, newLocalPredictionRuleList);
    }

    if (filterSourcePatternIds && filterSourcePatternIds.length > 0) {
      newLocalPredictionRuleList = R.filter((item) => {
        return R.difference(filterSourcePatternIds, item.sourcePatternIds).length === 0;
      }, newLocalPredictionRuleList);
    }

    if (filterStatus || filterStatus === 0) {
      newLocalPredictionRuleList = R.filter((item) => item.statusFlag === filterStatus, newLocalPredictionRuleList);
    }

    if (statisticsFilterType !== 'all' && statisticsStartTimeObj && statisticsEndTimeObj) {
      const startTime = statisticsStartTimeObj.clone().startOf('days').valueOf();
      const endTime = statisticsEndTimeObj.clone().endOf('days').valueOf();
      newLocalPredictionRuleList = R.filter((item) => {
        item.rootCauseInfo = R.filter((_item) => startTime <= _item.t && _item.t <= endTime, item.rootCauseInfo || []);
        return item.rootCauseInfo && item.rootCauseInfo.length > 0;
      }, R.clone(newLocalPredictionRuleList) || []);
    }

    return (
      <div
        className={`full-height flex-row ${isLoading || isLoadingSystem ? 'loading ' : ''} ${
          isLoaded ? ' loaded' : ''
        }`}
        style={{ paddingTop: 8 }}
      >
        <Container className="flex-grow flex-min-height flex-col">
          <Spin spinning={isLoadingSystem} wrapperClassName="full-width full-height spin-full-width">
            <div className="full-width full-height flex-col">
              <div className="flex-row flex-center-align flex-wrap" style={{ padding: '0px 0px 8px 8px' }}>
                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                  <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.incidentProject)}:</div>
                  <Select
                    showSearch
                    size="small"
                    style={{ width: 150 }}
                    placeholder={intl.formatMessage(eventMessages.rootCauseProject)}
                    value={rootCauseProject}
                    optionFilterProp="value"
                    onChange={this.onChangeRootCauseProject}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.projectNameReal} value={item.projectNameReal}>
                          {item.projectDisplayName || item.projectNameReal}
                        </Select.Option>
                      );
                    }, this.projectList || [])}
                  </Select>
                </div>

                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                  <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.ruleType)}:</div>
                  <Select
                    showSearch
                    size="small"
                    style={{ width: 105 }}
                    placeholder={intl.formatMessage(eventMessages.rootCauseLevel)}
                    value={rootCauseLevel}
                    optionFilterProp="value"
                    onChange={this.onChangeRootCauseLevel}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                    disabled={isLoading || isLoadingLocalList}
                  >
                    <Select.Option value="component">{intl.formatMessage(appFieldsMessages.component)}</Select.Option>
                    <Select.Option value="instance">{intl.formatMessage(appFieldsMessages.instance)}</Select.Option>
                  </Select>
                </div>

                {rootCauseLevel === 'instance' && (
                  <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                    <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.instanceName)}:</div>
                    <Select
                      allowClear
                      showSearch
                      size="small"
                      style={{ width: 150 }}
                      placeholder={intl.formatMessage(eventMessages.incidentInstance)}
                      options={this.incidentInstanceListOptions}
                      value={filterIncidentInstance}
                      onChange={this.onChangeFilterIncidentInstance}
                      optionFilterProp="label"
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                      disabled={isLoading || isLoadingLocalList}
                      filterOption={(input, option) =>
                        (option?.value ?? '').toLowerCase().includes(input.toLowerCase()) ||
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </div>
                )}
                {rootCauseLevel === 'component' && (
                  <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                    <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.componentName)}:</div>
                    <Select
                      allowClear
                      showSearch
                      size="small"
                      style={{ width: 150 }}
                      placeholder={intl.formatMessage(eventMessages.incidentComponent)}
                      options={this.incidentComponentListOptions}
                      value={filterIncidentComponent}
                      onChange={this.onChangeFilterIncidentComponent}
                      optionFilterProp="label"
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                      disabled={isLoading || isLoadingLocalList}
                    />
                  </div>
                )}

                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                  <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.incidentPattern)}:</div>
                  <Select
                    allowClear
                    showSearch
                    size="small"
                    style={{ width: 100 }}
                    placeholder={intl.formatMessage(eventMessages.incidentPattern)}
                    value={filterPatternId}
                    optionFilterProp="value"
                    onChange={this.onChangeFilterPatternId}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                    disabled={isLoading || isLoadingLocalList}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.patternListOptions)}
                  </Select>
                </div>

                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                  <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.knowledgeBaseType)}:</div>
                  <Select
                    size="small"
                    style={{ width: 200, marginRight: 8 }}
                    value={filterKBType}
                    onChange={this.onChangeFilterKBType}
                    options={[
                      { value: 'all', label: 'All' },
                      { value: 'distributed', label: 'Distributed knowledge base' },
                      { value: 'local', label: 'Local knowledge base' },
                    ]}
                  />
                </div>

                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                  <div style={{ marginRight: 10 }}>{intl.formatMessage(eventMessages.rootCausePattern)}:</div>
                  <Select
                    allowClear
                    showSearch
                    size="small"
                    style={{ width: 100 }}
                    placeholder={intl.formatMessage(eventMessages.rootCausePattern)}
                    mode="multiple"
                    value={filterSourcePatternIds}
                    optionFilterProp="value"
                    onChange={(filterSourcePatternIds) =>
                      this.setState({ filterSourcePatternIds, page: 1 }, () => {
                        this.changeFilter({ patternBool: true });
                      })
                    }
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.state.sourcePatternListOptions)}
                  </Select>
                </div>

                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                  <div style={{ marginRight: 10 }}>{intl.formatMessage(appFieldsMessages.status)}:</div>
                  <Select
                    allowClear
                    showSearch
                    size="small"
                    style={{ width: 100 }}
                    placeholder={intl.formatMessage(appFieldsMessages.status)}
                    options={this.statusOptions}
                    value={filterStatus}
                    onChange={(filterStatus) =>
                      this.setState({ filterStatus, page: 1 }, () => {
                        this.changeFilter({ patternBool: true, statusBool: true });
                      })
                    }
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  />
                </div>

                <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0', width: 250 }}>
                  <div style={{ marginRight: 10 }}>{intl.formatMessage(appFieldsMessages.statistics)}:</div>
                  <Select
                    size="small"
                    style={{ width: 100, marginRight: 8 }}
                    value={statisticsFilterType}
                    onChange={(statisticsFilterType) =>
                      this.setState({ statisticsFilterType }, () => {
                        this.changeFilter({ patternBool: true, statusBool: true, statisticsBool: true });
                      })
                    }
                    options={[
                      { value: 'all', label: 'All' },
                      { value: 'timeFilter', label: 'Time Filter' },
                    ]}
                  />
                  {statisticsFilterType !== 'all' && (
                    <div>
                      <DatePicker
                        size="small"
                        allowClear={false}
                        showToday={false}
                        value={statisticsStartTimeObj}
                        disabledDate={(current) => {
                          return current && current > moment.utc().add(1, 'days').endOf('day');
                        }}
                        onChange={this.handleStartTimeChange}
                      />
                      <span style={{ margin: '0 8px' }}>-</span>
                      <DatePicker
                        size="small"
                        allowClear={false}
                        showToday={false}
                        value={statisticsEndTimeObj}
                        disabledDate={(current) => {
                          return current && current > moment.utc().add(1, 'days').endOf('day');
                        }}
                        onChange={this.handleEndTimeChange}
                      />
                    </div>
                  )}
                </div>

                <div className="flex-grow" />

                <Button
                  size="small"
                  icon={<DownloadOutlined />}
                  style={{ margin: '8px 16px 0 0' }}
                  onClick={this.handleDownload}
                  loading={this.state.downloadFileLoading}
                >
                  {intl.formatMessage(appButtonsMessages.download)}
                </Button>

                <Button
                  size="small"
                  icon={<UploadOutlined />}
                  style={{ margin: '8px 16px 0 0' }}
                  onClick={() => this.setState({ uploadFlagModal: true })}
                >
                  {intl.formatMessage(appButtonsMessages.upload)}
                </Button>
              </div>

              <div className="flex-grow flex-min-width flex-min-height flex-row" style={{ padding: 8 }}>
                <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
                  <div style={{ width: '30%', maxWidth: 320, marginRight: 16 }}>
                    <AutoSizer>
                      {({ width, height }) => (
                        <Table
                          className="with-border"
                          ref={(c) => {
                            this.dataTable = c;
                          }}
                          width={width}
                          height={height}
                          headerHeight={40}
                          rowHeight={40}
                          rowCount={(incidentList || []).length}
                          rowGetter={({ index }) => (incidentList || [])[index]}
                          onRowClick={({ rowData }) => {
                            this.handleIncidentClick(rowData);
                          }}
                          rowClassName={({ index }) => {
                            let className = 'clickable';
                            className += index >= 0 && index % 2 === 1 ? ' odd-row' : '';
                            // Ignore header row.
                            if (index >= 0) {
                              const incident = (incidentList || [])[index];
                              if (incident) {
                                if (incident.key === activeIncidentKey) {
                                  className += ' active';
                                }
                              }
                            }
                            return className;
                          }}
                          sort={this.sortTable}
                          sortBy={sortBy}
                          sortDirection={sortDirection}
                        >
                          <Column
                            width={100}
                            flexGrow={1}
                            label={intl.formatMessage(eventMessages.incidentPattern)}
                            dataKey="patternId"
                            cellRenderer={this.renderPatternId}
                            headerRenderer={this.headerRenderer}
                          />
                          {rootCauseLevel === 'instance' && (
                            <Column
                              width={120}
                              flexGrow={1}
                              label={intl.formatMessage(eventMessages.incidentInstance)}
                              dataKey="instanceName"
                              cellRenderer={this.renderContent}
                              headerRenderer={this.headerRenderer}
                            />
                          )}
                          {rootCauseLevel === 'component' && (
                            <Column
                              width={120}
                              flexGrow={1}
                              label={intl.formatMessage(eventMessages.incidentComponent)}
                              dataKey="componentName"
                              cellRenderer={this.renderContent}
                              headerRenderer={this.headerRenderer}
                            />
                          )}
                          {/* <Column
                            width={40}
                            label={null}
                            dataKey="kbIgnoreFlag"
                            cellRenderer={this.renderKBIgnoreFlag}
                            disableSort
                          /> */}
                        </Table>
                      )}
                    </AutoSizer>
                  </div>

                  <div className="flex-grow flex-min-width flex-col">
                    <div className="flex-grow flex-min-height">
                      <Spin spinning={isLoadingLocalList} wrapperClassName="full-width full-height spin-full-height">
                        <AutoSizer>
                          {({ width, height }) => (
                            <div className="event-list">
                              <div
                                className="event-list-header"
                                style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 8 : 0 }}
                              >
                                <div className="header-column flex-center-justify" style={{ width: 40, minWidth: 40 }}>
                                  <Checkbox checked={checkAll} onChange={this.handleCheckAllChange} />
                                </div>
                                <div
                                  className="header-column flex-center-justify"
                                  style={{ width: 40, minWidth: 40 }}
                                />
                                <div
                                  className="header-column"
                                  style={{ width: 110, minWidth: 110 }}
                                  onClick={this.headerClick('matchedCount')}
                                >
                                  <span>{intl.formatMessage(eventMessages.truePositive)}</span>
                                  {this.sortIcon(sortByList, sortDirectionList, 'matchedCount')}
                                </div>
                                <div
                                  className="header-column"
                                  style={{ width: 110, minWidth: 110 }}
                                  onClick={this.headerClick('unmatchedCount')}
                                >
                                  <span>{intl.formatMessage(eventMessages.falsePositive)}</span>
                                  {this.sortIcon(sortByList, sortDirectionList, 'unmatchedCount')}
                                </div>
                                <div className="header-column" style={{ width: 160, flex: 1 }}>
                                  {intl.formatMessage(eventMessages.rootCauseMetricOrLog)}
                                </div>
                                <div className="header-column" style={{ width: 140 }}>
                                  {intl.formatMessage(appFieldsMessages.delay)}
                                </div>
                                <div className="header-column" style={{ width: 190 }}>
                                  {intl.formatMessage(appFieldsMessages.status)}
                                </div>
                                <div className="header-column" style={{ width: userInfo.isReadUser ? 100 : 200 }} />
                              </div>
                              <List
                                className="event-list-grid"
                                ref={(listNode) => {
                                  this.listNode = listNode;
                                }}
                                width={width}
                                height={height - 40}
                                rowCount={(newLocalPredictionRuleList || []).length}
                                overscanRowCount={4}
                                deferredMeasurementCache={this.cellMeasureCache}
                                rowHeight={this.cellMeasureCache.rowHeight}
                                rowRenderer={(props) => this.renderListItem(props, { newLocalPredictionRuleList })}
                                onScrollbarPresenceChange={({ horizontal, vertical }) => {
                                  if (vertical) {
                                    this.listNodeHeaderScrollbar = true;
                                  } else {
                                    this.listNodeHeaderScrollbar = false;
                                  }
                                  this.forceUpdate();
                                }}
                              />
                            </div>
                          )}
                        </AutoSizer>
                      </Spin>
                    </div>

                    <div className="flex-row" style={{ padding: '8px 0px' }}>
                      <div className="flex-row flex-center-align">
                        <div className="flex-grow flex-row flex-center-align" />
                        <Pagination
                          size="small"
                          current={page}
                          total={incidentPredictionRuleList.length}
                          pageSize={pageSize}
                          onChange={this.handlePaginationChange}
                          showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                          showSizeChanger
                          pageSizeOptions={['10', '20', '40', '60', '100', '500', '1000']}
                          onShowSizeChange={this.handlePaginationSizeChange}
                        />
                      </div>
                      <div className="flex-grow" />
                      {userInfo.isAdmin && rootCauseLevel === 'component' && (
                        <Button
                          size="small"
                          type="primary"
                          loading={isSubmitting}
                          disabled={hasError}
                          onClick={() => this.setState({ escalateAddModal: true })}
                        >
                          {intl.formatMessage(appButtonsMessages.escalateToGlobalKnowledgeBase)}
                        </Button>
                      )}
                      <Popover
                        content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                        mouseEnterDelay={0.3}
                        placement="left"
                      >
                        <Button
                          size="small"
                          type="primary"
                          loading={isSubmitting}
                          disabled={hasError || userInfo.isReadUser}
                          onClick={this.handleSaveClick}
                          style={{ marginLeft: 8 }}
                        >
                          {intl.formatMessage(appButtonsMessages.update)}
                        </Button>
                      </Popover>

                      {/* <Button
                        type="primary"
                        size="small"
                        style={{ marginLeft: 8 }}
                        loading={isSubmitting}
                        disabled={hasErrorRemove}
                        onClick={() => this.handleMultipleConfigActionsClick()}
                      >
                        {intl.formatMessage(eventMessages.takeAction)}
                      </Button> */}

                      <Popover
                        content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                        mouseEnterDelay={0.3}
                        placement="left"
                      >
                        <Popconfirm
                          placement="topRight"
                          title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                          okText={intl.formatMessage(appButtonsMessages.yes)}
                          cancelText={intl.formatMessage(appButtonsMessages.no)}
                          onConfirm={() => this.handleRuleRemove()}
                          onCancel={(event) => event.stopPropagation()}
                          disabled={userInfo.isReadUser}
                        >
                          <Button
                            type="primary"
                            size="small"
                            className="button-color-grey"
                            style={{ marginLeft: 8 }}
                            loading={isSubmitting}
                            disabled={hasErrorRemove || userInfo.isReadUser}
                            onClick={(event) => event.stopPropagation()}
                          >
                            <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                          </Button>
                        </Popconfirm>
                      </Popover>

                      <Popover
                        content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                        mouseEnterDelay={0.3}
                        placement="left"
                      >
                        <Button
                          type="primary"
                          size="small"
                          className="button-color-grey"
                          style={{ marginLeft: 8 }}
                          onClick={this.handleRuleRemoveAll}
                          disabled={userInfo.isReadUser}
                        >
                          <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.removeAll)}
                        </Button>
                      </Popover>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </Spin>
        </Container>

        {addRecommendationModalFlag && (
          <RecommendationModal
            intl={intl}
            activeIncidentKey={activeIncidentKey}
            addRecommendationActive={addRecommendationActive}
            rootCauseLevel={rootCauseLevel}
            incidentList={incidentList}
            credentials={credentials}
            onClose={() => {
              this.setState({ addRecommendationModalFlag: false });
            }}
          />
        )}

        {this.state.showSystemPredictionRuleActionModal && (
          <GlobalSystemKnowledgeBaseActionModal
            systemId={systemId}
            actionProjectNameList={this.state.actionProjectNameList}
            actionKeySet={this.state.actionKeySet}
            actionIncidentPredictionLibKeys={this.state.actionIncidentPredictionLibKeys}
            systemProjectNameList={this.state.systemProjectNameList}
            onClose={(reload) => {
              this.setState(
                {
                  showSystemPredictionRuleActionModal: false,
                  actionProjectNameList: [],
                  actionKeySet: [],
                  actionIncidentPredictionLibKeys: [],
                },
                () => {
                  if (reload) this.reloadData();
                },
              );
            }}
          />
        )}
        {this.state.showSavedActionModal && (
          <AddSavedActionModal
            title={intl.formatMessage(eventActionMessages.addSavedAction)}
            intl={intl}
            incident={addSavedActionRow}
            onClose={() => this.setState({ showSavedActionModal: false })}
          />
        )}
        {this.state.escalateAddModal && (
          <EscalateAddModal
            intl={intl}
            credentials={credentials}
            onClose={(patternName) => {
              if (patternName) {
                this.handleEscalateAddClick(patternName);
              }
              this.setState({ escalateAddModal: false });
            }}
          />
        )}
        {this.state.uploadFlagModal && (
          <UploadKBFileModal
            intl={intl}
            onClose={() => {
              this.setState({ uploadFlagModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

const UploadKBFileModal = (props: Object) => {
  const { intl, onClose } = props || {};
  const beforeUploadErrorFlag = useRef(0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    fileList: [],
    btnLoading: false,
  });
  const { fileList, btnLoading } = state;

  const handleUploadAvatar = () => {
    setState({ btnLoading: true });

    const request = [];
    R.forEach((file) => {
      const fetchUrl = `kb-file?operation=upload&fileName=${file?.name}`;
      const formData = new FormData();
      formData.append('data', file.fileData);
      request.push(fetchPostForm(getEndpoint(fetchUrl), formData));
    }, fileList || []);

    Promise.all(request)
      .then((data) => {
        const { success, message: meg } = data[0];
        if (success || success === undefined) {
          message.success(meg);
        } else {
          message.error(meg);
        }
        setState({ btnLoading: false });
        onClose();
      })
      .catch((err) => {
        message.error(err.message || String(err));
        setState({ btnLoading: false });
      });
  };

  const handleDeleteFile = (file) => {
    const newFileList = R.filter((item) => item.uid !== file.uid, fileList || []);
    setState({ fileList: newFileList });
  };

  return (
    <Modal
      title={intl.formatMessage(appButtonsMessages.upload)}
      width={650}
      visible
      onCancel={() => onClose()}
      onOk={handleUploadAvatar}
      okButtonProps={{ disabled: fileList.length < 1, loading: btnLoading }}
      okText={intl.formatMessage(appButtonsMessages.upload)}
    >
      <Alert
        message={
          <div style={{ paddingLeft: 8 }}>
            <div className="font-14 bold">File name format: project_userName_ruleType</div>
            <div className="font-14 bold">For example:</div>
            <div style={{ paddingLeft: 8 }}>1.domeProject_domeUser_ComponentLevelRuleType</div>
            <div style={{ paddingLeft: 8 }}>2.domeProject_domeUser_InstanceLevelRuleType</div>
          </div>
        }
        type="warning"
        showIcon
      />
      <div style={{ margin: '16px 0px', height: 200 }}>
        <Upload.Dragger
          fileList={fileList}
          accept=".json"
          maxCount={1}
          showUploadList={false}
          beforeUpload={(file, filelLists) => {
            beforeUploadErrorFlag.current += 1;
            const uploadFlag = beforeUploadErrorFlag.current === filelLists.length;

            const newFileList = [];
            R.forEach((item) => {
              const ks = R.filter((k) => k === '_', R.split('', item.name));
              if (ks.length < 2) {
                if (uploadFlag) message.error(`${item.name} the file name format is incorrect!`);
              } else {
                newFileList.push({ fileData: item, name: item.name });
              }
            }, filelLists);

            if (uploadFlag) beforeUploadErrorFlag.current = 0;
            setState({ fileList: newFileList });
          }}
        >
          <div
            className="ant-upload-drag-icon"
            style={{ fontSize: 30, lineHeight: '30px', marginBottom: 10, color: 'var(--text-color)' }}
          >
            <UploadOutlined />
          </div>
          <div className="ant-upload-text" style={{ fontSize: 12, color: 'var(--text-color)' }}>
            Click or drag .json files to this area to upload
          </div>
        </Upload.Dragger>
        <div>
          {R.map((file) => {
            return (
              <div key={file.uid} className="flex-row flex-center-align" style={{ margin: '4px 0px' }}>
                <div>
                  <FileOutlined />
                </div>
                <div className="flex-grow hidden-line-with-ellipsis" style={{ margin: '0px 4px' }}>
                  {file.name}
                </div>
                <div className="clickable" onClick={() => handleDeleteFile(file)}>
                  <DeleteOutlined />
                </div>
              </div>
            );
          }, fileList || [])}
        </div>
      </div>
    </Modal>
  );
};

const KnowledgeBaseRuleList = injectIntl(KnowledgeBaseRuleListCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, systemsMap, projects, projectDisplayMap, globalInfo, currentTheme } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { credentials, userInfo } = state.auth;
    const { globalSystemMatchedPredictions } = state.dashboard;
    return {
      location,
      loadStatus,
      currentLocale,
      userList,
      systemsMap,
      projects,
      projectDisplayMap,
      credentials,
      userInfo,

      globalInfo,
      globalSystemMatchedPredictions,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(KnowledgeBaseRuleList);
