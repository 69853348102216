/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';
import { get } from 'lodash';
import * as R from 'ramda';

import type { Deps } from '../../types';
import { loadProjectSettings, loadProjectEpisodeWord, getProjectAgentList } from '../../apis';
import { appMessages } from '../../app/messages';
import { showAppLoader, hideAppLoader, updateLastActionInfo } from '../../app/actions';
import { apiEpicErrorHandle } from '../../errors';
import { setProjectSettings, setSettingsApisParams } from '../actions';
import { pickNotNil, ifIn } from '../../utils';

const loadProjectSettingsEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('LOAD_PROJECT_SETTINGS').concatMap((action) => {
    const state = getState();
    const { params, force, callback } = action.payload;
    const { projectName } = action.payload;
    const { credentials } = state.auth;
    const { projects, systemsMap } = state.app;
    const { setting, start, limit } = params;
    const apisParamsKey = 'project';

    // Different setting uses different API, if the setting are using the same API,
    // we should not call the same API when setting changed. So we store the params
    // for each API, and compare with the current params individually.
    //
    // Meanwhile, we also need to support refresh.
    const projectSettingsParams = pickNotNil({ projectName, ...params });
    const prevProjectSettingsParams = state.settings.projectSettingsParams || {};

    const settingApiParamsKey = ifIn(setting, ['episodeword', 'agent', 'grouping']) ? setting : 'general';
    const prevCurrentApisParams = get(state.settings.currentApisParams, apisParamsKey, {});
    const prevSettingApiParams = get(prevCurrentApisParams, settingApiParamsKey, {});

    // Get the Api params for the current setting's api.
    let settingApiParams = { projectName };
    if (setting === 'agent') {
      settingApiParams = pickNotNil({ projectName });
    }

    let currentApisParams = { [settingApiParamsKey]: settingApiParams };
    const refresh = force || projectName !== prevProjectSettingsParams.projectName;
    let settingReload = refresh;
    if (!refresh) {
      settingReload = !R.equals(prevSettingApiParams, settingApiParams);
      currentApisParams = { ...prevCurrentApisParams, ...currentApisParams };
    }

    let currentErrorMessage = null;
    let apiAction$ = null;

    // If refresh, reset all project settings.
    const prevProjectSettings = state.settings.projectSettings || {};
    const project = R.find((p) => p.projectName === projectName, projects);
    const { projectShortName, owner } = project || {};
    const fullProjectName = projectName.indexOf('@') >= 0 ? projectName : `${projectShortName}@${owner}`;

    if (!project) {
      // The routing will guarantee the projectName is not empty, but we need
      // to check whether the projectName is a valid project. If invalid, set
      // error and clean the current settings.
      currentErrorMessage = appMessages.errorsProjectNotFound;
      apiAction$ = Observable.of(setProjectSettings({ projectSettings: {} }));
    } else if (settingReload && setting === 'agent') {
      apiAction$ = Observable.from(getProjectAgentList(credentials, projectName, {}))
        .concatMap((d) => {
          return Observable.of(
            setProjectSettings({
              projectSettings: { ...prevProjectSettings, ...d.data },
            }),
          );
        })
        .catch((err) => {
          return apiEpicErrorHandle(err);
        });
    } else if (settingReload && setting === 'episodeword') {
      apiAction$ = Observable.from(loadProjectEpisodeWord(credentials, projectName))
        .concatMap((d) => {
          return Observable.of(
            setProjectSettings({
              projectSettings: { ...prevProjectSettings, ...d.data },
            }),
          );
        })
        .catch((err) => {
          return apiEpicErrorHandle(err);
        });
    } else if (settingReload) {
      apiAction$ = Observable.from(loadProjectSettings(credentials, { projectName: fullProjectName, start, limit }))
        .concatMap((d) => {
          // Reset all settings.
          return Observable.of(
            setProjectSettings({
              projectSettings: { ...prevProjectSettings, ...d.data },
            }),
          );
        })
        .catch((err) => {
          return apiEpicErrorHandle(err);
        });
    } else {
      apiAction$ = Observable.empty();
    }

    if (R.type(callback) === 'Function') {
      callback();
    }

    // Return the general sequence for all API calls.
    return Observable.concat(
      Observable.of(showAppLoader),
      Observable.of(updateLastActionInfo()),
      Observable.of(setProjectSettings({ currentErrorMessage, projectSettingsParams })),
      Observable.of(setSettingsApisParams(apisParamsKey, currentApisParams)),
      apiAction$,
      Observable.of(hideAppLoader()),
    );
  });

export default loadProjectSettingsEpic;
