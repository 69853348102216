/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Form, Button, Alert, Input, Select, Card, TreeSelect, Checkbox, Divider, Spin } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import fetchGet from '../../../../common/apis/fetchGet';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { buildLocation, parseLocation } from '../../../../common/utils';

function parseArrayToTree(arr) {
  const root = {};
  for (const str of arr) {
    const parts = str?.split('.') || [];
    let node = root;
    for (const part of parts) {
      if (!node[part]) {
        node[part] = [];
      }
      node = node[part];
    }
  }
  return root;
}

function treeToJSON(tree, parentKey, isMounte) {
  const keys = Object.keys(tree);
  if (keys.length === 0) {
    return null;
  }
  const arr = [];
  for (const key of keys) {
    parentKey = `${parentKey}${isMounte ? ':' : '.'}${key}`;

    const children = treeToJSON(tree[key], parentKey);
    arr.push({ label: key, value: parentKey, children });
  }
  return arr;
}

const recursiveUpdateValue = (node, parentLabel, isMounte) => {
  const { label } = node;
  const updatedValue = parentLabel && isMounte ? `${parentLabel}:${label}` : `${parentLabel}.${label}`;
  node.value = updatedValue;
  if (node.children) {
    node.children.forEach((child) => recursiveUpdateValue(child, updatedValue));
  } else {
    node.label = updatedValue;
  }
};

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  credentials: Object,
  componentState: Object,
  location: Object,
  hasError: Boolean,
  isLoading: Boolean,
  replace: Function,
  userInfo: Object,
  userList: Array<Object>,
};

class DynatraceCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState, location } = props;
    const { id } = parseLocation(location);

    const state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',
      successMessage: '',

      envId: '',
      token: '',
      dataType: 'Metric',
      samplingInterval: 5,

      // metric
      metrics: [],
      kpiMetrics: [],
      samplingUnit: 60,

      instanceKey: '',
      timestampKey: '',

      metricOptions: [],
      kpiMetricOptions: [],
      dynatraceKeysOptions: [],

      hosts: [],
      hostsOption: [],

      applicationId: [],
      applicationOption: [],

      managementZone: '',
      zoneOptions: [],
      zones: [],
      batchType: 'Host',

      isHost: true,
      isPageLoading: false,
      projectOwner: '',
      preRecordDataId: id || undefined,

      searchValue: '',
    };

    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);

    this.state = {
      ...state,

      ...(componentState || {}),
    };
    this.dataTypeOption = [
      { label: 'Metric', value: 'Metric' },
      { label: 'Event', value: 'Event' },
      { label: 'Problems', value: 'Incident' },
    ];
    this.samplingUnitOption = [
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
    this.localMetrics = [];
    this.filterMetrics = [];

    this.filterApplicationOption = [];

    this.filterZoneOptions = [];
    this.zoneRex = /^[^,]+(,[^,]+)*$/;

    this.batchTypeOptions = [
      { label: 'Application', value: 'Application' },
      { label: 'Management zone', value: 'zone' },
      { label: 'Host', value: 'Host' },
    ];
  }

  componentDidMount() {
    this.props.saveProjectInfo('Dynatrace', { dataType: this.state.dataType }, this.state);
    this.getLoadProjectCreationData();
  }

  @autobind
  clearUrlJump() {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    const { isJump } = query;
    if (isJump) {
      replace(buildLocation(location.pathname, {}, { ...query, id: undefined, isJump: undefined }));
    }
  }

  @autobind
  getLoadProjectCreationData() {
    const { intl, location, credentials } = this.props;
    const { id, isJump, customerName } = parseLocation(location);
    if (!isJump) return;
    this.setState({ isPageLoading: true });
    fetchGet(getEndpoint('load-project-creation-data'), { ...credentials, id, UserName: customerName })
      .then((data) => {
        const { success, message: msg, envId, token, applicationId, hosts, zones, dataType = 'Metric' } = data || {};
        if (success || success === undefined) {
          if (dataType === 'Metric') {
            this.localMetrics = data?.metrics || [];
            this.parseDataMetric(this.localMetrics);
          } else {
            this.parseDataLog(data);
          }

          const applicationOption = R.map(
            (item) => ({ label: item.entityName, value: item.entityId }),
            applicationId || [],
          );
          this.filterApplicationOption = applicationOption;

          const hostsOption = R.map((item) => ({ label: item.hostName, value: item.hostId }), hosts || []);

          const zoneOptions = R.map((item) => ({ label: item.name, value: item.id }), zones || []);
          this.filterZoneOptions = zoneOptions;

          this.setState(
            {
              hosts: [],
              hostsOption,
              applicationOption,
              applicationId: [],
              zoneOptions,
              verified: true,
              envId,
              token,
              dataType,
              verifiedMessage: undefined,
              samplingInterval: dataType !== 'Metric' ? 10 : 5,
              isPageLoading: false,
              projectOwner: customerName,
            },
            () => {
              this.props.saveProjectInfo('Dynatrace', { dataType }, this.state);
              this.clearUrlJump();
            },
          );
        } else {
          this.filterApplicationOption = [];
          this.filterZoneOptions = [];
          this.setState(
            {
              verified: false,
              verifiedMessage: msg,
              successMessage: undefined,
              hosts: [],
              hostsOption: [],
              applicationId: [],
              applicationOption: [],
              zoneOptions: [],
              isPageLoading: false,
            },
            () => {
              this.props.saveProjectInfo('Dynatrace', { dataType }, this.state);
              this.clearUrlJump();
            },
          );
        }
      })
      .catch((err) => {
        console.error(err);
        this.filterApplicationOption = [];
        this.filterZoneOptions = [];
        this.setState(
          {
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
            successMessage: undefined,
            hosts: [],
            hostsOption: [],
            applicationId: [],
            applicationOption: [],
            zoneOptions: [],
            isPageLoading: false,
          },
          () => {
            this.props.saveProjectInfo('Dynatrace', { dataType: this.state.dataType }, this.state);
            this.clearUrlJump();
          },
        );
      });
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isVerifying: true,
      verified: false,
      metrics: [],
      kpiMetrics: [],
      instanceKey: '',
      timestampKey: '',
    });
    const { intl, credentials, userInfo } = this.props;
    const { isAdmin, isLocalAdmin } = userInfo;
    const { envId, token, dataType, managementZone, projectOwner } = this.state;
    const isMetric = dataType === 'Metric' && 'dynatraceMetric';
    const isLog = dataType === 'Log' && 'dynatraceLog';
    const isEvent = dataType === 'Event' && 'dynatraceEvent';
    const isIncident = dataType === 'Incident' && 'dynatraceIncident';
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: isMetric || isLog || isEvent || isIncident,
      envId,
      token,
      dataType,
      ...(isAdmin || isLocalAdmin ? { UserName: projectOwner } : {}),
      // managementZone: JSON.stringify(this.zoneRex.test(managementZone) ? managementZone.split(',') : []),
    })
      .then((d) => {
        const { success, message: errMsg } = d || {};
        if (success === undefined || success) {
          this.setState(
            {
              isVerifying: false,
              verifiedMessage: undefined,
              successMessage: intl.formatMessage(appMessages.dynatraceVerificationSuccessful),
            },
            () => {
              this.props.saveProjectInfo('Dynatrace', { dataType }, this.state);
            },
          );
        } else {
          this.filterApplicationOption = [];
          this.filterZoneOptions = [];
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg || intl.formatMessage(appMessages.dynatraceVerificationError),
              successMessage: undefined,
              hosts: [],
              hostsOption: [],
              applicationId: [],
              applicationOption: [],
              zoneOptions: [],
            },
            () => {
              this.props.saveProjectInfo('Dynatrace', { dataType }, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.filterApplicationOption = [];
        this.filterZoneOptions = [];
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
            successMessage: undefined,
            hosts: [],
            hostsOption: [],
            applicationId: [],
            applicationOption: [],
            zoneOptions: [],
          },
          () => {
            this.props.saveProjectInfo('Dynatrace', { dataType }, this.state);
          },
        );
      });
  }

  @autobind
  parseDataLog(data) {
    this.setState({ dynatraceKeysOptions: R.map((item) => ({ label: item, value: item }), data?.info) || [] });
  }

  @autobind
  parseDataMetric(data) {
    const treeData = R.groupBy((item) => {
      if (item.metricId.indexOf(':') >= 0) {
        return item.metricId.split(':')[0];
      } else {
        return 'unknow';
      }
    }, data);

    const treeDataNew = [];
    R.addIndex(R.forEachObjIndexed)((value, metricName, obj, idx) => {
      value = R.addIndex(R.map)((item, index) => ({ label: item.metricId, value: item.metricId }), value);
      const metricNames = R.map((item) => {
        return item.value?.indexOf(':') >= 0 ? item.value.split(':')[1] : item.value;
      }, value);
      const tree = parseArrayToTree(metricNames);
      const JSONtree = treeToJSON(tree);

      R.forEach((node) => {
        recursiveUpdateValue(node, metricName, true);
      }, JSONtree);
      treeDataNew.push({ label: metricName, value: metricName, children: JSONtree });
    }, treeData);
    this.filterMetrics = data;
    this.setState({
      metricOptions: treeDataNew,
    });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { credentials } = this.props;
    const {
      envId,
      token,
      dataType,
      samplingInterval,
      samplingUnit,
      metrics,
      kpiMetrics,
      instanceKey,
      timestampKey,
      hosts,
      applicationId,
      zones,
      isHost,
      preRecordDataId,
    } = this.state;
    const isMetric = dataType === 'Metric' && 'dynatraceMetric';
    const isLog = dataType === 'Log' && 'dynatraceLog';
    const isEvent = dataType === 'Event' && 'dynatraceEvent';
    const isIncident = dataType === 'Incident' && 'dynatraceIncident';
    // build metrics params
    this.props.createProject(
      'Dynatrace',
      {
        ...credentials,
        projectCreationType: isMetric || isLog || isEvent || isIncident,
        envId,
        token,
        dataType,
        operation: 'register',
        samplingInterval: Number(samplingInterval) * samplingUnit,
        ...(dataType === 'Metric' ? { metrics: JSON.stringify(metrics), kpiMetrics: JSON.stringify(kpiMetrics) } : {}),
        ...(dataType !== 'Metric'
          ? { instanceKey: JSON.stringify([instanceKey]), timestampKey: JSON.stringify([timestampKey]) }
          : {}),
        hosts: JSON.stringify(hosts),
        isBatch: !isHost,
        applicationId: JSON.stringify(applicationId),
        zones: JSON.stringify(zones),
        preRecordDataId,
      },
      this.state,
    );
  }

  @autobind
  handleDataTypeChange(dataType) {
    this.setState(
      {
        dataType,
        verified: false,
      },
      () => {
        this.props.saveProjectInfo('Dynatrace', { dataType }, this.state);
      },
    );
  }

  @autobind
  getListOptionValues(option) {
    return R.map((item) => item.value, option);
  }

  @autobind
  getFilterValue({ searchValue, options, type = 'label' }) {
    return R.filter((item) => item[type].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1, options);
  }

  @autobind
  isCheckedAll({ searchValue, options, valueList }) {
    const filterValue = this.getListOptionValues(this.getFilterValue({ searchValue, options }));
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  }

  render() {
    const { intl, hasError, isLoading, userInfo, userList } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,
      successMessage,
      envId,
      token,
      dataType,

      samplingInterval,
      samplingUnit,

      metrics,
      kpiMetrics,

      metricOptions,
      instanceKey,
      timestampKey,
      dynatraceKeysOptions,

      hostsOption,
      hosts,

      applicationOption,
      applicationId,

      managementZone,

      zones,
      zoneOptions,

      batchType,
      isHost,
      isPageLoading,
      projectOwner,

      searchValue,
    } = this.state;
    const { isAdmin, isLocalAdmin } = userInfo;
    const isMetric = dataType === 'Metric';
    const validateZone = managementZone ? this.zoneRex.test(managementZone) : true;
    const hasVerifyError = !envId || !token || !dataType || (isAdmin || isLocalAdmin ? !projectOwner : false);
    const hasBothError = (applicationId.length === 0 && zones.length === 0 && hosts.length === 0) || !batchType;
    const hasErrorRegister = hasVerifyError || !samplingInterval || !samplingUnit || hasError || hasBothError;

    return (
      <Spin spinning={isPageLoading}>
        <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
          <div className="text" style={{ paddingBottom: '1em' }}>
            Uses Dynatrace APIs to monitor and report logs/metrics.
          </div>

          {verifiedMessage && (
            <div style={{ marginBottom: '1em' }}>
              <Alert message={verifiedMessage} type="error" />
            </div>
          )}

          {successMessage && (
            <div style={{ marginBottom: '1em' }}>
              <Alert message={successMessage} type="success" />
            </div>
          )}

          <Card>
            <Form layout="vertical">
              <Form.Item
                label="Environment id"
                validateStatus={!envId ? 'error' : 'success'}
                help={!envId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <Input.Password
                  value={envId}
                  onChange={(e) => this.setState({ envId: e.target.value })}
                  disabled={verified}
                />
              </Form.Item>
              <Form.Item
                label="Token"
                validateStatus={!token ? 'error' : 'success'}
                help={!token ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <Input.Password
                  value={token}
                  onChange={(e) => this.setState({ token: e.target.value })}
                  disabled={verified}
                />
              </Form.Item>
              <Form.Item
                label="Data type"
                validateStatus={!dataType ? 'error' : 'success'}
                help={!dataType ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <Select
                  showSearch
                  options={this.dataTypeOption}
                  value={dataType}
                  onChange={this.handleDataTypeChange}
                  disabled={verified}
                />
              </Form.Item>
              {(isAdmin || isLocalAdmin) && (
                <Form.Item
                  label={intl.formatMessage(appFieldsMessages.owner)}
                  validateStatus={!projectOwner ? 'error' : 'success'}
                  help={
                    (isAdmin || isLocalAdmin) && !projectOwner
                      ? intl.formatMessage(appFieldsMessages.inputRequired)
                      : undefined
                  }
                  required
                >
                  <Select
                    showSearch
                    size="small"
                    value={projectOwner}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(projectOwner) => this.setState({ projectOwner })}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                    disabled={verified}
                  >
                    {R.map(
                      (item) => (
                        <Select.Option key={item.userName} value={item.userName}>
                          {item.userName}
                        </Select.Option>
                      ),
                      userList || [],
                    )}
                  </Select>
                </Form.Item>
              )}

              {false && (
                <Form.Item
                  label="Management zone"
                  validateStatus={!validateZone ? 'error' : 'success'}
                  help={!validateZone ? 'must be separated by commas.' : undefined}
                >
                  <Input
                    placeholder="word1,word2"
                    value={managementZone}
                    onChange={(e) => this.setState({ managementZone: e.target.value })}
                    disabled={verified}
                  />
                </Form.Item>
              )}
            </Form>
            <div className="inline field text-right">
              <Button
                type="primary"
                style={{ width: 120, marginTop: 16 }}
                loading={isVerifying}
                disabled={hasVerifyError || verified}
                onClick={this.handleVerifyClick}
              >
                Verify
              </Button>
            </div>
          </Card>
          <Card className={`${verified ? 'block' : 'display-none'}`}>
            <Form layout="vertical">
              <Form.Item
                label="Create projects by"
                required
                validateStatus={!batchType ? 'error' : 'success'}
                help={!batchType ? 'batchType cannot be empty.' : undefined}
              >
                <Select
                  options={this.batchTypeOptions}
                  value={batchType}
                  onChange={(batchType) =>
                    this.setState(
                      { batchType, isHost: batchType === 'Host', hosts: [], applicationId: [], zones: [] },
                      () => this.props.saveProjectInfo('Dynatrace', { dataType }, this.state),
                    )
                  }
                />
              </Form.Item>

              {isHost && (
                <Form.Item
                  label="Hosts"
                  required
                  validateStatus={hasBothError ? 'error' : 'success'}
                  help={hasBothError ? 'Hosts cannot be empty.' : undefined}
                >
                  <Select
                    className="no-count-num"
                    style={{ width: '100%' }}
                    showSearch
                    mode="multiple"
                    value={hosts}
                    autoClearSearchValue={false}
                    optionFilterProp="children"
                    onChange={(hosts) => this.setState({ hosts })}
                    onDropdownVisibleChange={(open) => {
                      if (!open) {
                        this.setState({ searchValue: '' });
                      }
                    }}
                    dropdownRender={(menu) => {
                      return (
                        <div>
                          <div
                            className="flex-row"
                            style={{ padding: '5px 12px' }}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={this.isCheckedAll({ searchValue, options: hostsOption, valueList: hosts })}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const filterValue = this.getListOptionValues(
                                  this.getFilterValue({ searchValue, options: hostsOption }),
                                );
                                const diff = R.difference(hosts, filterValue);
                                this.setState({ hosts: checked ? [...diff, ...filterValue] : diff });
                              }}
                            />
                            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                          </div>
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                    onSearch={(searchValue) => this.setState({ searchValue })}
                  >
                    {R.map((item) => {
                      return (
                        <Select.Option className="hide-icon" key={item.value} value={item.value}>
                          <Checkbox
                            checked={hosts.includes(item.value)}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {item.label}
                        </Select.Option>
                      );
                    }, hostsOption)}
                  </Select>
                </Form.Item>
              )}

              {!isHost && (
                <>
                  {batchType === 'Application' && (
                    <Form.Item
                      label="Application"
                      required
                      validateStatus={hasBothError ? 'error' : 'success'}
                      help={hasBothError ? 'Application cannot be empty.' : undefined}
                    >
                      <Select
                        className="no-count-num"
                        style={{ width: '100%' }}
                        showSearch
                        mode="multiple"
                        value={applicationId}
                        autoClearSearchValue={false}
                        optionFilterProp="children"
                        onChange={(applicationId) => this.setState({ applicationId, zones: [] })}
                        onDropdownVisibleChange={(open) => {
                          if (!open) {
                            this.filterApplicationOption = applicationOption;
                          }
                        }}
                        dropdownRender={(menu) => {
                          return (
                            <div>
                              <div
                                className="flex-row"
                                style={{ padding: '5px 12px' }}
                                onMouseDown={(event) => event.preventDefault()}
                              >
                                <Checkbox
                                  style={{ marginRight: 8 }}
                                  checked={applicationId.length === this.filterApplicationOption.length}
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    if (checked) {
                                      this.setState({
                                        applicationId: R.map((item) => item.value, this.filterApplicationOption),
                                        zones: [],
                                      });
                                    } else {
                                      this.setState({ applicationId: [], zones: [] });
                                    }
                                  }}
                                />
                                <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                              </div>
                              <Divider style={{ margin: '4px 0' }} />
                              {menu}
                            </div>
                          );
                        }}
                        onSearch={(searchValue) => {
                          const newApplication = searchValue
                            ? R.filter(
                                (item) => item.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
                                applicationOption,
                              )
                            : applicationOption;
                          this.filterApplicationOption = newApplication;
                        }}
                      >
                        {R.map((item) => {
                          return (
                            <Select.Option className="hide-icon" key={item.value} value={item.value}>
                              <Checkbox
                                checked={applicationId.includes(item.value)}
                                onChange={(e) => null}
                                style={{ marginRight: 8 }}
                              />
                              {item.label}
                            </Select.Option>
                          );
                        }, applicationOption)}
                      </Select>
                    </Form.Item>
                  )}

                  {batchType === 'zone' && (
                    <Form.Item
                      label="Management zone"
                      required
                      validateStatus={hasBothError ? 'error' : 'success'}
                      help={hasBothError ? 'Zone cannot be empty.' : undefined}
                    >
                      <Select
                        className="no-count-num"
                        style={{ width: '100%' }}
                        showSearch
                        mode="multiple"
                        value={zones}
                        autoClearSearchValue={false}
                        optionFilterProp="children"
                        onChange={(zones) => this.setState({ zones, applicationId: [] })}
                        onDropdownVisibleChange={(open) => {
                          if (!open) {
                            this.filterZoneOptions = zoneOptions;
                          }
                        }}
                        dropdownRender={(menu) => {
                          return (
                            <div>
                              <div
                                className="flex-row"
                                style={{ padding: '5px 12px' }}
                                onMouseDown={(event) => event.preventDefault()}
                              >
                                <Checkbox
                                  style={{ marginRight: 8 }}
                                  checked={zones.length === this.filterZoneOptions.length}
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    if (checked) {
                                      this.setState({
                                        applicationId: [],
                                        zones: R.map((item) => item.value, this.filterZoneOptions),
                                      });
                                    } else {
                                      this.setState({ applicationId: [], zones: [] });
                                    }
                                  }}
                                />
                                <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                              </div>
                              <Divider style={{ margin: '4px 0' }} />
                              {menu}
                            </div>
                          );
                        }}
                        onSearch={(searchValue) => {
                          const newZones = searchValue
                            ? R.filter(
                                (item) => item.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
                                zoneOptions,
                              )
                            : zoneOptions;
                          this.filterZoneOptions = newZones;
                        }}
                      >
                        {R.map((item) => {
                          return (
                            <Select.Option className="hide-icon" key={item.value} value={item.value}>
                              <Checkbox
                                checked={zones.includes(item.value)}
                                onChange={(e) => null}
                                style={{ marginRight: 8 }}
                              />
                              {item.label}
                            </Select.Option>
                          );
                        }, zoneOptions)}
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}

              <Form.Item
                label="Sampling Interval"
                validateStatus={!samplingInterval ? 'error' : 'success'}
                help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                required
              >
                <div className="flex-row flex-center-align">
                  <Input
                    style={{ width: 200 }}
                    value={samplingInterval}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (/^\d+$/.test(val) || R.isEmpty(val)) {
                        this.setState({ samplingInterval: Number(val) });
                      }
                    }}
                  />
                  <Select
                    style={{ width: 120, marginLeft: 4 }}
                    options={this.samplingUnitOption}
                    value={samplingUnit}
                    onChange={(samplingUnit) => this.setState({ samplingUnit })}
                  />
                </div>
              </Form.Item>

              {isMetric && (
                <>
                  <Form.Item label="Metrics">
                    <TreeSelect
                      className="no-count-num"
                      treeDefaultExpandAll
                      autoClearSearchValue={false}
                      treeData={metricOptions}
                      showSearch
                      multiple
                      treeCheckable
                      value={metrics}
                      onChange={(metrics) => this.setState({ metrics })}
                      showCheckedStrategy={TreeSelect.SHOW_CHILD}
                      style={{ width: '100%' }}
                      onSearch={(searchValue) => {
                        const newMetrics = searchValue
                          ? this.getFilterValue({ searchValue, options: this.localMetrics, type: 'metricId' })
                          : this.localMetrics;
                        this.parseDataMetric(newMetrics);
                      }}
                      dropdownRender={(menu) => {
                        return (
                          <div>
                            <div
                              className="flex-row"
                              style={{ padding: '5px 12px' }}
                              onMouseDown={(event) => event.preventDefault()}
                            >
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={
                                  R.difference(
                                    R.map((item) => item.metricId, this.filterMetrics),
                                    metrics,
                                  ).length === 0
                                }
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const filterValue = R.map((item) => item.metricId, this.filterMetrics);
                                  const diff = R.difference(metrics, filterValue);
                                  this.setState({ metrics: checked ? [...diff, ...filterValue] : diff });
                                }}
                              />
                              Select all
                            </div>
                            <Divider style={{ margin: '4px 0' }} />
                            {menu}
                          </div>
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="Kpi metrics">
                    <TreeSelect
                      className="no-count-num"
                      treeDefaultExpandAll
                      autoClearSearchValue={false}
                      treeData={metricOptions}
                      showSearch
                      multiple
                      treeCheckable
                      value={kpiMetrics}
                      onChange={(kpiMetrics) => this.setState({ kpiMetrics })}
                      showCheckedStrategy={TreeSelect.SHOW_CHILD}
                      style={{ width: '100%' }}
                      onSearch={(searchValue) => {
                        const newMetrics = searchValue
                          ? this.getFilterValue({ searchValue, options: this.localMetrics, type: 'metricId' })
                          : this.localMetrics;
                        this.parseDataMetric(newMetrics);
                      }}
                      dropdownRender={(menu) => {
                        return (
                          <div>
                            <div
                              className="flex-row"
                              style={{ padding: '5px 12px' }}
                              onMouseDown={(event) => event.preventDefault()}
                            >
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={
                                  R.difference(
                                    R.map((item) => item.metricId, this.filterMetrics),
                                    kpiMetrics,
                                  ).length === 0
                                }
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const filterValue = R.map((item) => item.metricId, this.filterMetrics);
                                  const diff = R.difference(kpiMetrics, filterValue);
                                  this.setState({ kpiMetrics: checked ? [...diff, ...filterValue] : diff });
                                }}
                              />
                              Select all
                            </div>
                            <Divider style={{ margin: '4px 0' }} />
                            {menu}
                          </div>
                        );
                      }}
                    />
                  </Form.Item>
                </>
              )}

              {!isMetric && (
                <>
                  <Form.Item label="Instance key">
                    <Select
                      allowClear
                      showSearch
                      value={instanceKey}
                      onChange={(instanceKey) => this.setState({ instanceKey })}
                      options={dynatraceKeysOptions}
                    />
                  </Form.Item>
                  <Form.Item label="Timestamp key">
                    <Select
                      allowClear
                      showSearch
                      value={timestampKey}
                      onChange={(timestampKey) => this.setState({ timestampKey })}
                      options={dynatraceKeysOptions}
                    />
                  </Form.Item>
                </>
              )}
            </Form>
          </Card>

          <div
            className={`${verified ? 'block' : 'display-none'}`}
            style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
          >
            <Button
              type="primary"
              style={{ width: 120 }}
              disabled={hasErrorRegister}
              onClick={this.handleConfirmClick}
              loading={isLoading}
            >
              {intl.formatMessage(appButtonsMessages.finished)}
            </Button>
          </div>
        </div>
      </Spin>
    );
  }
}

const DynatraceSetting = injectIntl(DynatraceCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials, userInfo } = state.auth;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    return { credentials, location, userInfo, userList };
  },
  { replace },
)(DynatraceSetting);
