/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { get, ceil } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import {
  DeleteOutlined,
  LockFilled,
  UnlockFilled,
  DownloadOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  message,
  Select,
  Popconfirm,
  Button,
  Modal,
  Checkbox,
  Input,
  DatePicker,
  Form,
  Alert,
  Spin,
  notification,
} from 'antd';

import { State } from '../../../common/types';
import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { CellRenderers, parseJSON, Defaults, Regex, downloadFile, sleep } from '../../../common/utils';
import { hideAppLoader, updateLastActionInfo } from '../../../common/app/actions';
import { Container, AutoSizer, Table, Column, Popover } from '../../../lib/fui/react';
import { appButtonsMessages, appMessages, appFieldsMessages } from '../../../common/app/messages';
import { settingsMessages } from '../../../common/settings/messages';
import { authMessages } from '../../../common/auth/messages';
import { eventMessages } from '../../../common/metric/messages';

type Props = {
  intl: Object,
  refresh: Number,
  hideAppLoader: Function,
  updateLastActionInfo: Function,
  appLoaderVisible: Boolean,
  projects: Array<Object>,
  credentials: Object,
  userInfo: Object,
  isAdmin: Boolean,
};

class UserManagementCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmitting: false,

      userFilter: '',
      userSearch: '',
      accountGroupSearch: '',
      isCheckedAll: false,

      localUserList: [],

      tooltipStatusMap: {},

      showCreateUserModal: false,

      rangePickerValue: [],
    };
    this.localUserList = [];
    this.roleListOption = [];
    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
    this.allPossibleCompany = [];
    this.now = moment.utc();
    this.inputCellRender = ({ dataKey, rowData, cellData }) => {
      const { isAdmin, intl } = this.props;
      const { role, otherBelongingOrganizationSet } = rowData;
      const disable = (dataKey === 'company' && !isAdmin) || dataKey === 'email';
      const isAdminSetLocalAdmin = dataKey === 'company' && isAdmin && role === 'LocalAdmin';
      if (isAdminSetLocalAdmin) {
        return (
          <Popconfirm
            placement="right"
            icon={null}
            title={
              <div>
                <div className="flex-row flex-center-align">
                  <span style={{ flexShrink: 0, width: 150 }}>
                    {intl.formatMessage(eventMessages.homeAccountGroup)}:
                  </span>
                  <Input size="small" value={cellData || ''} onChange={this.handleInputChanged(rowData, dataKey)} />
                </div>
                <div className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                  <span style={{ flexShrink: 0, width: 150 }}>
                    {intl.formatMessage(eventMessages.managedAccountGroup)}:
                  </span>
                  <Select
                    allowClear
                    mode="tags"
                    style={{ flex: 1, maxWidth: 200 }}
                    value={otherBelongingOrganizationSet}
                    onChange={(value) => {
                      rowData.otherBelongingOrganizationSet = value;
                      this.table.forceUpdateGrid();
                      this.forceUpdate();
                    }}
                    tokenSeparators={[',']}
                    options={this.allPossibleCompany}
                  />
                </div>
              </div>
            }
            showCancel={false}
            okType="default"
            okText={intl.formatMessage(appButtonsMessages.cancel)}
            onConfirm={(event) => {
              event.stopPropagation();
            }}
          >
            <Input
              size="small"
              value={`${cellData || ''}${
                otherBelongingOrganizationSet.length > 0 ? `,${String(otherBelongingOrganizationSet)}` : ''
              }`}
            />
          </Popconfirm>
        );
      }
      return (
        <Input
          size="small"
          value={cellData || ''}
          onChange={this.handleInputChanged(rowData, dataKey)}
          disabled={disable}
        />
      );
    };
    this.ruleStrMap = {
      ReadOnlyUser: 'ReadOnlyUser',
      LocalAdmin: 'Administrator',
      NormalUser: 'NormalUser',
    };
    this.sortByMap = {
      ReadOnlyUser: 0,
      NormalUser: 1,
      LocalAdmin: 2,
    };
  }

  componentDidMount() {
    if (this.props.appLoaderVisible) {
      this.props.hideAppLoader();
    }
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.refresh !== this.props.refresh) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { credentials, userInfo } = props;
    const { companyName } = userInfo;
    const { isLocalAdmin } = userInfo;

    this.setState({ isLoading: true, userFilter: '', userSearch: '', isCheckedAll: false });
    this.localUserList = [];
    props.updateLastActionInfo();
    fetchGet(`${window.BASE_URL || ''}/adminAssignRole`, {
      ...credentials,
      companyName,
    })
      .then((data) => {
        const userRoles = parseJSON(data.roles) || [];
        let localUserList = data.message || [];
        localUserList = R.map((item) => {
          const { signupDate, loginDate, otherBelongingOrganizationSet } = item;
          return {
            ...item,
            signupDate: signupDate || '',
            loginDate: loginDate || '',
            checked: false,
            otherBelongingOrganizationSet: otherBelongingOrganizationSet || [],
          };
        }, localUserList);

        localUserList = this.sortData(localUserList, {});
        localUserList = this.sortData(localUserList, this.state);

        let filterRoles = R.filter((n) => n !== 'Admin', userRoles);
        if (isLocalAdmin) {
          filterRoles = R.filter((n) => n !== 'LocalAdmin', filterRoles);
        }

        const roleListOption = R.map(
          (role) => ({ label: this.ruleStrMap[role], value: role, order: this.sortByMap[role] }),
          filterRoles || [],
        );
        this.roleListOption = R.sortWith([R.ascend(R.prop('order'))], roleListOption);

        this.localUserList = R.clone(localUserList);

        let allPossibleCompany = [];
        R.forEach((item) => {
          if (item) {
            allPossibleCompany = [...allPossibleCompany, { label: item, value: item }];
          }
        }, data.allPossibleCompany || []);
        this.allPossibleCompany = allPossibleCompany;

        this.now = moment.utc();
        this.table.forceUpdateGrid();
        this.forceUpdate();
        this.setState({
          isLoading: false,
          localUserList,
        });
      })
      .catch((err) => {
        notification.warning({
          description: err.message,
          duration: null,
        });
        this.setState({
          isLoading: false,
        });
      });
  }

  @autobind
  sortData(datas, state) {
    const { sortBy, sortDirection } = state;

    // sort by
    let sortList = datas;
    let sortFunctions = [R.descend(R.prop('signupDate'))];
    if (sortBy && sortDirection) {
      let sortDirectionPropFunc = R.prop(sortBy);
      if (['userName', 'firstName', 'lastName', 'company', 'email', 'zone', 'role'].includes(sortBy)) {
        const composeWhileNotNil = R.composeWith((f, res) => (R.isNil(res) ? '' : f(res)));
        sortDirectionPropFunc = composeWhileNotNil([R.toLower, R.prop(sortBy)]);
      }
      sortFunctions = sortDirection === 'DESC' ? [R.descend(sortDirectionPropFunc)] : [R.ascend(sortDirectionPropFunc)];
    }
    sortList = R.sortWith(sortFunctions)(sortList);

    return sortList;
  }

  @autobind
  handleSearchUser(userSearch) {
    this.setState({ userSearch }, () => {
      let { localUserList } = this.state;
      if (userSearch) {
        const searchVal = R.toLower(userSearch);
        localUserList = R.filter((item) => {
          return (
            R.toLower(item.userName || '').includes(searchVal) ||
            R.toLower(item.firstName || '').includes(searchVal) ||
            R.toLower(item.lastName || '').includes(searchVal)
          );
        }, localUserList);
      }
      this.localUserList = R.clone(localUserList);
      if (this.table) this.table.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  handleCreateUserClick() {
    this.setState({ showCreateUserModal: true });
  }

  @autobind
  handleInputChanged(rowData, dataKey) {
    return (e) => {
      const { target } = e;
      const newVal = target.type === 'checkbox' ? target.checked : target.value || '';

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  selectZoneRender({ dataKey, rowData, cellData }) {
    const { userName } = rowData;
    if (userName === 'admin') {
      return <div />;
    }
    return (
      <Select
        showSearch
        size="small"
        style={{ width: '100%' }}
        options={this.timezoneOptions || []}
        value={cellData}
        onChange={this.handleSelectChange(rowData, dataKey)}
        optionFilterProp="label"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ maxWidth: 650 }}
      />
    );
  }

  @autobind
  selectRoleRender({ dataKey, rowData, cellData }) {
    const { userName } = rowData;
    if (userName === 'admin') {
      return <div>{cellData}</div>;
    }
    return (
      <Select
        size="small"
        style={{ width: '100%' }}
        value={cellData}
        onChange={this.handleSelectChange(rowData, dataKey)}
        dropdownMatchSelectWidth={false}
      >
        {this.roleListOption.map((item) => (
          <Select.Option key={item.value} title={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  }

  @autobind
  handleSelectChange(rowData, dataKey) {
    return (value) => {
      // Save the data and force update.
      rowData[dataKey] = value || '';
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  trialPeriodRender({ dataKey, rowData, cellData }) {
    const { userName, signupDate } = rowData;

    // change display
    const signupDateObj = signupDate ? moment.utc(signupDate) : moment.utc().startOf('day');
    let expirationDate = signupDateObj.valueOf();
    if (cellData > 10000 || cellData === 0) {
      expirationDate = signupDateObj.clone().add(100, 'years').valueOf();
    } else {
      expirationDate = signupDateObj.clone().add(cellData, 'days').valueOf();
    }
    return (
      <DatePicker
        size="small"
        disabled={userName === 'admin'}
        value={moment.utc(expirationDate)}
        onChange={(date, dateString) => {
          const trialPeriodTime = date.valueOf() - signupDateObj.valueOf();
          rowData[dataKey] = ceil(trialPeriodTime / 86400000);
          this.table.forceUpdateGrid();
          this.forceUpdate();
        }}
      />
    );
  }

  @autobind
  suspendedRender({ dataKey, rowData, cellData }) {
    const { userName } = rowData;
    return (
      <Checkbox
        disabled={userName === 'admin'}
        size="small"
        checked={cellData}
        onChange={(e) => {
          // Save the data and force update.
          rowData[dataKey] = Boolean(e.target.checked);
          this.table.forceUpdateGrid();
          this.forceUpdate();
        }}
      >
        Yes
      </Checkbox>
    );
  }

  @autobind
  renderSignupDate({ cellData }) {
    return cellData ? moment.utc(Number(cellData)).format(Defaults.DateFormat2) : '';
  }

  @autobind
  configRenderer({ rowData }) {
    const { intl } = this.props;
    const { tooltipStatusMap } = this.state;

    const { userName, remainLockedTime } = rowData;
    const locked = Boolean(remainLockedTime && remainLockedTime > 0);

    return (
      <div className="flex-row">
        <Popconfirm
          placement="topRight"
          title={
            <div>
              {intl.formatMessage(eventMessages.thisUserWillRemoveLockStatus)}
              <br />
              {intl.formatMessage(appMessages.continueConfirm)}
            </div>
          }
          okText={intl.formatMessage(appButtonsMessages.yes)}
          cancelText={intl.formatMessage(appButtonsMessages.no)}
          onConfirm={() => this.handleUserUnLockClick(rowData)}
          onCancel={(event) => event.stopPropagation()}
          disabled={!locked}
        >
          <Popover
            content={
              <div>{`Remain locked time: ${CellRenderers.humanizeDuration({ period: remainLockedTime, intl })}`}</div>
            }
            placement="topRight"
            mouseEnterDelay={0.3}
            visible={locked && get(tooltipStatusMap, [userName, 'lock'])}
            onVisibleChange={(status) => {
              this.setState({
                tooltipStatusMap: { ...tooltipStatusMap, [userName]: { ...tooltipStatusMap[userName], lock: status } },
              });
            }}
          >
            {locked && (
              <LockFilled
                style={{ fontSize: 16, margin: '0 4px', color: locked ? 'orange' : 'currentColor' }}
                onClick={(event) => {
                  event.stopPropagation();
                  this.setState({
                    tooltipStatusMap: {
                      ...tooltipStatusMap,
                      [userName]: { ...tooltipStatusMap[userName], lock: false },
                    },
                  });
                }}
              />
            )}
            {!locked && (
              <UnlockFilled
                style={{ fontSize: 16, margin: '0 4px', color: locked ? 'orange' : 'currentColor' }}
                onClick={(event) => {
                  event.stopPropagation();
                  this.setState({
                    tooltipStatusMap: {
                      ...tooltipStatusMap,
                      [userName]: { ...tooltipStatusMap[userName], lock: false },
                    },
                  });
                }}
              />
            )}
          </Popover>
        </Popconfirm>

        {userName !== 'admin' && (
          <DeleteOutlined style={{ fontSize: 16, margin: '0 4px' }} onClick={this.showDeleteConfirm(rowData)} />
        )}
      </div>
    );
  }

  @autobind
  handleSaveClick() {
    const { intl, credentials } = this.props;
    const { localUserList } = this.state;

    const diff = R.difference(this.localUserList, localUserList);
    const targetUserNames = {};
    R.forEach((user) => {
      const { userName, otherBelongingOrganizationSet, ...rest } = user;

      if (rest.role === 'LocalAdmin' && otherBelongingOrganizationSet.length > 0) {
        rest.otherBelongingOrganizationSet = otherBelongingOrganizationSet;
      }
      targetUserNames[userName] = rest;
    }, diff);

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(`${window.BASE_URL || ''}/adminAssignRole`, {
      ...credentials,
      targetUserNames: JSON.stringify(targetUserNames),
    })
      .then((data) => {
        const { message: msg, success } = data;
        if (success || success === undefined) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.reloadData(this.props);
        } else {
          message.error(msg);
        }
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        notification.warning({
          description: err.message,
          duration: null,
        });
        this.setState({
          isSubmitting: false,
        });
      });
  }

  @autobind
  handleDelect() {
    const { intl, credentials } = this.props;
    const filterLocalUserList = R.filter((item) => item.checked, this.localUserList);
    const useNameList = R.map((item) => item.userName, filterLocalUserList);
    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(
      `${window.BASE_URL || ''}/adminAssignRole`,
      { ...credentials, customerList: JSON.stringify(useNameList), option: 'delete' },
      {},
      false,
    )
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData(this.props);
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        notification.warning({
          description: err.message,
          duration: null,
        });
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  showDeleteConfirm(rowData) {
    return () => {
      const self = this;
      const { projects, intl } = this.props;
      const { userName } = rowData;
      const userProjects = R.filter((project) => project.owner === userName, projects);
      Modal.confirm({
        title: intl.formatMessage(eventMessages.areYouSureDeleteThisUser),
        content: (
          <div className="flex-col flex-min-height">
            <div>
              {userProjects.length > 0
                ? intl.formatMessage(eventMessages.theseProjectsWillBeDeleted)
                : intl.formatMessage(eventMessages.thereIsNoProjectsBelongToThisUser)}
            </div>
            <div className="flex-row overflow-y-auto" style={{ flexWrap: 'wrap', maxHeight: 100 }}>
              {R.addIndex(R.map)(
                (item, idx) => (
                  <span key={item.projectName} style={{ margin: '0 2px', color: 'green' }}>
                    {idx === userProjects.length - 1 ? item.projectName : `${item.projectName},`}
                  </span>
                ),
                userProjects,
              )}
            </div>
          </div>
        ),
        okType: 'danger',
        autoFocusButton: 'cancel',
        onOk() {
          self.handleUserRemove(rowData);
        },
        onCancel() {},
      });
    };
  }

  @autobind
  handleUserRemove(rowData) {
    const { intl, credentials } = this.props;

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(
      `${window.BASE_URL || ''}/adminAssignRole`,
      { ...credentials, customerList: JSON.stringify([rowData.userName]), option: 'delete' },
      {},
      false,
    )
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData(this.props);
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        notification.warning({
          description: err.message,
          duration: null,
        });
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  handleUserUnLockClick(rowData) {
    const { intl, credentials } = this.props;

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('unlockuser'), { ...credentials, customerName: rowData.userName })
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.reloadData(this.props);
        this.setState({ isSubmitting: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  sort({ sortBy, sortDirection }) {
    const { sortDirection: prevSortDirection } = this.state;
    this.setState(
      {
        sortBy,
        sortDirection: !prevSortDirection ? 'ASC' : prevSortDirection === 'DESC' ? undefined : sortDirection,
      },
      () => {
        const localUserList = this.sortData(this.localUserList, this.state);
        this.localUserList = R.clone(localUserList);
        if (this.table) {
          this.table.forceUpdateGrid();
          this.forceUpdate();
        }
      },
    );
  }

  @autobind
  headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey || !sortDirection) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  async handleDownloadClick() {
    const { intl } = this.props;
    const { localUserList, rangePickerValue } = this.state;

    await sleep(600);

    // show waiting download modal
    const progressModal = Modal.info({
      title: null,
      footer: null,
      centered: true,
      className: 'hide-confirm-btns',
      okButtonProps: { display: 'none' },
      content: (
        <div className="flex-row flex-center-align flex-center-justify">
          <Spin tip={intl.formatMessage(appMessages.waitingDownload)} />
        </div>
      ),
    });

    const fname = `User list.csv`;
    let csvString = `${R.join(',', [
      'User Name',
      'First Name',
      'Last Name',
      'Account Group Name',
      'Email',
      'Signup Date',
      'Last Login Time',
      'Timezone',
      'Role',
      'Expiration date',
    ])}\r\n`;
    const csvData = [];

    let newLocalUserList = localUserList;
    if (rangePickerValue.length > 0) {
      newLocalUserList = R.filter((item) => {
        const time = moment.utc(Number(item.signupDate)).valueOf();
        const stateFlag = moment.utc(rangePickerValue[0]).valueOf();
        const endFlag = moment.utc(rangePickerValue[1]).add(1, 'days').valueOf();
        return stateFlag <= time && time <= endFlag;
      }, newLocalUserList);
    }

    R.forEach((item) => {
      const { signupDate, trialPeriod } = item;
      // change display
      const signupDateObj = signupDate ? moment.utc(signupDate) : moment.utc().startOf('day');
      let expirationDate = signupDateObj;
      if (trialPeriod > 10000 || trialPeriod === 0) {
        expirationDate = signupDateObj.clone().add(100, 'years');
      } else {
        expirationDate = signupDateObj.clone().add(trialPeriod, 'days');
      }

      csvData.push(
        R.join(',', [
          item.userName,
          item.firstName,
          item.lastName,
          item.company,
          item.email,
          moment.utc(Number(item.signupDate)).format(Defaults.DateFormat2),
          moment.utc(Number(item.loginDate)).format(Defaults.DateFormat2),
          item.zone,
          item.role,
          expirationDate.format(Defaults.DateFormat2),
        ]),
      );
    }, newLocalUserList);
    csvString += R.join('\r\n', csvData);

    downloadFile(csvString, fname);

    progressModal.destroy();
  }

  @autobind
  checkboxCellRender({ dataKey, rowData, cellData }) {
    return (
      <Checkbox
        size="small"
        checked={cellData || false}
        onChange={(e) => {
          const { rangePickerValue, accountGroupSearch } = this.state;
          let newLocalUserList = this.localUserList;
          rowData[dataKey] = Boolean(e.target.checked);

          if (rangePickerValue.length > 0) {
            newLocalUserList = R.filter((item) => {
              const time = moment.utc(Number(item.signupDate)).valueOf();
              const stateFlag = moment.utc(rangePickerValue[0]).valueOf();
              const endFlag = moment.utc(rangePickerValue[1]).add(1, 'days').valueOf();
              return stateFlag <= time && time <= endFlag;
            }, newLocalUserList);
          }

          if (accountGroupSearch) {
            newLocalUserList = R.filter(
              (item) => R.toLower(item.company || '').includes(R.toLower(accountGroupSearch)),
              newLocalUserList,
            );
          }

          const isCheckedAll = newLocalUserList.length > 0 && !R.find((m) => !m.checked, newLocalUserList);
          this.setState({ isCheckedAll }, () => {
            if (this.table) {
              this.table.forceUpdateGrid();
              this.forceUpdate();
            }
          });
        }}
      />
    );
  }

  @autobind
  checkAllHeaderRender(fieldName) {
    return () => {
      return (
        <Checkbox size="small" checked={this.state[fieldName]} onChange={this.handleAllCheckedChange(fieldName)} />
      );
    };
  }

  @autobind
  handleAllCheckedChange(fieldName) {
    return (e) => {
      const { rangePickerValue, accountGroupSearch } = this.state;
      let newLocalUserList = this.localUserList;
      const { checked } = e.target;

      if (rangePickerValue.length > 0) {
        newLocalUserList = R.filter((item) => {
          const time = moment.utc(Number(item.signupDate)).valueOf();
          const stateFlag = moment.utc(rangePickerValue[0]).valueOf();
          const endFlag = moment.utc(rangePickerValue[1]).add(1, 'days').valueOf();
          return stateFlag <= time && time <= endFlag;
        }, newLocalUserList);
      }

      if (accountGroupSearch) {
        newLocalUserList = R.filter(
          (item) => R.toLower(item.company || '').includes(R.toLower(accountGroupSearch)),
          newLocalUserList,
        );
      }
      R.forEach((m) => {
        if (fieldName === 'isCheckedAll') {
          m.checked = checked;
        }
      }, newLocalUserList);
      this.setState({ [fieldName]: checked }, () => {
        if (this.table) {
          this.table.forceUpdateGrid();
          this.forceUpdate();
        }
      });
    };
  }

  @autobind
  handleAccountGroupSearch(accountGroupSearch) {
    const { rangePickerValue } = this.state;
    let { isCheckedAll } = this.state;
    let newLocalUserList = this.localUserList;
    if (rangePickerValue.length > 0) {
      newLocalUserList = R.filter((item) => {
        const time = moment.utc(Number(item.signupDate)).valueOf();
        const stateFlag = moment.utc(rangePickerValue[0]).valueOf();
        const endFlag = moment.utc(rangePickerValue[1]).add(1, 'days').valueOf();
        return stateFlag <= time && time <= endFlag;
      }, newLocalUserList);
    }

    if (accountGroupSearch) {
      newLocalUserList = R.filter(
        (item) => R.toLower(item.company || '').includes(R.toLower(accountGroupSearch)),
        newLocalUserList,
      );
    }

    isCheckedAll = newLocalUserList.length > 0 && !R.find((m) => !m.checked, newLocalUserList);
    this.setState({ accountGroupSearch, isCheckedAll }, () => {
      if (this.table) {
        this.table.forceUpdateGrid();
        this.forceUpdate();
      }
    });
  }

  render() {
    const { intl, isAdmin, credentials, updateLastActionInfo, userInfo } = this.props;
    const { isLoading, isSubmitting, localUserList, rangePickerValue, accountGroupSearch } = this.state;
    const { sortBy, sortDirection } = this.state;
    const { isLocalAdmin } = userInfo;

    const diff = R.difference(this.localUserList, localUserList);
    const hasError =
      diff.length === 0 ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => Boolean(item.role), diff),
      );

    let newLocalUserList = this.localUserList;
    if (rangePickerValue.length > 0) {
      newLocalUserList = R.filter((item) => {
        const time = moment.utc(Number(item.signupDate)).valueOf();
        const stateFlag = moment.utc(rangePickerValue[0]).valueOf();
        const endFlag = moment.utc(rangePickerValue[1]).add(1, 'days').valueOf();
        return stateFlag <= time && time <= endFlag;
      }, newLocalUserList);
    }
    if (accountGroupSearch) {
      newLocalUserList = R.filter(
        (item) => R.toLower(item.company || '').includes(R.toLower(accountGroupSearch)),
        newLocalUserList,
      );
    }

    return (
      <Container fullHeight className={`${isLoading ? 'loading' : ''}`}>
        <Container className="full-height flex-col">
          <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
            <div className="flex-grow flex-row">
              <Input.Search
                size="small"
                placeholder={intl.formatMessage(eventMessages.userSearch)}
                style={{ width: 200 }}
                value={this.state.userFilter}
                allowClear
                enterButton
                onSearch={(value) => this.handleSearchUser(value)}
                onChange={(e) => this.setState({ userFilter: e.target.value })}
              />
              {(isAdmin || isLocalAdmin) && (
                <Input.Search
                  size="small"
                  placeholder={intl.formatMessage(eventMessages.accountGroupSearch)}
                  style={{ width: 200, marginLeft: 16 }}
                  allowClear
                  enterButton
                  onSearch={(accountGroupSearch) => this.handleAccountGroupSearch(accountGroupSearch)}
                />
              )}
              <Button size="small" type="primary" style={{ marginLeft: 16 }} onClick={this.handleCreateUserClick}>
                {intl.formatMessage(settingsMessages.createNewUser)}
              </Button>
              <Button size="small" type="primary" style={{ marginLeft: 16 }} onClick={this.handleDownloadClick}>
                <DownloadOutlined /> {intl.formatMessage(appButtonsMessages.download)}
              </Button>
              <div className="flex-row flex-center-align" style={{ marginLeft: 16 }}>
                <div>{intl.formatMessage(eventMessages.signupDate)}:</div>
                <DatePicker.RangePicker
                  size="small"
                  style={{ margin: '0 10px' }}
                  onChange={(date) => {
                    this.setState({ rangePickerValue: date ? [date[0].valueOf(), date[1].valueOf()] : [] });
                  }}
                />
              </div>
            </div>
            <div className="flex-row">
              <div className="bold light-label" style={{ marginRight: 8 }}>
                {intl.formatMessage(eventMessages.totalUsers)}:
              </div>
              <div>{localUserList.length}</div>
            </div>
          </div>
          <Container className={`flex-grow field ${isSubmitting ? 'disabled' : ''}`}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  rowCount={newLocalUserList.length}
                  rowGetter={({ index }) => newLocalUserList[index]}
                  ref={(table) => {
                    this.table = table;
                  }}
                  sort={this.sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  {(isAdmin || isLocalAdmin) && (
                    <Column
                      width={40}
                      disableSort
                      dataKey="checked"
                      className="text-center"
                      headerClassName="text-center"
                      headerRenderer={this.checkAllHeaderRender('isCheckedAll')}
                      cellRenderer={this.checkboxCellRender}
                    />
                  )}
                  <Column
                    width={100}
                    label={intl.formatMessage(eventMessages.userName)}
                    dataKey="userName"
                    headerRenderer={this.headerRenderer}
                    flexGrow={1}
                  />
                  <Column
                    width={100}
                    label={intl.formatMessage(eventMessages.firstName)}
                    dataKey="firstName"
                    cellRenderer={this.inputCellRender}
                    headerRenderer={this.headerRenderer}
                    flexGrow={1}
                  />
                  <Column
                    width={100}
                    label={intl.formatMessage(eventMessages.lastName)}
                    dataKey="lastName"
                    cellRenderer={this.inputCellRender}
                    headerRenderer={this.headerRenderer}
                    flexGrow={1}
                  />
                  <Column
                    width={100}
                    label={intl.formatMessage(eventMessages.accountGroup)}
                    dataKey="company"
                    cellRenderer={this.inputCellRender}
                    headerRenderer={this.headerRenderer}
                    flexGrow={1}
                  />
                  <Column
                    width={100}
                    label={intl.formatMessage(appFieldsMessages.email)}
                    dataKey="email"
                    // cellRenderer={this.inputCellRender}
                    cellRenderer={({ cellData }) => {
                      return (
                        <Popover placement="topRight" mouseEnterDelay={0.3} content={cellData || ''}>
                          <div className="hidden-line-with-ellipsis clickable">{cellData || ''}</div>
                        </Popover>
                      );
                    }}
                    headerRenderer={this.headerRenderer}
                    flexGrow={1}
                  />
                  <Column
                    width={110}
                    label={intl.formatMessage(eventMessages.signupDate)}
                    dataKey="signupDate"
                    cellRenderer={this.renderSignupDate}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={130}
                    label={intl.formatMessage(eventMessages.lastLoginTime)}
                    dataKey="loginDate"
                    cellRenderer={CellRenderers.years}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={100}
                    label={intl.formatMessage(appFieldsMessages.defaultTimezone)}
                    dataKey="zone"
                    cellRenderer={this.selectZoneRender}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={120}
                    label={intl.formatMessage(eventMessages.role)}
                    dataKey="role"
                    cellRenderer={this.selectRoleRender}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={130}
                    label={intl.formatMessage(authMessages.expirationDate)}
                    dataKey="trialPeriod"
                    cellRenderer={this.trialPeriodRender}
                    headerRenderer={this.headerRenderer}
                  />
                  <Column
                    width={80}
                    label={intl.formatMessage(eventMessages.suspended)}
                    dataKey="isSuspended"
                    cellRenderer={this.suspendedRender}
                    headerRenderer={this.headerRenderer}
                  />
                  {(isAdmin || isLocalAdmin) && (
                    <Column width={70} label="" cellRenderer={this.configRenderer} dataKey="userName" disableSort />
                  )}
                </Table>
              )}
            </AutoSizer>
          </Container>
          <div className="flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            {(isAdmin || isLocalAdmin) && (
              <Button
                type="primary"
                loading={isSubmitting}
                disabled={!R.find((item) => item.checked, this.localUserList)}
                onClick={this.handleDelect}
                style={{ marginRight: 8 }}
              >
                {intl.formatMessage(appButtonsMessages.delete)}
              </Button>
            )}
            <Button type="primary" loading={isSubmitting} disabled={hasError} onClick={this.handleSaveClick}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </div>
        </Container>

        {this.state.showCreateUserModal && (
          <UserCreateModal
            intl={intl}
            credentials={credentials}
            updateLastActionInfo={updateLastActionInfo}
            users={localUserList}
            userInfo={userInfo}
            timezoneOptions={this.timezoneOptions}
            onClose={(reload) =>
              this.setState({ showCreateUserModal: false }, () => {
                if (reload) this.reloadData(this.props);
              })
            }
          />
        )}
      </Container>
    );
  }
}

const UserCreateModal = ({
  intl,
  credentials,
  updateLastActionInfo,
  users,
  timezoneOptions,
  onClose,
  userInfo,
}: Object) => {
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const allUserNames = R.map((item) => item.userName, users || []);
  const { isLocalAdmin, companyName } = userInfo;

  const handleCreateUser = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        setSubmitting(true);
        updateLastActionInfo();
        fetchPost(`${window.BASE_URL || ''}/customersignupbyadmin`, {
          ...credentials,
          companyName,
          newCustomerModel: JSON.stringify(values),
        })
          .then((data) => {
            const { success, message: errMsg } = data || {};
            if (success === undefined || success) {
              message.success(intl.formatMessage(appMessages.apiSuccess));
              setSubmitting(false);
              setErrMsg();
              onClose(true);
            } else {
              setSubmitting(false);
              setErrMsg(errMsg);
            }
          })
          .catch((err) => {
            setSubmitting(false);
            setErrMsg(`${err.message || String(err)}`);
          });
      })
      .catch((errorInfo) => {});
  }, [form]);

  return (
    <Modal
      title={intl.formatMessage(settingsMessages.createNewUser)}
      width={850}
      visible
      maskClosable={false}
      onCancel={() => onClose()}
      onOk={handleCreateUser}
      okButtonProps={{ loading: isSubmitting }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        labelWrap
        form={form}
        initialValues={{ timezone: 'UTC', companyName: isLocalAdmin ? companyName : '' }}
      >
        <Form.Item
          name="firstName"
          label={intl.formatMessage(appFieldsMessages.fname)}
          rules={[
            {
              required: true,
              message: intl.formatMessage(appFieldsMessages.inputRequired),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={intl.formatMessage(appFieldsMessages.lname)}
          rules={[
            {
              required: true,
              message: intl.formatMessage(appFieldsMessages.inputRequired),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="companyName"
          label={intl.formatMessage(eventMessages.accountGroupName)}
          rules={[
            {
              required: true,
              message: intl.formatMessage(appFieldsMessages.inputRequired),
            },
          ]}
        >
          <Input disabled={isLocalAdmin} />
        </Form.Item>
        <Form.Item
          name="phone"
          label={intl.formatMessage(appFieldsMessages.phoneNumber)}
          rules={[
            {
              required: true,
              message: intl.formatMessage(appFieldsMessages.inputRequired),
            },
            {
              validator: (_, value) => {
                if (value) {
                  if (!Regex.phone.test(value)) {
                    return Promise.reject(new Error(intl.formatMessage(authMessages.errorsPhoneIncorrect)));
                  } else {
                    return Promise.resolve();
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label={intl.formatMessage(appFieldsMessages.email)}
          rules={[
            {
              required: true,
              message: intl.formatMessage(appFieldsMessages.inputRequired),
            },
            {
              type: 'email',
              message: intl.formatMessage(authMessages.errorsEmailIncorrect),
            },
            // {
            //   validator: (_, value) => {
            //     if (value) {
            //       if (!Regex.emailExcludePerson.test(value)) {
            //         return Promise.reject(new Error(intl.formatMessage(authMessages.errorsEmailIncorrectPerson)));
            //       } else {
            //         return Promise.resolve();
            //       }
            //     }
            //     return Promise.resolve();
            //   },
            // },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="userName"
          label={intl.formatMessage(appFieldsMessages.userName)}
          rules={[
            {
              required: true,
              message: intl.formatMessage(appFieldsMessages.inputRequired),
            },
            {
              validator: (_, value) => {
                if (value) {
                  if (!Regex.userName.test(value)) {
                    return Promise.reject(new Error(intl.formatMessage(authMessages.errorsUserNameContain)));
                  } else if (allUserNames.includes(value)) {
                    return Promise.reject(new Error('User name already exist.'));
                  } else {
                    return Promise.resolve();
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label={intl.formatMessage(appFieldsMessages.password)}
          rules={[
            {
              required: true,
              message: intl.formatMessage(appFieldsMessages.inputRequired),
            },
            {
              validator: (_, value) => {
                if (value) {
                  if (value.length < 8) {
                    return Promise.reject(new Error(intl.formatMessage(authMessages.errorsPasswordLength)));
                  } else if (!value.match(/[0-9]/g)) {
                    return Promise.reject(new Error(intl.formatMessage(authMessages.errorsPasswordNumber)));
                  } else if (!value.match(/[A-Z]/g)) {
                    return Promise.reject(new Error(intl.formatMessage(authMessages.errorsPasswordUppercase)));
                  } else if (!value.match(/[a-z]/g)) {
                    return Promise.reject(new Error(intl.formatMessage(authMessages.errorsPasswordLowercase)));
                  } else if (!value.match(/[~!@#$%^&*_+?:]/g)) {
                    return Promise.reject(new Error(intl.formatMessage(authMessages.errorsPasswordSpecialCharacters)));
                  } else {
                    return Promise.resolve();
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={intl.formatMessage(appFieldsMessages.confirmPassword)}
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: intl.formatMessage(appFieldsMessages.inputRequired),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(intl.formatMessage(authMessages.errorsPasswordNotMatch)));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="timezone"
          label={intl.formatMessage(appFieldsMessages.defaultTimezone)}
          rules={[
            {
              required: true,
              message: intl.formatMessage(appFieldsMessages.inputRequired),
            },
          ]}
        >
          <Select showSearch filterOption options={timezoneOptions} />
        </Form.Item>

        {errMsg && (
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Alert message={errMsg} type="error" showIcon />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

const UserManagement = injectIntl(UserManagementCore);
export default connect(
  (state: State) => {
    const { appLoaderVisible } = state.app;
    const { projects } = state.app;
    const { credentials, userInfo } = state.auth;
    const { isAdmin } = userInfo;
    return { appLoaderVisible, projects, credentials, isAdmin, userInfo };
  },
  { hideAppLoader, updateLastActionInfo },
)(UserManagement);
