/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2024
 * @author YueYong
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { get, isArray } from 'lodash';
import { Popover, Empty, Card, Tooltip, Button, DatePicker } from 'antd';
import { LeftOutlined, RightOutlined, ReloadOutlined } from '@ant-design/icons';
import { State } from '../../common/types';
import { Defaults, parseLocation, MetricRenderers, parseJSON } from '../../common/utils';
import { Container, AutoSizer } from '../../lib/fui/react';

import { LineChart } from '../../lib/fui/react/charts';

import { appFieldsMessages } from '../../common/app/messages';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import {
  calculationLogic,
  calculationEventLogic,
  calculationWithBaseLineLogic,
} from '../../common/metric/logic/newMetricChartDataLogic';
import { formatNormalEventList, formatWithPrediction } from '../../common/apis/newFormatMetricEventList';
import { baseLineDataListFormat } from '../../common/apis/getMetricLineChartsBaseLine';
import calculationBaseLineLogic from '../../common/metric/logic/newMetricLineChartsBaselineLogic';
import SeparateInstanceChartModal from './components/SeparateInstanceChartModal';

const RED_COLOR = '#F2495C';
const VIEW_MODE = {
  LIST: 'list',
  GRID: 'grid',
};
const DEFAULT_CONFIG = {
  chartHeight: 212,
  viewMode: VIEW_MODE.LIST,
  gridColumnCount: 2,
  chartPadding: 25,
  showMetricName: 0,
  showMetricUnit: 0,
  withBaseline: 1,
  showChartCardMode: 0,
  dark: 0,
  zoomAll: 0,
  predictedFlag: 0,
};
const filterPredict = true;

type Props = {
  // Common props
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  replace: Function,
};

class EmbedMetricLineChartsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { location } = props;
    const query = parseLocation(location);
    let { instanceList, metricList } = query;
    const {
      startTimeWindow,
      endTimeWindow,
      hideBaseline,
      jwtToken,
      jwt,
      viewMode,
      chartHeight,
      gridColumnCount,
      showMetricName,
      showMetricUnit,
      showChartCardMode,
      zoomAll,
      startTimestamp,
      startTime,
      endTimestamp,
      endTime,
      projectName,
      chartWidth,
      zoneName,
      systemName,
      customerName,
      predictedFlag,
      hideDateRangeSelector,
    } = query;

    let chartDateWindow = [];
    if (startTimeWindow && endTimeWindow) {
      chartDateWindow = [Number(startTimeWindow), Number(endTimeWindow)];
    } else if (startTimestamp && endTimestamp) {
      chartDateWindow = [Number(startTimestamp), Number(endTimestamp)];
    } else if (startTime && endTime) {
      chartDateWindow = [Number(startTime), Number(endTime)];
    }
    if (metricList) {
      metricList = parseJSON(metricList) || [];
    }
    if (instanceList) {
      instanceList = parseJSON(instanceList) || [];
    }
    this.state = {
      jwtToken: jwtToken || jwt,
      zoneName,
      systemName,
      projectName,
      customerName,
      // eslint-disable-next-line react/no-unused-state
      viewMode: viewMode || DEFAULT_CONFIG.viewMode,
      chartHeight: chartHeight || DEFAULT_CONFIG.chartHeight,
      gridColumnCount: gridColumnCount || DEFAULT_CONFIG.gridColumnCount,
      showMetricName: +(showMetricName || DEFAULT_CONFIG.showMetricName),
      showMetricUnit: +(showMetricUnit || DEFAULT_CONFIG.showMetricUnit),
      withBaseline: !+hideBaseline,
      showChartCardMode: +(showChartCardMode || DEFAULT_CONFIG.showChartCardMode),
      zoomAll: +(zoomAll || DEFAULT_CONFIG.zoomAll),
      startTimestamp: startTimestamp || startTime || startTimeWindow,
      endTimestamp: endTimestamp || endTime || endTimeWindow,
      datasetStartTime: startTimestamp || startTime || startTimeWindow,
      datasetEndTime: endTimestamp || endTime || endTimeWindow,
      predictedFlag: +(predictedFlag || DEFAULT_CONFIG.predictedFlag),
      hideDateRangeSelector: +hideDateRangeSelector,
      chartWidth,
      // params
      chartDateWindow,
      metricDatasetMap: {},
      instanceList,
      metricList: isArray(metricList) ? metricList : [metricList],
      // eslint-disable-next-line react/no-unused-state
      componentMapLoading: false,
      // eslint-disable-next-line react/no-unused-state
      sampleInterval: 1,
      instanceComponentMap: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      const {
        projectName,
        startTimestamp,
        endTimestamp,
        jwtToken,
        metricList,
        instanceList,
        withBaseline,
        systemName,
        customerName,
        zoneName,
        predictedFlag,
      } = this.state;
      // 非法输入不去加载数据
      if (
        !jwtToken ||
        !projectName ||
        !instanceList?.length ||
        !metricList?.length ||
        !(!!startTimestamp && !!endTimestamp)
      ) {
        throw new Error();
      }
      this.setState({
        isLoading: true,
        metricEventListMap: {},
        metricDatasetMap: {},
        // eslint-disable-next-line react/no-unused-state
        metricUnitMapping: {},
        currentTimestamp: undefined,
        columnInfoMap: {},
        latestDataTime: undefined,
        k8CoverageMap: {},
        // eslint-disable-next-line react/no-unused-state
        eventMetricList: [],
        metricRootCauseMap: {},
        instanceDownRootCauseList: [],
        sampleInterval: 0,
        instanceComponentMap: {},
        timeChange: false,
      });
      const startTimeObj = moment.utc(Number(startTimestamp)).startOf('day');
      const metricLinechartRes = await fetchGet(getEndpoint('metric-linechart-jwt'), {
        jwt: jwtToken,
        startTime: startTimestamp,
        endTime: endTimestamp,
        systemName,
        customerName,
        zoneName,
        instanceList: JSON.stringify(instanceList),
        metricList: JSON.stringify(metricList),
        predictedFlag: !!predictedFlag,
        projectName: decodeURIComponent(projectName),
      });
      const {
        splitCsvData,
        baseLine,
        events,
        predictionEvents,
        instanceComponentMap,
        sampleInterval = 0,
        userZonePredictionStartTime,
        predictionBaseLine,
      } = metricLinechartRes;
      let nowTimestamp = moment.utc().valueOf();
      const topestRank = 100; // 默认100
      let eventList = [];
      if (userZonePredictionStartTime) {
        nowTimestamp = moment.utc(userZonePredictionStartTime).valueOf();
      }

      if (predictionEvents?.myArrayList) {
        const eventsMap = formatWithPrediction(
          [
            {
              data: {
                All: R.map((event) => event.map, events?.myArrayList || []),
              },
            },
            {
              data: {
                All: R.map((event) => event.map, predictionEvents?.myArrayList || []),
              },
            },
          ],
          'All',
          undefined,
          filterPredict,
          nowTimestamp,
        );
        eventList = eventsMap.eventList || [];
      } else {
        const eventsMap = formatNormalEventList(
          [
            {
              data: {
                All: R.map((event) => event.map, events?.myArrayList || []),
              },
            },
          ],
          'All',
          filterPredict,
          nowTimestamp,
        );
        eventList = eventsMap.eventList || [];
      }

      const { eventMetricList, metricRootCauseMap, instanceDownRootCauseList, newEventList } = calculationEventLogic(
        eventList,
        instanceList,
        sampleInterval || 1,
        topestRank,
      );
      eventList = newEventList;
      // Handle instance down root cause list
      if (instanceDownRootCauseList.length > 0 && eventMetricList) {
        R.forEach((metric) => {
          const rcList = metricRootCauseMap[metric] || [];
          metricRootCauseMap[metric] = [...rcList, ...instanceDownRootCauseList];
        }, eventMetricList);
      }

      const anomalyMetricByInstances = metricList;
      const {
        metricEventListMap, // Mapping of metricName calculated from csv data: eventList
        metricDatasetMap, // metricName calculated from csv data: mapping of chartdata
        metricUnitMapping, // Mapping of metricName : unit calculated from csv data
        currentTimestamp, // The current time is used to split prediction data
        columnInfoMap, // Column metricName calculated from csv data: mapping of columns
        latestDataTime, // The time of the latest data in all csv data
        k8CoverageMap, // Calculated from the k8Coverage information of linechart data
      } = calculationLogic(
        [
          {
            userZonePredictionStartTime,
            metricUnitMapping: metricLinechartRes.metricUnitMapping?.myArrayList || [],
            splitCsvData: splitCsvData.map || {},
            k8CoverageMap: JSON.stringify(metricLinechartRes.k8CoverageMap || '{}'),
          },
        ],
        startTimeObj,
        undefined,
        metricList,
        metricList,
        metricRootCauseMap,
        instanceList,
        anomalyMetricByInstances,
        metricList,
        topestRank,
        eventList,
      );
      let newMetricDatasetMap = metricDatasetMap;
      if (withBaseline) {
        // 预测基线处理部分
        const predictionBaseLineObj = parseJSON(predictionBaseLine || '{}') || {};

        const predictionData = {};
        R.forEachObjIndexed((vals, timestamp) => {
          R.forEachObjIndexed((baseData, instance) => {
            if (!R.has(instance, predictionData)) {
              predictionData[instance] = [];
            }
            predictionData[instance].push(baseData);
          }, vals);
        }, predictionBaseLineObj);

        // baseline calculation
        const { eventLineChartBaselineData } = calculationBaseLineLogic(
          [baseLine.map, baseLineDataListFormat([predictionData])],
          metricList,
        );
        newMetricDatasetMap = calculationWithBaseLineLogic(eventLineChartBaselineData, metricDatasetMap, columnInfoMap);
      }
      const instanceComponentMapObj = {};
      if (instanceComponentMap) {
        const instanceComponentMapArr = parseJSON(instanceComponentMap) || [];
        // eslint-disable-next-line no-return-assign
        R.forEach((item) => {
          instanceComponentMapObj[item.instanceName] = item.component;
        }, instanceComponentMapArr);
      }
      this.setState({
        metricEventListMap,
        metricDatasetMap: newMetricDatasetMap,
        // eslint-disable-next-line react/no-unused-state
        metricUnitMapping,
        currentTimestamp,
        columnInfoMap,
        latestDataTime,
        k8CoverageMap,
        // eslint-disable-next-line react/no-unused-state
        eventMetricList,
        metricRootCauseMap,
        instanceDownRootCauseList,
        sampleInterval,
        instanceComponentMap: instanceComponentMapObj,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePreviousDayClick = () => {
    const { startTimestamp, endTimestamp } = this.state;
    const startTimeObj = moment.utc(startTimestamp).startOf('day').subtract(1, 'day');
    const endTimeObj = moment.utc(endTimestamp).endOf('day').subtract(1, 'day');
    this.setState({
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      timeChange: startTimestamp !== startTimeObj.valueOf() || endTimestamp !== endTimeObj.valueOf(),
    });
  };

  handleNextDayClick = () => {
    const { startTimestamp, endTimestamp } = this.state;
    const startTimeObj = moment.utc(startTimestamp).startOf('day').add(1, 'day');
    const endTimeObj = moment.utc(endTimestamp).endOf('day').add(1, 'day');
    this.setState({
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      timeChange: startTimestamp !== startTimeObj.valueOf() || endTimestamp !== endTimeObj.valueOf(),
    });
  };

  handleStartTimeChange = (startTimeObj) => {
    const { startTimestamp, timeChange } = this.state;
    const relStartTimestamp = startTimeObj.startOf('day').valueOf();
    this.setState({
      startTimestamp: relStartTimestamp,
      timeChange: startTimestamp !== relStartTimestamp || timeChange,
    });
  };

  handleEndTimeChange = (endTimeObj) => {
    const { endTimestamp, timeChange } = this.state;
    const relEndTimestamp = endTimeObj.endOf('day').valueOf();
    this.setState({
      endTimestamp: relEndTimestamp,
      timeChange: endTimestamp !== relEndTimestamp || timeChange,
    });
  };

  handleDateWindowSync = (dateWindow) => {
    this.setState({ chartDateWindow: dateWindow });
  };

  // annotation组件
  Annotation = ({ x, timestamp, annos, isCurrent, ...rest }) => {
    const { intl } = this.props;
    const { instanceDisplayNameMap, sampleInterval, k8CoverageMap } = this.state;
    if (isCurrent) {
      return (
        <div key="isCurrent" className="anno" style={{ left: x, height: 0, width: 0, marginTop: -6 }}>
          <div
            style={{
              position: 'absolute',
              whiteSpace: 'nowrap',
              color: '--light-label',
              transform: 'translateX(-50%)',
              marginTop: -16,
            }}
          >
            {`${intl.formatMessage(appFieldsMessages.currentTime)}: ${moment
              .utc(timestamp)
              .format(Defaults.TimeOnlyFormat)}`}
          </div>
          <div
            style={{
              position: 'absolute',
              height: 0,
              width: 0,
              borderTop: '4px solid var(--linechart-now-arrow-color)',
              borderLeft: '4px solid transparent',
              borderRight: '4px solid transparent',
              transform: 'translateX(-50%)',
            }}
          />
        </div>
      );
    }

    const maxPct = R.reduce(
      R.max,
      0,
      R.map((anno) => parseFloat(anno.pct), annos),
    );

    let isHigher = false;
    R.forEach((a) => {
      if (a.isHigher) {
        isHigher = true;
      }
    }, annos);

    const backgroundColor =
      maxPct >= 200 ? RED_COLOR : maxPct > 0 ? 'orange' : maxPct === 0 ? (isHigher ? 'orange' : '#2185d0') : '#2185d0';
    return (
      <Popover
        key={`${timestamp}`}
        overlayClassName="dark"
        placement="right"
        title={null}
        color="rgba(15,26,30,1)"
        zIndex={1001}
        content={
          <>
            {MetricRenderers.LineChartAnnotation(
              timestamp,
              annos,
              intl,
              { sampleInterval },
              k8CoverageMap,
              instanceDisplayNameMap,
            )}
          </>
        }
      >
        <div className="anno" style={{ left: x, borderColor: backgroundColor }}>
          <div className="indicator" />
        </div>
      </Popover>
    );
  };

  renderListItem = ({ metricList, width, height }) => {
    // eslint-disable-next-line react/prop-types
    const { intl, customerName } = this.props;
    const {
      viewMode,
      metricDatasetMap,
      instanceList,
      chartDateWindow,
      startTimestamp,
      endTimestamp,
      projectName,
      sampleInterval,
      columnInfoMap = {},
      metricEventListMap = {},
      latestDataTime,
      currentTimestamp,
      instanceDisplayNameMap = {},
      k8CoverageMap = {},
      withBaseline,
      chartHeight,
      gridColumnCount,
      showMetricName,
      showMetricUnit,
      showChartCardMode,
      zoomAll,
      chartWidth,
      instanceComponentMap = {},
      systemName,
    } = this.state;
    // reset chart data window by latestDataTime
    const currentStartTime = moment.utc().startOf('day').valueOf();
    if (latestDataTime > currentStartTime && latestDataTime < chartDateWindow[1]) {
      chartDateWindow[1] = latestDataTime + 2 * 60 * 1000;
    }
    const renderMetric = (metric, lineChartWidth, lineChartHeight) => {
      const mds = get(metricDatasetMap, metric);
      if (mds) {
        const { name, unit, labels, data, rootCauseMap, predictionlabelMap } = mds;
        const labelsVisibility = R.map(
          (label) => {
            const info = columnInfoMap[label];
            return info ? (instanceList || []).includes(info.instanceId) : true;
          },
          R.filter((label) => label !== 'timestamp', labels),
        );
        // merge metric annos
        const annotationTimeMap = {};
        const elist = metricEventListMap[metric] || [];
        R.forEach((event) => {
          const { isPrediction, startTimestamp, endTimestamp } = event;
          const { rootCauseJson } = event;
          let { rootCauseDetailsArr } = rootCauseJson;
          rootCauseDetailsArr = R.filter(
            (item) => item.rootCauseMetric === metric && R.indexOf(item.instanceId, instanceList) >= 0,
            rootCauseDetailsArr || [],
          );

          // push root cause to annotations
          R.forEach((rc) => {
            const timeRangeList = rc.timeRangeList || [];
            if (timeRangeList.length > 0) {
              const rootCauseStartTime =
                timeRangeList[0].startTimestamp - (rc.needHighlightPading ? (sampleInterval || 1) * 1000 : 0);

              if (!R.has(rootCauseStartTime, annotationTimeMap)) {
                annotationTimeMap[rootCauseStartTime] = [];
              }
              annotationTimeMap[rootCauseStartTime].push({
                ...rc,

                // event info
                isPrediction,
                startTimestamp,
                endTimestamp,
              });
            }
          }, rootCauseDetailsArr);
        }, elist);
        const annotations = [];
        R.forEachObjIndexed((annos, timestamp) => {
          annotations.push({
            timestamp: Number(timestamp),
            annos,
          });
        }, annotationTimeMap);
        if (latestDataTime > currentTimestamp) {
          annotations.push({
            timestamp: Number(currentTimestamp),
            isCurrent: true,
          });
        }
        const onClickSeparateInstance = () => {
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            showSeparateInstanceChartModal: true,
            // eslint-disable-next-line react/no-unused-state
            activeMetricData: {
              currentTimestamp,
              columnInfoMap,
              samplingIntervalInSecond: sampleInterval,
              name,
              unit,
              labels,
              data,
              predictionlabelMap,
              annotations,
              labelsVisibility,
              rootCauseMap,
              chartDateWindow,
              isCustomData: !!withBaseline,
              handleOnCreateAnnotation: this.Annotation,
            },
          });
        };

        return (
          <LineChart
            width={lineChartWidth}
            height={lineChartHeight}
            intl={intl}
            currentTimestamp={currentTimestamp}
            labelMapping={(node, x) => {
              const { instanceId } = get(columnInfoMap, [node], {});
              const component = get(instanceComponentMap, instanceId, '');
              const k8Coverage = k8CoverageMap[instanceId] || [];
              const findCoverage = R.find((item) => item[1]?.s <= x && x <= item[1]?.e, k8Coverage);
              let hostID = null;
              let podID = null;
              if (findCoverage) {
                hostID = findCoverage[0]?.h;
                podID = findCoverage[0]?.p;
              }
              return { instanceId, component, hostID, podID };
            }}
            samplingInterval={sampleInterval * 1000}
            title={showMetricName ? name : undefined}
            titlePostfix={showMetricUnit ? unit : undefined}
            labels={labels}
            data={data}
            predictionlabelMap={predictionlabelMap}
            annotations={annotations}
            labelsVisibility={labelsVisibility}
            highlightMap={rootCauseMap}
            onCreateAnnotation={this.Annotation}
            dateWindow={zoomAll && sampleInterval ? chartDateWindow : undefined}
            datasetStartTime={startTimestamp}
            datasetEndTime={endTimestamp}
            onDateWindowChange={this.handleDateWindowSync}
            isCustomData={!!withBaseline}
            showSeparateInstance
            userInfo={{ isReadUser: true }}
            onClickSeparateInstance={onClickSeparateInstance}
            projectName={projectName}
            owner={customerName}
            systemId={systemName}
            instanceDisplayNameMap={instanceDisplayNameMap}
          />
        );
      }
      return <ChartEmpty height={lineChartHeight} metric={metric} />;
    };
    const gridWidth =
      viewMode === VIEW_MODE.GRID ? Math.floor((width - 16 * gridColumnCount) / gridColumnCount) : width - 32;
    const curChartWidth =
      viewMode === VIEW_MODE.LIST && chartWidth ? chartWidth : gridWidth - DEFAULT_CONFIG.chartPadding * 2;
    const cardHeight = chartHeight + DEFAULT_CONFIG.chartPadding * 2;
    return (
      <div className="flex-row flex-wrap" style={{ width, minHeight: 240, margin: '8px 8px 16px 8px' }}>
        {metricList.map((metricKey, index) => {
          const metric = metricList[index];
          return metric && metricDatasetMap[metric] ? (
            <div
              key={metricKey}
              style={{
                width: gridWidth,
                minWidth: 300,
                position: 'relative',
                margin: 8,
              }}
            >
              {!!showChartCardMode && (
                <Card
                  style={{
                    width: '100%',
                    paddingTop: 5,
                    height: cardHeight,
                    overflow: 'hidden',
                  }}
                  hoverable
                >
                  {renderMetric(metric, curChartWidth, chartHeight)}
                </Card>
              )}
              {!showChartCardMode && (
                <div
                  style={{
                    width: '100%',
                    paddingTop: 5,
                    height: cardHeight,
                    overflow: 'hidden',
                  }}
                >
                  {renderMetric(metric, curChartWidth, chartHeight)}
                </div>
              )}
            </div>
          ) : (
            <ChartEmpty metric={metric} cardHeight={chartHeight} width={curChartWidth} />
          );
        })}
      </div>
    );
  };

  render() {
    const {
      metricList,
      isCustomData,
      customerName,
      isLoading,
      projectName,
      k8CoverageMap = {},
      instanceDisplayNameMap = {},
      metricAnomalyMap = {},
      activeMetricData,
      timeChange,
      startTimestamp,
      endTimestamp,
      hideDateRangeSelector,
    } = this.state;
    const { intl } = this.props;
    return (
      <Container fullHeight withGutter className="flex-col  metric-linecharts">
        {!hideDateRangeSelector && (
          <div className="flex-row" style={{ padding: 16 }}>
            <div style={{ marginRight: 24 }}>
              <div
                style={{
                  lineHeight: '20px',
                  fontSize: 14,
                }}
              >
                {intl.formatMessage(appFieldsMessages.date)}
              </div>
              <div className="flex-row">
                <Tooltip mouseEnterDelay={0.3} placement="top" title="Previous Day">
                  <Button size="small" icon={<LeftOutlined />} onClick={this.handlePreviousDayClick} type="default" />
                </Tooltip>
                <DatePicker
                  size="small"
                  allowClear={false}
                  style={{ width: 110, margin: '0 2px' }}
                  value={moment.utc(+startTimestamp)}
                  placeholder="Start"
                  onChange={this.handleStartTimeChange}
                  disabledDate={(current) => {
                    return current && current > moment.utc().add(1, 'days').endOf('day');
                  }}
                />
                <span style={{ margin: 'auto 4px', fontSize: 16 }}>~</span>
                <DatePicker
                  size="small"
                  allowClear={false}
                  style={{ width: 110, margin: '0 2px' }}
                  value={moment.utc(+endTimestamp)}
                  placeholder="End"
                  onChange={this.handleEndTimeChange}
                  disabledDate={(current) => {
                    return current && current > moment.utc().add(1, 'days').endOf('day');
                  }}
                />
                <Tooltip mouseEnterDelay={0.3} placement="top" title="Next Day">
                  <Button size="small" icon={<RightOutlined />} onClick={this.handleNextDayClick} type="default" />
                </Tooltip>
              </div>
            </div>
            <div style={{ marginRight: 24 }}>
              <div style={{ height: 20, fontSize: 14 }} />
              <div className="flex-row flex-center-align">
                <Popover
                  mouseEnterDelay={0.3}
                  placement="bottom"
                  visible={timeChange}
                  content={<div style={{ width: 240 }}>Please click this button to reload metric line chart data</div>}
                >
                  <Button
                    size="small"
                    disabled={isLoading}
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={this.loadData}
                  >
                    Reload
                  </Button>
                </Popover>
              </div>
            </div>
          </div>
        )}

        <div className="flex-row full-height overflow-y-auto">
          <div className="flex-grow flex-col" style={{ marginTop: -16, marginLeft: 8 }}>
            <Container
              fullHeight
              className={`flex-grow flex-row overflow-y-auto overflow-x-hidden ${isLoading ? ' loading' : ''}`}
            >
              <AutoSizer>
                {({ width, height }) => {
                  if (metricList && metricList.length && !isLoading) {
                    return this.renderListItem({
                      metricList,
                      isCustomData,
                      customerName,
                      width: width - 10,
                      height,
                    });
                  }
                }}
              </AutoSizer>
            </Container>
          </div>
        </div>
        {this.state.showSeparateInstanceChartModal && (
          <SeparateInstanceChartModal
            projectName={projectName}
            activeMetricData={activeMetricData}
            k8CoverageMap={k8CoverageMap}
            onClose={() =>
              this.setState({
                showSeparateInstanceChartModal: false,
                activeMetricData: undefined,
              })
            }
            instanceDisplayNameMap={instanceDisplayNameMap}
            metricAnomalyMap={metricAnomalyMap}
            showRCAFlag={false}
            incidentInfo={this.state.incidentInfo}
          />
        )}
      </Container>
    );
  }
}

function ChartEmpty(props) {
  return (
    <div
      className="full-height flex-col"
      style={{ height: props.cardHeight, width: props.width || '100%', padding: 16 }}
    >
      <div className="font-14 bold">{props.metric}</div>
      <div className="flex-grow flex-row flex-center-align flex-center-justify">
        <Empty />
      </div>
    </div>
  );
}

const EmbedMetricLineCharts = injectIntl(EmbedMetricLineChartsCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { dark } = parseLocation(location);
  if (dark) {
    state.app.currentTheme = 'dark';
  } else {
    state.app.currentTheme = 'light';
  }
  state.auth.userInfo = { isReadUser: true };
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return { location, isDark };
}, {})(EmbedMetricLineCharts);
