/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import { createLoadAction, updateLastActionInfo } from '../../app/actions';
import { ActionTypes } from '../actions';
import { getLoadStatusActions } from '../../utils';
import { postEventAction } from '../../apis';
import { appMessages } from '../../app/messages';

const saveEventActionLogic = createLogic({
  type: [ActionTypes.SAVE_EVENT_ACTION],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    const {
      projectName,
      projectNameList,
      neuronId,
      instanceName,
      eventType,
      needRecipients,
      rootCauseKey,
      startTime,
      endTime,
      systemId,
      customerName,
    } = params;
    postEventAction(credentials, params)
      .then(() => {
        dispatch(
          createLoadAction(
            ActionTypes.LOAD_EVENT_ACTION,
            {
              projectName,
              projectNameList,
              operation: 'getAction',
              neuronId,
              instanceName,
              eventType,
              needRecipients,
              rootCauseKey,
              startTime,
              endTime,
              systemId,
              customerName,
            },
            loader,
          ),
        );
      })
      .catch((err) => {
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default saveEventActionLogic;
