import React, { useReducer } from 'react';
import { Button, Form, Select, Spin } from 'antd';
import * as R from 'ramda';
import { Modal } from '../../../lib/fui/react';
import SelectMultiple from '../../../../components/custome/SelectMultiple';

const getZoneOptions = (zoneSet, systemId) => {
  let zoneOptions = R.filter((x) => x !== `zone_${systemId}`, zoneSet).map((item) => ({
    value: item,
    label: item,
  }));
  zoneOptions = [
    { value: '__all__', label: 'All zones' },
    { value: `zone_${systemId}`, label: 'No zones' },
    ...zoneOptions,
  ];
  return zoneOptions;
};
const WidgetSettingsModal = (props: Object) => {
  const { onClose, onApply, allProjects, settings, widget, isKubernetes, getSystemInfo, systemOptions } = props;
  const { params } = widget;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    projectNameList: params?.projectNameList || [],
    zoneList: params?.zoneList || [],
    systemId: props.systemId,
  });
  const systemInfo = getSystemInfo(state.systemId);
  const zoneSet = systemInfo?.systemInfo?.zoneSet || [];
  const zoneOptions = getZoneOptions(zoneSet, systemInfo?.id);
  let projects = R.filter((i) => i.systemId === state.systemId && i.ps !== 'Deleting', allProjects || []);

  const onlyKubernetes = ['AverageK8sHostCount', 'AveragePodCount'];
  const filterKubernetes = ['AverageInstanceCount'];
  if (isKubernetes) {
    onlyKubernetes.push('AverageContainerCount');
  } else {
    filterKubernetes.push('AverageContainerCount');
  }

  if (R.includes(widget?.id, onlyKubernetes)) {
    projects = R.filter((p) => R.includes('Kubernetes', p?.cloudType || ''), projects);
  } else if (R.includes(widget?.id, filterKubernetes)) {
    projects = R.filter((p) => !R.includes('Kubernetes', p?.cloudType || ''), projects);
  }

  const projectOptions = R.map((p) => ({ label: p.projectDisplayName, value: p.projectShortName }), projects);

  const { loading, projectNameList, zoneList } = state;
  const handleApply = () => {
    if (onApply) {
      onApply({ projectNameList, zoneList, systemId: state.systemId });
    }
  };
  // const hasError = !(settings?.projectNameList && projectNameList.length > 0);
  const hasError = false;

  return (
    <Modal title="Widget settings" visible footer={null} onCancel={() => onClose()}>
      <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-width">
        <Form layout="vertical" className="full-width">
          {/* system selector */}
          {systemOptions && systemOptions.length > 0 && (
            <Form.Item label="System">
              <Select
                options={systemOptions}
                value={state.systemId}
                onChange={(systemId) => setState({ systemId: systemId, zoneList: [], projectNameList: [] })}
              />
            </Form.Item>
          )}
          {settings?.projectNameList && (
            <Form.Item label="Projects">
              <SelectMultiple
                options={projectOptions}
                value={projectNameList}
                onChange={(projectNameList) => setState({ projectNameList })}
              />
            </Form.Item>
          )}
          {settings?.zoneList && (
            <Form.Item label="Zones">
              <SelectMultiple options={zoneOptions} value={zoneList} onChange={(zoneList) => setState({ zoneList })} />
            </Form.Item>
          )}
        </Form>
      </Spin>
      <div className="flex-row flex-end-justify">
        <Button size="small" disabled={hasError} type="primary" onClick={handleApply} loading={loading}>
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default WidgetSettingsModal;
