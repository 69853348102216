/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import * as R from 'ramda';
import { get } from 'lodash';

import { ActionTypes } from './actions';
import type { LogState, Action } from '../types';

const initialState: LogState = {
  incidentList: [],
  incidentListParams: {},
  instanceListData: {},
  incident: {},
  incidentParams: {},
  currentError: null,
  viewsState: {},

  instanceInfoList: [],
  instanceInfoTotal: 0,
  detailInfos: {},
  clusterInfoList: null,
  logEntryContextClusterInfoList: [],
  sequenceInfoList: null,
  anomalySequenceInfoList: null,
  keywordInfoList: null,
  logKeywordEntryList: null,
  logPatternEntryData: {},
};

const reducer = (state: LogState = initialState, action: Action): LogState => {
  if (action.type === 'SET_LOG_INFO') {
    const info = action.payload;
    if (R.isNil(info)) {
      return state;
    }
    return {
      ...state,
      ...info,
    };
  } else if (action.type === ActionTypes.SET_LOG_KEYWORD_STATS) {
    const { data } = action.payload;
    const keywordTotalCount = get(data, ['keywordInfoList'], []).length;
    const { detailInfos } = state;
    return { ...state, detailInfos: { ...detailInfos, keywordTotalCount, ...data } };
  } else if (action.type === ActionTypes.SET_LOG_STATS) {
    const { data } = action.payload;
    const { detailInfos } = state;
    return { ...state, detailInfos: { ...detailInfos, ...data } };
  } else if (action.type === ActionTypes.SET_LOG_INSTANCES_STATS) {
    const { data } = action.payload;
    const { instancesDetailInfos } = data || {};
    return { ...state, instancesDetailInfos };
  } else if (action.type === ActionTypes.SET_LOG_INSTANCEINFO_LIST) {
    const { data } = action.payload;
    const { instanceInfoList, instanceInfoTotal } = data;
    return { ...state, instanceInfoList, instanceInfoTotal };
  } else if (action.type === ActionTypes.SET_LOG_CLUSTERINFO_LIST) {
    const { data } = action.payload;
    const { clusterInfoList } = data;
    return { ...state, clusterInfoList };
  } else if (action.type === ActionTypes.SET_LOG_SEQUENCEINFO_LIST) {
    const { data } = action.payload;
    const { sequenceInfoList } = data;
    return { ...state, sequenceInfoList };
  } else if (action.type === ActionTypes.SET_LOG_ANOMALY_SEQUENCEINFO_LIST) {
    const { data } = action.payload;
    const { anomalySequenceInfoList } = data;
    return { ...state, anomalySequenceInfoList };
  } else if (action.type === ActionTypes.SET_LOG_KEYWORDINFO_LIST) {
    const { data } = action.payload;
    const { keywordInfoList } = data;
    return { ...state, keywordInfoList };
  } else if (action.type === ActionTypes.SET_LOG_KEYWORD_ENTRY_LIST) {
    const { data } = action.payload;
    const { logKeywordEntryList } = data;
    return { ...state, logKeywordEntryList };
  } else if (action.type === ActionTypes.SET_LOG_HOTCOLD_ENTRY_LIST) {
    const { data } = action.payload;
    const { logEntryList, logEntryListTotal, originSize, compressedSize, decreaseRatio } = data;
    return { ...state, logEntryList, logEntryListTotal, originSize, compressedSize, decreaseRatio };
  } else if (action.type === ActionTypes.SET_LOG_IMPORTANT_ENTRY_LIST) {
    const { data } = action.payload;
    const { logImportantEntryList, logImportantEntryTotal } = data;
    return { ...state, logImportantEntryList, logImportantEntryTotal };
  } else if (action.type === ActionTypes.SET_LOG_ENTRY_CONTEXT_LIST) {
    const { data } = action.payload;
    const {
      logEntryContextList,
      logEntryContextListTotal,
      logEntryContextHighlightPages,
      logEntryContextResetPage,
      logEntryContextClusterInfoList,
    } = data;
    return {
      ...state,
      logEntryContextList,
      logEntryContextListTotal,
      logEntryContextHighlightPages,
      logEntryContextResetPage,
      logEntryContextClusterInfoList,
    };
  } else if (action.type === ActionTypes.SET_LOG_ENTRY_CAUSAL_LIST) {
    const { data } = action.payload;
    const { logEntryCausalList, incidentCausalProperty } = data;
    return { ...state, logEntryCausalList, incidentCausalProperty };
  } else if (action.type === ActionTypes.SET_LOG_PATTERN_ENTRY_LIST) {
    const { data } = action.payload;
    return { ...state, logPatternEntryData: data };
  } else if (action.type === ActionTypes.SET_LOG_SEQ_ENTRY_LIST) {
    const { data } = action.payload;
    const { logSeqEntryList, logSeqEntryTotal } = data;
    return { ...state, logSeqEntryList, logSeqEntryTotal };
  } else if (action.type === 'SELECT_LOG_PATTERN') {
    let { viewsState } = state;
    const { view, patternId } = action.payload;
    if (view) {
      // Set the current pattern id, and reset the event list.
      viewsState = {
        ...viewsState,
        [view]: {
          currentPatternId: patternId,
          currentEventList: [],
        },
      };
      return { ...state, viewsState };
    }
    return state;
  } else if (action.type === 'SELECT_LOG_PATTERN_SEQUENCE') {
    let { viewsState } = state;
    const { view, sequenceId } = action.payload;
    if (Boolean(view) && Boolean(sequenceId)) {
      viewsState = {
        ...viewsState,
        [view]: {
          currentSequenceId: sequenceId,
          currentEventList: [],
        },
      };
      return { ...state, viewsState };
    }
    return state;
  } else if (action.type === 'UPDATE_LOG_PATTERN_SEQUENCE_NAME') {
    const { view, patternId, patternSequenceName } = action.payload;
    let changed = false;
    const { incident } = state;
    const viewData = incident[view];
    let sequences = viewData.sequences || [];

    sequences = R.map((p) => {
      if (p.id === patternId && p.isSequence) {
        changed = true;
        return { ...p, name: patternSequenceName };
      }
      return p;
    }, sequences);

    if (changed) {
      return {
        ...state,
        incident: { ...incident, [view]: { ...viewData, sequences } },
      };
    }
  } else if (action.type === 'UPDATE_LOG_PATTERN_NAME_INLIST') {
    const { patternId, patternName } = action.payload;
    const clusterInfoList = R.map(
      (c) => (String(c.nid) === String(patternId) ? { ...c, name: patternName, patternName } : c),
      get(state, ['detailInfos', 'clusterInfoList'], []),
    );

    return {
      ...state,
      detailInfos: {
        ...(state.detailInfos || {}),
        clusterInfoList,
      },
    };
  } else if (action.type === 'UPDATE_LOG_PATTERN_NAME') {
    const { view, patternId, patternName } = action.payload;
    const nid = parseInt(patternId, 10);
    let changed = false;

    const { incident } = state;
    const viewData = incident[view];

    if (view !== 'seq') {
      let patterns = viewData.patterns || [];

      patterns = R.map((p) => {
        if (p.nid === nid) {
          changed = true;
          return { ...p, name: patternName, patternName };
        }
        return p;
      }, patterns);

      if (changed) {
        return {
          ...state,
          incident: { ...incident, [view]: { ...viewData, patterns } },
        };
      }
    } else {
      let sequences = viewData.sequences || [];

      sequences = R.map((p) => {
        if (p.id === nid) {
          changed = true;
          return { ...p, name: patternName, patternName };
        }
        return p;
      }, sequences);

      if (changed) {
        return {
          ...state,
          incident: { ...incident, [view]: { ...viewData, sequences } },
        };
      }
    }
    return state;
  } else if (action.type === 'SET_LOG_PATTERN_FLAG') {
    const { params } = action.payload;
    const { nid, ignoreFlag } = params;
    const { eventListMap } = state;

    if (nid) {
      const newEventListMap = {};
      R.forEachObjIndexed((val, key) => {
        let { data } = val;
        if (data) {
          const { eventList } = data;
          if (eventList) {
            R.forEach((e) => {
              if (e.neuronId === nid) {
                e.ignoreFlag = ignoreFlag;
              }
            }, eventList);
          }
          data = { ...data, eventList };
          val = { ...val, data };
        }
        newEventListMap[key] = val;
      }, eventListMap || {});
      return {
        ...state,
        eventListMap: newEventListMap,
      };
    }

    return state;
  } else if (action.type === 'SET_LOG_GRADIENT_BARS') {
    const { mapTotal } = action.payload;
    return {
      ...state,
      mapTotal,
    };
  } else if (action.type === 'SET_LOG_GRADIENT_VIEW') {
    const { incident } = action.payload;
    const stateIncident = get(state, 'incident', {});

    return {
      ...state,
      incident: R.merge(stateIncident, incident),
    };
  } else if (action.type === ActionTypes.SET_LOG_ANOMALY_TIMELINES) {
    const { data } = action.payload;
    const { timelines } = data;
    return { ...state, timelines };
  } else if (action.type === ActionTypes.SET_EVENT_TRIAGE) {
    const { data } = action.payload;
    const { userAction } = data || {};
    return { ...state, userAction };
  } else if (action.type === ActionTypes.SET_EVENT_TRIAGE_REPORT_LIST) {
    const { data } = action.payload;
    const { triageReportList } = data || {};
    return { ...state, triageReportList };
  } else if (action.type === ActionTypes.SER_FEATURE_KEYWORD) {
    const { data } = action.payload;
    return { ...state, clusterFeatureKeywords: data };
  } else if (action.type === ActionTypes.SET_KEYWORD_FREQUENCY_ENTRY) {
    const { data } = action.payload;
    const { logKeywordFrequency } = data;
    return { ...state, logKeywordFrequency };
  } else if (action.type === ActionTypes.SET_LOG_DAY_ENTRY_LIST) {
    const { data } = action.payload;
    const { logEntryDayList, logEntryDaySampleMsg, logEntryDayListTotal } = data;
    return {
      ...state,
      logEntryDayList,
      logEntryDaySampleMsg,
      logEntryDayListTotal,
    };
  } else if (action.type === ActionTypes.SET_LOG_FEATURE_ENTRY_INFO) {
    const { data } = action.payload;
    const { logFeatureMap, logFeatureEvents } = data;
    return {
      ...state,
      logFeatureMap,
      logFeatureEvents,
    };
  }
  return { ...initialState, ...state };
};

export default reducer;
