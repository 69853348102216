/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postProjectHoliday = (credentials: Credentials, params: Object) => {
  const { projectName, holidayName, startTime, endTime, ...rest } = params;

  return fetchPost(getEndpoint('holiday'), {
    ...credentials,
    projectName,
    holidayName,
    startTime: startTime ? startTime.utc().format('MM-DD') : '',
    endTime: endTime ? endTime.utc().format('MM-DD') : '',
    ...rest,
  }).then((d) => {
    return d;
  });
};

export default postProjectHoliday;
