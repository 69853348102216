/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import { Scrollbars } from 'react-custom-scrollbars';

export default Scrollbars;
