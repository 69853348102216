/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { Container, CellMeasurerCache } from '../../../lib/fui/react';

type Props = {
  width: Number,
  height: Number,
  queryParams: Object,
  queryResult: Object,
};

class SummaryMetricReport extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({
      // fixedWidth: true,
      minHeight: 40,
      minWidth: 140,
    });

    this.gridOffsetY = 40;
    this.state = {};
    this.convertData(props.queryResult, {});
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps.queryResult, {});
    }
  }

  @autobind
  convertData(queryResult, params = {}) {
    const { anomalyProjectList, normalProjectList, inactiveProjectList } = queryResult;

    this.anomalyProjectList = anomalyProjectList;
    this.normalProjectList = normalProjectList;
    this.inactiveProjectList = inactiveProjectList;
  }

  @autobind
  getColor(event, ratio) {
    if (event.isLower) {
      return '#1678c2';
    }
    if (event.isHigher) {
      if (ratio > 10) {
        return '#DB2828';
      }
      if (ratio < 10) {
        return '#FBBD08';
      }
    }
    return '#1678c2';
  }

  render() {
    const { width, height } = this.props;
    const totalProjectNumber =
      this.anomalyProjectList.length + this.normalProjectList.length + this.inactiveProjectList.length;

    const metricList = (event, eventDetails, index) => {
      if (event.patternId === '-1') {
        return (
          <span
            key={index}
            style={{
              background: '#DB2828',
              padding: '4px 0.5em',
              marginRight: '0.5em',
              borderRadius: '2px',
              color: 'white',
            }}
          >
            <span>TV</span>
          </span>
        );
      } else if (event.patternId === '-2') {
        return (
          <span
            key={index}
            style={{
              background: '#DB2828',
              padding: '4px 0.5em',
              marginRight: '0.5em',
              borderRadius: '2px',
              color: 'white',
            }}
          >
            <span>DOWN</span>
          </span>
        );
      }
      return (
        <span
          key={index}
          style={{
            background: this.getColor(eventDetails, event.anomalyRatio),
            padding: '4px 0.5em',
            marginRight: '0.5em',
            borderRadius: '2px',
            color: 'white',
          }}
        >
          {eventDetails.metricName}
          {eventDetails.isHigher && <span>&#x25B3;</span>}
          {eventDetails.isLower && <span>&#x25BD;</span>}
        </span>
      );
    };
    const metricMapList = (event, eventDetailsList) => {
      const eventDetailsKeys = [];
      const eventDetailsListDuplicated = [];
      R.forEach((e) => {
        const key = `${e.metricName}-${e.isHigher}-${e.isLower}`;
        if (R.indexOf(key, eventDetailsKeys) < 0) {
          eventDetailsKeys.push(key);
          eventDetailsListDuplicated.push(e);
        }
      }, eventDetailsList);

      return eventDetailsListDuplicated.map((eventDetails, index) => {
        return metricList(event, eventDetails, index);
      });
    };

    return (
      <Container className="content flex-col" style={{ height, width }}>
        <Container style={{ paddingBottom: 8 }}>
          <div>
            {totalProjectNumber === 0 && <p>No result found.</p>}

            {totalProjectNumber !== 0 && (
              <div>
                {this.anomalyProjectList.map((projectStr, index) => {
                  const project = eval(`(${projectStr})`);
                  return (
                    <div key={index}>
                      <div id={project.name}>
                        <h3 style={{ marginBottom: '0.5em' }}>
                          <span>
                            {project.eventGroupList.length} of&nbsp;
                            <a
                              target="_blank"
                              href={`/metric/events?projectName=${project.name}@${
                                project.owner
                              }&instanceGroup=All&startTime=${project.startDate}&endTime=${project.endDate}`}
                            >
                              {project.totalEventNumber} Metric anomaly events
                            </a>
                          </span>
                        </h3>
                        <div>
                          {project.eventGroupList.map((grouping, index) => {
                            const event = grouping.eventDataList[0];
                            const eventDetailsList = event.eventDetailsList;
                            return (
                              <div key={index}>
                                <div>
                                  <h4 style={{ marginBottom: '0.5em' }}>
                                    {event.patternId === '-1' && (
                                      <span style={{ fontWeight: 'bold', lineHeight: 2 }}>Threshold violation</span>
                                    )}
                                    {event.patternId === '-2' ? (
                                      <span style={{ fontWeight: 'bold', lineHeight: 2 }}>Instance Down</span>
                                    ) : (
                                      <span style={{ fontWeight: 'bold', lineHeight: 2 }}>{event.patternName}</span>
                                    )}
                                    &nbsp;-&nbsp;<span>{grouping.name} Group</span>
                                  </h4>
                                  <div>{metricMapList(event, eventDetailsList)}</div>
                                </div>
                                <div style={{ lineHeight: 2 }}>
                                  Started {event.startTime}, duration {event.durationMinutes} minutes
                                </div>
                                <a
                                  target="_blank"
                                  href={`/metric/events?projectName=${project.name}@${project.owner}&instanceGroup=${
                                    grouping.name
                                  }&startTime=${project.startDate}&endTime=${project.endDate}`}
                                >
                                  View Metric Anomaly Details
                                </a>
                                <div>
                                  {eventDetailsList.length > 0 && (
                                    <table
                                      style={{
                                        width: 800,
                                        background: '#efefef',
                                        borderCollapse: 'collapse',
                                        margin: '0.5em 0',
                                      }}
                                    >
                                      <tbody>
                                        {eventDetailsList.map((eventDetails, index) => {
                                          return (
                                            <tr key={index} style={{ border: '1px solid white' }}>
                                              <td style={{ padding: '0.5em 1em', width: 200 }}>
                                                {eventDetails.hasHostName
                                                  ? `${eventDetails.hostName}(${eventDetails.instanceName})`
                                                  : `${eventDetails.instanceName}`}
                                              </td>
                                              <td style={{ padding: '0.5em 1em', width: 200 }}>
                                                {event.patternId === '-1' && <span>Threshold violation</span>}
                                                {event.patternId === '-2' ? (
                                                  <span>Instance Down</span>
                                                ) : (
                                                  <span>{eventDetails.metricName}</span>
                                                )}
                                              </td>
                                              <td style={{ padding: '0.5em 1em', width: 400 }}>
                                                {eventDetails.isHigher &&
                                                  `${eventDetails.percent}% higher than normal (${
                                                    eventDetails.currentValue
                                                  } vs ${eventDetails.normalValue})`}
                                                {eventDetails.isLower &&
                                                  `${eventDetails.percent}% lower than normal (${
                                                    eventDetails.currentValue
                                                  } vs ${eventDetails.normalValue})`}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <p>
                          {project.noEventGroupList.length > 0 && (
                            <div>
                              <div>
                                Last 24 hours, {project.eventGroupList.lenght} groups have no anomaly events, view
                                normal values:
                              </div>
                              <div>
                                {project.noEventGroupList.map((grouping, index) => {
                                  return (
                                    <a
                                      target="_blank"
                                      key={index}
                                      style={{ marginRight: '1em' }}
                                      href={`/query?t=f14e65eca3844ce29745c3d47fe90b00&projectName=${project.name}@${
                                        project.owner
                                      }&instanceGroup=${grouping}&startTime=${project.startDate}&endTime=${
                                        project.endDate
                                      }`}
                                    >
                                      {grouping}
                                    </a>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {totalProjectNumber !== 0 && (
              <div>
                {this.normalProjectList.map((projectStr, index) => {
                  const project = eval(`(${projectStr})`);
                  return (
                    <div key={index}>
                      <hr />
                      <h3 id={`${project.name}`} style={{ marginBottom: '0.5em' }}>
                        last 24 hours, all groups have no anomaly events.
                      </h3>
                      <div>
                        <a target="_blank">View normal metric values:</a>
                        <div>
                          {project.noEventGroupList.map((grouping, index) => {
                            return (
                              <a
                                target="_blank"
                                key={index}
                                style={{ marginRight: '1em' }}
                                href={`/query?t=f14e65eca3844ce29745c3d47fe90b00&projectName=${project.name}@${
                                  project.owner
                                }&instanceGroup=${grouping}&startTime=${project.startDate}&endTime=${project.endDate}`}
                              >
                                {grouping}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <p />
          </div>
        </Container>
      </Container>
    );
  }
}

export default SummaryMetricReport;
