/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/
/* eslint-disable no-console */
import { get, isArray } from 'lodash';

const removeNoLogEntry = entryList => {
  let ret = entryList;
  if (entryList && isArray(entryList) && entryList.length === 1) {
    const rawData = get(entryList[0], ['rawData'], '');
    if (rawData.indexOf('Sorry, no log entries') === 0 && rawData.indexOf('support@insightfinder.com') > 0) {
      ret = [];
    }
  }

  return ret;
};

export default removeNoLogEntry;
