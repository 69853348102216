import React from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { get, isInteger, toInteger } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'react-router-redux';
import VLink from 'valuelink';
import { Button, message, notification, Pagination } from 'antd';

import { State } from '../../../../common/types';
import { Table, Column, AutoSizer, Container, Select, Input } from '../../../../lib/fui/react';
import { loadProjectMetricSettings, saveProjectMetricSettings } from '../../../../common/settings/actions';
import { buildLocation, parseLocation } from '../../../../common/utils';
import { AscendingComparer } from '../../../../lib/fui/utils';

import { appFieldsMessages, appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

type Props = {
  push: Function,
  intl: Object,
  location: Object,
  projectName: String,
  currentProject: Object,
  instanceMetricList: Array<Object>,
  metricListCount: Number,
  possibleMetricType: Array<String>,
  excludedMetrics: Array<String>,
  currentLoadingComponents: Object,
  data: Object,
  // eslint-disable-next-line
  loadProjectMetricSettings: Function,
  saveProjectMetricSettings: Function,
};

class CapacityPlanningSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.submitLoadingKey = 'settings_metric_submit';
    this.instanceLoader = 'settings_metric_instance_loader';

    // Make a full copy of the data to avoid side affect with other components.
    this.localMetrics = [];
    const checked = this.localMetrics.length > 0 && !R.find((m) => !m.isIgnore, this.localMetrics);
    const apmChecked = this.localMetrics.length > 0 && !R.find((m) => !m.isAPM, this.localMetrics);

    this.state = {
      metricFilter: '',

      isIgnoreAll: checked,
      isApmAll: apmChecked,
    };

    this.inputCellRender = ({ dataKey, rowData, cellData }) => (
      <input className="fui input" value={cellData || ''} onChange={this.handleInputChanged(rowData, dataKey)} />
    );
    this.checkboxCellRender = ({ dataKey, rowData, cellData }) => (
      <input
        className="fui input"
        type="checkbox"
        checked={cellData || false}
        onChange={this.handleInputChanged(rowData, dataKey)}
      />
    );
    this.modifiedCellRender = ({ cellData }) => <p>{cellData ? 'Yes' : ''}</p>;

    this.instanceToApp = {};
    this.instanceListOption = [];

    this.metricTypeList = [];
    this.trigeringModeList = [{ label: 'Higher than normal', value: 1 }, { label: 'Lower than normal', value: 2 }];
    this.metricPriorityOptions = [
      { label: 'Low', value: 1 },
      { label: 'Medium Low', value: 2 },
      { label: 'Medium', value: 3 },
      { label: 'Medium High', value: 4 },
      { label: 'High', value: 5 },
    ];
  }

  componentDidMount() {
    this.parseData(this.props);
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { instanceMetricList, possibleMetricType, excludedMetrics } = nextProps;
    const nextParams = parseLocation(nextProps.location);
    const params = parseLocation(this.props.location);
    const { intl } = this.props;

    if (nextProps.data !== this.props.data) {
      this.parseData(nextProps);
    }

    let changeState = false;
    if (
      nextParams.start !== params.start ||
      nextParams.limit !== params.limit ||
      nextParams.instanceId !== params.instanceId ||
      nextParams.metricFilter !== params.metricFilter
    ) {
      this.reloadData(nextProps);
    } else if (
      nextProps.instanceMetricList !== this.props.instanceMetricList ||
      nextProps.possibleMetricType !== this.props.possibleMetricType
    ) {
      this.localMetrics = R.clone(instanceMetricList);
      this.localMetrics = R.sortWith([R.ascend(R.prop('smetric'))], this.localMetrics);
      this.metricTypeList = R.map((item) => ({ value: item, label: item }), possibleMetricType);
      changeState = true;
    } else if (nextProps.excludedMetrics !== this.props.excludedMetrics) {
      if (excludedMetrics && excludedMetrics.length > 0) {
        notification.info({
          message: null,
          description: (
            <div className="flex-row" style={{ maxHeight: 120, overflowY: 'auto' }}>
              {intl.formatMessage(settingsMessages.infoProjectSettingSavedWithExclusion, {
                excludedMetrics: R.join(
                  ', ',
                  R.uniq(
                    R.map(
                      (item) => `${item.metricName} (${this.instanceToApp[item.instanceName] || item.instanceName})`,
                      excludedMetrics,
                    ),
                  ),
                ),
              })}
            </div>
          ),
          duration: 0,
        });
      } else {
        message.success(intl.formatMessage(settingsMessages.infoProjectSettingSaved));
      }
    }

    // If metrics changes, reset the changed state, and get an new clone.
    if (changeState) {
      const checked = this.localMetrics.length > 0 && !R.find((m) => !m.isIgnore, this.localMetrics);
      const apmChecked = this.localMetrics.length > 0 && !R.find((m) => !m.isAPM, this.localMetrics);
      this.setState({ isIgnoreAll: checked, isApmAll: apmChecked });
    }
  }

  @autobind
  reloadData(props) {
    const { loadProjectMetricSettings, currentProject } = props;
    const { owner } = currentProject;
    const { projectName } = props;
    const params = parseLocation(props.location);
    const { start, limit, instanceId, metricFilter } = params;
    if (projectName && start && limit) {
      loadProjectMetricSettings(
        { viewCategory: 'capacitySettings', projectName, owner, instanceId, start, limit, metricFilter },
        { [this.instanceLoader]: true },
      );
    }
  }

  @autobind
  parseData(props) {
    const instanceList = get(props, ['data', 'instanceList'], []);
    this.instanceToApp = get(props, ['data', 'instanceToApp'], {});
    const appList = R.sort(
      AscendingComparer,
      R.uniq(R.map((i) => this.instanceToApp[i.instanceId] || i.instanceId, instanceList)),
    );
    this.instanceListOption = R.map((i) => ({ label: i, value: i }), appList);
  }

  @autobind
  handleSaveClick() {
    const { projectName, instanceMetricList, currentProject } = this.props;
    const { saveProjectMetricSettings } = this.props;
    const { owner } = currentProject;
    const { location } = this.props;
    const params = parseLocation(location);
    const { instanceId, start, limit, metricFilter } = params;

    const diff = R.difference(this.localMetrics, instanceMetricList);
    const metricList = R.map(
      (metric) => ({
        ...metric,
        isIgnore: undefined,
        triggeringMode: undefined,
        threshold: undefined,
        capacitySetting: JSON.stringify({
          isIgnore: metric.isIgnore,
          triggeringMode: metric.triggeringMode,
          threshold: metric.threshold !== '' ? metric.threshold : 0,
          modified: metric.cpSettingModified,
        }),
      }),
      diff,
    );
    if (projectName && metricList) {
      saveProjectMetricSettings({
        viewCategory: 'capacitySettings',
        projectName,
        owner,
        instanceId,
        metricList,
        start,
        limit,
        metricFilter,
      });
    }
  }

  @autobind
  handleInputChanged(rowData, dataKey) {
    return (e) => {
      const target = e.target;
      const newVal = target.type === 'checkbox' ? target.checked : target.value || '';

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleIsIgnoreAllChecked(e) {
    const checked = e.target.checked;

    R.forEach((m) => {
      m.isIgnore = checked;
    }, this.localMetrics);
    this.setState({ isIgnoreAll: checked });
  }

  @autobind
  checkAllHeaderRender() {
    const { isIgnoreAll } = this.state;
    return (
      <div>
        <span>isIgnore</span>
        <input className="fui input" type="checkbox" checked={isIgnoreAll} onChange={this.handleIsIgnoreAllChecked} />
      </div>
    );
  }

  @autobind
  handleSelectionTypeChange(rowData, dataKey) {
    return (e) => {
      const newVal = e ? e.value : null;

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  renderMetricType({ rowData, dataKey, cellData }) {
    return (
      <Select
        withPortal
        autosize
        clearable
        options={this.metricTypeList}
        value={cellData || ''}
        onChange={this.handleSelectionTypeChange(rowData, dataKey)}
      />
    );
  }
  @autobind
  renderTrigeringMode({ rowData, dataKey, cellData }) {
    return (
      <Select
        withPortal
        autosize
        options={this.trigeringModeList}
        value={cellData}
        onChange={this.handleSelectionTypeChange(rowData, dataKey)}
      />
    );
  }

  @autobind
  renderSpecialInterval({ rowData, dataKey, cellData }) {
    return (
      <input
        className="fui input"
        type="number"
        value={cellData}
        onChange={this.handleInputChanged(rowData, dataKey)}
      />
    );
  }

  @autobind
  metricPriorityRender({ dataKey, rowData, cellData }) {
    return (
      <Select
        withPortal
        autosize
        clearable
        options={this.metricPriorityOptions}
        value={cellData || ''}
        onChange={this.handleSelectionChange(rowData, dataKey)}
      />
    );
  }

  @autobind
  handleSelectionChange(rowData, dataKey) {
    return (e) => {
      const newVal = e ? e.value : 0;

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }
  @autobind
  renderMetricName({ rowData }) {
    const { smetric, unit } = rowData;

    return (
      <div className="full-width flex-row flex-min-width flex-center-align">
        <span className="hidden-line-with-ellipsis">{unit ? `${smetric} (${unit})` : smetric}</span>
      </div>
    );
  }

  @autobind
  handleInstanceChange(val) {
    const { push, location } = this.props;
    const params = parseLocation(location);
    const { value: instanceId } = val || {};
    push(buildLocation(location.pathname, {}, { ...params, instanceId, start: 0 }));
  }

  @autobind
  handleInstanceResetClick() {
    this.reloadData(this.props);
  }

  @autobind
  handlePaginationChange(page, pageSize) {
    const { location, push } = this.props;
    const params = parseLocation(location);
    const { limit } = params;
    const newStart = limit * (page - 1);

    push(buildLocation(location.pathname, {}, { ...params, start: newStart }));
  }
  @autobind
  handlePaginationSizeChange(page, pageSize) {
    const { location, push } = this.props;
    const params = parseLocation(location);

    push(buildLocation(location.pathname, {}, { ...params, start: 0, limit: pageSize }));
  }

  @autobind
  iconSearchClick(event) {
    event.stopPropagation();
    event.preventDefault();

    const { metricFilter } = this.state;
    const { push, location } = this.props;
    const params = parseLocation(location);
    push(buildLocation(location.pathname, {}, { ...params, metricFilter }));
  }

  render() {
    const { intl, location, instanceMetricList, metricListCount } = this.props;
    const params = parseLocation(location);
    const hasData = this.localMetrics.length > 0;
    const metricFilterLink = VLink.state(this, 'metricFilter');

    const { instanceId } = params;
    let { start, limit } = params;
    let page = 1;
    if (isInteger(metricListCount) && start && limit) {
      start = Number(start);
      limit = Number(limit);
      if (start / limit >= 1) {
        page = toInteger(start / limit) + 1;
      }
    }

    const isInstance = Boolean(instanceId);
    const diff = R.symmetricDifference(this.localMetrics, instanceMetricList);

    const hasError = diff.length === 0;
    const isSubmitting = get(this.props.currentLoadingComponents, this.submitLoadingKey, false);
    const isLoading = get(this.props.currentLoadingComponents, this.instanceLoader, false);

    return (
      <Container fullHeight className="overflow-y-auto overflow-x-hidden">
        <form
          className={`ui ${hasError ? 'error' : ''} form full-height flex-col`}
          style={{ fontSize: 12, width: '100%' }}
        >
          <Container className="field flex-row" style={{ marginBottom: 4 }}>
            <label style={{ margin: 'auto 1em auto 0' }}>{intl.formatMessage(appFieldsMessages.component)}:</label>
            <Select
              name="application"
              autosize={false}
              clearable
              style={{ width: 200 }}
              options={this.instanceListOption}
              value={instanceId}
              onChange={this.handleInstanceChange}
            />
            {isInstance && (
              <div
                className="ui grey button"
                onClick={this.handleInstanceResetClick}
                style={{ marginLeft: '1em', display: 'none' }}
              >
                {intl.formatMessage(appButtonsMessages.reset)}
              </div>
            )}
            <div className="flex-grow" />
          </Container>
          <Container className="flex-row" style={{ padding: '0 0 5px 0' }}>
            <label style={{ margin: 'auto 1em auto 0' }}>{intl.formatMessage(appFieldsMessages.metric)}:</label>
            <div className="flex-row flex-center-align" style={{ width: 200 }}>
              <Input
                valueLink={metricFilterLink}
                icon="icon search"
                fullWidth
                iconSearch
                iconSearchClick={this.iconSearchClick}
              />
            </div>
            <div className="flex-grow" />
            <Pagination
              size="small"
              current={page}
              total={metricListCount}
              pageSize={limit || 10}
              onChange={this.handlePaginationChange}
              showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
              showSizeChanger
              pageSizeOptions={['10', '20', '40', '60', '100']}
              onShowSizeChange={this.handlePaginationSizeChange}
            />
          </Container>
          {!hasData && (
            <Container>
              <div
                className="ui mini warning message"
                style={{ paddingTop: 10, paddingBottom: 10, marginBottom: 5, display: 'block' }}
              >
                {intl.formatMessage(settingsMessages.noCapacityPlanningFound)}
              </div>
            </Container>
          )}
          <Container className={`flex-grow field${isLoading ? ' loading' : ''}${isSubmitting ? ' disabled' : ''}`}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={40}
                  rowCount={this.localMetrics.length}
                  rowGetter={({ index }) => this.localMetrics[index]}
                  ref={(c) => {
                    this.table = c;
                  }}
                >
                  <Column
                    width={240}
                    flexGrow={1}
                    label={intl.formatMessage(appFieldsMessages.metric)}
                    dataKey="smetric"
                    cellRenderer={this.renderMetricName}
                  />
                  <Column
                    width={140}
                    label={intl.formatMessage(settingsMessages.threshold)}
                    dataKey="threshold"
                    cellRenderer={this.inputCellRender}
                  />
                  <Column
                    width={180}
                    label={intl.formatMessage(settingsMessages.triggeringMode)}
                    dataKey="triggeringMode"
                    cellRenderer={this.renderTrigeringMode}
                  />
                  <Column
                    width={100}
                    label={intl.formatMessage(appFieldsMessages.ignore)}
                    dataKey="isIgnore"
                    className="text-center"
                    headerClassName="text-center"
                    headerRenderer={this.checkAllHeaderRender}
                    cellRenderer={this.checkboxCellRender}
                  />
                  {isInstance && (
                    <Column
                      width={80}
                      label={intl.formatMessage(settingsMessages.modified)}
                      dataKey="cpSettingModified"
                      className="text-center"
                      headerClassName="text-center"
                      cellRenderer={this.modifiedCellRender}
                    />
                  )}
                </Table>
              )}
            </AutoSizer>
          </Container>
          {hasData && (
            <Container className="field text-right">
              <Button type="primary" loading={isSubmitting} disabled={hasError} onClick={this.handleSaveClick}>
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </Container>
          )}
        </form>
      </Container>
    );
  }
}

const CapacityPlanningSetting = injectIntl(CapacityPlanningSettingCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { capacitySettings, excludedMetrics } = state.settings;
    const instanceMetricList = get(capacitySettings, ['metricList'], []);
    const metricListCount = get(capacitySettings, ['metricListCount'], 0);
    const possibleMetricType = get(capacitySettings, ['possibleMetricType'], []);
    return { location, instanceMetricList, metricListCount, possibleMetricType, excludedMetrics };
  },
  { push, loadProjectMetricSettings, saveProjectMetricSettings },
)(CapacityPlanningSetting);
