import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { CellMeasurerCache } from '../../../lib/fui/react';
import { updateLastActionInfo, createLoadAction } from '../../../common/app/actions';

// new
import GlobalAlertTable from './GlobalAlertTable';

type Props = {
  events: Array,
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refreshTime: Number,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  zoomStartTime: Number,
  // eslint-disable-next-line
  zoomEndTime: Number,
  // eslint-disable-next-line
  selectAnomalyInstance: String,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoadingDetectedIncident: Boolean,
  // eslint-disable-next-line
  reloadSystem: Number,
  // eslint-disable-next-line
  onReloadSystemIncidentTimelines: Function,
  // eslint-disable-next-line
  updateState: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createLoadAction: Function,

  // eslint-disable-next-line
  globalSystemDetectIncidentTimelines: Array<Object>,
  width: Number,
  height: Number,
  onRefreshData: Function,
  rankMap: Object,
  title: String,
  typeString: String,
  activeLineIconKey: String,
  changeActiveLineIconKey: Function,
};

class GlobalAlertCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {};
    this.patternCountMap = {};
    this.patternCountProjectMap = {};
  }

  componentDidMount() {
    // this.reloadDetectedIncidentData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    const { events = [], isLoadingDetectedIncident, systemInfo, width, onRefreshData, rankMap, height } = this.props;
    const { title, typeString, activeLineIconKey, changeActiveLineIconKey } = this.props;

    return (
      <>
        <div style={{ padding: '8px 0' }}>
          <span className="font-14 bold">{title}</span> - {typeString}
        </div>
        <GlobalAlertTable
          tabName="detectedAlerts"
          systemInfo={systemInfo}
          events={events}
          activeEvent={events}
          width={width - 2}
          height={height - 36}
          isLoading={isLoadingDetectedIncident}
          onRefreshData={onRefreshData}
          rankMap={rankMap}
          activeLineIconKey={activeLineIconKey}
          changeActiveLineIconKey={changeActiveLineIconKey}
        />
      </>
    );
  }
}

const GlobalAlert = injectIntl(GlobalAlertCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    const { globalSystemDetectIncidentTimelines } = state.dashboard;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,

      globalSystemDetectIncidentTimelines,
    };
  },
  { updateLastActionInfo, createLoadAction },
)(GlobalAlert);
