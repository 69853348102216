/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPut from './fetchPut';

const updateCausalGroup = (credentials: Credentials, params: Object) => {
  const { causalKey, ...rest } = params;
  return fetchPut(getEndpoint('causalgroup'), { ...credentials, ...rest, causalKey }, {}, false).then(() => {
    return {};
  });
};

export default updateCausalGroup;
