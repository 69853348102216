import React from 'react';
import { Button, Col, Popconfirm, Row } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

const OperationButtons = ({
  style,
  onPrev,
  onNext,
  onCancel,
  nextText = 'Next',
  nextIcon = () => <ArrowRightOutlined />,
  needConfirm,
  confirContent,
  hidePrev,
  disabled,
}) => {
  return (
    <div className="operation-button-group" style={style}>
      <Row align="middle">
        <Col flex={1}>
          <Button size="small" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          {!hidePrev && (
            <Button size="small" onClick={onPrev} style={{ marginRight: 10 }}>
              <ArrowLeftOutlined />
              Prev
            </Button>
          )}

          {needConfirm && (
            <Popconfirm
              placement="topRight"
              title={confirContent}
              onConfirm={onNext}
              okText="Yes"
              cancelText="No"
              disabled={disabled}
            >
              <Button size="small" type="primary" disabled={disabled}>
                {nextText}
                {nextIcon()}
              </Button>
            </Popconfirm>
          )}
          {!needConfirm && (
            <Button size="small" type="primary" onClick={onNext}>
              {nextText}
              {nextIcon()}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OperationButtons;
