import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Select, message, Spin, Alert, Button, Popconfirm } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPut from '../../../common/apis/fetchPut';
import getEndpoint from '../../../common/apis/getEndpoint';
// import { Defaults } from '../../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';

import AddActionModal from '../../metric/components/AddActionModal';

type Props = {
  systemId: String,
  actionProjectNameList: Array<String>,
  actionKeySet: Array<String>,
  actionIncidentPredictionLibKeys: Array<String>,
  systemProjectNameList: Array<String>,
  // eslint-disable-next-line
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class GlobalSystemKnowledgeBaseActionModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: undefined,
      isSubmitting: false,

      actionsMap: {},
      actionsList: [],

      savedActions: [],
      actionId: undefined,
      proxyServerId: undefined,

      showAddActionModal: false,
    };
    this.proxyServerOptions = [];
  }

  componentDidMount() {
    this.reloadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.actionProjectNameList !== this.props.actionProjectNameList ||
      prevProps.actionKeySet !== this.props.actionKeySet ||
      prevProps.actionIncidentPredictionLibKeys !== this.props.actionIncidentPredictionLibKeys
    ) {
      this.reloadData();
    }
  }

  @autobind
  reloadData(props) {
    const {
      intl,
      credentials,
      projectDisplayMap,
      actionProjectNameList,
      systemId,
      actionKeySet,
      actionIncidentPredictionLibKeys,
    } = this.props;
    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();

    const requests = [
      fetchGet(getEndpoint('projectactions'), {
        ...credentials,
        operation: 'getAction',
        projectNameList: JSON.stringify(actionProjectNameList),
      }),
      fetchGet(getEndpoint('actionproxyserver'), {
        ...credentials,
        systemName: systemId,
        customerName:
          actionIncidentPredictionLibKeys[0]?.key?.userName || actionIncidentPredictionLibKeys[0]?.userName || null,
      }),
    ];
    Promise.all(requests)
      .then((results) => {
        const { success, message: errMsg, projectActionMap } = results[0];
        if (!success) {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.proxyServerOptions = [];
          this.setState({ isLoading: false, errMsg, actionsMap: {}, actionsList: [], savedActions: [] });
        } else {
          const actionsMap = {};
          const actionsList = [];
          let actionIdList = [];
          R.forEachObjIndexed((actions, projectFullName) => {
            const [projectName, owner] = projectFullName.split('@');
            const projectNameReal = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
            const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
            R.forEach((action) => {
              actionsMap[action.actionId] = { ...action, projectDisplayName };
              actionsList.push({ ...action, projectDisplayName });
              actionIdList.push(action.actionId);
            }, actions);
          }, projectActionMap || []);
          actionIdList = R.uniq(actionIdList);

          // proxy
          const { actionProxyServers } = results[1] || {};

          this.proxyServerOptions = actionProxyServers || [];
          this.setState({
            isLoading: false,
            actionsMap,
            actionsList,
            savedActions: actionKeySet || [],
            // savedActions: [
            //   {
            //     actionId: '8823daf9e7ac333478734aec5110ac2615497e15',
            //     proxyServerId: 'b4521df2-890b-479b-8859-558eb4acbc41',
            //   },
            // ],
          });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.proxyServerOptions = [];
        this.setState({ isLoading: false, errMsg: String(err), actionsMap: {}, actionsList: [], savedActions: [] });
      });
  }

  @autobind
  handleSaveAction() {
    const { intl, credentials, onClose, actionIncidentPredictionLibKeys } = this.props;
    const { savedActions, actionId, proxyServerId } = this.state;

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPut(getEndpoint('predictionrule'), {
      ...credentials,
      operation: 'setAction',
      IncidentPredictionLibKey: JSON.stringify(actionIncidentPredictionLibKeys),
      actionIdKeySet: JSON.stringify(
        R.uniqWith(R.eqBy(R.prop('actionId')), [{ actionId, proxyServerId }, ...savedActions]),
      ),
    })
      .then((d) => {
        const { success, message: errMsg } = d;
        if (!success) {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmitting: false, errMsg });
        } else {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmitting: false });
          onClose(true);
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false, errMsg: String(err) });
      });
  }

  @autobind
  handleRemoveAction(actionId) {
    const { intl, credentials, onClose, actionIncidentPredictionLibKeys } = this.props;
    const { savedActions } = this.state;
    const newActions = R.filter((action) => action.actionId !== actionId, savedActions);

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchPut(getEndpoint('predictionrule'), {
      ...credentials,
      operation: 'setAction',
      IncidentPredictionLibKey: JSON.stringify(actionIncidentPredictionLibKeys),
      actionIdKeySet: JSON.stringify(newActions),
    })
      .then((d) => {
        const { success, message: errMsg } = d;
        if (!success) {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isLoading: false, errMsg });
        } else {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isLoading: false });
          onClose(true);
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, errMsg: String(err) });
      });
  }

  @autobind
  handleAddActionClick() {
    this.setState({ showAddActionModal: true });
  }

  render() {
    const { intl, onClose, systemProjectNameList } = this.props;
    const { isLoading, errMsg, isSubmitting, actionsMap, actionsList } = this.state;
    const { savedActions, actionId, proxyServerId } = this.state;

    const disabled = !actionId;
    return (
      <Modal
        title={intl.formatMessage(eventMessages.takeAction)}
        width={750}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSaveAction}
        okButtonProps={{ disabled, loading: isSubmitting }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Form.Item label={intl.formatMessage(eventActionMessages.savedAction)}>
              {savedActions.length === 0 && (
                <Alert
                  message={null}
                  description={intl.formatMessage(eventActionMessages.noSavedAction)}
                  type="info"
                  showIcon
                />
              )}
              {savedActions.length !== 0 && (
                <div className="event-list">
                  <div className="event-list-header" style={{ height: 30, width: '100%' }}>
                    <div className="header-column" style={{ width: 120, flex: 1 }}>
                      {intl.formatMessage(eventActionMessages.actionName)}
                    </div>
                    <div className="header-column" style={{ width: 120, flex: 1 }}>
                      {intl.formatMessage(eventActionMessages.actionServer)}
                    </div>
                    <div className="header-column" style={{ width: 100 }} />
                  </div>
                  <div className="event-list-grid" style={{ maxHeight: 100, overflowY: 'auto' }}>
                    {R.addIndex(R.map)((item, index) => {
                      const actionInfo = actionsMap[item.actionId];
                      const proxyInfo = R.find(
                        (proxy) => proxy.id === item.proxyServerId,
                        this.proxyServerOptions || [],
                      );
                      return (
                        <div
                          key={index}
                          className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                          style={{ minHeight: 40 }}
                        >
                          <div className="row-column" style={{ width: 120, flex: 1 }}>
                            {actionInfo
                              ? `${actionInfo.name} (${actionInfo.projectDisplayName || actionInfo.projectName})`
                              : item.actionId}
                          </div>
                          <div className="row-column" style={{ width: 120, flex: 1 }}>
                            {proxyInfo ? proxyInfo.serverName : item.proxyServerId}
                          </div>
                          <div className="row-column flex-end-justify" style={{ width: 100 }}>
                            <Popconfirm
                              placement="topRight"
                              title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
                              onConfirm={() => this.handleRemoveAction(item.actionId)}
                              onCancel={(event) => event.stopPropagation()}
                            >
                              <Button
                                size="small"
                                className="button-color-grey"
                                onClick={(event) => event.stopPropagation()}
                              >
                                <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                              </Button>
                            </Popconfirm>
                          </div>
                        </div>
                      );
                    }, savedActions)}
                  </div>
                </div>
              )}
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(eventActionMessages.actionName)}
              validateStatus={disabled ? 'error' : 'success'}
              help={!actionId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-row full-width">
                <Select
                  showSearch
                  size="small"
                  placeholder={intl.formatMessage(eventActionMessages.actionName)}
                  value={actionId}
                  onChange={(actionId) => {
                    this.setState({ actionId });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <Select.Option key={item.actionId}>{`${item.name} (${
                        item.projectDisplayName || item.projectName
                      })`}</Select.Option>
                    );
                  }, actionsList)}
                </Select>

                <Button type="primary" size="small" style={{ marginLeft: 8 }} onClick={this.handleAddActionClick}>
                  <PlusOutlined /> {intl.formatMessage(eventActionMessages.addAction)}
                </Button>
              </div>
            </Form.Item>
            <Form.Item label={intl.formatMessage(eventActionMessages.actionServer)}>
              <Select
                size="small"
                allowClear
                showSearch
                value={proxyServerId}
                onChange={(proxyServerId) => this.setState({ proxyServerId })}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.addIndex(R.map)((item, index) => {
                  return (
                    <Select.Option key={item.id}>
                      <div className="flex-row flex-center-align">
                        <span className="light-label bold">{intl.formatMessage(settingsMessages.serverName)}:</span>
                        <span style={{ margin: '0 8px 0 4px' }}>{item.serverName}</span>
                        <span className="light-label bold">{intl.formatMessage(settingsMessages.hostName)}:</span>
                        <span style={{ margin: '0 8px 0 4px' }}>{item.hostName}</span>
                      </div>
                    </Select.Option>
                  );
                }, this.proxyServerOptions || [])}
              </Select>
            </Form.Item>

            {errMsg && (
              <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
                <Alert message={errMsg} type="error" showIcon />
              </Form.Item>
            )}
          </Form>
        </Spin>

        {this.state.showAddActionModal && (
          <AddActionModal
            projectNameList={systemProjectNameList}
            onClose={(relaod) => {
              this.setState({ showAddActionModal: false }, () => {
                if (relaod) this.reloadData();
              });
            }}
          />
        )}
      </Modal>
    );
  }
}

const GlobalSystemKnowledgeBaseActionModal = injectIntl(GlobalSystemKnowledgeBaseActionModalCore);
export default connect(
  (state) => {
    const { credentials, userInfo } = state.auth;
    const { projectDisplayMap } = state.app;
    return { credentials, userInfo, projectDisplayMap };
  },
  {
    updateLastActionInfo,
  },
)(GlobalSystemKnowledgeBaseActionModal);
