/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';

const getLogKeywordFrequencyEntry = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, day, keyword } = params;
  const dayObj = moment.utc(day, Defaults.DateFormat).startOf('day');
  const requests = [];
  requests.push(
    fetchGet(getEndpoint('logfetchkeywordtimefrequency'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: dayObj.valueOf(),
      dayString: day,
      keyword,
    }),
  );

  return Promise.all(requests).then((results) => {
    const logKeywordFrequency = results[0] || {};
    return { logKeywordFrequency };
  });
};

export default getLogKeywordFrequencyEntry;
