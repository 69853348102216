/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, debounce } from 'lodash';
import { autobind } from 'core-decorators';
import $ from 'jquery';
import * as d3 from 'd3';

import { CausalParser } from '../../common/utils';
import fetchPost from '../../common/apis/fetchPost';
import getEndpoint from '../../common/apis/getEndpoint';
import fetchGet from '../../common/apis/fetchGet';
import getInstanceDisplayName from '../../common/utils/getInstanceDisplayName';

const d3tip = require('d3-tip');

class D3Tree extends React.PureComponent {
  constructor(props) {
    super(props);

    this.container = null;
    this.orientation = 'left-to-right';
    this.tip = null;
    this.tipNode = null;

    // tree graph options
    this.needArrow = true;
    this.markerOrientation = 'marker-end';
    this.loopStep = 2;
    this.defaultMaxLevel = 3;
    this.autoDisplayLevel = this.defaultMaxLevel;
    this.lineHeight = 42;
    this.nodeRadiusSize = 6;
    this.nodeId = 0;
    this.duration = 750;
    // default is 'left-to-right'
    this.diagonal = d3.svg.diagonal().projection((d) => {
      return [d.y, d.x];
    });
    this.nodePathExpand = {};
    this.autoDisplayNode = 10;
    this.leafNodeMap = {};

    this.relationElemInfoMap = {};
    this.relationInfoMap = {};
    this.instanceMapping = {};
    this.intraInstanceList = [];
    this.allRootNodes = [];
    this.nodeRelationMap = {};

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      hasRelation: false,
      // eslint-disable-next-line react/no-unused-state
      relationList: [],

      // eslint-disable-next-line react/no-unused-state
      rootnode: null,
    };

    this.orientations = {
      'right-to-left': (width, height) => ({
        size: [height, width],
        x(d) {
          return width - d.y;
        },
        y(d) {
          return d.x;
        },
      }),
      'left-to-right': (width, height) => ({
        size: [height, width],
        x(d) {
          return d.y;
        },
        y(d) {
          return d.x;
        },
      }),
      'top-to-bottom': (width, height) => ({
        size: [width, height],
        x(d) {
          return d.x;
        },
        y(d) {
          return d.y;
        },
      }),
      'bottom-to-top': (width, height) => ({
        size: [width, height],
        x(d) {
          return d.x;
        },
        y(d) {
          return height - d.y;
        },
      }),
    };

    this.renderChart = debounce(this.renderChartFunc, 600);
  }

  UNSAFE_componentWillMount() {
    this.clearChart();
  }

  @autobind
  getChartData(props) {
    return {
      treeData: {},
    };
  }

  @autobind
  createTreeData({ nodeRelationMap, parentAllNodeNameMap, nodeNames, parentNode, parentPath, level, hasInstance }) {
    const data = [];

    R.forEach((node) => {
      // allow append node
      const newParentAllNodeNameMap = R.clone(parentAllNodeNameMap);
      if (!R.has(node, newParentAllNodeNameMap)) newParentAllNodeNameMap[node] = 0;
      newParentAllNodeNameMap[node] += 1;

      let nodeInfo = {};
      let label = node;
      let hasIntra = false;
      if (hasInstance) {
        nodeInfo = this.relationElemInfoMap[node] || {};
        label = `${nodeInfo.postFix ? `[${nodeInfo.postFix}] ` : ''}${CausalParser.trimString(nodeInfo.eventType, 18)}`;
      } else {
        const appName =
          this.instanceMapping[node] && this.instanceMapping[node] !== node
            ? `${node} (${this.instanceMapping[node]})`
            : node;
        hasIntra = this.intraInstanceList.indexOf(node) !== -1;
        label = `${hasIntra ? '[*] ' : ''}${CausalParser.trimString(appName, 18)}`;
      }

      const path = parentPath ? `${parentPath}->${node}` : node;
      const nodeData = {
        hasInstance,
        node,
        nodeInfo,
        parentNode,
        path,
        name: label,
        hasIntra,
        newParentAllNodeNameMap,
        level,
      };

      // get next nodes
      const nodeRelation = get(nodeRelationMap, node, {});
      const childrenNodeNames =
        (this.orientation === 'right-to-left' ? nodeRelation.previousNodes : nodeRelation.nextNodes) || [];

      // get the node path expand info
      if (!R.has(path, this.nodePathExpand)) {
        this.nodePathExpand[path] = { total: childrenNodeNames.length, display: 0 };
      }
      const nodeNames = R.slice(
        this.nodePathExpand[path].display,
        this.nodePathExpand[path].display + this.autoDisplayNode,
        childrenNodeNames,
      );
      const needNextNode = this.nodePathExpand[path].total > this.nodePathExpand[path].display + this.autoDisplayNode;
      const needPreviousNode = this.nodePathExpand[path].display > 0;

      // if current node already join twice, then stop to build children
      if (newParentAllNodeNameMap[node] < this.loopStep && (childrenNodeNames.length > 0 || nodeRelation.hasChildren)) {
        if (level < this.autoDisplayLevel) {
          // has children and auto displayed
          nodeData.children = this.createTreeData({
            nodeRelationMap,
            parentAllNodeNameMap: newParentAllNodeNameMap,
            nodeNames,
            parentNode: node,
            parentPath: path,
            level: level + 1,
            hasInstance,
          });
          if (nodeData.children.length === 0) {
            nodeData.children = null;
            nodeData.hasChildren = Boolean(nodeRelation.hasChildren);
          }

          // build expand control node
          if (needNextNode && nodeData.children) {
            nodeData.children.push(this.getExpandNode({ path, direction: 'next' }));
          }
          if (needPreviousNode && nodeData.children) {
            nodeData.children.push(this.getExpandNode({ path, direction: 'previous' }));
          }
        } else {
          // has children but not displayed
          nodeData.hasChildren = true;
        }
      }
      data.push(nodeData);
    }, nodeNames);
    return data;
  }

  @autobind
  clearChart() {
    // clear grapth control cache
    this.nodePathExpand = {};

    if (this.tip) {
      this.tip.destroy();
    }

    if (this.container) {
      d3.select(this.container).select('svg').remove();
    }
  }

  @autobind
  renderChartFunc(props) {
    this.clearChart();

    if (!this.container) return;

    // Get tree data
    const { treeData } = this.getChartData(props);

    // create svg
    const margin = { top: 20, right: 120, bottom: 20, left: 120 };
    const zoom = d3.behavior.zoom().scaleExtent([1, 4]);
    const container = d3.select(this.container);
    const svg = container
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .call(
        zoom.on('zoom', () => {
          svg.attr(
            'transform',
            `translate(${zoom.translate()[0] + margin.left},${zoom.translate()[1] + margin.top})scale(${zoom.scale()})`,
          );
        }),
      )
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // build the svg size
    const viewerHeight = container.property('clientHeight') - margin.top - margin.bottom;
    const viewerWidth = container.property('clientWidth') - margin.right - margin.left;
    // build the orientation by orientation
    const orientation = this.orientations[this.orientation](viewerWidth, viewerHeight);
    this.diagonal = d3.svg.diagonal().projection((d) => {
      return [orientation.x(d), orientation.y(d)];
    });
    this.markerOrientation = this.orientation === 'right-to-left' ? 'marker-start' : 'marker-end';

    // create tree
    const tree = d3.layout
      .tree()
      .size(orientation.size)
      .separation((a, b) => {
        return 2;
      });

    // create tip
    this.tip = d3tip()
      .attr('class', 'd3-tip')
      .direction(this.orientation === 'right-to-left' ? 'w' : 'e')
      .offset([0, this.orientation === 'right-to-left' ? -10 : 10])
      .html(this.renderTip);
    const { tip } = this;
    svg.call(tip);

    // create arrow
    const arrowSvg =
      this.orientation === 'right-to-left'
        ? 'M 0,0 m 8,-5 L -4,0 L 8,5 L 8,0 Z'
        : 'M 0,0 m -8,-5 L 4,0 L -8,5 L -8,0 Z';
    const arrowViewBox = this.orientation === 'right-to-left' ? '0 -5 10 10' : '-10 -5 10 10';
    const defs = svg.append('defs');
    defs
      .append('marker')
      .attr('id', 'arrow')
      .attr('markerUnits', 'strokeWidth')
      .attr('viewBox', arrowViewBox)
      .attr('markerWidth', 4)
      .attr('markerHeight', 4)
      .attr('refX', 0)
      .attr('refY', 0)
      .attr('orient', 'auto')
      .attr('style', 'var(--black)')
      .append('path')
      .attr('d', arrowSvg);

    // ini root tree
    const root = treeData;
    root.x0 = container.property('clientHeight') / 2;
    root.y0 = 0;

    // update the tree
    this.updateTree(root, { viewerWidth, viewerHeight, root, svg, tip, tree });
  }

  @autobind
  getTreeLevelWidth(root) {
    const levelWidth = [1];
    const childCount = (level, n) => {
      const allChildren = n.children;
      if (allChildren) {
        if (levelWidth.length <= level + 1) levelWidth.push(0);

        levelWidth[level + 1] += allChildren.length;
        allChildren.forEach((d) => {
          childCount(level + 1, d);
        });
      }
    };
    childCount(0, root);
    return levelWidth;
  }

  @autobind
  updateTree(source, props) {
    const self = this;
    const { viewerWidth, viewerHeight, root, svg, tip } = props;
    let { tree } = props;

    // get global info
    const { relationInfoMap } = this;
    const startTs = moment.utc().valueOf();

    // Compute the new height, function counts total children of root node and sets tree height accordingly.
    const levelWidth = this.getTreeLevelWidth(root);
    props.viewerHeight = Math.max(d3.max(levelWidth) * this.lineHeight, viewerHeight);
    const orientation = this.orientations[this.orientation](viewerWidth, props.viewerHeight);
    tree = tree.size(orientation.size);

    // Compute the new tree layout.
    const nodes = tree.nodes(root).reverse();
    const links = tree.links(nodes);

    // Normalize for fixed-depth.
    nodes.forEach((d) => {
      d.y = d.depth * 180;
    });

    // Update the nodes…
    const node = svg.selectAll('g.node').data(nodes, (d) => {
      this.nodeId += 1;
      // eslint-disable-next-line no-return-assign
      return d.id || (d.id = this.nodeId);
    });

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = node
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => {
        return `translate(${source.y0},${source.x0})`;
      });
    nodeEnter
      .append('circle')
      .attr('r', 1e-6)
      .style('fill', (d) => {
        return d.isExpandNode ? 'black' : d.hasChildren || d.childrenBackup ? 'lightsteelblue' : '#fff';
      })
      .on('click', this.nodeCircleClick(props));
    nodeEnter
      .append('text')
      .attr('class', (d) => {
        return d.isExpandNode || d.hasIntra ? 'clickable' : '';
      })
      .attr('x', (d) => {
        return 0;
        // return d.children || d.childrenBackup ? -13 : 13;
      })
      .attr('dy', '20px')
      .attr('text-anchor', (d) => {
        return 'middle';
        // return d.children || d.childrenBackup ? 'end' : 'start';
      })
      .text((d) => {
        const { instanceStr } = getInstanceDisplayName(this?.props?.incidentMetaData?.instanceDisplayNameMap, d?.node);
        let nodeInfo = {};
        let label = d?.node;
        let hasIntra = false;
        if (d?.hasInstance) {
          nodeInfo = this.relationElemInfoMap[d?.node] || {};
          label = `${nodeInfo.postFix ? `[${nodeInfo.postFix}] ` : ''}${CausalParser.trimString(
            nodeInfo.eventType,
            18,
          )}`;
        } else {
          const appName =
            this.instanceMapping[d?.node] && this.instanceMapping[d?.node] !== d?.node
              ? `${instanceStr} (${this.instanceMapping[d?.node]})`
              : instanceStr;
          hasIntra = this.intraInstanceList.indexOf(d?.node) !== -1;
          label = `${hasIntra ? '[*] ' : ''}${CausalParser.trimString(appName, 18)}`;
        }
        return label || d.name;
      })
      .style('fill-opacity', 1e-6)
      .on('click', this.nodeClick(props))
      .on('mouseover', function (d) {
        // not show tip if is expand node
        if (d.isExpandNode) {
          return;
        }

        setTimeout(() => {
          tip.show({ operation: 'node', target: this, d }, this);
          self.tipNode = d;

          // add tip control, allow mouse into tip and hold the tip.
          $('div.d3-tip')
            .unbind('mouseenter mouseleave')
            .bind('mouseenter', () => {
              tip.hover = true;
              // console.log('tip mouseenter');
            })
            .bind('mouseleave', () => {
              tip.hover = false;
              tip.hide(d);
              // console.log('tip mouseleave');
            });
        }, 300);
      })
      .on('mouseout', (d) => {
        setTimeout(() => {
          if (!tip.hover) {
            self.tipNode = null;
            tip.hide(d);
          }
        }, 300);
      });

    // Transition nodes to their new position.
    const nodeUpdate = node
      .transition()
      .duration(this.duration)
      .attr('transform', (d) => {
        return `translate(${orientation.x(d)},${orientation.y(d)})`;
      });
    nodeUpdate
      .select('circle')
      .attr('r', this.nodeRadiusSize)
      .style('fill', (d) => {
        return d.isExpandNode ? 'black' : d.hasChildren || d.childrenBackup ? 'lightsteelblue' : '#fff';
      });
    nodeUpdate.select('text').style('fill-opacity', 1).style('fill', 'var(--text-color)');

    // Transition exiting nodes to the parent's new position.
    const nodeExit = node
      .exit()
      .transition()
      .duration(this.duration)
      .attr('transform', (d) => {
        return `translate(${source.y},${source.x})`;
      })
      .remove();
    nodeExit.select('circle').attr('r', 1e-6);
    nodeExit.select('text').style('fill-opacity', 1e-6);

    // Update the links…
    const link = svg.selectAll('g.link').data(links, (d) => {
      return d.target.id;
    });

    // Enter any new links at the parent's previous position.
    const linkEnter = link.enter().append('g').attr('class', 'link');
    const linkPath = linkEnter.append('path');
    linkPath
      .attr('d', (d) => {
        const o = { x: source.x0, y: source.y0 };
        return this.diagonal({ source: o, target: o });
      })
      .attr(this.markerOrientation, this.needArrow ? 'url(#arrow)' : 'none')
      .style('stroke-dasharray', (d) => {
        const { isExpandNode } = d.target;
        return isExpandNode ? '5,5' : 'none';
      })
      .style('stroke', (d) => {
        const { isExpandNode } = d.target;
        const relationKey =
          this.orientation === 'right-to-left'
            ? `${d.target.node}-${d.source.node}`
            : `${d.source.node}-${d.target.node}`;
        const relation = relationInfoMap[relationKey];
        return isExpandNode ? 'var(--black)' : get(relation, ['realtionColor'], 'var(--text-color-secondary)');
      })
      .style('stroke-width', (d) => {
        const { isExpandNode } = d.target;
        const relationKey =
          this.orientation === 'right-to-left'
            ? `${d.target.node}-${d.source.node}`
            : `${d.source.node}-${d.target.node}`;
        const relation = relationInfoMap[relationKey];
        return isExpandNode ? '1px' : `${get(relation, ['relationWidth'], 2)}px`;
      })
      .on('mouseover', function (d) {
        const { isExpandNode } = d.target;
        if (!isExpandNode) {
          const relationKey =
            this.orientation === 'right-to-left'
              ? `${d.target.node}-${d.source.node}`
              : `${d.source.node}-${d.target.node}`;
          const relation = relationInfoMap[relationKey];
          d3.select(this.parentNode)
            .select('path')
            .style({
              'stroke-width': `${get(relation, ['relationWidth'], 2) + 0.5}px`,
            });
        }
      })
      .on('mouseout', function (d) {
        const { isExpandNode } = d.target;
        if (!isExpandNode) {
          const relationKey =
            this.orientation === 'right-to-left'
              ? `${d.target.node}-${d.source.node}`
              : `${d.source.node}-${d.target.node}`;
          const relation = relationInfoMap[relationKey];
          d3.select(this.parentNode)
            .select('path')
            .style({
              'stroke-width': `${get(relation, ['relationWidth'], 2)}px`,
            });
        }
      })
      .on('click', (d) => {
        if (!d.target.isExpandNode) {
          this.pathClick(d);
        }
      });
    linkEnter
      .append('circle')
      .attr('r', 5)
      .attr('transform', (d) => {
        const x = (d.source.x + d.target.x) / 2;
        const y = (d.source.y + d.target.y) / 2;
        return `translate(${this.orientation === 'right-to-left' ? viewerWidth - y : y},${x})`;
      })
      .style('fill', () => 'lightsteelblue')
      .style('fill-opacity', 1e-6)
      .on('mouseover', function (d) {
        const { isExpandNode } = d.target;
        if (!isExpandNode) {
          const relationKey =
            this.orientation === 'right-to-left'
              ? `${d.target.node}-${d.source.node}`
              : `${d.source.node}-${d.target.node}`;
          const relation = relationInfoMap[relationKey];
          d3.select(this.parentNode)
            .select('path')
            .style({
              'stroke-width': `${get(relation, ['relationWidth'], 2) + 0.5}px`,
            });
        }
      })
      .on('mouseout', function (d) {
        const { isExpandNode } = d.target;
        if (!isExpandNode) {
          const relationKey =
            this.orientation === 'right-to-left'
              ? `${d.target.node}-${d.source.node}`
              : `${d.source.node}-${d.target.node}`;
          const relation = relationInfoMap[relationKey];
          d3.select(this.parentNode)
            .select('path')
            .style({
              'stroke-width': `${get(relation, ['relationWidth'], 2)}px`,
            });
        }
      })
      .on('click', (d) => {
        if (!d.target.isExpandNode) {
          this.pathClick(d);
        }
      });
    linkEnter
      .append('text')
      .attr('transform', (d) => {
        return `translate(${(d.source.y + d.target.y) / 2},${(d.source.x + d.target.x) / 2})`;
      })
      .attr('dy', '-8px')
      .attr('text-anchor', 'middle')
      .text((d) => {
        // const { source, target } = d;
        // const relationKey =
        //   this.orientation === 'right-to-left' ? `${target.node}-${source.node}` : `${source.node}-${target.node}`;
        // const ralation = relationInfoMap[relationKey];
        // if (false && ralation) {
        //   const { probability, count } = ralation || {};
        //   return `Max Prob:${(probability * 100).toFixed(1)}%,Max Count:${count}`;
        // }
        return '';
      })
      .style('fill-opacity', 1e-6);

    // Transition links to their new position.
    const linkUpdate = link.transition().duration(this.duration);
    linkUpdate.select('path').attr('d', (d) => {
      const { source, target } = d;
      return this.diagonal({
        source: { x: source.x, y: source.y + this.nodeRadiusSize },
        target: { x: target.x, y: target.y - this.nodeRadiusSize - 1 },
      });
    });
    linkUpdate
      .select('text')
      .attr('transform', (d) => {
        return `translate(${(d.source.y + d.target.y) / 2},${(d.source.x + d.target.x) / 2})`;
      })
      .style('fill-opacity', 1);
    linkUpdate
      .select('circle')
      .style('fill-opacity', 1)
      .attr('transform', (d) => {
        const x = (d.source.x + d.target.x) / 2;
        const y = (d.source.y + d.target.y) / 2;
        return `translate(${this.orientation === 'right-to-left' ? viewerWidth - y : y},${x})`;
      });

    // Transition exiting nodes to the parent's new position.
    const linkExit = link.exit().transition().duration(this.duration).remove();
    linkExit.select('path').attr('d', (d) => {
      const o = { x: source.x, y: source.y };
      return this.diagonal({ source: o, target: o });
    });
    linkExit.select('text').style('fill-opacity', 1e-6);
    linkExit.select('circle').style('fill-opacity', 1e-6);

    // Stash the old positions for transition.
    nodes.forEach((d) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
    console.debug(`Render tree duration: ${(moment.utc().valueOf() - startTs) / 1000} sec`);
  }

  @autobind
  renderTip({ operation, target, d }) {
    return '';
  }

  @autobind
  getExpandNode({ path, direction }) {
    return {
      isExpandNode: true,
      direction,
      node: `expandNode-${direction}`,
      name: `${direction === 'previous' ? 'Previous' : 'Next'} ${this.autoDisplayNode}`,
      path,
    };
  }

  @autobind
  mappingData(item) {
    const mappingKey = {
      av: 'avgAnomalyValue',
      as: 'avgAnomalyScore',
      af: 'avgAnomalyFrequency',
      anv: 'avgNormalValue',
      c: 'content',
      t: 'type',
      pan: 'patternName',
      n: 'nid',
      idx: 'index',
      pb: 'probability',
      idn: 'instanceDown',
      ov: 'overAllAvgValue',
      m: 'metricDirection',
      cid: 'containerId',
      p: 'percentage',
      ia: 'isAlert',
      r: 'rawData',
      pn: 'projectName',
      cn: 'customerName',
      iip: 'instanceNameInThisProject',
    };
    const newItem = {};
    R.forEachObjIndexed((val, key) => {
      newItem[mappingKey[key]] = val;
    }, item || {});
    return newItem;
  }

  @autobind
  async buildLeafNodeMap({
    needPrefetchNodes,
    credentials,
    causalKey,
    customerName,
    startTimestamp,
    endTimestamp,
    operation,
    causalType,
    timeThreshold,
    joinDependency,
    graphView,
    resultStartstamp,
    resultEndstamp,
  }) {
    const metricUnit = await fetchGet(getEndpoint('metric-unit', 2), {});
    const resultNext = await fetchPost(getEndpoint('relationprocess', 2), {
      ...credentials,
      interInstances: JSON.stringify(needPrefetchNodes),
      causalKey,
      customerName,
      startTime: startTimestamp,
      endTime: endTimestamp,
      operation,
      causalType,
      timeThreshold,
      joinDependency,
      bySrc: graphView !== 'target',
      ...(resultStartstamp && resultEndstamp
        ? { resultStartTime: resultStartstamp, resultEndTime: resultEndstamp }
        : {}),
    });
    R.forEach((item) => {
      item.from = R.map((_item) => this.mappingData(_item), item.from || []);
      item.to = R.map((_item) => this.mappingData(_item), item.to || []);
    }, resultNext?.data || []);
    const incidentNext = CausalParser.parseIncidentRelations({
      operation,
      ...(resultNext || {}),
      ...(metricUnit || {}),
    });
    let hasChildrenNodes = [];
    R.forEach((relation) => {
      if (needPrefetchNodes.indexOf(relation.elem1) !== -1) hasChildrenNodes.push(relation.elem1);
    }, incidentNext.relation || []);
    hasChildrenNodes = R.uniq(hasChildrenNodes);
    R.forEach((node) => {
      if (hasChildrenNodes.indexOf(node) !== -1) {
        this.leafNodeMap[node] = false;
      } else {
        this.leafNodeMap[node] = true;

        // update hasChildren in nodeRelationMap if node is leaf
        if (R.has(node, this.nodeRelationMap)) {
          this.nodeRelationMap[node].hasChildren = false;
        }
      }
    }, needPrefetchNodes);
  }

  @autobind
  findNodeByPath(node, findPath) {
    const { isExpandNode, path, children } = node;
    if (isExpandNode) {
      return null;
    }
    if (path === findPath) {
      return node;
    } else if (findPath.indexOf(path) === 0) {
      let result = null;
      R.forEach((item) => {
        const data = this.findNodeByPath(item, findPath);
        if (data) {
          result = data;
        }
      }, children || []);
      return result;
    }
    return null;
  }

  @autobind
  nodeCircleClick(props) {
    return (d) => {
      const { isExpandNode, node, path, children, childrenBackup, hasChildren, newParentAllNodeNameMap } = d || {};
      // Toggle children on click.
      if (isExpandNode) {
        this.nodeClick(props)(d);
      } else {
        if (children) {
          d.childrenBackup = children;
          d.children = null;
        } else if (childrenBackup) {
          d.children = childrenBackup;
          d.childrenBackup = null;
        } else if (!childrenBackup && hasChildren) {
          // Dynamically create new node children
          // update tree root. Use current node parentAllNodeNameMap to build
          // get next nodes
          const nodeRelation = get(this.nodeRelationMap, node);
          const childrenNodeNames =
            (this.orientation === 'right-to-left' ? nodeRelation.previousNodes : nodeRelation.nextNodes) || [];
          // create new children
          const newChildren = this.createTreeData({
            nodeRelationMap: this.nodeRelationMap,
            parentAllNodeNameMap: newParentAllNodeNameMap,
            nodeNames: childrenNodeNames,
            parentNode: node,
            parentPath: path,
            level: this.autoDisplayLevel,
          });
          if (newChildren.length > 0) {
            d.children = newChildren;
          }
          d.hasChildren = false;
        }
        this.updateTree(d, props);
      }
    };
  }

  @autobind
  nodeClick(props) {
    return (d) => {};
  }

  @autobind
  pathClick(d) {}

  @autobind
  onChangeRootnode(event) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ rootnode: event.target.value }, () => {
      this.renderChart(this.props);
    });
  }
}

export default D3Tree;
