/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';
import type { Deps } from '../../types';
import { postProjectExportModel } from '../../apis';
import { apiEpicErrorHandle } from '../../errors';
import { showAppAlert, updateLastActionInfo } from '../../app/actions';
import { appMessages } from '../../app/messages';

import { getLoaderEpicAction } from '../../utils';

const exportProjectModelEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('EXPORT_PROJECT_MODEL').concatMap((action) => {
    const state = getState();
    const { credentials } = state.auth;
    const { params, loader } = action.payload;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(postProjectExportModel(credentials, params))
      .concatMap((d) => {
        if (!d.success) {
          return Observable.of(showAppAlert('error', appMessages.errorsMessage, { message: d.message }));
        }
        return Observable.empty();
      })
      .catch((err) => apiEpicErrorHandle(err));
    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$, hideLoader);
  });

export default exportProjectModelEpic;
