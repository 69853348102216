/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get, isEqual, isInteger, isNumber, toInteger, toString } from 'lodash';
import { autobind } from 'core-decorators';
import VLink from 'valuelink';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { QuestionCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Tabs, Alert, Popover, Tooltip, message, InputNumber } from 'antd';

import { Container, Input, Select } from '../../../../lib/fui/react';
import { showAppAlert } from '../../../../common/app/actions';

import { appFieldsMessages, appMenusMessages, appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { DashboardMessages } from '../../../../common/dashboard/messages';

import { AnomalyThresholdSensitivity } from '../../../app/components/Selectors';

type Props = {
  intl: Object,
  projectName: String,
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  saveProjectSettings: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  tabStyle: Object,
  entry: String,
  handleMetricSensitivity: Function,
  refs: Function,
  updateStatus: String,
};

class AlertSensitivitySettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.pvalueStateKey = 'pValue';
    this.cvalueStateKey = 'cValue';
    this.submitLoadingKey = 'settings_alertsensitivity_submit';

    this.pvaluePropsPath = ['data', this.pvalueStateKey];
    this.cvaluePropsPath = ['data', this.cvalueStateKey];

    const pValue = get(props, this.pvaluePropsPath, '');
    const cValue = get(props, this.cvaluePropsPath, '');
    const highRatioCValue = get(props, ['data', 'highRatioCValue'], 0);

    const samplingInterval = get(props, ['data', 'samplingInterval'], 1);
    const maximumHint = get(props, ['data', 'maximumHint'], '');
    const dynamicBaselineDetectionFlag = get(props, ['data', 'dynamicBaselineDetectionFlag'], false);
    const enableBaselineDetectionDoubleVerify = get(props, ['data', 'enableBaselineDetectionDoubleVerify'], false);
    const filterByAnomalyInBaselineGeneration = get(props, ['data', 'filterByAnomalyInBaselineGeneration'], false);
    const baselineDuration = get(props, ['data', 'baselineDuration'], 86400000);
    const positiveBaselineViolationFactor = get(props, ['data', 'positiveBaselineViolationFactor'], 0);
    const negativeBaselineViolationFactor = get(props, ['data', 'negativeBaselineViolationFactor'], 0);
    const enablePeriodAnomalyFilter = get(props, ['data', 'enablePeriodAnomalyFilter'], false);
    const enableCumulativeDetect = get(props, ['data', 'enableCumulativeDetect'], false);
    const enableUBLDetect = get(props, ['data', 'enableUBLDetect'], false);

    const [cvalueNumber, cvalueUnit] = this.handleCvalue(cValue, samplingInterval);
    const [hightCValueNumber, highCValueUnit] = this.handleCvalue(highRatioCValue, samplingInterval);

    const newPatternRange = get(props, ['data', 'newPatternRange'], '');
    const incidentPredictionWindow = get(props, ['data', 'incidentPredictionWindow'], '');
    const minIncidentPredictionWindow = get(props, ['data', 'minIncidentPredictionWindow'], '');
    const incidentRelationSearchWindow = get(props, ['data', 'incidentRelationSearchWindow'], '');
    const incidentPredictionEventLimit = get(props, ['data', 'incidentPredictionEventLimit'], '');
    const rootCauseCountThreshold = get(props, ['data', 'rootCauseCountThreshold'], '');
    const rootCauseProbabilityThreshold = get(props, ['data', 'rootCauseProbabilityThreshold'], '');
    const rootCauseLogMessageSearchRange = get(props, ['data', 'rootCauseLogMessageSearchRange'], '');
    const predictionCorrelationSensitivity = get(props, ['data', 'predictionCorrelationSensitivity'], '0.5');
    const causalPredictionSetting = get(props, ['data', 'causalPredictionSetting']);
    const rootCauseRankSetting = get(props, ['data', 'rootCauseRankSetting']);
    const causalMinDelay = get(props, ['data', 'causalMinDelay'], '0');
    const maximumRootCauseResultSize = get(props, ['data', 'maximumRootCauseResultSize'], '');
    const compositeRCALimit = get(props, ['data', 'compositeRCALimit'], 1);
    const multiHopSearchLevel = get(props, ['data', 'multiHopSearchLevel'], '');
    const multiHopSearchLimit = get(props, ['data', 'multiHopSearchLimit'], '');
    const avgPerIncidentDowntimeCost = get(props, ['data', 'avgPerIncidentDowntimeCost'], '');
    const enableKPIPrediction = get(props, ['data', 'enableKPIPrediction'], false);
    const predictionTrainingDataLength = get(props, ['data', 'predictionTrainingDataLength'], 0);
    const ignoreInstanceForKB = get(props, ['data', 'ignoreInstanceForKB'], false);
    const showInstanceDown = get(props, ['data', 'showInstanceDown'], false);
    const retentionTime = get(props, ['data', 'retentionTime'], 365);
    const UBLRetentionTime = get(props, ['data', 'UBLRetentionTime'], 365);
    const enableMetricDataPrediction = get(props, ['data', 'enableMetricDataPrediction'], false);
    const enableFillGap = get(props, ['data', 'enableFillGap'], false);

    const initState = {
      activeKey: '1',

      pValue,
      rawCValue: cValue,
      cValue: cvalueNumber,
      highRatioCValue: hightCValueNumber,
      cvalueUnit,
      highCValueUnit,
      samplingInterval,
      maximumHint,
      newPatternRange,
      incidentPredictionWindow,
      minIncidentPredictionWindow,
      incidentRelationSearchWindow,
      predictionCorrelationSensitivity,
      incidentPredictionEventLimit,
      rootCauseCountThreshold,
      rootCauseProbabilityThreshold: isNumber(rootCauseProbabilityThreshold)
        ? toInteger(rootCauseProbabilityThreshold * 100)
        : '',
      rootCauseLogMessageSearchRange,
      causalPredictionSetting,
      rootCauseRankSetting,
      causalMinDelay: causalMinDelay / 1000 / 60,
      maximumRootCauseResultSize,
      compositeRCALimit,
      multiHopSearchLevel,
      multiHopSearchLimit,
      avgPerIncidentDowntimeCost,
      dynamicBaselineDetectionFlag,
      enableBaselineDetectionDoubleVerify,
      filterByAnomalyInBaselineGeneration,
      baselineDuration,
      positiveBaselineViolationFactor,
      negativeBaselineViolationFactor,
      enablePeriodAnomalyFilter,
      enableCumulativeDetect,
      enableUBLDetect,
      enableKPIPrediction,
      predictionTrainingDataLength,
      ignoreInstanceForKB,
      showInstanceDown,
      retentionTime,
      UBLRetentionTime,
      enableMetricDataPrediction,
      enableFillGap,
    };
    this.state = initState;
    this.localData = initState;

    this.cvalueUnit = [
      { label: intl.formatMessage(appFieldsMessages.minute), value: 60 },
      { label: intl.formatMessage(appFieldsMessages.hour), value: 3600 },
      { label: intl.formatMessage(appFieldsMessages.day), value: 86400 },
    ];
    this.predictionRelationsOptions = [
      { label: intl.formatMessage(settingsMessages.allRelationships), value: '0' },
      { label: intl.formatMessage(settingsMessages.interInstanceRelationships), value: '1' },
      { label: intl.formatMessage(settingsMessages.intraInstanceRelationships), value: '2' },
    ];
    this.rootCauseRankOptions = [
      { label: intl.formatMessage(settingsMessages.intraInstanceFirst), value: '0' },
      { label: intl.formatMessage(settingsMessages.interInstanceFirst), value: '1' },
      { label: intl.formatMessage(settingsMessages.noPreference), value: '2' },
    ];
    this.predictionCorrelationSensitivityOptions = [
      { label: intl.formatMessage(DashboardMessages.low), value: '0.85' },
      { label: intl.formatMessage(DashboardMessages.medium), value: '0.75' },
      { label: intl.formatMessage(DashboardMessages.high), value: '0.5' },
    ];
    this.baselineDurationOption = [
      { label: '1', value: 3600000 },
      { label: '2', value: 7200000 },
      { label: '3', value: 10800000 },
      { label: '4', value: 14400000 },
      { label: '6', value: 21600000 },
      { label: '8', value: 28800000 },
      { label: '12', value: 43200000 },
      { label: '24', value: 86400000 },
    ];
  }

  @autobind
  handleCvalue(cValue, samplingInterval) {
    let cvalueNumber = Number(cValue) * samplingInterval;
    let cvalueUnit = 1;

    if (isInteger(samplingInterval / 86400)) {
      cvalueUnit = 86400;
      cvalueNumber /= cvalueUnit;
      return [cvalueNumber, cvalueUnit];
    }

    if (isInteger(cvalueNumber / 3600)) {
      cvalueUnit = 3600;
      cvalueNumber /= cvalueUnit;
      return [cvalueNumber, cvalueUnit];
    }

    if (isInteger(cvalueNumber / 60)) {
      cvalueUnit = 60;
      cvalueNumber /= cvalueUnit;
      return [cvalueNumber, cvalueUnit];
    }
    return [cvalueNumber, cvalueUnit];
  }

  componentDidMount() {
    if (this.props.entry === 'batch') {
      this.props.refs(this);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const pValue = get(newProps, this.pvaluePropsPath, '');
    const cValue = get(newProps, this.cvaluePropsPath, '');
    const samplingInterval = get(newProps, ['data', 'samplingInterval'], 1);
    const maximumHint = get(newProps, ['data', 'maximumHint'], '');
    const dynamicBaselineDetectionFlag = get(newProps, ['data', 'dynamicBaselineDetectionFlag'], false);
    const enableBaselineDetectionDoubleVerify = get(newProps, ['data', 'enableBaselineDetectionDoubleVerify'], false);
    const filterByAnomalyInBaselineGeneration = get(newProps, ['data', 'filterByAnomalyInBaselineGeneration'], false);
    const baselineDuration = get(newProps, ['data', 'baselineDuration'], 86400000);
    const positiveBaselineViolationFactor = get(newProps, ['data', 'positiveBaselineViolationFactor'], 0);
    const negativeBaselineViolationFactor = get(newProps, ['data', 'negativeBaselineViolationFactor'], 0);
    const enablePeriodAnomalyFilter = get(newProps, ['data', 'enablePeriodAnomalyFilter'], false);
    const enableCumulativeDetect = get(newProps, ['data', 'enableCumulativeDetect'], false);
    const enableUBLDetect = get(newProps, ['data', 'enableUBLDetect'], false);
    const newPatternRange = get(newProps, ['data', 'newPatternRange']);
    const incidentPredictionWindow = get(newProps, ['data', 'incidentPredictionWindow'], '');
    const minIncidentPredictionWindow = get(newProps, ['data', 'minIncidentPredictionWindow']);
    const incidentRelationSearchWindow = get(newProps, ['data', 'incidentRelationSearchWindow']);
    const incidentPredictionEventLimit = get(newProps, ['data', 'incidentPredictionEventLimit']);
    const rootCauseCountThreshold = get(newProps, ['data', 'rootCauseCountThreshold']);
    const rootCauseProbabilityThreshold = get(newProps, ['data', 'rootCauseProbabilityThreshold']);
    const rootCauseLogMessageSearchRange = get(newProps, ['data', 'rootCauseLogMessageSearchRange']);
    const predictionCorrelationSensitivity = get(newProps, ['data', 'predictionCorrelationSensitivity'], '0.5');
    const causalPredictionSetting = get(newProps, ['data', 'causalPredictionSetting']);
    const rootCauseRankSetting = get(newProps, ['data', 'rootCauseRankSetting']);
    const causalMinDelay = get(newProps, ['data', 'causalMinDelay'], '0');
    const maximumRootCauseResultSize = get(newProps, ['data', 'maximumRootCauseResultSize']);
    const compositeRCALimit = get(newProps, ['data', 'compositeRCALimit']);
    const multiHopSearchLevel = get(newProps, ['data', 'multiHopSearchLevel']);
    const multiHopSearchLimit = get(newProps, ['data', 'multiHopSearchLimit']);
    const avgPerIncidentDowntimeCost = get(newProps, ['data', 'avgPerIncidentDowntimeCost']);
    const enableKPIPrediction = get(newProps, ['data', 'enableKPIPrediction'], false);
    const predictionTrainingDataLength = get(newProps, ['data', 'predictionTrainingDataLength'], 0);
    const ignoreInstanceForKB = get(newProps, ['data', 'ignoreInstanceForKB']);
    const highRatioCValue = get(newProps, ['data', 'highRatioCValue'], '');
    const showInstanceDown = get(newProps, ['data', 'showInstanceDown'], false);
    const retentionTime = get(newProps, ['data', 'retentionTime'], 365);
    const UBLRetentionTime = get(newProps, ['data', 'UBLRetentionTime'], 365);
    const enableMetricDataPrediction = get(newProps, ['data', 'enableMetricDataPrediction'], false);
    const enableFillGap = get(newProps, ['data', 'enableFillGap'], false);

    // If the props is changed, set the local state.
    if (
      (pValue !== get(this.props, this.pvaluePropsPath) ||
        cValue !== get(this.props, this.cvaluePropsPath) ||
        maximumHint !== get(this.props, ['data', 'maximumHint']) ||
        dynamicBaselineDetectionFlag !== get(this.props, ['data', 'dynamicBaselineDetectionFlag']) ||
        enableBaselineDetectionDoubleVerify !== get(this.props, ['data', 'enableBaselineDetectionDoubleVerify']) ||
        filterByAnomalyInBaselineGeneration !== get(this.props, ['data', 'filterByAnomalyInBaselineGeneration']) ||
        baselineDuration !== get(this.props, ['data', 'baselineDuration']) ||
        positiveBaselineViolationFactor !== get(this.props, ['data', 'positiveBaselineViolationFactor']) ||
        negativeBaselineViolationFactor !== get(this.props, ['data', 'negativeBaselineViolationFactor']) ||
        enablePeriodAnomalyFilter !== get(this.props, ['data', 'enablePeriodAnomalyFilter']) ||
        enableCumulativeDetect !== get(this.props, ['data', 'enableCumulativeDetect']) ||
        enableUBLDetect !== get(this.props, ['data', 'enableUBLDetect']) ||
        newPatternRange !== get(this.props, ['data', 'newPatternRange']) ||
        incidentPredictionEventLimit !== get(this.props, ['data', 'incidentPredictionEventLimit']) ||
        incidentPredictionWindow !== get(this.props, ['data', 'incidentPredictionWindow']) ||
        minIncidentPredictionWindow !== get(this.props, ['data', 'minIncidentPredictionWindow']) ||
        incidentRelationSearchWindow !== get(this.props, ['data', 'incidentRelationSearchWindow']) ||
        rootCauseCountThreshold !== get(this.props, ['data', 'rootCauseCountThreshold']) ||
        rootCauseProbabilityThreshold !== get(this.props, ['data', 'rootCauseProbabilityThreshold']) ||
        rootCauseLogMessageSearchRange !== get(this.props, ['data', 'rootCauseLogMessageSearchRange']) ||
        predictionCorrelationSensitivity !== get(this.props, ['data', 'predictionCorrelationSensitivity']) ||
        causalPredictionSetting !== get(this.props, ['data', 'causalPredictionSetting']) ||
        rootCauseRankSetting !== get(this.props, ['data', 'rootCauseRankSetting']) ||
        causalMinDelay !== get(this.props, ['data', 'causalMinDelay']) ||
        maximumRootCauseResultSize !== get(this.props, ['data', 'maximumRootCauseResultSize']) ||
        compositeRCALimit !== get(this.props, ['data', 'compositeRCALimit']) ||
        multiHopSearchLevel !== get(this.props, ['data', 'multiHopSearchLevel']) ||
        multiHopSearchLimit !== get(this.props, ['data', 'multiHopSearchLimit']) ||
        avgPerIncidentDowntimeCost !== get(this.props, ['data', 'avgPerIncidentDowntimeCost']) ||
        enableKPIPrediction !== get(this.props, ['data', 'enableKPIPrediction']) ||
        predictionTrainingDataLength !== get(this.props, ['data', 'predictionTrainingDataLength'], 0) ||
        ignoreInstanceForKB !== get(this.props, ['data', 'ignoreInstanceForKB']) ||
        highRatioCValue !== get(this.props, ['data', 'highRatioCValue'], '') ||
        retentionTime !== get(this.props, ['data', 'retentionTime'], 365) ||
        UBLRetentionTime !== get(this.props, ['data', 'UBLRetentionTime'], 365) ||
        showInstanceDown !== get(this.props, ['data', 'showInstanceDown']) ||
        enableMetricDataPrediction !== get(this.props, ['data', 'enableMetricDataPrediction'], '') ||
        enableFillGap !== get(this.props, ['data', 'enableFillGap'], false)) &&
      !R.isEmpty(newProps.data)
    ) {
      const [cvalueNumber, cvalueUnit] = this.handleCvalue(cValue, samplingInterval);
      const [highCValueNumber, highCValueUnit] = this.handleCvalue(highRatioCValue, samplingInterval);

      const isBatch = R.equals(newProps.entry, 'batch');
      if (isBatch && newProps.updateStatus === 'tab') {
        return;
      }
      const forms = {
        pValue,
        cValue: cvalueNumber,
        cvalueUnit,
        highRatioCValue: highCValueNumber,
        highCValueUnit,
        samplingInterval,
        maximumHint,
        newPatternRange,
        incidentPredictionWindow: String(incidentPredictionWindow),
        minIncidentPredictionWindow: String(minIncidentPredictionWindow),
        incidentRelationSearchWindow,
        incidentPredictionEventLimit,
        predictionCorrelationSensitivity,
        rootCauseCountThreshold,
        rootCauseProbabilityThreshold: isNumber(rootCauseProbabilityThreshold)
          ? toInteger(rootCauseProbabilityThreshold * 100)
          : '',
        rootCauseLogMessageSearchRange,
        causalPredictionSetting,
        rootCauseRankSetting,
        causalMinDelay: causalMinDelay / 1000 / 60,
        maximumRootCauseResultSize,
        compositeRCALimit,
        multiHopSearchLevel,
        multiHopSearchLimit,
        avgPerIncidentDowntimeCost,
        dynamicBaselineDetectionFlag,
        enableBaselineDetectionDoubleVerify,
        filterByAnomalyInBaselineGeneration,
        baselineDuration,
        positiveBaselineViolationFactor,
        negativeBaselineViolationFactor,
        enablePeriodAnomalyFilter,
        enableCumulativeDetect,
        enableUBLDetect,
        enableKPIPrediction,
        predictionTrainingDataLength,
        ignoreInstanceForKB,
        showInstanceDown,
        retentionTime,
        UBLRetentionTime,
        enableMetricDataPrediction,
        enableFillGap,
      };
      this.setState(forms);
      this.localData = forms;
    }
  }

  @autobind
  diffFlagVal(newVal, oldVal) {
    return toString(newVal) !== toString(oldVal);
  }

  @autobind
  diffSensitivity(sensitivity) {
    const { cvalueUnit, samplingInterval, highCValueUnit } = this.state;
    const sensitivityObj = { oldValMap: {}, newValMap: {} };
    R.forEach((item) => {
      const newVal = sensitivity[item];
      const oldVal = this.localData[item];
      if (!R.has(item, this.localData)) return;
      if (item === 'rootCauseProbabilityThreshold') {
        const oldSetVal = isNumber(oldVal) ? oldVal / 100 : oldVal;
        if (this.diffFlagVal(newVal, oldSetVal)) {
          sensitivityObj.oldValMap[item] = oldSetVal;
          sensitivityObj.newValMap[item] = newVal;
        }
      } else if (item === 'cValue' || item === 'highRatioCValue') {
        const multipleMap = { cValue: cvalueUnit, highRatioCValue: highCValueUnit };
        const oldSetVal = isNumber(oldVal) ? (oldVal * multipleMap[item]) / samplingInterval : oldVal;
        if (this.diffFlagVal(newVal, oldSetVal)) {
          sensitivityObj.oldValMap[item] = oldSetVal;
          sensitivityObj.newValMap[item] = newVal;
        }
      } else if (item === 'causalMinDelay') {
        const oldSetVal = isNumber(oldVal) ? oldVal * 60 * 1000 : oldVal;
        if (this.diffFlagVal(newVal, oldSetVal)) {
          sensitivityObj.oldValMap[item] = oldSetVal;
          sensitivityObj.newValMap[item] = newVal;
        }
      } else if (toString(newVal) !== toString(oldVal)) {
        if (!Number.isNaN(newVal)) {
          sensitivityObj.oldValMap[item] = oldVal;
          sensitivityObj.newValMap[item] = newVal;
        }
      }
    }, R.keys(sensitivity));
    return sensitivityObj;
  }

  @autobind
  handleSaveClick() {
    const { saveProjectSettings, projectName, showAppAlert, entry, handleMetricSensitivity } = this.props;
    const pValue = parseFloat(this.state[this.pvalueStateKey]);
    const cValue = parseFloat(this.state[this.cvalueStateKey]);
    const {
      cvalueUnit,
      maximumHint,
      causalPredictionSetting,
      rootCauseRankSetting,
      dynamicBaselineDetectionFlag,
      enableBaselineDetectionDoubleVerify,
      filterByAnomalyInBaselineGeneration,
      baselineDuration,
      positiveBaselineViolationFactor,
      negativeBaselineViolationFactor,
      enablePeriodAnomalyFilter,
      enableCumulativeDetect,
      enableUBLDetect,
      predictionCorrelationSensitivity,
      enableKPIPrediction,
      predictionTrainingDataLength,
      highRatioCValue,
      highCValueUnit,
      ignoreInstanceForKB,
      showInstanceDown,
      retentionTime,
      UBLRetentionTime,
      enableMetricDataPrediction,
      enableFillGap,
    } = this.state;
    const { samplingInterval } = this.state;
    const isBatch = entry === 'batch';
    const newPatternRange = parseInt(this.state.newPatternRange, 10);
    const incidentPredictionWindow = this.state.incidentPredictionWindow
      ? parseInt(this.state.incidentPredictionWindow, 10)
      : null;
    const minIncidentPredictionWindow = this.state.minIncidentPredictionWindow
      ? parseInt(this.state.minIncidentPredictionWindow, 10)
      : null;
    const incidentRelationSearchWindow = this.state.incidentRelationSearchWindow
      ? parseInt(this.state.incidentRelationSearchWindow, 10)
      : null;
    const incidentPredictionEventLimit = this.state.incidentPredictionEventLimit
      ? parseInt(this.state.incidentPredictionEventLimit, 10)
      : null;
    const rootCauseCountThreshold = this.state.rootCauseCountThreshold
      ? parseInt(this.state.rootCauseCountThreshold, 10)
      : null;
    const rootCauseProbabilityThreshold = this.state.rootCauseProbabilityThreshold
      ? parseFloat(this.state.rootCauseProbabilityThreshold)
      : null;
    const rootCauseLogMessageSearchRange = this.state.rootCauseLogMessageSearchRange
      ? parseInt(this.state.rootCauseLogMessageSearchRange, 10)
      : null;
    const causalMinDelay = this.state.causalMinDelay ? parseInt(this.state.causalMinDelay, 10) : null;
    const maximumRootCauseResultSize = this.state.maximumRootCauseResultSize
      ? parseInt(this.state.maximumRootCauseResultSize, 10)
      : null;
    const compositeRCALimit = this.state.compositeRCALimit ? parseInt(this.state.compositeRCALimit, 10) : null;
    const multiHopSearchLevel = this.state.multiHopSearchLevel ? parseInt(this.state.multiHopSearchLevel, 10) : null;
    const multiHopSearchLimit = this.state.multiHopSearchLimit ? parseInt(this.state.multiHopSearchLimit, 10) : null;
    const avgPerIncidentDowntimeCost = this.state.avgPerIncidentDowntimeCost
      ? parseInt(this.state.avgPerIncidentDowntimeCost, 10)
      : null;

    if (
      !isInteger((cValue * cvalueUnit) / samplingInterval) ||
      !isInteger((highRatioCValue * highCValueUnit) / samplingInterval)
    ) {
      if (isBatch) {
        message.error(`The Number of Samples must be an integer multiple of ${samplingInterval} minutes`);
      } else {
        showAppAlert('error', {
          id: projectName,
          defaultMessage: `The Number of Samples must be an integer multiple of ${samplingInterval} minutes`,
        });
      }
    } else {
      const sensitivity = {
        pValue,
        cValue: (cValue * cvalueUnit) / samplingInterval,
        highRatioCValue: (highRatioCValue * highCValueUnit) / samplingInterval,
        maximumHint,
        dynamicBaselineDetectionFlag,
        enableBaselineDetectionDoubleVerify,
        filterByAnomalyInBaselineGeneration,
        baselineDuration,
        positiveBaselineViolationFactor,
        negativeBaselineViolationFactor,
        enablePeriodAnomalyFilter,
        enableCumulativeDetect,
        enableUBLDetect,
        newPatternRange,
        incidentPredictionWindow: String(incidentPredictionWindow),
        minIncidentPredictionWindow: String(minIncidentPredictionWindow),
        incidentRelationSearchWindow,
        incidentPredictionEventLimit,
        predictionCorrelationSensitivity: Number(predictionCorrelationSensitivity),
        rootCauseCountThreshold,
        rootCauseProbabilityThreshold: isNumber(rootCauseProbabilityThreshold)
          ? rootCauseProbabilityThreshold / 100
          : '',
        rootCauseLogMessageSearchRange,
        causalPredictionSetting: Number(causalPredictionSetting),
        rootCauseRankSetting: Number(rootCauseRankSetting),
        causalMinDelay: causalMinDelay * 60 * 1000,
        maximumRootCauseResultSize,
        // compositeRCALimit,
        multiHopSearchLevel,
        multiHopSearchLimit,
        avgPerIncidentDowntimeCost,
        enableKPIPrediction,
        predictionTrainingDataLength: Number(predictionTrainingDataLength),
        ignoreInstanceForKB,
        showInstanceDown,
        retentionTime,
        UBLRetentionTime,
        enableMetricDataPrediction,
        enableFillGap,
      };
      if (isBatch) {
        handleMetricSensitivity({ ...sensitivity, samplingInterval });
        return;
      }

      const hasSetData = this.diffSensitivity(sensitivity);
      if (R.keys(hasSetData.newValMap).length < 1 || R.keys(hasSetData.oldValMap).length < 1) {
        return;
      }
      sensitivity.hasSetData = JSON.stringify(hasSetData);

      saveProjectSettings(projectName, sensitivity, { [this.submitLoadingKey]: true });
    }
  }

  @autobind
  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  @autobind
  renderFieldItem(FormFieldItem, fieldNames = []) {
    const { entry } = this.props;
    const isBatch = R.equals(entry, 'batch');
    const initialValue = R.map(
      (n) => (R.isNil(this.localData[n]) ? this.localData[n] : this.localData[n].toString().toLowerCase()),
      fieldNames,
    );
    const currentValue = R.map(
      (n) => (R.isNil(this.state[n]) ? this.state[n] : this.state[n].toString().toLowerCase()),
      fieldNames,
    );
    return (
      <>
        {isBatch && (
          <div className="flex-row" style={{ alignItems: 'end' }}>
            <div style={{ height: 20, width: 28, margin: ' 0em 0em 1.5em' }}>
              {!R.equals(initialValue, currentValue) && <CheckOutlined style={{ fontSize: 16, color: '#f85042' }} />}
            </div>
            {FormFieldItem}
          </div>
        )}
        {!isBatch && FormFieldItem}
      </>
    );
  }

  render() {
    const { intl, projectName, tabStyle, entry, tabPosition } = this.props;
    const pvalueLink = VLink.state(this, this.pvalueStateKey).check(
      (x) => Boolean(x),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const cvalueLink = VLink.state(this, this.cvalueStateKey).check((x) => {
      return Number(x) > 0 && isInteger(Number(x));
    }, intl.formatMessage(settingsMessages.errorEmptySelection));

    const highCValueLink = VLink.state(this, 'highRatioCValue').check((x) => {
      return Number(x) > 0 && isInteger(Number(x));
    }, 'Please input an integer number');

    const highCValueUnitLink = VLink.state(this, 'highCValueUnit').check((x) => x, 'The high cValue unit is required');

    const cvalueUnitLink = VLink.state(this, 'cvalueUnit').check((x) => x, 'The low cValue unit is required');

    const maximumHintLink = VLink.state(this, 'maximumHint');

    const newPatternRangeLink = VLink.state(this, 'newPatternRange').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0),
      'Please input a positive integer number',
    );
    const incidentPredictionEventLimitLink = VLink.state(this, 'incidentPredictionEventLimit').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input an integer number',
    );
    const incidentPredictionWindowLink = VLink.state(this, 'incidentPredictionWindow').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'input an integer number',
    );
    const minIncidentPredictionWindowLink = VLink.state(this, 'minIncidentPredictionWindow').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'input an integer number',
    );
    const incidentRelationSearchWindowLink = VLink.state(this, 'incidentRelationSearchWindow').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'input an integer number',
    );
    const rootCauseCountThresholdLink = VLink.state(this, 'rootCauseCountThreshold').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const rootCauseProbabilityThresholdLink = VLink.state(this, 'rootCauseProbabilityThreshold').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0 && parseInt(value, 10) <= 100),
      'Please input a positive integer number',
    );
    const rootCauseLogMessageSearchRangeLink = VLink.state(this, 'rootCauseLogMessageSearchRange').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) > 0),
      'Please input a positive integer number',
    );
    const predictionRelationsLink = VLink.state(this, 'causalPredictionSetting').check(
      (x) => Boolean(x),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const rootCauseRankLink = VLink.state(this, 'rootCauseRankSetting').check(
      (x) => Boolean(x),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const predictionCorrelationSensitivityLink = VLink.state(this, 'predictionCorrelationSensitivity').check(
      (x) => Boolean(x),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const baselineDurationLink = VLink.state(this, 'baselineDuration').check(
      (x) => Boolean(x),
      intl.formatMessage(settingsMessages.errorEmptySelection),
    );
    const causalMinDelayLink = VLink.state(this, 'causalMinDelay').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input an integer number',
    );
    const maximumRootCauseResultSizeLink = VLink.state(this, 'maximumRootCauseResultSize').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const multiHopSearchLevelLink = VLink.state(this, 'multiHopSearchLevel').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 2),
      'Please input a positive integer number',
    );
    const multiHopSearchLimitLink = VLink.state(this, 'multiHopSearchLimit').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input an integer number',
    );
    const avgPerIncidentDowntimeCostLink = VLink.state(this, 'avgPerIncidentDowntimeCost').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'Please input a positive integer number',
    );
    const trainingDataLengthLink = VLink.state(this, 'predictionTrainingDataLength').check((x) => {
      return (Number(x) >= 0 && isInteger(Number(x))) || null || '' || 0;
    }, 'Please input an integer number');

    const retentionTimeLink = VLink.state(this, 'retentionTime').check((x) => {
      return (Number(x) >= 0 && isInteger(Number(x))) || null || '' || 0;
    }, 'Please input an integer number');

    const UBLRetentionTimeLink = VLink.state(this, 'UBLRetentionTime').check((x) => {
      return (Number(x) >= 0 && isInteger(Number(x))) || null || '' || 0;
    }, 'Please input an integer number');

    const hasError =
      pvalueLink.error ||
      cvalueLink.error ||
      cvalueUnitLink.error ||
      causalMinDelayLink.error ||
      maximumRootCauseResultSizeLink.error ||
      multiHopSearchLevelLink.error ||
      multiHopSearchLimitLink.error ||
      newPatternRangeLink.error ||
      incidentPredictionEventLimitLink.error ||
      predictionCorrelationSensitivityLink.error ||
      baselineDurationLink.error ||
      incidentPredictionWindowLink.error ||
      minIncidentPredictionWindowLink.error ||
      incidentRelationSearchWindowLink.error ||
      rootCauseCountThresholdLink.error ||
      rootCauseProbabilityThresholdLink.error ||
      rootCauseLogMessageSearchRangeLink.error ||
      predictionRelationsLink.error ||
      rootCauseRankLink.error ||
      avgPerIncidentDowntimeCostLink.error ||
      trainingDataLengthLink.error ||
      highCValueLink.error ||
      highCValueUnitLink.error;

    const isSubmitting = get(this.props.currentLoadingComponents, this.submitLoadingKey, false);
    return (
      <Container fullHeight className="flex-col flex-min-height tabs-content-padding-top">
        <div className="flex-grow overflow-y-auto">
          <Tabs
            className="full-height ant-tabs-content-full-height"
            tabBarStyle={{ width: tabPosition === 'top' ? '100%' : 250 }}
            tabPosition={tabPosition || 'left'}
            activeKey={this.state.activeKey}
            onChange={(activeKey) => this.setState({ activeKey })}
            style={tabStyle}
          >
            <Tabs.TabPane
              tab={intl.formatMessage(settingsMessages.anomalyDetectionSetting)}
              key="1"
              className="ui form overflow-y-auto"
            >
              <Alert
                message={null}
                description={intl.formatMessage(settingsMessages.anomalyDetectionSettingDesc)}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
              {entry === 'batch' &&
                this.renderFieldItem(
                  <div className="select field required" style={{ width: 180 }}>
                    <label>{intl.formatMessage(settingsMessages.sensitivity)}</label>
                    <AnomalyThresholdSensitivity intl={intl} valueLink={pvalueLink} />
                  </div>,
                  ['pValue'],
                )}
              {entry === 'batch' &&
                this.renderFieldItem(
                  <div className="select field required" style={{ width: 310 }}>
                    <label>{intl.formatMessage(settingsMessages.lowAnomalyRatioDurationThreshold)}</label>
                    <div className="flex-row">
                      <Input className="input-appearance" type="number" valueLink={cvalueLink} style={{ width: 180 }} />
                      <Select valueLink={cvalueUnitLink} style={{ width: 130 }} options={this.cvalueUnit} />
                    </div>
                  </div>,
                  ['cValue', 'cvalueUnit'],
                )}

              {entry === 'batch' &&
                this.renderFieldItem(
                  <div className="select field required" style={{ width: 310 }}>
                    <label>{intl.formatMessage(settingsMessages.highAnomalyRatioDurationThreshold)}</label>
                    <div className="flex-row">
                      <Input
                        className="input-appearance"
                        type="number"
                        valueLink={highCValueLink}
                        style={{ width: 180 }}
                      />
                      <Select valueLink={highCValueUnitLink} style={{ width: 130 }} options={this.cvalueUnit} />
                    </div>
                  </div>,
                  ['highRatioCValue', 'highCValueUnit'],
                )}
              {entry === 'batch' &&
                this.renderFieldItem(
                  <div
                    className="select field flex-row"
                    style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                  >
                    <label style={{ width: 350, marginLeft: 14 }}>
                      {intl.formatMessage(settingsMessages.enableDynamicBaselineDetection)}
                    </label>
                    <input
                      name="dynamicBaselineDetectionFlag"
                      type="checkbox"
                      checked={this.state.dynamicBaselineDetectionFlag}
                      onChange={this.handleInputChange}
                    />
                  </div>,
                  ['dynamicBaselineDetectionFlag'],
                )}
              {this.renderFieldItem(
                <div className="select field" style={{ width: 180 }}>
                  <label>{intl.formatMessage(settingsMessages.maximumHintNumber)}</label>
                  <Input
                    className="input-appearance"
                    type="number"
                    valueLink={maximumHintLink}
                    style={{ width: 180 }}
                  />
                </div>,
                ['maximumHint'],
              )}
              {entry !== 'batch' &&
                this.renderFieldItem(
                  <div
                    className="select field flex-row"
                    style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                  >
                    <label style={{ width: 350, marginLeft: 14 }}>
                      {intl.formatMessage(settingsMessages.enableBaselineValidation)}
                    </label>
                    <input
                      name="enableBaselineDetectionDoubleVerify"
                      type="checkbox"
                      checked={this.state.enableBaselineDetectionDoubleVerify}
                      onChange={this.handleInputChange}
                    />
                  </div>,
                  ['enableBaselineDetectionDoubleVerify'],
                )}

              {entry !== 'batch' &&
                this.renderFieldItem(
                  <div
                    className="select field flex-row"
                    style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                  >
                    <label style={{ width: 350, marginLeft: 14 }}>
                      {intl.formatMessage(settingsMessages.enableAnomalyFilterForBaseline)}
                    </label>
                    <input
                      name="filterByAnomalyInBaselineGeneration"
                      type="checkbox"
                      checked={this.state.filterByAnomalyInBaselineGeneration}
                      onChange={this.handleInputChange}
                    />
                  </div>,
                  ['filterByAnomalyInBaselineGeneration'],
                )}
              {entry !== 'batch' &&
                this.renderFieldItem(
                  <div className="field">
                    <label>{intl.formatMessage(settingsMessages.baselineSpanHours)}</label>
                    <div className="select field required" style={{ width: 180 }}>
                      <Select valueLink={baselineDurationLink} options={this.baselineDurationOption} />
                    </div>
                  </div>,
                  ['baselineDuration'],
                )}

              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14 }}>
                    {intl.formatMessage(settingsMessages.missingDataIncidentEscalation)}
                  </label>
                  <input
                    name="showInstanceDown"
                    type="checkbox"
                    checked={this.state.showInstanceDown}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['showInstanceDown'],
              )}
              <div>
                <label>{intl.formatMessage(settingsMessages.dynamicBaselineViolationFactor)}</label>
                <div style={{ margin: '8px 0 16px 16px' }}>
                  {this.renderFieldItem(
                    <div className="select field flex-row flex-center-align">
                      <label style={{ width: 170, textTransform: 'capitalize' }}>
                        {intl.formatMessage(settingsMessages.positiveAnomalyDetection)}:
                      </label>
                      <InputNumber
                        min={0}
                        style={{ width: 180 }}
                        value={this.state.positiveBaselineViolationFactor}
                        onChange={(value) => this.setState({ positiveBaselineViolationFactor: value || 0 })}
                      />
                    </div>,
                    ['positiveBaselineViolationFactor'],
                  )}
                  {this.renderFieldItem(
                    <div className="select field flex-row flex-center-align" style={{ marginTop: 8 }}>
                      <label style={{ width: 170, textTransform: 'capitalize' }}>
                        {intl.formatMessage(settingsMessages.negativeAnomalyDetection)}:
                      </label>
                      <InputNumber
                        min={0}
                        style={{ width: 180 }}
                        value={this.state.negativeBaselineViolationFactor}
                        onChange={(value) => this.setState({ negativeBaselineViolationFactor: value || 0 })}
                      />
                    </div>,
                    ['negativeBaselineViolationFactor'],
                  )}
                </div>
              </div>
              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14 }}>
                    {intl.formatMessage(settingsMessages.enablePeriodAnomalyFilter)}
                  </label>
                  <input
                    name="enablePeriodAnomalyFilter"
                    type="checkbox"
                    checked={this.state.enablePeriodAnomalyFilter}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['enablePeriodAnomalyFilter'],
              )}

              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14 }}>
                    {intl.formatMessage(settingsMessages.enableCumulativeDetect)}
                  </label>
                  <input
                    name="enableCumulativeDetect"
                    type="checkbox"
                    checked={this.state.enableCumulativeDetect}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['enableCumulativeDetect'],
              )}
              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14 }}>
                    {intl.formatMessage(settingsMessages.enableUBLDetect)}
                  </label>
                  <input
                    name="enableUBLDetect"
                    type="checkbox"
                    checked={this.state.enableUBLDetect}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['enableUBLDetect'],
              )}

              {this.renderFieldItem(
                <div className="field">
                  <label>
                    {intl.formatMessage(settingsMessages.newPatternDetectionPeriod)}
                    <Tooltip placement="top" title={intl.formatMessage(settingsMessages.newPatternDetectionPeriodDesc)}>
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Tooltip>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="newPatternRange"
                      valueLink={newPatternRangeLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['newPatternRange'],
              )}

              {this.renderFieldItem(
                <div className="select field">
                  <label>{intl.formatMessage(settingsMessages.metricDataPredictionTrainingDataLength)}</label>
                  <Input
                    className="input-appearance"
                    type="number"
                    valueLink={trainingDataLengthLink}
                    style={{ maxWidth: 270 }}
                  />
                </div>,
                ['predictionTrainingDataLength'],
              )}
              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14 }}>
                    {intl.formatMessage(settingsMessages.enableMetricPrediction)}
                  </label>
                  <input
                    name="enableMetricDataPrediction"
                    type="checkbox"
                    checked={this.state.enableMetricDataPrediction}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['enableMetricDataPrediction'],
              )}
              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14 }}>Enable Gap Filling</label>
                  <input
                    name="enableFillGap"
                    type="checkbox"
                    checked={this.state.enableFillGap}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['enableFillGap'],
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={intl.formatMessage(appMenusMessages.globalSystemPrediction)}
              key="2"
              className="ui form overflow-y-auto"
            >
              <Alert
                message={null}
                description={intl.formatMessage(settingsMessages.incidentPredictionDesc, { projectName })}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />

              {this.renderFieldItem(
                <div
                  className="select field flex-row"
                  style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                >
                  <label style={{ width: 350, marginLeft: 14 }}>
                    {intl.formatMessage(settingsMessages.enableKPIPrediction)}
                  </label>
                  <input
                    name="enableKPIPrediction"
                    type="checkbox"
                    checked={this.state.enableKPIPrediction}
                    onChange={this.handleInputChange}
                  />
                </div>,
                ['enableKPIPrediction'],
              )}

              {this.renderFieldItem(
                <div className="field">
                  <label>{intl.formatMessage(settingsMessages.numberLimit)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="incidentPredictionEventLimit"
                      valueLink={incidentPredictionEventLimitLink}
                      style={{ width: 180 }}
                      icon="icon close"
                      iconSearch
                      iconSearchClick={() => incidentPredictionEventLimitLink.set('')}
                    />
                  </div>
                </div>,
                ['incidentPredictionEventLimit'],
              )}

              {this.renderFieldItem(
                <div className="field">
                  <label>{intl.formatMessage(settingsMessages.maximumPredictionInterval)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="1"
                      name="incidentPredictionWindow"
                      valueLink={incidentPredictionWindowLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['incidentPredictionWindow'],
              )}

              {this.renderFieldItem(
                <div className="field">
                  <label>{intl.formatMessage(settingsMessages.minimumPredictionInterval)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="minIncidentPredictionWindow"
                      valueLink={minIncidentPredictionWindowLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['minIncidentPredictionWindow'],
              )}

              {this.renderFieldItem(
                <div className="field">
                  <label>{intl.formatMessage(settingsMessages.incidentPredictionValidationWindow)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="incidentRelationSearchWindow"
                      valueLink={incidentRelationSearchWindowLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['incidentRelationSearchWindow'],
              )}

              {this.renderFieldItem(
                <div className="field">
                  <label>{intl.formatMessage(settingsMessages.FFTMetricPrediction)}</label>
                  <div className="select field required" style={{ width: 180 }}>
                    <Select
                      valueLink={predictionCorrelationSensitivityLink}
                      options={this.predictionCorrelationSensitivityOptions}
                    />
                  </div>
                </div>,
                ['predictionCorrelationSensitivity'],
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={intl.formatMessage(settingsMessages.rootCauseAnalysisSetting)}
              key="3"
              className="ui form overflow-y-auto"
            >
              <Alert
                message={null}
                description={intl.formatMessage(settingsMessages.rootCauseAnalysisDesc, { projectName })}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseNumberLimitIncident)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="maximumRootCauseResultSize"
                      valueLink={maximumRootCauseResultSizeLink}
                    />
                  </div>
                </div>,
                ['maximumRootCauseResultSize'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseCountThreshold)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="rootCauseCountThreshold"
                      valueLink={rootCauseCountThresholdLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['rootCauseCountThreshold'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseProbabilityThreshold)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="rootCauseProbabilityThreshold"
                      valueLink={rootCauseProbabilityThresholdLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['rootCauseProbabilityThreshold'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseLogSearchWindow)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="0"
                      name="rootCauseLogMessageSearchRange"
                      valueLink={rootCauseLogMessageSearchRangeLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['rootCauseLogMessageSearchRange'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.IPAndRCARelationSelection)}</label>
                  <div className="select field required" style={{ width: 180 }}>
                    <Select valueLink={predictionRelationsLink} options={this.predictionRelationsOptions} />
                  </div>
                </div>,
                ['causalPredictionSetting'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>
                    {intl.formatMessage(settingsMessages.maximumRootCauseHop)}
                    <Popover
                      title={null}
                      content={
                        <div style={{ maxWidth: 320 }}>
                          {intl.formatMessage(settingsMessages.valueShouldLargeThan, { a: () => 1 })}
                        </div>
                      }
                      mouseEnterDelay={0.3}
                    >
                      <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
                    </Popover>
                  </label>
                  <div className="select field" style={{ width: 180 }}>
                    <Input
                      type="number"
                      min="2"
                      name="multiHopSearchLevel"
                      valueLink={multiHopSearchLevelLink}
                      style={{ width: 180 }}
                    />
                  </div>
                </div>,
                ['multiHopSearchLevel'],
              )}

              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.rootCauseRank)}</label>
                  <div className="select field required" style={{ width: 180 }}>
                    <Select valueLink={rootCauseRankLink} options={this.rootCauseRankOptions} />
                  </div>
                </div>,
                ['rootCauseRankSetting'],
              )}

              {/* {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.compositeRCALimit)}</label>
                  <div className="select field" style={{ width: 180 }}>
                    <InputNumber
                      type="number"
                      style={{ width: '100%' }}
                      min={0}
                      precision={0}
                      value={this.state.compositeRCALimit}
                      onChange={(value) => this.setState({ compositeRCALimit: value || 1 })}
                    />
                  </div>
                </div>,
                ['compositeRCALimit'],
              )} */}
            </Tabs.TabPane>

            {false && (
              <Tabs.TabPane
                tab={intl.formatMessage(settingsMessages.knowledgeBase)}
                key="5"
                className="ui form overflow-y-auto"
              >
                {this.renderFieldItem(
                  <div
                    className="select field flex-row"
                    style={{ justifyContent: 'start', flexDirection: 'row-reverse', alignItems: 'center' }}
                  >
                    <label style={{ width: 350, marginLeft: 14 }}>
                      {intl.formatMessage(settingsMessages.ignoreInstanceForKB)}
                    </label>
                    <input
                      name="ignoreInstanceForKB"
                      type="checkbox"
                      checked={this.state.ignoreInstanceForKB}
                      onChange={this.handleInputChange}
                    />
                  </div>,
                  ['ignoreInstanceForKB'],
                )}
              </Tabs.TabPane>
            )}

            <Tabs.TabPane
              tab={intl.formatMessage(settingsMessages.laborCost)}
              key="12"
              className="ui form overflow-y-auto"
            >
              {this.renderFieldItem(
                <div>
                  <label>{intl.formatMessage(settingsMessages.averageIncidentDowntimeCost)}</label>
                  <div className="select field">
                    <div style={{ width: 180 }}>
                      <Input
                        type="number"
                        min="0"
                        name="avgPerIncidentDowntimeCost"
                        valueLink={avgPerIncidentDowntimeCostLink}
                      />
                    </div>
                  </div>
                </div>,
                ['avgPerIncidentDowntimeCost'],
              )}
            </Tabs.TabPane>

            {entry === 'batch' && (
              <Tabs.TabPane
                tab={intl.formatMessage(settingsMessages.retention)}
                key="13"
                className="ui form overflow-y-auto"
              >
                {this.renderFieldItem(
                  <div>
                    <label>
                      {`${intl.formatMessage(settingsMessages.dataRetention)} (${intl.formatMessage(
                        appFieldsMessages.day,
                      )})`}
                    </label>
                    <div className="select field">
                      <div style={{ width: 180 }}>
                        <Input type="number" min="0" name="retentionTime" valueLink={retentionTimeLink} />
                      </div>
                    </div>
                  </div>,
                  ['retentionTime'],
                )}
                {this.renderFieldItem(
                  <div>
                    <label>
                      {`${intl.formatMessage(settingsMessages.modelRetention)} (${intl.formatMessage(
                        appFieldsMessages.day,
                      )}
                      )`}
                    </label>
                    <div className="select field">
                      <div style={{ width: 180 }}>
                        <Input type="number" min="0" name="UBLRetentionTime" valueLink={UBLRetentionTimeLink} />
                      </div>
                    </div>
                  </div>,
                  ['UBLRetentionTime'],
                )}
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>

        {entry !== 'batch' && (
          <div className="flex-row flex-end-justify" style={{ marginTop: 16 }}>
            <Button type="primary" loading={isSubmitting} disabled={hasError} onClick={this.handleSaveClick}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </div>
        )}
      </Container>
    );
  }
}

const AlertSensitivitySetting = injectIntl(AlertSensitivitySettingCore);
export default connect(
  (state) => {
    return {};
  },
  { push, replace, showAppAlert },
  null,
  { forwardRef: true },
)(AlertSensitivitySetting);
