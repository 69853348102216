/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { message, Spin, Select, Button, AutoComplete } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPut from '../../../common/apis/fetchPut';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { AutoSizer, Table, Column, Modal } from '../../../lib/fui/react';
import { State } from '../../../common/types';

import { appButtonsMessages, appMessages } from '../../../common/app/messages';
import { settingsMessages } from '../../../common/settings/messages';

type Props = {
  activeIncident: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  updateLastActionInfo: Function,
};

class EditServiceNowMappingModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = { isLoading: false, isSubmitting: false, projectMappings: [] };
    this.tables = [];
    this.projects = [];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activeIncident !== nextProps.activeIncident) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const self = this;
    const { intl, projects, credentials, activeIncident } = props;
    const { serviceProvider, account, service_host, userName, tableMapping } = activeIncident;

    self.setState({ isLoading: true });
    // get projects
    this.projects = R.filter((project) => project.owner === userName, projects);

    // parse projectMappings
    let projectMappings = tableMapping || [];
    projectMappings = R.map((item) => {
      return { project: item[0], table: item[1] };
    }, projectMappings);

    props.updateLastActionInfo();
    fetchGet(getEndpoint('service-integration'), {
      ...credentials,
      operation: 'ServiceNow',
      customerName: userName,
      serviceProvider,
      account,
      service_host,
    })
      .then((data) => {
        // get tables
        let tabels = data || [];
        tabels = R.map((item) => {
          return { value: item, label: item };
        }, tabels);
        tabels = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], tabels);
        this.tables = tabels;

        self.setState({ projectMappings, isLoading: false });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.tables = [];
        self.setState({ projectMappings, isLoading: false });
      });
  }

  @autobind
  handleAddProjectMappings() {
    const { projectMappings } = this.state;
    const newProjectMappings = [...projectMappings, { project: undefined, table: undefined }];
    this.setState({ projectMappings: newProjectMappings });
  }

  @autobind
  handleSubmit() {
    const { onClose, intl, credentials, activeIncident } = this.props;
    const { serviceProvider, account, service_host, userName } = activeIncident;
    const { projectMappings } = this.state;
    const mappingList = R.map((item) => {
      return [item.project, item.table];
    }, projectMappings || []);

    this.setState({ isSubmitting: true });
    this.props.updateLastActionInfo();
    fetchPut(getEndpoint('service-integration'), {
      ...credentials,
      operation: 'ServiceNow',
      customerName: userName,
      serviceProvider,
      account,
      service_host,
      mappingList: JSON.stringify(mappingList),
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmitting: false });
        onClose(true);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  renderProject({ cellData, rowData }) {
    return (
      <Select
        size="small"
        style={{ width: '100%' }}
        value={cellData}
        onChange={(project) => {
          rowData.project = project;
          if (this.table) this.table.forceUpdateGrid();
          this.forceUpdate();
        }}
      >
        {R.map(
          (item) => (
            <Select.Option key={item.projectShortName} value={item.projectShortName}>
              {item.projectShortName}
            </Select.Option>
          ),
          this.projects || [],
        )}
      </Select>
    );
  }

  @autobind
  renderTable({ cellData, rowData }) {
    return (
      <AutoComplete
        allowClear
        size="small"
        style={{ width: '100%' }}
        placeholder="enter or select"
        options={this.tables || []}
        filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        value={cellData}
        onChange={(table) => {
          rowData.table = table;
          if (this.table) this.table.forceUpdateGrid();
          this.forceUpdate();
        }}
      />
    );
  }

  @autobind
  controlRenderer({ rowIndex }) {
    const { intl } = this.props;

    return (
      <Button
        size="small"
        className="button-color-grey"
        onClick={() => {
          const { projectMappings } = this.state;
          const newProjectMappings = R.remove(rowIndex, 1, projectMappings);
          this.setState({ projectMappings: newProjectMappings });
        }}
      >
        {intl.formatMessage(appButtonsMessages.remove)}
      </Button>
    );
  }

  render() {
    const { intl, onClose } = this.props;
    const { isLoading, isSubmitting, projectMappings } = this.state;

    const hasError = !R.reduce(
      R.and,
      true,
      R.map((item) => item.project && item.table, projectMappings),
    );
    return (
      <Modal
        visible
        maskClosable={false}
        width={760}
        title={intl.formatMessage(settingsMessages.editTableMapping)}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: hasError, loading: isSubmitting }}
      >
        <Spin spinning={isLoading}>
          <div style={{ marginBottom: 8 }}>
            <Button size="small" type="primary" onClick={this.handleAddProjectMappings}>
              {intl.formatMessage(appButtonsMessages.add)}
            </Button>
          </div>
          <div className="flex-col">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={(table) => {
                    this.table = table;
                  }}
                  width={width}
                  height={300}
                  headerHeight={40}
                  rowHeight={40}
                  rowCount={projectMappings.length}
                  rowGetter={({ index }) => projectMappings[index]}
                  sort={this.sort}
                >
                  <Column
                    width={200}
                    label="Project"
                    dataKey="project"
                    flexGrow={1}
                    cellRenderer={this.renderProject}
                  />
                  <Column width={200} label="Table" dataKey="table" flexGrow={1} cellRenderer={this.renderTable} />
                  <Column width={110} label="" dataKey="project" cellRenderer={this.controlRenderer} />
                </Table>
              )}
            </AutoSizer>
          </div>
        </Spin>
      </Modal>
    );
  }
}

const EditServiceNowMappingModal = injectIntl(EditServiceNowMappingModalCore);
export default connect(
  (state: State) => {
    const { loadStatus, projects } = state.app;
    const { credentials, userInfo } = state.auth;
    return { loadStatus, projects, credentials, userInfo };
  },
  { updateLastActionInfo },
)(EditServiceNowMappingModal);
