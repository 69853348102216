/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import { createEpicMiddleware } from 'redux-observable';
import { createLogicMiddleware } from 'redux-logic';
import { routerMiddleware } from 'react-router-redux';

import configureEpics from './configureEpics';
import configureLogic from './configureLogic';

const injectMiddleware = (deps) => ({ dispatch, getState }) => (next) => (action) =>
  next(typeof action === 'function' ? action({ ...deps, dispatch, getState }) : action);

const configureMiddleware = (deps) => {
  const { history } = deps;

  const rootEpic = configureEpics(deps);
  const epicMiddleware = createEpicMiddleware(rootEpic);

  const rootLogic = configureLogic();
  const logicMiddleware = createLogicMiddleware(rootLogic, deps);

  const middlewares = [injectMiddleware(deps), logicMiddleware, epicMiddleware, routerMiddleware(history)];

  if (module.hot && typeof module.hot.accept === 'function') {
    module.hot.accept('./configureEpics', () => {
      const configureEpics = require('./configureEpics').default;
      epicMiddleware.replaceEpic(configureEpics(deps));
    });
  }

  if (module.hot && typeof module.hot.accept === 'function') {
    module.hot.accept('./configureLogic.js', () => {
      const configureLogic = require('./configureLogic').default;
      logicMiddleware.replaceLogic(configureLogic());
    });
  }

  return middlewares;
};

export default configureMiddleware;
