/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, TreeSelect, Alert, Form, Checkbox, Divider, Input, Select, message, Upload } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Container } from '../../../../lib/fui/react';
import { MetricParser } from '../../../../common/utils';

import { appFieldsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  setting: String,
  // eslint-disable-next-line
  currentProject: Object,
  // eslint-disable-next-line
  dataType: String,
  // eslint-disable-next-line
  currentLoadingComponents: Object,
  // eslint-disable-next-line
  data: Object,
  // eslint-disable-next-line
  saveProjectSettings: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  refreshTime: Number,
};

class EndpointSettingKubernetesCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoadingKey: false,
      isLoading: false,
      isUpdateKey: false,
      isSubmitting: false,
      errorMessageKey: '',
      errorMessage: '',

      clusterType: 'k8sCustom',
      clusterName: '',
      enableAutoscale: false,
      isLoadingFile: false,
      configFileList: [],
      fileContent: undefined,

      privateKeyId: '',
      privateKey: '',
      clientId: '',
      clientEmail: '',
      projectId: '',

      instanceTypeMetricsMap: {},
      instanceTypeList: [],
      preSelectedMetrics: [],
      instanceTypes: [],
      treeData: [],
      treeDataKeys: [],
      selectedMetrics: [],
    };
    this.enableAutoscale = false;
    this.fileContent = undefined;
    this.clusterName = '';
    this.privateKeyId = '';
    this.privateKey = '';
    this.clientId = '';
    this.clientEmail = '';
    this.projectId = '';

    this.clusterTypeOptions = [
      { value: 'k8sCustom', label: 'Custom' },
      { value: 'k8sGke', label: 'Google Kubernetes Engine (GKE)' },
    ];
  }

  componentDidMount() {
    this.reloadKey(this.props);
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refreshTime !== nextProps.refreshTime) {
      this.reloadKey(nextProps);
      this.reloadData(nextProps);
    }
  }

  @autobind
  beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('File must smaller than 2MB!');
      return false;
    }

    this.setState({ isLoadingFile: true, configFileList: [file] });
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const fileContent = reader.result;
        this.setState({ isLoadingFile: false, fileContent });
      };
    });
  }

  @autobind
  reloadKey(props) {
    const { credentials, projectName, currentProject } = props;

    this.setState({ isLoadingKey: true });
    const cloudType = get(currentProject, ['cloudType']);
    return fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((data) => {
        const {
          success,
          message,
          clusterType,
          autoScale: enableAutoscale,
          clusterName,
          privateKeyId,
          privateKey,
          clientId,
          clientEmail,
          projectId,
        } = data || {};
        if (success === undefined || success) {
          this.enableAutoscale = enableAutoscale;
          this.clusterName = clusterName;
          this.privateKeyId = privateKeyId;
          this.privateKey = privateKey;
          this.clientId = clientId;
          this.clientEmail = clientEmail;
          this.projectId = projectId;
          this.setState({
            isLoadingKey: false,
            errorMessageKey: undefined,

            clusterType,
            enableAutoscale,
            clusterName,
            privateKeyId: privateKeyId || '',
            privateKey: privateKey || '',
            clientId: clientId || '',
            clientEmail: clientEmail || '',
            projectId: projectId || '',
          });
        } else {
          this.setState({
            isLoadingKey: false,
            errorMessageKey: message,

            clusterType: 'k8sCustom',
            enableAutoscale: false,
            clusterName: '',
            privateKeyId: '',
            privateKey: '',
            clientId: '',
            clientEmail: '',
            projectId: '',
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoadingKey: false,
          errorMessageKey: errMsg || String(err),

          clusterType: 'k8sCustom',
          enableAutoscale: false,
          clusterName: '',
          privateKeyId: '',
          privateKey: '',
          clientId: '',
          clientEmail: '',
          projectId: '',
        });
      });
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName } = props;

    this.setState({ isLoading: true });
    return fetchGet(getEndpoint('gke-metrics-setting'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const { success, message, metricsList, metrics, preSelected } = data || {};
        if (success === undefined || success) {
          // build instanceType Metrics Map
          const instanceTypeMetricsMap = {};
          R.forEach((val) => {
            R.forEach((item) => {
              const { instanceType, metrics } = item;
              if (!R.has(instanceType, instanceTypeMetricsMap)) instanceTypeMetricsMap[instanceType] = [];

              instanceTypeMetricsMap[instanceType] = [...instanceTypeMetricsMap[instanceType], ...(metrics || [])];
            }, val || []);
          }, metricsList);
          const instanceTypeList = R.keys(instanceTypeMetricsMap);

          // set selected metrics
          let selectedMetrics = [];
          R.forEach((item) => {
            selectedMetrics = [...selectedMetrics, ...(item || [])];
          }, metrics);

          // set default instanceTypes
          const instanceTypes = [];
          R.forEachObjIndexed((allMetrics, instanceType) => {
            if (!R.isEmpty(R.intersection(selectedMetrics, allMetrics))) {
              instanceTypes.push(instanceType);
            }
          }, instanceTypeMetricsMap);

          // build tree data
          let allMetrics = [];
          R.forEachObjIndexed((metrics) => {
            allMetrics = [...allMetrics, ...metrics];
          }, R.pick(instanceTypes, instanceTypeMetricsMap));
          const { treeData, treeDataKeys } = MetricParser.getMetricTreeStructure(allMetrics);

          // reset selectedMetrics
          selectedMetrics = R.intersection(selectedMetrics, treeDataKeys);

          this.setState({
            isLoading: false,
            errorMessage: undefined,
            instanceTypeMetricsMap,
            instanceTypeList,
            preSelectedMetrics: preSelected,
            instanceTypes,
            treeData,
            treeDataKeys,
            selectedMetrics,
          });
        } else {
          this.setState({
            isLoading: false,
            errorMessage: message,
            instanceTypeMetricsMap: {},
            instanceTypeList: [],
            preSelectedMetrics: [],
            instanceTypes: [],
            treeData: [],
            treeDataKeys: [],
            selectedMetrics: [],
          });
        }
      })
      .catch((err) => {
        const { message: errMsg } = err || {};
        this.setState({
          isLoading: false,
          errorMessage: errMsg || String(err),
          instanceTypeMetricsMap: {},
          instanceTypeList: [],
          preSelectedMetrics: [],
          instanceTypes: [],
          treeData: [],
          treeDataKeys: [],
          selectedMetrics: [],
        });
      });
  }

  @autobind
  handleUpdateKey() {
    const { intl, credentials, projectName } = this.props;

    this.setState({ isUpdateKey: true });
    const {
      clusterType,
      clusterName,
      enableAutoscale,
      fileContent,
      privateKeyId,
      privateKey,
      clientId,
      clientEmail,
      projectId,
    } = this.state;
    return fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,

      clusterName,
      enableAutoscale,
      fileConfig: fileContent,
      ...(clusterType === 'k8sGke' ? { projectId, privateKeyId, privateKey, clientEmail, clientId } : {}),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success === undefined || success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isUpdateKey: false, errorMessageKey: undefined });
          this.reloadKey(this.props);
          this.reloadData(this.props);
        } else {
          this.setState({ isUpdateKey: false, errorMessageKey: errMsg });
        }
      })
      .catch((err) => {
        this.setState({ isUpdateKey: false, errorMessageKey: String(err) });
      });
  }

  @autobind
  handleInstanceTypeChange(instanceTypes) {
    this.setState({ instanceTypes }, () => {
      const { instanceTypeMetricsMap, preSelectedMetrics, selectedMetrics } = this.state;
      // build tree data
      let allMetrics = [];
      R.forEachObjIndexed((metrics) => {
        allMetrics = [...allMetrics, ...metrics];
      }, R.pick(instanceTypes, instanceTypeMetricsMap));
      const { treeData, treeDataKeys } = MetricParser.getMetricTreeStructure(allMetrics);

      // set pre selected metrics
      let newMetrics = selectedMetrics;
      R.forEach((val) => {
        const { metrics } = val;
        newMetrics = [...newMetrics, ...(metrics || [])];
      }, preSelectedMetrics);
      newMetrics = R.intersection(newMetrics, treeDataKeys);

      this.setState({ treeData, treeDataKeys, selectedMetrics: newMetrics });
    });
  }

  @autobind
  handleSubmit() {
    const { intl, credentials, projectName } = this.props;

    this.setState({ isSubmitting: true });
    const { selectedMetrics } = this.state;
    return fetchPost(getEndpoint('gke-metrics-setting'), {
      ...credentials,
      projectName,
      metrics: JSON.stringify(selectedMetrics),
    })
      .then((data) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        this.setState({ isSubmitting: false, errorMessage: undefined });
        this.reloadData(this.props);
      })
      .catch((err) => {
        this.setState({ isSubmitting: false, errorMessage: String(err) });
      });
  }

  render() {
    const { intl } = this.props;
    const { isLoadingKey, isLoading, isUpdateKey, isSubmitting, errorMessageKey, errorMessage } = this.state;
    const {
      clusterType,
      clusterName,
      enableAutoscale,
      isLoadingFile,
      configFileList,
      fileContent,
      projectId,
      privateKeyId,
      privateKey,
      clientEmail,
      clientId,

      instanceTypeList,
      instanceTypes,
      treeData,
      // eslint-disable-next-line no-unused-vars
      treeDataKeys,
      selectedMetrics,
    } = this.state;

    let hasErrKey = !clusterType;
    if (enableAutoscale) {
      hasErrKey = hasErrKey || R.isNil(fileContent);
    }
    if (clusterType === 'k8sGke') {
      hasErrKey = hasErrKey || !clusterName || !projectId || !privateKeyId || !privateKey || !clientEmail || !clientId;
    }
    const hasChangeKey =
      this.enableAutoscale !== enableAutoscale ||
      this.fileContent !== fileContent ||
      this.clusterName !== clusterName ||
      this.privateKeyId !== privateKeyId ||
      this.privateKey !== privateKey ||
      this.clientId !== clientId ||
      this.clientEmail !== clientEmail ||
      this.projectId !== projectId;
    const hasFormErr = selectedMetrics.length === 0;
    return (
      <Container fullHeight className={`overflow-y-auto ${isLoadingKey || isLoading ? ' loading' : ''}`}>
        <div className="full-height" style={{ width: 800 }}>
          <Form layout="vertical">
            <Form.Item
              label="Cluster Type"
              required
              validateStatus={!clusterType ? 'error' : 'success'}
              help={!clusterType ? 'This input is required.' : undefined}
            >
              <Select
                disabled
                options={this.clusterTypeOptions}
                value={clusterType}
                onChange={(clusterType) => this.setState({ clusterType })}
              />
            </Form.Item>
            <Form.Item
              label="Cluster Name"
              required={clusterType === 'k8sGke'}
              validateStatus={clusterType === 'k8sGke' && !clusterName ? 'error' : 'success'}
              help={clusterType === 'k8sGke' && !clusterName ? 'This input is required.' : undefined}
            >
              <Input value={clusterName} onChange={(e) => this.setState({ clusterName: e.target.value })} />
            </Form.Item>
            <div className="flex-row" style={{ marginBottom: 24 }}>
              <div className="bold" style={{ marginRight: 16, color: 'rgba(0, 0, 0, 0.85)' }}>
                Enable Autoscale
              </div>
              <Checkbox
                size="small"
                checked={enableAutoscale}
                onChange={(e) => {
                  const newVal = e.target.checked;
                  const state = { enableAutoscale: newVal };
                  if (!newVal) {
                    state.configFileList = [];
                    state.fileContent = undefined;
                  }
                  this.setState(state);
                }}
              />
            </div>
            {enableAutoscale && (
              <Form.Item label="Config File" required>
                <Upload
                  onRemove={(file) => this.setState({ configFileList: [], fileContent: undefined })}
                  beforeUpload={this.beforeUpload}
                  fileList={configFileList}
                >
                  <Button>{isLoadingFile ? <LoadingOutlined /> : <UploadOutlined />} Select File</Button>
                </Upload>
              </Form.Item>
            )}

            {clusterType === 'k8sGke' && (
              <>
                <Form.Item
                  label="Project Id"
                  required
                  validateStatus={!projectId ? 'error' : 'success'}
                  help={!projectId ? 'This input is required.' : undefined}
                >
                  <Input value={projectId} onChange={(e) => this.setState({ projectId: e.target.value })} />
                </Form.Item>
                <Form.Item
                  label="Private key Id"
                  required
                  validateStatus={!privateKeyId ? 'error' : 'success'}
                  help={!privateKeyId ? 'This input is required.' : undefined}
                >
                  <Input.Password
                    value={privateKeyId}
                    onChange={(e) => this.setState({ privateKeyId: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  label="Private key"
                  required
                  validateStatus={!privateKey ? 'error' : 'success'}
                  help={!privateKey ? 'This input is required.' : undefined}
                >
                  <Input.Password value={privateKey} onChange={(e) => this.setState({ privateKey: e.target.value })} />
                </Form.Item>
                <Form.Item
                  label="Client email"
                  required
                  validateStatus={!clientEmail ? 'error' : 'success'}
                  help={!clientEmail ? 'This input is required.' : undefined}
                >
                  <Input value={clientEmail} onChange={(e) => this.setState({ clientEmail: e.target.value })} />
                </Form.Item>
                <Form.Item
                  label="Client Id"
                  required
                  validateStatus={!clientId ? 'error' : 'success'}
                  help={!clientId ? 'This input is required.' : undefined}
                >
                  <Input.Password value={clientId} onChange={(e) => this.setState({ clientId: e.target.value })} />
                </Form.Item>
              </>
            )}

            {errorMessageKey && (
              <Alert message="Error" description={errorMessageKey} type="error" showIcon style={{ marginBottom: 16 }} />
            )}
            <Form.Item>
              <Button
                type="primary"
                disabled={hasErrKey || !hasChangeKey}
                loading={isUpdateKey}
                onClick={this.handleUpdateKey}
              >
                Update private key
              </Button>
            </Form.Item>

            {clusterType === 'k8sGke' && (
              <>
                <Form.Item
                  label="Instance Type"
                  validateStatus={R.isEmpty(instanceTypes) ? 'error' : 'success'}
                  help={R.isEmpty(instanceTypes) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    disabled={hasChangeKey}
                    mode="multiple"
                    allowClear
                    autoClearSearchValue={false}
                    maxTagCount={1}
                    value={instanceTypes}
                    onChange={this.handleInstanceTypeChange}
                    showSearch
                    optionFilterProp="key"
                    optionLabelProp="key"
                    dropdownRender={(menu) => {
                      const selectAll = R.difference(instanceTypeList, instanceTypes).length === 0;
                      return (
                        <div>
                          <div
                            className="flex-row"
                            style={{ padding: '5px 12px' }}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selectAll}
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (checked) {
                                  this.handleInstanceTypeChange(instanceTypeList);
                                } else {
                                  this.handleInstanceTypeChange([]);
                                }
                              }}
                            />
                            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                          </div>
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                  >
                    {R.map((item) => {
                      return (
                        <Select.Option className="hide-icon" key={item}>
                          <Checkbox
                            checked={instanceTypes.includes(item)}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {item}
                        </Select.Option>
                      );
                    }, instanceTypeList)}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Metrics"
                  required
                  validateStatus={selectedMetrics.length === 0 ? 'error' : 'success'}
                  help={selectedMetrics.length === 0 ? 'This input is required.' : undefined}
                >
                  <TreeSelect
                    style={{ width: '100%' }}
                    disabled={hasChangeKey}
                    allowClear
                    maxTagCount={1}
                    showSearch
                    autoClearSearchValue={false}
                    treeDefaultExpandAll={false}
                    treeCheckable
                    treeNodeLabelProp="title"
                    treeData={treeData}
                    value={selectedMetrics}
                    onChange={(selectedMetrics) => this.setState({ selectedMetrics })}
                  />
                </Form.Item>
                {errorMessage && (
                  <Alert
                    message="Error"
                    description={errorMessage}
                    type="error"
                    showIcon
                    style={{ marginBottom: 16 }}
                  />
                )}
                <Form.Item style={{ marginBottom: 500 }}>
                  <Button
                    type="primary"
                    disabled={hasChangeKey || hasFormErr}
                    loading={isSubmitting}
                    onClick={this.handleSubmit}
                  >
                    Update metrics
                  </Button>
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      </Container>
    );
  }
}

const EndpointSettingKubernetes = injectIntl(EndpointSettingKubernetesCore);
export default connect((state) => {
  return {};
}, {})(EndpointSettingKubernetes);
