/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import type { Action } from '../types';

export const ActionTypes = {
  LOAD_CAUSAL_GROUP_ANALYSIS: 'LOAD_CAUSAL_GROUP_ANALYSIS',
  SET_CAUSAL_GROUP_ANALYSIS: 'SET_CAUSAL_GROUP_ANALYSIS',
  LOAD_CAUSAL_GROUPLIST: 'LOAD_CAUSAL_GROUPLIST',
  SET_CAUSAL_GROUPLIST_DATA: 'SET_CAUSAL_GROUPLIST_DATA',
  LOAD_CAUSAL_INCIDENTLIST: 'LOAD_CAUSAL_INCIDENTLIST',
  SET_CAUSAL_INCIDENTLIST_DATA: 'SET_CAUSAL_INCIDENTLIST_DATA',
  LOAD_CAUSAL_INCIDENT_META_DATA: 'LOAD_CAUSAL_INCIDENT_META_DATA',
  SET_CAUSAL_INCIDENT_META_DATA: 'SET_CAUSAL_INCIDENT_META_DATA',

  LOAD_CAUSAL_DEPENDENCY_GRAPH: 'LOAD_CAUSAL_DEPENDENCY_GRAPH',
  SET_CAUSAL_DEPENDENCY_GRAPH: 'SET_CAUSAL_DEPENDENCY_GRAPH',
  LOAD_CAUSAL_LOG_EVENTS: 'LOAD_CAUSAL_LOG_EVENTS',
  SET_CAUSAL_LOG_EVENTS: 'SET_CAUSAL_LOG_EVENTS',
};

export const setCausalErrorMessage = (message: any = null, params: Object = {}): Action => ({
  type: 'SET_CAUSAL_ERROR_MESSAGE',
  payload: { message, params },
});

export const createCausalGroup = (params: Object, loader: ?any): Action => ({
  type: 'CREATE_CAUSAL_GROUP',
  payload: { params, loader },
});

export const updateCausalGroup = (params: Object, loader: ?any): Action => ({
  type: 'UPDATE_CAUSAL_GROUP',
  payload: { params, loader },
});

export const removeCausalGroup = (params: Object, loader: ?any): Action => ({
  type: 'REMOVE_CAUSAL_GROUP',
  payload: { params, loader },
});

export const handleShareCausalGroup = (params: Object, loader: ?any): Action => ({
  type: 'SHARE_CAUSAL_GROUP',
  payload: { params, loader },
});

export const createCausalIncident = (params: Object, loader: ?any): Action => ({
  type: 'CREATE_CAUSAL_INCIDENT',
  payload: { params, loader },
});

export const loadCausalIncident = (params: Object, force: Boolean = false, loader: ?any): Action => ({
  type: 'LOAD_CAUSAL_INCIDENT',
  payload: { params, force, loader },
});

export const setCausalIncidentData = (params: Object, data: Object): Action => ({
  type: 'SET_CAUSAL_INCIDENT_DATA',
  payload: { params, data },
});

export const resetCausalIncidentData = (isInstance: Boolean = true): Action => ({
  type: 'RESET_CAUSAL_INCIDENT_DATA',
  payload: { isInstance },
});

export const loadEventMetricMap = (params: Object, force: Boolean = false, loader: ?any): Action => ({
  type: 'LOAD_EVENT_METRIC_MAP',
  payload: { params, force, loader },
});

export const setEventMetricMapData = (params: Object, data: Object): Action => ({
  type: 'SET_EVENT_METRIC_MAP_DATA',
  payload: { params, data },
});
