import React from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';

import { Alert, Button, Spin } from 'antd';
import { get, isArray } from 'lodash';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal, Popover } from '../../../lib/fui/react';

import { logMessages } from '../../../common/log/messages';
import { GlobalParse, parseLocation } from '../../../common/utils';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { appButtonsMessages } from '../../../common/app/messages';

type Props = {
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  // eslint-disable-next-line
  incident: Object,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  timezoneOffset: Number,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  currentLocale: String,
  globalInfo: Object,
};

class RecommendationPredictionModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      suggestActions: [],
      recommendationMap: {},
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  async reloadData(props) {
    const { currentLocale, incident } = props;
    const { suggestActionList } = incident || {};

    this.setState({ isLoading: true });

    const allSuggestActions = GlobalParse.parseSuggestActions({
      currentLocale,
      rootCauseSuggestActions: [],
      suggestActionListFromTimeLine: suggestActionList || [],
    });

    const recommendationMap = await this.getRecommendationList(props);

    this.setState({ isLoading: false, suggestActions: allSuggestActions, recommendationMap });
  }

  @autobind
  getRecommendationList(props) {
    const { credentials, incident } = props;
    const { predictionRuleInfo } = incident || {};

    return fetchGet(getEndpoint('getrecommendations'), {
      ...credentials,
      predictionRuleInfo: `[${decodeURIComponent(predictionRuleInfo)}]`,
    })
      .then((data) => {
        delete data.success;
        return data || {};
      })
      .catch((e) => {
        console.log(String(e));
        return {};
      });
  }

  @autobind
  renderSuggestActions(rowData, index) {
    const { location, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    const { action, componentName, instanceName } = rowData;

    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName);
    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {action}
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          <Popover content={componentName} mouseEnterDelay={0.3} placement="top">
            <div className="hidden-line-with-ellipsis">{componentName}</div>
          </Popover>
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          <Popover content={instanceStr} mouseEnterDelay={0.3} placement="top">
            <div className="hidden-line-with-ellipsis">{instanceStr}</div>
          </Popover>
        </div>
      </div>
    );
  }

  render() {
    const { intl, onClose, incident } = this.props;
    const { isLoading, suggestActions, recommendationMap } = this.state;

    return (
      <Modal
        visible
        title={intl.formatMessage(logMessages.recommendations)}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={1300}
        bodyStyle={{ height: 600 }}
        footer={[
          <Button size="small" key="cancel" onClick={() => onClose()}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>,
        ]}
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          {suggestActions.length === 0 && (
            <div style={{ marginBottom: 16 }}>
              <Alert
                message=""
                description={intl.formatMessage(logMessages.noRecommendationsFound)}
                type="info"
                showIcon
              />
            </div>
          )}
          {suggestActions.length > 0 && (
            <div className="flex-row" style={{ marginBottom: 4, lineHeight: '24px' }}>
              <span style={{ fontSize: 14, fontWeight: 700 }}>{intl.formatMessage(logMessages.recommendations)}</span>
            </div>
          )}
          {suggestActions.length > 0 && (
            <div className="event-list flex-grow flex-min-height flex-col" style={{ marginBottom: 16 }}>
              <div className="event-list-header" style={{ height: 50, width: '100%' }}>
                <div className="header-column" style={{ width: 120, flex: 1 }}>
                  {intl.formatMessage(eventActionMessages.suggestedAction)}
                </div>
                <div className="header-column" style={{ width: 120, flex: 1 }}>
                  {intl.formatMessage(eventActionMessages.componentName)}
                </div>
                <div className="header-column" style={{ width: 120, flex: 1 }}>
                  {intl.formatMessage(eventActionMessages.instanceName)}
                </div>
              </div>
              <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                {R.addIndex(R.map)((rowData, index) => this.renderSuggestActions(rowData, index), suggestActions)}
              </div>
            </div>
          )}
          {isArray(recommendationMap[incident.predictionRuleInfo]) &&
            recommendationMap[incident.predictionRuleInfo]?.length > 0 && (
              <div className="flex-row" style={{ marginBottom: 4, lineHeight: '24px' }}>
                <span style={{ fontSize: 14, fontWeight: 700 }}>
                  {intl.formatMessage(eventMessages.manuallyInputRecommendations)}
                </span>
              </div>
            )}
          {isArray(recommendationMap[incident.predictionRuleInfo]) &&
            recommendationMap[incident.predictionRuleInfo]?.length > 0 && (
              <div className="event-list flex-grow flex-min-height flex-col" style={{ marginBottom: 16 }}>
                <div className="event-list-header" style={{ height: 50, width: '100%' }}>
                  <div className="header-column" style={{ width: 120, flex: 1 }}>
                    {intl.formatMessage(eventMessages.recommendation)}
                  </div>
                </div>
                <div className="event-list-grid flex-grow flex-min-height">
                  {R.addIndex(R.map)((rowData, index) => {
                    return (
                      <div
                        key={index}
                        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                        style={{ minHeight: 40 }}
                      >
                        <div className="row-column" style={{ width: 120, flex: 1 }}>
                          {rowData}
                        </div>
                      </div>
                    );
                  }, recommendationMap[incident.predictionRuleInfo])}
                </div>
              </div>
            )}
        </Spin>
      </Modal>
    );
  }
}

const RecommendationPredictionModal = injectIntl(RecommendationPredictionModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset, currentLocale, globalInfo } = state.app;
    const { userInfo, credentials } = state.auth;
    return {
      location,
      loadStatus,
      timezoneOffset,
      userInfo,
      credentials,
      currentLocale,
      globalInfo,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(RecommendationPredictionModal);
