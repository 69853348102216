/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';
import appForecastMetricDataParser from './magicParsers/appForecastMetricDataParser';

const getMetricAppForecastData = (credentials: Credentials, params: Object) => {
  const { projectName, instanceGroup, startTime, interval } = params;
  const dateFormat = 'YYYY-MM-DD';
  const startTimeObj = moment(startTime, dateFormat).startOf('day');

  return fetchPost(getEndpoint('appForecast'), {
    ...credentials,
    projectName,
    instanceGroup,
    startTimestamp: startTimeObj.valueOf(),
    interval: parseInt(interval, 10),
    operation: 'get',
  }).then((d) => {
    const rawData = d.data;

    const appNames = rawData.appNames || [];
    const stats = get(rawData, ['appObj', 'stats'], {});
    const instanceInfo = rawData.instanceInfo || {};
    const defaultAppName = 'Aggregation';

    const convertor = (name) => {
      const instanceList = instanceInfo[name] || [];
      return {
        name,
        instanceList,
        instanceCount: instanceList.length,
        cpuMax: get(stats, [name, 'max']),
        cpuAvg: get(stats, [name, 'avg']),
      };
    };

    // Put special Aggregation at the top of the list and sort others by cpu avg
    let appInfoList = R.map((a) => convertor(a), R.filter((a) => a !== defaultAppName, appNames));
    appInfoList = R.sort((a, b) => b.cpuAvg - a.cpuAvg, appInfoList);

    const defaultAppInfo = convertor(defaultAppName);
    defaultAppInfo.instanceCount = R.reduce((acc, a) => acc + a.instanceCount, 0, appInfoList);
    appInfoList = [defaultAppInfo, ...appInfoList];

    const appForecastData = get(rawData, ['appObj', 'appForecastData'], {});

    const appMetricMap = {};
    R.forEachObjIndexed((val, key) => {
      appMetricMap[key] = appForecastMetricDataParser(val);
    }, appForecastData);
    return {
      rawData,
      data: {
        appInfoList,
        appMetricMap,
        periodMap: get(rawData, ['periodMap'], {}),
        startTimestamp: get(rawData, ['appObj', 'startTimestamp']),
        endTimestamp: get(rawData, ['appObj', 'endTimestamp']),
        endDataTimestamp: get(rawData, ['appObj', 'endDataTimestamp']),
      },
    };
  });
};

export default getMetricAppForecastData;
