/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import ReactDOM from 'react-dom';
import localforage from 'localforage';
import { createBrowserHistory } from 'history';

import getBrowserLocale from '../common/getBrowserLocale';
import configureStore from '../common/configureStore';

import AppRoot from './app/Root';
import appVersion from '../../version.js';

const history = createBrowserHistory();

const createInitialState = () => {
  // Merge server states with default web states as the initial states.
  // eslint-disable-next-line no-underscore-dangle
  const state = JSON.parse(window.__INITIAL_STATE__ || '{}');
  return {
    ...state,
    app: {
      appName: 'InsightFinder',
      appVersion,
      currentLocale: getBrowserLocale(),
      ...state.app,
    },
  };
};

const store = configureStore({
  initialState: createInitialState(),
  platformDeps: {
    storageEngine: localforage,
    history,
  },
});

ReactDOM.render(<AppRoot store={store} history={history} />, document.getElementById('app'));
