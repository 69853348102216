/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';
import * as R from 'ramda';
import type { Deps } from '../../types';
import { getLogIncidentList } from '../../apis';
import { appMessages } from '../../app/messages';
import { apiEpicErrorHandle } from '../../errors';
import { updateLastActionInfo } from '../../app/actions';
import { setLogInfo } from '../actions';
import { getLoaderEpicAction, pickNotNil } from '../../utils';

const loadLogIncidentListEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('LOAD_LOG_INCIDENT_LIST').concatMap((action) => {
    // Need to handle several cases:
    // - projectName is empty, which also means there is no project to select, show message.
    // - projectName is not in the list, show message.
    // - params are correct, calls API and handler errors.
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const projects = R.filter((p) => !p.isMetric, state.app.projects);
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const { projectName } = params;
    const project = R.find((p) => p.projectName === projectName, projects);

    let apiAction$ = null;

    if (!projectName) {
      apiAction$ = Observable.of(setLogInfo({ currentError: appMessages.errorsNoLogProject }));
    } else if (!project) {
      apiAction$ = Observable.of(setLogInfo({ currentError: appMessages.errorsProjectNotFound }));
    } else {
      apiAction$ = Observable.from(getLogIncidentList(credentials, params))
        .concatMap((d) => {
          return Observable.of(
            setLogInfo({
              incidentList: d.data.incidentList,
            }),
          );
        })
        .catch((err) => {
          if (err.name === 'NotFoundError') {
            return Observable.of(setLogInfo({ currentError: appMessages.errorsProjectNotFound }));
          } else {
            return apiEpicErrorHandle(err);
          }
        });
    }

    return Observable.concat(
      Observable.of(setLogInfo({ currentError: null })),
      showLoader,
      Observable.of(updateLastActionInfo()),
      apiAction$,
      hideLoader,
    );
  });

export default loadLogIncidentListEpic;
