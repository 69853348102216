import React from 'react';
import * as R from 'ramda';
import { get, isNumber } from 'lodash';
import VLink from 'valuelink';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';

import { Defaults } from '../../../common/utils';
import { Modal } from '../../../../artui/react';
import { Container, Input, Select } from '../../../lib/fui/react';
import { appFieldsMessages } from '../../../common/app/messages';

type Props = {
  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,

  project: Object,
  onClose: Function,
};

class TicketParamsModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { project } = this.props;
    this.instanceListOptions = project.instanceInfoList || [];
    this.eventTypeOptions = [{ label: 'Metric', value: 'metric' }, { label: 'Log', value: 'log' }];
    this.state = {
      filterInstanceName: '',
      countFilter: 5,
      probabilityFilter: '',
      delayFilter: '',
      eventTypeFilter: null,
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = nextProps;
    if (intl.locale !== this.props.intl.locale) {
      moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    }
  }

  @autobind
  handleConfirm() {
    const { ...rest } = this.state;
    const { onClose } = this.props;
    if (onClose) {
      onClose({ ...rest });
    }
  }
  render() {
    const { intl, onClose } = this.props;

    const instanceNameLink = VLink.state(this, 'filterInstanceName');
    const countFilterLink = VLink.state(this, 'countFilter').check(
      (value) => Boolean(value) && parseInt(value, 10) >= 0,
      'input a positive integer number',
    );
    const probabilityFilterLink = VLink.state(this, 'probabilityFilter').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'input a positive integer number',
    );
    const delayFilterLink = VLink.state(this, 'delayFilter').check(
      (value) => !value || (Boolean(value) && parseInt(value, 10) >= 0),
      'input a positive integer number',
    );
    const eventTypeFilterLink = VLink.state(this, 'eventTypeFilter');

    const hasError = countFilterLink.error;
    return (
      <Modal ref={(modal) => (this.modal = modal)} size="small" onClose={onClose}>
        <Container className="flex-col content" style={{ fontSize: 12 }}>
          <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
            <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
              Instance Filter:
            </span>
            <div className="flex-grow">
              <Select
                name="Instance"
                valueLink={instanceNameLink}
                placeholder="instance"
                options={this.instanceListOptions}
                clearable
              />
            </div>
          </div>
          <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
            <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
              Count Filter:
            </span>
            <div className="flex-grow">
              <Input type="number" valueLink={countFilterLink} placeholder={'count'} fullWidth />
            </div>
          </div>
          <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
            <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
              Probability Filter(%):
            </span>
            <div className="flex-grow">
              <Input type="number" valueLink={probabilityFilterLink} placeholder={'probability'} fullWidth />
            </div>
          </div>
          <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
            <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
              Delay Filter(minutes):
            </span>
            <div className="flex-grow">
              <Input type="number" valueLink={delayFilterLink} placeholder={'delay'} fullWidth />
            </div>
          </div>
          <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
            <span className="label" style={{ width: 120, fontWeight: 'bold' }}>
              Event Type Filter:
            </span>
            <div className="flex-grow">
              <Select options={this.eventTypeOptions} valueLink={eventTypeFilterLink} />
            </div>
          </div>
          <div className="flex-row" style={{ marginTop: 16 }}>
            <div className="flex-grow" />
            <div className={`ui button orange ${hasError ? 'disabled' : ''}`} onClick={this.handleConfirm}>
              <i className="save icon" />
              <span>Confirm</span>
            </div>
          </div>
        </Container>
      </Modal>
    );
  }
}

const TicketParamsModal = injectIntl(TicketParamsModalCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    return {
      location,
      loadStatus,
    };
  },
  { push, replace },
)(TicketParamsModal);
