/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Button, Input, Modal, Popconfirm, Spin } from 'antd';
import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import fetchDelete from '../../../../common/apis/fetchDelete';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { buildUrl, parseLocation } from '../../../../common/utils';
import BaseUrls from '../../../app/BaseUrls';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { eventMessages } from '../../../../common/metric/messages';

import PreviewInstance from './PreviewInstance';

import logo from '../../../../../images/InsightFinder_Horizontal_Gray_Light.svg';
import logoDark from '../../../../../images/InsightFinder_Horizontal_Gray_Dark.svg';

type Props = {
  projectName: String,
  location: Object,
  intl: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  childReloadData: Function,
  localInstanceGroupList: Array<Object>,
  currentTheme: String,
};

class EditComponentCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmitting: false,

      activeEvent: null,
    };
    this.relationList = [];
    this.listRef = null;
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projectName !== this.props.projectName) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { credentials, projectName } = props;

    this.setState({ isLoading: true });
    this.props.updateLastActionInfo();
    fetchGet(getEndpoint('componentmapping'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        let relationList = data || [];
        relationList = R.addIndex(R.map)((item, index) => {
          const { instanceRegex } = item;
          return { ...item, oldInstanceRegex: instanceRegex, id: `${+new Date()}${index}` };
        }, relationList);

        this.relationList = relationList;
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.relationList = [];
        this.setState({ isLoading: false });
      });
  }

  @autobind
  handleSaveClick() {
    const { credentials, projectName, childReloadData, location } = this.props;
    const params = parseLocation(location);

    this.setState({ isSubmitting: true });

    let relationList = R.clone(this.relationList);
    relationList = R.map((item) => {
      const { oldInstanceRegex, instanceRegex, componentName } = item;
      if (oldInstanceRegex === instanceRegex) {
        return { instanceRegex, componentName };
      }
      return item;
    }, relationList);

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('componentmapping'), {
      ...credentials,
      projectName,
      mapping: JSON.stringify(relationList),
    })
      .then((data) => {
        this.setState({ isSubmitting: false });
        childReloadData();

        this.promptModal = Modal.confirm({
          title: (
            <span>
              Please update the dependency graph under the{' '}
              <Button
                type="link"
                style={{ padding: 0, margin: 0, fontSize: 14 }}
                onClick={() =>
                  window.open(buildUrl(BaseUrls.CausalAnalysis, {}, { customerName: params?.customerName }), '_blank')
                }
              >
                causal analysis
              </Button>{' '}
              page since the component name is changed.
            </span>
          ),
          content: null,
          onOk: () => {
            if (this.promptModal) this.promptModal.destroy();
          },
        });
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
      });
  }

  @autobind
  handleAddRelationClick() {
    const { activeEvent } = this.state;
    this.relationList = [
      {
        instanceRegex: null,
        componentName: null,
        id: `${+new Date()}${this.relationList.length}`,
      },
      ...this.relationList,
    ];
    if (this.listRef) {
      this.listRef.scrollTop = 0;
    }
    if (activeEvent) {
      this.setState({ activeEvent: null });
    } else {
      this.forceUpdate();
    }
  }

  @autobind
  renderListView(rowData, index) {
    const { intl } = this.props;
    const { activeEvent } = this.state;
    const { instanceRegex, componentName, id } = rowData;

    const activeFlag = activeEvent?.id === id;

    return (
      <div
        key={id}
        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''} ${activeFlag ? 'active' : ''}`}
        style={{ minHeight: 40 }}
      >
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          <Input
            size="small"
            value={instanceRegex}
            onChange={(e) => {
              const val = e.target.value;
              rowData.instanceRegex = val;
              this.forceUpdate();
            }}
          />
          <Button
            size="small"
            disabled={!rowData.instanceRegex}
            style={{ marginLeft: 8 }}
            onClick={() => this.setState({ activeEvent: rowData })}
          >
            {intl.formatMessage(appButtonsMessages.preview)}
          </Button>
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          <Input
            size="small"
            value={componentName}
            onChange={(e) => {
              const val = e.target.value;
              rowData.componentName = val;
              this.forceUpdate();
            }}
          />
        </div>
        <div className="row-column" style={{ width: 120 }}>
          <Popconfirm
            placement="topRight"
            title={intl.formatMessage(appMessages.continueConfirm)}
            onConfirm={() => this.handleRemoveClick(rowData, index)}
            onCancel={(event) => event.stopPropagation()}
          >
            <Button size="small" className="button-color-grey" onClick={(event) => event.stopPropagation()}>
              <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  }

  @autobind
  handleRemoveClick(rowData, index) {
    const { activeEvent } = this.state;
    const { oldInstanceRegex } = rowData;
    const findIdx = R.findIndex((item) => item.id === activeEvent?.id, this.relationList || []);

    if (oldInstanceRegex) {
      const { credentials, projectName } = this.props;

      this.setState({ isLoading: true });
      this.props.updateLastActionInfo();
      fetchDelete(getEndpoint('componentmapping'), {
        ...credentials,
        projectName,
        mapping: JSON.stringify([rowData]),
      })
        .then((data) => {
          if (index === findIdx) {
            this.setState({ activeEvent: null }, () => {
              this.reloadData(this.props);
            });
          } else {
            this.reloadData(this.props);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    } else {
      this.relationList = R.remove(index, 1, this.relationList);
      if (index === findIdx) {
        this.setState({ activeEvent: null });
      } else {
        this.forceUpdate();
      }
    }
  }

  render() {
    const { intl, localInstanceGroupList, currentTheme } = this.props;
    const { isLoading, isSubmitting, activeEvent } = this.state;

    const hasError =
      this.relationList.length === 0 ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => item.instanceRegex, this.relationList),
      );
    return (
      <>
        <div className="flex-grow overflow-hidden flex-row">
          <div className="corner-8 flex-col" style={{ flex: 5, marginRight: 12, padding: '12px 4px' }}>
            <div className="flex-row" style={{ marginBottom: 8 }}>
              <Button type="primary" onClick={this.handleAddRelationClick}>
                <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
              </Button>
              <div className="flex-grow" />
            </div>

            <Spin spinning={isLoading} wrapperClassName="spin-full-height full-width flex-grow flex-min-height">
              <div className="flex-grow flex-col flex-min-height">
                <div className="event-list flex-grow flex-col flex-min-height">
                  <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                    <div className="header-column" style={{ width: 120, flex: 1 }}>
                      {intl.formatMessage(appFieldsMessages.instanceRegex)}
                    </div>
                    <div className="header-column" style={{ width: 120, flex: 1 }}>
                      {intl.formatMessage(appFieldsMessages.component)}
                    </div>
                    <div className="header-column" style={{ width: 120 }} />
                  </div>
                  <div
                    className="event-list-grid flex-grow overflow-y-auto"
                    ref={(ref) => {
                      this.listRef = ref;
                    }}
                  >
                    {R.addIndex(R.map)((rowData, index) => this.renderListView(rowData, index), this.relationList)}
                  </div>
                </div>
              </div>
            </Spin>
          </div>
          <div className="corner-8 flex-col" style={{ flex: 3, padding: '12px 4px' }}>
            <div style={{ lineHeight: '36px', fontSize: 16 }}>
              {intl.formatMessage(appButtonsMessages.previewInstance)}:
            </div>
            {activeEvent ? (
              <PreviewInstance
                intl={intl}
                event={activeEvent}
                key={activeEvent?.instanceRegex}
                localInstanceGroupList={localInstanceGroupList}
              />
            ) : (
              <div
                className="corner-8 flex-col flex-min-height flex-grow flex-center-align flex-center-justify"
                style={{ border: '1px solid var(--virtualized-table-border-color)' }}
              >
                <div className="text-center" style={{ fontSize: 16, color: 'var(--type-gray)' }}>
                  <img src={currentTheme === 'light' ? logo : logoDark} alt="" style={{ width: 60, height: 60 }} />
                  <div className="bold" style={{ margin: '16px 0 4px 0' }}>
                    {intl.formatMessage(eventMessages.noPreviewYet)}
                  </div>
                  <div>{intl.formatMessage(eventMessages.seeMatchingInstance)}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex-row" style={{ marginBottom: 8 }}>
          <div className="flex-grow" />
          <Button
            size="small"
            type="primary"
            loading={isSubmitting}
            disabled={isLoading || hasError}
            onClick={this.handleSaveClick}
          >
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </div>
      </>
    );
  }
}

const EditComponent = injectIntl(EditComponentCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, projects, currentTheme } = state.app;
    const { credentials } = state.auth;
    return {
      loadStatus,
      projects,
      credentials,
      location,
      currentTheme,
    };
  },
  { updateLastActionInfo },
)(EditComponent);
