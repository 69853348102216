/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'react-router-redux';

import { parseLocation, buildLocation } from '../../../common/utils';
import { Container } from '../../../lib/fui/react';
import { logMessages } from '../../../../src/common/log/messages';

import EventGroup from '../../../../components/log/loganalysis/event-group';

type Props = {
  intl: Object,
  location: Object,
  push: Function,
  loadStatus: Object,
  isAdmin: Boolean,
  userName: String,

  width: Number,
  height: Number,
  project: Object,
  queryParams: Object,
  queryResult: Object,
};

class AllLogEventCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      total: 0,
      page: 1,
      pageSize: 100,

      clusterInfoList: [],
      clusterSampleMsgs: {},
      eventList: [],
    };
  }

  componentDidMount() {
    this.convertData(this.props, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.convertData(nextProps, {});
    }
  }

  @autobind
  convertData(props, params = {}) {
    const { queryResult, otherResult } = props;
    const { numOfResult, pageNumber, pageSize } = otherResult;

    let clusterInfoList = [];
    let eventList = [];
    R.forEachObjIndexed((val, dayTs) => {
      const { clusterInfo, events } = val;
      clusterInfoList = [...clusterInfoList, ...clusterInfo];
      eventList = [...eventList, ...events];
    }, queryResult || {});

    clusterInfoList = R.uniqWith(R.eqBy(R.prop('nid')), clusterInfoList);
    eventList = R.sortWith([R.ascend(R.prop('timestamp'))], eventList);

    const clusterSampleMsgs = {};
    R.forEach((c) => {
      const sampleMsgs = get(c, ['sampleMsg'], []);
      R.addIndex(R.forEach)((s, idx) => {
        clusterSampleMsgs[`${c.nid}[${idx}]`] = s;
      }, sampleMsgs);
    }, clusterInfoList);

    this.setState({ total: numOfResult, page: pageNumber, pageSize, clusterInfoList, clusterSampleMsgs, eventList });
  }

  @autobind
  handlePageChanged(pageNo) {
    const { push, location } = this.props;
    const params = parseLocation(location);
    this.setState({ page: pageNo }, () => {
      push(buildLocation(location.pathname, {}, { ...params, pageNumber: pageNo }));
    });
  }

  render() {
    const { intl, width, height } = this.props;
    const { page, pageSize, total, clusterSampleMsgs, eventList } = this.state;

    return (
      <Container className="flex-col" style={{ height, width }}>
        <div className="flex-grow flex-col" style={{ overflowY: 'hidden' }}>
          {eventList.length === 0 && (
            <div
              className="ui info message"
              style={{
                margin: 8,
                height: 50,
              }}
            >
              {intl.formatMessage(logMessages.noLogEntryFound)}
            </div>
          )}
          {eventList.length > 0 && (
            <EventGroup
              name=""
              eventDataset={eventList}
              totalCount={total}
              pageSize={pageSize}
              pageNo={page}
              onPageChanged={this.handlePageChanged}
              isContext
              showFE={false}
              hasType={false}
              hasCount
              hasNid
              hasDecompress
              clusterSampleMsgs={clusterSampleMsgs}
              needSortDatetime
            />
          )}
        </div>
      </Container>
    );
  }
}

const AllLogEvent = injectIntl(AllLogEventCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { isAdmin, userName } = state.auth.userInfo;

    return {
      location,
      loadStatus,
      isAdmin,
      userName,
    };
  },
  { push },
)(AllLogEvent);
