// import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getAnomalyInstance = (credentials: Credentials, params: Object) => {
  return fetchGet(getEndpoint('instancewithanomaly'), {
    ...credentials,
    ...params,
  }).then((data) => {
    return data;
  });
};

export default getAnomalyInstance;
